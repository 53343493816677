import React, { Component } from 'react';
import { Form, Input, Button, Col, Spin, Modal, Select, message} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalNuevoEstandarDesempenoEspecifico extends Component {
    formRef = React.createRef();

    render() {
        const { fetchingNuevoEstandarDesempenoEspecifico, showModalNuevoEstandarDesempenoEspecifico, onShowModalNuevoEstandarDesempenoEspecifico,
            onNuevoEstandarDesempenoEspecifico, listaServiciosFiltro, page, limpiarFormulario, fetchingListaServiciosFiltro, onListaServiciosFiltro, onListaServiciosFiltroLimpiar, listaServiciosActivosF} = this.props

        const onFinish = formulario => {
            for (let i=0; i<formulario.idServicio.length; i++) {
                formulario.idServicio[i] = formulario.idServicio[i].split('-')[0]
            }
            console.log('Success:', formulario);
            onNuevoEstandarDesempenoEspecifico(formulario, page, listaServiciosActivosF);
        }

        if (limpiarFormulario && limpiarFormulario === 'crearEstandarDesempeño') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo);
        };

        const handleCancel = () => {
            onShowModalNuevoEstandarDesempenoEspecifico();
            this.formRef.current.resetFields();
        }

        const buscarServicios = value => {
            if(value.length>=3){
                if(value){
                    console.log(value);
                onListaServiciosFiltro(value)
                } else {
                    message.warning('No se encontró servicio con esas palabras')
                }
            } else {
               onListaServiciosFiltroLimpiar();
            } 
        }

        return (
            <Modal
                visible={showModalNuevoEstandarDesempenoEspecifico}
                title='Nueva Sección de Estándares Específicos'
                onCancel={handleCancel}
                footer={false}
            >
                <Spin spinning={fetchingNuevoEstandarDesempenoEspecifico}>
                    <Form {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        
                        ref={this.formRef}
                    >
                        <Form.Item
                            label="Sección"
                            name="descripcion"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <TextArea
                            pattern="^[ñíóáéú a-zA-Z ]+$"
                            title="Descripción sin carácteres especiales" 
                            maxLength={150} 
                            placeholder='Descripción sección'
                            autoSize />
                        </Form.Item>
                        
                        <Form.Item
                            label="Servicio"
                            name="idServicio"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Selecciona un servicio"
                                loading={fetchingListaServiciosFiltro}
                                showSearch
                                filterOption={false}
                                onSearch={buscarServicios}
                                notFoundContent={null}
                            >
                                    {listaServiciosFiltro.map(option => <Option key={option.idServicio + '-' + option.nombre}>{option.idServicio + '-' + option.nombre}</Option>)}
                            </Select>
                        </Form.Item>
                        <Col span={12}>
                            <Button className="btnFiltrar" htmlType="submit">Crear Sección de Estándares Específicos</Button>
                        </Col>
                    </Form>
                </Spin>

            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingNuevoEstandarDesempenoEspecifico: state.MecanismosDePagosReducer.fetchingNuevoEstandarDesempenoEspecifico,
        showModalNuevoEstandarDesempenoEspecifico: state.MecanismosDePagosReducer.showModalNuevoEstandarDesempenoEspecifico,
        page: state.MecanismosDePagosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevoEstandarDesempenoEspecifico: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_ESTANDAR_DESEMPENO_ESPECIFICO', showModalNuevoEstandarDesempenoEspecifico: false });
        },
        onNuevoEstandarDesempenoEspecifico: (formulario, page, listaServiciosActivosF) => {
            dispatch({ type: 'NUEVO_ESTANDAR_DESEMPENO_ESPECIFICO_REQUEST', formulario: formulario, page: page , listaServiciosActivosF:listaServiciosActivosF});
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
        },
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoEstandarDesempenoEspecifico);