import React, { Component } from 'react';
import { connect } from "react-redux";
import { Form, Input, Button, Modal, Col, Spin, Row, InputNumber, Select, message, Popover } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

import L from 'leaflet';
import { Map, ImageOverlay, FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const {Option} = Select;
class ModalEditarInterior extends Component {
    formRef = React.createRef();

    state = {
        fileImage: false,
        botonImage: false,
        botonEditar: false,
        fetchingImagen: true,
        imageUrl: null,
        height: 0,
        width: 0,
        geometry: [],
    }

    render() { 
        const {
            onEditarInterior,
            onShowModalEditarInterior,
            showModalEditarInterior,
            fetchingEditarInterior,
            interiorSeleccionado,
            page, fetchingListaUnidadesFuncioActCompleta, listaUnidadesFuncioActCompleta,
            limpiarFormulario, onGetListaUniFuncioActBusqueda, onGetListaUniFuncioActBusquedaLimpiar,
        } = this.props;

        if (limpiarFormulario && limpiarFormulario==='editarInterior') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
            onGetListaUniFuncioActBusquedaLimpiar();
            this.setState({
                fileImage: false,
                botonImage: false,
                botonEditar: false,
                fetchingImagen: false,
                imageUrl: null,
                height: 0,
                width: 0,
                geometry: [],
            })
        }
        if(!this.state.imageUrl && interiorSeleccionado){
            const rutaImagen = interiorSeleccionado.unidadFuncionalByIdUnidadFuncional.rutaImagen;
            const img = new Image();
            const updateState = (width, height) => {
                this.setState({
                    imageUrl: rutaImagen,
                    width, 
                    height,
                    geometry: null,
                    fetchingImagen: false,
                });
            }
            img.onload = function() {
                console.log(this.width + 'x' + this.height);
                updateState(this.width, this.height);
            }
            img.src = interiorSeleccionado.unidadFuncionalByIdUnidadFuncional.rutaImagen;
        }
        if(interiorSeleccionado && !this.state.geometry){
            if(interiorSeleccionado.coordenadas){
                this.setState({
                    geometry: interiorSeleccionado.coordenadas
                }) 
            }
            else{
                this.setState({
                    geometry: []
                }) 
            }
        }
        
        // const enteroInput = (value) => {
        //     this.formRef.current.setFieldsValue({'cantidad': parseInt(value)});
        // }
        const buscarUnidadFuncional = (value) => {
            if(value.length>=3){
                if (value) {
                    console.log(value)
                    onGetListaUniFuncioActBusqueda(value);
                } else {
                    message.warning('No se encontró unidad funcional con esas palabras')
                }
            }
            else{
                onGetListaUniFuncioActBusquedaLimpiar();
            }
        };

        const onFinish = formulario => {
            if(this.state.geometry.length>1){
                return message.error('El interior funcional cuenta con más de una geometría. Favor de trazar solo una geometría.');
            }
            
            console.log('Success:', formulario, this.state.geometry);
            onEditarInterior(formulario, interiorSeleccionado, this.state.geometry, page);
        };
        
        const handleCancel = () => {
            onShowModalEditarInterior();
            onGetListaUniFuncioActBusquedaLimpiar();
            this.setState({
                fileImage: false,
                botonImage: false,
                botonEditar: false,
                fetchingImagen: false,
                imageUrl: null,
                height: 0,
                width: 0,
                geometry: [],
            })
        }
        const bounds = [
            [0, 0],
            [this.state.height, this.state.width],
        ]
        const style = { height: '35em', width: '100%' }

        const editGeometry = (geometriesE) => {
            console.log(`geometriesE`, geometriesE)
            
            this.setState({...this.state, geometry: geometriesE})
        }
        const _edited = (e) => {
            const {layers: {_layers}} = e;
            let editedArray = [];
            Object.values(_layers).map(({_leaflet_id, editing}) =>{
                //edited array
                console.log(`editing`, editing)
                editedArray = [...editedArray, {id: _leaflet_id, latlngs: editing.latlngs[0][0]}]
            });
            
            console.log('edited', e, editedArray);
            editGeometry(editedArray); 
        }

        const getGeoJson = () => {
            let geometry = this.state.geometry;
            console.log(`geometry`, geometry)
            return {
                type: "FeatureCollection",
                features: [{
                    type: "Feature",
                    properties: {},
                    geometry: {
                        type: "Polygon",
                        coordinates: 
                            geometry
                    }
                }]
            }
        }

        const _onFeatureGroupReady = reactFGref => {
            // populate the leaflet FeatureGroup with the geoJson layers
            if(!reactFGref ) return;
            let leafletGeoJSON = new L.GeoJSON(getGeoJson());
            let leafletFG = reactFGref.leafletElement;
            
            let layers = leafletFG._layers;
            let layers_ids = Object.keys(layers);
            
            if(layers_ids.length === 0) {
                //add layers
                leafletGeoJSON.eachLayer(layer => {
                    leafletFG.addLayer(layer);
                });
            }
        };
        return (  
            <Modal
            visible={showModalEditarInterior}
            title='Editar Interior Funcional'
            onCancel={handleCancel}
            footer={false}
            width={'80%'}

            >
                <Spin spinning={fetchingEditarInterior}>
                    {interiorSeleccionado ? (
                    <Form {...layout}
                    name="formulario"
                    initialValues={interiorSeleccionado}
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Unidad funcional a la que pertenece"
                                    name="todoUbicacion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona la unidad funcional"
                                        loading={fetchingListaUnidadesFuncioActCompleta}
                                        showSearch
                                        filterOption={false}
                                        onSearch={buscarUnidadFuncional}
                                        notFoundContent={null}
                                    >
                                        {listaUnidadesFuncioActCompleta.map(option => 
                                        <Option key={option.idUnidadFuncional}>
                                            <Popover content={option.unidadFuncional} title={false}>
                                                {option.unidadFuncional}
                                            </Popover>
                                        </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Interior funcional"
                                    name="interiorFuncional"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={100} placeholder="Ingresa el interior funcional" />
                                </Form.Item>
                            </Col>
                            {/* <Col span={11}>
                                <Form.Item
                                    label="Cantidad"
                                    name="cantidad"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber min={1} max={100} onChange={enteroInput} placeholder="Ingresa la cantidad" />
                                </Form.Item>
                            </Col> */}
                            <Col span={23}>
                                <Spin spinning={this.state.fetchingImagen}>
                                    <p>Geometría</p>
                                    {console.log(`this.state.imageUrl`, this.state.imageUrl)}
                                    {this.state.imageUrl && this.state.geometry ? (
                                    <Map
                                        crs={L.CRS.Simple}
                                        minZoom={-4}
                                        bounds={bounds}
                                        style={style}
                                    >
                                        <FeatureGroup
                                            ref={reactFGref => {
                                            _onFeatureGroupReady(reactFGref);
                                            }}
                                        >
                                            <EditControl
                                                position="topright"
                                                // onCreated={_created}
                                                onEdited={_edited}
                                                // onDeleted={_deleted}
                                                draw={{
                                                    circle: false,
                                                    circlemarker: false,
                                                    marker: false,
                                                    rectangle: false,
                                                    polyline: false,
                                                    polygon: false,
                                                }}
                                                edit={{
                                                    remove: false,
                                                }}
                                            />
                                        </FeatureGroup>
                                        <ImageOverlay
                                            url={this.state.imageUrl}
                                            bounds={bounds}
                                            zIndex={10}
                                        />
                                    </Map>
                                    ):false}
                                </Spin>
                            </Col>
                            
                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Editar Interior Funcional</Button>
                            </Col>
                        </Row>
                    </Form>
                    ):false}
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarInterior: state.CatalogosGeneralesReducer.fetchingEditarInterior,
        showModalEditarInterior: state.CatalogosGeneralesReducer.showModalEditarInterior,
        fetchingListaPermisos: state.CatalogosGeneralesReducer.fetchingListaPermisos,
        listaPermisos: state.CatalogosGeneralesReducer.listaPermisos,
        interiorSeleccionado: state.CatalogosGeneralesReducer.interiorSeleccionado,
        page: state.CatalogosGeneralesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaUnidadesFuncioActCompleta: state.CatalogosGeneralesReducer.fetchingListaUnidadesFuncioActCompleta,
        listaUnidadesFuncioActCompleta: state.CatalogosGeneralesReducer.listaUnidadesFuncioActCompleta,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarInterior: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_INTERIOR', fetchingEditarInterior: false, interiorSeleccionado: null})
        },
        onEditarInterior: ( formulario, interiorSeleccionado, editedGeometry, page ) => {
            dispatch({ type: "EDITAR_INTERIOR_REQUEST", formulario, interiorSeleccionado, editedGeometry, page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        },
        onGetListaUniFuncioActBusqueda: (busqueda) => {
            dispatch({ type: 'LISTA_UNI_FUNCIO_ACT_COMPLETA_REQUEST', busqueda: busqueda });
        },
        onGetListaUniFuncioActBusquedaLimpiar: () => {
            dispatch({ type: 'LISTA_UNI_FUNCIO_ACT_COMPLETA_SUCCESS', listaUnidadesFuncioActCompleta: [] });
        },
        onShowModalMapa: () => {
            dispatch({ type: 'SHOW_MODAL_MAPA_INTERIOR', showModalMapaInterior: true})
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarInterior);
