import React, { Component } from 'react';
import { Form, Input, Button, Col, Spin, Modal, Select, Row, message } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalNuevoSubfamilias extends Component {
    formRef = React.createRef();
    render() {
        const { fetchingNuevoSubfamilias, showModalNuevoSubfamilias, onNuevoSubfamilias, onShowModalNuevoSubfamilias, listaCentroDeCostosActivos, fetchingListaCentroDeCostosActivos, limpiarFormulario, fetchingListaServiciosActivosF, listaServiciosActivosF, fetchingListaFamiliasActivasServ, listaFamiliasActivasServ, onListaFamilias,
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onNuevoSubfamilias(formulario, this.props.page);
        };

        if (limpiarFormulario && limpiarFormulario === 'crearSubfamilia') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        

        const onServicioSeleccionado = (idServicio) =>{
            this.formRef.current.resetFields(['idFamilia']);
            onListaFamilias(idServicio);
        }

        const handleCancel = () => {
            onShowModalNuevoSubfamilias();
            this.formRef.current.resetFields();
        }

        return (
            <Modal
            visible={showModalNuevoSubfamilias}
            title='Nueva Subfamilia'
            onCancel={handleCancel}
            footer={false}
            >
                <Spin spinning={fetchingNuevoSubfamilias}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Row justify='space-around'></Row>
                            <Col span={24}>
                                <Form.Item
                                    label="Subfamilia"
                                    name="subFamilia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={30} placeholder="Ingrese subfamilia, el nombre sólo debe contener letras" pattern="^[a-zñA-ZÑ ÁÉÓÚÍáéóíú]+$" title="El nombre sólo debe contener letras"/>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                        label="Servicio"
                                        name="idServicio"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el servicio"
                                            loading={fetchingListaServiciosActivosF}
                                            onChange= { onServicioSeleccionado }
                                        >
                                            {listaServiciosActivosF.map(option => <Option key={option.idServicio}>{ option.nombre + ': ' + option.nombreC }</Option>)}
                                        </Select>
                            </Form.Item>
                            </Col>
                            
                            {/* {listaFamiliasActivasServ[0]?( */}
                            <Col span={24}>
                                <Form.Item
                                        label="Familia"
                                        name="idFamilia"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona la familia de activos"
                                            loading={fetchingListaFamiliasActivasServ}
                                            disabled={!(listaFamiliasActivasServ.length > 0)}
                                        >
                                                 {listaFamiliasActivasServ.map(option => <Option key={option.idFamilia}>{ option.familia }</Option>)}
                                        </Select>
                                </Form.Item>
                            </Col>
                            {/* ): false} */}

                            <Col span={24}>
                                <Form.Item
                                        label="Centro de costos"
                                        name="idCentroCostos"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el centro de costo"
                                             loading={fetchingListaCentroDeCostosActivos}
                                        >
                                             {listaCentroDeCostosActivos.map(option => <Option key={option.idCentroCosto}>{ option.centroCosto }</Option>)}
                                        </Select>
                                </Form.Item>
                            </Col>

                            <Col span={11}>
                                <Button className="btnFiltrar" htmlType="submit">Crear Subfamilia</Button>
                            </Col>
                        <Row/>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingNuevoSubfamilias: state.CatalogosGeneralesReducer.fetchingNuevoSubfamilias,
        showModalNuevoSubfamilias: state.CatalogosGeneralesReducer.showModalNuevoSubfamilias,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        page: state.CatalogosGeneralesReducer.page,
        listaCentroDeCostosActivos: state.CatalogosGeneralesReducer.listaCentroDeCostosActivos,
        fetchingListaCentroDeCostosActivos: state.CatalogosGeneralesReducer.fetchingListaCentroDeCostosActivos,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaFamiliasActivasServ: state.CatalogosGeneralesReducer.fetchingListaFamiliasActivasServ,
        listaFamiliasActivasServ: state.CatalogosGeneralesReducer.listaFamiliasActivasServ,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalNuevoSubfamilias: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_SUBFAMILIAS', showModalNuevoSubfamilias: false})
        },
        onNuevoSubfamilias: ( formulario, page ) => {
            dispatch({ type: "NUEVO_SUBFAMILIAS_REQUEST" , formulario: formulario, page: page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        },
        onListaFamilias: (idServicio) => {
            dispatch({ type: 'LISTA_FAMILIAS_ACTIVAS_SERVICIO_REQUEST', idServicio: idServicio })
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoSubfamilias);