import React from 'react';
import { Form, Card, Spin, Row, Col, Result, Menu, Select, Typography, Alert, Button, Tooltip, message} from 'antd';
import { RightCircleOutlined, LeftCircleOutlined, FileWordOutlined, ReloadOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

import RelacionFallas from './RelacionFallas';
import RelacionDeducciones from './RelacionDeducciones';
import RelacionDeduccionesReiteracion from './RelacionDeduccionesReiteracion';
import RelacionDeduccionesErrores from './RelacionDeduccionesErrores';
import InformacionDeTerreno from "./InformacionDeTerreno";
import ModalEditorTexto from "./ModalEditorTexto";
import Infraestructura from "./Infraestructura";
import Equipo from "./Equipo";
import Acciones from "./Acciones";
import ResumenPagoServicio from './ResumenPagoServicio';
import ResumenCalculoPMI from './ResumenCalculoPMI';
import ResumenCalculoPMR from './ResumenCalculoPMR';
import DeduccionAplicable from './DeduccionAplicable';
import DeduccionConsumoSP from './DeduccionConsumoSP';

import imaSearch from '../../assets/undraw_choice_re_2hkp.svg';
import ReactVivus from "react-vivus";

const { SubMenu } = Menu;
const { Meta } = Card;
const { Paragraph, Text } = Typography;
const { Option } = Select;

class RMDPContainer extends React.Component {
     formRef = React.createRef();
     
     componentDidMount(){ this.props.onGetAnios(); this.props.onGetServiciosActivos(); this.props.onGetGruposServicios(); }

     state={ keySelected: null, titleCard: 'Selecciona una Opción', colMenu: 7, colCard: 17, maximizar: false, subTitle: '', mesBoolean: true, anioState: null, booleanAnio: true, idAnio: 0, idMes: null, mesLocal: '' }
 
     render() {
          const { onMesSeleccionado, listaAniosRMDP, onGetMeses, listaMesesRMDP, onGetRMDP, RMDPObject, listaMesesProcesados, onGetMesesNO } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){ permisosUsuario = JSON.parse(permisosUsuario); }

          const handleClick = (e) => {
               if(e.item.props.children){
                    let titleCard = e.item.props.children;
                    if(titleCard.includes('(',0)){
                         titleCard = 'Listados del (i) al (vii) enunciados en el Anexo 1, Mecanismo de Pagos';
                         this.setState({ subTitle: e.item.props.children }); 
                    } else if(titleCard.includes('1.', 0)){
                         //titleCard = <p>ACREDITAMIENTO DE LA PRESTACIÓN DE LOS SERVICIOS DURANTE EL <br/>PERIODO DE CONFORMIDAD CON LO DISPUESTO EN EL ANEXO 12 DEL CONTRATO</p>
                         titleCard = 'ACREDITAMIENTO DE LA PRESTACIÓN DE LOS SERVICIOS DURANTE EL PERIODO DE CONFORMIDAD CON LO DISPUESTO EN EL ANEXO 12 DEL CONTRATO'
                         this.setState({ subTitle: e.item.props.children }); 
                    } else if(titleCard.includes('3.', 0)){
                         titleCard = 'Cálculo de Pago por Servicio para el Mes i';
                         this.setState({ subTitle: e.item.props.children }); 
                    }

                    this.setState({ keySelected: e.key, titleCard: titleCard });
               }
          };

          const onExpandir = () => {
               if(!this.state.maximizar){ this.setState({ colMenu: 0, colCard: 24, maximizar: true }); }
               else{ this.setState({  colMenu: 7, colCard: 17, maximizar: false }); }
          };

          const FPSii_iii = () => {
               return(
                    <Row justify='space-around'>
                         <Col span={24}>
                              <Paragraph>
                                   <Text strong style={{ fontSize: 14 }} >
                                        Para cumplir con los puntos (ii) y (iii) del RMDP, debe entregar los tickets originales emitidos durante el mes i, dichos tickets deben contener además de los detalles de la falla reportada, la atención prestada para la solución de cada una de las fallas.
                                   </Text>
                              </Paragraph>
                         </Col>
                    </Row>
               );
          };

          const onChangeAnio = (value) => {
               if(value){
                    let idAnioCalendarioLocal = value.split('-')[0];
                    let anioLocal = value.split('-')[1];
                    onGetMeses(anioLocal);
                    onGetMesesNO(idAnioCalendarioLocal);
                    this.setState({ idAnio: idAnioCalendarioLocal, anioState: anioLocal, booleanAnio: false, keySelected: null, titleCard: 'Selecciona una Opción', mesBoolean: true });
                    this.formRef.current.resetFields(['mesSeleccionado']);
               }
          };

          const onChangeMes = (value) => {
               let idMes =  value.split('-')[0];
               let mes = value.split('-')[1];
               onMesSeleccionado(idMes, this.state.anioState, mes);
               onGetRMDP(idMes, this.state.anioState);
               this.setState({ mesBoolean: false, keySelected: null, titleCard: 'Selecciona una Opción', idMes, mesLocal: mes});

               //Limpiar txt de meses actuales y anteriores
               this.props.onLimpiarInfra();
               this.props.onLimpiarInfraAnterior();
               this.props.onLimpiarTerreno();
               this.props.onLimpiarTerrenoAnterior();
               this.props.onLimpiarAcciones();
               this.props.onLimpiarAccionesAnterior();
          };

          const onDescargarReporte =  () => {
               // message.info('La descarga puede demorar un momento.');
               this.props.onGetReporte(this.state.idMes, this.state.anioState, this.state.mesLocal);
          };

          return (<>
               { permisosUsuario ? (<>
                    <Row gutter={[0,0]}>
                         <Col span={this.state.colMenu} style={{backgroundColor: '#737373', borderTopLeftRadius:'1em', borderBottomLeftRadius:'1em'}}>
                              <Row justify='space-around' style={{paddingLeft:'.5em', paddingRight:'2.5em'}}>
                                   <Col span={24}>
                                   <Form name="formulario" ref={this.formRef} >
                                        <Row justify='space-around' gutter={[8,8]}>
                                             <Col span={11}> <br />
                                                  <Tooltip placement="top" title='Selecciona el año a presentar'>
                                                       <Form.Item name="anioSeleccionado">
                                                            <Select
                                                                 style={{width: '100%'}}
                                                                 placeholder="Año"
                                                                 loading={this.props.fetchinglistaAniosRMDP}
                                                                 onChange={onChangeAnio}
                                                                 showSearch
                                                            >
                                                                 {listaAniosRMDP.map(option => <Option key={option.idAnioCalendario + '-' + option.anio}>{option.anio}</Option>)}
                                                            </Select>
                                                       </Form.Item>
                                                  </Tooltip>
                                             </Col>
                                             <Col span={11}> <br />
                                                  <Tooltip placement="top" title='Selecciona el mes a presentar'>
                                                       <Form.Item name="mesSeleccionado" >
                                                            <Select
                                                                 disabled={this.state.booleanAnio}
                                                                 style={{width: '100%'}}
                                                                 placeholder="Mes"
                                                                 loading={this.props.fetchingListaMesesProcesados}
                                                                 onChange={onChangeMes}
                                                                 showSearch
                                                            >
                                                                 {listaMesesProcesados.map(option => <Option key={option.idMes + '-' + option.mesCalendario}>{option.mesCalendario}</Option>)}

                                                                 {/* {listaMesesRMDP.map(option => <Option key={option.idMes + '-' + option.mesCalendario}>{option.mesCalendario}</Option>)} */}
                                                            </Select>
                                                       </Form.Item>
                                                  </Tooltip>
                                             </Col>
                                             <Col span={1}> <br />
                                                  <Button className='iconSecundaryBackDark' shape='circle' icon={<FileWordOutlined />} disabled={!permisosUsuario.exportarExcelRmdp ? true : this.state.mesBoolean} onClick={onDescargarReporte} loading={this.props.fetchingGetReporteRMDP}/>
                                             </Col>
                                        </Row>
                                   </Form>
                                   </Col>
                              </Row>

                              <Menu style={{textAlign: 'left'}} mode="inline" onClick={handleClick} defaultOpenKeys={['sub1']} theme='dark' disabled={!RMDPObject}>
                                   <SubMenu key="sub1" title={<p style={{ fontSize:16}}><b>Reporte Mensual de Desempeño y Pagos</b></p>} >
                                        <SubMenu disabled={this.state.mesBoolean} key="sub2" title={<p><b>1. </b>Acreditamiento Anexo 12</p>} >
                                             <Menu.Item key="1">1.1 Información del Terreno</Menu.Item>
                                             <Menu.Item key="2">1.2 Infraestructura</Menu.Item>
                                             <Menu.Item key="3">1.3 Equipo - Servicios Programados</Menu.Item>
                                        </SubMenu>
                                        <SubMenu disabled={this.state.mesBoolean} key="sub3" title={<p><b>2. </b>Falla de la Presentación del Servicio</p>}>
                                             <Menu.Item key="4">(i) Relación de Fallas en la Presentación del Servicio</Menu.Item>
                                             <Menu.Item key="5">(ii) y (iii) Tickets Emitidos en el Mes i</Menu.Item>
                                             <Menu.Item key="6">(iv) Relación de Deducciones en el Mes i</Menu.Item>
                                             <Menu.Item key="7">(v) Relación de Deducciones por Reiteraciones</Menu.Item>
                                             <Menu.Item key="8">(vi) Relación de Deducciones por Errores u Omisiones</Menu.Item>
                                             <Menu.Item key="9">(vii) Descripción de Acciones</Menu.Item>
                                        </SubMenu>
                                        <SubMenu disabled={this.state.mesBoolean} key="sub4" title={<p><b>3. </b>Cálculo de Pago por Servicio para el Mes i</p>} >
                                             <Menu.Item key="10">3.1 Resumen del Pago por Servicio</Menu.Item>
                                             <Menu.Item key="11">3.2 Resumen para el Cálculo del PMIi</Menu.Item>
                                             <Menu.Item key="12">3.3 Resumen para el Cálculo del PMRi</Menu.Item>
                                             <Menu.Item key="13">3.4 Deducción Aplicable</Menu.Item>
                                             <Menu.Item key="14">3.5 Deducción por Consumo de los Servicios Públicos del Desarrollador</Menu.Item>
                                        </SubMenu>
                                   </SubMenu>
                              </Menu>
                         </Col>

                         <Col span={this.state.colCard} style={{backgroundColor: '#F2F2F2', borderBottomRightRadius:'1em', borderTopRightRadius:'1.5em' }}>
                               <Row type="flex" align='middle'>
                                   <Col span={24} style={{backgroundColor: '#E4E4E4', paddingTop: '1em', paddingBottom: '1em', borderTopRightRadius:'1em'}}>
                                        <Row justify='start' align='middle' gutter={[0,0]}>
                                             <Col span={3} style={{paddingLeft: '1em'}}>
                                                  <Text style={{textAlign: 'left', fontSize: '2em', color: '#737373', borderLeftColor: '#E4E4E4', borderRightColor:'#737373', borderTopColor:'#E4E4E4', borderBottomColor:'#E4E4E4', paddingRight: '.5em', borderStyle:'solid'}} strong> RMDP </Text>
                                             </Col>
                                             <Col span={20} style={{ paddingLeft: '.5em', textAlign:'left' }}>
                                                  <Text style={{textAlign: 'left', fontSize: 16, color: '#737373' }} strong> {this.state.titleCard} </Text>
                                             </Col>
                                             <Col span={1}>
                                                  { this.state.maximizar ? (<> <RightCircleOutlined className='iconoMaxMin' onClick={onExpandir} /> </>):(<> <LeftCircleOutlined className='iconoMaxMin' onClick={onExpandir}/> </>) }
                                             </Col>
                                        </Row>
                                   </Col>
                                   
                                   <Col span={24} style={{backgroundColor: '#F2F2F2', padding: '.5em'}}>
                                   <Spin indicator={<ReloadOutlined style={{ fontSize: 30, textAlign:'center' }} spin />} tip={<p style={{paddingTop:'1em', textAlign:'center'}}>Procesando...</p>} spinning={this.props.fetchingGetReporteRMDP}>
                                        <br />
                                             { !this.state.keySelected ? (<>
                                                  {/* <Row justify='space-around' align='middle' style={{height:'40em'}}>
                                                       <Result
                                                            status="info"
                                                            subTitle="Es necesario seleccionar una opción para continuar."
                                                       />
                                                  </Row> */}
                                                  <Row justify='center' justify='space-around' align='middle' style={{height:'35em'}}>
                                                  <Col span={8} style={{paddingTop:'8em', paddingBottom:'8em', textAlign:'center'}}>
                                                  {/* <img src={imaSearch} /> */}
                                                       <ReactVivus
                                                            id="imaSearch"
                                                            option={{
                                                                 file: imaSearch,
                                                                 animTimingFunction: "EASE",
                                                                 type: "delayed",
                                                            }}
                                                       />

                                                       <Paragraph><Text type='secondary'> Es necesario seleccionar una opción para continuar </Text></Paragraph>
                                                  </Col>
                                                  </Row>
                                             </>):(<>
                                                  <Meta description={<p><b style={{color:'#1747ca', fontSize: 16}}> {this.state.subTitle} </b><br/></p>} />
                                                  { this.state.keySelected == 1 ? (<>
                                                       <InformacionDeTerreno />
                                                  </>): (<>{this.state.keySelected == 2 ? (<>
                                                       <Infraestructura />
                                                  </>): (<>{this.state.keySelected == 3 ? (<>
                                                       <Equipo />
                                                  </>): (<>{this.state.keySelected == 4 ? (<>
                                                       <RelacionFallas />
                                                  </>): (<>{this.state.keySelected == 5 ? (<>
                                                       <FPSii_iii />
                                                  </>): (<>{this.state.keySelected == 6 ? (<>
                                                       <RelacionDeducciones />
                                                  </>): (<>{this.state.keySelected == 7 ? (<>
                                                       <RelacionDeduccionesReiteracion />
                                                  </>): (<>{this.state.keySelected == 8 ? (<>
                                                       <RelacionDeduccionesErrores />
                                                  </>): (<>{this.state.keySelected == 9 ? (<>
                                                       <Acciones />
                                                  </>): (<>{this.state.keySelected == 10 ? (<>
                                                       <ResumenPagoServicio />
                                                  </>): (<>{this.state.keySelected == 11 ? (<>
                                                       <ResumenCalculoPMI />
                                                  </>): (<>{this.state.keySelected == 12 ? (<>
                                                       <ResumenCalculoPMR />
                                                  </>): (<>{this.state.keySelected == 13 ? (<>
                                                       <DeduccionAplicable />
                                                  </>): (<> { this.state.keySelected == 14 ? (<>
                                                       <DeduccionConsumoSP />
                                                  </>):false } </>) }</>)}</>)}</>)}</>)}</>)}</>)}</>)}</>)}</>)}</>)}</>)}</>)}
                                             </>) }   
                                   </Spin>            
                                   </Col>
                                  
                              </Row>
                         </Col>
                    </Row>
               </>): (<>
                    <Row justify='center'>
                         <Result status="403" title="Acceso denegado" subTitle="Lo siento, no tiene permiso autorizado para acceder." />
                    </Row>
               </>) }
              <ModalEditorTexto />
          </>);
     }
}
 
 const mapStateToProps = state => {
     return {

          fetchinglistaAniosRMDP: state.RMDPReducer.fetchinglistaAniosRMDP,
          listaAniosRMDP: state.RMDPReducer.listaAniosRMDP,
          fetchingListaMesesRMDP: state.RMDPReducer.fetchingListaMesesRMDP,
          listaMesesRMDP: state.RMDPReducer.listaMesesRMDP,
          fetchingGetRMDP: state.RMDPReducer.fetchingGetRMDP,
          RMDPObject: state.RMDPReducer.RMDPObject,

          fetchingListaMesesProcesados: state.MecanismosDePagosReducer.fetchingListaMesesProcesados,
          listaMesesProcesados: state.MecanismosDePagosReducer.listaMesesProcesados,

          fetchingGetReporteRMDP: state.RMDPReducer.fetchingGetReporteRMDP,
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          onGetAnios: () => {
               dispatch({ type: 'ANIOS_RMDP_REQUEST' });
          },
          onGetMeses: (anio) => {
               dispatch({ type: 'LISTA_MESES_PROCESADOS_REQUEST', anio });
          },
          onGetMesesNO: (idAnioCalendario) => {
               dispatch({ type: 'MESES_RMDP_REQUEST', idAnioCalendario });
          },
          onMesSeleccionado: (mesSeleccionadoRMDP, anioSeleccionadoRMDP, mesTexto) => {
               dispatch({ type: 'MES_SELECCIONADO_RMDP', mesSeleccionadoRMDP, anioSeleccionadoRMDP, mesTexto });
          },
          onGetRMDP: (mes, anio) => {
               dispatch({ type: 'GET_RMDP_REQUEST', mes, anio });
          },
          onGetReporte: (idMes, anio, mesTxt) => {
               dispatch({ type: 'GET_REPORTE_RMDP_REQUEST', idMes, anio, mesTxt });
          },
          onGetServiciosActivos: () => {
               dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
          },
          onGetGruposServicios: () => {
               dispatch({ type: "LISTA_GRUPOS_SERVICIOS_REQUEST" });
          },

          onLimpiarInfra: () => {
               dispatch({ type: 'OBTENER_ARCHIVO_MES_ACTUAL_INFRAESTRUCTURA_SUCCESS', archivoMesAnteriorInfraestructura: '' });
          },
          onLimpiarInfraAnterior: () => {
               dispatch({ type: 'OBTENER_ARCHIVO_MES_ANTERIOR_INFRAESTRUCTURA_SUCCESS', archivoMesActualInfraestructura: '' });
          },

          onLimpiarTerrenoAnterior: () => {
               dispatch({ type: 'OBTENER_ARCHIVO_MES_ANTERIOR_TERRENO_SUCCESS', archivoMesAnteriorTerreno: '' });
          },
          onLimpiarTerreno: () => {
               dispatch({ type: 'OBTENER_ARCHIVO_MES_ACTUAL_TERRENO_SUCCESS', archivoMesActualTerreno: '' });
          },

          onLimpiarAccionesAnterior: () => {
               dispatch({ type: 'OBTENER_ARCHIVO_MES_ANTERIOR_ACCIONES_SUCCESS', archivoMesAnteriorAcciones: '' });
          },
          onLimpiarAcciones: () => {
               dispatch({ type: 'OBTENER_ARCHIVO_MES_ACTUAL_ACCIONES_SUCCESS', archivoMesActualAcciones: '' });
          },
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(RMDPContainer);
