import React from 'react';
import {connect} from 'react-redux';
import { Spin , Modal, Row, Col, Button} from 'antd';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import Formulario from './Formulario';

function ModalCerrarFolioEO (props) {

    const {folioErrorOmisionSeleccionado, fetchingCerrarFolioEO, showModalCerrarFolioEO, page, busqueda,
        onShowModalCerrarFolio, onCerrarFolio
    } = props;

    const handleCerrar = () => {
        const {idFolioErrorOmision} =folioErrorOmisionSeleccionado
        onCerrarFolio(idFolioErrorOmision, page, busqueda)
    }

    const handleCancel = () => {
        onShowModalCerrarFolio();
    }

    return (
        <>
        <Modal
            visible={showModalCerrarFolioEO}
            title='Cerrar Folio de Error u Omisión'
            onCancel={handleCancel}
            footer={false}
            width='80%'
        >
            <Spin spinning={fetchingCerrarFolioEO}>
                <Formulario id={1} disabled={true} ticketSSConciliado={folioErrorOmisionSeleccionado} action='Editar'/>
                <Row justify='center'>
                    <Col span={11}>
                        <Button className="nuevoUsuario" style={{ width: '100%' }} onClick={handleCerrar}>Cerrar Folio</Button>
                    </Col>
                </Row>
            </Spin>


        </Modal>
    </>
    )
}

const mapStateToProps = state => {
    return {
        folioErrorOmisionSeleccionado: state.MecanismosDePagosReducer.folioErrorOmisionSeleccionado,
        fetchingCerrarFolioEO: state.MecanismosDePagosReducer.fetchingCerrarFolioEO,
        showModalCerrarFolioEO: state.MecanismosDePagosReducer.showModalCerrarFolioEO,
        page: state.MecanismosDePagosReducer.page,
        busqueda: state.MecanismosDePagosReducer.busqueda,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalCerrarFolio: () => {
            dispatch({ type:'MODAL_CERRAR_FOLIO_EO', showModalCerrarFolioEO: false, folioErrorOmisionSeleccionado: null})
        },
        onCerrarFolio: (idFolioErrorOmision, page, busqueda) => {
            dispatch({ type: 'CERRAR_FOLIO_ERROR_OMISION_REQUEST', idFolioErrorOmision, page, busqueda})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalCerrarFolioEO);