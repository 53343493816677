import React from 'react';
import { Row, Col, DatePicker, Card, Table, Input, Button, Select, Tooltip, Modal } from 'antd';
import { EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevaBitacora from './ModalNuevaBitacora';
import ModalEditarBitacora from './ModalEditarBitacora';
import ModalVerPDF from './ModalVerPDF';
import moment from 'moment';

const confirm = Modal.confirm;
const { Search } = Input;
const { Option } = Select;
class CatalogoBitacorasContainer extends React.Component {
    componentDidMount(){
        this.props.onListaServiciosActivos(1,{'idServicio': [], 'param': null, 'fecha': null});
        this.props.onGetInfoProyecto();
        this.props.onListaTiposBitacoraActivos();

        this.props.getBitacorasBusqueda(1, {'idServicio': [], 'param': null, 'fecha': null});
        // this.props.onGetModulosBitacoraByIdModulo();
    }
    state={
        page: 1,
        busqueda: {
            idServicio: [],
            param: null,
            fecha: null,
        },
    }
    render() {
        const { fetchingListaBitacorasBusqueda ,listaBitacorasBusqueda, onEstadoBitacora, listaInfoProyecto,
            getBitacorasBusqueda, onModalEditarBitacora, onModalNuevaBitacora, onModalVerPDF,
            listaBitacorasBusquedaCantidad, fetchingListaServiciosActivosF, listaServiciosActivosF
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        let modulo = sessionStorage.getItem('vistaDashboard');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        console.log('modulo', modulo)

        let userInfo = sessionStorage.getItem('userInfo');
        if(userInfo[0]) {
            userInfo = JSON.parse(userInfo);
        }

        const listaServiciosUsuario = userInfo[0].serviciosByIdUsuario ? userInfo[0].serviciosByIdUsuario : [];

        const onChangeServicio = (value) => {
            const busqueda = {
                param: this.state.busqueda.param,
                idServicio: value,
                fecha: this.state.busqueda.fecha
            }
            this.setState({
                busqueda,
            })
            console.log(busqueda);
            getBitacorasBusqueda(1, busqueda);
        }
        const onBuscarParam = (value) => {
            const busqueda = {
                param: value,
                idServicio: this.state.busqueda.idServicio,
                fecha: this.state.busqueda.fecha,
            }
            this.setState({
                busqueda,
            })
            console.log(busqueda);
            getBitacorasBusqueda(1, busqueda);
        }

        const columns = [{
                title: 'Asunto',
                dataIndex: 'asunto',
                key: 'asunto',
            },{
                title: 'Servicio',
                dataIndex: 'servicio',
                key: 'servicio',
            },{
                title: 'Tipo de bitácora',
                dataIndex: 'tipoBitacora',
                key: 'tipoBitacora',
            },{
                title: 'Descripción',
                dataIndex: 'descripcion',
                key: 'descripcion',
            },{
                title: 'Fecha de alta',
                dataIndex: 'fechaAltaGrid',
                key: 'fechaAltaGrid',
            },{
                title: 'Evidencia',
                dataIndex: 'rutaEvidencia',
                key: 'rutaEvidencia',
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!record.rutaEvidencia ||  
                        ( modulo == 'Configuración y Seguridad' ? !permisosUsuario.visualizarDocumentoBitácoraConfiguraciónYSeguridad: (
                            modulo == 'Información del Proyecto' || modulo == 'Información del proyecto' ? !permisosUsuario.visualizarDocumentoBitácoraInformaciónDelProyecto : (
                                modulo == 'Información operativa' || modulo == 'Información Operativa' ? !permisosUsuario.visualizarDocumentoBitácoraInformaciónOperativa : (
                                    modulo == 'Base de conocimiento' || modulo == 'Base de Conocimiento' ? !permisosUsuario.visualizarDocumentoBitácoraBaseDeConocimiento : (
                                        modulo == 'Almacenes' || modulo == 'Almacen' ? !permisosUsuario.visualizarDocumentoBitácoraAlmacenes : (
                                            'Catálogos generales' || modulo == 'Catálogos Generales' ? !permisosUsuario.visualizarDocumentoBitácoraCatálogosGenerales : (
                                                modulo == 'Recursos humanos' || modulo == 'Recursos Humanos' ? !permisosUsuario.visualizarDocumentoBitácoraRecursosHumanos : (
                                                    modulo == 'Servicios variables' || modulo == 'Servicios Variables' ? !permisosUsuario.visualizarDocumentoBitácoraServiciosVariables : (
                                                        modulo == 'Solicitud de servicios' || modulo == 'Solicitud de Servicios' ? !permisosUsuario.visualizarDocumentoBitácoraSolicitudDeServicios : (
                                                            modulo == 'Servicios planificados' || modulo == 'Servicios Planificados' ? !permisosUsuario.visualizarDocumentoBitácoraServiciosPlanificados : (
                                                                modulo == 'Servicios públicos' || modulo == 'Servicios Públicos' ? !permisosUsuario.visualizarDocumentoBitácoraServiciosPúblicos : (
                                                                    modulo == 'Gestión de calidad' || modulo == 'Gestión de Calidad' ? !permisosUsuario.visualizarDocumentoBitácoraGestiónDeCalidad : (
                                                                        modulo == 'Evaluación de la gestión' || modulo == 'Evaluación de la Gestión' ? !permisosUsuario.visualizarDocumentoBitácoraEvaluaciónDeLaGestión : (
                                                                            modulo == 'Monitoreo integral' || modulo == 'Monitoreo Integral' ? !permisosUsuario.visualizarDocumentoBitácoraMonitoreoIntegral : (
                                                                                modulo == 'Mecanismos de pago' || modulo == 'Mecanismos de Pago' ? !permisosUsuario.visualizarDocumentoBitácoraMecanismosDePago : !permisosUsuario.visualizarDocumentoBitácoraRmdp
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            ) 
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        ) ) } onClick={() => onVerPDF(record)}><EyeOutlined /></a>
                ),
            }
            // ,{
            //     title: 'Activo',
            //     dataIndex: 'activo',
            //     key: 'activo',
            //     align: 'center',
            //     render: (text, record) => (
            //         <Switch checked={text} 
            //         disabled={ true
            //             // modulo === 'Configuración y Seguridad' ?(permisosUsuario.activarDesactivarBitácoraConfiguraciónYSeguridad ? !permisosUsuario.activarDesactivarBitácoraConfiguraciónYSeguridad : true) : (
            //             //     modulo === 'Información del Proyecto' ?  (permisosUsuario.activarBitácoraInformaciónDelProyecto ? !permisosUsuario.activarBitácoraInformaciónDelProyecto : true) : (
            //             //         modulo === 'Información Operativa' ? !permisosUsuario.activarBitácoraInformaciónOperativa : (
            //             //             modulo === 'Base de Conocimiento' ? !permisosUsuario.activarBitácoraBaseDeConocimiento : (
            //             //                 modulo == 'Almacenes' || modulo == 'Almacen' ? !permisosUsuario.activarBitácoraAlmacenes : (
            //             //                     modulo === 'Catálogos Generales' ? !permisosUsuario.activarBitácoraCatálogosGenerales : (
            //             //                         modulo === 'Recursos Humanos' ? !permisosUsuario.activarBitácoraRecursosHumanos : (
            //             //                             modulo === 'Servicios Variables' ? !permisosUsuario.activarBitácoraServiciosVariables : (
            //             //                                 modulo === 'Solicitud de Servicios' ? !permisosUsuario.activarBitácoraSolicitudDeServicios : (
            //             //                                     modulo === 'Servicios Planificados' ? !permisosUsuario.activarBitácoraServiciosPlanificados : (
            //             //                                         modulo === 'Servicios Públicos' ? !permisosUsuario.activarBitácoraServiciosPúblicos : (
            //             //                                             modulo === 'Gestión de Calidad' ? !permisosUsuario.activarBitácoraGestiónDeCalidad : (
            //             //                                                 modulo === 'Evaluación de la Gestión' ? !permisosUsuario.activarBitácoraEvaluaciónDeLaGestión : (
            //             //                                                     modulo === 'Monitoreo Integral' ? !permisosUsuario.activarBitácoraMonitoreoIntegral : (
            //             //                                                         modulo === 'Mecanismos de Pago' ? !permisosUsuario.activarBitácoraMecanismosDePago : !permisosUsuario.activarBitácoraRmdp
            //             //                                                     )
            //             //                                                 )
            //             //                                             )
            //             //                                         )
            //             //                                     ) 
            //             //                                 )
            //             //                             )
            //             //                         )
            //             //                     )
            //             //                 )
            //             //             )
            //             //         )
            //             //     )
            //             // )
            //         } onChange={() => handleDesactivar(record)}/>
            //     ),
            // }
            ,{
                title: 'Editar',
                key: 'editar',
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' disabled={
                        modulo === 'Configuración y Seguridad' ?(permisosUsuario.editarBitácoraConfiguraciónYSeguridad ? !permisosUsuario.editarBitácoraConfiguraciónYSeguridad : true) : (
                            modulo === 'Información del Proyecto' ?  (permisosUsuario.editarBitácoraInformaciónDelProyecto ? !permisosUsuario.editarBitácoraInformaciónDelProyecto : true) : (
                                modulo === 'Información Operativa' ? !permisosUsuario.editarBitácoraInformaciónOperativa : (
                                    modulo === 'Base de Conocimiento' ? !permisosUsuario.editarBitácoraBaseDeConocimiento : (
                                        modulo == 'Almacenes' || modulo == 'Almacen' ? !permisosUsuario.editarBitácoraAlmacenes : (
                                            modulo === 'Catálogos Generales' ? !permisosUsuario.editarBitácoraCatálogosGenerales : (
                                                modulo === 'Recursos Humanos' ? !permisosUsuario.editarBitácoraRecursosHumanos : (
                                                    modulo === 'Servicios Variables' ? !permisosUsuario.editarBitácoraServiciosVariables : (
                                                        modulo === 'Solicitud de Servicios' ? !permisosUsuario.editarBitácoraSolicitudDeServicios : (
                                                            modulo === 'Servicios Planificados' ? !permisosUsuario.editarBitácoraServiciosPlanificados : (
                                                                modulo === 'Servicios Públicos' ? !permisosUsuario.editarBitácoraServiciosPúblicos : (
                                                                    modulo === 'Gestión de Calidad' ? !permisosUsuario.editarBitácoraGestiónDeCalidad : (
                                                                        modulo === 'Evaluación de la Gestión' ? !permisosUsuario.editarBitácoraEvaluaciónDeLaGestión : (
                                                                            modulo === 'Monitoreo Integral' ? !permisosUsuario.editarBitácoraMonitoreoIntegral : (
                                                                                modulo === 'Mecanismos de Pago' ? !permisosUsuario.editarBitácoraMecanismosDePago : !permisosUsuario.editarBitácoraRmdp
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            ) 
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    } onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },
        ];

        const onVerPDF = (value) => {
            onModalVerPDF(value);
        }
        const onEditar = (key) => {
            console.log('key', key)
            onModalEditarBitacora(key, this.state.page, this.state.busqueda);
            this.props.onListaTiposBitacoraActivos();
        }
        const handleDesactivar = (key) => {
            console.log(key);
            const page = this.state.page;
            const busqueda = this.state.busqueda;
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado de la bitácora "'+ key.asunto +'"?',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoBitacora(key.idBitacora, page, busqueda);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            getBitacorasBusqueda(pagination.current, this.state.busqueda);
		}
        const onNuevoRol = () => {
            onModalNuevaBitacora(this.state.page, this.state.busqueda);
        }
        const onFechaSeleccionada = (value) => {
            let busqueda;
            if(value){
                busqueda = {
                    param: this.state.busqueda.param,
                    idServicio: this.state.busqueda.idServicio,
                    fecha: value.format('YYYY-MM-DD'),
                }
            }
            else{
                busqueda = {
                    param: this.state.busqueda.param,
                    idServicio: this.state.busqueda.idServicio,
                    fecha: null,
                }
            }
            this.setState({
                busqueda,
            })
            console.log(busqueda);
            getBitacorasBusqueda(1, busqueda);
        }

        const  disabledDate = (fecha) => {
            if( fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicioOperacion) {
                return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicioOperacion;
            } else {
                console.log((listaInfoProyecto.vigencia +  parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2])) + '-' +listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[1] + '-' + listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[0])
                if(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos){
                return fecha && moment(fecha.format('YYYY-MM-DD')) > moment((listaInfoProyecto.vigencia +  parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2])) + '-' +listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[1] + '-' + listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[0]);
            }
            }
        }

        return (
        <Card className='cardCatalogos' title={<div className="headerLista">Bitácora {sessionStorage.getItem('vistaDashboard')}</div>}>
                <Row justify='end' gutter={[8,8]}>
                    <Col span={7}>
                        <Search className='buscarModulos' placeholder="Ingresa el tipo de bitácora a filtrar" onSearch={value => onBuscarParam(value)} style={{ width: '100%' }}/>
                    </Col>
                    <Col span={7}>
                        <DatePicker
                            style={{ width: '100%' }}
                            format='DD-MM-YYYY'
                            onChange={onFechaSeleccionada}
                            disabledDate={disabledDate}
                        />
                    </Col>
                    {/* <Col span={7}>
                        <Button onClick={onNuevoRol} disabled={
                            modulo === 'Configuración y Seguridad' ?(permisosUsuario.crearBitácoraConfiguraciónYSeguridad ? !permisosUsuario.crearBitácoraConfiguraciónYSeguridad : true) : (
                                modulo === 'Información del Proyecto' ?  (permisosUsuario.crearBitácoraInformaciónDelProyecto ? !permisosUsuario.crearBitácoraInformaciónDelProyecto : true) : (
                                    modulo === 'Información Operativa' ? !permisosUsuario.crearBitácoraInformaciónOperativa : (
                                        modulo === 'Base de Conocimiento' ? !permisosUsuario.crearBitácoraBaseDeConocimiento : (
                                            modulo == 'Almacenes' || modulo == 'Almacen' ? !permisosUsuario.crearBitácoraAlmacenes : (
                                                modulo === 'Catálogos Generales' ? !permisosUsuario.crearBitácoraCatálogosGenerales : (
                                                    modulo === 'Recursos Humanos' ? !permisosUsuario.crearBitácoraRecursosHumanos : (
                                                        modulo === 'Servicios Variables' ? !permisosUsuario.crearBitácoraServiciosVariables : (
                                                            modulo === 'Solicitud de Servicios' ? !permisosUsuario.crearBitácoraSolicitudDeServicios : (
                                                                modulo === 'Servicios Planificados' ? !permisosUsuario.crearBitácoraServiciosPlanificados : (
                                                                    modulo === 'Servicios Públicos' ? !permisosUsuario.crearBitácoraServiciosPúblicos : (
                                                                        modulo === 'Gestión de Calidad' ? !permisosUsuario.crearBitácoraGestiónDeCalidad : (
                                                                            modulo === 'Evaluación de la Gestión' ? !permisosUsuario.crearBitácoraEvaluaciónDeLaGestión : (
                                                                                modulo === 'Monitoreo Integral' ? !permisosUsuario.crearBitácoraMonitoreoIntegral : (
                                                                                    modulo === 'Mecanismos de Pago' ? !permisosUsuario.crearBitácoraMecanismosDePago : !permisosUsuario.crearBitácoraRmdp
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                ) 
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        } className='nuevoUsuario'>Nuevo Registro</Button>
                    </Col> */}

                    <Col span={1}>
                        <Tooltip title='Nuevo Registro' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoRol} disabled={
                            modulo === 'Configuración y Seguridad' ?(permisosUsuario.crearBitácoraConfiguraciónYSeguridad ? !permisosUsuario.crearBitácoraConfiguraciónYSeguridad : true) : (
                                modulo === 'Información del Proyecto' ?  (permisosUsuario.crearBitácoraInformaciónDelProyecto ? !permisosUsuario.crearBitácoraInformaciónDelProyecto : true) : (
                                    modulo === 'Información Operativa' ? !permisosUsuario.crearBitácoraInformaciónOperativa : (
                                        modulo === 'Base de Conocimiento' ? !permisosUsuario.crearBitácoraBaseDeConocimiento : (
                                            modulo == 'Almacenes' || modulo == 'Almacen' ? !permisosUsuario.crearBitácoraAlmacenes : (
                                                modulo === 'Catálogos Generales' ? !permisosUsuario.crearBitácoraCatálogosGenerales : (
                                                    modulo === 'Recursos Humanos' ? !permisosUsuario.crearBitácoraRecursosHumanos : (
                                                        modulo === 'Servicios Variables' ? !permisosUsuario.crearBitácoraServiciosVariables : (
                                                            modulo === 'Solicitud de Servicios' ? !permisosUsuario.crearBitácoraSolicitudDeServicios : (
                                                                modulo === 'Servicios Planificados' ? !permisosUsuario.crearBitácoraServiciosPlanificados : (
                                                                    modulo === 'Servicios Públicos' ? !permisosUsuario.crearBitácoraServiciosPúblicos : (
                                                                        modulo === 'Gestión de Calidad' ? !permisosUsuario.crearBitácoraGestiónDeCalidad : (
                                                                            modulo === 'Evaluación de la Gestión' ? !permisosUsuario.crearBitácoraEvaluaciónDeLaGestión : (
                                                                                modulo === 'Monitoreo Integral' ? !permisosUsuario.crearBitácoraMonitoreoIntegral : (
                                                                                    modulo === 'Mecanismos de Pago' ? !permisosUsuario.crearBitácoraMecanismosDePago : !permisosUsuario.crearBitácoraRmdp
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                ) 
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        } icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                </Row>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="idBitacora" 
                            columns={columns}
                            dataSource={listaBitacorasBusqueda}
                            loading={fetchingListaBitacorasBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaBitacorasBusquedaCantidad, simple: true, current: this.state.page }}
                        />    
                    </Col>
                </Row>
                <ModalNuevaBitacora/>
                <ModalEditarBitacora/>
                <ModalVerPDF/>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaBitacorasBusqueda: state.ConfiguracionYSeguridadReducer.listaBitacorasBusqueda,
        fetchingListaBitacorasBusqueda: state.ConfiguracionYSeguridadReducer.fetchingListaBitacorasBusqueda,
        listaBitacorasBusquedaCantidad: state.ConfiguracionYSeguridadReducer.listaBitacorasBusquedaCantidad,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,

        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        getBitacorasBusqueda: (page, busqueda) => {
	    	dispatch({ type: "LISTA_BITACORAS_BUSQUEDA_REQUEST", page, busqueda });
        },
        onEstadoBitacora: (idBitacora, page, busqueda) => {
	    	dispatch({ type: "ESTADO_BITACORA_REQUEST", idBitacora, page, busqueda });
        },
        onModalNuevaBitacora: (page, busqueda) => {
            dispatch({ type: "MODAL_NUEVA_BITACORA", showModalNuevaBitacora: true, page, busqueda });
        },
        onModalEditarBitacora: (bitacoraSeleccionada, page, busqueda) => {
            dispatch({ type: "MODAL_EDITAR_BITACORA", showModalEditarBitacora: true, bitacoraSeleccionada, page, busqueda});
        },
        onModalVerPDF: (bitacoraSeleccionada) => {
            dispatch({ type: 'MODAL_VER_BITACORA', showModalVerBitacora: true, bitacoraSeleccionada})
        },
        onListaServiciosActivos: (page, busqueda, listaServiciosUsuario) => {
            dispatch ({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST', page, busqueda, listaServiciosUsuario });
        },
        onGetInfoProyecto: () => {
            dispatch ({ type: 'GET_INFOPROYECTO_REQUEST' });
        },
        onListaTiposBitacoraActivos: () => {
            dispatch ({ type: 'LISTA_TIPOS_BITACORA_ACTIVOS_REQUEST',  });
        },
        onGetModulosBitacoraByIdModulo: () => {
            dispatch({ type: 'LISTA_MODOULOS_BITACORAS_BY_IDMODULO_REQUEST' });
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoBitacorasContainer);