import React from 'react';
import { message, Form, Row, Col, Button, Modal, Select, Input, Spin, Alert } from 'antd';
import { connect } from "react-redux";
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option } = Select;
const { Search } = Input;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };

class InventarioCiclicoContainer extends React.Component {
    formRef = React.createRef();

     componentDidMount() {
          
     }

     state = {
          loadingBooleanLocal: false,
          folioInventario: '',
     }


     render() {
          const { 
               limpiarFormulario, 
               onLimpiarFormulario,
               showModalIngresarFolio,
               onShowModalIngresarFolio,
               tipoInventario,
               loadingBoolean,
               onShowModalInformacionFolio,
               showModalInformacionFolio,
               onProdutosByFolio,
               onShowModalNoFolio,
               fetchingListaFiltroFolio,
               listaFiltroFolio,
               onListaFiltro,
               onListaFiltroLimpiar,
               onValidarConteo,
          } = this.props;


          const regresarIngresar = () =>{
               //setTimeout(onShowModalIngresarFolio, 1500);
               onShowModalIngresarFolio();
               this.setState({loadingBooleanLocal: false});
          };

          const abrirInformacion = () => {
               setTimeout(onShowModalInformacionFolio, 1500);
               onShowModalInformacionFolio(this.state.folioInventario);
          };

          const abrirNoFolio = () => {
               setTimeout(onShowModalNoFolio, 1500);
               onListaFiltroLimpiar();
          };

          const onFinish = formulario => {              
               console.log('Success:', formulario);

               this.setState({ loadingBooleanLocal: true, folioInventario: formulario.folioInventario });
               setTimeout(regresarIngresar, 1200);
               this.formRef.current.resetFields();

               //ABRE EL MODAL PARA INFORMACIÓN Y CAPTURA
               //abrirInformacion();
               onShowModalInformacionFolio(formulario.folioInventario);
               onListaFiltroLimpiar();
               onValidarConteo(formulario.folioInventario);
               
               //abrirNoFolio(); ABRE MODAL ERROR
               //onProdutosByFolio(formulario.folioInventario);
          };

          if (limpiarFormulario && limpiarFormulario === 'inventarioFisico') {
               onLimpiarFormulario();
          }

          const onFinishFailed = errorInfo => {
               console.log('Failed:', errorInfo);
          };

          const handleCancel = () => {
               this.setState({ loadingBooleanLocal: false });
               this.formRef.current.resetFields();
               onShowModalIngresarFolio();
               onListaFiltroLimpiar();
          };

          const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;

          const loading = (
               <Spin tip="Validando Folio..." indicator={antIcon} style={{ color: '#ff8c62' }}>
                    {/* <Alert
                         message="Procesando Información"
                         description="Verificando folio, espere un momento."
                         type="info"
                    /> */}
               </Spin>
          )

          const buscarFolio = value => {
               if(value.length >= 3){
                    if(value){
                         onListaFiltro(value)
                    } else {
                         abrirNoFolio();
                    }
               } else {
                    onListaFiltroLimpiar();
               } 
          };

          return (
               <Modal
                    visible={showModalIngresarFolio}
                    title={'Ingresar Folio de Inventario'}
                    onCancel={handleCancel}
                    footer={false}
                    width={'40%'}
               >

                    <Form {...layout}
                         name="formulario"
                         onFinish={onFinish}
                         
                         ref={this.formRef}
                    >
                         
                         { this.state.loadingBooleanLocal ? (
                              <Row justify='space-around' style={{ textAlign: 'center' }}>
                                   <Col span={24}>
                                        {loading}
                                   </Col>
                              </Row>
                         ) : (

                              <Row justify='space-around' style={{ textAlign: 'left' }}>
                              {/* <Col>
                                   <CheckCircleOutlined spin={false} style={{fontSize: 80, color:"#ff8c62", textAlign:"center"}}/>
                              </Col> */}

                              <Col span={23}>
                                   <Form.Item
                                        label="Folio de Inventario"
                                        name="folioInventario"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                   >
                                        <Select
                                             allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Escribe el folio a buscar"
                                             loading={fetchingListaFiltroFolio}
                                             showSearch={true}
                                             filterOption={false}
                                             onSearch={buscarFolio}
                                             notFoundContent={null}
                                             
                                        >
                                             {listaFiltroFolio.map(option => 
                                                  <Option key={option}> 
                                                       {option}
                                                  </Option>)
                                             }
                                        </Select>
                                   
                                   </Form.Item>
                              </Col>

                              <Col span={23}>
                                   <Button className="btnFiltrar" style={{ width: '100%' }} htmlType='submit'>
                                        Aceptar
                                   </Button>
                              </Col> 
                              </Row>
                         ) }

                    </Form>

                    {/* <InformacionFolio/> */}
               </Modal>

          );
     }
}

const mapStateToProps = state => {
     return {
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          showModalIngresarFolio: state.AlmacenesReducer.showModalIngresarFolio,
          showModalInformacionFolio: state.AlmacenesReducer.showModalInformacionFolio,
          showModalNoFolio: state.AlmacenesReducer.showModalNoFolio,
          tipoInventario: state.AlmacenesReducer.tipoInventario,
          loadingBoolean: state.AlmacenesReducer.loadingBoolean,
          fetchingListaFiltroFolio: state.AlmacenesReducer.fetchingListaFiltroFolio,
          listaFiltroFolio: state.AlmacenesReducer.listaFiltroFolio,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
          onShowModalIngresarFolio: () => {
               dispatch({ type: 'SHOW_MODAL_INGRESAR_FOLIO', showModalIngresarFolio: false });
          },
          onShowModalInformacionFolio: (folioInventario) => {
               dispatch({ type: 'SHOW_MODAL_INFORMACION_FOLIO', showModalInformacionFolio: true, folioInventario });
          },
          onProdutosByFolio: (folioInventario) => {
               dispatch({ type: 'LISTA_PRODUCTOS_POR_FOLIO_REQUEST', folioInventario });
          },
          onShowModalNoFolio: () => {
               dispatch({ type: 'SHOW_MODAL_NO_FOLIO', showModalNoFolio: true });
          },
          onListaFiltro: (busqueda) => {
               dispatch({ type: "FOLIOS_FILTRADOS_REQUEST", busqueda: busqueda})
          },
          // onListaFiltroLimpiar: () => {
          //      dispatch({ type: "FOLIOS_FILTRADOS_SUCCESS", busqueda: ''})
          // },
          onListaFiltroLimpiar: () => {
               dispatch({ type: "FOLIOS_FILTRADOS_SUCCESS", listaFiltroFolio: []})
          },
          onValidarConteo: (folioInventario) => {
               dispatch({ type: 'VALIDACION_CONTEO_BY_FOLIO_REQUEST', folioInventario });
          }
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(InventarioCiclicoContainer);
