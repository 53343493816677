import React from 'react';
import {connect} from 'react-redux';
import {  Button, Row, Col, Table, Switch, Radio, Modal, Tooltip} from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { FilePdfFilled, FileExcelFilled } from '@ant-design/icons';

const {confirm} = Modal;
class TableSection extends React.Component {
     formRef = React.createRef();
     // componentDidMount(){ 
     //     onGetProveedores();
     // }

     state={ 
          agrupado: 'SinAgrupar',
          page: 1,
          orden: {
               field: null,
               order: null,
          }
     }

     render() {
          const {fetchingListaReporteExistencias, listaReporteExistencias, listaReporteExistenciasCantidad, fetchingReporteExistenciasEXCEL, fetchingReporteExistenciasPDF,  formularioReporte,listaServiciosActivosF, handlers, data, onDescargarReportePDF, onDescargarReporteExcel, onGetReporte, onDescargarReporteAgrupadoPDF, onDescargarReporteAgrupadoExcel, onGetReporteAgrupado, listaReporteExistenciasAgrupado, listaReporteExistenciasAgrupadoCantidad, fetchingReporteExistenciasAgrupado, fetchingReporteDinamicoAgrupadoEXCEL, fetchingReporteDinamicoAgrupadoPDF, onCleanFamilias, onCleanSubfamiliasPorServicio, onCleanServiciosAlmacen,
          } = this.props;
          
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]) {
               permisosUsuario = JSON.parse(permisosUsuario);
          }

          const {state, checkArray, order} = data;
          console.log('order  orden', order)
          const {handleTableChange, handleSteps, handleOrden} = handlers;
          
          const onHandleTableChange = (pagination, filter, orden, agrupado) => {
               handleTableChange(pagination, filter, orden, agrupado);
          }

          const onHandleSteps = (value) => {
               handleSteps(value);
               onCleanFamilias();
               onCleanSubfamiliasPorServicio();
               onCleanServiciosAlmacen();
          }
        
          const descargarReporte = (value) => {
               console.log("estoy en descargar", value)
               let _this = this;
               if(_this.state.agrupado === 'SinAgrupar'){
                    if (value === 'pdf') { 
                         onDescargarReportePDF(formularioReporte, order, formularioReporte.titulo); 
                    }  else { 
                         onDescargarReporteExcel(formularioReporte, order, formularioReporte.titulo); 
                    }
               } else {
               //     if (value === 'pdf') { 
               //         onDescargarReporteAgrupadoPDF(formularioReporte, checkbox, _this.state.agrupado, order, formularioReporte.titulo); 
               //     }  else { 
               //         onDescargarReporteAgrupadoExcel(formularioReporte, checkbox, _this.state.agrupado, order, formularioReporte.titulo); 
               //     }
               }
          }

        
          const columns = [
               {title: 'Tipo folio', dataIndex: 'tipoFolio', key: 'tipoFolio', align: 'center'},
               {title: 'SKU', dataIndex: 'sku', key: 'sku', align: 'center'},
               {title: 'Operación', dataIndex: 'operacion', key: 'operacion', align: 'center'},
               {title: 'Fecha/hora de movimiento', dataIndex: 'fechaMovimientoTable', key: 'fechaMovimientoTable', align: 'center'},
               {title: 'Usuario realizó', dataIndex: 'usuario', key: 'usuario', align: 'center'},
               {title: 'Cantidad movimiento', dataIndex: 'cantidadMovimiento', key: 'cantidadMovimiento', align: 'center'},
               {title: 'Moneda', dataIndex: 'moneda', key: 'moneda', align: 'center'},
               {title: 'Tipo cambio', dataIndex: 'tipoCambioTable', key: 'tipoCambioTable', align: 'right'},
               {title: 'Costo unitario', dataIndex: 'costoUnitarioTable', key: 'costoUnitarioTable', align: 'right'},
               {title: 'Costo promedio', dataIndex: 'costoPromedioTable', key: 'costoPromedioTable', align: 'right'},
               {title: 'Costo en pesos', dataIndex: 'costoPesosTable', key: 'costoPesosTable', align: 'right'},
               {title: 'Saldo inventario', dataIndex: 'saldoInventario', key: 'saldoInventario', align: 'right'},
          ];
        
          const columnsSelected = columns.filter( field => checkArray.includes(field.key));
          const scroll = {x: columns.length * 200}

          const onAgrupar = (value) => {
               console.log('value AgruparReporteExistencia', value)

               this.setState({
                    agrupado: value.target.value,
               });
               formularioReporte.tipoAgrupado = value.target.value;
               handleOrden(null, 'ASC', null)

               if(value.target.value !== 'SinAgrupar'){
                    onGetReporteAgrupado(formularioReporte, 1, value.target.value, order)
               }
               else{
                    const orden = {field : null, order: 'ASC'};
                    onGetReporte(formularioReporte, 1, orden)
               }
          }

          const columnsAgrupamiento = [
               { title: this.state.agrupado === "almacen" ? "Almacenes" : this.state.agrupado === "servicio" ? "Servicios" : this.state.agrupado === "familia" ? 'Familias' : this.state.agrupado === "subfamilia" ? "Subfamilias" : this.state.agrupado === "tipoDeFolios" ? "Tipo de folios" : this.state.agrupado, dataIndex: 'nombre', key: "id" },
          ];

          const onChangeAgrupado = (record, pagination, filter, orden) => {
               console.log(`record, e`, record, pagination, filter, orden);
               const orderFields = {tipoC: 'ru.Tipo', grupoServiciosC:'gser.Nombre', folioOrdenC:'osp.FolioOrden', 
               ubicacionC: 'af.CodigoArea', nombreActivoC: 'ace.Descripcion', fechaC: 'fre.Fecha'}

               record.listaReporteExistenciasAgrupado = listaReporteExistenciasAgrupado;
               record.listaReporteExistenciasAgrupadoCantidad = listaReporteExistenciasAgrupadoCantidad;
               if(orden) {
                    const field = orden.columnKey ? orderFields[orden.columnKey] : null;
                    const orderField = orden.order === 'descend' ? 'DESC': 'ASC';
                    const sorter = {field: field, order: orderField}
                    this.setState({orden: sorter})
                    console.log(`sorter`, sorter)
                    return onGetReporte(formularioReporte, pagination.current, sorter, record );
               }

               onGetReporte(formularioReporte, pagination.current, order, record);
          };

          const ordenamientoGeneral = (value, tipo) => {
               console.log(`value`, value); console.log('tipo', tipo)
               if(tipo === 'field'){
                    handleOrden(value.target.value, order.order)
               }
               else{
                    handleOrden(order.field, value.target.value)
               }
          };

          console.log('listaReporteExistenciasAgrupado en módulo', listaReporteExistenciasAgrupado)

          return (
               <>
                    <Row justify='space-between' gutter={[8,8]}>
                         <Col span={3}>
                         <Row justify='space-around' gutter={[8,8]}>
                              <Col span={12}>
                                   <Tooltip title='Exportar archivo .pdf' placement="top">
                                        <Button disabled={listaReporteExistenciasCantidad <= 0 ? true : !permisosUsuario.exportarPdfReporteDinamicoRecursosHumanos} className="iconSecundary" shape='circle' icon={<FilePdfFilled /> } loading={fetchingReporteExistenciasPDF || fetchingReporteDinamicoAgrupadoPDF} onClick={()=> descargarReporte('pdf')}/>
                                   </Tooltip>
                              </Col>
                              <Col span={12}>
                                   <Tooltip title='Exportar archivo .xls' placement="top">
                                        <Button disabled={listaReporteExistenciasCantidad <= 0 ? true : !permisosUsuario.exportarExcelReporteDinamicoRecursosHumanos} className="iconSecundary" shape='circle' icon={<FileExcelFilled /> } loading={fetchingReporteExistenciasEXCEL || fetchingReporteDinamicoAgrupadoEXCEL} onClick={()=> descargarReporte('excel')}/>
                                   </Tooltip>
                              </Col>
                         </Row>
                         </Col>
                         <Col style={{ marginTop: '.4em' }}>
                         <Radio.Group defaultValue='SinAgrupar' onChange={onAgrupar}  size="small">
                              <Radio.Button value="almacen">Almacén</Radio.Button>
                              <Radio.Button value="servicio">Servicio</Radio.Button>
                              <Radio.Button value="familia">Familias</Radio.Button>
                              <Radio.Button value="subfamilia">Subfamilias</Radio.Button>
                              <Radio.Button value="tipoDeFolios">Tipo de Folio</Radio.Button>
                              <Radio.Button value='SinAgrupar'>Sin Agrupamiento</Radio.Button>
                         </Radio.Group>
                         </Col>
                    </Row>
                    <Row>
                         <Col span={24}>
                         <Row style={{ paddingTop: '20px' }}>
                              <Col span={24}>
                                   { this.state.agrupado !== 'SinAgrupar' ? (
                                        <Table
                                             size='small'
                                             rowKey='id'
                                             columns={columnsAgrupamiento}
                                             dataSource={listaReporteExistenciasAgrupado}
                                             expandable={{expandedRowRender: record => 
                                             <Table 
                                                  rowKey="id" 
                                                  columns={columns}
                                                  loading={fetchingListaReporteExistencias} 
                                                  onChange={(e,u,i) => onChangeAgrupado(record, e, u, i)} 
                                                  dataSource={record.agrupados.data} 
                                                  pagination={{ total: record.agrupados.cantidad, simple: true, current: record.agrupados.page }} 
                                                  scroll={scroll}
                                             /> 
                                             } }
                                             loading={fetchingReporteExistenciasAgrupado}
                                             locale={{ emptyText: 'Sin datos' }}
                                             onChange={(a,b,c) => onHandleTableChange(a,b,c, true)}
                                             pagination={{ total: listaReporteExistenciasAgrupadoCantidad, simple: true, current: state.page }}
                                             scroll={scroll}
                                        />
                                   ):  (
                                        <Table
                                             size='small'
                                             rowKey='id'
                                             columns={columns}
                                             dataSource={listaReporteExistencias}
                                             loading={fetchingListaReporteExistencias}
                                             locale={{ emptyText: 'Sin datos' }}
                                             onChange={onHandleTableChange}
                                             scroll={scroll}
                                             pagination={{ total: listaReporteExistenciasCantidad, simple: true, current: state.page }}
                                        />
                                   ) }
                              </Col>
                         </Row>
                         </Col>
                    </Row>
                    <Row justify='start'>
                         <Col span={6}>
                         <Button className="btnFiltrar" onClick={() => onHandleSteps('atras')} style={{ width: '100%', marginTop: '20px' }}>Anterior</Button>
                         </Col>
                    </Row>
               </>
          );
     }
}

const mapStateToProps = state => {
     return {
          fetchingListaReporteExistencias: state.AlmacenesReducer.fetchingListaReporteExistencias,
          listaReporteExistencias: state.AlmacenesReducer.listaReporteExistencias,
          listaReporteExistenciasCantidad: state.AlmacenesReducer.listaReporteExistenciasCantidad,

          fetchingReporteExistenciasEXCEL: state.AlmacenesReducer.fetchingReporteExistenciasEXCEL,
          fetchingReporteExistenciasPDF: state.AlmacenesReducer.fetchingReporteExistenciasPDF,
          fetchingReporteDinamicoAgrupadoEXCEL: state.ServiciosPlanificadosReducer.fetchingReporteDinamicoAgrupadoEXCEL,
          fetchingReporteDinamicoAgrupadoPDF: state.ServiciosPlanificadosReducer.fetchingReporteDinamicoAgrupadoPDF,

          formularioReporte: state.AlmacenesReducer.formularioReporte,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,

          listaReporteExistenciasAgrupado: state.AlmacenesReducer.listaReporteExistenciasAgrupado,
          listaReporteExistenciasAgrupadoCantidad: state.AlmacenesReducer.listaReporteExistenciasAgrupadoCantidad,
          fetchingReporteExistenciasAgrupado: state.AlmacenesReducer.fetchingReporteExistenciasAgrupado,
     }
}

const mapDispatchToProps = dispatch => {
     return {
          onDescargarReportePDF: (formulario, order) => {
               dispatch({ type: 'PDF_REPORTE_EXISTENCIAS_REQUEST', formulario, order});
          },
          onDescargarReporteExcel: (formulario, order, tituloDocumento) => {
               dispatch({ type: 'EXCEL_REPORTE_EXISTENCIAS_REQUEST', formulario, order, tituloDocumento});
          },
          onDescargarReporteAgrupadoPDF: (formulario, checkboxData, agrupado, order, tituloDocumento) => {
               dispatch({ type: 'PDF_REPORTE_DINAMICO_AGRUPADO_SP_REQUEST', formulario, checkboxData, agrupado, order, tituloDocumento });
          },
          onDescargarReporteAgrupadoExcel: (formulario, checkboxData, agrupado, order, tituloDocumento) => {
               dispatch({ type: 'EXCEL_REPORTE_DINAMICO_AGRUPADO_SP_REQUEST', formulario, checkboxData, agrupado, order, tituloDocumento });
          },

          onGetReporte: (formulario, page, order, agrupado) => {
               dispatch({ type: 'LISTA_REPORTE_EXISTENCIAS_REQUEST', formulario, page, order, agrupado});
          },
          onGetReporteAgrupado: (formulario, page, agrupado, order) => {
               dispatch({ type: 'LISTA_REPORTE_EXISTENCIAS_AGRUPADO_REQUEST', formulario, page, agrupado, order});
          },
          onCleanFamilias: () => {
               dispatch({ type: 'LISTA_FAMILIAS_ACTIVAS_SERVICIO_SUCCESS', listaFamiliasActivasServ: [] })
          },
          onCleanSubfamiliasPorServicio: () => {
               dispatch({ type: 'LISTA_SUBFAMILIAS_POR_SERVICIO_SUCCESS', listaSubfamiliasServicio: [] });
          },
          onCleanServiciosAlmacen: () => {
               dispatch({ type: 'LISTA_SERVICIOS_ALMACEN_SUCCESS', listaServiciosbyAlmacen: [] })
          },

     }
}

export default connect (mapStateToProps, mapDispatchToProps) (TableSection);