import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoDominio from './ModalNuevoDominio';
import ModalEditarDominio from './ModalEditarDominio';

const confirm = Modal.confirm;
const { Search } = Input;
class CatalogoDeDominios extends React.Component {
    componentDidMount(){
        this.props.onGetDominios(1, '');
    }
    state={
        page: 1,
        busqueda: '',
    }
    render() {
        const { fetchingListaDominiosBusqueda ,listaDominiosBusqueda, onEstadoDominio, onGetDominios, 
            onModalEditarDominio, onModalNuevoDominio, listaDominiosBusquedaCantidad
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        const onBuscarDominio = busqueda => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            onGetDominios(1, busqueda);
        }
        
        const columns = [{
                title: 'Nombre dominio',
                dataIndex: 'dominioCompleto',
                key: 'dominioCompleto',
            },{
                title: 'Fecha alta',
                dataIndex: 'fechaAlta',
                key: 'fechaAlta',
            },{
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.dominioActivoInactivo} onChange={() => handleDesactivar(record)}/>
                ),
            },{
                title: 'Editar',
                key: 'editar',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarDominio} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },
        ];
        const onEditar = (key) => {
            console.log(key);
            onModalEditarDominio(key, this.state.page);
        }
        const handleDesactivar = (key) => {
            console.log(key);
            const page = this.state.page;
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del dominio "'+ key.dominio +'"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoDominio(key.idDominio, page)
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onGetDominios(pagination.current, this.state.busqueda);
		}
        const onNuevoDominio = () => {
            onModalNuevoDominio(this.state.page);
        }

        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search className='buscarModulos' placeholder="Ingresa el nombre del dominio" onSearch={value => onBuscarDominio(value)} style={{ width: '100%' }}/>
                    </Col>
                    {/* <Col span={5}>
                        <Button onClick={onNuevoDominio} disabled={!permisosUsuario.crearDominio} className='nuevoUsuario'>Nuevo Dominio</Button>
                    </Col> */}

                    <Col span={1}>
                        <Tooltip title='Nuevo Dominio' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoDominio} disabled={!permisosUsuario.crearDominio} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="dominioCompleto" 
                            columns={columns}
                            dataSource={listaDominiosBusqueda}
                            loading={fetchingListaDominiosBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaDominiosBusquedaCantidad, simple: true, current: this.state.page}}
                        />         
                    </Col>
                </Row>
                <ModalNuevoDominio/>
                <ModalEditarDominio/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        catalogoSeleccionado: state.InformacionOperativaReducer.catalogoSeleccionado,
        listaDominiosBusqueda: state.InformacionOperativaReducer.listaDominiosBusqueda,
        fetchingListaDominiosBusqueda: state.InformacionOperativaReducer.fetchingListaDominiosBusqueda,
        listaDominios: state.ConfiguracionYSeguridadReducer.listaDominios,
        fetchingListaDominios: state.ConfiguracionYSeguridadReducer.fetchingListaDominios,
        listaDominiosBusquedaCantidad: state.InformacionOperativaReducer.listaDominiosBusquedaCantidad,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetDominios: (page, busqueda) => {
	    	dispatch({ type: "LISTA_DOMINIOS_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
        },
        onEstadoDominio: (idDominio, page) => {
	    	dispatch({ type: "ESTADO_DOMINIO_REQUEST", idDominio: idDominio, page: page });
        },
        onModalNuevoDominio: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_DOMINIO", showModalNuevoDominio: true, page: page});
        },
        onModalEditarDominio: (dominioSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_DOMINIO", showModalEditarDominio: true, dominioSeleccionado: dominioSeleccionado, page: page});
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoDeDominios);