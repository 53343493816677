import { takeLatest, call, put } from "redux-saga/effects";
import * as recursosHumanosActions from '../actions/RecursosHumanosActions';
import * as configuracionYSeguridadActions from '../actions/ConfiguracionYSeguridadActions'
import {
    listaPersonalBusquedaCall, cambiarEstadoPersonalCall, descargarExcelPersonalCall, nuevoPersonalCall, editarPersonalCall, descargarPDFCompletoPersonalCall, descargarPDFReducidoPersonalCall, historialPersonalCall, listaPersonalServicioCall,
    listaControlAsistenciasBusquedaCall, listaControlAsistenciasExportarCall, importarBiometricoCall,

    reporteDinamicoRHCall, reporteDinamicoPDFRHCall, reporteDinamicoExcelRHCall,
    listaGrupoServiciosByIdUsuarioCall, listaGerenciasByIdUsuarioCall, reporteDinamicoPDFAgrupadoRHCall, reporteDinamicoExcelAgrupadoRHCall, reporteDinamicoRHAgrupadoCall, crearRegistroReporteRHCall, getAllRegistrosRHCall, getRegistrosByTokenRHCall, estadoRegistroReporteRHCall, editarRegistroReporteRHCall
} from '../../services/RecursosHumanosServices';
import avatarPerfil from '../../assets/fotoPerfil.png';
import moment from 'moment';

// Alta de Personal
function* listaAltaPersonalBusqueda(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let param = '';
        let servicio = '';
        let field = 'field=nombre&';
        let order = 'order=ASC&';
        let page = action.page - 1; 
        let estado = 'estado=true&';

        if(action.busqueda.estado !== undefined){
            estado = 'estado=' + action.busqueda.estado + '&';
        }
        if(action.busqueda.param){
            param = 'param=' + action.busqueda.param + '&';
        }
        if(action.busqueda.servicio){
            servicio = 'servicio=' + action.busqueda.servicio + '&';
        }
        if(action.orden.field){
            field = 'field=' + action.orden.field + '&';
        }
        if(action.orden.order){
            order = 'order=' + action.orden.order + '&';
        }
        console.log(param, servicio, field, order, page)
        const response = yield call(listaPersonalBusquedaCall, token, param, servicio, field, order, estado, page)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const listaPersonalBusqueda = response.data.data;
            const listaPersonalBusquedaCantidad = response.data.cantidad;
            for(let i=0; i<listaPersonalBusqueda.length; i++){
                listaPersonalBusqueda[i].fechaIngreso = moment(listaPersonalBusqueda[i].fechaIngreso.split('T')[0], 'YYYY-MM-DD')
                if(listaPersonalBusqueda[i].servicioByIdServicio){
                    listaPersonalBusqueda[i].servicio = listaPersonalBusqueda[i].servicioByIdServicio.nombre;
                }
                else{
                    listaPersonalBusqueda[i].servicio = 'No tiene servicio';
                }
                
                listaPersonalBusqueda[i].diasLaboralesLista = listaPersonalBusqueda[i].diasLaborales.split(', ');

                if(listaPersonalBusqueda[i].horarioEntrada && listaPersonalBusqueda[i].horarioSalida){
                    listaPersonalBusqueda[i].horarioEntradaForm = moment(listaPersonalBusqueda[i].horarioEntrada, 'HH:mm:ss');
                    listaPersonalBusqueda[i].horarioSalidaForm = moment(listaPersonalBusqueda[i].horarioSalida, 'HH:mm:ss');
                    listaPersonalBusqueda[i].turnoHorario = listaPersonalBusqueda[i].diasLaborales + ' | ' + listaPersonalBusqueda[i].horarioEntrada.split(':')[0] + ':' + listaPersonalBusqueda[i].horarioEntrada.split(':')[1] + '-' + listaPersonalBusqueda[i].horarioSalida.split(':')[0] + ':' + listaPersonalBusqueda[i].horarioSalida.split(':')[1]
                }
                else{
                    listaPersonalBusqueda[i].horarioEntradaForm = null;
                    listaPersonalBusqueda[i].horarioSalidaForm = null;
                    listaPersonalBusqueda[i].turnoHorario = listaPersonalBusqueda[i].diasLaborales + ' | ' + 'Horario libre';
                }

                if(listaPersonalBusqueda[i].proveedorByIdProveedor){
                    listaPersonalBusqueda[i].proveedor = listaPersonalBusqueda[i].proveedorByIdProveedor.razonSocial;
                }
                else{
                    listaPersonalBusqueda[i].proveedor = 'No tiene proveedor';
                }
                listaPersonalBusqueda[i].fotoGrid = listaPersonalBusqueda[i].foto
                if(listaPersonalBusqueda[i].foto === ''){
                    listaPersonalBusqueda[i].fotoGrid = avatarPerfil;
                }
            }
            
            yield put({ type: recursosHumanosActions.LISTA_PERSONAL_BUSQUEDA_SUCCESS, listaPersonalBusqueda, listaPersonalBusquedaCantidad, busqueda: action.busqueda, orden: action.orden });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: recursosHumanosActions.LISTA_PERSONAL_BUSQUEDA_FAILURE })
        }
    } catch (error) {
        yield put({ type: recursosHumanosActions.LISTA_PERSONAL_BUSQUEDA_FAILURE})
    }
}
export function* listaAltaPersonalBusquedaSaga(){
    yield takeLatest(recursosHumanosActions.LISTA_PERSONAL_BUSQUEDA_REQUEST, listaAltaPersonalBusqueda)
}

function* listaPersonalServicio(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let param = action.param;
        let idServicio = action.idServicio;
        console.log(param, idServicio)
        const response = yield call(listaPersonalServicioCall, token, param, idServicio)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const listaPersonalServicio = response.data.data;
            for(let i in listaPersonalServicio){
                listaPersonalServicio[i].nombreCompleto = listaPersonalServicio[i].aMaterno ? listaPersonalServicio[i].nombre + ' ' +listaPersonalServicio[i].aPaterno + ' ' + listaPersonalServicio[i].aMaterno : listaPersonalServicio[i].nombre + ' ' +listaPersonalServicio[i].aPaterno;
            }
            
            yield put({ type: recursosHumanosActions.LISTA_PERSONAL_SERVICIO_SUCCESS, listaPersonalServicio });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: recursosHumanosActions.LISTA_PERSONAL_SERVICIO_FAILURE })
        }
    } catch (error) {
        yield put({ type: recursosHumanosActions.LISTA_PERSONAL_SERVICIO_FAILURE})
    }
}
export function* listaPersonalServicioSaga(){
    yield takeLatest(recursosHumanosActions.LISTA_PERSONAL_SERVICIO_REQUEST, listaPersonalServicio)
}

function* cambiarEstadoPersonal(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idPersonal = action.idPersonal;
        let mensaje = '';
        if(action.formulario){
            mensaje = action.formulario.mensaje;
        }
        let data = {
            'message': mensaje,
            'code':  '',
            'id': ''
        }
        console.log(data)
        const response = yield call(cambiarEstadoPersonalCall, token, idPersonal, data)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "El estado del personal cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: recursosHumanosActions.CAMBIAR_ESTADO_PERSONAL_SUCCESS });
            yield put({ type: recursosHumanosActions.LISTA_PERSONAL_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda, orden: action.orden });
            if(action.formulario){
                yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'estadoPersonal' });
            }
            
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: recursosHumanosActions.CAMBIAR_ESTADO_PERSONAL_FAILURE })
        }
    } catch (error) {
        yield put({ type: recursosHumanosActions.CAMBIAR_ESTADO_PERSONAL_FAILURE})
    }
}
export function* cambiarEstadoPersonalSaga(){
    yield takeLatest(recursosHumanosActions.CAMBIAR_ESTADO_PERSONAL_REQUEST, cambiarEstadoPersonal)
}

function* descargarExcelPersonal(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let param = '';
        let servicio = '';
        let estado = 'true&'
        let field = 'field=nombre&';
        let order = 'order=ASC&';

        if(action.busqueda.estado){
            estado = action.busqueda.estado + '&';
        }
        if(action.busqueda.param){
            param = 'param=' + action.busqueda.param + '&';
        }
        if(action.busqueda.servicio){
            servicio = 'servicio=' + action.busqueda.servicio + '&';
        }
        if(action.orden.field){
            field = 'field=' + action.orden.field + '&';
        }
        if(action.orden.order){
            order = 'order=' + action.orden.order + '&';
        }
        console.log(param, servicio, estado, field, order)
        const response = yield call(descargarExcelPersonalCall, token, param, servicio, estado, field, order)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se descargó el archivo de forma exitosa";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Personal_ " + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + "_" + new Date().getHours() + "_" + new Date().getMinutes() + "_" + new Date().getSeconds() + ".csv";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: recursosHumanosActions.DESCARGAR_EXCEL_PERSONAL_SUCCESS });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: recursosHumanosActions.DESCARGAR_EXCEL_PERSONAL_FAILURE })
        }
    } catch (error) {
        yield put({ type: recursosHumanosActions.DESCARGAR_EXCEL_PERSONAL_FAILURE})
    }
}
export function* descargarExcelPersonalSaga(){
    yield takeLatest(recursosHumanosActions.DESCARGAR_EXCEL_PERSONAL_REQUEST, descargarExcelPersonal)
}

function* descargarPDFPersonal(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let param = '';
        let servicio = '';
        let estado = 'true&'
        let field = 'field=nombre&';
        let order = 'order=ASC&';

        if(action.busqueda.estado){
            estado = action.busqueda.estado + '&';
        }
        if(action.busqueda.param){
            param = 'param=' + action.busqueda.param + '&';
        }
        if(action.busqueda.servicio){
            servicio = 'servicio=' + action.busqueda.servicio + '&';
        }
        if(action.orden.field){
            field = 'field=' + action.orden.field + '&';
        }
        if(action.orden.order){
            order = 'order=' + action.orden.order + '&';
        }
        console.log(param, servicio, estado, field, order)
        let response = null;
        if(action.tipo === 'completo'){
            response = yield call(descargarPDFCompletoPersonalCall, token, param, servicio, estado, field, order)
        }
        else{
            response = yield call(descargarPDFReducidoPersonalCall, token, param, servicio, estado, field, order)
        }
        
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se descargó el archivo de forma exitosa";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Personal_ " + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + "_" + new Date().getHours() + "_" + new Date().getMinutes() + "_" + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: recursosHumanosActions.DESCARGAR_PDF_PERSONAL_SUCCESS });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: recursosHumanosActions.DESCARGAR_PDF_PERSONAL_FAILURE })
        }
    } catch (error) {
        yield put({ type: recursosHumanosActions.DESCARGAR_PDF_PERSONAL_FAILURE})
    }
}
export function* descargarPDFPersonalSaga(){
    yield takeLatest(recursosHumanosActions.DESCARGAR_PDF_PERSONAL_REQUEST, descargarPDFPersonal)
}

function* listaHistorialPersonal(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let fechaInicio = '';
        let fechaFin = '';
        const idPersonal = action.idPersonal;
        const page = action.page - 1;

        if(action.formulario.fechaInicio){
            fechaInicio = '&fechaInicio=' + action.formulario.fechaInicio ;
        }
        if(action.formulario.fechaFin){
            fechaFin = '&fechaFin=' + action.formulario.fechaFin;
        }
        console.log(fechaFin, fechaInicio, idPersonal)
        const response = yield call(historialPersonalCall, token, idPersonal, fechaInicio, fechaFin, page)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const listaHistorialPersonal = response.data.data;
            const listaHistorialPersonalCantidad = response.data.cantidad;
            for(let i=0; i<listaHistorialPersonal.length; i++){
                listaHistorialPersonal[i].fechaIngreso = moment(listaHistorialPersonal[i].fechaIngreso.split('T')[0], 'YYYY-MM-DD')
                if(listaHistorialPersonal[i].servicioByIdServicio){
                    listaHistorialPersonal[i].servicio = listaHistorialPersonal[i].servicioByIdServicio.nombre;
                }
                else{
                    listaHistorialPersonal[i].servicio = 'No tiene servicio';
                }
                
                listaHistorialPersonal[i].diasLaboralesLista = listaHistorialPersonal[i].diasLaborales.split(',');
                listaHistorialPersonal[i].diasLaborales = listaHistorialPersonal[i].diasLaborales.split(',').join(', ');

                if(listaHistorialPersonal[i].horarioEntrada && listaHistorialPersonal[i].horarioSalida){
                    listaHistorialPersonal[i].horarioEntradaForm = moment(listaHistorialPersonal[i].horarioEntrada, 'HH:mm:ss');
                    listaHistorialPersonal[i].horarioSalidaForm = moment(listaHistorialPersonal[i].horarioSalida, 'HH:mm:ss');
                    listaHistorialPersonal[i].turnoHorario = listaHistorialPersonal[i].diasLaborales + ' | ' + listaHistorialPersonal[i].horarioEntrada.split(':')[0] + ':' + listaHistorialPersonal[i].horarioEntrada.split(':')[1] + '-' + listaHistorialPersonal[i].horarioSalida.split(':')[0] + ':' + listaHistorialPersonal[i].horarioSalida.split(':')[1]
                }
                else{
                    listaHistorialPersonal[i].horarioEntradaForm = null;
                    listaHistorialPersonal[i].horarioSalidaForm = null;
                    listaHistorialPersonal[i].turnoHorario = listaHistorialPersonal[i].diasLaborales + ' | ' + 'Horario libre';
                }

                if(listaHistorialPersonal[i].proveedorByIdProveedor){
                    listaHistorialPersonal[i].proveedor = listaHistorialPersonal[i].proveedorByIdProveedor.razonSocial;
                }
                else{
                    listaHistorialPersonal[i].proveedor = 'No tiene proveedor';
                }
                listaHistorialPersonal[i].fotoGrid = listaHistorialPersonal[i].foto
                if(listaHistorialPersonal[i].foto === ''){
                    listaHistorialPersonal[i].fotoGrid = avatarPerfil;
                }

                listaHistorialPersonal[i].fechaAlta = listaHistorialPersonal[i].fechaAlta.split('T')[0].split('-').reverse().join('-') + ' ' + listaHistorialPersonal[i].fechaAlta.split('T')[1].split('.')[0].split(':')[0] + ':' + listaHistorialPersonal[i].fechaAlta.split('T')[1].split('.')[0].split(':')[1];
                listaHistorialPersonal[i].usuarioEdito = listaHistorialPersonal[i].usuarioByIdusuario.nombre + ' ' + listaHistorialPersonal[i].usuarioByIdusuario.aPaterno;
            }
            
            yield put({ type: recursosHumanosActions.HISTORIAL_PERSONAL_SUCCESS, listaHistorialPersonal, listaHistorialPersonalCantidad });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: recursosHumanosActions.HISTORIAL_PERSONAL_FAILURE })
        }
    } catch (error) {
        yield put({ type: recursosHumanosActions.HISTORIAL_PERSONAL_FAILURE})
    }
}
export function* listaHistorialPersonalSaga(){
    yield takeLatest(recursosHumanosActions.HISTORIAL_PERSONAL_REQUEST, listaHistorialPersonal)
}

function* nuevoPersonal(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let aMaterno = '';
        if(action.formulario.aMaterno){
            aMaterno = action.formulario.aMaterno;
        }
        const diasLaboralesLista = action.formulario.diasLaborales.sort();
        console.log('diasLaboralesLista');
        console.log(diasLaboralesLista);
        let diasLaborales = '';
        for(let i=0; i<diasLaboralesLista.length; i++){
            if(diasLaboralesLista[i] === '1'){
                if(diasLaboralesLista.length - 1 === i){
                    diasLaborales += 'Domingo'
                }
                else{
                    diasLaborales += 'Domingo, '
                }
            }
            else if(diasLaboralesLista[i] === '2'){
                if(diasLaboralesLista.length - 1 === i){
                    diasLaborales += 'Lunes';
                }
                else{
                    diasLaborales += 'Lunes, ';
                }
            }
            else if(diasLaboralesLista[i] === '3'){
                if(diasLaboralesLista.length - 1 === i){
                    diasLaborales += 'Martes';
                }
                else{
                    diasLaborales += 'Martes, ';
                }
            }
            else if(diasLaboralesLista[i] === '4'){
                if(diasLaboralesLista.length - 1 === i){
                    diasLaborales += 'Miércoles';
                }
                else{
                    diasLaborales += 'Miércoles, ';
                }
            }
            else if(diasLaboralesLista[i] === '5'){
                if(diasLaboralesLista.length - 1 === i){
                    diasLaborales += 'Jueves';
                }
                else{
                    diasLaborales += 'Jueves, ';
                }
            }
            else if(diasLaboralesLista[i] === '6'){
                if(diasLaboralesLista.length - 1 === i){
                    diasLaborales += 'Viernes';
                }
                else{
                    diasLaborales += 'Viernes, ';
                }
            }
            else{
                if(diasLaboralesLista.length - 1 === i){
                    diasLaborales += 'Sábado';
                }
                else{
                    diasLaborales += 'Sábado, ';
                }
            }
        }
        console.log('diasLaborales');
        console.log(diasLaborales);

        const data = {
            "idPersonal": null,
            'rfc': action.formulario.rfc,
            'razonBaja': '',
            'folioID': action.formulario.folioID,
            "nombre": action.formulario.nombre,
            "aPaterno": action.formulario.aPaterno,
            "aMaterno": aMaterno,
            "numeroEmpleado": action.formulario.numeroEmpleado,
            "correo": action.formulario.correo,
            "puesto": action.formulario.puesto,
            "idProveedor": action.formulario.proveedor,
            "idServicio": action.formulario.servicio,
            "fechaIngreso": action.formulario.fechaIngreso,
            "idTurno": action.formulario.turno,
            "idExpediente": null,
            'idEmpleadoBiometrico': action.formulario.idEmpleadoBiometrico,
            "foto": "",
            'turno': '',
            "tipoTurno": action.formulario.tipoHorario,
            "diasLaborales": diasLaborales,
            "horarioEntrada": action.formulario.horarioEntrada,
            "horarioSalida": action.formulario.horarioSalida,
            'salidaComida': null,
            'regresoComida': null,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }
        console.log('data');
        console.log(data);
        const formData = new FormData();
        if(action.formulario.foto){
            formData.append('file', action.formulario.foto.file.originFileObj);
        }
        
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        const response = yield call(nuevoPersonalCall, token, formData)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se creó el personal de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: recursosHumanosActions.NUEVO_PERSONAL_SUCCESS });
            yield put({ type: recursosHumanosActions.SHOW_MODAL_NUEVO_PERSONAL, showModalNuevoPersonal: false });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearPersonal' });
            yield put({ type: recursosHumanosActions.LISTA_PERSONAL_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda, orden: action.orden });

            yield put({ type: recursosHumanosActions.VISTA_NUEVO_PERSONAL, vistaNuevoPersonal: 1 });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: recursosHumanosActions.NUEVO_PERSONAL_FAILURE })
        }
    } catch (error) {
        yield put({ type: recursosHumanosActions.NUEVO_PERSONAL_FAILURE})
    }
}
export function* nuevoPersonalSaga(){
    yield takeLatest(recursosHumanosActions.NUEVO_PERSONAL_REQUEST, nuevoPersonal)
}
function* editarPersonal(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let aMaterno = '';
        let turno = action.formulario.turno;
        let servicio = action.formulario.servicio;
        let proveedor = action.formulario.proveedor;
        
        let diasLaboralesLista = []; 
        for(let i=0; i<action.formulario.diasLaboralesLista.length; i++){
            if(action.formulario.diasLaboralesLista[i] === 'Domingo'){
                diasLaboralesLista.push('1');
            }
            else if(action.formulario.diasLaboralesLista[i] === 'Lunes'){
                diasLaboralesLista.push('2');
            }
            else if(action.formulario.diasLaboralesLista[i] === 'Martes'){
                diasLaboralesLista.push('3');
            }
            else if(action.formulario.diasLaboralesLista[i] === 'Miércoles'){
                diasLaboralesLista.push('4');
            }
            else if(action.formulario.diasLaboralesLista[i] === 'Jueves'){
                diasLaboralesLista.push('5');
            }
            else if(action.formulario.diasLaboralesLista[i] === 'Viernes'){
                diasLaboralesLista.push('6');
            }
            else{
                diasLaboralesLista.push('7');
            }
        }
        diasLaboralesLista = diasLaboralesLista.sort();
        console.log('diasLaboralesLista');
        console.log(diasLaboralesLista);
        let diasLaborales = '';
        for(let i=0; i<diasLaboralesLista.length; i++){
            console.log('diasLaboralesLista[i]');
            console.log(diasLaboralesLista[i]);
            console.log('i');
            console.log(i, diasLaboralesLista.length);
            if(diasLaboralesLista[i] === '1'){
                if(diasLaboralesLista.length - 1 === i){
                    diasLaborales += 'Domingo'
                }
                else{
                    diasLaborales += 'Domingo, '
                }
            }
            else if(diasLaboralesLista[i] === '2'){
                if(diasLaboralesLista.length - 1 === i){
                    diasLaborales += 'Lunes';
                }
                else{
                    diasLaborales += 'Lunes, ';
                }
            }
            else if(diasLaboralesLista[i] === '3'){
                if(diasLaboralesLista.length  - 1 === i){
                    diasLaborales += 'Martes';
                }
                else{
                    diasLaborales += 'Martes, ';
                }
            }
            else if(diasLaboralesLista[i] === '4'){
                if(diasLaboralesLista.length  - 1  === i){
                    diasLaborales += 'Miércoles';
                }
                else{
                    diasLaborales += 'Miércoles, ';
                }
            }
            else if(diasLaboralesLista[i] === '5'){
                if(diasLaboralesLista.length - 1 === i){
                    diasLaborales += 'Jueves';
                }
                else{
                    diasLaborales += 'Jueves, ';
                }
            }
            else if(diasLaboralesLista[i] === '6'){
                if(diasLaboralesLista.length - 1 === i){
                    diasLaborales += 'Viernes';
                }
                else{
                    diasLaborales += 'Viernes, ';
                }
            }
            else if(diasLaboralesLista[i] === '7'){
                if(diasLaboralesLista.length - 1 === i){
                    diasLaborales += 'Sábado';
                }
                else{
                    diasLaborales += 'Sábado, ';
                }
            }
        }
        console.log('diasLaborales');
        console.log(diasLaborales);

        if(isNaN(servicio)){
            servicio = action.personalSeleccionado.idServicio;
        }
        if(isNaN(proveedor)){
            proveedor = action.personalSeleccionado.idProveedor;
        }
        if(isNaN(turno)){
            turno = action.personalSeleccionado.idTurno;
        }
        if(action.formulario.aMaterno){
            aMaterno = action.formulario.aMaterno;
        }
        let idPersonal = action.personalSeleccionado.idPersonal;
        const data = {
            "idPersonal": idPersonal,
            'rfc': action.formulario.rfc,
            'folioID': action.formulario.folioID,
            'razonBaja': action.personalSeleccionado.razonBaja,
            "nombre": action.formulario.nombre,
            "aPaterno": action.formulario.aPaterno,
            "aMaterno": aMaterno,
            "numeroEmpleado": action.formulario.numeroEmpleado,
            "correo": action.formulario.correo,
            "puesto": action.formulario.puesto,
            "idProveedor": proveedor,
            "idServicio": servicio,
            "fechaIngreso": action.formulario.fechaIngreso,
            "idTurno": turno,
            "idExpediente": action.personalSeleccionado.idExpediente,
            "foto": action.personalSeleccionado.foto,
            'idEmpleadoBiometrico': action.formulario.idEmpleadoBiometrico,
            "turno": action.personalSeleccionado.turno,
            "tipoTurno": action.formulario.tipoTurno,
            "diasLaborales": diasLaborales,
            "horarioEntrada": action.formulario.horarioEntrada,
            "horarioSalida": action.formulario.horarioSalida,
            "salidaComida": null,
            "regresoComida": null,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": action.personalSeleccionado.activo,
        }
        console.log('data');
        console.log(data);
        const formData = new FormData();
        if(action.formulario.foto.file){
            formData.append('file', action.formulario.foto.file.originFileObj);
        }
        
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        const response = yield call(editarPersonalCall, token, idPersonal, formData)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se editó el personal '" + action.formulario.nombre + "' de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: recursosHumanosActions.EDITAR_PERSONAL_SUCCESS });
            yield put({ type: recursosHumanosActions.SHOW_MODAL_EDITAR_PERSONAL, showModalNuevoPersonal: false });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarPersonal' });
            yield put({ type: recursosHumanosActions.LISTA_PERSONAL_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda, orden: action.orden });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: recursosHumanosActions.EDITAR_PERSONAL_FAILURE })
        }
    } catch (error) {
        yield put({ type: recursosHumanosActions.EDITAR_PERSONAL_FAILURE})
    }
}
export function* editarPersonalSaga(){
    yield takeLatest(recursosHumanosActions.EDITAR_PERSONAL_REQUEST, editarPersonal)
}

// Control de Asistencia
function* listaCotrolAsistenciaBusqueda(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let param = '';
        let servicio = '';
        let fechaInicio = '';
        let fechaFin = '';
        let page = action.page - 1; 
        let field = action.filtros.field;
        let order = action.filtros.order;

        if(action.busqueda.param){
            param = 'param=' + action.busqueda.param + '&';
        }
        if(action.busqueda.servicio){
            servicio = 'servicio=' + action.busqueda.servicio + '&';
        }
        if(action.busqueda.rangoFechas){
            fechaInicio = 'fechaInicio=' + action.busqueda.rangoFechas.split(',')[0] + '&';
            fechaFin = 'fechaFin=' + action.busqueda.rangoFechas.split(',')[1] + '&';
        }
        console.log(param, servicio, fechaInicio, fechaFin, page)
        const response = yield call(listaControlAsistenciasBusquedaCall, token, param, servicio, fechaInicio, fechaFin, field, order, page)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const listaControlAsistenciaBusqueda = response.data.data;
            const listaControlAsistenciaBusquedaCantidad = response.data.cantidad;
            for(let i=0; i<listaControlAsistenciaBusqueda.length; i++){
                //listaControlAsistenciaBusqueda[i].fechaIngreso = listaControlAsistenciaBusqueda[i].personalByIdPersonal.fechaIngreso.split('T')[0].split('-').reverse().join('-');
                if(listaControlAsistenciaBusqueda[i].servicioNombre){
                    
                }
                else{
                    
                }
                if(!listaControlAsistenciaBusqueda[i].diasLaborales){
                    listaControlAsistenciaBusqueda[i].turnoHorario = 'El ID Biométrico no está dado de alta en el sistema';
                    listaControlAsistenciaBusqueda[i].servicio = 'El ID Biométrico no está dado de alta en el sistema';
                    listaControlAsistenciaBusqueda[i].numeroEmpleado = 'El ID Biométrico no está dado de alta en el sistema';
                    listaControlAsistenciaBusqueda[i].nombreCompleto = 'El ID Biométrico no está dado de alta en el sistema';
                    listaControlAsistenciaBusqueda[i].fotoGrid = 'El ID Biométrico no está dado de alta en el sistema';
                }
                else{
                    if(listaControlAsistenciaBusqueda[i].horarioEntrada && listaControlAsistenciaBusqueda[i].horarioSalida){
                        listaControlAsistenciaBusqueda[i].turnoHorario = listaControlAsistenciaBusqueda[i].diasLaborales + ' | ' + listaControlAsistenciaBusqueda[i].horarioEntrada.split(':')[0] + ':' + listaControlAsistenciaBusqueda[i].horarioEntrada.split(':')[1] + '-' + listaControlAsistenciaBusqueda[i].horarioSalida.split(':')[0] + ':' + listaControlAsistenciaBusqueda[i].horarioSalida.split(':')[1];
                    }
                    else{
                        listaControlAsistenciaBusqueda[i].turnoHorario = listaControlAsistenciaBusqueda[i].diasLaborales + ' | ' + 'Horario libre';
                    }
                    listaControlAsistenciaBusqueda[i].nombreCompleto = listaControlAsistenciaBusqueda[i].nombre + ' ' + listaControlAsistenciaBusqueda[i].apellidoPaterno;

                    if(listaControlAsistenciaBusqueda[i].aMaterno){
                        listaControlAsistenciaBusqueda[i].nombreCompleto += ' ' + listaControlAsistenciaBusqueda[i].apellidoMaterno
                    }

                    if(listaControlAsistenciaBusqueda[i].foto === ''){
                        listaControlAsistenciaBusqueda[i].fotoGrid = avatarPerfil;
                    }
                    listaControlAsistenciaBusqueda[i].servicio = listaControlAsistenciaBusqueda[i].servicioNombre;
                }
                
                listaControlAsistenciaBusqueda[i].idEmpleadoBiometrico = listaControlAsistenciaBusqueda[i].idBiometrico;

                if(listaControlAsistenciaBusqueda[i].tipoRegistro === 2){
                    listaControlAsistenciaBusqueda[i].tipoDeRegistro = 'Entrada';
                }
                else{
                    listaControlAsistenciaBusqueda[i].tipoDeRegistro = 'Salida';
                }

                listaControlAsistenciaBusqueda[i].fechaRegistro = listaControlAsistenciaBusqueda[i].fechaRegistro.split('T')[0].split('-').reverse().join('-') + ' ' + listaControlAsistenciaBusqueda[i].fechaRegistro.split('T')[1].split('.')[0].split(':')[0] + ':' + listaControlAsistenciaBusqueda[i].fechaRegistro.split('T')[1].split('.')[0].split(':')[1];
            }
            
            yield put({ type: recursosHumanosActions.LISTA_CONTROL_ASISTENCIA_BUSQUEDA_SUCCESS, listaControlAsistenciaBusqueda, listaControlAsistenciaBusquedaCantidad });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: recursosHumanosActions.LISTA_CONTROL_ASISTENCIA_BUSQUEDA_FAILURE })
        }
    } catch (error) {
        yield put({ type: recursosHumanosActions.LISTA_CONTROL_ASISTENCIA_BUSQUEDA_FAILURE})
    }
}
export function* listaCotrolAsistenciaBusquedaSaga(){
    yield takeLatest(recursosHumanosActions.LISTA_CONTROL_ASISTENCIA_BUSQUEDA_REQUEST, listaCotrolAsistenciaBusqueda)
}

function* listaCotrolAsistenciaExportar(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let param = '';
        let servicio = '';
        let fechaInicio = '';
        let fechaFin = '';
        let field = action.filtros.field;
        let order = action.filtros.order;

        if(action.busqueda.param){
            param = 'param=' + action.busqueda.param + '&';
        }
        if(action.busqueda.servicio){
            servicio = 'servicio=' + action.busqueda.servicio + '&';
        }
        if(action.busqueda.rangoFechas){
            fechaInicio = 'fechaInicio=' + action.busqueda.rangoFechas.split(',')[0] + '&';
            fechaFin = 'fechaFin=' + action.busqueda.rangoFechas.split(',')[1] + '&';
        }
        console.log(param, servicio, fechaInicio, fechaFin)
        const response = yield call(listaControlAsistenciasExportarCall, token, param, servicio, fechaInicio, fechaFin, field, order)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se descargó el archivo de forma exitosa";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Asistencias_ " + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + "_" + new Date().getHours() + "_" + new Date().getMinutes() + "_" + new Date().getSeconds() + ".csv";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: recursosHumanosActions.LISTA_CONTROL_ASISTENCIA_EXPORTAR_SUCCESS });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: recursosHumanosActions.LISTA_CONTROL_ASISTENCIA_EXPORTAR_FAILURE })
        }
    } catch (error) {
        yield put({ type: recursosHumanosActions.LISTA_CONTROL_ASISTENCIA_EXPORTAR_FAILURE})
    }
}
export function* listaCotrolAsistenciaExportarSaga(){
    yield takeLatest(recursosHumanosActions.LISTA_CONTROL_ASISTENCIA_EXPORTAR_REQUEST, listaCotrolAsistenciaExportar)
}

function* importarBiometrico(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        
        const response = yield call(importarBiometricoCall, token)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            // const showSuccessMsg = true;
            // const textMessage = "Se importaron los datos del biométrico de forma correcta";

            //yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: recursosHumanosActions.IMPORTAR_BIOMETRICO_SUCCESS });
            yield put({ type: recursosHumanosActions.LISTA_CONTROL_ASISTENCIA_BUSQUEDA_REQUEST, busqueda: action.busqueda, filtros: action.filtros, page: action.page });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: recursosHumanosActions.IMPORTAR_BIOMETRICO_FAILURE })
        }
    } catch (error) {
        yield put({ type: recursosHumanosActions.IMPORTAR_BIOMETRICO_FAILURE})
    }
}
export function* importarBiometricoSaga(){
    yield takeLatest(recursosHumanosActions.IMPORTAR_BIOMETRICO_REQUEST, importarBiometrico)
}

// Reporte Dinámico
function* listaReporteDinamicoRH(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idsProveedores = [];
        let idsServicios = [];
        let idsGerencias = [];
        let idsGruposServicios = [];

        if(action.formulario.IdsGerencias){
            for(let i in action.formulario.IdsGerencias){
                idsGerencias.push(action.formulario.IdsGerencias[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsGruposServicios){
            for(let i in action.formulario.idsGruposServicios){
                idsGruposServicios.push(action.formulario.idsGruposServicios[i].split(' - ')[0]);
            }
        }

        if(action.formulario.IdsServicios){
            for(let i in action.formulario.IdsServicios){
                idsServicios.push(action.formulario.IdsServicios[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsProveedores){
            for(let i in action.formulario.idsProveedores){
                idsProveedores.push(action.formulario.idsProveedores[i].split(' - ')[0]);
            }
        }

        const data = {
            "idsGerencias": action.formulario.IdsGerencias ? idsGerencias : null,
            "idsGruposServicios": action.formulario.idsGruposServicios ? idsGruposServicios : null,
            "idsProveedores": action.formulario.tipoAgrupado === 'Por proveedor' ? [action.agrupado.id] : action.formulario.idsProveedores ? idsProveedores : null,
            "idsServicios": action.formulario.tipoAgrupado === 'Por servicio' ? [action.agrupado.id] : action.formulario.IdsServicios ? idsServicios : null,
            "fechaIngresoInicio": action.formulario.fechaIngreso ? action.formulario.fechaIngreso[0].format('YYYY-MM-DD') : null,
            "fechaIngresoFin": action.formulario.fechaIngreso ? action.formulario.fechaIngreso[1].format('YYYY-MM-DD') : null,
            "tipoTurno": action.formulario.tipoHorario ? action.formulario.tipoHorario : null,
            "horarioEntrada": action.formulario.horarioEntrada ? action.formulario.horarioEntrada.format('HH:mm:ss') : null,
            "page": (action.page - 1) * 10,
            "offset": 10,
            "order": action.order.order,
            "field": action.order.field,
            "horarioSalida": action.formulario.horarioSalida ? action.formulario.horarioSalida.format('HH:mm:ss') : null,
            "salidaComida": action.formulario.salidaComida ? action.formulario.salidaComida.format('HH:mm:ss') : null,
            "regresoComida": action.formulario.regresoComida ? action.formulario.regresoComida.format('HH:mm:ss') : null,
            "activo": action.formulario.activo ? action.formulario.activo : null,
            "folioC": action.checkboxData.folioC ? action.checkboxData.folioC : null,
            "numeroEmpleadoC": action.checkboxData.numeroEmpleadoC ? action.checkboxData.numeroEmpleadoC : false,
            "nombreC": action.checkboxData.nombreC ? action.checkboxData.nombreC : false,
            "aPaternoC": action.checkboxData.aPaternoC ? action.checkboxData.aPaternoC : false,
            "aMaternoC": action.checkboxData.aMaternoC ? action.checkboxData.aMaternoC : false,
            "rfcC": action.checkboxData.rfcC ? action.checkboxData.rfcC : false,
            "folioIDC": action.checkboxData.folioIDC ? action.checkboxData.folioIDC : false,
            "nombreProveedorC": action.checkboxData.nombreProveedorC ? action.checkboxData.nombreProveedorC : false,
            "nombreServicioC": action.checkboxData.nombreServicioC ? action.checkboxData.nombreServicioC : false,
            "correoC":action.checkboxData.correoC ? action.checkboxData.correoC : false,
            "puestoC":action.checkboxData.puestoC ? action.checkboxData.puestoC : false,
            "fechaIngresoC":action.checkboxData.fechaIngresoC ? action.checkboxData.fechaIngresoC : false,
            "tipoTurnoC":action.checkboxData.tipoTurnoC ? action.checkboxData.tipoTurnoC : false,
            "diasLaboralesC":action.checkboxData.diasLaboralesC ? action.checkboxData.diasLaboralesC : false,
            "horarioEntradaC":action.checkboxData.horarioEntradaC ? action.checkboxData.horarioEntradaC : false,
            "horarioSalidaC":action.checkboxData.horarioSalidaC ? action.checkboxData.horarioSalidaC : false,
            "salidaComidaC":action.checkboxData.salidaComidaC ? action.checkboxData.salidaComidaC : false,
            "regresoComidaC":action.checkboxData.regresoComidaC ? action.checkboxData.regresoComidaC : false,
            "expedienteClaveC":action.checkboxData.expedienteClaveC ? action.checkboxData.expedienteClaveC : false,
            "activoC":action.checkboxData.activoC ? action.checkboxData.activoC : false,
            "razonBajaC":action.checkboxData.razonBajaC ? action.checkboxData.razonBajaC : false
        }
        console.log(`data`, data);

        const response = yield call(reporteDinamicoRHCall, token, data)
        console.log(response)

        if (response.status === 200) {
            const listaReporteDinamicoRH = response.data.data;
            const listaReporteDinamicoCantidadRH = response.data.cantidad;

            yield put({ type: recursosHumanosActions.LISTA_REPORTE_DINAMICO_RH_BUSQUEDA_SUCCESS, listaReporteDinamicoRH, listaReporteDinamicoCantidadRH })
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: recursosHumanosActions.LISTA_REPORTE_DINAMICO_RH_BUSQUEDA_FAILURE});
        }
    } catch (error) {
        yield put({type: recursosHumanosActions.LISTA_REPORTE_DINAMICO_RH_BUSQUEDA_FAILURE});
    }
}
export function* listaReporteDinamicoRHSaga() {
    yield takeLatest(recursosHumanosActions.LISTA_REPORTE_DINAMICO_RH_BUSQUEDA_REQUEST, listaReporteDinamicoRH)
}

function* exportarReporteDinamicoPDFRH(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idsProveedores = [];
        let idsServicios = [];
        let idsGerencias = [];
        let idsGruposServicios = [];

        if(action.formulario.IdsGerencias){
            for(let i in action.formulario.IdsGerencias){
                idsGerencias.push(action.formulario.IdsGerencias[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsGruposServicios){
            for(let i in action.formulario.idsGruposServicios){
                idsGruposServicios.push(action.formulario.idsGruposServicios[i].split(' - ')[0]);
            }
        }

        if(action.formulario.IdsServicios){
            for(let i in action.formulario.IdsServicios){
                idsServicios.push(action.formulario.IdsServicios[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsProveedores){
            for(let i in action.formulario.idsProveedores){
                idsProveedores.push(action.formulario.idsProveedores[i].split(' - ')[0]);
            }
        }

        let horarioEntrada = null;
        let horarioSalida = null;
        let salidaComida = null;
        let regresoComida = null;

        if(action.formulario.horarioEntrada){
            if(typeof action.formulario.horarioEntrada === "string"){
                horarioEntrada = action.formulario.horarioEntrada
            } else if(typeof action.formulario.horarioEntrada === "object"){
                horarioEntrada = action.formulario.horarioEntrada.format('HH:mm:ss')
            }
        }

        if(action.formulario.horarioSalida){
            if(typeof action.formulario.horarioSalida === "string"){
                horarioSalida = action.formulario.horarioSalida
            } else if(typeof action.formulario.horarioSalida === "object"){
                horarioSalida = action.formulario.horarioSalida.format('HH:mm:ss')
            }
        }

        if(action.formulario.salidaComida){
            if(typeof action.formulario.salidaComida === "string"){
                salidaComida = action.formulario.salidaComida
            } else if(typeof action.formulario.salidaComida === "object"){
                salidaComida = action.formulario.salidaComida.format('HH:mm:ss')
            }
        }

        if(action.formulario.regresoComida){
            if(typeof action.formulario.regresoComida === "string"){
                regresoComida = action.formulario.regresoComida
            } else if(typeof action.formulario.regresoComida === "object"){
                regresoComida = action.formulario.regresoComida.format('HH:mm:ss')
            }
        }

        console.log('action.formulario', action.formulario);
        console.log('algo', action.formulario.fechaIngreso ? action.formulario.fechaIngreso[0].format('YYYY-MM-DD') : null)

        const data = {
            "idsGerencias": action.formulario.IdsGerencias ? idsGerencias : null,
            "idsGruposServicios": action.formulario.idsGruposServicios ? idsGruposServicios : null,
            "idsProveedores": action.formulario.idsProveedores ? idsProveedores : null,
            "idsServicios": action.formulario.IdsServicios ? idsServicios : null,
            "fechaIngresoInicio": action.formulario.fechaIngreso ? action.formulario.fechaIngreso[0].format('YYYY-MM-DD') : null,
            "fechaIngresoFin": action.formulario.fechaIngreso ? action.formulario.fechaIngreso[1].format('YYYY-MM-DD') : null,
            "tipoTurno": action.formulario.tipoHorario ? action.formulario.tipoHorario : null,
            "horarioEntrada": horarioEntrada,
            "order": action.order.order,
            "field": action.order.field,
            "page": null,
            "offset": null,
            "horarioSalida": horarioSalida,
            "salidaComida": salidaComida,
            "regresoComida": regresoComida,
            "activo": action.formulario.activo ? action.formulario.activo : null,
            "folioC": action.checkboxData.folioC ? action.checkboxData.folioC : null,
            "numeroEmpleadoC": action.checkboxData.numeroEmpleadoC ? action.checkboxData.numeroEmpleadoC : false,
            "nombreC": action.checkboxData.nombreC ? action.checkboxData.nombreC : false,
            "aPaternoC": action.checkboxData.aPaternoC ? action.checkboxData.aPaternoC : false,
            "aMaternoC": action.checkboxData.aMaternoC ? action.checkboxData.aMaternoC : false,
            "rfcC": action.checkboxData.rfcC ? action.checkboxData.rfcC : false,
            "folioIDC": action.checkboxData.folioIDC ? action.checkboxData.folioIDC : false,
            "nombreProveedorC": action.checkboxData.nombreProveedorC ? action.checkboxData.nombreProveedorC : false,
            "nombreServicioC": action.checkboxData.nombreServicioC ? action.checkboxData.nombreServicioC : false,
            "correoC":action.checkboxData.correoC ? action.checkboxData.correoC : false,
            "puestoC":action.checkboxData.puestoC ? action.checkboxData.puestoC : false,
            "fechaIngresoC":action.checkboxData.fechaIngresoC ? action.checkboxData.fechaIngresoC : false,
            "tipoTurnoC":action.checkboxData.tipoTurnoC ? action.checkboxData.tipoTurnoC : false,
            "diasLaboralesC":action.checkboxData.diasLaboralesC ? action.checkboxData.diasLaboralesC : false,
            "horarioEntradaC":action.checkboxData.horarioEntradaC ? action.checkboxData.horarioEntradaC : false,
            "horarioSalidaC":action.checkboxData.horarioSalidaC ? action.checkboxData.horarioSalidaC : false,
            "salidaComidaC":action.checkboxData.salidaComidaC ? action.checkboxData.salidaComidaC : false,
            "regresoComidaC":action.checkboxData.regresoComidaC ? action.checkboxData.regresoComidaC : false,
            "expedienteClaveC":action.checkboxData.expedienteClaveC ? action.checkboxData.expedienteClaveC : false,
            "activoC":action.checkboxData.activoC ? action.checkboxData.activoC : false,
            "razonBajaC":action.checkboxData.razonBajaC ? action.checkboxData.razonBajaC : false
        }
        console.log(`data`, data);

        const response = yield call(reporteDinamicoPDFRHCall, token, data)
        console.log(response)

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const titulo = action.tituloDocumento ? action.tituloDocumento : 'ReporteRRHH_'
            link.download = titulo + + new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({type: recursosHumanosActions.PDF_REPORTE_DINAMICO_RH_SUCCESS})

            yield put({
                type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG,
                showSuccessMsg: true,
                textMessage: 'El archivo se descargó de forma correcta'
            });

        } else {
            const showErrorMsg = true;
            const textMessage = 'No se pudo descargar el PDF con las columnas y filtros seleccionados';

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: recursosHumanosActions.PDF_REPORTE_DINAMICO_RH_FAILURE});
        }
    } catch (error) {
        yield put({type: recursosHumanosActions.PDF_REPORTE_DINAMICO_RH_FAILURE});
    }
}
export function* exportarReporteDinamicoPDFRHSaga() {
    yield takeLatest(recursosHumanosActions.PDF_REPORTE_DINAMICO_RH_REQUEST, exportarReporteDinamicoPDFRH)
}

function* reporteDinamicoExcelRH(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idsProveedores = [];
        let idsServicios = [];
        let idsGerencias = [];
        let idsGruposServicios = [];

        if(action.formulario.IdsGerencias){
            for(let i in action.formulario.IdsGerencias){
                idsGerencias.push(action.formulario.IdsGerencias[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsGruposServicios){
            for(let i in action.formulario.idsGruposServicios){
                idsGruposServicios.push(action.formulario.idsGruposServicios[i].split(' - ')[0]);
            }
        }

        if(action.formulario.IdsServicios){
            for(let i in action.formulario.IdsServicios){
                idsServicios.push(action.formulario.IdsServicios[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsProveedores){
            for(let i in action.formulario.idsProveedores){
                idsProveedores.push(action.formulario.idsProveedores[i].split(' - ')[0]);
            }
        }

        let horarioEntrada = null;
        let horarioSalida = null;
        let salidaComida = null;
        let regresoComida = null;

        if(action.formulario.horarioEntrada){
            if(typeof action.formulario.horarioEntrada === "string"){
                horarioEntrada = action.formulario.horarioEntrada
            } else if(typeof action.formulario.horarioEntrada === "object"){
                horarioEntrada = action.formulario.horarioEntrada.format('HH:mm:ss')
            }
        }

        if(action.formulario.horarioSalida){
            if(typeof action.formulario.horarioSalida === "string"){
                horarioSalida = action.formulario.horarioSalida
            } else if(typeof action.formulario.horarioSalida === "object"){
                horarioSalida = action.formulario.horarioSalida.format('HH:mm:ss')
            }
        }

        if(action.formulario.salidaComida){
            if(typeof action.formulario.salidaComida === "string"){
                salidaComida = action.formulario.salidaComida
            } else if(typeof action.formulario.salidaComida === "object"){
                salidaComida = action.formulario.salidaComida.format('HH:mm:ss')
            }
        }

        if(action.formulario.regresoComida){
            if(typeof action.formulario.regresoComida === "string"){
                regresoComida = action.formulario.regresoComida
            } else if(typeof action.formulario.regresoComida === "object"){
                regresoComida = action.formulario.regresoComida.format('HH:mm:ss')
            }
        }

        const data = {
            "idsGerencias": action.formulario.IdsGerencias ? idsGerencias : null,
            "idsGruposServicios": action.formulario.idsGruposServicios ? idsGruposServicios : null,
            "idsProveedores": action.formulario.idsProveedores ? idsProveedores : null,
            "idsServicios": action.formulario.IdsServicios ? idsServicios : null,
            "fechaIngresoInicio": action.formulario.fechaIngreso ? action.formulario.fechaIngreso[0].format('YYYY-MM-DD') : null,
            "fechaIngresoFin": action.formulario.fechaIngreso ? action.formulario.fechaIngreso[1].format('YYYY-MM-DD') : null,
            "tipoTurno": action.formulario.tipoHorario ? action.formulario.tipoHorario : null,
            "horarioEntrada": horarioEntrada,
            "order": action.order.order,
            "field": action.order.field,
            "page": null,
            "offset": null,
            "horarioSalida": horarioSalida,
            "salidaComida": salidaComida,
            "regresoComida": regresoComida,
            "activo": action.formulario.activo ? action.formulario.activo : null,
            "folioC": action.checkboxData.folioC ? action.checkboxData.folioC : null,
            "numeroEmpleadoC": action.checkboxData.numeroEmpleadoC ? action.checkboxData.numeroEmpleadoC : false,
            "nombreC": action.checkboxData.nombreC ? action.checkboxData.nombreC : false,
            "aPaternoC": action.checkboxData.aPaternoC ? action.checkboxData.aPaternoC : false,
            "aMaternoC": action.checkboxData.aMaternoC ? action.checkboxData.aMaternoC : false,
            "rfcC": action.checkboxData.rfcC ? action.checkboxData.rfcC : false,
            "folioIDC": action.checkboxData.folioIDC ? action.checkboxData.folioIDC : false,
            "nombreProveedorC": action.checkboxData.nombreProveedorC ? action.checkboxData.nombreProveedorC : false,
            "nombreServicioC": action.checkboxData.nombreServicioC ? action.checkboxData.nombreServicioC : false,
            "correoC":action.checkboxData.correoC ? action.checkboxData.correoC : false,
            "puestoC":action.checkboxData.puestoC ? action.checkboxData.puestoC : false,
            "fechaIngresoC":action.checkboxData.fechaIngresoC ? action.checkboxData.fechaIngresoC : false,
            "tipoTurnoC":action.checkboxData.tipoTurnoC ? action.checkboxData.tipoTurnoC : false,
            "diasLaboralesC":action.checkboxData.diasLaboralesC ? action.checkboxData.diasLaboralesC : false,
            "horarioEntradaC":action.checkboxData.horarioEntradaC ? action.checkboxData.horarioEntradaC : false,
            "horarioSalidaC":action.checkboxData.horarioSalidaC ? action.checkboxData.horarioSalidaC : false,
            "salidaComidaC":action.checkboxData.salidaComidaC ? action.checkboxData.salidaComidaC : false,
            "regresoComidaC":action.checkboxData.regresoComidaC ? action.checkboxData.regresoComidaC : false,
            "expedienteClaveC":action.checkboxData.expedienteClaveC ? action.checkboxData.expedienteClaveC : false,
            "activoC":action.checkboxData.activoC ? action.checkboxData.activoC : false,
            "razonBajaC":action.checkboxData.razonBajaC ? action.checkboxData.razonBajaC : false
        }
        console.log(`data`, data);

        const response = yield call(reporteDinamicoExcelRHCall, token, data)
        console.log(response)

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const titulo = action.tituloDocumento ? action.tituloDocumento : 'ReporteRRHH_'
            link.download = titulo + new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: recursosHumanosActions.EXCEL_REPORTE_DINAMICO_RH_SUCCESS })
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage: 'El archivo se descargó de forma correcta' });

        } else {
            const showErrorMsg = true;
            const textMessage = 'No se pudo descargar el Excel con las columnas y filtros seleccionados';

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: recursosHumanosActions.EXCEL_REPORTE_DINAMICO_RH_FAILURE});
        }
    } catch (error) {
        yield put({type: recursosHumanosActions.EXCEL_REPORTE_DINAMICO_RH_FAILURE});
    }
}
export function* reporteDinamicoExcelRHSaga() {
    yield takeLatest(recursosHumanosActions.EXCEL_REPORTE_DINAMICO_RH_REQUEST, reporteDinamicoExcelRH)
}

function* listaGrupoServiciosByIdUsuario(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(listaGrupoServiciosByIdUsuarioCall, token, action.idUsuario);
        console.log(response);
        if(response.status === 200){
            let listaGrupoServiciosByIdUsuario = response.data;

            yield put({ type: recursosHumanosActions.LISTA_GRUPO_SERVICIOS_BYIDUSUARIO_SUCCESS, listaGrupoServiciosByIdUsuario });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: recursosHumanosActions.LISTA_GRUPO_SERVICIOS_BYIDUSUARIO_FAILURE });
        }
    } catch (error) {
        yield put({ type: recursosHumanosActions.LISTA_GRUPO_SERVICIOS_BYIDUSUARIO_FAILURE });
    }
} export function* listaGrupoServiciosByIdUsuarioSaga(){
    yield takeLatest(recursosHumanosActions.LISTA_GRUPO_SERVICIOS_BYIDUSUARIO_REQUEST, listaGrupoServiciosByIdUsuario)
}

function* listaGerenciasByIdUsuario(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(listaGerenciasByIdUsuarioCall, token, action.idUsuario);
        console.log(response);
        if(response.status === 200){
            let listaGerenciasByIdUsuario = response.data;

            yield put({ type: recursosHumanosActions.LISTA_GERENCIAS_BYIDUSUARIO_SUCCESS, listaGerenciasByIdUsuario });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: recursosHumanosActions.LISTA_GERENCIAS_BYIDUSUARIO_FAILURE });
        }
    } catch (error) {
        yield put({ type: recursosHumanosActions.LISTA_GERENCIAS_BYIDUSUARIO_FAILURE });
    }
} export function* listaGerenciasByIdUsuarioSaga(){
    yield takeLatest(recursosHumanosActions.LISTA_GERENCIAS_BYIDUSUARIO_REQUEST, listaGerenciasByIdUsuario)
}

function* exportarReporteDinamicoPDFAgrupadoRH(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idsProveedores = [];
        let idsServicios = [];
        let idsGerencias = [];
        let idsGruposServicios = [];

        if(action.formulario.IdsGerencias){
            for(let i in action.formulario.IdsGerencias){
                idsGerencias.push(action.formulario.IdsGerencias[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsGruposServicios){
            for(let i in action.formulario.idsGruposServicios){
                idsGruposServicios.push(action.formulario.idsGruposServicios[i].split(' - ')[0]);
            }
        }

        if(action.formulario.IdsServicios){
            for(let i in action.formulario.IdsServicios){
                idsServicios.push(action.formulario.IdsServicios[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsProveedores){
            for(let i in action.formulario.idsProveedores){
                idsProveedores.push(action.formulario.idsProveedores[i].split(' - ')[0]);
            }
        }

        let activo = "true";
        if(action.formulario.activo === 'Inactivo'){
            activo = 'false'
        } else if(action.formulario.activo === 'Activo'){
            activo = 'true'
        }

        let horarioEntrada = null;
        let horarioSalida = null;
        let salidaComida = null;
        let regresoComida = null;

        if(action.formulario.horarioEntrada){
            if(typeof action.formulario.horarioEntrada === "string"){
                horarioEntrada = action.formulario.horarioEntrada
            } else if(typeof action.formulario.horarioEntrada === "object"){
                horarioEntrada = action.formulario.horarioEntrada.format('HH:mm:ss')
            }
        }

        if(action.formulario.horarioSalida){
            if(typeof action.formulario.horarioSalida === "string"){
                horarioSalida = action.formulario.horarioSalida
            } else if(typeof action.formulario.horarioSalida === "object"){
                horarioSalida = action.formulario.horarioSalida.format('HH:mm:ss')
            }
        }

        if(action.formulario.salidaComida){
            if(typeof action.formulario.salidaComida === "string"){
                salidaComida = action.formulario.salidaComida
            } else if(typeof action.formulario.salidaComida === "object"){
                salidaComida = action.formulario.salidaComida.format('HH:mm:ss')
            }
        }

        if(action.formulario.regresoComida){
            if(typeof action.formulario.regresoComida === "string"){
                regresoComida = action.formulario.regresoComida
            } else if(typeof action.formulario.regresoComida === "object"){
                regresoComida = action.formulario.regresoComida.format('HH:mm:ss')
            }
        }

        console.log('idsServicios', idsServicios)

        const data = {
            "idsGerencias": action.formulario.IdsGerencias ? idsGerencias : null,
            "idsGruposServicios": action.formulario.idsGruposServicios ? idsGruposServicios : null,
            "idsProveedores": action.formulario.idsProveedores ? idsProveedores : null,
            "idsServicios": action.formulario.IdsServicios ? idsServicios : null,
            "fechaIngresoInicio": action.formulario.fechaIngreso ? action.formulario.fechaIngreso[0].format('YYYY-MM-DD') : null,
            "fechaIngresoFin": action.formulario.fechaIngreso ? action.formulario.fechaIngreso[1].format('YYYY-MM-DD') : null,
            "tipoTurno": action.formulario.tipoHorario ? action.formulario.tipoHorario : null,
            "horarioEntrada": horarioEntrada,
            "order": action.order.order,
            "field": action.order.field,
            "page": null,
            "offset": null,
            "horarioSalida": horarioSalida,
            "salidaComida": salidaComida,
            "regresoComida": regresoComida,
            "activo": action.formulario.activo ? activo : null,
            "folioC": action.checkboxData.folioC ? action.checkboxData.folioC : null,
            "numeroEmpleadoC": action.checkboxData.numeroEmpleadoC ? action.checkboxData.numeroEmpleadoC : false,
            "nombreC": action.checkboxData.nombreC ? action.checkboxData.nombreC : false,
            "aPaternoC": action.checkboxData.aPaternoC ? action.checkboxData.aPaternoC : false,
            "aMaternoC": action.checkboxData.aMaternoC ? action.checkboxData.aMaternoC : false,
            "rfcC": action.checkboxData.rfcC ? action.checkboxData.rfcC : false,
            "folioIDC": action.checkboxData.folioIDC ? action.checkboxData.folioIDC : false,
            "nombreProveedorC": action.checkboxData.nombreProveedorC ? action.checkboxData.nombreProveedorC : false,
            "nombreServicioC": action.checkboxData.nombreServicioC ? action.checkboxData.nombreServicioC : false,
            "correoC":action.checkboxData.correoC ? action.checkboxData.correoC : false,
            "puestoC":action.checkboxData.puestoC ? action.checkboxData.puestoC : false,
            "fechaIngresoC":action.checkboxData.fechaIngresoC ? action.checkboxData.fechaIngresoC : false,
            "tipoTurnoC":action.checkboxData.tipoTurnoC ? action.checkboxData.tipoTurnoC : false,
            "diasLaboralesC":action.checkboxData.diasLaboralesC ? action.checkboxData.diasLaboralesC : false,
            "horarioEntradaC":action.checkboxData.horarioEntradaC ? action.checkboxData.horarioEntradaC : false,
            "horarioSalidaC":action.checkboxData.horarioSalidaC ? action.checkboxData.horarioSalidaC : false,
            "salidaComidaC":action.checkboxData.salidaComidaC ? action.checkboxData.salidaComidaC : false,
            "regresoComidaC":action.checkboxData.regresoComidaC ? action.checkboxData.regresoComidaC : false,
            "expedienteClaveC":action.checkboxData.expedienteClaveC ? action.checkboxData.expedienteClaveC : false,
            "activoC":action.checkboxData.activoC ? action.checkboxData.activoC : false,
            "razonBajaC":action.checkboxData.razonBajaC ? action.checkboxData.razonBajaC : false,
            "tipoAgrupado": action.agrupado
        }
        console.log(`data`, data);

        const response = yield call(reporteDinamicoPDFAgrupadoRHCall, token, data)
        console.log(response)

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const titulo = action.tituloDocumento ? action.tituloDocumento : 'ReporteRRHH_'
            link.download = titulo + + new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({type: recursosHumanosActions.PDF_REPORTE_DINAMICO_RH_AGRUPADO_SUCCESS})

            yield put({
                type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG,
                showSuccessMsg: true,
                textMessage: 'El archivo se descargó de forma correcta'
            });

        } else {
            const showErrorMsg = true;
            const textMessage = 'No se pudo descargar el PDF con las columnas y filtros seleccionados';

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: recursosHumanosActions.PDF_REPORTE_DINAMICO_RH_AGRUPADO_FAILURE});
        }
    } catch (error) {
        yield put({type: recursosHumanosActions.PDF_REPORTE_DINAMICO_RH_AGRUPADO_FAILURE});
    }
}
export function* exportarReporteDinamicoPDFAgrupadoRHSaga() {
    yield takeLatest(recursosHumanosActions.PDF_REPORTE_DINAMICO_RH_AGRUPADO_REQUEST, exportarReporteDinamicoPDFAgrupadoRH)
}

function* reporteDinamicoExcelAgrupadoRH(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idsProveedores = [];
        let idsServicios = [];
        let idsGerencias = [];
        let idsGruposServicios = [];

        if(action.formulario.IdsGerencias){
            for(let i in action.formulario.IdsGerencias){
                idsGerencias.push(action.formulario.IdsGerencias[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsGruposServicios){
            for(let i in action.formulario.idsGruposServicios){
                idsGruposServicios.push(action.formulario.idsGruposServicios[i].split(' - ')[0]);
            }
        }

        if(action.formulario.IdsServicios){
            for(let i in action.formulario.IdsServicios){
                idsServicios.push(action.formulario.IdsServicios[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsProveedores){
            for(let i in action.formulario.idsProveedores){
                idsProveedores.push(action.formulario.idsProveedores[i].split(' - ')[0]);
            }
        }

        let activo = "true";
        if(action.formulario.activo === 'Inactivo'){
            activo = 'false'
        } else if(action.formulario.activo === 'Activo'){
            activo = 'true'
        }

        let horarioEntrada = null;
        let horarioSalida = null;
        let salidaComida = null;
        let regresoComida = null;

        if(action.formulario.horarioEntrada){
            if(typeof action.formulario.horarioEntrada === "string"){
                horarioEntrada = action.formulario.horarioEntrada
            } else if(typeof action.formulario.horarioEntrada === "object"){
                horarioEntrada = action.formulario.horarioEntrada.format('HH:mm:ss')
            }
        }

        if(action.formulario.horarioSalida){
            if(typeof action.formulario.horarioSalida === "string"){
                horarioSalida = action.formulario.horarioSalida
            } else if(typeof action.formulario.horarioSalida === "object"){
                horarioSalida = action.formulario.horarioSalida.format('HH:mm:ss')
            }
        }

        if(action.formulario.salidaComida){
            if(typeof action.formulario.salidaComida === "string"){
                salidaComida = action.formulario.salidaComida
            } else if(typeof action.formulario.salidaComida === "object"){
                salidaComida = action.formulario.salidaComida.format('HH:mm:ss')
            }
        }

        if(action.formulario.regresoComida){
            if(typeof action.formulario.regresoComida === "string"){
                regresoComida = action.formulario.regresoComida
            } else if(typeof action.formulario.regresoComida === "object"){
                regresoComida = action.formulario.regresoComida.format('HH:mm:ss')
            }
        }

        const data = {
            "idsGerencias": action.formulario.IdsGerencias ? idsGerencias : null,
            "idsGruposServicios": action.formulario.idsGruposServicios ? idsGruposServicios : null,
            "idsProveedores": action.formulario.idsProveedores ? idsProveedores : null,
            "idsServicios": action.formulario.IdsServicios ? idsServicios : null,
            "fechaIngresoInicio": action.formulario.fechaIngreso ? action.formulario.fechaIngreso[0].format('YYYY-MM-DD') : null,
            "fechaIngresoFin": action.formulario.fechaIngreso ? action.formulario.fechaIngreso[1].format('YYYY-MM-DD') : null,
            "tipoTurno": action.formulario.tipoHorario ? action.formulario.tipoHorario : null,
            "horarioEntrada": horarioEntrada,
            "order": action.order.order,
            "field": action.order.field,
            "page": null,
            "offset": null,
            "horarioSalida": horarioSalida,
            "salidaComida": salidaComida,
            "regresoComida": regresoComida,
            "activo": action.formulario.activo ? activo : null,
            "folioC": action.checkboxData.folioC ? action.checkboxData.folioC : null,
            "numeroEmpleadoC": action.checkboxData.numeroEmpleadoC ? action.checkboxData.numeroEmpleadoC : false,
            "nombreC": action.checkboxData.nombreC ? action.checkboxData.nombreC : false,
            "aPaternoC": action.checkboxData.aPaternoC ? action.checkboxData.aPaternoC : false,
            "aMaternoC": action.checkboxData.aMaternoC ? action.checkboxData.aMaternoC : false,
            "rfcC": action.checkboxData.rfcC ? action.checkboxData.rfcC : false,
            "folioIDC": action.checkboxData.folioIDC ? action.checkboxData.folioIDC : false,
            "nombreProveedorC": action.checkboxData.nombreProveedorC ? action.checkboxData.nombreProveedorC : false,
            "nombreServicioC": action.checkboxData.nombreServicioC ? action.checkboxData.nombreServicioC : false,
            "correoC":action.checkboxData.correoC ? action.checkboxData.correoC : false,
            "puestoC":action.checkboxData.puestoC ? action.checkboxData.puestoC : false,
            "fechaIngresoC":action.checkboxData.fechaIngresoC ? action.checkboxData.fechaIngresoC : false,
            "tipoTurnoC":action.checkboxData.tipoTurnoC ? action.checkboxData.tipoTurnoC : false,
            "diasLaboralesC":action.checkboxData.diasLaboralesC ? action.checkboxData.diasLaboralesC : false,
            "horarioEntradaC":action.checkboxData.horarioEntradaC ? action.checkboxData.horarioEntradaC : false,
            "horarioSalidaC":action.checkboxData.horarioSalidaC ? action.checkboxData.horarioSalidaC : false,
            "salidaComidaC":action.checkboxData.salidaComidaC ? action.checkboxData.salidaComidaC : false,
            "regresoComidaC":action.checkboxData.regresoComidaC ? action.checkboxData.regresoComidaC : false,
            "expedienteClaveC":action.checkboxData.expedienteClaveC ? action.checkboxData.expedienteClaveC : false,
            "activoC":action.checkboxData.activoC ? action.checkboxData.activoC : false,
            "razonBajaC":action.checkboxData.razonBajaC ? action.checkboxData.razonBajaC : false,
            "tipoAgrupado": action.agrupado
        }
        console.log(`data`, data);

        const response = yield call(reporteDinamicoExcelAgrupadoRHCall, token, data)
        console.log(response)

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const titulo = action.tituloDocumento ? action.tituloDocumento : 'ReporteRRHH_'
            link.download = titulo + new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: recursosHumanosActions.EXCEL_REPORTE_DINAMICO_RH_AGRUPADO_SUCCESS })
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage: 'El archivo se descargó de forma correcta' });

        } else {
            const showErrorMsg = true;
            const textMessage = 'No se pudo descargar el Excel con las columnas y filtros seleccionados';

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: recursosHumanosActions.EXCEL_REPORTE_DINAMICO_RH_AGRUPADO_FAILURE});
        }
    } catch (error) {
        yield put({type: recursosHumanosActions.EXCEL_REPORTE_DINAMICO_RH_AGRUPADO_FAILURE});
    }
}
export function* reporteDinamicoExcelAgrupadoRHSaga() {
    yield takeLatest(recursosHumanosActions.EXCEL_REPORTE_DINAMICO_RH_AGRUPADO_REQUEST, reporteDinamicoExcelAgrupadoRH)
}


function* reporteDinamicoRHAgrupado(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        let idsProveedores = [];
        let idsServicios = [];
        let idsGerencias = [];
        let idsGruposServicios = [];

        if(action.formulario.IdsGerencias){
            for(let i in action.formulario.IdsGerencias){
                idsGerencias.push(action.formulario.IdsGerencias[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsGruposServicios){
            for(let i in action.formulario.idsGruposServicios){
                idsGruposServicios.push(action.formulario.idsGruposServicios[i].split(' - ')[0]);
            }
        }

        if(action.formulario.IdsServicios){
            for(let i in action.formulario.IdsServicios){
                idsServicios.push(action.formulario.IdsServicios[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsProveedores){
            for(let i in action.formulario.idsProveedores){
                idsProveedores.push(action.formulario.idsProveedores[i].split(' - ')[0]);
            }
        }
        

        const data = {
            "idsGerencias": action.formulario.IdsGerencias ? idsGerencias : null,
            "idsGruposServicios": action.formulario.idsGruposServicios ? idsGruposServicios : null,
            "idsProveedores": action.formulario.idsProveedores ? idsProveedores : null,
            "idsServicios": action.formulario.IdsServicios ? idsServicios : null,
            "fechaIngresoInicio": action.formulario.fechaIngreso ? action.formulario.fechaIngreso[0].format('YYYY-MM-DD') : null,
            "fechaIngresoFin": action.formulario.fechaIngreso ? action.formulario.fechaIngreso[1].format('YYYY-MM-DD') : null,
            "tipoTurno": action.formulario.tipoHorario ? action.formulario.tipoHorario : null,
            "horarioEntrada": action.formulario.horarioEntrada ? action.formulario.horarioEntrada.format('HH:mm:ss') : null,
            "order": action.order.order,
            "field": action.order.field,
            "page": (action.page - 1) * 10,
            "offset": 10,
            "horarioSalida": action.formulario.horarioSalida ? action.formulario.horarioSalida.format('HH:mm:ss') : null,
            "salidaComida": action.formulario.salidaComida ? action.formulario.salidaComida.format('HH:mm:ss') : null,
            "regresoComida": action.formulario.regresoComida ? action.formulario.regresoComida.format('HH:mm:ss') : null,
            "activo": action.formulario.activo ? action.formulario.activo : null,
            "folioC": action.checkboxData.folioC ? action.checkboxData.folioC : null,
            "numeroEmpleadoC": action.checkboxData.numeroEmpleadoC ? action.checkboxData.numeroEmpleadoC : false,
            "nombreC": action.checkboxData.nombreC ? action.checkboxData.nombreC : false,
            "aPaternoC": action.checkboxData.aPaternoC ? action.checkboxData.aPaternoC : false,
            "aMaternoC": action.checkboxData.aMaternoC ? action.checkboxData.aMaternoC : false,
            "rfcC": action.checkboxData.rfcC ? action.checkboxData.rfcC : false,
            "folioIDC": action.checkboxData.folioIDC ? action.checkboxData.folioIDC : false,
            "nombreProveedorC": action.checkboxData.nombreProveedorC ? action.checkboxData.nombreProveedorC : false,
            "nombreServicioC": action.checkboxData.nombreServicioC ? action.checkboxData.nombreServicioC : false,
            "correoC":action.checkboxData.correoC ? action.checkboxData.correoC : false,
            "puestoC":action.checkboxData.puestoC ? action.checkboxData.puestoC : false,
            "fechaIngresoC":action.checkboxData.fechaIngresoC ? action.checkboxData.fechaIngresoC : false,
            "tipoTurnoC":action.checkboxData.tipoTurnoC ? action.checkboxData.tipoTurnoC : false,
            "diasLaboralesC":action.checkboxData.diasLaboralesC ? action.checkboxData.diasLaboralesC : false,
            "horarioEntradaC":action.checkboxData.horarioEntradaC ? action.checkboxData.horarioEntradaC : false,
            "horarioSalidaC":action.checkboxData.horarioSalidaC ? action.checkboxData.horarioSalidaC : false,
            "salidaComidaC":action.checkboxData.salidaComidaC ? action.checkboxData.salidaComidaC : false,
            "regresoComidaC":action.checkboxData.regresoComidaC ? action.checkboxData.regresoComidaC : false,
            "expedienteClaveC":action.checkboxData.expedienteClaveC ? action.checkboxData.expedienteClaveC : false,
            "activoC":action.checkboxData.activoC ? action.checkboxData.activoC : false,
            "razonBajaC":action.checkboxData.razonBajaC ? action.checkboxData.razonBajaC : false,
            "tipoAgrupado": action.agrupado ? action.agrupado : action.formulario.tipoAgrupado,
        }
        console.log(`data`, data);

        const response = yield call(reporteDinamicoRHAgrupadoCall, token, data)
        console.log(response)

        if(response.status === 200){
            const listaReporteDinamicoAgrupadoRH = response.data.data;
            const listaReporteDinamicoAgrupadoCantidadRH = response.data.cantidad;
            const agrupadoConst = action.agrupado ? action.agrupado : action.formulario.tipoAgrupado;
            if(agrupadoConst === 'Por estado'){
                for(let i in listaReporteDinamicoAgrupadoRH){
                    listaReporteDinamicoAgrupadoRH[i].id = i
                }
            }
            if(agrupadoConst === 'Por gerencia'){
                for(let i in listaReporteDinamicoAgrupadoRH){
                    if(listaReporteDinamicoAgrupadoRH[i].id === 0){
                        listaReporteDinamicoAgrupadoRH[i].nombre = 'Sin gerencia asignada';
                    }
                }
            }

            for(let i in listaReporteDinamicoAgrupadoRH){
                const dataAgrupado = {
                    "idsGerencias": agrupadoConst === 'Por gerencia' ? listaReporteDinamicoAgrupadoRH[i].id === 0 ? [null] : [listaReporteDinamicoAgrupadoRH[i].id] : idsGerencias,
                    "idsGruposServicios": agrupadoConst === 'Por grupo de servicios' ? [listaReporteDinamicoAgrupadoRH[i].id] : (action.formulario.idsGruposServicios ? idsGruposServicios : null),
                    "idsServicios": agrupadoConst === 'Por servicios' ? [listaReporteDinamicoAgrupadoRH[i].id] : (action.formulario.IdsServicios ? idsServicios : null),
                    'estados': agrupadoConst === 'Por estado' ? [listaReporteDinamicoAgrupadoRH[i].id] : (action.formulario.estados ? action.formulario.estados : null),
                    "fechaIngresoInicio": action.formulario.fechaIngreso ? action.formulario.fechaIngreso[0].format('YYYY-MM-DD') : null,
                    "fechaIngresoFin": action.formulario.fechaIngreso ? action.formulario.fechaIngreso[1].format('YYYY-MM-DD') : null,
                    "tipoTurno": action.formulario.tipoHorario ? action.formulario.tipoHorario : null,
                    "horarioEntrada": action.formulario.horarioEntrada ? action.formulario.horarioEntrada.format('HH:mm:ss') : null,
                    "page": (action.page - 1) * 10,
                    "offset": 10,
                    "order": action.order.order,
                    "field": action.order.field,
                    "horarioSalida": action.formulario.horarioSalida ? action.formulario.horarioSalida.format('HH:mm:ss') : null,
                    "salidaComida": action.formulario.salidaComida ? action.formulario.salidaComida.format('HH:mm:ss') : null,
                    "regresoComida": action.formulario.regresoComida ? action.formulario.regresoComida.format('HH:mm:ss') : null,
                    "activo": action.formulario.activo ? action.formulario.activo : null,
                    "folioC": action.checkboxData.folioC ? action.checkboxData.folioC : null,
                    "numeroEmpleadoC": action.checkboxData.numeroEmpleadoC ? action.checkboxData.numeroEmpleadoC : false,
                    "nombreC": action.checkboxData.nombreC ? action.checkboxData.nombreC : false,
                    "aPaternoC": action.checkboxData.aPaternoC ? action.checkboxData.aPaternoC : false,
                    "aMaternoC": action.checkboxData.aMaternoC ? action.checkboxData.aMaternoC : false,
                    "rfcC": action.checkboxData.rfcC ? action.checkboxData.rfcC : false,
                    "folioIDC": action.checkboxData.folioIDC ? action.checkboxData.folioIDC : false,
                    "nombreProveedorC": action.checkboxData.nombreProveedorC ? action.checkboxData.nombreProveedorC : false,
                    "nombreServicioC": action.checkboxData.nombreServicioC ? action.checkboxData.nombreServicioC : false,
                    "correoC":action.checkboxData.correoC ? action.checkboxData.correoC : false,
                    "puestoC":action.checkboxData.puestoC ? action.checkboxData.puestoC : false,
                    "fechaIngresoC":action.checkboxData.fechaIngresoC ? action.checkboxData.fechaIngresoC : false,
                    "tipoTurnoC":action.checkboxData.tipoTurnoC ? action.checkboxData.tipoTurnoC : false,
                    "diasLaboralesC":action.checkboxData.diasLaboralesC ? action.checkboxData.diasLaboralesC : false,
                    "horarioEntradaC":action.checkboxData.horarioEntradaC ? action.checkboxData.horarioEntradaC : false,
                    "horarioSalidaC":action.checkboxData.horarioSalidaC ? action.checkboxData.horarioSalidaC : false,
                    "salidaComidaC":action.checkboxData.salidaComidaC ? action.checkboxData.salidaComidaC : false,
                    "regresoComidaC":action.checkboxData.regresoComidaC ? action.checkboxData.regresoComidaC : false,
                    "expedienteClaveC":action.checkboxData.expedienteClaveC ? action.checkboxData.expedienteClaveC : false,
                    "activoC":action.checkboxData.activoC ? action.checkboxData.activoC : false,
                    "razonBajaC":action.checkboxData.razonBajaC ? action.checkboxData.razonBajaC : false
                }
                console.log(`dataAgrupado`, dataAgrupado);

                const responseAgrupado = yield call(reporteDinamicoRHCall, token, dataAgrupado)
                console.log(`responseAgrupado`, responseAgrupado)
                if(responseAgrupado.status === 200){
                    listaReporteDinamicoAgrupadoRH[i].agrupados = responseAgrupado.data;
                    listaReporteDinamicoAgrupadoRH[i].agrupados.page = 1;
                    for(let x in listaReporteDinamicoAgrupadoRH[i].agrupados.data){
                        listaReporteDinamicoAgrupadoRH[i].agrupados.data[x].id = parseInt(x) + 1;
                    };
                }
                else{
                    const showErrorMsg = true;
                    const textMessage = responseAgrupado.message;

                    yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                    yield put({ type: recursosHumanosActions.REPORTE_DINAMICO_AGRUPADO_RH_FAILURE });
                }
            }
            yield put({ type: recursosHumanosActions.REPORTE_DINAMICO_AGRUPADO_RH_SUCCESS, listaReporteDinamicoAgrupadoRH, listaReporteDinamicoAgrupadoCantidadRH })

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: recursosHumanosActions.REPORTE_DINAMICO_AGRUPADO_RH_FAILURE });
        }
    } catch (error) {
        yield put({ type: recursosHumanosActions.REPORTE_DINAMICO_AGRUPADO_RH_FAILURE });
    }
}
export function* reporteDinamicoRHAgrupadoSaga(){
    yield takeLatest(recursosHumanosActions.REPORTE_DINAMICO_AGRUPADO_RH_REQUEST, reporteDinamicoRHAgrupado)
}

function* getAllRegistrosRH(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;

        const response = yield call(getAllRegistrosRHCall, token, page);
        console.log(response);
        if(response.status === 200){
            let allRegistros = response.data.data;
            let allRegistrosCantidad = response.data.cantidad;

            for(let i in allRegistros){
                allRegistros[i].usuario = allRegistros[i].usuarioByIdUsuario.nombre + ' ' + allRegistros[i].usuarioByIdUsuario.aPaterno + (allRegistros[i].usuarioByIdUsuario.aMaterno ? (' ' + allRegistros[i].usuarioByIdUsuario.aMaterno) : '');

                allRegistros[i].formulario = {
                    IdsGerencias: undefined,
                    IdsGruposServicios: undefined,
                    IdsServicios: undefined,
                    activo: undefined,
                    IdsProveedores: undefined,
                    fechaIngresoInicio: undefined,
                    fechaIngresoFin: undefined,
                    horarioEntrada: undefined,
                    horarioSalida: undefined,
                    salidaComida: undefined,
                    regresoComida: undefined,
                    tipoTurno: undefined,
                }
                allRegistros[i].checkbox = {
                    numeroEmpleadoC: false,
                    nombreC: false,
                    aPaternoC: false,
                    aMaternoC: false,
                    rfcC: false,
                    folioIDC: false,
                    nombreProveedorC: false,
                    nombreServicioC: false,
                    correoC: false,
                    puestoC: false,
                    fechaIngresoC: false,
                    tipoTurnoC: false,
                    diasLaboralesC: false,
                    horarioEntradaC: false,
                    horarioSalidaC: false,
                    salidaComidaC: false,
                    regresoComidaC: false,
                    expedienteClaveC: false,
                    activoC: false,
                    razonBajaC: false,
                }
                allRegistros[i].agrupado = false;
                allRegistros[i].valoresColumnasSplit = allRegistros[i].valoresColumnas.split(',');
                for (let x in allRegistros[i].valoresColumnasSplit) {
                    allRegistros[i].valoresColumnasSplit[x] = allRegistros[i].valoresColumnasSplit[x].charAt(0).toLowerCase() + allRegistros[i].valoresColumnasSplit[x].slice(1);
                    allRegistros[i].checkbox[allRegistros[i].valoresColumnasSplit[x]] = true
                }

                let tipoAgrupado = 'SinAgrupar';
                let order = 'ASC';
                let field = 'pr.idPersonal'

                for (let x in allRegistros[i].filtrosByIdReportePersonal) {
                    allRegistros[i].formulario.IdsGerencias = allRegistros[i].filtrosByIdReportePersonal[x].filtro === 'IdsGerencias' ? allRegistros[i].filtrosByIdReportePersonal[x].valor.split(',') : allRegistros[i].formulario.IdsGerencias;
                    allRegistros[i].formulario.IdsServicios = allRegistros[i].filtrosByIdReportePersonal[x].filtro === 'IdsServicios' ? allRegistros[i].filtrosByIdReportePersonal[x].valor.split(',') : allRegistros[i].formulario.IdsServicios;
                    allRegistros[i].formulario.IdsGruposServicios = allRegistros[i].filtrosByIdReportePersonal[x].filtro === 'IdsGruposServicios' ? allRegistros[i].filtrosByIdReportePersonal[x].valor.split(',') : allRegistros[i].formulario.IdsGruposServicios;
                    allRegistros[i].formulario.IdsProveedores = allRegistros[i].filtrosByIdReportePersonal[x].filtro === 'IdsProveedores' ? allRegistros[i].filtrosByIdReportePersonal[x].valor.split(',') : allRegistros[i].formulario.IdsProveedores;

                    allRegistros[i].formulario.horarioEntrada = allRegistros[i].filtrosByIdReportePersonal[x].filtro === 'HorarioEntrada' ? allRegistros[i].filtrosByIdReportePersonal[x].valor.split(',') : allRegistros[i].formulario.horarioEntrada;
                    allRegistros[i].formulario.horarioSalida = allRegistros[i].filtrosByIdReportePersonal[x].filtro === 'HorarioSalida' ? allRegistros[i].filtrosByIdReportePersonal[x].valor.split(',') : allRegistros[i].formulario.horarioSalida;
                    allRegistros[i].formulario.salidaComida = allRegistros[i].filtrosByIdReportePersonal[x].filtro === 'SalidaComida' ? allRegistros[i].filtrosByIdReportePersonal[x].valor.split(',') : allRegistros[i].formulario.salidaComida;
                    allRegistros[i].formulario.regresoComida = allRegistros[i].filtrosByIdReportePersonal[x].filtro === 'RegresoComida' ? allRegistros[i].filtrosByIdReportePersonal[x].valor.split(',') : allRegistros[i].formulario.regresoComida;
                    allRegistros[i].formulario.tipoTurno = allRegistros[i].filtrosByIdReportePersonal[x].filtro === 'TipoTurno' ? allRegistros[i].filtrosByIdReportePersonal[x].valor : allRegistros[i].formulario.tipoTurno;

                    if(allRegistros[i].filtrosByIdReportePersonal[x].filtro === 'FechaIngreso'){
                        if(allRegistros[i].filtrosByIdReportePersonal[x].valor.includes(',')){
                            allRegistros[i].formulario.fechaIngresoInicio = new Date (allRegistros[i].filtrosByIdReportePersonal[x].valor.split(',')[0]).toISOString().slice(0, 10);
                            allRegistros[i].formulario.fechaIngresoFin = new Date (allRegistros[i].filtrosByIdReportePersonal[x].valor.split(',')[1]).toISOString().slice(0, 10);
                        }
                    }

                    if(allRegistros[i].filtrosByIdReportePersonal[x].filtro === 'TipoAgrupado'){
                        tipoAgrupado = allRegistros[i].filtrosByIdReportePersonal[x].valor
                    }

                    if(allRegistros[i].filtrosByIdReportePersonal[x].filtro === 'Activo'){
                        if(allRegistros[i].filtrosByIdReportePersonal[x].valor === 'true'){
                            allRegistros[i].formulario.activo = "Activo"
                        } else {
                            allRegistros[i].formulario.activo = "Inactivo"
                        } 
                    }

                    if(allRegistros[i].filtrosByIdReportePersonal[x].filtro === 'Order'){
                        order = allRegistros[i].filtrosByIdReportePersonal[x].valor.split(' por el campo ')[0];
                        field = allRegistros[i].filtrosByIdReportePersonal[x].valor.split(' por el campo ')[1];
                    }
                }

                allRegistros[i].checkboxData = Object.entries(allRegistros[i].checkbox);
                let checkAll = true;
                allRegistros[i].listaFields = [];
                for (let x in allRegistros[i].checkboxData) {
                    if (!allRegistros[i].checkboxData[x][1]) {
                        checkAll = false
                    }
                    allRegistros[i][allRegistros[i].checkboxData[x][0]] = allRegistros[i].checkboxData[x][1];
                    allRegistros[i].checkboxData[x].push(
                        allRegistros[i].checkboxData[x][0] === 'numeroEmpleadoC' ? 'Número de Empleado' : (
                            allRegistros[i].checkboxData[x][0] === 'nombreC' ? 'Nombre' : (
                                allRegistros[i].checkboxData[x][0] === 'aPaternoC' ? 'Apellido Paterno' : (
                                    allRegistros[i].checkboxData[x][0] === 'aMaternoC' ? 'Apellido Materno' : (
                                        allRegistros[i].checkboxData[x][0] === 'rfcC' ? 'RFC' : (
                                            allRegistros[i].checkboxData[x][0] === 'folioIDC' ? 'Folio ID' : (
                                                allRegistros[i].checkboxData[x][0] === 'nombreServicioC' ? 'Servicio' : (
                                                    allRegistros[i].checkboxData[x][0] === 'nombreProveedorC' ? 'Provedor' : (
                                                        allRegistros[i].checkboxData[x][0] === 'correoC' ? 'Correo' : (
                                                            allRegistros[i].checkboxData[x][0] === 'puestoC' ? 'Puesto' : (
                                                                allRegistros[i].checkboxData[x][0] === 'fechaIngresoC' ? 'Fecha de Ingreso' : (
                                                                    allRegistros[i].checkboxData[x][0] === 'tipoTurnoC' ? 'Tipo de Turno' : (
                                                                        allRegistros[i].checkboxData[x][0] === 'diasLaboralesC' ? 'Días Laborales' : (
                                                                            allRegistros[i].checkboxData[x][0] === 'horarioEntradaC' ? 'Horario Entrada' : (
                                                                                allRegistros[i].checkboxData[x][0] === 'horarioSalidaC' ? 'Horario Salida' : (
                                                                                    allRegistros[i].checkboxData[x][0] === 'salidaComidaC' ? 'Salida Comida' : (
                                                                                        allRegistros[i].checkboxData[x][0] === 'regresoComidaC' ? 'Regreso Comida' : (
                                                                                            allRegistros[i].checkboxData[x][0] === 'expedienteClaveC' ? 'Clave de Expediente' : (
                                                                                                allRegistros[i].checkboxData[x][0] === 'activoC' ? 'Condición' : (
                                                                                                    allRegistros[i].checkboxData[x][0] === 'razonBajaC' ? 'Razón de Baja' : false
                                                                                                )
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                }

                allRegistros[i].checkAll = checkAll;
                allRegistros[i].tipoAgrupado = tipoAgrupado;
                allRegistros[i].order = order;
                allRegistros[i].field = field;
            }

            console.log('allRegistros', allRegistros)

            yield put({ type: recursosHumanosActions.GET_ALL_REGISTROS_SUCCESS, allRegistros, allRegistrosCantidad });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: recursosHumanosActions.GET_ALL_REGISTROS_FAILURE });
        }
    } catch (error) {
        yield put({ type: recursosHumanosActions.GET_ALL_REGISTROS_FAILURE });
    }
} export function* getAllRegistrosRHSaga(){
    yield takeLatest(recursosHumanosActions.GET_ALL_REGISTROS_REQUEST, getAllRegistrosRH)
}

function* getRegistrosByTokenRH(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;

        const response = yield call(getRegistrosByTokenRHCall, token, page);
        console.log(response);
        if(response.status === 200){
            let registrosByToken = response.data.data;
            let registrosByTokenCantidad = response.data.cantidad;

            for(let i in registrosByToken){
                registrosByToken[i].usuario = registrosByToken[i].usuarioByIdUsuario.nombre + ' ' + registrosByToken[i].usuarioByIdUsuario.aPaterno + (registrosByToken[i].usuarioByIdUsuario.aMaterno ? (' ' + registrosByToken[i].usuarioByIdUsuario.aMaterno) : '');

                registrosByToken[i].formulario = {
                    IdsGerencias: undefined,
                    IdsGruposServicios: undefined,
                    IdsServicios: undefined,
                    activo: undefined,
                    IdsProveedores: undefined,
                    fechaIngresoInicio: undefined,
                    fechaIngresoFin: undefined,
                    horarioEntrada: undefined,
                    horarioSalida: undefined,
                    salidaComida: undefined,
                    regresoComida: undefined,
                    tipoTurno: undefined,
                }
                registrosByToken[i].checkbox = {
                    numeroEmpleadoC: false,
                    nombreC: false,
                    aPaternoC: false,
                    aMaternoC: false,
                    rfcC: false,
                    folioIDC: false,
                    nombreProveedorC: false,
                    nombreServicioC: false,
                    correoC: false,
                    puestoC: false,
                    fechaIngresoC: false,
                    tipoTurnoC: false,
                    diasLaboralesC: false,
                    horarioEntradaC: false,
                    horarioSalidaC: false,
                    salidaComidaC: false,
                    regresoComidaC: false,
                    expedienteClaveC: false,
                    activoC: false,
                    razonBajaC: false,
                }
                registrosByToken[i].agrupado = false;
                registrosByToken[i].valoresColumnasSplit = registrosByToken[i].valoresColumnas.split(',');
                for (let x in registrosByToken[i].valoresColumnasSplit) {
                    registrosByToken[i].valoresColumnasSplit[x] = registrosByToken[i].valoresColumnasSplit[x].charAt(0).toLowerCase() + registrosByToken[i].valoresColumnasSplit[x].slice(1);
                    registrosByToken[i].checkbox[registrosByToken[i].valoresColumnasSplit[x]] = true
                }

                let tipoAgrupado = 'SinAgrupar';
                let order = 'ASC';
                let field = 'pr.idPersonal'

                for (let x in registrosByToken[i].filtrosByIdReportePersonal) {
                    registrosByToken[i].formulario.IdsGerencias = registrosByToken[i].filtrosByIdReportePersonal[x].filtro === 'IdsGerencias' ? registrosByToken[i].filtrosByIdReportePersonal[x].valor.split(',') : registrosByToken[i].formulario.IdsGerencias;
                    registrosByToken[i].formulario.IdsServicios = registrosByToken[i].filtrosByIdReportePersonal[x].filtro === 'IdsServicios' ? registrosByToken[i].filtrosByIdReportePersonal[x].valor.split(',') : registrosByToken[i].formulario.IdsServicios;
                    registrosByToken[i].formulario.IdsGruposServicios = registrosByToken[i].filtrosByIdReportePersonal[x].filtro === 'IdsGruposServicios' ? registrosByToken[i].filtrosByIdReportePersonal[x].valor.split(',') : registrosByToken[i].formulario.IdsGruposServicios;
                    registrosByToken[i].formulario.IdsProveedores = registrosByToken[i].filtrosByIdReportePersonal[x].filtro === 'IdsProveedores' ? registrosByToken[i].filtrosByIdReportePersonal[x].valor.split(',') : registrosByToken[i].formulario.IdsProveedores;

                    registrosByToken[i].formulario.horarioEntrada = registrosByToken[i].filtrosByIdReportePersonal[x].filtro === 'HorarioEntrada' ? registrosByToken[i].filtrosByIdReportePersonal[x].valor.split(',') : registrosByToken[i].formulario.horarioEntrada;
                    registrosByToken[i].formulario.horarioSalida = registrosByToken[i].filtrosByIdReportePersonal[x].filtro === 'HorarioSalida' ? registrosByToken[i].filtrosByIdReportePersonal[x].valor.split(',') : registrosByToken[i].formulario.horarioSalida;
                    registrosByToken[i].formulario.salidaComida = registrosByToken[i].filtrosByIdReportePersonal[x].filtro === 'SalidaComida' ? registrosByToken[i].filtrosByIdReportePersonal[x].valor.split(',') : registrosByToken[i].formulario.salidaComida;
                    registrosByToken[i].formulario.regresoComida = registrosByToken[i].filtrosByIdReportePersonal[x].filtro === 'RegresoComida' ? registrosByToken[i].filtrosByIdReportePersonal[x].valor.split(',') : registrosByToken[i].formulario.regresoComida;
                    registrosByToken[i].formulario.tipoTurno = registrosByToken[i].filtrosByIdReportePersonal[x].filtro === 'TipoTurno' ? registrosByToken[i].filtrosByIdReportePersonal[x].valor : registrosByToken[i].formulario.tipoTurno;

                    if(registrosByToken[i].filtrosByIdReportePersonal[x].filtro === 'FechaIngreso'){
                        if(registrosByToken[i].filtrosByIdReportePersonal[x].valor.includes(',')){
                            registrosByToken[i].formulario.fechaIngresoInicio = new Date (registrosByToken[i].filtrosByIdReportePersonal[x].valor.split(',')[0]).toISOString().slice(0, 10);
                            registrosByToken[i].formulario.fechaIngresoFin = new Date (registrosByToken[i].filtrosByIdReportePersonal[x].valor.split(',')[1]).toISOString().slice(0, 10);
                        }
                    }

                    if(registrosByToken[i].filtrosByIdReportePersonal[x].filtro === 'TipoAgrupado'){
                        tipoAgrupado = registrosByToken[i].filtrosByIdReportePersonal[x].valor
                    }

                    if(registrosByToken[i].filtrosByIdReportePersonal[x].filtro === 'Activo'){
                        if(registrosByToken[i].filtrosByIdReportePersonal[x].valor === 'true'){
                            registrosByToken[i].formulario.activo = "Activo"
                        } else {
                            registrosByToken[i].formulario.activo = "Inactivo"
                        } 
                    }

                    if(registrosByToken[i].filtrosByIdReportePersonal[x].filtro === 'Order'){
                        order = registrosByToken[i].filtrosByIdReportePersonal[x].valor.split(' por el campo ')[0];
                        field = registrosByToken[i].filtrosByIdReportePersonal[x].valor.split(' por el campo ')[1];
                    }
                }

                registrosByToken[i].checkboxData = Object.entries(registrosByToken[i].checkbox);
                let checkAll = true;
                registrosByToken[i].listaFields = [];
                for (let x in registrosByToken[i].checkboxData) {
                    if (!registrosByToken[i].checkboxData[x][1]) {
                        checkAll = false
                    }
                    registrosByToken[i][registrosByToken[i].checkboxData[x][0]] = registrosByToken[i].checkboxData[x][1];
                    registrosByToken[i].checkboxData[x].push(
                        registrosByToken[i].checkboxData[x][0] === 'numeroEmpleadoC' ? 'Número de Empleado' : (
                            registrosByToken[i].checkboxData[x][0] === 'nombreC' ? 'Nombre' : (
                                registrosByToken[i].checkboxData[x][0] === 'aPaternoC' ? 'Apellido Paterno' : (
                                    registrosByToken[i].checkboxData[x][0] === 'aMaternoC' ? 'Apellido Materno' : (
                                        registrosByToken[i].checkboxData[x][0] === 'rfcC' ? 'RFC' : (
                                            registrosByToken[i].checkboxData[x][0] === 'folioIDC' ? 'Folio ID' : (
                                                registrosByToken[i].checkboxData[x][0] === 'nombreServicioC' ? 'Servicio' : (
                                                    registrosByToken[i].checkboxData[x][0] === 'nombreProveedorC' ? 'Provedor' : (
                                                        registrosByToken[i].checkboxData[x][0] === 'correoC' ? 'Correo' : (
                                                            registrosByToken[i].checkboxData[x][0] === 'puestoC' ? 'Puesto' : (
                                                                registrosByToken[i].checkboxData[x][0] === 'fechaIngresoC' ? 'Fecha de Ingreso' : (
                                                                    registrosByToken[i].checkboxData[x][0] === 'tipoTurnoC' ? 'Tipo de Turno' : (
                                                                        registrosByToken[i].checkboxData[x][0] === 'diasLaboralesC' ? 'Días Laborales' : (
                                                                            registrosByToken[i].checkboxData[x][0] === 'horarioEntradaC' ? 'Horario Entrada' : (
                                                                                registrosByToken[i].checkboxData[x][0] === 'horarioSalidaC' ? 'Horario Salida' : (
                                                                                    registrosByToken[i].checkboxData[x][0] === 'salidaComidaC' ? 'Salida Comida' : (
                                                                                        registrosByToken[i].checkboxData[x][0] === 'regresoComidaC' ? 'Regreso Comida' : (
                                                                                            registrosByToken[i].checkboxData[x][0] === 'expedienteClaveC' ? 'Clave de Expediente' : (
                                                                                                registrosByToken[i].checkboxData[x][0] === 'activoC' ? 'Condición' : (
                                                                                                    registrosByToken[i].checkboxData[x][0] === 'razonBajaC' ? 'Razón de Baja' : false
                                                                                                )
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                }

                registrosByToken[i].checkAll = checkAll;
                registrosByToken[i].tipoAgrupado = tipoAgrupado;
                registrosByToken[i].order = order;
                registrosByToken[i].field = field;
            }

            yield put({ type: recursosHumanosActions.GET_REGISTROS_BY_TOKEN_SUCCESS, registrosByToken, registrosByTokenCantidad });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: recursosHumanosActions.GET_REGISTROS_BY_TOKEN_FAILURE });
        }
    } catch (error) {
        yield put({ type: recursosHumanosActions.GET_REGISTROS_BY_TOKEN_FAILURE });
    }
} export function* getRegistrosByTokenRHSaga(){
    yield takeLatest(recursosHumanosActions.GET_REGISTROS_BY_TOKEN_REQUEST, getRegistrosByTokenRH)
}

function* crearRegistroReporteRH(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idsProveedores = [];
        let idsServicios = [];
        let idsGerencias = [];
        let idsGruposServicios = [];

        if(action.data.formulario.IdsGerencias){
            for(let i in action.data.formulario.IdsGerencias){
                idsGerencias.push(parseInt(action.data.formulario.IdsGerencias[i].split(' - ')[0]));
            }
        }

        if(action.data.formulario.idsGruposServicios){
            for(let i in action.data.formulario.idsGruposServicios){
                idsGruposServicios.push(parseInt(action.data.formulario.idsGruposServicios[i].split(' - ')[0]));
            }
        }

        if(action.data.formulario.IdsServicios){
            for(let i in action.data.formulario.IdsServicios){
                idsServicios.push(parseInt(action.data.formulario.IdsServicios[i].split(' - ')[0]));
            }
        }

        if(action.data.formulario.idsProveedores){
            for(let i in action.data.formulario.idsProveedores){
                idsProveedores.push(parseInt(action.data.formulario.idsProveedores[i].split(' - ')[0]));
            }
        }    

        const data = {
            "personalRequest":{
                "idsGerencias": action.data.formulario.IdsGerencias ? idsGerencias : null,
                "idsGruposServicios": action.data.formulario.idsGruposServicios ? idsGruposServicios : null,
                "idsProveedores": action.data.formulario.idsProveedores ? idsProveedores : null,
                "idsServicios": action.data.formulario.IdsServicios ? idsServicios : null,
                "fechaIngresoInicio": action.data.formulario.fechaIngreso ? action.data.formulario.fechaIngreso[0].format('YYYY-MM-DD') : null,
                "fechaIngresoFin": action.data.formulario.fechaIngreso ? action.data.formulario.fechaIngreso[1].format('YYYY-MM-DD') : null,
                "tipoTurno": action.data.formulario.tipoHorario ? action.data.formulario.tipoHorario : null,
                "horarioEntrada": action.data.formulario.horarioEntrada ? action.data.formulario.horarioEntrada.format('HH:mm:ss') : null,
                "order": action.data.order.order,
                "field": action.data.order.field,
                "page": action.data.page ? (action.data.page - 1) * 10 : null,
                "offset": null,
                "horarioSalida": action.data.formulario.horarioSalida ? action.data.formulario.horarioSalida.format('HH:mm:ss') : null,
                "salidaComida": action.data.formulario.salidaComida ? action.data.formulario.salidaComida.format('HH:mm:ss') : null,
                "regresoComida": action.data.formulario.regresoComida ? action.data.formulario.regresoComida.format('HH:mm:ss') : null,
                "activo": action.data.formulario.activo ? action.data.formulario.activo : null,
                "numeroEmpleadoC": action.data.checkboxData.numeroEmpleadoC ? action.data.checkboxData.numeroEmpleadoC : false,
                "nombreC": action.data.checkboxData.nombreC ? action.data.checkboxData.nombreC : false,
                "aPaternoC": action.data.checkboxData.aPaternoC ? action.data.checkboxData.aPaternoC : false,
                "aMaternoC": action.data.checkboxData.aMaternoC ? action.data.checkboxData.aMaternoC : false,
                "rfcC": action.data.checkboxData.rfcC ? action.data.checkboxData.rfcC : false,
                "folioIDC": action.data.checkboxData.folioIDC ? action.data.checkboxData.folioIDC : false,
                "nombreProveedorC": action.data.checkboxData.nombreProveedorC ? action.data.checkboxData.nombreProveedorC : false,
                "nombreServicioC": action.data.checkboxData.nombreServicioC ? action.data.checkboxData.nombreServicioC : false,
                "correoC":action.data.checkboxData.correoC ? action.data.checkboxData.correoC : false,
                "puestoC":action.data.checkboxData.puestoC ? action.data.checkboxData.puestoC : false,
                "fechaIngresoC":action.data.checkboxData.fechaIngresoC ? action.data.checkboxData.fechaIngresoC : false,
                "tipoTurnoC":action.data.checkboxData.tipoTurnoC ? action.data.checkboxData.tipoTurnoC : false,
                "diasLaboralesC":action.data.checkboxData.diasLaboralesC ? action.data.checkboxData.diasLaboralesC : false,
                "horarioEntradaC":action.data.checkboxData.horarioEntradaC ? action.data.checkboxData.horarioEntradaC : false,
                "horarioSalidaC":action.data.checkboxData.horarioSalidaC ? action.data.checkboxData.horarioSalidaC : false,
                "salidaComidaC":action.data.checkboxData.salidaComidaC ? action.data.checkboxData.salidaComidaC : false,
                "regresoComidaC":action.data.checkboxData.regresoComidaC ? action.data.checkboxData.regresoComidaC : false,
                "expedienteClaveC":action.data.checkboxData.expedienteClaveC ? action.data.checkboxData.expedienteClaveC : false,
                "activoC":action.data.checkboxData.activoC ? action.data.checkboxData.activoC : false,
                "razonBajaC":action.data.checkboxData.razonBajaC ? action.data.checkboxData.razonBajaC : false,
                "tipoAgrupado": action.data.agrupado
            },
            "reportesPersonal": {
                "idReportePersonal": null,
                'titulo': action.formulario.titulo,
                "nombre": action.formulario.nombre,
                "descripcion": action.formulario.descripcion ? action.formulario.descripcion : '',
                "idUsuario": null,
                "valoresColumnas": "",
                "fechaAlta": null,
                "fechaActualizacion": null,
                "activo": true
            }
        }
        console.log(`data`, data);

        const response = yield call(crearRegistroReporteRHCall, token, data)
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se creó el reporte predeterminado de manera exitosa";
            const order = { order: action.data.order.order, field: action.data.order.field}

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: recursosHumanosActions.MODAL_CREAR_REGISTRO, showModalCrearRegistro: false });

            if(action.data.agrupado === 'SinAgrupar'){
                if(action.data.tipoDocumento === 'pdf'){
                    yield put({ type: recursosHumanosActions.PDF_REPORTE_DINAMICO_RH_REQUEST, formulario: action.data.formulario, checkboxData: action.data.checkboxData, order, containerRegistros: true });
                }
                else{
                    yield put({ type: recursosHumanosActions.EXCEL_REPORTE_DINAMICO_RH_REQUEST, formulario: action.data.formulario, checkboxData: action.data.checkboxData, order, containerRegistros: true });
                }
            } else {
                action.formulario.horarioEntrada = action.formulario.horarioEntrada ? action.formulario.horarioEntrada.format('HH:mm:ss') : null;
                if(action.data.tipoDocumento === 'pdf'){
                    yield put({ type: recursosHumanosActions.PDF_REPORTE_DINAMICO_RH_AGRUPADO_REQUEST, formulario: action.data.formulario, checkboxData: action.data.checkboxData, agrupado: action.data.agrupado, order, containerRegistros: true });
                }
                else{
                    yield put({ type: recursosHumanosActions.EXCEL_REPORTE_DINAMICO_RH_AGRUPADO_REQUEST, formulario: action.data.formulario, checkboxData: action.data.checkboxData, agrupado: action.data.agrupado, order, containerRegistros: true });
                }
            }

            yield put({
                type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO,
                limpiarFormulario: 'crearRegistroReporteRH'
            });

            yield put({type: recursosHumanosActions.CREATE_REGISTROS_SUCCESS});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: recursosHumanosActions.CREATE_REGISTROS_FAILURE})
        }
    } catch (error) {
        yield put({type: recursosHumanosActions.CREATE_REGISTROS_FAILURE})
    }
}

export function* crearRegistroReporteRHSaga() {
    yield takeLatest(recursosHumanosActions.CREATE_REGISTROS_REQUEST, crearRegistroReporteRH)
}

function* estadoRegistroReporteRH(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idReporte = action.idReporte;

        const response = yield call(estadoRegistroReporteRHCall, token, idReporte);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El estado del reporte de recursos humanos cambió de forma correcta";

            yield put({type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage});

            if (action.personal) {
                yield put({
                    type: recursosHumanosActions.GET_ALL_REGISTROS_REQUEST,
                    page: action.page
                });
            } else {
                yield put({type: recursosHumanosActions.GET_REGISTROS_BY_TOKEN_REQUEST, page: action.page});
            }

            yield put({type: recursosHumanosActions.ESTADO_REGISTROS_SUCCESS});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});

            yield put({type: recursosHumanosActions.ESTADO_REGISTROS_FAILURE});
        }

    } catch (error) {
        console.log(error);
        yield put({type: recursosHumanosActions.ESTADO_REGISTROS_FAILURE});
    }
}

export function* estadoRegistroReporteRHSaga() {
    yield takeLatest(recursosHumanosActions.ESTADO_REGISTROS_REQUEST, estadoRegistroReporteRH);
}

function* editarRegistroReporteRH(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idReportePersonal = action.registroReporteSeleccionado.idReportePersonal;

        let idsProveedores = [];
        let idsServicios = [];
        let idsGerencias = [];
        let idsGruposServicios = [];
        let tipoAgrupado = action.formulario.tipoAgrupado

        if(action.formulario.IdsGerencias){
            for(let i in action.formulario.IdsGerencias){
                idsGerencias.push(parseInt(action.formulario.IdsGerencias[i].split(' - ')[0]));
            }
        }

        if(action.formulario.idsGruposServicios){
            for(let i in action.formulario.idsGruposServicios){
                idsGruposServicios.push(parseInt(action.formulario.idsGruposServicios[i].split(' - ')[0]));
            }
        }

        if(action.formulario.IdsServicios){
            for(let i in action.formulario.IdsServicios){
                idsServicios.push(parseInt(action.formulario.IdsServicios[i].split(' - ')[0]));
            }
        }

        if(action.formulario.idsProveedores){
            for(let i in action.formulario.idsProveedores){
                idsProveedores.push(parseInt(action.formulario.idsProveedores[i].split(' - ')[0]));
            }
        } 

        if(!action.registroReporteSeleccionado.activoC){
            if(action.formulario.tipoAgrupado === 'Por estado'){
                tipoAgrupado = 'SinAgrupar'
            }
        }

        if(!action.registroReporteSeleccionado.nombreServicioC){
            if(action.formulario.tipoAgrupado === 'Por servicios'){
                tipoAgrupado = 'SinAgrupar'
            }
        }

        const data = {
            "personalRequest":{
                "idsGerencias": action.formulario.IdsGerencias ? idsGerencias  : null,
                "idsGruposServicios": action.formulario.idsGruposServicios ? idsGruposServicios  : null,
                "idsProveedores": action.formulario.idsProveedores ? idsProveedores  : null,
                "idsServicios": action.formulario.IdsServicios ? idsServicios : null,
                "fechaIngresoInicio": action.formulario.fechaIngreso ? action.formulario.fechaIngreso[0].format('YYYY-MM-DD') : null,
                "fechaIngresoFin": action.formulario.fechaIngreso ? action.formulario.fechaIngreso[1].format('YYYY-MM-DD') : null,
                "tipoTurno": action.formulario.tipoHorario ? action.formulario.tipoHorario : null,
                "horarioEntrada": action.formulario.horarioEntrada ? action.formulario.horarioEntrada.format('HH:mm:ss') : null,
                "page": null,
                "offset": null,
                "order": action.formulario.order,
                "field": action.formulario.field,
                "horarioSalida": action.formulario.horarioSalida ? action.formulario.horarioSalida.format('HH:mm:ss') : null,
                "salidaComida": action.formulario.salidaComida ? action.formulario.salidaComida.format('HH:mm:ss') : null,
                "regresoComida": action.formulario.regresoComida ? action.formulario.regresoComida.format('HH:mm:ss') : null,
                "activo": action.formulario.activoCondicion ? action.formulario.activoCondicion === 'Activo' ? 'true' : 'false' : null,
                "numeroEmpleadoC": action.registroReporteSeleccionado.numeroEmpleadoC ? action.registroReporteSeleccionado.numeroEmpleadoC : false,
                "nombreC": action.registroReporteSeleccionado.nombreC ? action.registroReporteSeleccionado.nombreC : false,
                "aPaternoC": action.registroReporteSeleccionado.aPaternoC ? action.registroReporteSeleccionado.aPaternoC : false,
                "aMaternoC": action.registroReporteSeleccionado.aMaternoC ? action.registroReporteSeleccionado.aMaternoC : false,
                "rfcC": action.registroReporteSeleccionado.rfcC ? action.registroReporteSeleccionado.rfcC : false,
                "folioIDC": action.registroReporteSeleccionado.folioIDC ? action.registroReporteSeleccionado.folioIDC : false,
                "nombreProveedorC": action.registroReporteSeleccionado.nombreProveedorC ? action.registroReporteSeleccionado.nombreProveedorC : false,
                "nombreServicioC": action.registroReporteSeleccionado.nombreServicioC ? action.registroReporteSeleccionado.nombreServicioC : false,
                "correoC":action.registroReporteSeleccionado.correoC ? action.registroReporteSeleccionado.correoC : false,
                "puestoC":action.registroReporteSeleccionado.puestoC ? action.registroReporteSeleccionado.puestoC : false,
                "fechaIngresoC":action.registroReporteSeleccionado.fechaIngresoC ? action.registroReporteSeleccionado.fechaIngresoC : false,
                "tipoTurnoC":action.registroReporteSeleccionado.tipoTurnoC ? action.registroReporteSeleccionado.tipoTurnoC : false,
                "diasLaboralesC":action.registroReporteSeleccionado.diasLaboralesC ? action.registroReporteSeleccionado.diasLaboralesC : false,
                "horarioEntradaC":action.registroReporteSeleccionado.horarioEntradaC ? action.registroReporteSeleccionado.horarioEntradaC : false,
                "horarioSalidaC":action.registroReporteSeleccionado.horarioSalidaC ? action.registroReporteSeleccionado.horarioSalidaC : false,
                "salidaComidaC":action.registroReporteSeleccionado.salidaComidaC ? action.registroReporteSeleccionado.salidaComidaC : false,
                "regresoComidaC":action.registroReporteSeleccionado.regresoComidaC ? action.registroReporteSeleccionado.regresoComidaC : false,
                "expedienteClaveC":action.registroReporteSeleccionado.expedienteClaveC ? action.registroReporteSeleccionado.expedienteClaveC : false,
                "activoC":action.registroReporteSeleccionado.activoC ? action.registroReporteSeleccionado.activoC : false,
                "razonBajaC":action.registroReporteSeleccionado.razonBajaC ? action.registroReporteSeleccionado.razonBajaC : false,
                "tipoAgrupado": tipoAgrupado
            },
            "reportesPersonal": {
                "idReportePersonal": action.registroReporteSeleccionado.idReportePersonal,
                'titulo': action.formulario.titulo,
                "nombre": action.formulario.nombre,
                "descripcion": action.formulario.descripcion ? action.formulario.descripcion : '',
                "idUsuario": action.registroReporteSeleccionado.idUsuario,
                "valoresColumnas": "",
                "fechaAlta": null,
                "fechaActualizacion": null,
                "activo": true
            }
        }
        console.log(`data`, data);

        const response = yield call(editarRegistroReporteRHCall, token, data, idReportePersonal);
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se editó el reporte predeterminado de manera exitosa";

            if (action.personal) {
                yield put({
                    type: recursosHumanosActions.GET_ALL_REGISTROS_REQUEST,
                    page: action.page
                });
            } else {
                yield put({type: recursosHumanosActions.GET_REGISTROS_BY_TOKEN_REQUEST, page: action.page});
            }

            yield put({type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage});
            yield put({
                type: recursosHumanosActions.MODAL_EDITAR_REGISTRO,
                showModalEditarRegistro: false,
                page: action.page,
                personal: action.personal
            });

            yield put({type: recursosHumanosActions.EDIT_REGISTROS_SUCCESS});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: recursosHumanosActions.EDIT_REGISTROS_FAILURE})
        }
    } catch (error) {
        yield put({type: recursosHumanosActions.EDIT_REGISTROS_FAILURE})
    }
}

export function* editarRegistroReporteRHSaga() {
    yield takeLatest(recursosHumanosActions.EDIT_REGISTROS_REQUEST, editarRegistroReporteRH)
}