import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Col, Spin, Modal, Select, Row, Radio, Tooltip, Typography, message } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

const { Option } = Select;
const { Text } = Typography;


const ModalEditarVariable = () => {
    const [timeIsRequired, setTimeIsRequired] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const showModalEditarVariable = useSelector((state) => state.ServiciosVariablesReducer.showModalEditarVariable);
    const variableSeleccionada = useSelector((state) => state.ServiciosVariablesReducer.variableSeleccionada);
    const fetchingEditarVariable = useSelector((state) => state.ServiciosVariablesReducer.fetchingEditarVariable);
    const fetchingUnidadesMediPorCategoria = useSelector((state) => state.AlmacenesReducer.fetchingUnidadesMediPorCategoria);
    const unidadesMediPorCategoria = useSelector((state) =>  state.AlmacenesReducer.unidadesMediPorCategoria);
    const page = useSelector((state) => state.ServiciosVariablesReducer.page);

    const layout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    }; 

    useEffect(() => {
        dispatch({
          type: 'UNIDADES_MEDI_POR_CATEGORIA_REQUEST',
          idCategoriaUnidadMedida: 6,
        });
        if(variableSeleccionada){
            setTimeIsRequired(variableSeleccionada.considerTiempo)
        }
        form.resetFields();
      }, [variableSeleccionada, form]);
      
    

      const handleCancel = () => {
        dispatch({
            type: 'SHOW_MODAL_EDITAR_VARIABLE',
            showModalEditarVariable: false,
            variableSeleccionada: []
        });
        form.resetFields();
    };
    

    const onFinish = formulario => {
        if(!formulario.subvariablesByVariable){
            message.error('Debe de ingresarse al menos una subvariable');
       }
       else{
           for (const i in formulario.subvariablesByVariable) {
                for (const key in unidadesMediPorCategoria) {
                    if(unidadesMediPorCategoria[key].unidadMedida === formulario.subvariablesByVariable[i].unidadMedida){
                        formulario.subvariablesByVariable[i].idUnidadMedida = unidadesMediPorCategoria[key].idUnidadMedida;
                        delete formulario.subvariablesByVariable[i].unidadMedida;
                        formulario.subvariablesByVariable[i].unidadMedidaByIdUnidadMedida.idUnidadMedida = unidadesMediPorCategoria[key].idUnidadMedida;
                        formulario.subvariablesByVariable[i].unidadMedidaByIdUnidadMedida.unidadMedida = unidadesMediPorCategoria[key].unidadMedida;
                    }
                }
           }
           
        dispatch({ 
            type: "EDITAR_VARIABLES_REQUEST",
            formulario, 
            variableSeleccionada,
            page
        });
       }
       // console.log('formulario:', formulario);
        
    }  

    console.log("variableSeleccionada",variableSeleccionada)

    const onChangeRadioBtn = (e) =>{
        setTimeIsRequired(e.target.value)
    }

    return ( 
        <Modal
            visible={showModalEditarVariable}
            title='Editar Variable'
            destroyOnClose
            onCancel={handleCancel}
            footer={false}
            width='40%'
        >
            <Spin spinning={fetchingEditarVariable}>
                {variableSeleccionada  &&
                    <Form 
                        {...layout}
                        name='formulario'
                        initialValues={variableSeleccionada}
                        onFinish={onFinish}
                        preserve={false}
                        form={form} 
                    >
                        <Row type='flex'>
                            <Col span={24}>
                                <Form.Item
                                    label="Nombre de la variable"
                                    name="variable"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                <Input
                                    pattern="^^[ñíóáéú a-zA-Z 0-9]+$"
                                    title="Nombre debe tener letras y numeros."
                                    maxLength={100} placeholder='Ingrese nombre de la variable' />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Considerar Tiempo"
                                    name="considerTiempo"
                                >
                                    <Radio.Group onChange={onChangeRadioBtn} defaultValue={timeIsRequired}>
                                        <Radio value={true}>Si</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            {timeIsRequired === true &&
                                <Col span={14} offset={1}>
                                    <Form.Item
                                        label="Tiempo"
                                        name="tiempo"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Input maxLength={100} placeholder='Ingrese el tiempo' />
                                    </Form.Item>
                                </Col>
                            }
                            </Row>
                            
                            <Col span={24}>
                                        <Form.List name="subvariablesByVariable">
                                             {(fields, { add, remove }) => ( <>
                                                  {fields.map(field => (
                                                       <Row justify='center' key={field.key} gutter={[8,8]} align='middle'>
                                                            <Col span={23}>
                                                                 <Row justify='space-around' key={field.key} gutter={[8,8]}>
                                                                      <Col span={16}>
                                                                           <Form.Item
                                                                                {...field}
                                                                                label={'Nombre Subvariable'}
                                                                                name={[field.name, 'nombre']}
                                                                                fieldKey={[field.fieldKey, 'nombre']}
                                                                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                                           >
                                                                                <Input maxLength={100} placeholder='Nombre de la Subvariable' />
                                                                           </Form.Item>
                                                                      </Col>

                                                                      <Col span={8}>
                                                                           <Form.Item
                                                                                {...field}
                                                                                label={'Unidad de medida'}
                                                                                name={[field.name, 'unidadMedida']}
                                                                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                                           >
                                                                                <Select allowClear style={{ width: '100%' }}  placeholder="Seleccione la unidad de medida" loading={fetchingUnidadesMediPorCategoria} showSearch>
                                                                                     {unidadesMediPorCategoria.map(option => 
                                                                                        <Option key={option.unidadMedida}>
                                                                                            <Text>{option.unidadMedida}</Text>
                                                                                        </Option>
                                                                                     )}
                                                                                </Select>
                                                                           </Form.Item>
                                                                      </Col>
                                                                 </Row>
                                                            </Col>

                                                            {fields.length > 1 && (
                                                                <Tooltip placement="topLeft" title="Eliminar Subvariable"><MinusCircleOutlined onClick={() => remove(field.name)} /></Tooltip>
                                                            )}
                                                       </Row>
                                                  ))}
                                                  <Col span={24}>
                                                    <Form.Item>
                                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                            Agregar Subvariable
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                             </> )}
                                        </Form.List>
                                   </Col>
                            <Row>
                            </Row>
                            <Col span={24}>
                                <Button className="btnFiltrar" htmlType="submit">Editar Variable</Button>
                            </Col>
                    </Form>
                }
            </Spin>
        </Modal>
        );
    }

export default ModalEditarVariable;
 