import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, Select, Tooltip, Row, DatePicker, message, Upload, Typography } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { FilePdfFilled } from '@ant-design/icons';
import { connect } from "react-redux";
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;
const { Dragger } = Upload;
const { Text, Paragraph } = Typography;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalEditarBitacora extends Component {
    formRef = React.createRef();
    state={
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
        fileList: [],
    }
    render() {
        const { fetchingEditarBitacora, showModalEditarBitacora, onEditarBitacora, onShowModalEditarBitacora,
            fetchingListaServiciosActivosF, listaServiciosActivosF, limpiarFormulario, onLimpiarFormulario,
            listaTiposBitacoraActivos, fetchingListaTiposBitacoraActivos, page, busqueda, listaInfoProyecto,
            bitacoraSeleccionada,
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        let userInfo = sessionStorage.getItem('userInfo');
        if(userInfo[0]) {
            userInfo = JSON.parse(userInfo);
        }

        const listaServiciosUsuario = userInfo[0].serviciosByIdUsuario ? userInfo[0].serviciosByIdUsuario : [];

        const onFinish = formulario => {
            console.log('Success:', formulario);
            if(!this.state.filePDF){
                formulario.evidencia = undefined;
            }
            if(formulario.fechaHora){
                console.log('Entró a formulario');
                formulario.fechaHora = formulario.fechaHora.format('YYYY-MM-DD HH:mm');
            }
            console.log('después de format:', formulario);
            onEditarBitacora(formulario, page, busqueda, bitacoraSeleccionada);
            this.setState({fileList: []});
        };
        

        const handleCancel = () => {
            onShowModalEditarBitacora(page, busqueda);
            this.props.onLimpiarListaTiposBitacoraActivos();
            this.setState({
                filePDF: false,
                botonPDF: false,
                botonEditar: false,
                fileList: [],
            });
            this.formRef.current.resetFields();
        }

        if(limpiarFormulario && limpiarFormulario === 'editarBitacora'){
            onLimpiarFormulario();
            this.props.onLimpiarListaTiposBitacoraActivos();
            this.setState({
                filePDF: false,
                botonPDF: false,
                botonEditar: false,
            });
        };
        const servicioSeleccionado = (value) => {
            this.formRef.current.setFieldsValue({'tipoBitacoraEditar': undefined})
            if(value){
                this.props.onListaTiposBitacoraActivos(parseInt(value));
            }
            else{
                this.props.onLimpiarListaTiposBitacoraActivos();
            }
        }
        const  disabledDate = (fecha) => {
            if( fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicioOperacion) {
                return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicioOperacion;
            } else {
                console.log((listaInfoProyecto.vigencia +  parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2])) + '-' +listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[1] + '-' + listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[0])
                if(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos){
                return fecha && moment(fecha.format('YYYY-MM-DD')) > moment((listaInfoProyecto.vigencia +  parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2])) + '-' +listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[1] + '-' + listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[0]);
            }
            }
        }
        const onChangePDF = (info) => {
            let fileList = [...info.fileList];
            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            fileList = fileList.slice(-1);

            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                    fileList
                })
            }
            if (info.file.status === 'done') {
                var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,50}$");
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['cartaFabricante']);
                    this.setState({fileList: []});
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true,
                        fileList
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['cartaFabricante']);
                    this.setState({fileList: []});
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true,
                        fileList
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }

        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
                fileList: []
            });
        }

        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };

        return (
            <Modal
            visible={showModalEditarBitacora}
            title='Editar Bitácora'
            onCancel={handleCancel}
            footer={false}
            width='70%'
            >
                {bitacoraSeleccionada ? (
                <Spin spinning={fetchingEditarBitacora}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    ref={this.formRef}
                    initialValues={bitacoraSeleccionada}
                    >
                        <Row justify='space-around' gutter={[8,8]}>
                            <Col span={12}>
                                <Form.Item
                                    label="Asunto"
                                    name="asunto"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <TextArea  maxLength={450} placeholder='Ingresa el asunto' autoSize />
                                </Form.Item>
                            </Col>
                            {/* {permisosUsuario.especialBitácora ? (
                            <Col span={11}>
                                <Form.Item
                                    label="Servicio"
                                    name="servicioEditar"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona un servicio a filtrar"
                                        loading={fetchingListaServiciosActivosF}
                                        onChange={servicioSeleccionado}
                                        showSearch={true}
                                    >
                                        {listaServiciosActivosF.map(option => 
                                        <Option key={option.idServicio + ' - ' + option.nombre}>
                                            <Tooltip placement="topLeft" title={option.idServicio + ' - ' + option.nombre}>
                                                {option.idServicio + ' - ' + option.nombre}
                                            </Tooltip>
                                        </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            ):(
                            <Col span={11}>
                                <Form.Item
                                    label="Servicio"
                                    name="servicioEditar"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona un servicio a filtrar"
                                        onChange={servicioSeleccionado}
                                        showSearch={true}
                                    >
                                        {listaServiciosUsuario.map(option => 
                                        <Option key={option.idServicio + ' - ' + option.nombre}>
                                            <Tooltip placement="topLeft" title={option.idServicio + ' - ' + option.nombre}>
                                                {option.idServicio + ' - ' + option.nombre}
                                            </Tooltip>
                                        </Option>)}
                                    </Select>  
                                </Form.Item>
                            </Col>
                            )} */}
                            <Col span={12}>
                                <Form.Item
                                    label="Módulo bitácora"
                                    name="bitacoraModuloEditar"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona un tipo de bitácora"
                                        loading={this.props.fetchingListaModulosBitacoraByIdModulo}
                                        showSearch={true}
                                    >
                                        {this.props.listaModulosBitacoraByIdModulo.map(option => 
                                        <Option key={option.idBitacoraModulo + ' - ' + option.nombre}>
                                            <Tooltip placement="topLeft" title={option.idBitacoraModulo + ' - ' + option.nombre}>
                                                {option.idBitacoraModulo + ' - ' + option.nombre}
                                            </Tooltip>
                                        </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Observaciones"
                                    name="observaciones"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <TextArea  maxLength={450} placeholder='Ingresa las observaciones' autoSize />
                                </Form.Item>
                            </Col>
                            <Col span={12}>                   
                                <Form.Item
                                    label="Fecha y hora"
                                    name="fechaHora"
                                >
                                    <DatePicker
                                        style={{ width: '100%' }} 
                                        format='DD-MM-YYYY HH:mm' 
                                        showTime={true}
                                        disabledDate={disabledDate}
                                        />      
                                </Form.Item> 
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Acción realizada"
                                    name="accion"
                                >
                                    <Input maxLength={100} placeholder='Ingresa la acción realizada' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Conclusiones"
                                    name="conclusiones"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <TextArea  maxLength={450} placeholder='Ingresa las conclusiones' autoSize />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Responsable de Servicio"
                                    name="responsableSer"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={100} placeholder='Ingresa el responsable' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Responsable de Registro"
                                    name="responsableReg"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={100} placeholder='Ingresa el responsable' />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Evidencia PDF"
                                    name="evidencia"
                                >
                                    {/* <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                        {this.state.filePDF === false ? (
                                        <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                            <UploadOutlined /> Click para subir evidencia PDF
                                        </Button>
                                        ):false}
                                    </Upload> */}
                                    <Dragger customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false} style={{borderRadius:'1em'}} fileList={this.state.fileList}>
                                        <Row justify='center'>
                                            <FilePdfFilled className='iconTabPane'/>
                                            <Col span={24} style={{paddingTop:'1em'}}>
                                                <Paragraph>
                                                    <Text strong> Click o arrastra a ésta área el archivo PDF </Text>
                                                </Paragraph>
                                            </Col>
                                        </Row>
                                    </Dragger>
                                </Form.Item>
                            </Col>
                            <Col span={12} className='marginBoton'>
                                <br/>
                                <Button disabled={this.state.botonEditar} className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Editar Bitácora</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
                ):false}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarBitacora: state.ConfiguracionYSeguridadReducer.fetchingEditarBitacora,
        showModalEditarBitacora: state.ConfiguracionYSeguridadReducer.showModalEditarBitacora,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        page: state.ConfiguracionYSeguridadReducer.page,
        busqueda: state.ConfiguracionYSeguridadReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaTiposBitacoraActivos: state.ConfiguracionYSeguridadReducer.listaTiposBitacoraActivos,
        fetchingListaTiposBitacoraActivos: state.ConfiguracionYSeguridadReducer.fetchingListaTiposBitacoraActivos,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        bitacoraSeleccionada: state.ConfiguracionYSeguridadReducer.bitacoraSeleccionada,

        listaModulosBitacoraByIdModulo: state.ConfiguracionYSeguridadReducer.listaModulosBitacoraByIdModulo,
        fetchingListaModulosBitacoraByIdModulo: state.ConfiguracionYSeguridadReducer.fetchingListaModulosBitacoraByIdModulo,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarBitacora: (page, busqueda) => {
            dispatch({ type: 'MODAL_EDITAR_BITACORA', showModalEditarBitacora: false, page, busqueda })
        },
        onEditarBitacora: ( formulario, page, busqueda, bitacoraSeleccionada ) => {
            dispatch({ type: "EDITAR_BITACORA_REQUEST", formulario, page, busqueda, bitacoraSeleccionada });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onListaTiposBitacoraActivos: (idServicio) => {
            dispatch ({ type: 'LISTA_TIPOS_BITACORA_ACTIVOS_REQUEST', idServicio });
        },
        onLimpiarListaTiposBitacoraActivos: () => {
            dispatch ({ type: 'LISTA_TIPOS_BITACORA_ACTIVOS_SUCCESS', listaTiposBitacoraActivos: [] });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarBitacora);