import React, { Component } from 'react';
import {Form, InputNumber, Row, Col, Divider, Button, Input, message, Modal} from 'antd';

import { connect } from "react-redux";
const { TextArea } = Input;
const confirm = Modal.confirm;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ListaPeriodoEditar extends Component {
    formRef = React.createRef();
    componentDidMount(){
        this.props.onGetPeriodosTodos();
        this.props.onRequestInfoProyecto();
    }
    render(){
        
        const {
            onCambiarVista,  
            formularioPeriodo, // formulario anterior
            periodoSeleccionado,
            listaPeriodosTodos, // Valores activos
            listaPeriodoParaFormulario, // valores iniciales para form
            listaInfoProyecto,
        } = this.props;
        

        const onFinish = (formulario) => {
            console.log('formulario', formulario);

            let listaPeriodos = []
            let periodosEditados = []
            
            for (var key in formulario) {
                if (Object.prototype.hasOwnProperty.call(formulario, key)) {
                    let idCampo = key.split('-');
                    if (idCampo.length === 2) {
                        let idPeriodoFraccionMaxima = idCampo[1];
                        let campo = idCampo[0];
                        let valor = formulario[key];
    
                        let periodoFormulario = {[campo]: (valor), 'idPeriodoFraccionMaxima': parseInt(idPeriodoFraccionMaxima)};
                        listaPeriodos.push(periodoFormulario);
                    }
                }
            } 

            console.log('Array con el formulario editado :>> ', listaPeriodos);
            


                let listaPeriodosTodosLocal = [];
                
                listaPeriodosTodosLocal = listaPeriodosTodos;

                for(let x in listaPeriodosTodosLocal){

                    for(let i in listaPeriodos){

                        if(listaPeriodos[i].idPeriodoFraccionMaxima === listaPeriodosTodosLocal[x].idPeriodoFraccionMaxima && listaPeriodos[i].fraccionMaxDescuentoNuevo && listaPeriodos[i].fraccionMaxDescuentoNuevo !== listaPeriodosTodosLocal[x].fraccionMaxDescuento ){
                            listaPeriodosTodosLocal[x].agregar = true
                            listaPeriodosTodosLocal[x].fraccionMaxDescuento = listaPeriodos[i].fraccionMaxDescuentoNuevo
                        } else if(listaPeriodos[i].idPeriodoFraccionMaxima === listaPeriodosTodosLocal[x].idPeriodoFraccionMaxima && listaPeriodos[i].periodoAniosNuevo && listaPeriodos[i].periodoAniosNuevo !== listaPeriodosTodosLocal[x].periodoAnios ){
                            listaPeriodosTodosLocal[x].agregar = true
                            listaPeriodosTodosLocal[x].periodoAnios = listaPeriodos[i].periodoAniosNuevo
                        } else if(listaPeriodos[i].idPeriodoFraccionMaxima === listaPeriodosTodosLocal[x].idPeriodoFraccionMaxima && listaPeriodos[i].periodoMesNuevo && listaPeriodos[i].periodoMesNuevo !== listaPeriodosTodosLocal[x].periodoMes ){
                            listaPeriodosTodosLocal[x].agregar = true
                            listaPeriodosTodosLocal[x].periodoMes = listaPeriodos[i].periodoMesNuevo
                        }

                    if(listaPeriodos[i].idPeriodoFraccionMaxima === listaPeriodosTodosLocal[x].idPeriodoFraccionMaxima && listaPeriodos[i].cantidadAniosNuevo ){
                        
                        if(listaPeriodos[i].cantidadAniosNuevo !== parseInt(listaPeriodosTodosLocal[x].cantidadAnios) ) {
                            console.log('entro a la comparacion', listaPeriodos[i])

    
                                let y = parseInt(x) + 1
                                listaPeriodosTodosLocal[x].cantidadAnios = parseInt(listaPeriodos[i].cantidadAniosNuevo)
                                listaPeriodosTodosLocal[x].agregar = true
                                //si comienza en enero se resta 1
                                if(parseInt(listaPeriodosTodosLocal[x].fechaInicialPeriodo.split('T')[0].split('-')[1]) === 1) {

                                    listaPeriodosTodosLocal[x].fechaFinalPeriodo = (parseInt(listaPeriodosTodosLocal[x].fechaInicialPeriodo.split('T')[0].split('-')[0]) - 1) + '-' + listaPeriodosTodosLocal[x].fechaFinalPeriodo.split('T')[0].split('-')[1] + '-' + listaPeriodosTodosLocal[x].fechaFinalPeriodo.split('T')[0].split('-')[2] + 'T00:00:00'
                                    
                                    if(listaPeriodosTodosLocal[y]) {

                                        listaPeriodosTodosLocal[y].fechaInicialPeriodo = (parseInt(listaPeriodosTodos[x].fechaFinalPeriodo.split('T')[0].split('-')[0]) + 1)+ '-' + listaPeriodosTodosLocal[y].fechaInicialPeriodo.split('T')[0].split('-')[1] + '-' + listaPeriodosTodos[y].fechaInicialPeriodo.split('T')[0].split('-')[2] + 'T00:00:00'
                                        
                                    } 
                                 } else {

                                    listaPeriodosTodosLocal[x].fechaFinalPeriodo = (parseInt(listaPeriodosTodosLocal[x].fechaInicialPeriodo.split('T')[0].split('-')[0]) + parseInt(listaPeriodos[i].cantidadAniosNuevo)) + '-' + listaPeriodosTodosLocal[x].fechaFinalPeriodo.split('T')[0].split('-')[1] + '-' + listaPeriodosTodosLocal[x].fechaFinalPeriodo.split('T')[0].split('-')[2] + 'T00:00:00'
                                    
                                    if(listaPeriodosTodosLocal[y]) {

                                        listaPeriodosTodosLocal[y].fechaInicialPeriodo = (parseInt(listaPeriodosTodos[x].fechaFinalPeriodo.split('T')[0].split('-')[0]))+ '-' + listaPeriodosTodosLocal[y].fechaInicialPeriodo.split('T')[0].split('-')[1] + '-' + listaPeriodosTodos[y].fechaInicialPeriodo.split('T')[0].split('-')[2] + 'T00:00:00'
                                         
                                    } 
                                    
                                }

                                console.log('fecha final periodo', listaPeriodosTodosLocal[x].fechaFinalPeriodo)
                                
                        } else {

                            let y = parseInt(x) + 1

                            if(parseInt(listaPeriodosTodosLocal[x].fechaInicialPeriodo.split('T')[0].split('-')[1]) === 1){

                                listaPeriodosTodosLocal[x].fechaFinalPeriodo = ((listaPeriodosTodosLocal[x].cantidadAnios - 1) + parseInt(listaPeriodosTodosLocal[x].fechaInicialPeriodo.split('T')[0].split('-')[0] )) + '-' +listaPeriodosTodosLocal[x].fechaFinalPeriodo.split('T')[0].split('-')[1] + '-' + listaPeriodosTodosLocal[x].fechaFinalPeriodo.split('T')[0].split('-')[2] + 'T00:00:00'
                               
                                
                                if(listaPeriodosTodosLocal[y]) {

                                    listaPeriodosTodosLocal[y].fechaInicialPeriodo = (parseInt(listaPeriodosTodosLocal[x].fechaFinalPeriodo.split('T')[0].split('-')[0]) + 1)+ '-' + listaPeriodosTodosLocal[y].fechaInicialPeriodo.split('T')[0].split('-')[1] + '-' + listaPeriodosTodos[y].fechaInicialPeriodo.split('T')[0].split('-')[2]
                                   
                                     
                                }

                            } else {

                                listaPeriodosTodosLocal[x].fechaFinalPeriodo = (listaPeriodosTodosLocal[x].cantidadAnios + parseInt(listaPeriodosTodosLocal[x].fechaInicialPeriodo.split('T')[0].split('-')[0] )) + '-' +listaPeriodosTodosLocal[x].fechaFinalPeriodo.split('T')[0].split('-')[1] + '-' + listaPeriodosTodosLocal[x].fechaFinalPeriodo.split('T')[0].split('-')[2] + 'T00:00:00'
                                  
                                if(listaPeriodosTodosLocal[y]) {

                                    listaPeriodosTodosLocal[y].fechaInicialPeriodo = (parseInt(listaPeriodosTodosLocal[x].fechaFinalPeriodo.split('T')[0].split('-')[0]))+ '-' + listaPeriodosTodosLocal[y].fechaInicialPeriodo.split('T')[0].split('-')[1] + '-' + listaPeriodosTodos[y].fechaInicialPeriodo.split('T')[0].split('-')[2]
                                    
                                }

                            }       
                            
                        }
                        

                    } 
                    
                    
                }
                if(listaPeriodosTodosLocal[x].agregar){
                    periodosEditados.push(listaPeriodosTodosLocal[x])
                    listaPeriodosTodosLocal[x].agregar = false
                }

            } 



            console.log('listaPeriodos todos local', listaPeriodosTodosLocal)
            
            console.log('listaPeriodos editados ', periodosEditados)
            
            
            // for verificar años
            let ValorInicial = 0;
            for(let q in listaPeriodosTodosLocal){
                ValorInicial += parseInt(listaPeriodosTodosLocal[q].cantidadAnios)
            }

            let Vigencia = 0;
            if(listaInfoProyecto){
                if(listaInfoProyecto.vigencia){
                    Vigencia = parseInt(listaInfoProyecto.vigencia)
                }
            }
            
            console.log(ValorInicial, Vigencia)
            if(ValorInicial > Vigencia){
                message.warning('Verificar que la cantida de años no sea mayor a la vigencia del proyecto', 7);
                this.formRef.current.resetFields(['cantidadAniosNuevo-' + periodoSeleccionado.idPeriodoFraccionMaxima]);
            } else {
                confirm({
                    title: 'Estos son los Periodos que fueron modificados. ¿Estás seguro de modificarlos?',
                    content: 
                    <div>
                        {periodosEditados.map(periodo =>
                        <Row justify='space-around' key={periodo.idPeriodoFraccionMaxima}>
                            <Divider>{'Periodo ' + periodo.idPeriodoFraccionMaxima}</Divider>
                            <Col span={11}>
                                <p><strong>Cantidad Años: </strong>{periodo.cantidadAnios}</p>
                            </Col>
                            <Col span={11}>
                                <p><strong>Fracción Maxima Descuento: </strong>{periodo.fraccionMaxDescuento}</p>
                            </Col>
                            <Col span={11}>
                                <p><strong>Periodo Años: </strong>{periodo.periodoAnios}</p>
                            </Col>
                            <Col span={11}>
                                <p><strong>Periodo Mes: </strong>{periodo.periodoMes}</p>
                            </Col>
                            
                        </Row>
                        )}
                    </div>,
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    width: '50%',
                    onOk() {
                        onCambiarVista(4, formularioPeriodo, listaPeriodosTodosLocal);
                    },
                    onCancel() {
                        onCambiarVista(1, null);
                    },
                });
            }


            
        }

        


        return (
            <Form {...layout}
                name="formulario"
                onFinish={onFinish}
                initialValues={listaPeriodoParaFormulario}
                
                ref={this.formRef}
            >
                <div>
                    <h2>Favor de ingresar nuevamente la cantidad de años</h2>
                    {listaPeriodosTodos.map( periodo => 
                    <Row justify='space-around' key={periodo.idPeriodoFraccionMaxima}>
                        <Divider>{'Periodo ' + periodo.idPeriodoFraccionMaxima}</Divider>
                        <Col span={24}>
                            <Form.Item
                                label='Cantidad de Años'
                                name={'cantidadAniosNuevo-' + periodo.idPeriodoFraccionMaxima}
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                

                            >
                                <InputNumber  min={1} placeholder="Ingrese la cantidad de años" />  
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Fracción Maxima de Descuento"
                                name={'fraccionMaxDescuentoNuevo-' + periodo.idPeriodoFraccionMaxima}
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                <InputNumber  min={1} max={100} placeholder="Ingrese la fraccion maxima de descuento"/>
                            </Form.Item>
                        </Col> 

                        <Col span={24}>
                            <Form.Item
                                label="Periodo Años"
                                name={'periodoAniosNuevo-' + periodo.idPeriodoFraccionMaxima}
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                <TextArea
                                    autoSize
                                    pattern="^^[ñíóáéú a-zA-Z 0-9]+$"
                                    title="Nombre debe tener letras y numeros."
                                    maxLength={100} placeholder='Ingrese Periodo Años' />
                            </Form.Item>
                        </Col> 

                        <Col span={24}>
                            <Form.Item
                                label="Periodo Mes"
                                name={'periodoMesNuevo-' + periodo.idPeriodoFraccionMaxima}
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                <TextArea
                                    autoSize
                                    pattern="^^[ñíóáéú a-zA-Z 0-9]+$"
                                    title="Nombre debe tener letras y numeros."
                                    maxLength={100} placeholder='Ingrese Periodo periodoMes' />
                            </Form.Item>
                        </Col> 

                    
                    </Row>
                    )}
                    <Col span={24} className='marginBoton'>
                        <br/>
                        <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Guardar Cambios</Button>
                    </Col>
                    
                </div>

            </Form>
        );
    }
}


const mapStateToProps = state => {
	return {
        cambiarVistaModalPeriodoFMD: state.MecanismosDePagosReducer.cambiarVistaModalPeriodoFMD,
        listaPeriodosTodos: state.MecanismosDePagosReducer.listaPeriodosTodos,
        listaPeriodoParaFormulario: state.MecanismosDePagosReducer.listaPeriodoParaFormulario,
        periodoSeleccionado: state.MecanismosDePagosReducer.periodoSeleccionado,
        formularioPeriodo: state.MecanismosDePagosReducer.formularioPeriodo,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onCambiarVista: (cambiarVistaModalPeriodoFMD, formularioPeriodo, periodosEditados) => {
            dispatch({ type: 'CAMBIAR_VISTA_PERIODO_FMD', cambiarVistaModalPeriodoFMD, formularioPeriodo, periodosEditados })
        },
        onGetPeriodosTodos: () => {
            dispatch({ type: 'LISTA_PERIODOS_TODOS_REQUEST' });
        },
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        },
        
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaPeriodoEditar);