import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {Table, Row, Col, Button, DatePicker, Card, Select, message, Popover, Form} from 'antd';
import {  FileSearchOutlined, CloseCircleOutlined} from '@ant-design/icons';
import ModalDetalleOrdenSP from './ModalDetalleOrdenSP';
import ModalCancelacionOrdenSP from './ModalCancelacionOrdenSP';

const {Option} = Select;
const { RangePicker } = DatePicker;
class CancelacionOrdenesSP extends React.Component {
    formRef = React.createRef();
    state = {
        page : 1,
        idServicio: "",
        idPartida: "",
        idUbicacion: "",
        programa: "",
        fecha: null,
    }

    componentDidMount () {
        this.props.onGetOrdenesSPCancelacion("", "", "", null, "", 1); this.props.onGetServiciosActivos();
    }
    render() {

        const {fetchingListaCancelacionOrdenesSPBusqueda,listaCancelacionOrdenesSPBusqueda,listaCancelacionOrdenesSPCantidad,fetchingListaServiciosFiltro,listaServiciosFiltro,
            fetchingUbicacionesSinHijos,ubicacionesSinHijos,
            onListaServiciosFiltro,onListaServiciosFiltroLimpiar,onListaUbicacionesFiltro,onListaUbicacionesFiltroLimpiar,onGetDetalleOrdenSP,onModalDetalleOrdenSP,onModalCancelacionOrdenSP,onGetOrdenesSPCancelacion} = this.props;

        const {page, idServicio, idPartida, idUbicacion, programa, fecha} = this.state;
        
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columns = [{
            title: 'Folio de orden',
            dataIndex: 'folioOrden',
            key: 'folioOrden',
            width: '7%'
        },{
            title: 'Estado de la orden',
            dataIndex: 'estadoOrden',
            key: 'estadoOrden',
            width: '7%'
        },{
            title: 'Categoría',
            dataIndex: 'categoria',
            key: 'categoria',
            width: '11%'
        },{
            title: 'Nomenclatura',
            dataIndex: 'nomenclatura',
            key: 'nomenclatura',
            width: '7%'
        },{
            title: 'Servicio',
            dataIndex: 'nombreServicio',
            key: 'nombreServicio',
            width: '11%'
        },{
            title: 'Partida',
            dataIndex: 'partida',
            key: 'partida',
            width: '8%'
        },{
            title: 'Programa',
            dataIndex: 'programa',
            key: 'programa',
            width: '7%'
        },{
            title: 'Nombre de rutina',
            dataIndex: 'rutina',
            key: 'rutina',
            width: '11%'
        },{
            title: 'Tipo',
            dataIndex: 'tipoRutina',
            key: 'tipoRutina',
            width: '7%'
        },{
            title: 'Clave',
            dataIndex: 'claveRutina',
            key: 'claveRutina',
            width: '5%'
        },{
            title: 'Detalle',
            key: 'detalle',
            align: 'center',
            width: '5%',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.detalleDeOrdenEnCancelaciónDeServiciosPlanificados} onClick={() => onDetalle(record)}  ><FileSearchOutlined /></a>
            ),
        },{
            title: 'Documento adjunto',
            dataIndex: 'rutaArchivo',
            key: 'rutaArchivo',
            align: 'center',
            width: '6%',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.documentoAdjuntoDeOrdenEnCancelaciónDeServiciosPlanificados} onClick={() => generarPDF(record)} ><FileSearchOutlined /></a>
            ),
        },{
            title: 'Cancelar orden',
            key: 'cancelar',
            align: 'center',
            width: '5%',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.cancelarOrdenDeServiciosPlanificados ? true : record.estadoOrden == 'Generada' ? false : true}   onClick={() => onCancelarOrden(record)} ><CloseCircleOutlined /></a>
            ),
        }];
        const generarPDF = (key) => {
            console.log('key', key);
            const folio = key.folioOrden;
            if(key.programa === "Mantenimiento"){
                this.props.onGenerarMantenimientoPDF(folio);
            } else if(key.programa === "Rutina"){
                this.props.onGenerarRutinaPDF(folio);
            } else {
                this.props.onGenerarPruebaPDF(folio);
            }
           
        };

        const buscarServicios = value => {
            if (value.length >= 3) {
                if (value) {
                    console.log(value);
                    onListaServiciosFiltro(value)
                } else {
                    message.warning('No se encontró servicio con esas palabras')
                }
            } else {
                onListaServiciosFiltroLimpiar();
            }
        }

        const handleSearch = (value) => {
            if(value.length>=3){
                if (value) {
                    console.log(value)
                    onListaUbicacionesFiltro(value);
                } else {
                    message.warning('No se encontró ubicación con esas palabras')
                }
            }
            else{
                onListaUbicacionesFiltroLimpiar();
            }
        };

        const handleSelect = (value, field) => {

            if(field === 'idServicio'){
                if(value){
                    this.props.onGetPartidasByIdServicio(value.split(' - ')[0]);
                    this.formRef.current.resetFields(['idPartida']);
                } else {
                    this.props.onCleanPartidasByIdServicio();
                    this.formRef.current.resetFields(['idPartida']);
                }
                this.setState({[field]: value, page:1, idPartida:""}, () => onGetOrdenesSPCancelacion(this.state.idServicio, "", this.state.idUbicacion, fecha, this.state.programa, 1));
            } else {
                this.setState({[field]: value, page:1}, () => onGetOrdenesSPCancelacion(this.state.idServicio, this.state.idPartida, this.state.idUbicacion, fecha, this.state.programa, 1));
            }
        }

        const onChangeDate = value => {
            this.setState({fecha: value, page:1}, () => onGetOrdenesSPCancelacion(idServicio, idPartida, idUbicacion, this.state.fecha, programa, 1));
        }

        const disabledRange = fecha => {
            const currentYear = new Date();
            const year = currentYear.getFullYear();
            const fechaVigencia = moment(`31/12/${year}`, "DD-MM-YYYY")
            const fechaInicio = moment(`1/1/${year}`, "DD-MM-YYYY")
            if (fecha && fecha < fechaInicio) {
                return fecha && fecha < fechaInicio;
            } else {
                return fecha && fecha > fechaVigencia;
            }
        }

        const onCancelarOrden = (record) => {
            const busqueda = {idServicio, idUbicacion, fecha, programa}
            onModalCancelacionOrdenSP(record, busqueda, page);
        }

        const onDetalle = record =>{
            onModalDetalleOrdenSP(record);
            onGetDetalleOrdenSP(record)
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            onGetOrdenesSPCancelacion(idServicio, idPartida, idUbicacion, fecha, programa, pagination.current);
        }

        const scroll = {"x": 1700 | true, "y": 1000};

        const onPartidaSelect = (value, field) => {
            if(value){
                onGetOrdenesSPCancelacion(idServicio, value.split(' - ')[0], idUbicacion, fecha, programa, 1)
                this.setState({idPartida: value.split(' - ')[0]})
            } else {
                onGetOrdenesSPCancelacion(idServicio, '', idUbicacion, fecha, programa, 1);
                this.setState({ idPartida: ''})
                this.formRef.current.resetFields(['idPartida']);
            }
        };

        return (
            <div>
                <Card className="cardCatalogos" title={<div className='headerLista'>Cancelación de Ordenes</div>}>
                <Form name="formulario" ref={this.formRef}>
                    <Row justify='space-between' gutter={[8,8]}>
                        <Col span={12}>
                            <Select
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Selecciona un servicio"
                                loading={this.props.fetchingListaServiciosActivosF}
                                showSearch
                                onChange={value => handleSelect(value, 'idServicio')}
                            >
                                {this.props.listaServiciosActivosF.map(option => <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>{option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}</Option>)}
                            </Select>
                        </Col>
                        <Col span={12}>
                            <Form.Item  name="idPartida">
                                <Select
                                    allowClear
                                    showSearch={true}
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona una partidas"
                                    loading={this.props.fetchingListaPartidasByServicio4Fallas}
                                    disabled={!this.props.listaPartidasByServicio4Fallas[0]}
                                    onChange={value => onPartidaSelect(value, 'idPartida')}
                                    value={this.state.partida}
                                >
                                    {this.props.listaPartidasByServicio4Fallas.map(option =>
                                        <Option key={option.idPartida + ' - ' + option.nomenclatura  + ': ' + option.nombre}>
                                            {option.idPartida + ' - ' + option.nomenclatura  + ': ' + option.nombre}
                                        </Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Select allowClear
                                style={{ width: '100%' }}
                                placeholder="Ingrese la ubicación"
                                loading={fetchingUbicacionesSinHijos}
                                showSearch
                                filterOption={false}
                                onSearch={handleSearch}
                                onChange={value => handleSelect(value, 'idUbicacion')}
                                notFoundContent={null}
                                dropdownStyle={{ minWidth: "60%" }}
                            >
                                {ubicacionesSinHijos.map(option =>
                                    <Option key={`${option.idUbicacion} - ${option.ubicacionCOA}`} >
                                        <Popover content={option.ubicacionCOA} title={false}>
                                            {`${option.idUbicacion} - ${option.ubicacionCOA}`}
                                        </Popover>
                                    </Option>
                                )}
                            </Select>
                        </Col>
                        <Col span={5}>
                            <Select allowClear onChange={value => handleSelect(value, 'programa')}
                                style={{ width: '100%' }} placeholder="Selecciona un programa">
                                <Option value='Rutina'>Rutina</Option>
                                <Option value='Mantenimiento'>Mantenimiento</Option>
                                <Option value='Prueba'>Prueba</Option>
                            </Select>
                        </Col>
                        <Col span={7}>
                            <RangePicker
                                format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio", "Fecha fin"]}
                                disabledDate={disabledRange}
                                onChange={onChangeDate}
                            />
                        </Col>
                    </Row>
                </Form>
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Table
                                size="small"
                                rowKey="folioOrden"
                                columns={columns}
                                dataSource={listaCancelacionOrdenesSPBusqueda}
                                loading={fetchingListaCancelacionOrdenesSPBusqueda || this.props.fetchingDescargarPDForden}
                                locale={{ emptyText: 'Sin datos' }}
                                onChange={handleTableChange}
                                pagination={{ total: listaCancelacionOrdenesSPCantidad, simple: true, current: this.state.page }}
                                scroll={scroll}
                            />
                        </Col>
                    </Row>
                </Card>
                <ModalDetalleOrdenSP/>
                <ModalCancelacionOrdenSP/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaCancelacionOrdenesSPBusqueda: state.ServiciosPlanificadosReducer.fetchingListaCancelacionOrdenesSPBusqueda,
        listaCancelacionOrdenesSPBusqueda: state.ServiciosPlanificadosReducer.listaCancelacionOrdenesSPBusqueda,
        listaCancelacionOrdenesSPCantidad: state.ServiciosPlanificadosReducer.listaCancelacionOrdenesSPCantidad,
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
        fetchingUbicacionesSinHijos: state.SolicitudDeServiciosReducer.fetchingUbicacionesSinHijos,
        ubicacionesSinHijos: state.SolicitudDeServiciosReducer.ubicacionesSinHijos,
        fetchingDescargarPDForden: state.ServiciosPlanificadosReducer.fetchingDescargarPDForden,

        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        listaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.listaPartidasByServicio4Fallas,
        fetchingListaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.fetchingListaPartidasByServicio4Fallas,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda});
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []});
        },
        onListaUbicacionesFiltro: (busqueda) => {
            dispatch({ type: 'UBICACIONES_SIN_HIJOS_REQUEST', busqueda: busqueda });
        },
        onListaUbicacionesFiltroLimpiar: () => {
            dispatch({ type: 'UBICACIONES_SIN_HIJOS_SUCCESS', ubicacionesSinHijos: [] });
        },
        onGetDetalleOrdenSP: (ordenCancelacionSPSeleccionado) => {
            dispatch({ type: 'LISTA_DETALLE_CANCELACION_ORDEN_SP_REQUEST', ordenCancelacionSPSeleccionado: ordenCancelacionSPSeleccionado });
        },
        onModalDetalleOrdenSP: (ordenCancelacionSPSeleccionado) => {
            dispatch({ type: 'SHOW_MODAL_DETALLE_CANCELACION_ORDEN_SP',showModalDetalleOrdenesSPCancelacion: true, ordenCancelacionSPSeleccionado});
        },
        onModalCancelacionOrdenSP: (ordenCancelacionSPSeleccionado, busqueda, page) => {
            dispatch({ type: 'SHOW_MODAL_CANCELAR_ORDEN_SP', showModalCancelarOrdenSP: true, ordenCancelacionSPSeleccionado: ordenCancelacionSPSeleccionado, busqueda: busqueda, page:page});
        },
        onGetOrdenesSPCancelacion: (idServicio, idPartida, idUbicacion, fecha, programa, page) => {
            dispatch({ type: 'LISTA_BUSQUEDA_ORDENES_SP_CANCELAR_REQUEST', idServicio: idServicio, idPartida: idPartida, idUbicacion:idUbicacion, fecha:fecha, programa:programa, page:page});
        },
        onGenerarMantenimientoPDF: (folio) => {
            dispatch({ type: "DESCARGAR_PDF_MANTENIMIENTO_REQUEST", folio });
        },
        onGenerarPruebaPDF: (folio) => {
            dispatch({ type: "DESCARGAR_PDF_PRUEBA_REQUEST", folio });
        },
        onGenerarRutinaPDF: (folio) => {
            dispatch({ type: "DESCARGAR_PDF_RUTINA_REQUEST", folio });
        },
        onGetServiciosActivos: () => {
            dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
        },

        onGetPartidasByIdServicio: (idServicio) => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_REQUEST', idServicio})
        },
        onCleanPartidasByIdServicio: () => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_SUCCESS', listaPartidasByServicio4Fallas: []})
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (CancelacionOrdenesSP);