import React, { useEffect, useRef, useState } from 'react';
import {connect} from 'react-redux';
import { Alert, Button, Row, Col,Select, Form, Input, DatePicker, TimePicker, Tooltip, message} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const {Option} = Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const FilterSection = props => {
     let formRef = useRef();
    
     const {
          checkboxData, order, onGetReporteDinamico,formularioReporte,listaServiciosActivosF, handleSteps, onLimpiarFormulario,
          onGetServiciosActivos, onGetReporteDinamicoAgrupado, fetchingListaProveedores, listaProveedores, listaParamProveedores, fetchingListaParamProveedores, 
          onListaProveedoresFiltro, onListaProveedoresFiltroLimpiar, fetchingListaGrupoServiciosByIdUsuario, listaGrupoServiciosByIdUsuario, 
          onListaServiciosByIdsGerencias, fetchingListaClasificacionesActivos, listaClasificacionesActivos, fetchingListaServiciosByIdsGerencias, 
          listaServiciosByIdsGerencias, listaGruposServicios, fetchingListaGruposServicios, listaServiciosByIdsGrupos, fetchingListaServiciosByIdsGrupos,
          serviciosByIdUsuarioGrupos,  serviciosByIdUsuarioGerencia, fetchingListaGerenciasByIdUsuario, listaGerenciasByIdUsuario, onListaServiciosByIdsGrupos,
          listaAreasFuncionalesActivas, fetchingListaAreasFuncionalesActivas,fetchingListaUnidadesFuncionalesActivas,listaUnidadesFuncionalesActivas, onGetUnidades, listaPartidasByIdsServicios, fetchingListaPartidasByIdsServicios, onGetPartidasByIdsServicios, onCleanPartidasByIdsServicios
     } = props;

     let permisosUsuario = sessionStorage.getItem('permisosUsuario');
     if (permisosUsuario[0]) {
          permisosUsuario = JSON.parse(permisosUsuario);
     }

     const [boolGrupo, setBoolGrupo] = useState(true);
     const [disabledUnidades, setDisabledUnidades] = useState(true);
     const [tipoProgramaRutina, setTipoProgramaRutina] = useState(true);
     const [boolClasificacion, setBoolClasificacion] = useState(true);

     useEffect(() => {
          onGetServiciosActivos();
          props.onGerencias();
          props.onListaGrupoSer();
          props.onGetAreas();

     }, [])

     let userInfo = sessionStorage.getItem('userInfo');
     if(userInfo[0]) {
          userInfo = JSON.parse(userInfo);
     }

     const onHandleSteps = value => {
          handleSteps(value);
          onLimpiarFormulario();
          setBoolGrupo(false);
          setBoolClasificacion(false);
     }

     const onFinish = formulario => {
          console.log('order', order)
          console.log('formulario', formulario);
          onGetReporteDinamico(formulario, 1, order, checkboxData);
          //onGetReporteDinamicoAgrupado(formulario, 1, null);
          handleSteps('siguiente');
     }



     const onGrupoSeleccionado = (value) => {
          formRef.current.setFieldsValue({'idsGerencias': undefined});
          if(value.length > 0){
               formRef.current.setFieldsValue({'idsServicios': undefined});
               setBoolGrupo(false);
               onListaServiciosByIdsGrupos(value);
          } else {
               setBoolGrupo(true);
               formRef.current.setFieldsValue({'idsGruposServicios': undefined, 'idsServicios': undefined, 'idsPartidas': undefined});
               onCleanPartidasByIdsServicios();
          }
     };

     const onClasificacionSeleccionado = (value) => {
          formRef.current.setFieldsValue({'idsGruposServicios': undefined});
          if(value.length > 0){
               formRef.current.setFieldsValue({'idsServicios': undefined});
               setBoolClasificacion(false);
               onListaServiciosByIdsGerencias(value);
          } else {
               setBoolClasificacion(true);
               formRef.current.setFieldsValue({'idsGerencias': undefined, 'idsServicios': undefined});
          }
     };

     const onChangeSelect = (value) => {
          formRef.current.setFieldsValue({'unidadFuncional': undefined});
          if(value){
               onGetUnidades(value.split(' - ')[1]);
               setDisabledUnidades(false)
          }
          else{
               setDisabledUnidades(true)
          }
     };

     const servicioSeleccionado = (value) => {
          console.log('value', value)
          if(value.length > 0){
               onGetPartidasByIdsServicios(value);
               formRef.current.setFieldsValue({'idsPartidas': undefined});
          } else {
               onCleanPartidasByIdsServicios();
               formRef.current.setFieldsValue({'idsPartidas': undefined});
          }
     };

     return (
          <>
               <Row style={{padding:'1em'}}>
                    <Col span={24}>
                         <Alert message="Selecciona los filtros a aplicar en el reporte." type="info" style={{ marginBottom: '16px' }} />
                    </Col>
               </Row>
               <Form {...layout}
                    name='formulario'
                    onFinish={onFinish}
                    initialValues={formularioReporte}
                    ref={formRef}
               >
                    <Row justify='space-between' gutter={[8,8]} style={{padding:'1em'}}>
                         <Col span={11}>
                              <Form.Item
                                   name='tipo'
                                   label='Tipo'
                                   rules={[{ required: false, message: 'Favor de llenar el campo' }]}
                              >
                                   <Select
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el tipo"
                                        loading={fetchingListaParamProveedores}
                                        showSearch={true}
                                        notFoundContent={null}
                                   >
                                        <Option key='Ordenes'>Ordenes</Option>
                                        <Option key='Tareas'>Tareas</Option>
                                   </Select>
                              </Form.Item>
                         </Col>
                         
                         <Col span={11}>
                              <Form.Item
                                   name="idsGerencias"
                                   label="Gerencia"
                                   rules={[{ required: false, message: 'Favor de llenar el campo' }]}
                              >
                              {permisosUsuario.especialRecursosHumanos ? (
                              <Select
                                   allowClear
                                   style={{ width: '100%' }} 
                                   placeholder="Selecciona las gerencias"
                                   loading={fetchingListaClasificacionesActivos}
                                   mode='multiple'
                                   onChange={onClasificacionSeleccionado}
                                   disabled={!boolGrupo}
                              >
                                   {listaClasificacionesActivos.map(item => <Option key={item.idClasificacionGerencia + ' - ' + item.nombreGerencia}>{item.idClasificacionGerencia + ' - ' + item.nombreGerencia}</Option>)}
                              </Select>
                              ):(
                              <Select allowClear
                                   style={{ width: '100%' }}
                                   placeholder="Selecciona las gerencias"
                                   loading={fetchingListaGerenciasByIdUsuario}
                                   mode='multiple'
                                   onChange={onClasificacionSeleccionado}
                                   disabled={!boolGrupo}
                              >
                                   {listaGerenciasByIdUsuario.map(option => 
                                   <Option key={option.idClasificacionGerencia + ' - ' + option.nombreGerencia}>
                                        <Tooltip title={option.idClasificacionGerencia + ' - ' + option.nombreGerencia}>
                                             {option.idClasificacionGerencia + ' - ' + option.nombreGerencia}
                                        </Tooltip>
                                   </Option>
                                   )}
                              </Select>
                              )}
                              </Form.Item>
                         </Col>

                         <Col span={11}>
                              <Form.Item
                                   name='idsGruposServicios'
                                   label='Grupo de Servicios'
                                   rules={[{ required: false, message: 'Favor de llenar el campo' }]}
                              >
                                   {permisosUsuario.especialRecursosHumanos ? (
                                   <Select
                                        allowClear
                                        style={{ width: '100%' }} 
                                        placeholder="Selecciona los grupos de servicios"
                                        loading={fetchingListaGruposServicios}
                                        mode='multiple'
                                        onChange={onGrupoSeleccionado}
                                        disabled={!boolClasificacion}
                                   >
                                        {listaGruposServicios.map(option => <Option key={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}><Tooltip title={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}>{option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}</Tooltip></Option>)}
                                   </Select>
                                   ):(
                                   <Select
                                        mode='multiple'
                                        allowClear
                                        style={{ width: '100%' }} placeholder="Selecciona los grupos de servicios"
                                        loading={fetchingListaGrupoServiciosByIdUsuario}
                                        onChange = {onGrupoSeleccionado}
                                        disabled={!boolClasificacion}
                                   >
                                        {listaGrupoServiciosByIdUsuario.map(option => <Option key={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}><Tooltip title={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}>{option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}</Tooltip></Option>)}
                                   </Select>
                                   )}
                              </Form.Item>
                         </Col>

                         <Col span={11}>
                              <Form.Item
                                   name='idsServicios'
                                   label='Servicio'
                              >
                                   {permisosUsuario.especialGestiónDeCalidad ? (
                                   <Select
                                        allowClear
                                        style={{ width: '100%' }} placeholder="Selecciona los servicios"
                                        mode='multiple'
                                        disabled={boolGrupo && boolClasificacion}
                                        loading={boolClasificacion ? fetchingListaServiciosByIdsGrupos : fetchingListaServiciosByIdsGerencias}
                                        onChange={servicioSeleccionado}
                                   >
                                        {boolClasificacion ? (<>
                                             { listaServiciosByIdsGrupos ? (<>
                                                  {listaServiciosByIdsGrupos.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nombre}</Option>)}
                                             </>):false }
                                        </>) : (<>
                                             { listaServiciosByIdsGerencias ? (<>
                                                  {listaServiciosByIdsGerencias.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nombre}</Option>)}
                                             </>):false }
                                        </>)}
                                   </Select>
                                   ):(
                                   <Select
                                        mode='multiple'
                                        allowClear
                                        style={{ width: '100%' }} 
                                        placeholder="Selecciona los servicios"
                                        disabled={boolGrupo && boolClasificacion}
                                        loading={boolClasificacion ? fetchingListaServiciosByIdsGrupos : fetchingListaServiciosByIdsGerencias}
                                        onChange={servicioSeleccionado}
                                   >
                                        {boolClasificacion ? (<>
                                             { serviciosByIdUsuarioGrupos ? (<>
                                                  {serviciosByIdUsuarioGrupos.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nombre}</Option>)}
                                             </>):false }
                                        </>) : (<>
                                             { serviciosByIdUsuarioGerencia ? (<>
                                                  {serviciosByIdUsuarioGerencia.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nombre}</Option>)}
                                             </>):false }
                                        </>)}
                                   </Select>
                                   )}
                              </Form.Item>
                         </Col>

                         {checkboxData.partidaC && (
                              <Col span={11}>
                                   <Form.Item
                                        name='idsPartidas'
                                        label='Partida'
                                   >
                                        <Select
                                             mode='multiple'
                                             allowClear
                                             style={{ width: '100%' }} 
                                             placeholder="Selecciona las partidas"
                                             disabled={listaPartidasByIdsServicios.length === 0}
                                             loading={fetchingListaPartidasByIdsServicios}
                                        >
                                             {listaPartidasByIdsServicios.map(partida => <Option key={partida.idPartida + ' - ' + partida.nombre}>{partida.idPartida + ' - ' + partida.nombre}</Option>)}
                                        </Select>
                                   </Form.Item>
                              </Col>
                         )}
                         
                         {checkboxData.estatusOrdenC ? (
                         <Col span={11}>
                              <Form.Item
                                   name='estatusOrden'
                                   label='Estatus'
                              >
                                   <Select
                                        mode='multiple'
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el estatus de la orden"
                                        loading={fetchingListaParamProveedores}
                                        showSearch={true}
                                        notFoundContent={null}
                                   >
                                        <Option key='Generada'>Generada</Option>
                                        <Option key='Realizada'>Realizada</Option>
                                   </Select>
                              </Form.Item>
                         </Col>
                         ):false}

                         {checkboxData.tipoProgramaC ? (
                         <Col span={11}>
                              <Form.Item
                                   name='tipoPrograma'
                                   label='Tipo de Programa'
                              >
                                   <Select
                                        mode='multiple'
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el tipo de programa"
                                        loading={fetchingListaParamProveedores}
                                        onChange={(value) => {value.includes('Rutina')===true ? setTipoProgramaRutina(true)  : value.length === 0 ? setTipoProgramaRutina(true) : setTipoProgramaRutina(false)}}
                                        showSearch={true}
                                        notFoundContent={null}
                                   >
                                        <Option key='Rutina'>Rutina</Option>
                                        <Option key='Mantenimiento'>Mantenimiento</Option>
                                        <Option key='Prueba'>Prueba</Option>
                                   </Select>
                              </Form.Item>
                         </Col>
                         ):false}
                         {tipoProgramaRutina === true  &&
                              <>
                                   <Col span={11}>
                                        <Form.Item
                                             name='areaFuncional'
                                             label='Área Funcional'
                                             rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                             <Select allowClear
                                                  style={{ width: '100%' }}
                                                  placeholder = "Selecciona el área funcional"
                                                  onChange = {onChangeSelect}
                                                  loading = {fetchingListaAreasFuncionalesActivas}
                                             >
                                             {listaAreasFuncionalesActivas.map(option => <Option key={option.codigoArea + " - " + option.idAreaFuncional +  option.areaFuncional}>{  option.areaFuncional }</Option>)}
                                             </Select>
                                        </Form.Item>
                                   </Col>
                                   
                                   <Col span={11}>
                                        <Form.Item
                                             name='unidadFuncional'
                                             label='Unidad Funcional'
                                             rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                             <Select allowClear
                                                  style={{ width: '100%' }}
                                                  disabled={disabledUnidades}
                                                  placeholder="Selecciona el unidad funcional"
                                                  loading={fetchingListaUnidadesFuncionalesActivas}
                                             >
                                             {listaUnidadesFuncionalesActivas.map(option => <Option key={option.claveUnidad + " - " + option.unidadFuncional}>{  option.unidadFuncional }</Option>)}
                                             </Select>
                                        </Form.Item>
                                   </Col>
                              </>
                         }
                         <Col span={11}>
                              <Form.Item
                                   label="Rango de Fecha"
                                   name="rangoFechas"
                              >
                                   <RangePicker
                                        format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Fecha inicio", "Fecha fin"]}
                                        // disabledDate={disabledRange}
                                   />
                              </Form.Item>
                         </Col>
                    </Row>
                    <Row style={{padding:'1em'}}>
                         <Col span={6}>
                              <Button className="btnFiltrar" onClick={() => onHandleSteps('anterior')} style={{ width: '100%', marginTop: '20px' }}>Anterior</Button>
                         </Col>
                         <Col span={6} offset={12}>
                              <Button className="btnFiltrar"  htmlType='submit' style={{ width: '100%', marginTop: '20px' }}>Siguiente</Button>
                         </Col>
                    </Row>
                    
               </Form>
          </>
     )
}

const mapStateToProps = state => {
     return {

          formularioReporte: state.ServiciosPlanificadosReducer.formularioReporte,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,

          listaGrupoServiciosByIdUsuario: state.ServiciosPlanificadosReducer.listaGrupoServiciosByIdUsuario,
          fetchingListaGrupoServiciosByIdUsuario: state.ServiciosPlanificadosReducer.fetchingListaGrupoServiciosByIdUsuario,

          fetchingListaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.fetchingListaClasificacionesActivos,
          listaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.listaClasificacionesActivos,

          listaServiciosByIdsGerencias: state.CatalogosGeneralesReducer.listaServiciosByIdsGerencias,
          fetchingListaServiciosByIdsGerencias: state.CatalogosGeneralesReducer.fetchingListaServiciosByIdsGerencias,

          fetchingListaGruposServicios: state.CatalogosGeneralesReducer.fetchingListaGruposServicios,
          listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,

          fetchingListaGerenciasByIdUsuario: state.ServiciosPlanificadosReducer.fetchingListaGerenciasByIdUsuario,
          listaGerenciasByIdUsuario: state.ServiciosPlanificadosReducer.listaGerenciasByIdUsuario,

          listaServiciosByIdsGrupos: state.CatalogosGeneralesReducer.listaServiciosByIdsGrupos,
          fetchingListaServiciosByIdsGrupos: state.CatalogosGeneralesReducer.fetchingListaServiciosByIdsGrupos,

          serviciosByIdUsuarioGrupos: state.CatalogosGeneralesReducer.serviciosByIdUsuarioGrupos,
          serviciosByIdUsuarioGerencia: state.CatalogosGeneralesReducer.serviciosByIdUsuarioGerencia,

          listaAreasFuncionalesActivas: state.AlmacenesReducer.listaAreasFuncionalesActivas,
          fetchingListaAreasFuncionalesActivas: state.AlmacenesReducer.fetchingListaAreasFuncionalesActivas,
          listaUnidadesFuncionalesActivas: state. AlmacenesReducer.listaUnidadesFuncionalesActivas,
          fetchingListaUnidadesFuncionalesActivas: state.AlmacenesReducer.fetchingListaUnidadesFuncionalesActivas,

          listaPartidasByIdsServicios: state.ConfiguracionYSeguridadReducer.listaPartidasByIdsServicios,
          fetchingListaPartidasByIdsServicios: state.ConfiguracionYSeguridadReducer.fetchingListaPartidasByIdsServicios,
     }
}

const mapDispatchToProps = dispatch => {
     return {
          onGerencias: () => {
               dispatch({ type: 'LISTA_CLASIFICACION_ACTIVOS_REQUEST', reporteDinamicoES: true })
          },
          onListaGrupoSer: () => {
               dispatch({ type: 'LISTA_GRUPOS_SERVICIOS_REQUEST' })
          },
          onGetReporteDinamico: (formulario, page, order, checkboxData) => {
               dispatch({ type: 'LISTA_REPORTE_DINAMICO_SP_REQUEST', formulario, page, order, checkboxData});
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO_RH', formularioReporte: {},listaReporteDinamicoBusqueda: [], listaReporteDinamicoBusquedaCantidad: null})
          },
          onGetServiciosActivos: () => {
               dispatch({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST'})
          },
          onGetReporteDinamicoAgrupado: (formulario, page, agrupado) => {
               dispatch({ type: 'REPORTE_DINAMICO_AGRUPADO_RH_REQUEST', formulario, page, agrupado});
          },
          onListaProveedoresFiltro: (busqueda) => {
               dispatch({ type: "LISTA_PROVEEDORES_PARAM_REQUEST", busqueda })
          },
          onListaProveedoresFiltroLimpiar: () => {
               dispatch({ type: "LISTA_PROVEEDORES_PARAM_SUCCESS", listaParamProveedores: []})
          },
          onListaServiciosByIdsGerencias: (idsGerencias) => {
               dispatch({ type: 'LISTA_SERVICIOS_BY_IDGERENCIAS_REQUEST', idsGerencias });
          },
          onListaServiciosByIdsGrupos: (idsGruposSer) => {
               dispatch({ type: 'LISTA_SERVICIOS_BY_IDSGRUPOS_REQUEST', idsGruposSer });
          }, 
          onGetUnidades: (idAreaFuncional) => {
               dispatch({ type: "LISTA_UNIDAD_FUNCIONAL_REQUEST" , idAreaFuncional});
          },
          onGetAreas: () => {
               dispatch({ type: "LISTA_AREA_FUNCIONAL_ACTIVA_REQUEST" });
          }, 

          onGetPartidasByIdsServicios: (idsServicios) => {
               dispatch({ type: 'LISTA_PARTIDAS_BY_IDSSERVICIOS_REQUEST', idsServicios });
          },
          onCleanPartidasByIdsServicios: () => {
               dispatch({ type: 'LISTA_PARTIDAS_BY_IDSSERVICIOS_SUCCESS', listaPartidasByIdsServicios: [] });
          },
     }
}

export default connect (mapStateToProps, mapDispatchToProps) (FilterSection);