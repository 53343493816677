import React, { useContext, useState, useEffect, useRef } from 'react';
import {Input,Form} from 'antd';
import {EditableContext} from './TareasTable';

const CeldaEditable = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    handleError,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef();
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);
  
    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };
  
    const save = async (e) => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record, ...values });
        handleError(false);
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
        handleError(true);
      }
    };
  
    let childNode = children;
    const programa = record?.programacionRutinasByIdProgramacionRutina?.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado?.rutinaByIdRutina?.programa;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: (dataIndex === 'resultados') && (programa === 'Prueba') ? true: false,
              message: 'Favor de llenar el campo',
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
  
    return <td {...restProps}>{childNode}</td>;
  };

  export default CeldaEditable;