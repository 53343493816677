import React from 'react';
import { Form, Row, Col, Modal, Input, Spin, Select, Button, Divider, DatePicker, InputNumber, message, Alert, Tooltip,Collapse, Switch } from 'antd';
import { connect } from "react-redux";
import { CaretRightOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import moment from 'moment';
import EditarCapturaConsumo from './EditarCapturaConsumo';

const { Option } = Select;
const { Panel } = Collapse;
const { confirm } = Modal;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
};

class ModalCapturaConsumos extends React.Component {
     formRef = React.createRef();

     state = {
          page: 1,
          ubicacionDepo: 'Ubicación',
          boton: false,
          boolean: true,
          admin: false,
          messageAlert: (<p>Favor de seleccionar un horario para proceder con la captura de éste servicio público.
               </p>),
          typeAlert: 'info',
     }

     render() {
          const { 
               page,
               busqueda,
               onCloseModal,
               fetchingCapturarLectura,
               showModalCapturaConsumos,
               servicioPublicoSeleccionado,

               listaUnidadesDeMedicionActivos,
               fetchingListaUnidadesDeMedicionActivos,
               fetchingListaVariablesMedicionActivos, 
               listaVariablesMedicionActivos,
               fetchingListaServiciosPublicosActivos,
               listaServiciosPublicosActivos,
               fetchingListaDepositosByIdServiciosPublicos,
               listaDepositosByIdServiciosPublicos,
               fetchingProgramacionByServicio,
               programacionByServicio,
               programacionHorariosByIdProgramacionConsumo,
               onVariableByProgramacion,
               onLimpiarVariableByProgramacion,
               variableMedicionByProgramacion,
               fetchingVariableMedicionByProgramacion,
               onLimpiarProgramacionByServicio,
               horariosByServicio,
               fetchingHorariosByServicio,
               onRegistroLectura,
               onVerificar,
               onUnidadByCategoria,
               fetchingUnidadesMediPorCategoria,
               unidadesMediPorCategoria,
               horariosByServicioAdmin,
               fetchingHorariosByServicioAdmin,
               onDepositoByidServicioPublico,
               onLimpiarDepositoByidServicioPublico,
               listaInitialValueDepos,
               onLimpiarUnidades,
          } = this.props;

          let hoy = new Date().toLocaleDateString();

          const handleCancel = () => {
               onCloseModal();
               this.setState({ boton: false, boolean: true, admin: false, messageAlert: (<p>Favor de seleccionar un horario para proceder con la captura de éste servicio público.
                    </p>),
               typeAlert: 'info',});
               onLimpiarProgramacionByServicio();
               onLimpiarVariableByProgramacion();
               onLimpiarDepositoByidServicioPublico();
               onLimpiarUnidades();
               this.formRef.current.resetFields();
          };

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
               permisosUsuario = JSON.parse(permisosUsuario);
          }

          const onFinish = formulario => {
               let fecha = new Date().toLocaleDateString();
               let listaCaptura = [];
               let listaCapturaConjunta = listaDepositosByIdServiciosPublicos;

               console.log('Success:', formulario);

               if(listaDepositosByIdServiciosPublicos.length === 0){
                    message.warning(
                         <p>Éste servicio público aún no cuenta con una <strong>asignación de ubicación y capacidad</strong> definida, favor de verificarlo.</p>
                         , 7);
                    this.setState({ boton: true });
               }

               for (var key in formulario) {
                    if (Object.prototype.hasOwnProperty.call(formulario, key)) {
                         let idCampo = key.split('-');
                         if (idCampo.length === 2) {
                              let idCaptura = idCampo[1];
                              let campo = idCampo[0];
                              let valor = formulario[key];
          
                              let capturaFormulario = {[campo]: valor, 'idCaptura': parseInt(idCaptura)};
                              listaCaptura.push(capturaFormulario);
                         }
                    }
               }

                    for(let i = 0; i < listaDepositosByIdServiciosPublicos.length; i++){
                         for(let j = 0; j < listaCaptura.length; j++){
                              if(listaDepositosByIdServiciosPublicos[i].idAsignacionServicioPublico === listaCaptura[j].idCaptura && listaCaptura[j].lecturaRegistrada){
                                   listaCapturaConjunta[i].lecturaRegistrada = listaCaptura[j].lecturaRegistrada
                              }
                              if(listaDepositosByIdServiciosPublicos[i].idAsignacionServicioPublico === listaCaptura[j].idCaptura && listaCaptura[j].idVariableMedicion){
                                   listaCapturaConjunta[i].idVariableMedicion = listaCaptura[j].idVariableMedicion
                              }
                              if(listaDepositosByIdServiciosPublicos[i].idAsignacionServicioPublico === listaCaptura[j].idCaptura && listaCaptura[j].idUnidadMedida){
                                   listaCapturaConjunta[i].idUnidadMedida = listaCaptura[j].idUnidadMedida
                              }
                         }
                    }
               
               //onVerificar(formulario, listaCapturaConjunta, page, busqueda);  

               onRegistroLectura(formulario, listaCapturaConjunta, page, busqueda);
               
               this.setState({ boton: false, boolean: true, admin: false, messageAlert: (<p>Favor de seleccionar un horario para proceder con la captura de éste servicio público.
                    </p>),
               typeAlert: 'info',});
               onLimpiarProgramacionByServicio();
               onLimpiarVariableByProgramacion();
               this.formRef.current.resetFields();
               onLimpiarDepositoByidServicioPublico();
               onLimpiarUnidades();

          };

          const onHorarioSeleccioando = (value) => {
               if(programacionByServicio.length === 0){
                    message.warning(
                         <p>Éste servicio público aún no cuenta con una <strong>programación para la medición de consumos</strong> definida, favor de verificarlo.</p>
                         , 7);
                    this.setState({ boton: true });
               }
               
               if(value){
                    for(let i = 0; i < listaDepositosByIdServiciosPublicos.length; i++){
                         this.formRef.current.resetFields(['idUnidadMedida-' + listaDepositosByIdServiciosPublicos[i].idAsignacionServicioPublico, 'idVariableMedicion-' + listaDepositosByIdServiciosPublicos[i].idAsignacionServicioPublico, 'lecturaRegistrada-' + listaDepositosByIdServiciosPublicos[i].idAsignacionServicioPublico]);
                    }
               }
               onDepositoByidServicioPublico(servicioPublicoSeleccionado.idServicioPublico, value);

               if(listaDepositosByIdServiciosPublicos.length === 0){
                    this.setState({ messageAlert: (<p>Éste servicio público aún no cuenta con una <b>asignación de ubicación y capacidad</b> definida, favor de verificarlo.
                         </p>), typeAlert: 'warning' });
               } 
          };

          if(programacionByServicio.length > 0 && this.state.boolean === true){
               onVariableByProgramacion(programacionByServicio[0].idProgramacionConsumo);
               this.setState({ boolean: false });
          }

          const onVariable = (value, idAsignacionServicioPublico) => {
               for(let i = 0; i < variableMedicionByProgramacion.length; i++){
                    if(variableMedicionByProgramacion[i].idVariableMedicion === parseInt(value)){
                         onUnidadByCategoria(variableMedicionByProgramacion[i].idCategoriaUnidadMedida);
                    }
               }

               this.formRef.current.resetFields(['idUnidadMedida-' + idAsignacionServicioPublico]);
          };

          const onPermisoUsuario = (checked) => {
               this.setState({admin: checked});
               this.formRef.current.resetFields(['idProgramacionHorario']);
               for(let i = 0; i < listaDepositosByIdServiciosPublicos.length; i++){
                    this.formRef.current.resetFields(['idUnidadMedida-' + listaDepositosByIdServiciosPublicos[i].idAsignacionServicioPublico, 'idVariableMedicion-' + listaDepositosByIdServiciosPublicos[i].idAsignacionServicioPublico, 'lecturaRegistrada-' + listaDepositosByIdServiciosPublicos[i].idAsignacionServicioPublico]);
               }
          };

          return (
               <Modal
                    visible={showModalCapturaConsumos}
                    title={'Captura de Lectura de Servicio Público'}
                    onCancel={handleCancel}
                    footer={false}
                    width={'80%'}
               >
                    { servicioPublicoSeleccionado ? (
                         <>
                              <Form {...layout}
                                   name="formulario"
                                   //initialValues={listaInitialValueDepos}
                                   onFinish={onFinish}
                                   ref={this.formRef}
                              >

                                   <Row justify='space-around'>
                                        <Col span={11}>
                                             <Form.Item
                                                  name='nombreDeposito'
                                                  label='Depósito de Almacenamiento'
                                                  rules={[{ required: false, message: 'Favor de llenar el campo'}]}
                                             >
                                                  <Input disabled defaultValue={servicioPublicoSeleccionado.nombreDeposito} />
                                             </Form.Item>
                                        </Col>

                                        <Col span={4}>
                                             <Form.Item
                                                  name='fecha'
                                                  label='Fecha'
                                                  rules={[{ required: false, message: 'Favor de llenar el campo'}]}
                                             >
                                                  <DatePicker defaultValue={moment(hoy, 'DD/MM/YYYY')} format={'DD/MM/YYYY'} disabled={true}/>

                                             </Form.Item>
                                        </Col>
                                        { !this.state.admin ? (
                                        <>
                                        { horariosByServicio ? (
                                             <Col span={3}>
                                                  <Form.Item
                                                       name='idProgramacionHorario'
                                                       label='Horario'
                                                       rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                                  >
                                                       <Select
                                                            allowClear
                                                            style={{ width: '100%' }}
                                                            placeholder="Seleccione una variable de medicion"
                                                            loading={fetchingHorariosByServicio}
                                                            onChange={onHorarioSeleccioando}
                                                            
                                                       >
                                                            {horariosByServicio.map(option => 
                                                                 <Option key={option.idProgramacionHorario} >
                                                                      {option.horarioSelect}
                                                                 </Option>
                                                            )}
                                                       </Select> 
                                                  </Form.Item>
                                             </Col>
                                        ): false }
                                        </>
                                        ): (
                                             <Col span={3}>
                                                  <Form.Item
                                                       name='idProgramacionHorario'
                                                       label='Horario'
                                                       rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                                  >
                                                       <Select
                                                            allowClear
                                                            style={{ width: '100%' }}
                                                            placeholder="Seleccione una variable de medicion"
                                                            loading={fetchingHorariosByServicioAdmin}
                                                            onChange={onHorarioSeleccioando}
                                                            
                                                       >
                                                            {horariosByServicioAdmin.map(option => 
                                                                 <Option key={option.idProgramacionHorario} >
                                                                      {option.horarioSelect}
                                                                 </Option>
                                                            )}
                                                       </Select> 
                                                  </Form.Item>
                                             </Col>
                                        ) }

                                        { programacionByServicio[0] ? (
                                             <Tooltip title='Periodo en minutos para la vigencia de la captura.'>
                                                  <Col span={3}>
                                                       <Form.Item
                                                            name='periodo'
                                                            label='Periodo Vigencia'
                                                            rules={[{ required: false, message: 'Favor de llenar el campo'}]}
                                                       >
                                                            <Input
                                                                 disabled
                                                                 defaultValue={programacionByServicio[0].vencimientoMinutos + ' min.'}
                                                            />
                                                       </Form.Item>
                                                  </Col>
                                             </Tooltip>
                                        ): false }

                                        { permisosUsuario.especialServiciosPúblicos ? (
                                             <>
                                             <Col span={24}>
                                                  <Collapse
                                                       bordered={false}
                                                       defaultActiveKey={['1']}
                                                       expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                                       className="site-collapse-custom-collapse"
                                                  >
                                                       <Panel header="Usuario Administrador" className="site-collapse-custom-panel">
                                                            <Alert message=
                                                                 {<p>Si necesita habilitar un <b>"Horario"</b> posterior al tiempo establecido para su vencimiento, es necesario activar éste permiso de usuario.
                                                                 </p>}
                                                                 type="info" showIcon style={{ borderRadius: '2em' }}
                                                            />
                                                            <br/>
                                                            <Switch
                                                                 checkedChildren={'Desactivar'}
                                                                 unCheckedChildren={'Activar'}
                                                                 defaultChecked={false}
                                                                 size={'default'}
                                                                 onChange={onPermisoUsuario}
                                                            />
                                                       </Panel>
                                                  </Collapse>
                                             </Col> 
                                             </>
                                        ): false }

                                        <Divider orientation='left' style={{fontSize: 14}}>Captura del Servicio: <b style={{color:'#1747ca'}}>{ servicioPublicoSeleccionado.nombre }</b></Divider>

                                        { listaDepositosByIdServiciosPublicos.length === 0 ? (
                                             <Col span={24}>
                                                  <Alert message=
                                                  {this.state.messageAlert}
                                                  type={this.state.typeAlert} showIcon closable style={{ borderRadius: '2em' }}/>
                                             </Col>
                                        ): (
                                        <Col span={24}>

                                        { listaDepositosByIdServiciosPublicos[0].lecturaRegistrada !== null ? (
                                             <Col span={24}>
                                                  <Alert 
                                                       message={'El servicio público en el horario seleccionado ya cuenta con una captura de lectura, si es capturado nuevamente se guardarán los nuevos registros.'}
                                                       type={"success"}
                                                       showIcon
                                                       closable style={{ borderRadius: '2em' }}
                                                  />
                                             </Col>
                                        ): false }

                                        {/* { listaDepositosByIdServiciosPublicos[0].lecturaRegistrada == null ? (
                                             <> */}
                                             {/* //////////////////////////////////////////////////////////////// */}
                                             {listaDepositosByIdServiciosPublicos.map(listaDepositosByIdServiciosPublicos =>
                                                  <Row justify='space-around' key={listaDepositosByIdServiciosPublicos.idAsignacionServicioPublico}>
                                                       <Col span={10}>
                                                            <Form.Item
                                                                 name={'ubicacion-' + listaDepositosByIdServiciosPublicos.idAsignacionServicioPublico}
                                                                 label={<strong>Ubicación </strong>}
                                                            >
                                                                 <p>{listaDepositosByIdServiciosPublicos.claveUbicacion + ': '}<i>{listaDepositosByIdServiciosPublicos.nombreUbicacion}</i></p>
                                                            </Form.Item>
                                                       </Col>
                                                       <Col span={3}>
                                                            <Form.Item
                                                                 name={`lecturaRegistrada-${listaDepositosByIdServiciosPublicos.idAsignacionServicioPublico}`}
                                                                 label='Lectura'
                                                                 rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                                            >
                                                                 <InputNumber
                                                                      placeholder='Lectura'
                                                                      min={0}
                                                                 />
                                                            </Form.Item>
                                                       </Col>
                                                       <Col span={5}>
                                                            <Form.Item
                                                                 //name={'variableMedicion-' + listaDepositosByIdServiciosPublicos.idAsignacionServicioPublico}
                                                                 name={`idVariableMedicion-${listaDepositosByIdServiciosPublicos.idAsignacionServicioPublico}`}
                                                                 label='Variable de Medición'
                                                                 rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                                            >
                                                                 <Select
                                                                      allowClear
                                                                      style={{ width: '100%' }}
                                                                      placeholder="Seleccione una variable de medicion"
                                                                      loading={fetchingVariableMedicionByProgramacion}
                                                                      onChange={(value) => onVariable(value, listaDepositosByIdServiciosPublicos.idAsignacionServicioPublico)}
                                                                 >
                                                                      {variableMedicionByProgramacion.map(option => <Option key={option.idVariableMedicion}>{ option.nombre}</Option>)}
                                                                 </Select> 
                                                            </Form.Item>
                                                       </Col>

                                                       <Col span={5}>
                                                            <Form.Item
                                                                 // name={'unidadMedida-' + listaDepositosByIdServiciosPublicos.idAsignacionServicioPublico}
                                                                 name={`idUnidadMedida-${listaDepositosByIdServiciosPublicos.idAsignacionServicioPublico}`}
                                                                 label='Unidad de Medida'
                                                                 rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                                            >
                                                                 <Select
                                                                      allowClear
                                                                      style={{ width: '100%' }}
                                                                      placeholder="Seleccione una unidad de medida"
                                                                      loading={fetchingUnidadesMediPorCategoria}
                                                                 >
                                                                      {unidadesMediPorCategoria.map(option => <Option key={option.idUnidadMedida}>{ option.unidadMedida}</Option>)}
                                                                 </Select>    
                                                            </Form.Item>
                                                       </Col>
                                                  </Row>
                                             )}
                                   
                                             {/* </>
                                        ): false } */}
                                            
                                        </Col>
                                        ) }

                                        <Divider style={{margin: '1px'}}/>

                                        <Col span={11}>
                                             <br/> <br/>
                                             <Button className='btnPrueba' style={{ width: '100%' }} htmlType='submit' disabled={this.state.boton}>
                                                  Guardar
                                             </Button>
                                        </Col>

                                   </Row>

                              </Form>

                              
                              {/* { listaDepositosByIdServiciosPublicos[0] ? (
                                   <>
                                   { listaDepositosByIdServiciosPublicos[0].lecturaRegistrada !== null ? (
                                        <EditarCapturaConsumo />
                                   ): false}
                                   </>
                              ): false} */}
                         </>
                    ) : false }                
               </Modal>
          );             
     }
}

const mapStateToProps = state => {
     return {
          page: state.ServiciosPublicosReducer.page,
          busqueda: state.ServiciosPublicosReducer.busqueda,
          fetchingCapturarLectura: state.ServiciosPublicosReducer.fetchingCapturarLectura,
          showModalCapturaConsumos: state.ServiciosPublicosReducer.showModalCapturaConsumos,
          servicioPublicoSeleccionado: state.ServiciosPublicosReducer.servicioPublicoSeleccionado,

          listaUnidadesDeMedicionActivos: state.AlmacenesReducer.listaUnidadesDeMedicionActivos,
          fetchingListaUnidadesDeMedicionActivos: state.AlmacenesReducer.fetchingListaUnidadesDeMedicionActivos,
          fetchingListaVariablesMedicionActivos: state.ServiciosPublicosReducer.fetchingListaVariablesMedicionActivos, 
          listaVariablesMedicionActivos: state.ServiciosPublicosReducer.listaVariablesMedicionActivos,
          fetchingListaServiciosPublicosActivos: state.ServiciosPublicosReducer.fetchingListaServiciosPublicosActivos, 
          listaServiciosPublicosActivos: state.ServiciosPublicosReducer.listaServiciosPublicosActivos,
          fetchingListaDepositosByIdServiciosPublicos: state.ServiciosPublicosReducer.fetchingListaDepositosByIdServiciosPublicos,
          listaDepositosByIdServiciosPublicos: state.ServiciosPublicosReducer.listaDepositosByIdServiciosPublicos,

          fetchingProgramacionByServicio: state.ServiciosPublicosReducer.fetchingProgramacionByServicio,
          programacionByServicio: state.ServiciosPublicosReducer.programacionByServicio,
          programacionHorariosByIdProgramacionConsumo: state.ServiciosPublicosReducer.programacionHorariosByIdProgramacionConsumo,
          variableMedicionByProgramacion: state.ServiciosPublicosReducer.variableMedicionByProgramacion,
          fetchingVariableMedicionByProgramacion: state.ServiciosPublicosReducer.fetchingVariableMedicionByProgramacion,
          horariosByServicio: state.ServiciosPublicosReducer.horariosByServicio,
          fetchingHorariosByServicio: state.ServiciosPublicosReducer.fetchingHorariosByServicio,
          horariosByServicioAdmin: state.ServiciosPublicosReducer.horariosByServicioAdmin,
          fetchingHorariosByServicioAdmin: state.ServiciosPublicosReducer.fetchingHorariosByServicioAdmin,

          unidadesMediPorCategoria: state.AlmacenesReducer.unidadesMediPorCategoria,
          fetchingUnidadesMediPorCategoria: state.AlmacenesReducer.fetchingUnidadesMediPorCategoria,

          listaInitialValueDepos: state.ServiciosPublicosReducer.listaInitialValueDepos,
          verificacion: state.ServiciosPublicosReducer.verificacion
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onCloseModal: () => {
               dispatch({ type: 'SHOW_MODAL_CAPTURA_CONSUMOS', showModalCapturaConsumos: false });
          },
          onVariableByProgramacion: (idProgramacionConsumo) => {
               dispatch({ type: 'VARIABLES_POR_PROGRAMACION_REQUEST', idProgramacionConsumo });
          },
          onLimpiarVariableByProgramacion: () => {
               dispatch({ type: 'VARIABLES_POR_PROGRAMACION_SUCCESS', variableMedicionByProgramacion: [] });
          },
          onLimpiarProgramacionByServicio: () => {
               dispatch({ type: 'PROGRAMACION_POR_SERVICIO_SUCCESS', programacionByServicio: [], programacionHorariosByIdProgramacionConsumo: [] });
          },
          onRegistroLectura: (formulario, listaDepositosByIdServiciosPublicos, page, busqueda) => {
               dispatch({ type: 'REGISTRO_LECTURA_REQUEST', formulario, listaDepositosByIdServiciosPublicos, page, busqueda});
          },
          onVerificar: (formulario, listaConjunta, page, busqueda) => {
               dispatch({ type: 'VERIFICACION_REGISTRO_LECTURA_REQUEST', formulario, listaConjunta, page, busqueda});
          },
          onUnidadByCategoria: (idCategoriaUnidadMedida) => {
               dispatch({ type: 'UNIDADES_MEDI_POR_CATEGORIA_REQUEST', idCategoriaUnidadMedida });
          },
          onDepositoByidServicioPublico: (idServicioPublico, idProgramacionHorario) => {
               dispatch({ type: 'LISTA_DEPOSITO_BY_SERVICIO_REQUEST', idServicioPublico, idProgramacionHorario});
          },
          onLimpiarDepositoByidServicioPublico: () => {
               dispatch({ type: 'LISTA_DEPOSITO_BY_SERVICIO_SUCCESS', listaDepositosByIdServiciosPublicos: []});
          },
          onLimpiarUnidades: () => {
               dispatch({ type: 'UNIDADES_MEDI_POR_CATEGORIA_SUCCESS', unidadesMediPorCategoria: [] });
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCapturaConsumos);