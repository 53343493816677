import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {
    Row,
    Col,
    Modal,
    message,
    Spin,
    Form,
    DatePicker,
    Select,
    InputNumber,
    Button,
    Popover,
    Upload,
    Input
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option } = Select;
const { RangePicker } = DatePicker;
const confirm = Modal.confirm;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalCDDFacturacion extends React.Component {
    formRef = React.createRef();

    state = {
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
        data: null,
        precioUnitario: null,
        consumoPyrs: null,
        consumoDesarrollador: null,
        totalFacturado: null,
    }

    componentDidMount(){
        this.props.onGetUnidadesMedicion();
        this.props.onListaMeses();
    }

    render() {

        const {limpiarFormulario, showModalCalculoCDFacturacion, fetchingCalculoCDFacturacion, listaInfoProyecto, fetchingListaUbicacionesFiltro, listaUbicacionesFiltro,
              fetchingListaServiciosPublicosCalculo,listaServiciosPublicosCalculo, fetchingListaUnidadesDeMedicionActivos, listaUnidadesDeMedicionActivos, onShowModalCCDFacturacion, 
            onListaUbicacionesFiltro, onListaUbicacionesFiltroLimpiar, onCrearCalculoFacturacion, onDescargarPDF, onDescargarExcel, fetchingListaMesesEvaluacion, listaMesesEvaluacion
        } = this.props;

        const {data, precioUnitario, consumoPyrs, consumoDesarrollador, totalFacturado} = this.state;

        if (limpiarFormulario && limpiarFormulario === 'CCDF') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        const disabledRange = fecha => {
            const fechaVigencia = moment(listaInfoProyecto.fechaVigencia, "DD-MM-YYYY")
            const fechaInicio = moment(listaInfoProyecto.fechaInicioOperacion, "DD-MM-YYYY")
            if (fecha && fecha < fechaInicio) {
                return fecha && fecha < fechaInicio;
            } else {
                return fecha && fecha > fechaVigencia;
            }
        };

        const handleSearch = (value) => {
            if(value.length>=3){
                if (value) {
                    console.log(value)
                    onListaUbicacionesFiltro(value);
                } else {
                    message.warning('No se encontró ubicación con esas palabras')
                }
            }
            else{
                onListaUbicacionesFiltroLimpiar();
            }
        };

        const changeCosto = (value, campo) => {
            const precio = campo == 'precioUnitario' ?  parseFloat(value).toFixed(3) : parseFloat(value).toFixed(2);
            this.formRef.current.setFieldsValue({[campo]: precio})
            this.setState({[campo]:precio});
            
            if(precioUnitario  && consumoPyrs) {
                const cobroConsumoPyrs = (consumoPyrs * precioUnitario).toFixed(2);
                this.formRef.current.setFieldsValue({'cobroConsumoPyrs':cobroConsumoPyrs});
            }

            if(precioUnitario  && consumoDesarrollador) {
                const cobroConsumoDesarrollador = (consumoDesarrollador * precioUnitario).toFixed(2);
                this.formRef.current.setFieldsValue({'cobroConsumoDesarrollador':cobroConsumoDesarrollador});
            }
        }

        const cobroCalculo = (consumo, precio, campo) => {
            const cobroCampo = campo === 'consumoPyrs' ? 'cobroConsumoPyrs' : 'cobroConsumoDesarrollador';
            if (precio && consumo) {
                const cobroConsumo = (consumo * precio).toFixed(2);
                this.formRef.current.setFieldsValue({[cobroCampo]:cobroConsumo})
            } else {
                this.formRef.current.setFieldsValue({[cobroCampo]: null})
            }
        }

        const onCalcularCobro = (value, campo) => {
            this.setState({[campo]: value}, () => cobroCalculo(value, precioUnitario, campo)); 
        }

        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                })
            }
            var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
            if (info.file.status === 'done') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['rutaArchivo']);
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['rutaArchivo']);
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }
        const onRemovePDF = () => {
            this.formRef.current.resetFields(['rutaArchivo']);
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };

        const onFinish = formulario => {
            console.log(formulario);
            this.setState({data: formulario});
            if (consumoDesarrollador || consumoPyrs){
                const cobroTotal = (consumoDesarrollador*precioUnitario) + (consumoPyrs*precioUnitario);
                if (parseFloat(totalFacturado) !== cobroTotal ) {
                    confirm({
                        title: 'La suma de cobro por consumo cliente y Desarrollador no coincide con el Total Facturado.¿Estás seguro que deseas crear el cálculo?',
                        content: '',
                        okText: 'Sí',
                        okType: 'danger',
                        cancelText: 'No',
                        onOk() {
                            console.log('Hecho')
                            onCrearCalculoFacturacion(formulario);
            },
                        onCancel() {
                            console.log('Cancel');
                        },
                    });
                } else {
                    onCrearCalculoFacturacion(formulario);
                }
            } else {
                message.warning('Favor de llenar el campo consumo cliente o desarrollador.')
            }
        }

        const onFinishFailed = () => {
            console.log('Cancel');
        }

        const onDescargarArchivo = (type) => {
            console.log(type);
            if (type == 'PDF') {
                onDescargarPDF(data);
            }
            if (type == 'Excel') {
                onDescargarExcel(data);
            }
        }

        const handleCancel = () => {
            onShowModalCCDFacturacion()
            this.setState({filePDF: false, botonPDF: false, botonEditar: false, data: null, precioUnitario: null, consumoPyrs:null, consumoDesarrollador: null, totalFacturado:null});
            this.formRef.current.resetFields();
        }
     
        return (
            <div>
                <Modal
                    visible={showModalCalculoCDFacturacion}
                    title={`Cálculo de Consumo Diferenciado por Facturación`}
                    onCancel={handleCancel}
                    footer={false}
                    width='70%'
                >
                    <Spin spinning={fetchingCalculoCDFacturacion} >
                        <Form {...layout}
                                name='formulario'
                                onFinish={onFinish}
                                
                                ref={this.formRef}
                        >
                            <Row type='flex' justify='space-around' align="middle">
                                <Col span={11}>
                                    <Form.Item
                                        label='Servicio públicos'
                                        name='idServicioPublico'
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select
                                            allowClear
                                            style={{ width: '100%', textAlign: 'left' }}
                                            placeholder='Seleccione un servicio público'
                                            loading={fetchingListaServiciosPublicosCalculo}
                                        >
                                            {listaServiciosPublicosCalculo.map(option => <Option key={option.idServicioPublico} value={option.idServicioPublico} nombreDeposito={option.nombreDeposito}>{option.nombre}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={11} >
                                    <Form.Item
                                        label="Periodo"
                                        name="periodo"
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <RangePicker
                                            format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio", "Fecha fin"]}
                                            disabledDate={disabledRange}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label='Mes'
                                        name='idMes'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <Select allowClear
                                                style={{width: '100%'}}
                                                placeholder="Selecciona el mes"
                                                loading={fetchingListaMesesEvaluacion}
                                                showSearch={true}
                                        >
                                            {listaMesesEvaluacion.map(option => <Option
                                                key={option.idMes}>{option.mes}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label='Número de Factura'
                                        name='numeroFactura'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <Input maxLength={100} placeholder='Ingresa el número de factura'/>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label='Ciclo de Facturación'
                                        name='cicloFacturacion'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                        <Select
                                            allowClear
                                            style={{width: '100%', textAlign: 'left'}}
                                            placeholder='Seleccione un ciclo de facturación'
                                            >
                                                <Option value='Mensual'>Mensual</Option>
                                                <Option value='Bimestral'>Bimestral</Option>
                                                <Option value='Trimestral'>Trimestral</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label='Total Facturado'
                                        name='totalFacturado'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <InputNumber min={0} style={{ width: '100%' }}
                                            onChange={value => changeCosto(value, 'totalFacturado')}
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            placenholder="Ingrese total facturado" 
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={23}>
                                    <Form.Item
                                        label='Ubicación'
                                        name='ubicacion'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Ingrese la ubicación"
                                            loading={fetchingListaUbicacionesFiltro}
                                            showSearch
                                            filterOption={false}
                                            onSearch={handleSearch}
                                            notFoundContent={null}
                                            dropdownStyle={{ minWidth: "60%" }}
                                            >
                                            {listaUbicacionesFiltro.map(option =>
                                                <Option key={`${option.idUbicacion} - ${option.todoUbicacionCOA}`}>
                                                    <Popover content={option.todoUbicacionCOA} title={false}>
                                                        {`${option.idUbicacion} - ${option.todoUbicacionCOA}`}
                                                    </Popover>
                                                </Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label='Consumo Clinete '
                                        name='consumoPyrs'
                                        rules={[{ required: false, message: 'Favor de llenar el campo' }]}
                                    >
                                        <InputNumber min={0} style={{ width: '100%' }} onChange={value => onCalcularCobro(value, 'consumoPyrs')}/>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label='Consumo desarrollador '
                                        name='consumoDesarrollador'
                                        rules={[{ required: false, message: 'Favor de llenar el campo' }]}
                                    >
                                        <InputNumber min={0} style={{ width: '100%' }}  onChange={value => onCalcularCobro(value, 'consumoDesarrollador')}/>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label='Unidad de medida'
                                        name='unidadMedida'
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Seleccione una unidad de medida"
                                            loading={fetchingListaUnidadesDeMedicionActivos}
                                        >
                                            {listaUnidadesDeMedicionActivos.map(option => <Option key={option.idUnidadMedida}>{option.unidadMedida}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label='Precio unitario'
                                        name='precioUnitario'
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <InputNumber min={0} style={{ width: '100%' }}
                                            onChange={value => changeCosto(value, 'precioUnitario')}
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            placenholder="Ingrese precio unitario"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label='Cobro por consumo cliente'
                                        name='cobroConsumoPyrs'
                                    >
                                        <InputNumber disabled min={0} style={{ width: '100%' }}
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label='Cobro por consumo desarrollador'
                                        name='cobroConsumoDesarrollador'
                                    >
                                        <InputNumber disabled min={0} style={{ width: '100%' }}
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label='Archivo factura'
                                        name="rutaArchivo"
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='image/*,.pdf' multiple={false}>
                                            {this.state.filePDF === false ? (
                                                <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                                    <UploadOutlined /> Click para subir Archivo Factura
                                                </Button>
                                            ) : false}
                                        </Upload>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Button  className="btnFiltrar" style={{ width: '100%', marginTop: '14px' }} htmlType="submit">Crear Cálculo de Consumo Diferenciado por Facturación</Button>
                                </Col>
                                <Col span={11}>
                                    <Button  disabled={!data} className="btnFiltrar" style={{ width: '100%' }} onClick={() => onDescargarArchivo('Excel')}>Exportar a Excel</Button>
                                </Col>
                                <Col span={11}>
                                    <Button disabled={!data} className="btnFiltrar" style={{ width: '100%' }} onClick={() => onDescargarArchivo('PDF')}>Exportar a PDF</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>

                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaMesesEvaluacion: state.EvaluacionDeLaGestionReducer.fetchingListaMesesEvaluacion,
        listaMesesEvaluacion: state.EvaluacionDeLaGestionReducer.listaMesesEvaluacion,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        showModalCalculoCDFacturacion: state.ServiciosPublicosReducer.showModalCalculoCDFacturacion,
        fetchingCalculoCDFacturacion: state.ServiciosPublicosReducer.fetchingCalculoCDFacturacion,
        fetchingListaServiciosPublicosCalculo: state.ServiciosPublicosReducer.fetchingListaServiciosPublicosCalculo,
        listaServiciosPublicosCalculo: state.ServiciosPublicosReducer.listaServiciosPublicosCalculo,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        fetchingListaUbicacionesFiltro: state.CatalogosGeneralesReducer.fetchingListaUbicacionesFiltro,
        listaUbicacionesFiltro: state.CatalogosGeneralesReducer.listaUbicacionesFiltro,
        fetchingListaUnidadesDeMedicionActivos: state.AlmacenesReducer.fetchingListaUnidadesDeMedicionActivos,
        listaUnidadesDeMedicionActivos: state.AlmacenesReducer.listaUnidadesDeMedicionActivos,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalCCDFacturacion: () => {
            dispatch({ type: 'SHOW_MODAL_CDD_FACTURACION', showModalCalculoCDFacturacion: false})
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
        },
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        },
        onListaUbicacionesFiltro: (busqueda) => {
            dispatch({ type: 'LISTA_UBICACIONES_FILTRO_REQUEST', busqueda: busqueda });
        },
        onListaUbicacionesFiltroLimpiar: () => {
            dispatch({ type: 'LISTA_UBICACIONES_FILTRO_SUCCESS', listaUbicacionesFiltro: [] });
        },
        onGetUnidadesMedicion: () => {
            dispatch({ type: 'LISTA_UNIDADES_MEDICION_ACTIVOS_REQUEST' });
        },
        onCrearCalculoFacturacion: (formulario) => {
            dispatch({ type: 'CALCULO_CONSUMO_DIFERENCIADO_FACTURACION_REQUEST', formulario: formulario});
        },
        onDescargarPDF: (formulario) => {
            dispatch({ type: 'CALCULO_CD_FACTURACION_PDF_REQUEST', formulario:formulario});
        },
        onDescargarExcel: (formulario) => {
            dispatch({ type: 'CALCULO_CD_FACTURACION_EXCEL_REQUEST', formulario:formulario});
        },
        onListaMeses: () => {
            dispatch({type: 'LISTA_MESES_EVALUACION_REQUEST'})
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalCDDFacturacion);
