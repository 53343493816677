import React, { Component } from 'react';
import { Row, Col, Select, Table, Input, Button, Tooltip, DatePicker, Form } from 'antd';
import { connect } from "react-redux";
import { SearchOutlined, EditOutlined, PlusOutlined, FileExcelOutlined } from '@ant-design/icons';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import moment from 'moment';
import NuevoTipoCambio from './ModalNuevoTipoCambio';
import EditarTipoCambio from './ModalEditarTipoCambio';

const { RangePicker } = DatePicker;
const { Option } = Select;
class TipoCambioContainer extends Component {
     formRef = React.createRef();
     componentDidMount(){ 
          this.props.onGetListaTipoCambio(1, {}, {order: 'DESC', field: 'fecha'}); 
          this.props.onGetTiposMonedas();
     }
     state = { page: 1, busqueda: {}, orden: { order: 'DESC', field: 'fecha'} }

     render() {
          const { onGetListaTipoCambio, fetchingListaTipoMonedasActivas, listaTipoMonedasActivas, 
               listaInfoProyecto
          } = this.props;

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
               permisosUsuario = JSON.parse(permisosUsuario);
          };

          const disabledRangeToday = fecha => {
               const fechaInicio = moment(listaInfoProyecto.fechaInicioOperacion, "YYYY-MM-DD")

               let today = new Date();
               let dd = String(today.getDate()).padStart(2, '0');
               let mm = String(today.getMonth() + 1).padStart(2, '0');
               let yyyy = today.getFullYear();

               today = yyyy + '-' + mm + '-' + dd;

               if (fecha && fecha < fechaInicio) {
                   return fecha && moment(fecha.format('YYYY-MM-DD')) < fechaInicio;
               } else {
                   return fecha && moment(fecha.format('YYYY-MM-DD')) > moment(today) + 1;
               }
          };

          const columns = [
               {
                    title: 'Fecha',
                    dataIndex: 'fecha',
                    key: 'fecha',
                    sorter: true,
               }, {
                    title: 'Moneda',
                    dataIndex: 'tipoMoneda',
                    key: 'tipoMoneda',
                    align: 'center',
                    sorter: true,
               },{
                    title: 'Nomenclatura',
                    dataIndex: 'nomenclatura',
                    key: 'nomenclatura',
                    align: 'center',
                    sorter: true,
               },{
                    title: 'Tipo de Cambio',
                    dataIndex: 'tipoCambioTabla',
                    key: 'tipoCambioTabla',
                    align: 'right',
                    sorter: true,
               }, {
                    title: 'Editar',
                    key: 'editar',
                    align: 'center',
                    render: (text, record) => (
                         <a className='editarIcono' disabled={!permisosUsuario.editarInteriorFuncional} onClick={() => onEditar(record)}><EditOutlined /></a>
                    ),
               },
          ];

          const onEditar = (key) => {
               console.log(key);
               key.idTipoMonedaEditar = key.idTipoMoneda + ' - ' + key.tipoMoneda + ' (' + key.nomenclatura + ')';
               this.props.onModalEditar(key, this.state.page, this.state.busqueda, this.state.orden);
          };

          const handleTableChange = (pagination, filtro, orden)=>{
               this.setState({
                    page: pagination.current,
               })
               console.log('handleTable', pagination, filtro, orden);
               if(orden){
                    let campo = 'fecha';
                    let order = 'DESC';
                
                    if(orden.order === 'ascend'){
                        order = 'ASC';
                    }
    
                    if(orden.columnKey === 'tipoMoneda'){
                        campo = 'tpe.nombre';
                    }

                    if(orden.columnKey === 'nomenclatura'){
                         campo = 'tpe.nomenclatura';
                     }

                    if(orden.columnKey === 'tipoCambioTabla'){
                         campo = 'tce.tipoCambio';
                     }
                    const ordenLocal = {
                         order,
                         field: campo
                    };
                    onGetListaTipoCambio(pagination.current, this.state.busqueda, ordenLocal);
                    this.setState({ orden: ordenLocal, });
               }
               else{
                    onGetListaTipoCambio(pagination.current, this.state.busqueda, this.state.orden);
               }
          };

          const onFinish = formulario => { 
               console.log('Success:', formulario);
               this.setState({
                    busqueda: formulario,
               });
               if(formulario.rangoFechas){
                    formulario.fechaInicio = formulario.rangoFechas[0].format('YYYY-MM-DD');
                    formulario.fechaFin = formulario.rangoFechas[1].format('YYYY-MM-DD');
               }
               
               onGetListaTipoCambio(this.state.page, formulario, this.state.orden);
          };

          return (
               <div>
                    <Form
                         name="formulario"
                         ref={this.formRef}
                         onFinish={onFinish}
                    >
                         <Row justify='space-between' gutter={[8,8]}>
                              <Col span={10}>
                                   <Form.Item
                                        name="idsTiposMonedas"
                                   >
                                        <Select allowClear
                                             mode='multiple'
                                             style={{ width: '100%' }}
                                             placeholder="Selecciona tipos de monedas"
                                             loading={fetchingListaTipoMonedasActivas}
                                        >
                                             {listaTipoMonedasActivas.map(option => <Option key={option.idTipoMoneda + ' - ' + option.nombre + ' (' + option.nomenclatura + ')'}>{ option.idTipoMoneda + ' - ' + option.nombre + ' (' + option.nomenclatura + ')'}</Option>)}
                                        </Select>
                                   </Form.Item>
                              </Col>
                              <Col span={10}>
                                   <Form.Item
                                        name='rangoFechas'
                                   >
                                        <RangePicker
                                             format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Fecha inicio", "Fecha fin"]}
                                             disabledDate={disabledRangeToday}
                                        />
                                   </Form.Item>
                              </Col>
                              <Col span={1}>
                                   <Button shape="circle" icon={<SearchOutlined />} className="btnFiltrar" htmlType="submit"/>
                              </Col>

                              <Col span={1}>
                                   <Tooltip title='Nuevo Tipo de Cambio' placement="topRight">
                                        <Button className="iconTer" shape="circle" onClick={() => { this.props.onShowModalNuevo(this.state.page, this.state.busqueda, this.state.orden) }} icon={<PlusOutlined />}/>
                                   </Tooltip>
                              </Col>

                              <Col span={1}>
                                   <Tooltip title='Exportar archivo .xls' placement="topRight">
                                        <Button disabled={!permisosUsuario.exportarExcelCatálogoActivos} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } loading={this.props.fetchingExportarExcelTipoCambio} onClick={() => this.props.onExport(this.state.page, this.state.busqueda, this.state.orden)}/>
                                   </Tooltip>
                              </Col> 
                         </Row>
                    </Form>
                    <Col span={24}>
                         <Table
                              size="small"
                              rowKey="idTipoCambio"
                              columns={columns}
                              dataSource={this.props.listaTipoCambio}
                              loading={this.props.fetchingListaTipoCambio}
                              locale={{ emptyText: 'Sin datos' }}
                              onChange={handleTableChange}
                              pagination={{ total: this.props.listaTipoCambioCantidad, simple: true, current: this.state.page }}
                         />
                    </Col>
                    <NuevoTipoCambio />
                    <EditarTipoCambio />
               </div>
          );
     }
}

const mapStateToProps = state => {
     return {
          fetchingListaTipoCambio: state.CatalogosGeneralesReducer.fetchingListaTipoCambio,
          listaTipoCambio: state.CatalogosGeneralesReducer.listaTipoCambio,
          listaTipoCambioCantidad: state.CatalogosGeneralesReducer.listaTipoCambioCantidad,
          fetchingListaTipoMonedasActivas: state.CatalogosGeneralesReducer.fetchingListaTipoMonedasActivas,
          listaTipoMonedasActivas: state.CatalogosGeneralesReducer.listaTipoMonedasActivas,
          listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
          fetchingExportarExcelTipoCambio: state.CatalogosGeneralesReducer.fetchingExportarExcelTipoCambio,
     };
};

const mapDispatchToProps = (dispatch) => {
     return {
          onGetListaTipoCambio: (page, busqueda, orden) => {
               dispatch({ type: 'LISTA_TIPO_CAMBIO_REQUEST', page, busqueda, orden });
          },
          onGetTiposMonedas: () => {
               dispatch({ type: 'LISTA_TIPOS_MONEDAS_ACTIVOS_REQUEST' });
          },
          onShowModalNuevo: (page, busqueda, orden) => {
               dispatch({ type: 'SHOW_MODAL_NUEVO_TIPO_CAMBIO', showModalNuevoTipoCambio: true, page, busqueda, orden });
          },
          onModalEditar: (tipoCambioSeleccionado, page, busqueda, orden) => {
               dispatch({ type: "SHOW_MODAL_EDITAR_TIPO_CAMBIO", showModalEditarTipoCambio: true, tipoCambioSeleccionado, page, busqueda, orden })
          },
          onExport: (page, busqueda, orden) => {
               dispatch({ type: "EXPORTAR_EXCEL_TIPO_CAMBIO_REQUEST", page, busqueda, orden });
          },
     }
}
export default connect(mapStateToProps, mapDispatchToProps)(TipoCambioContainer);