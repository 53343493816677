import React, { Component } from 'react';
import { Form, message, Button, Row, Col, Spin, Modal, InputNumber, Divider, Upload  } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalAgregarPPL extends Component {
    formRef = React.createRef();
    state = {
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
        showVerificacion: false,
        valueDecimal: null, 
        key: null,
   }
    render() {
        const { 
            fetchingAgregarPPL, showModalAgregarPPL, onAgregarPPL, numPplMes, busqueda, onCambiarNumPPLMes,
            onShowModalAgregarPPL, limpiarFormulario, mesCalendarioSeleccionado, listaInfoProyecto,
            terminado, editando
        } = this.props;
        
        const onFinish = formulario => {
            console.log('Success:', formulario);
            
            onAgregarPPL(formulario, busqueda, mesCalendarioSeleccionado, listaInfoProyecto, editando, this.props.fechaAnterior);
        };

        if (limpiarFormulario && limpiarFormulario === 'agregarPPL') {
            //this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
            this.setState({
                filePDF: false,
                botonPDF: false,
                botonEditar: false,
            })
        }
            
        const handleCancel = () => {
            onShowModalAgregarPPL();
            
            this.formRef.current.resetFields();
            this.setState({
                filePDF: false,
                botonPDF: false,
                botonEditar: false,
            })
        }
        const changeDecimales = (value, key) => {
            console.log('value', value)
            if(this.formRef.current){
                if(value){
                    let valueDecimal = parseInt(value);
                    const mesTerminadoValues = Object.values(this.formRef.current.getFieldsValue());
                    const mesTerminadoKeys = Object.keys(this.formRef.current.getFieldsValue());
                    let terminadoMes = true;
                    console.log('mesTerminadoValues, mesTerminadoKeys', mesTerminadoValues, mesTerminadoKeys)
                    for(let i in mesTerminadoValues){
                        if(mesTerminadoKeys[i] !== 'pdf'){
                            if(!mesTerminadoValues[i]){
                                terminadoMes = false
                            }
                        }
                    }
                    console.log('terminadoMes');
                    console.log(terminadoMes);
                    if(isNaN(valueDecimal)){
                        this.formRef.current.setFieldsValue({['numPpl-' + key] : 1});
                        const totalPPlMes = Object.values(this.formRef.current.getFieldsValue()).reduce((sum, value) => (typeof value == "number" ? sum + value : sum), 0);
                        onCambiarNumPPLMes(busqueda, mesCalendarioSeleccionado, totalPPlMes, terminadoMes, editando)
                    }
                    else{
                        if(valueDecimal>=listaInfoProyecto.poblacionPtvMax){
                            this.setState({
                                showVerificacion: true,
                                valueDecimal, 
                                key,
                            })
                        }
                        else{
                            this.formRef.current.setFieldsValue({['numPpl-' + key]: valueDecimal});
                            const totalPPlMes = Object.values(this.formRef.current.getFieldsValue()).reduce((sum, value) => (typeof value == "number" ? sum + value : sum), 0);
                            onCambiarNumPPLMes(busqueda, mesCalendarioSeleccionado, totalPPlMes, terminadoMes, editando)
                        }
                    }
                }
                else{
                    this.formRef.current.setFieldsValue({['numPpl-' + key] : 1});
                    const totalPPlMes = Object.values(this.formRef.current.getFieldsValue()).reduce((sum, value) => (typeof value == "number" ? sum + value : sum), 0);
                    onCambiarNumPPLMes(busqueda, mesCalendarioSeleccionado, totalPPlMes, terminado, editando)
                }
            }
        }
        const onOkV = () => {
            this.formRef.current.setFieldsValue({['numPpl-' + this.state.key]: this.state.valueDecimal});
            const totalPPlMes = Object.values(this.formRef.current.getFieldsValue()).reduce((sum, value) => (typeof value == "number" ? sum + value : sum), 0);
            
            onCambiarNumPPLMes(busqueda, mesCalendarioSeleccionado, totalPPlMes, terminado, editando);
            this.setState({
                showVerificacion: false,
                valueDecimal: null, 
                key: null,
            })
        };
        const onCancelV = () => {
            this.formRef.current.setFieldsValue({['numPpl-' + this.state.key] : 1});
            const totalPPlMes = Object.values(this.formRef.current.getFieldsValue()).reduce((sum, value) => (typeof value == "number" ? sum + value : sum), 0);
            
            onCambiarNumPPLMes(busqueda, mesCalendarioSeleccionado, totalPPlMes, terminado, editando);
            this.setState({
                showVerificacion: false,
                valueDecimal: null, 
                key: null,
            })
        };
        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                })
            }
            var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
            if (info.file.status === 'done') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                    cargando: true
                });
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                    filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }
        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };
        return (
            <div>
                <Modal
                    visible={this.state.showVerificacion}
                    onOk={onOkV}
                    onCancel={onCancelV}
                    zIndex={3001}
                >
                    <h2>La cantidad capturada supera la Población Máxima "{listaInfoProyecto.poblacionPtvMax}", ¿desea continuar?</h2>
                </Modal>
            
                <Modal
                visible={showModalAgregarPPL}
                title='Suma de PPL del mes'
                onCancel={handleCancel}
                footer={false}
                width='80%'
                zIndex={3000}
                >
                    {mesCalendarioSeleccionado ? (
                    <Spin spinning={fetchingAgregarPPL}>
                        {mesCalendarioSeleccionado.objetoFormulario ? (
                        <Form {...layout}
                        name="formulario"
                        onFinish={onFinish}
                        ref={this.formRef}
                        initialValues={mesCalendarioSeleccionado.objetoFormulario}
                        >
                            {mesCalendarioSeleccionado.listaDiasMes ? (
                            <Row justify='start'>
                                <Col span={24}>
                                    <h1 style={{textAlign: 'center'}}>{mesCalendarioSeleccionado.mes}</h1>
                                </Col>
                                {mesCalendarioSeleccionado.listaDiasMes.map(option => 
                                <Col span={4} key={option}>
                                    <Row justify='space-around'>
                                        <Col span={22}>
                                            <Divider>Día {option}</Divider>
                                        </Col>
                                        <Col span={22}>
                                            <Form.Item
                                                label="Número de PPLs"
                                                name={"numPpl-" + option}
                                            >
                                                <InputNumber min={1} placeholder="Ingresa número de PPLs"
                                                    step={1} onChange={(e) => changeDecimales(e, option)} 
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                            </Row>
                            ):false}
                            <Divider/>
                            <Row justify='space-around'>
                                <Col span={7}>
                                    <h2>Total PPLs del mes: <strong>{numPplMes}</strong></h2>
                                </Col>
                                {console.log('terminado', terminado)}
                                <Col span={7}>
                                    <Form.Item
                                        name="pdf"
                                        rules={[{ required: terminado, message: 'Favor de llenar el campo' }]}
                                    >
                                        <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} 
                                            accept='.pdf' multiple={false}
                                        >
                                            {this.state.filePDF === false ? (
                                            <Button disabled={!terminado} className='btnCargaArchivos'>
                                                <UploadOutlined /> Click para adjuntar evidencia PDF
                                            </Button>
                                            ):false}
                                    </Upload>
                                    </Form.Item>
                                </Col>
                                <Col span={7} >
                                    <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit" disabled={this.state.botonPDF}>Agregar PPLs</Button>
                                </Col>
                            </Row>
                        </Form>
                        ):false}
                    </Spin>
                    ):false}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingAgregarPPL: state.MecanismosDePagosReducer.fetchingAgregarPPL,
        showModalAgregarPPL: state.MecanismosDePagosReducer.showModalAgregarPPL,
        busqueda: state.MecanismosDePagosReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaMesesCalendario: state.MecanismosDePagosReducer.listaMesesCalendario,
        mesCalendarioSeleccionado: state.MecanismosDePagosReducer.mesCalendarioSeleccionado,
        numPplMes: state.MecanismosDePagosReducer.numPplMes,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        terminado: state.MecanismosDePagosReducer.terminado,
        editando: state.MecanismosDePagosReducer.editando,
        fechaAnterior: state.MecanismosDePagosReducer.fechaAnterior,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalAgregarPPL: (busqueda, mesCalendarioSeleccionado) => {
            dispatch({ type: 'SHOW_MODAL_AGREGAR_PPL', showModalAgregarPPL: false, busqueda, mesCalendarioSeleccionado})
        },
        onAgregarPPL: ( formulario, busqueda, mesCalendarioSeleccionado, listaInfoProyecto, editando, fechaAnterior ) => {
            dispatch({ type: "AGREGAR_PPL_REQUEST" , formulario, busqueda, mesCalendarioSeleccionado, listaInfoProyecto, editando, fechaAnterior });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        },
        onCambiarNumPPLMes: (busqueda, mesCalendarioSeleccionado, numPplMes, terminado, editando) => {
            dispatch({ type: 'SHOW_MODAL_AGREGAR_PPL', showModalAgregarPPL: true, busqueda, mesCalendarioSeleccionado, numPplMes, terminado, editando })
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAgregarPPL);