import React from 'react';
import { Form, Row, Col, Switch, Modal, Table, Input, Button, Tooltip, InputNumber } from 'antd';
import { EditOutlined, PlusOutlined, SearchOutlined, RedoOutlined, FileExcelOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

import ModalNuevo from './ModalNuevoRangoDeValidacion';
import ModalEditar from './ModalEditarRangoDeValidacion';

const confirm = Modal.confirm;
const layout = {  labelCol: { span: 24 },  wrapperCol: { span: 24 }, };

class CatalogoRangosDeValidacion extends React.Component {
     formRef = React.createRef();
     componentDidMount(){ this.props.getRangosValidacion(1); }
     state={  page: 1,  busqueda: { limiteInferior: null, limiteSuperior: null, nombre: null }, orden: { field: 'nombre', order: 'ASC'} }

     render() {
          const { onCambiarEstado } = this.props;
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
               permisosUsuario = JSON.parse(permisosUsuario);
          }

          const onFinish = formulario => {
               console.log('Success:', formulario);
               function numberWithCommas(x) {
                    let parts = x.toString().split(".");
                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return parts.join(".");
               }

               if(formulario.limiteInferior !== undefined && formulario.limiteSuperior !== undefined){
                    if(parseFloat(formulario.limiteInferior) > parseFloat(formulario.limiteSuperior)){
                         Modal.error({
                              title: <p> <strong>Error en Límites</strong> </p>,
                              content: <p> El límite inferior ingresado: <strong style={{color: '#ff7875'}}>$ {numberWithCommas(formulario.limiteInferior)}</strong> no puede ser mayor al límite superior: $ {numberWithCommas(formulario.limiteSuperior)}, favor de verificar. </p>,
                         });
                    } else {
                         //Busqueda con dos límites
                         console.log('Entra a ambos límites');
                         let busqueda = { limiteInferior: formulario.limiteInferior, limiteSuperior: formulario.limiteSuperior, nombre: formulario.nombre }
                         this.props.getRangosValidacion(this.state.page, busqueda, this.state.orden);
                         this.setState({ busqueda });
                    }
               } else {
                    if(formulario.limiteInferior !== undefined){
                         console.log('Entra a limite inf sólo');
                         let busqueda = { limiteInferior: formulario.limiteInferior, limiteSuperior: undefined, nombre: formulario.nombre }
                         this.props.getRangosValidacion(this.state.page, busqueda, this.state.orden);
                         this.setState({ busqueda });
                    } else if(formulario.limiteSuperior !== undefined){
                         console.log('Entra a limite sup sólo');
                         let busqueda = { limiteInferior: undefined, limiteSuperior: formulario.limiteSuperior, nombre: formulario.nombre }
                         this.props.getRangosValidacion(this.state.page, busqueda, this.state.orden);
                         this.setState({ busqueda });
                    } else {
                         console.log('Entra a vacío')
                         let busqueda = { limiteInferior: undefined, limiteSuperior: undefined, nombre: formulario.nombre }
                         this.props.getRangosValidacion(this.state.page, busqueda, this.state.orden);
                         this.setState({ busqueda });
                    }    
               }
               
               
          };

          const onEditar = (key) => { this.props.onShowModalEditarRangoDeValidacion(key, this.state.page, this.state.busqueda, this.state.orden); };

          const handleDesactivar = (key) => {
               console.log(key);
               const { page, busqueda, orden } = this.state;
               confirm({
                    title: '¿Estás seguro que deseas cambiar el estado del rango de validación: "'+ key.nombre +'"?',
                    okText: 'Sí',
                     okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                        onCambiarEstado(key.idRangoDeValidacion, page, busqueda, orden);
                    },
                    onCancel() {
                         console.log('Cancel');
                    },
               });
          };

          const columns = [
               { title: 'Nombre', dataIndex: 'nombre',  key: 'nombre', sorter: true, },
               { title: 'Limite inferior', dataIndex: 'rangInf',  key: 'rangInf', align:'right' },
               { title: 'Limite superior', dataIndex: 'rangSup',  key: 'rangSup', align:'right' },
               { title: 'Fecha de alta', dataIndex: 'fechaAltaTabla',  key: 'fechaAltaTabla' },
               { title: 'Activo', dataIndex: 'activo',  key: 'activo',  width:'5%', align:'center',
                    render: (text, record) => (
                         <Switch checked={text} disabled={!permisosUsuario.rolActivoInactivo} onChange={() => handleDesactivar(record)}/>
                    ),
               },
               { title: 'Editar', key: 'editar',  width:'5%', align:'center',
                    render: (text, record) => (
                         <a className='editarIcono' disabled={!permisosUsuario.editarRol} onClick={() => onEditar(record)}><EditOutlined /></a>
                    ),
               },
          ];
        
        
          const handleTableChange = (pagination, filtro, orden)=>{
               this.setState({  page: pagination.current, });
               
               if(orden){
                    let field = orden.columnKey;
                    let order = 'DESC';
                
                    if(orden.order === 'ascend'){
                        order = 'ASC';
                    }
    
                    if(orden.columnKey === 'limiteInferior'){
                        field = 'limiteInferior';
                    }else if(orden.columnKey === 'limiteSuperior'){
                        field = 'limiteSuperior';
                    }else if(orden.columnKey === 'nombre'){
                         field = 'nombre';
                    }
                    const ordenState = { field, order }
                    this.props.getRangosValidacion(pagination.current, this.state.busqueda, ordenState);
                    this.setState({ orden: ordenState });
               }
               else{
                    this.props.getRangosValidacion(pagination.current, this.state.busqueda, this.state.orden);
               }
          };
          
          const onNuevo = () => {
               this.props.onShowModalNuevoRangoDeValidacion();
          };

          return (<>
               <Form {...layout} name="formulario" onFinish={onFinish}  ref={this.formRef} >
                    <Row justify='space-between' gutter={[8,8]}>
                         <Col span={9}>
                              <Form.Item name="nombre" >
                                   <Input placeholder='Ingresa el nombre a filtrar' />
                              </Form.Item>
                         </Col>
                         <Col span={5}>
                              <Tooltip title='Ingresa el límite inferior' placement='topRight'> 
                                   <Form.Item name="limiteInferior" >
                                        <InputNumber
                                             placeholder="Ingresa el límite inferior"
                                             formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 
                                             parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                             min={0}
                                        />
                                   </Form.Item>
                              </Tooltip>
                         </Col>
                         <Col span={5}>
                              <Tooltip title='Ingresa el límite superior' placement='topRight'>
                                   <Form.Item name="limiteSuperior" >
                                        <InputNumber 
                                             placeholder="Ingresa el límite superior"
                                             formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 
                                             parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                             min={0}
                                        />
                                   </Form.Item>
                              </Tooltip>
                         </Col>
                         <Col span={1}>
                              <Tooltip title='Restablecer valores de búsqueda' placement="topRight">
                                   <Button className="iconTer" shape="circle" onClick={() => { this.formRef.current.resetFields(); this.setState({busqueda: { limiteInferior: null, limiteSuperior: null }}); this.props.getRangosValidacion(this.state.page); }} icon={<RedoOutlined />}/>
                              </Tooltip>
                         </Col>
                         <Col span={1}>
                              <Tooltip title='Aplicar filtro de búsqueda' placement="topRight">
                                   <Button className="iconTer" shape="circle" htmlType="submit" disabled={!permisosUsuario.buscarRol} icon={<SearchOutlined />}/>
                              </Tooltip>
                         </Col>
                         <Col span={1}>
                              <Tooltip title='Nuevo Rango de Validación' placement="topRight">
                                   <Button className="iconTer" shape="circle" onClick={onNuevo} disabled={!permisosUsuario.crearRol} icon={<PlusOutlined />}/>
                              </Tooltip>
                         </Col>
                         <Col span={1}>
                              <Tooltip title='Exportar archivo .xls' placement="topRight">
                                   <Button disabled={!permisosUsuario.exportarExcelCatálogoActivos} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } loading={this.props.fetchingExcelRangosValidacion} onClick={() => { this.props.onExport() }} />
                              </Tooltip>
                         </Col> 
                    </Row>
               </Form>
               <Row justify='space-between' gutter={[8,8]}>
                    <Col span={24}>
                         <Table 
                              size="small" 
                              rowKey="descripcion" 
                              columns={columns}
                              dataSource={permisosUsuario.buscarRol ? this.props.listaBusquedaRangosValidacion : []}
                              loading={this.props.fetchingListaBusquedaRangosValidacion}
                              locale={{ emptyText: 'Sin datos' }}
                              onChange={handleTableChange}
                              pagination={{ total: this.props.listaBusquedaRangosValidacionCantidad, simple: true, current: this.state.page }}
                         />         
                    </Col>
               </Row>

               <ModalNuevo />
               <ModalEditar />
          </>);
     }
}

const mapStateToProps = state => {
     return {
          listaBusquedaRangosValidacion: state.ConfiguracionYSeguridadReducer.listaBusquedaRangosValidacion,
          listaBusquedaRangosValidacionCantidad: state.ConfiguracionYSeguridadReducer.listaBusquedaRangosValidacionCantidad,
          fetchingListaBusquedaRangosValidacion: state.ConfiguracionYSeguridadReducer.fetchingListaBusquedaRangosValidacion,
          fetchingExcelRangosValidacion: state.ConfiguracionYSeguridadReducer.fetchingExcelRangosValidacion,
     };
};
const mapDispatchToProps = dispatch => {
	return {
          getRangosValidacion: (page, busqueda, orden) => {
               dispatch({ type: "LISTA_BUSQUEDA_RANGOS_VALIDACION_REQUEST", page, busqueda, orden });
          },
          onShowModalNuevoRangoDeValidacion: (page, busqueda, orden) => {
               dispatch({ type: 'SHOW_MODAL_NUEVO_RANGO_VALIDACION', showModalNuevoRangoValidacion: true, page, busqueda, orden })
          },
          onShowModalEditarRangoDeValidacion: (rangoValidacionSeleccionado, page, busqueda, orden) => {
               dispatch({ type: 'SHOW_MODAL_EDITAR_RANGO_VALIDACION', showModalEditarRangoValidacion: true, rangoValidacionSeleccionado, page, busqueda, orden })
          },
          onCambiarEstado: (idRangoDeValidacion,page, busqueda, orden) => {
               dispatch({ type: 'ESTADO_RANGO_VALIDACION_REQUEST', idRangoDeValidacion, page, busqueda, orden});
          },
          onExport : () => {
               dispatch({ type: 'EXPORT_EXCEL_RANGOS_VALIDACION_REQUEST' });
          }
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoRangosDeValidacion);