import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {Table, Row, Col, Button, DatePicker, Card, Select, message, Popover, Form} from 'antd';
import { EditOutlined, FileSearchOutlined, SearchOutlined} from '@ant-design/icons';
import ModalDetalleActividades from './ModalDetalleActividades';
import ModalDetallePrograma from './ModalDetallePrograma';
import ModalCambiarEstatus from './ModalCambiarEstatus';

const {Option} = Select;
const { RangePicker } = DatePicker;

class EstatusOrdenesSP extends React.Component {
    formRef = React.createRef();
    state = {
        page : 1,
        idServicio: "",
        idPartida: "",
        idUbicacion: "",
        programa: "",
        fecha: null
    }

    componentDidMount () {
        this.props.onGetEstatusOrdenesSP("", "", "", null, "", 1); this.props.onGetServiciosActivos();
    }

    render () {
        const {fetchingListaServiciosFiltro, listaServiciosFiltro, fetchingListaUbicacionesFiltro, listaUbicacionesFiltro,
            fetchingListaCancelacionOrdenesSPBusqueda, listaCancelacionOrdenesSPBusqueda, listaCancelacionOrdenesSPCantidad,
            onListaServiciosFiltro, onListaServiciosFiltroLimpiar, onListaUbicacionesFiltro, onListaUbicacionesFiltroLimpiar, onModalDetalleOrdenSPActividades, onModalDetalleOrdenSPPrograma,onGetDetalleOrdenSP, onModalCambiarEstatusOrdenSP, onGetEstatusOrdenesSP} = this.props;
        const {page, idServicio, idPartida, idUbicacion, programa, fecha} = this.state;
        
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        // specifying your onload callback function
        var callback = function () {
            console.log('Done!!!!');
        };
        
        // specifying verify callback function
        var verifyCallback = function (response) {
            console.log(response);
        };
        // const data = [{folioOrden: 'SAS12', estadoOrden:'Generada'}, {folioOrden:'SAS13', estadoOrden: 'Mantenimiento'}]

        const columns = [{
            title: 'Folio de orden',
            dataIndex: 'folioOrden',
            key: 'folioOrden',
            width: '8%'
        },{
            title: 'Fecha Orden',
            dataIndex: 'fechaOrden',
            key: 'fechaOrden',
            width: '7%'
        },{
            title: 'Estado de orden',
            dataIndex: 'estadoOrden',
            key: 'estadoOrden',
            width: '8%'
        },{
            title: 'Categoría',
            dataIndex: 'categoria',
            key: 'categoria',
            width: '10%'
        },{
            title: 'Nomenclatura',
            dataIndex: 'nomenclatura',
            key: 'nomenclatura',
            width: '8%'
        },{
            title: 'Servicio',
            dataIndex: 'nombreServicio',
            key: 'nombreServicio',
            width: '12%'
        },{
            title: 'Partida',
            dataIndex: 'partida',
            key: 'partida',
            width: '7%'
        },{
            title: 'Programa',
            dataIndex: 'programa',
            key: 'programa',
            width: '7%'
        },{
            title: 'Nombre de rutina',
            dataIndex: 'rutina',
            key: 'rutina',
            width: '12%'
        },{
            title: 'Tipo',
            dataIndex: 'tipoRutina',
            key: 'tipoRutina',
            width: '7%'
        },{
            title: 'Clave',
            dataIndex: 'claveRutina',
            key: 'claveRutina',
            width: '7%'
        },{
            title: 'Detalle Actividades',
            key: 'detalle',
            align: 'center',
            width: '7%',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.detalleActividadesDeLaOrdenDeServiciosPlanificados}    onClick={() => onDetalleActividades(record)}   ><FileSearchOutlined /></a>
            ),
        },{
            title: 'Detalle Programa',
            key: 'detalle',
            align: 'center',
            width: '8%',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.detalleProgramaDeLaOrdenDeServiciosPlanificados}    onClick={() => onDetallePrograma(record)}   ><FileSearchOutlined /></a>
            ),
        },{
            title: 'Cambiar Estado',
            key: 'cambioEstado',
            align: 'center',
            width: '10%',
            fixed: 'right',
            render: (text, record) => (
                <Button className="btnFiltrar"   onClick={() => onCambiarEstatusOrden(record)} disabled={!permisosUsuario.cambiarEstadoDeLasOrdenesDeServiciosPlanificados ? true : record.estadoOrden == 'Realizada' || record.estadoOrden == 'Cancelada' ? true : false}     >Cambiar Estado</Button>
            ),
        }];

        const buscarServicios = value => {
            if (value.length >= 3) {
                if (value) {
                    console.log(value);
                    onListaServiciosFiltro(value)
                } else {
                    message.warning('No se encontró servicio con esas palabras')
                }
            } else {
                onListaServiciosFiltroLimpiar();
            }
        }

        const handleSearch = (value) => {
            if(value.length>=3){
                if (value) {
                    console.log(value)
                    onListaUbicacionesFiltro(value);
                } else {
                    message.warning('No se encontró ubicación con esas palabras')
                }
            }
            else{
                onListaUbicacionesFiltroLimpiar();
            }
        };

        const handleSelect = (value, field) => {
            
            if(field === 'idServicio'){
                if(value){
                    this.props.onGetPartidasByIdServicio(value.split(' - ')[0]);
                    this.formRef.current.resetFields(['idPartida']);
                } else {
                    this.props.onCleanPartidasByIdServicio();
                    this.formRef.current.resetFields(['idPartida']);
                }
                this.setState({[field]: value, page: 1, idPartida:""}, () => onGetEstatusOrdenesSP(this.state.idServicio, "", this.state.idUbicacion, fecha, this.state.programa, 1));
            } else {
                this.setState({[field]: value, page: 1}, () => onGetEstatusOrdenesSP(this.state.idServicio, this.state.idPartida, this.state.idUbicacion, fecha, this.state.programa, 1));
            }
        }

        const onChangeDate = value => {
            this.setState({fecha: value}, () => onGetEstatusOrdenesSP(idServicio, this.state.idPartida, idUbicacion, this.state.fecha, programa, 1));
        }

        const disabledRange = fecha => {
            const currentYear = new Date();
            const year = currentYear.getFullYear();
            const fechaVigencia = moment(`31/12/${year}`, "DD-MM-YYYY")
            const fechaInicio = moment(`1/1/${year}`, "DD-MM-YYYY")
            if (fecha && fecha < fechaInicio) {
                return fecha && fecha < fechaInicio;
            } else {
                return fecha && fecha > fechaVigencia;
            }
        }

        const onDetalleActividades = record =>{
            onModalDetalleOrdenSPActividades(record);
        }

        const onDetallePrograma = record =>{
            onModalDetalleOrdenSPPrograma(record);
            onGetDetalleOrdenSP(record);
        }

        const onCambiarEstatusOrden = record => {
            console.log(`record`, record)
            const listaInsumos = {
                insumos: record.insumosByOrden ? record.insumosByOrden : [],
                herramientas: record.herramientasByOrden ? record.herramientasByOrden : [],
            }
            const listaPersonal = {
                personal: record.personalByIdOrdenServicioPlanificado ? record.personalByIdOrden : []
            }
            const busqueda = {idServicio, idUbicacion, fecha, programa}
            const listaSinRepetir = record.insumosLista
            const listaSinRepetirHerramienta = record.herramientasLista
            console.log('Todo', listaInsumos, listaSinRepetir, listaSinRepetirHerramienta)
            onModalCambiarEstatusOrdenSP(record, listaInsumos, listaSinRepetir, listaSinRepetirHerramienta, busqueda, page, listaPersonal);
            onGetDetalleOrdenSP(record);
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            onGetEstatusOrdenesSP(idServicio, this.state.idPartida, idUbicacion, fecha, programa, pagination.current);
        }

        const scroll = {"x": 1600 | true, "y": 1000};

        const onPartidaSelect = (value, field) => {
            if(value){
                onGetEstatusOrdenesSP(idServicio, value.split(' - ')[0], idUbicacion, fecha, programa, 1)
                this.setState({idPartida: value.split(' - ')[0]})
            } else {
                onGetEstatusOrdenesSP(idServicio, '', idUbicacion, fecha, programa, 1);
                this.setState({ idPartida: ''})
                this.formRef.current.resetFields(['idPartida']);
            }
        };

        return (
            <div>
                <Card className="cardCatalogos" title={<div className='headerLista'>Cambiar Estado de las Órdenes e Ingresar Insumos</div>}>
                <Form name="formulario" ref={this.formRef}>
                    <Row justify='space-between' gutter={[8,8]}>
                        <Col span={12}>
                            <Select
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Selecciona un servicio"
                                loading={this.props.fetchingListaServiciosActivosF}
                                showSearch
                                onChange={value => handleSelect(value, 'idServicio')}
                            >
                                {this.props.listaServiciosActivosF.map(option => <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>{option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}</Option>)}
                            </Select>
                        </Col>
                        <Col span={12}>
                            <Form.Item  name="idPartida">
                                <Select
                                    allowClear
                                    showSearch={true}
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona una partidas"
                                    loading={this.props.fetchingListaPartidasByServicio4Fallas}
                                    disabled={!this.props.listaPartidasByServicio4Fallas[0]}
                                    onChange={value => onPartidaSelect(value, 'idPartida')}
                                    value={this.state.partida}
                                >
                                    {this.props.listaPartidasByServicio4Fallas.map(option =>
                                        <Option key={option.idPartida + ' - ' + option.nomenclatura  + ': ' + option.nombre}>
                                            {option.idPartida + ' - ' + option.nomenclatura  + ': ' + option.nombre}
                                        </Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Select allowClear
                                style={{ width: '100%' }}
                                placeholder="Ingrese la ubicación"
                                loading={fetchingListaUbicacionesFiltro}
                                showSearch
                                filterOption={false}
                                onSearch={handleSearch}
                                onChange={value => handleSelect(value, 'idUbicacion')}
                                notFoundContent={null}
                                dropdownStyle={{ minWidth: "60%" }}
                            >
                                {listaUbicacionesFiltro.map(option =>
                                    <Option key={`${option.idUbicacion} - ${option.todoUbicacionCOA}`} >
                                        <Popover content={option.todoUbicacionCOA} title={false}>
                                            {`${option.idUbicacion} - ${option.todoUbicacionCOA}`}
                                        </Popover>
                                    </Option>
                                )}
                            </Select>
                        </Col>
                        <Col span={5}>
                            <Select allowClear onChange={value => handleSelect(value, 'programa')}
                                style={{ width: '100%' }} placeholder="Selecciona un programa">
                                <Option value='Rutina'>Rutina</Option>
                                <Option value='Mantenimiento'>Mantenimiento</Option>
                                <Option value='Prueba'>Prueba</Option>
                            </Select>
                        </Col>
                        <Col span={7}>
                            <RangePicker
                                format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio", "Fecha fin"]}
                                disabledDate={disabledRange}
                                onChange={onChangeDate}
                            />
                        </Col>
                    </Row>
                </Form>
                    <Row>
                        <Col span={24}>
                            <Table
                                size="small"
                                rowKey="folioOrden"
                                columns={columns}
                                dataSource={listaCancelacionOrdenesSPBusqueda}
                                loading={fetchingListaCancelacionOrdenesSPBusqueda}
                                locale={{ emptyText: 'Sin datos' }}
                                onChange={handleTableChange}
                                pagination={{ total: listaCancelacionOrdenesSPCantidad, simple: true, current: this.state.page }}
                                scroll={scroll}
                            />
                        </Col>
                    </Row>
                </Card>
                <ModalDetalleActividades/>
                <ModalDetallePrograma/>
                <ModalCambiarEstatus />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaCancelacionOrdenesSPBusqueda: state.ServiciosPlanificadosReducer.fetchingListaCancelacionOrdenesSPBusqueda,
        listaCancelacionOrdenesSPBusqueda: state.ServiciosPlanificadosReducer.listaCancelacionOrdenesSPBusqueda,
        listaCancelacionOrdenesSPCantidad: state.ServiciosPlanificadosReducer.listaCancelacionOrdenesSPCantidad,
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
        fetchingListaUbicacionesFiltro: state.CatalogosGeneralesReducer.fetchingListaUbicacionesFiltro,
        listaUbicacionesFiltro: state.CatalogosGeneralesReducer.listaUbicacionesFiltro,

        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        listaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.listaPartidasByServicio4Fallas,
        fetchingListaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.fetchingListaPartidasByServicio4Fallas,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda});
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []});
        },
        onListaUbicacionesFiltro: (busqueda) => {
            dispatch({ type: 'LISTA_UBICACIONES_FILTRO_REQUEST', busqueda: busqueda });
        },
        onListaUbicacionesFiltroLimpiar: () => {
            dispatch({ type: 'LISTA_UBICACIONES_FILTRO_SUCCESS', listaUbicacionesFiltro: [] });
        },
        onModalDetalleOrdenSPActividades: (estatusOrdenSPSeleccionado) => {
            dispatch({ type: 'SHOW_MODAL_DETALLE_ESTATUS_ORDEN_SP_ACTIVIDAD',showModalDetalleEstatusOrdenesSPActividad: true, estatusOrdenSPSeleccionado: estatusOrdenSPSeleccionado });
        },
        onModalDetalleOrdenSPPrograma: (estatusOrdenSPSeleccionado) => {
            dispatch({ type: 'SHOW_MODAL_DETALLE_ESTATUS_ORDEN_SP_PROGRAMA',showModalDetalleEstatusOrdenesSPPrograma: true, estatusOrdenSPSeleccionado: estatusOrdenSPSeleccionado});
        },
        onGetDetalleOrdenSP: (ordenCancelacionSPSeleccionado) => {
            dispatch({ type: 'LISTA_DETALLE_CANCELACION_ORDEN_SP_REQUEST', ordenCancelacionSPSeleccionado: ordenCancelacionSPSeleccionado });
        },
        onModalCambiarEstatusOrdenSP: (estatusOrdenSPSeleccionado, listaInsumos, listaSinRepetir, listaSinRepetirHerramienta, busqueda, page, listaPersonal) => {
            dispatch({ type: 'SHOW_MODAL_CAMBIAR_ESTATUS_OSP', showModalCambiarEstatusOrdenSP: true, estatusOrdenSPSeleccionado, listaInsumos, listaSinRepetir, listaSinRepetirHerramienta, busqueda, page, listaPersonal });
        },
        onGetEstatusOrdenesSP: (idServicio, idPartida, idUbicacion, fecha, programa, page) => {
            dispatch({ type: 'LISTA_BUSQUEDA_ORDENES_SP_CANCELAR_REQUEST', idServicio: idServicio, idPartida:idPartida, idUbicacion:idUbicacion, fecha:fecha, programa:programa, page:page});
        },

        onGetPartidasByIdServicio: (idServicio) => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_REQUEST', idServicio})
        },
        onCleanPartidasByIdServicio: () => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_SUCCESS', listaPartidasByServicio4Fallas: []})
        },
        onGetServiciosActivos: () => {
            dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (EstatusOrdenesSP);