import React from 'react';
import { Form, Card, Spin, Row, Col, Select, Result, Tooltip, Button, Divider, message, DatePicker, Radio, Alert, Descriptions, Table, Tabs } from 'antd';
import { SearchOutlined, FilePdfOutlined, PieChartOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import {Bar, Pie, Line} from 'react-chartjs-2';
import 'chart.piecelabel.js';
import moment from 'moment';
import ModalComparativos from './ModalComparativos';
import trendlineLinear from "chartjs-plugin-trendline";

import imaSearch from '../../../assets/undraw_segment_analysis_re_ocsl.svg';
import ReactVivus from "react-vivus";

const { Option } = Select;
const { TabPane } = Tabs;

class ReporteResultadosContainer extends React.Component {
     formRef = React.createRef();
     componentDidMount(){
          this.props.onGetInfoProyecto();
          this.props.onGetServiciosActivos();
     }

     state={
         page: 1, firstbuttonBoolean: true,  idServicio: null, mes: '', annio: '', radioButtonBoolean: false, idMes: null, titleReport: '', buttonDisabled: true, opcSelected: [], tipo: 'Folio', ResultTipo: '', elementoGraficado: '', cardTitle: null, showBarPagination: false
     }
 
     render() {
          const {
               fetchingListaServiciosActivosF, listaServiciosActivosF, fetchingInformacionReporte, onGetInformacionReporte, fetchingReporteEncuestasPDF, informacionReporte, onExportPDF, columnsReport, onCleanInformacionReporte, listaInfoProyecto, onShowComparativos, onGetGeneralReportExport, fetchingGeneralReport
          } = this.props;

          const onFinish = (formulario) => {
               console.log('Success:', formulario);
               
               let idServicio = formulario.servicio.split(' - ')[0];
               let idMes = (formulario.mesAnnio._d).getMonth() + 1;

               this.setState({ firstbuttonBoolean: false, idServicio: idServicio, idMes: idMes });
               onGetInformacionReporte( idServicio, idMes, this.state.annio, 'Folio', 1 );
          }

          let userInfo = sessionStorage.getItem('userInfo');
          if(userInfo[0]) {
               userInfo = JSON.parse(userInfo);
          }

          const serviciosByIdUsuario = userInfo[0].serviciosByIdUsuario ? userInfo[0].serviciosByIdUsuario : [];
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){ permisosUsuario = JSON.parse(permisosUsuario); }

          const servicioSeleccionado = (value) => { 
               if(value){ 
                    this.setState({firstbuttonBoolean: true, titleReport: '', radioButtonBoolean: false, tipo: 'Folio', ResultTipo: '' }); 
                    onCleanInformacionReporte();
               } else { 
                    this.setState({firstbuttonBoolean: true, titleReport: '', radioButtonBoolean: false, tipo: 'Folio', ResultTipo: '' });
                    onCleanInformacionReporte();
               } 
          };

          const onChangeMonth = (date, dateString) => {
               if(date){
                    if(listaInfoProyecto.fechaInicio){

                              let mes = dateString.split('-')[0];
                              let annio = dateString.split('-')[1]; 
                              this.setState({ mes: mes, annio: annio, firstbuttonBoolean: true, titleReport: '', radioButtonBoolean: false, tipo: '', ResultTipo: ''  });
                              onCleanInformacionReporte();

                         let idMesLocal = (date._d).getMonth();
                         let diasVigencia = new Date(annio, idMesLocal + 1, 0).getDate();

                         let mesFechaInicio = new Date(annio, idMesLocal, 1, 0,0,0,0)
                         let mesFechaFin = new Date(annio, idMesLocal, diasVigencia, 23,59,59,59);

                         const diferenciaInicio = listaInfoProyecto.fechaInicio.diff(moment(mesFechaFin), "days");

                         const fechaVigenciaNuevo = listaInfoProyecto.fechaVigencia.split('-')[2] + '-' + listaInfoProyecto.fechaVigencia.split('-')[1] + '-' + listaInfoProyecto.fechaVigencia.split('-')[0];
                         
                         const fechaVigenciaLocal = new Date(fechaVigenciaNuevo); 
                         if(diferenciaInicio > 0){
                              message.error('No es posible seleccionar una fecha de inicio menor al inicio del contrato del proyecto', 5);
                              this.formRef.current.resetFields(['mesAnnio']);
                         }
                         if(mesFechaInicio > fechaVigenciaLocal){
                              message.error('No es posible seleccionar una fecha de fin mayor a la vigencia del contrato del proyecto', 5);
                              this.formRef.current.resetFields(['mesAnnio']);
                         }
                    }
                    else{
                         message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                         this.formRef.current.resetFields(['mesAnnio']);
                    }
               } else {
                    this.setState({firstbuttonBoolean: true, titleReport: '', radioButtonBoolean: false, tipo: '' });
                    onCleanInformacionReporte();
               }
          };

          let data=[];
          let cantidadReporte = null;
          let chartData = {}; let charDataPie = {};
          let optionsData = {}; let optionsDataPie = {};
          if (informacionReporte){
               const { cantidad, cantidadEncuestas, promedioServicio, camposReporte } = informacionReporte;
               cantidadReporte = cantidad;
               let total = 0;

               data = [
                    {label: 'Cantidad de Encuestas', data: cantidadEncuestas},
                    {label: 'Promedio de Servicio', data: promedioServicio},
               ];

               if(camposReporte){

                    let labelsReporte = [];
                    let dataReporte = [];

                    let labesReportPie = [];
                    let dataReportPied = [];

                    for(let i in camposReporte){
                         if(camposReporte[i].folioEncuesta){
                              labelsReporte.push(camposReporte[i].folioEncuesta);
                         } else if(camposReporte[i].preguntaEncuesta){
                              labelsReporte.push(camposReporte[i].preguntaEncuesta);
                         } else if(camposReporte[i].servicioEncuesta){
                              labelsReporte.push(camposReporte[i].servicioEncuesta);
                         } 

                         dataReporte.push(camposReporte[i].calificacion);
                    }

                    if(this.state.opcSelected){
                         let arr = this.state.opcSelected;
                         if(arr.graficaEncuestas){
                              if(this.state.tipo === 'Folio' || this.state.tipo === 'Pregunta'){
                                   for(let i in arr.graficaEncuestas){
                                        labesReportPie.push(arr.graficaEncuestas[i].etiqueta + ' (' + arr.graficaEncuestas[i].factor + ')');
                                        dataReportPied.push(arr.graficaEncuestas[i].numeroRespuestas);
                                        total += arr.graficaEncuestas[i].numeroRespuestas;
                                   }
                              } 
                         }
                    } 
                    
                    if (this.state.tipo === 'Servicio') {
                         if(camposReporte[0]){
                              for(let i in camposReporte[0].graficaEncuestas){
                                   labesReportPie.push(camposReporte[0].graficaEncuestas[i].etiqueta + ' (' + camposReporte[0].graficaEncuestas[i].factor + ')');
                                   dataReportPied.push(camposReporte[0].graficaEncuestas[i].numeroRespuestas);
                                   total += camposReporte[0].graficaEncuestas[i].numeroRespuestas;
                              }
                         }
                         //this.setState({ buttonDisabled: false });
                    }

                    chartData = {
                         labels: labelsReporte,
                         datasets: [{  data: dataReporte,  backgroundColor: 'rgb(248,189,93,0.8)', borderColor: 'rgb(248,189,93,1)',  borderWidth: 2 }],
                    }

                    charDataPie = {
                         labels: labesReportPie,
                         datasets: [{  data: dataReportPied,  backgroundColor: ['#d1dbaf', '#b1c379', '#e64d14', '#6b7c37', '#77893d', '#839743', '#9ab150', '#a2b75e', '#c1cf94', '#e1e8ca', '#f0f4e5'], borderColor: '#f8faf3',  borderWidth: 2 }],
                    }

     
                    optionsData = {
                         scales: {
                              yAxes: [{
                                   ticks: {  beginAtZero: true, autoSkip: true, maxTicksLimit: 100, maxRotation: 0, minRotation: 0 },
                                   scaleLabel: { display: true, labelString: ['Calificación'] } 
                              }]
                         },
                         tooltips: { enabled: true },
                         hover: { animationDuration: 7 },
                         legend: { display: false },
                    }

                    optionsDataPie = {
                         pieceLabel: {
                              render: {
                                   datalabels: {
                                        formatter: (value, ctx) => {
                       
                                             let datasets = ctx.chart.data.datasets;
                         
                                             if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                                                  let sum = 0;
                                                  datasets.map(dataset => {
                                                       sum += dataset.data[ctx.dataIndex];
                                                  });
                                                  let percentage = Math.round((value / sum) * 100) + '%';
                                                  return percentage;
                                             }
                                        }
                                   }
                              },
                              fontSize: 14,
                              fontColor: '#fff'
                         },
                         tooltips: {
                              callbacks: {
                                   label: function(tooltipItem, data) {
                                        let dataset = data.datasets[tooltipItem.datasetIndex];
                                        let meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                        let total = meta.total;
                                        let currentValue = dataset.data[tooltipItem.index];
                                        let percentage = parseFloat((currentValue/total*100).toFixed(1));
                                        return currentValue + ' (' + percentage + '%)';
                                   },
                                   title: function(tooltipItem, data) {
                                   return data.labels[tooltipItem[0].index];
                                   }
                              },
                              title: function(tooltipItem, data) {
                                   return data.labels[tooltipItem[0].index];
                              },
                         },
                         hover: { animationDuration: 7 },
                         legend: { position: "left", align: "center", labels: {boxWidth: 15},
                              onHover: function (event, legendItem) {
                                   let chart = this.chart;
                                   let index = legendItem.index;
                                   let segment = chart.getDatasetMeta(0).data[index];
                                   chart.tooltip._active = [segment]
                                   chart.tooltip.update()
                                   chart.draw()
                              },
                              onLeave: function (event, legendItem) {
                                   let chart = this.chart;
                                   let index = legendItem.index;
                                   chart.tooltip._active = []
                                   chart.tooltip.update()
                                   chart.draw()
                              } 
                         },
                    }
               }
          }

          const onTipoReporte = (value) => {
               console.log('value', value)
               if(value){
                    // let tipo = value.target.value;
                    let tipo = value;
                    this.setState({ radioButtonBoolean: true });

                    if (tipo === 'Folio') {
                         const cardTitleLocal = (<b>Gráfico General por {tipo}:</b>);
                         this.setState({ tipo: tipo, titleReport: ' Reporte por Folio ', buttonDisabled: true, ResultTipo: 'Seleccione un folio para gráficar', cardTitle: cardTitleLocal, page: 1 });
                    } else if (tipo === 'Pregunta') {
                         const cardTitleLocal = (<b>Gráfico General por {tipo}:</b>);
                         this.setState({ tipo: tipo,  titleReport: ' Reporte por Preguntas ', buttonDisabled: true, ResultTipo: 'Seleccione una pregunta para gráficar', cardTitle: cardTitleLocal,  page: 1  });
                    } else {
                         const cardTitleLocal = (<p><b>Gráfico General por {tipo}:</b></p>);
                         this.setState({ tipo: tipo,  titleReport: ' Reporte por Promedio General del Servicio ', elementoGraficado: '', cardTitle: cardTitleLocal, });
                    }

                    onGetInformacionReporte( this.state.idServicio, this.state.idMes, this.state.annio, tipo, 1 );
               } else {
                    this.setState({ radioButtonBoolean: false });
               }
          };

          const onDescarga = () => { onExportPDF(this.state.idMes, this.state.annio, this.state.idServicio, informacionReporte.cantidadEncuestas, informacionReporte.promedioServicio, this.state.opcSelected, informacionReporte.cantidad, informacionReporte.camposReporte, this.state.tipo, this.props.informacionReporte) };

          const onDescargaGeneral = () => { onGetGeneralReportExport(this.state.idServicio, this.state.idMes, this.state.annio, this.state.tipo) };

          const handleTableChange = pagination => {
               if (this.state.tipo === 'Folio') {
                    const cardTitleLocal = (<b>Gráfico General por {this.state.tipo}:</b>);
                    this.setState({ titleReport: ' Reporte por Folio ', cardTitle: cardTitleLocal, showBarPagination: true, });
               } else if (this.state.tipo === 'Pregunta') {
                    const cardTitleLocal = (<b>Gráfico General por {this.state.tipo}:</b>);
                    this.setState({ titleReport: ' Reporte por Preguntas ', cardTitle: cardTitleLocal, showBarPagination: true,  });
               } else {
                    const cardTitleLocal = (<p><b>Gráfico General por {this.state.tipo}:</b></p>);
                    this.setState({ titleReport: ' Reporte por Promedio General del Servicio ', elementoGraficado: '', cardTitle: cardTitleLocal, showBarPagination: false });
               }

               this.setState({ page: pagination.current, showBarPagination: true })
               onGetInformacionReporte( this.state.idServicio, this.state.idMes, this.state.annio, this.state.tipo, pagination.current );
          };

          if(columnsReport){
               if(columnsReport[0]){
                    if(columnsReport[0].title === 'Pregunta' || columnsReport[0].title === 'Folio'){
                         if(columnsReport.length === 2){
                              const graf = {
                                   title: 'Gráficar', key: 'graficar', align: 'center', width: '5%',
                                   render: (record) => (
                                        <a className='editarIcono' onClick={() => onGraficar(record)}><PieChartOutlined /></a>
                                   ),
                              }
                              columnsReport.push(graf);
                         }
                    }
               }
          }

          const onGraficar = (value) => { 
               console.log('value onGraficar', value)
               if(value){
                    let elementoGraficado = '';
                    let cardTitleLocal = null;

                    if(this.state.tipo == 'Folio'){
                         elementoGraficado = value.folioEncuesta;
                         cardTitleLocal = (<p><b>Gráfico Unitario por {this.state.tipo}:</b> <div style={{color:'#ff8c62'}}>{ elementoGraficado}</div> </p>);
                    } else {
                         elementoGraficado =  value.preguntaEncuesta;
                         cardTitleLocal = (<p><b>Gráfico Unitario por {this.state.tipo}:</b> <div style={{color:'#ff8c62'}}>{ elementoGraficado}</div> </p>);
                    }

                    this.setState({ buttonDisabled: false, opcSelected: value, elementoGraficado: elementoGraficado, cardTitle: cardTitleLocal, showBarPagination: false });
               } else {
                    this.setState({ buttonDisabled: true });
               }
          }; 

          const onComparativos = () => {  
               let infoReporteSeleccionado = [];

               if(this.state.tipo === 'Servicio'){
                    if(informacionReporte.camposReporte){ infoReporteSeleccionado = informacionReporte.camposReporte[0]; }
               } else {
                    if(informacionReporte.camposReporte){ infoReporteSeleccionado = this.state.opcSelected; }
                    
               }

               onShowComparativos(this.state.tipo, infoReporteSeleccionado); 
          };

          return (
               <Card className="cardCatalogos" title={<div className='headerLista'>Reportes de Encuestas de Satisfacción</div>}>
                    <br/>
                    { true ? (<>
                         <Spin spinning={false}>
                         <Form
                              name="formulario"
                              ref={this.formRef}
                              onFinish={onFinish}
                              
                         >
                              <Row justify='space-around'>
                                   <Col span={5}>
                                        <Tooltip title='Seleccione el mes y año a buscar '>
                                             <Form.Item  name='mesAnnio' rules={[{ required: true, message: 'Favor de llenar el campo' }]} >
                                                  <DatePicker onChange={onChangeMonth} picker="month" style={{width:"100%"}} format='MM-YYYY' />
                                             </Form.Item>
                                        </Tooltip>
                                   </Col>

                                   <Col span={16}>
                                        <Tooltip title='Ingresa servicio a buscar'>
                                             <Form.Item  name='servicio' rules={[{ required: true, message: 'Favor de llenar el campo' }]} >
                                                  {permisosUsuario.especialGestiónDeCalidad ? (
                                                       <Select allowClear
                                                       style={{ width: '100%' }}
                                                       placeholder="Selecciona un servicio a filtrar"
                                                       loading={fetchingListaServiciosActivosF}
                                                       onChange={servicioSeleccionado}
                                                       showSearch={true}
                                                       >
                                                            {listaServiciosActivosF.map(option => <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>{option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}</Option>)}
                                                       </Select>
                                                  ):(
                                                       <Select
                                                            allowClear
                                                            style={{ width: '100%' }} placeholder="Selecciona un servicio"
                                                       >
                                                            {serviciosByIdUsuario.map(option => <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>{option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}</Option>)}
                                                       </Select>
                                                  )}
                                             </Form.Item>
                                        </Tooltip>
                                   </Col>

                                   <Col span={1}>
                                        <Button className="btnFiltrar" shape="circle" icon={ <SearchOutlined />} htmlType="submit" />
                                   </Col>

                                   { !this.state.firstbuttonBoolean ? (<>

                                        <Col span={24}>
                                             <Tabs className='tabDashboard' defaultActiveKey="Folio" centered onChange={ onTipoReporte }>
                                                  <TabPane tab="Reporte por Folio" key="Folio">
                                                       <Row justify='space-around'>
                                                       { cantidadReporte > 0 ? (<>
                                                            <Col span={24}>
                                                                 <Spin spinning={fetchingInformacionReporte}>
                                                                      <Descriptions layout="horizontal" bordered={true}>
                                                                           {data.map(item => 
                                                                                <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                                           )}
                                                                      </Descriptions>
                                                                 </Spin>
                                                            </Col>

                                                            <Col span={8}>
                                                                 <br />
                                                                 <Table  size="small" rowKey="id" columns={columnsReport} dataSource={informacionReporte.camposReporte} loading={fetchingInformacionReporte} locale={{ emptyText: 'Sin datos' }} pagination={{ total: cantidadReporte, simple: true, current: this.state.page }} onChange={handleTableChange}
                                                                 />
                                                            </Col>

                                                            <Col span={15}>
                                                                 <br />
                                                                 <Card className="cardCatalogos" title={this.state.cardTitle}>
                                                                 { this.state.buttonDisabled ? (<>
                                                                      { this.state.tipo !== 'Servicio' ? (<>
                                                                           <Bar
                                                                                data={chartData}
                                                                                options={optionsData}
                                                                           />
                                                                      </>): (<>
                                                                           {/* CASO EN QUE SE SELECCIONA POR PRIMERA VEZ POR SERVICIO */}
                                                                           <Pie 
                                                                                data={charDataPie}
                                                                                options={optionsDataPie}
                                                                           />
                                                                      </>) }
                                                                 </>):(<>
                                                                      { this.state.showBarPagination ? (<>
                                                                           <Bar
                                                                           data={chartData}
                                                                           options={optionsData}
                                                                           />
                                                                      </>):(<>
                                                                           <Pie 
                                                                           data={charDataPie}
                                                                           options={optionsDataPie}
                                                                           />
                                                                      </>) }
                                                                      
                                                                 </>) }
                                                                 </Card>
                                                            </Col>
                                                       </>):(<>
                                                            <Col span={24}>
                                                                 <Spin spinning={fetchingInformacionReporte}>
                                                                      <Alert
                                                                           message="Encuestas Evaluadas"
                                                                           description="No hay encuestas evaluadas para el periodo, servicio y tipo seleccionado."
                                                                           type="warning"
                                                                           showIcon
                                                                           style={{ borderRadius: '2em' }}
                                                                      />
                                                                 </Spin>
                                                            </Col>
                                                       </>) }
                                                       </Row>
                                                  </TabPane>
                                                  <TabPane tab="Reporte por Preguntas" key="Pregunta">
                                                       <Row justify='space-around'>
                                                       { cantidadReporte > 0 ? (<>
                                                            <Col span={24}>
                                                                 <Spin spinning={fetchingInformacionReporte}>
                                                                      <Descriptions layout="horizontal" bordered={true}>
                                                                           {data.map(item => 
                                                                                <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                                           )}
                                                                      </Descriptions>
                                                                 </Spin>
                                                            </Col>

                                                            <Col span={8}>
                                                                 <br />
                                                                 <Table  size="small" rowKey="id" columns={columnsReport} dataSource={informacionReporte.camposReporte} loading={fetchingInformacionReporte} locale={{ emptyText: 'Sin datos' }} pagination={{ total: cantidadReporte, simple: true, current: this.state.page }} onChange={handleTableChange}
                                                                 />
                                                            </Col>

                                                            <Col span={15}>
                                                                 <br />
                                                                 <Card className="cardCatalogos" title={this.state.cardTitle}>
                                                                 { this.state.buttonDisabled ? (<>
                                                                      { this.state.tipo !== 'Servicio' ? (<>
                                                                           <Bar
                                                                                data={chartData}
                                                                                options={optionsData}
                                                                           />
                                                                      </>): (<>
                                                                           {/* CASO EN QUE SE SELECCIONA POR PRIMERA VEZ POR SERVICIO */}
                                                                           <Pie 
                                                                                data={charDataPie}
                                                                                options={optionsDataPie}
                                                                           />
                                                                      </>) }
                                                                 </>):(<>
                                                                      { this.state.showBarPagination ? (<>
                                                                           <Bar
                                                                           data={chartData}
                                                                           options={optionsData}
                                                                           />
                                                                      </>):(<>
                                                                           <Pie 
                                                                           data={charDataPie}
                                                                           options={optionsDataPie}
                                                                           />
                                                                      </>) }
                                                                      
                                                                 </>) }
                                                                 </Card>
                                                            </Col>
                                                       </>):(<>
                                                            <Col span={24}>
                                                                 <Spin spinning={fetchingInformacionReporte}>
                                                                      <Alert
                                                                           message="Encuestas Evaluadas"
                                                                           description="No hay encuestas evaluadas para el periodo, servicio y tipo seleccionado."
                                                                           type="warning"
                                                                           showIcon
                                                                           style={{ borderRadius: '2em' }}
                                                                      />
                                                                 </Spin>
                                                            </Col>
                                                       </>) }
                                                       </Row>
                                                  </TabPane>
                                                  <TabPane tab="Reporte por Promedio General del Servicio" key="Servicio">
                                                       <Row justify='space-around' gutter={[8,8]} style={{padding:'1em'}}>
                                                            <Col span={24}>
                                                                 <Spin spinning={fetchingInformacionReporte}>
                                                                      <Descriptions layout="horizontal" bordered={true}>
                                                                           <Descriptions.Item label='Cantidad de Encuestas'>{informacionReporte.cantidadEncuestas}</Descriptions.Item>
                                                                           { informacionReporte.camposReporte ? (
                                                                                <Descriptions.Item label='Promedio Mensual'>{informacionReporte.camposReporte[0].calificacion}</Descriptions.Item>
                                                                           ):false}
                                                                           <Descriptions.Item label='Promedio Anual'>{informacionReporte.promedioServicio}</Descriptions.Item>
                                                                      </Descriptions>
                                                                 </Spin>
                                                            </Col>

                                                            {/* <Col span={24}>
                                                                 <br />
                                                                 <Table  size="small" rowKey="id" columns={columnsReport} dataSource={informacionReporte.camposReporte} loading={fetchingInformacionReporte} locale={{ emptyText: 'Sin datos' }} pagination={{ total: cantidadReporte, simple: true, current: this.state.page }} onChange={handleTableChange}
                                                                 />
                                                            </Col> */}

                                                            <Col span={12}>
                                                            { this.props.tendenciaAnualGraph && this.props.promediosMensualesGraph ? (<>
                                                            { this.props.promediosMensualesGraph.etiquetas ? (<>
                                                            { this.props.promediosMensualesGraph.etiquetas.length > 1 ? (
                                                                 <Card className="cardCatalogos" title={<p><b>Tendencia del Servicio en el año</b></p>}>
                                                                      { this.props.tendenciaAnualGraph && this.props.promediosMensualesGraph ? (<>
                                                                      { this.props.promediosMensualesGraph.etiquetas ? (<>
                                                                           { this.props.promediosMensualesGraph.etiquetas.length > 1 ? (
                                                                                <Bar
                                                                                     data={
                                                                                          {
                                                                                               labels: this.props.promediosMensualesGraph.etiquetas,
                                                                                               datasets: [
                                                                                                    {
                                                                                                         label: 'Tendencia',
                                                                                                         data: this.props.tendenciaAnualGraph.cantidades,
                                                                                                         fill: false,
                                                                                                         borderColor: "#9f7e2f",
                                                                                                         type: 'line',
                                                                                                         borderDash: [3, 3],
                                                                                                         order: 0
                                                                                                    },
                                                                                                    {  
                                                                                                         label: 'Calificación',
                                                                                                         data: this.props.promediosMensualesGraph.cantidades,  
                                                                                                         backgroundColor: 'rgb(248,189,93,0.8)', 
                                                                                                         borderColor: 'rgb(248,189,93,1)',  
                                                                                                         borderWidth: 2,
                                                                                                         type: 'line',
                                                                                                         fill: false,
                                                                                                         order: 1,
                                                                                                    },
                                                                                               ],
                                                                                          }
                                                                                     }
                                                                                     options={
                                                                                          {
                                                                                               scales: {
                                                                                                    yAxes: [{
                                                                                                         ticks: {  beginAtZero: true, autoSkip: true, maxTicksLimit: 100 },
                                                                                                         scaleLabel: { display: true, labelString: ['Calificación'] } 
                                                                                                    }]
                                                                                               },
                                                                                               tooltips: { enabled: true },
                                                                                               hover: { animationDuration: 7 },
                                                                                               legend: { display: true, labels: {boxWidth: 15}, },
                                                                                          }
                                                                                     }
                                                                                />
                                                                           ): false }
                                                                      </>): false }
                                                                      </>):false }
                                                                 </Card>
                                                                 ): false }
                                                                 </>): false }
                                                                 </>):false }
                                                            </Col>
                                                                 

                                                            
                                                            <Col span={12}>
                                                                 <Card className="cardCatalogos" title={this.state.cardTitle}>
                                                                      <Pie 
                                                                           data={charDataPie}
                                                                           options={optionsDataPie}
                                                                      />
                                                                 </Card>
                                                            </Col>
                                                       </Row>
                                                  </TabPane>
                                             </Tabs>
                                        </Col>

                                        {/* <Col span={24}>
                                             <Radio.Group  buttonStyle="solid" style={{width: '100%', textAlign: 'center', }} size='medium' onChange={ onTipoReporte } >
                                                  <Radio.Button value="Folio" style={{width: '33%', borderRadius: '2em'}}> Reporte por Folio </Radio.Button>
                                                  <Radio.Button value="Pregunta" style={{width: '33%', borderRadius: '2em'}}> Reporte por Preguntas </Radio.Button>
                                                  <Radio.Button value="Servicio" style={{width: '33%', borderRadius: '2em'}}> Reporte por Promedio General del Servicio </Radio.Button>
                                             </Radio.Group>
                                        </Col> */}

                                             <Divider />
                                             { this.state.tipo !== 'Servicio' ? (<Col span={8}></Col>):(
                                                  <Col span={11}>
                                                       <Button disabled={ this.state.tipo !== 'Servicio' ? (this.state.buttonDisabled || informacionReporte.cantidad <= 0): (informacionReporte.cantidad <= 0) } onClick={onComparativos} className='nuevoUsuario'>
                                                            Comparativos
                                                       </Button>
                                                  </Col>
                                             )}
                                             
                                             { this.state.tipo !== 'Servicio' ? (
                                                  <Col span={15}>
                                                       <Button disabled={ informacionReporte.cantidad <= 0 ? true : !permisosUsuario.exportarEvaluaciónDelServicio }  className='btnCargaArchivos' style={{ width: '100%' }} onClick={  !this.state.showBarPagination ? (this.state.buttonDisabled || informacionReporte.cantidad <= 0 ? (onDescargaGeneral):(onDescarga)) : onDescargaGeneral} loading={ !this.state.showBarPagination ? ( this.state.buttonDisabled || informacionReporte.cantidad <= 0 ? (fetchingGeneralReport):(fetchingReporteEncuestasPDF)) : fetchingGeneralReport }>
                                                            <b>Exportar Evaluación del Servicio <FilePdfOutlined /></b>
                                                       </Button>
                                                  </Col>
                                             ):(
                                                  <Col span={11}>
                                                  <Button disabled={ informacionReporte.cantidad <= 0  ? true : !permisosUsuario.exportarEvaluaciónDelServicio }  className='btnCargaArchivos' style={{ width: '100%' }} onClick={onDescarga} loading={fetchingReporteEncuestasPDF}>
                                                       <b>Exportar Evaluación del Servicio <FilePdfOutlined /></b>
                                                  </Button>
                                             </Col>
                                             ) }

                                   </>): (<Col span={24}>
                                        <Row justify='center' justify='space-around' align='middle' gutter={[8,8]}>
                                        <Col span={24}>
                                             <Alert
                                                  message="Reportes de Encuestas de Satisfacción"
                                                  description="Selecciona un mes-año, servicio y presiona el botón de búsqueda para continuar"
                                                  type="info"
                                                  showIcon
                                                  style={{ borderRadius: '2em' }}
                                             />
                                        </Col>
                                        <Col span={7} style={{paddingTop:'3em', paddingBottom:'1em', textAlign:'center'}}>
                                        {/* <img src={imaSearch} /> */}
                                             <ReactVivus
                                                  id="imaSearch"
                                                  option={{
                                                       file: imaSearch,
                                                       animTimingFunction: "EASE",
                                                       type: "delayed",
                                                  }}
                                             />
                                        </Col>
                                   </Row>
                                   </Col>) }

                              </Row>
                         </Form>

                         <ModalComparativos />
                    </Spin>
                    </>): (<>
                         <Row justify='center'>
                              <Result
                                   status="403"
                                   title="Acceso denegado"
                                   subTitle="Lo siento, no tiene permiso autorizado para acceder."
                              />
                         </Row>
                    </>) }
               </Card>
          );
     }
}
 
 const mapStateToProps = state => {
     return {
          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          fetchingInformacionReporte: state.GestionDeCalidadReducer.fetchingInformacionReporte,
          informacionReporte: state.GestionDeCalidadReducer.informacionReporte,
          fetchingReporteEncuestasPDF: state.GestionDeCalidadReducer.fetchingReporteEncuestasPDF,
          columnsReport: state.GestionDeCalidadReducer.columnsReport,
          listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
          fetchingListaInfoProyecto: state.InformacionDelProyectoReducer.fetchingListaInfoProyecto,
          fetchingGeneralReport: state.GestionDeCalidadReducer.fetchingGeneralReport,
          informacionReporteCantidad: state.GestionDeCalidadReducer.informacionReporteCantidad,
          tendenciaAnualGraph: state.GestionDeCalidadReducer.tendenciaAnualGraph,
          promediosMensualesGraph: state.GestionDeCalidadReducer.promediosMensualesGraph
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          onGetServiciosActivos: () => {
               dispatch ({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST' });
          },
          onGetInfoProyecto: () => {
               dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
          },
          onGetInformacionReporte: (idServicio, idMes, anio, tipo, page) => {
               dispatch({ type: 'OBTENER_INFORMACION_REPORTE_REQUEST', idServicio, idMes, anio, tipo, page});
          },
          onCleanInformacionReporte: () => {
               dispatch({ type: 'OBTENER_INFORMACION_REPORTE_SUCCESS', informacionReporte: []});
          },
          onExportPDF: (idMes, annio, idServicio, cantidadEncuestas, promedioServicio, camposReporte, cantidad, camposReporteOrigin, tipo, informacionReporte) => {
               dispatch({ type: 'OBTENER_INFORMACION_REPORTE_PDF_REQUEST', idMes, annio, idServicio, cantidadEncuestas, promedioServicio, camposReporte, cantidad, camposReporteOrigin, tipo, informacionReporte });
          },
          onShowComparativos: (tipoReporteSeleccionado, infoReporteSeleccionado) => {
               dispatch({ type: 'SHOW_MODAL_COMPARATIVOS', showModalComparativos: true, tipoReporteSeleccionado, infoReporteSeleccionado });
          },
          onGetGeneralReportExport: (idServicio, idMes, anio, tipo) => {
               dispatch({ type: 'GET_GENERAL_REPORT_PDF_REQUEST', idServicio, idMes, anio, tipo });
          },
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(ReporteResultadosContainer);