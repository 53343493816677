import React from 'react';
import {connect} from 'react-redux';
import {  Button, Row, Col, Table, Modal, Radio, Tooltip} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { FilePdfFilled, FileExcelFilled } from '@ant-design/icons';

const { confirm } = Modal;
class TableSection extends React.Component {
    state = {
        agrupado: 'SinAgrupar',
        tipoAgrupado: '',
        orden: {
            field: 'ser.Nombre',
            order: 'ASC'
        }
    }
    render() {
        const {fetchingListaReportesDinamico, listaReportesDinamico, listaReportesDinamicoCantidad, onModalNuevoRegistroReporte,
            formularioReporte, handlers, data, onDescargarReportePDF, onDescargarReporteExcel, fetchingExportarReportesDinamico,
            listaReportesDinamicoAgrupado, fetchingListaReportesDinamicoAgrupado, listaReportesDinamicoAgrupadoCantidad, 
            onGetReporteDinamico, onGetReporteDinamicoAgrupado,
        } = this.props;
        
        const {checkbox, checkArray, state, order} = data;
        const {handleTableChange, handleSteps} = handlers;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        
        const onHandleTableChange = (pagination, filter, orden) => {
            handleTableChange(pagination, filter, orden);
        }

        const onHandleSteps = (value) => {
            handleSteps(value);
        }
        
        const descargarReporte = value => {
            const agrupado = this.state.agrupado;
            const busqueda = {
                formularioReporte, 
                checkbox,
                order,
                agrupado 
            }
            
            confirm({
                title: '¿Deseas guardar el reporte como una vista predeterminada?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onModalNuevoRegistroReporte(busqueda, formularioReporte, checkbox, order, value);
                },
                onCancel() {
                    if (value === 'pdf') return onDescargarReportePDF(formularioReporte, checkbox, order, permisosUsuario.especialEvaluaciónDeLaGestión, agrupado);
                    onDescargarReporteExcel(formularioReporte, checkbox, order, permisosUsuario.especialEvaluaciónDeLaGestión, agrupado);
                },
            });
            
        }

        
        const columns = [
            {title: 'Gerencia', dataIndex: 'nombreGerencia', key: 'gerenciaC', sorter: true}, 
            {title: 'Centro de costos', dataIndex: 'centroCosto', key: 'centroC', sorter: true},
            {title: 'Servicio', dataIndex: 'nombreServicio', key: 'servicioC', sorter: true}, 
            {title: 'Partida', dataIndex: 'nombrePartida', key: 'partidaC', sorter: true},
            {title: 'Proveedor', dataIndex: 'nombreProveedor', key: 'proveedorC', sorter: true},
            {title: 'Objetivo', dataIndex: 'objetivo', key: 'objetivoC', sorter: true},
            {title: 'Temas', dataIndex: 'tema', key: 'temaC', sorter: true},
            {title: 'Indicador de desempeño', dataIndex: 'indicadorDesempeno', key: 'indicadorC', sorter: true},
            {title: 'Valor esperado', dataIndex: 'valorEsperado', key: 'valorEsperadoC', sorter: true, align: 'right'},
            {title: 'Valor real', dataIndex: 'valorReal', key: 'valorRealC', sorter: true, align: 'right'},
            {title: 'Mes evaluado', dataIndex: 'fechaEvaluacion', key: 'fechaEvaluacionC'},
        ];
        
        const columnsSelected = columns.filter( field => checkArray.includes(field.key));
        const scroll = columnsSelected.length > 4 ? {x: columnsSelected.length * 200} : {x: 700}

        const onAgrupar = (value) => {
            const tipoAgrupado = value.target.value === 'Por servicio' ? 'Servicio' : value.target.value === 'Por grupo servicio' ? 'Grupo de Servicio' : value.target.value === 'Por gerencia' ? 'Gerencia' : value.target.value === 'Por centro de costo' ? 'Centro de Costos' : value.target.value === 'Por objetivo' ? 'Objetivo' : 'Tema'
            this.setState({
                agrupado: value.target.value,
                tipoAgrupado
            });
            formularioReporte.tipoAgrupado = value.target.value;
            if(value.target.value !== 'SinAgrupar'){
                onGetReporteDinamicoAgrupado(formularioReporte, 1, checkbox, order, permisosUsuario.especialGestiónDeCalidad)
            }
            else{
                onGetReporteDinamico(formularioReporte, 1, checkbox, order, permisosUsuario.especialGestiónDeCalidad)
            }
        }


        const columnsAgrupamiento = [
            { title: this.state.tipoAgrupado, dataIndex: 'nombre', key: 'nombre' },
        ];

        const onChangeAgrupado = (record, pagination, filter, orden) => {
            console.log(`record, e`, record, pagination, filter, orden);
            const orderFields = {gerenciaC:'cg.NombreGerencia', centroC:'cc.CentroCosto', servicioC:'ser.Nombre',
                partidaC:'part.Nombre', proveedorC:'pro.Nombre', objetivoC: 'obj.Objetivo', temaC: 'tem.tema', indicadorC:' id.IndicadorDesempeño',
                valorEsperadoC: 'id.ValorEsperado', valorRealC: 'ei.ValorReal', mesC:'meses.Mes', anioC: 'ei.Anio'
            }
            record.listaReportesDinamicoAgrupado = listaReportesDinamicoAgrupado;
            record.listaReportesDinamicoAgrupadoCantidad = listaReportesDinamicoAgrupadoCantidad;
            console.log('record.listaReportesDinamicoAgrupado', record.listaReportesDinamicoAgrupado)
            if(orden) {
                const field = orden.columnKey ? orderFields[orden.columnKey] : 'ser.Nombre';
                const orderField = orden.order === 'descend' ? 'DESC': 'ASC';
                const sorter = {field: field, order: orderField}
                this.setState({orden: sorter})
                console.log(`sorter`, sorter)
                return onGetReporteDinamico(formularioReporte, pagination.current, checkbox, sorter, permisosUsuario.especialEvaluaciónDeLaGestión, record );
            }
            onGetReporteDinamico(formularioReporte, pagination.current, checkbox, this.state.orden, permisosUsuario.especialEvaluaciónDeLaGestión, record);
        }

        return (
            <>
                <Row justify='space-between'>
                    <Col span={3}>
                        <Row justify='space-around'>
                            <Col span={11}>
                                {/* <Button className="btnCargaArchivos" disabled={!permisosUsuario.exportarPdfReporteDinámicoParaEvaluaciónDeLaGestión ? true : !listaReportesDinamico.length>0 || fetchingExportarReportesDinamico} loading={fetchingExportarReportesDinamico}
                                onClick={()=> descargarReporte('pdf')} style={{ width: '100%', marginTop: '20px' }}
                                >
                                    Exportar Reporte PDF
                                </Button> */}
                                <Tooltip title='Exportar archivo .pdf'>
                                    <Button className="iconSecundary" shape='circle' icon={<FilePdfFilled /> } disabled={!permisosUsuario.exportarPdfReporteDinámicoParaEvaluaciónDeLaGestión ? true : !listaReportesDinamico.length>0 || fetchingExportarReportesDinamico} loading={fetchingExportarReportesDinamico} onClick={()=> descargarReporte('pdf')} style={{marginTop: '20px' }}/>
                                </Tooltip>
                            </Col>
                            <Col span={11}>
                                {/* <Button className="btnCargaArchivos" disabled={!permisosUsuario.exportarExcelReporteDinámicoParaEvaluaciónDeLaGestión ? true : !listaReportesDinamico.length>0 || fetchingExportarReportesDinamico} loading={fetchingExportarReportesDinamico}
                                onClick={()=> descargarReporte('excel')} style={{ width: '100%', marginTop: '20px' }}
                                >
                                    Exportar Reporte Excel    
                                </Button> */}
                                <Tooltip title='Exportar archivo .xls'>
                                    <Button className="iconSecundary" shape='circle' icon={<FileExcelFilled /> } disabled={!permisosUsuario.exportarExcelReporteDinámicoParaEvaluaciónDeLaGestión ? true : !listaReportesDinamico.length>0 || fetchingExportarReportesDinamico} loading={fetchingExportarReportesDinamico} onClick={()=> descargarReporte('excel')} style={{marginTop: '20px' }}/>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Col>
                    
                    <Col span={12}>
                        <br/>
                        <Radio.Group defaultValue='SinAgrupar' onChange={onAgrupar}  size="small">
                            <Radio.Button value="Por servicio">Servicio</Radio.Button>
                            <Radio.Button value="Por grupo servicio">Grupo Servicios</Radio.Button>
                            <Radio.Button value="Por gerencia">Gerencia</Radio.Button>
                            <Radio.Button value="Por centro de costo">Centro de Costos</Radio.Button>
                            <Radio.Button value="Por objetivo">Objetivo</Radio.Button>
                            <Radio.Button value="Por tema">Tema</Radio.Button>
                            <Radio.Button value='SinAgrupar'>Sin Agrupamiento</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Row style={{ paddingTop: '20px' }}>
                            <Col span={24}>
                                {this.state.agrupado !== 'SinAgrupar' ? (
                                <Table
                                    size='small'
                                    rowKey='id'
                                    columns={columnsAgrupamiento}
                                    dataSource={listaReportesDinamicoAgrupado}
                                    expandable={{expandedRowRender: record => <Table rowKey="id" columns={columns} loading={fetchingListaReportesDinamico} onChange={(e,u,i) => onChangeAgrupado(record, e, u, i)} dataSource={record.agrupados.data} pagination={{ total: record.agrupados.cantidad, simple: true, current: record.agrupados.page }} />} }
                                    loading={fetchingListaReportesDinamicoAgrupado}
                                    locale={{ emptyText: 'Sin datos' }}
                                    onChange={onHandleTableChange}
                                    scroll={scroll}
                                    pagination={{ total: listaReportesDinamicoAgrupadoCantidad, simple: true, current: state.page }}
                                />
                                ):
                                (
                                <Table
                                    size='small'
                                    rowKey='idReporte'
                                    columns={columnsSelected}
                                    dataSource={listaReportesDinamico}
                                    loading={fetchingListaReportesDinamico}
                                    locale={{ emptyText: 'Sin datos' }}
                                    onChange={onHandleTableChange}
                                    scroll={scroll}
                                    pagination={{ total: listaReportesDinamicoCantidad, simple: true, current: state.page }}
                                />
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify='start'>
                    <Col span={6}>
                        <Button className="btnFiltrar" onClick={() => onHandleSteps('atras')} style={{ width: '100%', marginTop: '20px' }}>Anterior</Button>
                    </Col>
                </Row>
            </>
        )
    }
}


const mapStateToProps = state => {
    return {
        fetchingListaReportesDinamico: state.EvaluacionDeLaGestionReducer.fetchingListaReportesDinamico,
        listaReportesDinamico: state.EvaluacionDeLaGestionReducer.listaReportesDinamico,
        listaReportesDinamicoCantidad: state.EvaluacionDeLaGestionReducer.listaReportesDinamicoCantidad,
        fetchingListaReportesDinamicoAgrupado: state.EvaluacionDeLaGestionReducer.fetchingListaReportesDinamicoAgrupado,
        listaReportesDinamicoAgrupado: state.EvaluacionDeLaGestionReducer.listaReportesDinamicoAgrupado,
        listaReportesDinamicoAgrupadoCantidad: state.EvaluacionDeLaGestionReducer.listaReportesDinamicoAgrupadoCantidad,
        formularioReporte: state.EvaluacionDeLaGestionReducer.formularioReporte,
        fetchingExportarReportesDinamico: state.EvaluacionDeLaGestionReducer.fetchingExportarReportesDinamico,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onDescargarReportePDF: (formulario, checkbox, order, especial, agrupado) => {
            dispatch({ type: 'EXPORTAR_REPORTE_PDF_REQUEST', formulario, checkbox, order, tituloDocumento: null, idReporteEvaluacion: null, especial, agrupado});
        },
        onDescargarReporteExcel: (formulario, checkbox, order, especial, agrupado) => {
            dispatch({ type: 'EXPORTAR_REPORTE_EXCEL_REQUEST', formulario,checkbox, order, tituloDocumento: null, idReporteEvaluacion: null, especial, agrupado});
        },
        onModalNuevoRegistroReporte: (busqueda, formulario, checkbox, order, tipoDocumento) => {
            dispatch({ type: "MODAL_NUEVO_REGISTRO_REPORTE", showModalNuevoRegistroReporte: true, busqueda, data: {formulario, checkbox, order, tipoDocumento} });
        },
        onGetReporteDinamico: (formulario, page, checkboxData, order, especial, agrupado) => {
            dispatch({ type: 'LISTA_REPORTE_DINAMICO_REQUEST', formulario, page, checkboxData, order, especial, agrupado });
        },
        onGetReporteDinamicoAgrupado: (formulario, page, checkboxData, order, especial) => {
            dispatch({ type: 'LISTA_REPORTE_DINAMICO_AGRUPADO_REQUEST', formulario, page, checkboxData, order, especial });
        },
    }
}

export default connect (mapStateToProps, mapDispatchToProps) (TableSection);