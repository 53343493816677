import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Form, message, Tooltip, Checkbox } from 'antd';
import { EditOutlined, SearchOutlined, FileSearchOutlined, LockOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoServicio from './ModalNuevoServicio';
import ModalEditarServicio from './ModalEditarServicio';
import ModalArchivos from './ModalArchivos';
import { loginCall } from "../../../services/LoginServices";


const confirm = Modal.confirm;
class CatalogoDeServicios extends React.Component {
    formRef = React.createRef();
    componentDidMount(){
        const formulario = {
            grupo: '',
            servicio: '',
        };
        this.props.onGetServicios(1, formulario);
        this.props.onGetContratos();
        this.props.onGetGruposServicios();
        this.props.onRequestInfoProyecto();
    }
    state={
        page: 1,
        formulario: {
            grupo: '',
            servicio: '',
        },
        password: '',
        sinFiltros: false,
    }
    render() {
        const { fetchingListaServiciosBusqueda ,listaServiciosBusqueda, onEstadoServicio, onGetServicios, 
            onModalEditarServicio, onModalNuevoServicio, listaGruposServicios, onArchivosServicio,
            listaServiciosBusquedaCantidad, onGetServiciosSinInfo
        } = this.props;

        let userInfo = sessionStorage.getItem('userInfo');
        if(!userInfo.idUsuario){
            userInfo = JSON.parse(userInfo);
        }

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        const onFinish = formulario => {
            this.setState({
                formulario: formulario,
                page: 1,
            })
            console.log(formulario);
            onGetServicios(1, formulario);
        }

        
        const onVer = (key) => {
            onArchivosServicio(key, this.state.page);
        }
        
        const columns = [{
                title: 'Nomenclatura',
                dataIndex: 'nomenclatura',
                key: 'nomenclatura',
                width: '6%',
            },{
                title: 'Nombre',
                dataIndex: 'nombre',
                key: 'nombre',
                width: '9%',
            },{
                title: 'Grupo de servicio',
                dataIndex: 'nombreGrupoSer',
                key: 'nombreGrupoSer',
                width: '8%',
            },{
                title: 'Valor ponderado',
                dataIndex: 'valorPonderado',
                key: 'valorPonderado',
                width: '6%',
                align: 'right',
            },{
                title: 'Ruta manual procedimientos',
                dataIndex: 'rutaManual',
                key: 'rutaManual',
                width: '13%',
            },{
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                width: '5%',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.servicioActivoInactivo} onChange={() => handleDesactivar(record)}/>
                ),
            },{
                title: 'Ver archivos',
                key: 'ver',
                width: '4%',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.verArchivosDeServicio} onClick={() => onVer(record)}><FileSearchOutlined /></a>
                ),
            },{
                title: 'Editar',
                key: 'editar',
                width: '4%',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarServicio} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },
        ];
        const onEditar = (key) => {
            console.log(key);
            for(let i=0; i<listaGruposServicios.length; i++){
                if(key.idGrupoSer === listaGruposServicios[i].idGrupoSer){
                    key.grupoServicio = listaGruposServicios[i].nombre
                }
            }
            key.valorPonderado = key.valorPonderado.split('%')[0];

            onModalEditarServicio(key, this.state.page, this.state.formulario);
            this.props.onContratosActivosIdServicio(key.idServicio)
        }
        const onPassword = (value) => {
            this.setState({
                password: value.target.value,
            });
        };
        const onCambiarEstado = (key) => {
            console.log(key);
            console.log('userInfo');
            console.log(userInfo);
            const userName = userInfo[0].username;
            const values = {
                'userName': userName,
                'password': this.state.password,  
            }
            const page = this.state.page;
            loginCall(values).then(response => {
                console.log('response');
                console.log(response);
                
                if(response.status === 200){
                    onEstadoServicio(key.idServicio, page)
                }
                else{
                    let errorMensaje = '';
                    if(response.error_message === 'Bad credentials'){
                        errorMensaje = 'Contraseña incorrecta. Por favor intente de nuevo';
                    }
                    else{
                        errorMensaje = response.error_message;
                    }
                    message.error(errorMensaje);
                    return;
                }
                this.setState({
                    password: '',
                });
            }).catch(error => {
                message.error('Hubo un error, favor de intentar después');
                return;
            })
        }
        
        const handleDesactivar = (key) => {
            console.log(key);
            confirm({
                title: <p>¿Estás seguro que deseas cambiar el estado del servicio "{key.nombre}"? <br/><strong style={{color: '#ff7875'}}>Los usuarios y proveedores asignados a este servicio se verán afectados.</strong></p>,
                content: <Input.Password prefix={<LockOutlined/>} placeholder="Ingrese contraseña" onChange={onPassword} />,
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onCambiarEstado(key);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            if(this.state.sinFiltros === true){
                onGetServiciosSinInfo(pagination.current, this.state.sinFiltros);
            }
            else{
                onGetServicios(pagination.current, this.state.formulario);
            }
		}
        const onNuevoServicio = () => {
            onModalNuevoServicio(this.state.page, this.state.formulario);
        }
        const onSinInfo = (value) => {
            if(value.target.checked === true){
                onGetServiciosSinInfo(this.state.page, value.target.checked);
            }
            else{
                const formulario = {
                    grupo: '',
                    servicio: '',
                };
                onGetServicios(1, formulario);
            }
            this.setState({
                sinFiltros: value.target.checked,
            })
            this.formRef.current.resetFields();
            
        }
        const scroll = {"x": 2000 | true, "y": 1000};
        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={24}>
                        <Form
                        name="formulario"
                        onFinish={onFinish}
                        
                        ref={this.formRef}
                        >
                            <Row justify='space-around' gutter={[8,8]}>
                                <Col span={10}>
                                    <Form.Item
                                    name="servicio"
                                    >
                                        <Input disabled={this.state.sinFiltros} className='inputFiltro' placeholder="Ingrese servicio a filtrar"/>
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        name="grupo"
                                    >
                                        <Input disabled={this.state.sinFiltros} className='inputFiltro' placeholder="Ingrese grupo a filtrar"/>
                                    </Form.Item>
                                </Col>
                                <Col span={1}>
                                    <Button disabled={this.state.sinFiltros} shape="circle" icon={<SearchOutlined />} className="btnFiltrar" htmlType="submit"></Button>
                                </Col>

                                <Col span={1}>
                                    <Tooltip title='Nuevo Servicio' placement="topRight">
                                        <Button className="iconTer" shape="circle" onClick={onNuevoServicio} disabled={!permisosUsuario.crearServicio}  icon={<PlusOutlined />}/>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Form>
                    </Col>

                    
                    <Col span={5}>
                        <Tooltip title="Mostrar los registros que no cuentan con un grupo de servicios">
                            <Checkbox defaultChecked={false} onChange={onSinInfo}>Sin información</Checkbox>
                        </Tooltip>
                    </Col>
                    {/* <Col offset={13} span={5}>
                        <Button onClick={onNuevoServicio} disabled={!permisosUsuario.crearServicio} className='nuevoUsuario'>Nuevo Servicio</Button>
                    </Col> */}

                    

                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="nombre" 
                            columns={columns}
                            dataSource={listaServiciosBusqueda}
                            loading={fetchingListaServiciosBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaServiciosBusquedaCantidad, simple: true, current: this.state.page }}
                            scroll={scroll}
                        />         
                    </Col>
                </Row>
                <ModalNuevoServicio/>
                <ModalEditarServicio/>
                <ModalArchivos/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaServiciosBusqueda: state.CatalogosGeneralesReducer.listaServiciosBusqueda,
        fetchingListaServiciosBusqueda: state.CatalogosGeneralesReducer.fetchingListaServiciosBusqueda,
        listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,
        contraseñaValidada: state.LoginReducer.contraseñaValidada,
        listaServiciosBusquedaCantidad: state.CatalogosGeneralesReducer.listaServiciosBusquedaCantidad,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetServicios: (page, formulario) => {
	    	dispatch({ type: "LISTA_SERVICIOS_BUSQUEDA_REQUEST", page: page, formulario: formulario });
        },
        onGetContratos: () => {
	    	dispatch({ type: "LISTA_CONTRATOS_REQUEST" });
        },
        onGetGruposServicios: () => {
	    	dispatch({ type: "LISTA_GRUPOS_SERVICIOS_REQUEST" });
        },
        onEstadoServicio: (idServicio, page) => {
	    	dispatch({ type: "ESTADO_SERVICIO_REQUEST", idServicio: idServicio, page: page });
        },
        onModalNuevoServicio: (page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_SERVICIO", showModalNuevoServicio: true, page, busqueda });
        },
        onModalEditarServicio: (servicioSeleccionado, page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_SERVICIO", showModalEditarServicio: true, servicioSeleccionado, page, busqueda });
        },
        onArchivosServicio: (servicioSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_ARCHIVOS_SERVICIO", showArchivosServicio: true, servicioSeleccionado: servicioSeleccionado, page: page });
        },
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        },
        onGetServiciosSinInfo: (page, check) => {
            dispatch({ type: 'LISTA_SERVICIOS_SIN_INFO_REQUEST', page, check});
        },
        onContratosActivosIdServicio: (idServicio) => {
            dispatch({ type: 'LISTA_CONTRATOS_ACTIVOS_ID_SERVICIO_REQUEST', idServicio});
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoDeServicios);