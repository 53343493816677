import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, Spin, Select, DatePicker, InputNumber, Divider, message } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class EditarTiempoDeGarantia extends Component {
    formRef = React.createRef();
    state={
        tipoEdicion: null,
        disabledSelect: false,
        nivelEditado: false,
    }
    render() { 

        const {
            onEditarNivelSeveridad, nivelSeveridadSeleccionado, fetchingEditarNivelSeveridad,
            listaInfoProyecto, fetchingListaInfoProyecto, onLimpiarFormulario, limpiarFormulario,
            onCambiarVista, page, busqueda,
        } = this.props;
        

        if(limpiarFormulario && limpiarFormulario === 'editarNivelSeveridad'){
            console.log('entró a limpiar');
            onLimpiarFormulario();
            onCambiarVista(1);
        }

        const onFinish = formulario => {
            console.log('Success:', formulario);
            console.log('tiempo de garantia seleccionado', nivelSeveridadSeleccionado);
            const rangeValue = formulario['rangoVigencia'];
            formulario.rangoVigencia = rangeValue[0].format('YYYY-MM-DD') + "," + rangeValue[1].format('YYYY-MM-DD');
            formulario.fechaInicioVigencia = formulario.rangoVigencia.split(',')[0];
            formulario.fechaFinVigencia = formulario.rangoVigencia.split(',')[1];
            if(this.state.tipoEdicion === 'simple'){
                console.log('formulario');
                console.log(formulario);
                onEditarNivelSeveridad(formulario, page, nivelSeveridadSeleccionado, busqueda)
            }
            else{
                onCambiarVista(2, formulario);
                console.log('Pedir contraseña ');
                this.props.onListaNivelesSeveridadActivos(formulario, nivelSeveridadSeleccionado);
            }
        }

        const fechas = (rangeValue) => {
            if(listaInfoProyecto.fechaInicio){
                if(rangeValue[0]){
                    const fechaInicioAnterior = nivelSeveridadSeleccionado.fechaInicioVigencia.split('T')[0];
                    const diferenciaAnterior = moment(fechaInicioAnterior, 'YYYY-MM-DD').diff(moment(rangeValue[0].format('YYYY-MM-DD')), "days");
                    console.log('diferenciaAnterior');
                    console.log(diferenciaAnterior);
                    if(this.state.nivelEditado){
                        if(diferenciaAnterior > -1){
                            message.error('No es posible seleccionar una fecha de inicio menor ni igual a la fecha de inicio anterior (' + fechaInicioAnterior.split('-').reverse().join('-') + ')', 7);
                            this.formRef.current.setFieldsValue({'rangoVigencia': [null, nivelSeveridadSeleccionado.rangoVigencia[1]]});
                        }
                    }
                }
            }
            else{
                message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                this.formRef.current.setFieldsValue({'rangoVigencia': [null, nivelSeveridadSeleccionado.rangoVigencia[1]]});
            }
        };

        const handleUnidadMedida = () => {
            const unidadChange = nivelSeveridadSeleccionado.unidadMedida === this.formRef.current.getFieldValue(['unidadMedida']);
            if(this.state.tipoEdicion === 'completa'){
                if (!unidadChange) {
                    this.setState({nivelEditado: true})
                    if(this.formRef.current.getFieldValue('rangoVigencia')[0]){
                        message.warning('Por motivos de seguridad, favor de seleccionar de nuevo la fecha de inicio de vigencia', 7);
                    }
                    this.formRef.current.setFieldsValue({'rangoVigencia': [null, nivelSeveridadSeleccionado.rangoVigencia[1]]});
                }
                else{
                    this.setState({nivelEditado: false})
                }
            }
        }

        const disabledRange = fecha => {
            return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicio
        }

        const onSelectTipoEdicion = (value) => {
            this.setState({
                tipoEdicion: value,
                disabledSelect: true,
            })
        }

        const changeDecimales = (value) => {
            const tiempoChange = parseInt(nivelSeveridadSeleccionado.tiempoRespuesta) === value;
            
            if(this.state.tipoEdicion === 'completa'){
                if (!tiempoChange) {
                    this.setState({nivelEditado: true})
                    if(this.formRef.current.getFieldValue('rangoVigencia')[0]){
                        message.warning('Por motivos de seguridad, favor de seleccionar de nuevo la fecha de inicio de vigencia', 7);
                    }
                    this.formRef.current.setFieldsValue({'rangoVigencia': [null, nivelSeveridadSeleccionado.rangoVigencia[1]]});
                }
                else{
                    this.setState({nivelEditado: false})
                }
            }

            let valueDecimal = parseInt(value);
            if(isNaN(valueDecimal)){
                valueDecimal = 1;
            }
            this.formRef.current.setFieldsValue({'tiempoRespuesta': valueDecimal});
        }
        return ( 
            <div>
                {nivelSeveridadSeleccionado ? (
                    <Spin spinning={fetchingEditarNivelSeveridad}>
                        <Form {...layout}
                            name="formulario"
                            initialValues={nivelSeveridadSeleccionado}
                            onFinish={onFinish}
                            ref={this.formRef}
                        >
                            <Row justify='space-around'>
                                <Col span={11}>
                                    <p>Selecciona el tipo de edición</p>
                                </Col>
                                <Col span={11}>
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el tipo de edición"
                                        onChange={onSelectTipoEdicion}
                                        disabled={this.state.disabledSelect}
                                    >
                                        <Option key='simple'>Edición simple</Option>
                                        <Option key='completa'>Edición por convenio</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Divider/>
                            {this.state.tipoEdicion ?(
                            <Row justify='space-around'>

                                <Col span={24}>
                                    <Form.Item
                                        label="Severidad"
                                        name="severidad"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Input maxLength={100} placeholder='Ingresa la severidad'/> 
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="Descripción de severidad"
                                        name="descripcion"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Input maxLength={200} placeholder='Ingresa la descripción de la severdidad'/> 
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="Tiempo de respuesta"
                                        name="tiempoRespuesta"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <InputNumber min={1} onChange={changeDecimales} placeholder='Ingresa el tiempo de respuesta'/> 
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="Unidad de medida"
                                        name="unidadMedida"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona la unidad de medida"
                                            onChange={handleUnidadMedida}
                                            showSearch
                                        >
                                            <Option key={'Minutos'}>{'Minutos'}</Option>
                                            <Option key={'Horas'}>{'Horas'}</Option>
                                            <Option key={'Días'}>{'Días'}</Option>
                                            <Option key={'Años'}>{'Años'}</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="Fechas vigencias del nivel de severidad"
                                        name="rangoVigencia"
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <RangePicker
                                            format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio","Fecha fin"]}
                                            disabledDate={disabledRange}
                                            loading={fetchingListaInfoProyecto}
                                            disabled={[false, true]} 
                                            allowClear={false}
                                            onChange={fechas}
                                            />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Editar Nivel de Severidad</Button>
                                </Col>
                            </Row>
                            ):false}
                        </Form>
                    </Spin>
                ) : false}
            </div>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        fetchingEditarNivelSeveridad: state.MecanismosDePagosReducer.fetchingEditarNivelSeveridad,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        fetchingListaInfoProyecto: state.InformacionDelProyectoReducer.fetchingListaInfoProyecto,
        page: state.MecanismosDePagosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        nivelSeveridadSeleccionado: state.MecanismosDePagosReducer.nivelSeveridadSeleccionado,
        vistaModalNivelSeveridad: state.MecanismosDePagosReducer.vistaModalNivelSeveridad,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onEditarNivelSeveridad: (formularioNivelSeveridad, page, nivelSeveridadSeleccionado, busqueda ) => {
            dispatch({ type: "EDITAR_NIVEL_SEVERIDAD_REQUEST", formularioNivelSeveridad, page, nivelSeveridadSeleccionado, busqueda })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onCambiarVista: (vistaModalNivelSeveridad, formularioNivelSeveridad) => {
            dispatch({ type: 'CAMBIAR_VISTA_NIVEL_SEVERIDAD', vistaModalNivelSeveridad, formularioNivelSeveridad, nivelesSeveridadEditados: null})
        },
        onListaNivelesSeveridadActivos: (formulario, nivelSeveridadSeleccionado) => {
            dispatch({ type: 'LISTA_NIVELES_SEVERIDAD_ACTIVOS_REQUEST', formulario, nivelSeveridadSeleccionado });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditarTiempoDeGarantia);