import React, {Component} from 'react';
import { Row, Col, Modal, Table, Input, Button, message, Card} from 'antd';
import { connect } from "react-redux";

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalReingresoActivo from './ModalReingresoActivo';
const { Search } = Input;
class ReingresoActivosContainer extends Component {
   // tableRef = React.createRef();
   componentDidMount(){
    this.props.onGetReingresoActivo(1, '');
    this.props.onGetFoliosActivos();
}
    state = {
        page: 1,
        busqueda: '',
        order: 'ASC',
        field: 'fechaSalida', 
        visible: false,
        reingresoSeleccionado: [], 
        selectedRowKeys: [], // Check here to configure the default column
        selectedRows: [],
        hasSelected: true,
    }

    render() { 
        const { 
            onGetReingresoActivo,
            listaReingresoActivosBusqueda,
            fetchinglistaReingresoActivosBusqueda,
            listaReingresoActivosBusquedaCantidad,
            onModalReingresoActivos,
            asignacionPDF,
            listaReingresoActivosFoliosAct,
        } = this.props;

        console.log('listaReingresoActivosFoliosAct: ', listaReingresoActivosFoliosAct);
        console.log('asignacionPDF', asignacionPDF);

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        };

        const limpiarSeleccionado = () => {
            this.setState({
                selectedRows: []
            })
        }

        const onBuscar = folio => {         
            const busqueda = {folio: folio, field: this.state.field, order: this.state.order}
            this.setState({ selectedRowKeys: [], busqueda: busqueda });
            onGetReingresoActivo(1, busqueda);
        }
        const onReingreso = () => {
            const { selectedRows } = this.state;
            onModalReingresoActivos(selectedRows, this.state.page);
             this.setState({ hasSelected: false });
        }

        const onChangeTabla = (record, selected, selectedRows) => {
            console.log('record: ', record);
            console.log('selected: ', selected);
            console.log('selectedRowKeys changed: ', selectedRows);

            let listaSeleccionadosActivos = []
            for (let i = 0; i < record.length; i++) {
                for (let x = 0; x < listaReingresoActivosFoliosAct.length; x++) {
                    if (record[i] === listaReingresoActivosFoliosAct[x].idMovimientoActivo) {
                        listaSeleccionadosActivos.push(listaReingresoActivosFoliosAct[x]);
                    }
                }
            }
            console.log('listaSeleccionadosActivos', listaSeleccionadosActivos);
            // const selectedRowsFilter = selected.filter(item => item ? true : false)

            for (let i = 0; i < listaSeleccionadosActivos.length; i++) {
                if (listaSeleccionadosActivos[0].folio !== listaSeleccionadosActivos[i].folio && record.length !== 0) {
                    message.error('No puedes dar reingreso a activos que tengan diferente folio de salida')
                    this.setState({ hasSelected: true });
                } else {
                    this.setState({ hasSelected: false });
                }
           }
           this.setState({ selectedRowKeys: record, selectedRows: listaSeleccionadosActivos });
       };

        const { selectedRowKeys } = this.state;

        const rowSelection = {
            selectedRowKeys,
            onChange: onChangeTabla,
        };

        const handleTableChange = pagination => {
            this.setState({
                page: pagination.current
            })
            onGetReingresoActivo(pagination.current, this.state.busqueda)
        }

        const columns = [{
            title: 'Folio de Salida',
            dataIndex: 'folio',
            width: '7%',
            key: 'folio',
        },{
            title: 'Descripción',
            dataIndex: ['activosCaractByIdActivosCaract', 'especificaciones'],
            key: 'descripcion',
            
        },{
            title: 'Código de Equipo',
            dataIndex: 'codigoEquipo',
            key: 'codigoEquipo',
           
        },{
           title: 'Código Interno',
           dataIndex: 'codigoInterno',
           key: 'codigoInterno',
           
        },{
            title: 'Num Serie',
            dataIndex: 'serieNum',
            key: 'serieNum',
            
        },{
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
            
        },{
            title: 'Clave Ubicación',
            dataIndex: 'claveUbicacion',
            key: 'claveUbicacion',
        },
        
    ];

    console.log("hasSelected",this.state.hasSelected)
        return ( 
            <Card className="cardCatalogos" title={<div className="headerLista">Reingreso de Activos</div>}>
                 <Row justify='space-around' gutter={[8,8]}>
                    <Col span={24}>
                        <Search className='buscarModulos' placeholder="Buscar folio de salida" onSearch={value => onBuscar(value)} style={{ width: '100%' }} />
                        {/* <Search className='buscarModulos' placeholder="Buscar folio de salida" style={{ width: '100%' }} /> */}
                    </Col>
                  </Row>
                    <Col span={24}>
                        <br />
                        <Table
                            size="small"
                            rowKey="idMovimientoActivo"
                            columns={columns}
                            rowSelection={rowSelection}
                            dataSource={listaReingresoActivosBusqueda}
                            loading={fetchinglistaReingresoActivosBusqueda}
                            locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaReingresoActivosBusquedaCantidad, simple: true, current: this.state.page }}
                          //  ref={this.tableRef}
                                
                           showSorterTooltip={true}
                        />
                    </Col>
                    
                    <ModalReingresoActivo onLimpiarSeleccionado={limpiarSeleccionado}/>
                     
                    <Col span={24}>
                        <Button className="btnFiltrar" style={{width: '40%'}} onClick={onReingreso} disabled={this.state.hasSelected || !permisosUsuario.exportarExcelActualizaciónTecnologica}> Generar Reingreso de Activo</Button>  
                    </Col>
            </Card>
        );
    }
}

const mapStateToProps  = state => {
    return {
        listaReingresoActivosBusqueda: state.AlmacenesReducer.listaReingresoActivosBusqueda,
        fetchinglistaReingresoActivosBusqueda: state.AlmacenesReducer.fetchinglistaReingresoActivosBusqueda,
        listaReingresoActivosBusquedaCantidad: state.AlmacenesReducer.listaReingresoActivosBusquedaCantidad,
        listaReingresoActivosFoliosAct: state.AlmacenesReducer.listaReingresoActivosFoliosAct,
        //PDF
        descargar: state.AlmacenesReducer.descargar,
        asignacionPDF: state.AlmacenesReducer.asignacionPDF,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onGetReingresoActivo: (page, busqueda) => {
            dispatch({ type: "REINGRESO_ACTIVOS_BUSQUEDA_REQUEST", page: page, busqueda: busqueda })
        },
        onModalReingresoActivos: (reingresoSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_REINGRESO", showModalReingresoActivos: true, reingresoSeleccionado: reingresoSeleccionado, page: page})
        },
        onGetFoliosActivos: () => {
            dispatch({ type: "REINGRESO_ACTIVOS_LISTA_ACT_REQUEST"});
        }
    }     
}
export default connect(mapStateToProps, mapDispatchToProps)(ReingresoActivosContainer)