import axios from "axios";

//const endpoint = "https://papantla-services.herokuapp.com/";
//const endpoint = "https://papantla-dev.herokuapp.com/";
const endpoint = 'https://morelos-dev.herokuapp.com/';
//const endpoint = "https://papantla-demo.herokuapp.com/";

//const endpoint = "http://172.24.0.170:8080/"

//const endpoint = 'https://papantla-services-jose.herokuapp.com/';
//const endpoint = 'https://papantla-dev-jose.herokuapp.com/';

//const endpoint = 'https://papantla-secret-jose.herokuapp.com/';
//const endpoint = 'https://papantla-secret.herokuapp.com/';

export function listaFamiliasDeActivosCall(token) {
	return axios({
	    method: "get",
	    url: endpoint + "familias/activas",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//Servicios de Catálogo de Grupos de Servicios
export function listaGruposServiciosBusquedaCall(token, page, busqueda) {
	return axios({
	    method: "get",
	    url: endpoint + "servicios/grupos?" + busqueda + "page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function estadoGrupoServicioCall(token, idGrupoSer) {
	return axios({
	    method: "get",
	    url: endpoint + "servicios/grupos/activa/" + idGrupoSer,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function crearGrupoServicioCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "servicios/grupos",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function editarGrupoServicioCall(token, data, idGrupoServicio) {
	return axios({
	    method: "put",
	    url: endpoint + "servicios/grupos/" + idGrupoServicio,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaGruposServiciosCall(token) {
	return axios({
	    method: "get",
	    url: endpoint + "servicios/grupos/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Servicios de Catálogo de Servicios
export function listaServiciosCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "servicios/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaServiciosByCentroCostoCall(token, data) {
	return axios({
	    method: "post",
		url: endpoint + "servicios/reporte/evaluacion",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaServiciosByProveedorCall(token, idProveedor) {
	return axios({
	    method: "get",
		url: endpoint + "servicios/proveedor/" + idProveedor + "/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaServiciosByGrupoServicioCall(token, idGrupoSer) {
	return axios({
	    method: "get",
		url: endpoint + "servicios/by/grupos/" + idGrupoSer,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaServiciosByGruposServiciosCall(token, listaGruposServicios) {
	return axios({
	    method: "get",
		url: endpoint + "servicios/by/grupos?idsGruposSer=" + listaGruposServicios,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaServiciosBusquedaCall(token, page, servicio, grupoSer) {
	return axios({
	    method: "get",
	    url: endpoint + "servicios?" + servicio + grupoSer + "page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function listaServiciosSinInfoCall(token, page) {
	return axios({
	    method: "get",
	    url: endpoint + "servicios?check=true&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function estadoServicioCall(token, idServicio) {
	return axios({
	    method: "get",
	    url: endpoint + "servicios/activa/" + idServicio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaServiciosByIdGerenciaCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "servicios/activos/gerencias/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaServiciosByIdsGruposSerCall(token, idsGruposSer) {
	return axios({
	    method: "get",
	    url: endpoint + "servicios/activos/by/" + idsGruposSer,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearServicioCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "servicios/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarServicioCall(token, data, idServicio) {
	return axios({
	    method: "put",
		url: endpoint + "servicios/" + idServicio,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Lista de Servivios Activos 
export function listaServiciosActivosFCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "servicios/activosF",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Servicios de Catálogo de Familias de Activos

export function listaFamiliasActivasServCall(token, idServicio){
	return axios({
		method: "get",
		url: endpoint + "familias/activasByServicio?idServicio=" + idServicio,
		 headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaFamiliasDeActivosBusquedaCall(token, page, busqueda) {
	return axios({
	    method: "get",
	    url: endpoint + "familias/?" + busqueda + "page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoFamiliasDeActivosCall(token, idFamilia) {
	return axios({
	    method: "get",
	    url: endpoint + "familias/activa/" + idFamilia,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearFamiliasDeActivosCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "familias/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarFamiliasDeActivosCall(token, data, idFamilia) {
	return axios({
	    method: "put",
	    url: endpoint + "familias/" + idFamilia,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// SUBFAMILIAS
// Servicios de Catálogo Subfamilias
export function listaSubfamiliasProductosCall(token) {
	return axios({
	    method: "get",
	    url: endpoint + "tiposFolio/productos/subFamilias",
		headers: {'Content-Type': 'application/json', 'Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function listaSubfamiliasCall(token) {
	return axios({
	    method: "get",
	    url: endpoint + "familias/subfamilias/activas",
		headers: {'Content-Type': 'application/json', 'Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaSubfamiliasPorServicioCall(token, idServicio) {
	return axios({
	    method: "get",
	    url: endpoint + "familias/subfamilias/servicio?idServicio=" + idServicio,
		headers: {'Content-Type': 'application/json', 'Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaSubfamiliasRutinasCall(token, idServicio) {
	return axios({
	    method: "get",
	    url: endpoint + "familias/subfamilias/rutinas/activas/by/servicio/" + idServicio,
		headers: {'Content-Type': 'application/json', 'Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaSubfamiliasBusquedaCall(token, page, busqueda) {
	return axios({
	    method: "get",
	    url: endpoint + "familias/subfamilias/?" + busqueda + "page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json', 'Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoSubfamiliasCall(token, idSubFamilia) {
	return axios({
		method: "get",
		url: endpoint + "familias/subfamilias/activa/" + idSubFamilia,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearSubfamiliasCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "familias/subfamilias",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarSubfamiliasCall(token, data, idSubFamilia) {
	return axios({
		method: "put",
		url: endpoint + "familias/subfamilias/" + idSubFamilia,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaSubfamiliasByPartidaCall(token, idPartida) {
	return axios({
		method: "get",
		url: endpoint + "familias/subfamilias/activasByPartida?idPartida=" + idPartida,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//SERVICIOS CÁTALOGO CENTRO DE COSTOS
export function listaCentroDeCostosActivosCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "centroCostos/Activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaCentroDeCostosByGerenciaCall(token, data) {
	return axios({
	    method: "post",
		url: endpoint + "centroCostos/reporte/evaluacion",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getCentroCostosPageCall(token, page) {
	return axios({
		method: "get",
		url: endpoint + "centroCostos?page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getCentroDeCostosBusquedaCall(token, page, busqueda) {
	return axios({
		method: "get",
		url: endpoint + "centroCostos?centroCosto=" + busqueda + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearCentroDeCostos(token, data) {
	return axios({
		method: "post",
		url: endpoint + "centroCostos/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarCentroDeCostosCall(token, data, idCentroCosto) {
	return axios({
		method: "put",
		url: endpoint + "centroCostos/" + idCentroCosto,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoCentrosDeCostoCall(token, idCentroCosto) {
	return axios({
		method: "get",
		url: endpoint + "centroCostos/activa/" + idCentroCosto,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// **********+ SERVICES  AREAS FUNCIONALES********* //
export function listaAreasActivosCall(token) {
	return axios({
		method: "get",
		url: endpoint + "areas/activos/areas",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function filtroCodigoAreaFuncionalClaveCall(token, busqueda, page) {
	return axios({
		method: "get",
		url: endpoint + "areas?param=" + busqueda + "&page=" + page + "&offset=10",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function obtenerAreasFuncionalesCall(token, page) {
	return axios({
		method: "get",
		url: endpoint + "areas?page=" + page + "&offset=10",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function nuevaAreasFuncionalCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "areas",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data : data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarAreasFuncionalCall(token, idAreaFuncional, data) {
	return axios({
		method: "put",
		url: endpoint + "areas/" + idAreaFuncional,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function cambiarEstadoAreasFuncionalCall(token, idAreaFuncional) {
	return axios({
		method: "get",
		url: endpoint + "areas/activar/" + idAreaFuncional,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaAreasFuncionalesActivasCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "areas/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function areasBloquearCall(token) {
	return axios({
		method: "get",
		url: endpoint + "areas/visible",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// UNIDADES FUNCIONALES
//Servicios de Catálogo de Unidades Funcionales
export function listaUnidadesActivosCall(token) {
	return axios({
		method: "get",
		url: endpoint + "areas/activos/unidades",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaUniActCompletaCall(token, busqueda) {
	return axios({
		method: "get",
		url: endpoint + "areas/unidades/activos/concat?param=" + busqueda,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaUniActPorAreaCall(token, idAreaFuncional) {
	return axios({
		method: "get",
		url: endpoint + "areas/unidades/activos?idAreaFuncional=" + idAreaFuncional,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaUnidadesFuncionalesBusquedaCall(token, busqueda, page) {
	return axios({
	    method: "get",
	    url: endpoint + "areas/unidades/?" + busqueda + "page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoUnidadesFuncionalesCall(token, idUnidadFuncional) {
	return axios({
	    method: "get",
	    url: endpoint + "areas/activar/unidades/" + idUnidadFuncional,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearUnidadesFuncionalesCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "areas/unidades",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function editarUnidadesFuncionalesCall(token, data, idUnidadFuncional) {
	return axios({
	    method: "put",
	    url: endpoint + "areas/unidades/" + idUnidadFuncional,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// ********* CATALOGOS INTERIORES **************** //
export function listaInterioresActivosCall(token) {
	return axios({
		method: "get",
		url: endpoint + "areas/activos/interiores",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function listaInterioresActivosCompletaCall(token, busqueda) {
	return axios({
		method: "get",
		url: endpoint + "areas/interiores/activos/concat?param=" + busqueda,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function listaInterioresActivosPorUnidadCall(token, idUnidadFuncional) {
	return axios({
		method: "get",
		url: endpoint + "areas/interiores/activos?idUnidadFuncional=" + idUnidadFuncional,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function filtroInteriorCall(token, busqueda, page) {
	return axios({
		method: "get",
		url: endpoint + "areas/interiores/?interiorFuncional=" + busqueda + "&page=" + page + "&offset=10",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function obtenerInteriorCall(token, page) {
	return axios({
		method: "get",
		url: endpoint + "areas/interiores/?page=" + page + "&offset=10",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function cambiarEstadoInteriorCall(token, idInteriorFuncional) {
	return axios({
		method: "get",
		url: endpoint + "areas/activar/interiores/" + idInteriorFuncional,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function nuevaInteriorCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "areas/interiores",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data : data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarInteriorCall(token, idInteriorFuncional, data) {
	return axios({
		method: "put",
		url: endpoint + "areas/interiores/" + idInteriorFuncional,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// ********* CATALOGOS SUB-INTERIORES **************** //
export function listaSubInterioresActivosCall(token) {
	return axios({
		method: "get",
		url: endpoint + "areas/activos/subinteriores",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaSubInterioresActivosPorInteriorCall(token, idInteriorFuncional) {
	return axios({
		method: "get",
		url: endpoint + "areas/subInteriores/activos?idInteriorFuncional=" + idInteriorFuncional,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function filtroSubInteriorCall(token, busqueda, page) {
	return axios({
		method: "get",
		url: endpoint + "areas/subInteriores/?subInteriorFuncional=" + busqueda + "&page=" + page + "&offset=10",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function obtenerSubInteriorCall(token, page) {
	return axios({
		method: "get",
		url: endpoint + "areas/subInteriores/?page=" + page + "&offset=10",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function cambiarEstadoSubInteriorCall(token, idSubInteriorFuncional) {
	return axios({
		method: "get",
		url: endpoint + "areas/activar/subInteriores/" + idSubInteriorFuncional,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function nuevaSubInteriorCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "areas/subInteriores",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data : data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarSubInteriorCall(token, idSubInteriorFuncional, data) {
	return axios({
		method: "put",
		url: endpoint + "areas/subInteriores/" + idSubInteriorFuncional,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//SERVICIOS CÁTALOGO CATEGORÍAS PARA FALLAS
export function listaCategoriasFallasActivosCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "fallaCategoria/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
		
export function getCategoriasFallasBusquedaCall(token, page, busqueda) {
	return axios({
		method: "get",
		url: endpoint + "fallaCategoria?numeroFallaCategoria=" + busqueda  +"&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearCategoriaFallaCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "fallaCategoria/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarCategoriaFallaCall(token, data, idFallaCategoria) {
	return axios({
		method: "put",
		url: endpoint + "fallaCategoria/" + idFallaCategoria,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportExcelTiemposRehabilitacionCall(token, page, busqueda) {
	return axios({
		method: "get",
		url: endpoint + "fallaCategoria/excel",
		headers: {'Content-Type': 'application/json','Authorization':token},
		responseType: 'blob',
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoCategoriaFallaCall(token, idFallaCategoria) {
	return axios({
		method: "get",
		url: endpoint + "fallaCategoria/activa/" + idFallaCategoria,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// ******* UBICACIONES ********* //
export function listaUbicacionesTodasBusquedaCall(token, param) {
	return axios({
		method: "get",
		url: endpoint + "areas/todas/ubicaciones?param=" + param,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaUbicacionesActivosCall(token) {
	return axios({
		method: "get",
		url: endpoint + "areas/activos/ubicaciones",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaUbicacionesFiltroCall(token, busqueda) {
	return axios({
		method: "get",
		url: endpoint + "areas/activos/ubicaciones?param=" + busqueda,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function filtroBusquedaUbicacionesCall(token, areaFuncional, unidadFuncional, interiorFuncional, page) {
	return axios({
		method: "get",
		url: endpoint + "areas/ubicaciones/?" + areaFuncional +  unidadFuncional + interiorFuncional +  "&page=" + page + "&offset=10",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	})
}

export function cambiarEstadoUbicacionCall(token, idUbicacion) {
	return axios({
		method: "get",
		url: endpoint + "areas/activar/ubicaciones/" + idUbicacion,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearUbicacionCall(token, data){
	return axios({
		method: "post",
		url: endpoint + "areas/ubicaciones/" ,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarUbicacionCall(token, idUbicacion, data) {
	return axios({
		method: "put",
		url: endpoint + "areas/ubicaciones/" + idUbicacion,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	})
}

export function exportarUbicacionesPDFCall(token, areaFuncional, unidadFuncional, interiorFuncional) {
	return axios({
		method: "get",
		responseType: 'blob',
		url: endpoint + "areas/ubicaciones/export/pdf?" + areaFuncional +  unidadFuncional + interiorFuncional,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarUbicacionesExcelCall(token, areaFuncional, unidadFuncional, interiorFuncional) {
	return axios({
		method: "get",
		responseType: 'blob',
		url: endpoint + "areas/ubicaciones/export/excel?" + areaFuncional +  unidadFuncional + interiorFuncional,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// ********* PONDERACIONES ÁREAS FUNCIONALES ********* //
export function listaPonderacionesActivosCall(token) {
	return axios({
		method: "get",
		url: endpoint + "areas/ponderacion/activos",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}


export function listaPonderacionesBusquedaCall(token, busqueda, page) {
	return axios({
		method: "get",
		url: endpoint + "areas/ponderacion?" + busqueda + "&page=" + page + "&offset=10",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearPonderacionCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "areas/ponderacion",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data : data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarPonderacionCall(token, idPonderacionArea, data) {
	return axios({
		method: "put",
		url: endpoint + "areas/ponderacion/" + idPonderacionArea,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Tipo de moneda
export function listaTipoMonedaCall(token, data) {
	return axios({
	    	method: "post",
	    	url: endpoint + "tipo/moneda/obtener",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoTipoMonedaCall(token, idTipoMoneda) {
	return axios({
	    method: "get",
	    url: endpoint + "tipo/moneda/activa/" + idTipoMoneda,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearTipoMonedaCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "tipo/moneda",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data : data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarTipoMonedaCall(token, data, idTipoMoneda ) {
	return axios({
		method: "put",
		url: endpoint + "tipo/moneda/" + idTipoMoneda,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaTiposMonedasActivosCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "tipo/moneda/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarTipoMonedaExcelCall(token) {
	return axios({
	    method: "get",
	    url: endpoint + "tipo/moneda/excel",
		headers: {'Content-Type': 'application/json','Authorization':token},
		responseType: 'blob',
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//TIPO DE CAMBIO
export function listaTipoCambioCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "tipo/moneda/tipoCambio/obtener",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function nuevoTipoCambioCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "tipo/moneda/tipoCambio",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarTipoCambioCall(token, data, idTipoCambio ) {
	return axios({
		method: "put",
		url: endpoint + "tipo/moneda/tipoCambio/editar/" + idTipoCambio,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarTipoCambioExcelCall(token, data) {
	return axios({
	   	 method: "post",
		url: endpoint + "tipo/moneda/tipoCambio/excel",
		responseType: 'blob',
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}