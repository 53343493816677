import React, { Component } from 'react';
import { Modal } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import EditarEstandarDeDesempeño from './EditarEstandarDeDesempeño';
import VerificarContraseña from './VerificarContraseña';
import ArchivoEstandarDeDesempeño from './ArchivoEstandarDeDesempeño';
import DatosEditados from './DatosEditados';

class ModalEditarEstandarDeDesempeño extends Component {
    formRef = React.createRef();
    render() {
        const { 
               showModalEditarEstandaresDeDesempeño, 
               onShowModalEditarEstandaresDeDesempeño, 
               vistaModalEstandaresDeDesempeño, 
               onCambiarVista, 
               onLimpiarFormulario,
        } = this.props;

        const handleCancel = () => {
                onShowModalEditarEstandaresDeDesempeño();
                onLimpiarFormulario('')
                onCambiarVista(1);
                this.setState({
                    claseReferenciaSeleccionado: ''
                });
        }
        function ContenidoEditar() {
            if(vistaModalEstandaresDeDesempeño === 1){
                return(
                    <EditarEstandarDeDesempeño/>
                );
            }
            else if(vistaModalEstandaresDeDesempeño === 2){
                return(
                    <VerificarContraseña/>
                );
            }
            else if(vistaModalEstandaresDeDesempeño === 3){
                return(
                   <DatosEditados/>
                );
            }
            else if(vistaModalEstandaresDeDesempeño === 4){
                return(
                    <ArchivoEstandarDeDesempeño/>
                );
            }
        };
        
        return (
            <Modal
            visible={showModalEditarEstandaresDeDesempeño}
            title='Editar Estándar'
            onCancel={handleCancel}
            footer={false}
            width='80%'
            >
                <ContenidoEditar/>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
          showModalEditarEstandaresDeDesempeño: state.MecanismosDePagosReducer.showModalEditarEstandaresDeDesempeño,
          vistaModalEstandaresDeDesempeño: state.MecanismosDePagosReducer.vistaModalEstandaresDeDesempeño,
	};
};

const mapDispatchToProps = dispatch => {
	return {
            onShowModalEditarEstandaresDeDesempeño: () => {
                dispatch({ type: 'SHOW_MODAL_EDITAR_ESTANDAR_DESEMPEÑO', showModalEditarFallasDeDisponibilidad: false, page: null })
            },
            onLimpiarFormulario: (limpiarFormulario) => {
                dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: limpiarFormulario });
            },
            onCambiarVista: (vistaModalEstandaresDeDesempeño) => {
                dispatch({ type: 'CAMBIAR_VISTA_ESTANDAR_DESEMPEÑO', vistaModalEstandaresDeDesempeño: vistaModalEstandaresDeDesempeño, formularioEstandaresDeDesempeño: null, EstandaresDeDesempeñoEditados: null})
            },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarEstandarDeDesempeño);