import React from 'react';
import {connect} from 'react-redux';
import {  Button, Row, Col, Table, Modal, Radio, Tooltip} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import moment from 'moment';
import { FilePdfFilled, FileExcelFilled } from '@ant-design/icons';
class TableSection extends React.Component {
    state = {
        agrupado: 'SinAgrupar',
        tipoAgrupado: '',
        orden: {
            field: 'ser.Nombre',
            order: 'ASC'
        }
    }
    render() {
        const {fetchingReporteDinamicoES, listaReporteDinamicoES, listaReporteDinamicoESCantidad, fetchingReporteDinamicoExcel,
            formularioReporte, handlers, data, onDescargarReportePDF, onDescargarReporteExcel, fetchingReporteDinamicoPDF,
            listaReporteDinamicoAgrupadoES, fetchingReporteDinamicoAgrupadoES, listaReporteDinamicoAgrupadoESCantidad, 
            onGetReporteDinamico, onGetReporteDinamicoAgrupado,
        } = this.props;
        
        const {checkbox, checkArray, state, order} = data;
        const {handleTableChange, handleSteps, handleOrden} = handlers;
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        
        const onHandleTableChange = (pagination, filter, orden) => {
            handleTableChange(pagination, filter, orden);
        }

        const onHandleSteps = (value) => {
            handleSteps(value);
        }
        
        const descargarReporte = value => {
            console.log(`value`, value)
            const agrupado = this.state.agrupado;
            if (value === 'pdf') return onDescargarReportePDF(formularioReporte, checkbox, order, moment().format('YYYY'), agrupado, permisosUsuario.especialGestiónDeCalidad);
            onDescargarReporteExcel(formularioReporte, checkbox, order, moment().format('YYYY'), agrupado, permisosUsuario.especialGestiónDeCalidad);
        }

        
        const columns = [
            {title: 'Folio', dataIndex: 'folio', key: 'folioC', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
            {title: 'Gerencia', dataIndex: 'gerencia', key: 'gerenciaC'}, 
            {title: 'Grupo de Servicios', dataIndex: 'grupoServicio', key: 'grupoC'},
            {title: 'Servicio', dataIndex: 'nombreServicio', key: 'servicioC', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true}, 
            {title: 'Mes', dataIndex: 'mes', key: 'mesC'},
            {title: 'Año', dataIndex: 'anio', key: 'mesC'},
            {title: 'Calificación', dataIndex: 'calificacion', key: 'calificacionC', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
            {title: 'Comentario', dataIndex: 'comentario', key: 'comentarioC'},
            {title: 'Estado', dataIndex: 'estado', key: 'estadoC', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
        ];
        
        const columnsSelected = columns.filter( field => checkArray.includes(field.key));
        const scroll = columnsSelected.length > 4 ? {x: columnsSelected.length * 200} : {x: 700}
        columnsSelected.push({title: 'Estado', dataIndex: 'estado', key: 'estadoC', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true})

        const onAgrupar = (value) => {
            const tipoAgrupado = value.target.value === 'Por servicio' ? 'Servicio' : value.target.value === 'Por grupo servicio' ? 'Grupo de Servicio' : value.target.value === 'Por gerencia' ? 'Gerencia' : value.target.value === 'Por estado' ? 'Estado' : value.target.value === 'Por grupo servicio' ? 'Grupo de Servicio' : 'Mes'
            this.setState({
                agrupado: value.target.value,
                tipoAgrupado
            });
            formularioReporte.tipoAgrupado = value.target.value;
            if(value.target.value !== 'SinAgrupar'){
                onGetReporteDinamicoAgrupado(formularioReporte, 1, checkbox, order, moment().format('YYYY'), permisosUsuario.especialGestiónDeCalidad)
            }
            else{
                onGetReporteDinamico(formularioReporte, 1, checkbox, order, moment().format('YYYY'), permisosUsuario.especialGestiónDeCalidad)
            }
        }


        const columnsAgrupamiento = [
            { title: this.state.tipoAgrupado, dataIndex: 'nombre', key: 'nombre' },
        ];

        const onChangeAgrupado = (record, pagination, filter, orden) => {
            console.log(`record, e`, record, pagination, filter, orden);
            const orderFields = {servicioC:'ser.Nombre', folioC:'ei.FolioEncuesta', calificacionC:'ctf.Calificacion', estadoC: 'ei.Estado'}

            record.listaReporteDinamicoAgrupadoES = listaReporteDinamicoAgrupadoES;
            record.listaReporteDinamicoAgrupadoESCantidad = listaReporteDinamicoAgrupadoESCantidad;
            console.log('record.listaReporteDinamicoAgrupadoES', record.listaReporteDinamicoAgrupadoES)
            if(orden) {
                const field = orden.columnKey ? orderFields[orden.columnKey] : 'ser.Nombre';
                const orderField = orden.order === 'descend' ? 'DESC': 'ASC';
                const sorter = {field: field, order: orderField}
                this.setState({orden: sorter})
                console.log(`sorter`, sorter)
                return onGetReporteDinamico(formularioReporte, pagination.current, checkbox, sorter, moment().format('YYYY'), record, permisosUsuario.especialGestiónDeCalidad );
            }
            onGetReporteDinamico(formularioReporte, pagination.current, checkbox, this.state.orden, moment().format('YYYY'), record, permisosUsuario.especialGestiónDeCalidad);
        }
        
        const ordenamientoGeneral = (value, tipo) => {
            console.log(`value`, value)
            if(tipo === 'field'){
                handleOrden(value.target.value, order.order)
            }
            else{
                handleOrden(order.field, value.target.value)
            }
        }
        console.log(`checkbox`, checkbox)
        return (
            <>
                <Row justify='space-between'>
                    <Col span={3}>
                        <Row justify='space-around'>
                            <Col span={11}>
                                {/* <Button className="btnCargaArchivos" disabled={!permisosUsuario.exportarReporteDinámicoParaEncuestasDeSatisfacciónPdf ? true : !listaReporteDinamicoES.length>0 || fetchingReporteDinamicoPDF} loading={fetchingReporteDinamicoPDF}
                                onClick={()=> descargarReporte('pdf')} style={{ width: '100%', marginTop: '20px' }}
                                >
                                    Exportar Reporte PDF
                                </Button> */}
                                <Tooltip title='Exportar archivo .pdf'>
                                    <Button className="iconSecundary" shape='circle' icon={<FilePdfFilled /> } disabled={!permisosUsuario.exportarReporteDinámicoParaEncuestasDeSatisfacciónPdf ? true : !listaReporteDinamicoES.length>0 || fetchingReporteDinamicoPDF} loading={fetchingReporteDinamicoPDF} onClick={()=> descargarReporte('pdf')} style={{marginTop: '20px' }}/>
                                </Tooltip>
                            </Col>

                            <Col span={11}>
                                {/* <Button className="btnCargaArchivos" disabled={!permisosUsuario.exportarReporteDinámicoParaEncuestasDeSatisfacciónExcel ? true : !listaReporteDinamicoES.length>0 || fetchingReporteDinamicoExcel} loading={fetchingReporteDinamicoExcel}
                                onClick={()=> descargarReporte('excel')} style={{ width: '100%', marginTop: '20px' }}
                                >
                                    Exportar Reporte Excel    
                                </Button> */}
                                <Tooltip title='Exportar archivo .xls'>
                                    <Button className="iconSecundary" shape='circle' icon={<FileExcelFilled /> } disabled={!permisosUsuario.exportarReporteDinámicoParaEncuestasDeSatisfacciónExcel ? true : !listaReporteDinamicoES.length>0 || fetchingReporteDinamicoExcel} loading={fetchingReporteDinamicoExcel} onClick={()=> descargarReporte('excel')} style={{marginTop: '20px' }}/>
                                </Tooltip>
                                
                            </Col>
                        </Row>
                    </Col>

                    <Col span={10}>
                        <br/>
                        <Radio.Group defaultValue='SinAgrupar' onChange={onAgrupar}  size="small">
                            {checkbox.gerenciaC ? (
                            <Radio.Button value="Por gerencia">Gerencia</Radio.Button>
                            ):false}
                            {checkbox.grupoC ? (
                            <Radio.Button value="Por grupo servicio">Grupo Servicios</Radio.Button>
                            ):false}
                            {checkbox.servicioC ? (
                            <Radio.Button value="Por servicio">Servicio</Radio.Button>
                            ):false}
                            {checkbox.mesC ? (
                            <Radio.Button value="Por mes">Mes</Radio.Button>
                            ):false}
                            <Radio.Button value="Por estado">Estado</Radio.Button>
                            <Radio.Button value='SinAgrupar'>Sin Agrupamiento</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Row style={{ paddingTop: '20px' }}>
                            <Col span={24}>
                                {this.state.agrupado !== 'SinAgrupar' ? (
                                <Table
                                    title={() => 
                                        <Row justify='space-around'>
                                            <Col span={17}>
                                                <Radio.Group defaultValue={order.field} onChange={(e) => ordenamientoGeneral(e, 'field')}  size="small">
                                                    {checkbox.folioC ? (
                                                    <Radio.Button value="ei.idFolioEncuesta">Folio</Radio.Button>
                                                    ):false}
                                                    {checkbox.servicioC ? (
                                                    <Radio.Button value="ser.Nombre">Servicio</Radio.Button>
                                                    ):false}
                                                    {checkbox.calificacionC ? (
                                                    <Radio.Button value="ctf.Calificacion">Calificación</Radio.Button>
                                                    ):false}
                                                    <Radio.Button value="ei.Estado">Estado</Radio.Button>
                                                </Radio.Group>
                                            </Col>
                                            <Col span={6}>
                                                <Radio.Group defaultValue={order.order} onChange={(e) => ordenamientoGeneral(e, 'orden')} size="small">
                                                    <Radio.Button value="ASC">Ascendente</Radio.Button>
                                                    <Radio.Button value="DESC">Descendente</Radio.Button>
                                                </Radio.Group>
                                            </Col>
                                        </Row>
                                    }
                                    size='small'
                                    rowKey='id'
                                    columns={columnsAgrupamiento}
                                    dataSource={listaReporteDinamicoAgrupadoES}
                                    expandable={{expandedRowRender: record => <Table rowKey="id" columns={columnsSelected} loading={fetchingReporteDinamicoES} onChange={(e,u,i) => onChangeAgrupado(record, e, u, i)} dataSource={record.agrupados.data} pagination={{ total: record.agrupados.cantidad, simple: true, current: record.agrupados.page }} />} }
                                    loading={fetchingReporteDinamicoAgrupadoES}
                                    locale={{ emptyText: 'Sin datos' }}
                                    onChange={onHandleTableChange}
                                    scroll={scroll}
                                    pagination={{ total: listaReporteDinamicoAgrupadoESCantidad, simple: true, current: state.page }}
                                />
                                ):
                                (
                                <Table
                                    size='small'
                                    rowKey='id'
                                    columns={columnsSelected}
                                    dataSource={listaReporteDinamicoES}
                                    loading={fetchingReporteDinamicoES}
                                    locale={{ emptyText: 'Sin datos' }}
                                    onChange={onHandleTableChange}
                                    scroll={scroll}
                                    pagination={{ total: listaReporteDinamicoESCantidad, simple: true, current: state.page }}
                                />
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify='start'>
                    <Col span={6}>
                        <Button className="btnFiltrar" onClick={() => onHandleSteps('atras')} style={{ width: '100%', marginTop: '20px' }}>Anterior</Button>
                    </Col>
                </Row>
            </>
        )
    }
}


const mapStateToProps = state => {
    return {
        fetchingReporteDinamicoES: state.GestionDeCalidadReducer.fetchingReporteDinamicoES,
        listaReporteDinamicoES: state.GestionDeCalidadReducer.listaReporteDinamicoES,
        listaReporteDinamicoESCantidad: state.GestionDeCalidadReducer.listaReporteDinamicoESCantidad,
        fetchingReporteDinamicoAgrupadoES: state.GestionDeCalidadReducer.fetchingReporteDinamicoAgrupadoES,
        listaReporteDinamicoAgrupadoES: state.GestionDeCalidadReducer.listaReporteDinamicoAgrupadoES,
        listaReporteDinamicoAgrupadoESCantidad: state.GestionDeCalidadReducer.listaReporteDinamicoAgrupadoESCantidad,
        formularioReporte: state.GestionDeCalidadReducer.formularioReporte,
        fetchingReporteDinamicoPDF: state.GestionDeCalidadReducer.fetchingReporteDinamicoPDF,
        fetchingReporteDinamicoExcel: state.GestionDeCalidadReducer.fetchingReporteDinamicoExcel,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onDescargarReportePDF: (formulario, checkbox, order, año, agrupado, especial) => {
            dispatch({ type: 'PDF_REPORTE_DINAMICO_ES_REQUEST', formulario, checkbox, order, año, agrupado, especial});
        },
        onDescargarReporteExcel: (formulario, checkbox, order, año, agrupado, especial) => {
            dispatch({ type: 'EXCEL_REPORTE_DINAMICO_ES_REQUEST', formulario, checkbox, order, año, agrupado, especial});
        },
        onGetReporteDinamico: (formulario, page, checkboxData, order, año, agrupado, especial) => {
            dispatch({ type: 'REPORTE_DINAMICO_ES_REQUEST', formulario, page, checkboxData, order, año, agrupado, especial });
        },
        onGetReporteDinamicoAgrupado: (formulario, page, checkboxData, order, año, especial) => {
            dispatch({ type: 'REPORTE_DINAMICO_AGRUPADO_ES_REQUEST', formulario, page, checkboxData, order, año, especial });
        },
    }
}

export default connect (mapStateToProps, mapDispatchToProps) (TableSection);