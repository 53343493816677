import React, { Component } from 'react';
import { Row, Form, Input, Button, Modal, Col, Spin, Switch, Select, message, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Option } = Select;
class ModalEditarFalla extends Component {
    formRef = React.createRef();
    render() {
        const { fetchingEditarFalla, showModalEditarFalla, onEditarFalla, onShowModalEditarFalla,
            limpiarFormulario, onLimpiarFormulario, onListaServiciosFiltro, onListaServiciosFiltroLimpiar, 
            onListaEstandarFiltro, onListaEstandarFiltroLimpiar, fetchingListaTiposFallasActivas, listaTiposFallasActivas,
            fetchingListaServiciosFiltro, listaServiciosFiltro, fetchingListaEstandaresFiltro, listaEstandaresFiltro,
            fallaSeleccionada, tipo, idServicio, onObtenerPartidasByidServicio, listaPartidasByServicio4Fallas, fetchingListaPartidasByServicio4Fallas, onLimpiarPartidas
        } = this.props;

        const onFinish = formulario => {
            console.log('formulario', formulario);
            onEditarFalla(formulario, this.props.page, this.props.busqueda, listaTiposFallasActivas, fallaSeleccionada);
        };

        if (limpiarFormulario && limpiarFormulario === 'editarFalla') {
            onLimpiarFormulario();
            onListaEstandarFiltroLimpiar();
            onListaServiciosFiltroLimpiar();
            onLimpiarPartidas();
        }

        const handleCancel = () => {
            onShowModalEditarFalla(false, null, this.props.page, this.props.busqueda, null);
            this.formRef.current.resetFields();
            onLimpiarPartidas();
            onListaServiciosFiltroLimpiar();
        }

        const buscarServicios = value => {
            if (value.length >= 3) {
                if (value) {
                    console.log(value);
                    onListaServiciosFiltro(value)
                } else {
                    message.warning('No se encontró servicio con esas palabras')
                }
            } else {
                onListaServiciosFiltroLimpiar();
            }
        }
        const buscarEstandar = value => {
            if (value.length >= 3) {
                console.log(value);
                onListaEstandarFiltro(value, tipo, idServicio)
            } else {
                onListaEstandarFiltroLimpiar();
            }
        }

        const onCategoriaFalla = value => {
            onListaEstandarFiltroLimpiar();

            if(value){
                onShowModalEditarFalla(true, fallaSeleccionada, this.props.page, this.props.busqueda, value.split(' (')[1].split(')')[0], idServicio, listaPartidasByServicio4Fallas);
                    
                this.formRef.current.setFieldsValue({'estandarDesempenoEdit': undefined});
            }
        };

        const servicioSeleccionado = value => {
            onListaEstandarFiltroLimpiar();

            let servSeleccionado = []
            for(let i in listaServiciosFiltro){
                if(listaServiciosFiltro[i].idServicio === parseInt(value)){
                    servSeleccionado = listaServiciosFiltro[i];
                }
            }
            
            if(value){
                if(servSeleccionado.proveedorByServicio){
                    onObtenerPartidasByidServicio(value);
                    onShowModalEditarFalla(true, fallaSeleccionada, this.props.page, this.props.busqueda, tipo, value.split(' - ')[0], listaPartidasByServicio4Fallas);
                    this.formRef.current.setFieldsValue({'estandarDesempenoEdit': undefined, 'partidasEdit': undefined});
                } else {
                    message.warning('Es necesario asignar el servicio a un proveedor');
                    onObtenerPartidasByidServicio(value);
                    onShowModalEditarFalla(true, fallaSeleccionada, this.props.page, this.props.busqueda, tipo, value.split(' - ')[0], listaPartidasByServicio4Fallas);
                    this.formRef.current.setFieldsValue({'estandarDesempenoEdit': undefined, 'partidasEdit': undefined});
                }
            }
            else{
                this.setState({
                    servicioOk: false,
                })
            }
        };

        return (
            <Modal
                visible={showModalEditarFalla}
                title='Editar Falla'
                onCancel={handleCancel}
                footer={false}
                width='60%'
            >
            {fallaSeleccionada ?(
                <Spin spinning={fetchingEditarFalla}>
                    <Form {...layout}
                        name="formulario"
                        onFinish={onFinish}
                        ref={this.formRef}
                        initialValues={fallaSeleccionada}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Nombre falla"
                                    name="nombre"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={250} placeholder="Ingresa el nombre de falla"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Ingresa el servicio a buscar"
                                    name="servicioEdit"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Select
                                        style={{ width: '100%', textAlign: 'left' }}
                                        placeholder="Ingresa el servicio a buscar"
                                        loading={fetchingListaServiciosFiltro}
                                        showSearch
                                        filterOption={false}
                                        onSearch={buscarServicios}
                                        onChange={servicioSeleccionado}
                                        notFoundContent={null}
                                    >
                                        {listaServiciosFiltro.map(option =>
                                        <Option key={option.idServicio + ' - ' + option.nomenclatura + ' (' + option.nombre + ')'}> 
                                            <Tooltip placement="topLeft" title={option.idServicio + ' - ' + option.nomenclatura + ' (' + option.nombre + ')'}>
                                                {option.idServicio + ' - ' + option.nomenclatura + ' (' + option.nombre + ')'}
                                            </Tooltip>
                                        </Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            { listaPartidasByServicio4Fallas ? (<>
                            { listaPartidasByServicio4Fallas[0] ? (<Col span={11}>
                                <Form.Item
                                    label="Selecciona una o varias partidas"
                                    name="partidasEdit"
                                    rules={[{ required: (listaPartidasByServicio4Fallas[0]), message: 'Favor de llenar el campo' }]}
                                >
                                    <Select
                                        showSearch={true}
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona una o varias partidas"
                                        loading={fetchingListaPartidasByServicio4Fallas}
                                    >
                                        {listaPartidasByServicio4Fallas.map(option =>
                                            <Option key={option.idPartida + ' - ' + option.nomenclatura  + ' (' + option.nombre + ')'}>
                                                {option.idPartida + ' - ' + option.nomenclatura  + ' (' + option.nombre + ')'}
                                            </Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>):false }
                            </>):false }
                            <Col span={11}>
                                <Form.Item
                                    label="Selecciona el tipo de falla"
                                    name="tipoFallaEdit"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Select
                                        style={{ width: '100%', textAlign: 'left' }}
                                        placeholder="Selecciona el tipo de falla"
                                        loading={fetchingListaTiposFallasActivas}
                                        notFoundContent={null}
                                        onChange={onCategoriaFalla}
                                        showSearch
                                    >
                                        {listaTiposFallasActivas.map(option => 
                                        <Option key={option.clave + ' - ' + option.nombre  + ' (' + option.tipo + ')'}>
                                            <Tooltip placement="topLeft" title={option.clave + ' - ' + option.nombre  + ' (' + option.tipo + ')'}>
                                                {option.clave + ' - ' + option.nombre  + ' (' + option.tipo + ')'}
                                            </Tooltip>
                                        </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Ingresa el estándar de desempeño a buscar"
                                    name="estandarDesempenoEdit"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Select
                                        style={{ width: '100%', textAlign: 'left' }}
                                        placeholder="Ingresa el estándar de desempeño a buscar"
                                        loading={fetchingListaEstandaresFiltro}
                                        showSearch
                                        filterOption={false}
                                        onSearch={buscarEstandar}
                                        notFoundContent={null}
                                    >
                                        {listaEstandaresFiltro.map(option => 
                                        <Option key={option.idEstandarDesempeno + ' - ' + option.referencia + ' (' + option.descripcion + ')'}>
                                            <Tooltip placement="topLeft" title={option.idEstandarDesempeno + ' - ' + option.referencia + ' (' + option.descripcion + ')'}>
                                                {option.idEstandarDesempeno + ' - ' + option.referencia + ' (' + option.descripcion + ')'}
                                            </Tooltip>
                                        </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                label='Conversión'
                                name='conversion'
                                valuePropName="checked"
                                >
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                    />                                            
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                label='Visible'
                                name='visible'
                                valuePropName="checked"
                                >
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                    />                                            
                                </Form.Item>
                            </Col>
                           
                            <Col span={11} className='marginBoton'>
                                <br />
                                <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Editar Falla</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            ):false}
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingEditarFalla: state.SolicitudDeServiciosReducer.fetchingEditarFalla,
        showModalEditarFalla: state.SolicitudDeServiciosReducer.showModalEditarFalla,
        page: state.SolicitudDeServiciosReducer.page,
        busqueda: state.SolicitudDeServiciosReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaTiposFallasActivas: state.SolicitudDeServiciosReducer.fetchingListaTiposFallasActivas,
        listaTiposFallasActivas: state.SolicitudDeServiciosReducer.listaTiposFallasActivas,
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
        fetchingListaEstandaresFiltro: state.MecanismosDePagosReducer.fetchingListaEstandaresFiltro,
        listaEstandaresFiltro: state.MecanismosDePagosReducer.listaEstandaresFiltro,
        fallaSeleccionada: state.SolicitudDeServiciosReducer.fallaSeleccionada,
        tipo: state.SolicitudDeServiciosReducer.tipo,
        idServicio: state.SolicitudDeServiciosReducer.idServicio,
        listaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.listaPartidasByServicio4Fallas,
        fetchingListaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.fetchingListaPartidasByServicio4Fallas,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarFalla: (showModalEditarFalla, fallaSeleccionada, page, busqueda, tipo, idServicio, listaPartidasByServicio4Fallas) => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_FALLA', showModalEditarFalla, fallaSeleccionada, page, busqueda, tipo, idServicio, listaPartidasByServicio4Fallas })
        },
        onEditarFalla: (formulario, page, busqueda, listaTiposFallasActivas, fallaSeleccionada, ) => {
            dispatch({ type: "EDITAR_FALLA_REQUEST", formulario, page, busqueda, listaTiposFallasActivas, fallaSeleccionada });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
        },
        onListaEstandarFiltro: (param, tipo, idServicio) => {
            dispatch({ type: "LISTA_ESTANDARES_FILTRO_REQUEST", param, tipo, idServicio})
        },
        onListaEstandarFiltroLimpiar: () => {
            dispatch({ type: "LISTA_ESTANDARES_FILTRO_SUCCESS", listaEstandaresFiltro: []})
        },
        onObtenerPartidasByidServicio: (idsServicios) => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_REQUEST', idsServicios });
        },
        onLimpiarPartidas: () => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_SUCCESS', listaPartidasByServicio4Fallas: []  });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarFalla);