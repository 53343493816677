import React, { Component } from 'react';
import {Button,Col,Row,Table, message, Divider, Tooltip} from 'antd';
import { EditOutlined, ArrowLeftOutlined, SaveOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalModificarRegistroEditar from './ModalModificarRegistroEditar';


class ListaProductosSelecionadosEditar extends Component {
    state = {
        page: 1,
    }
    render() { 

        const {listaSeleccionadosEditar, listaDetalle, almacenDestino, almacenOrigen, onGetUbicacionDestino, onGetUbicacionesOrigen,
            onBorrarUbicacionesDestino, onCambiarVista, onModalEditar, onAgregarProductos, busqueda
        } = this.props;
        
        const onEditar = key => { // mandar onGetUbicaciones solo para ambas y suma
            console.log('key:', key)
            key.listaSerieNum = key.listaSerieNum ?  key.listaSerieNum : [];
             
            if(almacenDestino.length > 0){
                key.ambas = true;
                onGetUbicacionDestino(1, almacenDestino[0].idAlmacen);
            }
            onModalEditar(key, this.state.page) 
        };

        const guardarProductos = () => {
            console.log('listaSeleccionadosEditar', listaSeleccionadosEditar);
            const folio =  listaDetalle[0].folio;
            let valido = true;
            for (let i = 0; i < listaSeleccionadosEditar.length; i++) {
                if(!listaSeleccionadosEditar[i].cantidad){
                    message.warning('Debe ingresar cantidad del producto con sku "'+ listaSeleccionadosEditar[i].sku + '" antes de guardar folio') 
                    i = listaSeleccionadosEditar.length
                    valido = false;
                } else if(!listaSeleccionadosEditar[i].idUbicacionProductoDestino && listaDetalle[0].tiposFoliosByIdTipoFolio.operacion !== "Resta"){
                    message.warning('Debe ingresar ubicacion destino del producto con código almacén "'+ listaSeleccionadosEditar[i].codigoAdicional + '" antes de guardar folio') 
                    i = listaSeleccionadosEditar.length
                    valido = false;
                } else if(typeof listaSeleccionadosEditar[i].productoByIdProducto !== 'undefined'){
                    if(listaSeleccionadosEditar[i].productoByIdProducto.caracteristicaLote){
                        if(!listaSeleccionadosEditar[i].lote){
                            message.warning('Debe ingresar lote del producto con sku "'+ listaSeleccionadosEditar[i].sku + '" antes de guardar folio')
                            i = listaSeleccionadosEditar.length
                            valido = false; 
                        }
                    }
                }
            }
            if(valido){
                onAgregarProductos(folio, listaSeleccionadosEditar, this.state.page);
                onBorrarUbicacionesDestino()
                onCambiarVista(1);
            }
        }

        const pasoAnterior = () => {
            if(listaDetalle[0].tiposFoliosByIdTipoFolio.operacion === "Suma"){
                onBorrarUbicacionesDestino();
            }
            else if(listaDetalle[0].tiposFoliosByIdTipoFolio.operacion === "Resta"){
                if(listaDetalle[0].nombreTipo === 'Salida por instalación'){
                    console.log('busqueda', busqueda)
                    this.props.onGetInventarioTipoActivos(1, almacenOrigen[0].idAlmacen, busqueda.busqueda, busqueda.idSubFamilia)
                }
                else{
                    onGetUbicacionesOrigen(1, almacenOrigen[0].idAlmacen);
                }
            } else if(almacenDestino[0]!== undefined && almacenOrigen[0]!== undefined ){
                //onGetUbicacionDestino(1, almacenDestino[0].idAlmacen);
                onBorrarUbicacionesDestino();
                onGetUbicacionesOrigen(1, almacenOrigen[0].idAlmacen);
            }
            onCambiarVista(2, null, this.props.tipoActivos);
        }
 

        const columns = [{
            title: 'Código de almacén',
            dataIndex: 'codigoAdicional',
            key: 'codigoAdicional',
            align: 'center'
        },{
            title: 'Nombre del producto',
            dataIndex: 'nombre',
            key: 'nombre',
            align: 'center'
        }, {
            title: 'Clave del producto',
            dataIndex: 'clave',
            key: 'clave',
            align: 'center'
        }, {
            title: 'Número de serie',  dataIndex: 'serieNum', key: 'serieNum', align: 'center', render: (text, record) => (
                record.serieNum ? text : 'No aplica'
            ), 
        },{
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
            align: 'center'
        },{
            title: 'Costo unitario',
            dataIndex: 'precioTabla',
            key: 'precioTabla',
            align: 'right'
        },{
            title: 'Lote',
            dataIndex: 'lote',
            key: 'lote',
            align: 'center'
        },  {
            title: 'Cantidad',
            dataIndex: 'cantidad',
            key: 'cantidad',
            align: 'right',
        },{
            title: ' Modificar ',
            key: 'editar',
            align: 'center',
            render: (text, record) => (
                <a className='editarIcono' disabled={listaDetalle[0]?.tiposFoliosByIdTipoFolio.operacion === "Resta" ? (record.subFamiliaByIdSubFamilia.familiaByIdFamilia.tipoFamilia === 'Activos' ? true : false) : false } onClick={() => onEditar(record)}><EditOutlined /></a>
            ),
        }];

        return ( 
            <div>
                <Row justify="space-around">
                    <Col span={20}>
                        <Divider orientation="left">Agregar Productos</Divider>
                    </Col>

                    <Col span={1} style={{ paddingTop:'1em' }}>
                        <Tooltip title='Paso Anterior'>
                            <Button className="iconTer" shape="circle" onClick={pasoAnterior} icon={<ArrowLeftOutlined />}/>
                        </Tooltip>
                    </Col>

                    <Col span={1} style={{ paddingTop:'1em' }}>
                        <Tooltip title='Guardar Productos'>
                            <Button className="iconTer" shape="circle" onClick={guardarProductos} icon={<SaveOutlined />}/>
                        </Tooltip>
                    </Col>

                    <Col span={24}>
                        <br />
                        <Table
                            size="small"
                            rowKey="idProducto"
                            columns={columns}
                            dataSource={listaSeleccionadosEditar}
                            locale={{ emptyText: 'Sin datos' }}
                            pagination={false}
                            scroll={{"x": 1000 | true, "y": 1000}}
                        />
                      </Col>  
                     
                    {/* <Col span={11}>
                        <Button onClick={pasoAnterior} className='nuevoUsuario'>Anterior Paso</Button>
                    </Col>
                    <Col span={11}>
                        <Button onClick={guardarProductos} className='nuevoUsuario'>Guardar Productos</Button>
                    </Col> */}
                </Row> 
                <ModalModificarRegistroEditar/>
            </div>
         );
    }
}
 
const mapStateToProps = state => {
	return {
        listaSeleccionadosEditar: state.AlmacenesReducer.listaSeleccionadosEditar,
        listaDetalle: state.AlmacenesReducer.listaDetalle,
        page: state.AlmacenesReducer.page,
        busqueda: state.AlmacenesReducer.busqueda,
        almacenDestino: state.AlmacenesReducer.almacenDestino,
        almacenOrigen: state.AlmacenesReducer.almacenOrigen,
        tipoActivos: state.AlmacenesReducer.tipoActivos,
    };
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalModificar: (registroProductoSeleccionado) => {
            dispatch({ type: "SHOW_MODAL_MODIFICAR_EDITARFOLIO", showModalModificarEditarFolio: true, registroProductoSeleccionado: registroProductoSeleccionado})
        },
        onCambiarVista: (vistaModalEditarFolio, selectedRows, tipoActivos) => {
            dispatch({ type: "CAMBIAR_VISTA_MODAL_EDITARFOLIO", vistaModalEditarFolio: vistaModalEditarFolio, listaSeleccionadosEditar: selectedRows, tipoActivos});
        }, 
        onModalEditar: (registroProductoSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_MODIFICAR_EDITARFOLIO", showModalModificarEditarFolio: true, registroProductoSeleccionado:registroProductoSeleccionado, page: page})
        },
        onAgregarProductos: (folio, listaSeleccionadosEditar, page) => {
            dispatch({ type: "NUEVO_AGREGAR_PRODUCTOS_REQUEST", folio: folio, listaSeleccionadosEditar: listaSeleccionadosEditar, page});
        },
        onBorrarUbicacionesDestino: () => {
            dispatch({ type: "UBICACION_DESTINO_POR_ALMACEN_SUCCESS", listaUbicacionesDestino: null})
        },
        onGetUbicacionesOrigen: (page, idAlmacen) => {
            dispatch({ type: "OBTENER_UBICACION_POR_ALMACEN_REQUEST", page: page, idAlmacen: idAlmacen})
        },
        onGetUbicacionDestino: (page, idAlmacen) => {
            dispatch({ type: "UBICACION_DESTINO_POR_ALMACEN_REQUEST", page: page, idAlmacen: idAlmacen})
        },
        onGetInventarioTipoActivos: (page, idAlmacenOrigen, busqueda, idSubFamilia) => {
            dispatch({ type: 'LISTA_INVENTARIO_TIPO_ACTIVOS_REQUEST', page, idAlmacenOrigen, busqueda, idSubFamilia })
        },
	};
};
 
export default connect(mapStateToProps, mapDispatchToProps ) (ListaProductosSelecionadosEditar);