import React, { Component } from 'react';
import { connect } from "react-redux";
import { Form, Input, Button, Modal, Col, Spin, Row, InputNumber, message, Select, Popover } from 'antd';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import L from 'leaflet';
import { Map, ImageOverlay, FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const {Option} = Select;
class ModalEditarSubInterior extends Component {
    formRef = React.createRef();
    
    state = {
        fileImage: false,
        botonImage: false,
        botonEditar: false,
        fetchingImagen: true,
        imageUrl: null,
        height: 0,
        width: 0,
        geometry: [],
    }

    render() { 
        const {
            onEditarSubInterior,
            onShowModalEditarSubInterior,
            showModalEditarSubInterior,
            fetchingEditarSubInterior,
            subInteriorSeleccionado,
            page, 
            busqueda,
            fetchingListaInteriorActivosCompleta, 
            limpiarFormulario, 
            listaInteriorActivosCompleta, 
            onRequestInterioresActivosCompleta,
            onRequestInterioresActivosCompletaLimpiar,
        } = this.props;

        if (limpiarFormulario && limpiarFormulario==='editarSubInterior') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
            onRequestInterioresActivosCompletaLimpiar();
            this.setState({
                fileImage: false,
                botonImage: false,
                botonEditar: false,
                fetchingImagen: false,
                imageUrl: null,
                height: 0,
                width: 0,
                geometry: [],
            });
        }
        if(!this.state.imageUrl && subInteriorSeleccionado){
            const rutaImagen = subInteriorSeleccionado.interiorFuncionalByIdInteriorFuncional.unidadFuncionalByIdUnidadFuncional.rutaImagen;
            const img = new Image();
            const updateState = (width, height) => {
                this.setState({
                    imageUrl: rutaImagen,
                    width, 
                    height,
                    geometry: null,
                    fetchingImagen: false,
                });
            }
            img.onload = function() {
                console.log(this.width + 'x' + this.height);
                updateState(this.width, this.height);
            }
            img.src = subInteriorSeleccionado.interiorFuncionalByIdInteriorFuncional.unidadFuncionalByIdUnidadFuncional.rutaImagen;
        }
        if(subInteriorSeleccionado && !this.state.geometry){
            if(subInteriorSeleccionado.coordenadas){
                this.setState({
                    geometry: subInteriorSeleccionado.coordenadas
                }) 
            }
            else{
                this.setState({
                    geometry: []
                }) 
            }
        }

        // const enteroInput = (value) => {
        //     this.formRef.current.setFieldsValue({'cantidad': parseInt(value)});
        // }
        const buscarInteriorFuncional = (value) => {
            if(value.length>=3){
                onRequestInterioresActivosCompleta(value);
            }
            else{
                onRequestInterioresActivosCompletaLimpiar();
            }
        };

        const onFinish = formulario => {
            if(this.state.geometry.length>1){
                return message.error('El subinterior funcional cuenta con más de una geometría. Favor de trazar solo una geometría.');
            }
            
            console.log('Success:', formulario, this.state.geometry);
            onEditarSubInterior(formulario, subInteriorSeleccionado, this.state.geometry, page, busqueda);
        };

        const handleCancel = () => {
            onShowModalEditarSubInterior();
            onRequestInterioresActivosCompletaLimpiar();
            this.setState({
                fileImage: false,
                botonImage: false,
                botonEditar: false,
                fetchingImagen: false,
                imageUrl: null,
                height: 0,
                width: 0,
                geometry: [],
            });
        }
        const bounds = [
            [0, 0],
            [this.state.height, this.state.width],
        ]
        const style = { height: '35em', width: '100%' }

        const editGeometry = (geometriesE) => {
            console.log(`geometriesE`, geometriesE)
            
            this.setState({...this.state, geometry: geometriesE})
        }
        const _edited = (e) => {
            const {layers: {_layers}} = e;
            let editedArray = [];
            Object.values(_layers).map(({_leaflet_id, editing}) =>{
                //edited array
                console.log(`editing`, editing)
                editedArray = [...editedArray, {id: _leaflet_id, latlngs: editing.latlngs[0][0]}]
            });
            
            console.log('edited', e, editedArray);
            editGeometry(editedArray); 
        }

        const getGeoJson = () => {
            let geometry = this.state.geometry;
            console.log(`geometry`, geometry)
            return {
                type: "FeatureCollection",
                features: [{
                    type: "Feature",
                    properties: {},
                    geometry: {
                        type: "Polygon",
                        coordinates: 
                            geometry
                    }
                }]
            }
        }

        const _onFeatureGroupReady = reactFGref => {
            // populate the leaflet FeatureGroup with the geoJson layers
            if(!reactFGref ) return;
            let leafletGeoJSON = new L.GeoJSON(getGeoJson());
            let leafletFG = reactFGref.leafletElement;
            
            let layers = leafletFG._layers;
            let layers_ids = Object.keys(layers);
            
            if(layers_ids.length === 0) {
                //add layers
                leafletGeoJSON.eachLayer(layer => {
                    leafletFG.addLayer(layer);
                });
            }
        };

        return ( 
            <Modal
                visible={showModalEditarSubInterior}
                title='Editar SubInterior Funcional'
                onCancel={handleCancel}
                footer={false}
                width={'80%'}
            >
                <Spin spinning={fetchingEditarSubInterior}>
                    {subInteriorSeleccionado ? (
                    <Form {...layout}
                        name="formulario"
                        initialValues={subInteriorSeleccionado}
                        onFinish={onFinish}
                        ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Interior al que pertenece" 
                                    name="todoUbicacion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el interior funcional"
                                        loading={fetchingListaInteriorActivosCompleta}
                                        showSearch
                                        filterOption={false}
                                        onSearch={buscarInteriorFuncional}
                                        notFoundContent={null}
                                    >
                                        {listaInteriorActivosCompleta.map(option => 
                                        <Option key={option.idInteriorFuncional}>
                                            <Popover content={option.interiorFuncional} title={false}>
                                                {option.interiorFuncional}
                                            </Popover>
                                        </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="SubInterior funcional"
                                    name="subInteriorFuncional"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input pattern="^[ñíóáéúÁÉÓÍÚa-zA-Z0-9 ]+$" 
                                        title="La subinterior funcional solo debe tener letras, números y espacios"
                                        maxLength={60} placeholder="Ingresa el subinterior funcional" />
                                </Form.Item>
                            </Col>
                            {/* <Col span={11}>
                                <Form.Item
                                    label="Cantidad"
                                    name="cantidad"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber min={1} max={100} onChange={enteroInput} placeholder="Ingrese la cantidad" />
                                </Form.Item>
                            </Col> */}

                            <Col span={23}>
                                <Spin spinning={this.state.fetchingImagen}>
                                    <p>Geometría</p>
                                    {console.log(`this.state.imageUrl`, this.state.imageUrl)}
                                    {this.state.imageUrl && this.state.geometry ? (
                                    <Map
                                        crs={L.CRS.Simple}
                                        minZoom={-4}
                                        bounds={bounds}
                                        style={style}
                                    >
                                        <FeatureGroup
                                            ref={reactFGref => {
                                            _onFeatureGroupReady(reactFGref);
                                            }}
                                        >
                                            <EditControl
                                                position="topright"
                                                // onCreated={_created}
                                                onEdited={_edited}
                                                // onDeleted={_deleted}
                                                draw={{
                                                    circle: false,
                                                    circlemarker: false,
                                                    marker: false,
                                                    rectangle: false,
                                                    polyline: false,
                                                    polygon: false,
                                                }}
                                                edit={{
                                                    remove: false,
                                                }}
                                            />
                                        </FeatureGroup>
                                        <ImageOverlay
                                            url={this.state.imageUrl}
                                            bounds={bounds}
                                            zIndex={10}
                                        />
                                    </Map>
                                    ):false}
                                </Spin>
                            </Col>
                            
                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Editar SubInterior Funcional</Button>
                            </Col>
                        </Row>
                    </Form>
                    ):false}
                </Spin>
            </Modal>
        );
    }
}
 
const mapStateToProps = state => {
	return {
        fetchingEditarSubInterior: state.CatalogosGeneralesReducer.fetchingEditarSubInterior,
        showModalEditarSubInterior: state.CatalogosGeneralesReducer.showModalEditarSubInterior,
        fetchingListaPermisos: state.CatalogosGeneralesReducer.fetchingListaPermisos,
        listaPermisos: state.CatalogosGeneralesReducer.listaPermisos,
        subInteriorSeleccionado: state.CatalogosGeneralesReducer.subInteriorSeleccionado,
        page: state.CatalogosGeneralesReducer.page,
        busqueda: state.CatalogosGeneralesReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaInteriorActivosCompleta: state.CatalogosGeneralesReducer.fetchingListaInteriorActivosCompleta,
        listaInteriorActivosCompleta: state.CatalogosGeneralesReducer.listaInteriorActivosCompleta,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarSubInterior: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_SUBINTERIOR', fetchingEditarSubInterior: false, subInteriorSeleccionado: null})
        },
        onEditarSubInterior: ( formulario, subInteriorSeleccionado, editedGeometry, page, busqueda ) => {
            dispatch({ type: "EDITAR_SUBINTERIOR_REQUEST", formulario, subInteriorSeleccionado, editedGeometry, page, busqueda });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        },
        onRequestInterioresActivosCompleta: (busqueda) => {
            dispatch({ type: 'LISTA_INTERIORES_ACT_COMPLETA_REQUEST', busqueda: busqueda});
        },
        onRequestInterioresActivosCompletaLimpiar: () => {
            dispatch({ type: 'LISTA_INTERIORES_ACT_COMPLETA_REQUEST', listaInteriorActivosCompleta: []});
        },
        onShowModalMapa: () => {
            dispatch({ type: 'SHOW_MODAL_MAPA_SUBINTERIOR', showModalMapaSubInterior: true})
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarSubInterior);