import axios from "axios";

//const endpoint = "https://papantla-services.herokuapp.com/";
//const endpoint = "https://papantla-dev.herokuapp.com/";
const endpoint = 'https://morelos-dev.herokuapp.com/';
//const endpoint = "https://papantla-demo.herokuapp.com/";

//const endpoint = "http://172.24.0.170:8080/"

//const endpoint = 'https://papantla-services-jose.herokuapp.com/';
//const endpoint = 'https://papantla-dev-jose.herokuapp.com/';

//const endpoint = 'https://papantla-secret-jose.herokuapp.com/';
//const endpoint = 'https://papantla-secret.herokuapp.com/';


//Servicio Unidades de Medición
export function listaUnidadesDeMedicionActivosCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "unidadesMedidas/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getUnidadesDeMedicionPageCall(token, page, busqueda) {
	return axios({
		method: "get",
		url: endpoint + "unidadesMedidas/?unidadMedida=" + busqueda + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearUnidadDeMedicionCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "unidadesMedidas/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarUnidadDeMedicionCall(token, data, idUnidadMedida) {
	return axios({
		method: "put",
		url: endpoint + "unidadesMedidas/" + idUnidadMedida,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoUnidadDeMedicionCall(token, idUnidadMedida) {
	return axios({
		method: "get",
		url: endpoint + "unidadesMedidas/activar/" + idUnidadMedida,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function listaUnidadesMediPorCategoriaCall(token, idCategoriaUnidadMedida) {
	return axios({
		method: "get",
		url: endpoint + "unidadesMedidas/categoriasUnidadMedida/" + idCategoriaUnidadMedida,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarExcelUnidadesMedicionCall(token) {
	return axios({
	    	method: "post",
	    	url: endpoint + "unidadesMedidas/unidades/exportar/excel?",
		headers: {'Authorization':token},
		responseType: 'blob',
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Servicio Categorias de Medición
export function listaCategoriasDeMedicionActivosCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "unidadesMedidas/categorias/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getCategoriasDeMedicionPageCall(token, page, busqueda) {
	return axios({
		method: "get",
		url: endpoint + "unidadesMedidas/categorias/?categoriaUnidadMedida=" + busqueda + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearCategoriaDeMedicionCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "unidadesMedidas/categorias",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarCategoriaDeMedicionCall(token, data, idCategoriaUnidadMedida) {
	return axios({
		method: "put",
		url: endpoint + "unidadesMedidas/categorias/" + idCategoriaUnidadMedida,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoCategoriaDeMedicionCall(token, idCategoriaUnidadMedida) {
	return axios({
		method: "get",
		url: endpoint + "unidadesMedidas/categorias/activar/" + idCategoriaUnidadMedida,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarExcelCategoriasMedicionCall(token) {
	return axios({
	    	method: "post",
	    	url: endpoint + "unidadesMedidas/categorias/exportar/excel?",
		headers: {'Authorization':token},
		responseType: 'blob',
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Servicios de Cátalogo Activos Características
export function listaActivosCaractCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "activos/caract/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function listaActivosCaractFiltroCall(token, busqueda) {
	return axios({
	    method: "get",
		url: endpoint + "activos/caract/activos?param=" + busqueda,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaActivosCaractFiltroAsignacionSPCall(token, busqueda) {
	return axios({
		method: 'get',
		url: endpoint + "asignaciones/serv/planificados/activos?param=" + busqueda,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaActivosCaractBusquedaCall(token, page, busqueda) {
	return axios({
	    method: "get",
	    url: endpoint + "activos/caract?" + busqueda + "page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoActivoCaractCall(token, idActivoCaract) {
	return axios({
	    method: "get",
	    url: endpoint + "activos/caract/activa/" + idActivoCaract,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearActivoCaractCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "activos/caract",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarActivoCaractCall(token, data, idActivoCaract) {
	return axios({
	    method: "put",
	    url: endpoint + "activos/caract/" + idActivoCaract,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarExcelCaracteristicasActivosCall(token) {
	return axios({
	    	method: "post",
	    	url: endpoint + "activos/caracteristicas/exportar/excel?",
		headers: {'Authorization':token},
		responseType: 'blob',
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Servicios de Asignación de Activos

export function getAllAreasCall(token) {
	console.log(endpoint + "areas/activos/areas");
	return axios({
		method: "get",
		url: endpoint + "areas/activos/areas",
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getAllAreasExCall(token, idUbicacionAnterior) {
	console.log(endpoint + "areas/activos/areasEx?idUbicacionAnterior=" + idUbicacionAnterior);
	return axios({
		method: "get",
		url: endpoint + "areas/activos/areasEx?idUbicacionAnterior=" + idUbicacionAnterior,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaUnidadesFuncionalesActivasExCall(token, idUbicacionAnterior, idAreaFuncional) {
	return axios({
		method: "get",
		url: endpoint + "areas/activos/unidadesEx?idUbicacionAnterior=" + idUbicacionAnterior + "&idAreaFuncional=" + idAreaFuncional,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaUnidadesFuncionalesActivasCall(token, idAreaFuncional) {
	return axios({
		method: "get",
		url: endpoint + "areas/activos/unidadesUbicacion?idAreaFuncional=" + idAreaFuncional,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaUsuariosCall(token) {
	return axios({
		method: "get",
		url: endpoint + "usuarios/activos",
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaInterioresFuncionalesExCall(token, idUbicacionAnterior, idAreaFuncional, idUnidadFuncional) {
	return axios({
		method: "get",
		url: endpoint + "areas/activos/interioresEx?idUbicacionAnterior=" + idUbicacionAnterior + "&idAreaFuncional=" + idAreaFuncional + "&idUnidadFuncional=" + idUnidadFuncional,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}


export function listaInterioresFuncionalesCall(token, idAreaFuncional, idUnidadFuncional) {
	return axios({
		method: "get",
		url: endpoint + "areas/activos/interioresUbicacion?idAreaFuncional=" + idAreaFuncional + "&idUnidadFuncional=" + idUnidadFuncional,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaSubinterioresFuncionalesCall(token, idAreaFuncional, idUnidadFuncional, idInteriorFuncional) {
	console.log('ENDPOINT');
	console.log(endpoint + "areas/activos/subinterioresUbicacion?idAreaFuncional=" + idAreaFuncional + "&idUnidadFuncional=" + idUnidadFuncional + "&idInteriorFuncional" + idInteriorFuncional);
	return axios({
		method: "get",
		url: endpoint + "areas/activos/subinterioresUbicacion?idAreaFuncional=" + idAreaFuncional + "&idUnidadFuncional=" + idUnidadFuncional + "&idInteriorFuncional=" + idInteriorFuncional,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaSubinterioresFuncionalesExCall(token, idUbicacionAnterior, idAreaFuncional, idUnidadFuncional, idInteriorFuncional) {
	console.log('ENDPOINT');
	console.log(endpoint + "areas/activos/subinterioresEx?idUbicacionAnterior=" + 	idUbicacionAnterior + "&idAreaFuncional="+ idAreaFuncional + "&idUnidadFuncional=" + idUnidadFuncional + "&idInteriorFuncional=" + idInteriorFuncional);
	return axios({
		method: "get",
		url: endpoint + "areas/activos/subinterioresEx?idUbicacionAnterior=" + idUbicacionAnterior + "&idAreaFuncional="+ idAreaFuncional + "&idUnidadFuncional=" + idUnidadFuncional + "&idInteriorFuncional=" + idInteriorFuncional,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaAsignacionActivosBusquedaCall(token, page, busqueda){
	return axios({
		method: "get",
		url: endpoint + "activos?param=" + busqueda + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaActivosBusquedaAUISCall(token, page, any, order, field, idAreaFuncional, idUnidadFuncional, idInteriorFuncional, idSubInteriorFuncional){
	console.log("activos/activosUbicacion?" + any + order + field + idAreaFuncional + idUnidadFuncional + idInteriorFuncional + idSubInteriorFuncional + "page=" + page + "&offset=10")
	return axios({
		method: "get",
		url: endpoint + "activos/activosUbicacion?" + any + order + field + idAreaFuncional + idUnidadFuncional + idInteriorFuncional + idSubInteriorFuncional + "page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearAsignacionActivosCall(token, data, idAreaFuncional, idUnidadFuncional, idInteriorFuncional, idSubInteriorFuncional, idUsuarioRecibe) {
	return axios({
		method: "post",
		responseType: 'blob',
	    url: endpoint + "activos/asignacion?idAreaFuncional=" + idAreaFuncional + "&idUnidadFuncional=" + idUnidadFuncional + "&idInteriorFuncional=" + idInteriorFuncional + "&idSubInteriorFuncional=" + idSubInteriorFuncional + "&idUsuarioRecibe=" + idUsuarioRecibe,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//CATALOGO ACTIVOS
export function listaActivosBusquedaCall(token, page, busqueda) {
	return axios({
	    method: "get",
	    url: endpoint + "activos?param=" + busqueda + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoActivoCall(token, idActivo) {
	return axios({
	    method: "get",
	    url: endpoint + "activos/activa/" + idActivo,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearActivoCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarActivoCall(token, data, idActivo) {
	return axios({
	    method: "put",
	    url: endpoint + "activos/" + idActivo,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function archivosEsperaActivosCall(token, estado, page) {
	return axios({
	    method: "get",
	    url: endpoint + "activos/carga/masiva?estado=" + estado + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function subirArchivoActivoCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "activos/carga/masiva",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function eliminarArchivoActivoCall(token, idArchivoMasivo) {
	return axios({
	    method: "delete",
	    url: endpoint + "activos/carga/masiva/" + idArchivoMasivo,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarErroresArchivoActivoCall(token, idArchivoMasivo) {
	return axios({
	    method: "get",
	    url: endpoint + "activos/carga/masiva/errores?idArchivoMasivo=" + idArchivoMasivo,
		headers: {'Authorization':token},
		responseType: 'blob',
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function procesarArchivoActivoCall(token, idArchivoMasivo) {
	return axios({
	    method: "get",
	    url: endpoint + "activos/carga/masiva/procesando?idArchivoMasivo=" + idArchivoMasivo,
		headers: {'Content-Type': 'application/json', 'Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarExcelActivosCall(token) {
	return axios({
	    	method: "post",
	    	url: endpoint + "activos/exportar/excel",
		headers: {'Authorization':token},
		responseType: 'blob',
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Servicios Estatus del Activo
export function listaEstatusActivoActivosCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "estadosActivos/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getEstatusActivosPageCall(token, page, busqueda) {
	return axios({
		method: "get",
		url: endpoint + "estadosActivos/?param=" + busqueda + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearEstatusActivoCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "estadosActivos/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarEstatusActivoCall(token, data, idEstadoActivo) {
	return axios({
		method: "put",
		url: endpoint + "estadosActivos/" + idEstadoActivo,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoEstatusActivoCall(token, idEstadoActivo) {
	return axios({
		method: "get",
		url: endpoint + "estadosActivos/activar/" + idEstadoActivo,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarExcelEstatusActivoCall(token) {
	return axios({
	    	method: "post",
	    	url: endpoint + "estadosActivos/exportar/excel",
		headers: {'Authorization':token},
		responseType: 'blob',
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// SERVICIOS MOTIVOS DE SALIDA
export function listaMotivosBusquedaCall(token , page, param, field, order){
	return axios({
		method: "get",
		url: endpoint + "motivos?page=" + page + "&offset=10&" + param + field + order,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	})
};

export function listaMotivosActivosCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "motivos/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function cambiarEstadoMotivosCall(token , idMotivoSalida){
	return axios({
		method: "get",
	    url: endpoint + "motivos/activar/" + idMotivoSalida,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	})
}; 

export function crearMotivosCall(token , data){
	return axios({
		method: "post",
	    url: endpoint + "motivos",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data, 
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	})
}; 

export function editarMotivosCall(token , data, idMotivoSalida){
	return axios({
		method: "put",
		url: endpoint + "motivos/" + idMotivoSalida,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	})
}; 

export function exportarExcelMotivosSalidaActivosCall(token, order, field) {
	return axios({
	    	method: "post",
	    	url: endpoint + "motivos/exportar/excel?order=" + order + "&field=" + field,
		headers: {'Authorization':token},
		responseType: 'blob',
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// SERVICIOS REINGRESO ACTIVOS BUSQUEDA
export function listarReingresoActivosBusquedaCall(token , folio, page, field, order){
	return axios({
		method: "get",
		url: endpoint + "reingresos?" + folio + "&page=" + page + "&offset=10&" + field + order,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	})
}

// CREAR REINGRESO ACTIVO
// folios actvios para reingreso de bienes/activos
export function ReingresofoliosActivosCall(token){
	return axios({
		method: "get",
		url: endpoint + "movimientoActivos/foliosActivos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	})
};

export function crearReingresoActivoCall(token, data, idAreaFuncional, idUnidadFuncional, idInteriorFuncional, idSubInteriorFuncional, idUsuarioRecibe, idEstadoActivo, comentarioReingreso){
	return axios({
		responseType: 'blob',
		method: "post",
	    url: endpoint + "reingresos/asignacionReingresos?idAreaFuncional=" + idAreaFuncional + "&idUnidadFuncional=" + idUnidadFuncional + "&idInteriorFuncional=" + idInteriorFuncional + idSubInteriorFuncional + "&idUsuarioRecibe=" + idUsuarioRecibe + "&idEstadoActivo=" + idEstadoActivo + "&comentarioReingreso=" + comentarioReingreso,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data, 
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// CREAR REINGREESO ACTIVO  CONSERVANDO LA UBICACION ANTERIOR
export function crearReingresoActivoSinAsignacionCall(token, data, idUsuarioRecibe, idEstadoActivo, comentarioReingreso){
	return axios({
		responseType: 'blob',
		method: "post",
	    url: endpoint + "reingresos/sinAsignacionReingresos?idUsuarioRecibe=" + idUsuarioRecibe + "&idEstadoActivo=" + idEstadoActivo + "&comentarioReingreso=" + comentarioReingreso,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data, 
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	})
}
	

//Servicios Rangos de Renovación del Activo
export function listaRangosRenovacionActivosCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "rangosRenovacion/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	})
}; 


export function getRangosRenovacionPageCall(token, page, busqueda) {
	return axios({
		method: "get",
		url: endpoint + "rangosRenovacion/?param=" + busqueda + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearRangoRenovacionCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "rangosRenovacion/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	})
}; 


export function editarRangoRenovacionCall(token, data, idRangoRenovacion) {
	return axios({
		method: "put",
		url: endpoint + "rangosRenovacion/" + idRangoRenovacion,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	})
}; 


export function estadoRangoRenovacionCall(token, idRangoRenovacion) {
	return axios({
		method: "get",
		url: endpoint + "rangosRenovacion/activar/" + idRangoRenovacion,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarExcelRangosRenovacionActivoCall(token) {
	return axios({
	    	method: "post",
	    	url: endpoint + "rangosRenovacion/exportar/excel",
		headers: {'Authorization':token},
		responseType: 'blob',
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Salida de Bienes :)

export function generadorFolioSalidaCall(token, tipoMovimiento) {
	return axios({
		method: "get",
		url: endpoint + "movimientoActivos/folio?tipoMovimiento=" + tipoMovimiento,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaSalidaDeBienesBusquedaCall(token, idServicio, page, busqueda){
	return axios({
		method: "get",
		url: endpoint + "activos/activosByServicio?idServicio=" + idServicio + "&page=" + page  + "&offset=10" + "&param=" + busqueda,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaActivosEnOperacionServicioCall(token, idServicio){
	return axios({
		method: "get",
		url: endpoint + "activos/activosByServicioSinPage?idServicio=" + idServicio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearSalidaDeBienesCall(token, data, idUbicacionDestino, usuarioRecibe, condicion) {
	console.log("service crearSalidaDeBienesCall", "movimientoActivos/salidaBienes?" +  idUbicacionDestino + "usuarioRecibe=" + usuarioRecibe + condicion)
	return axios({
		method: "post",
		responseType: 'blob',
	    url: endpoint + "movimientoActivos/salidaBienes?" +  idUbicacionDestino + "usuarioRecibe=" + usuarioRecibe + condicion,
		headers: {'Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Servicios Requisición por Actualización Tecnológica
export function getListaActualizacionActivosPageCall(token, data, maximoDias, minimoDias, fecha, page, busqueda) {
	return axios({
		method: "post",
		url: endpoint + "actualizacionTecnologica/?fecha=" + fecha + "&valorMax=" + maximoDias + "&valorMin=" + minimoDias + "&param=" + busqueda + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function ActualizacionActivosDescargaArchivoCall(token, data, maximoDias, minimoDias, fecha, busqueda) {
	return axios({
		method: "post",
		url: endpoint + "actualizacionTecnologica/exportar?fecha=" + fecha + "&valorMax=" + maximoDias + "&valorMin=" + minimoDias + "&param=" + busqueda,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
		responseType: 'blob',
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Almacenes
// Búsqueda
export function listaAlmacenesBusquedaCall(token, page, param, order, field) {
	return axios({
	    method: "get",
	    url: endpoint + "almacenes?" + order + field + param + "page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json', 'Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Activos
export function listaAlmacenesActivosCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "almacenes/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Cambiar Estado
export function estadoAlmacenCall(token, idAlmacen) {
	return axios({
		method: "get",
		url: endpoint + "almacenes/activa/" + idAlmacen,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Crear
export function crearAlmacenCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "almacenes/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Editar
export function editarAlmacenCall(token, data, idAlmacen) {
	console.log(endpoint + "almacenes/" + idAlmacen);
	return axios({
		method: "put",
		url: endpoint + "almacenes/" + idAlmacen,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarExcelAlmacenesCall(token, order, field) {
	return axios({
	    	method: "post",
	    	url: endpoint + "almacenes/exportar/excel?order=" + order + "&field=" + field,
		headers: {'Authorization':token},
		responseType: 'blob',
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Requisición Directa
export function subirArchivoRequisicionCall(token, data, idServicio) {
	return axios({
	    	method: "post",
	    	url: endpoint + "requisicion/directa/" + idServicio,
		headers: {'Authorization':token},
		data: data,
		responseType: 'blob',
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function folioRequisicionCall(token, folio) {
	return axios({
		method: "get",
		url: endpoint + "requisicion/directa?folio=" + folio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Servicios Tipos de Folio de Movimientos
export function listaTiposFoliosMovimientosActivosCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "tiposFolio/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function getTiposFoliosMovimientosPageCall(token, page, busqueda) {
	return axios({
		method: "get",
		url: endpoint + "tiposFolio/?param=" + busqueda + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function estadoTipoFolioMovimientoCall(token, idTipoFolio) {
	return axios({
		method: "get",
		url: endpoint + "tiposFolio/activar/" + idTipoFolio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearTipoFolioMovimientoCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "tiposFolio",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function editarTipoFolioMovimientoCall(token, data, idTipoFolio) {
	return axios({
		method: "put",
		url: endpoint + "tiposFolio/" + idTipoFolio,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarExcelTipoFolioCall(token) {
	return axios({
	    	method: "post",
	    	url: endpoint + "tiposFolio/moviento/exportar/excel?",
		headers: {'Authorization':token},
		responseType: 'blob',
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// ** MOVIMIENTOS ENTRADAS/SALIDAS SERVICES ** //
export function listaMovimientosGeneralBusquedaCall(token, order, field, param, page) {
	return axios({
		method: "get",
		url: endpoint + "movimientos/almacenes/folios?" + order + field + "param=" + param + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// no me acuerdo
export function listaMovimientosConsultaCall(token, folio, page) {
	return axios({
		method: "get",
		url: endpoint + "movimientos/almacenes?folio=" + folio + "&offset=" + page + "&limit=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
// consulta lista inventario
export function listaInventarioConsultaCall(token, busqueda, idFamilia, idSubFamilia, page, idAlmacenOrigen) {
	//console.log(endpoint + "movimientos/almacenes/inventario?param=" + busqueda + idFamilia + "&idSubFamilia=" + idSubFamilia + "&idUbicacion=" + idUbicacion + "&page=" + page + "&offset=10")
	return axios({
		method: "get",
		url: endpoint + "movimientos/almacenes/inventario?param=" + busqueda + idFamilia + "&idSubFamilia=" + idSubFamilia +  "&page=" + page + "&offset=10&idAlmacen=" + idAlmacenOrigen ,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Lista de inventario de tipo familia Activos
export function listaInventarioTipoActivoCall(token, page, idAlmacen, param, idSubFamilia) {
	return axios({
		method: "get",
		url: endpoint + "movimientos/almacenes/inventario/activos?page=" + page + "&offset=10&idAlmacen=" + idAlmacen + param + idSubFamilia,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// lista requisicion directa
export function listaRequisicionDirectaConsultaCall(token, folio, busqueda, idFamilia, idSubFamilia, page) {
	console.log(endpoint + "requisicion/directa/folios?folio=" + folio + "&param=" + busqueda + idFamilia + "&idSubFamilia=" + idSubFamilia + "&page=" + page + "&offset=10",)
	return axios({
		method: "get",
		url: endpoint + "requisicion/directa/folios?folio=" + folio + "&param=" + busqueda + idFamilia + "&idSubFamilia=" + idSubFamilia + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// consulta lista productos
export function listaProductosConsultaCall(token, param, idFamilia, idSubFamilia, orden, campo, page) {
	return axios({
		method: "get",
		url: endpoint + "tiposFolio/producto/activo/almacen?param=" + param + "&idFamilia=" + idFamilia + "&idSubFamilia=" + idSubFamilia + "&" + orden + "&" +  campo + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// imprimir o descargar folio
export function imprimirFolioCall(token, folio, idUsuarioRecibe){
	return axios({
		method: "get",
		responseType: "blob",
		url: endpoint + "movimientos/almacenes/imprimir/folio?folio=" + folio + "&idUsuarioRecibe=" + idUsuarioRecibe,
		headers: {'Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
// generar nuevo folio 
export function generarFolioCall(token, data){
	return axios({
		method: "post",
		url: endpoint + "movimientos/almacenes", 
		headers: {'Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
// cerrar folio
export function cerrarFolioCall(token, folio){
	return axios({
		method: "post",
		url: endpoint + "movimientos/almacenes/cerrar/folio?folio=" + folio, 
		headers: {'Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
// agregar productos en edicion
export function agregarProductosEdicionCall(token, folio, data){
	return axios({
		method: "post",
		url: endpoint + "movimientos/almacenes/agregar?folio=" + folio, 
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// editar Folio movimiento
export function editarFolioCall(token, folio, data){
	return axios({
		method: "put",
		url: endpoint + "movimientos/almacenes?folio=" + folio, 
		headers: {'Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// eliminar producto - edicion
export function cancelarProductoCall(token, idMovimientoAlmacen) {
	return axios({
		method: "get",
		url: endpoint + "movimientos/almacenes/desactivar/registro/" + idMovimientoAlmacen,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function obtenerAlmacenPorUbicacionCall(token, idUbicacionProducto) {
	return axios({
		method: "get",
		url: endpoint + "tiposFolio/ubicaciones/almacenes/" + idUbicacionProducto,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
// obtener info idInventario 
export function obtenerInfoIdInventarioCall(token, idProducto, lote, idUbicacionOrigen) {
	console.log('servicio',endpoint + "movimientos/almacenes/verificar/?idProducto=" + idProducto + "&lote=" + lote + "&idUbicacionOrigen=" + idUbicacionOrigen)
	return axios({
		method: "get",
		url: endpoint + "movimientos/almacenes/verificar/?idProducto=" + idProducto + "&lote=" + lote + "&idUbicacionOrigen=" + idUbicacionOrigen,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// cerrar folio sin Salida
export function cerrarFolioSinSalidaCall(token, folio) {
	return axios({
		method: "get",
		url: endpoint + "movimientos/almacenes/salida/folio?folio=" + folio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}



// ubicacion por almacen
export function ubicacionPorAlmacenCall(token, idAlmacen) {
	return axios({
		method: "get",
		url: endpoint + "tiposFolio/ubicaciones/almacen/" + idAlmacen,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
// Servicio para obtener lista filtradas productos
export function listaProductosFiltradosConsultaCall(token, busqueda, idSubFamilia, orden, campo, page, folio) {
	return axios({
		method: "get",
		url: endpoint + "tiposFolio/producto/activo/almacen/agregar?param=" + busqueda + "&idSubFamilia=" + idSubFamilia  + "&orden=" + orden + "&campo=" + campo + "&folio=" + folio + "&page=" + page + "&offset=10" ,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Servicio para obtener lista filtrados inventario
export function listaInventarioFiltradosConsultaCall(token, busqueda, idSubFamilia, page, idUbicacion ,folio) {
	return axios({
		method: "get",
		url: endpoint + "movimientos/almacenes/inventario/agregar?param=" + busqueda + "&idFamilia=&idSubFamilia=" + idSubFamilia  + "&page=" + page + "&offset=10&idAlmacen="+ idUbicacion + "&folio=" + folio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Servicio para obtener lista requisicion filtrados
export function listaRequisicionFiltradosConsultaCall(token, folio, busqueda, idSubFamilia, page ,folioMov) {
	console.log('service', endpoint + "requisicion/directa/folios/agregar?folio=" + folio + "&param=" + busqueda + "&idFamilia=&idSubFamilia=" + idSubFamilia  + "&page=" + page + "&offset=10&folioMov=" + folioMov)
	return axios({
		method: "get",
		url: endpoint + "requisicion/directa/folios/agregar?folio=" + folio + "&param=" + busqueda + "&idFamilia=&idSubFamilia=" + idSubFamilia  + "&page=" + page + "&offset=10&folioMov=" + folioMov,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
 }

// SERVICIOS REQUISICION PUNTO DE REORDEN
export function catalogoPuntoReordenBusquedaCall(token, param, order, field, tipo, page) {
	return axios({
		method: "get",
		url: endpoint + "movimientos/almacenes/folios/reorden?param=" + param +  "&order=" + order + "&field=" + field + tipo + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
// generar folio requisicion punto de reorden
export function generarFolioRequisicionPuntoReordenCall(token){
	return axios({
		method: "post",
		url: endpoint + "movimientos/almacenes/generar", 
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// consulta por folio
export function consultaFolioCall(token, folio, page){
	return axios({
		method: "get",
		url: endpoint + "movimientos/almacenes/folio/consulta/reorden?folio=" + folio + "&page=" + page + "&offset=10", 
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// editar producto de requisicion punto de reorden
export function editarProductoRequisicionPuntoReordenCall(token, data, idRequisicionPuntoReorden) {
	return axios({
		method: "put",
		url: endpoint + "movimientos/almacenes/edicion/" + idRequisicionPuntoReorden,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Cerrar folio punto de reorden
export function cerrarFolioRequisicionPuntoReordenCall(token, folio, data) {
	return axios({
		method: "post",
		url: endpoint + "movimientos/almacenes/cerrar/folio/reorden?folio=" + folio,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
// servicio para descargar folio
export function descargarFolioRequisicionPuntoReordenCall(token, folio, idUsuarioRecibe) {
	return axios({
		method: "post",
		responseType: "blob",
		url: endpoint + "movimientos/almacenes/exportar/folio?folio=" + folio + "&idUsuarioRecibe=" + idUsuarioRecibe,
		headers: {'Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// servicio para descargar folio de requisiscion de compra
export function descargarFolioRequisicionCompraCall(token, folio) {
	return axios({
		method: "get",
		responseType: "blob",
		url: endpoint + "requisicion/materiales/imprimir/folio/compra/?folio=" + folio,
		headers: {'Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
// cancelar folio de requisicon punto de reorden
export function cancelarFolioPuntoReordenCall(token, folio){
	return axios({
		method: "get",
		url: endpoint + "movimientos/almacenes/cancelar/folio/reorden?folio=" + folio, 
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
	
//Servicios Productos de Almacén
export function listaProductosActivosCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "tiposFolio/productos/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaProductosActivosBusquedaCall(token, busqueda, tipoFamilia) {
	return axios({
	    method: "get",
		url: endpoint + "tiposFolio/productos/activos/by?param=" + busqueda + '&tipoFamilia=' + tipoFamilia,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getProductosPageCall(token, page, busqueda, campo, orden, idSubFamilia, familiaTipoActivo) {
	return axios({
		method: "get",
		url: endpoint + "tiposFolio/producto/?FamiliaTipoActivo=" + familiaTipoActivo + "&param=" + busqueda + "&page=" + page + "&offset=10&orden="+ orden + "&campo=" + campo + "&idSubFamilia=" + idSubFamilia,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearProductoCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "tiposFolio/producto",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarProductoCall(token, data, idProducto) {
	return axios({
		method: "put",
		url: endpoint + "tiposFolio/producto/" + idProducto,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarExcelProductosCall(token, order, field, idSubFamilia) {
	return axios({
	    	method: "post",
	    	url: endpoint + "tiposFolio/productos/exportar/excel?order=" + order + "&field=" + field + "&idSubFamilia=" + idSubFamilia,
		headers: {'Authorization':token},
		responseType: 'blob',
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function cancelarFolioCall(token, folio){
	console.log(endpoint + "movimientos/almacenes/cancelar/folio?folio=" + folio);
	return axios({
		method: "get",
		url: endpoint + "movimientos/almacenes/cancelar/folio?folio=" + folio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoProductoCall(token, idProducto) {
	return axios({
		method: "get",
		url: endpoint + "tiposFolio/producto/activa/" + idProducto,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Servicios Ubicaciones de Productos
export function listaUbicacionesProductosActivosCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "tiposFolio/ubicaciones/productos/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function getUbicacionesProductosPageCall(token, page, busqueda, campo, orden, nombreAlmacen) {
	return axios({
		method: "get",
		url: endpoint + "tiposFolio/ubicaciones/productos/?almacen="+ nombreAlmacen + "&param=" + busqueda + "&field=" + campo + "&order=" + orden + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function estadoUbicacionProductoCall(token, idUbicacionProducto) {
	return axios({
		method: "get",
		url: endpoint + "tiposFolio/ubicaciones/productos/activar/" + idUbicacionProducto,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearUbicacionProductoCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "tiposFolio/ubicaciones/prodcutos/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function editarUbicacionProductoCall(token, data, idUbicacionProducto) {
	return axios({
		method: "put",
		url: endpoint + "tiposFolio/ubicaciones/productos/" + idUbicacionProducto,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarExcelUbicacionesProductosCall(token, order, field) {
	return axios({
	    	method: "post",
	    	url: endpoint + "tiposFolio/ubicaciones/productos/exportar/excel?order=" + order + "&field=" + field,
		headers: {'Authorization':token},
		responseType: 'blob',
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Búsqueda filtro familias
export function listaFamiliasFiltroCall(token, busqueda) {
	return axios({
	    method: "get",
	    url: endpoint + "familias/activas?param=" + busqueda,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Busqueda filtro subfamilias
export function listaSubfamiliasFiltroCall(token, busqueda) {
	return axios({
	    method: "get",
	    url: endpoint + "familias/subfamilias/AllActivas?param=" + busqueda,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Servicios por Almacenes
export function listaServiciosbyAlmacenCall(token, data) {
	return axios({
	   	method: "post",
	    	url: endpoint + "servicios/activosByAlmacenes/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Familias por Servicio
export function listaFamiliasbyServicioCall(token, data) {
	return axios({
	   	method: "post",
	    	url: endpoint + "familias/activasByServicios/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Subfamilias por Familia
export function listaSubfamiliasbyFamiliaCall(token, data) {
	return axios({
	   	method: "post",
	    	url: endpoint + "familias/subfamilias/activasByFamilias/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaInventarioCiclicoDescargaCall(token, formData, porcentajeUso, mayor, menor, ) {

	console.log(endpoint + "movimientos/almacenes/inventarioCiclico?" + porcentajeUso + menor + mayor)
	
	return axios({
		method: "post",
		url: endpoint + "movimientos/almacenes/inventarioCiclico?" + porcentajeUso + menor + mayor,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: formData,
		responseType: 'blob'
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Obtener Productos Inventario Cíclico
export function listadoInventarioCiclicoCall(token, data, porcentajeUso,  menor, mayor, offset) {

	console.log(endpoint + "movimientos/almacenes/listadoInventario?limite=10" + "&offset=" + offset + porcentajeUso  + menor + mayor);

	return axios({
	   	method: "post",
	    	url: endpoint + "movimientos/almacenes/listadoInventario?limite=10" + "&offset=" + offset + porcentajeUso  + menor + mayor,
		headers: { 'Authorization': token },
		data: data,
	}).then(response => {
		return response;	
	}).catch(error => {
		return error.response.data;
	});
}

// Captura Inventario - Capturar conteo del inventario
export function capturarConteoCall(token, data, numConteo) {
	return axios({
	    method: "post",
		url: endpoint + "movimientos/almacenes/capturarConteo?numConteo=" + numConteo,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Captura de Inventario - Almacenes by folio y número de conteo
export function listaAlmacenesByFolioCall(token, folioInventario, numConteo) {
	//console.log('path', endpoint + "movimientos/almacenes/folioInventario/almacenes?folioInventario=" + folioInventario + '&numConteo=' + numConteo)
	return axios({
	    method: "get",
		url: endpoint + "movimientos/almacenes/folioInventario/almacenes?folioInventario=" + folioInventario + '&numConteo=' + numConteo,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Captura Inventario - Conteo Completo ?
export function validarConteoCall(token, folioInventario, numConteo) {
	console.log(endpoint + "movimientos/almacenes/conteoInventarioCompleto?folioInventario=" + folioInventario + '&numConteo=' + numConteo);
	return axios({
	    method: "get",
		url: endpoint + "movimientos/almacenes/conteoInventarioCompleto?folioInventario=" + folioInventario + '&numConteo=' + numConteo,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Captura Inventario - Pisos by folio y número de conteo
export function listaPisosByFolioCall(token, data, folioInventario, numConteo) {
	return axios({
	    method: "post",
	    url: endpoint + "movimientos/almacenes/folioInventario/pisos?folioInventario=" + folioInventario + '&numConteo=' + numConteo,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Captura Inventario - Areas by folio y número de conteo
export function listaAreasByFolioCall(token, data, folioInventario, numConteo) {
	return axios({
	    method: "post",
	    url: endpoint + "movimientos/almacenes/folioInventario/areas?folioInventario=" + folioInventario + '&numConteo=' + numConteo,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Captura Inventario - Pasillos by folio y número de conteo
export function listaPasillosByFolioCall(token, data, folioInventario, numConteo) {
	return axios({
	    method: "post",
	    url: endpoint + "movimientos/almacenes/folioInventario/pasillos?folioInventario=" + folioInventario + '&numConteo=' + numConteo,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Captura Inventario - Racks by folio y número de conteo
export function listaRacksByFolioCall(token, data, folioInventario, numConteo) {
	return axios({
	    method: "post",
	    url: endpoint + "movimientos/almacenes/folioInventario/racks?folioInventario=" + folioInventario + '&numConteo=' + numConteo,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Captura Inventario - Productos by folio y número de conteo
export function listaProductosByFolioCall(token, data, folioInventario, numConteo) {
	return axios({
	    method: "post",
	    url: endpoint + "movimientos/almacenes/productosByFolioInventario?folioInventario=" + folioInventario + '&numConteo=' + numConteo,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Generar Folio
export function generarFolioCapturaCall(token, data, menor, mayor, porcentajeUso) {
	console.log(endpoint + "movimientos/almacenes/folioInventario?" + menor + mayor + porcentajeUso);
	return axios({
	    method: "post",
		url: endpoint + "movimientos/almacenes/folioInventario?" + menor + mayor + porcentajeUso,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Filtro por Folios Activos
export function listaFiltroFolioCall(token, busqueda) {
	return axios({
	    method: "get",
	    url: endpoint + "movimientos/almacenes/foliosInventarioActivos?param=" + busqueda,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Listado para toma de inventario fisico general
export function listaPisosByAlmacenCall(token, idsAlmacenes) {
	return axios({
	    method: "get",
		url: endpoint + "listado/inventario/fisico/general/pisos?idsAlmacenes=" + idsAlmacenes,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaAreasByPisosCall(token, pisos) {
	return axios({
		method: "get",
		url: endpoint + "listado/inventario/fisico/general/areas?pisos=" + pisos,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function listaPasillosByAreasCall(token, areas) {
	return axios({
		method: "get",
		url: endpoint + "listado/inventario/fisico/general/pasillos?areas=" + areas,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function listaRacksByPasillos(token, pasillos) {
	return axios({
		method: "get",
		url: endpoint + "listado/inventario/fisico/general/racks?pasillos=" + pasillos,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function listaInventarioFisicoBusquedaCall(token, data, page) {
	return axios({
		method: "post",
		url: endpoint + "listado/inventario/fisico/general/?page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function listaInventarioFisicoDescargaCall(token, data, idUsuario) {
	return axios({
		method: "post",
		url: endpoint + "listado/inventario/fisico/general/imprimir/pdf?idUsuarioReviso=" + idUsuario,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
		responseType: 'blob'
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Reporte de Faltantes y Ajuste Inventario Físico
export function listaFaltantesInventarioCall(token, folio, conteo, page) {
	return axios({
		method: "get",
		url: endpoint + "listado/inventario/fisico/general/reporte/faltantes?folio=" + folio + "&conteo=" + conteo + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function listaFaltantesInventarioDescargaCall(token, folio, conteo) {
	return axios({
		method: "post",
		url: endpoint + "listado/inventario/fisico/general/imprimir/pdf/faltantes?folio=" + folio + "&conteo=" + conteo,
		headers: {'Content-Type': 'application/json','Authorization':token},
		responseType: 'blob'
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function CerrarFolioInventarioCall(token, folio) {
	return axios({
		method: "get",
		url: endpoint + "listado/inventario/fisico/general/cerrar/folio/" + folio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function salidaInstalacionCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "movimientos/almacenes/instalacion",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Kardex - Producto existente
export function productoExistenteCall(token, claveSku) {
	return axios({
		method: "get",
		url: endpoint + "movimientos/almacenes/kardex/productoExistente?claveSku=" + claveSku,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Kardex - Almacenes por producto
export function almacenesByProductoCall(token, claveSku) {
	return axios({
		method: "get",
		url: endpoint + "movimientos/almacenes/kardex/almacenByProducto?claveSku=" + claveSku,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Kardex - Obtener kardex producto
export function obtenerKardexProductoCall(token, idAlmacen, claveSku, operacion, fechaInicio, fechaFin) {
	return axios({
		method: "get",
		url: endpoint + "movimientos/almacenes/kardexEncabezado?idAlmacen=" + idAlmacen + "&claveSku=" + claveSku + "&operacion=" + operacion + "&fechaInicio=" + fechaInicio + "&fechaFin=" + fechaFin,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Kardex - Obtener kardex MOVIMIENTOS
export function obtenerKardexMovimientosCall(token, idAlmacen, claveSku, operacion, fechaInicio, fechaFin) {
	return axios({
		method: "get",
		url: endpoint + "movimientos/almacenes/kardex?idAlmacen=" + idAlmacen + "&claveSku=" + claveSku + "&operacion=" + operacion + "&fechaInicio=" + fechaInicio + "&fechaFin=" + fechaFin,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Kardex - Reporte xls
export function kardexReporteCall(token, data ) {
	return axios({
		method: "post",
		url: endpoint + "movimientos/almacenes/kardexReporte",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
		responseType: 'blob'
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// servicios para ingresos de verificacion de calidad
export function getFoliosVerCalidadCall(token, param,fechaInicio, fechaFinal, order, field , page) {
	return axios({
		method: "get",
		url: endpoint + "ingreso/almacen/calidad/folios?param=" + param + "&fechaInicio=" + fechaInicio + "&fechaFin=" + fechaFinal + "&order=" + order + "&field=" + field + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearFolioVerificacionCalCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + 'ingreso/almacen/calidad/crear',
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function detalleFolioVCCall(token, folio, page){
	return axios({
		method: "get",
		url: endpoint + 'ingreso/almacen/calidad?folio=' + folio +'&page=' + page + '&offset=10',
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function verificacionCalidadCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + 'ingreso/almacen/calidad/verificar',
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function imprimirPdfVerificacionCalidadCall(token, idUsuario, folio) {
	return axios({
		method: "post",
		url: endpoint + 'ingreso/almacen/calidad/imprimir/folio?idUsuarioRecibe='+idUsuario+'&folio='+folio,
		headers: {'Content-Type': 'application/json','Authorization':token},
		responseType: 'blob'
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//descargas para movimientos de almacen
export function verificarSerieNumCall(token, folio) {
	return axios({
		method: "get",
		url: endpoint + 'movimientos/almacenes/verificar/activos?folio='+folio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function descargarListaSeriesCall(token, idUsuario, folio) {
	return axios({
		method: "post",
		url: endpoint + 'movimientos/almacenes/imprimir/folioNumerosSerie?idUsuarioRecibe='+idUsuario+'&folio='+folio,
		headers: {'Content-Type': 'application/json','Authorization':token},
		responseType: 'blob'
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function descargarSalidaInstalacionCall(token, folio) {
	return axios({
		method: "get",
		url: endpoint + 'movimientos/almacenes/instalacion/imprimir?folio=' + folio,
		headers: {'Content-Type': 'application/json','Authorization': token},
		responseType: 'blob'
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//movimientos de Herramientas
export function busquedaHerramientasCall(token, param, idAlmacen, page){
	return axios({
		method: "get",
		url: endpoint + 'movimientos/herramientas/inventario?' + param + '&idAlmacen=' + idAlmacen +'&page=' + page + '&offset=10',
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function prestamoHerramientasCall(token, data) {
	return axios({
		method: "post",
		responseType: 'blob',
		url: endpoint + 'movimientos/herramientas',
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function bajaHerramientasCall(token, data) {
	return axios({
		method: "post",
		responseType: 'blob',
		url: endpoint + 'movimientos/herramientas/baja',
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function devolucionHerramientasCall(token, data) {
	return axios({
		method: "put",
		url: endpoint + 'movimientos/herramientas/devolucion',
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function obtenerFoliosPrestamosCall(token) {
	return axios({
		method: "get",
		url: endpoint + 'movimientos/herramientas/folios',
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function obtenerFolioPrestamoCall(token, folio, page) {
	return axios({
		method: "get",
		url: endpoint + 'movimientos/herramientas?folio='+folio+'&page='+page+'&offset=10',
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function obtenerNumerosInventarioPrestadosCall(token) {
	return axios({
		method: "get",
		url: endpoint + 'movimientos/herramientas/inventario/verificar',
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function obtenerNumerosInventarioPorFolioCall(token, folio, idUbicacionProducto, idProducto) {
	return axios({
		method: "get",
		url: endpoint + 'movimientos/herramientas/numeros?folio='+folio+'&idUbicacionProducto='+idUbicacionProducto+'&idProducto='+idProducto,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearNumerosInventarioCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + 'movimientos/herramientas/numeros/crear',
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function obtenerNumerosInventarioDisponiblesCall(token, idProducto, idUbicacionProducto) {
	return axios({
		method: "get",
		url: endpoint + 'movimientos/herramientas/inventario/verificar?idProducto='+idProducto+'&idUbicacionProducto='+idUbicacionProducto,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}




export function getErroresCargaMasivaID(token, idArchivoMasivo, page) {
	return axios({
		method: "get",
		url: endpoint + "tiposFolio/carga/masiva/errores?idArchivoMasivo="+idArchivoMasivo+"&page="+page+"&offset=10&order=fila&direction=DESC",
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getListArchivosCargaMasiva(token, page, estado) {
	return axios({
		method: "get",
		url: `${endpoint}tiposFolio/archivos/masivos?page=${page}&offset=10&order=fechaActualizacion&direction=DESC&estado=${estado}`,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}


export function changeEstadoArchivos(token, idArchivoMasivo, estado) {
	return axios({
		method: "put",
		url: `${endpoint}tiposFolio/archivos/masivos/estado/${idArchivoMasivo}?estado=${estado}`,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}



//Carga masiva IPH

export function cargaMasivaIPHCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "tiposFolio/carga/masiva/producto",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function cargaMasivaSeriesCall(token, data, folio) {
	return axios({
	    method: "post",
	    url: endpoint + "tiposFolio/carga/masiva/series?folio="+folio,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}


//Carga masiva Almacén

export function cargaMasivaAlmacenCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "ingreso/almacen/calidad/carga/masiva/producto",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function cargaMasivaSeriesAlmacenCall(token, data, folio) {
	return axios({
	    method: "post",
	    url: endpoint + "ingreso/almacen/calidad/carga/masiva/series?folio="+folio,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//---------------------------------------------REQUISICION DE MATERIALES SERVICIOS--------------------------------------------------------
//Obtener folios requisiciones generados
export function getRequisicionesGeneradasCall(token, page, order, field, busqueda, estado, idEncargado, responsable) {
	return axios({
		method: "get",
		url: endpoint + "requisicion/materiales/generados?" + busqueda + estado + idEncargado + responsable + "page=" + page + "&offset=10&order=" + order + '&field=' + field,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//obtener IPHs
export function getProductosPageRequisicionMaterialesCall(token, page, servicio, partida, campo, subFamilia ) {
	return axios({
		method: "get",
		url: endpoint + "requisicion/materiales/iph?" + servicio + partida + campo + subFamilia + "page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Generar Solicitud de Materiales
export function registrarRequisicionMaterialesCall(token, data ) {
	return axios({
		method: "post",
		url: endpoint + "requisicion/materiales",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Editar Solicitud de Materiales
export function editarRequisicionMaterialesCall(token, idRequisicionMateriales, data ) {
	return axios({
		method: "put",
		url: endpoint + "requisicion/materiales/update?idRequisicionMateriales=" + idRequisicionMateriales,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Cambiar estado de folio de requisición de materiales
export function cambiarEstadoRequisicionCall(token, idRequisicionMateriales, idEstado, data ) {
	return axios({
		method: "put",
		url: endpoint + "requisicion/materiales?idRequisicionMateriales=" + idRequisicionMateriales + "&idEstado=" + idEstado,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Aprobar productos del folio de requisición de materiales
export function aprobarProductosRequisicionCall(token, idRequisicionMateriales, data ) {
	return axios({
		method: "put",
		url: endpoint + "requisicion/materiales/productos/aprobado?idRequisicionMateriales=" + idRequisicionMateriales,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Descargar folio requisicion materiales
export function descargarFolioRequisicionCall(token, folio) {
	return axios({
		method: "get",
		url: endpoint + "requisicion/materiales/imprimir/folio?folio=" + folio,
		headers: {'Content-Type': 'application/json','Authorization':token},
		responseType: 'blob'
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Buscar productos por folio requisicion materiales
export function productosFolioRequisicionCall(token, folio, idAlmacen) {
	return axios({
		method: "get",
		url: endpoint + "requisicion/materiales/surtir?folio=" + folio + '&idAlmacen=' + idAlmacen,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Buscar productos por folio requisicion materiales
export function surtirProductosFolioRequisicionCall(token, idRequisicionMateriales, data) {
	return axios({
		method: "put",
		url: endpoint + "requisicion/materiales/surtir?idRequisicionMateriales=" + idRequisicionMateriales,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Reporte de existencias

export function reporteExistenciasCall(token, data ) {
	return axios({
		method: "post",
		url: endpoint + "movimientos/almacenes/reporte/existencias/dinamico",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function reporteExistenciasAgrupadoCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "movimientos/almacenes/reporte/existencias/dinamico/agrupado",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function reporteExistenciasExcelCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "movimientos/almacenes/reporte/existencias/dinamico/excel",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
		responseType: 'blob',
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function reporteExistenciasPDFCall(token, data, titulo) {
	return axios({
		method: "post",
		url: endpoint + "movimientos/almacenes/reporte/existencias/dinamico/pdf?titulo=",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
		responseType: 'blob',
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
