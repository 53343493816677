import { takeLatest, call, put } from "redux-saga/effects";
import * as RMDPActions from '../actions/RMDPActions';
import * as configuracionYSeguridadActions from '../actions/ConfiguracionYSeguridadActions';
import {
    getYearsRMDPCall,
    getMonthsRMDPCall,
    getRMDPCall,
    getReporteRMDPCall,

    // ACREDITAMIENTO DE LA PRESTACIÓN DE LOS SERVICIOS DURANTE EL PERIODO DE CONFORMIDAD CON LO DISPUESTO EN EL ANEXO 12 DEL CONTRATO
    listaGruposServiciosCall,
    crearInformacionDeTerrenoCall,
    editarInformacionDeTerrenoCall,
    obtenerArchivoMesAnteriorCall,
    obtenerArchivoMesAnteriorTerrenoCall,
    crearInfraestructuraCall,
    editarInfraestructuraCall,
    obtenerArchivoMesAnteriorInfraestructuraCall,
    obtenerArchivoMesActualTerrenoCall,
    obtenerArchivoMesActualInfraestructuraCall,
    obtenerUbicacionesInfraestructuraCall,
    crearEquipoCall,
    editarEquipoCall,
    obtenerArchivoMesAnteriorEquipoCall,
    obtenerArchivoMesActualEquipoCall,
    crearAccionesCall,
    editarAccionesCall,
    obtenerArchivoMesAnteriorAccionesCall,
    obtenerArchivoMesActualAccionesCall,
    obtenerListaFoliosErroresUOmisionesCall,
    busquedaTareasRMDPCall,
    listaServiciosByIdServicioRMDPCall,

    // Listados del (i) al (vii) enunciados en el Anexo 1, Mecanismo de Pagos
    dataSSAgrupamientoCall,
    listaReporteFallasCall,
    exportarExcelReporteFallasCall,
    exportarPDFReporteFallasCall,
    dataGraficaRelacionFallasCall,
    
    exportarFoliosErroresUOmisionesCall,
    exportarFoliosErroresUOmisionesPDFCall, 
    exportarFoliosErroresUOmisionesExcelCall,

    exportarPDFRelacionDeduccionMesCall,
    exportarExcelRelacionDeduccionMesCall,
    relacionDeduccionMesCall,

    relacionDeduccionReiteracionMesCall,
    exportarPDFRelacionDeduccionReiteracionMesCall,
    exportarEXCELRelacionDeduccionReiteracionMesCall,

    // Cálculo de Pago por Servicio para el Mes i
    //  3.1 Resumen del Pago por Servicio 
    resumenPagoServicioCall,
    exportarPDFResumenPagoServicioCall,
    exportarEXCELResumenPagoServicioCall,

    //  3.2 Resumen para el Cálculo del PMIi &  3.3 Resumen para el Cálculo del PMRi 
    exportarPDFResumenCalculoCall,
    resumenCalculoPMIyPMRCall,
    exportarEXCELResumenCalculoCall,

    //  3.4 Deducción Aplicable 
    getResumenDeduccionAplicableCall,
    pdfResumenDeduccionAplicableCall,
    excelResumenDeduccionAplicableCall,

    //  3.5 Deducción por Consumo de los Servicios Públicos del Desarrollador 
    getTipoConsumoRMDPCall,
    getTipoConsumoGeneralRMDPCall,
    pdfTipoConsumoRMDPCall,
    pdfTipoConsumoGeneralRMDPCall,
    excelTipoConsumoRMDPCall,
    excelTipoConsumoGeneralRMDPCall,
} from '../../services/RMDPServices';
import { calendarioDeProgramacionCall } from '../../services/ServiciosPlanificadosServices';

import {listaServiciosFiltroCall} from '../../services/MecanismosDePagosServices';

function* listaAniosRMDP(action) {
     try {
          console.log(action);
          const token = sessionStorage.getItem('token');
          const response = yield call(getYearsRMDPCall, token);    
     
          if (response.status === 200) {
               let listaAniosRMDP = response.data;
     
               yield put({ type: RMDPActions.ANIOS_RMDP_SUCCESS, listaAniosRMDP });
          } else {
               yield put({ type: RMDPActions.ANIOS_RMDP_FAILURE });
          }
     } catch (error) {
         yield put({ type: RMDPActions.ANIOS_RMDP_FAILURE });
     }
}
export function* listaAniosRMDPSaga() {
     yield takeLatest(RMDPActions.ANIOS_RMDP_REQUEST, listaAniosRMDP);
}

function* listaMesesRMDP(action) {
     try {
          console.log(action);
          const token = sessionStorage.getItem('token');
          const { idAnioCalendario } = action
          const response = yield call(getMonthsRMDPCall, token, idAnioCalendario);    

          console.log('listaMesesRMDP', response)
          if (response.status === 200) {
               let listaMesesRMDP = response.data;
     
               yield put({ type: RMDPActions.MESES_RMDP_SUCCESS, listaMesesRMDP });
          } else {
               yield put({ type: RMDPActions.MESES_RMDP_FAILURE });
          }
     } catch (error) {
         yield put({ type: RMDPActions.MESES_RMDP_FAILURE });
     }
}
export function* listaMesesRMDPSaga() {
     yield takeLatest(RMDPActions.MESES_RMDP_REQUEST, listaMesesRMDP);
}

function* getRMDP(action) {
     try {
          console.log(action);
          const token = sessionStorage.getItem('token');
          const response = yield call(getRMDPCall, token, action.mes, action.anio);

          if (response.status === 200) {
               let data = response.data.data[0];
               console.log("RMDP Data", data);

               yield put({ type: RMDPActions.GET_RMDP_SUCCESS, RMDPObject: data });
          } else {
               yield put({ type: RMDPActions.GET_RMDP_FAILURE });
          }
     } catch (error) {
         yield put({ type: RMDPActions.GET_RMDP_FAILURE });
     }
}
export function* getRMDPSaga() {
     yield takeLatest(RMDPActions.GET_RMDP_REQUEST, getRMDP);
}

function* getReporteRMDP(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let {idMes, anio, mesTxt} = action

        const data = {
            "informacionTerreno":true,
            "infraestructura": true,
            "informacionAdicional":true,
            "descripcionAcciones":true,
            "reporteFallas":true,
            "reporteDeducciones": true,
            "reporteDeduccionesReiteracion":true,
            "reporteErroresOmision":true,
            "reportePagoServicio":true,
            "reportePmi": true,
            "reportePmri": true,
            "reporteDa": true,
            "reportePagosPorServicioPublicoGenerales": true,
            "reportePagosPorServicioPublico": true,
            "idMes": idMes,
            "anio": anio
        }

        console.log('data', data)

        const response = yield call(getReporteRMDPCall, token, data);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el archivo de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "RMDP-" + mesTxt + anio + ".docx";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: RMDPActions.GET_REPORTE_RMDP_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: RMDPActions.GET_REPORTE_RMDP_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.GET_REPORTE_RMDP_FAILURE });
    }
}
export function* getReporteRMDPSaga(){
    yield takeLatest(RMDPActions.GET_REPORTE_RMDP_REQUEST, getReporteRMDP)
}

// ACREDITAMIENTO DE LA PRESTACIÓN DE LOS SERVICIOS DURANTE EL PERIODO DE CONFORMIDAD CON LO DISPUESTO EN EL ANEXO 12 DEL CONTRATO

// Información del Terreno
function* crearInformacionDeTerreno(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        let data = {};

        if (action.RMDPObject) {
            data = {
                "idRmdp" : action.RMDPObject.idRmdp,
                "idMes" : action.mes,
                "anio" : action.anio,
                "fechaAlta" : null,
                "informacionTerrenoByIdRmdp": {
                    "idInformacionTerreno": null,
                    "idRmdp": action.RMDPObject.idRmdp,
                    "rutaArchivo": null,
                    "fechaAlta": null,
                    "rmdpByIdRmdp": null
                },
            };
        } else {
            data = {
                "idRmdp" : null,
                "idMes" : action.mes,
                "anio" : action.anio,
                "fechaAlta" : null,
                "informacionTerrenoByIdRmdp": {
                    "idInformacionTerreno": null,
                    "idRmdp": null,
                    "rutaArchivo": null,
                    "fechaAlta": null,
                    "rmdpByIdRmdp": null
                },
            };
        }


        console.log("CrearData", data);

        const formData = new FormData();

        formData.append('data', new Blob([JSON.stringify(data)], {
            type: 'application/json'
        }));

        let dataWithoutNBSP = action.fileData.split('&nbsp;').join('');
        console.log('dataWithoutNBSP', dataWithoutNBSP)
        let re = /<br>/g;
        let fileData = '<body>' + dataWithoutNBSP.replace(re, '') + '</body>';
        fileData = fileData.split('<br />').join('');
        if(!fileData.includes('/></figure>')){
            fileData = fileData.split('></figure>').join('/></figure>');
        }
        fileData = fileData.split('</table/>').join('</table>');
        fileData = fileData.split('</oembed/>').join('</oembed>');
        console.log('fileData al editar', fileData);

        var blob = new Blob([fileData], { type: 'plain/text' });

        if(action.fileData){
            formData.append('file', blob,'informacion_de_terreno_' + action.mes + '_' + action.anio + '.txt');
        }

        const response = yield call(crearInformacionDeTerrenoCall, token, formData)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se creó la información de terreno de forma correcta";

            yield put({ type: RMDPActions.CREAR_INFORMACION_TERRENO_SUCCESS });
            yield put({ type: RMDPActions.GET_RMDP_REQUEST, mes: action.mes, anio: action.anio });
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: RMDPActions.CREAR_INFORMACION_TERRENO_FAILURE})
        }
    } catch (error) {
        yield put({ type: RMDPActions.CREAR_INFORMACION_TERRENO_FAILURE})
    }
}
export function* crearInformacionDeTerrenoSaga(){
    yield takeLatest(RMDPActions.CREAR_INFORMACION_TERRENO_REQUEST, crearInformacionDeTerreno)
}

function* editarInformacionDeTerreno(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        let data = {
                "idRmdp" : action.RMDPObject.idRmdp,
                "idMes" : action.mes,
                "anio" : action.anio,
                "fechaAlta" : null,
                "informacionTerrenoByIdRmdp": {
                    "idInformacionTerreno": action.RMDPObject.informacionTerrenoByIdRmdp.idInformacionTerreno,
                    "idRmdp": action.RMDPObject.idRmdp,
                    "rutaArchivo": null,
                    "fechaAlta": null,
                    "rmdpByIdRmdp": null
                },
            };

        console.log("EditarData", data);

        const formData = new FormData();

        formData.append('data', new Blob([JSON.stringify(data)], {
            type: 'application/json'
        }));

        let dataWithoutNBSP = action.fileData.split('&nbsp;').join('');
        console.log('dataWithoutNBSP', dataWithoutNBSP)
        let re = /<br>/g;
        let fileData = '<body>' + dataWithoutNBSP.replace(re, '') + '</body>';
        fileData = fileData.split('<br />').join('');
        if(!fileData.includes('/></figure>')){
            fileData = fileData.split('></figure>').join('/></figure>');
        }
        fileData = fileData.split('</table/>').join('</table>');
        fileData = fileData.split('</oembed/>').join('</oembed>');
        console.log('fileData al editar', fileData);

        let blob = new Blob([fileData], { type: 'plain/text' });

        if(action.fileData){
            formData.append('file', blob,'informacion_de_terreno_' + action.mes + '_' + action.anio + '.txt');
        }

        const response = yield call(editarInformacionDeTerrenoCall, token, formData, action.RMDPObject.informacionTerrenoByIdRmdp.idInformacionTerreno)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se editó la información de terreno de forma correcta";

            yield put({ type: RMDPActions.EDITAR_INFORMACION_TERRENO_SUCCESS });
            yield put({ type: RMDPActions.GET_RMDP_REQUEST, mes: action.mes, anio: action.anio });
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: RMDPActions.EDITAR_INFORMACION_TERRENO_FAILURE})
        }
    } catch (error) {
        yield put({ type: RMDPActions.EDITAR_INFORMACION_TERRENO_FAILURE})
    }
}
export function* editarInformacionDeTerrenoSaga(){
    yield takeLatest(RMDPActions.EDITAR_INFORMACION_TERRENO_REQUEST, editarInformacionDeTerreno)
}


function* obtenerArchivoMesAnteriorTerreno(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const mes = parseInt(action.mes) -1;

        const response = yield call(obtenerArchivoMesAnteriorTerrenoCall, token, mes, action.anio)
        console.log(response)

        if(response.status === 200){
            yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_TERRENO_SUCCESS, archivoMesAnteriorTerreno: response.data });
            yield put({ type: RMDPActions.GET_RMDP_REQUEST, mes: action.mes, anio: action.anio });
        } else {
            yield put({ type: RMDPActions.GET_RMDP_REQUEST, mes: action.mes, anio: action.anio });
            yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_TERRENO_FAILURE})
        }
    } catch (error) {
        yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_TERRENO_FAILURE})
    }
}
export function* obtenerArchivoMesAnteriorTerrenoSaga(){
    yield takeLatest(RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_TERRENO_REQUEST, obtenerArchivoMesAnteriorTerreno)
}


function* obtenerArchivoMesActualTerreno(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(obtenerArchivoMesActualTerrenoCall, token, action.mes, action.anio, action.nombreArchivo)
        console.log(response)

        if(response.status === 200){
            yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_TERRENO_SUCCESS, archivoMesActualTerreno: response.data });
        } else {
            yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_TERRENO_FAILURE})
        }
    } catch (error) {
        yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_TERRENO_FAILURE})
    }
}
export function* obtenerArchivoMesActualTerrenoSaga(){
    yield takeLatest(RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_TERRENO_REQUEST, obtenerArchivoMesActualTerreno)
}

// Información del Terreno
function* crearInfraestructura(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        let data = {};

        if (action.RMDPObject) {
            data = {
                "idRmdp" : action.RMDPObject.idRmdp,
                "idMes" : action.mes,
                "anio" : action.anio,
                "fechaAlta" : null,
                "infraestructuraByIdRmdp": {
                    "idInfraestructura": null,
                    "idRmdp": action.RMDPObject.idRmdp,
                    "rutaArchivo": null,
                    "fechaAlta": null,
                    "rmdpByIdRmdp": null
                },
            };
        } else {
            data = {
                "idRmdp" : null,
                "idMes" : action.mes,
                "anio" : action.anio,
                "fechaAlta" : null,
                "infraestructuraByIdRmdp": {
                    "idInfraestructura": null,
                    "idRmdp": null,
                    "rutaArchivo": null,
                    "fechaAlta": null,
                    "rmdpByIdRmdp": null
                },
            };
        }

        console.log("CrearData", data);

        const formData = new FormData();

        formData.append('data', new Blob([JSON.stringify(data)], {
            type: 'application/json'
        }));

        let dataWithoutNBSP = action.fileData.split('&nbsp;').join('');
        console.log('dataWithoutNBSP', dataWithoutNBSP)
        let re = /<br>/g;
        let fileData = '<body>' + dataWithoutNBSP.replace(re, '') + '</body>';
        fileData = fileData.split('<br />').join('');
        if(!fileData.includes('/></figure>')){
            fileData = fileData.split('></figure>').join('/></figure>');
        }
        fileData = fileData.split('</table/>').join('</table>');
        fileData = fileData.split('</oembed/>').join('</oembed>');
        console.log('fileData', fileData)

        let blob = new Blob([fileData], { type: 'plain/text' });

        if(action.fileData){
            formData.append('file', blob,'infraestructura_' + action.mes + '_' + action.anio + '.txt');
        }

        console.log('formData', formData)

        const response = yield call(crearInfraestructuraCall, token, formData)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se crearon los datos de infraestructura de forma correcta";

            yield put({ type: RMDPActions.CREAR_INFRAESTRUCTURA_SUCCESS });
            yield put({ type: RMDPActions.GET_RMDP_REQUEST, mes: action.mes, anio: action.anio });
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: RMDPActions.CREAR_INFRAESTRUCTURA_FAILURE})
        }
    } catch (error) {
        yield put({ type: RMDPActions.CREAR_INFRAESTRUCTURA_FAILURE})
    }
}
export function* crearInfraestructuraSaga(){
    yield takeLatest(RMDPActions.CREAR_INFRAESTRUCTURA_REQUEST, crearInfraestructura)
}

function* editarInfraestructura(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        let data = {
            "idRmdp" : action.RMDPObject.idRmdp,
            "idMes" : action.mes,
            "anio" : action.anio,
            "fechaAlta" : null,
            "infraestructuraByIdRmdp": {
                "idInfraestructura": action.RMDPObject.infraestructuraByIdRmdp.idInfraestructura,
                "idRmdp": action.RMDPObject.idRmdp,
                "rutaArchivo": null,
                "fechaAlta": null,
                "rmdpByIdRmdp": null
            },
        };

        console.log("EditarData", data);

        const formData = new FormData();

        formData.append('data', new Blob([JSON.stringify(data)], {
            type: 'application/json'
        }));

        let dataWithoutNBSP = action.fileData.split('&nbsp;').join('');
        console.log('dataWithoutNBSP', dataWithoutNBSP)
        let re = /<br>/g;
        let fileData = '<body>' + dataWithoutNBSP.replace(re, '') + '</body>';
        fileData = fileData.split('<br />').join('');
        if(!fileData.includes('/></figure>')){
            fileData = fileData.split('></figure>').join('/></figure>');
        }
        fileData = fileData.split('</table/>').join('</table>');
        fileData = fileData.split('</oembed/>').join('</oembed>');
        console.log('fileData al editar', fileData);

        let blob = new Blob([fileData], { type: 'plain/text' });

        if(action.fileData){
            formData.append('file', blob,'infraestructura_' + action.mes + '_' + action.anio + '.txt');
        }

        const response = yield call(editarInfraestructuraCall, token, formData, action.RMDPObject.infraestructuraByIdRmdp.idInfraestructura)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se editó la información de infraestructura de forma correcta";

            yield put({ type: RMDPActions.EDITAR_INFRAESTRUCTURA_SUCCESS });
            yield put({ type: RMDPActions.GET_RMDP_REQUEST, mes: action.mes, anio: action.anio });

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: RMDPActions.EDITAR_INFRAESTRUCTURA_FAILURE})
        }
    } catch (error) {
        yield put({ type: RMDPActions.EDITAR_INFRAESTRUCTURA_FAILURE})
    }
}
export function* editarInfraestructuraSaga(){
    yield takeLatest(RMDPActions.EDITAR_INFRAESTRUCTURA_REQUEST, editarInfraestructura)
}


function* obtenerArchivoMesAnteriorInfraestructura(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const mes = parseInt(action.mes) -1;

        const response = yield call(obtenerArchivoMesAnteriorInfraestructuraCall, token, mes, action.anio)
        console.log(response)

        if(response.status === 200){
            yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_INFRAESTRUCTURA_SUCCESS, archivoMesAnteriorInfraestructura: response.data });
            yield put({ type: RMDPActions.GET_RMDP_REQUEST, mes: action.mes, anio: action.anio });
        } else {
            yield put({ type: RMDPActions.GET_RMDP_REQUEST, mes: action.mes, anio: action.anio });
            yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_INFRAESTRUCTURA_FAILURE})
        }
    } catch (error) {
        yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_INFRAESTRUCTURA_FAILURE})
    }
}
export function* obtenerArchivoMesAnteriorInfraestructuraSaga(){
    yield takeLatest(RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_INFRAESTRUCTURA_REQUEST, obtenerArchivoMesAnteriorInfraestructura)
}


function* obtenerArchivoMesActualInfraestructura(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(obtenerArchivoMesActualInfraestructuraCall, token, action.mes, action.anio, action.nombreArchivo)
        console.log(response)

        if(response.status === 200){
            yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_INFRAESTRUCTURA_SUCCESS, archivoMesActualInfraestructura: response.data });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            // yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_INFRAESTRUCTURA_FAILURE})
        }
    } catch (error) {
        yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_INFRAESTRUCTURA_FAILURE})
    }
}
export function* obtenerArchivoMesActualInfraestructuraSaga(){
    yield takeLatest(RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_INFRAESTRUCTURA_REQUEST, obtenerArchivoMesActualInfraestructura)
}

function* obtenerUbicacionesInfraestructura(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page -1;

        const response = yield call(obtenerUbicacionesInfraestructuraCall, token, action.idMes, action.anio, page)
        console.log(response)

        if(response.status === 200){
            let ubicacionesInfraestructura = response.data;
            const ubicacionesInfraestructuraCantidad = response.data.cantidad;
            const columns = [
                {title: 'Código de área', dataIndex: 'codigoArea', key:'codigoArea', align:'center'},
                {title: 'Nombre de área', dataIndex: 'nombreArea', key:'nombreArea', align:'center', width: '35%'},
                {title: 'Peso de área', dataIndex: 'pesoArea', key:'pesoArea', align:'right'},
                {title: 'Categoría de tiempo de reahabilitación', dataIndex: 'categoria', key:'categoria', align:'right'},
                {title: 'Número de turnos', dataIndex: 'turnos', key:'turnos', align:'right'},
                // {title: 'Factor de seguridad', dataIndex: 'factorSeguridad', key:'factorSeguridad', align:'center'},
                {title: 'Factor 1', dataIndex: 'factor1Tabla', key:'factor1Tabla', align:'right', width: '8%'},
                {title: 'Factor 2', dataIndex: 'factor2Tabla', key:'factor2Tabla', align:'right', width: '8%'},
                {title: 'Factor 3', dataIndex: 'factor3Tabla', key:'factor3Tabla', align:'right', width: '8%'},

            ];

            for(let i in ubicacionesInfraestructura.data){
                ubicacionesInfraestructura.data[i].pesoArea = ubicacionesInfraestructura.data[i].pesoArea.toFixed(4) + ' %';
                //ubicacionesInfraestructura.data[i].factorSeguridad = parseInt(ubicacionesInfraestructura.data[i].factor2).toFixed(1) + ' %';

                if(ubicacionesInfraestructura.data[i].areaFuncionalByIdAreaFuncional){
                    ubicacionesInfraestructura.data[i].codigoArea = ubicacionesInfraestructura.data[i].areaFuncionalByIdAreaFuncional.codigoArea;
                    ubicacionesInfraestructura.data[i].nombreArea = ubicacionesInfraestructura.data[i].areaFuncionalByIdAreaFuncional.areaFuncional;
                }

                if(ubicacionesInfraestructura.data[i].fallaCategoriaByIdFallaCategoria){
                    ubicacionesInfraestructura.data[i].tiempoRehabilitacion = ubicacionesInfraestructura.data[i].fallaCategoriaByIdFallaCategoria.fallaCategoria;
                    ubicacionesInfraestructura.data[i].categoria = ubicacionesInfraestructura.data[i].fallaCategoriaByIdFallaCategoria.numeroFallaCategoria
                    if(ubicacionesInfraestructura.data[i].fallaCategoriaByIdFallaCategoria.unidadMedidaByIdUnidadMedida){
                        ubicacionesInfraestructura.data[i].tiempoRehabilitacion += ' ' + ubicacionesInfraestructura.data[i].fallaCategoriaByIdFallaCategoria.unidadMedidaByIdUnidadMedida.abreviacion;
                    }
                }

                if(ubicacionesInfraestructura.data[i].factor1Activo){ ubicacionesInfraestructura.data[i].factor1Tabla = ubicacionesInfraestructura.data[i].factor1 + '*'; }
                else { ubicacionesInfraestructura.data[i].factor1Tabla = ubicacionesInfraestructura.data[i].factor1; }

                if(ubicacionesInfraestructura.data[i].factor2Activo){ ubicacionesInfraestructura.data[i].factor2Tabla = ubicacionesInfraestructura.data[i].factor2 + '*'; }
                else { ubicacionesInfraestructura.data[i].factor2Tabla = ubicacionesInfraestructura.data[i].factor2; }

                if(ubicacionesInfraestructura.data[i].factor3Activo){ ubicacionesInfraestructura.data[i].factor3Tabla = ubicacionesInfraestructura.data[i].factor3 + '*'; }
                else { ubicacionesInfraestructura.data[i].factor3Tabla = ubicacionesInfraestructura.data[i].factor3; }
            }


            ubicacionesInfraestructura.columns = columns;

            yield put({ type: RMDPActions.OBTENER_UBICACIONES_INFRAESTRUCTURA_SUCCESS, ubicacionesInfraestructura, ubicacionesInfraestructuraCantidad });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: RMDPActions.OBTENER_UBICACIONES_INFRAESTRUCTURA_FAILURE})
        }
    } catch (error) {
        yield put({ type: RMDPActions.OBTENER_UBICACIONES_INFRAESTRUCTURA_FAILURE})
    }
}
export function* obtenerUbicacionesInfraestructuraSaga(){
    yield takeLatest(RMDPActions.OBTENER_UBICACIONES_INFRAESTRUCTURA_REQUEST, obtenerUbicacionesInfraestructura)
}

// Equipo
function* crearEquipo(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        let data = {};

        if (action.RMDPObject) {
            data = {
                "idRmdp" : action.RMDPObject.idRmdp,
                "idMes" : action.mes,
                "anio" : action.anio,
                "fechaAlta" : null,
                "informacionAdicionalServicioByIdRmdp": {
                    "idInformacionAdicionalServicio": null,
                    "idRmdp": action.RMDPObject.idRmdp,
                    "rutaArchivo": null,
                    "fechaAlta": null,
                    "rmdpByIdRmdp": null,
                    "idServicio": action.idServicio,
                    "idPartida": action.idPartida
                },
            };
        } else {
            data = {
                "idRmdp" : null,
                "idMes" : action.mes,
                "anio" : action.anio,
                "fechaAlta" : null,
                "informacionAdicionalServicioByIdRmdp": {
                    "idInformacionAdicionalServicio": null,
                    "idRmdp": null,
                    "rutaArchivo": null,
                    "fechaAlta": null,
                    "rmdpByIdRmdp": null,
                    "idServicio": action.idServicio,
                    "idPartida": action.idPartida
                },
            };
        }


        let formData = new FormData();

        formData.append('data', new Blob([JSON.stringify(data)], {
            type: 'application/json'
        }));

        let dataWithoutNBSP = action.fileData.split('&nbsp;').join('');
        console.log('dataWithoutNBSP', dataWithoutNBSP)
        let re = /<br>/g;
        let fileData = '<body> <h2>' + action.etiqueta + '</h2>' + dataWithoutNBSP.replace(re, '<br />') + '</body>';
        fileData = fileData.split('<br />').join('');
        if(!fileData.includes('/></figure>')){
            fileData = fileData.split('></figure>').join('/></figure>');
        }
        fileData = fileData.split('</table/>').join('</table>');
        fileData = fileData.split('</oembed/>').join('</oembed>');
        console.log('fileData', fileData)

        let blob = new Blob([fileData], { type: 'plain/text' });

        if(action.fileData){
            if(action.idPartida){
                formData.append('file', blob,'equipo_Ser-' + action.idServicio + '_Par-' + action.idPartida + '_' + action.mes + '_' + action.anio +'.txt');
            }else{
                formData.append('file', blob,'equipo_Ser-' + action.idServicio + '_' + action.mes + '_' + action.anio +'.txt');
            }
        }

        console.log('formData', formData)

        const response = yield call(crearEquipoCall, token, formData)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se crearon los datos de equipo de forma correcta";

            yield put({ type: RMDPActions.CREAR_EQUIPO_SUCCESS });
            yield put({ type: RMDPActions.GET_RMDP_REQUEST, mes: action.mes, anio: action.anio });

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: RMDPActions.CREAR_EQUIPO_FAILURE})
        }
    } catch (error) {
        yield put({ type: RMDPActions.CREAR_EQUIPO_FAILURE})
    }
}
export function* crearEquipoSaga(){
    yield takeLatest(RMDPActions.CREAR_EQUIPO_REQUEST, crearEquipo)
}

function* editarEquipo(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let data = {};

        if(action.RMDPObject.informacionAdicionalServicioList[0]){
            for(let i in action.RMDPObject.informacionAdicionalServicioList){
                if(action.idPartida){
                    if(parseInt(action.idServicio) === action.RMDPObject.informacionAdicionalServicioList[i].idServicio && parseInt(action.idPartida) === parseInt(action.idServicio) === action.RMDPObject.informacionAdicionalServicioList[i].idPartida){
                        data = {
                            "idRmdp" : action.RMDPObject.idRmdp,
                            "idMes" : action.mes,
                            "anio" : action.anio,
                            "fechaAlta" : null,
                            "informacionAdicionalServicioByIdRmdp": {
                                "idInformacionAdicionalServicio": action.RMDPObject.informacionAdicionalServicioList[i].idInformacionAdicionalServicio,
                                "idRmdp": action.RMDPObject.idRmdp,
                                "rutaArchivo": null,
                                "fechaAlta": null,
                                "rmdpByIdRmdp": null,
                                "idServicio": action.idServicio,
                                "idPartida": action.idPartida
                            },
                        };
                    }
                } else {
                    if(parseInt(action.idServicio) === action.RMDPObject.informacionAdicionalServicioList[i].idServicio){
                        data = {
                            "idRmdp" : action.RMDPObject.idRmdp,
                            "idMes" : action.mes,
                            "anio" : action.anio,
                            "fechaAlta" : null,
                            "informacionAdicionalServicioByIdRmdp": {
                                "idInformacionAdicionalServicio": action.RMDPObject.informacionAdicionalServicioList[i].idInformacionAdicionalServicio,
                                "idRmdp": action.RMDPObject.idRmdp,
                                "rutaArchivo": null,
                                "fechaAlta": null,
                                "rmdpByIdRmdp": null,
                                "idServicio": action.idServicio,
                                "idPartida": action.idPartida
                            },
                        };
                    }
                }
            }
        }

        console.log("EditarData", data);

        const formData = new FormData();

        formData.append('data', new Blob([JSON.stringify(data)], {
            type: 'application/json'
        }));

        let dataWithoutNBSP = action.fileData.split('&nbsp;').join('');
        console.log('dataWithoutNBSP', dataWithoutNBSP)
        let re = /<br>/g;
        let fileData = '<body>' + dataWithoutNBSP.replace(re, '<br />') + '</body>';
        fileData = fileData.split('<br />').join('');
        if(!fileData.includes('/></figure>')){
            fileData = fileData.split('></figure>').join('/></figure>');
        }
        fileData = fileData.split('</table/>').join('</table>');
        fileData = fileData.split('</oembed/>').join('</oembed>');
        console.log('fileData', fileData)

        let blob = new Blob([fileData], { type: 'plain/text' });

        if(action.fileData){
            if(action.idPartida){
                console.log('Opc partida');
                formData.append('file', blob,'equipo_Ser-' + action.idServicio + '_Par-' + action.idPartida + '_' + action.mes + '_' + action.anio +'.txt');
            }else{
                console.log('Opc servicio');
                formData.append('file', blob,'equipo_Ser-' + action.idServicio + '_' + action.mes + '_' + action.anio +'.txt');
            }
        }

        const response = yield call(editarEquipoCall, token, formData, data.informacionAdicionalServicioByIdRmdp.idInformacionAdicionalServicio)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se editó la información de equipo de forma correcta";

            yield put({ type: RMDPActions.EDITAR_EQUIPO_SUCCESS });
            yield put({ type: RMDPActions.GET_RMDP_REQUEST, mes: action.mes, anio: action.anio });

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: RMDPActions.EDITAR_EQUIPO_FAILURE})
        }
    } catch (error) {
        yield put({ type: RMDPActions.EDITAR_EQUIPO_FAILURE})
    }
}
export function* editarEquipoSaga(){
    yield takeLatest(RMDPActions.EDITAR_EQUIPO_REQUEST, editarEquipo)
}

function* listaGruposServiciosForRMDP(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const response = yield call(listaGruposServiciosCall, token);
        console.log('response', response)
        if (response.status === 200) {
            let listaGruposServiciosRMDP = response.data;
            
            yield put({ type: RMDPActions.LISTA_GRUPOS_SERVICIOS_RDMP_SUCCESS, listaGruposServiciosRMDP });
        } else {
            yield put({ type: RMDPActions.LISTA_GRUPOS_SERVICIOS_RDMP_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.LISTA_GRUPOS_SERVICIOS_RDMP_FAILURE });
    }
}
export function* listaGruposServiciosForRMDPSaga() {
    yield takeLatest(RMDPActions.LISTA_GRUPOS_SERVICIOS_RDMP_REQUEST, listaGruposServiciosForRMDP);
}

function* obtenerArchivoMesAnteriorEquipo(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(obtenerArchivoMesAnteriorEquipoCall, token, parseInt(action.mes) - 1, action.anio, action.idServicio, action.idPartida)
        console.log('response obtener equipo', response)

        if(response.status === 200){
            let archivoMesAnteriorEquipo = response.data ? response.data : `
                <h3>Niveles de Mantenimiento</h3>
                <br />
                <h3>Conclusión</h3>
                <br />
            `;
            yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_EQUIPO_SUCCESS, archivoMesAnteriorEquipo: archivoMesAnteriorEquipo });
            yield put({ type: RMDPActions.GET_RMDP_REQUEST, mes: action.mes, anio: action.anio });
        } else {
            console.log('response.message', response.message)
            // let archivoMesAnteriorEquipo = response.message === 'No existe archivo de informacion adicional de equipo del mes i-1' ? 
            // `
            // <h3>Niveles de Mantenimiento</h3>
            // <br />
            // <h3>Conclusión</h3>
            // <br />
            // `
            // : yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg: response.message !== 'No existe archivo de informacion adicional de equipo del mes i-1', textMessage: response.message });

            let archivoMesAnteriorEquipo = '';
            if(response.message === 'No existe archivo de informacion adicional de equipo del mes i-1' || response.message === 'Sin información adicional de equipo del mes i-1'){
                console.log('Entra a if 500')
                archivoMesAnteriorEquipo = `
                <h3>Niveles de Mantenimiento</h3>
                <br />
                <h3>Conclusión</h3>
                <br />
                `
            }

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg: response.message !== 'No existe archivo de informacion adicional de equipo del mes i-1' && response.message !== 'Sin información adicional de equipo del mes i-1', textMessage: response.message });
            yield put({ type: RMDPActions.GET_RMDP_REQUEST, mes: action.mes, anio: action.anio });
            yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_EQUIPO_FAILURE, archivoMesAnteriorEquipo: archivoMesAnteriorEquipo})
        }
    } catch (error) {
        yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_EQUIPO_FAILURE})
    }
}
export function* obtenerArchivoMesAnteriorEquipoSaga(){
    yield takeLatest(RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_EQUIPO_REQUEST, obtenerArchivoMesAnteriorEquipo)
}

function* obtenerArchivoMesActualEquipo(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(obtenerArchivoMesActualEquipoCall, token, action.mes, action.anio, action.nombreArchivo, action.idServicio, action.idPartida)
        console.log(response)

        if(response.status === 200){
            yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_EQUIPO_SUCCESS, archivoMesActualEquipo: response.data });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            // yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_EQUIPO_FAILURE, archivoMesActualEquipo: null})
        }
    } catch (error) {
        yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_EQUIPO_FAILURE})
    }
}
export function* obtenerArchivoMesActualEquipoSaga(){
    yield takeLatest(RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_EQUIPO_REQUEST, obtenerArchivoMesActualEquipo)
}

function* busquedaTareasRMDP(action) {
    try {
        console.log(action);
        const page = action.page - 1;
        const idServicio = action.idServicio;
        const token = sessionStorage.getItem('token');
        let dias = new Date(action.anio, action.mes, 0).getDate();
        let fechaInicio = action.anio + '-' + action.mes + '-1'; 
        let fechaFin = action.anio + '-' + action.mes + '-' + dias;

        const response = yield call(busquedaTareasRMDPCall, token, idServicio, fechaInicio, fechaFin, page);
        console.log('response', response);
        if (response.status === 200) {
            let busquedaTareasRMDP = response.data.data;
            let dataPrimeraTabla= [];
            let listaIdActCaract = [];
            busquedaTareasRMDP.forEach(function(elemento) { listaIdActCaract.push(elemento.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.idActivoCaract); });
            let uniqueIds = [...new Set(listaIdActCaract)];

            console.log('listaIdActCaract', listaIdActCaract)
            console.log('uniqueIds', uniqueIds)

            let repetidos = {};

            listaIdActCaract.forEach(function(numero){
                repetidos[numero] = (repetidos[numero] || 0) + 1;
            });

                console.log('repetidos', repetidos);

            for(let i in busquedaTareasRMDP){
                if(busquedaTareasRMDP[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado){
                    if(busquedaTareasRMDP[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo){
                        if(busquedaTareasRMDP[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.activoCaractByIdActivoCaract){
                            busquedaTareasRMDP[i].caracteristicaActivo = busquedaTareasRMDP[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.activoCaractByIdActivoCaract.descripcion;

                            if(busquedaTareasRMDP[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia){
                                busquedaTareasRMDP[i].subfamilia = busquedaTareasRMDP[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia.subFamilia;

                                if(busquedaTareasRMDP[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia.familiaByIdFamilia){
                                    busquedaTareasRMDP[i].familia = busquedaTareasRMDP[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia.familiaByIdFamilia.familia;
                                }
                            }
                        }
                    }
                }
            }



            for(let j in uniqueIds){
                for(let i in busquedaTareasRMDP){ 
                    if(uniqueIds[j] === busquedaTareasRMDP[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.idActivoCaract){
                        console.log('entra al if');
                        dataPrimeraTabla.push({
                            cantidad: repetidos[uniqueIds[j]], 
                            idActivoCaract: uniqueIds[j],
                            familia: busquedaTareasRMDP[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia.familiaByIdFamilia.familia
                        }); 
                    }
                }
            }

            console.log('dataPrimeraTabla', dataPrimeraTabla)

            yield put({ type: RMDPActions.BUSCAR_TAREAS_RMDP_SUCCESS, busquedaTareasRMDP });
        } else {
            yield put({ type: RMDPActions.BUSCAR_TAREAS_RMDP_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.BUSCAR_TAREAS_RMDP_FAILURE });
    }
}
export function* busquedaTareasRMDPSaga() {
    yield takeLatest(RMDPActions.BUSCAR_TAREAS_RMDP_REQUEST, busquedaTareasRMDP);
}

function* listaServiciosByIdServicioRMDP(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const idServicio = action.idServicio

        const response = yield call(listaServiciosByIdServicioRMDPCall, token, idServicio, page);
        console.log('response', response)
        if (response.status === 200) {
            let listaServiciosByIdServicioCount = response.data.data;
            const listaServiciosByIdServicioCountCantidad = response.data.cantidad;


            yield put({ type: RMDPActions.LISTA_SERVICIOS_BYIDSERVICIO_COUNT_SUCCESS, listaServiciosByIdServicioCount, listaServiciosByIdServicioCountCantidad });
        } else {
            yield put({ type: RMDPActions.LISTA_SERVICIOS_BYIDSERVICIO_COUNT_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.LISTA_SERVICIOS_BYIDSERVICIO_COUNT_FAILURE });
    }
}
export function* listaServiciosByIdServicioRMDPSaga() {
    yield takeLatest(RMDPActions.LISTA_SERVICIOS_BYIDSERVICIO_COUNT_REQUEST, listaServiciosByIdServicioRMDP);
}

// Acciones
function* crearAcciones(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        let data = {};

        if (action.RMDPObject) {
            data = {
                "idRmdp" : action.RMDPObject.idRmdp,
                "idMes" : action.mes,
                "anio" : action.anio,
                "fechaAlta" : null,
                "descripcionAccionesByIdRmdp": {
                    "idDescripcionAccion": null,
                    "idRmdp": action.RMDPObject.idRmdp,
                    "rutaArchivo": null,
                    "fechaAlta": null,
                    "rmdpByIdRmdp": null
                },
            };
        } else {
            data = {
                "idRmdp" : null,
                "idMes" : action.mes,
                "anio" : action.anio,
                "fechaAlta" : null,
                "descripcionAccionesByIdRmdp": {
                    "idDescripcionAccion": null,
                    "idRmdp": null,
                    "rutaArchivo": null,
                    "fechaAlta": null,
                    "rmdpByIdRmdp": null
                },
            };
        }

        console.log("CrearData", data);

        const formData = new FormData();

        formData.append('data', new Blob([JSON.stringify(data)], {
            type: 'application/json'
        }));

        let dataWithoutNBSP = action.fileData.split('&nbsp;').join('');
        console.log('dataWithoutNBSP', dataWithoutNBSP)
        let re = /<br>/g;
        let fileData = '<body>' + dataWithoutNBSP.replace(re, '<br />') + '</body>';
        fileData = fileData.split('<br />').join('');
        if(!fileData.includes('/></figure>')){
            fileData = fileData.split('></figure>').join('/></figure>');
        } 
        fileData = fileData.split('</table/>').join('</table>');
        fileData = fileData.split('</oembed/>').join('</oembed>');
        console.log('fileData', fileData)

        let blob = new Blob([fileData], { type: 'plain/text' });

        if(action.fileData){
            formData.append('file', blob,'acciones_' + action.mes + '_' + action.anio + '.txt');
        }

        console.log('formData', formData)

        const response = yield call(crearAccionesCall, token, formData)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se crearon las acciones de forma correcta";

            yield put({ type: RMDPActions.CREAR_ACCIONES_SUCCESS });
            yield put({ type: RMDPActions.GET_RMDP_REQUEST, mes: action.mes, anio: action.anio });

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: RMDPActions.CREAR_ACCIONES_FAILURE})
        }
    } catch (error) {
        yield put({ type: RMDPActions.CREAR_ACCIONES_FAILURE})
    }
}
export function* crearAccionesSaga(){
    yield takeLatest(RMDPActions.CREAR_ACCIONES_REQUEST, crearAcciones)
}

function* editarAcciones(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        let data = {
            "idRmdp" : action.RMDPObject.idRmdp,
            "idMes" : action.mes,
            "anio" : action.anio,
            "fechaAlta" : null,
            "descripcionAccionesByIdRmdp": {
                "idDescripcionAccion": action.RMDPObject.descripcionAccionesByIdRmdp.idDescripcionAccion,
                "idRmdp": action.RMDPObject.idRmdp,
                "rutaArchivo": null,
                "fechaAlta": null,
                "rmdpByIdRmdp": null
            },
        };

        console.log("EditarData", data);

        const formData = new FormData();

        formData.append('data', new Blob([JSON.stringify(data)], {
            type: 'application/json'
        }));

        let dataWithoutNBSP = action.fileData.split('&nbsp;').join('');
        let re = /<br>/g;
        let fileData = '<body>' + dataWithoutNBSP.replace(re, '<br />') + '</body>';
        fileData = fileData.split('<br />').join('');
        if(!fileData.includes('/></figure>')){
            fileData = fileData.split('></figure>').join('/></figure>');
        }
        fileData = fileData.split('</table/>').join('</table>');
        fileData = fileData.split('</oembed/>').join('</oembed>');
        console.log('fileData', fileData)

        let blob = new Blob([fileData], { type: 'plain/text' });

        if(action.fileData){
            formData.append('file', blob,'acciones_' + action.mes + '_' + action.anio + '.txt');
        }

        const response = yield call(editarAccionesCall, token, formData, action.RMDPObject.descripcionAccionesByIdRmdp.idDescripcionAccion)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se editaron las acciones de forma correcta";

            yield put({ type: RMDPActions.EDITAR_ACCIONES_SUCCESS });
            yield put({ type: RMDPActions.GET_RMDP_REQUEST, mes: action.mes, anio: action.anio });

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: RMDPActions.EDITAR_ACCIONES_FAILURE})
        }
    } catch (error) {
        yield put({ type: RMDPActions.EDITAR_ACCIONES_FAILURE})
    }
}
export function* editarAccionesSaga(){
    yield takeLatest(RMDPActions.EDITAR_ACCIONES_REQUEST, editarAcciones)
}

function* obtenerArchivoMesAnteriorAcciones(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const mes = parseInt(action.mes) -1;

        const response = yield call(obtenerArchivoMesAnteriorAccionesCall, token, mes, action.anio)
        console.log('obtenerArchivoMesAnteriorAcciones', response)

        if(response.status === 200){

            yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_ACCIONES_SUCCESS, archivoMesAnteriorAcciones: response.data });
            yield put({ type: RMDPActions.GET_RMDP_REQUEST, mes: action.mes, anio: action.anio });
        } else {
            yield put({ type: RMDPActions.GET_RMDP_REQUEST, mes: action.mes, anio: action.anio });
            yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_ACCIONES_FAILURE})
        }
    } catch (error) {
        yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_ACCIONES_FAILURE})
    }
}
export function* obtenerArchivoMesAnteriorAccionesSaga(){
    yield takeLatest(RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_ACCIONES_REQUEST, obtenerArchivoMesAnteriorAcciones)
}

function* obtenerArchivoMesActualAcciones(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(obtenerArchivoMesActualAccionesCall, token, action.mes, action.anio, action.nombreArchivo)
        console.log('response mes actual', response)

        if(response.status === 200){
            yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_ACCIONES_SUCCESS, archivoMesActualAcciones: response.data });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            // yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_ACCIONES_FAILURE})
        }
    } catch (error) {
        yield put({ type: RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_ACCIONES_FAILURE})
    }
}
export function* obtenerArchivoMesActualAccionesSaga(){
    yield takeLatest(RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_ACCIONES_REQUEST, obtenerArchivoMesActualAcciones)
}

// Lista folios errores u omisiones
function* listaFoliosErroresUOmisiones(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const { mes, anio, field, order, idTipoFalla } = action;
        const page = action.page - 1;

        let formData = new FormData();
        formData.append('idsTipoFallas', new Blob([JSON.stringify(idTipoFalla)], {
            type: "application/json"
        }));

        const response = yield call(obtenerListaFoliosErroresUOmisionesCall, token, mes, anio, page, field, order, formData);
        console.log('response', response)
        if (response.status === 200) {
            let listaFoliosErroresOmisiones = response.data.data;
            const listaFoliosErroresOmisionesCantidad = response.data.cantidad;

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            for(let i in listaFoliosErroresOmisiones){
                if(listaFoliosErroresOmisiones[i].tipoFolio === 'Error'){
                    if(listaFoliosErroresOmisiones[i].solicitudServicioByIdSolicitudServicio){
                        listaFoliosErroresOmisiones[i].noTicket = listaFoliosErroresOmisiones[i].solicitudServicioByIdSolicitudServicio.folio;
                    }
                } else  { listaFoliosErroresOmisiones[i].noTicket = '-' }

                if(listaFoliosErroresOmisiones[i].deductivaCierreByIdFolioErrorOmision){
                    listaFoliosErroresOmisiones[i].deduccion = numberWithCommas(listaFoliosErroresOmisiones[i].deductivaCierreByIdFolioErrorOmision.deductiva.toFixed(2));
                } else {
                    listaFoliosErroresOmisiones[i].deduccion = '-'
                }

                let fechaRegistro = listaFoliosErroresOmisiones[i].fechaRegistroErrorOmision.split('T')[0];
                let horaRegistro = listaFoliosErroresOmisiones[i].fechaRegistroErrorOmision.split('T')[1];
                listaFoliosErroresOmisiones[i].fechaRegistro = fechaRegistro.split('-')[2] + '-' + fechaRegistro.split('-')[1] + '-' + fechaRegistro.split('-')[0];

                let fechaCierre = listaFoliosErroresOmisiones[i].fechaCierreCorrecta.split('T')[0];
                let horaCierre = listaFoliosErroresOmisiones[i].fechaCierreCorrecta.split('T')[1];
                listaFoliosErroresOmisiones[i].fechaCierreTabla = fechaCierre.split('-')[2] + '-' + fechaCierre.split('-')[1] + '-' + fechaCierre.split('-')[0] + ' ' + horaCierre.split(':')[0] + ':' + horaCierre.split(':')[1];

                if(listaFoliosErroresOmisiones[i].tipoFolio === 'Error'){
                    if(listaFoliosErroresOmisiones[i].solicitudServicioByIdSolicitudServicio){
                        let fechaApertura = listaFoliosErroresOmisiones[i].solicitudServicioByIdSolicitudServicio.fechaAlta.split('T')[0];
                        let horaApertura = listaFoliosErroresOmisiones[i].solicitudServicioByIdSolicitudServicio.fechaAlta.split('T')[1];
                        listaFoliosErroresOmisiones[i].fechaAperturaTabla = fechaApertura.split('-')[2] + '-' + fechaApertura.split('-')[1] + '-' + fechaApertura.split('-')[0] + ' ' + horaApertura.split(':')[0] + ':' + horaApertura.split(':')[1];
                    }
                } else {
                    let fechaApertura = listaFoliosErroresOmisiones[i].fechaRegistroErrorOmision.split('T')[0];
                    let horaApertura = listaFoliosErroresOmisiones[i].fechaRegistroErrorOmision.split('T')[1];
                    listaFoliosErroresOmisiones[i].fechaAperturaTabla = fechaApertura.split('-')[2] + '-' + fechaApertura.split('-')[1] + '-' + fechaApertura.split('-')[0] + ' ' + horaApertura.split(':')[0] + ':' + horaApertura.split(':')[1];
                }
            }

            yield put({ type: RMDPActions.LISTA_FOLIOS_ERRORES_OMISIONES_SUCCESS, listaFoliosErroresOmisiones, listaFoliosErroresOmisionesCantidad });
        } else {
            yield put({ type: RMDPActions.LISTA_FOLIOS_ERRORES_OMISIONES_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.LISTA_FOLIOS_ERRORES_OMISIONES_FAILURE });
    }
}
export function* listaFoliosErroresUOmisionesSaga() {
    yield takeLatest(RMDPActions.LISTA_FOLIOS_ERRORES_OMISIONES_REQUEST, listaFoliosErroresUOmisiones);
}

function* exportarFoliosErroresUOmisionesPDF(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const response = yield call(exportarFoliosErroresUOmisionesPDFCall, token, action.mes, action.anio);
        console.log('response', response)
        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "RelacionDeduccionesEOI-" + action.mesTxt + action.anio + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: RMDPActions.EXPORTAR_FOLIOS_ERRORES_OMISIONES_PDF_SUCCESS });
        } else {
            yield put({ type: RMDPActions.EXPORTAR_FOLIOS_ERRORES_OMISIONES_PDF_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.EXPORTAR_FOLIOS_ERRORES_OMISIONES_PDF_FAILURE });
    }
}
export function* exportarFoliosErroresUOmisionesPDFSaga() {
    yield takeLatest(RMDPActions.EXPORTAR_FOLIOS_ERRORES_OMISIONES_PDF_REQUEST, exportarFoliosErroresUOmisionesPDF);
}

function* exportarFoliosErroresUOmisionesExcel(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const response = yield call(exportarFoliosErroresUOmisionesExcelCall, token, action.mes, action.anio);
        console.log('response', response)
        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "RelacionDeduccionesEOI-" + action.mesTxt + action.anio + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: RMDPActions.EXPORTAR_FOLIOS_ERRORES_OMISIONES_EXCEL_SUCCESS });
        } else {
            yield put({ type: RMDPActions.EXPORTAR_FOLIOS_ERRORES_OMISIONES_EXCEL_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.EXPORTAR_FOLIOS_ERRORES_OMISIONES_EXCEL_FAILURE });
    }
}
export function* exportarFoliosErroresUOmisionesExcelSaga() {
    yield takeLatest(RMDPActions.EXPORTAR_FOLIOS_ERRORES_OMISIONES_EXCEL_REQUEST, exportarFoliosErroresUOmisionesExcel);
}

// Listados del (i) al (vii) enunciados en el Anexo 1, Mecanismo de Pagos
function* dataSSforRMDPAgrupamiento(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {order, field} = action.order;
        const agrupamiento = action.agrupamiento ? action.agrupamiento: null;
        const anio = parseInt(action.anio);
        const diasDelMes = new Date(anio, action.idMes, 0).getDate();
        let mesFechaInicio = new Date(anio, action.idMes -1, 1)
        let mesFechaFin = new Date(anio, action.idMes -1, diasDelMes);

        console.log('mesFechaInicio', mesFechaInicio.toISOString().slice(0,10).replace(/-/g,"-"));
        console.log('mesFechaFin', mesFechaFin.toISOString().slice(0,10).replace(/-/g,"-"));
        
        const data = {
            "idsServicios": null,
            "idGruposSer": null,
            "idAreaFuncional":null,
            "idUnidadFuncional":null,
            "idInteriorFuncional":null,
            "queja": null,
            "usuarioSolicito":null,
            "usuarioRegistro":null,
            "fechaAltaInicio": mesFechaInicio.toISOString().slice(0,10).replace(/-/g,"-"),
            "fechaAltaFin": mesFechaFin.toISOString().slice(0,10).replace(/-/g,"-"),
            "fechaCierreInicio": null,
            "fechaCierreFin": null,
            "fechaTerminacionInicio": null,
            "fechaTerminacionFin": null,
            "estado": null,
            "page": null,
            "offset": null,
            "order":order,
            "field": field,
            "agrupamiento": agrupamiento
        }

        let response = yield call(dataSSAgrupamientoCall, token, data);
        console.log('response', response);

        if (response.status == 200) {
            let dataSSAgrupamiento = response.data.data;
            const dataSSAgrupamientoCantidad = response.data.cantidad;
    
            yield put({ type: RMDPActions.DATA_SS_AGRUPAMIENTO_SUCCESS, dataSSAgrupamiento, dataSSAgrupamientoCantidad });
        }
        else {
            yield put({ type: RMDPActions.DATA_SS_AGRUPAMIENTO_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: RMDPActions.DATA_SS_AGRUPAMIENTO_FAILURE });
    }
} export function* dataSSforRMDPAgrupamientoSaga() {
     yield takeLatest(RMDPActions.DATA_SS_AGRUPAMIENTO_REQUEST, dataSSforRMDPAgrupamiento)
}

function* dataGraficaRelacionFallas(action) {
    try {
         console.log(action);
         const token = sessionStorage.getItem('token');
         const {order, field} = action.order;
         const agrupamiento = action.agrupamiento ? action.agrupamiento: null;
         const anio = parseInt(action.anio);
         const diasDelMes = new Date(anio, action.idMes, 0).getDate();
         let mesFechaInicio = new Date(anio, action.idMes -1, 1)
         let mesFechaFin = new Date(anio, action.idMes -1, diasDelMes);

         console.log('mesFechaInicio', mesFechaInicio.toISOString().slice(0,10).replace(/-/g,"-"));
         console.log('mesFechaFin', mesFechaFin.toISOString().slice(0,10).replace(/-/g,"-"));
        
        const data = {
            "idsServicios": null,
            "idGruposSer": null,
            "idAreaFuncional":null,
            "idUnidadFuncional":null,
            "idInteriorFuncional":null,
            "queja": null,
            "usuarioSolicito":null,
            "usuarioRegistro":null,
            "fechaAltaInicio": mesFechaInicio.toISOString().slice(0,10).replace(/-/g,"-"),
            "fechaAltaFin": mesFechaFin.toISOString().slice(0,10).replace(/-/g,"-"),
            "fechaCierreInicio": null,
            "fechaCierreFin": null,
            "fechaTerminacionInicio": null,
            "fechaTerminacionFin": null,
            "estado": null,
            "page": null,
            "offset": null,
            "order":order,
            "field": field,
            "agrupamiento": agrupamiento
        }

        let response = yield call(dataGraficaRelacionFallasCall, token, data);
        console.log('response', response);

        if (response.status == 200) {
            let dataGraficaRelacionFallas = response.data.data;
            const dataGraficaRelacionFallasCantidad = response.data.cantidad;

            yield put({ type: RMDPActions.DATA_GRAFICA_RELACION_FALLAS_SUCCESS, dataGraficaRelacionFallas, dataGraficaRelacionFallasCantidad });
        }
        else {
            yield put({ type: RMDPActions.DATA_GRAFICA_RELACION_FALLAS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: RMDPActions.DATA_GRAFICA_RELACION_FALLAS_FAILURE });
    }
} export function* dataGraficaRelacionFallasSaga() {
    yield takeLatest(RMDPActions.DATA_GRAFICA_RELACION_FALLAS_REQUEST, dataGraficaRelacionFallas)
}

function* dataSSforRMDPAgrupamientoByGrupo(action) {
     try {
          console.log(action);
          const token = sessionStorage.getItem('token');
          const {order, field} = action.order;
          const agrupamiento = action.agrupamiento ? action.agrupamiento: null;
          const anio = parseInt(action.anio);
          const diasDelMes = new Date(anio, action.idMes, 0).getDate();
          let mesFechaInicio = new Date(anio, action.idMes -1, 1)
          let mesFechaFin = new Date(anio, action.idMes -1, diasDelMes);
         
          const data = {
               "idsServicios": null,
               "idGruposSer": null,
               "idAreaFuncional":null,
               "idUnidadFuncional":null,
               "idInteriorFuncional":null,
               "queja": null,
               "usuarioSolicito":null,
               "usuarioRegistro":null,
               "fechaAltaInicio": mesFechaInicio.toISOString().slice(0,10).replace(/-/g,"-"),
               "fechaAltaFin": mesFechaFin.toISOString().slice(0,10).replace(/-/g,"-"),
               "fechaCierreInicio": null,
               "fechaCierreFin": null,
               "fechaTerminacionInicio": null,
               "fechaTerminacionFin": null,
               "estado": null,
               "page": null,
               "offset": null,
               "order":order,
               "field": field,
               "agrupamiento": agrupamiento
          }
 
         let response = yield call(dataSSAgrupamientoCall, token, data);
         console.log('response', response);
 
          if (response.status == 200) {
               let dataSSAgrupamientoByGrupo = response.data.data;
     
               yield put({ type: RMDPActions.DATA_SS_AGRUPAMIENTO_BY_GRUPO_SUCCESS, dataSSAgrupamientoByGrupo });
          }
          else {
               yield put({ type: RMDPActions.DATA_SS_AGRUPAMIENTO_BY_GRUPO_FAILURE });
          }
 
     } catch (error) {
         console.log(error);
         yield put({ type: RMDPActions.DATA_SS_AGRUPAMIENTO_BY_GRUPO_FAILURE });
     }
} export function* dataSSforRMDPAgrupamientoByGrupoSaga() {
     yield takeLatest(RMDPActions.DATA_SS_AGRUPAMIENTO_BY_GRUPO_REQUEST, dataSSforRMDPAgrupamientoByGrupo)
}

//  (i) Relación de Fallas en la Presentación del Servicio 
function* listaReporteFallas(action) {
     try {
          console.log(action);
          const token = sessionStorage.getItem('token');
          let { idMes, anio, page } = action;
          page = (page - 1) * 10; 
          let idServicio = '';
          if(action.idServicio){ idServicio = '&idServicio=' + action.idServicio }
          let idGrupoSer = '';
          if(action.idGrupoSer){ idGrupoSer = '&idGrupoSer=' + action.idGrupoSer }
          let tipo = '';
          if(action.tipo){ tipo = '&tipo=' + action.tipo }

          const response = yield call(listaReporteFallasCall, token, idMes, anio, page, idServicio, idGrupoSer, tipo);
          console.log('response', response)
     
          if (response.status === 200) {
               let listaReporteFallas = response.data.data;
               let listaReporteFallasCantidad = response.data.cantidad;

                function numberWithCommas(x) {
                        let parts = x.toString().split(".");
                        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        return parts.join(".");
                }

                for(let i in listaReporteFallas){
                    if (listaReporteFallas[i].fechaAlta) {
                         let fechaAlta = listaReporteFallas[i].fechaAlta.split('T')[0];
                         let horaAlta = listaReporteFallas[i].fechaAlta.split('T')[1];
                         listaReporteFallas[i].fechaAltaTabla = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0] + ' ' + horaAlta.split(':')[0] + ':' + horaAlta.split(':')[1];
                    }

                    listaReporteFallas[i].deduccion = numberWithCommas(listaReporteFallas[i].deductiva.toFixed(2));

                    if(!listaReporteFallas[i].unidadFuncional){
                         listaReporteFallas[i].unidadFuncional = 'Sin unidad funcional'
                    }

                    if(listaReporteFallas[i].deductivaParcial){
                        listaReporteFallas[i].tipoDeductivaTabla = 'Parcial';
                    } else {
                        listaReporteFallas[i].tipoDeductivaTabla = 'Definitiva';
                    }
                }
     
               yield put({ type: RMDPActions.LISTA_REPORTE_FALLAS_SUCCESS, listaReporteFallas, listaReporteFallasCantidad });
          } else {
               yield put({ type: RMDPActions.LISTA_REPORTE_FALLAS_FAILURE });
          }
     } catch (error) {
         yield put({ type: RMDPActions.LISTA_REPORTE_FALLAS_FAILURE });
     }
} export function* listaReporteFallasSaga() {
     yield takeLatest(RMDPActions.LISTA_REPORTE_FALLAS_REQUEST, listaReporteFallas);
}

function* exportarExcelReporteFallas(action) {
     try {
         console.log(action)
          const token = sessionStorage.getItem('token');
          const idMes = action.idMes;
          const anio = action.anio;
          const mesTxt = action.mes;
 
          const response = yield call(exportarExcelReporteFallasCall, token, anio, idMes);
          console.log(response)
 
          if(response.status === 200){
               const showSuccessMsg = true;
               const textMessage = "Se exportó el excel de forma correcta";
     
               const url = window.URL.createObjectURL(new Blob([response.data]));
               const link = document.createElement('a');
               link.href = url;
               link.download = "ReporteFallas-" + mesTxt + anio +  ".xls";
     
               document.body.appendChild(link);
               link.click();
               link.parentNode.removeChild(link);
     
               yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
               yield put({ type: RMDPActions.EXCEL_REPORTE_FALLAS_SUCCESS });
     
          } else {
               const showErrorMsg = true;
               const textMessage = response.message;
     
               yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
               yield put({ type: RMDPActions.EXCEL_REPORTE_FALLAS_FAILURE });
          }
    } catch (error) {
        yield put({ type: RMDPActions.EXCEL_REPORTE_FALLAS_FAILURE });
    }
} export function* exportarExcelReporteFallasSaga(){
     yield takeLatest(RMDPActions.EXCEL_REPORTE_FALLAS_REQUEST, exportarExcelReporteFallas)
}


function* exportarPDFReporteFallas(action) {
     try {
         console.log(action)
          const token = sessionStorage.getItem('token');
          const idMes = action.idMes;
          const anio = action.anio;
          const mesTxt = action.mes;
 
          const response = yield call(exportarPDFReporteFallasCall, token, anio, idMes);
          console.log(response)
 
          if(response.status === 200){
               const showSuccessMsg = true;
               const textMessage = "Se exportó el archivo pdf de forma correcta";
     
               const url = window.URL.createObjectURL(new Blob([response.data]));
               const link = document.createElement('a');
               link.href = url;
               link.download = "ReporteFallas-" + mesTxt + anio +  ".pdf";
     
               document.body.appendChild(link);
               link.click();
               link.parentNode.removeChild(link);
     
               yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
               yield put({ type: RMDPActions.PDF_REPORTE_FALLAS_SUCCESS });
     
          } else {
               const showErrorMsg = true;
               const textMessage = response.message;
     
               yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
               yield put({ type: RMDPActions.PDF_REPORTE_FALLAS_FAILURE });
          }
     } catch (error) {
         yield put({ type: RMDPActions.PDF_REPORTE_FALLAS_FAILURE });
     }
 } export function* exportarPDFReporteFallasSaga(){
     yield takeLatest(RMDPActions.PDF_REPORTE_FALLAS_REQUEST, exportarPDFReporteFallas)
}

// (iv) Relación de Deducciones en el Mes i
function* exportarPDFRelacionDeduccionMes(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const response = yield call(exportarPDFRelacionDeduccionMesCall, token, action.idMes, action.anio);
        console.log('response', response)
        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "RelacionDeducciones-" + action.mesTxt + action.anio + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: RMDPActions.PDF_RELACION_DEDUCCION_SUCCESS });
        } else {
            yield put({ type: RMDPActions.PDF_RELACION_DEDUCCION_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.PDF_RELACION_DEDUCCION_FAILURE });
    }
}
export function* exportarPDFRelacionDeduccionMesSaga() {
    yield takeLatest(RMDPActions.PDF_RELACION_DEDUCCION_REQUEST, exportarPDFRelacionDeduccionMes);
}

function* exportarExcelRelacionDeduccionMes(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const response = yield call(exportarExcelRelacionDeduccionMesCall, token, action.idMes, action.anio);
        console.log('response', response)
        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "RelacionDeducciones-" + action.mesTxt + action.anio + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: RMDPActions.EXCEL_RELACION_DEDUCCION_SUCCESS });
        } else {
            yield put({ type: RMDPActions.EXCEL_RELACION_DEDUCCION_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.EXCEL_RELACION_DEDUCCION_FAILURE });
    }
}
export function* exportarExcelRelacionDeduccionMesSaga() {
    yield takeLatest(RMDPActions.EXCEL_RELACION_DEDUCCION_REQUEST, exportarExcelRelacionDeduccionMes);
}

function* relacionDeduccionMes(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const { idMes, anio, order, field, idServicio, idTipoFalla } = action;
        // let idServicio = '';
        // if(action.idServicio){ idServicio = '?idServicio=' + action.idServicio }

        let formData = new FormData();
        formData.append('idsTipoFallas', new Blob([JSON.stringify(idTipoFalla)], {
            type: "application/json"
        }));

        const response = yield call(relacionDeduccionMesCall, token, idServicio, idMes, anio, page, order, field, formData)
        console.log('response', response);

        if(response.status === 200){
            const relacionDeduccion = response.data.data;
            const relacionDeduccionCantidad = response.data.cantidad;

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            for(let i in relacionDeduccion){
                if(relacionDeduccion[i].solicitudByIdSolicitudServicio){
                    relacionDeduccion[i].folio = relacionDeduccion[i].solicitudByIdSolicitudServicio.folio;
                    relacionDeduccion[i].descripcionSolicitudTabla = relacionDeduccion[i].solicitudByIdSolicitudServicio.descripcionSolicitud;

                    let fechaCierre = relacionDeduccion[i].fechaCierre.split('T')[0];
                    let horaCierre = relacionDeduccion[i].fechaCierre.split('T')[1];
                    relacionDeduccion[i].fechaCierreTabla = fechaCierre.split('-')[2] + '-' + fechaCierre.split('-')[1] + '-' + fechaCierre.split('-')[0]  + ' ' + horaCierre.split(':')[0] + ':' + horaCierre.split(':')[1];

                    if(relacionDeduccion[i].ponderacionServArea){ relacionDeduccion[i].ponderacionServAreaTabla = relacionDeduccion[i].ponderacionServArea } else { relacionDeduccion[i].ponderacionServAreaTabla = '-' }
                    
                    let fechaAlta = relacionDeduccion[i].solicitudByIdSolicitudServicio.fechaAlta.split('T')[0];
                    let horaAlta = relacionDeduccion[i].solicitudByIdSolicitudServicio.fechaAlta.split('T')[1];
                    relacionDeduccion[i].fechaAltaTabla = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0]  + ' ' + horaAlta.split(':')[0] + ':' + horaAlta.split(':')[1];

                    if(relacionDeduccion[i].solicitudByIdSolicitudServicio.ubicacionByIdUbicacion){
                        if(relacionDeduccion[i].solicitudByIdSolicitudServicio.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional){
                            relacionDeduccion[i].codigoArea = relacionDeduccion[i].solicitudByIdSolicitudServicio.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.codigoArea;
                            relacionDeduccion[i].areaFuncional = relacionDeduccion[i].solicitudByIdSolicitudServicio.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                        }

                        if(relacionDeduccion[i].solicitudByIdSolicitudServicio.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional){
                            relacionDeduccion[i].unidadFuncional = relacionDeduccion[i].solicitudByIdSolicitudServicio.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                        }
                    }

                    if(relacionDeduccion[i].solicitudByIdSolicitudServicio.servicioByIdServicio){
                        relacionDeduccion[i].nombreServicioTabla = relacionDeduccion[i].solicitudByIdSolicitudServicio.servicioByIdServicio.nombre
                    }

                    if(relacionDeduccion[i].solicitudByIdSolicitudServicio.fallaByIdFalla){
                        if(relacionDeduccion[i].solicitudByIdSolicitudServicio.fallaByIdFalla.tipoFallaByIdTipoFalla){
                            relacionDeduccion[i].tipoFalla = relacionDeduccion[i].solicitudByIdSolicitudServicio.fallaByIdFalla.tipoFallaByIdTipoFalla.nombre;
                            relacionDeduccion[i].claveTipoFalla = relacionDeduccion[i].solicitudByIdSolicitudServicio.fallaByIdFalla.tipoFallaByIdTipoFalla.clave;
                            relacionDeduccion[i].descripcionTipoFalla = relacionDeduccion[i].solicitudByIdSolicitudServicio.fallaByIdFalla.tipoFallaByIdTipoFalla.descripcion;
                        }
                    }
                }
                // if(relacionDeduccion[i].deductivaCierreByIdServicio){
                //     relacionDeduccion[i].deduccion = numberWithCommas(relacionDeduccion[i].deductivaCierreByIdServicio.deductiva.toFixed(2));
                // }

                relacionDeduccion[i].deduccion = numberWithCommas(relacionDeduccion[i].deductiva.toFixed(2));
            }

            console.log('relacionDeduccion', relacionDeduccion)

            yield put({ type: RMDPActions.RELACION_DEDUCCIONES_SUCCESS, relacionDeduccion, relacionDeduccionCantidad });;
        } else {
            yield put({ type: RMDPActions.RELACION_DEDUCCIONES_FAILURE})
        }
    } catch (error) {
        yield put({ type: RMDPActions.RELACION_DEDUCCIONES_FAILURE})
    }
}
export function* relacionDeduccionMesSaga(){
    yield takeLatest(RMDPActions.RELACION_DEDUCCIONES_REQUEST, relacionDeduccionMes)
}

//  (v) Relación de Deducciones por Reiteraciones 
function* exportarPDFRelacionDeduccionReiteracionMes(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const response = yield call(exportarPDFRelacionDeduccionReiteracionMesCall, token, action.idMes, action.anio);
        console.log('response', response)
        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "RelacionDeduccionesReiteracion-" + action.mesTxt + action.anio + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: RMDPActions.PDF_RELACION_DEDUCCION_REITERACION_SUCCESS });
        } else {
            yield put({ type: RMDPActions.PDF_RELACION_DEDUCCION_REITERACION_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.PDF_RELACION_DEDUCCION_REITERACION_FAILURE });
    }
}
export function* exportarPDFRelacionDeduccionReiteracionMesSaga() {
    yield takeLatest(RMDPActions.PDF_RELACION_DEDUCCION_REITERACION_REQUEST, exportarPDFRelacionDeduccionReiteracionMes);
}

function* exportarEXCELRelacionDeduccionReiteracionMes(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const response = yield call(exportarEXCELRelacionDeduccionReiteracionMesCall, token, action.idMes, action.anio);
        console.log('response', response)
        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "RelacionDeduccionesReiteracion-" + action.mesTxt + action.anio + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: RMDPActions.EXCEL_RELACION_DEDUCCION_REITERACION_SUCCESS });
        } else {
            yield put({ type: RMDPActions.EXCEL_RELACION_DEDUCCION_REITERACION_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.EXCEL_RELACION_DEDUCCION_REITERACION_FAILURE });
    }
}
export function* exportarEXCELRelacionDeduccionReiteracionMesSaga() {
    yield takeLatest(RMDPActions.EXCEL_RELACION_DEDUCCION_REITERACION_REQUEST, exportarEXCELRelacionDeduccionReiteracionMes);
}

function* relacionDeduccionReiteracionMes(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const { idMes, anio, order, field, idServicio, idTipoFalla } = action;
        let formData = new FormData();
        formData.append('idsTipoFallas', new Blob([JSON.stringify(idTipoFalla)], {
            type: "application/json"
        }));

        const response = yield call(relacionDeduccionReiteracionMesCall, token, idServicio, idMes, anio, page, order, field, formData)
        console.log('response', response);

        if(response.status === 200){
            const relacionDeduccionReiteracion = response.data.data;
            const relacionDeduccionReiteracionCantidad = response.data.cantidad;

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            if(relacionDeduccionReiteracionCantidad > 0){
                for(let i in relacionDeduccionReiteracion){
                    if(relacionDeduccionReiteracion[i].solicitudByIdSolicitudServicio){
                        relacionDeduccionReiteracion[i].folio = relacionDeduccionReiteracion[i].solicitudByIdSolicitudServicio.folio;

                        relacionDeduccionReiteracion[i].descripcionSolicitudTabla = relacionDeduccionReiteracion[i].solicitudByIdSolicitudServicio.descripcionSolicitud;

                        let fechaAlta = relacionDeduccionReiteracion[i].solicitudByIdSolicitudServicio.fechaAlta.split('T')[0];
                        let horaAlta = relacionDeduccionReiteracion[i].solicitudByIdSolicitudServicio.fechaAlta.split('T')[1];
                        relacionDeduccionReiteracion[i].fechaAltaTabla = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0]  + ' ' + horaAlta.split(':')[0] + ':' + horaAlta.split(':')[1];

                        let fechaCierre = relacionDeduccionReiteracion[i].solicitudByIdSolicitudServicio.fechaCierre.split('T')[0];
                        let horaCierre = relacionDeduccionReiteracion[i].solicitudByIdSolicitudServicio.fechaCierre.split('T')[1];
                        relacionDeduccionReiteracion[i].fechaCierreTabla = fechaCierre.split('-')[2] + '-' + fechaCierre.split('-')[1] + '-' + fechaCierre.split('-')[0] + ' ' + horaCierre.split(':')[0] + ':' + horaCierre.split(':')[1];

                        if(relacionDeduccionReiteracion[i].solicitudByIdSolicitudServicio.ubicacionByIdUbicacion){
                            if(relacionDeduccionReiteracion[i].solicitudByIdSolicitudServicio.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional){
                                relacionDeduccionReiteracion[i].areaFuncionalTabla = relacionDeduccionReiteracion[i].solicitudByIdSolicitudServicio.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;

                                relacionDeduccionReiteracion[i].codigoAreaTabla = relacionDeduccionReiteracion[i].solicitudByIdSolicitudServicio.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.codigoArea;
                            }

                            if(relacionDeduccionReiteracion[i].solicitudByIdSolicitudServicio.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional){
                                relacionDeduccionReiteracion[i].unidadFuncionalTabla = relacionDeduccionReiteracion[i].solicitudByIdSolicitudServicio.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                            }
                        }

                        if(relacionDeduccionReiteracion[i].solicitudByIdSolicitudServicio.fallaByIdFalla){
                            if(relacionDeduccionReiteracion[i].solicitudByIdSolicitudServicio.fallaByIdFalla.tipoFallaByIdTipoFalla){
                                relacionDeduccionReiteracion[i].tipoFallaTabla = relacionDeduccionReiteracion[i].solicitudByIdSolicitudServicio.fallaByIdFalla.tipoFallaByIdTipoFalla.descripcion;

                                relacionDeduccionReiteracion[i].claveTipoFalla = relacionDeduccionReiteracion[i].solicitudByIdSolicitudServicio.fallaByIdFalla.tipoFallaByIdTipoFalla.clave;
                            }
                        }

                        if(relacionDeduccionReiteracion[i].solicitudByIdSolicitudServicio.servicioByIdServicio){
                            relacionDeduccionReiteracion[i].nombreServicioTabla = relacionDeduccionReiteracion[i].solicitudByIdSolicitudServicio.servicioByIdServicio.nombre;
                        }
                    }

                    relacionDeduccionReiteracion[i].deduccionTabla = numberWithCommas(relacionDeduccionReiteracion[i].deductiva.toFixed(2));
                }
            }

            yield put({ type: RMDPActions.RELACION_DEDUCCIONES_REITERACION_SUCCESS, relacionDeduccionReiteracion, relacionDeduccionReiteracionCantidad });;
        } else {
            yield put({ type: RMDPActions.RELACION_DEDUCCIONES_REITERACION_FAILURE})
        }
    } catch (error) {
        yield put({ type: RMDPActions.RELACION_DEDUCCIONES_REITERACION_FAILURE})
    }
}
export function* relacionDeduccionReiteracionMesSaga(){
    yield takeLatest(RMDPActions.RELACION_DEDUCCIONES_REITERACION_REQUEST, relacionDeduccionReiteracionMes)
}

// Cálculo de Pago por Servicio para el Mes i
//  3.1 Resumen del Pago por Servicio 
function* resumenPagoServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const response = yield call(resumenPagoServicioCall, token, action.idMes, action.anio);
        if (response.status === 200) {
            let resumenPagoServicio = response.data;
            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            for(let i in resumenPagoServicio){
                resumenPagoServicio[i].pmiTabla = numberWithCommas(resumenPagoServicio[i].pmi.toFixed(2));
                resumenPagoServicio[i].pmrTabla = numberWithCommas(resumenPagoServicio[i].pmr.toFixed(2));
                resumenPagoServicio[i].daTabla = numberWithCommas(resumenPagoServicio[i].da.toFixed(2));
                resumenPagoServicio[i].cpdTabla = numberWithCommas(resumenPagoServicio[i].cpd.toFixed(2));
                resumenPagoServicio[i].spTabla = numberWithCommas(resumenPagoServicio[i].sp.toFixed(2));
                resumenPagoServicio[i].pmsTabla = numberWithCommas(resumenPagoServicio[i].pms.toFixed(2));
            }

            yield put({ type: RMDPActions.RESUMEN_PAGO_SERVICIO_SUCCESS, resumenPagoServicio });
        } else {
            yield put({ type: RMDPActions.RESUMEN_PAGO_SERVICIO_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.RESUMEN_PAGO_SERVICIO_FAILURE });
    }
}
export function* resumenPagoServicioSaga() {
    yield takeLatest(RMDPActions.RESUMEN_PAGO_SERVICIO_REQUEST, resumenPagoServicio);
}

function* exportarPDFResumenPagoServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const response = yield call(exportarPDFResumenPagoServicioCall, token, action.idMes, action.anio);
        console.log('response', response)
        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "ResumenPagoServicio-" + action.mesTxt + action.anio + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            const showSuccessMsg = true;
            const textMessage = "Se exportó el archivo de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: RMDPActions.PDF_RESUME_PAGO_SUCCESS });
        } else {
            yield put({ type: RMDPActions.PDF_RESUME_PAGO_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.PDF_RESUME_PAGO_FAILURE });
    }
} export function* exportarPDFResumenPagoServicioSaga() {
    yield takeLatest(RMDPActions.PDF_RESUME_PAGO_REQUEST, exportarPDFResumenPagoServicio);
}

function* exportarEXCELResumenPagoServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const response = yield call(exportarEXCELResumenPagoServicioCall, token, action.idMes, action.anio);
        console.log('response', response)
        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "ResumenPagoServicio-" + action.mesTxt + action.anio + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            const showSuccessMsg = true;
            const textMessage = "Se exportó el archivo de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: RMDPActions.EXCEL_RESUME_PAGO_SUCCESS });
        } else {
            yield put({ type: RMDPActions.EXCEL_RESUME_PAGO_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.EXCEL_RESUME_PAGO_FAILURE });
    }
} export function* exportarEXCELResumenPagoServicioSaga() {
    yield takeLatest(RMDPActions.EXCEL_RESUME_PAGO_REQUEST, exportarEXCELResumenPagoServicio);
}

//  3.2 Resumen para el Cálculo del PMIi & 3.3 Resumen para el Cálculo del PMRi 
function* resumenCalculoPMIyPMR(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const response = yield call(resumenCalculoPMIyPMRCall, token, action.idMes, action.anio);    
        console.log('response', response)
        if (response.status === 200) {
            let resumenCalculo = response.data.data;

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            for(let i in resumenCalculo){
                if(resumenCalculo[i].proyecto){
                    resumenCalculo[i].paiTabla = numberWithCommas(resumenCalculo[i].proyecto.pagoAnualInversion.toFixed(2)); 
                    resumenCalculo[i].parf = numberWithCommas(resumenCalculo[i].proyecto.pagoAnualReferencia.toFixed(2));
                    resumenCalculo[i].parv = numberWithCommas(resumenCalculo[i].proyecto.pagoReferenciaVar.toFixed(2));
                    resumenCalculo[i].ptvMin = numberWithCommas(resumenCalculo[i].proyecto.poblacionPtvMin.toFixed(0));
                }
                if(resumenCalculo[i].anoContractualByAnioCalendario){
                    resumenCalculo[i].inpc0 = resumenCalculo[i].anoContractualByAnioCalendario.inpc0;
                    resumenCalculo[i].fuenteInpc0 = resumenCalculo[i].anoContractualByAnioCalendario.fuente;

                    let fechaPublicacion = resumenCalculo[i].anoContractualByAnioCalendario.fechaPublicacion.split('T')[0];
                    resumenCalculo[i].fechaPublicacionInpc0 = fechaPublicacion.split('-')[2] + '-' + fechaPublicacion.split('-')[1] + '-' + fechaPublicacion.split('-')[0];;
                }

                if(resumenCalculo[i].anioCalendarioByIdAnioCalendario){
                    resumenCalculo[i].dai = resumenCalculo[i].anioCalendarioByIdAnioCalendario.dai;
                }

                let fechaPublicacionInpc = resumenCalculo[i].fechaPubInpc.split('T')[0];
                resumenCalculo[i].fechaPublicacionInpc = fechaPublicacionInpc.split('-')[2] + '-' + fechaPublicacionInpc.split('-')[1] + '-' + fechaPublicacionInpc.split('-')[0];;

                resumenCalculo[i].priTabla = numberWithCommas(resumenCalculo[i].pri);
                resumenCalculo[i].pviTabla = numberWithCommas(resumenCalculo[i].pvi.toFixed(2));
                resumenCalculo[i].ptiTabla = numberWithCommas(resumenCalculo[i].pti.toFixed(2));
            }

            const dataTablePMI=[
                { variable: 'PAI', descripcion: 'Pago Anual de Inversión', valor: resumenCalculo[0].paiTabla, fuente: '-', fechaPublicacion: '-' },
                { variable: 'INPCi-1', descripcion: 'Índice Nacional de Precios al Consumidor del mes i - 1', valor: resumenCalculo[0].inpc, fuente: resumenCalculo[0].fuentePubInpc, fechaPublicacion: resumenCalculo[0].fechaPublicacionInpc },
                { variable: 'INPC0', descripcion: 'Índice Nacional de Precios al Consumidor en la fecha base', valor: resumenCalculo[0].inpc0, fuente: resumenCalculo[0].fuenteInpc0, fechaPublicacion: resumenCalculo[0].fechaPublicacionInpc0 },
                { variable: 'dmi', descripcion: 'Número de días del mes i en que se prestó el servicio', valor: resumenCalculo[0].dmi, fuente: '-', fechaPublicacion: '-' },
                { variable: 'dai', descripcion: 'Número de días del año i en que se presta el servicio', valor: resumenCalculo[0].dai, fuente: '-', fechaPublicacion: '-' },
            ];

            const columns = [
                { title: 'Variable', dataIndex: 'variable', key:'variable', align:'center',
                    render(text, record) {
                        return {
                            props: {
                                style: { background: '#ccd9fdc4' }
                            },
                            children: record.variable
                        };
                    }    
                },
                { title: 'Descripción', dataIndex: 'descripcion', key:'descripcion', align:'center' },
                { title: 'Valor', dataIndex: 'valor', key:'valor', align:'right' },
                { title: 'Fuente', dataIndex: 'fuente', key:'fuente', align:'center' },
                { title: 'Fecha publicación', dataIndex: 'fechaPublicacion', key:'fechaPublicacion', align:'center' },
            ];

            const dataTablePMR = [
                { variable: 'PARf', descripcion: 'Pago Anual de Referencia Fijo', valor: resumenCalculo[0].parf, fuente: '-', fechaPublicacion: '-' },
                { variable: 'PARv', descripcion: 'Pago Anual de Referencia Variable', valor: resumenCalculo[0].parv, fuente: '-', fechaPublicacion: '-' },
                { variable: 'INPCi-1', descripcion: 'Índice Nacional de Precios al Consumidor del mes i - 1', valor: resumenCalculo[0].inpc, fuente: resumenCalculo[0].fuentePubInpc, fechaPublicacion: resumenCalculo[0].fechaPublicacionInpc },
                { variable: 'INPC0', descripcion: 'Índice Nacional de Precios al Consumidor en la fecha base', valor: resumenCalculo[0].inpc0, fuente: resumenCalculo[0].fuenteInpc0, fechaPublicacion: resumenCalculo[0].fechaPublicacionInpc0 },
                { variable: 'dmi', descripcion: 'Número de días del mes i en que se prestó el servicio', valor: resumenCalculo[0].dmi, fuente: '-', fechaPublicacion: '-' },
                { variable: 'dai', descripcion: 'Número de días del año i en que se presta el servicio', valor: resumenCalculo[0].dai, fuente: '-', fechaPublicacion: '-' },
                { variable: 'ptv', descripcion: 'Población mínima', valor: resumenCalculo[0].ptvMin, fuente: '-', fechaPublicacion: '-' },
                { variable: 'pri', descripcion: 'Suma de los PPLs diarios en el mes i', valor: resumenCalculo[0].priTabla, fuente: '-', fechaPublicacion: '-' },
                { variable: 'pti', descripcion: 'Promedio de PPLs (pri/dmi)', valor: resumenCalculo[0].ptiTabla, fuente: '-', fechaPublicacion: '-' },
                { variable: 'pvi', descripcion: 'Diferencia entre PPLs mínimos y número de PPLs promedio en el mes', valor: resumenCalculo[0].pviTabla, fuente: '-', fechaPublicacion: '-' },

            ];

            resumenCalculo.columns = columns;
            resumenCalculo.dataTablePMI = dataTablePMI;
            resumenCalculo.dataTablePMR = dataTablePMR;

            yield put({ type: RMDPActions.RESUMEN_CALCULO_SUCCESS, resumenCalculo });
        } else {
            yield put({ type: RMDPActions.RESUMEN_CALCULO_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.RESUMEN_CALCULO_FAILURE });
    }
}
export function* resumenCalculoPMIyPMRSaga() {
    yield takeLatest(RMDPActions.RESUMEN_CALCULO_REQUEST, resumenCalculoPMIyPMR);
}

function* exportarPDFResumenCalculo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const response = yield call(exportarPDFResumenCalculoCall, token, action.idMes, action.anio, action.selector);
        console.log('response', response)
        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            let title = 'ResumenCalculoPMR-'; if(action.selector){title='ResumenCalculoPMI-'}
            link.href = url;
            link.download = title + action.mesTxt + action.anio + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            const showSuccessMsg = true;
            const textMessage = "Se exportó el archivo de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: RMDPActions.PDF_RESUMEN_CALCULO_SUCCESS });
        } else {
            yield put({ type: RMDPActions.PDF_RESUMEN_CALCULO_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.PDF_RESUMEN_CALCULO_FAILURE });
    }
} export function* exportarPDFResumenCalculoSaga() {
    yield takeLatest(RMDPActions.PDF_RESUMEN_CALCULO_REQUEST, exportarPDFResumenCalculo);
}

function* exportarEXCELResumenCalculo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const response = yield call(exportarEXCELResumenCalculoCall, token, action.idMes, action.anio, action.selector);
        console.log('response', response)
        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            let title = 'ResumenCalculoPMR-'; if(action.selector){title='ResumenCalculoPMI-'}
            link.href = url;
            link.download = title + action.mesTxt + action.anio + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            const showSuccessMsg = true;
            const textMessage = "Se exportó el archivo de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: RMDPActions.EXCEL_RESUMEN_CALCULO_SUCCESS });
        } else {
            yield put({ type: RMDPActions.EXCEL_RESUMEN_CALCULO_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.EXCEL_RESUMEN_CALCULO_FAILURE });
    }
} export function* exportarEXCELResumenCalculoSaga() {
    yield takeLatest(RMDPActions.EXCEL_RESUMEN_CALCULO_REQUEST, exportarEXCELResumenCalculo);
}
//  3.4 Deducción Aplicable 
function* getResumenDeduccionAplicable(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const response = yield call(getResumenDeduccionAplicableCall, token, action.idMes, action.anio);
        console.log('response', response)
        if (response.status === 200) {
            let resumenDeduccionAplicable = response.data;
            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            for(let i in resumenDeduccionAplicable){
                resumenDeduccionAplicable[i].porcentajeTabla =  resumenDeduccionAplicable[i].porcentaje.toFixed(2) + ' %';
                resumenDeduccionAplicable[i].importeTabla = '$ ' + numberWithCommas(resumenDeduccionAplicable[i].importe.toFixed(2));
            }

            yield put({ type: RMDPActions.DEDUCCION_APLICABLE_RESUMEN_SUCCESS, resumenDeduccionAplicable });
        } else {
            yield put({ type: RMDPActions.DEDUCCION_APLICABLE_RESUMEN_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.DEDUCCION_APLICABLE_RESUMEN_FAILURE });
    }
}
export function* getResumenDeduccionAplicableSaga() {
    yield takeLatest(RMDPActions.DEDUCCION_APLICABLE_RESUMEN_REQUEST, getResumenDeduccionAplicable);
} 

function* pdfResumenDeduccionAplicable(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idMes = action.idMes;
        const anio = action.anio;
        const mesTxt = action.mesTxt;

        const response = yield call(pdfResumenDeduccionAplicableCall, token, idMes, anio);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el archivo pdf de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "DeduccionAplicable-" + mesTxt + anio +  ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: RMDPActions.PDF_DEDUCCION_APLICABLE_RESUMEN_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: RMDPActions.PDF_DEDUCCION_APLICABLE_RESUMEN_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.PDF_DEDUCCION_APLICABLE_RESUMEN_FAILURE });
    }
} export function* pdfResumenDeduccionAplicableSaga(){
    yield takeLatest(RMDPActions.PDF_DEDUCCION_APLICABLE_RESUMEN_REQUEST, pdfResumenDeduccionAplicable)
}

function* excelResumenDeduccionAplicable(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idMes = action.idMes;
        const anio = action.anio;
        const mesTxt = action.mesTxt;

        const response = yield call(excelResumenDeduccionAplicableCall, token, idMes, anio);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el archivo de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "DeduccionAplicable-" + mesTxt + anio +  ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: RMDPActions.EXCEL_DEDUCCION_APLICABLE_RESUMEN_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: RMDPActions.EXCEL_DEDUCCION_APLICABLE_RESUMEN_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.EXCEL_DEDUCCION_APLICABLE_RESUMEN_FAILURE });
    }
} export function* excelResumenDeduccionAplicableSaga(){
    yield takeLatest(RMDPActions.EXCEL_DEDUCCION_APLICABLE_RESUMEN_REQUEST, excelResumenDeduccionAplicable)
}

//  3.5 Deducción por Consumo de los Servicios Públicos del Desarrollador 
function* getTipoConsumoRMDP(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const response = yield call(getTipoConsumoRMDPCall, token, action.idMes, action.anio, page);
        console.log('response', response)
        if (response.status === 200) {
            let dataTipoConsumoRMDP = response.data.data;
            const dataTipoConsumoRMDPCantidad = response.data.cantidad;
            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            for(let i in dataTipoConsumoRMDP){
                dataTipoConsumoRMDP[i].precioUnitario = numberWithCommas(dataTipoConsumoRMDP[i].precioUnitario.toFixed(4));
                dataTipoConsumoRMDP[i].consumoDesarrollador = numberWithCommas(dataTipoConsumoRMDP[i].consumoDesarrollador.toFixed(4));
                dataTipoConsumoRMDP[i].consumoPyrs = numberWithCommas(dataTipoConsumoRMDP[i].consumoPyrs.toFixed(4));
                dataTipoConsumoRMDP[i].totalConsumida = numberWithCommas(dataTipoConsumoRMDP[i].totalConsumida.toFixed(4));
                dataTipoConsumoRMDP[i].totalFacturado = numberWithCommas(dataTipoConsumoRMDP[i].totalFacturado.toFixed(4));
                dataTipoConsumoRMDP[i].tipoConsumo = dataTipoConsumoRMDP[i].servicioPublicoByIdServicioPublico.nombre;
                dataTipoConsumoRMDP[i].unidadMedicion = dataTipoConsumoRMDP[i].unidadMedidaByIdUnidadMedida.unidadMedida;
                dataTipoConsumoRMDP[i].expectativaMes = numberWithCommas(dataTipoConsumoRMDP[i].servicioPublicoByIdServicioPublico.expectativaMes.toFixed(4));
                dataTipoConsumoRMDP[i].expectativaMesAnio = numberWithCommas(dataTipoConsumoRMDP[i].servicioPublicoByIdServicioPublico.expectativaMesAnio.toFixed(4));
            }

            console.log('dataTipoConsumoRMDP', dataTipoConsumoRMDP)

            yield put({ type: RMDPActions.GET_TIPO_CONSUMO_RMDP_SUCCESS, dataTipoConsumoRMDP, dataTipoConsumoRMDPCantidad });
        } else {
            yield put({ type: RMDPActions.GET_TIPO_CONSUMO_RMDP_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.GET_TIPO_CONSUMO_RMDP_FAILURE });
    }
}
export function* getTipoConsumoRMDPSaga() {
    yield takeLatest(RMDPActions.GET_TIPO_CONSUMO_RMDP_REQUEST, getTipoConsumoRMDP);
}

function* getTipoConsumoGeneralRMDP(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const response = yield call(getTipoConsumoGeneralRMDPCall, token, action.idMes, action.anio, page);
        console.log('response', response)
        if (response.status === 200) {
            let dataTipoConsumoGeneral = response.data.data;
            const dataTipoConsumoGeneralCantidad = response.data.cantidad;
            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            for(let i in dataTipoConsumoGeneral){
                dataTipoConsumoGeneral.tipoConsumo = dataTipoConsumoGeneral.nombre;
                dataTipoConsumoGeneral[i].totalConsumidaDesarrollador = numberWithCommas(dataTipoConsumoGeneral[i].totalConsumidaDesarrollador.toFixed(4));
                dataTipoConsumoGeneral[i].totalPyrs = numberWithCommas(dataTipoConsumoGeneral[i].totalPyrs.toFixed(4));
                dataTipoConsumoGeneral[i].totalConsumida = numberWithCommas(dataTipoConsumoGeneral[i].totalConsumida.toFixed(4));
                dataTipoConsumoGeneral[i].totalPagar = numberWithCommas(dataTipoConsumoGeneral[i].totalPagar.toFixed(4));
                dataTipoConsumoGeneral[i].expectativaMes = numberWithCommas(dataTipoConsumoGeneral[i].expectativaMes.toFixed(4));
                dataTipoConsumoGeneral[i].expectativaMesAnio = numberWithCommas(dataTipoConsumoGeneral[i].expectativaMesAnio.toFixed(4));
            }

            yield put({ type: RMDPActions.GET_TIPO_CONSUMO_GENERAL_RMDP_SUCCESS, dataTipoConsumoGeneral, dataTipoConsumoGeneralCantidad });
        } else {
            yield put({ type: RMDPActions.GET_TIPO_CONSUMO_GENERAL_RMDP_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.GET_TIPO_CONSUMO_GENERAL_RMDP_FAILURE });
    }
}
export function* getTipoConsumoGeneralRMDPSaga() {
    yield takeLatest(RMDPActions.GET_TIPO_CONSUMO_GENERAL_RMDP_REQUEST, getTipoConsumoGeneralRMDP);
}

function* pdfTipoConsumoRMDP(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idMes = action.idMes;
        const anio = action.anio;
        const mesTxt = action.mesTxt;

        const response = yield call(pdfTipoConsumoRMDPCall, token, idMes, anio);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el archivo de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "DeduccionSPTiposConsumo-" + mesTxt + anio +  ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: RMDPActions.PDF_TIPO_CONSUMO_RMDP_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: RMDPActions.PDF_TIPO_CONSUMO_RMDP_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.PDF_TIPO_CONSUMO_RMDP_FAILURE });
    }
} export function* pdfTipoConsumoRMDPSaga(){
    yield takeLatest(RMDPActions.PDF_TIPO_CONSUMO_RMDP_REQUEST, pdfTipoConsumoRMDP)
}

function* pdfTipoConsumoGeneralRMDP(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idMes = action.idMes;
        const anio = action.anio;
        const mesTxt = action.mesTxt;

        const response = yield call(pdfTipoConsumoGeneralRMDPCall, token, idMes, anio);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el archivo de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "DeduccionSPGeneral-" + mesTxt + anio +  ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: RMDPActions.PDF_TIPO_CONSUMO_GENERAL_RMDP_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: RMDPActions.PDF_TIPO_CONSUMO_GENERAL_RMDP_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.PDF_TIPO_CONSUMO_GENERAL_RMDP_FAILURE });
    }
} export function* pdfTipoConsumoGeneralRMDPSaga(){
    yield takeLatest(RMDPActions.PDF_TIPO_CONSUMO_GENERAL_RMDP_REQUEST, pdfTipoConsumoGeneralRMDP)
}

function* excelTipoConsumoRMDP(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idMes = action.idMes;
        const anio = action.anio;
        const mesTxt = action.mesTxt;

        const response = yield call(excelTipoConsumoRMDPCall, token, idMes, anio);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el archivo de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "DeduccionSPTiposConsumo-" + mesTxt + anio +  ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: RMDPActions.EXCEL_TIPO_CONSUMO_RMDP_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: RMDPActions.EXCEL_TIPO_CONSUMO_RMDP_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.EXCEL_TIPO_CONSUMO_RMDP_FAILURE });
    }
} export function* excelTipoConsumoRMDPSaga(){
    yield takeLatest(RMDPActions.EXCEL_TIPO_CONSUMO_RMDP_REQUEST, excelTipoConsumoRMDP)
}

function* excelTipoConsumoGeneralRMDP(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idMes = action.idMes;
        const anio = action.anio;
        const mesTxt = action.mesTxt;

        const response = yield call(excelTipoConsumoGeneralRMDPCall, token, idMes, anio);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el archivo de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "DeduccionSPGeneral-" + mesTxt + anio +  ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: RMDPActions.EXCEL_TIPO_CONSUMO_GENERAL_RMDP_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: RMDPActions.EXCEL_TIPO_CONSUMO_GENERAL_RMDP_FAILURE });
        }
    } catch (error) {
        yield put({ type: RMDPActions.EXCEL_TIPO_CONSUMO_GENERAL_RMDP_FAILURE });
    }
} export function* excelTipoConsumoGeneralRMDPSaga(){
    yield takeLatest(RMDPActions.EXCEL_TIPO_CONSUMO_GENERAL_RMDP_REQUEST, excelTipoConsumoGeneralRMDP)
}

function* listaServiciosforEquipo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const busqueda = '';
        
        const response = yield call(listaServiciosFiltroCall,token, busqueda)
        console.log(response);

        if (response.status === 200) {
            let listaServiciosForEquipo = response.data;
            let listaServiciosEquipo = '';

            for(let i = 0; i < listaServiciosForEquipo.length; i++){
                let contador = i + 1;
                if(listaServiciosForEquipo[i].partidasByIdServicio.length > 0){
                    for(let j = 0; j < listaServiciosForEquipo[i].partidasByIdServicio.length; j++){
                        let sContador = j + 1;
                        listaServiciosEquipo += " <h2> 1.3." + contador + "." + sContador + " " + listaServiciosForEquipo[i].nombre + " - " + listaServiciosForEquipo[i].partidasByIdServicio[j].nombre + "</h2> <br /> <h3>Niveles de Mantenimiento</h3> <br /> <h3>Conclusión</h3> <br /> ";
                    }
                } else {
                    listaServiciosEquipo += " <h2> 1.3." + contador + " " + listaServiciosForEquipo[i].nombre + "</h2> <br /> <h3>Niveles de Mantenimiento</h3> <br /> <h3>Conclusión</h3> <br /> ";
                }
            }
            yield put({ type: "LISTA_SERVICIOS_FOR_EQUIPO_SUCCESS", listaServiciosEquipo})
        } else {
            yield put ({ type: "LISTA_SERVICIOS_FOR_EQUIPO_FAILURE"})
        }
    } catch (error) {
        yield put ({ type: "LISTA_SERVICIOS_FOR_EQUIPO_FAILURE"})
    }
}
export function* listaServiciosforEquipoSaga() {
    yield takeLatest(RMDPActions.LISTA_SERVICIOS_FOR_EQUIPO_REQUEST,listaServiciosforEquipo )
}


function* listaProgramacionForEquipo(action) {
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        let fechaInicio = 'fechaInicio=' + action.fechaInicio;
        let fechaFin = '&fechaFin=' + action.fechaFin; 
        const idGrupoSer = '&idGrupoSer=' + action.idGrupoSer;
        let tipoPrograma = '';
        let idServicio = '';
        let idPartida = '';
        if(action.idServicio){
            idServicio = '&idServicio=' + action.idServicio;
        }
        if(action.idPartida){
            idPartida = '&idPartida=' + action.idPartida;
        }
        
        const response = yield call (calendarioDeProgramacionCall, token, fechaInicio, fechaFin, idGrupoSer, tipoPrograma, idServicio, idPartida);
        console.log(response);
    
        if (response.status == 200) {
            let listaProgramacionForEquipo = response.data;
            let listaProgamacionForEquipoReal = [];

            for(let i in listaProgramacionForEquipo){
                if(listaProgramacionForEquipo[i].calendarioProgramacionRutinaResponse[0]){
                    for(let x in listaProgramacionForEquipo[i].calendarioProgramacionRutinaResponse){
                        let igualesTodos = true;
                        let estado = null;
                        
                        for(let y in listaProgramacionForEquipo[i].calendarioProgramacionRutinaResponse[x].calendarioProgramacionQueryResponse){
                            if(estado){
                                if(estado !== listaProgramacionForEquipo[i].calendarioProgramacionRutinaResponse[x].calendarioProgramacionQueryResponse[y].estadoRutina){
                                    igualesTodos = false;
                                }
                            }
                            else{
                                estado = listaProgramacionForEquipo[i].calendarioProgramacionRutinaResponse[x].calendarioProgramacionQueryResponse[y].estadoRutina;
                            }
                        }
                        for(let y in listaProgramacionForEquipo[i].calendarioProgramacionRutinaResponse[x].calendarioProgramacionQueryResponse){
                            if(igualesTodos){
                                listaProgramacionForEquipo[i].calendarioProgramacionRutinaResponse[x].color = listaProgramacionForEquipo[i].calendarioProgramacionRutinaResponse[x].calendarioProgramacionQueryResponse[y].estadoRutina === 'En proceso' ? 'success': listaProgramacionForEquipo[i].calendarioProgramacionRutinaResponse[x].calendarioProgramacionQueryResponse[y].estadoRutina === 'Realizada' ? 'default' : 'warning';
                            }
                            else{
                                listaProgramacionForEquipo[i].calendarioProgramacionRutinaResponse[x].color = 'processing';
                            }
                        }
                    }
                }
            }

            yield put({ type: RMDPActions.LISTA_PROGRAMADOS_FOR_EQUIPO_SUCCESS, listaProgramacionForEquipo})
        }
        else {
            yield put({ type: RMDPActions.LISTA_PROGRAMADOS_FOR_EQUIPO_FAILURE});
        }
    } catch (error) {
        console.log(error);
        yield put({ type: RMDPActions.LISTA_PROGRAMADOS_FOR_EQUIPO_FAILURE});
    }

}
export function* listaProgramacionForEquipoSaga() {
    yield takeLatest(RMDPActions.LISTA_PROGRAMADOS_FOR_EQUIPO_REQUEST, listaProgramacionForEquipo);
}