import React from 'react';
import {connect} from 'react-redux';
import { Row, Col, Card, Input, Spin , Select, Divider, Button} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import Formulario from './Formulario';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const {Search} = Input;
const {Option} = Select;
const {TextArea} = Input;

function NuevoFolioError (props) {
    const {ticketSSConciliado, fetchingTicketSSConciliado, onGetTicketSS} = props;
    const folio = ticketSSConciliado?.solicitudServicioByIdSolicitudServicio?.folio;
    
    const fecha = new Date();
    let anno = fecha.getFullYear();
    let annoSt = anno.toLocaleString('en-US');
    const addOn = 'SS' + annoSt.substr(-2) + '-';

    const onSearch = (value) => {
        onGetTicketSS(addOn + value);
    }; 

    return (
      <>
        <Row justify="space-around">
          <Col span={11}>
            <Card
              className="cardCatalogos"
              title={<div className="headerLista">Nuevo Folio Error</div>}
            >
                <Spin spinning={fetchingTicketSSConciliado}>
                    <Search addonBefore={addOn} placeholder="No. Ticket" allowClear onSearch={onSearch} style={{ width: '100%' }} enterButton={<SearchOutlined />}/>
                    <Divider/>
                    <Formulario id={1} disabled={false} ticketSSConciliado={ticketSSConciliado} action="Crear" >
                        <Button className="nuevoUsuario" style={{ width: '100%' }} htmlType="submit">Guardar Folio</Button>
                    </Formulario>
                </Spin>
            
            </Card>
          </Col>

          <Col span={11}>
            <Card
              className="cardCatalogos"
              title={<div className="headerLista">Consulta Ticket</div>}
            >
                <Spin spinning={fetchingTicketSSConciliado}>
                    <Row>
                        <Col span={8}>
                        <div className="headerLista">Folio: </div>
                        </Col>
                        <Col span={12}>
                            <Input value={folio} disabled />
                        </Col>
                    </Row>
                    <Divider/>
                    
                    <Formulario id={2} disabled={true} ticketSSConciliado={ticketSSConciliado} action="Crear"/>
                </Spin>
            </Card>
          </Col>
        </Row>
      </>
    );
}

const mapStateToProps = state => {
    return {
        ticketSSConciliado:  state.MecanismosDePagosReducer.ticketSSConciliado,
        fetchingTicketSSConciliado:  state.MecanismosDePagosReducer.fetchingTicketSSConciliado,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetTicketSS: (busqueda) => {
            dispatch({ type: 'TICKET_SS_CONCILIADO_REQUEST', busqueda});
        },
        onLimpiarTicketSS: () => {
            dispatch({ type: 'TICKET_SS_CONCILIADO_SUCCESS', ticketSSConciliado:null });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (NuevoFolioError);