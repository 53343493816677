import React, { Component } from 'react';
import { Form, Input, Button, Col, Spin, Modal, Select} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalNuevaReferenciaDeEstanderes extends Component {
     formRef = React.createRef();
     state = {
          claseReferenciaSeleccionado: '',
     }
     render() {
          const { 
               fetchingNuevaReferenciaEstandar, 
               showModalNuevaReferenciaEstandar, 
               onShowModalNuevaReferenciaEstandar,
               onNuevaReferenciaDeEstandares,  
               page, 
               limpiarFormulario, 
               listaReferenciasDeEstandaresActivos,
          } = this.props

          const onFinish = formulario => {
               console.log('Success:', formulario);
               onNuevaReferenciaDeEstandares(formulario, page);
          }

          if (limpiarFormulario && limpiarFormulario === 'crearReferenciaEstandar') {
               this.formRef.current.resetFields();
               this.props.onLimpiarFormulario();
               this.state.claseReferenciaSeleccionado = '';
          }

          const onFinishFailed = errorInfo => {
               console.log('Failed: ', errorInfo);
          };

          const handleCancel = () => {
               onShowModalNuevaReferenciaEstandar();
               this.setState({
                    claseReferenciaSeleccionado: ''
                });
               this.formRef.current.resetFields();
          }

          const seleccionClaseReferencia = (value) => {
               console.log('value: ', value)
               let claseReferenciaSeleccionado = false;

                if(value === 'General'){
                    claseReferenciaSeleccionado = false;
                } else{
                    claseReferenciaSeleccionado = true;
                }

                this.setState({
                    claseReferenciaSeleccionado: claseReferenciaSeleccionado,
                });
                console.log('claseReferenciaSeleccionado: ', claseReferenciaSeleccionado);
          }

          return (
               <Modal
                    visible={showModalNuevaReferenciaEstandar}
                    title='Nueva Referencia'
                    onCancel={handleCancel}
                    footer={false}
               >
                    <Spin spinning={fetchingNuevaReferenciaEstandar}>
                         <Form {...layout}
                         name='formulario'
                         onFinish={onFinish}
                         
                         ref={this.formRef}
                         >

                              <Form.Item
                              label="Referencia"
                              name="referencia"
                              rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                              >
                                   <Input
                                   pattern="^([ñíóáéúÁÉÓÍÚa-zA-Z])+([ ])+([0-9]{1,2})$"
                                   title="Referencia sin carácteres especiales" 
                                   maxLength={80} 
                                   placeholder='Referencia'/>
                              </Form.Item>

                              <Form.Item
                              label="Clase Referencia"
                              name="claseReferencia"
                              rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                              
                              >
                                   <Select allowClear
                                   style={{ width: '100%' }}
                                   placeholder="Selecciona la clase referencia"
                                   onChange={seleccionClaseReferencia}
                                   >
                                        <Option value="General">General</Option>
                                        <Option value="Específica">Específica</Option>
                                   </Select>
                              </Form.Item>

                              {this.state.claseReferenciaSeleccionado ===  true ? (
                                   <Form.Item
                                   label="Subnivel de Referencia"
                                   name="subnivel"
                                   rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                   >
                                        <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el subnivel de referencia"
                                        >
                                             <Option value="01">01</Option>
                                             <Option value="02">02</Option>
                                             {/* <Option value="No tiene subnivel">No tiene subnivel</Option> */}
                                        </Select>
                                   </Form.Item>
                              ): false}

                              <Form.Item
                              label="Descripción"
                              name="descripcion"
                              rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                   <TextArea
                                   pattern="^[ñíóáéú a-zA-Z ]+$"
                                   title="Descripción sin carácteres especiales" 
                                   maxLength={150} 
                                   placeholder='Descripción'
                                   autoSize />
                              </Form.Item>

                         <Col span={12}>
                              <Button className="btnFiltrar" htmlType="submit">Crear Referencia</Button>
                         </Col>
                         </Form>
                    </Spin>
               </Modal>
          )
     }
}

const mapStateToProps = state => {
     return {
          fetchingNuevaReferenciaEstandar: state.MecanismosDePagosReducer.fetchingNuevaReferenciaEstandar,
          showModalNuevaReferenciaEstandar: state.MecanismosDePagosReducer.showModalNuevaReferenciaEstandar,
          page: state.MecanismosDePagosReducer.page,
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          listaReferenciasDeEstandaresActivos: state.MecanismosDePagosReducer.listaReferenciasDeEstandaresActivos,
     };
};

const mapDispatchToProps = dispatch => {
     return {
          onShowModalNuevaReferenciaEstandar: () => {
               dispatch({ type: 'SHOW_MODAL_NUEVA_REFERENCIA_ESTANDAR', showModalNuevaReferenciaEstandar: false });
          },
          onNuevaReferenciaDeEstandares: (formulario, page) => {
               dispatch({ type: 'NUEVA_REFERENCIA_ESTANDAR_REQUEST', formulario: formulario, page: page });
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
          }
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevaReferenciaDeEstanderes);