import React from 'react';
import { connect } from 'react-redux';
import { Table, Row, Col, Button, Input, Switch, Modal, Form, Select, Tooltip } from 'antd';
import { EditOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoTema from './ModalNuevoTema';
import ModalEditarTema from './ModalEditarTema';

const { confirm } = Modal;
const { Option } = Select;
class CatalogoTemasContainer extends React.Component {
    state = {
        page: 1,
        busqueda: {},
    }

    componentDidMount () {
        this.props.onListaTemaBusqueda(1, {});
        this.props.onListaCentroCostos();
    }

    render () {
        const {fetchingListaTemasBusqueda, listaTemasBusqueda, listaTemasBusquedaCantidad, 
            onEstadoTema, onListaTemaBusqueda, onModalNuevoTema, onModalEditarTema, listaCentroDeCostosActivos,
            fetchingListaCentroDeCostosActivos,
        } = this.props;

            
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columns = [{
            title: 'Gerencia',
            dataIndex: 'gerencia',
            key: 'gerencia',
            width: '20%',
        },{
            title: 'Centro de costos',
            dataIndex: 'centroCosto',
            key: 'centroCosto',
            width: '30%',
        },{
            title: 'Tema',
            dataIndex: 'tema',
            key: 'tema',
            width: '30%',
        },{
            title: 'Activo',
            dataIndex: 'activo',
            key: 'activo',
            width: '10%',
                align: 'center',
            render: (text, record) => (
                <Switch checked={text} disabled={!permisosUsuario.temaActivoinactivo} onChange = {() => handleDesactivar(record)}/>
            ),
        }, {
            title: 'Editar',
            key: 'editar',
            width: '10%',
            align: 'center',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.editarTema} onClick={() => onEditar(record)}><EditOutlined /></a>
            ),
        }];

        const handleDesactivar = (key) => {
            let page = this.state.page;
            let busqueda = this.state.busqueda;
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del tema "' + key.tema + '"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoTema(key.idTema, page, busqueda);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }

        const onFinish = (busqueda) =>{
            console.log(busqueda);
            this.setState({
                busqueda,
            });
            this.props.onListaTemaBusqueda(this.state.page, busqueda);
        };
        
        const onNuevaAsignacion = () => {
            onModalNuevoTema(this.state.page, this.state.busqueda);
        }

        const onEditar = (temaSeleccionado) => {
            console.log('temaSeleccionado', temaSeleccionado)
            onModalEditarTema(temaSeleccionado, this.state.page, this.state.busqueda);
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            onListaTemaBusqueda(pagination.current, this.state.busqueda);
        }

        return (
            <div>
                <Row justify='space-around'>
                    <Col span={22}>
                        <Form
                            name="formulario"
                            ref={this.formRef}
                            onFinish={onFinish}
                        >
                            <Row justify='space-around' gutter={[8,8]}>
                                <Col span={11}>
                                    <Form.Item
                                        name="tema"
                                    >
                                        <Input className='inputFiltro' placeholder='Ingresa el nombre del tema'/>
                                    </Form.Item>
                                </Col>

                                <Col span={11}>
                                    <Form.Item
                                        name="centro"
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el centro de costo"
                                            loading={fetchingListaCentroDeCostosActivos}
                                        >
                                            {listaCentroDeCostosActivos.map(option => 
                                            <Option key={option.centroCosto}>
                                                <Tooltip title={option.centroCosto}>
                                                    {option.centroCosto}
                                                </Tooltip>
                                            </Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={1}>
                                    <Button className="btnFiltrar" shape="circle" icon={ <SearchOutlined />} htmlType="submit" />
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    {/* <Col span={8}>
                        <Button onClick={onNuevaAsignacion} disabled={!permisosUsuario.crearTema} className='nuevoUsuario' style={{ width: '100%' }}>Nuevo Tema</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nuevo Tema' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevaAsignacion} disabled={!permisosUsuario.crearTema} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                </Row>

                <Row justify='space-between'>
                    <Col span={24}>
                        <Table
                            size='small'
                            rowKey='idTema'
                            columns={columns}
                            dataSource={listaTemasBusqueda}
                            loading={fetchingListaTemasBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaTemasBusquedaCantidad, simple: true, current: this.state.page }}
                        /> 
                    </Col>
                </Row>
                <ModalNuevoTema/>
                <ModalEditarTema/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaTemasBusqueda: state.EvaluacionDeLaGestionReducer.fetchingListaTemasBusqueda,
        listaTemasBusqueda: state.EvaluacionDeLaGestionReducer.listaTemasBusqueda,
        listaTemasBusquedaCantidad: state.EvaluacionDeLaGestionReducer.listaTemasBusquedaCantidad,
        fetchingListaCentroDeCostosActivos: state.CatalogosGeneralesReducer.fetchingListaCentroDeCostosActivos,
        listaCentroDeCostosActivos: state.CatalogosGeneralesReducer.listaCentroDeCostosActivos,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onListaTemaBusqueda: (page, busqueda) => {
            dispatch({ type: "LISTA_TEMAS_BUSQUEDA_REQUEST", page, busqueda });
        },
        onListaCentroCostos: () => {
            dispatch({ type: 'LISTA_CENTRO_COSTOS_ACTIVOS_REQUEST' });
        },
        onEstadoTema: (idTema, page, busqueda) => {
            dispatch({ type: "ESTADO_TEMA_REQUEST", idTema, page, busqueda });
        },
        onModalNuevoTema: (page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_TEMA", showModalNuevoTema: true, page, busqueda });
        },
        onModalEditarTema: (temaSeleccionado, page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_TEMA", showModalEditarTema: true, temaSeleccionado, page, busqueda });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoTemasContainer);