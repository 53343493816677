import React, { Component } from 'react';
import {Modal, Col, Button, Spin, Form, Input, InputNumber } from 'antd'
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { TextArea } = Input;
class ModalModificarProducto extends Component {
    state = {
        fetchingModificar: false,
    }
    render() { 
        const {
            showModalModificarProducto,
            closeModal,
            productoSeleccionadoModificar, 
            listaFolioVC,
            onGuardarDatos
        } = this.props;

        const onFinish = (formulario) => {
            console.log('form', formulario);
            let listaProductosLocal = listaFolioVC 
            console.log('listaProductosLocal', listaProductosLocal)
            onGuardarDatos(null);
            for(let i in listaProductosLocal){
                if(listaProductosLocal[i].idIngresoAlmacenCalidad === productoSeleccionadoModificar.idIngresoAlmacenCalidad){
                    console.log('entro aqui', listaProductosLocal[i])
                    listaProductosLocal[i] = Object.assign(listaProductosLocal[i], formulario)
                }
            }
            console.log('lista seleccionados fuera del for', listaProductosLocal);
            onGuardarDatos(listaProductosLocal);
            this.setState({
                fetchingModificar: true
            })
            closeModal();
            this.setState({
                fetchingModificar: false
            })
        }

        const handleCancel = () => {
            closeModal();
        }

        return ( 
            <div>
                <Modal
                    visible={showModalModificarProducto}
                    title='Ingresar datos de rechazo del producto '
                    onCancel={handleCancel}
                    footer={false}
                    width={'40%'}
                >
                    {productoSeleccionadoModificar ? (
                        <Spin spinning={this.state.fetchingModificar}>
                        <Form {...layout}
                                name='formulario'
                                initialValues={productoSeleccionadoModificar}
                                onFinish={onFinish}
                                ref={this.formRef}
                            >
                                <Form.Item
                                      label="Motivo de Rechazo"
                                      name="motivoRechazo"
                                      rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                   <TextArea
                                        maxLength={200}
                                        placeholder='Motivo de rechazo'
                                        autoSize />
                                </Form.Item>
                            
                                 <Form.Item
                                    label="Cantidad rechazada"
                                    name="cantidadRechazada"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                      <InputNumber min={0} placeholder='Cantidad' max={productoSeleccionadoModificar.cantidad}/>
                                </Form.Item>
    
                        
                                <Col span={24}>
                                   <Button htmlType="submit" className='nuevoUsuario'>Guardar Cambios</Button>
                                </Col>
                                
                            </Form>
                        </Spin>
                    ): false}
                    

                </Modal>
            </div>
         );
    }
}

const mapStateToProps = state => {
    return {
        showModalModificarProducto: state.AlmacenesReducer.showModalModificarProducto,
        productoSeleccionadoModificar: state.AlmacenesReducer.productoSeleccionadoModificar,
        page: state.AlmacenesReducer.page,
        busqueda: state.AlmacenesReducer.busqueda,
        listaFolioVC: state.AlmacenesReducer.listaFolioVC
    };
};
const mapDispatchToProps = dispatch => {
    return {
        closeModal: () => {
            dispatch({ type: 'SHOW_MODAL_MODIFICAR_PRODUCTO', showModalModificarProducto: false })
        },
        onGuardarDatos: (listaProductosLocal) => {
            dispatch({ type: 'ACTUALIZAR_TABLA_PRODUCTOS_VERIFICACION' , listaFolioVC: listaProductosLocal})
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalModificarProducto)
