import React from 'react';
import { Row, Col, Card, Table, Select, Tooltip, Button, Form, Spin } from 'antd';
import { EditOutlined, CalculatorOutlined, EyeOutlined, LockOutlined, UnlockOutlined, PlusCircleOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import ModalDetalleMesCalendario from './ModalDetalleMesCalendario';
import ModalCrearMesCalendario from './ModalCrearMesCalendario';
import ModalEditarMesCalendario from './ModalEditarMesCalendario';

import moment from 'moment';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';


const { Option } = Select;

class AñoMesCalendarioContainer extends React.Component {
    formRef = React.createRef();
    componentDidMount(){
        if(this.props.listaInfoProyecto.idProyecto){
            this.props.onGetListaAñosCalendario(this.props.listaInfoProyecto);
        }
        else{
            this.props.onRequestInfoProyecto()
        }
    }
    state={
        busqueda: '',
    }

    render() {
        const {
            fetchingListaMesesCalendario, listaMesesCalendario, listaAñosCalendario, onShowModalDetalle,
            onGetDetalleMesCalendario, onCrearMesCalendario, listaInfoProyecto, onAgregarPPL, onCerrarMes,
            onCalcularMes, onExportarExcel, onShowModalEditar,
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        
        const columns = [
            {title: 'Mes', dataIndex: 'mes', key: 'mes', width: '10%'},
            {title: <Tooltip title="Número de días del mes i">dmi</Tooltip>, dataIndex: 'dmi', key: 'dmi', width: '7%', align: 'center'},
            {title: <Tooltip title="Índice Nacional de Precios al Consumidor">INPC i-1</Tooltip>, dataIndex: 'inpc', key: 'inpc', align: 'center', width: '7%'},
            {title: <Tooltip title="Suma de los PPL"><a className='pri'>pri</a></Tooltip>, dataIndex: 'pri', key: 'pri', align: 'center', width: '7%',
                render: (text, record) => (
                    <a className='pri' disabled={!permisosUsuario.verSumaDePpls ? true : !record.estadoMes} onClick={() => agregarPPL(record)}>{text}</a>
                )
            },
            {title: <Tooltip title="Promedio diario de los PPLs en el mes"><a className='naranja'>Pti</a></Tooltip>, dataIndex: 'pti', key: 'pti', align: 'center',  width: '7%',},
            {title: <Tooltip title="Diferencia entre PPLs mínimos y número de PPLs promedio en el mes"><a className='naranja'>Pvi</a></Tooltip>, dataIndex: 'pvi', key: 'pvi', align: 'center', width: '7%'},
            {title: <Tooltip title="Pago Mensual de Inversión"><a className='naranja'>PMI</a></Tooltip>, dataIndex: 'pmi', key: 'pmi', align: 'center', width: '10%'},
            {title: <Tooltip title="Pago Mensual de Referencia"><a className='naranja'>PMR</a></Tooltip>, dataIndex: 'pmr', key: 'pmr', align: 'center', width: '10%'},
            {title: <Tooltip title="Deducción Máxima Aplicable"><a className='naranja'>DMA</a></Tooltip>, dataIndex: 'dma', key: 'dma', align: 'center', width: '10%'},
            { title: 'Estado', align:'center', render: (record) => (<>
                { record.estadoMes ? (
                    <Tooltip title='Abierto'> <UnlockOutlined className='editarIcono'/> </Tooltip>
                ):(
                    <Tooltip title='Cerrado'> <a className='editarIcono' disabled><LockOutlined /></a> </Tooltip>
                ) }
            </>) },
            {title: 'Acciones', dataIndex: 'pri', key: 'pri', width: '15%', align: 'center',
                render: (text, record) => (
                    <Row justify='space-around'>
                        <Col span={6}>
                            <a className='editarIcono' disabled={!permisosUsuario.editarMesCalendario ? true : !record.estadoMes} onClick={() => onEditar(record)}><EditOutlined /></a>
                        </Col>
                        <Col span={6}>
                            <a className='editarIcono' disabled={!permisosUsuario.detallesDelMes} onClick={() => onDetalles(record)}><EyeOutlined /></a>
                        </Col>
                        <Col span={6}>
                            {record.pri === "0" ? (
                            <Tooltip title='Es necesario capturar pri'>
                                <div>
                                    <a className='editarIcono' disabled={true}>
                                        <CalculatorOutlined />
                                    </a>
                                </div>
                                
                            </Tooltip>
                            ):(
                            <a className='editarIcono' disabled={!permisosUsuario.calcularMes ? true: !record.estadoMes } onClick={() => onCalcular(record)}>
                                <CalculatorOutlined />
                            </a>
                            )}
                        </Col>
                        {/* Acción de cerrar mes-i dentro del CU, anteriormente se podía realizar desde aquí, pero para evitar errores en cálculos, se decidió generar el cierre cuando se procese el cálculo de deductivas para el cierre de mes */}
                        {/* <Col span={6}>
                            {record.estadoMes ? (
                                <a className='editarIcono' disabled={(!record.pplCalendariosByIdMesCalendario) ? true : (record.pplCalendariosByIdMesCalendario.length === record.dmi && record.anio + '-' + record.idMes < moment().format('YYYY-MM-DD').split('-')[0] + '-' + parseInt(moment().format('YYYY-MM-DD').split('-')[1])) ? false : true } onClick={() => onCerrar(record)}><UnlockOutlined /></a>
                            ):(
                            <a className='editarIcono' disabled={true} onClick={() => onEditar(record)}><LockOutlined /></a>
                            )}
                        </Col> */}
                    </Row>
                )
            },
        ];

        const agregarPPL = (value) => {
            let dias = new Date(value.anio, value.idMes, 0).getDate()
            value.listaDiasMes = [];
            value.listaDiasMes = Array.from({length: value.dmi}, (v, i) => dias - i);
            value.listaDiasMes.reverse();

            value.objetoFormulario = {'nuevo': true}
            let numPplMes = 0;
            let terminado = false;
            if(value.pplCalendariosByIdMesCalendario){
                if(value.pplCalendariosByIdMesCalendario.length === value.dmi){
                    terminado = true;
                }
                numPplMes = value.pplCalendariosByIdMesCalendario[0].numPplMes;
                for(let i in value.pplCalendariosByIdMesCalendario){
                    let idMes = 'numPpl-' + value.pplCalendariosByIdMesCalendario[i].diaMes;
                    value.objetoFormulario[idMes] = value.pplCalendariosByIdMesCalendario[i].numPpl;
                }
            }
            
            if(this.state.busqueda === ''){
                this.setState({
                    busqueda: listaAñosCalendario[0],
                })
                console.log('listaAñosCalendario[0]', listaAñosCalendario[0])
                onAgregarPPL(listaAñosCalendario[0], value, numPplMes, terminado);
            }
            else{
                onAgregarPPL(this.state.busqueda, value, numPplMes, terminado)
            }
            
        }
        const onDetalles = (value) => {
            if(this.state.busqueda === ''){
                this.setState({
                    busqueda: listaAñosCalendario[0],
                })
                onShowModalDetalle(value, listaAñosCalendario[0]);
            }
            else{
                onShowModalDetalle(value, this.state.busqueda);
            }
            onGetDetalleMesCalendario(value.idMesCalendario, value.idAnioCalendario);
        }
        const onCerrar = (value) => {
            console.log('value');
            console.log(value);
            if(this.state.busqueda === ''){
                this.setState({
                    busqueda: listaAñosCalendario[0],
                })
                onCerrarMes(value.idMesCalendario, listaAñosCalendario[0], listaInfoProyecto );
            }
            else{
                onCerrarMes(value.idMesCalendario, this.state.busqueda, listaInfoProyecto );
            }
        }
        const onEditar = (value) => {
            console.log('value');
            console.log(value);
            let fechaAnterior = listaInfoProyecto.fechaInicioOperacion.format('YYYY-MM-DD');
            if(fechaAnterior.split('-')[0] + '-' + parseInt(fechaAnterior.split('-')[1]) !== value.anio + '-' + value.idMes){
                console.log('fechaAnterior', fechaAnterior)

                fechaAnterior = value.anio + '-' + parseInt(value.idMes) +'-' + '01';
            }
            console.log('fechaAnterior');
            console.log(fechaAnterior);

            if(this.state.busqueda === ''){
                this.setState({
                    busqueda: listaAñosCalendario[0],
                })
                onShowModalEditar(listaAñosCalendario[0], fechaAnterior, value);
            }
            else{
                onShowModalEditar(this.state.busqueda, fechaAnterior, value);
            }
        }
        const onCalcular = (value) => {
            if(this.state.busqueda === ''){
                this.setState({
                    busqueda: listaAñosCalendario[0],
                })
                onCalcularMes(value, listaAñosCalendario[0], listaInfoProyecto );
            }
            else{
                onCalcularMes(value, this.state.busqueda, listaInfoProyecto );
            }
        }

        const onAñoCalendario = (value) => {
            this.setState({
                busqueda: value,
            })
            this.formRef.current.setFieldsValue({ 'anio': value });
            this.props.onGetListaMesesCalendario(value);
        }

        const crearMesCalendario = () => {
            console.log(listaMesesCalendario)
            let fechaAnterior = listaInfoProyecto.fechaInicioOperacion.format('YYYY-MM-DD');
            if(listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario){
                if(listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[0].mesByIdMes.idMes === 12){
                    fechaAnterior = (parseInt(listaMesesCalendario[0].anio) + 1) + '-01-01'
                }
                else{
                    fechaAnterior = listaMesesCalendario[0].anio + '-' + (parseInt(listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[0].mesByIdMes.idMes) + 1) +'-' + '01'
                }
            }
            console.log('fechaAnterior');
            console.log(fechaAnterior);
            if(this.state.busqueda === ''){
                this.setState({
                    busqueda: listaAñosCalendario[0],
                })
                onCrearMesCalendario(listaAñosCalendario[0], fechaAnterior);
            }
            else{
                onCrearMesCalendario(this.state.busqueda, fechaAnterior);
            }
        }

        const exportarExcel = () => {
            if(this.state.busqueda === ''){
                this.setState({
                    busqueda: listaAñosCalendario[0],
                })
                onExportarExcel(listaAñosCalendario[0]);
            }
            else{
                onExportarExcel(this.state.busqueda);
            }
            
        }
        return (
            <Card className="cardCatalogos" title={<div className='headerLista'>Año/Mes Calendario</div>}>
                <br/>
                <Spin spinning={fetchingListaMesesCalendario}>
                    {listaMesesCalendario[0] ? (
                    <>
                        <Form name="formulario"
                            ref={this.formRef}
                            initialValues={listaMesesCalendario[0]}
                        >
                            <Row justify='end'>
                                {listaAñosCalendario[0] ? (
                                <Col span={8}>
                                    <Form.Item
                                        name="anio"
                                    >
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el año"
                                            onChange={onAñoCalendario}
                                        >
                                            {listaAñosCalendario.map(option => <Option key={option}>{option}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                ):false}
                            </Row>
                        </Form>
                        <Row justify='end'>
                            <Col span={2}>
                                {listaMesesCalendario[0].dai === 1 ? (
                                <h3 style={{textAlign:'right'}}>{listaMesesCalendario[0].dai} día</h3>
                                ):(
                                <h3 style={{textAlign:'right'}}>{listaMesesCalendario[0].dai} días</h3>
                                )}
                            </Col>
                            <Col span={1} offset={1}>
                                {listaMesesCalendario[0].estadoAnio ? (
                                <a className='editarIcono' disabled={true} ><UnlockOutlined /></a>
                                ):(
                                <a className='editarIcono' disabled={true} ><LockOutlined /></a>
                                )}
                            </Col>
                            <Col span={24}>
                                <br/>
                                {listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario ? (
                                <Table 
                                    size="small" 
                                    rowKey="idAnioCalendario" 
                                    columns={columns}
                                    dataSource={listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario}
                                    loading={fetchingListaMesesCalendario}
                                    locale={{ emptyText: 'Sin datos' }}
                                    pagination={false}
                                    
                                />      
                                ):false}   
                            </Col>
                        </Row>
                    </>
                    
                    ):false}
                    <br/>
                    <Row justify='end' style={{paddingRight:'1em'}}>
                        <Col span={2}>
                            {console.log(listaAñosCalendario, listaMesesCalendario, listaInfoProyecto.fechaFin)}
                            {listaAñosCalendario[0] && listaMesesCalendario[0] && listaInfoProyecto.fechaFin ? (
                            <Row justify='space-around'>
                                <Col>
                                    <Button className='iconSecundary' shape="circle" disabled={!permisosUsuario.exportarExcelMesDelCalendario ? true : !listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario} onClick={exportarExcel}>
                                        <FileExcelOutlined />
                                    </Button>
                                </Col>
                                <Col>
                                    <Button className='iconTer' disabled={!permisosUsuario.crearMesCalendario ? true : listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario ? (listaAñosCalendario[0] + '-' + listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[0].mesByIdMes.idMes === parseInt(listaInfoProyecto.fechaFin.format('YYYY-MM-DD').split('-')[0]) + '-' + parseInt(listaInfoProyecto.fechaFin.format('YYYY-MM-DD').split('-')[1])) ? true: (!listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario ) ? false: ((listaAñosCalendario[0] === listaMesesCalendario[0].anio ) ? false : true): false} shape="circle" onClick={crearMesCalendario}>
                                        <PlusCircleOutlined />
                                    </Button>
                                </Col>
                            </Row>
                            ):false}
                        </Col>
                    </Row>
                    <ModalDetalleMesCalendario/>
                    <ModalCrearMesCalendario/>
                    <ModalEditarMesCalendario/>
                </Spin>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaMesesCalendario: state.MecanismosDePagosReducer.fetchingListaMesesCalendario,
        listaMesesCalendario: state.MecanismosDePagosReducer.listaMesesCalendario,
        listaMesesCalendarioCantidad: state.MecanismosDePagosReducer.listaMesesCalendarioCantidad,
        fetchingListaAñosCalendario: state.MecanismosDePagosReducer.fetchingListaAñosCalendario,
        listaAñosCalendario: state.MecanismosDePagosReducer.listaAñosCalendario,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onGetListaMesesCalendario: (busqueda) => {
            dispatch({ type: "LISTA_MESES_CALENDARIO_REQUEST", busqueda });
        },
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST', añoMesCalendario: true });
        },
        onGetListaAñosCalendario: (listaInfoProyecto) => {
            dispatch({ type: 'LISTA_AÑO_CALENDARIO_REQUEST', listaInfoProyecto });
        },
        onShowModalDetalle: (mesCalendarioSeleccionado, busqueda) => {
            dispatch({ type: 'SHOW_MODAL_DETALLE_MES_CALENDARIO', showModalDetalleMesCalendario: true, mesCalendarioSeleccionado, busqueda })
        },
        onGetDetalleMesCalendario: (idMes, idAño) => {
            dispatch({ type: 'DETALLE_MES_CALENDARIO_REQUEST', idMes, idAño })
        },
        onCrearMesCalendario: (busqueda, fechaAnterior) => {
            dispatch({ type: 'SHOW_MODAL_CREAR_MES_CALENDARIO', showModalCrearMesCalendario: true, busqueda, fechaAnterior })
        },
        onAgregarPPL: (busqueda, mesCalendarioSeleccionado, numPplMes, terminado) => {
            dispatch({ type: 'SHOW_MODAL_AGREGAR_PPL', showModalAgregarPPL: true, busqueda, mesCalendarioSeleccionado, numPplMes, terminado })
        },
        onCerrarMes: (idMes, busqueda, listaInfoProyecto) => {
            dispatch({ type: 'CERRAR_MES_CALENDARIO_REQUEST', idMes, busqueda, listaInfoProyecto });
        },
        onCalcularMes: (mesCalendarioSeleccionado, busqueda, listaInfoProyecto) => {
            dispatch({ type: 'CALCULAR_MES_CALENDARIO_REQUEST', mesCalendarioSeleccionado, busqueda, listaInfoProyecto })
        },
        onExportarExcel: (año) => {
            dispatch({ type: 'EXPORTAR_EXCEL_MES_CALENDARIO_REQUEST', año })
        },
        onShowModalEditar: (busqueda, fechaAnterior, mesCalendarioEditar) => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_MES_CALENDARIO', showModalEditarMesCalendario: true, busqueda, fechaAnterior, mesCalendarioEditar })
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AñoMesCalendarioContainer);