//Archivo Saga que ejecuta las acciones del Login

import { takeLatest, call, put } from "redux-saga/effects";
import * as loginActions from '../actions/LoginAction';
import * as configuracionYSeguridadActions from '../actions/ConfiguracionYSeguridadActions';
import { loginCall, usuarioDetalleCall, recuperarContraseñaCall, cerrarSesionCall } from "../../services/LoginServices";
import { listaModulosBitacoraByIdModuloCall } from "../../services/ConfiguracionYSeguridadServices";

//********************** LOGIN *****************************
function* getLogin(action) {
    try {
        console.log(action);
        const response = yield call(loginCall, action.loginInfo);
        console.log(response);
        
        if(response.status==200){
            let token = response.headers.authorization; 
            console.log('token', token)
            console.log(token)
            const jwt_decode = require('jwt-decode');
            const decoded = jwt_decode(token);
            let hoy = null;
            if(decoded.exp){
                hoy = new Date(decoded.exp * 1000);
                console.log(hoy);
            }
            
            //Con el token que nos responde el servicio se manda a llamar el WS para traer el detalle del usuario logeado
            let responseUserInfo = yield call(usuarioDetalleCall,token);
            console.log('response de responseUserInfo');
            console.log(responseUserInfo);

            if(responseUserInfo.status==200){
                if(response.data.success_message !== 'Success' && response.data.success_status === 200){
                    const showWarningMsg = true;
                    console.log(response.data.success_message);
                    const textMessage = response.data.success_message;
                    console.log(textMessage);

                    yield put({ type: configuracionYSeguridadActions.SHOW_WARNING_MSG, showWarningMsg, textMessage });
                }
                console.log(`responseUserInfo.data[0].roles`, responseUserInfo.data[0].roles)
                function camello(str){
                    return str.split(' ').map(function(Word,index){
                        // If it is the first Word make sure to lowercase all the chars.
                        if(index == 0){
                            return Word.toLowerCase();
                        }
                        // If it is not the first Word only upper case the first char and lowercase the rest.
                        return Word.charAt(0).toUpperCase() + Word.slice(1).toLowerCase();
                    }).join('');
                }
                  
                if(responseUserInfo.data[0].roles.length>0){
                    let permisosUsuario = {};
                    permisosUsuario.verConfiguracionYSeguridad = false;
                    permisosUsuario.verInformacionOperativa = false;
                    permisosUsuario.verAlmacenes = false;
                    permisosUsuario.verCatalogosGenerales = false;
                    permisosUsuario.verRecursosHumanos = false;
                    permisosUsuario.verServiciosVariables = false;
                    permisosUsuario.verSolicitudDeServicios = false;
                    permisosUsuario.verServiciosPlanificados = false;
                    permisosUsuario.verServiciosPublicos = false;
                    permisosUsuario.verGestionDeCalidad = false;
                    permisosUsuario.verEvaluacionDeLaGestion = false;
                    permisosUsuario.verMonitoreoIntegral = false;
                    permisosUsuario.verMecanismosDePago = false;
                    permisosUsuario.verRMDP = false;
                    for(let i in responseUserInfo.data[0].roles){
                        let rp = responseUserInfo.data[0].roles[i];
                        for(let x in rp.permisosByIdRol){
                            const permiso = camello(rp.permisosByIdRol[x].descripcion.trim().split('/').join(''));
                            permisosUsuario[permiso] = true;
                            if(rp.permisosByIdRol[x].eventoByIdEvento.idModulo === 1){
                                permisosUsuario.verConfiguracionYSeguridad = true;  
                            }
                            else if(rp.permisosByIdRol[x].eventoByIdEvento.idModulo === 3){
                                permisosUsuario.verInformacionOperativa = true;
                            }
                            else if(rp.permisosByIdRol[x].eventoByIdEvento.idModulo === 5){
                                permisosUsuario.verAlmacenes = true;
                            }
                            else if(rp.permisosByIdRol[x].eventoByIdEvento.idModulo === 6){
                                permisosUsuario.verCatalogosGenerales = true;
                            }
                            else if(rp.permisosByIdRol[x].eventoByIdEvento.idModulo === 7){
                                permisosUsuario.verRecursosHumanos = true;
                            }
                            else if(rp.permisosByIdRol[x].eventoByIdEvento.idModulo === 8){
                                permisosUsuario.verServiciosVariables = true;
                            }
                            else if(rp.permisosByIdRol[x].eventoByIdEvento.idModulo === 9){
                                permisosUsuario.verSolicitudDeServicios = true;
                            }
                            else if(rp.permisosByIdRol[x].eventoByIdEvento.idModulo === 10){
                                permisosUsuario.verServiciosPlanificados = true;
                            }
                            else if(rp.permisosByIdRol[x].eventoByIdEvento.idModulo === 11){
                                permisosUsuario.verServiciosPublicos = true;
                            }
                            else if(rp.permisosByIdRol[x].eventoByIdEvento.idModulo === 12){
                                permisosUsuario.verGestionDeCalidad = true;
                            }
                            else if(rp.permisosByIdRol[x].eventoByIdEvento.idModulo === 13){
                                permisosUsuario.verEvaluacionDeLaGestion = true;
                            }
                            else if(rp.permisosByIdRol[x].eventoByIdEvento.idModulo === 14){
                                permisosUsuario.verMonitoreoIntegral = true;
                            }
                            else if(rp.permisosByIdRol[x].eventoByIdEvento.idModulo === 15){
                                permisosUsuario.verMecanismosDePago = true;
                            }
                            else if(rp.permisosByIdRol[x].eventoByIdEvento.idModulo === 16){
                                permisosUsuario.verRMDP = true;
                            }
                        }
                    }
                    

                    let vistaDashboard = '';
                    let idModulo = 1;
                    if(permisosUsuario.verConfiguracionYSeguridad){
                        vistaDashboard = 'Configuración y Seguridad';
                    }
                    else if(permisosUsuario.buscarProyectoMod2){
                        vistaDashboard = 'Información del Proyecto'; idModulo = 2;
                    }
                    else if(permisosUsuario.verInformacionOperativa){
                        vistaDashboard = 'Información Operativa'; idModulo = 3;
                    }
                    else if(permisosUsuario.buscarDocumentoBase){
                        vistaDashboard = 'Base de Conocimiento'; idModulo = 4;
                    }
                    else if(permisosUsuario.verAlmacenes){
                        vistaDashboard = 'Almacenes'; idModulo = 5;
                    }
                    else if(permisosUsuario.verCatalogosGenerales){
                        vistaDashboard = 'Catálogos Generales'; idModulo = 6;
                    }
                    else if(permisosUsuario.verRecursosHumanos){
                        vistaDashboard = 'Recursos Humanos'; idModulo = 7;
                    }
                    else if(permisosUsuario.verServiciosVariables){
                        vistaDashboard = 'Servicios Variables'; idModulo = 8;
                    }
                    else if(permisosUsuario.verSolicitudDeServicios){
                        vistaDashboard = 'Solicitud de Servicios'; idModulo = 9;
                    }
                    else if(permisosUsuario.verServiciosPlanificados){
                        vistaDashboard = 'Servicios Planificados'; idModulo = 10;
                    }
                    else if(permisosUsuario.verServiciosPublicos){
                        vistaDashboard = 'Servicios Públicos'; idModulo = 11;
                    }
                    else if(permisosUsuario.verGestionDeCalidad){
                        vistaDashboard = 'Gestión de Calidad'; idModulo = 12;
                    }
                    else if(permisosUsuario.verEvaluacionDeLaGestion){
                        vistaDashboard = 'Evaluación de la Gestión'; idModulo = 13;
                    }
                    else if(permisosUsuario.verMonitoreoIntegral){
                        vistaDashboard = 'Monitoreo Integral'; idModulo = 14;
                    }
                    else if(permisosUsuario.verMecanismosDePago){
                        vistaDashboard = 'Mecanismos de Pago'; idModulo = 15;
                    }
                    else if(permisosUsuario.verRMDP){
                        vistaDashboard = 'RMDP'; idModulo = 16;
                    }

                    const responseModulosBit = yield call(listaModulosBitacoraByIdModuloCall, token, idModulo);
                    if(responseModulosBit.status==200){
                        const listaModulosBitacoraByIdModulo = responseModulosBit.data;
            
                        yield put({ type: configuracionYSeguridadActions.LISTA_MODOULOS_BITACORAS_BY_IDMODULO_SUCCESS, listaModulosBitacoraByIdModulo });
                    }
                    else{
            
                        yield put({ type: configuracionYSeguridadActions.LISTA_MODOULOS_BITACORAS_BY_IDMODULO_FAILURE });
                    }

                    console.log(permisosUsuario);
                    console.log(vistaDashboard);
                    sessionStorage.setItem('token', token);
                    sessionStorage.setItem('hoy', hoy);
                    sessionStorage.setItem('isAuthenticated', true);
                    sessionStorage.setItem('perfilUsuario', responseUserInfo.data[0].roles[0].descripcion);
                    sessionStorage.setItem('userInfo', JSON.stringify(responseUserInfo.data));
                    sessionStorage.setItem('permisosUsuario', JSON.stringify(permisosUsuario));
                    
                    
                    sessionStorage.setItem('vistaDashboard', vistaDashboard)
                    let selectedPerfil = responseUserInfo.data[0].roles[0].descripcion;

                    yield put({ type: loginActions.CHANGE_SELECTED_PROFILE, selectedPerfil});
                    yield put({ type: configuracionYSeguridadActions.CAMBIAR_VISTA_DASHBOARD, vistaDashboard});

                    let usuario = responseUserInfo.data
                    yield put({ type: loginActions.LOGIN_API_CALL_SUCCESS,usuario,token});
                    yield put({ type: loginActions.MODAL_EXPIRAR_SESION, showExpirarSesion: false});
                }
                //El usuario no tiene perfiles disponibles
                else{
                    yield put({ type: loginActions.LOGIN_API_CALL_FAILURE});
                    let selectedPerfil='';
                    yield put({ type: loginActions.CHANGE_SELECTED_PROFILE, selectedPerfil});
                }
            }
            //Falló la llamada al WS
            else{
                let errorMensaje = 'Login correcto, estamos trabajando en esto.';
                yield put({ type: loginActions.LOGIN_API_CALL_FAILURE, errorMensaje});
            }
        }
        else{
            let errorMensaje = '';
            if(response.error_message === 'Bad credentials'){
                errorMensaje = 'Usuario y/o contraseña incorrectas. Por favor intente de nuevo.';
            }
            else{
                errorMensaje=response.error_message;
            }
            yield put({ type: loginActions.LOGIN_API_CALL_FAILURE, errorMensaje});
        }
    
    } catch (error) {
        let errorMensaje = error;
        // dispatch a failure action to the store with the error
        yield put({ type: loginActions.LOGIN_API_CALL_FAILURE, errorMensaje });
    }
}
export function* getLoginSaga() {
    yield takeLatest(loginActions.LOGIN_API_CALL_REQUEST, getLogin);
}

//********************** RECUPERAR CONTRASEÑA *****************************
function* recuperarContraseña(action) {
    try {
        console.log(action);
        const email = action.fetchingReinciar.userName
        const response = yield call(recuperarContraseñaCall, email);
        console.log(response);
        if(response.status==200){
            const showSuccessMsg = true;
            const textMessage = "El correo se ha enviado exitosamente, favor de revisar su bandeja de entrada";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: loginActions.MODAL_RESET_PASSWORD, showModalReset: false });
            yield put({ type: loginActions.RESET_PASSWORD_SUCCESS });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: loginActions.RESET_PASSWORD_FAILURE});
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: loginActions.RESET_PASSWORD_FAILURE });
    }
}
export function* recuperarContraseñaSaga() {
    yield takeLatest(loginActions.RESET_PASSWORD_REQUEST, recuperarContraseña);
}

//********************** Cerrar Sesión *****************************
function* cerrarSesion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const response = yield call(cerrarSesionCall, token);
        console.log(response);
        if(response.status==200){
            yield put({ type: loginActions.LOG_OUT_SUCCESS });
            sessionStorage.clear();
            window.location.reload();
        }
        else{
            yield put({ type: loginActions.LOG_OUT_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: loginActions.LOG_OUT_FAILURE });
    }
}
export function* cerrarSesionSaga() {
    yield takeLatest(loginActions.LOG_OUT_REQUEST, cerrarSesion);
}