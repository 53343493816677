import React from 'react';
import { Spin, message, Form, Row, Col, Button, Modal, Select, Input, Divider, Steps, Alert } from 'antd';
import { connect } from "react-redux";
import { UserOutlined, SolutionOutlined, UserAddOutlined, ArrowRightOutlined, ArrowLeftOutlined, CloseCircleOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { identificacionCall } from "../../../services/SolicitudDeServiciosServices";

const { Option } = Select;
const { Step } = Steps;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };
class Identificacion extends React.Component {
    formRef = React.createRef();

     state = {
          showCrear: false,
          showIngresar: false,
          current: 0,
          usuarioId: null,
          messageError: false,
          fetchingLogin: false,
     }

     render() {
          let currentDate = new Date();
          let saludo = '';

          if(currentDate.getHours() >= 0 &&  currentDate.getHours() < 12){
               saludo='Buenos días, ';
          } else if(currentDate.getHours() >= 12 && currentDate.getHours() < 19) {
               saludo='Buenas tardes, ';
          } else {
               saludo='Buenas noches, ';
          }

          const { 
               limpiarFormulario, 
               onLimpiarFormulario,
               onCloseModal,
               onBuscarUsuario,
               onLimpiarListaUsuario,
               fetchingListaUsuariosParam,
               listaUsuariosParam
          } = this.props;

          let userInfo = sessionStorage.getItem('userInfo');
          if(!userInfo.idUsuario){ userInfo = JSON.parse(userInfo); }
          let usuario = '';
          if(userInfo[0].aMaterno === null){
               usuario = userInfo[0].nombre + ' ' + userInfo[0].aPaterno;
          } else {
               usuario = userInfo[0].nombre + ' ' + userInfo[0].aPaterno + ' ' + userInfo[0].aMaterno;
          }


          const onFinish = formulario => {
               console.log('Success:', formulario);
          };

          if (limpiarFormulario && limpiarFormulario === 'inventarioFisico') {
               onLimpiarFormulario();
          }

          const onFinishFailed = errorInfo => {
               console.log('Failed:', errorInfo);
          };

          const onIngresar = () => {
               let nombreUsuarioLocal = '';
               let idUsuarioLocal = null;
               this.setState({
                    fetchingLogin: true,
               })

               for(let i = 0; i < listaUsuariosParam.length; i++){
                    console.log('usuario en form', this.state.usuarioId);
                    if(listaUsuariosParam[i].idUsuario === this.state.usuarioId){
                         idUsuarioLocal = listaUsuariosParam[i].idUsuario
                         if(listaUsuariosParam[i].aMaterno === null){
                              nombreUsuarioLocal = listaUsuariosParam[i].nombre + ' ' + listaUsuariosParam[i].aPaterno;
                         } else {
                              nombreUsuarioLocal = listaUsuariosParam[i].nombre + ' ' + listaUsuariosParam[i].aPaterno + ' ' + listaUsuariosParam[i].aMaterno;
                         }
                    }
               }

               const values = {
                    'idUsuario': idUsuarioLocal,
                    'pin': this.formRef.current.getFieldValue('pin'),  
               }
                    console.log('values', values)
                    const token = sessionStorage.getItem('token');

                    identificacionCall(token, values).then(response => {
                    console.log('response');
                    console.log(response);
               
                    if(response.status === 200){
                         onCloseModal(nombreUsuarioLocal, false, "", idUsuarioLocal);
                         this.setState({ showIngresar: true, showCrear: false, current: 0  });
                         this.formRef.current.resetFields();
     
                         onLimpiarListaUsuario();
                         this.setState({
                              fetchingLogin: false,
                         })
                    }
                    else{
                         this.setState({
                              fetchingLogin: false,
                         })

                         this.setState({ showIngresar: true, showCrear: false, current: 1, messageError: true  });

                         return;
                    }
               }).catch(error => {
                    console.log(error);
                    message.error('Hubo un error, favor de intentar después');
                    return;
               })
          };

          const onCrear = () => {
               if((this.formRef.current.getFieldValue('usuarioGenerico') === undefined || this.formRef.current.getFieldValue('usuarioGenerico') === '') || (this.formRef.current.getFieldValue('areaPertenece') === undefined || this.formRef.current.getFieldValue('areaPertenece') === '') ){
                    message.error('Favor de llenar los campos anteriores.');
               } else {
                    onCloseModal(this.formRef.current.getFieldValue('usuarioGenerico'), false, this.formRef.current.getFieldValue('areaPertenece'), null);

                    this.setState({ showCrear: true, showIngresar: false, current: 0  });
                    this.formRef.current.resetFields();
               }
          };

          const onAnterior = () => {this.setState({ current: 0, messageError: false })};

          const onSiguiente = () => {
               if(this.formRef.current.getFieldValue('usuario') === undefined || this.formRef.current.getFieldValue('usuario') === ''){
                    message.error('Favor de llenar el campo.');
               } else {
                    const usuarioId = parseInt(this.formRef.current.getFieldValue('usuario'));
                    let nombreUsuarioLocal = '';
                    let idUsuarioLocal = null;
                    this.setState({
                         fetchingLogin: true,
                    })

                    for(let i = 0; i < listaUsuariosParam.length; i++){
                         console.log('usuario en form', usuarioId);
                         if(listaUsuariosParam[i].idUsuario === usuarioId){
                              idUsuarioLocal = listaUsuariosParam[i].idUsuario
                              if(listaUsuariosParam[i].aMaterno === null){
                                   nombreUsuarioLocal = listaUsuariosParam[i].nombre + ' ' + listaUsuariosParam[i].aPaterno;
                              } else {
                                   nombreUsuarioLocal = listaUsuariosParam[i].nombre + ' ' + listaUsuariosParam[i].aPaterno + ' ' + listaUsuariosParam[i].aMaterno;
                              }
                         }
                    }
                    
                    onCloseModal(nombreUsuarioLocal, false, "", idUsuarioLocal);
                    this.setState({ showIngresar: true, showCrear: false, current: 0  });
                    this.formRef.current.resetFields();

                    onLimpiarListaUsuario();
                    this.setState({
                         fetchingLogin: false,
                    })
               }
          };

          const onPIN = () => {
               this.setState({ current: 2 });
          };

          const { current } = this.state;

          const buscarUsuario = (value) => {
               if(value.length>=3){
                   console.log(value)
                   onBuscarUsuario(value);
               }
               else{
                   onLimpiarListaUsuario();
               }
          };
          const onCerrarIdentificacion = () => {
               onCloseModal(null, true, null, null, true);
               this.setState({ current: 0 });
          }

          return (
               <Modal
                    visible={this.props.showModalIdentificacion}
                    title={'Identificación de Usuario'}
                    footer={false}
                    width={'35%'}
                    closable={false}
               >
                    <Form {...layout}
                         name="formulario"
                         onFinish={onFinish}
                         
                         ref={this.formRef}
                    >
                         <Spin spinning={this.state.fetchingLogin}>
                              <Row justify='space-around' style={{ textAlign: 'left' }}>
                                   <Steps
                                        type="navigation"
                                        size="small"
                                        current={current}
                                   >
                                        <Step status="process"  icon={<UserOutlined className="stepIcon"/>} />
                                        <Step status="process"  icon={<SolutionOutlined className="stepIcon"/>} disabled={true}/>
                                        <Step status="process" icon={<UserAddOutlined className="stepIcon"/>} disabled={true}/>
                                   </Steps>

                                   { this.state.current === 0 ? (
                                   <>
                                        <Col span={23}>
                                             <br/>
                                             <div> <h3>
                                                  {saludo + 'está llamando al Levantamiento de Incidenias, le atiende ' + usuario + ', ¿con quién tengo el gusto?' }
                                             </h3> </div>
                                        </Col>

                                        <Col span={23}>
                                             <Form.Item
                                                  label=""
                                                  name="usuario"
                                                  rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                             >
                                                  <Select
                                                       allowClear
                                                       style={{ width: '100%' }}
                                                       placeholder="Escribe el usuario a buscar"
                                                       loading={fetchingListaUsuariosParam}
                                                       showSearch
                                                       filterOption={false}
                                                       onSearch={buscarUsuario}
                                                       notFoundContent={null}
                                                  >
                                                       {listaUsuariosParam.map(option => <Option key={option.idUsuario}>{option.nombre + ' ' + option.aPaterno}</Option>)}
                                                  </Select>
                                             </Form.Item>
                                        </Col>

                                        <Col span={23}>
                                             <Row justify='end'>
                                                  <Col span={6}>
                                                  </Col>
                                                  <Col span={16}>
                                                       <a onClick={onPIN}><p className="resetPassword" style={{align: 'right', textAlign:'right'}}> <strong>¿No cuenta con un usuario?</strong></p></a>
                                                  </Col> 
                                             </Row>
                                        </Col>

                                        <Col span={11}>
                                             <Button type='danger' style={{ width: '100%' }} onClick={onCerrarIdentificacion}>
                                                  Cerrar <CloseCircleOutlined />
                                             </Button>
                                        </Col>

                                        <Col span={11}>
                                             <Button className='btnCargaArchivos' style={{ width: '100%' }} onClick={onSiguiente}>
                                                  Siguiente <ArrowRightOutlined />
                                             </Button>
                                        </Col>   

                                   </>
                                   ): false }
                                   
                                   
                                   { this.state.current === 1 ? (
                                   <>
                                        { this.state.messageError ? (
                                             <Col span={23}>
                                                  <br/>
                                                  <Alert message="PIN incorrecto. Por favor intente de nuevo." type="error" showIcon />
                                             </Col>
                                        ):false }

                                        <Col span={23}>
                                             <br/>
                                             <div> <h3>
                                                  {'¿Me puede proporcionar su PIN para el registro de Solicitudes de Servicio, por favor?'}
                                             </h3> </div>
                                        </Col>

                                        <Col span={23}>
                                             <Form.Item
                                                  name='pin'
                                                  label=''
                                                  rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                             >
                                                  <Input.Password
                                                       visibilityToggle={true}
                                                       maxLength={4}
                                                  />
                                             </Form.Item>
                                        </Col>

                                        <Col span={11}>
                                             <Button className='btnCargaArchivos' style={{ width: '100%' }} onClick={onAnterior}>
                                                  <ArrowLeftOutlined /> Anterior
                                             </Button>
                                        </Col>

                                        <Col span={11}>
                                             <Button className='btnPrueba' style={{ width: '100%' }} onClick={onIngresar}>
                                                  Identificar
                                             </Button>
                                        </Col>
                                   </>
                                   ): false }
                              
                                   { this.state.current === 2 ? (
                                   <>
                                        <Divider orientation={'left'} style={{margin: '10px'}}>
                                             {'Crear Usuario Genérico'}
                                        </Divider>

                                        <Col span={23}>
                                             <Form.Item
                                                  name='usuarioGenerico'
                                                  label='Nombre'
                                                  rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                             >
                                                  <Input />
                                             </Form.Item>
                                        </Col>

                                        <Col span={23}>
                                             <Form.Item
                                                  name='areaPertenece'
                                                  label='Área a la que pertenence'
                                                  rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                             >
                                                  <Input />
                                             </Form.Item>
                                        </Col>

                                        <Col span={11}>
                                             <Button type='danger' style={{ width: '100%' }} onClick={onCerrarIdentificacion}>
                                                  Cerrar <CloseCircleOutlined />
                                             </Button>
                                        </Col>
                                        
                                        <Col span={11}>
                                             <Button className='btnPrueba' style={{ width: '100%' }} onClick={onCrear}>
                                                  Crear Usuario
                                             </Button>
                                        </Col>
                                   </>
                                   ): false }

                              </Row>
                         </Spin>
                    </Form>
               </Modal>
          );
     }
}

const mapStateToProps = state => {
     return {
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          showModalIdentificacion: state.SolicitudDeServiciosReducer.showModalIdentificacion,
          fetchingListaUsuariosParam: state.ConfiguracionYSeguridadReducer.fetchingListaUsuariosParam,
          listaUsuariosParam: state.ConfiguracionYSeguridadReducer.listaUsuariosParam,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
          onBuscarUsuario: (busqueda) => {
               dispatch({ type: 'LISTA_USUARIOS_PARAM_REQUEST', busqueda})
          },
          onLimpiarListaUsuario: () => {
               dispatch({ type: 'LISTA_USUARIOS_PARAM_SUCCESS', listaUsuariosParam: []})
          },
          onCloseModal: (usuarioIdentificacion, identificacion, areaPertenece, idUsuarioReporta, sinIdentificar) => {
               dispatch({ type: 'SHOW_MODAL_IDENTIFICACION', showModalIdentificacion: false, usuarioIdentificacion, identificacion, areaPertenece, idUsuarioReporta, sinIdentificar})
          },
          // onCronometro: () => {
          //      dispatch({ type: 'RESTART_CRONOMETRO', segundos: '00:00:00', limpiarCrono: true });
          // }
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(Identificacion);
