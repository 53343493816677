import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import { Alert, Button, Row, Col, Select, Form} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import moment from 'moment';

const {Option} = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const FilterSection = props => {
    let formRef = React.createRef();
    const {
        checkboxData, onGetReporteDinamico, formularioReporte, handleSteps, onLimpiarFormulario,
        onGerencias, fetchingListaClasificacionesActivos, listaClasificacionesActivos, 
        listaGruposServicios, fetchingListaGruposServicios, onListaGrupoSer, listaServiciosByIdsGerencias,
        fetchingListaServiciosByGruposServicios, listaServiciosByGruposServicios, onListaServiciosByGrupoSer,
        fetchingListaMesesEvaluacion, listaMesesEvaluacion, state, activarGerencia, onListaServiciosByIdsGerencias
    } = props;

    let permisosUsuario = sessionStorage.getItem('permisosUsuario');
    if(permisosUsuario[0]){
        permisosUsuario = JSON.parse(permisosUsuario);
    }

    let userInfo = sessionStorage.getItem('userInfo');
    if(userInfo[0]) {
        userInfo = JSON.parse(userInfo);
    }
    let serviciosByIdUsuario = []
    if(userInfo[0].serviciosByIdUsuario && listaServiciosByGruposServicios){
        for(let i in listaServiciosByGruposServicios){
            for(let x in userInfo[0].serviciosByIdUsuario){
                if(listaServiciosByGruposServicios[i].idServicio === userInfo[0].serviciosByIdUsuario[x].idServicio){
                    serviciosByIdUsuario.push(userInfo[0].serviciosByIdUsuario[x]);
                }
            }
        }
    }
    if(userInfo[0].serviciosByIdUsuario && listaServiciosByIdsGerencias){
        for(let i in listaServiciosByIdsGerencias){
            for(let x in userInfo[0].serviciosByIdUsuario){
                if(listaServiciosByIdsGerencias[i].idServicio === userInfo[0].serviciosByIdUsuario[x].idServicio){
                    serviciosByIdUsuario.push(userInfo[0].serviciosByIdUsuario[x]);
                }
            }
        }
    }

    useEffect(() => {
        onGerencias();
        onListaGrupoSer();
        props.onListaMesesActivos();
    }, [])

    const onHandleSteps = value => {
        handleSteps(value);
        onLimpiarFormulario();
        props.onLimpiarListaServiciosByGrupoSer();
        props.onLimpiarListaServiciosByIdsGerencias();
    }

    const onFinish = formulario => {
        console.log('Formulario', formulario);
        const sorter = {field:'ser.Nombre',order:'ASC'}
        onGetReporteDinamico(formulario, 1, checkboxData, sorter, moment().format('YYYY'), permisosUsuario.especialGestiónDeCalidad);
        handleSteps('siguiente');
    }

    const onGrupoSerSelect = (value) => {
        console.log(`value`, value);

        formRef.current.setFieldsValue({'idsServicios': undefined});
        formRef.current.setFieldsValue({'idsGerencias': undefined});
        props.onLimpiarListaServiciosByGrupoSer();
        if(value[0]){
            onListaServiciosByGrupoSer(value);
            activarGerencia(true, 'grupo');
        }
        else{
            activarGerencia(false, 'grupo');
        }
    }

    const gerenciaSelect = (value) => {
        console.log(`value gerencia`, value);
        formRef.current.setFieldsValue({'idsServicios': undefined});
        formRef.current.setFieldsValue({'idsGruposSer': undefined});
        props.onLimpiarListaServiciosByIdsGerencias();
        if(value[0]){
            onListaServiciosByIdsGerencias(value)
            activarGerencia(true, 'gerencia');
        }
        else{
            activarGerencia(false, 'gerencia');
        }
    }

    const onServiciosSeleccionados = (value) => {
        console.log(`value`, value);
        formRef.current.setFieldsValue({'idsServicios': value});
    }
    return (
        <>
            <Row>
                <Col span={24}>
                    <Alert message="Selecciona los filtros a aplicar en el reporte." type="info" style={{ marginBottom: '16px' }} />
                </Col>
            </Row>
            <Form {...layout}
                name='formulario'
                onFinish={onFinish}
                initialValues={formularioReporte}
                ref={formRef}
            >
                <Row justify='space-between'>
                    {checkboxData.gerenciaC ? (
                    <Col span={11}>
                        <Form.Item
                            label='Gerencia'
                            name='idsGerencias'
                        >
                            <Select
                                allowClear
                                disabled={state.activarGrupo}
                                style={{ width: '100%' }} 
                                placeholder="Selecciona las gerencias"
                                loading={fetchingListaClasificacionesActivos}
                                mode='multiple'
                                onChange={gerenciaSelect}
                            >
                                {listaClasificacionesActivos.map(item => <Option key={item.idClasificacionGerencia + ' - ' + item.nombreGerencia}>{item.idClasificacionGerencia + ' - ' + item.nombreGerencia}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    ): false}
                    {checkboxData.grupoC ? (
                    <Col span={11}>
                        <Form.Item
                            label='Grupo de Servicios'
                            name='idsGruposSer'
                        >
                            <Select
                                allowClear
                                disabled={state.activarGerencia}
                                style={{ width: '100%' }}
                                placeholder="Ingresa los grupos de servicio a buscar"
                                loading={fetchingListaGruposServicios}
                                onChange={onGrupoSerSelect}
                                mode='multiple'
                            >
                                {listaGruposServicios.map(item => <Option key={item.idGrupoSer + ' - ' + item.nombre}>{item.idGrupoSer + ' - ' + item.nombre}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    ):false}
                    {checkboxData.servicioC ? (
                    <Col span={11}>
                        <Form.Item
                            label='Servicio'
                            name='idsServicios'
                        >
                            {!permisosUsuario.especialGestiónDeCalidad ? (
                            <Select
                                allowClear
                                style={{ width: '100%' }} placeholder="Selecciona los servicios"
                                mode='multiple'
                                loading={fetchingListaServiciosByGruposServicios || props.fetchingListaServiciosByIdsGerencias}
                                onChange={onServiciosSeleccionados}
                            >
                                {serviciosByIdUsuario.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nombre}</Option>)})
                            </Select>
                            ):(
                            <>
                            {state.activarGrupo ? (
                            <Select
                                allowClear
                                style={{ width: '100%' }} placeholder="Selecciona los servicios"
                                mode='multiple'
                                loading={fetchingListaServiciosByGruposServicios}
                                onChange={onServiciosSeleccionados}
                            >
                                {listaServiciosByGruposServicios.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nombre}</Option>)})
                            </Select>
                            ):(
                            <Select
                                allowClear
                                style={{ width: '100%' }} placeholder="Selecciona los servicios"
                                mode='multiple'
                                loading={props.fetchingListaServiciosByIdsGerencias}
                                onChange={onServiciosSeleccionados}
                            >
                                {listaServiciosByIdsGerencias.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nombre}</Option>)})
                            </Select>
                            )}
                            </>
                            )}
                        </Form.Item>
                    </Col>
                    ):false}
                    {checkboxData.mesC ? (
                    <Col span={11}>
                        <Form.Item
                            label="Mes"
                            name="idMes"
                        >
                            <Select allowClear
                                style={{width: '100%'}}
                                placeholder="Selecciona los meses"
                                loading={fetchingListaMesesEvaluacion}
                                mode='multiple'
                            >
                                {listaMesesEvaluacion.map(option => <Option key={option.idMes + ' - ' + option.mes}>{option.idMes + ' - ' + option.mes}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    ):false}
                    <Col span={11}>
                        <Form.Item
                            label="Estado"
                            name="estado"
                        >
                            <Select allowClear
                                style={{width: '100%'}}
                                placeholder="Selecciona los estados"
                                loading={fetchingListaMesesEvaluacion}
                                mode='multiple'
                            >
                                <Option key='Generada'>Generada</Option>
                                <Option key='Capturada'>Capturada</Option>
                                <Option key='No_Evaluado'>No evaluado</Option>
                                <Option key='Evaluada'>Evaluada</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Button className="btnFiltrar" onClick={() => onHandleSteps('anterior')} style={{ width: '100%', marginTop: '20px' }}>Anterior</Button>
                    </Col>
                    <Col span={6} offset={12}>
                        <Button className="btnFiltrar"  htmlType='submit' style={{ width: '100%', marginTop: '20px' }}>Siguiente</Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

const mapStateToProps = state => {
    return {
        fetchingListaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.fetchingListaClasificacionesActivos,
        listaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.listaClasificacionesActivos,
        fetchingListaGruposServicios: state.CatalogosGeneralesReducer.fetchingListaGruposServicios,
        listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,
        fetchingListaServiciosByGruposServicios: state.CatalogosGeneralesReducer.fetchingListaServiciosByGruposServicios,
        listaServiciosByGruposServicios: state.CatalogosGeneralesReducer.listaServiciosByGruposServicios,
        fetchingListaMesesEvaluacion: state.EvaluacionDeLaGestionReducer.fetchingListaMesesEvaluacion,
        listaMesesEvaluacion: state.EvaluacionDeLaGestionReducer.listaMesesEvaluacion,
        formularioReporte: state.GestionDeCalidadReducer.formularioReporte,
        listaServiciosByIdsGerencias: state.CatalogosGeneralesReducer.listaServiciosByIdsGerencias,
        fetchingListaServiciosByIdsGerencias: state.CatalogosGeneralesReducer.fetchingListaServiciosByIdsGerencias,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGerencias: () => {
            dispatch({ type: 'LISTA_CLASIFICACION_ACTIVOS_REQUEST', reporteDinamicoES: true })
        },
        onListaGrupoSer: () => {
            dispatch({ type: 'LISTA_GRUPOS_SERVICIOS_REQUEST' })
        },
        onListaServiciosByGrupoSer: (idsGrupoSer) => {
            dispatch({ type: 'LISTA_SERVICIOS_BY_GRUPOS_SERV_REQUEST', idsGrupoSer, reporte: true })
        },
        onLimpiarListaServiciosByGrupoSer: () => {
            dispatch({ type: 'LISTA_SERVICIOS_BY_GRUPOS_SERV_SUCCESS', listaServiciosByGruposServicios: [] })
        },
        onLimpiarListaGerencias: () => {
            dispatch({ type: 'LISTA_CLASIFICACION_ACTIVOS_SUCCESS', listaClasificacionesActivos: [] })
        },
        onListaMesesActivos: () => {
            dispatch({type: 'LISTA_MESES_EVALUACION_REQUEST'});
        },
        onGetReporteDinamico: (formulario, page, checkboxData, order, año, especial) => {
            dispatch({ type: 'REPORTE_DINAMICO_ES_REQUEST', formulario, page, checkboxData, order, año, especial });
        },
        onGetReporteDinamicoAgrupado: (formulario, page, checkboxData, order, especial) => {
            dispatch({ type: 'LISTA_REPORTE_DINAMICO_AGRUPADO_REQUEST', formulario, page, checkboxData, order, especial });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO_REPORTE', formularioReporte: {}, listaReportesDinamico: [], listaReportesDinamicoCantidad: 0 })
        },
        onListaServiciosByIdsGerencias: (idsGerencias) => {
            dispatch({ type: 'LISTA_SERVICIOS_BY_IDGERENCIAS_REQUEST', idsGerencias });
        },
        onLimpiarListaServiciosByIdsGerencias: () => {
            dispatch({ type: 'LISTA_SERVICIOS_BY_IDGERENCIAS_SUCCESS', listaServiciosByIdsGerencias: [] });
        },
    }
}

export default connect (mapStateToProps, mapDispatchToProps) (FilterSection);
