import * as ServiciosPlanificadosActions from '../actions/ServiciosPlanificadosActions';

const initialState = {
   catalogoServiciosPlanificados: null,
   page: null,
   busqueda: '',

   //Rutinas
   fetchingListaRutinasBusqueda: false,
   listaRutinasBusqueda: [],
   listaRutinasBusquedaCantidad: 0,
   listaSinRepetir: [],
   listaSinRepetirHerramienta: [],

   // Reducer ordenes para rutina y mantenimiento
   fetchingListaOrdenesBusquedaRequest: false,
   listaOrdenesBusquedaRequest: [],
   listaOrdenesBusquedaRequestCantidad: null,
   showModalDetallePrograma: false,
   programaSeleccionada: null,
   programaSeleccionadaDetalle: null,
   fetchingListaServiciosRutinaFiltro: false,
   listaServiciosRutinaFiltro: [],
   listaInsumosOrdenes: [],
   fetchingGenerarOrden: false,
   showModalGenerarOrden: false,
   programaSeleccionadoParaOrden: null,
   programaGenerar: [],
   fetchingListaOrdenesRMP: false,
   listaOrdenesRMP: [], // lista ordenes Rutina, mto, prueba
   listaOrdenesRMPCantidad: null,
   fetchingEliminarTarea: false,
   showModalEditarOrden: false,
   //tareaSeleccionadaDetalle: null,
   fetchingReprogramarTarea: false,
   showModalReprogramarTarea: false,
   tareaSeleccionadaReprogramacion: null,
   fetchingObtenerProgramacionOrden: false,

   fetchingReprogramarTareaMultiple: false,
   
   listaProgramacionOrden: [], 
   fetchingDescargarPDForden: false,
   showModalVerProgramacionOrden: false,
   verProgramacionSeleccionadoOrden: null,

   //Rutinas
   fetchingListaRutinasBusqueda: false,
   listaRutinasBusqueda: [],
   listaRutinasBusquedaCantidad: 0,

   showModalDetalleRutina: false,
   rutinaSeleccionada: null,
   tipoFrecuencia: null,
   listaInsumos: [],
   listaPersonal: null,
   revisarTareas: false,
   revisarInsumos: false,

   showModalNuevaRutina: false,
   fetchingNuevaRutina: false,
   formularioRutina: null,
   vistaNuevaRutina: 1,
   tipoModal: null,

   showModalEditarRutina: false,
   fetchingEditarRutina: false,

   //Asignacion para Servicios Planificados
   listaAsignacionesSPBusqueda: [],
   listaAsignacionesSPCantidad: null,
   fetchingListaAsignacionesSPBusqueda: false,

   listaSPNoAsignados: [],
   fetchinglistaSPNoAsignados: false,

   showModalDetalleAsignacionesSP: false,
   showModalDetalleRutinaSP: false,

   showModalNuevaAsignacionSPlanificado: false,
   fetchingNuevaAsignacionSPlanificado: false,

   showModalEditarAsignacionSPlanificado: false,
   fetchingEditarAsignacionSPlanificado: false,
   servicioPlanificadoSeleccionado: null,

   // CU - Programas de RutinasMantenimientos y Pruebas para Servicios Planificados.
   showModalProgramarRMP: false,
   showModalDetalleActividadRMP: false,
   showModalDetalleUbicacionRMP: false,
   showModalActualizarRM: false,
   showModalFechaRMP: false,
   programa: '',
   programaRMPSeleccionado: [],
   asignacionRMPSeleccionada: [],

   fetchingListaRMPBusqueda: false,
   listaRMPBusqueda: [],
   listaRMPBusquedaCantidad: null,
   spnbool: true,
   typeModal: '',

   fetchingProgramarRMP: false,
   dataProgramar: {},
   idsServicios: [],
   fetchingEditarRMP: false,
   
   //Cancelación Órdenes de Servicios Planificados
   listaCancelacionOrdenesSPBusqueda: [],
   listaCancelacionOrdenesSPCantidad: null,
   fetchingListaCancelacionOrdenesSPBusqueda: false,

   cancelacionOrdenesSPDetalle: [],
   cancelacionOrdenesSPDetalleCantidad: null,
   fetchingCancelacionOrdenesSPDetalle: false,
   showModalDetalleOrdenesSPCancelacion: false,

   showModalCancelarOrdenSP: false,
   fetchingCancelarOrdenSP: false,
   ordenCancelacionSPSeleccionado: null,

   //Estatus Órdenes de Servicios Planificados
   showModalDetalleEstatusOrdenesSPActividad: false,
   showModalDetalleEstatusOrdenesSPPrograma: false,

   showModalCambiarEstatusOrdenSP: false,
   fetchingCambiarEstatusOrdenSP: false,
   estatusOrdenSPSeleccionado: null,

   fetchinEditarInsumosEstatus: false,
   fetchinEditarPersonalSP: false,

   //Vista Calendario Servicios Planificados
   listaTareasVistaCalendarioBusqueda: [],
   listaTareasVistaCalendarioBusquedaCantidad: null,
   fetchingListaTareasVistaCalendarioBusqueda: false,

   showModalReprogramarTareaVC: false,
   fetchingReprogramarTareaVC: false,
   tareaSeleccionadaVC: null,

   showModalTablaTareasVC: false,
   programacionSeleccionadaVC: null,

   //Reporte Servicios Planificados
   listaReporteSPBusqueda: [],
   listaReporteSPBusquedaSPCantidad: null,
   fetchingListaReporteSPBusqueda: false,
   fetchingDescargaListaRerporteSPExcel: false,
   fetchingDescargaListaReporteSPPDF: false,

   listaReporteSPBusquedaDescarga:[],
   fetchinglistaReporteSPBusquedaDescarga: false,

   //Carga Servicios Planificados
   fetchingListaArchivosEsperaSP: false,
   listaArchivosEsperaSP: [],
   listaArchivosEsperaSPCantidad: 0,

   fetchingSubirArchivoSP: false,
   fetchingBorrarArchivoSP: false,

   //Calendario de Programación
   fetchingCalendarioProgramacion: false,
   listaCalendarioProgramacion: [],
   listaCalendarioInicial: [],

   tareaSeleccionada: null,
   showModalCalendarioDetalle: false,

   showModalReprogramarDragDrop: false,
   anteriorRMP: null,
   nuevoRMP: null,
   noDragDrop: false,

   fetchingCalendarioDetalle: false,
   calendarioDetalle: null,
   calendarioProgramacion: false,
   rmdp: false,

   fetchingListaRegistrosSP: false,
   listaRegistrosSP: [],
   listaRegistrosSPCantidad: 0,

   fetchingListaRegistrosEspecialSP: false,
   listaRegistrosEspecialSP: [],
   listaRegistrosEspecialSPCantidad: 0,

   fetchingListaReporteDinamicoSP: false,
   formularioReporte: null,
   listaReporteDinamicoSP: [],
   listaReporteDinamicoSPCantidad: 0,

   fetchingReporteDinamicoPDF: false,
   fetchingReporteDinamicoEXCEL: false,

   fetchingReporteDinamicoAgrupadoSP: false,
   listaReporteDinamicoAgrupadoSP: [],
   listaReporteDinamicoAgrupadoSPCantidad: 0,

   fetchingReporteDinamicoAgrupadoPDF: false,
   fetchingReporteDinamicoAgrupadoEXCEL: false,

   //editar y crear registro
   showModalEditarRegistro: false,
   showModalCrearRegistro: false,
   data: null,
   registroReporteSeleccionado: [],
   personal: false,
   fetchingEditarRegistro: false,
   fetchingCreateRegistro: false,
   
};

export default (state = initialState, action) => {
   switch (action.type) {
      case ServiciosPlanificadosActions.CAMBIAR_CATALOGO_SERVICIOS_PLANIFICADOS:
         return { ...state, catalogoServiciosPlanificados: action.catalogoServiciosPlanificados };

         // Reducers Ordenes Rutina y Mantenimiento
      case ServiciosPlanificadosActions.LISTA_RUTINAS_PROGRAMADAS_REPROGRAMADAS_REQUEST:
         return { ...state, fetchingListaOrdenesBusquedaRequest: true };
      case ServiciosPlanificadosActions.LISTA_RUTINAS_PROGRAMADAS_REPROGRAMADAS_SUCCESS:
         return { ...state, fetchingListaOrdenesBusquedaRequest: false, listaOrdenesBusquedaRequest: action.listaOrdenesBusquedaRequest, listaOrdenesBusquedaRequestCantidad: action.listaOrdenesBusquedaRequestCantidad ,listaInsumosOrdenes: action.listaInsumosOrdenes};
      case ServiciosPlanificadosActions.LISTA_RUTINAS_PROGRAMADAS_REPROGRAMADAS_FAILURE:
         return { ...state, fetchingListaOrdenesBusquedaRequest: false };

      case ServiciosPlanificadosActions.SHOW_MODAL_DETALLE_PROGRAMA:
         return { ...state, showModalDetallePrograma: action.showModalDetallePrograma, programaSeleccionadaDetalle: action.programaSeleccionadaDetalle, programaSeleccionada: action.programaSeleccionada, page: action.page }

      case ServiciosPlanificadosActions.LISTA_SERVICIOS_RUTINAS_ACTIVOS_REQUEST:
         return {...state, fetchingListaServiciosRutinaFiltro: true };
      case ServiciosPlanificadosActions.LISTA_SERVICIOS_RUTINAS_ACTIVOS_SUCCESS:
         return {...state, fetchingListaServiciosRutinaFiltro: false, listaServiciosRutinaFiltro: action.listaServiciosRutinaFiltro};
      case ServiciosPlanificadosActions.LISTA_SERVICIOS_RUTINAS_ACTIVOS_FAILURE:
         return {...state, fetchingListaServiciosRutinaFiltro: false };           

      case ServiciosPlanificadosActions.GENERAR_ORDEN_PROGRAMA_REQUEST:
         return {...state, fetchingGenerarOrden: true };
      case ServiciosPlanificadosActions.GENERAR_ORDEN_PROGRAMA_SUCCESS:
         return {...state, fetchingGenerarOrden: false };
      case ServiciosPlanificadosActions.GENERAR_ORDEN_PROGRAMA_FAILURE:
         return {...state, fetchingGenerarOrden: false }; 
               
      case ServiciosPlanificadosActions.ELIMINAR_TAREA_ORDEN_REQUEST:
         return {...state, fetchingEliminarTarea: true };
      case ServiciosPlanificadosActions.ELIMINAR_TAREA_ORDEN_SUCCESS:
         return {...state, fetchingEliminarTarea: false };
      case ServiciosPlanificadosActions.ELIMINAR_TAREA_ORDEN_FAILURE:
         return {...state, fetchingEliminarTarea: false }; 
            
      case ServiciosPlanificadosActions.SHOW_MODAL_GENERAR_ORDEN:
         return {...state, showModalGenerarOrden: action.showModalGenerarOrden, programaSeleccionadoParaOrden: action.programaSeleccionadoParaOrden, programaGenerar: action.programaGenerar , page: action.page, busqueda: action.busqueda}  

      case ServiciosPlanificadosActions.LISTA_ORDENES_RMP_ACTIVOS_REQUEST:
         return {...state, fetchingListaOrdenesRMP: true };
      case ServiciosPlanificadosActions.LISTA_ORDENES_RMP_ACTIVOS_SUCCESS:
         return {...state, fetchingListaOrdenesRMP: false , listaOrdenesRMP: action.listaOrdenesRMP, listaOrdenesRMPCantidad: action.listaOrdenesRMPCantidad};
      case ServiciosPlanificadosActions.LISTA_ORDENES_RMP_ACTIVOS_FAILURE:
         return {...state, fetchingListaOrdenesRMP: false };  
               
      case ServiciosPlanificadosActions.SHOW_MODAL_EDITAR_ORDEN:
           return {...state, showModalEditarOrden: action.showModalEditarOrden, tareaSeleccionada: action.tareaSeleccionada, page: action.page, busqueda: action.busqueda }
               
      case ServiciosPlanificadosActions.REPROGRAMAR_TAREA_ORDEN_REQUEST:
         return {...state, fetchingReprogramarTarea: true };
      case ServiciosPlanificadosActions.REPROGRAMAR_TAREA_ORDEN_SUCCESS:
         return {...state, fetchingReprogramarTarea: false };
      case ServiciosPlanificadosActions.REPROGRAMAR_TAREA_ORDEN_FAILURE:
         return {...state, fetchingReprogramarTarea: false };

      case ServiciosPlanificadosActions.REPROGRAMAR_TAREA_MULTIPLE_REQUEST:
         return {...state, fetchingReprogramarTareaMultiple: true };
      case ServiciosPlanificadosActions.REPROGRAMAR_TAREA_MULTIPLE_SUCCESS:
         return {...state, fetchingReprogramarTareaMultiple: false };
      case ServiciosPlanificadosActions.REPROGRAMAR_TAREA_MULTIPLE_FAILURE:
         return {...state, fetchingReprogramarTareaMultiple: false };
      
      case ServiciosPlanificadosActions.SHOW_MODAL_REPROGRAMAR_TAREA_ORDEN:
               return {...state, showModalReprogramarTarea: action.showModalReprogramarTarea, tareaSeleccionadaReprogramacion: action.tareaSeleccionadaReprogramacion, page: action.page, busqueda: action.busqueda}
               
      case ServiciosPlanificadosActions.OBTENER_PROGRAMACION_ORDEN_REQUEST:
               return {...state, fetchingObtenerProgramacionOrden: true };
      case ServiciosPlanificadosActions.OBTENER_PROGRAMACION_ORDEN_SUCCESS:
               return {...state, fetchingObtenerProgramacionOrden: false , listaProgramacionOrden: action.listaProgramacionOrden };
      case ServiciosPlanificadosActions.OBTENER_PROGRAMACION_ORDEN_FAILURE:
               return {...state, fetchingObtenerProgramacionOrden: false };    
               
      case ServiciosPlanificadosActions.DESCARGAR_PDF_MANTENIMIENTO_REQUEST:
              return {...state, fetchingDescargarPDForden: true, fetchingListaCancelacionOrdenesSPBusqueda: true };
      case ServiciosPlanificadosActions.DESCARGAR_PDF_MANTENIMIENTO_SUCCESS:
              return {...state, fetchingDescargarPDForden: false, fetchingListaCancelacionOrdenesSPBusqueda: false };
      case ServiciosPlanificadosActions.DESCARGAR_PDF_MANTENIMIENTO_FAILURE:
              return {...state, fetchingDescargarPDForden: false, fetchingListaCancelacionOrdenesSPBusqueda: false };        
              
      case ServiciosPlanificadosActions.DESCARGAR_PDF_RUTINA_REQUEST:
              return {...state, fetchingDescargarPDForden: true };
      case ServiciosPlanificadosActions.DESCARGAR_PDF_RUTINA_SUCCESS:
              return {...state, fetchingDescargarPDForden: false };
      case ServiciosPlanificadosActions.DESCARGAR_PDF_RUTINA_FAILURE:
              return {...state, fetchingDescargarPDForden: false };     
              
      case ServiciosPlanificadosActions.DESCARGAR_PDF_PRUEBA_REQUEST:
              return {...state, fetchingDescargarPDForden: true };
      case ServiciosPlanificadosActions.DESCARGAR_PDF_PRUEBA_SUCCESS:
              return {...state, fetchingDescargarPDForden: false };
      case ServiciosPlanificadosActions.DESCARGAR_PDF_PRUEBA_FAILURE:
              return {...state, fetchingDescargarPDForden: false };       
              
      case ServiciosPlanificadosActions.SHOW_MODAL_VER_PROGRAMACION_ORDEN:
              return {...state, showModalVerProgramacionOrden: action.showModalVerProgramacionOrden, verProgramacionSeleccionadoOrden: action.verProgramacionSeleccionadoOrden };
         
                //RUTINAS
      case ServiciosPlanificadosActions.LISTA_RUTINAS_BUSQUEDA_REQUEST:
         return { ...state, fetchingListaRutinasBusqueda: true };
      case ServiciosPlanificadosActions.LISTA_RUTINAS_BUSQUEDA_SUCCESS:
         return { ...state, fetchingListaRutinasBusqueda: false, listaRutinasBusqueda: action.listaRutinasBusqueda, listaRutinasBusquedaCantidad: action.listaRutinasBusquedaCantidad };
      case ServiciosPlanificadosActions.LISTA_RUTINAS_BUSQUEDA_FAILURE:
         return { ...state, fetchingListaRutinasBusqueda: false };

      case ServiciosPlanificadosActions.MODAL_DETALLE_RUTINA:
         return { ...state, showModalDetalleRutina: action.showModalDetalleRutina, rutinaSeleccionada: action.rutinaSeleccionada };

      case ServiciosPlanificadosActions.ESTADO_RUTINA_REQUEST:
         return { ...state, fetchingListaRutinasBusqueda: true };
      case ServiciosPlanificadosActions.ESTADO_RUTINA_SUCCESS:
         return { ...state, fetchingListaRutinasBusqueda: false };
      case ServiciosPlanificadosActions.ESTADO_RUTINA_FAILURE:
         return { ...state, fetchingListaRutinasBusqueda: false };

      case ServiciosPlanificadosActions.SHOW_MODAL_NUEVA_RUTINA:
         return { ...state, showModalNuevaRutina: action.showModalNuevaRutina, page: action.page, busqueda: action.busqueda };
      case ServiciosPlanificadosActions.VISTA_NUEVA_RUTINA:
         return { ...state, formularioRutina: action.formularioRutina, vistaNuevaRutina: action.vistaNuevaRutina, listaInsumos: action.listaInsumos, tipoModal: action.tipoModal };

      case ServiciosPlanificadosActions.NUEVA_RUTINA_REQUEST:
         return { ...state, fetchingNuevaRutina: true };
      case ServiciosPlanificadosActions.NUEVA_RUTINA_SUCCESS:
         return { ...state, fetchingNuevaRutina: false };
      case ServiciosPlanificadosActions.NUEVA_RUTINA_FAILURE:
         return { ...state, fetchingNuevaRutina: false };

      case ServiciosPlanificadosActions.SHOW_MODAL_EDITAR_RUTINA:
         return { ...state, showModalEditarRutina: action.showModalEditarRutina, rutinaSeleccionada: action.rutinaSeleccionada, page: action.page, busqueda: action.busqueda, tipoFrecuencia: action.tipoFrecuencia, listaInsumos: action.listaInsumos, listaSinRepetir: action.listaSinRepetir, listaSinRepetirHerramienta: action.listaSinRepetirHerramienta };

      case ServiciosPlanificadosActions.EDITAR_RUTINA_REQUEST:
         return { ...state, fetchingEditarRutina: true };
      case ServiciosPlanificadosActions.EDITAR_RUTINA_SUCCESS:
         return { ...state, fetchingEditarRutina: false };
      case ServiciosPlanificadosActions.EDITAR_RUTINA_FAILURE:
         return { ...state, fetchingEditarRutina: false };

      //Asignacion para Servicios Planificados
      case ServiciosPlanificadosActions.LISTA_ASIGNACIONES_SP_BUSQUEDA_REQUEST:
         return { ...state, fetchingListaAsignacionesSPBusqueda: true };
      case ServiciosPlanificadosActions.LISTA_ASIGNACIONES_SP_BUSQUEDA_SUCCESS:
         return { ...state, fetchingListaAsignacionesSPBusqueda: false, listaAsignacionesSPBusqueda: action.listaAsignacionesSPBusqueda, listaAsignacionesSPCantidad: action.listaAsignacionesSPCantidad };
      case ServiciosPlanificadosActions.LISTA_ASIGNACIONES_SP_BUSQUEDA_FAILURE:
         return { ...state, fetchingListaAsignacionesSPBusqueda: false };

      case ServiciosPlanificadosActions.LISTA_SP_NO_ASIGNADOS_REQUEST:
         return { ...state, fetchinglistaSPNoAsignados: true };
      case ServiciosPlanificadosActions.LISTA_SP_NO_ASIGNADOS_SUCCESS:
         return { ...state, fetchinglistaSPNoAsignados: false, listaSPNoAsignados: action.listaSPNoAsignados };
      case ServiciosPlanificadosActions.LISTA_SP_NO_ASIGNADOS_FAILURE:
         return { ...state, fetchinglistaSPNoAsignados: false };

      case ServiciosPlanificadosActions.SHOW_MODAL_DETALLE_ASIGNACIONES_SP:
         return { ...state, showModalDetalleAsignacionesSP: action.showModalDetalleAsignacionesSP, servicioPlanificadoSeleccionado: action.servicioPlanificadoSeleccionado };
      case ServiciosPlanificadosActions.SHOW_MODAL_DETALLE_RUTINA_SP:
         return { ...state, showModalDetalleRutinaSP: action.showModalDetalleRutinaSP, servicioPlanificadoSeleccionado: action.servicioPlanificadoSeleccionado };
      case ServiciosPlanificadosActions.SHOW_MODAL_NUEVA_ASIGNACION_SP:
         return { ...state, showModalNuevaAsignacionSPlanificado: action.showModalNuevaAsignacionSPlanificado, page: action.page, busqueda: action.busqueda };
      case ServiciosPlanificadosActions.SHOW_MODAL_EDITAR_ASIGNACION_SP:
         return { ...state, showModalEditarAsignacionSPlanificado: action.showModalEditarAsignacionSPlanificado, servicioPlanificadoSeleccionado: action.servicioPlanificadoSeleccionado, page: action.page, busqueda: action.busqueda };

      case ServiciosPlanificadosActions.NUEVA_ASIGNACION_SP_REQUEST:
         return { ...state, fetchingNuevaAsignacionSPlanificado: true };
      case ServiciosPlanificadosActions.NUEVA_ASIGNACION_SP_SUCCESS:
         return { ...state, fetchingNuevaAsignacionSPlanificado: false };
      case ServiciosPlanificadosActions.NUEVA_ASIGNACION_SP_FAILURE:
         return { ...state, fetchingNuevaAsignacionSPlanificado: false };

      case ServiciosPlanificadosActions.EDITAR_ASIGNACION_SP_REQUEST:
         return { ...state, fetchingEditarAsignacionSPlanificado: true };
      case ServiciosPlanificadosActions.EDITAR_ASIGNACION_SP_SUCCESS:
         return { ...state, fetchingEditarAsignacionSPlanificado: false };
      case ServiciosPlanificadosActions.EDITAR_ASIGNACION_SP_FAILURE:
         return { ...state, fetchingEditarAsignacionSPlanificado: false };

      // CU - Programas de Rutinas Mantenimientos y Pruebas para Servicios Planificados.
      case ServiciosPlanificadosActions.SHOW_MODAL_PROGRAMAR_RMP:
         return { ...state, showModalProgramarRMP: action.showModalProgramarRMP, programaRMPSeleccionado: action.programaRMPSeleccionado, programa: action.programa, page: action.page, busqueda: action.busqueda, idsServicios: action.idsServicios };

      case ServiciosPlanificadosActions.SHOW_MODAL_FECHAS_RMP:
         return { ...state, showModalFechaRMP: action.showModalFechaRMP, asignacionRMPSeleccionada: action.asignacionRMPSeleccionada, programaRMPSeleccionado: action.programaRMPSeleccionado, programa: action.programa, page: action.page, busqueda: action.busqueda, typeModal: action.typeModal, idsServicios: action.idsServicios };
      
      case ServiciosPlanificadosActions.SHOW_MODAL_DETALLE_ACTIVIDAD_RMP:
         return { ...state, showModalDetalleActividadRMP: action.showModalDetalleActividadRMP, programaRMPSeleccionado: action.programaRMPSeleccionado, programa: action.programa, page: action.page, busqueda: action.busqueda };

      case ServiciosPlanificadosActions.SHOW_MODAL_DETALLE_UBICACION_RMP:
         return { ...state, showModalDetalleUbicacionRMP: action.showModalDetalleUbicacionRMP, programaRMPSeleccionado: action.programaRMPSeleccionado, page: action.page, programa: action.programa };

      case ServiciosPlanificadosActions.SHOW_MODAL_ACTUALIZAR_RM:
         return { ...state, showModalActualizarRM: action.showModalActualizarRM, programaRMPSeleccionado: action.programaRMPSeleccionado, page: action.page, busqueda: action.busqueda, programa: action.programa, idsServicios: action.idsServicios };

      case ServiciosPlanificadosActions.LISTA_RMP_BUSQUEDA_REQUEST:
         return { ...state, fetchingListaRMPBusqueda: true };
      case ServiciosPlanificadosActions.LISTA_RMP_BUSQUEDA_SUCCESS:
         return { ...state, fetchingListaRMPBusqueda: false, page: action.page, listaRMPBusqueda: action.listaRMPBusqueda, listaRMPBusquedaCantidad: action.listaRMPBusquedaCantidad, busqueda: action.busqueda, spnbool: action.spnbool };
      case ServiciosPlanificadosActions.LISTA_RMP_BUSQUEDA_FAILURE:
         return { ...state, fetchingListaRMPBusqueda: false };

      case ServiciosPlanificadosActions.PROGRAMAR_RMP_REQUEST:
         return { ...state, fetchingProgramarRMP: true };
      case ServiciosPlanificadosActions.PROGRAMAR_RMP_SUCCESS:
         return { ...state, fetchingProgramarRMP: false, page: action.page, busqueda: action.busqueda, dataProgramar: action.dataProgramar };
      case ServiciosPlanificadosActions.PROGRAMAR_RMP_FAILURE:
         return { ...state, fetchingProgramarRMP: false };

      case ServiciosPlanificadosActions.EDITAR_RMP_REQUEST:
         return { ...state, fetchingEditarRMP: true };
         case ServiciosPlanificadosActions.EDITAR_RMP_SUCCESS:
            return { ...state, fetchingEditarRMP: false };
         case ServiciosPlanificadosActions.EDITAR_RMP_FAILURE:
            return { ...state, fetchingEditarRMP: false };

      //Cancelacion Ordenes Servicios Planificados

      case ServiciosPlanificadosActions.LISTA_BUSQUEDA_ORDENES_SP_CANCELAR_REQUEST:
         return { ...state, fetchingListaCancelacionOrdenesSPBusqueda: true};
      case ServiciosPlanificadosActions.LISTA_BUSQUEDA_ORDENES_SP_CANCELAR_SUCCESS:
         return { ...state, fetchingListaCancelacionOrdenesSPBusqueda: false, listaCancelacionOrdenesSPBusqueda: action.listaCancelacionOrdenesSPBusqueda, listaCancelacionOrdenesSPCantidad: action.listaCancelacionOrdenesSPCantidad};
      case ServiciosPlanificadosActions.LISTA_BUSQUEDA_ORDENES_SP_CANCELAR_FAILURE:
         return { ...state, fetchingListaCancelacionOrdenesSPBusqueda: false};

      case ServiciosPlanificadosActions.LISTA_DETALLE_CANCELACION_ORDEN_SP_REQUEST:
         return { ...state, fetchingCancelacionOrdenesSPDetalle: true};
      case ServiciosPlanificadosActions.LISTA_DETALLE_CANCELACION_ORDEN_SP_SUCCESS:
         return { ...state, fetchingCancelacionOrdenesSPDetalle: false, cancelacionOrdenesSPDetalle: action.cancelacionOrdenesSPDetalle, cancelacionOrdenesSPDetalleCantidad: action.cancelacionOrdenesSPDetalleCantidad};
      case ServiciosPlanificadosActions.LISTA_DETALLE_CANCELACION_ORDEN_SP_FAILURE:
         return { ...state, fetchingCancelacionOrdenesSPDetalle: false};

      case ServiciosPlanificadosActions.CANCELAR_ORDEN_SP_REQUEST:
         return { ...state, fetchingCancelarOrdenSP: true};
      case ServiciosPlanificadosActions.CANCELAR_ORDEN_SP_SUCCESS:
         return { ...state, fetchingCancelarOrdenSP: false};
      case ServiciosPlanificadosActions.CANCELAR_ORDEN_SP_FAILURE:
         return { ...state, fetchingCancelarOrdenSP: false};

      case ServiciosPlanificadosActions.SHOW_MODAL_DETALLE_CANCELACION_ORDEN_SP:
         return { ...state, showModalDetalleOrdenesSPCancelacion: action.showModalDetalleOrdenesSPCancelacion, ordenCancelacionSPSeleccionado: action.ordenCancelacionSPSeleccionado};
      case ServiciosPlanificadosActions.SHOW_MODAL_CANCELAR_ORDEN_SP:
         return { ...state, showModalCancelarOrdenSP: action.showModalCancelarOrdenSP, ordenCancelacionSPSeleccionado: action.ordenCancelacionSPSeleccionado, busqueda: action.busqueda, page: action.page}

      //Estatus de Òrdenes Servicios Planificados

      case ServiciosPlanificadosActions.CAMBIAR_ESTATUS_ORDEN_SP_REQUEST:
         return { ...state, fetchingCambiarEstatusOrdenSP: true};
      case ServiciosPlanificadosActions.CAMBIAR_ESTATUS_ORDEN_SP_SUCCESS:
         return { ...state, fetchingCambiarEstatusOrdenSP: false};
      case ServiciosPlanificadosActions.CAMBIAR_ESTATUS_ORDEN_SP_FAILURE:
         return { ...state, fetchingCambiarEstatusOrdenSP: false};

      case ServiciosPlanificadosActions.EDITAR_INSUMOS_ESTATUS_REQUEST:
         return { ...state, fetchinEditarInsumosEstatus: true };
      case ServiciosPlanificadosActions.EDITAR_INSUMOS_ESTATUS_SUCCESS:
         return { ...state, fetchinEditarInsumosEstatus: false };
      case ServiciosPlanificadosActions.EDITAR_INSUMOS_ESTATUS_FAILURE:
         return { ...state, fetchinEditarInsumosEstatus: false };

      case ServiciosPlanificadosActions.EDITAR_PERSONAL_SP_REQUEST:
         return { ...state, fetchinEditarPersonalSP: true };
      case ServiciosPlanificadosActions.EDITAR_PERSONAL_SP_SUCCESS:
         return { ...state, fetchinEditarPersonalSP: false };
      case ServiciosPlanificadosActions.EDITAR_PERSONAL_SP_FAILURE:
         return { ...state, fetchinEditarPersonalSP: false };

      case ServiciosPlanificadosActions.SHOW_MODAL_DETALLE_ESTATUS_ORDEN_SP_ACTIVIDAD:
         return { ...state, showModalDetalleEstatusOrdenesSPActividad: action.showModalDetalleEstatusOrdenesSPActividad, estatusOrdenSPSeleccionado: action.estatusOrdenSPSeleccionado};
      case ServiciosPlanificadosActions.SHOW_MODAL_DETALLE_ESTATUS_ORDEN_SP_PROGRAMA:
         return { ...state, showModalDetalleEstatusOrdenesSPPrograma: action.showModalDetalleEstatusOrdenesSPPrograma, estatusOrdenSPSeleccionado: action.estatusOrdenSPSeleccionado, cancelacionOrdenesSPDetalle: action.cancelacionOrdenesSPDetalle, cancelacionOrdenesSPDetalleCantidad: action.cancelacionOrdenesSPDetalleCantidad};
      case ServiciosPlanificadosActions.SHOW_MODAL_CAMBIAR_ESTATUS_OSP:
         return {...state, showModalCambiarEstatusOrdenSP: action.showModalCambiarEstatusOrdenSP, estatusOrdenSPSeleccionado: action.estatusOrdenSPSeleccionado, listaInsumos: action.listaInsumos, listaSinRepetir: action.listaSinRepetir, listaSinRepetirHerramienta: action.listaSinRepetirHerramienta,busqueda: action.busqueda, page: action.page, listaPersonal: action.listaPersonal};
      case ServiciosPlanificadosActions.ACTUALIZAR_LISTA_INSUMOS_ESTATUS:
         return {...state, listaInsumos: action.listaInsumos, revisarInsumos: action.revisarInsumos};
      case ServiciosPlanificadosActions.ACTUALIZAR_LISTA_INSUMOS_SIN_REPETIR:
         return {...state, listaSinRepetir: action.listaSinRepetir, listaSinRepetirHerramienta: action.listaSinRepetirHerramienta}
      case ServiciosPlanificadosActions.ACTUALIZAR_LISTA_PERSONAL_ESTATUS:
         return {...state, listaPersonal: action.listaPersonal, revisarTareas: action.revisarTareas};

      //VISTA CALENDARIO SERVICIOS PLANIFICADOS
      case ServiciosPlanificadosActions.LISTA_BUSQUEDA_VISTA_CALENDARIO_REQUEST:
         return { ...state, fetchingListaTareasVistaCalendarioBusqueda : true};
      case ServiciosPlanificadosActions.LISTA_BUSQUEDA_VISTA_CALENDARIO_SUCCESS:
         return { ...state, fetchingListaTareasVistaCalendarioBusqueda: false, listaTareasVistaCalendarioBusqueda: action.listaTareasVistaCalendarioBusqueda, listaTareasVistaCalendarioBusquedaCantidad: action.listaTareasVistaCalendarioBusquedaCantidad};
      case ServiciosPlanificadosActions.LISTA_BUSQUEDA_VISTA_CALENDARIO_FAILURE:
         return { ...state, fetchingListaTareasVistaCalendarioBusqueda: false};

      case ServiciosPlanificadosActions.REPROGRAMAR_TAREA_VISTA_CALENDARIO_REQUEST:
         return { ...state, fetchingReprogramarTareaVC: true};
      case ServiciosPlanificadosActions.REPROGRAMAR_TAREA_VISTA_CALENDARIO_SUCCESS:
         return { ...state, fetchingReprogramarTareaVC: false};
      case ServiciosPlanificadosActions.REPROGRAMAR_TAREA_VISTA_CALENDARIO_FAILURE:
         return { ...state, fetchingReprogramarTareaVC: false};

      case ServiciosPlanificadosActions.SHOW_MODAL_REPROGRAMAR_TAREA_VC: 
         return { ...state, showModalReprogramarTareaVC: action.showModalReprogramarTareaVC, tareaSeleccionadaVC: action.tareaSeleccionadaVC, busqueda: action.busqueda, page: action.page, calendarioProgramacion: action.calendarioProgramacion}
      case ServiciosPlanificadosActions.SHOW_MODAL_TABLA_TAREAS_VC:
         return { ...state, showModalTablaTareasVC: action.showModalTablaTareasVC, programacionSeleccionadaVC: action.programacionSeleccionadaVC, busqueda: action.busqueda, page: action.page}

      //Reporte Servicios Planificados
      case ServiciosPlanificadosActions.LISTA_BUSQUEDA_REPORTE_SP_REQUEST:
         return { ...state, fetchingListaReporteSPBusqueda: true};
      case ServiciosPlanificadosActions.LISTA_BUSQUEDA_REPORTE_SP_SUCCESS:
         return { ...state, fetchingListaReporteSPBusqueda: false, listaReporteSPBusqueda: action.listaReporteSPBusqueda, listaReporteSPBusquedaCantidad: action.listaReporteSPBusquedaCantidad};
      case ServiciosPlanificadosActions.LISTA_BUSQUEDA_REPORTE_SP_FAILURE:
         return { ...state, fetchingListaReporteSPBusqueda: false};

      case ServiciosPlanificadosActions.LISTA_BUSQUEDA_REPORTE_SP_DESCARGA_REQUEST:
         return { ...state, fetchinglistaReporteSPBusquedaDescarga: true};
      case ServiciosPlanificadosActions.LISTA_BUSQUEDA_REPORTE_SP_DESCARGA_SUCCESS:
         return { ...state, fetchinglistaReporteBusquedaSPDescarga: false, listaReporteSPBusquedaDescarga: action.listaReporteSPBusquedaDescarga};
      case ServiciosPlanificadosActions.LISTA_BUSQUEDA_REPORTE_SP_DESCARGA_FAILURE:
         return { ...state, fetchinglistaReporteBusquedaSPDescarga: false};

      case ServiciosPlanificadosActions.DESCARGAR_PDF_REPORTE_SP_REQUEST:
         return { ...state, fetchingDescargaListaReporteSPPDF: true };
      case ServiciosPlanificadosActions.DESCARGAR_PDF_REPORTE_SP_SUCCESS:
         return { ...state, fetchingDescargaListaReporteSPPDF: false };
      case ServiciosPlanificadosActions.DESCARGAR_PDF_REPORTE_SP_FAILURE:
         return { ...state, fetchingDescargaListaReporteSPPDF: false };

      case ServiciosPlanificadosActions.DESCARGAR_EXCEL_REPORTE_SP_REQUEST:
         return { ...state, fetchingDescargaListaRerporteSPExcel: true };
      case ServiciosPlanificadosActions.DESCARGAR_EXCEL_REPORTE_SP_SUCCESS:
         return { ...state, fetchingDescargaListaRerporteSPExcel: false };
      case ServiciosPlanificadosActions.DESCARGAR_EXCEL_REPORTE_SP_FAILURE:
         return { ...state, fetchingDescargaListaRerporteSPExcel: false };

      //Carga Servicios planificados
      case ServiciosPlanificadosActions.GET_ARCHIVOS_ESPERA_SP_REQUEST:
         return { ...state, fetchingListaArchivosEsperaSP: true};
      case ServiciosPlanificadosActions.GET_ARCHIVOS_ESPERA_SP_SUCCESS:
         return { ...state, fetchingListaArchivosEsperaSP: false, listaArchivosEsperaSP: action.listaArchivosEsperaSP, listaArchivosEsperaSPCantidad: action.listaArchivosEsperaSPCantidad};
      case ServiciosPlanificadosActions.GET_ARCHIVOS_ESPERA_SP_FAILURE:
         return { ...state, fetchingListaArchivosEsperaSP: false};

      case ServiciosPlanificadosActions.SUBIR_ARCHIVO_SP_REQUEST:
         return { ...state, fetchingSubirArchivoSP: true };
      case ServiciosPlanificadosActions.SUBIR_ARCHIVO_SP_SUCCESS:
         return { ...state, fetchingSubirArchivoSP: false };
      case ServiciosPlanificadosActions.SUBIR_ARCHIVO_SP_FAILURE:
         return { ...state, fetchingSubirArchivoSP: false };

      case ServiciosPlanificadosActions.ELIMINAR_ARCHIVO_SP_REQUEST:
         return { ...state, fetchingBorrarArchivoSP: true};
      case ServiciosPlanificadosActions.ELIMINAR_ARCHIVO_SP_SUCCESS:
         return { ...state, fetchingBorrarArchivoSP: false};
      case ServiciosPlanificadosActions.ELIMINAR_ARCHIVO_SP_FAILURE:
         return { ...state, fetchingBorrarArchivoSP: false};
         
      case ServiciosPlanificadosActions.PROCESAR_ARCHIVO_SP_REQUEST:
         return { ...state, fetchingListaArchivosEsperaSP: true};
      case ServiciosPlanificadosActions.PROCESAR_ARCHIVO_SP_SUCCESS:
         return { ...state, fetchingListaArchivosEsperaSP: false};
      case ServiciosPlanificadosActions.PROCESAR_ARCHIVO_SP_FAILURE:
         return { ...state, fetchingListaArchivosEsperaSP: false};

      case ServiciosPlanificadosActions.EXPORTAR_ERRORES_ARCHIVO_SP_REQUEST:
         return { ...state, fetchingListaArchivosEsperaSP: true};
      case ServiciosPlanificadosActions.EXPORTAR_ERRORES_ARCHIVO_SP_SUCCESS:
         return { ...state, fetchingListaArchivosEsperaSP: false};
      case ServiciosPlanificadosActions.EXPORTAR_ERRORES_ARCHIVO_SP_FAILURE:
         return { ...state, fetchingListaArchivosEsperaSP: false};


      //CALENDARIO PROGRAMACIÓN
      case ServiciosPlanificadosActions.CALENDARIO_PROGRAMACION_REQUEST:
         return { ...state, fetchingCalendarioProgramacion: true};
      case ServiciosPlanificadosActions.CALENDARIO_PROGRAMACION_SUCCESS:
         return { ...state, fetchingCalendarioProgramacion: false, listaCalendarioProgramacion: action.listaCalendarioProgramacion, listaCalendarioInicial: action.listaCalendarioInicial };
      case ServiciosPlanificadosActions.CALENDARIO_PROGRAMACION_FAILURE:
         return { ...state, fetchingCalendarioProgramacion: false};

      case ServiciosPlanificadosActions.SHOW_MODAL_CALENDARIO_DETALLE:
         return { ...state, showModalCalendarioDetalle: action.showModalCalendarioDetalle, tareaSeleccionada: action.tareaSeleccionada, busqueda: action.busqueda, rmdp: action.rmdp }
      case ServiciosPlanificadosActions.SHOW_MODAL_REPROGRAMAR_DRAG_DROP:
         return { ...state, showModalReprogramarDragDrop: action.showModalReprogramarDragDrop, anteriorRMP: action.anteriorRMP, nuevoRMP: action.nuevoRMP, busqueda: action.busqueda }

      case ServiciosPlanificadosActions.CALENDARIO_DETALLE_REQUEST:
         return { ...state, fetchingCalendarioDetalle: true};
      case ServiciosPlanificadosActions.CALENDARIO_DETALLE_SUCCESS:
         return { ...state, fetchingCalendarioDetalle: false, calendarioDetalle: action.calendarioDetalle, noDragDrop: action.noDragDrop };
      case ServiciosPlanificadosActions.CALENDARIO_DETALLE_FAILURE:
         return { ...state, fetchingCalendarioDetalle: false};

      //REPORTE DINÁMICO SP REDUCERS
      case ServiciosPlanificadosActions.LISTA_REGISTROS_SP_REQUEST:
         return { ...state, fetchingListaRegistrosSP: true};
      case ServiciosPlanificadosActions.LISTA_REGISTROS_SP_SUCCESS:
         return { ...state, fetchingListaRegistrosSP: false, listaRegistrosSP: action.listaRegistrosSP, listaRegistrosSPCantidad: action.listaRegistrosSPCantidad };
      case ServiciosPlanificadosActions.LISTA_REGISTROS_SP_FAILURE:
         return { ...state, fetchingListaRegistrosSP: false};

      case ServiciosPlanificadosActions.LISTA_REGISTROS_SP_ESPECIAL_REQUEST:
         return { ...state, fetchingListaRegistrosEspecialSP: true};
      case ServiciosPlanificadosActions.LISTA_REGISTROS_SP_ESPECIAL_SUCCESS:
         return { ...state, fetchingListaRegistrosEspecialSP: false, listaRegistrosEspecialSP: action.listaRegistrosEspecialSP, listaRegistrosEspecialSPCantidad: action.listaRegistrosEspecialSPCantidad };
      case ServiciosPlanificadosActions.LISTA_REGISTROS_SP_ESPECIAL_FAILURE:
         return { ...state, fetchingListaRegistrosEspecialSP: false};

      case ServiciosPlanificadosActions.LISTA_REPORTE_DINAMICO_SP_REQUEST:
         return { ...state, fetchingListaReporteDinamicoSP: true, formularioReporte: action.formulario };
      case ServiciosPlanificadosActions.LISTA_REPORTE_DINAMICO_SP_SUCCESS:
         return { ...state, fetchingListaReporteDinamicoSP: false, listaReporteDinamicoSP: action.listaReporteDinamicoSP, listaReporteDinamicoSPCantidad: action.listaReporteDinamicoSPCantidad };
      case ServiciosPlanificadosActions.LISTA_REPORTE_DINAMICO_SP_FAILURE:
         return { ...state, fetchingListaReporteDinamicoSP: false };

      case ServiciosPlanificadosActions.PDF_REPORTE_DINAMICO_SP_REQUEST:
         return { ...state, fetchingReporteDinamicoPDF: true };
      case ServiciosPlanificadosActions.PDF_REPORTE_DINAMICO_SP_SUCCESS:
         return { ...state, fetchingReporteDinamicoPDF: false };
      case ServiciosPlanificadosActions.PDF_REPORTE_DINAMICO_SP_FAILURE:
         return { ...state, fetchingReporteDinamicoPDF: false };

      case ServiciosPlanificadosActions.EXCEL_REPORTE_DINAMICO_SP_REQUEST:
         return { ...state, fetchingReporteDinamicoEXCEL: true };
      case ServiciosPlanificadosActions.EXCEL_REPORTE_DINAMICO_SP_SUCCESS:
         return { ...state, fetchingReporteDinamicoEXCEL: false };
      case ServiciosPlanificadosActions.EXCEL_REPORTE_DINAMICO_SP_FAILURE:
         return { ...state, fetchingReporteDinamicoEXCEL: false };

      case ServiciosPlanificadosActions.LISTA_REPORTE_DINAMICO_AGRUPADO_SP_REQUEST:
         return { ...state, fetchingReporteDinamicoAgrupadoSP: true, formularioReporte: action.formulario };
      case ServiciosPlanificadosActions.LISTA_REPORTE_DINAMICO_AGRUPADO_SP_SUCCESS:
         return { ...state, fetchingReporteDinamicoAgrupadoSP: false, listaReporteDinamicoAgrupadoSP: action.listaReporteDinamicoAgrupadoSP, listaReporteDinamicoAgrupadoSPCantidad: action.listaReporteDinamicoAgrupadoSPCantidad };
      case ServiciosPlanificadosActions.LISTA_REPORTE_DINAMICO_AGRUPADO_SP_FAILURE:
         return { ...state, fetchingReporteDinamicoAgrupadoSP: false };

      case ServiciosPlanificadosActions.PDF_REPORTE_DINAMICO_AGRUPADO_SP_REQUEST:
         return { ...state, fetchingReporteDinamicoAgrupadoPDF: true };
      case ServiciosPlanificadosActions.PDF_REPORTE_DINAMICO_AGRUPADO_SP_SUCCESS:
         return { ...state, fetchingReporteDinamicoAgrupadoPDF: false };
      case ServiciosPlanificadosActions.PDF_REPORTE_DINAMICO_AGRUPADO_SP_FAILURE:
         return { ...state, fetchingReporteDinamicoAgrupadoPDF: false };

      case ServiciosPlanificadosActions.EXCEL_REPORTE_DINAMICO_AGRUPADO_SP_REQUEST:
         return { ...state, fetchingReporteDinamicoAgrupadoEXCEL: true };
      case ServiciosPlanificadosActions.EXCEL_REPORTE_DINAMICO_AGRUPADO_SP_SUCCESS:
         return { ...state, fetchingReporteDinamicoAgrupadoEXCEL: false };
      case ServiciosPlanificadosActions.EXCEL_REPORTE_DINAMICO_AGRUPADO_SP_FAILURE:
         return { ...state, fetchingReporteDinamicoAgrupadoEXCEL: false };

      case ServiciosPlanificadosActions.MODAL_CREAR_REGISTRO_SP:
         return { ...state, showModalCrearRegistro: action.showModalCrearRegistro, data: action.data };

      case ServiciosPlanificadosActions.CREATE_REGISTROS_SP_REQUEST:
         return { ...state, fetchingCreateRegistro: true };
      case ServiciosPlanificadosActions.CREATE_REGISTROS_SP_SUCCESS:
         return { ...state, fetchingCreateRegistro: false };
      case ServiciosPlanificadosActions.CREATE_REGISTROS_SP_FAILURE:
         return { ...state, fetchingCreateRegistro: false };
         
      case ServiciosPlanificadosActions.MODAL_EDITAR_REGISTRO_SP:
         return { ...state, showModalEditarRegistro: action.showModalEditarRegistro, registroReporteSeleccionado: action.registroReporteSeleccionado, page: action.page, personal: action.personal };

      case ServiciosPlanificadosActions.EDITAR_REGISTROS_SP_REQUEST:
         return { ...state, fetchingEditarRegistro: true };
      case ServiciosPlanificadosActions.EDITAR_REGISTROS_SP_SUCCESS:
         return { ...state, fetchingEditarRegistro: false };
      case ServiciosPlanificadosActions.EDITAR_REGISTROS_SP_FAILURE:
         return { ...state, fetchingEditarRegistro: false };

      default:
         return state;
   }
}
