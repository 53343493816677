import React from 'react';
import {connect} from 'react-redux';
import {Form, Row, Col, Button, Modal, Spin, Input} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalNuevaClasificacion extends React.Component {
    formRef = React.createRef();
    render() {
        const {showModalCrearClasificacion, fetchingCrearClasificacion, page, limpiarFormulario, busqueda,
            onShowModalClasificacion, onCrearClasificacion
        } = this.props;
        
        if (limpiarFormulario && limpiarFormulario === 'crearClasificacion') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        const onFinish = (formulario) => {
            onCrearClasificacion(formulario, page, busqueda);        
        }

        const handleCancel = () => {
            onShowModalClasificacion();
            this.setState({SPSelected: null, activosSelected: []})
            this.formRef.current.resetFields();
        }

        return (
            <div>
                <Modal
                    visible={showModalCrearClasificacion}
                    title='Nueva Gerencia'
                    onCancel={handleCancel}
                    footer={false}
                    width='70%'
                >
                    <Spin spinning={fetchingCrearClasificacion}>
                        <Form {...layout}
                            name='formulario'
                            onFinish={onFinish}
                            ref={this.formRef}
                        >
                            <Row type='flex' justify='space-around'>
                                <Col span={11}>
                                    <Form.Item
                                        label='Nombre de la gerencia'
                                        name='nombreGerencia'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                        <Input maxLength={100} placeholder='Ingresa el nombre de la gerencia' style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                
                                <Col span={11}>
                                    <Form.Item
                                        label='Descripción'
                                        name='descripcion'
                                        >
                                        <TextArea autoSize maxLength={650} style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={11} className='marginBoton'>
                                    <br/>
                                    <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Crear Gerencia</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalCrearClasificacion: state.EvaluacionDeLaGestionReducer.showModalCrearClasificacion,
        fetchingCrearClasificacion: state.EvaluacionDeLaGestionReducer.fetchingCrearClasificacion,
        page: state.EvaluacionDeLaGestionReducer.page,
        busqueda: state.EvaluacionDeLaGestionReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalClasificacion: () => {
            dispatch({ type: "SHOW_MODAL_CREAR_CLASIFICACION", showModalCrearClasificacion: false});
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
        },
        onCrearClasificacion: (formulario, page, busqueda) => {
            dispatch({ type: 'CREAR_CLASIFICACION_REQUEST', formulario, page, busqueda})
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalNuevaClasificacion);