import React, {Component} from 'react'
import { Modal, Row, Col, Button, Spin } from 'antd';
import {ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

class ModalVerPDFReportesDesempeno extends Component {
    state={
        pageNumber: 1,
        numPages: null,
    }
    render() { 
        const {onShowModalVerPDFReportesDesempeno, rutaArchivoEstandaresDesempeno, showModalPDFEstandaresDesempeno,
        } = this.props;

        const handleCancel = () => {
            onShowModalVerPDFReportesDesempeno();
            this.setState({pageNumber: 1});
        }

        const onDocumentLoadSuccess = ({ numPages }) => {
            this.setState({numPages})
        }

        const cambiarPagina = (tipo) => {
            console.log('tipo', tipo)
            if(tipo === 'atras'){
                this.setState({pageNumber: this.state.pageNumber-1})
            }
            else{
                this.setState({pageNumber: this.state.pageNumber+1})
            }
        }

        return ( 
            <Modal
                visible={showModalPDFEstandaresDesempeno}
                title='Consulta De Documento'
                onCancel={handleCancel}
                footer={false}
                width={1050}
            >
                {rutaArchivoEstandaresDesempeno ? (
                    <>
                        <Row justify='space-around'>
                            <Col span={24}>
                                <Document
                                    loading={<Spin spinning={true}/>}
                                    file={rutaArchivoEstandaresDesempeno} onLoadSuccess={onDocumentLoadSuccess}>
                                    
                                    <Page pageNumber={this.state.pageNumber} width={1000} />
                                </Document>
                            </Col>
                        </Row>
                        <br/>
                        <Row justify='space-around'>
                            <Col span={5}>
                                <p>
                                    Página {this.state.pageNumber} de {this.state.numPages}
                                </p>
                            </Col>
                            <Col span={2}>
                                <Button className="iconTer" shape="circle" disabled={this.state.pageNumber === 1} onClick={()=>cambiarPagina('atras')} icon={<ArrowLeftOutlined />}/>
                            </Col>
                            <Col span={2}>
                                <Button className="iconTer" shape="circle" disabled={this.state.pageNumber === this.state.numPages} onClick={()=>cambiarPagina('adelante')} icon={<ArrowRightOutlined />}/>
                            </Col>
                        </Row>
                    </>
                ):false}
            </Modal>
         );
    }
}
 
const mapStateToProps = state => {
	return {
        showModalPDFEstandaresDesempeno: state.GestionDeCalidadReducer.showModalPDFEstandaresDesempeno,
        rutaArchivoEstandaresDesempeno: state.GestionDeCalidadReducer.rutaArchivoEstandaresDesempeno,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalVerPDFReportesDesempeno: () => {
            dispatch ({ type: 'MODAL_SHOW_PDF_ESTANDARES_DESEMPENO',
                showModalPDFEstandaresDesempeno: false, rutaArchivoEstandaresDesempeno: null })
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalVerPDFReportesDesempeno);
