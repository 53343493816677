import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import { Form, Button, Row, Col, Spin, message, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import {loginCall} from '../../../services/LoginServices';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const VerificarContraseña = props => {
    let formRef = React.createRef();
    const {fetchingLogin, formularioNivelTiempoRespuesta, nivelTiempoRespuestaSeleccionado, onGetListaNivelTREdicion, onCambiarVista} = props;

    useEffect (() => {
        onGetListaNivelTREdicion(formularioNivelTiempoRespuesta,nivelTiempoRespuestaSeleccionado);
    }, [])

    let userInfo = sessionStorage.getItem('userInfo');
    if (!userInfo.idUsuario) {
        userInfo = JSON.parse(userInfo);
    }

    const onFinish = formulario => {
        console.log(formulario);
        const userName = userInfo[0].username;
        const values = {
            'userName': userName,
            'password': formulario.password,  
        }
        console.log(values);
        loginCall(values).then(response => {
            console.log(response);
            
            if(response.status === 200){
                onCambiarVista(3, formularioNivelTiempoRespuesta);
            }
            else{
                let errorMensaje = '';
                if(response.error_message === 'Bad credentials'){
                    errorMensaje = 'Contraseña incorrecta. Por favor intente de nuevo';
                }
                else{
                    errorMensaje = response.error_message;
                }
                message.error(errorMensaje);
                return;
            }
        }).catch(error => {
            console.log(error);
            message.error('Hubo un error, favor de intentar después');
            return;
        })
        
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    return(
        <>
            <Spin spinning={fetchingLogin}>
                <Form {...layout}
                    name="login"
                    onFinish={onFinish}
                    
                    ref={formRef}
                >
                    <Row justify='space-around'>
                        <Col span={11}>
                            <Form.Item
                                label="Ingresa tu contraseña"
                                name="password"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Input.Password prefix={<LockOutlined />} placeholder="Ingresa tu contraseña" />
                            </Form.Item>
                        </Col>
                        <Col span={11} className='marginBoton'>
                            <br />
                            <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Verificar Contraseña</Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </>
    )
}

const mapStateToProps = state => {
    return {
        fetchingLogin: state.LoginReducer.fetchingLogin,
        formularioNivelTiempoRespuesta: state.MecanismosDePagosReducer.formularioNivelTiempoRespuesta,
        nivelTiempoRespuestaSeleccionado: state.MecanismosDePagosReducer.nivelTiempoRespuestaSeleccionado,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCambiarVista: (vistaModalNivelTiempoRespuesta, formularioNivelTiempoRespuesta) => {
            dispatch({ type: 'CAMBIAR_VISTA_NIVEL_TIEMPO_RESPUESTA', vistaModalNivelTiempoRespuesta, formularioNivelTiempoRespuesta }) 
        },
        onGetListaNivelTREdicion: (formularioNivelTiempoRespuesta,nivelTiempoRespuestaSeleccionado) => {
            dispatch({ type: 'LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_E_REQUEST',formularioNivelTiempoRespuesta,nivelTiempoRespuestaSeleccionado});
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (VerificarContraseña);