import React, { Component } from 'react';
import { Form, Input, Button, Row, Col, Spin, Modal, Select, Switch, message, Checkbox} from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const { Option, OptGroup } = Select;
const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };

class ModalNuevoAlmacen extends Component {
     formRef = React.createRef();
     state = {
          almacenSeleccionado: '',
          caracteristicaInventarioLocal: true,
          serviciosTodos: false,
     }
     render() {
          const { 
               fetchingNuevoAlmacen, 
               showModalNuevoAlmacen, 
               onShowModalNuevoAlmacen,
               onNuevoAlmacen,  
               page, 
               limpiarFormulario,
               fetchingListaServiciosFiltro,
               listaServiciosFiltro,
               fetchingListaAreasFuncionalesActivos,
               listaAreasFuncionalesActivos,
               onListaServiciosFiltro,
               onListaServiciosFiltroLimpiar,
               listaServiciosActivosF,
               checked

          } = this.props

          const onFinish = formulario => {
               formulario['caracteristicaInventario'] = this.state.caracteristicaInventarioLocal;
               console.log('this.state.serviciosTodos', this.state.serviciosTodos)
               console.log('Success:', formulario);
               console.log('checked', checked)
               onNuevoAlmacen(formulario, page, listaServiciosActivosF, this.state.serviciosTodos);
               onListaServiciosFiltroLimpiar();
          }

          if (limpiarFormulario && limpiarFormulario === 'crearAlmacen') {
               this.formRef.current.resetFields();
               this.props.onLimpiarFormulario();
               this.state.almacenSeleccionado = '';
          }

          const onFinishFailed = errorInfo => {
               console.log('Failed: ', errorInfo);
          };

          const handleCancel = () => {
               onShowModalNuevoAlmacen();
               this.setState({
                    almacenSeleccionado: ''
                });
               this.formRef.current.resetFields();
               onListaServiciosFiltroLimpiar();
          }

          const buscarServicios = value => {
               if(value.length>=3){
                    if(value){
                         onListaServiciosFiltro(value)
                    } else {
                         message.warning('No se encontró servicio con esas palabras')
                    }
               } else {
                    onListaServiciosFiltroLimpiar();
               } 
          }

          const handleCaracteristica = (checked) => {
               if(!checked)
                    this.state.caracteristicaInventarioLocal = false;
               else
                    this.state.caracteristicaInventarioLocal = true;
          }

          const todosServicios = () => {
               this.setState({ checked: !this.state.checked });
               console.log('checked', this.state.checked);
          };

          const onChangeServicios = value => {
               this.setState({serviciosTodos: false});
               const todos = value.some(item => item === 'todos');
               if(todos) {
                    this.setState({ serviciosTodos: true });
                    this.formRef.current.setFieldsValue({'servicios':['todos']})
               }
          };

          return (
               <Modal
                    visible={showModalNuevoAlmacen}
                    title='Nuevo Almacen'
                    onCancel={handleCancel}
                    footer={false}
               >
                    <Spin spinning={fetchingNuevoAlmacen}>
                         <Form {...layout}
                         name='formulario'
                         onFinish={onFinish}
                         
                         ref={this.formRef}
                         >
                              <Row type='flex' justify='space-between'>
                                   <Col span={19}>
                                        <Form.Item
                                        label="Nombre"
                                        name="nombreC"
                                        rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                        >
                                             <Input
                                                  pattern="^[0-9A-Za-zñáéíóúÑÁÉÍÓÚüÜ;\.:'\s\-,]+$"
                                                  title="Nombre sin carácteres especiales." 
                                                  maxLength={100} 
                                                  placeholder='Escribe el nombre corto'
                                             />
                                        </Form.Item>
                                   </Col>
                                   <Col span={4}>
                                        <Form.Item
                                        label='Disponible'
                                        name='caracteristicaInventario'
                                        >
                                             <Switch
                                                  checkedChildren={<CheckOutlined/>}
                                                  unCheckedChildren={<CloseOutlined/>}
                                                  defaultChecked
                                                  onChange={handleCaracteristica}
                                             />
                                        </Form.Item>                                  
                                   </Col>
                              </Row>

                              <Row justify='space-around'>
                                   <Col span={24}>
                                        <Form.Item
                                        label="Descripción"
                                        name="nombreL"
                                        rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                        >
                                             <Input
                                                  pattern="^[0-9A-Za-zñáéíóúÑÁÉÍÓÚüÜ;\.:/()'\s\-,]+$"
                                                  title="Nombre sin carácteres especiales."
                                                  maxLength={250}
                                                  placeholder='Escribe la descripción'
                                                  //autoSize
                                             />
                                        </Form.Item>
                                   </Col>

                              {/* <Form.Item
                              label="Servicio"
                              name="idServicio"
                              rules={[{ required: this.state.checked, message: 'Favor de llenar el campo', }]}
                              >
                                   { this.state.checked ? (
                                   <Select
                                        mode="multiple"
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Escribe el nombre del servicio"
                                        loading={fetchingListaServiciosFiltro}
                                        showSearch={true}
                                        filterOption={false}
                                        onSearch={buscarServicios}
                                        notFoundContent={null}
                                        
                                   >
                                             {listaServiciosFiltro.map(option => <Option key={option.idServicio + '-' + option.nombre}>{option.idServicio + '-' + option.nombre}</Option>)}
                                   </Select>
                                   ): false }
                              
                              </Form.Item>

                              <Form.Item
                              name='todosServicios'>
                                   <Checkbox onChange={todosServicios}>Todos</Checkbox>
                              </Form.Item> */}

                                   <Col span={24}>
                                        <Form.Item
                                             label='Servicio'
                                             name='idServicio'
                                             rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                             <Select
                                                  mode='multiple'
                                                  allowClear
                                                  style={{ width: '100%', textAlign: 'left' }}
                                                  placeholder="Ingresa el servicio a buscar"
                                                  loading={fetchingListaServiciosFiltro}
                                                  showSearch
                                                  filterOption={false}
                                                  onSearch={buscarServicios}
                                                  onChange={onChangeServicios}
                                                  notFoundContent={null}
                                             >
                                                  <OptGroup label="Todos los servicios">
                                                       <Option value="todos">Todos los Servicios</Option>
                                                  </OptGroup>
                                                  <OptGroup label="Servicios">
                                                       {listaServiciosFiltro.map(option => <Option disabled={this.state.serviciosTodos} key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>{option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}</Option>)}
                                                  </OptGroup>
                                             </Select>
                                        </Form.Item>
                                   </Col>

                                   <Col span={24}>
                                        <Form.Item
                                        label='Área Funcional'
                                        name="idAreaFuncional"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                             <Select 
                                                  allowClear
                                                  style={{ width: '100%' }}
                                                  placeholder="Selecciona el área funcional"
                                                  loading={fetchingListaAreasFuncionalesActivos}
                                             >
                                                  {listaAreasFuncionalesActivos.map(option => <Option key={option.idAreaFuncional}>{ option.codigoArea + ' - ' + option.areaFuncional }</Option>)}
                                             </Select>
                                        </Form.Item>
                                   </Col> 

                                   <Col span={12}>
                                        <Button style={{width:'100%'}} className="btnFiltrar" htmlType="submit">Crear Almacen</Button>
                                   </Col>
                              </Row>
                         </Form>
                    </Spin>
               </Modal>
          )
     }
}

const mapStateToProps = state => {
     return {
          fetchingNuevoAlmacen: state.AlmacenesReducer.fetchingNuevoAlmacen,
          showModalNuevoAlmacen: state.AlmacenesReducer.showModalNuevoAlmacen,
          page: state.AlmacenesReducer.page,
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
          listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
          fetchingListaAreasFuncionalesActivos: state.CatalogosGeneralesReducer.fetchingListaAreasFuncionalesActivos,
          listaAreasFuncionalesActivos: state.CatalogosGeneralesReducer.listaAreasFuncionalesActivos,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          checked: state.AlmacenesReducer.checked,
     };
};

const mapDispatchToProps = dispatch => {
     return {
          onShowModalNuevoAlmacen: () => {
               dispatch({ type: 'SHOW_MODAL_NUEVO_ALMACEN', showModalNuevoAlmacen: false });
          },
          onNuevoAlmacen: (formulario, page, listaServiciosActivosF, checked) => {
               dispatch({ type: 'NUEVO_ALMACEN_REQUEST', formulario: formulario, page: page, listaServiciosActivosF, checked });
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
          },
          onListaServiciosFiltro: (busqueda) => {
               dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
          },
          onListaServiciosFiltroLimpiar: () => {
               dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoAlmacen);