import React, { Component } from 'react';
import { Form, Button, Row, Col, Modal, InputNumber, Input, Divider, message } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const confirm = Modal.confirm;

class PorcentajeDeduccion extends Component {
    formRef = React.createRef();
    render() {
        const { 
            onCambiarVista, 
            formularioDisponibilidad,
            FallasDeDisponibilidadSeleccionado,
            fallaDisponibilidadEditados,
        } = this.props;


        const onFinish = formulario => {
            console.log('formulario en PorcentajeDeducción');
            console.log(formulario.porcentajeDeduccion);
            console.log('formularioDisponibilidad.porcentajeDeduccion: ') 
            console.log(formularioDisponibilidad.porcentajeDeduccion);

            if (formulario.porcentajeDeduccion !== formularioDisponibilidad.porcentajeDeduccion) {
                message.warning('El valor del porcentaje de deducción, debe ser el mismo que ingresó al editar (' + formularioDisponibilidad.porcentajeDeduccion + ').');
            }
            else{
                confirm({
                    title: 'Esta es la categoría de falla de disponibilidad que fue modificada. ¿Estás seguro de modificarlo?',
                    content: 
                    <div>
                        
                        <Row justify='space-around' key={FallasDeDisponibilidadSeleccionado.idFallaDisponibilidad}>
                            <Col span={14}>
                                <p><strong>Categoría: </strong>{FallasDeDisponibilidadSeleccionado.fallaDisponibilidad}</p>
                            </Col>
                            <Col span={8}>
                                <p><strong>Porcentaje de Deducción: </strong>{formulario.porcentajeDeduccion}%</p>
                            </Col>
                        </Row>
                        
                    </div>,
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    width: '50%',
                    onOk() {
                        onCambiarVista(4, formularioDisponibilidad);
                    },
                    onCancel() {
                        onCambiarVista(1, null);
                    },
                });
            }
        };
       
        

        const changeDecimales = (value) => {
            let valueDecimal = parseFloat(value).toFixed(0);
            let campo = 'porcentajeDeduccion';
            if(isNaN(valueDecimal)){
                valueDecimal = 0.00
            }
            this.formRef.current.setFieldsValue({[campo]: valueDecimal});
        }

        return (
            <Form {...layout}
            name="formulario"
            onFinish={onFinish}
            
            ref={this.formRef}
            >
                <div>
                    <h2>Favor de ingresar nuevamente el porcentaje de deducción</h2>                   
                    <Row justify='space-around'>
                        <Col span={24}>
                            <Form.Item
                            label="Porcentaje de Deducción"
                            name={'porcentajeDeduccion'}
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <InputNumber 
                                min={0.01} 
                                max={100.00}
                                step={1.00} 
                                formatter={value => `${value}%`}
                                parser={value => value.replace('%', '')}
                                placeholder="Ingresa el porcentaje de deducción por falla de disponibilidad"
                                onChange={changeDecimales}
                                title="Número debe ser entero o fracción a dos decímales" 
                                pattern='^[0-9]*(\.[0-9]{0,2})?%' />
                            </Form.Item>
                        </Col>
                    </Row>
                 
                    <Col span={24} className='marginBoton'>
                        <br/>
                        <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">
                            Guardar Cambios
                        </Button>
                    </Col>
                </div>
            </Form>
        );
    }
}

const mapStateToProps = state => {
	return {
        formularioDisponibilidad: state.MecanismosDePagosReducer.formularioDisponibilidad,
        FallasDeDisponibilidadSeleccionado: state.MecanismosDePagosReducer.FallasDeDisponibilidadSeleccionado,
        fallaDisponibilidadEditados: state.MecanismosDePagosReducer.fallaDisponibilidadEditados,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onCambiarVista: (vistaModalFallaDisponiblidad, formularioDisponibilidad, fallaDisponibilidadEditados) => {
            dispatch({ type: 'CAMBIAR_VISTA_FALLA_DISPONIBILIDAD', vistaModalFallaDisponiblidad: vistaModalFallaDisponiblidad, formularioDisponibilidad: formularioDisponibilidad, fallaDisponibilidadEditados: fallaDisponibilidadEditados })
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PorcentajeDeduccion);