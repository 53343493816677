import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, Row, Select } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Option } = Select;
class ModalEditarReferenciaDeEstandares extends Component {
     formRef = React.createRef();
     state = {
          claseReferenciaSeleccionado: '',
     }
     render() {
          
          const { 
               fetchingEditarRerenciaEstandar, 
               showModalEditarReferenciaEstandar, 
               onEditarReferenciaEstandar, 
               onShowModalEditarReferenciaEstandar,
               referenciaEstandarSeleccionada, 
               limpiarFormulario,
               mostrarEspecifica,
               onActualizarEstandar
          } = this.props;

          console.log('referenciaEstandarSeleccionada en modal: ', referenciaEstandarSeleccionada);
          const onFinish = formulario => {
               console.log('Success:', formulario);
               onEditarReferenciaEstandar(formulario, referenciaEstandarSeleccionada, this.props.page, this.props.busqueda);
          };

          if (limpiarFormulario && limpiarFormulario === 'editarReferenciaEstandar') {
               this.formRef.current.resetFields();
               this.props.onLimpiarFormulario();
               onActualizarEstandar(false);
          }

          const onFinishFailed = errorInfo => {
               console.log('Failed:', errorInfo);
          };

          const handleCancel = () => {
               this.setState({
                    claseReferenciaSeleccionado: ''
                });
               onShowModalEditarReferenciaEstandar();
          }

          const seleccionClaseReferencia = (value) => {
               console.log('value: ', value);
               let mostrarEspecificaLocal = false;
   
               if(value === 'General'){
                   mostrarEspecificaLocal = false;
               } else{
                   mostrarEspecificaLocal = true;
               }
               onActualizarEstandar(mostrarEspecificaLocal);
               console.log('Dentro de sección mostrarEspecificaLocal: ', mostrarEspecificaLocal);
          }
          console.log('Fuera de sección mostrarEspecificaLocal: ', mostrarEspecifica);

          return (
               <Modal
               visible={showModalEditarReferenciaEstandar}
               title='Editar Referencias'
               onCancel={handleCancel}
               footer={false}
               >
                    {referenciaEstandarSeleccionada ? ( 
                    <Spin spinning={fetchingEditarRerenciaEstandar}>
                         
                         <Form {...layout}
                         name="formulario"
                         initialValues={referenciaEstandarSeleccionada}
                         onFinish={onFinish}
                         
                         ref={this.formRef}
                         >
                              <Row justify='space-around'>
                                   <Col span={24}>
                                        <Form.Item
                                        label="Referencia"
                                        name="referencia"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                             <Input
                                             pattern="^([ñíóáéúÁÉÓÍÚa-zA-Z])+([ ])+([0-9]{1,2})$"
                                             title="Referencia sin carácteres especiales" 
                                             maxLength={80} 
                                             placeholder='Referencia'/>
                                        </Form.Item>
                                   </Col>

                                   <Col span={24}>
                                        <Form.Item
                                        label="Clase Referencia"
                                        name="claseReferencia"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                             <Select allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Selecciona la clase referencia"
                                             onChange={seleccionClaseReferencia}
                                             >
                                                  <Option value="General">General</Option>
                                                  <Option value="Específica">Específica</Option>
                                             </Select>
                                        </Form.Item>
                                   </Col>

                                   {mostrarEspecifica ===  true ? (
                                   <Col span={24}>
                                        <Form.Item
                                        label="Subnivel Referencia"
                                        name="subnivel"
                                        rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                        >
                                             <Select allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Selecciona la referencia"
                                             >
                                                  <Option value="01">01</Option>
                                                  <Option value="02">02</Option>
                                                  {/* <Option value="No tiene subnivel">No tiene subnivel</Option> */}
                                             </Select>
                                        </Form.Item>
                                   </Col>
                                   ): false}

                                   <Col span={24}>
                                        <Form.Item
                                        label="Descripción"
                                        name="descripcion"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                             <TextArea
                                             pattern="^[ñíóáéú a-zA-Z ]+$"
                                             title="Descripción sin carácteres especiales" 
                                             maxLength={150} 
                                             placeholder='Descripción'
                                             autoSize />
                                        </Form.Item>
                                   </Col>

                                   <Col span={24}>
                                        <Button className="btnFiltrar" htmlType="submit">Editar Referencia</Button>
                                   </Col>
                              </Row>
                         </Form>
                         
                    </Spin>
                    ): false}
               </Modal>
          );
     }
}

const mapStateToProps = state => {
	return {
          fetchingEditarRerenciaEstandar: state.MecanismosDePagosReducer.fetchingEditarRerenciaEstandar,
          showModalEditarReferenciaEstandar: state.MecanismosDePagosReducer.showModalEditarReferenciaEstandar,
          referenciaEstandarSeleccionada: state.MecanismosDePagosReducer.referenciaEstandarSeleccionada,
          page: state.MecanismosDePagosReducer.page,
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          busqueda: state.MecanismosDePagosReducer.busqueda,
          mostrarEspecifica: state.MecanismosDePagosReducer.mostrarEspecifica
	};
};

const mapDispatchToProps = dispatch => {
	return {
          onShowModalEditarReferenciaEstandar: () => {
               dispatch({ type: 'SHOW_MODAL_EDITAR_REFERENCIA_ESTANDAR', showModalEditarReferenciaEstandar: false, referenciaEstandarSeleccionada: null})
          },
          onEditarReferenciaEstandar: ( formulario, referenciaEstandarSeleccionada, page, busqueda ) => {
               dispatch({ type: "EDITAR_REFERENCIA_ESTANDAR_REQUEST" , formulario: formulario, referenciaEstandarSeleccionada: referenciaEstandarSeleccionada, page: page, busqueda: busqueda });
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
          onActualizarEstandar: (mostrarEspecifica) => {
               dispatch({ type: "ESTANDARES_DESEMPEÑO_BUSQUEDA_FAILURE", mostrarEspecifica: mostrarEspecifica });
          },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarReferenciaDeEstandares);