import React, { Component } from 'react';
import { Form, Input, Button, Col, Spin, Modal, Select, message} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalNuevaClaveMetodoSupervision extends Component {
    formRef = React.createRef();


    render() {
        const { fetchingNuevoClavesMetodosSupervision, showModalNuevaClaveMetodosSupervision, onShowModalNuevaClaveMetodoSupervision,
            onNuevaClaveMetodoSupervision, page, limpiarFormulario} = this.props

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onNuevaClaveMetodoSupervision(formulario, page);
        }

        if (limpiarFormulario && limpiarFormulario === 'crearClave') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo);
        };

        const handleCancel = () => {
            onShowModalNuevaClaveMetodoSupervision();
            this.formRef.current.resetFields();
        }

        const claveMetodosSupervision = ["Interno", "Externo"];

        return (
            <Modal
                visible={showModalNuevaClaveMetodosSupervision}
                title='Nuevo Método de Supervisión'
                onCancel={handleCancel}
                footer={false}
            >
                <Spin spinning={fetchingNuevoClavesMetodosSupervision}>
                    <Form {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        
                        ref={this.formRef}
                    >
                        <Form.Item
                            label="Clase"
                            name="claveMetodoSupervision"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                             <Select allowClear
                                style={{ width: '100%' }}
                                placeholder="Seleccione la clase método de supervisión"
                            >
                                {claveMetodosSupervision.map(option => <Option key={option} value={option}>{option}</Option>)}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Descripción corta"
                            name="descripcionC"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <TextArea
                            pattern="^[ñíóáéú a-zA-Z ]+$"
                            title="Descripción sin carácteres especiales" 
                            maxLength={100} 
                            placeholder='Descripción corta'
                            autoSize />
                        </Form.Item>

                        <Form.Item
                            label="Descripción larga"
                            name="descripcionL"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <TextArea
                            pattern="^[ñíóáéú a-zA-Z ]+$"
                            title="Descripción sin carácteres especiales" 
                            maxLength={200} 
                            placeholder='Descripción larga'
                            autoSize />
                        </Form.Item>
                        <Col span={12}>
                            <Button className="btnFiltrar" htmlType="submit">Crear Método de Supervisión</Button>
                        </Col>
                    </Form>
                </Spin>

            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingNuevoClavesMetodosSupervision: state.MecanismosDePagosReducer.fetchingNuevoClavesMetodosSupervision,
        showModalNuevaClaveMetodosSupervision: state.MecanismosDePagosReducer.showModalNuevaClaveMetodosSupervision,
        page: state.MecanismosDePagosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevaClaveMetodoSupervision: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_CLAVE_METODO_SUPERVISION', showModalNuevaClaveMetodosSupervision: false });
        },
        onNuevaClaveMetodoSupervision: (formulario, page) => {
            dispatch({ type: 'NUEVA_CLAVE_METODO_SUPERVISION_REQUEST', formulario: formulario, page: page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevaClaveMetodoSupervision);