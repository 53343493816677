import React, {Component} from 'react'
import { Form, Select, Button, Col, Spin, Modal, Row, Input, message, InputNumber } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalEditarPlanCalidad extends Component {
    formRef = React.createRef();
    state={
        listaAños: [],
    }
    render() { 
        const {
            onEditarPonderadorEncuesta, onShowModalEditarPonderadorEncuesta, showModalEditarPonderadorEncuesta,
            fetchingEditarPonderadorEncuesta, listaInfoProyecto, page, ponderadorEncuestaSeleccionado,
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onEditarPonderadorEncuesta(formulario, page, ponderadorEncuestaSeleccionado);
        };

        const handleCancel = () => {
            onShowModalEditarPonderadorEncuesta();
            this.formRef.current.resetFields();
        }
        const onListaAños = () => {
            let list = [];
            console.log('listaInfoProyecto', listaInfoProyecto)
            if(listaInfoProyecto){
                list.push(parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2]))
                for(let i = 0; i <listaInfoProyecto.vigencia; i++){
                    list.push(parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2]) + parseInt(i) +  1)
                }
            }
            this.setState({
               listaAños: list
            }); 
        };

        const changeDecimal = (value) => {
            const factor = parseFloat(value).toFixed(2)
            this.formRef.current.setFieldsValue({'factor': factor})
        }
        
        return (
            <Modal
                visible={showModalEditarPonderadorEncuesta}
                title='Editar Ponderador para Encuesta de Satisfacción'
                onCancel={handleCancel}
                footer={false}
                width='50%'
            >
                <Spin spinning={fetchingEditarPonderadorEncuesta}>
                {ponderadorEncuestaSeleccionado ? (
                    <Form {...layout}
                        name="formulario"
                        onFinish={onFinish}
                        ref={this.formRef}
                        initialValues={ponderadorEncuestaSeleccionado}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Etiqueta de calificación"
                                    name="etiqueta"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={25} placeholder='Ingresa la nomenclatura' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Año de vigencia"
                                    name="anio"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Seleccione el año"
                                        loading={this.state.fetchingListaAños}
                                        onClick={onListaAños}
                                    >
                                        {this.state.listaAños.map(option => <Option key={option}>{option}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Factor"
                                    name="factor"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <InputNumber min={0.01} max={1} onChange={changeDecimal} placeholder='Ingresa el factor' style={{width: '100%'}} />
                                </Form.Item>
                            </Col>

                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Editar Ponderador para Encuesta de Satisfacción</Button>
                            </Col>
                        </Row>
                    </Form>
                    ):false}
                </Spin>
            </Modal>
        );
    }
}
 const mapStateToProps = state => {
     return {
        showModalEditarPonderadorEncuesta: state.GestionDeCalidadReducer.showModalEditarPonderadorEncuesta,
        fetchingEditarPonderadorEncuesta: state.GestionDeCalidadReducer.fetchingEditarPonderadorEncuesta,
        ponderadorEncuestaSeleccionado: state.GestionDeCalidadReducer.ponderadorEncuestaSeleccionado,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        page: state.GestionDeCalidadReducer.page,
        listaPlanCalidadAños: state.GestionDeCalidadReducer.listaPlanCalidadAños,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarPonderadorEncuesta: () => {
            dispatch ({ type: 'MODAL_EDITAR_PONDERADOR_ENCUESTA', showModalEditarPonderadorEncuesta: false })
        },
        onEditarPonderadorEncuesta: (formulario, page, ponderadorEncuestaSeleccionado) => {
            dispatch({ type: "EDITAR_PONDERADOR_ENCUESTA_REQUEST", formulario, page, ponderadorEncuestaSeleccionado })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarPlanCalidad);