import React from 'react';
import {connect} from 'react-redux';
import {Form, Row, Col, Button, Modal, Spin, Input} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalNuevoRegistroReporte extends React.Component {
     formRef = React.createRef();
     render() {
          const {showModalCrearRegistro, fetchingCreateRegistro, limpiarFormulario,  onShowModalRegistroReporte, onCrearRegistroReporte, data } = this.props;
          
          if (limpiarFormulario && limpiarFormulario === 'crearRegistroReporteRH') {
               this.formRef.current.resetFields();
               this.props.onLimpiarFormulario();
          }

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if (permisosUsuario[0]) {  permisosUsuario = JSON.parse(permisosUsuario);  }

          const onFinish = (formulario) => {
               console.log("estoy en SP onFinish")
               onCrearRegistroReporte(formulario, data);        
          }

          const handleCancel = () => {
               onShowModalRegistroReporte();
               this.formRef.current.resetFields();
          }

          console.log("estoy en SP")

          return (
               <div>
                    <Modal
                         visible={showModalCrearRegistro}
                         title='Nuevo Registro Predeterminado'
                         onCancel={handleCancel}
                         footer={false}
                         width='70%'
                    >
                         <Spin spinning={fetchingCreateRegistro}>
                         <Form {...layout}
                              name='formulario'
                              onFinish={onFinish}
                              ref={this.formRef}
                         >
                              <Row type='flex' justify='space-around'>
                                   <Col span={11}>
                                        <Form.Item
                                             label='Título del documento'
                                             name='titulo'
                                             rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                             >
                                             <Input maxLength={200} placeholder='Ingresa el título del documento' style={{ width: '100%' }} />
                                        </Form.Item>
                                   </Col>
                                   <Col span={11}>
                                        <Form.Item
                                             label='Nombre'
                                             name='nombre'
                                             rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                             >
                                             <Input maxLength={100} placeholder='Ingresa el nombre' style={{ width: '100%' }} />
                                        </Form.Item>
                                   </Col>
                                   
                                   <Col span={11}>
                                        <Form.Item
                                             label='Descripción'
                                             name='descripcion'
                                             >
                                             <TextArea autoSize maxLength={450} placeholder='Ingresa la descripción' style={{ width: '100%' }} />
                                        </Form.Item>
                                   </Col>
                                   <Col span={11} className='marginBoton'>
                                        <br/>
                                        <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Crear Registro Predeterminado</Button>
                                   </Col>
                              </Row>
                         </Form>
                         </Spin>
                    </Modal>
               </div>
          )
     }
}

const mapStateToProps = state => {
     return {
          showModalCrearRegistro: state.ServiciosPlanificadosReducer.showModalCrearRegistro,
          fetchingCreateRegistro: state.ServiciosPlanificadosReducer.fetchingCreateRegistro,
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          data: state.ServiciosPlanificadosReducer.data,
     }
}

const mapDispatchToProps = dispatch => {
     return {
          onShowModalRegistroReporte: () => {
               dispatch({ type: "MODAL_CREAR_REGISTRO_SP", showModalCrearRegistro: false});
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
          },
          onCrearRegistroReporte: (formulario, data) => {
               dispatch({ type: 'CREATE_REGISTROS_SP_REQUEST', formulario, data})
          },
     }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalNuevoRegistroReporte);