import React, {Component} from 'react'
import { Modal } from 'antd';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import EditarNivelSeveridad from './EditarNivelSeveridad';
import VerificarContraseña from './VerificarContraseña';
import ListaNivelesSeveridad from './ListaNivelesSeveridad';
import ArchivoEditarNivelesSeveridad from './ArchivoEditarNivelesSeveridad';

class ModalEditarTiempoDeGarantia extends Component {
    render() { 
        const {onShowModalEditarNivelSeveridad,  onCambiarVista, vistaModalNivelSeveridad,
            showModalEditarNivelSeveridad,
        } = this.props;

        const handleCancel = () => {
            onShowModalEditarNivelSeveridad()
            onCambiarVista(1);
        }

        function ContenidoEditar() {
            if(vistaModalNivelSeveridad === 1){
                return(
                    <EditarNivelSeveridad/>
                );
            }
            else if(vistaModalNivelSeveridad === 2){
                return(
                    <VerificarContraseña/>
                );
            }
            else if(vistaModalNivelSeveridad === 3){
                return(
                    <ListaNivelesSeveridad/>
                );
            }
            else if(vistaModalNivelSeveridad === 4){
                return(
                    <ArchivoEditarNivelesSeveridad/>
                );
            }
        };

        return ( 
            <Modal
            visible={showModalEditarNivelSeveridad}
            title='Editar Nivel de Severidad'
            onCancel={handleCancel}
            footer={false}
            >
                <ContenidoEditar/>
            </Modal>
         );
    }
}
 
const mapStateToProps = state => {
	return {
        showModalEditarNivelSeveridad: state.MecanismosDePagosReducer.showModalEditarNivelSeveridad,
        vistaModalNivelSeveridad: state.MecanismosDePagosReducer.vistaModalNivelSeveridad,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarNivelSeveridad: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_NIVEL_SEVERIDAD', showModalEditarNivelSeveridad: false })
        },
        onCambiarVista: (vistaModalNivelSeveridad) => {
            dispatch({ type: 'CAMBIAR_VISTA_NIVEL_SEVERIDAD', vistaModalNivelSeveridad })
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarTiempoDeGarantia);