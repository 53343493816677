import React, { Component } from 'react';
import { Form, Input, Button, Col, Spin, Modal, Radio, Select, Row } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';
const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalNuevaUbicacionProducto extends Component {
    formRef = React.createRef();

 

    render() {
        const { fetchingNuevaUbicacionProducto, showModalNuevaUbicacionProducto, onShowModalNuevaUbicacionProducto,onNuevaUbicacionProducto,
            listaAlmacenesActivos, fetchingListaAlmacenesActivos, page, limpiarFormulario } = this.props

        const onFinish = formulario => {
            console.log('Success:' , formulario);
            onNuevaUbicacionProducto(formulario, page);
        }

        if (limpiarFormulario && limpiarFormulario==='CUP') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo);
        };

        const handleCancel = () => {
            onShowModalNuevaUbicacionProducto();
            this.formRef.current.resetFields();
        }
        return (
            <Modal
                visible={showModalNuevaUbicacionProducto}
                title='Nueva Ubicación del Producto'
                onCancel={handleCancel}
                footer={false}
                width="60%"
            >
                <Spin spinning={fetchingNuevaUbicacionProducto}>
                    <Form {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        
                        ref={this.formRef}
                    >
                        <Row type='flex' justify='space-between'>
                            <Col span={11}>
                                <Form.Item
                                    label="Piso"
                                    name="piso"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Input
                                        pattern="^^[ñíóáéú a-zA-Z0-9]+$"
                                        title="Nombre debe tener letras y números."
                                        maxLength={100} placeholder='Ingrese piso' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Area"
                                    name="area"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Input
                                        pattern="^^[ñíóáéú a-zA-Z0-9]+$"
                                        title="Nombre debe tener letras o números."
                                        maxLength={100} placeholder='Ingrese área' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Pasillo"
                                    name="pasillo"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input
                                        pattern="^^[ñíóáéú a-zA-Z0-9]+$"
                                        title="Nombre debe tener letras o números."
                                        maxLength={100} placeholder='Ingrese pasillo' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Rack"
                                    name="rack"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Input
                                        pattern="^^[ñíóáéú a-zA-Z0-9]+$"
                                        title="Nombre debe tener letras o números."
                                        maxLength={100} placeholder='Ingrese rack' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Nivel del rack"
                                    name="nivelRack"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Input
                                        pattern="^^[ñíóáéú a-zA-Z0-9]+$"
                                        title="Nombre debe tener letras o números."
                                        maxLength={100} placeholder='Ingrese nivel del rack' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Posición en el rack"
                                    name="posicionRack"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Input
                                        pattern="^^[ñíóáéú a-zA-Z0-9]+$"
                                        title="Nombre debe tener letras o números."
                                        maxLength={100} placeholder='Ingrese posición en el rack' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Almacen"
                                    name="idAlmacen"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Seleccione un almacén"
                                        loading={fetchingListaAlmacenesActivos}
                                    >
                                        {listaAlmacenesActivos.map(option => <Option key={option.idAlmacen}>{option.nombreC}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11} className='marginBoton'>
                                <br />
                                <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Crear Ubicación de Producto</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>

            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingNuevaUbicacionProducto: state.AlmacenesReducer.fetchingNuevaUbicacionProducto,
        showModalNuevaUbicacionProducto: state.AlmacenesReducer.showModalNuevaUbicacionProducto,
        page: state.AlmacenesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaAlmacenesActivos: state.AlmacenesReducer.fetchingListaAlmacenesActivos,
        listaAlmacenesActivos: state.AlmacenesReducer.listaAlmacenesActivos,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevaUbicacionProducto: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVA_UBICACION_PRODUCTO', showModalNuevaUbicacionProducto: false });
        },
        onNuevaUbicacionProducto: (formulario, page) => {
            dispatch( { type: 'NUEVA_UBICACION_PRODUCTO_REQUEST', formulario: formulario, page:page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevaUbicacionProducto);