import React, { Component } from 'react';
import {Row, Col, Modal, Descriptions, Spin} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';

class ModalDetalleTicketOrigen extends Component {
    formRef = React.createRef();

     render() {
          const { onCloseModalDetalleTicketOrigen, showModalDetalleTicketOrigen, ticketOrigenSeleccionado,
               fetchingListaTicketsOrigen,
          } = this.props;

          let data=[];
          if (ticketOrigenSeleccionado){
               let { folio, servicio, areaFuncional, nombreFalla, descripcionSolicitud, fechaAltaDescription, fechaCierreDescription } = ticketOrigenSeleccionado;
               
               data = [
                    {label: 'Número de ticket', data: folio, key: 'folio'},
                    {label: 'Fecha de alta', data: fechaAltaDescription, key: 'fechaAltaDescription'},
                    {label: 'Fecha de cierre', data: fechaCierreDescription, key: 'fechaCierreDescription'},
                    {label: 'Servicio', data: servicio, key: 'servicio'},
                    {label: 'Área funcional', data: areaFuncional, key: 'areaFuncional'},
                    {label: 'Falla', data: nombreFalla, key: 'nombreFalla'},
                    {label: 'Descripción', data: descripcionSolicitud, key: 'descripcionSolicitud'},
               ];
          }

          const handleCancel = () => {
               onCloseModalDetalleTicketOrigen();
          };
        
          return (
               <Modal
                    visible={showModalDetalleTicketOrigen}
                    title='Detalle Ticket Origen'
                    onCancel={handleCancel}
                    footer={false}
                    width={'60%'}
               >
                    <Row justify='space-around'>
                         <Col span={24}>
                              <Spin spinning={fetchingListaTicketsOrigen}>
                                   <Descriptions 
                                        layout="horizontal" bordered= {true}
                                   >
                                        {data.map(item => 
                                             <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                        )}
                                   </Descriptions>
                              </Spin>
                         </Col>
                    </Row>
               </Modal>
          )
     }
}

const mapStateToProps = state => {
     return {
          showModalDetalleTicketOrigen: state.MecanismosDePagosReducer.showModalDetalleTicketOrigen,
          fetchingListaTicketsOrigen: state.MecanismosDePagosReducer.fetchingListaTicketsOrigen,
          ticketOrigenSeleccionado: state.MecanismosDePagosReducer.ticketOrigenSeleccionado,
     };
};

const mapDispatchToProps = dispatch => {
     return {
          onCloseModalDetalleTicketOrigen: () => {
               dispatch({ type: 'SHOW_MODAL_DETALLE_TICKET_ORIGEN', showModalDetalleTicketOrigen: false });
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetalleTicketOrigen);