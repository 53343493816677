import React, { Component } from 'react';
import { Row, Col, Modal, Select, Divider, Button, Spin, Tooltip, Typography, Table, Input, Steps, message, Alert } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, EditOutlined, DeleteOutlined, ClearOutlined, SaveOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

const { Option } = Select;
const { Search } = Input;
const { Text } = Typography;
const { Step } = Steps;

class ModalNuevaSolicitud extends Component {
    formRef = React.createRef();
    componentDidMount() {
        this.props.onGetServiciosAct();
    }
    state = {
        page: 1,
        selectedRowKeys: [],
        selectedRows: [],
        currentStep: 0,
        fileList: [],
        filteredData: [],
        busqueda: '',
        myInterval: null,
        fetchingInterval: false,
        servicio: undefined,
        partida: undefined,
        subFamilia: undefined,
        campo: undefined,
    }

    componentDidUpdate(prevProps) {
        if (prevProps.listaSeleccionados !== this.props.listaSeleccionados) {
            this.setState({ filteredData: this.props.listaSeleccionados });
        }
    }

    render() {
        const {
            fetchingListaServiciosActivosF,
            listaServiciosActivosF,

            showModalRequisicionMateriales,
            onCloseModal,
            fetchingRegistrarRequisicionMateriales,

            selectedRowKeys,

            onRegistrarRequisicion,
            listaProductosBusqueda,
            fetchingListaProductosBusqueda,
            listaProductosBusquedaCantidad,
            onGetInventario,
            onActualizarTabla,

            fetchingListaSubfamiliasServicio,
            listaSubfamiliasServicio,
            mostrarTablaSolicitudMateriales,
            limpiarFormulario, listaPartidasByServicio, fetchingListaPartidasByServicio, onLimpiarPartidasByServicio
        } = this.props; 

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        let userInfo = sessionStorage.getItem('userInfo');
        if(userInfo[0]) {
            userInfo = JSON.parse(userInfo);
        }

        const serviciosByIdUsuario = userInfo[0].serviciosByIdUsuario ? userInfo[0].serviciosByIdUsuario : [];

        if (limpiarFormulario && limpiarFormulario === 'registrarRequisicion') {
            this.setState({
                page: 1,
                selectedRowKeys: [],
                selectedRows: [],
                currentStep: 0,
                fileList: [],
                filteredData: [],
                busqueda: '',
                myInterval: null,
                fetchingInterval: false,
                servicio: undefined,
                partida: undefined,
                subFamilia: undefined,
                campo: undefined,
            });
            onActualizarTabla([], [], null, true);
            this.props.onLimpiarFormulario();
        }

        const handleCancel = () => {
            onCloseModal();
            this.setState({
                page: 1,
                selectedRowKeys: [],
                selectedRows: [],
                currentStep: 0,
                fileList: [],
                filteredData: [],
                busqueda: '',
                myInterval: null,
                fetchingInterval: false,
                servicio: undefined,
                partida: undefined,
                subFamilia: undefined,
                campo: undefined,
            });
            onActualizarTabla([], [], null, true);
        };

        const onBuscarProducto = (busqueda, name) => {
            console.log('busqueda (value): ', busqueda);
            console.log('name: ', name);
            this.setState({
                [name]: busqueda,
                page: 1,
            });
            if (name === 'campo') {
                onGetInventario(1, this.state.servicio.split(' - ')[1], parseInt(this.state.partida), busqueda, this.state.subFamilia)
            }
            else if (name === 'subFamilia') {
                onGetInventario(1, this.state.servicio.split(' - ')[1], parseInt(this.state.partida), this.state.campo, busqueda)
            }
            else if (name === 'servicio') {
                onLimpiarPartidasByServicio([], true);
                onActualizarTabla([], [], null, true);
                this.props.onSubfamiliasPorServicio(busqueda ? busqueda.split(' - ')[0] : null);
                onGetInventario(1, busqueda.split(' - ')[1], undefined, undefined, undefined);
                this.setState({
                    selectedRowKeys: [], filteredData: [], subFamilia: undefined, campo: undefined, partida: undefined,
                });
                this.props.onListaPartidasByServicio(parseInt(busqueda));
            }
            else if (name === 'partida') {
                onLimpiarPartidasByServicio(listaPartidasByServicio, false);
                onActualizarTabla([], [], null, true);
                onGetInventario(1, this.state.servicio.split(' - ')[1], parseInt(busqueda), undefined, undefined);
                this.setState({
                    selectedRowKeys: [], filteredData: [], subFamilia: undefined, campo: undefined,
                });
                this.props.onGetSubfamiliasByPartida(parseInt(busqueda));
            }
        }

        const onChangeTabla = (record, selected, selectedRows) => {
            console.log('record: ', record);
            console.log('selected: ', selected);

            let listaSelected = this.props.listaSeleccionados;
            const listaFinal = [];
            if (!listaSelected) {
                listaSelected = [];
            }

            if (selected.length) {
                for (let i in selected) {
                    if (selected[i]) {
                        selected[i].agregado = false;
                    }
                }
                for (let i in selected) {
                    if (listaSelected.length) {
                        for (let x in listaSelected) {
                            if (selected[i]) {
                                if (!selected[i].agregado) {
                                    if (listaSelected[x].rowKey === record[i]) {
                                        listaFinal.push(listaSelected[x]);
                                        selected[i].agregado = true;
                                    }
                                    else {
                                        listaFinal.push(selected[i]);
                                        selected[i].agregado = true;
                                    }
                                }
                            }
                            else {
                                if (listaSelected[x].rowKey === record[i]) {
                                    listaFinal.push(listaSelected[x]);
                                }
                            }
                        }
                    }
                    else {
                        listaFinal.push(selected[i]);
                    }
                }
            }
            // console.log('listaFinal');
            // console.log(listaFinal);

            let hasSelectedLocal = true

            if (record.length !== 0) {
                hasSelectedLocal = false;
            }

            this.setState({ selectedRowKeys: record, selectedRows: selected });
            onActualizarTabla(listaFinal, record, null, hasSelectedLocal);
        };

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });

            onGetInventario(pagination.current, this.state.servicio.split(' - ')[1], parseInt(this.state.partida), this.state.campo, this.state.subFamilia);
        };

        const onEditar = key => {
            console.log('key', key);
            this.props.onShowModalModificar(key);
            onCloseModal(this.props.page, this.props.busqueda, this.props.orden);
        };
        const onEliminar = key => {            
            let listaSelected = this.props.listaSeleccionados;
            onActualizarTabla([], [], null, false);
            for(let i in this.props.listaSeleccionados){
                if(this.props.listaSeleccionados[i].idProducto === key.idProducto){
                    listaSelected.splice(i, 1);
                }
            }
            let recordLocal = [];
            for(let i in listaSelected){
                recordLocal.push(listaSelected[i].idProducto);
            }

            let hasSelectedLocal = true
            if (recordLocal.length !== 0) {
                hasSelectedLocal = false;
            }
            this.setState({fetchingInterval: true, myInterval: setInterval(function () {onActualizarTabla(listaSelected, recordLocal, 'reiniciarInterval', hasSelectedLocal);}, 1) }) 
            //this.setState({ selectedRowKeys: recordLocal, filteredData: listaSelected });
        }
        if(this.props.pageMovimientos === 'reiniciarInterval'){
            clearInterval(this.state.myInterval); 
            onActualizarTabla(this.props.listaSeleccionados, selectedRowKeys, null, this.props.hasSelected);
            this.setState({ fetchingInterval: false })
        }

        const onLimpiar = () => {
            this.setState({ selectedRowKeys: [], filteredData: [], currentStep: 0, servicio: null });
            onActualizarTabla([], [], null, false);
        }

        const columns = [
            { title: 'Código de almacén', dataIndex: 'codigoAdicional', key: 'codigoAdicional', align: 'center', },
            { title: 'Nombre del producto', dataIndex: 'nombre', key: 'nombre', align: 'center', },
            { title: 'Clave del producto', dataIndex: 'clave', key: 'clave', align: 'center', },
            { title: 'SKU', dataIndex: 'sku', key: 'sku', align: 'center', },
            { title: 'Precio Unitario', dataIndex: 'precioTabla', key: 'precioTabla', align: 'right', },
            { title: 'Cantidad', dataIndex: 'cantidad', key: 'cantidad', align: 'center', },
        ];

        const columnsSelected = [{
            title: 'Código de almacén', dataIndex: 'codigoAdicional', key: 'codigoAdicional', align: 'center',
        }, {
            title: 'Nombre del producto', dataIndex: 'nombre', key: 'nombre', align: 'center',
        }, {
            title: 'Clave del producto', dataIndex: 'clave', key: 'clave', align: 'center',
        }, {
            title: 'SKU', dataIndex: 'sku', key: 'sku', align: 'center',
        },{ 
            title: 'Precio Unitario', dataIndex: 'precioTabla', key: 'precioTabla', align: 'right', 
        },{
            title: 'Cantidad   ', dataIndex: 'cantidad', key: 'cantidad', align: 'right',
        }, {
            title: ' Modificar ', key: 'editar', align: 'center',
            render: (text, record) => (
                <a className='editarIcono' onClick={() => onEditar(record)}><EditOutlined /></a>
            ),
        },{ 
            title: 'Eliminar', key: 'eliminar', align: 'center',
            render: (text, record) => (
                <a className='eliminarIcono' onClick={() => onEliminar(record)}><DeleteOutlined /></a>
            ),
        }];

        const rowSelection = {
            selectedRowKeys,
            onChange: onChangeTabla,
        };

        const onFinalizar = () => {
            for(let i in this.props.listaSeleccionados){
                if(!this.props.listaSeleccionados[i].cantidad){
                    message.warning('Favor de agregar cantidad al PIH "' + this.props.listaSeleccionados[i].nombre + ' - ' + this.props.listaSeleccionados[i].codigoAdicional + '"')
                    return;
                }
            }
            onRegistrarRequisicion(this.props.listaSeleccionados, this.props.page, this.props.busqueda, this.props.orden)
        }

        const onSiguiente = () => {
            this.setState({ currentStep: 1 })
        }
        console.log('mostrarTablaSolicitudMateriales', mostrarTablaSolicitudMateriales)
        
        return (
            <div>
                <Modal
                    visible={showModalRequisicionMateriales}
                    title='Nueva Solicitud de Materiales'
                    onCancel={handleCancel}
                    footer={false}
                    width={'80%'}
                >
                    <Spin spinning={fetchingRegistrarRequisicionMateriales}>
                        <Row justify='space-between' gutter={[8, 8]}>
                            <Col span={22}>
                                <Divider orientation='left'> <Text strong> Agregar Productos </Text></Divider>
                            </Col>
                            <Col span={1} style={{ paddingTop: '1em' }}>
                                <Tooltip title='Anterior'>
                                    <Button className="iconTer" shape="circle" onClick={() => { this.setState({ currentStep: 0 }); onGetInventario(this.state.page, this.state.servicio.split(' - ')[1], parseInt(this.state.partida), this.state.campo, this.state.subFamilia) }} icon={<ArrowLeftOutlined />} disabled={this.state.currentStep !== 0 ? false : true} />
                                </Tooltip>
                            </Col>
                            <Col span={1} style={{ paddingTop: '1em' }}>
                                {this.state.currentStep === 1 && this.state.filteredData.length > 0 ?(
                                <Tooltip title='Finalizar'>
                                    <Button className="iconTer" shape="circle" onClick={onFinalizar} icon={<SaveOutlined />} disabled={this.state.selectedRowKeys.length > 0 ? false : true} />
                                </Tooltip>
                                ):(
                                <Tooltip title='Siguiente'>
                                    <Button className="iconTer" shape="circle" onClick={onSiguiente} icon={<ArrowRightOutlined />} disabled={this.state.selectedRowKeys.length > 0 ? false : true} />
                                </Tooltip>
                                )}
                            </Col>
                            <Col span={24} style={{ padding: '1em' }}>
                                <Steps
                                    type='navigation'
                                    size='small'
                                    current={this.state.currentStep}
                                >
                                    <Step status={this.state.currentStep > 0 ? 'finish' : 'process'} title='Seleccionar Productos' />
                                    <Step status={this.state.currentStep > 1 ? 'finish' : 'process'} title='Detalle de Productos' />
                                </Steps>
                            </Col>
                            <Col span={24}>
                            {this.state.currentStep === 0 ? (
                                <Row justify='start'gutter={[8, 8]}>
                                    <Col span={listaPartidasByServicio.length ? 6 : 8}>
                                        {permisosUsuario.especialAlmacén ? (
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el servicio"
                                            loading={fetchingListaServiciosActivosF}
                                            showSearch
                                            onChange={(value) => onBuscarProducto(value, "servicio")}
                                            value={this.state.servicio}
                                        >
                                            {listaServiciosActivosF.map(option => <Option key={option.idServicio + ' - ' + option.nombre}>{option.nombre}</Option>)}
                                        </Select>
                                        ):(
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el servicio"
                                            showSearch
                                            onChange={(value) => onBuscarProducto(value, "servicio")}
                                            value={this.state.servicio}
                                        >
                                            {serviciosByIdUsuario.map(option => <Option key={option.idServicio + ' - ' + option.nombre}>{option.nombre}</Option>)}
                                        </Select>
                                        )}
                                    </Col>
                                    {this.state.servicio && listaPartidasByServicio.length ? (
                                    <Col span={6}>
                                        <Select 
                                            style={{width: '100%'}}
                                            placeholder="Selecciona una partida"
                                            loading={fetchingListaPartidasByServicio}
                                            showSearch
                                            onChange={(value) => onBuscarProducto(value, "partida")}
                                            value={this.state.partida}
                                        >
                                            {listaPartidasByServicio.map(option => <Option
                                                key={option.idPartida + ' - ' + option.nombre}>{option.nombre}</Option>)}
                                        </Select>
                                    </Col>
                                    ):false}
                                    {this.state.partida && listaPartidasByServicio.length ? (
                                    <Col span={listaPartidasByServicio.length ? 6 : 8}>
                                        <Select
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona la subfamilia por partida"
                                            loading={this.props.fetchingListaSubfamiliasByIdPartida}
                                            onChange={(value) => onBuscarProducto(value, "subFamilia")}
                                            value={this.state.subFamilia}
                                        >
                                            {this.props.listaSubfamiliasByIdPartida.map(option => <Option key={option.subFamilia}>{option.subFamilia}</Option>)}
                                        </Select>
                                    </Col>
                                    ):false}
                                    {this.state.servicio && !mostrarTablaSolicitudMateriales && !listaPartidasByServicio.length ? (
                                    <Col span={listaPartidasByServicio.length ? 6 : 8}>
                                        <Select
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona la subfamilia"
                                            loading={fetchingListaSubfamiliasServicio}
                                            onChange={(value) => onBuscarProducto(value, "subFamilia")}
                                            value={this.state.subFamilia}
                                        >
                                            {listaSubfamiliasServicio.map(option => <Option key={option.subFamilia}>{option.subFamilia}</Option>)}
                                        </Select>
                                    </Col>
                                    ):false}
                                    {this.state.servicio && !mostrarTablaSolicitudMateriales? (
                                    <Col span={listaPartidasByServicio.length ? 6 : 8}>
                                        <Search value={this.state.campo} allowClear className='buscarModulos' placeholder='Ingresa nombre/clave/código de almacén del producto' onSearch={(value) => onBuscarProducto(value, "campo")} />
                                    </Col>
                                    ):false}
                                    {this.state.servicio && !mostrarTablaSolicitudMateriales ? (
                                    <Col span={24}>
                                        <br/>
                                        <Table
                                            size="small"
                                            rowKey="rowKey"
                                            columns={columns}
                                            dataSource={listaProductosBusqueda}
                                            loading={fetchingListaProductosBusqueda}
                                            locale={{ emptyText: 'Sin datos de productos' }}
                                            onChange={handleTableChange}
                                            pagination={{ total: listaProductosBusquedaCantidad, simple: true, current: this.state.page }}
                                            showSorterTooltip={true}
                                            rowSelection={rowSelection}
                                            scroll={{"x": 1100, "y": 1000}}
                                        />
                                    </Col>
                                    ):(
                                    <Col span={24}>
                                        <Alert message="Favor de seleccionar un servicio" type="info" />
                                    </Col>
                                    )}
                                </Row>
                            ) : (
                                this.state.currentStep === 1 &&
                                <Row justify='end'>
                                    <Col span={24}>
                                        <Table
                                            size="small"
                                            rowKey="idProducto"
                                            columns={columnsSelected}
                                            dataSource={this.state.filteredData}
                                            locale={{ emptyText: 'Sin datos' }}
                                            pagination={false}
                                            scroll={{"x": 1000 | true, "y": 1000}}
                                            loading={this.state.fetchingInterval}
                                        />
                                    </Col>
                                    <Col span={1}>
                                        <br/>
                                        <Tooltip title='Limpiar lista de productos' placement="topRight">
                                            <Button disabled={!this.state.filteredData.length} className="iconSecundary" shape='circle' icon={<ClearOutlined /> } onClick={onLimpiar}/>
                                        </Tooltip>
                                    </Col>
                                </Row>
                            )}
                            </Col>
                        </Row>
                    </Spin>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,

        showModalRequisicionMateriales: state.AlmacenesReducer.showModalRequisicionMateriales,
        fetchingRegistrarRequisicionMateriales: state.AlmacenesReducer.fetchingRegistrarRequisicionMateriales,

        listaProductosBusqueda: state.AlmacenesReducer.listaProductosBusqueda,
        fetchingListaProductosBusqueda: state.AlmacenesReducer.fetchingListaProductosBusqueda,
        listaProductosBusquedaCantidad: state.AlmacenesReducer.listaProductosBusquedaCantidad,

        formularioNuevoFolio: state.AlmacenesReducer.formularioNuevoFolio,

        listaSeleccionados: state.AlmacenesReducer.listaSeleccionados,

        selectedRowKeys: state.AlmacenesReducer.selectedRowKeys,
        hasSelected: state.AlmacenesReducer.hasSelected,

        fetchingListaSubfamiliasServicio: state.CatalogosGeneralesReducer.fetchingListaSubfamiliasServicio,
        listaSubfamiliasServicio: state.CatalogosGeneralesReducer.listaSubfamiliasServicio,
        pageMovimientos: state.AlmacenesReducer.pageMovimientos,
        page: state.AlmacenesReducer.page,
        busqueda: state.AlmacenesReducer.busqueda,
        orden: state.AlmacenesReducer.orden,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,

        listaPartidasByServicio: state.EvaluacionDeLaGestionReducer.listaPartidasByServicio,
        fetchingListaPartidasByServicio: state.EvaluacionDeLaGestionReducer.fetchingListaPartidasByServicio,
        mostrarTablaSolicitudMateriales: state.EvaluacionDeLaGestionReducer.mostrarTablaSolicitudMateriales,
        
        listaSubfamiliasByIdPartida: state.CatalogosGeneralesReducer.listaSubfamiliasByIdPartida,
        fetchingListaSubfamiliasByIdPartida: state.CatalogosGeneralesReducer.fetchingListaSubfamiliasByIdPartida,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onCloseModal: (page, busqueda, orden) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_FOLIO_REQUISICION_MATERIALES", showModalRequisicionMateriales: false, page, busqueda, orden })
        },
        onGetServiciosAct: () => {
            dispatch({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST' });
        },
        onSubfamiliasPorServicio: (idServicio) => {
            dispatch({ type: 'LISTA_SUBFAMILIAS_POR_SERVICIO_REQUEST', idServicio });
        },
        onGetInventario: (page, servicio, partida, campo, subFamilia) => {
            dispatch({ type: "LISTA_INVENTARIO_REQUISICION_MATERIALES_REQUEST", page, servicio, partida, campo, subFamilia })
        },
        onRegistrarRequisicion: (listaSeleccionados, page, busqueda, orden) => {
            dispatch({ type: "REGISTRAR_REQUISICION_MATERIALES_REQUEST", listaSeleccionados, page, busqueda, orden });
        },
        onActualizarTabla: (listaSeleccionados, selectedRowKeys, pageMovimientos, hasSelected) => {
            dispatch({ type: 'ACTUALIZAR_TABLA_REQUISICION_MATERIALES', listaSeleccionados, selectedRowKeys, pageMovimientos, hasSelected });
        },
        onShowModalModificar: (registroProductoSeleccionado) => {
            dispatch({ type: "SHOW_MODAL_MODIFICAR_REGISTRO_REQUISICION", showModalModificarRequisicionMateriales: true, registroProductoSeleccionado })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        },
        onListaPartidasByServicio: (idServicio) => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_REQUEST', idServicio: idServicio})
        },
        onLimpiarPartidasByServicio: (listaPartidasByServicio, mostrarTablaSolicitudMateriales) => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_SERVICIO_SUCCESS', listaPartidasByServicio, mostrarTablaSolicitudMateriales })
        },
        onGetSubfamiliasByPartida: (idPartida) => {
            dispatch({ type: 'LISTA_SUBFAMILIAS_BY_PARTIDA_REQUEST', idPartida })
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevaSolicitud);