import React, { Component } from 'react';
import { Form, Button, Row, Col, Spin, Modal, Result, Tooltip, Select, Table, Input, Alert, Radio, Divider } from 'antd';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import { FileSearchOutlined, SearchOutlined } from '@ant-design/icons';
import ModalDetalle from './ModalDetalleTicketOrigen';

const { Option } = Select;
const { Search } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalBusquedaTicketOrigen extends Component {
     formRef = React.createRef();
     componentDidMount(){
          this.props.onGetAreasFuncionales();
          this.props.onGetServiciosActivos();
     }
     state={
          codigoInternoBtn: false, idFallaState: '', idAreaFuncionalState: '', idServicioState: '', noTicketStatus: false, folioState: '', buttonDisabled: true, ticketSeleccionado: []
     }

     render() {
          let fecha = new Date();
          let anno = fecha.getFullYear();
          let annoSt = anno.toLocaleString('en-US');
          const addOn = 'SS' + annoSt.substr(-2) + '-';

          const { 
               showModalBusquedaTicketOrigen,
               onCloseModalBusquedaTicketOrigen, listaAreasFuncionalesActivas, fetchingListaAreasFuncionalesActivas, listaServiciosActivosF, fetchingListaServiciosActivosF, onGetTicketOrigen, onShowModalDetalleTicketOrigen, listaTicketsOrigen, fetchingListaTicketsOrigen, onCleanTicketsOrigen, idSolicitudServicioReiterada, onGetDocumentosRelacionados, onGetFallasByServicio, fetchingListaFallasByServicio, listaFallasByServicio, listaFallasPorServicioLimpiar
          } = this.props;
        
          const onFinish = formulario => {
               console.log('Success:', formulario);
               onGetTicketOrigen(null, idSolicitudServicioReiterada, formulario.areaFuncional.split(' - ')[0], formulario.servicio.split(' - ')[0], formulario.falla.split(' - ')[0])
               onCleanTicketsOrigen(); 
               //this.formRef.current.resetFields();
               this.formRef.current.setFieldsValue({'noTicket': null});
          }
            
          const handleCancel = () => {
               onCloseModalBusquedaTicketOrigen(); listaFallasPorServicioLimpiar();
               onCleanTicketsOrigen();
               if(idSolicitudServicioReiterada){this.formRef.current.resetFields(); this.formRef.current.setFieldsValue({'noTicket': null}); }
               this.setState({ codigoInternoBtn: false, idFallaState: '', idAreaFuncionalState: '', idServicioState: '', noTicketStatus: false, folioState: '', buttonDisabled: true });
          };

          const fallaSelected = (value) => {
               if(value){
                    let idFallaLocal = parseInt(value.split(' -')[0]);
                    this.setState({ idFallaState: idFallaLocal, page: 1 });
               } else {
                    this.setState({ idFallaState: '', page: 1 });
               }
          };

          const areaFuncionalSelected = (value) => {
               if(value){
                    let idAFLocal = parseInt(value.split(' -')[0]);
                    this.setState({ idAreaFuncionalState: idAFLocal, page: 1 });
               } else {
                    this.setState({ idAreaFuncionalState: '', page: 1 });
               }
          };

          const servicioSelected = (value) => {
               if(value){
                    let idServicioLocal = parseInt(value.split(' -')[0]);
                    this.setState({ idServicioState: idServicioLocal, page: 1 });
                    onGetFallasByServicio(idServicioLocal);
               } else {
                    this.setState({ idServicioState: '', page: 1 });

                    listaFallasPorServicioLimpiar();
                    this.formRef.current.setFieldsValue({'falla': undefined});
               }

          };

          const onBusquedaTicket = (value) => {
               if(value){
                    if(this.formRef.current.getFieldValue('noTicket')){
                         this.setState({ folioState: value });
                    } else { this.setState({ folioState: '' }); }

                    onGetTicketOrigen(addOn + value, idSolicitudServicioReiterada, this.state.idAreaFuncionalState, this.state.idServicioState, this.state.idFallaState);

               } else { this.setState({ folioState: '' }); }
          };

          const onDetalle = (record) => {
               console.log('record', record)
               onShowModalDetalleTicketOrigen(record);
          }

          const columnas = [
               { title: 'No. Ticket', dataIndex: 'folio', key: 'folio', align: 'center', width: '8%' },
               { title: 'Servicio', dataIndex: 'servicio', key: 'servicio', align: 'center', width: '42%' },
               { title: 'Área funcional', dataIndex: 'areaFuncional', key: 'areaFuncional', align: 'center', width: '20%' },
               { title: 'Falla', dataIndex: 'nombreFalla', key: 'nombreFalla', align: 'center', width: '25%' },
               { title: '', dataIndex: '', key: '', align: 'center', width: '5%',
               render: (record) => (
                    <FileSearchOutlined className='editarIcono' onClick={() => onDetalle(record)} />
               )},
          ];

          const rowSelection = {
               onChange: (selectedRowKeys, selectedRows) => {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                    if(selectedRowKeys){
                         this.setState({ buttonDisabled: false, ticketSeleccionado: selectedRows[0] });
                    } else {
                         this.setState({ buttonDisabled: true });
                    }
               }
          };
          
          const tipoBusqueda = (value) => {
               console.log('value', value)
               if(value){
                    if(value.target.value === 'a'){
                         this.setState({ noTicketStatus: false, idFallaState: '', idAreaFuncionalState: '', idServicioState: '', buttonDisabled: true, });
                         this.formRef.current.resetFields();
                         onCleanTicketsOrigen(); listaFallasPorServicioLimpiar();
                    } else if(value.target.value === 'b'){
                         this.setState({ noTicketStatus: true, folioState: '', buttonDisabled: true });
                         this.formRef.current.resetFields();
                         onCleanTicketsOrigen(); listaFallasPorServicioLimpiar();
                    }
               }
          }

          const seleccionarTicket = () => { 
               onCloseModalBusquedaTicketOrigen(this.state.ticketSeleccionado, this.state.ticketSeleccionado.idSolicitudServicio);
               onGetDocumentosRelacionados(this.state.ticketSeleccionado.idSolicitudServicio, this.state.ticketSeleccionado);
               this.formRef.current.resetFields();
               listaFallasPorServicioLimpiar(); onCleanTicketsOrigen();
               this.setState({ buttonDisabled: true });
          }

          return (
               <Modal
                    visible={showModalBusquedaTicketOrigen}
                    title='Búsqueda de Ticket Origen'
                    onCancel={handleCancel}
                    footer={false}
                    width='70%'
               >
                    {idSolicitudServicioReiterada ? (
                    <Spin spinning={false}>
                         <Form {...layout}
                         name="formulario"
                         onFinish={onFinish}
                         ref={this.formRef}
                         >
                              <Row justify='end'>
                                   <Col span={3}>
                                        <Form.Item>
                                             <div style={{ textAlign:'rigth' }} >Búsqueda por: </div>
                                        </Form.Item>
                                   </Col>
                                   <Col span={5}>
                                        <Form.Item name='tipoBusqueda'>
                                             <Radio.Group size='small' onChange={tipoBusqueda}>
                                                  <Radio.Button value="a">No. de Ticket</Radio.Button>
                                                  <Radio.Button value="b">Parámetros</Radio.Button>
                                             </Radio.Group>
                                        </Form.Item>
                                   </Col>

                                   <Divider style={{ margin: '10px' }}/>
                              </Row>

                              <Row justify='space-around'>
                                   <Col span={5}>
                                        <Form.Item name='noTicket' rules={[{ required: !this.state.noTicketStatus, message: 'Favor de llenar el campo', }]}>
                                             <Search addonBefore={addOn} placeholder="No. Ticket" allowClear onSearch={onBusquedaTicket} style={{ width: '100%' }} enterButton={<SearchOutlined />} disabled={this.state.noTicketStatus} />
                                        </Form.Item>
                                   </Col>

                                   <Col span={5}>
                                        <Form.Item name='areaFuncional' rules={[{ required: this.state.noTicketStatus, message: 'Favor de llenar el campo', }]}>
                                             <Select 
                                                  allowClear showSearch style={{ width: '100%' }} placeholder="Seleccione la área funcional" loading={fetchingListaAreasFuncionalesActivas} onChange={areaFuncionalSelected}
                                                  disabled={!this.state.noTicketStatus}
                                             >
                                                  {listaAreasFuncionalesActivas.map(option => 
                                                  <Option key={option.idAreaFuncional + ' - ' +  option.claveArea + ': ' + option.areaFuncional}>
                                                       <Tooltip title={option.idAreaFuncional + ' - ' +  option.claveArea + ': ' + option.areaFuncional}> {option.idAreaFuncional + ' - ' +  option.claveArea + ': ' + option.areaFuncional} </Tooltip>
                                                  </Option>)}
                                             </Select>
                                        </Form.Item>
                                   </Col>

                                   <Col span={5}>
                                        <Form.Item name='servicio' rules={[{ required: this.state.noTicketStatus, message: 'Favor de llenar el campo', }]}>
                                             <Select
                                                  allowClear showSearch style={{ width: '100%' }} placeholder="Selecciona el servicio" loading={fetchingListaServiciosActivosF} onChange={servicioSelected}
                                                  disabled={!this.state.noTicketStatus}
                                             >
                                                  {listaServiciosActivosF.map(option => 
                                                       <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                            <Tooltip placement="topLeft" title={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                                 { option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre }
                                                            </Tooltip>
                                                       </Option>
                                                  )}
                                             </Select>
                                        </Form.Item>
                                   </Col>

                                   <Col span={5}>
                                        <Form.Item name='falla' rules={[{ required: this.state.noTicketStatus, message: 'Favor de llenar el campo', }]}>
                                             { listaFallasByServicio ? (
                                             <Select
                                                  style={{ width: '100%', textAlign: 'left' }} placeholder="Selecciona el tipo de falla" loading={fetchingListaFallasByServicio} onChange={fallaSelected} showSearch allowClear disabled={!listaFallasByServicio[0]}
                                             >
                                                  {listaFallasByServicio.map(option => 
                                                  <Option key={option.idFalla + ' - ' + option.nombre }>
                                                       <Tooltip placement="topLeft" title={option.idFalla + ' - ' + option.nombre }>
                                                            {option.idFalla + ' - ' + option.nombre }
                                                       </Tooltip>
                                                  </Option>)}
                                             </Select>
                                             ):(
                                                  <Select
                                                  style={{ width: '100%', textAlign: 'left' }} placeholder="Selecciona el tipo de falla" showSearch disabled={true} />
                                             ) }
                                        </Form.Item>
                                   </Col>

                                   <Col span={1}>
                                        <Tooltip title='Búsqueda por parámetros' >
                                             <Button className="btnFiltrar" shape="circle" icon={ <SearchOutlined />} disabled={!this.state.noTicketStatus} htmlType="submit" />
                                        </Tooltip>
                                   </Col>

                                   { !listaTicketsOrigen[0] ? (<>
                                        { this.state.noTicketStatus ? (<>
                                             <Col span={24}>
                                                  <Spin spinning={fetchingListaTicketsOrigen}>
                                                  <Alert
                                                       message="Parámetros de búsqueda"
                                                       description="Ingresa el área funcional, servicio y tipo de falla para continuar."
                                                       type="info"
                                                       showIcon
                                                       style={{ borderRadius: '2em' }}
                                                  />
                                                  </Spin>
                                             </Col>

                                             <Divider />
                                        </>):(<>
                                             <Col span={24}>
                                                  <Spin spinning={fetchingListaTicketsOrigen}>
                                                  <Alert
                                                       message="Número de Ticket"
                                                       description="Ingresa el número de ticket a buscar para continuar."
                                                       type="info"
                                                       showIcon
                                                       style={{ borderRadius: '2em' }}
                                                  />
                                                  </Spin>
                                             </Col>

                                             <Divider />
                                        </>)}
                                   </>):(<>
                                        <Col span={24} className='entrenceElement'>
                                             <Table size='small' columns={columnas}
                                                  rowSelection={{
                                                       type: 'radio',
                                                       ...rowSelection,
                                                  }}
                                                  size='small'
                                                  rowKey='idSolicitudServicio'
                                                  dataSource={listaTicketsOrigen}
                                                  loading={fetchingListaTicketsOrigen}
                                                  // onChange={handleTableChange}
                                                  locale={{ emptyText: 'Sin datos' }}
                                                  pagination={false}
                                             />
                                             <br />
                                        </Col>
                                   </>)}

                                   <Button className="btnFiltrar" style={{ width: '50%' }} disabled={this.state.buttonDisabled} onClick={seleccionarTicket} >
                                        Seleccionar
                                   </Button>
                              </Row>
                              <ModalDetalle />
                         </Form>
                    </Spin>
                    ):
                         <Result
                         status="warning"
                         title="Ticket reiterado no seleccionado"
                         subTitle="Antes de proceder con la selección del ticket origen, es necesario seleccionar el ticket reiterado."
                         />
                    }
               </Modal>
          );
     }
}

const mapStateToProps = (state) => {
	return {
          showModalBusquedaTicketOrigen: state.MecanismosDePagosReducer.showModalBusquedaTicketOrigen,
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          listaAreasFuncionalesActivas: state.AlmacenesReducer.listaAreasFuncionalesActivas,
          fetchingListaAreasFuncionalesActivas: state.AlmacenesReducer.fetchingListaAreasFuncionalesActivas,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,

          listaTicketsOrigen: state.MecanismosDePagosReducer.listaTicketsOrigen,
          fetchingListaTicketsOrigen: state.MecanismosDePagosReducer.fetchingListaTicketsOrigen,

          idSolicitudServicioReiterada: state.MecanismosDePagosReducer.idSolicitudServicioReiterada,
          fetchingListaFallasByServicio: state.SolicitudDeServiciosReducer.fetchingListaFallasByServicio,
          listaFallasByServicio: state.SolicitudDeServiciosReducer.listaFallasByServicio,
	};
};

const mapDispatchToProps = dispatch => {
	return {
          onCloseModalBusquedaTicketOrigen: (ticketOrigenSeleccionadoMODAL, idSolicitudServicioOrigen) => {
               dispatch({ type: 'SHOW_MODAL_BUSQUEDA_TICKET_ORIGEN', showModalBusquedaTicketOrigen: false, ticketOrigenSeleccionadoMODAL, idSolicitudServicioOrigen })
          },
          onGetAreasFuncionales: () => {
               dispatch({ type: "LISTA_AREA_FUNCIONAL_ACTIVA_REQUEST", });
          },
          onGetServiciosActivos: () => {
               dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
          },
          onGetTicketOrigen: (folio, idSolicitudServicioReiterada, idAreaFuncional, idServicio, idFalla) => {
               dispatch({ type: 'LISTA_TICKETS_ORIGEN_REQUEST', folio, idSolicitudServicioReiterada, idAreaFuncional, idServicio, idFalla,  });
          },
          onCleanTicketsOrigen: () => {
               dispatch({ type: 'LISTA_TICKETS_ORIGEN_SUCCESS', listaTicketsOrigen: [] });
          },
          onShowModalDetalleTicketOrigen: (ticketOrigenSeleccionado) => {
               dispatch({ type: 'SHOW_MODAL_DETALLE_TICKET_ORIGEN', showModalDetalleTicketOrigen: true, ticketOrigenSeleccionado });
          },
          onGetDocumentosRelacionados: (idSolicitudServicioOrigen, ticketOrigenSeleccionado) => {
               dispatch({ type: 'GET_DOCUMENTO_RELACIONADO_REQUEST',  idSolicitudServicioOrigen, ticketOrigenSeleccionado })
          },
          onGetFallasByServicio: (idServicio) => {
               dispatch({ type: 'LISTA_FALLAS_ACTIVAS_BY_SERVICIO_REQUEST', idServicio });
          },
          listaFallasPorServicioLimpiar: () => {
               dispatch({ type: 'LISTA_FALLAS_ACTIVAS_BY_SERVICIO_SUCCESS', listaFallasByServicio: [] });
          },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalBusquedaTicketOrigen);