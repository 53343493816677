import * as configuracionYSeguridadActions from '../actions/ConfiguracionYSeguridadActions';

const initialState = {
    page: 0,
    limpiarFormulario: null,
    busqueda: null,
    orden: null,

    showSuccessMsg: false,
    showWarningMsg: false,
    showErrorMsg: false,
    typeMessage: null,
    textMessage: null,

    vistaDashboard: sessionStorage.getItem('vistaDashboard'),
    catalogoSeleccionado: null,

    fetchingListaUsuariosParam: false,
    listaUsuariosParam: [],

    fetchingListaUsuarios: false,
    listaUsuarios: [],
    cantidadListaUsuarios: null,

    fetchingEstadoUsuario: false,
    fetchingEditarUsuario: false,
    fetchingEditarContraseña: false,
    
    usuario: null,
    modalEditarUsuario: false,

    fetchingListaDominiosActivos: false,
    listaDominiosActivos: [],

    fetchingListaRoles: false,
    listaRoles: [],
    listaRolesBusquedaCantidad: null,
    listaRolesBusqueda: [],

    modalNuevoUsuario: false,
    fetchingNuevoUsuario: false,

    fetchingVerificarUsuario: false,
    verificarCorreo: false,
    listaProveedoresDominio: [],
    username: null,
    idDominio: null,

    fetchingListaModulos: false,
    listaModulos: [],
    listaModulosSelect: [],

    fetchingEstadoModulo: false,
    showModalNuevoModulo: false,
    fetchingNuevoModulo: false,

    listaModulosByIdUsuario: [],
    fetchingListaModulosByIdUsuario: false,
    
    //Reducers de Seguridad
    fetchingListaPermisos: false,
    listaPermisos: [],

    fetchingListaPermisosByModulo: false,
    listaPermisosByModulo: [],

    fetchingListaPermisosBusqueda: false,
    listaPermisosBusqueda: [],
    listaPermisosBusquedaCantidad: null,

    fetchingListaLogs: false,
    listaLogs: [],
    listaLogsCantidad: null,
    listaLogsInfinity: [],
    hasMore: true,

    //Reducers de Roles
    showModalNuevoRol: false,
    showModalEditarRol: false,
    rolSeleccionado: null,

    fetchingNuevoRol: false,
    fetchingEditarRol: false,
    valueSearch: '',

    // Reducers de settings
    fetchingListaSettings: false,
    listaSettings : [],

    showModalEditarSettings: false,
    settingSeleccionado: null,

    fetchingEditarSettings: false,

    //Alerta
    fetchingListaAlertaBusqueda: false,
    listaAlertaBusqueda: [],
    listaAlertaBusquedaCantidad: 0,

    fetchingListaDiasAlerta: false,
    listaDiasAlerta: [],

    fetchingListaEventoPorModulo: false,
    listaEventoPorModulo: null,

    showModalDetalleAlerta: false, 
    alertaSeleccionado: null,

    showModalNuevaAlerta: false,
    fetchingNuevaAlerta: false,

    showModalEditarAlerta: false,
    fetchingEditarAlerta: false,
    
    periodicidad: null,
    rangoFechas: null,

    tiempoRecarga: '05:00',
    
    showNotificaciones: false,

    //Tipos de Bitacora
    fetchingListaTiposBitacoraBusqueda: false,
    listaTiposBitacoraBusqueda: [],
    listaTiposBitacoraBusquedaCantidad: 0,

    fetchingListaTiposBitacoraActivos: false,
    listaTiposBitacoraActivos: [],

    showModalNuevoTipoBitacora: false,
    fetchingNuevoTipoBitacora: false,

    showModalEditarTipoBitacora: false,
    fetchingEditarTipoBitacora: false,
    tipoBitacoraSeleccionada: null,

    fetchingListaTipoBitacoraByIdModulo: false,
    listaTipoBitacoraByIdModulo: [],

    //Catálogo de Bitacora
    fetchingListaBitacorasBusqueda: false,
    listaBitacorasBusqueda: [],
    listaBitacorasBusquedaCantidad: 0,

    showModalNuevaBitacora: false,
    fetchingNuevaBitacora: false,

    showModalEditarBitacora: false,
    fetchingEditarBitacora: false,
    bitacoraSeleccionada: null,

    showModalVerBitacora: false,

    formularioSearch: {
        dominio: '',
        servidor: '',
    },
    
    formularioSearchAlertas:{
        servicio: '',
        modulo: '',
        evento: ''
    },

    idModulo: null,
    listaModulosBitacora: [],
    fetchingListaModulosBitacora: false,
    listaModulosBitacoraCantidad: null,

    showModalNuevoModuloBitacora: false,
    fetchingNuevoModuloBitacora: false,

    moduloBitacoraSeleccionada: null,
    showModalEditarModuloBitacora: false,
    fetchingEditarModuloBitacora: false,

    listaModulosBitacoraByIdModulo: [],
    fetchingListaModulosBitacoraByIdModulo: false,

    // Reporte Dinamico
    listaReporteDinamicoBitacora: [],
    listaReporteDinamicoCantidadBitacora: null,
    fetchingListaReporteDinamicoBitacora: false,

    fetchingReporteDinamicoAgrupadoBitacora: false,
    fetchingReporteDinamicoPDF: false,
    fetchingReporteDinamicoEXCEL: false,
    formularioReporte: [],
    listaReporteDinamicoAgrupadoBitacora: [],
    listaReporteDinamicoAgrupadoCantidadBitacora: null,

    fetchingListaGerenciasByIdUsuario: false,
    listaGerenciasByIdUsuario: [],

    fetchingReporteDinamicoAgrupadoPDF: false,
    fetchingReporteDinamicoAgrupadoEXCEL: false,

    listaPartidasByIdsServicios: [],
    fetchingListaPartidasByIdsServicios: false,

    fetchingGetAllRegistros: false,
    allRegistros: [],
    allRegistrosCantidad: null,
    fetchingGetRegistrosByToken: false,
    registrosByToken: [],
    registrosByTokenCantidad: null,

    fetchingEstadoRegistros: false,
    fetchingCreateRegistro: false,
    fetchingEditRegistro: false,

    showModalEditarRegistro: false,
    showModalCrearRegistro: false,
    data: null,
    registroReporteSeleccionado: [],
    personal: false,
    fetchingEditRegistros: false,

    listaPartidasActivas: [],
    fetchingListaPartidasActivas: false,

     //Rangos de Validación
    listaBusquedaRangosValidacion: [],
    listaBusquedaRangosValidacionCantidad: null,
    fetchingListaBusquedaRangosValidacion: false,

    listaActivosRangosValidacion: [],
    fetchingListaActivosRangosValidacion: false,

    fetchingNuevoRangoValidacion: false,

    fetchingEstadoRangoValidacion: false,

    fetchingEditarRangosValidacion: false,
    rangoValidacionSeleccionado: null,

    showModalEditarRangoValidacion: false,
    showModalNuevoRangoValidacion: false,

    fetchingExcelRangosValidacion: false,

     //Responsables de Servicio
    listaBusquedaResponsableServicio: [],
    listaBusquedaResponsableServicioCantidad: null,
    fetchingListaBusquedaResponsableServicio: false,

    listaResponsablesActivos: [],
    fetchingResponsablesActivos: false,

    fetchingEstadoResponsable: false,
    fetchingNuevoResponsable: false,
    fetchingEditarResponsable: false,
    responsableSeleccionado: null,

    showModalEditarResponsable: false,
    showModalNuevoResponsable: false,
    
    fetchingListaUsuariosByIdsServicios: false,
    listaUsuariosByIdsServicios: [],

    listaUsuariosByIdsServiciosTipoRS: [],
    fetchingListaUsuariosByIdsServiciosTipoRS: false,
    listaUsuariosByIdsServiciosTipoAT: [],
    fetchingListaUsuariosByIdsServiciosTipoAT: false,
    listaUsuariosByIdsServiciosTipoPYRS: [],
    fetchingListaUsuariosByIdsServiciosTipoPYRS: false,

    showModalHistoricoResponsable: false,
    listaBusquedaHistoricoResponsable: [],
    listaBusquedaHistoricoResponsableCantidad: null,
    fetchingListaBusquedaHistoricoResponsable: false,

    //Notificaciones
    fetchingListaNotificaciones: false,
    listaNotificaciones: [],
    listaNotificacionesNoLeidas: [],
    cantidadNotificacionesNoLeidas: null,
    cantidadNotificaciones: null,
    fetchingListaNotificacionesNoLeidas: false,

    fetchingActualizarNotificaciones: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case configuracionYSeguridadActions.SHOW_SUCCESS_MSG:
            return { ...state, showSuccessMsg: action.showSuccessMsg, typeMessage: "success", textMessage: action.textMessage };
        case configuracionYSeguridadActions.SHOW_WARNING_MSG:
            return { ...state, showWarningMsg: action.showWarningMsg, typeMessage: "warning", textMessage: action.textMessage };
        case configuracionYSeguridadActions.SHOW_ERROR_MSG:
            return { ...state, showErrorMsg: action.showErrorMsg, typeMessage: "error", textMessage: action.textMessage };
        case configuracionYSeguridadActions.LIMPIAR_FORMULARIO:
            return { ...state, limpiarFormulario: action.limpiarFormulario};

        case configuracionYSeguridadActions.CAMBIAR_VISTA_DASHBOARD:
            return { ...state, vistaDashboard: action.vistaDashboard };
        case configuracionYSeguridadActions.CAMBIAR_CATALOGO_SELECCIONADO:
            return { ...state, catalogoSeleccionado: action.catalogoSeleccionado };

        //CATÁLOGO DE USUARIOS
        case configuracionYSeguridadActions.LISTA_USUARIOS_PARAM_REQUEST:
            return { ...state, fetchingListaUsuariosParam: true };
        case configuracionYSeguridadActions.LISTA_USUARIOS_PARAM_SUCCESS:
            return { ...state, fetchingListaUsuariosParam: false, listaUsuariosParam: action.listaUsuariosParam };
        case configuracionYSeguridadActions.LISTA_USUARIOS_PARAM_FAILURE:
            return { ...state, fetchingListaUsuariosParam: false };

        case configuracionYSeguridadActions.LISTA_USUARIOS_SIN_FILTRO_REQUEST:
            return { ...state, fetchingListaUsuarios: true };
        case configuracionYSeguridadActions.LISTA_USUARIOS_SIN_FILTRO_SUCCESS:
            return { ...state, fetchingListaUsuarios: false, listaUsuarios: action.listaUsuarios, cantidadListaUsuarios: action.cantidadListaUsuarios };
        case configuracionYSeguridadActions.LISTA_USUARIOS_SIN_FILTRO_FAILURE:
            return { ...state, fetchingListaUsuarios: false };

        case configuracionYSeguridadActions.CAMBIAR_ESTADO_USUARIO_REQUEST:
            return { ...state, fetchingEstadoUsuario: true };
        case configuracionYSeguridadActions.CAMBIAR_ESTADO_USUARIO_SUCCESS:
            return { ...state, fetchingEstadoUsuario: false };
        case configuracionYSeguridadActions.CAMBIAR_ESTADO_USUARIO_FAILURE:
            return { ...state, fetchingEstadoUsuario: false };

        case configuracionYSeguridadActions.LISTA_DOMINIOS_ACTIVOS_REQUEST:
            return { ...state, fetchingListaDominiosActivos: true };
        case configuracionYSeguridadActions.LISTA_DOMINIOS_ACTIVOS_SUCCESS:
            return { ...state, fetchingListaDominiosActivos: false, listaDominiosActivos: action.listaDominiosActivos };
        case configuracionYSeguridadActions.LISTA_DOMINIOS_ACTIVOS_FAILURE:
            return { ...state, fetchingListaDominiosActivos: false };

        case configuracionYSeguridadActions.LISTA_ROLES_REQUEST:
            return { ...state, fetchingListaRoles: true };
        case configuracionYSeguridadActions.LISTA_ROLES_SUCCESS:
            return { ...state, fetchingListaRoles: false, listaRoles: action.listaRoles };
        case configuracionYSeguridadActions.LISTA_ROLES_FAILURE:
            return { ...state, fetchingListaRoles: false };

        case configuracionYSeguridadActions.LISTA_ROLES_BUSQUEDA_REQUEST:
            return { ...state, fetchingListaRoles: true };
        case configuracionYSeguridadActions.LISTA_ROLES_BUSQUEDA_SUCCESS:
            return { ...state, fetchingListaRoles: false, listaRolesBusqueda: action.listaRolesBusqueda, listaRolesBusquedaCantidad: action.listaRolesBusquedaCantidad };
        case configuracionYSeguridadActions.LISTA_ROLES_BUSQUEDA_FAILURE:
            return { ...state, fetchingListaRoles: false };

        case configuracionYSeguridadActions.MODAL_EDITAR_USUARIO:
            return { ...state, modalEditarUsuario: action.modalEditarUsuario, usuario: action.usuario, page: action.page };

        case configuracionYSeguridadActions.EDITAR_USUARIO_REQUEST:
            return { ...state, fetchingEditarUsuario: true };
        case configuracionYSeguridadActions.EDITAR_USUARIO_SUCCESS:
            return { ...state, fetchingEditarUsuario: false };
        case configuracionYSeguridadActions.EDITAR_USUARIO_FAILURE:
            return { ...state, fetchingEditarUsuario: false };

        case configuracionYSeguridadActions.MODAL_NUEVO_USUARIO:
            return { ...state, modalNuevoUsuario: action.modalNuevoUsuario, verificarCorreo:false, username: null, serviciosPorProveedor: [], page: action.page };

        case configuracionYSeguridadActions.VERIFICAR_USUARIO_REQUEST:
            return { ...state, fetchingVerificarUsuario: true, verificarCorreo: false };
        case configuracionYSeguridadActions.VERIFICAR_USUARIO_SUCCESS:
            return { ...state, fetchingVerificarUsuario: false, verificarCorreo: true, username: action.username, idDominio: action.idDominio };
        case configuracionYSeguridadActions.VERIFICAR_USUARIO_FAILURE:
            return { ...state, fetchingVerificarUsuario: false, verificarCorreo: false};

        case configuracionYSeguridadActions.LISTA_PROVEEDOR_DOMINIO_SUCCESS:
            return { ...state, listaProveedoresDominio: action.listaProveedoresDominio };
        case configuracionYSeguridadActions.LISTA_PROVEEDOR_DOMINIO_FAILURE:
            return { ...state, listaProveedoresDominio: [] };

        case configuracionYSeguridadActions.RECUPERAR_PIN_REQUEST:
            return { ...state, fetchingEstadoUsuario: true };
        case configuracionYSeguridadActions.RECUPERAR_PIN_SUCCESS:
            return { ...state, fetchingEstadoUsuario: false };
        case configuracionYSeguridadActions.RECUPERAR_PIN_FAILURE:
            return { ...state, fetchingEstadoUsuario: false };

        case configuracionYSeguridadActions.EDITAR_CONTRASEÑA_REQUEST:
            return { ...state, fetchingEditarContraseña: true };
        case configuracionYSeguridadActions.EDITAR_CONTRASEÑA_SUCCESS:
            return { ...state, fetchingEditarContraseña: false };
        case configuracionYSeguridadActions.EDITAR_CONTRASEÑA_FAILURE:
            return { ...state, fetchingEditarContraseña: false };

        case configuracionYSeguridadActions.NUEVO_USUARIO_REQUEST:
            return { ...state, fetchingNuevoUsuario: true };
        case configuracionYSeguridadActions.NUEVO_USUARIO_SUCCESS:
            return { ...state, fetchingNuevoUsuario: false };
        case configuracionYSeguridadActions.NUEVO_USUARIO_FAILURE:
            return { ...state, fetchingNuevoUsuario: false };

        case configuracionYSeguridadActions.FILTRO_DOMINIO_REQUEST:
            return { ...state, fetchingListaUsuarios: true };
        case configuracionYSeguridadActions.FILTRO_DOMINIO_SUCCESS:
            return { ...state, fetchingListaUsuarios: false, listaUsuarios: action.listaUsuarios, cantidadListaUsuarios: action.cantidadListaUsuarios };
        case configuracionYSeguridadActions.FILTRO_DOMINIO_FAILURE:
            return { ...state, fetchingListaUsuarios: false };

        case configuracionYSeguridadActions.FILTRO_SERVICIO_REQUEST:
            return { ...state, fetchingListaUsuarios: true };
        case configuracionYSeguridadActions.FILTRO_SERVICIO_SUCCESS:
            return { ...state, fetchingListaUsuarios: false, listaUsuarios: action.listaUsuarios, cantidadListaUsuarios: action.cantidadListaUsuarios };
        case configuracionYSeguridadActions.FILTRO_SERVICIO_FAILURE:
            return { ...state, fetchingListaUsuarios: false };

        case configuracionYSeguridadActions.FILTRO_DOMINIO_SERVICIO_REQUEST:
            return { ...state, fetchingListaUsuarios: true };
        case configuracionYSeguridadActions.FILTRO_DOMINIO_SERVICIO_SUCCESS:
            return { ...state, fetchingListaUsuarios: false, listaUsuarios: action.listaUsuarios, cantidadListaUsuarios: action.cantidadListaUsuarios };
        case configuracionYSeguridadActions.FILTRO_DOMINIO_SERVICIO_FAILURE:
            return { ...state, fetchingListaUsuarios: false };

        //MÓDULOS DEL SISTEMA
        case configuracionYSeguridadActions.LISTA_MODULOS_REQUEST:
            return { ...state, fetchingListaModulos: true };
        case configuracionYSeguridadActions.LISTA_MODULOS_SUCCESS:
            return { ...state, fetchingListaModulos: false, listaModulosSelect: action.listaModulosSelect };
        case configuracionYSeguridadActions.LISTA_MODULOS_FAILURE:
            return { ...state, fetchingListaModulos: false };

        case configuracionYSeguridadActions.GET_MODULOS_REQUEST:
            return { ...state, fetchingListaModulos: true };
        case configuracionYSeguridadActions.GET_MODULOS_SUCCESS:
            return { ...state, fetchingListaModulos: false, listaModulos: action.listaModulos };
        case configuracionYSeguridadActions.GET_MODULOS_FAILURE:
            return { ...state, fetchingListaModulos: false };

        case configuracionYSeguridadActions.ESTADO_MODULO_REQUEST:
            return { ...state, fetchingEstadoModulo: true };
        case configuracionYSeguridadActions.ESTADO_MODULO_SUCCESS:
            return { ...state, fetchingEstadoModulo: false };
        case configuracionYSeguridadActions.ESTADO_MODULO_FAILURE:
            return { ...state, fetchingEstadoModulo: false };

        case configuracionYSeguridadActions.SHOW_NUEVO_MODULO:
            return { ...state, showModalNuevoModulo: action.showModalNuevoModulo, page: action.page };

        case configuracionYSeguridadActions.NUEVO_MODULO_REQUEST:
            return { ...state, fetchingNuevoModulo: true };
        case configuracionYSeguridadActions.NUEVO_MODULO_SUCCESS:
            return { ...state, fetchingNuevoModulo: false };
        case configuracionYSeguridadActions.NUEVO_MODULO_FAILURE:
            return { ...state, fetchingNuevoModulo: false };

        case configuracionYSeguridadActions.GET_MODULOS_BY_IDUSUARIO_REQUEST:
            return { ...state, fetchingListaModulosByIdUsuario: true };
        case configuracionYSeguridadActions.GET_MODULOS_BY_IDUSUARIO_SUCCESS:
            return { ...state, fetchingListaModulosByIdUsuario: false, listaModulosByIdUsuario: action.listaModulosByIdUsuario };
        case configuracionYSeguridadActions.GET_MODULOS_BY_IDUSUARIO_FAILURE:
            return { ...state, fetchingListaModulosByIdUsuario: false };

        //REDUCERS DE SEGURIDAD
        case configuracionYSeguridadActions.GET_PERMISOS_REQUEST:
            return { ...state, fetchingListaPermisos: true };
        case configuracionYSeguridadActions.GET_PERMISOS_SUCCESS:
            return { ...state, fetchingListaPermisos: false, listaPermisos: action.listaPermisos };
        case configuracionYSeguridadActions.GET_PERMISOS_FAILURE:
            return { ...state, fetchingListaPermisos: false };

        case configuracionYSeguridadActions.GET_PERMISOS_BY_MODULO_REQUEST:
            return { ...state, fetchingListaPermisosByModulo: true };
        case configuracionYSeguridadActions.GET_PERMISOS_BY_MODULO_SUCCESS:
            return { ...state, fetchingListaPermisosByModulo: false, listaPermisosByModulo: action.listaPermisosByModulo };
        case configuracionYSeguridadActions.GET_PERMISOS_BY_MODULO_FAILURE:
            return { ...state, fetchingListaPermisosByModulo: false };

        case configuracionYSeguridadActions.GET_PERMISOS_BUSQUEDA_REQUEST:
            return { ...state, fetchingListaPermisosBusqueda: true };
        case configuracionYSeguridadActions.GET_PERMISOS_BUSQUEDA_SUCCESS:
            return { ...state, fetchingListaPermisosBusqueda: false, listaPermisosBusqueda: action.listaPermisosBusqueda, listaPermisosBusquedaCantidad: action.listaPermisosBusquedaCantidad };
        case configuracionYSeguridadActions.GET_PERMISOS_BUSQUEDA_FAILURE:
            return { ...state, fetchingListaPermisosBusqueda: false };

        case configuracionYSeguridadActions.OBTENER_LOGS_REQUEST:
            return { ...state, fetchingListaLogs: true };
        case configuracionYSeguridadActions.OBTENER_LOGS_SUCCESS:
            return { ...state, fetchingListaLogs: false, listaLogs: action.listaLogs, listaLogsCantidad: action.listaLogsCantidad, listaLogsInfinity: action.listaLogsInfinity, hasMore: action.hasMore };
        case configuracionYSeguridadActions.OBTENER_LOGS_FAILURE:
            return { ...state, fetchingListaLogs: false };

        //REDUCERS DE ROLES
        case configuracionYSeguridadActions.ESTADO_ROL_REQUEST:
            return { ...state, fetchingListaRoles: true };
        case configuracionYSeguridadActions.ESTADO_ROL_SUCCESS:
            return { ...state, fetchingListaRoles: false };
        case configuracionYSeguridadActions.ESTADO_ROL_FAILURE:
            return { ...state, fetchingListaRoles: false };

        case configuracionYSeguridadActions.SHOW_MODAL_NUEVO_ROL:
            return { ...state, showModalNuevoRol: action.showModalNuevoRol, page: action.page };

        case configuracionYSeguridadActions.SHOW_MODAL_EDITAR_ROL:
            return { ...state, showModalEditarRol: action.showModalEditarRol, rolSeleccionado: action.rolSeleccionado, page: action.page };

        case configuracionYSeguridadActions.NUEVO_ROL_REQUEST:
            return { ...state, fetchingNuevoRol: true };
        case configuracionYSeguridadActions.NUEVO_ROL_SUCCESS:
            return { ...state, fetchingNuevoRol: false };
        case configuracionYSeguridadActions.NUEVO_ROL_FAILURE:
            return { ...state, fetchingNuevoRol: false };

        case configuracionYSeguridadActions.EDITAR_ROL_REQUEST:
            return { ...state, fetchingEditarRol: true };
        case configuracionYSeguridadActions.EDITAR_ROL_SUCCESS:
            return { ...state, fetchingEditarRol: false };
        case configuracionYSeguridadActions.EDITAR_ROL_FAILURE:
            return { ...state, fetchingEditarRol: false };

        case configuracionYSeguridadActions.GET_VALUE_SEARCH:
            return { ...state, valueSearch: action.valueSearch, page: action.page };

        // Reducers Settings
        case configuracionYSeguridadActions.GET_SETTINGS_REQUEST:
            return {...state, fetchingListaSettings: true};
        case configuracionYSeguridadActions.GET_SETTINGS_SUCCESS:
            return {...state, fetchingListaSettings: false, listaSettings: action.listaSettings};        
        case configuracionYSeguridadActions.GET_SETTINGS_FAILURE:
            return {...state, fetchingListaSettings: false};

        case configuracionYSeguridadActions.EDITAR_SETTINGS_REQUEST:
            return { ...state, fetchingEditarSettings: true };
        case configuracionYSeguridadActions.EDITAR_SETTINGS_SUCCESS:
            return { ...state, fetchingEditarSettings: false };
        case configuracionYSeguridadActions.EDITAR_SETTINGS_FAILURE:
            return { ...state, fetchingEditarSettings: false };

        case configuracionYSeguridadActions.SHOW_MODAL_EDITAR_SETTINGS:
            return { ...state, showModalEditarSettings: action.showModalEditarSettings, settingSeleccionado: action.settingSeleccionado};


        case configuracionYSeguridadActions.SERVICIO_POR_PROVEEDOR_REQUEST:
           return { ...state, fetchingServiciosPorProveedor: true };
        case configuracionYSeguridadActions.SERVICIO_POR_PROVEEDOR_SUCCESS:
            return { ...state, fetchingServiciosPorProveedor: false, serviciosPorProveedor: action.serviciosPorProveedor };
        case configuracionYSeguridadActions.SERVICIO_POR_PROVEEDOR_FAILURE:
            return { ...state, fetchingServiciosPorProveedor: false };

        //ALERTA
        case configuracionYSeguridadActions.LISTA_ALERTAS_BUSQUEDA_REQUEST:
            return { ...state, fetchingListaAlertaBusqueda: true };
        case configuracionYSeguridadActions.LISTA_ALERTAS_BUSQUEDA_SUCCESS:
            return { ...state, fetchingListaAlertaBusqueda: false, listaAlertaBusqueda: action.listaAlertaBusqueda, listaAlertaBusquedaCantidad: action.listaAlertaBusquedaCantidad };
        case configuracionYSeguridadActions.LISTA_ALERTAS_BUSQUEDA_FAILURE:
            return { ...state, fetchingListaAlertaBusqueda: false };

        case configuracionYSeguridadActions.ESTADO_ALERTA_REQUEST:
            return { ...state, fetchingListaAlertaBusqueda: true };
        case configuracionYSeguridadActions.ESTADO_ALERTA_SUCCESS:
            return { ...state, fetchingListaAlertaBusqueda: false };
        case configuracionYSeguridadActions.ESTADO_ALERTA_FAILURE:
            return { ...state, fetchingListaAlertaBusqueda: false };

        case configuracionYSeguridadActions.LISTA_DIAS_ALERTA_REQUEST:
            return { ...state, fetchingListaDiasAlerta: true };
        case configuracionYSeguridadActions.LISTA_DIAS_ALERTA_SUCCESS:
            return { ...state, fetchingListaDiasAlerta: false, listaDiasAlerta: action.listaDiasAlerta };
        case configuracionYSeguridadActions.LISTA_DIAS_ALERTA_FAILURE:
            return { ...state, fetchingListaDiasAlerta: false };

        case configuracionYSeguridadActions.LISTA_EVENTO_POR_MODULO_REQUEST:
           return { ...state, fetchingListaEventoPorModulo: true };
        case configuracionYSeguridadActions.LISTA_EVENTO_POR_MODULO_SUCCESS:
            return { ...state, fetchingListaEventoPorModulo: false, listaEventoPorModulo: action.listaEventoPorModulo };
        case configuracionYSeguridadActions.LISTA_EVENTO_POR_MODULO_FAILURE:
            return { ...state, fetchingListaEventoPorModulo: false };

        case configuracionYSeguridadActions.SHOW_MODAL_DETALLE_ALERTA:
            return { ...state, showModalDetalleAlerta: action.showModalDetalleAlerta, alertaSeleccionado: action.alertaSeleccionado}

        case configuracionYSeguridadActions.SHOW_MODAL_NUEVA_ALERTA:
            return { ...state, showModalNuevaAlerta: action.showModalNuevaAlerta, page: action.page, busqueda: action.busqueda}

        case configuracionYSeguridadActions.SHOW_MODAL_EDITAR_ALERTA:
            return { ...state, showModalEditarAlerta: action.showModalEditarAlerta, alertaSeleccionado: action.alertaSeleccionado, page: action.page, busqueda: action.busqueda}

        case configuracionYSeguridadActions.NUEVA_ALERTA_REQUEST:
            return { ...state, fetchingNuevaAlerta: true };
        case configuracionYSeguridadActions.NUEVA_ALERTA_SUCCESS:
            return { ...state, fetchingNuevaAlerta: false };
        case configuracionYSeguridadActions.NUEVA_ALERTA_FAILURE:
            return { ...state, fetchingNuevaAlerta: false };

        case configuracionYSeguridadActions.EDITAR_ALERTA_REQUEST:
            return { ...state, fetchingEditarAlerta: true };
        case configuracionYSeguridadActions.EDITAR_ALERTA_SUCCESS:
            return { ...state, fetchingEditarAlerta: false };
        case configuracionYSeguridadActions.EDITAR_ALERTA_FAILURE:
            return { ...state, fetchingEditarAlerta: false };

        case configuracionYSeguridadActions.CAMBIAR_PERIODICIDAD:
            return { ...state, periodicidad: action.periodicidad, rangoFechas: action.rangoFechas };

        case configuracionYSeguridadActions.TIEMPO_RECARGA:
            return { ...state, tiempoRecarga: action.tiempoRecarga };

        case configuracionYSeguridadActions.SHOW_NOTIFICACIONES:
            return { ...state, showNotificaciones: action.showNotificaciones}

        //TIPOS DE BITACORA
        case configuracionYSeguridadActions.LISTA_TIPOS_BITACORA_BUSQUEDA_REQUEST:
            return { ...state, fetchingListaTiposBitacoraBusqueda: true };
        case configuracionYSeguridadActions.LISTA_TIPOS_BITACORA_BUSQUEDA_SUCCESS:
            return { ...state, fetchingListaTiposBitacoraBusqueda: false, listaTiposBitacoraBusqueda: action.listaTiposBitacoraBusqueda, listaTiposBitacoraBusquedaCantidad: action.listaTiposBitacoraBusquedaCantidad };
        case configuracionYSeguridadActions.LISTA_TIPOS_BITACORA_BUSQUEDA_FAILURE:
            return { ...state, fetchingListaTiposBitacoraBusqueda: false };

        case configuracionYSeguridadActions.LISTA_TIPOS_BITACORA_ACTIVOS_REQUEST:
            return { ...state, fetchingListaTiposBitacoraActivos: true };
        case configuracionYSeguridadActions.LISTA_TIPOS_BITACORA_ACTIVOS_SUCCESS:
            return { ...state, fetchingListaTiposBitacoraActivos: false, listaTiposBitacoraActivos: action.listaTiposBitacoraActivos };
        case configuracionYSeguridadActions.LISTA_TIPOS_BITACORA_ACTIVOS_FAILURE:
            return { ...state, fetchingListaTiposBitacoraActivos: false };

        case configuracionYSeguridadActions.ESTADO_TIPO_BITACORA_REQUEST:
            return { ...state, fetchingListaTiposBitacoraBusqueda: true };
        case configuracionYSeguridadActions.ESTADO_TIPO_BITACORA_SUCCESS:
            return { ...state, fetchingListaTiposBitacoraBusqueda: false };
        case configuracionYSeguridadActions.ESTADO_TIPO_BITACORA_FAILURE:
            return { ...state, fetchingListaTiposBitacoraBusqueda: false };

        case configuracionYSeguridadActions.MODAL_NUEVO_TIPO_BITACORA:
            return { ...state, showModalNuevoTipoBitacora: action.showModalNuevoTipoBitacora, page: action.page, busqueda: action.busqueda }

        case configuracionYSeguridadActions.NUEVO_TIPO_BITACORA_REQUEST:
            return { ...state, fetchingNuevoTipoBitacora: true };
        case configuracionYSeguridadActions.NUEVO_TIPO_BITACORA_SUCCESS:
            return { ...state, fetchingNuevoTipoBitacora: false };
        case configuracionYSeguridadActions.NUEVO_TIPO_BITACORA_FAILURE:
            return { ...state, fetchingNuevoTipoBitacora: false };

        case configuracionYSeguridadActions.MODAL_EDITAR_TIPO_BITACORA:
            return { ...state, showModalEditarTipoBitacora: action.showModalEditarTipoBitacora, tipoBitacoraSeleccionada: action.tipoBitacoraSeleccionada, page: action.page, busqueda: action.busqueda }

        case configuracionYSeguridadActions.EDITAR_TIPO_BITACORA_REQUEST:
            return { ...state, fetchingEditarTipoBitacora: true };
        case configuracionYSeguridadActions.EDITAR_TIPO_BITACORA_SUCCESS:
            return { ...state, fetchingEditarTipoBitacora: false };
        case configuracionYSeguridadActions.EDITAR_TIPO_BITACORA_FAILURE:
            return { ...state, fetchingEditarTipoBitacora: false };
        
        case configuracionYSeguridadActions.LISTA_TIPO_BITACORAS_BY_ID_MODULO_REQUEST:
            return { ...state, fetchingListaTipoBitacoraByIdModulo: true };
        case configuracionYSeguridadActions.LISTA_TIPO_BITACORAS_BY_ID_MODULO_SUCCESS:
            return { ...state, fetchingListaTipoBitacoraByIdModulo: false, listaTipoBitacoraByIdModulo: action.listaTipoBitacoraByIdModulo };
        case configuracionYSeguridadActions.LISTA_TIPO_BITACORAS_BY_ID_MODULO_FAILURE:
            return { ...state, fetchingListaTipoBitacoraByIdModulo: false };

        //CATÁLOGO DE BITACORA
        case configuracionYSeguridadActions.LISTA_BITACORAS_BUSQUEDA_REQUEST:
            return { ...state, fetchingListaBitacorasBusqueda: true };
        case configuracionYSeguridadActions.LISTA_BITACORAS_BUSQUEDA_SUCCESS:
            return { ...state, fetchingListaBitacorasBusqueda: false, listaBitacorasBusqueda: action.listaBitacorasBusqueda, listaBitacorasBusquedaCantidad: action.listaBitacorasBusquedaCantidad };
        case configuracionYSeguridadActions.LISTA_BITACORAS_BUSQUEDA_FAILURE:
            return { ...state, fetchingListaBitacorasBusqueda: false };

        case configuracionYSeguridadActions.ESTADO_BITACORA_REQUEST:
            return { ...state, fetchingListaBitacorasBusqueda: true };
        case configuracionYSeguridadActions.ESTADO_BITACORA_SUCCESS:
            return { ...state, fetchingListaBitacorasBusqueda: false };
        case configuracionYSeguridadActions.ESTADO_BITACORA_FAILURE:
            return { ...state, fetchingListaBitacorasBusqueda: false };

        case configuracionYSeguridadActions.MODAL_NUEVA_BITACORA:
            return { ...state, showModalNuevaBitacora: action.showModalNuevaBitacora, page: action.page, busqueda: action.busqueda }

        case configuracionYSeguridadActions.NUEVA_BITACORA_REQUEST:
            return { ...state, fetchingNuevaBitacora: true };
        case configuracionYSeguridadActions.NUEVA_BITACORA_SUCCESS:
            return { ...state, fetchingNuevaBitacora: false };
        case configuracionYSeguridadActions.NUEVA_BITACORA_FAILURE:
            return { ...state, fetchingNuevaBitacora: false };

        case configuracionYSeguridadActions.MODAL_EDITAR_BITACORA:
            return { ...state, showModalEditarBitacora: action.showModalEditarBitacora, bitacoraSeleccionada: action.bitacoraSeleccionada, page: action.page, busqueda: action.busqueda }

        case configuracionYSeguridadActions.EDITAR_BITACORA_REQUEST:
            return { ...state, fetchingEditarBitacora: true };
        case configuracionYSeguridadActions.EDITAR_BITACORA_SUCCESS:
            return { ...state, fetchingEditarBitacora: false };
        case configuracionYSeguridadActions.EDITAR_BITACORA_FAILURE:
            return { ...state, fetchingEditarBitacora: false };

        case configuracionYSeguridadActions.MODAL_VER_BITACORA:
            return { ...state, showModalVerBitacora: action.showModalVerBitacora, bitacoraSeleccionada: action.bitacoraSeleccionada }

        case configuracionYSeguridadActions.GET_VALUES_FORMULARIO:
            return { ...state, formularioSearch:action.formularioSearch, page:action.page};

        case configuracionYSeguridadActions.GET_VALUES_FORMULARIO_ALERTAS:
            return { ...state, formularioSearchAlertas:action.formularioSearchAlertas, page:action.page};
        //Módulos Bitácoras
        case configuracionYSeguridadActions.GET_ID_MODULO:
            return {...state, idModulo: action.idModulo};
        case configuracionYSeguridadActions.LISTA_BITACORAS_MODULOS_BUSQUEDA_REQUEST:
            return { ...state, fetchingListaModulosBitacora: true };
        case configuracionYSeguridadActions.LISTA_BITACORAS_MODULOS_BUSQUEDA_SUCCESS:
            return { ...state, fetchingListaModulosBitacora: false, listaModulosBitacora: action.listaModulosBitacora, listaModulosBitacoraCantidad: action.listaModulosBitacoraCantidad };
        case configuracionYSeguridadActions.LISTA_BITACORAS_MODULOS_BUSQUEDA_FAILURE:
            return { ...state, fetchingListaModulosBitacora: false };

        case configuracionYSeguridadActions.SHOW_MODAL_NUEVO_MODULO_BITACORA:
            return { ...state, showModalNuevoModuloBitacora: action.showModalNuevoModuloBitacora, page: action.page, busqueda: action.busqueda }

        case configuracionYSeguridadActions.NUEVO_MODULO_BITACORA_REQUEST:
            return { ...state, fetchingNuevoModuloBitacora: true };
        case configuracionYSeguridadActions.NUEVO_MODULO_BITACORA_SUCCESS:
            return { ...state, fetchingNuevoModuloBitacora: false };
        case configuracionYSeguridadActions.NUEVO_MODULO_BITACORA_FAILURE:
            return { ...state, fetchingNuevoModuloBitacora: false };

        case configuracionYSeguridadActions.SHOW_MODAL_EDITAR_MODULO_BITACORA:
            return { ...state, showModalEditarModuloBitacora: action.showModalEditarModuloBitacora, moduloBitacoraSeleccionada: action.moduloBitacoraSeleccionada, page: action.page, busqueda: action.busqueda }

        case configuracionYSeguridadActions.EDITAR_MODULO_BITACORA_REQUEST:
            return { ...state, fetchingEditarModuloBitacora: true };
        case configuracionYSeguridadActions.EDITAR_MODULO_BITACORA_SUCCESS:
            return { ...state, fetchingEditarModuloBitacora: false };
        case configuracionYSeguridadActions.EDITAR_MODULO_BITACORA_FAILURE:
            return { ...state, fetchingEditarModuloBitacora: false };

        case configuracionYSeguridadActions.LISTA_MODOULOS_BITACORAS_BY_IDMODULO_REQUEST:
            return { ...state, fetchingListaModulosBitacoraByIdModulo: true };
        case configuracionYSeguridadActions.LISTA_MODOULOS_BITACORAS_BY_IDMODULO_SUCCESS:
            return { ...state, fetchingListaModulosBitacoraByIdModulo: false, listaModulosBitacoraByIdModulo: action.listaModulosBitacoraByIdModulo };
        case configuracionYSeguridadActions.LISTA_MODOULOS_BITACORAS_BY_IDMODULO_FAILURE:
            return { ...state, fetchingListaModulosBitacoraByIdModulo: false };
        
        //Reporte Dinámico
            case configuracionYSeguridadActions.LISTA_REPORTE_DINAMICO_BITACORA_BUSQUEDA_REQUEST:
                return { ...state, fetchingListaReporteDinamicoBitacora: true, formularioReporte: action.formulario };
            case configuracionYSeguridadActions.LISTA_REPORTE_DINAMICO_BITACORA_BUSQUEDA_SUCCESS:
                    return { ...state, fetchingListaReporteDinamicoBitacora: false, listaReporteDinamicoBitacora: action.listaReporteDinamicoBitacora, listaReporteDinamicoCantidadBitacora: action.listaReporteDinamicoCantidadBitacora };
            case configuracionYSeguridadActions.LISTA_REPORTE_DINAMICO_BITACORA_BUSQUEDA_FAILURE:
                    return { ...state, fetchingListaReporteDinamicoBitacora: false };
 
            case configuracionYSeguridadActions.REPORTE_DINAMICO_AGRUPADO_BITACORA_REQUEST:
                    return { ...state, fetchingReporteDinamicoAgrupadoBitacora: true, formularioReporte: action.formulario };
            case configuracionYSeguridadActions.REPORTE_DINAMICO_AGRUPADO_BITACORA_SUCCESS:
                    return { ...state, fetchingReporteDinamicoAgrupadoBitacora: false, listaReporteDinamicoAgrupadoBitacora: action.listaReporteDinamicoAgrupadoBitacora, listaReporteDinamicoAgrupadoCantidadBitacora: action.listaReporteDinamicoAgrupadoCantidadBitacora };
            case configuracionYSeguridadActions.REPORTE_DINAMICO_AGRUPADO_BITACORA_FAILURE:
                    return { ...state, fetchingReporteDinamicoAgrupadoBitacora: false };
                    
            case configuracionYSeguridadActions.PDF_REPORTE_DINAMICO_BITACORA_REQUEST:
                    return { ...state, fetchingReporteDinamicoPDF: true };
            case configuracionYSeguridadActions.PDF_REPORTE_DINAMICO_BITACORA_SUCCESS:
                    return { ...state, fetchingReporteDinamicoPDF: false };
            case configuracionYSeguridadActions.PDF_REPORTE_DINAMICO_BITACORA_FAILURE:
                    return { ...state, fetchingReporteDinamicoPDF: false };
    
            case configuracionYSeguridadActions.EXCEL_REPORTE_DINAMICO_BITACORA_REQUEST:
                    return { ...state, fetchingReporteDinamicoEXCEL: true };
            case configuracionYSeguridadActions.EXCEL_REPORTE_DINAMICO_BITACORA_SUCCESS:
                    return { ...state, fetchingReporteDinamicoEXCEL: false };
            case configuracionYSeguridadActions.EXCEL_REPORTE_DINAMICO_BITACORA_FAILURE:
                    return { ...state, fetchingReporteDinamicoEXCEL: false };

            case configuracionYSeguridadActions.PDF_REPORTE_DINAMICO_BITACORA_AGRUPADO_REQUEST:
                return { ...state, fetchingReporteDinamicoAgrupadoPDF: true };
            case configuracionYSeguridadActions.PDF_REPORTE_DINAMICO_BITACORA_AGRUPADO_SUCCESS:
                return { ...state, fetchingReporteDinamicoAgrupadoPDF: false };
            case configuracionYSeguridadActions.PDF_REPORTE_DINAMICO_BITACORA_AGRUPADO_FAILURE:
                return { ...state, fetchingReporteDinamicoAgrupadoPDF: false };
    
            case configuracionYSeguridadActions.EXCEL_REPORTE_DINAMICO_BITACORA_AGRUPADO_REQUEST:
                return { ...state, fetchingReporteDinamicoAgrupadoEXCEL: true };
            case configuracionYSeguridadActions.EXCEL_REPORTE_DINAMICO_BITACORA_AGRUPADO_SUCCESS:
                return { ...state, fetchingReporteDinamicoAgrupadoEXCEL: false };
            case configuracionYSeguridadActions.EXCEL_REPORTE_DINAMICO_BITACORA_AGRUPADO_FAILURE:
                return { ...state, fetchingReporteDinamicoAgrupadoEXCEL: false };

            case configuracionYSeguridadActions.LIMPIAR_FORMULARIO_REPORTE_BITACORA:
                return { ...state, formularioReporte : action.formularioReporte, listaReporteDinamicoBitacora: action.listaReporteDinamicoBitacora, listaReporteDinamicoCantidadBitacora: action.listaReporteDinamicoCantidadBitacora};

            case configuracionYSeguridadActions.LISTA_PARTIDAS_BY_IDSSERVICIOS_REQUEST:
                return { ...state, fetchingListaPartidasByIdsServicios: true };
            case configuracionYSeguridadActions.LISTA_PARTIDAS_BY_IDSSERVICIOS_SUCCESS:
                return { ...state, fetchingListaPartidasByIdsServicios: false, listaPartidasByIdsServicios: action.listaPartidasByIdsServicios };
            case configuracionYSeguridadActions.LISTA_PARTIDAS_BY_IDSSERVICIOS_FAILURE:
                return { ...state, fetchingListaPartidasByIdsServicios: false };


            case configuracionYSeguridadActions.GET_ALL_REGISTROS_BITACORA_REQUEST:
                return { ...state, fetchingGetAllRegistros: true };
            case configuracionYSeguridadActions.GET_ALL_REGISTROS_BITACORA_SUCCESS:
                return { ...state, fetchingGetAllRegistros: false, allRegistros: action.allRegistros, allRegistrosCantidad: action.allRegistrosCantidad };
            case configuracionYSeguridadActions.GET_ALL_REGISTROS_BITACORA_FAILURE:
                return { ...state, fetchingGetAllRegistros: false };

            case configuracionYSeguridadActions.GET_REGISTROS_BY_TOKEN_BITACORA_REQUEST:
                return { ...state, fetchingGetRegistrosByToken: true };
            case configuracionYSeguridadActions.GET_REGISTROS_BY_TOKEN_BITACORA_SUCCESS:
                return { ...state, fetchingGetRegistrosByToken: false, registrosByToken: action.registrosByToken, registrosByTokenCantidad: action.registrosByTokenCantidad };
            case configuracionYSeguridadActions.GET_REGISTROS_BY_TOKEN_BITACORA_FAILURE:
                return { ...state, fetchingGetRegistrosByToken: false };

            case configuracionYSeguridadActions.CREATE_REGISTROS_BITACORA_REQUEST:
                return { ...state, fetchingCreateRegistro: true };
            case configuracionYSeguridadActions.CREATE_REGISTROS_BITACORA_SUCCESS:
                return { ...state, fetchingCreateRegistro: false };
            case configuracionYSeguridadActions.CREATE_REGISTROS_BITACORA_FAILURE:
                return { ...state, fetchingCreateRegistro: false };

            case configuracionYSeguridadActions.ESTADO_REGISTROS_BITACORA_REQUEST:
                return { ...state, fetchingEstadoRegistros: true };
            case configuracionYSeguridadActions.ESTADO_REGISTROS_BITACORA_SUCCESS:
                return { ...state, fetchingEstadoRegistros: false };
            case configuracionYSeguridadActions.ESTADO_REGISTROS_BITACORA_FAILURE:
                return { ...state, fetchingEstadoRegistros: false };

            case configuracionYSeguridadActions.EDIT_REGISTROS_BITACORA_REQUEST:
                return { ...state, fetchingEditRegistros: true };
            case configuracionYSeguridadActions.EDIT_REGISTROS_BITACORA_SUCCESS:
                return { ...state, fetchingEditRegistros: false };
            case configuracionYSeguridadActions.EDIT_REGISTROS_BITACORA_FAILURE:
                return { ...state, fetchingEditRegistros: false };

            case configuracionYSeguridadActions.MODAL_CREAR_REGISTRO_BITACORA:
                return { ...state, showModalCrearRegistro: action.showModalCrearRegistro, data: action.data };

            case configuracionYSeguridadActions.MODAL_EDITAR_REGISTRO_BITACORA:
                return { ...state, showModalEditarRegistro: action.showModalEditarRegistro, registroReporteSeleccionado: action.registroReporteSeleccionado, page: action.page, personal: action.personal };

            case configuracionYSeguridadActions.LISTA_PARTIDAS_ACTIVAS_REQUEST:
                return { ...state, fetchingListaPartidasActivas: true };
            case configuracionYSeguridadActions.LISTA_PARTIDAS_ACTIVAS_SUCCESS:
                return { ...state, fetchingListaPartidasActivas: false, listaPartidasActivas: action.listaPartidasActivas };
            case configuracionYSeguridadActions.LISTA_PARTIDAS_ACTIVAS_FAILURE:
                return { ...state, fetchingListaPartidasActivas: false };

            // Rangos de Validacion
            case configuracionYSeguridadActions.LISTA_BUSQUEDA_RANGOS_VALIDACION_REQUEST:
                return { ...state, fetchingListaBusquedaRangosValidacion: true };
            case configuracionYSeguridadActions.LISTA_BUSQUEDA_RANGOS_VALIDACION_SUCCESS:
                return { ...state, fetchingListaBusquedaRangosValidacion: false, listaBusquedaRangosValidacion: action.listaBusquedaRangosValidacion, listaBusquedaRangosValidacionCantidad: action.listaBusquedaRangosValidacionCantidad };
            case configuracionYSeguridadActions.LISTA_BUSQUEDA_RANGOS_VALIDACION_FAILURE:
                return { ...state, fetchingListaBusquedaRangosValidacion: false };

            case configuracionYSeguridadActions.LISTA_ACTIVOS_RANGOS_VALIDACION_REQUEST:
                return { ...state, fetchingListaActivosRangosValidacion: true };
            case configuracionYSeguridadActions.LISTA_ACTIVOS_RANGOS_VALIDACION_SUCCESS:
                return { ...state, fetchingListaActivosRangosValidacion: false, listaActivosRangosValidacion: action.listaActivosRangosValidacion };
            case configuracionYSeguridadActions.LISTA_ACTIVOS_RANGOS_VALIDACION_FAILURE:
                return { ...state, fetchingListaActivosRangosValidacion: false };

            case configuracionYSeguridadActions.NUEVO_RANGO_VALIDACION_REQUEST:
                return { ...state, fetchingNuevoRangoValidacion: true };
            case configuracionYSeguridadActions.NUEVO_RANGO_VALIDACION_SUCCESS:
                return { ...state, fetchingNuevoRangoValidacion: false, };
            case configuracionYSeguridadActions.NUEVO_RANGO_VALIDACION_FAILURE:
                return { ...state, fetchingNuevoRangoValidacion: false };

            case configuracionYSeguridadActions.EDITAR_RANGO_VALIDACION_REQUEST:
                return { ...state, fetchingEditarRangosValidacion: true };
            case configuracionYSeguridadActions.EDITAR_RANGO_VALIDACION_SUCCESS:
                return { ...state, fetchingEditarRangosValidacion: false, };
            case configuracionYSeguridadActions.EDITAR_RANGO_VALIDACION_FAILURE:
                return { ...state, fetchingEditarRangosValidacion: false };

            case configuracionYSeguridadActions.ESTADO_RANGO_VALIDACION_REQUEST:
                return { ...state, fetchingEstadoRangoValidacion: true };
            case configuracionYSeguridadActions.ESTADO_RANGO_VALIDACION_SUCCESS:
                return { ...state, fetchingEstadoRangoValidacion: false, };
            case configuracionYSeguridadActions.ESTADO_RANGO_VALIDACION_FAILURE:
                return { ...state, fetchingEstadoRangoValidacion: false };
            
            case configuracionYSeguridadActions.SHOW_MODAL_NUEVO_RANGO_VALIDACION:
                return { ...state, showModalNuevoRangoValidacion: action.showModalNuevoRangoValidacion, page: action.page, busqueda: action.busqueda, orden: action.orden }
            
            case configuracionYSeguridadActions.SHOW_MODAL_EDITAR_RANGO_VALIDACION:
                return { ...state, showModalEditarRangoValidacion: action.showModalEditarRangoValidacion, rangoValidacionSeleccionado: action.rangoValidacionSeleccionado, page: action.page, busqueda: action.busqueda, orden: action.orden}

            case configuracionYSeguridadActions.EXPORT_EXCEL_RANGOS_VALIDACION_REQUEST:
                    return { ...state, fetchingExcelRangosValidacion: true };
            case configuracionYSeguridadActions.EXPORT_EXCEL_RANGOS_VALIDACION_SUCCESS:
                    return { ...state, fetchingExcelRangosValidacion: false };
            case configuracionYSeguridadActions.EXPORT_EXCEL_RANGOS_VALIDACION_FAILURE:
                    return { ...state, fetchingExcelRangosValidacion: false };

            // Responsables del Servicio
            case configuracionYSeguridadActions.LISTA_BUSQUEDA_RESPONSABLES_REQUEST:
                return { ...state, fetchingListaBusquedaResponsableServicio: true };
            case configuracionYSeguridadActions.LISTA_BUSQUEDA_RESPONSABLES_SUCCESS:
                return { ...state, fetchingListaBusquedaResponsableServicio: false, listaBusquedaResponsableServicio: action.listaBusquedaResponsableServicio, listaBusquedaResponsableServicioCantidad: action.listaBusquedaResponsableServicioCantidad };
            case configuracionYSeguridadActions.LISTA_BUSQUEDA_RESPONSABLES_FAILURE:
                return { ...state, fetchingListaBusquedaResponsableServicio: false };

            case configuracionYSeguridadActions.LISTA_ACTIVOS_RESPONSABLES_REQUEST:
                return { ...state, fetchingResponsablesActivos: true };
            case configuracionYSeguridadActions.LISTA_ACTIVOS_RESPONSABLES_SUCCESS:
                return { ...state, fetchingResponsablesActivos: false, listaResponsablesActivos: action.listaResponsablesActivos };
            case configuracionYSeguridadActions.LISTA_ACTIVOS_RESPONSABLES_FAILURE:
                return { ...state, fetchingResponsablesActivos: false };

            case configuracionYSeguridadActions.NUEVO_RESPONSABLE_REQUEST:
                return { ...state, fetchingNuevoResponsable: true };
            case configuracionYSeguridadActions.NUEVO_RESPONSABLE_SUCCESS:
                return { ...state, fetchingNuevoResponsable: false, };
            case configuracionYSeguridadActions.NUEVO_RESPONSABLE_FAILURE:
                return { ...state, fetchingNuevoResponsable: false };

            case configuracionYSeguridadActions.EDITAR_RESPONSABLE_REQUEST:
                return { ...state, fetchingEditarResponsable: true };
            case configuracionYSeguridadActions.EDITAR_RESPONSABLE_SUCCESS:
                return { ...state, fetchingEditarResponsable: false, };
            case configuracionYSeguridadActions.EDITAR_RESPONSABLE_FAILURE:
                return { ...state, fetchingEditarResponsable: false };

            case configuracionYSeguridadActions.ESTADO_RESPONSABLE_REQUEST:
                return { ...state, fetchingEstadoResponsable: true };
            case configuracionYSeguridadActions.ESTADO_RESPONSABLE_SUCCESS:
                return { ...state, fetchingEstadoResponsable: false, };
            case configuracionYSeguridadActions.ESTADO_RESPONSABLE_FAILURE:
                return { ...state, fetchingEstadoResponsable: false };
            
            case configuracionYSeguridadActions.SHOW_MODAL_NUEVO_RESPONSABLE:
                return { ...state, showModalNuevoResponsable: action.showModalNuevoResponsable, page: action.page, busqueda: action.busqueda, orden: action.orden }
            
            case configuracionYSeguridadActions.SHOW_MODAL_EDITAR_RESPONSABLE:
                return { ...state, showModalEditarResponsable: action.showModalEditarResponsable, responsableSeleccionado: action.responsableSeleccionado, page: action.page, busqueda: action.busqueda, orden: action.orden}

            case configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_REQUEST:
                return { ...state, fetchingListaUsuariosByIdsServicios: true };
            case configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_SUCCESS:
                return { ...state, fetchingListaUsuariosByIdsServicios: false, listaUsuariosByIdsServicios: action.listaUsuariosByIdsServicios };
            case configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_FAILURE:
                return { ...state, fetchingListaUsuariosByIdsServicios: false };

            case configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_RS_REQUEST:
                return { ...state, fetchingListaUsuariosByIdsServiciosTipoRS: true };
            case configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_RS_SUCCESS:
                return { ...state, fetchingListaUsuariosByIdsServiciosTipoRS: false, listaUsuariosByIdsServiciosTipoRS: action.listaUsuariosByIdsServiciosTipoRS };
            case configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_RS_FAILURE:
                return { ...state, fetchingListaUsuariosByIdsServiciosTipoRS: false };

            case configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_AT_REQUEST:
                return { ...state, fetchingListaUsuariosByIdsServiciosTipoAT: true };
            case configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_AT_SUCCESS:
                return { ...state, fetchingListaUsuariosByIdsServiciosTipoAT: false, listaUsuariosByIdsServiciosTipoAT: action.listaUsuariosByIdsServiciosTipoAT };
            case configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_AT_FAILURE:
                return { ...state, fetchingListaUsuariosByIdsServiciosTipoAT: false };

            case configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_PYRS_REQUEST:
                return { ...state, fetchingListaUsuariosByIdsServiciosTipoPYRS: true };
            case configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_PYRS_SUCCESS:
                return { ...state, fetchingListaUsuariosByIdsServiciosTipoPYRS: false, listaUsuariosByIdsServiciosTipoPYRS: action.listaUsuariosByIdsServiciosTipoPYRS };
            case configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_PYRS_FAILURE:
                return { ...state, fetchingListaUsuariosByIdsServiciosTipoPYRS: false };

            case configuracionYSeguridadActions.MODAL_HISTORICO_RESPONSABLES:
                return { ...state, showModalHistoricoResponsable: action.showModalHistoricoResponsable, page: action.page, busqueda: action.busqueda, orden: action.orden }

            case configuracionYSeguridadActions.LISTA_HISTORICO_RESPONSABLE_BUSQUEDA_REQUEST:
                return { ...state, fetchingListaBusquedaHistoricoResponsable: true };
            case configuracionYSeguridadActions.LISTA_HISTORICO_RESPONSABLE_BUSQUEDA_SUCCESS:
                return { ...state, fetchingListaBusquedaHistoricoResponsable: false, listaBusquedaHistoricoResponsable: action.listaBusquedaHistoricoResponsable, listaBusquedaHistoricoResponsableCantidad: action. listaBusquedaHistoricoResponsableCantidad };
            case configuracionYSeguridadActions.LISTA_HISTORICO_RESPONSABLE_BUSQUEDA_FAILURE:
                return { ...state, fetchingListaBusquedaHistoricoResponsable: false };

            //Notificaciones
            case configuracionYSeguridadActions.LISTA_NOTIFICACIONES_REQUEST:
                return { ...state, fetchingListaNotificaciones: true };
            case configuracionYSeguridadActions.LISTA_NOTIFICACIONES_SUCCESS:
                return { ...state, fetchingListaNotificaciones: false, listaNotificaciones: action.listaNotificaciones, cantidadNotificaciones: action. cantidadNotificaciones };
            case configuracionYSeguridadActions.LISTA_NOTIFICACIONES_FAILURE:
                return { ...state, fetchingListaNotificaciones: false };

            case configuracionYSeguridadActions.LISTA_NOTIFICACIONES_NO_LEIDAS_REQUEST:
                return { ...state, fetchingListaNotificacionesNoLeidas: true };
            case configuracionYSeguridadActions.LISTA_NOTIFICACIONES_NO_LEIDAS_SUCCESS:
                return { ...state, fetchingListaNotificacionesNoLeidas: false, listaNotificacionesNoLeidas: action.listaNotificacionesNoLeidas, cantidadNotificacionesNoLeidas: action. cantidadNotificacionesNoLeidas };
            case configuracionYSeguridadActions.LISTA_NOTIFICACIONES_NO_LEIDAS_FAILURE:
                return { ...state, fetchingListaNotificacionesNoLeidas: false };
            
            case configuracionYSeguridadActions.ACTUALIZAR_NOTIFICACIONES_REQUEST:
                return { ...state, fetchingActualizarNotificaciones: true };
            case configuracionYSeguridadActions.ACTUALIZAR_NOTIFICACIONES_SUCCESS:
                return { ...state, fetchingActualizarNotificaciones: false };
            case configuracionYSeguridadActions.ACTUALIZAR_NOTIFICACIONES_FAILURE:
                return { ...state, fetchingActualizarNotificaciones: false };
    
        default:
            return state;
    }
};