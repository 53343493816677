import React from 'react';
import { Table, Row, Col, Switch, Modal, Button, Form, Input, DatePicker, Tooltip, Typography } from 'antd';
import { connect } from "react-redux";
import { EditOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import ModalNuevoProveedor from './ModalNuevoProveedor';
import ModalEditarProveedor from './ModalEditarProveedor';
import '../../../stylesheets/ConfiguracionYSeguridad/Usuarios.css';

const confirm = Modal.confirm;
const {Text} = Typography;

class ProveedoresContainer extends React.Component {
    formRef = React.createRef();
    componentDidMount(){
        const formulario = {
            busqueda: '',
            fechaFin: '',
        };
        this.props.onGetProveedoresBusqueda(1, formulario);
        this.props.onGetListaServicios(null);
        this.props.onGetListaDominios();
        this.props.onGetListaExpedientes();
        this.props.onObtenerServicios();
    }
    
    state={
        page: 1,
        formulario: {
            busqueda: '',
            fechaFin: '',
        },
    }
    render() {
        const { fetchingProveedoresBusqueda, proveedoresBusqueda, onRequestCambiarEstadoProveedor, 
            onModalEditarProveedor, onModalNuevoProveedor, onGetProveedoresBusqueda, proveedoresBusquedaCantidad, onGetListaServicios, onObtenerPartidasByidServicio, listaServiciosActivosF
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columns = [{
                title: 'RFC',
                dataIndex: 'rfc',
                key: 'rfc',
                width: '7%',
            },{
                title: 'Razón social',
                dataIndex: 'razonSocial',
                key: 'razonSocial',
                width: '10%',
            },{
                title: 'Nombre de contacto',
                dataIndex: 'nombre',
                key: 'nombre',
                width: '8%',
            },{
                title: 'Correo de contacto',
                dataIndex: 'correo',
                key: 'correo',
                width: '12%',
            },{
                title: 'Dominio del proveedor',
                dataIndex: 'nombreDominio',
                key: 'nombreDominio',
                width: '8%',
            },{
                title: 'Fecha inicio',
                dataIndex: 'fechaInicio',
                key: 'fechaInicio',
                width: '6%',
            },{
                title: 'Fecha fin',
                dataIndex: 'fechaFin',
                key: 'fechaFin',
                width: '6%',
            },{
                title: 'Fecha alta',
                dataIndex: 'fechaAlta',
                key: 'fechaAlta',
                width: '6%',
            },{
                title: 'Nombre servicio',
                dataIndex: 'nombreServicio',
                key: 'dominioNombre',
                width: '15%',
            },{
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                width: '5%',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.proveedorActivoInactivo} onChange={(e) => handleDesactivar(record, e)}/>
                ),
            },{
                title: 'Editar',
                key: 'editar',
                width: '5%',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarProveedor} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },
        ];
        const onEditar = (key) => {
            console.log('proveedorSeleccionado', key)
            onGetListaServicios(key.idProveedor);
            let areas = [];
            let partidas = [];
            let areasConjuntas = []; // Lista de áreas sin servicio
            let AC = [];

            let partidasConjuntas = [];
            let PC = [];
            let serviciosSeleccionados = [];

            if(key.serviciosProveedoresByIdProveedor){
                for(let i in key.serviciosByIdProveedor){
                    for(let j in key.serviciosProveedoresByIdProveedor){
                        if(key.serviciosByIdProveedor[i].idServicio === key.serviciosProveedoresByIdProveedor[j].idServicio){
                            let concatServ = 'asignado-' + key.serviciosByIdProveedor[i].idServicio;
                            key[concatServ] = key.serviciosProveedoresByIdProveedor[j].asignacion;
                        }
                    }
                    serviciosSeleccionados.push(key.serviciosProveedoresByIdProveedor[i].idServicio)
                }

                onObtenerPartidasByidServicio(serviciosSeleccionados , key.idProveedor, listaServiciosActivosF)

                for(let i in key.serviciosProveedoresByIdProveedor){

                    if(key.serviciosProveedoresByIdProveedor[i].idAreaFuncional){
                        let arrArea = {
                            'area': key.serviciosProveedoresByIdProveedor[i].idAreaFuncional + ' - ' + 
                            key.serviciosProveedoresByIdProveedor[i].areaFuncionalByIdAreaFuncional.codigoArea + ', ' + 
                            key.serviciosProveedoresByIdProveedor[i].areaFuncionalByIdAreaFuncional.areaFuncional,
                            'idServicio': key.serviciosProveedoresByIdProveedor[i].idServicio
                        }

                        areas.push(arrArea);
                        key.areas = areas;

                    }

                    if(key.serviciosProveedoresByIdProveedor[i].idPartida){
                        let arrPartida = {
                            'partida': key.serviciosProveedoresByIdProveedor[i].idPartida + ' - ' + key.serviciosProveedoresByIdProveedor[i].partidaByIdPartida.nombre,
                            'idServicio': key.serviciosProveedoresByIdProveedor[i].idServicio
                        }
                        partidas.push(arrPartida);
                        key.partidas = partidas;
                    }
                }

                for(let i in key.servicios){
                    let areasConj = [];
                    let partConj = [];
                    for(let j in areas){
                        if(parseInt(key.servicios[i]) === areas[j].idServicio){
                            areasConj.push(areas[j].area);
                        }
                    }

                    areasConjuntas.push(areasConj);

                    for(let j in partidas){
                        if(parseInt(key.servicios[i]) === partidas[j].idServicio){
                            partConj.push(partidas[j].partida);
                        }
                    }
                    partidasConjuntas.push(partConj);
                }

                for(let i in key.servicios){
                    let areasConj = {
                        'areas': areasConjuntas[i],
                        'idServicio': parseInt(key.servicios[i]),
                    };
                    AC.push(areasConj);
                }

                for(let j in AC){
                    for(let i = AC[j].areas.length -1; i >=0; i--){
                        if(AC[j].areas.indexOf(AC[j].areas[i]) !== i) AC[j].areas.splice(i,1);
                    }
                }

                for(let i in key.servicios){
                    let partConj = {
                        'partidas': partidasConjuntas[i],
                        'idServicio': parseInt(key.servicios[i]),
                    };

                    PC.push(partConj);
                }

                for(let j in PC){
                    for(let i = PC[j].partidas.length -1; i >=0; i--){
                        if(PC[j].partidas.indexOf(PC[j].partidas[i]) !== i) PC[j].partidas.splice(i,1);
                    }
                }

                key.partidasConjuntas = PC;

                function removeDuplicates(partidas, prop) {
                    let newArray = [];
                    let lookupObject  = {};
               
                    for(var i in partidas) {
                       lookupObject[partidas[i][prop]] = partidas[i];
                    }
               
                    for(i in lookupObject) {
                        newArray.push(lookupObject[i]);
                    }
                     return newArray;
                }

                function removeDuplicatesAreas(areas, prop) {
                    let newArray = [];
                    let lookupObject  = {};
               
                    for(var i in areas) {
                       lookupObject[areas[i][prop]] = areas[i];
                    }
               
                    for(i in lookupObject) {
                        newArray.push(lookupObject[i]);
                    }
                     return newArray;
                }
               
                let uniqueArray = removeDuplicates(partidas, "partida");
                let uniqueArrayAreas = removeDuplicatesAreas(areas, 'area');

                for(let i in key.serviciosByIdProveedor){
                    if(key.serviciosByIdProveedor[i].idServicio === AC[i].idServicio){
                        let concatArea = 'area-' + key.serviciosByIdProveedor[i].idServicio;
                        key[concatArea] = AC[i].areas;
                    }

                    for(let j in PC){
                        if(key.serviciosByIdProveedor[i].idServicio === PC[j].idServicio){
                            let concatPartida = 'partida-' + key.serviciosByIdProveedor[i].idServicio;
                            key[concatPartida] = PC[j].partidas;
                        }
                    }
                }

                // for(let i in key.servicios){
                //     if(key['asignado-' + parseInt(key.servicios[i])]  === 'Area'){
                //         console.log('ENTRO EN CONTEINER A IF ASIGNACION AREA');
                //     } else if(key['asignado-' + parseInt(key.servicios[i])] === 'Partida'){
                //         console.log('ENTRO EN CONTEINER A IF ASIGNACION PARTIDA');
                //     } else if(key['asignado-' + parseInt(key.servicios[i])] === 'Todo'){
                //         console.log('ENTRO EN CONTEINER A IF ASIGNACION TODO');
                //     }
                // }
            }

            onModalEditarProveedor(key, this.state.page);
        }

        const handleDesactivar = (key, e) => {
            const page = this.state.page;
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del proveedor "'+ key.nombre +'"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onRequestCambiarEstadoProveedor(key.idProveedor, page);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
        const onNuevoProveedor = () => {
            onModalNuevoProveedor(this.state.page);
            this.props.onGetListaServicios(null);
        }

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onGetProveedoresBusqueda(1, formulario);
            this.setState({
                formulario: formulario,
                page: 1,
            })
        }
        
        
        
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onGetProveedoresBusqueda(pagination.current, this.state.formulario);
		}
        const scroll = {"x": 2400 | true, "y": 1000};
        return (
            <div className="imgCorp">
                <Row justify='space-around' gutter={[8,8]}>
                    <Col span={24} style={{backgroundColor:'#E4E4E4', borderTopRightRadius:'2em', borderTopLeftRadius:'2em', padding:'1em', textAlign: 'left',}}>
                        <Text style={{textAlign: 'left', fontSize: '2em', color: '#737373' }} strong> Control de Proveedores </Text>
                    </Col>
                    <Col span={24} style={{paddingTop:'1em'}}>
                        <Row justify='space-around' gutter={[8,8]}>
                            <Col span={23}>
                                <Form name="formulario" onFinish={onFinish} ref={this.formRef} >
                                    <Row justify='space-around' gutter={[8,8]}>
                                        <Col span={11}>
                                            <Form.Item
                                            name="busqueda"
                                            >
                                                <Input className='inputFiltro' placeholder="Ingrese proveedor a buscar"/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item
                                                name="fechaFin"
                                            >
                                                <DatePicker style={{width: '100%'}} placeholder='Fecha fin vigencia' format='DD-MM-YYYY'/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={1}>
                                            <Button shape="circle" icon={<SearchOutlined />} className="btnFiltrar" htmlType="submit"></Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            {/* <Col span={1}>
                                <Button onClick={onNuevoProveedor} disabled={!permisosUsuario.crearProveedor} className='nuevoUsuario'>Nuevo Proveedor</Button>
                            </Col> */}
                            <Col span={1}>
                                <Tooltip title='Nuevo Módulo' placement="topRight">
                                    <Button className="iconTer" shape="circle" onClick={onNuevoProveedor} disabled={!permisosUsuario.crearProveedor} icon={<PlusOutlined />}/>
                                </Tooltip>
                            </Col>

                            <Col span={24} style={{padding:'.5em'}}>
                                <Table 
                                size="small"
                                className='tablaUsuarios'
                                locale={{ emptyText: 'Sin datos' }}
                                rowKey="rfc" 
                                columns={columns}
                                dataSource={proveedoresBusqueda}
                                loading={fetchingProveedoresBusqueda}
                                onChange={handleTableChange}
                                pagination={{ total: proveedoresBusquedaCantidad, simple: true, current: this.state.page }}
                                scroll={scroll}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <ModalNuevoProveedor/>
                <ModalEditarProveedor/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        fetchingProveedoresBusqueda: state.InformacionOperativaReducer.fetchingProveedoresBusqueda,
        proveedoresBusqueda: state.InformacionOperativaReducer.proveedoresBusqueda,
        proveedoresBusquedaCantidad: state.InformacionOperativaReducer.proveedoresBusquedaCantidad,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetProveedoresBusqueda: (page, formulario) => {
	    	dispatch({ type: "LISTA_PROVEEDORES_BUSQUEDA_REQUEST", page: page, formulario: formulario });
        },
        onRequestCambiarEstadoProveedor: (idProveedor, page) => {
            dispatch({ type: "ESTADO_PROVEEDOR_REQUEST", idProveedor: idProveedor, page: page });
        },
        onModalNuevoProveedor: (page) => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_PROVEEDOR', showModalNuevoProveedor: true, page: page });
        },
        onModalEditarProveedor: (proveedorSeleccionado, page) => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_PROVEEDOR', showModalEditarProveedor: true, proveedorSeleccionado: proveedorSeleccionado, page: page, idsServicios: [] });
        },
        onGetListaServicios: (idProveedor) => {
            dispatch({ type: 'LISTA_SERVICIOS_PROVEEDORES_REQUEST', idProveedor });
        },
        onGetListaDominios: () => {
            dispatch({ type: 'LISTA_DOMINIOS_ACTIVOS_REQUEST' });
        },
        onGetListaExpedientes: () => {
            dispatch({ type: 'LISTA_EXPEDIENTES_REQUEST' });
        },
        onObtenerPartidasByidServicio: (idsServicios, idProveedor, serviciosActivos) => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_SERVICIO_INFO_OP_REQUEST', idsServicios, idProveedor, serviciosActivos });
        },
        onObtenerServicios: () => {
            dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
       },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ProveedoresContainer);