import React from 'react';
import {connect} from 'react-redux';
import {Form, Row, Col, Button, Modal, Spin, Select, message,Popover, InputNumber, Input, Alert, Switch} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import {estadoDepositoCall} from '../../../services/ServiciosPublicosServices'

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalEditarAsignacionDeposito extends React.Component {
    formRef = React.createRef();

    state = { depositosActivos : {}}

    componentDidUpdate (prevProps) {
        if (this.props.listaDepositosServiciosPublicos !== prevProps.listaDepositosServiciosPublicos) {
            let activosObjeto={}
            this.props.listaDepositosServiciosPublicos.forEach(item => {
                activosObjeto[item.idAsignacionServicioPublico] = item.activo
            });
            this.setState({depositosActivos: activosObjeto})
        }
    }

    render(){

        const {fetchingEditarAsignacionSP, showModalEditarAsignacionSP, page, busqueda, limpiarFormulario, fetchingListaUbicacionesFiltro, listaUbicacionesFiltro,
            fetchingListaUnidadesDeMedicionActivos,listaUnidadesDeMedicionActivos, listaDepositosServiciosPublicos, fetchingListaDepositosServiciosPublicos, listaDepositosSPObjeto,
            onShowModalEditarAsignacion, onLimpiarFormulario, onListaUbicacionesFiltro, onListaUbicacionesFiltroLimpiar, onEditarAsignacion} = this.props;

        if (limpiarFormulario && limpiarFormulario === 'EASP') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }
        
        const handleSearch = (value) => {
            if(value.length>=3){
                if (value) {
                    console.log(value)
                    onListaUbicacionesFiltro(value);
                } else {
                    message.warning('No se encontró ubicación con esas palabras')
                }
            }
            else{
                onListaUbicacionesFiltroLimpiar();
            }
        };

        const onFinish = formulario => {
            console.log(formulario);
            let idServicioPublico = listaDepositosServiciosPublicos[0].idServicioPublico;
            let asignacionObjet = {'idAsignacionServicioPublico': null, 'idServicioPublico': idServicioPublico, 'idUbicacion':null,
                'idUnidadMedida':null, 'capacidad': null, 'fechaAlta': null, 'fechaActualizacion':null, 'activo':true}
            let asignacionArray = []
            let contador = 0;
            for (var key in formulario) {
                if (Object.prototype.hasOwnProperty.call(formulario, key)) {
                    let campo = key.split('-');
                    let idAsignacionServicioPublico = parseInt(campo[1]);
                    if(campo.length === 2) {
                        let valor = formulario[key]
                        let valorFormato = valor.toString().split('-')[0]
                        asignacionObjet[campo[0]] = isNaN(valorFormato) ? valorFormato : parseInt(valorFormato);
                        contador++
                        if(contador === 4){
                            asignacionObjet['idAsignacionServicioPublico'] = idAsignacionServicioPublico;
                            asignacionArray.push(asignacionObjet)
                            contador=0;
                            asignacionObjet = {'idAsignacionServicioPublico': null, 'idServicioPublico': idServicioPublico, 'idUbicacion':null,
                                'idUnidadMedida':null, 'capacidad': null, 'fechaAlta': null, 'fechaActualizacion':null, 'activo':true}
                        }
                    } 
                }
            }
            asignacionArray.forEach(item => {
                if(isNaN(item.idUbicacion) || isNaN(item.idUnidadMedida)){
                    const depositoIndex = listaDepositosServiciosPublicos.find(deposito => deposito.idAsignacionServicioPublico == item.idAsignacionServicioPublico)
                    item.idUbicacion = isNaN(item.idUbicacion) ? depositoIndex.idUbicacion : item.idUbicacion;
                    item.idUnidadMedida = isNaN(item.idUnidadMedida) ? depositoIndex.idUnidadMedida : item.idUnidadMedida;
                };
            })

            console.log(idServicioPublico, asignacionArray, page, busqueda);
            onEditarAsignacion(idServicioPublico, asignacionArray, page, busqueda)
        }

        const onFinishFailed = () =>{
            console.log('Cancel')
        }
        
        const handleCancel = () => {
            onShowModalEditarAsignacion();
            this.formRef.current.resetFields();
            this.setState({depositosActivos:{}});
        }

        const switchHandle = async (value, data) => {
            const token = sessionStorage.getItem('token');
            const estado = await estadoDepositoCall(token,data);
            if(estado.status == 200){
                let objetos = this.state.depositosActivos;
                objetos[data] = value;
                this.setState({depositosActivos:objetos})
                const textMessage = "El estado del deposito se cambió de forma correcta";
                message.success(textMessage);
            } else {
                const textMessage = estado.message;
                message.error(textMessage);
                const campo = `activo-${data}`
                this.formRef.current.setFieldsValue({[campo]: true});
            }
        }
        return (
            <div>
                <Modal
                    visible={showModalEditarAsignacionSP}
                    title='Editar Asignación de Depósitos de Servicio Público'
                    onCancel={handleCancel}
                    footer={false}
                    width='90%'
                >
                    <Spin spinning={fetchingEditarAsignacionSP}>
                        {listaDepositosServiciosPublicos && listaDepositosSPObjeto ? (
                            <div>
                            <Form
                                {...layout}
                                name='formulario'
                                initialValues={listaDepositosSPObjeto}
                                onFinish={onFinish}
                                
                                ref={this.formRef}
                                >
                                {(listaDepositosServiciosPublicos.map(deposito => (
                                    <Row type='flex' justify='space-around' key={deposito.idAsignacionServicioPublico}>
                                        <Col span={3}>
                                            <Form.Item
                                                label={listaDepositosServiciosPublicos.indexOf(deposito) == 0 ? 'Nombre depósito' : false}
                                                name={`${deposito.nombreDeposito}-${deposito.idAsignacionServicioPublico}-${deposito.idAsignacionServicioPublico}`}
                                                >
                                                <Input disabled defaultValue={deposito.nombreDeposito} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item
                                                label={listaDepositosServiciosPublicos.indexOf(deposito) == 0 ? 'Ubicación' : false}
                                                name={`idUbicacion-${deposito.idAsignacionServicioPublico}`}
                                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                                >
                                                <Select allowClear
                                                    style={{ width: '100%' }}
                                                    placeholder="Ingrese la ubicación"
                                                    loading={fetchingListaUbicacionesFiltro}
                                                    showSearch
                                                    filterOption={false}
                                                    onSearch={handleSearch}
                                                    notFoundContent={null}
                                                    dropdownStyle={{ minWidth: "60%" }}
                                                    >
                                                    {listaUbicacionesFiltro.map(option =>
                                                        <Option key={`${option.idUbicacion} - ${option.todoUbicacionCOA}`}>
                                                            <Popover content={option.todoUbicacionCOA} title={false}>
                                                                {`${option.idUbicacion} - ${option.todoUbicacionCOA}`}
                                                            </Popover>
                                                        </Option>
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                label={listaDepositosServiciosPublicos.indexOf(deposito) == 0 ? 'Capacidad' : false}
                                                name={`capacidad-${deposito.idAsignacionServicioPublico}`}
                                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                                >
                                                <InputNumber min={1} placeholder="Ingrese la capacidad" />

                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item
                                                label={listaDepositosServiciosPublicos.indexOf(deposito) == 0 ? 'Unidad de medida' : false}
                                                name={`idUnidadMedida-${deposito.idAsignacionServicioPublico}`}
                                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                                >
                                                <Select
                                                    allowClear
                                                    style={{ width: '100%' }}
                                                    placeholder="Seleccione una unidad de medida"
                                                    loading={fetchingListaUnidadesDeMedicionActivos}
                                                    >
                                                    {listaUnidadesDeMedicionActivos.map(option => <Option key={option.idUnidadMedida}>{option.unidadMedida}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Form.Item
                                                label={listaDepositosServiciosPublicos.indexOf(deposito) == 0 ? 'Activo' : false}
                                                name={`activo-${deposito.idAsignacionServicioPublico}`}
                                                >
                                                <Switch checked={this.state.depositosActivos[deposito.idAsignacionServicioPublico]}  onChange={(value) => switchHandle (value, deposito.idAsignacionServicioPublico)}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                ))
                                )}
                            <Row type='flex' justify='center'>
                                <Col span={12}>
                                    <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Editar Asignación de Ubicación y Capacidad</Button>
                                </Col>
                            </Row>
                            </Form>
                            </div>
                        ): false}
                    </Spin>

                </Modal>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        fetchingEditarAsignacionSP: state.ServiciosPublicosReducer.fetchingEditarAsignacionSP,
        showModalEditarAsignacionSP: state.ServiciosPublicosReducer.showModalEditarAsignacionSP,
        page: state.ServiciosPublicosReducer.page,
        busqueda: state.ServiciosPublicosReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaUbicacionesFiltro: state.CatalogosGeneralesReducer.fetchingListaUbicacionesFiltro,
        listaUbicacionesFiltro: state.CatalogosGeneralesReducer.listaUbicacionesFiltro,
        fetchingListaUnidadesDeMedicionActivos: state.AlmacenesReducer.fetchingListaUnidadesDeMedicionActivos,
        listaUnidadesDeMedicionActivos: state.AlmacenesReducer.listaUnidadesDeMedicionActivos,
        fetchingListaDepositosServiciosPublicos: state.ServiciosPublicosReducer.fetchingListaDepositosServiciosPublicos,
        listaDepositosServiciosPublicos: state.ServiciosPublicosReducer.listaDepositosServiciosPublicos,
        listaDepositosSPObjeto: state.ServiciosPublicosReducer.listaDepositosSPObjeto,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onShowModalEditarAsignacion: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_ASIGNACION_DEPOSITOS', showModalEditarAsignacionSP:false})
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
        },
        onListaUbicacionesFiltro: (busqueda) => {
            dispatch({ type: 'LISTA_UBICACIONES_FILTRO_REQUEST', busqueda: busqueda });
        },
        onListaUbicacionesFiltroLimpiar: () => {
            dispatch({ type: 'LISTA_UBICACIONES_FILTRO_SUCCESS', listaUbicacionesFiltro: [] });
        },
        onEditarAsignacion: (idServicioPublico, formulario, page, busqueda) => {
            dispatch({ type: 'EDITAR_ASIGNACION_SERVICIOS_PUBLICOS_REQUEST', idServicioPublico: idServicioPublico, formulario: formulario, page: page, busqueda: busqueda});
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalEditarAsignacionDeposito);