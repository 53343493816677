import React, { Component } from 'react';
import {Row, Col, Modal, Select, Form, Button, InputNumber, message, Input} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};


class ModalCrearNumeros extends Component {
    formRef = React.createRef();
    render() { 
        const {
            showModalCrearNumeros,
            herrSeleccionada,
            onCerrarModalCrearNumeros,
            onCrearNumerosRequest,
            listaNumerosInventarioPrestados,
            listaSinRepetir,
            numerosInventarioDisponibles,
            numerosInventarioDisponiblesCantidad,
        } = this.props;

        const handleCancel = () => {
            onCerrarModalCrearNumeros();
            this.formRef.current.resetFields()
        };

        const onFinish = (formulario) => {
            console.log('formulario', formulario)

            onCrearNumerosRequest(formulario, herrSeleccionada);
            onCerrarModalCrearNumeros();
            this.formRef.current.resetFields()
        };
        
        const onProductoSeleccionado = (e, field) => {
            let value = e.target.value
            let formulario = this.formRef.current.getFieldsValue('herramientas')

            console.log(value, field, formulario, listaSinRepetir);

            if(numerosInventarioDisponibles) {
                for(let i in numerosInventarioDisponibles){
                    if(numerosInventarioDisponibles[i].numeroInventario == value){
                        formulario.herramientas[field.name].codigoInventario = null;
                        message.warning('La herramienta se encuentra en un prestamo o una baja')
                        this.formRef.current.setFieldsValue({'herramientas': formulario.herramientas});
                    }
                }
            }


            let repetido = false;
            if(listaSinRepetir){
                for(let i=0; i<listaSinRepetir.length; i++){
                    if(listaSinRepetir[i] === value){
                        if(listaSinRepetir[field]){
                            console.log('entra al if')
                            formulario.herramientas[field.key].codigoInventario = listaSinRepetir[field.key];
                            message.warning('No es posible seleccionar una herramienta que ya esté en la lista');
                            this.formRef.current.setFieldsValue({'herramientas': formulario.herramientas});
                            repetido = true;
                        }
                        formulario.herramientas[field.name].codigoInventario = null;
                        message.warning('No es posible seleccionar una herramienta que ya esté en la lista');
                        this.formRef.current.setFieldsValue({'herramientas': formulario.herramientas});
                        repetido = true;
                        break;
                    }
                }
            }
            
            if(!repetido){
                let listaActualizada = [];
                for(let x=0; x<formulario.herramientas.length; x++){
                    listaActualizada.push(formulario.herramientas[x]?.codigoInventario ?? 'undefined'); //
                }
                this.props.onActualizarListaSinRepetir(listaActualizada)
            }
             
        }
        return ( 
            <div>
                <Modal
                    visible={showModalCrearNumeros}
                    title='Crear Numeros de Inventario'
                    onCancel={handleCancel}
                    footer={false}
                    width={'40%'}
                >
                    <Row justify='space-between'> 
                        <Col span={24}>
                            
                            <Form {...layout}
                                name="formulario"
                                onFinish={onFinish}
                                ref={this.formRef}
                            >
                                <Form.List name="herramientas">
                                    {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(field => (
                                        <Row justify='space-around' key={field.key}>
                                            <Col span={18}>
                                                <Form.Item
                                                    {...field}
                                                    label='Codigo Inventario'
                                                    name={[field.name, 'codigoInventario']}
                                                    fieldKey={[field.fieldKey, 'codigoInventario']}
                                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                >
                                                    <Input 
                                                        maxLength={100}
                                                        onChange={e => onProductoSeleccionado(e, field)} />
                                                </Form.Item>
                                            </Col>
                                            
                                            <Col span={2}>
                                                {fields.length > 1 ? (
                                                <Form.Item>
                                                    <MinusCircleOutlined className='borrarInsumo' onClick={() => remove(field.name)} />
                                                </Form.Item>
                                                ):false}
                                            </Col>
                                        </Row>
                                        ))}
                                        {herrSeleccionada && herrSeleccionada.cantidad - numerosInventarioDisponiblesCantidad > fields.length ? (
                                            <Form.Item>
                                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                    Agregar Herramienta
                                                </Button>
                                            </Form.Item>
                                        ): false }
                                        
                                        
                                    </>
                                    )}
                                </Form.List>
                                <Row justify='space-around'>
                                    <Col span={11} className='marginBoton'>
                                        <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Guardar Herramientas</Button>
                                    </Col>  
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Modal>    
            </div>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        page: state.AlmacenesReducer.page,
        busqueda: state.AlmacenesReducer.busqueda,
        showModalCrearNumeros: state.AlmacenesReducer.showModalCrearNumeros,
        herrSeleccionada: state.AlmacenesReducer.herrSeleccionada,
        listaNumerosInventarioPrestados: state.AlmacenesReducer.listaNumerosInventarioPrestados,
        listaSinRepetir: state.ServiciosPlanificadosReducer.listaSinRepetir,
        numerosInventarioDisponibles : state.AlmacenesReducer.numerosInventarioDisponibles,
        numerosInventarioDisponiblesCantidad: state.AlmacenesReducer.numerosInventarioDisponiblesCantidad,
};
};

const mapDispatchToProps = dispatch => {
    return {
        onCerrarModalCrearNumeros: () => {
            dispatch({ type: 'MODAL_CREAR_NUMEROS_INVENTARIO', showModalCrearNumeros: false })
        },
        onCrearNumerosRequest: (formulario, herrSeleccionada) => {
            dispatch({ type: 'CREAR_NUMEROS_INVENTARIO_FOLIO_REQUEST', formulario, herrSeleccionada})
        },
        onActualizarListaSinRepetir: (listaSinRepetir) => {
            dispatch({ type: "ACTUALIZAR_LISTA_INSUMOS_SIN_REPETIR",  listaSinRepetir });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCrearNumeros);