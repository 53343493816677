import React, { Component } from 'react';
import {Row, Col, Modal, Form, Spin, Input, DatePicker, Button} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import {connect} from 'react-redux';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalReprogramarTarea extends Component {
    formRef = React.createRef();
    render() { 
        const {showModalReprogramarTarea, tareaSeleccionadaReprogramacion,
               fetchingReprogramarTarea, onCerrarModal, onReprogramarTarea,
               page, busqueda, tareaSeleccionada 
            } = this.props;
            
        const handleCancel = () => {
            onCerrarModal();
        };
        
        const onFinish = (formulario) => {
            console.log('formulario', formulario);
            onReprogramarTarea(formulario, tareaSeleccionadaReprogramacion, page, busqueda, tareaSeleccionada.idRutina);
            this.formRef.current.resetFields();
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo);
        };
        return (
            <Modal
                visible={showModalReprogramarTarea}
                title={'Reprogramar Tarea'}
                onCancel={handleCancel}
                footer={false}>
                <Spin spinning={fetchingReprogramarTarea}>

                    <Form {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        
                        ref={this.formRef}>
                        <Row type='flex' justify='space-around'>
                            <Col span={24}>
                                <Form.Item
                                    label="Motivo de Reprogramación"
                                    name="observacion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Input
                                        pattern="^^[ñíóáéú a-zA-Z 0-9,.]+$"
                                        title="Nombre debe tener letras y numeros."
                                        maxLength={150} placeholder='Ingrese Motivo de Reprogramación' />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Fecha de Reprogramación"
                                    name="fechaReprogramacion"
                                >
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        placeholder='Fecha de reprogramación'
                                        format='DD-MM-YYYY' />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Button className="btnFiltrar" htmlType="submit">Guardar Cambios</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}
 
const mapStateToProps = (state) => {
    return {
        showModalReprogramarTarea: state.ServiciosPlanificadosReducer.showModalReprogramarTarea,
        fetchingReprogramarTarea: state.ServiciosPlanificadosReducer.fetchingReprogramarTarea,
        tareaSeleccionadaReprogramacion: state.ServiciosPlanificadosReducer.tareaSeleccionadaReprogramacion,
        tareaSeleccionada: state.ServiciosPlanificadosReducer.tareaSeleccionada,
        page: state.ServiciosPlanificadosReducer.page,
        busqueda: state.ServiciosPlanificadosReducer.busqueda,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCerrarModal: () => {
            dispatch({ type: "SHOW_MODAL_REPROGRAMAR_TAREA_ORDEN" , showModalReprogramarTarea: false, tareaSeleccionadaReprogramacion: null})
        },
        onReprogramarTarea: (formulario, tareaSeleccionadaReprogramacion, page, busqueda, idRutina ) => {
            dispatch({ type: "REPROGRAMAR_TAREA_ORDEN_REQUEST", formulario, tareaSeleccionadaReprogramacion, page, busqueda, idRutina})
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalReprogramarTarea);