import React, { Component } from 'react';
import LoginContainer from "./LoginContainer";
import PapantlaApp from "./PapantlaApp";

import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect
} from 'react-router-dom'

import { connect } from "react-redux";

class App extends Component {

	render() {
		// var http = require("http");
		// setInterval(function() {
		// 	http.get("https://papantla-demo.herokuapp.com");
		// }, 200000);

		const isAuthenticatedSession = sessionStorage.getItem('isAuthenticated');
		
		return (

			<Router>
				<Switch>
					<Route exact path="/login" component={LoginContainer} />
					<Route exact path="/" component={LoginContainer} />
					<PrivateRoute isAuthenticated={isAuthenticatedSession} path="/dashboard" component={PapantlaApp} />
					<Redirect to="/login" />
				</Switch>
			</Router>
		);
	}
}

const PrivateRoute = ({ component: Component,isAuthenticated, ...rest }) => (
 	<Route
		{...rest}
		render={props => (
			isAuthenticated
			? (<Component {...props} />)
			: (<Redirect to='/login' />)
		)}
 	/>
); 

const mapStateToProps = state => {
	return {
		isAuthenticated: state.LoginReducer.isAuthenticated,
	};
};

export default connect(mapStateToProps)(App);