import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {Form, Table, Row, Col, Button, Alert, DatePicker, Card, Select, message, Popover} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import TablaTareas from './TablaTareas';
const {Option} = Select;
const { RangePicker } = DatePicker;

class VistaCalendarioSP extends React.Component {
    formRef = React.createRef();
    state = {
        page : 1,
        idServicio: "",
        idPartida: "",
        idUbicacion: "",
        programa: "",
        fecha: null,
        mes: null,
    }

    componentDidMount () {
       this.props.onGetTareas("", "", "", null, "", 1); this.props.onGetServiciosActivos();
    }

    componentDidUpdate(prevProps) {
        if(this.props.listaTareasVistaCalendarioBusqueda !== prevProps.listaTareasVistaCalendarioBusqueda) {
            if(this.props.programacionSeleccionadaVC) {
                console.log('Se Actualiza Componente');
                //Se actualizan tareas seleccionadas
                const listaTareasVistaCalendarioBusqueda = this.props.listaTareasVistaCalendarioBusqueda;
                const programacionSeleccionadaVC = this.props.programacionSeleccionadaVC;
                const {idServicio, idPartida, idUbicacion, fecha, programa, page, mes} = this.state;
                let tareasActualizar = [];
                for (let programacion of listaTareasVistaCalendarioBusqueda) {
                    if(programacion.idProgramacionRutina === programacionSeleccionadaVC[0].idProgramacionRutina){
                        tareasActualizar = programacion.tareasMes[mes].filter(item => {
                            const findTarea = programacionSeleccionadaVC.find(tarea => tarea.idFechaRutina === item.idFechaRutina);
                            return findTarea;
                        });
                    }
                }
                const busqueda = {idServicio, idPartida, idUbicacion, fecha, programa}
                this.props.onModalReprogramarTareaVC(tareasActualizar, busqueda, page);
            }
        }
    }

    render() {
        const {listaTareasVistaCalendarioBusqueda,listaTareasVistaCalendarioBusquedaCantidad,fetchingListaTareasVistaCalendarioBusqueda,fetchingListaServiciosFiltro,listaServiciosFiltro,
            fetchingUbicacionesSinHijos,ubicacionesSinHijos,
            onListaServiciosFiltro,onListaServiciosFiltroLimpiar,onListaUbicacionesFiltro,onListaUbicacionesFiltroLimpiar,onGetTareas, onModalReprogramarTareaVC
        } = this.props;

        const {page, idServicio, idPartida, idUbicacion, programa, fecha} = this.state;
        
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const currentYear = new Date();
        const year = currentYear.getFullYear();
        let eneroW = moment(`${year}-01`).weeks();
        const febreroW = moment(`${year}-02`).weeks();
        const marzoW = moment(`${year}-03`).weeks();
        const abrilW = moment(`${year}-04`).weeks();
        const mayoW = moment(`${year}-05`).weeks();
        const junioW = moment(`${year}-06`).weeks();
        const julioW = moment(`${year}-07`).weeks();
        const agostoW = moment(`${year}-08`).weeks();
        const septiembreW = moment(`${year}-09`).weeks();
        const octubreW = moment(`${year}-10`).weeks();
        const noviembreW = moment(`${year}-11`).weeks();
        const diciembreW = moment(`${year}-12`).weeks();
        const ultimoDia = moment(`${year}-12-31`).weeks();

        const febreroD = moment(`${year}-02`).day();
        const marzoD = moment(`${year}-03`).day();
        const abrilD = moment(`${year}-04`).day();
        const mayoD = moment(`${year}-05`).day();
        const junioD = moment(`${year}-06`).day();
        const julioD = moment(`${year}-07`).day();
        const agostoD = moment(`${year}-08`).day();
        const septiembreD = moment(`${year}-09`).day();
        const octubreD = moment(`${year}-10`).day();
        const noviembreD = moment(`${year}-11`).day();        
        const diciembreD = moment(`${year}-12`).day();        

        eneroW = eneroW !== 1 ? 0 : eneroW;
        const eneroST = febreroD !== 1 ? febreroW - eneroW + 1 : febreroW - eneroW;
        const febreroST = marzoD !==1 ? marzoW - febreroW + 1 : marzoW - febreroW;
        const marzoST = abrilD !==1 ?  abrilW - marzoW + 1 : abrilW - marzoW;
        const abrilST = mayoD !==1 ?  mayoW - abrilW + 1 : mayoW - abrilW;
        const mayoST = junioD !==1 ? junioW - mayoW + 1 : junioW - mayoW;
        const junioST = julioD !==1 ? julioW - junioW + 1 : julioW - junioW;
        const julioST = agostoD !==1 ? agostoW - julioW + 1 : agostoW - julioW;
        const agostoST = septiembreD !==1 ? septiembreW - agostoW + 1 : septiembreW - agostoW;
        const septiembreST = octubreD !==1 ? octubreW - septiembreW + 1 : octubreW - septiembreW;
        const octubreST = noviembreD !==1 ? noviembreW - octubreW + 1 : noviembreW - octubreW;
        const noviembreST = diciembreD !==1 ? diciembreW - noviembreW + 1 : diciembreW - noviembreW;
        const diciembreST = ultimoDia === 1 ? 53 - diciembreW +1 :ultimoDia - diciembreW + 1;

        const semanasMes = (semanas, semanaInicial, mes) => {
            let semanasMes = [];
            for (let semana of semanas) {
                if( mes === 11){
                    semana = semana === 1 ? 53 : semana;  //condicion diciembre
                }
                if( mes === 0) {
                    semana = semana === 53 ? 0 : semana;  //condicion enero semana 53
                }
                semana = semana - semanaInicial +1;
                semanasMes.push(semana);
            }
            return semanasMes;
        }

        const divisiones = (semanas, record, mes) => {
            let arreglo = [];
            let semanasEjecucion = null;
            
            switch (mes) {
                case 0:
                    // console.log(semanasMes(record.semanasMes['0'], eneroW))
                    semanasEjecucion = semanasMes(record.semanasMes['0'], eneroW, 0);
                    break;
                case 1:
                    semanasEjecucion = semanasMes(record.semanasMes['1'], febreroW, 1);
                    break;
                case 2:
                    semanasEjecucion = semanasMes(record.semanasMes['2'], marzoW, 2);
                    break;
                case 3:
                    semanasEjecucion = semanasMes(record.semanasMes['3'], abrilW, 3);
                    break;
                case 4:
                    semanasEjecucion = semanasMes(record.semanasMes['4'], mayoW, 4);
                    break;
                case 5:
                    semanasEjecucion = semanasMes(record.semanasMes['5'], junioW, 5);
                    break;
                case 6:
                    semanasEjecucion = semanasMes(record.semanasMes['6'], julioW, 6);
                    break;
                case 7:
                    semanasEjecucion = semanasMes(record.semanasMes['7'], agostoW, 7);
                    break;
                case 8:
                    semanasEjecucion = semanasMes(record.semanasMes['8'], septiembreW, 8);
                    break;
                case 9:
                    semanasEjecucion = semanasMes(record.semanasMes['9'], octubreW, 9);
                    break;
                case 10:
                    semanasEjecucion = semanasMes(record.semanasMes['10'], noviembreW, 10);
                    break;
                case 11:
                    semanasEjecucion = semanasMes(record.semanasMes['11'], diciembreW, 11);
                    break;
                default:
                    semanasEjecucion = null;
            }

            const colSpan = semanas < 5 ? 5 : semanas;
            if(semanasEjecucion.length > 0) {
                const semanasEjecucionU = new Set(semanasEjecucion);
                const semanasEjecucionArray = [...semanasEjecucionU];
                for (let i=1; i<=semanas;i++) {
                    const semanaFind = semanasEjecucionArray.indexOf(i);
                    if(semanaFind > -1) {
                        arreglo.push(<Col span={Math.trunc(23/colSpan)}><Button className="btnSemanaData" size='small' onClick={() => tareasModal(i, mes, record)}>{`S${i}`}</Button></Col>)
                        continue;
                    }
    
                    arreglo.push(<Col span={Math.trunc(23/colSpan)}><Button disabled className="btnSemana" size='small'>{`S${i}`}</Button></Col>)
                }
            } else {
                for (let i=1; i<=semanas;i++) {    
                    arreglo.push(<Col span={Math.trunc(23/colSpan)}><Button disabled className="btnSemana" size='small'>{`S${i}`}</Button></Col>)
                }
            }
            return arreglo;
        }
        
        const columns = [{
            title: 'Categoría',
            dataIndex: 'categoria',
            key: 'categoria',
            // width: '8%'
        },{
            title: 'Nomenclatura',
            dataIndex: 'nomenclatura',
            key: 'nomenclatura',
            // width: '4%'
        },{
            title: 'Partida',
            dataIndex: 'partida',
            key: 'partida',
            // width: '4%'
        },{
            title: 'Programa',
            dataIndex: 'programa',
            key: 'programa',
            // width: '4%'
        },{
            title: 'Nombre de rutina',
            dataIndex: 'rutina',
            key: 'rutina',
            // width: '8%'
        },{
            title: 'Tipo',
            dataIndex: 'tipoRutina',
            key: 'tipoRutina',
            // width: '4%'
        },{
            title: 'Ubicación',
            dataIndex: 'ubicacion',
            key: 'ubicacion',
            width: 250
        },{
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
            // width: '4%'
        },{
            title: 'Descripción',
            dataIndex: 'descripcion',
            key: 'descripcion',
            // width: '8%'
        },{
            title: 'Enero',
            dataIndex: 'enero',
            key: 'enero',
            // width: '4%',
            align: 'center',
            render: (text, record) => (
                <Row type='flex' justify='space-around'>
                    {divisiones(eneroST, record, 0)}
                </Row>
                
            ),
            },{
            title: 'Febrero',
            dataIndex: 'febrero',
            key: 'febrero',
            // width: '4%',
            align: 'center',
            render: (text, record) => (
                <Row type='flex' justify='space-around'>
                    {divisiones(febreroST, record, 1)}
                </Row>
                
            ),
        },{
            title: 'Marzo',
            dataIndex: 'marzo',
            key: 'marzo',
            // width: '4%',
            align: 'center',
            render: (text, record) => (
                <Row type='flex' justify='space-around'>
                    {divisiones(marzoST, record, 2)}
                </Row>
                
            ),
        },{
            title: 'Abril',
            dataIndex: 'abril',
            key: 'abril',
            // width: '4%',
            align: 'center',
            render: (text, record) => (
                <Row type='flex' justify='space-around'>
                    {divisiones(abrilST, record, 3)}
                </Row>
                
            ),
        },{
            title: 'Mayo',
            dataIndex: 'mayo',
            key: 'mayo',
            // width: '4%',
            align: 'center',
            render: (text, record) => (
                <Row type='flex' justify='space-around'>
                    {divisiones(mayoST, record, 4)}
                </Row>
                
            ),
        },{
            title: 'Junio',
            dataIndex: 'junio',
            key: 'junio',
            // width: '4%',
            align: 'center',
            render: (text, record) => (
                <Row type='flex' justify='space-around'>
                    {divisiones(junioST, record, 5)}
                </Row>
                
            ),
        },{
            title: 'Julio',
            dataIndex: 'julio',
            key: 'julio',
            // width: '4%',
            align: 'center',
            render: (text, record) => (
                <Row type='flex' justify='space-around'>
                    {divisiones(julioST, record, 6)}
                </Row>
                
            ),
        },{
            title: 'Agosto',
            dataIndex: 'agosto',
            key: 'agosto',
            // width: '4%',
            align: 'center',
            render: (text, record) => (
                <Row type='flex' justify='space-around'>
                    {divisiones(agostoST, record, 7)}
                </Row>
                
            ),
        },{
            title: 'Septiembre',
            dataIndex: 'septiembre',
            key: 'septiembre',
            // width: '4%',
            align: 'center',
            render: (text, record) => (
                <Row type='flex' justify='space-around'>
                    {divisiones(septiembreST, record, 8)}
                </Row>
                
            ),
        },{
            title: 'Octubre',
            dataIndex: 'octubre',
            key: 'octubre',
            // width: '4%',
            align: 'center',
            render: (text, record) => (
                <Row type='flex' justify='space-around'>
                    {divisiones(octubreST, record, 9)}
                </Row>
                
            ),
        },{
            title: 'Noviembre',
            dataIndex: 'noviembre',
            key: 'noviembre',
            // width: '4%',
            align: 'center',
            render: (text, record) => (
                <Row type='flex' justify='space-around'>
                    {divisiones(noviembreST, record, 10)}
                </Row>
                
            ),
        },{
            title: 'Diciembre',
            dataIndex: 'diciembre',
            key: 'diciembre',
            // width: '4%',
            align: 'center',
            render: (text, record) => (
                <Row type='flex' justify='space-around'>
                    {divisiones(diciembreST, record, 11)}
                </Row>
                
            ),
        }];

        const buscarServicios = value => {
            if (value.length >= 3) {
                if (value) {
                    console.log(value);
                    onListaServiciosFiltro(value)
                } else {
                    message.warning('No se encontró servicio con esas palabras')
                }
            } else {
                onListaServiciosFiltroLimpiar();
            }
        }

        const handleSearch = (value) => {
            if(value.length>=3){
                if (value) {
                    console.log(value)
                    onListaUbicacionesFiltro(value);
                } else {
                    message.warning('No se encontró ubicación con esas palabras')
                }
            }
            else{
                onListaUbicacionesFiltroLimpiar();
            }
        };

        const handleSelect = (value, field) => {
            // this.setState({[field]: value}, () => onGetTareas(this.state.idServicio, this.state.idUbicacion, fecha, this.state.programa, page));

            if(field === 'idServicio'){
                if(value){
                    this.props.onGetPartidasByIdServicio(value.split(' - ')[0]);
                    this.formRef.current.resetFields(['idPartida']);
                } else {
                    this.props.onCleanPartidasByIdServicio();
                    this.formRef.current.resetFields(['idPartida']);
                }
                this.setState({[field]: value, page:1, idPartida:""}, () => onGetTareas(this.state.idServicio, "", this.state.idUbicacion, fecha, this.state.programa, 1));
            } else {
                this.setState({[field]: value, page:1}, () => onGetTareas(this.state.idServicio, this.state.idPartida, this.state.idUbicacion, fecha, this.state.programa, 1));
            }
        }

        const onChangeDate = value => {
            this.setState({fecha: value}, () => onGetTareas(idServicio, idPartida, idUbicacion, this.state.fecha, programa, page));
        }

        const disabledRange = fecha => {
            const currentYear = new Date();
            const year = currentYear.getFullYear();
            const fechaVigencia = moment(`1/1/${year+1}`, "DD-MM-YYYY")
            const fechaInicio = moment(`1/1/${year}`, "DD-MM-YYYY")
            if (fecha && fecha < fechaInicio) {
                return fecha && fecha < fechaInicio;
            } else {
                return fecha && fecha > fechaVigencia;
            }
        }

        const tareasModal = (semana, mes, record) => {
            this.setState({mes:mes});
            const semanaMesAnual = {
                0: eneroW, 1: febreroW, 2: marzoW, 3: abrilW, 4: mayoW, 5: junioW, 6: julioW,
                7: agostoW, 8: septiembreW, 9: octubreW, 10: noviembreW, 11: diciembreW
            };
            let semanaMes = semanaMesAnual[mes];
            if( mes === 0) {
                semanaMes = semanaMes === 53 ? 0 : semanaMes;  //condicion enero semana 53
            }
            const semanaSelecionada = semanaMes + semana - 1;
            const tareasSemana = record.tareasMes[mes].filter(item => {
                const fechaEjecucion = item.fecha.split('T')[0];
                let semanaEjecucion = moment(fechaEjecucion).weeks();
                if( mes === 0) {
                    semanaEjecucion = semanaEjecucion === 53 ? 0 : semanaEjecucion;
                }
                if( mes === 11) {
                    semanaEjecucion = semanaEjecucion === 1 ? 53 : semanaEjecucion;
                }
                const semanaItem = semanaEjecucion === semanaSelecionada ? true: false;
                return semanaItem;
            })

            console.log(semana, mes, record, semanaSelecionada);
            console.log(tareasSemana);
            const busqueda = {idServicio, idUbicacion, fecha, programa}
            onModalReprogramarTareaVC(tareasSemana, busqueda, page);

        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            onGetTareas(idServicio, idPartida, idUbicacion, fecha, programa, pagination.current)
        }

        const scroll = {"x": 2900 | true, "y": 1000};

        const onPartidaSelect = (value, field) => {
            if(value){
                onGetTareas(idServicio, value.split(' - ')[0], idUbicacion, fecha, programa, 1)
                this.setState({idPartida: value.split(' - ')[0]})
            } else {
                onGetTareas(idServicio, '', idUbicacion, fecha, programa, 1);
                this.setState({ idPartida: ''})
                this.formRef.current.resetFields(['idPartida']);
            }
        };

        return (
            <div>
                <Card className="cardCatalogos" title={<div className='headerLista'>Vista Calendario de Rutinas y Mantenimientos</div>}>
                <Form name="formulario" ref={this.formRef}>
                    <Row justify='space-between' style={{ marginBottom: '20px' }} gutter={[8,8]}>
                        {/* <Col span={11}>
                            <Select
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Selecciona un servicio"
                                loading={fetchingListaServiciosFiltro}
                                showSearch
                                filterOption={false}
                                onSearch={buscarServicios}
                                onChange={value => handleSelect(value, 'idServicio')}
                                notFoundContent={null}
                            >
                                {listaServiciosFiltro.map(option => <Option key={option.idServicio + '-' + option.nombre}>{option.idServicio + '-' + option.nombre}</Option>)}
                            </Select>
                        </Col> */}
                        <Col span={12}>
                            <Select
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Selecciona un servicio"
                                loading={this.props.fetchingListaServiciosActivosF}
                                showSearch
                                onChange={value => handleSelect(value, 'idServicio')}
                            >
                                {this.props.listaServiciosActivosF.map(option => <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>{option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}</Option>)}
                            </Select>
                        </Col>
                        <Col span={12}>
                            <Form.Item  name="idPartida">
                                <Select
                                    allowClear
                                    showSearch={true}
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona una partidas"
                                    loading={this.props.fetchingListaPartidasByServicio4Fallas}
                                    disabled={!this.props.listaPartidasByServicio4Fallas[0]}
                                    onChange={value => onPartidaSelect(value, 'idPartida')}
                                    value={this.state.partida}
                                >
                                    {this.props.listaPartidasByServicio4Fallas.map(option =>
                                        <Option key={option.idPartida + ' - ' + option.nomenclatura  + ': ' + option.nombre}>
                                            {option.idPartida + ' - ' + option.nomenclatura  + ': ' + option.nombre}
                                        </Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={10}>

                            <Select allowClear
                                style={{ width: '100%' }}
                                placeholder="Ingrese la ubicación"
                                loading={fetchingUbicacionesSinHijos}
                                showSearch
                                filterOption={false}
                                onSearch={handleSearch}
                                onChange={value => handleSelect(value, 'idUbicacion')}
                                notFoundContent={null}
                                dropdownStyle={{ minWidth: "60%" }}
                            >
                                {ubicacionesSinHijos.map(option =>
                                    <Option key={`${option.idUbicacion} - ${option.ubicacionCOA}`} >
                                        <Popover content={option.ubicacionCOA} title={false}>
                                            {`${option.idUbicacion} - ${option.ubicacionCOA}`}
                                        </Popover>
                                    </Option>
                                )}
                            </Select>
                        </Col>
                        <Col span={6}>
                            <Select allowClear onChange={value => handleSelect(value, 'programa')}
                                style={{ width: '100%' }} placeholder="Selecciona un programa">
                                <Option value='Rutina'>Rutina</Option>
                                <Option value='Mantenimiento'>Mantenimiento</Option>
                                <Option value='Prueba'>Prueba</Option>
                            </Select>
                        </Col>
                        <Col span={8}>

                            <RangePicker
                                format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio", "Fecha fin"]}
                                disabledDate={disabledRange}
                                onChange={onChangeDate}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Alert
                                message={"Favor de seleccionar una semana para reprogramar las tareas."}
                                type="info"
                                style={{marginBottom:'16px'}}
                            />
                            <Table
                                size="small"
                                rowKey="rutina"
                                columns={columns}
                                dataSource={listaTareasVistaCalendarioBusqueda}
                                loading={fetchingListaTareasVistaCalendarioBusqueda}
                                locale={{ emptyText: 'Sin datos' }}
                                onChange={handleTableChange}
                                pagination={{ total: listaTareasVistaCalendarioBusquedaCantidad, simple: true, current: this.state.page }}
                                scroll={scroll}
                            />
                        </Col>
                    </Row>
                    <TablaTareas />
                </Form>
                </Card>
            </div>
        )

    }
}

const mapStateToProps = state => {
    return {
        listaTareasVistaCalendarioBusqueda: state.ServiciosPlanificadosReducer.listaTareasVistaCalendarioBusqueda,
        listaTareasVistaCalendarioBusquedaCantidad: state.ServiciosPlanificadosReducer.listaTareasVistaCalendarioBusquedaCantidad,
        fetchingListaTareasVistaCalendarioBusqueda: state.ServiciosPlanificadosReducer.fetchingListaTareasVistaCalendarioBusqueda,
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
        fetchingUbicacionesSinHijos: state.SolicitudDeServiciosReducer.fetchingUbicacionesSinHijos,
        ubicacionesSinHijos: state.SolicitudDeServiciosReducer.ubicacionesSinHijos,
        programacionSeleccionadaVC: state.ServiciosPlanificadosReducer.programacionSeleccionadaVC,

        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        listaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.listaPartidasByServicio4Fallas,
        fetchingListaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.fetchingListaPartidasByServicio4Fallas,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda});
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []});
        },
        onListaUbicacionesFiltro: (busqueda) => {
            dispatch({ type: 'UBICACIONES_SIN_HIJOS_REQUEST', busqueda: busqueda });
        },
        onListaUbicacionesFiltroLimpiar: () => {
            dispatch({ type: 'UBICACIONES_SIN_HIJOS_SUCCESS', ubicacionesSinHijos: [] });
        },
        onGetTareas: (idServicio, idPartida, idUbicacion, fecha, programa, page) => {
            dispatch({ type: 'LISTA_BUSQUEDA_VISTA_CALENDARIO_REQUEST', idServicio: idServicio, idPartida: idPartida, idUbicacion:idUbicacion, fecha:fecha, programa:programa, page:page});
        },
        onModalReprogramarTareaVC: (programacionSeleccionadaVC, busqueda, page) => {
            dispatch({ type: 'SHOW_MODAL_TABLA_TAREAS_VC', showModalTablaTareasVC: true, programacionSeleccionadaVC, busqueda, page});
        },
        onGetServiciosActivos: () => {
            dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
        },

        onGetPartidasByIdServicio: (idServicio) => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_REQUEST', idServicio})
        },
        onCleanPartidasByIdServicio: () => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_SUCCESS', listaPartidasByServicio4Fallas: []})
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (VistaCalendarioSP);