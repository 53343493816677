import * as ServiciosPublicosActions from '../actions/ServiciosPublicosActions';

const initialState = {
     page: null,
     catalogoServiciosPublicos: null,
     busqueda: '',

     // Servicios Públicos
     listaServiciosPublicosActivos: [],
     fetchingListaServiciosPublicosActivos: false,
     fetchingListaServiciosPublicosBusqueda: false,
     listaServiciosPublicosBusqueda: [],
     listaServiciosPublicosBusquedaCantidad: 0,

     showModalNuevoServicioPublico: false,
     fetchingNuevoServicioPublico: false,

     showModalEditarServicioPublico: false,
     fetchingEditarServicioPublico: false,
     servicioPublicoSeleccionado: null,

     // Variables de Medición
     listaVariablesMedicionBusqueda: [],
     listaVariablesMedicionCantidad: null,
     fetchingListaVariablesMedicionBusqueda: false,
     showModalNuevaVariableMedicion: false,
     showModalEditarVariableMedicion: false,
     showModalDetalleVariable: false,
     variableMedicionSeleccionado: [],
     fetchingNuevaVariableMedicion: false,
     fetchingEditarVariableMedicion: false,
     fetchingListaVariablesMedicionActivos: false,
     listaVariablesMedicionActivos: [],

     // Programación y Medición de Consumos
     listaProgramacionMedicionBusqueda: [],
     listaProgramacionMedicionCantidad: null,
     fetchingListaProgramacionMedicionBusqueda: false,
     showModalNuevaProgramacion: false,
     showModalEditarProgramacion: false,
     programacionSeleccionada: [],
     fetchingNuevaProgramacion: false,
     fetchingEditarProgramacion: false,
     cantidadHorarios: null,
     todasVariables: false,

     // Registro de Lecturas
     showModalCapturaConsumos: false,
     showModalPresentacionConsumos: false,
     fetchingCapturarLectura: false,
     listaDepositosByIdServiciosPublicos: [],
     fetchingListaDepositosByIdServiciosPublicos: false,
     fetchingProgramacionByServicio: false,
     programacionByServicio: [],
     programacionHorariosByIdProgramacionConsumo: [],
     fetchingVariableMedicionByProgramacion: false,
     variableMedicionByProgramacion: [],
     fetchingHorariosByServicio: false,
     horariosByServicio: [],
     fetchingRegistroLectura: false,
     fetchingHorariosByServicioAdmin: false,
     horariosByServicioAdmin: [],
     fetchingListaDepositosByIdServiciosPublicosSinProgra: false,
     listaDepositosByIdServiciosPublicosSinProgra: [],
     registroLecturasPresentacion: [],
     fetchingRegistroLecturasPresentacion: false,
     chartData: {},
     optionsData: {},
     consumo1: 0, consumo2: 0, data: [],
     fetchingRegistroLecturaReporte: false,
     listaInitialValueDepos: {},
     idProgramacionHorario: null,
     fetchingVerificacionRegistroLectura: false,
     verificacion: false,

     showModalCapturaAbastecimiento: false,
     fetchingListaAsignacionCapacidadByIdServicioPublico: false,
     listaAsignacionCapacidadByIdServicioPublico: [],
     fetchingCrearAbastecimiento: false,
     fetchingListaVariablesMediconByIdServicioPublico: false,
     listaVariablesMedicionByIdServicioPublico: [],

     fetchingRegistroLecturaPDF: false,

     fetchingRegistroLecturaPresentacionDispo: false,
     fetchingRegistroLecturaPresentacionCons: false,
     registroLecturaPresentacionDispo: [],
     registroLecturaPresentacionCons: [],
     etiquetasPresentacionDispo: [], dataPresentacionDispo: [],
     unidadMedidaDispo: '', dataDescriptionDispo: [],
     etiquetasPresentacionCons: [], dataPresentacionCons: [],
     unidadMedidaCons: '', dataDescriptionCons: [],

     fetchingListaUbicacionesByIdServicioPublico: false,
     listaUbicacionesByIdServicioPublico: [],

     //Asignación de Ubicación y Capacidad
     listaServiciosPublicosAsignadosBusqueda: [],
     listaServiciosPublicosAsignadosCantidad: null,
     fetchingListaServiciosPublicosAsignadosBusqueda: false,
     listaServiciosPublicosNoAsignados: [],
     fetchingListaServiciosPublicosNoAsignados: false,
     
     showModalDetalleDepositosSP: false,
     listaDepositosServiciosPublicos: [],
     listaDepositosSPObjeto: {},
     fetchingListaDepositosServiciosPublicos: false,

     showModalNuevaAsignacionSP: false,
     fetchingNuevaAsignacionSP: false,
     
     showModalEditarAsignacionSP: false,
     fetchingEditarAsignacionSP: false,
     
     // Cálculos de Consumo Diferenciado
     listaServiciosPublicosCalculoBusqueda: [],
     listaServiciosPublicosCalculoCantidad: null,
     fetchingListaServiciosPublicosCalculoBusqueda: false,
     listaServiciosPublicosCalculo: [],
     fetchingListaServiciosPublicosCalculo: false,
     
     showModalDetalleCalculosInterno: false,
     listaDetalleCalculosInterno: [],
     listaDetalleCalculosInternoCantidad: null,
     fetchingListaDetalleCalculosInterno: false,
     servicioPublicoSeleccionadoCCDInterno: null,

     showModalDetalleCalculosFacturacion: false,
     listaDetalleCalculosFacturacion: [],
     listaDetalleCalculosFacturacionCantidad: null,
     fetchingListaDetalleCalculosFacturacion: false,
     servicioPublicoSeleccionadoCCDFacturacion: null,

     showModalCalculoCDInterno: false,
     fetchingCalculoCDInterno: false,
     listaCDInterno: [],           //tabla selección
     fetchingListaCDInterno: false,
     consumoTotal:null,
     lecturasInicioFinArray: [], 
     
     showModalCalculoCDFacturacion: false,
     fetchingCalculoCDFacturacion: false,

     fetchingCalculoCDInternoDescargaPDF: false,
     fetchingCalculoCDInternoDescargaExcel: false,
     fetchingCalculoCDFacturacionDescargaPDF: false,
     fetchingCalculoCDFacturacionDescargaExcel: false,


}

export default (state = initialState, action) => {
     switch (action.type) {
          case ServiciosPublicosActions.CAMBIAR_CATALOGO_SERVICIOS_PUBLICOS:
             return { ...state, catalogoServiciosPublicos: action.catalogoServiciosPublicos };

          // Servicios Públicos 
          case ServiciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_ACTIVOS_REQUEST:
               return { ...state, fetchingListaServiciosPublicosActivos: true };
          case ServiciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaServiciosPublicosActivos: false, listaServiciosPublicosActivos: action.listaServiciosPublicosActivos };
          case ServiciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_ACTIVOS_FAILURE:
               return { ...state, fetchingListaServiciosPublicosActivos: false };

          case ServiciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaServiciosPublicosBusqueda: true };
          case ServiciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaServiciosPublicosBusqueda: false, listaServiciosPublicosBusqueda: action.listaServiciosPublicosBusqueda, listaServiciosPublicosBusquedaCantidad: action.listaServiciosPublicosBusquedaCantidad };
          case ServiciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaServiciosPublicosBusqueda: false };

          case ServiciosPublicosActions.ESTADO_SERVICIO_PUBLICO_REQUEST:
               return { ...state, fetchingListaServiciosPublicosBusqueda: true };
          case ServiciosPublicosActions.ESTADO_SERVICIO_PUBLICO_SUCCESS:
               return { ...state, fetchingListaServiciosPublicosBusqueda: false };
          case ServiciosPublicosActions.ESTADO_SERVICIO_PUBLICO_FAILURE:
               return { ...state, fetchingListaServiciosPublicosBusqueda: false };

          case ServiciosPublicosActions.SHOW_MODAL_NUEVO_SERVICIO_PUBLICO:
               return { ...state, showModalNuevoServicioPublico: action.showModalNuevoServicioPublico, page: action.page, busqueda: action.busqueda };

          case ServiciosPublicosActions.NUEVO_SERVICIO_PUBLICO_REQUEST:
               return { ...state, fetchingNuevoServicioPublico: true };
          case ServiciosPublicosActions.NUEVO_SERVICIO_PUBLICO_SUCCESS:
               return { ...state, fetchingNuevoServicioPublico: false };
          case ServiciosPublicosActions.NUEVO_SERVICIO_PUBLICO_FAILURE:
               return { ...state, fetchingNuevoServicioPublico: false };

          case ServiciosPublicosActions.SHOW_MODAL_EDITAR_SERVICIO_PUBLICO:
               return { ...state, showModalEditarServicioPublico: action.showModalEditarServicioPublico, servicioPublicoSeleccionado: action.servicioPublicoSeleccionado, page: action.page, busqueda: action.busqueda };

          case ServiciosPublicosActions.EDITAR_SERVICIO_PUBLICO_REQUEST:
               return { ...state, fetchingEditarServicioPublico: true };
          case ServiciosPublicosActions.EDITAR_SERVICIO_PUBLICO_SUCCESS:
               return { ...state, fetchingEditarServicioPublico: false };
          case ServiciosPublicosActions.EDITAR_SERVICIO_PUBLICO_FAILURE:
               return { ...state, fetchingEditarServicioPublico: false };

          // Variables de Medición
          case ServiciosPublicosActions.LISTA_VARIABLES_MEDICION_ACTIVOS_REQUEST:
               return { ...state, fetchingListaVariablesMedicionActivos: true };
          case ServiciosPublicosActions.LISTA_VARIABLES_MEDICION_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaVariablesMedicionActivos: false, listaVariablesMedicionActivos: action.listaVariablesMedicionActivos };
          case ServiciosPublicosActions.LISTA_VARIABLES_MEDICION_ACTIVOS_FAILURE:
               return { ...state, fetchingListaVariablesMedicionActivos: false };

          case ServiciosPublicosActions.LISTA_VARIABLES_MEDICION_REQUEST:
               return { ...state, fetchingListaVariablesMedicionBusqueda: true };
          case ServiciosPublicosActions.LISTA_VARIABLES_MEDICION_SUCCESS:
               return { ...state, fetchingListaVariablesMedicionBusqueda: false, listaVariablesMedicionBusqueda: action.listaVariablesMedicionBusqueda, listaVariablesMedicionCantidad: action.listaVariablesMedicionCantidad, page: action.page };
          case ServiciosPublicosActions.LISTA_VARIABLES_MEDICION_FAILURE:
               return { ...state, fetchingListaVariablesMedicionBusqueda: false };

          case ServiciosPublicosActions.ESTADO_VARIABLES_MEDICION_REQUEST:
               return { ...state, fetchingListaVariablesMedicionBusqueda: true };
          case ServiciosPublicosActions.ESTADO_VARIABLES_MEDICION_SUCCESS:
               return { ...state, fetchingListaVariablesMedicionBusqueda: false };
          case ServiciosPublicosActions.ESTADO_VARIABLES_MEDICION_FAILURE:
               return { ...state, fetchingListaVariablesMedicionBusqueda: false };

          case ServiciosPublicosActions.SHOW_MODAL_NUEVA_VARIABLE_MEDICION:
               return { ...state, showModalNuevaVariableMedicion: action.showModalNuevaVariableMedicion, page: action.page };

          case ServiciosPublicosActions.SHOW_MODAL_EDITAR_VARIABLE_MEDICION:
               return { ...state, showModalEditarVariableMedicion: action.showModalEditarVariableMedicion, variableMedicionSeleccionado: action.variableMedicionSeleccionado, page: action.page, busqueda: action.busqueda };

          case ServiciosPublicosActions.SHOW_MODAL_DETALLE_VARIABLE:
               return { ...state, showModalDetalleVariable: action.showModalDetalleVariable, variableMedicionSeleccionado: action.variableMedicionSeleccionado, page: action.page };

          case ServiciosPublicosActions.NUEVA_VARIABLE_MEDICION_REQUEST:
               return { ...state, fetchingNuevaVariableMedicion: true };
          case ServiciosPublicosActions.NUEVA_VARIABLE_MEDICION_SUCCESS:
               return { ...state, fetchingNuevaVariableMedicion: false, page: action.page };
          case ServiciosPublicosActions.NUEVA_VARIABLE_MEDICION_FAILURE:
               return { ...state, fetchingNuevaVariableMedicion: false };

          case ServiciosPublicosActions.EDITAR_VARIABLES_MEDICION_REQUEST:
               return { ...state, fetchingEditarVariableMedicion: true };
          case ServiciosPublicosActions.EDITAR_VARIABLES_MEDICION_SUCCESS:
               return { ...state, fetchingEditarVariableMedicion: false };
          case ServiciosPublicosActions.EDITAR_VARIABLES_MEDICION_FAILURE:
               return { ...state, fetchingEditarVariableMedicion: false };

          // Programación y Medición de Consumos
          case ServiciosPublicosActions.LISTA_PROGRAMACION_MEDICION_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaProgramacionMedicionBusqueda: true };
          case ServiciosPublicosActions.LISTA_PROGRAMACION_MEDICION_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaProgramacionMedicionBusqueda: false, listaProgramacionMedicionBusqueda: action.listaProgramacionMedicionBusqueda, listaProgramacionMedicionCantidad: action.listaProgramacionMedicionCantidad, page: action.page };
          case ServiciosPublicosActions.LISTA_PROGRAMACION_MEDICION_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaProgramacionMedicionBusqueda: false };

          case ServiciosPublicosActions.ESTADO_PROGRAMACION_MEDICION_REQUEST:
               return { ...state, fetchingListaProgramacionMedicionBusqueda: true };
          case ServiciosPublicosActions.ESTADO_PROGRAMACION_MEDICION_SUCCESS:
               return { ...state, fetchingListaProgramacionMedicionBusqueda: false };
          case ServiciosPublicosActions.ESTADO_PROGRAMACION_MEDICION_FAILURE:
               return { ...state, fetchingListaProgramacionMedicionBusqueda: false };

          case ServiciosPublicosActions.SHOW_MODAL_NUEVA_PROGRAMACION_MEDICION:
               return { ...state, showModalNuevaProgramacion: action.showModalNuevaProgramacion, page: action.page };

          case ServiciosPublicosActions.SHOW_MODAL_EDITAR_PROGRAMACION_MEDICION:
               return { ...state, showModalEditarProgramacion: action.showModalEditarProgramacion, programacionSeleccionada: action.programacionSeleccionada, page: action.page, busqueda: action.busqueda, cantidadHorarios: action.cantidadHorarios, todasVariables: action.todasVariables };

          case ServiciosPublicosActions.NUEVA_PROGRAMACION_MEDICION_REQUEST:
               return { ...state, fetchingNuevaProgramacion: true };
          case ServiciosPublicosActions.NUEVA_PROGRAMACION_MEDICION_SUCCESS:
               return { ...state, fetchingNuevaProgramacion: false, page: action.page };
          case ServiciosPublicosActions.NUEVA_PROGRAMACION_MEDICION_FAILURE:
               return { ...state, fetchingNuevaProgramacion: false };

          case ServiciosPublicosActions.EDITAR_PROGRAMACION_MEDICION_REQUEST:
               return { ...state, fetchingEditarProgramacion: true };
          case ServiciosPublicosActions.EDITAR_PROGRAMACION_MEDICION_SUCCESS:
               return { ...state, fetchingEditarProgramacion: false };
          case ServiciosPublicosActions.EDITAR_PROGRAMACION_MEDICION_FAILURE:
               return { ...state, fetchingEditarProgramacion: false };

          // Registro de Lecturas
          case ServiciosPublicosActions.SHOW_MODAL_CAPTURA_CONSUMOS:
               return { ...state, showModalCapturaConsumos: action.showModalCapturaConsumos, servicioPublicoSeleccionado: action.servicioPublicoSeleccionado, page: action.page, busqueda: action.busqueda };

          case ServiciosPublicosActions.LISTA_DEPOSITO_BY_SERVICIO_REQUEST:
               return { ...state, fetchingListaDepositosByIdServiciosPublicos: true };
          case ServiciosPublicosActions.LISTA_DEPOSITO_BY_SERVICIO_SUCCESS:
               return { ...state, fetchingListaDepositosByIdServiciosPublicos: false, listaDepositosByIdServiciosPublicos: action.listaDepositosByIdServiciosPublicos, listaInitialValueDepos: action.listaInitialValueDepos, idProgramacionHorario: action. idProgramacionHorario };
          case ServiciosPublicosActions.LISTA_DEPOSITO_BY_SERVICIO_FAILURE:
               return { ...state, fetchingListaDepositosByIdServiciosPublicos: false };

          case ServiciosPublicosActions.LISTA_DEPOSITO_BY_SERVICIO_SIN_PROGRA_REQUEST:
               return { ...state, fetchingListaDepositosByIdServiciosPublicosSinProgra: true };
          case ServiciosPublicosActions.LISTA_DEPOSITO_BY_SERVICIO_SIN_PROGRA_SUCCESS:
               return { ...state, fetchingListaDepositosByIdServiciosPublicosSinProgra: false, listaDepositosByIdServiciosPublicosSinProgra: action.listaDepositosByIdServiciosPublicosSinProgra };
          case ServiciosPublicosActions.LISTA_DEPOSITO_BY_SERVICIO_SIN_PROGRA_FAILURE:
               return { ...state, fetchingListaDepositosByIdServiciosPublicosSinProgra: false };
               
          case ServiciosPublicosActions.SHOW_MODAL_PRESENTACION_CONSUMOS:
               return { ...state, showModalPresentacionConsumos: action.showModalPresentacionConsumos, servicioPublicoSeleccionado: action.servicioPublicoSeleccionado, page: action.page, busqueda: action.busqueda };

          case ServiciosPublicosActions.PROGRAMACION_POR_SERVICIO_REQUEST:
               return { ...state, fetchingProgramacionByServicio: true };
          case ServiciosPublicosActions.PROGRAMACION_POR_SERVICIO_SUCCESS:
               return { ...state, fetchingProgramacionByServicio: false, programacionByServicio: action.programacionByServicio, programacionHorariosByIdProgramacionConsumo: action.programacionHorariosByIdProgramacionConsumo };
          case ServiciosPublicosActions.PROGRAMACION_POR_SERVICIO_FAILURE:
               return { ...state, fetchingProgramacionByServicio: false };

          case ServiciosPublicosActions.VARIABLES_POR_PROGRAMACION_REQUEST:
               return { ...state, fetchingVariableMedicionByProgramacion: true };
          case ServiciosPublicosActions.VARIABLES_POR_PROGRAMACION_SUCCESS:
               return { ...state, fetchingVariableMedicionByProgramacion: false, variableMedicionByProgramacion: action.variableMedicionByProgramacion };
          case ServiciosPublicosActions.VARIABLES_POR_PROGRAMACION_FAILURE:
               return { ...state, fetchingVariableMedicionByProgramacion: false };

          case ServiciosPublicosActions.HORARIOS_BY_SERVICIOS_REQUEST:
               return { ...state, fetchingHorariosByServicio: true };
          case ServiciosPublicosActions.HORARIOS_BY_SERVICIOS_SUCCESS:
               return { ...state, fetchingHorariosByServicio: false, horariosByServicio: action.horariosByServicio };
          case ServiciosPublicosActions.HORARIOS_BY_SERVICIOS_FAILURE:
               return { ...state, fetchingHorariosByServicio: false };

          case ServiciosPublicosActions.HORARIOS_BY_SERVICIOS_ADMIN_REQUEST:
               return { ...state, fetchingHorariosByServicioAdmin: true };
          case ServiciosPublicosActions.HORARIOS_BY_SERVICIOS_ADMIN_SUCCESS:
               return { ...state, fetchingHorariosByServicioAdmin: false, horariosByServicioAdmin: action.horariosByServicioAdmin };
          case ServiciosPublicosActions.HORARIOS_BY_SERVICIOS_ADMIN_FAILURE:
               return { ...state, fetchingHorariosByServicioAdmin: false };

          case ServiciosPublicosActions.REGISTRO_LECTURA_REQUEST:
               return { ...state, fetchingRegistroLectura: true };
          case ServiciosPublicosActions.REGISTRO_LECTURA_SUCCESS:
               return { ...state, fetchingRegistroLectura: false };
          case ServiciosPublicosActions.REGISTRO_LECTURA_FAILURE:
               return { ...state, fetchingRegistroLectura: false };

          case ServiciosPublicosActions.REGISTRO_LECTURA_PRESENTACION_REQUEST:
               return { ...state, fetchingRegistroLecturasPresentacion: true };
          case ServiciosPublicosActions.REGISTRO_LECTURA_PRESENTACION_SUCCESS:
               return { ...state, fetchingRegistroLecturasPresentacion: false, registroLecturasPresentacion: action.registroLecturasPresentacion, chartData: action.chartData, optionsData: action.optionsData, consumo1: action.consumo1, consumo2: action.consumo2, data: action.data };
          case ServiciosPublicosActions.REGISTRO_LECTURA_PRESENTACION_FAILURE:
               return { ...state, fetchingRegistroLecturasPresentacion: false };

          case ServiciosPublicosActions.REGISTRO_LECTURA_EXCEL_REQUEST:
               return { ...state, fetchingRegistroLecturaReporte: true };
          case ServiciosPublicosActions.REGISTRO_LECTURA_EXCEL_SUCCESS:
               return { ...state, fetchingRegistroLecturaReporte: false };
          case ServiciosPublicosActions.REGISTRO_LECTURA_EXCEL_FAILURE:
               return { ...state, fetchingRegistroLecturaReporte: false }; 

          case ServiciosPublicosActions.VERIFICACION_REGISTRO_LECTURA_REQUEST:
               return { ...state, fetchingVerificacionRegistroLectura: true };
          case ServiciosPublicosActions.VERIFICACION_REGISTRO_LECTURA_SUCCESS:
               return { ...state, fetchingVerificacionRegistroLectura: false, verificacion: action.verificacion };
          case ServiciosPublicosActions.VERIFICACION_REGISTRO_LECTURA_FAILURE:
               return { ...state, fetchingVerificacionRegistroLectura: false };

          case ServiciosPublicosActions.SHOW_MODAL_CAPTURA_ABASTECIMIENTO:
               return { ...state, showModalCapturaAbastecimiento: action.showModalCapturaAbastecimiento, servicioPublicoSeleccionado: action.servicioPublicoSeleccionado, page: action.page, busqueda: action.busqueda };

          case ServiciosPublicosActions.LISTA_ASIGNACION_CAPACIDAD_BYIDSERVICIOPUBLICO_REQUEST:
               return { ...state, fetchingListaAsignacionCapacidadByIdServicioPublico: true };
          case ServiciosPublicosActions.LISTA_ASIGNACION_CAPACIDAD_BYIDSERVICIOPUBLICO_SUCCESS:
               return { ...state, fetchingListaAsignacionCapacidadByIdServicioPublico: false, listaAsignacionCapacidadByIdServicioPublico: action.listaAsignacionCapacidadByIdServicioPublico };
          case ServiciosPublicosActions.LISTA_ASIGNACION_CAPACIDAD_BYIDSERVICIOPUBLICO_FAILURE:
               return { ...state, fetchingListaAsignacionCapacidadByIdServicioPublico: false };

          case ServiciosPublicosActions.CREAR_ABASTECIMIENTO_REQUEST:
               return { ...state, fetchingCrearAbastecimiento: true };
          case ServiciosPublicosActions.CREAR_ABASTECIMIENTO_SUCCESS:
               return { ...state, fetchingCrearAbastecimiento: false };
          case ServiciosPublicosActions.CREAR_ABASTECIMIENTO_FAILURE:
               return { ...state, fetchingCrearAbastecimiento: false }; 

          case ServiciosPublicosActions.LISTA_VARIABLES_MEDICION_BY_IDSERVICIOPUBLICO_REQUEST:
               return { ...state, fetchingListaVariablesMediconByIdServicioPublico: true };
          case ServiciosPublicosActions.LISTA_VARIABLES_MEDICION_BY_IDSERVICIOPUBLICO_SUCCESS:
               return { ...state, fetchingListaVariablesMediconByIdServicioPublico: false, listaVariablesMedicionByIdServicioPublico: action.listaVariablesMedicionByIdServicioPublico };
          case ServiciosPublicosActions.LISTA_VARIABLES_MEDICION_BY_IDSERVICIOPUBLICO_FAILURE:
               return { ...state, fetchingListaVariablesMediconByIdServicioPublico: false };

          case ServiciosPublicosActions.REGISTRO_LECTURAS_PDF_REQUEST:
               return { ...state, fetchingRegistroLecturaPDF: true };
          case ServiciosPublicosActions.REGISTRO_LECTURAS_PDF_SUCCESS:
               return { ...state, fetchingRegistroLecturaPDF: false };
          case ServiciosPublicosActions.REGISTRO_LECTURAS_PDF_FAILURE:
               return { ...state, fetchingRegistroLecturaPDF: false };

          case ServiciosPublicosActions.REGISTRO_LECTURA_PRESENTACION_DISPONIBILIDAD_REQUEST:
               return { ...state, fetchingRegistroLecturaPresentacionDispo: true };
          case ServiciosPublicosActions.REGISTRO_LECTURA_PRESENTACION_DISPONIBILIDAD_SUCCESS:
               return { ...state, fetchingRegistroLecturaPresentacionDispo: false, registroLecturaPresentacionDispo: action.registroLecturaPresentacionDispo, etiquetasPresentacionDispo: action.etiquetasPresentacionDispo, dataPresentacionDispo: action.dataPresentacionDispo, unidadMedidaDispo: action.unidadMedidaDispo, dataDescriptionDispo: action.dataDescriptionDispo };
          case ServiciosPublicosActions.REGISTRO_LECTURA_PRESENTACION_DISPONIBILIDAD_FAILURE:
               return { ...state, fetchingRegistroLecturaPresentacionDispo: false };

          case ServiciosPublicosActions.REGISTRO_LECTURA_PRESENTACION_CONSUMO_REQUEST:
               return { ...state, fetchingRegistroLecturaPresentacionCons: true };
          case ServiciosPublicosActions.REGISTRO_LECTURA_PRESENTACION_CONSUMO_SUCCESS:
               return { ...state, fetchingRegistroLecturaPresentacionCons: false, registroLecturaPresentacionCons: action.registroLecturaPresentacionCons, etiquetasPresentacionCons: action.etiquetasPresentacionCons, dataPresentacionCons: action.dataPresentacionCons, unidadMedidaCons: action.unidadMedidaCons, dataDescriptionCons: action.dataDescriptionCons };
          case ServiciosPublicosActions.REGISTRO_LECTURA_PRESENTACION_CONSUMO_FAILURE:
               return { ...state, fetchingRegistroLecturaPresentacionCons: false };

          case ServiciosPublicosActions.LISTA_UBICACIONES_BY_IDSERVICIOPUBLICO_REQUEST:
               return { ...state, fetchingListaUbicacionesByIdServicioPublico: true };
          case ServiciosPublicosActions.LISTA_UBICACIONES_BY_IDSERVICIOPUBLICO_SUCCESS:
               return { ...state, fetchingListaUbicacionesByIdServicioPublico: false, listaUbicacionesByIdServicioPublico: action.listaUbicacionesByIdServicioPublico };
          case ServiciosPublicosActions.LISTA_UBICACIONES_BY_IDSERVICIOPUBLICO_FAILURE:
               return { ...state, fetchingListaUbicacionesByIdServicioPublico: false };

          //disponibilidad y consumo 
          case ServiciosPublicosActions.REGISTRO_LECTURA_DISPONIBILIDAD_EXCEL_REQUEST:
               return { ...state, fetchingRegistroLecturaPresentacionDispo: true };
          case ServiciosPublicosActions.REGISTRO_LECTURA_DISPONIBILIDAD_EXCEL_SUCCESS:
               return { ...state, fetchingRegistroLecturaPresentacionDispo: false };
          case ServiciosPublicosActions.REGISTRO_LECTURA_DISPONIBILIDAD_EXCEL_FAILURE:
               return { ...state, fetchingRegistroLecturaPresentacionDispo: false };

          case ServiciosPublicosActions.REGISTRO_LECTURA_DISPONIBILIDAD_PDF_REQUEST:
               return { ...state, fetchingRegistroLecturaPresentacionDispo: true };
          case ServiciosPublicosActions.REGISTRO_LECTURA_DISPONIBILIDAD_PDF_SUCCESS:
               return { ...state, fetchingRegistroLecturaPresentacionDispo: false };
          case ServiciosPublicosActions.REGISTRO_LECTURA_DISPONIBILIDAD_PDF_FAILURE:
               return { ...state, fetchingRegistroLecturaPresentacionDispo: false };

          case ServiciosPublicosActions.REGISTRO_LECTURA_CONSUMO_EXCEL_REQUEST:
               return { ...state, fetchingRegistroLecturaPresentacionCons: true };
          case ServiciosPublicosActions.REGISTRO_LECTURA_CONSUMO_EXCEL_SUCCESS:
               return { ...state, fetchingRegistroLecturaPresentacionCons: false };
          case ServiciosPublicosActions.REGISTRO_LECTURA_CONSUMO_EXCEL_FAILURE:
               return { ...state, fetchingRegistroLecturaPresentacionCons: false };

          case ServiciosPublicosActions.REGISTRO_LECTURA_CONSUMO_PDF_REQUEST:
               return { ...state, fetchingRegistroLecturaPresentacionCons: true };
          case ServiciosPublicosActions.REGISTRO_LECTURA_CONSUMO_PDF_SUCCESS:
               return { ...state, fetchingRegistroLecturaPresentacionCons: false };
          case ServiciosPublicosActions.REGISTRO_LECTURA_CONSUMO_PDF_FAILURE:
               return { ...state, fetchingRegistroLecturaPresentacionCons: false };

          //Asignación de Ubicacion y Capacidad SP
          case ServiciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_ASIGNADOS_REQUEST:
               return { ...state, fetchingListaServiciosPublicosAsignadosBusqueda: true};
          case ServiciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_ASIGNADOS_SUCCESS:
               return { ...state, fetchingListaServiciosPublicosAsignadosBusqueda: false, listaServiciosPublicosAsignadosBusqueda: action.listaServiciosPublicosAsignadosBusqueda, listaServiciosPublicosAsignadosCantidad: action.listaServiciosPublicosAsignadosCantidad};
          case ServiciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_ASIGNADOS_FAILURE:
               return { ...state, fetchingListaServiciosPublicosAsignadosBusqueda: false};

          case ServiciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_NO_ASIGNADOS_REQUEST:
               return { ...state,fetchingListaServiciosPublicosNoAsignados: true};
          case ServiciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_NO_ASIGNADOS_SUCCESS:
               return { ...state, fetchingListaServiciosPublicosNoAsignados: false, listaServiciosPublicosNoAsignados: action.listaServiciosPublicosNoAsignados};
          case ServiciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_NO_ASIGNADOS_FAILURE:
               return { ...state, fetchingListaServiciosPublicosNoAsignados: false};

          case ServiciosPublicosActions.LISTA_DEPOSITOS_SERVICIOS_PUBLICOS_REQUEST:
               return { ...state, fetchingListaDepositosServiciosPublicos: true };
          case ServiciosPublicosActions.LISTA_DEPOSITOS_SERVICIOS_PUBLICOS_SUCCESS:
               return { ...state, fetchingListaDepositosServiciosPublicos: false, listaDepositosServiciosPublicos: action.listaDepositosServiciosPublicos};
          case ServiciosPublicosActions.LISTA_DEPOSITOS_SERVICIOS_PUBLICOS_FAILURE:
               return { ...state, fetchingListaDepositosServiciosPublicos: false};

          case ServiciosPublicosActions.SHOW_MODAL_DETALLE_DEPOSITOS:
               return { ...state, showModalDetalleDepositosSP: action.showModalDetalleDepositosSP,servicioPublicoSeleccionado: action.servicioPublicoSeleccionado, page: action.page, busqueda: action.busqueda };
          case ServiciosPublicosActions.SHOW_MODAL_NUEVA_ASIGNACION_DEPOSITOS:
               return { ...state, showModalNuevaAsignacionSP: action.showModalNuevaAsignacionSP, page: action.page, busqueda: action.busqueda };
          case ServiciosPublicosActions.SHOW_MODAL_EDITAR_ASIGNACION_DEPOSITOS:
               return { ...state, showModalEditarAsignacionSP: action.showModalEditarAsignacionSP, servicioPublicoSeleccionado: action.servicioPublicoSeleccionado, listaDepositosSPObjeto: action.listaDepositosSPObjeto, page: action.page, busqueda: action.busqueda };
          
          case ServiciosPublicosActions.NUEVA_ASIGNACION_SERVICIOS_PUBLICOS_REQUEST:
               return { ...state, fetchingNuevaAsignacionSP: true};
          case ServiciosPublicosActions.NUEVA_ASIGNACION_SERVICIOS_PUBLICOS_SUCCESS:
               return { ...state, fetchingNuevaAsignacionSP: false};
          case ServiciosPublicosActions.NUEVA_ASIGNACION_SERVICIOS_PUBLICOS_FAILURE:
               return { ...state, fetchingNuevaAsignacionSP: false};
          
          case ServiciosPublicosActions.EDITAR_ASIGNACION_SERVICIOS_PUBLICOS_REQUEST:
               return { ...state, fetchingEditarAsignacionSP: true};
          case ServiciosPublicosActions.EDITAR_ASIGNACION_SERVICIOS_PUBLICOS_SUCCESS:
               return { ...state, fetchingEditarAsignacionSP: false};
          case ServiciosPublicosActions.EDITAR_ASIGNACION_SERVICIOS_PUBLICOS_FAILURE:
               return { ...state, fetchingEditarAsignacionSP: false};

          // Cálculos de Consumo Diferenciado
          case ServiciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_CCD_REQUEST:
               return { ...state, fetchingListaServiciosPublicosCalculo: true};
          case ServiciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_CCD_SUCCESS:
               return { ...state, fetchingListaServiciosPublicosCalculo: false, listaServiciosPublicosCalculo: action.listaServiciosPublicosCalculo};
          case ServiciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_CCD_FAILURE:
               return { ...state, fetchingListaServiciosPublicosCalculo: false};
          
          case ServiciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_CCD_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaServiciosPublicosCalculoBusqueda: true};
          case ServiciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_CCD_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaServiciosPublicosCalculoBusqueda: false, listaServiciosPublicosCalculoBusqueda: action.listaServiciosPublicosCalculoBusqueda, listaServiciosPublicosCalculoCantidad: action.listaServiciosPublicosCalculoCantidad};
          case ServiciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_CCD_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaServiciosPublicosCalculoBusqueda: false};            

          case ServiciosPublicosActions.LISTA_DETALLE_CCD_INTERNO_REQUEST:
               return { ...state, fetchingListaDetalleCalculosInterno: true};
          case ServiciosPublicosActions.LISTA_DETALLE_CCD_INTERNO_SUCCESS:
               return { ...state, fetchingListaDetalleCalculosInterno: false, listaDetalleCalculosInterno: action.listaDetalleCalculosInterno, listaDetalleCalculosInternoCantidad: action.listaDetalleCalculosInternoCantidad };
          case ServiciosPublicosActions.LISTA_DETALLE_CCD_INTERNO_FAILURE:
               return { ...state, fetchingListaDetalleCalculosInterno: false};

          case ServiciosPublicosActions.LISTA_DETALLE_CCD_FACTURACION_REQUEST:
               return { ...state, fetchingListaDetalleCalculosFacturacion: true};
          case ServiciosPublicosActions.LISTA_DETALLE_CCD_FACTURACION_SUCCESS:
               return { ...state, fetchingListaDetalleCalculosFacturacion: false, listaDetalleCalculosFacturacion: action.listaDetalleCalculosFacturacion, listaDetalleCalculosFacturacionCantidad: action.listaDetalleCalculosFacturacionCantidad };
          case ServiciosPublicosActions.LISTA_DETALLE_CCD_FACTURACION_FAILURE:
               return { ...state, fetchingListaDetalleCalculosFacturacion: false};

          case ServiciosPublicosActions.LISTA_CCD_INTERNO_REQUEST:
               return { ...state, fetchingListaCDInterno: true};
          case ServiciosPublicosActions.LISTA_CCD_INTERNO_SUCCESS:
               return { ...state, fetchingListaCDInterno: false, listaCDInterno: action.listaCDInterno, consumoTotal: action.consumoTotal, lecturasInicioFinArray: action.lecturasInicioFinArray};
          case ServiciosPublicosActions.LISTA_CCD_INTERNO_FAILURE:
               return { ...state, fetchingListaCDInterno: false};

          case ServiciosPublicosActions.SHOW_MODAL_DETALLE_CCD_INTERNO:
               return { ...state, showModalDetalleCalculosInterno: action.showModalDetalleCalculosInterno, servicioPublicoSeleccionadoCCDInterno: action.servicioPublicoSeleccionadoCCDInterno, page: action.page, busqueda: action.busqueda}
          case ServiciosPublicosActions.SHOW_MODAL_DETALLE_CCD_FACTURACION:
               return { ...state, showModalDetalleCalculosFacturacion: action.showModalDetalleCalculosFacturacion, servicioPublicoSeleccionadoCCDFacturacion: action.servicioPublicoSeleccionadoCCDFacturacion, page: action.page, busqueda: action.busqueda}
          case ServiciosPublicosActions.SHOW_MODAL_CCD_INTERNO:
               return { ...state, showModalCalculoCDInterno: action.showModalCalculoCDInterno, page: action.page, busqueda: action.busqueda};
          case ServiciosPublicosActions.SHOW_MODAL_CDD_FACTURACION:
               return { ...state, showModalCalculoCDFacturacion: action.showModalCalculoCDFacturacion, page: action.page, busqueda: action.busqueda};

          case ServiciosPublicosActions.CALCULO_CONSUMO_DIFERENCIADO_INTERNO_REQUEST:
               return { ...state, fetchingCalculoCDInterno: true};
          case ServiciosPublicosActions.CALCULO_CONSUMO_DIFERENCIADO_INTERNO_SUCCESS:
               return { ...state, fetchingCalculoCDInterno: false};
          case ServiciosPublicosActions.CALCULO_CONSUMO_DIFERENCIADO_INTERNO_FAILURE:
               return { ...state, fetchingCalculoCDInterno: false};

          case ServiciosPublicosActions.CALCULO_CONSUMO_DIFERENCIADO_FACTURACION_REQUEST:
               return { ...state, fetchingCalculoCDFacturacion: true};
          case ServiciosPublicosActions.CALCULO_CONSUMO_DIFERENCIADO_FACTURACION_SUCCESS:
               return { ...state, fetchingCalculoCDFacturacion: false};
          case ServiciosPublicosActions.CALCULO_CONSUMO_DIFERENCIADO_FACTURACION_FAILURE:
               return { ...state, fetchingCalculoCDFacturacion: false};

          case ServiciosPublicosActions.CALCULO_CD_INTERNO_PDF_REQUEST:
               return { ...state, fetchingCalculoCDInternoDescargaPDF: true };
          case ServiciosPublicosActions.CALCULO_CD_INTERNO_PDF_SUCCESS:
               return { ...state, fetchingCalculoCDInternoDescargaPDF: false};
          case ServiciosPublicosActions.CALCULO_CD_INTERNO_PDF_FAILURE:
               return { ...state, fetchingCalculoCDInternoDescargaPDF: false};

          case ServiciosPublicosActions.CALCULO_CD_INTERNO_EXCEL_REQUEST:
               return { ...state, fetchingCalculoCDInternoDescargaExcel: true};
          case ServiciosPublicosActions.CALCULO_CD_INTERNO_EXCEL_SUCCESS:
               return { ...state, fetchingCalculoCDInternoDescargaExcel: false};
          case ServiciosPublicosActions.CALCULO_CD_INTERNO_EXCEL_FAILURE:
               return { ...state, fetchingCalculoCDInternoDescargaExcel: false};

          case ServiciosPublicosActions.CALCULO_CD_FACTURACION_PDF_REQUEST:
               return { ...state, fetchingCalculoCDFacturacionDescargaPDF: true};
          case ServiciosPublicosActions.CALCULO_CD_FACTURACION_PDF_SUCCESS:
               return { ...state, fetchingCalculoCDFacturacionDescargaPDF: false};
          case ServiciosPublicosActions.CALCULO_CD_FACTURACION_PDF_FAILURE:
               return { ...state, fetchingCalculoCDFacturacionDescargaPDF: false};

          case ServiciosPublicosActions.CALCULO_CD_FACTURACION_EXCEL_REQUEST:
               return { ...state, fetchingCalculoCDFacturacionDescargaExcel: true};
          case ServiciosPublicosActions.CALCULO_CD_FACTURACION_EXCEL_SUCCESS:
               return { ...state, fetchingCalculoCDFacturacionDescargaExcel: false};
          case ServiciosPublicosActions.CALCULO_CD_FACTURACION_EXCEL_FAILURE:
               return { ...state, fetchingCalculoCDFacturacionDescargaExcel: false};
          
          default:
               return state;
     }
}
