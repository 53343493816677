import React, { Component } from 'react';
import {  Modal, Row, Col } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

class ModalArchivos extends Component {
    render() {
        const { servicioSeleccionado, showArchivosServicio, onShowModalArchivosServicio } = this.props;

        const handleCancel = () => {
            onShowModalArchivosServicio()
        }
          
        return (
            <Modal
            visible={showArchivosServicio}
            title='Archivos Servicios'
            onCancel={handleCancel}
            footer={false}
            width={'70%'}
            >
                {servicioSeleccionado?(
                    <Row justify='space-between'>
                        <Col span={11}>
                            <h1>Imagen de servicio</h1>
                            <h2>Imagen actual:</h2>
                            {servicioSeleccionado.urlImagen ? (
                            <img src={servicioSeleccionado.urlImagen} className='fotoServicio'/>
                            ):(
                            <p>No cuenta con una imagen al momento, favor de agregar una.</p>
                            )}
                        </Col>
                        <Col span={11}>
                            <h1>Procedimiento PDF de servicio</h1>
                            <h2>Procedimiento actual:</h2>
                            {servicioSeleccionado.rutaManual ? (
                                <a target='_blank' href={servicioSeleccionado.rutaManual}>{servicioSeleccionado.rutaManual}</a>
                            ):(
                            <p>No cuenta con un procedimiento, favor de agregar uno.</p>
                            )}
                        </Col>
                    </Row>
                ):false}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarServicio: state.CatalogosGeneralesReducer.fetchingEditarServicio,
        showArchivosServicio: state.CatalogosGeneralesReducer.showArchivosServicio,
        servicioSeleccionado: state.CatalogosGeneralesReducer.servicioSeleccionado,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalArchivosServicio: () => {
            dispatch({ type: 'SHOW_MODAL_ARCHIVOS_SERVICIO', showArchivosServicio: false, servicioSeleccionado: null})
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalArchivos);