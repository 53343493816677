import React from 'react';
import { Button, Form, Row, Col, Modal, Select, Spin } from 'antd';
import { connect } from "react-redux";
import { CheckCircleOutlined, FilePdfOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option } = Select;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };

class Folio extends React.Component {
    formRef = React.createRef();
     render() {
          const { 
               showModalFolioSolicitud,
               onCloseModal,
               onDescargar, impreso,
               fetchingExportarSolicitudDeServicio,
          } = this.props;

          const handleCancel = () => {
               console.log('Entra a handleCancel')
               onCloseModal();
          };

          const onDescarga = () => {
               onDescargar(this.props.folio);
          };
          return (
               <Modal
                    visible={showModalFolioSolicitud}
                    title={''}
                    onCancel={handleCancel}
                    footer={false}
                    width={'30%'}
               >
                    <Spin spinning={fetchingExportarSolicitudDeServicio}>
                         <Form {...layout}
                              name="formulario"
                              ref={this.formRef}
                         >
                              <Row justify='space-around'>
                                   <Col span={24}>
                                        <div style={{textAlign:"center"}}>
                                             <CheckCircleOutlined spin={false} style={{fontSize: 80, color:"#ff8c62"}}/>
                                        </div>
                                   </Col>
                                   
                                   <Col span={24}>
                                        <br/>
                                        <h3 style={{ textAlign:"center" }}>
                                             <p> { 'LE CONFIRMO QUE SE GENERÓ EL FOLIO: '} <b style={{ color:"#ff8c62", }}> {this.props.folio} </b> {', REGISTRADO EL ' + this.props.fechaCreacion + ' A LAS ' + this.props.horaCreacion + '.' } </p>
                                        </h3>
                                   </Col>

                                   <Col span={24}>
                                        <br/>
                                        <Button disabled={impreso} className='btnCargaArchivos' style={{ width: '100%' }} onClick={onDescarga}>
                                             Descargar Solicitud de Servicio <FilePdfOutlined /> 
                                        </Button>
                                   </Col>
                              </Row>
                         </Form>
                    </Spin>
               </Modal>
          );
     }
}

const mapStateToProps = state => {
     return {
          showModalFolioSolicitud: state.SolicitudDeServiciosReducer.showModalFolioSolicitud,
          fechaCreacion: state.SolicitudDeServiciosReducer.fechaCreacion,
          horaCreacion: state.SolicitudDeServiciosReducer.horaCreacion,
          folio: state.SolicitudDeServiciosReducer.folio,
          fetchingExportarSolicitudDeServicio: state.SolicitudDeServiciosReducer.fetchingExportarSolicitudDeServicio,
          impreso: state.SolicitudDeServiciosReducer.impreso,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onCloseModal: () => {
               dispatch({ type: 'SHOW_MODAL_FOLIO_SOLICITUD', showModalFolioSolicitud: false, horaCreacion: '',fechaCreacion: '', folio: '', identificacion: true });
          },
          onDescargar: (folio) => {
               dispatch({ type: 'EXPORTAR_SOLICITUD_SERVICIO_REQUEST', folio, page: 1 });
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(Folio);
