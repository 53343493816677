import React, {Component} from 'react'
import { Modal } from 'antd';
import { connect } from "react-redux";
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoFolio from './ModalNuevoFolio';
import ModalCatalogoPorTipoFolio from './ModalCatalogoPorTipoFolio';
import ListaProductosSeleccionados from './ListaProductosSeleccionados';
import SalidaPorRequisicion from './SalidaPorRequisicion';

class ContenedorNuevoFolio extends Component {
    render() { 
        const {
            onShowModalNuevoFolio, 
            onCambiarVista, 
            vistaModalNuevoFolio, 
            showModalNuevoFolio, 
            onBorrarUbicacionesDestino,
            onBorrarListas, 
            onActualizarTabla,
        } = this.props;
     
        const handleCancel = () => {
            onShowModalNuevoFolio()
            onCambiarVista(1);
            onBorrarUbicacionesDestino();
            onBorrarListas();
            onActualizarTabla(null, [], 1, true);
        }

        function ContenidoNuevo() {
            if(vistaModalNuevoFolio === 1){
                return(
                    <ModalNuevoFolio/>
                );
            }
            else if(vistaModalNuevoFolio === 2){
                return(
                    <ModalCatalogoPorTipoFolio/>
                );
            }
            else if(vistaModalNuevoFolio === 3){
                return(
                    <ListaProductosSeleccionados/>   
                );
            }
            else if(vistaModalNuevoFolio === 4){
                return(
                    <SalidaPorRequisicion/>   
                );
            } else {
                return <div />
            }
        };
        return ( 
            <Modal
                visible={showModalNuevoFolio}
                title='Nuevo Folio'
                onCancel={handleCancel}
                footer={false}
                width={'70%'}
            >
                <ContenidoNuevo/>
            </Modal>
         );
    }
}
 
const mapStateToProps = state => {
	return {
        showModalNuevoFolio: state.AlmacenesReducer.showModalNuevoFolio,
        vistaModalNuevoFolio: state.AlmacenesReducer.vistaModalNuevoFolio,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalNuevoFolio: () => {
            dispatch({ type: "SHOW_MODAL_NUEVO_FOLIO", showModalNuevoFolio: false})
        },
        onCambiarVista: (vistaModalNuevoFolio) => {
            dispatch({ type: "CAMBIAR_VISTA_MODAL_NUEVOFOLIO", vistaModalNuevoFolio: vistaModalNuevoFolio});
        },
        onBorrarUbicacionesDestino: () => {
            dispatch({ type: "UBICACION_DESTINO_POR_ALMACEN_SUCCESS", listaUbicacionesDestino: null})
        },
        onBorrarUbicacionesOrigen: () => {
            dispatch({ type: "OBTENER_UBICACION_POR_ALMACEN_REQUEST", listaUbicaciones: null})
        },
        onBorrarListas: () => {
            dispatch({ type: "LISTA_INVENTARIO_SUCCESS", listaProductosBusqueda: null});
        },
        onActualizarTabla: (listaSeleccionados, selectedRowKeys, pageMovimientos, hasSelected) => {
            dispatch({ type: 'ACTUALIZAR_TABLA_MOVIMIENTOS', listaSeleccionados, selectedRowKeys, pageMovimientos, hasSelected})
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContenedorNuevoFolio);