import React, { Component } from 'react';
import {Row, Col, Modal, Spin, Form, Input, Select, Button, TimePicker, DatePicker, message} from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalNuevaAlerta extends Component {
    formRef = React.createRef();
    state={
        periodicidad: null,
        hola: false,
        fechaVigenia: null,
    }
    render() {
        const { onShowModalNuevaAlerta, showModalNuevaAlerta, limpiarFormulario, onLimpiarFormulario,
            onNuevaAlerta, listaModulosSelect, fetchingListaModulos, fetchingNuevaAlerta,
            listaDiasAlerta, fetchingListaServiciosFiltro, listaServiciosFiltro,
            onBuscarSevicio, onLimpiarListaSevicio, fetchingListaUsuariosParam, listaUsuariosParam, 
            onBuscarUsuario, onLimpiarListaUsuario, onActualizarBotonesDias, listaInfoProyecto, onListaEventoPorModulo,
            listaEventoPorModulo, fetchingListaEventoPorModulo, formularioSearchAlertas
        } = this.props;

        if (limpiarFormulario && limpiarFormulario === 'nuevaAlerta') {
            this.formRef.current.resetFields();
            this.setState({
                periodicidad: null,
            })
            onLimpiarFormulario();
            onLimpiarListaSevicio();
            onLimpiarListaUsuario();
            this.props.onLimpiarEventoPorModulo();
        }

        const onFinish = formulario => {
            console.log('Success:', formulario, listaDiasAlerta);
            const rangeValue = formulario['rangoVigencia'];

            formulario.rangoVigencia = rangeValue[0].format('YYYY-MM-DD') + "," + rangeValue[1].format('YYYY-MM-DD');
            console.log('formulario despues del parse');
            console.log(formulario);
            let pasa = false;
            if(this.state.periodicidad === 'Semanal' || this.state.periodicidad === 'Personalizado'){
                for(let i=0; i<listaDiasAlerta.length; i++){
                    if(listaDiasAlerta[i].activo === true){
                        pasa = true;
                    }
                }
                if(pasa === true){
                    onNuevaAlerta(formulario, this.props.page, this.props.busqueda, listaDiasAlerta, formularioSearchAlertas );
                }
                else{
                    message.warning('Favor de seleccionar al menos un día de la semana.');
                }
            }
            else{
                onNuevaAlerta(formulario, this.props.page, this.props.busqueda, listaDiasAlerta, formularioSearchAlertas );
            }
        };
        

        const handleCancel = () => {
            onShowModalNuevaAlerta();
            onLimpiarFormulario();
            this.formRef.current.resetFields();
        }
        
        const buscarServicio = (value) => {
            if(value.length>=3){
                console.log(value)
                onBuscarSevicio(value);
            }
            else{
                onLimpiarListaSevicio();
            }
        };

        const buscarUsuario = (value) => {
            if(value.length>=3){
                console.log(value)
                onBuscarUsuario(value);
            }
            else{
                onLimpiarListaUsuario();
            }
        };

        const onSelectPeriodicidad = (value) => {
            this.setState({
                periodicidad: value
            });
            const listaDiasAlertaLocal = listaDiasAlerta;
            onActualizarBotonesDias(null);
            if(value === 'Diaria'){
                for(let i=0; i<listaDiasAlertaLocal.length; i++){
                    listaDiasAlertaLocal[i].type = 'primary';
                    listaDiasAlertaLocal[i].activo = true;
                }
            }
            else{
                for(let i=0; i<listaDiasAlertaLocal.length; i++){
                    listaDiasAlertaLocal[i].type = 'default';
                    listaDiasAlertaLocal[i].activo = false;
                }
            }
            onActualizarBotonesDias(listaDiasAlertaLocal);
        }

        const onBotonDia = (idDiaSemana) => {
            const listaDiasAlertaLocal = listaDiasAlerta;
            onActualizarBotonesDias(null);
            for(let i=0; i<listaDiasAlertaLocal.length; i++){
                if(this.state.periodicidad === 'Semanal' || this.state.periodicidad === 'Mensual'){
                    if(listaDiasAlertaLocal[i].idDiaSemana === idDiaSemana){
                        if(listaDiasAlertaLocal[i].activo === true){
                            listaDiasAlertaLocal[i].type = 'default';
                            listaDiasAlertaLocal[i].activo = false;
                        }
                        else{   
                            listaDiasAlertaLocal[i].type = 'primary';
                            listaDiasAlertaLocal[i].activo = true;
                        }
                    }
                    else{
                        listaDiasAlertaLocal[i].type = 'default';
                        listaDiasAlertaLocal[i].activo = false;
                    }
                }
                else{
                    if(listaDiasAlertaLocal[i].idDiaSemana === idDiaSemana){
                        if(listaDiasAlertaLocal[i].activo === true){
                            listaDiasAlertaLocal[i].type = 'default';
                            listaDiasAlertaLocal[i].activo = false;
                        }
                        else{   
                            listaDiasAlertaLocal[i].type = 'primary';
                            listaDiasAlertaLocal[i].activo = true;
                        }
                    }
                }
            }
            this.setState({
                hola: true,
            });
            onActualizarBotonesDias(listaDiasAlertaLocal);
        }

        const disabledRange = fecha => {
            return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicio;
        }
        const disabledRangeMes = fecha => {
            return fecha && moment(fecha.format('YYYY-MM-DD')) < this.state.fechaVigenia[0];
        }

        const fechas = (rangeValue) => {
            this.setState({
                fechaVigenia: null,
            })
            if(listaInfoProyecto.fechaFin){
                if(rangeValue){
                    const diferencia = moment(listaInfoProyecto.fechaFin, 'YYYY-MM-DD').diff(moment(rangeValue[1].format('YYYY-MM-DD')), "days");

                    this.setState({
                        fechaVigenia: rangeValue,
                    })
                    if(diferencia < 0){
                        message.error('No es posible seleccionar una fecha de fin mayor a la del proyecto (' + listaInfoProyecto.fechaFin.format('YYYY-MM-DD').split('-').reverse().join('-') + ')', 7);
                        this.formRef.current.setFieldsValue({'rangoVigencia': [rangeValue[0], null]});
                        this.setState({
                            fechaVigenia: null,
                        })
                    }
                }
                else{
                    this.setState({
                        fechaVigenia: null,
                    })
                }
            }
            else{
                message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                this.formRef.current.setFieldsValue({'rangoVigencia': [null, null]});
            }
        };

        const fechasMes = (fechaDetonacion) => {
            if(fechaDetonacion){
                console.log('this.state.fechaVigenia');
                console.log(this.state.fechaVigenia);
                console.log(this.state.fechaVigenia[1].format('YYYY-MM-DD'), fechaDetonacion.format('YYYY-MM-DD'));
                const diferencia = this.state.fechaVigenia[1].diff(fechaDetonacion, "days");
                console.log('diferencia');
                console.log(diferencia);
                if(diferencia < 0){
                    message.error('No es posible seleccionar una fecha de detonación mayor a la fecha final de la alerta (' + this.state.fechaVigenia[1].format('YYYY-MM-DD').split('-').reverse().join('-') + ')', 7);
                    this.formRef.current.setFieldsValue({'fechaDetonacion':  null});
                }
            }
        };

        const eventosPorModulo = (idModulo) => {
            if(idModulo){
                onListaEventoPorModulo(idModulo);
            }
            else{
                this.props.onLimpiarEventoPorModulo();
            }
        }

        return (
            <Modal
                visible={showModalNuevaAlerta}
                title='Nueva Alerta'
                onCancel={handleCancel}
                footer={false}
                width='70%'
            >
                <Spin spinning={fetchingNuevaAlerta}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Nombre de la alerta"
                                    name="nombreAlerta"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={150} placeholder="Ingresa el nombre de la alerta"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Módulo de sistema"
                                    name="modulo"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Select
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el módulo de sistema"
                                        loading={fetchingListaModulos}
                                        notFoundContent={null}
                                        onChange={eventosPorModulo}
                                    >
                                        {listaModulosSelect.map(option => <Option key={option.idModulo}>{option.nombre}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Servicio"
                                    name="servicio"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Select
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Escribe el servicio a buscar"
                                        loading={fetchingListaServiciosFiltro}
                                        showSearch
                                        filterOption={false}
                                        onSearch={buscarServicio}
                                        notFoundContent={null}
                                    >
                                        {listaServiciosFiltro.map(option => <Option key={option.idServicio}>{option.nombre}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Usuarios para alerta"
                                    name="usuarios"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Select
                                        allowClear
                                        mode='multiple'
                                        style={{ width: '100%' }}
                                        placeholder="Escribe el usuario a buscar"
                                        loading={fetchingListaUsuariosParam}
                                        showSearch
                                        filterOption={false}
                                        onSearch={buscarUsuario}
                                        notFoundContent={null}
                                    >
                                        {listaUsuariosParam.map(option => <Option key={option.idUsuario + '-' + option.nombre + ' ' + option.aPaterno}>{option.idUsuario + '-' + option.nombre + ' ' + option.aPaterno}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {listaEventoPorModulo ? (
                            <Col span={11}>
                                <Form.Item
                                    label="Eventos"
                                    name="evento"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Select
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el evento"
                                        loading={fetchingListaEventoPorModulo}
                                    >
                                        {listaEventoPorModulo.map(option => <Option key={option.idEvento}>{option.evento}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            ):false}
                            <Col span={11}>
                                <Form.Item
                                    label="Periodicidad"
                                    name="periodicidad"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Select
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona la periodicidad"
                                        onChange={onSelectPeriodicidad}
                                    >
                                        <Option key='Diaria'>Diaria</Option>
                                        <Option key='Semanal'>Semanal</Option>
                                        <Option key='Mensual'>Mensual</Option>
                                        <Option key='Por evento'>Por Evento</Option>
                                        <Option key='Personalizado'>Personalizado</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Rango de fechas de la alerta"
                                    name="rangoVigencia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <RangePicker
                                        format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio", "Ingresa fecha fin"]}
                                        disabledDate={disabledRange} onChange={fechas} />
                                </Form.Item>
                            </Col>
                            {this.state.periodicidad === 'Diaria' ?(
                            <>
                            <Col span={11}>
                                <p>Días de la semana</p>
                                {listaDiasAlerta ? (
                                <Row justify='space-around'>
                                    {listaDiasAlerta.map(option => 
                                    <Col span={2} key={option.idDiaSemana}>
                                        <Button disabled={true} onClick={() => onBotonDia(option.idDiaSemana)} type={option.type} shape="circle" value={option.idDiaSemana}>{option.dia}</Button>
                                    </Col>
                                    )}
                                </Row>
                                ):false}
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Horario"
                                    name="horario"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <TimePicker showNow={false} format='HH:mm' placeholder={["Horario"]} style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            </>
                            ):false}
                            
                            {this.state.periodicidad === 'Semanal'  || this.state.periodicidad === 'Personalizado' ?(
                            <>
                            <Col span={11}>
                                <p>Días de la semana</p>
                                {listaDiasAlerta ? (
                                <Row justify='space-around'>
                                    {listaDiasAlerta.map(option => 
                                    <Col span={2} key={option.idDiaSemana}>
                                        <Button onClick={() => onBotonDia(option.idDiaSemana)} type={option.type} shape="circle" value={option.idDiaSemana}>{option.dia}</Button>
                                    </Col>
                                    )}
                                </Row>
                                ):false}
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Horario"
                                    name="horario"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <TimePicker showNow={false} format='HH:mm' placeholder={["Horario"]} style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            </>
                            ):false}

                            {this.state.periodicidad === 'Mensual' ? (
                            <>
                            {this.state.fechaVigenia ? (
                            <Col span={11}>
                                <Form.Item
                                    label="Día del mes"
                                    name="fechaDetonacion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <DatePicker disabledDate={disabledRangeMes} onChange={fechasMes} format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={"Selecciona el día del mes"}/>
                                </Form.Item>
                            </Col>
                            ):false}
                            <Col span={11}>
                                <Form.Item
                                    label="Horario"
                                    name="horario"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <TimePicker showNow={false} format='HH:mm' placeholder={["Horario"]} style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            </>
                            ):false}
            

                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Crear Alerta</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalNuevaAlerta: state.ConfiguracionYSeguridadReducer.showModalNuevaAlerta,
        page: state.ConfiguracionYSeguridadReducer.page,
        busqueda: state.ConfiguracionYSeguridadReducer.busqueda,
        fetchingNuevaAlerta: state.ConfiguracionYSeguridadReducer.fetchingNuevaAlerta,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaDiasAlerta: state.ConfiguracionYSeguridadReducer.listaDiasAlerta,
        listaModulosSelect: state.ConfiguracionYSeguridadReducer.listaModulosSelect,
        fetchingListaModulos: state.ConfiguracionYSeguridadReducer.fetchingListaModulos,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        fetchingListaUsuariosParam: state.ConfiguracionYSeguridadReducer.fetchingListaUsuariosParam,
        listaUsuariosParam: state.ConfiguracionYSeguridadReducer.listaUsuariosParam,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        fetchingListaEventoPorModulo: state.ConfiguracionYSeguridadReducer.fetchingListaEventoPorModulo,
        listaEventoPorModulo: state.ConfiguracionYSeguridadReducer.listaEventoPorModulo,
        formularioSearchAlertas: state.ConfiguracionYSeguridadReducer.formularioSearchAlertas
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevaAlerta: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVA_ALERTA', showModalNuevaAlerta: false });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onNuevaAlerta: (formulario, page, busqueda, listaDiasAlerta, formularioSearchAlertas) => {
            dispatch({ type: 'NUEVA_ALERTA_REQUEST', formulario, page, busqueda, listaDiasAlerta, formularioSearchAlertas});
        },
        onBuscarSevicio: (busqueda) => {
            dispatch({ type: 'LISTA_SERVICIOS_FILTRO_REQUEST', busqueda})
        },
        onLimpiarListaSevicio: () => {
            dispatch({ type: 'LISTA_SERVICIOS_FILTRO_SUCCESS', listaServiciosFiltro: []})
        },
        onBuscarUsuario: (busqueda) => {
            dispatch({ type: 'LISTA_USUARIOS_PARAM_REQUEST', busqueda})
        },
        onLimpiarListaUsuario: () => {
            dispatch({ type: 'LISTA_USUARIOS_PARAM_SUCCESS', listaUsuariosParam: []})
        },
        onActualizarBotonesDias: (listaDiasAlerta) => {
            dispatch({ type: 'LISTA_DIAS_ALERTA_SUCCESS', listaDiasAlerta})
        },
        onListaEventoPorModulo: (idModulo) => {
            dispatch({ type: 'LISTA_EVENTO_POR_MODULO_REQUEST', idModulo})
        },
        onLimpiarEventoPorModulo: () => {
            dispatch({ type: 'LISTA_EVENTO_POR_MODULO_SUCCESS', listaEventoPorModulo: null})
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevaAlerta);