import React, { Component } from 'react';
import { Row, Col, Table, Button, Input, Card, Tooltip, Popover, Spin, Tabs, Select } from 'antd';
import { connect } from "react-redux";
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

import ModalNuevaSolicitud from './ModalNuevaSolicitud';

import { PlusOutlined, EditOutlined, FilePdfOutlined, CloseCircleOutlined, InfoCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import ModalModificarRegistro from './ModalModificarRegistro';
import ModalEditarSolicitud from './ModalEditarSolicitud';
import ModalAprobarFolio from './ModalAprobarFolio';

const { Search } = Input;
const { TabPane } = Tabs;
const { Option } = Select;

class RequisicionMaterialesContainer extends Component {
     state = {
          page: 1,
          busqueda: {
               busqueda: '',
               estado: '',
               idEncargado: JSON.parse(sessionStorage.getItem('userInfo'))[0].idUsuario
          },
          orden: {
               field: 'idRequisicionMateriales',
               order: 'DESC',
          },
          pageAprobar: 1,
          busquedaAprobar: {
               busqueda: '',
               estado: 'Autorizado,Surtido,Parcialmente surtida,Generado,Cerrado,Cancelado',
               idEncargado: '',
          },
          ordenAprobar: {
               field: 'idRequisicionMateriales',
               order: 'DESC',
          },
     }
     componentDidMount() {
          let userInfo = sessionStorage.getItem('userInfo');
          if(!userInfo.idUsuario){
               userInfo =JSON.parse(userInfo);
          }
          this.props.onGetListaFoliosRequisicionMateriales(1, this.state.busqueda, this.state.orden, userInfo[0].idUsuario, false);
     }
     render() {
          const { fetchingListaFoliosRequisicionMateriales, listaFoliosRequisicionMaterialesCantidad, 
               listaFoliosRequisicionMateriales, fetchingDescargarRequisicion, onShowModal, onGetListaFoliosRequisicionMateriales,
          } = this.props;

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if (permisosUsuario[0]) {
               permisosUsuario = JSON.parse(permisosUsuario);
          };
          let userInfo = sessionStorage.getItem('userInfo');
          if(!userInfo.idUsuario){
               userInfo =JSON.parse(userInfo);
          }

          const onSearchFolio = (value, tipo) => {
               if(tipo === 'solicitar'){
                    const busqueda = {
                         'busqueda': value,
                         'estado': this.state.busqueda.estado,
                         'idEncargado': this.state.busqueda.idEncargado,
                    }
                    onGetListaFoliosRequisicionMateriales(this.state.page, busqueda, this.state.orden, userInfo[0].idUsuario, false);
                    this.setState({ busqueda });
               }
               else{
                    const busquedaAprobar = {
                         'busqueda': value,
                         'estado': this.state.busquedaAprobar.estado,
                         'idEncargado': this.state.busquedaAprobar.idEncargado,
                    }
                    onGetListaFoliosRequisicionMateriales(this.state.pageAprobar, busquedaAprobar, this.state.ordenAprobar, userInfo[0].idUsuario, permisosUsuario.especialAlmacén ? false : true);
                    this.setState({ busquedaAprobar });
               }
          };
          const onFiltrarEstado = (value) => {
               const busquedaAprobar = {
                    'busqueda': this.state.busquedaAprobar.busqueda,
                    'estado': value.length ? value : undefined,
                    'idEncargado': this.state.busquedaAprobar.idEncargado,
               }
               onGetListaFoliosRequisicionMateriales(this.state.pageAprobar, busquedaAprobar, this.state.ordenAprobar, userInfo[0].idUsuario, permisosUsuario.especialAlmacén ? false : true);
               this.setState({ busquedaAprobar });
          }

          const onCreateFolio = () => {
               onShowModal(this.state.page, this.state.busqueda, this.state.orden);
          };

          const handleTableChange = (pagination, filtro, orden) => {
               console.log('pagination normal', pagination.current, orden);
               this.setState({
                    page: pagination.current,
               });
               if(orden.columnKey){
                    let field = orden.columnKey;
                    let order = 'ASC';
               
                    if(orden.order === 'descend'){
                        order = 'DESC';
                    }

                    if(orden.columnKey === 'folio'){
                         field = 'idRequisicionMateriales';
                    }
    
                    if(!orden.order){
                         field = 'idRequisicionMateriales';
                         order = 'DESC';
                    }

                    onGetListaFoliosRequisicionMateriales(this.state.page, this.state.busqueda, {'order': order, 'field': field}, userInfo[0].idUsuario, false);
                    this.setState({
                        orden: {'order': order, 'field': field,},
                    });
                    
               }
               else{
                    onGetListaFoliosRequisicionMateriales(pagination.current, this.state.busqueda, this.state.orden, userInfo[0].idUsuario, false);
               }
          } 
          const handleTableChangeAprobar = (pagination, filtro, orden) => {
               console.log('pagination aprobar', pagination.current, orden);
               this.setState({
                    pageAprobar: pagination.current,
               });
               if(orden.columnKey){
                    let field = orden.columnKey;
                    let order = 'ASC';
               
                    if(orden.order === 'descend'){
                        order = 'DESC';
                    }
    
                    if(orden.columnKey === 'folio'){
                         field = 'idRequisicionMateriales';
                    }

                    if(!orden.order){
                         field = 'idRequisicionMateriales';
                         order = 'DESC';
                    }

                    onGetListaFoliosRequisicionMateriales(this.state.pageAprobar, this.state.busquedaAprobar, {'order': order, 'field': field}, userInfo[0].idUsuario, permisosUsuario.especialAlmacén ? false : true);
                    this.setState({
                        ordenAprobar: {'order': order, 'field': field,},
                    });
                    
               }
               else{
                    onGetListaFoliosRequisicionMateriales(pagination.current, this.state.busquedaAprobar, this.state.ordenAprobar, userInfo[0].idUsuario, permisosUsuario.especialAlmacén ? false : true);
               }
          } 

          const onDescargarPdf = (key) => {
               this.props.onDescargarFolioRequisicion(key.folio);
          }
          
          const cancelarFolio = (key) => {
               console.log('key', key)
               this.props.onCancelarFolio(key.idRequisicionMateriales, this.state.page, this.state.busqueda, this.state.orden, userInfo[0].idUsuario);
          };
          const onEditarFolio = (key) => {
               console.log('key', key);
               this.props.onShowEditar(key, this.state.page, this.state.busqueda, this.state.orden);
               let selectedRowKeys = [];
               for(let i in key.productosByIdRequisicion){
                    selectedRowKeys.push(key.productosByIdRequisicion[i].idProducto);
               }
               this.props.onActualizarTabla(key.productosByIdRequisicion, selectedRowKeys, this.state.page, false);
               let partida = key.productosByIdRequisicion[0].productoByIdProducto.subFamiliaByIdSubFamilia.familiaByIdFamilia.idPartida;
               this.props.onGetInventario(1, key.productosByIdRequisicion[0].productoByIdProducto.subFamiliaByIdSubFamilia.familiaByIdFamilia.servicioByIdServicio.nombre, partida,null, null, key.productosByIdRequisicion);
               if(partida){
                    this.props.onGetSubfamiliasByPartida(partida);
                    this.props.onListaPartidasByServicio(key.productosByIdRequisicion[0].productoByIdProducto.subFamiliaByIdSubFamilia.familiaByIdFamilia.idServicio);
               }
               else{
                    this.props.onSubfamiliasPorServicio(key.productosByIdRequisicion[0].productoByIdProducto.subFamiliaByIdSubFamilia.familiaByIdFamilia.idServicio);
               }
          };

          const columns = [{
               title: 'Número de Folio',
               dataIndex: 'folio',
               key: 'folio',
               sorter: true,
               align: 'center',
          }, {
               title: 'Fecha de Alta',
               dataIndex: 'fechaAlta',
               key: 'fechaAlta',
               sorter: true,
               align: 'center',
          }, {
               title: 'Usuario Autorizó',
               dataIndex: 'nombreUsuarioAutoriza',
               key: 'nombreUsuarioAutoriza',
               align: 'center',
          },{
               title: 'Estado',
               dataIndex: 'estado',
               key: 'estado',
               sorter: true,
               align: 'center',
          },{
               title: 'Opciones',
               key: '',
               align: 'center',
               render: (record) => (
                    <Row justify='space-around'>
                         <Col>
                              <Tooltip placement="left" title='Cancelar Folio'>
                                   <Popover
                                        content={
                                        <Spin spinning={this.props.fetchingCambiarEstadoRequisicion || fetchingListaFoliosRequisicionMateriales}>
                                             <Row justify='space-around'>
                                                  <Col>
                                                       <Button disabled={record.estado != 'Generado' ? true : !permisosUsuario.descargarPdfDelFolioDeVerificación} className="nuevoUsuario" onClick={()=>cancelarFolio(record)}>Sí</Button>
                                                  </Col>
                                             </Row>
                                        </Spin>}
                                        title="¿Estás seguro que deseas cancelar la requisición?"
                                        trigger="click"
                                   >
                                        <a className='editarIcono' disabled={record.estado != 'Generado' ? true : !permisosUsuario.descargarPdfDelFolioDeVerificación}> <CloseCircleOutlined /></a>
                                   </Popover>
                              </Tooltip>
                         </Col>
                         <Col>
                              <Tooltip placement="right" title='Editar Folio'>
                                   <a className='editarIcono' onClick={()=>onEditarFolio(record)} disabled={record.estado != 'Generado' ? true : !permisosUsuario.descargarPdfDelFolioDeVerificación}> <EditOutlined /></a>
                              </Tooltip>
                         </Col>
                    </Row>
               ),
          },{
               title: 'Descargar Folio',
               key: '',
               align: 'center',
               render: (record) => (
                    <a className='editarIcono' onClick={()=>onDescargarPdf(record)} disabled={!permisosUsuario.descargarPdfDelFolioDeVerificación}> <FilePdfOutlined /></a>
               ),
          }];

          const columnsAprobar = [{
               title: 'Número de Folio',
               dataIndex: 'folio',
               key: 'folio',
               sorter: true,
               align: 'center',
          }, {
               title: 'Fecha de Alta',
               dataIndex: 'fechaAlta',
               key: 'fechaAlta',
               sorter: true,
               align: 'center',
          }, {
               title: 'Solicitante',
               dataIndex: 'idUsuarioSolicitante',
               key: 'idUsuarioSolicitante',
               sorter: true,
               align: 'center',
          }, {
               title: 'Usuario Autorizó',
               dataIndex: 'nombreUsuarioAutoriza',
               key: 'nombreUsuarioAutoriza',
               align: 'center',
          },{
               title: 'Estado',
               dataIndex: 'estado',
               key: 'estado',
               sorter: true,
               align: 'center',
          },{
               title: 'Nota de SM cerrada',
               dataIndex: 'nota',
               key: 'nota',
               align: 'center',
          },{
               title: 'Total de la Solicitud',
               dataIndex: 'totalSolicitud',
               key: 'totalSolicitud',
               align: 'right',
          }, {
               title: 'Aprobar',
               key: '',
               align: 'center',
               render: (record) => (
                    <a className='editarIcono' onClick={()=>onAprobarFolio(record)} disabled={record.estado === 'Generado' || record.estado === 'Autorizado' || record.estado === 'Parcialmente surtida' ? false : true }> <CheckCircleOutlined /></a>
               ),
          }];

          const onAprobarFolio = (value) => {
               console.log('value', value)
               this.props.onShowModalAprobarRequisicion(value, this.state.pageAprobar, this.state.busquedaAprobar, this.state.ordenAprobar);
          }

          const onChangeTab = (value) => {
               this.setState({
                    page: 1,
                    busqueda: {
                         busqueda: '',
                         estado: '',
                         idEncargado: JSON.parse(sessionStorage.getItem('userInfo'))[0].idUsuario
                    },
                    orden: {
                         field: 'idRequisicionMateriales',
                         order: 'DESC',
                    },
                    pageAprobar: 1,
                    busquedaAprobar: {
                         busqueda: '',
                         estado: 'Autorizado,Surtido,Parcialmente surtida,Generado,Cerrado,Cancelado',
                         idEncargado: '',
                    },
                    ordenAprobar: {
                         field: 'idRequisicionMateriales',
                         order: 'DESC',
                    },
               })
               if(value === '1'){
                    onGetListaFoliosRequisicionMateriales(1, {busqueda: '', estado: '', idEncargado: JSON.parse(sessionStorage.getItem('userInfo'))[0].idUsuario}, {field: 'idRequisicionMateriales', order: 'DESC'}, userInfo[0].idUsuario, false);
               }
               else{
                    onGetListaFoliosRequisicionMateriales(1, {busqueda: '', estado: 'Autorizado,Surtido,Parcialmente surtida,Generado,Cerrado,Cancelado', idEncargado: ''}, {field: 'idRequisicionMateriales', order: 'DESC'}, userInfo[0].idUsuario, permisosUsuario.especialAlmacén ? false : true);
               }
          }

          return (
               <Tabs destroyInactiveTabPane={true} className='tabDashboard' defaultActiveKey="1" onChange={onChangeTab}>
                    <TabPane tab={<Tooltip placement="top" title='Solicitar'><InfoCircleOutlined className='efectoTab'/> </Tooltip>} key="1">
                         <Card className="cardCatalogos" title={<div className="headerLista">Solicitud de Materiales</div>}>
                              <Row type='flex' justify='space-around' gutter={[8, 8]}>
                                   <Col span={23}>
                                        <Search className='buscarModulos' placeholder='Ingresa folio o servicio a buscar' onSearch={value => onSearchFolio(value, 'solicitar')} />
                                   </Col>

                                   <Col span={1}>
                                        <Tooltip title='Nuevo Folio' placement="topRight">
                                             <Button className="iconTer" shape="circle" onClick={onCreateFolio} icon={<PlusOutlined />} />
                                        </Tooltip>
                                   </Col>

                                   <Col span={24}>
                                        <br />
                                        <Table
                                             size="small"
                                             rowKey="idRequisicionMateriales"
                                             columns={columns}
                                             dataSource={listaFoliosRequisicionMateriales}
                                             loading={fetchingListaFoliosRequisicionMateriales || fetchingDescargarRequisicion || this.props.fetchingCambiarEstadoRequisicion}
                                             locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar' }}
                                             onChange={handleTableChange}
                                             pagination={{ total: listaFoliosRequisicionMaterialesCantidad, simple: true, current: this.state.page }}
                                        />
                                   </Col>
                              </Row>
                              <ModalNuevaSolicitud />
                              <ModalModificarRegistro />
                              <ModalEditarSolicitud/>
                         </Card>
                    </TabPane>
                    <TabPane tab={<Tooltip placement="top" title='Aprobar'><CheckCircleOutlined className='efectoTab'/> </Tooltip>} key="2">
                         <Card className="cardCatalogos" title={<div className="headerLista">Aprobar Solicitudes de Materiales</div>}>
                              <Row type='flex' justify='space-around' gutter={[8, 8]}>
                                   <Col span={16}>
                                        <Search className='buscarModulos' placeholder='Ingresa folio o servicio a buscar' onSearch={value => onSearchFolio(value, 'aprobar')} />
                                   </Col>
                                   <Col span={8}>
                                        <Select allowClear
                                             mode='multiple'
                                             style={{ width: '100%' }}
                                             placeholder="Selecciona el estado a filtrar"
                                             onChange={onFiltrarEstado}
                                        >
                                             <Option key="Generado">Generado</Option>
                                             <Option key="Autorizado">Autorizado</Option>
                                             <Option key="Parcialmente surtida">Parcialmente surtida</Option>
                                             <Option key="Surtido">Surtido</Option>
                                             <Option key="Cerrado">Cerrado</Option>
                                             <Option key="Cancelado">Cancelado</Option>
                                        </Select>
                                   </Col>

                                   <Col span={24}>
                                        <br />
                                        <Table
                                             size="small"
                                             rowKey="idRequisicionMateriales"
                                             columns={columnsAprobar}
                                             dataSource={listaFoliosRequisicionMateriales}
                                             loading={fetchingListaFoliosRequisicionMateriales || this.props.fetchingCambiarEstadoRequisicion}
                                             locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar' }}
                                             onChange={handleTableChangeAprobar}
                                             pagination={{ total: listaFoliosRequisicionMaterialesCantidad, simple: true, current: this.state.pageAprobar }}
                                        />
                                   </Col>
                              </Row>
                         </Card>
                         <ModalAprobarFolio />
                    </TabPane>
               </Tabs>
          );
     }
}

const mapStateToProps = state => {
     return {
          fetchingListaFoliosRequisicionMateriales: state.AlmacenesReducer.fetchingListaFoliosRequisicionMateriales,
          listaFoliosRequisicionMateriales: state.AlmacenesReducer.listaFoliosRequisicionMateriales,
          listaFoliosRequisicionMaterialesCantidad: state.AlmacenesReducer.listaFoliosRequisicionMaterialesCantidad,
          fetchingDescargarRequisicion: state.AlmacenesReducer.fetchingDescargarRequisicion,
          fetchingCambiarEstadoRequisicion: state.AlmacenesReducer.fetchingCambiarEstadoRequisicion,
     }
}
const mapDispatchToProps = dispatch => {
     return {
          onShowModal: (page, busqueda, orden) => {
               dispatch({ type: "SHOW_MODAL_NUEVO_FOLIO_REQUISICION_MATERIALES", showModalRequisicionMateriales: true, page, busqueda, orden })
          },
          onGetListaFoliosRequisicionMateriales: (page, busqueda, orden, idUsuario, responsable) => {
               dispatch({ type: "LISTA_FOLIOS_REQUISICION_MATERIALES_REQUEST", page, busqueda, orden, idUsuario, responsable });
          },
          onGetInventario: (page, servicio, partida, campo, subFamilia, productosByIdRequisicion) => {
               dispatch({ type: "LISTA_INVENTARIO_REQUISICION_MATERIALES_REQUEST", page, servicio, partida, campo, subFamilia, productosByIdRequisicion })
          },
          onShowEditar: (requisicionSeleccionada, page, busqueda, orden) => {
               dispatch({ type: 'SHOW_MODAL_EDITAR_REQUISICION', showModalEditarRequisicion: true, requisicionSeleccionada, page, busqueda, orden })
          },
          onActualizarTabla: (listaSeleccionados, selectedRowKeys, pageMovimientos, hasSelected) => {
               dispatch({ type: 'ACTUALIZAR_TABLA_REQUISICION_MATERIALES', listaSeleccionados, selectedRowKeys, pageMovimientos, hasSelected });
          },
          onCancelarFolio: (idRequisicionMateriales, page, busqueda, orden, idUsuario) => {
               dispatch({ type: 'CAMBIAR_ESTADO_REQUISICION_REQUEST', idRequisicionMateriales, idEstado: 5, page, busqueda, orden, idUsuario })
          },
          onShowModalAprobarRequisicion: (requisicionSeleccionada, page, busqueda, orden) => {
               dispatch({ type: "SHOW_MODAL_APROBAR_REQUISICION", showModalAprobarRequisicion: true, requisicionSeleccionada, page, busqueda, orden })
          },
          onDescargarFolioRequisicion: (folio) => {
               dispatch({ type: 'DESCARGAR_REQUISICION_REQUEST', folio });
          },
          onSubfamiliasPorServicio: (idServicio) => {
               dispatch({ type: 'LISTA_SUBFAMILIAS_POR_SERVICIO_REQUEST', idServicio });
          },
          onGetSubfamiliasByPartida: (idPartida) => {
               dispatch({ type: 'LISTA_SUBFAMILIAS_BY_PARTIDA_REQUEST', idPartida })
          },
          onListaPartidasByServicio: (idServicio) => {
               dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_REQUEST', idServicio: idServicio})
          },
     }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequisicionMaterialesContainer);
