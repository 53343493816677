import React from 'react';
import { message, Form, Row, Col, Upload, Button, List, Modal, Spin, Select, Pagination, Card, Typography } from 'antd';
import { connect } from "react-redux";
import { UploadOutlined, DeleteOutlined, VerticalAlignBottomOutlined, VerticalAlignTopOutlined, FileExcelFilled } from '@ant-design/icons';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { confirm } = Modal;
const { Option } = Select;
const {Paragraph, Text} = Typography;
const { Dragger } = Upload;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class CargaMasivaActivosContainer extends React.Component {
    formRef = React.createRef();
    componentDidMount(){
        this.props.onGetArchivosEspera(1);
    }
    state = {
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
        loadings: [],
        page: 1,
        estado: 'revision',
        fileList: [],
    }
    enterLoading = index => {
        this.setState(({ loadings }) => {
            const newLoadings = [...loadings];
            newLoadings[index] = true;
        
            return {
                loadings: newLoadings,
            };
        });
            setTimeout(() => {
            this.setState(({ loadings }) => {
                const newLoadings = [...loadings];
                newLoadings[index] = false;
        
                return {
                loadings: newLoadings,
                };
            });
        }, 1000);
    };
    render() {
        const { limpiarFormulario, fetchingListaArchivosEspera, listaArchivosEspera, onEliminarArchivo, 
            onSubirArchivoActivo, fetchingSubirArchivoActivo, onGetArchivosEspera, onProcesarArchivoActivo,
            onExportarErroresArchivoActivo, listaArchivosEsperaCantidad, onLimpiarFormulario
        } = this.props;

        const onFinish = formulario => {
            onSubirArchivoActivo(formulario, this.state.page);
        };
        if (limpiarFormulario && limpiarFormulario === 'cargarArchivoActivo') {
            this.formRef.current.resetFields(['csv']);
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
            onLimpiarFormulario();
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };
        const onChangePDF = (info) => {
            let fileList = [...info.fileList];
   
            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            fileList = fileList.slice(-1);
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else {
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                    fileList
                })
            }
            if (info.file.status === 'done') {
                var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,40}$");
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['csv']);
                    this.setState({fileList: []});
                }
                else {
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true,
                        fileList
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['csv']);
                    this.setState({fileList: []});
                }
                else {
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true,
                        fileList
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }

        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
                fileList: []
            });
        }

        const eliminarArchivo = (listaArchivoSeleccionado) => {
            const page = this.state.page;
            confirm({
                title: '¿Estás seguro que deseas cancelar el archivo '+ listaArchivoSeleccionado.archivo +'?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEliminarArchivo(listaArchivoSeleccionado.idArchivoMasivo, page);
                },
                onCancel() {
                console.log('Cancel');
                },
            });	  
        };

        const onEstadoArchivos = (estado) => {
            this.setState({
                estado: estado,
                page: 1,
            });
            onGetArchivosEspera(1, estado);
        }

        const procesarArchivo = (archivo) => {
            const page = this.state.page;
            confirm({
                title: '¿Estás seguro que deseas procesar el archivo '+ archivo.archivo +'?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onProcesarArchivoActivo(archivo.idArchivoMasivo, page);
                },
                onCancel() {
                console.log('Cancel');
                },
            });	  
            
        }

        const descargarErroresArchivo = (archivo) => {
            onExportarErroresArchivoActivo(archivo.idArchivoMasivo)
        }

        const cambiarPagina = (page) => {
            this.setState({
                page: page,
            })
            onGetArchivosEspera(page, this.state.estado);
        }
        return (
        <Card className="cardCatalogos" title={<div className="headerLista">Carga Masiva de Activos</div>}>
            <Row justify='space-between' style={{textAlign: 'left'}}>
                <Col span={11}>
                    <Spin spinning={fetchingSubirArchivoActivo}>
                        <Form {...layout}
                        name="formulario"
                        onFinish={onFinish}
                        ref={this.formRef}>
                            <Row justify='space-around'>
                                {/* <Col span={24}>
                                    <p>Favor de agregar un archivo de extensión CSV. El nombre del archivo no debe contener caracteres especiales (*, /, acentos,.,comas,etc...) <br/>Ejemplo: tabladatos.csv <br/> <strong>Nota: Revisa que el formato no haya tenido pérdidas en data antes de subirlo a revisión.</strong></p>
                                    <Form.Item
                                        label="Archivo CSV"
                                        name="csv"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.csv' multiple={false}>
                                            {this.state.filePDF === false ? (
                                            <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                                <UploadOutlined /> Click para subir archivo CSV
                                            </Button>
                                            ):false}
                                        </Upload>
                                    </Form.Item>
                                </Col> */}
                                <Col span={24}>
                                        <Paragraph>
                                             Favor de agregar un archivo de extensión CSV. El nombre del archivo no debe contener caracteres especiales (*, /, acentos,.,comas,etc.).
                                        </Paragraph>
                                            
                                        <Paragraph>
                                             <pre style={{backgroundColor:'#fafafa', borderRadius:'1em', padding:'.5em'}}>Ejemplo: tabladatos.csv</pre>
                                        </Paragraph>

                                        <Paragraph>
                                             <Text strong>
                                                  Nota: Revisa que el formato no haya tenido pérdidas en data antes de subirlo a revisión.
                                             </Text>
                                        </Paragraph>

                                        <Form.Item label="Archivo CSV" name="csv" rules={[{ required: true, message: 'Favor de llenar el campo', }]}  >
                                             <Dragger customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.csv' multiple={false} style={{borderRadius:'1em'}} fileList={this.state.fileList}>
                                                  <Row justify='center'>
                                                       <FileExcelFilled className='iconTabPane'/>
                                                       <Col span={24} style={{paddingTop:'1em'}}>
                                                            <Text strong> Click o arrastra a ésta área el achivo </Text>
                                                       </Col>
                                                  </Row>
                                             </Dragger>
                                        </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Button className="btnFiltrar" style={{ width: '100%' }} loading={this.state.loadings[1]} onClick={() => this.enterLoading(1)}><a href="https://appcontrollersolutions.com/FormatoCargaMasivaActivos.csv">Descargar Formato</a></Button>
                                </Col>
                                <Col span={11}>
                                    <Button disabled={!this.state.filePDF} className="btnFormato" style={{ width: '100%' }} htmlType="submit">Cargar Archivo CSV</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Col>
                <Col span={11}>
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Selecciona el estado a buscar"
                        onChange={onEstadoArchivos}
                        defaultValue="revision"
                    >
                        <Option key='revision'>Revisión</Option>
                        <Option key='cancelado'>Cancelado</Option>
                        <Option key='finalizado'>Finalizado</Option>
                    </Select>
                    {this.state.estado === 'revision' ? (
                    <List
                    className="demo-loadmore-list"
                    locale={{ emptyText: 'Sin datos' }}
                    itemLayout="horizontal"
                    loading={fetchingListaArchivosEspera}
                    dataSource={listaArchivosEspera}
                    renderItem={item => (
                       <List.Item className='listaHover' actions={[
                            <div className="iconoLista borrarIcono" onClick={(e) => { e.stopPropagation(); eliminarArchivo(item); } }><DeleteOutlined /></div>,
                            <div className="iconoLista cargaIcono" onClick={(e) => { e.stopPropagation(); procesarArchivo(item); } }><VerticalAlignTopOutlined /></div>
                        ]}>
                       <List.Item.Meta
                            title={ 'Nombre de Archivo:  ' + item.archivo}
                            description={'Fecha de alta: '+ item.fechaAlta }
                        />
                        </List.Item>
                    )}
                    /> 
                    ):(
                    <List
                    className="demo-loadmore-list"
                    locale={{ emptyText: 'Sin datos' }}
                    itemLayout="horizontal"
                    loading={fetchingListaArchivosEspera}
                    dataSource={listaArchivosEspera}
                    renderItem={item => (
                       <List.Item className='listaHover' actions={[
                            <div>
                                {this.state.estado === 'finalizado' ? (
                                    <div className="iconoLista cargaIcono" onClick={(e) => { e.stopPropagation(); descargarErroresArchivo(item); } }><VerticalAlignBottomOutlined /></div>
                                ):false}
                            </div>
                        ]}>
                       <List.Item.Meta
                            title={ 'Nombre de Archivo:  ' + item.archivo}
                            description={'Fecha de alta: '+ item.fechaAlta }
                        />
                        </List.Item>
                    )}
                    /> 
                    )}
                    <Pagination total={listaArchivosEsperaCantidad} simple={true} current={this.state.page} onChange={cambiarPagina} />
                </Col>
            </Row>
        </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        vistaDashboard: state.ConfiguracionYSeguridadReducer.vistaDashboard,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaArchivosEspera: state.AlmacenesReducer.fetchingListaArchivosEspera,
        listaArchivosEspera: state.AlmacenesReducer.listaArchivosEspera,
        listaArchivosEsperaCantidad: state.AlmacenesReducer.listaArchivosEsperaCantidad,
        fetchingSubirArchivoActivo: state.AlmacenesReducer.fetchingSubirArchivoActivo,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onEliminarArchivo: (idArchivoMasivo, page) => {
			dispatch({ type: "ELIMINAR_ARCHIVO_ACTIVO_REQUEST", idArchivoMasivo: idArchivoMasivo, page: page });
		},
        onGetArchivosEspera: (page, estado) => {
            dispatch({ type: "GET_ARCHIVOS_ESPERA_REQUEST", page, estado });
        },
        onSubirArchivoActivo: (formulario, page) => {
            dispatch({ type: 'SUBIR_ARCHIVO_ACTIVO_REQUEST', formulario, page });
        },
        onExportarErroresArchivoActivo: (idArchivoMasivo) => {
            dispatch({ type: 'EXPORTAR_ERRORES_ARCHIVO_REQUEST', idArchivoMasivo})
        },
        onProcesarArchivoActivo: (idArchivoMasivo, page) => {
            dispatch({ type: 'PROCESAR_ARCHIVO_REQUEST', idArchivoMasivo, page})
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CargaMasivaActivosContainer);