import React from 'react';
import { Input, Row, Col, Button, Switch, Table, Modal, Tooltip } from 'antd';
import { connect } from "react-redux";
import { EditOutlined, FileSearchOutlined, PlusOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

import ModalNuevaVariable from './ModalNuevaVariable';
import ModalEditarVariable from './ModalEditarVariable';
import ModalDetalleVariable from './ModalDetalleVariable';

const { Search } = Input;

const confirm = Modal.confirm;

class VariablesDeMedicionContainer extends React.Component {
     formRef = React.createRef();

     componentDidMount() {
          this.props.onGetUnidadesMedicion();   
          this.props.onGetCategoriasMedicion();
          this.props.onGetVariablesMedicion(1, this.state.busqueda);       
     }

     state = {
          page: 1,
          busqueda: '',
     }


     render() {
          const {
               listaVariablesMedicionBusqueda,
               listaVariablesMedicionCantidad,
               fetchingListaVariablesMedicionBusqueda,
               onShowModalNuevaVariable,
               onGetVariablesMedicion,
               onEstadoVariablesMedicion,
               onShowModalDetalle,
               onShowModalEditarVariable,
          } = this.props;

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if (permisosUsuario[0]) {
               permisosUsuario = JSON.parse(permisosUsuario);
          }

          const columns = [
               {title: 'Nombre', dataIndex: 'nombre', key: 'nombre'},
               {title: 'Descripción', dataIndex: 'descripcion', key: 'descripcion'}, 
               {title: 'Detalle', key: 'detalle', align: 'center',
               render: (record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.detalleDeVariableDeMedicion}  onClick={() => onDetalle(record)}><FileSearchOutlined /></a>
               ),},
               {title: 'Activo', dataIndex: 'activo', key: 'activo', align: 'center',
               render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.variableDeMedicionActivoinactivo} onChange = {() => handleDesactivar(record)} />
               ),}, 
               {title: 'Editar', key: 'editar', align: 'center',
               render: (record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarVariableDeMedicion}  onClick={() => onEditar(record)}><EditOutlined /></a>
               ),},
          ];

          const onBuscarVariableMedicion = (param) => {
               const busqueda = param;
               this.setState({ busqueda: busqueda, page: 1 });
               
               onGetVariablesMedicion(1, busqueda);
          };

          const handleDesactivar = (key) => {
               console.log(key.idVariableMedicion);
               let page = this.state.page;
               let busqueda = this.state.busqueda;
               confirm({
                    title: '¿Estás seguro que deseas cambiar el estado de la variable de medición "' + key.nombre + '"?',
                    content: '',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                         onEstadoVariablesMedicion(key.idVariableMedicion, page, busqueda);
                    },
                    onCancel() {
                         console.log('Cancel');
                    },
               });
          }

          const onEditar = (key) => {
               onShowModalEditarVariable(key, this.state.page, this.state.busqueda);
          };

          const onDetalle = (key) => {
               console.log('key', key)
               onShowModalDetalle(key, this.state.page);
          };

          const onNuevaVariableMedicion = () => {
               onShowModalNuevaVariable(this.state.page);
          }

          const handleTableChange = (pagination) => {
               this.setState({ page: pagination.current,});
          }

          

          return (
               <Row justify='space-around' style={{ textAlign: 'left' }} gutter={[8,8]}>   
                    <Col span={22}>
                         <Search className='buscarModulos' placeholder='Ingresar nombre a buscar' onSearch={value => onBuscarVariableMedicion(value)} />
                    </Col>
                    {/* <Col span={8}>
                         <Button onClick={onNuevaVariableMedicion} disabled={!permisosUsuario.crearVariableDeMedicion} className='nuevoUsuario'>Nueva Variable de Medición</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nueva Variable de Medición' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevaVariableMedicion} disabled={!permisosUsuario.crearVariableDeMedicion} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                         <Table
                              size="small"
                              rowKey="idVariableMedicion"
                              columns={columns}
                              dataSource={listaVariablesMedicionBusqueda}
                              loading={fetchingListaVariablesMedicionBusqueda}
                              locale={{ emptyText: 'Sin datos' }}
                              onChange={handleTableChange}
                              pagination={{ total: listaVariablesMedicionCantidad, simple: true, current: this.state.page }}
                         />
                    </Col>     
               
                    <ModalNuevaVariable />
                    <ModalEditarVariable />
                    <ModalDetalleVariable />                 
               </Row>
          );
     }
}

const mapStateToProps = state => {
     return {
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          listaVariablesMedicionBusqueda: state.ServiciosPublicosReducer.listaVariablesMedicionBusqueda,
          fetchingListaVariablesMedicionBusqueda: state.ServiciosPublicosReducer.fetchingListaVariablesMedicionBusqueda,
          listaVariablesMedicionCantidad: state.ServiciosPublicosReducer.listaVariablesMedicionCantidad,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onShowModalNuevaVariable: (page) => {
               dispatch({ type: 'SHOW_MODAL_NUEVA_VARIABLE_MEDICION', showModalNuevaVariableMedicion: true, page });
          },
          onGetCategoriasMedicion: () => {
               dispatch({ type: 'LISTA_CATEGORIAS_MEDICION_ACTIVOS_REQUEST' });
          },
          onGetUnidadesMedicion: () => {
               dispatch({ type: 'LISTA_UNIDADES_MEDICION_ACTIVOS_REQUEST' });
          },
          onGetVariablesMedicion: (page, busqueda) => {
               dispatch({ type: 'LISTA_VARIABLES_MEDICION_REQUEST', page, busqueda });
          },
          onEstadoVariablesMedicion: (idVariableMedicion, page, busqueda) => {
               dispatch({ type: 'ESTADO_VARIABLES_MEDICION_REQUEST', idVariableMedicion, page, busqueda });
          },
          onShowModalDetalle: (variableMedicionSeleccionado, page) => {
               dispatch({ type: 'SHOW_MODAL_DETALLE_VARIABLE', showModalDetalleVariable: true, variableMedicionSeleccionado, page });
          },
          onShowModalEditarVariable: (variableMedicionSeleccionado, page, busqueda) => {
               dispatch({ type: 'SHOW_MODAL_EDITAR_VARIABLE_MEDICION', showModalEditarVariableMedicion: true, variableMedicionSeleccionado, page, busqueda });
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(VariablesDeMedicionContainer);
