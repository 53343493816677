import React, { Component } from 'react';
import { Form, Input, Button, Modal, Row, Col, Spin, Switch, InputNumber, Tooltip } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";


const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalNuevoServicioPublico extends Component {
    formRef = React.createRef();
    render() {
        const { fetchingNuevoServicioPublico, showModalNuevoServicioPublico, onNuevoServicioPersonal, onShowModalNuevoServicioPublico,
            limpiarFormulario, onLimpiarFormulario, busqueda, page, 
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            if(formulario.cantidadDeposito < 0){
                formulario.cantidadDeposito = 0;
            }
            onNuevoServicioPersonal(formulario, page, busqueda );
        };
        

        if (limpiarFormulario && limpiarFormulario === 'crearServicioPublico') {
            this.formRef.current.resetFields();
            onLimpiarFormulario();
        }

        const handleCancel = () => {
            onShowModalNuevoServicioPublico();
            this.formRef.current.resetFields();
        }
        const cantidadEntera = (value) => {
            const cantidadDeposito = parseInt(value);
            this.formRef.current.setFieldsValue({'cantidadDeposito': cantidadDeposito});
        }

        return (
            <Modal
            visible={showModalNuevoServicioPublico}
            title='Nuevo Servicio Público'
            onCancel={handleCancel}
            width={'40%'}
            footer={false}
            >
                <Spin spinning={fetchingNuevoServicioPublico}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={24}>
                                <Tooltip title="Este campo no podrá ser editado en un futuro">
                                    <Form.Item
                                        label="Nombre del servicio público"
                                        name="nombre"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Input maxLength={150} placeholder="Ingresa el nombre del servicio público" />
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Nombre del depósito de almacenamiento"
                                    name="nombreDeposito"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={150} placeholder="Ingresa el nombre del depósito de almacenamiento" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Cantidad de depósitos de almacenamiento"
                                    name="cantidadDeposito"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <InputNumber onChange={cantidadEntera} min={0} placeholder="Ingresa la cantidad de depósitos de almacenamiento" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="¿Cálculo diferenciado?"
                                    name="calculo"
                                    valuePropName="checked"
                                >
                                    <Switch checkedChildren={'Sí'} unCheckedChildren={'No'}/>
                                </Form.Item>
                            </Col>

                            <Col span={11}>
                                <Form.Item
                                    label="Abastecimiento"
                                    name="abastecimiento"
                                    valuePropName="checked"
                                >
                                    <Switch checkedChildren={'Sí'} unCheckedChildren={'No'}/>
                                </Form.Item>
                            </Col>

                            <Col span={23} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Crear Servicio Público</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingNuevoServicioPublico: state.ServiciosPublicosReducer.fetchingNuevoServicioPublico,
        showModalNuevoServicioPublico: state.ServiciosPublicosReducer.showModalNuevoServicioPublico,
        listaTurnos: state.ServiciosPublicosReducer.listaTurnos,
        fetchingListaTurnos: state.ServiciosPublicosReducer.fetchingListaTurnos,
        busqueda: state.ServiciosPublicosReducer.busqueda,
        orden: state.ServiciosPublicosReducer.orden,
        page: state.ServiciosPublicosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalNuevoServicioPublico: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_SERVICIO_PUBLICO', showModalNuevoServicioPublico: false })
        },
        onNuevoServicioPersonal: ( formulario, page, busqueda ) => {
            dispatch({ type: "NUEVO_SERVICIO_PUBLICO_REQUEST", formulario, page, busqueda });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoServicioPublico);