import React, { Component } from 'react';
import { Form, Input, Button, Col, Spin, Modal, Row } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalNuevaCategoriaDeMedicion extends Component {
    formRef = React.createRef();
    render() {
        const { fetchingNuevaCategoriaDeMedicion, showModalNuevaCategoriaDeMedicion, onShowModalNuevaCategoriaDeMedicion,
             onNuevaCategoriaDeMedicion, page, limpiarFormulario } = this.props

        const onFinish = formulario => {
            console.log('Success:' , formulario);
            onNuevaCategoriaDeMedicion(formulario, page);
        }

        if (limpiarFormulario && limpiarFormulario==='crearCategoria') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo);
        };

        const handleCancel = () => {
            onShowModalNuevaCategoriaDeMedicion();
            this.formRef.current.resetFields();
        }
        return (
            <Modal
                visible={showModalNuevaCategoriaDeMedicion}
                title='Nueva Categoria de Medición'
                onCancel={handleCancel}
                footer={false}
            >
                <Spin spinning={fetchingNuevaCategoriaDeMedicion}>
                    <Form {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        
                        ref={this.formRef}
                    >
                        <Form.Item
                            label="Categoría de medición"
                            name="categoriaUnidadMedida"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <Input
                            pattern="^^[ñíóáéú a-zA-Z ]+$"
                            title="Categoría de Medición debe tener letras." 
                            maxLength={80} placeholder='Categoría de medición' />
                        </Form.Item>
                        <Form.Item
                            label="Clave de categoría"
                            name="claveCategoria"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <Input
                            maxLength={80} placeholder='Clave de categoría' />
                        </Form.Item>
                        <Row justify='space-around'>
                            <Col span={12}>
                                <Button style={{width:'100%'}} className="btnFiltrar" htmlType="submit">Crear Categoría de Medición</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>

            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingNuevaCategoriaDeMedicion: state.AlmacenesReducer.fetchingNuevaCategoriaDeMedicion,
        showModalNuevaCategoriaDeMedicion: state.AlmacenesReducer.showModalNuevaCategoriaDeMedicion,
        page: state.AlmacenesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevaCategoriaDeMedicion: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVA_CATEGORIA_MEDICION', showModalNuevaCategoriaDeMedicion: false });
        },
        onNuevaCategoriaDeMedicion: (formulario, page) => {
            dispatch( { type: 'NUEVA_CATEGORIA_MEDICION_REQUEST', formulario: formulario, page:page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevaCategoriaDeMedicion);