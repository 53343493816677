import React from 'react';
import { Row, Col, Modal, Calendar, Tag} from 'antd';
import { connect } from "react-redux";
import {  } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import moment from 'moment';

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };

class ModalFecha extends React.Component {
     formRef = React.createRef();

     componentDidMount() {
          
     }

     state = {
          page: 1,
     }


     render() {
          const { 
               onCloseModalFecha,
               programaRMPSeleccionado,
               programa,
               showModalFechaRMP,
               page,
               busqueda,
               onShowUbicacion,
               asignacionRMPSeleccionada,
               typeModal,
               onShowActualizar,
               idsServicios
          } = this.props;

          const handleCancel = () => {
               onCloseModalFecha(idsServicios);
               console.log('typeModal', typeModal)
               if(typeModal === 'detalle'){
                    onShowUbicacion(programaRMPSeleccionado, programa, page, busqueda);
               } else if (typeModal === 'actualizar') {
                    onShowActualizar(programaRMPSeleccionado, page, busqueda, programa, idsServicios);
               }
          };

          

          let nombreUbiAct = '';
          let fechas = [];
          if(programaRMPSeleccionado){
               if(asignacionRMPSeleccionada){
                    if(programa === "Rutina"){
                         nombreUbiAct = asignacionRMPSeleccionada.ubicacionCOA 
                    } else {
                         nombreUbiAct = asignacionRMPSeleccionada.descripcion + ' (' + asignacionRMPSeleccionada.codigoInterno + ')'
                    }

                    if(programaRMPSeleccionado.programacionRutinasByIdProgramacionRutina){

                         for(let i = 0; i < programaRMPSeleccionado.programacionRutinasByIdProgramacionRutina.length; i++){
                              if( programaRMPSeleccionado.programacionRutinasByIdProgramacionRutina[i].idAsignacionServicioPlanificado === asignacionRMPSeleccionada.idAsignacionServicioPlanificado){

                                   if(programaRMPSeleccionado.programacionRutinasByIdProgramacionRutina[i].fechasRutinasByIdProgramacion){

                                        for(let j = 0; j < programaRMPSeleccionado.programacionRutinasByIdProgramacionRutina[i].fechasRutinasByIdProgramacion.length; j++){
                                             let fchSplit = programaRMPSeleccionado.programacionRutinasByIdProgramacionRutina[i].fechasRutinasByIdProgramacion[j].fecha;
                                             fchSplit = fchSplit.split('T')[0];
                                             let fch = [
                                                  fchSplit
                                             ]
                                             fechas.push(fch);
                                        }

                                   }
                                   
                              }
                         }
                    }
               }
          }

          function getListData(value) {
               let listData;
               for(let i = 0; i < fechas.length; i ++){
                    if(value.format('YYYY-MM-DD') === fechas[i][0]) {
                         listData = [
                              { type: 'warning', content: programa },
                         ];
                    }
               }
               return listData || [];
          }
          
          function dateCellRender(value) {
          const listData = getListData(value);
          let style = { textAlign: 'center'};

               return (
                    <ul className="events">
                         {listData.map(item => (
                              <li key={item.content}>
                                   <div style={style}>
                                        <br/>
                                        <Tag color="gold" className='tag-rad' style={{ width:'90%', textAlign:'center' }}>{item.content} </Tag>
                                        <br/>
                                   </div>
                              </li>
                         ))}
                    </ul>
               );
          }

          let today = new Date();
          let yyyy = today.getFullYear() + 1;
          let beYear = yyyy + '-01-01';
          let enYear = (yyyy - 2) + '-12-31';

          function disabledDate(current) {
               let customDate = enYear;
               return current && current < moment(customDate, "YYYY-MM-DD");
          }

          return (
               <>
               { programaRMPSeleccionado ? (
                    <Modal
                         visible={showModalFechaRMP}
                         title={programaRMPSeleccionado.rutina + ' / ' + nombreUbiAct}
                         onCancel={handleCancel}
                         footer={false}
                         width={'65%'}
                    >
                              <Row justify='space-around'>
                                   <Col span={24}>
                                        <Calendar 
                                             dateCellRender={dateCellRender}
                                             disabledDate={disabledDate}
                                             
                                        />
                                   </Col>
                              </Row>      
                    </Modal>
               ) : false }   
               </>
          );             
     }
}

const mapStateToProps = state => {
     return {
          showModalFechaRMP: state.ServiciosPlanificadosReducer.showModalFechaRMP,
          programaRMPSeleccionado: state.ServiciosPlanificadosReducer.programaRMPSeleccionado,
          programa: state.ServiciosPlanificadosReducer.programa,
          page: state.ServiciosPlanificadosReducer.page,
          busqueda: state.ServiciosPlanificadosReducer.busqueda,
          asignacionRMPSeleccionada: state.ServiciosPlanificadosReducer.asignacionRMPSeleccionada,
          typeModal: state.ServiciosPlanificadosReducer.typeModal,
          idsServicios: state.ServiciosPlanificadosReducer.idsServicios,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onCloseModalFecha: (idsServicios) => {
               dispatch({ type: 'SHOW_MODAL_FECHAS_RMP', showModalFechaRMP: false, idsServicios });
          },
          onShowUbicacion: (programaRMPSeleccionado, programa, page, busqueda) => {
               dispatch({ type: 'SHOW_MODAL_DETALLE_UBICACION_RMP', showModalDetalleUbicacionRMP: true, programaRMPSeleccionado, programa, page, busqueda });
          },
          onShowActualizar: (programaRMPSeleccionado, page, busqueda, programa, idsServicios) => {
               dispatch({ type: 'SHOW_MODAL_ACTUALIZAR_RM', showModalActualizarRM: true, programaRMPSeleccionado, page, busqueda, programa, idsServicios });
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalFecha);