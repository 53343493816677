import React, { Component } from 'react';
import {Card, Row, Col, Modal, List, Table, message, Button, Select} from 'antd';
import { EditOutlined, LockOutlined, UnlockOutlined  } from '@ant-design/icons';
import {connect} from 'react-redux';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css'

import ModalNuevoINPC0 from './ModalNuevoINPC0';
import ModalEditarINPC0 from './ModalEditarINPC0';
import ModalNuevoPARv from './ModalNuevoPARv';

const confirm = Modal.confirm;
const { Option } = Select;

class ValoresPagoAnualContainer extends Component {
    componentDidMount() {
        this.props.onGetValoresPagoAnual(1);
        this.props.onGetPARv(1, this.state.busqueda);
        this.props.onRequestInfoProyecto();
    }
    state = {
        busqueda: {param: ''},
        page: 1,
        fetchingListaAños: false,
        listaAños: [],
    }
    render() {

        const { fetchingValoresPagoAnual, listaValoresPagoAnual,
                onEstadoINCP0, onShowModalAgregarINPC0, onShowModalEditarINPC0,
                listaPARvCantidad, listaPARv, fetchingListaPARv, onShowModalNuevoPARv,
                onGetPARv, listaInfoProyecto,
        } =  this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        };

        let data = []
        if (listaInfoProyecto) {
            const { pagoReferenciaVar, pagoAnualInversion, pagoAnualReferencia, poblacionPtvMax, fechaInicioOperacionDetalleMecanismos, fechaInicioDetalleMecanismos} = listaInfoProyecto;
            
            let numberpagoReferenciaVar = (new Intl.NumberFormat().format(pagoReferenciaVar));
            let numberpagoAnualInversion = (new Intl.NumberFormat().format(pagoAnualInversion));
            let numberpagoAnualReferencia = (new Intl.NumberFormat().format(pagoAnualReferencia));
            let numberpoblacionPtvMax = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(poblacionPtvMax)
                  

            data = [
                {
                    title: 'Pago Anual de Inversión',
                    data: '$ '+numberpagoAnualInversion,
                },
                {
                    title: "Pago Anual Referencia Fijo",
                    data: '$ '+numberpagoAnualReferencia,
                },
                {
                    title: "Pago Anual Referencia Variable",
                    data: '$ '+numberpagoReferenciaVar,
                },
                {
                    title: "Ptv",
                    data: numberpoblacionPtvMax,
                },
                {
                    title: "Fecha Inicio de Operaciones",
                    data: fechaInicioOperacionDetalleMecanismos
                },
                {
                    title: "Fecha Base",
                    data: fechaInicioDetalleMecanismos
                }]
        }

        const handleDesactivarINPC0 = (key) => {
            console.log('key :>> ', key);
            let page = this.state.page
            if(!key.estatus) {
                return message.warning('El INPC0 se encuentra cerrado')
            } else {

                confirm({
                    title: `¿Seguro de cerrar este valor "${key.inpc0}"? no será posible cambiarlo nuevamente.`,
                    content: '',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                        onEstadoINCP0(key.idAnoContractual, page);
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });

            }

        };

        const handleEditarINPC0 = (key) => {
            let { page } = this.state;

            if (listaValoresPagoAnual) {

                listaValoresPagoAnual.forEach(item => {
                   if (!item.estatus) {
                       return message.warning('El valor INCP0 se encuentra desactivado, no puede editarse')
                   } else {
                       return  onShowModalEditarINPC0(key, page );
                   }
                });

            }

           
        };
        
        const handleAgregarINPC0 = () => {
            console.log('entro a agregar');
            let { page } = this.state;

            
           if ( listaValoresPagoAnual.length > 0 ){

               return message.warning('No se puede agregar nuevamente el iNPC0', 5);
                                                
            } else {

                return onShowModalAgregarINPC0(page);
                
            }     
                                
            
        };

        const handleAgregarPARv = () => {
            let { page, busqueda } = this.state;

            onShowModalNuevoPARv(page, busqueda);
        }

        const columns = [{
            title: 'INPC0',
            dataIndex: 'inpc0',
            key: 'inpc0',
            align: 'center'
        },{
            title: 'Estado INPC0',
            dataIndex: 'estatus',
            key: 'estatus',
            align: 'center',  
            render: (text, record) => (
                <div>
                {  
                   !record.estatus ? (
                    <a className='editarIcono' disabled onClick = {() => handleDesactivarINPC0(record)}> <LockOutlined />
                    </a>
                   ) : (
                     <a className='editarIcono' disabled={!permisosUsuario.estadoInpc0} onClick = {() => handleDesactivarINPC0(record)}>  <UnlockOutlined />
                     </a>
                   ) 
                }
                   
                </div>
            ),
        },{
            title: 'Editar INPC0',
            key: 'editar',
            align: 'center',
            render: (record) => (
               <a className='editarIcono' disabled={!permisosUsuario.editarInpc0 || !record.estatus } onClick={ () => handleEditarINPC0(record) }> < EditOutlined />
               </a>
            ),          
        }];

        
        const columnsPARv = [{
            title: 'PARv',
            dataIndex: 'parV',
            key: 'parV',
            align: 'center'
        },{
            title: 'Numero de oficio',
            dataIndex: 'numeroOficio',
            key: 'numeroOficio',
            align: 'center'
        },{
            title: 'Fecha inicio de vigencia',
            dataIndex: 'fechaInicioVigencia',
            key: 'fechaInicioVigencia',
            align: 'center'
        },{
            title: 'Fecha fin de vigencia',
            dataIndex: 'fechaFinVigencia',
            key: 'fechaFinVigencia',
            align: 'center'
        }];

        const onListaAños = (value) => {
            let d = new Date();
            let list = [];
            console.log('listaInfoProyecto', listaInfoProyecto)
            if(listaInfoProyecto){
                list.push(parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2]))
                for(let i = 0; i <listaInfoProyecto.vigencia; i++){
                    list.push(parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2]) + parseInt(i) +  1)
                }
            }

            this.setState({
               listaAños: list
            }); 
        };

        const onChangeAño = (value) => {
            if (value) {
                let añoSelected = value;
                let busqueda = {param: añoSelected};
                let page = this.state.page;
                
                this.setState({
                    busqueda
                });

                onGetPARv(page, busqueda);
            };
        };

        const handleTableChange = (pagination) => {
            this.setState({ 
                page: pagination.current
            })
            onGetPARv(pagination.current, this.state.busqueda);
        }

        return (
        <div>  
            <Card className="cardCatalogos" title={<div className='headerLista'>Valores de Pago Anual</div>}>
                {listaValoresPagoAnual ? (
                    <>
                        <Row type="flex" justify='space-between'>
                            <Col span={10}>
                                <List
                                    className="listaCatalogos"
                                    bordered
                                    dataSource={data}
                                    renderItem={item => (
                                       <List.Item className='listaHover' className='listaHover'>
                                            <Col span={16}>
                                                <strong>{item.title}</strong>
                                            </Col>
                                            <Col span={6}>
                                                {item.data !== null ? item.data : '-'}
                                            </Col>
                                            
                                        </List.Item>
                                    )}
                                />
                            </Col>
                            
                            <Col span={8}>
                                <Table
                                    size="small"
                                    rowKey="idAnoContractual"
                                    columns={columns}
                                    dataSource={listaValoresPagoAnual}
                                    loading={fetchingValoresPagoAnual}
                                    locale={{ emptyText: 'Sin datos' }}
                                    pagination={false}
                                />
                            </Col>
                            
                            <Col span={4}>
                                <Button onClick={handleAgregarINPC0} disabled={!permisosUsuario.crearInpc0 ? true : listaValoresPagoAnual[0] } className='nuevoUsuario'>Nuevo INPC0</Button>
                            </Col>
                                     
                        </Row>
                    </>
                ) : false}
                < ModalNuevoINPC0 />                        
                < ModalEditarINPC0 />  
                           
            </Card>

            <br></br> 
            <Card className="cardCatalogos">
                <Row type="flex" justify='space-between'>
                    <Col span={5}>
                        <Select allowClear
                            style={{ width: '100%' }}
                            placeholder="Seleccione el año"
                            loading={this.state.fetchingListaAños}
                            onClick={onListaAños}
                            onChange={onChangeAño}
                        >
                            {this.state.listaAños.map(option => <Option key={option}>{option}</Option>)}
                        </Select>
                    </Col>
                    <Col span={8}>

                        <Button onClick={handleAgregarPARv} disabled={!permisosUsuario.crearParv} className='nuevoUsuario'>Nuevo PARv</Button>
                    </Col>
                    <Col span={24}>
                        <br />
                        <Table
                            size="small"
                            rowKey="idParV"
                            columns={columnsPARv}
                            dataSource={listaPARv}
                            loading={fetchingListaPARv}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaPARvCantidad, simple: true }}
                        />
                    </Col>
                </Row>
            </Card>
            <ModalNuevoPARv/>
        </div>  
        );
    }
}
 const mapStateToProps = (state) => {
    return {
        fetchingValoresPagoAnual: state.MecanismosDePagosReducer.fetchingValoresPagoAnual,
        listaValoresPagoAnual: state.MecanismosDePagosReducer.listaValoresPagoAnual,
        listaPARv: state.MecanismosDePagosReducer.listaPARv,
        fetchingListaPARv: state.MecanismosDePagosReducer.fetchingListaPARv,
        listaPARvCantidad: state.MecanismosDePagosReducer.listaPARvCantidad,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
    }
} 

const mapDispatchToProps = (dispatch) => {
    return {
        onGetValoresPagoAnual: (page) => {
            dispatch({ type: "LISTA_VALORES_PAGO_ANUAL_REQUEST", page});
        },
        onEstadoINCP0: (idAnoContractual, page) => {
            dispatch({ type: "ESTADO_INPC0_REQUEST", idAnoContractual, page})
        },
        onShowModalAgregarINPC0: (page) => {
            dispatch({ type: "SHOW_MODAL_CREAR_INPC0", showModalNuevoINPC0: true, page})
        },
        onShowModalEditarINPC0: (inpc0Seleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_INPC0", showModalEditarINPC0: true, inpc0Seleccionado, page})
        },
        onGetPARv: (page, busqueda) => {
            dispatch({ type: "LISTA_PARv_REQUEST", page, busqueda});
        },
        onShowModalNuevoPARv: (page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_CREAR_PARv", showModalCrearPARv: true, page, busqueda});
        },
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        },
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ValoresPagoAnualContainer);