import React, { Component } from 'react';
import { Form, Input, Button, Row, Col, Spin, Modal, Select } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';
import moment from 'moment';

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalNuevaUnidadDeMedicion extends Component {
    formRef = React.createRef();

    componentDidMount() {
        this.props.onGetCategoriasdeMedicion();
    }
    render() {
        const { fetchingNuevaUnidadDeMedicion, showModalNuevaUnidadDeMedicion, onShowModalNuevaUnidadDeMedicion,
            onNuevaUnidadDeMedicion, listaCategoriasDeMedicionActivos, page, limpiarFormulario, fetchingListaCategoriasDeMedicionActivos } = this.props

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onNuevaUnidadDeMedicion(formulario, page);
        }

        if (limpiarFormulario && limpiarFormulario === 'crearUnidadMedicion') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo);
        };

        const handleCancel = () => {
            onShowModalNuevaUnidadDeMedicion();
            this.formRef.current.resetFields();
        }

        return (
            <Modal
                visible={showModalNuevaUnidadDeMedicion}
                title='Nueva Unidad de Medición'
                onCancel={handleCancel}
                footer={false}
            >
                <Spin spinning={fetchingNuevaUnidadDeMedicion}>
                    <Form {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        
                        ref={this.formRef}
                    >
                        <Form.Item
                            label="Unidad de medición"
                            name="unidadMedida"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <Input
                            pattern="^[ñíóáéú a-zA-Z ]+$"
                            title="Unidad de medición debe tener letras" 
                            maxLength={80} 
                            placeholder='Unidad de medición' />
                        </Form.Item>

                        <Form.Item
                            label="Abreviación"
                            name="abreviacion"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <Input 
                            maxLength={80} placeholder='Abreviación' />
                        </Form.Item>
                        <Form.Item
                            label="Categoría de medida"
                            name="categoriaMedida"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Select
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Selecciona la categoría de medida"
                                loading={fetchingListaCategoriasDeMedicionActivos}
                            >
                                {listaCategoriasDeMedicionActivos.map(option => <Option key={option.idCategoriaUnidadMedida}>{option.categoriaUnidadMedida}</Option>)}
                            </Select>
                        </Form.Item>
                        <Row justify='space-around'>
                            <Col span={12}>
                                <Button style={{width:'100%'}} className="btnFiltrar" htmlType="submit">Crear Unidad de Medición</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>

            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingNuevaUnidadDeMedicion: state.AlmacenesReducer.fetchingNuevaUnidadDeMedicion,
        showModalNuevaUnidadDeMedicion: state.AlmacenesReducer.showModalNuevaUnidadDeMedicion,
        page: state.AlmacenesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaCategoriasDeMedicionActivos: state.AlmacenesReducer.listaCategoriasDeMedicionActivos,
        fetchingListaCategoriasDeMedicionActivos: state.AlmacenesReducer.fetchingListaCategoriasDeMedicionActivos,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevaUnidadDeMedicion: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVA_UNIDAD_MEDICION', showModalNuevaUnidadDeMedicion: false });
        },
        onNuevaUnidadDeMedicion: (formulario, page) => {
            dispatch({ type: 'NUEVA_UNIDAD_MEDICION_REQUEST', formulario: formulario, page: page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
        },
        onGetCategoriasdeMedicion: () => {
            dispatch({ type: "LISTA_CATEGORIAS_MEDICION_ACTIVOS_REQUEST" });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevaUnidadDeMedicion);