import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import { Row, Col, Modal, Spin, InputNumber, Input, Button, DatePicker, Form, Select } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { RangePicker } = DatePicker;
const {Option} = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const ModalNuevoNivelTiempoR = (props) => {
    let formRef = React.createRef();

    const [state, setState] = useState({fechaInicio: undefined, disabledFechaI: false});
    const {fechaInicio, disabledFechaI} = state;

    const {fetchingNuevoNivelTiempoRespuesta,showModalNuevoNivelTiempoRespuesta,page,busqueda,limpiarFormulario,listaInfoProyecto,
        listaEstandaresDesempenoEspecificosActivos, onShowModalNuevoNivelTiempoR, onNuevoNivelTiempoR, onLimpiarFormulario
    } = props;

    useEffect (() => {
        onLimpiarFormulario();
        if(limpiarFormulario === 'CNTRS') {
            formRef.current.resetFields();
        }
    }, [limpiarFormulario]);

    const disabledRange = fecha => {
        return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicio;
    }
    const onFinish = formulario => {
        console.log(formulario)
        onNuevoNivelTiempoR(formulario, page, busqueda);
    }

    const onFinishFailed = errorInfo => {
        console.log(errorInfo);
    }

    const handleCancel = () => {
        onShowModalNuevoNivelTiempoR();
        formRef.current.resetFields();
        setState({fechaInicio: undefined, disabledFechaI: false});
    }

    return (
        <>
        <Modal
            visible={showModalNuevoNivelTiempoRespuesta}
            title='Nuevo Nivel de Tiempo de Respuesta por Sección'
            onCancel={handleCancel}
            footer={false}
            width='70%'
        >
            <Spin spinning={fetchingNuevoNivelTiempoRespuesta}>
                <Form {...layout}
                    ref={formRef}
                    name='formulario'
                    onFinish={onFinish}
                    
                >
                    <Row type='flex' justify='space-between'>
                        <Col span={11}>
                            <Form.Item
                                label='Sección'
                                name='seccion'
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select
                                    allowClear
                                    style={{ width: '100%' }} placeholder="Selecciona una sección"
                                >
                                    {listaEstandaresDesempenoEspecificosActivos.map(item => (<Option key={item.idServSeccionEspecifico}>{item.descripcion}</Option>))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label='Categoría'
                                name='categoria'
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Input
                                    pattern="^[ñíóáéúÁÉÍÓÚÑ a-zA-Z0-9,.]+$"
                                    title="Categoría debe tener letras o números."
                                    maxLength={300} placeholder='Categoría' 
                                />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label='Tiempo respuesta'
                                name='tiempoRespuesta'
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <InputNumber 
                                    min={1} max={1000} placeholder='Tiempo respuesta'
                                    title="Número debe ser entero" pattern="^[0-9]+$" 
                                />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label='Unidad de medida'
                                name='unidadMedida'
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select
                                    allowClear
                                    style={{ width: '100%' }} placeholder="Selecciona unidad de medida"
                                >
                                    <Option key={4}>{'Minutos'}</Option>
                                    <Option key={1}>{'Horas'}</Option>
                                    <Option key={2}>{'Días'}</Option>
                                    <Option key={3}>{'Años'}</Option>                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="Fechas vigencias"
                                name="rangoVigencia"
                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                            >
                                <RangePicker
                                    format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio", "Fecha fin"]}
                                    disabledDate={disabledRange}
                                    // defaultPickerValue={[moment(nivelTiempoRespuestaAnterior[0]?.fechaInicioVigenciaFormat,'DD-MM-YYYY'), moment(listaInfoProyecto.fechaVigencia, 'DD-MM-YYYY')]}
                                    defaultPickerValue={[moment(listaInfoProyecto.fechaInicio, 'DD-MM-YYYY'), moment(listaInfoProyecto.fechaVigencia, 'DD-MM-YYYY')]}
                                    defaultValue={[fechaInicio, moment(listaInfoProyecto.fechaVigencia, 'DD-MM-YYYY')]}
                                    disabled={[disabledFechaI, true]}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Button className="btnFiltrar" style={{ width: '100%', marginTop: '40px' }} htmlType="submit">Crear Nivel Tiempo Respuesta</Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
        </>
    )
}

const mapStateToProps = state => {
    return {
        fetchingNuevoNivelTiempoRespuesta: state.MecanismosDePagosReducer.fetchingNuevoNivelTiempoRespuesta,
        showModalNuevoNivelTiempoRespuesta: state.MecanismosDePagosReducer.showModalNuevoNivelTiempoRespuesta,
        fetchingNivelTiempoRespuestaAnterior: state.MecanismosDePagosReducer.fetchingNivelTiempoRespuestaAnterior,
        nivelTiempoRespuestaAnterior: state.MecanismosDePagosReducer.nivelTiempoRespuestaAnterior,
        page: state.MecanismosDePagosReducer.page,
        busqueda: state.MecanismosDePagosReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        listaEstandaresDesempenoEspecificosActivos: state.MecanismosDePagosReducer.listaEstandaresDesempenoEspecificosActivos,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevoNivelTiempoR: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_NIVEL_TIEMPO_RESPUESTA', showModalNuevoNivelTiempoRespuesta: false});
        },
        onNuevoNivelTiempoR: (formulario, page, busqueda) => {
            dispatch({ type: 'NUEVO_NIVEL_TIEMPO_RESPUESTA_REQUEST', formulario, page, busqueda})
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        }
    };
};

export default connect(mapStateToProps,mapDispatchToProps) (ModalNuevoNivelTiempoR);