import React, { useEffect, useState } from 'react';
import {Col, Table, Select, Modal, Spin, Row, Switch, message, Upload, Typography, Button} from "antd";
import 'antd/dist/antd.css';
import { useDispatch, useSelector } from "react-redux";
import { SaveOutlined, FilePdfFilled} from '@ant-design/icons';
import ModalAgregarProductivdad from './ModalAgregarProductividad';

const Option = Select.Option;
const { Text } = Typography;
const { Dragger } = Upload

const ModalCapturarInfoServicioVariable = () =>{
    const dispatch = useDispatch();
    const showModalCapturarInfoServicioVariable = useSelector((state) => state.ServiciosVariablesReducer.showModalCapturarInfoServicioVariable);
    const listaDetalleServicioVariable = useSelector((state) =>state.ServiciosVariablesReducer.listaDetalleServicioVariable);
    const listSubvariablesByVariable = useSelector((state) =>state.ServiciosVariablesReducer.listSubvariablesByVariable);
    const fetchingListaDetalleServicioVariable = useSelector((state) =>state.ServiciosVariablesReducer.fetchingListaDetalleServicioVariable);
    const listaUbicacionesActivos =  useSelector((state) =>state.CatalogosGeneralesReducer.listaUbicacionesActivos);
    const productividadAgregada = useSelector((state) => state.ServiciosVariablesReducer.productividadAgregada);
    const formulario = useSelector((state) => state.ServiciosVariablesReducer.formulario);
    const servicioCapturarSeleccionado = useSelector((state) =>state.ServiciosVariablesReducer.servicioCapturarSeleccionado)
    const fetchingCapturarProductividadVariable = useSelector((state) =>state.ServiciosVariablesReducer.fetchingCapturarProductividadVariable)
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        dispatch({ type: 'LISTA_UBICACIONES_ACTIVOS_REQUEST' });
        if(showModalCapturarInfoServicioVariable == false){
            setFileList([])
        }
    }, [showModalCapturarInfoServicioVariable]); 

    const handleCancel = () => {
        dispatch({ type: "SHOW_MODAL_CAPTURAR_INFO_SERVICIO_VARIABLE", showModalCapturarInfoServicioVariable: false, })
    };

    const columns = [{
        title: 'Subvariable',
        dataIndex: ['subvariableByIdSubvariable', 'nombre'],
        key: 'idVariable',
        align: 'center',
    },{
        title: 'Ubicacion',
        dataIndex: 'idUbicacion',
        key: 'idUbicacion',
        align: 'center',
        render: (record, index) => (
            <>
                {listaUbicacionesActivos.map((item)=>
                    <>
                        {item.idUbicacion == record &&
                            <span>{item.todoUbicacion}</span>
                        }
                    </>
                )}
           </>
        ),  
    },{
        title: 'Fecha de productividad',
        dataIndex: 'fechaProductividad',
        key: 'fechaProductividad',
        align: 'center',
    },{
        title: 'Cantidad',
        dataIndex: 'cantidad',
        key: 'cantidad',
        align: 'center',
    },{
        title: 'Productividad agregada',
        dataIndex: 'productividadAgregada',
        key: 'productividadAgregada',
        align: 'center',
        render: (record, index) => (
            <>
            {console.log('record', record)}
            <Switch checked={record} disabled={true} />
            </>
        ),
    },{
        title: 'Agregar productividad',
        key: 'editar',
        align: 'center',
        render: (record, index) => (
            <a className='editarIcono' onClick={() => addProductividad(record)}> <SaveOutlined /> </a>
        ),          
    }];

    const changeVariable = (variable) =>{
        dispatch({ type: "GET_SUBVARIABLE_BY_IDS_REQUEST", idServicioVariable:listaDetalleServicioVariable.idServicioVariable, idVariable:variable});
    }

    const addProductividad = (key) =>{
        dispatch({ type: "SHOW_MODAL_AGREGAR_PRODUCTIVDAD_VARIABLE", showModalAgregarProductividadVariable: true, registroSeleccionado:key})
    }

    const capturarProductividad = () => {
        console.log('Capturar productividad', fileList, formulario, servicioCapturarSeleccionado);
        dispatch({ type: "CAPTURAR_PRODUCTIVIDAD_VARIABLE_REQUEST", formulario: formulario, idServicioVariable: servicioCapturarSeleccionado.idServicioVariable, pdf: fileList.length ? fileList[0] : null  })
    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const onRemovePDF = () => {
        setFileList([])
    }

    const onChangePDF = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);

        if (info.file.status !== 'uploading') {
        //console.log(info.file, info.fileList);
        }
        else {
            setFileList(fileList)
        }
        if (info.file.status === 'done') {
            var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,40}$");
            if (!reGex.test(info.file.name)) {
            message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc.). Favor de cambiar el nombre', 8);
            }
            else {
                setFileList(fileList)
            }
            setFileList(fileList)
            
        }
        else if (info.file.status === 'error') {
            if (!reGex.test(info.file.name)) {
                message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc. ). Favor de cambiar el nombre', 8);
            }
            else {
                message.error(`${info.file.name} se cargó de forma incorrecta`);
                setFileList(fileList)
            }
            setFileList(fileList)
        }
    }
    return (
        <Modal
            visible={showModalCapturarInfoServicioVariable}
            title='Capturar Informacion de Servicio Variable'
            onCancel={handleCancel}
            footer={false}
            width='80%'
        >
            <Spin spinning={fetchingListaDetalleServicioVariable || fetchingCapturarProductividadVariable}>
                {listaDetalleServicioVariable.variablesByIdServicioVariable &&
                    <Row>
                    {listaDetalleServicioVariable.variablesByIdServicioVariable[0] ? (
                       <>
                            <Col span={6}>
                            {listaDetalleServicioVariable.variablesByIdServicioVariable.length>1 ? (
                                <Select allowClear
                                    style={{ width: '100%' }}
                                    defaultValue={listaDetalleServicioVariable.variablesByIdServicioVariable[0].variable}
                                    onChange={changeVariable}
                                    placeholder="Selecciona la Variable"
                                >
                                    {listaDetalleServicioVariable.variablesByIdServicioVariable.map(option => <Option key={option.idVariable}>{option.variable}</Option>)}
                                </Select>
                                ) : (
                                    <Select allowClear
                                    style={{ width: '100%' }}
                                    disabled
                                    defaultValue={listaDetalleServicioVariable.variablesByIdServicioVariable[0].variable}
                                    onChange={changeVariable}
                                    placeholder="Selecciona la Variable"
                                >
                                    {listaDetalleServicioVariable.variablesByIdServicioVariable.map(option => <Option key={option.idVariable}>{option.variable}</Option>)}
                                </Select>
                                )}
                            </Col>
                            
                            <Col span={24}>
                                <br />
                                <Table
                                    size="small"
                                    rowKey="idVariableServicioVariable"
                                    columns={columns}
                                    dataSource={listSubvariablesByVariable}
                                    locale={{ emptyText: 'Sin datos'}}
                                    // onChange={handleTableChange}
                                    pagination={{ simple: true, hideOnSinglePage: true}}
                                />
                                <br />
                                <Row justify='space-around' align="middle">
                                    <Col span={8}>
                                        <Dragger disabled={!productividadAgregada} customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false} style={{borderRadius:'1em'}} fileList={fileList}>
                                            <Row justify='center'>
                                                <FilePdfFilled className='iconTabPane'/>
                                                <Col span={24} style={{paddingTop:'1em'}}>
                                                    <Text strong> Click o arrastra a ésta área el achivo </Text>
                                                </Col>
                                            </Row>
                                        </Dragger>
                                    </Col>
                                    <Col span={8} >
                                        <Button disabled={!productividadAgregada} className="btnFiltrar" onClick={capturarProductividad}>Capturar Productividad</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </>
                    ):(
                        <Col span={24}>
                            <Table
                                size="small"
                                rowKey="idVariableServicioVariable"
                                columns={columns}
                                dataSource={[]}
                                locale={{ emptyText: 'Sin datos'}}
                                // onChange={handleTableChange}
                                //pagination={{ total: listaDetalleServicioVariableCantidad, simple: true, current: this.state.page }}
                            />
                        </Col>
                    )}
                    </Row>
                }
                <ModalAgregarProductivdad/>
            </Spin>
        </Modal>
    );
};

export default ModalCapturarInfoServicioVariable;


