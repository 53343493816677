import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import { Alert, Button, Row, Col, Select, Form, Input, DatePicker} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const {Option} = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const FilterSection = props => {
    let formRef = React.createRef();
    
    const {
        checkboxData, onGetReporteDinamico, formularioReporte, onServiciosByCentroCosto, handleSteps, onLimpiarFormulario,
        onGerencias, fetchingListaClasificacionesActivos, listaClasificacionesActivos, 
        fetchingListaPartidasByServicios, listaPartidasByServicios, onListaPartidas, fetchingListaCentroDeCostosByGerencia,
        listaCentroDeCostosByGerencia, onCetroCostosByGerencia, fetchingListaServiciosByCentroCosto, listaServiciosByCentroCosto,
        listaTemasActivos, fetchingListaTemasActivos, onListaTemas,
    } = props;


    useEffect(() => {
        onGerencias();
        onListaTemas();
    }, [])

    let permisosUsuario = sessionStorage.getItem('permisosUsuario');
    if(permisosUsuario[0]){
        permisosUsuario = JSON.parse(permisosUsuario);
    }

    let userInfo = sessionStorage.getItem('userInfo');
    if(userInfo[0]) {
        userInfo = JSON.parse(userInfo);
    }

    const serviciosByIdUsuario = []
    if(userInfo[0].serviciosByIdUsuario && listaServiciosByCentroCosto){
        for(let i in listaServiciosByCentroCosto){
            for(let x in userInfo[0].serviciosByIdUsuario){
                if(listaServiciosByCentroCosto[i].idServicio === userInfo[0].serviciosByIdUsuario[x].idServicio){
                    serviciosByIdUsuario.push(userInfo[0].serviciosByIdUsuario[x]);
                }
            }
        }
    }

    const onHandleSteps = value => {
        handleSteps(value);
        onLimpiarFormulario();
        props.onLimpiarListaGerencias();
        props.limpiarListaPartidasByServicios();
    }

    const onFinish = formulario => {
        console.log(formulario);
        const sorter = {field:'ser.Nombre',order:'ASC'}
        onGetReporteDinamico(formulario, 1, checkboxData, sorter, permisosUsuario.especialEvaluaciónDeLaGestión);
        handleSteps('siguiente');
    }

    const gerenciaSeleccionada = (value) => {
        formRef.current.setFieldsValue({'centroCosto': undefined, 'idServicio': undefined});
        if(formRef.current.getFieldValue('partida')){
            formRef.current.setFieldsValue({'partida': undefined});
        }
        props.limpiarListaCentroCostoByGerencia();
            props.limpiarListaServiciosByCentroCosto();
            props.limpiarListaPartidasByServicios();
        if(value[0]){
            onCetroCostosByGerencia(value);
        }
    }

    const centroCostoSeleccionado = (value) => {
        formRef.current.setFieldsValue({'idServicio': undefined});
        if(formRef.current.getFieldValue('partida')){
            formRef.current.setFieldsValue({'partida': undefined});
        }
        props.limpiarListaServiciosByCentroCosto();
            props.limpiarListaPartidasByServicios();
        if(value[0]){
            onServiciosByCentroCosto(value);
        }
    }

    const onServicioSelect = (value) => {
        if(formRef.current.getFieldValue('partida')){
            formRef.current.setFieldsValue({'partida': undefined});
        }
        props.limpiarListaPartidasByServicios();
        if(value[0]){
            onListaPartidas(value);
        }
    }


    return (
        <>
            <Row>
                <Col span={24}>
                    <Alert message="Selecciona los filtros a aplicar en el reporte." type="info" style={{ marginBottom: '16px' }} />
                </Col>
            </Row>
            <Form {...layout}
                name='formulario'
                onFinish={onFinish}
                initialValues={formularioReporte}
                ref={formRef}
            >
                <Row justify='space-between'>
                    <Col span={11}>
                        <Form.Item
                            label='Gerencia'
                            name='gerencia'
                            rules={[{ required: true, message: 'Favor de seleccionar las gerencias', }]}
                        >
                            <Select
                                allowClear
                                style={{ width: '100%' }} placeholder="Selecciona las gerencias"
                                loading={fetchingListaClasificacionesActivos}
                                onChange={gerenciaSeleccionada}
                                mode='multiple'
                            >
                                {listaClasificacionesActivos.map(item => <Option key={item.idClasificacionGerencia + ' - ' + item.nombreGerencia}>{item.idClasificacionGerencia + ' - ' + item.nombreGerencia}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    {checkboxData.partidaC ? (
                    <Col span={11}>
                        <Form.Item
                            label='Centro de Costo'
                            name='centroCosto'
                        >
                            <Select
                                allowClear
                                style={{ width: '100%' }} placeholder="Selecciona los centros de costo"
                                loading={fetchingListaCentroDeCostosByGerencia}
                                onChange={centroCostoSeleccionado}
                                mode='multiple'
                            >
                                {listaCentroDeCostosByGerencia.map(item => <Option key={item.idCentroCosto + ' - ' + item.centroCosto}>{item.idCentroCosto + ' - ' + item.centroCosto}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    ):false}
                    {checkboxData.partidaC ? (
                    <Col span={11}>
                        <Form.Item
                            label='Servicio'
                            name='idServicio'
                        >
                            {permisosUsuario.especialEvaluaciónDeLaGestión ? (
                            <Select
                                allowClear
                                style={{ width: '100%' }} placeholder="Selecciona los servicios"
                                onChange={onServicioSelect}
                                mode='multiple'
                                loading={fetchingListaServiciosByCentroCosto}
                            >
                                {listaServiciosByCentroCosto.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nombre}</Option>)})
                            </Select>
                            ):(
                            <Select
                                allowClear
                                style={{ width: '100%' }} placeholder="Selecciona los servicios"
                                onChange={onServicioSelect}
                                mode='multiple'
                                loading={fetchingListaServiciosByCentroCosto}
                            >
                                {serviciosByIdUsuario.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nombre}</Option>)})
                            </Select>
                            )}
                        </Form.Item>
                    </Col>
                    ):false}
                    {checkboxData.partidaC ? (
                        <Col span={11}>
                            <Form.Item
                                label="Partida"
                                name="partida"
                            >
                                <Select
                                    allowClear
                                    style={{ width: '100%' }} placeholder="Selecciona las partidas"
                                    mode='multiple'
                                    loading={fetchingListaPartidasByServicios}
                                >
                                    {listaPartidasByServicios.map(item => <Option key={item.idPartida + ' - ' + item.nombre}>{item.idPartida + ' - ' + item.nombre}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    ) :false}
                    {checkboxData.temaC ? (
                        <Col span={11}>
                            <Form.Item
                                label="Temas"
                                name="temas"
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Ingresa los temas a buscar"
                                    loading={fetchingListaTemasActivos}
                                    mode='multiple'
                                >
                                    {listaTemasActivos.map(item => <Option key={item.idTema + ' - ' + item.tema}>{item.idTema + ' - ' + item.tema}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    ) :false}
                    {checkboxData.proveedorC ? (
                        <Col span={11}>
                            <Form.Item
                                label="Proveedor"
                                name="proveedor"
                            >
                                <Input style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    ): false}
                    {checkboxData.fechaEvaluacionC ? (
                        <Col span={11}>
                            <Form.Item
                                label="Mes evaluado"
                                name="fechaEvaluacion"
                            >
                                <DatePicker picker="month" format='MM-YYYY' style={{ width: '100%' }} 
                                    placeholder={"Ingresa el mes evaluado"}
                                />
                            </Form.Item>
                        </Col>
                    ) : false}
                </Row>
                <Row>
                    <Col span={6}>
                        <Button className="btnFiltrar" onClick={() => onHandleSteps('anterior')} style={{ width: '100%', marginTop: '20px' }}>Anterior</Button>
                    </Col>
                    <Col span={6} offset={12}>
                        <Button className="btnFiltrar"  htmlType='submit' style={{ width: '100%', marginTop: '20px' }}>Siguiente</Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

const mapStateToProps = state => {
    return {
        fetchingListaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.fetchingListaClasificacionesActivos,
        listaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.listaClasificacionesActivos,
        fetchingListaPartidasByServicios: state.InformacionOperativaReducer.fetchingListaPartidasByServicios,
        listaPartidasByServicios: state.InformacionOperativaReducer.listaPartidasByServicios,
        formularioReporte: state.EvaluacionDeLaGestionReducer.formularioReporte,
        fetchingListaCentroDeCostosByGerencia: state.CatalogosGeneralesReducer.fetchingListaCentroDeCostosByGerencia,
        listaCentroDeCostosByGerencia: state.CatalogosGeneralesReducer.listaCentroDeCostosByGerencia,
        fetchingListaServiciosByCentroCosto: state.CatalogosGeneralesReducer.fetchingListaServiciosByCentroCosto,
        listaServiciosByCentroCosto: state.CatalogosGeneralesReducer.listaServiciosByCentroCosto,
        listaTemasActivos: state.EvaluacionDeLaGestionReducer.listaTemasActivos,
        fetchingListaTemasActivos: state.EvaluacionDeLaGestionReducer.fetchingListaTemasActivos,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGerencias: () => {
            dispatch({ type: 'LISTA_CLASIFICACION_ACTIVOS_REQUEST' })
        },
        onListaPartidas: (idsServicios) => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_SERVICIOS_REQUEST', idsServicios })
        },
        onListaTemas: (param) => {
            dispatch({ type: 'LISTA_TEMAS_ACTIVOS_REQUEST', param })
        },
        onLimpiarListaGerencias: () => {
            dispatch({ type: 'LISTA_CLASIFICACION_ACTIVOS_SUCCESS', listaClasificacionesActivos: [] })
        },
        limpiarListaPartidasByServicios: () => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_SERVICIOS_SUCCESS', listaPartidasByServicios: [] })
        },
        onGetReporteDinamico: (formulario, page, checkboxData, order, especial) => {
            dispatch({ type: 'LISTA_REPORTE_DINAMICO_REQUEST', formulario, page, checkboxData, order, especial });
        },
        onGetReporteDinamicoAgrupado: (formulario, page, checkboxData, order, especial) => {
            dispatch({ type: 'LISTA_REPORTE_DINAMICO_AGRUPADO_REQUEST', formulario, page, checkboxData, order, especial });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO_REPORTE', formularioReporte: {}, listaReportesDinamico: [], listaReportesDinamicoCantidad: 0 })
        },
        onCetroCostosByGerencia: (idsGerencias) => {
            dispatch({ type: 'LISTA_CENTRO_COSTOS_BY_GERENCIA_REQUEST', idsGerencias });
        },
        onServiciosByCentroCosto: (idsCentrosCostos) => {
            dispatch({ type: 'LISTA_SERVICIOS_BY_CENTRO_COSTO_REQUEST', idsCentrosCostos });
        },
        limpiarListaCentroCostoByGerencia: () => {
            dispatch({ type: 'LISTA_CENTRO_COSTOS_BY_GERENCIA_SUCCESS', listaCentroDeCostosByGerencia: [] })
        },
        limpiarListaServiciosByCentroCosto: () => {
            dispatch({ type: 'LISTA_SERVICIOS_BY_CENTRO_COSTO_SUCCESS', listaServiciosByCentroCosto: [] })
        },
        onLimpiarListaTemas: () => {
            dispatch({ type: 'LISTA_TEMAS_ACTIVOS_SUCCESS', listaTemasActivos: [] })
        },
    }
}

export default connect (mapStateToProps, mapDispatchToProps) (FilterSection);
