import React, {Component} from 'react'
import { Modal } from 'antd';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import EditarPeriodo from './EditarPeriodo';
import VerificarContraseña from './VerificarContraseña';
import ArchivoPeriodo from './ArchivoPeriodo';
import ListaPeriodoEditar from './ListaPeriodoEditar';

class ModalEditarPeriodoContainer extends Component {
    formRef = React.createRef();

    render() { 
        const {
            showModalEditarPeriodoFMD,
            onLimpiarFormulario,
            onCambiarVista,
            cambiarVistaModalPeriodoFMD,
            onCerrarModal,
        
        } = this.props;

        const handleCancel = () => {
            onCerrarModal()
            onLimpiarFormulario('')
            onCambiarVista(1);
        }

        function ContenidoEditarPeriodo() {
            if(cambiarVistaModalPeriodoFMD === 1){
                return(
                    <EditarPeriodo/>
                );
            }
            else if(cambiarVistaModalPeriodoFMD === 2){
                return(
                    <VerificarContraseña/>
                );
            }
            else if(cambiarVistaModalPeriodoFMD === 3) {
                return(
                    <ListaPeriodoEditar/>
                )
            }
            else if(cambiarVistaModalPeriodoFMD === 4){
                return(
                    <ArchivoPeriodo/>
                );
            }
        };

        return ( 
            <Modal
                visible={showModalEditarPeriodoFMD}
                title='Editar Periodo Fracción de Tiempo'
                onCancel={handleCancel}
                footer={false}
            >
                <ContenidoEditarPeriodo/>
            </Modal>
         );
    }
}
 
const mapStateToProps = state => {
	return {
        showModalEditarPeriodoFMD: state.MecanismosDePagosReducer.showModalEditarPeriodoFMD,
        cambiarVistaModalPeriodoFMD: state.MecanismosDePagosReducer.cambiarVistaModalPeriodoFMD,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onCerrarModal: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_PERIODO_FMD', showModalEditarPeriodoFMD: false, page: null })
        },
        onLimpiarFormulario: (limpiarFormulario) => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario });
        },
        onCambiarVista: (cambiarVistaModalPeriodoFMD) => {
            dispatch({ type: 'CAMBIAR_VISTA_PERIODO_FMD', cambiarVistaModalPeriodoFMD })
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarPeriodoContainer);