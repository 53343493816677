import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {Spin, Row, Col, Button, DatePicker, Modal, Form, Alert, Input} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const {TextArea} = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ReprogramarTareas extends React.Component {
    formRef = React.createRef();

    render () {

        const {limpiarFormulario, page, busqueda, showModalReprogramarTareaVC, tareaSeleccionadaVC, fetchingReprogramarTareaVC,
            onLimpiarFormulario,onModalReprogramarTareaVC, onReprogramarTareaVC} = this.props;

        if (limpiarFormulario && limpiarFormulario === 'TRVC') {
            this.formRef.current.resetFields();
            onLimpiarFormulario();
        }

        const disabledRange = fecha => {
            const currentYear = new Date();
            const year = currentYear.getFullYear();
            //let fechaEjecucion = tareaSeleccionadaVC.fechaEjecucion;
            const fechaVigencia = moment(`31/12/${year}`, "DD-MM-YYYY");
            const fechaInicio = moment();
            if (fecha && fecha < fechaInicio) {
                return fecha && fecha < fechaInicio;
            } else {                
                return fecha && fecha > fechaVigencia;
            }
        }

        const onFinish = formulario => {
            console.log(formulario);
            onReprogramarTareaVC(formulario, tareaSeleccionadaVC, busqueda, page, this.props.calendarioProgramacion );
        }

        const onFinishFailed = () => {
            console.log('Cancel');
        }

        const handleCancel = () => {
            onModalReprogramarTareaVC();
        }

        return(
            <div>
                {tareaSeleccionadaVC ? (
                    <Modal
                        visible={showModalReprogramarTareaVC}
                        title={`Reprogramar tarea`}
                        onCancel={handleCancel}
                        footer={false}
                        width='70%'
                    >
                        <Spin spinning={fetchingReprogramarTareaVC}>
                            <Alert
                                message={"Favor de seleccionar una nueva fecha para la tarea."}
                                type="info"
                            />
                            <Form {...layout}
                                name='formulario'
                                onFinish={onFinish}
                                
                                initialValues={tareaSeleccionadaVC}
                                ref={this.formRef}
                            >
                                <Row type='flex' justify='space-around'>
                                    <Col span={5}>
                                        <Form.Item
                                            label='Fecha programada'
                                            name='fechaEjecucionM'
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <DatePicker
                                                format='DD-MM-YYYY' style={{ width: '100%' }} placeholder='Ingrese fecha.'
                                                disabled
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item
                                            label='Fecha reprogramación'
                                            name='fechaReprogramacion'
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <DatePicker
                                                format='DD-MM-YYYY' style={{ width: '100%' }} placeholder='Ingrese fecha.'
                                                disabledDate={disabledRange}
                                                allowClear={true}
                                                showToday={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item
                                            label='Observaciones'
                                            name='observaciones'
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <TextArea
                                                pattern="^^[a-zñA-ZÑ0-9 ÁÉÓÚÍáéóíú,.]+$"
                                                title="Observaciones sin carácteres especiales"
                                                maxLength={250}
                                                placeholder='Observaciones de reprogramación.'
                                                autoSize
                                            />

                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row type='flex' justify='space-around'>
                                    <Col span={12}>
                                        <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Reprogramar</Button>
                                    </Col>
                                </Row>

                            </Form>
                        </Spin>
                    </Modal>
                ): false}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        page: state.ServiciosPlanificadosReducer.page,
        busqueda: state.ServiciosPlanificadosReducer.busqueda,
        showModalReprogramarTareaVC: state.ServiciosPlanificadosReducer.showModalReprogramarTareaVC,
        tareaSeleccionadaVC: state.ServiciosPlanificadosReducer.tareaSeleccionadaVC,
        fetchingReprogramarTareaVC: state.ServiciosPlanificadosReducer.fetchingReprogramarTareaVC,
        calendarioProgramacion: state.ServiciosPlanificadosReducer.calendarioProgramacion,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onModalReprogramarTareaVC: () => {
            dispatch({ type: 'SHOW_MODAL_REPROGRAMAR_TAREA_VC', showModalReprogramarTareaVC: false });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
        },
        onReprogramarTareaVC: (formulario, tareaSeleccionadaVC, busqueda, page, calendarioProgramacion) => {
            dispatch({ type: 'REPROGRAMAR_TAREA_VISTA_CALENDARIO_REQUEST', formulario, tareaSeleccionadaVC, busqueda, page, calendarioProgramacion });
        },
    }

}

export default connect(mapStateToProps, mapDispatchToProps) (ReprogramarTareas);