import React from 'react';
import { Form, Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalEditarFallasDeDisponibilidad from './ModalEditarFallaDeDisponibilidad';
import ModalNuevaFallaDeDisponibilidad from './ModalNuevaFallaDeDisponibilidad';

const { Search } = Input;
const confirm = Modal.confirm;

class CatalogoFallasDeDisponibilidad extends React.Component {
     componentDidMount(){
          this.props.onGetFallasDeDisponibilidad(1, '');
          this.props.onRequestInfoProyecto();
     }
     state={
          page: 1,
          busqueda: '',
     }

    render() {
          const {
               fetchingListaFallasDeDisponibilidadBusqueda,
               listaFallasDeDisponibilidadBusqueda,
               listaFallasDeDisponibilidadBusquedaCantidad,
               
               onGetFallasDeDisponibilidad,
               onModalNuevaFallaDeDisponibiliad,
               onModalEditarFallaDeDisponibilidad,
               onEstadoFallaDeDisponibilidad

          } = this.props;

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
          permisosUsuario = JSON.parse(permisosUsuario);
          }
          const onBuscarFallaDeDisponibilidad = (busqueda) => {
          this.setState({
               busqueda: busqueda,
               page: 1,
          })
          console.log(busqueda);
          onGetFallasDeDisponibilidad(1, busqueda);
          }

          const columns = [
               {title: 'Categoría de Falla', dataIndex: 'fallaDisponibilidad', key: 'fallaDisponibilidad', width: '13%'},
               {title: 'Descripción', dataIndex: 'descripcion', key: 'descripcion'},
               {title: 'Porcentaje de Deducción', dataIndex: 'porcentajeDeduccionTabla', key: 'porcentajeDeduccionTabla', align: 'right', width: '15%'},
               {title: 'Fecha Alta', dataIndex: 'fechaAlta', key: 'fechaAlta', width: '10%'},
               {title: 'Activo', dataIndex: 'activo', key: 'activo',
               render: (text, record) => (
                   <Switch checked={text} disabled={!permisosUsuario.categoriaDeFallaDeDisponibilidadActivoinactivo} onChange={() => handleDesactivar(record)}/>
               ),},
               { title: 'Editar', key: 'editar',
               render: (text, record) => (
                   <a className='editarIcono' disabled={!permisosUsuario.editarCategoriaDeFallaDeDisponibilidad} onClick={() => onEditar(record)}><EditOutlined /></a>
               ),}
          ];

          const onEditar = (key) => {
               onModalEditarFallaDeDisponibilidad(key, this.state.page);
          }
          const handleDesactivar = (key) => {
               console.log(key);
               const page = this.state.page;
               confirm({
                    title: '¿Estás seguro que deseas cambiar el estado de la cateogoría de falla de disponibilidad?',
                    content: '',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                         onEstadoFallaDeDisponibilidad(key.idFallaDisponibilidad, page)
                    },
                    onCancel() {
                         console.log('Cancel');
                    },
               });
          }
          const handleTableChange = (pagination)=>{
               this.setState({
                   page: pagination.current,
               })
               onGetFallasDeDisponibilidad(pagination.current, this.state.busqueda);
          }
          const onNuevaFalla = () => {
               onModalNuevaFallaDeDisponibiliad(this.state.page);
          }

          console.log('listaFallasDeDisponibilidadBusqueda: ', listaFallasDeDisponibilidadBusqueda);

          return (
          <Form
          name="formulario"
          ref={this.formRef}
          onFinish={onBuscarFallaDeDisponibilidad}
           >
               <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                         <Search className='buscarModulos' placeholder="Ingresa la descripción o categoría de falla" onSearch={value => onBuscarFallaDeDisponibilidad(value)} style={{ width: '100%' }}/>
                    </Col>
                    {/* <Col span={8}>
                         <Button onClick={onNuevaFalla} disabled={!permisosUsuario.crearCategoriaPorFallaDeDisponibilidad} className='nuevoUsuario'>Nueva Categoría</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nueva Categoría' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevaFalla} disabled={!permisosUsuario.crearCategoriaPorFallaDeDisponibilidad} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                         <Table 
                              size="small" 
                              rowKey="fallaDisponibilidad" 
                              columns={columns}
                              dataSource={listaFallasDeDisponibilidadBusqueda}
                              loading={fetchingListaFallasDeDisponibilidadBusqueda}
                              locale={{ emptyText: 'Sin datos' }}
                              onChange={handleTableChange}
                              pagination={{ total: listaFallasDeDisponibilidadBusquedaCantidad, simple: true, current: this.state.page}}
                              
                         />         
                    </Col>
               </Row>
               <ModalNuevaFallaDeDisponibilidad/>
               <ModalEditarFallasDeDisponibilidad/>
          </Form>
          );
     }
}

const mapStateToProps = state => {
     return {
          fetchingListaFallasDeDisponibilidadBusqueda: state.MecanismosDePagosReducer.fetchingListaFallasDeDisponibilidadBusqueda,
          listaFallasDeDisponibilidadBusqueda: state.MecanismosDePagosReducer.listaFallasDeDisponibilidadBusqueda,
          listaFallasDeDisponibilidadBusquedaCantidad: state.MecanismosDePagosReducer.listaFallasDeDisponibilidadBusquedaCantidad,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onGetFallasDeDisponibilidad: (page, busqueda) => {
                    dispatch({ type: "FALLA_DISPONIBILIDAD_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
          },
          onEstadoFallaDeDisponibilidad: (idFallaDisponibilidad, page) => {
                    dispatch({ type: "ESTADO_FALLA_DISPONIBILIDAD_REQUEST", idFallaDisponibilidad: idFallaDisponibilidad, page: page });
          },
          onModalNuevaFallaDeDisponibiliad: (page) => {
               dispatch({ type: "SHOW_MODAL_NUEVO_FALLA_DISPONIBILIDAD", showModalNuevoFallasDeDisponibilidad: true, page: page});
          },
          onModalEditarFallaDeDisponibilidad: (FallasDeDisponibilidadSeleccionado, page) => {
               dispatch({ type: "SHOW_MODAL_EDITAR_FALLA_DISPONIBILIDAD", showModalEditarFallasDeDisponibilidad: true, FallasDeDisponibilidadSeleccionado: FallasDeDisponibilidadSeleccionado, page: page});
          },
          onRequestInfoProyecto: () => {
               dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
           },
     };
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoFallasDeDisponibilidad);