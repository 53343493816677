import React from 'react';
import {connect} from 'react-redux';
import { Row, Col, Modal, List, Divider, Table} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

class ModalDetalle extends  React.Component {

    render () {

        const {servicioPlanificadoSeleccionado, showModalDetalleRutinaSP, onShowModalDetalle} = this.props;
        
        let data = [];
        if (servicioPlanificadoSeleccionado) {
            const {actividades,epp, personal, insumosByIdRutina} = servicioPlanificadoSeleccionado;
            const herramientasByIdRutina = servicioPlanificadoSeleccionado.herramientasByIdRutina ? servicioPlanificadoSeleccionado.herramientasByIdRutina : []
            
            data = [{title:"Actividades", data: actividades}, {title:"EPP", data: epp}, {title:"Personal", data: personal},
            {title: "Insumos", data: insumosByIdRutina}, {title: "Herramientas", data: herramientasByIdRutina} ]
        }
        console.log(`data`, data)

        const columns = [{
            title: 'Descripción',
            dataIndex: 'descripcion',
            key: 'descripcion',
            width: '60%',
        },{
            title: 'Cantidad',
            dataIndex: 'cantidad',
            key: 'cantidad',
            align: 'center',
            width: '15%',
        },{
            title: 'Unidad de medida',
            dataIndex: 'unidadMedida',
            key: 'unidadMedida',
            align: 'center',
            width: '25%',
        }]

        const handleCancel = () => {
            onShowModalDetalle()
        }

        return (
            <div>
                {servicioPlanificadoSeleccionado ? (
                    <Modal
                        visible={showModalDetalleRutinaSP}
                        title={`Lista Detalle del Servicio Planificado: '${servicioPlanificadoSeleccionado.rutina}'`}
                        onCancel={handleCancel}
                        footer={false}
                        width='60%'
                    >    
                        {servicioPlanificadoSeleccionado ? (
                            <div>
                            <Row justify='space-around'>
                                <Col span={24}>
                                    <List
                                        className="listaCatalogos"
                                        bordered
                                        dataSource={data}
                                        renderItem={item => (
                                           <List.Item className='listaHover'>
                                                <Col span={5}>
                                                    <strong>{item.title}</strong>
                                                </Col>
                                                <Col span={18}>
                                                    {console.log(`item.data`, item.title, item.data)}
                                                    {item.title !== 'Insumos' ? 
                                                    <>
                                                    {item.title !== 'Herramientas' ? item.data : (
                                                       <Table
                                                       size='small'
                                                       rowKey='descripcion'
                                                       columns={columns}
                                                       dataSource={item.data}
                                                       locale={{ emptyText: 'Sin datos' }}
                                                       pagination={{ total: item.data ? item.data.length : 0, simple: true, hideOnSinglePage: true  }}
                                                   /> 
                                                    )}
                                                    </> : (
                                                    <Table
                                                        size='small'
                                                        rowKey='descripcion'
                                                        columns={columns}
                                                        dataSource={item.data}
                                                        locale={{ emptyText: 'Sin datos' }}
                                                        pagination={{ total: item.data ? item.data.length : 0, simple: true, hideOnSinglePage: true  }}
                                                    />
                                                    )}
                                                </Col>
                                            </List.Item>
                                        )}
                                        />
                                </Col>
                            </Row>
                            </div>
                        ) : false}                   
                    </Modal>
                ) : false}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalDetalleRutinaSP: state.ServiciosPlanificadosReducer.showModalDetalleRutinaSP,
        servicioPlanificadoSeleccionado: state.ServiciosPlanificadosReducer.servicioPlanificadoSeleccionado,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalDetalle: () => {
            dispatch( {type: 'SHOW_MODAL_DETALLE_RUTINA_SP', showModalDetalleRutinaSP: false, servicioPlanificadoSeleccionado: null})
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalDetalle);