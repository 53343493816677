import React, { Component } from 'react';
import { Row, Form, Input, Button, Col, Select, InputNumber, message, Divider } from 'antd';
import { MinusCircleOutlined, PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class InsumosForm extends Component {
    formRef = React.createRef();
    state={
        listaInsumos: [],
    }

    render() {
        const { onNuevaRutina, limpiarFormulario, onLimpiarFormulario, fetchingListaProductosActivosBusqueda, 
            listaProductosActivosBusqueda, onSiguientePaso, formularioRutina, listaInsumos, onEditarRutina,
            onListaProductosActivosBusqueda, onListaProductosActivosBusquedaLimpiar, listaSinRepetir,
            onActualizarListaSinRepetir, listaSubfamiliasRutinas, fetchingListaHerramientasActivasBusqueda,
            listaHerramientasActivasBusqueda, onListaHerramientasActivasBusqueda, onListaHerramientasActivasBusquedaLimpiar,
            tipoModal, listaSinRepetirHerramienta
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario, formularioRutina);
            if(formulario.insumos){
                for(let i in formulario.insumos){
                    if(formulario.insumos[i].insumo){
                        if(!formulario.insumos[i].cantidad){
                            message.error('Debes agregar cantidad al insumo');
                            return;
                        }
                    }
                    if(formulario.insumos[i].cantidad){
                        if(!formulario.insumos[i].insumo){
                            message.error('Debes agregar un insumo');
                            return;
                        }
                    }
                }
            }
            if(formulario.herramientas){
                for(let i in formulario.herramientas){
                    if(formulario.herramientas[i].insumo){
                        if(!formulario.herramientas[i].cantidad){
                            message.error('Debes agregar cantidad a la herramienta');
                            return;
                        }
                    }
                    if(formulario.herramientas[i].cantidad){
                        if(!formulario.herramientas[i].insumo){
                            message.error('Debes agregar una herramienta');
                            return;
                        }
                    }
                }
            }
            if(tipoModal === 'crear'){
                onNuevaRutina(formulario, formularioRutina, this.props.page, this.props.busqueda);
            }
            else{
                console.log('entró a editar');
                onEditarRutina(formulario, formularioRutina, this.props.page, this.props.busqueda, listaSubfamiliasRutinas, this.props.listaGruposServicios, this.props.listaServiciosByGrupoServicio, this.props.rutinaSeleccionada, this.props.listaPartidasByServicio4Fallas);
            }
            
        };
        
        if(limpiarFormulario && limpiarFormulario === 'crearRutina'){
            this.formRef.current.resetFields();
            onLimpiarFormulario();
        }
        const onInteger = (value, field) => {
            if(value){
                if(! Number.isInteger(value)){
                    let formulario = this.formRef.current.getFieldsValue('insumos');
                    formulario.insumos[field.key].cantidad = parseInt(value);
                    this.formRef.current.setFieldsValue({'insumos': formulario.insumos});
                }
            }
        }
        
        const pasoAnterior = () => {
            onSiguientePaso(formularioRutina, 1, null);
            let formulario = this.formRef.current.getFieldsValue('insumos');
            console.log('formulario');
            console.log(formulario);
            console.log('listaInsumos');
            console.log(listaInsumos);
            onSiguientePaso(formularioRutina, 1, formulario);
        }

        const buscarActivoCarac = (value, tipo) => {
            if(value.length>=3){
                console.log(value)
                if(tipo === 'insumo')
                    onListaProductosActivosBusqueda(value);
                else
                    onListaHerramientasActivasBusqueda(value)
            }
            else{
                if(tipo === 'insumo')
                    onListaProductosActivosBusquedaLimpiar();
                else
                    onListaHerramientasActivasBusquedaLimpiar();
            }
        };

        const onProductoSeleccionado = (value, field) => {
            let formulario = this.formRef.current.getFieldsValue('insumos');
            console.log(`formulario`, formulario, listaSinRepetir, field)
            let repetido = false;
            let repetidoNumero = 0;
            if(formulario.insumos.length !== listaSinRepetir.length){
                if(listaSinRepetir){
                    for(let i in listaSinRepetir){
                        console.log(`listaSinRepetir[i], value`, listaSinRepetir[i], value)
                        if(listaSinRepetir[i] === value){
                            console.log('Se está repitiendo')
                            formulario.insumos[field.name].insumo = null;
                            message.warning('No es posible seleccionar un producto que ya esté en la lista');
                            this.formRef.current.setFieldsValue({'insumos': formulario.insumos});
                            repetido = true;
                        }
                    }
                }
            }
            else{
                for(let i in formulario.insumos){
                    if(formulario.insumos[i].insumo === value){
                        repetidoNumero += 1;
                    }
                }
                if(repetidoNumero>1){
                    formulario.insumos[field.name].insumo = listaSinRepetir[field.name];
                    message.warning('No es posible seleccionar un producto que ya esté en la lista');
                    this.formRef.current.setFieldsValue({'insumos': formulario.insumos});
                }
            }
            
            console.log('despues de if nuevo y for', formulario, listaSinRepetir, repetidoNumero, repetido)
            if(!repetido){
                let listaActualizada = [];
                if(formulario.insumos){
                    for(let x=0; x<formulario.insumos.length; x++){
                        if(formulario.insumos[x].insumo){
                            listaActualizada.push(formulario.insumos[x].insumo);
                        }
                    }
                }
                if(formulario.herramientas){
                    for(let x=0; x<formulario.herramientas.length; x++){
                        if(formulario.herramientas[x].herramienta){
                            listaActualizada.push(formulario.herramientas[x].herramienta);
                        }
                    }
                }
                onActualizarListaSinRepetir(this.props.showModalEditarRutina, this.props.rutinaSeleccionada, this.props.page, this.props.busqueda, this.props.tipoFrecuencia, listaInsumos, listaActualizada, listaSinRepetirHerramienta);
            }
        }

        const onHerramientaSeleccionado = (value, field) => {
            let formulario = this.formRef.current.getFieldsValue('insumos');
            console.log(`formulario`, formulario, listaSinRepetir, listaSinRepetirHerramienta)
            let repetido = false;
            let repetidoNumero = 0;
            
            if(formulario.herramientas.length !== listaSinRepetirHerramienta.length){
                if(listaSinRepetirHerramienta){
                    for(let i in listaSinRepetirHerramienta){
                        if(listaSinRepetirHerramienta[i] === value){
                            formulario.herramientas[field.name].herramienta = null;
                            message.warning('No es posible seleccionar una herramienta que ya esté en la lista');
                            this.formRef.current.setFieldsValue({'herramientas': formulario.herramientas});
                            repetido = true
                        }
                    }
                }
            }
            else{
                for(let i in formulario.herramientas){
                    if(formulario.herramientas[i].herramienta === value){
                        repetidoNumero += 1;
                    }
                }
                if(repetidoNumero>1){
                    formulario.herramientas[field.name].herramienta = listaSinRepetir[field.name];;
                    message.warning('No es posible seleccionar una herramienta que ya esté en la lista');
                    this.formRef.current.setFieldsValue({'herramientas': formulario.herramientas});
                }
            }
            
            console.log('despues de if nuevo y for', formulario, listaSinRepetirHerramienta, repetidoNumero)
            if(!repetido){
                let listaActualizada = [];
                if(formulario.insumos){
                    for(let x=0; x<formulario.insumos.length; x++){
                        if(formulario.insumos[x].insumo){
                            listaActualizada.push(formulario.insumos[x].insumo);
                        }
                    }
                }
                if(formulario.herramientas){
                    for(let x=0; x<formulario.herramientas.length; x++){
                        if(formulario.herramientas[x].herramienta){
                            listaActualizada.push(formulario.herramientas[x].herramienta);
                        }
                    }
                }
                onActualizarListaSinRepetir(this.props.showModalEditarRutina, this.props.rutinaSeleccionada, this.props.page, this.props.busqueda, this.props.tipoFrecuencia, listaInsumos, listaSinRepetir, listaActualizada);
            }
        }

        const removeManual = (name) => {
            let formulario = this.formRef.current.getFieldsValue('insumos');
            console.log(`formulario antes de todo`, formulario)
            let formularioNuevo = [];
            let listaActualizada = [];
            for(let i = 0; i < formulario.insumos.length; i++){
                if(i !== name && formulario.insumos[i].insumo) {
                    formularioNuevo.push(formulario.insumos[i]);
                    listaActualizada.push(formulario.insumos[i].insumo);
                }
            }
            this.formRef.current.setFieldsValue({'insumos': formularioNuevo});
            
            console.log(`formularioNuevo`, formularioNuevo, listaActualizada)
            
            onActualizarListaSinRepetir(this.props.showModalEditarRutina, this.props.rutinaSeleccionada, this.props.page, this.props.busqueda, this.props.tipoFrecuencia, listaInsumos, listaActualizada, listaSinRepetirHerramienta);
        };
        const removeManualHerramienta = (name) => {
            let formulario = this.formRef.current.getFieldsValue('insumos');
            console.log(`formulario antes de todo`, formulario, name)
            let formularioNuevo = [];
            let listaActualizada = [];
            for(let i = 0; i < formulario.herramientas.length; i++){
                if(i !== name && formulario.herramientas[i].insumo) {
                    formularioNuevo.push(formulario.herramientas[i]);
                    listaActualizada.push(formulario.herramientas[i].insumo);
                }
            }
            this.formRef.current.setFieldsValue({'herramientas': formularioNuevo});
            
            console.log(`formularioNuevo`, formularioNuevo, listaActualizada)
            
            onActualizarListaSinRepetir(this.props.showModalEditarRutina, this.props.rutinaSeleccionada, this.props.page, this.props.busqueda, this.props.tipoFrecuencia, listaInsumos, listaSinRepetir, listaActualizada);
        };

        return (
            <Form {...layout}
                name="formulario"
                onFinish={onFinish}
                ref={this.formRef}
                initialValues={listaInsumos}
            >
                <Divider>Agregar Insumos</Divider>
                <Form.List name="insumos">
                    {(fields, { add, remove }) => (
                    <>
                        {fields.map(field => (
                        <Row justify='space-around' key={field.key}>
                            <Col span={14}>
                                <Form.Item
                                    {...field}
                                    label='Producto insumo'
                                    name={[field.name, 'insumo']}
                                    fieldKey={[field.fieldKey, 'insumo']}
                                >
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Ingresa nombre, clave o SKU del producto"
                                        loading={fetchingListaProductosActivosBusqueda}
                                        showSearch
                                        filterOption={false}
                                        onSearch={(value) => buscarActivoCarac(value, 'insumo')}
                                        onChange={(value) => onProductoSeleccionado(value, field)}
                                        notFoundContent={null}
                                    >
                                        {listaProductosActivosBusqueda.map(option => <Option key={option.idProducto + ' - ' + option.nombre + ' - Unidad de medida: ' + option.unidadMedida}>{option.idProducto + ' - ' + option.nombre + ' - Unidad de medida: ' + option.unidadMedida}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    {...field}
                                    label='Cantidad'
                                    name={[field.name, 'cantidad']}
                                    fieldKey={[field.fieldKey, 'cantidad']}
                                >
                                    <InputNumber min={1} onChange={(value) => onInteger(value, field)} style={{width: '100%'}} placeholder='Ingresa la cantidad'/>
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                {fields.length > 0 ? (
                                <Form.Item>
                                    <MinusCircleOutlined className='borrarInsumo' onClick={() => removeManual(field.name)} />
                                </Form.Item>
                                ):false}
                            </Col>
                        </Row>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Agregar Insumo
                            </Button>
                        </Form.Item>
                    </>
                    )}
                </Form.List>
                <br/>
                <Divider>Agregar Herramientas</Divider>
                <Form.List name="herramientas">
                    {(fields, { add, remove }) => (
                    <>
                        {fields.map(field => (
                        <Row justify='space-around' key={field.key}>
                            <Col span={14}>
                                <Form.Item
                                    {...field}
                                    label='Herramienta'
                                    name={[field.name, 'insumo']}
                                    fieldKey={[field.fieldKey, 'insumo']}
                                >
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Ingresa nombre, clave o SKU de la herramienta"
                                        loading={fetchingListaHerramientasActivasBusqueda}
                                        showSearch
                                        filterOption={false}
                                        onSearch={(value) => buscarActivoCarac(value, 'herramienta')}
                                        onChange={(value) => onHerramientaSeleccionado(value, field)}
                                        notFoundContent={null}
                                    >
                                        {listaHerramientasActivasBusqueda.map(option => <Option key={option.idProducto + ' - ' + option.nombre + ' - Unidad de medida: ' + option.unidadMedida}>{option.idProducto + ' - ' + option.nombre + ' - Unidad de medida: ' + option.unidadMedida}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    {...field}
                                    label='Cantidad'
                                    name={[field.name, 'cantidad']}
                                    fieldKey={[field.fieldKey, 'cantidad']}
                                >
                                    <InputNumber min={1} onChange={(value) => onInteger(value, field)} style={{width: '100%'}} placeholder='Ingresa la cantidad'/>
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                {fields.length > 0 ? (
                                <Form.Item>
                                    <MinusCircleOutlined className='borrarInsumo' onClick={() => removeManualHerramienta(field.name)} />
                                </Form.Item>
                                ):false}
                            </Col>
                        </Row>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Agregar Herramienta
                            </Button>
                        </Form.Item>
                    </>
                    )}
                </Form.List>
                <Row justify='space-around'>
                    <Col span={11} className='marginBoton'>
                        <br/>
                        <Button className='btnCargaArchivos' onClick={pasoAnterior}><ArrowLeftOutlined /> Paso Anterior</Button>
                    </Col>
                    {tipoModal === 'crear' ? (
                    <Col span={11} className='marginBoton'>
                        <br/>
                        <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Crear Rutina/Mantenimiento/Prueba</Button>
                    </Col>
                    ):(
                    <Col span={11} className='marginBoton'>
                        <br/>
                        <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Editar Rutina/Mantenimiento/Prueba</Button>
                    </Col>   
                    )}
                </Row>
            </Form>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingNuevaRutina: state.ServiciosPlanificadosReducer.fetchingNuevaRutina,
        showModalNuevaRutina: state.ServiciosPlanificadosReducer.showModalNuevaRutina,
        page: state.ServiciosPlanificadosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        busqueda: state.ServiciosPlanificadosReducer.busqueda,
        formularioRutina: state.ServiciosPlanificadosReducer.formularioRutina,
        listaInsumos: state.ServiciosPlanificadosReducer.listaInsumos,
        listaSinRepetirHerramienta: state.ServiciosPlanificadosReducer.listaSinRepetirHerramienta,
        tipoModal: state.ServiciosPlanificadosReducer.tipoModal,
        listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,
        listaServiciosByGrupoServicio: state.CatalogosGeneralesReducer.listaServiciosByGrupoServicio,
        rutinaSeleccionada: state.ServiciosPlanificadosReducer.rutinaSeleccionada,
        fetchingListaProductosActivosBusqueda: state.AlmacenesReducer.fetchingListaProductosActivosBusqueda,
        listaProductosActivosBusqueda: state.AlmacenesReducer.listaProductosActivosBusqueda,
        fetchingListaHerramientasActivasBusqueda: state.AlmacenesReducer.fetchingListaHerramientasActivasBusqueda,
        listaHerramientasActivasBusqueda: state.AlmacenesReducer.listaHerramientasActivasBusqueda,
        listaSinRepetir: state.ServiciosPlanificadosReducer.listaSinRepetir,
        listaSubfamiliasRutinas: state.CatalogosGeneralesReducer.listaSubfamiliasRutinas,
        showModalEditarRutina: state.ServiciosPlanificadosReducer.showModalEditarRutina,
        listaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.listaPartidasByServicio4Fallas,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onNuevaRutina: ( formulario, formularioRutina, page, busqueda ) => {
            dispatch({ type: "NUEVA_RUTINA_REQUEST" , formulario, formularioRutina, page, busqueda });
        },
        onEditarRutina: ( formulario, formularioRutina, page, busqueda, listaSubfamilias, listaGruposServicios, listaServiciosByGrupoServicio, rutinaSeleccionada, listaPartidasByServicio4Fallas ) => {
            dispatch({ type: "EDITAR_RUTINA_REQUEST" , formulario, formularioRutina, page, busqueda, listaSubfamilias, listaGruposServicios,listaServiciosByGrupoServicio, rutinaSeleccionada, listaPartidasByServicio4Fallas });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onSiguientePaso: ( formularioRutina, vistaNuevaRutina, listaInsumos ) => {
            dispatch({ type: "VISTA_NUEVA_RUTINA" , formularioRutina, vistaNuevaRutina, listaInsumos, tipoModal: null });
        },
        onListaProductosActivosBusqueda: (busqueda) => {
            dispatch({ type: 'LISTA_PRODUCTOS_BUSQUEDA_REQUEST', busqueda });
        },
        onListaProductosActivosBusquedaLimpiar: () => {
            dispatch({ type: 'LISTA_PRODUCTOS_BUSQUEDA_SUCCESS', listaProductosActivosBusqueda: [] });
        },
        onListaHerramientasActivasBusqueda: (busqueda) => {
            dispatch({ type: 'LISTA_HERRAMIENTAS_BUSQUEDA_REQUEST', busqueda });
        },
        onListaHerramientasActivasBusquedaLimpiar: () => {
            dispatch({ type: 'LISTA_HERRAMIENTAS_BUSQUEDA_SUCCESS', listaHerramientasActivasBusqueda: [] });
        },
        onActualizarListaSinRepetir: (showModalEditarRutina, rutinaSeleccionada, page, busqueda, tipoFrecuencia, listaInsumos, listaSinRepetir, listaSinRepetirHerramienta) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_RUTINA", showModalEditarRutina, rutinaSeleccionada, page, busqueda, tipoFrecuencia, listaInsumos, listaSinRepetir,listaSinRepetirHerramienta });
        },
        onCleanPartidasByIdServicio: () => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_SUCCESS', listaPartidasByServicio4Fallas: []})
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InsumosForm);