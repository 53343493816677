import axios from "axios";

//const endpoint = "https://papantla-services.herokuapp.com/";
//const endpoint = "https://papantla-dev.herokuapp.com/";
const endpoint = 'https://morelos-dev.herokuapp.com/';
//const endpoint = "https://papantla-demo.herokuapp.com/";

//const endpoint = "http://172.24.0.170:8080/"

//const endpoint = 'https://papantla-services-jose.herokuapp.com/';
//const endpoint = 'https://papantla-dev-jose.herokuapp.com/';

//const endpoint = 'https://papantla-secret-jose.herokuapp.com/';
//const endpoint = 'https://papantla-secret.herokuapp.com/';

const CancelToken = axios.CancelToken;
export let cancelUploadN;
export let cancelUploadE;

//BASE DE CONOCIMIENTO
export function listaDocumentosBaseCall(token, data) {
    return axios({
        method: 'post',
        url: endpoint + 'base/conocimientos/obtener',
        headers: {'Content-Type': 'application/json', 'Authorization':token},
        data: data,
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data;
    });
};
export function crearDocumentoBaseCall(token, data) {
    return axios({
        method: 'post',
        url: endpoint + 'base/conocimientos',
        headers: {'Content-Type': 'application/json', 'Authorization':token},
        data: data,
        cancelToken: new CancelToken(function executor(c) { cancelUploadN = c;})
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data;
    });
};
export function editarDocumentosBaseCall(token,data, idBaseConocimiento) {
    return axios({
        method: 'put',
        url: endpoint + 'base/conocimientos/' + idBaseConocimiento,
        headers: {'Content-Type': 'application/json', 'Authorization':token},
        data: data,
        cancelToken: new CancelToken(function executor(c) { cancelUploadE = c;})
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data;
    });
};

export function exportarExcelCall(token, data) {
    return axios({
        method: 'post',
        responseType: 'blob',
        url: endpoint + 'base/conocimientos/exportar/excel',
        headers: {'Content-Type': 'application/json', 'Authorization':token},
        data: data,
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data;
    });
};