import React from 'react';
import { Row, Col, Card, Input, Button, Radio, Form, Spin , Select, Divider, DatePicker, Tooltip, message, Upload} from 'antd';
import { UploadOutlined, SearchOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import moment from 'moment';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const {Search} = Input;
const {Option} = Select;
const {TextArea} = Input;

class EditarConciliacionContainer extends React.Component {
    formRef = React.createRef();
    componentDidMount(){
        this.props.onGetListaTiposFallasActivas();
        this.props.onGetNivelSeveridad();
        this.props.onGetListaMesesAbiertos();
        this.props.onMesesProcesadosAnioActual();
    }

    state = {
        busqueda: '',
        idTipoFalla: null,
        filePDF: false,
        botonPDF: false,
        habilitarSeveridad: false,
    }
    componentDidUpdate(prevProps) {
        if(prevProps.busquedaFolioConciliadoobj !== this.props.busquedaFolioConciliadoobj) {
            if(this.formRef.current) {
                this.formRef.current.setFieldsValue({
                    'tipoFalla': this.props.busquedaFolioConciliadoobj.tipoFalla, 
                    'nombreFalla': this.props.busquedaFolioConciliadoobj.nombreFalla,
                    'descripcionSolicitud': this.props.busquedaFolioConciliadoobj.descripcionSolicitud,
                    'nivelSeveridad': this.props.busquedaFolioConciliadoobj.nivelSeveridad,
                    'clasificacionTicket': this.props.busquedaFolioConciliadoobj.clasificacionTicket,
                    'fechaCierreEditar': this.props.busquedaFolioConciliadoobj.fechaCierreEditar,
                    'fechaConciliacionEditar': this.props.busquedaFolioConciliadoobj.fechaConciliacionEditar,
                });
            }
        }
    }
    render() {
        
        const {
            onGetFolioConciliado,
            busquedaFolioConciliado,
            busquedaFolioConciliadoobj,
            fetchingBusquedaFolioConciliado,
            fetchingListaTiposFallasActivas,
            listaTiposFallasActivas,
            onFallasPorServicioLimpiar,
            listaNivelesSeveridadPorFalla,
            fetchingNivelesSeveridadPorFalla,
            onGetNivelSeveridad,
            fetchingFallasPorServicio,
            fallasPorServicio,
            onFallasPorServicio,
            fetchingEditarFolioConciliado,
            onEditarFolioConciliado,
            limpiarFormulario,
            onLimpiarFolioConciliacion
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');

        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        let fecha = new Date();
        let anno = fecha.getFullYear();
        let annoSt = anno.toLocaleString('en-US');
        const addOn = 'SS' + annoSt.substr(-2) + '-';

        const onSearch = (value) => {
            this.setState({
                busqueda: addOn + value,
            });
            onGetFolioConciliado(addOn + value);
        };

        const onFinish = formulario => {
            console.log('Success:', formulario);

            onEditarFolioConciliado(formulario, busquedaFolioConciliadoobj, busquedaFolioConciliado);
        };

        if (limpiarFormulario && limpiarFormulario === 'editarConciliacion') {
            this.setState({ 
                busqueda: '',
                idTipoFalla: null,
                filePDF: false,
                botonPDF: false,
                habilitarSeveridad: false,
            });
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
            onLimpiarFolioConciliacion();
        }

        const onChangeTipoFalla = (value) => {
            if(value) {
                let idTipo = parseInt(value);
                busquedaFolioConciliadoobj.idTipoFalla = idTipo;

                let busquedaTicketSSobjLocal = busquedaFolioConciliadoobj;
                if(idTipo === 1) {
                    this.props.onActualizarFolio(busquedaFolioConciliado, null)
                    busquedaTicketSSobjLocal.fallaCalidad = true
                    this.props.onActualizarFolio(busquedaFolioConciliado, busquedaTicketSSobjLocal)
                } else {
                    this.props.onActualizarFolio(busquedaFolioConciliado, null)
                    busquedaTicketSSobjLocal.fallaCalidad = false
                    this.props.onActualizarFolio(busquedaFolioConciliado, busquedaTicketSSobjLocal)
                }

            }
        }

        const buscarFalla = (value) => {
            if(value.length>=3){
                console.log(value)
                onFallasPorServicio(busquedaFolioConciliadoobj.idServicio, value);
            } else {
                onFallasPorServicioLimpiar();
            }
        };

        const onChangeFalla = (fallaSeleccionada) => {console.log(`fallaSeleccionada.split(': ')[1]`, fallaSeleccionada.split(': ')[1]);
            let busquedaTicketSSobjLocal = busquedaFolioConciliadoobj;
            if(parseInt(fallaSeleccionada.split(': ')[1]) === 1) {
                this.props.onActualizarFolio(busquedaFolioConciliado, null);
                busquedaTicketSSobjLocal.habilitarSeveridad = true;
                console.log('busquedaTicketSSobjLocal', busquedaTicketSSobjLocal)
                this.props.onActualizarFolio(busquedaFolioConciliado, busquedaTicketSSobjLocal);
            } else {
                this.props.onActualizarFolio(busquedaFolioConciliado, null);
                busquedaTicketSSobjLocal.habilitarSeveridad = false;
                this.props.onActualizarFolio(busquedaFolioConciliado, busquedaTicketSSobjLocal);
            }
        }
        console.log(`busquedaFolioConciliadoobj`, busquedaFolioConciliadoobj)
        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                })
            }

            var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
            if (info.file.status === 'done') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }
        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };

        const onReiniciarValores = () => {
            this.formRef.current.setFieldsValue({
                'tipoFalla': busquedaFolioConciliadoobj.tipoFalla, 
                'nombreFalla': busquedaFolioConciliadoobj.nombreFalla,
                'descripcionSolicitud': busquedaFolioConciliadoobj.descripcionSolicitud,
                'nivelSeveridad': busquedaFolioConciliadoobj.nivelSeveridad,
                'clasificacionTicket': busquedaFolioConciliadoobj.clasificacionTicket,
                'fechaCierreEditar': busquedaFolioConciliadoobj.fechaCierreEditar,
                'fechaConciliacionEditar': busquedaFolioConciliadoobj.fechaConciliacionEditar,
            });
        }

        const layout = {
            labelCol: {
              span: 8,
            },
            wrapperCol: {
              span: 16,
            },
        };

        const disabledRange = fecha => {
            const fechaAltaFormato = (busquedaFolioConciliadoobj.fechaAltaFormato._i).split(' ')[0];
            let fechaInicio = {};
            const mesAlta = parseInt(fechaAltaFormato.split('-')[1]);
            let mesAltaProcesado = false;

            //validar que el mes en el que se dio de alta ya fue procesado
            for(let i in this.props.listaMesesProcesados){
                let mes = parseInt(this.props.listaMesesProcesados[i].idMes);
                if(mesAlta === mes){
                    mesAltaProcesado = true;
                }
            }

            let fechaFin = {};
            if(mesAltaProcesado){
                if(this.props.listaMesesProcesados[0]){
                    let anio = parseInt(this.props.listaMesesProcesados[0].anio);
                    let mes = parseInt(this.props.listaMesesProcesados[0].idMes);
                    mes = mes + 1;
                    let dias = 1;
                    if(mes <10){mes = '0' + mes}

                    let fechaInicioFormato = anio + '-' + mes + '-' + dias;
                    fechaInicio = moment(`${fechaInicioFormato}`, "YYYY-MM-DD");
                }

                fechaFin = new Date()
            } else {
                fechaInicio = moment(`${fechaAltaFormato}`, "YYYY-MM-DD");

                fechaFin = new Date()
            }

            return moment(fecha.format('YYYY-MM-DD')) < fechaInicio || moment(fecha.format('YYYY-MM-DD')) > fechaFin;
        }

        function range(start, end) {
            const result = [];
            for (let i = start; i < end; i++) {
              result.push(i);
            }
            return result;
        }

        const disabledTime = (time) => {
            if(time){
                // fecha inicial.

                let timeLocal = moment(busquedaFolioConciliadoobj.fechaAltaFormato.format('HH:mm'));
                let hour = null
                let minute = null
                if(timeLocal._i){
                    hour = parseInt(timeLocal._i.split(':')[0]);
                    minute = parseInt(timeLocal._i.split(':')[1]);
                    minute = minute + 1;
                }

                if (time && time.format("YYYY-MM-DD") === busquedaFolioConciliadoobj.fechaAltaFormato.format("YYYY-MM-DD")) {
                    if(time.format("HH") === busquedaFolioConciliadoobj.fechaAltaFormato.format("HH")){
                        return {
                            disabledHours: () => range(0, hour),
                            disabledMinutes: () => range(0, minute)
                        };
                    }

                    return {
                        disabledHours: () => range(0, hour),
                    };
                }
            }
        }

        return ( 
            <Row justify='space-around'>
                <Col span={11}>
                    <Card className="cardCatalogos" title={<div className='headerLista'>Editar Conciliación</div>} >
                        <Spin spinning={fetchingBusquedaFolioConciliado || fetchingEditarFolioConciliado}>
                            <Search addonBefore={addOn} type="number" placeholder="No. Ticket" allowClear onSearch={onSearch} style={{ width: '100%' }} enterButton={<SearchOutlined />}/>

                            <Divider/>

                            {Object.entries(busquedaFolioConciliadoobj).length > 0 ? (

                            <Form {...layout}
                                name="formulario"
                                onFinish={onFinish}
                                initialValues={busquedaFolioConciliadoobj}
                                ref={this.formRef}
                            >
                                <Col span={24}>
                                    <Form.Item 
                                        label="Tipo de Falla"
                                        name='tipoFalla'
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                        { <Select
                                            style={{ width: '100%' }} 
                                            placeholder="Selecciona el tipo de falla" 
                                            loading={fetchingListaTiposFallasActivas}   
                                            showSearch 
                                            allowClear
                                            onChange={onChangeTipoFalla}
                                        >
                                            {listaTiposFallasActivas.map(option => 
                                            <Option key={option.idTipoFalla + ' - ' + option.nombre}>
                                                <Tooltip placement="topLeft" title={option.idTipoFalla + ' - ' + option.nombre}>
                                                        {option.idTipoFalla + ' - ' + option.nombre}
                                                </Tooltip>
                                            </Option>)}
                                        </Select>}
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="Falla"
                                        name="nombreFalla"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder="Seleccione el tipo de Falla"
                                            loading={fetchingFallasPorServicio}
                                            onSearch={buscarFalla}
                                            notFoundContent={null}
                                            filterOption={false}
                                            onChange={onChangeFalla}
                                            
                                        >
                                            {fallasPorServicio.map(option =>
                                            <Option key={option.idFalla + '-' + option.nombre + ': ' + option.estandarByIdEstandar.idServSeccionEspecifico}>
                                                <Tooltip placement="topLeft" title={option.idFalla + '-' + option.nombre + ': ' + option.estandarByIdEstandar.idServSeccionEspecifico}>
                                                    {option.idFalla + '-' +option.nombre + ': ' + option.estandarByIdEstandar.idServSeccionEspecifico}
                                                </Tooltip>
                                            </Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="Descripcion de Falla"
                                        name="descripcionSolicitud"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <TextArea rows={3} disabled/>
                                    </Form.Item>
                                </Col>

                                {console.log('fallaCalidad ternaria', busquedaFolioConciliadoobj.fallaCalidad, busquedaFolioConciliadoobj.habilitarSeveridad)}
                                { busquedaFolioConciliadoobj.fallaCalidad ? (busquedaFolioConciliadoobj.habilitarSeveridad ? (
                                    <Col span={24}>
                                        <Form.Item
                                            label="Nivel de Severidad"
                                            name='nivelSeveridad'
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <Select
                                                style={{ width: '100%' }}
                                                placeholder="Selecciona el nivel de severidad"
                                                loading={fetchingNivelesSeveridadPorFalla}
                                                showSearch
                                                allowClear
                                            >
                                                {listaNivelesSeveridadPorFalla.map(option =>
                                                    <Option key={option.idNivelSeveridad}>
                                                        <Tooltip placement="topLeft" title={option.idNivelSeveridad + '-' +option.descripcion}>
                                                            {option.idNivelSeveridad + ' - ' + option.descripcion}
                                                        </Tooltip>
                                                    </Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                ): false  ): false}

                                <Form.Item
                                    label="Clasificacion del Ticket"
                                    name="clasificacionTicket"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Radio.Group style={{ width: '100%' }} >
                                        <Radio value='Solicitud de Servicio'>Solicitud de Servicio</Radio>
                                        <Radio value='SNP'>SNP</Radio>
                                        <Radio value='Queja'>Queja</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Col span={24}>                   
                                    <Form.Item
                                        label="Fecha de Cierre"
                                        name="fechaCierreEditar"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <DatePicker 
                                            disabled // Modificación solicitada por Vero. 01-10-2021
                                            style={{ width: '100%' }} 
                                            format='DD-MM-YYYY HH:mm' 
                                            showTime={true}
                                            />      
                                    </Form.Item> 
                                </Col>
                        
                                <Col span={24}>
                                    <Form.Item
                                        label="Fecha Conciliada"
                                        name="fechaConciliacionEditar"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <DatePicker 
                                            style={{ width: '100%' }} 
                                            format='DD-MM-YYYY HH:mm' 
                                            showTime={true}
                                            disabledDate={disabledRange}
                                            disabledTime={disabledTime}
                                            />      
                                    </Form.Item> 
                                </Col>  

                                <Col span={24}>
                                    <Form.Item
                                        label="Evidencia PDF"
                                        name="pdf"
                                    >
                                        <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false} style={{ width: '100%' }}>
                                            {this.state.filePDF === false ? (
                                            <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                                <UploadOutlined /> Click para subir evidencia PDF
                                            </Button>
                                            ):false}
                                        </Upload>
                                    </Form.Item>
                                </Col>
                            

                                <Divider/>

                                <Row justify='space-between'>
                                    <Col span={11}>
                                        <Button className="nuevoUsuario" style={{ width: '100%' }} onClick={onReiniciarValores} >Reiniciar Valores</Button>
                                    </Col>

                                    <Col span={11}>
                                        <Button className="nuevoUsuario" style={{ width: '100%' }} htmlType="submit">Guardar Cambios</Button>
                                    </Col>
                                </Row>  
                            
                            </Form>
                            ) : false}
                        </Spin>
                    </Card>
                </Col>
            </Row>
         );
    }
}

const mapStateToProps = state => {
	return {
        busquedaFolioConciliado:  state.MecanismosDePagosReducer.busquedaFolioConciliado,
        busquedaFolioConciliadoobj:  state.MecanismosDePagosReducer.busquedaFolioConciliadoobj,
        fetchingBusquedaFolioConciliado:  state.MecanismosDePagosReducer.fetchingBusquedaFolioConciliado,
        
        fallasPorTipoFalla: state.MecanismosDePagosReducer.fallasPorTipoFalla,
        fetchingFallasPorTipoFalla: state.MecanismosDePagosReducer.fetchingFallasPorTipoFalla,
        fetchingListaTiposFallasActivas: state.SolicitudDeServiciosReducer.fetchingListaTiposFallasActivas,
        listaTiposFallasActivas: state.SolicitudDeServiciosReducer.listaTiposFallasActivas,

        listaNivelesSeveridadPorFalla: state.MecanismosDePagosReducer.listaNivelesSeveridadPorFalla,
        fetchingNivelesSeveridadPorFalla: state.MecanismosDePagosReducer.fetchingNivelesSeveridadPorFalla,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,

        seccionesPorServicio: state.MecanismosDePagosReducer.seccionesPorServicio,
        fallasPorServicio: state.MecanismosDePagosReducer.fallasPorServicio,
        fetchingFallasPorServicio: state.MecanismosDePagosReducer.fetchingFallasPorServicio,

        fetchingEditarFolioConciliado: state.MecanismosDePagosReducer.fetchingEditarFolioConciliado,
        listaMesesAbiertos: state.MecanismosDePagosReducer.listaMesesAbiertos,
        listaMesesProcesados: state.MecanismosDePagosReducer.listaMesesProcesados,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onGetFolioConciliado: (busqueda) => {
            dispatch({ type: 'BUSQUEDA_FOLIO_CONCILIADO_REQUEST', busqueda});
        },
        onGetListaTiposFallasActivas: () => {
            dispatch({ type: "LISTA_TIPOS_FALLAS_ACTIVAS_REQUEST" });
        },
        onGetFallasPorTipoFalla: (idTipoFalla, param) => {
             dispatch({ type: "FALLAS_POR_TIPO_DE_FALLA_REQUEST", idTipoFalla, param});
        },
        onFallasPorServicioLimpiar: () => {
            dispatch({ type: "FALLAS_POR_SERVICIO_SUCCESS", fallasPorServicio: []});
        },
        onGetNivelSeveridad: () => {
            dispatch({ type: 'NIVELES_DE_SEVERIDAD_POR_FALLA_REQUEST' });
        },
        onFallasPorServicio: (idServicio, param) => {
            dispatch({ type: 'FALLAS_POR_SERVICIO_REQUEST', idServicio, param })
        },
        onEditarFolioConciliado: (formulario, busquedaFolioConciliadoobj, busquedaFolioConciliado) => {
            dispatch({ type: 'EDITAR_FOLIO_CONCILIADO_REQUEST', formulario, busquedaFolioConciliadoobj, busquedaFolioConciliado})
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        },
        onLimpiarFolioConciliacion: () => {
            dispatch({ type: 'BUSQUEDA_FOLIO_CONCILIADO_SUCCESS', busquedaFolioConciliado: [], busquedaFolioConciliadoobj: {} })
        },
        onActualizarFolio: (busquedaFolioConciliado, busquedaFolioConciliadoobj) => {
            dispatch({ type: 'BUSQUEDA_FOLIO_CONCILIADO_SUCCESS', busquedaFolioConciliado, busquedaFolioConciliadoobj})
        },

        onGetListaMesesAbiertos: () => {
            dispatch({ type: 'LISTA_MESES_ABIERTOS_REQUEST' });
        },
        onMesesProcesadosAnioActual: () => {
            dispatch({ type: 'LISTA_MESES_PROCESADOS_REQUEST' });
        },
	};
};
 
export default connect(mapStateToProps, mapDispatchToProps)(EditarConciliacionContainer);