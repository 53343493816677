import React from 'react';
import { Form, Spin, Row, Col, Table, Tooltip, Menu, Select, message, Button, Empty, Typography, Descriptions, Collapse, Result} from 'antd';
import { connect } from "react-redux"; 
import '../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import {  FileSearchOutlined, SearchOutlined, FilePdfOutlined, FileExcelOutlined, EyeOutlined, SelectOutlined } from '@ant-design/icons';
import { Equation,  defaultErrorHandler  } from "react-equation";
const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
const { SubMenu } = Menu;
const {Option} = Select;
const {Text} = Typography;
const { Panel } = Collapse;
class RelacionDeduccionesReiteracion extends React.Component {
     formRef = React.createRef();
     
     componentDidMount(){ this.props.onGetListaGrupoSerForRMDP(); this.props.onGetGruposServiciosActivos(); 
          this.props.onGetListaTiposFallasActivas();
     }

     state={ currentMenu: null, nombreGrupoSer: '', nombreServicio: '', page: 1, idServicio: '', claveTipoFalla: null, dataVariablesBase: [], dataDetalleDeductiva: [], dataDeducctionTotal: [], idTipoFalla: null }
 
     render() {
          const { listaGruposServiciosRMDP, fetchingListaServiciosFiltro, listaServiciosFiltro, onListaServiciosFiltro } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){ permisosUsuario = JSON.parse(permisosUsuario); }

          const onFinish = (formulario) => {
               console.log('Success:', formulario);
          };

          const handleTableChange = (pagination) => {
               this.setState({ page: pagination.current });
               this.props.onGetDeduccionesReiteracion(this.state.idServicio, this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, pagination.current, 'ASC', 'idSolicitudServicio', this.state.idTipoFalla);
          };

          const onDesgloce = (e) => {
               console.log('e', e);
               function numberWithCommas(x) {
                    let parts = x.toString().split(".");
                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return parts.join(".");
               }

               let pmiFx = '0.00'; if(e.pmi){pmiFx = numberWithCommas(e.pmi.toFixed(2))}
               let pmrFx = '0.00'; if(e.pmr){pmrFx = numberWithCommas(e.pmr.toFixed(2))}
               let fsaFx = e.fsa.toFixed(2);
               
               let dataVariablesBase = [
                    { label: <b><Tooltip title="Número de días del mes i">dm</Tooltip></b>, data: e.dmi},
                    { label: <b><Tooltip title='Pago Mensual de Inversión'>PMI</Tooltip></b>, data: pmiFx, align: 'center' },
                    { label: <b><Tooltip title='Pago Mensual de Referencia'>PMR</Tooltip></b>, data: pmrFx, align: 'center' },
               ];

               let dataDetalleDeductiva = [];
               let dataDeducctionTotal = [];
               
               if(e.claveTipoFalla === 'FLCL'){
                    let tipoDeductiva = 'Definitiva'; if(e.deductivaParcial){ tipoDeductiva = 'Parcial' }
                    dataDetalleDeductiva = [
                         { label: <Tooltip title="Número de ticket o solicitud de servicio">No. Ticket</Tooltip>, data: <b className='naranja'>{e.folio}</b>},
                         { label: <Tooltip title="Nombre del tipo de falla">Tipo de falla</Tooltip>, data: e.tipoFallaTabla},
                         { label: <Tooltip title="Descripción completa de la falla, capturada al momento en que se reportó la falla">Descripción de la falla</Tooltip>, data: e.descripcionSolicitudTabla},
                         { label: 'Fecha y hora de creación', data: e.fechaAltaTabla },
                         { label: 'Fecha y hora de cierre', data: e.fechaCierreTabla },
                         { label: <Tooltip title="Ponderador de Servicio para el área funcional">pssm</Tooltip>, data: e.ps.toFixed(2)},
                         { label: <Tooltip title="Porcentaje correspondiente del servicio">Ponderador del servicio</Tooltip>, data: e.ponderacionServAreaTabla},
                         { label: <Tooltip title="Nombre de la categoría, relacionada al catálogo de categorías de fallas de calidad">Categoría de falla de Calidad </Tooltip>, data: e.categoria},
                         { label: <Tooltip title="Porcentaje de deducción correspondiente, según la categoría de falla de calidad">pcfc</Tooltip>, data: e.pcfc},
                         { label: <Tooltip title="Nombre del área funcional">Área funcional</Tooltip>, data: e.areaFuncionalTabla},
                         { label: <Tooltip title="Peso del área funcional">psa</Tooltip>, data: e.psa},
                         { label: <Tooltip title="Factor de Seguridad">fsa</Tooltip>, data: fsaFx},
                         { label: <Tooltip title="Número de turnos afectados">utfc</Tooltip>, data: e.utf},
                         { label: <Tooltip title="Nombre del servicio">Servicio</Tooltip>, data: e.nombreServicioTabla},
                         { label: <b><Tooltip title="Tipo de deductiva">Tipo</Tooltip></b>, data: tipoDeductiva},
                         { label: <Tooltip title="No. de ticket reiterado">Ticket reiterado</Tooltip>, data: e.ticketReiterado},
                    ];

                    dataDeducctionTotal = [
                         { label: <b>Deducción total:</b>, data: e.deduccionTabla }
                    ];
               } else if(e.claveTipoFalla === 'FLDL') {
                    let tipoDeductiva = 'Definitiva'; if(e.deductivaParcial){ tipoDeductiva = 'Parcial' }
                    dataDetalleDeductiva = [
                         { label: <Tooltip title="Número de ticket o solicitud de servicio">No. Ticket</Tooltip>, data: <b className='naranja'>{e.folio}</b>},
                         { label: <Tooltip title="Nombre del tipo de falla">Tipo de falla</Tooltip>, data: e.tipoFallaTabla},
                         { label: <Tooltip title="Descripción completa de la falla, capturada al momento en que se reportó la falla">Descripción de la falla</Tooltip>, data: e.descripcionSolicitudTabla},
                         { label: 'Fecha y hora de creación', data: e.fechaAltaTabla },
                         { label: 'Fecha y hora de cierre', data: e.fechaCierreTabla },
                         { label: <Tooltip title="Ponderador de Servicio para el área funcional">pssm</Tooltip>, data: e.ps.toFixed(2)},
                         { label: <Tooltip title="Nombre de la categoría, relacionada al catálogo de categorías de fallas de disponibilidad">Categoría de falla de Disponibilidad </Tooltip>, data: e.categoria},
                         { label: <Tooltip title="Porcentaje de deducción correspondiente, según la categoría de falla de disponibilidad">pdfd</Tooltip>, data: e.pdfd},
                         { label: <Tooltip title="Nombre del área funcional">Área funcional</Tooltip>, data: e.areaFuncionalTabla},
                         { label: <Tooltip title="Peso del área funcional">psam</Tooltip>, data: e.psa},
                         { label: <Tooltip title="Factor de Seguridad">fsam</Tooltip>, data: fsaFx},
                         { label: <Tooltip title="Número de turnos afectados">utfd</Tooltip>, data: e.utf},
                         { label: <Tooltip title="Nombre del servicio">Servicio</Tooltip>, data: e.nombreServicioTabla},
                         { label: <b><Tooltip title="Tipo de deductiva">Tipo</Tooltip></b>, data: tipoDeductiva},
                         { label: <Tooltip title="No. de ticket reiterado">Ticket reiterado</Tooltip>, data: e.ticketReiterado},
                    ];

                    dataDeducctionTotal = [
                         { label: <b>Deducción total:</b>, data: e.deduccionTabla }
                    ];
               } else {
                    let tipoDeductiva = 'Definitiva'; if(e.deductivaParcial){ tipoDeductiva = 'Parcial' }
                    dataDetalleDeductiva = [
                         { label: <Tooltip title="Número de ticket o solicitud de servicio">No. Ticket</Tooltip>, data: <b className='naranja'>{e.folio}</b>},
                         { label: <Tooltip title="Nombre del tipo de falla">Tipo de falla</Tooltip>, data: e.tipoFallaTabla},
                         { label: <Tooltip title="Descripción completa de la falla, capturada al momento en que se reportó la falla">Descripción de la falla</Tooltip>, data: e.descripcionSolicitudTabla},
                         { label: 'Fecha y hora de creación', data: e.fechaAltaTabla },
                         { label: 'Fecha y hora de cierre', data: e.fechaCierreTabla },
                         
                         { label: <Tooltip title="Nombre del área funcional">Área funcional</Tooltip>, data: e.areaFuncionalTabla},
                         { label: <Tooltip title="Número de turnos afectados">trfg</Tooltip>, data: e.utf},
                         { label: <Tooltip title="Nombre del servicio">Servicio</Tooltip>, data: e.nombreServicioTabla},
                         { label: <b><Tooltip title="Tipo de deductiva">Tipo</Tooltip></b>, data: tipoDeductiva},
                         { label: <Tooltip title="No. de ticket reiterado">Ticket reiterado</Tooltip>, data: e.ticketReiterado},
                    ];

                    dataDeducctionTotal = [
                         { label: <b>Deducción total:</b>, data: e.deduccionTabla }
                    ];
               }

               this.setState({ claveTipoFalla: e.claveTipoFalla, dataVariablesBase, dataDetalleDeductiva, dataDeducctionTotal });
          };

          const columns = [
               { title: <Tooltip title='Número de ticket reiterado'>Folio</Tooltip>, dataIndex: 'folio', key: 'folio', align: 'center' },
               { title: <Tooltip title='Número de ticket origen'>Ticket origen</Tooltip>, dataIndex: 'folioOrigen', key: 'folioOrigen', align: 'center' },
               { title: <Tooltip title='Fecha y hora en la que se reportó la falla'>Fecha</Tooltip>, dataIndex: 'fechaAltaTabla', key: 'fechaAltaTabla', align: 'center' },
               { title: <Tooltip title='Código del área en la que ocurrió la falla'>Código área</Tooltip>, dataIndex: 'codigoAreaTabla', key: 'codigoAreaTabla', align: 'center' },
               { title: <Tooltip title='Nombre del área funcional en la que ocurrió la falla'>Área funcional</Tooltip>, dataIndex: 'areaFuncionalTabla', key: 'areaFuncionalTabla', align: 'center' },
               { title: <Tooltip title='Nombre de la unidad funcional en la que ocurrió la falla'>Unidad funcional</Tooltip>, dataIndex: 'unidadFuncionalTabla', key: 'unidadFuncionalTabla', align: 'center' },
               { title: <Tooltip title='Monto de deducción'>Deducción</Tooltip>, dataIndex: 'deduccionTabla', key: 'deduccionTabla', align: 'right' },
               { title: <Tooltip title='Tipo de falla relacionado al catálogo de falla para cada ticket'>Tipo de falla</Tooltip>, dataIndex: 'tipoFallaTabla', key: 'tipoFallaTabla', align: 'center' },
               { title: '', dataIndex: '', key: '', align: 'center',
               render: (record) => (
                    <a className='editarIcono' onClick={() => onDesgloce(record)}> <FileSearchOutlined /> </a>
               )},
          ];

          const onExportar = (e) => {
               if(e === 'pdf'){
                    this.props.onGetPDF(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, this.props.mesTexto);
               } else {
                    this.props.onGetXLS(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, this.props.mesTexto);
               }
          };

          const titleTable = () => {
               return (
                    <Row justify='space-around' gutter={[8,8]} align='middle' >
                         <Col span={20}>
                              <div style={{textAlign:'center'}}>
                                   <h4>FALLAS POR REITERACIONES EN LA PRESTACIÓN DEL SERVICIO DE <b>{ this.state.nombreGrupoSer.toUpperCase() }</b> PARA EL MES <b>{this.props.mesTexto.toUpperCase()} {this.props.anioSeleccionadoRMDP}</b></h4>
                                   <h5>SERVICIO <b>{ this.state.nombreServicio.toUpperCase() }</b></h5>
                              </div>
                         </Col>

                         <Col span={2}>
                              <Tooltip title='Exportar reporte de fallas .pdf'>
                                   <Button className="iconSecundary" shape="circle" loading={this.props.fetchingRelacionDeduccionReiteracionPDF} icon={<FilePdfOutlined />} onClick={(e) => { onExportar('pdf'); }}/>
                              </Tooltip>
                         </Col>
                         
                         <Col span={2}>
                              <Tooltip title='Exportar reporte de fallas .xls'>
                                   <Button className="iconSecundary" shape="circle" loading={this.props.fetchingRelacionDeduccionReiteracionEXCEL} icon={<FileExcelOutlined />} onClick={(e) => { onExportar('xls'); }}/>
                              </Tooltip>
                         </Col>
                    </Row>
               );
          };

          const handleClickMenu = (e) => {
               this.formRef.current.resetFields(['servicioSelect']);
               this.props.onListaServiciosFiltroLimpiar();

               const nombreGrupoSer = e.keyPath[1].split('-')[1];
               const idGrupoSer = e.keyPath[1].split('-')[0];
               const nombreServicio = e.keyPath[0].split('-')[1];
               const idServicio = e.keyPath[0].split('-')[0];
               this.props.onGetDeduccionesReiteracion(idServicio, this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, 1, 'ASC', 'idServicio');
               this.setState({ currentMenu: e.key.split('-')[0], nombreGrupoSer, nombreServicio, idServicio });
          };

          const buscarServicios = (value) => {
               console.log('value', value)
               if (value.length >= 3) {
                    if (value) {
                         onListaServiciosFiltro(value);
                    } else {
                         message.warning('No se encontró servicio con esas palabras')
                    }
               } else {
                   this.props.onListaServiciosFiltroLimpiar();
               }
          };

          const servicioSeleccionado = (e) => {
               let idServicio = e.split('-')[0];
               let nombreServicio = (e.split('_')[0]).split('-')[1];
               let idGrupoSer = e.split('_')[1];
               let nombreGrupoSer = '';

               for(let i in this.props.listaGruposServicios){
                    if(parseInt(idGrupoSer) === this.props.listaGruposServicios[i].idGrupoSer){
                         nombreGrupoSer = this.props.listaGruposServicios[i].nombre;
                    }
               }

               //console.log('idServicio', idServicio); console.log('nombreServicio', nombreServicio); console.log('idGrupoSer', idGrupoSer); console.log('nombreGrupoSer', nombreGrupoSer);
               this.props.onGetDeduccionesReiteracion(idServicio, this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, 1, 'ASC', 'idSolicitudServicio');
               this.setState({ idServicio, idServicio, nombreServicio, nombreGrupoSer, currentMenu: idServicio });
          };

          const genExtra = () => ( <EyeOutlined onClick={event => { event.stopPropagation(); }} /> );

          const tipoFallaSelected = (e) => {
               if(e.length > 0){
                    let idTipoFalla = [];
                    for(let i in e){
                         idTipoFalla.push(parseInt(e[i].split(' - ')[0]));
                    }

                    this.props.onGetDeduccionesReiteracion('', this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, 1, 'ASC', 'idSolicitudServicio', idTipoFalla);
                    this.setState({ idTipoFalla });
               }
          }

          return (<>
               <Form {...layout} name="formulario" ref={this.formRef} onFinish={onFinish}>
                    {/* <Row justify='space-around'>
                         <Col span={24} style={{backgroundColor: "#737373", borderTopRightRadius: '1em', borderTopLeftRadius: '1em', paddingTop: '1em', paddingLeft: '1em', paddingRight:'0.5em'}}>
                              <Form.Item name='servicioSelect'>
                                   <Select 
                                        mode='tag'
                                        style={{ width: '100%' }}
                                        placeholder="Escribe el nombre del servicio o su nomenclatura a buscar y seleccionalo"
                                        loading={fetchingListaServiciosFiltro}
                                        showSearch
                                        filterOption={false}
                                        onSearch={buscarServicios}
                                        notFoundContent={null}
                                        onChange={e => servicioSeleccionado(e)}
                                        suffixIcon={<SearchOutlined />}
                                   >
                                        {listaServiciosFiltro.map(option => <Option key={option.idServicio + '-' + option.nombre + '_' + option.idGrupoSer}>{option.nomenclatura + ': ' + option.nombre}</Option>)}
                                   </Select>
                              </Form.Item>
                         </Col>
                         <Col span={24}>
                              <Spin spinning={this.props.fetchingListaGruposServicios}>
                                   <Menu onClick={handleClickMenu} mode="horizontal" theme='dark' style={{textAlign:'center', alignContent:'space-between'}}>
                                   { listaGruposServiciosRMDP.map( option => 
                                        <SubMenu key={option.idGrupoSer + '-' + option.nombre} title={option.nombre}>
                                             { option.serviciosListByIdGrupo.map( optionSub => 
                                                  <Menu.Item key={optionSub.idServicio + '-' + optionSub.nombre}>
                                                       { optionSub.nombre }
                                                  </Menu.Item>
                                             ) }
                                        </SubMenu>
                                   ) }
                                   </Menu> 
                              </Spin>
                              <br />
                         </Col>
                    </Row> */}

                    <Row justify='end' style={{ paddingBottom:'1em' }}>
                         <Col span={8}>
                              <Select mode='multiple' style={{ width: '100%', textAlign: 'left' }} placeholder="Selecciona el tipo de falla" loading={this.props.fetchingListaTiposFallasActivas} onChange={tipoFallaSelected}>
                                   {this.props.listaTiposFallasActivas.map(option => 
                                        <Option key={option.idTipoFalla + ' - ' + option.nombre}>
                                             <Tooltip placement="topLeft" title={option.idTipoFalla + ' - ' + option.nombre }>
                                                  {option.idTipoFalla + ' - ' + option.nombre}
                                             </Tooltip>
                                        </Option>)}
                                   )}
                              </Select>
                         </Col>
                    </Row>

                    <Row justify='space-around' align='middle'>

                         { this.state.idTipoFalla ? (
                              <Col span={24}>
                              { this.props.relacionDeduccionReiteracionCantidad > 0 ? (
                                   <Table
                                        title={() => (
                                             <Row justify='space-around' gutter={[8,8]} align='middle' >
                                                  <Col span={20}>
                                                       <div style={{textAlign:'center'}}>
                                                            <h4> REPORTE DE TICKETS CON RELACIÓN DE DEDUCTIVAS POR REITERACIÓN PARA EL MES <b>{this.props.mesTexto.toUpperCase()} {this.props.anioSeleccionadoRMDP}</b></h4>
                                                       </div>
                                                  </Col>

                                                  <Col span={2}>
                                                       <Tooltip title='Exportar reporte de fallas .pdf'>
                                                            <Button className="iconSecundary" shape="circle" loading={this.props.fetchingRelacionDeduccionReiteracionPDF} icon={<FilePdfOutlined />} onClick={(e) => { onExportar('pdf'); }}/>
                                                       </Tooltip>
                                                  </Col>
                                                  
                                                  <Col span={2}>
                                                       <Tooltip title='Exportar reporte de fallas .xls'>
                                                            <Button className="iconSecundary" shape="circle" loading={this.props.fetchingRelacionDeduccionReiteracionEXCEL} icon={<FileExcelOutlined />} onClick={(e) => { onExportar('xls'); }}/>
                                                       </Tooltip>
                                                  </Col>
                                             </Row>
                                        )}
                                        size="small"
                                        //rowKey="id"
                                        columns={columns}
                                        dataSource={this.props.relacionDeduccionReiteracion}
                                        loading={this.props.fetchingRelacionDeduccionReiteracion}
                                        onChange={handleTableChange}
                                        pagination={{ total: this.props.relacionDeduccionReiteracionCantidad, simple: true, current: this.state.page}}
                                   />
                              ): (
                                   <Spin spinning={this.props.fetchingRelacionDeduccionReiteracion}>
                                        <Row justify='space-around' align='middle' style={{ height:'30em' }}>
                                             <Col span={24} >
                                                  <Empty description='Sin datos' />
                                             </Col>
                                        </Row>
                                   </Spin>
                              ) }
                              </Col>
                         ):(
                              <Col span={24}>
                                   <Row justify='space-around' align='middle' style={{height:'30em'}}>
                                        <Result  icon={<SelectOutlined />} subTitle="Selecciona un tipo de falla para continuar." />
                                   </Row>
                              </Col>
                         ) }

                         {/* { this.state.currentMenu ? (
                              <Col span={24}>
                                   { this.props.relacionDeduccionReiteracionCantidad > 0 ? (
                                        <Table
                                        title={titleTable}
                                        size="small"
                                        //rowKey="id"
                                        columns={columns}
                                        dataSource={this.props.relacionDeduccionReiteracion}
                                        loading={this.props.fetchingRelacionDeduccionReiteracion}
                                        onChange={handleTableChange}
                                        pagination={{ total: this.props.relacionDeduccionReiteracionCantidad, simple: true, current: this.state.page}}
                                        />
                                   ): (
                                        <Row justify='space-around' align='middle' style={{ height:'30em' }}>
                                             <Col span={24} >
                                                  <Empty description='Sin datos' />
                                             </Col>
                                        </Row>
                                   ) }
                              </Col>
                         ):( */}
                              {/* <Col span={24}>
                                   { this.props.relacionDeduccionReiteracionCantidad > 0 ? (
                                        <Table
                                             title={() => (
                                                  <Row justify='space-around' gutter={[8,8]} align='middle' >
                                                       <Col span={20}>
                                                            <div style={{textAlign:'center'}}>
                                                                 <h4> REPORTE DE TICKETS CON RELACIÓN DE DEDUCTIVAS POR REITERACIÓN PARA EL MES <b>{this.props.mesTexto.toUpperCase()} {this.props.anioSeleccionadoRMDP}</b></h4>
                                                            </div>
                                                       </Col>

                                                       <Col span={2}>
                                                            <Tooltip title='Exportar reporte de fallas .pdf'>
                                                                 <Button className="iconSecundary" shape="circle" loading={this.props.fetchingRelacionDeduccionReiteracionPDF} icon={<FilePdfOutlined />} onClick={(e) => { onExportar('pdf'); }}/>
                                                            </Tooltip>
                                                       </Col>
                                                       
                                                       <Col span={2}>
                                                            <Tooltip title='Exportar reporte de fallas .xls'>
                                                                 <Button className="iconSecundary" shape="circle" loading={this.props.fetchingRelacionDeduccionReiteracionEXCEL} icon={<FileExcelOutlined />} onClick={(e) => { onExportar('xls'); }}/>
                                                            </Tooltip>
                                                       </Col>
                                                  </Row>
                                             )}
                                             size="small"
                                             //rowKey="id"
                                             columns={columns}
                                             dataSource={this.props.relacionDeduccionReiteracion}
                                             loading={this.props.fetchingRelacionDeduccionReiteracion}
                                             onChange={handleTableChange}
                                             pagination={{ total: this.props.relacionDeduccionReiteracionCantidad, simple: true, current: this.state.page}}
                                        />
                                   ): (
                                        <Spin spinning={this.props.fetchingRelacionDeduccionReiteracion}>
                                             <Row justify='space-around' align='middle' style={{ height:'30em' }}>
                                                  <Col span={24} >
                                                       <Empty description='Sin datos' />
                                                  </Col>
                                             </Row>
                                        </Spin>
                                   ) }
                              </Col> */}
                         {/* ) } */}

                         { this.state.claveTipoFalla === 'FLCL' ? (
                              <Col span={24}>
                                   <Collapse expandIconPosition='right' className='collapseDetalleRDO' defaultActiveKey={['1']}>
                                        <Panel header="DEDUCTIVAS POR REITERACIÓN EN FALLAS DE CALIDAD" key="1" extra={genExtra()} showArrow={true}>
                                             <Row justify='center'>
                                                  <Text style={{fontSize: 20}}>
                                                       <Equation
                                                            value="DFRC_i^c = ((PMI_i + PMR_i)/dm_i) * psa_m * fsa_m * (utrfc_i_c / ut_m) * [1 + pss_m] * ps_i * pcfc"
                                                            errorHandler={defaultErrorHandler}
                                                       />
                                                  </Text>

                                                  <Col span={24}>
                                                       <Descriptions layout="vertical" bordered={true} title='VARIABLES BASE'>
                                                            {this.state.dataVariablesBase.map(item => 
                                                                 <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                            )}
                                                       </Descriptions>
                                                       <br/>
                                                  </Col>

                                                  <Col span={24}>
                                                       <Descriptions layout="vertical" bordered={true}>
                                                            {this.state.dataDetalleDeductiva.map(item => 
                                                                 <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                            )}
                                                       </Descriptions>
                                                       <br/>
                                                  </Col>

                                                  <Col span={24}>
                                                       <Descriptions layout="horizontal" bordered={true}>
                                                            {this.state.dataDeducctionTotal.map(item => 
                                                                 <Descriptions.Item column={{ xs: 1 }} label={item.label}>{item.data}</Descriptions.Item>
                                                            )}
                                                       </Descriptions>
                                                       <br/>
                                                  </Col>
                                             </Row>
                                        </Panel>
                                   </Collapse>
                                   <br/>
                              </Col>
                         ): ( this.state.claveTipoFalla === 'FLDL' ? (
                              <Col span={24}>
                                   <Collapse expandIconPosition='right' className='collapseDetalleRDO' defaultActiveKey={['1']}>
                                        <Panel header="DEDUCTIVAS POR REITERACIÓN EN FALLAS DE DISPONIBILIDAD" key="1" extra={genExtra()} showArrow={true}>
                                             <Row justify='center'>
                                                  <Text style={{fontSize: 20}}>
                                                       <Equation
                                                            value="DFRD_d^i = ((PMI_i + PMR_i)/dm_i) * psa_m * fsa_m * (utfd_d^i / ut_m) * pdfd_i * [1 + pss_m]"
                                                            errorHandler={defaultErrorHandler}
                                                       />
                                                  </Text>

                                                  <Col span={24}>
                                                       <Descriptions layout="vertical" bordered={true} title='VARIABLES BASE'>
                                                            {this.state.dataVariablesBase.map(item => 
                                                                 <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                            )}
                                                       </Descriptions>
                                                       <br/>
                                                  </Col>

                                                  <Col span={24}>
                                                       <Descriptions layout="vertical" bordered={true}>
                                                            {this.state.dataDetalleDeductiva.map(item => 
                                                                 <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                            )}
                                                       </Descriptions>
                                                       <br/>
                                                  </Col>
                                                  <Col span={24}>
                                                       <Descriptions layout="horizontal" bordered={true}>
                                                            {this.state.dataDeducctionTotal.map(item => 
                                                                 <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                            )}
                                                       </Descriptions>
                                                       <br/>
                                                  </Col>
                                             </Row>
                                        </Panel>
                                   </Collapse>
                                   <br/>
                              </Col>
                         ): ( this.state.claveTipoFalla === 'FLGS' ? (
                              <Col span={24}>
                                   <Collapse expandIconPosition='right' className='collapseDetalleRDO' defaultActiveKey={['1']}>
                                        <Panel header="DEDUCTIVAS POR REITERACIÓN EN FALLAS GENERALES DEL SERVICIO" key="1" extra={genExtra()} showArrow={true}>
                                             <Row justify='center'>
                                                  <Text style={{fontSize: 20}}>
                                                       <Equation
                                                            value="DFGS_g^i = 0.1 * [PMI_i + PMR_i] * (trfg_g^i / dm_i) * 2"
                                                            errorHandler={defaultErrorHandler}
                                                       />
                                                  </Text>

                                                  <Col span={24}>
                                                       <Descriptions layout="vertical" bordered={true} title='VARIABLES BASE'>
                                                            {this.state.dataVariablesBase.map(item => 
                                                                 <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                            )}
                                                       </Descriptions>
                                                       <br/>
                                                  </Col>

                                                  <Col span={24}>
                                                       <Descriptions layout="vertical" bordered={true}>
                                                            {this.state.dataDetalleDeductiva.map(item => 
                                                                 <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                            )}
                                                       </Descriptions>
                                                       <br/>
                                                  </Col>

                                                  <Col span={24}>
                                                       <Descriptions layout="horizontal" bordered={true}>
                                                            {this.state.dataDeducctionTotal.map(item => 
                                                                 <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                            )}
                                                       </Descriptions>
                                                       <br/>
                                                  </Col>
                                             </Row>
                                        </Panel>
                                   </Collapse> <br/>
                              </Col>  
                         ):false ) ) }
                    </Row>
               </Form>
          </>);
     }
}
 
 const mapStateToProps = state => {
     return {
          anioSeleccionadoRMDP: state.RMDPReducer.anioSeleccionadoRMDP,
          mesTexto: state.RMDPReducer.mesTexto,
          mesSeleccionadoRMDP: state.RMDPReducer.mesSeleccionadoRMDP,

          listaGruposServiciosRMDP: state.RMDPReducer.listaGruposServiciosRMDP,
          fetchingListaGruposServicios: state.RMDPReducer.fetchingListaGruposServicios,

          fetchingRelacionDeduccionReiteracionPDF: state.RMDPReducer.fetchingRelacionDeduccionReiteracionPDF,
          fetchingRelacionDeduccionReiteracionEXCEL: state.RMDPReducer.fetchingRelacionDeduccionReiteracionEXCEL,

          relacionDeduccionReiteracion: state.RMDPReducer.relacionDeduccionReiteracion,
          relacionDeduccionReiteracionCantidad: state.RMDPReducer.relacionDeduccionReiteracionCantidad,
          fetchingRelacionDeduccionReiteracion: state.RMDPReducer.fetchingRelacionDeduccionReiteracion,

          fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
          listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,

          listaGruposServicios: state.MonitorIntegralReducer.listaGruposServicios,

          fetchingListaTiposFallasActivas: state.SolicitudDeServiciosReducer.fetchingListaTiposFallasActivas,
          listaTiposFallasActivas: state.SolicitudDeServiciosReducer.listaTiposFallasActivas,
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          onGetListaGrupoSerForRMDP: () => {
               dispatch({ type: 'LISTA_GRUPOS_SERVICIOS_RDMP_REQUEST' });
          },
          onGetDeduccionesReiteracion: (idServicio, idMes, anio, page, order, field, idTipoFalla) => {
               dispatch({ type: 'RELACION_DEDUCCIONES_REITERACION_REQUEST', idServicio, idMes, anio, page, order, field, idTipoFalla });
          },
          onGetPDF: (idMes, anio, mesTxt) => {
               dispatch({ type: 'PDF_RELACION_DEDUCCION_REITERACION_REQUEST', idMes, anio, mesTxt})
          },
          onListaServiciosFiltro: (busqueda) => {
               dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
          },
          onListaServiciosFiltroLimpiar: () => {
               dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
          },
          onGetGruposServiciosActivos: () => {
               dispatch({ type: 'LISTA_GRUPOSERVICIOS_REQUEST' });
          },
          onGetXLS: (idMes, anio, mesTxt) => {
               dispatch({ type: 'EXCEL_RELACION_DEDUCCION_REITERACION_REQUEST', idMes, anio, mesTxt });
          },
          onGetListaTiposFallasActivas: () => {
               dispatch({ type: "LISTA_TIPOS_FALLAS_ACTIVAS_REQUEST" })
          },
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(RelacionDeduccionesReiteracion);