import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip, Typography } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoFamiliasDeActivos from './ModalNuevoFamiliasDeActivos';
import ModalEditarFamiliasDeActivos from './ModalEditarFamiliasDeActivos';

const { Search } = Input;
const {Text} = Typography;
const confirm = Modal.confirm;
class CatalogoDeFamiliasDeActivos extends React.Component {
    componentDidMount(){
        this.props.onGetFamiliasDeActivos(1, '');
        this.props.onGetServicios();
    }
    state={
        page: 1,
        busqueda: '',
    }
    render() {
        const { fetchingListaFamiliasDeActivosBusqueda, listaFamiliasDeActivosBusqueda, onEstadoFamiliasDeActivos, onGetFamiliasDeActivos, onModalEditarFamiliasDeActivos, onModalNuevoFamiliasDeActivos, listaFamiliasDeActivosBusquedaCantidad,
        } = this.props;
        console.log('listaFamiliasDeActivosBusqueda');
        console.log(listaFamiliasDeActivosBusqueda);
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        const onBuscarFamiliasDeActivos = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            onGetFamiliasDeActivos(1, busqueda);
        }
        
        const columns = [{
                title: 'Familia',
                dataIndex: 'familia',
                key: 'familia',
            },{
                title: 'Servicio',
                dataIndex: 'servicioTabla',
                key: 'servicioTabla',
            },{ 
                title: 'Partida', 
                dataIndex: 'partidaTabla', 
                key: 'partidaTabla',
                render: (text, record) => (
                    text === 'Sin partida' ? <Text italic style={{color:'#dddddd'}}>{text}</Text> : <Text>{text}</Text>
                ), 
            },{
                title: 'Tipo de familia',
                dataIndex: 'tipoFamilia',
                key: 'tipoFamilia',
            },{
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.familiaActivoInactivo} onChange={() => handleDesactivar(record)}/>
                ),
            },{
                title: 'Editar',
                key: 'editar',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarFamilia} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },
        ];
        const onEditar = (key) => {
            onModalEditarFamiliasDeActivos(key, this.state.page);
            this.props.onListaPartidasByServicio(key.idServicio);
        }
        const handleDesactivar = (key) => {
            console.log(key);
            const page = this.state.page;
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado de la familia "'+ key.familia +'"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoFamiliasDeActivos(key.idFamilia, page)
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onGetFamiliasDeActivos(pagination.current, this.state.busqueda);
		}
        const onNuevaFamilia = () => {
            onModalNuevoFamiliasDeActivos(this.state.page);
        }

        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search className='buscarModulos' placeholder="Ingresa la familia" onSearch={value => onBuscarFamiliasDeActivos(value)} style={{ width: '100%' }}/>
                    </Col>
                    {/* <Col span={7}>
                        <Button onClick={onNuevaFamilia} disabled={!permisosUsuario.crearFamilia} className='nuevoUsuario'>Nueva Familia</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nueva Familia' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevaFamilia} disabled={!permisosUsuario.crearFamilia} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="familia" 
                            columns={columns}
                            dataSource={listaFamiliasDeActivosBusqueda}
                            loading={fetchingListaFamiliasDeActivosBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaFamiliasDeActivosBusquedaCantidad, simple: true, currente: this.state.page}}
                        />         
                    </Col>
                </Row>
                <ModalNuevoFamiliasDeActivos/>
                <ModalEditarFamiliasDeActivos/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaFamiliasDeActivosBusqueda: state.CatalogosGeneralesReducer.listaFamiliasDeActivosBusqueda,
        fetchingListaFamiliasDeActivosBusqueda: state.CatalogosGeneralesReducer.fetchingListaFamiliasDeActivosBusqueda, 
        listaFamiliasDeActivosBusquedaCantidad: state.CatalogosGeneralesReducer.listaFamiliasDeActivosBusquedaCantidad,
        fetchingListaServiciosActivos: state.CatalogosGeneralesReducer.fetchingListaServiciosActivos,
        listaServiciosActivos: state.CatalogosGeneralesReducer.listaServiciosActivos,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetFamiliasDeActivos: (page, busqueda) => {
	    	dispatch({ type: "FAMILIAS_ACTIVOS_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
        },
        onEstadoFamiliasDeActivos: (idFamilia, page) => {
	    	dispatch({ type: "ESTADO_FAMILIAS_ACTIVOS_REQUEST", idFamilia: idFamilia, page: page });
        },
        onModalNuevoFamiliasDeActivos: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_FAMILIAS_ACTIVOS", showModalNuevoFamiliasDeActivos: true, page: page });
        },
        onModalEditarFamiliasDeActivos: (familiasDeActivosSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_FAMILIAS_ACTIVOS", showModalEditarFamiliasDeActivos: true, familiasDeActivosSeleccionado: familiasDeActivosSeleccionado, page: page});
        },
        onGetServicios: () => {
            dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
        },
        onListaPartidasByServicio: (idServicio) => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_REQUEST', idServicio: idServicio})
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoDeFamiliasDeActivos);