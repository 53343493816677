import React from 'react';
import { Row, Col, Button, Input, Select, Table, Modal, Card } from 'antd';
import { connect } from "react-redux";
import { FileSearchOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalDetalleCalculosInterno from './ModalDetalleCalculosInterno';
import ModalDetalleCalculosFacturacion from './ModalDetalleCalculosFacturacion';
import ModalCDDInterno from './ModalCCDInterno';
import ModalCDDFacturacion from './ModalCCDFacturacion';

const { Search } = Input;
const confirm = Modal.confirm;

class CalculosDeConsumoDiferenciadoContainer extends React.Component {
     state = {
        page: 1,
        busqueda:"",
     }

     componentDidMount() {
          this.props.onGetServiciosPublicosBusqueda(1,"");
          this.props.onGetListaServiciosPublicosCCD();
     }



     render() {
          const {listaServiciosPublicosCalculoBusqueda, fetchingListaServiciosPublicosCalculoBusqueda,listaServiciosPublicosCalculoCantidad,
               onGetServiciosPublicosBusqueda, onGetDetalleCalculosInterno,onModalDetalleCalculosInterno, onGetDetalleCalculosFacturacion, onModalDetalleCalculosFacturacion,
               onModalCCDInterno, onModalCCDFacturacion} = this.props;

          const {page, busqueda} = this.state;

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
               permisosUsuario = JSON.parse(permisosUsuario);
          }
          
          const columns = [{
               title: 'Nombre',
               dataIndex: 'nombre',
               key: 'nombre',
           },
           {
               title: 'Cálculos consumo diferenciado interno',
               key: 'detalle',
               width: '30%',
               align: 'center',
               render: (text, record) => (
                   <a className='editarIcono' disabled={!permisosUsuario.detalleDeCálculosConsumoDiferenciadoInterno}  onClick={() => onDetalleInterno(record)}  ><FileSearchOutlined /></a>
               ),
           },
           {
               title: 'Cálculos consumo diferenciado por facturación',
               key: 'detalle',
               width: '30%',
               align: 'center',
               render: (text, record) => (
                   <a className='editarIcono' disabled={!permisosUsuario.detalleDeCálculosConsumoDiferenciadoPorFacturación}  onClick={() => onDetalleFacturacion(record)}  ><FileSearchOutlined /></a>
               ),
           }
          ];

          const onBuscar = value => {
               this.setState({busqueda: value});
               onGetServiciosPublicosBusqueda(page, value);
          }

          const onDetalleInterno = (key) => {
               console.log('key', key)
               onGetDetalleCalculosInterno(key, 1);
               onModalDetalleCalculosInterno(key);

          };

          const onDetalleFacturacion = (key) => {
               console.log('key', key)
               onGetDetalleCalculosFacturacion(key, 1);
               onModalDetalleCalculosFacturacion(key);

          };

          const onCalculoCDInterno = () => {
               onModalCCDInterno(page, busqueda);
          }

          const onCalculoCDFacturacion = () => {
               onModalCCDFacturacion(page, busqueda);
          }

          const handleTableChange = (pagination) => {
               this.setState({
                   page: pagination.current,
               });
               onGetServiciosPublicosBusqueda(pagination.current, busqueda);
           }


          return (
               <Card className='cardCatalogos' title={<div className='headerLista'>Cálculos de Consumo Diferenciado</div>}>
                    <Row justify='space-around' style={{ textAlign: 'left' }}>
                         <Col span={24}>
                              <Search className='buscarModulos' placeholder="Ingresa el nombre del servicio público" onSearch={value => onBuscar(value)} style={{ width: '100%' }} />
                         </Col>
                    </Row>
                    <br/>
                    <Row justify='space-between' >
                         <Col span={11}>
                              <Button onClick={onCalculoCDInterno} disabled={!permisosUsuario.calculoDeConsumoDiferenciadoInterno} className='nuevoUsuario'>Cálculo de Consumo Diferenciado Interno</Button>
                         </Col>
                         <Col span={11}>
                              <Button onClick={onCalculoCDFacturacion} disabled={!permisosUsuario.calculoDeConsumoDiferenciadoPorFacturación} className='nuevoUsuario'>Cálculo de Consumo Diferenciado por Facturación</Button>
                         </Col>
                    </Row>
                    <Row justify='space-between' >
                         <Col span={24}>
                              <br />
                              <Table
                                   size="small"
                                   rowKey="nombre" 
                                   columns={columns}
                                   dataSource={listaServiciosPublicosCalculoBusqueda}
                                   loading={fetchingListaServiciosPublicosCalculoBusqueda}
                                   locale={{ emptyText: 'Sin datos' }}
                                   onChange={handleTableChange}
                                   pagination={{total: listaServiciosPublicosCalculoCantidad, simple: true, current: this.state.page}}
                              />
                         </Col>
                    </Row>
                    <ModalDetalleCalculosInterno/>
                    <ModalDetalleCalculosFacturacion />
                    <ModalCDDInterno />
                    <ModalCDDFacturacion />
               </Card>
          );
     }
}

const mapStateToProps = state => {
     return {
          listaServiciosPublicosCalculoBusqueda: state.ServiciosPublicosReducer.listaServiciosPublicosCalculoBusqueda,
          fetchingListaServiciosPublicosCalculoBusqueda: state.ServiciosPublicosReducer.fetchingListaServiciosPublicosCalculoBusqueda,
          listaServiciosPublicosCalculoCantidad: state.ServiciosPublicosReducer.listaServiciosPublicosCalculoCantidad,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onGetServiciosPublicosBusqueda: (page, busqueda) => {
               dispatch({ type: "LISTA_SERVICIOS_PUBLICOS_CCD_BUSQUEDA_REQUEST", page: page, busqueda: busqueda});
          },
          onGetListaServiciosPublicosCCD: () => {
               dispatch({ type: 'LISTA_SERVICIOS_PUBLICOS_CCD_REQUEST'});
          },
          onModalDetalleCalculosInterno: (servicioPublicoSeleccionadoCCDInterno, page, busqueda) => {
               dispatch({ type: 'SHOW_MODAL_DETALLE_CCD_INTERNO', showModalDetalleCalculosInterno: true, servicioPublicoSeleccionadoCCDInterno: servicioPublicoSeleccionadoCCDInterno, page:page, busqueda:busqueda})
          },
          onGetDetalleCalculosInterno: (servicioPublicoSeleccionadoCCDInterno, page) => {
               dispatch({ type: 'LISTA_DETALLE_CCD_INTERNO_REQUEST', servicioPublicoSeleccionadoCCDInterno: servicioPublicoSeleccionadoCCDInterno, page:page})
          },
          onModalDetalleCalculosFacturacion: (servicioPublicoSeleccionadoCCDFacturacion, page, busqueda) => {
               dispatch({ type: 'SHOW_MODAL_DETALLE_CCD_FACTURACION', showModalDetalleCalculosFacturacion: true, servicioPublicoSeleccionadoCCDFacturacion: servicioPublicoSeleccionadoCCDFacturacion, page:page, busqueda:busqueda})
          },
          onGetDetalleCalculosFacturacion: (servicioPublicoSeleccionadoCCDFacturacion, page) => {
               dispatch({ type: 'LISTA_DETALLE_CCD_FACTURACION_REQUEST', servicioPublicoSeleccionadoCCDFacturacion: servicioPublicoSeleccionadoCCDFacturacion, page:page})
          },
          onModalCCDInterno: (page, busqueda) => {
               dispatch({ type: 'SHOW_MODAL_CCD_INTERNO', showModalCalculoCDInterno: true, page:page, busqueda:busqueda });
          },
          onModalCCDFacturacion: (page, busqueda) => {
               dispatch({ type: 'SHOW_MODAL_CDD_FACTURACION', showModalCalculoCDFacturacion: true, page:page, busqueda:busqueda });
          }
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalculosDeConsumoDiferenciadoContainer);
