import React, { Component } from 'react';
import { Form, Button, Row, Col, Spin, message, DatePicker, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import moment from 'moment';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { RangePicker } = DatePicker;

class ArchivoEditarCategoria extends Component {
    formRef = React.createRef();
    state = {
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
    }
    render() {
        const { onEditarCategoriaFallaDeCalidad, CategoriaFallaDeCalidadSeleccionado, fetchingEditarCategoriaFallaDeCalidad, formularioFallaCalidad,
            listaInfoProyecto, page, limpiarFormulario, onLimpiarFormulario,listaFiltradaEditados,
        } = this.props;

        if(limpiarFormulario && limpiarFormulario === 'editarCategoriaFallaPDF'){
            console.log('entró a limpiar');
            this.formRef.current.resetFields();
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
            onLimpiarFormulario();
        }
        const onFinish = formularioPDF => {
            console.log('formularioPDF');
            console.log(formularioPDF);
            onEditarCategoriaFallaDeCalidad(formularioFallaCalidad, page, formularioPDF, CategoriaFallaDeCalidadSeleccionado, listaFiltradaEditados);
        };
        

        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                })
            }
            var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
            if (info.file.status === 'done') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }
        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };
        return (
            <Spin spinning={fetchingEditarCategoriaFallaDeCalidad}>
                <Form {...layout}
                name="formulario"
                onFinish={onFinish}
                
                ref={this.formRef}
                >
                    <Row justify='space-around'>
                        <Col span={18}>
                            <Form.Item
                                label="Archivo PDF"
                                name="pdf"
                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                            >
                                <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                    {this.state.filePDF === false ? (
                                    <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                        <UploadOutlined /> Click para subir convenio PDF
                                    </Button>
                                    ):false}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={18} className='marginBoton'>
                            <br/>
                            <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Editar Categoría de Fallas de Calidad</Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
	return {
        formularioFallaCalidad: state.MecanismosDePagosReducer.formularioFallaCalidad,
        fetchingEditarCategoriaFallaDeCalidad: state.MecanismosDePagosReducer.fetchingEditarCategoriaFallaDeCalidad,
        CategoriaFallaDeCalidadSeleccionado: state.MecanismosDePagosReducer.CategoriaFallaDeCalidadSeleccionado,
        page: state.MecanismosDePagosReducer.page,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        listaFiltradaEditados: state.MecanismosDePagosReducer.listaFiltradaEditados,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onEditarCategoriaFallaDeCalidad: (formulario, page,formularioPDF, CategoriaFallaDeCalidadSeleccionado, listaFiltradaEditados) => {
            dispatch( { type: 'EDITAR_CATEGORIA_FALLA_CALIDAD_REQUEST', formulario: formulario, page:page, formularioPDF:formularioPDF, CategoriaFallaDeCalidadSeleccionado: CategoriaFallaDeCalidadSeleccionado, listaFiltradaEditados: listaFiltradaEditados });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchivoEditarCategoria);