import React from 'react';
import { Row, Col, Select, Input, Spin, Badge, Tooltip, Card, Table, List, Form, Typography } from 'antd';
import { FileSearchOutlined, HomeOutlined, LogoutOutlined, DoubleLeftOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { Doughnut} from 'react-chartjs-2';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import '../../../stylesheets/ConfiguracionYSeguridad/Mapa.css';
import L from 'leaflet';
import { Map, ImageOverlay, FeatureGroup} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import iconoNegro from '../../../assets/markerBlack.png';
import iconoRojo from '../../../assets/markerRed.png';
import iconoAmarillo from '../../../assets/markerYellow.png';
import iconoVerde from '../../../assets/markerGreen.png';

const { Option } = Select;
const { Search } = Input;
const { Text } = Typography

class MapaDeIncidenciasContainer extends React.Component {
     formRef = React.createRef();
     componentDidMount(){
          this.props.onListaAreasNumero({}, true);
          this.props.onGetGruposServicios();
     }

     state = {
          imageUrl: null,
          page: 1,
          height: 0,
          width: 0,
          first: false,
          nivel: 0,
          idAreaFuncional: null,
          idUnidadFuncional: null,
          idInteriorFuncional: null,
          idSubInteriorFuncional: null,
          folio: '',
          busqueda: {},
          mostrarDetalle: false,
     }
 
     render() {
          const {
               fetchingListaAreasNumero, listaAreasNumero, fetchingListaUnidadesNumero, listaUnidadesNumero,
               fetchingListaInterioresNumero, listaInterioresNumero, fetchingListaSubInterioresNumero, listaSubInterioresNumero,
               fetchingListaDetallesSS, listaDetallesSS, listaDetallesSSCantidad, onListaAreasNumero,onLimpiarListaUnidadesNumero, 
               fetchingListaGruposServicios, listaGruposServicios, onGetServiciosByGruposServicios, onListaDetallesSS,
               fetchingListaServiciosByGruposServicios, listaServiciosByGruposServicios, onLimpiarServiciosByGruposServicios,
               onListaUnidadesNumero, onListaInterioresNumero, listaInfoProyecto, reiniciarAtrasMapa, atrasMapa,
               onInfoDetalleSS, infoDetalleSS, onListaSubInterioresNumero, onLimpiarListaInterioresNumero, onLimpiarListaSubInterioresNumero,
               listaDetalleMapa, onInfoDetalleSSRequest, fetchingInfoDetalleSS, reiniciarMapa
          } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
               permisosUsuario = JSON.parse(permisosUsuario);
          }

          if(!this.state.imageUrl && !this.state.first){
               const rutaImagenCentro = listaInfoProyecto.rutaImagenCentro;
               const img = new Image();
               const updateState = (width, height) => {
                    this.setState({
                         imageUrl: rutaImagenCentro,
                         width,
                         height, 
                         first: true,
                    })
               }
               img.onload = function() {
                    updateState(this.width, this.height);
               }
               img.src = rutaImagenCentro;
          }
          const stepBack = () => {
               console.log(`atrasMapa stepback`, atrasMapa)
               const busqueda = this.state.busqueda;
               let imageUrl = this.state.imageUrl
               if(this.state.nivel === 3){
                    console.log('Entró a suInterior para atrás')
                    imageUrl = this.state.imagenUnidad;
                    busqueda.idSubInteriorFuncional = null;
                    busqueda.idInteriorFuncional = null;
                    onLimpiarListaSubInterioresNumero();
                    onListaInterioresNumero(this.state.idUnidadFuncional, busqueda, false);

                    this.setState({
                         nivel: 2,
                         idSubInteriorFuncional: null,
                         idInteriorFuncional: null,
                    })
                    onListaDetallesSS(this.state.page, busqueda);
               }
               else if(this.state.nivel === 2){
                    console.log('Entró a interior para atrás')
                    imageUrl = this.state.imagenArea;
                    busqueda.idUnidadFuncional = null;
                    onLimpiarListaInterioresNumero();
                    onListaUnidadesNumero(this.state.idAreaFuncional, busqueda, false);
                    this.setState({
                         nivel: 1,
                         idUnidadFuncional: null,
                    })
                    onListaDetallesSS(this.state.page, busqueda);
               }
               else if(this.state.nivel === 1){
                    imageUrl = listaInfoProyecto.rutaImagenCentro;
                    console.log('Entró a unidad para atrás')
                    busqueda.idAreaFuncional = null;
                    busqueda.folio = null;
                    onLimpiarListaUnidadesNumero();
                    onListaAreasNumero(busqueda, false);
                    
                    this.setState({
                         page: 1,
                         nivel: 0,
                         idAreaFuncional: null,
                    })
                    if(this.formRef.current){
                         this.formRef.current.resetFields(['folio']);
                    }
               }
               this.setState({
                    imageUrl: null,
                    width: 0,
                    height: 0,
                    busqueda,
                    mostrarDetalle: false,
               })
               
               const img = new Image();
               const updateState = (width, height) => {
                    this.setState({
                         imageUrl,
                         width,
                         height,
                    })
               }
               img.onload = function() {
                    updateState(this.width, this.height);
               }
               img.src = imageUrl;
               onInfoDetalleSS(null);
          }

          const onHome = () => {
               console.log(`onHome`, atrasMapa)
               const busqueda = this.state.busqueda;
               busqueda.idAreaFuncional = null;
               busqueda.idUnidadFuncional = null;
               busqueda.idInteriorFuncional = null;
               busqueda.idSubInteriorFuncional = null;
               busqueda.folio = null;
               if(this.formRef.current){
                    this.formRef.current.resetFields(['folio']);
               }
               
               this.setState({
                    imageUrl: null,
                    height: 0,
                    width: 0,
                    first: false,
                    page: 1,
                    nivel: 0,
                    idAreaFuncional: null,
                    idUnidadFuncional: null,
                    idInteriorFuncional: null,
                    idSubInteriorFuncional: null,
                    busqueda,
                    mostrarDetalle: false,
               })
               const rutaImagenCentro = listaInfoProyecto.rutaImagenCentro;
               const img = new Image();
               const updateState = (width, height) => {
                    this.setState({
                         imageUrl: rutaImagenCentro,
                         width,
                         height,
                         nivel: 0,
                    })
               }
               img.onload = function() {
                    console.log(this.width + 'x' + this.height);
                    updateState(this.width, this.height);
               }
               img.src = rutaImagenCentro;
               onListaAreasNumero(busqueda, true);
               onLimpiarListaUnidadesNumero();
               onLimpiarListaInterioresNumero();
               onLimpiarListaSubInterioresNumero();
               onInfoDetalleSS(null);
          }
          const onChangeTable = (pagination) => {
               this.setState({
                    page: pagination.current
               })
               onListaDetallesSS(pagination.current, this.state.busqueda)
          }
          
          if(atrasMapa){
               console.log(`atrasMapa dentro de if`, atrasMapa)
               if(this.state.nivel === 0){
                    onHome();
               }
               else{
                    const imageUrl = this.state.imageUrl
                    this.setState({
                         imageUrl: null,
                         width: 0,
                         height: 0,
                    })
                    
                    const img = new Image();
                    const updateState = (width, height) => {
                         this.setState({
                              imageUrl,
                              width,
                              height,
                         })
                    }
                    img.onload = function() {
                         updateState(this.width, this.height);
                    }
                    img.src = imageUrl;
               }
               reiniciarAtrasMapa();
          }
          const selectFiltro = (value, tipo) => {
               let busqueda = this.state.busqueda;
               if(tipo !== 'folio'){
                    if(value[0]){
                         if(tipo === 'idServicio'){
                              let idsServicios = [];
                              for(let i in value){
                                   idsServicios.push(value[i].split(' - ')[0]);
                              }
                              busqueda.idServicio = idsServicios;
                         }
                         else{
                              busqueda[tipo] = value;
                         }
                    }
                    else{
                         busqueda[tipo] = null;
                    }
               }
               else {
                    busqueda.folio = value;
               }
               if(tipo === 'idGrupoSer'){
                    console.log(`value`, value)
                    if(value[0]){
                         onGetServiciosByGruposServicios(value)
                    }
                    else{
                         busqueda.idServicio = null;
                         busqueda.idGrupoSer = null;
                         onLimpiarServiciosByGruposServicios();
                    }
               }
               if(tipo === 'idGrupoSer' || tipo === 'idServicio' || tipo === 'estado' || tipo === 'folio'){
                    console.log(`this.state.nivel`, this.state.nivel)
                    if(this.state.nivel === 0){
                         onListaAreasNumero(busqueda, false);
                    }
                    else if(this.state.nivel === 1){
                         onListaUnidadesNumero(this.state.idAreaFuncional, busqueda, false);
                    }
                    else if(this.state.nivel === 2){
                         onListaInterioresNumero(this.state.idUnidadFuncional, busqueda, false);
                    }
                    else{
                         onListaSubInterioresNumero(this.state.idInteriorFuncional, busqueda, false);
                    }
               }
               this.setState({
                    busqueda,
               });
               onListaDetallesSS(this.state.page, busqueda);
               regresarDetalle();
          }

          const columns = [{
                    dataIndex: 'color',
                    key: 'color',
                    render: (text, record) => (
                    <Badge color={text === 'Negro' ? 'black' : text === 'Verde' ? 'green' : text === 'Amarillo' ? 'yellow' : 'red' }/>
                    )
               },{
                    title: 'Folio',
                    dataIndex:'folio',
                    key: 'folio',
                    width: '20%',
               },{
                    title: 'Descripción de SS',
                    dataIndex: 'descripcionSolicitud',
                    key: 'descripcionSolicitud',
                    width: '80%',
               },{
                    title: 'Detalle',
                    key: 'trazabilidad',
                    align: 'center', 
                    render: (text, record) => (
                        <a className='editarIcono' onClick={() => onDetalle(record)}><FileSearchOutlined /></a>
                    ),
               }
          ];

          const onDetalle = (value) => {
               console.log(`value`, value)
               this.setState({
                    mostrarDetalle: true,
                    imageUrl: null,
                    width: 0,
                    height: 0,
               })
               
               const img = new Image();
               const updateState = (width, height) => {
                    this.setState({
                         imageUrl: value.plano,
                         width,
                         height,
                    })
               }
               img.onload = function() {
                    updateState(this.width, this.height);
               }
               img.src = value.plano;
               onInfoDetalleSS(value);
          }
          
          const getGeoJson = () => {
               let listaPoligono = [];
               if(!fetchingInfoDetalleSS){
                    if(reiniciarMapa){
                         const rutaImagenCentro = this.state.imageUrl;
                         this.setState({
                              imageUrl: null,
                              width: 0,
                              height: 0,
                         })
                         const img = new Image();
                         const updateState = (width, height) => {
                              this.setState({
                                   imageUrl: rutaImagenCentro,
                                   width,
                                   height, 
                              })
                         }
                         img.onload = function() {
                              updateState(this.width, this.height);
                         }
                         img.src = rutaImagenCentro;
                         onInfoDetalleSS(infoDetalleSS);
                    }
                    if(infoDetalleSS){
                         listaPoligono = [{
                              type: "Feature",
                              properties: {
                                   color: infoDetalleSS.color === 'Negro' ? 'black' : infoDetalleSS.color === 'Verde' ? 'green' : infoDetalleSS.color === 'Amarillo' ? 'yellow' : 'red', 
                                   'id': infoDetalleSS.idSubInterior ? infoDetalleSS.idSubInterior : infoDetalleSS.idInterior ? infoDetalleSS.idInterior : infoDetalleSS.idUnidadFuncional ? infoDetalleSS.idUnidadFuncional : infoDetalleSS.idAreaFuncional,
                                   "popupContent": infoDetalleSS.folio
                              },
                              geometry: {
                                   type: "Polygon",
                                   coordinates: [
                                        infoDetalleSS.coordenadas
                                   ]
                              }
                         }];
                         return {
                              type: "FeatureCollection",
                              features: listaPoligono
                         }
                    }
                    else{
                         if(this.state.nivel === 0){
                              listaAreasNumero.map((ubicacion) => {
                                   listaPoligono.push(obtenerGemotrias(ubicacion))
                              })
                              return {
                                   type: "FeatureCollection",
                                   features: listaPoligono
                              }
                         }
                         else if(this.state.nivel === 1){
                              if(listaUnidadesNumero[0]){
                                   listaUnidadesNumero.map((ubicacion) => {
                                        listaPoligono.push(obtenerGemotrias(ubicacion))
                                   })
                                   return {
                                        type: "FeatureCollection",
                                        features: listaPoligono
                                   }
                              }
                              else{
                                   return {
                                        type: "FeatureCollection",
                                        features: listaPoligono
                                   }
                              }
                         }
                         else if(this.state.nivel === 2){
                              if(listaInterioresNumero[0]){
                                   listaInterioresNumero.map((ubicacion) => {
                                        listaPoligono.push(obtenerGemotrias(ubicacion))
                                   })
                                   return {
                                        type: "FeatureCollection",
                                        features: listaPoligono
                                   }
                              }
                              else{
                                   return {
                                        type: "FeatureCollection",
                                        features: listaPoligono
                                   }
                              }
                         }
                         else if(this.state.nivel === 3 && !fetchingListaSubInterioresNumero){
                              if(listaSubInterioresNumero[0]){
                                   listaSubInterioresNumero.map((ubicacion) => {
                                        listaPoligono.push(obtenerGemotrias(ubicacion))
                                   })
                                   return {
                                        type: "FeatureCollection",
                                        features: listaPoligono
                                   }
                              }
                              else{
                                   listaInterioresNumero.map((ubicacion) => {
                                        if(ubicacion.id === this.state.idInteriorFuncional){
                                             listaPoligono.push(obtenerGemotrias(ubicacion))
                                        }
                                   })
                                   return {
                                        type: "FeatureCollection",
                                        features: listaPoligono
                                   }
                              }
                         }
                         else{
                              return {
                                   type: "FeatureCollection",
                                   features: listaPoligono
                              }
                         }
                    }
               }
          }
          const obtenerGemotrias = (ubicacion) => {
               return(
                    ubicacion.numeroSS !==0 ? ({
                         type: "Feature",
                         properties: {
                              'color': ubicacion.color, 
                              'id': ubicacion.id,
                              'popupContent': ubicacion.nombre + ' - ' + ubicacion.numeroSS + ' SS',
                              'posicion': ubicacion.posicion,
                         },
                         geometry: {
                              type: "Polygon",
                              coordinates: [
                                   ubicacion.coordenadas
                              ]
                         },  
                    }) : false
               )
          }
          var greenIcon = L.icon({
               iconUrl: iconoVerde,
               iconSize:     [30, 30], // size of the icon
               //iconAnchor:   [22, 22], // point of the icon which will correspond to marker's location
               //popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
          });
          var yellowIcon = L.icon({
               iconUrl: iconoAmarillo,
               iconSize:     [30, 30], // size of the icon
               //iconAnchor:   [22, 22], // point of the icon which will correspond to marker's location
               //popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
          });
          var redIcon = L.icon({
               iconUrl: iconoRojo,
               iconSize:     [30, 30], // size of the icon
               //iconAnchor:   [22, 22], // point of the icon which will correspond to marker's location
               //popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
          });
          var blackIcon = L.icon({
               iconUrl: iconoNegro,
               iconSize:     [30, 30], // size of the icon
               //iconAnchor:   [22, 22], // point of the icon which will correspond to marker's location
               //popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
          });
           
          
          const _onFeatureGroupReady = reactFGref => {
               // populate the leaflet FeatureGroup with the geoJson layers
               const _this = this;
               if(!reactFGref) return;
               let leafletGeoJSON = new L.GeoJSON(getGeoJson(), {
                    style: function (feature) {
                        return {color: feature.properties.color};
                    }})
               let leafletFG = reactFGref.leafletElement;
               let layers = leafletFG._layers;
               
               //Mostrar el geométrico con las coordenadas de las ubicaciones
               leafletGeoJSON.on("click", function (e){
                    if(_this.state.nivel === 0){
                         _this.setState({
                              imageUrl: null,
                              width: 0,
                              height: 0
                         })
                         for(let i in listaAreasNumero){
                              if(listaAreasNumero[i].id === e.layer._leaflet_id){
                                   const rutaImagenCentro = listaAreasNumero[i].rutaArchivo;
                                   const img = new Image();
                                   const updateState = (width, height) => {
                                        _this.setState({
                                             imageUrl: rutaImagenCentro,
                                             imagenArea: rutaImagenCentro,
                                             width,
                                             height,
                                             nivel: _this.state.nivel + 1,
                                        })
                                   }
                                   img.onload = function() {
                                        updateState(this.width, this.height);
                                   }
                                   img.src = rutaImagenCentro;
                              }
                         }
                         const busqueda = _this.state.busqueda;
                         busqueda.idAreaFuncional = e.layer._leaflet_id;
                         busqueda.idUnidadFuncional = null;
                         onListaUnidadesNumero(e.layer._leaflet_id, busqueda, true);
                         onListaDetallesSS(_this.state.page, busqueda)
                         _this.setState({
                              idAreaFuncional: e.layer._leaflet_id,
                              idUnidadFuncional: null,
                              busqueda
                         })
                    }
                    else if(_this.state.nivel === 1){
                         _this.setState({
                              imageUrl: null,
                              width: 0,
                              height: 0
                         })
                         for(let i in listaUnidadesNumero){
                              if(listaUnidadesNumero[i].id === e.layer._leaflet_id){console.log(`listaUnidadesNumero[i].rutaArchivo`, listaUnidadesNumero[i].rutaArchivo)
                                   const rutaImagenCentro = listaUnidadesNumero[i].rutaArchivo;
                                   const img = new Image();
                                   const updateState = (width, height) => {
                                        _this.setState({
                                             imageUrl: rutaImagenCentro,
                                             imagenUnidad: rutaImagenCentro,
                                             width,
                                             height,
                                             nivel: _this.state.nivel + 1,
                                        })
                                   }
                                   img.onload = function() {
                                        updateState(this.width, this.height);
                                   }
                                   img.src = rutaImagenCentro;
                              }
                         }
                         const busqueda = _this.state.busqueda;
                         busqueda.idUnidadFuncional = e.layer._leaflet_id
                         onListaInterioresNumero(e.layer._leaflet_id, busqueda, true);
                         onListaDetallesSS(_this.state.page, busqueda)
                         _this.setState({
                              idUnidadFuncional: e.layer._leaflet_id,
                              busqueda
                         })
                    }
                    else if(_this.state.nivel === 2 && !_this.state.mostrarDetalle){
                         const rutaImagenCentro = _this.state.imageUrl;
                         _this.setState({
                              imageUrl: null,
                              width: 0,
                              height: 0
                         })
                         const img = new Image();
                         const updateState = (width, height) => {
                              _this.setState({
                                   imageUrl: rutaImagenCentro,
                                   width,
                                   height,
                                   nivel: _this.state.nivel + 1,
                              })
                         }
                         img.onload = function() {
                              updateState(this.width, this.height);
                         }
                         img.src = rutaImagenCentro;

                         const busqueda = _this.state.busqueda;
                         busqueda.idInteriorFuncional = e.layer._leaflet_id
                         onListaSubInterioresNumero(e.layer._leaflet_id, busqueda, true);
                         onListaDetallesSS(_this.state.page, busqueda)
                         _this.setState({
                              idInteriorFuncional: e.layer._leaflet_id,
                              busqueda
                         })
                    }
               });
    
               let layers_ids = Object.keys(layers);
               
               //Marcadores dentro del mapa
               const showMarkers = (nombre, descripcion, y, x, color, idSolicitudServicio) => {
                    const marker = L.marker([x, y], {icon: color === 'Negro' ? blackIcon : color === 'Rojo' ? redIcon : color === 'Amarillo' ? yellowIcon : greenIcon})
                    leafletFG.addLayer(marker);
               
                    marker.bindPopup("<b>" + nombre + "</b><br>" + descripcion);

                    marker.on('mouseover', function (e) {
                         this.openPopup();
                    });
                    marker.on('mouseout', function (e) {
                         this.closePopup();
                    }); 
                    marker.on('click', function (e) {
                         onInfoDetalleSSRequest(idSolicitudServicio)
                         _this.setState({
                              mostrarDetalle: true,
                         })
                    });    
               }
               if(_this.state.nivel > 1 && getGeoJson()){
                    const points = getGeoJson();
                    let x = 0;
                    for(let i in points.features){
                         if(points.features[i]){
                              if(points.features[i].properties.popupContent.split(' - ')[1]){
                                   let repetir = 0;
                                   let mover = 10;
                                   for(let y=0; y<parseInt(points.features[i].properties.popupContent.split(' - ')[1].split(' SS')[0]); y++){
                                        if(listaDetalleMapa[x]){
                                             if(points.features[i].geometry.coordinates[0][x]){
                                                  showMarkers(listaDetalleMapa[x].folio, listaDetalleMapa[x].descripcionSolicitud, points.features[i].geometry.coordinates[0][y][0], points.features[i].geometry.coordinates[0][y][1], listaDetalleMapa[x].color, listaDetalleMapa[x].idSolicitudServicio)
                                                  x ++;
                                             }
                                             else{
                                                  showMarkers(listaDetalleMapa[x].folio, listaDetalleMapa[x].descripcionSolicitud, points.features[i].geometry.coordinates[0][repetir][2] === 'derecha' ? points.features[i].geometry.coordinates[0][repetir][0] + mover : points.features[i].geometry.coordinates[0][repetir][0] - mover, points.features[i].geometry.coordinates[0][repetir][1], listaDetalleMapa[x].color, listaDetalleMapa[x].idSolicitudServicio)
                                                  repetir ++;
                                                  x ++;
                                                  if(repetir === points.features[i].geometry.coordinates[0].length){
                                                       repetir = 0;
                                                       mover = mover + 10;
                                                  }
                                             }
                                        }
                                   }
                              }
                         }
                    }
               }

               if(layers_ids.length === 0) {
                    //add layers
                    leafletGeoJSON.eachLayer(layer => {
                         layer._leaflet_id = layer.feature.properties.id
                         leafletFG.addLayer(layer);
                         leafletFG._leaflet_id = layer.feature.properties.id
                         layer.bindPopup(layer.feature.properties.popupContent);

                         layer.on('mouseover', function (e) {
                              this.openPopup();
                         });
                         layer.on('mouseout', function (e) {
                              this.closePopup();
                         });              
                    });
               }
          };
          
          const bounds = [
               [0, 0],
               [this.state.height, this.state.width],
          ]
          const style = { height: '100%', width: '100%'}

          const regresarDetalle = () => {
               this.setState({
                    imageUrl: null,
                    width: null,
                    height: null,
               })
               let rutaImagenCentro = null;
               if(this.state.nivel === 1){
                    for(let i in listaAreasNumero){
                         if(this.state.idAreaFuncional === listaAreasNumero[i].id){
                              rutaImagenCentro = listaAreasNumero[i].rutaArchivo;
                         }
                    }
               }
               else{
                    for(let i in listaUnidadesNumero){
                         if(this.state.idUnidadFuncional === listaUnidadesNumero[i].id){
                              rutaImagenCentro = listaUnidadesNumero[i].rutaArchivo;
                         }
                    }  
               }
               const img = new Image();
               const updateState = (width, height) => {
                    this.setState({
                         imageUrl: rutaImagenCentro,
                         width,
                         height,
                    })
               }
               img.onload = function() {
                    updateState(this.width, this.height);
               }
               img.src = rutaImagenCentro;

               this.setState({
                    mostrarDetalle: false,
               })
               onInfoDetalleSS(null);
          }
          let charDataPie = {
               labels: ['Generadas', 'En proceso', 'Fuera de tiempo', 'Cerradas'],
               datasets: [{  data: [94, 64, 14, 11],  backgroundColor: ['#d1dbaf', '#b1c379', '#e64d14', '#6b7c37'], borderColor: '#f8faf3',  borderWidth: 2 }],
          };
          let charDataPie2 = {
               labels: ['Planificadas', 'En proceso', 'Fuera de tiempo'],
               datasets: [{  data: [94, 73, 21],  backgroundColor: ['#d1dbaf', '#b1c379', '#e64d14'], borderColor: '#f8faf3',  borderWidth: 2 }],
          };

          const cambiarVista = (value) => {
               this.props.onCambiarVista(value);
          }

          let data=[]
          if (infoDetalleSS){
               const {descripcionSolicitud, estado, fechaCompromiso, ubicacion, fechaAlta, servicio} = infoDetalleSS;
               
               data = [{title:"Descripción", data: descripcionSolicitud}, {title:"Fecha de compromiso", data: fechaCompromiso.split('T')[0].split('-').reverse().join('-') + ' ' + fechaCompromiso.split('T')[1].split(':')[0] + ':' + fechaCompromiso.split('T')[1].split(':')[1]}, 
               {title:"Fecha de alta", data: fechaAlta.split('T')[0].split('-').reverse().join('-') + ' ' + fechaAlta.split('T')[1].split(':')[0] + ':' + fechaAlta.split('T')[1].split(':')[1]}, {title:"Ubicación", data: ubicacion}, {title:"Servicio", data: servicio}, {title:"Estado",data: estado}];
          }
          return (
               <Row>
                    <Col span={6} style={{backgroundColor: '#737373', borderTopLeftRadius: '1em', borderBottomLeftRadius: '1em'}}>
                         <br/>
                         <p className='textoBlanco' style={{textAlign: 'center', fontSize: 14, color: 'white'}}><strong>Filtro de Búsqueda</strong></p>
                         <br/>
                         <br/>
                         <Row justify='space-around'>
                              <Col span={22}>
                                   <Select allowClear 
                                        mode='multiple'
                                        style={{ width: '100%', textAlign: 'left' }} 
                                        placeholder="Selecciona un grupo de servicios" 
                                        onChange={(e) => selectFiltro(e, 'idGrupoSer')} 
                                        loading={fetchingListaGruposServicios}
                                   >
                                        {listaGruposServicios.map(option => 
                                        <Option key={option.idGrupoSer}>
                                             <Tooltip title={ option.nombre + ': ' + option.descripcion }>
                                                  { option.nombre + ': ' + option.descripcion }
                                             </Tooltip>
                                        </Option>
                                        )}
                                   </Select>
                                   <br/>
                                   <br/>
                              </Col>
                              {this.state.busqueda.idGrupoSer ? (
                              <Col span={22}>
                                   <Select allowClear
                                        mode='multiple'
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona un servicio"
                                        loading={fetchingListaServiciosByGruposServicios}
                                        onChange={(e) => selectFiltro(e, 'idServicio')}
                                        showSearch
                                   >
                                        {listaServiciosByGruposServicios.map(option => 
                                        <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                             <Tooltip title={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                  {option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}
                                             </Tooltip>
                                        </Option>)}
                                   </Select>
                                   <br/>
                                   <br/>
                              </Col>
                              ):false}

                              <Col span={22}>
                                   <Select allowClear mode='multiple' style={{ width: '100%', textAlign: 'left' }} placeholder="Selecciona un estado" onChange={(e) => selectFiltro(e, 'estado')} loading={fetchingListaGruposServicios}>
                                        <Option key='Generada'>Generada</Option>
                                        <Option key='Abierto'>Abierto</Option>
                                        <Option key='En proceso'>En proceso</Option>
                                        <Option key='En espera'>En espera</Option>
                                   </Select>
                                   <br/>
                                   <br/>
                              </Col>
                              {!this.state.mostrarDetalle ? (
                              <Col span={this.state.idAreaFuncional ? 22: 0}>
                                   <Table 
                                        title={() => 
                                        <Form name="formulario" ref={this.formRef}>
                                             <Form.Item name="folio">
                                                  <Search placeholder="Ingresa folio a buscar" onSearch={(e) => selectFiltro(e, 'folio')} style={{ width: '100%' }} />
                                             </Form.Item>
                                        </Form>}
                                        size="small" 
                                        rowKey="idSolicitudServicio" 
                                        columns={columns}
                                        onChange={onChangeTable}
                                        dataSource={listaDetallesSS}
                                        loading={fetchingListaDetallesSS}
                                        locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar'}}
                                        pagination={{ simple: true, total: listaDetallesSSCantidad  }}
                                   />    
                              </Col>
                              ):(
                              <Col span={22}>
                                   {infoDetalleSS ? (
                                   <>
                                        <Card className="cardCatalogos" 
                                             headStyle={{ backgroundColor: '#b1c5fa8a', borderTopLeftRadius: '1.7em', borderTopRightRadius: '1.7em', borderColor: '#b1c5fa8a'}} 
                                             title={infoDetalleSS.folio}
                                             loading={fetchingInfoDetalleSS}
                                             extra={<LogoutOutlined className='editarIcono' onClick={regresarDetalle}/>}
                                        >
                                             <List
                                             className="listaDetalle"
                                             bordered={false}
                                             dataSource={data}
                                             renderItem={item => (
                                                 <List.Item className='listaHover' className='listaHover'>
                                                       <Col span={9}>
                                                       <strong>{item.title}</strong>  
                                                       </Col>
                                                       <Col span={14}>
                                                       {item.data} 
                                                       </Col>
                                                  </List.Item>
                                             )}
                                             />
                                        </Card>
                                        <br/>
                                   </>
                                   ):false}
                              </Col>
                              )}
                         </Row>
                    </Col>
                    <Col span={18} style={{backgroundColor: '#dddddd', borderBottomRightRadius: '1em', borderTopRightRadius:'1em' }}>
                         <Row type="flex" align='middle'>
                              <Col span={24} style={{backgroundColor: '#E4E4E4', paddingTop: '1em', paddingBottom: '1em', paddingLeft: '1em', borderTopRightRadius:'1em'}}>
                                   <Row justify='start' align='middle' gutter={[0,0]}>
                                        <Text style={{textAlign: 'left', fontSize: '2em', color: '#737373', borderLeftColor: '#E4E4E4', borderRightColor:'#737373', borderTopColor:'#E4E4E4', borderBottomColor:'#E4E4E4', borderStyle: 'solid', paddingRight: '.5em'}} strong><a onClick={stepBack} disabled={this.state.nivel === 0}><DoubleLeftOutlined className={this.state.nivel === 0 ? 'noRegresar' : 'regresar'}/></a> <a onClick={onHome} disabled={this.state.nivel === 0 ? true: false}><HomeOutlined className={this.state.nivel === 0 ? 'noRegresar' : 'regresar'}/></a></Text>
                                        <Text style={{textAlign: 'left', fontSize: '2em', color: '#737373', paddingLeft: '0.5em'}} strong>Mapa De Incidencias</Text>
                                   </Row>
                              </Col>
                              <Col span={24}>
                                   <Spin spinning={fetchingListaAreasNumero || fetchingListaUnidadesNumero || fetchingListaInterioresNumero || fetchingListaSubInterioresNumero || fetchingListaDetallesSS || fetchingInfoDetalleSS}>
                                        <div id="mapid">
                                             {this.state.imageUrl ? (
                                             <Map
                                                  crs={L.CRS.Simple}
                                                  minZoom={-3}
                                                  bounds={bounds}
                                                  style={style}
                                             >
                                                  <FeatureGroup
                                                       ref={reactFGref => {
                                                       _onFeatureGroupReady(reactFGref);
                                                       }}
                                                  />
                                                  <ImageOverlay
                                                       url={this.state.ubicacion ? this.state.ubicacion.rutaArchivo : this.state.imageUrl}
                                                       bounds={bounds}
                                                       zIndex={1}
                                                  >
                                                  </ImageOverlay>
                                             </Map>
                                             ) : false}
                                        </div>
                                   </Spin>
                              </Col>
                         </Row>
                    </Col>
               </Row>
          );
     }
}
 
const mapStateToProps = state => {
     return {
          fetchingListaAreasNumero: state.MonitorIntegralReducer.fetchingListaAreasNumero,
          listaAreasNumero: state.MonitorIntegralReducer.listaAreasNumero,
          fetchingListaUnidadesNumero: state.MonitorIntegralReducer.fetchingListaUnidadesNumero,
          listaUnidadesNumero: state.MonitorIntegralReducer.listaUnidadesNumero,
          fetchingListaInterioresNumero: state.MonitorIntegralReducer.fetchingListaInterioresNumero,
          listaInterioresNumero: state.MonitorIntegralReducer.listaInterioresNumero,
          fetchingListaSubInterioresNumero: state.MonitorIntegralReducer.fetchingListaSubInterioresNumero,
          listaSubInterioresNumero: state.MonitorIntegralReducer.listaSubInterioresNumero,
          fetchingListaDetallesSS: state.MonitorIntegralReducer.fetchingListaDetallesSS,
          listaDetallesSS: state.MonitorIntegralReducer.listaDetallesSS,
          listaDetallesSSCantidad: state.MonitorIntegralReducer.listaDetallesSSCantidad,
          listaDetalleMapa: state.MonitorIntegralReducer.listaDetalleMapa,
          fetchingListaGruposServicios: state.CatalogosGeneralesReducer.fetchingListaGruposServicios,
          listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,
          fetchingListaServiciosByGruposServicios: state.CatalogosGeneralesReducer.fetchingListaServiciosByGruposServicios,
          listaServiciosByGruposServicios: state.CatalogosGeneralesReducer.listaServiciosByGruposServicios,
          listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
          fetchingListaInfoProyecto: state.InformacionDelProyectoReducer.fetchingListaInfoProyecto,
          atrasMapa: state.MonitorIntegralReducer.atrasMapa,
          infoDetalleSS: state.MonitorIntegralReducer.infoDetalleSS,
          fetchingInfoDetalleSS: state.MonitorIntegralReducer.fetchingInfoDetalleSS,
          reiniciarMapa: state.MonitorIntegralReducer.reiniciarMapa,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onListaAreasNumero: (busqueda, noAtras) => {
               dispatch({ type: 'LISTA_AREAS_NUMERO_REQUEST', busqueda, noAtras })
          },
          onListaUnidadesNumero: (idAreaFuncional, busqueda, noAtras) => {
               dispatch({ type: 'LISTA_UNIDADES_NUMERO_REQUEST', idAreaFuncional, busqueda, noAtras })
          },
          onListaInterioresNumero: (idUnidadFuncional, busqueda, noAtras) => {
               dispatch({ type: 'LISTA_INTERIORES_NUMERO_REQUEST', idUnidadFuncional, busqueda, noAtras })
          },
          onListaSubInterioresNumero: (idInteriorFuncional, busqueda, noAtras) => {
               dispatch({ type: 'LISTA_SUBINTERIORES_NUMERO_REQUEST', idInteriorFuncional, busqueda, noAtras })
          },
          onLimpiarListaUnidadesNumero: () => {
               dispatch({ type: 'LISTA_UNIDADES_NUMERO_SUCCESS', listaUnidadesNumero: [] })
          },
          onLimpiarListaInterioresNumero: () => {
               dispatch({ type: 'LISTA_INTERIORES_NUMERO_SUCCESS', listaInterioresNumero: [] })
          },
          onLimpiarListaSubInterioresNumero: () => {
               dispatch({ type: 'LISTA_SUBINTERIORES_NUMERO_SUCCESS', listaSubInterioresNumero: [] })
          },
          onGetGruposServicios: () => {
               dispatch({ type: "LISTA_GRUPOS_SERVICIOS_REQUEST" });
          },
          onGetServiciosByGruposServicios: (idsGrupoSer) => {
               dispatch({ type: 'LISTA_SERVICIOS_BY_GRUPOS_SERV_REQUEST', idsGrupoSer });
          },
          onLimpiarServiciosByGruposServicios: () => {
               dispatch({ type: 'LISTA_SERVICIOS_BY_GRUPOS_SERV_SUCCESS', listaServiciosByGruposServicios: [] });
          },
          onListaDetallesSS: (page, busqueda) => {
               dispatch({ type: 'LISTA_DETALLES_SS_REQUEST', page, busqueda })
          },
          reiniciarAtrasMapa: () => {
               dispatch({ type: 'ATRAS_MAPA', atrasMapa: false })
          },
          onInfoDetalleSS: (infoDetalleSS) => {
               dispatch({ type: 'INFO_DETALLE_SS_SUCCESS', infoDetalleSS })
          },
          onInfoDetalleSSRequest: (idSolicitudServicio) => {
               dispatch({ type: 'INFO_DETALLE_SS_REQUEST', idSolicitudServicio })
          },
     };
};
export default connect(mapStateToProps, mapDispatchToProps)(MapaDeIncidenciasContainer);