export const CAMBIAR_CATALOGO_ALMACENES = "CAMBIAR_CATALOGO_ALMACENES";

//CATEGORIAS DE MEDICION
export const LISTA_CATEGORIAS_MEDICION_ACTIVOS_REQUEST = 'LISTA_CATEGORIAS_MEDICION_ACTIVOS_REQUEST';
export const LISTA_CATEGORIAS_MEDICION_ACTIVOS_SUCCESS = 'LISTA_CATEGORIAS_MEDICION_ACTIVOS_SUCCESS';
export const LISTA_CATEGORIAS_MEDICION_ACTIVOS_FAILURE = 'LISTA_CATEGORIAS_MEDICION_ACTIVOS_FAILURE';

export const CATEGORIAS_MEDICION_BUSQUEDA_REQUEST = 'CATEGORIAS_MEDICION_BUSQUEDA_REQUEST';
export const CATEGORIAS_MEDICION_BUSQUEDA_SUCCESS = 'CATEGORIAS_MEDICION_BUSQUEDA_SUCCESS';
export const CATEGORIAS_MEDICION_BUSQUEDA_FAILURE = 'CATEGORIAS_MEDICION_BUSQUEDA_FAILURE';

export const NUEVA_CATEGORIA_MEDICION_REQUEST = 'NUEVA_CATEGORIA_MEDICION_REQUEST';
export const NUEVA_CATEGORIA_MEDICION_SUCCESS = 'NUEVA_CATEGORIA_MEDICION_SUCCESS';
export const NUEVA_CATEGORIA_MEDICION_FAILURE = 'NUEVA_CATEGORIA_MEDICION_FAILURE';

export const EDITAR_CATEGORIA_MEDICION_REQUEST = 'EDITAR_CATEGORIA_MEDICION_REQUEST';
export const EDITAR_CATEGORIA_MEDICION_SUCCESS = 'EDITAR_CATEGORIA_MEDICION_SUCCESS';
export const EDITAR_CATEGORIA_MEDICION_FAILURE = 'EDITAR_CATEGORIA_MEDICION_FAILURE';

export const ESTADO_CATEGORIA_MEDICION_REQUEST = 'ESTADO_CATEGORIA_MEDICION_REQUEST';
export const ESTADO_CATEGORIA_MEDICION_SUCCESS = 'ESTADO_CATEGORIA_MEDICION_SUCCESS';
export const ESTADO_CATEGORIA_MEDICION_FAILURE = 'ESTADO_CATEGORIA_MEDICION_FAILURE';

export const UNIDADES_MEDI_POR_CATEGORIA_REQUEST = 'UNIDADES_MEDI_POR_CATEGORIA_REQUEST';
export const UNIDADES_MEDI_POR_CATEGORIA_SUCCESS = 'UNIDADES_MEDI_POR_CATEGORIA_SUCCESS';
export const UNIDADES_MEDI_POR_CATEGORIA_FAILURE = 'UNIDADES_MEDI_POR_CATEGORIA_FAILURE';

export const SHOW_MODAL_NUEVA_CATEGORIA_MEDICION = 'SHOW_MODAL_NUEVA_CATEGORIA_MEDICION';
export const SHOW_MODAL_EDITAR_CATEGORIA_MEDICION = 'SHOW_MODAL_EDITAR_CATEGORIA_MEDICION';

export const EXPORTAR_CATEGORIA_MEDICION_EXCEL_REQUEST = 'EXPORTAR_CATEGORIA_MEDICION_EXCEL_REQUEST';
export const EXPORTAR_CATEGORIA_MEDICION_EXCEL_SUCCESS = 'EXPORTAR_CATEGORIA_MEDICION_EXCEL_SUCCESS';
export const EXPORTAR_CATEGORIA_MEDICION_EXCEL_FAILURE = 'EXPORTAR_CATEGORIA_MEDICION_EXCEL_FAILURE';

//UNIDADES DE MEDICION
export const LISTA_UNIDADES_MEDICION_ACTIVOS_REQUEST = 'LISTA_UNIDADES_MEDICION_ACTIVOS_REQUEST';
export const LISTA_UNIDADES_MEDICION_ACTIVOS_SUCCESS = 'LISTA_UNIDADES_MEDICION_ACTIVOS_SUCCESS';
export const LISTA_UNIDADES_MEDICION_ACTIVOS_FAILURE = 'LISTA_UNIDADES_MEDICION_ACTIVOS_FAILURE';

export const UNIDADES_MEDICION_BUSQUEDA_REQUEST = 'UNIDADES_MEDICION_BUSQUEDA_REQUEST';
export const UNIDADES_MEDICION_BUSQUEDA_SUCCESS = 'UNIDADES_MEDICION_BUSQUEDA_SUCCESS';
export const UNIDADES_MEDICION_BUSQUEDA_FAILURE = 'UNIDADES_MEDICION_BUSQUEDA_FAILURE';

export const NUEVA_UNIDAD_MEDICION_REQUEST = 'NUEVA_UNIDAD_MEDICION_REQUEST';
export const NUEVA_UNIDAD_MEDICION_SUCCESS = 'NUEVA_UNIDAD_MEDICION_SUCCESS';
export const NUEVA_UNIDAD_MEDICION_FAILURE = 'NUEVA_UNIDAD_MEDICION_FAILURE';

export const EDITAR_UNIDAD_MEDICION_REQUEST = 'EDITAR_UNIDAD_MEDICION_REQUEST';
export const EDITAR_UNIDAD_MEDICION_SUCCESS = 'EDITAR_UNIDAD_MEDICION_SUCCESS';
export const EDITAR_UNIDAD_MEDICION_FAILURE = 'EDITAR_UNIDAD_MEDICION_FAILURE';

export const ESTADO_UNIDADES_MEDICION_REQUEST = 'ESTADO_UNIDADES_MEDICION_REQUEST';
export const ESTADO_UNIDADES_MEDICION_SUCCESS = 'ESTADO_UNIDADES_MEDICION_SUCCESS';
export const ESTADO_UNIDADES_MEDICION_FAILURE = 'ESTADO_UNIDADES_MEDICION_FAILURE';

export const SHOW_MODAL_NUEVA_UNIDAD_MEDICION = 'SHOW_MODAL_NUEVA_UNIDAD_MEDICION';
export const SHOW_MODAL_EDITAR_UNIDAD_MEDICION = 'SHOW_MODAL_EDITAR_UNIDAD_MEDICION';

export const EXPORTAR_UNIDAD_MEDICION_EXCEL_REQUEST = 'EXPORTAR_UNIDAD_MEDICION_EXCEL_REQUEST';
export const EXPORTAR_UNIDAD_MEDICION_EXCEL_SUCCESS = 'EXPORTAR_UNIDAD_MEDICION_EXCEL_SUCCESS';
export const EXPORTAR_UNIDAD_MEDICION_EXCEL_FAILURE = 'EXPORTAR_UNIDAD_MEDICION_EXCEL_FAILURE';

//ACTIONS ACTIVOS CARACTERÍSTICAS
export const LISTA_ACTIVOS_CARACT_REQUEST = 'LISTA_ACTIVOS_CARACT_REQUEST';
export const LISTA_ACTIVOS_CARACT_SUCCESS = 'LISTA_ACTIVOS_CARACT_SUCCESS';
export const LISTA_ACTIVOS_CARACT_FAILURE = 'LISTA_ACTIVOS_CARACT_FAILURE';

export const LISTA_ACTIVOS_CARACT_BUSQUEDA_REQUEST = 'LISTA_ACTIVOS_CARACT_BUSQUEDA_REQUEST';
export const LISTA_ACTIVOS_CARACT_BUSQUEDA_SUCCESS = 'LISTA_ACTIVOS_CARACT_BUSQUEDA_SUCCESS';
export const LISTA_ACTIVOS_CARACT_BUSQUEDA_FAILURE = 'LISTA_ACTIVOS_CARACT_BUSQUEDA_FAILURE';

export const LISTA_ACTIVOS_CARACT_FILTRO_REQUEST = 'LISTA_ACTIVOS_CARACT_FILTRO_REQUEST';
export const LISTA_ACTIVOS_CARACT_FILTRO_SUCCESS = 'LISTA_ACTIVOS_CARACT_FILTRO_SUCCESS';
export const LISTA_ACTIVOS_CARACT_FILTRO_FAILURE = 'LISTA_ACTIVOS_CARACT_FILTRO_FAILURE';

export const LISTA_ACTIVOS_CARACT_FILTRO_ASIGNACION_SP_REQUEST = 'LISTA_ACTIVOS_CARACT_FILTRO_ASIGNACION_SP_REQUEST';
export const LISTA_ACTIVOS_CARACT_FILTRO_ASIGNACION_SP_SUCCESS = 'LISTA_ACTIVOS_CARACT_FILTRO_ASIGNACION_SP_SUCCESS';
export const LISTA_ACTIVOS_CARACT_FILTRO_ASIGNACION_SP_FAILURE = 'LISTA_ACTIVOS_CARACT_FILTRO_ASIGNACION_SP_FAILURE';

export const ESTADO_ACTIVO_CARACT_REQUEST = 'ESTADO_ACTIVO_CARACT_REQUEST';
export const ESTADO_ACTIVO_CARACT_SUCCESS = 'ESTADO_ACTIVO_CARACT_SUCCESS';
export const ESTADO_ACTIVO_CARACT_FAILURE = 'ESTADO_ACTIVO_CARACT_FAILURE';

export const SHOW_MODAL_NUEVO_ACTIVO_CARACT = 'SHOW_MODAL_NUEVO_ACTIVO_CARACT';
export const SHOW_MODAL_EDITAR_ACTIVO_CARACT = 'SHOW_MODAL_EDITAR_ACTIVO_CARACT';

export const NUEVO_ACTIVO_CARACT_REQUEST = 'NUEVO_ACTIVO_CARACT_REQUEST';
export const NUEVO_ACTIVO_CARACT_SUCCESS = 'NUEVO_ACTIVO_CARACT_SUCCESS';
export const NUEVO_ACTIVO_CARACT_FAILURE = 'NUEVO_ACTIVO_CARACT_FAILURE';

export const EDITAR_ACTIVO_CARACT_REQUEST = 'EDITAR_ACTIVO_CARACT_REQUEST';
export const EDITAR_ACTIVO_CARACT_SUCCESS = 'EDITAR_ACTIVO_CARACT_SUCCESS';
export const EDITAR_ACTIVO_CARACT_FAILURE = 'EDITAR_ACTIVO_CARACT_FAILURE';

export const EXPORTAR_CARACTERISTICAS_ACTIVOS_EXCEL_REQUEST = 'EXPORTAR_CARACTERISTICAS_ACTIVOS_EXCEL_REQUEST';
export const EXPORTAR_CARACTERISTICAS_ACTIVOS_EXCEL_SUCCESS = 'EXPORTAR_CARACTERISTICAS_ACTIVOS_EXCEL_SUCCESS';
export const EXPORTAR_CARACTERISTICAS_ACTIVOS_EXCEL_FAILURE = 'EXPORTAR_CARACTERISTICAS_ACTIVOS_EXCEL_FAILURE';

//ACTIONS CATALOGO ACTIVOS 
export const LISTA_ACTIVOS_REQUEST = 'LISTA_ACTIVOS_REQUEST';
export const LISTA_ACTIVOS_SUCCESS = 'LISTA_ACTIVOS_SUCCESS';
export const LISTA_ACTIVOS_FAILURE = 'LISTA_ACTIVOS_FAILURE';

export const LISTA_ACTIVO_BUSQUEDA_REQUEST = 'LISTA_ACTIVO_BUSQUEDA_REQUEST';
export const LISTA_ACTIVO_BUSQUEDA_SUCCESS = 'LISTA_ACTIVO_BUSQUEDA_SUCCESS';
export const LISTA_ACTIVO_BUSQUEDA_FAILURE = 'LISTA_ACTIVO_BUSQUEDA_FAILURE';

export const ESTADO_ACTIVO_REQUEST = 'ESTADO_ACTIVO_REQUEST';
export const ESTADO_ACTIVO_SUCCESS = 'ESTADO_ACTIVO_SUCCESS';
export const ESTADO_ACTIVO_FAILURE = 'ESTADO_ACTIVO_FAILURE';

export const SHOW_MODAL_NUEVO_ACTIVO = 'SHOW_MODAL_NUEVO_ACTIVO';
export const SHOW_MODAL_EDITAR_ACTIVO = 'SHOW_MODAL_EDITAR_ACTIVO';

export const NUEVO_ACTIVO_REQUEST = 'NUEVO_ACTIVO_REQUEST';
export const NUEVO_ACTIVO_SUCCESS = 'NUEVO_ACTIVO_SUCCESS';
export const NUEVO_ACTIVO_FAILURE = 'NUEVO_ACTIVO_FAILURE';

export const EDITAR_ACTIVO_REQUEST = 'EDITAR_ACTIVO_REQUEST';
export const EDITAR_ACTIVO_SUCCESS = 'EDITAR_ACTIVO_SUCCESS';
export const EDITAR_ACTIVO_FAILURE = 'EDITAR_ACTIVO_FAILURE';

export const EXPORTAR_ACTIVOS_EXCEL_REQUEST = 'EXPORTAR_ACTIVOS_EXCEL_REQUEST';
export const EXPORTAR_ACTIVOS_EXCEL_SUCCESS = 'EXPORTAR_ACTIVOS_EXCEL_SUCCESS';
export const EXPORTAR_ACTIVOS_EXCEL_FAILURE = 'EXPORTAR_ACTIVOS_EXCEL_FAILURE';

//ACTIONS ASIGNACIÓN DE ACTIVOS
export const LISTA_ASIGNACION_ACTIVOS_REQUEST = 'LISTA_ASIGNACION_ACTIVOS_REQUEST';
export const LISTA_ASIGNACION_ACTIVOS_SUCCESS = 'LISTA_ASIGNACION_ACTIVOS_SUCCESS';
export const LISTA_ASIGNACION_ACTIVOS_FAILURE = 'LISTA_ASIGNACION_ACTIVOS_FAILURE';

export const ASIGNACION_ACTIVOS_BUSQUEDA_REQUEST = 'ASIGNACION_ACTIVOS_BUSQUEDA_REQUEST';
export const ASIGNACION_ACTIVOS_BUSQUEDA_SUCCESS = 'ASIGNACION_ACTIVOS_BUSQUEDA_SUCCESS';
export const ASIGNACION_ACTIVOS_BUSQUEDA_FAILURE = 'ASIGNACION_ACTIVOS_BUSQUEDA_FAILURE';

export const NUEVA_ASIGNACION_ACTIVOS_REQUEST = 'NUEVA_ASIGNACION_ACTIVOS_REQUEST';
export const NUEVA_ASIGNACION_ACTIVOS_SUCCESS = 'NUEVA_ASIGNACION_ACTIVOS_SUCCESS';
export const NUEVA_ASIGNACION_ACTIVOS_FAILURE = 'NUEVA_ASIGNACION_ACTIVOS_FAILURE';

export const LISTA_AREA_FUNCIONAL_ACTIVA_REQUEST = 'LISTA_AREA_FUNCIONAL_ACTIVA_REQUEST';
export const LISTA_AREA_FUNCIONAL_ACTIVA_SUCCESS = 'LISTA_AREA_FUNCIONAL_ACTIVA_SUCCESS';
export const LISTA_AREA_FUNCIONAL_ACTIVA_FAILURE = 'LISTA_AREA_FUNCIONAL_ACTIVA_FAILURE';

export const LISTA_AREA_FUNCIONAL_ACTIVA_EX_REQUEST = 'LISTA_AREA_FUNCIONAL_ACTIVA_EX_REQUEST';
export const LISTA_AREA_FUNCIONAL_ACTIVA_EX_SUCCESS = 'LISTA_AREA_FUNCIONAL_ACTIVA_EX_SUCCESS';
export const LISTA_AREA_FUNCIONAL_ACTIVA_EX_FAILURE = 'LISTA_AREA_FUNCIONAL_ACTIVA_EX_FAILURE';

export const LISTA_UNIDAD_FUNCIONAL_REQUEST = 'LISTA_UNIDAD_FUNCIONAL_REQUEST';
export const LISTA_UNIDAD_FUNCIONAL_SUCCESS = 'LISTA_UNIDAD_FUNCIONAL_SUCCESS';
export const LISTA_UNIDAD_FUNCIONAL_FAILURE = 'LISTA_UNIDAD_FUNCIONAL_FAILURE';

export const LISTA_INTERIOR_FUNCIONAL_REQUEST = 'LISTA_INTERIOR_FUNCIONAL_REQUEST';
export const LISTA_INTERIOR_FUNCIONAL_SUCCESS = 'LISTA_INTERIOR_FUNCIONAL_SUCCESS';
export const LISTA_INTERIOR_FUNCIONAL_FAILURE = 'LISTA_INTERIOR_FUNCIONAL_FAILURE';

export const LISTA_SUBINTERIOR_FUNCIONAL_REQUEST = 'LISTA_SUBINTERIOR_FUNCIONAL_REQUEST';
export const LISTA_SUBINTERIOR_FUNCIONAL_SUCCESS = 'LISTA_SUBINTERIOR_FUNCIONAL_SUCCESS';
export const LISTA_SUBINTERIOR_FUNCIONAL_FAILURE = 'LISTA_SUBINTERIOR_FUNCIONAL_FAILURE';

export const LISTA_UNIDAD_FUNCIONAL_EX_REQUEST = 'LISTA_UNIDAD_FUNCIONAL_EX_REQUEST';
export const LISTA_UNIDAD_FUNCIONAL_EX_SUCCESS = 'LISTA_UNIDAD_FUNCIONAL_EX_SUCCESS';
export const LISTA_UNIDAD_FUNCIONAL_EX_FAILURE = 'LISTA_UNIDAD_FUNCIONAL_EX_FAILURE';

export const LISTA_INTERIOR_FUNCIONAL_EX_REQUEST = 'LISTA_INTERIOR_FUNCIONAL_EX_REQUEST';
export const LISTA_INTERIOR_FUNCIONAL_EX_SUCCESS = 'LISTA_INTERIOR_FUNCIONAL_EX_SUCCESS';
export const LISTA_INTERIOR_FUNCIONAL_EX_FAILURE = 'LISTA_INTERIOR_FUNCIONAL_EX_FAILURE';

export const LISTA_SUBINTERIOR_FUNCIONAL_EX_REQUEST = 'LISTA_SUBINTERIOR_FUNCIONAL_EX_REQUEST';
export const LISTA_SUBINTERIOR_FUNCIONAL_EX_SUCCESS = 'LISTA_SUBINTERIOR_FUNCIONAL_EX_SUCCESS';
export const LISTA_SUBINTERIOR_FUNCIONAL_EX_FAILURE = 'LISTA_SUBINTERIOR_FUNCIONAL_EX_FAILURE';

export const LISTA_USUARIOS_ACTIVOS_REQUEST = 'LISTA_USUARIOS_ACTIVOS_REQUEST';
export const LISTA_USUARIOS_ACTIVOS_SUCCESS = 'LISTA_USUARIOS_ACTIVOS_SUCCESS';
export const LISTA_USUARIOS_ACTIVOS_FAILURE = 'LISTA_USUARIOS_ACTIVOS_FAILURE';

export const GET_ASIGNACION_PDF_REQUEST = "GET_ASIGNACION_PDF_REQUEST";
export const GET_ASIGNACION_PDF_SUCCESS = "GET_ASIGNACION_PDF_SUCCESS";
export const GET_ASIGNACION_PDF_FAILURE = "GET_ASIGNACION_PDF_FAILURE";

export const SHOW_MODAL_ASIGNACION_ACTIVOS = 'SHOW_MODAL_ASIGNACION_ACTIVOS';

//RANGOS DE RENOVACIÓN DE ACTIVO FIJO
export const LISTA_RANGOS_RENOVACION_ACTIVOS_REQUEST = 'LISTA_RANGOS_RENOVACION_ACTIVOS_REQUEST';
export const LISTA_RANGOS_RENOVACION_ACTIVOS_SUCCESS = 'LISTA_RANGOS_RENOVACION_ACTIVOS_SUCCESS';
export const LISTA_RANGOS_RENOVACION_ACTIVOS_FAILURE = 'LISTA_RANGOS_RENOVACION_ACTIVOS_FAILURE';

export const RANGOS_RENOVACION_ACTIVO_BUSQUEDA_REQUEST = 'RANGOS_RENOVACION_ACTIVO_BUSQUEDA_REQUEST';
export const RANGOS_RENOVACION_ACTIVO_BUSQUEDA_SUCCESS = 'RANGOS_RENOVACION_ACTIVO_BUSQUEDA_SUCCESS';
export const RANGOS_RENOVACION_ACTIVO_BUSQUEDA_FAILURE = 'RANGOS_RENOVACION_ACTIVO_BUSQUEDA_FAILURE';

export const NUEVO_RANGO_RENOVACION_ACTIVO_REQUEST = 'NUEVO_RANGO_RENOVACION_ACTIVO_REQUEST';
export const NUEVO_RANGO_RENOVACION_ACTIVO_SUCCESS = 'NUEVO_RANGO_RENOVACION_ACTIVO_SUCCESS';
export const NUEVO_RANGO_RENOVACION_ACTIVO_FAILURE = 'NUEVO_RANGO_RENOVACION_ACTIVO_FAILURE';

export const EDITAR_RANGO_RENOVACION_ACTIVO_REQUEST = 'EDITAR_RANGO_RENOVACION_ACTIVO_REQUEST';
export const EDITAR_RANGO_RENOVACION_ACTIVO_SUCCESS = 'EDITAR_RANGO_RENOVACION_ACTIVO_SUCCESS';
export const EDITAR_RANGO_RENOVACION_ACTIVO_FAILURE = 'EDITAR_RANGO_RENOVACION_ACTIVO_FAILURE';

export const ESTADO_RANGO_RENOVACION_ACTIVO_REQUEST = 'ESTADO_RANGO_RENOVACION_ACTIVO_REQUEST';
export const ESTADO_RANGO_RENOVACION_ACTIVO_SUCCESS = 'ESTADO_RANGO_RENOVACION_ACTIVO_SUCCESS';
export const ESTADO_RANGO_RENOVACION_ACTIVO_FAILURE = 'ESTADO_RANGO_RENOVACION_ACTIVO_FAILURE';

export const SHOW_MODAL_NUEVO_RANGO_RENOVACION_ACTIVO = 'SHOW_MODAL_NUEVO_RANGO_RENOVACION_ACTIVO';
export const SHOW_MODAL_EDITAR_RANGO_RENOVACION_ACTIVO = 'SHOW_MODAL_EDITAR_RANGO_RENOVACION_ACTIVO';

export const EXPORTAR_RANGO_RENOVACION_ACTIVO_EXCEL_REQUEST = 'EXPORTAR_RANGO_RENOVACION_ACTIVO_EXCEL_REQUEST';
export const EXPORTAR_RANGO_RENOVACION_ACTIVO_EXCEL_SUCCESS = 'EXPORTAR_RANGO_RENOVACION_ACTIVO_EXCEL_SUCCESS';
export const EXPORTAR_RANGO_RENOVACION_ACTIVO_EXCEL_FAILURE = 'EXPORTAR_RANGO_RENOVACION_ACTIVO_EXCEL_FAILURE';

//CATÁLOGO DEL ESTATUS DEL ACTIVO
export const LISTA_ESTATUS_ACTIVOS_REQUEST = 'LISTA_ESTATUS_ACTIVOS_REQUEST';
export const LISTA_ESTATUS_ACTIVOS_SUCCESS = 'LISTA_ESTATUS_ACTIVOS_SUCCESS';
export const LISTA_ESTATUS_ACTIVOS_FAILURE = 'LISTA_ESTATUS_ACTIVOS_FAILURE';

export const ESTATUS_ACTIVO_BUSQUEDA_REQUEST = 'ESTATUS_ACTIVO_BUSQUEDA_REQUEST';
export const ESTATUS_ACTIVO_BUSQUEDA_SUCCESS = 'ESTATUS_ACTIVO_BUSQUEDA_SUCCESS';
export const ESTATUS_ACTIVO_BUSQUEDA_FAILURE = 'ESTATUS_ACTIVO_BUSQUEDA_FAILURE';

export const NUEVO_ESTATUS_ACTIVO_REQUEST = 'NUEVO_ESTATUS_ACTIVO_REQUEST';
export const NUEVO_ESTATUS_ACTIVO_SUCCESS = 'NUEVO_ESTATUS_ACTIVO_SUCCESS';
export const NUEVO_ESTATUS_ACTIVO_FAILURE = 'NUEVO_ESTATUS_ACTIVO_FAILURE';

export const EDITAR_ESTATUS_ACTIVO_REQUEST = 'EDITAR_ESTATUS_ACTIVO_REQUEST';
export const EDITAR_ESTATUS_ACTIVO_SUCCESS = 'EDITAR_ESTATUS_ACTIVO_SUCCESS';
export const EDITAR_ESTATUS_ACTIVO_FAILURE = 'EDITAR_ESTATUS_ACTIVO_FAILURE';

export const ESTADO_ESTATUS_ACTIVO_REQUEST = 'ESTADO_ESTATUS_ACTIVO_REQUEST';
export const ESTADO_ESTATUS_ACTIVO_SUCCESS = 'ESTADO_ESTATUS_ACTIVO_SUCCESS';
export const ESTADO_ESTATUS_ACTIVO_FAILURE = 'ESTADO_ESTATUS_ACTIVO_FAILURE';

export const SHOW_MODAL_NUEVO_ESTATUS_ACTIVO = 'SHOW_MODAL_NUEVO_ESTATUS_ACTIVO';
export const SHOW_MODAL_EDITAR_ESTATUS_ACTIVO = 'SHOW_MODAL_EDITAR_ESTATUS_ACTIVO';

export const GET_ARCHIVOS_ESPERA_REQUEST = 'GET_ARCHIVOS_ESPERA_REQUEST';
export const GET_ARCHIVOS_ESPERA_SUCCESS = 'GET_ARCHIVOS_ESPERA_SUCCESS';
export const GET_ARCHIVOS_ESPERA_FAILURE = 'GET_ARCHIVOS_ESPERA_FAILURE';

export const SUBIR_ARCHIVO_ACTIVO_REQUEST = 'SUBIR_ARCHIVO_ACTIVO_REQUEST';
export const SUBIR_ARCHIVO_ACTIVO_SUCCESS = 'SUBIR_ARCHIVO_ACTIVO_SUCCESS';
export const SUBIR_ARCHIVO_ACTIVO_FAILURE = 'SUBIR_ARCHIVO_ACTIVO_FAILURE';

export const ELIMINAR_ARCHIVO_ACTIVO_REQUEST = 'ELIMINAR_ARCHIVO_ACTIVO_REQUEST';
export const ELIMINAR_ARCHIVO_ACTIVO_SUCCESS = 'ELIMINAR_ARCHIVO_ACTIVO_SUCCESS';
export const ELIMINAR_ARCHIVO_ACTIVO_FAILURE = 'ELIMINAR_ARCHIVO_ACTIVO_FAILURE';

export const EXPORTAR_ERRORES_ARCHIVO_REQUEST = 'EXPORTAR_ERRORES_ARCHIVO_REQUEST';
export const EXPORTAR_ERRORES_ARCHIVO_SUCCESS = 'EXPORTAR_ERRORES_ARCHIVO_SUCCESS';
export const EXPORTAR_ERRORES_ARCHIVO_FAILURE = 'EXPORTAR_ERRORES_ARCHIVO_FAILURE';

export const PROCESAR_ARCHIVO_REQUEST = 'PROCESAR_ARCHIVO_REQUEST';
export const PROCESAR_ARCHIVO_SUCCESS = 'PROCESAR_ARCHIVO_SUCCESS';
export const PROCESAR_ARCHIVO_FAILURE = 'PROCESAR_ARCHIVO_FAILURE';

export const EXPORTAR_ESTATUS_ACTIVO_EXCEL_REQUEST = 'EXPORTAR_ESTATUS_ACTIVO_EXCEL_REQUEST';
export const EXPORTAR_ESTATUS_ACTIVO_EXCEL_SUCCESS = 'EXPORTAR_ESTATUS_ACTIVO_EXCEL_SUCCESS';
export const EXPORTAR_ESTATUS_ACTIVO_EXCEL_FAILURE = 'EXPORTAR_ESTATUS_ACTIVO_EXCEL_FAILURE';

// ACTIONS MOTIVOS DE SALIDA
export const LISTA_MOTIVOS_DE_SALIDA_BUSQUEDA_REQUEST = "LISTA_MOTIVOS_DE_SALIDA_BUSQUEDA_REQUEST"
export const LISTA_MOTIVOS_DE_SALIDA_BUSQUEDA_SUCCESS = "LISTA_MOTIVOS_DE_SALIDA_BUSQUEDA_SUCCESS"
export const LISTA_MOTIVOS_DE_SALIDA_BUSQUEDA_FAILURE = "LISTA_MOTIVOS_DE_SALIDA_BUSQUEDA_FAILURE"

export const ESTADO_MOTIVOS_DE_SALIDA_REQUEST = "ESTADO_MOTIVOS_DE_SALIDA_REQUEST"
export const ESTADO_MOTIVOS_DE_SALIDA_SUCCESS = "ESTADO_MOTIVOS_DE_SALIDA_SUCCESS"
export const ESTADO_MOTIVOS_DE_SALIDA_FAILURE = "ESTADO_MOTIVOS_DE_SALIDA_FAILURE"

export const SHOW_MODAL_NUEVO_MOTIVOS_DE_SALIDA = "SHOW_MODAL_NUEVO_MOTIVOS_DE_SALIDA"
export const SHOW_MODAL_EDITAR_MOTIVOS_DE_SALIDA = "SHOW_MODAL_EDITAR_MOTIVOS_DE_SALIDA"

export const NUEVO_MOTIVOS_DE_SALIDA_REQUEST = "NUEVO_MOTIVOS_DE_SALIDA_REQUEST"
export const NUEVO_MOTIVOS_DE_SALIDA_SUCCESS = "NUEVO_MOTIVOS_DE_SALIDA_SUCCESS"
export const NUEVO_MOTIVOS_DE_SALIDA_FAILURE = "NUEVO_MOTIVOS_DE_SALIDA_FAILURE"

export const EDITAR_MOTIVOS_DE_SALIDA_REQUEST = "EDITAR_MOTIVOS_DE_SALIDA_REQUEST"
export const EDITAR_MOTIVOS_DE_SALIDA_SUCCESS = "EDITAR_MOTIVOS_DE_SALIDA_SUCCESS"
export const EDITAR_MOTIVOS_DE_SALIDA_FAILURE = "EDITAR_MOTIVOS_DE_SALIDA_FAILURE"

export const MOTIVOS_DE_SALIDA_ACTIVOS_REQUEST = 'MOTIVOS_DE_SALIDA_ACTIVOS_REQUEST';
export const MOTIVOS_DE_SALIDA_ACTIVOS_SUCCESS = 'MOTIVOS_DE_SALIDA_ACTIVOS_SUCCESS';
export const MOTIVOS_DE_SALIDA_ACTIVOS_FAILURE = 'MOTIVOS_DE_SALIDA_ACTIVOS_FAILURE';

export const EXPORTAR_MOTIVOS_DE_SALIDA_ACTIVOS_EXCEL_REQUEST = 'EXPORTAR_MOTIVOS_DE_SALIDA_ACTIVOS_EXCEL_REQUEST';
export const EXPORTAR_MOTIVOS_DE_SALIDA_ACTIVOS_EXCEL_SUCCESS = 'EXPORTAR_MOTIVOS_DE_SALIDA_ACTIVOS_EXCEL_SUCCESS';
export const EXPORTAR_MOTIVOS_DE_SALIDA_ACTIVOS_EXCEL_FAILURE = 'EXPORTAR_MOTIVOS_DE_SALIDA_ACTIVOS_EXCEL_FAILURE';

// Salida de Bienes

export const SHOW_MODAL_SALIDA_BIENES = 'SHOW_MODAL_SALIDA_BIENES';

export const NUEVA_SOLICITUD_SALIDA_BIENES_REQUEST = 'NUEVA_SOLICITUD_SALIDA_BIENES_REQUEST';
export const NUEVA_SOLICITUD_SALIDA_BIENES_SUCCESS = 'NUEVA_SOLICITUD_SALIDA_BIENES_SUCCESS';
export const NUEVA_SOLICITUD_SALIDA_BIENES_FAILURE = 'NUEVA_SOLICITUD_SALIDA_BIENES_FAILURE';

export const CAMBIAR_VISTA_SALIDA_BIENES = 'CAMBIAR_VISTA_SALIDA_BIENES';

export const GENERAR_FOLIO_SALIDA_REQUEST = 'GENERAR_FOLIO_SALIDA_REQUEST';
export const GENERAR_FOLIO_SALIDA_SUCCESS = 'GENERAR_FOLIO_SALIDA_SUCCESS';
export const GENERAR_FOLIO_SALIDA_FAILURE = 'GENERAR_FOLIO_SALIDA_FAILURE';

export const LISTA_SALIDA_BIENES_BUSQUEDA_REQUEST = 'LISTA_SALIDA_BIENES_BUSQUEDA_REQUEST';
export const LISTA_SALIDA_BIENES_BUSQUEDA_SUCCESS = 'LISTA_SALIDA_BIENES_BUSQUEDA_SUCCESS';
export const LISTA_SALIDA_BIENES_BUSQUEDA_FAILURE = 'LISTA_SALIDA_BIENES_BUSQUEDA_FAILURE';

export const ACTUALIZAR_TABLA_SALIDA = 'ACTUALIZAR_TABLA_SALIDA';

export const LISTA_ACTIVOS_OPERACION_SERVICIO_REQUEST = 'LISTA_ACTIVOS_OPERACION_SERVICIO_REQUEST';
export const LISTA_ACTIVOS_OPERACION_SERVICIO_SUCCESS = 'LISTA_ACTIVOS_OPERACION_SERVICIO_SUCCESS';
export const LISTA_ACTIVOS_OPERACION_SERVICIO_FAILURE = 'LISTA_ACTIVOS_OPERACION_SERVICIO_FAILURE';

//ACTUALIZACION TECNOLOGICA ACTIVO 
export const ACTUALIZACION_ACTIVO_BUSQUEDA_REQUEST = 'ACTUALIZACION_ACTIVO_BUSQUEDA_REQUEST';
export const ACTUALIZACION_ACTIVO_BUSQUEDA_SUCCESS = 'ACTUALIZACION_ACTIVO_BUSQUEDA_SUCCESS';
export const ACTUALIZACION_ACTIVO_BUSQUEDA_FAILURE = 'ACTUALIZACION_ACTIVO_BUSQUEDA_FAILURE';

export const ACTUALIZACION_ACTIVO_DESCARGA_REQUEST = 'ACTUALIZACION_ACTIVO_DESCARGA_REQUEST';
export const ACTUALIZACION_ACTIVO_DESCARGA_SUCCESS = 'ACTUALIZACION_ACTIVO_DESCARGA_SUCCESS';
export const ACTUALIZACION_ACTIVO_DESCARGA_FAILURE = 'ACTUALIZACION_ACTIVO_DESCARGA_FAILURE';

export const SHOW_MODAL_ACTUALIZACION_ACTIVO_DETALLE = 'SHOW_MODAL_ACTUALIZACION_ACTIVO_DETALLE';

// REINGRESO DE ACTIVOS
export const REINGRESO_ACTIVOS_BUSQUEDA_REQUEST = 'REINGRESO_ACTIVOS_BUSQUEDA_REQUEST';
export const REINGRESO_ACTIVOS_BUSQUEDA_SUCCESS = 'REINGRESO_ACTIVOS_BUSQUEDA_SUCCESS';
export const REINGRESO_ACTIVOS_BUSQUEDA_FAILURE = 'REINGRESO_ACTIVOS_BUSQUEDA_FAILURE';
// Lista de folios activos para reingreso de activos
export const REINGRESO_ACTIVOS_LISTA_ACT_REQUEST = 'REINGRESO_ACTIVOS_LISTA_ACT_REQUEST';
export const REINGRESO_ACTIVOS_LISTA_ACT_SUCCESS = 'REINGRESO_ACTIVOS_LISTA_ACT_SUCCESS';
export const REINGRESO_ACTIVOS_LISTA_ACT_FAILURE = 'REINGRESO_ACTIVOS_LISTA_ACT_FAILURE';

export const NUEVO_REINGRESO_ACTIVO_REQUEST = 'NUEVO_REINGRESO_ACTIVO_REQUEST';
export const NUEVO_REINGRESO_ACTIVO_SUCCESS = 'NUEVO_REINGRESO_ACTIVO_SUCCESS';
export const NUEVO_REINGRESO_ACTIVO_FAILURE = 'NUEVO_REINGRESO_ACTIVO_FAILURE';

export const SHOW_MODAL_NUEVO_REINGRESO = 'SHOW_MODAL_NUEVO_REINGRESO';

// Catálogo de Almacenes
export const ALMACEN_BUSQUEDA_REQUEST = 'ALMACEN_BUSQUEDA_REQUEST';
export const ALMACEN_BUSQUEDA_SUCCESS = 'ALMACEN_BUSQUEDA_SUCCESS';
export const ALMACEN_BUSQUEDA_FAILURE = 'ALMACEN_BUSQUEDA_FAILURE';

export const NUEVO_ALMACEN_REQUEST = 'NUEVO_ALMACEN_REQUEST';
export const NUEVO_ALMACEN_SUCCESS = 'NUEVO_ALMACEN_SUCCESS';
export const NUEVO_ALMACEN_FAILURE = 'NUEVO_ALMACEN_FAILURE';

export const EDITAR_ALMACEN_REQUEST = 'EDITAR_ALMACEN_REQUEST';
export const EDITAR_ALMACEN_SUCCESS = 'EDITAR_ALMACEN_SUCCESS';
export const EDITAR_ALMACEN_FAILURE = 'EDITAR_ALMACEN_FAILURE';

export const ESTADO_ALMACEN_REQUEST = 'ESTADO_ALMACEN_REQUEST';
export const ESTADO_ALMACEN_SUCCESS = 'ESTADO_ALMACEN_SUCCESS';
export const ESTADO_ALMACEN_FAILURE = 'ESTADO_ALMACEN_FAILURE';

export const SHOW_MODAL_NUEVO_ALMACEN = 'SHOW_MODAL_NUEVO_ALMACEN';
export const SHOW_MODAL_EDITAR_ALMACEN = 'SHOW_MODAL_EDITAR_ALMACEN';

export const ALMACENES_ACTIVOS_BUSQUEDA_REQUEST = 'ALMACENES_ACTIVOS_BUSQUEDA_REQUEST';
export const ALMACENES_ACTIVOS_BUSQUEDA_FAILURE = 'ALMACENES_ACTIVOS_BUSQUEDA_FAILURE';
export const ALMACENES_ACTIVOS_BUSQUEDA_SUCCESS = 'ALMACENES_ACTIVOS_BUSQUEDA_SUCCESS';

export const EXPORTAR_ALMACENES_EXCEL_REQUEST = 'EXPORTAR_ALMACENES_EXCEL_REQUEST';
export const EXPORTAR_ALMACENES_EXCEL_SUCCESS = 'EXPORTAR_ALMACENES_EXCEL_SUCCESS';
export const EXPORTAR_ALMACENES_EXCEL_FAILURE = 'EXPORTAR_ALMACENES_EXCEL_FAILURE';

// Requisición Directa
// export const GET_ARCHIVO_REQUISICION_ESPERA_REQUEST = 'GET_ARCHIVO_REQUISICION_ESPERA_REQUEST';
// export const GET_ARCHIVO_REQUISICION_ESPERA_SUCCESS = 'GET_ARCHIVO_REQUISICION_ESPERA_SUCCESS';
// export const GET_ARCHIVO_REQUISICION_ESPERA_FAILURE = 'GET_ARCHIVO_REQUISICION_ESPERA_FAILURE';

export const SUBIR_ARCHIVO_REQUISICION_ACTIVO_REQUEST = 'SUBIR_ARCHIVO_REQUISICION_ACTIVO_REQUEST';
export const SUBIR_ARCHIVO_REQUISICION_ACTIVO_SUCCESS = 'SUBIR_ARCHIVO_REQUISICION_ACTIVO_SUCCESS';
export const SUBIR_ARCHIVO_REQUISICION_ACTIVO_FAILURE = 'SUBIR_ARCHIVO_REQUISICION_ACTIVO_FAILURE';

// export const ELIMINAR_ARCHIVO_REQUISICION_ACTIVO_REQUEST = 'ELIMINAR_ARCHIVO_REQUISICION_ACTIVO_REQUEST';
// export const ELIMINAR_ARCHIVO_REQUISICION_ACTIVO_SUCCESS = 'ELIMINAR_ARCHIVO_REQUISICION_ACTIVO_SUCCESS';
// export const ELIMINAR_ARCHIVO_REQUISICION_ACTIVO_FAILURE = 'ELIMINAR_ARCHIVO_REQUISICION_ACTIVO_FAILURE';

// export const EXPORTAR_ERRORES_ARCHIVO_REQUISICION_REQUEST = 'EXPORTAR_ERRORES_ARCHIVO_REQUISICION_REQUEST';
// export const EXPORTAR_ERRORES_ARCHIVO_REQUISICION_SUCCESS = 'EXPORTAR_ERRORES_ARCHIVO_REQUISICION_SUCCESS';
// export const EXPORTAR_ERRORES_ARCHIVO_REQUISICION_FAILURE = 'EXPORTAR_ERRORES_ARCHIVO_REQUISICION_FAILURE';

// export const PROCESAR_ARCHIVO_REQUISICION_REQUEST = 'PROCESAR_ARCHIVO_REQUISICION_REQUEST';
// export const PROCESAR_ARCHIVO_REQUISICION_SUCCESS = 'PROCESAR_ARCHIVO_REQUISICION_SUCCESS';
// export const PROCESAR_ARCHIVO_REQUISICION_FAILURE = 'PROCESAR_ARCHIVO_REQUISICION_FAILURE';

export const FOLIO_REQUISICION_DIRECTA_REQUEST = 'FOLIO_REQUISICION_DIRECTA_REQUEST';
export const FOLIO_REQUISICION_DIRECTA_SUCCESS = 'FOLIO_REQUISICION_DIRECTA_SUCCESS';
export const FOLIO_REQUISICION_DIRECTA_FAILURE = 'FOLIO_REQUISICION_DIRECTA_FAILURE';

// TIPOS DE FOLIOS DE MOVIMIENTO
export const LISTA_TIPOS_FOLIOS_ACTIVOS_REQUEST = 'LISTA_TIPOS_FOLIOS_ACTIVOS_REQUEST';
export const LISTA_TIPOS_FOLIOS_ACTIVOS_SUCCESS = 'LISTA_TIPOS_FOLIOS_ACTIVOS_SUCCESS';
export const LISTA_TIPOS_FOLIOS_ACTIVOS_FAILURE = 'LISTA_TIPOS_FOLIOS_ACTIVOS_FAILURE';

export const TIPOS_FOLIOS_BUSQUEDA_REQUEST = 'TIPOS_FOLIOS_BUSQUEDA_REQUEST';
export const TIPOS_FOLIOS_BUSQUEDA_SUCCESS = 'TIPOS_FOLIOS_BUSQUEDA_SUCCESS';
export const TIPOS_FOLIOS_BUSQUEDA_FAILURE = 'TIPOS_FOLIOS_BUSQUEDA_FAILURE';

export const NUEVO_TIPO_FOLIO_REQUEST = 'NUEVO_TIPO_FOLIO_REQUEST';
export const NUEVO_TIPO_FOLIO_SUCCESS = 'NUEVO_TIPO_FOLIO_SUCCESS';
export const NUEVO_TIPO_FOLIO_FAILURE = 'NUEVO_TIPO_FOLIO_FAILURE';

export const EDITAR_TIPO_FOLIO_REQUEST = 'EDITAR_TIPO_FOLIO_REQUEST';
export const EDITAR_TIPO_FOLIO_SUCCESS = 'EDITAR_TIPO_FOLIO_SUCCESS';
export const EDITAR_TIPO_FOLIO_FAILURE = 'EDITAR_TIPO_FOLIO_FAILURE';

export const ESTADO_TIPOS_FOLIOS_REQUEST = 'ESTADO_TIPOS_FOLIOS_REQUEST';
export const ESTADO_TIPOS_FOLIOS_SUCCESS = 'ESTADO_TIPOS_FOLIOS_SUCCESS';
export const ESTADO_TIPOS_FOLIOS_FAILURE = 'ESTADO_TIPOS_FOLIOS_FAILURE';

export const EXPORTAR_TIPOS_FOLIO_EXCEL_REQUEST = 'EXPORTAR_TIPOS_FOLIO_EXCEL_REQUEST';
export const EXPORTAR_TIPOS_FOLIO_EXCEL_SUCCESS = 'EXPORTAR_TIPOS_FOLIO_EXCEL_SUCCESS';
export const EXPORTAR_TIPOS_FOLIO_EXCEL_FAILURE = 'EXPORTAR_TIPOS_FOLIO_EXCEL_FAILURE';

export const SHOW_MODAL_NUEVO_TIPO_FOLIO = 'SHOW_MODAL_NUEVO_TIPO_FOLIO';
export const SHOW_MODAL_EDITAR_TIPO_FOLIO = 'SHOW_MODAL_EDITAR_TIPO_FOLIO';

export const ACTUALIZAR_TABLA_REQU_MATERIALES = 'ACTUALIZAR_TABLA_REQU_MATERIALES';
export const ACTUALIZAR_TABLA_MOVIMIENTOS = 'ACTUALIZAR_TABLA_MOVIMIENTOS';

// CATÁLOGO DE PRODUCTOS
export const LISTA_PRODUCTOS_ACTIVOS_REQUEST = 'LISTA_PRODUCTOS_ACTIVOS_REQUEST';
export const LISTA_PRODUCTOS_ACTIVOS_SUCCESS = 'LISTA_PRODUCTOS_ACTIVOS_SUCCESS';
export const LISTA_PRODUCTOS_ACTIVOS_FAILURE = 'LISTA_PRODUCTOS_ACTIVOS_FAILURE';

export const LISTA_PRODUCTOS_BUSQUEDA_REQUEST = 'LISTA_PRODUCTOS_BUSQUEDA_REQUEST';
export const LISTA_PRODUCTOS_BUSQUEDA_SUCCESS = 'LISTA_PRODUCTOS_BUSQUEDA_SUCCESS';
export const LISTA_PRODUCTOS_BUSQUEDA_FAILURE = 'LISTA_PRODUCTOS_BUSQUEDA_FAILURE';

export const LISTA_HERRAMIENTAS_BUSQUEDA_REQUEST = 'LISTA_HERRAMIENTAS_BUSQUEDA_REQUEST';
export const LISTA_HERRAMIENTAS_BUSQUEDA_SUCCESS = 'LISTA_HERRAMIENTAS_BUSQUEDA_SUCCESS';
export const LISTA_HERRAMIENTAS_BUSQUEDA_FAILURE = 'LISTA_HERRAMIENTAS_BUSQUEDA_FAILURE';

export const PRODUCTOS_BUSQUEDA_REQUEST = 'PRODUCTOS_BUSQUEDA_REQUEST';
export const PRODUCTOS_BUSQUEDA_SUCCESS = 'PRODUCTOS_BUSQUEDA_SUCCESS';
export const PRODUCTOS_BUSQUEDA_FAILURE = 'PRODUCTOS_BUSQUEDA_FAILURE';

export const NUEVO_PRODUCTO_REQUEST = 'NUEVO_PRODUCTO_REQUEST';
export const NUEVO_PRODUCTO_SUCCESS = 'NUEVO_PRODUCTO_SUCCESS';
export const NUEVO_PRODUCTO_FAILURE = 'NUEVO_PRODUCTO_FAILURE';

export const EDITAR_PRODUCTO_REQUEST = 'EDITAR_PRODUCTO_REQUEST';
export const EDITAR_PRODUCTO_SUCCESS = 'EDITAR_PRODUCTO_SUCCESS';
export const EDITAR_PRODUCTO_FAILURE = 'EDITAR_PRODUCTO_FAILURE';

export const ESTADO_PRODUCTO_REQUEST = 'ESTADO_PRODUCTO_REQUEST';
export const ESTADO_PRODUCTO_SUCCESS = 'ESTADO_PRODUCTO_SUCCESS';
export const ESTADO_PRODUCTO_FAILURE = 'ESTADO_PRODUCTO_FAILURE';

export const SHOW_MODAL_NUEVO_PRODUCTO = 'SHOW_MODAL_NUEVO_PRODUCTO';
export const SHOW_MODAL_EDITAR_PRODUCTO = 'SHOW_MODAL_EDITAR_PRODUCTO';
export const SHOW_MODAL_DETALLE_PRODUCTO = 'SHOW_MODAL_DETALLE_PRODUCTO';

export const EXPORTAR_PRODUCTOS_EXCEL_REQUEST = 'EXPORTAR_PRODUCTOS_EXCEL_REQUEST';
export const EXPORTAR_PRODUCTOS_EXCEL_SUCCESS = 'EXPORTAR_PRODUCTOS_EXCEL_SUCCESS';
export const EXPORTAR_PRODUCTOS_EXCEL_FAILURE = 'EXPORTAR_PRODUCTOS_EXCEL_FAILURE';

// MOVIMIENTOS ENTRADAS/SALIDAS ACTIONS
export const LISTA_MOVIMIENTOS_GENERAL_BUSQUEDA_REQUEST = "LISTA_MOVIMIENTOS_GENERAL_BUSQUEDA_REQUEST";
export const LISTA_MOVIMIENTOS_GENERAL_BUSQUEDA_SUCCESS = "LISTA_MOVIMIENTOS_GENERAL_BUSQUEDA_SUCCESS";
export const LISTA_MOVIMIENTOS_GENERAL_BUSQUEDA_FAILURE = "LISTA_MOVIMIENTOS_GENERAL_BUSQUEDA_FAILURE";

export const SHOW_MODAL_OPCIONES_MOVIMIENTOS_ALMACEN = "SHOW_MODAL_OPCIONES_MOVIMIENTOS_ALMACEN";
export const SHOW_MODAL_DETALLE = "SHOW_MODAL_DETALLE";

export const CANCELAR_FOLIO_REQUEST = "CANCELAR_FOLIO_REQUEST";
export const CANCELAR_FOLIO_SUCCESS = "CANCELAR_FOLIO_SUCCESS";
export const CANCELAR_FOLIO_FAILURE = "CANCELAR_FOLIO_FAILURE";

export const LISTA_DETALLE_CONSULTA_REQUEST = "LISTA_DETALLE_CONSULTA_REQUEST";
export const LISTA_DETALLE_CONSULTA_SUCCESS = "LISTA_DETALLE_CONSULTA_SUCCESS";
export const LISTA_DETALLE_CONSULTA_FAILURE = "LISTA_DETALLE_CONSULTA_FAILURE";

export const NUEVO_FOLIO_REQUEST = "NUEVO_FOLIO_REQUEST";
export const NUEVO_FOLIO_SUCCESS = "NUEVO_FOLIO_SUCCESS";
export const NUEVO_FOLIO_FAILURE = "NUEVO_FOLIO_FAILURE";

export const SHOW_MODAL_NUEVO_FOLIO = "SHOW_MODAL_NUEVO_FOLIO";

export const CAMBIAR_VISTA_MODAL_NUEVOFOLIO = "CAMBIAR_VISTA_MODAL_NUEVOFOLIO";

export const LISTA_INVENTARIO_REQUEST = "LISTA_INVENTARIO_REQUEST";
export const LISTA_INVENTARIO_SUCCESS = "LISTA_INVENTARIO_SUCCESS";
export const LISTA_INVENTARIO_FAILURE = "LISTA_INVENTARIO_FAILURE";

export const LISTA_REQUISICION_DIRECTA_REQUEST = "LISTA_REQUISICION_DIRECTA_REQUEST";
export const LISTA_REQUISICION_DIRECTA_SUCCESS = "LISTA_REQUISICION_DIRECTA_SUCCESS";
export const LISTA_REQUISICION_DIRECTA_FAILURE = "LISTA_REQUISICION_DIRECTA_FAILURE";

export const SHOW_MODAL_MODIFICAR_REGISTRO_NUEVOFOLIO = "SHOW_MODAL_MODIFICAR_REGISTRO_NUEVOFOLIO"
export const SHOW_MODAL_LISTA_SELECCIONADOS_NUEVOFOLIO = "SHOW_MODAL_LISTA_SELECCIONADOS_NUEVOFOLIO"

//ACTIONS DE LISTA INVENTARIO DE TIPO ACTIVOS
export const LISTA_INVENTARIO_TIPO_ACTIVOS_REQUEST = 'LISTA_INVENTARIO_TIPO_ACTIVOS_REQUEST';
export const LISTA_INVENTARIO_TIPO_ACTIVOS_SUCCESS = 'LISTA_INVENTARIO_TIPO_ACTIVOS_SUCCESS';
export const LISTA_INVENTARIO_TIPO_ACTIVOS_FAILURE = 'LISTA_INVENTARIO_TIPO_ACTIVOS_FAILURE';

//ACTIONS DE LISTA INVENTARIO CONSULTA
export const IMPRIMIR_FOLIO_REQUEST = "IMPRIMIR_FOLIO_REQUEST";
export const IMPRIMIR_FOLIO_SUCCESS = "IMPRIMIR_FOLIO_SUCCESS";
export const IMPRIMIR_FOLIO_FAILURE = "IMPRIMIR_FOLIO_FAILURE";

export const OBTENER_UBICACION_POR_ALMACEN_REQUEST = "OBTENER_UBICACION_POR_ALMACEN_REQUEST";
export const OBTENER_UBICACION_POR_ALMACEN_SUCCESS = "OBTENER_UBICACION_POR_ALMACEN_SUCCESS";
export const OBTENER_UBICACION_POR_ALMACEN_FAILURE = "OBTENER_UBICACION_POR_ALMACEN_FAILURE";

export const UBICACION_DESTINO_POR_ALMACEN_REQUEST = "UBICACION_DESTINO_POR_ALMACEN_REQUEST";
export const UBICACION_DESTINO_POR_ALMACEN_SUCCESS = "UBICACION_DESTINO_POR_ALMACEN_SUCCESS";
export const UBICACION_DESTINO_POR_ALMACEN_FAILURE = "UBICACION_DESTINO_POR_ALMACEN_FAILURE";

export const CERRAR_FOLIO_REQUEST = "CERRAR_FOLIO_REQUEST";
export const CERRAR_FOLIO_SUCCESS = "CERRAR_FOLIO_SUCCESS";
export const CERRAR_FOLIO_FAILURE = "CERRAR_FOLIO_FAILURE";

export const CERRAR_FOLIO_SIN_SALIDA_REQUEST = "CERRAR_FOLIO_SIN_SALIDA_REQUEST";
export const CERRAR_FOLIO_SIN_SALIDA_SUCCESS = "CERRAR_FOLIO_SIN_SALIDA_SUCCESS";
export const CERRAR_FOLIO_SIN_SALIDA_FAILURE = "CERRAR_FOLIO_SIN_SALIDA_FAILURE";

export const EDITAR_FOLIO_REQUEST = "EDITAR_FOLIO_REQUEST";
export const EDITAR_FOLIO_SUCCESS = "EDITAR_FOLIO_SUCCESS";
export const EDITAR_FOLIO_FAILURE = "EDITAR_FOLIO_FAILURE";

export const CANCELAR_PRODUCTO_REQUEST = "CANCELAR_PRODUCTO_REQUEST";
export const CANCELAR_PRODUCTO_SUCCESS = "CANCELAR_PRODUCTO_SUCCESS";
export const CANCELAR_PRODUCTO_FAILURE = "CANCELAR_PRODUCTO_FAILURE";

//Salida por Instalación ACTIONS
export const SHOW_MODAL_CREAR_INSTALACION = 'SHOW_MODAL_CREAR_INSTALACION';

export const SALIDA_INSTALACION_REQUEST = 'SALIDA_INSTALACION_REQUEST';
export const SALIDA_INSTALACION_SUCCESS = 'SALIDA_INSTALACION_SUCCESS';
export const SALIDA_INSTALACION_FAILURE = 'SALIDA_INSTALACION_FAILURE';


export const NUEVO_AGREGAR_PRODUCTOS_REQUEST = "NUEVO_AGREGAR_PRODUCTOS_REQUEST";
export const NUEVO_AGREGAR_PRODUCTOS_SUCCESS = "NUEVO_AGREGAR_PRODUCTOS_SUCCESS";
export const NUEVO_AGREGAR_PRODUCTOS_FAILURE = "NUEVO_AGREGAR_PRODUCTOS_FAILURE";

export const SHOW_MODAL_EDITAR_FOLIO = "SHOW_MODAL_EDITAR_FOLIO";
export const SHOW_MODAL_MODIFICAR_EDITARFOLIO = "SHOW_MODAL_MODIFICAR_EDITARFOLIO";
export const CAMBIAR_VISTA_MODAL_EDITARFOLIO = "CAMBIAR_VISTA_MODAL_EDITARFOLIO";

export const OBTENER_ALMACEN_POR_UBICACION_REQUEST = "OBTENER_ALMACEN_POR_UBICACION_REQUEST";
export const OBTENER_ALMACEN_POR_UBICACION_SUCCESS = "OBTENER_ALMACEN_POR_UBICACION_SUCCESS";
export const OBTENER_ALMACEN_POR_UBICACION_FAILURE = "OBTENER_ALMACEN_POR_UBICACION_FAILURE";

export const OBTENER_ALMACEN_POR_UBICACION_DESTINO_REQUEST = "OBTENER_ALMACEN_POR_UBICACION_DESTINO_REQUEST";
export const OBTENER_ALMACEN_POR_UBICACION_DESTINO_SUCCESS = "OBTENER_ALMACEN_POR_UBICACION_DESTINO_SUCCESS";
export const OBTENER_ALMACEN_POR_UBICACION_DESTINO_FAILURE = "OBTENER_ALMACEN_POR_UBICACION_DESTINO_FAILURE";

export const OBTENER_INFO_IDINVENTARIO_REQUEST = "OBTENER_INFO_IDINVENTARIO_REQUEST";
export const OBTENER_INFO_IDINVENTARIO_SUCCESS = "OBTENER_INFO_IDINVENTARIO_SUCCESS";
export const OBTENER_INFO_IDINVENTARIO_FAILURE = "OBTENER_INFO_IDINVENTARIO_FAILURE";

export const LISTA_PRODUCTOS_FILTRADO_REQUEST = "LISTA_PRODUCTOS_FILTRADO_REQUEST";
export const LISTA_PRODUCTOS_FILTRADO_SUCCESS = "LISTA_PRODUCTOS_FILTRADO_SUCCESS";
export const LISTA_PRODUCTOS_FILTRADO_FAILURE = "LISTA_PRODUCTOS_FILTRADO_FAILURE";

export const LISTA_INVENTARIO_FILTRADO_REQUEST = "LISTA_INVENTARIO_FILTRADO_REQUEST";
export const LISTA_INVENTARIO_FILTRADO_SUCCESS = "LISTA_INVENTARIO_FILTRADO_SUCCESS";
export const LISTA_INVENTARIO_FILTRADO_FAILURE = "LISTA_INVENTARIO_FILTRADO_FAILURE";

export const LISTA_REQUISICION_FILTRADO_REQUEST = "LISTA_REQUISICION_FILTRADO_REQUEST";
export const LISTA_REQUISICION_FILTRADO_SUCCESS = "LISTA_REQUISICION_FILTRADO_SUCCESS";
export const LISTA_REQUISICION_FILTRADO_FAILURE = "LISTA_REQUISICION_FILTRADO_FAILURE";

// ACTION REQUIISICION PUNTO DE REORDEN
export const REQUISICION_PUNTO_REORDEN_BUSQUEDA_REQUEST = "REQUISICION_PUNTO_REORDEN_BUSQUEDA_REQUEST";
export const REQUISICION_PUNTO_REORDEN_BUSQUEDA_SUCCESS = "REQUISICION_PUNTO_REORDEN_BUSQUEDA_SUCCESS";
export const REQUISICION_PUNTO_REORDEN_BUSQUEDA_FAILURE = "REQUISICION_PUNTO_REORDEN_BUSQUEDA_FAILURE";

export const GENERAR_REQUISICION_PUNTO_REORDEN_REQUEST = "GENERAR_REQUISICION_PUNTO_REORDEN_REQUEST";
export const GENERAR_REQUISICION_PUNTO_REORDEN_SUCCESS = "GENERAR_REQUISICION_PUNTO_REORDEN_SUCCESS";
export const GENERAR_REQUISICION_PUNTO_REORDEN_FAILURE = "GENERAR_REQUISICION_PUNTO_REORDEN_FAILURE";

export const CAMBIAR_VISTA_PUNTO_REORDEN = "CAMBIAR_VISTA_PUNTO_REORDEN";
export const SHOW_MODAL_REQUISICION_PUNTO_REORDEN = "SHOW_MODAL_REQUISICION_PUNTO_REORDEN";

export const CONSULTA_FOLIO_REQUISICION_REQUEST = "CONSULTA_FOLIO_REQUISICION_REQUEST";
export const CONSULTA_FOLIO_REQUISICION_SUCCESS = "CONSULTA_FOLIO_REQUISICION_SUCCESS";
export const CONSULTA_FOLIO_REQUISICION_FAILURE = "CONSULTA_FOLIO_REQUISICION_FAILURE";

export const SHOW_MODAL_EDITAR_PRODUCTO_REQUISICION = "SHOW_MODAL_EDITAR_PRODUCTO_REQUISICION";

export const EDITAR_PRODUCTO_REQUISICION_PUNTOREORDEN_REQUEST = "EDITAR_PRODUCTO_REQUISICION_PUNTOREORDEN_REQUEST";
export const EDITAR_PRODUCTO_REQUISICION_PUNTOREORDEN_SUCCESS = "EDITAR_PRODUCTO_REQUISICION_PUNTOREORDEN_SUCCESS";
export const EDITAR_PRODUCTO_REQUISICION_PUNTOREORDEN_FAILURE = "EDITAR_PRODUCTO_REQUISICION_PUNTOREORDEN_FAILURE";

export const CERRAR_FOLIO_REQUISICION_PUNTOREORDEN_REQUEST = "CERRAR_FOLIO_REQUISICION_PUNTOREORDEN_REQUEST";
export const CERRAR_FOLIO_REQUISICION_PUNTOREORDEN_SUCCESS = "CERRAR_FOLIO_REQUISICION_PUNTOREORDEN_SUCCESS";
export const CERRAR_FOLIO_REQUISICION_PUNTOREORDEN_FAILURE = "CERRAR_FOLIO_REQUISICION_PUNTOREORDEN_FAILURE";

export const SHOW_MODAL_CERRAR_FOLIO_REQUISICION = "SHOW_MODAL_CERRAR_FOLIO_REQUISICION";

export const DESCARGAR_FOLIO_REQUISICION_PUNTOREORDEN_REQUEST = "DESCARGAR_FOLIO_REQUISICION_PUNTOREORDEN_REQUEST";
export const DESCARGAR_FOLIO_REQUISICION_PUNTOREORDEN_SUCCESS = "DESCARGAR_FOLIO_REQUISICION_PUNTOREORDEN_SUCCESS";
export const DESCARGAR_FOLIO_REQUISICION_PUNTOREORDEN_FAILURE = "DESCARGAR_FOLIO_REQUISICION_PUNTOREORDEN_FAILURE";

export const DESCARGAR_FOLIO_REQUISICION_COMPRA_REQUEST = "DESCARGAR_FOLIO_REQUISICION_COMPRA_REQUEST";
export const DESCARGAR_FOLIO_REQUISICION_COMPRA_SUCCESS = "DESCARGAR_FOLIO_REQUISICION_COMPRA_SUCCESS";
export const DESCARGAR_FOLIO_REQUISICION_COMPRA_FAILURE = "DESCARGAR_FOLIO_REQUISICION_COMPRA_FAILURE";

export const SHOW_MODAL_DESCARGAR_FOLIO = "SHOW_MODAL_DESCARGAR_FOLIO";

export const CANCELAR_FOLIO_REQUISICION_PUNTOREORDEN_REQUEST = "CANCELAR_FOLIO_REQUISICION_PUNTOREORDEN_REQUEST";
export const CANCELAR_FOLIO_REQUISICION_PUNTOREORDEN_SUCCESS = "CANCELAR_FOLIO_REQUISICION_PUNTOREORDEN_SUCCESS";
export const CANCELAR_FOLIO_REQUISICION_PUNTOREORDEN_FAILURE = "CANCELAR_FOLIO_REQUISICION_PUNTOREORDEN_FAILURE";


// CATÁLOGO UBICACIONES DE PRODUCTOS
export const LISTA_UBICACIONES_PRODUCTOS_ACTIVOS_REQUEST = 'LISTA_UBICACIONES_PRODUCTOS_ACTIVOS_REQUEST';
export const LISTA_UBICACIONES_PRODUCTOS_ACTIVOS_SUCCESS = 'LISTA_UBICACIONES_PRODUCTOS_ACTIVOS_SUCCESS';
export const LISTA_UBICACIONES_PRODUCTOS_ACTIVOS_FAILURE = 'LISTA_UBICACIONES_PRODUCTOS_ACTIVOS_FAILURE';

export const UBICACIONES_PRODUCTOS_BUSQUEDA_REQUEST = 'UBICACIONES_PRODUCTOS_BUSQUEDA_REQUEST';
export const UBICACIONES_PRODUCTOS_BUSQUEDA_SUCCESS = 'UBICACIONES_PRODUCTOS_BUSQUEDA_SUCCESS';
export const UBICACIONES_PRODUCTOS_BUSQUEDA_FAILURE = 'UBICACIONES_PRODUCTOS_BUSQUEDA_FAILURE';

export const NUEVA_UBICACION_PRODUCTO_REQUEST = 'NUEVA_UBICACION_PRODUCTO_REQUEST';
export const NUEVA_UBICACION_PRODUCTO_SUCCESS = 'NUEVA_UBICACION_PRODUCTO_SUCCESS';
export const NUEVA_UBICACION_PRODUCTO_FAILURE = 'NUEVA_UBICACION_PRODUCTO_FAILURE';

export const EDITAR_UBICACION_PRODUCTO_REQUEST = 'EDITAR_UBICACION_PRODUCTO_REQUEST';
export const EDITAR_UBICACION_PRODUCTO_SUCCESS = 'EDITAR_UBICACION_PRODUCTO_SUCCESS';
export const EDITAR_UBICACION_PRODUCTO_FAILURE = 'EDITAR_UBICACION_PRODUCTO_FAILURE';

export const ESTADO_UBICACION_PRODUCTO_REQUEST = 'ESTADO_UBICACION_PRODUCTO_REQUEST';
export const ESTADO_UBICACION_PRODUCTO_SUCCESS = 'ESTADO_UBICACION_PRODUCTO_SUCCESS';
export const ESTADO_UBICACION_PRODUCTO_FAILURE = 'ESTADO_UBICACION_PRODUCTO_FAILURE';

export const SHOW_MODAL_NUEVA_UBICACION_PRODUCTO = 'SHOW_MODAL_NUEVA_UBICACION_PRODUCTO';
export const SHOW_MODAL_EDITAR_UBICACION_PRODUCTO = 'SHOW_MODAL_EDITAR_UBICACION_PRODUCTO';

export const EXPORTAR_UBICACIONES_PRODUCTOS_EXCEL_REQUEST = 'EXPORTAR_UBICACIONES_PRODUCTOS_EXCEL_REQUEST';
export const EXPORTAR_UBICACIONES_PRODUCTOS_EXCEL_SUCCESS = 'EXPORTAR_UBICACIONES_PRODUCTOS_EXCEL_SUCCESS';
export const EXPORTAR_UBICACIONES_PRODUCTOS_EXCEL_FAILURE = 'EXPORTAR_UBICACIONES_PRODUCTOS_EXCEL_FAILURE';

//LISTADO PARA TOMA DE INVENTARIO FISICO GENERAL
export const LISTA_PISOS_BY_ALMACEN_REQUEST = 'LISTA_PISOS_BY_ALMACEN_REQUEST';
export const LISTA_PISOS_BY_ALMACEN_SUCCESS = 'LISTA_PISOS_BY_ALMACEN_SUCCESS';
export const LISTA_PISOS_BY_ALMACEN_FAILURE = 'LISTA_PISOS_BY_ALMACEN_FAILURE';

export const LISTA_AREAS_BY_PISOS_REQUEST = 'LISTA_AREAS_BY_PISOS_REQUEST';
export const LISTA_AREAS_BY_PISOS_SUCCESS = 'LISTA_AREAS_BY_PISOS_SUCCESS';
export const LISTA_AREAS_BY_PISOS_FAILURE = 'LISTA_AREAS_BY_PISOS_FAILURE';

export const LISTA_PASILLOS_BY_AREAS_REQUEST = 'LISTA_PASILLOS_BY_AREAS_REQUEST';
export const LISTA_PASILLOS_BY_AREAS_SUCCESS = 'LISTA_PASILLOS_BY_AREAS_SUCCESS';
export const LISTA_PASILLOS_BY_AREAS_FAILURE = 'LISTA_PASILLOS_BY_AREAS_FAILURE';

export const LISTA_RACKS_BY_PASILLOS_REQUEST = 'LISTA_RACKS_BY_PASILLOS_REQUEST';
export const LISTA_RACKS_BY_PASILLOS_SUCCESS = 'LISTA_RACKS_BY_PASILLOS_SUCCESS';
export const LISTA_RACKS_BY_PASILLOS_FAILURE = 'LISTA_RACKS_BY_PASILLOS_FAILURE';

export const LISTA_INVENTARIO_FISICO_GENERAL_REQUEST = 'LISTA_INVENTARIO_FISICO_GENERAL_REQUEST';
export const LISTA_INVENTARIO_FISICO_GENERAL_SUCCESS = 'LISTA_INVENTARIO_FISICO_GENERAL_SUCCESS';
export const LISTA_INVENTARIO_FISICO_GENERAL_FAILURE = 'LISTA_INVENTARIO_FISICO_GENERAL_FAILURE';

export const LISTA_INVENTARIO_FISICO_DESCARGA_REQUEST = 'LISTA_INVENTARIO_FISICO_DESCARGA_REQUEST';
export const LISTA_INVENTARIO_FISICO_DESCARGA_SUCCESS = 'LISTA_INVENTARIO_FISICO_DESCARGA_SUCCESS';
export const LISTA_INVENTARIO_FISICO_DESCARGA_FAILURE = 'LISTA_INVENTARIO_FISICO_DESCARGA_FAILURE';

export const SHOW_MODAL_LISTA_INVENTARIO_FISICO_GENERAL = 'SHOW_MODAL_LISTA_INVENTARIO_FISICO_GENERAL';
//

export const INVENTARIO_FISICO_REQUEST = 'INVENTARIO_FISICO_REQUEST';
export const INVENTARIO_FISICO_SUCCESS = 'INVENTARIO_FISICO_SUCCESS';
export const INVENTARIO_FISICO_FAILURE = 'INVENTARIO_FISICO_FAILURE';

export const LISTA_FAMILIAS_FILTRO_SUCCESS = 'LISTA_FAMILIAS_FILTRO_SUCCESS';
export const LISTA_FAMILIAS_FILTRO_REQUEST = 'LISTA_FAMILIAS_FILTRO_REQUEST';
export const LISTA_FAMILIAS_FILTRO_FAILURE = 'LISTA_FAMILIAS_FILTRO_FAILURE';

export const LISTA_SUBFAMILIAS_FILTRO_SUCCESS = 'LISTA_SUBFAMILIAS_FILTRO_SUCCESS';
export const LISTA_SUBFAMILIAS_FILTRO_REQUEST = 'LISTA_SUBFAMILIAS_FILTRO_REQUEST';
export const LISTA_SUBFAMILIAS_FILTRO_FAILURE = 'LISTA_SUBFAMILIAS_FILTRO_FAILURE';

export const LISTA_SERVICIOS_ALMACEN_SUCCESS = 'LISTA_SERVICIOS_ALMACEN_SUCCESS';
export const LISTA_SERVICIOS_ALMACEN_REQUEST = 'LISTA_SERVICIOS_ALMACEN_REQUEST';
export const LISTA_SERVICIOS_ALMACEN_FAILURE = 'LISTA_SERVICIOS_ALMACEN_FAILURE';

export const LISTA_FAMILIAS_SERVICIO_SUCCESS = 'LISTA_FAMILIAS_SERVICIO_SUCCESS';
export const LISTA_FAMILIAS_SERVICIO_REQUEST = 'LISTA_FAMILIAS_SERVICIO_REQUEST';
export const LISTA_FAMILIAS_SERVICIO_FAILURE = 'LISTA_FAMILIAS_SERVICIO_FAILURE';

export const LISTA_SUBFAMILIAS_FAMILIA_SUCCESS = 'LISTA_SUBFAMILIAS_FAMILIA_SUCCESS';
export const LISTA_SUBFAMILIAS_FAMILIA_REQUEST = 'LISTA_SUBFAMILIAS_FAMILIA_REQUEST';
export const LISTA_SUBFAMILIAS_FAMILIA_FAILURE = 'LISTA_SUBFAMILIAS_FAMILIA_FAILURE';

//REPORTE FALTANTES Y AJUSTE INVENTARIO FISICO
export const LISTA_FALTANTES_INVENTARIO_FISICO_REQUEST = 'LISTA_FALTANTES_INVENTARIO_FISICO_REQUEST';
export const LISTA_FALTANTES_INVENTARIO_FISICO_SUCCESS = 'LISTA_FALTANTES_INVENTARIO_FISICO_SUCCESS';
export const LISTA_FALTANTES_INVENTARIO_FISICO_FAILURE = 'LISTA_FALTANTES_INVENTARIO_FISICO_FAILURE';

export const LISTA_FALTANTES_INVENTARIO_FISICO_DESCARGA_REQUEST = 'LISTA_FALTANTES_INVENTARIO_FISICO_DESCARGA_REQUEST';
export const LISTA_FALTANTES_INVENTARIO_FISICO_DESCARGA_SUCCESS = 'LISTA_FALTANTES_INVENTARIO_FISICO_DESCARGA_SUCCESS';
export const LISTA_FALTANTES_INVENTARIO_FISICO_DESCARGA_FAILURE = 'LISTA_FALTANTES_INVENTARIO_FISICO_DESCARGA_FAILURE';

export const CERRAR_FOLIO_AJUSTE_INVENTARIO_REQUEST = 'CERRAR_FOLIO_AJUSTE_INVENTARIO_REQUEST';
export const CERRAR_FOLIO_AJUSTE_INVENTARIO_SUCCESS = 'CERRAR_FOLIO_AJUSTE_INVENTARIO_SUCCESS';
export const CERRAR_FOLIO_AJUSTE_INVENTARIO_FAILURE = 'CERRAR_FOLIO_AJUSTE_INVENTARIO_FAILUTE';

//
export const SHOW_MODAL_INVENTARIO_CICLICO = 'SHOW_MODAL_INVENTARIO_CICLICO';

export const LISTA_INVENTARIO_CICLICO_SUCCESS = 'LISTA_INVENTARIO_CICLICO_SUCCESS';
export const LISTA_INVENTARIO_CICLICO_REQUEST = 'LISTA_INVENTARIO_CICLICO_REQUEST';
export const LISTA_INVENTARIO_CICLICO_FAILURE = 'LISTA_INVENTARIO_CICLICO_FAILURE';

export const LISTA_INVENTARIO_CICLICO_DESCARGA_REQUEST = 'LISTA_INVENTARIO_CICLICO_DESCARGA_REQUEST';
export const LISTA_INVENTARIO_CICLICO_DESCARGA_SUCCESS = 'LISTA_INVENTARIO_CICLICO_DESCARGA_SUCCESS';
export const LISTA_INVENTARIO_CICLICO_DESCARGA_FAILURE = 'LISTA_INVENTARIO_CICLICO_DESCARGA_FAILURE';

export const LISTA_PRODUCTOS_POR_FOLIO_REQUEST = 'LISTA_PRODUCTOS_POR_FOLIO_REQUEST';
export const LISTA_PRODUCTOS_POR_FOLIO_SUCCESS = 'LISTA_PRODUCTOS_POR_FOLIO_SUCCESS';
export const LISTA_PRODUCTOS_POR_FOLIO_FAILURE = 'LISTA_PRODUCTOS_POR_FOLIO_FAILURE';

export const SHOW_MODAL_NO_FOLIO = 'SHOW_MODAL_NO_FOLIO';

export const FOLIOS_FILTRADOS_REQUEST = 'FOLIOS_FILTRADOS_REQUEST';
export const FOLIOS_FILTRADOS_SUCCESS = 'FOLIOS_FILTRADOS_SUCCESS';
export const FOLIOS_FILTRADOS_FAILURE = 'FOLIOS_FILTRADOS_FAILURE';

export const GENERAR_FOLIO_CAPTURA_REQUEST = 'GENERAR_FOLIO_CAPTURA_REQUEST';
export const GENERAR_FOLIO_CAPTURA_SUCCESS = 'GENERAR_FOLIO_CAPTURA_SUCCESS';
export const GENERAR_FOLIO_CAPTURA_FAILURE = 'GENERAR_FOLIO_CAPTURA_FAILURE';

export const LISTA_ALMACENES_BY_FOLIO_REQUEST = 'LISTA_ALMACENES_BY_FOLIO_REQUEST';
export const LISTA_ALMACENES_BY_FOLIO_SUCCESS = 'LISTA_ALMACENES_BY_FOLIO_SUCCESS';
export const LISTA_ALMACENES_BY_FOLIO_FAILURE = 'LISTA_ALMACENES_BY_FOLIO_FAILURE';

export const LISTA_PISOS_BY_FOLIO_REQUEST = 'LISTA_PISOS_BY_FOLIO_REQUEST';
export const LISTA_PISOS_BY_FOLIO_SUCCESS = 'LISTA_PISOS_BY_FOLIO_SUCCESS';
export const LISTA_PISOS_BY_FOLIO_FAILURE = 'LISTA_PISOS_BY_FOLIO_FAILURE';

export const LISTA_AREAS_BY_FOLIO_REQUEST = 'LISTA_AREAS_BY_FOLIO_REQUEST';
export const LISTA_AREAS_BY_FOLIO_SUCCESS = 'LISTA_AREAS_BY_FOLIO_SUCCESS';
export const LISTA_AREAS_BY_FOLIO_FAILURE = 'LISTA_AREAS_BY_FOLIO_FAILURE';

export const LISTA_PASILLOS_BY_FOLIO_REQUEST = 'LISTA_PASILLOS_BY_FOLIO_REQUEST';
export const LISTA_PASILLOS_BY_FOLIO_SUCCESS = 'LISTA_PASILLOS_BY_FOLIO_SUCCESS';
export const LISTA_PASILLOS_BY_FOLIO_FAILURE = 'LISTA_PASILLOS_BY_FOLIO_FAILURE';

export const LISTA_RACKS_BY_FOLIO_REQUEST = 'LISTA_RACKS_BY_FOLIO_REQUEST';
export const LISTA_RACKS_BY_FOLIO_SUCCESS = 'LISTA_RACKS_BY_FOLIO_SUCCESS';
export const LISTA_RACKS_BY_FOLIO_FAILURE = 'LISTA_RACKS_BY_FOLIO_FAILURE';

export const LISTA_PRODUCTOS_BY_FOLIO_REQUEST = 'LISTA_PRODUCTOS_BY_FOLIO_REQUEST';
export const LISTA_PRODUCTOS_BY_FOLIO_SUCCESS = 'LISTA_PRODUCTOS_BY_FOLIO_SUCCESS';
export const LISTA_PRODUCTOS_BY_FOLIO_FAILURE = 'LISTA_PRODUCTOS_BY_FOLIO_FAILURE';

export const CAPTURAR_CONTEO_REQUEST = 'CAPTURAR_CONTEO_REQUEST';
export const CAPTURAR_CONTEO_SUCCESS = 'CAPTURAR_CONTEO_SUCCESS';
export const CAPTURAR_CONTEO_FAILURE = 'CAPTURAR_CONTEO_FAILURE';
export const CAPTURA_CONTEO = 'CAPTURA_CONTEO';

export const SHOW_MODAL_INGRESAR_FOLIO = 'SHOW_MODAL_INGRESAR_FOLIO';
export const SHOW_MODAL_INFORMACION_FOLIO = 'SHOW_MODAL_INFORMACION_FOLIO';
export const SHOW_MODAL_CAPTURA_INVENTARIO = 'SHOW_MODAL_CAPTURA_INVENTARIO';
export const SHOW_MODAL_FOLIO_CREADO = 'SHOW_MODAL_FOLIO_CREADO';

export const VALIDACION_CONTEO_BY_FOLIO_REQUEST = 'VALIDACION_CONTEO_BY_FOLIO_REQUEST';
export const VALIDACION_CONTEO_BY_FOLIO_SUCCESS = 'VALIDACION_CONTEO_BY_FOLIO_SUCCESS';
export const VALIDACION_CONTEO_BY_FOLIO_FAILURE = 'VALIDACION_CONTEO_BY_FOLIO_FAILURE';

export const VALIDACION_CONTEO_UNO = 'VALIDACION_CONTEO_UNO';
export const VALIDACION_CONTEO_DOS = 'VALIDACION_CONTEO_DOS';
export const VALIDACION_CONTEO_TRES = 'VALIDACION_CONTEO_TRES';

// Kardex
export const KARDEX_PRODUCTOS_EXISTENETES_REQUEST = 'KARDEX_PRODUCTOS_EXISTENETES_REQUEST';
export const KARDEX_PRODUCTOS_EXISTENETES_SUCCESS = 'KARDEX_PRODUCTOS_EXISTENETES_SUCCESS';
export const KARDEX_PRODUCTOS_EXISTENETES_FAILURE = 'KARDEX_PRODUCTOS_EXISTENETES_FAILURE';

export const ALMACENES_BY_PRODUCTO_REQUEST = 'ALMACENES_BY_PRODUCTO_REQUEST';
export const ALMACENES_BY_PRODUCTO_SUCCESS = 'ALMACENES_BY_PRODUCTO_SUCCESS';
export const ALMACENES_BY_PRODUCTO_FAILURE = 'ALMACENES_BY_PRODUCTO_FAILURE';

export const KARDEX_OBTENER_PRODUCTOS_REQUEST = 'KARDEX_OBTENER_PRODUCTOS_REQUEST';
export const KARDEX_OBTENER_PRODUCTOS_SUCCESS = 'KARDEX_OBTENER_PRODUCTOS_SUCCESS';
export const KARDEX_OBTENER_PRODUCTOS_FAILURE = 'KARDEX_OBTENER_PRODUCTOS_FAILURE';

export const KARDEX_OBTENER_MOVIMIENTOS_REQUEST = 'KARDEX_OBTENER_MOVIMIENTOS_REQUEST';
export const KARDEX_OBTENER_MOVIMIENTOS_SUCCESS = 'KARDEX_OBTENER_MOVIMIENTOS_SUCCESS';
export const KARDEX_OBTENER_MOVIMIENTOS_FAILURE = 'KARDEX_OBTENER_MOVIMIENTOS_FAILURE';

export const KARDEX_REPORTE_REQUEST = 'KARDEX_REPORTE_REQUEST';
export const KARDEX_REPORTE_SUCCESS = 'KARDEX_REPORTE_SUCCESS';
export const KARDEX_REPORTE_FAILURE = 'KARDEX_REPORTE_FAILURE';

// Verificacion de Calidad
export const LISTA_FOLIOS_VERIFICACION_CALIDAD_REQUEST = "LISTA_FOLIOS_VERIFICACION_CALIDAD_REQUEST";
export const LISTA_FOLIOS_VERIFICACION_CALIDAD_SUCCESS = "LISTA_FOLIOS_VERIFICACION_CALIDAD_SUCCESS";
export const LISTA_FOLIOS_VERIFICACION_CALIDAD_FAILURE = "LISTA_FOLIOS_VERIFICACION_CALIDAD_FAILURE";

export const SHOW_MODAL_NUEVOFOLIO_VERIFICACION_CAL = "SHOW_MODAL_NUEVOFOLIO_VERIFICACION_CAL";
export const SHOW_MODAL_VERIFICARFOLIO_CAL = "SHOW_MODAL_VERIFICARFOLIO_CAL";
export const SHOW_MODAL_MODIFICAR_PRODUCTO = "SHOW_MODAL_MODIFICAR_PRODUCTO";

export const CREAR_FOLIO_VERIFICACION_CALIDAD_REQUEST = "CREAR_FOLIO_VERIFICACION_CALIDAD_REQUEST";
export const CREAR_FOLIO_VERIFICACION_CALIDAD_SUCCESS = "CREAR_FOLIO_VERIFICACION_CALIDAD_SUCCESS";
export const CREAR_FOLIO_VERIFICACION_CALIDAD_FAILURE = "CREAR_FOLIO_VERIFICACION_CALIDAD_FAILURE";

export const FOLIO_VERIFICACION_CALIDAD_REQUEST = "FOLIO_VERIFICACION_CALIDAD_REQUEST";
export const FOLIO_VERIFICACION_CALIDAD_SUCCESS = "FOLIO_VERIFICACION_CALIDAD_SUCCESS";
export const FOLIO_VERIFICACION_CALIDAD_FAILURE = "FOLIO_VERIFICACION_CALIDAD_FAILURE";

export const DETALLE_FOLIO_VERIFICACION_CALIDAD_REQUEST = "DETALLE_FOLIO_VERIFICACION_CALIDAD_REQUEST";
export const DETALLE_FOLIO_VERIFICACION_CALIDAD_SUCCESS = "DETALLE_FOLIO_VERIFICACION_CALIDAD_SUCCESS";
export const DETALLE_FOLIO_VERIFICACION_CALIDAD_FAILURE = "DETALLE_FOLIO_VERIFICACION_CALIDAD_FAILURE";

export const ACTUALIZAR_TABLA_PRODUCTOS_VERIFICACION = "ACTUALIZAR_TABLA_PRODUCTOS_VERIFICACION";

export const IMPRIMIR_PDF_VERIFICACION_CALIDAD_REQUEST = "IMPRIMIR_PDF_VERIFICACION_CALIDAD_REQUEST";
export const IMPRIMIR_PDF_VERIFICACION_CALIDAD_SUCCESS = "IMPRIMIR_PDF_VERIFICACION_CALIDAD_SUCCESS";
export const IMPRIMIR_PDF_VERIFICACION_CALIDAD_FAILURE = "IMPRIMIR_PDF_VERIFICACION_CALIDAD_FAILURE";

export const SHOW_MODAL_MODIFICAR_REGISTRO_VERIFICACION = 'SHOW_MODAL_MODIFICAR_REGISTRO_VERIFICACION';

//ACTIONS DE DESCARGAR FOLIOS PARA MOVIMIENTOS DE ALMACEN
export const VERIFICAR_SERIENUM_REQUEST = "VERIFICAR_SERIENUM_REQUEST";
export const VERIFICAR_SERIENUM_SUCCESS = "VERIFICAR_SERIENUM_SUCCESS";
export const VERIFICAR_SERIENUM_FAILURE = "VERIFICAR_SERIENUM_FAILURE";

export const DESCARGAR_LISTA_SERIES_REQUEST = "DESCARGAR_LISTA_SERIES_REQUEST";
export const DESCARGAR_LISTA_SERIES_SUCCESS = "DESCARGAR_LISTA_SERIES_SUCCESS";
export const DESCARGAR_LISTA_SERIES_FAILURE = "DESCARGAR_LISTA_SERIES_FAILURE";

export const DESCARGAR_SALIDA_INSTALACION_REQUEST = "DESCARGAR_SALIDA_INSTALACION_REQUEST";
export const DESCARGAR_SALIDA_INSTALACION_SUCCESS = "DESCARGAR_SALIDA_INSTALACION_SUCCESS";
export const DESCARGAR_SALIDA_INSTALACION_FAILURE = "DESCARGAR_SALIDA_INSTALACION_FAILURE";

// actions movimientos de herramientas
export const HERRAMIENTAS_BUSQUEDA_REQUEST = "HERRAMIENTAS_BUSQUEDA_REQUEST";
export const HERRAMIENTAS_BUSQUEDA_SUCCESS = "HERRAMIENTAS_BUSQUEDA_SUCCESS";
export const HERRAMIENTAS_BUSQUEDA_FAILURE = "HERRAMIENTAS_BUSQUEDA_FAILURE";

export const PRESTAMO_HERRAMIENTAS_REQUEST = "PRESTAMO_HERRAMIENTAS_REQUEST";
export const PRESTAMO_HERRAMIENTAS_SUCCESS = "PRESTAMO_HERRAMIENTAS_SUCCESS";
export const PRESTAMO_HERRAMIENTAS_FAILURE = "PRESTAMO_HERRAMIENTAS_FAILURE";

export const ACTUALIZAR_TABLA_HERRAMIENTAS = "ACTUALIZAR_TABLA_HERRAMIENTAS";
export const ACTUALIZAR_FOLIO_PRESTAMO = "ACTUALIZAR_FOLIO_PRESTAMO";
export const ACTUALIZAR_TABLA_NUMEROS_INVENTARIO_FOLIO = "ACTUALIZAR_TABLA_NUMEROS_INVENTARIO_FOLIO"

export const BAJA_HERRAMIENTAS_REQUEST = "BAJA_HERRAMIENTAS_REQUEST";
export const BAJA_HERRAMIENTAS_SUCCESS = "BAJA_HERRAMIENTAS_SUCCESS";
export const BAJA_HERRAMIENTAS_FAILURE = "BAJA_HERRAMIENTAS_FAILURE";

export const OBTENER_FOLIOS_PRESTAMOS_REQUEST = "OBTENER_FOLIOS_PRESTAMOS_REQUEST"
export const OBTENER_FOLIOS_PRESTAMOS_SUCCESS = "OBTENER_FOLIOS_PRESTAMOS_SUCCESS"
export const OBTENER_FOLIOS_PRESTAMOS_FAILURE = "OBTENER_FOLIOS_PRESTAMOS_FAILURE"

export const DEVOLUCION_HERRAMIENTAS_REQUEST = "DEVOLUCION_HERRAMIENTAS_REQUEST";
export const DEVOLUCION_HERRAMIENTAS_SUCCESS = "DEVOLUCION_HERRAMIENTAS_SUCCESS";
export const DEVOLUCION_HERRAMIENTAS_FAILURE = "DEVOLUCION_HERRAMIENTAS_FAILURE";

export const DETALLE_FOLIO_PRESTAMO_REQUEST = "DETALLE_FOLIO_PRESTAMO_REQUEST";
export const DETALLE_FOLIO_PRESTAMO_SUCCESS = "DETALLE_FOLIO_PRESTAMO_SUCCESS";
export const DETALLE_FOLIO_PRESTAMO_FAILURE = "DETALLE_FOLIO_PRESTAMO_FAILURE";

export const SHOW_MODAL_PRESTAMO_HERRAMIENTA = "SHOW_MODAL_PRESTAMO_HERRAMIENTA";
export const SHOW_MODAL_BAJA_HERRAMIENTA = "SHOW_MODAL_BAJA_HERRAMIENTA";
export const SHOW_MODAL_DEVOLUCION_HERRAMIENTA = "SHOW_MODAL_DEVOLUCION_HERRAMIENTA";
export const SHOW_MODAL_DEVOLUCION_ESTADO_HERRAMIENTA = "SHOW_MODAL_DEVOLUCION_ESTADO_HERRAMIENTA";

export const NUMEROS_INVENTARIO_PRESTADO_REQUEST = "NUMEROS_INVENTARIO_PRESTADO_REQUEST";
export const NUMEROS_INVENTARIO_PRESTADO_SUCCESS = "NUMEROS_INVENTARIO_PRESTADO_SUCCESS";
export const NUMEROS_INVENTARIO_PRESTADO_FAILURE = "NUMEROS_INVENTARIO_PRESTADO_FAILURE";

export const OBTENER_NUMEROS_INVENTARIO_FOLIO_REQUEST = "OBTENER_NUMEROS_INVENTARIO_FOLIO_REQUEST";
export const OBTENER_NUMEROS_INVENTARIO_FOLIO_SUCCESS = "OBTENER_NUMEROS_INVENTARIO_FOLIO_SUCCESS";
export const OBTENER_NUMEROS_INVENTARIO_FOLIO_FAILURE = "OBTENER_NUMEROS_INVENTARIO_FOLIO_FAILURE";

export const CREAR_NUMEROS_INVENTARIO_FOLIO_REQUEST = "CREAR_NUMEROS_INVENTARIO_FOLIO_REQUEST";
export const CREAR_NUMEROS_INVENTARIO_FOLIO_SUCCESS = "CREAR_NUMEROS_INVENTARIO_FOLIO_SUCCESS";
export const CREAR_NUMEROS_INVENTARIO_FOLIO_FAILURE = "CREAR_NUMEROS_INVENTARIO_FOLIO_FAILURE";

export const MODAL_CREAR_NUMEROS_INVENTARIO = "MODAL_CREAR_NUMEROS_INVENTARIO";

export const OBTENER_NUMEROS_INVENTARIO_DISPONIBLES_REQUEST = "OBTENER_NUMEROS_INVENTARIO_DISPONIBLES_REQUEST";
export const OBTENER_NUMEROS_INVENTARIO_DISPONIBLES_SUCCESS = "OBTENER_NUMEROS_INVENTARIO_DISPONIBLES_SUCCESS";
export const OBTENER_NUMEROS_INVENTARIO_DISPONIBLES_FAILURE = "OBTENER_NUMEROS_INVENTARIO_DISPONIBLES_FAILURE";

// Solicitud de Materiales
export const LISTA_FOLIOS_REQUISICION_MATERIALES_REQUEST = 'LISTA_FOLIOS_REQUISICION_MATERIALES_REQUEST';
export const LISTA_FOLIOS_REQUISICION_MATERIALES_SUCCESS = 'LISTA_FOLIOS_REQUISICION_MATERIALES_SUCCESS';
export const LISTA_FOLIOS_REQUISICION_MATERIALES_FAILURE = 'LISTA_FOLIOS_REQUISICION_MATERIALES_FAILURE';

export const SHOW_MODAL_NUEVO_FOLIO_REQUISICION_MATERIALES = 'SHOW_MODAL_NUEVO_FOLIO_REQUISICION_MATERIALES';

export const LISTA_INVENTARIO_REQUISICION_MATERIALES_REQUEST = 'LISTA_INVENTARIO_REQUISICION_MATERIALES_REQUEST';
export const LISTA_INVENTARIO_REQUISICION_MATERIALES_SUCCESS = 'LISTA_INVENTARIO_REQUISICION_MATERIALES_SUCCESS';
export const LISTA_INVENTARIO_REQUISICION_MATERIALES_FAILURE = 'LISTA_INVENTARIO_REQUISICION_MATERIALES_FAILURE';

export const REGISTRAR_REQUISICION_MATERIALES_REQUEST = 'REGISTRAR_REQUISICION_MATERIALES_REQUEST';
export const REGISTRAR_REQUISICION_MATERIALES_SUCCESS = 'REGISTRAR_REQUISICION_MATERIALES_SUCCESS';
export const REGISTRAR_REQUISICION_MATERIALES_FAILURE = 'REGISTRAR_REQUISICION_MATERIALES_FAILURE';

export const ACTUALIZAR_TABLA_REQUISICION_MATERIALES = 'ACTUALIZAR_TABLA_REQUISICION_MATERIALES';
export const SHOW_MODAL_MODIFICAR_REGISTRO_REQUISICION = 'SHOW_MODAL_MODIFICAR_REGISTRO_REQUISICION';

export const CAMBIAR_ESTADO_REQUISICION_REQUEST = 'CAMBIAR_ESTADO_REQUISICION_REQUEST';
export const CAMBIAR_ESTADO_REQUISICION_SUCCESS = 'CAMBIAR_ESTADO_REQUISICION_SUCCESS';
export const CAMBIAR_ESTADO_REQUISICION_FAILURE = 'CAMBIAR_ESTADO_REQUISICION_FAILURE';

export const SHOW_MODAL_EDITAR_REQUISICION = 'SHOW_MODAL_EDITAR_REQUISICION';

export const EDITAR_REQUISICION_REQUEST = 'EDITAR_REQUISICION_REQUEST';
export const EDITAR_REQUISICION_SUCCESS = 'EDITAR_REQUISICION_SUCCESS';
export const EDITAR_REQUISICION_FAILURE = 'EDITAR_REQUISICION_FAILURE';

export const SHOW_MODAL_APROBAR_REQUISICION = 'SHOW_MODAL_APROBAR_REQUISICION';

export const APROBAR_REQUISICION_REQUEST = 'APROBAR_REQUISICION_REQUEST';
export const APROBAR_REQUISICION_SUCCESS = 'APROBAR_REQUISICION_SUCCESS';
export const APROBAR_REQUISICION_FAILURE = 'APROBAR_REQUISICION_FAILURE';

export const DESCARGAR_REQUISICION_REQUEST = 'DESCARGAR_REQUISICION_REQUEST';
export const DESCARGAR_REQUISICION_SUCCESS = 'DESCARGAR_REQUISICION_SUCCESS';
export const DESCARGAR_REQUISICION_FAILURE = 'DESCARGAR_REQUISICION_FAILURE';

export const PRODUCTOS_FOLIO_REQUISICION_REQUEST = 'PRODUCTOS_FOLIO_REQUISICION_REQUEST';
export const PRODUCTOS_FOLIO_REQUISICION_SUCCESS = 'PRODUCTOS_FOLIO_REQUISICION_SUCCESS';
export const PRODUCTOS_FOLIO_REQUISICION_FAILURE = 'PRODUCTOS_FOLIO_REQUISICION_FAILURE';

export const SURTIR_PRODUCTOS_FOLIO_REQUISICION_REQUEST = 'SURTIR_PRODUCTOS_FOLIO_REQUISICION_REQUEST';
export const SURTIR_PRODUCTOS_FOLIO_REQUISICION_SUCCESS = 'SURTIR_PRODUCTOS_FOLIO_REQUISICION_SUCCESS';
export const SURTIR_PRODUCTOS_FOLIO_REQUISICION_FAILURE = 'SURTIR_PRODUCTOS_FOLIO_REQUISICION_FAILURE';

// Carga Masiva de Productos, Herramientas e Insumos.
export const SHOW_MODAL_CARGA_MASIVA_PIH = 'SHOW_MODAL_CARGA_MASIVA_PIH';

export const CARGA_MASIVA_PIH_REQUEST = 'CARGA_MASIVA_PIH_REQUEST';
export const CARGA_MASIVA_PIH_SUCCESS = 'CARGA_MASIVA_PIH_SUCCESS';
export const CARGA_MASIVA_PIH_FAILURE = 'CARGA_MASIVA_PIH_FAILURE';

export const ERRORES_CARGA_PIH_REQUEST = 'ERRORES_CARGA_PIH_REQUEST';
export const ERRORES_CARGA_PIH_SUCCESS = 'ERRORES_CARGA_PIH_SUCCESS';
export const ERRORES_CARGA_PIH_FAILURE = 'ERRORES_CARGA_PIH_FAILURE';

export const SHOW_MODAL_ERRORES_CARGA_PIH = 'SHOW_MODAL_ERRORES_CARGA_PIH';

export const GET_LIST_PROCESAMIENTO_PIH_REQUEST = 'GET_LIST_PROCESAMIENTO_PIH_REQUEST';
export const GET_LIST_PROCESAMIENTO_PIH_SUCCESS = 'GET_LIST_PROCESAMIENTO_PIH_SUCCESS';
export const GET_LIST_PROCESAMIENTO_PIH_FAILURE = 'GET_LIST_PROCESAMIENTO_PIH_FAILURE';

export const CHANGE_STATUS_IPH_REQUEST = 'CHANGE_STATUS_IPH_REQUEST';
export const CHANGE_STATUS_IPH_SUCCESS = 'CHANGE_STATUS_IPH_SUCCESS';
export const CHANGE_STATUS_IPH_FAILURE = 'CHANGE_STATUS_IPH_FAILURE';

export const CARGA_MASIVA_SERIES_REQUEST = 'CARGA_MASIVA_SERIES_REQUEST';
export const CARGA_MASIVA_SERIES_SUCCESS = 'CARGA_MASIVA_SERIES_SUCCESS';
export const CARGA_MASIVA_SERIES_FAILURE = 'CARGA_MASIVA_SERIES_FAILURE';

export const DESCARGAR_ARCHIVO_SERIES = 'DESCARGAR_ARCHIVO_SERIES';

export const CHANGE_VALUE_BTN_ADD = 'CHANGE_VALUE_BTN_ADD';
export const CHANGE_VALUE_BTN_SERIES = 'CHANGE_VALUE_BTN_SERIES';
export const CHANGE_VALUE_BTN_FOLIO = 'CHANGE_VALUE_BTN_FOLIO';

// Carga Masiva Saldos Iniciales

export const CARGA_MASIVA_ALMACEN_REQUEST = 'CARGA_MASIVA_ALMACEN_REQUEST';
export const CARGA_MASIVA_ALMACEN_SUCCESS = 'CARGA_MASIVA_ALMACEN_SUCCESS';
export const CARGA_MASIVA_ALMACEN_FAILURE = 'CARGA_MASIVA_ALMACEN_FAILURE';

export const CARGA_MASIVA_ALMACEN_SERIES_REQUEST = 'CARGA_MASIVA_ALMACEN_SERIES_REQUEST';
export const CARGA_MASIVA_ALMACEN_SERIES_SUCCESS = 'CARGA_MASIVA_ALMACEN_SERIES_SUCCESS';
export const CARGA_MASIVA_ALMACEN_SERIES_FAILURE = 'CARGA_MASIVA_ALMACEN_SERIES_FAILURE';

//Reporte de Existencias
export const LISTA_REPORTE_EXISTENCIAS_REQUEST = 'LISTA_REPORTE_EXISTENCIAS_REQUEST';
export const LISTA_REPORTE_EXISTENCIAS_SUCCESS = 'LISTA_REPORTE_EXISTENCIAS_SUCCESS';
export const LISTA_REPORTE_EXISTENCIAS_FAILURE = 'LISTA_REPORTE_EXISTENCIAS_FAILURE';

export const EXCEL_REPORTE_EXISTENCIAS_REQUEST = 'EXCEL_REPORTE_EXISTENCIAS_REQUEST';
export const EXCEL_REPORTE_EXISTENCIAS_SUCCESS = 'EXCEL_REPORTE_EXISTENCIAS_SUCCESS';
export const EXCEL_REPORTE_EXISTENCIAS_FAILURE = 'EXCEL_REPORTE_EXISTENCIAS_FAILURE';

export const PDF_REPORTE_EXISTENCIAS_REQUEST = 'PDF_REPORTE_EXISTENCIAS_REQUEST';
export const PDF_REPORTE_EXISTENCIAS_SUCCESS = 'PDF_REPORTE_EXISTENCIAS_SUCCESS';
export const PDF_REPORTE_EXISTENCIAS_FAILURE = 'PDF_REPORTE_EXISTENCIAS_FAILURE';

export const EXCEL_REPORTE_EXISTENCIAS_AGRUPADO_REQUEST = 'EXCEL_REPORTE_EXISTENCIAS_AGRUPADO_REQUEST';
export const EXCEL_REPORTE_EXISTENCIAS_AGRUPADO_SUCCESS = 'EXCEL_REPORTE_EXISTENCIAS_AGRUPADO_SUCCESS';
export const EXCEL_REPORTE_EXISTENCIAS_AGRUPADO_FAILURE = 'EXCEL_REPORTE_EXISTENCIAS_AGRUPADO_FAILURE';

export const PDF_REPORTE_EXISTENCIAS_AGRUPADO_REQUEST = 'PDF_REPORTE_EXISTENCIAS_AGRUPADO_REQUEST';
export const PDF_REPORTE_EXISTENCIAS_AGRUPADO_SUCCESS = 'PDF_REPORTE_EXISTENCIAS_AGRUPADO_SUCCESS';
export const PDF_REPORTE_EXISTENCIAS_AGRUPADO_FAILURE = 'PDF_REPORTE_EXISTENCIAS_AGRUPADO_FAILURE';

export const LISTA_REPORTE_EXISTENCIAS_AGRUPADO_REQUEST = 'LISTA_REPORTE_EXISTENCIAS_AGRUPADO_REQUEST';
export const LISTA_REPORTE_EXISTENCIAS_AGRUPADO_SUCCESS = 'LISTA_REPORTE_EXISTENCIAS_AGRUPADO_SUCCESS';
export const LISTA_REPORTE_EXISTENCIAS_AGRUPADO_FAILURE = 'LISTA_REPORTE_EXISTENCIAS_AGRUPADO_FAILURE';