import React, { Component } from 'react';
import { Form, Button, Row, Col, Spin, Select, DatePicker, InputNumber, message, Input, Divider } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class EditarFallaDisponibilidad extends Component {
    formRef = React.createRef();
    state = {
        deduccionEditado: false,
        tipoEdicion: null,
        disabledSelect: false,
        kindEdition: false,
    }
     render() {
        const { 
            fetchingEditarFallasDeDisponibilidad, 
            onEditarFallaDisponibilidad,
            limpiarFormulario, 
            onLimpiarFormulario, 
            page, 
            listaInfoProyecto, 
            fetchingListaInfoProyecto, 
            FallasDeDisponibilidadSeleccionado,
            onCambiarVista,
        } = this.props;

        if(limpiarFormulario && limpiarFormulario === 'editarFallasDisponibilidad'){
            this.formRef.current.resetFields();
            onLimpiarFormulario();
            onCambiarVista(1, []);
        }

        const onFinish = formulario => {
            const rangeValue = formulario['rangoVigencia'];
            if(rangeValue[0]){
                formulario.rangoVigencia = rangeValue[0].format('YYYY-MM-DD') + "," + rangeValue[1].format('YYYY-MM-DD');
                if(this.state.tipoEdicion === 'simple'){
                    onEditarFallaDisponibilidad(formulario, page, FallasDeDisponibilidadSeleccionado);
                }
                else{
                    if(FallasDeDisponibilidadSeleccionado.porcentajeDeduccion === formulario['porcentajeDeduccion']){
                        console.log('terminado: ', formulario);
                        this.setState({
                            formulario: formulario,
                        });
                        onEditarFallaDisponibilidad(formulario, page, FallasDeDisponibilidadSeleccionado);
                    }
                    else{
                        onCambiarVista(2, formulario);
                        this.props.onGetCategorias(formulario, FallasDeDisponibilidadSeleccionado); 
                        console.log('Pedir contraseña');
                    }
                }
                
            }
            else{
                message.warning('Favor de seleccionar una fecha de inicio de vigencia', 7);
            }
        }

        

        const changeDecimales = (value) => {
            let valueDecimal = parseInt(value);
            let campo = 'porcentajeDeduccion';
            if(this.state.tipoEdicion === 'simple'){
                this.formRef.current.setFieldsValue({[campo]: valueDecimal});
            }
            else{
                if(this.formRef.current.getFieldValue('rangoVigencia')[0]){
                    message.warning('Por motivos de seguridad, favor de seleccionar de nuevo la fecha de inicio de vigencia', 7);
                }
                if(isNaN(valueDecimal)){
                    valueDecimal = 0;
                }
                this.formRef.current.setFieldsValue({[campo]: valueDecimal, 'rangoVigencia': [null, FallasDeDisponibilidadSeleccionado.rangoVigencia[1]]});
                if(value !== parseInt(FallasDeDisponibilidadSeleccionado.porcentajeDeduccion)){
                    this.setState({
                        deduccionEditado: true,
                    });
                }
                else{
                    this.setState({
                        deduccionEditado: false,
                    });
                }
            }
        }

        const fechas = (rangeValue) => {
            if(listaInfoProyecto.fechaInicio){
                if(rangeValue[0]){
                    const fechaInicioAnterior = FallasDeDisponibilidadSeleccionado.fechaInicioVigencia.split('T')[0];
                    const diferenciaAnterior = moment(fechaInicioAnterior, 'YYYY-MM-DD').diff(moment(rangeValue[0].format('YYYY-MM-DD')), "days");
                    console.log('diferenciaAnterior');
                    console.log(diferenciaAnterior);
                    if(this.state.deduccionEditado){
                        if(diferenciaAnterior > -1){
                            message.error('No es posible seleccionar una fecha de inicio menor ni igual a la fecha de inicio anterior (' + fechaInicioAnterior.split('-').reverse().join('-') + ')', 7);
                            this.formRef.current.setFieldsValue({'rangoVigencia': [null, FallasDeDisponibilidadSeleccionado.rangoVigencia[1]]});
                        }
                    }
                }
            }
            else{
                message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                this.formRef.current.setFieldsValue({'rangoVigencia': [null, FallasDeDisponibilidadSeleccionado.rangoVigencia[1]]});
            }
        };

        const disabledRange = fecha => {
            console.log('listaInfoProyecto.fechaInicio; ', listaInfoProyecto.fechaInicio);
            return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicio;
        }
        const onSelectTipoEdicion = (value) => {
            if(value === 'simple'){
                this.state.kindEdition = false;
            }
            else{
                this.state.kindEdition = true;
            }

            this.setState({
                tipoEdicion: value,
                disabledSelect: true,
            })
        }
        
        return (
            <div>
                {FallasDeDisponibilidadSeleccionado ? (
                    <Spin spinning={fetchingEditarFallasDeDisponibilidad}>
                        <Form {...layout}
                        name="formulario"
                        initialValues={FallasDeDisponibilidadSeleccionado}
                        onFinish={onFinish}
                        
                        ref={this.formRef}
                        >
                            <Row justify='space-around'>
                                <Col span={11}>
                                    <p>Selecciona el tipo de edición</p>
                                </Col>
                                <Col span={11}>
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el tipo de edición"
                                        onChange={onSelectTipoEdicion}
                                        disabled={this.state.disabledSelect}
                                    >
                                        <Option key='simple'>Edición simple</Option>
                                        <Option key='completa'>Edición por convenio</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Divider/>
                            {this.state.tipoEdicion ?(
                            <Row justify='space-around'>
                                <Col span={24}>
                                    <Form.Item
                                        label="Categoría de Falla de Disponibilidad"
                                        name="fallaDisponibilidad"
                                        rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                    >
                                        <Input
                                        disabled={this.state.kindEdition}
                                        pattern="^^[ñíóáéú a-zA-Z0-9 ]+$"
                                        title="Categoría de fallas debe tener letras." 
                                        maxLength={30} placeholder='Categoría de fallas de disponibilidad' />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>    
                                    <Form.Item
                                    label="Descripción"
                                    name="descripcion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <TextArea maxLength={250} placeholder="Ingrese descripción" autoSize pattern="^[ñíóáéú a-zA-Z ]+$" title="Descripción sin carácteres especiales" disabled={this.state.kindEdition}/>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                    label="Porcentaje de Deducción"
                                    name="porcentajeDeduccion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <InputNumber
                                        min={1.00} 
                                        max={100.00}
                                        step={1.00} 
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                        placeholder="Ingresa el porcentaje de deducción por cateogría de falla de disponibilidad"
                                        onChange={changeDecimales}
                                        title="Número debe ser entero o fracción a dos decímales" 
                                        pattern='^[0-9]*(\.[0-9]{0,2})?%' />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="Fechas Vigencias"
                                        name="rangoVigencia"
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <RangePicker
                                            format='DD-MM-YYYY' 
                                            style={{ width: '100%' }} 
                                            placeholder={["Ingresa fecha inicio", "Fecha fin"]}
                                            onChange={fechas}
                                            loading={fetchingListaInfoProyecto}
                                            disabledDate={disabledRange}
                                            disabled={[false, true]}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col className='marginBoton'>
                                    <br/>
                                    <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Editar Categoría</Button>
                                </Col>
                            </Row>
                            ):false}
                        </Form>
                    </Spin>
                ): false}
            </div>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarFallasDeDisponibilidad: state.MecanismosDePagosReducer.fetchingEditarFallasDeDisponibilidad,
        page: state.MecanismosDePagosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        fetchingListaInfoProyecto: state.InformacionDelProyectoReducer.fetchingListaInfoProyecto,
        FallasDeDisponibilidadSeleccionado: state.MecanismosDePagosReducer.FallasDeDisponibilidadSeleccionado,
        vistaModalFallaDisponiblidad: state.MecanismosDePagosReducer.vistaModalFallaDisponiblidad,
        fallaDisponibilidadEditados: state.MecanismosDePagosReducer.fallaDisponibilidadEditados
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onEditarFallaDisponibilidad: ( formulario, page, FallasDeDisponibilidadSeleccionado ) => {
            dispatch({ type: "EDITAR_FALLA_DISPONIBILIDAD_REQUEST" , formulario: formulario, page: page, FallasDeDisponibilidadSeleccionado: FallasDeDisponibilidadSeleccionado });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onCambiarVista: (vistaModalFallaDisponiblidad, formularioDisponibilidad) => {
            dispatch({ type: 'CAMBIAR_VISTA_FALLA_DISPONIBILIDAD', vistaModalFallaDisponiblidad: vistaModalFallaDisponiblidad, formularioDisponibilidad: formularioDisponibilidad, fallaDisponibilidadEditados: []})
        },
        onGetCategorias: (formulario, FallasDeDisponibilidadSeleccionado) => {
            dispatch({ type: "LISTA_CATEGORIAS_DISPONIBILIDAD_REQUEST", formulario,  FallasDeDisponibilidadSeleccionado})
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditarFallaDisponibilidad);