import React, { Component } from 'react';
import { Form, Input, Button, Row, Col, Spin, Modal} from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';
import moment from 'moment';

const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalNuevoEstatusActivo extends Component {
    formRef = React.createRef();
   
    render() {
        const { fetchingNuevoEstatusActivo, showModalNuevoEstatusActivo, onShowModalNuevoEstatus,
            onNuevoEstatus, page, limpiarFormulario } = this.props
         
        const onFinish = formulario => {
            console.log('Success:', formulario);
            onNuevoEstatus(formulario, page);
        }

        if (limpiarFormulario && limpiarFormulario === 'crearEstatus') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo);
        };

        const handleCancel = () => {
            onShowModalNuevoEstatus();
            this.formRef.current.resetFields();
        }
        
        return (
            <Modal
                visible={showModalNuevoEstatusActivo}
                title='Nuevo Estatus del Activo'
                onCancel={handleCancel}
                footer={false}
            >
                <Spin spinning={fetchingNuevoEstatusActivo}>
                    <Form {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        
                        ref={this.formRef}
                    >
                        <Form.Item
                            label="Descripción corta"
                            name="descripcionC"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <Input
                            pattern="^[ñíóáéú a-zA-Z0-9 ]+$"
                            title="Descripción sin carácteres especiales" 
                            maxLength={100} 
                            placeholder='Descripción corta' />
                        </Form.Item>

                        <Form.Item
                            label="Descripción larga"
                            name="descripcionL"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <TextArea
                            pattern="^[ñíóáéú a-zA-Z0-9 ]+$"
                            title="Descripción sin carácteres especiales" 
                            maxLength={200} 
                            placeholder='Descripción Larga'
                            autoSize />
                        </Form.Item>
                        
                        <Row justify='space-around'>
                            <Col span={12}>
                                <Button style={{width:'100%'}} className="btnFiltrar" htmlType="submit">Crear Estatus del Activo</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>

            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingNuevoEstatusActivo: state.AlmacenesReducer.fetchingNuevoEstatusActivo,
        showModalNuevoEstatusActivo: state.AlmacenesReducer.showModalNuevoEstatusActivo,
        page: state.AlmacenesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevoEstatus: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_ESTATUS_ACTIVO', showModalNuevoEstatusActivo: false });
        },
        onNuevoEstatus: (formulario, page) => {
            dispatch({ type: 'NUEVO_ESTATUS_ACTIVO_REQUEST', formulario: formulario, page: page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoEstatusActivo);