import React from 'react';
import { Button, Form, Row, Col, Modal, Select, Tooltip, TimePicker } from 'antd';
import { connect } from "react-redux";
import { FilePdfOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option } = Select;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };

class ModalCambiarHora extends React.Component {
    formRef = React.createRef();

     componentDidMount() {
          
     }

     state = {

     }

     render() {
          const { 
               showModalCambiarHora,
               onCloseModal,
          } = this.props;

          const handleCancel = () => {
               console.log('Entra a handleCancel')
               onCloseModal();
               this.formRef.current.resetFields();
          };

          const onGuardarHora = () => {
               onCloseModal();
               this.formRef.current.resetFields();
          };
          
          return (
               <Modal
                    visible={showModalCambiarHora}
                    title={'Modificar Hora de Ejecución'}
                    onCancel={handleCancel}
                    footer={false}
                    width={'30%'}
               >
                    <Form {...layout}
                         name="formulario"
                         ref={this.formRef}
                    >
                         <Row justify='space-around'>
                              
                              <Col span={11}>
                                   <Tooltip placement="bottom" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                        <Form.Item
                                             label='Hora de ejecución'
                                             name='horaEjecucion'
                                             rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                             <TimePicker format='HH:mm' style={{ width: '100%'}}/>
                                        </Form.Item>
                                   </Tooltip>
                              </Col>

                              <Col span={11}>
                                   <br/> <br/>
                                   <Button className='btnPrueba' style={{ width: '100%' }} onClick={onGuardarHora}>
                                        Guardar
                                   </Button>
                              </Col>
                         </Row>
                    </Form>
               </Modal>
          );
     }
}

const mapStateToProps = state => {
     return {
          showModalCambiarHora: state.SolicitudDeServiciosReducer.showModalCambiarHora,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onCloseModal: () => {
               dispatch({ type: 'SHOW_MODAL_CAMBIAR_HR', showModalCambiarHora: false });
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCambiarHora);
