import React from 'react';
import {connect} from 'react-redux';
import {  Button, Row, Col, Table, Switch, Radio, Modal, Tooltip} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { FilePdfFilled, FileExcelFilled } from '@ant-design/icons';
import CrearRegistro from './RegistrosPersonalizados/ModalNuevoRegistro';

const {confirm} = Modal;
class TableSection extends React.Component {
    formRef = React.createRef();
    // componentDidMount(){ 
    //     onGetProveedores();
    // }

    state={ 
        agrupado: 'SinAgrupar',
        tipoAgrupado: '',
        page: 1,
        orden: {
            field: 'ser.nombre',
            order: 'ASC'
        }
    }

    render() {
            const {fetchingListaReporteDinamicoBitacora, listaReporteDinamicoBitacora, listaReporteDinamicoCantidadBitacora, fetchingReporteDinamicoEXCEL, fetchingReporteDinamicoPDF,  formularioReporte,listaServiciosActivosF, handlers, data, onDescargarReportePDF, onDescargarReporteExcel, onGetReporteDinamico, onDescargarReporteAgrupadoPDF, onDescargarReporteAgrupadoExcel, onGetReporteDinamicoAgrupado, listaReporteDinamicoAgrupadoBitacora, listaReporteDinamicoAgrupadoCantidadBitacora, fetchingReporteDinamicoAgrupadoBitacora, onModalNuevoRegistroReporte, fetchingReporteDinamicoAgrupadoEXCEL, fetchingReporteDinamicoAgrupadoPDF
            } = this.props;
            
            let permisosUsuario = sessionStorage.getItem('permisosUsuario');
            if(permisosUsuario[0]) {
                permisosUsuario = JSON.parse(permisosUsuario);
            }

            const {checkbox, checkArray, state, order} = data;
            const {handleTableChange, handleSteps, handleOrden} = handlers;
            
            const onHandleTableChange = (pagination, filter, orden) => {
                handleTableChange(pagination, filter, orden);
            }

            const onHandleSteps = (value) => {
                handleSteps(value);
            }
            
            const descargarReporte = (value) => {
                let _this = this;
                confirm({
                    title: '¿Deseas guardar el reporte como una vista predeterminada?',
                    content: '',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                        onModalNuevoRegistroReporte(formularioReporte, checkbox, value, _this.state.agrupado, order);
                    },
                    onCancel() {
                        if(_this.state.agrupado === 'SinAgrupar'){
                            if (value === 'pdf') {
                                onDescargarReportePDF(formularioReporte, checkbox, order); 
                            }  else { 
                                onDescargarReporteExcel(formularioReporte, checkbox, order); 
                            }
                        } else {
                            if (value === 'pdf') { 
                                onDescargarReporteAgrupadoPDF(formularioReporte, checkbox, _this.state.agrupado, order); 
                            }  else { 
                                onDescargarReporteAgrupadoExcel(formularioReporte, checkbox, _this.state.agrupado, order); 
                            }
                        }
                    },
                });
            }
            
            const columns = [
                {title: 'Tipo de bitácora',dataIndex: 'tipoBitacora',key: 'tipoBitacoraC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true}, 
                {title: 'Descripción',dataIndex: 'descripcion',key: 'descripcionC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Nombre',dataIndex: 'nombre', key: 'nombreC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Módulo',dataIndex: 'modulo',key: 'moduloC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Servicio',dataIndex: 'servicio',key: 'servicioC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Partida',dataIndex: 'partida',key: 'partidaC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Fecha',dataIndex: 'fechaTable',key: 'fechaC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Hora',dataIndex: 'hora',key: 'horaC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Asunto',dataIndex: 'asunto',key: 'asuntoC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Acción',dataIndex: 'accion',key: 'accionC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Responsable de Servicio',dataIndex: 'responsableSer',key: 'responsableSerC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Responsable de Registro',dataIndex: 'responsableReg',key: 'responsableRegC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Observaciones',dataIndex: 'observaciones',key: 'observacionesC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Conclusión',dataIndex: 'conclusiones',key: 'conclusionesC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
            ];
            
            const columnsSelected = columns.filter( field => checkArray.includes(field.key));
            const scroll = columnsSelected.length > 4 ? {x: columnsSelected.length * 250} : {x: 700}

            const onAgrupar = (value) => {
                const tipoAgrupado = value.target.value === 'servicio' ? 'Servicio' : value.target.value === 'modulo' ? 'Módulo' : value.target.value === 'tipoBitacora' ? 'Tipo bitácora' : value.target.value === 'partida' ? 'Partida' : value.target.value === 'fecha' ? 'Fecha' : value.target.value === 'hora' ? 'Hora' : value.target.value === 'responsableSer' ? 'Responsable de Servicio' : 'Responsable de Registro';
                this.setState({
                    agrupado: value.target.value,
                    tipoAgrupado
                });
                formularioReporte.tipoAgrupado = value.target.value;
                if(value.target.value !== 'SinAgrupar'){
                    console.log('Entra a != sin agrupar')
                    onGetReporteDinamicoAgrupado(formularioReporte, 1, value.target.value, checkbox, order)
                }
                else{
                    onGetReporteDinamico(formularioReporte, 1, order, checkbox)
                }
            }

            const columnsAgrupamiento = [ { title: this.state.tipoAgrupado, dataIndex: this.state.agrupado === 'fecha' ? 'nombreTable' : 'nombre', key: 'nombre' } ];

            const onChangeAgrupado = (record, pagination, filter, orden) => {
                console.log(`record, e`, record, pagination, filter, orden);
                const orderFields = { tipoBitacoraC:'tbe.tipoBitacora', descripcionC:'tbe.descripcion', nombreC:'bme.nombre', moduloC: 'me.nombre', servicioC: 'se.nombre', partidaC: 'pe.nombre', asuntoC: 'be.asunto', observacionesC: 'be.observaciones', fechaC: 'be.fecha', horaC: 'be.hora', accionC: 'be.accion', responsableSerC: 'be.responsableSer', responsableRegC: 'be.responsableReg',conclusionesC: 'be.conclusiones' }
    
                record.listaReporteDinamicoAgrupadoBitacora = listaReporteDinamicoAgrupadoBitacora;
                record.listaReporteDinamicoAgrupadoCantidadBitacora = listaReporteDinamicoAgrupadoCantidadBitacora;
                if(orden) {
                    const field = orden.columnKey ? orderFields[orden.columnKey] : 'tbe.tipoBitacora';
                    const orderField = orden.order === 'descend' ? 'DESC': 'ASC';
                    const sorter = {field: field, order: orderField}
                    this.setState({orden: sorter})
                    console.log(`sorter`, sorter)
                    return onGetReporteDinamico(formularioReporte, pagination.current, sorter, checkbox, record );
                }

                onGetReporteDinamico(formularioReporte, pagination.current, order, checkbox, record);
            };

            const ordenamientoGeneral = (value, tipo) => {
                console.log(`value`, value)
                if(tipo === 'field'){
                    handleOrden(value.target.value, order.order)
                }
                else{
                    handleOrden(order.field, value.target.value)
                }
            };

            return (
                <>
                    <Row justify='space-between' gutter={[16,16]}>
                        <Col span={3}>
                            <Row justify='space-around'>
                                <Col span={11}>
                                    <Tooltip title='Exportar archivo .pdf'>
                                        <Button className="iconSecundary" shape='circle' icon={<FilePdfFilled /> } disabled={listaReporteDinamicoCantidadBitacora <= 0 ? true : !permisosUsuario.exportarPdfReporteDinamicoRecursosHumanos} loading={fetchingReporteDinamicoPDF || fetchingReporteDinamicoAgrupadoPDF}
                                    onClick={()=> descargarReporte('pdf')} style={{marginTop: '20px' }}/>
                                    </Tooltip>
                                </Col>
                                <Col span={11}>
                                    <Tooltip title='Exportar archivo .xls'>
                                        <Button className="iconSecundary" shape='circle' icon={<FileExcelFilled /> } disabled={listaReporteDinamicoCantidadBitacora <= 0 ? true : !permisosUsuario.exportarExcelReporteDinamicoRecursosHumanos} loading={fetchingReporteDinamicoEXCEL || fetchingReporteDinamicoAgrupadoEXCEL}
                                            onClick={()=> descargarReporte('excel')} style={{marginTop: '20px' }}/>
                                    </Tooltip>
                                </Col>
                            </Row>
                         </Col>
                        <Col span={17} style={{paddingTop:'2em'}}>
                            <Radio.Group defaultValue='SinAgrupar' onChange={onAgrupar}  size="small">
                                { checkbox.moduloC ? ( <Radio.Button value="modulo">Módulo</Radio.Button>): false }
                                { checkbox.tipoBitacoraC ? ( <Radio.Button value="tipoBitacora">Tipo Bitácora</Radio.Button>): false }
                                { checkbox.servicioC ? (<Radio.Button value="servicio">Servicio</Radio.Button>): false }
                                { checkbox.partidaC ? (<Radio.Button value="partida">Partida</Radio.Button>): false }
                                { checkbox.fechaC ? (<Radio.Button value="fecha">Fecha</Radio.Button>): false }
                                { checkbox.responsableSerC ? (<Radio.Button value="responsableSer">Responsable de Servicio</Radio.Button>): false }
                                { checkbox.responsableRegC ? (<Radio.Button value="responsableReg">Responsable de Registro</Radio.Button>): false }
                                <Radio.Button value='SinAgrupar'>Sin Agrupamiento</Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Row style={{ paddingTop: '20px' }}>
                                <Col span={24}>
                                    { this.state.agrupado !== 'SinAgrupar' ? (
                                        <Table
                                            title={() => 
                                                <Row justify='space-around' gutter={[8,8]} align='middle'>
                                                    <Col span={20}>
                                                        <Radio.Group defaultValue={order.field} onChange={(e) => ordenamientoGeneral(e, 'field')}  size="small">
                                                            { checkbox.tipoBitacoraC ? (<Radio.Button value="tbe.tipoBitacora">Tipo de bitácora</Radio.Button>) : false }
                                                            { checkbox.descripcionC ? (<Radio.Button value="tbe.descripcion">Descripción</Radio.Button>) : false }
                                                            { checkbox.nombreC ? (<Radio.Button value="bme.nombre">Nombre</Radio.Button>) : false }
                                                            { checkbox.moduloC ? (<Radio.Button value="me.nombre">Módulo</Radio.Button>) : false }
                                                            { checkbox.servicioC ? (<Radio.Button value="se.nombre">Servicio</Radio.Button>) : false }
                                                            { checkbox.partidaC ? (<Radio.Button value="pe.nombre">Partida</Radio.Button>) : false }
                                                            { checkbox.fechaC ? (<Radio.Button value="be.fecha">Fecha</Radio.Button>) : false }
                                                            { checkbox.horaC ? (<Radio.Button value="be.hora">Hora</Radio.Button>) : false }
                                                            { checkbox.asuntoC ? (<Radio.Button value="be.asunto">Asunto</Radio.Button>) : false }
                                                            { checkbox.accionC ? (<Radio.Button value="be.accion">Acción</Radio.Button>) : false }
                                                            { checkbox.responsableSerC ? (<Radio.Button value="be.responsableSer">Responsable de Servicio</Radio.Button>) : false }
                                                            { checkbox.responsableRegC ? (<Radio.Button value="be.responsableReg">Responsable de Registro</Radio.Button>) : false }
                                                            { checkbox.observacionesC ? (<Radio.Button value="be.observaciones">Observaciones</Radio.Button>) : false }
                                                            { checkbox.conclusionesC ? (<Radio.Button value="be.conclusiones">Conclusión</Radio.Button>) : false }
                                                        </Radio.Group>
                                                    </Col>
                                                    <Col span={4}>
                                                        <Radio.Group defaultValue={order.order} onChange={(e) => ordenamientoGeneral(e, 'orden')} size="small">
                                                            <Radio.Button value="ASC">Ascendente</Radio.Button>
                                                            <Radio.Button value="DESC">Descendente</Radio.Button>
                                                        </Radio.Group>
                                                    </Col>
                                                </Row>
                                            }
                                            size='small'
                                            rowKey={ this.state.agrupado == 'fecha' || this.state.agrupado == 'responsableSer'|| this.state.agrupado == 'responsableReg' ? "nombre" : "id" }
                                            columns={columnsAgrupamiento}
                                            dataSource={listaReporteDinamicoAgrupadoBitacora}
                                            expandable={{expandedRowRender: record => 
                                                <Table 
                                                    rowKey="id" 
                                                    columns={columnsSelected} 
                                                    loading={fetchingListaReporteDinamicoBitacora} 
                                                    onChange={(e,u,i) => onChangeAgrupado(record, e, u, i)} 
                                                    dataSource={record.agrupados.data} 
                                                    pagination={{ total: record.agrupados.cantidad, simple: true, current: record.agrupados.page }} 
                                                    scroll={scroll}
                                                /> 
                                            } }
                                            loading={fetchingReporteDinamicoAgrupadoBitacora}
                                            locale={{ emptyText: 'Sin datos' }}
                                            onChange={onHandleTableChange}
                                            pagination={{ total: listaReporteDinamicoAgrupadoCantidadBitacora, simple: true, current: state.page }}
                                            scroll={scroll}
                                        />
                                    ):  (
                                        <Table
                                            size='small'
                                            rowKey='id'
                                            columns={columnsSelected}
                                            dataSource={listaReporteDinamicoBitacora}
                                            loading={fetchingListaReporteDinamicoBitacora}
                                            locale={{ emptyText: 'Sin datos' }}
                                            onChange={onHandleTableChange}
                                            scroll={scroll}
                                            pagination={{ total: listaReporteDinamicoCantidadBitacora, simple: true, current: state.page }}
                                        />
                                    ) }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row justify='start'>
                        <Col span={6}>
                            <Button className="btnFiltrar" onClick={() => onHandleSteps('atras')} style={{ width: '100%', marginTop: '20px' }}>Anterior</Button>
                        </Col>
                    </Row>
                    <CrearRegistro />
                </>
            );
        }
    }


const mapStateToProps = state => {
    return {
        fetchingListaReporteDinamicoBitacora: state.ConfiguracionYSeguridadReducer.fetchingListaReporteDinamicoBitacora,
        listaReporteDinamicoBitacora: state.ConfiguracionYSeguridadReducer.listaReporteDinamicoBitacora,
        listaReporteDinamicoCantidadBitacora: state.ConfiguracionYSeguridadReducer.listaReporteDinamicoCantidadBitacora,

        fetchingReporteDinamicoEXCEL: state.ConfiguracionYSeguridadReducer.fetchingReporteDinamicoEXCEL,
        fetchingReporteDinamicoPDF: state.ConfiguracionYSeguridadReducer.fetchingReporteDinamicoPDF,
        fetchingReporteDinamicoAgrupadoEXCEL: state.ConfiguracionYSeguridadReducer.fetchingReporteDinamicoAgrupadoEXCEL,
        fetchingReporteDinamicoAgrupadoPDF: state.ConfiguracionYSeguridadReducer.fetchingReporteDinamicoAgrupadoPDF,
        formularioReporte: state.ConfiguracionYSeguridadReducer.formularioReporte,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,

        listaReporteDinamicoAgrupadoBitacora: state.ConfiguracionYSeguridadReducer.listaReporteDinamicoAgrupadoBitacora,
        listaReporteDinamicoAgrupadoCantidadBitacora: state.ConfiguracionYSeguridadReducer.listaReporteDinamicoAgrupadoCantidadBitacora,
        fetchingReporteDinamicoAgrupadoBitacora: state.ConfiguracionYSeguridadReducer.fetchingReporteDinamicoAgrupadoBitacora,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onDescargarReportePDF: (formulario, checkboxData, order) => {
            dispatch({ type: 'PDF_REPORTE_DINAMICO_BITACORA_REQUEST', formulario, checkboxData, order});
        },
        onDescargarReporteExcel: (formulario, checkboxData, order) => {
            dispatch({ type: 'EXCEL_REPORTE_DINAMICO_BITACORA_REQUEST', formulario,checkboxData, order});
        },
        onGetReporteDinamico: (formulario, page, order, checkboxData, agrupado) => {
            dispatch({ type: 'LISTA_REPORTE_DINAMICO_BITACORA_BUSQUEDA_REQUEST', formulario, page, order, checkboxData, agrupado});
        },
        onDescargarReporteAgrupadoPDF: (formulario, checkboxData, agrupado, order) => {
            dispatch({ type: 'PDF_REPORTE_DINAMICO_BITACORA_AGRUPADO_REQUEST', formulario, checkboxData, agrupado, order });
        },
        onDescargarReporteAgrupadoExcel: (formulario, checkboxData, agrupado, order) => {
            dispatch({ type: 'EXCEL_REPORTE_DINAMICO_BITACORA_AGRUPADO_REQUEST', formulario, checkboxData, agrupado, order });
        },
        onGetReporteDinamicoAgrupado: (formulario, page, agrupado, checkboxData, order) => {
            dispatch({ type: 'REPORTE_DINAMICO_AGRUPADO_BITACORA_REQUEST', formulario, page, agrupado, checkboxData, order});
        },
        onModalNuevoRegistroReporte: (formulario, checkboxData, tipoDocumento, agrupado, order) => {
            dispatch({ type: "MODAL_CREAR_REGISTRO_BITACORA", showModalCrearRegistro: true, data: {formulario, checkboxData, tipoDocumento, agrupado, order} });
        },
    }
}

export default connect (mapStateToProps, mapDispatchToProps) (TableSection);