import React, { Component } from 'react';
import { Form, Input, Button, Row, Col, Spin, Modal, Radio, Select } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';
const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalNuevoTipoDeFolio extends Component {
    
    formRef = React.createRef();
    render() {
        const { fetchingNuevoTipoFolioMovimiento, showModalNuevoTipoFolioMovimiento, onShowModalNuevoTipoFolioMovimiento,
            onNuevoTipoFolioMovimiento, page, limpiarFormulario } = this.props

        const onFinish = formulario => {
            console.log('Success:' , formulario);
            onNuevoTipoFolioMovimiento(formulario, page);
        }

        if (limpiarFormulario && limpiarFormulario==='crearTipoFolio') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo);
        };

        const handleCancel = () => {
            onShowModalNuevoTipoFolioMovimiento();
            this.formRef.current.resetFields();
        }

        return (
            <Modal
                visible={showModalNuevoTipoFolioMovimiento}
                title='Nuevo Tipo de Folio de Movimiento'
                onCancel={handleCancel}
                footer={false}
            >
                <Spin spinning={fetchingNuevoTipoFolioMovimiento}>
                    <Form {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        
                        ref={this.formRef}
                    >
                        <Form.Item
                            label="Nombre"
                            name="nombre"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <Input
                            pattern="^^[ñíóáéú a-zA-Z]+$"
                            title="Nombre debe tener letras." 
                            maxLength={50} placeholder='Nombre tipo de folio' />
                        </Form.Item>
                        <Form.Item
                            label="Nomenclatura"
                            name="nomenclatura"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <Input
                            pattern="^^[Ñ A-Z0-9]+$"
                            title="Nomenclaruta debe tener solo mayúsculas."
                            maxLength={4} placeholder='Nomenclatura' />
                        </Form.Item>
                        <Form.Item
                            label="Operación al inventario"
                            name="operacion"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <Select
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Seleccione el tipo de folio">
                                <Option value="Suma">Suma</Option>
                                <Option value="Resta">Resta</Option>
                                <Option value="Ambas">Ambas</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Requiere folio origen "
                            name="folioOrigen"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <Radio.Group>
                                <Radio value={true}>Si</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Row justify='space-around'>
                            <Col span={24}>
                                <Button style={{width:'100%'}} className="btnFiltrar" htmlType="submit">Crear Tipo de Folio de Movimiento</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>

            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingNuevoTipoFolioMovimiento: state.AlmacenesReducer.fetchingNuevoTipoFolioMovimiento,
        showModalNuevoTipoFolioMovimiento: state.AlmacenesReducer.showModalNuevoTipoFolioMovimiento,
        page: state.AlmacenesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevoTipoFolioMovimiento: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_TIPO_FOLIO', showModalNuevoTipoFolioMovimiento: false });
        },
        onNuevoTipoFolioMovimiento: (formulario, page) => {
            dispatch( { type: 'NUEVO_TIPO_FOLIO_REQUEST', formulario: formulario, page:page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoTipoDeFolio);