import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, Select, message } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalEditarEstandarDesempenoEspecifico extends Component {
    formRef = React.createRef();
    

    render() {

        const { fetchingEditarEstandarDesempenoEspecifico, showModalEditarEstandarDesempenoEspecifico, onEditarEstandarDesempenoEspecifico, onShowModalEditarEstandarDesempenoEspecifico,
            EstandarDesempenoEspecificoSeleccionado, page, limpiarFormulario, listaServiciosFiltro, fetchingListaServiciosFiltro, onListaServiciosFiltro, onListaServiciosFiltroLimpiar, listaServiciosActivosF} = this.props;

        const onFinish = formulario => {
            for (let i=0; i<formulario.servicios.length; i++) {
                formulario.servicios[i] = formulario.servicios[i].split('-')[0]
            }
            console.log('Success:', formulario);
            onEditarEstandarDesempenoEspecifico(formulario, EstandarDesempenoEspecificoSeleccionado, page, listaServiciosActivosF);

        };
        if (limpiarFormulario && limpiarFormulario==='editarEstandarDesempeño') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        

        const handleCancel = () => {
            onShowModalEditarEstandarDesempenoEspecifico();
        }

        const buscarServicios = value => {
            if(value.length>=3){
                if(value){
                    console.log(value);
                onListaServiciosFiltro(value)
                } else {
                    message.warning('No se encontró servicio con esas palabras')
                }
            } else {
               onListaServiciosFiltroLimpiar();
            } 
        }

        return (
            <Modal
                visible={showModalEditarEstandarDesempenoEspecifico}
                title='Editar Sección de Estándares Específicos'
                onCancel={handleCancel}
                footer={false}>
                <Spin spinning={fetchingEditarEstandarDesempenoEspecifico}>
                    {EstandarDesempenoEspecificoSeleccionado ? (
                        <Form {...layout}
                            name="formulario"
                            initialValues={EstandarDesempenoEspecificoSeleccionado}
                            onFinish={onFinish}
                            
                            ref={this.formRef}
                        >
                            <Form.Item
                                label="Sección"
                                name="descripcion"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                <TextArea
                                    pattern="^[ñíóáéú a-zA-Z ]+$"
                                    title="Descripción sin carácteres especiales"
                                    maxLength={150}
                                    placeholder='Descripción sección'
                                    autoSize />
                            </Form.Item>

                            <Form.Item
                                label="Servicio"
                                name="servicios"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select
                                    mode='multiple'
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona un servicio"
                                    loading={fetchingListaServiciosFiltro}
                                    showSearch
                                    filterOption={false}
                                    onSearch={buscarServicios}
                                    notFoundContent={null}
                                >
                                    {listaServiciosFiltro.map(option => <Option key={option.idServicio + '-' + option.nombre}>{option.idServicio + '-' + option.nombre}</Option>)}
                                </Select>
                            </Form.Item>
                        
                            <Col span={12}>
                                <Button className="btnFiltrar" htmlType="submit">Editar Sección de Estándares Específicos</Button>
                            </Col>
                        </Form>
                    ) : false}
                </Spin>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingEditarEstandarDesempenoEspecifico: state.MecanismosDePagosReducer.fetchingEditarEstandarDesempenoEspecifico,
        showModalEditarEstandarDesempenoEspecifico: state.MecanismosDePagosReducer.showModalEditarEstandarDesempenoEspecifico,
        EstandarDesempenoEspecificoSeleccionado: state.MecanismosDePagosReducer.EstandarDesempenoEspecificoSeleccionado,
        page: state.MecanismosDePagosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
    };
}


const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarEstandarDesempenoEspecifico: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_ESTANDAR_DESEMPENO_ESPECIFICO', showModalEditarEstandarDesempenoEspecifico: false });
        },
        onEditarEstandarDesempenoEspecifico: (formulario, EstandarDesempenoEspecificoSeleccionado, page, listaServiciosActivosF) => {
            dispatch({ type: 'EDITAR_ESTANDAR_DESEMPENO_ESPECIFICO_REQUEST', formulario: formulario, EstandarDesempenoEspecificoSeleccionado:EstandarDesempenoEspecificoSeleccionado, page: page, listaServiciosActivosF: listaServiciosActivosF });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
        },
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarEstandarDesempenoEspecifico);