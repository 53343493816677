import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, Row, InputNumber, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

import L from 'leaflet';
import { Map, ImageOverlay, FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import VerificarContraseña from './VerificarContraseña';
import ArchivoEditarPonderacion from './ArchivoEditarArea';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalEditarAreaFuncional extends Component {
    formRef = React.createRef();
    
    state = {
        fileImage: false,
        botonImage: false,
        botonEditar: false,
        imageUrl: null,
        height: 0,
        width: 0,
        geometry: null,
    }
    
    render() {
        const { 
            AreaFuncionalSeleccionado,
            onEditarAreasFuncionales,
            onShowModalEditarAreaFuncional,
            showModalEditarAreaFuncional,
            fetchingEditarAreaFuncional,
            page, listaInfoProyecto,
            limpiarFormulario, bloqueado, busqueda, cambiarVistaModalArea
        } = this.props;
        
        if(AreaFuncionalSeleccionado && !this.state.geometry){
            if(AreaFuncionalSeleccionado.coordenadas){
               this.setState({
                    geometry: AreaFuncionalSeleccionado.coordenadas
                }) 
            }
            else{
                this.setState({
                    geometry: []
                }) 
            }
        }
        
        const onFinish = formulario => {
            console.log(`editedGeometry`, this.state.geometry)
            console.log('Success:', formulario);
            if(this.state.geometry.length > 1) {
                return message.error('No se permite más de una geometría para el área funcional');
            }
            if(bloqueado){
                onEditarAreasFuncionales(formulario, AreaFuncionalSeleccionado, this.state.geometry, page, busqueda, bloqueado);
            }
            else{
                this.props.onCambiarVista(2, formulario, this.state.geometry);
            }
        };

        if (bloqueado && limpiarFormulario && limpiarFormulario==='editarArea') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
            this.setState({
                fileImage: false,
                botonImage: false,
                botonEditar: false,
                imageUrl: null,
                height: 0,
                width: 0,
                geometry: null,
            });
        }

        const handleCancel = () => {
            onShowModalEditarAreaFuncional()
            this.setState({
                fileImage: false,
                botonImage: false,
                botonEditar: false,
                imageUrl: null,
                height: 0,
                width: 0,
                geometry: null,
            });
            this.props.onCambiarVista(1);
        }
        // const enteroInput = (value) => {
        //     this.formRef.current.setFieldsValue({'cantidad': parseInt(value)});
        // }

        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };
    
        const onChangeImage = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else {
                this.setState({
                    ...this.state,
                    botonImage: true,
                    botonEditar: true,
                })
            }
            if (info.file.status === 'done') {
                var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,40}$");
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['plano']);
                    this.setState({...this.state, botonImage: false});
                }
                else {
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        ...this.state,
                        botonImage: true,
                        fileImage: true
                    });
                }
            } else if (info.file.status === 'error') {
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['plano']);
                    this.setState({...this.state, botonImage: false});
                }
                else {
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        ...this.state,
                        fileImage: true
                    });
                }
                this.setState({
                    ...this.state,
                    botonImage: false,
                    botonEditar: false,
                });
            }
        }
    
        const onRemoveImage = () => {
            this.setState({
                ...this.state,
                fileImage: false,
                botonImage: false,
            });
        }

        if(!this.state.imageUrl && listaInfoProyecto){
            const rutaImagenCentro = listaInfoProyecto.rutaImagenCentro;
            const img = new Image();
            const updateState = (width, height) => {
                this.setState({
                    imageUrl: rutaImagenCentro,
                    width, 
                    height,
                    geometry: null,
                });
            }
            img.onload = function() {
                console.log(this.width + 'x' + this.height);
                updateState(this.width, this.height);
            }
            img.src = listaInfoProyecto.rutaImagenCentro;
        }
        const bounds = [
            [0, 0],
            [this.state.height, this.state.width],
        ]
        const style = { height: '30em', width: '100%' }

        // const setGeometry = (layer) => {
        //     this.setState({...this.state, geometry: [...this.state.geometry, layer]});
        // }
        
        // const _created = (e) => {
        //     const {layer, layerType} = e;
        //     if(this.state.geometry.length>0){
        //         message.warning('No se permite más de una geometría para el área funcional');
        //     }
        //     if(layerType === 'polygon') {
        //         const {_leaflet_id} = layer;
        //         setGeometry({id: _leaflet_id, latlngs: layer.getLatLngs()[0]});
        //     }
        // }
        const editGeometry = (geometriesE) => {
            console.log(`geometriesE`, geometriesE)
            
            this.setState({...this.state, geometry: geometriesE})
        }
        const _edited = (e) => {
            const {layers: {_layers}} = e;
            let editedArray = [];
            Object.values(_layers).map(({_leaflet_id, editing}) =>{
                //edited array
                console.log(`editing`, editing)
                editedArray = [...editedArray, {id: _leaflet_id, latlngs: editing.latlngs[0][0]}]
            });
            
            console.log('edited', e, editedArray);
            editGeometry(editedArray); 
        }
        // const deleteGeometry = (geometriesD) => {
        //     console.log(`geometriesD`, geometriesD)
        //     let geoArray = this.state.geometry
        //     console.log(`geoArray`, geoArray)
            
        //     if(geometriesD[1]){
        //         geoArray = []
        //     }
        //     else{
        //         let borrarConFilter = false;
        //         for(let i in geoArray){
        //             if(geoArray[i].id === geometriesD[0]){
        //                 borrarConFilter = true;
        //             }
        //         }
        //         if(borrarConFilter){
        //             geoArray = geoArray.filter(geo => geo.id !== geometriesD[0])
        //         }
        //         else{
        //             let nuevo = []
        //             for(let i in geoArray){
        //                 console.log(`geoArray[i]`, geoArray[i])
                        
        //                 if(!geoArray[i][0]){
        //                     console.log('Entró al if de [0]')
        //                     nuevo.push(geoArray[i]);
        //                 }
        //             }
        //             geoArray = nuevo;
        //         }
        //     }
            
        //     console.log(`geoArray despues del map`, geoArray)
            
        //     this.setState({...this.state, geometry: geoArray});
        // }
        // const _deleted = (e) =>{ 
        //     const {layers: {_layers}} = e;
        //     let deletedArray = [];
        //     Object.values(_layers).map(({_leaflet_id, editing}) =>{
        //         console.log(`_leaflet_id, editing`, _leaflet_id, editing)
        //         //edited array
        //         deletedArray = [...deletedArray, _leaflet_id ]
        //     });
        //     deleteGeometry(deletedArray);
        // }

        const getGeoJson = () => {
            let geometry = this.state.geometry;
            console.log(`geometry`, geometry)
            return {
                type: "FeatureCollection",
                features: [{
                    type: "Feature",
                    properties: {},
                    geometry: {
                        type: "Polygon",
                        coordinates: 
                            geometry
                    }
                }]
            }
        }

        const _onFeatureGroupReady = reactFGref => {
            // populate the leaflet FeatureGroup with the geoJson layers
            if(!reactFGref ) return;
            let leafletGeoJSON = new L.GeoJSON(getGeoJson());
            let leafletFG = reactFGref.leafletElement;
            
            let layers = leafletFG._layers;
            let layers_ids = Object.keys(layers);
            
            if(layers_ids.length === 0) {
                //add layers
                leafletGeoJSON.eachLayer(layer => {
                    leafletFG.addLayer(layer);
                });
            }
        };
        return ( 
            <Modal
                visible={showModalEditarAreaFuncional}
                title='Editar Área Funcional'
                onCancel={handleCancel}
                footer={false}
                width={'80%'}
            >
                {cambiarVistaModalArea === 1 ? (
                <Spin spinning={fetchingEditarAreaFuncional}>
                    {AreaFuncionalSeleccionado ? (
                    <Form {...layout}
                    name="formulario"
                    initialValues={AreaFuncionalSeleccionado}
                    onFinish={onFinish}
                    ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Código área"
                                    name="codigoArea"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input pattern="^([ñíóáéúÁÉÓÍÚa-zA-Z]{3})+([ ])+([0-9]{1,2})$" disabled={true}
                                        title="El codigo area deben ser 3 letras un espacio y numeros" 
                                        minLength={3} maxLength={6} placeholder="Ingresa el código de área funcional" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Área funcional"
                                    name="areaFuncional"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input
                                        maxLength={40} placeholder="Ingresa el área funcional" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Clave de área"
                                    name="claveArea"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input pattern="^[ñíóáéúÁÉÓÍÚa-zA-Z0-9]+$" 
                                        title="La clave área solo debe tener letras" 
                                        minLength={3} maxLength={3} placeholder="Ingresa la clave de área funcional" />
                                </Form.Item>
                            </Col>
                            {/* <Col span={11}>
                                <Form.Item
                                    label="Cantidad"
                                    name="cantidad"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber min={1} max={100} onChange={enteroInput} placeholder="Ingresa la cantidad" />
                                </Form.Item>
                            </Col> */}
                            <Col span={11}>
                                <Form.Item
                                    label="Plano"
                                    name="plano"
                                >
                                    <Upload  customRequest={dummyRequest} onChange={onChangeImage} onRemove={onRemoveImage} accept='.jpg, .jpeg, .png, .svg' multiple={false} style={{ width: '100%' }}>
                                        {this.state.fileImage === false ? (
                                        <Button disabled={this.state.botonImage} className='btnCargaArchivos'>
                                            <UploadOutlined /> Click para Adjuntar Plano
                                        </Button>
                                        ):false}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={23}>
                                <p>Geometría</p>
                                {console.log(`this.state.imageUrl`, this.state.imageUrl)}
                                {this.state.imageUrl && this.state.geometry ? (
                                <Map
                                    crs={L.CRS.Simple}
                                    minZoom={-4}
                                    bounds={bounds}
                                    style={style}
                                >
                                    <FeatureGroup
                                        ref={reactFGref => {
                                        _onFeatureGroupReady(reactFGref);
                                        }}
                                    >
                                        <EditControl
                                            position="topright"
                                            // onCreated={_created}
                                            onEdited={_edited}
                                            // onDeleted={_deleted}
                                            draw={{
                                                circle: false,
                                                circlemarker: false,
                                                marker: false,
                                                rectangle: false,
                                                polyline: false,
                                                polygon: false,
                                            }}
                                            edit={{
                                                remove: false,
                                            }}
                                        />
                                    </FeatureGroup>
                                    <ImageOverlay
                                        url={this.state.imageUrl}
                                        bounds={bounds}
                                        zIndex={10}
                                    />
                                </Map>
                                ):false}
                            </Col>
                            
                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" htmlType="submit" style={{width: '100%'}}>Editar Área Funcional</Button>
                            </Col>
                        </Row>
                    </Form>
                    ):false}
                </Spin>
                ):(
                <>
                    {cambiarVistaModalArea === 2 ? (
                    <VerificarContraseña />    
                    ):(
                    <ArchivoEditarPonderacion />
                    )}
                </>
                )}
            </Modal>
         );
    }
}
 
const mapStateToProps = state => {
	return {
        fetchingEditarAreaFuncional: state.CatalogosGeneralesReducer.fetchingEditarAreaFuncional,
        showModalEditarAreaFuncional: state.CatalogosGeneralesReducer.showModalEditarAreaFuncional,
        fetchingListaPermisos: state.CatalogosGeneralesReducer.fetchingListaPermisos,
        listaPermisos: state.CatalogosGeneralesReducer.listaPermisos,
        AreaFuncionalSeleccionado: state.CatalogosGeneralesReducer.AreaFuncionalSeleccionado,
        page: state.CatalogosGeneralesReducer.page,
        busqueda: state.CatalogosGeneralesReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        editedGeometry: state.CatalogosGeneralesReducer.editedGeometry,
        bloqueado: state.CatalogosGeneralesReducer.bloqueado,
        cambiarVistaModalArea: state.CatalogosGeneralesReducer.cambiarVistaModalArea,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarAreaFuncional: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_AREASFUNCIONALES', showModalEditarAreaFuncional: false, AreaFuncionalSeleccionado: null, editedGeometry: []})
        },
        onEditarAreasFuncionales: ( formulario, AreaFuncionalSeleccionado, editedGeometry, page, busqueda, bloqueado ) => {
            dispatch({ type: "EDITAR_AREASFUNCIONALES_REQUEST", formulario, AreaFuncionalSeleccionado, editedGeometry, page, busqueda, bloqueado });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        },
        onShowModalMapa: (editedGeometry) => {
            dispatch({ type: 'SHOW_MODAL_MAPA_AREA_FUNCIONAL', showModalMapaAreaFuncional: true, editedGeometry })
        },
        onCambiarVista: (cambiarVistaModalArea, formularioAreas, editedGeometry) => {
            dispatch({ type: "CAMBIAR_VISTA_AREAS", cambiarVistaModalArea, formularioAreas, editedGeometry })
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarAreaFuncional);