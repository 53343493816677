import React, { Component } from 'react';
import { Modal, Tooltip, Row, Col, Table, Button, Alert } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalTrazabilidad from './ModalTrazabilidad';

class ModalSolicitudesServicio extends Component {
    state = {
        page: 1,
    }
    render() { 
        const {
            showModalSolicitudesDeServicio,
            servicioSeleccionadoTablero,
            onCerrarModal,
            solicitudesDeServicioPorServicio,
            solicitudesDeServicioPorServicioCantidad,
            onGetTrazabilidadFolio,
            fetchingSolicitudesDeServicioPorServicio,
            idServicio, 
            field, onShowModalTrazabilidad,
            estadoFolios, 
            idGrupoSer, page, busqueda,
            onGetSolicitudesDeServicioPorServicio,
            onLimpiarSolicitudesGrid,
        } =  this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const scroll = {"x": 2000 | true, "y": 1000};

        const handleCancel = () => {
            onCerrarModal();
            onLimpiarSolicitudesGrid();
        };

        const handleTableChange = (pagination) => {
            this.setState({ 
                page: pagination.current
            });
            onGetSolicitudesDeServicioPorServicio(idServicio, field, estadoFolios, pagination.current, idGrupoSer)
        }

        const columns = [{
            title: 'Folio',
            dataIndex: 'folio',
            key: 'folio',
            align: 'center',
        }, {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            align: 'center',
        }, {
            title: 'Servicio',
            dataIndex: 'servicio',
            key: 'servicio',
            align: 'center',
        }, {
            title: 'Descripcion de Falla',
            dataIndex: 'descripcionFalla',
            key: 'descripcionFalla',
            align: 'center',
        }, {
            title: 'Area',
            dataIndex: 'area',
            key: 'area',
            align: 'center', 
        },{
            title: 'Unidad',
            dataIndex: 'unidad',
            key: 'unidad',
            align: 'center', 
        },{
            title: 'Interior',
            dataIndex: 'interior',
            key: 'interior',
            align: 'center', 
        },{
            title: 'Trazabilidad',
            dataIndex: 'trazabilidad',
            key: 'trazabilidad',
            align: 'center', 
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.verTrazabilidadEnTableroDeOperaciones} onClick={() => onSolicitud(record)}><FileSearchOutlined /></a>
            ),
        }];

        const onSolicitud = (key) => {
            console.log(`key`, key);
            onShowModalTrazabilidad(key, page, busqueda);
            onGetTrazabilidadFolio(key.folio, 1)
        }

        return ( 
            <div>
                {servicioSeleccionadoTablero  ? (
                <Modal
                    visible={showModalSolicitudesDeServicio}
                    title={`Solicitudes de Servicio`}
                    onCancel={handleCancel}
                    footer={false}
                    width={'85%'}
                >
                    {solicitudesDeServicioPorServicio.length > 0 ? (
                    <Col span={24}>
                        <br/>                           
                        <Table 
                            size="small" 
                            rowKey="idSolicitudServicio" 
                            columns={columns}
                            dataSource={solicitudesDeServicioPorServicio}
                            loading={fetchingSolicitudesDeServicioPorServicio}
                            locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar'}}
                            onChange={handleTableChange}
                            pagination={{ total: solicitudesDeServicioPorServicioCantidad, simple: true  }}
                            scroll={scroll}
                        />                        
                    </Col> 
                    ) : (
                        <div><Alert message="No hay folios de Solicitudes de Servicio " type="info"/></div>   
                    )}
                    <ModalTrazabilidad/>
                </Modal>
                ) : false }
            </div>
        );
    }
}
 
const mapStateToProps = state => {
    return {
        showModalSolicitudesDeServicio: state.MonitorIntegralReducer.showModalSolicitudesDeServicio,
        servicioSeleccionadoTablero: state.MonitorIntegralReducer.servicioSeleccionadoTablero,
        solicitudesDeServicioPorServicio: state.MonitorIntegralReducer.solicitudesDeServicioPorServicio,
        solicitudesDeServicioPorServicioCantidad: state.MonitorIntegralReducer.solicitudesDeServicioPorServicioCantidad,
        solicitudesDeServicioPorServicioPorcentaje: state.MonitorIntegralReducer.solicitudesDeServicioPorServicioPorcentaje,
        fetchingSolicitudesDeServicioPorServicio: state.MonitorIntegralReducer.fetchingSolicitudesDeServicioPorServicio,
        page: state.MonitorIntegralReducer.page, 
        busqueda: state.MonitorIntegralReducer.busqueda,
        idServicio: state.MonitorIntegralReducer.idServicio, 
        field: state.MonitorIntegralReducer.field,
        estadoFolios: state.MonitorIntegralReducer.estadoFolios, 
        idGrupoSer: state.MonitorIntegralReducer.idGrupoSer,
    };
 };
 const mapDispatchToProps = dispatch => {
    return {
        onCerrarModal: () => {
            dispatch({ type: 'SHOW_MODAL_SOLICITUDES_DE_SERVICIO', showModalSolicitudesDeServicio: false})
        },
        onGetSolicitudesDeServicioPorServicio: (idServicio, color, selector, page, idGrupoSer) => {
            dispatch({ type: 'SOLICITUDES_DE_SERVICIO_POR_SERVICIO_REQUEST', idServicio, color, selector, page, idGrupoSer})
        },
        onLimpiarSolicitudesGrid: () => {
            dispatch({ type: 'SOLICITUDES_DE_SERVICIO_POR_SERVICIO_SUCCESS', solicitudesDeServicioPorServicio: [] })
        },
        onGetTrazabilidadFolio: (folio, page) => {
            dispatch({ type: "OBTENER_TRAZABILIDAD_FOLIO_REQUEST", folio, page});
        },
        onShowModalTrazabilidad: (solicitudDeServicioSeleccionado, page, busqueda) => {
            dispatch({ type: 'SHOW_MODAL_TRAZABILIDAD', showModalTrazabilidad: true, solicitudDeServicioSeleccionado, page, busqueda });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps) (ModalSolicitudesServicio);