import React, { Component } from 'react';
import {Form,Button,Modal,Col,Spin,Divider,DatePicker,InputNumber, message, Select, Input} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Option } = Select;
//MODAL MODIFICAR DENTRO DE "NUEVO FOLIO" POSTERIOR A LA SELECCIÓN DE PIH

class ModalModificarRegistroNuevoFolio extends Component {
    state = {  tipoMonedaSeleccionada: null, cantidad: [] }

    formRef = React.createRef();
    render() { 
        const {showModalModificarRegistroNuevoFolio, fetchingNuevoFolio, registroProductoSeleccionado,
            listaSeleccionados, vistaModalNuevoFolio, formularioNuevoFolio, fetchingObtenerUbicacionDestino,
            listaUbicacionesDestino, } = this.props;
    
        const onFinish = formulario => {
            console.log('Formulario:' , formulario);
            const listaSeleccionadosLocal = listaSeleccionados;
            this.props.onGuardarProducto(vistaModalNuevoFolio, formularioNuevoFolio, null);
            console.log('listaSeleccionadosLocal', listaSeleccionadosLocal)
            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            
            for (let i = 0; i < listaSeleccionadosLocal.length; i++) {
                
                if(listaSeleccionadosLocal[i].idProducto === registroProductoSeleccionado.idProducto){
                    if(listaSeleccionadosLocal[i].serieNum){
                        if(listaSeleccionadosLocal[i].serieNum === registroProductoSeleccionado.serieNum){
                            listaSeleccionadosLocal[i] = Object.assign(listaSeleccionadosLocal[i], formulario);
                        }
                    }
                    else{
                        listaSeleccionadosLocal[i] = Object.assign(listaSeleccionadosLocal[i], formulario);
                    }
                    
                    if(formularioNuevoFolio.idTipoFolio.split('-')[1] === 'Suma'){
                        if(listaSeleccionadosLocal[i].tipoMonedaEditar){
                            let tipoMonedaEdt = listaSeleccionadosLocal[i].tipoMonedaEditar.split('(')[1]
                            listaSeleccionadosLocal[i].tipoMoneda = listaSeleccionadosLocal[i].tipoMonedaEditar.split(' - ')[1];
                            listaSeleccionadosLocal[i].precioTabla = numberWithCommas(Number(formulario.precio).toFixed(2)) + ' ' + tipoMonedaEdt.split(')')[0];
                        } else {
                            listaSeleccionadosLocal[i].precioTabla = numberWithCommas(Number(formulario.precio).toFixed(2));
                        }

                        if(listaSeleccionadosLocal[i].subFamiliaByIdSubFamilia.familiaByIdFamilia.tipoFamilia === 'Activos'){
                            listaSeleccionadosLocal[i].serieNum = '';
                            for(let x=1; x<=listaSeleccionadosLocal[i].cantidad; x++){
                                if(x===listaSeleccionadosLocal[i].cantidad){
                                    listaSeleccionadosLocal[i].serieNum += listaSeleccionadosLocal[i]['serieNum-' + x];
                                }else{
                                    if(listaSeleccionadosLocal[i]['serieNum-' + x]){
                                        listaSeleccionadosLocal[i].serieNum += listaSeleccionadosLocal[i]['serieNum-' + x] + ', ';
                                    }
                                }
                            }
                        }

                        if(formulario.tipoCambio){
                                let costoTotal = Number(formulario.precio) * Number(formulario.tipoCambio) * formulario.cantidad;
                                listaSeleccionadosLocal[i].costoTotal = numberWithCommas(costoTotal.toFixed(2))  + ' MXN';
                                listaSeleccionadosLocal[i].tipoCambio = Number(formulario.tipoCambio).toFixed(2) + ' MXN';
                        } else {
                                let costoTotal = Number(formulario.precio) * formulario.cantidad;
                                listaSeleccionadosLocal[i].costoTotal = numberWithCommas(costoTotal.toFixed(2)) + ' MXN';
                                listaSeleccionadosLocal[i].tipoCambio = 'N/A';
                        }
                    }
                }
            }
            console.log('lista seleccionados fuera for', listaSeleccionadosLocal);
            this.props.onGuardarProducto(vistaModalNuevoFolio, formularioNuevoFolio, listaSeleccionadosLocal);
            this.props.onShowModalModificar();
        };

        const onCantidad = (value) => {
            this.formRef.current.setFieldsValue({'cantidad': parseInt(value)});
            if(formularioNuevoFolio.idTipoFolio.split('-')[1] === "Resta" || (formularioNuevoFolio.idTipoFolio.split('-')[1] === "Suma" && formularioNuevoFolio.idTipoFolio.split('-')[2] === "true") ){
                if(value > registroProductoSeleccionado.cantidad ){
                    message.error('Verificar cantidad de piezas disponibles');
                    this.formRef.current.resetFields(['cantidad']);
                }
            }
            const registroProductoSeleccionadoLocal = registroProductoSeleccionado;
            this.props.onActualizarListaSerieNum(null, this.props.page);
            registroProductoSeleccionadoLocal.listaSerieNum = [];
            for (let i=1; i<=parseInt(value); i++) {
                registroProductoSeleccionadoLocal.listaSerieNum.push(i);
            }
            this.props.onActualizarListaSerieNum(registroProductoSeleccionadoLocal, this.props.page);
            const cantidadLocal = []
            for(let i=0; i<parseInt(value); i++){
                cantidadLocal.push(i + 1);
            }
            this.setState({
                cantidad: cantidadLocal
            })
        }

        const handleCancel = () => {
            this.props.onShowModalModificar();
            this.formRef.current.resetFields();
            this.setState({ tipoMonedaSeleccionada: null, cantidad: [] });
        };
        const  disabledDate = (current) => {
            // Can not select days before today and today
            return current && current < moment();
        }
        
        const onNumeroSerie = (id) => {
            if(this.formRef.current){
                const formulario = this.formRef.current.getFieldsValue();
                const campo = 'serieNum-' + id;
                const valor = this.formRef.current.getFieldValue(campo);
                if(valor){
                    const listaSerieNum = [];

                    for (var key in formulario) {
                        if (Object.prototype.hasOwnProperty.call(formulario, key)) {
                            let idCampo = key.split('-');
                            if (idCampo.length === 2) {
                            let id = idCampo[1];
                            let campo = idCampo[0];
                            let valor = formulario[key];
        
                            let serieNum = {[campo]: valor, 'id': parseInt(id)};
                            listaSerieNum.push(serieNum);
                            }
                        }
                    }
                    for(let i in listaSerieNum){
                        console.log('listaSerieNum[i].id, id', listaSerieNum[i].id, id)
                        if(listaSerieNum[i].id !== id){
                            if(valor === listaSerieNum[i].serieNum){
                                message.warning('No es posible asignar el número de serie en más de un producto.');
                                this.formRef.current.resetFields([campo]);
                            }
                        }
                    }
                }
            }
        }
        return (
            <Modal
                visible={showModalModificarRegistroNuevoFolio}
                title={'Modificar información de: ' + registroProductoSeleccionado?.nombre}
                onCancel={handleCancel}
                footer={false}
            >
                {registroProductoSeleccionado ? (
                    <Spin spinning={fetchingNuevoFolio}>
                        <Form {...layout}
                            name='formulario'
                            initialValues={registroProductoSeleccionado}
                            onFinish={onFinish}
                            ref={this.formRef}
                        >
                            {listaUbicacionesDestino && (formularioNuevoFolio.idTipoFolio.split('-')[1] === "Suma" || formularioNuevoFolio.idTipoFolio.split('-')[1] === "Ambas") ? (
                            <Form.Item
                                label="Ubicacion destino"
                                name="idUbicacionProductoDestino"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Seleccione la ubicación destino"
                                    loading={fetchingObtenerUbicacionDestino}
                                >
                                    {listaUbicacionesDestino.map(option => <Option key={option.idUbicacionProducto}>{option.area + "-" + option.piso + "-" + option.pasillo + "-" + option.rack + "-" + option.nivelRack + "-" + option.posicionRack}</Option>)}
                                </Select>
                            </Form.Item>
                            ): false}
                            {formularioNuevoFolio.idTipoFolio.split('-')[1] === "Ambas" ? (registroProductoSeleccionado.subFamiliaByIdSubFamilia.familiaByIdFamilia?.tipoFamilia !== 'Activos' ? ( 
                            <Form.Item
                                label="Cantidad"
                                name="cantidad"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <InputNumber min={1} placeholder='Cantidad' onChange={onCantidad}/>
                            </Form.Item>
                            ):false):(
                            <Form.Item
                                label="Cantidad"
                                name="cantidad"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <InputNumber min={1} placeholder='Cantidad' onChange={onCantidad}/>
                            </Form.Item>
                            )}

                            {formularioNuevoFolio.idTipoFolio.split('-')[1] !== "Resta" && formularioNuevoFolio.idTipoFolio.split('-')[1] !== "Ambas" ? ( <>

                                {registroProductoSeleccionado.subFamiliaByIdSubFamilia.familiaByIdFamilia.tipoFamilia === 'Activos' ? ( 
                                    <>
                                    {registroProductoSeleccionado.listaSerieNum.map(i => 
                                        <Form.Item
                                            key={i}
                                            label={"Número de serie para producto Nº" + i}
                                            name={"serieNum-" + i}
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <Input maxLength={30} onBlur={() => onNumeroSerie(i)} placeholder='Ingresa el número de serie' />
                                        </Form.Item>
                                    )}
                                    <Divider orientation="left" /> 
                                    </> 
                                ): false}

                                <Form.Item
                                    label="Tipo de moneda"
                                    name="tipoMonedaEditar"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el tipo de moneda"
                                        loading={this.props.fetchingListaTipoMonedasActivas}
                                        onChange={(a, b) => { this.setState({ tipoMonedaSeleccionada: b.children }); }}
                                    >
                                        {this.props.listaTipoMonedasActivas.map(option => <Option key={option.idTipoMoneda + ' - ' + option.nombre + ' (' + option.nomenclatura + ')'}>{option.idTipoMoneda + ' - ' + option.nombre + ' (' + option.nomenclatura + ')'}</Option>)}
                                    </Select>
                                </Form.Item>
                                
                                <Form.Item
                                    label="Costo unitario"
                                    name="precio"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber min={1} placeholder='Precio' formatter={value =>  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                                </Form.Item>

                                { this.state.tipoMonedaSeleccionada ? (<>
                                    {this.state.tipoMonedaSeleccionada.split('(')[1] !== 'MXN)' ? (
                                        <Form.Item
                                            label="Tipo de cambio"
                                            name="tipoCambio"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <InputNumber min={1} placeholder='Tipo de cambio' formatter={value =>  `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                                        </Form.Item>
                                    ) : false}
                                </>): (<>
                                    {registroProductoSeleccionado.tipoMoneda.split('(')[1] !== 'MXN)' ? (
                                        <Form.Item
                                            label="Tipo de cambio"
                                            name="tipoCambio"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <InputNumber min={1} placeholder='Tipo de cambio' formatter={value =>  `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                                        </Form.Item>
                                    ) : false}
                                </>) }
                           
                            
                                {registroProductoSeleccionado.caracteristicaLote ? ( <>
                                    <Divider orientation="left" />
                                    <Form.Item
                                        label="Lote"
                                        name="lote"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <InputNumber min={1} placeholder='Lote' />
                                    </Form.Item>
                                    
                                    <Form.Item
                                        label="Fecha de caducidad"
                                        name="fechaCaducidad"
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            format='DD-MM-YYYY'
                                            placeholder={["Seleccione fecha de caducidad"]}
                                            disabledDate={disabledDate}
                                            showToday={false}
                                        />
                                    </Form.Item>
                                </> ): false}
                            </> ) : false}

                            {registroProductoSeleccionado.productoByIdProducto ? ( <>
                                {registroProductoSeleccionado.productoByIdProducto.caracteristicaLote ? ( <>
                                    <Divider orientation="left" />
                                    <Form.Item
                                        label="Lote"
                                        name="lote"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                          <InputNumber placeholder='Lote' />
                                    </Form.Item>
                                    
                                    <Form.Item
                                        label="Fecha de caducidad"
                                        name="fechaCaducidad"
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            format='DD-MM-YYYY'
                                            placeholder={["Seleccione fecha de caducidad"]}
                                            disabledDate={disabledDate}
                                            showToday={false}
                                        />
                                    </Form.Item>
                                </> ): false }
                            </> ): false }
                             
                            <Col span={24}>
                               <Button htmlType="submit" className='nuevoUsuario'>Guardar Cambios</Button>
                            </Col>
                            
                        </Form>
                    </Spin>
                ): false}
                
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        showModalModificarRegistroNuevoFolio: state.AlmacenesReducer.showModalModificarRegistroNuevoFolio,
        fetchingNuevoFolio: state.AlmacenesReducer.fetchingNuevoFolio,
        registroProductoSeleccionado: state.AlmacenesReducer.registroProductoSeleccionado,
        listaSeleccionados: state.AlmacenesReducer.listaSeleccionados,
        vistaModalNuevoFolio: state.AlmacenesReducer.vistaModalNuevoFolio, 
        formularioNuevoFolio: state.AlmacenesReducer.formularioNuevoFolio,
        listaUbicacionesDestino: state.AlmacenesReducer.listaUbicacionesDestino,
        fetchingObtenerUbicacionDestino: state.AlmacenesReducer.fetchingObtenerUbicacionDestino,

        listaTipoMonedasActivas: state.CatalogosGeneralesReducer.listaTipoMonedasActivas,
        fetchingListaTipoMonedasActivas: state.CatalogosGeneralesReducer.fetchingListaTipoMonedasActivas,
    };
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalModificar: () => {
            dispatch({ type: "SHOW_MODAL_MODIFICAR_REGISTRO_NUEVOFOLIO", showModalModificarRegistroNuevoFolio: false, registroProductoSeleccionado: null})
        },
        onGuardarProducto: (vistaModalNuevoFolio, formularioNuevoFolio, listaSeleccionados) => {
            dispatch({ type: "CAMBIAR_VISTA_MODAL_NUEVOFOLIO", vistaModalNuevoFolio: vistaModalNuevoFolio, formularioNuevoFolio: formularioNuevoFolio, listaSeleccionados: listaSeleccionados})  
        },
        onGetUbicacionDestino: (page, idAlmacen) => {
            dispatch({ type: "UBICACION_DESTINO_POR_ALMACEN_REQUEST", page: page, idAlmacen: idAlmacen})
        },
        onActualizarListaSerieNum: (registroProductoSeleccionado) => {
            dispatch({ type: "SHOW_MODAL_MODIFICAR_REGISTRO_NUEVOFOLIO", showModalModificarRegistroNuevoFolio: true, registroProductoSeleccionado: registroProductoSeleccionado})
        },
	};
};
 
export default connect(mapStateToProps, mapDispatchToProps)(ModalModificarRegistroNuevoFolio);