import React from 'react';
import { Row, Col, Button, Input, Form, message, Spin } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import '../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import { loginCall } from "../services/LoginServices";

class EditarPefil extends React.Component {
    formRef = React.createRef();
    state={
        fetchingContraseña: false,
        verificado: false,
    }
    render() {
        const { usuario, limpiarFormulario } = this.props;
        console.log('usuario', usuario)
        let userInfo = sessionStorage.getItem('userInfo');
        if(!userInfo.idUsuario){
            userInfo = JSON.parse(userInfo);
        }

        if (limpiarFormulario && limpiarFormulario === 'editarContraseñaFormulario') {
            this.props.onLimpiarFormulario();
            this.formRef.current.resetFields();
        }

        const verificarContraseña = () => {
            const formulario = this.formRef.current.getFieldsValue();
            console.log('formulario', formulario)
            this.setState({
                fetchingContraseña: true,
            })
            const userName = userInfo[0].username;
            const values = {
                'userName': userName,
                'password': formulario.contraseñaAnterior,  
            }
            loginCall(values).then(response => {
                console.log('response');
                console.log(response);
                
                if(response.status === 200){
                    this.setState({
                        fetchingContraseña: false,
                        verificado: true,
                    })
                }
                else{
                    let errorMensaje = '';
                    if(response.error_message === 'Bad credentials'){
                        errorMensaje = 'Contraseña incorrecta. Por favor intente de nuevo';
                    }
                    else{
                        errorMensaje = response.error_message;
                    }
                    message.error(errorMensaje);
                    return;
                }
            }).catch(error => {
                console.log(error);
                message.error('Hubo un error, favor de intentar después');
                return;
            })
        }

        const onFinish = (formulario) => {
            console.log('formulario', formulario);
            this.props.onEditarContraseña(usuario ? usuario : userInfo[0], formulario)
        }
        return (
            <Spin spinning={this.state.fetchingContraseña || this.props.fetchingEditarContraseña}>
                <Row justify="space-around">
                    <Col span={usuario ? 24 : 18}>
                        {!usuario ? (
                        <h1 className='headerUsuarios'>Editar contraseña</h1>
                        ) : false}
                        <Form
                            name="formulario"
                            onFinish={onFinish}
                            ref={this.formRef}
                        >
                            <Row justify='space-around' gutter={[8,8]}>
                                {!usuario ? (
                                <>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Contraseña Actual"
                                            name="contraseñaAnterior"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <Input.Password prefix={<LockOutlined/>} disabled={this.state.verificado} placeholder="Ingresa tu contraseña actual"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Button style={{width: '100%'}} disabled={this.state.verificado} className="btnFiltrar" onClick={verificarContraseña}>Verificar Contraseña</Button>
                                    </Col>
                                </>
                                ) : false}
                                {this.state.verificado || usuario ? (
                                <>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Contraseña Nueva"
                                            name="contraseñaNueva"
                                            hasFeedback
                                            rules={[
                                                { required: true, message: 'La contraseña debe contener 8 caracteres mínimos y 60 caracteres máximos.', min: 8, max: 60},
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        console.log('value', value)
                                                        if (!/\d/.test(value)) {
                                                            return Promise.reject(new Error('La contraseña debe contener al menos un número'));
                                                        }
                                                        else if (!/[A-Z]/.test(value)) {
                                                            return Promise.reject(new Error('La contraseña debe contener al menos una letra mayúscula'));
                                                        }
                                                        else if (!/[a-z]/.test(value)) {
                                                            return Promise.reject(new Error('La contraseña debe contener al menos una letra minúscula'));
                                                        }
                                                        else if (!/[!"._,;?¿¡=)(/&%$#@]/.test(value)) {
                                                            return Promise.reject(new Error('La contraseña debe contener al menos un caracter especial !¡"._,;¿?=()/&%$#@'));
                                                        }
                                                        else{
                                                            return Promise.resolve();
                                                        }
                                                    },
                                                })
                                            ]}
                                        >
                                            <Input.Password prefix={<LockOutlined/>} placeholder="Ingresa tu contraseña nueva"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Repetir Contraseña Nueva"
                                            name="contraseñaNuevaRepetir"
                                            dependencies={['contraseñaNueva']}
                                            hasFeedback
                                            rules={[{ required: true, message: 'Favor de llenar el campo' },
                                            ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('contraseñaNueva') === value) {
                                                return Promise.resolve();
                                                }
                                
                                                return Promise.reject(new Error('Las contraseñas no coinciden'));
                                            },
                                            })]}
                                        >
                                            <Input.Password prefix={<LockOutlined/>} placeholder="Ingresa de nuevo tu contraseña nueva"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Button style={{width: '100%'}} className="btnFiltrar" htmlType="submit">Editar Contraseña</Button>
                                    </Col>
                                </>
                                ):false}
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
    return {
        fetchingEditarContraseña: state.ConfiguracionYSeguridadReducer.fetchingEditarContraseña,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onEditarContraseña: (usuario, formulario) => {
            dispatch({ type: "EDITAR_CONTRASEÑA_REQUEST", usuario, formulario });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        }
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(EditarPefil);