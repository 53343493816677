import React from 'react';
import {connect} from 'react-redux';
import {Spin, Row, Col, Table, Modal} from 'antd';
import { CalendarOutlined} from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ReprogramarTareas from './ReprogramarTareas';

class TablaTareas extends React.Component {

    render () {

        const {page, busqueda, showModalTablaTareasVC, programacionSeleccionadaVC, onModalReprogramarTareaVC, onModalTablaTareas} = this.props;
        
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columns = [{
            title: 'Fecha ejecución',
            dataIndex: 'fechaEjecucion',
            key: 'fechaEjecucion',
            // width: '8%'
        }, {
            title: 'Estado rutina',
            dataIndex: 'estado',
            key: 'estado',
            // width: '8%'
        },{
            title: 'Reprogramar',
            dataIndex: 'reprogramar',
            key: 'reprogramar',
            align: 'center',
            render: (text, record) => (
               <a className='editarIcono' disabled={!permisosUsuario.reprogramarTarea ? true : record.estado === 'Realizada' ? true : false} onClick={ () => reprogramarTarea(record) }> <CalendarOutlined />
               </a>
            ), 
        }];

        const reprogramarTarea = record => {
            console.log(record);
            onModalReprogramarTareaVC(record, busqueda, page)
        }

        const handleCancel = () => {
            onModalTablaTareas();
        }

        const scroll = {"y": 500};

        return (
        <div>
            {programacionSeleccionadaVC ? (
                <Modal
                visible={showModalTablaTareasVC}
                title={`Lista de tareas`}
                onCancel={handleCancel}
                footer={false}
                width='70%'
                >
                    <Row>
                        <Col span={24}>
                            <Table
                                size="small"
                                rowKey="fechaEjecucion"
                                columns={columns}
                                dataSource={programacionSeleccionadaVC}
                                // loading={fetchingListaTareasVistaCalendarioBusqueda}
                                locale={{ emptyText: 'Sin datos' }}
                                pagination={{ total: programacionSeleccionadaVC.length, pageSize: programacionSeleccionadaVC.length, hideOnSinglePage: true }}
                                scroll={scroll}
                            />
                        </Col>
                    </Row>   
                </Modal>
            ): false}
            <ReprogramarTareas/>
        </div>)
    }
}

const mapStateToProps = state => {
    return {
        page: state.ServiciosPlanificadosReducer.page,
        busqueda: state.ServiciosPlanificadosReducer.busqueda,
        showModalTablaTareasVC: state.ServiciosPlanificadosReducer.showModalTablaTareasVC,
        programacionSeleccionadaVC: state.ServiciosPlanificadosReducer.programacionSeleccionadaVC,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onModalReprogramarTareaVC: (tareaSeleccionadaVC, busqueda, page) => {
            dispatch({ type: 'SHOW_MODAL_REPROGRAMAR_TAREA_VC', showModalReprogramarTareaVC: true, tareaSeleccionadaVC, busqueda, page });
        },
        onModalTablaTareas: () => {
            dispatch({ type: 'SHOW_MODAL_TABLA_TAREAS_VC', showModalTablaTareasVC: false});
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (TablaTareas);