import * as loginActions from '../actions/LoginAction' 

const initialState = {
    fetchingLogin: false,
    tokenLogin: null,
    errorLogin: false,
    errorMensaje: null,
    usuario: sessionStorage.getItem('userInfo'),
    isAuthenticated: false,
    selectedPerfil: sessionStorage.getItem('perfilUsuario'),

    showModalReset: false,
    fetchingReinciar: false,

    fetchingLogout: false,

    showExpirarSesion: false,
};

export default(state = initialState, action) => {
    switch (action.type) {

        case loginActions.LOGIN_API_CALL_REQUEST:
            return { ...state, fetchingLogin: true, errorLogin: false };
        case loginActions.LOGIN_API_CALL_SUCCESS:
            return { ...state, fetchingLogin: false, usuario: action.usuario,tokenLogin: action.token,isAuthenticated:true, errorLogin: false, fetchingLogout: false };
        case loginActions.LOGIN_API_CALL_FAILURE:
            return { ...state, fetchingLogin: false, listaPedidosProgramados: [], errorLogin: true, errorMensaje: action.errorMensaje};

        case loginActions.CHANGE_SELECTED_PROFILE:
            return { ...state, selectedPerfil:action.selectedPerfil};

        case loginActions.MODAL_RESET_PASSWORD:
            return { ...state, showModalReset: action.showModalReset};

        case loginActions.RESET_PASSWORD_REQUEST:
            return { ...state, fetchingReinciar: true};
        case loginActions.RESET_PASSWORD_SUCCESS:
            return { ...state, fetchingReinciar: false};
        case loginActions.RESET_PASSWORD_FAILURE:
            return { ...state, fetchingReinciar: false};

        case loginActions.LOG_OUT_REQUEST:
            return { ...state, fetchingLogout: true};
        case loginActions.LOG_OUT_REQUEST:
            return { ...state, fetchingLogout: false};
        case loginActions.LOG_OUT_REQUEST:
            return { ...state, fetchingLogout: false};

        case loginActions.MODAL_EXPIRAR_SESION:
            return { ...state, showExpirarSesion: action.showExpirarSesion };

        default:
            return state;
    }
};