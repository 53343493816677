import React from 'react';
import {Card, Table, Row, Col, Select, Button, Input, Switch, DatePicker, Tooltip} from 'antd';
import { EditOutlined, EyeOutlined, DownloadOutlined, ProfileOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalVerPDFPlanCalidad from './ModalVerPDFPlanCorrectivo';
import ModalNuevoPlanCalidad from './ModalNuevoPlanCorrectivo';
import ModalEditarPlanCalidad from './ModalEditarPlanCorrectivo';
import ModalListaActividades from "./ModalListaActividades";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;
class GestionDePlanesCorrectivosContainer extends React.Component {
     state = {
          listaAños: [],
          page: 1,
          busqueda: {},
          orden: {},
          estado: false,
          periodo: []
     }
     componentDidMount(){
          this.props.onRequestInfoProyecto();
          this.props.onListaServiciosActivos();
          this.props.onListaPlanesCorrectivos(1, [], [], this.state.estado, this.state.periodo);
     }
     componentWillUnmount(){
          this.props.onListaPlanesCorrectivosLimpiar();
     }
 
     render() {
          const { listaServiciosActivosF, onListaPlanesCorrectivos, fetchingListaPlanesCorrectivosBusqueda, onShowModalVerPDFPlanCorrectivo,
               listaPlanesCorrectivosBusqueda, listaPlanesCorrectivosBusquedaCantidad, fetchingListaServiciosActivosF,
               onShowModalNuevoPlanCorrectivo, onShowModalEditarPlanCorrectivo, 
          } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
             permisosUsuario = JSON.parse(permisosUsuario);
          }

          const columns = [{
                    title: 'Nombre del documento',
                    dataIndex: 'nombreArchivo',
                    key: 'nombreArchivo',
                    width: '17%',
               }, {
                    title: 'Origen',
                    dataIndex: 'origen',
                    key: 'origen',
                    width: '13%',
               }, {
                    title: 'Fecha de incumplimiento',
                    dataIndex: 'fechaIncumplimiento',
                    key: 'fechaIncumplimiento',
                    width: '12%',
                    sorter: true
               }, {
                    title: 'Descripción',
                    dataIndex: 'incumplimiento',
                    key: 'incumplimiento',
                    width: '18%',
               }, {
                    title: 'Fecha real de término',
                    dataIndex: 'fechaTerminoGrid',
                    key: 'fechaTerminoGrid',
                    width: '10%',
                    sorter: true
               }, {
                    title: 'Actividades',
                    dataIndex: 'actividades',
                    key: 'actividades',
                    width: '10%',
                    align: 'center',
                    render: (text, record) => (
                        <a className='editarIcono' disabled={!permisosUsuario.actividadesPlanCorrectivo} onClick={() => onShowActividades(record.actividadesPlanesByIdPlanCorrectivo)}><ProfileOutlined /></a>
                    ),
               }, {
                    title: 'Editar',
                    key: 'editar',
                    width: '8%',
                    align: 'center',
                    render: (text, record) => (
                         <a className='editarIcono' disabled={!permisosUsuario.editarPlanCorrectivo ? true: record.estado ? false: true} onClick={() => onEditar(record)}><EditOutlined /></a>
                    ),
               },{
                    title: 'Consultar',
                    key: 'consultar',
                    width: '8%',
                    align: 'center',
                    render: (text, record) => (
                         <a className='editarIcono' disabled={!permisosUsuario.consultarPlanCorrectivo} onClick={() => onMostrarPDF(record)}><EyeOutlined /></a>
                    ),
               },{
                    title: 'Descargar',
                    key: 'desacrgar',
                    width: '8%',
                    align: 'center',
                    render: (text, record) => (
                         <a className='editarIcono' disabled={!permisosUsuario.descargarPlanCorrectivo} target='_blank' href={record.rutaArchivo}><DownloadOutlined /></a>
                    ),
               },
          ];

          const onShowActividades = (listaActividades) => {
               if (listaActividades) {
                    this.props.onShowModalListaActividades(listaActividades);
               }
          }

          const onMostrarPDF = (key) => {
               onShowModalVerPDFPlanCorrectivo(key);
          }

          const onEditar = (key) => {
               console.log(key);
               key.estadoEditar = key.estado;
               key.fechaTerminoEditar = key.fechaTermino;
               console.log('this.state.estado', this.state.estado)
               onShowModalEditarPlanCorrectivo(key, this.state.page, this.state.busqueda, this.state.orden, this.state.estado );
          }

          const onNuevoPlanCorrectivo = () => {
               onShowModalNuevoPlanCorrectivo(this.state.page, this.state.busqueda, this.state.orden, this.state.estado);
          }

          const onChangeServicio = (value) => {
               const busqueda = {
                    idServicio: value,
                    origen: this.state.busqueda.origen
               };
               this.setState({
                    busqueda,
               });
               onListaPlanesCorrectivos(this.state.page, busqueda, this.state.orden, this.state.estado, this.state.periodo);
          };
          const onChangeEstado = (value) => {
               this.setState({
                    estado: value,
               });
               onListaPlanesCorrectivos(this.state.page, this.state.busqueda, this.state.orden, value, this.state.periodo);
          };
          const onChangeRange = (value) => {
               this.setState({
                    periodo: value
               });
               onListaPlanesCorrectivos(this.state.page, this.state.busqueda, this.state.orden, this.state.estado, value);
          };
          const handleTableChange = (pagination, filtro, ordenTabla)=>{
               console.log('ordenTabla', ordenTabla)
               this.setState({
                   page: pagination.current,
               })
               if (ordenTabla.columnKey) {

                    let field = ordenTabla.columnKey;
                    let order = 'ASC';
    
                    if (ordenTabla.order === 'descend') {
                        order = 'DESC';
                    }
                    const orden = {
                         order, 
                         field
                    };
                    this.setState ({
                         orden
                    });
                    onListaPlanesCorrectivos(pagination.current, this.state.busqueda, orden, this.state.estado, this.state.periodo);
               } else {
                    onListaPlanesCorrectivos(pagination.current, this.state.busqueda, this.state.orden, this.state.estado, this.state.periodo);
               }
          };

          const onBuscarOrigen = (value) => {
               const busqueda = {
                    idServicio: this.state.busqueda.idServicio,
                    origen: value
               };
               this.setState({
                    busqueda,
               });
               onListaPlanesCorrectivos(this.state.page, busqueda, this.state.orden, this.state.estado, this.state.periodo);
          }
          return (
               <Card className="cardCatalogos" title={<div className='headerLista'>Gestión De Planes Correctivos</div>}>
                   <Row justify='space-between' gutter={[8,8]}>
                         <Col span={7}>
                              <Select allowClear
                                   style={{ width: '100%' }}
                                   placeholder="Selecciona un servicio a filtrar"
                                   loading={fetchingListaServiciosActivosF}
                                   onChange={onChangeServicio}
                                   showSearch={true}
                              >
                                   {listaServiciosActivosF.map(option => <Option key={option.idServicio + ' - ' + option.nombre}>{option.idServicio + ' - ' + option.nombre}</Option>)}
                              </Select>
                         </Col>
                         <Col span={6}>
                              <Search className='buscarModulos' placeholder="Ingresa el origen a filtrar"  onSearch={value => onBuscarOrigen(value)} style={{ width: '100%' }} />
                         </Col>
                        <Col span={7}>
                             <RangePicker
                                 onChange={onChangeRange} format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Fecha inicio", "Fecha fin"]}
                             />
                        </Col>
                         <Col span={3}>
                              <Switch onChange={onChangeEstado} checkedChildren="Estado abierto"
                                      unCheckedChildren="Estado cerrado"/>
                         </Col>
                         {/* <Col span={4}>
                              <Button onClick={onNuevoPlanCorrectivo} disabled={!permisosUsuario.crearPlanCorrectivo ? true: !this.state.busqueda } className='nuevoUsuario'>Nuevo Plan Correctivo</Button>
                         </Col> */}
                         <Col span={1}>
                              <Tooltip title='Nuevo Plan Correctivo' placement="topRight">
                                   <Button className="iconTer" shape="circle" onClick={onNuevoPlanCorrectivo} disabled={!permisosUsuario.crearPlanCorrectivo ? true: !this.state.busqueda } icon={<PlusOutlined />}/>
                              </Tooltip>
                         </Col>

                         <Col span={24}>
                              <Table 
                                   size="small" 
                                   rowKey="idPlanCorrectivo"
                                   columns={columns}
                                   dataSource={listaPlanesCorrectivosBusqueda}
                                   loading={fetchingListaPlanesCorrectivosBusqueda}
                                   locale={{ emptyText: 'Sin datos' }}
                                   onChange={handleTableChange}
                                   pagination={{ total: listaPlanesCorrectivosBusquedaCantidad, simple: true, current: this.state.page}}
                              />         
                         </Col>
                    </Row>
                    <ModalVerPDFPlanCalidad/>
                    <ModalNuevoPlanCalidad/>
                    <ModalEditarPlanCalidad/>
                    <ModalListaActividades />
               </Card>
          );
     }
}
 
 const mapStateToProps = state => {
     return {
          fetchingListaPlanesCorrectivosBusqueda: state.GestionDeCalidadReducer.fetchingListaPlanesCorrectivosBusqueda,
          listaPlanesCorrectivosBusqueda: state.GestionDeCalidadReducer.listaPlanesCorrectivosBusqueda,
          listaPlanesCorrectivosBusquedaCantidad: state.GestionDeCalidadReducer.listaPlanesCorrectivosBusquedaCantidad,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          onRequestInfoProyecto: () => {
               dispatch({ type: 'GET_INFOPROYECTO_REQUEST', planCalidadAños: true });
          },
          onListaServiciosActivos: () => {
               dispatch ({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST' });
          },
          onListaPlanesCorrectivos: (page, busqueda, orden, estado, periodo) => {
               dispatch({ type: 'LISTA_PLANES_CORRECTIVOS_BUSQUEDA_REQUEST', page, busqueda, orden, estado, periodo })
          },
          onListaPlanesCorrectivosLimpiar: () => {
               dispatch({ type: 'LISTA_PLANES_CORRECTIVOS_BUSQUEDA_SUCCESS' })
          },
          onShowModalVerPDFPlanCorrectivo: (planCorrectivoSeleccionado) => {
               dispatch ({ type: 'MODAL_VER_PLAN_CORRECTIVO', showModalVerPlanCorrectivo: true, planCorrectivoSeleccionado })
          },
          onShowModalNuevoPlanCorrectivo: (page, busqueda, orden, estado) => {
               dispatch ({ type: 'MODAL_NUEVO_PLAN_CORRECTIVO', showModalNuevoPlanCorrectivo: true, page, busqueda, orden, estado })
          },
          onShowModalEditarPlanCorrectivo: (planCorrectivoSeleccionado, page, busqueda, orden, estado) => {
               dispatch ({ type: 'MODAL_EDITAR_PLAN_CORRECTIVO', showModalEditarPlanCorrectivo: true, planCorrectivoSeleccionado, page, busqueda, orden, estado })
          },
          onShowModalListaActividades: (listaActividades) => {
               dispatch({
                    type: 'MODAL_LISTA_ACTIVIDADES',
                    showModalListaActividades: true,
                    listaActividades: listaActividades
               })
          },
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(GestionDePlanesCorrectivosContainer);
