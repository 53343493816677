import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, Select } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalEditarRol extends Component {
    state={
        showPermisos:false
    }
    formRef = React.createRef();
    render() {
        const { fetchingEditarRol, showModalEditarRol, onEditarRol, onShowModalEditarRol,
            fetchingListaPermisosByModulo, listaPermisosByModulo, rolSeleccionado, getPermisosByModulo, 
            fetchingListaModulos, listaModulosSelect,valueSearch
        } = this.props;
        console.log('rolSeleccionado', rolSeleccionado);
        const {showPermisos} = this.state;
        const onFinish = formulario => {
            console.log('Success:', formulario);
            onEditarRol(formulario, listaPermisosByModulo, rolSeleccionado, this.props.page, valueSearch);
            this.setState({
                showPermisos:false
            })
        };
        

        const handleCancel = () => {
            onShowModalEditarRol()
            this.setState({
                showPermisos:false
            })
        }

        const permisosPorModulo = (value) => {
            if(value){
                this.setState({
                    showPermisos:true
                })
            }
            getPermisosByModulo(value.split(' - ')[0]);
        }

        console.log("valueSearch",valueSearch)
        return (
            <Modal
            visible={showModalEditarRol}
            title='Editar Rol'
            width={'70%'}
            onCancel={handleCancel}
            footer={false}
            >
                <Spin spinning={fetchingEditarRol}>
                    {rolSeleccionado ? (
                    <Form {...layout}
                    name="formulario"
                    initialValues={rolSeleccionado}
                    onFinish={onFinish}
                    ref={this.formRef}
                    >
                        <Form.Item
                            label="Nombre del rol"
                            name="descripcion"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Input  maxLength={80} placeholder="Ingrese nombre del rol" />
                        </Form.Item>
                        <Form.Item
                            label="Módulo de sistema"
                            name="modulo"
                            rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                        >
                            <Select
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Selecciona el módulo de sistema"
                                loading={fetchingListaModulos}
                                notFoundContent={null}
                                onChange={permisosPorModulo}
                            >
                                {listaModulosSelect.map(option => <Option key={option.idModulo + ' - ' + option.nombre}>{option.nombre}</Option>)}
                            </Select>
                        </Form.Item>
                        {showPermisos==true?(
                            <Form.Item
                                label="Permisos"
                                name="permisosSelect"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona los servicios"
                                    loading={fetchingListaPermisosByModulo}
                                >
                                    {listaPermisosByModulo.map(option => <Option key={option.idPermiso + ' - ' + option.descripcion}>{option.idPermiso + ' - ' + option.descripcion}</Option>)}
                                </Select>
                            </Form.Item>
                        ):(false)}
                        <Col span={12}>
                            <Button className="btnFiltrar" htmlType="submit">Editar Rol</Button>
                        </Col>
                    </Form>
                    ):false}
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarRol: state.ConfiguracionYSeguridadReducer.fetchingEditarRol,
        showModalEditarRol: state.ConfiguracionYSeguridadReducer.showModalEditarRol,
        fetchingListaPermisosByModulo: state.ConfiguracionYSeguridadReducer.fetchingListaPermisosByModulo,
        listaPermisosByModulo: state.ConfiguracionYSeguridadReducer.listaPermisosByModulo,
        rolSeleccionado: state.ConfiguracionYSeguridadReducer.rolSeleccionado,
        page: state.ConfiguracionYSeguridadReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaModulosSelect: state.ConfiguracionYSeguridadReducer.listaModulosSelect,
        fetchingListaModulos: state.ConfiguracionYSeguridadReducer.fetchingListaModulos,
        valueSearch: state.ConfiguracionYSeguridadReducer.valueSearch
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarRol: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_ROL', showModalEditarRol: false, rolSeleccionado: null, page: null })
        },
        onEditarRol: ( formulario, listaPermisos, rolSeleccionado, page, valueSearch ) => {
            dispatch({ type: "EDITAR_ROL_REQUEST" , formulario, listaPermisos, rolSeleccionado, page, valueSearch });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        getPermisosByModulo: (idModulo) => {
            dispatch({ type: 'GET_PERMISOS_BY_MODULO_REQUEST', idModulo});
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarRol);