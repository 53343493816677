import React, { Component } from 'react';
import { Row, Col, Table, Input, Button, Switch, Modal, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import moment from 'moment';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoNivelSeveridad from './ModalNuevoNivelSeveridad';
import ModalEditarNivelSeveridad from './ModalEditarNivelSeveridad';

const confirm = Modal.confirm;
const { Search } = Input;
class CatalogoTiemposDeGarantia extends Component { 
    componentDidMount(){
        this.props.onGetNivelesSeveridad(1, '');
        this.props.onGetlistaInfoProyecto();
    }

    state={
        page: 1,
        busqueda: '',
    }
    render() { 

        const {  onGetNivelesSeveridad, fetchingListaNivelesSeveridadBusqueda, listaNivelesSeveridadBusqueda,
            listaNivelesSeveridadBusquedaCantidad, onModalNuevoNivelSeveridad, onModalEditarNivelSeveridad,
            onEstadoNivelSeveridad,
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const onBuscarNivelSeveridad = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            onGetNivelesSeveridad(1, busqueda);
        };

        const columns = [{
                title: 'Nivel de Severidad',
                dataIndex: 'severidad',
                key: 'severidad',
                width: '15%',
            }, {
                title: 'Descripción',
                dataIndex: 'descripcion',
                key: 'descripcion',
                width: '30%',
            }, {
                title: 'Tiempo de Respuesta',
                dataIndex: 'tiempoRespuesta',
                key: 'tiempoRespuesta',
                width: '10%',
            }, {
                title: 'Unidad de medida',
                dataIndex: 'unidadMedida',
                key: 'unidadMedida',
                width: '10%',
            }, {
                title: 'Fecha alta',
                dataIndex: 'fechaAlta',
                key: 'fechaAlta',
                width: '15%',
            },  {
                title: 'Activo', 
                dataIndex: 'activo', 
                key: 'activo',
                width: '8%',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.nivelDeSeveridadActivoinactivo} onChange={() => handleDesactivar(record)}/>
                ),
            },{
                title: 'Editar',
                key: 'editar',
                width: '8%',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarNivelDeSeveridad} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },
        ];

        const handleDesactivar = (key) => {
            console.log(key);
            const page = this.state.page;
            const busqueda = this.state.busqueda;
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del nivel de severidad?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoNivelSeveridad(key.idNivelSeveridad, page, busqueda)
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }

        const onEditar = (key) => {
            key.rangoVigencia = [moment(key.fechaInicioVigencia, 'YYYY-MM-DD'),moment(key.fechaFinVigencia, 'YYYY-MM-DD')];
            console.log(key);
            onModalEditarNivelSeveridad(key, this.state.page, this.state.busqueda);
        }
        
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onGetNivelesSeveridad(pagination.current, this.state.busqueda);
        };


        const onNuevoNivelSeveridad = () => {
            onModalNuevoNivelSeveridad(this.state.page, this.state.busqueda);
        }


        return ( 
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search className='buscarModulos' placeholder="Buscar nivel de severidad o descripción" onSearch={value => onBuscarNivelSeveridad(value)} style={{ width: '100%' }}/> 
                    </Col>
                    {/* <Col span={7}>
                        <Button onClick={onNuevoNivelSeveridad} disabled={!permisosUsuario.crearNivelDeSeveridad} className='nuevoUsuario'>Nuevo Nivel de Severidad</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nuevo Nivel de Severidad' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoNivelSeveridad} disabled={!permisosUsuario.crearNivelDeSeveridad} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="idNivelSeveridad"
                            columns={columns}
                            dataSource={listaNivelesSeveridadBusqueda}
                            loading={fetchingListaNivelesSeveridadBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaNivelesSeveridadBusquedaCantidad, simple: true, current: this.state.page}}
                        />         
                    </Col>
                </Row>
                <ModalNuevoNivelSeveridad/>
                <ModalEditarNivelSeveridad/>
            </div>
         );
    }
}
 
const mapStateToProps = state => {
	return {
        fetchingListaNivelesSeveridadBusqueda:  state.MecanismosDePagosReducer.fetchingListaNivelesSeveridadBusqueda,
        listaNivelesSeveridadBusqueda: state.MecanismosDePagosReducer.listaNivelesSeveridadBusqueda,
        listaNivelesSeveridadBusquedaCantidad: state.MecanismosDePagosReducer.listaNivelesSeveridadBusquedaCantidad
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onGetNivelesSeveridad: (page, busqueda) => {
	    	dispatch({ type: "LISTA_NIVELES_SEVERIDAD_BUSQUEDA_REQUEST", page, busqueda });
        },
        onModalNuevoNivelSeveridad: (page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_NIVEL_SEVERIDAD", showModalNuevoNivelSeveridad: true, page, busqueda });
        },
        onModalEditarNivelSeveridad: (nivelSeveridadSeleccionado, page, busqueda) => {
            console.log(nivelSeveridadSeleccionado, page, busqueda)
            dispatch({ type: "SHOW_MODAL_EDITAR_NIVEL_SEVERIDAD", showModalEditarNivelSeveridad: true, nivelSeveridadSeleccionado, page, busqueda })
        },
        onGetlistaInfoProyecto: () => {
            dispatch({ type: "GET_INFOPROYECTO_REQUEST"})
        },
        onEstadoNivelSeveridad: (idNivelSeveridad, page, busqueda) => {
            dispatch({ type: "ESTADO_NIVEL_SEVERIDAD_REQUEST", idNivelSeveridad, page, busqueda });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoTiemposDeGarantia);