import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, FileExcelOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css'
import ModalNuevaCategoriaDeMedicion from './ModalNuevaCategoriaDeMedicion';
import ModalEditarCategoriaDeMedicion from './ModalEditarCategoriaDeMedicion';

const confirm = Modal.confirm;
const { Search } = Input;

class CatalogoCategoriaDeMedicion extends React.Component {
    state = {
        page: 1,
        busqueda: '',
    }

    componentDidMount() {
        this.props.onGetCategoriasDeMedicion(1);
    }

    render() {
        const { fetchingListaCategoriasDeMedicionBusqueda, listaCategoriasDeMedicionBusqueda, onGetCategoriasDeMedicion,
            onModalNuevaCategoriaDeMedida, onModalEditarCategoriaDeMedicion, onEstadoCategoriaDeMedicion, listaCategoriasDeMedicionBusquedaCantidad } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columns = [{
            title: 'Categoría de medición',
            dataIndex: 'categoriaUnidadMedida',
            key: 'categoriaUnidadMedida',
        }, {
            title: 'Clave categoría',
            dataIndex: 'claveCategoria',
            key: 'claveCategoria',
        },{
            title: 'Activo',
            dataIndex: 'activo',
            key: 'activo',
            align: 'center',
            render: (text, record) => (
                <Switch checked={text} disabled={!permisosUsuario.categoriaDeMedicionActivoInactivo || record.categoriaUnidadMedida==='Tiempo'} onChange = {() => handleDesactivar(record)} />
            ),
        }, {
            title: 'Editar',
            key: 'editar',
            align: 'center',
            //width: '6%',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.editarCategoriaDeMedicion || record.categoriaUnidadMedida==='Tiempo'}  onClick={() => onEditar(record)}><EditOutlined /></a>
            ),
        },
        ];

        const onBuscarCategoriasDeMedicion = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            });
            onGetCategoriasDeMedicion(1, busqueda);
        } 

        const handleDesactivar = (key) => {
            let page = this.state.page
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado de la categoría de medición "' + key.categoriaUnidadMedida + '"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoCategoriaDeMedicion(key.idCategoriaUnidadMedida, page);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }

        const onEditar = (key) => {
            onModalEditarCategoriaDeMedicion(key, this.state.page);

        }

        const onNuevoCategoriaDeMedida = () => {
            onModalNuevaCategoriaDeMedida(this.state.page);
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            onGetCategoriasDeMedicion(pagination.current, this.state.busqueda);
        }


        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={20}>
                        <Search className='buscarModulos' placeholder='Ingresar categoría de medición' onSearch={value => onBuscarCategoriasDeMedicion(value)} />
                    </Col>
                    {/* <Col span={9}>
                        <Button onClick={onNuevoCategoriaDeMedida} disabled={!permisosUsuario.crearCategoriaDeMedicion} className='nuevoUsuario'>Nueva Categoría de Medición</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nueva Categoría de Medición' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoCategoriaDeMedida} disabled={!permisosUsuario.crearCategoriaDeMedicion} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={1}>
                        <Tooltip title='Exportar archivo .xls' placement="topRight">
                            <Button disabled={!permisosUsuario.exportarExcelCatálogoCategoríasMedición} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } loading={this.props.fetchingExportarExcelCategoriasMedicion} onClick={() => this.props.onExport()}/>
                        </Tooltip>
                    </Col>

                    <Col span={24}>
                        <br />
                        <Table
                            size="small"
                            rowKey="categoriaUnidadMedida"
                            columns={columns}
                            dataSource={listaCategoriasDeMedicionBusqueda}
                            loading={fetchingListaCategoriasDeMedicionBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                        // scroll={scroll}
                        onChange={handleTableChange}
                        pagination={{ total: listaCategoriasDeMedicionBusquedaCantidad, simple: true, current: this.state.page }}
                        />
                    </Col>
                </Row>
                <ModalNuevaCategoriaDeMedicion/>
                <ModalEditarCategoriaDeMedicion/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        listaCategoriasDeMedicionBusqueda: state.AlmacenesReducer.listaCategoriasDeMedicionBusqueda,
        fetchingListaCategoriasDeMedicionBusqueda: state.AlmacenesReducer.fetchingListaCategoriasDeMedicionBusqueda,
        listaCategoriasDeMedicionBusquedaCantidad: state.AlmacenesReducer.listaCategoriasDeMedicionBusquedaCantidad,
        fetchingExportarExcelCategoriasMedicion: state.AlmacenesReducer.fetchingExportarExcelCategoriasMedicion,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetCategoriasDeMedicion: (page, busqueda) => {
            dispatch({ type: "CATEGORIAS_MEDICION_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
        },
        onEstadoCategoriaDeMedicion: (idCategoriaUnidadMedida, page) => {
            dispatch({ type: "ESTADO_CATEGORIA_MEDICION_REQUEST", idCategoriaUnidadMedida: idCategoriaUnidadMedida, page: page});
        },
        onModalNuevaCategoriaDeMedida: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVA_CATEGORIA_MEDICION", showModalNuevaCategoriaDeMedicion: true, page: page })
        },
        onModalEditarCategoriaDeMedicion: (CategoriaDeMedicionSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_CATEGORIA_MEDICION", showModalEditarCategoriaDeMedicion: true, CategoriaDeMedicionSeleccionado:CategoriaDeMedicionSeleccionado, page:page});
        },
        onExport: () => {
            dispatch({ type: "EXPORTAR_CATEGORIA_MEDICION_EXCEL_REQUEST" });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoCategoriaDeMedicion);