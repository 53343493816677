import React, { Component } from 'react';
import { Form, Input, Button, Modal, Row, Col, Spin, DatePicker, message, Upload, Typography } from 'antd';
import { UploadOutlined, FilePdfFilled } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import moment from 'moment';
import { connect } from "react-redux";

const { RangePicker } = DatePicker;
const { Dragger } = Upload;
const { Text, Paragraph } = Typography;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalNuevoRol extends Component {
    formRef = React.createRef();
    state={
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
        uploadOk: false,
    }
    render() {
        const { fetchingNuevoContrato, showModalNuevoContrato, onNuevoContrato, onShowModalNuevoContrato, page,
            limpiarFormulario, onLimpiarFormulario, listaInfoProyecto
        } = this.props;

        if(limpiarFormulario && limpiarFormulario === 'crearContrato'){
            console.log('entró a limpiar');
            this.formRef.current.resetFields();
            this.setState({
                filePDF: false,
                botonPDF: false,
                botonEditar: false,
                uploadOk: false,
            });
            onLimpiarFormulario();
        }

        const onFinish = formulario => {
            console.log('Success:', formulario);
            if(this.state.uploadOk){
                const rangeValue = formulario['rangoVigencia'];
                formulario.rangoVigencia = rangeValue[0].format('YYYY-MM-DD') + "," + rangeValue[1].format('YYYY-MM-DD');
                onNuevoContrato(formulario, page);
            }
            else{
                message.warning('Favor de seleccionar un contrato.');
            }
        };

        

        const handleCancel = () => {
            onShowModalNuevoContrato();
            this.setState({
                filePDF: false,
                botonPDF: false,
                botonEditar: false,
                uploadOk: false,
            });
            this.formRef.current.resetFields();
        }
        const onChangePDF = (info) => {
            console.log('info', info)
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                })
            }
            var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
            if (info.file.status === 'done') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true,
                        uploadOk: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                    
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true,
                        uploadOk: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }
        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
                uploadOk: false,
            });
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };
        const fechas = (rangeValue) => {
            if (rangeValue) {
                if(listaInfoProyecto.fechaInicio){
                    const fechaInicio = listaInfoProyecto.fechaInicio.diff(moment(rangeValue[0].format('YYYY-MM-DD')), "days");
                    const fechaVigencia = moment(listaInfoProyecto.fechaVigencia.split('-').reverse().join('-')).diff(moment(rangeValue[1].format('YYYY-MM-DD')), "days");
                    
                    if(fechaInicio>0){
                        message.error('No es posible seleccionar una fecha de inicio menor al inicio de contrato del proyecto', 7);
                        this.formRef.current.resetFields(['rangoVigencia']);
                    }
                    else if(fechaVigencia<0){
                        message.error('No es posible seleccionar una vigencia mayor al final de contrato del proyecto', 7);
                        this.formRef.current.resetFields(['rangoVigencia']);
                    }
                }
                else{
                    message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                    this.formRef.current.resetFields(['rangoVigencia']);
                }
            } else {
                message.warning ('Favor de seleccionar un rango de vigencia.')
            }
        }
        return (
            <Modal
            visible={showModalNuevoContrato}
            title='Nuevo Contrato'
            onCancel={handleCancel}
            footer={false}
            width={'70%'}
            >
                <Spin spinning={fetchingNuevoContrato}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Row justify='space-around' gutter={[8,8]}>
                            <Col span={8}>
                                <Form.Item
                                    label="Clave contrato"
                                    name="clave"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={30} placeholder="Ingresa la clave" pattern='^[-A-Z._0-9]+$' title='Sólo se admiten letras mayúsculas, números, punto o guiones'/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Descripción del contrato"
                                    name="descripcion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={150} placeholder="Ingresa la descripción" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Rango de vigencia"
                                    name="rangoVigencia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <RangePicker style={{width: '100%'}} onChange={fechas} format = 'DD-MM-YYYY' placeholder={["Fecha Inicio","Fecha Fin"]} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Contrato PDF"
                                    name="pdf"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                        {this.state.filePDF === false ? (
                                        <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                            <UploadOutlined /> Click para subir procedimiento PDF
                                        </Button>
                                        ):false}
                                    </Upload>
                                    {/* <Dragger customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false} style={{borderRadius:'1em'}} fileList={this.state.fileList}>
                                        <Row justify='center'>
                                            <FilePdfFilled className='iconTabPane'/>
                                            <Col span={24} style={{paddingTop:'1em'}}>
                                                <Paragraph>
                                                    <Text strong> Click o arrastra a ésta área el procedimiento PDF </Text>
                                                </Paragraph>
                                            </Col>
                                        </Row>
                                    </Dragger> */}
                                </Form.Item>
                            </Col>
                        
                            <Col span={8} className='marginBoton'>
                                <br/>
                                <Button disabled={this.state.botonEditar} className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Crear contrato</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingNuevoContrato: state.InformacionOperativaReducer.fetchingNuevoContrato,
        showModalNuevoContrato: state.InformacionOperativaReducer.showModalNuevoContrato,
        page: state.InformacionOperativaReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalNuevoContrato: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_CONTRATO', showModalNuevoContrato: false})
        },
        onNuevoContrato: ( formulario, page ) => {
            dispatch({ type: "NUEVO_CONTRATO_REQUEST" , formulario: formulario, page: page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoRol);