import React, { Component } from 'react';
import { Form, Button, Modal, Row, Col, Spin, Select, DatePicker, InputNumber, Switch, message } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalNuevoPonderacion extends Component {
    formRef = React.createRef();
    render() {
        const { fetchingNuevoPonderacion, showModalNuevoPonderacion, onNuevoPonderacion, onShowModalNuevoPonderacion,
            listaAreasFuncionalesActivos, fetchingListaAreasFuncionalesActivos, fetchingListaCategoriasDeFallasActivos, listaCategoriasDeFallasActivos,
            limpiarFormulario, onLimpiarFormulario, page, listaInfoProyecto
        } = this.props;

        if(limpiarFormulario && limpiarFormulario === 'crearPonderacion'){
            console.log('entró a limpiar');
            this.formRef.current.resetFields();
            onLimpiarFormulario();
        }

        const onFinish = formulario => {
            console.log('Success:', formulario);
            if(formulario['bandera1'] === undefined)
                formulario.bandera1 = false;
            if(formulario['bandera2'] === undefined)
                formulario.bandera2 = false;
            if(formulario['bandera3'] === undefined)
                formulario.bandera3 = false;

            const rangeValue = formulario['rangoVigencia'];
            formulario.rangoVigencia = rangeValue[0].format('YYYY-MM-DD') + "," + rangeValue[1].format('YYYY-MM-DD');
            onNuevoPonderacion(formulario, page, this.props.busqueda);
        };
        

        const handleCancel = () => {
            onShowModalNuevoPonderacion();
            this.formRef.current.resetFields();
        }
        const changeDecimales = (value) => {
            let valueDecimal = parseFloat(value).toFixed(4);
            if(isNaN(valueDecimal)){
                valueDecimal = 0.0000
            }
            this.formRef.current.setFieldsValue({'pesoArea': valueDecimal});
        }
        const fechas = (rangeValue) => {
            if(rangeValue[0]){
                if(listaInfoProyecto.fechaInicio){
                    const diferencia = listaInfoProyecto.fechaInicio.diff(moment(rangeValue[0].format('YYYY-MM-DD')), "days");
                    
                    if(diferencia>0){
                        message.error('No es posible seleccionar una fecha de inicio menor al inicio de contrato del proyecto', 7);
                        this.formRef.current.resetFields(['rangoVigencia']);
                    }
                }
                else{
                    message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                    this.formRef.current.resetFields(['rangoVigencia']);
                }
            }
        }
        
        return (
            <Modal
            visible={showModalNuevoPonderacion}
            title='Nueva Ponderación Área Funcional'
            onCancel={handleCancel}
            footer={false}
            width={'70%'}
            >
                {listaInfoProyecto ? (
                <Spin spinning={fetchingNuevoPonderacion}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Área funcional"
                                    name="idAreaFuncional"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el área funcional"
                                        loading={fetchingListaAreasFuncionalesActivos}
                                    >
                                        {listaAreasFuncionalesActivos.map(option => <Option key={option.idAreaFuncional}>{option.areaFuncional}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Peso de área"
                                    name="pesoArea"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber min={0.0001} max={99.9999} placeholder="Ingresa el peso de área"
                                        defaultValue={0.0001}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                        onChange={changeDecimales}
                                        step={0.0010} />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Tiempo de rehabilitación"
                                    name="idFallaCategoria"
                                    rules={[{ required: true, message: 'Favor de seleccionar un valor', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona un tiempo de rehabilitación"
                                        loading={fetchingListaCategoriasDeFallasActivos}
                                    >
                                        {listaCategoriasDeFallasActivos.map(option => <Option key={option.idFallaCategoria}>{ option.fallaCategoriaCompleta }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Turnos"
                                    name="turnos"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber min={0} max={4} style={{ width: '100%' }} placeholder="Ingresa el turno" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Row justify='space-between'>
                                    <Col span={17}>
                                        <Form.Item
                                            label="Factor 1"
                                            name="factor1"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <InputNumber min={1} max={100} placeholder="Ingresa el factor 1" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item
                                            label="Considerar factor 1"
                                            name="bandera1"
                                            valuePropName="checked"
                                        >
                                            <Switch />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={11}>
                                <Row justify='space-between'>
                                    <Col span={17}>
                                        <Form.Item
                                            label="Factor 2"
                                            name="factor2"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <InputNumber min={1} max={100} placeholder="Ingresa el factor 2"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item
                                            label="Considerar factor 2"
                                            name="bandera2"
                                            valuePropName="checked"
                                        >
                                            <Switch />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={11}>
                                <Row justify='space-between'>
                                    <Col span={17}>
                                        <Form.Item
                                            label="Factor 3"
                                            name="factor3"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <InputNumber min={1} max={100} placeholder="Ingresa el factor 3" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item
                                            label="Considerar factor 3"
                                            name="bandera3"
                                            valuePropName="checked"
                                        >
                                            <Switch />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Cantidad"
                                    name="cantidad"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber min={1} max={100} placeholder="Ingresa la cantidad" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Fechas vigencia"
                                    name="rangoVigencia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <RangePicker
                                        style={{width: '100%'}} format='DD-MM-YYYY' onChange={fechas} placeholder={["Ingresa fecha inicio","Fecha fin"]}
                                        defaultValue={[, moment(listaInfoProyecto.fechaVigencia, 'DD-MM-YYYY')]}
                                        disabled={[false, true]}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Crear Ponderación Área</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
                ):<h1>Favor de agregar un contrato en 'Información del proyecto'</h1>}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingNuevoPonderacion: state.CatalogosGeneralesReducer.fetchingNuevoPonderacion,
        showModalNuevoPonderacion: state.CatalogosGeneralesReducer.showModalNuevoPonderacion,
        fetchingListaAreasFuncionalesActivos: state.CatalogosGeneralesReducer.fetchingListaAreasFuncionalesActivos,
        listaAreasFuncionalesActivos: state.CatalogosGeneralesReducer.listaAreasFuncionalesActivos,
        fetchingListaCategoriasDeFallasActivos: state.CatalogosGeneralesReducer.fetchingListaCategoriasDeFallasActivos,
        listaCategoriasDeFallasActivos: state.CatalogosGeneralesReducer.listaCategoriasDeFallasActivos,
        page: state.CatalogosGeneralesReducer.page,
        busqueda: state.CatalogosGeneralesReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalNuevoPonderacion: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_PONDERACION', showModalNuevoPonderacion: false, page: null })
        },
        onNuevoPonderacion: ( formulario, page, busqueda ) => {
            dispatch({ type: "NUEVO_PONDERACION_REQUEST" , formulario: formulario, page, busqueda });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoPonderacion);