import React from 'react';
import {connect} from 'react-redux';
import {Form, Row, Col, Button, Modal, Spin, Input, Select, Tooltip} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Option } = Select;

class ModalNuevoTema extends React.Component {
    formRef = React.createRef();
    render() {
        const {showModalNuevoTema, fetchingNuevoTema, page, limpiarFormulario, busqueda,
            onShowModalTema, onCrearTema, fetchingListaCentroDeCostosActivos, listaCentroDeCostosActivos
        } = this.props;
        
        if (limpiarFormulario && limpiarFormulario === 'crearTema') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        const onFinish = (formulario) => {
            onCrearTema(formulario, page, busqueda);        
        }

        const handleCancel = () => {
            onShowModalTema(page, busqueda);
            this.formRef.current.resetFields();
        }

        return (
            <div>
                <Modal
                    visible={showModalNuevoTema}
                    title='Nuevo Tema'
                    onCancel={handleCancel}
                    footer={false}
                    width='50%'
                >
                    <Spin spinning={fetchingNuevoTema}>
                        <Form {...layout}
                            name='formulario'
                            onFinish={onFinish}
                            ref={this.formRef}
                        >
                            <Row type='flex' justify='space-around'>
                                <Col span={11}>
                                    <Form.Item
                                        label='Nombre del tema'
                                        name='tema'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                        <Input maxLength={100} placeholder='Ingresa el nombre del tema' style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                
                                <Col span={11}>
                                    <Form.Item
                                        label='Centro de costos'
                                        name='idCentroCosto'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el centro de costo"
                                            loading={fetchingListaCentroDeCostosActivos}
                                        >
                                            {listaCentroDeCostosActivos.map(option => 
                                            <Option key={option.idCentroCosto}>
                                                <Tooltip title={option.centroCosto}>
                                                    {option.centroCosto}
                                                </Tooltip>
                                            </Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={11} className='marginBoton'>
                                    <br/>
                                    <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Crear Tema</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalNuevoTema: state.EvaluacionDeLaGestionReducer.showModalNuevoTema,
        fetchingNuevoTema: state.EvaluacionDeLaGestionReducer.fetchingNuevoTema,
        page: state.EvaluacionDeLaGestionReducer.page,
        busqueda: state.EvaluacionDeLaGestionReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaCentroDeCostosActivos: state.CatalogosGeneralesReducer.fetchingListaCentroDeCostosActivos,
        listaCentroDeCostosActivos: state.CatalogosGeneralesReducer.listaCentroDeCostosActivos,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalTema: (page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_TEMA", showModalNuevoTema: false, page, busqueda});
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
        },
        onCrearTema: (formulario, page, busqueda) => {
            dispatch({ type: 'NUEVO_TEMA_REQUEST', formulario, page, busqueda})
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalNuevoTema);