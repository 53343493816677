import React from 'react';
import { connect } from 'react-redux';
import { Table, Col, Switch, Modal, Divider, Row, Spin, Radio } from 'antd';
import { EditOutlined, FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalEditar from './ModalEditarRegistro';
import moment from 'moment';

const { confirm } = Modal;

class RegistroReportesContainer extends React.Component {
     state = {
          page: 1,
          busqueda: null,
          personal: false
     }

     componentDidMount () {
          this.props.onListaRegistrosReporte(1); 
          this.props.onListaRegistrosEspecialReporte(1); 
          this.props.onGetServiciosActivos(); 
          this.props.onGetListaModulosByIdUsuario(); 
          this.props.onGetPartidasActivas(); 
     }

     render () {
          const {onListaRegistrosReporte, onListaRegistrosEspecialReporte, onEstadoRegistroReporte,
               onModalEditarRegistroReporte, fetchingExportarReportesDinamico, 
          } = this.props;

               
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if (permisosUsuario[0]) {
               permisosUsuario = JSON.parse(permisosUsuario);
          }

          const columns = [{
               title: 'Título del documento',
               dataIndex: 'titulo',
               key: 'titulo',
               width: '15%',
          },{
               title: 'Nombre',
               dataIndex: 'nombre',
               key: 'nombre',
               width: '20%',
          },{
               title: 'Descripción',
               dataIndex: 'descripcion',
               key: 'descripcion',
               width: '30%',
          },{ 
               title: 'Usuario',
               dataIndex: 'usuario',
               key: 'usuario',
               width: '15%',
          },{
               title: 'Editar',
               key: 'editar',
               width: '5%',
               align: 'center',
               render: (text, record) => (
                    <a className='editarIcono' disabled={this.state.personal ? true : !record.activo}   onClick={() => onEditar(record)}  ><EditOutlined /></a>
               ),
          }, {
               title: 'Exportar',
               key: 'exportar',
               width: '10%',
               align: 'center',
               render: (text, record) => (
                    <Row justify='space-around'>
                         <Col span={6}>
                         <a className='editarIcono' disabled={!permisosUsuario.exportarPdfReporteDinamicoRecursosHumanos ? false : !record.activo} onClick={() => onExportar(record, 'pdf')}><FilePdfOutlined /></a>
                         </Col>
                         <Col span={6}>
                         <a className='editarIcono' disabled={!permisosUsuario.exportarExcelReporteDinamicoRecursosHumanos ? false : !record.activo} onClick={() => onExportar(record, 'excel')}><FileExcelOutlined /></a>
                         </Col>
                    </Row>
               ),
          }, {
               title: 'Activo',
               dataIndex: 'activo',
               key: 'activo',
               width: '5%',
                    align: 'center',
               render: (text, record) => (
                    <Switch checked={text} disabled={false} onChange = {() => handleDesactivar(record)} />
               ),
          }];

          const onExportar = (value, tipo) => {
               console.log(`value, tipo`, value, tipo);
               let order = { order: value.order, field: value.field }

               if(value.formulario.horarioEntrada){
                    value.formulario.horarioEntrada = value.formulario.horarioEntrada[0];
               }

               if(value.formulario.horarioSalida){
                    value.formulario.horarioSalida = value.formulario.horarioSalida[0];
               }

               if(value.formulario.regresoComida){
                    value.formulario.regresoComida = value.formulario.regresoComida[0];
               }

               if(value.formulario.salidaComida){
                    value.formulario.salidaComida = value.formulario.salidaComida[0];
               }

               if(value.tipoAgrupado === 'SinAgrupar'){
                    if(tipo === 'pdf'){
                         this.props.onDescargarReportePDF(value.formulario, value.checkbox, order, value.titulo);
                    }
                    else{
                         this.props.onDescargarReporteExcel(value.formulario, value.checkbox, order, value.titulo);
                    }
               } else {
                    if(tipo === 'pdf'){
                         this.props.onDescargarReporteAgrupadoPDF(value.formulario, value.checkbox, value.tipoAgrupado, order, value.titulo);
                    }
                    else{
                         this.props.onDescargarReporteAgrupadoExcel(value.formulario, value.checkbox, value.tipoAgrupado, order, value.titulo);
                    }
               }
              
          }

          const handleDesactivar = (key) => {
               console.log('key', key)

               let page = this.state.page;
               let personal = this.state.personal;
               confirm({
                    title: '¿Estás seguro que deseas cambiar el estado del reporte predeterminado "' + key.nombre + '"?',
                    content: '',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                         onEstadoRegistroReporte(key.idReporteBitacora, page, personal);
                    },
                    onCancel() {
                         console.log('Cancel');
                    },
               });
          }
        
          const onEditar = (registroReporteSeleccionado) => {
               const idsModulos = [];
               // const idsTiposBitacoras = [];
               const idsServicios = [];
               const idsPartidas = [];
               
               if(registroReporteSeleccionado.formulario.idsModulos){
                    for(let i in this.props.listaModulosByIdUsuario){
                         for(let j in registroReporteSeleccionado.formulario.idsModulos){
                              if(this.props.listaModulosByIdUsuario[i].idModulo === parseInt(registroReporteSeleccionado.formulario.idsModulos[j])){
                                   idsModulos.push(this.props.listaModulosByIdUsuario[i].idModulo + ' - ' + this.props.listaModulosByIdUsuario[i].nombre);
                              }
                         }
                    }
                    registroReporteSeleccionado.idsModulos = idsModulos;
                    
               }

               // if(registroReporteSeleccionado.formulario.idsTiposBitacoras){
               //      for(let i in this.props.listaTipoBitacoraByIdModulo){
               //           for(let j in registroReporteSeleccionado.formulario.idsTiposBitacoras){
               //                if(this.props.listaTipoBitacoraByIdModulo[i].idTipoBitacora === parseInt(registroReporteSeleccionado.formulario.idsTiposBitacoras[j])){
               //                     idsTiposBitacoras.push(this.props.listaTipoBitacoraByIdModulo[i].idTipoBitacora + ' - ' +  this.props.listaTipoBitacoraByIdModulo[i].tipoBitacora);
               //                }
               //           }
               //      }
               //      registroReporteSeleccionado.idsTiposBitacoras = idsTiposBitacoras;
               // }

               if(registroReporteSeleccionado.formulario.idsPartidas){
                    for(let i in this.props.listaPartidasActivas){
                         for(let j in registroReporteSeleccionado.formulario.idsPartidas){
                              if(this.props.listaPartidasActivas[i].idPartida === parseInt(registroReporteSeleccionado.formulario.idsPartidas[j])){
                                   idsPartidas.push(this.props.listaPartidasActivas[i].idPartida + ' - ' + this.props.listaPartidasActivas[i].nombre);
                              }
                         }
                    }
                    registroReporteSeleccionado.idsPartidas = idsPartidas;
               }

               if(registroReporteSeleccionado.formulario.idsServicios){
                    for(let i in this.props.listaServiciosActivosF){
                         for(let j in registroReporteSeleccionado.formulario.idsServicios){
                              if(this.props.listaServiciosActivosF[i].idServicio === parseInt(registroReporteSeleccionado.formulario.idsServicios[j])){
                                   idsServicios.push(this.props.listaServiciosActivosF[i].idServicio + ' - ' + this.props.listaServiciosActivosF[i].nombre);
                              }
                         }
                    }
                    registroReporteSeleccionado.idsServicios = idsServicios;
               }

               registroReporteSeleccionado.fecha = registroReporteSeleccionado.formulario.fechaFin ? [moment(registroReporteSeleccionado.formulario.fechaInicio, 'YYYY-MM-DD'), moment(registroReporteSeleccionado.formulario.fechaFin, 'YYYY-MM-DD')] : undefined;

               console.log('registroReporteSeleccionado final', registroReporteSeleccionado);

               if(registroReporteSeleccionado.formulario.idsModulos){
                    this.props.onGetListaTipoBitacoraByIdsModulos(idsModulos, registroReporteSeleccionado, this.state.page, this.state.personal);
               } else {
                    onModalEditarRegistroReporte(registroReporteSeleccionado, this.state.page, this.state.personal); 
               }
               // onModalEditarRegistroReporte();
          }

          const handleTableChange = (pagination) => {
               this.setState({
                    page: pagination.current,
               });
               if(!this.state.personal){
                    onListaRegistrosEspecialReporte(pagination.current);
               }
               else{
                    onListaRegistrosReporte(pagination.current);
               }
          }
     
          const cambiarTablaRegistros = (value) => {
               console.log(`value`, value)
               if(value.target.value === 'Todos'){
                    this.setState({
                         page: 1,
                         personal: true
                    });
                    
                    onListaRegistrosReporte(1)
               }
               else{
                    this.setState({
                         page: 1,
                         personal: false
                    });
                    onListaRegistrosEspecialReporte(1)
               }
          }

          return (
               <div>
                    <Divider orientation='left'>Registros Guardados</Divider>
                         <Row justify='end' style={{padding:'1em'}}>
                              <Radio.Group defaultValue='Personal' onChange={cambiarTablaRegistros}  size="small">
                                   {permisosUsuario.especialRecursosHumanos ? (
                                   <Radio.Button value="Todos">Todos</Radio.Button>
                                   ):false}
                                   <Radio.Button value="Personal">Personal</Radio.Button>
                              </Radio.Group>
                         </Row>
                    <Spin spinning={fetchingExportarReportesDinamico || this.props.fetchingReporteDinamicoEXCEL || this.props.fetchingReporteDinamicoPDF || this.props.fetchingReporteDinamicoAgrupadoEXCEL || this.props.fetchingReporteDinamicoAgrupadoPDF || this.props.fetchingEstadoRegistros}>
                         <Col span={24}>
                              <Table
                                   size='small'
                                   rowKey='idReporteEvaluacion'
                                   columns={columns}
                                   dataSource={this.state.personal ? this.props.allRegistros : this.props.registrosByToken }
                                   loading={this.state.personal ? this.props.fetchingGetAllRegistros : this.props.fetchingGetRegistrosByToken }
                                   locale={{ emptyText: 'Sin datos' }}
                                   onChange={handleTableChange}
                                   pagination={{ total: this.state.personal ? this.props.allRegistrosCantidad : this.props.registrosByTokenCantidad, simple: true, current: this.state.page }}
                              />
                         </Col>
                    </Spin>
                    <ModalEditar />
               </div>
          )
     }
}

const mapStateToProps = state => {
     return {
          allRegistros: state.ConfiguracionYSeguridadReducer.allRegistros,
          allRegistrosCantidad: state.ConfiguracionYSeguridadReducer.allRegistrosCantidad,
          fetchingGetAllRegistros: state.ConfiguracionYSeguridadReducer.fetchingGetAllRegistros,

          registrosByToken: state.ConfiguracionYSeguridadReducer.registrosByToken,
          registrosByTokenCantidad: state.ConfiguracionYSeguridadReducer.registrosByTokenCantidad,
          fetchingGetRegistrosByToken: state.ConfiguracionYSeguridadReducer.fetchingGetRegistrosByToken,

          fetchingEstadoRegistros: state.ConfiguracionYSeguridadReducer.fetchingEstadoRegistros,
          fetchingExportarReportesDinamico: state.ConfiguracionYSeguridadReducer.fetchingExportarReportesDinamico,

          listaModulosByIdUsuario: state.ConfiguracionYSeguridadReducer.listaModulosByIdUsuario,
          fetchingListaModulosByIdUsuario: state.ConfiguracionYSeguridadReducer.fetchingListaModulosByIdUsuario,
          listaTipoBitacoraByIdModulo: state.ConfiguracionYSeguridadReducer.listaTipoBitacoraByIdModulo,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          listaPartidasActivas: state.ConfiguracionYSeguridadReducer.listaPartidasActivas,

          fetchingReporteDinamicoEXCEL: state.ConfiguracionYSeguridadReducer.fetchingReporteDinamicoEXCEL,
          fetchingReporteDinamicoPDF: state.ConfiguracionYSeguridadReducer.fetchingReporteDinamicoPDF,
          fetchingReporteDinamicoAgrupadoEXCEL: state.ConfiguracionYSeguridadReducer.fetchingReporteDinamicoAgrupadoEXCEL,
          fetchingReporteDinamicoAgrupadoPDF: state.ConfiguracionYSeguridadReducer.fetchingReporteDinamicoAgrupadoPDF,
     }
}

const mapDispatchToProps = dispatch => {
     return {
          onListaRegistrosReporte: (page) => {
               dispatch({ type: 'GET_ALL_REGISTROS_BITACORA_REQUEST', page })
          },
          onListaRegistrosEspecialReporte: (page) => {
               dispatch({ type: 'GET_REGISTROS_BY_TOKEN_BITACORA_REQUEST', page })
          },
          onEstadoRegistroReporte: (idReporte, page, personal) => {
               dispatch({ type: "ESTADO_REGISTROS_BITACORA_REQUEST", idReporte, page, personal });
          },
          onModalEditarRegistroReporte: (registroReporteSeleccionado, page, personal) => {
               dispatch({ type: "MODAL_EDITAR_REGISTRO_BITACORA", showModalEditarRegistro: true, registroReporteSeleccionado, page, personal });
          },
          onDescargarReportePDF: (formulario, checkboxData, order, titulo) => {
               dispatch({ type: 'PDF_REPORTE_DINAMICO_BITACORA_REQUEST', formulario, checkboxData, order, titulo, containerRegistros: true});
          },
          onDescargarReporteExcel: (formulario, checkboxData, order, titulo) => {
               dispatch({ type: 'EXCEL_REPORTE_DINAMICO_BITACORA_REQUEST', formulario,checkboxData, order, titulo, containerRegistros: true});
          },
          onDescargarReporteAgrupadoPDF: (formulario, checkboxData, agrupado, order, titulo) => {
               dispatch({ type: 'PDF_REPORTE_DINAMICO_BITACORA_AGRUPADO_REQUEST', formulario, checkboxData, agrupado, order, titulo, containerRegistros: true });
          },
          onDescargarReporteAgrupadoExcel: (formulario, checkboxData, agrupado, order, titulo) => {
               dispatch({ type: 'EXCEL_REPORTE_DINAMICO_BITACORA_AGRUPADO_REQUEST', formulario, checkboxData, agrupado, order, titulo, containerRegistros: true });
          },
          onGetServiciosActivos: () => {
               dispatch({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST'})
          },
          onGetListaTipoBitacoraByIdsModulos: (idsModulos, registroReporteSeleccionado, page, personal) => {
               dispatch({ type: 'LISTA_TIPO_BITACORAS_BY_ID_MODULO_REQUEST', idsModulos, registroReporteSeleccionado, page, personal });
          },
          onGetListaModulosByIdUsuario: () => {
               dispatch({ type: 'GET_MODULOS_BY_IDUSUARIO_REQUEST', idUsuario: null });
          },
          onGetPartidasActivas: () => {
               dispatch({ type: 'LISTA_PARTIDAS_ACTIVAS_REQUEST' });
          },
     }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistroReportesContainer);