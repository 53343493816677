import React from 'react';
import { Form, Card, Spin, Row, Col, Result, Tabs, Select, Table, Tooltip, Button, DatePicker, Typography, Modal, Switch, Descriptions, Divider, Empty } from 'antd';
import { EyeOutlined, FileExcelOutlined, SelectOutlined, FilePdfOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import moment from 'moment';

const layout = { labelCol: { span: 24 },  wrapperCol: { span: 24 } };
const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text, Title } = Typography;

class ReporteDeductivasContainer extends React.Component {
     formRef = React.createRef();
     componentDidMount(){ 
          this.props.onGetMeses(null);
          this.props.onGetResumen('Externa', null);

          if(this.props.listaInfoProyecto.idProyecto){
               this.props.onGetListaAñosCalendario(this.props.listaInfoProyecto);
          } else{  this.props.onRequestInfoProyecto() }

          if(this.props.listaAñosCalendario[0]){
               this.props.onGetMeses(this.props.listaAñosCalendario[0]);
          }
      }

     state={ primera: true, page: 1, current: '1', tipoDeductiva: 'Externa', idMesCalendario: null, mes: '', anio: '', visible: false, dateString: [], disabledSelect: true, tipoConcentrado: '', disabledButtonSelected: true, visibleConcentrado:  false}
     handleCancel = () => { this.setState({ visible: false, page: 1 }) };
     handleCancelConcentrado = () => { this.setState({ visibleConcentrado: false, page: 1 }) };
 
     render() {
          const { listaAñosCalendario, listaMesesProcesados, resumenReporteDeductivas, concentradoAnualResumen, concentrado, onGetDetalleConcentrado } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){ 
               permisosUsuario = JSON.parse(permisosUsuario); 
               if(this.state.primera){
                    let currentPermiso = permisosUsuario.verDeductivasPorFallasEnElServicio ? '1' : permisosUsuario.verDeductivasInternas ? '2' : '3'
                    this.setState({
                         current: currentPermiso,
                         primera: false
                    })
               }
          }

          const onFinish = (formulario) => {
               console.log('formulario', formulario)
          };

          const handleClick = (e) => { 
               //ALGO PARA EL COMMIT
               console.log('e', e); 
               if(e){ 
                    this.setState({ current: e });

                    if(e === '1'){
                         this.props.onGetResumen('Externa', this.state.idMesCalendario);
                         this.setState({ tipoDeductiva: 'Externa' });
                    } else if(e === '2') {
                         this.props.onGetResumen('Interna', this.state.idMesCalendario);
                         this.setState({ tipoDeductiva: 'Interna' });
                    }
               } 
          };

          const handleTableChange = (pagination) => {
               this.setState({ page: pagination.current });
          };

          const opcRangeDate = (
               <Row justify='end' gutter={[8,8]}>
                    <Col span={12}>
                         <Form.Item name='rangeYear' >
                              <RangePicker placeholder={["Fecha inicial","Fecha final"]}  style={{width:'100%'}}/>
                         </Form.Item>
                    </Col>

                    <Col span={12}>
                         <Form.Item name='ticketsOption' >
                              <Select style={{ width: '100%' }} placeholder="Selecciona una opción">
                                   <Option>Tickets con deductivas internas</Option>
                                   <Option>Tickets con deductivas por fallas en la prestación del servicio</Option>
                              </Select>
                         </Form.Item>
                    </Col>
               </Row>
          );

          const onAñoCalendario = (value) => {
               this.formRef.current.setFieldsValue({ 'anio': value });
               this.props.onGetMeses(value);
          };

          const onChangeMes = (value) => {
               console.log('value', value)
               let idMesCalendario =  value.split('-')[0];
               let mes = value.split('-')[1];
               let anio = value.split('-')[2];
               this.props.onGetResumen(this.state.tipoDeductiva, idMesCalendario);
               this.setState({ idMesCalendario, mes, anio });
          };

          const onShowDetalle = () => { 
               this.setState({ visible: true });
               this.props.onGetDetalle(this.state.tipoDeductiva, this.state.idMesCalendario); 
          };

          const onDescarga = () => {
               this.props.onExportResumen(this.state.tipoDeductiva, this.state.idMesCalendario, this.state.mes, this.state.anio);
          };

          const onDesgargaDetalle = (type) => {
               console.log('type', type)
               if(type === 'excel'){
                    this.props.onExportDetalle(this.state.tipoDeductiva, this.state.idMesCalendario, this.state.mes, this.state.anio);
               } else if(type === 'pdf'){
                    this.props.onExportDetallePDF(this.state.tipoDeductiva, this.state.idMesCalendario, this.state.mes, this.state.anio);
               }
          };

          const columns = [
               { title: 'Ticket', dataIndex: 'ticket', key: 'ticket', align: 'center',  width: '2%' },
               // { title: 'Tipo ticket', dataIndex: 'tipoTicket', key: 'tipoTicket', align: 'center', width: "3%"  },
               { title: <Tooltip title='Persona que reportó la falla '>Usuario reportó</Tooltip>, dataIndex: 'origen', key: 'origen',  align: 'center', width: "5%"  },
               // { title: <Tooltip title='Persona que capturó la Solicitud de Servicio'>Personal CCO</Tooltip>, dataIndex: 'personalCCO', key: 'personalCCO', align: 'center', width: "5%" },
               { title: <Tooltip title='Nombre del servicio al que corresponde la falla'>Servicio</Tooltip>, dataIndex: 'nombreServicio', key: 'nombreServicio', align: 'center', width: "8%" },
               { title: <Tooltip title='Clave del área en la que ocurrió la falla'>Clave área</Tooltip>, dataIndex: 'claveArea', key: 'claveArea', align: 'center', width: "2%" },
               { title: <Tooltip title='Nombre del área donde ocurrió la falla '>Nombre área</Tooltip>, dataIndex: 'areaFuncional', key: 'areaFuncional',  align: 'center', width: "8%" },
               { title: 'Código área', key: 'codigoArea', dataIndex: 'codigoArea', align: 'center', width:'2%' },
               { title: <Tooltip title='Tipo de falla, según lo capturado por CCO'>Tipo falla</Tooltip>, dataIndex: 'tipoFalla', key: 'tipoFalla', align: 'center', width: "6%" },
               { title: <Tooltip title='Descripción de la falla capturada por el CCO'>Descripción falla</Tooltip>, dataIndex: 'descripcionSolicitud', key: 'descripcionSolicitud', align: 'center', width: "18%" },
               { title: <Tooltip title='Fecha y hora de creación de la Solicitud de Servicio'>Fecha creación</Tooltip>, dataIndex: 'fechaAltaTabla', key: 'fechaAltaTabla', align: 'center', width: "3%"  },
               { title: <Tooltip title='Fecha y hora de cierre de la Solicitud de Servicio'>Fecha cierre</Tooltip>, dataIndex: 'fechaCierreTabla', key: 'fechaCierreTabla', align: 'center', width: "3%"  },
               { title: <Tooltip title='Nombre de la categoría a la que corresponde la ubicación de la falla'>Categoría área</Tooltip>, dataIndex: 'numeroFallaCategoriaTabla', key: 'numeroFallaCategoriaTabla', align: 'center', width: "2%" },
               { title: 'Clave estándar', dataIndex: 'referenciaEstandarTabla', key: 'referenciaEstandarTabla', align: 'center', width: "2%" },
               { title: 'Descripción estándar', dataIndex: 'descripcionEstandarTabla', key: 'descripcionEstandarTabla', align: 'center', width: "13%" },
               { title: 'Categoría calidad', dataIndex: 'fallaCalidadTabla', key: 'fallaCalidadTabla', align: 'center', width: "3%" },
               { title: 'Categoría disponibilidad', dataIndex: 'fallaDisponibilidadTabla', key: 'fallaDisponibilidadTabla', align: 'center', width: "10%" },
               { title: <Tooltip title='Valor del Pago Mensual de Inversión'>PMI</Tooltip>, dataIndex: 'pmiTabla', key: 'pmiTabla', align: 'right', width: "3%" },
               { title: <Tooltip title='Valor del Pago Mensual de Referencia'>PMR</Tooltip>, dataIndex: 'pmrTabla', key: 'pmrTabla', align: 'right', width: "3%" },
               { title: <Tooltip title='Valor de los días el mes en que se da el servicio para el mes i '>dm</Tooltip>, dataIndex: 'dmi', key: 'dmi', align: 'center', width: "2%" },
               { title: <Tooltip title='Valor del peso del de área funcional'>psa</Tooltip>, dataIndex: 'psaTabla', key: 'psaTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor para el factor de seguridad'>fsa</Tooltip>, dataIndex: 'fsaTabla', key: 'fsaTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor para el inpc de la fecha de inicio de operaciones'>INPC0</Tooltip>, dataIndex: 'inpc0', key: 'inpc0', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor del INPC para el mes i-1'>INPCi-1</Tooltip>, dataIndex: 'inpcCalculo', key: 'inpcCalculo', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor del número de turnos existentes para el área funcional'>ut</Tooltip>, dataIndex: 'utTabla', key: 'utTabla', align: 'center', width: "2%" },
               { title: <Tooltip title='Valor del número de turnos en los que ocurre la falla'>utf</Tooltip>, dataIndex: 'utfTabla', key: 'utfTabla', align: 'center', width: "2%" },
               { title: <Tooltip title='Valor del porcentaje de dedución por fallas de disponibilidad'>pdfd</Tooltip>, dataIndex: 'pdfdTabla', key: 'pdfdTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor del ponderador de servicios'>ps</Tooltip>, dataIndex: 'psTabla', key: 'psTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor del ponderador de categoría de falla de calidad'>pcfc</Tooltip>, dataIndex: 'pcfcTabla', key: 'pcfcTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor para el ponderador de servicio por área'>pss_x_a</Tooltip>, dataIndex: 'ponderacionServAreaTabla', key: 'ponderacionServAreaTabla', align: 'center', width: "2%" },
               { title: 'Deductiva', dataIndex: 'deductivaTabla', key: 'deductivaTabla', align: 'right', width: "3.5%" },
               { title: 'Deductiva parcial', dataIndex: 'deductivaParcialTexto', key: 'deductivaParcialTexto', align: 'center', width: "2%" },
               // { title: 'Conciliada', dataIndex: 'conciliada', key: 'conciliada', align: 'center', width: "2%",
               // render: (text) => (
               //     <Switch checked={text} disabled={true}/>
               // )},
               // { title: 'Fecha cierre mes', dataIndex: 'fechaCierreMesTabla', key: 'fechaCierreMesTabla', align: 'center', width: "3%" },
               { title: 'Ticket reiterado', dataIndex: 'ticketReiteradoTabla', key: 'ticketReiteradoTabla', align: 'center', width: "2%" },
               { title: 'Número referencia', dataIndex: 'numReferenciaTabla', key: 'numReferenciaTabla', align: 'center', width: "2%" },
               // { title: 'Tiempo garantía', dataIndex: 'tiempoGarantiaTabla', key: 'tiempoGarantiaTabla', align: 'center', width: "2.5%" },
          ];

          const columnsProv = [
               { title: 'Ticket', dataIndex: 'ticket', key: 'ticket', align: 'center',  width: '2%' },
               // { title: 'Tipo ticket', dataIndex: 'tipoTicket', key: 'tipoTicket', align: 'center', width: "3%"  },
               { title: <Tooltip title='Persona que reportó la falla '>Usuario reportó</Tooltip>, dataIndex: 'origen', key: 'origen',  align: 'center', width: "5%"  },
               // { title: <Tooltip title='Persona que capturó la Solicitud de Servicio'>Personal CCO</Tooltip>, dataIndex: 'personalCCO', key: 'personalCCO', align: 'center', width: "5%" },
               { title: <Tooltip title='Nombre del servicio al que corresponde la falla'>Servicio</Tooltip>, dataIndex: 'nombreServicio', key: 'nombreServicio', align: 'center', width: "8%" },
               { title: <Tooltip title='Nombre del proveedor que brinda el servicio'>Proveedor</Tooltip>, dataIndex:'nombreProveedor', key:'nombreProveedor', align: 'center', width: "5%" },
               { title: <Tooltip title='Clave del área en la que ocurrió la falla'>Clave área</Tooltip>, dataIndex: 'claveArea', key: 'claveArea', align: 'center', width: "2%" },
               { title: <Tooltip title='Nombre del área donde ocurrió la falla '>Nombre área</Tooltip>, dataIndex: 'areaFuncional', key: 'areaFuncional',  align: 'center', width: "8%" },
               { title: 'Código área', key: 'codigoArea', dataIndex: 'codigoArea', align: 'center', width:'2%' },
               { title: <Tooltip title='Tipo de falla, según lo capturado por CCO'>Tipo falla</Tooltip>, dataIndex: 'tipoFalla', key: 'tipoFalla', align: 'center', width: "6%" },
               { title: <Tooltip title='Descripción de la falla capturada por el CCO'>Descripción falla</Tooltip>, dataIndex: 'descripcionSolicitud', key: 'descripcionSolicitud', align: 'center', width: "18%" },
               { title: <Tooltip title='Fecha y hora de creación de la Solicitud de Servicio'>Fecha creación</Tooltip>, dataIndex: 'fechaAltaTabla', key: 'fechaAltaTabla', align: 'center', width: "3%"  },
               { title: <Tooltip title='Fecha y hora de cierre de la Solicitud de Servicio'>Fecha cierre</Tooltip>, dataIndex: 'fechaCierreTabla', key: 'fechaCierreTabla', align: 'center', width: "3%"  },
               { title: <Tooltip title='Nombre de la categoría a la que corresponde la ubicación de la falla'>Categoría área</Tooltip>, dataIndex: 'numeroFallaCategoriaTabla', key: 'numeroFallaCategoriaTabla', align: 'center', width: "2%" },
               { title: 'Clave estándar', dataIndex: 'referenciaEstandarTabla', key: 'referenciaEstandarTabla', align: 'center', width: "2%" },
               { title: 'Descripción estándar', dataIndex: 'descripcionEstandarTabla', key: 'descripcionEstandarTabla', align: 'center', width: "13%" },
               { title: 'Categoría calidad', dataIndex: 'fallaCalidadTabla', key: 'fallaCalidadTabla', align: 'center', width: "3%" },
               { title: 'Categoría disponibilidad', dataIndex: 'fallaDisponibilidadTabla', key: 'fallaDisponibilidadTabla', align: 'center', width: "10%" },
               { title: <Tooltip title='Valor del Pago Mensual de Inversión'>PMI</Tooltip>, dataIndex: 'pmiTabla', key: 'pmiTabla', align: 'right', width: "3%" },
               { title: <Tooltip title='Valor del Pago Mensual de Referencia'>PMR</Tooltip>, dataIndex: 'pmrTabla', key: 'pmrTabla', align: 'right', width: "3%" },
               { title: <Tooltip title='Valor de los días el mes en que se da el servicio para el mes i '>dm</Tooltip>, dataIndex: 'dmi', key: 'dmi', align: 'center', width: "2%" },
               { title: <Tooltip title='Valor del peso del de área funcional'>psa</Tooltip>, dataIndex: 'psaTabla', key: 'psaTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor para el factor de seguridad'>fsa</Tooltip>, dataIndex: 'fsaTabla', key: 'fsaTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor para el inpc de la fecha de inicio de operaciones'>INPC0</Tooltip>, dataIndex: 'inpc0', key: 'inpc0', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor del INPC para el mes i-1'>INPCi-1</Tooltip>, dataIndex: 'inpcCalculo', key: 'inpcCalculo', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor del número de turnos existentes para el área funcional'>ut</Tooltip>, dataIndex: 'utTabla', key: 'utTabla', align: 'center', width: "2%" },
               { title: <Tooltip title='Valor del número de turnos en los que ocurre la falla'>utf</Tooltip>, dataIndex: 'utfTabla', key: 'utfTabla', align: 'center', width: "2%" },
               { title: <Tooltip title='Valor del porcentaje de dedución por fallas de disponibilidad'>pdfd</Tooltip>, dataIndex: 'pdfdTabla', key: 'pdfdTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor del ponderador de servicios'>ps</Tooltip>, dataIndex: 'psTabla', key: 'psTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor del ponderador de categoría de falla de calidad'>pcfc</Tooltip>, dataIndex: 'pcfcTabla', key: 'pcfcTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor para el ponderador de servicio por área'>pss_x_a</Tooltip>, dataIndex: 'ponderacionServAreaTabla', key: 'ponderacionServAreaTabla', align: 'center', width: "2%" },
               { title: 'Deductiva', dataIndex: 'deductivaTabla', key: 'deductivaTabla', align: 'right', width: "3.5%" },
               { title: 'Deductiva parcial', dataIndex: 'deductivaParcialTexto', key: 'deductivaParcialTexto', align: 'center', width: "2%" },
               // { title: 'Conciliada', dataIndex: 'conciliada', key: 'conciliada', align: 'center', width: "2%",
               // render: (text) => (
               //     <Switch checked={text} disabled={true}/>
               // )},
               // { title: 'Fecha cierre mes', dataIndex: 'fechaCierreMesTabla', key: 'fechaCierreMesTabla', align: 'center', width: "3%" },
               { title: 'Ticket reiterado', dataIndex: 'ticketReiteradoTabla', key: 'ticketReiteradoTabla', align: 'center', width: "2%" },
               { title: 'Número referencia', dataIndex: 'numReferenciaTabla', key: 'numReferenciaTabla', align: 'center', width: "2%" },
               // { title: 'Tiempo garantía', dataIndex: 'tiempoGarantiaTabla', key: 'tiempoGarantiaTabla', align: 'center', width: "2.5%" },
          ];

          const disabledRange = fecha => {
               return fecha && moment(fecha.format('YYYY-MM-DD')) < this.props.listaInfoProyecto.fechaInicioOperacion;
          }

          const opConcentrado = (e) => {
               if(e){
                    this.props.onGetConcentrando(e, this.state.dateString[0].split("-").reverse().join('-'), this.state.dateString[1].split("-").reverse().join('-'));
                    this.setState({ tipoConcentrado: e, disabledButtonSelected: false });
               } else {
                    this.setState({ tipoConcentrado: '', disabledButtonSelected: true });
               }
          };

          const onChangeFecha = (date, dateString) => { 
               if(date){
                    console.log('date, dateString', date, dateString)
                    this.setState({ dateString, disabledSelect: false, tipoConcentrado: '', disabledButtonSelected: true });
                    this.formRef.current.resetFields(['ticketsOption']);
               } else {
                    this.setState({ dateString: [], disabledSelect: true, tipoConcentrado: '', disabledButtonSelected: true });
                    this.formRef.current.resetFields(['ticketsOption']);
               }
          };

          const onShowModalDetalleConcentrado = () => {
               onGetDetalleConcentrado(this.state.tipoConcentrado, this.state.dateString[0].split("-").reverse().join('-'), this.state.dateString[1].split("-").reverse().join('-'), this.state.page);
               this.setState({ visibleConcentrado: true })
          };

          const handleTableChangeConcentrado = (pagination) => {
               this.setState({ page: pagination.current });

               onGetDetalleConcentrado(this.state.tipoConcentrado, this.state.dateString[0].split("-").reverse().join('-'), this.state.dateString[1].split("-").reverse().join('-'), pagination.current);
          };

          const columnsConcentrado = [
               { title: <Tooltip title='Folio de la solicitud de servicio'>Folio</Tooltip>, dataIndex: 'folio', key: 'folio',  align: 'center',  },

               { title: <Tooltip title='Número de año de alta al que corresponde el ticket'>Año</Tooltip>, dataIndex: 'year', key: 'year',  align: 'center'   },

               { title: <Tooltip title='Nombre del proveedor que da el servicio'>Proveedor</Tooltip>, dataIndex: 'proveedor', key: 'proveedor',  align: 'center'  },

               { title: <Tooltip title='Fecha y hora de alta del ticket'>Fecha alta</Tooltip>, dataIndex: 'fechaAltaTabla', key: 'fechaAltaTabla',  align: 'center'  },

               { title: <Tooltip title='Fecha y hora de cierre del ticket'>Fecha cierre</Tooltip>, dataIndex: 'fechaCierreTabla', key: 'fechaCierreTabla',  align: 'center'   },

               { title: <Tooltip title='Nombre del servicio al que corresponde el ticket'>Servicio</Tooltip>, dataIndex: 'servicio', key: 'servicio',  align: 'center'  },

               { title: <Tooltip title='Clave de la ubicación donde ocurrió la falla, según el catálogo de ubicaciones'>Clave ubicación</Tooltip>, dataIndex: 'claveUbicacion', key: 'claveUbicacion',  align: 'center' },

               { title: <Tooltip title='Descripción de la falla, capturada por el usuario de atención al usuario'>Falla</Tooltip>, dataIndex: 'descripcionFalla', key: 'descripcionFalla',  align: 'center', width:'35%'   },

               { title: <Tooltip title='Fecha y hora de terminación  del ticket'>Fecha terminación</Tooltip>, dataIndex: 'fechaTerminacionTabla', key: 'fechaTerminacionTabla',  align: 'center'  },

               { title: <Tooltip title='Nombre de la ubicación donde ocurrió la falla'>Ubicación</Tooltip>, dataIndex: 'ubicacion', key: 'ubicacion',  align: 'center'  },

               { title: <Tooltip title='Monto de la deductiva'>Monto deductiva</Tooltip>, dataIndex: 'deductivaTabla', key: 'deductivaTabla',  align: 'right'  },

               { title: <Tooltip title='Clasificación del ticket, después de la conciliación'>Clasificación</Tooltip>, dataIndex: 'clasificacion', key: 'clasificacion',  align: 'center'  },
          ];

          const onDesgargaDetalleConcentrado = () => {
               this.props.onGetExcelDetalleConcentrado(this.state.tipoConcentrado, this.state.dateString[0].split("-").reverse().join('-'), this.state.dateString[1].split("-").reverse().join('-'));
          };

          const onDesgargaResumenConcentrado = () => {
               this.props.onGetExcelResumenConcentrado(this.state.tipoConcentrado, this.state.dateString[0].split("-").reverse().join('-'), this.state.dateString[1].split("-").reverse().join('-'));
          };

          return (<>
               { permisosUsuario ? (<>
               <Spin spinning={this.props.fetchingResumenReporteDeductivas}>
                    { true ? (
                    
                    <Form name="formulario" ref={this.formRef}  {...layout} onFinish={onFinish} initialValues={listaMesesProcesados.ultimoMes}>
                         <Row  type="flex" justify='space-around' align='middle' gutter={[0,0]} style={{ padding: '1em', backgroundColor: '#E4E4E4', borderTopRightRadius:'1em', borderTopLeftRadius:'1em' }}>
                              <Col span={12} style={{backgroundColor: '#E4E4E4'}}>
                                   <div style={{ textAlign:'left' }}>
                                        <Text style={{textAlign: 'left', fontSize: '2em', color: '#737373', paddingRight: '.5em'}} strong> Reporte de Deductivas </Text>
                                   </div>
                              </Col>
                              <Col span={12} style={{backgroundColor:'whitesmoke', paddingTop:'1em', paddingLeft:'1em', paddingRight:'1em', borderRadius:'1em'}} justify='end'>
                                   <Tabs defaultActiveKey="1" tabPosition='top' type='card' onChange={(key) => handleClick(key)} style={{width: '100%'}} size='large'>
                                        <TabPane disabled={!permisosUsuario.verDeductivasPorFallasEnElServicio} tab="Deductivas por Fallas en el Servicio" key={1}/>

                                        <TabPane disabled={!permisosUsuario.verDeductivasInternas} tab="Deductivas Internas" key={2} />

                                        <TabPane disabled={!permisosUsuario.verConcentradoAnual} tab="Concentrado Anual" key={3} />
                                   </Tabs>
                              </Col>
                          </Row>

                          <Row type="flex" justify='space-around' align='middle' gutter={[0,0]}>
                              <Col span={24} style={{backgroundColor: '#F2F2F2', borderBottomLeftRadius: '1EM', borderBottomRightRadius:'1em'}}>
                                   { this.state.current !== '3' ? (
                                        <Row justify='end' gutter={[8,8]} style={{paddingTop: '1em', paddingLeft: '1em', paddingRight:'1em'}}>
                                             {listaAñosCalendario[0] ? (
                                                  <Col span={4}>
                                                       <Form.Item name="anio" >
                                                            <Select
                                                                 style={{ width: '100%' }}
                                                                 placeholder="Selecciona el año"
                                                                 onChange={onAñoCalendario}
                                                                 loading={this.props.fetchingListaAñosCalendario}
                                                            >
                                                                 {listaAñosCalendario.map(option => <Option key={option}>{option}</Option>)}
                                                            </Select>
                                                       </Form.Item>
                                                  </Col>
                                             ):false}

                                             <Col span={4}>
                                                  <Form.Item name="mesCalendario" >
                                                       <Select
                                                            disabled={this.state.booleanAnio}
                                                            style={{width: '100%'}}
                                                            placeholder="Seleccione el mes"
                                                            loading={this.props.fetchingListaMesesProcesados}
                                                            onChange={onChangeMes}
                                                            showSearch
                                                       >
                                                            {listaMesesProcesados.map(option => <Option key={option.idMesCalendario + '-' + option.mesCalendario + '-' + option.anio}>{option.mesCalendario}</Option>)}
                                                       </Select>
                                                  </Form.Item>
                                             </Col>
                                        </Row>
                                   ):(
                                        <Row justify='end' gutter={[8,8]} style={{paddingTop: '1em', paddingLeft: '1em', paddingRight:'1em'}}>
                                             <Col span={5}>
                                                  <Form.Item name='rangeYear' >
                                                       <RangePicker onChange={onChangeFecha} format='DD-MM-YYYY' placeholder={["Fecha inicial","Fecha final"]}  style={{width:'100%'}} disabledDate={disabledRange}/>
                                                  </Form.Item>
                                             </Col>

                                             <Col span={7}>
                                                  <Form.Item name='ticketsOption' >
                                                       <Select style={{ width: '100%' }} placeholder="Selecciona una opción" onChange={ opConcentrado }  disabled={this.state.disabledSelect}>
                                                            <Option key='Interna'> <Tooltip title='Tickets con deductivas internas'> Tickets con deductivas internas </Tooltip></Option>
                                                            <Option key='Externa'><Tooltip title='Tickets con deductivas por fallas en la prestación del servicio'> Tickets con deductivas por fallas en la prestación del servicio</Tooltip></Option>
                                                       </Select>
                                                  </Form.Item>
                                             </Col>
                                        </Row>
                                   ) }
                                   
                                   { this.state.current === '1' ? (<>
                                        <Row type='flex' justify='space-around' align='middle' gutter={[8,8]} style={{padding: '1em'}}>
                                             <Col span={24}>
                                                  <Card className="cardCatalogos" style={{backgroundColor: '#F2F2F2'}} bordered={false} title={ 
                                                  <Row justify='space-around'>
                                                       <Col span={22} style={{fontSize:18, textAlign:"left", color:"#1747ca"}}>     
                                                            Reporte en Resumen del Cálculo de Deductivas:
                                                       </Col>
                                                       <Col span={1}>
                                                            <Tooltip title='Exportar a Excel'>
                                                                 <Button disabled={!permisosUsuario.exportarAExcelDeductivasPorFallaEnElServicio ? true : resumenReporteDeductivas.length > 0 ? false : true} loading={this.props.fetchingResumenReporteDeductivasExcel} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } onClick={onDescarga}/>
                                                            </Tooltip>
                                                       </Col>

                                                       <Col span={1}>
                                                            <Tooltip title='Ver detalle'>
                                                                 <Button disabled={!permisosUsuario.verDetalleDeResumenDeDeductivasPorFallaEnElServicio ? true : resumenReporteDeductivas.length > 0 ? false : true} className="iconTer" shape="circle" icon={<EyeOutlined />} onClick={onShowDetalle}/>
                                                            </Tooltip>
                                                       </Col>
                                                  </Row> } >
                                                       { resumenReporteDeductivas.length > 0 ? (
                                                            <Table
                                                                 size="small"
                                                                 rowKey="servicio"
                                                                 columns={resumenReporteDeductivas.columnsExternas}
                                                                 dataSource={resumenReporteDeductivas}
                                                                 loading={this.props.fetchingResumenReporteDeductivas}
                                                                 pagination={false}
                                                                 scroll={{ x:1800 }}
                                                            />
                                                       ):(
                                                            <Spin spinning={this.props.fetchingResumenReporteDeductivas}>
                                                                 <Row justify='space-around' align='middle' style={{height:'25em'}}>
                                                                      <Empty description='Sin datos' />
                                                                 </Row>
                                                            </Spin>
                                                       ) }
                                                  </Card>
                                             </Col>
                                        </Row>
                                   </>): ( this.state.current === '2' ? (
                                        <Row justify='center' style={{padding: '1em'}} gutter={[8,8]} type="flex">
                                             <Col span={24}>
                                                  <Card className="cardCatalogos" style={{backgroundColor: '#F2F2F2'}} bordered={false} title={ 
                                                  <Row justify='space-around'>
                                                       <Col span={22} style={{fontSize:18, textAlign:"left", color:"#1747ca"}}>     
                                                            Reporte en Resumen del Cálculo de Deductivas Internas:
                                                       </Col>
                                                       <Col span={1}>
                                                            <Tooltip title='Exportar a Excel'>
                                                                 <Button disabled={!permisosUsuario.exportarExcelDeductivasInternas ? true : resumenReporteDeductivas.length > 0 ? false : true} loading={this.props.fetchingResumenReporteDeductivasExcel} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } onClick={onDescarga}/>
                                                            </Tooltip>
                                                       </Col>

                                                       <Col span={1}>
                                                            <Tooltip title='Ver detalle'>
                                                                 <Button disabled={!permisosUsuario.verDetalleDeResumenDeDeductivasInternas ? true : resumenReporteDeductivas.length > 0 ? false : true} className="iconTer" shape="circle" icon={<EyeOutlined />} onClick={onShowDetalle}/>
                                                            </Tooltip>
                                                       </Col>
                                                  </Row> } >
                                                       { resumenReporteDeductivas.length > 0 ? (
                                                            <Table
                                                                 size="small"
                                                                 rowKey="servicio"
                                                                 columns={resumenReporteDeductivas.columnsInternas}
                                                                 dataSource={resumenReporteDeductivas}
                                                                 loading={this.props.fetchingResumenReporteDeductivas}
                                                                 pagination={false}
                                                                 scroll={{ x:2000 }}
                                                            />
                                                       ):(
                                                            <Spin spinning={this.props.fetchingResumenReporteDeductivas}>
                                                                 <Row justify='space-around' align='middle' style={{height:'25em'}}>
                                                                      <Empty description='Sin datos' />
                                                                 </Row>
                                                            </Spin>
                                                       ) }
                                                  </Card>
                                             </Col>
                                        </Row>
                                   ): (this.state.current === '3' ? (
                                        <Spin spinning={this.props.fetchingConcentradoAnualResumen}>
                                             <Card className="cardCatalogos" style={{backgroundColor: '#F2F2F2'}} bordered={false} title={ 
                                                  <Row justify='space-around'>
                                                       <Col span={22} style={{fontSize:18, textAlign:"left", color:"#1747ca"}}>     
                                                            Tickets con Deductiva por Área Funcional y por Servicio:
                                                       </Col>
                                                       <Col span={1}>
                                                            <Tooltip title='Ver detalle'>
                                                                 <Button className="iconTer" shape="circle"  icon={<EyeOutlined />} onClick={onShowModalDetalleConcentrado} disabled={!permisosUsuario.verDetalleConcentradoDeDeductivasAnual ? true : this.state.disabledButtonSelected}/>
                                                            </Tooltip>
                                                       </Col>

                                                       <Col span={1}>
                                                            <Tooltip title='Exportar a Excel'>
                                                                 <Button disabled={!permisosUsuario.exportarExcelConcentradoDeDeductivasAnual ? true : this.state.disabledButtonSelected} loading={this.props.fetchingExcelResumenConcentradoAnual} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } onClick={onDesgargaResumenConcentrado}/>
                                                            </Tooltip>
                                                       </Col>
                                                  </Row> } 
                                             >
                                                  <Row justify='end' gutter={[8,8]} type="flex" style={{padding: '1em'}}>
                                                       { concentradoAnualResumen.length > 0 ? (<>
                                                            {concentrado.map( areas =>
                                                                 <Col span={24} style={{padding:'1em'}}>
                                                                      <Text strong style={{fontSize: 20, paddingBotton:'1em', color: '#0e2c82'}}> { areas.areaFuncional } </Text>
                                                                      <Divider style={{color:'#0e2c82', backgroundColor:'#0e2c82'}} /> 
                                                                      { areas.servicios.map( servicios => <div>
                                                                           <Card className="cardCatalogos" style={{backgroundColor: '#F2F2F2'}} bordered={true}>
                                                                                <Descriptions layout="horizontal" bordered size='small' column={{ xxl: 9, xl: 9, lg: 9, md: 9, sm: 9, xs: 9 }}>
                                                                                     <Descriptions.Item label="Servicio" span={1}>{servicios.servicio}</Descriptions.Item>
                                                                                     <Descriptions.Item label="Total">{servicios.totalFolios}</Descriptions.Item>
                                                                                </Descriptions>

                                                                                <Divider orientation='left' style={{ fontSize:12, color: '#C4C4C4' }}>Cantidad de Tickets por Año</Divider>
                                                                                <Descriptions layout="vertical" bordered size='small' column={{ xxl: 12, xl: 12, lg: 12, md: 12, sm: 12, xs: 12 }} >
                                                                                     {servicios.graficaResponseList.map(anios => 
                                                                                          <Descriptions.Item label={anios.etiqueta} contentStyle={{align:'right'}} >{anios.cantidad}</Descriptions.Item>
                                                                                     )}
                                                                                </Descriptions>
                                                                           </Card>
                                                                      <br/></div>) }

                                                                 </Col>
                                                            )}
                                                       </>):(<>
                                                            { this.state.disabledButtonSelected ? (
                                                                 <Col span={24} style={{padding:'1em'}}>
                                                                      <Row justify='space-around' align='middle' style={{height:'25em'}}>
                                                                           <Result  icon={<SelectOutlined />} subTitle="Selecciona una fecha inicial, fecha final y una opción de tickets con deductivas." />
                                                                      </Row>
                                                                 </Col>
                                                            ):(
                                                                 <Col span={24} style={{padding:'1em'}}>
                                                                      <Spin spinning={this.props.fetchingConcentradoAnualResumen}>
                                                                           <Row justify='space-around' align='middle' style={{height:'25em'}}>
                                                                                <Empty description='Sin datos' />
                                                                           </Row>
                                                                      </Spin>
                                                                 </Col>
                                                            )}
                                                       </>) }
                                                  </Row>
                                             </Card>
                                        </Spin>
                                   ): false) ) }
                              </Col>

                              <Modal title="Detalle del Cálculo de Deductivas" visible={this.state.visible} footer={false} onCancel={this.handleCancel}  width = {'90%'}>
                                   {/* <Row justify='end' style={{padding:'1em'}} gutter={[16]}>
                                        <Col>
                                             <Tooltip title='Exportar a Excel'>
                                                  <Button loading={this.props.fetchingDetalleResumenReporteDeductivasExcel} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } onClick={() => onDesgargaDetalle('excel')}/>
                                             </Tooltip>
                                        </Col>

                                        <Col>
                                             <Tooltip title='Exportar a PDF'>
                                                  <Button loading={this.props.fetchingDetalleResumenReporteDeductivasPDF} className="iconSecundary" shape='circle' icon={<FilePdfOutlined /> } onClick={() => onDesgargaDetalle('pdf')}/>
                                             </Tooltip>
                                        </Col>
                                   </Row> */}
                                   <Col span={24}>
                                        <div className="ant-descriptions-view" >
                                             <Table
                                                  title={ () => (
                                                       <Row justify='end' gutter={[16,16]}>
                                                            <Col>
                                                                 <Tooltip title='Exportar a Excel'>
                                                                      <Button disabled={this.state.current === '1' ? !permisosUsuario.exportarAExcelDeductivasPorFallaEnElServicio : !permisosUsuario.exportarExcelDeductivasInternas} loading={this.props.fetchingDetalleResumenReporteDeductivasExcel} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } onClick={() => onDesgargaDetalle('excel')}/>
                                                                 </Tooltip>
                                                            </Col>

                                                            <Col>
                                                                 <Tooltip title='Exportar a PDF'>
                                                                      <Button disabled={this.state.current === '1' ? !permisosUsuario.exportarAPdfDeductivasPorFallaEnElServicio : !permisosUsuario.exportarPdfDeductivasInternas} loading={this.props.fetchingDetalleResumenReporteDeductivasPDF} className="iconSecundary" shape='circle' icon={<FilePdfOutlined /> } onClick={() => onDesgargaDetalle('pdf')}/>
                                                                 </Tooltip>
                                                            </Col>
                                                       </Row>
                                                  ) }
                                                  size="small"
                                                  rowKey="servicio"
                                                  columns={ this.state.tipoDeductiva === 'Externa' ? columns: columnsProv}
                                                  dataSource={this.props.detalleResumenReporteDeductivas}
                                                  loading={this.props.fetchingDetalleResumenReporteDeductivas}
                                                  scroll={{ x: 7000 }}
                                                  onChange={handleTableChange}
                                                  pagination={{ total: this.props.detalleResumenReporteDeductivasCantidad, simple: true, current: this.state.page }}
                                             />
                                        </div>
                                   </Col>
                              </Modal>


                              <Modal title="Detalle Concentrado Anual" visible={this.state.visibleConcentrado} footer={false} onCancel={this.handleCancelConcentrado}  width = {'90%'}>
                                   <Row justify='end' style={{padding:'1em'}}>
                                        <Tooltip title='Exportar a Excel'>
                                             <Button disabled={!permisosUsuario.exportarExcelConcentradoDeDeductivasAnual} loading={this.props.fetchingExcelDetalleConcentradoAnual} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } onClick={onDesgargaDetalleConcentrado}/>
                                        </Tooltip>
                                   </Row>
                                   <Col span={24}>
                                        <div className="ant-descriptions-view" >
                                             <Table
                                                  size="small"
                                                  rowKey="servicio"
                                                  columns={columnsConcentrado}
                                                  dataSource={this.props.detalleConcentradoAnual}
                                                  loading={this.props.fetchingDetalleConcentradoAnual}
                                                  onChange={handleTableChangeConcentrado}
                                                  pagination={{ total: this.props.detalleConcentradoAnualCantidad, simple: true, current: this.state.page }}
                                                  scroll={{x:1900}}
                                             />
                                        </div>
                                   </Col>
                              </Modal>
                         </Row>
                    </Form>
                    ): (
                         <Row justify='space-around' align='middle' style={{height:'35em'}}>
                              <Empty description='Sin meses procesados' />
                         </Row>
                    )}
               </Spin>
               </>): (<>
                    <Row justify='center'>
                         <Result
                              status="403"
                              title="Acceso denegado"
                              subTitle="Lo siento, no tiene permiso autorizado para acceder."
                         />
                    </Row>
               </>) }
          </>);
     }
}
 
 const mapStateToProps = state => {
     return {
          fetchingListaAñosCalendario: state.MecanismosDePagosReducer.fetchingListaAñosCalendario,
          fetchingListaMesesCalendario: state.MecanismosDePagosReducer.fetchingListaMesesCalendario,
          listaAñosCalendario: state.MecanismosDePagosReducer.listaAñosCalendario,
          listaMesesCalendario: state.MecanismosDePagosReducer.listaMesesCalendario,
          listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,

          resumenReporteDeductivas: state.MecanismosDePagosReducer.resumenReporteDeductivas,
          fetchingResumenReporteDeductivas: state.MecanismosDePagosReducer.fetchingResumenReporteDeductivas,

          fetchingListaMesesProcesados: state.MecanismosDePagosReducer.fetchingListaMesesProcesados,
          listaMesesProcesados: state.MecanismosDePagosReducer.listaMesesProcesados,
          fetchingResumenReporteDeductivasExcel: state.MecanismosDePagosReducer.fetchingResumenReporteDeductivasExcel,

          detalleResumenReporteDeductivas: state.MecanismosDePagosReducer.detalleResumenReporteDeductivas,
          fetchingDetalleResumenReporteDeductivas: state.MecanismosDePagosReducer.fetchingDetalleResumenReporteDeductivas,
          fetchingDetalleResumenReporteDeductivasExcel: state.MecanismosDePagosReducer.fetchingDetalleResumenReporteDeductivasExcel,
          detalleResumenReporteDeductivasCantidad: state.MecanismosDePagosReducer.detalleResumenReporteDeductivas,

          fetchingConcentradoAnualResumen: state.MecanismosDePagosReducer.fetchingConcentradoAnualResumen,
          concentradoAnualResumen: state.MecanismosDePagosReducer.concentradoAnualResumen,
          concentrado: state.MecanismosDePagosReducer.concentrado,

          detalleConcentradoAnual: state.MecanismosDePagosReducer.detalleConcentradoAnual,
          detalleConcentradoAnualCantidad: state.MecanismosDePagosReducer.detalleConcentradoAnualCantidad,
          fetchingDetalleConcentradoAnual: state.MecanismosDePagosReducer.fetchingDetalleConcentradoAnual,

          fetchingExcelResumenConcentradoAnual: state.MecanismosDePagosReducer.fetchingExcelResumenConcentradoAnual,
          fetchingExcelDetalleConcentradoAnual: state.MecanismosDePagosReducer.fetchingExcelDetalleConcentradoAnual,
          fetchingDetalleResumenReporteDeductivasPDF: state.MecanismosDePagosReducer.fetchingDetalleResumenReporteDeductivasPDF,
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          onGetListaMesesCalendario: (busqueda) => {
               dispatch({ type: "LISTA_MESES_CALENDARIO_REQUEST", busqueda });
          },
          onRequestInfoProyecto: () => {
               dispatch({ type: 'GET_INFOPROYECTO_REQUEST', añoMesCalendario: true });
          },
          onGetListaAñosCalendario: (listaInfoProyecto) => {
               dispatch({ type: 'LISTA_AÑO_CALENDARIO_REQUEST', listaInfoProyecto });
          },
          onGetMeses: (anio) => {
               dispatch({ type: 'LISTA_MESES_PROCESADOS_REQUEST', anio });
          },

          onGetResumen: (tipoDeductiva, idMesCalendario) => {
               dispatch({ type: 'REPORTE_DEDUCTIVAS_RESUMEN_REQUEST', tipoDeductiva, idMesCalendario });
          },
          onExportResumen: (tipoDeductiva, idMesCalendario, mesTxt, anio) => {
               dispatch({ type: 'EXCEL_REPORTE_DEDUCTIVAS_RESUMEN_REQUEST', tipoDeductiva, idMesCalendario, mesTxt, anio });
          },

          onGetDetalle: (tipoDeductiva, idMesCalendario) => {
               dispatch({ type: 'DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_REQUEST', tipoDeductiva, idMesCalendario });
          },
          onExportDetalle: (tipoDeductiva, idMesCalendario, mesTxt, anio) => {
               dispatch({ type: 'EXCEL_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_REQUEST', tipoDeductiva, idMesCalendario, mesTxt, anio });
          },
          onGetConcentrando: (tipoDeductiva, fechaInicio, fechaFin) => {
               dispatch({ type: 'CONCENTRADO_ANUAL_RESUMEN_REQUEST', tipoDeductiva, fechaInicio, fechaFin});
          },
          onGetDetalleConcentrado: (tipoDeductiva, fechaInicio, fechaFin, page) => {
               dispatch({ type: 'DETALLE_CONCENTRADO_ANUAL_RESUMEN_REQUEST', tipoDeductiva, fechaInicio, fechaFin, page });
          },
          onGetExcelResumenConcentrado: (tipoDeductiva, fechaInicio, fechaFin) => {
               dispatch({ type: 'EXCEL_CONCENTRADO_ANUAL_RESUMEN_REQUEST', tipoDeductiva, fechaInicio, fechaFin });
          },

          onGetExcelDetalleConcentrado: (tipoDeductiva, fechaInicio, fechaFin) => {
               dispatch({ type: 'EXCEL_DETALLE_CONCENTRADO_ANUAL_RESUMEN_REQUEST', tipoDeductiva, fechaInicio, fechaFin });
          },
          onExportDetallePDF: (tipoDeductiva, idMesCalendario, mesTxt, anio) => {
               dispatch({ type: 'PDF_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_REQUEST', tipoDeductiva, idMesCalendario, mesTxt, anio });
          },
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(ReporteDeductivasContainer);