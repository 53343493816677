import React from 'react';
import { connect } from 'react-redux';
import { Table, Col, Switch, Modal, Divider, Row, Spin, Radio } from 'antd';
import { EditOutlined, FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { confirm } = Modal;

class RegistrosReportesContainer extends React.Component {
    state = {
        page: 1,
        busqueda: null,
        personal: false
    }

    componentDidMount () {
        this.props.onListaRegistrosReporte(1)
    }
    componentWillUnmount(){
        this.props.limpiarListaCentroCostoByGerencia();
        this.props.limpiarListaServiciosByCentroCosto();
    }
    render () {
        const {onListaRegistrosReporte, onListaRegistrosEspecialReporte, fetchingListaRegistrosEspecialReporte,
            listaRegistrosEspecialReporte, listaRegistrosEspecialReporteCantidad, listaRegistrosReporte, fetchingListaRegistrosReporte,
            listaRegistrosReporteCantidad, onEstadoRegistroReporte, onListaClasificacion, onExportarPDF, onExportarExcel,
            onModalEditarRegistroReporte, fetchingExportarReportesDinamico, onListaPartidas, onGerencias
        } = this.props;

            
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columns = [{
            title: 'Título del documento',
            dataIndex: 'titulo',
            key: 'titulo',
            width: '15%',
        },{
            title: 'Nombre',
            dataIndex: 'nombre',
            key: 'nombre',
            width: '20%',
        },{
            title: 'Descripción',
            dataIndex: 'descripcion',
            key: 'descripcion',
            width: '30%',
        },{ 
            title: 'Usuario',
            dataIndex: 'usuario',
            key: 'usuario',
            width: '15%',
        },{
            title: 'Editar',
            key: 'editar',
            width: '5%',
            align: 'center',
            render: (text, record) => (
                <a className='editarIcono' disabled={this.state.personal ? true : !record.activo}   onClick={() => onEditar(record)}  ><EditOutlined /></a>
            ),
        }, {
            title: 'Exportar',
            key: 'exportar',
            width: '10%',
            align: 'center',
            render: (text, record) => (
                <Row justify='space-around'>
                    <Col span={6}>
                        <a className='editarIcono' disabled={!permisosUsuario.exportarPdfReporteDinámicoParaEvaluaciónDeLaGestión ? false : !record.activo} onClick={() => onExportar(record, 'pdf')}><FilePdfOutlined /></a>
                    </Col>
                    <Col span={6}>
                        <a className='editarIcono' disabled={!permisosUsuario.exportarExcelReporteDinámicoParaEvaluaciónDeLaGestión ? false : !record.activo} onClick={() => onExportar(record, 'excel')}><FileExcelOutlined /></a>
                    </Col>
                </Row>
            ),
        }, {
            title: 'Activo',
            dataIndex: 'activo',
            key: 'activo',
            width: '5%',
                align: 'center',
            render: (text, record) => (
                <Switch checked={text} disabled={this.state.personal} onChange = {() => handleDesactivar(record)} />
            ),
        }];

        const onExportar = (value, tipo) => {
            console.log(`value, tipo`, value, tipo);
            if(tipo === 'pdf'){
                onExportarPDF(value.formulario, value.checkbox, value.orden, value.titulo, value.idReporteEvaluacion, permisosUsuario.especialEvaluaciónDeLaGestión, value.agrupado);
            }
            else{
                onExportarExcel(value.formulario, value.checkbox, value.orden, value.titulo, value.idReporteEvaluacion, permisosUsuario.especialEvaluaciónDeLaGestión, value.agrupado);
            }
        }

        const handleDesactivar = (key) => {
            let page = this.state.page;
            let personal = this.state.personal;
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del reporte predeterminado "' + key.nombre + '"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoRegistroReporte(key.idReporteEvaluacion, page, personal);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
        
        const onEditar = (registroReporteSeleccionado) => {
            console.log(`registroReporteSeleccionado`, registroReporteSeleccionado);
            onGerencias();
            this.props.onCetroCostosByGerencia(registroReporteSeleccionado.gerencia);
            this.props.onServiciosByCentroCosto(registroReporteSeleccionado.centroCosto);
            onListaPartidas(registroReporteSeleccionado.servicio);
            this.props.onListaTemas();
            onModalEditarRegistroReporte(registroReporteSeleccionado, this.state.page, this.state.personal);
            
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            if(this.state.personal){
                onListaRegistrosEspecialReporte(pagination.current);
            }
            else{
                onListaRegistrosReporte(pagination.current);
            }
            // onListaClasificacion(pagination.current, this.state.busqueda);
        }
    
        const cambiarTablaRegistros = (value) => {
            console.log(`value`, value.target.value)
            if(value.target.value){
                this.setState({
                    page: 1,
                    personal: true
                });
                onListaRegistrosEspecialReporte(1)
            }
            else{
                this.setState({
                    page: 1,
                    personal: false
                });
                onListaRegistrosReporte(1)
            }
        }

        return (
            <div>
                <Divider orientation='left'>Registros Guardados</Divider>
                <Row justify='end' style={{padding:'1em'}}>
                    <Radio.Group defaultValue={false} onChange={cambiarTablaRegistros} size="small">
                        {permisosUsuario.especialEvaluaciónDeLaGestión ? (
                        <Radio.Button value={true}>Todos</Radio.Button>
                        ):false}
                        <Radio.Button value={false}>Personal</Radio.Button>
                    </Radio.Group>
                </Row>
                <br/>
                <br/>
                <Spin spinning={fetchingExportarReportesDinamico}>
                    <Col span={24}>
                        <Table
                            size='small'
                            rowKey='idReporteEvaluacion'
                            columns={columns}
                            dataSource={this.state.personal ? listaRegistrosEspecialReporte : listaRegistrosReporte}
                            loading={this.state.personal ? fetchingListaRegistrosEspecialReporte : fetchingListaRegistrosReporte}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: this.state.personal ? listaRegistrosEspecialReporteCantidad : listaRegistrosReporteCantidad, simple: true, current: this.state.page }}
                        />
                    </Col>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaRegistrosEspecialReporte: state.EvaluacionDeLaGestionReducer.fetchingListaRegistrosEspecialReporte,
        listaRegistrosEspecialReporte: state.EvaluacionDeLaGestionReducer.listaRegistrosEspecialReporte,
        listaRegistrosEspecialReporteCantidad: state.EvaluacionDeLaGestionReducer.listaRegistrosEspecialReporteCantidad,
        fetchingListaRegistrosReporte: state.EvaluacionDeLaGestionReducer.fetchingListaRegistrosReporte,
        listaRegistrosReporte: state.EvaluacionDeLaGestionReducer.listaRegistrosReporte,
        listaRegistrosReporteCantidad: state.EvaluacionDeLaGestionReducer.listaRegistrosReporteCantidad,
        fetchingExportarReportesDinamico: state.EvaluacionDeLaGestionReducer.fetchingExportarReportesDinamico,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onEstadoRegistroReporte: (idReporteEvaluacion, page, personal) => {
            dispatch({ type: "ESTADO_REGISTRO_REPORTE_REQUEST", idReporteEvaluacion, page, personal });
        },
        onModalEditarRegistroReporte: (registroReporteSeleccionado, page, personal) => {
            dispatch({ type: "MODAL_EDITAR_REGISTRO_REPORTE", showModalEditarRegistroReporte: true, registroReporteSeleccionado, page, personal });
        },
        onListaRegistrosReporte: (page) => {
            dispatch({ type: 'LISTA_REGISTRO_REPORTE_REQUEST', page })
        },
        onListaRegistrosEspecialReporte: (page) => {
            dispatch({ type: 'LISTA_REGISTRO_ESPECIAL_REPORTE_REQUEST', page })
        },
        onExportarPDF: (formulario, checkbox, order, tituloDocumento, idReporteEvaluacion, especial, agrupado) => {
            dispatch({ type: 'EXPORTAR_REPORTE_PDF_REQUEST', formulario, checkbox, order, tituloDocumento, idReporteEvaluacion, especial, agrupado })
        },
        onExportarExcel: (formulario, checkbox, order, tituloDocumento, idReporteEvaluacion, especial, agrupado) => {
            dispatch({ type: 'EXPORTAR_REPORTE_EXCEL_REQUEST', formulario, checkbox, order, tituloDocumento, idReporteEvaluacion, especial, agrupado })
        },
        onListaPartidas: (idsServicios) => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_SERVICIOS_REQUEST', idsServicios })
        },
        onGetServiciosActivos: () => {
            dispatch({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST'})
        },
        onGerencias: () => {
            dispatch({ type: 'LISTA_CLASIFICACION_ACTIVOS_REQUEST' })
        },
        onCetroCostosByGerencia: (idsGerencias) => {
            dispatch({ type: 'LISTA_CENTRO_COSTOS_BY_GERENCIA_REQUEST', idsGerencias });
        },
        onServiciosByCentroCosto: (idsCentrosCostos) => {
            dispatch({ type: 'LISTA_SERVICIOS_BY_CENTRO_COSTO_REQUEST', idsCentrosCostos });
        },
        onListaTemas: (param) => {
            dispatch({ type: 'LISTA_TEMAS_ACTIVOS_REQUEST', param })
        },
        limpiarListaCentroCostoByGerencia: () => {
            dispatch({ type: 'LISTA_CENTRO_COSTOS_BY_GERENCIA_SUCCESS', listaCentroDeCostosByGerencia: [] })
        },
        limpiarListaServiciosByCentroCosto: () => {
            dispatch({ type: 'LISTA_SERVICIOS_BY_CENTRO_COSTO_SUCCESS', listaServiciosByCentroCosto: [] })
        },
        onLimpiarListaPartidas: () => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_SERVICIOS_SUCCESS', listaPartidasByServicios: [] })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrosReportesContainer);