import React, {Component} from 'react'
import {Form, Button, Col, Spin, Modal, Row, Input, Select, DatePicker, Switch, message, Upload} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

import moment from 'moment';
import {UploadOutlined} from "@ant-design/icons";
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Option } = Select
class ModalEditarPlanCalidad extends Component {
    formRef = React.createRef();
    state = {
        filePDF: false,
        botonPDF: false,
        listaActividades: []
    }
    render() { 
        const {
            onEditarPlanCorrectivo, onShowModalEditarPlanCorrectivo, showModalEditarPlanCorrectivo, onActualizarOrigen,
            fetchingEditarPlanCorrectivo, listaInfoProyecto, page, limpiarFormulario, busqueda, planCorrectivoSeleccionado,
            fetchingListaServiciosActivosF, listaServiciosActivosF, orden, estado
        } = this.props;

        if (planCorrectivoSeleccionado && this.state.listaActividades.length === 0) {
            if (planCorrectivoSeleccionado.actividadesPlanesByIdPlanCorrectivo) {
                if (planCorrectivoSeleccionado.actividadesPlanesByIdPlanCorrectivo.length > 0) {
                    this.setState({
                        listaActividades: planCorrectivoSeleccionado.actividadesPlanesByIdPlanCorrectivo
                    });
                    console.log('listaActividades', planCorrectivoSeleccionado.actividadesPlanesByIdPlanCorrectivo);
                }
            }
        }

        const onFinish = formulario => {
            console.log('Success:', formulario);
            console.log('actividadesList', this.state.listaActividades)
            if(!formulario.estadoEditar){
                formulario.fechaTermino = formulario.fechaTermino.format('YYYY-MM-DD');
            }
            for(let i in this.state.listaActividades){
                if(!this.state.listaActividades[i].fechaRealizacionEditar){
                    message.warning('Favor de seleccionar una fecha de realización');
                    return
                }
                if(!this.state.listaActividades[i].fechaCompromisoEditar){
                    message.warning('Favor de seleccionar una fecha compromiso');
                    return
                }
                if(!this.state.listaActividades[i].actividad){
                    message.warning('Favor de seleccionar una actividad');
                    return
                }
            }
            onEditarPlanCorrectivo(formulario, page, busqueda, orden, planCorrectivoSeleccionado, this.state.listaActividades, estado);
        };

        if (limpiarFormulario && limpiarFormulario === 'editarPlanCorrectivo') {
            this.props.onLimpiarFormulario();
            onShowModalEditarPlanCorrectivo(page, busqueda);
            this.formRef.current.resetFields();
            this.setState({
                filePDF: false,
                botonPDF: false,
                listaActividades: []
            });
        }
        const handleCancel = () => {
            onShowModalEditarPlanCorrectivo(page, busqueda);
            this.formRef.current.resetFields();
            this.setState({
                filePDF: false,
                botonPDF: false,
                listaActividades: []
            });
        }

        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            } else {
                this.setState({
                    botonPDF: true,
                    botonCrear: true,
                })
            }
            if (info.file.status === 'done') {
                var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,40}$");
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                    this.setState({
                        filePDF: false,
                        botonPDF: false,
                    });
                } else {
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonCrear: false,
                });
            } else if (info.file.status === 'error') {
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                    this.setState({
                        filePDF: false,
                        botonPDF: false,
                    });
                } else {
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonCrear: false,
                });
            }
        }

        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
            this.formRef.current.resetFields(['pdf']);
        }

        const dummyRequest = ({file, onSuccess}) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };
        
        const disabledDate = fecha => {
            const fechaInicio = moment(planCorrectivoSeleccionado.fechaIncumplimiento, "DD-MM-YYYY").add(1, 'd');
            const fechaVigencia = moment(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[0] + '-' + listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[1] + '-' + (parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2]) + listaInfoProyecto.vigencia) , "DD-MM-YYYY");
            
            if (fecha && fecha < fechaInicio) {
                return fecha && fecha < fechaInicio;
            } else {                
                return fecha && fecha > fechaVigencia;
            }
        }
        const onEstadoPlanCorrectivo = (value) => {
            console.log('value', value)
            const planCorrectivoLocal = planCorrectivoSeleccionado;
            onActualizarOrigen(null, page, busqueda, orden, estado);
            planCorrectivoLocal.estadoEditar = value;
            onActualizarOrigen(planCorrectivoLocal, page, busqueda, orden, estado);
        }
        return (
            <Modal
                visible={showModalEditarPlanCorrectivo}
                title='Editar Plan Correctivo'
                onCancel={handleCancel}
                footer={false}
                width='60%'
            >
                <Spin spinning={fetchingEditarPlanCorrectivo}>
                    {planCorrectivoSeleccionado ? (
                    <Form {...layout}
                        name="formulario"
                        onFinish={onFinish}
                        ref={this.formRef}
                        initialValues={planCorrectivoSeleccionado}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Servicio"
                                    name="servicio"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona un servicio"
                                        loading={fetchingListaServiciosActivosF}
                                        showSearch={true}
                                    >
                                        {listaServiciosActivosF.map(option => <Option key={option.idServicio + ' - ' + option.nombre}>{option.idServicio + ' - ' + option.nombre}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Estado"
                                    name="estadoEditar"
                                    valuePropName="checked"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Switch
                                        checkedChildren={'Abierto'}
                                        unCheckedChildren={'Cerrado'}
                                        onChange={onEstadoPlanCorrectivo}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Fecha de incumplimiento"
                                    name="fechaIncumplimientoEditar"
                                    rules={[{required: true, message: 'Favor de llenar el campo'}]}
                                >
                                    <DatePicker
                                        style={{width: '100%'}}
                                        format='DD-MM-YYYY'
                                        disabledDate={disabledDate}
                                        showToday={false}
                                    />
                                </Form.Item>
                            </Col>
                            {!planCorrectivoSeleccionado.estadoEditar ? (
                            <Col span={11}>
                                <Form.Item
                                    label="Fecha real de término"
                                    name="fechaTermino"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        format='DD-MM-YYYY'
                                        disabledDate={disabledDate}
                                        showToday={false}
                                    />
                                </Form.Item>
                            </Col>
                            ):false}
                            {!planCorrectivoSeleccionado.estadoEditar ? (
                            <Col span={11}>
                                <Form.Item
                                    label="Observaciones"
                                    name="observacion"
                                >
                                    <Input maxLength={450} placeholder='Ingresa las observaciones' />
                                </Form.Item>
                            </Col>
                            ):false}
                            <Col span={11}>
                                <Form.Item
                                    label="Adjuntar archivo"
                                    name="pdf"
                                >
                                    <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF}
                                            accept='.pdf' multiple={false}>
                                        {this.state.filePDF === false ? (
                                            <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                                <UploadOutlined/> Click para adjuntar archivo PDF
                                            </Button>
                                        ) : false}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        {this.state.listaActividades.length > 0 ? (
                            <Row justify='space-around'>
                                <h3>Editar Actividades</h3>
                            </Row>
                        ) : false}
                        {this.state.listaActividades.map((actividad, index) =>
                            <Row justify='space-around' key={actividad.idActividadPlan} style={{ marginBottom: "20px" }} >
                                <Col span={7}>
                                    <Form.Item
                                        label="Actividad"
                                        name={"actividad-" + index}
                                    >
                                        <Input
                                            onChange={(value) => this.state.listaActividades[index].actividad = value.target.value}
                                            defaultValue={this.state.listaActividades[index].actividad} maxLength={150}
                                            placeholder='Ingresa la actividad'/>
                                    </Form.Item>
                                </Col>
                                
                                <Col span={7}>
                                    <Form.Item
                                        label="Fecha Compromiso"
                                        name={"fechaCompromiso-" + index}
                                    >
                                        <DatePicker
                                            style={{width: '100%'}}
                                            format='DD-MM-YYYY'
                                            onChange={(date, dateString) => actividad.fechaCompromiso = dateString}
                                            defaultValue={actividad.fechaCompromisoEditar}
                                            disabledDate={disabledDate}
                                            placeholder='Selecciona fecha compromiso'
                                            showToday={false}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        label="Fecha Realización"
                                        name={"fechaRealizacion-" + index}
                                    >
                                        <DatePicker
                                            style={{width: '100%'}}
                                            format='DD-MM-YYYY'
                                            onChange={(date, dateString) => actividad.fechaRealizacion = dateString}
                                            defaultValue={actividad.fechaRealizacionEditar}
                                            disabledDate={disabledDate}
                                            placeholder='Selecciona fecha realización'
                                            showToday={false}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>)}
                        <Row justify='space-around'>
                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Editar Plan de Correctivo</Button>
                            </Col>
                        </Row>   
                    </Form>
                    ):false}
                </Spin>
            </Modal>
        );
    }
}
 const mapStateToProps = state => {
     return {
        showModalEditarPlanCorrectivo: state.GestionDeCalidadReducer.showModalEditarPlanCorrectivo,
        fetchingEditarPlanCorrectivo: state.GestionDeCalidadReducer.fetchingEditarPlanCorrectivo,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        page: state.GestionDeCalidadReducer.page,
        busqueda: state.GestionDeCalidadReducer.busqueda,
        orden: state.GestionDeCalidadReducer.orden,
        estado: state.GestionDeCalidadReducer.estado,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        planCorrectivoSeleccionado: state.GestionDeCalidadReducer.planCorrectivoSeleccionado,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarPlanCorrectivo: (page, busqueda) => {
            dispatch ({ type: 'MODAL_EDITAR_PLAN_CORRECTIVO', showModalEditarPlanCorrectivo: false, planCorrectivoSeleccionado: null, page, busqueda })
        },
        onEditarPlanCorrectivo: (formulario, page, busqueda, orden, planCorrectivoSeleccionado, listaActividades, estado) => {
            dispatch({ type: "EDITAR_PLAN_CORRECTIVO_REQUEST", formulario, page, busqueda, orden, planCorrectivoSeleccionado, listaActividades, estado })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onActualizarOrigen: (planCorrectivoSeleccionado, page, busqueda, orden, estado) => {
            dispatch ({ type: 'MODAL_EDITAR_PLAN_CORRECTIVO', showModalEditarPlanCorrectivo: true, planCorrectivoSeleccionado, page, busqueda, orden, estado })
       },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarPlanCalidad);
