import React, {Component} from 'react';
import { Form, Spin, Row, Col, Input, Button, DatePicker, message, Select} from "antd";
import {connect} from 'react-redux';
import moment from 'moment';
import ModalContraseña from '../ModalContraseña';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option } = Select;
const { TextArea } = Input;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class CerradoForm extends Component {
    formRef = React.createRef();
    state = {
        btnRegistrar: false,
        formulario: null,
    }
    render() {
        const { fetchingCambioEstadoSeguimiento, onActualizarSeguimiento, fetchingListaUsuariosParam, listaUsuariosParam,
            folioSolicitudServicioSeleccionado, page, busqueda, listaTrazabilidad,
            onShowModalContraseña, isEspecial, onLimpiarListaUsuario, onBuscarUsuario,
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');

        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        };

        if(isEspecial){
            onActualizarSeguimiento(this.state.formulario, folioSolicitudServicioSeleccionado, page, busqueda);
            onShowModalContraseña(false, false);
       };

        const onFinish = (formulario) => {
            if(permisosUsuario.especialSolicitudDeServicio){
                this.setState({ formulario })
                onShowModalContraseña(true, false);
                
            } else {
                if(formulario.usuarioPYRS === "" && formulario.usuarioAuditoriaTecnica === "") {
                    message.warning('Se requiere "Usuario PYRS" y "Usuario Auditoria Tecnica" para registrar cierre de folio')
                } else {
                    onActualizarSeguimiento(formulario, folioSolicitudServicioSeleccionado, page, busqueda);
                }
            }
            
        };

        const onChangeDate = (value) => {
            console.log(value);
            if (value || listaTrazabilidad) {
                let fechaEstado;
                
                if(listaTrazabilidad.length > 0){
                    if(listaTrazabilidad[0].fechaEstado){
                        console.log(listaTrazabilidad[0].fechaEstado);
                        fechaEstado = listaTrazabilidad[0].fechaEstado
                    } else {
                        fechaEstado = null;
                    }
                } else {
                    fechaEstado = null
                }

                let resultadoValidacionFechaAlta = moment(value, 'DD-MM-YYYY HH:mm').isAfter(moment(folioSolicitudServicioSeleccionado.fechaAlta, 'DD-MM-YYYY HH:mm'));
                //let resultadoValidacionFechaTerminacion = moment(value, 'DD-MM-YYYY HH:mm').isAfter(moment(folioSolicitudServicioSeleccionado.fechaTerminacion, 'DD-MM-YYYY HH:mm'));
                let resultadoValidacionFechaEstado = moment(value, 'DD-MM-YYYY HH:mm').isSameOrAfter(moment(fechaEstado, 'DD-MM-YYYY HH:mm'));
                if (!resultadoValidacionFechaAlta) {
                    message.warning('La fecha seleccionada tiene que ser mayor a fecha Alta de la solicitud de servicio', 7);
                    this.setState({ btnRegistrar: true })
                } else if (!resultadoValidacionFechaEstado && fechaEstado !== null) {
                    message.warning('La fecha seleccionada tiene que ser mayor a la fecha de realizado  de la solicitud de servicio', 7);
                    this.setState({ btnRegistrar: true })
                } else {
                    this.setState({ btnRegistrar: false })
                }

            }
        };

        const buscarUsuario = (value) => {
            if(value.length>=3){
                console.log(value)
                onBuscarUsuario(value);
            }
            else{
                onLimpiarListaUsuario();
            }
        };

        return (
            <div>
                <Spin spinning={fetchingCambioEstadoSeguimiento}>
                    {folioSolicitudServicioSeleccionado ? (
                    <Form {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        initialValues={folioSolicitudServicioSeleccionado}
                        ref={this.formRef}
                    >
                        <Row type='flex' justify='space-around'>
                            <Col span={23}>
                                <Form.Item
                                    label="Observaciones"
                                    name="observacionesF"
                                >
                                <TextArea
                                    rows={1}
                                    pattern="^^[ñíóáéú a-zA-Z 0-9]+$"
                                    title="Observaciones de cierre debe tener letras y numeros."
                                    maxLength={100} placeholder='Ingrese observaciones' />
                                    </Form.Item>
                             </Col>

                            <Col span={23}>
                                <Form.Item
                                    label="Usuario PYRS"
                                    name="usuarioPYRS"
                                >
                                    {/* <Select
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Escribe el usuario a buscar"
                                        loading={fetchingListaUsuariosParam}
                                        showSearch
                                        filterOption={false}
                                        onSearch={buscarUsuario}
                                        notFoundContent={null}
                                    >
                                        {listaUsuariosParam.map(option => <Option key={option.aMaterno ? option.nombre + ' ' + option.aPaterno + ' ' + option.aMaterno : option.nombre + ' ' + option.aPaterno}>{option.aMaterno ? option.nombre + ' ' + option.aPaterno + ' ' + option.aMaterno : option.nombre + ' ' + option.aPaterno}</Option>)}
                                    </Select> */}

                                    <Select allowClear style={{ width: '100%' }}  placeholder="Seleccione el usuario PYRS" loading={this.props.fetchingListaUsuariosByIdsServiciosTipoPYRS} showSearch >
                                        {this.props.listaUsuariosByIdsServiciosTipoPYRS.map(option => 
                                            <Option key={option.aMaterno ? option.nombre + ' ' + option.aPaterno + ' ' + option.aMaterno : option.nombre + ' ' + option.aPaterno}>
                                                {option.aMaterno ? 
                                                    <>
                                                        {option.nombre + ' ' + option.aPaterno + ' ' + option.aMaterno }
                                                    </>
                                                    : 
                                                    <>
                                                        {option.nombre + ' ' + option.aPaterno }
                                                    </>
                                                }
                                            </Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={23}>
                                <Form.Item
                                    label="Usuario Auditoría Técnica"
                                    name="usuarioAuditoriaTecnica"
                                >
                                    {/* <Select
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Escribe el usuario a buscar"
                                        loading={fetchingListaUsuariosParam}
                                        showSearch
                                        filterOption={false}
                                        onSearch={buscarUsuario}
                                        notFoundContent={null}
                                    >
                                        {listaUsuariosParam.map(option => <Option key={option.aMaterno ? option.nombre + ' ' + option.aPaterno + ' ' + option.aMaterno : option.nombre + ' ' + option.aPaterno}>{option.aMaterno ? option.nombre + ' ' + option.aPaterno + ' ' + option.aMaterno : option.nombre + ' ' + option.aPaterno}</Option>)}
                                    </Select> */}

                                    <Select allowClear style={{ width: '100%' }}  placeholder="Seleccione el usuario AT" loading={this.props.fetchingListaUsuariosByIdsServiciosTipoAT} showSearch >
                                        {this.props.listaUsuariosByIdsServiciosTipoAT.map(option => 
                                            <Option key={option.aMaterno ? option.nombre + ' ' + option.aPaterno + ' ' + option.aMaterno : option.nombre + ' ' + option.aPaterno}>
                                                {option.aMaterno ? 
                                                    <>
                                                        {option.nombre + ' ' + option.aPaterno + ' ' + option.aMaterno }
                                                    </>
                                                    : 
                                                    <>
                                                        {option.nombre + ' ' + option.aPaterno }
                                                    </>
                                                }
                                            </Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={23}>
                                <Form.Item
                                    label="Fecha de Cierre"
                                    name="fechaCierre"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        format='DD-MM-YYYY HH:mm'
                                        placeholder={["Seleccione fecha y hora En Cierre"]}
                                        onChange={onChangeDate}
                                        showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={23}>
                                <Button 
                                    className="btnFiltrar" 
                                    style={{ width: '40%' }} 
                                    disabled={this.state.btnRegistrar}
                                    htmlType="submit">
                                    Registrar     
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    ) : false}
                </Spin>
                <ModalContraseña/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        fetchingCambioEstadoSeguimiento: state.SolicitudDeServiciosReducer.fetchingCambioEstadoSeguimiento,
        folioSolicitudServicioSeleccionado: state.SolicitudDeServiciosReducer.folioSolicitudServicioSeleccionado,
        listaTrazabilidad: state.SolicitudDeServiciosReducer.listaTrazabilidad,
        page: state.SolicitudDeServiciosReducer.page,
        busqueda: state.SolicitudDeServiciosReducer.busqueda,
        isEspecial: state.SolicitudDeServiciosReducer.isEspecial,
        fetchingListaUsuariosParam: state.ConfiguracionYSeguridadReducer.fetchingListaUsuariosParam,
        listaUsuariosParam: state.ConfiguracionYSeguridadReducer.listaUsuariosParam,

        listaUsuariosByIdsServiciosTipoRS: state.ConfiguracionYSeguridadReducer.listaUsuariosByIdsServiciosTipoRS,
        fetchingListaUsuariosByIdsServiciosTipoRS: state.ConfiguracionYSeguridadReducer.fetchingListaUsuariosByIdsServiciosTipoRS,

        listaUsuariosByIdsServiciosTipoAT: state.ConfiguracionYSeguridadReducer.listaUsuariosByIdsServiciosTipoAT,
        fetchingListaUsuariosByIdsServiciosTipoAT: state.ConfiguracionYSeguridadReducer.fetchingListaUsuariosByIdsServiciosTipoAT,

        listaUsuariosByIdsServiciosTipoPYRS: state.ConfiguracionYSeguridadReducer.listaUsuariosByIdsServiciosTipoPYRS,
        fetchingListaUsuariosByIdsServiciosTipoPYRS: state.ConfiguracionYSeguridadReducer.fetchingListaUsuariosByIdsServiciosTipoPYRS,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onActualizarSeguimiento: (formulario, folioSolicitudServicioSeleccionado, page, busqueda) => {
            dispatch({ type: "CERRAR_FOLIO_SS_REQUEST", formulario, folioSolicitudServicioSeleccionado, page, busqueda});
        },
        onShowModalContraseña: (showModalContraseña, isEspecial) => {
            dispatch({ type: "SHOW_MODAL_CONTRASEÑA", showModalContraseña , isEspecial})
        },
        onBuscarUsuario: (busqueda) => {
            dispatch({ type: 'LISTA_USUARIOS_PARAM_REQUEST', busqueda})
        },
        onLimpiarListaUsuario: () => {
            dispatch({ type: 'LISTA_USUARIOS_PARAM_SUCCESS', listaUsuariosParam: []})
        },
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(CerradoForm);