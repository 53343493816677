import React from 'react';
import {connect} from 'react-redux';
import {Table, Row, Col, Modal} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

class ModalDetalleAsignaciones extends  React.Component {

    state = {
        page : 1
    }

    render () {

        const {servicioPlanificadoSeleccionado, showModalDetalleAsignacionesSP, 
            onShowModalDetalle} = this.props;

        let asignacionByIdAsignacionServicioPlanificado = [];

        const columns = servicioPlanificadoSeleccionado?.programa == "Rutina" ? 
        [{
            title: 'Ubicacion',
            dataIndex: 'ubicacion',
            key: 'ubicacion',
            // width: '40%',
        }]: [{
            title: 'Descripción',
            dataIndex: 'descripcion',
            key: 'descripcion',
            // width: '40%',
        },{
            title: 'Código Interno',
            dataIndex: 'codigoInterno',
            key: 'codigoInterno',
            // width: '40%',
        },{
            title: 'Ubicacion',
            dataIndex: 'ubicacion',
            key: 'ubicacion',
            // width: '40%',
        }]

        const handleCancel = () => {
            onShowModalDetalle()
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
        }
        return (
            <div>
                {servicioPlanificadoSeleccionado ? (
                    <Modal
                        visible={showModalDetalleAsignacionesSP}
                        title={`Lista Detalle de Asignaciones Servicios Planificados: '${servicioPlanificadoSeleccionado.rutina}'`}
                        onCancel={handleCancel}
                        footer={false}
                        width='80%'
                    >    
                    <div>
                        <Table
                            size='small'
                            rowKey= {servicioPlanificadoSeleccionado?.programa == "Rutina" ? "ubicacion" : "codigoInterno"}
                            columns={columns}
                            dataSource={servicioPlanificadoSeleccionado.asignacionByIdAsignacionServicioPlanificado}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: asignacionByIdAsignacionServicioPlanificado.length, simple: true, current: this.state.page }}
                        />     
                    </div>                    
                    </Modal>
                ) : false}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalDetalleAsignacionesSP: state.ServiciosPlanificadosReducer.showModalDetalleAsignacionesSP,
        servicioPlanificadoSeleccionado: state.ServiciosPlanificadosReducer.servicioPlanificadoSeleccionado,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalDetalle: () => {
            dispatch( {type: 'SHOW_MODAL_DETALLE_ASIGNACIONES_SP', showModalDetalleAsignacionesSP: false, servicioPlanificadoSeleccionado: null})
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalDetalleAsignaciones);