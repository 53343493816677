import React, { Component } from 'react';
import { Form, Input, Button, Col, Spin, Modal, Select, InputNumber, message, DatePicker, Radio } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalNuevaFallaDeDisponibilidad extends Component {
    formRef = React.createRef();
    render() {
        const { 
            fetchingNuevoFallasDeDisponibilidad, 
            showModalNuevoFallasDeDisponibilidad, 
            onNuevaFallaDeDisponibilidad, 
            onshowModalNuevoFallasDeDisponibilidad, 
            limpiarFormulario,
            listaInfoProyecto,
        } = this.props;
        console.log('listaInfoProyecto: ', listaInfoProyecto);
        const onFinish = formulario => {
            console.log('Success:', formulario);
            const rangeValue = formulario['rangoVigencia'];
            formulario.rangoVigencia = rangeValue[0].format('YYYY-MM-DD') + "," + rangeValue[1].format('YYYY-MM-DD');
            onNuevaFallaDeDisponibilidad(formulario, this.props.page);
        };

        console.log('limpiar formulario', limpiarFormulario);
        
        if (limpiarFormulario && limpiarFormulario === 'crearFallasDeDisponibilidad') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }
            

        

        const handleCancel = () => {
            onshowModalNuevoFallasDeDisponibilidad();
            this.formRef.current.resetFields();
        }

        const changeDecimales = (value) => {
            let valueDecimal = parseInt(value);
            if(isNaN(valueDecimal)){
            valueDecimal = 0;
            }
            this.formRef.current.setFieldsValue({'pesoArea': valueDecimal});
        }
        const fechas = (rangeValue) => {
            if(listaInfoProyecto.fechaInicio){
            const diferencia = listaInfoProyecto.fechaInicio.diff(moment(rangeValue[0].format('YYYY-MM-DD')), "days");
            
            if(diferencia > 0){
                message.error('No es posible seleccionar una fecha de inicio menor al inicio de contrato del proyecto', 7);
                this.formRef.current.resetFields(['rangoVigencia']);
            }
            }
            else{
            message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
            this.formRef.current.resetFields(['rangoVigencia']);
            }
        }

        return (
            <Modal
            visible={showModalNuevoFallasDeDisponibilidad}
            title='Nueva Categoría'
            onCancel={handleCancel}
            footer={false}
            >
               {listaInfoProyecto ? (
                <Spin spinning={fetchingNuevoFallasDeDisponibilidad}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Form.Item
                            label="Categoría de falla"
                            name="fallaDisponibilidad"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Input
                            pattern="^^[ñíóáéú a-zA-Z0-9 ]+$"
                            title="Categoría de fallas debe tener letras." 
                            maxLength={30} placeholder='Categoría de fallas de disponibilidad' />
                            {/* <Select allowClear
                            style={{ width: '100%' }}
                            placeholder="Selecciona una categoría"
                            >
                                <Option value="1">Categoría 1</Option>
                                <Option value="2">Categoría 2</Option>
                            </Select> */}
                        </Form.Item>

                        <Form.Item
                            label="Descripción"
                            name="descripcion"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <TextArea maxLength={250} placeholder="Ingrese descripción" autoSize pattern="^[ñíóáéú a-zA-Z ]+$"  title="Descripción sin carácteres especiales" />
                        </Form.Item>

                        <Form.Item
                              label="Porcentaje de deducción"
                              name="porcentajeDeduccion"
                              rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                         >
                              <InputNumber min={1.00} max={100.00} placeholder="Ingresa el porcentaje de deducción por falla de disponibilidad"
                              formatter={value => `${value}%`}
                              parser={value => value.replace('%', '')}
                              onChange={changeDecimales}
                              step={1.00} />
                         </Form.Item>

                         <Form.Item
                              label="Fechas vigencia"
                              name="rangoVigencia"
                              rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                         >
                              <RangePicker
                              style={{width: '100%'}} format='DD-MM-YYYY' onChange={fechas} placeholder={["Ingresa fecha inicio","Fecha fin"]}
                              defaultValue={[, moment(listaInfoProyecto.fechaVigencia, 'DD-MM-YYYY')]}
                              disabled={[false, true]}
                              />
                         </Form.Item>
                        
                        <Col span={12}>
                            <Button className="btnFiltrar" htmlType="submit">Crear Categoría</Button>
                        </Col>
                    </Form>
                </Spin>
               ):<h1>Favor de agregar un contrato en 'Información del proyecto'</h1>}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingNuevoFallasDeDisponibilidad: state.MecanismosDePagosReducer.fetchingNuevoFallasDeDisponibilidad,
        showModalNuevoFallasDeDisponibilidad: state.MecanismosDePagosReducer.showModalNuevoFallasDeDisponibilidad,
        page: state.MecanismosDePagosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onshowModalNuevoFallasDeDisponibilidad: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_FALLA_DISPONIBILIDAD', showModalNuevoFallasDeDisponibilidad: false})
        },
        onNuevaFallaDeDisponibilidad: ( formulario, page ) => {
            dispatch({ type: "NUEVO_FALLA_DISPONIBILIDAD_REQUEST" , formulario: formulario, page: page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevaFallaDeDisponibilidad);