import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css'
import ModalNuevaClaveMetodoSupervision from './ModalNuevoClaveMetodoDeSupervision';
import ModalEditarClaveMetodoSupervision from './ModalEditarClaveMetodoDeSupervision';

const confirm = Modal.confirm;
const { Search } = Input;

class CatalogoClavesMetodosSupervision extends React.Component {
    state = {
        page: 1,
        busqueda: '',
    }

    componentDidMount() {
        this.props.onGetClavesMetodosSupervision(1);
    }

    render() {
        const { fetchingListaClavesMetodosSupervisionBusqueda, listaClavesMetodosSupervisionBusqueda, onGetClavesMetodosSupervision,
            onModalNuevaClaveMetodoSupervision, onModalEditarClaveMetodoSupervision, onEstadoClaveMetodoSupervision, listaClavesMetodosSupervisionBusquedaCantidad } = this.props;
                
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columns = [{
            title: 'Clase',
            dataIndex: 'claveMetodoSupervision',
            key: 'claveMetodoSupervision',
        }, {
            title: 'Descripción corta',
            dataIndex: 'descripcionC',
            key: 'descripcionC',
            width: '20%',
        },{
            title: 'Descripción larga',
            dataIndex: 'descripcionL',
            key: 'descripcionL',
        },{
            title: 'Activo',
            dataIndex: 'activo',
            key: 'activo',
            render: (text, record) => (
                <Switch checked={text} disabled={!permisosUsuario.metodoDeSupervisionActivoinactivo} onChange = {() => handleDesactivar(record)} />
            ),
        }, {
            title: 'Editar',
            key: 'editar',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.editarMetodoDeSupervision}  onClick={() => onEditar(record)}><EditOutlined /></a>
            ),
        },
        ];

        const onBuscarClavesMetodosSupervision = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            });
            console.log(busqueda);
            onGetClavesMetodosSupervision(1, busqueda);
        } 

        const handleDesactivar = (key) => {
            console.log(key.idClaveMetodoSupervision);
            let page = this.state.page
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del método de supervisión "' + key.descripcionC + '"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoClaveMetodoSupervision(key.idClaveMetodoSupervision, page);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }

        const onEditar = (key) => {
            onModalEditarClaveMetodoSupervision(key, this.state.page);

        }

        const onNuevaClaveMetodoSupervision = () => {
            console.log("Modal");
            onModalNuevaClaveMetodoSupervision(this.state.page);
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            onGetClavesMetodosSupervision(pagination.current, this.state.busqueda);
        }


        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search className='buscarModulos' placeholder='Ingresar método de supervisión.' onSearch={value => onBuscarClavesMetodosSupervision(value)} />
                    </Col>
                    {/* <Col span={10}>
                        <Button onClick={onNuevaClaveMetodoSupervision} disabled={!permisosUsuario.crearMetodoDeSupervision} className='nuevoUsuario'>Nuevo Método de Supervisión</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nuevo Método de Supervisión' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevaClaveMetodoSupervision} disabled={!permisosUsuario.crearMetodoDeSupervision} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table
                            size="small"
                            rowKey="claveMetodoSupervision"
                            columns={columns}
                            dataSource={listaClavesMetodosSupervisionBusqueda}
                            loading={fetchingListaClavesMetodosSupervisionBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                        // scroll={scroll}
                            onChange={handleTableChange}
                            pagination={{ total: listaClavesMetodosSupervisionBusquedaCantidad, simple: true, current: this.state.page }}
                        />
                    </Col>
                </Row>
                <ModalNuevaClaveMetodoSupervision/>
                <ModalEditarClaveMetodoSupervision/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        listaClavesMetodosSupervisionBusqueda: state.MecanismosDePagosReducer.listaClavesMetodosSupervisionBusqueda,
        fetchingListaClavesMetodosSupervisionBusqueda: state.MecanismosDePagosReducer.fetchingListaClavesMetodosSupervisionBusqueda,
        listaClavesMetodosSupervisionBusquedaCantidad: state.MecanismosDePagosReducer.listaClavesMetodosSupervisionBusquedaCantidad,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetClavesMetodosSupervision: (page, busqueda) => {
            dispatch({ type: "CLAVES_METODOS_SUPERVISION_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
        },
        onEstadoClaveMetodoSupervision: (idClaveMetodoSupervision, page) => {
            dispatch({ type: "ESTADO_CLAVE_METODO_SUPERVISION_REQUEST", idClaveMetodoSupervision: idClaveMetodoSupervision, page: page});
        },
        onModalNuevaClaveMetodoSupervision: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_CLAVE_METODO_SUPERVISION", showModalNuevaClaveMetodosSupervision: true, page: page })
        },
        onModalEditarClaveMetodoSupervision: (ClaveMetodosSupervisionSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_CLAVE_METODO_SUPERVISION", showModalEditarClaveMetodosSupervision: true, ClaveMetodosSupervisionSeleccionado:ClaveMetodosSupervisionSeleccionado, page:page});
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoClavesMetodosSupervision);