import React, { Component } from 'react';
import { Form, Button, Row, Col, Modal, InputNumber, Input, Divider, message } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const confirm = Modal.confirm;
class ListaPonderaciones extends Component {
    formRef = React.createRef();
    render() {
        const { onCambiarVista, listaPonderacionesActivas, ponderacionSeleccionado, listaPonderacionesAreas
        } = this.props;


        const onFinish = formulario => {
            console.log('formulario');
            console.log(formulario);
            let listaPonderacion = [];
            let ponderacionEditados = [];
            let noIguales = true;
            for (var key in formulario) {
                if (Object.prototype.hasOwnProperty.call(formulario, key)) {
                    let idCampo = key.split('-');
                    if (idCampo.length === 2) {
                        let idPonderacionArea = idCampo[1];
                        let campo = idCampo[0];
                        let valor = formulario[key];
    
                        let ponderacionFormulario = {[campo]: valor, 'idPonderacionArea': parseInt(idPonderacionArea), 'agregar': true};
                        listaPonderacion.push(ponderacionFormulario);
                    }
                }
            }
            
            for(let i=0; i<listaPonderacionesActivas.length; i++){
                for(let x=0; x<listaPonderacion.length; x++){
                    if(listaPonderacionesActivas[i].pesoArea === listaPonderacion[x].pesoArea && listaPonderacionesActivas[i].idPonderacionArea === listaPonderacion[x].idPonderacionArea){
                        if(ponderacionSeleccionado.idPonderacionArea === listaPonderacion[x].idPonderacionArea){
                            listaPonderacion[x].agregar = true;
                        }
                        else{
                            listaPonderacion[x].agregar = false;
                        }
                    }
                }
            }

            for(let i=0; i<listaPonderacionesActivas.length; i++){
                for(let x=0; x<listaPonderacion.length; x++){
                    if(listaPonderacionesActivas[i].pesoArea !== listaPonderacion[x].pesoArea && listaPonderacion[x].agregar && listaPonderacionesActivas[i].idPonderacionArea === listaPonderacion[x].idPonderacionArea ){
                        if(!ponderacionEditados[0]){
                            
                            if(ponderacionSeleccionado.idPonderacionArea === listaPonderacion[x].idPonderacionArea){
                                if(this.props.formulario.pesoArea === listaPonderacion[x].pesoArea){
                                    listaPonderacion[x].areaFuncional = listaPonderacionesActivas[i].areaFuncionalByIdAreaFuncional.codigoArea + ' - ' + listaPonderacionesActivas[i].areaFuncionalByIdAreaFuncional.areaFuncional + ' - ' + listaPonderacionesActivas[i].areaFuncionalByIdAreaFuncional.claveArea
                                    ponderacionEditados.push(listaPonderacion[x]);
                                    listaPonderacion[x].agregar = false;
                                    noIguales = false;
                                }
                            }
                            else{
                                listaPonderacion[x].areaFuncional = listaPonderacionesActivas[i].areaFuncionalByIdAreaFuncional.codigoArea + ' - ' + listaPonderacionesActivas[i].areaFuncionalByIdAreaFuncional.areaFuncional + ' - ' + listaPonderacionesActivas[i].areaFuncionalByIdAreaFuncional.claveArea
                                ponderacionEditados.push(listaPonderacion[x]);
                            }
                        }
                        for(let y=0; y<ponderacionEditados.length; y++){
                            if(ponderacionEditados[y].idPonderacionArea === listaPonderacion[x].idPonderacionArea){
                                listaPonderacion[x].agregar = false;
                            }
                            else if (listaPonderacion[x].agregar){
                                if(ponderacionSeleccionado.idPonderacionArea === listaPonderacion[x].idPonderacionArea){
                                    if(this.props.formulario.pesoArea === listaPonderacion[x].pesoArea){
                                        listaPonderacion[x].areaFuncional = listaPonderacionesActivas[i].areaFuncionalByIdAreaFuncional.codigoArea + ' - ' + listaPonderacionesActivas[i].areaFuncionalByIdAreaFuncional.areaFuncional + ' - ' + listaPonderacionesActivas[i].areaFuncionalByIdAreaFuncional.claveArea
                                        ponderacionEditados.push(listaPonderacion[x]);
                                        listaPonderacion[x].agregar = false;
                                        noIguales = false;
                                    }
                                }
                                else{
                                    listaPonderacion[x].areaFuncional = listaPonderacionesActivas[i].areaFuncionalByIdAreaFuncional.codigoArea + ' - ' + listaPonderacionesActivas[i].areaFuncionalByIdAreaFuncional.areaFuncional + ' - ' + listaPonderacionesActivas[i].areaFuncionalByIdAreaFuncional.claveArea
                                    ponderacionEditados.push(listaPonderacion[x]);
                                    listaPonderacion[x].agregar = false;
                                }
                            }
                        } 
                    }
                }
            }
            console.log('ponderacionEditados para confirm:', ponderacionEditados)
            const formularioAnterior = this.props.formulario;
            if(noIguales){
                message.warning('El valor peso área funcional ' + ponderacionSeleccionado.idPonderacionArea + ', debe ser el mismo que ingresó al editar (' + this.props.formulario.pesoArea + ').')
                this.formRef.current.resetFields(['pesoArea-' + ponderacionSeleccionado.idPonderacionArea]);
            }
            else{
                confirm({
                    title: 'Estos son los Pesos de Área Funcional que fueron modificados. ¿Estás seguro de modificarlos?',
                    content: 
                    <div>
                        {ponderacionEditados.map(ponderacionEditado =>
                        <Row justify='space-around' key={ponderacionEditado.idPonderacionArea}>
                            <Divider>{'Ponderación de Área Funcional ' + ponderacionEditado.idPonderacionArea}</Divider>
                            <Col span={14}>
                                <p><strong>Área Funcional: </strong>{ponderacionEditado.areaFuncional}</p>
                            </Col>
                            <Col span={8}>
                                <p><strong>Peso área: </strong>{ponderacionEditado.pesoArea}%</p>
                            </Col>
                        </Row>
                        )}
                    </div>,
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    width: '50%',
                    onOk() {
                        onCambiarVista(4, formularioAnterior, ponderacionEditados);
                    },
                    onCancel() {
                        onCambiarVista(1, null);
                    },
                });
            }
        };
        
        const changeDecimales = (value, idPonderacionArea) => {
            let valueDecimal = parseFloat(value).toFixed(4);
            let campo = 'pesoArea-' + idPonderacionArea;
            if(isNaN(valueDecimal)){
                valueDecimal = 0.0000
            }
            this.formRef.current.setFieldsValue({[campo]: valueDecimal});
        }

        return (
            <Form {...layout}
            name="formulario"
            onFinish={onFinish}
            
            initialValues={listaPonderacionesAreas}
            ref={this.formRef}
            >
                <div>
                    <h2>Favor de ingresar nuevamente los ponderadores de áreas funcionales</h2>
                    {listaPonderacionesActivas.map(ponderacionArea => 
                    <Row justify='space-around' key={ponderacionArea.idPonderacionArea}>
                        <Divider>{'Ponderación de Área Funcional ' + ponderacionArea.idPonderacionArea}</Divider>
                        <Col span={11}>
                            <Form.Item
                                label="Peso de área"
                                name={'pesoArea-' + ponderacionArea.idPonderacionArea}
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <InputNumber min={0.0001} max={99.9999} placeholder="Ingresa el peso de área"
                                    defaultValue={0.0001}
                                    formatter={value => `${value}%`}
                                    parser={value => value.replace('%', '')}
                                    onChange={value => changeDecimales(value, ponderacionArea.idPonderacionArea)}
                                    step={0.0010} />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="Área funcional"
                                name='areaFuncional'
                            >
                                <Input disabled={true} placeholder={ponderacionArea.areaFuncionalByIdAreaFuncional.codigoArea + ' - ' + ponderacionArea.areaFuncionalByIdAreaFuncional.areaFuncional + ' - ' + ponderacionArea.areaFuncionalByIdAreaFuncional.claveArea} ></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    )}
                    <Col span={24} className='marginBoton'>
                        <br/>
                        <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Guardar Cambios</Button>
                    </Col>
                </div>
            </Form>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingLogin: state.LoginReducer.fetchingLogin,
        listaPonderacionesActivas: state.CatalogosGeneralesReducer.listaPonderacionesActivas,
        formulario: state.CatalogosGeneralesReducer.formulario,
        ponderacionSeleccionado: state.CatalogosGeneralesReducer.ponderacionSeleccionado,
        formulario: state.CatalogosGeneralesReducer.formulario,
        listaPonderacionesAreas: state.CatalogosGeneralesReducer.listaPonderacionesAreas,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onCambiarVista: (vistaModalPonderacion, formulario, ponderacionEditados) => {
            dispatch({ type: 'CAMBIAR_VISTA_PONDERACION', vistaModalPonderacion: vistaModalPonderacion, formulario: formulario, ponderacionEditados: ponderacionEditados })
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaPonderaciones);