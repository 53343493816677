import React, { Component } from 'react';
import { Modal, Row, Col, Table, Switch } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

class ModalHistorialPersonal extends Component {
    formRef = React.createRef();
    state={
        page: 1,
        formulario: {
            fechaInicio: null,
            fechaFin: null,
        }
    }
    render() {
        const { showModalHistorialPersonal, onShowModalHistorialPersonal, fetchingHistorialPersonal, listaHistorialPersonal,
            listaHistorialPersonalCantidad, onModalHistorialPersonal, personalSeleccionado,
        } = this.props;

        const columns = [{
                title: 'Fecha Edición',
                dataIndex: 'fechaAlta',
                key: 'fechaAlta',
                width: '10%',
                sorter: true,
            },{
                title: 'Usuario Editó',
                dataIndex: 'usuarioEdito',
                key: 'usuarioEdito',
                width: '15%',
                sorter: true,
            },{
                title: 'Número de empleado',
                dataIndex: 'numeroEmpleado',
                key: 'numeroEmpleado',
                width: '10%',
                sorter: true,
            },{
                title: 'RFC',
                dataIndex: 'rfc',
                key: 'rfc',
                width: '10%',
            },{
                title: 'Nombre(s)',
                dataIndex: 'nombre',
                key: 'nombre',
                width: '15%',
                sorter: true,
            },{
                title: 'Apellido Paterno',
                dataIndex: 'aPaterno',
                key: 'aPaterno',
                width: '10%',
                sorter: true,
            },{
                title: 'Apellido Materno',
                dataIndex: 'aMaterno',
                key: 'aMaterno',
                width: '10%',
                sorter: true,
            },{  
                title: 'ID Biométrico',
                dataIndex: 'idEmpleadoBiometrico',
                key: 'idEmpleadoBiometrico',
                width: '10%',
            },{    
                title: 'Proveedor',
                dataIndex: 'proveedor',
                key: 'proveedor',
                width: '12%',
                sorter: true,
            },{
                
                title: 'Servicio',
                dataIndex: 'servicio',
                key: 'servicio',
                width: '10%',
                sorter: true,
            },{            
                title: 'Puesto',
                dataIndex: 'puesto',
                key: 'puesto',
                width: '10%',
                sorter: true,
            },{
                title: 'Turno y Horario',
                dataIndex: 'turnoHorario',
                key: 'turnoHorario',
                width: '15%',
                sorter: true,
            }, {
                title: 'Condición',
                dataIndex: 'activo',
                key: 'activo',
                width: '10%',
                align: 'center',
                render: (text, record) => (
                    <Switch checked={text} disabled={true} />
                ),
            },{
                title: 'Razón de baja',
                dataIndex: 'razonBaja',
                key: 'razonBaja',
                width: '10%',
                render: (text, record) => (
                    <p>{record.activo ? '' : text}</p>
                ),
            }
        ];
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onModalHistorialPersonal(personalSeleccionado.idPersonal, pagination.current, this.state.formulario);
            
        }

        const handleCancel = () => {
            onShowModalHistorialPersonal();
        }

        const scroll = {"x": 1900 | true, "y": 1000};

        return (
            <Modal
                visible={showModalHistorialPersonal}
                title='Historial de Ediciones'
                onCancel={handleCancel}
                footer={false}
                width={'70%'}
            >
                <Row justify='space-around'>
                    <Col span={24}>
                        <br/>
                        <Table 
                            size="small" 
                            rowKey="idPersonal" 
                            columns={columns}
                            dataSource={listaHistorialPersonal}
                            loading={fetchingHistorialPersonal}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaHistorialPersonalCantidad, simple: true, current: this.state.page}}
                            scroll={scroll}
                            showSorterTooltip={true}
                        />         
                    </Col>
                </Row>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingHistorialPersonal: state.RecursosHumanosReducer.fetchingHistorialPersonal,
        showModalHistorialPersonal: state.RecursosHumanosReducer.showModalHistorialPersonal,
        listaHistorialPersonal: state.RecursosHumanosReducer.listaHistorialPersonal,
        listaHistorialPersonalCantidad: state.RecursosHumanosReducer.listaHistorialPersonalCantidad,
        personalSeleccionado: state.RecursosHumanosReducer.personalSeleccionado,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalHistorialPersonal: () => {
            dispatch({ type: 'SHOW_MODAL_HISTORIAL_PERSONAL', showModalHistorialPersonal: false })
        },
        onModalHistorialPersonal: (personalSeleccionado, page, formulario) => {
            dispatch({ type: 'HISTORIAL_PERSONAL_REQUEST', personalSeleccionado, page, formulario });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalHistorialPersonal);