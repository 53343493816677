import React, {Component} from 'react';
import { Form, Spin, Row, Col, Input, Button, DatePicker, Select, message, Upload} from "antd";
import { UploadOutlined, ArrowRightOutlined} from '@ant-design/icons';
import {connect} from 'react-redux';
import moment from 'moment';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { TextArea } = Input;
const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class TerminadoForm extends Component {
  formRef = React.createRef();

  componentDidMount() {
    this.props.onGetAreas()
    this.props.onGetUnidades()
    this.props.onGetInteriores()
    this.props.onGetSubInteriores()
  };

  state = {
    filePDF: false,
    botonPDF: false,
    disabledBtn: false,
    idAreaFuncional: null,
    idUnidadFuncional: null,
    idInteriorFuncional: null,
    idSubInteriorFuncional: null,
  };

  render() {
    const { fetchingCambioEstadoSeguimiento, onSiguienteVista ,folioSolicitudServicioSeleccionado,
            listaAreasFuncionalesActivas,fetchingListaAreasFuncionalesActivas,
            fetchingListaUnidadesFuncionalesActivas,listaUnidadesFuncionalesActivas,
            listaInteriorFuncional,fetchingListaInteriorFuncional,listaSubinteriorFuncional,
            fetchingListaSubinteriorFuncional, onGetUnidades, onGetInteriores,listaTrazabilidad,
            onGetSubInteriores, obtenerUbicacion, ubicacionSeguimiento, page, busqueda, formularioTerminado
    } = this.props;

    const currentYear = moment().format('YYYY');
    console.log('currentYear', currentYear);

    const onFinish = (formularioTerminado) => {
        console.log('formulario terminado', formularioTerminado);
        onSiguienteVista(true, formularioTerminado, null, null, null, folioSolicitudServicioSeleccionado);
    };
  
    const onFinishFailed = errorInfo => {
        console.log('Failed: ', errorInfo);
    };    

    const onUnidadesFuncionales = (idAreaFuncional) => {            
       this.formRef.current.setFieldsValue({'idUnidadFuncional': undefined,'idInteriorFuncional': undefined, 'idSubInteriorFuncional': undefined});
       onGetUnidades(idAreaFuncional);
       this.setState({ idAreaFuncional: idAreaFuncional }); 
    };    

    const onInterioresFuncionales = (idUnidadFuncional) => {
        this.formRef.current.setFieldsValue({'idInteriorFuncional': undefined,'idSubInteriorFuncional': undefined });
        onGetInteriores(this.state.idAreaFuncional, idUnidadFuncional);
        this.setState({ idUnidadFuncional: idUnidadFuncional});
    };     

    const onSubInterioresFuncionales = (idInteriorFuncional) => {
        this.formRef.current.setFieldsValue({'idSubInteriorFuncional': undefined });
        onGetSubInteriores(this.state.idAreaFuncional, this.state.idUnidadFuncional, idInteriorFuncional);
        this.setState({ idInteriorFuncional: idInteriorFuncional });
        if (!this.state.idSubInteriorFuncional || !this.state.idInteriorFuncional) {
          this.state.idSubInteriorFuncional = ''
          this.state.idInteriorFuncional = ''
        }
        obtenerUbicacion(this.state.idAreaFuncional, this.state.idUnidadFuncional, this.state.idInteriorFuncional, this.state.idSubInteriorFuncional);
    };     

    const onChangePDF = (info) => {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        else{
            this.setState({
                botonPDF: true,
                botonEditar: true,
            })
        }
        var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
        if (info.file.status === 'done') {
            if(!reGex.test(info.file.name)){
                message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                this.formRef.current.resetFields(['pdf']);
            }
            else{
                message.success(`${info.file.name} se cargó de forma correcta`);
                this.setState({
                    filePDF: true
                });
            }
            this.setState({
                botonPDF: false,
                botonEditar: false,
            });
        } else if (info.file.status === 'error') {
            if(!reGex.test(info.file.name)){
                message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                this.formRef.current.resetFields(['pdf']);
            }
            else{
                message.error(`${info.file.name} se cargó de forma incorrecta`);
                this.setState({
                    filePDF: true
                });
            }
            this.setState({
                botonPDF: false,
                botonEditar: false,
            });
         }
    }
    const onRemovePDF = () => {
        this.setState({
            filePDF: false,
            botonPDF: false,
        });
    }
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };     

    const onChangeDate = (value) => {
        //console.log('fecha Estado:', listaTrazabilidad[0].fechaEstado);
        if(value || listaTrazabilidad){
            let fechaEstado;
            if(listaTrazabilidad.length > 0){
                if(listaTrazabilidad[0].fechaEstado){
                    console.log(listaTrazabilidad[0].fechaEstado);
                    fechaEstado = listaTrazabilidad[0].fechaEstado
                } else {
                    fechaEstado = null;
                }
            }
            
            let resultadoValidacionFechaAlta = moment(value, 'DD-MM-YYYY HH:mm').isAfter(moment(folioSolicitudServicioSeleccionado.fechaAlta, 'DD-MM-YYYY HH:mm'));
            let resultadoValidacionFechaEstado = moment(value, 'DD-MM-YYYY HH:mm').isAfter(moment(fechaEstado, 'DD-MM-YYYY HH:mm'));
            let resultadoValidacionAñoCurrent = moment(value, 'YYYY').isAfter(moment(currentYear, 'YYYY'));
            console.log(resultadoValidacionFechaAlta, resultadoValidacionFechaEstado, resultadoValidacionAñoCurrent)
            if (!resultadoValidacionFechaAlta) {
                message.warning('La fecha seleccionada tiene que ser mayor a la fecha de alta de la solicitud de servicio', 5);
                this.setState({ disabledBtn: true })
            } else if(!resultadoValidacionFechaEstado) {
                message.warning('La fecha seleccionada tiene que ser mayor a la ultima fecha de estado de la solicitud de servicio', 5);
                this.setState({ disabledBtn: true })
            } else if(!resultadoValidacionAñoCurrent) {
                message.warning('La fecha seleccionada tiene que ser durante el año en curso', 5);
                this.setState({ disabledBtn: true })
            } else {
                this.setState({ disabledBtn: false })
            }
        }
    };       

    return ( 
         <div>
            <Spin spinning={fetchingCambioEstadoSeguimiento}>
                {folioSolicitudServicioSeleccionado ? (
                <Form {...layout}
                   name='formularioTerminado'
                   onFinish={onFinish}
                   
                   ref={this.formRef} 
                   initialValues={formularioTerminado || folioSolicitudServicioSeleccionado}
                 >
                     <Row type='flex' justify='space-between'>
                         <Col span={23}>
                          <Form.Item
                             label="Área Funcional"
                             name="area"
                             rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                             >
                              <Select allowClear
                                disabled={true}
                                style={{ width: '100%' }}
                                placeholder="Seleccione el área funcional"
                                loading={fetchingListaAreasFuncionalesActivas}
                                onChange={onUnidadesFuncionales}
                                >
                                 {listaAreasFuncionalesActivas.map(option => <Option key={option.idAreaFuncional}>{ option.areaFuncional }</Option>)}
                                 </Select>
                             </Form.Item>
                         </Col>
                           
                         <Col span={23}>
                             <Form.Item
                                label="Unidades Funcionales"
                                name="unidad"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                              >
                                 <Select allowClear
                                    disabled={true}
                                    style={{ width: '100%' }}
                                    placeholder="Seleccione el unidad funcional"
                                    loading={fetchingListaUnidadesFuncionalesActivas}
                                    onChange={onInterioresFuncionales}
                                 >
                                    {listaUnidadesFuncionalesActivas.map(option => <Option key={option.idUnidadFuncional}>{ option.unidadFuncional }</Option>)}
                                 </Select>
                             </Form.Item>
                          </Col>
                            
                           <Col span={23}>
                               <Form.Item
                                  label="Interior"
                                  name="interior"
                                >
                                   <Select allowClear
                                       disabled={true}
                                       style={{ width: '100%' }}
                                       placeholder="Seleccione el interior funcional"
                                       loading={fetchingListaInteriorFuncional}
                                       onChange={onSubInterioresFuncionales}
                                   >
                                    {listaInteriorFuncional.map(option => <Option key={option.idInteriorFuncional}>{ option.interiorFuncional }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            
                             {/* <Col span={23}>
                                 <Form.Item
                                    label="Subinterior"
                                    name="subInterior"
                                 >
                                 <Select allowClear
                                      disabled={true}
                                      style={{ width: '100%' }}
                                      placeholder="Seleccione el subinterior funcional"
                                      loading={fetchingListaSubinteriorFuncional}
                                    >
                                     {listaSubinteriorFuncional.map(option => <Option key={option.idSubInteriorFuncional}>{ option.subInteriorFuncional }</Option>)}
                                     </Select>
                                 </Form.Item>
                             </Col> */}

                             <Col span={23}>
                                   <Form.Item
                                      label="Referencia"
                                      name="referencia">
                                    <TextArea
                                        rows={1}
                                        pattern="^^[ñíóáéú a-zA-Z 0-9]+$"
                                        title="Referencia debe tener letras y numeros."
                                        maxLength={100} placeholder='Ingrese Referencia' />
                                  </Form.Item>
                                </Col>

                               <Col span={23}>
                                  <Form.Item
                                    label="Fecha de Diagnostico"
                                    name="fechaDiagnostico"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                   >
                                    <DatePicker
                                       style={{ width: '100%' }}
                                       format='DD-MM-YYYY HH:mm'
                                       placeholder={["Seleccione fecha y hora Diagnostico"]}
                                       onChange={onChangeDate}
                                       showTime={{ defaultValue: moment('00:00', 'HH:mm') }} 
                                    />
                                  </Form.Item>
                               </Col>
                               
                                <Col span={23}>
                                   <Form.Item
                                      label="Diagnostico"
                                      name="diagnostico"
                                      rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <TextArea
                                        rows={1}
                                        pattern="^^[ñíóáéú a-zA-Z 0-9]+$"
                                        title="Nombre debe tener letras y numeros."
                                        maxLength={100} placeholder='Ingrese Diagnostico' />
                                  </Form.Item>
                                </Col>
                                <Col span={23}>
                                   <Form.Item
                                      label="Actividades Realizadas"
                                      name="actividadesRealizadas"
                                      rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <TextArea
                                        rows={1}
                                        pattern="^^[ñíóáéú a-zA-Z 0-9]+$"
                                        title="Nombre debe tener letras y numeros."
                                        maxLength={100} placeholder='Ingrese Actividades Realizadas' />
                                  </Form.Item>
                                </Col>
                                <Col span={23}>
                                   <Form.Item
                                      label="Personal"
                                      name="personal"
                                      rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Input pattern="^^[ñíóáéú a-zA-Z 0-9]+$"
                                           title="Nombre debe tener letras y numeros."
                                           maxLength={100} placeholder='Ingrese Personal'/>
                                  </Form.Item>
                                </Col>
                                <Col span={23}>
                                  <Form.Item
                                    label="Fecha de Realizado"
                                    name="fechaTerminacion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                   >
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        format='DD-MM-YYYY HH:mm'
                                        placeholder={["Seleccione fecha y hora realizado"]}
                                        onChange={onChangeDate}
                                        showTime={{ defaultValue: moment('00:00', 'HH:mm') }} 
                                    />
                                  </Form.Item>
                               </Col>
                               
                                <Col span={23}>
                                    <Form.Item
                                        label="Archivo PDF"
                                        name="pdf"
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                  <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                    {this.state.filePDF === false ? (
                                    <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                        <UploadOutlined /> Click para subir archivo PDF
                                    </Button>
                                    ):false}
                                   </Upload>
                                 </Form.Item>
                              </Col>
                              
                            </Row>
                        
                               <Col span={23} className='marginBoton'>
                                    <br />
                                    <Button disabled={this.state.disabledBtn} className="btnFiltrar" style={{ width: '100%' }} htmlType="submit"><ArrowRightOutlined /> Siguiente Paso</Button>
                                </Col>
                        </Form>
                ): false}
                    </Spin>

            </div>
         );
    }
}

const mapStateToProps = (state) => {
    return {
        fetchingCambioEstadoSeguimiento: state.SolicitudDeServiciosReducer.fetchingCambioEstadoSeguimiento,
        formularioTerminado: state.SolicitudDeServiciosReducer.formularioTerminado,
        page: state.SolicitudDeServiciosReducer.page,
        busqueda: state.SolicitudDeServiciosReducer.busqueda,
        folioSolicitudServicioSeleccionado: state.SolicitudDeServiciosReducer.folioSolicitudServicioSeleccionado,
        ubicacionSeguimiento: state.SolicitudDeServiciosReducer.ubicacionSeguimiento,

        listaAreasFuncionalesActivas: state.AlmacenesReducer.listaAreasFuncionalesActivas,
        fetchingListaAreasFuncionalesActivas: state.AlmacenesReducer.fetchingListaAreasFuncionalesActivas,
        listaUnidadesFuncionalesActivas: state. AlmacenesReducer.listaUnidadesFuncionalesActivas,
        fetchingListaUnidadesFuncionalesActivas: state.AlmacenesReducer.fetchingListaUnidadesFuncionalesActivas,
        listaInteriorFuncional: state.AlmacenesReducer.listaInteriorFuncional,
        fetchingListaInteriorFuncional: state.AlmacenesReducer.fetchingListaInteriorFuncional,
        listaSubinteriorFuncional: state.AlmacenesReducer.listaSubinteriorFuncional,
        fetchingListaSubinteriorFuncional: state.AlmacenesReducer.fetchingListaSubinteriorFuncional,
        listaTrazabilidad: state.SolicitudDeServiciosReducer.listaTrazabilidad,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSiguienteVista: (cambiarVistaModalEstadoTerminado, formularioTerminado, listaEpp, listaHerramienta, listaInsumosT, folioSolicitudServicioSeleccionado) => {
            dispatch({ type: "CAMBIAR_VISTA_MODAL_ESTADO_TERMINADO", cambiarVistaModalEstadoTerminado, formularioTerminado, listaEpp, listaHerramienta, listaInsumosT, folioSolicitudServicioSeleccionado});
        },
        onGetAreas: () => {
            dispatch({ type: "LISTA_AREA_FUNCIONAL_ACTIVA_REQUEST", });
        },
        onGetUnidades: (idAreaFuncional) => {
            dispatch({ type: "LISTA_UNIDAD_FUNCIONAL_REQUEST", idAreaFuncional: idAreaFuncional });
        },
        onGetInteriores: (idAreaFuncional, idUnidadFuncional) => {
            dispatch({ type: "LISTA_INTERIOR_FUNCIONAL_REQUEST", idAreaFuncional: idAreaFuncional, idUnidadFuncional: idUnidadFuncional });
        },
        onGetSubInteriores: (idAreaFuncional, idUnidadFuncional, idInteriorFuncional) => {
            dispatch({ type: "LISTA_SUBINTERIOR_FUNCIONAL_REQUEST", idAreaFuncional: idAreaFuncional, idUnidadFuncional: idUnidadFuncional, idInteriorFuncional: idInteriorFuncional });
        },
        obtenerUbicacion: (idAreaFuncional, idUnidadFuncional, idInteriorFuncional, idSubInteriorFuncional) => {
            dispatch({ type: "OBTENER_UBICACION_REQUEST", idAreaFuncional, idUnidadFuncional, idInteriorFuncional, idSubInteriorFuncional });
        },
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(TerminadoForm);