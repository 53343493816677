import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, Row, Select, message } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Option } = Select;
class ModalEditarRol extends Component {
    formRef = React.createRef();
    render() {
        
        const { fetchingEditarSubfamilias, showModalEditarSubfamilias, onEditarSubfamilias, onShowModalEditarSubfamilias, fetchingListaFamiliasDeActivos, listaFamiliasDeActivos, fetchingListaCentroDeCostosActivos, listaCentroDeCostosActivos,
            SubfamiliasSeleccionado, limpiarFormulario, fetchingListaFamiliasActivasServ, listaFamiliasActivasServ, onListaFamilias, fetchingListaServiciosActivosF, listaServiciosActivosF
        } = this.props;
        console.log('SubfamiliasSeleccionado en modal');
        console.log(SubfamiliasSeleccionado);
        const onFinish = formulario => {
            console.log('Success:', formulario);
            onEditarSubfamilias(formulario, SubfamiliasSeleccionado, this.props.page, listaFamiliasDeActivos, listaCentroDeCostosActivos);
        };

        if (limpiarFormulario && limpiarFormulario === 'editarSubfamilia') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        

        const onServicioSeleccionado = (idServicio) =>{
            this.formRef.current.setFieldsValue({'familia': []});
            onListaFamilias(idServicio);
        }

        const handleCancel = () => {
            onShowModalEditarSubfamilias()
        }

        return (
            <Modal
            visible={showModalEditarSubfamilias}
            title='Editar Subfamilia'
            onCancel={handleCancel}
            footer={false}
            >
                <Spin spinning={fetchingEditarSubfamilias}>
                    {SubfamiliasSeleccionado ? (
                    <Form {...layout}
                    name="formulario"
                    initialValues={SubfamiliasSeleccionado}
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={24}>
                                <Form.Item
                                    label="Subfamilia"
                                    name="subFamilia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={80} placeholder="Ingrese subfamilia, el nombre sólo debe contener letras" pattern="^[a-zñA-ZÑ ÁÉÓÚÍáéóíú]+$" title="El nombre sólo debe contener letras"/>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                        label="Servicio"
                                        name="servicio"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el servicio"
                                            loading={fetchingListaServiciosActivosF}
                                            onChange= { onServicioSeleccionado }
                                        >
                                            {listaServiciosActivosF.map(option => <Option key={option.idServicio}>{ option.nombre + ': ' + option.nombreC }</Option>)}
                                        </Select>
                            </Form.Item>
                            </Col>
                            
                            {/*{listaFamiliasActivasServ[0]?( */}
                            <Col span={24}>
                                <Form.Item
                                        label="Familia"
                                        name="familia"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona la familia de activos"
                                            loading={fetchingListaFamiliasActivasServ}
                                            //disabled={!(listaFamiliasActivasServ.length > 0)}
                                        >
                                            {listaFamiliasActivasServ.map(option => <Option key={option.familia}>{ option.familia }</Option>)}
                                        </Select>
                                </Form.Item>
                            </Col>
                            {/*): false} */}

                            <Col span={24}>
                                <Form.Item
                                        label="Centro de costos"
                                        name="centroCosto"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el centro de costo"
                                             loading={fetchingListaCentroDeCostosActivos}
                                        >
                                             {listaCentroDeCostosActivos.map(option => <Option key={option.centroCosto}>{ option.centroCosto }</Option>)}
                                        </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Button className="btnFiltrar" htmlType="submit">Editar Subfamilia</Button>
                            </Col>
                        </Row>
                    </Form>
                    ):false}
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarSubfamilias: state.CatalogosGeneralesReducer.fetchingEditarSubfamilias,
        showModalEditarSubfamilias: state.CatalogosGeneralesReducer.showModalEditarSubfamilias,
        fetchingListaPermisos: state.CatalogosGeneralesReducer.fetchingListaPermisos,
        listaPermisos: state.CatalogosGeneralesReducer.listaPermisos,
        SubfamiliasSeleccionado: state.CatalogosGeneralesReducer.SubfamiliasSeleccionado,
        page: state.CatalogosGeneralesReducer.page,
        fetchingListaCentroDeCostosActivos: state.CatalogosGeneralesReducer.fetchingListaCentroDeCostosActivos,
        listaCentroDeCostosActivos: state.CatalogosGeneralesReducer.listaCentroDeCostosActivos,
        fetchingListaFamiliasDeActivos: state.CatalogosGeneralesReducer.fetchingListaFamiliasDeActivos,
        listaFamiliasDeActivos: state.CatalogosGeneralesReducer.listaFamiliasDeActivos,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaFamiliasActivasServ: state.CatalogosGeneralesReducer.fetchingListaFamiliasActivasServ,
        listaFamiliasActivasServ: state.CatalogosGeneralesReducer.listaFamiliasActivasServ,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarSubfamilias: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_SUBFAMILIAS', showModalEditarSubfamilias: false, SubfamiliasSeleccionado: null})
        },
        onEditarSubfamilias: ( formulario, SubfamiliasSeleccionado, page, listaFamiliasActivasServ, listaCentroDeCostosActivos ) => {
            dispatch({ type: "EDITAR_SUBFAMILIAS_REQUEST" , formulario: formulario, SubfamiliasSeleccionado: SubfamiliasSeleccionado, page: page, listaFamiliasActivasServ: listaFamiliasActivasServ, listaCentroDeCostosActivos: listaCentroDeCostosActivos });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        },
        onListaFamilias: (idServicio) => {
            dispatch({ type: 'LISTA_FAMILIAS_ACTIVAS_SERVICIO_REQUEST', idServicio: idServicio })
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarRol);