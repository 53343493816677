import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, Upload, Row, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalEditarRol extends Component {
    formRef = React.createRef();
    state={
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
    }
    render() {
        const { fetchingEditarExpediente, showModalEditarExpediente, onEditarExpediente, onShowModalEditarExpediente,
            expedienteSeleccionado, limpiarFormulario, onLimpiarFormulario, tipoEdicion
        } = this.props;

        if(limpiarFormulario && limpiarFormulario === 'editarExpediente'){
            console.log('entró a limpiar');
            this.formRef.current.resetFields();
            this.setState({
                filePDF: false,
                botonPDF: false,
                botonEditar: false,
            });
            onLimpiarFormulario();
        }

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onEditarExpediente(formulario, expedienteSeleccionado, this.props.page, tipoEdicion);
        };
        

        const handleCancel = () => {
            onShowModalEditarExpediente()
        }
        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                })
            }
            var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,30}$");
            if (info.file.status === 'done') {
                if(info.file.size > 70000000){
                    message.error('El tamaño máximo aceptado es de 70 MB', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if(info.file.size > 70000000){
                    message.error('El tamaño máximo aceptado es de 70 MB', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }
        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };

        return (
            <Modal
            visible={showModalEditarExpediente}
            title='Editar Expediente'
            onCancel={handleCancel}
            footer={false}
            width={'70%'}
            >
                <Spin spinning={fetchingEditarExpediente}>
                    {expedienteSeleccionado ? (
                    <Form {...layout}
                    name="formulario"
                    initialValues={expedienteSeleccionado}
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            {!tipoEdicion ? (
                            <Col span={11}>
                                <Form.Item
                                    label="Clave expediente"
                                    name="clave"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={25} placeholder="Ingresa la clave"/>
                                </Form.Item>
                            </Col>
                            ):false}
                            {!tipoEdicion ? (
                            <Col span={11}>
                                <Form.Item
                                    label="Descripción del expediente"
                                    name="descripcion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={250} placeholder="Ingresa la descripción" />
                                </Form.Item>
                            </Col>
                            ):false}
                            <Col span={11}>
                                <Form.Item
                                    label="Archivo PDF"
                                    name="pdf"
                                >
                                    <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                        {this.state.filePDF === false ? (
                                        <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                            <UploadOutlined /> Click para subir archivo PDF
                                        </Button>
                                        ):false}
                                    </Upload>
                                </Form.Item>
                            </Col>

                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Editar Expediente</Button>
                            </Col>
                        </Row>
                    </Form>
                    ):false}
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarExpediente: state.InformacionOperativaReducer.fetchingEditarExpediente,
        showModalEditarExpediente: state.InformacionOperativaReducer.showModalEditarExpediente,
        expedienteSeleccionado: state.InformacionOperativaReducer.expedienteSeleccionado,
        page: state.InformacionOperativaReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        tipoEdicion: state.InformacionOperativaReducer.tipoEdicion,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarExpediente: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_EXPEDIENTE', showModalEditarExpediente: false, expedienteSeleccionado: null, page: null})
        },
        onEditarExpediente: ( formulario, expedienteSeleccionado, page, tipoEdicion ) => {
            dispatch({ type: "EDITAR_EXPEDIENTE_REQUEST" , formulario, expedienteSeleccionado, page, tipoEdicion});
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarRol);