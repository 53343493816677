import React, {Component} from 'react'
import { Form, Select, Button, Col, Spin, Modal, Row, Input, Tooltip } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import moment from 'moment';

const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalEditarPreguntaEncuesta extends Component {
    formRef = React.createRef();
    state={
        listaAños: [],
        añoPasado: false,
    }
    render() { 
        const {
            onNuevaPreguntaEncuesta, onShowModalEditarPreguntaEncuesta, showModalEditarPreguntaEncuesta,
            fetchingEditarPreguntaEncuesta, page, preguntaEncuestaSeleccionado, busqueda,
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario, this.state.añoPasado);
            onNuevaPreguntaEncuesta(formulario, page, preguntaEncuestaSeleccionado, busqueda);
        };

        const handleCancel = () => {
            onShowModalEditarPreguntaEncuesta(page, busqueda);
            this.formRef.current.resetFields();
        }
        return (
            <>
                {preguntaEncuestaSeleccionado ? (
                <Modal
                    visible={showModalEditarPreguntaEncuesta}
                    title={'Editar Pregunta de Encuesta de Satisfacción para el Año ' + preguntaEncuestaSeleccionado.anio}
                    onCancel={handleCancel}
                    footer={false}
                    width='50%'
                >
                    <Spin spinning={fetchingEditarPreguntaEncuesta}>
                        <Form {...layout}
                            name="formulario"
                            onFinish={onFinish}
                            ref={this.formRef}
                            initialValues={preguntaEncuestaSeleccionado}
                        >
                            <Row justify='space-around'>
                            <Col span={11}>
                                    <Form.Item
                                        label="Servicio"
                                        name="servicio"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Input disabled={true} />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label="Pregunta"
                                        name="pregunta"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Input maxLength={200} placeholder='Ingresa la pregunta' />
                                    </Form.Item>
                                </Col>

                                <Col span={11} className='marginBoton'>
                                    <br/>
                                    <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Editar Pregunta de Encuesta de Satisfacción</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Modal>
                ):false}
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        showModalEditarPreguntaEncuesta: state.GestionDeCalidadReducer.showModalEditarPreguntaEncuesta,
        fetchingEditarPreguntaEncuesta: state.GestionDeCalidadReducer.fetchingEditarPreguntaEncuesta,
        preguntaEncuestaSeleccionado: state.GestionDeCalidadReducer.preguntaEncuestaSeleccionado,
        page: state.GestionDeCalidadReducer.page,
        busqueda: state.GestionDeCalidadReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarPreguntaEncuesta: (page, busqueda) => {
            dispatch ({ type: 'MODAL_EDITAR_PREGUNTA_ENCUESTA', showModalEditarPreguntaEncuesta: false, page, busqueda })
        },
        onNuevaPreguntaEncuesta: (formulario, page, preguntaEncuestaSeleccionado, busqueda) => {
            dispatch({ type: "EDITAR_PREGUNTA_ENCUESTA_REQUEST", formulario, page, preguntaEncuestaSeleccionado, busqueda })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarPreguntaEncuesta);