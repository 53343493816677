import React, { Component } from 'react';
import { Form, InputNumber, Input, Button, Col, Row, Divider, Spin, message, DatePicker, Upload, Steps, Typography} from 'antd';
import { UploadOutlined, FileImageFilled } from '@ant-design/icons';

import { connect } from 'react-redux';
import '../../stylesheets/ConfiguracionYSeguridad/Catalogos.css'

//Componente de Contrato
import Contrato from './Contrato';

const { Dragger } = Upload;
const { Text, Paragraph } = Typography;
const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 }, };
const { Step } = Steps;
const steps = [
    {
        title: 'Crear Proyecto',
        content: 'First-content',
    },
    {
        title: 'Asignar Contrato',
        content: 'Second-content',
    },
];

class NuevoInfoProyecto extends Component {
    componentDidMount() {
        this.props.onGetContratos();
    }
    formRef = React.createRef();
    state = {
        fileImagen: false,
        botonImagen: false,
        filePlano: false,
        botonPlano: false,
        botoEditar: false,
        current: 0,
        valido: false,
        imageListaPlano: [],
        imageLista: [],
    }
    next() {
        const current = this.state.current + 1;
        this.setState({ current });
    }

    prev() {
        const current = this.state.current - 1;
        this.setState({ current });
    }

    render() {
        const { fetchingNuevoListaInfoProyecto,
            onNuevoInfoProyecto,
            listaInfoProyecto,
            fetchingListaInfoProyecto,
            siguientePaso,
            onSiguiente,
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        if (listaInfoProyecto && this.state.valido === false) {
            if(listaInfoProyecto.idProyecto){
                console.log('Entro al if');
                console.log(listaInfoProyecto);
                if (!listaInfoProyecto.idContrato) {
                    this.setState({
                        current: 1,
                        valido: true
                    });
                }
            }
            
        }
        const { current } = this.state;

        const onFinish = formulario => {
            console.log('formulario');
            console.log(formulario);
            formulario['fechaInicio'] = formulario['fechaInicio'].format('DD-MM-YYYY')
            formulario['fechaInicioOperacion'] = formulario['fechaInicioOperacion'].format('DD-MM-YYYY')
            onNuevoInfoProyecto(formulario);


        }
        if (siguientePaso === true) {
            this.next();
            onSiguiente()
        }
        
        //imagen
        const onChangeImagen = (info, tipo) => {
            console.log('info');
            console.log(info);

            let imageLista = [...info.fileList];
   
            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            imageLista = imageLista.slice(-1);

            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else {
                this.setState({
                    botonImagen: true,
                    botonPlano: true,
                    botonEditar: true,
                });
                if(tipo === 'imagen'){
                    this.setState({
                        imageLista: imageLista,
                    });
                } else {
                    this.setState({
                        imageListaPlano: imageLista,
                    });
                }
            }
            if (info.file.status === 'done') {
                var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,30}$");

                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['imagen']);
                    this.setState({imageLista: [], imageListaPlano: []});
                }
                else {
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    if(tipo === 'imagen'){
                        this.setState({
                            fileImagen: true,
                            imageLista: imageLista,
                        });
                    }
                    else{
                        this.setState({
                            filePlano: true,
                            imageListaPlano: imageLista,
                        });
                    }
                }
                this.setState({
                    botonImagen: false,
                    botonEditar: false,
                })
            } else if (info.file.status === 'error') {
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['imagen']);
                    this.setState({imageLista: [], imageListaPlano: []});
                }
                else {
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    if(tipo === 'imagen'){
                        this.setState({
                            fileImagen: true,
                            imageLista: imageLista,
                        });
                    }
                    else{
                        this.setState({
                            filePlano: true,
                            imageListaPlano: imageLista,
                        });
                    }
                }
                this.setState({
                    botonImagen: false,
                    botonEditar: false,
                })
            }
        }
        const onRemoveImagen = (tipo) => {
            if(tipo === 'imagen'){
                this.setState({
                    fileImagen: false,
                    botonImagen: false,
                    imageLista: [],
                });
            }
            else{
                this.setState({
                    filePlano: false,
                    botonImagen: false,
                    imageListaPlano: [],
                });
            }
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };
        return (
            <Spin spinning={fetchingNuevoListaInfoProyecto || fetchingListaInfoProyecto} >
                <Steps current={current}>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title} />
                    ))}
                </Steps>
                {steps[current].content === "First-content" ? (
                    <Form {...layout}
                        name="formulario"
                        onFinish={onFinish}
                        
                        ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="RFC"
                                    name="rfc"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={12}
                                        minLength={12} placeholder="Ingresa el RFC"
                                        pattern='^([A-ZÑ&]{3}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$'
                                        title='No es un formato válido para RFC persona moral' />
                                </Form.Item>
                            </Col>

                            <Col span={11}>
                                <Form.Item
                                    label="Razón social"
                                    name="razonSocial"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={80} placeholder="Ingresa la razon social" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Nombre del Cliente"
                                    name="nombreCliente"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={80} placeholder="Ingresa el nombre del cliente" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Centro Negocios"
                                    name="centroNegocios"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={40} placeholder="Ingresa el centro de negocios" />
                                </Form.Item>
                            </Col>


                            <Col span={11}>
                                <Form.Item
                                    label="Años de Vigencia del Contrato"
                                    name="vigencia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <InputNumber placeholder="Ingresa los años de vigencia del contrato" />
                                </Form.Item>
                            </Col>

                            <Col span={11}>
                                <Form.Item
                                    label="Pago Anual de Referencia"
                                    name="pagoAnualReferencia"

                                >
                                    <InputNumber formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        placeholder="Ingresa el pago anual de referencia"
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={11}>
                                <Form.Item
                                    label="Poblacion PTV maxima"
                                    name="poblacionPtvMax"
                                    rules={[{ required: true, message: 'Favor de llenar el campo (Campo editable)' }]}
                                >
                                    <InputNumber formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        placeholder="Ingresa la poblacion PTV maxima"
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={11}>
                                <Form.Item
                                    label="Pago de Referencia Variable"
                                    name="pagoReferenciaVar"
                                >
                                    <InputNumber formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        placeholder="Ingresa el pago de referencia variable"
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={11}>
                                <Form.Item
                                    label="Poblacion PTV minima"
                                    name="poblacionPtvMin"
                                >
                                    <InputNumber formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        placeholder="Ingresa la poblacion PTV minima"
                                        // parser={value => value.replace(/\s?|(,*)/g, '')}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={11}>
                                <Form.Item
                                    label="Pago Anual de Inversion"
                                    name="pagoAnualInversion"
                                >
                                    <InputNumber formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        placeholder="Ingresa el pago anual de inversion"
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={11}>
                                <Form.Item
                                    label="Fecha inicio del proyecto"
                                    name="fechaInicio"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <DatePicker style={{ width: '100%' }} format='DD-MM-YYYY' />
                                </Form.Item>
                            </Col>

                            <Col span={11}>
                                <Form.Item
                                    label="Fecha inicio de operacion"
                                    name="fechaInicioOperacion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <DatePicker style={{ width: '100%' }} format='DD-MM-YYYY' />
                                </Form.Item>
                            </Col>

                            <Divider orientation="left">Dirección</Divider>
                        </Row>
                        <Row justify='space-between' gutter={[8,8]}>
                            <Col span={6}>
                                <Form.Item
                                    label="Calle"
                                    name="calle"
                                    rules={[{ required: true, message: 'Favor de llenar el campo (Campo editable)' }]}
                                >
                                    <Input maxLength={40} placeholder="Ingresa la calle" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Número exterior"
                                    name="numeroExterior"
                                    rules={[{ required: true, message: 'Favor de llenar el campo (Campo editable)' }]}
                                >
                                    <Input maxLength={10} placeholder="Ingresa el numero exterior" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Número interior"
                                    name="numeroInterior"
                                // rules={[{ required: true, message: 'Favor de llenar el campo (Campo editable)' }]}
                                >
                                    <Input maxLength={5} placeholder="Ingresar el numero interior" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Nombre colonia"
                                    name="nombreColonia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo (Campo editable)' }]}
                                >
                                    <Input maxLength={50} placeholder="Ingresar el nombre de la colonia" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Código Postal"
                                    name="cp"
                                    rules={[{ required: true, message: 'Favor de llenar el campo (Campo editable)' }]}
                                >
                                    <Input minLength='5' maxLength='5' placeholder="Ingresa el codigo postal"
                                        title="El código postal debe ser números"
                                        pattern="^[0-9]+$" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Municipio o alcaldía"
                                    name="municipio"
                                    rules={[{ required: true, message: 'Favor de llenar el campo (Campo editable)' }]}
                                >
                                    <Input name="municipio" maxLength={50} placeholder="Ingrese Municipio o delegacion"
                                        title="El municipio debe contener sólo letras"
                                        pattern="^[a-zñA-ZÑ ÁÉÓÚÍáéóíú]+$" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Estado"
                                    name="estado"
                                    rules={[{ required: true, message: 'Favor de llenar el campo (Campo editable)' }]}
                                >
                                    <Input name="estado" maxLength={50}
                                        placeholder="Ingresa el estado"
                                        title="El estado debe contener sólo letras"
                                        pattern="^[a-zñA-ZÑ ÁÉÓÚÍáéóíú]+$" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item
                                    label="Imagen"
                                    name="imagen"
                                >
                                    {/* <Upload customRequest={dummyRequest} onChange={e => onChangeImagen(e, 'imagen')} onRemove={() => onRemoveImagen('imagen')} accept='.jpg, .jpeg, .png' multiple={false}>
                                        {this.state.fileImagen === false ? (
                                            <Button disabled={this.state.botonImagen} className='btnCargaArchivos'>
                                                <UploadOutlined /> Click para subir imagen
                                            </Button>
                                        ) : false}
                                    </Upload> */}

                                    <Dragger fileList={this.state.imageLista} customRequest={dummyRequest} onChange={e => onChangeImagen(e, 'imagen')} onRemove={() => onRemoveImagen('imagen')} accept='.jpg, .jpeg, .png' multiple={false} style={{borderRadius:'1em'}}>
                                        <Row justify='center'>
                                            <FileImageFilled className='iconTabPane'/>
                                            <Col span={24} style={{paddingTop:'1em'}}>
                                                <Paragraph>
                                                    <Text strong> Click o arrastra a ésta área el achivo: jpg, png, jpeg </Text>
                                                </Paragraph>
                                            </Col>
                                        </Row>
                                    </Dragger>

                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Plano del centro"
                                    name="plano"
                                    rules={[{ required: true, message: 'Favor de adjuntar un plano' }]}
                                >
                                    {/* <Upload customRequest={dummyRequest} onChange={e => onChangeImagen(e, 'plano')} onRemove={() => onRemoveImagen('plano')} accept='.jpg, .jpeg, .png' multiple={false}>
                                        {this.state.filePlano === false ? (
                                            <Button disabled={this.state.botonPlano} className='btnCargaArchivos'>
                                                <UploadOutlined /> Click para subir plano
                                            </Button>
                                        ) : false}
                                    </Upload> */}

                                    <Dragger fileList={this.state.imageListaPlano} customRequest={dummyRequest} onChange={e => onChangeImagen(e, 'plano')} onRemove={() => onRemoveImagen('plano')} accept='.jpg, .jpeg, .png, .svg' multiple={false} style={{borderRadius:'1em'}}>
                                        <Row justify='center'>
                                            <FileImageFilled className='iconTabPane'/>
                                            <Col span={24} style={{paddingTop:'1em'}}>
                                                <Paragraph>
                                                    <Text strong> Click o arrastra a ésta área el achivo: jpg, png, jpeg </Text>
                                                </Paragraph>
                                            </Col>
                                        </Row>
                                    </Dragger>

                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify='end'>
                            <Col span={11} className='marginBoton'>
                                <br />
                                {current === 0 ? (
                                    <Button className="btnFiltrar2" style={{ width: '100%' }} htmlType="submit" disabled={!permisosUsuario.crearProyecto || !permisosUsuario.crearProyectoMod2} >Siguiente Paso</Button>
                                ) : false}
                            </Col>
                        </Row>
                    </Form>
                ) : false}

                {steps[current].content === "Second-content" ? (
                    <Contrato />
                ) : false}
            </Spin>
        );
    }
}
const mapStateToProps = state => {
    return {
        fetchingListaInfoProyecto: state.InformacionDelProyectoReducer.fetchingListaInfoProyecto,
        fetchingNuevoListaInfoProyecto: state.InformacionDelProyectoReducer.fetchingNuevoListaInfoProyecto,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        siguientePaso: state.InformacionDelProyectoReducer.siguientePaso,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onNuevoInfoProyecto: (formulario) => {
            dispatch({ type: "NUEVO_INFOPROYECTO_REQUEST", formulario: formulario });
        }, onGetContratos: () => {
            dispatch({ type: "LISTA_CONTRATOS_REQUEST" });
        }, onSiguiente: () => {
            dispatch({ type: "NUEVO_INFOPROYECTO_FAILURE" })
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(NuevoInfoProyecto);