import axios from "axios";

//const endpoint = "https://papantla-services.herokuapp.com/";
//const endpoint = "https://papantla-dev.herokuapp.com/";
const endpoint = 'https://morelos-dev.herokuapp.com/';
//const endpoint = "https://papantla-demo.herokuapp.com/";

//const endpoint = "http://172.24.0.170:8080/"

//const endpoint = 'https://papantla-services-jose.herokuapp.com/';
//const endpoint = 'https://papantla-dev-jose.herokuapp.com/';

//const endpoint = 'https://papantla-secret-jose.herokuapp.com/';
//const endpoint = 'https://papantla-secret.herokuapp.com/';

// servicio catalogo de variables
export function obtenerVariablesGeneralCall(token, param, campo, orden, page){
    return axios({
        method: "get",
		url: endpoint + "servicios/variables?param="+param+"&field="+campo+"&order="+orden+"&page="+page+"&offset=10",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};

// servicio catalogo de activar/desactivar variables
export function activarVariablesCall(token, idVariable){
    return axios({
        method: "get",
		url: endpoint + "servicios/variables/activar/" + idVariable,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};

// servicio editar variables
export function editarVariablesCall(token, idVariable, data){
    return axios({
        method: "put",
		url: endpoint + "servicios/variables/" + idVariable,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};

export function crearVariablesCall(token, data){
    return axios({
        method: "post",
		url: endpoint + "servicios/variables/",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};


// Servicio obtener servicios variables
export function obtenerServiciosVariablesCall(token, param, campo, orden, page, offset){
    return axios({
        method: "get",
		url: endpoint + "servicios/variables/servicio?"+param+"&field="+campo+"&order="+orden+"&page="+page+"&offset="+offset,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};

//variables by servicio
export function obtenersVariablesByServicioCall(token, idServicioVariable){
    return axios({
        method: "get",
		url: endpoint + "servicios/variables/activos?idServicioVariable="+idServicioVariable,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};


// crear servicio variable
export function crearServicioVariableCall(token, data){
    return axios({
        method: "post",
		url: endpoint + "servicios/variables/servicio",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};
// obtener variables activas 
export function obtenerVariablesActivasCall(token){
	return axios({
		method: "get",
		url: endpoint + "servicios/variables/activos",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};
// editar servicio variable
export function editarServicioVariableCall(token, idServicioVariable, data){
    return axios({
        method: "put",
		url: endpoint + "servicios/variables/servicio/" + idServicioVariable,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};
// activar servicio variable
export function activarServicioVariableCall(token, idServicioVariable){
    return axios({
        method: "get",
		url: endpoint + "servicios/variables/servicio/activar/" + idServicioVariable,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};
// obtener ubicaciones activas
export function obtenerUbicacionesActivasCall(token){
    return axios({
        method: "get",
		url: endpoint + "areas/activos/ubicaciones",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};


//get subvariables
export function obtenerSubVariableByIdCall(token, idServicioVariable, idVariable){
    return axios({
        method: "get",
		url: endpoint + "servicios/variables/subvariables" + idServicioVariable + idVariable,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};
//get subvariables by idVariable
export function obtenerSubVariableByIdVariableCall(token, idVariable){
    return axios({
        method: "get",
		url: endpoint + "servicios/variables/subvariables/ByVariable?idVariable=" + idVariable,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};

// detalle de servicio variable (variables para captura)
export function detalleServicioVariableCall(token, idServicioVariable){
    return axios({
        method: "get",
		url: endpoint + "servicios/variables/servicio/" + idServicioVariable,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};
//captura de productividades
export function capturarProductividadCall(token, data){
	return axios({
		method: "put",
		url: endpoint + "servicios/variables/servicio/capturar",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response =>{
		return response;
	}).catch(error => {
		return error.response.data
	});
};

// obtener variables que no esten en el servicio
export function obtenerVariablesFiltradasCall(token, idServicioVariable){
    return axios({
        method: "get",
		url: endpoint + "servicios/variables/activos/filtrados/" + idServicioVariable,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};

// activar variable dentro de servicio variable
export function activarVariableServicioVariableCall(token, idServicioVariable, idVariable){
    return axios({
        method: "get",
		url: endpoint + "servicios/variables/servicio/variable/activar?idServicioVariable=" + idServicioVariable + "&idVariable=" + idVariable,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};
// Presentacion servicios variables
export function presentacionServicioVariableCall(token, idServicioVariable,idVariable, idSubvariable, idUbicacion, comparativa, fechaInicio, fechaFin, page){
	console.log("presentacionServicioVariableCall: ",  "servicios/variables/servicio/presentacion/" + idServicioVariable + "&fechaInicio=" + fechaInicio + "&page=" + page + "&offset=30" + idVariable + idSubvariable + idUbicacion +"&fechaFin=" + fechaFin + "&comparativa=" + comparativa)
    return axios({
        method: "get",
		url: endpoint + "servicios/variables/servicio/presentacion/" + idServicioVariable + "&fechaInicio=" + fechaInicio + "&page=" + page + "&offset=30" + idVariable + idSubvariable + idUbicacion +"&fechaFin=" + fechaFin + "&comparativa=" + comparativa,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};

export function presentacionSVPDFCall(token, idServicioVariable, idVariable, idSubvariable, comparativa, fechaInicio, fechaFin, page){
	return axios({
	     method: "post",
		 url: endpoint + "servicios/variables/servicio/presentacionPdf?" + idVariable + idSubvariable + idServicioVariable + "&comparativa=" + comparativa + "&fechaInicio=" + fechaInicio + "&fechaFin=" + fechaFin + "&page=" + page + "&offset=10",
		 headers: { 'Content-Type': 'application/json', 'Authorization': token },
		 responseType: 'blob',
	}).then(response => {
		 return response;
	}).catch(error => {
		 return error.response.data;
	});
 };
// exportacion excell
export function exportacionExcelServicioCall(token, data){
    return axios({
        method: "post",
		url: endpoint + "servicios/variables/excel",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		responseType: 'blob',
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};
// servicios activos
export function serviciosActivosCall(token){
    return axios({
        method: "get",
		url: endpoint + "servicios/activosF",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};


export function excelMonitorProductividadesCall(token, data){
	return axios({
	    method: "post",
		 url: endpoint + "servicios/variables/monitor/productividades/exportar/excel",
		 headers: { 'Content-Type': 'application/json', 'Authorization': token },
		 responseType: 'blob',
		 data: data,
	 }).then(response => {
		 return response;
	 }).catch(error => {
		 return error.response.data;
	});
 };

 export function pdfMonitorProductividadesCall(token, data){
	return axios({
	    method: "post",
		 url: endpoint + "servicios/variables/monitor/productividades/exportar/pdf",
		 headers: { 'Content-Type': 'application/json', 'Authorization': token },
		 responseType: 'blob',
		 data: data,
	 }).then(response => {
		 return response;
	 }).catch(error => {
		 return error.response.data;
	});
 };

 export function getMonitorProductividadesCall(token, data){
	return axios({
	    	method: "post",
		url: endpoint + "servicios/variables/monitor/productividades",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
 };

 export function serviciosVariablesByIdServicioCall(token, idServicio){
	return axios({
	    	method: "get",
		url: endpoint + "servicios/variables/variables/byIdServicio/?idServicio=" + idServicio,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		 return response;
	}).catch(error => {
		 return error.response.data;
	});
 };
