import React, { Component } from 'react';
import { Modal } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import AgregarActivoSalida from './AgregarActivoSalida';
import SolicitudSalidaDeBienes from './SolicitudSalidaDeBienes';
import ArchivoSalidaDeBienes from './ArchivoSalidaDeBienes';

class ModalEditarEstandarDeDesempeño extends Component {
     formRef = React.createRef();
     render() {
          const { 
               showModalSalidaDeBienes, 
               onShowModalSalida, 
               vistaModalSalidaDeBienes, 
               onCambiarVista, 
               onLimpiarFormulario,
               onActualizarTabla,
               onListaServiciosFiltroLimpiar,
               onListaMotivosActivosLimpiar,
               limpiarGenerarFolio
          } = this.props;

          const handleCancel = () => {
               onShowModalSalida();
               onLimpiarFormulario('');
               onCambiarVista(1);
               onActualizarTabla();
               limpiarGenerarFolio();
               //onListaServiciosFiltroLimpiar();
               //onListaMotivosActivosLimpiar();
               //this.formRef.current.resetFields();
          }

          function ContenidoSolicitud() {
               if(vistaModalSalidaDeBienes === 1){
                    return(
                         <AgregarActivoSalida/>
                    );
               }
               else if(vistaModalSalidaDeBienes === 2){
                    return(
                         <SolicitudSalidaDeBienes/>
                    );
               }
               else if(vistaModalSalidaDeBienes === 3){
                    return(
                         <ArchivoSalidaDeBienes/>
                    );
               }
          };
        
          return (
               <Modal
               visible={showModalSalidaDeBienes}
               title='Solicitud de Salida'
               onCancel={handleCancel}
               footer={false}
               width='80%'
               >
                    <ContenidoSolicitud/>
               </Modal>
          );
     }
}

const mapStateToProps = state => {
	return {
          showModalSalidaDeBienes: state.AlmacenesReducer.showModalSalidaDeBienes,
          vistaModalSalidaDeBienes: state.AlmacenesReducer.vistaModalSalidaDeBienes,
          salidaActivoSeleccionado: state.AlmacenesReducer.salidaActivoSeleccionado,
	};
};

const mapDispatchToProps = dispatch => {
	return {
          onShowModalSalida: () => {
               dispatch({ type: 'SHOW_MODAL_SALIDA_BIENES', showModalSalidaDeBienes: false, page: null })
          },
          onLimpiarFormulario: (limpiarFormulario) => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: limpiarFormulario });
          },
          onCambiarVista: (vistaModalSalidaDeBienes) => {
               dispatch({ type: 'CAMBIAR_VISTA_SALIDA_BIENES', vistaModalSalidaDeBienes: vistaModalSalidaDeBienes, formularioSalidaDeBienes: null})
          },
          onActualizarTabla: (salidaActivoSeleccionado, selectedRowKeys, hasSelected, page) => {
               dispatch({ type: 'ACTUALIZAR_TABLA_SALIDA', salidaActivoSeleccionado: salidaActivoSeleccionado, selectedRowKeys: selectedRowKeys, hasSelected, page: 1})
          },
          onListaServiciosFiltroLimpiar: () => {
               dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: [] })
          },
          onListaMotivosActivosLimpiar: () => {
               dispatch({ type: "MOTIVOS_DE_SALIDA_ACTIVOS_SUCCESS", listaMotivosSalidaActivos: [] });
          },
          limpiarGenerarFolio: () => {
               dispatch({ type: 'GENERAR_FOLIO_SALIDA_SUCCESS', tipoMovimiento: null });
          },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarEstandarDeDesempeño);