import { takeLatest, call, put } from "redux-saga/effects";
import * as gestionDeCalidadActions from '../actions/GestionDeCalidadActions';
import * as configuracionYSeguridadActions from '../actions/ConfiguracionYSeguridadActions'
import {
    listaPlanesCalidadBusquedaCall,
    listaPlanCalidadAñosCall,
    estadoPlanCalidadCall,
    nuevoPlanCalidadCall,
    editarPlanCalidadCall,

    listaPlanesCorrectivosBusquedaCall,
    nuevoPlanCorrectivoCall,
    editarPlanCorrectivoCall,

    listaPonderadoresEncuestaCAll,
    duplicarPonderadoresEncuestaCAll,
    añosPonderadoresEncuestaCall,
    estadoPonderadorEncuestaCall,
    nuevoPonderadorEncuestaCall,
    editarPonderadorEncuestaCall,

    listaPreguntasEncuestaCAll,
    estadoEncuestaCall,
    nuevaPreguntaEncuestaCall,
    editarPreguntaEncuestaCall,
    nuevoFolioEncuestaCall,
    exportarFolioEncuestaCall,
    listaAñosPreguntaEncuestaCAll,

    mesesByAñoServicioEncuestaCall,
    listaFoliosBusquedaCall,
    registrarEncuestaCall,
    evaluarEncuestaCall,

    getInformacionReporteCall,
    exportarReporteEncuestasCall,
    generalReportExportCall,
    comparativoServicioPDFCall,

    reporteDinamicoESCall,
    reporteDinamicoAgrupadoESCall,
    reporteDinamicoPDFESCall,
    reporteDinamicoExcelESCall,

    listaCumplimientoEstandaresCall,
    crearFolioEstandarCall,
    exportarFolioEstandarCall,
    listaFoliosEstandaresEvaluarCall,
    evaluarFolioEstandarCall,
    listaFoliosEstandaresEvaluadosCall,
    listaMesesActivosCall,
    listaFoliosEstandaresCall,
    listaFoliosSolicitudesCall,
    listaEstandaresDesempeñoAuditoriasCall,
    listaEstandaresDesempeñoCall,

    listaFoliosEstandaresDesempenoCall,
    listaEstandaresPorFolioCall,
    subirArchivoPorEstandarCall,
    listaGerenciasByServicioCall,
    listaObjetivosByGerenciaCall,
    listaEvaluacionesDeDesempenoCall,
    listaIndicadoresByGerenciaObjetiboCall,
    listaPartidasByServicioCall,
    crearEvaluacionDeDesempenoCall,
    listaServiciosEvaluacionesDeDesempenoCall,

} from '../../services/GestionDeCalidadServices';
import moment from 'moment';
import {LISTA_SERVICIOS_EVALUACIONES_DE_DESEMPENO_REQUEST} from "../actions/GestionDeCalidadActions";

// SAGA PARA GESTIÓN DE PLAN DE CALIDAD
function* listaPlanesCalidadBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let año = '';
        const page = action.page - 1;
        if(action.busqueda){
            año = 'anio=' + action.busqueda + '&';
        }
        
        const response = yield call(listaPlanesCalidadBusquedaCall, token, page, año)
        console.log(response);

        if (response.status === 200) {
            const listaPlanesCalidadBusqueda = response.data.data;
            const listaPlanesCalidadBusquedaCantidad = response.data.cantidad;

            yield put({ type: gestionDeCalidadActions.LISTA_PLANES_CALIDAD_BUSQUEDA_SUCCESS, listaPlanesCalidadBusqueda, listaPlanesCalidadBusquedaCantidad })
        } else {
            yield put ({ type: gestionDeCalidadActions.LISTA_PLANES_CALIDAD_BUSQUEDA_FAILURE})
        }
    } catch (error) {
        yield put ({ type: gestionDeCalidadActions.LISTA_PLANES_CALIDAD_BUSQUEDA_FAILURE})
    }
}
export function* listaPlanesCalidadBusquedaSaga() {
    yield takeLatest(gestionDeCalidadActions.LISTA_PLANES_CALIDAD_BUSQUEDA_REQUEST, listaPlanesCalidadBusqueda )
}

function* listaPlanCalidadAños(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaPlanCalidadAñosCall, token)
        console.log(response);

        if (response.status === 200) {
            const listaPlanCalidadAños = response.data;

            yield put({ type: gestionDeCalidadActions.LISTA_AÑOS_PLAN_CALIDAD_SUCCESS, listaPlanCalidadAños })
        } else {
            yield put ({ type: gestionDeCalidadActions.LISTA_AÑOS_PLAN_CALIDAD_FAILURE})
        }
    } catch (error) {
        yield put ({ type: gestionDeCalidadActions.LISTA_AÑOS_PLAN_CALIDAD_FAILURE})
    }
}
export function* listaPlanCalidadAñosSaga() {
    yield takeLatest(gestionDeCalidadActions.LISTA_AÑOS_PLAN_CALIDAD_REQUEST, listaPlanCalidadAños )
}

function* estadoPlanCalidad(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idGestionCalidad = action.idGestionCalidad;

        const response = yield call(estadoPlanCalidadCall, token, idGestionCalidad)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "El estado del plan de calidad se cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.ESTADO_PLAN_CALIDAD_SUCCESS })

            yield put({ type: gestionDeCalidadActions.LISTA_PLANES_CALIDAD_BUSQUEDA_REQUEST, busqueda: action.busqueda, page: action.page });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.ESTADO_PLAN_CALIDAD_FAILURE})
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.ESTADO_PLAN_CALIDAD_FAILURE})
    }
}
export function* estadoPlanCalidadSaga(){
    yield takeLatest(gestionDeCalidadActions.ESTADO_PLAN_CALIDAD_REQUEST, estadoPlanCalidad)
}

function* crearPlanCalidad(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let año = '';
        let sigue = true;
        if(action.listaPlanCalidadAños[0]){
            if(parseInt(action.listaInfoProyecto.fechaInicioOperacion.format('YYYY-MM-DD').split('-')[0]) + action.listaInfoProyecto.vigencia === parseInt(action.listaPlanCalidadAños[0])){
                sigue = false;
            } else{
                año = parseInt(action.listaPlanCalidadAños[0]) + 1;
            }
        } else {
            año = action.listaInfoProyecto.fechaInicioOperacion.format('YYYY-MM-DD').split('-')[0];
        }
        console.log('sigue', sigue)
        if(sigue){
            const data = {
                "idGestionCalidad": null,
                "nombreArchivo": action.formulario.pdf.file.name,
                "nomenclatura": action.formulario.nomenclatura,
                "version": action.formulario.version,
                "anio": año,
                "rutaArchivo": null,
                "fechaAlta": null,
                "fechaActualizacion": null,
                "activo": true
            };

            const formData = new FormData();

            formData.append('data', new Blob([JSON.stringify(data)], {
                type: 'application/json'
            }));

            if(action.formulario.pdf){
                formData.append('file', action.formulario.pdf.file.originFileObj);
            }

            console.log(data);
            const response = yield call(nuevoPlanCalidadCall, token, formData)
            console.log(response)

            if(response.status === 200){
                const showSuccessMsg = true;
                const textMessage = "Se creó el plan de calidad de forma correcta";
                
                yield put({ type: gestionDeCalidadActions.LISTA_PLANES_CALIDAD_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda });
                yield put({ type: gestionDeCalidadActions.LISTA_AÑOS_PLAN_CALIDAD_REQUEST });

                yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
                yield put({ type: gestionDeCalidadActions.NUEVO_PLAN_CALIDAD_SUCCESS })
                yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearPlanCalidad'});
                
                yield put({ type: gestionDeCalidadActions.MODAL_NUEVO_PLAN_CALIDAD, showModalNuevoPlanCalidad: false });
            } else {
                const showErrorMsg = true;
                const textMessage = response.message;

                yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                yield put({ type: gestionDeCalidadActions.NUEVO_PLAN_CALIDAD_FAILURE})
            }
        } else{
            const showErrorMsg = true;
            const textMessage = 'No es posible crear un plan de calidad mayor al año de fin de operaciones';

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.NUEVO_PLAN_CALIDAD_FAILURE})
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.NUEVO_PLAN_CALIDAD_FAILURE})
    }
}
export function* crearPlanCalidadSaga(){
    yield takeLatest(gestionDeCalidadActions.NUEVO_PLAN_CALIDAD_REQUEST, crearPlanCalidad)
}


function* editarPlanCalidad(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idGestionCalidad = action.planCalidadSeleccionado.idGestionCalidad;
        const data = {
            "idGestionCalidad": idGestionCalidad,
            "nombreArchivo": action.formulario.pdf.file.name,
            "nomenclatura": action.planCalidadSeleccionado.nomenclatura,
            "version": action.formulario.version,
            "anio": action.planCalidadSeleccionado.anio,
            "rutaArchivo": action.planCalidadSeleccionado.rutaArchivo,
            "fechaAlta": action.planCalidadSeleccionado.fechaAlta,
            "fechaActualizacion": action.planCalidadSeleccionado.fechaActualizacion,
            "activo": action.planCalidadSeleccionado.activo,
        };

        const formData = new FormData();

        formData.append('data', new Blob([JSON.stringify(data)], {
            type: 'application/json'
        }));

        if(action.formulario.pdf){
            formData.append('file', action.formulario.pdf.file.originFileObj);
        }

        console.log(data);
        const response = yield call(editarPlanCalidadCall, token, formData, idGestionCalidad)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se editó el plan de calidad de forma correcta";
            
            yield put({ type: gestionDeCalidadActions.LISTA_PLANES_CALIDAD_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda });

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.EDITAR_PLAN_CALIDAD_SUCCESS })
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarPlanCalidad'});
            
            yield put({ type: gestionDeCalidadActions.MODAL_EDITAR_PLAN_CALIDAD, showModalEditarPlanCalidad: false });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.EDITAR_PLAN_CALIDAD_FAILURE})
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.EDITAR_PLAN_CALIDAD_FAILURE})
    }
}
export function* editarPlanCalidadSaga(){
    yield takeLatest(gestionDeCalidadActions.EDITAR_PLAN_CALIDAD_REQUEST, editarPlanCalidad)
}


// SAGA PARA GESTIÓN DE PLANES CORRECTIVOS
function* listaPlanesCorrectivosBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let idServicio = '';
        let origen = '';
        let field = 'idPlanCorrectivo';
        let order = 'DESC';
        let estado = action.estado;

        if(action.orden){
            if(action.orden.field){
                field = action.orden.field;
                order = action.orden.order;
            }
        }
        
        if(action.busqueda.idServicio){
            idServicio = parseInt(action.busqueda.idServicio);
        }
        if(action.busqueda.origen){
            origen = action.busqueda.origen;
        }

        let fechaInicio = ''
        let fechaFin = ''

        if (action.periodo) {
            if (action.periodo.length > 0) {
                fechaInicio = action.periodo[0].format('YYYY-MM-DD');
                fechaInicio = '&fechaInicio=' + fechaInicio;
                fechaFin = action.periodo[1].format('YYYY-MM-DD');
                fechaFin = '&fechaFin=' + fechaFin;
            }
        }

        const response = yield call(listaPlanesCorrectivosBusquedaCall, token, page, idServicio, origen, field, order, estado, fechaInicio, fechaFin)
        console.log(response);

        if (response.status === 200) {
            const listaPlanesCorrectivosBusqueda = response.data.data;
            const listaPlanesCorrectivosBusquedaCantidad = response.data.cantidad;
            for(let i in listaPlanesCorrectivosBusqueda){

                if(listaPlanesCorrectivosBusqueda[i].fechaIncumplimiento){
                    listaPlanesCorrectivosBusqueda[i].fechaIncumplimiento = listaPlanesCorrectivosBusqueda[i].fechaIncumplimiento.split('T')[0].split('-').reverse().join('-');
                    listaPlanesCorrectivosBusqueda[i].fechaIncumplimientoEditar = moment(listaPlanesCorrectivosBusqueda[i].fechaIncumplimiento, 'DD-MM-YYYY');
                }

                if (listaPlanesCorrectivosBusqueda[i].actividadesPlanesByIdPlanCorrectivo) {
                    for (let j in listaPlanesCorrectivosBusqueda[i].actividadesPlanesByIdPlanCorrectivo) {
                        listaPlanesCorrectivosBusqueda[i].actividadesPlanesByIdPlanCorrectivo[j].fechaCompromiso = listaPlanesCorrectivosBusqueda[i].actividadesPlanesByIdPlanCorrectivo[j].fechaCompromiso.split('T')[0].split('-').reverse().join('-');
                        listaPlanesCorrectivosBusqueda[i].actividadesPlanesByIdPlanCorrectivo[j].fechaCompromisoEditar = moment(listaPlanesCorrectivosBusqueda[i].actividadesPlanesByIdPlanCorrectivo[j].fechaCompromiso, 'DD-MM-YYYY');

                        listaPlanesCorrectivosBusqueda[i].actividadesPlanesByIdPlanCorrectivo[j].fechaRealizacionEditar = null;
                        if (listaPlanesCorrectivosBusqueda[i].actividadesPlanesByIdPlanCorrectivo[j].fechaRealizacion) {
                            listaPlanesCorrectivosBusqueda[i].actividadesPlanesByIdPlanCorrectivo[j].fechaRealizacion = listaPlanesCorrectivosBusqueda[i].actividadesPlanesByIdPlanCorrectivo[j].fechaRealizacion.split('T')[0].split('-').reverse().join('-');
                            listaPlanesCorrectivosBusqueda[i].actividadesPlanesByIdPlanCorrectivo[j].fechaRealizacionEditar = moment(listaPlanesCorrectivosBusqueda[i].actividadesPlanesByIdPlanCorrectivo[j].fechaRealizacion, 'DD-MM-YYYY');
                        }
                    }
                }

                if(listaPlanesCorrectivosBusqueda[i].servicioByIdServicio) {
                    listaPlanesCorrectivosBusqueda[i].servicio = listaPlanesCorrectivosBusqueda[i].idServicio + ' - ' + listaPlanesCorrectivosBusqueda[i].servicioByIdServicio.nombre
                }

                if(listaPlanesCorrectivosBusqueda[i].fechaTermino){
                    listaPlanesCorrectivosBusqueda[i].fechaTerminoGrid = listaPlanesCorrectivosBusqueda[i].fechaTermino.split('T')[0].split('-').reverse().join('-');
                    listaPlanesCorrectivosBusqueda[i].fechaTermino = moment(listaPlanesCorrectivosBusqueda[i].fechaTermino, 'DD-MM-YYYY');
                }

                if(listaPlanesCorrectivosBusqueda[i].origen !== 'Encuesta de satisfacción' && listaPlanesCorrectivosBusqueda[i].origen !== 'Quejas' && listaPlanesCorrectivosBusqueda[i].origen !== 'Auditorías'){
                    listaPlanesCorrectivosBusqueda[i].otro = listaPlanesCorrectivosBusqueda[i].origen;
                    listaPlanesCorrectivosBusqueda[i].origenEditar = 'Otro';
                }
                else{
                    listaPlanesCorrectivosBusqueda[i].origenEditar = listaPlanesCorrectivosBusqueda[i].origen;
                }
            }

            yield put({ type: gestionDeCalidadActions.LISTA_PLANES_CORRECTIVOS_BUSQUEDA_SUCCESS, listaPlanesCorrectivosBusqueda, listaPlanesCorrectivosBusquedaCantidad })
        } else {
            yield put ({ type: gestionDeCalidadActions.LISTA_PLANES_CORRECTIVOS_BUSQUEDA_FAILURE})
        }
    } catch (error) {
        yield put ({ type: gestionDeCalidadActions.LISTA_PLANES_CORRECTIVOS_BUSQUEDA_FAILURE})
    }
}
export function* listaPlanesCorrectivosBusquedaSaga() {
    yield takeLatest(gestionDeCalidadActions.LISTA_PLANES_CORRECTIVOS_BUSQUEDA_REQUEST, listaPlanesCorrectivosBusqueda )
}

function* crearPlanCorrectivo(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let origen = action.formulario.origen;
        if(action.formulario.origen === 'Otro'){
            origen = action.formulario.otro;
        }

        const data = {
            "idPlanCorrectivo": null,
            'idServicio': parseInt(action.formulario.servicio),
            "nombreArchivo": action.formulario.pdf.file.name,
            "origen": origen,
            "motivoImprocedencia": action.formulario.motivoImprocedencia,
            "idMes": action.formulario.idMes,
            "responsableAtencion": action.formulario.responsableAtencion,
            'observacion': '',
            "idFolioEncuesta": action.formulario.idFolioEncuesta,
            "idFolioEstandar": action.formulario.idFolioEstandar,
            "idFolioSolicitud": action.formulario.idFolioSolicitud,
            "idEstandarDesempeño": action.formulario.idEstandarDesempeño,
            "rutaArchivo": null,
            "fechaAlta": null,
            "fechaActualizacion": null,
            'fechaTermino': null,
            'fechaIncumplimiento': action.formulario.fechaIncumplimiento,
            'incumplimiento': action.formulario.incumplimiento,
            "estado": true
        };

        const formData = new FormData();

        formData.append('data', new Blob([JSON.stringify(data)], {
            type: 'application/json'
        }));

        var listaActividades = action.listaActividades;

        for (let i in action.listaActividades) {
            listaActividades[i].fechaCompromiso = listaActividades[i].fechaCompromiso.split('-').reverse().join('-');
        }

        formData.append('actividadesList', new Blob([JSON.stringify(listaActividades)], {
            type: 'application/json'
        }));

        if(action.formulario.pdf){
            formData.append('file', action.formulario.pdf.file.originFileObj);
        }

        console.log(data);
        const response = yield call(nuevoPlanCorrectivoCall, token, formData)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se creó el plan correctivo de forma correcta";
            
            yield put({ type: gestionDeCalidadActions.LISTA_PLANES_CORRECTIVOS_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda, orden: action.orden });

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.NUEVO_PLAN_CORRECTIVO_SUCCESS })
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearPlanCorrectivo'});
            
            yield put({ type: gestionDeCalidadActions.MODAL_NUEVO_PLAN_CORRECTIVO, showModalNuevoPlanCorrectivo: false, page: action.page, busqueda: action.busqueda });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.NUEVO_PLAN_CORRECTIVO_FAILURE})
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.NUEVO_PLAN_CORRECTIVO_FAILURE})
    }
}
export function* crearPlanCorrectivoSaga(){
    yield takeLatest(gestionDeCalidadActions.NUEVO_PLAN_CORRECTIVO_REQUEST, crearPlanCorrectivo)
}

function* editarPlanCorrectivo(action) {
    try {
        console.log('action editar plan correctivo', action)
        const token = sessionStorage.getItem('token');
        const idPlanCorrectivo = action.planCorrectivoSeleccionado.idPlanCorrectivo;
        let observacion = '';
        let fechaTermino = null;

        if(!action.formulario.estado){
            observacion = action.formulario.observacion;
            fechaTermino = action.formulario.fechaTermino;
        }

        let nombreArchivo = action.planCorrectivoSeleccionado.nombreArchivo;
        if(action.formulario.pdf){
            nombreArchivo = action.formulario.pdf.file.name;
        }

        const data = {
            "idPlanCorrectivo": idPlanCorrectivo,
            'idServicio': parseInt(action.formulario.servicio),
            "nombreArchivo": nombreArchivo,
            "origen": action.planCorrectivoSeleccionado.origen,
            "fechaCompromiso": action.formulario.fechaCompromisoEditar,
            "responsableAtencion": action.planCorrectivoSeleccionado.responsableAtencion,
            'observacion': observacion,
            "rutaArchivo": action.planCorrectivoSeleccionado.rutaArchivo,
            "fechaAlta": action.planCorrectivoSeleccionado.fechaAlta,
            "fechaActualizacion": action.planCorrectivoSeleccionado.fechaActualizacion,
            'fechaTermino': fechaTermino,
            'fechaIncumplimiento': action.formulario.fechaIncumplimientoEditar,
            'fechaRealizacion': action.formulario.fechaRealizacionEditar,
            'incumplimiento': action.planCorrectivoSeleccionado.incumplimiento,
            "estado": action.formulario.estadoEditar
        };
        console.log('data', data)

        const formData = new FormData();

        formData.append('data', new Blob([JSON.stringify(data)], {
            type: 'application/json'
        }));

        var listaActividades = action.listaActividades;

        for (let i in action.listaActividades) {
            listaActividades[i].fechaCompromiso = listaActividades[i].fechaCompromiso.split('-').reverse().join('-');
            listaActividades[i].fechaRealizacion = listaActividades[i].fechaRealizacion.split('-').reverse().join('-');
        }

        formData.append('actividadesList', new Blob([JSON.stringify(action.listaActividades)], {
            type: 'application/json'
        }));

        if(action.formulario.pdf){
            formData.append('file', action.formulario.pdf.file.originFileObj);
        }

        console.log(data);
        const response = yield call(editarPlanCorrectivoCall, token, formData, idPlanCorrectivo)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se editó el plan correctivo de forma correcta";
            
            yield put({ type: gestionDeCalidadActions.LISTA_PLANES_CORRECTIVOS_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda, orden: action.orden, estado: action.estado });

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.EDITAR_PLAN_CORRECTIVO_SUCCESS })
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarPlanCorrectivo'});
            
            // yield put({ type: gestionDeCalidadActions.MODAL_EDITAR_PLAN_CORRECTIVO, showModalEditarPlanCorrectivo: false, planCorrectivoSeleccionado: null });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.EDITAR_PLAN_CORRECTIVO_FAILURE})
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.EDITAR_PLAN_CORRECTIVO_FAILURE})
    }
}
export function* editarPlanCorrectivoSaga(){
    yield takeLatest(gestionDeCalidadActions.EDITAR_PLAN_CORRECTIVO_REQUEST, editarPlanCorrectivo)
}

function* listaMesesActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaMesesActivosCall, token)
        console.log(response);

        if (response.status === 200) {
            const listaMesesActivos = response.data;

            yield put({ type: gestionDeCalidadActions.LISTA_MESES_ACTIVOS_SUCCESS, listaMesesActivos })
        } else {
            yield put ({ type: gestionDeCalidadActions.LISTA_MESES_ACTIVOS_FAILURE})
        }
    } catch (error) {
        yield put ({ type: gestionDeCalidadActions.LISTA_MESES_ACTIVOS_FAILURE})
    }
}
export function* listaMesesActivosSaga() {
    yield takeLatest(gestionDeCalidadActions.LISTA_MESES_ACTIVOS_REQUEST, listaMesesActivos )
}

function* listaFoliosEstandares(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const param = action.param;
        const idServicio = action.idServicio;

        const response = yield call(listaFoliosEstandaresCall, token, param, idServicio)
        console.log(response);

        if (response.status === 200) {
            const listaFoliosEstandares = response.data;

            yield put({ type: gestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_SUCCESS, listaFoliosEstandares })
        } else {
            yield put ({ type: gestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_FAILURE})
        }
    } catch (error) {
        yield put ({ type: gestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_FAILURE})
    }
}
export function* listaFoliosEstandaresSaga() {
    yield takeLatest(gestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_REQUEST, listaFoliosEstandares )
}

function* listaFoliosSolicitudes(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const param = action.param;
        const idServicio = action.idServicio;

        const response = yield call(listaFoliosSolicitudesCall, token, param, idServicio)
        console.log(response);

        if (response.status === 200) {
            const listaFoliosSolicitudes = response.data;

            yield put({ type: gestionDeCalidadActions.LISTA_FOLIOS_SOLICITUDES_SUCCESS, listaFoliosSolicitudes })
        } else {
            yield put ({ type: gestionDeCalidadActions.LISTA_FOLIOS_SOLICITUDES_FAILURE})
        }
    } catch (error) {
        yield put ({ type: gestionDeCalidadActions.LISTA_FOLIOS_SOLICITUDES_FAILURE})
    }
}
export function* listaFoliosSolicitudesSaga() {
    yield takeLatest(gestionDeCalidadActions.LISTA_FOLIOS_SOLICITUDES_REQUEST, listaFoliosSolicitudes )
}

function* listaEstandaresDesempeñoAuditorias(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const param = action.param;
        const idFolioEstandar = action.idFolioEstandar;

        const response = yield call(listaEstandaresDesempeñoAuditoriasCall, token, param, idFolioEstandar)
        console.log(response);

        if (response.status === 200) {
            const listaEstandaresDesempeñoAuditorias = response.data;

            yield put({ type: gestionDeCalidadActions.LISTA_ESTANDARES_DESEMPENO_AUDITORIAS_SUCCESS, listaEstandaresDesempeñoAuditorias })
        } else {
            yield put ({ type: gestionDeCalidadActions.LISTA_ESTANDARES_DESEMPENO_AUDITORIAS_FAILURE})
        }
    } catch (error) {
        yield put ({ type: gestionDeCalidadActions.LISTA_ESTANDARES_DESEMPENO_AUDITORIAS_FAILURE})
    }
}
export function* listaEstandaresDesempeñoAuditoriasSaga() {
    yield takeLatest(gestionDeCalidadActions.LISTA_ESTANDARES_DESEMPENO_AUDITORIAS_REQUEST, listaEstandaresDesempeñoAuditorias )
}

function* listaEstandaresDesempeño(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const param = action.param;
        const idServicio = action.idServicio;

        const response = yield call(listaEstandaresDesempeñoCall, token, param, idServicio)
        console.log(response);

        if (response.status === 200) {
            const listaEstandaresDesempeño = response.data;

            yield put({ type: gestionDeCalidadActions.LISTA_ESTANDARES_DESEMPENO_SUCCESS, listaEstandaresDesempeño })
        } else {
            yield put ({ type: gestionDeCalidadActions.LISTA_ESTANDARES_DESEMPENO_FAILURE})
        }
    } catch (error) {
        yield put ({ type: gestionDeCalidadActions.LISTA_ESTANDARES_DESEMPENO_FAILURE})
    }
}
export function* listaEstandaresDesempeñoSaga() {
    yield takeLatest(gestionDeCalidadActions.LISTA_ESTANDARES_DESEMPENO_REQUEST, listaEstandaresDesempeño )
}

//PONDERADORES PARA ENCUESTAS DE SATISFACCIÓN
function* listaPonderadoresEncuesta(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        
        const response = yield call(listaPonderadoresEncuestaCAll, token, page)
        console.log(response);

        if (response.status === 200) {
            const listaPonderadoresEncuesta = response.data.data;
            const listaPonderadoresEncuestaCantidad = response.data.cantidad;
            for(let i in listaPonderadoresEncuesta){
                listaPonderadoresEncuesta[i].fechaAlta = listaPonderadoresEncuesta[i].fechaAlta.split('T')[0].split('-').reverse().join('-') + ' ' + listaPonderadoresEncuesta[i].fechaAlta.split('T')[1].split(':')[0] + ':' + listaPonderadoresEncuesta[i].fechaAlta.split('T')[1].split(':')[1]
            }

            yield put({ type: gestionDeCalidadActions.LISTA_PONDERADORES_ENCUESTA_SUCCESS, listaPonderadoresEncuesta, listaPonderadoresEncuestaCantidad })
        } else {
            yield put ({ type: gestionDeCalidadActions.LISTA_PONDERADORES_ENCUESTA_FAILURE})
        }
    } catch (error) {
        yield put ({ type: gestionDeCalidadActions.LISTA_PONDERADORES_ENCUESTA_FAILURE})
    }
}
export function* listaPonderadoresEncuestaSaga() {
    yield takeLatest(gestionDeCalidadActions.LISTA_PONDERADORES_ENCUESTA_REQUEST, listaPonderadoresEncuesta )
}

function* duplicarPonderadoresEncuesta(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const año = action.listaAñosPonderadoresEncuesta[0];

        const response = yield call(duplicarPonderadoresEncuestaCAll, token, año)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Los ponderadores para encuestas de satisfacción se duplicaron de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.REPETIR_PONDERADORES_ENCUESTA_SUCCESS });

            yield put({ type: gestionDeCalidadActions.LISTA_PONDERADORES_ENCUESTA_REQUEST, page: action.page });
            yield put({ type: gestionDeCalidadActions.AÑOS_PONDERADORES_ENCUESTA_REQUEST });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.REPETIR_PONDERADORES_ENCUESTA_FAILURE})
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.REPETIR_PONDERADORES_ENCUESTA_FAILURE})
    }
}
export function* duplicarPonderadoresEncuestaSaga(){
    yield takeLatest(gestionDeCalidadActions.REPETIR_PONDERADORES_ENCUESTA_REQUEST, duplicarPonderadoresEncuesta)
}

function* añosPonderadoresEncuesta(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(añosPonderadoresEncuestaCall, token)
        console.log(response)

        if(response.status === 200){
            yield put({ type: gestionDeCalidadActions.AÑOS_PONDERADORES_ENCUESTA_SUCCESS, listaAñosPonderadoresEncuesta: response.data })

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.AÑOS_PONDERADORES_ENCUESTA_FAILURE });
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.AÑOS_PONDERADORES_ENCUESTA_FAILURE });
    }
}
export function* añosPonderadoresEncuestaSaga(){
    yield takeLatest(gestionDeCalidadActions.AÑOS_PONDERADORES_ENCUESTA_REQUEST, añosPonderadoresEncuesta)
}

function* estadoPonderadorEncuesta(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idPonderacionEncuesta = action.idPonderacionEncuesta;

        const response = yield call(estadoPonderadorEncuestaCall, token, idPonderacionEncuesta)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "El estado del ponderador para encuestas de satisfacción se cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.ESTADO_PONDERADOR_ENCUESTA_SUCCESS })

            yield put({ type: gestionDeCalidadActions.LISTA_PONDERADORES_ENCUESTA_REQUEST, page: action.page });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.ESTADO_PONDERADOR_ENCUESTA_FAILURE})
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.ESTADO_PONDERADOR_ENCUESTA_FAILURE})
    }
}
export function* estadoPonderadorEncuestaSaga(){
    yield takeLatest(gestionDeCalidadActions.ESTADO_PONDERADOR_ENCUESTA_REQUEST, estadoPonderadorEncuesta)
}

function* crearPonderadorEncuesta(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const data = {
            "idPonderacionEncuesta":null,
            "etiqueta": action.formulario.etiqueta,
            "anio": action.formulario.año,
            "factor": action.formulario.factor,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }

        console.log(data);
        const response = yield call(nuevoPonderadorEncuestaCall, token, data)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se creó el ponderador para encuestas de satisfacción de forma correcta";
            
            yield put({ type: gestionDeCalidadActions.LISTA_PONDERADORES_ENCUESTA_REQUEST, page: action.page });
            yield put({ type: gestionDeCalidadActions.AÑOS_PONDERADORES_ENCUESTA_REQUEST });

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.NUEVO_PONDERADOR_ENCUESTA_SUCCESS })
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearPonderadorEncuesta' });
            
            yield put({ type: gestionDeCalidadActions.MODAL_NUEVO_PONDERADOR_ENCUESTA, showModalNuevoPonderadorEncuesta: false });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.NUEVO_PONDERADOR_ENCUESTA_FAILURE})
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.NUEVO_PONDERADOR_ENCUESTA_FAILURE})
    }
}
export function* crearPonderadorEncuestaSaga(){
    yield takeLatest(gestionDeCalidadActions.NUEVO_PONDERADOR_ENCUESTA_REQUEST, crearPonderadorEncuesta)
}

function* editarPonderadorEncuesta(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idPonderacionEncuesta = action.ponderadorEncuestaSeleccionado.idPonderacionEncuesta;

        const data = {
            "idPonderacionEncuesta": idPonderacionEncuesta,
            "etiqueta": action.formulario.etiqueta,
            "anio": action.formulario.anio,
            "factor": action.formulario.factor,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": action.ponderadorEncuestaSeleccionado.activo
        }

        console.log(data);
        const response = yield call(editarPonderadorEncuestaCall, token, data, idPonderacionEncuesta);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se editó el ponderador para encuesta de satisfacción de forma correcta";
            
            yield put({ type: gestionDeCalidadActions.LISTA_PONDERADORES_ENCUESTA_REQUEST, page: action.page });
            yield put({ type: gestionDeCalidadActions.AÑOS_PONDERADORES_ENCUESTA_REQUEST });

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.EDITAR_PONDERADOR_ENCUESTA_SUCCESS });
            
            yield put({ type: gestionDeCalidadActions.MODAL_EDITAR_PONDERADOR_ENCUESTA, showModalEditarPonderadorEncuesta: false, ponderadorEncuestaSeleccionado: null });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.EDITAR_PONDERADOR_ENCUESTA_FAILURE})
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.EDITAR_PONDERADOR_ENCUESTA_FAILURE})
    }
}
export function* editarPonderadorEncuestaSaga(){
    yield takeLatest(gestionDeCalidadActions.EDITAR_PONDERADOR_ENCUESTA_REQUEST, editarPonderadorEncuesta)
}

//EMISIÓN DE ENCUESTAS DE SATISFACCIÓN
function* listaAñosPreguntas(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        
        const response = yield call(listaAñosPreguntaEncuestaCAll, token)
        console.log(response);

        if (response.status === 200) {
            yield put({ type: gestionDeCalidadActions.LISTA_AÑOS_PREGUNTAS_SUCCESS, listaAñosPregunta: response.data })
        } else {
            yield put ({ type: gestionDeCalidadActions.LISTA_AÑOS_PREGUNTAS_FAILURE})
        }
    } catch (error) {
        yield put ({ type: gestionDeCalidadActions.LISTA_AÑOS_PREGUNTAS_FAILURE})
    }
}
export function* listaAñosPreguntasSaga() {
    yield takeLatest(gestionDeCalidadActions.LISTA_AÑOS_PREGUNTAS_REQUEST, listaAñosPreguntas )
}

function* listaPreguntasEncuesta(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let idServicio = '';
        let año = '';
        if(action.busqueda.idServicio){
            idServicio = 'idServicio=' + parseInt(action.busqueda.idServicio) + '&';
        }
        if(action.busqueda.año){
            año = 'anio=' + action.busqueda.año + '&';
        }
        
        const response = yield call(listaPreguntasEncuestaCAll, token, idServicio, año, page )
        console.log(response);

        if (response.status === 200) {
            const listaPreguntasEncuesta = response.data.data;
            const listaPreguntasEncuestaCantidad = response.data.cantidad;
            for(let i in listaPreguntasEncuesta){
                listaPreguntasEncuesta[i].fechaAlta = listaPreguntasEncuesta[i].fechaAlta.split('T')[0].split('-').reverse().join('-') + ' ' + listaPreguntasEncuesta[i].fechaAlta.split('T')[1].split(':')[0] + ':' + listaPreguntasEncuesta[i].fechaAlta.split('T')[1].split(':')[1]
                listaPreguntasEncuesta[i].servicio = listaPreguntasEncuesta[i].servicioByIdServicio.nombre;
            }

            yield put({ type: gestionDeCalidadActions.LISTA_PREGUNTAS_ENCUESTA_SUCCESS, listaPreguntasEncuesta, listaPreguntasEncuestaCantidad })
        } else {
            yield put ({ type: gestionDeCalidadActions.LISTA_PREGUNTAS_ENCUESTA_FAILURE})
        }
    } catch (error) {
        yield put ({ type: gestionDeCalidadActions.LISTA_PREGUNTAS_ENCUESTA_FAILURE})
    }
}
export function* listaPreguntasEncuestaSaga() {
    yield takeLatest(gestionDeCalidadActions.LISTA_PREGUNTAS_ENCUESTA_REQUEST, listaPreguntasEncuesta )
}

function* estadoEncuesta(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idPreguntaEncuesta = action.idPreguntaEncuesta;

        const response = yield call(estadoEncuestaCall, token, idPreguntaEncuesta)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "El estado de la pregunta de encuestas de satisfacción se cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.ESTADO_ENCUESTA_SUCCESS })

            yield put({ type: gestionDeCalidadActions.LISTA_PREGUNTAS_ENCUESTA_REQUEST, page: action.page, busqueda: action.busqueda });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.ESTADO_ENCUESTA_FAILURE})
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.ESTADO_ENCUESTA_FAILURE})
    }
}
export function* estadoEncuestaSaga(){
    yield takeLatest(gestionDeCalidadActions.ESTADO_ENCUESTA_REQUEST, estadoEncuesta)
}

function* crearPreguntaEncuesta(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let año = parseInt(action.año);
        const idServicios = [];

        for(let i in action.formulario.servicio){
            idServicios.push(parseInt(action.formulario.servicio[i]));
        }
        const data = {
            "preguntasEncuestas":{
                "idPreguntaEncuesta": null,
                "anio": año,
                "idServicio": parseInt(action.formulario.servicio),
                "pregunta": action.formulario.pregunta,
                "fechaAlta": null,
                "fechaActualizacion": null,
                "activo": true
            },
            "idsServicios": idServicios 
        }

        console.log(data);
        const response = yield call(nuevaPreguntaEncuestaCall, token, data)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se creó la pregunta de encuestas de satisfacción de forma correcta";
            
            yield put({ type: gestionDeCalidadActions.LISTA_PREGUNTAS_ENCUESTA_REQUEST, page: action.page, busqueda: action.busqueda });
            yield put({ type: gestionDeCalidadActions.LISTA_AÑOS_PREGUNTAS_REQUEST });
            

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.NUEVA_PREGUNTA_ENCUESTA_SUCCESS })
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearPreguntaEncuesta' });
            
            yield put({ type: gestionDeCalidadActions.MODAL_NUEVA_PREGUNTA_ENCUESTA, showModalNuevaPreguntaEncuesta: false });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.NUEVA_PREGUNTA_ENCUESTA_FAILURE})
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.NUEVA_PREGUNTA_ENCUESTA_FAILURE})
    }
}
export function* crearPreguntaEncuestaSaga(){
    yield takeLatest(gestionDeCalidadActions.NUEVA_PREGUNTA_ENCUESTA_REQUEST, crearPreguntaEncuesta)
}

function* editarPreguntaEncuesta(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idPreguntaEncuesta = action.preguntaEncuestaSeleccionado.idPreguntaEncuesta;

        const data ={
            "idPreguntaEncuesta": idPreguntaEncuesta,
            "anio": action.preguntaEncuestaSeleccionado.anio,
            "idServicio": action.preguntaEncuestaSeleccionado.idServicio,
            "pregunta": action.formulario.pregunta,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": action.preguntaEncuestaSeleccionado.activo,
            "servicioByIdServicio": null,
            "ponderacionesEncuestas": null
        }

        console.log(data);
        const response = yield call(editarPreguntaEncuestaCall, token, data, idPreguntaEncuesta);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se editó la pregunta para encuesta de satisfacción de forma correcta";
            
            yield put({ type: gestionDeCalidadActions.LISTA_PREGUNTAS_ENCUESTA_REQUEST, page: action.page, busqueda: action.busqueda });

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.EDITAR_PREGUNTA_ENCUESTA_SUCCESS });
            
            yield put({ type: gestionDeCalidadActions.MODAL_EDITAR_PREGUNTA_ENCUESTA, showModalEditarPreguntaEncuesta: false, preguntaEncuestaSeleccionado: null, page: action.page, busqueda: action.busqueda });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.EDITAR_PREGUNTA_ENCUESTA_FAILURE})
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.EDITAR_PREGUNTA_ENCUESTA_FAILURE})
    }
}
export function* editarPreguntaEncuestaSaga(){
    yield takeLatest(gestionDeCalidadActions.EDITAR_PREGUNTA_ENCUESTA_REQUEST, editarPreguntaEncuesta)
}

function* crearFolioEncuesta(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let mes = 12;

        if(action.formulario.mes === 'Enero'){
            mes = 1
        }
        else if(action.formulario.mes === 'Febrero'){
            mes = 2
        }
        else if(action.formulario.mes === 'Marzo'){
            mes = 3
        }
        else if(action.formulario.mes === 'Abril'){
            mes = 4
        }
        else if(action.formulario.mes === 'Mayo'){
            mes = 5
        }
        else if(action.formulario.mes === 'Junio'){
            mes = 6
        }
        else if(action.formulario.mes === 'Julio'){
            mes = 7
        }
        else if(action.formulario.mes === 'Agosto'){
            mes = 8
        }
        else if(action.formulario.mes === 'Septiembre'){
            mes = 9
        }
        else if(action.formulario.mes === 'Octubre'){
            mes = 10
        }
        else if(action.formulario.mes === 'Noviembre'){
            mes = 11
        }

        const data = {
            "idFolioEncuesta": null,
            "idServicio": parseInt(action.formulario.servicio),
            'idUbicacion': null,
            "cantidadFolios": action.formulario.cantidad,
            "anio": action.formulario.año,
            "idMes": mes,
            "folioEncuesta": '',
            "comentarios": "",
            "estado": '',
            "rutaEvidencia": "",
            "fechaAlta": null,
            "fechaActualizacion": null
        }

        console.log(data);
        const response = yield call(nuevoFolioEncuestaCall, token, data)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se crearon los folios de encuestas de satisfacción de forma correcta, descargando los archivos...";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });

            yield put({ type: gestionDeCalidadActions.NUEVO_FOLIO_ENCUESTA_SUCCESS })
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearFolioEncuesta' });
            yield put({ type: gestionDeCalidadActions.EXPORTAR_FOLIO_ENCUESTA_REQUEST, folio: response.data.id });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.NUEVO_FOLIO_ENCUESTA_FAILURE})
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.NUEVO_FOLIO_ENCUESTA_FAILURE})
    }
}
export function* crearFolioEncuestaSaga(){
    yield takeLatest(gestionDeCalidadActions.NUEVO_FOLIO_ENCUESTA_REQUEST, crearFolioEncuesta)
}

function* exportarFolioEncuesta(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const folio = action.folio;
        
        const response = yield call(exportarFolioEncuestaCall, token, folio)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se descargó el archivo de forma exitosa";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "FolioEncuesta_ " + new Date().getDate() + "-" + + new Date().getMonth()  + "_" + + new Date().getFullYear() + "-" + new Date().getHours() + "_" + new Date().getMinutes() + "_" + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.EXPORTAR_FOLIO_ENCUESTA_SUCCESS });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.EXPORTAR_FOLIO_ENCUESTA_FAILURE })
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.EXPORTAR_FOLIO_ENCUESTA_FAILURE})
    }
}
export function* exportarFolioEncuestaSaga(){
    yield takeLatest(gestionDeCalidadActions.EXPORTAR_FOLIO_ENCUESTA_REQUEST, exportarFolioEncuesta)
}

//REGISTRO DE ENCUESTAS DE SATISFACCIÓN
function* mesesByAñoServicioEncuesta(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const año = 'anio=' + action.año;
        const idServicio = '&idServicio=' + action.idServicio

        const response = yield call(mesesByAñoServicioEncuestaCall, token, año, idServicio)
        console.log(response)

        if(response.status === 200){
            yield put({ type: gestionDeCalidadActions.LISTA_MESES_FOLIO_SUCCESS, listaMesesFolio: response.data })

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.LISTA_MESES_FOLIO_FAILURE });
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.LISTA_MESES_FOLIO_FAILURE });
    }
}
export function* mesesByAñoServicioEncuestaSaga(){
    yield takeLatest(gestionDeCalidadActions.LISTA_MESES_FOLIO_REQUEST, mesesByAñoServicioEncuesta)
}

function* listaFoliosBusqueda(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = (action.page - 1) * 10;
        const idServicio = 'idServicio=' + action.busqueda.idServicio;
        const idMes = '&idMes=' + action.busqueda.mes;
        const año = '&anio=' + action.busqueda.año;
        let estado = '';
        if(action.busqueda.estado){
            estado = '&estado=' + action.busqueda.estado;
        }
        const response = yield call(listaFoliosBusquedaCall, token, idServicio, idMes, año, estado, page)
        console.log(response)

        if(response.status === 200){
            const listaFoliosBusqueda = response.data.data;
            const listaFoliosBusquedaCantidad = response.data.cantidad;

            for(let i in listaFoliosBusqueda){
                listaFoliosBusqueda[i].fechaAlta = listaFoliosBusqueda[i].fechaAlta.split('T')[0].split('-').reverse().join('-') + ' ' + listaFoliosBusqueda[i].fechaAlta.split('T')[1].split(':')[0] + ':' +listaFoliosBusqueda[i].fechaAlta.split('T')[1].split(':')[1]
                if(listaFoliosBusqueda[i].calificacionTotalByIdFolioEncuesta){
                    listaFoliosBusqueda[i].calificacion = listaFoliosBusqueda[i].calificacionTotalByIdFolioEncuesta.calificacion;
                }
                else{
                    listaFoliosBusqueda[i].calificacion = 'No se ha evaluado.';
                }
                listaFoliosBusqueda[i].ubicacion = 'No se ha evaluado'
                if(listaFoliosBusqueda[i].ubicacionByIdUbicacion){
                    listaFoliosBusqueda[i].ubicacion = listaFoliosBusqueda[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                    if(listaFoliosBusqueda[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional){
                        listaFoliosBusqueda[i].ubicacion += ', ' + listaFoliosBusqueda[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                    }
                    if(listaFoliosBusqueda[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional){
                        listaFoliosBusqueda[i].ubicacion += ', ' + listaFoliosBusqueda[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional;
                    }
                    if(listaFoliosBusqueda[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional){
                        listaFoliosBusqueda[i].ubicacion += ', ' + listaFoliosBusqueda[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                    }
                }

            }
            yield put({ type: gestionDeCalidadActions.LISTA_FOLIOS_BUSQUEDA_SUCCESS, listaFoliosBusqueda, listaFoliosBusquedaCantidad })

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.LISTA_FOLIOS_BUSQUEDA_FAILURE });
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.LISTA_FOLIOS_BUSQUEDA_FAILURE });
    }
}
export function* listaFoliosBusquedaSaga(){
    yield takeLatest(gestionDeCalidadActions.LISTA_FOLIOS_BUSQUEDA_REQUEST, listaFoliosBusqueda)
}

function* registrarPreguntasEncuesta(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const folioPreguntas = [];
        for(let i in action.listaPreguntas){
            let idPonderacionEncuesta = action.listaPreguntas[i].pregunta;
            if(!idPonderacionEncuesta){
                idPonderacionEncuesta = action.folioSeleccionado.preguntasEncuestasByIdFolio[i].ponderacionesEncuestas[0].idPonderacionEncuesta;
            }
            let folioPregunta = {
                "idFolioPregunta": 0,
                "idFolioEncuesta": action.folioSeleccionado.idFolioEncuesta,
                "idPreguntaEncuesta": action.listaPreguntas[i].idPreguntaEncuesta,
                "idPonderacionEncuesta": idPonderacionEncuesta,
                "activo": true
            }
            folioPreguntas.push(folioPregunta);
        }
        const data = {
            "idFolioEncuesta": action.folioSeleccionado.idFolioEncuesta,
            "idServicio": action.folioSeleccionado.idServicio,
            'idUbicacion': action.formulario.ubicacion,
            "anio": action.folioSeleccionado.anio,
            "idMes": action.folioSeleccionado.idMes,
            "folioEncuesta": action.folioSeleccionado.folioEncuesta,
            "comentarios": action.formulario.comentarios ? action.formulario.comentarios : '',
            "estado": action.folioSeleccionado.folioEncuesta,
            "rutaEvidencia": '',
            "fechaAlta": null,
            "fechaActualizacion": null,
            "foliosPreguntas": folioPreguntas,
        }
        console.log(`data`, data);
        
        const formData = new FormData();

        formData.append('file', action.formulario.evidenciaPDF.file.originFileObj);
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));

        const response = yield call(registrarEncuestaCall, token, formData)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se registraron las respuestas de forma exitosa";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.MODAL_REGISTRAR_ENCUESTA, showModalRegistrarEncuesta: false });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'registrarEncuesta' });
            yield put({ type: gestionDeCalidadActions.LISTA_FOLIOS_BUSQUEDA_REQUEST, busqueda: action.busqueda, page: action.page });

            yield put({ type: gestionDeCalidadActions.REGISTRAR_ENCUESTA_SUCCESS });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.REGISTRAR_ENCUESTA_FAILURE })
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.REGISTRAR_ENCUESTA_FAILURE})
    }
}
export function* registrarPreguntasEncuestaSaga(){
    yield takeLatest(gestionDeCalidadActions.REGISTRAR_ENCUESTA_REQUEST, registrarPreguntasEncuesta)
}

function* evaluarEncuesta(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let folio = '';
        let idServicio = '';
        let año = '';
        let idMes = '';

        if(action.folio){
            folio = 'folio=' + action.folio;
        }
        else{
            idServicio = 'idServicio=' + action.idServicio;
            año = '&anio=' + action.año;
            idMes = '&idMes=' + action.idMes;
        }
        const response = yield call(evaluarEncuestaCall, token, folio, idServicio, año, idMes)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = action.folio ? response.data.message : response.data.message;

            yield put({ type: gestionDeCalidadActions.EVALUAR_ENCUESTA_SUCCESS });

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.LISTA_FOLIOS_BUSQUEDA_REQUEST, busqueda: action.busqueda, page: action.page });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.EVALUAR_ENCUESTA_FAILURE })
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.EVALUAR_ENCUESTA_FAILURE})
    }
}
export function* evaluarEncuestaSaga(){
    yield takeLatest(gestionDeCalidadActions.EVALUAR_ENCUESTA_REQUEST, evaluarEncuesta)
}

// Reportes de encuestas de satisfacción
function* informacionReporte(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = (action.page - 1) * 10;
        
        const response = yield call(getInformacionReporteCall, token, action.idServicio, action.idMes, action.anio, action.tipo, page)
        console.log(response);

        if (response.status === 200) {
            const informacionReporte = response.data;
            const informacionReporteCantidad = response.data.cantidad;
            let columnsReport = [];
            let tendenciaAnualGraph = {};
            let promediosMensualesGraph = {};
            let etiquetas = [];
            let cantidad = [];
            let cantidadTendencia = [];

            if(informacionReporte.camposReporte){
                for(let j in informacionReporte.camposReporte){
                    if(informacionReporte.camposReporte[j].folio){
                        informacionReporte.camposReporte[j].folioEncuesta = informacionReporte.camposReporte[j].folio.folioEncuesta;
                        informacionReporte.camposReporte[j].id = informacionReporte.camposReporte[j].folio.idFolioEncuesta;
                        columnsReport = [
                            { title: 'Folio', dataIndex: 'folioEncuesta', key: 'folioEncuesta', align: 'center' },
                            { title: 'Calificación', dataIndex: 'calificacion', key: 'calificacion', align: 'center', width: '22%' },
                        ];
                    } else if (informacionReporte.camposReporte[j].pregunta) {
                        informacionReporte.camposReporte[j].preguntaEncuesta = informacionReporte.camposReporte[j].pregunta.pregunta;
                        informacionReporte.camposReporte[j].id = informacionReporte.camposReporte[j].pregunta.idPreguntaEncuesta;
                        columnsReport = [
                            { title: 'Pregunta', dataIndex: 'preguntaEncuesta', key: 'preguntaEncuesta', align: 'center' },
                            { title: 'Calificación', dataIndex: 'calificacion', key: 'calificacion', align: 'center', width: '22%' },
                        ];
                    } else if(informacionReporte.camposReporte[j].servicio){
                        informacionReporte.camposReporte[j].servicioEncuesta = informacionReporte.camposReporte[j].servicio.nombre;
                        informacionReporte.camposReporte[j].id = informacionReporte.camposReporte[j].servicio.idServicio;
                        columnsReport = [
                            { title: 'Servicio', dataIndex: 'servicioEncuesta', key: 'servicioEncuesta', align: 'center' },
                            { title: 'Calificación', dataIndex: 'calificacion', key: 'calificacion', align: 'center', width: '22%' },
                       ];
                    }
                }
            }
            
            if(informacionReporte.tendenciaAnual){
                for(let i in informacionReporte.tendenciaAnual){
                    cantidadTendencia.push(informacionReporte.tendenciaAnual[i].cantidad.toFixed(2));
                }

                tendenciaAnualGraph = {
                    cantidades: cantidadTendencia
                };
            }

            if(informacionReporte.promediosMensuales){
                for(let i in informacionReporte.promediosMensuales){
                    etiquetas.push(informacionReporte.promediosMensuales[i].etiqueta);
                    cantidad.push(informacionReporte.promediosMensuales[i].cantidad);
                }

                promediosMensualesGraph = {
                    etiquetas: etiquetas,
                    cantidades: cantidad
                };
            }

            console.log('promediosMensualesGraph', promediosMensualesGraph)

            yield put({ type: gestionDeCalidadActions.OBTENER_INFORMACION_REPORTE_SUCCESS, informacionReporte, columnsReport, informacionReporteCantidad, tendenciaAnualGraph, promediosMensualesGraph })
        } else {
            yield put ({ type: gestionDeCalidadActions.OBTENER_INFORMACION_REPORTE_FAILURE})
        }
    } catch (error) {
        yield put ({ type: gestionDeCalidadActions.OBTENER_INFORMACION_REPORTE_FAILURE})
    }
}
export function* informacionReporteSaga() {
    yield takeLatest(gestionDeCalidadActions.OBTENER_INFORMACION_REPORTE_REQUEST, informacionReporte )
}

function* exportarReporteEncuestas(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        let camposReporte = [];

        if(action.tipo !== 'Servicio'){
            camposReporte = [{
                'calificacion': action.camposReporte.calificacion,
                'folio': action.camposReporte.folio,
                'graficaEncuestas': action.camposReporte.graficaEncuestas,
                'pregunta': action.camposReporte.pregunta,
                'servicio': action.camposReporte.servicio,
            }];
        } else {
            camposReporte = [{
                'calificacion': action.camposReporteOrigin[0].calificacion,
                'folio': action.camposReporteOrigin[0].folio,
                'graficaEncuestas': action.camposReporteOrigin[0].graficaEncuestas,
                'pregunta': action.camposReporteOrigin[0].pregunta,
                'servicio': action.camposReporteOrigin[0].servicio,
            }];
        }

        console.log('camposReporte', camposReporte)

        let data = {};
        if(action.tipo !== 'Servicio'){
            data = {
                'idMes': action.idMes, 
                'anio': action.annio, 
                'idServicio': action.idServicio, 
                'cantidadEncuestas': action.cantidadEncuestas, 
                'promedioServicio': action.promedioServicio, 
                'camposReporte': camposReporte,
                'cantidad': action.cantidad,
            };
        }else {
            let tendenciaAnual = action.informacionReporte.tendenciaAnual;
            let promediosMensuales = action.informacionReporte.promediosMensuales;

            data = {
                'idMes': action.idMes, 
                'anio': action.annio, 
                'idServicio': action.idServicio, 
                'cantidadEncuestas': action.cantidadEncuestas, 
                'promedioServicio': action.promedioServicio, 
                'camposReporte': camposReporte,
                'cantidad': action.cantidad,
                'tendenciaAnual': tendenciaAnual,
                'promediosMensuales': promediosMensuales,
            };
        }

        console.log('data', data)
        
        const response = yield call(exportarReporteEncuestasCall, token, data);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se descargó el archivo de forma exitosa";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "ReporteEncuestas" + action.tipo + "_" + new Date().getDate() + "-" + (new Date().getMonth() + 1) + "_" + + new Date().getFullYear() + "-" + new Date().getHours() + "_" + new Date().getMinutes() + "_" + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.OBTENER_INFORMACION_REPORTE_PDF_SUCCESS });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.OBTENER_INFORMACION_REPORTE_PDF_FAILURE })
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.OBTENER_INFORMACION_REPORTE_PDF_FAILURE})
    }
}
export function* exportarReporteEncuestasSaga(){
    yield takeLatest(gestionDeCalidadActions.OBTENER_INFORMACION_REPORTE_PDF_REQUEST, exportarReporteEncuestas)
}

function* generalReportExport(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
    
        let response = yield call(generalReportExportCall, token, action.idServicio, action.idMes, action.anio, action.tipo);
        console.log('response', response)
 
        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "ReporteEncuestasGeneral" + action.tipo + "_" + new Date().getDate() + "-" +  (new Date().getMonth() + 1) + "-" + + new Date().getFullYear() + "_" + new Date().getHours() + "_" + new Date().getMinutes() + "_" + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            
            yield put({ type: gestionDeCalidadActions.GET_GENERAL_REPORT_PDF_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.GET_GENERAL_REPORT_PDF_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: gestionDeCalidadActions.GET_GENERAL_REPORT_PDF_FAILURE });
    }
}
export function* generalReportExportSaga() {
    yield takeLatest(gestionDeCalidadActions.GET_GENERAL_REPORT_PDF_REQUEST, generalReportExport);
}

function* comparativoServicioPDF(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const serviciosPrimera = [];
        const serviciosSegunda = [];

        for(let i in action.serviciosPrimera){
            serviciosPrimera.push(parseInt(action.serviciosPrimera[i].split('-')));
        }

        for(let i in action.serviciosSegunda){
            serviciosSegunda.push(parseInt(action.serviciosSegunda[i].split('-')));
        }

        const data = {
            'idMesP': action.idMesUno, 
            'anioP': action.anioUno, 
            'idsServiciosP': serviciosPrimera,
            'idMesS': action.idMesDos, 
            'anioS': action.anioDos, 
            'idsServiciosS': serviciosSegunda,
        };
        console.log(`data`, data)
        const response = yield call(comparativoServicioPDFCall, token, data);
        console.log(response)
        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se descargó el archivo de forma exitosa";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "ComparativoServicio" + action.tipo + "_" + new Date().getDate() + "-" + (new Date().getMonth() + 1) + "_" + + new Date().getFullYear() + "-" + new Date().getHours() + "_" + new Date().getMinutes() + "_" + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.COMPARATIVO_SERVICIO_PDF_SUCCESS });
        } else {
            const showErrorMsg = true;
            const textMessage = 'Al menos una de las opciones seleccionadas no cuenta con encuestas.';
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.COMPARATIVO_SERVICIO_PDF_FAILURE })
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.COMPARATIVO_SERVICIO_PDF_FAILURE})
    }
}
export function* comparativoServicioPDFSaga(){
    yield takeLatest(gestionDeCalidadActions.COMPARATIVO_SERVICIO_PDF_REQUEST, comparativoServicioPDF)
}

function* informacionPrimerComparativo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        
        const response = yield call(getInformacionReporteCall, token, action.idServicio, action.idMes, action.anio, action.tipo, 0)
        console.log(response);

        if (response.status === 200) {
            const informacionPrimerComparativo = response.data;

            if(informacionPrimerComparativo.camposReporte){
                for(let j in informacionPrimerComparativo.camposReporte){
                    if(informacionPrimerComparativo.camposReporte[j].folio){
                        informacionPrimerComparativo.camposReporte[j].folioEncuesta = informacionPrimerComparativo.camposReporte[j].folio.folioEncuesta;
                        informacionPrimerComparativo.camposReporte[j].id = informacionPrimerComparativo.camposReporte[j].folio.idFolioEncuesta;
                    } else if (informacionPrimerComparativo.camposReporte[j].pregunta) {
                        informacionPrimerComparativo.camposReporte[j].preguntaEncuesta = informacionPrimerComparativo.camposReporte[j].pregunta.pregunta;
                        informacionPrimerComparativo.camposReporte[j].id = informacionPrimerComparativo.camposReporte[j].pregunta.idPreguntaEncuesta;
                    } else if(informacionPrimerComparativo.camposReporte[j].servicio){
                        informacionPrimerComparativo.camposReporte[j].servicioEncuesta = informacionPrimerComparativo.camposReporte[j].servicio.nombre;
                        informacionPrimerComparativo.camposReporte[j].id = informacionPrimerComparativo.camposReporte[j].servicio.idServicio;
                    }
                }
            }

            console.log('informacionPrimerComparativo', informacionPrimerComparativo)

            yield put({ type: gestionDeCalidadActions.OBTENER_INFORMACION_COMPARATIVO1_SUCCESS, informacionPrimerComparativo })
        } else {
            yield put ({ type: gestionDeCalidadActions.OBTENER_INFORMACION_COMPARATIVO1_FAILURE})
        }
    } catch (error) {
        yield put ({ type: gestionDeCalidadActions.OBTENER_INFORMACION_COMPARATIVO1_FAILURE})
    }
} export function* informacionPrimerComparativoSaga() {
    yield takeLatest(gestionDeCalidadActions.OBTENER_INFORMACION_COMPARATIVO1_REQUEST, informacionPrimerComparativo )
}

function* informacionSegundoComparativo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        
        const response = yield call(getInformacionReporteCall, token, action.idServicio, action.idMes, action.anio, action.tipo, 0)
        console.log(response);

        if (response.status === 200) {
            const informacionSegundoComparativo = response.data;

            if(informacionSegundoComparativo.camposReporte){
                for(let j in informacionSegundoComparativo.camposReporte){
                    if(informacionSegundoComparativo.camposReporte[j].folio){
                        informacionSegundoComparativo.camposReporte[j].folioEncuesta = informacionSegundoComparativo.camposReporte[j].folio.folioEncuesta;
                        informacionSegundoComparativo.camposReporte[j].id = informacionSegundoComparativo.camposReporte[j].folio.idFolioEncuesta;
                    } else if (informacionSegundoComparativo.camposReporte[j].pregunta) {
                        informacionSegundoComparativo.camposReporte[j].preguntaEncuesta = informacionSegundoComparativo.camposReporte[j].pregunta.pregunta;
                        informacionSegundoComparativo.camposReporte[j].id = informacionSegundoComparativo.camposReporte[j].pregunta.idPreguntaEncuesta;
                    } else if(informacionSegundoComparativo.camposReporte[j].servicio){
                        informacionSegundoComparativo.camposReporte[j].servicioEncuesta = informacionSegundoComparativo.camposReporte[j].servicio.nombre;
                        informacionSegundoComparativo.camposReporte[j].id = informacionSegundoComparativo.camposReporte[j].servicio.idServicio;
                    }
                }
            }

            console.log('informacionSegundoComparativo', informacionSegundoComparativo)

            yield put({ type: gestionDeCalidadActions.OBTENER_INFORMACION_COMPARATIVO2_SUCCESS, informacionSegundoComparativo })
        } else {
            yield put ({ type: gestionDeCalidadActions.OBTENER_INFORMACION_COMPARATIVO2_FAILURE})
        }
    } catch (error) {
        yield put ({ type: gestionDeCalidadActions.OBTENER_INFORMACION_COMPARATIVO2_FAILURE})
    }
} export function* informacionSegundoComparativoSaga() {
    yield takeLatest(gestionDeCalidadActions.OBTENER_INFORMACION_COMPARATIVO2_REQUEST, informacionSegundoComparativo )
}

//Reporte Dinámico para Encuestas de Satisfacción
function* listaReporteDinamicoES(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
        console.log(`userInfo`, userInfo);
        
        let listaGerencias = [];
        if(action.formulario.idsGerencias){
            if(action.formulario.idsGerencias[0]){
                for(let i in action.formulario.idsGerencias){
                    if(action.formulario.idsGerencias[i].split(' - ')[1] === 'Sin gerencia asignada'){
                        listaGerencias.push(null)
                    }else{
                        listaGerencias.push(action.formulario.idsGerencias[i].split(' - ')[0])
                    }
                }
            }
            else{
                listaGerencias = null;
            }
        }
        else{
            listaGerencias = null;
        }
        let idsGruposSer = [];
        if(action.formulario.idsGruposSer){
            if(action.formulario.idsGruposSer[0]){
                for(let i in action.formulario.idsGruposSer){
                    idsGruposSer.push(action.formulario.idsGruposSer[i].split(' - ')[0]);
                }
            }
            else{
                idsGruposSer = null;
            }
        }
        else{
            idsGruposSer = null;
        }
        let idsServicios = [];
        if(action.formulario.idsServicios){
            if(action.formulario.idsServicios[0]){
                for(let i in action.formulario.idsServicios){
                    idsServicios.push(action.formulario.idsServicios[i].split(' - ')[0]);
                }
            }
            else{
                if(!action.especial){
                    for(let x in userInfo[0].serviciosByIdUsuario){
                        idsServicios.push(userInfo[0].serviciosByIdUsuario[x].idServicio);                    
                    }
                }
                else{
                    idsServicios = null;
                }
            }
        }
        else{
            if(!action.especial){
                for(let x in userInfo[0].serviciosByIdUsuario){
                    idsServicios.push(userInfo[0].serviciosByIdUsuario[x].idServicio);                    
                }
            }
            else{
                idsServicios = null;
            }
        }
        let idsMeses = [];
        if(action.formulario.idsMeses){
            if(action.formulario.idsMeses[0]){
                for(let i in action.formulario.idsMeses){
                    idsMeses.push(action.formulario.idsMeses[i].split(' - ')[0]);
                }
            }
            else{
                idsMeses = null;
            }
        }
        else{
            idsMeses = null;
        }
        const data = {
            "idsGruposSer": action.formulario.tipoAgrupado === 'Por grupo servicio' ? [action.agrupado.id] : idsGruposSer,
            "idsGerencias": action.formulario.tipoAgrupado === 'Por gerencia' ? [action.agrupado.id] : listaGerencias,
            "idsServicios": action.formulario.tipoAgrupado === 'Por servicio' ? [action.agrupado.id] : idsServicios,
            "idsMeses": action.formulario.tipoAgrupado === 'Por mes' ? [action.agrupado.id] : idsMeses,
            "estados": action.formulario.tipoAgrupado === 'Por estado' ? [action.agrupado.nombre] : action.formulario.estados ? action.formulario.estados : null,
            "anio": action.año,
            "page": (action.page - 1) * 10,
            "offset": 10,
            "order": action.order.order,
            "field": action.order.field,
            "grupoC": action.checkboxData.grupoC ? action.checkboxData.grupoC : false,
            "gerenciaC": action.checkboxData.gerenciaC ? action.checkboxData.gerenciaC : false,
            "servicioC": action.checkboxData.servicioC ? action.checkboxData.servicioC : false,
            "mesC": action.checkboxData.mesC ? action.checkboxData.mesC : false,
            'anioC': action.checkboxData.mesC ? true : false,
            "folioC": action.checkboxData.folioC ? action.checkboxData.folioC : false,
            "calificacionC": action.checkboxData.calificacionC ? action.checkboxData.calificacionC : false,
            "comentarioC": action.checkboxData.comentarioC ? action.checkboxData.comentarioC : false,
        }
        console.log(`data`, data);

        const response = yield call(reporteDinamicoESCall, token, data)
        console.log(response)

        if (response.status === 200) {
            const listaReporteDinamicoES = response.data.data;
            const listaReporteDinamicoESCantidad = response.data.cantidad;
            for(let i in listaReporteDinamicoES){
                listaReporteDinamicoES[i].id = i + 1;
            }

            if(action.agrupado){
                const listaReporteDinamicoAgrupadoES = action.agrupado.listaReporteDinamicoAgrupadoES;
                const listaReporteDinamicoAgrupadoESCantidad = action.agrupado.listaReporteDinamicoAgrupadoESCantidad;
                for(let i in listaReporteDinamicoAgrupadoES){
                    if(action.agrupado.id === listaReporteDinamicoAgrupadoES[i].id){
                        listaReporteDinamicoAgrupadoES[i].agrupados = response.data;
                        listaReporteDinamicoAgrupadoES[i].agrupados.page = action.page;
                        for(let x in listaReporteDinamicoAgrupadoES[i].agrupados.data){
                            listaReporteDinamicoAgrupadoES[i].agrupados.data[x].id = parseInt(x) + 1;
                        };
                    }
                }
                console.log(`listaReporteDinamicoAgrupadoES cambio`, listaReporteDinamicoAgrupadoES)
                yield put({ type: gestionDeCalidadActions.REPORTE_DINAMICO_AGRUPADO_ES_SUCCESS, listaReporteDinamicoAgrupadoES, listaReporteDinamicoAgrupadoESCantidad })
                yield put({ type: gestionDeCalidadActions.REPORTE_DINAMICO_ES_FAILURE });
            }
            else{
                yield put({ type: gestionDeCalidadActions.REPORTE_DINAMICO_ES_SUCCESS, listaReporteDinamicoES, listaReporteDinamicoESCantidad })
            }
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: gestionDeCalidadActions.REPORTE_DINAMICO_ES_FAILURE});
        }
    } catch (error) {
        yield put({type: gestionDeCalidadActions.REPORTE_DINAMICO_ES_FAILURE});
    }
}

export function* listaReporteDinamicoESSaga() {
    yield takeLatest(gestionDeCalidadActions.REPORTE_DINAMICO_ES_REQUEST, listaReporteDinamicoES)
}

function* listaReporteDinamicoESAgrupado(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
        console.log(`userInfo`, userInfo);

        let listaGerencias = [];
        if(action.formulario.idsGerencias){
            if(action.formulario.idsGerencias[0]){
                for(let i in action.formulario.idsGerencias){
                    if(action.formulario.idsGerencias[i].split(' - ')[1] === 'Sin gerencia asignada'){
                        listaGerencias.push(null)
                    }else{
                        listaGerencias.push(action.formulario.idsGerencias[i].split(' - ')[0])
                    }
                }
            }
            else{
                listaGerencias = null;
            }
        }
        else{
            listaGerencias = null;
        }
        let idsGruposSer = [];
        if(action.formulario.idsGruposSer){
            if(action.formulario.idsGruposSer[0]){
                for(let i in action.formulario.idsGruposSer){
                    idsGruposSer.push(action.formulario.idsGruposSer[i].split(' - ')[0])
                }
            }
            else{
                idsGruposSer = null;
            }
        }
        else{
            idsGruposSer = null;
        }
        let idsServicios = [];
        if(action.formulario.idsServicios){
            if(action.formulario.idsServicios[0]){
                for(let i in action.formulario.idsServicios){
                    idsServicios.push(action.formulario.idsServicios[i].split(' - ')[0]);
                }
            }
            else{
                if(!action.especial){
                    for(let x in userInfo[0].serviciosByIdUsuario){
                        idsServicios.push(userInfo[0].serviciosByIdUsuario[x].idServicio);                    
                    }
                }
                else{
                    idsServicios = null;
                }
            }
        }
        else{
            if(!action.especial){
                for(let x in userInfo[0].serviciosByIdUsuario){
                    idsServicios.push(userInfo[0].serviciosByIdUsuario[x].idServicio);                    
                }
            }
            else{
                idsServicios = null;
            }
        }
        let idsMeses = [];
        if(action.formulario.idsMeses){
            if(action.formulario.idsMeses[0]){
                for(let i in action.formulario.idsMeses){
                    idsMeses.push(action.formulario.idsMeses[i].split(' - ')[0]);
                }
            }
            else{
                idsMeses = null;
            }
        }
        else{
            idsMeses = null;
        }

        const data = {
            "idsGruposSer": idsGruposSer,
            "idsGerencias": listaGerencias,
            "idsServicios": idsServicios,
            "idsMeses":idsMeses,
            "estados": action.formulario.estados ? action.formulario.estados : null,
            "anio": action.año,
            "page": (action.page - 1) * 10,
            "offset": 10,
            "order": action.order.order,
            "field": action.order.field,
            "grupoC": action.checkboxData.grupoC ? action.checkboxData.grupoC : false,
            "gerenciaC": action.checkboxData.gerenciaC ? action.checkboxData.gerenciaC : false,
            "servicioC": action.checkboxData.servicioC ? action.checkboxData.servicioC : false,
            "mesC": action.checkboxData.mesC ? action.checkboxData.mesC : false,
            'anioC': action.checkboxData.mesC ? true : false,
            "folioC": action.checkboxData.folioC ? action.checkboxData.folioC : false,
            "calificacionC": action.checkboxData.calificacionC ? action.checkboxData.calificacionC : false,
            "comentarioC": action.checkboxData.comentarioC ? action.checkboxData.comentarioC : false,
            "tipoAgrupado": action.formulario.tipoAgrupado,
            "orderAgrupado": "DESC",
            "fieldAgrupado": action.formulario.tipoAgrupado === 'Por servicio' ? 'ser.Nombre' : action.formulario.tipoAgrupado === 'Por gerencia' ? 'cg.NombreGerencia' : action.formulario.tipoAgrupado === 'Por estado' ? 'ei.Estado' : action.formulario.tipoAgrupado === 'Por grupo servicio' ? 'gsr.Nombre' : 'meses.Mes'
        }
        console.log(`data`, data);

        const response = yield call(reporteDinamicoAgrupadoESCall, token, data)
        console.log(response)

        if(response.status === 200){
            const listaReporteDinamicoAgrupadoES = response.data.data;
            const listaReporteDinamicoAgrupadoESCantidad = response.data.cantidad;
            if(action.formulario.tipoAgrupado === 'Por estado'){
                for(let i in listaReporteDinamicoAgrupadoES){
                    listaReporteDinamicoAgrupadoES[i].id = i
                }
            }
            if(action.formulario.tipoAgrupado === 'Por gerencia'){
                for(let i in listaReporteDinamicoAgrupadoES){
                    if(listaReporteDinamicoAgrupadoES[i].id === 0){
                        listaReporteDinamicoAgrupadoES[i].nombre = 'Sin gerencia asignada';
                    }
                }
            }

            for(let i in listaReporteDinamicoAgrupadoES){
                const dataAgrupado = {
                    "idsGerencias": action.formulario.tipoAgrupado === 'Por gerencia' ? listaReporteDinamicoAgrupadoES[i].id === 0 ? [null] : [listaReporteDinamicoAgrupadoES[i].id] : listaGerencias,
                    "idsGruposSer": action.formulario.tipoAgrupado === 'Por grupo servicio' ? [listaReporteDinamicoAgrupadoES[i].id] : idsGruposSer,
                    "idsServicios": action.formulario.tipoAgrupado === 'Por servicio' ? [listaReporteDinamicoAgrupadoES[i].id] : idsServicios,
                    "idsMeses": action.formulario.tipoAgrupado === 'Por mes' ? [listaReporteDinamicoAgrupadoES[i].id] : idsMeses,
                    'estados': action.formulario.tipoAgrupado === 'Por estado' ? [listaReporteDinamicoAgrupadoES[i].nombre] : (action.formulario.estados ? action.formulario.estados : null),
                    "idMes": action.formulario.fechaEvaluacion ? parseInt(action.formulario.fechaEvaluacion.format('MM-YYYY').split('-')[0]) : null,
                    "anio": action.año,
                    "page": (action.page - 1) * 10,
                    "offset": 10,
                    "order": action.order.order,
                    "field": action.order.field,
                    "grupoC": action.checkboxData.grupoC ? action.checkboxData.grupoC : false,
                    "gerenciaC": action.checkboxData.gerenciaC ? action.checkboxData.gerenciaC : false,
                    "servicioC": action.checkboxData.servicioC ? action.checkboxData.servicioC : false,
                    "mesC": action.checkboxData.mesC ? action.checkboxData.mesC : false,
                    'anioC': action.checkboxData.mesC ? true : false,
                    "folioC": action.checkboxData.folioC ? action.checkboxData.folioC : false,
                    "calificacionC": action.checkboxData.calificacionC ? action.checkboxData.calificacionC : false,
                    "comentarioC": action.checkboxData.comentarioC ? action.checkboxData.comentarioC : false,
                }
                console.log(`dataAgrupado`, dataAgrupado);

                const responseAgrupado = yield call(reporteDinamicoESCall, token, dataAgrupado)
                console.log(`responseAgrupado`, responseAgrupado)
                if(responseAgrupado.status === 200){
                    listaReporteDinamicoAgrupadoES[i].agrupados = responseAgrupado.data;
                    listaReporteDinamicoAgrupadoES[i].agrupados.page = 1;
                    for(let x in listaReporteDinamicoAgrupadoES[i].agrupados.data){
                        listaReporteDinamicoAgrupadoES[i].agrupados.data[x].id = parseInt(x) + 1;
                    };
                }
                else{
                    const showErrorMsg = true;
                    const textMessage = responseAgrupado.message;

                    yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                    yield put({ type: gestionDeCalidadActions.REPORTE_DINAMICO_AGRUPADO_ES_FAILURE });
                }
            }
            yield put({ type: gestionDeCalidadActions.REPORTE_DINAMICO_AGRUPADO_ES_SUCCESS, listaReporteDinamicoAgrupadoES, listaReporteDinamicoAgrupadoESCantidad })

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.REPORTE_DINAMICO_AGRUPADO_ES_FAILURE });
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.REPORTE_DINAMICO_AGRUPADO_ES_FAILURE });
    }
}
export function* listaReporteDinamicoESAgrupadoSaga(){
    yield takeLatest(gestionDeCalidadActions.REPORTE_DINAMICO_AGRUPADO_ES_REQUEST, listaReporteDinamicoESAgrupado)
}

function* exportarReporteDinamicoPDFES(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
        console.log(`userInfo`, userInfo);

        let listaGerencias = [];
        if(action.formulario.idsGerencias){
            if(action.formulario.idsGerencias[0]){
                for(let i in action.formulario.idsGerencias){
                    if(action.formulario.idsGerencias[i].split(' - ')[1] === 'Sin gerencia asignada'){
                        listaGerencias.push(null)
                    }else{
                        listaGerencias.push(action.formulario.idsGerencias[i].split(' - ')[0])
                    }
                }
            }
            else{
                listaGerencias = null;
            }
        }
        else{
            listaGerencias = null;
        }
        let idsGruposSer = [];
        if(action.formulario.idsGruposSer){
            if(action.formulario.idsGruposSer[0]){
                for(let i in action.formulario.idsGruposSer){
                    idsGruposSer.push(action.formulario.idsGruposSer[i].split(' - ')[0])
                }
            }
            else{
                idsGruposSer = null;
            }
        }
        else{
            idsGruposSer = null;
        }
        let idsServicios = [];
        if(action.formulario.idsServicios){
            if(action.formulario.idsServicios[0]){
                for(let i in action.formulario.idsServicios){
                    idsServicios.push(action.formulario.idsServicios[i].split(' - ')[0]);
                }
            }
            else{
                if(!action.especial){
                    for(let x in userInfo[0].serviciosByIdUsuario){
                        idsServicios.push(userInfo[0].serviciosByIdUsuario[x].idServicio);                    
                    }
                }
                else{
                    idsServicios = null;
                }
            }
        }
        else{
            if(!action.especial){
                for(let x in userInfo[0].serviciosByIdUsuario){
                    idsServicios.push(userInfo[0].serviciosByIdUsuario[x].idServicio);                    
                }
            }
            else{
                idsServicios = null;
            }
        }
        let idsMeses = [];
        if(action.formulario.idsMeses){
            if(action.formulario.idsMeses[0]){
                for(let i in action.formulario.idsMeses){
                    idsMeses.push(action.formulario.idsMeses[i].split(' - ')[0]);
                }
            }
            else{
                idsMeses = null;
            }
        }
        else{
            idsMeses = null;
        }
        const data = {
            "idsGruposSer": idsGruposSer,
            "idsGerencias": listaGerencias,
            "idsServicios": idsServicios,
            "idsMeses":idsMeses,
            "estados": action.formulario.estados ? action.formulario.estados : null,
            "anio": action.año,
            "page": null,
            "offset": null,
            "order": action.order.order,
            "field": action.order.field,
            "grupoC": action.checkbox.grupoC ? action.checkbox.grupoC : false,
            "gerenciaC": action.checkbox.gerenciaC ? action.checkbox.gerenciaC : false,
            "servicioC": action.checkbox.servicioC ? action.checkbox.servicioC : false,
            "mesC": action.checkbox.mesC ? action.checkbox.mesC : false,
            'anioC': action.checkbox.mesC ? true : false,
            "folioC": action.checkbox.folioC ? action.checkbox.folioC : false,
            "calificacionC": action.checkbox.calificacionC ? action.checkbox.calificacionC : false,
            "comentarioC": action.checkbox.comentarioC ? action.checkbox.comentarioC : false,
            'estadoC': true,
            "tipoAgrupado": action.agrupado !== 'SinAgrupar' ? action.agrupado : '',
            "orderAgrupado": "DESC",
            "fieldAgrupado": action.formulario.tipoAgrupado === 'Por servicio' ? 'ser.Nombre' : action.formulario.tipoAgrupado === 'Por gerencia' ? 'cg.NombreGerencia' : action.formulario.tipoAgrupado === 'Por estado' ? 'ei.Estado' : action.formulario.tipoAgrupado === 'Por grupo servicio' ? 'gsr.Nombre' : action.formulario.tipoAgrupado === 'Por mes' ? 'meses.Mes' : '',
        }
        console.log(`data`, data);

        const response = yield call(reporteDinamicoPDFESCall, token, data)
        console.log(response)

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = 'Reporte Dinámico Encuestas de Satisfacción - ' + new Date().getDate() + "-" + new Date().getMonth() + "-" + new Date().getFullYear() + "_" + new Date().getHours() + new Date().getMinutes() + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({type: gestionDeCalidadActions.PDF_REPORTE_DINAMICO_ES_SUCCESS})

            yield put({
                type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG,
                showSuccessMsg: true,
                textMessage: 'El archivo se descargó de forma correcta'
            });

        } else {
            const showErrorMsg = true;
            const textMessage = 'No se pudo descargar el PDF con las columnas y filtros seleccionados';

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: gestionDeCalidadActions.PDF_REPORTE_DINAMICO_ES_FAILURE});
        }
    } catch (error) {
        yield put({type: gestionDeCalidadActions.PDF_REPORTE_DINAMICO_ES_FAILURE});
    }
}

export function* exportarReporteDinamicoPDFESSaga() {
    yield takeLatest(gestionDeCalidadActions.PDF_REPORTE_DINAMICO_ES_REQUEST, exportarReporteDinamicoPDFES)
}

function* exportarReporteDinamicoExcelES(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
        console.log(`userInfo`, userInfo);

        let listaGerencias = [];
        if(action.formulario.idsGerencias){
            if(action.formulario.idsGerencias[0]){
                for(let i in action.formulario.idsGerencias){
                    if(action.formulario.idsGerencias[i].split(' - ')[1] === 'Sin gerencia asignada'){
                        listaGerencias.push(null)
                    }else{
                        listaGerencias.push(action.formulario.idsGerencias[i].split(' - ')[0])
                    }
                }
            }
            else{
                listaGerencias = null;
            }
        }
        else{
            listaGerencias = null;
        }
        let idsGruposSer = [];
        if(action.formulario.idsGruposSer){
            if(action.formulario.idsGruposSer[0]){
                for(let i in action.formulario.idsGruposSer){
                    idsGruposSer.push(action.formulario.idsGruposSer[i].split(' - ')[0])
                }
            }
            else{
                idsGruposSer = null;
            }
        }
        else{
            idsGruposSer = null;
        }
        let idsServicios = [];
        if(action.formulario.idsServicios){
            if(action.formulario.idsServicios[0]){
                for(let i in action.formulario.idsServicios){
                    idsServicios.push(action.formulario.idsServicios[i].split(' - ')[0]);
                }
            }
            else{
                if(!action.especial){
                    for(let x in userInfo[0].serviciosByIdUsuario){
                        idsServicios.push(userInfo[0].serviciosByIdUsuario[x].idServicio);                    
                    }
                }
                else{
                    idsServicios = null;
                }
            }
        }
        else{
            if(!action.especial){
                for(let x in userInfo[0].serviciosByIdUsuario){
                    idsServicios.push(userInfo[0].serviciosByIdUsuario[x].idServicio);                    
                }
            }
            else{
                idsServicios = null;
            }
        }
        let idsMeses = [];
        if(action.formulario.idsMeses){
            if(action.formulario.idsMeses[0]){
                for(let i in action.formulario.idsMeses){
                    idsMeses.push(action.formulario.idsMeses[i].split(' - ')[0]);
                }
            }
            else{
                idsMeses = null;
            }
        }
        else{
            idsMeses = null;
        }
        
        const data = {
            "idsGruposSer": idsGruposSer,
            "idsGerencias": listaGerencias,
            "idsServicios": idsServicios,
            "idsMeses":idsMeses,
            "estados": action.formulario.estados ? action.formulario.estados : null,
            "anio": action.año,
            "page": null,
            "offset": null,
            "order": action.order.order,
            "field": action.order.field,
            "grupoC": action.checkbox.grupoC ? action.checkbox.grupoC : false,
            "gerenciaC": action.checkbox.gerenciaC ? action.checkbox.gerenciaC : false,
            "servicioC": action.checkbox.servicioC ? action.checkbox.servicioC : false,
            "mesC": action.checkbox.mesC ? action.checkbox.mesC : false,
            'anioC': action.checkbox.mesC ? true : false,
            "folioC": action.checkbox.folioC ? action.checkbox.folioC : false,
            "calificacionC": action.checkbox.calificacionC ? action.checkbox.calificacionC : false,
            "comentarioC": action.checkbox.comentarioC ? action.checkbox.comentarioC : false,
            'estadoC': true,
            "tipoAgrupado": action.agrupado !== 'SinAgrupar' ? action.agrupado : '',
            "orderAgrupado": "DESC",
            "fieldAgrupado": action.formulario.tipoAgrupado === 'Por servicio' ? 'ser.Nombre' : action.formulario.tipoAgrupado === 'Por gerencia' ? 'cg.NombreGerencia' : action.formulario.tipoAgrupado === 'Por estado' ? 'ei.Estado' : action.formulario.tipoAgrupado === 'Por grupo servicio' ? 'gsr.Nombre' : action.formulario.tipoAgrupado === 'Por mes' ? 'meses.Mes' : '',
        }
        console.log(`data`, data);

        const response = yield call(reporteDinamicoExcelESCall, token, data)
        console.log(response)

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = 'Reporte Dinámico Encuestas de Satisfacción - ' + new Date().getDate() + "-" + new Date().getMonth() + "-" + new Date().getFullYear() + "_" + new Date().getHours() + new Date().getMinutes() + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: gestionDeCalidadActions.EXCEL_REPORTE_DINAMICO_ES_SUCCESS })
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage: 'El archivo se descargó de forma correcta' });

        } else {
            const showErrorMsg = true;
            const textMessage = 'No se pudo descargar el Excel con las columnas y filtros seleccionados';

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: gestionDeCalidadActions.EXCEL_REPORTE_DINAMICO_ES_FAILURE});
        }
    } catch (error) {
        yield put({type: gestionDeCalidadActions.EXCEL_REPORTE_DINAMICO_ES_FAILURE});
    }
}

export function* exportarReporteDinamicoExcelESSaga() {
    yield takeLatest(gestionDeCalidadActions.EXCEL_REPORTE_DINAMICO_ES_REQUEST, exportarReporteDinamicoExcelES)
}

// Cumplimiento de Estándares
function* listaCumplimientoEstandares(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const idServicio = 'idServicio=' + parseInt(action.busqueda.servicio);
        let referencia = '';
        if (action.busqueda.estandar){
            referencia = '&referencia=' + action.busqueda.estandar;
        }
        let order = '&order=ASC';
        let field  = '&field=idEstandarDesempeno';
        if(action.busqueda.orden){
            order = '&order=' + action.busqueda.orden.order;
            field = '&field=' + action.busqueda.orden.field;
        }
        
        const response = yield call(listaCumplimientoEstandaresCall, token, idServicio, referencia, order, field, page)
        console.log(response)

        if(response.status === 200){
            const listaCumplimientosEstandares = response.data.data;
            const listaCumplimientosEstandaresCantidad = response.data.cantidad;
            for(let i in listaCumplimientosEstandares){
                listaCumplimientosEstandares[i].claveMetodoSupervision = listaCumplimientosEstandares[i].claveMetodoSupervisionByIdClaveMetodoSupervision.claveMetodoSupervision;
                listaCumplimientosEstandares[i].referenciaGrid = listaCumplimientosEstandares[i].referenciaDesempenoByIdReferenciaDesempeno.referencia;
                listaCumplimientosEstandares[i].descripcionGrid = listaCumplimientosEstandares[i].referenciaDesempenoByIdReferenciaDesempeno.descripcion;
            }

            yield put({ type: gestionDeCalidadActions.LISTA_CUMPLIMIENTO_ESTANDARES_SUCCESS, listaCumplimientosEstandares, listaCumplimientosEstandaresCantidad })

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.LISTA_CUMPLIMIENTO_ESTANDARES_FAILURE });
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.LISTA_CUMPLIMIENTO_ESTANDARES_FAILURE });
    }
}
export function* listaCumplimientoEstandaresSaga(){
    yield takeLatest(gestionDeCalidadActions.LISTA_CUMPLIMIENTO_ESTANDARES_REQUEST, listaCumplimientoEstandares)
}

function* generarFolioEstandar(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const cumplimientos = [];
        for(let i in action.listaSeleccionados){
            let cumplimientoEstandar = {
                "idCumplimientoEstandar": null,
                "idFolioEstandar": null,
                "idEstandarDesempeno": action.listaSeleccionados[i],
                "cumple":true
            }
            cumplimientos.push(cumplimientoEstandar);
        }
        const data = {
            "idFolioEstandar": null,
            "folioEstandar": "",
            "estado": "",
            "idUbicacion": null,
            "idServicio": parseInt(action.busqueda.servicio),
            "usuarioEvalua": "",
            "puestoEvalua": "",
            "fechaEvalua": null,
            "rutaEvidencia": "",
            "fechaAlta": null,
            "fechaActualizacion": null,
            "cumplimientosByIdFolioEstandar": cumplimientos,
            'observaciones': ''
        }
        console.log(`data`, data);
        
        const response = yield call(crearFolioEstandarCall, token, data)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se generó el folio de forma correcta, se descargará el archivo...";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.EXPORTAR_FOLIO_ESTANDAR_REQUEST, folio: response.data.id});
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'generarFolioEstandar' });

            yield put({ type: gestionDeCalidadActions.CREAR_FOLIO_ESTANDAR_SUCCESS });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.CREAR_FOLIO_ESTANDAR_FAILURE })
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.CREAR_FOLIO_ESTANDAR_FAILURE})
    }
}
export function* generarFolioEstandarSaga(){
    yield takeLatest(gestionDeCalidadActions.CREAR_FOLIO_ESTANDAR_REQUEST, generarFolioEstandar)
}

function* exportarFolioEstandar(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
    
        let response = yield call(exportarFolioEstandarCall, token, action.folio);
        console.log('response', response)
 
        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Folio-Estandar_" + new Date().getDate() + "-" +  (new Date().getMonth() + 1) + "-" + + new Date().getFullYear() + "_" + new Date().getHours() + "_" + new Date().getMinutes() + "_" + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage: 'Se descargó de forma exitosa el archivo' });

            yield put({ type: gestionDeCalidadActions.EXPORTAR_FOLIO_ESTANDAR_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.EXPORTAR_FOLIO_ESTANDAR_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: gestionDeCalidadActions.EXPORTAR_FOLIO_ESTANDAR_FAILURE });
    }
}
export function* exportarFolioEstandarSaga() {
    yield takeLatest(gestionDeCalidadActions.EXPORTAR_FOLIO_ESTANDAR_REQUEST, exportarFolioEstandar);
}

function* listaFoliosEstandaresEvaluar(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        
        const response = yield call(listaFoliosEstandaresEvaluarCall, token, page)
        console.log(response)

        if(response.status === 200){
            const listaFoliosEstandaresEvaluar = response.data.data;
            const listaFoliosEstandaresEvaluarCantidad = response.data.cantidad;
            for(let i in listaFoliosEstandaresEvaluar){
                listaFoliosEstandaresEvaluar[i].servicio = listaFoliosEstandaresEvaluar[i].servicioByIdServicio.nombre;
                listaFoliosEstandaresEvaluar[i].fechaAltaGrid = listaFoliosEstandaresEvaluar[i].fechaAlta.split('T')[0].split('-').reverse().join('-') + ' ' + listaFoliosEstandaresEvaluar[i].fechaAlta.split('T')[1].split(':')[0] + ':' +listaFoliosEstandaresEvaluar[i].fechaAlta.split('T')[1].split(':')[1]
            }

            yield put({ type: gestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_EVALUAR_SUCCESS, listaFoliosEstandaresEvaluar, listaFoliosEstandaresEvaluarCantidad })

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_EVALUAR_FAILURE });
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_EVALUAR_FAILURE });
    }
}
export function* listaFoliosEstandaresEvaluarSaga(){
    yield takeLatest(gestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_EVALUAR_REQUEST, listaFoliosEstandaresEvaluar)
}

function* evaluarFolioEstandar(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        let data = action.folioEstandarSeleccionado;
        data.fechaEvalua = action.formulario.fechaEvalua.format('YYYY-MM-DDTHH:mm');
        data.idUbicacion = parseInt(action.formulario.ubicacion);
        data.usuarioEvalua = action.formulario.nombreEvalua;
        data.puestoEvalua = action.formulario.puestoEvalua;
        data.observaciones = action.formulario.observaciones ? action.formulario.observaciones : '';
        for(let i in action.listaEstandares){
            data.cumplimientosByIdFolioEstandar[i].cumple = action.listaEstandares[i].cumple ? true : false
        }
        console.log(`data`, data);

        const formData = new FormData();

        formData.append('file', action.formulario.evidenciaPDF.file.originFileObj);
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        
        const response = yield call(evaluarFolioEstandarCall, token, formData)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se evaluó el folio de estándar de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_EVALUAR_REQUEST, page: action.page});
            yield put({ type: gestionDeCalidadActions.MODAL_EVALUAR_FOLIO_ESTANDAR, showModalEvaluarFolioEstandar: false});
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'evaluarFolioEstandar' });

            yield put({ type: gestionDeCalidadActions.EVALUAR_FOLIO_ESTANDAR_SUCCESS });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.EVALUAR_FOLIO_ESTANDAR_FAILURE })
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.EVALUAR_FOLIO_ESTANDAR_FAILURE})
    }
}
export function* evaluarFolioEstandarSaga(){
    yield takeLatest(gestionDeCalidadActions.EVALUAR_FOLIO_ESTANDAR_REQUEST, evaluarFolioEstandar)
}

function* listaFoliosEstandaresEvaluados(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        
        const response = yield call(listaFoliosEstandaresEvaluadosCall, token, page)
        console.log(response)

        if(response.status === 200){
            const listaFoliosEstandaresEvaluados = response.data.data;
            const listaFoliosEstandaresEvaluadosCantidad = response.data.cantidad;
            for(let i in listaFoliosEstandaresEvaluados){
                listaFoliosEstandaresEvaluados[i].servicio = listaFoliosEstandaresEvaluados[i].servicioByIdServicio.nombre;
                listaFoliosEstandaresEvaluados[i].fechaAltaGrid = listaFoliosEstandaresEvaluados[i].fechaAlta.split('T')[0].split('-').reverse().join('-') + ' ' + listaFoliosEstandaresEvaluados[i].fechaAlta.split('T')[1].split(':')[0] + ':' +listaFoliosEstandaresEvaluados[i].fechaAlta.split('T')[1].split(':')[1]
            }

            yield put({ type: gestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_EVALUADOS_SUCCESS, listaFoliosEstandaresEvaluados, listaFoliosEstandaresEvaluadosCantidad })

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_EVALUADOS_FAILURE });
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_EVALUADOS_FAILURE });
    }
}
export function* listaFoliosEstandaresEvaluadosSaga(){
    yield takeLatest(gestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_EVALUADOS_REQUEST, listaFoliosEstandaresEvaluados)
}

// Reportes de desempeño de estandares generales
function* listaFoliosEstandaresDesempeno(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        var idServicio = action.idServicio;
        if (!action.idServicio) {
            idServicio = '';
        }

        const response = yield call(listaFoliosEstandaresDesempenoCall, token, page, idServicio, action.order, action.field);
        console.log(response);
        if(response.status === 200){
            var listaFoliosEstandaresDesempeno = response.data.data;
            const listaFoliosEstandaresDesempenoCantidad = response.data.cantidad;

            for(let i in listaFoliosEstandaresDesempeno){
                listaFoliosEstandaresDesempeno[i].fechaEvalua = listaFoliosEstandaresDesempeno[i].fechaEvalua.split('T')[0].split('-').reverse().join('-');
                listaFoliosEstandaresDesempeno[i].fechaAlta = listaFoliosEstandaresDesempeno[i].fechaAlta.split('T')[0].split('-').reverse().join('-');
            }

            console.log('lista', listaFoliosEstandaresDesempeno);
            yield put({ type: gestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_DESEMPENO_SUCCESS, listaFoliosEstandaresDesempeno, listaFoliosEstandaresDesempenoCantidad });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_DESEMPENO_FAILURE });
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_DESEMPENO_FAILURE });
    }
}
export function* listaFoliosEstandaresDesempenoSaga(){
    yield takeLatest(gestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_DESEMPENO_REQUEST, listaFoliosEstandaresDesempeno)
}

function* listaEstandaresPorFolio(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const idFolioEstandar = action.idFolioEstandar;

        const response = yield call(listaEstandaresPorFolioCall, token, page, idFolioEstandar, action.param);
        console.log(response);
        if(response.status === 200){
            var listaEstandaresPorFolio = response.data.data;
            const listaEstandaresPorFolioCantidad = response.data.cantidad;

            for(let i in listaEstandaresPorFolio){
                listaEstandaresPorFolio[i].referencia = listaEstandaresPorFolio[i].estandarByIdEstandarDesempeno.referencia;
                listaEstandaresPorFolio[i].fechaAlta = listaEstandaresPorFolio[i].estandarByIdEstandarDesempeno.fechaAlta.split('T')[0];
                listaEstandaresPorFolio[i].fechaActualizacion = listaEstandaresPorFolio[i].estandarByIdEstandarDesempeno.fechaActualizacion.split('T')[0];
                listaEstandaresPorFolio[i].descripcion = listaEstandaresPorFolio[i].estandarByIdEstandarDesempeno.descripcion;
            }

            console.log('lista', listaEstandaresPorFolio);
            yield put({ type: gestionDeCalidadActions.LISTA_ESTANDARES_POR_FOLIO_SUCCESS, listaEstandaresPorFolio: listaEstandaresPorFolio, listaEstandaresPorFolioCantidad });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.LISTA_ESTANDARES_POR_FOLIO_FAILURE });
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.LISTA_ESTANDARES_POR_FOLIO_FAILURE });
    }
}
export function* listaEstandaresPorFolioSaga(){
    yield takeLatest(gestionDeCalidadActions.LISTA_ESTANDARES_POR_FOLIO_REQUEST, listaEstandaresPorFolio)
}

function* subirArchivoPorEstandar(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const formData = new FormData();

        if(action.formulario.pdf){
            formData.append('file', action.formulario.pdf.file.originFileObj);
        }

        const response = yield call(subirArchivoPorEstandarCall, token, formData, action.idCumplimientoEstandar)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Archivo guardado con éxito";

            const page = action.auxData.page;
            const idFolioEstandar = action.auxData.idFolioEstandar;
            const param = action.auxData.param;

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.SUBIR_ARCHIVO_POR_ESTANDAR_SUCCESS })

            yield put({ type: gestionDeCalidadActions.MODAL_UPLOAD_PDF_POR_ESTANDAR, showModalUploadPDFPorEstandar: false, idCumplimientoEstandar: null });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'subirArchivoPorEstandar' });
            yield put({ type: gestionDeCalidadActions.LISTA_ESTANDARES_POR_FOLIO_REQUEST, page, idFolioEstandar, param });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: gestionDeCalidadActions.SUBIR_ARCHIVO_POR_ESTANDAR_FAILURE})
        }
    } catch (error) {
        yield put({ type: gestionDeCalidadActions.SUBIR_ARCHIVO_POR_ESTANDAR_FAILURE})
    }
}
export function* subirArchivoPorEstandarSaga(){
    yield takeLatest(gestionDeCalidadActions.SUBIR_ARCHIVO_POR_ESTANDAR_REQUEST, subirArchivoPorEstandar)
}
