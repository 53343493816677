import React, { Component } from 'react';
import { Row, Col, Table, Button, Input, Card, Tooltip, Popover, Spin, Select } from 'antd';
import { connect } from "react-redux";
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ContenedorPuntoReorden from './ContenedorPuntoReorden';
import ModalUsuario from './ModalUsuario';
import {  ExclamationCircleOutlined , FilePdfOutlined, PlusOutlined } from '@ant-design/icons';
const { Search } = Input;
const {Option} = Select;
class CatalogoPuntoReorden extends Component {
    state = {
        page: 1,
        busqueda: {order: 'DESC', field: 'numeroFolio', tipo: null},
        order: 'DESC',
        field: 'numeroFolio',
        param: '',
        idUsuario: undefined,
     }
    componentDidMount(){
        this.props.onGetRequisicionPuntoReorden(1, this.state.busqueda);
        this.props.onGetUsuarioRes();
    }
    render() { 
        const {onGetRequisicionPuntoReorden, fetchingRequisicionPuntoReordenBusqueda, 
            listaRequisicionPuntoReorden, listaRequisicionPuntoReordenCantidad, onGenerarRequisicionPuntoReorden, listaUsuariosActivos,
            fetchingListaUsuariosActivos
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        };

        const descargarFolio = key => {
            if(key.folioRequisicionMateriales){
                console.log('key descargar compra', key)
                this.props.onDescargarCompra(key);
                return;
            }
            this.props.onDescargar(this.state.idUsuario, key);
        };

        const generarFolioRequisicion = () => {
            onGenerarRequisicionPuntoReorden(this.state.page, this.state.busqueda);
        }

        const onBuscarRequisicion = param => {
            const busqueda = { param: param, field: this.state.field, order: this.state.order, tipo: this.state.busqueda.tipo}
            this.setState({ busqueda: busqueda, page: 1 });
            onGetRequisicionPuntoReorden(1, busqueda)
        };

        const handleTableChange = (pagination, filtro,  orden) => {
            console.log('orden', orden, filtro)
            if(orden.field) {
                let campo = orden.columnKey;
                let order = 'ASC';
                if (orden.order === 'descend') {
                    order = 'DESC';
                }
                if (!orden.order) {
                    order = 'DESC';
                    campo = 'numeroFolio';
                }

                const busqueda = { param: this.state.param, field: campo, order: order, tipo: this.state.busqueda.tipo}
                onGetRequisicionPuntoReorden(pagination.current, busqueda)
                this.setState({
                    order: order,
                    field: campo,
                    busqueda: busqueda,
                    page: pagination.current,
                })
            } else {
                onGetRequisicionPuntoReorden(pagination.current, this.state.busqueda);
                this.setState({
                    page: pagination.current,
                })
            }
        }
        
        const onTipoRequisicion = (value) => {
            const busqueda = { param: this.state.param, field: this.state.busqueda.field, order: this.state.busqueda.order, tipo: value}
            this.setState({
                busqueda: busqueda,
                page: 1
            });
            onGetRequisicionPuntoReorden(1, busqueda);
        }

        const showOpciones = key =>{
            console.log('key', key);
            this.props.onShowOpciones(key, this.state.page, this.state.busqueda);
            // if( key.estadoFolio === "Cerrado" || key.estadoFolio === "Cancelado"){
            //     this.props.onGetConsultaFolio(key.numeroFolio, this.state.page)
            // } else {
            //     this.props.onGetConsultaFolio(key.numeroFolio, this.state.page)
            // }
            this.props.onGetConsultaFolio(key.numeroFolio, 1)
        }

        const onChangeUsuario = (value) => {
            console.log(value)
            if(value){
                value = parseInt(value);
            }
            this.setState({
                idUsuario: value
            })
        }

        const columns = [{
            title: 'Folio',
            dataIndex: 'numeroFolio',
            key: 'numeroFolio',
            sorter: true
        },{
            title: 'Estado de Folio',
            dataIndex: 'estadoFolio',
            key: 'estadoFolio',
            sorter: true
        }, {
            title: 'Tipo Requisición',
            dataIndex: 'tipo',
            key: 'tipo',
        }, {
            title: 'Fecha de Alta',
            dataIndex: 'fechaAlta',
            key: 'fechaAlta',
            sorter: true
        },{
            title: 'Opciones',
            key: '',
            align: 'center',
            render: (record) => (
               <a className='editarIcono' onClick={ () => showOpciones(record) }> < ExclamationCircleOutlined />
               </a>
            ),          
        },{
            title: 'Descargar Folio',
            key: '',
            align: 'center',
            render: (record) => (
                <>
                {record.folioRequisicionMateriales ? (
                    <a className='editarIcono' onClick={ () => descargarFolio(record) } disabled={!permisosUsuario.descargarFolioDeRequisicionPorReorden}> <FilePdfOutlined /></a>
                ) : (
                    <Popover placement="top" title='Usuario responsable para descargar PDF'
                        content={
                            <Spin spinning={this.props.fetchingDescargarFolioRequisicionPuntoReorden || this.props.fetchingDescargarFolioRequisicionCompra}>
                                <Row justify='space-around'>
                                    <Col span={21}>
                                        <Select 
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el usuario responsable a exportar PDF"
                                            loading={fetchingListaUsuariosActivos}  
                                            onChange={onChangeUsuario}
                                            showSearch
                                        >
                                            {listaUsuariosActivos.map(option => <Option key={option.idUsuario + ' - ' + option.nombre + " " + option.aPaterno + ": " + option.username}>{option.idUsuario + ' - ' + option.nombre + " " + option.aPaterno + ": " + option.username}</Option>)}
                                        </Select>
                                    </Col>
                                    <Col span={2}>
                                        <Button className="iconSecundary" shape="circle" onClick={ () => descargarFolio(record) } icon={<FilePdfOutlined />} disabled={!this.state.idUsuario}/>
                                    </Col>
                                </Row>
                            </Spin>
                        } 
                        trigger="click"
                    >
                        <a className='editarIcono' disabled={!permisosUsuario.descargarFolioDeRequisicionPorReorden}> <FilePdfOutlined /></a>
                    </Popover>
                )}
                </>
            ),          
        },];

        return ( 
            <Card className="cardCatalogos" title={<div className="headerLista">Requisición por Punto de Reorden y Compra</div>}>
                <Row type='flex' justify='space-between'>
                    <Col span={16}>
                        <Search className='buscarModulos' placeholder="Buscar folio /Estado folio" onSearch={value => onBuscarRequisicion(value)} style={{ width: '100%' }} />
                    </Col>
                    <Col span={6}>
                        <Select allowClear
                            style={{ width: '100%' }}
                            placeholder="Selecciona el tipo"
                            onChange={onTipoRequisicion}
                        >
                            <Option key='false'>Punto de Reorden</Option>
                            <Option key='true'>Solicitud de Compra</Option>
                        </Select>
                    </Col>
                    <Col span={1}>
                        <Tooltip title='Generar Folio de Requisición por Punto de Reorden' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={generarFolioRequisicion} disabled={!permisosUsuario.crearFolioDeRequisicionPorReorden} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    
                    <Col span={24}>
                        <br />
                        <Table
                            size="small"
                            rowKey="numeroFolio"
                            columns={columns}
                            dataSource={listaRequisicionPuntoReorden}
                            loading={fetchingRequisicionPuntoReordenBusqueda || this.props.fetchingDescargarFolioRequisicionCompra || this.props.fetchingRequisicionPuntoReordenNuevo}
                            locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaRequisicionPuntoReordenCantidad, simple: true, current: this.state.page }}
                        />
                    </Col>
                </Row>
                <ContenedorPuntoReorden/>
                <ModalUsuario/>
            </Card>
         );
    }
}

const mapStateToProps = state => {
    return {
        fetchingRequisicionPuntoReordenBusqueda: state.AlmacenesReducer.fetchingRequisicionPuntoReordenBusqueda,
        listaRequisicionPuntoReorden: state.AlmacenesReducer.listaRequisicionPuntoReorden,
        listaRequisicionPuntoReordenCantidad: state.AlmacenesReducer.listaRequisicionPuntoReordenCantidad, 

        fetchingDescargarFolioRequisicionPuntoReorden: state.AlmacenesReducer.fetchingDescargarFolioRequisicionPuntoReorden,
        fetchingDescargarFolioRequisicionCompra: state.AlmacenesReducer.fetchingDescargarFolioRequisicionCompra,
        listaUsuariosActivos: state.AlmacenesReducer.listaUsuariosActivos,
        fetchingListaUsuariosActivos: state.AlmacenesReducer.fetchingListaUsuariosActivos,
        fetchingRequisicionPuntoReordenNuevo: state.AlmacenesReducer.fetchingRequisicionPuntoReordenNuevo,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onShowOpciones: (folioSeleccionado, page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_REQUISICION_PUNTO_REORDEN", showModalRequisicionPuntoReorden: true, folioSeleccionado, page, busqueda})
        },
        onGetRequisicionPuntoReorden: (page, busqueda) => {
            dispatch({ type: "REQUISICION_PUNTO_REORDEN_BUSQUEDA_REQUEST", page, busqueda})
        },
        onGenerarRequisicionPuntoReorden: (page, busqueda) => {
            dispatch({ type: "GENERAR_REQUISICION_PUNTO_REORDEN_REQUEST", page, busqueda })
        },
        onGetConsultaFolio: (folio, page) => {
            dispatch({ type: "CONSULTA_FOLIO_REQUISICION_REQUEST", folio, page })
        },
        // onDescargar: (folioSeleccionado) => {
        //     dispatch({ type: "SHOW_MODAL_DESCARGAR_FOLIO", showModalDescargarFolio: true,  folioSeleccionado})
        // },

        onGetUsuarioRes: () => {
            dispatch({ type: 'LISTA_USUARIOS_ACTIVOS_REQUEST', });
        },
        onGetUsuarioResLimpiar: () => {
            dispatch({ type: 'LISTA_USUARIOS_ACTIVOS_SUCCESS',  listaUsuariosActivos: []});
        },
        onDescargar: (idUsuario, folioSeleccionado) => {
            dispatch({ type: "DESCARGAR_FOLIO_REQUISICION_PUNTOREORDEN_REQUEST", idUsuario, folioSeleccionado});
        },
        onDescargarCompra: (folioSeleccionado) => {
            dispatch ({ type: 'DESCARGAR_FOLIO_REQUISICION_COMPRA_REQUEST', folioSeleccionado });
        },
    } 
}
 
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoPuntoReorden);
