import React, { Component } from 'react';
import { Form, Input, Button, Modal, Row, Col, Spin, Switch, Tooltip } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import { LaptopOutlined } from '@ant-design/icons';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalEditarRol extends Component {
    formRef = React.createRef();

    render() {
        const { fetchingEditarGrupoServicio, showModalEditarGrupoServicio, onEditarGrupoServicio, onShowModalEditarGrupoServicio, onActualizarEquipamiento,
            grupoServicioSeleccionado, limpiarFormulario, onLimpiarFormulario
        } = this.props;
        console.log(grupoServicioSeleccionado);

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onEditarGrupoServicio(formulario, grupoServicioSeleccionado, this.props.page, this.props.equipamientoTec);
        };
        

        console.log('limpiarFormulario');
        console.log(limpiarFormulario);
        if (limpiarFormulario && limpiarFormulario === 'editarGrupoServicio') {
            console.log('entró a limpiar');
            this.formRef.current.resetFields();
            onLimpiarFormulario();
        }

        const handleCancel = () => {
            onShowModalEditarGrupoServicio()
        }

        const onSeleccionarEquip = (value) => { onActualizarEquipamiento(value); };

         return (
            <Modal
            visible={showModalEditarGrupoServicio}
            title='Editar Grupo de Servicio'
            onCancel={handleCancel}
            footer={false}
            >
                <Spin spinning={fetchingEditarGrupoServicio}>
                    {grupoServicioSeleccionado ? (
                    <Form {...layout}
                    name="formulario"
                    initialValues={grupoServicioSeleccionado}
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Form.Item
                            label="Nombre"
                            name="nombre"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Input maxLength={80} placeholder="Ingrese nombre" />
                        </Form.Item>
                        <Form.Item
                            label="Descripción"
                            name="descripcion"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Input maxLength={120} placeholder="Ingrese descripción" />
                        </Form.Item>
                        <Row justify='space-around'>
                            <Col span={12}>
                                <Form.Item
                                    name='equipamientoTec'
                                >
                                    <Tooltip title='Equipamiento tecnológico' placement="bottom">
                                        <Switch defaultChecked={grupoServicioSeleccionado.equipamientoTec} checkedChildren={<LaptopOutlined />} unCheckedChildren={<LaptopOutlined /> } onChange={onSeleccionarEquip}/>
                                    </Tooltip>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Button className="btnFiltrar" htmlType="submit"  style={{width:'100%'}}>Editar Grupo de Servicio</Button>
                            </Col>
                        </Row>
                    </Form>
                    ):false}
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarGrupoServicio: state.CatalogosGeneralesReducer.fetchingEditarGrupoServicio,
        showModalEditarGrupoServicio: state.CatalogosGeneralesReducer.showModalEditarGrupoServicio,
        fetchingListaPermisos: state.CatalogosGeneralesReducer.fetchingListaPermisos,
        listaPermisos: state.CatalogosGeneralesReducer.listaPermisos,
        grupoServicioSeleccionado: state.CatalogosGeneralesReducer.grupoServicioSeleccionado,
        page: state.CatalogosGeneralesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        equipamientoTec: state.CatalogosGeneralesReducer.equipamientoTec,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarGrupoServicio: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_GRUPO_SERVICIO', showModalEditarGrupoServicio: false, grupoServicioSeleccionado: null})
        },
        onEditarGrupoServicio: ( formulario, grupoServicioSeleccionado, page, equipamientoTec ) => {
            dispatch({ type: "EDITAR_GRUPO_SERVICIO_REQUEST" , formulario: formulario, grupoServicioSeleccionado: grupoServicioSeleccionado, page: page, equipamientoTec });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onActualizarEquipamiento: (equipamientoTec) => {
            dispatch({ type: 'EDITAR_GRUPO_SERVICIO_SUCCESS',  equipamientoTec});
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarRol);