import * as SolicitudDeServiciosActions from '../actions/SolicitudDeServiciosActions';

const newLocal = true;
const initialState = {
     catalogoSolicitudDeServicios: null,
     solicitudDeServicios: null,
     page: 1,
     busqueda: '',
     fetchingUbicacionesSinHijos: false,
     ubicacionesSinHijos: [],

     // Reducer Seguimiento Solicitudes de servicio
     fetchingSeguimientoFolioSS: false,
     listaSeguimientoFolioSS: [],
     listaSeguimientoFolioSSCantidad: null,
     showModalDetalleFolioSS: false,
     showModalOpcionesSeguimientoFolio: false,
     folioSolicitudServicioSeleccionado: null,
     folioSolicitudServicioSeleccionado2: null,//detalle folioSS
     fetchingCambioEstadoSeguimiento: false,
     
     fetchingListaServiciosConFallas: false,
     listaServiciosConFallas: [],
     fetchingUbicacionSeguimiento: false,
     ubicacionSeguimiento: '',
     fetchingListaTrazabilidad: false,
     listaTrazabilidad: [],
     listaTrazabilidadCantidad: null,
     fetchingListaProveedoresByServicio: false,
     listaProveedoresByServicio: [],
     cambiarVistaModalEstadoTerminado: false,
     formularioTerminado: null,
     listaEpp: [],
     listaHerramienta: [],
     listaInsumosT: [],
     showModalEstadoTerminado: false,
     currentStatus: 0,
     showModalContraseña: false,
     isEspecial: false,
     showModalProrroga: false,
     folioSeleccionadoProrroga: null,
     fetchingStatusProrroga: false,
     listaProrrogas: [],
     listaProrrogasCantidad: null,
     fetchingNuevaProrroga: false,
     limpiarClasificacion: false,
     showModalTraslado: false,
     fetchingObtenerTraslados: false,
     listaTraslados: [],
     listaTrasladosCantidad: false,


     //Catálogo de Tipos de Fallas
     fetchingListaTiposFallasActivas: false,
     listaTiposFallasActivas: [],

     fetchingListaTiposFallasBusqueda: false,
     listaTiposFallasBusqueda: [],
     listaTiposFallasBusquedaCantidad: 0,

     showModalNuevoTipoFalla: false,
     fetchingNuevoTipoFalla: false,

     showModalEditarTipoFalla: false,
     fetchingEditarTipoFalla: false,
     tipoFallaSeleccionado: null,

     //Catálogo de Fallas
     fetchingListaFallasBusqueda: false,
     fetchingExportarFallasExcel: false,
     listaFallasBusqueda: [],
     listaFallasBusquedaCantidad: 0,

     showModalNuevaFalla: false,
     fetchingNuevaFalla: false,

     showModalEditarFalla: false,
     fetchingEditarFalla: false,
     fallaSeleccionada: null,
     tipo: null,
     idServicio: null,
     listaTiposEOI: [],
     listaTiposReit: [],

     listaPartidasByServicio4Fallas: [],
     fetchingListaPartidasByServicio4Fallas: false,
     
     // CU - Recepción y captura de Solicitud de Servicio
     segundos: '00:00:00',
     showModalIdentificacion: false,
     identificacion: true,
     usuarioIdentificacion: '',
     areaPertenece: '',
     idUsuarioReporta: null,
     fetchingCrearSolicitudDeServicio: false,
     fetchingExportarSolicitudDeServicio: false,
     impreso: false,

     showModalFolioSolicitud: false, //CAMBIAR A FALSE
     fechaCreacion: '',
     horaCreacion: '',
     folio: '',
     fetchingListaFolioSS: false,
     listaFoliosSS: [],
     listaFoliosSSCantidad: null,
     solicitudDeServicioSeleccionado: [],
     showModalDetalleSS: false,
     listaFallasActivas: [],
     fetchingListaFallasActivas: false,
     limpiarCrono: false,
     listaFallasByServicio: [],
     fetchingListaFallasByServicio: false,

     fetchingListaUbicacionesSinInterior: false,
     listaUbicacionesSinInterior: [],

     fetchingListaServiciosActivosProveedor: false,
     listaServiciosActivosProveedores: [],

     listaPartidasByFalla: [],
     fetchingListaPartidasByFalla: false,

     fetchingTiempoLimite: false,
     
     // CU -  Reporteador Automático
     showModalCambiarHora: false,
     fetchingListaSSDiarios: false,
     listaSSDiariosNoCancelados: [],
     listaSSDiariosNoCanceladosCantidad: null,
     fechaVerificar: '',
     reporterBoolean: true,
     fetchingVisualizarReporte: false,
     fechaReporte: '',
     listaSSDiariosCantidad: null,
     listaSSDiarios: [],

     //Reporte Solicitud Servicio
     fetchingReporteBusqueda: false,
     listaReporteBusqueda: [],
     listaReporteBusquedaCantidad: 0,

     fetchingExportarReporteExcel: false,
     fetchingExportarReportePDF: false,

     showModalDetalleReporte: false,
     reporteSeleccionado: null,

     //Reporte Dinámico
     fetchingReporteDinamicoBusqueda: false,
     listaReporteDinamicoBusqueda: [],
     listaReporteDinamicoBusquedaCantidad: 0,

     fetchingExportarReporteDinamicoExcel: false,
     fetchingExportarReporteDinamicoPDF: false,

     formularioRD: {},
     listaReporteDinamicoGrupo: [],
     fetchingListaReporteDinamicoGrupo: false,
     listaReporteDinamicoGrupoCantidad: null,
     fetchingReporteDinamicoBusquedaWithout: false,
     listaReporteDinamicoBusquedaCantidadWithout: null,
     listaReporteDinamicoBusquedaWithout: [],
};

export default (state = initialState, action) => {
     switch (action.type) {
          case SolicitudDeServiciosActions.CAMBIAR_CATALOGO_SOLICITUD_SERVICIOS:
               return { ...state, catalogoSolicitudDeServicios: action.catalogoSolicitudDeServicios };

          case SolicitudDeServiciosActions.CAMBIAR_SOLICITUD_SERVICIOS:
               return { ...state, solicitudDeServicios: action.solicitudDeServicios, identificacion: state.identificacion};

          case SolicitudDeServiciosActions.UBICACIONES_SIN_HIJOS_REQUEST:
               return { ...state,  fetchingUbicacionesSinHijos: true };
          case SolicitudDeServiciosActions.UBICACIONES_SIN_HIJOS_SUCCESS:
               return { ...state,  fetchingUbicacionesSinHijos: false, ubicacionesSinHijos: action.ubicacionesSinHijos };
          case SolicitudDeServiciosActions.UBICACIONES_SIN_HIJOS_FAILURE:
               return { ...state,  fetchingUbicacionesSinHijos: false };

          // Reducer seguimiento de Folios Solicitudes de Servicio
          case SolicitudDeServiciosActions.LISTA_FOLIOS_SS_BUSQUEDA_REQUEST:
               return {...state, fetchingSeguimientoFolioSS: true};
          case SolicitudDeServiciosActions.LISTA_FOLIOS_SS_BUSQUEDA_SUCCESS:
               return {...state, fetchingSeguimientoFolioSS: false, listaSeguimientoFolioSS: action.listaSeguimientoFolioSS, listaSeguimientoFolioSSCantidad: action.listaSeguimientoFolioSSCantidad };
          case SolicitudDeServiciosActions.LISTA_FOLIOS_SS_BUSQUEDA_FAILURE:
               return {...state, fetchingSeguimientoFolioSS: false}; 
               
          case SolicitudDeServiciosActions.SHOW_MODAL_VER_DETALLE_FOLIO_SS:
               return{...state, showModalDetalleFolioSS: action.showModalDetalleFolioSS, folioSolicitudServicioSeleccionado2: action.folioSolicitudServicioSeleccionado2};     

           case SolicitudDeServiciosActions.SHOW_MODAL_OPCIONES_SEGUIMIENTO_FOLIO_SS:
               return{...state, showModalOpcionesSeguimientoFolio: action.showModalOpcionesSeguimientoFolio, folioSolicitudServicioSeleccionado: action.folioSolicitudServicioSeleccionado, page: action.page, busqueda: action.busqueda}; 

          case SolicitudDeServiciosActions.REASIGNAR_FOLIO_SS_REQUEST:
               return { ...state, fetchingCambioEstadoSeguimiento: true }
          case SolicitudDeServiciosActions.REASIGNAR_FOLIO_SS_SUCCESS:
               return { ...state, fetchingCambioEstadoSeguimiento: false }
          case SolicitudDeServiciosActions.REASIGNAR_FOLIO_SS_FAILURE:
               return { ...state, fetchingCambioEstadoSeguimiento: false }

          case SolicitudDeServiciosActions.EN_PROCESO_FOLIO_SS_REQUEST:
               return { ...state, fetchingCambioEstadoSeguimiento: true }
          case SolicitudDeServiciosActions.EN_PROCESO_FOLIO_SS_SUCCESS:
               return { ...state, fetchingCambioEstadoSeguimiento: false }
          case SolicitudDeServiciosActions.EN_PROCESO_FOLIO_SS_FAILURE:
               return { ...state, fetchingCambioEstadoSeguimiento: false }

          case SolicitudDeServiciosActions.TERMINADO_FOLIO_SS_REQUEST:
               return { ...state, fetchingCambioEstadoSeguimiento: true }
          case SolicitudDeServiciosActions.TERMINADO_FOLIO_SS_SUCCESS:
               return { ...state, fetchingCambioEstadoSeguimiento: false }
          case SolicitudDeServiciosActions.TERMINADO_FOLIO_SS_FAILURE:
               return { ...state, fetchingCambioEstadoSeguimiento: false }

          case SolicitudDeServiciosActions.CERRAR_FOLIO_SS_REQUEST:
               return { ...state, fetchingCambioEstadoSeguimiento: true }
          case SolicitudDeServiciosActions.CERRAR_FOLIO_SS_SUCCESS:
               return { ...state, fetchingCambioEstadoSeguimiento: false }
          case SolicitudDeServiciosActions.CERRAR_FOLIO_SS_FAILURE:
               return { ...state, fetchingCambioEstadoSeguimiento: false }

          case SolicitudDeServiciosActions.EN_ESPERA_FOLIO_SS_REQUEST:
               return { ...state, fetchingCambioEstadoSeguimiento: true }
          case SolicitudDeServiciosActions.EN_ESPERA_FOLIO_SS_SUCCESS:
               return { ...state, fetchingCambioEstadoSeguimiento: false }
          case SolicitudDeServiciosActions.EN_ESPERA_FOLIO_SS_FAILURE:
               return { ...state, fetchingCambioEstadoSeguimiento: false }

          case SolicitudDeServiciosActions.CANCELAR_FOLIO_SS_REQUEST:
               return { ...state, fetchingCambioEstadoSeguimiento: true }
          case SolicitudDeServiciosActions.CANCELAR_FOLIO_SS_SUCCESS:
               return { ...state, fetchingCambioEstadoSeguimiento: false }
          case SolicitudDeServiciosActions.CANCELAR_FOLIO_SS_FAILURE:
               return { ...state, fetchingCambioEstadoSeguimiento: false }

           case SolicitudDeServiciosActions.ABIERTO_FOLIO_SS_REQUEST:
               return { ...state, fetchingCambioEstadoSeguimiento: true }
          case SolicitudDeServiciosActions.ABIERTO_FOLIO_SS_SUCCESS:
               return { ...state, fetchingCambioEstadoSeguimiento: false }
          case SolicitudDeServiciosActions.ABIERTO_FOLIO_SS_FAILURE:
               return { ...state, fetchingCambioEstadoSeguimiento: false }

          case SolicitudDeServiciosActions.TRASLADO_FOLIO_SS_REQUEST:
               return { ...state, fetchingCambioEstadoSeguimiento: true }
          case SolicitudDeServiciosActions.TRASLADO_FOLIO_SS_SUCCESS:
               return { ...state, fetchingCambioEstadoSeguimiento: false }
          case SolicitudDeServiciosActions.TRASLADO_FOLIO_SS_FAILURE:
               return { ...state, fetchingCambioEstadoSeguimiento: false }

          case SolicitudDeServiciosActions.LISTA_SERVICIOS_ACTIVOS_CON_FALLAS_REQUEST:
               return {...state, fetchingListaServiciosConFallas: true};
          case SolicitudDeServiciosActions.LISTA_SERVICIOS_ACTIVOS_CON_FALLAS_SUCCESS:
               return {...state, fetchingListaServiciosConFallas: false, listaServiciosConFallas: action.listaServiciosConFallas };
          case SolicitudDeServiciosActions.LISTA_SERVICIOS_ACTIVOS_CON_FALLAS_FAILURE:
               return {...state, fetchingListaServiciosConFallas: false};  

          case SolicitudDeServiciosActions.OBTENER_UBICACION_REQUEST:
               return {...state, fetchingUbicacionSeguimiento: true};
          case SolicitudDeServiciosActions.OBTENER_UBICACION_SUCCESS:
               return {...state, fetchingUbicacionSeguimiento: false, ubicacionSeguimiento: action.ubicacionSeguimiento };
          case SolicitudDeServiciosActions.OBTENER_UBICACION_FAILURE:
               return {...state, fetchingUbicacionSeguimiento: false}; 

          case SolicitudDeServiciosActions.OBTENER_TRAZABILIDAD_FOLIO_REQUEST:
               return {...state, fetchingListaTrazabilidad: true};
          case SolicitudDeServiciosActions.OBTENER_TRAZABILIDAD_FOLIO_SUCCESS:
               return {...state, fetchingListaTrazabilidad: false, listaTrazabilidad: action.listaTrazabilidad, listaTrazabilidadCantidad: action.listaTrazabilidadCantidad };
          case SolicitudDeServiciosActions.OBTENER_TRAZABILIDAD_FOLIO_FAILURE:
               return {...state, fetchingListaTrazabilidad: false}; 

          case SolicitudDeServiciosActions.LISTA_PROVEEDORES_BY_SERVICIO_ACTIVOS_REQUEST:
               return {...state, fetchingListaProveedoresByServicio: true};
          case SolicitudDeServiciosActions.LISTA_PROVEEDORES_BY_SERVICIO_ACTIVOS_SUCCESS:
               return {...state, fetchingListaProveedoresByServicio: false, listaProveedoresByServicio: action.listaProveedoresByServicio};
          case SolicitudDeServiciosActions.LISTA_PROVEEDORES_BY_SERVICIO_ACTIVOS_FAILURE:
               return {...state, fetchingListaProveedoresByServicio: false}; 

          case SolicitudDeServiciosActions.CAMBIAR_VISTA_MODAL_ESTADO_TERMINADO:
               return {...state, cambiarVistaModalEstadoTerminado: action.cambiarVistaModalEstadoTerminado, formularioTerminado: action.formularioTerminado, listaEpp: action.listaEpp, listaHerramienta: action.listaHerramienta, listaInsumosT: action.listaInsumosT, folioSolicitudServicioSeleccionado: action.folioSolicitudServicioSeleccionado};
               
          case SolicitudDeServiciosActions.SHOW_MODAL_ESTADO_TERMINADO:
               return {...state, showModalEstadoTerminado: action.showModalEstadoTerminado, page: action.page, busqueda: action.busqueda };   

          case SolicitudDeServiciosActions.CURRENT_STATUS_SEGUIMIENTO:
               return {...state, currentStatus: action.currentStatus, cambiarVistaModalEstadoTerminado: action.cambiarVistaModalEstadoTerminado }

           case SolicitudDeServiciosActions.SHOW_MODAL_CONTRASEÑA:
               return {...state, showModalContraseña: action.showModalContraseña, isEspecial: action.isEspecial}
             
          case SolicitudDeServiciosActions.SHOW_MODAL_PRORROGA:
               return {...state, showModalProrroga: action.showModalProrroga, folioSeleccionadoProrroga: action.folioSeleccionadoProrroga, limpiarClasificacion: action.limpiarClasificacion };

          case SolicitudDeServiciosActions.LISTA_PRORROGA_REQUEST:
               return { ...state, fetchingStatusProrroga: true }
          case SolicitudDeServiciosActions.LISTA_PRORROGA_SUCCESS:
               return { ...state, fetchingStatusProrroga: false, listaProrrogas: action.listaProrrogas , listaProrrogasCantidad: action.listaProrrogasCantidad}
          case SolicitudDeServiciosActions.LISTA_PRORROGA_FAILURE:
               return { ...state, fetchingStatusProrroga: false }

          case SolicitudDeServiciosActions.CREAR_PRORROGA_REQUEST:
               return { ...state, fetchingNuevaProrroga: true }
          case SolicitudDeServiciosActions.CREAR_PRORROGA_SUCCESS:
               return { ...state, fetchingNuevaProrroga: false  }
          case SolicitudDeServiciosActions.CREAR_PRORROGA_FAILURE:
               return { ...state, fetchingNuevaProrroga: false }

         case SolicitudDeServiciosActions.SHOW_MODAL_TRASLADO:
                return {...state, showModalTraslado: action.showModalTraslado, folioSeleccionadoTraslado: action.folioSeleccionadoTraslado };
     
          case SolicitudDeServiciosActions.LISTA_TRASLADOS_REQUEST:
               return {...state, fetchingObtenerTraslados: true};
          case SolicitudDeServiciosActions.LISTA_TRASLADOS_SUCCESS:
               return {...state, fetchingObtenerTraslados: false, listaTraslados: action.listaTraslados, listaTrasladosCantidad: action.listaTrasladosCantidad };
          case SolicitudDeServiciosActions.LISTA_TRASLADOS_FAILURE:
               return {...state, fetchingObtenerTraslados: false};    


          //CATÁLOGO DE TIPOS DE FALLAS
          case SolicitudDeServiciosActions.LISTA_TIPOS_FALLAS_ACTIVAS_REQUEST:
               return { ...state, fetchingListaTiposFallasActivas: true };
          case SolicitudDeServiciosActions.LISTA_TIPOS_FALLAS_ACTIVAS_SUCCESS:
               return { ...state, fetchingListaTiposFallasActivas: false, listaTiposFallasActivas: action.listaTiposFallasActivas, listaTiposEOI: action.listaTiposEOI, listaTiposReit: action.listaTiposReit };
          case SolicitudDeServiciosActions.LISTA_TIPOS_FALLAS_ACTIVAS_FAILURE:
               return { ...state, fetchingListaTiposFallasActivas: false };

          case SolicitudDeServiciosActions.LISTA_TIPOS_FALLAS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaTiposFallasBusqueda: true };
          case SolicitudDeServiciosActions.LISTA_TIPOS_FALLAS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaTiposFallasBusqueda: false, listaTiposFallasBusqueda: action.listaTiposFallasBusqueda, listaTiposFallasBusquedaCantidad: action.listaTiposFallasBusquedaCantidad };
          case SolicitudDeServiciosActions.LISTA_TIPOS_FALLAS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaTiposFallasBusqueda: false };

          case SolicitudDeServiciosActions.ESTADO_TIPO_FALLA_REQUEST:
               return { ...state, fetchingListaTiposFallasBusqueda: true };
          case SolicitudDeServiciosActions.ESTADO_TIPO_FALLA_SUCCESS:
               return { ...state, fetchingListaTiposFallasBusqueda: false };
          case SolicitudDeServiciosActions.ESTADO_TIPO_FALLA_FAILURE:
               return { ...state, fetchingListaTiposFallasBusqueda: false };

          case SolicitudDeServiciosActions.SHOW_MODAL_NUEVO_TIPO_FALLA:
               return { ...state, showModalNuevoTipoFalla: action.showModalNuevoTipoFalla, page: action.page, busqueda: action.busqueda };

          case SolicitudDeServiciosActions.NUEVO_TIPO_FALLA_REQUEST:
               return { ...state, fetchingNuevoTipoFalla: true };
          case SolicitudDeServiciosActions.NUEVO_TIPO_FALLA_SUCCESS:
               return { ...state, fetchingNuevoTipoFalla: false };
          case SolicitudDeServiciosActions.NUEVO_TIPO_FALLA_FAILURE:
               return { ...state, fetchingNuevoTipoFalla: false };

          case SolicitudDeServiciosActions.SHOW_MODAL_EDITAR_TIPO_FALLA:
               return { ...state, showModalEditarTipoFalla: action.showModalEditarTipoFalla, tipoFallaSeleccionado: action.tipoFallaSeleccionado, page: action.page, busqueda: action.busqueda };

          case SolicitudDeServiciosActions.EDITAR_TIPO_FALLA_REQUEST:
               return { ...state, fetchingEditarTipoFalla: true };
          case SolicitudDeServiciosActions.EDITAR_TIPO_FALLA_SUCCESS:
               return { ...state, fetchingEditarTipoFalla: false };
          case SolicitudDeServiciosActions.EDITAR_TIPO_FALLA_FAILURE:
               return { ...state, fetchingEditarTipoFalla: false };

          //CATÁLOGO DE FALLAS
          case SolicitudDeServiciosActions.LISTA_FALLAS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaFallasBusqueda: true };
          case SolicitudDeServiciosActions.LISTA_FALLAS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaFallasBusqueda: false, listaFallasBusqueda: action.listaFallasBusqueda, listaFallasBusquedaCantidad: action.listaFallasBusquedaCantidad };
          case SolicitudDeServiciosActions.LISTA_FALLAS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaFallasBusqueda: false };

          case SolicitudDeServiciosActions.EXPORTAR_FALLAS_EXCEL_REQUEST:
               return { ...state, fetchingExportarFallasExcel: true };
          case SolicitudDeServiciosActions.EXPORTAR_FALLAS_EXCEL_SUCCESS:
               return { ...state, fetchingExportarFallasExcel: false };
          case SolicitudDeServiciosActions.EXPORTAR_FALLAS_EXCEL_FAILURE:
               return { ...state, fetchingExportarFallasExcel: false };

          case SolicitudDeServiciosActions.ESTADO_FALLA_REQUEST:
               return { ...state, fetchingListaFallasBusqueda: true };
          case SolicitudDeServiciosActions.ESTADO_FALLA_SUCCESS:
               return { ...state, fetchingListaFallasBusqueda: false };
          case SolicitudDeServiciosActions.ESTADO_FALLA_FAILURE:
               return { ...state, fetchingListaFallasBusqueda: false };

          case SolicitudDeServiciosActions.SHOW_MODAL_NUEVA_FALLA:
               return { ...state, showModalNuevaFalla: action.showModalNuevaFalla, page: action.page, busqueda: action.busqueda };

          case SolicitudDeServiciosActions.NUEVA_FALLA_REQUEST:
               return { ...state, fetchingNuevaFalla: true };
          case SolicitudDeServiciosActions.NUEVA_FALLA_SUCCESS:
               return { ...state, fetchingNuevaFalla: false };
          case SolicitudDeServiciosActions.NUEVA_FALLA_FAILURE:
               return { ...state, fetchingNuevaFalla: false };

          case SolicitudDeServiciosActions.SHOW_MODAL_EDITAR_FALLA:
               return { ...state, showModalEditarFalla: action.showModalEditarFalla, fallaSeleccionada: action.fallaSeleccionada, page: action.page, busqueda: action.busqueda, tipo: action.tipo, idServicio: action.idServicio, listaPartidasByServicio4Fallas: action.listaPartidasByServicio4Fallas };

          case SolicitudDeServiciosActions.EDITAR_FALLA_REQUEST:
               return { ...state, fetchingEditarFalla: true };
          case SolicitudDeServiciosActions.EDITAR_FALLA_SUCCESS:
               return { ...state, fetchingEditarFalla: false };
          case SolicitudDeServiciosActions.EDITAR_FALLA_FAILURE:
               return { ...state, fetchingEditarFalla: false };

          case SolicitudDeServiciosActions.LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_REQUEST:
               return { ...state, fetchingListaPartidasByServicio4Fallas: true };
          case SolicitudDeServiciosActions.LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_SUCCESS:
               return { ...state, fetchingListaPartidasByServicio4Fallas: false, listaPartidasByServicio4Fallas: action.listaPartidasByServicio4Fallas };
          case SolicitudDeServiciosActions.LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_FAILURE:
               return { ...state, fetchingListaPartidasByServicio4Fallas: false };

          // CU - Recepción y captura de Solicitud de Servicio
          case SolicitudDeServiciosActions.SHOW_MODAL_IDENTIFICACION:
               return { ...state, showModalIdentificacion: action.showModalIdentificacion, usuarioIdentificacion: action.usuarioIdentificacion, identificacion: action.identificacion, areaPertenece: action.areaPertenece, idUsuarioReporta: action.idUsuarioReporta, horaCreacion: action.horaCreacion,fechaCreacion: action.fechaCreacion, sinIdentificar: action.sinIdentificar };

          case SolicitudDeServiciosActions.CREAR_SOLICITUD_SERVICIO_REQUEST:
               return { ...state, fetchingCrearSolicitudDeServicio: true };
          case SolicitudDeServiciosActions.CREAR_SOLICITUD_SERVICIO_SUCCESS:
               return { ...state, fetchingCrearSolicitudDeServicio: false };
          case SolicitudDeServiciosActions.CREAR_SOLICITUD_SERVICIO_FAILURE:
               return { ...state, fetchingCrearSolicitudDeServicio: false };
          
          case SolicitudDeServiciosActions.EXPORTAR_SOLICITUD_SERVICIO_REQUEST:
               return { ...state, fetchingExportarSolicitudDeServicio: true };
          case SolicitudDeServiciosActions.EXPORTAR_SOLICITUD_SERVICIO_SUCCESS:
               return { ...state, fetchingExportarSolicitudDeServicio: false, impreso: action.impreso };
          case SolicitudDeServiciosActions.EXPORTAR_SOLICITUD_SERVICIO_FAILURE:
               return { ...state, fetchingExportarSolicitudDeServicio: false };

          case SolicitudDeServiciosActions.SHOW_MODAL_FOLIO_SOLICITUD:
               return { ...state, showModalFolioSolicitud: action.showModalFolioSolicitud, horaCreacion: action.horaCreacion,fechaCreacion: action.fechaCreacion, folio: action.folio, identificacion: action.identificacion, impreso: false };
          
          case SolicitudDeServiciosActions.LISTA_FOLIOS_SS_REQUEST:
               return { ...state, fetchingListaFolioSS: true };
          case SolicitudDeServiciosActions.LISTA_FOLIOS_SS_SUCCESS:
               return { ...state, fetchingListaFolioSS: false, listaFoliosSS: action.listaFoliosSS, listaFoliosSSCantidad: action.listaFoliosSSCantidad };
          case SolicitudDeServiciosActions.LISTA_FOLIOS_SS_FAILURE:
               return { ...state, fetchingListaFolioSS: false };

          case SolicitudDeServiciosActions.SHOW_MODAL_DETALLE_SS:
               return { ...state, showModalDetalleSS: action.showModalDetalleSS, solicitudDeServicioSeleccionado: action.solicitudDeServicioSeleccionado, page: action.page };

          case SolicitudDeServiciosActions.CRONOMETRO: 
               return { ...state, segundos: action.segundos }

          case SolicitudDeServiciosActions.RESTART_CRONOMETRO:
               return { ...state, segundos: action.segundos, limpiarCrono: action.limpiarCrono}

          case SolicitudDeServiciosActions.LISTA_FALLAS_ACTIVAS_BY_SERVICIO_REQUEST:
               return { ...state, fetchingListaFallasByServicio: true };
          case SolicitudDeServiciosActions.LISTA_FALLAS_ACTIVAS_BY_SERVICIO_SUCCESS:
               return { ...state, fetchingListaFallasByServicio: false, listaFallasByServicio: action.listaFallasByServicio };
          case SolicitudDeServiciosActions.LISTA_FALLAS_ACTIVAS_BY_SERVICIO_FAILURE:
               return { ...state, fetchingListaFallasByServicio: false }; 

          case SolicitudDeServiciosActions.LISTA_FALLAS_ACTIVAS_REQUEST:
               return { ...state, fetchingListaFallasActivas: true };
          case SolicitudDeServiciosActions.LISTA_FALLAS_ACTIVAS_SUCCESS:
               return { ...state, fetchingListaFallasActivas: false, listaFallasActivas: action.listaFallasActivas };
          case SolicitudDeServiciosActions.LISTA_FALLAS_ACTIVAS_FAILURE:
               return { ...state, fetchingListaFallasActivas: false };     
               
          case SolicitudDeServiciosActions.LISTA_UBICACIONES_SIN_INTERIOR_REQUEST:
               return { ...state, fetchingListaUbicacionesSinInterior: true };
          case SolicitudDeServiciosActions.LISTA_UBICACIONES_SIN_INTERIOR_SUCCESS:
               return { ...state, fetchingListaUbicacionesSinInterior: false, listaUbicacionesSinInterior: action.listaUbicacionesSinInterior };
          case SolicitudDeServiciosActions.LISTA_UBICACIONES_SIN_INTERIOR_FAILURE:
               return { ...state, fetchingListaUbicacionesSinInterior: false };

          case SolicitudDeServiciosActions.LISTA_SERVICIOS_ACTIVOS_PROVEEDOR_REQUEST:
               return {...state, fetchingListaServiciosActivosProveedor: true};
          case SolicitudDeServiciosActions.LISTA_SERVICIOS_ACTIVOS_PROVEEDOR_SUCCESS:
               return {...state, fetchingListaServiciosActivosProveedor: false, listaServiciosActivosProveedores: action.listaServiciosActivosProveedores };
          case SolicitudDeServiciosActions.LISTA_SERVICIOS_ACTIVOS_PROVEEDOR_FAILURE:
               return {...state, fetchingListaServiciosActivosProveedor: false}; 

          case SolicitudDeServiciosActions.LISTA_PARTIDAS_BY_FALLA_REQUEST:
               return { ...state, fetchingListaPartidasByFalla: true };
          case SolicitudDeServiciosActions.LISTA_PARTIDAS_BY_FALLA_SUCCESS:
               return { ...state, fetchingListaPartidasByFalla: false, listaPartidasByFalla: action.listaPartidasByFalla };
          case SolicitudDeServiciosActions.LISTA_PARTIDAS_BY_FALLA_FAILURE:
               return { ...state, fetchingListaPartidasByFalla: false };

          case SolicitudDeServiciosActions.REGISTRAR_TIEMPO_LIMITE_REQUEST:
               return { ...state, fetchingTiempoLimite: true };
          case SolicitudDeServiciosActions.REGISTRAR_TIEMPO_LIMITE_SUCCESS:
               return { ...state, fetchingTiempoLimite: false };
          case SolicitudDeServiciosActions.REGISTRAR_TIEMPO_LIMITE_FAILURE:
               return { ...state, fetchingTiempoLimite: false };

          //CATÁLOGO DE TIPOS DE FALLAS
          case SolicitudDeServiciosActions.LISTA_TIPOS_FALLAS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaTiposFallasBusqueda: true };
          case SolicitudDeServiciosActions.LISTA_TIPOS_FALLAS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaTiposFallasBusqueda: false, listaTiposFallasBusqueda: action.listaTiposFallasBusqueda, listaTiposFallasBusquedaCantidad: action.listaTiposFallasBusquedaCantidad };
          case SolicitudDeServiciosActions.LISTA_TIPOS_FALLAS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaTiposFallasBusqueda: false };

          case SolicitudDeServiciosActions.ESTADO_TIPO_FALLA_REQUEST:
               return { ...state, fetchingListaTiposFallasBusqueda: true };
          case SolicitudDeServiciosActions.ESTADO_TIPO_FALLA_SUCCESS:
               return { ...state, fetchingListaTiposFallasBusqueda: false };
          case SolicitudDeServiciosActions.ESTADO_TIPO_FALLA_FAILURE:
               return { ...state, fetchingListaTiposFallasBusqueda: false };

          case SolicitudDeServiciosActions.SHOW_MODAL_NUEVO_TIPO_FALLA:
               return { ...state, showModalNuevoTipoFalla: action.showModalNuevoTipoFalla, page: action.page, busqueda: action.busqueda };

          case SolicitudDeServiciosActions.NUEVO_TIPO_FALLA_REQUEST:
               return { ...state, fetchingNuevoTipoFalla: true };
          case SolicitudDeServiciosActions.NUEVO_TIPO_FALLA_SUCCESS:
               return { ...state, fetchingNuevoTipoFalla: false };
          case SolicitudDeServiciosActions.NUEVO_TIPO_FALLA_FAILURE:
               return { ...state, fetchingNuevoTipoFalla: false };

          case SolicitudDeServiciosActions.SHOW_MODAL_EDITAR_TIPO_FALLA:
               return { ...state, showModalEditarTipoFalla: action.showModalEditarTipoFalla, tipoFallaSeleccionado: action.tipoFallaSeleccionado, page: action.page, busqueda: action.busqueda };

          case SolicitudDeServiciosActions.EDITAR_TIPO_FALLA_REQUEST:
               return { ...state, fetchingEditarTipoFalla: true };
          case SolicitudDeServiciosActions.EDITAR_TIPO_FALLA_SUCCESS:
               return { ...state, fetchingEditarTipoFalla: false };
          case SolicitudDeServiciosActions.EDITAR_TIPO_FALLA_FAILURE:
               return { ...state, fetchingEditarTipoFalla: false };

          // CU - Reporteador Automático
          case SolicitudDeServiciosActions.SHOW_MODAL_CAMBIAR_HR:
               return { ...state, showModalCambiarHora: action.showModalCambiarHora };

          case SolicitudDeServiciosActions.LISTA_SS_DIARIOS_REQUEST:
               return { ...state, fetchingListaSSDiarios: true };
          case SolicitudDeServiciosActions.LISTA_SS_DIARIOS_SUCCESS:
               return { ...state, fetchingListaSSDiarios: false, listaSSDiarios: action.listaSSDiarios, listaSSDiariosCantidad: action.listaSSDiariosCantidad, reporterBoolean: action.reporterBoolean, page: action.page, fechaVerificar: action.fechaVerificar };
          case SolicitudDeServiciosActions.LISTA_SS_DIARIOS_FAILURE:
               return { ...state, fetchingListaSSDiarios: false };

          case SolicitudDeServiciosActions.VISUALIZAR_REPORTE_REQUEST:
               return { ...state, fetchingVisualizarReporte: true };
          case SolicitudDeServiciosActions.VISUALIZAR_REPORTE_SUCCESS:
               return { ...state, fetchingVisualizarReporte: false, fechaReporte: action.fechaReporte };
          case SolicitudDeServiciosActions.VISUALIZAR_REPORTE_FAILURE:
               return { ...state, fetchingVisualizarReporte: false };

          //REPORTE SOLICITUD SERVICIO
          case SolicitudDeServiciosActions.LISTA_REPORTE_BUSQUEDA_REQUEST:
               return { ...state, fetchingReporteBusqueda: true };
          case SolicitudDeServiciosActions.LISTA_REPORTE_BUSQUEDA_SUCCESS:
               return { ...state, fetchingReporteBusqueda: false, listaReporteBusqueda: action.listaReporteBusqueda, listaReporteBusquedaCantidad: action.listaReporteBusquedaCantidad };
          case SolicitudDeServiciosActions.LISTA_REPORTE_BUSQUEDA_FAILURE:
               return { ...state, fetchingReporteBusqueda: false };

          case SolicitudDeServiciosActions.EXPORTAR_EXCEL_REPORTE_BUSQUEDA_REQUEST:
               return { ...state, fetchingExportarReporteExcel: true };
          case SolicitudDeServiciosActions.EXPORTAR_EXCEL_REPORTE_BUSQUEDA_SUCCESS:
               return { ...state, fetchingExportarReporteExcel: false };
          case SolicitudDeServiciosActions.EXPORTAR_EXCEL_REPORTE_BUSQUEDA_FAILURE:
               return { ...state, fetchingExportarReporteExcel: false };

          case SolicitudDeServiciosActions.EXPORTAR_PDF_REPORTE_BUSQUEDA_REQUEST:
               return { ...state, fetchingExportarReportePDF: true };
          case SolicitudDeServiciosActions.EXPORTAR_PDF_REPORTE_BUSQUEDA_SUCCESS:
               return { ...state, fetchingExportarReportePDF: false };
          case SolicitudDeServiciosActions.EXPORTAR_PDF_REPORTE_BUSQUEDA_FAILURE:
               return { ...state, fetchingExportarReportePDF: false };

          case SolicitudDeServiciosActions.SHOW_MODAL_DETALLE_REPORTE:
               return { ...state, showModalDetalleReporte: action.showModalDetalleReporte, reporteSeleccionado: action.reporteSeleccionado };
               
          //Reporte Dinámico
          case SolicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_REQUEST:
               return { ...state, fetchingReporteDinamicoBusqueda: true, formularioRD: action.formulario};
          case SolicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_SUCCESS:
               return { ...state, fetchingReporteDinamicoBusqueda: false, listaReporteDinamicoBusqueda: action.listaReporteDinamicoBusqueda, listaReporteDinamicoBusquedaCantidad: action.listaReporteDinamicoBusquedaCantidad};
          case SolicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_FAILURE:
               return { ...state, fetchingReporteDinamicoBusqueda: false};
          
          case SolicitudDeServiciosActions.EXPORTAR_EXCEL_REPORTE_DINAMICO_REQUEST:
               return { ...state, fetchingExportarReporteDinamicoExcel: true};
          case SolicitudDeServiciosActions.EXPORTAR_EXCEL_REPORTE_DINAMICO_SUCCESS:
               return { ...state, fetchingExportarReporteDinamicoExcel: false};
          case SolicitudDeServiciosActions.EXPORTAR_EXCEL_REPORTE_DINAMICO_FAILURE:
               return { ...state, fetchingExportarReporteDinamicoExcel: false};

          case SolicitudDeServiciosActions.EXPORTAR_PDF_REPORTE_DINAMICO_REQUEST:
               return { ...state, fetchingExportarReporteDinamicoPDF: true};
          case SolicitudDeServiciosActions.EXPORTAR_PDF_REPORTE_DINAMICO_SUCCESS:
               return { ...state, fetchingExportarReporteDinamicoPDF: false};
          case SolicitudDeServiciosActions.EXPORTAR_PDF_REPORTE_DINAMICO_FAILURE:
               return { ...state, fetchingExportarReporteDinamicoPDF: false};

          case SolicitudDeServiciosActions.LIMPIAR_FORMULARIO_RD:
               return { ...state, formularioRD : action.formularioRD, listaReporteDinamicoBusqueda: action.listaReporteDinamicoBusqueda, listaReporteDinamicoBusquedaCantidad: action.listaReporteDinamicoBusquedaCantidad};

          case SolicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_GRUPO_REQUEST:
               return { ...state, fetchingListaReporteDinamicoGrupo: true};
          case SolicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_GRUPO_SUCCESS:
               return { ...state, fetchingListaReporteDinamicoGrupo: false, listaReporteDinamicoGrupo: action.listaReporteDinamicoGrupo, listaReporteDinamicoGrupoCantidad: action.listaReporteDinamicoGrupoCantidad};
          case SolicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_GRUPO_FAILURE:
               return { ...state, fetchingListaReporteDinamicoGrupo: false};


          case SolicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_WITHOUT_REQUEST:
               return { ...state, fetchingReporteDinamicoBusquedaWithout: true, formularioRD: action.formulario};
          case SolicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_WITHOUT_SUCCESS:
               return { ...state, fetchingReporteDinamicoBusquedaWithout: false, listaReporteDinamicoBusquedaWithout: action.listaReporteDinamicoBusquedaWithout, listaReporteDinamicoBusquedaCantidadWithout: action.listaReporteDinamicoBusquedaCantidadWithout};
          case SolicitudDeServiciosActions.LISTA_BUSQUEDA_REPORTE_DINAMICO_WITHOUT_FAILURE:
               return { ...state, fetchingReporteDinamicoBusquedaWithout: false};

          default:
               return state;
     }
}
