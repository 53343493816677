import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip, Checkbox } from 'antd';
import { EditOutlined, FileSearchOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoExpediente from './ModalNuevoExpediente';
import ModalEditarExpediente from './ModalEditarExpediente';
import ModalArchivoExpediente from './ModalArchivoExpediente';

const { Search } = Input;
const confirm = Modal.confirm;
class CatalogoDeExpediente extends React.Component {
    componentDidMount(){
        this.props.onGetExpedientes(1, '');
    }
    state={
        page: 1,
        busqueda: '',
        sinFiltros: false,
    }
    render() {
        const { fetchingListaExpedientesBusqueda, listaExpedientesBusqueda, onEstadoExpediente, 
            onGetExpedientes, onModalEditarExpediente, onModalNuevoExpediente, onModalArchivoExpediente,
            listaExpedientesBusquedaCantidad, onArchivosExpediente, onGetExpedientesSinInfo
        } = this.props;
        
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        const onBuscarExpediente = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            onGetExpedientes(1, busqueda);
        }
        const disabledField = ['EXP-PRO'];

        const columns = [{
                title: 'Clave',
                dataIndex: 'clave',
                key: 'clave',
                width: '10%',
            },{
                title: 'Descripción',
                dataIndex: 'descripcion',
                key: 'descripcion',
                width: '15%',
            },{
                title: 'Nombre carpeta',
                dataIndex: 'nombreCarpeta',
                key: 'nombreCarpeta',
                width: '15%',
            },{
                title: 'Ruta carpeta',
                dataIndex: 'rutaCarpeta',
                key: 'rutaCarpeta',
                width: '15%',
            },{
                title: 'Nombre de contacto',
                dataIndex: 'nombreProveedor',
                key: 'nombreProveedor',
                width: '12%',
            },{
                title: 'Fecha alta',
                dataIndex: 'fechaAlta',
                key: 'fechaAlta',
                width: '10%',
            },{
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                width: '5%',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.expedienteActivoInactivo || disabledField.includes(record.clave)} onChange={() => handleDesactivar(record)}/>
                ),
            },{
                title: 'Ver archivos',
                key: 'ver',
                width: '5%',
                render: (text, record) => (
                    <a className='editarIcono' disabled={disabledField.includes(record.clave)} onClick={() => onVerArchivo(record)}><FileSearchOutlined /></a>
                ),
            },{
                title: 'Editar',
                key: 'editar',
                width: '4%',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarExpediente || disabledField.includes(record.clave)} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },
        ];
        const onVerArchivo = (key) => {
            console.log(key);
            onArchivosExpediente(key.nombreCarpeta);
            onModalArchivoExpediente(key, this.state.page);
        }
        const onEditar = (key) => {
            onModalEditarExpediente(key, this.state.page);
        }
        const handleDesactivar = (key) => {
            console.log(key);
            const page = this.state.page;
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del expediente "'+ key.clave +'"?',
                content: <strong style={{color: '#ff7875'}}>Los proveedores con este expediente asignado se verán afectados.</strong>,
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoExpediente(key.idExpediente, page)
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            if(this.state.sinFiltros){
                onGetExpedientesSinInfo(pagination.current);
            }
            onGetExpedientes(pagination.current, this.state.busqueda);
		}
        const onNuevoExpediente = () => {
            onModalNuevoExpediente(this.state.page);
        }

        const onSinInfo = (value) => {
            if(value.target.checked === true){
                onGetExpedientesSinInfo(this.state.page);
            }
            else{
                this.props.onGetExpedientes(1, '');
            }
            this.setState({
                sinFiltros: value.target.checked,
            })
        }
        const scroll = {"x": 1500 | true, "y": 1000};
        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search disabled={this.state.sinFiltros} className='buscarModulos' placeholder="Ingrese el nombre del proveedor a filtrar" onSearch={value => onBuscarExpediente(value)} style={{ width: '100%' }}/>
                    </Col>
                    {/* <Col span={7}>
                        <Button onClick={onNuevoExpediente} disabled={!permisosUsuario.crearExpediente} className='nuevoUsuario'>Nuevo Expediente</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nuevo Expediente' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoExpediente} disabled={!permisosUsuario.crearExpediente} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={5}>
                        <br/>
                        <Tooltip title="Mostrar los registros que no cuentan con un proveedor">
                            <Checkbox defaultChecked={false} onChange={onSinInfo}>Sin información</Checkbox>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="clave" 
                            columns={columns}
                            dataSource={listaExpedientesBusqueda}
                            loading={fetchingListaExpedientesBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaExpedientesBusquedaCantidad, simple: true, current: this.state.page}}
                            scroll={scroll}
                        />         
                    </Col>
                </Row>
                <ModalNuevoExpediente/>
                <ModalEditarExpediente/>
                <ModalArchivoExpediente/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaExpedientesBusqueda: state.InformacionOperativaReducer.listaExpedientesBusqueda,
        fetchingListaExpedientesBusqueda: state.InformacionOperativaReducer.fetchingListaExpedientesBusqueda,
        listaExpedientesBusquedaCantidad: state.InformacionOperativaReducer.listaExpedientesBusquedaCantidad,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetExpedientes: (page, busqueda) => {
	    	dispatch({ type: "LISTA_EXPEDIENTES_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
        },
        onEstadoExpediente: (idExpediente, page) => {
	    	dispatch({ type: "ESTADO_EXPEDIENTE_REQUEST", idExpediente: idExpediente, page: page });
        },
        onModalNuevoExpediente: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_EXPEDIENTE", showModalNuevoExpediente: true, page: page });
        },
        onModalEditarExpediente: (expedienteSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_EXPEDIENTE", showModalEditarExpediente: true, expedienteSeleccionado: expedienteSeleccionado, page: page });
        },
        onModalArchivoExpediente: (expedienteSeleccionado, page) => {
            dispatch({ type: 'SHOW_MODAL_ARCHIVOS_EXPEDIENTE', showArchivosExpediente: true, expedienteSeleccionado: expedienteSeleccionado, page: page });
        },
        onArchivosExpediente: (nombreCarpeta) => {
            dispatch({ type: 'ARCHIVOS_EXPEDIENTE_REQUEST', nombreCarpeta: nombreCarpeta });
        },
        onGetExpedientesSinInfo: (page) => {
            dispatch({ type: 'LISTA_EXPEDIENTES_SIN_INFO_REQUEST', page: page });
        }
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoDeExpediente);