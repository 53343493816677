import React from 'react';
import { Card, Alert, Row, Col, List } from 'antd';
import { connect } from "react-redux";
import { ShrinkOutlined, ArrowsAltOutlined, ImportOutlined } from '@ant-design/icons';

import CatalogoDeFamiliasDeActivos from './CatalogoDeFamiliasDeActivos/CatalogoDeFamiliasDeActivos';
import CatalogoCentrosDeCostos from './CatalogoDeCentroDeCostos/CatalogoDeCentroDeCostos'
import CatalogoDeSubfamilias from './CatalogoDeSubfamilias/CatalogoDeSubfamilias';
import CatalogoDeUnidadesFuncionales from './CatalogoDeUnidadesFuncionales/CatalogoDeUnidadesFuncionales';
import CatalogoDeGruposDeServicio from './CatalogoDeGruposDeServicio/CatalogoDeGruposDeServicio';
import CatalogoDeServicios from './CatalogoDeServicios/CatalogoDeServicios';
import CatalogoAreasFuncionales from './CatalogosAreasFuncionales/CatalogoDeAreasFuncionales';

import CatalogoDeInterior from './CatalogosDeInterior/CatalogoDeInterior';
import CatalogoSubInterior from './CatalogoDeSubInterior/CatalogoSubInterior';
import CatalogoDeUbicaciones from './CatalogoDeUbicaciones/CatalogoDeUbicaciones';


import '../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import CatalogoDePonderaciones from './CatalogoDePonderaciones/CatalogoDePonderaciones';

import ClasificacionGerenciasContainer from '../EvaluacionDeLaGestion/ClasificacionDeGerencias/ClasificacionGerenciasContainer';

import imaSearch from '../../assets/undraw_searching_re_3ra9.svg';
import ReactVivus from "react-vivus";


function isEmpty(obj) {
    for(var prop in obj) {
        if(obj.hasOwnProperty(prop))
            return false;
    }
    return true;
}
class DashboardContainer extends React.Component {
    state={
        catalogo: 'Selecciona un Catálogo',
        colLista: 8,
        colTabla: 15,
        maximizar: false
    }
    render() {
        const { onChangeCatalogo, catalogoGeneral } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        const listaCatalogos = [];
        if(permisosUsuario.buscarAreaFuncional){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Áreas Funcionales',
            })
        }
        if(permisosUsuario.buscarPonderaciónDeAreaFuncional){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Ponderación de Áreas Funcionales',
            })
        }
        if(permisosUsuario.buscarUnidadFuncional){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Unidades Funcionales',
            })
        }
        if(permisosUsuario.buscarInteriorFuncional){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Interiores Funcionales',
            })
        }
        // if(permisosUsuario.buscarSubinteriorFuncional){
        //     listaCatalogos.push({
        //         'catalogo': 'Catálogo de SubInteriores Funcionales',
        //     })
        // }
        if(permisosUsuario.buscarUbicacion){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Ubicaciones',
            })
        }
        if(permisosUsuario.buscarGrupoDeServicio){
            listaCatalogos.push({
                'catalogo': 'Catálogo Grupo de Servicios',
            })
        }
        if(permisosUsuario.buscarServicio){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Servicios',
            })
        }
        if(permisosUsuario.buscarGerencia){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Gerencias',
            })
        }
        if(permisosUsuario.buscarCentroDeCostos){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Centro de Costos',
            })
        }
        if(permisosUsuario.buscarFamilia){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Familias',
            })
        }
        if(permisosUsuario.buscarSubfamilia){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Subfamilias',
            })
        }
        
        function ShowInfo(props) {
            if (isEmpty(catalogoGeneral)) {
                return (
                    <Row justify='space-around' align='middle'>
                        <Col span={24}>
                            <Alert message="Favor de seleccionar un catálogo" type="info" />
                        </Col>
                        <Col span={10} style={{paddingTop:'8em', paddingBottom:'8em'}}>
                            {/* <img src={imaSearch} /> */}
                            <ReactVivus
                                id="imaSearch"
                                option={{
                                    file: imaSearch,
                                    animTimingFunction: "EASE",
                                    type: "delayed",
                                }}
                            />
                        </Col>
                    </Row>);
            }
            
	        return (
                <div>
                    <CatalogoApp/>
                </div>
            );
        }

        function CatalogoApp() {
            if(catalogoGeneral === 'Catálogo de Áreas Funcionales'){
                return(
                    <div>
                         <CatalogoAreasFuncionales/> 
                    </div>
                );
            }else if(catalogoGeneral === 'Catálogo de Ponderación de Áreas Funcionales'){
                return(
                    <div>
                        <CatalogoDePonderaciones/>
                    </div>
                );
            }else if(catalogoGeneral === 'Catálogo de Unidades Funcionales'){
                return(
                    <div>
                        <CatalogoDeUnidadesFuncionales/>
                    </div>
                );
            }else if(catalogoGeneral === 'Catálogo de Interiores Funcionales'){
                return(
                    <div>
                        <CatalogoDeInterior/>
                    </div>
                );
            }
            // else if(catalogoGeneral === 'Catálogo de SubInteriores Funcionales'){
            //     return(
            //         <div>
            //             <CatalogoSubInterior/>
            //         </div>
            //     );
            // }
            else if(catalogoGeneral === 'Catálogo de Ubicaciones'){
                return(
                    <div>
                        <CatalogoDeUbicaciones/>
                    </div>
                );
            }else if(catalogoGeneral === 'Catálogo Grupo de Servicios'){
                return(
                    <div>
                        <CatalogoDeGruposDeServicio/>
                    </div>
                );
            }else if(catalogoGeneral === 'Catálogo de Servicios'){
                return(
                    <div>
                        <CatalogoDeServicios/>
                    </div>
                );
            }else if(catalogoGeneral === 'Catálogo de Gerencias'){
                return(
                    <div>
                        <ClasificacionGerenciasContainer/>
                    </div>
                );
            }else if(catalogoGeneral === 'Catálogo de Centro de Costos') {
                return(
                    <div>
                        <CatalogoCentrosDeCostos/>
                    </div>
                );
            }else if(catalogoGeneral === 'Catálogo de Familias'){
                return(
                    <div>
                        <CatalogoDeFamiliasDeActivos/>
                    </div>
                );
            }else if(catalogoGeneral === 'Catálogo de Subfamilias'){
                return(
                    <div>
                        <CatalogoDeSubfamilias/>
                    </div>
                );
            }
			return <div>  </div>;
        };
        
        const changeCatalogo = (catalogo) => {
            this.setState({
                catalogo: catalogo.catalogo,
            })
            onChangeCatalogo(catalogo.catalogo);
        }

        const onExpandir = () => {
            if(!this.state.maximizar){
                this.setState({
                    colLista: 0,
                    colTabla: 24,
                    maximizar: true
                });
            }
            else{
                this.setState({
                    colLista: 8,
                    colTabla: 15,
                    maximizar: false,
                });
            }
        }
        return (
            <div className="imgCorp">
                <Row justify='space-between'>
                    <Col span={this.state.colLista}>
                        <List
                            header={
                                <div className='headerLista'>Catálogos Generales</div>
                            }
                            className="listaCatalogos"
                            bordered
                            dataSource={listaCatalogos}
                            renderItem={item => (
                            <List.Item className='listaHover'
                                actions={[<ImportOutlined onClick={(e) => { e.stopPropagation(); changeCatalogo(item); } } className="iconAbrir" />]}
                            >
                                <div>{item.catalogo}</div>
                            </List.Item>
                            )}
                        />                    
                    </Col>
                    <Col span={this.state.colTabla}>
                        <Card className="cardCatalogos" title={<div className='headerLista'>{this.state.catalogo}</div>} extra={this.state.maximizar ? (<ShrinkOutlined className='iconoMaxMin' onClick={onExpandir} />):(<ArrowsAltOutlined className='iconoMaxMin' onClick={onExpandir}/>)}>
                            <ShowInfo info={catalogoGeneral} />
                        </Card> 
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        vistaDashboard: state.ConfiguracionYSeguridadReducer.vistaDashboard,
        catalogoGeneral: state.CatalogosGeneralesReducer.catalogoGeneral,
    };
};
const mapDispatchToProps = dispatch => {
	return {
	  	onRequestLogin: (loginInfo) => {
	    	dispatch({ type: "LOGIN_API_CALL_REQUEST", loginInfo:loginInfo });
        },
        onChangeCatalogo: (catalogoGeneral) => {
            dispatch({ type: "CAMBIAR_CATALOGO_GENERAL" , catalogoGeneral: catalogoGeneral});
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);