import React, { Component } from 'react';
import {Modal, Row, Col, Table, Button , message, Spin} from 'antd'
import { EditOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

import ModalModificarProducto from './ModalModificarProducto'


class ModalVerificacion extends Component {
    state = { page: 1 }
    
    render() { 
        const {
            showModalFolioVC,
            closeModalVerificacion,
            folioEVDCseleccionado,
            listaFolioVC,
            listaFolioVCCalidad,
            page,
            busqueda,
            fetchingListaFVerificacionCalidad,
            onShowModalModificar,
            onGenerarIngreso,
        } = this.props;

        const handleCancel = () => {
            closeModalVerificacion();
        };

        const columns = folioEVDCseleccionado ? 
        [{
            title: 'Código de almacén',
            dataIndex: 'codigoAdicional',
            key: 'codigoAdicional',
            align: 'center',
        },{
            title: 'Producto',
            dataIndex: 'producto',
            key: 'producto',
            align: 'center',
        },{
            title: 'Clave producto',
            dataIndex: 'clave',
            key: 'clave',
            align: 'center',
        },{
            title: 'Número de serie',
            dataIndex: 'serieNum',
            key: 'serieNum',
            align: 'center',
            render: (text, record) => (
                record.serieNum ? text : 'No aplica'
            ),
        },{
            title: 'Tipo moneda',
            dataIndex: 'tipoMoneda',
            key: 'tipoMoneda',
            align: 'center',
        },{
            title: 'Precio unitario',
            dataIndex: 'costoOriginalTabla',
            key: 'costoOriginalTabla',
            align: 'right',
        },{
            title: 'Unidad medida',
            dataIndex: 'unidadMedida',
            key: 'unidadMedida',
            align: 'center',
        },{
            title: 'Ubicación',
            dataIndex: 'ubicacion',
            key: 'ubicacion',
            align: 'center',
        }, (folioEVDCseleccionado.estado === "Cerrado" ||  folioEVDCseleccionado.estado === "Abierto") ?  {
            title: 'Cantidad aceptada',
            dataIndex: 'cantidad',
            key: 'cantidad',
            align: 'center',
        }: {},{
            title: 'Cantidad rechazada',
            dataIndex: 'cantidadRechazada',
            key: 'cantidadRechazada',
            align: 'center',
        },{
            title: 'Motivo de rechazo',
            dataIndex: 'motivoRechazo',
            key: 'motivoRechazo',
            align: 'center',
        },folioEVDCseleccionado.estado === "Abierto" ? {
            title: 'Modificar',
            key: '',
            align: 'center',
            render: (record) => (
               <a className='editarIcono' onClick={ () => modificarRegistro(record) }> < EditOutlined />
               </a>
            ),          
        }: {}]: []

        const modificarRegistro = (key) =>{
            console.log(key);
            onShowModalModificar(key);
        }

        const handleTableChange = (pagination) => {
            console.log(pagination)
            this.props.onGetDetalleFolio(folioEVDCseleccionado.folio, pagination.current);
            this.setState({page: pagination.current});
        }

        const onRegistrarIngreso = () => {
            console.log(listaFolioVC)
            onGenerarIngreso(listaFolioVC, page, busqueda, folioEVDCseleccionado);
        }

        

        return ( 
            <div>
            {folioEVDCseleccionado ? (
                <>
                <Modal
                    visible={showModalFolioVC}
                    title={`Folio: ${folioEVDCseleccionado.folio} Estatus: ${folioEVDCseleccionado.folio.split('-')[0] === 'EVDC' && folioEVDCseleccionado.estado === 'Abierto' ? 'Entrada sin Verificacón' : folioEVDCseleccionado.folio.split('-')[0] === 'SVDC' ? 'Devolucion de productos' : 'Entrada de Verificación'} `}
                    onCancel={handleCancel}
                    footer={false}
                    width={'85%'}
                >
                    <Spin spinning={this.props.fetchingFolioVC}>
                        <Row justify='space-between'>
                            <Col span={24}>
                            <br />
                                <Table
                                    size="small"
                                    rowKey="idIngresoAlmacenCalidad"
                                    columns={columns}
                                    dataSource={listaFolioVC}
                                    loading={fetchingListaFVerificacionCalidad}
                                    locale={{ emptyText: 'Sin datos de productos' }}
                                    onChange={handleTableChange}
                                    pagination={{ total: listaFolioVCCalidad, simple: true, current: this.state.page  }}
                                />
                            </Col>
                            <Col span={11}>
                            </Col>
                            {console.log('folioEVDCseleccionado', folioEVDCseleccionado)}
                            {folioEVDCseleccionado.estado === "Abierto" ? (
                                <>
                                <Col span={10}>
                                    <Button onClick={onRegistrarIngreso} className='nuevoUsuario' >Guardar Cambios</Button>
                                </Col>
                                </>
                            ): false}
                            
                        </Row>
                    </Spin>
                </Modal>
                <ModalModificarProducto />
                </>
            ): false}
                
            </div>
         );
    }
}

const mapStateToProps = state => {
    return {
        showModalFolioVC: state.AlmacenesReducer.showModalFolioVC,
        folioEVDCseleccionado: state.AlmacenesReducer.folioEVDCseleccionado,
        listaFolioVC: state.AlmacenesReducer.listaFolioVC,
        listaFolioVCCalidad: state.AlmacenesReducer.listaFolioVCCalidad,
        fetchingListaFVerificacionCalidad: state.AlmacenesReducer.fetchingListaFVerificacionCalidad,
        page: state.AlmacenesReducer.page,
        busqueda: state.AlmacenesReducer.busqueda,
        fetchingFolioVC: state.AlmacenesReducer.fetchingFolioVC,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        closeModalVerificacion: () => {
            dispatch({ type: 'SHOW_MODAL_VERIFICARFOLIO_CAL', showModalFolioVC: false })
        },
        onShowModalModificar: ( productoSeleccionadoModificar ) => {
            dispatch({ type: 'SHOW_MODAL_MODIFICAR_PRODUCTO', showModalModificarProducto: true ,productoSeleccionadoModificar })
        },
        onGenerarIngreso: (listaFolioVC, page, busqueda, folioEVDCseleccionado) => {
            dispatch({ type: 'FOLIO_VERIFICACION_CALIDAD_REQUEST', listaFolioVC, page, busqueda, folioEVDCseleccionado})
        },
        onGetDetalleFolio: (folio, page) => {
            dispatch({ type: 'DETALLE_FOLIO_VERIFICACION_CALIDAD_REQUEST', folio, page})
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalVerificacion)