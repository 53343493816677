import React from 'react';
import { Form, Card, Spin, Row, Col, Select, Result, Button, Descriptions, Tooltip, DatePicker, message, Table, List, Modal, Typography, Empty } from 'antd';
import { FileExcelOutlined, EyeOutlined, SelectOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import DetalleCalculo from './DetalleCalculo';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import moment from 'moment';

import imaSearch from '../../../assets/undraw_discount_d-4-bd.svg';
import ReactVivus from "react-vivus";

const { Option } = Select;
const { Text } = Typography;
const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 }};
class CalcularDeductivasContainer extends React.Component {
     formRef = React.createRef();
     componentDidMount(){
          this.props.onGetListaMesesAbiertos();
     }

     state={
         visible: false, mesSeleccionado: [], visibleDatePicker: false, ultimoDiaMes: '', disabledButtons: true, fechaProyeccionST: '', proyeccion: null, cierreMes: true, proyeccionDesc: false, ultimoDiaAllPPLs: false, tipoCalculo: null, optSI: false, visibleDetalleTickets: false, page: 1, mesAnterior: null,
     }

     handleCancel = () => { this.setState({ visibleDetalleTickets: false }) };

     render() {
          const {
               showModalDetalle, listaMesesAbiertos, fetchingListaMesesAbiertos, onGetValorCalculo, onGetListaResumenServicio, fetchingListaResumenServicio, listaResumenServicio,fetchingDeductivaResumenExcel, onExportarResumenDeductiva, onGetDetelle, valorCalculoTipoDeductiva, fetchingValorCalculoTipoDeductiva
          } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
             permisosUsuario = JSON.parse(permisosUsuario);
          }

          const onShowModalDetalle = () => {          
               showModalDetalle(this.state.fechaProyeccionST, this.state.mesSeleccionado.idMesCalendario, this.state.proyeccion, this.state.cierreMes, this.state.mesSeleccionado.procesable, this.state.ultimoDiaAllPPLs, this.state.mesSeleccionado, this.state.mesAnterior);
               onGetDetelle(this.state.fechaProyeccionST, this.state.mesSeleccionado.idMesCalendario, this.state.proyeccion);
          };

          const onMesSelected = (e) => { 
               if(e){
                    let anio = '';
                    let mes = '';
                    let idMesLocal = parseInt(e.split('-')[0]);

                    // console.log('listaMesesAbiertos', listaMesesAbiertos)

                    for(let i = 0; i < listaMesesAbiertos.length; i++){
                         if(listaMesesAbiertos[i].idMesCalendario === idMesLocal){
                              this.setState({mesSeleccionado: listaMesesAbiertos[i]});
                              anio = parseInt(listaMesesAbiertos[i].anioCalendario);
                              mes = parseInt(listaMesesAbiertos[i].idMes);

                              if((listaMesesAbiertos[i+1])){
                                   this.setState({mesAnterior: listaMesesAbiertos[i+1]});
                              } else {
                                   this.setState({mesAnterior: null});
                              }

                              onGetValorCalculo(null , listaMesesAbiertos[i].idMesCalendario);
                         }
                         
                    }

                    let dias = new Date(anio, mes, 0).getDate();

                    let fecha = new Date(anio, mes -1, dias);
                    fecha.setDate(fecha.getDate() + 1);

                    this.setState({ visible: true, visibleDatePicker: false, ultimoDiaMes: fecha, disabledButtons: true, proyeccionDesc: false, proyeccion: null, ultimoDiaAllPPLs: false, optSI: false });
                    this.formRef.current.resetFields(['tipoCalculo']);
                    this.props.onGetTicketsNoConcilidados(idMesLocal, this.state.page);
               }
          };

          let data=[];
          if (valorCalculoTipoDeductiva){
               const { dma, dmi, inpc, pmi, pmr, pri, pti, pvi, cantidadPplCalendario } = valorCalculoTipoDeductiva;
               let inpcFx = null; if(inpc){inpcFx = inpc.toFixed(2);}

               function numberWithCommas(x) {
                    let parts = x.toString().split(".");
                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return parts.join(".");
               }

               let priFx = '0'; if(pri){priFx = numberWithCommas(pri.toFixed(0))}
               let pmiFx = '0.00'; if(pmi){pmiFx = numberWithCommas(pmi.toFixed(2))}
               let pmrFx = '0.00'; if(pmr){pmrFx = numberWithCommas(pmr.toFixed(2))}
               let ptiFx = '0.00'; if(pti){ptiFx = numberWithCommas(parseInt(pti).toFixed(2))}
               let pviFx = '0'; if(pvi){pviFx = numberWithCommas(pvi.toFixed(2))}
               let dmaFx = '0'; if(dma){dmaFx = numberWithCommas(dma.toFixed(2))}
               
               data = [
                    {label: <b><Tooltip title="Número de días del mes i"><a className='naranja'>DMI</a></Tooltip></b>, data: dmi},
                    // {label: <b>PPL capturados</b>, data: cantidadPplCalendario},
                    {label: <b><Tooltip title="Índice Nacional de Precios al Consumidor"><a className='naranja'>INPC i-1</a></Tooltip></b>, data: inpc},
                    {label: <b><Tooltip title="Suma de los PPL"><a className='pri'>PRI</a></Tooltip></b>, data: priFx},
                    {label: <b><Tooltip title="Promedio diario de los PPLs en el mes"><a className='naranja'>Pti</a></Tooltip></b>, data: ptiFx},
                    {label: <b><Tooltip title="Diferencia entre PPLs mínimos y número de PPLs promedio en el mes"><a className='naranja'>Pvi</a></Tooltip></b>, data: pviFx},
                    { label: <b><Tooltip title='Pago Mensual de Inversión'><a className='naranja'>PMI</a></Tooltip></b>, data: pmiFx, align: 'center' },
                    { label: <b><Tooltip title='Pago Mensual de Referencia'><a className='naranja'>PMR</a></Tooltip></b>, data: pmrFx, align: 'center' },
                    { label: <b><Tooltip title='Deducción Máxima Aplicable'><a className='naranja'>DMA</a></Tooltip></b>, data: dmaFx, align: 'center' },
               ];
          }

          const openNotificationWithIcon = (fecha) => {
               message.info({
                    content: 'Fecha tomada para el cálculo: ' + fecha.split("-").reverse().join("-"),
               });
          };

          const onTipoCalculo = (value) => {
               console.log('this.state.mesSeleccionado', this.state.mesSeleccionado)
               this.setState({ tipoCalculo: parseInt(value), optSI: false });
               if(value === '2'){
                    //PROYECCIÓN A FUTURO
                    this.setState({ visibleDatePicker: true, disabledButtons: true, cierreMes: true, proyeccionDesc: true, ultimoDiaAllPPLs: false });
                    onGetValorCalculo('futuro' ,this.state.mesSeleccionado.idMesCalendario);

                    message.info('Valores base recalculados de manera temporal');
               } else {
                    //AL ÚLTIMO DÍA PPL CAPTURADO
                    //CIERRE DE MES
                    let annio = this.state.mesSeleccionado.anioCalendario;
                    let mes = this.state.mesSeleccionado.idMes;
                    let dia = this.state.mesSeleccionado.pplCalendariosByIdMesCalendario[0].diaMes;

                    if(mes < 10) { mes = '0' + mes; }
                    if(this.state.mesSeleccionado.pplCalendariosByIdMesCalendario[0].diaMes < 10){ dia = '0' + dia}

                    let fecha = annio + '-' + mes + '-' + dia;

                    this.setState({ visibleDatePicker: false, disabledButtons: false, fechaProyeccionST: fecha, cierreMes: true, proyeccionDesc: false});
                    
                    if(value === '3'){
                         // CÁLCULO PARA CIERRE DE MES
                         this.setState({ cierreMes: false, ultimoDiaAllPPLs: false, proyeccion: null });
                         onGetListaResumenServicio(fecha, this.state.mesSeleccionado.idMesCalendario, null, this.state.mesSeleccionado.mesCalendario);
                         if(this.props.listaTicketsNoConciliados.cantidad === 0){ openNotificationWithIcon(fecha); }

                         onGetValorCalculo(null ,this.state.mesSeleccionado.idMesCalendario);
                    }
                    if(value === '1'){
                         onGetValorCalculo('ultimo' ,this.state.mesSeleccionado.idMesCalendario);
                         onGetListaResumenServicio(fecha, this.state.mesSeleccionado.idMesCalendario, 'ultimo', this.state.mesSeleccionado.mesCalendario);
                         openNotificationWithIcon(fecha);
                         this.setState({ proyeccion: 'ultimo', ultimoDiaAllPPLs: false });

                         if(this.state.mesSeleccionado.cantidadPplCalendario === this.state.mesSeleccionado.dmi){
                              console.log('Entra al if ultimo día con todos los PPLs')
                              this.setState({ ultimoDiaAllPPLs: true });
                         }
                    }
               }
          };

          const disabledDate = (current) => {
               return current && current >= moment(this.state.ultimoDiaMes, "YYYY-MM-DD");
               //return current && moment(current.format('YYYY-MM-DD')) >= moment(this.state.ultimoDiaMes);
          };

          const onValidarDate = (date) => {
               let diaM = date._d.getDate();
               let mesM = date._d.getMonth();
               let anioM = date._d.getFullYear();

               let mes = this.state.mesSeleccionado.idMes;
               if(mes < 10) { mes = '0' + mes; }
               let dia = this.state.mesSeleccionado.pplCalendariosByIdMesCalendario[0].diaMes;
               if(this.state.mesSeleccionado.pplCalendariosByIdMesCalendario[0].diaMes < 10){ dia = '0' + dia}

               if(this.state.mesSeleccionado.pplCalendariosByIdMesCalendario[0]){
                    if(anioM >= parseInt(this.state.mesSeleccionado.anioCalendario)){
                         if((mesM + 1) >= this.state.mesSeleccionado.idMes){
                              if(diaM <= this.state.mesSeleccionado.pplCalendariosByIdMesCalendario[0].diaMes){
                                   message.warning( <p>El día seleccionado debe ser mayor a la fecha del último día de ppl capturado <b>({dia + '-' + mes + '-' +  this.state.mesSeleccionado.anioCalendario})</b> </p> );
                                   this.formRef.current.resetFields(['fechaProyeccion']);
                              } else {
                                   // AQUI MERO
                                   let fecha = date.format('YYYY-MM-DD');
                                   onGetListaResumenServicio(fecha, this.state.mesSeleccionado.idMesCalendario, 'futuro', this.state.mesSeleccionado.mesCalendario);

                                   this.setState({ disabledButtons: false, fechaProyeccionST: fecha, proyeccion: 'futuro' });

                                   openNotificationWithIcon(fecha);
                              }
                         } else {
                              message.warning( <p>El día seleccionado debe ser mayor a la fecha del último día de ppl capturado <b>({dia + '-' + mes + '-' +  this.state.mesSeleccionado.anioCalendario})</b></p> );
                              this.formRef.current.resetFields(['fechaProyeccion']);   
                         }
                    } else {
                         message.warning( <p>El día seleccionado debe ser mayor a la fecha del último día de ppl capturado <b>({dia + '-' + mes + '-' +  this.state.mesSeleccionado.anioCalendario})</b></p> );
                         this.formRef.current.resetFields(['fechaProyeccion']); 
                    }
               }
          };

          const handleTableChange = (pagination) => {  };

          const onDescarga = () => {
               onExportarResumenDeductiva(this.state.fechaProyeccionST, this.state.mesSeleccionado.idMesCalendario, this.state.proyeccion);
          };

          
          const columns = [
               { title: <b><Tooltip title='Nombre del servicio al que están referenciadas las fallas'>Servicio</Tooltip></b>, dataIndex: 'servicio', key: 'servicio', align: 'center', width:'40%'  },

               { title: <b><Tooltip title='Cantidad de tickets procesados por servicio'>Cantidad de tickets</Tooltip></b>, dataIndex: 'foliosByServicio', key: 'foliosByServicio',  align: 'right', width:'7%' },

               { title: <b><Tooltip title='Suma acumulada de deductivas según las agrupaciones anteriores'>Total</Tooltip></b>, dataIndex: 'cantidadTabla', key: 'cantidadTabla', align: 'right' },
          ];

          let dataList = listaResumenServicio;
          const getSummaryData = () => {
               let totalBorrow = 0; let totalCantidad = 0;
               let key = dataList.length;
               key = key.toString();

               let tipoFalla = '';
               let listTiposFalla = [];
               for(let j in listaResumenServicio[0].graficaResponseList){
                    tipoFalla = listaResumenServicio[0].graficaResponseList[j].etiqueta.replace(/\s+/g, '');
                    listTiposFalla.push(tipoFalla);
               }

               dataList.forEach(({ foliosByServicio, deductivaAcumulada }) => {
                    totalBorrow += foliosByServicio;
                    totalCantidad += deductivaAcumulada;
               });

               let totalCantidadTabla = totalCantidad.toLocaleString('en-US', {'maximumFractionDigits': 2});

               if(!totalCantidadTabla.includes('.')){ totalCantidadTabla = totalCantidadTabla + '.00'; }

               dataList = [
                    {
                         idResumenServicio: key,
                         // servicio: <b style={{fontSize:14, color:"#0e2c82"}}>TOTAL</b>,
                         foliosByServicio: <b style={{fontSize:14, color:"#0e2c82"}}>TOTAL</b>,
                         cantidadTabla: <b style={{fontSize:14, color:"#0e2c82"}}>{totalCantidadTabla}</b>,
                    },
                    ...dataList
               ];
               dataList.sort(((a, b) => a.idResumenServicio - b.idResumenServicio));

          }; if(listaResumenServicio.length >= 1){getSummaryData();}

          const optNO = () => {
               this.formRef.current.resetFields(['tipoCalculo']);
               this.setState({ visible: true, visibleDatePicker: false, disabledButtons: true, proyeccionDesc: false, proyeccion: null, ultimoDiaAllPPLs: false, optSI: false  });
          };

          const optSI = () => {
               this.setState({ optSI: true });
               openNotificationWithIcon(this.state.fechaProyeccionST)
          };

          const onShowModalDetalleTickets = () => { this.setState({ visibleDetalleTickets: true }); };

          const listChangePage = (pagination) => {
               console.log('pagination', pagination);
               this.setState({ page: pagination.current });
               this.props.onGetTicketsNoConcilidados(this.state.mesSeleccionado.idMesCalendario, pagination.current);
          };

          return (
          <Form name="formulario" ref={this.formRef} >
               <Row >
                    <Col span={5} style={{backgroundColor: '#737373', borderTopLeftRadius:'1em'}}>
                         <Row justify='space-around' gutter={[0,0]} align='middle'>
                              <Col span={22} style={{ alignItems:'center', alignContent:'space-around', paddingTop: '1em' }}>
                                   <Tooltip title='Selecciona el mes a calcular'>
                                        <Form.Item name='mes-i' >
                                             <Select placeholder='Seleccione el mes a calcular' loading={fetchingListaMesesAbiertos} onChange={(e) => onMesSelected(e)} >
                                                  {listaMesesAbiertos.map(option => <Option key={option.idMesCalendario + '-' + option.idAnioCalendario}>{option.mesCalendario + ' ' + option.anioCalendario}</Option>)}
                                             </Select>
                                        </Form.Item>
                                   </Tooltip>
                              </Col>
                         </Row>
                    </Col>

                    <Col span={19} style={{backgroundColor: '#F2F2F2', borderTopRightRadius:'1em' }}>
                         <Row type="flex" justify='start' align='middle' gutter={[0,0]}>
                              <Col span={24} style={{backgroundColor: '#E4E4E4', paddingTop: '1em', paddingBottom: '1em', paddingLeft: '1em', borderTopRightRadius:'1em'}}>
                                   <div style={{ textAlign:'left' }}>
                                        <Text style={{textAlign: 'left', fontSize: '2em', color: '#737373', paddingRight: '.5em'}} strong> Cálculo de Deductivas </Text>
                                   </div>
                              </Col>
                         </Row>
                    </Col>

                    <Col span={24} style={{backgroundColor: '#F2F2F2', padding: '1em'}}>
                         { this.state.visible ? (<div className='entrenceElement'>
                              <Row justify='space-around'>
                                   <Col span={24}>
                                        <Spin spinning={fetchingValorCalculoTipoDeductiva}>
                                             <Card className="cardCatalogos" style={{background:'#F2F2F2'}} bordered={true} title={ 
                                                  <Row justify='space-between' align='middle' gutter={[8,8]}>
                                                       <Col span={10}>
                                                            <Row>
                                                                 <Col span={23} style={{fontSize:25, textAlign:"left", color:"#e64d14"}}>     
                                                                      {this.state.mesSeleccionado.mesCalendario + ' ' + this.state.mesSeleccionado.anioCalendario}
                                                                 </Col>
                                                                 <Col span={23} style={{fontSize:18, textAlign:"left", color:"#ff8c62"}}>     
                                                                      Datos del mes:
                                                                 </Col>
                                                            </Row>
                                                       </Col>

                                                       <Col span ={14}>
                                                            <Row justify='end'>
                                                                 <Col span={11}>
                                                                      <Form.Item name='tipoCalculo' >
                                                                           <br/> 
                                                                           { this.state.mesSeleccionado.cantidadPplCalendario < this.state.mesSeleccionado.dmi ? (<>
                                                                                <Select
                                                                                     placeholder='Tipo de Cálculo de Deductivas'
                                                                                     onChange={onTipoCalculo}
                                                                                     disabled={!permisosUsuario.calcularDeductivas} 
                                                                                >
                                                                                     
                                                                                     <Option key='1'><Tooltip title='Calcular a la fecha del último día de PPL capturado'>
                                                                                          Calcular a la fecha del último día de PPL capturado
                                                                                     </Tooltip></Option>
                                                                                     
                                                                                     {/* Validar, si es el primer mes de inicio a operación no se deberá realizar una proyección */}
                                                                                     <Option key='2'><Tooltip title='Proyección a futuro'>
                                                                                          Proyección a futuro
                                                                                     </Tooltip></Option>
                                                                                     
                                                                                </Select>
                                                                           </>):(<>
                                                                                <Select
                                                                                     placeholder='Tipo de Cálculo de Deductivas'
                                                                                     onChange={onTipoCalculo}
                                                                                     disabled={!permisosUsuario.calcularDeductivas} 
                                                                                >
                                                                                     <Option key='1'><Tooltip title='Calcular a la fecha del último día de PPL capturado'>
                                                                                          Calcular a la fecha del último día de PPL capturado
                                                                                     </Tooltip></Option>
                                                                                     <Option key='3'><Tooltip title='Cálculo de deductivas para cierre mensual'>
                                                                                          Cálculo de deductivas para cierre mensual
                                                                                     </Tooltip></Option>
                                                                                     
                                                                                </Select>
                                                                           </>)}
                                                                      </Form.Item>
                                                                 </Col>
                                                                 
                                                                 { this.state.visibleDatePicker ? (<>
                                                                      <Col span={1}></Col>
                                                                      <Col span={11}>
                                                                           <Form.Item
                                                                                name='fechaProyeccion'
                                                                           >    <br />
                                                                                <DatePicker 
                                                                                     bordered={true}
                                                                                     placeholder='Fecha de proyección'
                                                                                     style={{ width: '100%' }}
                                                                                     disabledDate={disabledDate}
                                                                                     showToday={false}
                                                                                     onChange={onValidarDate}
                                                                                     format='DD-MM-YYYY'
                                                                                />
                                                                           </Form.Item>
                                                                      </Col>
                                                                 </>):false }
                                                            </Row>
                                                       </Col>
                                                  </Row> 
                                                  } >
                                                  <Descriptions layout="horizontal" size='small' bordered={true} className='DescriptionDeductiva' column={{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 4, xs: 4 }}>
                                                       {data.map(item => 
                                                            <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                       )}
                                                  </Descriptions>
                                             </Card>
                                        </Spin>
                                   </Col>
                              </Row>     
                         </div>):(  
                              // <Row justify='space-around' align='middle' style={{height:'35em'}}>
                              //      <Result  icon={<SelectOutlined />} subTitle="Selecciona un mes para continuar." />
                              // </Row>
                              <Row justify='center' justify='space-around' align='middle' style={{height:'35em'}}>
                                   <Col span={8} style={{paddingTop:'8em', paddingBottom:'8em'}}>
                                   {/* <img src={imaSearch} /> */}
                                        <ReactVivus
                                             id="imaSearch"
                                             option={{
                                                  file: imaSearch,
                                                  animTimingFunction: "EASE",
                                                  type: "delayed",
                                             }}
                                        />
                                        <Text type='secondary'>
                                             Selecciona un mes para continuar.
                                        </Text>
                                   </Col>
                              </Row>
                         ) }

                         { !this.state.disabledButtons ? ( <>  
                              <Row justify='space-around' className='entrenceElement'>
                                   <Col span={24}> <br/>
                                        { this.state.tipoCalculo !== 3 ? (
                                        // Caso: Calcular a la fecha del último día de PPL capturado
                                        <Card className="cardCatalogos" style={{background:'#F2F2F2'}} bordered={true} title={ 
                                        <Row justify='space-around'>
                                             <Col span={22} style={{fontSize:18, textAlign:"left", color:"#1747ca"}}>     
                                                  Reporte en Resumen del Cálculo de Deductivas:
                                             </Col>
                                             <Col span={1}>
                                                  <Tooltip title='Exportar a Excel'>
                                                       <Button className="iconSecundary" shape='circle' disabled={this.props.listaResumenServicioCantidad <= 0} icon={<FileExcelOutlined /> } loading={fetchingDeductivaResumenExcel} onClick={onDescarga}/>
                                                  </Tooltip>
                                             </Col>

                                             <Col span={1}>
                                                  <Tooltip title='Ver detalle'>
                                                       <Button className="iconTer" shape="circle" disabled={!permisosUsuario.detalleDeDeductivas ? true : this.state.disabledButtons} icon={<EyeOutlined />} onClick={onShowModalDetalle}/>
                                                  </Tooltip>
                                             </Col>
                                        </Row> } >
                                             { this.props.listaResumenServicioCantidad > 0 ? (
                                                  <Table
                                                       size="small"
                                                       rowKey="idResumenServicio"
                                                       columns={listaResumenServicio.columns}
                                                       dataSource={dataList}
                                                       loading={fetchingListaResumenServicio}
                                                       onChange={handleTableChange}
                                                       locale={{ emptyText: 'Sin datos' }}
                                                       scroll={{ x: 4000 }}
                                                       pagination={false}
                                                  />
                                             ):(
                                                  <Spin spinning={this.props.fetchingListaResumenServicio}>
                                                       <Empty description='Sin datos' />
                                                  </Spin>
                                             ) }
                                        </Card>
                                        ):(  this.props.listaTicketsNoConciliados.cantidad > 0 ? (<>
                                             { this.state.optSI ? (
                                                  <Card className="cardCatalogos" style={{background:'#F2F2F2'}} bordered={true} title={ 
                                                       <Row justify='space-around'>
                                                            <Col span={22} style={{fontSize:18, textAlign:"left", color:"#1747ca"}}>     
                                                                 Reporte en Resumen del Cálculo de Deductivas:
                                                            </Col>
                                                            <Col span={1}>
                                                                 <Tooltip title='Exportar a Excel'>
                                                                      <Button className="iconSecundary" shape='circle' disabled={!permisosUsuario.exportarExcelDeductivas ? true : this.state.disabledButtons} icon={<FileExcelOutlined /> } loading={fetchingDeductivaResumenExcel} onClick={onDescarga}/>
                                                                 </Tooltip>
                                                            </Col>
          
                                                            <Col span={1}>
                                                                 <Tooltip title='Ver detalle'>
                                                                      <Button className="iconTer" shape="circle" disabled={this.state.disabledButtons} icon={<EyeOutlined />} onClick={onShowModalDetalle}/>
                                                                 </Tooltip>
                                                            </Col>
                                                       </Row> } >
                                                            { console.log('SEGUNDO CASO') }
                                                            <Table
                                                                 size="small"
                                                                 rowKey="idResumenServicio"
                                                                 columns={listaResumenServicio.columns}
                                                                 dataSource={dataList}
                                                                 loading={fetchingListaResumenServicio}
                                                                 onChange={handleTableChange}
                                                                 locale={{ emptyText: 'Sin datos' }}
                                                                 scroll={{ x: 4000 }}
                                                                 pagination={false}
                                                            />
                                                  </Card>
                                             ):(
                                                  <Card className="cardCatalogos" style={{background:'#F2F2F2'}} bordered={true} title={ 
                                                       <Row justify='space-around'>
                                                            <Col span={23} style={{fontSize:18, textAlign:"left", color:"#ff8c62"}}>
                                                            </Col>
          
                                                            <Col span={1}>
                                                                 <Tooltip title='Ver detalle'>
                                                                      <Button className="iconTer" shape="circle" icon={<EyeOutlined />} onClick={onShowModalDetalleTickets}/>
                                                                 </Tooltip>
                                                            </Col>
                                                       </Row> } >
                                                            <Result
                                                            title={ this.props.listaTicketsNoConciliados.cantidad > 1 ? (<p>Existen <b>{this.props.listaTicketsNoConciliados.cantidad}</b> tickets no conciliados</p>) : (<p>Existe <b>{this.props.listaTicketsNoConciliados.cantidad}</b> ticket no conciliado</p>) }
                                                            subTitle="¿Desea calcular deductivas parciales para tickets no conciliados?"
                                                            extra={<Row justify='center' gutter={[8,8]} align='middle'>
                                                                 <Col span={3}>
                                                                      <Button style={{width:'100%'}} className='btnSecundarioTwo' onClick={optSI}> Sí </Button>
                                                                 </Col>
                                                                 <Col span={3}>
                                                                      <Button style={{width:'100%'}} className='btnSecundarioTwo' onClick={optNO}> No </Button>
                                                                 </Col>
                                                            </Row>}
                                                            /> 
                                                  </Card>
                                             ) }
                                        </>): (
                                             // Caso: Cálculo de deductivas para cierre mensual
                                             <Card className="cardCatalogos" style={{background:'#F2F2F2'}} bordered={true} title={ 
                                                  <Row justify='space-around'>
                                                       <Col span={22} style={{fontSize:18, textAlign:"left", color:"#1747ca"}}>     
                                                            Reporte en Resumen del Cálculo de Deductivas:
                                                       </Col>
                                                       <Col span={1}>
                                                            <Tooltip title='Exportar a Excel'>
                                                                 <Button className="iconSecundary" shape='circle' disabled={dataList.length === 0} icon={<FileExcelOutlined /> } loading={fetchingDeductivaResumenExcel} onClick={onDescarga}/>
                                                            </Tooltip>
                                                       </Col>

                                                       <Col span={1}>
                                                            <Tooltip title='Ver detalle'>
                                                                 <Button className="iconTer" shape="circle" disabled={this.state.disabledButtons} icon={<EyeOutlined />} onClick={onShowModalDetalle}/>
                                                            </Tooltip>
                                                       </Col>
                                                  </Row> } >
                                                       { dataList.length > 0 ? (
                                                            <Table
                                                                 size="small"
                                                                 rowKey="idResumenServicio"
                                                                 columns={listaResumenServicio.columns}
                                                                 dataSource={dataList}
                                                                 loading={fetchingListaResumenServicio}
                                                                 onChange={handleTableChange}
                                                                 locale={{ emptyText: 'Sin datos' }}
                                                                 scroll={{ x: 4000 }}
                                                                 pagination={false}
                                                            />
                                                       ):(
                                                            <Spin spinning={fetchingListaResumenServicio}>
                                                                 <Empty description='Sin datos' />
                                                            </Spin>
                                                       ) }
                                             </Card>
                                        ) ) }
                                   </Col>
                              </Row>
                         </>):false }

                         <DetalleCalculo />

                         <Modal title="Tickets no Conciliados" visible={this.state.visibleDetalleTickets} footer={false} onCancel={this.handleCancel}  width = {'80%'} >
                              <List
                                   pagination={{ total: this.props.listaTicketsNoConciliados.cantidad, simple: true, current: this.state.page }}
                                   onChange={listChangePage}
                                   style={{backgroundColor:"white"}}
                                   grid={{ gutter: 8, column: 10 }}
                                   dataSource={this.props.listaTicketsNoConciliados.data}
                                   loading={this.props.fetchingListaTicktesNoConciliados}
                                   renderItem={item => (
                                       <List.Item className='listaHover' className='listaHover'>
                                             <Card className="cardCatalogos" bordered={false} style={{backgroundColor:'#F2F2F2'}}><Row justify='center' align='middle' ><p style={{ textAlign:'center' }}>{ item.folio }</p></Row></Card>
                                        </List.Item>
                                   )}
                              />
                         </Modal>
                    </Col>
               </Row>
          </Form>
          );
     }
}
 
 const mapStateToProps = state => {
     return {
          listaMesesAbiertos: state.MecanismosDePagosReducer.listaMesesAbiertos,
          fetchingListaMesesAbiertos: state.MecanismosDePagosReducer.fetchingListaMesesAbiertos,
          fetchingListaResumenServicio: state.MecanismosDePagosReducer.fetchingListaResumenServicio, 
          listaResumenServicio: state.MecanismosDePagosReducer.listaResumenServicio,
          listaResumenServicioCantidad: state.MecanismosDePagosReducer.listaResumenServicioCantidad,
          fetchingListaResumenTipo: state.MecanismosDePagosReducer.fetchingListaResumenTipo, 
          listaResumenTipo: state.MecanismosDePagosReducer.listaResumenTipo, 
          listaResumenTipoCantidad: state.MecanismosDePagosReducer.listaResumenTipoCantidad,
          fetchingDeductivaResumenExcel: state.MecanismosDePagosReducer.fetchingDeductivaResumenExcel,
          valorCalculoTipoDeductiva: state.MecanismosDePagosReducer.valorCalculoTipoDeductiva,
          fetchingValorCalculoTipoDeductiva: state.MecanismosDePagosReducer.fetchingValorCalculoTipoDeductiva,

          fetchingListaTicktesNoConciliados: state.MecanismosDePagosReducer.fetchingListaTicktesNoConciliados,
          listaTicketsNoConciliados: state.MecanismosDePagosReducer.listaTicketsNoConciliados
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          showModalDetalle: (fechaProyeccion, idMesCalendario, proyeccion, cierreMes, procesable, ultimoDiaAllPPLs, mesSeleccionado, mesAnterior) => {
               dispatch({ type: 'SHOW_MODAL_DETALLE_CALCULO_DEDUCTIVA', showModalDetalleCalculoDeductivas: true, fechaProyeccion, idMesCalendario, proyeccion, cierreMes, procesable, ultimoDiaAllPPLs, mesSeleccionado, mesAnterior });
          },
          onGetListaMesesAbiertos: () => {
               dispatch({ type: 'LISTA_MESES_ABIERTOS_REQUEST' });
          },
          onGetValorCalculo: (proyeccion, idMesCalendario) => {
               dispatch({ type: 'VALOR_TIPO_CALCULO_DEDUCTIVA_REQUEST', proyeccion, idMesCalendario });
          },
          onGetListaResumenServicio: (fechaProyeccion, idMesCalendario, proyeccion, mesCalendario) => {
               dispatch({ type: 'LISTA_RESUMEN_SERVICIO_REQUEST', fechaProyeccion, idMesCalendario, proyeccion, mesCalendario });
          },
          onExportarResumenDeductiva: (fechaProyeccion, idMesCalendario, proyeccion) => {
               dispatch({ type: 'DEDUCTIVA_RESUMEN_EXCEL_REQUEST', fechaProyeccion, idMesCalendario, proyeccion });
          },
          onGetDetelle: (fechaProyeccion, idMesCalendario, proyeccion) => {
               dispatch({ type: 'LISTA_DETALLE_CALCULO_DEDUCTIVA_REQUEST', fechaProyeccion, idMesCalendario, proyeccion });
          },
          onGetTicketsNoConcilidados: (idMesCalendario, page) => {
               dispatch({ type: 'DEDUCTIVA_TICKETS_NO_CONCILIADOS_REQUEST', idMesCalendario, page });
          },
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(CalcularDeductivasContainer);