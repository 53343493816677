import React from 'react';
import {Table, Row, Col, Button, Modal, Card, Tooltip} from 'antd';
import {connect} from 'react-redux';
import { EditOutlined, FileSearchOutlined, PlusOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import Search from 'antd/lib/input/Search';
import ModalDetalleDepositos from './ModalDetalleDeposito';
import ModalAsignacionDepositos from './ModalAsignacionDeposito';
import ModalEditarAsignacionDeposito from './ModalEditarAsignacionDeposito';

const confirm = Modal.confirm;

class AsignacionCapacidadUbicacion extends React.Component {
    state = { page: 1,  busqueda: "" }

    componentDidMount() {
        this.props.onGetServiciosPublicosA(this.state.page, this.state.busqueda);
        this.props.onGetUnidadesMedicion();
    }

    render() {

        const {listaServiciosPublicosAsignadosBusqueda, listaServiciosPublicosAsignadosCantidad,fetchingListaServiciosPublicosAsignadosBusqueda, 
            onGetServiciosPublicosA, onGetDetalleContenedoresSPA, onModalNuevaAsignacionSP} = this.props;

        const {page, busqueda} = this.state;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columns = [{
            title: 'Nombre',
            dataIndex: 'nombre',
            key: 'nombre',
            width: '40%',
        },{
            title: 'Cantidad depósito almacenamiento',
            dataIndex: 'cantidadDeposito',
            key: 'cantidadDeposito',
            width: '30%',
            align: 'center'
        },{
            title: 'Detalle depósitos',
            key: 'detalle',
            width: '15%',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.detalleDepositoDeServicioPublico}  onClick={() => onDetalle(record)}  ><FileSearchOutlined /></a>
            ),
        },{
            title: 'Editar',
            key: 'editar',
            width: '15%',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.editarAsignaciónDeDepositoDeServicioPublico}  onClick={() => onEditar(record)} ><EditOutlined /></a>
            ),
        }]

        const onBuscar = value => {
            this.setState({busqueda: value});
            onGetServiciosPublicosA(page, value);
        }

        const onNuevaAsignacion = () => {
            onModalNuevaAsignacionSP(page, busqueda);

        }

        const onDetalle = record => {
            onGetDetalleContenedoresSPA (record, page, busqueda, 'detalle');
        }

        const onEditar = record => {
            onGetDetalleContenedoresSPA (record, page, busqueda, 'editar');
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            onGetServiciosPublicosA(pagination.current, busqueda);
        }

        return (
            <Card className='cardCatalogos' title={<div className='headerLista'>Asignación de Ubicación y Capacidad</div>}>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search className='buscarModulos' placeholder='Ingrese nombre del servicio público' onSearch={(value) => onBuscar(value, "busqueda")} />
                    </Col>
                    {/* <Col span={7}>
                        <Button onClick={onNuevaAsignacion} disabled={!permisosUsuario.crearAsignacionDeDepositoDeServiciosPúblicos} className='nuevoUsuario'>Nueva Asignación</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nueva Asignación' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevaAsignacion} disabled={!permisosUsuario.crearAsignacionDeDepositoDeServiciosPúblicos} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table
                            size='small'
                            rowKey='nombre'
                            columns={columns}
                            dataSource={listaServiciosPublicosAsignadosBusqueda}
                            loading={fetchingListaServiciosPublicosAsignadosBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaServiciosPublicosAsignadosCantidad, simple: true, current: this.state.page }}                        
                        /> 
                    </Col>
                </Row>
                <ModalDetalleDepositos/>
                <ModalAsignacionDepositos/>
                <ModalEditarAsignacionDeposito/>
            </Card>
        )
    }
}

const mapStateToProps = state => {
    return {
        listaServiciosPublicosAsignadosBusqueda: state.ServiciosPublicosReducer.listaServiciosPublicosAsignadosBusqueda,
        listaServiciosPublicosAsignadosCantidad: state.ServiciosPublicosReducer.listaServiciosPublicosAsignadosCantidad,
        fetchingListaServiciosPublicosAsignadosBusqueda: state.ServiciosPublicosReducer.fetchingListaServiciosPublicosAsignadosBusqueda,

    }
}

const mapDispatchToProps = dispatch => {
    return{
        onGetServiciosPublicosA: (page, busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_PUBLICOS_ASIGNADOS_REQUEST", page: page, busqueda: busqueda});
        },
        onGetDetalleContenedoresSPA: (servicioPublicoSeleccionado, page, busqueda, tipo) => {
            dispatch({ type: 'LISTA_DEPOSITOS_SERVICIOS_PUBLICOS_REQUEST', servicioPublicoSeleccionado: servicioPublicoSeleccionado, page:page, busqueda: busqueda, tipo:tipo});
        },
        onModalEditarAsignacionSP: ( servicioPublicoSeleccionado, page, busqueda, tipo) => {
            dispatch({ type: 'LISTA_DEPOSITOS_SERVICIOS_PUBLICOS_REQUEST', servicioPublicoSeleccionado: servicioPublicoSeleccionado, page:page, busqueda: busqueda, tipo:tipo});
        },
        onModalNuevaAsignacionSP: (page, busqueda) => {
            dispatch({ type: 'SHOW_MODAL_NUEVA_ASIGNACION_DEPOSITOS', showModalNuevaAsignacionSP: true, page: page, busqueda: busqueda});
        },
        onGetUnidadesMedicion: () => {
            dispatch({ type: 'LISTA_UNIDADES_MEDICION_ACTIVOS_REQUEST' });
       },

    }
}

export default connect(mapStateToProps,mapDispatchToProps)(AsignacionCapacidadUbicacion);