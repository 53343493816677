import React from 'react';
import {Form, Spin, Row, Col, Table, Tooltip, Select, Button, Typography, Modal} from 'antd';
import {connect} from "react-redux";
import '../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { SaveOutlined, FileSearchOutlined } from '@ant-design/icons';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-classic-base64-upload/build/ckeditor';

const {Text} = Typography;

ClassicEditor.defaultConfig = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'insertTable',
        '|',
        'imageUpload',
        '|',
        'undo',
        'redo'
      ]
    },
    image: {
      toolbar: [
        'imageStyle:full',
        'imageStyle:side',
        '|',
        'imageTextAlternative'
      ]
    },
    table: {
      contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
    },
    language: 'es'
  };

class Infraestructura extends React.Component {
    formRef = React.createRef();

    componentDidMount() {
        if(this.props.RMDPObject){
            if(this.props.RMDPObject.infraestructuraByIdRmdp){
                this.props.onObtenerArchivoMesActual(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, 'infraestructura_' + this.props.mesSeleccionadoRMDP + '_' + this.props.anioSeleccionadoRMDP + '.txt'); 
            }
        }

        this.props.onObtenerArchivoMesAnterior(  this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP);
    }

    handleOk = () => { this.setState({ visible: false}) };  
    handleCancel = () => { this.setState({ visible: false }) };

    state = { data: '',  page: 1, visible: false }

    render() {
        const {
            anioSeleccionadoRMDP,
            mesSeleccionadoRMDP,
            mesTexto,
            fetchingObtenerArchivoMesAnteriorInfraestructura,
            archivoMesAnteriorInfraestructura,
            RMDPObject,
            archivoMesActualInfraestructura
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        let baseData = `
            <h1>1.2.1 Requerimientos Mínimos de Construcción</h1>
            <br />
            <h2>1.2.2 Requerimientos Para el Diseño del Centro Penitenciario</h2>
            <br />
        `

        const onGuardarInfraestructura = () => {
            console.log("RMDPObject", RMDPObject);
            console.log('this.state.data', this.state.data)
            if (this.state.data.length > 0) {
                if (RMDPObject && RMDPObject.infraestructuraByIdRmdp) {
                    this.props.onEditarInfraestructura(mesSeleccionadoRMDP, anioSeleccionadoRMDP, this.state.data, RMDPObject);
                } else {
                    this.props.onCrearInfraestructura(mesSeleccionadoRMDP, anioSeleccionadoRMDP, this.state.data, RMDPObject);
                }
            }
        };

        const onVerUbicaciones = () => {
            this.props.onObtenerUbicacion(mesSeleccionadoRMDP, anioSeleccionadoRMDP, this.state.page);
            this.setState({ visible:true });
        };

        const handleTableChange = (pagination)=>{
            this.setState({ page: pagination.current });
            this.props.onObtenerUbicacion(mesSeleccionadoRMDP, anioSeleccionadoRMDP, pagination.current);
        };

        return (<Spin spinning={this.props.fetchingCrearInfraestructura || this.props.fetchingEditarInfraestructura || this.props.fetchingObtenerArchivoMesActualInfraestructura || this.props.fetchingObtenerArchivoMesAnteriorInfraestructura}>
            <Row justify='end'>
                <Col span={4} style={{backgroundColor: '#737373', borderTopLeftRadius: '1em', borderTopRightRadius: '1em', textAlign:'center'}}>
                    <Text style={{ fontSize: 16, color: "white"}} strong> {anioSeleccionadoRMDP} - {mesTexto} </Text>
                </Col>
            </Row>
                <CKEditor
                    editor={ClassicEditor}
                    data={archivoMesActualInfraestructura ? archivoMesActualInfraestructura : archivoMesAnteriorInfraestructura ? archivoMesAnteriorInfraestructura : baseData}
                    onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        this.setState({
                            data: data
                        });
                        console.log({event, editor, data});
                    }}
                    onBlur={(event, editor) => {
                        console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                        console.log('Focus.', editor);
                    }}
                />

            <Row justify='end' gutter={[8,8]} style={{padding:'1em'}}>
                <Col span={1}>
                    <Tooltip title='Ver Ubicaciones'>
                        <Button className="iconTer" shape="circle" icon={<FileSearchOutlined />} onClick={onVerUbicaciones}/>
                    </Tooltip>
                </Col>
                <Col span={1}>
                    <Tooltip title='Guardar Cambios'>
                        <Button className="iconTer" shape="circle" icon={<SaveOutlined />} onClick={onGuardarInfraestructura}/>
                    </Tooltip>
                </Col>
            </Row>

            <Row justify='space-around'>
                <Col span={24}>
                    { this.props.ubicacionesInfraestructura ? (
                         <Modal title="Detalle Ubicaciones" visible={this.state.visible} footer={false} onCancel={this.handleCancel}  width = {'90%'} >
                              <Table
                                    title={ () => (
                                        <Row justify='center' type="flex" align='middle'>
                                            <h2>ÁREAS FUNCIONALES EXISTENTES PARA <b>{ this.props.mesTexto.toUpperCase() + ' ' + this.props.anioSeleccionadoRMDP }</b></h2>
                                        </Row>)
                                    }
                                    rowKey="idPonderacionArea"
                                    size='small'
                                    columns={this.props.ubicacionesInfraestructura.columns}
                                    dataSource={this.props.ubicacionesInfraestructura.data}
                                    loading={this.props.fetchingObtenerUbicacionesInfraestructura}
                                    locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar'}}
                                    onChange={handleTableChange}
                                    pagination={{ total: this.props.ubicacionesInfraestructuraCantidad, simple: true, current: this.state.page}}
                              />
                         </Modal>
                    ):false }
                </Col>
            </Row>
        </Spin>);
    }
}

const mapStateToProps = state => {
    return {
        anioSeleccionadoRMDP: state.RMDPReducer.anioSeleccionadoRMDP,
        mesSeleccionadoRMDP: state.RMDPReducer.mesSeleccionadoRMDP,
        mesTexto: state.RMDPReducer.mesTexto,
        fetchingObtenerArchivoMesAnteriorInfraestructura: state.RMDPReducer.fetchingObtenerArchivoMesAnteriorInfraestructura,
        archivoMesAnteriorInfraestructura: state.RMDPReducer.archivoMesAnteriorInfraestructura,
        RMDPObject: state.RMDPReducer.RMDPObject,
        ubicacionesInfraestructura: state.RMDPReducer.ubicacionesInfraestructura,
        ubicacionesInfraestructuraCantidad: state.RMDPReducer.ubicacionesInfraestructuraCantidad,
        fetchingObtenerUbicacionesInfraestructura: state.RMDPReducer.fetchingObtenerUbicacionesInfraestructura,

        archivoMesActualInfraestructura: state.RMDPReducer.archivoMesActualInfraestructura,

        fetchingCrearInfraestructura: state.RMDPReducer.fetchingCrearInfraestructura,
        fetchingEditarInfraestructura: state.RMDPReducer.fetchingEditarInfraestructura,
        fetchingObtenerArchivoMesActualInfraestructura: state.RMDPReducer.fetchingObtenerArchivoMesActualInfraestructura,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditorDeTexto: () => {
            dispatch({type: 'MODAL_EDITOR_TEXTO', showModalEditorTexto: true, dataEditorDeTexto: null})
        },
        onCrearInfraestructura: (mes, anio, fileData, RMDPObject) => {
            dispatch({type: 'CREAR_INFRAESTRUCTURA_REQUEST', mes, anio, fileData, RMDPObject})
        },
        onEditarInfraestructura: (mes, anio, fileData, RMDPObject) => {
            dispatch({type: 'EDITAR_INFRAESTRUCTURA_REQUEST', mes, anio, fileData, RMDPObject})
        },
        onObtenerArchivoMesAnterior: (mes, anio) => {
            dispatch({type: 'OBTENER_ARCHIVO_MES_ANTERIOR_INFRAESTRUCTURA_REQUEST', mes, anio})
        },
        onObtenerUbicacion: (idMes, anio, page) => {
            dispatch({ type: 'OBTENER_UBICACIONES_INFRAESTRUCTURA_REQUEST', idMes, anio, page });
        },
        onObtenerArchivoMesActual:(mes, anio, nombreArchivo) => {
            dispatch({ type: 'OBTENER_ARCHIVO_MES_ACTUAL_INFRAESTRUCTURA_REQUEST', mes, anio, nombreArchivo });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Infraestructura);
