import * as MonitorIntegralActions from '../actions/MonitorIntegralActions';

const initialState = {
     page: 1,
     busqueda: '',

     //Mapa de Incidencias
     fetchingListaAreasNumero: false,
     listaAreasNumero: [],
     
     fetchingListaUnidadesNumero: false,
     listaUnidadesNumero: [],

     fetchingListaInterioresNumero: false,
     listaInterioresNumero: [],

     fetchingListaSubInterioresNumero: false,
     listaSubInterioresNumero: [],

     fetchingListaDetallesSS: false,
     listaDetallesSS: [],
     listaDetallesSSCantidad: 0,
     listaDetalleMapa: [],

     fetchingInfoDetalleSS: false,
     infoDetalleSS: null,
     reiniciarMapa: false,

     atrasMapa: false,

     //Monitoreo Integral
     fetchingListaGruposServicios: false,
     listaGruposServicios: [],
     fetchingServicioPorGrupo: false,
     servicioPorGrupo: [],
     servicioPorGrupoCantidad: null,
     servicioSeleccionadoTablero: null,
     showModalSolicitudesDeServicio: false,
     fetchingSolicitudesDeServicioPorServicio: false,
     solicitudesDeServicioPorServicio: [],
     solicitudesDeServicioPorServicioCantidad: null,
     solicitudesDeServicioPorServicioPorcentaje: null,
     cantidadFoliosPorIdGrupoServ: null,
     idServicio: null,
     field: null,
     idGrupoSer: null,

     showModalTrazabilidad: false,
     solicitudDeServicioSeleccionado: null,
     
     fetchingMonitoreoTotalEncuestas: false,
     fetchingDataEncuestasSatisfaccion: false,
     dataEncustasSatisfaccion: [],
     fetchingDataGraficasByServicio: false,
     dataGraficasByServicio: [],
     fetchingDataGraficasQuejas: false,
     dataGraficasQuejas: [],
     fetchingDataGraficasEvaluacionDesempeno: false,
     dataGraficasEvaluacionDesempeno: [],
     listaServiciosByClasificacionGerencia: [],
     fetchingListaServiciosByClasificacionGerencia: false,
     fetchingDataGraficasQuejasByServicio: false,
     dataGraficasQuejasByServicio: [],
     fetchingDataGraficaPromedioSatisfaccion: false,
     dataGraficaPromedioSatisfaccion: [],
     fetchingDataEstandaresByTipo: false,
     dataEstandaresByTipo: [],

     // RDO | Monitoreo de Módulos Operativos
     dataRDOSolicitudes: [],
     fetchingDataRDOSolicitudes: false,
     infoRDO_SolictudesServicio: {},
     dataRDOPlanificados: [],
     infoRDO_ServiciosPlanificados: {},
     fetchingDataRDOPlanificados: false,
     fetchingMonitorModulosPlanificadosDatos: false,
     monitorModulosPlanificadosDatos: [],
     dataRDOServiciosPublicos: [],
     fertchingDataRDOServiciosPublicos: false,

     //Reporte Gráfico
     fetchingGetReporteGrafico: false,
     listRerpoteGrafico: [],
     listReporteGraficoCount: null,
     showModalInformacionReporteGrafico: false,
     reporteGraficoSeleccionado: null,
     fetchingGetRerporteGraficoAgrupado: false,
     listReporteGraficoAgrupado: [],
     listReporteGraficoAgrupadoCount: null,
}    

export default (state = initialState, action) => {
     switch (action.type) {
          //REDUCERS MAPA DE INCIDENCIAS
          case MonitorIntegralActions.LISTA_AREAS_NUMERO_REQUEST:
               return {...state, fetchingListaAreasNumero: true };
          case MonitorIntegralActions.LISTA_AREAS_NUMERO_SUCCESS:
               return {...state, fetchingListaAreasNumero: false, listaAreasNumero: action.listaAreasNumero };
          case MonitorIntegralActions.LISTA_AREAS_NUMERO_FAILURE:
               return {...state, fetchingListaAreasNumero: false };

          case MonitorIntegralActions.LISTA_UNIDADES_NUMERO_REQUEST:
               return {...state, fetchingListaUnidadesNumero: true };
          case MonitorIntegralActions.LISTA_UNIDADES_NUMERO_SUCCESS:
               return {...state, fetchingListaUnidadesNumero: false, listaUnidadesNumero: action.listaUnidadesNumero };
          case MonitorIntegralActions.LISTA_UNIDADES_NUMERO_FAILURE:
               return {...state, fetchingListaUnidadesNumero: false };

          case MonitorIntegralActions.LISTA_INTERIORES_NUMERO_REQUEST:
               return {...state, fetchingListaInterioresNumero: true };
          case MonitorIntegralActions.LISTA_INTERIORES_NUMERO_SUCCESS:
               return {...state, fetchingListaInterioresNumero: false, listaInterioresNumero: action.listaInterioresNumero };
          case MonitorIntegralActions.LISTA_INTERIORES_NUMERO_FAILURE:
               return {...state, fetchingListaInterioresNumero: false };

          case MonitorIntegralActions.LISTA_SUBINTERIORES_NUMERO_REQUEST:
               return {...state, fetchingListaSubInterioresNumero: true };
          case MonitorIntegralActions.LISTA_SUBINTERIORES_NUMERO_SUCCESS:
               return {...state, fetchingListaSubInterioresNumero: false, listaSubInterioresNumero: action.listaSubInterioresNumero };
          case MonitorIntegralActions.LISTA_SUBINTERIORES_NUMERO_FAILURE:
               return {...state, fetchingListaSubInterioresNumero: false };

          case MonitorIntegralActions.LISTA_DETALLES_SS_REQUEST:
               return {...state, fetchingListaDetallesSS: true };
          case MonitorIntegralActions.LISTA_DETALLES_SS_SUCCESS:
               return {...state, fetchingListaDetallesSS: false, listaDetallesSS: action.listaDetallesSS, listaDetallesSSCantidad: action.listaDetallesSSCantidad, listaDetalleMapa: action.listaDetalleMapa};
          case MonitorIntegralActions.LISTA_DETALLES_SS_FAILURE:
               return {...state, fetchingListaDetallesSS: false };

          case MonitorIntegralActions.INFO_DETALLE_SS_REQUEST:
               return {...state, fetchingInfoDetalleSS: true};
          case MonitorIntegralActions.INFO_DETALLE_SS_SUCCESS:
               return {...state, fetchingInfoDetalleSS: false, infoDetalleSS: action.infoDetalleSS, reiniciarMapa: action.reiniciarMapa};
          case MonitorIntegralActions.INFO_DETALLE_SS_FAILURE:
               return {...state, fetchingInfoDetalleSS: false};

          case MonitorIntegralActions.ATRAS_MAPA:
               return {...state, atrasMapa: action.atrasMapa };

          //REDUCERS MONITOREO INTEGRAL
          case MonitorIntegralActions.LISTA_GRUPOSERVICIOS_REQUEST:
               return {...state, fetchingListaGruposServicios: true }
          case MonitorIntegralActions.LISTA_GRUPOSERVICIOS_SUCCESS:
               return {...state, fetchingListaGruposServicios: false, listaGruposServicios: action.listaGruposServicios }  
          case MonitorIntegralActions.LISTA_GRUPOSERVICIOS_FAILURE:
               return {...state, fetchingListaGruposServicios: false }       

          case MonitorIntegralActions.SERVICIO_POR_GRUPOSERVICIO_REQUEST:
               return {...state, fetchingServicioPorGrupo: true }
          case MonitorIntegralActions.SERVICIO_POR_GRUPOSERVICIO_SUCCESS:
               return {...state, fetchingServicioPorGrupo: false, servicioPorGrupo: action.servicioPorGrupo, servicioPorGrupoCantidad: action.servicioPorGrupoCantidad }  
          case MonitorIntegralActions.SERVICIO_POR_GRUPOSERVICIO_FAILURE:
               return {...state, fetchingServicioPorGrupo: false } 

          case MonitorIntegralActions.SHOW_MODAL_SOLICITUDES_DE_SERVICIO:
               return {...state, showModalSolicitudesDeServicio: action.showModalSolicitudesDeServicio, servicioSeleccionadoTablero: action.servicioSeleccionadoTablero,  idServicio: action.idServicio , field: action.color, idGrupoSer: action.idGrupoSer } 

          case MonitorIntegralActions.SOLICITUDES_DE_SERVICIO_POR_SERVICIO_REQUEST:
               return {...state, fetchingSolicitudesDeServicioPorServicio: true }
          case MonitorIntegralActions.SOLICITUDES_DE_SERVICIO_POR_SERVICIO_SUCCESS:
               return {...state, fetchingSolicitudesDeServicioPorServicio: false, solicitudesDeServicioPorServicio: action.solicitudesDeServicioPorServicio, solicitudesDeServicioPorServicioCantidad: action.solicitudesDeServicioPorServicioCantidad,  solicitudesDeServicioPorServicioPorcentaje: action.solicitudesDeServicioPorServicioPorcentaje}  
          case MonitorIntegralActions.SOLICITUDES_DE_SERVICIO_POR_SERVICIO_FAILURE:
               return {...state, fetchingSolicitudesDeServicioPorServicio: false } 

         case MonitorIntegralActions.CANTIDAD_FOLIOS_POR_IDGRUPOSERVICIO_REQUEST:
               return {...state, fetchingListaGruposServicios: true }
          case MonitorIntegralActions.CANTIDAD_FOLIOS_POR_IDGRUPOSERVICIO_SUCCESS:
               return {...state, fetchingListaGruposServicios: false, cantidadFoliosPorIdGrupoServ: action.cantidadFoliosPorIdGrupoServ }  
          case MonitorIntegralActions.CANTIDAD_FOLIOS_POR_IDGRUPOSERVICIO_FAILURE:
               return {...state, fetchingListaGruposServicios: false }       

          case MonitorIntegralActions.SHOW_MODAL_TRAZABILIDAD:
               return {...state, showModalTrazabilidad: action.showModalTrazabilidad, solicitudDeServicioSeleccionado: action.solicitudDeServicioSeleccionado, page: action.page, busqueda: action.busqueda }

          case MonitorIntegralActions.DATA_GRAFICAS_ENCUESTAS_SATISFACCION_REQUEST:
               return {...state, fetchingDataEncuestasSatisfaccion: true }
          case MonitorIntegralActions.DATA_GRAFICAS_ENCUESTAS_SATISFACCION_SUCCESS:
               return {...state, fetchingDataEncuestasSatisfaccion: false, dataEncustasSatisfaccion: action.dataEncustasSatisfaccion }  
          case MonitorIntegralActions.DATA_GRAFICAS_ENCUESTAS_SATISFACCION_FAILURE:
               return {...state, fetchingDataEncuestasSatisfaccion: false }

          case MonitorIntegralActions.DATA_GRAFICAS_ENCUSTAS_SATISFACCION_SERVICIO_REQUEST:
               return {...state, fetchingDataGraficasByServicio: true }
          case MonitorIntegralActions.DATA_GRAFICAS_ENCUSTAS_SATISFACCION_SERVICIO_SUCCESS:
               return {...state, fetchingDataGraficasByServicio: false, dataGraficasByServicio: action.dataGraficasByServicio }  
          case MonitorIntegralActions.DATA_GRAFICAS_ENCUSTAS_SATISFACCION_SERVICIO_FAILURE:
               return {...state, fetchingDataGraficasByServicio: false }

          case MonitorIntegralActions.DATA_GRAFICAS_QUEJAS_REQUEST:
               return {...state, fetchingDataGraficasQuejas: true }
          case MonitorIntegralActions.DATA_GRAFICAS_QUEJAS_SUCCESS:
               return {...state, fetchingDataGraficasQuejas: false, dataGraficasQuejas: action.dataGraficasQuejas }  
          case MonitorIntegralActions.DATA_GRAFICAS_QUEJAS_FAILURE:
               return {...state, fetchingDataGraficasQuejas: false }

          case MonitorIntegralActions.DATA_GRAFICAS_QUEJAS_SERVICIO_REQUEST:
               return {...state, fetchingDataGraficasQuejasByServicio: true }
          case MonitorIntegralActions.DATA_GRAFICAS_QUEJAS_SERVICIO_SUCCESS:
               return {...state, fetchingDataGraficasQuejasByServicio: false, dataGraficasQuejasByServicio: action.dataGraficasQuejasByServicio }  
          case MonitorIntegralActions.DATA_GRAFICAS_QUEJAS_SERVICIO_FAILURE:
               return {...state, fetchingDataGraficasQuejasByServicio: false }

          case MonitorIntegralActions.DATA_GRAFICAS_EVALUACION_DESEMPENO_REQUEST:
               return {...state, fetchingDataGraficasEvaluacionDesempeno: true }
          case MonitorIntegralActions.DATA_GRAFICAS_EVALUACION_DESEMPENO_SUCCESS:
               return {...state, fetchingDataGraficasEvaluacionDesempeno: false, dataGraficasEvaluacionDesempeno: action.dataGraficasEvaluacionDesempeno }  
          case MonitorIntegralActions.DATA_GRAFICAS_EVALUACION_DESEMPENO_FAILURE:
               return {...state, fetchingDataGraficasEvaluacionDesempeno: false }

          case MonitorIntegralActions.LISTA_SERVICIOS_BY_CLASIFICACION_GERENCIA_REQUEST:
               return {...state, fetchingListaServiciosByClasificacionGerencia: true }
          case MonitorIntegralActions.LISTA_SERVICIOS_BY_CLASIFICACION_GERENCIA_SUCCESS:
               return {...state, fetchingListaServiciosByClasificacionGerencia: false, listaServiciosByClasificacionGerencia: action.listaServiciosByClasificacionGerencia }  
          case MonitorIntegralActions.LISTA_SERVICIOS_BY_CLASIFICACION_GERENCIA_FAILURE:
               return {...state, fetchingListaServiciosByClasificacionGerencia: false }

          case MonitorIntegralActions.DATA_GRAFICA_PROMEDIO_SATISFACCION_REQUEST:
               return {...state, fetchingDataGraficaPromedioSatisfaccion: true }
          case MonitorIntegralActions.DATA_GRAFICA_PROMEDIO_SATISFACCION_SUCCESS:
               return {...state, fetchingDataGraficaPromedioSatisfaccion: false, dataGraficaPromedioSatisfaccion: action.dataGraficaPromedioSatisfaccion }  
          case MonitorIntegralActions.DATA_GRAFICA_PROMEDIO_SATISFACCION_FAILURE:
               return {...state, fetchingDataGraficaPromedioSatisfaccion: false }

          case MonitorIntegralActions.DATA_ESTANDARES_BY_TIPO_REQUEST:
               return {...state, fetchingDataEstandaresByTipo: true }
          case MonitorIntegralActions.DATA_ESTANDARES_BY_TIPO_SUCCESS:
               return {...state, fetchingDataEstandaresByTipo: false, dataEstandaresByTipo: action.dataEstandaresByTipo }  
          case MonitorIntegralActions.DATA_ESTANDARES_BY_TIPO_FAILURE:
               return {...state, fetchingDataEstandaresByTipo: false }

          case MonitorIntegralActions.MONITOR_MODULOS_OPERATIVOS_SS_REQUEST:
               return {...state, fetchingDataRDOSolicitudes: true }
          case MonitorIntegralActions.MONITOR_MODULOS_OPERATIVOS_SS_SUCCESS:
               return {...state, fetchingDataRDOSolicitudes: false, dataRDOSolicitudes: action.dataRDOSolicitudes, infoRDO_SolictudesServicio: action.infoRDO_SolictudesServicio }  
          case MonitorIntegralActions.MONITOR_MODULOS_OPERATIVOS_SS_FAILURE:
               return {...state, fetchingDataRDOSolicitudes: false }

          case MonitorIntegralActions.MONITOR_MODULOS_OPERATIVOS_SP_REQUEST:
               return {...state, fetchingDataRDOPlanificados: true }
          case MonitorIntegralActions.MONITOR_MODULOS_OPERATIVOS_SP_SUCCESS:
               return {...state, fetchingDataRDOPlanificados: false, dataRDOPlanificados: action.dataRDOPlanificados, infoRDO_ServiciosPlanificados: action.infoRDO_ServiciosPlanificados }  
          case MonitorIntegralActions.MONITOR_MODULOS_OPERATIVOS_SP_FAILURE:
               return {...state, fetchingDataRDOPlanificados: false }

          case MonitorIntegralActions.MONITOR_MODULOS_PLANIFICADOS_DATOS_REQUEST:
               return {...state, fetchingMonitorModulosPlanificadosDatos: true }
          case MonitorIntegralActions.MONITOR_MODULOS_PLANIFICADOS_DATOS_SUCCESS:
               return {...state, fetchingMonitorModulosPlanificadosDatos: false, monitorModulosPlanificadosDatos: action.monitorModulosPlanificadosDatos }  
          case MonitorIntegralActions.MONITOR_MODULOS_PLANIFICADOS_DATOS_FAILURE:
               return {...state, fetchingMonitorModulosPlanificadosDatos: false }

          case MonitorIntegralActions.DATA_SERV_PUBLIC_REQUEST:
               return {...state, fertchingDataRDOServiciosPublicos: true }
          case MonitorIntegralActions.DATA_SERV_PUBLIC_SUCCESS:
               return {...state, fertchingDataRDOServiciosPublicos: false, dataRDOServiciosPublicos: action.dataRDOServiciosPublicos }  
          case MonitorIntegralActions.DATA_SERV_PUBLIC_FAILURE:
               return {...state, fertchingDataRDOServiciosPublicos: false }

          //Reporte Gráfico
          case MonitorIntegralActions.GET_REPORTE_GRAFICO_REQUEST:
               return {...state, fetchingGetReporteGrafico: true };
          case MonitorIntegralActions.GET_REPORTE_GRAFICO_SUCCESS:
               return {...state, fetchingGetReporteGrafico: false, listRerpoteGrafico: action.listRerpoteGrafico, listReporteGraficoCount: action.listReporteGraficoCount };
          case MonitorIntegralActions.GET_REPORTE_GRAFICO_FAILURE:
               return {...state, fetchingGetReporteGrafico: false };

          case MonitorIntegralActions.SHOW_MODAL_INFO_REPORTE_GRAFICO:
               return {...state, showModalInformacionReporteGrafico: action.showModalInformacionReporteGrafico, reporteGraficoSeleccionado: action.reporteGraficoSeleccionado, } 

          case MonitorIntegralActions.GET_REPORTE_GRAFICO_GRUPO_REQUEST:
               return {...state, fetchingGetRerporteGraficoAgrupado: true };
          case MonitorIntegralActions.GET_REPORTE_GRAFICO_GRUPO_SUCCESS:
               return {...state, fetchingGetRerporteGraficoAgrupado: false, listReporteGraficoAgrupado: action.listReporteGraficoAgrupado, listReporteGraficoAgrupadoCount: action.listReporteGraficoAgrupadoCount };
          case MonitorIntegralActions.GET_REPORTE_GRAFICO_GRUPO_FAILURE:
               return {...state, fetchingGetRerporteGraficoAgrupado: false };

          default:
               return state;
     }
}