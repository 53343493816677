import React, { Component } from 'react';
import { Form, Input, Button, Modal, Row, Col, Spin, Select, DatePicker, InputNumber, message, Upload, Typography } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import { UploadOutlined, FileImageFilled, FilePdfFilled } from '@ant-design/icons';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Dragger } = Upload;
const { Text, Paragraph } = Typography;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const props = {
    name: 'file',
    
    headers: {
        authorization: 'authorization-text',
    },
    onChange(info) {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} se cargó de forma correcta`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} se cargó de forma incorrecta.`);
        }
    },
};
class ModalNuevoServicio extends Component {
    state = {
        fechaInicio: null,
        fechaFin: null,
        fileImagen: false,
        filePDF: false,
        botonImagen: false,
        botonPDF: false,
        botonEditar: false,
        fileList: [],
        listaImagen: [],
    }
    formRef = React.createRef();
    render() {
        const { fetchingNuevoServicio, showModalNuevoServicio, onNuevoServicio, onShowModalNuevoServicio,
            listaGruposServicios, fetchingListaGruposServicios, fetchingListaContratos, listaContratos,
            limpiarFormulario, onLimpiarFormulario, page, listaInfoProyecto, busqueda
        } = this.props;

        if(limpiarFormulario && limpiarFormulario === 'crearServicio'){
            console.log('entró a limpiar');
            this.formRef.current.resetFields();
            this.setState({
                fileImagen: false,
                botonImagen: false,
                filePDF: false,
                botonPDF: false,
                botonEditar: false,
            });
            onLimpiarFormulario();
            onShowModalNuevoServicio(page, busqueda);
        }

        const onFinish = formulario => {
            console.log('Success:', formulario);
            const rangeValue = formulario['rangoVigencia'];
            formulario.rangoVigencia = rangeValue[0].format('YYYY-MM-DD') + "," + rangeValue[1].format('YYYY-MM-DD');
            onNuevoServicio(formulario, listaGruposServicios, listaContratos, page, busqueda);

            this.setState({
                fileImagen: false,
                botonImagen: false,
                filePDF: false,
                botonPDF: false,
                botonEditar: false,
                listaImagen: [],
                fileList: [],
            });
        };

        const handleCancel = () => {
            onShowModalNuevoServicio(page, busqueda);
            this.setState({
                fileImagen: false,
                botonImagen: false,
                filePDF: false,
                botonPDF: false,
                botonEditar: false,
                listaImagen: [],
                fileList: [],
            });
            this.formRef.current.resetFields();
        }

        const onChangeImagen = (info) => {
            console.log('info');
            console.log(info);
            let listaImagen = [...info.fileList];
   
            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            listaImagen = listaImagen.slice(-1);

            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonImagen: true,
                    botonEditar: true,
                    listaImagen
                })
            }
            var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
            if (info.file.status === 'done') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios, acentos y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['imagen']);
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        fileImagen: true,
                        listaImagen
                    });
                }
                this.setState({
                    botonImagen: false,
                    botonEditar: false,
                    listaImagen
                })
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['imagen']);
                    this.setState({listaImagen: []});
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        fileImagen: true,
                        listaImagen
                    });
                }
                this.setState({
                    botonImagen: false,
                    botonEditar: false,
                })
            }
        }
        
        const onChangePDF = (info) => {
            let fileList = [...info.fileList];
            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            fileList = fileList.slice(-1);
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                    fileList
                })
            }
            var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
            if (info.file.status === 'done') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                    this.setState({fileList: []});
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true,
                        fileList
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                    this.setState({fileList: []});
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true,
                        fileList
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }
        const onRemoveImagen = () => {
            this.setState({
                fileImagen: false,
                botonImagen: false,
                listaImagen: [],
            });
        }
        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
                fileList: []
            });
        }
        const changePonderador = (value) => {
            const valorPonderador = parseFloat(value).toFixed(2)
            this.formRef.current.setFieldsValue({'valorPonderador': valorPonderador})
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };
        const fechas = (rangeValue) => {
            if(listaInfoProyecto.fechaInicio && rangeValue){
                const fechaInicio = listaInfoProyecto.fechaInicio.diff(moment(rangeValue[0].format('YYYY-MM-DD')), "days");
                const fechaVigencia = moment([listaInfoProyecto.fechaVigencia.split('-')[2], (listaInfoProyecto.fechaVigencia.split('-')[1] - 1), listaInfoProyecto.fechaVigencia.split('-')[0]]).diff(moment(rangeValue[1].format('YYYY-MM-DD')), "days");
                
                if(fechaInicio>0){
                    message.error('No es posible seleccionar una fecha de inicio menor al inicio de contrato del proyecto', 7);
                    this.formRef.current.resetFields(['rangoVigencia']);
                }
                else if(fechaVigencia<0){
                    message.error('No es posible seleccionar una vigencia mayor al final de contrato del proyecto', 7);
                    this.formRef.current.resetFields(['rangoVigencia']);
                }
            }
            else{
                message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                this.formRef.current.resetFields(['rangoVigencia']);
            }
        }
        return (
            <Modal
            visible={showModalNuevoServicio}
            title='Nuevo Servicio'
            onCancel={handleCancel}
            footer={false}
            width={'70%'}
            >
                <Spin spinning={fetchingNuevoServicio}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Nombre"
                                    name="nombre"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={200} placeholder="Ingresa el nombre" title="No se permiten números ni caracteres especiales" pattern="^[a-zñA-ZÑ ÁÉÓÚÍáéóíú.,]+$"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Nombre corto"
                                    name="nombreC"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={80} placeholder="Ingresa el nombre corto" title="No se permiten números ni caracteres especiales" pattern="^[a-zñA-ZÑ ÁÉÓÚÍáéóíú.,]+$"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Grupo servicio"
                                    name="idGrupoSer"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el grupo de servicio"
                                        loading={fetchingListaGruposServicios}
                                    >
                                        {listaGruposServicios.map(option => <Option key={option.idGrupoSer}>{ option.nombre + ': ' + option.descripcion }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Valor ponderador"
                                    name="valorPonderador"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber min={0}
                                        max={100}
                                        defaultValue={0.00}
                                        style={{ width:'100%', textAlign: 'right'}}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                        onChange={changePonderador}
                                        step={0.05}/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Rango de vigencia"
                                    name="rangoVigencia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <RangePicker style={{width: '100%'}} format='DD-MM-YYYY' onChange={fechas} placeholder={["Fecha inicio","Fecha fin"]} />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Nomenclatura"
                                    name="nomenclatura"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={20} minLength={4} placeholder="Ingresa la nomenclatura" title="Sólo se permiten mayúsculas y mínimo 4 caracteres" pattern="^[A-ZÑ ÁÉÓÚÍ]+$"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Observaciones"
                                    name="observaciones"
                                >
                                    <Input maxLength={200} placeholder="Ingresa las observaciones" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Contrato"
                                    name="idContrato"
                                    rules={[{ required: true, message: 'Favor de seleccionar un valor', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona un contrato"
                                        loading={fetchingListaContratos}
                                        mode='multiple'
                                    >
                                        {listaContratos.map(option => <Option key={option.idContrato}>{ option.clave }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Imagen"
                                    name="imagen"
                                    rules={[{ required: true, message: 'Favor de seleccionar un archivo', }]}
                                >
                                    {/* <Upload customRequest={dummyRequest} onChange={onChangeImagen} onRemove={onRemoveImagen} accept='.jpg, .jpeg, .ico, .png' multiple={false}>
                                        {this.state.fileImagen === false ? (
                                            <Button disabled={this.state.botonImagen} className='btnCargaArchivos'>
                                                <UploadOutlined /> Click para subir imagen
                                            </Button>
                                        ):false}
                                    </Upload> */}

                                    <Dragger fileList={this.state.listaImagen} customRequest={dummyRequest} onRemove={onRemoveImagen} onChange={onChangeImagen} accept='.jpg, .jpeg, .png' multiple={false} style={{borderRadius:'1em'}}>
                                        <Row justify='center'>
                                            <FileImageFilled className='iconTabPane'/>
                                            <Col span={24} style={{paddingTop:'1em'}}>
                                                <Paragraph>
                                                    <Text strong> Click o arrastra a ésta área el achivo: jpg, png, jpeg </Text>
                                                </Paragraph>
                                            </Col>
                                        </Row>
                                    </Dragger>
                                    
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Procedimiento PDF"
                                    name="pdf"
                                    rules={[{ required: true, message: 'Favor de seleccionar un archivo', }]}
                                >
                                    {/* <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                        {this.state.filePDF === false ? (
                                        <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                            <UploadOutlined /> Click para subir procedimiento PDF
                                        </Button>
                                        ):false}
                                    </Upload> */}
                                    <Dragger customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false} style={{borderRadius:'1em'}} fileList={this.state.fileList}>
                                        <Row justify='center'>
                                            <FilePdfFilled className='iconTabPane'/>
                                            <Col span={24} style={{paddingTop:'1em'}}>
                                                <Paragraph>
                                                    <Text strong> Click o arrastra a ésta área el procedimiento PDF </Text>
                                                </Paragraph>
                                            </Col>
                                        </Row>
                                    </Dragger>
                                </Form.Item>
                            </Col>

                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button disabled={this.state.botonEditar} className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Crear Servicio</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingNuevoServicio: state.CatalogosGeneralesReducer.fetchingNuevoServicio,
        showModalNuevoServicio: state.CatalogosGeneralesReducer.showModalNuevoServicio,
        fetchingListaGruposServicios: state.CatalogosGeneralesReducer.fetchingListaGruposServicios,
        listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,
        fetchingListaContratos: state.InformacionOperativaReducer.fetchingListaContratos,
        listaContratos: state.InformacionOperativaReducer.listaContratos,
        page: state.CatalogosGeneralesReducer.page,
        busqueda: state.CatalogosGeneralesReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalNuevoServicio: (page, busqueda) => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_SERVICIO', showModalNuevoServicio: false, page, busqueda })
        },
        onNuevoServicio: ( formulario, listaGruposServicios, listaContratos, page, busqueda ) => {
            dispatch({ type: "NUEVO_SERVICIO_REQUEST" , formulario, listaGruposServicios, listaContratos, page, busqueda });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoServicio);