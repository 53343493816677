import React, { Component } from 'react';
import {Form,Input,Col,DatePicker, Modal,Button} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalEditarProducto extends Component {
    formRef = React.createRef();
    state = {
        page: 1,
    }
    render() { 
        const {showModalEditarProductoRequsicion, productoReqSeleccionado, page} = this.props;

        const onFinish = (formulario) => {
            console.log('formulario', formulario)
            this.props.onEditarProductoRequisicion(formulario, productoReqSeleccionado, this.state.page);
            this.formRef.current.resetFields();
            this.props.onShowModalModificar();
        }

        const handleCancel = () => {
            this.props.onShowModalModificar();
            this.formRef.current.resetFields();
        }
        return ( 
            <Modal
                visible={showModalEditarProductoRequsicion}
                title='Modificar Informacion del Producto'
                onCancel={handleCancel}
                footer={false}
            >
                 <Form {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        
                        ref={this.formRef}
                    >    
                         <Form.Item
                            label="Nombre de Proveedor"
                            name="nombreProveedor"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                              <Input placeholder='Nombre del Proveedor' />
                         </Form.Item>
                         <Form.Item
                                label="Fecha probable de entrega"
                                name="fechaProbableEntrega"
                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                            >
                                <DatePicker style={{ width: '100%' }} format='DD-MM-YYYY' />      
                         </Form.Item> 
                        <Col span={24}>
                           <Button htmlType="submit" className='nuevoUsuario'>Guardar Cambios</Button>
                        </Col>
                    </Form>       
          </Modal>
         );
    }
}
const mapStateToProps = state => {
	return {
        productoReqSeleccionado: state.AlmacenesReducer.productoReqSeleccionado,
        showModalEditarProductoRequsicion: state.AlmacenesReducer.showModalEditarProductoRequsicion,
        page: state.AlmacenesReducer.page,
    };
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalModificar: () => {
            dispatch({ type: "SHOW_MODAL_EDITAR_PRODUCTO_REQUISICION", showModalEditarProductoRequsicion: false})
        },
        onEditarProductoRequisicion: (formulario, productoReqSeleccionado, page) => {
            dispatch({ type: "EDITAR_PRODUCTO_REQUISICION_PUNTOREORDEN_REQUEST", formulario, productoReqSeleccionado, page})
        }
	};
};
export default connect(mapStateToProps, mapDispatchToProps) (ModalEditarProducto);