import axios from "axios";

//const endpoint = "https://papantla-services.herokuapp.com/";
//const endpoint = "https://papantla-dev.herokuapp.com/";
const endpoint = 'https://morelos-dev.herokuapp.com/';
//const endpoint = "https://papantla-demo.herokuapp.com/";

//const endpoint = "http://172.24.0.170:8080/"

//const endpoint = 'https://papantla-services-jose.herokuapp.com/';
//const endpoint = 'https://papantla-dev-jose.herokuapp.com/';

//const endpoint = 'https://papantla-secret-jose.herokuapp.com/';
//const endpoint = 'https://papantla-secret.herokuapp.com/';


export function getYearsRMDPCall(token) {
	return axios({
		method: "get",
		url: endpoint + "rmdp/anios",
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getMonthsRMDPCall(token, idAnioCalendario) {
	return axios({
		method: "get",
		url: endpoint + "rmdp/meses?idAnioCalendario=" + idAnioCalendario,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getRMDPCall(token, idMes, anio) {
	return axios({
		method: "get",
		url: endpoint + "rmdp/general?idMes=" + idMes + "&anio=" + anio,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getReporteRMDPCall(token, data) {
	return axios({
        	method: "post",
        	responseType: 'blob',
		url: endpoint + "rmdp/imprimir/reporte",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// ACREDITAMIENTO DE LA PRESTACIÓN DE LOS SERVICIOS DURANTE EL PERIODO DE CONFORMIDAD CON LO DISPUESTO EN EL ANEXO 12 DEL CONTRATO

// Información del Terreno

export function crearInformacionDeTerrenoCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "rmdp/informacion/terreno",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarInformacionDeTerrenoCall(token, data, id) {
	return axios({
		method: "put",
		url: endpoint + "rmdp/informacion/terreno/" + id,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function obtenerArchivoMesAnteriorTerrenoCall(token, mes, anio) {
	return axios({
		method: "get",
		url: endpoint + 'rmdp/informacion/terreno/mes/anterior?idMes=' + mes + '&anio=' + anio,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function obtenerArchivoMesActualTerrenoCall(token, mes, anio, nombreArchivo) {
	return axios({
		method: "get",
		url: endpoint + 'archivos/rmpd/informacion/terreno/' + mes + '/' + anio + '/' + nombreArchivo,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Infraestructura

export function crearInfraestructuraCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "rmdp/infraestructura",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarInfraestructuraCall(token, data, id) {
	return axios({
		method: "put",
		url: endpoint + "rmdp/infraestructura/" + id,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function obtenerArchivoMesAnteriorInfraestructuraCall(token, mes, anio) {
	return axios({
		method: "get",
		url: endpoint + 'rmdp/infraestructura/mes/anterior?idMes=' + mes + '&anio=' + anio,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function obtenerArchivoMesActualInfraestructuraCall(token, mes, anio, nombreArchivo) {
	return axios({
		method: "get",
		url: endpoint + 'archivos/rmpd/infraestructura/' + mes + '/' + anio + '/' + nombreArchivo,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function obtenerUbicacionesInfraestructuraCall(token, idMes, anio, page) {
	return axios({
		method: "get",
		url: endpoint + 'rmdp/infraestructura/ubicaciones?idMes=' + idMes + '&anio=' + anio + '&page=' + page + '&offset=10',
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Equipo
export function crearEquipoCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "rmdp/equipo",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarEquipoCall(token, data, id) {
	return axios({
		method: "put",
		url: endpoint + "rmdp/equipo/" + id,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaGruposServiciosCall(token) {
	return axios({
		method: "get",
		url: endpoint + 'rmdp/grupos/servicios',
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function obtenerArchivoMesAnteriorEquipoCall(token, mes, anio, idServicio, idPartida) {
	return axios({
		method: "get",
		url: endpoint + 'rmdp/equipo/mes/anterior?idMes=' + mes + '&anio=' + anio + '&idServicio=' + idServicio + '&idPartida=' + idPartida,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function obtenerArchivoMesActualEquipoCall(token, mes, anio, nombreArchivo, idServicio, idPartida) {
	return axios({
		method: "get",
		url: endpoint + 'archivos/rmpd/equipo/' + mes + '/' + anio + '/' + idServicio + '/' + nombreArchivo + "?idPartida=" + idPartida,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function busquedaTareasRMDPCall (token, idServicio, fechaInicio, fechaFin, page) {
	return axios({
	    method: 'get',
	    url: endpoint + `cancelacion/orden/busqueda/calendario?idServicio=${idServicio}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&page=${page}&offset=10`,
	    headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then( response => {
	    return response;
	}).catch ( error => {
	    return error.response.data;
	});
}

export function listaServiciosByIdServicioRMDPCall (token, idServicio, page) {
	return axios({
	    method: 'get',
	    url: endpoint + `rmdp/activos/${idServicio}?page=10&offset=${page}`,
	    headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then( response => {
	    return response;
	}).catch ( error => {
	    return error.response.data;
	});
} 

// Acciones
export function crearAccionesCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "rmdp/acciones",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarAccionesCall(token, data, id) {
	return axios({
		method: "put",
		url: endpoint + "rmdp/acciones/" + id,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function obtenerArchivoMesAnteriorAccionesCall(token, mes, anio) {
	return axios({
		method: "get",
		url: endpoint + 'rmdp/acciones/mes/anterior?idMes=' + mes + '&anio=' + anio,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function obtenerArchivoMesActualAccionesCall(token, mes, anio, nombreArchivo) {
	return axios({
		method: "get",
		url: endpoint + 'archivos/rmpd/acciones/' + mes + '/' + anio + '/' + nombreArchivo,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Folios Errores U Omisiones
export function obtenerListaFoliosErroresUOmisionesCall(token, mes, anio, page, field, order, formData) {
	return axios({
		method: "post",
		url: endpoint + 'rmdp/folios/errores/omisiones?idMes=' + mes + '&anio=' + anio + '&page=' + page + '&offset=10&field=' + field + '&order=' + order,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: formData
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarFoliosErroresUOmisionesPDFCall(token, mes, anio) {
	return axios({
		method: "post",
		responseType: "blob",
		url: endpoint + "rmdp/folios/errores/omisiones/pdf?idMes=" + mes + "&anio=" + anio,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarFoliosErroresUOmisionesExcelCall(token, mes, anio) {
	return axios({
		method: "post",
		responseType: "blob",
		url: endpoint + "rmdp/folios/errores/omisiones/excel?idMes=" + mes + "&anio=" + anio,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}



// Listados del (i) al (vii) enunciados en el Anexo 1, Mecanismo de Pagos
export function dataSSAgrupamientoCall (token, data) {
	return axios({
		method: 'post',
		url: endpoint + 'solicitud/servicio/reporte/dinamico/grupos',
		headers: {'Content-Type': 'application/json', 'Authorization':token},
		data: data,
	}).then( response => response)
	.catch(error => error.response.data);
}

//  (i) Relación de Fallas en la Presentación del Servicio
export function listaReporteFallasCall(token, idMes, anio, page, idServicio, idGrupoSer, tipo) {
	return axios({
		method: "get",
		url: endpoint + "rmdp/fallas/reporte?anio=" + anio + "&idMes=" + idMes + "&page=" + page + "&offset=10" + idServicio + idGrupoSer + tipo,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarExcelReporteFallasCall(token, anio, idMes) {
	return axios({
        method: "get",
        responseType: 'blob',
		url: endpoint + "rmdp/fallas/reporte/excel?anio=" + anio + "&idMes=" + idMes,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarPDFReporteFallasCall(token, anio, idMes) {
	return axios({
        method: "get",
        responseType: 'blob',
		url: endpoint + "rmdp/fallas/reporte/pdf?anio=" + anio + "&idMes=" + idMes,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function dataGraficaRelacionFallasCall (token, data) {
	return axios({
		method: 'post',
		url: endpoint + 'rmdp/reporte/dinamico/grupos',
		headers: {'Content-Type': 'application/json', 'Authorization':token},
		data: data,
	}).then( response => response)
	.catch(error => error.response.data);
}

//  (iv) Relación de Deducciones en el Mes i 

export function relacionDeduccionMesCall(token, idServicio, idMes, anio, page, order, field, formData) {
	return axios({
       	method: "post",
		url: endpoint + "rmdp/relacion/deducciones?idServicio=" + idServicio + "&idMes=" + idMes + "&anio=" + anio + "&page=" + page + "&offset=10" + "&order=" + order + "&field=" + field,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: formData
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarExcelRelacionDeduccionMesCall(token, idMes, anio) {
	return axios({
        	method: "get",
        	responseType: 'blob',
		url: endpoint + "rmdp/relacion/deducciones/excel?idMes=" + idMes + "&anio=" + anio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarPDFRelacionDeduccionMesCall(token, idMes, anio) {
	return axios({
        	method: "get",
        	responseType: 'blob',
		url: endpoint + "rmdp/relacion/deducciones/pdf?idMes=" + idMes + "&anio=" + anio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//  (v) Relación de Deducciones por Reiteraciones 
export function relacionDeduccionReiteracionMesCall(token, idServicio, idMes, anio, page, order, field, formData) {
	return axios({
       	method: "post",
		url: endpoint + "rmdp/relacion/deducciones/reiteracion?idServicio=" + idServicio + "&idMes=" + idMes + "&anio=" + anio + "&page=" + page + "&offset=10" + "&order=" + order + "&field=" + field,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: formData
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarPDFRelacionDeduccionReiteracionMesCall(token, idMes, anio) {
	return axios({
        	method: "get",
        	responseType: 'blob',
		url: endpoint + "rmdp/relacion/deducciones/reiteracion/pdf?idMes=" + idMes + "&anio=" + anio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarEXCELRelacionDeduccionReiteracionMesCall(token, idMes, anio) {
	return axios({
        	method: "get",
        	responseType: 'blob',
		url: endpoint + "rmdp/relacion/deducciones/reiteracion/excel?idMes=" + idMes + "&anio=" + anio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Cálculo de Pago por Servicio para el Mes i
//  3.1 Resumen del Pago por Servicio 
export function resumenPagoServicioCall(token, idMes, anio) {
	return axios({
       	method: "get",
		url: endpoint + "rmdp/resumen?idMes=" + idMes + "&anio=" + anio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function resumenCalculoPMIyPMRCall(token, idMes, anio) {
	return axios({
       	method: "get",
		url: endpoint + "rmdp/resumen/pmi?idMes=" + idMes + "&anio=" + anio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarPDFResumenPagoServicioCall(token, idMes, anio) {
	return axios({
        	method: "get",
        	responseType: 'blob',
		url: endpoint + "rmdp/resumen/pdf?idMes=" + idMes + "&anio=" + anio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarEXCELResumenPagoServicioCall(token, idMes, anio) {
	return axios({
        	method: "get",
        	responseType: 'blob',
		url: endpoint + "rmdp/resumen/excel?idMes=" + idMes + "&anio=" + anio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//  3.2 Resumen para el Cálculo del PMIi  & 3.3 Resumen para el Cálculo del PMRi 
export function exportarPDFResumenCalculoCall(token, idMes, anio, selector) {
	return axios({
        	method: "get",
        	responseType: 'blob',
		url: endpoint + "rmdp/resumen/pmi/pdf?idMes=" + idMes + "&anio=" + anio + "&selector=" + selector,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarEXCELResumenCalculoCall(token, idMes, anio, selector) {
	return axios({
        	method: "get",
        	responseType: 'blob',
		url: endpoint + "rmdp/resumen/pmi/excel?idMes=" + idMes + "&anio=" + anio + "&selector=" + selector,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//  3.4 Deducción Aplicable 
export function getResumenDeduccionAplicableCall(token, idMes, anio) {
	return axios({
       	method: "get",
		url: endpoint + "rmdp/resumen/da?idMes=" + idMes + "&anio=" + anio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function pdfResumenDeduccionAplicableCall(token, idMes, anio) {
return axios({
	method: "get",
	responseType: 'blob',
	url: endpoint + "rmdp/resumen/da/pdf?idMes=" + idMes + "&anio=" + anio,
	headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function excelResumenDeduccionAplicableCall(token, idMes, anio) {
	return axios({
		method: "get",
		responseType: 'blob',
		url: endpoint + "rmdp/resumen/da/excel?idMes=" + idMes + "&anio=" + anio,
		headers: {'Content-Type': 'application/json','Authorization':token},
		}).then(response => {
			return response;
		}).catch(error => {
			return error.response.data;
		});
	}

//  3.5 Deducción por Consumo de los Servicios Públicos del Desarrollador 
export function getTipoConsumoRMDPCall(token, idMes, anio, page) {
	return axios({
       	method: "get",
		url: endpoint + "rmdp/detalle/facturacion/mes?idMes=" + idMes + "&anio=" + anio + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function pdfTipoConsumoRMDPCall(token, idMes, anio) {
	return axios({
		method: "get",
		responseType: 'blob',
		url: endpoint + "rmdp/detalle/facturacion/pdf?idMes=" + idMes + "&anio=" + anio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getTipoConsumoGeneralRMDPCall(token, idMes, anio, page) {
	return axios({
       	method: "get",
		url: endpoint + "rmdp/detalle/facturacion/general?idMes=" + idMes + "&anio=" + anio + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function pdfTipoConsumoGeneralRMDPCall(token, idMes, anio) {
	return axios({
		method: "get",
		responseType: 'blob',
		url: endpoint + "rmdp/detalle/facturacion/general/pdf?idMes=" + idMes + "&anio=" + anio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function excelTipoConsumoRMDPCall(token, idMes, anio) {
	return axios({
		method: "get",
		responseType: 'blob',
		url: endpoint + "rmdp/detalle/facturacion/excel?idMes=" + idMes + "&anio=" + anio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function excelTipoConsumoGeneralRMDPCall(token, idMes, anio) {
	return axios({
		method: "get",
		responseType: 'blob',
		url: endpoint + "rmdp/detalle/facturacion/general/excel?idMes=" + idMes + "&anio=" + anio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}