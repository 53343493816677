import React, { Component } from 'react';
import { Form, Input, Button, Modal, Row, Col, Spin, Switch, InputNumber } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";


const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalEditarServicioPublico extends Component {
    formRef = React.createRef();
    render() {
        const { fetchingEditarServicioPublico, showModalEditarServicioPublico, onEditarServicioPersonal, onShowModalEditarServicioPublico,
            limpiarFormulario, onLimpiarFormulario, busqueda, page, servicioPublicoSeleccionado
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            if(formulario.cantidadDeposito < 0){
                formulario.cantidadDeposito = 0;
            }
            onEditarServicioPersonal(servicioPublicoSeleccionado, formulario, page, busqueda );
        };
        

        if (limpiarFormulario && limpiarFormulario === 'editarServicioPublico') {
            this.formRef.current.resetFields();
            onLimpiarFormulario();
        }

        const handleCancel = () => {
            onShowModalEditarServicioPublico();
            this.formRef.current.resetFields();
        }
        const cantidadEntera = (value) => {
            const cantidadDeposito = parseInt(value);
            this.formRef.current.setFieldsValue({'cantidadDeposito': cantidadDeposito});
        }

        return (
            <Modal
            visible={showModalEditarServicioPublico}
            title='Editar Servicio Público'
            onCancel={handleCancel}
            footer={false}
            >
                <Spin spinning={fetchingEditarServicioPublico}>
                    {servicioPublicoSeleccionado ? (
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    initialValues={servicioPublicoSeleccionado}
                    ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={24}>
                                <Form.Item
                                    label="Nombre del servicio público"
                                    name="nombre"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input disabled={true} maxLength={150} placeholder="Ingresa el nombre del servicio público" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Nombre del depósito de almacenamiento"
                                    name="nombreDeposito"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={150} placeholder="Ingresa el nombre del depósito de almacenamiento" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Cantidad de depósitos de almacenamiento"
                                    name="cantidadDeposito"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <InputNumber onChange={cantidadEntera} min={0} placeholder="Ingresa la cantidad de depósitos de almacenamiento" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="¿Cálculo diferenciado?"
                                    name="calculo"
                                    valuePropName="checked"
                                >
                                    <Switch checkedChildren={'Sí'} unCheckedChildren={'No'}/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Abastecimiento"
                                    name="abastecimiento"
                                    valuePropName="checked"
                                >
                                    <Switch checkedChildren={'Sí'} unCheckedChildren={'No'}/>
                                </Form.Item>
                            </Col>

                            <Col span={23} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Editar Servicio Público</Button>
                            </Col>
                        </Row>
                    </Form>
                    ):false}
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarServicioPublico: state.ServiciosPublicosReducer.fetchingEditarServicioPublico,
        showModalEditarServicioPublico: state.ServiciosPublicosReducer.showModalEditarServicioPublico,
        listaTurnos: state.ServiciosPublicosReducer.listaTurnos,
        fetchingListaTurnos: state.ServiciosPublicosReducer.fetchingListaTurnos,
        busqueda: state.ServiciosPublicosReducer.busqueda,
        orden: state.ServiciosPublicosReducer.orden,
        page: state.ServiciosPublicosReducer.page,
        servicioPublicoSeleccionado: state.ServiciosPublicosReducer.servicioPublicoSeleccionado,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarServicioPublico: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_SERVICIO_PUBLICO', showModalEditarServicioPublico: false })
        },
        onEditarServicioPersonal: (servicioPublicoSeleccionado, formulario, page, busqueda ) => {
            dispatch({ type: "EDITAR_SERVICIO_PUBLICO_REQUEST", servicioPublicoSeleccionado, formulario, page, busqueda });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarServicioPublico);