import React from 'react';
import { message, Form, Row, Col, Divider, Button, Statistic, Select, Switch, DatePicker, Input, Table, Card, Alert, Tooltip, Spin } from 'antd';
import { connect } from "react-redux";
import { SearchOutlined, ArrowUpOutlined, ArrowDownOutlined, ReloadOutlined, FileExcelOutlined } from '@ant-design/icons';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import moment from 'moment';


const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };

class KardexContainer extends React.Component {
    formRef = React.createRef();

    componentDidMount() {
          this.props.onGetAlmacenesActivos();
          this.props.onRequestInfoProyecto();
          this.props.onProductosActivos();
     }

     state = {
          page: 1,
          checkedAlmacen: true,
          checkedFechas: true,
          checkedFechasValidar: true,
          cardsMovimientos: false,
          todosAlmacenesNombre: [],
          almacenesSeleccionados: [],
          almacenSeleccionado: null,
          almacenesTodos: false,
          fechasSeleccionadas:[],
          encabezado: false,
          productoBusqueda: [],
          fechasIngresadas: [],
          selected: '',
     }


     render() {
          const { 
               limpiarFormulario, 
               onLimpiarFormulario,
               listaInfoProyecto,
               onProductoExistente,
               kardexCardsMovimientos,
               kardexEncabezado,
               onNuevaConsulta,
               onAlmacenesByProducto,
               listaAlmacenesByProducto,
               fetchingListaAlmacenesByProducto,
               listaProductosActivos,
               listaKardexProductosObtenidos,
               onObtenerKardexProducto,
               fetchingListaKardexMovimientos, 
               listaKardexMovimientos,
               onObtenerKardexMovimientos,
               onReporteKardex,
               onLimpiarTabla,
               dataMovimientosSumas, 
               dataMovimientosRestas,
               cantidadMovimientoSuma,
               cantidadMovimientoResta,
          } = this.props;

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
               permisosUsuario = JSON.parse(permisosUsuario);
          }

          const onFinish = formulario => { 
               console.log('Success:', formulario, listaProductosActivos);

               onProductoExistente((formulario.skuClave).trim());

               onAlmacenesByProducto((formulario.skuClave).trim());

               for(let i = 0; i < listaProductosActivos.length; i++){
                    if(listaProductosActivos[i].codigoAdicional === formulario.skuClave){
                         let productoBusquedaLocal = [];
                         productoBusquedaLocal = listaProductosActivos[i];
                         this.setState({ productoBusqueda: productoBusquedaLocal });
                    } 
               }
               this.setState({ checkedFechas: true, checkedFechasValidar: !(formulario.todosFechas) });
               this.formRef.current.resetFields();
               onNuevaConsulta();
          };

          if (limpiarFormulario && limpiarFormulario === 'inventarioFisico') {
               onLimpiarFormulario();
          }

          const fechas = (rangeValue) => {
               if(listaInfoProyecto.fechaInicioOperacion){
                    const diferenciaInicio = listaInfoProyecto.fechaInicioOperacion.diff(moment(rangeValue[0].format('YYYY-MM-DD')), "days");

                    const fechaVigenciaNuevo = listaInfoProyecto.fechaVigencia.split('-')[2] + '-' + listaInfoProyecto.fechaVigencia.split('-')[1] + '-' + listaInfoProyecto.fechaVigencia.split('-')[0];
                    const fechaVigenciaLocal = new Date(fechaVigenciaNuevo);
                    this.setState({ fechasIngresadas: rangeValue});
                    if(diferenciaInicio > 0){
                    message.error('No es posible seleccionar una fecha de inicio menor al inicio del contrato del proyecto', 10);
                    this.formRef.current.resetFields(['rangoMovimientos']);
                    }
                    if(rangeValue[1] > fechaVigenciaLocal){
                         message.error('No es posible seleccionar una fecha de fin mayor a la vigencia del contrato del proyecto', 10);
                         this.formRef.current.resetFields(['rangoMovimientos']);
                    }
               }
               else{
                    message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                    this.formRef.current.resetFields(['rangoMovimientos']);
               }
          };

          const todasFechas = (checked) => {
               this.setState({ checkedFechas: !this.state.checkedFechas });
               this.formRef.current.resetFields(['rangoMovimientos']);
          };

          const handleChange = (value) => {
               console.log(`selected ${value}`)
               this.setState({ selected: value });
          };

          const onConsultar = () => {
               this.setState({ encabezado: false, checkedAlmacen: true, checkedFechas: true, productoBusqueda: [], almacenSeleccionado: null });
               this.formRef.current.resetFields();
               onNuevaConsulta();
               onLimpiarTabla();
          }

          const columns = [
               { title: 'Folio', dataIndex: 'folio', key: 'folio', align: 'center' },
               { title: 'Tipo Folio', dataIndex: 'tipoFolio', key: 'tipoFolio', align:'center' },
               { title: 'Operación', dataIndex: 'operacion', key: 'operacion', align:'center' },
               { title: 'Fecha/Hora Realización', dataIndex: 'fechaHora', key: 'fechaHora', align:'center' },
               { title: 'Usuario Realizó', dataIndex: 'usuario', key: 'usuario', align:'center' },
               { title: 'Cantidad Movimiento', dataIndex: 'cantidad', key: 'cantidad', align:'right' },
               { title: 'Costo Unitario', dataIndex: 'costoUnitarioTabla', key: 'costoUnitarioTabla', align:'right' },
               { title: 'Costo Promedio', dataIndex: 'costoPromedioTabla', key: 'costoPromedioTabla', align:'right' },
               { title: 'Saldo Inventario', dataIndex: 'saldoInventario', key: 'saldoInventario', align:'right' },
          ];

          const onAlmacenSeleccionado = (value) => {
               
               let almacenesIdsTodos = [];

               if(value === 'todos') {
                    this.setState({almacenesTodos: true});
                    this.formRef.current.setFieldsValue({'idAlmacen':['todos']})
                    listaAlmacenesByProducto.map(item => almacenesIdsTodos.push(item.idAlmacen));
                    
                    console.log('almacenesIdsTodos', almacenesIdsTodos)
               }

               if(value){ this.setState({ almacenSeleccionado: value });}
               
               if(!this.state.checkedFechasValidar){
                    console.log('Entra a todas fechas', this.state.productoBusqueda)

                    let fechas = [moment(listaInfoProyecto.fechaInicioOperacion, 'DD-MM-YYYY'), moment(listaInfoProyecto.fechaVigencia, 'DD-MM-YYYY')];

                    let fechaFin = fechas[1]._i.split('-')[2] + '-' + fechas[1]._i.split('-')[1] + '-' + fechas[1]._i.split('-')[0];

                    onObtenerKardexProducto( parseInt(value), this.state.productoBusqueda.codigoAdicional, this.state.selected, fechas[0]._i, fechaFin );

                    onObtenerKardexMovimientos(parseInt(value), this.state.productoBusqueda.codigoAdicional, this.state.selected, fechas[0]._i, fechaFin, almacenesIdsTodos );
               } else {
                    console.log('Entra a fechas seleccionadas')

                    const fechaInicioIngresada = this.state.fechasIngresadas[0].format('YYYY-MM-DD');
                    const fechaFinIngresada = this.state.fechasIngresadas[1].format('YYYY-MM-DD');

                    onObtenerKardexProducto( parseInt(value), this.state.productoBusqueda.codigoAdicional, this.state.selected, fechaInicioIngresada, fechaFinIngresada );

                    onObtenerKardexMovimientos(parseInt(value), this.state.productoBusqueda.codigoAdicional, this.state.selected, fechaInicioIngresada, fechaFinIngresada, almacenesIdsTodos);
               }
               onLimpiarTabla(); // julio
          }

          const onReporte = () => {
               onReporteKardex(listaKardexProductosObtenidos.data, listaKardexMovimientos.data);
          };

          const  disabledDate = (fecha) => {
               if( fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicioOperacion) {
                   return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicioOperacion;
               } else {
                   if(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos){
                    return fecha && moment(fecha.format('YYYY-MM-DD')) > moment((listaInfoProyecto.vigencia +  parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2])) + '-' +listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[1] + '-' + listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[0]);
                    }
               }
          };

          return (
          <Card className="cardCatalogos" title={<div className="headerLista">Kárdex de Movimientos Entrada/Salidas al Almacén</div>}>
               <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    ref={this.formRef}
               >
                    <Spin spinning={this.props.fetchingKardexProductosExistentes || fetchingListaAlmacenesByProducto}>
                    { kardexEncabezado ? (
                         <Row justify='space-around' style={{ textAlign: 'center' }}>
                              { !this.state.almacenSeleccionado ? (
                                   <Col span={24} style={{paddingBottom:'2em'}}>
                                        <Alert message="Selecciona un almacén" type="info" />
                                   </Col>
                              ): false }

                              <Col span={4}>
                                   <Form.Item
                                        label="Almacén"
                                        hasFeedback
                                   />
                              </Col>
                              <Col span={7}>
                                   <Form.Item
                                        name="idAlmacenSeleccionado"
                                   >
                                        <Select
                                             // allowClear
                                             className='input'
                                             style={{ width: '100%'}}
                                             placeholder="Seleccione Almacen"
                                             loading={fetchingListaAlmacenesByProducto}
                                             onChange={onAlmacenSeleccionado}
                                        >
                                             {listaAlmacenesByProducto.map(option => 
                                             <Option key={option.idAlmacen}>
                                                  { option.nombreC}
                                             </Option>) }
                                        </Select>
                                   </Form.Item>
                              </Col>

                              <Col span={4}>
                                   <Form.Item
                                   label="Código de almacén"
                                   hasFeedback
                                   />
                              </Col>
                              <Col span={7}>
                                   <Form.Item
                                        name="claveSKUSeleccionado"
                                   >
                                        <Input 
                                             allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Código de almacén del producto seleccionado"
                                             disabled={true}
                                             className='input'
                                             defaultValue={ this.state.productoBusqueda.codigoAdicional}
                                        />
                                   </Form.Item>
                              </Col>

                              <Col span={4}>
                                   <Form.Item
                                        label="Producto"
                                        hasFeedback
                                   />
                              </Col>
                              <Col span={7}>
                                   <Form.Item
                                        name="idProductoSeleccionado"
                                   >
                                        <Input 
                                             allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Producto seleccionado"
                                             disabled={true}
                                             className='input'
                                             defaultValue={ this.state.productoBusqueda.nombre }
                                        />
                                   </Form.Item>
                              </Col>

                              { this.state.productoBusqueda.unidadMedidaByIdUnidadMedida != undefined ? (
                                   <>
                                        <Col span={4}>
                                             <Form.Item
                                             label="Unidad de Medida"
                                             hasFeedback
                                             />
                                        </Col>
                                   
                                        <Col span={7}>
                                             <Form.Item
                                                  name="unidadMedidaSeleccionado"
                                             >
                                                  <Input 
                                                       allowClear
                                                       style={{ width: '100%' }}
                                                       placeholder="Unidad de medida del producto seleccionado"
                                                       disabled={true}
                                                       defaultValue={ this.state.productoBusqueda.unidadMedidaByIdUnidadMedida.unidadMedida }
                                                  />
                                             </Form.Item>
                                        </Col>
                                   </>
                              ):(
                                   <>
                                        <Col span={4}>
                                             <Form.Item
                                                  label="Unidad de Medida"
                                                  hasFeedback
                                             />
                                        </Col>

                                        <Col span={7}>
                                             <Form.Item>
                                                  <Input 
                                                       allowClear
                                                       style={{ width: '100%' }}
                                                       placeholder="Unidad de medida del producto seleccionado"
                                                       disabled={true}
                                                  />
                                             </Form.Item>
                                        </Col>
                                   </>
                              ) }
                         </Row>
                    ):(
                         <Row justify='space-around' style={{ textAlign: 'left' }} gutter={[8,8]}>   
                              <Col span={8}>
                                   <Form.Item
                                        name='skuClave'
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                   >
                                        <Input
                                             maxLength={50} 
                                             placeholder='Ingresa codigo adicional'
                                        >
                                        </Input>
                                   </Form.Item>
                              </Col>

                              <Col span={8}>
                                   <Form.Item
                                        name="rangoMovimientos"
                                        rules={[{ required: this.state.checkedFechas, message: 'Favor de llenar el campo' }]}
                                   >
                                        { this.state.checkedFechas ? (
                                             <RangePicker
                                                  className='input'
                                                  style={{width: '100%'}} 
                                                  format='DD-MM-YYYY' 
                                                  onChange={fechas} 
                                                  disabledDate={disabledDate}
                                                  placeholder={["Fecha inicio","Fecha fin"]}
                                             />
                                        ): (
                                             <RangePicker
                                                  className='input'
                                                  style={{width: '100%'}} 
                                                  format='DD-MM-YYYY'
                                                  defaultValue={[moment(listaInfoProyecto.fechaInicioOperacion, 'DD-MM-YYYY'), moment(listaInfoProyecto.fechaVigencia, 'DD-MM-YYYY')]} 
                                                  disabled={[!this.state.checkedFechas, !this.state.checkedFechas]}
                                             />
                                        ) }
                                   </Form.Item>
                              </Col>

                              <Col span={2}>
                                   <Form.Item
                                        name='todosFechas'
                                   >
                                        <Switch
                                             checkedChildren={'TODAS'}
                                             unCheckedChildren={'TODAS'}
                                             defaultChecked={false}
                                             size={'default'}
                                             onChange={todasFechas}
                                        />
                                   </Form.Item>
                              </Col>
                              <Col span={5}>
                                   <Form.Item
                                        name='tipoMovimiento'
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                   >                                  
                                        <Select 
                                             style={{ width: '100%' }} 
                                             onChange={handleChange}
                                             placeholder="Selecciona tipo de movimiento"
                                        >
                                             <OptGroup label="Tipo de Movimiento">
                                                  <Option value="Entradas">Entradas</Option>
                                                  <Option value="Salidas">Salidas</Option>
                                                  <Option value="Ambas">Ambas</Option>
                                             </OptGroup>
                                        </Select>
                                   </Form.Item>
                              </Col>
                         
                              <Col span={1}>
                                   <Button shape="circle" icon={<SearchOutlined />} className="btnFiltrar" htmlType="submit"/>
                              </Col>
                         </Row>
                    )}
                         <Row justify='space-around' gutter={[8,8]}>  

                              <Col span={22}>
                                   <Divider orientation='left'> Movimientos </Divider>
                              </Col>

                              <Col span={1} style={{ paddingTop:'1em' }}>
                                   <Tooltip title='Consultar otro movimiento' placement="topRight">
                                        <Button className="iconTer" shape="circle"icon={<ReloadOutlined />} disabled={ !kardexCardsMovimientos } onClick={onConsultar}/>
                                   </Tooltip>
                              </Col>

                              <Col span={1} style={{ paddingTop:'1em' }}>
                                   <Tooltip title='Exportar archivo .xls' placement="topRight">
                                        <Button className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } loading={this.props.fetchingKardexReporte} disabled={!permisosUsuario.exportarExcelKardexMovimientosEsAlAlmacén || !kardexCardsMovimientos} onClick={onReporte}/>
                                   </Tooltip>
                              </Col> 
                              <Col span={24}>
                                   <Spin spinning={fetchingListaKardexMovimientos || this.props.fetchingListaKardexProductosObtenidos}>
                                        <Row justify='space-around'>
                                             <Col span={24}>
                                                  <Row justify='space-around' gutter={[8,8]}>
                                                  { kardexCardsMovimientos && dataMovimientosSumas[0] ? (
                                                       <Col span={5}>
                                                            <Tooltip titile='Movimientos Suma'>
                                                                 <Card className="cardCatalogos">
                                                                      <Statistic
                                                                           title="Total Entradas"
                                                                           value={ cantidadMovimientoSuma }
                                                                           precision={0}
                                                                           valueStyle={{ color: '#3f8600' }}
                                                                           prefix={<ArrowUpOutlined />}
                                                                      />
                                                                 </Card>
                                                            </Tooltip>
                                                       </Col>
                                                  ):false }

                                                  { kardexCardsMovimientos && dataMovimientosRestas[0] ? (
                                                       <Col span={5}>
                                                            <Tooltip titile='Movimientos Resta'>
                                                                 <Card className="cardCatalogos">
                                                                      <Statistic
                                                                           title="Total Salidas"
                                                                           value={cantidadMovimientoResta}
                                                                           precision={0}
                                                                           valueStyle={{ color: '#cf1322' }}
                                                                           prefix={<ArrowDownOutlined />}
                                                                      />
                                                                 </Card>
                                                            </Tooltip>
                                                       </Col>
                                                  ):false }
                                                  </Row>
                                             </Col>
                                             <Col span={24}>
                                                  <br/>
                                                  <Table
                                                       size="small" 
                                                       rowKey="idProducto" 
                                                       columns={columns}
                                                       locale={{ emptyText: 'Sin datos'}}
                                                       dataSource={listaKardexMovimientos.data}
                                                       loading={fetchingListaKardexMovimientos}
                                                       //pagination={{ total: listaSalidaDeBienesBusquedaCantidad, simple: true, current: page }}
                                                       pagination={false}
                                                       scroll={{"x": 1800 | true, "y": 1000}}
                                                  />
                                             </Col>
                                             
                                             {/* { (kardexCardsMovimientos && dataMovimientosSumas[0]) || (kardexCardsMovimientos && dataMovimientosRestas[0]) ? (
                                                  <Divider style={{ margin: '1px' }} orientation="left">
                                                       <h3> Movimientos </h3>
                                                  </Divider>
                                             ): false } */}

                                             {/* <Col span={24}>
                                                  <Row justify='space-around' gutter={[8,8]}>
                                                  { kardexCardsMovimientos && dataMovimientosSumas[0] ? (
                                                       <Col span={5}>
                                                            <Tooltip titile='Movimientos Suma'>
                                                                 <Card className="cardCatalogos">
                                                                      <Statistic
                                                                           title="Total Entradas"
                                                                           value={ cantidadMovimientoSuma }
                                                                           precision={0}
                                                                           valueStyle={{ color: '#3f8600' }}
                                                                           prefix={<ArrowUpOutlined />}
                                                                      />
                                                                 </Card>
                                                            </Tooltip>
                                                       </Col>
                                                  ):false }

                                                  { kardexCardsMovimientos && dataMovimientosRestas[0] ? (
                                                       <Col span={5}>
                                                            <Tooltip titile='Movimientos Resta'>
                                                                 <Card className="cardCatalogos">
                                                                      <Statistic
                                                                           title="Total Salidas"
                                                                           value={cantidadMovimientoResta}
                                                                           precision={0}
                                                                           valueStyle={{ color: '#cf1322' }}
                                                                           prefix={<ArrowDownOutlined />}
                                                                      />
                                                                 </Card>
                                                            </Tooltip>
                                                       </Col>
                                                  ):false }
                                             </Row>
                                             </Col> */}
                                        </Row>
                                   </Spin>
                              </Col>
                              {/* <Divider style={{ margin: '10px' }} />
                              <Col span={11}>
                                   <Button className="btnCargaArchivos" style={{ width: '100%' }} disabled={!permisosUsuario.exportarExcelKardexMovimientosEsAlAlmacén || !kardexCardsMovimientos}onClick={onReporte}>
                                        Exportar en Excel
                                   </Button>
                              </Col>
                              
                              <Col span={11}>
                                   <Button className="btnPrueba" style={{ width: '100%' }} disabled={ !kardexCardsMovimientos } onClick={onConsultar}>
                                        Consultar Otro Movimiento
                                   </Button>
                              </Col> */}
                         </Row>
                    </Spin>
               </Form>
          </Card>
          );
     }
}

const mapStateToProps = state => {
     return {
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          fetchingListaAlmacenesActivos: state.AlmacenesReducer.fetchingListaAlmacenesActivos,
          listaAlmacenesActivos: state.AlmacenesReducer.listaAlmacenesActivos,
          listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
          kardexCardsMovimientos: state.AlmacenesReducer. kardexCardsMovimientos,
          kardexEncabezado: state.AlmacenesReducer.kardexEncabezado,
          listaAlmacenesByProducto: state.AlmacenesReducer.listaAlmacenesByProducto,
          fetchingListaAlmacenesByProducto: state.AlmacenesReducer.fetchingListaAlmacenesByProducto,
          listaProductosActivos: state.AlmacenesReducer.listaProductosActivos,
          fetchingListaKardexProductosObtenidos: state.AlmacenesReducer.fetchingListaKardexProductosObtenidos, 
          listaKardexProductosObtenidos: state.AlmacenesReducer.listaKardexProductosObtenidos,
          fetchingListaKardexMovimientos: state.AlmacenesReducer.fetchingListaKardexMovimientos, 
          listaKardexMovimientos: state.AlmacenesReducer.listaKardexMovimientos,
          dataMovimientosSumas: state.AlmacenesReducer.dataMovimientosSumas, 
          dataMovimientosRestas: state.AlmacenesReducer.dataMovimientosRestas,
          buttonExport: state.AlmacenesReducer.buttonExport,
          cantidadMovimientoSuma: state.AlmacenesReducer.cantidadMovimientoSuma,
          cantidadMovimientoResta: state.AlmacenesReducer.cantidadMovimientoResta,
          fetchingKardexReporte: state.AlmacenesReducer.fetchingKardexReporte,
          fetchingKardexProductosExistentes: state.AlmacenesReducer.fetchingKardexProductosExistentes,
     };
};
const mapDispatchToProps = dispatch => {
	return {
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
          onGetAlmacenesActivos: () => {
               dispatch({ type: 'ALMACENES_ACTIVOS_BUSQUEDA_REQUEST' })
          },
          onRequestInfoProyecto: () => {
               dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
          },
          onProductoExistente: (skuClave) => {
               dispatch({ type: 'KARDEX_PRODUCTOS_EXISTENETES_REQUEST', skuClave });
          },
          onNuevaConsulta: () => {
               dispatch({ type: 'KARDEX_PRODUCTOS_EXISTENETES_SUCCESS', kardexEncabezado: false, kardexCardsMovimientos: false });
          },
          onAlmacenesByProducto: (skuClave) => {
               dispatch({ type: 'ALMACENES_BY_PRODUCTO_REQUEST', skuClave });
          },
          onProductosActivos: () => {
               dispatch({ type: 'LISTA_PRODUCTOS_ACTIVOS_REQUEST' });
          },
          onObtenerKardexProducto: (idAlmacen, claveSku, operacion, fechaInicio, fechaFin) => {
               dispatch({ type: 'KARDEX_OBTENER_PRODUCTOS_REQUEST', idAlmacen, claveSku, operacion, fechaInicio, fechaFin });
          },
          onObtenerKardexMovimientos: (idAlmacen, claveSku, operacion, fechaInicio, fechaFin, todosAlmacenes) => {
               dispatch({ type: 'KARDEX_OBTENER_MOVIMIENTOS_REQUEST', idAlmacen, claveSku, operacion, fechaInicio, fechaFin, todosAlmacenes });
          },
          onLimpiarTabla: () => {
               dispatch({ type: 'KARDEX_OBTENER_MOVIMIENTOS_SUCCESS', listaKardexMovimientos: [], dataMovimientosSumas: [], dataMovimientosRestas: []})
          },
          onReporteKardex: (kardexProducto, kardexMovimientos) => {
               dispatch({ type: 'KARDEX_REPORTE_REQUEST', kardexProducto, kardexMovimientos });
          }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(KardexContainer);