import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col, Divider, Modal, Button, Radio, DatePicker, Card, Table, Spin, Tooltip, Select} from 'antd';
import { Bar } from 'react-chartjs-2';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { BarChartOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';

const { Option } = Select;
const { RangePicker } = DatePicker;

const ModalPresentacionServicioUnitario = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [formularioState, setFormularioState] = useState([]);
    const [habilitarExportacion, setHabilitarExportacion] = useState(true);

    const showModalPresentacionServicioVariable = useSelector((state) =>state.ServiciosVariablesReducer.showModalPresentacionServicioVariable);
    const servicioPresentacionSeleccionado = useSelector((state) =>state.ServiciosVariablesReducer.servicioPresentacionSeleccionado);
    const page = useSelector((state) =>state.ServiciosVariablesReducer.page);
    const busqueda = useSelector((state) =>state.ServiciosVariablesReducer.busqueda);
    const serVariablesResponse = useSelector((state) =>state.ServiciosVariablesReducer.serVariablesResponse);
    const fetchingPresentacionSVPDF = useSelector((state) =>state.ServiciosVariablesReducer.fetchingPresentacionSVPDF);
    const dataGraficaUnitarioPSV = useSelector((state) =>state.ServiciosVariablesReducer.dataGraficaUnitarioPSV);
    const optionsGraficaPSV = useSelector((state) =>state.ServiciosVariablesReducer.optionsGraficaPSV);
    const listaUbicacionesFiltro = useSelector((state) =>state.CatalogosGeneralesReducer.listaUbicacionesFiltro);
    const fetchingListaUbicacionesFiltro = useSelector((state) =>state.CatalogosGeneralesReducer.fetchingListaUbicacionesFiltro);   

     useEffect(() => {
          if(showModalPresentacionServicioVariable==false){
               form.resetFields();
          }
          dispatch({ type: "LISTA_UBICACIONES_FILTRO_REQUEST", busqueda: 'adm'});
          dispatch({ type: "LISTA_SERVICIOS_ACTIVOS_REQUEST"});
     }, [form, showModalPresentacionServicioVariable]);    
    
     //funciones
     const onFinish = formulario => {
        console.log('formulario:', formulario);
        setHabilitarExportacion(false)
        setFormularioState(formulario)
        dispatch({ type: "PRESENTACION_SERVICIO_VARIABLES_REQUEST", formulario:formulario, comparativa:'Unitario', idVariable: servicioPresentacionSeleccionado.idVariable, idSubvariable: servicioPresentacionSeleccionado.idSubvariable, idServicioVariable:servicioPresentacionSeleccionado.idServicioVariable, page:1, label:[{servicio:servicioPresentacionSeleccionado.servicioVariable }, {subvariable: servicioPresentacionSeleccionado.subvariableByIdSubvariable.nombre}]})
     }; 
        
     const handleSearch = (param) =>{
          if(param){
               dispatch({ type: "LISTA_UBICACIONES_FILTRO_REQUEST", busqueda: param})
          }
     }

     const onExportar = () => {
          dispatch({ type: "EXPORTAR_EXCEL_SERVICIO_VARIABLES_REQUEST", serVariablesResponse, page, busqueda});
     };

     const onDescargarPDF = () =>{
          dispatch({ type: "PRESENTACION_SV_PDF_REQUEST", formulario: formularioState, rangeValue: formularioState.fechas, idServicioVariable: servicioPresentacionSeleccionado.idServicioVariable, page})
     }

     console.log("servicioPresentacionSeleccionado",servicioPresentacionSeleccionado)

     console.log("dataGraficaUnitarioPSV",dataGraficaUnitarioPSV, "optionsGraficaPSV",optionsGraficaPSV)

    return ( 
     <>
               <Spin spinning={fetchingPresentacionSVPDF}>
                    <Form 
                         name='formulario'
                         onFinish={onFinish}
                         preserve={false}
                         layout="vertical"
                         form={form} 
                    >
                        <Row>
                            <Col span={7} offset={1}>
                                    <Form.Item
                                        label="Periodo"
                                        name="fechas"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <RangePicker style={{ width: '100%' }} format={'DD/MM/YYYY'}/>
                                    </Form.Item>
                            </Col>
                            <Col span={8} offset={1}>
                                    <Form.Item
                                        label="Ubicación"
                                        name='idUbicacion'
                                    >
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona una ubicación"
                                            showSearch
                                            loading={fetchingListaUbicacionesFiltro}
                                            filterOption={false}
                                            onSearch={handleSearch}
                                        >
                                            {listaUbicacionesFiltro.map(option => <Option key={option.idUbicacion}> {option.todoUbicacion}</Option>)}
                                        </Select>
                                    </Form.Item>
                            </Col>
                            <Col span={1} offset={1} style={{paddingTop:'2.8em'}}>
                                    <Tooltip title='Ver Presentacion' placement="topRight">
                                        <Button className="iconTer" shape="circle"htmlType="submit" icon={<BarChartOutlined />}/>
                                    </Tooltip>
                            </Col>
                        </Row>
                         <Col span={24}>
                              <Row justify='center' id='grafico' className="sansserif" gutter={[8,8]}>
                                   <Col span={24} >
                                        <Card
                                             title={
                                                  <Row justify='space-around' gutter={[8,8]}>
                                                       <Col span={20}>
                                                            {'Subvariable: ' + servicioPresentacionSeleccionado.subvariableByIdSubvariable.nombre} 
                                                       </Col>
                                                       <Col span={1}>
                                                            <Tooltip title='Exportar archivo .xls' placement="topRight">
                                                                 <Button className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } onClick={onExportar} disabled={habilitarExportacion}/>
                                                            </Tooltip>
                                                       </Col>
                                                       <Col span={1}>
                                                            <Tooltip title='Exportar archivo .pdf' placement="topRight">
                                                                 <Button className="iconSecundary" shape='circle' icon={<FilePdfOutlined /> } onClick={onDescargarPDF} disabled={habilitarExportacion}/>
                                                            </Tooltip>
                                                       </Col> 
                                                  </Row>
                                             }
                                             className="listaCatalogos"
                                             bordered={true}
                                             style={{ align: 'center' }}
                                        >
                                             <Bar data={dataGraficaUnitarioPSV} options={optionsGraficaPSV} />
                                        </Card>
                                   </Col>
                              </Row>  
                         </Col>
                    </Form>
               </Spin>
        </>
    );
}
export default ModalPresentacionServicioUnitario;