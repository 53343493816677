import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, Select, Row } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalNuevoRol extends Component {
    formRef = React.createRef();
    render() {
        const { fetchingNuevoDominio, showModalNuevoDominio, onNuevoDominio, onShowModalNuevoDominio,
            limpiarFormulario, onLimpiarFormulario,
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onNuevoDominio(formulario, this.props.page);
        };
        
        if(limpiarFormulario && limpiarFormulario === 'crearDominio'){
            this.formRef.current.resetFields();
            onLimpiarFormulario();
        }

        const handleCancel = () => {
            onShowModalNuevoDominio();
            this.formRef.current.resetFields();
        }
          
        return (
            <Modal
            visible={showModalNuevoDominio}
            title='Nuevo Dominio'
            onCancel={handleCancel}
            footer={false}
            >
                <Spin spinning={fetchingNuevoDominio}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Row justify='space-between'>
                            <Col span={14}>
                                <Form.Item
                                    label="Nombre del dominio"
                                    name="dominio"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input addonBefore='@' maxLength={30} pattern="^(.[a-z]{1,10})+$" title="El valor de dominio debe ser letras minúsculas o números" placeholder="Ingresa el dominio" />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                               <Form.Item
                                    label='Termino del dominio'
                                    name='terminacion'
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input title="El valor de terminación debe ser letras minúsculas" pattern="^\.(.[comx]{1,2})+$" placeholder='.com/.mx/.com.mx' maxLength={15}/>
                                </Form.Item> 
                            </Col>
                        </Row>
                        <Col span={12}>
                            <Button className="btnFiltrar" htmlType="submit">Crear Dominio</Button>
                        </Col>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingNuevoDominio: state.InformacionOperativaReducer.fetchingNuevoDominio,
        showModalNuevoDominio: state.InformacionOperativaReducer.showModalNuevoDominio,
        page: state.InformacionOperativaReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalNuevoDominio: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_DOMINIO', showModalNuevoDominio: false, page: null })
        },
        onNuevoDominio: ( formulario, page ) => {
            dispatch({ type: "NUEVO_DOMINIO_REQUEST" , formulario: formulario, page: page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoRol);