import React, {Component} from 'react';
import {Modal, Steps, Divider, Row, Col, Card, Table, Alert, message, Button} from 'antd';
import {connect} from 'react-redux';
import {PlayCircleOutlined, FormOutlined, RedoOutlined, EditOutlined, LoadingOutlined, CheckCircleOutlined, StopOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ReasignarForm from './FormulariosSeguimiento/ReasignarForm';
import ProcesoForm from './FormulariosSeguimiento/ProcesoForm';
import EstadoTerminadoContainer from './EstadoTerminadoContainer';
import CierreForm from './FormulariosSeguimiento/CerradoForm';
import EsperaForm from './FormulariosSeguimiento/EsperaForm';
import CancelarForm from './FormulariosSeguimiento/CancelarForm';
import AbiertoForm from './FormulariosSeguimiento/AbiertoForm';
import TrasladoForm from './FormulariosSeguimiento/TrasladoForm';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css'
const { Step } = Steps;

class ModalSeguimientoFolioSS extends Component {
    formRef = React.createRef();
    state = {
        page: 1
    }
    render() {
        const { showModalOpcionesSeguimientoFolio, onCerrarModal, listaTrazabilidad, 
            fetchingListaTrazabilidad, onGetTrazabilidadFolio, listaTrazabilidadCantidad, 
            folioSolicitudServicioSeleccionado, currentStatus,onStatusSteps, 
            // onShowModalTraslado, listaTraslados, listaTrasladosCantidad, fetchingObtenerTraslados, onGetTrasladosFolio,
        } = this.props;
        let listaTrazabilidadValid = {}
        
        for (let i = 0; i < listaTrazabilidad.length; i++) {
            if (listaTrazabilidad[i] === listaTrazabilidad[0]) {
                console.log(listaTrazabilidad[i])
                listaTrazabilidadValid.estado = listaTrazabilidad[i].estado
                
            } 
            
        }
        // (listaTrazabilidadValid.estado === 'Asignado' && permisosUsuario.especialSolicitudDeServicio)
        //(!permisosUsuario.especialSolicitudDeServicio && !listaTrazabilidadValid.estado === 'Asignado')
        console.log('listaTrazabilidadValid :>> ', listaTrazabilidadValid);

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');

        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }


        const onChangeStep = value => {
            console.log('value', value)
            onStatusSteps(value);
        };
        

        const handleCancel = () => {
            onCerrarModal()
            this.props.onStatusSteps();
            this.props.onSiguienteVista(false, null, null, null, null, null );
            this.props.onCleanUsuariosByIdsServiciosTipoPYRS();
            this.props.onCleanUsuariosByIdsServiciosTipoAT();
            this.props.onCleanUsuariosByIdsServiciosTipoRS();
        };

        const handleTableChange = (pagination)=>{
            onGetTrazabilidadFolio(folioSolicitudServicioSeleccionado.folio, pagination.current);

            this.setState({page: pagination.current});
        };
        
        // const handleTableChangeTraslado = (pagination) => {
        //     onGetTrasladosFolio(folioSolicitudServicioSeleccionado, pagination.current)
        // }

        const columns = [{
            title: 'Fecha de Estado',
            dataIndex: 'fechaEstado',
            key: 'fechaEstado',
            align: 'center'
        },{
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            align: 'center'
        },{
            title: 'Fecha de Actualización',
            dataIndex: 'fechaActualizacion',
            key: 'fechaActualizacion',
            align: 'center'
        },{
            title: 'Usuario que registra',
            dataIndex: 'username',
            key: 'username',
            align: 'center'
        }];

        // const columnsTraslado = [{
        //     title: 'Fecha de Ruta',
        //     dataIndex: 'fechaEstado',
        //     key: 'fechaEstado',
        //     align: 'center'
        // },{
        //     title: 'Ubicacion',
        //     dataIndex: 'ubicacion',
        //     key: 'ubicacion',
        //     align: 'center'
        // },{
        //     title: 'Tecnico Responsable',
        //     dataIndex: 'tecnicoResponsable',
        //     key: 'tecnicoResponsable',
        //     align: 'center'
        // },{
        //     title: 'Observacion',
        //     dataIndex: 'observacion',
        //     key: 'observacion',
        //     align: 'center'
        // }];



        // const onChangeTrasladoModal = () => {
        //     onShowModalTraslado(folioSolicitudServicioSeleccionado);
        // }

        // const scroll = {"x": 700 | true, "y": 1000}; 
        
        
        return (
            <div>
            {folioSolicitudServicioSeleccionado ? (
                <Modal
                visible={showModalOpcionesSeguimientoFolio}
                title={`Seguimiento Folio Solicitud de Servicio - Folio: ${folioSolicitudServicioSeleccionado.folio} - Estado Actual: ${folioSolicitudServicioSeleccionado.estado} `}
                onCancel={handleCancel}
                footer={false}
                width='95%'
            >
                {permisosUsuario.especialSolicitudDeServicio ? (
                <Steps
                    type="navigation"
                    current={currentStatus}
                    onChange={onChangeStep}
                    className="site-navigation-steps"
                > 
                    <Step icon={<PlayCircleOutlined />} title="Inicio" />
                    <Step icon={<FormOutlined />} title="Asignado"   disabled={listaTrazabilidadValid.estado === 'Asignado'}/>
                    {/* <Step title="Traslado"  /> */}
                    <Step icon={<RedoOutlined />} title="Proceso"   disabled={listaTrazabilidadValid.estado === 'En Proceso'}/>
                    <Step icon={<EditOutlined />} title="Reasignar" />
                    <Step icon={<LoadingOutlined />} title="En espera" disabled={listaTrazabilidadValid.estado === 'En Espera'}/>
                    <Step icon={<CheckCircleOutlined />} title="Realizado" disabled={listaTrazabilidadValid.estado === 'Realizado'} />
                    <Step icon={<StopOutlined />} title="Cerrado"   disabled={listaTrazabilidadValid.estado === 'Cerrado'} />
                    <Step icon={<CloseCircleOutlined />} title="Cancelado" disabled={listaTrazabilidadValid.estado === 'Cancelado'} />
                </Steps>
                ):(
                <Steps
                    type="navigation"
                    current={currentStatus}
                    onChange={onChangeStep}
                    className="site-navigation-steps"
                    
                > 
                    <Step icon={<PlayCircleOutlined />} title="Inicio" />
                    <Step icon={<FormOutlined />} title="Asignado" disabled={permisosUsuario.estadoAsignadoSolicitudServicio ? ((folioSolicitudServicioSeleccionado.estado === "En Proceso" || folioSolicitudServicioSeleccionado.estado === "En Espera" || folioSolicitudServicioSeleccionado.estado === "Realizado" || folioSolicitudServicioSeleccionado.estado === "Cerrado"  || folioSolicitudServicioSeleccionado.estado === "Asignado" || folioSolicitudServicioSeleccionado.estado === "Traslado" ) && !permisosUsuario.especialSolicitudDeServicio) : true } />
                    {/* <Step title="Traslado" disabled={(folioSolicitudServicioSeleccionado.estado === "En Proceso" || folioSolicitudServicioSeleccionado.estado === "Cerrado" || folioSolicitudServicioSeleccionado.estado === "Realizado" ) && !permisosUsuario.especialSolicitudDeServicio} /> */}
                    <Step icon={<RedoOutlined />} title="Proceso" disabled={permisosUsuario.estadoProcesoSolicitudServicio ? ((folioSolicitudServicioSeleccionado.estado === "Abierto"  || folioSolicitudServicioSeleccionado.estado === "En Proceso"|| folioSolicitudServicioSeleccionado.estado === "Cerrado" || folioSolicitudServicioSeleccionado.estado === "Realizado" || folioSolicitudServicioSeleccionado.estado === "Cancelado" ) && !permisosUsuario.especialSolicitudDeServicio) : true} />
                    <Step icon={<EditOutlined />} title="Reasignar" disabled={permisosUsuario.estadoReasignarSolicitudServicio ? ((folioSolicitudServicioSeleccionado.estado === "En Espera" || folioSolicitudServicioSeleccionado.estado === "Cancelado" || folioSolicitudServicioSeleccionado.estado === "Traslado" || folioSolicitudServicioSeleccionado.estado === 'Reasignado') && !permisosUsuario.especialSolicitudDeServicio) : true }/>
                    <Step icon={<LoadingOutlined />} title="En espera" disabled={permisosUsuario.estadoEsperaSolicitudServicio ? ((folioSolicitudServicioSeleccionado.estado === "Abierto"  || folioSolicitudServicioSeleccionado.estado === "Cerrado" || folioSolicitudServicioSeleccionado.estado === "En Espera" ||folioSolicitudServicioSeleccionado.estado === "Realizado" || folioSolicitudServicioSeleccionado.estado === "Cancelado" || folioSolicitudServicioSeleccionado.estado === "Asignado") && !permisosUsuario.especialSolicitudDeServicio) : true }/>
                    <Step icon={<CheckCircleOutlined />} title="Realizado" disabled={permisosUsuario.estadoRealizadoSolicitudServicio ? ((folioSolicitudServicioSeleccionado.estado === "Realizado" || folioSolicitudServicioSeleccionado.estado === "Abierto" || folioSolicitudServicioSeleccionado.estado === "Cerrado" || folioSolicitudServicioSeleccionado.estado === "En Espera" || folioSolicitudServicioSeleccionado.estado === "Cancelado" || folioSolicitudServicioSeleccionado.estado === "Asignado" || folioSolicitudServicioSeleccionado.estado === "Traslado")  && !permisosUsuario.especialSolicitudDeServicio) : true} />
                    <Step icon={<StopOutlined />} title="Cerrado" disabled={permisosUsuario.estadoCerradoSolicitudServicio ? ((folioSolicitudServicioSeleccionado.estado === "Abierto"  || folioSolicitudServicioSeleccionado.estado === "Cerrado" || folioSolicitudServicioSeleccionado.estado === "En Proceso" || folioSolicitudServicioSeleccionado.estado === "En Espera" || folioSolicitudServicioSeleccionado.estado === "Cancelado" || folioSolicitudServicioSeleccionado.estado === "Traslado" || folioSolicitudServicioSeleccionado.estado === "Asignado" ) && !permisosUsuario.especialSolicitudDeServicio) : true } />
                    <Step icon={<CloseCircleOutlined />} title="Cancelado" disabled={permisosUsuario.estadoCanceladoSolicitudServicio ? ((folioSolicitudServicioSeleccionado.estado === "En Espera" || folioSolicitudServicioSeleccionado.estado === "Cancelado" ) && !permisosUsuario.especialSolicitudDeServicio) : true }/>
                </Steps>
                )}
                <Divider />
                <Row justify="space-between">
                    <Col span={12}>
                        <Card className="cardCatalogos" >
                            
                            { currentStatus == 0 || currentStatus === undefined ? (
                                <div><Alert message="Inicia seguimiento del folio " type="info"/></div>
                            ) : false}
                            {currentStatus == 1  ? (
                                <AbiertoForm />
                            ) : false}
                            {/* {currentStatus == 2  ? (
                                <>
                                <Button className="btnFiltrar" style={{width: '100%'}} onClick={onChangeTrasladoModal}>Agregar Ruta de Traslado</Button>
                                <Col>
                                  <br></br>
                                  <Table
                                    size="small"
                                    rowKey="idTrasladoFolio"
                                    columns={columnsTraslado}
                                    dataSource={listaTraslados}
                                    loading={fetchingObtenerTraslados}
                                    locale={{ emptyText: 'Sin datos' }}
                                    onChange={handleTableChangeTraslado}
                                    pagination={{ total: listaTrasladosCantidad, simple: true }}
                                    scroll={scroll}
                                 />
                                </Col>
                                </>
                            ) : false} */}
                            {currentStatus == 2  ? (
                                <ProcesoForm />
                            ) : false}
                            {currentStatus == 3 ? (
                                <ReasignarForm />
                            ) : false}
                            {currentStatus == 4 ? (
                                <EsperaForm />
                            ) : false}
                            {currentStatus == 5   ? (
                                 <EstadoTerminadoContainer />
                            ) : false}
                            {currentStatus == 6 ? (
                                 <CierreForm />
                            ) : false}
                            {currentStatus == 7 ? (
                                <CancelarForm />
                            ) : false}
                        </Card>
                    </Col>
                    <Col span={11}>
                        <Card className="cardCatalogos" title={<div className='headerLista'>Historial de Actualización Folio</div>}>
                             <br />
                                <Table
                                    size="small"
                                    rowKey="idTrazabilidadFolio"
                                    columns={columns}
                                    dataSource={listaTrazabilidad}
                                    loading={fetchingListaTrazabilidad}
                                    locale={{ emptyText: 'Sin datos' }}
                                    onChange={handleTableChange}
                                    pagination={{ total: listaTrazabilidadCantidad, simple: true, current: this.state.page}}
                                />
                        </Card>
                    </Col>
                </Row>
                <TrasladoForm/>
            </Modal>
            ): false}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showModalOpcionesSeguimientoFolio: state.SolicitudDeServiciosReducer.showModalOpcionesSeguimientoFolio,
        folioSolicitudServicioSeleccionado: state.SolicitudDeServiciosReducer.folioSolicitudServicioSeleccionado,
        listaTrazabilidad: state.SolicitudDeServiciosReducer.listaTrazabilidad,
        fetchingListaTrazabilidad: state.SolicitudDeServiciosReducer.fetchingListaTrazabilidad,
        listaTrazabilidadCantidad: state.SolicitudDeServiciosReducer.listaTrazabilidadCantidad,
        page: state.SolicitudDeServiciosReducer.page,
        currentStatus: state.SolicitudDeServiciosReducer.currentStatus,
        listaTraslados: state.SolicitudDeServiciosReducer.listaTraslados,
        fetchingListaTrazabilidad: state.SolicitudDeServiciosReducer.fetchingListaTrazabilidad,
        listaTrasladosCantidad: state.SolicitudDeServiciosReducer.listaTrasladosCantidad,
    }
} 

const mapDispatchToProps = (dispatch) => {
    return {
        onCerrarModal: () => {
            dispatch({ type: "SHOW_MODAL_OPCIONES_SEGUIMIENTO_FOLIO_SS", showModalOpcionesSeguimientoFolio: false, folioSolicitudServicioSeleccionado: null})
        },
        onGetTrazabilidadFolio: (folio, page) => {
            dispatch({ type: "OBTENER_TRAZABILIDAD_FOLIO_REQUEST", folio, page});
        },
        onStatusSteps: (currentStatus) => {
            dispatch({ type: "CURRENT_STATUS_SEGUIMIENTO", currentStatus});
        },
        onStatusStepsModal: () => {
            dispatch({ type: "CURRENT_STATUS_SEGUIMIENTO" , current: 0})
        },
        onSiguienteVista: (cambiarVistaModalEstadoTerminado, formularioTerminado, listaEpp, listaHerramienta, listaInsumosT, folioSolicitudServicioSeleccionado) => {
            dispatch({ type: "CAMBIAR_VISTA_MODAL_ESTADO_TERMINADO", cambiarVistaModalEstadoTerminado, formularioTerminado, listaEpp, listaHerramienta, listaInsumosT, folioSolicitudServicioSeleccionado});
        },
        onShowModalTraslado: (folioSeleccionadoTraslado) => {
            dispatch({ type: "SHOW_MODAL_TRASLADO", showModalTraslado: true, folioSeleccionadoTraslado });
        },
        onGetTrasladosFolio: (folioSolicitudServicioSeleccionado, page) => {
            dispatch({ type: "LISTA_TRASLADOS_REQUEST", folioSolicitudServicioSeleccionado, page})
        },
        onCleanUsuariosByIdsServiciosTipoPYRS: () => {
            dispatch({ type: 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_PYRS_SUCCESS', listaUsuariosByIdsServiciosTipoPYRS: []  });
        },
        onCleanUsuariosByIdsServiciosTipoRS: () => {
            dispatch({ type: 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_RS_SUCCESS', listaUsuariosByIdsServiciosTipoRS: []  });
        },
        onCleanUsuariosByIdsServiciosTipoAT: () => {
            dispatch({ type: 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_AT_SUCCESS', listaUsuariosByIdsServiciosTipoAT: []  });
        },
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ModalSeguimientoFolioSS);
 