import React, {Component} from 'react'
import { Modal, Row, Col, List, Button, Spin } from 'antd';
import {ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

class ModalDetalleRegistroEncuesta extends Component {
    state={
        pageNumber: 1,
        numPages: null,
    }
    render() { 
        const {onShowModalDetalleFolio, folioSeleccionado, showModalDetalleFolio,
        } = this.props;

        const handleCancel = () => {
            onShowModalDetalleFolio();
            this.setState({pageNumber: 1});
        }

        const onDocumentLoadSuccess = ({ numPages }) => {
            this.setState({numPages})
        }

        const cambiarPagina = (tipo) => {
            console.log('tipo', tipo)
            if(tipo === 'atras'){
                this.setState({pageNumber: this.state.pageNumber-1})
            }
            else{
                this.setState({pageNumber: this.state.pageNumber+1})
            }
        }
        return ( 
            <Modal
                visible={showModalDetalleFolio}
                title='Detalle del Folio'
                onCancel={handleCancel}
                footer={false}
                width={1450}
            >
                {folioSeleccionado ? (
                <Row justify='space-around'>
                    <Col span={24}>
                        <h3>Calificación: <strong>{folioSeleccionado.calificacion}</strong></h3>
                    </Col>
                    <Col span={24}>
                        <h3>Lugar de encuesta : <strong>{folioSeleccionado.ubicacion}</strong></h3>
                    </Col>
                    <Col span={9}>
                        <List
                            className="listaCatalogos"
                            bordered
                            dataSource={folioSeleccionado.preguntasEncuestasByIdFolio}
                            renderItem={item => (
                               <List.Item className='listaHover'>
                                    <Col span={18}>
                                        <strong>{item.pregunta}</strong>  
                                    </Col>
                                    <Col span={5}>
                                        {item.ponderacionesEncuestas[0].etiqueta} 
                                    </Col>
                                </List.Item>
                            )}
                        />
                    </Col>
                    <Col span={13}>
                        <>
                            <Row justify='space-around'>
                                <Col span={24}>
                                    <Document
                                        loading={<Spin spinning={true}/>}
                                        file={folioSeleccionado.rutaEvidencia} onLoadSuccess={onDocumentLoadSuccess}>
                                        
                                        <Page pageNumber={this.state.pageNumber} width={750} />
                                    </Document>
                                </Col>
                            </Row>
                            <br/>
                            <Row justify='space-around'>
                                <Col span={5}>
                                    <p>
                                        Página {this.state.pageNumber} de {this.state.numPages}
                                    </p>
                                </Col>
                                <Col span={2}>
                                    <Button className="iconTer" shape="circle" disabled={this.state.pageNumber === 1} onClick={()=>cambiarPagina('atras')} icon={<ArrowLeftOutlined />}/>
                                </Col>
                                <Col span={2}>
                                    <Button className="iconTer" shape="circle" disabled={this.state.pageNumber === this.state.numPages} onClick={()=>cambiarPagina('adelante')} icon={<ArrowRightOutlined />}/>
                                </Col>
                            </Row>
                        </>
                    </Col>
                </Row>
                ):false}
            </Modal>
        );
    }
}
 
const mapStateToProps = state => {
	return {
        showModalDetalleFolio: state.GestionDeCalidadReducer.showModalDetalleFolio,
        folioSeleccionado: state.GestionDeCalidadReducer.folioSeleccionado,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalDetalleFolio: () => {
            dispatch ({ type: 'MODAL_DETALLE_FOLIO', showModalDetalleFolio: false, folioSeleccionado: null })
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetalleRegistroEncuesta);