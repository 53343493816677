import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, Select, DatePicker, Row, Radio, Divider, Popover, message, Tooltip, notification } from 'antd';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const { Option } = Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalEditarProveedor extends Component {
    formRef = React.createRef();

    componentDidMount(){
        if(this.props.proveedorSeleccionado){
            this.props.onGetListaServicios(this.props.proveedorSeleccionado.idProveedor);
        }
    }

    state = {
        rangoVigencia: '',
        rfcValido: false,

        showProvPartida: true,
        asignado: 'Todo',
        idsServiciosSeleccionados: [],
        executed: false,
        partidasLocal: [],
        executedAsginado: false,
    }
    render() {
        const { fetchingEditarProveedor, showModalEditarProveedor, onEditarProveedor, onShowModalEditarProveedor,
            listaDominiosActivos, fetchingListaDominiosActivos, listaContratos, fetchingListaExpedientes,
            listaExpedientes, listaServiciosActivosForProveedor, fetchingListaServiciosActivosForProveedor,limpiarFormulario, onLimpiarFormulario, proveedorSeleccionado,

            listaAreasProveedores, fetchingListaAreasProveedores, onObtenerPartidasByidServicio, listaPartidasByServicio, fetchingListaPartidasByServicio, onLimpiarListaServicios, onLimpiarPartidas, listaPartidasByServicioWithout, actualizarAsig, onGetAreasByServicioProveedor, onLimpiarAreasByServicioProveedor, listaServiciosActivosF, listaAreasFuncionalesActivas, fetchingListaAreasFuncionalesActivas
        } = this.props;

        if(proveedorSeleccionado){
            if (this.state.executed === false) {
                this.setState({executed : true});
                let idsServicios = [];

                if(proveedorSeleccionado.servicios){
                    let partidas = [];
                    for(let i in proveedorSeleccionado.servicios){
                        idsServicios.push(parseInt(proveedorSeleccionado.servicios[i]));

                        let concatPart = 'partida-' + parseInt(proveedorSeleccionado.servicios[i]);
                        console.log('concatPart', concatPart)
                        if(proveedorSeleccionado[concatPart] !== undefined){
                            partidas.push(parseInt(proveedorSeleccionado[concatPart]));
                        }
                        this.setState({ partidasLocal: partidas });
                    }
                }

                onObtenerPartidasByidServicio(idsServicios, listaServiciosActivosForProveedor, proveedorSeleccionado.idProveedor, listaServiciosActivosF);

                for(let i in listaServiciosActivosF){
                    for(let j in proveedorSeleccionado.servicios){
                        if(listaServiciosActivosF[i].idServicio === parseInt(proveedorSeleccionado.servicios[j])){
                            let conctAsig = 'asignado-' + listaServiciosActivosF[i].idServicio;
                            console.log('conctAsig', conctAsig);

                            if(proveedorSeleccionado[conctAsig] === 'Todo'){
                                let concatArea = 'area-' + listaServiciosActivosF[i].idServicio;
                                let concatPartida = 'partida-' + listaServiciosActivosF[i].idServicio;
                                console.log('concatArea', concatArea);

                                proveedorSeleccionado[concatArea] = undefined;
                                proveedorSeleccionado[concatPartida] = undefined;

                            } else if(proveedorSeleccionado[conctAsig] === 'Partida'){
                                let concatArea = 'area-' + listaServiciosActivosF[i].idServicio;

                                proveedorSeleccionado[concatArea] = undefined;
                            } else if (proveedorSeleccionado[conctAsig] === 'Area'){
                                let concatPartida = 'partida-' + listaServiciosActivosF[i].idServicio;

                                proveedorSeleccionado[concatPartida] = undefined;
                            }
                        }
                    }
                }
            }
        }
   
        const onFinish = formulario => {
            console.log('Success:', formulario);
            let listaAsignacionProveedor = [];

                for (var key in formulario) {
                    if (Object.prototype.hasOwnProperty.call(formulario, key)) {
                        let idServicio = key.split('-');
                        if (idServicio.length === 2) {
                            let idCaptura = idServicio[1];
                            let campo = idServicio[0];
                            let valor = formulario[key];
        
                            let capturaFormulario = {[campo]: valor, 'idServicio': parseInt(idCaptura)};
                            listaAsignacionProveedor.push(capturaFormulario);
                        }
                    }
               }

            if(this.state.rangoVigencia){
                const rangeValue = this.state.rangoVigencia;
                if(rangeValue){
                    formulario.rangoVigencia = rangeValue[0].format('YYYY-MM-DD') + "," + rangeValue[1].format('YYYY-MM-DD');
                    this.setState({executed : false, showProvPartida: true, partidasLocal: [], executedAsginado: false});

                    onEditarProveedor(formulario, proveedorSeleccionado, listaDominiosActivos, listaExpedientes, listaContratos, listaServiciosActivosForProveedor, this.props.page, listaPartidasByServicioWithout, listaAsignacionProveedor, listaAreasFuncionalesActivas);
                }
            }
            else{
                this.setState({executed : false, showProvPartida: true, partidasLocal: [], executedAsginado: false});
                formulario.rangoVigencia = proveedorSeleccionado.fechaInicio.split('-')[2] + '-' + proveedorSeleccionado.fechaInicio.split('-')[1] + '-' + proveedorSeleccionado.fechaInicio.split('-')[0] + ',' + proveedorSeleccionado.fechaFin.split('-')[2] + '-' + proveedorSeleccionado.fechaFin.split('-')[1] + '-' + proveedorSeleccionado.fechaFin.split('-')[0];
                onEditarProveedor(formulario, proveedorSeleccionado, listaDominiosActivos, listaExpedientes, listaContratos, listaServiciosActivosForProveedor, this.props.page, listaPartidasByServicioWithout, listaAsignacionProveedor, listaAreasFuncionalesActivas);
            }
        };
        

        if (limpiarFormulario && limpiarFormulario === 'editarActivo') {
            this.formRef.current.resetFields();
            onLimpiarFormulario();
            //onLimpiarListaServicios();
            onLimpiarPartidas();
        }

        const handleCancel = () => {
            onShowModalEditarProveedor();
            onLimpiarPartidas();
            this.setState({executed : false, showProvPartida: true, partidasLocal: [], executedAsginado: false});
        }
        const onChanegFechas = (rangoVigencia) => {
            this.setState({
                rangoVigencia: rangoVigencia,
            })
        }
        const cambiarPersona = (persona) => {
            proveedorSeleccionado.persona = persona.target.value;
            this.formRef.current.setFieldsValue({
                rfc: '',
            });
        }

        const onServicioSel = (value) => {
            if(value[0]){
                onObtenerPartidasByidServicio(value, listaServiciosActivosForProveedor, proveedorSeleccionado.idProveedor, listaServiciosActivosF);

                let serviciosLocal = [];
                let nombreServiciosLocal = [];
                let serviciosArray = [];
                for(let i = 0; i < value.length; i++){
                    serviciosLocal.push(parseInt(value[i]));
                    this.formRef.current.resetFields(['partida-' + value[i].split(' - ')[0], 'area-' + value[i].split(' - ')[0]]);
                    this.formRef.current.setFieldsValue({['asignado-' + value[i].split(' - ')[0]]: 'Todo'})
                    for(let j = 0; j < listaServiciosActivosForProveedor.length; j++){
                        if(parseInt(value[i]) === listaServiciosActivosForProveedor[j].idServicio){
                            nombreServiciosLocal.push(listaServiciosActivosForProveedor[j].nombre);
                            serviciosArray.push(listaServiciosActivosForProveedor[j]);

                            let showMSG = false;
                            let proveedores = [];
                            let servicio = '';

                            for(let j in listaServiciosActivosForProveedor){
                                if(parseInt(value[i]) === listaServiciosActivosForProveedor[j].idServicio){
                                    if(listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio){
                                        showMSG = true;
                                        servicio = listaServiciosActivosForProveedor[j].nomenclatura + ' - ' + listaServiciosActivosForProveedor[j].nombre;
                                        for(let k in listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio){
                                            proveedores.push(' ' + listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio[k].proveedorByIdProveedor.nombre + ', ');
                                        }
                                    }
                                }
                            }

                            let uniqueProveedores = proveedores.reduce(function(a,b){
                                if (a.indexOf(b) < 0 ) a.push(b);
                                return a;
                            },[]);

                            while(showMSG){
                                // message.info(
                                //     <p> El/Los proveedor/es: <b>{uniqueProveedores}</b> ya se le han asignado áreas del servicio <b>{servicio}</b>. Si se genera la asignación a este proveedor el o los anteriores las perderan</p>, 10
                                // );

                                notification.warning({
                                    duration: 5,
                                    placement: 'topLeft',
                                    message: servicio,
                                    description:
                                        <p> El/Los proveedor/es: <b>{uniqueProveedores}</b> ya se le han asignado áreas de este servicio. Si se genera la asignación a este proveedor el o los anteriores las perderan.</p>,
                                });
                                showMSG = false
                            }
                        }
                    }
                }

                this.setState({ showProvPartida: true, idsServiciosSeleccionados: serviciosLocal, servSeleccionandos: serviciosArray });

            } else {
                this.setState({ showProvPartida: false });
            }
        };

        const cambiarAsignacion = (asignado, idServicio, key) => {
            let listaPartidasActualizada = listaPartidasByServicio;
            listaPartidasActualizada[key][0].asignado = asignado.target.value;
            actualizarAsig(listaPartidasActualizada, listaPartidasByServicioWithout);

            console.log('listaPartidasActualizada', listaPartidasActualizada);
            if(asignado.target.value === 'Todo'){
                this.formRef.current.resetFields(['partida-' + idServicio, 'area-' + idServicio]);
                this.formRef.current.setFieldsValue({['partida-' + idServicio]: undefined, ['area-' + idServicio]: undefined});
                console.log('Entra a opción TODOS')
                console.log('listaServiciosActivosForProveedor', listaServiciosActivosForProveedor)

                let showMSG = false;
                let proveedores = [];
                let servicio = '';

                for(let j in listaServiciosActivosForProveedor){
                    if(idServicio === listaServiciosActivosForProveedor[j].idServicio){
                        if(listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio){
                            showMSG = true;
                            servicio = listaServiciosActivosForProveedor[j].nomenclatura + ' - ' + listaServiciosActivosForProveedor[j].nombre;
                            for(let k in listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio){
                                proveedores.push(' ' + listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio[k].proveedorByIdProveedor.nombre + ', ');
                            }
                        }
                    }
                }

                let uniqueProveedores = proveedores.reduce(function(a,b){
                    if (a.indexOf(b) < 0 ) a.push(b);
                    return a;
                  },[]);

                while(showMSG){
                    // message.info(
                    //     <p> El/Los proveedor/es: <b>{uniqueProveedores}</b> ya se le han asignado áreas del servicio <b>{servicio}</b>. Si se genera la asignación a este proveedor el o los anteriores las perderan</p>, 10
                    // );
                    notification.warning({
                        duration: 5,
                        placement: 'topLeft',
                        message: servicio,
                        description:
                            <p> El/Los proveedor/es: <b>{uniqueProveedores}</b> ya se le han asignado áreas de este servicio. Si se genera la asignación a este proveedor el o los anteriores las perderan.</p>,
                    });
                    showMSG = false
                }

            } else if(asignado.target.value === 'Partida'){
                this.formRef.current.setFieldsValue({['area-' + idServicio]: undefined});
                //this.formRef.current.resetFields(['area-' + idServicio]);
                if(!listaPartidasByServicio[0]){
                    message.warning('El o los servicios seleccionados no cuentan con partidas.');
                }
            } else if(asignado.target.value === 'Area'){
                // onGetAreasByServicioProveedor(idServicio, proveedorSeleccionado.idProveedor, partidasID);
                //this.formRef.current.resetFields(['partida-' + idServicio]);
                this.formRef.current.setFieldsValue({['partida-' + idServicio]: undefined});
            }

            this.setState({ asignado: asignado.target.value });
        };

        if(listaPartidasByServicio.length >= 1){
            if (this.state.executedAsginado === false) {
                for(let i in listaPartidasByServicio){
                    if(proveedorSeleccionado){
                        for(let j in proveedorSeleccionado.servicios){
                            if(listaPartidasByServicio[i][0].idServicio === parseInt(proveedorSeleccionado.servicios[j])){
                                let concatAsig = 'asignado-' + parseInt(proveedorSeleccionado.servicios[j]);
                                listaPartidasByServicio[i][0].asignado = proveedorSeleccionado[concatAsig];

                                console.log('listaPartidasByServicio en área', listaPartidasByServicio)
                                actualizarAsig(listaPartidasByServicio, listaPartidasByServicioWithout);
                            }
                        }
                    }
                }
                this.setState({ executedAsginado: true});
            }
        }

        const onPartidasSeleccionadas = (value, idServicio, key) => {
            let asignacionPartida = false;
            let proveedor = [];
            let partida = [];

            if(value){
                for(let i in value){
                    for(let j in listaServiciosActivosForProveedor){
                        if(idServicio === listaServiciosActivosForProveedor[j].idServicio){
                            if(listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio){
                                for(let k in listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio){
                                    if(listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio[k].idPartida === parseInt(value[i])){
                                        if(listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio[k].asignacion === "Partida"){
                                            asignacionPartida = true;
                                            proveedor.push(' ' + listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio[k].proveedorByIdProveedor.nombre + ', ');
                                            partida.push(' ' + listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio[k].partidaByIdPartida.nombre + ', ')
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                let uniquePartidas = partida.reduce(function(a,b){
                    if (a.indexOf(b) < 0 ) a.push(b);
                    return a;
                },[]);

                let uniqueProveedores = proveedor.reduce(function(a,b){
                    if (a.indexOf(b) < 0 ) a.push(b);
                    return a;
                },[]);

                while(asignacionPartida === true){
                    message.info(
                        <p> La/Las partida/s: <b>{uniquePartidas}</b> ya se encuentran en uso por el/los proveedores: <b>{uniqueProveedores}</b> si se genera la asignación a este proveedor los anteriores las perderan</p>, 10
                    );
                    asignacionPartida = false
                }

            }
        };

        const onAreasSeleccionadas = (value, idServicio, key) => {

            let asignacionArea = false;
            let proveedor = [];
            let area = [];
            if(value){
                for(let i in value){
                    for(let j in listaServiciosActivosForProveedor){
                        if(idServicio === listaServiciosActivosForProveedor[j].idServicio){
                            if(listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio){
                                for(let k in listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio){
                                    if(listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio[k].idAreaFuncional === parseInt(value[i])){
                                        asignacionArea = true;
                                        proveedor.push(' ' + listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio[k].proveedorByIdProveedor.nombre + ', ');
                                        area.push(' ' + listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio[k].areaFuncionalByIdAreaFuncional.areaFuncional + ', ');
                                    }
                                }
                            }
                        }
                    }
                }

                let uniqueAreas = area.reduce(function(a,b){
                    if (a.indexOf(b) < 0 ) a.push(b);
                    return a;
                },[]);

                let uniqueProveedores = proveedor.reduce(function(a,b){
                    if (a.indexOf(b) < 0 ) a.push(b);
                    return a;
                },[]);

                while(asignacionArea === true){
                    message.info(
                        <p> El/Las área/s: <b>{uniqueAreas}</b> ya se encuentra en uso por el/los proveedor/es: <b>{uniqueProveedores}</b> si se genera la asignación a este proveedor los anteriores las perderan</p>, 10
                    );
                    asignacionArea = false
                }

            }
        };

        const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

        return (
            <Modal
            visible={showModalEditarProveedor}
            title='Editar Proveedor'
            onCancel={handleCancel}
            footer={false}
            width={'70%'}
            >
                <Spin spinning={fetchingEditarProveedor}>
                    {proveedorSeleccionado ? (
                    <Form {...layout}
                    name="formulario"
                    initialValues={proveedorSeleccionado}
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Persona"
                                    name="persona"
                                >
                                    <Radio.Group onChange={cambiarPersona} defaultValue={proveedorSeleccionado.persona}>
                                        <Radio value='fisica'>Física</Radio>
                                        <Radio value='moral'>Moral</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            {proveedorSeleccionado.persona === 'fisica' ? (
                            <Col span={11}>
                                <Form.Item
                                    label="RFC"
                                    name="rfc"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={13} minLength={13}
                                    placeholder="Ingresa el RFC" 
                                    pattern='^([A-ZÑ&]{4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$'
                                    title='No es un formato válido para RFC persona física'/>
                                </Form.Item>
                            </Col>
                            ):(
                            <Col span={11}>
                                <Form.Item
                                    label="RFC"
                                    name="rfc"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={12}
                                    minLength={12} placeholder="Ingresa el RFC"
                                    pattern='^([A-ZÑ&]{3}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$'
                                    title='No es un formato válido para RFC persona moral'/>
                                </Form.Item>
                            </Col>
                            )}
                            <Col span={11}>
                                <Form.Item
                                    label="Razón social"
                                    name="razonSocial"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input placeholder="Ingresa la razón social" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Nombre de contacto"
                                    name="nombre"
                                >
                                    <Input placeholder="Ingresa el nombre de contacto" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Correo electrónico de contacto"
                                    name="correo"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', type: 'email' }]}
                                >
                                    <Input placeholder="Ingresa el correo electrónico de contacto" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Dominio"
                                    name="dominios"
                                    //rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select mode="multiple"
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el dominio"
                                        loading={fetchingListaDominiosActivos}
                                    >
                                        {listaDominiosActivos.map(option => <Option defaultValue={proveedorSeleccionado.dominios} key={option.idDominio + ' - ' + option.dominioCompleto}>{option.idDominio + ' - ' +  option.dominioCompleto }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Rango de vigencia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <RangePicker format = 'DD-MM-YYYY' placeholder={["Fecha Inicio","Fecha Fin"]} style={{width: '100%'}} onChange={onChanegFechas} defaultValue={[moment(proveedorSeleccionado.fechaInicio, 'DD-MM-YYYY'), moment(proveedorSeleccionado.fechaFin, 'DD-MM-YYYY')]}/>
                                </Form.Item>
                            </Col>


                            <Col span={11}>
                                <Form.Item
                                    label="Clave de expediente"
                                    name="nombreExpediente"
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona la clave de expediente"
                                        loading={fetchingListaExpedientes}
                                    >
                                        {listaExpedientes.map(option => <Option defaultValue={proveedorSeleccionado.nombreExpediente} key={option.clave}>{ option.clave }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={23}>
                                <Spin spinning={this.props.fetchingListaServiciosActivosForProveedor}>
                                    <Form.Item
                                        label="Servicio"
                                        name="servicios"
                                        rules={[{ required: true, message: 'Favor de seleccionar una opción', }]}
                                    >
                                        <Select 
                                            mode="multiple"
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el servicio"
                                            loading={fetchingListaServiciosActivosForProveedor}
                                            onChange={onServicioSel}
                                        >
                                            {listaServiciosActivosForProveedor.map(option => <Option defaultValue={proveedorSeleccionado.servicios} key={option.idServicio + ' - ' + option.nombre}>{ option.idServicio + ' - ' + option.nombre }</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Spin>
                            </Col>

{/********************************************************************************************************************/}
                    
                    <Col span={23}>
                    <Spin spinning={fetchingListaPartidasByServicio}>
                        { listaPartidasByServicio && this.state.showProvPartida ? (<> 
                            { listaPartidasByServicio.map((index, key) =>
                            <Row justify='space-around' key={index[0].idServicio}>
                            { index[0].servicioByIdServicio ? (<>
                            { index[0].idPartida !== null ? (
                                <Divider orientation='left' style={{fontSize: 14}}>Asignación proveedores-partidas-áreas:  <b style={{color:'#1747ca'}}>{ index[0].servicioByIdServicio.nombre }</b></Divider>
                            ): (
                                <Divider orientation='left' style={{fontSize: 14}}>Asignación proveedores-áreas:  <b style={{color:'#1747ca'}}>{ index[0].servicioByIdServicio.nombre }</b></Divider>
                            )}

                            </>):false }

                            { index[0].idPartida !== null ? (
                                <Col span={6}>
                                    <Form.Item
                                        label="Asignado por"
                                        name={"asignado-" + index[0].idServicio}
                                    >
                                        <Radio.Group onChange={(e) => cambiarAsignacion(e, index[0].idServicio, key)} defaultValue={'Todo'}>
                                            <Radio value={'Todo'}>Todo</Radio>
                                            <Radio value={'Partida'}>Partida</Radio>
                                            <Radio value={'Area'}>Área</Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                </Col>
                            ):(
                                <Col span={6}>
                                    <Form.Item
                                        label="Asignado por"
                                        name={"asignado-" + index[0].idServicio}
                                    >
                                        <Radio.Group onChange={(e) => cambiarAsignacion(e, index[0].idServicio, key)} defaultValue={'Todo'}>
                                            <Radio value={'Todo'}>Todas</Radio>
                                            <Radio value={'Area'}>Área</Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                </Col>
                            ) }

                            { index[0].idPartida !== null ? (
                                <Col span={8}>
                                    <Form.Item
                                        label='Partida'
                                        name={'partida-' + index[0].idServicio}
                                        rules={[{ required: !(index[0].asignado !== 'Partida'), message: 'Favor de seleccionar una opción', }]}
                                    >
                                        <Select
                                            showSearch={true}
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona una partida"
                                            disabled={index[0].asignado !== 'Partida'}
                                            loading={fetchingListaPartidasByServicio}
                                            onChange={(e) => onPartidasSeleccionadas(e, index[0].idServicio, key)}
                                        >
                                            {index.map(option =>
                                                <Option key={option.idPartida + ' - ' + option.nombre }>
                                                    <Popover content={option.idPartida + ' - ' + option.nombre} title={false}>
                                                        { option.idPartida + ' - ' + option.nombre }
                                                    </Popover>
                                                </Option>
                                            )}
                                        </Select>

                                    </Form.Item>
                                </Col>
                            ):(<Col span={0}></Col>)}

                            { index[0].idPartida !== null ? (
                                <Col span={8}>
                                    <Form.Item
                                        label='Área'
                                        name={'area-' + index[0].idServicio}
                                        rules={[{ required: !(index[0].asignado !== 'Area'), message: 'Favor de seleccionar una opción', }]}
                                    >
                                        <Select
                                            showSearch={true}
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona una área"
                                            disabled={index[0].asignado !== 'Area'}
                                            loading={fetchingListaAreasFuncionalesActivas}
                                            //onClick={(e) => onAreaClick(e, index[0].idServicio, key)}
                                            notFoundContent={ <div style={{ textAlign: 'center' }}> <Spin indicator={antIcon} spinning={fetchingListaAreasFuncionalesActivas} /> </div> }
                                            onChange={(e) => onAreasSeleccionadas(e, index[0].idServicio, key)}
                                        >
                                            {listaAreasFuncionalesActivas.map(option =>
                                                <Option key={option.idAreaFuncional + ' - ' + option.codigoArea + ', ' + option.areaFuncional}>
                                                    <Popover content={option.idAreaFuncional + ' - ' + option.codigoArea + ', ' + option.areaFuncional} title={false}>
                                                        { option.idAreaFuncional + ' - ' + option.codigoArea + ', ' + option.areaFuncional }
                                                    </Popover>
                                                </Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            ): (
                                <Col span={17}>
                                    <Form.Item
                                        label='Área'
                                        name={'area-' + index[0].idServicio}
                                        rules={[{ required: !(index[0].asignado !== 'Area'), message: 'Favor de seleccionar una opción', }]}
                                    >
                                        <Select
                                            showSearch={true}
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona una área"
                                            disabled={index[0].asignado !== 'Area'}
                                            loading={fetchingListaAreasFuncionalesActivas}
                                            //onClick={(e) => onAreaClick(e, index[0].idServicio, key)}
                                            notFoundContent={ <div style={{ textAlign: 'center' }}> <Spin indicator={antIcon} spinning={fetchingListaAreasFuncionalesActivas} /> </div> }
                                            onChange={(e) => onAreasSeleccionadas(e, index[0].idServicio, key)}
                                        >
                                            {listaAreasFuncionalesActivas.map(option =>
                                                <Option key={option.idAreaFuncional + ' - ' + option.codigoArea + ', ' + option.areaFuncional}>
                                                    <Popover content={option.idAreaFuncional + ' - ' + option.codigoArea + ', ' + option.areaFuncional} title={false}>
                                                        { option.idAreaFuncional + ' - ' + option.codigoArea + ', ' + option.areaFuncional }
                                                    </Popover>
                                                </Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}
                            </Row>

                            ) }
                            
                        </>):false }
                        </Spin>
                        </Col>

{/********************************************************************************************************************/}

                            <Divider orientation="left">Dirección</Divider>
                            <Col span={11}>
                                <Form.Item
                                    label="Calle"
                                    name="calle"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input placeholder="Ingresa la calle" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Número exterior"
                                    name="numeroExterior"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input placeholder="Ingresa el número exterior" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Número interior"
                                    name="numeroInterior"
                                >
                                    <Input placeholder="Ingresa el número interior" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Nombre colonia"
                                    name="nombreColonia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input placeholder="Ingresa la colonia" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Código Postal"
                                    name="cp"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input minLength='5' maxLength='5'  placeholder="Ingresa el Código Postal" title="El código postal debe ser números" pattern="^[0-9]+$"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Municipio o alcaldía"
                                    name="municipio"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input placeholder="Ingresa el municipio o alcaldía" title="El municipio debe contener sólo letras" pattern="^[a-zñA-ZÑ ÁÉÓÚÍáéóíú]+$"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Estado"
                                    name="estado"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input placeholder="Ingresa el estado" title="El estado debe contener sólo letras" pattern="^[a-zñA-ZÑ ÁÉÓÚÍáéóíú]+$"/>
                                </Form.Item>
                            </Col>
                        
                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Editar Proveedor</Button>
                            </Col>
                        </Row>
                    </Form>
                    ):false}
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarProveedor: state.InformacionOperativaReducer.fetchingEditarProveedor,
        showModalEditarProveedor: state.InformacionOperativaReducer.showModalEditarProveedor,
        proveedorSeleccionado: state.InformacionOperativaReducer.proveedorSeleccionado,
        fetchingListaDominiosActivos: state.ConfiguracionYSeguridadReducer.fetchingListaDominiosActivos,
        listaDominiosActivos: state.ConfiguracionYSeguridadReducer.listaDominiosActivos,
        fetchingListaExpedientes: state.InformacionOperativaReducer.fetchingListaExpedientes,
        listaExpedientes: state.InformacionOperativaReducer.listaExpedientes,
        fetchingListaContratos: state.InformacionOperativaReducer.fetchingListaContratos,
        listaContratos: state.InformacionOperativaReducer.listaContratos,
        fetchingListaServiciosActivosForProveedor: state.InformacionOperativaReducer.fetchingListaServiciosActivosForProveedor,
        listaServiciosActivosForProveedor: state.InformacionOperativaReducer.listaServiciosActivosForProveedor,
        page: state.InformacionOperativaReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,

        listaAreasProveedores: state.InformacionOperativaReducer.listaAreasProveedores,
        fetchingListaAreasProveedores: state.InformacionOperativaReducer.fetchingListaAreasProveedores,
        listaPartidasByServicio: state.InformacionOperativaReducer.listaPartidasByServicio,
        fetchingListaPartidasByServicio: state.InformacionOperativaReducer.fetchingListaPartidasByServicio,
        idsServicios: state.InformacionOperativaReducer.idsServicios,
        listaPartidasByServicioWithout: state.InformacionOperativaReducer.listaPartidasByServicioWithout,

        listaAreasFuncionalesActivas: state.AlmacenesReducer.listaAreasFuncionalesActivas,
        fetchingListaAreasFuncionalesActivas: state.AlmacenesReducer.fetchingListaAreasFuncionalesActivas,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarProveedor: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_PROVEEDOR', showModalEditarProveedor: false, proveedorSeleccionado: null, page: null })
        },
        onEditarProveedor: ( formulario, proveedorSeleccionado, listaDominiosActivos, listaExpedientes, listaContratos, listaServicios, page, listaPartidasByServicioWithout, listaAsignacionProveedor, listaAreasFuncionalesActivas) => {
            dispatch({ type: "EDITAR_PROVEEDOR_REQUEST" , formulario, proveedorSeleccionado, listaDominiosActivos, listaExpedientes, listaContratos, listaServicios, page, listaPartidasByServicioWithout, listaAsignacionProveedor, listaAreasFuncionalesActivas });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onObtenerPartidasByidServicio: (idsServicios, listaServiciosActivosForProveedor, idProveedor, serviciosActivos) => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_SERVICIO_INFO_OP_REQUEST', idsServicios, listaServiciosActivosForProveedor, idProveedor, serviciosActivos });
        },
        onLimpiarListaServicios: () => {
            dispatch({ type: 'LISTA_SERVICIOS_ACTIVOSF_SUCCESS', listaServiciosActivosForProveedor: [] });
        },
        onLimpiarPartidas: () => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_SERVICIO_INFO_OP_SUCCESS', listaPartidasByServicio: [] });
        },
        actualizarAsig: (listaPartidasByServicio, listaPartidasByServicioWithout) => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_SERVICIO_INFO_OP_SUCCESS', listaPartidasByServicio, listaPartidasByServicioWithout });
        },
        onGetAreasByServicioProveedor: (idServicio, idProveedor, partidas) => {
            dispatch({ type: 'LISTA_AREAS_PROVEEDORES_REQUEST', idServicio, idProveedor, partidas });
        },
        onLimpiarAreasByServicioProveedor:() => {
            dispatch({ type: 'LISTA_AREAS_PROVEEDORES_SUCCESS', listaAreasProveedores: [] });
        },
        onGetListaServicios: (idProveedor) => {
            dispatch({ type: 'LISTA_SERVICIOS_PROVEEDORES_REQUEST', idProveedor });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarProveedor);