import axios from "axios";
//const endpoint = "https://papantla-services.herokuapp.com/";
//const endpoint = "https://papantla-dev.herokuapp.com/";
const endpoint = 'https://morelos-dev.herokuapp.com/';
//const endpoint = "https://papantla-demo.herokuapp.com/";

//const endpoint = "http://172.24.0.170:8080/"

//const endpoint = 'https://papantla-services-jose.herokuapp.com/';
//const endpoint = 'https://papantla-dev-jose.herokuapp.com/';

//const endpoint = 'https://papantla-secret-jose.herokuapp.com/';
//const endpoint = 'https://papantla-secret.herokuapp.com/';

//Catálogo de Servicios Públicos
export function listaServiciosPublicosActivosSinUsoCall(token) {
	return axios({
		method: "get",
		url: endpoint + "servicios/publicos/activos/",
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function serviciosPublicosBusquedaCall(token, page, busqueda) {
	return axios({
		method: "get",
		url: endpoint + "servicios/publicos?" + busqueda + "page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Servicio Variables de Medición
export function estadoServicioPublicoCall(token, idServicioPublico) {
	return axios({
		method: "get",
		url: endpoint + "servicios/publicos/activa/" + idServicioPublico,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearServicioPublicoCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "servicios/publicos",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarServicioPublicoCall(token, data, idServicioPublico) {
	return axios({
		method: "put",
		url: endpoint + "servicios/publicos/" + idServicioPublico,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Servicio Variables de Medición
export function listaVariablesMedicionBusquedaCall(token, page, nombre) {
	return axios({
		method: "get",
		url: endpoint + "variablesMedicion?page=" + page + "&offset=10"  + "&nombre=" + nombre,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaVariablesMedicionActivasCall(token) {
	return axios({
		method: "get",
		url: endpoint + "variablesMedicion/activas/",
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoVariablesMedicionCall(token, idVariablesMedicion) {
	return axios({
		method: "get",
		url: endpoint + "variablesMedicion/activa/" + idVariablesMedicion,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearVariablesMedicionCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "variablesMedicion/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarVariablesDeMedicionCall(token, data, idVariablesMedicion) {
	return axios({
		method: "put",
		url: endpoint + "variablesMedicion/" + idVariablesMedicion,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Programación y Medición de Consumos.
export function listaProgramacionMedicionCall(token, page, servPublico) {
	return axios({
		method: "get",
		url: endpoint + "programacionConsumos?page=" + page + "&offset=10"  + "&servPublico=" + servPublico,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearProgramacionMedicionCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "programacionConsumos/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarProgramacionMedicionCall(token, data, idProgramacionConsumo) {
	return axios({
		method: "put",
		url: endpoint + "programacionConsumos/" + idProgramacionConsumo,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoProgramacionConsumoCall(token, idProgramacionConsumo) {
	return axios({
		method: "get",
		url: endpoint + "programacionConsumos/activa/" + idProgramacionConsumo,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Reporte de Lectura
export function listaDepositosActivosCall(token, idServicioPublico, idProgramacionHorario) {
	return axios({
		method: "get",
		//url: endpoint + "asignacion/servicios/publicos/asignaciones/" + idServicioPublico,
		url: endpoint + "registroLecturas/asignaciones?idServicioPublico=" + idServicioPublico + "&idProgramacionHorario=" + idProgramacionHorario,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaDepositosActivosSinIdProgramacionCall(token, idServicioPublico) {
	return axios({
		method: "get",
		url: endpoint + "asignacion/servicios/publicos/asignaciones/" + idServicioPublico,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Registro Lecturas - presentacion
export function registroLecturasPresentacionCall(token, idAsignacionServicioPublico, idServicioPublico, fechaInicio1, fechaFin1, fechaInicio2, fechaFin2) {
	return axios({
		method: "get",
		url: endpoint + "registroLecturas/presentacion?" + idAsignacionServicioPublico + idServicioPublico + '&fechaInicio1=' + fechaInicio1 + '&fechaFin1=' + fechaFin1 + '&fechaInicio2=' + fechaInicio2 + '&fechaFin2=' + fechaFin2,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function registroLecturasPresentacionDisponibilidadCall(token, idAsignacionServicioPublico, idServicioPublico, idUbicacion, fechaInicio, fechaFin) {
	return axios({
		method: "get",
		url: endpoint + "registroLecturas/presentacion/disponibilidad?" + idAsignacionServicioPublico + idServicioPublico + idUbicacion + fechaInicio + fechaFin,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function registroLecturasPresentacionConsumoCall(token, idAsignacionServicioPublico, idServicioPublico, idUbicacion, fechaInicio, fechaFin) {
	return axios({
		method: "get",
		url: endpoint + "registroLecturas/presentacion/consumo?" + idAsignacionServicioPublico + idServicioPublico + idUbicacion + fechaInicio +  fechaFin,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaUbicacionesByIdServicioPublicoCall(token, idServicioPublico) {
	return axios({
		method: "get",
		url: endpoint + "areas/ubicaciones/byServicioPublico?idServicioPublico=" + idServicioPublico,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}


// Registro Lecturas - excel
export function registroLecturasReporteCall(token, idAsignacionServicioPublico, idServicioPublico, fechaInicio1, fechaFin1, fechaInicio2, fechaFin2) {
	return axios({
		method: "get",
		url: endpoint + "registroLecturas/reporte?" + idAsignacionServicioPublico + idServicioPublico + '&fechaInicio1=' + fechaInicio1 + '&fechaFin1=' + fechaFin1 + '&fechaInicio2=' + fechaInicio2 + '&fechaFin2=' + fechaFin2,
		headers: {'Content-Type': 'application/json','Authorization':token},
		responseType: 'blob'
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Programacion Consumos - por Servicio Publico
export function programacionByServicioCall(token, idServicioPublico) {
	return axios({
		method: "get",
		url: endpoint + "programacionConsumos/byServicioPublico/" + idServicioPublico,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Variables Medicion - activas por Programacion Consumo
export function variableMedicionByProgramacionCall(token, idProgramacionConsumo) {
	return axios({
		method: "get",
		url: endpoint + "variablesMedicion/activasByProgramacionConsumo/" + idProgramacionConsumo,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Registro Lecturas - Horarios Capturables por Servicio Publico
export function horariosCapturablesByServicioCall(token, idServicioPublico) {
	return axios({
		method: "get",
		url: endpoint + "registroLecturas/" + idServicioPublico,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Registro Lecturas - Horarios Capturables por Servicio Publico (admin)
export function horariosCapturablesByServicioAdminCall(token, idServicioPublico) {
	return axios({
		method: "get",
		url: endpoint + "registroLecturas/administrador/" + idServicioPublico,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Registro Lecturas - Verificar
export function verificarRegistroLecturaCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "registroLecturas/verificar",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function registroLecturaCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "registroLecturas",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaConsumosAsignacionByIdServicioPublicoACall (token, idServicioPublico) {
	return axios({
		method:'get',
		url: endpoint + "asignacion/servicios/publicos/asignacion/?idServicioPublico=" + idServicioPublico +  "&page=0&offset=10",
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function crearAbastecimientoCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "servicios/publicos/abastecimiento",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaVariablesMedicionByIdServicioPublicoCall (token, idServicioPublico) {
	return axios({
		method:'get',
		url: endpoint + "variablesMedicion/byServicioPublico/" + idServicioPublico,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function registroLecturaPDFCall (token, idServicioPublico, idAsignacionServicioPublico, fechaInicio1, fechaInicio2, fechaFin1, fechaFin2) {
	return axios({
		method:'get',
		responseType: "blob",
		url: endpoint + "registroLecturas/presentacion/pdf?" + idServicioPublico + idAsignacionServicioPublico + fechaInicio1 + fechaInicio2 + fechaFin1 + fechaFin2,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};


export function registroLecturaDisponibilidadYConsumoPDFCall (token, idServicioPublico, idAsignacionServicioPublico, fechaInicio, fechaFin, tipo, idUbicacion) {
	return axios({
		method:'get',
		responseType: "blob",
		url: endpoint + "registroLecturas/presentacion/disponibilidad/pdf?" + idServicioPublico + idAsignacionServicioPublico + fechaInicio + fechaFin + tipo + idUbicacion,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};


export function registroLecturaDisponibilidadYConsumoExcelCall(token, idServicioPublico, idAsignacionServicioPublico, fechaInicio, fechaFin, tipo, idUbicacion) {
	return axios({
		method: "get",
		url: endpoint + "registroLecturas/presentacion/disponibilidad/excel?" + idServicioPublico + idAsignacionServicioPublico + fechaInicio + fechaFin + tipo + idUbicacion,
		headers: {'Content-Type': 'application/json','Authorization':token},
		responseType: 'blob'
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}




//Asignacion Capacidad y Ubicacion
export function listaServiciosPublicosACall (token, page, busqueda) {
	return axios({
		method:'get',
		url: endpoint + "asignacion/servicios/publicos/asignados?param=" + busqueda +  "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};
//Lista servicios publicos no asignados
export function listaServiciosPublicosNACall (token) {
	return axios({
		method:'get',
		url: endpoint + "asignacion/servicios/publicos/sin/asignar",
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};
//lista depositos de servicios publicos asignados
export function listaDepositosSPACall (token, idServicioPublico) {
	return axios({
		method: 'get',
		url: endpoint + 'asignacion/servicios/publicos/asignaciones/' + idServicioPublico,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function crearAsignacionDepositosCall (token, idServicioPublico, data) {
	return axios({
		method: 'post',
		url: endpoint + 'asignacion/servicios/publicos/asignacion?idServicioPublico=' + idServicioPublico,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarAsignacionDepositosCall (token, idServicioPublico, data) {
	return axios({
		method: 'put',
		url: endpoint + 'asignacion/servicios/publicos/edicion/' + idServicioPublico,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoDepositoCall (token, idServicioPublico) {
	return axios({
		method: 'get',
		url: endpoint + 'asignacion/servicios/publicos/activar/' + idServicioPublico,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then( response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Cálculo de Consumo Diferenciado
export function listaSPCalculoCall (token) {
	return axios({
		method: 'get',
		url: endpoint + 'calculo/consumo/habilitados/publicos',
		headers: {'Content-Type': 'application/json', 'Authorization': token}
	}).then( response => {
		return response;
	}).catch( error => {
		return error.response.data;
	});
}

export function listaSPCalculoBusquedaCall (token, page, busqueda) {
	return axios({
		method: 'get',
		url: endpoint + 'calculo/consumo/?param=' + busqueda + '&page=' + page + '&offset=10',
		headers: {'Content-Type': 'application/json', 'Authorization': token}
	}).then( response => {
		return response;
	}).catch( error => {
		return error.response.data;
	})
}

export function listaDetalleCCDInternoCall (token, idServicioPublico, page) {
	return axios({
		method: 'get',
		url: endpoint + 'calculo/consumo/detalle/interno?idServicioPublico=' + idServicioPublico +'&page=' + page + '&offset=10&orden=DESC',
		headers: {'Content-Type': 'application/json', 'Authorization': token}
	}).then( response => {
		return response;
	}).catch( error => {
		return error.response.data;
	});
}

export function listaDetalleCCDFactorizacionCall (token, idServicioPublico, page) {
	return axios({
		method: 'get',
		url: endpoint + 'calculo/consumo/detalle/facturacion?idServicioPublico=' + idServicioPublico +'&page=' + page + '&offset=10&orden=DESC',
		headers: {'Content-Type': 'application/json', 'Authorization': token}
	}).then( response => {
		return response;
	}).catch( error => {
		return error.response.data;
	});
}

export function lecturaInicioCDInternoCall (token, idServicioPublico, inicioPeriodo, finPeriodo) {
	return axios({
		method: 'get',
		url: endpoint + 'calculo/consumo/registro/inicio/periodo?idAsignacionServicioPublico=' + idServicioPublico +'&inicioPeriodo=' + inicioPeriodo +'&finPeriodo=' + finPeriodo,
		headers: {'Content-Type': 'application/json', 'Authorization': token}
	}).then( response => {
		return response;
	}).catch( error => {
		return error.response.data;
	})
}

export function lecturaFinCDInternoCall (token, idServicioPublico, inicioPeriodo, finPeriodo) {
	return axios({
		method: 'get',
		url: endpoint + 'calculo/consumo/registro/fin/periodo?idAsignacionServicioPublico=' + idServicioPublico +'&inicioPeriodo=' + inicioPeriodo +'&finPeriodo=' + finPeriodo,
		headers: {'Content-Type': 'application/json', 'Authorization': token}
	}).then( response => {
		return response;
	}).catch( error => {
		return error.response.data;
	})
}

export function crearCalculoCDInternoCall (token, idServicioPublico, data) {
	return axios({
		method: "post",
		url: endpoint + 'calculo/consumo?idServicioPublico=' + idServicioPublico,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearCalculoCDFacturacionCall (token, data) {
	return axios({
		method: 'post',
		url: endpoint + 'calculo/consumo/facturacion/guardar',
		headers: {'Content-Type': 'application/json', 'Authorization': token},
		data: data
	}).then( response => {
		return response;
	}).catch( error => {
		return error.response.data;
	});
}

export function calculoCDInternoPDFCall (token, idServicioPublico, inicioPeriodo, finPeriodo, data) {
	return axios({
		method: 'post',
		url: endpoint + 'calculo/consumo/imprimir/calculo/diferenciado?idServicioPublico=' + idServicioPublico + '&inicioPeriodo=' + inicioPeriodo + '&finPeriodo=' + finPeriodo,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
		data: data,
		responseType: 'blob',
	}).then( response => {
		return response;
	}).catch( error => {
		return error.response.data;
	});
}

export function calculoCDInternoExcelCall (token, data) {
	return axios({
		method: 'post',
		url: endpoint + 'calculo/consumo/imprimir/calculo/dif/excel',
		headers: {'Content-Type': 'application/json', 'Authorization': token},
		data: data,
		responseType: 'blob',
	}).then( response => {
		return response;
	}).catch( error => {
		return error.response.data;
	});
}

export function calculoCDFacturacionPDFCall (token, data) {
	return axios({
		method: 'post',
		url: endpoint + 'calculo/consumo/facturacion/pdf',
		headers: {'Content-Type': 'application/json', 'Authorization': token},
		data: data,
		responseType: 'blob',
	}).then( response => {
		return response;
	}).catch( error => {
		return error.response.data;
	});
}

export function calculoCDFacturacionExcelCall (token, data) {
	return axios({
		method: 'post',
		url: endpoint + 'calculo/consumo/facturacion/excel',
		headers: {'Content-Type': 'application/json', 'Authorization': token},
		data: data,
		responseType: 'blob',
	}).then( response => {
		return response;
	}).catch( error => {
		return error.response.data;
	});
}