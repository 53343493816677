import React, { Component } from 'react';
import { Form, Input, Button, Col, Spin, Modal, Select, Row } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';
const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalEditarUbicacionProducto extends Component {
    formRef = React.createRef();


    render() {

        const { fetchingEditarUbicacionProducto, showModalEditarUbicacionProducto, onEditarUbicacionProducto, onShowModalEditarUbicacionProducto,
            ubicacionProductoSeleccionado,listaAlmacenesActivos, fetchingListaAlmacenesActivos, page, limpiarFormulario } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onEditarUbicacionProducto(formulario, ubicacionProductoSeleccionado, page);

        };
        if (limpiarFormulario && limpiarFormulario==='EUP') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        

        const handleCancel = () => {
            onShowModalEditarUbicacionProducto();
        }

        return (
            <Modal
                visible={showModalEditarUbicacionProducto}
                title='Editar Ubicación del Producto'
                onCancel={handleCancel}
                footer={false}
                width='60%'>
                <Spin spinning={fetchingEditarUbicacionProducto}>
                    {ubicacionProductoSeleccionado ? (
                        <Form {...layout}
                            name="formulario"
                            initialValues={ubicacionProductoSeleccionado}
                            onFinish={onFinish}
                            
                            ref={this.formRef}
                        >
                            <Row type='flex' justify='space-between'>
                                <Col span={11}>
                                    <Form.Item
                                        label="Piso"
                                        name="piso"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <Input
                                            pattern="^^[ñíóáéú a-zA-Z0-9]+$"
                                            title="Nombre debe tener letras y números."
                                            maxLength={100} placeholder='Ingrese piso' />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label="Area"
                                        name="area"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <Input
                                            pattern="^^[ñíóáéú a-zA-Z0-9]+$"
                                            title="Nombre debe tener letras o números."
                                            maxLength={100} placeholder='Ingrese área' />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label="Pasillo"
                                        name="pasillo"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Input
                                            pattern="^^[ñíóáéú a-zA-Z0-9]+$"
                                            title="Nombre debe tener letras o números."
                                            maxLength={100} placeholder='Ingrese pasillo' />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label="Rack"
                                        name="rack"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <Input
                                            pattern="^^[ñíóáéú a-zA-Z0-9]+$"
                                            title="Nombre debe tener letras o números."
                                            maxLength={100} placeholder='Ingrese rack' />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label="Nivel del rack"
                                        name="nivelRack"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <Input
                                            pattern="^^[ñíóáéú a-zA-Z0-9]+$"
                                            title="Nombre debe tener letras o números."
                                            maxLength={100} placeholder='Ingrese nivel del rack' />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label="Posición en el rack"
                                        name="posicionRack"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <Input
                                            pattern="^^[ñíóáéú a-zA-Z0-9]+$"
                                            title="Nombre debe tener letras o números."
                                            maxLength={100} placeholder='Ingrese posición en el rack' />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label="Almacen"
                                        name="almacen"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Seleccione un almacén"
                                            loading={fetchingListaAlmacenesActivos}
                                        >
                                            {listaAlmacenesActivos.map(option => <Option key={option.idAlmacen}>{option.nombreC}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={11} className='marginBoton'>
                                    <br />
                                    <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Editar Ubicación de Producto</Button>
                                </Col>
                            </Row>
                        </Form>
                    ) : false}
                </Spin>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingEditarUbicacionProducto: state.AlmacenesReducer.fetchingEditarUbicacionProducto,
        showModalEditarUbicacionProducto: state.AlmacenesReducer.showModalEditarUbicacionProducto,
        ubicacionProductoSeleccionado: state.AlmacenesReducer.ubicacionProductoSeleccionado,
        page: state.AlmacenesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaAlmacenesActivos: state.AlmacenesReducer.fetchingListaAlmacenesActivos,
        listaAlmacenesActivos: state.AlmacenesReducer.listaAlmacenesActivos,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarUbicacionProducto: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_UBICACION_PRODUCTO', showModalEditarUbicacionProducto: false, UbicacionProductoSeleccionado: null })
        },
        onEditarUbicacionProducto: (formulario, ubicacionProductoSeleccionado, page) => {
            dispatch({ type: 'EDITAR_UBICACION_PRODUCTO_REQUEST', formulario: formulario, ubicacionProductoSeleccionado: ubicacionProductoSeleccionado, page:page, })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        },
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarUbicacionProducto);