import React, { Component } from 'react';
import { Row, Form, Input, Button, Modal, Col, Spin, Select, DatePicker, message, Upload, InputNumber, Popover } from 'antd';
import moment from 'moment';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import { UploadOutlined } from '@ant-design/icons';


const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalNuevoActivo extends Component {
    state = {
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
        showCalendario: false,
    }

    formRef = React.createRef();

    componentDidMount() {
        this.props.onGetActivosCaract();
    }

    render() {
        const { fetchingNuevoActivo, showModalNuevoActivo, onNuevoActivo, onShowModalNuevoActivo,
            fetchingListaActivosCaractFiltro, listaActivosCaractFiltro, fetchingListaUbicacionesFiltro, listaUbicacionesFiltro,
            limpiarFormulario, onLimpiarFormulario, listaInfoProyecto, onListaUbicacionesFiltro, onListaUbicacionesFiltroLimpiar,
            onListaActivosCaractFiltro, onListaActivosCaractFiltroLimpiar, 
        } = this.props;

        const onFinish = formulario => {
            const formularioFecha = {
                ...formulario,
                'fechaSuministro': formulario['fechaSuministro'].format('YYYY-MM-DD'),
                'fechaInstalacion': formulario['fechaInstalacion'].format('YYYY-MM-DD'),
                'fechaGarantia': formulario['fechaGarantia'].format('YYYY-MM-DD')
            }
            onNuevoActivo(formularioFecha, this.props.page);
        };

        if (limpiarFormulario && limpiarFormulario === 'crearActivo') {
            this.formRef.current.resetFields();
            this.setState({
                filePDF: false,
                botonPDF: false,
                botonEditar: false,
                showCalendario: false,
            })
            onLimpiarFormulario();
            onListaUbicacionesFiltroLimpiar();
            onListaActivosCaractFiltroLimpiar();
        }
        

        const handleCancel = () => {
            onShowModalNuevoActivo();
            onListaUbicacionesFiltroLimpiar();
            onListaActivosCaractFiltroLimpiar();
            this.setState({
                filePDF: false,
                botonPDF: false,
                botonEditar: false,
                showCalendario: false,
            })
            this.formRef.current.resetFields();
        }

        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else {
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                })
            }
            if (info.file.status === 'done') {
                var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,40}$");
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['cartaFabricante']);
                }
                else {
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['cartaFabricante']);
                }
                else {
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }

        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
        }
        const fechas = (rangeValue ) => {
            if(listaInfoProyecto.fechaInicio && rangeValue){
                const diferencia = listaInfoProyecto.fechaInicio.diff(moment(rangeValue.format('YYYY-MM-DD')), "days");
                this.formRef.current.resetFields(['fechaGarantia', 'fechaInstalacion']);
                if(diferencia>0){
                    message.error('No es posible seleccionar una fecha de inicio menor al inicio de contrato del proyecto', 7);
                    this.formRef.current.resetFields(['fechaSuministro']);
                    this.setState({
                        showCalendario: false,
                    });
                }
                else{
                    this.setState({
                        showCalendario: true,
                    });
                }
            }
            else{
                message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                this.formRef.current.resetFields(['fechaGarantia', 'fechaInstalacion', 'fechaSuministro']);
            }
        }
        const fechaValidar = (fecha) => {
            if (fecha) {
                const fechaSuministro = this.formRef.current.getFieldValue('fechaSuministro').format('YYYY-MM-DD');
                const diferencia = moment(fechaSuministro).diff(moment(fecha.format('YYYY-MM-DD')), "days");
                if(diferencia>0){
                    message.error('No es posible seleccionar una fecha menor a fecha suministro', 7);
                    this.formRef.current.resetFields(['fechaInstalacion']);
                }
                else{
                    const fechaGarantia = this.formRef.current.getFieldValue('fechaGarantia')
                    if(fechaGarantia){
                        const diferenciaGarantia = moment(fechaGarantia.format('YYYY-MM-DD')).diff(moment(fecha.format('YYYY-MM-DD')), "days");
                        if(diferenciaGarantia>0){
                            message.error('No es posible seleccionar una fecha menor a fecha garantía', 7);
                            this.formRef.current.resetFields(['fechaInstalacion']);
                        }
                    }
                }
            } else {
                message.error('Seleccionar una fecha de Garantía');
            }
        }
        const fechaValidarGarantia = (fecha) => {
            if(fecha) {
                const fechaSuministro = this.formRef.current.getFieldValue('fechaSuministro').format('YYYY-MM-DD');
                const diferencia = moment(fechaSuministro).diff(moment(fecha.format('YYYY-MM-DD')), "days");
                if(diferencia>0){
                    message.error('No es posible seleccionar una fecha menor a fecha suministro', 7);
                    this.formRef.current.resetFields(['fechaGarantia']);
                }
                else{
                    const fechaInstalacion = this.formRef.current.getFieldValue('fechaInstalacion');
                    if(fechaInstalacion){
                        const diferenciaGarantia = moment(fechaInstalacion.format('YYYY-MM-DD')).diff(moment(fecha.format('YYYY-MM-DD')), "days");
                        if(diferenciaGarantia<0){
                            message.error('No es posible seleccionar una fecha mayor a fecha instalación', 7);
                            this.formRef.current.resetFields(['fechaGarantia']);
                        }
                    }
                }
            } else {
                message.error('Seleccione una fecha de garantía')
            }
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };
        const handleSearch = (value) => {
            if(value.length>=3){
                if (value) {
                    onListaUbicacionesFiltro(value);
                } else {
                    message.warning('No se encontró ubicación con esas palabras')
                }
            }
            else{
                onListaUbicacionesFiltroLimpiar();
            }
        };
        const buscarActivoCarac = (value) => {
            if(value.length>=3){
                if (value) {
                    onListaActivosCaractFiltro(value);
                } else {
                    message.warning('No se encontró ubicación con esas palabras')
                }
            }
            else{
                onListaActivosCaractFiltroLimpiar();
            }
        };
        
        const estado = ["Bueno", "Regular", "Malo"];

        return (
            <Modal
                visible={showModalNuevoActivo}
                title='Nuevo Activo'
                onCancel={handleCancel}
                footer={false}
                width='90%'>
                <Spin spinning={fetchingNuevoActivo}>
                    <Form {...layout}
                        name="formulario"
                        onFinish={onFinish}
                        
                        ref={this.formRef}>
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Activo característica"
                                    name="idActivoCaract"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Ingrese el activo característica"
                                        loading={fetchingListaActivosCaractFiltro}
                                        showSearch
                                        filterOption={false}
                                        onSearch={buscarActivoCarac}
                                        notFoundContent={null}
                                    >
                                        {listaActivosCaractFiltro.map(option => <Option key={option.idActivoCaract}>{option.descripcion}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Ubicación"
                                    name="idUbicacion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Ingrese la ubicación"
                                        loading={fetchingListaUbicacionesFiltro}
                                        showSearch
                                        filterOption={false}
                                        onSearch={handleSearch}
                                        notFoundContent={null}
                                    >
                                        {listaUbicacionesFiltro.map(option => 
                                        <Option key={option.idUbicacion}>
                                            <Popover content={option.todoUbicacion} title={false}>
                                                {option.todoUbicacion}
                                            </Popover>
                                        </Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="SKU"
                                    name="sku"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={60} placeholder="Ingrese el SKU"
                                        title="SKU debe contener sólo letras y números" pattern="^[a-zñA-ZÑ0-9 ÁÉÓÚÍáéóíú]+$" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Número de serie"
                                    name="serieNum"
                                >
                                    <Input maxLength={40} placeholder="Ingrese el número de serie"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Estado"
                                    name="estado"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Seleccione el estado"
                                        loading={fetchingListaUbicacionesFiltro}
                                    >
                                        {estado.map(option => <Option key={option} value={option}>{option}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Fecha suministro"
                                    name="fechaSuministro"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <DatePicker onChange={fechas} style={{ width: '100%' }} 
                                    format='DD-MM-YYYY' placeholder={["Seleccione fecha suministro"]} />
                                </Form.Item>
                            </Col>
                            {this.state.showCalendario ? (
                            <Col span={11}>
                                <Form.Item
                                    label="Fecha instalación"
                                    name="fechaInstalacion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <DatePicker onChange={fechaValidar} style={{ width: '100%' }} 
                                    format='DD-MM-YYYY' placeholder={["Seleccione fecha instalación"]} />
                                </Form.Item>
                            </Col>
                            ):false}
                            {this.state.showCalendario ? (
                            <Col span={11}>
                                <Form.Item
                                    label="Fecha garantía"
                                    name="fechaGarantia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <DatePicker onChange={fechaValidarGarantia} style={{ width: '100%' }} 
                                    format='DD-MM-YYYY'  placeholder={["Seleccione fecha garantía"]} />
                                </Form.Item>
                            </Col>    
                            ):false}
                            
                            
                            <Col span={11}>
                                <Form.Item
                                    label="Garantía años"
                                    name="garantia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <InputNumber min={1} placeholder="Ingrese la garantía" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Observaciones"
                                    name="observaciones"
                                >
                                    <Input maxLength={100} placeholder="Ingrese observaciones" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Carta fabricante"
                                    name="cartaFabricante"
                                >
                                    <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                        {this.state.filePDF === false ? (
                                        <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                            <UploadOutlined /> Click para subir carta de fabricante PDF
                                        </Button>
                                        ):false}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={11} className='marginBoton'>
                                <br />
                                <Button disabled={this.state.botonEditar} className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Crear Activo</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingNuevoActivo: state.AlmacenesReducer.fetchingNuevoActivo,
        showModalNuevoActivo: state.AlmacenesReducer.showModalNuevoActivo,
        page: state.AlmacenesReducer.page,
        fetchingListaActivosCaractFiltro: state.AlmacenesReducer.fetchingListaActivosCaractFiltro,
        listaActivosCaractFiltro: state.AlmacenesReducer.listaActivosCaractFiltro,
        fetchingListaUbicacionesFiltro: state.CatalogosGeneralesReducer.fetchingListaUbicacionesFiltro,
        listaUbicacionesFiltro: state.CatalogosGeneralesReducer.listaUbicacionesFiltro,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevoActivo: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_ACTIVO', showModalNuevoActivo: false, page: null })
        },
        onNuevoActivo: (formulario, page) => {
            dispatch({ type: "NUEVO_ACTIVO_REQUEST", formulario: formulario, page: page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onGetActivosCaract: () => {
            dispatch({ type: 'LISTA_ACTIVOS_CARACT_REQUEST', });
        },
        onListaUbicacionesFiltro: (busqueda) => {
            dispatch({ type: 'LISTA_UBICACIONES_FILTRO_REQUEST', busqueda: busqueda });
        },
        onListaUbicacionesFiltroLimpiar: () => {
            dispatch({ type: 'LISTA_UBICACIONES_FILTRO_SUCCESS', listaUbicacionesFiltro: [] });
        },
        onListaActivosCaractFiltro: (busqueda) => {
            dispatch({ type: 'LISTA_ACTIVOS_CARACT_FILTRO_REQUEST', busqueda: busqueda });
        },
        onListaActivosCaractFiltroLimpiar: () => {
            dispatch({ type: 'LISTA_ACTIVOS_CARACT_FILTRO_SUCCESS', listaActivosCaractFiltro: [] });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoActivo);