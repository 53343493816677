import React, { Component } from 'react';
import { Form, Button, Row, Col, Modal, InputNumber, Input, Divider, message , Select} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const confirm = Modal.confirm;
const {Option} = Select;
class ListaTiempoDeGarantia extends Component {
    formRef = React.createRef();
    render() { 
        const { 
            onCambiarVista, formularioNivelSeveridad, nivelSeveridadSeleccionado, listaNivelesSeveridadActivos,
            listaNivelesSeveridad,
        } = this.props;       

        const onFinish = formulario => {
            console.log('formulario entrando onFinish: ', formulario)
            
            let listaNivelesPrimera = []
            let listaNivelesLocal = listaNivelesSeveridadActivos;
            let noIguales = false
            for (var key in formulario) {
                if (Object.prototype.hasOwnProperty.call(formulario, key)) {
                    let idCampo = key.split('-');
                    if (idCampo.length === 2) {
                        let idNivelSeveridad = idCampo[1];
                        let campo = idCampo[0];
                        let valor = formulario[key];
    
                        let tiemposFormulario = {[campo]: valor, 'idNivelSeveridad': parseInt(idNivelSeveridad)};
                        listaNivelesPrimera.push(tiemposFormulario);
                    }
                }
            }

            console.log('listaNivelesPrimera', listaNivelesPrimera);
            for (let i = 0; i < listaNivelesSeveridadActivos.length; i++) {
                listaNivelesLocal[i].revisado = false
                if(listaNivelesSeveridadActivos[i].unidadMedida === formularioNivelSeveridad.unidadMedida){
                    formularioNivelSeveridad.idUnidadMedida = listaNivelesSeveridadActivos[i].idUnidadMedida;
                }
            }

            let listaTiempos = [];
            for(let i in listaNivelesLocal){
                for(let x in listaNivelesPrimera){
                    if(listaNivelesLocal[i].idNivelSeveridad === listaNivelesPrimera[x].idNivelSeveridad){
                        if(listaNivelesPrimera[x].tiempoRespuesta){
                            listaNivelesLocal[i].tiempoRespuestaNuevo = listaNivelesPrimera[x].tiempoRespuesta;
                        }
                        if(listaNivelesPrimera[x].unidadMedida){
                            listaNivelesLocal[i].unidadMedidaNuevo = listaNivelesPrimera[x].unidadMedida;
                        }
                        if(listaNivelesPrimera[x].descripcion){
                            listaNivelesLocal[i].descripcionNuevo = listaNivelesPrimera[x].descripcion;
                        }
                    }
                }
            }
            console.log('listaNivelesLocal, listaNivelesSeveridadActivos', listaNivelesLocal, listaNivelesSeveridadActivos)
            for(let i in listaNivelesSeveridadActivos){
                if(parseInt(listaNivelesSeveridadActivos[i].tiempoRespuesta) !== parseInt(listaNivelesLocal[i].tiempoRespuestaNuevo)){
                    console.log('If de tiempoRespuesta', parseInt(listaNivelesSeveridadActivos[i].tiempoRespuesta), parseInt(listaNivelesLocal[i].tiempoRespuestaNuevo))
                    listaTiempos.push(listaNivelesLocal[i]);
                }
                else if(listaNivelesSeveridadActivos[i].unidadMedida !== listaNivelesLocal[i].unidadMedidaNuevo){
                    console.log('If de unidadMedida',listaNivelesSeveridadActivos[i].unidadMedida, listaNivelesLocal[i].unidadMedidaNuevo)
                    listaTiempos.push(listaNivelesLocal[i]);
                }
                else if(listaNivelesSeveridadActivos[i].descripcion !== listaNivelesLocal[i].descripcionNuevo){
                    console.log('If de descripcion', listaNivelesSeveridadActivos[i].descripcion, listaNivelesLocal[i].descripcionNuevo)
                    listaTiempos.push(listaNivelesLocal[i]);
                }
                if(listaNivelesSeveridadActivos[i].idNivelSeveridad === nivelSeveridadSeleccionado.idNivelSeveridad){
                    console.log('Entró al if del que estoy editando', listaNivelesLocal[i], formularioNivelSeveridad)
                    if(parseInt(listaNivelesLocal[i].tiempoRespuestaNuevo) !== parseInt(formularioNivelSeveridad.tiempoRespuesta) && !listaNivelesLocal[i].revisado){
                        console.log('1er if')
                        noIguales = true;
                        listaNivelesLocal[i].revisado = true;
                    }
                    if(listaNivelesLocal[i].unidadMedidaNuevo !== formularioNivelSeveridad.unidadMedida && !listaNivelesLocal[i].revisado){
                        console.log('2do if')
                        noIguales = true;
                        listaNivelesLocal[i].revisado = true;
                    }
                    if(listaNivelesLocal[i].descripcionNuevo !== formularioNivelSeveridad.descripcion && !listaNivelesLocal[i].revisado){
                        console.log('3er if')
                        noIguales = true;
                        listaNivelesLocal[i].revisado = true;
                    }
                }
            }

            console.log('listaTiempos, noIguales', listaTiempos, noIguales);
            if(noIguales){
                message.warning('El valor del tiempo de respuesta ' + nivelSeveridadSeleccionado.idNivelSeveridad + ', debe ser el mismo que ingresó al editar (' + formularioNivelSeveridad.tiempoRespuesta + ' ' + formularioNivelSeveridad.unidadMedida + ', ' + formularioNivelSeveridad.descripcion + ').')
            } else {
                confirm({
                    title: 'Estos son los Niveles de Severidad que fueron modificados. ¿Estás seguro de modificarlos?',
                    content: 
                    <div>
                        {listaTiempos.map(tiempoEditado =>
                        <Row justify='space-around' key={tiempoEditado.idNivelSeveridad}>
                            <Divider>{'Nivel de Severidad ' + tiempoEditado.idNivelSeveridad}</Divider>
                            <Col span={8}>
                                <p><strong>Tiempo de Respuesta: </strong>{tiempoEditado.tiempoRespuestaNuevo}</p>
                            </Col>
                            <Col span={8}>
                                <p><strong>Unidad de Medida: </strong>{tiempoEditado.unidadMedidaNuevo}</p>
                            </Col>
                            <Col span={8}>
                                <p><strong>Descripción: </strong>{tiempoEditado.descripcionNuevo}</p>
                            </Col>
                            
                        </Row>
                        )}
                    </div>,
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    width: '50%',
                    onOk() {
                        onCambiarVista(4, formularioNivelSeveridad, listaTiempos);
                    },
                    onCancel() {
                        onCambiarVista(1, null);
                    },
                });
            }   
        } // fin onFinish

        const changeDecimales = (value, idNivelSeveridad) => {
            let valueDecimal = parseInt(value);
            if(isNaN(valueDecimal)){
                valueDecimal = 1;
            }
            this.formRef.current.setFieldsValue({['tiempoRespuesta-' + idNivelSeveridad]: valueDecimal});
        }
       console.log('lista tiempos todos ', listaNivelesSeveridadActivos);

        return ( 
            <Form {...layout}
            name="formulario"
            onFinish={onFinish}
            initialValues={listaNivelesSeveridad}
            ref={this.formRef}
            >
                <div>
                    <h2>Favor de ingresar nuevamente el tiempo de respuesta y descripción de los niveles de severidad</h2>
                    {listaNivelesSeveridadActivos.map(nivelSeveridad => 
                        <Row justify='space-around' key={nivelSeveridad.idNivelSeveridad}>
                            <Divider>{'Nivel de Severidad ' + nivelSeveridad.idNivelSeveridad}</Divider>
                            <Col span={11}>
                                <Form.Item
                                    label="Tiempo de respuesta"
                                    name={'tiempoRespuesta-' + nivelSeveridad.idNivelSeveridad}
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber min={1} max={100} placeholder='Ingrese tiempo de garantia' onChange={(e)=>changeDecimales(e, nivelSeveridad.idNivelSeveridad)} />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                    <Form.Item
                                        label="Unidad de medida"
                                        name={"unidadMedida-" + nivelSeveridad.idNivelSeveridad} 
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona la unidad de medida"
                                        >
                                            <Option key={'Minutos'}>{'Minutos'}</Option>
                                            <Option key={'Horas'}>{'Horas'}</Option>
                                            <Option key={'Días'}>{'Días'}</Option>
                                            <Option key={'Años'}>{'Años'}</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Descripción de severidad"
                                    name={"descripcion-" + nivelSeveridad.idNivelSeveridad} 
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input placeholder={nivelSeveridad.nombre } ></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                        )}
                    
                    <Col span={24} className='marginBoton'>
                        <br/>
                        <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Guardar Cambios</Button>
                    </Col>
                </div>
            </Form>
         );
    }
}

const mapStateToProps = state => {
    return {
        formularioNivelSeveridad: state.MecanismosDePagosReducer.formularioNivelSeveridad,
        listaNivelesSeveridadActivos: state.MecanismosDePagosReducer.listaNivelesSeveridadActivos,
        nivelSeveridadSeleccionado: state.MecanismosDePagosReducer.nivelSeveridadSeleccionado,
        unidadesMediPorCategoria: state.AlmacenesReducer.unidadesMediPorCategoria,
        fetchingUnidadesMediPorCategoria: state.AlmacenesReducer.fetchingUnidadesMediPorCategoria,
        listaNivelesSeveridad: state.MecanismosDePagosReducer.listaNivelesSeveridad,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCambiarVista: (vistaModalNivelSeveridad, formularioNivelSeveridad, nivelesEditados) => {
            console.log('nivelesEditados en lista dispatch', nivelesEditados)
            dispatch({ type: 'CAMBIAR_VISTA_NIVEL_SEVERIDAD', vistaModalNivelSeveridad, formularioNivelSeveridad, nivelesEditados})
        },
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ListaTiempoDeGarantia);