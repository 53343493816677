import React, { Component } from 'react';
import { Form, Input, Button, Col, Spin, Row, Select, Divider, DatePicker, InputNumber, message } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class EditarCategoriaFallaDeCalidad extends Component {
    formRef = React.createRef();
    state = {
        tipoEdicion: null,
        disabledSelect: false,
    }


    render() {
        const { fetchingEditarCategoriaFallaDeCalidad, showModalEditarCategoriaFallaDeCalidad, onShowModalEditarCategoriaFallaDeCalidad,
             onEditarCategoriaFallaDeCalidad, CategoriaFallaDeCalidadSeleccionado, page, limpiarFormulario, onLimpiarFormulario, listaInfoProyecto,
             fetchingListaInfoProyecto, onCambiarVista } = this.props

        if (limpiarFormulario && limpiarFormulario === 'editarCategoriaFalla') {
            this.formRef.current.resetFields();
            onLimpiarFormulario();
            onCambiarVista(1);
            
        }
        
        const onFinish = formulario => {
            const rangeValue = formulario['rangoVigencia'];
            formulario.rangoVigencia = rangeValue[0].format('YYYY-MM-DD') + "," + rangeValue[1].format('YYYY-MM-DD');
            formulario.fechaInicioVigencia = formulario.rangoVigencia.split(',')[0];
            formulario.fechaFinVigencia = formulario.rangoVigencia.split(',')[1];
            if(this.state.tipoEdicion === 'simple'){
                onEditarCategoriaFallaDeCalidad(formulario, page, CategoriaFallaDeCalidadSeleccionado);
            }
            else{
                if(CategoriaFallaDeCalidadSeleccionado.valorPorcentaje === formulario['valorPorcentaje']) {
                    console.log('completo:', formulario);
                    onEditarCategoriaFallaDeCalidad(formulario, page, CategoriaFallaDeCalidadSeleccionado);
                }
                else {
                    onCambiarVista(2, formulario);
                    console.log('Ingresar contraseña');
                }
            }
        }

        //comparar solo con la fecha seleccionada
        const disabledRange = fecha => {
            return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicio;
        }

        const fechas = (rangeValue) => {
            if(listaInfoProyecto.fechaInicio){
                if (rangeValue[0]) {
                    const fechaInicioAnterior = CategoriaFallaDeCalidadSeleccionado.fechaInicioVigencia.split('T')[0];
                    const diferenciaAnterior = moment(fechaInicioAnterior, 'YYYY-MM-DD').diff(moment(rangeValue[0].format('YYYY-MM-DD')), "days");
                    const nuevoPorcentaje = CategoriaFallaDeCalidadSeleccionado.valorPorcentaje === this.formRef.current.getFieldValue(['valorPorcentaje']);

                    if (!nuevoPorcentaje) {
                        if (diferenciaAnterior > -1) {
                            message.error('No es posible seleccionar una fecha de inicio menor ni igual a la fecha de inicio anterior (' + fechaInicioAnterior.split('-').reverse().join('-') + ')', 7);
                            this.formRef.current.setFieldsValue({ 'rangoVigencia': [null, CategoriaFallaDeCalidadSeleccionado.rangoVigencia[1]] });
                        }
                    }
                }
            }
            else{
                message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                this.formRef.current.setFieldsValue({'rangoVigencia': [null, CategoriaFallaDeCalidadSeleccionado.rangoVigencia[1]]});
            }
        };

        const handleFecha = () => {
            const porcentajeChange = CategoriaFallaDeCalidadSeleccionado.valorPorcentaje === this.formRef.current.getFieldValue(['valorPorcentaje']);
            if(this.state.tipoEdicion === 'completa'){
                if (!porcentajeChange) {
                    message.warning('Por motivos de seguridad, favor de seleccionar de nuevo la fecha de inicio de vigencia', 7);
                    this.formRef.current.setFieldsValue({'rangoVigencia': [null, CategoriaFallaDeCalidadSeleccionado.rangoVigencia[1]]});
                }
            }
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo);
        };
        const onSelectTipoEdicion = (value) => {
            console.log('value');
            console.log(value);
            this.setState({
                tipoEdicion: value,
                disabledSelect: true,
            })
        }

        return (
            <div>
                {CategoriaFallaDeCalidadSeleccionado ? (
                    <Spin spinning={fetchingEditarCategoriaFallaDeCalidad}>
                        <Form {...layout}
                            name='formulario'
                            onFinish={onFinish}
                            
                            initialValues={CategoriaFallaDeCalidadSeleccionado}
                            ref={this.formRef}
                        >
                            <Row justify='space-around'>
                                <Col span={9}>
                                    <p>Selecciona el tipo de edición</p>
                                </Col>
                                <Col span={13}>
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el tipo de edición"
                                        onChange={onSelectTipoEdicion}
                                        disabled={this.state.disabledSelect}
                                    >
                                        <Option key='simple'>Edición simple</Option>
                                        <Option key='completa'>Edición por convenio</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Divider/>
                            {this.state.tipoEdicion ?(
                            <Row justify='space-around'>
                                <Col span={24}>
                                    <Form.Item
                                        label="Categoría de fallas de calidad"
                                        name="fallaCalidad"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <Input
                                            pattern="^^[ñíóáéú a-zA-Z0-9 ]+$"
                                            title="Categoría de fallas debe tener letras." 
                                            maxLength={80} placeholder='Categoría de fallas de calidad' />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="Descripción"
                                        name="descripcion"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <Input
                                        maxLength={200} placeholder='Descripción' />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="Valor porcentaje"
                                        name="valorPorcentaje"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <InputNumber min={1} max={100} placeholder='Valor porcentaje'
                                            onChange={handleFecha}
                                            title="Número debe ser entero" pattern="^[0-9]+$" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="Fechas vigencias valor porcentaje"
                                        name="rangoVigencia"
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <RangePicker
                                            format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio","Fecha fin"]}
                                            loading={fetchingListaInfoProyecto}
                                            onChange={fechas}
                                            disabledDate={disabledRange}
                                            disabled={[false, true]}                          
                                        />
                                    </Form.Item>
                                </Col>
                                
                                <Col span={11}>
                                    <Button className="btnFiltrar" htmlType="submit">Editar Categoría de Fallas de Calidad</Button>
                                </Col>
                            </Row>
                            ):false}
                        </Form>
                    </Spin>
                ) : false }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingEditarCategoriaFallaDeCalidad: state.MecanismosDePagosReducer.fetchingEditarCategoriaFallaDeCalidad,
        page: state.MecanismosDePagosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        fetchingListaInfoProyecto: state.InformacionDelProyectoReducer.fetchingListaInfoProyecto,
        CategoriaFallaDeCalidadSeleccionado: state.MecanismosDePagosReducer.CategoriaFallaDeCalidadSeleccionado,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditarCategoriaFallaDeCalidad: (formulario, page, CategoriaFallaDeCalidadSeleccionado) => {
            dispatch( { type: 'EDITAR_CATEGORIA_FALLA_CALIDAD_REQUEST', formulario: formulario, page:page, CategoriaFallaDeCalidadSeleccionado: CategoriaFallaDeCalidadSeleccionado });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        },
        onCambiarVista: (vistaModalFallaCalidad, formularioFallaCalidad) => {
            dispatch({ type: 'CAMBIAR_VISTA_FALLA_CALIDAD', vistaModalFallaCalidad: vistaModalFallaCalidad, formularioFallaCalidad:formularioFallaCalidad})
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditarCategoriaFallaDeCalidad);