import React, { Component } from 'react';
import {Row, Col, Modal, Form, Button, Input, Select, Tooltip, Spin, Divider} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';
import { RightSquareFilled, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

class ModalNuevoObjetivo extends Component {
    formRef = React.createRef();

    state = { serviciosSeleccionadosMappgin: [], };

     render() {
          const { onCloseModal, showModalNuevoObjetivo, listaCentroDeCostosActivos, fetchingListaCentroDeCostosActivos, onListaEstandarFiltro, onListaEstandarFiltroLimpiar, fetchingListaEstandaresByServicio, listaEstandaresDesempenoFiltroActivosByServicio, onGetTemasActivos, onGetTemasActivosLimpiar, fetchingListaTemasActivos, listaTemasActivos, serviciosByIdCentroCosto,  fetchingServiciosByIdCentroCosto, limpiarFormulario, onNewObjetivo, onGetServiciosByIdCentroCosto, onGetServiciosByIdCentroCostoLimpiar } = this.props;

          const handleCancel = () => {
               onCloseModal();
               this.formRef.current.resetFields();
               this.setState({ serviciosSeleccionadosMappgin: [] });
               onGetServiciosByIdCentroCostoLimpiar();
               onListaEstandarFiltroLimpiar();
               onGetTemasActivosLimpiar();
          };

          if (limpiarFormulario && limpiarFormulario === 'crearObjetivo') {
               this.formRef.current.resetFields();
               this.props.onLimpiarFormulario();
          }

          const onFinish = (formulario) => {
               console.log('Success:', formulario);
               onNewObjetivo(formulario, this.props.page, this.props.busqueda);
               this.setState({ serviciosSeleccionadosMappgin: [] });
               onGetServiciosByIdCentroCostoLimpiar();
          };

          const onFinishFailed = (errorInfo) => {
               console.log('Failed:', errorInfo);
          };

          const buscarEstandar = (value, index) => {
               if (value.length >= 3) {
                   console.log(value);
                   onListaEstandarFiltro(value, index)
               } else {
                   onListaEstandarFiltroLimpiar();
               }
          };

          const buscarTema = (value) => {
               if (value.length >= 3) {
                    console.log(value);
                    onGetTemasActivos(value)
               } else {
                    onGetTemasActivosLimpiar();
               }
          };

          const centroCostoSelected = (value) => {
               if(value) {
                    onGetServiciosByIdCentroCosto(parseInt(value.split(' - ')[0]));
                    this.formRef.current.resetFields(['servicios']);
                    this.setState({ serviciosSeleccionadosMappgin: [] });
               }
          };

          const servicioSelected = (value) => {
               if(value){
                    let serviciosSeleccionadosMappgin = [];
                    for(let i in value){
                         for(let j in serviciosByIdCentroCosto){
                              if(serviciosByIdCentroCosto[j].idServicio === parseInt(value[i].split(' - ')[0])){
                                   serviciosSeleccionadosMappgin.push(serviciosByIdCentroCosto[j])
                              }
                         }
                    }
                    this.setState({ serviciosSeleccionadosMappgin });
               }
          };

          const estandarSeleccionado = (value) => { if(value){ onListaEstandarFiltroLimpiar(); } };
        
          return (
               <Modal
                    visible={showModalNuevoObjetivo}
                    title='Nuevo Objetivo'
                    onCancel={handleCancel}
                    footer={false}
                    width={'60%'}
               >
                    <Spin spinning={this.props.fetchingNuevoObjetivo}>
                         <Form name="formulario" onFinish={onFinish}  ref={this.formRef} layout='vertical'>
                              <Row justify='space-around'>
                                   <Col span={11}>
                                        <Form.Item name='objetivo' label='Objetivo' rules={[{ required: true, message: 'Favor de llenar el campo'}]}>
                                             <Input maxLength={250} placeholder='Ingresa nombre del objetivo' />
                                        </Form.Item>
                                   </Col>

                                   <Col span={11}>
                                        <Form.Item name='tema' label='Tema' rules={[{ required: true, message: 'Favor de llenar el campo'}]}>
                                             <Select style={{ width: '100%', textAlign: 'left' }} placeholder="Ingresa el tema a buscar" loading={fetchingListaTemasActivos}  showSearch  notFoundContent={null} onSearch={buscarTema}>
                                                  {listaTemasActivos.map(option => <Option key={option.idTema + ' - ' + option.tema}>
                                                       <Tooltip placement="topLeft" title={option.tema}>
                                                            {option.idTema + ' - ' + option.tema}
                                                       </Tooltip>
                                                  </Option>)}
                                             </Select>
                                        </Form.Item>
                                   </Col>

                                   <Col span={11}>
                                        <Form.Item name='centroCosto' label='Centro de costo' rules={[{ required: true, message: 'Favor de llenar el campo'}]}>
                                             <Select style={{ width: '100%', textAlign: 'left' }} showSearch placeholder="Selecciona el centro de costo" loading={fetchingListaCentroDeCostosActivos} onChange={centroCostoSelected}>
                                                  {listaCentroDeCostosActivos.map(option => <Option key={option.idCentroCosto + ' - ' + option.centroCosto}>{option.idCentroCosto + ' - ' + option.centroCosto}</Option>)}
                                             </Select>
                                        </Form.Item>
                                   </Col>
                                   
                                   <Col span={11}>
                                        <Form.Item name='servicios' label='Servicio' rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                             <Select allowClear showSearch style={{ width: '100%' }} placeholder="Selecciona el servicio" loading={fetchingServiciosByIdCentroCosto} disabled={ !serviciosByIdCentroCosto[0] } mode='multiple' onChange={servicioSelected}>
                                                  {serviciosByIdCentroCosto.map(option => 
                                                       <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                            <Tooltip placement="topLeft" title={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                                 { option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre }
                                                            </Tooltip>
                                                       </Option>
                                                  )}
                                             </Select>
                                        </Form.Item>
                                   </Col>

                                   {/* <Col span={23}>
                                        <Form.Item name='estandar' label='Estándar' rules={[{ required: true, message: 'Favor de llenar el campo'}]}>
                                             <Select style={{ width: '100%', textAlign: 'left' }} placeholder="Ingresa el estándar a buscar" loading={fetchingListaEstandaresByServicio}  showSearch  notFoundContent={null} onSearch={buscarEstandar}>
                                                  {listaEstandaresDesempenoFiltroActivosByServicio.map(option => <Option key={option.idEstandarDesempeno + ' - ' + option.referencia + ': ' + option.descripcion}>
                                                       <Tooltip placement="topLeft" title={option.referencia + ': ' + option.descripcion}>
                                                            {option.idEstandarDesempeno + ' - ' + option.referencia + ': ' + option.descripcion}
                                                       </Tooltip>
                                                  </Option>)}
                                             </Select>
                                        </Form.Item>
                                   </Col> */}
                              </Row>

                              { serviciosByIdCentroCosto[0] && this.state.serviciosSeleccionadosMappgin ? (<>
                                   { this.state.serviciosSeleccionadosMappgin.map((index, key) =>
                                   
                                        <Row justify='space-around' key={index.idServicio}>
                                             <Divider orientation='left' style={{fontSize: 14}}>Estándar para el servicio: <b style={{color:'#1747ca'}}>{ index.nombre }</b></Divider>
                                             <Col span={23}>
                                                  <Form.Item
                                                       //label='Estándar'
                                                       name={'estandar-' + index.idServicio}
                                                       rules={[{ required: true, message: 'Favor de seleccionar una opción', }]}
                                                  >
                                                       <Select style={{ width: '100%', textAlign: 'left' }} placeholder="Ingresa el estándar a buscar" loading={fetchingListaEstandaresByServicio}  showSearch  notFoundContent={null} onSearch={(value) => buscarEstandar(value, index.idServicio)} onChange={estandarSeleccionado}>
                                                            {listaEstandaresDesempenoFiltroActivosByServicio.map(option => <Option key={option.idEstandarDesempeno + ' - ' + option.referencia + ': ' + option.descripcion}>
                                                                 <Tooltip placement="topLeft" title={option.referencia + ': ' + option.descripcion}>
                                                                      {option.idEstandarDesempeno + ' - ' + option.referencia + ': ' + option.descripcion}
                                                                 </Tooltip>
                                                            </Option>)}
                                                       </Select>
                                                  </Form.Item>
                                             </Col>
                                        </Row>
                                   ) }
                              </>):false }

                              <Divider />
                              <Row justify='space-around'>
                                   <Col span={11}>
                                        <Button className="nuevoUsuario" htmlType='submit'>
                                             Guardar
                                        </Button>
                                   </Col>
                              </Row>
                              
                         </Form>
                    </Spin>
               </Modal>
          )
     }
}

const mapStateToProps = state => {
     return {
          page: state.EvaluacionDeLaGestionReducer.page,
          busqueda: state.EvaluacionDeLaGestionReducer.busqueda,
          showModalNuevoObjetivo: state.EvaluacionDeLaGestionReducer.showModalNuevoObjetivo,
          fetchingListaTicketsReiterados: state.MecanismosDePagosReducer.fetchingListaTicketsReiterados,
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,

          listaCentroDeCostosActivos: state.CatalogosGeneralesReducer.listaCentroDeCostosActivos,
          fetchingListaCentroDeCostosActivos: state.CatalogosGeneralesReducer.fetchingListaCentroDeCostosActivos,
          
          fetchingListaEstandaresByServicio: state.EvaluacionDeLaGestionReducer.fetchingListaEstandaresByServicio,
          listaEstandaresDesempenoFiltroActivosByServicio: state.EvaluacionDeLaGestionReducer.listaEstandaresDesempenoFiltroActivosByServicio,

          listaTemasActivos: state.EvaluacionDeLaGestionReducer.listaTemasActivos,
          fetchingListaTemasActivos: state.EvaluacionDeLaGestionReducer.fetchingListaTemasActivos,

          serviciosByIdCentroCosto: state.EvaluacionDeLaGestionReducer.serviciosByIdCentroCosto,
          fetchingServiciosByIdCentroCosto: state.EvaluacionDeLaGestionReducer.fetchingServiciosByIdCentroCosto,

          fetchingNuevoObjetivo: state.EvaluacionDeLaGestionReducer.fetchingNuevoObjetivo,
     };
};

const mapDispatchToProps = dispatch => {
     return {
          onCloseModal: () => {
               dispatch({ type: 'SHOW_MODAL_NUEVO_OBJETIVO', showModalNuevoObjetivo: false });
          },
          onListaEstandarFiltro: (param, idServicio) => {
               dispatch({ type: "LISTA_ESTANDARES_ACTIVOS_PARAM_BY_SERVICIO_REQUEST", param, idServicio})
          },
          onListaEstandarFiltroLimpiar: () => {
               dispatch({ type: "LISTA_ESTANDARES_ACTIVOS_PARAM_BY_SERVICIO_SUCCESS", listaEstandaresDesempenoFiltroActivosByServicio: []})
          },
          onGetTemasActivos: (param) => {
               dispatch({ type: 'LISTA_TEMAS_ACTIVOS_REQUEST', param });
          },
          onGetTemasActivosLimpiar: () => {
               dispatch({ type: 'LISTA_TEMAS_ACTIVOS_SUCCESS', listaTemasActivos: [] });
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
          },
          onNewObjetivo: (formulario, page, busqueda) => {
               dispatch({ type: 'NUEVO_OBJETIVO_REQUEST', formulario, page, busqueda });
          },
          onGetServiciosByIdCentroCosto: (idCentroCosto) => {
               dispatch({ type: 'SERVICIOS_BY_IDCENTROCOSTO_REQUEST', idCentroCosto });
          },
          onGetServiciosByIdCentroCostoLimpiar: () => {
               dispatch({ type: 'SERVICIOS_BY_IDCENTROCOSTO_SUCCESS', serviciosByIdCentroCosto: [] });
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoObjetivo);