import React, { Component } from 'react';
import { connect } from "react-redux";
import { Form, Input, Button, Modal, Col, Spin, Row, InputNumber, Select,  message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

import L from 'leaflet';
import { Map, ImageOverlay, FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import VerificarContraseña from './VerificarContraseña';
import ArchivoConvenioUnidad from './ArchivoConvenioUnidad';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Option } = Select;
class ModalEditarUnidadFuncional extends Component {
    formRef = React.createRef();

    state = {
        fileImage: false,
        botonImage: false,
        botonEditar: false,
        fetchingImagen: true,
        imageUrl: null,
        height: 0,
        width: 0,
        geometry: [],
    }

    render() {
        
        const { fetchingEditarUnidadesFuncionales, showModalEditarUnidadesFuncionales, onEditarUnidadesFuncionales, onShowModalEditarUnidadesFuncionales,
            UnidadesFuncionalesSeleccionado, limpiarFormulario, fetchingListaAreasFuncionalesActivos,
            listaAreasFuncionalesActivos, bloqueado, cambiarVistaModalUnidad
        } = this.props;
        
        
        if (limpiarFormulario && limpiarFormulario === 'editarUnidadFuncional') {
            if(bloqueado){
                this.formRef.current.resetFields();
            }
            this.props.onLimpiarFormulario();
            this.setState({
                fileImage: false,
                botonImage: false,
                botonEditar: false,
                imageUrl: null,
                height: 0,
                width: 0,
                geometry: [],
                fetchingImagen: false,
            })
        }

        if(!this.state.imageUrl && UnidadesFuncionalesSeleccionado){
            const rutaImagen = UnidadesFuncionalesSeleccionado.areaFuncionalByIdAreaFuncional.rutaImagen;
            const img = new Image();
            const updateState = (width, height) => {
                this.setState({
                    imageUrl: rutaImagen,
                    width, 
                    height,
                    geometry: null,
                    fetchingImagen: false,
                });
            }
            img.onload = function() {
                console.log(this.width + 'x' + this.height);
                updateState(this.width, this.height);
            }
            img.src = UnidadesFuncionalesSeleccionado.areaFuncionalByIdAreaFuncional.rutaImagen;
        }

        if(UnidadesFuncionalesSeleccionado && !this.state.geometry){
            if(UnidadesFuncionalesSeleccionado.coordenadas){
                this.setState({
                    geometry: UnidadesFuncionalesSeleccionado.coordenadas
                }) 
            }
            else{
                this.setState({
                    geometry: []
                }) 
            }
        }
       
        // const enteroInput = (value) => {
        //     this.formRef.current.setFieldsValue({'cantidad': parseInt(value)});
        // }

        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };
    
        const onChangeImage = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else {
                this.setState({
                    ...this.state,
                    botonImage: true,
                    botonEditar: true,
                })
            }
            if (info.file.status === 'done') {
                var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,40}$");
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['plano']);
                    this.setState({...this.state, botonImage: false});
                }
                else {
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        ...this.state,
                        botonImage: true,
                        fileImage: true,
                    });
                }
            } else if (info.file.status === 'error') {
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['plano']);
                    this.setState({...this.state, botonImage: false});
                }
                else {
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        ...this.state,
                        fileImage: true
                    });
                }
                this.setState({
                    ...this.state,
                    botonImage: false,
                    botonEditar: false,
                });
            }
        }
    
        const onRemoveImage = () => {
            this.setState({
                ...this.state,
                fileImage: false,
                botonImage: false,
            });
        }

        const onFinish = formulario => {
            if(this.state.geometry.length>1){
                return message.error('La unidad funcional cuenta con más de una geometría. Favor de trazar solo una geometría.');
            }
            console.log('bloqueado', bloqueado)
            if(bloqueado){
                onEditarUnidadesFuncionales(formulario, UnidadesFuncionalesSeleccionado, this.state.geometry,this.props.page, this.props.busqueda, bloqueado);
            }
            else{
                this.props.onCambiarVista(2, formulario, this.state.geometry);
            }
            console.log('Success:', formulario, this.state.geometry);
        };
        const handleCancel = () => {
            onShowModalEditarUnidadesFuncionales();
            this.props.onCambiarVista(1, null, null);
            this.setState({
                fileImage: false,
                botonImage: false,
                botonEditar: false,
                fetchingImagen: false,
                imageUrl: null,
                height: 0,
                width: 0,
                geometry: [],
            })
        }

        const bounds = [
            [0, 0],
            [this.state.height, this.state.width],
        ]
        const style = { height: '35em', width: '100%' }

        const editGeometry = (geometriesE) => {
            console.log(`geometriesE`, geometriesE)
            
            this.setState({...this.state, geometry: geometriesE})
        }
        const _edited = (e) => {
            const {layers: {_layers}} = e;
            let editedArray = [];
            Object.values(_layers).map(({_leaflet_id, editing}) =>{
                //edited array
                console.log(`editing`, editing)
                editedArray = [...editedArray, {id: _leaflet_id, latlngs: editing.latlngs[0][0]}]
            });
            
            console.log('edited', e, editedArray);
            editGeometry(editedArray); 
        }

        const getGeoJson = () => {
            let geometry = this.state.geometry;
            console.log(`geometry`, geometry)
            return {
                type: "FeatureCollection",
                features: [{
                    type: "Feature",
                    properties: {},
                    geometry: {
                        type: "Polygon",
                        coordinates: 
                            geometry
                    }
                }]
            }
        }

        const _onFeatureGroupReady = reactFGref => {
            // populate the leaflet FeatureGroup with the geoJson layers
            if(!reactFGref ) return;
            let leafletGeoJSON = new L.GeoJSON(getGeoJson());
            let leafletFG = reactFGref.leafletElement;
            
            let layers = leafletFG._layers;
            let layers_ids = Object.keys(layers);
            
            if(layers_ids.length === 0) {
                //add layers
                leafletGeoJSON.eachLayer(layer => {
                    leafletFG.addLayer(layer);
                });
            }
        };
        return (
            <Modal
                visible={showModalEditarUnidadesFuncionales}
                title='Editar Unidad Funcional'
                onCancel={handleCancel}
                footer={false}
                width={'80%'}
            >
                {cambiarVistaModalUnidad === 1 ? (
                <Spin spinning={fetchingEditarUnidadesFuncionales}>
                    {UnidadesFuncionalesSeleccionado ? (
                    <Form {...layout}
                        name="formulario"
                        initialValues={UnidadesFuncionalesSeleccionado}
                        onFinish={onFinish}
                        ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Área funcional a la que pertenece"
                                    name="areaFuncional"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona la área funcional"
                                        loading={fetchingListaAreasFuncionalesActivos}
                                    >
                                        {listaAreasFuncionalesActivos.map(option => <Option key={option.idAreaFuncional}>{option.areaFuncional}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Unidad funcional"
                                    name="unidadFuncional"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={100} placeholder="Ingresa nombre de unidad funcional"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Clave de unidad"
                                    name="claveUnidad"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={30} placeholder="Ingresa la clave de unidad funcional" title='Sólo debe de contener letras en mayúscula' pattern="^[A-ZÑ ÁÉÓÚÍ0-9]+$" />
                                </Form.Item>
                            </Col>
                            {/* <Col span={11}>
                                <Form.Item
                                    label="Cantidad"
                                    name="cantidad"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber min={1} max={100} onChange={enteroInput} placeholder="Ingresa la cantidad" />
                                </Form.Item>
                            </Col> */}
                            <Col span={11}>
                                <Form.Item
                                    label="Plano"
                                    name="imagen"
                                    rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                >
                                    <Upload  customRequest={dummyRequest} onChange={onChangeImage} onRemove={onRemoveImage} accept='.jpg, .jpeg, .png, .svg' multiple={false} style={{ width: '100%' }}>
                                        {this.state.fileImage === false ? (
                                            <Button disabled={this.state.botonImage} className='btnCargaArchivos'>
                                                <UploadOutlined /> Click para subir plano
                                            </Button>
                                        ):false}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={23}>
                                <Spin spinning={this.state.fetchingImagen}>
                                    <p>Geometría</p>
                                    {console.log(`this.state.imageUrl`, this.state.imageUrl)}
                                    {this.state.imageUrl && this.state.geometry ? (
                                    <Map
                                        crs={L.CRS.Simple}
                                        minZoom={-4}
                                        bounds={bounds}
                                        style={style}
                                    >
                                        <FeatureGroup
                                            ref={reactFGref => {
                                            _onFeatureGroupReady(reactFGref);
                                            }}
                                        >
                                            <EditControl
                                                position="topright"
                                                // onCreated={_created}
                                                onEdited={_edited}
                                                // onDeleted={_deleted}
                                                draw={{
                                                    circle: false,
                                                    circlemarker: false,
                                                    marker: false,
                                                    rectangle: false,
                                                    polyline: false,
                                                    polygon: false,
                                                }}
                                                edit={{
                                                    remove: false,
                                                }}
                                            />
                                        </FeatureGroup>
                                        <ImageOverlay
                                            url={this.state.imageUrl}
                                            bounds={bounds}
                                            zIndex={10}
                                        />
                                    </Map>
                                    ):false}
                                </Spin>
                            </Col>
                            
                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Editar Unidad Funcional</Button>
                            </Col>
                        </Row>
                    </Form>
                    ):false}
                </Spin>
                ):(
                <>
                    {cambiarVistaModalUnidad === 2 ? (
                    <VerificarContraseña />
                    ):(
                    <ArchivoConvenioUnidad />
                    )}
                </>
                )}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarUnidadesFuncionales: state.CatalogosGeneralesReducer.fetchingEditarUnidadesFuncionales,
        showModalEditarUnidadesFuncionales: state.CatalogosGeneralesReducer.showModalEditarUnidadesFuncionales,
        fetchingListaPermisos: state.CatalogosGeneralesReducer.fetchingListaPermisos,
        listaPermisos: state.CatalogosGeneralesReducer.listaPermisos,
        UnidadesFuncionalesSeleccionado: state.CatalogosGeneralesReducer.UnidadesFuncionalesSeleccionado,
        page: state.CatalogosGeneralesReducer.page,
        busqueda: state.CatalogosGeneralesReducer.busqueda,
        bloqueado: state.CatalogosGeneralesReducer.bloqueado,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaAreasFuncionalesActivos: state.CatalogosGeneralesReducer.fetchingListaAreasFuncionalesActivos,
        listaAreasFuncionalesActivos: state.CatalogosGeneralesReducer.listaAreasFuncionalesActivos,
        cambiarVistaModalUnidad: state.CatalogosGeneralesReducer.cambiarVistaModalUnidad,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarUnidadesFuncionales: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_UNIDADESFUNCIONALES', showModalEditarUnidadesFuncionales: false, UnidadesFuncionalesSeleccionado: null})
        },
        onEditarUnidadesFuncionales: ( formulario, UnidadesFuncionalesSeleccionado, editedGeometry, page, busqueda, bloqueado ) => {
            dispatch({ type: "EDITAR_UNIDADESFUNCIONALES_REQUEST" , formulario, UnidadesFuncionalesSeleccionado, editedGeometry, page, busqueda, bloqueado });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        },
        onShowModalMapa: () => {
            dispatch({ type: 'SHOW_MODAL_MAPA_UNIDAD_FUNCIONAL', showModalMapaUnidadFuncional: true})
        },
        onCambiarVista: (cambiarVistaModalUnidad, formularioUnidad, editedGeometry) => {
            dispatch({ type: "CAMBIAR_VISTA_UNIDAD", cambiarVistaModalUnidad, formularioUnidad, editedGeometry })
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarUnidadFuncional);