import React, { useEffect, useRef, useState } from 'react';
import {connect} from 'react-redux';
import { Alert, Button, Row, Col,Select, Form, Input, DatePicker, TimePicker, Tooltip, message} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import moment from 'moment'

const {Option} = Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const FilterSection = props => {
     let formRef = useRef();
    
     const {
          checkboxData, order, onGetReporteDinamico,formularioReporte,listaServiciosActivosF, fetchingListaServiciosActivosF,
          handleSteps, onLimpiarFormulario,
          onGetServiciosActivos, onGetReporteDinamicoAgrupado, 
          



          onClearPartidasByIdsServicios, onGetPartidasByIdsServicios, onListaTiposBitacoraActivos, onGetModulos, listaInfoProyecto,
          listaTiposBitacoraActivos, fetchingListaTiposBitacoraActivos, listaModulosSelect, fetchingListaModulos, listaPartidasByIdsServicios,
          fetchingListaPartidasByIdsServicios, onGetListaModulosByIdUsuario, listaModulosByIdUsuario, fetchingListaModulosByIdUsuario, onGetListaTipoBitacoraByIdsModulos, onCleanListaTipoBitacoraByIdsModulos, listaTipoBitacoraByIdModulo, fetchingListaTipoBitacoraByIdModulo,
     } = props;

     let permisosUsuario = sessionStorage.getItem('permisosUsuario');
     if (permisosUsuario[0]) {
          permisosUsuario = JSON.parse(permisosUsuario);
     }

     const [boolGrupo, setBoolGrupo] = useState(true);
     const [boolClasificacion, setBoolClasificacion] = useState(true);
     let userInfo = sessionStorage.getItem('userInfo');
     if(userInfo[0]) {
          userInfo = JSON.parse(userInfo);
     }

     useEffect(() => {
          onGetServiciosActivos();
          onListaTiposBitacoraActivos();
          onGetModulos();
          onGetListaModulosByIdUsuario(userInfo[0].idUsuario);
     }, [])

     
     console.log('userInfo', userInfo)

     const onHandleSteps = value => {
          handleSteps(value);
          onLimpiarFormulario();
          setBoolGrupo(false);
          setBoolClasificacion(false);
     }

     const onFinish = formulario => {
          console.log('order', order)
          console.log('formulario', formulario);
          console.log('checkboxData', checkboxData)
          onGetReporteDinamico(formulario, 1, order, checkboxData);
          onGetReporteDinamicoAgrupado(formulario, 1, null);
          handleSteps('siguiente');
     }

     const servicioSeleccionado = (value) => {
          if(value.length > 0){
               onGetPartidasByIdsServicios(value);
               formRef.current.setFieldsValue({'idsPartidas': undefined});
          } else {
               formRef.current.setFieldsValue({'idsPartidas': undefined});
               onClearPartidasByIdsServicios();
          }
     };

     const  disabledDate = (fecha) => {
          if( fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicioOperacion) {
              return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicioOperacion;
          } else {
              if(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos){
                return fecha && moment(fecha.format('YYYY-MM-DD')) > moment((listaInfoProyecto.vigencia +  parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2])) + '-' +listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[1] + '-' + listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[0]);
            }
          }
     };

     const modulosSeleccionados = (record) => {
          if(record.length > 0){
               onGetListaTipoBitacoraByIdsModulos(record);
               formRef.current.setFieldsValue({'idsTiposBitacoras': undefined});
          } else {
               onCleanListaTipoBitacoraByIdsModulos();
               formRef.current.setFieldsValue({'idsTiposBitacoras': undefined});
          }
     };

     return (
          <>
               <Row style={{padding:'1em'}}>
                    <Col span={24}>
                         <Alert message="Selecciona los filtros a aplicar en el reporte." type="info" style={{ marginBottom: '16px' }} />
                    </Col>
               </Row>
               <Form {...layout}
                    name='formulario'
                    onFinish={onFinish}
                    
                    initialValues={formularioReporte}
                    ref={formRef}
               >
                    <Row justify='space-between' gutter={[8,8]} style={{padding:'1em'}}>
                         {/* {checkboxData.moduloC ? ( */}
                         <Col span={11}>
                              <Form.Item
                                   label="Módulo"
                                   name="idsModulos"
                                   rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                              >
                                   <Select
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el módulo de sistema"
                                        loading={fetchingListaModulosByIdUsuario}
                                        notFoundContent={null}
                                        mode="multiple"
                                        onChange={modulosSeleccionados}
                                   >
                                        {listaModulosByIdUsuario.map(option => <Option key={option.idModulo + ' - ' + option.nombre}>{option.idModulo + ' - ' + option.nombre}</Option>)}
                                   </Select>
                              </Form.Item>
                         </Col>
                         {/* ) : false} */}

                         {/* {checkboxData.tipoBitacoraC ? ( */}
                         <Col span={11}>
                              <Form.Item
                                   label="Tipo de bitácora"
                                   name="idsTiposBitacoras"
                                   rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                              >
                                   <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona un tipo de bitácora"
                                        loading={fetchingListaTipoBitacoraByIdModulo}
                                        showSearch={true}
                                        mode="multiple"
                                   >
                                        {listaTipoBitacoraByIdModulo.map(option => 
                                        <Option key={option.idTipoBitacora + ' - ' + option.tipoBitacora}>
                                             <Tooltip placement="topLeft" title={option.idTipoBitacora + ' - ' + option.tipoBitacora}>
                                                  {option.idTipoBitacora + ' - ' + option.tipoBitacora}
                                             </Tooltip>
                                        </Option>)}
                                   </Select>
                              </Form.Item>
                         </Col>
                         {/* ):false} */}

                         { checkboxData.servicioC ? (
                              <Col span={11}>
                                   <Form.Item
                                        label="Servicio"
                                        name="idsServicios"
                                        rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                   >
                                        <Select allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Selecciona un servicio a filtrar"
                                             loading={fetchingListaServiciosActivosF}
                                             onChange={servicioSeleccionado}
                                             showSearch={true}
                                             mode="multiple"
                                        >
                                             {listaServiciosActivosF.map(option => 
                                             <Option key={option.idServicio + ' - ' + option.nombre}>
                                                  <Tooltip placement="topLeft" title={option.idServicio + ' - ' + option.nombre}>
                                                       {option.idServicio + ' - ' + option.nombre}
                                                  </Tooltip>
                                             </Option>)}
                                        </Select>
                                   </Form.Item>
                              </Col>
                         ): false }

                         { checkboxData.partidaC ? (
                              <Col span={11}>
                                   <Form.Item
                                        label="Partida"
                                        name="idsPartidas"
                                        rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                   >
                                        <Select 
                                             allowClear 
                                             style={{width: '100%'}}
                                             placeholder="Selecciona una partida"
                                             loading={fetchingListaPartidasByIdsServicios}
                                             showSearch
                                             mode="multiple"
                                        >
                                             {listaPartidasByIdsServicios.map(option => 
                                                  <Option key={option.idPartida + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                       <Tooltip title={option.nomenclatura + ': ' + option.nombre}>
                                                            {option.idPartida + ' - ' + option.nomenclatura + ': ' + option.nombre}
                                                       </Tooltip>
                                                  </Option>)}
                                        </Select>
                                   </Form.Item>
                              </Col>
                         ): false }

                         { checkboxData.nombreC ? (
                              <Col span={11}>
                                   <Form.Item
                                        label="Nombre Módulo de Bitácora"
                                        name="nombre"
                                   >
                                        <Input maxLength={50} placeholder='Escribe el nombre del módulo de bitácora'/>
                                   </Form.Item>
                              </Col>
                         ): false }

                         { checkboxData.responsableSerC ? (
                              <Col span={11}>
                                   <Form.Item
                                        label="Responsable de Servicio"
                                        name="responsableSer"
                                   >
                                        <Input maxLength={50} placeholder='Escribe el nombre del responsable'/>
                                   </Form.Item>
                              </Col>
                         ): false }

                         { checkboxData.responsableRegC ? (
                              <Col span={11}>
                                   <Form.Item
                                        label="Responsable de Registro"
                                        name="responsableReg"
                                   >
                                        <Input maxLength={50} placeholder='Escribe el nombre del responsable'/>
                                   </Form.Item>
                              </Col>
                         ): false }
                         
                         {checkboxData.fechaC ? (
                         <Col span={11}>
                              <Form.Item
                                   label="Fecha"
                                   name="fecha"
                              >
                                   <RangePicker
                                        format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio", "Fecha fin"]}
                                        disabledDate={disabledDate}
                                   />
                              </Form.Item>
                         </Col>
                         ) : false}

                    </Row>
                    <Row style={{padding:'1em'}}>
                         <Col span={6}>
                         <Button className="btnFiltrar" onClick={() => onHandleSteps('anterior')} style={{ width: '100%', marginTop: '20px' }}>Anterior</Button>
                         </Col>
                         <Col span={6} offset={12}>
                         <Button className="btnFiltrar"  htmlType='submit' style={{ width: '100%', marginTop: '20px' }}>Siguiente</Button>
                         </Col>
                    </Row>
               </Form>
          </>
     )
}

const mapStateToProps = state => {
     return {

          formularioReporte: state.ConfiguracionYSeguridadReducer.formularioReporte,

          listaTiposBitacoraActivos: state.ConfiguracionYSeguridadReducer.listaTiposBitacoraActivos,
          fetchingListaTiposBitacoraActivos: state.ConfiguracionYSeguridadReducer.fetchingListaTiposBitacoraActivos,
          listaModulosSelect: state.ConfiguracionYSeguridadReducer.listaModulosSelect,
          fetchingListaModulos: state.ConfiguracionYSeguridadReducer.fetchingListaModulos,
          listaPartidasByIdsServicios: state.ConfiguracionYSeguridadReducer.listaPartidasByIdsServicios,
          fetchingListaPartidasByIdsServicios: state.ConfiguracionYSeguridadReducer.fetchingListaPartidasByIdsServicios,

          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
          listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,

          listaModulosByIdUsuario: state.ConfiguracionYSeguridadReducer.listaModulosByIdUsuario,
          fetchingListaModulosByIdUsuario: state.ConfiguracionYSeguridadReducer.fetchingListaModulosByIdUsuario,
          listaTipoBitacoraByIdModulo: state.ConfiguracionYSeguridadReducer.listaTipoBitacoraByIdModulo,
          fetchingListaTipoBitacoraByIdModulo: state.ConfiguracionYSeguridadReducer.fetchingListaTipoBitacoraByIdModulo,
     }
}

const mapDispatchToProps = dispatch => {
     return {
          
          onGetReporteDinamico: (formulario, page, order, checkboxData) => {
               dispatch({ type: 'LISTA_REPORTE_DINAMICO_BITACORA_BUSQUEDA_REQUEST', formulario, page, order, checkboxData});
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO_REPORTE_BITACORA', formularioReporte: {},listaReporteDinamicoBitacora: [], listaReporteDinamicoCantidadBitacora: null})
          },
          onGetServiciosActivos: () => {
               dispatch({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST'})
          },
          onGetReporteDinamicoAgrupado: (formulario, page, agrupado) => {
               dispatch({ type: 'REPORTE_DINAMICO_AGRUPADO_BITACORA_REQUEST', formulario, page, agrupado});
          },
          onListaTiposBitacoraActivos: () => {
               dispatch ({ type: 'LISTA_TIPOS_BITACORA_ACTIVOS_REQUEST',  });
          },
          onGetModulos: () => {
               dispatch({ type: 'LISTA_MODULOS_REQUEST'})
          },
          onGetPartidasByIdsServicios: (idsServicios) => {
               dispatch({ type: 'LISTA_PARTIDAS_BY_IDSSERVICIOS_REQUEST', idsServicios });
          },
          onClearPartidasByIdsServicios: () => {
               dispatch({ type: 'LISTA_PARTIDAS_BY_IDSSERVICIOS_SUCCESS', listaPartidasByIdsServicios: [] });
          },
          onGetListaModulosByIdUsuario: (idUsuario) => {
               dispatch({ type: 'GET_MODULOS_BY_IDUSUARIO_REQUEST', idUsuario });
          },
          onGetListaTipoBitacoraByIdsModulos: (idsModulos) => {
               dispatch({ type: 'LISTA_TIPO_BITACORAS_BY_ID_MODULO_REQUEST', idsModulos });
          },
          onCleanListaTipoBitacoraByIdsModulos: () => {
               dispatch({ type: 'LISTA_TIPO_BITACORAS_BY_ID_MODULO_SUCCESS', listaTipoBitacoraByIdModulo: [] });
          },
     }
}

export default connect (mapStateToProps, mapDispatchToProps) (FilterSection);