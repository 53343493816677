export const LOGIN_API_CALL_REQUEST = "LOGIN_API_CALL_REQUEST";
export const LOGIN_API_CALL_SUCCESS = "LOGIN_API_CALL_SUCCESS";
export const LOGIN_API_CALL_FAILURE = "LOGIN_API_CALL_FAILURE";

export const CHANGE_SELECTED_PROFILE = "CHANGE_SELECTED_PROFILE";

export const MODAL_RESET_PASSWORD = 'MODAL_RESET_PASSWORD';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_FAILURE = 'LOG_OUT_FAILURE';

export const MODAL_EXPIRAR_SESION = 'MODAL_EXPIRAR_SESION';