import React from 'react';
import { Row, Col, Button, Modal, Input, Switch, Table, Card, Tooltip } from 'antd';
import { connect } from "react-redux";
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevaProgramacion from './ModalNuevaProgramacion';
import ModalEditarProgramacion from './ModalEditarProgramacion';

const { Search } = Input;

const confirm = Modal.confirm;

class ProgramacionMedicionConsumosContainer extends React.Component {
     formRef = React.createRef();

     componentDidMount() {
          this.props.onGetServiciosPublicos();
          this.props.onGetVariablesMedicion();
          this.props.onGetListaProgramacion(1, '');
     }

     state = {
          page: 1,
          busqueda: '',
     }


     render() {
          const {
               listaProgramacionMedicionBusqueda,
               listaProgramacionMedicionCantidad,
               fetchingListaProgramacionMedicionBusqueda,
               onShowModalNuevaProgramacion,
               onGetListaProgramacion,
               onShowModalEditarProgramacion,
               onCambiarEstado,
          } = this.props;

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if (permisosUsuario[0]) {
               permisosUsuario = JSON.parse(permisosUsuario);
          }

          const columns = [
               {title: 'Nombre del Servicio Público', dataIndex: 'servicioPublico', key: 'servicioPublico', width: '25%'},
               {title: 'Horario de Lectura', dataIndex: 'horariosProgramacion', key: 'horariosProgramacion', width: '25%'}, 
               {title: 'Variables de Medición', dataIndex: 'variablesMedicionTabla', key: 'variablesMedicionTabla', width: '40%'},
               {title: 'Activo', dataIndex: 'activo', key: 'activo', align: 'center',
               render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.programaciónDeMediciónYConsumoActivoInactivo} onChange = {() => handleDesactivar(record)} />
               ), width: '5%'}, 
               {title: 'Editar', key: 'editar', align: 'center',
               render: (record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarProgramaciónDeMedicionYConsumoDeServiciosPublicos}  onClick={() => onEditar(record)}><EditOutlined /></a>
               ), width: '5%'},
          ];

          const handleDesactivar = (key) => {
               console.log(key.idProgramacionConsumo);
               let page = this.state.page;
               let busqueda = this.state.busqueda;
               confirm({
                    title: '¿Estás seguro que deseas cambiar el estado de la programación?',
                    content: '',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                         onCambiarEstado(key.idProgramacionConsumo, page, busqueda);
                    },
                    onCancel() {
                         console.log('Cancel');
                    },
               });
          };

          const handleTableChange = (pagination) => {
               this.setState({ page: pagination.current,});
          };

          const onBuscar = (param) => {
               const busqueda = param;
               this.setState({ busqueda: busqueda, page: 1 });
               
               onGetListaProgramacion(1, busqueda);
          };

          const onNueva = () => {
               onShowModalNuevaProgramacion(this.state.page);
          }

          const onEditar = (key) => {
               console.log('key', key);
               onShowModalEditarProgramacion(key, this.state.page, this.state.busqueda, key.NumLecturas);
          };

          return (
               <Card className='cardCatalogos' title={<div className='headerLista'>Programación de Medición y Consumos</div>}>
                    <Row justify='space-around' style={{ textAlign: 'left' }} gutter={[8,8]}>                         
                         <Col span={22}>
                              <Search className='buscarModulos' placeholder='Ingresar nombre del servicio a buscar' onSearch={value => onBuscar(value)} />
                         </Col>
                         {/* <Col span={7}>
                              <Button onClick={onNueva} disabled={!permisosUsuario.crearProgramaciónDeMedicionYConsumoDeServiciosPúblicos} className='nuevoUsuario'>Nueva Programación</Button>
                         </Col> */}
                         <Col span={1}>
                              <Tooltip title='Nueva Programación' placement="topRight">
                                   <Button className="iconTer" shape="circle" onClick={onNueva} disabled={!permisosUsuario.crearProgramaciónDeMedicionYConsumoDeServiciosPúblicos} icon={<PlusOutlined />}/>
                              </Tooltip>
                         </Col>
                         <Col span={24}>
                              <Table
                                   size="small"
                                   rowKey="idProgramacionConsumo"
                                   columns={columns}
                                   dataSource={listaProgramacionMedicionBusqueda}
                                   loading={fetchingListaProgramacionMedicionBusqueda}
                                   locale={{ emptyText: 'Sin datos' }}
                                   onChange={handleTableChange}
                                   pagination={{ total: listaProgramacionMedicionCantidad, simple: true, current: this.state.page }}
                              />
                         </Col>

                         <ModalNuevaProgramacion />
                         <ModalEditarProgramacion />
                    </Row>
               </Card>
          );
     }
}

const mapStateToProps = state => {
     return {
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          listaProgramacionMedicionBusqueda: state.ServiciosPublicosReducer.listaProgramacionMedicionBusqueda,
          listaProgramacionMedicionCantidad: state.ServiciosPublicosReducer.listaProgramacionMedicionCantidad,
          fetchingListaProgramacionMedicionBusqueda: state.ServiciosPublicosReducer.fetchingListaProgramacionMedicionBusqueda,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onShowModalNuevaProgramacion: (page) => {
               dispatch({ type: 'SHOW_MODAL_NUEVA_PROGRAMACION_MEDICION', showModalNuevaProgramacion: true, page });
          },
          onShowModalEditarProgramacion: (programacionSeleccionada, page, busqueda, cantidadHorarios) => {
               dispatch({ type: 'SHOW_MODAL_EDITAR_PROGRAMACION_MEDICION', showModalEditarProgramacion: true, programacionSeleccionada, page, busqueda, cantidadHorarios });
          }, 
          onGetServiciosPublicos: () => {
               dispatch({ type: 'LISTA_SERVICIOS_PUBLICOS_ACTIVOS_REQUEST' });
          },
          onGetVariablesMedicion: () => {
               dispatch({ type: 'LISTA_VARIABLES_MEDICION_ACTIVOS_REQUEST' }); 
          },
          onGetListaProgramacion: (page, busqueda) => {
               dispatch({ type: 'LISTA_PROGRAMACION_MEDICION_BUSQUEDA_REQUEST', page, busqueda });
          },
          onCambiarEstado: (idProgramacionConsumo, page, busqueda) => {
               dispatch({ type: 'ESTADO_PROGRAMACION_MEDICION_REQUEST', idProgramacionConsumo, page, busqueda });
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramacionMedicionConsumosContainer);
