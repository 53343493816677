import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css'
import ModalNuevaCategoriaFallaDeCalidad from './ModalNuevaCategoriaFallaDeCalidad';
import ModalEditarCagoriaFallaDeCalidad from './ModalEditarCategoriaFallaDeCalidad';

const confirm = Modal.confirm;
const { Search } = Input;

class CatalogoFallasDeCalidad extends React.Component {
    state = {
        page: 1,
        busqueda: '',
    }

    componentDidMount() {
        this.props.onGetCategoriasFallasDeCalidad(1);
        this.props.onRequestInfoProyecto();
    }

    render() {
        const { fetchingListaCategoriasFallasDeCalidadBusqueda, listaCategoriasFallasDeCalidadBusqueda, onGetCategoriasFallasDeCalidad,
            onModalNuevaCategoriaFallaDeCalidad, onModalEditarCategoriaFallaDeCalidad, onEstadoCategoriaFallaDeCalidad, listaCategoriasFallasDeCalidadBusquedaCantidad } = this.props;
                
        console.log('ListaCategoriasDeFallasDeCalidad');
        console.log(listaCategoriasFallasDeCalidadBusqueda);
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columns = [{
            title: 'Categoría de fallas de calidad',
            dataIndex: 'fallaCalidad',
            key: 'fallaCalidad',
        }, {
            title: 'Descripción',
            dataIndex: 'descripcion',
            key: 'descripcion',
        }, {
            title: 'Valor porcentaje',
            dataIndex: 'valorPorcentaje',
            key: 'valorPorcentaje',
            align: 'center',
        }, {
            title: 'Fecha Alta',
            dataIndex: 'fechaAlta',
            key: 'fechaAlta',
        }, {
            title: 'Activo',
            dataIndex: 'activo',
            key: 'activo',
            render: (text, record) => (
                <Switch checked={text} disabled={!permisosUsuario.categoriaDeFallasDeCalidadActivoinactivo} onChange = {() => handleDesactivar(record)} />
            ),
        }, {
            title: 'Editar',
            key: 'editar',
            //width: '6%',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.editarCategoriaDeFallasDeCalidad}  onClick={() => onEditar(record)}><EditOutlined /></a>
            ),
        },
        ];

        const onBuscarCategoriasFallasDeCalidad = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            });
            console.log(busqueda);
            onGetCategoriasFallasDeCalidad(1, busqueda);
        } 

        const handleDesactivar = (key) => {
            console.log(key.idFallaCalidad);
            let page = this.state.page
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado de la categoría de falla de calidad "' + key.descripcion + '"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoCategoriaFallaDeCalidad(key.idFallaCalidad, page);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }

        const onEditar = (key) => {
            onModalEditarCategoriaFallaDeCalidad(key, this.state.page);

        }

        const onNuevoCategoriaFallaDeCalidad = () => {
            onModalNuevaCategoriaFallaDeCalidad(this.state.page);
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            onGetCategoriasFallasDeCalidad(pagination.current, this.state.busqueda);
        }


        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search className='buscarModulos' placeholder='Ingresar categoría de fallas de calidad' onSearch={value => onBuscarCategoriasFallasDeCalidad(value)} />
                    </Col>
                    {/* <Col span={9}>
                        <Button onClick={onNuevoCategoriaFallaDeCalidad} disabled={!permisosUsuario.crearCategoriaDeFallasDeCalidad} className='nuevoUsuario'>Nueva Categoría Fallas de Calidad</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nueva Categoría Fallas de Calidad' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoCategoriaFallaDeCalidad} disabled={!permisosUsuario.crearCategoriaDeFallasDeCalidad} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table
                            size="small"
                            rowKey="fallaCalidad"
                            columns={columns}
                            dataSource={listaCategoriasFallasDeCalidadBusqueda}
                            loading={fetchingListaCategoriasFallasDeCalidadBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                        // scroll={scroll}
                        onChange={handleTableChange}
                        pagination={{ total: listaCategoriasFallasDeCalidadBusquedaCantidad, simple: true, current: this.state.page }}
                        />
                    </Col>
                </Row>
                <ModalNuevaCategoriaFallaDeCalidad/>
                <ModalEditarCagoriaFallaDeCalidad/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        listaCategoriasFallasDeCalidadBusqueda: state.MecanismosDePagosReducer.listaCategoriasFallasDeCalidadBusqueda,
        fetchingListaCategoriasFallasDeCalidadBusqueda: state.MecanismosDePagosReducer.fetchingListaCategoriasFallasDeCalidadBusqueda,
        listaCategoriasFallasDeCalidadBusquedaCantidad: state.MecanismosDePagosReducer.listaCategoriasFallasDeCalidadBusquedaCantidad,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetCategoriasFallasDeCalidad: (page, busqueda) => {
            dispatch({ type: "CATEGORIAS_FALLAS_CALIDAD_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
        },
        onEstadoCategoriaFallaDeCalidad: (idFallaCalidad, page) => {
            dispatch({ type: "ESTADO_CATEGORIA_FALLA_CALIDAD_REQUEST", idFallaCalidad: idFallaCalidad, page: page});
        },
        onModalNuevaCategoriaFallaDeCalidad: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVA_CATEGORIA_FALLA_CALIDAD", showModalNuevaCategoriaFallaDeCalidad: true, page: page })
        },
        onModalEditarCategoriaFallaDeCalidad: (CategoriaFallaDeCalidadSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_CATEGORIA_FALLA_CALIDAD", showModalEditarCategoriaFallaDeCalidad: true, CategoriaFallaDeCalidadSeleccionado:CategoriaFallaDeCalidadSeleccionado, page:page});
        },
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoFallasDeCalidad);