import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, Select, Tooltip, Row } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalEditarTipoBitacora extends Component {
    formRef = React.createRef();
    render() {
        const { fetchingEditarTipoBitacora, showModalEditarTipoBitacora, onEditarTipoBitacora, onShowModalEditarTipoBitacora,
            fetchingListaServiciosActivosF, listaServiciosActivosF, limpiarFormulario, onLimpiarFormulario,
            fetchingListaRoles, listaRoles, page, busqueda, tipoBitacoraSeleccionada
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onEditarTipoBitacora(formulario, page, busqueda, tipoBitacoraSeleccionada);
        };
        

        const handleCancel = () => {
            onShowModalEditarTipoBitacora(page, busqueda);
            this.formRef.current.resetFields();
        }

        if(limpiarFormulario && limpiarFormulario === 'editarTipoBitacora'){
            this.formRef.current.resetFields();
            onLimpiarFormulario();
        };

        return (
            <Modal
            visible={showModalEditarTipoBitacora}
            title='Editar Tipo de Bitácora'
            onCancel={handleCancel}
            footer={false}
            width='35%'
            >
            {tipoBitacoraSeleccionada ? (
                <Spin spinning={fetchingEditarTipoBitacora}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    initialValues={tipoBitacoraSeleccionada}
                    >
                        <Row justify='space-around'>
                            <Col span={24}>
                                <Form.Item
                                    label="Tipo de bitácora"
                                    name="tipoBitacora"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={100} placeholder="Ingresa nombre del tipo de bitácora" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Descripción"
                                    name="descripcion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <TextArea
                                        maxLength={450}
                                        placeholder='Ingresa la descripción'
                                        autoSize
                                    />
                                </Form.Item>
                            </Col>
                            {/* <Col span={11}>
                                <Form.Item
                                    label="Servicio"
                                    name="servicioEditar"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona un servicio"
                                        loading={fetchingListaServiciosActivosF}
                                        showSearch={true}
                                    >
                                        {listaServiciosActivosF.map(option => 
                                        <Option key={option.idServicio + ' - ' + option.nombre}>
                                            <Tooltip placement="topLeft" title={option.idServicio + ' - ' + option.nombre}>
                                                {option.idServicio + ' - ' + option.nombre}
                                            </Tooltip>
                                        </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Rol"
                                    name="rolEditar"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona un rol"
                                        loading={fetchingListaRoles}
                                        showSearch={true}
                                    >
                                        {listaRoles.map(option => 
                                        <Option key={option.idRol + ' - ' + option.descripcion}>
                                            <Tooltip placement="topLeft" title={option.idRol + ' - ' + option.descripcion}>
                                                {option.idRol + ' - ' + option.descripcion}
                                            </Tooltip>
                                        </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col> */}
                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Editar Tipo de Bitácora</Button>
                            </Col>
                        </Row>
                       
                    </Form>
                </Spin>
            ):false}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarTipoBitacora: state.ConfiguracionYSeguridadReducer.fetchingEditarTipoBitacora,
        showModalEditarTipoBitacora: state.ConfiguracionYSeguridadReducer.showModalEditarTipoBitacora,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        page: state.ConfiguracionYSeguridadReducer.page,
        busqueda: state.ConfiguracionYSeguridadReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaRoles: state.ConfiguracionYSeguridadReducer.fetchingListaRoles,
        listaRoles: state.ConfiguracionYSeguridadReducer.listaRoles,
        tipoBitacoraSeleccionada: state.ConfiguracionYSeguridadReducer.tipoBitacoraSeleccionada,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarTipoBitacora: (page, busqueda) => {
            dispatch({ type: 'MODAL_EDITAR_TIPO_BITACORA', showModalEditarTipoBitacora: false, page, busqueda })
        },
        onEditarTipoBitacora: ( formulario, page, busqueda, tipoBitacoraSeleccionada ) => {
            dispatch({ type: "EDITAR_TIPO_BITACORA_REQUEST" , formulario, page, busqueda, tipoBitacoraSeleccionada, busqueda });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarTipoBitacora);