import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import { Row, Col, Modal, Table, Input, Select, Button, Form, Card, Alert, Spin, Descriptions, message } from 'antd';
import { connect } from "react-redux";
import { FileSearchOutlined } from '@ant-design/icons';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalAsignarActivos from './ModalAsignarActivos';

import imaSearch from '../../../../assets/undraw_current_location_re_j130.svg';
import ReactVivus from "react-vivus";

const { Search } = Input;
const { Option } = Select;

const AsignacionActivosContainer = props => {
    const [form] = Form.useForm();

    useEffect(() => {
        props.onGetAreasFuncionales();
        if(props.showAlertMsg===true){
            message.warning('El área no cuenta con interiores funcionales, por favor elije un área o unidad distinta');
        }
       
    },[props.showAlertMsg]);

    const [page, setPage] = useState(1);
    const [busqueda, setBusqueda] = useState('');
    const [order, setOrder] = useState('ASC');
    const [field, setField] = useState('idActivo');
    const [any, setAny] = useState('');
    const [visible, setVisible] = useState(false);
    const [activoSeleccionado, setActivoSeleccionado] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [hasSelected, setHasSelected] = useState(true);
    const [idAreaFuncional, setIdAreaFuncional] = useState(null);
    const [idUnidadFuncional, setIdUnidadFuncional] = useState(null);
    const [idInteriorFuncional, setIdInteriorFuncional] = useState(null);
    const [idSubInteriorFuncional, setidSubInteriorFuncional] = useState(null);
    const [dataDescription, setDataDescription] = useState([])
    const [selectedRows, setSelectedRows] =  useState([])
    
    const handleCancel = () => {
        setVisible(false)
    };

    const { 
        fetchinglistaAsignacionActivosBusqueda, 
        listaAsignacionActivosBusqueda, 
        onGetAsignacionActivos,
        listaAsignacionActivosBusquedaCantidad,
        fetchingListaAreasFuncionalesActivas,
        listaAreasFuncionalesActivas,
        onModalAsignarActivos,
        fetchingListaUnidadesFuncionalesActivas,
        listaUnidadesFuncionalesActivas,
        fetchingListaInteriorFuncional,
        listaInteriorFuncional,
        fetchingListaSubinteriorFuncional,
        listaSubinteriorFuncional,
        limpiarFormulario,
        showAlertMsg,
        onGetUnidadesFuncionalesActivas, 
        onGetInterior, onGetSubinterior,
        limpiarAsignacionActivos, onLimpiarInteriorFuncional, onLimpiarSubInteriorFuncional,
    } = props;

    let permisosUsuario = sessionStorage.getItem('permisosUsuario');

    if(permisosUsuario[0]){
        permisosUsuario = JSON.parse(permisosUsuario);
    }

    console.log("showAlertMsg",showAlertMsg)
    
    const onUnidadesFuncionales = (idAreaFuncional) => {            
        form.resetFields(['idUnidadFuncional','idInteriorFuncional', 'idSubInteriorFuncional']);
        onGetUnidadesFuncionalesActivas(idAreaFuncional);
        setIdAreaFuncional(idAreaFuncional)
        setSelectedRowKeys([])
        limpiarAsignacionActivos();
        onLimpiarInteriorFuncional();
        onLimpiarSubInteriorFuncional();
    }

    const onInterioresFuncionales = (idUnidadFuncional) => {
        form.resetFields(['idInteriorFuncional', 'idSubInteriorFuncional']);
        onGetInterior(idAreaFuncional, idUnidadFuncional);
        setIdUnidadFuncional(idUnidadFuncional)
        setSelectedRowKeys([])
        limpiarAsignacionActivos();
        onLimpiarSubInteriorFuncional();
    }


    console.log("listaAsignacionActivosBusqueda",listaAsignacionActivosBusqueda)

    const onSubInterioresFuncionales = (idInteriorFuncional) => {
        form.resetFields(['idSubInteriorFuncional']);
        onGetSubinterior(idAreaFuncional, idUnidadFuncional, idInteriorFuncional);
        
        const busqueda = { order: order, field: field, idAreaFuncional: idAreaFuncional, idUnidadFuncional: idUnidadFuncional, idInteriorFuncional: idInteriorFuncional }
        setIdInteriorFuncional(idInteriorFuncional)
        setSelectedRowKeys([])
        setBusqueda(busqueda)
        
        onGetAsignacionActivos(1, busqueda);
    }

    const onSubInterioresFuncionalesFinal = (idSubInteriorFuncional) => {  
        const busqueda = { order: order, field: field, idAreaFuncional: idAreaFuncional, idUnidadFuncional: idUnidadFuncional, idInteriorFuncional: idInteriorFuncional, idSubInteriorFuncional: idSubInteriorFuncional }
        setSelectedRowKeys([])
        setBusqueda(busqueda)
        setidSubInteriorFuncional(idSubInteriorFuncional)
        onGetAsignacionActivos(1, busqueda);
    }

    const onBusqueda = (any) => {         
        const busqueda = { any: any, order: order, field: field, idAreaFuncional: idAreaFuncional, idUnidadFuncional: idUnidadFuncional, idInteriorFuncional: idInteriorFuncional, idSubInteriorFuncional: idSubInteriorFuncional }
        setSelectedRowKeys([])
        setBusqueda(busqueda)
        onGetAsignacionActivos(1, busqueda);
    }


    if (limpiarFormulario && limpiarFormulario === 'crearAsignacionActivos') {
        setHasSelected(true)
        form.resetFields();
        props.onLimpiarFormulario();
    }

    const onAsignar = () => {
        onModalAsignarActivos(selectedRows, page);
        setHasSelected(false)
    }

    const showMasInfo = (activoSeleccionado) => {
        let lista = [];
        let data=[];
        lista.push(activoSeleccionado);

        let { nombre, familia, subFamilia, centroCosto, descripcion, marca, modelo, especificaciones, costoUnidad, condicion, fabricante, usoBasico, codigoArea, claveArea } = activoSeleccionado;
        
        data = [
            { label: 'Grupo de Servicio', data: nombre, id: 1 },
            { label: 'Familia', data: familia, id: 2 },
            { label: 'Subfamilia', data: subFamilia, id: 3 },
            { label: 'Centro de Costos', data: centroCosto, id: 4 },
            { label: 'Nombre', data: descripcion, id: 5 },
            { label: 'Marca', data: marca, id: 6 },
            { label: 'Modelo', data: modelo, id: 7 },
            { label: 'Especificaciones', data: especificaciones, id: 8 },
            { label: 'Costo Unidad', data: costoUnidad, id: 9 },
            { label: 'Condición', data: condicion, id: 10 },
            { label: 'Fabricante', data: fabricante, id: 11 },
            { label: 'Uso Básico', data: usoBasico, id: 12 },
            { label: 'Código Área', data: codigoArea, id: 13 },
            { label: 'Clave Área', data: claveArea, id: 14 },
        ];
        setVisible(true)
        setActivoSeleccionado(lista)
        setDataDescription(data)
    }
    
    const columns = [{
            title: 'Descripción',
            dataIndex: 'descripcion',
            key: 'descripcion',
            sorter: true,
        },{
            title: 'Código de Equipo',
            dataIndex: 'codigoEquipo',
            key: 'codigoEquipo',
            sorter: true,
        },{
            title: 'Código Interno',
            dataIndex: 'codigoInterno',
            key: 'codigoInterno',
            sorter: true,
        },{
            title: 'Num Serie',
            dataIndex: 'serieNum',
            key: 'serieNum',
            sorter: true,
        },{
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
            sorter: true,
        },{
            title: 'Clave Ubicación',
            dataIndex: 'claveUbicacion',
            key: 'claveUbicacion',
        },{
            title: 'Más',
            key: '',
            render: (record) => (
                <a className='editarIcono' onClick={ () => showMasInfo(record) }> <FileSearchOutlined /> 
                </a>
            ),          
        },
    ];

    const handleTableChange = (pagination, filtro, orden)=>{
        setPage(pagination.current)
        if(orden){
            let campo = 'ace.descripcion';
            let order = 'ASC';
        
            if(orden.order === 'descend'){
                order = 'DESC';
            }

            if(orden.columnKey === 'descripcion'){
                campo = 'ace.descripcion';
            }else if(orden.columnKey === 'codigoEquipo'){
                campo = 'codigoEquipo';
            }else if(orden.columnKey === 'codigoInterno'){
                campo = 'codigoInterno';
            }else if(orden.columnKey === 'serieNum'){
                campo = 'serieNum';
            }else {
                campo = 'sku'; 
            }
            
            const busqueda = { any: any, order: order, field: campo, idAreaFuncional: idAreaFuncional, idUnidadFuncional: idUnidadFuncional, idInteriorFuncional: idInteriorFuncional, idSubInteriorFuncional: idSubInteriorFuncional }
            onGetAsignacionActivos(pagination.current, busqueda);
            setOrder(order)
            setField(campo)
            setBusqueda(busqueda)
            
        }
        else{
            onGetAsignacionActivos(pagination.current, busqueda);
        }
    } 
    
        const onChangeTabla = (record, selected, selectedRows) => {
            if(record.length !== 0){
                setHasSelected(false)
            }else{
                setHasSelected(true)
            }
            setSelectedRowKeys(record)
            setSelectedRows(selected)       
        };

        const rowSelection = {
            selectedRowKeys,
            onChange: onChangeTabla,
        };

    const columnsC = [
        { title: 'Grupo de Servicio', dataIndex: 'nombre', key: 'nombre', width:150 },
        { title: 'Familia', dataIndex: 'familia', key: 'familia', width:150 },
        { title: 'Subfamilia', dataIndex: 'subFamilia', key: 'subFamilia' },
        { title: 'Centro de Costos', dataIndex: 'centroCosto', key: 'centroCosto', width:150 },
        { title: 'Nombre', dataIndex: 'descripcion', key: 'descripcion', width:150 },
        { title: 'Marca', dataIndex: 'marca', key: 'marca', width:150 },
        { title: 'Modelo', dataIndex: 'modelo', key: 'modelo', width:150 },
        { title: 'Especificaciones', dataIndex: 'especificaciones', key: 'especificaciones', width:180 },
        { title: 'Costo Unidad', dataIndex: 'costoUnidad', key: 'costoUnidad' },
        { title: 'Condición', key: 'condicion', dataIndex: 'condicion'},
        { title: 'Fabricante', dataIndex: 'fabricante', key: 'fabricante' },
        { title: 'Uso Básico', dataIndex: 'usoBasico', key: 'usoBasico', width:180 },
        { title: 'Código Área', dataIndex: 'codigoArea', key: 'codigoArea' },
        { title: 'Clave Área', dataIndex: 'claveArea', key: 'claveArea' },
    ];

    console.log("listaInteriorFuncional",listaInteriorFuncional)

        return (
            <Card className="cardCatalogos" title={<div className="headerLista">Reasignación y Reubicación del Activo</div>}>
                <Form
                    name="formulario"
                    form={form}
                >
                    <Row justify='space-around' label="Filtrado por Ubicación" style={{background:'#e4e4e4', borderRadius:'1em'}} gutter={[8,8]} align='middle'>
                        <Col span={6} style={{paddingTop:'2em'}}>
                            <Form.Item
                                name="idAreaFuncional"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select 
                                    style={{ width: '100%' }}
                                    placeholder="Seleccione el área funcional"
                                    loading={fetchingListaAreasFuncionalesActivas}
                                    onChange={onUnidadesFuncionales}
                                    showSearch
                                    optionFilterProp="children"
                                >
                                    {listaAreasFuncionalesActivas.map(option => <Option key={option.idAreaFuncional}>{option.areaFuncional}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>

                        { listaUnidadesFuncionalesActivas[0] ? (
                        <Col span={6} style={{paddingTop:'2em'}}>
                                <Form.Item
                                        name="idUnidadFuncional"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Seleccione la unidad funcional"
                                            loading={fetchingListaUnidadesFuncionalesActivas}
                                            onChange={onInterioresFuncionales}       
                                            showSearch
                                            optionFilterProp="children"                                    
                                        >
                                            {listaUnidadesFuncionalesActivas.map(option => <Option key={option.idUnidadFuncional}>{option.unidadFuncional}</Option>)}
                                        </Select>
                                </Form.Item>
                        </Col>
                        ): false }

                        { listaInteriorFuncional[0] ? (    
                        <Col span={6} style={{paddingTop:'2em'}}>
                                <Form.Item
                                        name="idInteriorFuncional"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Seleccione la interior funcional"
                                            loading={fetchingListaInteriorFuncional}
                                            onChange={onSubInterioresFuncionales}
                                            showSearch
                                            optionFilterProp="children"
                                        >
                                            {listaInteriorFuncional.map(option => <Option key={option.idInteriorFuncional}>{option.interiorFuncional}</Option>)}
                                        </Select>
                                </Form.Item>
                        </Col>
                        ): false }

                        { listaSubinteriorFuncional[0] ? (
                        <Col span={6} style={{paddingTop:'2em'}}>
                                <Form.Item
                                        name="idSubInteriorFuncional"
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Seleccione la subinterior funcional"
                                            loading={fetchingListaSubinteriorFuncional}
                                            className='seleccionAsignar'
                                            onChange={onSubInterioresFuncionalesFinal}
                                            showSearch
                                            optionFilterProp="children"
                                        >
                                            {listaSubinteriorFuncional.map(option => <Option key={option.idSubInteriorFuncional}>{option.subInteriorFuncional}</Option>)}
                                        </Select>
                                </Form.Item>
                        </Col>
                        ): false }

                    </Row>

                    { listaAsignacionActivosBusqueda[0] ? (
                    <Row justify='space-between' style={{paddingTop:'1em'}}>
                        <Col span={24}>
                            <Search className='buscarModulos' placeholder="Ingrese descripción, código equipo, código interno, número serie o SKU" onSearch={value => onBusqueda(value)} style={{ width: '100%' }}/>
                        </Col>
                
                        <Col span={24}>
                            <br/>                           
                            <Table 
                                size="small" 
                                rowKey="idActivo" 
                                columns={columns}
                                rowSelection={rowSelection}
                                dataSource={listaAsignacionActivosBusqueda}
                                loading={fetchinglistaAsignacionActivosBusqueda}
                                locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar'}}
                                onChange={handleTableChange}
                                pagination={{ total: listaAsignacionActivosBusquedaCantidad, simple: true }}
                                scroll={{ x: 1300 }}
                                
                                showSorterTooltip={true}
                            />                            
                        </Col> 

                        {activoSeleccionado ? (
                            <Col span={24}>
                                <Button className="nuevoUsuario" style={{width: '50%'}} onClick={onAsignar} disabled={hasSelected}> 
                                    Generar Reasignación 
                                </Button>
                            </Col>
                        ): false}
                    </Row> 
                    ):(
                        <Spin spinning={fetchinglistaAsignacionActivosBusqueda}>
                            <Col span={24} style={{paddingTop:'1em'}}>
                                <Row justify='center'>
                                    <Col span= {24}>
                                        <Alert
                                            message="Ubicación del Activo"
                                            description="Ingrese una ubicación válida con activos para continuar"
                                            type="info"
                                            showIcon
                                            style={{ borderRadius: '2em' }}
                                        />
                                        <br />
                                    </Col>
                                    <Col span={6} style={{paddingTop:'1em', paddingBottom:'1em'}}>
                                    {/* <img src={imaSearch} /> */}
                                        <ReactVivus
                                            id="imaSearch"
                                            option={{
                                                file: imaSearch,
                                                animTimingFunction: "EASE",
                                                type: "delayed",
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Spin>
                    )}
                    
                    <ModalAsignarActivos/>

                    <Col span={24}>
                        <Modal
                            title="Detalle del Activo"
                            visible={visible}
                            footer={false}
                            onCancel={handleCancel}
                            width = {'80%'}
                        >
                                {/* <Table
                                    rowKey="idActivo"
                                    columns={columnsC}
                                    dataSource={activoSeleccionado}
                                    loading={fetchinglistaAsignacionActivosBusqueda}
                                    locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar'}}
                                    pagination={false}
                                    scroll={{ x: 1300 }}
                                /> */}
                                <Descriptions 
                                    layout="horizontal" bordered={true}
                                >
                                    {dataDescription.map(item => 
                                        <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                    )}
                                </Descriptions>
                        </Modal>
                    </Col>
                </Form>
            </Card>
            
        );
}

const mapStateToProps = state => {
    return {
        listaAsignacionActivosBusqueda: state.AlmacenesReducer.listaAsignacionActivosBusqueda,
        fetchinglistaAsignacionActivosBusqueda: state.AlmacenesReducer.fetchinglistaAsignacionActivosBusqueda, 
        listaAsignacionActivosBusquedaCantidad: state.AlmacenesReducer.listaAsignacionActivosBusquedaCantidad,
        listaAreasFuncionalesActivas: state.AlmacenesReducer.listaAreasFuncionalesActivas,
        fetchingListaAreasFuncionalesActivas: state.AlmacenesReducer.fetchingListaAreasFuncionalesActivas,
        listaUnidadesFuncionalesActivas: state. AlmacenesReducer.listaUnidadesFuncionalesActivas,
        fetchingListaUnidadesFuncionalesActivas: state.AlmacenesReducer.fetchingListaUnidadesFuncionalesActivas,
        listaInteriorFuncional: state.AlmacenesReducer.listaInteriorFuncional,
        fetchingListaInteriorFuncional: state.AlmacenesReducer.fetchingListaInteriorFuncional,
        listaSubinteriorFuncional: state.AlmacenesReducer.listaSubinteriorFuncional,
        fetchingListaSubinteriorFuncional: state.AlmacenesReducer.fetchingListaSubinteriorFuncional,
        listaAsignacionActivosActivos: state.AlmacenesReducer.listaAsignacionActivosActivos,
        fetchingListaAsignacionActivosActivos: state.AlmacenesReducer.fetchingListaAsignacionActivosActivos,
        AsignacionActivoSeleccionado: state.AlmacenesReducer.AsignacionActivoSeleccionado,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        showAlertMsg: state.AlmacenesReducer.showAlertMsg,

        //PDF

        descargar: state.AlmacenesReducer.descargar,
        asignacionPDF: state.AlmacenesReducer.asignacionPDF,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetAsignacionActivos: (page, busqueda) => {
	    	dispatch({ type: "ASIGNACION_ACTIVOS_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
        },
        limpiarAsignacionActivos: () => {
	    	dispatch({ type: "ASIGNACION_ACTIVOS_BUSQUEDA_SUCCESS", listaAsignacionActivosBusqueda: [], listaAsignacionActivosBusquedaCantidad: 0 });
        },
        onGetAreasFuncionales: () => {
            dispatch({ type: "LISTA_AREA_FUNCIONAL_ACTIVA_REQUEST", });
        },
        onModalAsignarActivos: (AsignacionActivoSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_ASIGNACION_ACTIVOS", showModalAsignarActivos: true, AsignacionActivoSeleccionado: AsignacionActivoSeleccionado, page: page});
        },
        onGetUnidadesFuncionalesActivas: (idAreaFuncional) => {
            dispatch({ type: 'LISTA_UNIDAD_FUNCIONAL_REQUEST', idAreaFuncional: idAreaFuncional});
        },
        onGetInterior: (idAreaFuncional, idUnidadFuncional) => {
            dispatch({ type: 'LISTA_INTERIOR_FUNCIONAL_REQUEST', idAreaFuncional: idAreaFuncional, idUnidadFuncional: idUnidadFuncional });
        },
        onLimpiarInteriorFuncional: () => {
            dispatch({ type: 'LISTA_INTERIOR_FUNCIONAL_SUCCESS', listaInteriorFuncional: [] })
        },
        onGetSubinterior: (idAreaFuncional, idUnidadFuncional, idInteriorFuncional) => {
            dispatch({ type: 'LISTA_SUBINTERIOR_FUNCIONAL_REQUEST', idAreaFuncional: idAreaFuncional, idUnidadFuncional: idUnidadFuncional, idInteriorFuncional: idInteriorFuncional});
        },
        onLimpiarSubInteriorFuncional: () => {
            dispatch({ type: 'LISTA_SUBINTERIOR_FUNCIONAL_SUCCESS', listaSubinteriorFuncional: [] })
        },
        onLimpiarTablaAsignacion: () => {
            dispatch({ type: 'ASIGNACION_ACTIVOS_BUSQUEDA_SUCCESS', listaAsignacionActivosBusqueda: [], })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        }
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(AsignacionActivosContainer);