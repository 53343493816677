import React from 'react';
import { Form, Row, Col, Switch, Modal, Table, Input, Button, Tooltip, Select, Empty, Descriptions, Divider, Typography, Card, Avatar } from 'antd';
import { EditOutlined, PlusOutlined, SearchOutlined, HistoryOutlined, EyeOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import imgEmpty from '../../../../assets/undraw_no_data.svg';

import ModalNuevo from './NuevoResponsable';
import ModalEditar from './EditarResponsable';
import ModalHistorico  from './HistoricoResponsables';

const confirm = Modal.confirm;
const { Search } = Input;
const { Option } = Select; 
const { Meta } = Card;
const { Text } = Typography;
const layout = {  labelCol: { span: 24 },  wrapperCol: { span: 24 } };

class ResponsablesContainer extends React.Component {
     formRef = React.createRef();
     componentDidMount(){ this.props.getResponsablesBusqueda(1); this.props.onGetServiciosActivos(); this.props.onGetProveedores(); this.props.onRequestInfoProyecto(); this.props.onGetRangosValidacionActivos(); }
     state={  page: 1,  busqueda: { responsable: null, servicio: null, proveedor: null }, orden:{ field: 'IdResponsable', order: 'ASC' }, visible: false, usuarioSuplente: {} }

     showModal = () => {
          this.setState({
               visible: true,
          });
     };

     hideModal = () => {
          this.setState({
               visible: false,
               usuarioSuplente: {}
          });
     };

     render() {
          const { onCambiarEstado } = this.props;
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
               permisosUsuario = JSON.parse(permisosUsuario);
          }

          const onFinish = formulario => {
               console.log('Success:', formulario);
               
          };

          const onEditar = (record) => { 
               console.log('record', record);
               if(record.partidaEditar){
                    let idsServicios = [];
                    idsServicios.push(record.idServicio);
                    this.props.onGetPartidasByIdsServicios(idsServicios);
               }

               if(record.servicioEditar){
                    let idsServiciosConcatenado = [];
                    idsServiciosConcatenado.push(record.servicioEditar);
                    // this.props.onGetUsuariosByIdsServicios(idsServiciosConcatenado);
                    this.props.onGetUsuariosByIdsServiciosTipoRS(idsServiciosConcatenado);
                    this.props.onGetUsuariosByIdsServiciosTipoAT(idsServiciosConcatenado);
                    this.props.onGetUsuariosByIdsServiciosTipoPYRS(idsServiciosConcatenado);
               }

               this.props.onShowModalEditar(record, this.state.page, this.state.busqueda, this.state.orden);
          };

          const handleDesactivar = (key) => {
               console.log(key);
               const { page, busqueda, orden } = this.state;
               confirm({
                    title: '¿Estás seguro que deseas cambiar el estado del rango de validación: "'+ key.nombre +'"?',
                    okText: 'Sí',
                     okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                        onCambiarEstado(key.idResponsable, page, busqueda, orden);
                    },
                    onCancel() {
                         console.log('Cancel');
                    },
               });
          };

          const onSuplencia = (record) => {
               console.log('record', record);
               this.setState({ usuarioSuplente: record });
               this.showModal();
          };


          const columnsUsuariosRS = [
               { title: 'Nombre', dataIndex: 'usuarioResponsableTabla',  key: 'usuarioResponsableTabla'},
               { title: 'Correo', dataIndex: 'correoResponsableTabla',  key: 'correoResponsableTabla'},
               { title: 'Rango Validación', dataIndex: 'rangoValidacionTabla',  key: 'rangoValidacionTabla'},
               { title: 'Suplencia', key: 'suplencia',  width:'5%', align:'center',
                    render: (text, record) => (
                         <a className='editarIcono' disabled={!permisosUsuario.editarRol} onClick={() => onSuplencia(record)}><EyeOutlined /></a>
                    ),
               },
          ];

          const columnsUsuarios = [
               { title: 'Nombre', dataIndex: 'usuarioResponsableTabla',  key: 'usuarioResponsableTabla'},
               { title: 'Correo', dataIndex: 'correoResponsableTabla',  key: 'correoResponsableTabla'},
               { title: 'Suplencia', key: 'suplencia',  width:'5%', align:'center',
                    render: (text, record) => (
                         <a className='editarIcono' disabled={!permisosUsuario.editarRol} onClick={() => onSuplencia(record)}><EyeOutlined /></a>
                    ),
               },
          ];

          const columns = [
               { title: 'Servicio', dataIndex: 'nombreServicioTabla',  key: 'nombreServicioTabla', align: 'center',},
               { title: 'Partida', dataIndex: 'nombrePartida',  key: 'nombrePartida', align: 'center',},
               { title: 'Responsable Servicio', key: 'responsablesServicioResponse', align: 'center',
                    render: (text, record) => (<>
                         { record &&
                              <Table style={{padding:'1em'}}
                                   size="small" 
                                   rowKey="idUsuario" 
                                   columns={columnsUsuariosRS}
                                   dataSource={record.responsablesServicioResponse}
                                   locale={{ emptyText: 'Sin datos' }}
                                   pagination={ false }
                              /> 
                         }
                    </>),
               },
               { title: 'Responsable AT', key: 'responsablesATResponse', align: 'center',
                    render: (text, record) => (<>
                         { record &&
                              <Table style={{padding:'1em'}}
                                   size="small" 
                                   rowKey="idUsuario" 
                                   columns={columnsUsuarios}
                                   dataSource={record.responsablesATResponse}
                                   locale={{ emptyText: 'Sin datos' }}
                                   pagination={ false }
                              /> 
                         }
                    </>),
               },
               { title: 'Responsable PYRS', key: 'responsablesPYRSResponse', align: 'center',
                    render: (text, record) => (<>
                         { record &&
                              <Table style={{padding:'1em'}}
                                   size="small" 
                                   rowKey="idUsuario" 
                                   columns={columnsUsuarios}
                                   dataSource={record.responsablesPYRSResponse}
                                   locale={{ emptyText: 'Sin datos' }}
                                   pagination={ false }
                              /> 
                         }
                    </>),
               },
               { title: 'Editar', key: 'editar',  width:'5%', align:'center',
                    render: (text, record) => (
                         <a className='editarIcono' disabled={!permisosUsuario.editarRol} onClick={() => onEditar(record)}><EditOutlined /></a>
                    ),
               },
          ];
        
        
          const handleTableChange = (pagination, filtro, orden)=>{
               this.setState({  page: pagination.current, });
               
               if(orden){
                    let field = orden.columnKey;
                    let order = 'ASC';
                
                    if(orden.order === 'descend'){
                        order = 'DESC';                
                    }
                    
                    const ordenState = { field, order }
                    this.props.getResponsablesBusqueda(pagination.current, this.state.busqueda, ordenState);
                    this.setState({ orden: ordenState });
               }
               else{
                    this.props.getResponsablesBusqueda(pagination.current, this.state.busqueda, this.state.orden);
               }
          };

          const serviciosSeleccionados = (e) => {
               // if(e.length > 0){
               //      let idsServicios = [];
               //      for(let i in e){ idsServicios.push(e[i].split(' - ')[0]) }
               //      let busqueda = { responsable: this.state.busqueda.responsable, servicio: idsServicios, proveedor: this.state.busqueda.proveedor }

               //      this.setState({busqueda});
               // } else {
               //      let busqueda = { responsable: this.state.busqueda.responsable, servicio: null, proveedor: this.state.busqueda.proveedor }

               //      this.setState({busqueda});
               // }

               if(e){
                    let servicio = e.split(' (')[1];
                    let busqueda = { responsable: this.state.busqueda.responsable, servicio: servicio.split(')')[0], proveedor: this.state.busqueda.proveedor }
                    this.props.getResponsablesBusqueda(1, busqueda, this.state.orden);
                    this.setState({busqueda});
               } else {
                    let busqueda = { responsable: this.state.busqueda.responsable, servicio: null, proveedor: this.state.busqueda.proveedor }
                    this.props.getResponsablesBusqueda(1, busqueda, this.state.orden);
                    this.setState({busqueda});
               }
          };

          const proveedoresSeleccionados = (e) => {
               // if(e.length > 0){
               //      let idsProveedores = [];
               //      for(let i in e){ idsProveedores.push(e[i].split(' - ')[0]) }
               //      let busqueda = { responsable: this.state.busqueda.responsable, servicio: this.state.busqueda.servicio, proveedor: idsProveedores }

               //      this.setState({busqueda});
               // } else {
               //      let busqueda = { responsable: this.state.busqueda.responsable, servicio: this.state.busqueda.servicio, proveedor: null }

               //      this.setState({busqueda});
               // }
               if(e){
                    let proveedor = e.split(': ')[1];
                    let busqueda = { responsable: this.state.busqueda.responsable, servicio: this.state.busqueda.servicio, proveedor: proveedor }
                    this.props.getResponsablesBusqueda(1, busqueda, this.state.orden);
                    this.setState({busqueda});
               } else {
                    let busqueda = { responsable: this.state.busqueda.responsable, servicio: this.state.busqueda.servicio, proveedor: null }
                    this.props.getResponsablesBusqueda(1, busqueda, this.state.orden);
                    this.setState({busqueda});
               }
          };

          const onSearch = (responsable) => {
               console.log('responsable', responsable)
               if(responsable){
                    let busqueda = { responsable: responsable, servicio: this.state.busqueda.servicio, proveedor: this.state.busqueda.proveedor }
                    this.props.getResponsablesBusqueda(1, busqueda, this.state.orden);
                    this.setState({ busqueda });
               } else {
                    let busqueda = { responsable: null, servicio: this.state.busqueda.servicio, proveedor: this.state.busqueda.proveedor }
                    this.props.getResponsablesBusqueda(1, busqueda, this.state.orden);
                    this.setState({ busqueda });
               }
           }

          return (<>
               <Form {...layout} name="formulario" onFinish={onFinish}  ref={this.formRef} >
                    <Row justify='space-between' gutter={[8,8]}>
                         <Col span={7}>
                              <Form.Item name="servicio" >
                                   <Select
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el servicio"
                                        loading={this.props.fetchingListaServiciosActivosF}
                                        showSearch
                                        // mode='multiple'
                                        onChange={serviciosSeleccionados}
                                   >
                                        {this.props.listaServiciosActivosF.map(option => 
                                            <Option key={option.nomenclatura + ': ' + option.nombre + ' (' + option.nombreC + ')'}>
                                            <Tooltip title={option.nomenclatura + ': ' + option.nombre}>
                                                {option.nomenclatura + ': ' + option.nombre} <Text italic type='secondary'> {' (' + option.nombreC + ')'} </Text>
                                            </Tooltip>
                                        </Option>
                                        )}
                                   </Select>
                              </Form.Item>
                         </Col>
                         <Col span={7}>
                              <Form.Item name="proveedor" >
                                   <Select
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el proveedor"
                                        loading={this.props.fetchingListaProveedores}
                                        showSearch
                                        // mode='multiple'
                                        onChange={proveedoresSeleccionados}
                                   >
                                        {this.props.listaProveedores.map(option => 
                                            <Option key={option.nombre + ': ' + option.razonSocial}>
                                                <Tooltip title={option.nombre + ': ' + option.razonSocial}>
                                                    {option.nombre + ': ' + option.razonSocial}
                                                </Tooltip>
                                            </Option>
                                        )}
                                   </Select>
                              </Form.Item>
                         </Col>
                         <Col span={7}>
                              <Form.Item name="responsables" >
                                   <Search allowClear className='buscarModulos' placeholder='Escribe el responsable a filtrar' onSearch={value=> onSearch(value)}  />
                              </Form.Item>
                         </Col>
                         <Col span={1}>
                              <Tooltip title='Nuevo Responsable' placement="topRight">
                                   <Button className="iconTer" shape="circle" onClick={() => {this.props.onShowModalNuevo(this.state.page, this.state.busqueda, this.state.orden);}} disabled={!permisosUsuario.crearRol} icon={<PlusOutlined />}/>
                              </Tooltip>
                         </Col>
                         <Col span={1}>
                              <Tooltip title='Histórico' placement="topRight">
                                   <Button className="iconTer" shape="circle" onClick={() => {this.props.onOpenModal(this.state.page, this.state.busqueda, this.state.orden); this.props.onGetHistorico();}} icon={<HistoryOutlined />}/>
                              </Tooltip>
                         </Col>
                    </Row>
               </Form>
               <Row justify='space-between' gutter={[8,8]}>
                    <Col span={24}>
                         <Table 
                              size="middle" 
                              rowKey="idResponsableServicio" 
                              columns={columns}
                              dataSource={permisosUsuario.buscarRol ? this.props.listaBusquedaResponsableServicio : []}
                              loading={this.props.fetchingListaBusquedaResponsableServicio}
                              locale={{ emptyText: 'Sin datos' }}
                              onChange={handleTableChange}
                              pagination={{ total: this.props.listaBusquedaResponsableServicioCantidad, simple: true, current: this.state.page }}
                              scroll={{x:2200}}
                         />         
                    </Col>
               </Row>

               <Modal
                    title="Suplencia"
                    visible={this.state.visible}
                    footer={false} onCancel={this.hideModal} 
                    width={'50%'}
               >
                    {this.state.usuarioSuplente && <>

                         { this.state.usuarioSuplente.servicioByIdServicio &&  <Card style={{ borderRadius:'1em'}}>
                              <Meta
                                   avatar={<Avatar size={60} shape="square" src={this.state.usuarioSuplente.servicioByIdServicio.urlImagen} />}
                                   title={ this.state.usuarioSuplente.servicioByIdServicio.nomenclatura + ': ' + this.state.usuarioSuplente.servicioByIdServicio.nombre }
                                   description={this.state.usuarioSuplente.partidaByIdPartida &&  this.state.usuarioSuplente.partidaByIdPartida.nomenclatura + ': ' + this.state.usuarioSuplente.partidaByIdPartida.nombre}
                              />
                         </Card> }

                         <Descriptions bordered size='small' layout="vertical" style={{paddingTop:'1em'}}>
                              <Descriptions.Item label="Responsable">{this.state.usuarioSuplente.usuarioResponsableTabla + ': ' + this.state.usuarioSuplente.correoResponsableTabla}</Descriptions.Item>
                              <Descriptions.Item label="Tipo responsable">{this.state.usuarioSuplente.tipoResponsable}</Descriptions.Item>
                         </Descriptions>

                         <Divider />
                         {this.state.usuarioSuplente.usuarioSuplenteByIdUsuario ?
                              <Descriptions bordered size='small' layout="vertical">
                                   <Descriptions.Item label="Suplente">{this.state.usuarioSuplente.suplenteTabla}</Descriptions.Item>
                                   <Descriptions.Item label="Fechas suplencia">{this.state.usuarioSuplente.fechasSuplenciaTabla}</Descriptions.Item>
                              </Descriptions>
                         :
                              <Empty image={imgEmpty} description='No cuenta con suplente activo'/>
                         }
                    </>}

               </Modal>

               <ModalNuevo />
               <ModalEditar />
               <ModalHistorico />
          </>);
     }
}

const mapStateToProps = state => {
     return {
          listaBusquedaResponsableServicio: state.ConfiguracionYSeguridadReducer.listaBusquedaResponsableServicio,
          listaBusquedaResponsableServicioCantidad: state.ConfiguracionYSeguridadReducer.listaBusquedaResponsableServicioCantidad,
          fetchingListaBusquedaResponsableServicio: state.ConfiguracionYSeguridadReducer.fetchingListaBusquedaResponsableServicio,

          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
          listaProveedores: state.InformacionOperativaReducer.listaProveedores,
          fetchingListaProveedores: state.InformacionOperativaReducer.fetchingListaProveedores,
     };
};
const mapDispatchToProps = dispatch => {
	return {
          getResponsablesBusqueda: (page, busqueda, orden) => {
               dispatch({ type: "LISTA_BUSQUEDA_RESPONSABLES_REQUEST", page, busqueda, orden });
          },
          onShowModalNuevo: (page, busqueda, orden) => {
               dispatch({ type: 'SHOW_MODAL_NUEVO_RESPONSABLE', showModalNuevoResponsable: true, page, busqueda, orden })
          },
          onShowModalEditar: (responsableSeleccionado, page, busqueda, orden) => {
               dispatch({ type: 'SHOW_MODAL_EDITAR_RESPONSABLE', showModalEditarResponsable: true, responsableSeleccionado, page, busqueda, orden })
          },
          onCambiarEstado: (idResponsable, page, busqueda, orden) => {
               dispatch({ type: 'ESTADO_RESPONSABLE_REQUEST', idResponsable, page, busqueda, orden });
          },
          onGetServiciosActivos: () => {
               dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
          },
          onGetProveedores: () => {
               dispatch({ type: 'GET_PROVEEDORES_REQUEST' })
          },
          onRequestInfoProyecto: () => {
               dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
          },
          onGetRangosValidacionActivos: () => {
               dispatch({ type: 'LISTA_ACTIVOS_RANGOS_VALIDACION_REQUEST' });
          },
          onGetPartidasByIdsServicios: (idsServicios) => {
               dispatch({ type: 'LISTA_PARTIDAS_BY_IDSSERVICIOS_REQUEST', idsServicios });
          },
          onGetUsuariosByIdsServicios: (idsServicios) => {
               dispatch({ type: 'LISTA_USUARIOS_BY_IDSSERVICIOS_REQUEST', idsServicios });
          },
          onGetUsuariosByIdsServiciosTipoRS: (idsServicios) => {
               dispatch({ type: 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_RS_REQUEST', idsServicios });
          },
          onGetUsuariosByIdsServiciosTipoAT: (idsServicios) => {
               dispatch({ type: 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_AT_REQUEST', idsServicios });
          },
          onGetUsuariosByIdsServiciosTipoPYRS: (idsServicios) => {
               dispatch({ type: 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_PYRS_REQUEST', idsServicios });
          },
          onOpenModal: (page, busqueda, orden) => {
               dispatch({ type: 'MODAL_HISTORICO_RESPONSABLES', showModalHistoricoResponsable: true, page, busqueda, orden })
          },
          onGetHistorico: () => {
               dispatch({ type: 'LISTA_HISTORICO_RESPONSABLE_BUSQUEDA_REQUEST', page: 1 });
          }
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ResponsablesContainer);