import React from 'react';
import { Row, Col, Table, Input, Button, Select, Switch, Tooltip } from 'antd';
import { connect } from "react-redux";
import { PlusOutlined } from '@ant-design/icons';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevaBitacora from './NuevoModuloBitacora';

const {Search} = Input;
const {Option} = Select;

class ModulosBitacorasContainer extends React.Component {
     componentDidMount(){
          this.props.getModulosBitacorasBusqueda(1, {
               idsTiposBitacoras: [],
               nombre: '',
               field: 'idBitacoraModulo',
               order: 'ASC'
          }); 
          this.props.onGetInfoProyecto(); this.props.onListaTiposBitacoraActivos(); this.props.onGetModulos(); this.props.onGetServiciosActivos();
     }
     state={
          page: 1,
          busqueda: {
               idsTiposBitacoras: [],
               nombre: '',
               field: 'idBitacoraModulo',
               order: 'ASC'
          },
     }
     render() {
          const {   } = this.props;

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
               permisosUsuario = JSON.parse(permisosUsuario);
          }

          let userInfo = sessionStorage.getItem('userInfo');
          if(userInfo[0]) {
               userInfo = JSON.parse(userInfo);
          }

          const onBuscarParam = (value) => {
               const busqueda = {
                    idsTiposBitacoras: this.state.busqueda.idsTiposBitacoras,
                    nombre: value,
                    field: this.state.busqueda.field,
                    order: this.state.busqueda.order
               }
               this.setState({ busqueda })
               console.log(busqueda);
               this.props.getModulosBitacorasBusqueda(1, busqueda);
          }

          const columns = [
               {
                    title: 'Nombre',
                    dataIndex: 'nombre',
                    key: 'nombre',
                    sorter: true
               },{
                    title: 'Tipo de bitácora',
                    dataIndex: 'tipoBitacora',
                    key: 'tipoBitacora',
                    sorter: true
               },{
                    title: 'Módulo',
                    dataIndex: 'modulo',
                    key: 'modulo',
                    sorter: true
               },{
                    title: 'Servicio',
                    dataIndex: 'servicio',
                    key: 'servicio',
                    sorter: true
               },{
                    title: 'Partida',
                    dataIndex: 'partida',
                    key: 'partida',
                    sorter: true
               },{
                    title: 'Fecha de alta',
                    dataIndex: 'fechaAltaGrid',
                    key: 'fechaAltaGrid',
                    sorter: true
               },
               {
                    title: 'Activo',
                    dataIndex: 'activo',
                    key: 'activo',
                    align: 'center',
                    sorter: true,
                    render: (text, record) => (
                        <Switch checked={text} disabled={true}/>
                    ),
                },
          ];

          const handleTableChange = (pagination, filtro, orden)=>{
               this.setState({  page: pagination.current })

               if(orden){
                    let campo = 'idBitacoraModulo';
                    let order = 'ASC';
                
                    if(orden.order === 'descend'){
                        order = 'DESC';
                    }
    
                    if(orden.columnKey === 'nombre'){
                         campo = 'idBitacoraModulo';
                    }else if(orden.columnKey === 'tipoBitacora'){
                        campo = 'idTipoBitacora';
                    }else if(orden.columnKey === 'modulo'){
                        campo = 'idModulo';
                    }else if(orden.columnKey === 'servicio'){
                        campo = 'idServicio';
                    }else if(orden.columnKey === 'partida'){
                        campo = 'idPartida';
                    }else if(orden.columnKey === 'fechaAltaGrid'){
                         campo = 'fechaAlta';
                    }else{
                        campo = 'activo'; 
                    }
                    
                    const busqueda = { 
                         idsTiposBitacoras: this.state.busqueda.idsTiposBitacoras,
                         nombre: this.state.busqueda.nombre,
                         field: campo,
                         order: order
                    }

                    this.setState({busqueda});
                    this.props.getModulosBitacorasBusqueda(pagination.current,busqueda);
               } else {
                    this.props.getModulosBitacorasBusqueda(pagination.current, this.state.busqueda);
               }
          };

          const onNuevo = () => {
               this.props.onModalNuevoModuloBitacora(this.state.page, this.state.busqueda);
          };

          const tipoBitacoraSeleccionado = (value) => {
               let idsTiposBitacoras = [];

               if(value.length > 0){
                    for(let i in value){
                         idsTiposBitacoras.push(value[i].split(' - ')[0]);
                    }
               }

               const busqueda = {
                    idsTiposBitacoras: idsTiposBitacoras,
                    nombre: this.state.busqueda.nombre,
                    field: this.state.busqueda.field,
                    order: this.state.busqueda.order
               }
               this.setState({ busqueda });
               this.props.getModulosBitacorasBusqueda(1, busqueda);
          };

          return (
               <div>
                    <Row justify='space-between' gutter={[8,8]}>
                         <Col span={11}>
                              <Search className='buscarModulos' placeholder="Ingresa el nombre de bitácora por módulo a filtrar" onSearch={value => onBuscarParam(value)} style={{ width: '100%' }}/>
                         </Col>
                         <Col span={11}>
                              <Select 
                                   mode='multiple'
                                   allowClear
                                   style={{ width: '100%' }}
                                   placeholder="Selecciona un tipo de bitácora"
                                   loading={this.props.fetchingListaTiposBitacoraActivos}
                                   showSearch={true}
                                   onChange={tipoBitacoraSeleccionado}
                              >
                                   {this.props.listaTiposBitacoraActivos.map(option => 
                                   <Option key={option.idTipoBitacora + ' - ' + option.tipoBitacora}>
                                        <Tooltip placement="topLeft" title={option.idTipoBitacora + ' - ' + option.tipoBitacora}>
                                             {option.idTipoBitacora + ' - ' + option.tipoBitacora}
                                        </Tooltip>
                                   </Option>)}
                              </Select>
                         </Col>
                         {/* <Col span={7}>
                              <Button onClick={onNuevo} disabled={!permisosUsuario.crearBitácoraMódulos} className='nuevoUsuario'>Nueva Bitácora por Módulo</Button>
                         </Col> */}

                         <Col span={1}>
                              <Tooltip title='Nueva Bitácora por Módulo' placement="topRight">
                                   <Button className="iconTer" shape="circle" onClick={onNuevo} disabled={!permisosUsuario.crearBitácoraMódulos}  icon={<PlusOutlined />}/>
                              </Tooltip>
                         </Col>

                         <Col span={24}>
                              <Table 
                                   size="small" 
                                   rowKey="idBitacoraModulo" 
                                   columns={columns}
                                   dataSource={this.props.listaModulosBitacora}
                                   loading={this.props.fetchingListaModulosBitacora}
                                   locale={{ emptyText: 'Sin datos' }}
                                   onChange={handleTableChange}
                                   pagination={{ total: this.props.listaModulosBitacoraCantidad, simple: true, current: this.state.page }}
                                   scroll={{x:1400}}
                              />    
                         </Col>
                    </Row>
                    <ModalNuevaBitacora/>
                    {/* <ModalEditarBitacora/> */}
               </div>
          );
     }
}

const mapStateToProps = state => {
     return {
          listaModulosBitacora: state.ConfiguracionYSeguridadReducer.listaModulosBitacora,
          fetchingListaModulosBitacora: state.ConfiguracionYSeguridadReducer.fetchingListaModulosBitacora,
          listaModulosBitacoraCantidad: state.ConfiguracionYSeguridadReducer.listaModulosBitacoraCantidad,

          listaTiposBitacoraActivos: state.ConfiguracionYSeguridadReducer.listaTiposBitacoraActivos,
          fetchingListaTiposBitacoraActivos: state.ConfiguracionYSeguridadReducer.fetchingListaTiposBitacoraActivos,

          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,

     };
};
const mapDispatchToProps = dispatch => {
	return {
          getModulosBitacorasBusqueda: (page, busqueda) => {
               dispatch({ type: "LISTA_BITACORAS_MODULOS_BUSQUEDA_REQUEST", page, busqueda });
          },
          onEstadoBitacora: (idBitacora, page, busqueda) => {
               dispatch({ type: "ESTADO_BITACORA_REQUEST", idBitacora, page, busqueda });
          },
          onModalNuevoModuloBitacora: (page, busqueda) => {
               dispatch({ type: "SHOW_MODAL_NUEVO_MODULO_BITACORA", showModalNuevoModuloBitacora: true, page, busqueda });
          },
          onModalEditarBitacora: (moduloBitacoraSeleccionada, page, busqueda) => {
               dispatch({ type: "SHOW_MODAL_EDITAR_MODULO_BITACORA", showModalEditarModuloBitacora: true, moduloBitacoraSeleccionada, page, busqueda});
          },
          onGetInfoProyecto: () => {
               dispatch ({ type: 'GET_INFOPROYECTO_REQUEST' });
          },
          onListaTiposBitacoraActivos: () => {
               dispatch ({ type: 'LISTA_TIPOS_BITACORA_ACTIVOS_REQUEST',  });
          },
          onGetModulos: () => {
               dispatch({ type: 'LISTA_MODULOS_REQUEST'})
          },
          onGetServiciosActivos: () => {
               dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
          },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ModulosBitacorasContainer);