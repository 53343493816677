import React, {Component} from 'react';
import { Form, Spin, Row, Col, Input, Button, Select, message, Radio, Popover} from "antd";
import {connect} from 'react-redux';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalContraseña from '../ModalContraseña';
import ModalProrroga from '../ModalProrroga';

const { TextArea } = Input;
const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ReasignarForm extends Component {
    formRef = React.createRef();

    componentDidMount(){
        this.props.onGetAreas();
        this.props.onGetFallas();
    };

    state = {
        formulario: null,
        pageProrroga: 1,
    };

    render() {
        const { fetchingCambioEstadoSeguimiento, onActualizarSeguimiento,
            fetchingListaServiciosConFallas, listaServiciosConFallas,
            fetchingListaFallasActivas, listaFallasActivas, listaAreasFuncionalesActivas,
            fetchingListaAreasFuncionalesActivas, fetchingListaUnidadesFuncionalesActivas, listaUnidadesFuncionalesActivas,
            listaInteriorFuncional, fetchingListaInteriorFuncional, listaSubinteriorFuncional,
            fetchingListaSubinteriorFuncional, onGetUnidades, onGetInteriores, folioSolicitudServicioSeleccionado,
            onGetSubInteriores, obtenerUbicacion, ubicacionSeguimiento, page, busqueda,
            onGetProveedoresByServicio, fetchingListaProveedoresByServicio, listaProveedoresByServicio,
            onShowModalContraseña, isEspecial, onShowModalProrroga, onGetProrrogaHistorial, limpiarClasificacion,
            //listaServiciosActivosF, onGetPartidasByFalla, fetchingListaPartidasByFalla, listaPartidasByFalla
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario')
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        };

        if(isEspecial){
             onActualizarSeguimiento(this.state.formulario, folioSolicitudServicioSeleccionado, ubicacionSeguimiento, page, busqueda);
             onShowModalContraseña(false, false);
        };    

        const onFinish = (formulario) => {
            console.log(formulario)
            if (permisosUsuario.especialSolicitudDeServicio) {
                this.setState({ formulario })
                onShowModalContraseña(true, false);
            } else {
                if (!this.formRef.current.getFieldValue('queja') && !this.formRef.current.getFieldValue('servicio') && !this.formRef.current.getFieldValue('falla') && !this.formRef.current.getFieldValue('proveedor') && !this.formRef.current.getFieldValue('area')) {
                    message.warning('Ingresar al menos un dato de los siguientes: "Queja" "Servicio" "Falla" "Proveedor" o "Ubicacion"', 5)
                } else {
                    onActualizarSeguimiento(formulario, folioSolicitudServicioSeleccionado, ubicacionSeguimiento, page, busqueda);
                    this.formRef.current.resetFields();
                }
            }
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo);
        };


        const onUnidadesFuncionales = (idAreaFuncional) => { 
            if(idAreaFuncional) {
                console.log('idAreaFuncional', idAreaFuncional);           
                this.formRef.current.setFieldsValue({'unidad': undefined,'interior': undefined, 'subInterior': undefined});
                onGetUnidades(idAreaFuncional.split('-')[0]);
                
                let idUnidadFuncional = '' 
                let idInteriorFuncional  = ''
                let idSubInteriorFuncional = ''
                obtenerUbicacion(idAreaFuncional.split('-')[0], idUnidadFuncional, idInteriorFuncional, idSubInteriorFuncional);
            } else {
                console.log('idAreaFuncional', idAreaFuncional);           
                this.formRef.current.setFieldsValue({'unidad': undefined,'interior': undefined, 'subInterior': undefined});
                onGetUnidades('');
                
                let idUnidadFuncional = '' 
                let idInteriorFuncional  = ''
                let idSubInteriorFuncional = ''
                obtenerUbicacion('', idUnidadFuncional, idInteriorFuncional, idSubInteriorFuncional);
            }
            
        }

        const onInterioresFuncionales = (idUnidadFuncional) => {
            if(idUnidadFuncional) {
                this.formRef.current.setFieldsValue({'interior': undefined, 'subInterior': undefined});
                let idAreaFuncional = this.formRef.current.getFieldValue('area').split('-')[0]
    
                let idInteriorFuncional  = ''
                let idSubInteriorFuncional = ''
                onGetInteriores(idAreaFuncional, idUnidadFuncional.split('-')[0]);
                obtenerUbicacion(idAreaFuncional, idUnidadFuncional.split('-')[0], idInteriorFuncional, idSubInteriorFuncional);
            } else {
                this.formRef.current.setFieldsValue({'interior': undefined, 'subInterior': undefined});
                let idAreaFuncional = this.formRef.current.getFieldValue('area').split('-')[0]
    
                let idInteriorFuncional  = ''
                let idSubInteriorFuncional = ''
                onGetInteriores(idAreaFuncional, '');
                obtenerUbicacion(idAreaFuncional, '', idInteriorFuncional, idSubInteriorFuncional);
            }
            
            
        }

        const onSubInterioresFuncionales = (idInteriorFuncional) => {
            if(idInteriorFuncional) {
                this.formRef.current.setFieldsValue({'subInterior': undefined});
                let idAreaFuncional = this.formRef.current.getFieldValue('area').split('-')[0]
                let idUnidadFuncional = this.formRef.current.getFieldValue('unidad').split('-')[0] 
                
                let idSubInteriorFuncional = ''
                onGetInteriores(idAreaFuncional, idUnidadFuncional, idInteriorFuncional.split('-')[0]);
                obtenerUbicacion(idAreaFuncional, idUnidadFuncional, idInteriorFuncional.split('-')[0], idSubInteriorFuncional);
            } else {
                this.formRef.current.setFieldsValue({'subInterior': undefined});
                let idAreaFuncional = this.formRef.current.getFieldValue('area').split('-')[0]
                let idUnidadFuncional = this.formRef.current.getFieldValue('unidad').split('-')[0] 
                
                let idSubInteriorFuncional = ''
                onGetInteriores(idAreaFuncional, idUnidadFuncional, '');
                obtenerUbicacion(idAreaFuncional, idUnidadFuncional, '', idSubInteriorFuncional);
            }
            
        };

        const onSubInterioresFuncionalesUltimo = (idSubInteriorFuncional) => {
            if (idSubInteriorFuncional){
                let idAreaFuncional = this.formRef.current.getFieldValue('area').split('-')[0]
                let idUnidadFuncional = this.formRef.current.getFieldValue('unidad').split('-')[0] 
                let idInteriorFuncional = this.formRef.current.getFieldValue('interior').split('-')[0]
                
                onGetInteriores(idAreaFuncional, idUnidadFuncional, idInteriorFuncional, idSubInteriorFuncional.split('-')[0]);
                obtenerUbicacion(idAreaFuncional, idUnidadFuncional, idInteriorFuncional, idSubInteriorFuncional.split('-')[0]);
                
            } else {
                let idAreaFuncional = this.formRef.current.getFieldValue('area').split('-')[0]
                let idUnidadFuncional = this.formRef.current.getFieldValue('unidad').split('-')[0] 
                let idInteriorFuncional = this.formRef.current.getFieldValue('interior').split('-')[0]
                
                onGetInteriores(idAreaFuncional, idUnidadFuncional, idInteriorFuncional, '');
                obtenerUbicacion(idAreaFuncional, idUnidadFuncional, idInteriorFuncional, '');
            }
            
        };

        const onModalProrroga = () => {
            console.log('abrir ');
            onShowModalProrroga(true, folioSolicitudServicioSeleccionado, false)  
            onGetProrrogaHistorial(folioSolicitudServicioSeleccionado, this.state.pageProrroga);   
        };

        if( limpiarClasificacion ) {
            this.formRef.current.resetFields(['queja']);
            onShowModalProrroga(false, null, false)
        }


        // const onFallaSeleccionada = (value) => {
        //     console.log('value :>> ', value);
        //     if(value){
        //         this.formRef.current.resetFields(['partidas']);
        //         onGetPartidasByFalla(parseInt(value.split('-')[0]));
        //     }
        // };

        return ( 
            <div>
            <Spin spinning={fetchingCambioEstadoSeguimiento}>

                {folioSolicitudServicioSeleccionado ? (
                    <Form {...layout}
                       name='formulario'
                       onFinish={onFinish}
                       initialValues={folioSolicitudServicioSeleccionado}
                       
                       ref={this.formRef} 
                       >

                        <Row type='flex' justify='space-around'>
                            <Col span={23}>
                                <Form.Item
                                    label="Observaciones"
                                    name="observacionesF"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <TextArea
                                        rows={1}
                                        pattern="^^[ñíóáéú a-zA-Z 0-9]+$"
                                        title="Observaciones en Reasignación debe tener letras y numeros."
                                        maxLength={100} placeholder='Ingrese observaciones' />
                                </Form.Item>
                            </Col>
                                
                            <Col span={23}>
                                <Form.Item
                                    label="Clasificación"
                                    name="queja" >
                                   <Radio.Group defaultValue="ninguna"  style={{ marginTop: 16 }}>
                                       <Radio onClick={onModalProrroga} value="ninguna"> Prorroga</Radio>
                                       <Radio value="queja"> Queja</Radio>
                                       <Radio value="SNP"> SNP</Radio>
                                       
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                            <Col span={23}>
                                <Form.Item
                                    label='Falla'
                                    name='falla'
                                    rules={[{ required: true, message: 'Favor de seleccionar una opción'}]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona una falla"
                                            loading={fetchingListaFallasActivas}
                                            //onChange={onFallaSeleccionada}
                                        >
                                            {listaFallasActivas.map(option => 
                                            <Option key={option.idFalla + '-' + option.nombre}>
                                                 {option.idFalla + '-' + option.nombre}
                                            </Option>
                                            )}
                                        </Select>
                                </Form.Item>
                            </Col>

                            <Col span={23}>
                                <Form.Item
                                    label="Servicio"
                                    name="servicio"
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Seleccione el servicio"
                                            loading={fetchingListaServiciosConFallas}
                                            //onChange={handleServicio}
                                            disabled
                                        >
                                            {listaServiciosConFallas.map(option => <Option key={option.idServicio}>{ option.nombre }</Option>)}
                                    </Select>
                                  </Form.Item>
                            </Col>

                            <Col span={23}>
                                <Form.Item
                                   label="Proveedor"
                                   name="proveedor"
                                   >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Seleccione el proveedor"
                                        loading={fetchingListaProveedoresByServicio}
                                        disabled
                                    >
                                        {listaProveedoresByServicio.map(option => <Option key={option.idProveedor}>{ option.nombre }</Option>)}
                                </Select>
                                </Form.Item>
                            </Col>
                            
                            {/* <Col span={23}>
                                <Form.Item
                                  label='Partidas'
                                  name='partidas'
                                  rules={[{ required: listaPartidasByFalla[0], message: 'Favor de llenar el campo', }]}
                                >
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona una o varias partidas"
                                        loading={fetchingListaPartidasByFalla}
                                        disabled={!listaPartidasByFalla[0]}
                                        mode='multiple'
                                    >

                                    {listaPartidasByFalla.map(option => 
                                    <Option key={option.idPartida + ' - ' + option.nomenclatura + ' ('+ option.nombre + ')'}>
                                         <Popover content={option.idPartida + ' - ' + option.nomenclatura + ' ('+ option.nombre + ')'} title={false}>
                                              {option.idPartida + ' - ' + option.nomenclatura + ' ('+ option.nombre + ')'}
                                         </Popover>
                                    </Option>
                                    )}
                                     </Select>

                                  </Form.Item>
                            </Col> */}

                            <Col span={23}>
                                <Form.Item
                                    label="Área Funcional"
                                    name="area"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                > 
                                 <Select allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona el área funcional"
                                    loading={fetchingListaAreasFuncionalesActivas}
                                    onChange={onUnidadesFuncionales}
                                 >
                                    {listaAreasFuncionalesActivas.map(option => <Option key={option.idAreaFuncional + "-" + option.areaFuncional}>{ option.idAreaFuncional + "-" + option.areaFuncional }</Option>)}
                                 </Select>
                                </Form.Item>
                            </Col>
                           
                            <Col span={23}>
                                <Form.Item
                                   label="Unidades Funcionales"
                                   name="unidad"
                                   
                                >
                                <Select allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona la unidad funcional"
                                    loading={fetchingListaUnidadesFuncionalesActivas}
                                    onChange={onInterioresFuncionales}
                                >
                                    {listaUnidadesFuncionalesActivas.map(option => <Option key={option.idUnidadFuncional + "-" + option.unidadFuncional}>{ option.idUnidadFuncional + "-" + option.unidadFuncional }</Option>)}
                                   </Select>
                               </Form.Item>
                            </Col>
                            
                             <Col span={23}>
                                <Form.Item
                                    label="Interior"
                                    name="interior"
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el interior funcional"
                                        loading={fetchingListaInteriorFuncional}
                                        onChange={onSubInterioresFuncionales}
                                    >
                                        {listaInteriorFuncional.map(option => <Option key={option.idInteriorFuncional + "-" + option.interiorFuncional}>{ option.idInteriorFuncional + "-" +option.interiorFuncional }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            
                            {/* <Col span={23}>
                                <Form.Item
                                    label="Subinterior"
                                    name="subInterior"
                                >
                                <Select allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Seleccione el subinterior funcional"
                                    loading={fetchingListaSubinteriorFuncional}
                                    onChange={onSubInterioresFuncionalesUltimo}
                                    >
                                         {listaSubinteriorFuncional.map(option => <Option key={option.idSubInteriorFuncional + "-" + option.subInteriorFuncional}>{option.idSubInteriorFuncional + "-" + option.subInteriorFuncional }</Option>)}
                                     </Select>
                                </Form.Item>
                            </Col> */}
                                  
                                
                            <Col span={23}>
                                <Button className="btnFiltrar" style={{ width: '40%' }} htmlType="submit">Registrar</Button>
                            </Col>
                            </Row>
                     </Form>
                     ): false}
                </Spin>
                <ModalContraseña/>
                <ModalProrroga/>
            </div>
         );
    }
}

const mapStateToProps = (state) => {
    return {
        fetchingCambioEstadoSeguimiento: state.SolicitudDeServiciosReducer.fetchingCambioEstadoSeguimiento,
        folioSolicitudServicioSeleccionado: state.SolicitudDeServiciosReducer.folioSolicitudServicioSeleccionado,
        page: state.SolicitudDeServiciosReducer.page,
        busqueda: state.SolicitudDeServiciosReducer.busqueda,
        ubicacionSeguimiento: state.SolicitudDeServiciosReducer.ubicacionSeguimiento,
        fetchingListaFallasActivas: state.SolicitudDeServiciosReducer.fetchingListaFallasActivas,
        listaFallasActivas: state.SolicitudDeServiciosReducer.listaFallasActivas,
        listaServiciosConFallas: state.SolicitudDeServiciosReducer.listaServiciosConFallas,
        fetchingListaServiciosConFallas: state.SolicitudDeServiciosReducer.fetchingListaServiciosConFallas,
        listaProveedoresByServicio: state.SolicitudDeServiciosReducer.listaProveedoresByServicio,
        fetchingListaProveedoresByServicio: state.SolicitudDeServiciosReducer.fetchingListaProveedoresByServicio,

        listaAreasFuncionalesActivas: state.AlmacenesReducer.listaAreasFuncionalesActivas,
        fetchingListaAreasFuncionalesActivas: state.AlmacenesReducer.fetchingListaAreasFuncionalesActivas,
        listaUnidadesFuncionalesActivas: state. AlmacenesReducer.listaUnidadesFuncionalesActivas,
        fetchingListaUnidadesFuncionalesActivas: state.AlmacenesReducer.fetchingListaUnidadesFuncionalesActivas,
        listaInteriorFuncional: state.AlmacenesReducer.listaInteriorFuncional,
        fetchingListaInteriorFuncional: state.AlmacenesReducer.fetchingListaInteriorFuncional,
        listaSubinteriorFuncional: state.AlmacenesReducer.listaSubinteriorFuncional,
        fetchingListaSubinteriorFuncional: state.AlmacenesReducer.fetchingListaSubinteriorFuncional,
        isEspecial: state.SolicitudDeServiciosReducer.isEspecial,
        limpiarClasificacion: state.SolicitudDeServiciosReducer.limpiarClasificacion,
        listaPartidasByFalla: state.SolicitudDeServiciosReducer.listaPartidasByFalla,
        fetchingListaPartidasByFalla: state.SolicitudDeServiciosReducer.fetchingListaPartidasByFalla,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onActualizarSeguimiento: (formulario, folioSolicitudServicioSeleccionado, ubicacionSeguimiento, page, busqueda) => {
            dispatch({ type: "REASIGNAR_FOLIO_SS_REQUEST", formulario, folioSolicitudServicioSeleccionado, ubicacionSeguimiento, page, busqueda });
        },
        onGetProveedoresByServicio: (idServicio) => {
            dispatch({ type: 'LISTA_PROVEEDORES_BY_SERVICIO_ACTIVOS_REQUEST', idServicio })
        },
        onGetServiciosConFallas: () => {
            dispatch({ type: 'LISTA_SERVICIOS_ACTIVOS_CON_FALLAS_REQUEST'});
        },
        // onGetFallas: (idServicio) => {
        //     dispatch({ type: 'LISTA_FALLAS_ACTIVAS_POR_SERVICIO_REQUEST', idServicio})
        // },
        onGetAreas: () => {
            dispatch({ type: "LISTA_AREA_FUNCIONAL_ACTIVA_REQUEST" });
        },
        onGetUnidades: (idAreaFuncional) => {
            dispatch({ type: "LISTA_UNIDAD_FUNCIONAL_REQUEST" , idAreaFuncional: idAreaFuncional});
        },
        onGetInteriores: (idAreaFuncional, idUnidadFuncional) => {
            dispatch({ type: "LISTA_INTERIOR_FUNCIONAL_REQUEST", idAreaFuncional: idAreaFuncional, idUnidadFuncional: idUnidadFuncional });
        },
        onGetSubInteriores: (idAreaFuncional, idUnidadFuncional, idInteriorFuncional) => {
            dispatch({ type: "LISTA_SUBINTERIOR_FUNCIONAL_REQUEST", idAreaFuncional: idAreaFuncional,  idUnidadFuncional: idUnidadFuncional, idInteriorFuncional: idInteriorFuncional});
        },
        obtenerUbicacion: (idAreaFuncional, idUnidadFuncional, idInteriorFuncional, idSubInteriorFuncional) => {
            dispatch({ type: "OBTENER_UBICACION_REQUEST", idAreaFuncional, idUnidadFuncional, idInteriorFuncional, idSubInteriorFuncional});
        },
        onShowModalContraseña: (showModalContraseña, isEspecial) => {
            dispatch({ type: "SHOW_MODAL_CONTRASEÑA", showModalContraseña , isEspecial})
        },
        onShowModalProrroga: (showModalProrroga, folioSeleccionadoProrroga, limpiarClasificacion) => {
            dispatch({ type: "SHOW_MODAL_PRORROGA" , showModalProrroga , folioSeleccionadoProrroga, limpiarClasificacion })
        },
        onGetProrrogaHistorial: (folioSolicitudServicioSeleccionado, page) => {
            dispatch({ type: "LISTA_PRORROGA_REQUEST", folioSolicitudServicioSeleccionado, page})
        },
        onGetPartidasByFalla: (idFalla) => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_FALLA_REQUEST', idFalla });
       },
        onGetFallas: () => {
            dispatch({ type: 'LISTA_FALLAS_ACTIVAS_REQUEST' });
   },
    } 
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ReasignarForm);