import React, { Component } from 'react';
import { Button, Col, Spin, Modal, Row, Table, Tooltip } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';
import { FilePdfFilled } from '@ant-design/icons';

class ModalListadoInventarioFisicoGeneral extends Component {
    formRef = React.createRef();

    constructor(props) {
        super(props)
        this.state = {page: 1}
    }

    render() {
        const { showModalInventarioFisicoGeneral,fetchingListaInventarioFisicoBusqueda, listaInvetarioFisicoBusqueda,listaInvetarioFisicoBusquedaCantidad, 
            onShowModalInventarioFisico, onBusquedaListadoInventario, onDescargarInventarioFisico } = this.props
        const {almacenes, pisos, areas, pasillos, racks} = this.props.data;

        let columns = [
            {
                title: 'SKU',
                dataIndex: 'sku',
                key: 'sku',
            },
            {
                title: 'Clave del producto',
                dataIndex: 'clave',
                key: 'clave',
            },
            {
                title: 'Nombre del producto',
                dataIndex: 'nombre',
                key: 'nombre',
            },
            {
                title: 'Unidad de medida',
                dataIndex: 'unidadMedida',
                key: 'unidadMedida',
            },
        ]

        const handleCancel = () => {
            onShowModalInventarioFisico();
            this.setState( {page:1})
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            onBusquedaListadoInventario(almacenes, pisos, areas, pasillos, racks, pagination.current);
        }

        const exportarPDF = () => {
            onDescargarInventarioFisico(almacenes, pisos, areas, pasillos, racks);
        }

        return (
            <Modal
                visible={showModalInventarioFisicoGeneral}
                title='Listado para Toma de Inventario Físico'
                onCancel={handleCancel}
                footer={false}
                width="60%"
            >
                <Row type='flex' justify='center'>
                    <Col span={24}>
                        <Table
                            title={() => 
                                <Row justify='end' gutter={[8,8]} style={{ paddingRight:'1em' }}>
                                    <Col span={1}>
                                        <Tooltip title='Exportar a PDF '>
                                                <Button className="iconSecundary" shape='circle' disabled={listaInvetarioFisicoBusquedaCantidad <= 0} icon={<FilePdfFilled /> } loading={this.props.fetchingListaInventarioFisicoDescarga} onClick={exportarPDF}/>
                                        </Tooltip>
                                    </Col>
                                </Row>
                            }
                            size="small"
                            rowKey="sku"
                            columns={columns}
                            dataSource={listaInvetarioFisicoBusqueda}
                            loading={this.props.fetchingListaInventarioFisicoBusqueda || this.props.fetchingListaInventarioFisicoDescarga}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaInvetarioFisicoBusquedaCantidad, simple: true, current: this.state.page }}
                        />
                    </Col>
                    {/* <Col span={12}>
                        <Button className="btnFiltrar" loading={this.props.fetchingListaInventarioFisicoDescarga} style={{ width: '100%' }} onClick={exportarPDF}>Exportar a PDF</Button>
                    </Col> */}
                </Row>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalInventarioFisicoGeneral: state.AlmacenesReducer.showModalInventarioFisicoGeneral,
        page: state.AlmacenesReducer.page,
        fetchingListaInventarioFisicoBusqueda: state.AlmacenesReducer.fetchingListaInventarioFisicoBusqueda,
        listaInvetarioFisicoBusqueda: state.AlmacenesReducer.listaInvetarioFisicoBusqueda,
        listaInvetarioFisicoBusquedaCantidad: state.AlmacenesReducer.listaInvetarioFisicoBusquedaCantidad,
        fetchingListaInventarioFisicoDescarga: state.AlmacenesReducer.fetchingListaInventarioFisicoDescarga,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalInventarioFisico: () => {
            dispatch({ type: 'SHOW_MODAL_LISTA_INVENTARIO_FISICO_GENERAL', showModalInventarioFisicoGeneral: false });
        },
        onBusquedaListadoInventario: (almacenes,pisos, areas, pasillos, racks, page) => {
            dispatch({ type: 'LISTA_INVENTARIO_FISICO_GENERAL_REQUEST', almacenes:almacenes, pisos:pisos, areas:areas, pasillos: pasillos, racks: racks, page: page});
        },
        onDescargarInventarioFisico: (almacenes,pisos, areas, pasillos, racks) => {
            dispatch( { type: 'LISTA_INVENTARIO_FISICO_DESCARGA_REQUEST', almacenes: almacenes, pisos:pisos, areas:areas, pasillos:pasillos, racks:racks });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalListadoInventarioFisicoGeneral);