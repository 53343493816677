import { takeLatest, call, put } from "redux-saga/effects";
import * as mecanismosDePagosActions from '../actions/MecanismosDePagosActions';
import * as informacionDelProyectoActions from '../actions/InformacionDelProyectoActions'
import * as configuracionYSeguridadActions from '../actions/ConfiguracionYSeguridadActions'
import {message} from 'antd';
import {
    listaFallasDeCalidadActivosCall,
    getFallasDeCalidadPageCall,
    crearFallaDeCalidadCall,
    editarFallaDeCalidadCall,
    estadoFallaDeCalidadCall,

    crearFallasDisponibilidadCall,
    listaFallasDisponibilidadBusquedaCall,
    estadoFallasDisponibilidadCall,
    editarFallasDisponibilidadCall,
    listaCategoriasDisponibilidadCall,

    listaEstandaresDesempenoEspecificosActivosCall,
    getEstandaresDesempenoEspecificosPageCall,
    crearEstandarDesempenoEspecificosCall,
    editarEstandarDesempenoEspecificosCall,
    estadoEstandarDesempenoEspecificosCall,

    listaTiempoDeGarantiaBusquedaCall,
    cambiarEstadoTiempoDeGarantiaCall,
    crearTiempoDeGarantiaCall,
    editarTiempoDeGarantiaCall,
    listaTiempoTodosCall,

    listaPonderacionServAreaBusquedaCall,
    cambiarEstadoPonderacionServAreaCall,
    crearPonderacionServAreaCall,
    editarPonderacionServAreaCall,
    listaServiciosFiltroCall,
    listaServiciosEnAreasCall,

    listaReferenciasDeEstandaresBusquedaCall,
    cambiarEstadoReferenciaDeEstandarCall,
    crearReferenciaDeEstandarCall,
    editarReferenciaDeEstandarCall,
    listaReferenciasDeEstandaresActivosCall,

    listaClavesMetodosSupervisionActivosCall,
    getClavesMetodosSupervisionPageCall,
    estadoClaveMetodoSupervisionCall,
    crearClaveMetodoSupervisionCall,
    editarClaveMetodoSupervisionCall,

    listaEstandaresDesempeñoFiltroCall,
    listaEstandaresDesempeñoFiltroActivosCall,
    listaEstandaresDesempenoBusquedaCall,
    cambiarEstadoEstandarDeDesempeñoCall,
    crearEstadoEstandarDeDesempeñoCall,
    editarEstandarDeDesempeñoCall,

    listaMesesCalendarioCall,
    listaAñosCalendarioCall,
    detalleMesCalendarioCall,
    calcularMesCalendarioCall,
    cerrarMesCalendarioCall,
    crearMesCalendarioCall,
    editarMesCalendarioCall,
    agregarPPLCall,
    exportarExcelMesCalendarioCall,

    getValoresPagoAnualCall,
    cambiarEstadoINCP0,
    crearINCP0Call,
    editarINPC0Call,
    getPARvCall,
    crearPARvCall,
    verificarPARvCall,
    listaPeriodoFraccionMaximaDescCall,
    crearPeriodoCall,
    verificarAñosPeriodoCall,
    editarPeriodoFMDCall,
    listaPeriodosTodosCall,

    obtenerNivelesSeveridadActivosCall,
    obtenerNivelesSeveridadBusquedaCall,
    estadoNivelSeveridadCall,
    crearNivelSeveridadCall,
    editarNivelSeveridadCall,

    listaNivelesTiemposRActivosCall,
    listaNivelesTiemposRCall,
    nivelesTiemposREstadoCall,
    nivelTiempoRAnterioCall,
    crearNivelTiempoRCall,
    editarNivelTiempoRCall,

    valorTipoDeCalculoDeDeductivaCall,
    listaMesesAbiertosCall,
    listaResumenServicioCall,
    listaResumenTipoCall,
    deductivaResumenExcelCall,
    listaDetalleCalculoDeductivasCall,
    detalleDeductivaExcelCall,
    procesarDeductivaCall,

    listaDocumentosReiteradoUltimosCall,
    listaTicketsReiteradosCall,
    listaTicketsOrigenCall,
    documentoRelacionadoCall,
    documentoRelacionadoByNumeroCall,
    crearDocumentoReiteracionCall,
    ticketsReiteradosByNumeroDocumentoCall,
    eliminarNumeroDocumentoCall,
    activosByCodigoInternoCall,

    reporteResumenCalculoDeductivaReiteracionCall,
    deductivaReiteracionResumenExcelCall,
    listaDetalleCalculoDeductivasReiteracionCall,
    detalleDeductivaReiteracionExcelCall,
    procesarDeductivaReiteracionCall,

    busquedaFoliosConciliacionCall,
    listaMesesConciliacionCall,
    listaTicketsConciliarCall,
    listaServiciosConciliacionCall,
    crearFolioConciliacionCall,
    descargarFolioConciliacionCall,
    listaTicketsbyFolioCall,
    eliminarTicketFCCall,
    editarFolioConciliacionCall,
    cerrarFolioConciliacionCall,

    busquedaTicketSSCall,
    fallasPorTipoFallaCall,
    nivelesDeSeveridadActivosPorFallaCall,
    crearConciliacionCall,
    seccionesPorServiciosCall,
    fallasPorServiciosCall,
    busquedaFolioConciliacionCall,
    edicionFolioConciliacionCall,

    getFoliosErrorOmisionCall,
    getDocFizcalizacionCall,
    crearFolioEOCall,
    editarFolioEOCall,
    cerrarFolioEOCall,

    reporteResumenCalculoDeductivaErrorCall,
    deductivaErrorResumenExcelCall,
    listaDetalleCalculoDeductivaErrorCall,
    detalleDeductivaErrorExcelCall,
    procesarDeductivaErrorCall,

    listaPagoServiciosMesesCall,
    calculoPagoServicioCall,
    guardarPagoServicioCall,
    pagoServicioDetalleCall,
    cerrarPagoServicioCall,
    listaServiciosPublicosMesCall,
    listaServiciosPublicosGeneralesCall,
    exportarPDFPagoDeServiciosPublicosCall,

    deductivaTicketsNoConciliadosCall,

    listaMesesProcesadosCall,
    listaDeductivasRDOCall,
    detalleDeductivaRDOCall,
    exportarDetalleDeductivaRDOExcelCall,
    deductivasAnterioresRDOCall,

    getResumenReporteDeductivasCall,
    getResumenReporteDeductivasExcelCall,
    getDetalleResumenReporteDeductivasCall,
    getDetalleResumenReporteDeductivasExcelCall,

    getConcentradoAnualResumenCall,
    getDetalleConcentradoAnualCall,
    getResumenConcentradoAnualExcelCall,
    getDetalleConcentradoAnualExcelCall,
    getDetalleReporteDeductivasPDFCall,
} from '../../services/MecanismosDePagosServices';
import moment from 'moment';
// SAGAS TIEMPO DE GARANTIA -- BUSQUEDA

// FILTRO DE SERVICIO PARA SELECT
function* listaServiciosFiltro(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const busqueda = action.busqueda;
      // request api
        const response = yield call(listaServiciosFiltroCall,token, busqueda)
        console.log(response);

        if (response.status === 200) {
            let listaServiciosFiltro = response.data;

            yield put({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro})
        } else {
            yield put ({ type: "LISTA_SERVICIOS_FILTRO_FAILURE"})
        }
    } catch (error) {
        yield put ({ type: "LISTA_SERVICIOS_FILTRO_FAILURE"})
    }
}
export function* listaServiciosFiltroSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_SERVICIOS_FILTRO_REQUEST,listaServiciosFiltro )
}
// LISTA ACTIVOS E INACTIVOS
function* tiemposGarantiaTodos(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token')

        let response = yield call(listaTiempoTodosCall, token)
        console.log('response', response)

        if(response.status === 200){
            let listaTiemposTodos = response.data;

            for(let i = 0; i < listaTiemposTodos.length; i++){
                if(listaTiemposTodos[i].serviciosByIdServicio){
                    listaTiemposTodos[i].nombre = listaTiemposTodos[i].serviciosByIdServicio.nombre;
                } else {
                    listaTiemposTodos[i].nombre = 'No tiene servicio'
                }

                if(listaTiemposTodos[i].unidadesMedidasByIdUnidadMedida){
                    listaTiemposTodos[i].unidadMedida = listaTiemposTodos[i].unidadesMedidasByIdUnidadMedida.unidadMedida
                } else {
                    listaTiemposTodos[i].unidadMedida = 'No tiene unidad de medida'
                }
            }

            yield put({ type: mecanismosDePagosActions.LISTA_TIEMPODEGARANTIA_TODOS_SUCCESS, listaTiemposTodos})
        } else {
            yield put({ type: mecanismosDePagosActions.LISTA_TIEMPODEGARANTIA_TODOS_FAILURE})
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.LISTA_TIEMPODEGARANTIA_TODOS_FAILURE})
    }
}

export function* tiemposGarantiaTodosSaga(){
    yield takeLatest(mecanismosDePagosActions.LISTA_TIEMPODEGARANTIA_TODOS_REQUEST, tiemposGarantiaTodos)
}


// SAGA PARA INICIALIZAR VALORES DEL FORM EEN LISTA PARA VERIFICAR LOS TIEMPOS DE GARANTIA
function* listaTiemposDeGarantiaForm(action){
    try {
        console.log('listaTiemposDeGarantiaForm action', action)
        const token = sessionStorage.getItem('token')

        let response = yield call(listaTiempoTodosCall, token)
        console.log('response ', response)

        if(response.status === 200){
            let listaTiemposDeGarantia = response.data;
            let listaTiemposDeGar = {};
            let unidadesMediPorCategoria = action.unidadesMediPorCategoria

            for(let i = 0; i < listaTiemposDeGarantia.length; i++){

                if(action.tiempoDeGarantiaSeleccionado.idTiempoGarantia === listaTiemposDeGarantia[i].idTiempoGarantia ){
                    listaTiemposDeGar['tiempoGarantiaNuevo-' + listaTiemposDeGarantia[i].idTiempoGarantia] = action.formulario.tiempoGarantia;
                    listaTiemposDeGar['unidadMedidaNuevo-' + listaTiemposDeGarantia[i].idTiempoGarantia] = action.formulario.unidadMedida;
                } else {
                    listaTiemposDeGar['tiempoGarantiaNuevo-' + listaTiemposDeGarantia[i].idTiempoGarantia] = listaTiemposDeGarantia[i].tiempoGarantia;
                    listaTiemposDeGar['unidadMedidaNuevo-' + listaTiemposDeGarantia[i].idTiempoGarantia] = listaTiemposDeGarantia[i].unidadesMedidasByIdUnidadMedida.unidadMedida;
                }

            }
            console.log('listaTiemposDeGar: ', listaTiemposDeGar)
            yield put({ type: mecanismosDePagosActions.LISTA_TIEMPODEGARANTIA_FORM_SUCCESS, listaTiemposDeGarantia, listaTiemposDeGar})
        } else {
            yield put({ type: mecanismosDePagosActions.LISTA_TIEMPODEGARANTIA_FORM_FAILURE})
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.LISTA_TIEMPODEGARANTIA_FORM_FAILURE})
    }
}

export function* listaTiemposDeGarantiaFormSaga(){
    yield takeLatest(mecanismosDePagosActions.LISTA_TIEMPODEGARANTIA_FORM_REQUEST, listaTiemposDeGarantiaForm)
}

function* tiempoDeGarantiaBusqueda(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token')
        let page = action.page - 1;
        let busqueda = ''
        if(action.busqueda){
            busqueda = "&servicio=" + action.busqueda;
        }
        let response = yield call(listaTiempoDeGarantiaBusquedaCall, token, page, busqueda)
        console.log(response)

        if(response.status === 200){
            let listaTiempoDeGarantiaBusqueda = response.data.data;
            let listaTiempoDeGarantiaBusquedaCantidad = response.data.cantidad;


            for(let i = 0; i < listaTiempoDeGarantiaBusqueda.length; i++){
                listaTiempoDeGarantiaBusqueda[i].fechaAlta = listaTiempoDeGarantiaBusqueda[i].fechaAlta.split('T')[0].split('-').reverse().join('-')
                listaTiempoDeGarantiaBusqueda[i].rangoVigencia = [moment(listaTiempoDeGarantiaBusqueda[i].fechaInicioVigencia, 'YYYY-MM-DD'),moment(listaTiempoDeGarantiaBusqueda[i].fechaFinVigencia, 'YYYY-MM-DD')]

                if(listaTiempoDeGarantiaBusqueda[i].serviciosByIdServicio){
                    listaTiempoDeGarantiaBusqueda[i].nombre = listaTiempoDeGarantiaBusqueda[i].serviciosByIdServicio.nombre;
                } else {
                    listaTiempoDeGarantiaBusqueda[i].nombre = 'No tiene servicio'
                }

                if(listaTiempoDeGarantiaBusqueda[i].unidadesMedidasByIdUnidadMedida){
                    listaTiempoDeGarantiaBusqueda[i].unidadMedida = listaTiempoDeGarantiaBusqueda[i].unidadesMedidasByIdUnidadMedida.unidadMedida
                } else {
                    listaTiempoDeGarantiaBusqueda[i].unidadMedida = 'No tiene unidad de medida'
                }
            }

            yield put({ type: mecanismosDePagosActions.LISTA_TIEMPODEGARANTIA_BUSQUEDA_SUCCESS, listaTiempoDeGarantiaBusqueda, listaTiempoDeGarantiaBusquedaCantidad})
        } else {
            yield put({ type: mecanismosDePagosActions.LISTA_TIEMPODEGARANTIA_BUSQUEDA_FAILURE})
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.LISTA_TIEMPODEGARANTIA_BUSQUEDA_FAILURE})
    }
}

export function* tiempoDeGarantiaBusquedaSaga(){
    yield takeLatest(mecanismosDePagosActions.LISTA_TIEMPODEGARANTIA_BUSQUEDA_REQUEST, tiempoDeGarantiaBusqueda)
}
// SAGAS TIEMPO DE GARANTIA -- ACTIVAR/ DESACTIVAR
function* cambiarEstadoTiempoDeGarantia(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idTiempoGarantia = action.idTiempoGarantia;

        const response = yield call(cambiarEstadoTiempoDeGarantiaCall, token, idTiempoGarantia)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const page = action.page;
            const busqueda = '';
            const showSuccessMsg = true;
            const textMessage = "El estado del tiempo de garantia cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.ESTADO_TIEMPODEGARANTIA_SUCCESS})
            yield put({ type: mecanismosDePagosActions.LISTA_TIEMPODEGARANTIA_BUSQUEDA_REQUEST, page, busqueda})
        } else {
            const showErrorMsg = true;
            const textMessage = "El servicio seleccionado esta en uso y no puede desactivarse";
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.ESTADO_TIEMPODEGARANTIA_FAILURE})
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.ESTADO_TIEMPODEGARANTIA_FAILURE})
    }
}

export function* cambiarEstadoTiempoDeGarantiaSaga(){
    yield takeLatest(mecanismosDePagosActions.ESTADO_TIEMPODEGARANTIA_REQUEST, cambiarEstadoTiempoDeGarantia)
}

// SAGAS TIEMPO DE GARANTIA -- CREAR
function* crearTiempoDeGarantia(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token')
        const data = {
            "idTiempoGarantia": null,
            "tiempoGarantia": action.formulario.tiempoGarantia,
            "idServicio": parseInt(action.formulario.idServicio),
            "idUnidadMedida": parseInt(action.formulario.idUnidadMedida),
            "fechaInicioVigencia": action.formulario.rangoVigencia.split(',')[0],
            "fechaFinVigencia": "",
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": null,
            "rutaArchivo": ""
        }

        console.log(data);
        const response = yield call(crearTiempoDeGarantiaCall, token, data)
        console.log(response)

        if(response.status === 200){
            const page = action.page;
            console.log('page')
            console.log(page)
            const busqueda = '';
            const showModalNuevoTiempoDeGarantia = false;
            const showSuccessMsg = true;
            const textMessage = "El tiempo de garantia se creó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.NUEVO_TIEMPODEGARANTIA_SUCCESS})
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearTiempoDeGarantia'});
            yield put({ type: mecanismosDePagosActions.LISTA_TIEMPODEGARANTIA_BUSQUEDA_REQUEST, page, busqueda})
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_NUEVO_TIEMPODEGARANTIA, showModalNuevoTiempoDeGarantia})
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.NUEVO_TIEMPODEGARANTIA_FAILURE})
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.NUEVO_TIEMPODEGARANTIA_FAILURE})
    }
}
export function* crearTiempoDeGarantiaSaga(){
    yield takeLatest(mecanismosDePagosActions.NUEVO_TIEMPODEGARANTIA_REQUEST, crearTiempoDeGarantia)
}

//SAGAS TIEMPO DE GARANTIA -- EDITAR
function* editarTiempoDeGarantia(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token')
        let idTiempoGarantia = action.tiempoDeGarantiaSeleccionado.idTiempoGarantia;
        let idServicio = action.formulario.nombre;
        let idUnidadMedida = action.formulario.unidadMedida;
        let limpiarFormulario = 'editarTiempoDeGarantia';
        let list = [];
        if(idServicio.split('-')[1]){
            idServicio = idServicio.split('-')[0];
        }

        if(isNaN(idServicio)){
            idServicio = action.tiempoDeGarantiaSeleccionado.idServicio
        }
        if(isNaN(idUnidadMedida)){
            idUnidadMedida = action.tiempoDeGarantiaSeleccionado.idUnidadMedida
        }
        if(action.tiemposEditados){
            console.log('entró  a tiemposEditados');
            for(let i=0; i<action.listaTiemposTodos.length; i++){
                for(let x=0; x<action.tiemposEditados.length; x++){
                    if(action.tiemposEditados[x].idTiempoGarantia === action.listaTiemposTodos[i].idTiempoGarantia){
                        const tiemposAgregar = action.listaTiemposTodos[i];
                        tiemposAgregar.tiempoGarantia = action.tiemposEditados[x].tiempoGarantiaNuevo;
                        tiemposAgregar.unidadMedida = action.tiemposEditados[x].unidadMedidaNuevo;
                        tiemposAgregar.idUnidadMedida = action.tiemposEditados[x].idUnidadMedida;
                        list.push(tiemposAgregar);
                    }
                }
            }
        }
        console.log('list', list)

        const data = {
            "idTiempoGarantia": idTiempoGarantia,
            "tiempoGarantia": action.formulario.tiempoGarantia,
            "idServicio": idServicio,
            "idUnidadMedida": idUnidadMedida,
            "fechaInicioVigencia": action.formulario.rangoVigencia.split(',')[0],
            "fechaFinVigencia": action.formulario.rangoVigencia.split(',')[1],
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": null,
            "rutaArchivo": action.formulario.rutaArchivo
        }

        console.log(data);

        const formData = new FormData();
        if(action.formularioPDF){
            limpiarFormulario = 'editarTiempoDeGarantiaPDF';
            formData.append('file', action.formularioPDF.pdf.file.originFileObj);
            formData.append('list', new Blob([JSON.stringify(list)], {
                type: "application/json"
            }));
        }

        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));


        const response = yield call(editarTiempoDeGarantiaCall, token, idTiempoGarantia, formData)
        console.log(response)

        if(response.status === 200){
            const page = action.page;
            const busqueda = '';
            const showModalEditarTiempoDeGarantia= false;
            const tiempoDeGarantiaSeleccionado = null;
            const showSuccessMsg = true;
            const textMessage = "El tiempo de garantia se modificó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario});
            yield put({ type: mecanismosDePagosActions.EDITAR_TIEMPODEGARANTIA_SUCCESS})
            yield put({ type: mecanismosDePagosActions.CAMBIAR_VISTA_TIEMPODEGARANTIA, vistaModalTiempoDeGarantia: 1, formularioGarantia: null,tiemposEditados: null})
            yield put({ type: mecanismosDePagosActions.LISTA_TIEMPODEGARANTIA_BUSQUEDA_REQUEST, page, busqueda})
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_EDITAR_TIEMPODEGARANTIA, showModalEditarTiempoDeGarantia, tiempoDeGarantiaSeleccionado})
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EDITAR_TIEMPODEGARANTIA_FAILURE})
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.EDITAR_TIEMPODEGARANTIA_FAILURE})
    }
}
export function* editarTiempoDeGarantiaSaga(){
    yield takeLatest(mecanismosDePagosActions.EDITAR_TIEMPODEGARANTIA_REQUEST, editarTiempoDeGarantia)
}


//CATÁLOGO CATEGORÍAS FALLAS DE CALIDAD
function* listaFallasDeCalidadActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaFallasDeCalidadActivosCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaCategoriasFallasDeCalidadActivos = response.data;
            yield put({ type: mecanismosDePagosActions.LISTA_CATEGORIAS_FALLAS_CALIDAD_ACTIVOS_SUCCESS, listaCategoriasFallasDeCalidadActivos });
        }
        else {
            yield put({ type: mecanismosDePagosActions.LISTA_CATEGORIAS_FALLAS_CALIDAD_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.LISTA_CATEGORIAS_FALLAS_CALIDAD_ACTIVOS_FAILURE });
    }
}
export function* listaFallasDeCalidadActivosSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_CATEGORIAS_FALLAS_CALIDAD_ACTIVOS_REQUEST, listaFallasDeCalidadActivos);
}

function* listaFallasDeCalidadEdicion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaFallasDeCalidadActivosCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaFallasDeCalidadEdicion = response.data;
            let objeto = {};
            let listaValoresIniciales = {};
            listaFallasDeCalidadEdicion.map(item => {
                const propiedad = `valorPorcentaje-${item.idFallaCalidad}`;
                objeto = {[propiedad]: item.valorPorcentaje};
                if (item.idFallaCalidad == action.CategoriaFallaDeCalidadSeleccionado.idFallaCalidad) {
                    objeto = {[propiedad]: action.formularioFallaCalidad.valorPorcentaje};
                }
                listaValoresIniciales = {...listaValoresIniciales,...objeto};
            })
            console.log(listaValoresIniciales);

            yield put({ type: mecanismosDePagosActions.LISTA_CATEGORIAS_FALLAS_CALIDAD_EDICION_SUCCESS, listaFallasDeCalidadEdicion, listaValoresIniciales });
        }
        else {
            yield put({ type: mecanismosDePagosActions.LISTA_CATEGORIAS_FALLAS_CALIDAD_EDICION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.LISTA_CATEGORIAS_FALLAS_CALIDAD_EDICION_FAILURE });
    }
}
export function* listaFallasDeCalidadEdicionSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_CATEGORIAS_FALLAS_CALIDAD_EDICION_REQUEST, listaFallasDeCalidadEdicion);
}

function* listaFallasDeCalidadBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let busqueda = '';
        if (action.busqueda) {
            busqueda = action.busqueda;
        }
        console.log('page');
        console.log(page);
        console.log('busqueda');
        console.log(busqueda);

        let response = yield call(getFallasDeCalidadPageCall, token, page, busqueda);
        console.log(response);

        if (response.status == 200) {
            let listaCategoriasFallasDeCalidadBusqueda = response.data.data;
            const listaCategoriasFallasDeCalidadBusquedaCantidad = response.data.cantidad;
            for (let i = 0; i < listaCategoriasFallasDeCalidadBusqueda.length; i++) {
                let fechaAlta = listaCategoriasFallasDeCalidadBusqueda[i].fechaAlta.split('T')[0];
                listaCategoriasFallasDeCalidadBusqueda[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
                listaCategoriasFallasDeCalidadBusqueda[i].rangoVigencia = [moment(listaCategoriasFallasDeCalidadBusqueda[i].fechaInicioVigencia, 'YYYY-MM-DD'),moment(listaCategoriasFallasDeCalidadBusqueda[i].fechaFinVigencia, 'YYYY-MM-DD')]
            };
            yield put({ type: mecanismosDePagosActions.CATEGORIAS_FALLAS_CALIDAD_BUSQUEDA_SUCCESS, listaCategoriasFallasDeCalidadBusqueda, listaCategoriasFallasDeCalidadBusquedaCantidad });
        }
        else {
            yield put({ type: mecanismosDePagosActions.CATEGORIAS_FALLAS_CALIDAD_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.CATEGORIAS_FALLAS_CALIDAD_BUSQUEDA_FAILURE });
    }
}
export function* listaActivosBusquedaSaga() {
    yield takeLatest(mecanismosDePagosActions.CATEGORIAS_FALLAS_CALIDAD_BUSQUEDA_REQUEST, listaFallasDeCalidadBusqueda);
}

function* cambiarEstadoFallaDeCalidad(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idFallaCalidad = action.idFallaCalidad;

        const response = yield call(estadoFallaDeCalidadCall, token, idFallaCalidad);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const busqueda = '';
            const showSuccessMsg = true;
            const textMessage = "El estado de la categoría de falla de calidad cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.ESTADO_CATEGORIA_FALLA_CALIDAD_SUCCESS });
            yield put({ type: mecanismosDePagosActions.LISTA_CATEGORIAS_FALLAS_CALIDAD_ACTIVOS_REQUEST });
            yield put({ type: mecanismosDePagosActions.CATEGORIAS_FALLAS_CALIDAD_BUSQUEDA_REQUEST, page, busqueda });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: mecanismosDePagosActions.ESTADO_CATEGORIA_FALLA_CALIDAD_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.ESTADO_CATEGORIA_FALLA_CALIDAD_FAILURE });
    }
}
export function* cambiarEstadoActivoSaga() {
    yield takeLatest(mecanismosDePagosActions.ESTADO_CATEGORIA_FALLA_CALIDAD_REQUEST, cambiarEstadoFallaDeCalidad);
}

function* crearCategoriaFallaDeCalidad(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idFallaCalidad": null,
            "fallaCalidad": action.formulario.fallaCalidad,
            "descripcion": action.formulario.descripcion,
            "valorPorcentaje": action.formulario.valorPorcentaje,
            "fechaInicioVigencia": action.formulario.fechaInicioVigencia,
            "fechaFinVigencia": null,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
            "rutaArchivo": null
        }

        console.log('Data');
        console.log(data);
        const response = yield call(crearFallaDeCalidadCall, token, data);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const busqueda = '';
            const showModalNuevaCategoriaFallaDeCalidad = false;
            const showSuccessMsg = true;
            const textMessage = "La categoría falla de calidad " + action.formulario.fallaCalidad + " se creó de forma correcta.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearCategoriaFalla' });
            yield put({ type: mecanismosDePagosActions.NUEVA_CATEGORIA_FALLA_CALIDAD_SUCCESS });
            yield put({ type: mecanismosDePagosActions.CATEGORIAS_FALLAS_CALIDAD_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: mecanismosDePagosActions.LISTA_CATEGORIAS_FALLAS_CALIDAD_ACTIVOS_REQUEST });
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_NUEVA_CATEGORIA_FALLA_CALIDAD, showModalNuevaCategoriaFallaDeCalidad });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: mecanismosDePagosActions.NUEVA_CATEGORIA_FALLA_CALIDAD_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.NUEVA_CATEGORIA_FALLA_CALIDAD_FAILURE });
    }
}
export function* crearCategoriaFallaDeCalidadSaga() {
    yield takeLatest(mecanismosDePagosActions.NUEVA_CATEGORIA_FALLA_CALIDAD_REQUEST, crearCategoriaFallaDeCalidad);
}

function* editarCategoriaFallaDeCalidad(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idFallaCalidad = action.CategoriaFallaDeCalidadSeleccionado.idFallaCalidad;
        const {listaFiltradaEditados} = action
        let limpiarFormulario = 'editarCategoriaFalla';

        const data = {
            "idFallaCalidad": idFallaCalidad,
            "fallaCalidad": action.formulario.fallaCalidad,
            "descripcion": action.formulario.descripcion,
            "valorPorcentaje": action.formulario.valorPorcentaje,
            "fechaInicioVigencia": action.formulario.fechaInicioVigencia,
            "fechaFinVigencia": action.formulario.fechaFinVigencia,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
            "rutaArchivo": null
        }

        console.log('data');
        console.log(data);
        console.log('listaFiltrada');
        console.log(listaFiltradaEditados);
        const formData = new FormData();
        if(action.formularioPDF){
            limpiarFormulario = 'editarCategoriaFallaPDF';
            formData.append('file', action.formularioPDF.pdf.file.originFileObj);
            formData.append('list', new Blob([JSON.stringify(listaFiltradaEditados)],{
                type: 'application/json'
            }));
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));

        console.log("formData")
        console.log(formData);
        const response = yield call(editarFallaDeCalidadCall, token, formData, idFallaCalidad);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const busqueda = '';
            const showModalEditarCategoriaFallaDeCalidad = false;
            const showSuccessMsg = true;
            let textMessage = "La categoría falla de calidad " + action.formulario.fallaCalidad + " se modificó de forma correcta";
            let CategoriaFallaDeCalidadSeleccionado = null;
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario });
            yield put({ type: mecanismosDePagosActions.EDITAR_CATEGORIA_FALLA_CALIDAD_SUCCESS });
            yield put({ type: mecanismosDePagosActions.CAMBIAR_VISTA_FALLA_CALIDAD, vistaModalFallaCalidad: 1, formularioFallaCalidad: null, listaFiltradaEditados: []});
            yield put({ type: mecanismosDePagosActions.CATEGORIAS_FALLAS_CALIDAD_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: mecanismosDePagosActions.LISTA_CATEGORIAS_FALLAS_CALIDAD_ACTIVOS_REQUEST });
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_EDITAR_CATEGORIA_FALLA_CALIDAD, showModalEditarCategoriaFallaDeCalidad, CategoriaFallaDeCalidadSeleccionado });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: mecanismosDePagosActions.EDITAR_CATEGORIA_FALLA_CALIDAD_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.EDITAR_CATEGORIA_FALLA_CALIDAD_FAILURE });
    }
}
export function* editarCategoriaFallaDeCalidadSaga() {
    yield takeLatest(mecanismosDePagosActions.EDITAR_CATEGORIA_FALLA_CALIDAD_REQUEST, editarCategoriaFallaDeCalidad);
}

// FALLAS DE DISPONIBLIDAD
// Lista Categorias de Fallas de Disponibilidad (Activos e inactivos)
function* listaCategoriasDisponibilidad(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token')

        let response = yield call(listaCategoriasDisponibilidadCall, token)
        console.log('response', response)

        if(response.status === 200){
            let listaCategoriasDisponibilidad = response.data;
            let listaCatDisponibilidad = {};

            for(let i = 0; i < listaCategoriasDisponibilidad.length; i++){

                if(action.FallasDeDisponibilidadSeleccionado.idFallaDisponibilidad === listaCategoriasDisponibilidad[i].idFallaDisponibilidad ){
                    listaCatDisponibilidad['porcentajeDeduccion-' + listaCategoriasDisponibilidad[i].idFallaDisponibilidad] = action.formulario.porcentajeDeduccion;
                } else {
                    listaCatDisponibilidad['porcentajeDeduccion-' + listaCategoriasDisponibilidad[i].idFallaDisponibilidad] = listaCategoriasDisponibilidad[i].porcentajeDeduccion;
                }

            }
            yield put({ type: mecanismosDePagosActions.LISTA_CATEGORIAS_DISPONIBILIDAD_SUCCESS, listaCategoriasDisponibilidad, listaCatDisponibilidad})
        } else {
            yield put({ type: mecanismosDePagosActions.LISTA_CATEGORIAS_DISPONIBILIDAD_FAILURE})
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.LISTA_CATEGORIAS_DISPONIBILIDAD_FAILURE})
    }
}
export function* listaCategoriasDisponibilidadSaga(){
    yield takeLatest(mecanismosDePagosActions.LISTA_CATEGORIAS_DISPONIBILIDAD_REQUEST, listaCategoriasDisponibilidad)
}

// Busqueda Falla de Disponibilidad

function* listaFallasDeDisponibilidadBusqueda(action) {
    try {
         console.log(action);
         const token = sessionStorage.getItem('token');
         const page = action.page - 1;
         let busqueda = '';
         if(action.busqueda){
            busqueda = 'param=' + action.busqueda + '&';
         }

        const response = yield call(listaFallasDisponibilidadBusquedaCall, token, page, busqueda);
        console.log(response);

        if(response.status == 200){
              let listaFallasDeDisponibilidadBusquedaCantidad = response.data.cantidad;
              let listaFallasDeDisponibilidadBusqueda = response.data.data;

              for(let i=0; i<listaFallasDeDisponibilidadBusqueda.length; i++){
                let fechaAlta = listaFallasDeDisponibilidadBusqueda[i].fechaAlta.split('T')[0];
                listaFallasDeDisponibilidadBusqueda[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
                listaFallasDeDisponibilidadBusqueda[i].rangoVigencia = [moment(listaFallasDeDisponibilidadBusqueda[i].fechaInicioVigencia, 'YYYY-MM-DD'),moment(listaFallasDeDisponibilidadBusqueda[i].fechaFinVigencia, 'YYYY-MM-DD')];

                listaFallasDeDisponibilidadBusqueda[i].porcentajeDeduccion = parseFloat(listaFallasDeDisponibilidadBusqueda[i].porcentajeDeduccion).toFixed(0);
                listaFallasDeDisponibilidadBusqueda[i].porcentajeDeduccionTabla = parseFloat(listaFallasDeDisponibilidadBusqueda[i].porcentajeDeduccion).toFixed(0) + '%';
              }

              yield put({ type: mecanismosDePagosActions.FALLA_DISPONIBILIDAD_BUSQUEDA_SUCCESS, listaFallasDeDisponibilidadBusqueda, listaFallasDeDisponibilidadBusquedaCantidad });
        }
        else{
              yield put({ type: mecanismosDePagosActions.FALLA_DISPONIBILIDAD_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.FALLA_DISPONIBILIDAD_BUSQUEDA_FAILURE });
    }
}
export function* listaFallasDeDisponibilidadBusquedaSaga() {
    yield takeLatest(mecanismosDePagosActions.FALLA_DISPONIBILIDAD_BUSQUEDA_REQUEST, listaFallasDeDisponibilidadBusqueda);
}

// Crear Falla de Disponibilidad

function* crearFallaDisponibilidad(action) {
     try {
          console.log(action);
          const token = sessionStorage.getItem('token');

          const data = {
               "idFallaDisponibilidad": null,
               "fallaDisponibilidad": (action.formulario.fallaDisponibilidad).trim(),
               "descripcion": (action.formulario.descripcion).trim(),
               "porcentajeDeduccion": action.formulario.porcentajeDeduccion,
               "fechaAlta": null,
               "fechaActualizacion": null,
               "fechaInicioVigencia": action.formulario.rangoVigencia.split(',')[0],
               "fechaFinVigencia": action.formulario.rangoVigencia.split(',')[1],
               "rutaArchivo": "",
               "activo": true,
          }
          console.log(data);
          const response = yield call(crearFallasDisponibilidadCall, token, data);
          console.log(response);

          if(response.status === 200){
               const page = action.page;
               const busqueda = '';
               const showModalNuevoFallasDeDisponibilidad = false;
               const showSuccessMsg = true;
               const textMessage = "La cateogoría de falla de disponibilidad se creó de forma correcta";
               yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
               yield put({ type: mecanismosDePagosActions.NUEVO_FALLA_DISPONIBILIDAD_SUCCESS });
               yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearFallasDeDisponibilidad' });
               yield put({ type: mecanismosDePagosActions.FALLA_DISPONIBILIDAD_BUSQUEDA_REQUEST, page, busqueda });
               yield put({ type: mecanismosDePagosActions.SHOW_MODAL_NUEVO_FALLA_DISPONIBILIDAD, showModalNuevoFallasDeDisponibilidad });
               yield put({ type: mecanismosDePagosActions.LISTA_FALLA_DISPONIBILIDAD_REQUEST});
          }
          else{
               const showErrorMsg = true;
               const textMessage = response.message;

               yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

               yield put({ type: mecanismosDePagosActions.NUEVO_FALLA_DISPONIBILIDAD_FAILURE });
          }

     } catch (error) {
          console.log(error);
          yield put({ type: mecanismosDePagosActions.NUEVO_FALLA_DISPONIBILIDAD_FAILURE });
     }
 }
export function* crearFallaDisponibilidadSaga() {
     yield takeLatest(mecanismosDePagosActions.NUEVO_FALLA_DISPONIBILIDAD_REQUEST, crearFallaDisponibilidad);
}

 // Cambiar estado Falla de Disponibilidad

function* cambiarEstadoFallasDisponibilidad(action) {
     try {
          console.log(action);
          const token = sessionStorage.getItem('token');
          const idFallaDisponibilidad  = action.idFallaDisponibilidad;

          const response = yield call(estadoFallasDisponibilidadCall, token, idFallaDisponibilidad);
          console.log(response);

          if(response.status === 200){
               const page = action.page;
               const busqueda = '';
               const showSuccessMsg = true;
               const textMessage = "El estado de la categoría de falla de disponibilidad cambió de forma correcta";

               yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
               yield put({ type: mecanismosDePagosActions.ESTADO_FALLA_DISPONIBILIDAD_SUCCESS });
               yield put({ type: mecanismosDePagosActions.FALLA_DISPONIBILIDAD_BUSQUEDA_REQUEST, page, busqueda });
               yield put({ type: mecanismosDePagosActions.LISTA_FALLA_DISPONIBILIDAD_REQUEST });
          }
          else{
               const showErrorMsg = true;
               const textMessage = response.message;

               yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

               yield put({ type: mecanismosDePagosActions.ESTADO_FALLA_DISPONIBILIDAD_FAILURE });
          }

     } catch (error) {
          console.log(error);
          yield put({ type: mecanismosDePagosActions.ESTADO_FALLA_DISPONIBILIDAD_FAILURE });
     }
}
export function* cambiarEstadoFallasDisponibilidadSaga() {
     yield takeLatest(mecanismosDePagosActions.ESTADO_FALLA_DISPONIBILIDAD_REQUEST, cambiarEstadoFallasDisponibilidad);
}

 // Editar Falla de Disponibilidad

function* editarFallasDisponibilidad(action) {
     try {
     console.log(action);
     const token = sessionStorage.getItem('token');
     const idFallaDisponibilidad  = action.FallasDeDisponibilidadSeleccionado.idFallaDisponibilidad;
     let limpiarFormulario = 'editarFallasDisponibilidad';
     let list = [];

     if(action.fallaDisponibilidadEditados){
        for(let i=0; i<action.listaCategoriasDisponibilidad.length; i++){
            for(let x=0; x<action.fallaDisponibilidadEditados.length; x++){
                if(action.fallaDisponibilidadEditados[x].idFallaDisponibilidad === action.listaCategoriasDisponibilidad[i].idFallaDisponibilidad){
                    const fallasAgregar = action.listaCategoriasDisponibilidad[i];
                    fallasAgregar.porcentajeDeduccion = action.fallaDisponibilidadEditados[x].porcentajeDeduccion;
                    list.push(fallasAgregar);
                }
            }
        }
    }

     const data = {
          "idFallaDisponibilidad": idFallaDisponibilidad,
          "fallaDisponibilidad": (action.formulario.fallaDisponibilidad).trim(),
          "porcentajeDeduccion": action.formulario.porcentajeDeduccion,
          "descripcion": (action.formulario.descripcion).trim(),
          "fechaAlta": action.FallasDeDisponibilidadSeleccionado.fechaAlta.split('-').reverse().join('-'),
          "fechaActualizacion": action.FallasDeDisponibilidadSeleccionado.fechaActualizacion,
          "fechaInicioVigencia": action.formulario.rangoVigencia.split(',')[0],
          "fechaFinVigencia": action.formulario.rangoVigencia.split(',')[1],
          "rutaArchivo": action.FallasDeDisponibilidadSeleccionado.rutaArchivo,
          "activo": action.FallasDeDisponibilidadSeleccionado.activo
     }
     console.log('data', data);
     console.log('list', list);

    const formData = new FormData();
    if(action.formularioPDF){
        limpiarFormulario = 'editarFallaPDF';
        formData.append('file', action.formularioPDF.pdf.file.originFileObj);
        formData.append('list', new Blob([JSON.stringify(list)], {
            type: "application/json"
        }));
    }
    formData.append('data', new Blob([JSON.stringify(data)], {
        type: "application/json"
    }));

    console.log('formData: ',formData);

     const response = yield call(editarFallasDisponibilidadCall, token, formData, idFallaDisponibilidad);
     console.log(response);

     if(response.status === 200){
            const page = action.page;
            const busqueda = '';
            const showModalEditarFallasDeDisponibilidad= false;
            const FallasDeDisponibilidadSeleccionado = null;
            const showSuccessMsg = true;
            const textMessage = "La categoría de falla de disponibilidad se modificó de forma correcta";
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario });
            yield put({ type: mecanismosDePagosActions.CAMBIAR_VISTA_FALLA_DISPONIBILIDAD, vistaModalFallaDisponiblidad: 1, formularioDisponibilidad: null });
            yield put({ type: mecanismosDePagosActions.CATEGORIAS_FALLAS_CALIDAD_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: mecanismosDePagosActions.EDITAR_FALLA_DISPONIBILIDAD_SUCCESS });
            yield put({ type: mecanismosDePagosActions.FALLA_DISPONIBILIDAD_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_EDITAR_FALLA_DISPONIBILIDAD, showModalEditarFallasDeDisponibilidad, FallasDeDisponibilidadSeleccionado });
            yield put({ type: mecanismosDePagosActions.LISTA_FALLA_DISPONIBILIDAD_REQUEST });
     }
     else{
          const showErrorMsg = true;
          const textMessage = response.message;

          yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

          yield put({ type: mecanismosDePagosActions.EDITAR_FALLA_DISPONIBILIDAD_FAILURE });
     }

     } catch (error) {
     console.log(error);
     yield put({ type: mecanismosDePagosActions.EDITAR_FALLA_DISPONIBILIDAD_FAILURE });
     }
}
export function* editarFallasDisponibilidadSaga() {
     yield takeLatest(mecanismosDePagosActions.EDITAR_FALLA_DISPONIBILIDAD_REQUEST, editarFallasDisponibilidad);
}

//CATÁLOGO SERVICIOS/SECCIONES ESTÁNDARES DESEMPEÑO ESPECÍFICOS
function* listaEstandaresDesempenoEspecificosActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaEstandaresDesempenoEspecificosActivosCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaEstandaresDesempenoEspecificosActivos = response.data;
            yield put({ type: mecanismosDePagosActions.LISTA_ESTANDARES_DESEMPENO_ESPECIFICOS_ACTIVOS_SUCCESS, listaEstandaresDesempenoEspecificosActivos });
        }
        else {
            yield put({ type: mecanismosDePagosActions.LISTA_ESTANDARES_DESEMPENO_ESPECIFICOS_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.LISTA_ESTANDARES_DESEMPENO_ESPECIFICOS_ACTIVOS_FAILURE });
    }
}
export function* listaEstandaresDesempenoEspecificosActivosSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_ESTANDARES_DESEMPENO_ESPECIFICOS_ACTIVOS_REQUEST, listaEstandaresDesempenoEspecificosActivos);
}

function* listaEstandaresDesempenoEspecificosBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let busqueda = '';
        if (action.busqueda) {
            busqueda = action.busqueda;
        }
        console.log('page');
        console.log(page);
        console.log('busqueda');
        console.log(busqueda);

        let response = yield call(getEstandaresDesempenoEspecificosPageCall, token, page, busqueda);
        console.log(response);

        if (response.status == 200) {
            const listaEstandaresDesempenoEspecificosBusqueda = response.data.data;
            const listaEstandaresDesempenoEspecificosBusquedaCantidad = response.data.cantidad;
            for (let i = 0; i < listaEstandaresDesempenoEspecificosBusqueda.length; i++) {
                if(listaEstandaresDesempenoEspecificosBusqueda[i].serviciosByIdServSeccionEspecifico) {
                    listaEstandaresDesempenoEspecificosBusqueda[i].idServicio = '';
                    listaEstandaresDesempenoEspecificosBusqueda[i].nombreServicio = '';
                    listaEstandaresDesempenoEspecificosBusqueda[i].servicios = [];
                    for(let x=0; x<listaEstandaresDesempenoEspecificosBusqueda[i].serviciosByIdServSeccionEspecifico.length; x++) {
                        listaEstandaresDesempenoEspecificosBusqueda[i].nombreServicio += '-' +  listaEstandaresDesempenoEspecificosBusqueda[i].serviciosByIdServSeccionEspecifico[x].nombre + ' ';
                        listaEstandaresDesempenoEspecificosBusqueda[i].servicios.push(listaEstandaresDesempenoEspecificosBusqueda[i].serviciosByIdServSeccionEspecifico[x].nombre);
                    }
                } else {
                    listaEstandaresDesempenoEspecificosBusqueda[i].nombreServicio = "No cuenta con servicio"
                }
            };
            yield put({ type: mecanismosDePagosActions.ESTANDARES_DESEMPENO_ESPECIFICOS_BUSQUEDA_SUCCESS, listaEstandaresDesempenoEspecificosBusqueda, listaEstandaresDesempenoEspecificosBusquedaCantidad });
        }
        else {
            yield put({ type: mecanismosDePagosActions.ESTANDARES_DESEMPENO_ESPECIFICOS_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.ESTANDARES_DESEMPENO_ESPECIFICOS_BUSQUEDA_FAILURE });
    }
}
export function* listaEstandaresDesempenoEspecificosBusquedaSaga() {
    yield takeLatest(mecanismosDePagosActions.ESTANDARES_DESEMPENO_ESPECIFICOS_BUSQUEDA_REQUEST, listaEstandaresDesempenoEspecificosBusqueda);
}

function* cambiarEstadoEstandarDesempenoEspecifico(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServSeccionEspecifico = action.idServSeccionEspecifico;

        const response = yield call(estadoEstandarDesempenoEspecificosCall, token, idServSeccionEspecifico);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const busqueda = '';
            const showSuccessMsg = true;
            const textMessage = "El estado de la sección de estándares específicos se cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.ESTADO_ESTANDAR_DESEMPENO_ESPECIFICO_SUCCESS });
            yield put({ type: mecanismosDePagosActions.LISTA_ESTANDARES_DESEMPENO_ESPECIFICOS_ACTIVOS_REQUEST });
            yield put({ type: mecanismosDePagosActions.ESTANDARES_DESEMPENO_ESPECIFICOS_BUSQUEDA_REQUEST, page, busqueda });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: mecanismosDePagosActions.ESTADO_ESTANDAR_DESEMPENO_ESPECIFICO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.ESTADO_ESTANDAR_DESEMPENO_ESPECIFICO_FAILURE });
    }
}
export function* cambiarEstadoDesempenoEspecificoSaga() {
    yield takeLatest(mecanismosDePagosActions.ESTADO_ESTANDAR_DESEMPENO_ESPECIFICO_REQUEST, cambiarEstadoEstandarDesempenoEspecifico);
}

function* crearEstandarDesempenoEspecifico(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let servicios = [];
        console.log(action.formulario)
        for(let i=0; i<action.formulario.idServicio.length; i++){
            for(let x=0; x<action.listaServiciosActivosF.length; x++){
                if(parseInt(action.formulario.idServicio[i]) === action.listaServiciosActivosF[x].idServicio){
                    const servicio = {
                        "idServicio": action.listaServiciosActivosF[x].idServicio,
                        "idGrupoSer": action.listaServiciosActivosF[x].idGrupoSer,
                        "nomenclatura": action.listaServiciosActivosF[x].nomenclatura,
                        "nombre": action.listaServiciosActivosF[x].nombre,
                        "nombreC": action.listaServiciosActivosF[x].nombreC,
                        "nombreManual": action.listaServiciosActivosF[x].nombreManual,
                        "rutaManual": action.listaServiciosActivosF[x].rutaManual,
                        "urlImagen": action.listaServiciosActivosF[x].urlImagen,
                        "peso": action.listaServiciosActivosF[x].peso,
                        "observaciones": action.listaServiciosActivosF[x].observaciones,
                        "fechaAlta": null,
                        "fechaActualizacion": null,
                        "activo": true,
                        "ponderacionByIdServicio": null,
                        "contratoByIdContrato": null,
                        "grupoSerByIdGrupoSer": null
                    }
                    servicios.push(servicio);
                }
            }
        }
        console.log('servicios');
        console.log(servicios);

        const data = {
            "idServSeccionEspecifico": null,
            "descripcion": action.formulario.descripcion,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
            "serviciosByIdServSeccionEspecifico": servicios
        }

        console.log('Data');
        console.log(data);
        const response = yield call(crearEstandarDesempenoEspecificosCall, token, data);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const busqueda = '';
            const showModalNuevoEstandarDesempenoEspecifico = false;
            const showSuccessMsg = true;
            const textMessage = "La sección de estándares específicos se creó de forma correcta.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearEstandarDesempeño' });
            yield put({ type: mecanismosDePagosActions.NUEVO_ESTANDAR_DESEMPENO_ESPECIFICO_SUCCESS });
            yield put({ type: mecanismosDePagosActions.ESTANDARES_DESEMPENO_ESPECIFICOS_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: mecanismosDePagosActions.LISTA_ESTANDARES_DESEMPENO_ESPECIFICOS_ACTIVOS_REQUEST });
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_NUEVO_ESTANDAR_DESEMPENO_ESPECIFICO, showModalNuevoEstandarDesempenoEspecifico });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: mecanismosDePagosActions.NUEVO_ESTANDAR_DESEMPENO_ESPECIFICO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.NUEVO_ESTANDAR_DESEMPENO_ESPECIFICO_FAILURE });
    }
}
export function* crearEstandarDesempenoEspecificoSaga() {
    yield takeLatest(mecanismosDePagosActions.NUEVO_ESTANDAR_DESEMPENO_ESPECIFICO_REQUEST, crearEstandarDesempenoEspecifico);
}

function* editarEstandarDesempenoEspecifico(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServSeccionEspecifico = action.EstandarDesempenoEspecificoSeleccionado.idServSeccionEspecifico;
        let servicios = [];
        let limpiarFormulario = 'editarEstandarDesempeño';

        for (let i=0; i<action.formulario.servicios.length; i++) {
            if(action.EstandarDesempenoEspecificoSeleccionado.serviciosByIdServSeccionEspecifico) {
                for(let y=0; y<action.EstandarDesempenoEspecificoSeleccionado.serviciosByIdServSeccionEspecifico.length; y++) {
                    if(action.formulario.servicios[i] === action.EstandarDesempenoEspecificoSeleccionado.serviciosByIdServSeccionEspecifico[y].nombre){
                        servicios.push(action.EstandarDesempenoEspecificoSeleccionado.serviciosByIdServSeccionEspecifico[y])
                    }
                }
            }

            for(let x=0; x<action.listaServiciosActivosF.length; x++){
                if(parseInt(action.formulario.servicios[i]) === action.listaServiciosActivosF[x].idServicio){
                    const servicio = {
                        "idServicio": action.listaServiciosActivosF[x].idServicio,
                        "idGrupoSer": action.listaServiciosActivosF[x].idGrupoSer,
                        "nomenclatura": action.listaServiciosActivosF[x].nomenclatura,
                        "nombre": action.listaServiciosActivosF[x].nombre,
                        "nombreC": action.listaServiciosActivosF[x].nombreC,
                        "nombreManual": action.listaServiciosActivosF[x].nombreManual,
                        "rutaManual": action.listaServiciosActivosF[x].rutaManual,
                        "urlImagen": action.listaServiciosActivosF[x].urlImagen,
                        "peso": action.listaServiciosActivosF[x].peso,
                        "observaciones": action.listaServiciosActivosF[x].observaciones,
                        "fechaAlta": null,
                        "fechaActualizacion": null,
                        "activo": true,
                        "ponderacionByIdServicio": null,
                        "contratoByIdContrato": null,
                        "grupoSerByIdGrupoSer": null
                    }
                    servicios.push(servicio);
                }
            }
        }

        console.log('servicios');
        console.log(servicios);

        const data = {
            "idServSeccionEspecifico": null,
            "descripcion": action.formulario.descripcion,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
            "serviciosByIdServSeccionEspecifico": servicios
        }

        console.log('data');
        console.log(data);

        const response = yield call(editarEstandarDesempenoEspecificosCall, token, data, idServSeccionEspecifico);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const busqueda = '';
            const showModalEditarEstandarDesempenoEspecifico = false;
            const showSuccessMsg = true;
            let textMessage = "La sección de estándares específicos se modificó de forma correcta";
            let EstandarDesempenoEspecificoSeleccionado = null;
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario });
            yield put({ type: mecanismosDePagosActions.EDITAR_ESTANDAR_DESEMPENO_ESPECIFICO_SUCCESS });
            yield put({ type: mecanismosDePagosActions.ESTANDARES_DESEMPENO_ESPECIFICOS_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: mecanismosDePagosActions.LISTA_ESTANDARES_DESEMPENO_ESPECIFICOS_ACTIVOS_REQUEST });
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_EDITAR_ESTANDAR_DESEMPENO_ESPECIFICO, showModalEditarEstandarDesempenoEspecifico, EstandarDesempenoEspecificoSeleccionado });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: mecanismosDePagosActions.EDITAR_ESTANDAR_DESEMPENO_ESPECIFICO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.EDITAR_ESTANDAR_DESEMPENO_ESPECIFICO_FAILURE });
    }
}
export function* editarEstandarDesempenoEspecificoSaga() {
    yield takeLatest(mecanismosDePagosActions.EDITAR_ESTANDAR_DESEMPENO_ESPECIFICO_REQUEST, editarEstandarDesempenoEspecifico);
}

// SAGA -- LISTA PONDERACION SERVICIO POR AREA -- BUSQUEDA
function* listaPonderacionServicioBusqueda(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token')
        let page = action.page - 1;
        let busqueda = ''
        if(action.busqueda){
            busqueda = "param=" + action.busqueda;
        }

        const response = yield call(listaPonderacionServAreaBusquedaCall, token, busqueda, page)
        console.log(response);

        if (response.status === 200) {
            let listaPonderacionServPorAreaBusqueda = response.data.data;
            let listaPonderacionServPorAreaBusquedaCantidad = response.data.cantidad;

            for (let i = 0; i < listaPonderacionServPorAreaBusqueda.length; i++){
                listaPonderacionServPorAreaBusqueda[i].ponderacionServAreaTabla = listaPonderacionServPorAreaBusqueda[i].ponderacionServArea.toFixed(1).toString() + '%'
                listaPonderacionServPorAreaBusqueda[i].ponderacionServArea = listaPonderacionServPorAreaBusqueda[i].ponderacionServArea.toFixed(1);

                listaPonderacionServPorAreaBusqueda[i].rangoVigencia = [moment(listaPonderacionServPorAreaBusqueda[i].fechaInicioVigencia, 'YYYY-MM-DD'),moment(listaPonderacionServPorAreaBusqueda[i].fechaFinVigencia, 'YYYY-MM-DD')]
                if(listaPonderacionServPorAreaBusqueda[i].serviciosEntityByIdServicio){
                    listaPonderacionServPorAreaBusqueda[i].nombre = listaPonderacionServPorAreaBusqueda[i].serviciosEntityByIdServicio.nombre
                } else {
                    listaPonderacionServPorAreaBusqueda[i].nombre = 'No tiene servicio'
                }

                if (listaPonderacionServPorAreaBusqueda[i].areasFuncionalesEntityByIdAreaFuncional) {
                    listaPonderacionServPorAreaBusqueda[i].areaFuncional = listaPonderacionServPorAreaBusqueda[i].areasFuncionalesEntityByIdAreaFuncional.areaFuncional
                    listaPonderacionServPorAreaBusqueda[i].claveArea = listaPonderacionServPorAreaBusqueda[i].areasFuncionalesEntityByIdAreaFuncional.claveArea
                } else {
                    listaPonderacionServPorAreaBusqueda[i].areaFuncional = 'No tiene area funcional'
                    listaPonderacionServPorAreaBusqueda[i].claveArea = 'No tiene clave area'
                }
        }

        yield put({ type: mecanismosDePagosActions.LISTA_PONDERACION_SERV_POR_AREA_BUSQUEDA_SUCCESS, listaPonderacionServPorAreaBusqueda, listaPonderacionServPorAreaBusquedaCantidad})
    } else {
        yield put({ type: mecanismosDePagosActions.LISTA_PONDERACION_SERV_POR_AREA_FAILURE})
    }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.LISTA_PONDERACION_SERV_POR_AREA_FAILURE})
    }
}
export function* listaPonderacionServicioBusquedaSaga(){
    yield takeLatest(mecanismosDePagosActions.LISTA_PONDERACION_SERV_POR_AREA_BUSQUEDA_REQUEST, listaPonderacionServicioBusqueda)
}


function* listaAreasPorServicio(action){
    try {
        console.log('listaAreasPorServicio action', action)
        const token = sessionStorage.getItem('token');
        const idServicio = action.idServicio;

        const response = yield call(listaServiciosEnAreasCall, token, idServicio)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const listaAreasPorServicio = response.data;
            const listaPonderacionServicioPorArea = {'good': true};
            for(let i=0; i<listaAreasPorServicio.length; i++){
                listaAreasPorServicio[i].ponderacionServArea = listaAreasPorServicio[i].ponderacionServArea.toFixed(1).toString();
                if(action.ponderacionServPorAreaSeleccionado.idPonderacionServArea === listaAreasPorServicio[i].idPonderacionServArea){
                    listaPonderacionServicioPorArea['ponderacionServAreaNuevo-' + listaAreasPorServicio[i].idPonderacionServArea] = action.formulario.ponderacionServArea;
                }
                else{
                    listaPonderacionServicioPorArea['ponderacionServAreaNuevo-' + listaAreasPorServicio[i].idPonderacionServArea] = listaAreasPorServicio[i].ponderacionServArea;
                }
            }
            console.log('listaPonderacionServicioPorArea');
            console.log(listaPonderacionServicioPorArea);
            yield put({ type: mecanismosDePagosActions.LISTA_AREAS_POR_SERVICIO_SUCCESS, listaAreasPorServicio, listaPonderacionServicioPorArea });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.LISTA_AREAS_POR_SERVICIO_FAILURE })
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.LISTA_AREAS_POR_SERVICIO_FAILURE})
    }
}
export function* listaAreasPorServicioSaga(){
    yield takeLatest(mecanismosDePagosActions.LISTA_AREAS_POR_SERVICIO_REQUEST, listaAreasPorServicio)
}


// SAGA --  PONDERACION SERVICIO POR AREA -- ACTIVAR
function* cambiarEstadoPonderacionServArea(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idPonderacionServArea = action.idPonderacionServArea;

        const response = yield call(cambiarEstadoPonderacionServAreaCall, token, idPonderacionServArea)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const page = action.page;
            const busqueda = '';
            const showSuccessMsg = true;
            const textMessage = "El estado del ponderador cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.ESTADO_PONDERACION_SERV_POR_AREA_SUCCESS})
            yield put({ type: mecanismosDePagosActions.LISTA_PONDERACION_SERV_POR_AREA_BUSQUEDA_REQUEST, page, busqueda})
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.ESTADO_PONDERACION_SERV_POR_AREA_FAILURE})
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.ESTADO_PONDERACION_SERV_POR_AREA_FAILURE})
    }
}

export function* cambiarEstadoPonderacionServAreaSaga(){
    yield takeLatest(mecanismosDePagosActions.ESTADO_PONDERACION_SERV_POR_AREA_REQUEST, cambiarEstadoPonderacionServArea)
}

// SAGA --  PONDERACION SERVICIO POR AREA -- CREAR
function* crearPonderacionServArea(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token')

        const data = {
            "idPonderacionServArea": null,
            "idServicio": parseInt(action.formulario.idServicio),
            "idAreaFuncional": parseInt(action.formulario.idAreaFuncional),
            "ponderacionServArea": action.formulario.ponderacionServArea,
            "fechaInicioVigencia": action.formulario.rangoVigencia.split(',')[0],
            "fechaFinVigencia": " ",
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": null,
            "rutaArchivo": ""
        }
        console.log(data);
        const response = yield call(crearPonderacionServAreaCall, token, data)
        console.log(response);

        if(response.status === 200){
            const page = action.page;
            console.log('page')
            console.log(page)
            const busqueda = '';
            const showModalNuevoPonderacionServPorArea = false;
            const showSuccessMsg = true;
            const textMessage = "El Ponderador de servicio por area funcional se creó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.NUEVO_PONDERACION_SERV_POR_AREA_SUCCESS})
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearPonderadorServArea'});
            yield put({ type: mecanismosDePagosActions.LISTA_PONDERACION_SERV_POR_AREA_BUSQUEDA_REQUEST, page, busqueda})
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_NUEVO_PONDERACION_SERV_POR_AREA, showModalNuevoPonderacionServPorArea})
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.NUEVO_PONDERACION_SERV_POR_AREA_FAILURE})
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.NUEVO_PONDERACION_SERV_POR_AREA_FAILURE})
    }
}
export function* crearPonderacionServAreaSaga(){
    yield takeLatest(mecanismosDePagosActions.NUEVO_PONDERACION_SERV_POR_AREA_REQUEST,crearPonderacionServArea)
}

function* editarPonderacionServArea(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token')
        let idPonderacionServArea = action.ponderacionServPorAreaSeleccionado.idPonderacionServArea
        let idServicio = action.formulario.nombre;
        let idAreaFuncional = action.formulario.areaFuncional;
        let limpiarFormulario = 'editarPonderacionServArea';
        let list = action.ponderacionEditados;

        if(isNaN(idServicio)){
            idServicio = action.ponderacionServPorAreaSeleccionado.idServicio
        }
        if(isNaN(idAreaFuncional)){
            idAreaFuncional = action.ponderacionServPorAreaSeleccionado.idAreaFuncional
        }
        // if(action.ponderacionEditados){
        //     console.log('entró  a ponderacionEditados');
        //     for(let i=0; i<action.listaAreasPorServicio.length; i++){
        //         for(let x=0; x<action.ponderacionEditados.length; x++){
        //             if(action.ponderacionEditados[x].idPonderacionServArea === action.listaAreasPorServicio[i].idPonderacionServArea){
        //                 const ponderacionAgregar = action.listaAreasPorServicio[i];
        //                 ponderacionAgregar.ponderacionServArea = action.ponderacionEditados[x].ponderacionServArea;
        //                 list.push(ponderacionAgregar);
        //             }
        //         }
        //     }
        // }
        const data = {
            "idPonderacionServArea": idPonderacionServArea,
            "ponderacionServArea": action.formulario.ponderacionServArea,
            "idServicio": idServicio,
            "idAreaFuncional": idAreaFuncional,
            "fechaInicioVigencia": action.formulario.rangoVigencia.split(',')[0],
            "fechaFinVigencia": action.formulario.rangoVigencia.split(',')[1],
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": action.ponderacionServPorAreaSeleccionado.activo,
            "rutaArchivo": action.formulario.rutaArchivo
        }

        console.log('data');
        console.log(data);

        console.log('list');
        console.log(list);

        const formData = new FormData();
        if(action.formularioPDF){
            limpiarFormulario = 'editarPonderacionServAreaPDF';
            formData.append('file', action.formularioPDF.pdf.file.originFileObj);
            formData.append('list', new Blob([JSON.stringify(list)], {
                type: "application/json"
            }));
        }

        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));

        const response = yield call(editarPonderacionServAreaCall, token, idPonderacionServArea, formData)
        console.log(response);

        if(response.status === 200){
            const page = action.page;
            const busqueda = '';
            const showModalEditarPonderacionServPorArea= false;
            const ponderacionServPorAreaSeleccionado = null;
            const showSuccessMsg = true;
            const textMessage = "El Ponderador de servicio por area funcional se modificó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario});
            yield put({ type: mecanismosDePagosActions.EDITAR_PONDERACION_SERV_POR_AREA_SUCCESS})
            yield put({ type: mecanismosDePagosActions.CAMBIAR_VISTA_PONDERACION_SERV_POR_AREA, vistaModalPonderacionServPorArea: 1, formularioPonderacionServ: null,})
            yield put({ type: mecanismosDePagosActions.LISTA_PONDERACION_SERV_POR_AREA_BUSQUEDA_REQUEST, page, busqueda})
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_EDITAR_PONDERACION_SERV_POR_AREA, showModalEditarPonderacionServPorArea, ponderacionServPorAreaSeleccionado})

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EDITAR_PONDERACION_SERV_POR_AREA_FAILURE})
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.EDITAR_PONDERACION_SERV_POR_AREA_FAILURE})
    }
}

export function* editarPonderacionServAreaSaga(){
    yield takeLatest(mecanismosDePagosActions.EDITAR_PONDERACION_SERV_POR_AREA_REQUEST, editarPonderacionServArea)
}

// Referencias de estándares de desempeño
// Activos

function* listaReferenciasDeEstandaresActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaReferenciasDeEstandaresActivosCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaReferenciasDeEstandaresActivos = response.data;
            yield put({ type: mecanismosDePagosActions.LISTA_REFERENCIAS_ESTANDARES_ACTIVOS_SUCCESS, listaReferenciasDeEstandaresActivos });
        }
        else {
            yield put({ type: mecanismosDePagosActions.LISTA_REFERENCIAS_ESTANDARES_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.LISTA_REFERENCIAS_ESTANDARES_ACTIVOS_FAILURE });
    }
}
export function* listaReferenciasDeEstandaresActivosSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_REFERENCIAS_ESTANDARES_ACTIVOS_REQUEST, listaReferenciasDeEstandaresActivos);
}

// Busqueda

function* listaReferenciasDeEstandaresBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let param = '';
        let order = 'ASC';
        let field = 'idReferenciaDesempeno';
        if (action.busqueda.param) {
            param = 'param=' + action.busqueda.param + '&';
        }
        if (action.busqueda.order){
            order = 'order=' + action.busqueda.order + '&';
        }
        if (action.busqueda.field){
            field = 'field=' + action.busqueda.field + '&';
        }
        console.log(param)
        console.log(order)
        console.log(field)

        const response = yield call(listaReferenciasDeEstandaresBusquedaCall, token, page, param, order, field);
        console.log(response);

        if(response.status == 200){
            let listaReferenciasDeEstandaresBusquedaCantidad = response.data.cantidad;
            let listaReferenciasDeEstandaresBusqueda = response.data.data;
            let mostrarEspecifica = false;
            let banderaTiempo = false;

            for(let i=0; i<listaReferenciasDeEstandaresBusqueda.length; i++){
                let fechaAlta = listaReferenciasDeEstandaresBusqueda[i].fechaAlta.split('T')[0];
                listaReferenciasDeEstandaresBusqueda[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];

                if(listaReferenciasDeEstandaresBusqueda[i].claseReferencia === 'General'){
                    listaReferenciasDeEstandaresBusqueda[i].mostrarEspecifica = false;
                } else {
                    listaReferenciasDeEstandaresBusqueda[i].mostrarEspecifica = true;
                }

                if(listaReferenciasDeEstandaresBusqueda[i].subnivel) {
                    listaReferenciasDeEstandaresBusqueda[i].subnivelTabla = listaReferenciasDeEstandaresBusqueda[i].subnivel;
                } else {
                    listaReferenciasDeEstandaresBusqueda[i].subnivelTabla = 'No tiene subnivel';
                    listaReferenciasDeEstandaresBusqueda[i].subnivel = '';
                }

                if(listaReferenciasDeEstandaresBusqueda[i].tiempoRespuesta === 0){
                    listaReferenciasDeEstandaresBusqueda[i].banderaTiempo = false;
                } else {
                    listaReferenciasDeEstandaresBusqueda[i].banderaTiempo = true;
                }
            }

            yield put({ type: mecanismosDePagosActions.REFERENCIAS_ESTANDARES_BUSQUEDA_SUCCESS, listaReferenciasDeEstandaresBusqueda, listaReferenciasDeEstandaresBusquedaCantidad });
        }
        else{
              yield put({ type: mecanismosDePagosActions.REFERENCIAS_ESTANDARES_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.REFERENCIAS_ESTANDARES_BUSQUEDA_FAILURE });
    }
}
export function* listaReferenciasDeEstandaresBusquedaSaga() {
    yield takeLatest(mecanismosDePagosActions.REFERENCIAS_ESTANDARES_BUSQUEDA_REQUEST, listaReferenciasDeEstandaresBusqueda);
}

// Cambiar estado

function* cambiarEstadoReferenciaDeEstandar(action) {
    try {
         console.log(action);
         const token = sessionStorage.getItem('token');
         const idReferenciaDesempeno  = action.idReferenciaDesempeno;

         const response = yield call(cambiarEstadoReferenciaDeEstandarCall, token, idReferenciaDesempeno);
         console.log(response);

         if(response.status === 200){
              const page = action.page;
              const busqueda = action.busqueda;
              const showSuccessMsg = true;
              const textMessage = "El estado de la referencia de estándar de desempeño específico cambió de forma correcta";

              yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
              yield put({ type: mecanismosDePagosActions.ESTADO_REFERENCIA_ESTANDAR_SUCCESS });
              yield put({ type: mecanismosDePagosActions.REFERENCIAS_ESTANDARES_BUSQUEDA_REQUEST, page, busqueda });
              yield put({ type: mecanismosDePagosActions.LISTA_REFERENCIAS_ESTANDARES_ACTIVOS_REQUEST});
         }
         else{
              const showErrorMsg = true;
              const textMessage = response.message;

              yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

              yield put({ type: mecanismosDePagosActions.ESTADO_REFERENCIA_ESTANDAR_FAILURE });
         }

    } catch (error) {
         console.log(error);
         yield put({ type: mecanismosDePagosActions.ESTADO_REFERENCIA_ESTANDAR_FAILURE });
    }
}
export function* cambiarEstadoReferenciaDeEstandarSaga() {
    yield takeLatest(mecanismosDePagosActions.ESTADO_REFERENCIA_ESTANDAR_REQUEST, cambiarEstadoReferenciaDeEstandar);
}

// Crear

function* crearReferenciaDeEstandar(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let subnivel = '';
        if(action.formulario.subnivel){
            subnivel = action.formulario.subnivel;
        }
        const data = {
            "idReferenciaDesempeno": null,
            "referencia": action.formulario.referencia,
            "claseReferencia": action.formulario.claseReferencia,
            "subnivel": subnivel,
            "descripcion": (action.formulario.descripcion).trim(),
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
        }

        console.log(data);
        const response = yield call(crearReferenciaDeEstandarCall, token, data)
        console.log(response)

        if(response.status === 200){
            const page = action.page;
            console.log('page')
            console.log(page)
            const busqueda = {order: 'ASC', field: 'idReferenciaDesempeno'};
            const showModalNuevaReferenciaEstandar = false;
            const showSuccessMsg = true;
            const textMessage = "La referencia de estándares de desempeño se creó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.NUEVA_REFERENCIA_ESTANDAR_SUCCESS})
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearReferenciaEstandar'});
            yield put({ type: mecanismosDePagosActions.REFERENCIAS_ESTANDARES_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_NUEVA_REFERENCIA_ESTANDAR, showModalNuevaReferenciaEstandar});
            yield put({ type: mecanismosDePagosActions.LISTA_REFERENCIAS_ESTANDARES_ACTIVOS_REQUEST });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.NUEVA_REFERENCIA_ESTANDAR_FAILURE})
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.NUEVA_REFERENCIA_ESTANDAR_FAILURE})
    }
}
export function* crearReferenciaDeEstandarSaga(){
    yield takeLatest(mecanismosDePagosActions.NUEVA_REFERENCIA_ESTANDAR_REQUEST, crearReferenciaDeEstandar)
}

// Editar

function* editarReferenciaDeEstandar(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idReferenciaDesempeno = action.referenciaEstandarSeleccionada.idReferenciaDesempeno;
        let limpiarFormulario = 'editarReferenciaEstandar';
        let subnivel = '';
        if(action.formulario.subnivel){
            subnivel = action.formulario.subnivel;
        }

        const data = {
            "idReferenciaDesempeno": idReferenciaDesempeno,
            "referencia": action.formulario.referencia,
            "claseReferencia": action.formulario.claseReferencia,
            "subnivel": subnivel,
            "descripcion": (action.formulario.descripcion).trim(),
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }
        console.log(data);
        const response = yield call(editarReferenciaDeEstandarCall, token, idReferenciaDesempeno, data );
        console.log(response);

        if(response.status === 200){
            const page = action.page;
            const busqueda = action.busqueda;
            const showModalEditarReferenciaEstandar= false;
            const showSuccessMsg = true;
            const textMessage = "La referencia de estándares de desempeño " + action.formulario.referencia + " se modificó de forma correcta";
            let referenciaEstandarSeleccionada = null;

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarReferenciaEstandar'});
            yield put({ type: mecanismosDePagosActions.EDITAR_REFERENCIA_ESTANDAR_SUCCESS });
            yield put({ type: mecanismosDePagosActions.REFERENCIAS_ESTANDARES_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_EDITAR_REFERENCIA_ESTANDAR, showModalEditarReferenciaEstandar, referenciaEstandarSeleccionada });
            yield put({ type: mecanismosDePagosActions.LISTA_REFERENCIAS_ESTANDARES_ACTIVOS_REQUEST });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: mecanismosDePagosActions.EDITAR_REFERENCIA_ESTANDAR_FAILURE });
        }

    } catch (error) {
        console.log(error);
yield put({ type: mecanismosDePagosActions.EDITAR_REFERENCIA_ESTANDAR_FAILURE });
    }
}
export function* editarReferenciaDeEstandarSaga() {
    yield takeLatest(mecanismosDePagosActions.EDITAR_REFERENCIA_ESTANDAR_REQUEST, editarReferenciaDeEstandar);
}

//CATÁLOGO CLAVES MÉTODOS DE SUPERVISIÓN

function* listaClavesMetodosSupervisionActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaClavesMetodosSupervisionActivosCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaClavesMetodosSupervisionActivos = response.data;

            const listaClavesMetodosSupervisionExternos = [];

            for(let i = 0; i < listaClavesMetodosSupervisionActivos.length; i++){
                if(listaClavesMetodosSupervisionActivos[i].claveMetodoSupervision === 'Externo' || listaClavesMetodosSupervisionActivos[i].claveMetodoSupervision === 'Externa'){
                    listaClavesMetodosSupervisionExternos.push(listaClavesMetodosSupervisionActivos[i]);
                }
            }

            yield put({ type: mecanismosDePagosActions.LISTA_CLAVES_METODOS_SUPERVISION_ACTIVOS_SUCCESS, listaClavesMetodosSupervisionActivos, listaClavesMetodosSupervisionExternos });
        }
        else {
            yield put({ type: mecanismosDePagosActions.LISTA_CLAVES_METODOS_SUPERVISION_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.LISTA_CLAVES_METODOS_SUPERVISION_ACTIVOS_FAILURE });
    }
}
export function* listaClavesMetodosSupervisionActivosSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_CLAVES_METODOS_SUPERVISION_ACTIVOS_REQUEST, listaClavesMetodosSupervisionActivos);
}

function* listaClavesMetodosSupervisionBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let busqueda = '';
        if (action.busqueda) {
            busqueda = action.busqueda;
        }
        console.log('page');
        console.log(page);
        console.log('busqueda');
        console.log(busqueda);

        let response = yield call(getClavesMetodosSupervisionPageCall, token, page, busqueda);
        console.log(response);

        if (response.status == 200) {
            let listaClavesMetodosSupervisionBusqueda = response.data.data;
            const listaClavesMetodosSupervisionBusquedaCantidad = response.data.cantidad;

            // let listaClavesMetodosSupervisionExternos = [];
            // let contador = 0;

            // for(let i = 0; i < listaClavesMetodosSupervisionBusqueda.length; i++){
            //     if(listaClavesMetodosSupervisionBusqueda[i].claveMetodoSupervision === 'Externo' || listaClavesMetodosSupervisionBusqueda[i].claveMetodoSupervision === 'Externa'){
            //         listaClavesMetodosSupervisionExternos[contador] = listaClavesMetodosSupervisionBusqueda[i];
            //         contador =+1;
            //     }
            // }

            yield put({ type: mecanismosDePagosActions.CLAVES_METODOS_SUPERVISION_BUSQUEDA_SUCCESS, listaClavesMetodosSupervisionBusqueda, listaClavesMetodosSupervisionBusquedaCantidad });
        }
        else {
            yield put({ type: mecanismosDePagosActions.CLAVES_METODOS_SUPERVISION_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.CLAVES_METODOS_SUPERVISION_BUSQUEDA_FAILURE });
    }
}
export function* listaClavesMetodosSupervisionBusquedaSaga() {
    yield takeLatest(mecanismosDePagosActions.CLAVES_METODOS_SUPERVISION_BUSQUEDA_REQUEST, listaClavesMetodosSupervisionBusqueda);
}

function* cambiarEstadoClaveMetodoSupervision(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idClaveMetodoSupervision = action.idClaveMetodoSupervision;

        const response = yield call(estadoClaveMetodoSupervisionCall, token, idClaveMetodoSupervision);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const busqueda = '';
            const showSuccessMsg = true;
            const textMessage = "El método de supervisión se cambió de forma correcta.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.ESTADO_CLAVE_METODO_SUPERVISION_SUCCESS });
            yield put({ type: mecanismosDePagosActions.LISTA_CLAVES_METODOS_SUPERVISION_ACTIVOS_REQUEST });
            yield put({ type: mecanismosDePagosActions.CLAVES_METODOS_SUPERVISION_BUSQUEDA_REQUEST, page, busqueda });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: mecanismosDePagosActions.CLAVES_METODOS_SUPERVISION_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.CLAVES_METODOS_SUPERVISION_BUSQUEDA_FAILURE });
    }
}
export function* cambiarEstadoClaveMetodoSupervisionSaga() {
    yield takeLatest(mecanismosDePagosActions.ESTADO_CLAVE_METODO_SUPERVISION_REQUEST, cambiarEstadoClaveMetodoSupervision);
}

function* crearClaveMetodoSupervision(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idClaveMetodoSupervision": null,
            "claveMetodoSupervision": action.formulario.claveMetodoSupervision,
            "descripcionC": action.formulario.descripcionC,
            "descripcionL": action.formulario.descripcionL,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }

        console.log('Data');
        console.log(data);
        const response = yield call(crearClaveMetodoSupervisionCall, token, data);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const busqueda = '';
            const showModalNuevaClaveMetodosSupervision = false;
            const showSuccessMsg = true;
            const textMessage = "El método de supervisión " + action.formulario.descripcionC + " se creó de forma correcta.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearClave' });
            yield put({ type: mecanismosDePagosActions.NUEVA_CLAVE_METODO_SUPERVISION_SUCCESS });
            yield put({ type: mecanismosDePagosActions.CLAVES_METODOS_SUPERVISION_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: mecanismosDePagosActions.LISTA_CLAVES_METODOS_SUPERVISION_ACTIVOS_REQUEST });
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_NUEVO_CLAVE_METODO_SUPERVISION, showModalNuevaClaveMetodosSupervision });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: mecanismosDePagosActions.NUEVA_CLAVE_METODO_SUPERVISION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.NUEVA_CLAVE_METODO_SUPERVISION_FAILURE });
    }
}
export function* crearClaveMetodoSupervisionSaga() {
    yield takeLatest(mecanismosDePagosActions.NUEVA_CLAVE_METODO_SUPERVISION_REQUEST, crearClaveMetodoSupervision);
}

function* editarClaveMetodoSupervision(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idClaveMetodoSupervision = action.ClaveMetodosSupervisionSeleccionado.idClaveMetodoSupervision;
        let limpiarFormulario = 'editarClave';

        const data = {
            "idClaveMetodoSupervision": null,
            "claveMetodoSupervision": action.formulario.claveMetodoSupervision,
            "descripcionC": action.formulario.descripcionC,
            "descripcionL": action.formulario.descripcionL,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }

        console.log('data');
        console.log(data);

        const response = yield call(editarClaveMetodoSupervisionCall, token, data, idClaveMetodoSupervision);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const busqueda = '';
            const showModalEditarClaveMetodosSupervision = false;
            const showSuccessMsg = true;
            let textMessage = "El método de supervisión " + action.formulario.descripcionC + " se modificó de forma correcta.";
            let ClaveMetodosSupervisionSeleccionado = null;
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario });
            yield put({ type: mecanismosDePagosActions.EDITAR_CLAVE_METODO_SUPERVISION_SUCCESS });
            yield put({ type: mecanismosDePagosActions.CLAVES_METODOS_SUPERVISION_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: mecanismosDePagosActions.LISTA_CLAVES_METODOS_SUPERVISION_ACTIVOS_REQUEST });
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_EDITAR_CLAVE_METODO_SUPERVISION, showModalEditarClaveMetodosSupervision, ClaveMetodosSupervisionSeleccionado });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: mecanismosDePagosActions.EDITAR_CLAVE_METODO_SUPERVISION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.EDITAR_CLAVE_METODO_SUPERVISION_FAILURE });
    }
}
export function* editarClaveMetodoSupervisionSaga() {
    yield takeLatest(mecanismosDePagosActions.EDITAR_CLAVE_METODO_SUPERVISION_REQUEST, editarClaveMetodoSupervision);
}

// Estándares de Desempeño
function* listaEstandaresDesempeñoFiltro(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const param = action.param;
        const tipo = action.tipo;
        const idServicio = action.idServicio;

        const response = yield call(listaEstandaresDesempeñoFiltroCall, token, param, tipo, idServicio);
        console.log(response);

        if(response.status == 200){
            let listaEstandaresFiltro = response.data;

            yield put({ type: mecanismosDePagosActions.LISTA_ESTANDARES_FILTRO_SUCCESS, listaEstandaresFiltro });
        }
        else{
              yield put({ type: mecanismosDePagosActions.LISTA_ESTANDARES_FILTRO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.LISTA_ESTANDARES_FILTRO_FAILURE });
    }
}
export function* listaEstandaresDesempeñoFiltroSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_ESTANDARES_FILTRO_REQUEST, listaEstandaresDesempeñoFiltro);
}

function* listaEstandaresDesempeñoFiltroActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const param = action.param;

        const response = yield call(listaEstandaresDesempeñoFiltroActivosCall, token, param );
        console.log(response);

        if(response.status == 200){
            let listaEstandaresFiltroActivos = response.data;

            yield put({ type: mecanismosDePagosActions.LISTA_ESTANDARES_ACTIVOS_SUCCESS, listaEstandaresFiltroActivos });
        }
        else{
              yield put({ type: mecanismosDePagosActions.LISTA_ESTANDARES_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.LISTA_ESTANDARES_ACTIVOS_FAILURE });
    }
}
export function* listaEstandaresDesempeñoFiltroActivosSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_ESTANDARES_ACTIVOS_REQUEST, listaEstandaresDesempeñoFiltroActivos);
}

// Busqueda

function* listaEstandaresDesempenoBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = (action.page - 1) * 10;
        let estandar = '';
        let referenciaEstandar = '';
        let idsServicios = '';
        let seccion = '';
        let order = 'order=ASC&';
        let field = 'field=referencia&';

        if (action.busqueda.referenciaEstandar){
            referenciaEstandar = 'referenciaEstandar=' + action.busqueda.referenciaEstandar + '&';
        }

        if (action.busqueda.estandar){
            estandar = 'estandar=' + action.busqueda.estandar + '&';
        }

        if (action.busqueda.idsServicios){
            idsServicios = 'idsServicios=' + action.busqueda.idsServicios + '&';
        }

        if (action.busqueda.seccion){
            seccion = 'seccion=' + action.busqueda.seccion + '&';
        }

        if (action.busqueda.order){
            order = 'order=' + action.busqueda.order + '&';
        }

        if (action.busqueda.field){
            field = 'field=' + action.busqueda.field + '&';
        }

        const response = yield call(listaEstandaresDesempenoBusquedaCall, token, page, referenciaEstandar, estandar, idsServicios, seccion, order, field);
        console.log(response);

        if(response.status == 200){
            let listaEstandaresDeDesempeñoBusquedaCantidad = response.data.cantidad;
            let listaEstandaresDeDesempeñoBusqueda = response.data.data;
            let mostrarEspecifica = false;
            let banderaTiempo = false;

            for(let i=0; i<listaEstandaresDeDesempeñoBusqueda.length; i++){
                let fechaAlta = listaEstandaresDeDesempeñoBusqueda[i].fechaAlta.split('T')[0];
                listaEstandaresDeDesempeñoBusqueda[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];

                listaEstandaresDeDesempeñoBusqueda[i].rangoVigencia = [moment(listaEstandaresDeDesempeñoBusqueda[i].fechaInicioVigencia, 'YYYY-MM-DD'),moment(listaEstandaresDeDesempeñoBusqueda[i].fechaFinVigencia, 'YYYY-MM-DD')]

                listaEstandaresDeDesempeñoBusqueda[i].tolerancia = parseFloat(listaEstandaresDeDesempeñoBusqueda[i].tolerancia).toFixed(0);

                listaEstandaresDeDesempeñoBusqueda[i].toleranciaTabla = parseFloat(listaEstandaresDeDesempeñoBusqueda[i].tolerancia).toFixed(0) + '%';


                // claveMetodoSupervisionByIdClaveMetodoSupervision
                if(listaEstandaresDeDesempeñoBusqueda[i].claveMetodoSupervisionByIdClaveMetodoSupervision) {

                    listaEstandaresDeDesempeñoBusqueda[i].claveMetodoSupervision = listaEstandaresDeDesempeñoBusqueda[i].claveMetodoSupervisionByIdClaveMetodoSupervision.claveMetodoSupervision;

                    listaEstandaresDeDesempeñoBusqueda[i].descripcionC = listaEstandaresDeDesempeñoBusqueda[i].claveMetodoSupervisionByIdClaveMetodoSupervision.descripcionC;

                    listaEstandaresDeDesempeñoBusqueda[i].claveMetodoCompleta = listaEstandaresDeDesempeñoBusqueda[i].claveMetodoSupervision + ', ' + listaEstandaresDeDesempeñoBusqueda[i].descripcionC;

                    if(listaEstandaresDeDesempeñoBusqueda[i].claveMetodoSupervision === 'Externo' || listaEstandaresDeDesempeñoBusqueda[i].claveMetodoSupervision === 'Externa'){
                        listaEstandaresDeDesempeñoBusqueda[i].metodosExternos = listaEstandaresDeDesempeñoBusqueda[i].claveMetodoCompleta;
                    }
                }

                // referenciaDesempenoByIdReferenciaDesempeno
                if(listaEstandaresDeDesempeñoBusqueda[i].referenciaDesempenoByIdReferenciaDesempeno) {

                    listaEstandaresDeDesempeñoBusqueda[i].subnivel = listaEstandaresDeDesempeñoBusqueda[i].referenciaDesempenoByIdReferenciaDesempeno.subnivel;

                    if(listaEstandaresDeDesempeñoBusqueda[i].subnivel === ''){
                        listaEstandaresDeDesempeñoBusqueda[i].referenciaSub = listaEstandaresDeDesempeñoBusqueda[i].referenciaDesempenoByIdReferenciaDesempeno.referencia;
                    }else{
                        listaEstandaresDeDesempeñoBusqueda[i].referenciaSub = listaEstandaresDeDesempeñoBusqueda[i].referenciaDesempenoByIdReferenciaDesempeno.referencia + '.' + listaEstandaresDeDesempeñoBusqueda[i].subnivel;
                    }

                    // listaEstandaresDeDesempeñoBusqueda[i].referencia = listaEstandaresDeDesempeñoBusqueda[i].referenciaSub + '.' + listaEstandaresDeDesempeñoBusqueda[i].referenciaEspecifica;

                    if(listaEstandaresDeDesempeñoBusqueda[i].referenciaDesempenoByIdReferenciaDesempeno.claseReferencia === 'General' ){
                        listaEstandaresDeDesempeñoBusqueda[i].referenciaEspecifica = listaEstandaresDeDesempeñoBusqueda[i].referencia.split('.')[1];
                    }else{
                        listaEstandaresDeDesempeñoBusqueda[i].referenciaEspecifica = listaEstandaresDeDesempeñoBusqueda[i].referencia.split('.')[2];
                    }

                    listaEstandaresDeDesempeñoBusqueda[i].referenciaReferencia = listaEstandaresDeDesempeñoBusqueda[i].referenciaDesempenoByIdReferenciaDesempeno.referencia;

                    listaEstandaresDeDesempeñoBusqueda[i].claseReferenciaReferencia = listaEstandaresDeDesempeñoBusqueda[i].referenciaDesempenoByIdReferenciaDesempeno.claseReferencia;

                    if(listaEstandaresDeDesempeñoBusqueda[i].claseReferenciaReferencia === 'General'){
                        listaEstandaresDeDesempeñoBusqueda[i].mostrarEspecifica = false;
                    } else {
                        listaEstandaresDeDesempeñoBusqueda[i].mostrarEspecifica = true;
                    }

                    if(listaEstandaresDeDesempeñoBusqueda[i].tiempoRespuesta === 0){
                        listaEstandaresDeDesempeñoBusqueda[i].banderaTiempo = false;
                    } else {
                        listaEstandaresDeDesempeñoBusqueda[i].banderaTiempo = true;
                    }

                    listaEstandaresDeDesempeñoBusqueda[i].descripcionReferencia = listaEstandaresDeDesempeñoBusqueda[i].referenciaDesempenoByIdReferenciaDesempeno.descripcion;

                    listaEstandaresDeDesempeñoBusqueda[i].referenciaCompleta = listaEstandaresDeDesempeñoBusqueda[i].referenciaSub + ', ' + listaEstandaresDeDesempeñoBusqueda[i].claseReferenciaReferencia + ', ' + listaEstandaresDeDesempeñoBusqueda[i].descripcionReferencia;
                }

                // servSeccionesEspecificasById
                if(listaEstandaresDeDesempeñoBusqueda[i].servSeccionesEspecificasById) {

                    listaEstandaresDeDesempeñoBusqueda[i].descripcionSecciones = listaEstandaresDeDesempeñoBusqueda[i].servSeccionesEspecificasById.descripcion;
                    //console.log('AQUI',listaEstandaresDeDesempeñoBusqueda[i].servSeccionesEspecificasById.serviciosByIdServSeccionEspecifico[0]);
                    if(listaEstandaresDeDesempeñoBusqueda[i].servSeccionesEspecificasById.serviciosByIdServSeccionEspecifico[0]) {
                        listaEstandaresDeDesempeñoBusqueda[i].nombreServicio = '';
                        for(let x=0; x < listaEstandaresDeDesempeñoBusqueda[i].servSeccionesEspecificasById.serviciosByIdServSeccionEspecifico.length; x++ ){
                            listaEstandaresDeDesempeñoBusqueda[i].nombreServicio += '-' + listaEstandaresDeDesempeñoBusqueda[i].servSeccionesEspecificasById.serviciosByIdServSeccionEspecifico[x].nombre + ' ';
                        }
                    }else{
                        listaEstandaresDeDesempeñoBusqueda[i].nombreServicio = 'No tiene servicio'
                    }
                } else {
                    listaEstandaresDeDesempeñoBusqueda[i].descripcionSecciones = 'No tiene descripción de sección';
                    listaEstandaresDeDesempeñoBusqueda[i].nombreServicio = 'No tiene servicio';
                }

                // unidadMedidaByIdUnidadMedida
                if(listaEstandaresDeDesempeñoBusqueda[i].unidadMedidaByIdUnidadMedida) {
                    listaEstandaresDeDesempeñoBusqueda[i].unidadMedida = listaEstandaresDeDesempeñoBusqueda[i].unidadMedidaByIdUnidadMedida.unidadMedida;
                }
                if(listaEstandaresDeDesempeñoBusqueda[i].tiempoRespuesta === 0){
                    listaEstandaresDeDesempeñoBusqueda[i].tiempoRespuestaCompleto = "n/a"
                }
                else{
                    listaEstandaresDeDesempeñoBusqueda[i].tiempoRespuestaCompleto = listaEstandaresDeDesempeñoBusqueda[i].tiempoRespuesta + ' ' + listaEstandaresDeDesempeñoBusqueda[i].unidadMedidaByIdUnidadMedida.unidadMedida;
                }

                // fallaCalidadByIdFallaCalidad
                if(listaEstandaresDeDesempeñoBusqueda[i].fallaCalidadByIdFallaCalidad) {
                    listaEstandaresDeDesempeñoBusqueda[i].fallaCalidad = listaEstandaresDeDesempeñoBusqueda[i].fallaCalidadByIdFallaCalidad.fallaCalidad;

                    listaEstandaresDeDesempeñoBusqueda[i].descripcionFallaCalidad = listaEstandaresDeDesempeñoBusqueda[i].fallaCalidadByIdFallaCalidad.descripcion;

                    listaEstandaresDeDesempeñoBusqueda[i].fallaCalidadCompleta = listaEstandaresDeDesempeñoBusqueda[i].fallaCalidad + ', ' + listaEstandaresDeDesempeñoBusqueda[i].descripcionFallaCalidad;
                }

                //
            }

            yield put({ type: mecanismosDePagosActions.ESTANDARES_DESEMPEÑO_BUSQUEDA_SUCCESS, listaEstandaresDeDesempeñoBusqueda, listaEstandaresDeDesempeñoBusquedaCantidad });
        }
        else{
              yield put({ type: mecanismosDePagosActions.ESTANDARES_DESEMPEÑO_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.ESTANDARES_DESEMPEÑO_BUSQUEDA_FAILURE });
    }
}
export function* listaEstandaresDesempenoBusquedaSaga() {
    yield takeLatest(mecanismosDePagosActions.ESTANDARES_DESEMPEÑO_BUSQUEDA_REQUEST, listaEstandaresDesempenoBusqueda);
}

// Cambiar Estado

function* cambiarEstadoEstandarDeDesempeño(action) {
    try {
         console.log(action);
         const token = sessionStorage.getItem('token');
         const idEstandarDesempeno  = action.idEstandarDesempeno;

         const response = yield call(cambiarEstadoEstandarDeDesempeñoCall, token, idEstandarDesempeno);
         console.log(response);

        if(response.status === 200){
              const page = action.page;
              const busqueda = action.busqueda;
              const showSuccessMsg = true;
              const textMessage = "El estado del estándar de desempeño cambió de forma correcta";

              yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
              yield put({ type: mecanismosDePagosActions.ESTADO_ESTANDAR_DESEMPEÑO_SUCCESS });
              yield put({ type: mecanismosDePagosActions.ESTANDARES_DESEMPEÑO_BUSQUEDA_REQUEST, page, busqueda });
        }
         else{
              const showErrorMsg = true;
              const textMessage = response.message;

              yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

              yield put({ type: mecanismosDePagosActions.ESTADO_ESTANDAR_DESEMPEÑO_FAILURE });
         }

    } catch (error) {
         console.log(error);
         yield put({ type: mecanismosDePagosActions.ESTADO_ESTANDAR_DESEMPEÑO_FAILURE });
    }
}
export function* cambiarEstadoEstandarDeDesempeñoSaga() {
    yield takeLatest(mecanismosDePagosActions.ESTADO_ESTANDAR_DESEMPEÑO_REQUEST, cambiarEstadoEstandarDeDesempeño);
}

// Nuevo

function* crearEstadoEstandarDeDesempeño(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idServSeccionEspecifico = null;
        let idUnidadMedida = null;
        if(action.formulario.idServSeccionEspecifico){
            idServSeccionEspecifico = parseInt(action.formulario.idServSeccionEspecifico);
        }

        if(action.formulario.idUnidadMedida){
            idUnidadMedida = parseInt(action.formulario.idUnidadMedida);
        }

        let referenciaEspecificaPrimaria = '';
        if(listaReferenciasDeEstandaresActivos.subnivel ==! ''){
            referenciaEspecificaPrimaria = listaReferenciasDeEstandaresActivos.referencia + '.' + listaReferenciasDeEstandaresActivos.subnivel + '.';
        } else {
            referenciaEspecificaPrimaria = listaReferenciasDeEstandaresActivos.referencia + '.';
        }

        let referenciaEspecificaPrimera = '';
        if(action.formulario.referenciaEspecificaPrimera){
            referenciaEspecificaPrimera = action.formulario.referenciaEspecificaPrimera;
        }

        const data = {
            "idEstandarDesempeno": null,
            "idReferenciaDesempeno": parseInt(action.formulario.idReferenciaDesempeno),
            "idServSeccionEspecifico": idServSeccionEspecifico,
            "idFallaCalidad": parseInt(action.formulario.idFallaCalidad),
            "idUnidadMedida": idUnidadMedida,
            "idClaveMetodoSupervision": parseInt(action.formulario.idClaveMetodoSupervision),
            //"referencia": referenciaEspecificaPrimera,
            "referencia": action.formulario.referenciaEspecifica,
            "descripcion": (action.formulario.descripcion).trim(),
            "tolerancia": parseFloat(action.formulario.tolerancia),
            "tiempoRespuesta": parseFloat(action.formulario.tiempoRespuesta),
            "fechaAlta": null,
            "fechaActualizacion": null,
            "fechaInicioVigencia": action.formulario.rangoVigencia[0].format('YYYY-MM-DD'),
            "fechaFinVigencia": null,
            "activo": true,
        }

        console.log(data);
        const response = yield call(crearEstadoEstandarDeDesempeñoCall, token, data)
        console.log(response)

        if(response.status === 200){
            const page = action.page;
            console.log('page')
            console.log(page)
            const busqueda = action.busqueda;
            const showModalNuevoEstandaresDeDesempeño = false;
            const showSuccessMsg = true;
            const textMessage = "El estándar de desempeño se creó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.NUEVO_ESTANDAR_DESEMPEÑO_SUCCESS })
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearEstandarDesempeño'});
            yield put({ type: mecanismosDePagosActions.ESTANDARES_DESEMPEÑO_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_NUEVO_ESTANDAR_DESEMPEÑO, showModalNuevoEstandaresDeDesempeño });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.NUEVO_ESTANDAR_DESEMPEÑO_FAILURE})
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.NUEVO_ESTANDAR_DESEMPEÑO_FAILURE})
    }
}
export function* crearEstadoEstandarDeDesempeñoSaga(){
    yield takeLatest(mecanismosDePagosActions.NUEVO_ESTANDAR_DESEMPEÑO_REQUEST, crearEstadoEstandarDeDesempeño)
}

// Editar
function* editarEstandarDeDesempeño(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idEstandarDesempeno  = action.EstandaresDeDesempeñoSeleccionado.idEstandarDesempeno;
        let limpiarFormulario = 'editarEstandarDeDesempeño';
        let idReferenciaDesempeno = action.EstandaresDeDesempeñoSeleccionado.idReferenciaDesempeno;
        let idServSeccionEspecifico = null;
        let idFallaCalidad = action.EstandaresDeDesempeñoSeleccionado.idFallaCalidad;
        let idUnidadMedida = action.EstandaresDeDesempeñoSeleccionado.idUnidadMedida;
        let idClaveMetodoSupervision = action.EstandaresDeDesempeñoSeleccionado.idClaveMetodoSupervision;

        for(let i = 0; i<action.listaEstandaresDesempenoEspecificosActivos.length; i++){
            if(action.formulario.descripcionSecciones === action.listaEstandaresDesempenoEspecificosActivos[i].descripcion){
                idServSeccionEspecifico = action.listaEstandaresDesempenoEspecificosActivos[i].idServSeccionEspecifico;
            }
        }
        for(let i=0; i<action.listaReferenciasDeEstandaresActivos.length; i++){
            if(action.listaReferenciasDeEstandaresActivos[i].referencia + '.' + action.listaReferenciasDeEstandaresActivos[i].subnivel + ', ' + action.listaReferenciasDeEstandaresActivos[i].claseReferencia + ', ' + action.listaReferenciasDeEstandaresActivos[i].descripcion === action.formulario.referenciaCompleta){
                idReferenciaDesempeno = action.listaReferenciasDeEstandaresActivos[i].idReferenciaDesempeno;
            }

            if(action.listaReferenciasDeEstandaresActivos[i].referencia + '.' + action.listaReferenciasDeEstandaresActivos[i].subnivel === action.formulario.referenciaSub){
                idReferenciaDesempeno = action.listaReferenciasDeEstandaresActivos[i].idReferenciaDesempeno;
            }
        }

        for(let i=0; i<action.listaClavesMetodosSupervisionActivos.length; i++){
            if(action.listaClavesMetodosSupervisionActivos[i].claveMetodoSupervision + ', ' + action.listaClavesMetodosSupervisionActivos[i].descripcionC  === action.formulario.metodosExternos){
                idClaveMetodoSupervision = action.listaClavesMetodosSupervisionActivos[i].idClaveMetodoSupervision;
            }
        }

        for(let i=0; i<action.listaCategoriasFallasDeCalidadActivos.length; i++){
            if(action.listaCategoriasFallasDeCalidadActivos[i].fallaCalidad + ', ' + action.listaCategoriasFallasDeCalidadActivos[i].descripcion  === action.formulario.fallaCalidadCompleta){
                idFallaCalidad = action.listaCategoriasFallasDeCalidadActivos[i].idFallaCalidad;
            }
        }

        for(let i=0; i<action.listaEstandaresDesempenoEspecificosActivos.length; i++){
            if(action.listaEstandaresDesempenoEspecificosActivos[i].descripcion  === action.formulario.idServSeccionEspecifico){
                idServSeccionEspecifico = action.listaEstandaresDesempenoEspecificosActivos[i].idServSeccionEspecifico;
            }
        }

        for(let i=0; i<action.unidadesMediPorCategoria.length; i++){
            if(action.unidadesMediPorCategoria[i].unidadMedida  === action.formulario.unidadMedida){
                idUnidadMedida = action.unidadesMediPorCategoria[i].idUnidadMedida;
            }
        }

        const data = {
            "idEstandarDesempeno": idEstandarDesempeno,
            "idReferenciaDesempeno": idReferenciaDesempeno,
            "idServSeccionEspecifico": idServSeccionEspecifico,
            "descripcion": (action.formulario.descripcion).trim(),
            "idFallaCalidad": idFallaCalidad,
            "tolerancia": parseFloat(action.formulario.tolerancia),
            "tiempoRespuesta": action.formulario.tiempoRespuesta,
            "idUnidadMedida": idUnidadMedida,
            "referencia": action.formulario.referenciaEspecifica,
            "idClaveMetodoSupervision": idClaveMetodoSupervision,
            "fechaAlta": action.EstandaresDeDesempeñoSeleccionado.fechaAlta.split('-').reverse().join('-'),
            "fechaActualizacion": action.EstandaresDeDesempeñoSeleccionado.fechaActualizacion,
            "fechaInicioVigencia": action.formulario.rangoVigencia.split(',')[0],
            "fechaFinVigencia": action.formulario.rangoVigencia.split(',')[1],
            "rutaArchivo": action.EstandaresDeDesempeñoSeleccionado.rutaArchivo,
            "activo": action.EstandaresDeDesempeñoSeleccionado.activo
        }
        console.log(data);

        const formData = new FormData();
        if(action.formularioPDF){
            limpiarFormulario = 'editarFallaPDF';
            formData.append('file', action.formularioPDF.pdf.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));

        console.log('formData: ',formData);

        const response = yield call(editarEstandarDeDesempeñoCall, token, formData, idEstandarDesempeno);
        console.log(response);

        if(response.status === 200){
            const page = action.page;
            const busqueda = action.busqueda;
            const showModalEditarEstandaresDeDesempeño= false;
            const EstandaresDeDesempeñoSeleccionado = null;
            const showSuccessMsg = true;
            const textMessage = "El estándar de desempeño se modificó de forma correcta";
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario });
            yield put({ type: mecanismosDePagosActions.CAMBIAR_VISTA_ESTANDAR_DESEMPEÑO, vistaModalEstandaresDeDesempeño: 1, formularioEstandaresDeDesempeño: null });
            yield put({ type: mecanismosDePagosActions.ESTANDARES_DESEMPEÑO_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: mecanismosDePagosActions.EDITAR_ESTANDAR_DESEMPEÑO_SUCCESS });
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_EDITAR_ESTANDAR_DESEMPEÑO, showModalEditarEstandaresDeDesempeño, EstandaresDeDesempeñoSeleccionado });
            yield put({ type: mecanismosDePagosActions.LISTA_ESTANDARES_DESEMPEÑO_REQUEST });
            yield put({ type: mecanismosDePagosActions.LISTA_CLAVES_METODOS_SUPERVISION_ACTIVOS_REQUEST });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: mecanismosDePagosActions.EDITAR_ESTANDAR_DESEMPEÑO_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.EDITAR_ESTANDAR_DESEMPEÑO_FAILURE });
    }
}
export function* editarEstandarDeDesempeñoSaga() {
    yield takeLatest(mecanismosDePagosActions.EDITAR_ESTANDAR_DESEMPEÑO_REQUEST, editarEstandarDeDesempeño);
}

// SAGA VALORES PAGO ANUAL
function* busquedaValoresPagoAnual(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let año;
        const page = action.page - 1;

        const response = yield call(getValoresPagoAnualCall, token, page);
        console.log('response busquedaValoresPagoAnual:>> ', response);

        if (response.status === 200) {
            let listaValoresPagoAnual = response.data.data;
            let listaValoresPagoAnualCantidad = response.data.cantidad;

            for(const i in listaValoresPagoAnual){

                if (listaValoresPagoAnual[i].proyectoByIdProyecto) {

                    listaValoresPagoAnual[i].pagoAnualInversion = listaValoresPagoAnual[i].proyectoByIdProyecto.pagoAnualInversion;
                    listaValoresPagoAnual[i].pagoAnualReferencia = listaValoresPagoAnual[i].proyectoByIdProyecto.pagoAnualReferencia;
                    listaValoresPagoAnual[i].poblacionPtvMax = listaValoresPagoAnual[i].proyectoByIdProyecto.poblacionPtvMax;
                    listaValoresPagoAnual[i].fechaInicioOperacion = listaValoresPagoAnual[i].proyectoByIdProyecto.fechaInicioOperacion.split('T')[0].split('-').reverse().join('-');
                    listaValoresPagoAnual[i].fechaInicio = listaValoresPagoAnual[i].proyectoByIdProyecto.fechaInicio.split('T')[0].split('-').reverse().join('-');
                    listaValoresPagoAnual[i].fechaPublicacion = moment(listaValoresPagoAnual[i].fechaPublicacion, 'DD-MM-YYYY');

                }
            }

            yield put({ type: mecanismosDePagosActions.LISTA_VALORES_PAGO_ANUAL_SUCCESS, listaValoresPagoAnual, listaValoresPagoAnualCantidad });

        } else {
            yield put({ type: mecanismosDePagosActions.LISTA_VALORES_PAGO_ANUAL_FAILURE })
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.LISTA_VALORES_PAGO_ANUAL_FAILURE })
    }
}

export function* busquedaValoresPagoAnualSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_VALORES_PAGO_ANUAL_REQUEST , busquedaValoresPagoAnual);
}

// SAGA VALORES PAGO ANUAL
function* cambiarEstadoINPC0(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idAnoContractual = action.idAnoContractual;

        const response = yield call(cambiarEstadoINCP0, token, idAnoContractual);
        console.log('response cambiarEstadoValorPagoAnual:>> ', response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "El estado del Valor de Pago Anual cambió de forma correcta.";
            const page = action.page;

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.ESTADO_INPC0_SUCCESS })
            yield put({ type: mecanismosDePagosActions.LISTA_VALORES_PAGO_ANUAL_REQUEST, page })
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.ESTADO_INPC0_FAILURE })
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.ESTADO_INPC0_FAILURE })
    }
}

export function* cambiarEstadoINPC0Saga() {
    yield takeLatest(mecanismosDePagosActions.ESTADO_INPC0_REQUEST , cambiarEstadoINPC0);
};

// crear iNPC0
function* crearINPC0(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const fechaPublicacion = action.formulario.fechaPublicacion.format('YYYY-MM-DD');
        const data = {
            "idAnoContractual": null,
            "idProyecto": null,
            "inpc0": parseFloat(action.formulario.inpc0),
            "fechaPublicacion": fechaPublicacion,
            "fechaCaptura": null,
            "estatus": null,
	        "fechaCierre": null,
            "fuente": action.formulario.fuente,
            "fechaAlta":null,
            "fechaActualizacion":null
        }
        console.log('data :>> ', data);

        const response = yield call(crearINCP0Call, token, data);
        console.log('response crearINPC0:>> ', response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "El iNPC0 se creó de forma correcta.";
            const showModalNuevoINPC0 = false
            const page = action.page;

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.CREAR_INPC0_SUCCESS })
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearINPC0'});
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_CREAR_INPC0, showModalNuevoINPC0 })
            yield put({ type: mecanismosDePagosActions.LISTA_VALORES_PAGO_ANUAL_REQUEST, page })
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.CREAR_INPC0_FAILURE })
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.CREAR_INPC0_FAILURE })
    }
}

export function* crearINPC0Saga() {
    yield takeLatest(mecanismosDePagosActions.CREAR_INPC0_REQUEST , crearINPC0);
};


// editar iNPC0
function* editarINPC0(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idAnoContractual = action.inpc0Seleccionado.idAnoContractual;
        let fechaPublicacion;
        let fuente;

        if (action.formulario.fechaPublicacion) {
            fechaPublicacion = action.formulario.fechaPublicacion.format('YYYY-MM-DD');
        } else {
            fechaPublicacion = action.inpc0Seleccionado.fechaPublicacion
        }

        if (action.formulario.fuente) {
            fuente = action.formulario.fuente
        } else {
            fuente = action.inpc0Seleccionado.fuente
        }
        const data = {
            "idAnoContractual": null,
            "idProyecto": null,
            "inpc0": parseFloat(action.formulario.inpc0),
            "fechaPublicacion": fechaPublicacion,
            "fechaCaptura": null,
            "estatus": null,
	        "fechaCierre": null,
            "fuente": fuente,
            "fechaAlta":null,
            "fechaActualizacion":null
        }
        console.log('data :>> ', data);

        const response = yield call(editarINPC0Call, token, idAnoContractual, data);
        console.log('response editarINPC0:>> ', response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "El iNPC0 se editó de forma correcta.";
            const showModalEditarINPC0 = false
            const page = action.page;

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EDITAR_INPC0_SUCCESS })
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarINPC0'});
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_EDITAR_INPC0, showModalEditarINPC0 })
            yield put({ type: mecanismosDePagosActions.LISTA_VALORES_PAGO_ANUAL_REQUEST, page })
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EDITAR_INPC0_FAILURE })
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.EDITAR_INPC0_FAILURE })
    }
}

export function* editarINPC0Saga() {
    yield takeLatest(mecanismosDePagosActions.EDITAR_INPC0_REQUEST , editarINPC0);
};

// Busqueda PAGO ANUAL REF VARIABLE
function* busquedaPARv(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let año;
        const page = action.page - 1;
        action.busqueda.param ? año = 'ano=' + action.busqueda.param : año = 'ano=' + moment().format('YYYY-MM-DD').split('-')[0]

        const response = yield call(getPARvCall, token, año, page);
        console.log('response busquedaPARv:>> ', response);

        if (response.status === 200) {
            let listaPARv = response.data.data;
            let listaPARvCantidad = response.data.cantidad;

            for(const i in listaPARv){

                let numero = listaPARv[i].parV.toString();
                listaPARv[i].parV = '$ ' + numero.replace(/\B(?=(\d{3})+(?!\d))/g, ',')


                listaPARv[i].fechaFinVigencia = listaPARv[i].fechaFinVigencia.split('T')[0].split('-').reverse().join('-');
                listaPARv[i].fechaInicioVigencia = listaPARv[i].fechaInicioVigencia.split('T')[0].split('-').reverse().join('-');
            }

            yield put({ type: mecanismosDePagosActions.LISTA_PARv_SUCCESS, listaPARv, listaPARvCantidad });
        } else {
            yield put({ type: mecanismosDePagosActions.LISTA_PARv_FAILURE })
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.LISTA_PARv_FAILURE })
    }
}

export function* busquedaPARvSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_PARv_REQUEST , busquedaPARv);
}

// Crear PARv
function* crearPARv(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let fechaInicioVigencia = action.formulario.fechaInicioVigencia.format('YYYY-MM-DD');


        const data = {
             "idParV": null,
             "parV": action.formulario.parV,
             "idProyecto": null,
             "numeroOficio":  action.formulario.numeroOficio,
             "fechaInicioVigencia": fechaInicioVigencia,
             "fechaFinVigencia": null,
             "fechaAlta":null,
             "fechaActualizacion":null,
             "rutaArchivo": null,
             "estatus": null
        };

        console.log('data', data)
        const formData = new FormData();
        if (action.formulario.pdf) {
            formData.append('file', action.formulario.pdf.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));

        console.log('formData', formData);


        const response = yield call(crearPARvCall, token, formData );
        console.log('response crearPARv', response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "El PARv se creó de forma correcta.";
            const showModalCrearPARv = false
            const page = action.page;
            const busqueda = action.busqueda;

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.CREAR_PARv_SUCCESS })
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearPARv'});
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_CREAR_PARv, showModalCrearPARv });
            yield put({ type: mecanismosDePagosActions.LISTA_PARv_REQUEST, page, busqueda });
            yield put({ type: mecanismosDePagosActions.LISTA_VALORES_PAGO_ANUAL_REQUEST, page });
            yield put({ type: informacionDelProyectoActions.GET_INFOPROYECTO_REQUEST });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.CREAR_PARv_FAILURE })
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.CREAR_PARv_FAILURE })
    }
}

export function* crearPARvSaga() {
    yield takeLatest(mecanismosDePagosActions.CREAR_PARv_REQUEST , crearPARv);
}

// verificar PARv
function* verificarPARv(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(verificarPARvCall, token );
        console.log('response verificarPARv', response);

        if (response.status === 200) {
            let listaVerificarPARv = response.data;

            yield put({ type: mecanismosDePagosActions.VERIFICAR_PARv_SUCCESS, listaVerificarPARv})
        } else {

            yield put({ type: mecanismosDePagosActions.VERIFICAR_PARv_FAILURE })
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.VERIFICAR_PARv_FAILURE })
    }
}

export function* verificarPARvSaga() {
    yield takeLatest(mecanismosDePagosActions.VERIFICAR_PARv_REQUEST , verificarPARv);
}

// SAGA PERIODO FRACCION MAXIMA DE DESCUENTO
function* listaPeriodoTodos(action){

    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaPeriodosTodosCall, token);
        console.log('response listaPeriodoTodos', response);

        if (response.status === 200) {
            let listaPeriodosTodos = response.data;

            yield put({ type: mecanismosDePagosActions.LISTA_PERIODOS_TODOS_SUCCESS, listaPeriodosTodos})
        } else {

            yield put({ type: mecanismosDePagosActions.LISTA_PERIODOS_TODOS_FAILURE })
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.LISTA_PERIODOS_TODOS_FAILURE })
    }
}

export function* listaPeriodoTodosSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_PERIODOS_TODOS_REQUEST , listaPeriodoTodos);
}
// inicializar formulario
function* listaPeriodoTodosFormulario(action){

    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaPeriodosTodosCall, token);
        console.log('response listaPeriodoTodosFormulario', response);

        if (response.status === 200) {
            let listaPeriodosFormularioEditar = response.data;
            let listaPeriodoParaFormulario = {};

            for(let i in listaPeriodosFormularioEditar){

                if(action.periodoSeleccionado.idPeriodoFraccionMaxima === listaPeriodosFormularioEditar[i].idPeriodoFraccionMaxima) {

                    listaPeriodoParaFormulario['cantidadAniosNuevo-' + listaPeriodosFormularioEditar[i].idPeriodoFraccionMaxima] = action.formulario.cantidadAnios
                    listaPeriodoParaFormulario['fraccionMaxDescuentoNuevo-' + listaPeriodosFormularioEditar[i].idPeriodoFraccionMaxima] = action.formulario.fraccionMaxDescuento
                    listaPeriodoParaFormulario['periodoAniosNuevo-' + listaPeriodosFormularioEditar[i].idPeriodoFraccionMaxima] = action.formulario.periodoAnios
                    listaPeriodoParaFormulario['periodoMesNuevo-' + listaPeriodosFormularioEditar[i].idPeriodoFraccionMaxima] = action.formulario.periodoMes

                } else {
                    listaPeriodoParaFormulario['cantidadAniosNuevo-' + listaPeriodosFormularioEditar[i].idPeriodoFraccionMaxima] = listaPeriodosFormularioEditar[i].cantidadAnios
                    listaPeriodoParaFormulario['fraccionMaxDescuentoNuevo-' + listaPeriodosFormularioEditar[i].idPeriodoFraccionMaxima] = listaPeriodosFormularioEditar[i].fraccionMaxDescuento
                    listaPeriodoParaFormulario['periodoAniosNuevo-' + listaPeriodosFormularioEditar[i].idPeriodoFraccionMaxima] = listaPeriodosFormularioEditar[i].periodoAnios
                    listaPeriodoParaFormulario['periodoMesNuevo-' + listaPeriodosFormularioEditar[i].idPeriodoFraccionMaxima] = listaPeriodosFormularioEditar[i].periodoMes

                }
            }
            console.log('listaPeriodoParaFormulario >>', listaPeriodoParaFormulario)

            yield put({ type: mecanismosDePagosActions.LISTA_PERIODOS_FORMULARIO_EDITAR_SUCCESS, listaPeriodosFormularioEditar, listaPeriodoParaFormulario})

        } else {

            yield put({ type: mecanismosDePagosActions.LISTA_PERIODOS_FORMULARIO_EDITAR_FAILURE })
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.LISTA_PERIODOS_FORMULARIO_EDITAR_FAILURE })
    }
}

export function* listaPeriodoTodosFormularioSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_PERIODOS_FORMULARIO_EDITAR_REQUEST , listaPeriodoTodosFormulario);
}


function* listaPeriodoFMD(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;

        const response = yield call(listaPeriodoFraccionMaximaDescCall, token, page );
        console.log('response listaPeriodoFMD', response);

        if (response.status === 200) {
            let listaPeriodoFMD = response.data.data;
            let listaPeriodoFMDCantidad = response.data.cantidad;

            for (let i in listaPeriodoFMD) {

                //listaPeriodoFMD[i].fraccionMaxDescuento = listaPeriodoFMD[i].fraccionMaxDescuento + " %";

                if (listaPeriodoFMD[i].fechaInicialPeriodo) {
                    listaPeriodoFMD[i].fechaInicialPeriodoGrid =  listaPeriodoFMD[i].fechaInicialPeriodo.split('T')[0].split('-').reverse().join('-');

                }
                if (listaPeriodoFMD[i].fechaFinalPeriodo) {
                    listaPeriodoFMD[i].fechaFinalPeriodoGrid =  listaPeriodoFMD[i].fechaFinalPeriodo.split('T')[0].split('-').reverse().join('-');

                }
                if (listaPeriodoFMD[i].fechaAlta) {
                    listaPeriodoFMD[i].fechaAlta =  listaPeriodoFMD[i].fechaAlta.split('T')[0].split('-').reverse().join('-');
                }

                if (listaPeriodoFMD[i].fechaActualizacion) {
                    listaPeriodoFMD[i].fechaActualizacion =  listaPeriodoFMD[i].fechaActualizacion.split('T')[0].split('-').reverse().join('-');
                }

            }

            yield put({ type: mecanismosDePagosActions.LISTA_CATALOGO_PERIODO_FMD_SUCCESS, listaPeriodoFMD, listaPeriodoFMDCantidad})
        } else {

            yield put({ type: mecanismosDePagosActions.LISTA_CATALOGO_PERIODO_FMD_FAILURE })
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.LISTA_CATALOGO_PERIODO_FMD_FAILURE })
    }
}

export function* listaPeriodoFMDSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_CATALOGO_PERIODO_FMD_REQUEST , listaPeriodoFMD);
}

// crear periodo fraccion max desc
function* crearPeriodoFMD(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let cantidadAnios;
        let fraccionMaxDescuento;

        if (action.formulario.cantidadAnios) {
            cantidadAnios = parseInt(action.formulario.cantidadAnios);
        }

        if (action.formulario.fraccionMaxDescuento) {
            fraccionMaxDescuento = parseInt(action.formulario.fraccionMaxDescuento);
        }


        const data = {
            "idPeriodoFraccionMaxima": null,
            "periodoAnios": action.formulario.periodoAnios,
            "periodoMes": action.formulario.periodoMes,
            "cantidadAnios": cantidadAnios,
            "fechaInicialPeriodo": null,
            "fechaFinalPeriodo": null,
	        "fraccionMaxDescuento": fraccionMaxDescuento,
            "rutaArchivo":null,
            "fechaAlta":null,
	        "fechaActualizacion": null,
            "activo": null
        }
        console.log('data :>> ', data);

        const response = yield call(crearPeriodoCall, token, data );
        console.log('response crearPeriodoFMD', response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "El Periodo se creó de forma correcta.";
            const showModalCrearPeriodoFMD = false
            const page = action.page;

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.CREAR_PERIODO_FMD_SUCCESS })
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearPeriodoFMD'});
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_CREAR_PERIODO_FMD, showModalCrearPeriodoFMD })
            yield put({ type: mecanismosDePagosActions.LISTA_CATALOGO_PERIODO_FMD_REQUEST, page })
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.CREAR_PERIODO_FMD_FAILURE })
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.CREAR_PERIODO_FMD_FAILURE })
    }
}

export function* crearPeriodoFMDSaga() {
    yield takeLatest(mecanismosDePagosActions.CREAR_PERIODO_FMD_REQUEST, crearPeriodoFMD);
}
// verificar años de periodo
function* verificarAñosPeriodo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(verificarAñosPeriodoCall, token );
        console.log('response verificarAñosPeriodo', response);

        if (response.status === 200) {
            let añosMaximoPeriodo = response.data;

            yield put({ type: mecanismosDePagosActions.VERIFICAR_AÑOS_PERIODO_SUCCESS, añosMaximoPeriodo})
        } else {

            yield put({ type: mecanismosDePagosActions.VERIFICAR_AÑOS_PERIODO_FAILURE })
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.VERIFICAR_AÑOS_PERIODO_FAILURE })
    }
}

export function* verificarAñosPeriodoSaga() {
    yield takeLatest(mecanismosDePagosActions.VERIFICAR_AÑOS_PERIODO_REQUEST , verificarAñosPeriodo);
}

// editar periodo
function* editarPeriodoFraccionMaxDesc(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idPeriodoFraccionMaxima = action.periodoSeleccionado.idPeriodoFraccionMaxima;
        let limpiarFormulario = 'editarPeriodoFMD';

        let periodoAnios;
        let periodoMes;
        let cantidadAnios;
        let fraccionMaxDescuento;

        action.formulario.periodoAnios ? periodoAnios = action.formulario.periodoAnios: periodoAnios = action.periodoSeleccionado.periodoAnios;
        action.formulario.periodoMes ? periodoMes = action.formulario.periodoMes: periodoMes = action.periodoSeleccionado.periodoMes;
        action.formulario.cantidadAnios ? cantidadAnios = action.formulario.cantidadAnios: cantidadAnios = action.periodoSeleccionado.cantidadAnios
        action.formulario.fraccionMaxDescuento ? fraccionMaxDescuento = action.formulario.fraccionMaxDescuento: fraccionMaxDescuento = action.periodoSeleccionado.fraccionMaxDescuento;

        let list = []
        list = action.periodosEditados;

        const data = {
            "idPeriodoFraccionMaxima": action.periodoSeleccionado.idPeriodoFraccionMaxima,
            "periodoAnios": periodoAnios,
            "periodoMes": periodoMes,
            "cantidadAnios": parseInt(cantidadAnios),
            "fechaInicialPeriodo": null,
            "fechaFinalPeriodo": null,
	        "fraccionMaxDescuento": parseInt(fraccionMaxDescuento),
            "rutaArchivo":null,
            "fechaAlta":null,
	        "fechaActualizacion": null,
            "activo": null
        }

        console.log('data', data)
        console.log('list', list)

        const formData = new FormData();

        if(action.formularioPDF){
            limpiarFormulario = 'editarPeriodoPDF';
            formData.append('file', action.formularioPDF.pdf.file.originFileObj);
            formData.append('list', new Blob([JSON.stringify(list)], {
                type: "application/json"
            }))
        }

        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));



        console.log('formData', formData);

        const response = yield call(editarPeriodoFMDCall, token ,idPeriodoFraccionMaxima, formData);
        console.log('response editarPeriodoFraccionMaxDesc', response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "El Periodo se editó de forma correcta.";
            const showModalEditarPeriodoFMD = false;
            const page = action.page;


            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EDITAR_PERIODO_FMD_SUCCESS });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario});
            yield put({ type: mecanismosDePagosActions.CAMBIAR_VISTA_PERIODO_FMD, cambiarVistaModalPeriodoFMD: 1, formularioPeriodo: null})
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_EDITAR_PERIODO_FMD, showModalEditarPeriodoFMD});
            yield put({ type: mecanismosDePagosActions.LISTA_CATALOGO_PERIODO_FMD_REQUEST, page })

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EDITAR_PERIODO_FMD_FAILURE })
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.EDITAR_PERIODO_FMD_FAILURE })
    }
}

export function* editarPeriodoFraccionMaxDescSaga() {
    yield takeLatest(mecanismosDePagosActions.EDITAR_PERIODO_FMD_REQUEST , editarPeriodoFraccionMaxDesc);
}

//AÑO MES CALENDARIO SAGA
function* listaMesesCalendario(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let año = action.busqueda;

        let response = yield call(listaMesesCalendarioCall, token, año);
        console.log(response);

        if (response.status == 200) {
            let listaMesesCalendario = response.data.data;
            const listaMesesCalendarioCantidad = response.data.cantidad;

            if(listaMesesCalendario[0]){
                for (let i in listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario){
                    listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].mes = listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].mesByIdMes.mes
                    listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].anio = listaMesesCalendario[0].anio;
                    listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].fechaPubInpc = moment(listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].fechaPubInpc);
                    listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].pri = listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].pri.toLocaleString('en-US', {'maximumFractionDigits': 2})
                    if(listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].pti){
                        listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].pti = listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].pti.toLocaleString('en-US', {'maximumFractionDigits': 2})
                    }
                    if(listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].pvi){
                        listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].pvi = listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].pvi.toLocaleString('en-US', {'maximumFractionDigits': 2})
                    }
                    if(listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].pmi){
                        listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].pmi = listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].pmi.toLocaleString('en-US', {'maximumFractionDigits': 2})
                    }
                    if(listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].pmr){
                        listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].pmr = listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].pmr.toLocaleString('en-US', {'maximumFractionDigits': 2})
                    }
                    if(listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].dma){
                        listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].dma = listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].dma.toLocaleString('en-US', {'maximumFractionDigits': 2})
                    }
                    if(action.idMes === listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i].idMesCalendario){
                        yield put({ type: mecanismosDePagosActions.SHOW_MODAL_EDITAR_MES_CALENDARIO, showModalEditarMesCalendario: true, busqueda: action.busqueda, fechaAnterior: action.fechaAnterior, mesCalendarioEditar: listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[i]})
                    }
                }

                // CU - Monitoreo de Módulos de Calidad

                // let today = new Date();
                // let mesAnterior = today.getMonth();
                // let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

                // function convertir() {
                // let inputMes = mesAnterior;
                // let numeroMes = parseInt(inputMes);
                //     if(! isNaN(numeroMes) && numeroMes >= 1  && numeroMes <= 12 ) {
                //         inputMes = meses[numeroMes - 1];
                //         return inputMes;
                //     }
                // }
                // if(listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[0]){ listaMesesCalendario[0].idMesCalendarioMonitoreo = listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[1].idMesCalendario }
                // listaMesesCalendario[0].mesMonitoreo = convertir();
            }
            else{
                let fechaFinAñoInicioOperacion = action.listaInfoProyecto.fechaInicioOperacion.format('YYYY-MM-DD').split('-')[0] + '-12-31';
                let diasAño = moment(fechaFinAñoInicioOperacion).diff(action.listaInfoProyecto.fechaInicioOperacion, "days") + 1;
                let objetoBase = {
                    'dai': diasAño,
                    'estadoAnio': true,
                    'anio': año,
                }
                listaMesesCalendario.push(objetoBase);
            }

            yield put({ type: mecanismosDePagosActions.LISTA_MESES_CALENDARIO_SUCCESS, listaMesesCalendario, listaMesesCalendarioCantidad });
        }
        else {
            yield put({ type: mecanismosDePagosActions.LISTA_MESES_CALENDARIO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.LISTA_MESES_CALENDARIO_FAILURE });
    }
}
export function* listaMesesCalendarioSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_MESES_CALENDARIO_REQUEST, listaMesesCalendario);
}

function* listaAñosCalendario(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let response = yield call(listaAñosCalendarioCall, token);
        console.log(response);

        if (response.status == 200) {
            let listaAñosCalendario = response.data;
            if(!listaAñosCalendario[0]){
                listaAñosCalendario[0] = action.listaInfoProyecto.fechaInicioOperacion.format('YYYY-MM-DD').split('-')[0];
            }
            console.log('listaAñosCalendario', listaAñosCalendario);
            yield put({ type: mecanismosDePagosActions.LISTA_AÑO_CALENDARIO_SUCCESS, listaAñosCalendario });
            yield put({ type: mecanismosDePagosActions.LISTA_MESES_CALENDARIO_REQUEST, busqueda: listaAñosCalendario[0], listaInfoProyecto: action.listaInfoProyecto })
        }
        else {
            yield put({ type: mecanismosDePagosActions.LISTA_AÑO_CALENDARIO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.LISTA_AÑO_CALENDARIO_FAILURE });
    }
}
export function* listaAñosCalendarioSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_AÑO_CALENDARIO_REQUEST, listaAñosCalendario);
}

function* detalleMesCalendario(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idMes = action.idMes;
        const idAño = action.idAño;

        let response = yield call(detalleMesCalendarioCall, token, idMes, idAño);
        console.log(response);

        if (response.status == 200) {
            let detalleMesCalendario = response.data;

            detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].anio = detalleMesCalendario[0].anio;
            detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].dai = detalleMesCalendario[0].dai;
            if(detalleMesCalendario[0].estadoAnio){
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].estadoAnio =  'Abierto';
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].fechaCierreAnio = null;
            }
            else{
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].estadoAnio =  'Cerrado';
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].fechaCierreAnio = detalleMesCalendario[0].fechaCierreAnio.split('T')[0].split('-').reverse().join('-');
            }

            if(detalleMesCalendario[0].estadoAnio){
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].estadoAnio =  'Abierto';
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].fechaCierreAnio = null;
            }
            else{
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].estadoAnio =  'Cerrado';
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].fechaCierreAnio = detalleMesCalendario[0].fechaCierreAnio.split('T')[0].split('-').reverse().join('-');
            }

            if(detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].estadoMes){
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].estadoMesDetalle =  'Abierto';
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].fechaCierreMes = null;
            }
            else{
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].estadoMesDetalle =  'Cerrado';
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].fechaCierreMes = detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].fechaCierreMes.split('T')[0].split('-').reverse().join('-');
            }
            detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].fechaPubInpc = detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].fechaPubInpc.split('T')[0].split('-').reverse().join('-');
            detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].mes = detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].mesByIdMes.mes;

            if(detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].dma){
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].dmaDetalle = detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].dma.toLocaleString('en-US', {'maximumFractionDigits': 2}) + ' | ' + detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].mesDetallesResponse.formulaDma;
            }
            else{
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].dmaDetalle = detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].mesDetallesResponse.formulaDma;
            }

            if(detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].pmi){
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].pmiDetalle = detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].pmi.toLocaleString('en-US', {'maximumFractionDigits': 2}) + ' | ' + detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].mesDetallesResponse.formulaPmi;
            }
            else{
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].pmiDetalle = detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].mesDetallesResponse.formulaPmi;
            }

            if(detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].pmr){
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].pmrDetalle = detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].pmr.toLocaleString('en-US', {'maximumFractionDigits': 2}) + ' | ' + detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].mesDetallesResponse.formulaPmr;
            }
            else{
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].pmrDetalle = detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].mesDetallesResponse.formulaPmr;
            }

            if(detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].pri){
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].priDetalle = detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].pri.toLocaleString('en-US', {'maximumFractionDigits': 2}) + ' | ' + detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].mesDetallesResponse.formulaPri;
            }
            else{
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].priDetalle = detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].mesDetallesResponse.formulaPri;
            }

            if(detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].pti){
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].ptiDetalle = detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].pti.toLocaleString('en-US', {'maximumFractionDigits': 2}) + ' | ' + detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].mesDetallesResponse.formulaPti;
            }
            else{
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].ptiDetalle = detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].mesDetallesResponse.formulaPti;
            }

            if(detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].pvi){
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].pviDetalle = detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].pvi.toLocaleString('en-US', {'maximumFractionDigits': 2}) + ' | ' + detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].mesDetallesResponse.formulaPvi;
            }
            else{
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].pviDetalle = detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].mesDetallesResponse.formulaPvi;
            }

            if(detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].periodoFraccionMaximaByIdMesCalendario){
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].diDetalle = detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].periodoFraccionMaximaByIdMesCalendario.fraccionMaxDescuento + '%';
            }
            else{
                detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].diDetalle = 'No cuenta con un valor';
            }


            yield put({ type: mecanismosDePagosActions.DETALLE_MES_CALENDARIO_SUCCESS, detalleMesCalendario });
        }
        else {
            yield put({ type: mecanismosDePagosActions.DETALLE_MES_CALENDARIO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.DETALLE_MES_CALENDARIO_FAILURE });
    }
}
export function* detalleMesCalendarioSaga() {
    yield takeLatest(mecanismosDePagosActions.DETALLE_MES_CALENDARIO_REQUEST, detalleMesCalendario);
}

function* crearMesCalendario(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let anio = action.fechaAnterior.split('-')[0];
        let dai = action.listaMesesCalendario[0].dai;
        let idMes = parseInt(action.fechaAnterior.split('-')[1]);
        let dmi = 0;

        if(action.listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario){
            if(parseInt(anio) === parseInt(action.listaInfoProyecto.fechaFin.format('YYYY-MM-DD').split('-')[0]) && idMes === parseInt(action.listaInfoProyecto.fechaFin.format('YYYY-MM-DD').split('-')[1])){
                console.log('Entró a ultimo mes del contrato');
                dmi = moment(action.listaInfoProyecto.fechaFin.format('YYYY-MM-DD') + ' 00:00:00').diff(action.fechaAnterior + ' 00:00:00', "days") + 1;
            }
            else{
                if(action.listaMesesCalendario[0].mesesCalendarioByIdAnioCalendario[0].mesByIdMes.idMes === 12){
                    if(parseInt(anio) === parseInt(action.listaInfoProyecto.fechaFin.format('YYYY-MM-DD').split('-')[0])){
                        let diferenciaDiasAño = moment(action.listaInfoProyecto.fechaFin.format('YYYY-MM-DD') + ' 00:00:00').diff(anio + '-1-1 00:00:00', "days");
                        dai = diferenciaDiasAño;
                    }
                    else{
                        let diferenciaDiasAño = moment((parseInt(anio) + 1) + '-1-1 00:00:00').diff(anio + '-1-1 00:00:00', "days");
                        dai = diferenciaDiasAño;
                    }
                }
                if(idMes === 12){
                    dmi = moment((parseInt(action.fechaAnterior.split('-')[0]) + 1) + '-1-1 00:00:00').diff(action.fechaAnterior+ ' 00:00:00', "days");
                }
                else{
                    dmi = moment(action.fechaAnterior.split('-')[0] + '-' + (parseInt(action.fechaAnterior.split('-')[1]) + 1) + '-1 00:00:00').diff(action.fechaAnterior + ' 00:00:00', "days");
                }
            }
        }
        else{
            dmi = moment(action.fechaAnterior.split('-')[0] + '-' + (parseInt(action.fechaAnterior.split('-')[1]) + 1) + '-1 00:00:00').diff(action.fechaAnterior + ' 00:00:00', "days");
        }
        console.log('dmi', dmi)
        const data = {
            "idAnioCalendario": null,
            "anio": anio,
            "dai": dai,
            "fechaCreoAnio": null,
            "fechaCierreAnio": null,
            "estadoAnio": true,
            "mesesCalendarioByIdAnioCalendario":[
                {
                    "idMesCalendario": null,
                    "idAnioCalendario": null,
                    "titulo": '',
                    "idMes": idMes,
                    "estadoMes": true,
                    "fechaCreoMes": null,
                    "fechaCierreMes": null,
                    "dmi": dmi,
                    "inpc": action.formulario.inpc,
                    "fechaPubInpc": action.formulario.fechaPubInpc,
                    "fuentePubInpc": action.formulario.fuentePubInpc,
                    "pri": null,
                    "pti": null,
                    "pvi": null,
                    "pmi": null,
                    "pmr": null,
                    "dma": null,
                    "fechaAlta": null,
                    "fechaActualizacion": null
                }
            ]
        }

        console.log(data);
        const response = yield call(crearMesCalendarioCall, token, data)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "El mes se creó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.CREAR_MES_CALENDARIO_SUCCESS })
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearMesCalendario'});
            yield put({ type: mecanismosDePagosActions.LISTA_AÑO_CALENDARIO_REQUEST, busqueda: action.busqueda, listaInfoProyecto: action.listaInfoProyecto });
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_CREAR_MES_CALENDARIO, showModalCrearMesCalendario: false });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.CREAR_MES_CALENDARIO_FAILURE})
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.CREAR_MES_CALENDARIO_FAILURE})
    }
}
export function* crearMesCalendarioSaga(){
    yield takeLatest(mecanismosDePagosActions.CREAR_MES_CALENDARIO_REQUEST, crearMesCalendario)
}

function* editarMesCalendario(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idAño = action.mesCalendarioSeleccionado.idAnioCalendario;
        const idMes = action.mesCalendarioSeleccionado.idMesCalendario;
        const data = {
            "idMesCalendario": idMes,
            "idAnioCalendario": idAño,
            "titulo": '',
            "idMes": action.mesCalendarioSeleccionado.idMes,
            "estadoMes": action.mesCalendarioSeleccionado.estadoMes,
            "fechaCreoMes": action.mesCalendarioSeleccionado.fechaCreoMes,
            "fechaCierreMes": action.mesCalendarioSeleccionado.fechaCierreMes,
            "dmi": action.mesCalendarioSeleccionado.dmi,
            "inpc": action.formulario.inpc,
            "fechaPubInpc": action.formulario.fechaPubInpc,
            "fuentePubInpc": action.formulario.fuentePubInpc,
            "pri": null,
            "pti": null,
            "pvi": null,
            "pmi": null,
            "pmr": null,
            "dma": null,
            "fechaAlta": action.mesCalendarioSeleccionado.fechaAlta,
            "fechaActualizacion": action.mesCalendarioSeleccionado.fechaActualizacion
        }

        console.log(data);
        const response = yield call(editarMesCalendarioCall, token, data, idMes, idAño);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "El mes se editó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EDITAR_MES_CALENDARIO_SUCCESS });

            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarMesCalendario'});
            yield put({ type: mecanismosDePagosActions.LISTA_AÑO_CALENDARIO_REQUEST, busqueda: action.busqueda, listaInfoProyecto: action.listaInfoProyecto });
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_EDITAR_MES_CALENDARIO, showModalCrearMesCalendario: false });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EDITAR_MES_CALENDARIO_FAILURE})
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.EDITAR_MES_CALENDARIO_FAILURE})
    }
}
export function* editarMesCalendarioSaga(){
    yield takeLatest(mecanismosDePagosActions.EDITAR_MES_CALENDARIO_REQUEST, editarMesCalendario)
}

function* agregarPPL(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idMes = action.mesCalendarioSeleccionado.idMesCalendario
        const idAño = action.mesCalendarioSeleccionado.idAnioCalendario
        const data = [];
        let listaPPLs = [];
        for (let key in action.formulario) {
            if (Object.prototype.hasOwnProperty.call(action.formulario, key)) {
                let diaMes = key.split('-');
                if (diaMes.length === 2 && action.formulario[key]) {
                    let idCaptura = diaMes[1];
                    let campo = diaMes[0];
                    let valor = action.formulario[key];

                    let capturaFormulario = {[campo]: valor, 'diaMes': parseInt(idCaptura)};
                    listaPPLs.push(capturaFormulario);
                }
            }
        }
        console.log('listaPPLs', listaPPLs);
        for(let i in listaPPLs){
            let ppls = {
                "idPplCalendario": null,
                "idMesCalendario": idMes,
                "tituloPpl": "",
                "diaMes": listaPPLs[i].diaMes,
                "numPpl": listaPPLs[i].numPpl,
                "numPplMes": 0,
                "fechaEvidencia": null,
                "archivo": "",
                "fechaAlta": null,
                "fechaActualizacion": null,
                "activo": true
            }
            data.push(ppls);
        }

        const formData = new FormData();

        formData.append('data', new Blob([JSON.stringify(data)], {
            type: 'application/json'
        }));

        if(action.formulario.pdf){
            formData.append('file', action.formulario.pdf.file.originFileObj);
        }


        console.log(data);
        const response = yield call(agregarPPLCall, token, idMes, idAño, formData)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se agregaron los PPLs de forma correcta";
            if(action.editando){
                yield put({ type: mecanismosDePagosActions.LISTA_MESES_CALENDARIO_REQUEST, busqueda: action.busqueda, listaInfoProyecto: action.listaInfoProyecto, idMes: idMes, fechaAnterior: action.fechaAnterior });
            }
            else{
                yield put({ type: mecanismosDePagosActions.LISTA_MESES_CALENDARIO_REQUEST, busqueda: action.busqueda, listaInfoProyecto: action.listaInfoProyecto });
            }

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.AGREGAR_PPL_SUCCESS })
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'agregarPPL'});

            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_AGREGAR_PPL, showModalAgregarPPL: false });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.AGREGAR_PPL_FAILURE})
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.AGREGAR_PPL_FAILURE})
    }
}
export function* agregarPPLSaga(){
    yield takeLatest(mecanismosDePagosActions.AGREGAR_PPL_REQUEST, agregarPPL)
}


function* cerrarMesCalendario(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idMes = action.idMes;

        const response = yield call(cerrarMesCalendarioCall, token, idMes)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se cerró el mes de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.CERRAR_MES_CALENDARIO_SUCCESS })

            yield put({ type: mecanismosDePagosActions.LISTA_MESES_CALENDARIO_REQUEST, busqueda: action.busqueda, listaInfoProyecto: action.listaInfoProyecto });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.CERRAR_MES_CALENDARIO_FAILURE })
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.CERRAR_MES_CALENDARIO_FAILURE })
    }
}
export function* cerrarMesCalendarioSaga(){
    yield takeLatest(mecanismosDePagosActions.CERRAR_MES_CALENDARIO_REQUEST, cerrarMesCalendario)
}

function* calcularMesCalendario(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idMes = action.mesCalendarioSeleccionado.idMesCalendario;
        const idAño = action.mesCalendarioSeleccionado.idAnioCalendario;

        const response = yield call(calcularMesCalendarioCall, token, idMes, idAño)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se calculó el mes de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.CALCULAR_MES_CALENDARIO_SUCCESS })

            yield put({ type: mecanismosDePagosActions.LISTA_MESES_CALENDARIO_REQUEST, busqueda: action.busqueda, listaInfoProyecto: action.listaInfoProyecto });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.CALCULAR_MES_CALENDARIO_FAILURE })
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.CALCULAR_MES_CALENDARIO_FAILURE })
    }
}
export function* calcularMesCalendarioSaga(){
    yield takeLatest(mecanismosDePagosActions.CALCULAR_MES_CALENDARIO_REQUEST, calcularMesCalendario)
}

function* exportarExcelMesCalendario(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const año = action.año;

        const response = yield call(exportarExcelMesCalendarioCall, token, año);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el excel de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Año-Calendario-" + año + '-' + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EXPORTAR_EXCEL_MES_CALENDARIO_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EXPORTAR_EXCEL_MES_CALENDARIO_FAILURE });
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.EXPORTAR_EXCEL_MES_CALENDARIO_FAILURE });
    }
}
export function* exportarExcelMesCalendarioSaga(){
    yield takeLatest(mecanismosDePagosActions.EXPORTAR_EXCEL_MES_CALENDARIO_REQUEST, exportarExcelMesCalendario)
}

// Calculo de Deductivas
function* listaMesesAbiertos(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token')

        let response = yield call(listaMesesAbiertosCall, token)
        console.log('response ', response)

        if(response.status === 200){
            let listaMesesAbiertos = response.data;

            for(let i in listaMesesAbiertos){
                if(listaMesesAbiertos[i].mesByIdMes){
                    listaMesesAbiertos[i].mesCalendario = listaMesesAbiertos[i].mesByIdMes.mes;
                }
                if(listaMesesAbiertos[i].anioCalendarioByIdAnioCalendario){
                    listaMesesAbiertos[i].anioCalendario = listaMesesAbiertos[i].anioCalendarioByIdAnioCalendario.anio
                }

                function numberWithCommas(x) {
                    let parts = x.toString().split(".");
                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return parts.join(".");
                }

                if(listaMesesAbiertos[i].pmr){
                    listaMesesAbiertos[i].pmr = numberWithCommas(listaMesesAbiertos[i].pmr.toFixed(2));
                }
                if(listaMesesAbiertos[i].pmi){
                    listaMesesAbiertos[i].pmi = numberWithCommas(listaMesesAbiertos[i].pmi.toFixed(2));
                }
                if(listaMesesAbiertos[i].dma){
                    listaMesesAbiertos[i].dma = numberWithCommas(listaMesesAbiertos[i].dma.toFixed(2));
                }
                if(listaMesesAbiertos[i].pri){
                    listaMesesAbiertos[i].pri = numberWithCommas(listaMesesAbiertos[i].pri.toFixed(2));
                }
                if(listaMesesAbiertos[i].pti){
                    listaMesesAbiertos[i].pti = numberWithCommas(listaMesesAbiertos[i].pti.toFixed(2))
                }
            }

            yield put({ type: mecanismosDePagosActions.LISTA_MESES_ABIERTOS_SUCCESS, listaMesesAbiertos })
        } else {
            yield put({ type: mecanismosDePagosActions.LISTA_MESES_ABIERTOS_FAILURE})
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.LISTA_MESES_ABIERTOS_FAILURE})
    }
} export function* listaMesesAbiertosSaga(){
    yield takeLatest(mecanismosDePagosActions.LISTA_MESES_ABIERTOS_REQUEST, listaMesesAbiertos)
}

function* valorTipoDeCalculoDeDeductiva(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token')
        let proyeccion = '';  
        let idMesCalendario = '';
        if(action.idMesCalendario){idMesCalendario = '?idMesCalendario=' + action.idMesCalendario }
        if(action.proyeccion){
            proyeccion = '&proyeccion=' + action.proyeccion;
        }

        let response = yield call(valorTipoDeCalculoDeDeductivaCall, token, proyeccion, idMesCalendario)

        if(response.status === 200){
            let valorCalculoTipoDeductiva = response.data;

            if(valorCalculoTipoDeductiva.pti){ 
                if(valorCalculoTipoDeductiva.pti === "NaN"){
                    valorCalculoTipoDeductiva.pti = '-';
                }
             }

            yield put({ type: mecanismosDePagosActions.VALOR_TIPO_CALCULO_DEDUCTIVA_SUCCESS, valorCalculoTipoDeductiva })
        } else {
            yield put({ type: mecanismosDePagosActions.VALOR_TIPO_CALCULO_DEDUCTIVA_FAILURE})
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.VALOR_TIPO_CALCULO_DEDUCTIVA_FAILURE})
    }
} export function* valorTipoDeCalculoDeDeductivaSaga(){
    yield takeLatest(mecanismosDePagosActions.VALOR_TIPO_CALCULO_DEDUCTIVA_REQUEST, valorTipoDeCalculoDeDeductiva)
}

function* listaResumenServicio(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token')
        let proyeccion = '';
        let idMesCalendario = '';
        let fechaProyeccion = action.fechaProyeccion;

        if(action.proyeccion){ proyeccion = '&proyeccion=' + action.proyeccion; }
        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario; }

        let response = yield call(listaResumenServicioCall, token, fechaProyeccion, idMesCalendario, proyeccion)
        console.log('response ', response)

        if(response.status === 200){
            let listaResumenServicio = response.data.data;
            let listaResumenServicioCantidad = response.data.cantidad;
            let columnsSaga = [];

            if(listaResumenServicioCantidad > 0){
                let servicio = {'title': 'Servicio', 'dataIndex': 'servicio', 'key': 'servicio', 'align': 'center', 'width': '15%' };columnsSaga.push(servicio);
                let proveedores = { title: 'Proveedor', dataIndex: 'proveedor', key: 'proveedor', align:'center' }; 
                if(listaResumenServicio[0].proveedor){
                    columnsSaga.push(proveedores);
                }

                for(let j in listaResumenServicio[0].graficaResponseList){
                    let tipoFalla = listaResumenServicio[0].graficaResponseList[j].etiqueta.replace(/\s+/g, '');
                    let column =
                        {
                            'title': listaResumenServicio[0].graficaResponseList[j].etiqueta,
                            'dataIndex': tipoFalla,
                            'key': tipoFalla,
                            'align': 'right'
                        }
                    columnsSaga.push(column);
                }

                let cantidadTickets = { title: 'Cantidad de tickets', dataIndex: 'foliosByServicio', key: 'foliosByServicio',  align: 'right' }; columnsSaga.push(cantidadTickets);

                let sumaAcumulada = { title: 'Total', dataIndex: 'cantidadTabla', key: 'cantidadTabla', align: 'right' }; columnsSaga.push(sumaAcumulada);

                for(let i in listaResumenServicio){
                    listaResumenServicio[i].mes = action.mesCalendario;
                    listaResumenServicio[i].idResumenServicio = i;
                    let tipoFalla = '';
                    //PARA CADA TIPO DE FALLA
                    for(let j in listaResumenServicio[i].graficaResponseList){
                        //let deductiva = (listaResumenServicio[i].graficaResponseList[j].cantidad).toLocaleString('en-US', {'maximumFractionDigits': 2});
                        let deductiva = (listaResumenServicio[i].graficaResponseList[j].cantidad).toFixed(2);
                        function numberWithCommas(x) {
                            let parts = x.toString().split(".");
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            return parts.join(".");
                        }

                        let deductivaFxd = numberWithCommas(deductiva);

                        if(!deductiva.includes('.')){ deductivaFxd = deductiva + '.00'; }
                        tipoFalla = listaResumenServicio[i].graficaResponseList[j].etiqueta.replace(/\s+/g, '');
                        listaResumenServicio[i][tipoFalla] = deductivaFxd;
                    }

                    if(listaResumenServicio[i].deductivaAcumulada){
                        let cant = listaResumenServicio[i].deductivaAcumulada.toLocaleString('en-US', {'maximumFractionDigits': 2})
                        if(cant.includes('.')){
                            listaResumenServicio[i].cantidadTabla = cant;
                        } else {
                            listaResumenServicio[i].cantidadTabla = cant + '.00';
                        }

                    } else { listaResumenServicio[i].cantidadTabla = '0.00' }

                    // De aquí ya no
                }
                listaResumenServicio.columns = columnsSaga;
                console.log('columnsSaga', columnsSaga);
            }

            yield put({ type: mecanismosDePagosActions.LISTA_RESUMEN_SERVICIO_SUCCESS, listaResumenServicio, listaResumenServicioCantidad })
        } else {
            yield put({ type: mecanismosDePagosActions.LISTA_RESUMEN_SERVICIO_FAILURE })
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.LISTA_RESUMEN_SERVICIO_FAILURE})
    }
} export function* listaResumenServicioSaga(){
    yield takeLatest(mecanismosDePagosActions.LISTA_RESUMEN_SERVICIO_REQUEST, listaResumenServicio)
}

function* deductivaResumenEnExcel(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let proyeccion = '';
        let idMesCalendario = '';
        let fechaProyeccion = action.fechaProyeccion;

        if(action.proyeccion){ proyeccion = '&proyeccion=' + action.proyeccion; }
        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario; }

        let response = yield call(deductivaResumenExcelCall, token, fechaProyeccion, idMesCalendario, proyeccion)

        console.log('response', response)

        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            let dia = new Date().getDate();
            if(dia < 10){ dia = '0' + dia }
            let mes = new Date().getMonth() + 1;
            if(mes < 10){ mes = '0' + mes }

            link.download = "DeductivaResumen_" + dia + "-" + mes + "-" + new Date().getFullYear() + "_" + new Date().getHours() + "" + new Date().getMinutes() + "" + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: mecanismosDePagosActions.DEDUCTIVA_RESUMEN_EXCEL_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el documento.";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.DEDUCTIVA_RESUMEN_EXCEL_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.DEDUCTIVA_RESUMEN_EXCEL_FAILURE });
    }
}
export function* deductivaResumenExcelSaga() {
    yield takeLatest(mecanismosDePagosActions.DEDUCTIVA_RESUMEN_EXCEL_REQUEST, deductivaResumenEnExcel);
}

function* listaDetalleCalculoDeductivas(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idMesCalendario = ''; let idTipoFalla = ''; let idAreaFuncional = ''; let idGrupoSer = ''; let idServicio = ''; let fechaCierre1 = ''; let fechaCierre2 = '';
        let fechaProyeccion = action.fechaProyeccion;
        let proyeccion = ''; let idTipoFallaEOI = ''; let idTipoFallaReit = '';
        if(action.proyeccion){ proyeccion = '&proyeccion=' + action.proyeccion }
        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario; }
        if(action.idTipoFalla){ idTipoFalla = '&idTipoFalla=' + action.idTipoFalla; }
        if(action.idAreaFuncional){ idAreaFuncional = '&idAreaFuncional=' + action.idAreaFuncional; }
        if(action.idGrupoSer){ idGrupoSer = '&idGrupoSer=' + action.idGrupoSer; }
        if(action.idServicio){ idServicio = '&idServicio=' + action.idServicio; }
        if(action.fechaCierre1){ fechaCierre1 = '&fechaCierre1=' + action.fechaCierre1; }
        if(action.fechaCierre2){ fechaCierre2 = '&fechaCierre2=' + action.fechaCierre2; }

        if(action.idTipoFallaEOI){ idTipoFallaEOI = '&idTipoFallaEOI=' + action.idTipoFallaEOI; }
        if(action.idTipoFallaReit){ idTipoFallaReit = '&idTipoFallaReiteracion=' + action.idTipoFallaReit; }

        let response = yield call(listaDetalleCalculoDeductivasCall, token, fechaProyeccion, idMesCalendario, proyeccion, idTipoFalla, idTipoFallaEOI, idTipoFallaReit, idAreaFuncional, idGrupoSer, idServicio, fechaCierre1, fechaCierre2 )
        console.log('response ', response)

        if(response.status === 200){
            let listaDetalleCalculoDeductivas = response.data.data;
            let listaDetalleCalculoDeductivasCantidad = response.data.cantidad;

            if(listaDetalleCalculoDeductivasCantidad > 0){
                for(let i in listaDetalleCalculoDeductivas){
                    if(listaDetalleCalculoDeductivas[i].solicitudByIdSolicitudServicio){
                        listaDetalleCalculoDeductivas[i].ticket = listaDetalleCalculoDeductivas[i].solicitudByIdSolicitudServicio.folio;

                        listaDetalleCalculoDeductivas[i].origen = listaDetalleCalculoDeductivas[i].solicitudByIdSolicitudServicio.nombreUsuario;

                        listaDetalleCalculoDeductivas[i].descripcionSolicitudBySolicitud= listaDetalleCalculoDeductivas[i].solicitudByIdSolicitudServicio.descripcionSolicitud;

                        if(listaDetalleCalculoDeductivas[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra){
                            if(listaDetalleCalculoDeductivas[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.aMaterno === null || listaDetalleCalculoDeductivas[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.aMaterno === ""){
                                listaDetalleCalculoDeductivas[i].personalCCO = listaDetalleCalculoDeductivas[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.nombre + ' ' + listaDetalleCalculoDeductivas[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.aPaterno;
                            } else {
                                listaDetalleCalculoDeductivas[i].personalCCO = listaDetalleCalculoDeductivas[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.nombre + ' ' + listaDetalleCalculoDeductivas[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.aPaterno + ' ' + listaDetalleCalculoDeductivas[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.aMaterno;;
                            }
                        }
                    } else {
                        listaDetalleCalculoDeductivas[i].ticket = listaDetalleCalculoDeductivas[i].folioOrigen;
                        listaDetalleCalculoDeductivas[i].origen = '-';
                        listaDetalleCalculoDeductivas[i].personalCCO = '-';
                    }

                    listaDetalleCalculoDeductivas[i].fechaAltaTabla = listaDetalleCalculoDeductivas[i].fechaAlta.split('T')[0].split('-').reverse().join('-') + ' ' + listaDetalleCalculoDeductivas[i].fechaAlta.split('T')[1].split(':')[0] + ':' + listaDetalleCalculoDeductivas[i].fechaAlta.split('T')[1].split(':')[1];

                    // if(lioKstaDetalleCalculoDeductivas[i].solicitudByIdSolicitudServicio){
                    //     listaDetalleCalculoDeductivas[i].fechaAltaTabla = listaDetalleCalculoDeductivas[i].solicitudByIdSolicitudServicio.fechaAlta.split('T')[0].split('-').reverse().join('-') + ' ' + listaDetalleCalculoDeductivas[i].solicitudByIdSolicitudServicio.fechaAlta.split('T')[1].split(':')[0] + ':' + listaDetalleCalculoDeductivas[i].solicitudByIdSolicitudServicio.fechaAlta.split('T')[1].split(':')[1];
                    // }

                    listaDetalleCalculoDeductivas[i].fechaCierreTabla = listaDetalleCalculoDeductivas[i].fechaCierre.split('T')[0].split('-').reverse().join('-') + ' ' + listaDetalleCalculoDeductivas[i].fechaCierre.split('T')[1].split(':')[0] + ':' + listaDetalleCalculoDeductivas[i].fechaCierre.split('T')[1].split(':')[1];

                    listaDetalleCalculoDeductivas[i].pmiTabla = listaDetalleCalculoDeductivas[i].pmi.toLocaleString('en-US', {'maximumFractionDigits': 2});

                    listaDetalleCalculoDeductivas[i].pmrTabla = listaDetalleCalculoDeductivas[i].pmr.toLocaleString('en-US', {'maximumFractionDigits': 2});
                    listaDetalleCalculoDeductivas[i].deductivaTabla = listaDetalleCalculoDeductivas[i].deductiva.toLocaleString('en-US', {'maximumFractionDigits': 2});

                    if(listaDetalleCalculoDeductivas[i].deductivaParcial){
                        listaDetalleCalculoDeductivas[i].deductivaParcialTexto = 'Sí'
                    } else { listaDetalleCalculoDeductivas[i].deductivaParcialTexto = 'No' }

                    if(listaDetalleCalculoDeductivas[i].fallaCalidad){
                        listaDetalleCalculoDeductivas[i].fallaCalidadTabla = listaDetalleCalculoDeductivas[i].fallaCalidad;
                    } else { listaDetalleCalculoDeductivas[i].fallaCalidadTabla = '-' }

                    if(listaDetalleCalculoDeductivas[i].fsa){
                        listaDetalleCalculoDeductivas[i].fsaTabla = listaDetalleCalculoDeductivas[i].fsa.toFixed(2);
                    } else { listaDetalleCalculoDeductivas[i].fsaTabla = '-' }

                    if(listaDetalleCalculoDeductivas[i].ps){
                        listaDetalleCalculoDeductivas[i].psTabla = listaDetalleCalculoDeductivas[i].ps.toFixed(2) + '%';
                    } else { listaDetalleCalculoDeductivas[i].psTabla = '-' }

                    if(listaDetalleCalculoDeductivas[i].pcfc){
                        listaDetalleCalculoDeductivas[i].pcfcTabla = listaDetalleCalculoDeductivas[i].pcfc.toFixed(2) + '%';
                    } else { listaDetalleCalculoDeductivas[i].pcfcTabla = '-' }

                    if(listaDetalleCalculoDeductivas[i].psa){
                        listaDetalleCalculoDeductivas[i].psaTabla = listaDetalleCalculoDeductivas[i].psa.toFixed(2) + '%';
                    } else { listaDetalleCalculoDeductivas[i].psaTabla = '-' }

                    if(listaDetalleCalculoDeductivas[i].pr){
                        listaDetalleCalculoDeductivas[i].prTabla = listaDetalleCalculoDeductivas[i].pr;
                    } else { listaDetalleCalculoDeductivas[i].prTabla = '-' }

                    if(listaDetalleCalculoDeductivas[i].ponderacionServArea){
                        listaDetalleCalculoDeductivas[i].ponderacionServAreaTabla = listaDetalleCalculoDeductivas[i].ponderacionServArea;
                    } else { listaDetalleCalculoDeductivas[i].ponderacionServAreaTabla = '-' }

                    if(listaDetalleCalculoDeductivas[i].ticketReiterado){
                        listaDetalleCalculoDeductivas[i].ticketReiteradoTabla = listaDetalleCalculoDeductivas[i].ticketReiterado;
                    } else { listaDetalleCalculoDeductivas[i].ticketReiteradoTabla = '-' }

                    if(listaDetalleCalculoDeductivas[i].numReferencia){
                        listaDetalleCalculoDeductivas[i].numReferenciaTabla = listaDetalleCalculoDeductivas[i].numReferencia;
                    } else { listaDetalleCalculoDeductivas[i].numReferenciaTabla = '-' }

                    if(listaDetalleCalculoDeductivas[i].tiempoGarantiaByIdSolicitudServicio){
                        let tg = '';
                        tg = listaDetalleCalculoDeductivas[i].tiempoGarantiaByIdSolicitudServicio.tiempoGarantia;
                        if(listaDetalleCalculoDeductivas[i].tiempoGarantiaByIdSolicitudServicio.unidadesMedidasByIdUnidadMedida){
                            listaDetalleCalculoDeductivas[i].tiempoGarantiaTabla = tg + ' ' + listaDetalleCalculoDeductivas[i].tiempoGarantiaByIdSolicitudServicio.unidadesMedidasByIdUnidadMedida.unidadMedida;
                        }
                    } else { listaDetalleCalculoDeductivas[i].tiempoGarantiaTabla = '-' }

                    if(listaDetalleCalculoDeductivas[i].ut){
                        listaDetalleCalculoDeductivas[i].utTabla = listaDetalleCalculoDeductivas[i].ut;
                    } else { listaDetalleCalculoDeductivas[i].utTabla = '-' }

                    if(listaDetalleCalculoDeductivas[i].utf){
                        listaDetalleCalculoDeductivas[i].utfTabla = listaDetalleCalculoDeductivas[i].utf;
                    } else { listaDetalleCalculoDeductivas[i].utfTabla = '-' }

                    if(listaDetalleCalculoDeductivas[i].pdfd){
                        listaDetalleCalculoDeductivas[i].pdfdTabla = listaDetalleCalculoDeductivas[i].pdfd.toFixed(2) + '%';
                    } else { listaDetalleCalculoDeductivas[i].pdfdTabla = '-' }

                    if(listaDetalleCalculoDeductivas[i].fallaDisponibilidad){
                        listaDetalleCalculoDeductivas[i].fallaDisponibilidadTabla = listaDetalleCalculoDeductivas[i].fallaDisponibilidad;
                    } else { listaDetalleCalculoDeductivas[i].fallaDisponibilidadTabla = '-' }

                    if(listaDetalleCalculoDeductivas[i].tipoDeductiva === 'Disponibilidad'){
                        listaDetalleCalculoDeductivas[i].referenciaEstandarTabla = '-';
                        listaDetalleCalculoDeductivas[i].descripcionEstandarTabla = '-';
                    } else {
                        listaDetalleCalculoDeductivas[i].referenciaEstandarTabla = listaDetalleCalculoDeductivas[i].referenciaEstandar;
                        listaDetalleCalculoDeductivas[i].descripcionEstandarTabla = listaDetalleCalculoDeductivas[i].descripcionEstandar;
                    }

                    if(listaDetalleCalculoDeductivas[i].numeroFallaCategoria){
                        listaDetalleCalculoDeductivas[i].numeroFallaCategoriaTabla = listaDetalleCalculoDeductivas[i].numeroFallaCategoria;
                    } else { listaDetalleCalculoDeductivas[i].numeroFallaCategoriaTabla = '-'; }

                    // if(listaDetalleCalculoDeductivas[i].nombreProveedor){
                    //     listaDetalleCalculoDeductivas[i].nombreProveedorTabla = listaDetalleCalculoDeductivas[i].nombreProveedor;
                    // } else {listaDetalleCalculoDeductivas[i].nombreProveedorTabla = 'Sin proveedor asignado';}

                    //PENDIENTE
                    listaDetalleCalculoDeductivas[i].fechaCierreMesTabla = '-'
                }
            }  

            yield put({ type: mecanismosDePagosActions.LISTA_DETALLE_CALCULO_DEDUCTIVA_SUCCESS, listaDetalleCalculoDeductivas, listaDetalleCalculoDeductivasCantidad })
        } else {
            yield put({ type: mecanismosDePagosActions.LISTA_DETALLE_CALCULO_DEDUCTIVA_FAILURE})
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.LISTA_DETALLE_CALCULO_DEDUCTIVA_FAILURE})
    }
} export function* listaDetalleCalculoDeductivasSaga(){
    yield takeLatest(mecanismosDePagosActions.LISTA_DETALLE_CALCULO_DEDUCTIVA_REQUEST, listaDetalleCalculoDeductivas)
}

function* detalleDeductivaExcel(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let proyeccion = '';
        let idMesCalendario = '';
        let fechaProyeccion = action.fechaProyeccion;
        let idTipoFalla = ''; let idAreaFuncional = ''; let idGrupoSer = ''; let idServicio = ''; let fechaCierre1 = ''; let fechaCierre2 = ''; let idTipoFallaEOI = ''; let idTipoFallaReit = '';

        if(action.proyeccion){ proyeccion = '&proyeccion=' + action.proyeccion; }
        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario; }
        if(action.idTipoFalla){ idTipoFalla = '&idTipoFalla=' + action.idTipoFalla; }
        if(action.idAreaFuncional){ idAreaFuncional = '&idAreaFuncional=' + action.idAreaFuncional; }
        if(action.idGrupoSer){ idGrupoSer = '&idGrupoSer=' + action.idGrupoSer; }
        if(action.idServicio){ idServicio = '&idServicio=' + action.idServicio; }
        if(action.fechaCierre1){ fechaCierre1 = '&fechaCierre1=' + action.fechaCierre1; }
        if(action.fechaCierre2){ fechaCierre2 = '&fechaCierre2=' + action.fechaCierre2; }
        if(action.idTipoFallaEOI){ idTipoFallaEOI = '&idTipoFallaEOI=' + action.idTipoFallaEOI; }
        if(action.idTipoFallaReit){ idTipoFallaReit = '&idTipoFallaReiteracion=' + action.idTipoFallaReit; }

        let response = yield call(detalleDeductivaExcelCall, token, fechaProyeccion, idMesCalendario, proyeccion, idTipoFalla, idTipoFallaEOI, idTipoFallaReit, idAreaFuncional, idGrupoSer, idServicio, fechaCierre1, fechaCierre2)

        console.log('response', response)

        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            let dia = new Date().getDate();
            if(dia < 10){ dia = '0' + dia }
            let mes = new Date().getMonth() + 1;
            if(mes < 10){ mes = '0' + mes }

            link.download = "Deductiva_" + dia + "-" + mes + "-" + new Date().getFullYear() + "_" + new Date().getHours() + "" + new Date().getMinutes() + "" + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: mecanismosDePagosActions.DETALLE_DEDUCTIVA_EXCEL_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el documento.";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.DETALLE_DEDUCTIVA_EXCEL_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.DETALLE_DEDUCTIVA_EXCEL_FAILURE });
    }
}
export function* detalleDeductivaExcelSaga() {
    yield takeLatest(mecanismosDePagosActions.DETALLE_DEDUCTIVA_EXCEL_REQUEST, detalleDeductivaExcel);
}

function* procesarDeductiva(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let idMesCalendario = '';
        let fechaProyeccion = action.fechaProyeccion;

        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario; }

        let response = yield call(procesarDeductivaCall, token, fechaProyeccion, idMesCalendario)

        console.log('response', response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = 'El cálculo fue procesado con éxito';

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.VALOR_TIPO_CALCULO_DEDUCTIVA_REQUEST, proyeccion: action.proyeccion, idMesCalendario: action.idMesCalendario });
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_DETALLE_CALCULO_DEDUCTIVA, showModalDetalleCalculoDeductivas: false });
            yield put({ type: mecanismosDePagosActions.PROCESAR_DEDUCTIVA_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.PROCESAR_DEDUCTIVA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.PROCESAR_DEDUCTIVA_FAILURE });
    }
}
export function* procesarDeductivaSaga() {
    yield takeLatest(mecanismosDePagosActions.PROCESAR_DEDUCTIVA_REQUEST, procesarDeductiva);
}

// NIVELES DE SEVERIDAD
function* listaNivelesSeveridadActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(obtenerNivelesSeveridadActivosCall,token)
        console.log(response);

        if (response.status === 200) {
            const listaNivelesSeveridadActivos = response.data;
            let listaNivelesSeveridad = {};

            for(let i = 0; i < listaNivelesSeveridadActivos.length; i++){
                if(listaNivelesSeveridadActivos[i].unidadMedidaByIdUnidadMedida){
                    listaNivelesSeveridadActivos[i].unidadMedida = listaNivelesSeveridadActivos[i].unidadMedidaByIdUnidadMedida.unidadMedida
                }
                else{
                    listaNivelesSeveridadActivos[i].unidadMedida = '';
                }
                if(action.nivelSeveridadSeleccionado.idNivelSeveridad === listaNivelesSeveridadActivos[i].idNivelSeveridad ){
                    listaNivelesSeveridad['tiempoRespuesta-' + listaNivelesSeveridadActivos[i].idNivelSeveridad] = action.formulario.tiempoRespuesta;
                    listaNivelesSeveridad['unidadMedida-' + listaNivelesSeveridadActivos[i].idNivelSeveridad] = action.formulario.unidadMedida;
                    listaNivelesSeveridad['descripcion-' + listaNivelesSeveridadActivos[i].idNivelSeveridad] = action.formulario.descripcion;
                } else {
                    listaNivelesSeveridad['tiempoRespuesta-' + listaNivelesSeveridadActivos[i].idNivelSeveridad] = listaNivelesSeveridadActivos[i].tiempoRespuesta;
                    listaNivelesSeveridad['unidadMedida-' + listaNivelesSeveridadActivos[i].idNivelSeveridad] = listaNivelesSeveridadActivos[i].unidadMedida;
                    listaNivelesSeveridad['descripcion-' + listaNivelesSeveridadActivos[i].idNivelSeveridad] = listaNivelesSeveridadActivos[i].descripcion;
                }
            }
            console.log('listaNivelesSeveridad');
            console.log(listaNivelesSeveridad);
            yield put({ type: mecanismosDePagosActions.LISTA_NIVELES_SEVERIDAD_ACTIVOS_SUCCESS, listaNivelesSeveridadActivos, listaNivelesSeveridad })
        } else {
            yield put ({ type: mecanismosDePagosActions.LISTA_NIVELES_SEVERIDAD_ACTIVOS_FAILURE})
        }
    } catch (error) {
        yield put ({ type: mecanismosDePagosActions.LISTA_NIVELES_SEVERIDAD_ACTIVOS_FAILURE})
    }
}
export function* listaNivelesSeveridadActivosSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_NIVELES_SEVERIDAD_ACTIVOS_REQUEST, listaNivelesSeveridadActivos )
}

function* listaNivelesSeveridadBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let busqueda = '';
        const page = action.page - 1;
        if(action.busqueda){
            busqueda = 'param=' + action.busqueda + '&';
        }

        const response = yield call(obtenerNivelesSeveridadBusquedaCall,token, page, busqueda)
        console.log(response);

        if (response.status === 200) {
            const listaNivelesSeveridadBusqueda = response.data.data;
            const listaNivelesSeveridadBusquedaCantidad = response.data.cantidad;
            for(let i in listaNivelesSeveridadBusqueda){
                listaNivelesSeveridadBusqueda[i].fechaAlta = listaNivelesSeveridadBusqueda[i].fechaAlta.split('T')[0].split('-').reverse().join('-') + ' ' + listaNivelesSeveridadBusqueda[i].fechaAlta.split('T')[1].split(':')[0] + ':' + listaNivelesSeveridadBusqueda[i].fechaAlta.split('T')[1].split(':')[1];

                if(listaNivelesSeveridadBusqueda[i].unidadMedidaByIdUnidadMedida){
                    listaNivelesSeveridadBusqueda[i].unidadMedida = listaNivelesSeveridadBusqueda[i].unidadMedidaByIdUnidadMedida.unidadMedida;
                }
                else{
                    listaNivelesSeveridadBusqueda[i].unidadMedida = 'No tiene unidad de medida';
                }

            }

            yield put({ type: mecanismosDePagosActions.LISTA_NIVELES_SEVERIDAD_BUSQUEDA_SUCCESS, listaNivelesSeveridadBusqueda, listaNivelesSeveridadBusquedaCantidad })
        } else {
            yield put ({ type: mecanismosDePagosActions.LISTA_NIVELES_SEVERIDAD_BUSQUEDA_FAILURE})
        }
    } catch (error) {
        yield put ({ type: mecanismosDePagosActions.LISTA_NIVELES_SEVERIDAD_BUSQUEDA_FAILURE})
    }
}
export function* listaNivelesSeveridadBusquedaSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_NIVELES_SEVERIDAD_BUSQUEDA_REQUEST, listaNivelesSeveridadBusqueda )
}

function* estadoNivelSeveridad(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idNivelSeveridad = action.idNivelSeveridad;

        const response = yield call(estadoNivelSeveridadCall, token, idNivelSeveridad)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "El estado del nivel de severidad se cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.ESTADO_NIVEL_SEVERIDAD_SUCCESS })

            yield put({ type: mecanismosDePagosActions.LISTA_NIVELES_SEVERIDAD_BUSQUEDA_REQUEST, busqueda: action.busqueda, page: action.page });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.ESTADO_NIVEL_SEVERIDAD_FAILURE})
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.ESTADO_NIVEL_SEVERIDAD_SUCCESS})
    }
}
export function* estadoNivelSeveridadSaga(){
    yield takeLatest(mecanismosDePagosActions.ESTADO_NIVEL_SEVERIDAD_REQUEST, estadoNivelSeveridad)
}

function* crearNivelSeveridad(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        let idUnidadMedida = null;
        if(action.formulario.idUnidadMedida === 'Horas'){
            idUnidadMedida = 1;
        }
        else if(action.formulario.idUnidadMedida === 'Días'){
            idUnidadMedida = 2;
        }
        else if(action.formulario.idUnidadMedida === 'Años'){
            idUnidadMedida = 3;
        }
        else if(action.formulario.idUnidadMedida === 'Minutos'){
            idUnidadMedida = 4;
        }

        const data = {
            "idNivelSeveridad": null,
            "severidad": action.formulario.severidad,
            "descripcion": action.formulario.descripcion,
            "tiempoRespuesta": action.formulario.tiempoRespuesta,
            "idUnidadMedida": idUnidadMedida,
            "rutaArchivo": "",
            "fechaInicioVigencia": action.formulario.fechaInicioVigencia,
            "fechaFinVigencia": null,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }

        console.log(data);
        const response = yield call(crearNivelSeveridadCall, token, data)
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "El nivel de severidad se creó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.NUEVO_NIVEL_SEVERIDAD_SUCCESS })

            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearNivelSeveridad'});
            yield put({ type: mecanismosDePagosActions.LISTA_NIVELES_SEVERIDAD_BUSQUEDA_REQUEST, busqueda: action.busqueda, page: action.page });
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_NUEVO_NIVEL_SEVERIDAD, showModalNuevoNivelSeveridad: false });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.NUEVO_NIVEL_SEVERIDAD_FAILURE})
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.NUEVO_NIVEL_SEVERIDAD_FAILURE})
    }
}
export function* crearNivelSeveridadSaga(){
    yield takeLatest(mecanismosDePagosActions.NUEVO_NIVEL_SEVERIDAD_REQUEST, crearNivelSeveridad)
}

function* editarNivelSeveridad(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idNivelSeveridad = action.nivelSeveridadSeleccionado.idNivelSeveridad;
        let limpiarFormulario = 'editarNivelSeveridad';
        let list = [];

        if(action.nivelesEditados){
            for(let i=0; i<action.listaNivelesSeveridadActivos.length; i++){
                for(let x=0; x<action.nivelesEditados.length; x++){
                    if(action.nivelesEditados[x].idNivelSeveridad === action.listaNivelesSeveridadActivos[i].idNivelSeveridad){
                        let idUnidadMedida = null;
                        if(action.nivelesEditados[x].unidadMedidaNuevo === 'Horas'){
                            idUnidadMedida = 1;
                        }
                        else if(action.nivelesEditados[x].unidadMedidaNuevo === 'Días'){
                            idUnidadMedida = 2;
                        }
                        else if(action.nivelesEditados[x].unidadMedidaNuevo === 'Años'){
                            idUnidadMedida = 3;
                        }
                        else if(action.nivelesEditados[x].unidadMedidaNuevo === 'Minutos'){
                            idUnidadMedida = 4;
                        }

                        const nivelesAgregar = action.listaNivelesSeveridadActivos[i];
                        nivelesAgregar.tiempoRespuesta = action.nivelesEditados[x].tiempoRespuestaNuevo;
                        nivelesAgregar.descripcion = action.nivelesEditados[x].descripcionNuevo;
                        nivelesAgregar.idUnidadMedida = idUnidadMedida;
                        list.push(nivelesAgregar);
                    }
                }
            }
        }
        let idUnidadMedida = null;
        if(action.formularioNivelSeveridad.unidadMedida === 'Horas'){
            idUnidadMedida = 1;
        }
        else if(action.formularioNivelSeveridad.unidadMedida === 'Días'){
            idUnidadMedida = 2;
        }
        else if(action.formularioNivelSeveridad.unidadMedida === 'Años'){
            idUnidadMedida = 3;
        }
        else if(action.formularioNivelSeveridad.unidadMedida === 'Minutos'){
            idUnidadMedida = 4;
        }

        const data = {
            "idNivelSeveridad": idNivelSeveridad,
            "severidad": action.formularioNivelSeveridad.severidad,
            "descripcion": action.formularioNivelSeveridad.descripcion,
            "tiempoRespuesta": action.formularioNivelSeveridad.tiempoRespuesta,
            "idUnidadMedida": idUnidadMedida,
            "rutaArchivo": action.nivelSeveridadSeleccionado.rutaArchivo,
            "fechaInicioVigencia": action.formularioNivelSeveridad.fechaInicioVigencia,
            "fechaFinVigencia": action.nivelSeveridadSeleccionado.fechaFinVigencia,
            "fechaAlta": action.nivelSeveridadSeleccionado.fechaAlta.split(' ')[0].split('-').reverse().join('-'),
            "fechaActualizacion": action.nivelSeveridadSeleccionado.fechaActualizacion,
            "activo": action.nivelSeveridadSeleccionado.activo
        }

        console.log('data', data);
        console.log('list', list);

        const formData = new FormData();
        if(action.formularioPDF){
            limpiarFormulario = 'editarNivelSeveridadPDF';
            formData.append('file', action.formularioPDF.pdf.file.originFileObj);
            formData.append('list', new Blob([JSON.stringify(list)], {
                type: "application/json"
            }));
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));

        console.log('formData: ',formData);

        const response = yield call(editarNivelSeveridadCall, token, idNivelSeveridad, formData);
        console.log(response);

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "El nivel de severidad se modificó de forma correcta";
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario });
            yield put({ type: mecanismosDePagosActions.EDITAR_NIVEL_SEVERIDAD_SUCCESS });

            yield put({ type: mecanismosDePagosActions.CAMBIAR_VISTA_NIVEL_SEVERIDAD, vistaModalNivelSeveridad: 1, formularioNivelSeveridad: null, nivelesSeveridadEditados: null });
            yield put({ type: mecanismosDePagosActions.LISTA_NIVELES_SEVERIDAD_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda });
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_EDITAR_NIVEL_SEVERIDAD, showModalEditarNivelSeveridad: false, nivelSeveridadSeleccionado: null });
            yield put({ type: mecanismosDePagosActions.LISTA_NIVELES_SEVERIDAD_ACTIVOS_REQUEST });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: mecanismosDePagosActions.EDITAR_NIVEL_SEVERIDAD_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.EDITAR_NIVEL_SEVERIDAD_FAILURE });
    }
}
export function* editarNivelSeveridadSaga() {
    yield takeLatest(mecanismosDePagosActions.EDITAR_NIVEL_SEVERIDAD_REQUEST, editarNivelSeveridad);
}

//Catálogo Niveles Tiempo de Respuesta por Sección
function* listaNivelesTiempoRSActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaNivelesTiemposRActivosCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaNivelesTiempoRespuestaActivos = response.data;
            yield put({ type: mecanismosDePagosActions.LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_SUCCESS, listaNivelesTiempoRespuestaActivos });
        }
        else {
            yield put({ type: mecanismosDePagosActions.LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_FAILURE });
    }
}
export function* listaNivelesTiempoRSActivosSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_REQUEST, listaNivelesTiempoRSActivos);
}

function* listaNivelesTiempoRSActivosEdicion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {formularioNivelTiempoRespuesta,nivelTiempoRespuestaSeleccionado} = action;

        const response = yield call(listaNivelesTiemposRActivosCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaNivelesTiempoRespuestaEdicion = response.data;
            let objeto = {};
            let listaValoresInicialesNTR = {};
            listaNivelesTiempoRespuestaEdicion.map(item => {
                const propiedadTR = `tiempoRespuesta-${item.idTiempoRespuestaSeccion}`;
                const propiedadUM = `idUnidadMedida-${item.idTiempoRespuestaSeccion}-${item.idUnidadMedida}`;
                const unidadMedidad = item.unidadesMedidasByIdUnidadMedida.unidadMedida;
                objeto = {[propiedadTR]: item.tiempoRespuesta, [propiedadUM]: unidadMedidad};
                if (item.idTiempoRespuestaSeccion == nivelTiempoRespuestaSeleccionado.idTiempoRespuestaSeccion) {
                    objeto = {[propiedadTR]: formularioNivelTiempoRespuesta.tiempoRespuesta, [propiedadUM]: formularioNivelTiempoRespuesta.unidadMedida};
                }
                listaValoresInicialesNTR = {...listaValoresInicialesNTR,...objeto};
            })
            console.log(listaValoresInicialesNTR);
            yield put({ type: mecanismosDePagosActions.LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_E_SUCCESS, listaNivelesTiempoRespuestaEdicion, listaValoresInicialesNTR });
        }
        else {
            yield put({ type: mecanismosDePagosActions.LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_E_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_E_FAILURE });
    }
}
export function* listaNivelesTiempoRSActivosEdicionSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_E_REQUEST, listaNivelesTiempoRSActivosEdicion);
}

function* listaNivelesTiempoRSBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const {busqueda=""} = action;

        let response = yield call(listaNivelesTiemposRCall, token, page, busqueda);
        console.log(response);

        if (response.status == 200) {
            let listaNivelesTiempoRespuestaBusqueda = response.data.data;
            const listaNivelesTiempoRespuestaBusquedaCantidad = response.data.cantidad;

            for (let nivel of listaNivelesTiempoRespuestaBusqueda) {
                nivel.seccion = nivel.servSeccionesEspecificasByIdServSeccionEspecifico.descripcion;
                nivel.unidadMedida = nivel.unidadesMedidasByIdUnidadMedida.unidadMedida;
                nivel.fechaAlta = nivel.fechaAlta.split('T')[0].split('-').reverse().join('-');
                nivel.rangoVigencia = [moment(nivel.fechaInicioVigencia, 'YYYY-MM-DD'), moment(nivel.fechaFinVigencia, 'YYYY-MM-DD')]
            }
            yield put({ type: mecanismosDePagosActions.NIVELES_TIEMPO_RESPUESTA_BUSQUEDA_SUCCESS, listaNivelesTiempoRespuestaBusqueda, listaNivelesTiempoRespuestaBusquedaCantidad });
        }
        else {
            yield put({ type: mecanismosDePagosActions.NIVELES_TIEMPO_RESPUESTA_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.NIVELES_TIEMPO_RESPUESTA_BUSQUEDA_FAILURE });
    }
}
export function* listaNivelesTiempoRSBusquedaSaga() {
    yield takeLatest(mecanismosDePagosActions.NIVELES_TIEMPO_RESPUESTA_BUSQUEDA_REQUEST, listaNivelesTiempoRSBusqueda);
}

function* cambiarEstadoNivelTR(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idTiempoRespuestaSeccion = action.idTiempoRespuestaSeccion;

        const response = yield call(nivelesTiemposREstadoCall, token, idTiempoRespuestaSeccion);
        console.log(response);

        if(response.status==200){
            const {busqueda="", page=0} = action;
            const showSuccessMsg = true;
            const textMessage = "El estado del nivel de tiempo respuesta cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.ESTADO_NIVEL_TIEMPO_RESPUESTA_SUCCESS });
            yield put({ type: mecanismosDePagosActions.LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_REQUEST });
            yield put({ type: mecanismosDePagosActions.NIVELES_TIEMPO_RESPUESTA_BUSQUEDA_REQUEST, page ,busqueda});
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: mecanismosDePagosActions.ESTADO_NIVEL_TIEMPO_RESPUESTA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.ESTADO_NIVEL_TIEMPO_RESPUESTA_FAILURE });
    }
}
export function* cambiarEstadoNivelTRSaga() {
    yield takeLatest(mecanismosDePagosActions.ESTADO_NIVEL_TIEMPO_RESPUESTA_REQUEST, cambiarEstadoNivelTR);
}

function* crearNuevoNivelTiempoRS(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {page=0, busqueda="", formulario={}} = action;
        const fechaInicioVigencia = formulario.rangoVigencia[0].format('YYYY-MM-DD');

        const data = {
            "idTiempoRespuestaSeccion": null,
            "idServSeccionEspecifico": parseInt(formulario.seccion),
            "categoria": formulario.categoria,
            "tiempoRespuesta": formulario.tiempoRespuesta,
            "idUnidadMedida": parseInt(formulario.unidadMedida),
            "fechaInicioVigencia": fechaInicioVigencia,
            "fechaFinVigencia": null,
            "rutaArchivo": null,
            "fechaAlta": null,
            "fechaActualizacion": null
        }
        console.log(data);
        const response = yield call(crearNivelTiempoRCall,token, data);
        console.log(response);

        if (response.status == 200) {
            const showModalNuevoNivelTiempoRespuesta = false;
            const showSuccessMsg = true;
            const textMessage = "El nivel tiempo de tiempo de respuesta fue agregado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "CNTRS" });
            yield put({ type: mecanismosDePagosActions.NUEVO_NIVEL_TIEMPO_RESPUESTA_SUCCESS });
            yield put({ type: mecanismosDePagosActions.NIVELES_TIEMPO_RESPUESTA_BUSQUEDA_REQUEST, page , busqueda});
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_NUEVO_NIVEL_TIEMPO_RESPUESTA, showModalNuevoNivelTiempoRespuesta });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: mecanismosDePagosActions.NUEVO_NIVEL_TIEMPO_RESPUESTA_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.NUEVO_NIVEL_TIEMPO_RESPUESTA_FAILURE });
    }
}
export function* crearNuevoNivelTiempoRSSaga() {
    yield takeLatest(mecanismosDePagosActions.NUEVO_NIVEL_TIEMPO_RESPUESTA_REQUEST, crearNuevoNivelTiempoRS);
}

function* editarNivelTiempoRS(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {page=0, busqueda="", formularioNivelTiempoRespuesta={}, nivelTiempoRespuestaSeleccionado={}, listaFiltradaEditadosNTR=[]} = action;
        const idTiempoRespuestaSeccion = nivelTiempoRespuestaSeleccionado.idTiempoRespuestaSeccion;
        const fechaInicioVigencia = formularioNivelTiempoRespuesta.rangoVigencia[0].format('YYYY-MM-DD');
        const fechaFinVigencia = formularioNivelTiempoRespuesta.rangoVigencia[1].format('YYYY-MM-DD');
        let limpiarFormulario = 'ENTRS';

        const data = {
            "idTiempoRespuestaSeccion": idTiempoRespuestaSeccion,
            "idServSeccionEspecifico": isNaN(parseInt(formularioNivelTiempoRespuesta.seccion)) ? nivelTiempoRespuestaSeleccionado.idServSeccionEspecifico : parseInt(formularioNivelTiempoRespuesta.seccion),
            "categoria": formularioNivelTiempoRespuesta.categoria,
            "tiempoRespuesta": formularioNivelTiempoRespuesta.tiempoRespuesta,
            "idUnidadMedida": isNaN(parseInt(formularioNivelTiempoRespuesta.unidadMedida)) ? nivelTiempoRespuestaSeleccionado.idUnidadMedida : parseInt(formularioNivelTiempoRespuesta.unidadMedida),
            "fechaInicioVigencia": fechaInicioVigencia,
            "fechaFinVigencia": fechaFinVigencia,
            "rutaArchivo": null,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo":true
        }
        console.log(data);
        console.log(listaFiltradaEditadosNTR);
        const formData = new FormData();
        if(action.formularioPDF) {
            limpiarFormulario = 'ENTRSPFD';
            formData.append('file', action.formularioPDF.pdf.file.originFileObj);
            formData.append('list', new Blob([JSON.stringify(listaFiltradaEditadosNTR)],{
                type: 'application/json'
            }));
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"

        }));
        const response = yield call(editarNivelTiempoRCall, token, formData, idTiempoRespuestaSeccion);
        console.log(response);

        if (response.status == 200) {
            const showModalEditarNivelTiempoRespuesta = false;
            const showSuccessMsg = true;
            const textMessage = "El nivel tiempo de tiempo de respuesta fue editado con éxito.";
            const nivelTiempoRespuestaSeleccionado = null;

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario });
            yield put({ type: mecanismosDePagosActions.EDITAR_NIVEL_TIEMPO_RESPUESTA_SUCCESS });
            yield put({ type: mecanismosDePagosActions.CAMBIAR_VISTA_NIVEL_TIEMPO_RESPUESTA, vistaModalNivelTiempoRespuesta: 1, formularioNivelTiempoRespuesta: null, listaFiltradaEditadosNTR: []});
            yield put({ type: mecanismosDePagosActions.NIVELES_TIEMPO_RESPUESTA_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: mecanismosDePagosActions.LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_REQUEST });
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_EDITAR_NIVEL_TIEMPO_RESPUESTA, showModalEditarNivelTiempoRespuesta, nivelTiempoRespuestaSeleccionado });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EDITAR_NIVEL_TIEMPO_RESPUESTA_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.EDITAR_NIVEL_TIEMPO_RESPUESTA_FAILURE });
    }
}
export function* editarNivelTiempoRSSaga() {
    yield takeLatest(mecanismosDePagosActions.EDITAR_NIVEL_TIEMPO_RESPUESTA_REQUEST, editarNivelTiempoRS);
}

// Número de Documento por Reiteración de Fallas
function* listaDocumentosReiteradoUltimos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let busqueda = '';
        if(action.busqueda){ busqueda = "?numerosDocumentos=" + action.busqueda }

        const response = yield call(listaDocumentosReiteradoUltimosCall, token , busqueda)
        console.log(response);

        if (response.status === 200) {
            let listaDocumentosReiteradoUltimos = response.data.data;

            for(let i in listaDocumentosReiteradoUltimos){
                let fRegistro = listaDocumentosReiteradoUltimos[i].fechaRegistro.split('T')[0].split('-').reverse().join('-');

                listaDocumentosReiteradoUltimos[i].fRegistro = fRegistro;

                if(listaDocumentosReiteradoUltimos[i].estado){
                    listaDocumentosReiteradoUltimos[i].estatus = 'Abierto';
                } else {
                    listaDocumentosReiteradoUltimos[i].estatus = 'Cerrado';
                }
            }

            yield put({ type: "LISTA_DOCUMENTO_REITERADO_ULTIMOS_SUCCESS", listaDocumentosReiteradoUltimos});
        } else {
            yield put ({ type: "LISTA_DOCUMENTO_REITERADO_ULTIMOS_FAILURE"})
        }
    } catch (error) {
        yield put ({ type: "LISTA_DOCUMENTO_REITERADO_ULTIMOS_FAILURE"})
    }
}
export function* listaDocumentosReiteradoUltimosSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_DOCUMENTO_REITERADO_ULTIMOS_REQUEST, listaDocumentosReiteradoUltimos )
}

function* listaDocumentosReiteradoUltimosWitOutSearch(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaDocumentosReiteradoUltimosCall, token, '')
        console.log(response);

        if (response.status === 200) {
            let listaDocumentosReiteradoUltimosWitoutSearch = response.data.data;

            for(let i in listaDocumentosReiteradoUltimosWitoutSearch){
                let fRegistro = listaDocumentosReiteradoUltimosWitoutSearch[i].fechaRegistro.split('T')[0].split('-').reverse().join('-');

                listaDocumentosReiteradoUltimosWitoutSearch[i].fRegistro = fRegistro;

                if(listaDocumentosReiteradoUltimosWitoutSearch[i].estado){
                    listaDocumentosReiteradoUltimosWitoutSearch[i].estatus = 'Abierto';
                } else {
                    listaDocumentosReiteradoUltimosWitoutSearch[i].estatus = 'Cerrado';
                }
            }

            yield put({ type: "LISTA_DOCUMENTO_REITERADO_ULTIMO_WITHOUT_SUCCESS", listaDocumentosReiteradoUltimosWitoutSearch});
        } else {
            yield put ({ type: "LISTA_DOCUMENTO_REITERADO_ULTIMO_WITHOUT_FAILURE"})
        }
    } catch (error) {
        yield put ({ type: "LISTA_DOCUMENTO_REITERADO_ULTIMO_WITHOUT_FAILURE"})
    }
}
export function* listaDocumentosReiteradoUltimosWitOutSearchSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_DOCUMENTO_REITERADO_ULTIMO_WITHOUT_REQUEST, listaDocumentosReiteradoUltimosWitOutSearch )
}

function* listaTicketsReiterados(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let folio = '';
        let idAreaFuncional = '';
        let idServicio = '';
        let idFalla = '';
        if(action.folio){ folio = "?folio=" + action.folio }
        if(action.idAreaFuncional){ idAreaFuncional = "?idAreaFuncional=" + action.idAreaFuncional }
        if(action.idServicio){ idServicio = "&idServicio=" + action.idServicio }
        if(action.idFalla){ idFalla = "&idFalla=" + action.idFalla }

        const response = yield call(listaTicketsReiteradosCall, token , folio, idAreaFuncional, idServicio, idFalla)
        console.log(response);

        if (response.status === 200) {
            let listaTicketsReiterados = response.data.data;
            let listaTicketsReiteradosCantidad = response.data.cantidad;

            for(let i in listaTicketsReiterados){
                let fAlta = listaTicketsReiterados[i].fechaAlta.split('T')[0].split('-').reverse().join('-') + ' ' + listaTicketsReiterados[i].fechaAlta.split('T')[1].split(':')[0] + ':' + listaTicketsReiterados[i].fechaAlta.split('T')[1].split(':')[1];
                let fCierre = listaTicketsReiterados[i].fechaCierre.split('T')[0].split('-').reverse().join('-') + ' ' + listaTicketsReiterados[i].fechaCierre.split('T')[1].split(':')[0] + ':' + listaTicketsReiterados[i].fechaCierre.split('T')[1].split(':')[1];

                listaTicketsReiterados[i].fechaCierreDescription = fCierre;
                listaTicketsReiterados[i].fechaAltaDescription = fAlta;

                if(listaTicketsReiterados[i].servicioByIdServicio){
                    listaTicketsReiterados[i].servicio = listaTicketsReiterados[i].servicioByIdServicio.nombre
                }

                if(listaTicketsReiterados[i].ubicacionByIdUbicacion){
                    if(listaTicketsReiterados[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional){
                        listaTicketsReiterados[i].areaFuncional = listaTicketsReiterados[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional
                    }
                }

                if(listaTicketsReiterados[i].fallaByIdFalla){
                    listaTicketsReiterados[i].nombreFalla = listaTicketsReiterados[i].fallaByIdFalla.nombre
                }
            }

            console.log('listaTicketsReiterados', listaTicketsReiterados);

            yield put({ type: "LISTA_TICKETS_REITERADOS_SUCCESS", listaTicketsReiterados, listaTicketsReiteradosCantidad })
        } else {
            yield put ({ type: "LISTA_TICKETS_REITERADOS_FAILURE"})
        }
    } catch (error) {
        yield put ({ type: "LISTA_TICKETS_REITERADOS_FAILURE"})
    }
}
export function* listaTicketsReiteradosSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_TICKETS_REITERADOS_REQUEST, listaTicketsReiterados )
}

function* listaTicketsOrigen(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let folio = '';
        let idAreaFuncional = '';
        let idServicio = '';
        let idFalla = '';
        let idSolicitudServicioReiterada = '';
        if(action.folio){ folio = "?folio=" + action.folio }
        if(action.idAreaFuncional){ idAreaFuncional = "?idAreaFuncional=" + action.idAreaFuncional }
        if(action.idServicio){ idServicio = "&idServicio=" + action.idServicio }
        if(action.idFalla){ idFalla = "&idFalla=" + action.idFalla }
        if(action.idSolicitudServicioReiterada){ idSolicitudServicioReiterada = "&idSolicitudServicioReiterada=" + action.idSolicitudServicioReiterada }

        const response = yield call(listaTicketsOrigenCall, token , folio, idAreaFuncional, idServicio, idFalla, idSolicitudServicioReiterada)
        console.log(response);

        if (response.status === 200) {
            let listaTicketsOrigen = response.data.data;
            let listaTicketsOrigenCantidad = response.data.cantidad;

            for(let i in listaTicketsOrigen){
                let fAlta = listaTicketsOrigen[i].fechaAlta.split('T')[0].split('-').reverse().join('-') + ' ' + listaTicketsOrigen[i].fechaAlta.split('T')[1].split(':')[0] + ':' + listaTicketsOrigen[i].fechaAlta.split('T')[1].split(':')[1];;
                let fCierre = listaTicketsOrigen[i].fechaCierre.split('T')[0].split('-').reverse().join('-') + ' ' + listaTicketsOrigen[i].fechaCierre.split('T')[1].split(':')[0] + ':' + listaTicketsOrigen[i].fechaCierre.split('T')[1].split(':')[1];;

                listaTicketsOrigen[i].fechaCierreDescription = fCierre;
                listaTicketsOrigen[i].fechaAltaDescription = fAlta;

                if(listaTicketsOrigen[i].servicioByIdServicio){
                    listaTicketsOrigen[i].servicio = listaTicketsOrigen[i].servicioByIdServicio.nombre
                }

                if(listaTicketsOrigen[i].ubicacionByIdUbicacion){
                    if(listaTicketsOrigen[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional){
                        listaTicketsOrigen[i].areaFuncional = listaTicketsOrigen[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional
                    }
                }

                if(listaTicketsOrigen[i].fallaByIdFalla){
                    listaTicketsOrigen[i].nombreFalla = listaTicketsOrigen[i].fallaByIdFalla.nombre
                }
            }

            yield put({ type: "LISTA_TICKETS_ORIGEN_SUCCESS", listaTicketsOrigen, listaTicketsOrigenCantidad })
        } else {
            yield put ({ type: "LISTA_TICKETS_ORIGEN_FAILURE"})
        }
    } catch (error) {
        yield put ({ type: "LISTA_TICKETS_ORIGEN_FAILURE"})
    }
}
export function* listaTicketsOrigenSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_TICKETS_ORIGEN_REQUEST, listaTicketsOrigen )
}

function* documentoRelacionado(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idSolicitudServicioOrigen = action.idSolicitudServicioOrigen;

        const response = yield call(documentoRelacionadoCall, token , idSolicitudServicioOrigen)
        console.log(response);

        if (response.status === 200) {
            let documentoRelacionado = response.data;
            let reiteracion = 1;
            let ticketOrigenSeleccionadoMODAL = action.ticketOrigenSeleccionado
            let showModalBusquedaTicketOrigen = false;

            if(documentoRelacionado.length > 0){
                if(documentoRelacionado[0].reiteracionesByIdDocumentoReiteracion){
                    reiteracion = documentoRelacionado[0].reiteracionesByIdDocumentoReiteracion[documentoRelacionado[0].reiteracionesByIdDocumentoReiteracion.length - 1].reiteracion + 1;
                }
            }

            //message.info('Número de reiteración: ' + reiteracion);

            yield put({ type: "GET_DOCUMENTO_RELACIONADO_SUCCESS", documentoRelacionado, reiteracion });
            yield put({ type: 'SHOW_MODAL_BUSQUEDA_TICKET_ORIGEN', showModalBusquedaTicketOrigen, ticketOrigenSeleccionadoMODAL, idSolicitudServicioOrigen, reiteracion });
        } else {
            yield put ({ type: "GET_DOCUMENTO_RELACIONADO_FAILURE"})
        }
    } catch (error) {
        yield put ({ type: "GET_DOCUMENTO_RELACIONADO_FAILURE"})
    }
}
export function* documentoRelacionadoSaga() {
    yield takeLatest(mecanismosDePagosActions.GET_DOCUMENTO_RELACIONADO_REQUEST, documentoRelacionado )
}

function* documentoRelacionadoByNumero(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let numeroDocumento = action.numeroDocumento;

        const response = yield call(documentoRelacionadoByNumeroCall, token , numeroDocumento)
        console.log(response);

        if (response.status === 200) {
            let documentoRelacionadoByNumero = response.data;

            yield put({ type: "GET_DOCUMENTO_RELACIONADO_BY_NUMERO_SUCCESS", documentoRelacionadoByNumero })
        } else {
            yield put ({ type: "GET_DOCUMENTO_RELACIONADO_BY_NUMERO_FAILURE"})
        }
    } catch (error) {
        yield put ({ type: "GET_DOCUMENTO_RELACIONADO_BY_NUMERO_FAILURE"})
    }
}
export function* documentoRelacionadoByNumeroSaga() {
    yield takeLatest(mecanismosDePagosActions.GET_DOCUMENTO_RELACIONADO_BY_NUMERO_REQUEST, documentoRelacionadoByNumero )
}

function* crearDocumentoReiteracion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let numDoc = action.numeroDocumento
        if(!action.numeroDocumento){ numDoc = action.codigoInterno}

        let idDocumentoReiteracion = '';
        if(action.idDocumentoReiteracion){ idDocumentoReiteracion = '?idDocumentoReiteracion=' + action.idDocumentoReiteracion }

        const data = {
            "idSolicitudServicioOrigen": action.idSolicitudServicioOrigen,
            "idSolicitudServicioReiterada": action.idSolicitudServicioReiterada,
            "numeroDocumento": numDoc,
            "reiteracion": action.reiteracion,
            "codigoInterno": action.codigoInterno
        }

        console.log('Data', data);

        const response = yield call(crearDocumentoReiteracionCall, token, data, idDocumentoReiteracion);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const busqueda = '';
            const showModalNuevoNumeroDocumento = false;
            const showSuccessMsg = true;
            const textMessage = "El nuevo número de documento se creó de forma correcta.";

            if(action.busqueda){ busqueda = "?numerosDocumentos=" + action.busqueda }

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'nuevoNumeroDocumento' });
            yield put({ type: mecanismosDePagosActions.CREAR_DOCUMENTO_REITERACION_SUCCESS });
            yield put({ type: mecanismosDePagosActions.LISTA_DOCUMENTO_REITERADO_ULTIMOS_REQUEST, page, busqueda });
            yield put({ type: mecanismosDePagosActions.SHOW_MODAL_NUEVO_NO_DOCUMENTO, showModalNuevoNumeroDocumento });
            yield put({ type: mecanismosDePagosActions.LISTA_DOCUMENTO_REITERADO_ULTIMO_WITHOUT_REQUEST });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: mecanismosDePagosActions.CREAR_DOCUMENTO_REITERACION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.CREAR_DOCUMENTO_REITERACION_FAILURE });
    }
}
export function* crearDocumentoReiteracionSaga() {
    yield takeLatest(mecanismosDePagosActions.CREAR_DOCUMENTO_REITERACION_REQUEST, crearDocumentoReiteracion);
}

function* ticketsReiteradosByNumeroDocumento(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idDocumentoReiteracion = action.idDocumentoReiteracion;

        const response = yield call(ticketsReiteradosByNumeroDocumentoCall, token , idDocumentoReiteracion)
        console.log(response);

        if (response.status === 200) {
            let ticketsReiteradosByDocumento = response.data.data;

            for(let i in ticketsReiteradosByDocumento){
                let fAlta = ticketsReiteradosByDocumento[i].fechaAlta.split('T')[0].split('-').reverse().join('-') + ' ' + ticketsReiteradosByDocumento[i].fechaAlta.split('T')[1].split(':')[0] + ':' + ticketsReiteradosByDocumento[i].fechaAlta.split('T')[1].split(':')[1];
                let fCierre = ticketsReiteradosByDocumento[i].fechaCierre.split('T')[0].split('-').reverse().join('-') + ' ' + ticketsReiteradosByDocumento[i].fechaCierre.split('T')[1].split(':')[0] + ':' + ticketsReiteradosByDocumento[i].fechaCierre.split('T')[1].split(':')[1];;

                ticketsReiteradosByDocumento[i].fechaCierreDescription = fCierre;
                ticketsReiteradosByDocumento[i].fechaAltaDescription = fAlta;

                if(ticketsReiteradosByDocumento[i].servicioByIdServicio){
                    ticketsReiteradosByDocumento[i].servicio = ticketsReiteradosByDocumento[i].servicioByIdServicio.nombre
                }

                if(ticketsReiteradosByDocumento[i].ubicacionByIdUbicacion){
                    if(ticketsReiteradosByDocumento[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional){
                        ticketsReiteradosByDocumento[i].areaFuncional = ticketsReiteradosByDocumento[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional
                    }
                }

                if(ticketsReiteradosByDocumento[i].fallaByIdFalla){
                    ticketsReiteradosByDocumento[i].nombreFalla = ticketsReiteradosByDocumento[i].fallaByIdFalla.nombre
                }
            }

            yield put({ type: "TICKETS_REITERADOS_BY_DOCUMENTO_SUCCESS", ticketsReiteradosByDocumento })
        } else {
            yield put ({ type: "TICKETS_REITERADOS_BY_DOCUMENTO_FAILURE"})
        }
    } catch (error) {
        yield put ({ type: "TICKETS_REITERADOS_BY_DOCUMENTO_FAILURE"})
    }
}
export function* ticketsReiteradosByNumeroDocumentoSaga() {
    yield takeLatest(mecanismosDePagosActions.TICKETS_REITERADOS_BY_DOCUMENTO_REQUEST, ticketsReiteradosByNumeroDocumento )
}

function* eliminarNumeroDocumento(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idDocumentoReiteracion = action.idDocumentoReiteracion;

        const response = yield call(eliminarNumeroDocumentoCall, token , idDocumentoReiteracion)
        console.log(response);

        if (response.status === 200) {
            const page = action.page;
            const busqueda = '';
            const showSuccessMsg = true;
            const textMessage = "El número de documento ha sido eliminado.";

            if(action.busqueda){ busqueda = "?numerosDocumentos=" + action.busqueda }

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.ELIMINAR_NUMERO_DOCUMENTO_SUCCESS})
            yield put({ type: mecanismosDePagosActions.LISTA_DOCUMENTO_REITERADO_ULTIMOS_REQUEST, page, busqueda });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.ELIMINAR_NUMERO_DOCUMENTO_FAILURE})
        }
    } catch (error) {
        yield put ({ type: "ELIMINAR_NUMERO_DOCUMENTO_FAILURE"})
    }
}
export function* eliminarNumeroDocumentoSaga() {
    yield takeLatest(mecanismosDePagosActions.ELIMINAR_NUMERO_DOCUMENTO_REQUEST, eliminarNumeroDocumento )
}

function* activosByCodigoInterno(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let codigoInterno = action.codigoInterno;

        const response = yield call(activosByCodigoInternoCall, token , codigoInterno)
        console.log(response);

        if (response.status === 200) {
            let listaActivosByCodigoInterno = response.data;

            for(let i in listaActivosByCodigoInterno){
                if(listaActivosByCodigoInterno[i].activoCaractByIdActivoCaract){
                    listaActivosByCodigoInterno[i].descripcion = listaActivosByCodigoInterno[i].activoCaractByIdActivoCaract.descripcion;

                    listaActivosByCodigoInterno[i].especificaciones = listaActivosByCodigoInterno[i].activoCaractByIdActivoCaract.especificaciones;

                    listaActivosByCodigoInterno[i].marca = listaActivosByCodigoInterno[i].activoCaractByIdActivoCaract.marca;

                    listaActivosByCodigoInterno[i].modelo = listaActivosByCodigoInterno[i].activoCaractByIdActivoCaract.modelo;
                }
            }

            yield put({ type: "LISTA_ACTIVOS_BY_CODIGO_INTERNO_SUCCESS", listaActivosByCodigoInterno })
        } else {
            yield put ({ type: "LISTA_ACTIVOS_BY_CODIGO_INTERNO_FAILURE"})
        }
    } catch (error) {
        yield put ({ type: "LISTA_ACTIVOS_BY_CODIGO_INTERNO_FAILURE"})
    }
}
export function* activosByCodigoInternoSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_ACTIVOS_BY_CODIGO_INTERNO_REQUEST, activosByCodigoInterno )
}

//Cálculo de deductivas por Reiteración para el mes i
function* reporteResumenCalculoDeductivaReiteracion(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idMesCalendario = '';
        let fechaProyeccion = action.fechaProyeccion;

        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario; }

        let response = yield call(reporteResumenCalculoDeductivaReiteracionCall, token, fechaProyeccion, idMesCalendario)
        console.log('response ', response)

        if(response.status === 200){
            let reporteResumenCalculoDR = response.data.data;
            let reporteResumenCalculoDRCantidad = response.data.cantidad;
            let columnsSaga = [];

            if(reporteResumenCalculoDRCantidad > 0){
                let servicio = {'title': 'Servicio', 'dataIndex': 'servicio', 'key': 'servicio', 'align': 'center', 'width': '30%' }; columnsSaga.push(servicio);

                for(let j in reporteResumenCalculoDR[0].graficaResponseList){
                    let tipoFalla = reporteResumenCalculoDR[0].graficaResponseList[j].etiqueta.replace(/\s+/g, '');
                    let column =
                        {
                            'title': reporteResumenCalculoDR[0].graficaResponseList[j].etiqueta,
                            'dataIndex': tipoFalla,
                            'key': tipoFalla,
                            'align': 'right'
                        }
                    columnsSaga.push(column);
                }

                let cantidadTickets = { title: 'Cantidad de tickets', dataIndex: 'foliosByServicio', key: 'foliosByServicio',  align: 'right', width: '7%' }; columnsSaga.push(cantidadTickets);

                let sumaAcumulada = { title: 'Total', dataIndex: 'cantidadTabla', key: 'cantidadTabla', align: 'right' }; columnsSaga.push(sumaAcumulada);

                for(let i in reporteResumenCalculoDR){
                    reporteResumenCalculoDR[i].mes = action.mesCalendario;
                    reporteResumenCalculoDR[i].idResumenServicio = i;
                    let tipoFalla = '';
                    //PARA CADA TIPO DE FALLA
                    for(let j in reporteResumenCalculoDR[i].graficaResponseList){
                        let deductiva = (reporteResumenCalculoDR[i].graficaResponseList[j].cantidad).toFixed(2);
                        function numberWithCommas(x) {
                            let parts = x.toString().split(".");
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            return parts.join(".");
                        }

                        let deductivaFxd = numberWithCommas(deductiva);

                        if(!deductiva.includes('.')){ deductivaFxd = deductiva + '.00'; }
                        tipoFalla = reporteResumenCalculoDR[i].graficaResponseList[j].etiqueta.replace(/\s+/g, '');
                        reporteResumenCalculoDR[i][tipoFalla] = deductivaFxd;
                    }

                    if(reporteResumenCalculoDR[i].deductivaAcumulada){
                        let cant = reporteResumenCalculoDR[i].deductivaAcumulada.toLocaleString('en-US', {'maximumFractionDigits': 2})
                        if(cant.includes('.')){
                            reporteResumenCalculoDR[i].cantidadTabla = cant;
                        } else {
                            reporteResumenCalculoDR[i].cantidadTabla = cant + '.00';
                        }

                    } else { reporteResumenCalculoDR[i].cantidadTabla = '0.00' }
                }
            }
            reporteResumenCalculoDR.columns = columnsSaga;

            yield put({ type: mecanismosDePagosActions.REPORTE_RESUMEN_CALCULO_DEDUCTIVA_REITERACION_SUCCESS, reporteResumenCalculoDR, reporteResumenCalculoDRCantidad })
        } else {
            yield put({ type: mecanismosDePagosActions.REPORTE_RESUMEN_CALCULO_DEDUCTIVA_REITERACION_FAILURE })
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.REPORTE_RESUMEN_CALCULO_DEDUCTIVA_REITERACION_FAILURE})
    }
} export function* reporteResumenCalculoDeductivaReiteracionSaga(){
    yield takeLatest(mecanismosDePagosActions.REPORTE_RESUMEN_CALCULO_DEDUCTIVA_REITERACION_REQUEST, reporteResumenCalculoDeductivaReiteracion)
}

function* deductivaReiteracionResumenExcel(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idMesCalendario = '';
        let fechaProyeccion = action.fechaProyeccion;
        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario; }

        let response = yield call(deductivaReiteracionResumenExcelCall, token, fechaProyeccion, idMesCalendario)

        console.log('response', response)

        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            let dia = new Date().getDate();
            if(dia < 10){ dia = '0' + dia }
            let mes = new Date().getMonth() + 1;
            if(mes < 10){ mes = '0' + mes }

            link.download = "DeductivaReiteracionResumen_" + dia + "-" + mes + "-" + new Date().getFullYear() + "_" + new Date().getHours() + "" + new Date().getMinutes() + "" + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: mecanismosDePagosActions.EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_REITERACION_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el documento.";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_REITERACION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_REITERACION_FAILURE });
    }
}
export function* deductivaReiteracionResumenExcelSaga() {
    yield takeLatest(mecanismosDePagosActions.EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_REITERACION_REQUEST, deductivaReiteracionResumenExcel);
}

function* listaDetalleCalculoDeductivasReiteracion(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idMesCalendario = ''; let idTipoFalla = ''; let idAreaFuncional = ''; let idGrupoSer = ''; let idServicio = ''; let fechaCierre1 = ''; let fechaCierre2 = '';
        let fechaProyeccion = action.fechaProyeccion;
        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario; }
        if(action.idTipoFalla){ idTipoFalla = '&idTipoFalla=' + action.idTipoFalla; }
        if(action.idAreaFuncional){ idAreaFuncional = '&idAreaFuncional=' + action.idAreaFuncional; }
        if(action.idGrupoSer){ idGrupoSer = '&idGrupoSer=' + action.idGrupoSer; }
        if(action.idServicio){ idServicio = '&idServicio=' + action.idServicio; }
        if(action.fechaCierre1){ fechaCierre1 = '&fechaCierre1=' + action.fechaCierre1; }
        if(action.fechaCierre2){ fechaCierre2 = '&fechaCierre2=' + action.fechaCierre2; }

        let response = yield call(listaDetalleCalculoDeductivasReiteracionCall, token, fechaProyeccion, idMesCalendario, idTipoFalla, idAreaFuncional, idGrupoSer, idServicio, fechaCierre1, fechaCierre2 )
        console.log('response ', response)

        if(response.status === 200){
            let listaDetalleCalculoReiteracion = response.data.data;
            let listaDetalleCalculoReiteracionCantidad = response.data.cantidad;

            for(let i in listaDetalleCalculoReiteracion){
                if(listaDetalleCalculoReiteracion[i].solicitudByIdSolicitudServicio){
                    listaDetalleCalculoReiteracion[i].ticket = listaDetalleCalculoReiteracion[i].solicitudByIdSolicitudServicio.folio;

                    listaDetalleCalculoReiteracion[i].origen = listaDetalleCalculoReiteracion[i].solicitudByIdSolicitudServicio.nombreUsuario;

                    listaDetalleCalculoReiteracion[i].descripcionSolicitudBySolicitud = listaDetalleCalculoReiteracion[i].solicitudByIdSolicitudServicio.descripcionSolicitud;

                    if(listaDetalleCalculoReiteracion[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra){
                        if(listaDetalleCalculoReiteracion[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.aMaterno === null || listaDetalleCalculoReiteracion[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.aMaterno === ""){
                            listaDetalleCalculoReiteracion[i].personalCCO = listaDetalleCalculoReiteracion[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.nombre + ' ' + listaDetalleCalculoReiteracion[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.aPaterno;
                        } else {
                            listaDetalleCalculoReiteracion[i].personalCCO = listaDetalleCalculoReiteracion[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.nombre + ' ' + listaDetalleCalculoReiteracion[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.aPaterno + ' ' + listaDetalleCalculoReiteracion[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.aMaterno;;
                        }
                    }
                } 
                else {
                    listaDetalleCalculoReiteracion[i].ticket = listaDetalleCalculoReiteracion[i].folioOrigen;
                    listaDetalleCalculoReiteracion[i].origen = '-';
                    listaDetalleCalculoReiteracion[i].personalCCO = '-';
                }

                listaDetalleCalculoReiteracion[i].fechaAltaTabla = listaDetalleCalculoReiteracion[i].fechaAlta.split('T')[0].split('-').reverse().join('-') + ' ' + listaDetalleCalculoReiteracion[i].fechaAlta.split('T')[1].split(':')[0] + ':' + listaDetalleCalculoReiteracion[i].fechaAlta.split('T')[1].split(':')[1];

                listaDetalleCalculoReiteracion[i].fechaCierreTabla = listaDetalleCalculoReiteracion[i].fechaCierre.split('T')[0].split('-').reverse().join('-') + ' ' + listaDetalleCalculoReiteracion[i].fechaCierre.split('T')[1].split(':')[0] + ':' + listaDetalleCalculoReiteracion[i].fechaCierre.split('T')[1].split(':')[1];

                listaDetalleCalculoReiteracion[i].pmiTabla = listaDetalleCalculoReiteracion[i].pmi.toLocaleString('en-US', {'maximumFractionDigits': 2});

                listaDetalleCalculoReiteracion[i].pmrTabla = listaDetalleCalculoReiteracion[i].pmr.toLocaleString('en-US', {'maximumFractionDigits': 2});
                listaDetalleCalculoReiteracion[i].deductivaTabla = listaDetalleCalculoReiteracion[i].deductiva.toLocaleString('en-US', {'maximumFractionDigits': 2});

                if(listaDetalleCalculoReiteracion[i].deductivaParcial){
                    listaDetalleCalculoReiteracion[i].deductivaParcialTexto = 'Sí'
                } else { listaDetalleCalculoReiteracion[i].deductivaParcialTexto = 'No' }

                if(listaDetalleCalculoReiteracion[i].fallaCalidad){
                    listaDetalleCalculoReiteracion[i].fallaCalidadTabla = listaDetalleCalculoReiteracion[i].fallaCalidad;
                } else { listaDetalleCalculoReiteracion[i].fallaCalidadTabla = '-' }

                if(listaDetalleCalculoReiteracion[i].fsa){
                    listaDetalleCalculoReiteracion[i].fsaTabla = listaDetalleCalculoReiteracion[i].fsa.toFixed(2);
                } else { listaDetalleCalculoReiteracion[i].fsaTabla = '-' }

                if(listaDetalleCalculoReiteracion[i].ps){
                    listaDetalleCalculoReiteracion[i].psTabla = listaDetalleCalculoReiteracion[i].ps.toFixed(2) + '%';
                } else { listaDetalleCalculoReiteracion[i].psTabla = '-' }

                if(listaDetalleCalculoReiteracion[i].pcfc){
                    listaDetalleCalculoReiteracion[i].pcfcTabla = listaDetalleCalculoReiteracion[i].pcfc.toFixed(2) + '%';
                } else { listaDetalleCalculoReiteracion[i].pcfcTabla = '-' }

                if(listaDetalleCalculoReiteracion[i].psa){
                    listaDetalleCalculoReiteracion[i].psaTabla = listaDetalleCalculoReiteracion[i].psa.toFixed(2) + '%';
                } else { listaDetalleCalculoReiteracion[i].psaTabla = '-' }

                if(listaDetalleCalculoReiteracion[i].ponderacionServArea >= 0){
                    console.log('Entra al if ponderacionServArea')
                    listaDetalleCalculoReiteracion[i].ponderacionServAreaTabla = listaDetalleCalculoReiteracion[i].ponderacionServArea.toFixed(2) + '%';;
                } else { listaDetalleCalculoReiteracion[i].ponderacionServAreaTabla = '-' }

                if(listaDetalleCalculoReiteracion[i].ticketReiterado){
                    listaDetalleCalculoReiteracion[i].ticketReiteradoTabla = listaDetalleCalculoReiteracion[i].ticketReiterado;
                } else { listaDetalleCalculoReiteracion[i].ticketReiteradoTabla = '-' }

                if(listaDetalleCalculoReiteracion[i].numReferencia){
                    listaDetalleCalculoReiteracion[i].numReferenciaTabla = listaDetalleCalculoReiteracion[i].numReferencia;
                } else { listaDetalleCalculoReiteracion[i].numReferenciaTabla = '-' }

                if(listaDetalleCalculoReiteracion[i].tiempoGarantiaByIdSolicitudServicio){
                    let tg = '';
                    tg = listaDetalleCalculoReiteracion[i].tiempoGarantiaByIdSolicitudServicio.tiempoGarantia;
                    if(listaDetalleCalculoReiteracion[i].tiempoGarantiaByIdSolicitudServicio.unidadesMedidasByIdUnidadMedida){
                        listaDetalleCalculoReiteracion[i].tiempoGarantiaTabla = tg + ' ' + listaDetalleCalculoReiteracion[i].tiempoGarantiaByIdSolicitudServicio.unidadesMedidasByIdUnidadMedida.unidadMedida;
                    }
                } else { listaDetalleCalculoReiteracion[i].tiempoGarantiaTabla = '-' }

                if(listaDetalleCalculoReiteracion[i].ut){
                    listaDetalleCalculoReiteracion[i].utTabla = listaDetalleCalculoReiteracion[i].ut;
                } else { listaDetalleCalculoReiteracion[i].utTabla = '-' }

                if(listaDetalleCalculoReiteracion[i].utf){
                    listaDetalleCalculoReiteracion[i].utfTabla = listaDetalleCalculoReiteracion[i].utf;
                } else { listaDetalleCalculoReiteracion[i].utfTabla = '-' }

                if(listaDetalleCalculoReiteracion[i].pdfd){
                    listaDetalleCalculoReiteracion[i].pdfdTabla = listaDetalleCalculoReiteracion[i].pdfd.toFixed(2) + '%';
                } else { listaDetalleCalculoReiteracion[i].pdfdTabla = '-' }

                if(listaDetalleCalculoReiteracion[i].fallaDisponibilidad){
                    listaDetalleCalculoReiteracion[i].fallaDisponibilidadTabla = listaDetalleCalculoReiteracion[i].fallaDisponibilidad;
                } else { listaDetalleCalculoReiteracion[i].fallaDisponibilidadTabla = '-' }

                if(listaDetalleCalculoReiteracion[i].tipoDeductiva === 'Disponibilidad'){
                    listaDetalleCalculoReiteracion[i].referenciaEstandarTabla = '-';
                    listaDetalleCalculoReiteracion[i].descripcionEstandarTabla = '-';
                } else {
                    listaDetalleCalculoReiteracion[i].referenciaEstandarTabla = listaDetalleCalculoReiteracion[i].referenciaEstandar;
                    listaDetalleCalculoReiteracion[i].descripcionEstandarTabla = listaDetalleCalculoReiteracion[i].descripcionEstandar;
                }

                if(listaDetalleCalculoReiteracion[i].numeroFallaCategoria){
                    listaDetalleCalculoReiteracion[i].numeroFallaCategoriaTabla = listaDetalleCalculoReiteracion[i].numeroFallaCategoria;
                } else { listaDetalleCalculoReiteracion[i].numeroFallaCategoriaTabla = '-'; }

                //PENDIENTE
                listaDetalleCalculoReiteracion[i].fechaCierreMesTabla = '-'
            }
            console.table(listaDetalleCalculoReiteracion);
            yield put({ type: mecanismosDePagosActions.LISTA_DETALLE_CALCULO_REITERACION_SUCCESS, listaDetalleCalculoReiteracion, listaDetalleCalculoReiteracionCantidad })
        } else {
            yield put({ type: mecanismosDePagosActions.LISTA_DETALLE_CALCULO_REITERACION_FAILURE})
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.LISTA_DETALLE_CALCULO_REITERACION_FAILURE})
    }
} export function* listaDetalleCalculoDeductivasReiteracionSaga(){
    yield takeLatest(mecanismosDePagosActions.LISTA_DETALLE_CALCULO_REITERACION_REQUEST, listaDetalleCalculoDeductivasReiteracion)
}

function* detalleDeductivaReiteracionExcel(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idMesCalendario = '';
        let fechaProyeccion = action.fechaProyeccion;
        let idTipoFalla = ''; let idAreaFuncional = ''; let idGrupoSer = ''; let idServicio = ''; let fechaCierre1 = ''; let fechaCierre2 = '';

        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario; }
        if(action.idTipoFalla){ idTipoFalla = '&idTipoFalla=' + action.idTipoFalla; }
        if(action.idAreaFuncional){ idAreaFuncional = '&idAreaFuncional=' + action.idAreaFuncional; }
        if(action.idGrupoSer){ idGrupoSer = '&idGrupoSer=' + action.idGrupoSer; }
        if(action.idServicio){ idServicio = '&idServicio=' + action.idServicio; }
        if(action.fechaCierre1){ fechaCierre1 = '&fechaCierre1=' + action.fechaCierre1; }
        if(action.fechaCierre2){ fechaCierre2 = '&fechaCierre2=' + action.fechaCierre2; }

        let response = yield call(detalleDeductivaReiteracionExcelCall, token, fechaProyeccion, idMesCalendario, idTipoFalla, idAreaFuncional, idGrupoSer, idServicio, fechaCierre1, fechaCierre2)

        console.log('response', response)

        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            let dia = new Date().getDate();
            if(dia < 10){ dia = '0' + dia }
            let mes = new Date().getMonth() + 1;
            if(mes < 10){ mes = '0' + mes }

            link.download = "DeductivaReiteracion_" + dia + "-" + mes + "-" + new Date().getFullYear() + "_" + new Date().getHours() + "" + new Date().getMinutes() + "" + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: mecanismosDePagosActions.EXPORTAR_EXCEL_DETALLE_CALCULO_REITERACION_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el documento.";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EXPORTAR_EXCEL_DETALLE_CALCULO_REITERACION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.EXPORTAR_EXCEL_DETALLE_CALCULO_REITERACION_FAILURE });
    }
}
export function* detalleDeductivaReiteracionExcelSaga() {
    yield takeLatest(mecanismosDePagosActions.EXPORTAR_EXCEL_DETALLE_CALCULO_REITERACION_REQUEST, detalleDeductivaReiteracionExcel);
}

function* procesarDeductivaReiteracion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let idMesCalendario = '';
        let fechaProyeccion = action.fechaProyeccion;

        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario; }

        let response = yield call(procesarDeductivaReiteracionCall, token, fechaProyeccion, idMesCalendario)

        console.log('response', response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = 'El cálculo fue procesado con éxito';

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.PROCESAR_DEDUCTIVA_REITERACION_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = "No fue posible procesar el cálculo";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.PROCESAR_DEDUCTIVA_REITERACION_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.PROCESAR_DEDUCTIVA_REITERACION_FAILURE });
    }
}
export function* procesarDeductivaReiteracionSaga() {
    yield takeLatest(mecanismosDePagosActions.PROCESAR_DEDUCTIVA_REITERACION_REQUEST, procesarDeductivaReiteracion);
}

function* deductivaTicketsNoConciliados(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idMesCalendario = action.idMesCalendario;
        const page = action.page - 1;

        const response = yield call(deductivaTicketsNoConciliadosCall,token, idMesCalendario, page);
        console.log('response no conciliados', response);

        if (response.status === 200) {
            let listaTicketsNoConciliados = response.data;

            yield put({ type: "DEDUCTIVA_TICKETS_NO_CONCILIADOS_SUCCESS", listaTicketsNoConciliados})
        } else {
            yield put ({ type: "DEDUCTIVA_TICKETS_NO_CONCILIADOS_FAILURE"})
        }
    } catch (error) {
        yield put ({ type: "DEDUCTIVA_TICKETS_NO_CONCILIADOS_FAILURE"})
    }
} export function* deductivaTicketsNoConciliadosSaga() {
    yield takeLatest(mecanismosDePagosActions.DEDUCTIVA_TICKETS_NO_CONCILIADOS_REQUEST, deductivaTicketsNoConciliados )
}

//Folio de Conciliación
function* listaFoliosConciliacion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const {busqueda=""} = action;

        const response = yield call(busquedaFoliosConciliacionCall, token, busqueda, page);
        console.log(response);

        if (response.status == 200) {
            let listaFoliosConciliacion = response.data.data;
            const listaFolicosConciliacionCantidad = response.data.cantidad;

            for (let folio of listaFoliosConciliacion) {
                folio.participantes = folio.nombresValidacion ?  folio.nombresValidacion.split(',') : [];
                folio.anio = folio.anioCalendarioByIdAnioCalendario.anio;
                folio.mes = folio.mesesByIdMes.mes;
                folio.fechaAlta = folio.fechaAlta.split('T')[0].split('-').reverse().join('-');
            }
            yield put({ type: mecanismosDePagosActions.FOLIO_CONCILIACION_BUSQUEDA_SUCCESS, listaFoliosConciliacion, listaFolicosConciliacionCantidad });
        }
        else {
            yield put({ type: mecanismosDePagosActions.FOLIO_CONCILIACION_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.FOLIO_CONCILIACION_BUSQUEDA_FAILURE });
    }
}
export function* listaFoliosConciliacionSaga() {
    yield takeLatest(mecanismosDePagosActions.FOLIO_CONCILIACION_BUSQUEDA_REQUEST, listaFoliosConciliacion);
}

function* listaMesesConciliacion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaMesesConciliacionCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaMesesFolioConciliacion = response.data;
            yield put({ type: mecanismosDePagosActions.MES_FOLIO_CONCILIAR_SUCCESS, listaMesesFolioConciliacion });
        }
        else {
            yield put({ type: mecanismosDePagosActions.MES_FOLIO_CONCILIAR_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.MES_FOLIO_CONCILIAR_FAILURE });
    }
}
export function* listaMesesConciliacionSaga() {
    yield takeLatest(mecanismosDePagosActions.MES_FOLIO_CONCILIAR_REQUEST, listaMesesConciliacion);
}

function* listaTicketsConciliar(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const fechaInicio = action.rangoFecha[0].format('YYYY-MM-DD');
        const fechaFin = action.rangoFecha[1].format('YYYY-MM-DD');
        const idServicio = action.idServicio ? parseInt(action.idServicio) : null;

        const response = yield call(listaTicketsConciliarCall, token, fechaInicio, fechaFin, idServicio, page);
        console.log(response);

        if (response.status == 200) {
            let listaSSByServicio = response.data.data;
            const listaSSByServicioCantidad = response.data.cantidad;
            const listaSSCantidad = idServicio ? action.listaSSCantidad : response.data.cantidad;

            for (let ticket of listaSSByServicio) {
                ticket.nombreServicio = ticket.servicioByIdServicio.nombreC;
                ticket.descripcionFalla = ticket.fallaByIdFalla.nombre;
                ticket.deductiva = ticket.deductiva.toFixed(2);
                ticket.fechaAltaHora = ticket.fechaAlta && ticket.fechaAlta.split('T')[0].split('-').reverse().join('-') + '  ' + ticket.fechaAlta.split('T')[1].split('.')[0];
                ticket.fechaTerminacionHora = ticket.fechaTerminacion && ticket.fechaTerminacion.split('T')[0].split('-').reverse().join('-') + '  ' + ticket.fechaTerminacion.split('T')[1].split('.')[0];
                ticket.fechaCierreHora = ticket.fechaCierre && ticket.fechaCierre.split('T')[0].split('-').reverse().join('-') + '  ' + ticket.fechaCierre.split('T')[1].split('.')[0];
            }

            yield put({ type: mecanismosDePagosActions.SS_BY_SERVICIO_SUCCESS, listaSSByServicio, listaSSCantidad, listaSSByServicioCantidad });
        }
        else {
            yield put({ type: mecanismosDePagosActions.SS_BY_SERVICIO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.SS_BY_SERVICIO_FAILURE });
    }
}
export function* listaTicketsConciliarSaga() {
    yield takeLatest(mecanismosDePagosActions.SS_BY_SERVICIO_REQUEST, listaTicketsConciliar);
}

function* listaServiciosConciliacion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const fechaInicio = action.rangoFecha[0].format('YYYY-MM-DD');
        const fechaFin = action.rangoFecha[1].format('YYYY-MM-DD');

        const response = yield call(listaServiciosConciliacionCall, token, fechaInicio, fechaFin);
        console.log(response);

        if (response.status == 200) {
            const listaServiciosFC = response.data;
            yield put({ type: mecanismosDePagosActions.SERVICIOS_FOLIO_CONCILIACION_SUCCESS, listaServiciosFC });
        }
        else {
            yield put({ type: mecanismosDePagosActions.SERVICIOS_FOLIO_CONCILIACION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.SERVICIOS_FOLIO_CONCILIACION_FAILURE });
    }
}
export function* listaServiciosConciliacionSaga() {
    yield takeLatest(mecanismosDePagosActions.SERVICIOS_FOLIO_CONCILIACION_REQUEST, listaServiciosConciliacion);
}

function* crearFolioConciliacion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {mes=1,idsSolicitudes=[],page=1, busqueda=""} = action;

        const data = {
            "idFolioConciliacion": null,
            "idAnioCalendario": null,
            "idMes": mes,
            "folioConciliacion": null,
            "nombresValidacion": null,
            "numeroTickets": idsSolicitudes.length,
            "fechaConciliacion": null,
            "estatus": null,
            "fechaCierre": null,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "rutaArchivo": null,
            "listaIdSolicitudesServicio":idsSolicitudes
        }

        const response = yield call(crearFolioConciliacionCall, token, data);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El folio de conciliación fue creado con éxito.";
            const showModalNuevoFolioConciliacion = false;

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'CNF'});
            yield put({ type: mecanismosDePagosActions.NUEVO_FOLIO_CONCILIACION_SUCCESS });
            yield put({ type: mecanismosDePagosActions.FOLIO_CONCILIACION_BUSQUEDA_REQUEST, page , busqueda});
            yield put({ type: mecanismosDePagosActions.MODAL_NUEVO_FOLIO_CONCILIACION, showModalNuevoFolioConciliacion, page , busqueda});
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.NUEVO_FOLIO_CONCILIACION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.NUEVO_FOLIO_CONCILIACION_FAILURE });
    }
}
export function* crearFolioConciliacionSaga() {
    yield takeLatest(mecanismosDePagosActions.NUEVO_FOLIO_CONCILIACION_REQUEST, crearFolioConciliacion);
}

function* descargarPDFFolioConciliacion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {idFolioConciliacion=0} = action;

        const response = yield call(descargarFolioConciliacionCall, token, idFolioConciliacion);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "Se descargó archivo PDF de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "FolioConciliacion" + idFolioConciliacion + '-' + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.IMPRIMIR_FOLIO_CONCILIACION_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.IMPRIMIR_FOLIO_CONCILIACION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.IMPRIMIR_FOLIO_CONCILIACION_FAILURE });
    }
}
export function* descargarPDFFolioConciliacionSaga() {
    yield takeLatest(mecanismosDePagosActions.IMPRIMIR_FOLIO_CONCILIACION_REQUEST, descargarPDFFolioConciliacion);
}

function* listaTicketsByFolio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const {idFolioConciliacion} = action;

        const response = yield call(listaTicketsbyFolioCall, token, idFolioConciliacion, page);
        console.log(response);

        if (response.status == 200) {
            let listaSSByFolio = response.data.data;
            const listaSSByFolioCantidad = response.data.cantidad;

            for (let ticket of listaSSByFolio) {
                ticket.folio = ticket.solicitudesServicioByIdSolicitudServicio.folio;
                ticket.deductiva = ticket.solicitudesServicioByIdSolicitudServicio.deductiva.toFixed(2);
                ticket.nombreServicio = ticket.serviciosByIdSolicitudServicio.nombreC;
                ticket.descripcionFalla = ticket.fallasByIdSolicitudServicio.nombre;
                ticket.fechaAltaHora =  ticket.solicitudesServicioByIdSolicitudServicio.fechaAlta &&  ticket.solicitudesServicioByIdSolicitudServicio.fechaAlta.split('T')[0].split('-').reverse().join('-') + '  ' +  ticket.solicitudesServicioByIdSolicitudServicio.fechaAlta.split('T')[1].split('.')[0];
                ticket.fechaTerminacionHora = ticket.solicitudesServicioByIdSolicitudServicio.fechaTerminacion && ticket.solicitudesServicioByIdSolicitudServicio.fechaTerminacion.split('T')[0].split('-').reverse().join('-') + '  ' + ticket.solicitudesServicioByIdSolicitudServicio.fechaTerminacion.split('T')[1].split('.')[0];
                ticket.fechaCierreHora = ticket.solicitudesServicioByIdSolicitudServicio.fechaCierre && ticket.solicitudesServicioByIdSolicitudServicio.fechaCierre.split('T')[0].split('-').reverse().join('-') + '  ' + ticket.solicitudesServicioByIdSolicitudServicio.fechaCierre.split('T')[1].split('.')[0];
                ticket.descripcionSolicitud = ticket.solicitudesServicioByIdSolicitudServicio.descripcionSolicitud;
            }

            yield put({ type: mecanismosDePagosActions.SS_BY_FOLIO_SUCCESS, listaSSByFolio, listaSSByFolioCantidad });
        }
        else {
            yield put({ type: mecanismosDePagosActions.SS_BY_FOLIO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.SS_BY_FOLIO_FAILURE });
    }
}
export function* listaTicketsByFolioSaga() {
    yield takeLatest(mecanismosDePagosActions.SS_BY_FOLIO_REQUEST, listaTicketsByFolio);
}

function* eliminarTicketFC(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page;
        const {idFolioConciliacion, idSolicitudesServicioFolioConciliacion} = action;

        const response = yield call(eliminarTicketFCCall, token, idSolicitudesServicioFolioConciliacion);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El ticket fue eliminado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.ELIMINAR_SS_FC_SUCCESS });
            yield put({ type: mecanismosDePagosActions.SS_BY_FOLIO_REQUEST, idFolioConciliacion, page });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.ELIMINAR_SS_FC_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.ELIMINAR_SS_FC_FAILURE });
    }
}
export function* eliminarTicketFCSaga() {
    yield takeLatest(mecanismosDePagosActions.ELIMINAR_SS_FC_REQUEST, eliminarTicketFC);
}

function* editarFolioConciliacion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {nombresValidacion="",folioConciliacionSeleccionado=null, numeroTickets=0, page=1, busqueda=""} = action;
        const {idFolioConciliacion, idAnioCalendario,idMes, folioConciliacion} = folioConciliacionSeleccionado;

        const data =  {
            "idFolioConciliacion": idFolioConciliacion,
            "idAnioCalendario": idAnioCalendario,
            "idMes": idMes,
            "folioConciliacion": folioConciliacion,
            "nombresValidacion": nombresValidacion,
            "numeroTickets": numeroTickets,
            "fechaConciliacion": null,
            "estatus": true,
            "fechaCierre": null,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "rutaArchivo": null
        }

        const response = yield call(editarFolioConciliacionCall, token, idFolioConciliacion, data);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El folio de conciliación fue editado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'EFC'});
            yield put({ type: mecanismosDePagosActions.EDITAR_FOLIO_CONCILIACION_SUCCESS });
            yield put({ type: mecanismosDePagosActions.FOLIO_CONCILIACION_BUSQUEDA_REQUEST, page , busqueda});
            yield put({ type: mecanismosDePagosActions.MODAL_EDITAR_FOLIO_CONCILIACION, showModalEditarFolioConciliacion: false, folioConciliacionSeleccionado: null, page:page, busqueda: busqueda, listaSSByFolio:[]});
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EDITAR_FOLIO_CONCILIACION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.EDITAR_FOLIO_CONCILIACION_FAILURE });
    }
}
export function* editarFolioConciliacionSaga() {
    yield takeLatest(mecanismosDePagosActions.EDITAR_FOLIO_CONCILIACION_REQUEST, editarFolioConciliacion);
}

function* cerrarFolioConciliacion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {formulario={}, folioConciliacionSeleccionado=null, page=1, busqueda=""} = action;
        const {idFolioConciliacion} = folioConciliacionSeleccionado;
        const fechaConciliacion = formulario.fechaConciliacion.format('YYYY-MM-DDTHH:mm');

        const data =  {
            "idFolioConciliacion": idFolioConciliacion,
            "idAnioCalendario": null,
            "idMes": null,
            "folioConciliacion": null,
            "nombresValidacion": null,
            "numeroTickets": null,
            "fechaConciliacion": fechaConciliacion,
            "estatus": true,
            "fechaCierre": null,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "rutaArchivo": null
        }
        console.log(data);
        const formData = new FormData();
        formData.append('file', formulario.pdf.file.originFileObj);
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        console.log(formData);
        const response = yield call(cerrarFolioConciliacionCall, token, idFolioConciliacion, formData);
        // const response = {status:200}
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El folio de conciliación fue cerrado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'CCFC'});
            yield put({ type: mecanismosDePagosActions.CERRAR_FOLIO_CONCILIACION_SUCCESS });
            yield put({ type: mecanismosDePagosActions.FOLIO_CONCILIACION_BUSQUEDA_REQUEST, page , busqueda});
            yield put({ type: mecanismosDePagosActions.MODAL_CERRAR_FOLIO_CONCILIACION, showModalCerrarFolioConciliacion: false, folioConciliacionSeleccionado: null, page:page, busqueda: busqueda});
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.CERRAR_FOLIO_CONCILIACION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.CERRAR_FOLIO_CONCILIACION_FAILURE });
    }
}
export function* cerrarFolioConciliacionSaga() {
    yield takeLatest(mecanismosDePagosActions.CERRAR_FOLIO_CONCILIACION_REQUEST, cerrarFolioConciliacion);
}

// Conciliacion
function* busquedaTicketSS(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let busqueda = 'folio=';
        if(action.busqueda) {
            busqueda += action.busqueda
        }

        const response = yield call(busquedaTicketSSCall, token, busqueda);
        console.log('response busqueda ticket', response);

        if (response.status == 200) {
            let busquedaTicketSS = response.data.data;
            let busquedaTicketSSobj = {}

            if(busquedaTicketSS[0]){
                for(let i in busquedaTicketSS) {
                    busquedaTicketSSobj.idSolicitudServicio = busquedaTicketSS[i].idSolicitudServicio
                    busquedaTicketSSobj.idUsuario = busquedaTicketSS[i].idUsuarioRegistra
                    busquedaTicketSSobj.descripcionSolicitud = busquedaTicketSS[i].descripcionSolicitud
                    busquedaTicketSSobj.idServicio = busquedaTicketSS[i].idServicio


                    busquedaTicketSS[i].fechaCierre = moment(busquedaTicketSS[i].fechaCierre.split('T')[0]+ " " + busquedaTicketSS[i].fechaCierre.split('T')[1].split(':')[0] + ":" + busquedaTicketSS[i].fechaCierre.split('T')[1].split(':')[1], 'YYYY-MM-DD HH:mm');

                    busquedaTicketSS[i].fechaAltaFormato = moment(busquedaTicketSS[i].fechaAlta.split('T')[0] + " " + busquedaTicketSS[i].fechaAlta.split('T')[1].split(':')[0] + ":" + busquedaTicketSS[i].fechaAlta.split('T')[1].split(':')[1], 'YYYY-MM-DD HH:mm');

                    busquedaTicketSSobj.fechaCierreEditar = moment(busquedaTicketSS[i].fechaCierre, 'YYYY-MM-DD HH:mm')
                    busquedaTicketSSobj.fechaAltaFormato = moment(busquedaTicketSS[i].fechaAltaFormato, 'YYYY-MM-DD HH:mm')

                    if(busquedaTicketSS[i].fallaByIdFalla) {
                        busquedaTicketSS[i].falla = busquedaTicketSS[i].fallaByIdFalla.idFalla + '-' + busquedaTicketSS[i].fallaByIdFalla.nombre;
                        busquedaTicketSSobj.nombreFalla = busquedaTicketSS[i].fallaByIdFalla.idFalla + '-' + busquedaTicketSS[i].fallaByIdFalla.nombre + ': ' + busquedaTicketSS[i].fallaByIdFalla.estandarByIdEstandar.idServSeccionEspecifico;
                        if(busquedaTicketSS[i].fallaByIdFalla.estandarByIdEstandar){
                            if(busquedaTicketSS[i].fallaByIdFalla.estandarByIdEstandar.idServSeccionEspecifico === 1) {
                                busquedaTicketSSobj.habilitarSeveridad = true;
                                busquedaTicketSS[i].habilitarSeveridad = true;
                            }
                        }
                    }

                    if(busquedaTicketSS[i].fallaByIdFalla.tipoFallaByIdTipoFalla) {
                        if(busquedaTicketSS[i].fallaByIdFalla.idTipoFalla === 1){
                            busquedaTicketSSobj.fallaCalidad = true;
                        }
                        busquedaTicketSSobj.idTipoFalla = busquedaTicketSS[i].fallaByIdFalla.tipoFallaByIdTipoFalla.idTipoFalla
                        busquedaTicketSS[i].tipoFalla = busquedaTicketSS[i].fallaByIdFalla.tipoFallaByIdTipoFalla.nombre
                        busquedaTicketSSobj.tipoFalla = busquedaTicketSS[i].fallaByIdFalla.tipoFallaByIdTipoFalla.idTipoFalla+' - '+busquedaTicketSS[i].fallaByIdFalla.tipoFallaByIdTipoFalla.nombre
                    }

                    if(busquedaTicketSS[i].nivelSeveridadByIdNivelSeveridad) {
                        busquedaTicketSS[i].nivelSeveridad = busquedaTicketSS[i].nivelSeveridadByIdNivelSeveridad.idNivelSeveridad + '-' + busquedaTicketSS[i].nivelSeveridadByIdNivelSeveridad.descripcion
                        busquedaTicketSSobj.nivelSeveridad = busquedaTicketSS[i].nivelSeveridadByIdNivelSeveridad.idNivelSeveridad + ' - ' + busquedaTicketSS[i].nivelSeveridadByIdNivelSeveridad.descripcion
                    }

                    if(busquedaTicketSS[i].queja === null || busquedaTicketSS[i].queja === 'Prorroga') {
                        busquedaTicketSS[i].clasificacionTicket = "Solicitud de Servicio"
                        busquedaTicketSSobj.clasificacionTicket = "Solicitud de Servicio"
                    } else {
                        busquedaTicketSS[i].clasificacionTicket = busquedaTicketSS[i].queja
                        busquedaTicketSSobj.clasificacionTicket = busquedaTicketSS[i].queja
                    }

                    if(busquedaTicketSS[i].fechaConciliacion) {
                        busquedaTicketSS[i].fechaConciliacion = moment(busquedaTicketSS[i].fechaConciliacion.split('T')[0], 'YYYY-MM-DD HH:mm')
                        busquedaTicketSSobj.fechaConciliacionEditar = moment(busquedaTicketSS[i].fechaConciliacion, 'YYYY-MM-DD HH:mm')
                    } else {
                        busquedaTicketSS[i].fechaConciliacion = 'No tiene fecha de conciliacion'
                        busquedaTicketSSobj.fechaConciliacionEditar = null
                    }
                }
            }
            else{
                yield put({ type: configuracionYSeguridadActions.SHOW_WARNING_MSG, showWarningMsg: true, textMessage: 'El ticket no existe o ya fue conciliado' });
            }

            console.log('busquedaTicketSSobj', busquedaTicketSSobj)

            yield put({ type: mecanismosDePagosActions.FALLAS_POR_SERVICIO_REQUEST, idServicio: busquedaTicketSSobj.idServicio });
            yield put({ type: mecanismosDePagosActions.BUSQUEDA_FOLIO_TICKET_SS_SUCCESS, busquedaTicketSS, busquedaTicketSSobj });
        }
        else {

            yield put({ type: mecanismosDePagosActions.BUSQUEDA_FOLIO_TICKET_SS_FAILURE });
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.BUSQUEDA_FOLIO_TICKET_SS_FAILURE });
    }
}
export function* busquedaTicketSSSaga() {
    yield takeLatest(mecanismosDePagosActions.BUSQUEDA_FOLIO_TICKET_SS_REQUEST, busquedaTicketSS);
}

function* fallasPorTipoFalla(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idTipoFalla = action.idTipoFalla;
        let param = '';

        if(action.param) {
            param = action.param
        }

        const response = yield call(fallasPorTipoFallaCall, token, idTipoFalla, param);
        console.log(response);

        if (response.status == 200) {
            let fallasPorTipoFalla = response.data;
            yield put({ type: mecanismosDePagosActions.FALLAS_POR_TIPO_DE_FALLA_SUCCESS, fallasPorTipoFalla });
        }
        else {
            yield put({ type: mecanismosDePagosActions.FALLAS_POR_TIPO_DE_FALLA_FAILURE });
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.FALLAS_POR_TIPO_DE_FALLA_FAILURE });
    }
}
export function* fallasPorTipoFallaSaga() {
    yield takeLatest(mecanismosDePagosActions.FALLAS_POR_TIPO_DE_FALLA_REQUEST, fallasPorTipoFalla);
}


function* nivelesSeveridadActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(nivelesDeSeveridadActivosPorFallaCall, token);
        console.log(response);

        if (response.status == 200) {
            let listaNivelesSeveridadPorFalla = response.data;
            yield put({ type: mecanismosDePagosActions.NIVELES_DE_SEVERIDAD_POR_FALLA_SUCCESS, listaNivelesSeveridadPorFalla });
        } else {
            yield put({ type: mecanismosDePagosActions.NIVELES_DE_SEVERIDAD_POR_FALLA_FAILURE });
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.NIVELES_DE_SEVERIDAD_POR_FALLA_FAILURE });
    }
}
export function* nivelesSeveridadActivosSaga() {
    yield takeLatest(mecanismosDePagosActions.NIVELES_DE_SEVERIDAD_POR_FALLA_REQUEST, nivelesSeveridadActivos);
}

function* crearConciliacion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const formulario = action.formulario;
        let fechaConciliacion = null;
        let idNivelSeveridad = null;
        const fechaCierre = formulario.fechaCierreEditar.format('YYYY-MM-DDTHH:mm');

        if(formulario.nivelSeveridad) {
            idNivelSeveridad = formulario.nivelSeveridad.split(' - ')[0]
        }

        if(formulario.fechaConciliacionEditar){
            fechaConciliacion = formulario.fechaConciliacionEditar.format('YYYY-MM-DDTHH:mm')
        }

        console.log('paso a data')
        console.log(fechaCierre, fechaConciliacion);

        const data = {
            "clasificacionTicket": formulario.clasificacionTicket,
            "descripcionFalla": formulario.descripcionSolicitud,
            "fallaByIdFalla": null,
            "fallasDisponibilidadByIdFallaDisponibilidad": null,
            "fechaActualizacion": null,
            "fechaAlta": null,
            "fechaCierre": fechaCierre,
            "fechaConciliacion": fechaConciliacion,
            "idConciliacion": null,
            "idFalla": parseInt(formulario.nombreFalla.split('-')[0]),
            "idFallaDisponibilidad": null,
            "idNivelSeveridad": idNivelSeveridad,
            "idSolicitudservicio": action.busquedaTicketSSobj.idSolicitudServicio,
            "idTipoFalla": parseInt(formulario.tipoFalla.split(' - ')[0]),
            "idUsuario": action.busquedaTicketSSobj.idUsuario,
            "nivelesSeveridadByIdNivelSeveridad": null,
            "pcfc": null,
            "pdfd": null,
            "rutaArchivo": "",
            "solicitudServicioByIdSolicitudServicio": null,
            "tiposFallasByIdTipoFalla": null,
            "tmfgs": null,
            "usuariosByIdUsuario": null,
            "utfc": null
        }

        console.log('data', data)
        const formData = new FormData();
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: 'application/json'
        }));
        if(action.formulario.pdf){
            formData.append('file', action.formulario.pdf.file.originFileObj);
        }

        const response = yield call(crearConciliacionCall, token, formData )
        console.log('response crearConciliacion', response)

        if(response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Información actualizada con éxito.";
            const busqueda = ""

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearConciliacion'});
            yield put({ type: mecanismosDePagosActions.CREAR_CONCILIACION_SUCCESS });
            yield put({ type: mecanismosDePagosActions.BUSQUEDA_FOLIO_TICKET_SS_REQUEST, busqueda });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.CREAR_CONCILIACION_FAILURE })
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.CREAR_CONCILIACION_FAILURE })
    }

}

export function* crearConciliacionSaga() {
    yield takeLatest(mecanismosDePagosActions.CREAR_CONCILIACION_REQUEST, crearConciliacion);
}

function* seccionesPorServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServicio = action.idServicio;

        const response = yield call(seccionesPorServiciosCall, token, idServicio);
        console.log('response seccionesPorServicio', response);

        if(response.status === 200){
            const seccionesPorServicio = response.data;

            yield put({ type: mecanismosDePagosActions.SECCIONES_POR_SERVICIO_SUCCESS, seccionesPorServicio });
        } else {
            yield put({ type: mecanismosDePagosActions.SECCIONES_POR_SERVICIO_FAILURE })
        }
    } catch(error) {
        yield put({ type: mecanismosDePagosActions.SECCIONES_POR_SERVICIO_FAILURE })
    }
}

export function* seccionesPorServicioSaga() {
    yield takeLatest(mecanismosDePagosActions.SECCIONES_POR_SERVICIO_REQUEST, seccionesPorServicio);
}

function* fallasPorServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idServicio = "";
        let param = '';

        if(action.param) {
            param = action.param
        }

        if(action.idServicio) {
            idServicio = action.idServicio;
        }

        const response = yield call(fallasPorServiciosCall, token, idServicio, param);
        console.log('response fallasPorServicio', response);

        if(response.status === 200){
            const fallasPorServicio = response.data;

            yield put({ type: mecanismosDePagosActions.FALLAS_POR_SERVICIO_SUCCESS, fallasPorServicio });
        } else {
            yield put({ type: mecanismosDePagosActions.FALLAS_POR_SERVICIO_FAILURE })
        }
    } catch(error) {
        yield put({ type: mecanismosDePagosActions.FALLAS_POR_SERVICIO_FAILURE })
    }
}

export function* fallasPorServicioSaga() {
    yield takeLatest(mecanismosDePagosActions.FALLAS_POR_SERVICIO_REQUEST, fallasPorServicio);
}

// Calculo de deductivas por errores u omisiones

function* reporteResumenCalculoEO(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idMesCalendario = '';
        let fechaProyeccion = action.fechaProyeccion;

        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario; }

        let response = yield call(reporteResumenCalculoDeductivaErrorCall, token, fechaProyeccion, idMesCalendario)
        console.log('response ', response)

        if(response.status === 200){
            let reporteResumenCalculoEO = response.data.data;
            let reporteResumenCalculoEOCantidad = response.data.cantidad;
            let columnsSaga = [];

            if(reporteResumenCalculoEOCantidad > 0){
                let servicio = {'title': 'Servicio', 'dataIndex': 'servicio', 'key': 'servicio', 'align': 'center', 'width': '30%' }; columnsSaga.push(servicio);

                for(let j in reporteResumenCalculoEO[0].graficaResponseList){
                    let tipoFalla = reporteResumenCalculoEO[0].graficaResponseList[j].etiqueta.replace(/\s+/g, '');
                    let column =
                        {
                            'title': reporteResumenCalculoEO[0].graficaResponseList[j].etiqueta,
                            'dataIndex': tipoFalla,
                            'key': tipoFalla,
                            'align': 'right'
                        }
                    columnsSaga.push(column);
                }

                let cantidadTickets = { title: 'Cantidad de tickets', dataIndex: 'foliosByServicio', key: 'foliosByServicio',  align: 'right', width: '7%' }; columnsSaga.push(cantidadTickets);

                let sumaAcumulada = { title: 'Total', dataIndex: 'cantidadTabla', key: 'cantidadTabla', align: 'right' }; columnsSaga.push(sumaAcumulada);

                for(let i in reporteResumenCalculoEO){
                    reporteResumenCalculoEO[i].mes = action.mesCalendario;
                    reporteResumenCalculoEO[i].idResumenServicio = i;
                    let tipoFalla = '';
                    //PARA CADA TIPO DE FALLA
                    for(let j in reporteResumenCalculoEO[i].graficaResponseList){
                        let deductiva = (reporteResumenCalculoEO[i].graficaResponseList[j].cantidad).toFixed(2);
                        function numberWithCommas(x) {
                            let parts = x.toString().split(".");
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            return parts.join(".");
                        }

                        let deductivaFxd = numberWithCommas(deductiva);

                        if(!deductiva.includes('.')){ deductivaFxd = deductiva + '.00'; }
                        tipoFalla = reporteResumenCalculoEO[i].graficaResponseList[j].etiqueta.replace(/\s+/g, '');
                        reporteResumenCalculoEO[i][tipoFalla] = deductivaFxd;
                    }

                    if(reporteResumenCalculoEO[i].deductivaAcumulada){
                        let cant = reporteResumenCalculoEO[i].deductivaAcumulada.toLocaleString('en-US', {'maximumFractionDigits': 2})
                        if(cant.includes('.')){
                            reporteResumenCalculoEO[i].cantidadTabla = cant;
                        } else {
                            reporteResumenCalculoEO[i].cantidadTabla = cant + '.00';
                        }

                    } else { reporteResumenCalculoEO[i].cantidadTabla = '0.00' }
                }
            }
            reporteResumenCalculoEO.columns = columnsSaga;

            yield put({ type: mecanismosDePagosActions.REPORTE_RESUMEN_CALCULO_DEDUCTIVA_ERROR_SUCCESS, reporteResumenCalculoEO, reporteResumenCalculoEOCantidad })
        } else {
            yield put({ type: mecanismosDePagosActions.REPORTE_RESUMEN_CALCULO_DEDUCTIVA_ERROR_FAILURE })
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.REPORTE_RESUMEN_CALCULO_DEDUCTIVA_ERROR_FAILURE})
    }
} export function* reporteResumenCalculoEOSaga(){
    yield takeLatest(mecanismosDePagosActions.REPORTE_RESUMEN_CALCULO_DEDUCTIVA_ERROR_REQUEST, reporteResumenCalculoEO)
}

function* deductivaErrorResumenExcel(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idMesCalendario = '';
        let fechaProyeccion = action.fechaProyeccion;
        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario; }

        let response = yield call(deductivaErrorResumenExcelCall, token, fechaProyeccion, idMesCalendario)

        console.log('response', response)

        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            let dia = new Date().getDate();
            if(dia < 10){ dia = '0' + dia }
            let mes = new Date().getMonth() + 1;
            if(mes < 10){ mes = '0' + mes }

            link.download = "DeductivaErrorOmisionResumen_" + dia + "-" + mes + "-" + new Date().getFullYear() + "_" + new Date().getHours() + "" + new Date().getMinutes() + "" + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: mecanismosDePagosActions.EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_ERROR_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el documento.";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_ERROR_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_ERROR_FAILURE });
    }
} export function* deductivaErrorResumenExcelSaga() {
    yield takeLatest(mecanismosDePagosActions.EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_ERROR_REQUEST, deductivaErrorResumenExcel);
}

function* listaDetalleCalculoDeductivasError(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idMesCalendario = ''; let idTipoFalla = ''; let idAreaFuncional = ''; let idGrupoSer = ''; let idServicio = ''; let fechaCierre1 = ''; let fechaCierre2 = '';
        let fechaProyeccion = action.fechaProyeccion;
        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario; }
        if(action.idTipoFalla){ idTipoFalla = '&idTipoFalla=' + action.idTipoFalla; }
        if(action.idAreaFuncional){ idAreaFuncional = '&idAreaFuncional=' + action.idAreaFuncional; }
        if(action.idGrupoSer){ idGrupoSer = '&idGrupoSer=' + action.idGrupoSer; }
        if(action.idServicio){ idServicio = '&idServicio=' + action.idServicio; }
        if(action.fechaCierre1){ fechaCierre1 = '&fechaCierre1=' + action.fechaCierre1; }
        if(action.fechaCierre2){ fechaCierre2 = '&fechaCierre2=' + action.fechaCierre2; }

        console.log('data a enviar',idMesCalendario, fechaProyeccion, idTipoFalla, idAreaFuncional, idGrupoSer, idServicio, fechaCierre1, fechaCierre2)

        let response = yield call(listaDetalleCalculoDeductivaErrorCall, token, fechaProyeccion, idMesCalendario, idTipoFalla, idAreaFuncional, idGrupoSer, idServicio, fechaCierre1, fechaCierre2 )
        console.log('response ', response)

        if(response.status === 200){
            let listaDetalleCalculoDEO = response.data.data;
            let listaDetalleCalculoDEOCantidad = response.data.cantidad;

            for(let i in listaDetalleCalculoDEO){
                if(listaDetalleCalculoDEO[i].solicitudByIdSolicitudServicio){
                    listaDetalleCalculoDEO[i].ticket = listaDetalleCalculoDEO[i].solicitudByIdSolicitudServicio.folio;

                    listaDetalleCalculoDEO[i].origen = listaDetalleCalculoDEO[i].solicitudByIdSolicitudServicio.nombreUsuario;

                    listaDetalleCalculoDEO[i].descripcionSolicitud = listaDetalleCalculoDEO[i].solicitudByIdSolicitudServicio.descripcionSolicitud;

                    if(listaDetalleCalculoDEO[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra){
                        if(listaDetalleCalculoDEO[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.aMaterno === null || listaDetalleCalculoDEO[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.aMaterno === ""){
                            listaDetalleCalculoDEO[i].personalCCO = listaDetalleCalculoDEO[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.nombre + ' ' + listaDetalleCalculoDEO[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.aPaterno;
                        } else {
                            listaDetalleCalculoDEO[i].personalCCO = listaDetalleCalculoDEO[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.nombre + ' ' + listaDetalleCalculoDEO[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.aPaterno + ' ' + listaDetalleCalculoDEO[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.aMaterno;;
                        }
                    }
                }

                listaDetalleCalculoDEO[i].fechaAltaTabla = listaDetalleCalculoDEO[i].fechaAlta.split('T')[0].split('-').reverse().join('-') + ' ' + listaDetalleCalculoDEO[i].fechaAlta.split('T')[1].split(':')[0] + ':' + listaDetalleCalculoDEO[i].fechaAlta.split('T')[1].split(':')[1];

                listaDetalleCalculoDEO[i].fechaCierreTabla = listaDetalleCalculoDEO[i].fechaCierre.split('T')[0].split('-').reverse().join('-') + ' ' + listaDetalleCalculoDEO[i].fechaCierre.split('T')[1].split(':')[0] + ':' + listaDetalleCalculoDEO[i].fechaCierre.split('T')[1].split(':')[1];

                listaDetalleCalculoDEO[i].pmiTabla = listaDetalleCalculoDEO[i].pmi.toLocaleString('en-US', {'maximumFractionDigits': 2});

                listaDetalleCalculoDEO[i].pmrTabla = listaDetalleCalculoDEO[i].pmr.toLocaleString('en-US', {'maximumFractionDigits': 2});
                listaDetalleCalculoDEO[i].deductivaTabla = listaDetalleCalculoDEO[i].deductiva.toLocaleString('en-US', {'maximumFractionDigits': 2});

                if(listaDetalleCalculoDEO[i].deductivaParcial){
                    listaDetalleCalculoDEO[i].deductivaParcialTexto = 'Sí'
                } else { listaDetalleCalculoDEO[i].deductivaParcialTexto = 'No' }

                if(listaDetalleCalculoDEO[i].fallaCalidad){
                    listaDetalleCalculoDEO[i].fallaCalidadTabla = listaDetalleCalculoDEO[i].fallaCalidad;
                } else { listaDetalleCalculoDEO[i].fallaCalidadTabla = '-' }

                if(listaDetalleCalculoDEO[i].fsa){
                    listaDetalleCalculoDEO[i].fsaTabla = listaDetalleCalculoDEO[i].fsa.toFixed(2);
                } else { listaDetalleCalculoDEO[i].fsaTabla = '-' }

                if(listaDetalleCalculoDEO[i].ps){
                    listaDetalleCalculoDEO[i].psTabla = listaDetalleCalculoDEO[i].ps.toFixed(2) + '%';
                } else { listaDetalleCalculoDEO[i].psTabla = '-' }

                if(listaDetalleCalculoDEO[i].pcfc){
                    listaDetalleCalculoDEO[i].pcfcTabla = listaDetalleCalculoDEO[i].pcfc.toFixed(2) + '%';
                } else { listaDetalleCalculoDEO[i].pcfcTabla = '-' }

                if(listaDetalleCalculoDEO[i].psa){
                    listaDetalleCalculoDEO[i].psaTabla = listaDetalleCalculoDEO[i].psa.toFixed(2) + '%';
                } else { listaDetalleCalculoDEO[i].psaTabla = '-' }

                if(listaDetalleCalculoDEO[i].ponderacionServArea >= 0){
                    console.log('Entra al if ponderacionServArea')
                    listaDetalleCalculoDEO[i].ponderacionServAreaTabla = listaDetalleCalculoDEO[i].ponderacionServArea.toFixed(2) + '%';
                } else { listaDetalleCalculoDEO[i].ponderacionServAreaTabla = '-' }

                if(listaDetalleCalculoDEO[i].ticketReiterado){
                    listaDetalleCalculoDEO[i].ticketReiteradoTabla = listaDetalleCalculoDEO[i].ticketReiterado;
                } else { listaDetalleCalculoDEO[i].ticketReiteradoTabla = '-' }

                if(listaDetalleCalculoDEO[i].numReferencia){
                    listaDetalleCalculoDEO[i].numReferenciaTabla = listaDetalleCalculoDEO[i].numReferencia;
                } else { listaDetalleCalculoDEO[i].numReferenciaTabla = '-' }

                if(listaDetalleCalculoDEO[i].tiempoGarantiaByIdSolicitudServicio){
                    let tg = '';
                    tg = listaDetalleCalculoDEO[i].tiempoGarantiaByIdSolicitudServicio.tiempoGarantia;
                    if(listaDetalleCalculoDEO[i].tiempoGarantiaByIdSolicitudServicio.unidadesMedidasByIdUnidadMedida){
                        listaDetalleCalculoDEO[i].tiempoGarantiaTabla = tg + ' ' + listaDetalleCalculoDEO[i].tiempoGarantiaByIdSolicitudServicio.unidadesMedidasByIdUnidadMedida.unidadMedida;
                    }
                } else { listaDetalleCalculoDEO[i].tiempoGarantiaTabla = '-' }

                if(listaDetalleCalculoDEO[i].ut){
                    listaDetalleCalculoDEO[i].utTabla = listaDetalleCalculoDEO[i].ut;
                } else { listaDetalleCalculoDEO[i].utTabla = '-' }

                if(listaDetalleCalculoDEO[i].utf){
                    listaDetalleCalculoDEO[i].utfTabla = listaDetalleCalculoDEO[i].utf;
                } else { listaDetalleCalculoDEO[i].utfTabla = '-' }

                if(listaDetalleCalculoDEO[i].pdfd){
                    listaDetalleCalculoDEO[i].pdfdTabla = listaDetalleCalculoDEO[i].pdfd.toFixed(2) + '%';
                } else { listaDetalleCalculoDEO[i].pdfdTabla = '-' }

                if(listaDetalleCalculoDEO[i].fallaDisponibilidad){
                    listaDetalleCalculoDEO[i].fallaDisponibilidadTabla = listaDetalleCalculoDEO[i].fallaDisponibilidad;
                } else { listaDetalleCalculoDEO[i].fallaDisponibilidadTabla = '-' }

                if(listaDetalleCalculoDEO[i].tipoDeductiva === 'Disponibilidad'){
                    listaDetalleCalculoDEO[i].referenciaEstandarTabla = '-';
                    listaDetalleCalculoDEO[i].descripcionEstandarTabla = '-';
                } else {
                    listaDetalleCalculoDEO[i].referenciaEstandarTabla = listaDetalleCalculoDEO[i].referenciaEstandar;
                    listaDetalleCalculoDEO[i].descripcionEstandarTabla = listaDetalleCalculoDEO[i].descripcionEstandar;
                }

                if(listaDetalleCalculoDEO[i].numeroFallaCategoria){
                    listaDetalleCalculoDEO[i].numeroFallaCategoriaTabla = listaDetalleCalculoDEO[i].numeroFallaCategoria;
                } else { listaDetalleCalculoDEO[i].numeroFallaCategoriaTabla = '-'; }

                //PENDIENTE
                listaDetalleCalculoDEO[i].fechaCierreMesTabla = '-'
            }
            console.table(listaDetalleCalculoDEO);
            yield put({ type: mecanismosDePagosActions.LISTA_DETALLE_CALCULO_ERROR_SUCCESS, listaDetalleCalculoDEO, listaDetalleCalculoDEOCantidad })
        } else {
            yield put({ type: mecanismosDePagosActions.LISTA_DETALLE_CALCULO_ERROR_FAILURE})
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.LISTA_DETALLE_CALCULO_ERROR_FAILURE})
    }
} export function* listaDetalleCalculoDeductivasErrorSaga(){
    yield takeLatest(mecanismosDePagosActions.LISTA_DETALLE_CALCULO_ERROR_REQUEST, listaDetalleCalculoDeductivasError)
}

function* detalleDeductivaErrorExcel(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idMesCalendario = '';
        let fechaProyeccion = action.fechaProyeccion;
        let idTipoFalla = ''; let idAreaFuncional = ''; let idGrupoSer = ''; let idServicio = ''; let fechaCierre1 = ''; let fechaCierre2 = '';

        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario; }
        if(action.idTipoFalla){ idTipoFalla = '&idTipoFalla=' + action.idTipoFalla; }
        if(action.idAreaFuncional){ idAreaFuncional = '&idAreaFuncional=' + action.idAreaFuncional; }
        if(action.idGrupoSer){ idGrupoSer = '&idGrupoSer=' + action.idGrupoSer; }
        if(action.idServicio){ idServicio = '&idServicio=' + action.idServicio; }
        if(action.fechaCierre1){ fechaCierre1 = '&fechaCierre1=' + action.fechaCierre1; }
        if(action.fechaCierre2){ fechaCierre2 = '&fechaCierre2=' + action.fechaCierre2; }

        let response = yield call(detalleDeductivaErrorExcelCall, token, fechaProyeccion, idMesCalendario, idTipoFalla, idAreaFuncional, idGrupoSer, idServicio, fechaCierre1, fechaCierre2)
        console.log('response', response)

        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            let dia = new Date().getDate();
            if(dia < 10){ dia = '0' + dia }
            let mes = new Date().getMonth() + 1;
            if(mes < 10){ mes = '0' + mes }

            link.download = "DeductivaErrorOmision_" + dia + "-" + mes + "-" + new Date().getFullYear() + "_" + new Date().getHours() + "" + new Date().getMinutes() + "" + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: mecanismosDePagosActions.EXPORTAR_EXCEL_DETALLE_CALCULO_ERROR_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el documento.";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EXPORTAR_EXCEL_DETALLE_CALCULO_ERROR_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.EXPORTAR_EXCEL_DETALLE_CALCULO_ERROR_FAILURE });
    }
}
export function* detalleDeductivaErrorExcelSaga() {
    yield takeLatest(mecanismosDePagosActions.EXPORTAR_EXCEL_DETALLE_CALCULO_ERROR_REQUEST, detalleDeductivaErrorExcel);
}

function* procesarDeductivaError(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let idMesCalendario = '';
        let fechaProyeccion = action.fechaProyeccion;

        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario; }

        let response = yield call(procesarDeductivaErrorCall, token, fechaProyeccion, idMesCalendario)
        console.log('response', response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = 'El cálculo fue procesado con éxito';

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.PROCESAR_DEDUCTIVA_ERROR_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = "No fue posible procesar el cálculo";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.PROCESAR_DEDUCTIVA_ERROR_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.PROCESAR_DEDUCTIVA_ERROR_FAILURE });
    }
}
export function* procesarDeductivaErrorSaga() {
    yield takeLatest(mecanismosDePagosActions.PROCESAR_DEDUCTIVA_ERROR_REQUEST, procesarDeductivaError);
}

// Editar folio conciliacion
function* busquedaFolioConciliado(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let busqueda = 'folio=';
        if(action.busqueda) {
            busqueda += action.busqueda
        }

        const response = yield call(busquedaFolioConciliacionCall, token, busqueda);
        console.log(`response`, response);

        if (response.status == 200) {
            let busquedaFolioConciliado = response.data.data;
            let busquedaFolioConciliadoobj = {}

            if(busquedaFolioConciliado[0]){
                for(let i in busquedaFolioConciliado) {
                    busquedaFolioConciliadoobj.idConciliacion = busquedaFolioConciliado[i].idConciliacion

                    busquedaFolioConciliadoobj.idSolicitudServicio = busquedaFolioConciliado[i].idSolicitudservicio
                    busquedaFolioConciliadoobj.idUsuario = busquedaFolioConciliado[i].idUsuarioRegistra
                    busquedaFolioConciliadoobj.descripcionSolicitud = busquedaFolioConciliado[i].descripcionFalla
                    busquedaFolioConciliadoobj.rutaArchivo = busquedaFolioConciliado[i].rutaArchivo

                    // busquedaFolioConciliado[i].fechaCierre = moment(busquedaFolioConciliado[i].fechaCierre.split('T')[0]+ " " + busquedaFolioConciliado[i].fechaCierre.split('T')[1].split(':')[0] + ":" + busquedaFolioConciliado[i].fechaCierre.split('T')[1].split(':')[1], 'YYYY-MM-DD HH:mm')
                    // busquedaFolioConciliadoobj.fechaCierreEditar = moment(busquedaFolioConciliado[i].fechaCierre, 'YYYY-MM-DD HH:mm')

                    // if(busquedaFolioConciliado[i].fechaAlta){
                    //     busquedaFolioConciliado[i].fechaAltaFormato = moment(busquedaFolioConciliado[i].fechaAlta.split('T')[0] + " " + busquedaFolioConciliado[i].fechaAlta.split('T')[1].split(':')[0] + ":" + busquedaFolioConciliado[i].fechaAlta.split('T')[1].split(':')[1], 'YYYY-MM-DD HH:mm');
                    //     busquedaFolioConciliadoobj.fechaAltaFormato = moment(busquedaFolioConciliado[i].fechaAltaFormato, 'YYYY-MM-DD HH:mm');
                    // }

                    if(busquedaFolioConciliado[i].solicitudServicioByIdSolicitudServicio){
                        busquedaFolioConciliado[i].fechaAltaFormato = moment(busquedaFolioConciliado[i].solicitudServicioByIdSolicitudServicio.fechaAlta.split('T')[0] + " " + busquedaFolioConciliado[i].solicitudServicioByIdSolicitudServicio.fechaAlta.split('T')[1].split(':')[0] + ":" + busquedaFolioConciliado[i].solicitudServicioByIdSolicitudServicio.fechaAlta.split('T')[1].split(':')[1], 'YYYY-MM-DD HH:mm');

                        busquedaFolioConciliadoobj.fechaAltaFormato = moment(busquedaFolioConciliado[i].fechaAltaFormato, 'YYYY-MM-DD HH:mm');

                        // El apartado de Fecha de cierre, tiene que ser llenado con la fechaCierre de solicitudServicioByIdSolicitudServicio. Sin oportunidad a modificación
                        let fechaCierre = moment(busquedaFolioConciliado[i].solicitudServicioByIdSolicitudServicio.fechaCierre.split('T')[0]+ " " + busquedaFolioConciliado[i].solicitudServicioByIdSolicitudServicio.fechaCierre.split('T')[1].split(':')[0] + ":" + busquedaFolioConciliado[i].solicitudServicioByIdSolicitudServicio.fechaCierre.split('T')[1].split(':')[1], 'YYYY-MM-DD HH:mm');

                        busquedaFolioConciliadoobj.fechaCierreEditar = moment(fechaCierre, 'YYYY-MM-DD HH:mm');
                    }

                    busquedaFolioConciliadoobj.idTipoFalla = busquedaFolioConciliado[i].idTipoFalla
                    busquedaFolioConciliadoobj.idNivelSeveridad = busquedaFolioConciliado[i].idNivelSeveridad

                    if(busquedaFolioConciliado[i].fallaByIdFalla && busquedaFolioConciliado[i].servSeccionesEspecificasByIdSeccion) {
                        busquedaFolioConciliado[i].falla = busquedaFolioConciliado[i].fallaByIdFalla.idFalla + '-' + busquedaFolioConciliado[i].fallaByIdFalla.nombre;
                        busquedaFolioConciliadoobj.nombreFalla = busquedaFolioConciliado[i].fallaByIdFalla.idFalla + '-' + busquedaFolioConciliado[i].fallaByIdFalla.nombre + ': ' + busquedaFolioConciliado[i].servSeccionesEspecificasByIdSeccion.idServSeccionEspecifico;

                        if(busquedaFolioConciliado[i].fallaByIdFalla.estandarByIdEstandar.idServSeccionEspecifico === 1) {
                            busquedaFolioConciliadoobj.habilitarSeveridad = true;
                            busquedaFolioConciliado[i].habilitarSeveridad = true;
                        }
                    }

                    if(busquedaFolioConciliado[i].nivelesSeveridadByIdNivelSeveridad) {
                        busquedaFolioConciliado[i].nivelSeveridad = busquedaFolioConciliado[i].nivelesSeveridadByIdNivelSeveridad.idNivelSeveridad + '-' + busquedaFolioConciliado[i].nivelesSeveridadByIdNivelSeveridad.descripcion
                        busquedaFolioConciliadoobj.nivelSeveridad = busquedaFolioConciliado[i].nivelesSeveridadByIdNivelSeveridad.idNivelSeveridad + ' - ' + busquedaFolioConciliado[i].nivelesSeveridadByIdNivelSeveridad.descripcion
                    }

                    if(busquedaFolioConciliado[i].fallaByIdFalla) {
                        if(busquedaFolioConciliado[i].fallaByIdFalla.idTipoFalla === 1){
                            busquedaFolioConciliadoobj.fallaCalidad = true;
                        }
                        busquedaFolioConciliado[i].falla = busquedaFolioConciliado[i].fallaByIdFalla.idFalla + '-' + busquedaFolioConciliado[i].fallaByIdFalla.nombre;
                        busquedaFolioConciliadoobj.nombreFalla = busquedaFolioConciliado[i].fallaByIdFalla.idFalla + '-' + busquedaFolioConciliado[i].fallaByIdFalla.nombre + ': ' + busquedaFolioConciliado[i].fallaByIdFalla.estandarByIdEstandar.idServSeccionEspecifico;
                        busquedaFolioConciliadoobj.idServicio = busquedaFolioConciliado[i].fallaByIdFalla.idServicio
                    }


                    if(busquedaFolioConciliado[i].fallaByIdFalla.tipoFallaByIdTipoFalla) {
                        busquedaFolioConciliado[i].tipoFalla = busquedaFolioConciliado[i].fallaByIdFalla.tipoFallaByIdTipoFalla.nombre
                        busquedaFolioConciliadoobj.tipoFalla = busquedaFolioConciliado[i].fallaByIdFalla.tipoFallaByIdTipoFalla.idTipoFalla+' - '+busquedaFolioConciliado[i].fallaByIdFalla.tipoFallaByIdTipoFalla.nombre
                    }

                    if (busquedaFolioConciliado[i].clasificacionTicket) {
                        const queja = busquedaFolioConciliado[i].clasificacionTicket;
                        console.log('solicitudServicioByIdSolicitudServicio', busquedaFolioConciliado[i].solicitudServicioByIdSolicitudServicio)
                        if(queja === null || queja === 'Prorroga') {
                            busquedaFolioConciliado[i].clasificacionTicket = "Solicitud de Servicio"
                            busquedaFolioConciliadoobj.clasificacionTicket = "Solicitud de Servicio"
                        } else {
                            busquedaFolioConciliado[i].clasificacionTicket = queja
                            busquedaFolioConciliadoobj.clasificacionTicket = queja
                        }
                    } else {
                        busquedaFolioConciliado[i].clasificacionTicket = "Solicitud de Servicio"
                        busquedaFolioConciliadoobj.clasificacionTicket = "Solicitud de Servicio"
                    }

                    // if(busquedaFolioConciliado[i].fechaConciliacion) {
                    //     // busquedaFolioConciliado[i].fechaConciliacion = moment(busquedaFolioConciliado[i].fechaConciliacion.split('T')[0], 'YYYY-MM-DD HH:mm')
                    //     busquedaFolioConciliado[i].fechaConciliacion = moment(busquedaFolioConciliado[i].fechaConciliacion.split('T')[0]+ " " + busquedaFolioConciliado[i].fechaConciliacion.split('T')[1].split(':')[0] + ":" + busquedaFolioConciliado[i].fechaConciliacion.split('T')[1].split(':')[1], 'YYYY-MM-DD HH:mm')
                    //     busquedaFolioConciliadoobj.fechaConciliacionEditar = moment(busquedaFolioConciliado[i].fechaConciliacion, 'YYYY-MM-DD HH:mm')
                    // } else {
                    //     busquedaFolioConciliado[i].fechaConciliacion = 'No tiene fecha de conciliacion'
                    //     busquedaFolioConciliadoobj.fechaConciliacionEditar = null
                    // }

                    if(busquedaFolioConciliado[i].fechaCierre) {
                        let fechaConciliacion = moment(busquedaFolioConciliado[i].fechaCierre.split('T')[0]+ " " + busquedaFolioConciliado[i].fechaCierre.split('T')[1].split(':')[0] + ":" + busquedaFolioConciliado[i].fechaCierre.split('T')[1].split(':')[1], 'YYYY-MM-DD HH:mm');

                        busquedaFolioConciliadoobj.fechaConciliacionEditar = moment(fechaConciliacion, 'YYYY-MM-DD HH:mm')
                    } else {
                        busquedaFolioConciliado[i].fechaConciliacion = 'No tiene fecha de conciliacion'
                        busquedaFolioConciliadoobj.fechaConciliacionEditar = null
                    }

                    
                }
            }
            else{
                yield put({ type: configuracionYSeguridadActions.SHOW_WARNING_MSG, showWarningMsg: true, textMessage: 'El ticket no existe o no ha sido conciliado' });
            }

            yield put({ type: mecanismosDePagosActions.BUSQUEDA_FOLIO_CONCILIADO_SUCCESS, busquedaFolioConciliado, busquedaFolioConciliadoobj });
        }
        else {

            yield put({ type: mecanismosDePagosActions.BUSQUEDA_FOLIO_CONCILIADO_FAILURE });
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.BUSQUEDA_FOLIO_CONCILIADO_FAILURE });
    }
}
export function* busquedaFolioConciliadoSaga() {
    yield takeLatest(mecanismosDePagosActions.BUSQUEDA_FOLIO_CONCILIADO_REQUEST, busquedaFolioConciliado);
}

function* edicionFolioConciliacion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const formulario = action.formulario;
        let fechaConciliacion = null;
        const idConciliacion = action.busquedaFolioConciliadoobj.idConciliacion
        const fechaCierre = formulario.fechaCierreEditar.format('YYYY-MM-DDTHH:mm');

        if(formulario.fechaConciliacionEditar){
            fechaConciliacion = formulario.fechaConciliacionEditar.format('YYYY-MM-DDTHH:mm')
        }

        console.log('paso a data')
        console.log(fechaCierre, fechaConciliacion);

        const data = {
            "idConciliacion": action.busquedaFolioConciliadoobj.idConciliacion,
            "idUsuario": action.busquedaFolioConciliado[0].idUsuario,
            "idSolicitudservicio": action.busquedaFolioConciliado[0].idSolicitudservicio,
            "idFalla": parseInt(formulario.nombreFalla.split('-')[0]),
            "descripcionFalla": action.busquedaFolioConciliado[0].descripcionFalla,
            "idTipoFalla": parseInt(formulario.tipoFalla.split(' - ')[0]),
            "idNivelSeveridad": parseInt(formulario.nivelSeveridad),
            "idFallaDisponibilidad": action.busquedaFolioConciliado[0].idFallaDisponibilidad,
            "pcfc": null,
            "pdfd": null,
            "tmfgs": null,
            "utfc": null,
            "fechaCierre": fechaConciliacion,
            "fechaActualizacion": null,
            "fechaAlta": null,
            "fechaConciliacion": null,
            "clasificacionTicket": formulario.clasificacionTicket,
            "rutaArchivo": action.busquedaFolioConciliadoobj.rutaArchivo
        };

        console.log('data', data);

        const formData = new FormData();
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: 'application/json'
        }));

        if(action.formulario.pdf){
            formData.append('file', action.formulario.pdf.file.originFileObj);
        }
        console.log('data', data)

        const response = yield call(edicionFolioConciliacionCall, token, idConciliacion, formData);

        if (response.status == 200) {
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "editarConciliacion" });
            const textMessage = 'La conciliación se ha editado correctamente.';
            yield put({ type: mecanismosDePagosActions.EDITAR_FOLIO_CONCILIADO_SUCCESS });
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage });
        }
        else {

            yield put({ type: mecanismosDePagosActions.EDITAR_FOLIO_CONCILIADO_FAILURE });
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg: true, textMessage });
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.EDITAR_FOLIO_CONCILIADO_FAILURE });
    }
}
export function* edicionFolioConciliacionSaga() {
    yield takeLatest(mecanismosDePagosActions.EDITAR_FOLIO_CONCILIADO_REQUEST, edicionFolioConciliacion);
}
//Folio de errores u omisión
function* busquedaTicketSSConciliado(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const busqueda = action.busqueda ?  `folio=${action.busqueda}` : 'folio='

        const response = yield call(busquedaFolioConciliacionCall, token, busqueda);
        console.log(response);

        if (response.status === 200) {
            let ticketSSConciliado = response.data.data[0];

            if(ticketSSConciliado) {
                ticketSSConciliado.tipoFalla = ticketSSConciliado.fallaByIdFalla.tipoFallaByIdTipoFalla.nombre;
                ticketSSConciliado.nombreFalla = ticketSSConciliado.fallaByIdFalla.nombre;
                ticketSSConciliado.descripcionDeFalla = ticketSSConciliado.solicitudServicioByIdSolicitudServicio.descripcionSolicitud;
                ticketSSConciliado.fechaCierre =  moment(ticketSSConciliado.fechaCierre.split('T')[0]+ " " + ticketSSConciliado.fechaCierre.split('T')[1].split(':')[0] + ":" + ticketSSConciliado.fechaCierre.split('T')[1].split(':')[1], 'YYYY-MM-DD HH:mm');
                ticketSSConciliado.fechaCierreEditar = moment(ticketSSConciliado.fechaCierre, 'YYYY-MM-DD HH:mm');

                //servicio
                const {servicioByIdServicio} = ticketSSConciliado.solicitudServicioByIdSolicitudServicio;
                ticketSSConciliado.servicio = `${servicioByIdServicio.idServicio} - ${servicioByIdServicio.nomenclatura} (${servicioByIdServicio.nombre})`;

                //ubicacion
                const {ubicacionByIdUbicacion} = ticketSSConciliado.solicitudServicioByIdSolicitudServicio
                ticketSSConciliado.ubicacion = `${ubicacionByIdUbicacion.idUbicacion} - ${ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.codigoArea}, ${ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional}`;

                if(ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional) {
                    ticketSSConciliado.ubicacion += ', ' + ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                }

                if(ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional) {
                    ticketSSConciliado.ubicacion += ', ' + ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional;
                }

                if(ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional) {
                    ticketSSConciliado.ubicacion += ', ' + ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                }

                //severidad
                ticketSSConciliado.severidad = false
                const {idServSeccionEspecifico} = ticketSSConciliado.servSeccionesEspecificasByIdSeccion;
                if(idServSeccionEspecifico === 1){
                    ticketSSConciliado.severidad = true;
                }
                ticketSSConciliado.nivelSeveridad = null;
                if(ticketSSConciliado.nivelesSeveridadByIdNivelSeveridad) {
                    ticketSSConciliado.nivelSeveridad = ticketSSConciliado.nivelesSeveridadByIdNivelSeveridad.descripcion;
                }
            }

            yield put({ type: mecanismosDePagosActions.TICKET_SS_CONCILIADO_SUCCESS, ticketSSConciliado });
        }

        else {

            yield put({ type: mecanismosDePagosActions.TICKET_SS_CONCILIADO_FAILURE });
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.TICKET_SS_CONCILIADO_FAILURE });
    }
}
export function* busquedaTicketSSConciliadoSaga() {
    yield takeLatest(mecanismosDePagosActions.TICKET_SS_CONCILIADO_REQUEST, busquedaTicketSSConciliado);
}

function* busquedaFolioErrorOmision(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const {busqueda="", fecha=null, servicio=null} = action;
        const fechaInicio = fecha ? fecha[0].format('YYYY-MM-DD') : null;
        const fechaFin = fecha ? fecha[1].format('YYYY-MM-DD') : null;

        const response = yield call(getFoliosErrorOmisionCall, token, busqueda, fechaInicio, fechaFin, servicio, page);
        console.log(response);

        if (response.status == 200) {
            let listaFoliosErroresOmisiones = response.data.data;
            const listaFoliosErroresOmisionesCantidad = response.data.cantidad;

            for (let folio of listaFoliosErroresOmisiones) {
                folio.tipoFalla = folio.tiposFallasByIdTipoFalla.nombre;
                folio.nombreFalla = folio.fallaByIdFalla.nombre;
                folio.descripcionDeFalla = folio.descripcionDeFalla;

                //ubicacion
                const {ubicacionByIdUbicacion} = folio;
                folio.ubicacion = `${ubicacionByIdUbicacion.idUbicacion} - ${ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.codigoArea}, ${ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional}`;

                if(ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional) {
                    folio.ubicacion += ', ' + ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                }

                if(ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional) {
                    folio.ubicacion += ', ' + ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional;
                }

                if(ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional) {
                    folio.ubicacion += ', ' + ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                }

                //servicio
                const {servicioByServicio} = folio;
                folio.servicio = `${servicioByServicio.idServicio} - ${servicioByServicio.nomenclatura} (${servicioByServicio.nombre})`;

                folio.tipoDocFiscalizacion = folio.documentoFiscalizacionByIdDocumentoFiscalizacion.tipo;
                folio.fechaCierreEditar = moment(folio.fechaCierreCorrecta, 'YYYY-MM-DD HH:mm');
                folio.fechaOmision = moment(folio.fechaOmision, 'YYYY-MM-DD HH:mm');

                let fechaRegistroErrorOmision = folio.fechaRegistroErrorOmision.split('T')[0];
                let horaRegitroErrorOmision = folio.fechaRegistroErrorOmision.split('T')[1];
                folio.fechaRegistroErrorOmisionGrid = fechaRegistroErrorOmision.split('-')[2] + '-' + fechaRegistroErrorOmision.split('-')[1] + '-' + fechaRegistroErrorOmision.split('-')[0] + ' ' + horaRegitroErrorOmision.split(':')[0] + ':' + horaRegitroErrorOmision.split(':')[1];
                
                folio.fechaGrid = folio.fechaOmision.format('DD-MM-YYYY HH:mm');
                if(folio.tipoFolio==='Error') {
                    folio.fechaErrorAlta = moment(folio.solicitudServicioByIdSolicitudServicio.fechaAlta, 'YYYY-MM-DD HH:mm');
                    folio.fechaGrid = folio.fechaErrorAlta.format('DD-MM-YYYY HH:mm');
                }

                //severidad
                folio.nivelSeveridad = folio.nivelesSeveridadByIdNivelSeveridad ? folio.nivelesSeveridadByIdNivelSeveridad.descripcion : null;
            }

            yield put({ type: mecanismosDePagosActions.FOLIOS_ERROR_OMISION_BUSQUEDA_SUCCESS, listaFoliosErroresOmisiones, listaFoliosErroresOmisionesCantidad });
        }
        else {
            yield put({ type: mecanismosDePagosActions.FOLIOS_ERROR_OMISION_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.FOLIOS_ERROR_OMISION_BUSQUEDA_FAILURE });
    }
}
export function* busquedaFolioErrorOmisionSaga() {
    yield takeLatest(mecanismosDePagosActions.FOLIOS_ERROR_OMISION_BUSQUEDA_REQUEST, busquedaFolioErrorOmision);
}

function* getDocumentosFiscalizacion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(getDocFizcalizacionCall, token);
        console.log(response);

        if (response.status === 200) {
            const listaDocumentosFiscalizacion = response.data;
            yield put({ type: mecanismosDePagosActions.DOCUMENTOS_FISCALIZACION_SUCCESS, listaDocumentosFiscalizacion });
        }

        else {

            yield put({ type: mecanismosDePagosActions.DOCUMENTOS_FISCALIZACION_FAILURE });
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.DOCUMENTOS_FISCALIZACION_FAILURE });
    }
}
export function* getDocumentosFiscalizacionSaga() {
    yield takeLatest(mecanismosDePagosActions.DOCUMENTOS_FISCALIZACION_REQUEST, getDocumentosFiscalizacion);
}

function* crearFolioEO(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {formulario={}, ticketSSConciliado=null, tipoFolio="", page=1, busqueda=""} = action;
        const idSolicitudservicio = ticketSSConciliado ? ticketSSConciliado.idSolicitudservicio : null;

        let {tipoFalla="", nombreFalla="", descripcionDeFalla="", nivelSeveridad=null, ubicacion="", servicio="",
            noDocFiscalizacion="", tipoDocFiscalizacion="",
        } = formulario;

        tipoFalla = isNaN(tipoFalla) ? ticketSSConciliado.idTipoFalla : parseInt(tipoFalla);
        nombreFalla = isNaN(nombreFalla) ? ticketSSConciliado.idFalla : parseInt(nombreFalla);
        ubicacion = parseInt(ubicacion.split('-')[0]);
        servicio = parseInt(servicio.split('-')[0]);
        tipoDocFiscalizacion = parseInt(tipoDocFiscalizacion);

        if(nivelSeveridad !== null) {
            nivelSeveridad = isNaN(nivelSeveridad) ? ticketSSConciliado?.idNivelSeveridad : parseInt(nivelSeveridad);
        }
        nivelSeveridad = tipoFolio==='Omision' ? null : nivelSeveridad;

        const fechaOmision = tipoFolio==='Omision' ? formulario?.fechaOmision.format('YYYY-MM-DDTHH:mm') : null;
        const fechaCierreCorrecta = formulario.fechaCierreEditar.format('YYYY-MM-DDTHH:mm');

        const data =  {
            "idFolioErrorOmision": null,
            "tipoFolio": tipoFolio,
            "estado": "Abierto",
            "idSolicitudServicio": idSolicitudservicio,
            "folioErrorOmision": null,
            "idTipoFalla": tipoFalla,
            "idFalla": nombreFalla,
            "descripcionDeFalla": descripcionDeFalla,
            "idNivelSeveridad": nivelSeveridad,
            "idFallaDisponibilidad": null,
            "idUbicacion": ubicacion,
            "idServicio": servicio,
            "fechaOmision": fechaOmision,
            "fechaCierreCorrecta": fechaCierreCorrecta,
            "noDocFiscalizacion": noDocFiscalizacion,
            "evidencia": "",
            "fechaRegistroErrorOmision": null,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
            "solicitudServicioByIdSolicitudServicio": null,
            "tiposFallasByIdTipoFalla": null,
            "fallaByIdFalla": null,
            "nivelesSeveridadByIdNivelSeveridad": null,
            "fallasDisponibilidadByIdFallaDisponibilidad": null,
            "ubicacionByIdUbicacion": null,
            "servicioByServicio": null,
            "documentoFiscalizacionByIdDocumentoFiscalizacion": null,
            "idDocumentoFiscalizacion": tipoDocFiscalizacion
        }

        console.log(data);
        const formData = new FormData();
        if(formulario.pdf?.file) {
            formData.append('file', formulario.pdf.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        console.log(formData);
        const response = yield call(crearFolioEOCall, token, formData);
        // const response = {status:200}
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El folio de error u omisión fue creado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.NUEVO_FOLIO_ERROR_OMISION_SUCCESS });
            yield put({ type: mecanismosDePagosActions.FOLIOS_ERROR_OMISION_BUSQUEDA_REQUEST, page , busqueda});
            yield put({ type: mecanismosDePagosActions.MODAL_NUEVO_FOLIO_EO, showModalNuevoFolioEO: false});
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.NUEVO_FOLIO_ERROR_OMISION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.NUEVO_FOLIO_ERROR_OMISION_FAILURE });
    }
}
export function* crearFolioEOSaga() {
    yield takeLatest(mecanismosDePagosActions.NUEVO_FOLIO_ERROR_OMISION_REQUEST, crearFolioEO);
}

function* editarFolioEO(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {formulario={}, ticketSSConciliado=null, page=1, busqueda=""} = action;
        const {idSolicitudservicio=null,tipoFolio,idFolioErrorOmision} = ticketSSConciliado;

        console.log(idSolicitudservicio,ticketSSConciliado)
        let {tipoFalla="", nombreFalla="", descripcionDeFalla="", nivelSeveridad=null, ubicacion="", servicio="",
            noDocFiscalizacion="", tipoDocFiscalizacion="",
        } = formulario;

        tipoFalla = isNaN(tipoFalla) ? ticketSSConciliado.idTipoFalla : parseInt(tipoFalla);
        nombreFalla = isNaN(nombreFalla) ? ticketSSConciliado.idFalla : parseInt(nombreFalla);
        ubicacion = parseInt(ubicacion.split('-')[0]);
        servicio = parseInt(servicio.split('-')[0]);
        tipoDocFiscalizacion = isNaN(tipoDocFiscalizacion) ? ticketSSConciliado.idDocumentoFiscalizacion : parseInt(tipoDocFiscalizacion);

        if(nivelSeveridad) {
            nivelSeveridad = isNaN(nivelSeveridad) ? ticketSSConciliado?.idNivelSeveridad : parseInt(nivelSeveridad);
        }
        const fechaOmision = tipoFolio==='Omision' ? formulario?.fechaOmision.format('YYYY-MM-DDTHH:mm') : null;
        const fechaCierreCorrecta = formulario.fechaCierreEditar.format('YYYY-MM-DDTHH:mm')

        const data =  {
            "idFolioErrorOmision": null,
            "tipoFolio": tipoFolio,
            "estado": "Abierto",
            "idSolicitudServicio": idSolicitudservicio,
            "folioErrorOmision": null,
            "idTipoFalla": tipoFalla,
            "idFalla": nombreFalla,
            "descripcionDeFalla": descripcionDeFalla,
            "idNivelSeveridad": nivelSeveridad,
            "idFallaDisponibilidad": null,
            "idUbicacion": ubicacion,
            "idServicio": servicio,
            "fechaOmision": fechaOmision,
            "fechaCierreCorrecta": fechaCierreCorrecta,
            "noDocFiscalizacion": noDocFiscalizacion,
            "evidencia": "",
            "fechaRegistroErrorOmision": null,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
            "solicitudServicioByIdSolicitudServicio": null,
            "tiposFallasByIdTipoFalla": null,
            "fallaByIdFalla": null,
            "nivelesSeveridadByIdNivelSeveridad": null,
            "fallasDisponibilidadByIdFallaDisponibilidad": null,
            "ubicacionByIdUbicacion": null,
            "servicioByServicio": null,
            "documentoFiscalizacionByIdDocumentoFiscalizacion": null,
            "idDocumentoFiscalizacion": tipoDocFiscalizacion
        }

        console.log(data);
        const formData = new FormData();
        if(formulario.pdf?.file) {
            formData.append('file', formulario.pdf.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        console.log(formData);
        const response = yield call(editarFolioEOCall, token, formData, idFolioErrorOmision);
        // const response = {status:200}
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El folio de error u omisión fue editado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EDITAR_FOLIO_ERROR_OMISION_SUCCESS });
            yield put({ type: mecanismosDePagosActions.FOLIOS_ERROR_OMISION_BUSQUEDA_REQUEST, page , busqueda});
            yield put({ type: mecanismosDePagosActions.MODAL_EDITAR_FOLIO_EO, showModalEditarFolioEO: false, folioErrorOmisionSeleccionado: null});
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EDITAR_FOLIO_ERROR_OMISION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.EDITAR_FOLIO_ERROR_OMISION_FAILURE });
    }
}
export function* editarFolioEOSaga() {
    yield takeLatest(mecanismosDePagosActions.EDITAR_FOLIO_ERROR_OMISION_REQUEST, editarFolioEO);
}

function* cerrarFolioEO(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {idFolioErrorOmision, page=1, busqueda=""} = action;

        const response = yield call(cerrarFolioEOCall, token, idFolioErrorOmision);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El folio de error u omisión fue cerrado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.CERRAR_FOLIO_ERROR_OMISION_SUCCESS });
            yield put({ type: mecanismosDePagosActions.FOLIOS_ERROR_OMISION_BUSQUEDA_REQUEST, page , busqueda});
            yield put({ type: mecanismosDePagosActions.MODAL_CERRAR_FOLIO_EO, showModalCerrarFolioEO: false, folioErrorOmisionSeleccionado: null});
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.CERRAR_FOLIO_ERROR_OMISION_FAILURE });
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.CERRAR_FOLIO_ERROR_OMISION_FAILURE });
    }
}
export function* cerrarFolioEOSaga() {
    yield takeLatest(mecanismosDePagosActions.CERRAR_FOLIO_ERROR_OMISION_REQUEST, cerrarFolioEO);
}

// CU - Pago por Servicios
function* listaPagoServiciosMeses(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaPagoServiciosMesesCall, token);
        console.log('response', response);

        if (response.status == 200) {
            const listaPagoServiciosMeses = response.data.data;

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            for(let i in listaPagoServiciosMeses){
                if(listaPagoServiciosMeses[i].pmi === 0){
                    if(listaPagoServiciosMeses[i].mesCalendarioByIdMesCalendario){
                        if(listaPagoServiciosMeses[i].mesCalendarioByIdMesCalendario.pmi){
                            listaPagoServiciosMeses[i].pmiTabla = numberWithCommas(listaPagoServiciosMeses[i].mesCalendarioByIdMesCalendario.pmi.toFixed(2));
                        } else {
                            listaPagoServiciosMeses[i].pmiTabla = '-';
                        }
                    }
                } else {
                    listaPagoServiciosMeses[i].pmiTabla = numberWithCommas(listaPagoServiciosMeses[i].pmi.toFixed(2));
                }

                if(listaPagoServiciosMeses[i].pmr === 0){
                    if(listaPagoServiciosMeses[i].mesCalendarioByIdMesCalendario){
                        if(listaPagoServiciosMeses[i].mesCalendarioByIdMesCalendario.pmr){
                            listaPagoServiciosMeses[i].pmrTabla = numberWithCommas(listaPagoServiciosMeses[i].mesCalendarioByIdMesCalendario.pmr.toFixed(2));
                        } else {
                            listaPagoServiciosMeses[i].pmrTabla = '-';
                        }
                    }
                } else {
                    listaPagoServiciosMeses[i].pmrTabla = numberWithCommas(listaPagoServiciosMeses[i].pmr.toFixed(2));
                }

                if(listaPagoServiciosMeses[i].mesCalendarioByIdMesCalendario){
                    listaPagoServiciosMeses[i].anioCalendario = listaPagoServiciosMeses[i].mesCalendarioByIdMesCalendario.anioCalendarioByIdAnioCalendario.anio;
                    listaPagoServiciosMeses[i].mesCalendario = listaPagoServiciosMeses[i].mesCalendarioByIdMesCalendario.mesByIdMes.mes;
                }

                listaPagoServiciosMeses[i].dpaTabla = numberWithCommas(listaPagoServiciosMeses[i].dpa.toFixed(2));

                if(listaPagoServiciosMeses[i].idPagoServicio > 0){
                    listaPagoServiciosMeses[i].daTabla = numberWithCommas(listaPagoServiciosMeses[i].da.toFixed(2));
                    listaPagoServiciosMeses[i].cpdTabla = numberWithCommas(listaPagoServiciosMeses[i].cpd.toFixed(2));
                    listaPagoServiciosMeses[i].spTabla = numberWithCommas(listaPagoServiciosMeses[i].sp.toFixed(2));
                    listaPagoServiciosMeses[i].pmsTabla = numberWithCommas(listaPagoServiciosMeses[i].pms.toFixed(2));
                } else {
                    listaPagoServiciosMeses[i].daTabla = '-';
                    listaPagoServiciosMeses[i].cpdTabla = '-';
                    listaPagoServiciosMeses[i].spTabla = '-';
                    listaPagoServiciosMeses[i].pmsTabla = '-';
                }     
                
            }

            console.log('listaPagoServiciosMeses', listaPagoServiciosMeses);

            yield put({ type: mecanismosDePagosActions.LISTA_PAGO_POR_SERVICIO_MESES_SUCCESS, listaPagoServiciosMeses });
        }
        else {
            yield put({ type: mecanismosDePagosActions.LISTA_PAGO_POR_SERVICIO_MESES_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.LISTA_PAGO_POR_SERVICIO_MESES_FAILURE });
    }
} export function* listaPagoServiciosMesesSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_PAGO_POR_SERVICIO_MESES_REQUEST, listaPagoServiciosMeses);
}

function* calculoPagoServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {idMesCalendario} = action;

        const response = yield call(calculoPagoServicioCall, token, idMesCalendario);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El cálculo para el pago ha sido procesado con éxito.";
            const calculoPagoServicio = response.data;

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            if(calculoPagoServicio){
                calculoPagoServicio.showPMS = numberWithCommas(calculoPagoServicio.pms.toFixed(2));
            }

            console.log('calculoPagoServicio', calculoPagoServicio);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.CALCULAR_PAGO_SERVICIO_SUCCESS, calculoPagoServicio, visibleCalculoPagoServicio: true });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.CALCULAR_PAGO_SERVICIO_FAILURE });
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.CALCULAR_PAGO_SERVICIO_FAILURE });
    }
} export function* calculoPagoServicioSaga() {
    yield takeLatest(mecanismosDePagosActions.CALCULAR_PAGO_SERVICIO_REQUEST, calculoPagoServicio);
}

function* guardarPagoServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {idMesCalendario} = action;

        const response = yield call(guardarPagoServicioCall, token, idMesCalendario);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El cálculo ha sido guardado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.GUARDAR_CALCULO_PAGO_SERVICIO_SUCCESS, visibleCalculoPagoServicio: false });
            yield put({ type: mecanismosDePagosActions.LISTA_PAGO_POR_SERVICIO_MESES_REQUEST });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.GUARDAR_CALCULO_PAGO_SERVICIO_FAILURE });
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.GUARDAR_CALCULO_PAGO_SERVICIO_FAILURE });
    }
} export function* guardarPagoServicioSaga() {
    yield takeLatest(mecanismosDePagosActions.GUARDAR_CALCULO_PAGO_SERVICIO_REQUEST, guardarPagoServicio);
} 

function* pagoServicioDetalle(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {idMesCalendario} = action;

        const response = yield call(pagoServicioDetalleCall, token, idMesCalendario);
        console.log(response);

        if (response.status == 200) {
            let detallePagoServicio = response.data;
            let dtfsMayor = true;

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            //Detalle para Deducción Aplicable:
            detallePagoServicio.dtfsTabla = numberWithCommas(detallePagoServicio.dtfs.toFixed(2));
            detallePagoServicio.diTabla = numberWithCommas(detallePagoServicio.di.toFixed(2));
            //detallePagoServicio.diTabla = detallePagoServicio.diTabla + ' %';
            detallePagoServicio.pmrTabla = numberWithCommas(detallePagoServicio.pmr.toFixed(2));
            detallePagoServicio.pmr_diTabla = numberWithCommas((detallePagoServicio.di_pmr).toFixed(2));

            detallePagoServicio.dtfdTabla = numberWithCommas(detallePagoServicio.dtfd.toFixed(2));
            detallePagoServicio.dtfcTabla = numberWithCommas(detallePagoServicio.dtfc.toFixed(2));
            detallePagoServicio.dtfgsTabla = numberWithCommas(detallePagoServicio.dtfgs.toFixed(2));
            detallePagoServicio.dtrfTabla = numberWithCommas(detallePagoServicio.dtrf.toFixed(2));
            detallePagoServicio.dteoTabla = numberWithCommas(detallePagoServicio.dteo.toFixed(2));
            detallePagoServicio.dpaTabla = numberWithCommas(detallePagoServicio.dpa.toFixed(2));

            if(detallePagoServicio.dtfs < detallePagoServicio.di_pmr) { dtfsMayor = false }

            console.log('detallePagoServicio', detallePagoServicio);

            yield put({ type: mecanismosDePagosActions.PAGO_SERVICIO_DETALLE_SUCCESS, detallePagoServicio, dtfsMayor });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.PAGO_SERVICIO_DETALLE_FAILURE });
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.PAGO_SERVICIO_DETALLE_FAILURE });
    }
} export function* pagoServicioDetalleSaga() {
    yield takeLatest(mecanismosDePagosActions.PAGO_SERVICIO_DETALLE_REQUEST, pagoServicioDetalle);
}

function* cerrarPagoServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {idPagoServicio} = action;

        const response = yield call(cerrarPagoServicioCall, token, idPagoServicio);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El monto de pago por servicio del mes ha sido cerrado con éxito";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.CERRAR_PAGO_SERVICIO_SUCCESS });
            yield put({ type: mecanismosDePagosActions.LISTA_PAGO_POR_SERVICIO_MESES_REQUEST });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.CERRAR_PAGO_SERVICIO_FAILURE });
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.CERRAR_PAGO_SERVICIO_FAILURE });
    }
} export function* cerrarPagoServicioSaga() {
    yield takeLatest(mecanismosDePagosActions.CERRAR_PAGO_SERVICIO_REQUEST, cerrarPagoServicio);
}

// CU - Pago de servicios públicos

function* listaServiciosPublicosMes(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaServiciosPublicosMesCall, token, action.idMes, parseInt(action.page) - 1);
        console.log('response', response);

        if (response.status === 200) {
            let data = response.data.data;
            let cantidad = response.data.cantidad;

            function numberWithCommas(x) {
                return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            }

            for(let i in data){
                data[i].precioUnitario = numberWithCommas(data[i].precioUnitario.toFixed(4));
                data[i].consumoDesarrollador = numberWithCommas(data[i].consumoDesarrollador.toFixed(4));
                data[i].consumoPyrs = numberWithCommas(data[i].consumoPyrs.toFixed(4));
                data[i].totalConsumida = numberWithCommas(data[i].totalConsumida.toFixed(4));
                data[i].totalFacturado = numberWithCommas(data[i].totalFacturado.toFixed(4));
                data[i].tipoConsumo = data[i].servicioPublicoByIdServicioPublico.nombre;
                data[i].unidadMedicion = data[i].unidadMedidaByIdUnidadMedida.unidadMedida;
                data[i].expectativaMes = numberWithCommas(data[i].servicioPublicoByIdServicioPublico.expectativaMes.toFixed(4));
                data[i].expectativaMesAnio = numberWithCommas(data[i].servicioPublicoByIdServicioPublico.expectativaMesAnio.toFixed(4));
            }

            yield put({ type: mecanismosDePagosActions.LISTA_SERVICIOS_PUBLICOS_MES_SUCCESS, listaServiciosPublicosMes: data, listaServiciosPublicosMesCantidad: cantidad });
        }
        else {
            yield put({ type: mecanismosDePagosActions.LISTA_SERVICIOS_PUBLICOS_MES_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.LISTA_SERVICIOS_PUBLICOS_MES_FAILURE });
    }
} export function* listaServiciosPublicosMesSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_SERVICIOS_PUBLICOS_MES_REQUEST, listaServiciosPublicosMes);
}

function* listaServiciosPublicosGenerales(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaServiciosPublicosGeneralesCall, token, action.idMes, parseInt(action.page) - 1);
        console.log('response', response);

        if (response.status === 200) {
            let data = response.data.data;
            let cantidad = response.data.cantidad;

            function numberWithCommas(x) {
                return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            }

            for(let i in data){
                data.tipoConsumo = data.nombre;
                data[i].totalConsumidaDesarrollador = numberWithCommas(data[i].totalConsumidaDesarrollador.toFixed(4));
                data[i].totalPyrs = numberWithCommas(data[i].totalPyrs.toFixed(4));
                data[i].totalConsumida = numberWithCommas(data[i].totalConsumida.toFixed(4));
                data[i].totalPagar = numberWithCommas(data[i].totalPagar.toFixed(4));
                data[i].expectativaMes = numberWithCommas(data[i].expectativaMes.toFixed(4));
                data[i].expectativaMesAnio = numberWithCommas(data[i].expectativaMesAnio.toFixed(4));
            }

            yield put({ type: mecanismosDePagosActions.LISTA_SERVICIOS_PUBLICOS_GENERALES_SUCCESS, listaServiciosPublicosGenerales: data, listaServiciosPublicosGeneralesCantidad: cantidad });
        }
        else {
            yield put({ type: mecanismosDePagosActions.LISTA_SERVICIOS_PUBLICOS_GENERALES_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.LISTA_SERVICIOS_PUBLICOS_GENERALES_FAILURE });
    }
} export function* listaServiciosPublicosGeneralesSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_SERVICIOS_PUBLICOS_GENERALES_REQUEST, listaServiciosPublicosGenerales);
}

function* exportarPDFPagoDeServiciosPublicos(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const data = {
            "data": null
        }

        const response = yield call(exportarPDFPagoDeServiciosPublicosCall, token, action.idMes, data)
        console.log(response)

        if(response.status === 200){
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Pago de servicios públicos.pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: mecanismosDePagosActions.EXPORTAR_SERVICIOS_PUBLICOS_PDF_SUCCESS })

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage: 'El archivo se descargó de forma correcta' });

        } else {
            const showErrorMsg = true;
            const textMessage = 'No se pudo descargar el PDF con las columnas y filtros seleccionados';

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EXPORTAR_SERVICIOS_PUBLICOS_PDF_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.EXPORTAR_SERVICIOS_PUBLICOS_PDF_FAILURE });
    }
}
export function* exportarPDFPagoDeServiciosPublicosSaga(){
    yield takeLatest(mecanismosDePagosActions.EXPORTAR_SERVICIOS_PUBLICOS_PDF_REQUEST, exportarPDFPagoDeServiciosPublicos)
}

//RDO | Monitor de Deductivas
function* listaMesesProcesados(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let anio = ''; if(action.anio){ anio = '?anio=' + action.anio }

        const response = yield call(listaMesesProcesadosCall, token, anio );
        console.log(response);

        if (response.status == 200) {
            const listaMesesProcesados = response.data;
            for(let i in listaMesesProcesados){
                if(listaMesesProcesados[i].mesByIdMes){
                    listaMesesProcesados[i].mesCalendario = listaMesesProcesados[i].mesByIdMes.mes;
                }

                if(listaMesesProcesados[i].anioCalendarioByIdAnioCalendario){
                    listaMesesProcesados[i].anio = listaMesesProcesados[i].anioCalendarioByIdAnioCalendario.anio;
                }
            }

            if(listaMesesProcesados.length > 0){
                let ultimoMes = listaMesesProcesados[0];
                listaMesesProcesados.ultimoMes = ultimoMes;
            }

            console.log('listaMesesProcesados', listaMesesProcesados);

            yield put({ type: mecanismosDePagosActions.LISTA_MESES_PROCESADOS_SUCCESS, listaMesesProcesados });
        }
        else {
            yield put({ type: mecanismosDePagosActions.LISTA_MESES_PROCESADOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.LISTA_MESES_PROCESADOS_FAILURE });
    }
}
export function* listaMesesProcesadosSaga() {
    yield takeLatest(mecanismosDePagosActions.LISTA_MESES_PROCESADOS_REQUEST, listaMesesProcesados);
}

function* listaDeductivasRDOInternas(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const tipoDeductiva =  'Interna'
        let idMesCalendario = '';
        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario }

        const response = yield call(listaDeductivasRDOCall, token, tipoDeductiva, idMesCalendario );
        console.log(response);

        if (response.status == 200) {
            const listaRDODeductivasInternas = response.data.data;
            const colors = ['#E49B0F', '#FFCB03', '#bb9a02', '#e2b90b', '#E4D00A', '#FFBA00', '#c2b452', '#6B8E23', '#D9E542', '#9C9C00', '#DFFF00', '#C6CE00', '#75B313', '#B0BF1A', '#008000', '#00B564', '#31bb2f', '#1B4125', '#009975', '#35682D', '#71BC78', '#77DD77', '#04A404', '#2E8B57', '#009B7D', '#128385', '#1B7677', '#00554E'];

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            let etiquetas = [];
            let informacion = [];
            let totalEncuestas = 0;
            let etiquetasLarga = [];
            let informacionFormat = [];
                if(listaRDODeductivasInternas){
                    for(let i in listaRDODeductivasInternas){
                        etiquetas.push(listaRDODeductivasInternas[i].etiqueta);
                        informacion.push(listaRDODeductivasInternas[i].cantidad);
                        informacionFormat.push(numberWithCommas(listaRDODeductivasInternas[i].cantidad.toFixed(2)));
                        etiquetasLarga.push(listaRDODeductivasInternas[i].etiquetaLarga);
                        totalEncuestas += listaRDODeductivasInternas[i].cantidad;

                        listaRDODeductivasInternas[i].deduccion = numberWithCommas(listaRDODeductivasInternas[i].cantidad.toFixed(2));
                        listaRDODeductivasInternas[i].color = colors[i];
                    }
                }

                let infoRDO_Internas = {
                    'etiquetas': etiquetas,
                    'informacion': informacion,
                    'informacionFormt': informacionFormat,
                    'total': totalEncuestas,
                    'eLargas': etiquetasLarga,
                    'colors': colors
                };

            yield put({ type: mecanismosDePagosActions.RDO_DEDUCTIVAS_INTERNAS_SUCCESS, listaRDODeductivasInternas, infoRDO_Internas });
        }
        else {
            yield put({ type: mecanismosDePagosActions.RDO_DEDUCTIVAS_INTERNAS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.RDO_DEDUCTIVAS_INTERNAS_FAILURE });
    }
}
export function* listaDeductivasRDOInternasSaga() {
    yield takeLatest(mecanismosDePagosActions.RDO_DEDUCTIVAS_INTERNAS_REQUEST, listaDeductivasRDOInternas);
}

function* listaDeductivasRDOExternas(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const tipoDeductiva =  'Externa'
        let idMesCalendario = '';
        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario }

        const response = yield call(listaDeductivasRDOCall, token, tipoDeductiva, idMesCalendario );
        console.log(response);

        if (response.status == 200) {
            const listaRDODeductivasExternas = response.data.data;
            const colors = ['#7CB9E8', '#00438A', '#ACDCDD', '#72A0C1', '#7ccce5', '#CCCCFF', '#0048BA', '#87CEFA', '#1DACD6', '#3b8183', '#40E0D0', '#AFE4DE', '#48D1CC', '#A0D6B4', '#007E8B', '#40E0D0', '#ACDCDD', '#00209C', '#0D98BA', '#4000FF', '#0000FF', '#536878', '#3fb8af', '#547980', '#83af9b', '#00a0b0', '#00008B', '#330066', '#7F00FF', '#6A0DAD', '#9F2B68'];

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            let etiquetas = [];
            let informacion = [];
            let totalEncuestas = 0;
            let etiquetasLarga = [];
            let informacionFormat = [];
                if(listaRDODeductivasExternas){
                    for(let i in listaRDODeductivasExternas){
                        etiquetas.push(listaRDODeductivasExternas[i].etiqueta);
                        informacion.push(listaRDODeductivasExternas[i].cantidad);
                        informacionFormat.push(numberWithCommas(listaRDODeductivasExternas[i].cantidad.toFixed(2)));
                        etiquetasLarga.push(listaRDODeductivasExternas[i].etiquetaLarga);
                        totalEncuestas += listaRDODeductivasExternas[i].cantidad;

                        listaRDODeductivasExternas[i].deduccion = numberWithCommas(listaRDODeductivasExternas[i].cantidad.toFixed(2));
                        listaRDODeductivasExternas[i].color = colors[i];
                    }
                }

                let infoRDO_Externas = {
                    'etiquetas': etiquetas,
                    'informacion': informacion,
                    'informacionFormt': informacionFormat,
                    'total': totalEncuestas,
                    'eLargas': etiquetasLarga,
                    'colors': colors,
                };

            yield put({ type: mecanismosDePagosActions.RDO_DEDUCTIVAS_EXTERNAS_SUCCESS, listaRDODeductivasExternas, infoRDO_Externas });
        }
        else {
            yield put({ type: mecanismosDePagosActions.RDO_DEDUCTIVAS_EXTERNAS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.RDO_DEDUCTIVAS_EXTERNAS_FAILURE });
    }
}
export function* listaDeductivasRDOExternasSaga() {
    yield takeLatest(mecanismosDePagosActions.RDO_DEDUCTIVAS_EXTERNAS_REQUEST, listaDeductivasRDOExternas);
}

function* detalleDeductivaRDO(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const tipoGrafica = action.tipoGrafica;
        let idMesCalendario = '';
        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario }

        const response = yield call(detalleDeductivaRDOCall, token, tipoGrafica, idMesCalendario );
        console.log(response);

        if (response.status === 200) {
            const detalleDeductivaRDO = response.data;
            let columns = [];

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            for(let i in detalleDeductivaRDO.data){
                detalleDeductivaRDO.data[i].reduccionTabla = (detalleDeductivaRDO.data[i].reduccion).toFixed(2) + ' %';
                detalleDeductivaRDO.data[i].deductivaTabla = numberWithCommas(detalleDeductivaRDO.data[i].deductiva.toFixed(2));
                detalleDeductivaRDO.data[i].deductivaConciliadaTabla = numberWithCommas(detalleDeductivaRDO.data[i].deductivaConciliada.toFixed(2));
            }

            if (tipoGrafica === 'Servicio') {
                columns = [
                    { title: 'Servicio', dataIndex: 'servicio', key: 'servicio', width:'21%' },
                    { title: 'Nomenclatura', dataIndex: 'nomenclatura', key: 'nomenclatura', width:'8%' },
                    { title: 'Grupo de servicios', dataIndex: 'grupoSer', key: 'grupoSer', },
                    { title: 'Total tickets', dataIndex: 'tickets', key: 'tickets', align:'right', width:'7%' },
                    { title: 'Tickets deductiva', dataIndex: 'ticketsDeductiva', key: 'ticketsDeductiva', align:'right', width:'7%' },
                    { title: 'Tickets conciliados', dataIndex: 'ticketsConciliados', key: 'ticketsConciliados', align:'right', width:'7%' },
                    { title: 'Tickets de meses anteriores', dataIndex: 'ticketsAnteriores', key: 'ticketsAnteriores', align:'right', width:'7%' },
                    { title: 'Monto total original', dataIndex: 'deductivaTabla', key: 'deductivaTabla', align:'right', width:'9%'  },
                    { title: 'Monto total conciliado', dataIndex: 'deductivaConciliadaTabla', key: 'deductivaConciliadaTabla', align:'right', width:'9%'  },
                    { title: 'Reducción', dataIndex: 'reduccionTabla', key: 'reduccionTabla', align:'right', width:'7%' },
                ];
            } else {
                columns = [
                    { title: 'Grupo de servicios', dataIndex: 'grupoSer', key: 'grupoSer', },
                    { title: 'Total tickets', dataIndex: 'tickets', key: 'tickets', align:'right', width:'8%' },
                    { title: 'Tickets deductiva', dataIndex: 'ticketsDeductiva', key: 'ticketsDeductiva', align:'right', width:'8%' },
                    { title: 'Tickets conciliados', dataIndex: 'ticketsConciliados', key: 'ticketsConciliados', align:'right', width:'8%' },
                    { title: 'Tickets de meses anteriores', dataIndex: 'ticketsAnteriores', key: 'ticketsAnteriores', align:'right', width:'8%' },
                    { title: 'Monto total original', dataIndex: 'deductivaTabla', key: 'deductivaTabla', align:'right', width:'13%' },
                    { title: 'Monto total conciliado', dataIndex: 'deductivaConciliadaTabla', key: 'deductivaConciliadaTabla', align:'right', width:'13%' },
                    { title: 'Reducción', dataIndex: 'reduccionTabla', key: 'reduccionTabla', align:'right', width:'8%' },
                ];
            }

            detalleDeductivaRDO.columns = columns;

            yield put({ type: mecanismosDePagosActions.DETALLE_DEDUCTIVA_RDO_SUCCESS, detalleDeductivaRDO });
        }
        else {
            yield put({ type: mecanismosDePagosActions.DETALLE_DEDUCTIVA_RDO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.DETALLE_DEDUCTIVA_RDO_FAILURE });
    }
}
export function* detalleDeductivaRDOSaga() {
    yield takeLatest(mecanismosDePagosActions.DETALLE_DEDUCTIVA_RDO_REQUEST, detalleDeductivaRDO);
}

function* exportarDetalleDeductivaRDOExcel(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const tipoGrafica = action.tipoGrafica;
        let idMesCalendario = '';
        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario }

        const response = yield call(exportarDetalleDeductivaRDOExcelCall, token, tipoGrafica, idMesCalendario);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el archivo de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "DetalleMonitorDeductiva-" + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.DETALLE_DEDUCTIVA_RDO_EXCEL_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.DETALLE_DEDUCTIVA_RDO_EXCEL_FAILURE });
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.DETALLE_DEDUCTIVA_RDO_EXCEL_FAILURE });
    }
}
export function* exportarDetalleDeductivaRDOExcelSaga(){
    yield takeLatest(mecanismosDePagosActions.DETALLE_DEDUCTIVA_RDO_EXCEL_REQUEST, exportarDetalleDeductivaRDOExcel)
}

function* deductivaByServicioRDO(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const tipoGrafica = 'Servicio';
        let idMesCalendario = '';
        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario }

        const response = yield call(detalleDeductivaRDOCall, token, tipoGrafica, idMesCalendario );
        console.log(response);

        if (response.status === 200) {
            const deductivaByServicio = response.data.data;

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            for(let i in deductivaByServicio){
                deductivaByServicio[i].deductivaTabla = numberWithCommas(deductivaByServicio[i].deductiva.toFixed(2));
                deductivaByServicio[i].deductivaConciliadaTabla = numberWithCommas(deductivaByServicio[i].deductivaConciliada.toFixed(2));
            }

            let etiquetas = [];
            let informacionOriginal = [];
            let totalOriginal = 0;
            let totalConciliado = 0;
            let etiquetasLarga = [];
            let informacionFormatOriginal = [];
            let informacionConciliada = [];
            let informacionFormatConciliada = [];
                if(deductivaByServicio){
                    for(let i in deductivaByServicio){
                        etiquetas.push(deductivaByServicio[i].nomenclatura);
                        informacionOriginal.push(deductivaByServicio[i].deductiva.toFixed(2));
                        informacionFormatOriginal.push(deductivaByServicio[i].deductivaTabla);
                        etiquetasLarga.push(deductivaByServicio[i].servicio);
                        totalOriginal += deductivaByServicio[i].deductiva;
                        totalConciliado += deductivaByServicio[i].deductivaConciliada;
                        informacionConciliada.push(deductivaByServicio[i].deductivaConciliada.toFixed(2));
                        informacionFormatConciliada.push(deductivaByServicio[i].deductivaConciliadaTabla);
                    }
                }

                let infoRDO_DeductivaByServicio = {
                    'etiquetas': etiquetas,
                    'informacionOriginal': informacionOriginal,
                    'informacionFormatOriginal': informacionFormatOriginal,
                    'totalOriginal': totalOriginal,
                    'informacionConciliada': informacionConciliada,
                    'informacionFormatConciliada': informacionFormatConciliada,
                    'totalConciliado': totalConciliado,
                    'eLargas': etiquetasLarga,
                };

                console.log('infoRDO_DeductivaByServicio', infoRDO_DeductivaByServicio);

            yield put({ type: mecanismosDePagosActions.DEDUCTIVA_POR_SERVICIO_SUCCESS, deductivaByServicio, infoRDO_DeductivaByServicio });
        }
        else {
            yield put({ type: mecanismosDePagosActions.DEDUCTIVA_POR_SERVICIO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.DEDUCTIVA_POR_SERVICIO_FAILURE });
    }
}
export function* deductivaByServicioRDOSaga() {
    yield takeLatest(mecanismosDePagosActions.DEDUCTIVA_POR_SERVICIO_REQUEST, deductivaByServicioRDO);
}

function* deductivasAnterioresRDO(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idMesCalendario = '';
        if(action.idMesCalendario){ idMesCalendario = '?idMesCalendario=' + action.idMesCalendario }

        const response = yield call(deductivasAnterioresRDOCall, token, idMesCalendario );
        console.log(response);

        if (response.status == 200) {
            const deductivasAnterioresRDO = response.data.data;
            const colors = ['#E60026', '#6D071A', '#B21A27', '#b04644', '#900020', '#E51A4C', '#c06c84', '#C30B4E', '#A11C55', '#E61D52', '#D4442F', '#7C342B', '#e8aaa4', '#CB1D11', '#f07818', '#FF4500', '#FF6000', '#800000', '#d68189', '#d9baa2', '#591F0B', '#CD7F32', '#E6B57E', '#996B42', '#A2522B', '#B5651D', '#7a6a53', '#654321', '#D19538', '#B5783A'];

            let etiquetas = [];
            let informacion = [];
            let totalEncuestas = 0;
            let etiquetasLarga = [];
            
                if(deductivasAnterioresRDO){
                    for(let i in deductivasAnterioresRDO){
                        etiquetas.push(deductivasAnterioresRDO[i].etiqueta);
                        informacion.push(deductivasAnterioresRDO[i].cantidad);
                        etiquetasLarga.push(deductivasAnterioresRDO[i].etiquetaLarga);
                        totalEncuestas += deductivasAnterioresRDO[i].cantidad;
                        deductivasAnterioresRDO[i].color = colors[i];
                    }
                }

                let infoRDO_DeductivasAnteriores = {
                    'etiquetas': etiquetas,
                    'informacion': informacion,
                    'total': totalEncuestas,
                    'eLargas': etiquetasLarga,
                    'colors': colors,
                };

                console.log('infoRDO_DeductivasAnteriores', infoRDO_DeductivasAnteriores);

            yield put({ type: mecanismosDePagosActions.DEDUCTIVAS_ANTERIORES_RDO_SUCCESS, deductivasAnterioresRDO, infoRDO_DeductivasAnteriores });
        }
        else {
            yield put({ type: mecanismosDePagosActions.DEDUCTIVAS_ANTERIORES_RDO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.DEDUCTIVAS_ANTERIORES_RDO_FAILURE });
    }
}
export function* deductivasAnterioresRDOSaga() {
    yield takeLatest(mecanismosDePagosActions.DEDUCTIVAS_ANTERIORES_RDO_REQUEST, deductivasAnterioresRDO);
}

//  Reporte de Deductivas 
function* getResumenReporteDeductivas(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let tipoDeductiva = action.tipoDeductiva;
        let idMesCalendario = '';
        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario }

        const response = yield call(getResumenReporteDeductivasCall, token, tipoDeductiva, idMesCalendario );
        console.log(response);

        if (response.status == 200) {
            const resumenReporteDeductivas = response.data.data;
            let columnsExternas = [];
            let columnsInternas = [];
            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            for(let i in resumenReporteDeductivas){
                columnsExternas = [
                    { title: 'Servicio', dataIndex: 'servicio', key: 'servicio', align:'center' },
                ];

                columnsInternas = [
                    { title: 'Proveedor', dataIndex: 'proveedor', key: 'proveedor', align:'center' },
                    { title: 'Servicio', dataIndex: 'servicio', key: 'servicio', align:'center' },
                ];


                if(resumenReporteDeductivas[i].graficaResponseList){
                    for (let j in resumenReporteDeductivas[i].graficaResponseList){
                        let tipoFalla = resumenReporteDeductivas[i].graficaResponseList[j].etiqueta.replace(/\s+/g, '');
                        resumenReporteDeductivas[i][tipoFalla] = numberWithCommas(resumenReporteDeductivas[i].graficaResponseList[j].cantidad.toFixed(2));
                        let column =
                            {
                                'title': resumenReporteDeductivas[i].graficaResponseList[j].etiqueta,
                                'dataIndex': tipoFalla,
                                'key': tipoFalla,
                                'align': 'right'
                            }
                        columnsExternas.push(column);
                        columnsInternas.push(column);
                    }
                    columnsExternas.push( { title: 'Cantidad de tickets', dataIndex: 'foliosByServicio', key: 'foliosByServicio', align:'right' } );
                    columnsExternas.push({ title: 'Total', dataIndex: 'totalDeductiva', key: 'totalDeductiva', align:'right' });

                    columnsInternas.push({ title: 'Cantidad de tickets', dataIndex: 'foliosByServicio', key: 'foliosByServicio', align:'right' });
                    columnsInternas.push({ title: 'Total', dataIndex: 'totalDeductiva', key: 'totalDeductiva', align:'right' });
                }
                resumenReporteDeductivas[i].totalDeductiva = numberWithCommas(resumenReporteDeductivas[i].deductivaAcumulada.toFixed(2));
            }

            resumenReporteDeductivas.columnsExternas = columnsExternas;
            resumenReporteDeductivas.columnsInternas = columnsInternas;

            console.log('resumenReporteDeductivas DESPUÉS', resumenReporteDeductivas);

            yield put({ type: mecanismosDePagosActions.REPORTE_DEDUCTIVAS_RESUMEN_SUCCESS, resumenReporteDeductivas });
        }
        else {
            yield put({ type: mecanismosDePagosActions.REPORTE_DEDUCTIVAS_RESUMEN_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.REPORTE_DEDUCTIVAS_RESUMEN_FAILURE });
    }
}
export function* getResumenReporteDeductivasSaga() {
    yield takeLatest(mecanismosDePagosActions.REPORTE_DEDUCTIVAS_RESUMEN_REQUEST, getResumenReporteDeductivas);
}

function* getResumenReporteDeductivasExcel(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idMesCalendario = '';
        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario }
        let tipoDeductiva = action.tipoDeductiva;

        const response = yield call(getResumenReporteDeductivasExcelCall, token, tipoDeductiva, idMesCalendario);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el archivo de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "ResumenDeductiva"  + tipoDeductiva + "-" + action.mesTxt + action.anio + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EXCEL_REPORTE_DEDUCTIVAS_RESUMEN_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EXCEL_REPORTE_DEDUCTIVAS_RESUMEN_FAILURE });
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.EXCEL_REPORTE_DEDUCTIVAS_RESUMEN_FAILURE });
    }
}
export function* getResumenReporteDeductivasExcelSaga(){
    yield takeLatest(mecanismosDePagosActions.EXCEL_REPORTE_DEDUCTIVAS_RESUMEN_REQUEST, getResumenReporteDeductivasExcel)
}

function* getDetalleResumenReporteDeductivas(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idMesCalendario = '';
        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario }
        let tipoDeductiva = action.tipoDeductiva;

        const response = yield call(getDetalleResumenReporteDeductivasCall, token, tipoDeductiva, idMesCalendario );
        console.log(response);

        if (response.status == 200) {
            const detalleResumenReporteDeductivas = response.data.data;
            const detalleResumenReporteDeductivasCantidad = response.data.cantidad;
            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            if(detalleResumenReporteDeductivasCantidad > 0){
                for(let i in detalleResumenReporteDeductivas){
                    if(detalleResumenReporteDeductivas[i].solicitudByIdSolicitudServicio){
                        detalleResumenReporteDeductivas[i].ticket = detalleResumenReporteDeductivas[i].solicitudByIdSolicitudServicio.folio;

                        detalleResumenReporteDeductivas[i].origen = detalleResumenReporteDeductivas[i].solicitudByIdSolicitudServicio.nombreUsuario;

                        detalleResumenReporteDeductivas[i].descripcionSolicitudBySolicitud= detalleResumenReporteDeductivas[i].solicitudByIdSolicitudServicio.descripcionSolicitud;

                        if(detalleResumenReporteDeductivas[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra){
                            if(detalleResumenReporteDeductivas[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.aMaterno === null || detalleResumenReporteDeductivas[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.aMaterno === ""){
                                detalleResumenReporteDeductivas[i].personalCCO = detalleResumenReporteDeductivas[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.nombre + ' ' + detalleResumenReporteDeductivas[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.aPaterno;
                            } else {
                                detalleResumenReporteDeductivas[i].personalCCO = detalleResumenReporteDeductivas[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.nombre + ' ' + detalleResumenReporteDeductivas[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.aPaterno + ' ' + detalleResumenReporteDeductivas[i].solicitudByIdSolicitudServicio.usuarioByIdUsuarioRegistra.aMaterno;;
                            }
                        }
                    } else {
                        detalleResumenReporteDeductivas[i].ticket = detalleResumenReporteDeductivas[i].folioOrigen;
                        detalleResumenReporteDeductivas[i].origen = '-';
                        detalleResumenReporteDeductivas[i].personalCCO = '-';
                    }

                    detalleResumenReporteDeductivas[i].fechaAltaTabla = detalleResumenReporteDeductivas[i].fechaAlta.split('T')[0].split('-').reverse().join('-') + ' ' + detalleResumenReporteDeductivas[i].fechaAlta.split('T')[1].split(':')[0] + ':' + detalleResumenReporteDeductivas[i].fechaAlta.split('T')[1].split(':')[1];

                    detalleResumenReporteDeductivas[i].fechaCierreTabla = detalleResumenReporteDeductivas[i].fechaCierre.split('T')[0].split('-').reverse().join('-') + ' ' + detalleResumenReporteDeductivas[i].fechaCierre.split('T')[1].split(':')[0] + ':' + detalleResumenReporteDeductivas[i].fechaCierre.split('T')[1].split(':')[1];

                    detalleResumenReporteDeductivas[i].pmiTabla = detalleResumenReporteDeductivas[i].pmi.toLocaleString('en-US', {'maximumFractionDigits': 2});

                    detalleResumenReporteDeductivas[i].pmrTabla = detalleResumenReporteDeductivas[i].pmr.toLocaleString('en-US', {'maximumFractionDigits': 2});
                    detalleResumenReporteDeductivas[i].deductivaTabla = detalleResumenReporteDeductivas[i].deductiva.toLocaleString('en-US', {'maximumFractionDigits': 2});

                    if(detalleResumenReporteDeductivas[i].deductivaParcial){
                        detalleResumenReporteDeductivas[i].deductivaParcialTexto = 'Sí'
                    } else { detalleResumenReporteDeductivas[i].deductivaParcialTexto = 'No' }

                    if(detalleResumenReporteDeductivas[i].fallaCalidad){
                        detalleResumenReporteDeductivas[i].fallaCalidadTabla = detalleResumenReporteDeductivas[i].fallaCalidad;
                    } else { detalleResumenReporteDeductivas[i].fallaCalidadTabla = '-' }

                    if(detalleResumenReporteDeductivas[i].fsa){
                        detalleResumenReporteDeductivas[i].fsaTabla = detalleResumenReporteDeductivas[i].fsa.toFixed(2);
                    } else { detalleResumenReporteDeductivas[i].fsaTabla = '-' }

                    if(detalleResumenReporteDeductivas[i].ps){
                        detalleResumenReporteDeductivas[i].psTabla = detalleResumenReporteDeductivas[i].ps.toFixed(2) + '%';
                    } else { detalleResumenReporteDeductivas[i].psTabla = '-' }

                    if(detalleResumenReporteDeductivas[i].pcfc){
                        detalleResumenReporteDeductivas[i].pcfcTabla = detalleResumenReporteDeductivas[i].pcfc.toFixed(2) + '%';
                    } else { detalleResumenReporteDeductivas[i].pcfcTabla = '-' }

                    if(detalleResumenReporteDeductivas[i].psa){
                        detalleResumenReporteDeductivas[i].psaTabla = detalleResumenReporteDeductivas[i].psa.toFixed(2) + '%';
                    } else { detalleResumenReporteDeductivas[i].psaTabla = '-' }

                    if(detalleResumenReporteDeductivas[i].pr){
                        detalleResumenReporteDeductivas[i].prTabla = detalleResumenReporteDeductivas[i].pr;
                    } else { detalleResumenReporteDeductivas[i].prTabla = '-' }

                    if(detalleResumenReporteDeductivas[i].ponderacionServArea){
                        detalleResumenReporteDeductivas[i].ponderacionServAreaTabla = detalleResumenReporteDeductivas[i].ponderacionServArea;
                    } else { detalleResumenReporteDeductivas[i].ponderacionServAreaTabla = '-' }

                    if(detalleResumenReporteDeductivas[i].ticketReiterado){
                        detalleResumenReporteDeductivas[i].ticketReiteradoTabla = detalleResumenReporteDeductivas[i].ticketReiterado;
                    } else { detalleResumenReporteDeductivas[i].ticketReiteradoTabla = '-' }

                    if(detalleResumenReporteDeductivas[i].numReferencia){
                        detalleResumenReporteDeductivas[i].numReferenciaTabla = detalleResumenReporteDeductivas[i].numReferencia;
                    } else { detalleResumenReporteDeductivas[i].numReferenciaTabla = '-' }

                    if(detalleResumenReporteDeductivas[i].tiempoGarantiaByIdSolicitudServicio){
                        let tg = '';
                        tg = detalleResumenReporteDeductivas[i].tiempoGarantiaByIdSolicitudServicio.tiempoGarantia;
                        if(detalleResumenReporteDeductivas[i].tiempoGarantiaByIdSolicitudServicio.unidadesMedidasByIdUnidadMedida){
                            detalleResumenReporteDeductivas[i].tiempoGarantiaTabla = tg + ' ' + detalleResumenReporteDeductivas[i].tiempoGarantiaByIdSolicitudServicio.unidadesMedidasByIdUnidadMedida.unidadMedida;
                        }
                    } else { detalleResumenReporteDeductivas[i].tiempoGarantiaTabla = '-' }

                    if(detalleResumenReporteDeductivas[i].ut){
                        detalleResumenReporteDeductivas[i].utTabla = detalleResumenReporteDeductivas[i].ut;
                    } else { detalleResumenReporteDeductivas[i].utTabla = '-' }

                    if(detalleResumenReporteDeductivas[i].utf){
                        detalleResumenReporteDeductivas[i].utfTabla = detalleResumenReporteDeductivas[i].utf;
                    } else { detalleResumenReporteDeductivas[i].utfTabla = '-' }

                    if(detalleResumenReporteDeductivas[i].pdfd){
                        detalleResumenReporteDeductivas[i].pdfdTabla = detalleResumenReporteDeductivas[i].pdfd.toFixed(2) + '%';
                    } else { detalleResumenReporteDeductivas[i].pdfdTabla = '-' }

                    if(detalleResumenReporteDeductivas[i].fallaDisponibilidad){
                        detalleResumenReporteDeductivas[i].fallaDisponibilidadTabla = detalleResumenReporteDeductivas[i].fallaDisponibilidad;
                    } else { detalleResumenReporteDeductivas[i].fallaDisponibilidadTabla = '-' }

                    if(detalleResumenReporteDeductivas[i].tipoDeductiva === 'Disponibilidad'){
                        detalleResumenReporteDeductivas[i].referenciaEstandarTabla = '-';
                        detalleResumenReporteDeductivas[i].descripcionEstandarTabla = '-';
                    } else {
                        detalleResumenReporteDeductivas[i].referenciaEstandarTabla = detalleResumenReporteDeductivas[i].referenciaEstandar;
                        detalleResumenReporteDeductivas[i].descripcionEstandarTabla = detalleResumenReporteDeductivas[i].descripcionEstandar;
                    }

                    if(detalleResumenReporteDeductivas[i].numeroFallaCategoria){
                        detalleResumenReporteDeductivas[i].numeroFallaCategoriaTabla = detalleResumenReporteDeductivas[i].numeroFallaCategoria;
                    } else { detalleResumenReporteDeductivas[i].numeroFallaCategoriaTabla = '-'; }

                    // if(detalleResumenReporteDeductivas[i].nombreProveedor){
                    //     detalleResumenReporteDeductivas[i].nombreProveedorTabla = detalleResumenReporteDeductivas[i].nombreProveedor;
                    // } else {detalleResumenReporteDeductivas[i].nombreProveedorTabla = 'Sin proveedor asignado';}

                    //PENDIENTE
                    detalleResumenReporteDeductivas[i].fechaCierreMesTabla = '-'
                }
            }  

            console.log('detalleResumenReporteDeductivas', detalleResumenReporteDeductivas);

            yield put({ type: mecanismosDePagosActions.DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_SUCCESS, detalleResumenReporteDeductivas, detalleResumenReporteDeductivasCantidad });
        }
        else {
            yield put({ type: mecanismosDePagosActions.DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_FAILURE });
    }
}
export function* getDetalleResumenReporteDeductivasSaga() {
    yield takeLatest(mecanismosDePagosActions.DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_REQUEST, getDetalleResumenReporteDeductivas);
}

function* getDetalleResumenReporteDeductivasExcel(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idMesCalendario = '';
        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario }
        let tipoDeductiva = action.tipoDeductiva;

        const response = yield call(getDetalleResumenReporteDeductivasExcelCall, token, tipoDeductiva, idMesCalendario);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el archivo de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "DetalleDeductiva"  + tipoDeductiva + "-" + action.mesTxt + action.anio + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EXCEL_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EXCEL_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_FAILURE });
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.EXCEL_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_FAILURE });
    }
}
export function* getDetalleResumenReporteDeductivasExcelSaga(){
    yield takeLatest(mecanismosDePagosActions.EXCEL_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_REQUEST, getDetalleResumenReporteDeductivasExcel)
}

function* getDetalleReporteDeductivasPDF(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idMesCalendario = '';
        if(action.idMesCalendario){ idMesCalendario = '&idMesCalendario=' + action.idMesCalendario }
        let tipoDeductiva = action.tipoDeductiva;

        const response = yield call(getDetalleReporteDeductivasPDFCall, token, tipoDeductiva, idMesCalendario);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el archivo de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "DetalleDeductiva"  + tipoDeductiva + "-" + action.mesTxt + action.anio + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.PDF_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.PDF_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_FAILURE });
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.PDF_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_FAILURE });
    }
}
export function* getDetalleReporteDeductivasPDFSaga(){
    yield takeLatest(mecanismosDePagosActions.PDF_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_REQUEST, getDetalleReporteDeductivasPDF)
}

function* getConcentradoAnualResumen(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {tipoDeductiva, fechaInicio, fechaFin} = action

        const response = yield call(getConcentradoAnualResumenCall, token, tipoDeductiva, fechaInicio, fechaFin );
        console.log(response);

        if (response.status === 200) {
            const concentradoAnualResumen = response.data.data;
            let concentrado = [];

            if(concentradoAnualResumen.length > 0){
                let columnsSaga = [];
                let listaAreasFuncionales = [];
                concentradoAnualResumen.forEach(function(elemento) { listaAreasFuncionales.push(elemento.areaFuncional); });
                let uniqueAreasFuncionales = [...new Set(listaAreasFuncionales)];

                for(let i in concentradoAnualResumen){
                    columnsSaga = [{title:'Servicio', dataIndex:'servicio', key:'servicio', align:'center'}];
                    for(let j in concentradoAnualResumen[i].graficaResponseList){
                        let tipoFalla = concentradoAnualResumen[i].graficaResponseList[j].etiqueta.replace(/\s+/g, '');
                        concentradoAnualResumen[i][tipoFalla] = concentradoAnualResumen[i].graficaResponseList[j].cantidad;
                        let column =
                            {
                                'title': concentradoAnualResumen[i].graficaResponseList[j].etiqueta,
                                'dataIndex': tipoFalla,
                                'key': tipoFalla,
                                'align': 'right'
                            }
                        columnsSaga.push(column);
                    }
                    columnsSaga.push({title:'Total', dataIndex:'totalFolios', key:'totalFolios', align:'right'});
                    concentradoAnualResumen[i].columnsSaga = columnsSaga;
                }

                for(let i in uniqueAreasFuncionales){
                    concentrado.push({ areaFuncional: uniqueAreasFuncionales[i]});
                }

                
                for(let i in uniqueAreasFuncionales){
                    let servicios = [];
                    for(let j in concentradoAnualResumen){
                        if(concentradoAnualResumen[j].areaFuncional === concentrado[i].areaFuncional){
                            servicios.push(concentradoAnualResumen[j])
                        }
                    }
                    concentrado[i].servicios = servicios
                }
            }

            yield put({ type: mecanismosDePagosActions.CONCENTRADO_ANUAL_RESUMEN_SUCCESS, concentradoAnualResumen, concentrado });
        }
        else {
            yield put({ type: mecanismosDePagosActions.CONCENTRADO_ANUAL_RESUMEN_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: mecanismosDePagosActions.CONCENTRADO_ANUAL_RESUMEN_FAILURE });
    }
}
export function* getConcentradoAnualResumenSaga() {
    yield takeLatest(mecanismosDePagosActions.CONCENTRADO_ANUAL_RESUMEN_REQUEST, getConcentradoAnualResumen);
}

function* getDetalleConcentradoAnual(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let {tipoDeductiva, fechaInicio, fechaFin, page} = action;
        page = (page - 1) * 10;

        const response = yield call(getDetalleConcentradoAnualCall, token, tipoDeductiva, fechaInicio, fechaFin, page);
        console.log(response);

        if (response.status == 200) {
            let detalleConcentradoAnual = response.data.data;
            let detalleConcentradoAnualCantidad = response.data.cantidad;

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            for(let i in detalleConcentradoAnual){
                if(detalleConcentradoAnual[i].fechaAlta){
                    let fechaAlta = detalleConcentradoAnual[i].fechaAlta.split('T')[0];
                    let Alta = detalleConcentradoAnual[i].fechaAlta.split('T')[1];
                    detalleConcentradoAnual[i].fechaAltaTabla = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0] + ' ' + Alta.split(':')[0] + ':' + Alta.split(':')[1];
                } else {
                    detalleConcentradoAnual[i].fechaAltaTabla = '-';
                }

                if(detalleConcentradoAnual[i].fechaCierre){
                    let fechaCierre = detalleConcentradoAnual[i].fechaCierre.split('T')[0];
                    let horaCierre = detalleConcentradoAnual[i].fechaCierre.split('T')[1];
                    detalleConcentradoAnual[i].fechaCierreTabla = fechaCierre.split('-')[2] + '-' + fechaCierre.split('-')[1] + '-' + fechaCierre.split('-')[0] + ' ' + horaCierre.split(':')[0] + ':' + horaCierre.split(':')[1];
                } else {
                    detalleConcentradoAnual[i].fechaCierreTabla = '-';
                }

                if(detalleConcentradoAnual[i].fechaTerminacion){
                    let fechaTerminacion = detalleConcentradoAnual[i].fechaTerminacion.split('T')[0];
                    let horaTerminacion = detalleConcentradoAnual[i].fechaTerminacion.split('T')[1];
                    detalleConcentradoAnual[i].fechaTerminacionTabla = fechaTerminacion.split('-')[2] + '-' + fechaTerminacion.split('-')[1] + '-' + fechaTerminacion.split('-')[0] + ' ' + horaTerminacion.split(':')[0] + ':' + horaTerminacion.split(':')[1];
                } else {
                    detalleConcentradoAnual[i].fechaTerminacionTabla = '-';
                }

                detalleConcentradoAnual[i].deductivaTabla = numberWithCommas(detalleConcentradoAnual[i].deductiva.toFixed(2));
            }

            yield put({ type: mecanismosDePagosActions.DETALLE_CONCENTRADO_ANUAL_RESUMEN_SUCCESS, detalleConcentradoAnual, detalleConcentradoAnualCantidad });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.DETALLE_CONCENTRADO_ANUAL_RESUMEN_FAILURE });
        }

    } catch (error) {
        yield put({ type: mecanismosDePagosActions.DETALLE_CONCENTRADO_ANUAL_RESUMEN_FAILURE });
    }
} export function* getDetalleConcentradoAnualSaga() {
    yield takeLatest(mecanismosDePagosActions.DETALLE_CONCENTRADO_ANUAL_RESUMEN_REQUEST, getDetalleConcentradoAnual);
} 

function* getResumenConcentradoAnualExcel(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let {tipoDeductiva, fechaInicio, fechaFin} = action;

        const response = yield call(getResumenConcentradoAnualExcelCall, token, tipoDeductiva, fechaInicio, fechaFin);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el archivo de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "ResumenConcentradoAnual"  + tipoDeductiva + "" + action.fechaInicio + '_' + action.fechaFin + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EXCEL_CONCENTRADO_ANUAL_RESUMEN_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EXCEL_CONCENTRADO_ANUAL_RESUMEN_FAILURE });
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.EXCEL_CONCENTRADO_ANUAL_RESUMEN_FAILURE });
    }
}
export function* getResumenConcentradoAnualExcelSaga(){
    yield takeLatest(mecanismosDePagosActions.EXCEL_CONCENTRADO_ANUAL_RESUMEN_REQUEST, getResumenConcentradoAnualExcel)
}


function* getDetalleConcentradoAnualExcel(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let {tipoDeductiva, fechaInicio, fechaFin} = action;

        const response = yield call(getDetalleConcentradoAnualExcelCall, token, tipoDeductiva, fechaInicio, fechaFin);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el archivo de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "DetalleConcentradoAnual"  + tipoDeductiva + "" + action.fechaInicio + '_' + action.fechaFin + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EXCEL_DETALLE_CONCENTRADO_ANUAL_RESUMEN_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: mecanismosDePagosActions.EXCEL_DETALLE_CONCENTRADO_ANUAL_RESUMEN_FAILURE });
        }
    } catch (error) {
        yield put({ type: mecanismosDePagosActions.EXCEL_DETALLE_CONCENTRADO_ANUAL_RESUMEN_FAILURE });
    }
}
export function* getDetalleConcentradoAnualExcelSaga(){
    yield takeLatest(mecanismosDePagosActions.EXCEL_DETALLE_CONCENTRADO_ANUAL_RESUMEN_REQUEST, getDetalleConcentradoAnualExcel)
}