import axios from "axios";

//const endpoint = "https://papantla-services.herokuapp.com/";
//const endpoint = "https://papantla-dev.herokuapp.com/";
const endpoint = 'https://morelos-dev.herokuapp.com/';
//const endpoint = "https://papantla-demo.herokuapp.com/";

//const endpoint = "http://172.24.0.170:8080/"

//const endpoint = 'https://papantla-services-jose.herokuapp.com/';
//const endpoint = 'https://papantla-dev-jose.herokuapp.com/';

//const endpoint = 'https://papantla-secret-jose.herokuapp.com/';
//const endpoint = 'https://papantla-secret.herokuapp.com/';


// Alta de Personal
export function listaPersonalBusquedaCall(token, param, servicio, field, order, estado, page) {
	return axios({
	    method: "get",
		url: endpoint + "personal?" + param + servicio + field + order + estado + "page="+ page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaPersonalServicioCall(token, param, idServicio) {
	return axios({
	    method: "get",
		url: endpoint + "orden/servicio/planificado/personal?idServicio=" + idServicio + '&nombre=' + param,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function cambiarEstadoPersonalCall(token, idPersonal, data) {
	return axios({
		method: "post",
		url: endpoint + "personal/activa/" + idPersonal,
		headers: {'Content-Type': 'application/json', 'Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function descargarExcelPersonalCall(token, param, servicio, estado, field, order) {
	return axios({
        method: "get",
        responseType: 'blob',
		url: endpoint + "personal/export/excel/completo?" + param + servicio + 'estado=' + estado + field + order,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function descargarPDFCompletoPersonalCall(token, param, servicio, estado, field, order) {
	return axios({
        method: "get",
        responseType: 'blob',
		url: endpoint + "personal/export/pdf/completo?" + param + servicio + 'estado=' + estado + field + order,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function descargarPDFReducidoPersonalCall(token, param, servicio, estado, field, order) {
	return axios({
        method: "get",
        responseType: 'blob',
		url: endpoint + "personal/export/pdf/reducido?" + param + servicio + 'estado=' + estado + field + order,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function historialPersonalCall(token, idPersonal, fechaInicio, fechaFin, page) {
	return axios({
		method: "get",
		url: endpoint + "personal/historial?idPersonal=" + idPersonal + fechaInicio + fechaFin + '&page=' + page + '&offset=10',
        headers: {'Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function nuevoPersonalCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "personal",
        headers: {'Authorization':token},
        data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarPersonalCall(token, idPersonal, data) {
	return axios({
		method: "put",
		url: endpoint + "personal/" + idPersonal,
        headers: {'Authorization':token},
        data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Servicios de control de asistencias
export function listaControlAsistenciasBusquedaCall(token, param, servicio, fechaInicio, fechaFin, field, order, page) {
	return axios({
        method: "get",
		url: endpoint + "personal/asistencia?" + param + servicio + fechaInicio + fechaFin + 'field=' + field + '&order=' + order + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaControlAsistenciasExportarCall(token, param, servicio, fechaInicio, fechaFin, field, order) {
	return axios({
		method: "get",
		responseType: 'blob',
		url: endpoint + "personal/asistencia/export?" + param + servicio + fechaInicio + fechaFin + 'field=' + field + '&order=' + order,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function importarBiometricoCall(token) {
	return axios({
		method: "get",
		url: endpoint + "personal/asistencia/import",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Reporte dinámico
export function reporteDinamicoRHCall(token, data) {
	return axios({
		method: "post",
	    url: endpoint + "personal/reporte/dinamico",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function reporteDinamicoRHAgrupadoCall(token, data) {
	return axios({
		method: "post",
	    url: endpoint + "personal/reporte/dinamico/agrupado",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function reporteDinamicoPDFRHCall(token, data) {
	return axios({
		method: "post",
		responseType: 'blob',
	    url: endpoint + "personal/reporte/dinamico/pdf",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function reporteDinamicoExcelRHCall(token, data) {
	return axios({
		method: "post",
		responseType: 'blob',
	    url: endpoint + "personal/reporte/dinamico/excel",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaGrupoServiciosByIdUsuarioCall(token, idUsuario) {
	return axios({
	    	method: "get",
		url: endpoint + "servicios/grupos/by/" + idUsuario,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaGerenciasByIdUsuarioCall(token, idUsuario) {
	return axios({
	    	method: "get",
		url: endpoint + "clasificacion/gerencias/by/" + idUsuario,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function reporteDinamicoPDFAgrupadoRHCall(token, data) {
	return axios({
		method: "post",
		responseType: 'blob',
	    	url: endpoint + "personal/reporte/dinamico/agrupado/pdf",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function reporteDinamicoExcelAgrupadoRHCall(token, data) {
	return axios({
		method: "post",
		responseType: 'blob',
	    	url: endpoint + "personal/reporte/dinamico/agrupado/excel",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getAllRegistrosRHCall(token, page) {
	return axios({
		method: "get",
		url: endpoint + 'personal/reporte/dinamico/registro/todos?page=' + page + '&offset=10', 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getRegistrosByTokenRHCall(token, page) {
	return axios({
		method: "get",
		url: endpoint + 'personal/reporte/dinamico/registro/?page=' + page + '&offset=10', 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearRegistroReporteRHCall(token, data) {
	return axios({
		method: "post",
	    url: endpoint + "personal/reporte/dinamico/registro",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoRegistroReporteRHCall(token, idReporte) {
	return axios({
		method: "get",
		url: endpoint + 'personal/reporte/dinamico/registro/activar/' + idReporte , 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarRegistroReporteRHCall(token, data, idReportePersonal) {
	return axios({
		method: "put",
		url: endpoint + "personal/reporte/dinamico/registro/" + idReportePersonal,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}