import { takeLatest, call, put } from "redux-saga/effects";
import * as BaseConocimientoActions from '../actions/BaseDeConocimientoActions';
import * as configuracionYSeguridadActions from '../actions/ConfiguracionYSeguridadActions'
import {listaDocumentosBaseCall, crearDocumentoBaseCall,editarDocumentosBaseCall, exportarExcelCall,
} from '../../services/BaseDeConocimientoServices';

// BASE DE CONOCIMIENTO
function* listaDocumentosBaseBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = (action.page - 1) * 10;
        let param = null;
        let idServicios = null;
        let order = 'ASC';
        let tipoConocimiento = '';
        if(action.formulario.servicios){
            if(action.formulario.servicios[0]){
                idServicios = [];
                if(action.formulario.servicios[0]!== 'todos'){
                    for(let i=0; i<action.formulario.servicios.length; i++){
                        idServicios.push(action.formulario.servicios[i].split('-')[0]);
                    }
                }
                else{
                    for(let i=0; i<action.listaServiciosActivosF.length; i++){
                        idServicios.push(action.listaServiciosActivosF[i].idServicio);
                    }
                }
            }
        }

        if(action.formulario.tipoConocimiento){
            tipoConocimiento = action.formulario.tipoConocimiento;
        }

        if(action.order){
            order = action.order;
        }
        if(action.formulario?.descripcion){
            param = action.formulario.descripcion;
        }

        const data = {
            'param': param, 
            'idServicios': idServicios, 
            'field':'tipoConocimiento', 
            'order':order,
            'page': page,
            'offset': '10',
            'tipoConocimiento': tipoConocimiento
        }
        
        console.log(data);
        console.log('data');

        const response = yield call(listaDocumentosBaseCall, token, data);
        console.log(response);
        console.log(response.data.data)

        if (response.status == 200) {
            let listaDocumentosBaseBusqueda = response.data.data;
            let listaDocumentosBaseBusquedaCantidad = response.data.cantidad;

            for ( let documento of listaDocumentosBaseBusqueda) {
                let serviciosArray = documento.serviciosByIdBaseConocimiento.map(servicio => `${servicio.idServicio}-${servicio.nombre}`);
                documento.servicios = serviciosArray;
            }

            yield put({ type: BaseConocimientoActions.LISTA_BASE_CONOCIMIENTO_SUCCESS, listaDocumentosBaseBusqueda, listaDocumentosBaseBusquedaCantidad });
        }
        else {
            yield put({ type: BaseConocimientoActions.LISTA_BASE_CONOCIMIENTO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: BaseConocimientoActions.LISTA_BASE_CONOCIMIENTO_FAILURE });
    }
}
export function* listaDocumentosBaseBusquedaSaga() {
    yield takeLatest(BaseConocimientoActions.LISTA_BASE_CONOCIMIENTO_REQUEST, listaDocumentosBaseBusqueda);
}

function* crearNuevoDocumentoBase(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {descripcion, version, nomenclatura, tipoConocimiento, rutaArchivo} = action.formulario;
        const {serviciosArray} = action; 

        const data = {
            "idBaseConocimiento": null,
            "descripcion": descripcion.trim(),
            "version": version.trim(),
            "tipoConocimiento": tipoConocimiento,
            "nomenclatura": nomenclatura.trim(),
            "rutaArchivo": null,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
            "serviciosByIdBaseConocimiento": serviciosArray
        }
        console.log('data',data);
        const formData = new FormData();
        formData.append('file', rutaArchivo.file.originFileObj);
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));

        console.log("formData")
        console.log(formData);
        const response = yield call(crearDocumentoBaseCall, token, formData);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showModalNuevoDocumentoBase = false;
            const showSuccessMsg = true;
            const textMessage = "El documento base " + descripcion + " fue agregado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "CDB" });
            yield put({ type: BaseConocimientoActions.NUEVO_DOCUMENTO_BASE_SUCCESS });
            yield put({ type: BaseConocimientoActions.LISTA_BASE_CONOCIMIENTO_REQUEST, page, formulario: action.busqueda });
            yield put({ type: BaseConocimientoActions.SHOW_MODAL_NUEVO_DOCUMENTO_BASE, showModalNuevoDocumentoBase });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: BaseConocimientoActions.NUEVO_DOCUMENTO_BASE_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: BaseConocimientoActions.NUEVO_DOCUMENTO_BASE_FAILURE });
    }
}
export function* crearNuevoDocumentoBaseSaga() {
    yield takeLatest(BaseConocimientoActions.NUEVO_DOCUMENTO_BASE_REQUEST, crearNuevoDocumentoBase);
}

function* editarDocumentoBase(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {descripcion, version, rutaArchivo} = action.formulario;
        const {serviciosArray} = action; 
        const {idBaseConocimiento, tipoConocimiento, nomenclatura} = action.DocumentoBaseSeleccionado;

        const data = {
            "idBaseConocimiento": null,
            "descripcion": descripcion.trim(),
            "version": version.trim(),
            "tipoConocimiento": tipoConocimiento,
            "nomenclatura": nomenclatura.trim(),
            "rutaArchivo": rutaArchivo.file ? null : rutaArchivo,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": null,
            "serviciosByIdBaseConocimiento": serviciosArray
        }
        console.log('data',data, idBaseConocimiento);
        const formData = new FormData();
        if(rutaArchivo.file) {
            console.log('archivo',rutaArchivo)
            formData.append('file', rutaArchivo.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));

        console.log("formData")
        console.log(formData);
        const response = yield call(editarDocumentosBaseCall, token, formData, idBaseConocimiento);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showModalEditarDocumentoBase = false;
            const DocumentoBaseSeleccionado = null;
            const limpiarFormulario = "EDB";
            const showSuccessMsg = true;
            const textMessage = "El documento base " + descripcion + " fue actualizado con éxito";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario });
            yield put({ type: BaseConocimientoActions.EDITAR_DOCUMENTO_BASE_SUCCESS });
            yield put({ type: BaseConocimientoActions.LISTA_BASE_CONOCIMIENTO_REQUEST, page, formulario: action.busqueda });
            yield put({ type: BaseConocimientoActions.SHOW_MODAL_EDITAR_DOCUMENTO_BASE, showModalEditarDocumentoBase, DocumentoBaseSeleccionado });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: BaseConocimientoActions.EDITAR_DOCUMENTO_BASE_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: BaseConocimientoActions.EDITAR_DOCUMENTO_BASE_FAILURE });
    }
}
export function* editarDocumentoBaseSaga() {
    yield takeLatest(BaseConocimientoActions.EDITAR_DOCUMENTO_BASE_REQUEST, editarDocumentoBase);
}


function* exportarExcel(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = (action.page - 1) * 10;
        let param = null;
        let idServicios = null;
        let order = 'ASC';
        let tipoConocimiento = '';
        if(action.formulario.servicios){
            if(action.formulario.servicios[0]){
                idServicios = [];
                if(action.formulario.servicios[0]!== 'todos'){ 
                    for(let i=0; i<action.formulario.servicios.length; i++){
                        idServicios.push(action.formulario.servicios[i].split('-')[0]);
                    }
                }
                else{
                    for(let i=0; i<action.listaServiciosActivosF.length; i++){
                        idServicios.push(action.listaServiciosActivosF[i].idServicio);
                    }
                }
            }
        }

        if(action.formulario.tipoConocimiento){
            tipoConocimiento = action.formulario.tipoConocimiento;
        }

        if(action.order){
            order = action.order;
        }
        if(action.formulario?.descripcion){
            param = action.formulario.descripcion;
        }

        const data = {
            'param': param, 
            'idServicios': idServicios, 
            'field':'tipoConocimiento', 
            'order':order,
            'page': page,
            'offset': '10',
            'tipoConocimiento': tipoConocimiento
        }
        
        console.log(data);
        console.log('data');
        const response = yield call(exportarExcelCall, token, data);
        console.log(response);

        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Base_de_Conocimientos_" + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + "_" + new Date().getHours() + "_" + new Date().getMinutes() + "_" + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            const showSuccessMsg = true;
            const textMessage = "Los datos se exportaron de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: BaseConocimientoActions.EXPORTAR_EXCEL_SUCCESS });
        }
        else {
            yield put({ type: BaseConocimientoActions.EXPORTAR_EXCEL_FAILURE });
        }
    } catch (error) {
        yield put({ type: BaseConocimientoActions.EXPORTAR_EXCEL_FAILURE });
    }
}
export function* exportarExcelSaga() {
    yield takeLatest(BaseConocimientoActions.EXPORTAR_EXCEL_REQUEST, exportarExcel);
}