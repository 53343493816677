import React, {Component} from 'react'
import {Modal, Table} from 'antd';

import {connect} from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

class ModalListaActividades extends Component {
    componentWillUnmount() {}

    state = {}

    render() {
        const {
            showModalListaActividades,
            listaActividades,
            onShowModalListaActividades
        } = this.props;

        const columns = [{
            title: 'Responsable',
            dataIndex: 'responsable',
            align: 'center',
            key: 'responsable',
            width: '15%',
        }, {
            title: 'Actividad',
            dataIndex: 'actividad',
            align: 'center',
            key: 'actividad',
            width: '25%',
        }, {
            title: 'Fecha Compromiso',
            dataIndex: 'fechaCompromiso',
            align: 'center',
            key: 'fechaCompromiso',
            width: '30%',
        }, {
            title: 'Fecha y Hora Realización',
            dataIndex: 'fechaRealizacion',
            align: 'center',
            key: 'fechaRealizacion',
            width: '30%',
        }
        ];
        const handleCancel = () => {
            onShowModalListaActividades()
        }

        return (
            <Modal
                visible={showModalListaActividades}
                title='Actividades'
                onCancel={handleCancel}
                footer={false}
                width='60%'
            >
                {listaActividades.length > 0 ? (
                    <Table
                        size="small"
                        rowKey="idActividadPlan"
                        columns={columns}
                        dataSource={listaActividades}
                        pagination={false}
                        locale={{emptyText: 'Sin datos'}}
                    />
                ) : false}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        showModalListaActividades: state.GestionDeCalidadReducer.showModalListaActividades,
        listaActividades: state.GestionDeCalidadReducer.listaActividades,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalListaActividades: () => {
            dispatch({
                type: 'MODAL_LISTA_ACTIVIDADES',
                showModalListaActividades: false,
                listaActividades: []
            })
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalListaActividades);
