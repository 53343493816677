import React from 'react';
import { connect } from 'react-redux';
import { Table, Col, Switch, Modal, Divider, Row, Spin, Radio } from 'antd';
import { EditOutlined, FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';
import ModalEditarRegistroSP from './ModalEditarRegistro';
import moment from 'moment';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { confirm } = Modal;

class RegistrosReportesSPContainer extends React.Component {
    state = {
        page: 1,
        busqueda: null,
        personal: false
    }

    componentDidMount () {
        this.props.onlistaRegistrosSP(1);
        this.props.onGetAreas();
        this.props.onGerencias();
        this.props.onListaGrupoSer();
        this.props.onListaServiciosActivos();
        this.props.onGetPartidasActivas();
    }
    componentWillUnmount(){
        this.props.limpiarListaCentroCostoByGerencia();
        this.props.limpiarListaServiciosByCentroCosto();
    }
    render () {
        const {onlistaRegistrosSP, onListaRegistrosEspecialReporte, fetchingListaRegistrosEspecialSP,
            listaRegistrosEspecialSP, listaRegistrosEspecialSPCantidad, listaRegistrosSP, fetchingListaRegistrosSP,
            listaRegistrosSPCantidad, onEstadoRegistroReporte, onListaClasificacion, onExportarPDF, onExportarAgrupadoPDF,
            onExportarExcel, onExportarAgrupadoExcel, onModalEditarRegistroReporte, fetchingExportarReportesDinamico, 
            listaAreasFuncionalesActivas, listaGruposServicios, listaClasificacionesActivos, onListaServiciosByIdsGerencias, listaServiciosActivosF, listaPartidasActivas
        } = this.props;

            
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columns = [{
            title: 'Título del documento',
            dataIndex: 'titulo',
            key: 'titulo',
            width: '15%',
        },{
            title: 'Nombre',
            dataIndex: 'nombre',
            key: 'nombre',
            width: '20%',
        },{
            title: 'Descripción',
            dataIndex: 'descripcion',
            key: 'descripcion',
            width: '30%',
        },{ 
            title: 'Usuario',
            dataIndex: 'usuario',
            key: 'usuario',
            width: '15%',
        },{
            title: 'Editar',
            key: 'editar',
            width: '5%',
            align: 'center',
            render: (text, record) => (
                <a className='editarIcono' disabled={this.state.personal ? true : !record.activo}   onClick={() => onEditar(record)}  ><EditOutlined /></a>
            ),
        }, {
            title: 'Exportar',
            key: 'exportar',
            width: '10%',
            align: 'center',
            render: (text, record) => (
                <Row justify='space-around'>
                    <Col span={6}>
                        <a className='editarIcono' disabled={!permisosUsuario.exportarPdfReporteDinámicoParaEvaluaciónDeLaGestión ? false : !record.activo} onClick={() => onExportar(record, 'pdf')}><FilePdfOutlined /></a>
                    </Col>
                    <Col span={6}>
                        <a className='editarIcono' disabled={!permisosUsuario.exportarExcelReporteDinámicoParaEvaluaciónDeLaGestión ? false : !record.activo} onClick={() => onExportar(record, 'excel')}><FileExcelOutlined /></a>
                    </Col>
                </Row>
            ),
        }, {
            title: 'Activo',
            dataIndex: 'activo',
            key: 'activo',
            width: '5%',
            align: 'center',
            render: (text, record) => (
                <Switch checked={text} disabled={this.state.personal} onChange = {() => handleDesactivar(record)} />
            ),
        }];
        
        const onExportar = (value, tipo) => {
            console.log(`estoy en exportar`, value, tipo);
            let order = { order: value.order, field: value.field }
            if(value.tipoAgrupado === 'SinAgrupar'){
                if(tipo === 'pdf'){
                    onExportarPDF(value.formulario, value.checkbox, order, value.titulo);
                }
                else{
                    onExportarExcel(value.formulario, value.checkbox, order, value.titulo);
                }
            } else {
                if(tipo === 'pdf'){
                    onExportarAgrupadoPDF(value.formulario, value.checkbox, order,  value.titulo, value.idReporteEvaluacion, permisosUsuario.especialEvaluaciónDeLaGestión, value.tipoAgrupado);
                }
                else{
                    onExportarAgrupadoExcel(value.formulario, value.checkbox, value.titulo, order, value.idReporteEvaluacion, permisosUsuario.especialEvaluaciónDeLaGestión, value.tipoAgrupado);
                }
            }
        }

        const handleDesactivar = (key) => {
            let page = this.state.page;
            let personal = this.state.personal;
            console.log("key, personal, page", key, personal, page)
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del reporte predeterminado "' + key.nombre + '"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoRegistroReporte(key.idReporteOrdenesServiciosPlanificados, page, personal);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
        
        const onEditar = (registroReporteSeleccionado) => {
            console.log(`listaRegistrosSP en tabla container`, registroReporteSeleccionado, listaRegistrosSP);
            if(registroReporteSeleccionado.idsGerencias.length){
                this.props.onListaServiciosByIdsGerencias(registroReporteSeleccionado.idsGerencias);
            }
            
            for(let i in listaClasificacionesActivos){
                for(let x in registroReporteSeleccionado.idsGerencias){
                    if(listaClasificacionesActivos[i].idClasificacionGerencia  === parseInt(registroReporteSeleccionado.idsGerencias[x])){
                        registroReporteSeleccionado.idsGerencias[x] = listaClasificacionesActivos[i].idClasificacionGerencia + ' - ' + listaClasificacionesActivos[i].nombreGerencia
                    }
                }
            }
            
            if(registroReporteSeleccionado.idsGruposServicios.length > 0){

                let idsGruposServiciosEditar = []
                for(let i in registroReporteSeleccionado.idsGruposServicios){
                    if(registroReporteSeleccionado.idsGruposServicios[i].includes('[')){
                        idsGruposServiciosEditar[i] = registroReporteSeleccionado.idsGruposServicios[i].replace('[', ""); 
                    }
                    if(registroReporteSeleccionado.idsServicios[i].includes(']')){ 
                        idsGruposServiciosEditar[i] = registroReporteSeleccionado.idsGruposServicios[i].replace(']', ""); 
                    }
                }
                registroReporteSeleccionado.idsGruposServiciosEditar = idsGruposServiciosEditar;

                for(let i in listaGruposServicios){
                    for(let x in registroReporteSeleccionado.idsGruposServiciosEditar){
                        if(listaGruposServicios[i].idGrupoSer === parseInt(registroReporteSeleccionado.idsGruposServiciosEditar[x])){
                            registroReporteSeleccionado.idsGruposServiciosEditar[x] = listaGruposServicios[i].idGrupoSer + ' - ' + listaGruposServicios[i].nombre + ': ' + listaGruposServicios[i].descripcion
                        }
                    }
                }

                this.props.onListaServiciosByIdsGrupos(registroReporteSeleccionado.idsGruposServiciosEditar);
            }

            if(registroReporteSeleccionado.idsServicios.length > 0){
                let idsServiciosEditar = []
                for(let i in registroReporteSeleccionado.idsServicios){
                    if(registroReporteSeleccionado.idsServicios[i].includes('[')){
                        idsServiciosEditar[i] = registroReporteSeleccionado.idsServicios[i].replace('[', ""); 
                    }
                    if(registroReporteSeleccionado.idsServicios[i].includes(']')){ 
                        idsServiciosEditar[i] = registroReporteSeleccionado.idsServicios[i].replace(']', ""); 
                    }
                }
                registroReporteSeleccionado.idsServiciosEditar = idsServiciosEditar;

                for(let i in listaServiciosActivosF){
                    for(let x in idsServiciosEditar){
                        if(listaServiciosActivosF[i].idServicio === parseInt(idsServiciosEditar[x])){
                            registroReporteSeleccionado.idsServiciosEditar[x] = listaServiciosActivosF[i].idServicio + ' - ' + listaServiciosActivosF[i].nomenclatura + ': ' + listaServiciosActivosF[i].nombre
                        }
                    }
                }
            }

            if(registroReporteSeleccionado.idsPartidas.length > 0){
                let idsPartidasEditar = []
                for(let i in registroReporteSeleccionado.idsPartidas){
                    if(registroReporteSeleccionado.idsPartidas[i].includes('[')){
                        idsPartidasEditar[i] = registroReporteSeleccionado.idsPartidas[i].replace('[', ""); 
                    }
                    if(registroReporteSeleccionado.idsPartidas[i].includes(']')){ 
                        idsPartidasEditar[i] = registroReporteSeleccionado.idsPartidas[i].replace(']', ""); 
                    }
                }
                registroReporteSeleccionado.idsPartidasEditar = idsPartidasEditar;

                for(let i in listaPartidasActivas){
                    for(let x in idsPartidasEditar){
                        if(listaPartidasActivas[i].idPartida === parseInt(idsPartidasEditar[x])){
                            registroReporteSeleccionado.idsPartidasEditar[x] = listaPartidasActivas[i].idPartida + ' - '  + listaPartidasActivas[i].nombre
                        }
                    }
                }
            }

            for(let i in listaAreasFuncionalesActivas){
                if(listaAreasFuncionalesActivas[i].codigoArea  === registroReporteSeleccionado.areaFuncional || '[' + listaAreasFuncionalesActivas[i].codigoArea + ']' ===  registroReporteSeleccionado.areaFuncional){
                    registroReporteSeleccionado.areaFuncional = listaAreasFuncionalesActivas[i].codigoArea + ': ' + listaAreasFuncionalesActivas[i].areaFuncional;
                    this.props.onGetUnidades(listaAreasFuncionalesActivas[i].idAreaFuncional);
                }
            }

            let unidadFuncionalEditar = registroReporteSeleccionado.unidadFuncional.replace('[','').replace(']','');
            registroReporteSeleccionado.unidadFuncionalEditar = unidadFuncionalEditar;

            if(registroReporteSeleccionado.tipoPrograma === undefined){
                registroReporteSeleccionado.showArea = true;
            }
            else{
                for(let i in registroReporteSeleccionado.tipoPrograma){
                    if(registroReporteSeleccionado.tipoPrograma[i] === 'Rutina'){
                        registroReporteSeleccionado.showArea = true;
                    }
                    
                }
            }
            
            registroReporteSeleccionado.fecha = registroReporteSeleccionado.formulario.fechaFin ? [moment(registroReporteSeleccionado.formulario.fechaInicio, 'YYYY-MM-DD'), moment(registroReporteSeleccionado.formulario.fechaFin, 'YYYY-MM-DD')] : undefined;
            onModalEditarRegistroReporte(registroReporteSeleccionado, this.state.page, this.state.personal);
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            if(this.state.personal){
                onListaRegistrosEspecialReporte(pagination.current);
            }
            else{
                onlistaRegistrosSP(pagination.current);
            }
            // onListaClasificacion(pagination.current, this.state.busqueda);
        }
    
        const cambiarTablaRegistros = (value) => {
            console.log("value en tabla registros", value.target.value)
            if(value.target.value){
                this.setState({
                    page: 1,
                    personal: true
                });
                onListaRegistrosEspecialReporte(1)
            }
            else{
                this.setState({
                    page: 1,
                    personal: false
                });
                onlistaRegistrosSP(1)
            }
        }
        return (
            <div>
                <ModalEditarRegistroSP/>
                <Divider orientation='left'>Registros Guardados</Divider>
                <Row justify='end' style={{padding:'1em'}}>
                    <Radio.Group defaultValue={false} onChange={cambiarTablaRegistros} size="small">
                        {permisosUsuario.especialEvaluaciónDeLaGestión ? (
                        <Radio.Button value={true}>Todos</Radio.Button>
                        ):false}
                        <Radio.Button value={false}>Personal</Radio.Button>
                    </Radio.Group>
                </Row>
                <br/>
                <br/>
                <Spin spinning={ this.props.fetchingReporteDinamicoPDF || this.props.fetchingReporteDinamicoEXCEL || this.props.fetchingReporteDinamicoAgrupadoSP || this.props.fetchingReporteDinamicoAgrupadoPDF || this.props.fetchingReporteDinamicoAgrupadoEXCEL}>
                    <Col span={24}>
                        <Table
                            size='small'
                            rowKey='idReporteOrdenesServiciosPlanificados'
                            columns={columns}
                            dataSource={this.state.personal ? listaRegistrosEspecialSP : listaRegistrosSP}
                            loading={this.state.personal ? fetchingListaRegistrosEspecialSP : fetchingListaRegistrosSP}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: this.state.personal ? listaRegistrosEspecialSPCantidad : listaRegistrosSPCantidad, simple: true, current: this.state.page }}
                        />
                    </Col>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaRegistrosEspecialSP: state.ServiciosPlanificadosReducer.fetchingListaRegistrosEspecialSP,
        listaRegistrosEspecialSP: state.ServiciosPlanificadosReducer.listaRegistrosEspecialSP,
        listaRegistrosEspecialSPCantidad: state.ServiciosPlanificadosReducer.listaRegistrosEspecialSPCantidad,
        fetchingListaRegistrosSP: state.ServiciosPlanificadosReducer.fetchingListaRegistrosSP,
        listaRegistrosSP: state.ServiciosPlanificadosReducer.listaRegistrosSP,
        listaRegistrosSPCantidad: state.ServiciosPlanificadosReducer.listaRegistrosSPCantidad,
        fetchingReporteDinamicoPDF: state.ServiciosPlanificadosReducer.fetchingReporteDinamicoPDF,
        fetchingReporteDinamicoEXCEL: state.ServiciosPlanificadosReducer.fetchingReporteDinamicoEXCEL,
        fetchingReporteDinamicoAgrupadoSP: state.ServiciosPlanificadosReducer.fetchingReporteDinamicoAgrupadoSP,
        fetchingReporteDinamicoAgrupadoPDF: state.ServiciosPlanificadosReducer.fetchingReporteDinamicoAgrupadoPDF,
        fetchingReporteDinamicoAgrupadoEXCEL: state.ServiciosPlanificadosReducer.fetchingReporteDinamicoAgrupadoEXCEL,
        listaAreasFuncionalesActivas: state.AlmacenesReducer.listaAreasFuncionalesActivas,
        listaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.listaClasificacionesActivos,
        listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,

        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        listaPartidasByIdsServicios: state.ConfiguracionYSeguridadReducer.listaPartidasByIdsServicios,
        listaPartidasActivas: state.ConfiguracionYSeguridadReducer.listaPartidasActivas,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onEstadoRegistroReporte: (idReporte, page, personal) => {
            dispatch({ type: "ESTADO_REGISTRO_REPORTE_SP_REQUEST", idReporte, page, personal });
        },
        onModalEditarRegistroReporte: (registroReporteSeleccionado, page, personal) => {
            dispatch({ type: "MODAL_EDITAR_REGISTRO_SP", showModalEditarRegistro: true, registroReporteSeleccionado, page, personal });
        },
        onlistaRegistrosSP: (page) => {
            dispatch({ type: 'LISTA_REGISTROS_SP_REQUEST', page })
        },
        onListaRegistrosEspecialReporte: (page) => {
            dispatch({ type: 'LISTA_REGISTROS_SP_ESPECIAL_REQUEST', page })
        },
        onExportarPDF: (formulario, checkboxData, order, tituloDocumento) => {
            dispatch({ type: 'PDF_REPORTE_DINAMICO_SP_REQUEST', formulario, checkboxData, order, tituloDocumento, containerRegistros: true })
        },
        onExportarExcel: (formulario, checkboxData, order, tituloDocumento) => {
            dispatch({ type: 'EXCEL_REPORTE_DINAMICO_SP_REQUEST', formulario,checkboxData, order, tituloDocumento, containerRegistros: true});
        },
        onExportarAgrupadoPDF: (formulario, checkboxData, order, tituloDocumento, idReporteEvaluacion, especial, agrupado) => {
            dispatch({ type: 'PDF_REPORTE_DINAMICO_AGRUPADO_SP_REQUEST', formulario, checkboxData, order, tituloDocumento, idReporteEvaluacion, especial, agrupado })
        },
        onExportarAgrupadoExcel: (formulario, checkboxData, tituloDocumento, order, idReporteEvaluacion, especial, agrupado) => {
            dispatch({ type: 'EXCEL_REPORTE_DINAMICO_AGRUPADO_SP_REQUEST', formulario, checkboxData, tituloDocumento, order, idReporteEvaluacion, especial, agrupado })
        },
        onListaPartidas: (idsServicios) => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_SERVICIOS_REQUEST', idsServicios })
        },
        onCetroCostosByGerencia: (idsGerencias) => {
            dispatch({ type: 'LISTA_CENTRO_COSTOS_BY_GERENCIA_REQUEST', idsGerencias });
        },
        onServiciosByCentroCosto: (idsCentrosCostos) => {
            dispatch({ type: 'LISTA_SERVICIOS_BY_CENTRO_COSTO_REQUEST', idsCentrosCostos });
        },
        limpiarListaCentroCostoByGerencia: () => {
            dispatch({ type: 'LISTA_CENTRO_COSTOS_BY_GERENCIA_SUCCESS', listaCentroDeCostosByGerencia: [] })
        },
        limpiarListaServiciosByCentroCosto: () => {
            dispatch({ type: 'LISTA_SERVICIOS_BY_CENTRO_COSTO_SUCCESS', listaServiciosByCentroCosto: [] })
        },
        onGerencias: () => {
            dispatch({ type: 'LISTA_CLASIFICACION_ACTIVOS_REQUEST', reporteDinamicoES: true })
        },
        onListaGrupoSer: () => {
            dispatch({ type: 'LISTA_GRUPOS_SERVICIOS_REQUEST' })
        },
        onGetUnidades: (idAreaFuncional) => {
            dispatch({ type: "LISTA_UNIDAD_FUNCIONAL_REQUEST" , idAreaFuncional});
        },
        onGetAreas: () => {
            dispatch({ type: "LISTA_AREA_FUNCIONAL_ACTIVA_REQUEST" });
        },
        onListaServiciosByIdsGerencias: (idsGerencias) => {
            dispatch({ type: 'LISTA_SERVICIOS_BY_IDGERENCIAS_REQUEST', idsGerencias });
        },
        onListaServiciosByIdsGrupos: (idsGruposSer) => {
            dispatch({ type: 'LISTA_SERVICIOS_BY_IDSGRUPOS_REQUEST', idsGruposSer });
        },

        onListaServiciosActivos: () => {
            dispatch ({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST' });
        },

        onGetPartidasActivas: () => {
            dispatch({ type: 'LISTA_PARTIDAS_ACTIVAS_REQUEST' });
       },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrosReportesSPContainer);