import { takeLatest, call, put } from "redux-saga/effects";
import * as configuracionYSeguridadActions from '../actions/ConfiguracionYSeguridadActions';
import * as informacionOperativaActions from '../actions/InformacionOperativaActions';
import * as informacionDelProyectoActions from '../actions/InformacionDelProyectoActions';

import { listaDominiosBusquedaCall, estadoDominioCall, crearDominioCall, editarDominioCall,
    listaExpedientesBusquedaCall, estadoExpedientesCall, crearExpedienteCall, editarExpedienteCall, verificarExpedientesCall, archivosExpedienteCall, eliminarArchivoExpedienteCall, listaExpedientesSinInfoCall,

    listaContratosBusquedaCall, estadoContratoCall, crearContratoCall, editarContratoCall, listaContratosSinInfoCall, listaContratosPorServicioCall, listaContratosActivosIdServicioCall,

    listaProveedoresCall, proveedoresBusquedaCall, estadoProveedorCall, crearProveedorCall, editarProveedorCall, listaBusquedaProveedoresCall,
    listaExpedientesCall, listaContratosCall,  listaPartidasByServicioCall, listaServiciosActivosForProveedorCall, listaAreasProveedoresCall, 

    listaPartidasBusquedaCall, listaPartidasByServiciosCall, estadoPartidaCall, crearPartidaCall, editarPartidaCall,
} from "../../services/InformacionOperativaServices";
import moment from 'moment';
//********************** Lista de Dominios para Catálogo Dominios *****************************
function* listaDominiosBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let dominio = '';
        if(action.busqueda){
            dominio = 'param=' + action.busqueda + '&';
        }
        console.log('page');
        console.log(page);
        console.log('dominio');
        console.log(dominio);
        let response  = yield call(listaDominiosBusquedaCall, token, page, dominio);
        
        console.log(response);

        if(response.status==200){
            const listaDominiosBusqueda = response.data.data;
            const listaDominiosBusquedaCantidad = response.data.cantidad;
            for(let i=0; i<listaDominiosBusqueda.length; i++){
                let fechaAlta = listaDominiosBusqueda[i].fechaAlta.split('T')[0];
                listaDominiosBusqueda[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
                listaDominiosBusqueda[i].dominioCompleto = listaDominiosBusqueda[i].dominio + listaDominiosBusqueda[i].terminacion;
            }

            yield put({ type: informacionOperativaActions.LISTA_DOMINIOS_BUSQUEDA_SUCCESS, listaDominiosBusqueda, listaDominiosBusquedaCantidad });
        }
        else{
            yield put({ type: informacionOperativaActions.LISTA_DOMINIOS_BUSQUEDA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.LISTA_DOMINIOS_BUSQUEDA_FAILURE });
    }
}
export function* listaDominiosBusquedaSaga() {
    yield takeLatest(informacionOperativaActions.LISTA_DOMINIOS_BUSQUEDA_REQUEST, listaDominiosBusqueda);
}

//********************** Cambiar el estado del Dominio Activo/inactivo *****************************
function* cambiarEstadoDominio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idDominio = action.idDominio;

        const response = yield call(estadoDominioCall, token, idDominio);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const formulario = {
                dominio: '',
                idProveedor: null,
            }
            const showSuccessMsg = true;
            const textMessage = "El estado del dominio cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: informacionOperativaActions.ESTADO_DOMINIO_SUCCESS });
            yield put({ type: informacionOperativaActions.LISTA_DOMINIOS_BUSQUEDA_REQUEST, page, formulario });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: informacionOperativaActions.ESTADO_DOMINIO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.ESTADO_DOMINIO_FAILURE });
    }
}
export function* cambiarEstadoDominioSaga() {
    yield takeLatest(informacionOperativaActions.ESTADO_DOMINIO_REQUEST, cambiarEstadoDominio);
}

//********************** Crear nuevo Dominio *****************************
function* crearDominio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        
        const data = {
            "idDominio": null,
            "dominio": action.formulario.dominio,
            'terminacion': action.formulario.terminacion,
            "fechaAlta": null,
            "activo": true,
            "fechaActualizacion": null
        }
        console.log(data);
        const response = yield call(crearDominioCall, token, data);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const formulario = {
                dominio: '',
                idProveedor: null,
            }
            const showModalNuevoDominio = false;
            const showSuccessMsg = true;
            const textMessage = "El dominio " + action.formulario.dominio + action.formulario.terminacion + " se creó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: informacionOperativaActions.NUEVO_DOMINIO_SUCCESS });
            yield put({ type: informacionOperativaActions.LISTA_DOMINIOS_BUSQUEDA_REQUEST, page, formulario });
            yield put({ type: configuracionYSeguridadActions.LISTA_DOMINIOS_ACTIVOS_REQUEST });
            yield put({ type: informacionOperativaActions.SHOW_MODAL_NUEVO_DOMINIO, showModalNuevoDominio });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearDominio' });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: informacionOperativaActions.NUEVO_DOMINIO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.NUEVO_DOMINIO_FAILURE });
    }
}
export function* crearDominioSaga() {
    yield takeLatest(informacionOperativaActions.NUEVO_DOMINIO_REQUEST, crearDominio);
}

//********************** Editar Dominio *****************************
function* editarDominio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idDominio = action.dominioSeleccionado.idDominio;
        const data = {
            "idDominio": idDominio,
            "dominio": action.formulario.dominio,
            'terminacion': action.formulario.terminacion,
            "fechaAlta": null,
            "activo": true,
            "fechaActualizacion": null
        }
        console.log(data);
        const response = yield call(editarDominioCall, token, data, idDominio);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const formulario = {
                dominio: '',
                idProveedor: null,
            }
            const showModalEditarDominio = false;
            const dominioSeleccionado = null;
            const showSuccessMsg = true;
            const textMessage = "El dominio " + action.formulario.dominio + action.formulario.terminacion + " se modificó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: informacionOperativaActions.EDITAR_DOMINIO_SUCCESS });
            yield put({ type: informacionOperativaActions.LISTA_DOMINIOS_BUSQUEDA_REQUEST, page, formulario });
            yield put({ type: configuracionYSeguridadActions.LISTA_DOMINIOS_ACTIVOS_REQUEST });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarDominio' });
            yield put({ type: informacionOperativaActions.SHOW_MODAL_EDITAR_DOMINIO, showModalEditarDominio, dominioSeleccionado });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: informacionOperativaActions.EDITAR_DOMINIO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.EDITAR_DOMINIO_FAILURE });
    }
}
export function* editarDominioSaga() {
    yield takeLatest(informacionOperativaActions.EDITAR_DOMINIO_REQUEST, editarDominio);
}

//********************** Lista de Expedientes para nuevo/editar proveedor *****************************
function* listaExpedientes(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response  = yield call(listaExpedientesCall, token);
        console.log(response);

        if(response.status==200){
            let listaExpedientes = response.data;
            yield put({ type: informacionOperativaActions.LISTA_EXPEDIENTES_SUCCESS, listaExpedientes });
        }
        else{
            yield put({ type: informacionOperativaActions.LISTA_EXPEDIENTES_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.LISTA_EXPEDIENTES_FAILURE });
    }
}
export function* listaExpedientesSaga() {
    yield takeLatest(informacionOperativaActions.LISTA_EXPEDIENTES_REQUEST, listaExpedientes);
}

//********************** Lista de Expedientes para Catálogo de Expedientes *****************************
function* listaExpedientesBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let busqueda = '';
        if(action.busqueda){
            busqueda = 'param=' + action.busqueda + '&';
        }
        console.log('page');
        console.log(page);
        console.log('busqueda');
        console.log(busqueda);

        let response  = yield call(listaExpedientesBusquedaCall, token, page, busqueda);
        console.log(response);

        if(response.status==200){
            let listaExpedientesBusqueda = response.data.data;
            const listaExpedientesBusquedaCantidad = response.data.cantidad;
            for(let i=0; i<listaExpedientesBusqueda.length; i++){
                let fechaAlta = listaExpedientesBusqueda[i].fechaAlta.split('T')[0];
                listaExpedientesBusqueda[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
                if(listaExpedientesBusqueda[i].proveedorByIdExpediente){
                    listaExpedientesBusqueda[i].idProveedor = listaExpedientesBusqueda[i].proveedorByIdExpediente.idProveedor;
                    listaExpedientesBusqueda[i].nombreProveedor = listaExpedientesBusqueda[i].proveedorByIdExpediente.nombre;
                }
                else{
                    listaExpedientesBusqueda[i].idProveedor = 'No';
                    listaExpedientesBusqueda[i].nombreProveedor = 'No tiene proveedor';
                }
            }
            yield put({ type: informacionOperativaActions.LISTA_EXPEDIENTES_BUSQUEDA_SUCCESS, listaExpedientesBusqueda, listaExpedientesBusquedaCantidad });
        }
        else{
            yield put({ type: informacionOperativaActions.LISTA_EXPEDIENTES_BUSQUEDA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.LISTA_EXPEDIENTES_BUSQUEDA_FAILURE });
    }
}
export function* listaExpedientesBusquedaSaga() {
    yield takeLatest(informacionOperativaActions.LISTA_EXPEDIENTES_BUSQUEDA_REQUEST, listaExpedientesBusqueda);
}

//********************** Lista de Expedientes SIN INFO para Catálogo de Expedientes *****************************
function* listaExpedientesSinInfo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;

        let response  = yield call(listaExpedientesSinInfoCall, token, page);
        console.log(response);

        if(response.status==200){
            let listaExpedientesBusqueda = response.data.data;
            const listaExpedientesBusquedaCantidad = response.data.cantidad;
            for(let i=0; i<listaExpedientesBusqueda.length; i++){
                let fechaAlta = listaExpedientesBusqueda[i].fechaAlta.split('T')[0];
                listaExpedientesBusqueda[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
                if(listaExpedientesBusqueda[i].proveedorByIdExpediente){
                    listaExpedientesBusqueda[i].idProveedor = listaExpedientesBusqueda[i].proveedorByIdExpediente.idProveedor;
                    listaExpedientesBusqueda[i].nombreProveedor = listaExpedientesBusqueda[i].proveedorByIdExpediente.nombre;
                }
                else{
                    listaExpedientesBusqueda[i].idProveedor = 'No';
                    listaExpedientesBusqueda[i].nombreProveedor = 'No tiene proveedor';
                }
            }
            yield put({ type: informacionOperativaActions.LISTA_EXPEDIENTES_SIN_INFO_SUCCESS, listaExpedientesBusqueda, listaExpedientesBusquedaCantidad });
        }
        else{
            yield put({ type: informacionOperativaActions.LISTA_EXPEDIENTES_SIN_INFO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.LISTA_EXPEDIENTES_SIN_INFO_FAILURE });
    }
}
export function* listaExpedientesSinInfoSaga() {
    yield takeLatest(informacionOperativaActions.LISTA_EXPEDIENTES_SIN_INFO_REQUEST, listaExpedientesSinInfo);
}

//********************** Cambiar el estado del Expediente Activo/inactivo *****************************
function* cambiarEstadoExpediente(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idExpediente = action.idExpediente;

        const response = yield call(estadoExpedientesCall, token, idExpediente);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const busqueda = '';
            const showSuccessMsg = true;
            const textMessage = "El estado del expediente cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: informacionOperativaActions.ESTADO_EXPEDIENTE_SUCCESS });
            yield put({ type: informacionOperativaActions.LISTA_EXPEDIENTES_BUSQUEDA_REQUEST, page, busqueda });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: informacionOperativaActions.ESTADO_EXPEDIENTE_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.ESTADO_EXPEDIENTE_FAILURE });
    }
}
export function* cambiarEstadoExpedienteSaga() {
    yield takeLatest(informacionOperativaActions.ESTADO_EXPEDIENTE_REQUEST, cambiarEstadoExpediente);
}

//********************** Ver archivos del Expediente *****************************
function* archivosExpediente(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const nombreCarpeta = action.nombreCarpeta;

        const response = yield call(archivosExpedienteCall, token, nombreCarpeta);
        console.log('response archivos expediente')
        console.log(response);

        if(response.status==200){
            let archivosExpediente = response.data;
            if(!archivosExpediente.length>0){
                archivosExpediente = null;
            }

            yield put({ type: informacionOperativaActions.ARCHIVOS_EXPEDIENTE_SUCCESS, archivosExpediente });
        }
        else{
            yield put({ type: informacionOperativaActions.ARCHIVOS_EXPEDIENTE_FAILURE, archivosExpediente: [] });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.ARCHIVOS_EXPEDIENTE_FAILURE });
    }
}
export function* archivosExpedienteSaga() {
    yield takeLatest(informacionOperativaActions.ARCHIVOS_EXPEDIENTE_REQUEST, archivosExpediente);
}

//********************** Eliminar archivo del Expediente *****************************
function* eliminarArchivosExpediente(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const pathArchivo = action.pathArchivo;

        const response = yield call(eliminarArchivoExpedienteCall, token, pathArchivo);
        console.log(response);

        if(response.status==200){
            const showSuccessMsg = true;
            const textMessage = "El archivo fue eliminado de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: informacionOperativaActions.ELIMIAR_ARCHIVO_EXPEDIENTE_SUCCESS });
            yield put({ type: informacionOperativaActions.ARCHIVOS_EXPEDIENTE_REQUEST, nombreCarpeta: action.nombreCarpeta });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: informacionOperativaActions.ELIMIAR_ARCHIVO_EXPEDIENTE_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.ELIMIAR_ARCHIVO_EXPEDIENTE_FAILURE });
    }
}
export function* eliminarArchivosExpedienteSaga() {
    yield takeLatest(informacionOperativaActions.ELIMIAR_ARCHIVO_EXPEDIENTE_REQUEST, eliminarArchivosExpediente);
}

//********************** Crear nuevo Expediente *****************************
function* crearExpediente(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const nombreCarpeta = action.formulario.nombreCarpeta;
        const verificar = yield call(verificarExpedientesCall, token, nombreCarpeta);
        console.log(verificar);
        if(verificar.status === 200){
            const data = {
                "idExpediente": null,
                "clave": action.formulario.clave,
                "descripcion": action.formulario.descripcion,
                "nombreCarpeta": action.formulario.nombreCarpeta,
                "rutaCarpeta": '',
                "fechaAlta": null,
                "fechaActualizacion": null,
                "activo": 1,
                'expedientePersonal': false,
                "proveedorByIdExpediente": null
            }
            console.log(data);
            const formData = new FormData();
            if(action.formulario.pdf){
                formData.append('file', action.formulario.pdf.file.originFileObj);
            }
            formData.append('data', new Blob([JSON.stringify(data)], {
                type: "application/json"
            }));
            const response = yield call(crearExpedienteCall, token, formData);
            console.log(response);
    
            if(response.status==200){
                const page = action.page;
                const busqueda = '';
                const showModalNuevoExpediente = false;
                const showSuccessMsg = true;
                const textMessage = "El expediente " + action.formulario.clave + " se creó de forma correcta";
    
                yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
                yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearExpediente' });
                yield put({ type: informacionOperativaActions.NUEVO_EXPEDIENTE_SUCCESS });
                yield put({ type: informacionOperativaActions.LISTA_EXPEDIENTES_BUSQUEDA_REQUEST, page, busqueda });
                yield put({ type: informacionOperativaActions.LISTA_EXPEDIENTES_REQUEST});
                yield put({ type: informacionOperativaActions.SHOW_MODAL_NUEVO_EXPEDIENTE, showModalNuevoExpediente });
            }
            else{
                const showErrorMsg = true;
                const textMessage = response.message;
    
                yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                yield put({ type: informacionOperativaActions.NUEVO_EXPEDIENTE_FAILURE });
            }
        }
        else{
            const showErrorMsg = true;
            const textMessage = verificar.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: informacionOperativaActions.NUEVO_EXPEDIENTE_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.NUEVO_EXPEDIENTE_FAILURE });
    }
}
export function* crearExpedienteSaga() {
    yield takeLatest(informacionOperativaActions.NUEVO_EXPEDIENTE_REQUEST, crearExpediente);
}

//********************** Editar Expediente *****************************
function* editarExpediente(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idExpediente = action.expedienteSeleccionado.idExpediente;
        let clave = action.expedienteSeleccionado.clave;
        let descripcion = action.expedienteSeleccionado.descripcion;
        if(!action.tipoEdicion){
            clave = action.formulario.clave;
            descripcion = action.formulario.descripcion
        }
        let data = {
            "idExpediente": idExpediente,
            "clave": clave,
            "descripcion": descripcion,
            "nombreCarpeta": action.expedienteSeleccionado.nombreCarpeta,
            "rutaCarpeta": action.expedienteSeleccionado.rutaCarpeta,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": action.expedienteSeleccionado.activo,
            'expedientePersonal': action.expedienteSeleccionado.expedientePersonal,
            "proveedorByIdExpediente": action.expedienteSeleccionado.proveedorByIdExpediente
        }
        console.log(data);
        const formData = new FormData();
        if(action.formulario.pdf){
            formData.append('file', action.formulario.pdf.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        const response = yield call(editarExpedienteCall, token, formData, idExpediente);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const busqueda = '';
            const showSuccessMsg = true;
            let textMessage = "El expediente " + clave + " se modificó de forma correcta";
            
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarExpediente' });
            yield put({ type: informacionOperativaActions.EDITAR_EXPEDIENTE_SUCCESS });
            yield put({ type: informacionOperativaActions.LISTA_EXPEDIENTES_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: informacionOperativaActions.LISTA_EXPEDIENTES_REQUEST});
            yield put({ type: informacionOperativaActions.SHOW_MODAL_EDITAR_EXPEDIENTE, showModalEditarExpediente: false, expedienteSeleccionado: null });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: informacionOperativaActions.EDITAR_EXPEDIENTE_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.EDITAR_EXPEDIENTE_FAILURE });
    }
}
export function* editarExpedienteSaga() {
    yield takeLatest(informacionOperativaActions.EDITAR_EXPEDIENTE_REQUEST, editarExpediente);
}

//********************** Lista de Contratos para nuevo/editar proveedor *****************************
function* listaContratos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaContratosCall, token);
        console.log(response);

        if(response.status==200){
            let listaContratos = response.data;
            yield put({ type: informacionOperativaActions.LISTA_CONTRATOS_SUCCESS, listaContratos });
        }
        else{
            yield put({ type: informacionOperativaActions.LISTA_CONTRATOS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.LISTA_CONTRATOS_FAILURE });
    }
}
export function* listaContratosSaga() {
    yield takeLatest(informacionOperativaActions.LISTA_CONTRATOS_REQUEST, listaContratos);
}

function* listaContratosActivosIdServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServicio = action.idServicio;
        
        const response = yield call(listaContratosActivosIdServicioCall, token, idServicio);
        console.log(response);

        if(response.status==200){
            let listaContratosActivosIdServicio = response.data;
            yield put({ type: informacionOperativaActions.LISTA_CONTRATOS_ACTIVOS_ID_SERVICIO_SUCCESS, listaContratosActivosIdServicio });
        }
        else{
            yield put({ type: informacionOperativaActions.LISTA_CONTRATOS_ACTIVOS_ID_SERVICIO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.LISTA_CONTRATOS_ACTIVOS_ID_SERVICIO_FAILURE });
    }
}
export function* listaContratosActivosIdServicioSaga() {
    yield takeLatest(informacionOperativaActions.LISTA_CONTRATOS_ACTIVOS_ID_SERVICIO_REQUEST, listaContratosActivosIdServicio);
}

//********************** Lista de Contratos para Catálogo de Contratos *****************************
function* listaContratosBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let proveedor = '';
        if(action.formulario.proveedor){
            proveedor = 'prov=' + action.formulario.proveedor + '&';
        }
        let servicio = '';
        if(action.formulario.servicio){
            servicio = 'serv=' + action.formulario.servicio + '&';
        }
        let fechaFin = '';
        if(action.formulario.fechaFin){
            fechaFin = 'fechaFin=' + action.formulario.fechaFin.format('YYYY-MM-DD') + '%2023:59:59' + '&';
        }
        
        let response  = yield call(listaContratosBusquedaCall, token, page, proveedor, servicio, fechaFin);
        
        console.log(response);

        if(response.status==200){
            const listaContratosBusqueda = response.data.data;
            const listaContratosBusquedaCantidad = response.data.cantidad;
            for(let i=0; i<listaContratosBusqueda.length; i++){
                let fechaAlta = listaContratosBusqueda[i].fechaAlta.split('T')[0];
                listaContratosBusqueda[i].fechaAlta = fechaAlta.split('-').reverse().join('-');
                
                let fechaInicio = listaContratosBusqueda[i].fechaInicio.split('T')[0];
                listaContratosBusqueda[i].fechaInicio = fechaInicio.split('-').reverse().join('-');
                let fechaFin = listaContratosBusqueda[i].fechaFin.split('T')[0];
                listaContratosBusqueda[i].fechaFin = fechaFin.split('-').reverse().join('-');

                listaContratosBusqueda[i].rangoVigencia = [moment(fechaInicio, 'YYYY-MM-DD'),moment(fechaFin, 'YYYY-MM-DD')];

                if(listaContratosBusqueda[i].proveedorByIdContrato){
                    listaContratosBusqueda[i].idProveedor = listaContratosBusqueda[i].proveedorByIdContrato.idProveedor;
                    listaContratosBusqueda[i].nombreProveedor = listaContratosBusqueda[i].proveedorByIdContrato.nombre;
                }
                else{
                    listaContratosBusqueda[i].idProveedor = 'No';
                    listaContratosBusqueda[i].nombreProveedor = 'No tiene proveedor';
                }
                if(listaContratosBusqueda[i].servicioByIdContrato){
                    listaContratosBusqueda[i].idServicio = listaContratosBusqueda[i].servicioByIdContrato.idServicio;
                    listaContratosBusqueda[i].nombreServicio = listaContratosBusqueda[i].servicioByIdContrato.nombre;
                }
                else{
                    listaContratosBusqueda[i].idServicio = 'No';
                    listaContratosBusqueda[i].nombreServicio = 'No tiene servicio';
                }
            }
            yield put({ type: informacionOperativaActions.LISTA_CONTRATOS_BUSQUEDA_SUCCESS, listaContratosBusqueda, listaContratosBusquedaCantidad });
        }
        else{
            yield put({ type: informacionOperativaActions.LISTA_CONTRATOS_BUSQUEDA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.LISTA_CONTRATOS_BUSQUEDA_FAILURE });
    }
}
export function* listaContratosBusquedaSaga() {
    yield takeLatest(informacionOperativaActions.LISTA_CONTRATOS_BUSQUEDA_REQUEST, listaContratosBusqueda);
}


//********************** Lista de Contratos sin info para Catálogo de Contratos *****************************
function* listaContratosSinInfo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let response  = yield call(listaContratosSinInfoCall, token, page);
        
        console.log(response);

        if(response.status==200){
            const listaContratosBusqueda = response.data.data;
            const listaContratosBusquedaCantidad = response.data.cantidad;
            for(let i=0; i<listaContratosBusqueda.length; i++){
                let fechaAlta = listaContratosBusqueda[i].fechaAlta.split('T')[0];
                listaContratosBusqueda[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
                let fechaFin = listaContratosBusqueda[i].fechaFin.split('T')[0];
                listaContratosBusqueda[i].fechaFin = fechaFin.split('-')[2] + '-' + fechaFin.split('-')[1] + '-' + fechaFin.split('-')[0];
                let fechaInicio = listaContratosBusqueda[i].fechaInicio.split('T')[0];
                listaContratosBusqueda[i].fechaInicio = fechaInicio.split('-')[2] + '-' + fechaInicio.split('-')[1] + '-' + fechaInicio.split('-')[0];
                listaContratosBusqueda[i].rangoVigencia = listaContratosBusqueda[i].fechaInicio + ', ' + listaContratosBusqueda[i].fechaFin;
                if(listaContratosBusqueda[i].proveedorByIdContrato){
                    listaContratosBusqueda[i].idProveedor = listaContratosBusqueda[i].proveedorByIdContrato.idProveedor;
                    listaContratosBusqueda[i].nombreProveedor = listaContratosBusqueda[i].proveedorByIdContrato.nombre;
                }
                else{
                    listaContratosBusqueda[i].idProveedor = 'No';
                    listaContratosBusqueda[i].nombreProveedor = 'No tiene proveedor';
                }
                if(listaContratosBusqueda[i].servicioByIdContrato){
                    listaContratosBusqueda[i].idServicio = listaContratosBusqueda[i].servicioByIdContrato.idServicio;
                    listaContratosBusqueda[i].nombreServicio = listaContratosBusqueda[i].servicioByIdContrato.nombre;
                }
                else{
                    listaContratosBusqueda[i].idServicio = 'No';
                    listaContratosBusqueda[i].nombreServicio = 'No tiene servicio';
                }
            }
            yield put({ type: informacionOperativaActions.LISTA_CONTRATOS_SIN_INFO_SUCCESS, listaContratosBusqueda, listaContratosBusquedaCantidad });
        }
        else{
            yield put({ type: informacionOperativaActions.LISTA_CONTRATOS_SIN_INFO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.LISTA_CONTRATOS_SIN_INFO_FAILURE });
    }
}
export function* listaContratosSinInfoSaga() {
    yield takeLatest(informacionOperativaActions.LISTA_CONTRATOS_SIN_INFO_REQUEST, listaContratosSinInfo);
}

//********************** Lista de Contratos por idServicio para Partidas *****************************
function* listaContratosPorServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServicio = action.idServicio;

        const response  = yield call(listaContratosPorServicioCall, token, idServicio);
        console.log(response);

        if(response.status==200){
            let listaContratosPorServicio = response.data;
            yield put({ type: informacionOperativaActions.LISTA_CONTRATOS_POR_SERVICIO_SUCCESS, listaContratosPorServicio });
        }
        else{
            yield put({ type: informacionOperativaActions.LISTA_CONTRATOS_POR_SERVICIO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.LISTA_CONTRATOS_POR_SERVICIO_FAILURE });
    }
}
export function* listaContratosPorServicioSaga() {
    yield takeLatest(informacionOperativaActions.LISTA_CONTRATOS_POR_SERVICIO_REQUEST, listaContratosPorServicio);
}

//********************** Cambiar el estado del Contrato Activo/inactivo *****************************
function* cambiarEstadoContrato(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idContrato = action.idContrato;

        const response = yield call(estadoContratoCall, token, idContrato);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const formulario = {
                proveedor: '',
                servicio: null,
                fechaFin: '',
            }
            const showSuccessMsg = true;
            const textMessage = "El estado del contrato cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: informacionOperativaActions.ESTADO_CONTRATO_SUCCESS });
            yield put({ type: informacionOperativaActions.LISTA_CONTRATOS_BUSQUEDA_REQUEST, page, formulario });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: informacionOperativaActions.ESTADO_CONTRATO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.ESTADO_CONTRATO_FAILURE });
    }
}
export function* cambiarEstadoContratoSaga() {
    yield takeLatest(informacionOperativaActions.ESTADO_CONTRATO_REQUEST, cambiarEstadoContrato);
}

//********************** Crear nuevo Contrato *****************************
function* crearContrato(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        
        const data = {
            "idContrato": null,
            "clave": action.formulario.clave,
            "descripcion": action.formulario.descripcion,
            "nombreArchivo": '',
            "rutaArchivo": '',
            "fechaInicio": action.formulario.rangoVigencia.split(",")[0]+'T00:00:00',
            "fechaFin": action.formulario.rangoVigencia.split(",")[1]+'T23:59:59',
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": 1,
            "servicioByIdContrato": null,
            "proveedorByIdContrato": null
        }
        console.log(data);

        const formData = new FormData();
        if(action.formulario.pdf){
            formData.append('file', action.formulario.pdf.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));

        const response = yield call(crearContratoCall, token, formData);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const formulario = {
                proveedor: '',
                servicio: null,
                fechaFin: '',
            }
            const showModalNuevoContrato = false;
            const showSuccessMsg = true;
            const textMessage = "El contrato " + action.formulario.clave + " se creó de forma correcta";
            if(action.listaInfoProyecto){
                yield put({ type: informacionDelProyectoActions.EDITAR_INFOPROYECTO_REQUEST, idContrato: response.data.id, listaInfoProyecto: action.listaInfoProyecto });
            }

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearContrato' });
            yield put({ type: informacionOperativaActions.NUEVO_CONTRATO_SUCCESS });
            yield put({ type: informacionOperativaActions.LISTA_CONTRATOS_BUSQUEDA_REQUEST, page, formulario });
            yield put({ type: informacionOperativaActions.LISTA_CONTRATOS_REQUEST });
            yield put({ type: informacionOperativaActions.SHOW_MODAL_NUEVO_CONTRATO, showModalNuevoContrato });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: informacionOperativaActions.NUEVO_CONTRATO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.NUEVO_CONTRATO_FAILURE });
    }
}
export function* crearContratoSaga() {
    yield takeLatest(informacionOperativaActions.NUEVO_CONTRATO_REQUEST, crearContrato);
}

//********************** Editar Contrato *****************************
function* editarContrato(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idContrato = action.contratoSeleccionado.idContrato;
        let data = {
            "idContrato": idContrato,
            "clave": action.formulario.clave,
            "descripcion": action.formulario.descripcion,
            "nombreArchivo": action.contratoSeleccionado.nombreArchivo,
            "rutaArchivo": action.contratoSeleccionado.rutaArchivo,
            "fechaInicio": action.formulario.rangoVigencia.split(",")[0]+'T00:00:00',
            "fechaFin": action.formulario.rangoVigencia.split(",")[1]+'T23:59:59',
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": action.contratoSeleccionado.activo,
            "servicioByIdContrato": action.contratoSeleccionado.servicioByIdContrato,
            "proveedorByIdContrato": null,
        }
        console.log(data);

        const formData = new FormData();
        if(action.formulario.pdf){
            formData.append('file', action.formulario.pdf.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        const response = yield call(editarContratoCall, token, formData, idContrato);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const formulario = {
                proveedor: '',
                servicio: null,
                fechaFin: '',
            }
            const showModalEditarContrato = false;
            const showSuccessMsg = true;
            let textMessage = "El contrato " + action.formulario.clave + " se modificó de forma correcta";
            let contratoSeleccionado = null;

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarContrato' });
            yield put({ type: informacionOperativaActions.EDITAR_CONTRATO_SUCCESS });
            yield put({ type: informacionDelProyectoActions.GET_INFOPROYECTO_REQUEST });
            yield put({ type: informacionOperativaActions.LISTA_CONTRATOS_BUSQUEDA_REQUEST, page, formulario });
            yield put({ type: informacionOperativaActions.SHOW_MODAL_EDITAR_CONTRATO, showModalEditarContrato, contratoSeleccionado });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: informacionOperativaActions.EDITAR_CONTRATO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.EDITAR_CONTRATO_FAILURE });
    }
}
export function* editarContratoSaga() {
    yield takeLatest(informacionOperativaActions.EDITAR_CONTRATO_REQUEST, editarContrato);
}


//************************ Lista de proveedores para Select ***************************
function* listaProveedores(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaProveedoresCall, token);
        console.log(response);

        if(response.status==200){
            const listaProveedores = response.data;
            
            yield put({ type: informacionOperativaActions.GET_PROVEEDORES_SUCCESS, listaProveedores });
        }
        else{
            yield put({ type: informacionOperativaActions.GET_PROVEEDORES_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.GET_PROVEEDORES_FAILURE });
    }
}
export function* listaProveedoresSaga() {
    yield takeLatest(informacionOperativaActions.GET_PROVEEDORES_REQUEST, listaProveedores);
}

//************************ Lista de proveedores para Select con búsqueda ***************************
function* listaParamProveedores(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const busqueda = action.busqueda;

        const response = yield call(listaBusquedaProveedoresCall, token, busqueda);
        console.log(response);

        if(response.status==200){
            const listaParamProveedores = response.data;
            
            yield put({ type: informacionOperativaActions.LISTA_PROVEEDORES_PARAM_SUCCESS, listaParamProveedores });
        }
        else{
            yield put({ type: informacionOperativaActions.LISTA_PROVEEDORES_PARAM_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.LISTA_PROVEEDORES_PARAM_FAILURE });
    }
}
export function* listaParamProveedoresSaga() {
    yield takeLatest(informacionOperativaActions.LISTA_PROVEEDORES_PARAM_REQUEST, listaParamProveedores);
}

//********************** Lista de proveedores para sección de Proveedores *****************************
function* proveedoresBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let busqueda = '';
        if(action.formulario.busqueda){
            busqueda = 'param=' + action.formulario.busqueda + '&';
        }
        let fechaFin = '';
        if(action.formulario.fechaFin){
            fechaFin = 'fechaFin=' + action.formulario.fechaFin.format('YYYY-MM-DD') + '%2023:59:59' + '&';
        }
        
        let response  = yield call(proveedoresBusquedaCall, token, page, busqueda, fechaFin);
        console.log(response);

        if(response.status==200){
            const proveedoresBusquedaCantidad = response.data.cantidad;
            const proveedoresBusqueda = response.data.data;

            for(let i=0; i<proveedoresBusqueda.length; i++){
                if(proveedoresBusqueda[i].rfc.length === 12){
                proveedoresBusqueda[i].persona='moral';
                }else{
                    proveedoresBusqueda[i].persona='fisica';
                }

                let fechaAlta = proveedoresBusqueda[i].fechaAlta.split('T')[0];
                fechaAlta = fechaAlta.split('-')[2]+'-'+fechaAlta.split('-')[1]+'-'+ fechaAlta.split('-')[0];
                proveedoresBusqueda[i].fechaAlta = fechaAlta;
                
                let fechaFin = proveedoresBusqueda[i].fechaFin.split('T')[0];
                fechaFin = fechaFin.split('-')[2]+'-'+fechaFin.split('-')[1]+'-'+ fechaFin.split('-')[0];
                proveedoresBusqueda[i].fechaFin = fechaFin;

                let fechaInicio = proveedoresBusqueda[i].fechaInicio.split('T')[0];
                fechaInicio = fechaInicio.split('-')[2]+'-'+fechaInicio.split('-')[1]+'-'+ fechaInicio.split('-')[0];
                proveedoresBusqueda[i].fechaInicio = fechaInicio;
                
                proveedoresBusqueda[i].rangoVigencia = proveedoresBusqueda[i].fechaInicio + ', ' + proveedoresBusqueda[i].fechaFin;

                proveedoresBusqueda[i].calle = proveedoresBusqueda[i].domicilio.split(', ')[0];
                proveedoresBusqueda[i].numeroExterior = proveedoresBusqueda[i].domicilio.split(', ')[1];
                if(proveedoresBusqueda[i].domicilio.split(', ')[2] == 'undefined'){
                    proveedoresBusqueda[i].numeroInterior = '';
                }
                else{
                    proveedoresBusqueda[i].numeroInterior = proveedoresBusqueda[i].domicilio.split(', ')[2];
                }
                proveedoresBusqueda[i].nombreColonia = proveedoresBusqueda[i].domicilio.split(', ')[3];
                proveedoresBusqueda[i].cp = proveedoresBusqueda[i].domicilio.split(', ')[4];
                proveedoresBusqueda[i].municipio = proveedoresBusqueda[i].domicilio.split(', ')[5];
                proveedoresBusqueda[i].estado = proveedoresBusqueda[i].domicilio.split(', ')[6];
                
                if(proveedoresBusqueda[i].dominiosByIdProveedor){
                    proveedoresBusqueda[i].idDominio = '';
                    proveedoresBusqueda[i].nombreDominio = '';
                    proveedoresBusqueda[i].dominios = [];
                    for(let x=0; x<proveedoresBusqueda[i].dominiosByIdProveedor.length; x++){
                        proveedoresBusqueda[i].idDominio += '-' + proveedoresBusqueda[i].dominiosByIdProveedor[x].idDominio + ' ';
                        proveedoresBusqueda[i].nombreDominio += '-' + proveedoresBusqueda[i].dominiosByIdProveedor[x].dominio + proveedoresBusqueda[i].dominiosByIdProveedor[x].terminacion + ' ';
                        proveedoresBusqueda[i].dominios.push( proveedoresBusqueda[i].dominiosByIdProveedor[x].idDominio +  ' - ' + proveedoresBusqueda[i].dominiosByIdProveedor[x].dominio + proveedoresBusqueda[i].dominiosByIdProveedor[x].terminacion)
                    }
                }
                else{
                    proveedoresBusqueda[i].idDominio = 'No';
                    proveedoresBusqueda[i].nombreDominio = 'No tiene dominio';
                }
                if(proveedoresBusqueda[i].expedienteByIdExpediente){
                    proveedoresBusqueda[i].nombreExpediente = proveedoresBusqueda[i].expedienteByIdExpediente.clave;
                }
                else{
                    proveedoresBusqueda[i].nombreExpediente = 'No tiene expediente';
                }
                if(proveedoresBusqueda[i].serviciosByIdProveedor){
                    proveedoresBusqueda[i].idServicio = '';
                    proveedoresBusqueda[i].nombreServicio = '';
                    proveedoresBusqueda[i].servicios = [];
                    for(let x=0; x<proveedoresBusqueda[i].serviciosByIdProveedor.length; x++){
                        proveedoresBusqueda[i].nombreServicio += '-' + proveedoresBusqueda[i].serviciosByIdProveedor[x].nombre + ' ';
                        proveedoresBusqueda[i].servicios.push(proveedoresBusqueda[i].serviciosByIdProveedor[x].idServicio + ' - ' + proveedoresBusqueda[i].serviciosByIdProveedor[x].nombre)
                    }
                }
                else{
                    proveedoresBusqueda[i].nombreServicio = 'No tiene servicio';
                }

                if(proveedoresBusqueda[i].partidasAreasByIdProveedor){
                        let idsPartidas = [];
                        let idsAreas = [];
                        let partida = [];
                        let area = [];
                        for(let x=0; x < proveedoresBusqueda[i].partidasAreasByIdProveedor.length; x++){
                            idsPartidas.push(proveedoresBusqueda[i].partidasAreasByIdProveedor[x].idPartida);
                            idsAreas.push(proveedoresBusqueda[i].partidasAreasByIdProveedor[x].idAreaFuncional);

                            if(proveedoresBusqueda[i].asignacion === 'area' || proveedoresBusqueda[i].asignacion === 'Area' ){
                                if(proveedoresBusqueda[i].partidasAreasByIdProveedor[x].areaFuncionalByIdAreaFuncional){
                                    area.push(proveedoresBusqueda[i].partidasAreasByIdProveedor[x].idAreaFuncional + ' - ' + proveedoresBusqueda[i].partidasAreasByIdProveedor[x].areaFuncionalByIdAreaFuncional.areaFuncional);
                                }
                            } else if(proveedoresBusqueda[i].asignacion === 'partida' || proveedoresBusqueda[i].asignacion === 'Partida'){
                                if(proveedoresBusqueda[i].partidasAreasByIdProveedor[x].partidaByIdPartida){
                                    partida.push(proveedoresBusqueda[i].partidasAreasByIdProveedor[x].idPartida + ' - ' + proveedoresBusqueda[i].partidasAreasByIdProveedor[x].partidaByIdPartida.nombre);
                                }
                            }
                        }

                        let filteredArrayPartidas = idsPartidas.filter(function(item, pos){
                            return idsPartidas.indexOf(item)== pos; 
                        });

                        let filteredArrayAreas = idsAreas.filter(function(item, pos){
                            return idsAreas.indexOf(item)== pos; 
                        });

                        let filteredArrayAreaNombres = area.filter(function(item, pos){
                            return area.indexOf(item)== pos; 
                        });

                        let filteredArrayPartidaNombres = partida.filter(function(item, pos){
                            return partida.indexOf(item)== pos; 
                        });
            
                    proveedoresBusqueda[i].idsPartidas = filteredArrayPartidas;
                    proveedoresBusqueda[i].idsAreas = filteredArrayAreas;
                    proveedoresBusqueda[i].area = filteredArrayAreaNombres;
                    proveedoresBusqueda[i].partida = filteredArrayPartidaNombres;

                }

            }
            yield put({ type: informacionOperativaActions.LISTA_PROVEEDORES_BUSQUEDA_SUCCESS, proveedoresBusqueda, proveedoresBusquedaCantidad });
        }
        else{
            yield put({ type: informacionOperativaActions.LISTA_PROVEEDORES_BUSQUEDA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.LISTA_PROVEEDORES_BUSQUEDA_FAILURE });
    }
}
export function* proveedoresBusquedaSaga() {
    yield takeLatest(informacionOperativaActions.LISTA_PROVEEDORES_BUSQUEDA_REQUEST, proveedoresBusqueda);
}

//********************** Cambiar el estado del Proveedor Activo/inactivo *****************************
function* cambiarEstadoProveedor(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idProveedor = action.idProveedor;

        const response = yield call(estadoProveedorCall, token, idProveedor);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const formulario = {
                busqueda: '',
                fechaFin: '',
            }
            const showSuccessMsg = true;
            const textMessage = "El estado del proveedor cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: informacionOperativaActions.ESTADO_PROVEEDOR_SUCCESS });
            yield put({ type: informacionOperativaActions.LISTA_PROVEEDORES_BUSQUEDA_REQUEST, page, formulario });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: informacionOperativaActions.ESTADO_PROVEEDOR_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.ESTADO_PROVEEDOR_FAILURE });
    }
}
export function* cambiarEstadoProveedorSaga() {
    yield takeLatest(informacionOperativaActions.ESTADO_PROVEEDOR_REQUEST, cambiarEstadoProveedor);
}

//********************** Crear nuevo Proveedor *****************************
function* crearProveedor(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let dominios = [];
        let servicios = [];
        let domicilio = action.formulario.calle + ', ' + action.formulario.numeroExterior + ', ' + 
        action.formulario.numeroInterior + ', ' + action.formulario.nombreColonia + ', ' + 
        action.formulario.cp + ', ' + action.formulario.municipio + ', ' + action.formulario.estado;
        let idExpediente = null;
        let SPA = []; //Servicios Partidas Areas

        if(action.formulario.nombreExpediente){
            idExpediente = parseInt(action.formulario.nombreExpediente);
        }
        
        let arrayAsignado = [];
        for(let i in action.listaAsignacionProveedor){
            if(action.listaAsignacionProveedor[i].asignado){
                const arr = {
                    'idServicio': action.listaAsignacionProveedor[i].idServicio,
                    'asignado': action.listaAsignacionProveedor[i].asignado
                }
                arrayAsignado.push(arr);
            }
        
        }

        for(let i in arrayAsignado){
            for(let j in action.listaAsignacionProveedor){
                if(arrayAsignado[i].idServicio === action.listaAsignacionProveedor[j].idServicio){
                    if(action.listaAsignacionProveedor[j].hasOwnProperty('partida')){
                        arrayAsignado[i].partida = action.listaAsignacionProveedor[j].partida;
                    } else if (action.listaAsignacionProveedor[j].hasOwnProperty('area')){
                        arrayAsignado[i].area = action.listaAsignacionProveedor[j].area;
                    }
                }
            }
        }

        console.log('arrayAsignado', arrayAsignado)

        for(let i in arrayAsignado){
            let partidas = [];
            let areas = [];
            
            Object.size = function(obj) {
                let size = 0,
                  key;
                for (key in obj) {
                  if (obj.hasOwnProperty(key)) size++;
                }
                return size;
            };
              
            // Get the size of an object
            let size = Object.size(arrayAsignado[i]);
            let idProveedor = ''
            if(arrayAsignado[i].asignado === 'Todo' && size === 4){
                console.log('Entra a TODO con Partidas');
                for(let y in action.listaPartidasByServicioWithout){
                    if(arrayAsignado[i].idServicio === action.listaPartidasByServicioWithout[y].idServicio ){
                        partidas.push(action.listaPartidasByServicioWithout[y].idPartida);
                    }
                }
                console.log('partidas dentro', partidas);
                let listaAreasProveedores = action.listaAreasFuncionalesActivas;

                for(let a in partidas){
                    for(let b in listaAreasProveedores){
                        let SPALocal = {
                            'idServicio': arrayAsignado[i].idServicio,
                            'idPartida': partidas[a],
                            'idAreaFuncional': listaAreasProveedores[b].idAreaFuncional,
                            'asignacion': arrayAsignado[i].asignado
                        }
                        SPA.push(SPALocal);
                    }
                }
                console.log('SPA en TODO', SPA);

            } else if(arrayAsignado[i].asignado === 'Todo' && size === 3){
                console.log('Entra a TODO sin Partidas');
                let idServ = 'idServicio=' + arrayAsignado[i].idServicio;
                let listaAreasProveedores = action.listaAreasFuncionalesActivas;

                    for(let b in listaAreasProveedores){
                        let SPALocal = {
                            'idServicio': arrayAsignado[i].idServicio,
                            'idPartida': null,
                            'idAreaFuncional': listaAreasProveedores[b].idAreaFuncional,
                            'asignacion': arrayAsignado[i].asignado
                        }
                        SPA.push(SPALocal);
                    }

            } else if (arrayAsignado[i].asignado === 'Partida'){
                console.log('Entra a PARTIDA');
                for(let x in arrayAsignado[i].partida){
                    partidas.push(parseInt(arrayAsignado[i].partida[x]));
                }

                console.log('partidas', partidas);
                let listaAreasProveedores = action.listaAreasFuncionalesActivas;

                for(let a in partidas){
                    for(let b in listaAreasProveedores){
                        let SPALocal = {
                            'idServicio': arrayAsignado[i].idServicio,
                            'idPartida': partidas[a],
                            'idAreaFuncional': listaAreasProveedores[b].idAreaFuncional,
                            'asignacion': arrayAsignado[i].asignado
                        }
                        SPA.push(SPALocal);
                    }
                }
                console.log('SPA en PARTIDA', SPA);

            } else if(arrayAsignado[i].asignado === 'Area' && size === 4){
                console.log('Entra a AREA');

                for(let x in arrayAsignado[i].area){
                    areas.push(parseInt(arrayAsignado[i].area[x]));
                }

                console.log('areas', areas)

                for(let a in areas){
                    for(let b in action.listaPartidasByServicioWithout){
                        if(arrayAsignado[i].idServicio === action.listaPartidasByServicioWithout[b].idServicio ){
                            let SPALocal = {
                                'idServicio': arrayAsignado[i].idServicio,
                                'idPartida': action.listaPartidasByServicioWithout[b].idPartida,
                                'idAreaFuncional': areas[a],
                                'asignacion': arrayAsignado[i].asignado
                            }
                            SPA.push(SPALocal);
                        }
                    }
                }
                console.log('SPA en AREA', SPA);
            } else if(arrayAsignado[i].asignado === 'Area' && size === 3){
                console.log('Entra a AREA sin Partida');
                for(let x in arrayAsignado[i].area){
                    areas.push(parseInt(arrayAsignado[i].area[x]));
                }

                console.log('areas', areas)

                for(let a in areas){
                    let SPALocal = {
                        'idServicio': arrayAsignado[i].idServicio,
                        'idPartida': null,
                        'idAreaFuncional': areas[a],
                        'asignacion': arrayAsignado[i].asignado
                    }
                    SPA.push(SPALocal);
                    
                }
            }
        }

        console.log('SPA', SPA);
        
        for(let i=0; i<action.formulario.idServicio.length; i++){
            for(let x=0; x<action.listaServicios.length; x++){
                if(parseInt(action.formulario.idServicio[i].split(' - ')[0]) === action.listaServicios[x].idServicio){
                    const servicio = {
                        "idServicio": action.listaServicios[x].idServicio,
                        "idGrupoSer": action.listaServicios[x].idGrupoSer,
                        "nomenclatura": action.listaServicios[x].nomenclatura,
                        "nombre": action.listaServicios[x].nombre,
                        "nombreC": action.listaServicios[x].nombreC,
                        "nombreManual": action.listaServicios[x].nombreManual,
                        "urlImagen": action.listaServicios[x].urlImagen,
                        "rutaManual": action.listaServicios[x].rutaManual,
                        "peso": action.listaServicios[x].peso,
                        "observaciones": action.listaServicios[x].observaciones,
                        "fechaAlta": null,
                        "fechaActualizacion": null,
                        "activo": 1,
                        "ponderacionByIdServicio": null,
                        'idContrato': action.listaServicios[x].idContrato,
                        "contratoByIdContrato": null,
                    }
                    servicios.push(servicio);
                }
            }
        }

        if(action.formulario.idDominio){
            for(let i=0; i<action.formulario.idDominio.length; i++){
                for(let x=0; x<action.listaDominiosActivos.length; x++){
                    if(parseInt(action.formulario.idDominio[i].split(' - ')[0]) === action.listaDominiosActivos[x].idDominio){
                        const dominio = {
                            'idDominio': action.listaDominiosActivos[x].idDominio,
                            'dominio': action.listaDominiosActivos[x].dominio,
                            'terminacion': action.listaDominiosActivos[x].terminacion,
                            'fechaAlta': null,
                            'activo': 1,
                            'fechaActualizacion': null,
                        }
                        dominios.push(dominio);
                    }
                }
            }
        }

        const data = {
            "idProveedor": null,
            "rfc": action.formulario.rfc,
            "razonSocial": action.formulario.razonSocial,
            "nombre": action.formulario.nombre,
            "domicilio": domicilio,
            "correo": action.formulario.correo,
            "fechaInicio": action.formulario.rangoVigencia.split(',')[0] + 'T00:00:00',
            "fechaFin": action.formulario.rangoVigencia.split(',')[1] + 'T23:59:59',
            'idExpediente': idExpediente,
            "activo": true,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "dominiosByIdProveedor": dominios,
            //"serviciosByIdProveedor": servicios,
            "contratoByIdProveedor": null,
            "expedienteByIdExpediente": null,
            "serviciosProveedoresByIdProveedor": SPA
        }

        console.log("data", data);
        const response = yield call(crearProveedorCall, token, data);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const formulario = {
                busqueda: '',
                fechaFin: '',
            }
            const showModalNuevoProveedor = false;
            const showSuccessMsg = true;
            const textMessage = "El proveedor " + action.formulario.nombre + " se creó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearProveedor' });
            yield put({ type: informacionOperativaActions.NUEVO_PROVEEDOR_SUCCESS });
            yield put({ type: informacionOperativaActions.LISTA_EXPEDIENTES_REQUEST });
            yield put({ type: informacionOperativaActions.LISTA_SERVICIOS_PROVEEDORES_REQUEST });
            yield put({ type: informacionOperativaActions.LISTA_PROVEEDORES_BUSQUEDA_REQUEST, page, formulario });
            yield put({ type: informacionOperativaActions.SHOW_MODAL_NUEVO_PROVEEDOR, showModalNuevoProveedor });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: informacionOperativaActions.NUEVO_PROVEEDOR_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.NUEVO_PROVEEDOR_FAILURE });
    }
}
export function* crearProveedorSaga() {
    yield takeLatest(informacionOperativaActions.NUEVO_PROVEEDOR_REQUEST, crearProveedor);
}

//********************** Editar Proveedor *****************************
function* editarProveedor(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idProveedor = action.proveedorSeleccionado.idProveedor;
        const idProveedorServ = '&idProveedor=' + action.proveedorSeleccionado.idProveedor;
        let dominios = [];
        let servicios = [];
        let idExpediente = null;
        let domicilio = action.formulario.calle + ', ' + action.formulario.numeroExterior + ', ' + 
        action.formulario.numeroInterior + ', ' + action.formulario.nombreColonia + ', ' + 
        action.formulario.cp + ', ' + action.formulario.municipio + ', ' + action.formulario.estado;
        let SPA = []; //Servicios Partidas Áreas
        
        let arrayAsignado = [];
        for(let i in action.listaAsignacionProveedor){
            if(action.listaAsignacionProveedor[i].asignado){
                const arr = {
                    'idServicio': action.listaAsignacionProveedor[i].idServicio,
                    'asignado': action.listaAsignacionProveedor[i].asignado
                }
                arrayAsignado.push(arr);
            }
        
        }


        for(let i in arrayAsignado){
            for(let j in action.listaAsignacionProveedor){
                if(arrayAsignado[i].idServicio === action.listaAsignacionProveedor[j].idServicio){
                    if(action.listaAsignacionProveedor[j].hasOwnProperty('partida')){
                        arrayAsignado[i].partida = action.listaAsignacionProveedor[j].partida;
                    } else if (action.listaAsignacionProveedor[j].hasOwnProperty('area')){
                        arrayAsignado[i].area = action.listaAsignacionProveedor[j].area;
                    }
                }
            }
        }

        console.log('arrayAsignado', arrayAsignado)

        for(let i in arrayAsignado){
            let partidas = [];
            let areas = [];
            
            Object.size = function(obj) {
                let size = 0,
                  key;
                for (key in obj) {
                  if (obj.hasOwnProperty(key)) size++;
                }
                return size;
            };
              
            // Get the size of an object
            let size = Object.size(arrayAsignado[i]);

            console.log('size', size)

            if(arrayAsignado[i].asignado === 'Todo' && size === 4){
                console.log('Entra a TODO con Partidas');
                for(let y in action.listaPartidasByServicioWithout){
                    if(arrayAsignado[i].idServicio === action.listaPartidasByServicioWithout[y].idServicio ){
                        partidas.push(action.listaPartidasByServicioWithout[y].idPartida);
                    }
                }
                console.log('partidas dentro', partidas);
                let listaAreasProveedores = action.listaAreasFuncionalesActivas;
                
                for(let a in partidas){
                    if(!isNaN(partidas[a])){
                        for(let b in listaAreasProveedores){
                            let SPALocal = {
                                'idServicio': arrayAsignado[i].idServicio,
                                'idPartida': partidas[a],
                                'idAreaFuncional': listaAreasProveedores[b].idAreaFuncional,
                                'asignacion': arrayAsignado[i].asignado
                            }
                            SPA.push(SPALocal);
                        }
                    }
                }
                console.log('SPA en TODO', SPA);

            } else if(arrayAsignado[i].asignado === 'Todo' && size === 3){
                console.log('Entra a TODO sin Partidas');
                let listaAreasProveedores = action.listaAreasFuncionalesActivas;
                let idServ = 'idServicio=' + arrayAsignado[i].idServicio;

                    for(let b in listaAreasProveedores){
                        let SPALocal = {
                            'idServicio': arrayAsignado[i].idServicio,
                            'idPartida': null,
                            'idAreaFuncional': listaAreasProveedores[b].idAreaFuncional,
                            'asignacion': arrayAsignado[i].asignado
                        }
                        SPA.push(SPALocal);
                    }

            } else if (arrayAsignado[i].asignado === 'Partida'){
                console.log('Entra a PARTIDA');
                for(let x in arrayAsignado[i].partida){
                    partidas.push(parseInt(arrayAsignado[i].partida[x]));
                }

                console.log('partidas', partidas);
                let listaAreasProveedores = action.listaAreasFuncionalesActivas;

                for(let a in partidas){
                    if(!isNaN(partidas[a])){
                        for(let b in listaAreasProveedores){
                            let SPALocal = {
                                'idServicio': arrayAsignado[i].idServicio,
                                'idPartida': partidas[a],
                                'idAreaFuncional': listaAreasProveedores[b].idAreaFuncional,
                                'asignacion': arrayAsignado[i].asignado
                            }
                            SPA.push(SPALocal);
                        }
                    }
                }
                console.log('SPA en PARTIDA', SPA);

            } else if(arrayAsignado[i].asignado === 'Area' && size === 4){
                console.log('Entra a AREA');

                for(let x in arrayAsignado[i].area){
                    areas.push(parseInt(arrayAsignado[i].area[x]));
                }

                console.log('areas', areas);
                console.log('listaPartidasByServicioWithout', action.listaPartidasByServicioWithout)

                for(let a in areas){
                    if(action.listaPartidasByServicioWithout.length >= 1){
                        for(let b in action.listaPartidasByServicioWithout){
                            if(arrayAsignado[i].idServicio === action.listaPartidasByServicioWithout[b].idServicio ){
                                let SPALocal = {
                                    'idServicio': arrayAsignado[i].idServicio,
                                    'idPartida': action.listaPartidasByServicioWithout[b].idPartida,
                                    'idAreaFuncional': areas[a],
                                    'asignacion': arrayAsignado[i].asignado
                                }
                                SPA.push(SPALocal);
                            }
                        }
                    } else {
                        let SPALocal = {
                            'idServicio': arrayAsignado[i].idServicio,
                            'idPartida': null,
                            'idAreaFuncional': areas[a],
                            'asignacion': arrayAsignado[i].asignado
                        }
                        SPA.push(SPALocal);
                    }
                }
                console.log('SPA en AREA', SPA);
            } else if(arrayAsignado[i].asignado === 'Area' && size === 3){
                console.log('Entra a AREA sin Partida');
                for(let x in arrayAsignado[i].area){
                    areas.push(parseInt(arrayAsignado[i].area[x]));
                }

                console.log('areas', areas)

                for(let a in areas){
                    let SPALocal = {
                        'idServicio': arrayAsignado[i].idServicio,
                        'idPartida': null,
                        'idAreaFuncional': areas[a],
                        'asignacion': arrayAsignado[i].asignado
                    }
                    SPA.push(SPALocal);
                    
                }
            }
        }

        console.log('SPA', SPA);

        if(action.formulario.dominios){
            for(let i=0; i<action.formulario.dominios.length; i++){
                for(let x=0; x<action.listaDominiosActivos.length; x++){
                    if(parseInt(action.formulario.dominios[i]) === action.listaDominiosActivos[x].idDominio){
                        const dominio = {
                            'idDominio': action.listaDominiosActivos[x].idDominio,
                            'dominio': action.listaDominiosActivos[x].dominio,
                            'terminacion': action.listaDominiosActivos[x].terminacion,
                            'fechaAlta': null,
                            'activo': 1,
                            'fechaActualizacion': null,
                        }
                        dominios.push(dominio);
                    }
                }
            }
        }

        if(action.formulario.nombreExpediente !== 'No tiene expediente'){
            console.log('Entró a if no tiene')
            idExpediente = action.proveedorSeleccionado.idExpediente;
            for(let i=0; i<action.listaExpedientes.length; i++){
                console.log('ver en el for')
                console.log(action.formulario.nombreExpediente)
                console.log(action.listaExpedientes[i].clave)
                if(action.formulario.nombreExpediente === action.listaExpedientes[i].clave){
                    console.log('entró al if de clave');
                    idExpediente = action.listaExpedientes[i].idExpediente;
                }
            }
        }

        const data = {
            "idProveedor": idProveedor,
            "rfc": action.formulario.rfc,
            "razonSocial": action.formulario.razonSocial,
            "nombre": action.formulario.nombre,
            "domicilio": domicilio,
            "correo": action.formulario.correo,
            "fechaInicio": action.formulario.rangoVigencia.split(',')[0] + 'T00:00:00',
            "fechaFin": action.formulario.rangoVigencia.split(',')[1] + 'T23:59:59',
            "activo": true,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "dominiosByIdProveedor": dominios,
            "serviciosProveedoresByIdProveedor": SPA,
            "contratoByIdProveedor": null,
            'idExpediente': idExpediente,
            "expedienteByIdExpediente": null,
        }

        console.log('data', data)

        const response = yield call(editarProveedorCall, token, data, idProveedor);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const formulario = {
                busqueda: '',
                fechaFin: '',
            }
            const showModalEditarProveedor = false;
            const proveedorSeleccionado = null;
            const showSuccessMsg = true;
            const textMessage = "El proveedor " + action.formulario.razonSocial + " se modificó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarProveedor' });
            yield put({ type: informacionOperativaActions.EDITAR_PROVEEDOR_SUCCESS });
            yield put({ type: informacionOperativaActions.LISTA_EXPEDIENTES_REQUEST });
            yield put({ type: informacionOperativaActions.LISTA_SERVICIOS_PROVEEDORES_REQUEST });
            yield put({ type: informacionOperativaActions.LISTA_PROVEEDORES_BUSQUEDA_REQUEST, page, formulario });
            yield put({ type: informacionOperativaActions.SHOW_MODAL_EDITAR_PROVEEDOR, showModalEditarProveedor, proveedorSeleccionado });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: informacionOperativaActions.EDITAR_PROVEEDOR_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.EDITAR_PROVEEDOR_FAILURE });
    }
}
export function* editarProveedorSaga() {
    yield takeLatest(informacionOperativaActions.EDITAR_PROVEEDOR_REQUEST, editarProveedor);
}

// Lista Servicios Activos sin asignar a Proveedores
function* listaServiciosActivosForProveedor(action) {
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');

        let idProveedor = '';
        if(action.idProveedor){
            idProveedor = '?idProveedor=' + action.idProveedor;
        }
    
        const response = yield call (listaServiciosActivosForProveedorCall, token, idProveedor);
        console.log('response', response);
    
        if (response.status == 200) {
            let listaServiciosActivosForProveedor = response.data;
            
            yield put({ type: informacionOperativaActions.LISTA_SERVICIOS_PROVEEDORES_SUCCESS, listaServiciosActivosForProveedor})

            console.log('listaServiciosActivosForProveedor', listaServiciosActivosForProveedor)
        }
        else {
            yield put({ type: informacionOperativaActions.LISTA_SERVICIOS_PROVEEDORES_FAILURE});
        }
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.LISTA_SERVICIOS_PROVEEDORES_FAILURE});
    }

}
export function* listaServiciosActivosForProveedorSaga() {
    yield takeLatest(informacionOperativaActions.LISTA_SERVICIOS_PROVEEDORES_REQUEST, listaServiciosActivosForProveedor);
}

// Lista Areas By idServicio/Partidas
function* listaAreasProveedores(action) {
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idServicio = '';
        let data = [];
        let idProveedor = '';

        if(action.partidas){
            data = action.partidas
        }

        if(data.length === 0){
            idServicio = 'idServicio=' + action.idServicio;
        }

        if(action.idProveedor){
            idProveedor = '&idProveedor=' + action.idProveedor;
        }
    
        const response = yield call (listaAreasProveedoresCall, token, idServicio, idProveedor, data);
        console.log('response', response);
    
        if (response.status == 200) {
            let listaAreasProveedores = response.data;
            console.log('listaAreasProveedores', listaAreasProveedores)
            
            yield put({ type: informacionOperativaActions.LISTA_AREAS_PROVEEDORES_SUCCESS, listaAreasProveedores})
        }
        else {
            yield put({ type: informacionOperativaActions.LISTA_AREAS_PROVEEDORES_FAILURE});
        }
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.LISTA_AREAS_PROVEEDORES_FAILURE});
    }

}
export function* listaAreasProveedoresSaga() {
    yield takeLatest(informacionOperativaActions.LISTA_AREAS_PROVEEDORES_REQUEST, listaAreasProveedores);
}

// Lista partidas por idServicio
function* listaPartidasByServicioInfoProyecto(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let data = [];
        let idServiciosSeleccionados = [];

        if(action.idsServicios){
            for(let i = 0; i < action.idsServicios.length; i++){
                const servicio = {
                    "idServicio": parseInt(action.idsServicios[i]),
                }
                data.push(servicio);
                idServiciosSeleccionados.push(parseInt(action.idsServicios[i]));
            }
        }

        const response = yield call(listaPartidasByServicioCall, token, idServiciosSeleccionados);
        console.log('response', response);
        if (response.status === 200) {
            let listaPartidasByServicio = [];
            let listaIds = [];
            let listaServicios = action.listaServiciosActivosForProveedor;
            let listaServiciosAct = action.serviciosActivos;
            let listaPartidasByServicioWithout = response.data;
            
            for(let i in response.data){
                listaIds.push(response.data[i].idServicio);
            }

            let listaIdsFilter = listaIds.filter(function(item, pos){
                return listaIds.indexOf(item)== pos; 
            });

            for(let i in listaIdsFilter){
                let listaPartida = [];
                for(let j in response.data){
                    if(response.data[j].idServicio === listaIdsFilter[i]){
                        listaPartida.push(response.data[j])
                    }
                }
                listaPartidasByServicio.push(listaPartida);
            }

            const diferenciaDeArreglos = (arr1, arr2) => {return arr1.filter(elemento => arr2.indexOf(elemento) == -1);}
            let idsServiciosSinPartidas = diferenciaDeArreglos(idServiciosSeleccionados, listaIds);
            let servicioByIdServicio = [];
            let listaSinPartida = [];
            

            for(let i in listaServicios){
                for(let j in idsServiciosSinPartidas){
                    if(listaServicios[i].idServicio === idsServiciosSinPartidas[j]){
                        servicioByIdServicio.push(listaServicios[i]);
                    }
                }
            }

            for(let i = 0; i < idsServiciosSinPartidas.length; i++){
                for(let j in listaServiciosAct){
                    if(idsServiciosSinPartidas[i] === listaServiciosAct[j].idServicio){
                        const sinPartidas = {
                            "idServicio": idsServiciosSinPartidas[i],
                            "servicioByIdServicio": listaServiciosAct[j],
                            "idPartida": null
                        }
                        listaSinPartida.push(sinPartidas);
                    }
                }
            }

            for(let i in listaSinPartida){
                let array = [];
                array.push(listaSinPartida[i]);
                listaPartidasByServicio.push(array);
            }
            console.log('listaPartidasByServicioWithout', listaPartidasByServicioWithout);
            console.log('listaPartidasByServicio', listaPartidasByServicio)

            yield put({ type: informacionOperativaActions.LISTA_PARTIDAS_BY_SERVICIO_INFO_OP_SUCCESS, listaPartidasByServicio, listaPartidasByServicioWithout});

            // *********************************************************************
            // let listaAreasProveedores = []
            // let responseArea = {}

            // for(let i in idServiciosSeleccionados){
            //     for(let j in listaPartidasByServicio){
            //         let idsPartidasLocal = [];
            //         for(let k in listaPartidasByServicio[j]){
            //             if(listaPartidasByServicio[j][k].idServicio === idServiciosSeleccionados[i]){
            //                 idsPartidasLocal.push(listaPartidasByServicio[j][k].idPartida);

            //                 let idServicioSel = '';
            //                 if(!idsPartidasLocal[0]){
            //                     idServicioSel = 'idServicio=' + idServiciosSeleccionados[i];
            //                 }

            //                 responseArea = yield call (listaAreasProveedoresCall, token, idServicioSel, idsPartidasLocal);
            //             }
            //         }
            //     }
               
            //     if (responseArea.status == 200) {
            //         const list = {
            //             'idServicio': idServiciosSeleccionados[i],
            //             'areasByIdServicio': responseArea.data
            //         }
            //         listaAreasProveedores.push(list);
            //         yield put({ type: informacionOperativaActions.LISTA_AREAS_PROVEEDORES_SUCCESS, listaAreasProveedores})
            //     }
            //     else {
            //         yield put({ type: informacionOperativaActions.LISTA_AREAS_PROVEEDORES_FAILURE});
            //     }
            // }

            // for(let i in listaPartidasByServicio){
            //     if(listaPartidasByServicio[i][0].idServicio === listaAreasProveedores[i].idServicio){
            //         listaPartidasByServicio[i][0].areas = listaAreasProveedores[i].areasByIdServicio;
            //     }
            // }

            // console.log('listaPartidasByServicio', listaPartidasByServicio)

        } else {
            yield put({ type: informacionOperativaActions.LISTA_PARTIDAS_BY_SERVICIO_INFO_OP_FAILURE});
        }
    } catch (error) {
        yield put({ type: informacionOperativaActions.LISTA_PARTIDAS_BY_SERVICIO_INFO_OP_FAILURE});
    }
}
export function* listaPartidasByServicioInfoProyectoSaga(){
    yield takeLatest(informacionOperativaActions.LISTA_PARTIDAS_BY_SERVICIO_INFO_OP_REQUEST, listaPartidasByServicioInfoProyecto);
}

function* listaPartidasByServicios(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const data = [];
        for(let i in action.idsServicios){
            data.push(parseInt(action.idsServicios[i]));
        }

        let response = yield call(listaPartidasByServiciosCall, token, data);
        console.log(response);

        if(response.status==200){
            const listaPartidasByServicios = response.data;

            yield put({ type: informacionOperativaActions.LISTA_PARTIDAS_BY_SERVICIOS_SUCCESS, listaPartidasByServicios });
        }
        else{
            yield put({ type: informacionOperativaActions.LISTA_PARTIDAS_BY_SERVICIOS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.LISTA_PARTIDAS_BY_SERVICIOS_FAILURE });
    }
}
export function* listaPartidasByServiciosSaga() {
    yield takeLatest(informacionOperativaActions.LISTA_PARTIDAS_BY_SERVICIOS_REQUEST, listaPartidasByServicios);
}

//******************Catálogo de Partidas******************
function* listaPartidasBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let param = '';
        if(action.busqueda.param){
            param = 'param=' + action.busqueda.param + '&';
        }
        let servicio = '';
        if(action.busqueda.servicio){
            servicio = 'servicio=' + action.busqueda.servicio + '&';
        }
        let response = yield call(listaPartidasBusquedaCall, token, page, param, servicio);
        console.log(response);

        if(response.status==200){
            const listaPartidasBusqueda = response.data.data;
            const listaPartidasBusquedaCantidad = response.data.cantidad;
            for(let i in listaPartidasBusqueda){
                listaPartidasBusqueda[i].servicio = listaPartidasBusqueda[i].servicioByIdServicio.nombre;
                listaPartidasBusqueda[i].servicioForm = listaPartidasBusqueda[i].servicioByIdServicio.idServicio + ' - ' + listaPartidasBusqueda[i].servicioByIdServicio.nomenclatura + ' - ' + listaPartidasBusqueda[i].servicioByIdServicio.nombre ;
                if(listaPartidasBusqueda[i].contratoByIdContrato){
                    listaPartidasBusqueda[i].contratoForm = listaPartidasBusqueda[i].contratoByIdContrato.clave + ' - ' + listaPartidasBusqueda[i].contratoByIdContrato.descripcion;
                }
                listaPartidasBusqueda[i].fechaAltaGrid = listaPartidasBusqueda[i].fechaAlta.split('T')[0].split('-').reverse().join('-') + ' ' + listaPartidasBusqueda[i].fechaAlta.split('T')[1].split(':')[0] + ':' + listaPartidasBusqueda[i].fechaAlta.split('T')[1].split(':')[1];
                listaPartidasBusqueda[i].fechaActualizacionGrid = listaPartidasBusqueda[i].fechaActualizacion.split('T')[0].split('-').reverse().join('-') + ' ' + listaPartidasBusqueda[i].fechaActualizacion.split('T')[1].split(':')[0] + ':' + listaPartidasBusqueda[i].fechaActualizacion.split('T')[1].split(':')[1];
            }
            
            yield put({ type: informacionOperativaActions.LISTA_PARTIDAS_BUSQUEDA_SUCCESS, listaPartidasBusqueda, listaPartidasBusquedaCantidad });
        }
        else{
            yield put({ type: informacionOperativaActions.LISTA_PARTIDAS_BUSQUEDA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.LISTA_PARTIDAS_BUSQUEDA_FAILURE });
    }
}
export function* listaPartidasBusquedaSaga() {
    yield takeLatest(informacionOperativaActions.LISTA_PARTIDAS_BUSQUEDA_REQUEST, listaPartidasBusqueda);
}

//********************** Cambiar el estado de la partida Activo/inactivo *****************************
function* cambiarEstadoPartida(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idPartida = action.idPartida;

        const response = yield call(estadoPartidaCall, token, idPartida);
        console.log(response);

        if(response.status==200){
            const showSuccessMsg = true;
            const textMessage = "El estado de la partida cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: informacionOperativaActions.CAMBIAR_ESTADO_PARTIDA_SUCCESS });
            yield put({ type: informacionOperativaActions.LISTA_PARTIDAS_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: informacionOperativaActions.CAMBIAR_ESTADO_PARTIDA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.CAMBIAR_ESTADO_PARTIDA_FAILURE });
    }
}
export function* cambiarEstadoPartidaSaga() {
    yield takeLatest(informacionOperativaActions.CAMBIAR_ESTADO_PARTIDA_REQUEST, cambiarEstadoPartida);
}

//********************** Crear nueva Partida *****************************
function* crearPartida(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idContrato = null;
        let observaciones = '';
        if(action.formulario.idContrato){
            for(let i in action.listaContratosPorServicio){
                if(action.formulario.idContrato === action.listaContratosPorServicio[i].clave + ' - ' + action.listaContratosPorServicio[i].descripcion){
                    idContrato = action.listaContratosPorServicio[i].idContrato;
                }
            }
        }
        if(action.formulario.observaciones){
            observaciones = action.formulario.observaciones
        }
        const data = {
            "idPartida": null,
            "nombre": action.formulario.nombre,
            "idServicio": action.formulario.idServicio.split(' - ')[0],
            "idContrato": idContrato,
            "nomenclatura": action.formulario.nomenclatura,
            "observaciones": observaciones,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }
        console.log(data);


        const response = yield call(crearPartidaCall, token, data);
        console.log(response);

        if(response.status==200){
            const showSuccessMsg = true;
            const textMessage = "La partida " + action.formulario.nombre + " se creó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearPartida' });
            yield put({ type: informacionOperativaActions.NUEVA_PARTIDA_SUCCESS });
            
            yield put({ type: informacionOperativaActions.SHOW_MODAL_NUEVA_PARTIDA, showModalNuevaPartida: false, page: action.page, busqueda: action.busqueda });
            yield put({ type: informacionOperativaActions.LISTA_PARTIDAS_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda })
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: informacionOperativaActions.NUEVA_PARTIDA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.NUEVA_PARTIDA_FAILURE });
    }
}
export function* crearPartidaSaga() {
    yield takeLatest(informacionOperativaActions.NUEVA_PARTIDA_REQUEST, crearPartida);
}

//********************** Editar nueva Partida *****************************
function* editarPartida(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idPartida = action.partidaSeleccionada.idPartida;
        let idContrato = null;
        let observaciones = '';
        if(action.formulario.contratoForm){
            for(let i in action.listaContratosPorServicio){
                if(action.formulario.contratoForm === action.listaContratosPorServicio[i].clave + ' - ' + action.listaContratosPorServicio[i].descripcion){
                    idContrato = action.listaContratosPorServicio[i].idContrato;
                }
            }
        }
        if(action.formulario.observaciones){
            observaciones = action.formulario.observaciones
        }
        const data = {
            "idPartida": idPartida,
            "nombre": action.formulario.nombre,
            "idServicio": action.formulario.servicioForm.split(' - ')[0],
            "idContrato": idContrato,
            "nomenclatura": action.formulario.nomenclatura,
            "observaciones": observaciones,
            "fechaAlta": action.partidaSeleccionada.fechaAlta,
            "fechaActualizacion": action.partidaSeleccionada.fechaActualizacion,
            "activo": action.partidaSeleccionada.activo,
        }
        console.log(data);


        const response = yield call(editarPartidaCall, token, data, idPartida);
        console.log(response);

        if(response.status==200){
            const showSuccessMsg = true;
            const textMessage = "La partida " + action.formulario.nombre + " se editó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarPartida' });
            yield put({ type: informacionOperativaActions.EDITAR_PARTIDA_SUCCESS });
            
            yield put({ type: informacionOperativaActions.SHOW_MODAL_EDITAR_PARTIDA, showModalEditarPartida: false, page: action.page, busqueda: action.busqueda });
            yield put({ type: informacionOperativaActions.LISTA_PARTIDAS_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda })
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: informacionOperativaActions.EDITAR_PARTIDA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: informacionOperativaActions.EDITAR_PARTIDA_FAILURE });
    }
}
export function* editarPartidaSaga() {
    yield takeLatest(informacionOperativaActions.EDITAR_PARTIDA_REQUEST, editarPartida);
}