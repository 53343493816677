import React, {Component} from 'react'
import { Modal } from 'antd';
import { connect } from "react-redux";
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import OpcionesFolioRequisicion from './OpcionesFolioRequisicion';


class ContenedorPuntoReorden extends Component {
    render() { 
        const {onShowModalRequisicionPuntoReorden, onCambiarVista,  vistaPuntoReorden, showModalRequisicionPuntoReorden,
            folioSeleccionado,
        } = this.props;
        
        const handleCancel = () => {
            onShowModalRequisicionPuntoReorden();
            onCambiarVista(1)
        }

        function ContenidoRequisicion() {
            if(vistaPuntoReorden === 1){
                return(
                    <OpcionesFolioRequisicion/>
                );
            }
            else if(vistaPuntoReorden === 2){
                return(
                    <div>Segunda Vista</div>
                );
            }
        };
        
        return ( 
            <Modal
               visible={showModalRequisicionPuntoReorden}
               title={folioSeleccionado?.folioRequisicionMateriales ? 'Requisición Solicitud de Materiales' :'Requisición Punto de Reorden'}
               onCancel={handleCancel}
               footer={false}
               width={'60%'}
            >
                <ContenidoRequisicion />
            </Modal>
         );
    }
}
 
const mapStateToProps = state => {
	return {
        showModalRequisicionPuntoReorden: state.AlmacenesReducer.showModalRequisicionPuntoReorden,
        vistaPuntoReorden: state.AlmacenesReducer.vistaPuntoReorden,
        folioSeleccionado: state.AlmacenesReducer.folioSeleccionado,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalRequisicionPuntoReorden: () => {
            dispatch({ type: "SHOW_MODAL_REQUISICION_PUNTO_REORDEN", showModalRequisicionPuntoReorden: false})
        },
        onCambiarVista: (vistaPuntoReorden) => {
            dispatch({ type: "CAMBIAR_VISTA_PUNTO_REORDEN", vistaPuntoReorden});
        }   
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContenedorPuntoReorden);