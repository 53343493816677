import React from 'react';
import { Row, Col, Select, Card, Tooltip, Tag, Spin, message } from 'antd';
import { connect } from "react-redux";
import moment from 'moment';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import '../../../stylesheets/RecursosHumanos/AltaPersonal.css';
import ModalCalendarioDetalle from './ModalCalendarioDetalle';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import ModalReprogramarDragDrop from './ModalReprogramarDragDrop';


const { Option } = Select;

class CalendarioProgramaciónContainer extends React.Component {
    componentDidMount(){
        this.props.onGetGruposServicios();
        this.props.onGetAreasFuncionales();
    }
    
    state={
        busqueda: {
            idGrupoSer: '',
            idServicio: '',
            mesVista: '',
            tipoPrograma: '',
            idAreaFuncional: '',
            idPartida: '',
        },
        servicio: undefined,
        partida: undefined,
    }
    componentWillUnmount(){
        this.props.limpiarCalendario()
    }
    render() {
        const { fetchingCalendarioProgramacion, fetchingListaGruposServicios,
            listaGruposServicios, onGetServiciosByGrupoServicio, onCalendarioProgramacion, listaServiciosByGrupoServicio,
            fetchingListaServiciosByGrupoServicio, onCalendarioDetalle, onShowModalCalendarioDetalle, listaCalendarioInicial,
        } = this.props;
        
        const grupoServicioSelected = (idGrupoSer) => {
            let busqueda = {
                idGrupoSer: idGrupoSer.split(' - ')[0],
                idServicio: undefined,
                mesVista: this.state.busqueda.mesVista,
                tipoPrograma: this.state.busqueda.tipoPrograma,
                idAreaFuncional: this.state.busqueda.idAreaFuncional,
                idPartida: undefined
            }
            this.setState({
                busqueda: busqueda,
                servicio: undefined,
                partida: undefined,
            })
            console.log(busqueda);
            onGetServiciosByGrupoServicio(idGrupoSer.split(' - ')[0]);
            onCalendarioProgramacion(busqueda);
            this.props.onCleanPartidasByIdServicio();
        }
        const servicioSelected = (idServicio) => {
            this.props.onCleanPartidasByIdServicio();
            if(idServicio){
                let busqueda = {
                    idGrupoSer: this.state.busqueda.idGrupoSer,
                    idServicio: idServicio.split(' - ')[0],
                    mesVista: this.state.busqueda.mesVista,
                    tipoPrograma: this.state.busqueda.tipoPrograma,
                    idAreaFuncional: this.state.busqueda.idAreaFuncional,
                    idPartida: undefined
                }
                this.setState({
                    busqueda: busqueda,
                    servicio: idServicio,
                    partida: undefined,
                })
                console.log(busqueda);
                onCalendarioProgramacion(busqueda);
                this.props.onGetPartidasByIdServicio(idServicio.split(' - ')[0]);
            }
            else{
                let busqueda = {
                    idGrupoSer: this.state.busqueda.idGrupoSer,
                    idServicio: '',
                    mesVista: this.state.busqueda.mesVista,
                    tipoPrograma: this.state.busqueda.tipoPrograma,
                    idAreaFuncional: this.state.busqueda.idAreaFuncional,
                    idPartida: undefined
                }
                this.setState({
                    busqueda: busqueda,
                    servicio: undefined,
                    partida: undefined,
                })
                console.log(busqueda);
                onCalendarioProgramacion(busqueda);
            }
        };

        const partidaSelected = (partida) => {
            if(partida){
                const idPartida = partida.split(' - ')[0];
                let busqueda = {
                    idGrupoSer: this.state.busqueda.idGrupoSer,
                    idServicio: this.state.busqueda.idServicio,
                    mesVista: this.state.busqueda.mesVista,
                    tipoPrograma: this.state.busqueda.tipoPrograma,
                    idAreaFuncional: this.state.busqueda.idAreaFuncional,
                    idPartida: idPartida
                }
                this.setState({
                    busqueda: busqueda,
                    partida: partida,
                });
                console.log(busqueda);
                onCalendarioProgramacion(busqueda);
            }else {
                let busqueda = {
                    idGrupoSer: this.state.busqueda.idGrupoSer,
                    idServicio: this.state.busqueda.idServicio,
                    mesVista: this.state.busqueda.mesVista,
                    tipoPrograma: this.state.busqueda.tipoPrograma,
                    idAreaFuncional: this.state.busqueda.idAreaFuncional,
                    idPartida: ''
                }
                this.setState({
                    busqueda: busqueda,
                });
                console.log(busqueda);
                onCalendarioProgramacion(busqueda);
            }
        };

        const tipoProgramaSelected = (tipoPrograma) => {
            let busqueda = {
                idGrupoSer: this.state.busqueda.idGrupoSer,
                idServicio: this.state.busqueda.idServicio,
                mesVista: this.state.busqueda.mesVista,
                tipoPrograma: tipoPrograma,
                idAreaFuncional: this.state.busqueda.idAreaFuncional,
                idPartida: this.state.idPartida
            }
            this.setState({
                busqueda: busqueda,
            })
            console.log(busqueda);
            onCalendarioProgramacion(busqueda);
        }

        const areaSelected = (idAreaFuncional) => {
            let busqueda = {
                idGrupoSer: this.state.busqueda.idGrupoSer,
                idServicio: this.state.busqueda.idServicio,
                mesVista: this.state.busqueda.mesVista,
                tipoPrograma: this.state.busqueda.tipoPrograma,
                idAreaFuncional: idAreaFuncional,
                idPartida: this.state.idPartida
            }
            this.setState({
                busqueda: busqueda,
            })
            console.log(busqueda);
            onCalendarioProgramacion(busqueda);
        }

        const tagSeleccionado = (item) => {
            item.fechaModal = moment(item.calendarioProgramacionQueryResponse[0].fechaRutina).format('LLLL').split(' 0:00')[0].charAt(0).toUpperCase() + moment(item.calendarioProgramacionQueryResponse[0].fechaRutina).format('LLLL').split(' 0:00')[0].slice(1);
            console.log('item', item, this.state.busqueda);
            onCalendarioDetalle(item, this.state.busqueda);
            onShowModalCalendarioDetalle(item, this.state.busqueda)
        }
        
        const renderEventContent = (item) => {
            let rutina = {};
            for(let i in listaCalendarioInicial){
                if(item.event.extendedProps.fecha === listaCalendarioInicial[i].fecha){
                    if(listaCalendarioInicial[i].idRutina === item.event.extendedProps.idRutina){
                        rutina = listaCalendarioInicial[i];
                        rutina.color = listaCalendarioInicial[i].color
                    }
                }
            }
            
            if(item && rutina){
                return (
                <Tag onClick={() => tagSeleccionado(rutina)} color={item.backgroundColor} className='tag-rad' style={{ width:'90%', textAlign:'center' }}><Tooltip placement="topLeft" title={item.event.title}>{item.event.title}</Tooltip></Tag>
                )
            }
        }
        const cambioEvento = (value) => {
            console.log('value evento', value.event._instance.range.start, moment())
            if(value.event._instance.range.start < moment()){
                value.revert();
                message.warning('No es posible seleccionar una fecha menor a la actual')
                return;
            }
            console.log('value', value.oldEvent.toJSON(), value.event.toJSON());
            if(value.event.toJSON().start.split('T')[0].split('-')[0] > value.oldEvent.toJSON().start.split('T')[0].split('-')[0]){
                value.revert();
                message.warning('No es posible seleccionar año diferente al actual')
                return;
            }
            reprogramarDragDrop(value.oldEvent.toJSON(), value.event.toJSON())
        }
        const cambioMes = (value) => {
            console.log('value mes', value)
            const fechaActual = moment(value.startStr.split('T')[0])
            let busqueda = {
                idGrupoSer: this.state.busqueda.idGrupoSer,
                idServicio: this.state.busqueda.idServicio,
                mesVista: fechaActual,
                tipoPrograma: this.state.busqueda.tipoPrograma,
                idPartida: this.state.idPartida
            }
            this.setState({
                busqueda: busqueda,
            })
            console.log(busqueda);
            if(this.state.busqueda.idGrupoSer){
                console.log('Entró a if de cambioMes')
                onCalendarioProgramacion(busqueda);
            }
        }

        const reprogramarDragDrop = (x, y) => {
            let anterior = {
                calendarioProgramacionQueryResponse: x.extendedProps.calendarioProgramacionQueryResponse,
                fecha: x.extendedProps.fecha,
                idRutina: x.extendedProps.idRutina,
                rutina: x.extendedProps.rutina,
                fechaModal: '',
                fechaDragDrop: x.start.split('T')[0],
            }
            let nuevo = {
                calendarioProgramacionQueryResponse: y.extendedProps.calendarioProgramacionQueryResponse,
                fecha: y.extendedProps.fecha,
                idRutina: y.extendedProps.idRutina,
                rutina: y.extendedProps.rutina,
                fechaModal: '',
                fechaDragDrop: y.start.split('T')[0],
            }
            anterior.fechaModal = moment(anterior.fechaDragDrop).format('LLLL').split(' 0:00')[0].charAt(0).toUpperCase() + moment(anterior.fechaDragDrop).format('LLLL').split(' 0:00')[0].slice(1);
            nuevo.fechaModal = moment(nuevo.fechaDragDrop).format('LLLL').split(' 0:00')[0].charAt(0).toUpperCase() + moment(nuevo.fechaDragDrop).format('LLLL').split(' 0:00')[0].slice(1);
            console.log('anterior y nuevo', anterior, nuevo, this.state.busqueda);
            onCalendarioDetalle(anterior, this.state.busqueda);
            this.props.onShowModalReprogramarDragDrop(anterior, nuevo, this.state.busqueda)
        }
        return (
            <Card className="cardCatalogos" title={<div className='headerLista'>Calendario de Programación</div>}>
                <Spin spinning={fetchingCalendarioProgramacion}>
                    <Row justify='space-between' gutter={[8,8]}>
                        <Col span={12}>
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Selecciona un grupo de servicio"
                                loading={fetchingListaGruposServicios}
                                onChange={grupoServicioSelected}
                                showSearch
                            >
                                {listaGruposServicios.map(option => <Option key={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}><Tooltip title={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}>{option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}</Tooltip></Option>)}
                            </Select>
                        </Col>
                        {this.state.busqueda.idGrupoSer ? (
                        <Col span={12}>
                            <Select allowClear
                                style={{ width: '100%' }}
                                placeholder="Selecciona un servicio"
                                loading={fetchingListaServiciosByGrupoServicio}
                                onChange={servicioSelected}
                                showSearch
                                value={this.state.servicio}
                            >
                                {listaServiciosByGrupoServicio.map(option => <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}><Tooltip title={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>{option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}</Tooltip></Option>)}
                            </Select>
                        </Col>
                        ):false}

                        { this.state.busqueda.idGrupoSer &&
                            <Col span={8}>
                                <Select
                                    allowClear
                                    showSearch={true}
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona una partidas"
                                    loading={this.props.fetchingListaPartidasByServicio4Fallas}
                                    disabled={!this.props.listaPartidasByServicio4Fallas[0]}
                                    onChange={partidaSelected}
                                    value={this.state.partida}
                                >
                                    {this.props.listaPartidasByServicio4Fallas.map(option =>
                                        <Option key={option.idPartida + ' - ' + option.nomenclatura  + ': ' + option.nombre}>
                                            {option.idPartida + ' - ' + option.nomenclatura  + ': ' + option.nombre}
                                        </Option>
                                    )}
                                </Select>
                            </Col>
                        }

                        {this.state.busqueda.idGrupoSer ? (
                        <Col span={8}>
                            <Select allowClear
                                style={{ width: '100%' }}
                                placeholder="Selecciona un tipo de programa"
                                onChange={tipoProgramaSelected}
                                showSearch
                            >
                                <Option key='Mantenimiento'>Mantenimiento</Option>
                                <Option key='Prueba'>Prueba</Option>
                                <Option key='Rutina'>Rutina</Option>
                            </Select>
                        </Col>
                        ):false}
                        {this.state.busqueda.idGrupoSer ? (
                        <Col span={8}>
                            <Select allowClear
                                style={{ width: '100%' }}
                                placeholder="Selecciona una área funcional"
                                loading={this.props.fetchingListaAreasFuncionalesActivas}
                                onChange={areaSelected}
                                showSearch
                            >
                                {this.props.listaAreasFuncionalesActivas.map(option => 
                                    <Option key={option.idAreaFuncional + ' - ' +  option.claveArea + ': ' + option.areaFuncional}>
                                        <Tooltip title={option.idAreaFuncional + ' - ' +  option.claveArea + ': ' + option.areaFuncional}> {option.idAreaFuncional + ' - ' +  option.claveArea + ': ' + option.areaFuncional} </Tooltip>
                                    </Option>
                                )}
                            </Select>
                        </Col>
                        ):false}
                        
                        <Col span={24}>
                            <br/>
                            <FullCalendar
                                plugins={[ dayGridPlugin, interactionPlugin ]}
                                initialView="dayGridMonth"
                                editable={true}
                                eventContent={renderEventContent}
                                events={listaCalendarioInicial}
                                locale={esLocale}
                                dayMaxEvents={true}
                                datesSet={cambioMes}
                                eventChange={cambioEvento}
                            />
                        </Col>
                    </Row>
                </Spin>
                <ModalCalendarioDetalle/>
                <ModalReprogramarDragDrop/>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaCalendarioProgramacion: state.ServiciosPlanificadosReducer.listaCalendarioProgramacion,
        fetchingCalendarioProgramacion: state.ServiciosPlanificadosReducer.fetchingCalendarioProgramacion,
        fetchingListaGruposServicios: state.CatalogosGeneralesReducer.fetchingListaGruposServicios,
        listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,
        fetchingListaServiciosByGrupoServicio: state.CatalogosGeneralesReducer.fetchingListaServiciosByGrupoServicio,
        listaServiciosByGrupoServicio: state.CatalogosGeneralesReducer.listaServiciosByGrupoServicio,
        listaCalendarioInicial: state.ServiciosPlanificadosReducer.listaCalendarioInicial,
        listaAreasFuncionalesActivas: state.AlmacenesReducer.listaAreasFuncionalesActivas,
        fetchingListaAreasFuncionalesActivas: state.AlmacenesReducer.fetchingListaAreasFuncionalesActivas,

        listaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.listaPartidasByServicio4Fallas,
        fetchingListaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.fetchingListaPartidasByServicio4Fallas,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetGruposServicios: () => {
	    	dispatch({ type: "LISTA_GRUPOS_SERVICIOS_REQUEST" });
        },
        onGetServiciosByGrupoServicio: (idGrupoSer) => {
            dispatch({ type: 'LISTA_SERVICIOS_BY_GRUPO_SERV_REQUEST', idGrupoSer });
        },
        onCalendarioProgramacion: (busqueda) => {
            dispatch({ type: 'CALENDARIO_PROGRAMACION_REQUEST', busqueda });
        },
        onCalendarioDetalle: (tareaSeleccionada, busqueda) => {
            dispatch({ type: 'CALENDARIO_DETALLE_REQUEST', tareaSeleccionada, busqueda })
        },
        onShowModalCalendarioDetalle: (tareaSeleccionada, busqueda) => {
            dispatch({ type: 'SHOW_MODAL_CALENDARIO_DETALLE', showModalCalendarioDetalle: true, tareaSeleccionada, busqueda })
        },
        onShowModalReprogramarDragDrop: (anteriorRMP, nuevoRMP, busqueda) => {
            dispatch({ type: 'SHOW_MODAL_REPROGRAMAR_DRAG_DROP', showModalReprogramarDragDrop: true, anteriorRMP, nuevoRMP, busqueda })
        },
        limpiarCalendario: () => {
            dispatch({ type: 'CALENDARIO_PROGRAMACION_SUCCESS', listaCalendarioProgramacion: [] })
        },
        onGetAreasFuncionales: () => {
            dispatch({ type: "LISTA_AREA_FUNCIONAL_ACTIVA_REQUEST", });
        },

       onGetPartidasByIdServicio: (idServicio) => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_REQUEST', idServicio})
        },
        onCleanPartidasByIdServicio: () => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_SUCCESS', listaPartidasByServicio4Fallas: []})
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CalendarioProgramaciónContainer);