import React, {Component} from 'react'
import { Form, Upload, Button, Col, Spin, Modal, Row, Input, message } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import { UploadOutlined } from '@ant-design/icons';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalEditarPlanCalidad extends Component {
    formRef = React.createRef();
    state = {
        filePDF: false,
        botonPDF: false,
        botonCrear: false,
    }
    render() { 
        const {
            onEditarPlanCalidad, onShowModalEditarPlanCalidad, showModalEditarPlanCalidad, listaPlanCalidadAños,
            fetchingEditarPlanCalidad, listaInfoProyecto, page, limpiarFormulario, busqueda, planCalidadSeleccionado,
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            if(planCalidadSeleccionado.version === formulario.version){
                message.error('Favor de cambiar la versión', 8);
            }
            else if(this.state.filePDF && planCalidadSeleccionado.version !== formulario.version){
                onEditarPlanCalidad(formulario, page, busqueda, listaInfoProyecto, listaPlanCalidadAños, planCalidadSeleccionado);
            }
            else{
                message.error('Favor de adjuntar un archivo PDF', 8);
            }
        };

        if (limpiarFormulario && limpiarFormulario === 'editarPlanCalidad') {
            this.setState({
                filePDF: false,
                botonPDF: false,
                botonCrear: false,
            });
            this.props.onLimpiarFormulario();
        }
        const handleCancel = () => {
            onShowModalEditarPlanCalidad(page, busqueda);
            this.formRef.current.resetFields();
            this.setState({
                filePDF: false,
                botonPDF: false,
                botonCrear: false,
            })
        }
        
        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else {
                this.setState({
                    botonPDF: true,
                    botonCrear: true,
                })
            }
            if (info.file.status === 'done') {
                var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,40}$");
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                    this.setState({
                        filePDF: false,
                        botonPDF: false,
                    });
                }
                else {
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonCrear: false,
                });
            } else if (info.file.status === 'error') {
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                    this.setState({
                        filePDF: false,
                        botonPDF: false,
                    });
                }
                else {
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonCrear: false,
                });
            }
        }

        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
            this.formRef.current.resetFields(['pdf']);
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };
        return (
            <Modal
                visible={showModalEditarPlanCalidad}
                title='Editar Plan de Calidad'
                onCancel={handleCancel}
                footer={false}
                width='50%'
            >
                <Spin spinning={fetchingEditarPlanCalidad}>
                    {planCalidadSeleccionado ? (
                    <Form {...layout}
                        name="formulario"
                        onFinish={onFinish}
                        ref={this.formRef}
                        initialValues={planCalidadSeleccionado}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Versión"
                                    name="version"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input
                                        pattern="^^[. 0-9]+$"
                                        title="Versión debe tener solo números."
                                        maxLength={10} placeholder='Ingresa la versión' 
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Adjuntar archivo"
                                    name="pdf"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                        {this.state.filePDF === false ? (
                                        <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                            <UploadOutlined /> Click para adjuntar archivo PDF
                                        </Button>
                                        ):false}
                                    </Upload>
                                </Form.Item>
                            </Col>

                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button disabled={this.state.botonCrear} className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Editar Plan de Calidad</Button>
                            </Col>
                        </Row>
                    </Form>
                    ):false}
                </Spin>
            </Modal>
        );
    }
}
 const mapStateToProps = state => {
     return {
        showModalEditarPlanCalidad: state.GestionDeCalidadReducer.showModalEditarPlanCalidad,
        fetchingEditarPlanCalidad: state.GestionDeCalidadReducer.fetchingEditarPlanCalidad,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        page: state.GestionDeCalidadReducer.page,
        busqueda: state.GestionDeCalidadReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaPlanCalidadAños: state.GestionDeCalidadReducer.listaPlanCalidadAños,
        planCalidadSeleccionado: state.GestionDeCalidadReducer.planCalidadSeleccionado,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarPlanCalidad: (page, busqueda) => {
            dispatch ({ type: 'MODAL_EDITAR_PLAN_CALIDAD', showModalEditarPlanCalidad: false, page, busqueda })
        },
        onEditarPlanCalidad: (formulario, page, busqueda, listaInfoProyecto, listaPlanCalidadAños, planCalidadSeleccionado) => {
            dispatch({ type: "EDITAR_PLAN_CALIDAD_REQUEST", formulario, page, busqueda, listaInfoProyecto, listaPlanCalidadAños, planCalidadSeleccionado })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarPlanCalidad);