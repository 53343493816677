import React from 'react';
import moment from 'moment';
import { Steps, message, Form, Row, Col, Button, Select, Spin, Divider, Input, Popover, Card, Table, Tooltip } from 'antd';
import { connect } from "react-redux";
import { ArrowRightOutlined, ArrowLeftOutlined, FileSearchOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import Identificacion from './Identificacion';
import Folio from './Folio';
import Detalle from './Detalle';

const { Option } = Select;
const { Step } = Steps;
const { TextArea } = Input;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };


class RecepcionCapturaContainer extends React.Component {
    formRef = React.createRef();

     componentDidMount() {
          this.props.onGetFolios(1);
          this.props.onGetFallas();
          this.props.onGetListaServiciosActivosProv();
          this.props.onObtenerServicios();
     }

     state = {
          page: 1,
          current: 0,
          hoy: new Date().getDate() + "-" + (new Date().getMonth()+ 1) + "-" + new Date().getFullYear(),
          ahora: '',
          ubicacion: '',
          seconds: 0,
          minutes: 0,
          disabledButton: false,
          duracion: '',
          color: '#1747ca',
          idServicio: null,
          idUbicacion: null,
          reiniciar: false,
          nombreServicio: 'Selecciona una falla',
     }


     render() {
          const { segundos, onGetTrazabilidadFolio, limpiarFormulario, onLimpiarFormulario, onShowModal,
               showModalIdentificacion, identificacion, onCrearSolicitud, onShowModalDetalle, listaFoliosSSCantidad,
               listaFoliosSS, fetchingListaFolioSS, onGetFolios,

               onUbicacionesSinHijosFiltroLimpiar, onUbicacionesSinHijosFiltro, ubicacionesSinHijos,
               fetchingUbicacionesSinHijos, onRegistrarTiempoLimite, listaFallasPorServicioLimpiar,
               listaFallasActivas, fetchingListaFallasActivas, listaServiciosActivosF, listaPartidasByFalla,
               fetchingListaPartidasByFalla, onGetPartidasByFalla, onCleanPartidasByFalla
          } = this.props;

          let userInfo = sessionStorage.getItem('userInfo');
          if(!userInfo.idUsuario){ userInfo = JSON.parse(userInfo); }

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
              permisosUsuario = JSON.parse(permisosUsuario);
          }

          const onFinish = formulario => {
               console.log('Success:', formulario);
          };

          if (limpiarFormulario && limpiarFormulario === 'crearAltaSS') {
               onLimpiarFormulario();
               onCleanPartidasByFalla();
          }

          

          const onShowIdentificacion = () => {
               if(!this.props.usuarioIdentificacion){
                    onUbicacionesSinHijosFiltroLimpiar();
                    this.formRef.current.resetFields();
               }
               onShowModal(true, false, false, this.props.usuarioIdentificacion);
               this.setState({ seconds: 0, minutes: 0, color: '#1747ca' });
          };

          const buscarUbicacionSinHijo = (value) => {
               if(value.length>=3){
                    if (value) {
                         console.log(value)
                         onUbicacionesSinHijosFiltro(value);
                    } else {
                         message.warning('No se encontró ubicación con esas palabras')
                    }
               }
               else{
                    onUbicacionesSinHijosFiltroLimpiar();
               }
          };

          const onSiguiente = () => {

               if(listaPartidasByFalla[0]){
                    console.log('Entra a condición CON partidas');
                    
                    if((this.formRef.current.getFieldValue('descripcion') === undefined || this.formRef.current.getFieldValue('descripcion') === '') || (this.formRef.current.getFieldValue('idFalla') === undefined || this.formRef.current.getFieldValue('idFalla') === null) ||  ((this.formRef.current.getFieldValue('idsPartidas') === undefined || this.formRef.current.getFieldValue('idsPartidas') === null))){
                         message.error('Favor de llenar los campos requeridos.');
                    } else {
                         this.setState({ current: 1 });
                         // let dia = new Date().getDate();
                         // if(dia < 10){ dia = '0' + dia }
                         // let mes = new Date().getMonth() + 1;
                         // if(mes < 10){ mes = '0' + mes }
                         // let fechaLocal = dia + '-' + mes + '-' + new Date().getFullYear()
                         // this.setState({ hoy: fechaLocal});
                    }

               } else {
                    console.log('Entra a condición SIN partidas');
                    if((this.formRef.current.getFieldValue('descripcion') === undefined || this.formRef.current.getFieldValue('descripcion') === '') || (this.formRef.current.getFieldValue('idFalla') === undefined || this.formRef.current.getFieldValue('idFalla') === null)){
                         message.error('Favor de llenar los campos requeridos.');
                    } else {
                         this.setState({ current: 1 });
                    }
               }
          };

          const onAnterior = () => { this.setState({ current: 0 }) };

          const onSiguienteConfirmar = () => { 
               if((this.formRef.current.getFieldValue('idUbicacion') === undefined || this.formRef.current.getFieldValue('idUbicacion') === '')){
                    message.error('Favor de ingresar una ubicación');
               } else {
                    console.log('Entró al else')
                    let currentHour = new Date();
                    let ahoraLocal = '';
                    let ubicacionLocal = '';

                    if(currentHour.getMinutes() < 10){
                         ahoraLocal = currentHour.getHours() + ':' + '0' + currentHour.getMinutes();
                    } else {
                         ahoraLocal = currentHour.getHours() + ':' + currentHour.getMinutes();
                    }

                    for(let i = 0; i < ubicacionesSinHijos.length; i ++){
                         console.log('ubicacionesSinHijos[i].idUbicacion', ubicacionesSinHijos[i].idUbicacion, 'parseInt(this.formRef.current.getFieldValue(idUbicacion))', parseInt(this.formRef.current.getFieldValue('idUbicacion')))
                         if(ubicacionesSinHijos[i].idUbicacion === parseInt(this.formRef.current.getFieldValue('idUbicacion'))){
                              ubicacionLocal = ubicacionesSinHijos[i].ubicacionCOA;
                         }
                    }

                    this.setState({ current: 2, ahora: ahoraLocal, ubicacion: ubicacionLocal });
               }
          };

          const onAnteriorConfirmar = () => {  this.setState({ current: 1 }) };

          const onConfirmarP3 = () => {
               onShowModal(false, true);
               this.setState({ disabledButton: true });

               let minutosLocal = '0' + Math.floor(this.state.seconds / 60)
               minutosLocal = minutosLocal.slice(-2);

               let segundosLocal = '0' + (this.state.seconds % 60)
               segundosLocal = segundosLocal.slice(-2);

               let tiempoLocal = minutosLocal + ':' + segundosLocal;
               tiempoLocal = '00:' + tiempoLocal;
               console.log('tiempoLocal', tiempoLocal);
               console.log('segundos', segundos)

               onCrearSolicitud(userInfo[0].idUsuario, parseInt(this.state.nombreServicio), parseInt(this.formRef.current.getFieldValue('idFalla')), parseInt(this.formRef.current.getFieldValue('idUbicacion')),this.formRef.current.getFieldValue('descripcion'), tiempoLocal, this.props.idUsuarioReporta, this.props.usuarioIdentificacion, this.props.areaPertenece, this.state.ahora, this.state.hoy, this.formRef.current.getFieldValue('idsPartidas'))

               onUbicacionesSinHijosFiltroLimpiar();
               listaFallasPorServicioLimpiar();
               this.formRef.current.resetFields();
               
               this.setState({
                    page: 1,
                    hoy: new Date().toLocaleDateString(),
                    disabledButton: false,
                    seconds: 0, 
                    minutes: 0, 
                    color: '#1747ca',
                    current: 0,
                    nombreServicio: 'Selecciona una falla',
               });
               startCrono();
          };

          // Cronómetro
          const getSecondsCrono = () => {
               let segundosLocal = '0' + (this.state.seconds % 60)
               segundosLocal = segundosLocal.slice(-2);
               return( segundosLocal )
          };

          const getMinutesCrono = () => {
               let minutosLocal = '0' + Math.floor(this.state.seconds / 60)
               minutosLocal = minutosLocal.slice(-2);
               return( minutosLocal )
          };

          const startCrono = () => {
               let _this = this;
               let startCronometro = setInterval( function () {
                    let idenLocal = _this.props.identificacion;
                    if(!idenLocal){
                         _this.setState({ seconds:  _this.state.seconds + 1 });
                         if(_this.state.seconds == 240){
                              _this.setState({color: '#fc4040'});
                              message.warning('El tiempo máximo para la captura es de 5 minutos.');
                         }
                         if(_this.state.seconds === 300){
                              onRegistrarTiempoLimite(_this.props.usuarioIdentificacion);
                              onShowModal(false, true, false, _this.props.usuarioIdentificacion);
                              onGetFolios(_this.state.page, null, null);
                              

                              _this.setState({ seconds: 0, minutes: 0, color: '#1747ca'});
                              clearInterval(startCronometro);
                         } 
                    } else { clearInterval(startCronometro);}
               }, 1000 );
          };

          console.log('identificacion', identificacion, this.state.seconds)
          if(this.state.seconds === 0 && identificacion === false){
               //if(showModalIdentificacion){
                    startCrono();
               //}
          }

          const columns = [
               {title: 'Folio', dataIndex: 'folio', key: 'folio'},
               {title: 'Servicio', dataIndex: 'servicio', key: 'servicio'},
               {title: 'Descripción', dataIndex: 'descripcionSolicitud', key: 'descripcionSolicitud'},
               {title: 'Ubicación', dataIndex: 'todoUbicacion', key: 'todoUbicacion'},
               {title: 'Estado', dataIndex: 'estado', key: 'estado'},
               {title: 'Detalle', key: 'detalle', align: 'center',
               render: (record) => (
                    <a className='editarIcono' onClick={() => onDetalle(record)}><FileSearchOutlined /></a>
               ),},
          ];

          const onDetalle = (key) => {
               console.log('key', key)
               onShowModalDetalle(key, this.state.page);
               //Enviar 1 en paginación fijo
               onGetTrazabilidadFolio(key.folio, 1)
          };

          const handleTableChange = (pagination) => {
               onGetFolios(pagination.current);
               this.setState({ page: pagination.current});
          };

          const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

          const onUbicacionSeleccionada = value => {
               console.log('value', value);
               onGetFolios(this.state.page, this.state.nombreServicio.split(' - ')[0], parseInt(value));
               this.setState({
                    idUbicacion: parseInt(value),
               })
          }

          if(this.props.sinIdentificar){
               this.setState({
                    page: 1,
                    hoy: new Date().toLocaleDateString(),
                    disabledButton: false,
                    seconds: 0, 
                    minutes: 0, 
                    color: '#1747ca',
                    current: 0,
               });
               startCrono();
               onShowModal(false, true, false);
               console.log('identificacion, showModalIdentificacion');
               console.log(identificacion, showModalIdentificacion);
          }

          const onFallaSeleccionada = (value) => {
               if(value){
                    this.formRef.current.resetFields(['idsPartidas']);
                    let idServ = ''
                    for(let i in listaFallasActivas){
                         if(parseInt(value) === listaFallasActivas[i].idFalla){
                              for(let j in listaServiciosActivosF){
                                   if(listaFallasActivas[i].idServicio === listaServiciosActivosF[j].idServicio){
                                        idServ = listaServiciosActivosF[j].idServicio + ' - ' + listaServiciosActivosF[j].nomenclatura + ' (' + listaServiciosActivosF[j].nombre + ')'
                                   }
                              } 
                              this.setState({ nombreServicio: idServ});
                         }
                    }
                    onGetFolios(this.state.page, idServ.split(' - ')[0], this.state.idUbicacion);
                    onGetPartidasByFalla(parseInt(value));
               } else {
                    this.formRef.current.resetFields(['idsPartidas']);
               }
          };

          return (
               <>
                    { identificacion ? (
                         <>
                              <Row justify='space-around'>
                                   <Col span={4}>
                                        <Button className='spanID' disabled={!permisosUsuario.identificarUsuarioParaSolicitudDeServicio} onClick={onShowIdentificacion}>
                                             <b>Identificar Usuario</b>
                                        </Button>
                                   </Col>
                              </Row>
                         </>
                    ): false }

                    <Spin indicator={antIcon} tip="Es necesario identificarse" spinning={identificacion}>
                         <Form {...layout}
                              name="formulario"
                              onFinish={onFinish}
                              
                              ref={this.formRef}
                         >
                         <Card
                              className="listaCatalogos"
                              bordered={true}
                              style={{align: 'center'}}
                              title={<div className="headerLista">Alta de Solicitud de Servicio</div>}
                         >

                              <Row justify='space-around' style={{ textAlign: 'left' }}>                         
                                   <Col span={20}>
                                        <Steps current={this.state.current}>
                                             <Step title="Detalle de la solicitud" />
                                             <Step title="Ubicación" />
                                             <Step title="Confirmar datos" />
                                        </Steps>
                                   </Col>

                                   <Col span={3} >
                                        <h3 style={{ textAlign:'center', color: this.state.color, backgroundColor: '#fff', borderRadius:'1em' }}>
                                             <div id="temporizador">{getMinutesCrono()} : {getSecondsCrono()}</div>
                                        </h3>
                                   </Col>

                                   { this.state.current === 0 ? (<> 
                                        <Divider orientation={'left'} style={{margin: '10px'}}>
                                             {'¿Cuál es la falla o solicitud que desea reportar?'}
                                        </Divider>
                                        <Col span={23}>
                                             <Form.Item
                                                  name='descripcion'
                                                  label='Descripción'
                                                  rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                             >
                                                  <TextArea autoSize maxLength={500} placeholder="Escribe una descripción de la falla o solicitud que deseas reportar"/>
                                             </Form.Item>
                                        </Col>

                                        <Col span={7}>
                                             <Form.Item
                                                  name='idFalla'
                                                  label='Incidencia'
                                                  rules={[{ required: true, message: 'Favor de seleccionar una opción'}]}
                                             >
                                                  <Select
                                                       showSearch
                                                       style={{ width: '100%' }}
                                                       placeholder="Selecciona una incidencia"
                                                       loading={fetchingListaFallasActivas}
                                                       //disabled={!listaFallasByServicio[0]}
                                                       onChange={onFallaSeleccionada}
                                                  >
                                                       {listaFallasActivas.map(option =>
                                                       <Option key={option.idFalla + ' - ' + option.nombre}>
                                                            <Tooltip title={option.idFalla + ' - ' + option.nombre}>
                                                                 {option.idFalla + ' - ' + option.nombre}
                                                            </Tooltip>
                                                       </Option>
                                                       )}
                                                  </Select>
                                             </Form.Item>
                                        </Col>

                                        <Col span={7}>
                                             <Form.Item
                                                  label="Servicio"
                                                  name="idServicio"
                                                  rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                             >
                                                  <Tooltip title={this.state.nombreServicio}>
                                                       <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Selecciona una emergencia"
                                                            disabled
                                                            value={this.state.nombreServicio}
                                                       />
                                                  </Tooltip>
                                             </Form.Item>
                                        </Col>

                                        <Col span={7}>
                                             <Form.Item
                                                  label='Partidas'
                                                  name='idsPartidas'
                                                  rules={[{ required: listaPartidasByFalla[0], message: 'Favor de llenar el campo', }]}
                                             >
                                                  <Select
                                                       showSearch
                                                       style={{ width: '100%' }}
                                                       placeholder="Selecciona una o varias partidas"
                                                       loading={fetchingListaPartidasByFalla}
                                                       disabled={!listaPartidasByFalla[0]}
                                                       //mode='multiple'
                                                  >

                                                       {listaPartidasByFalla.map(option => 
                                                       <Option key={option.idPartida + ' - ' + option.nomenclatura + ' ('+ option.nombre + ')'}>
                                                            <Popover content={option.idPartida + ' - ' + option.nomenclatura + ' ('+ option.nombre + ')'} title={false}>
                                                                 {option.idPartida + ' - ' + option.nomenclatura + ' ('+ option.nombre + ')'}
                                                            </Popover>
                                                       </Option>
                                                       )}
                                                  </Select>

                                             </Form.Item>
                                        </Col>

                                        <Col span={18}>
                                        </Col>

                                        <Col span={4}>
                                             <Button className='btnCargaArchivos' style={{ width: '100%' }} onClick={onSiguiente} >
                                                  Siguiente <ArrowRightOutlined />
                                             </Button>
                                        </Col>  
                                   </>): false }

                                   { this.state.current === 1 ? (<> 
                                        <Divider orientation={'left'} style={{margin: '10px'}}>
                                             {'¿Por favor, me indica la Ubicación donde se requiere la atención?'}
                                        </Divider>

                                        <Col span={23}>
                                             <Form.Item
                                                  label="Ubicación"
                                                  name="idUbicacion"
                                                  rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                             >
                                                  <Select
                                                       style={{ width: '100%' }}
                                                       placeholder="Escriba la ubicación a buscar"
                                                       loading={fetchingUbicacionesSinHijos}
                                                       showSearch
                                                       filterOption={false}
                                                       onSearch={buscarUbicacionSinHijo}
                                                       notFoundContent={null}
                                                       onChange={onUbicacionSeleccionada}
                                                  >
                                                       {ubicacionesSinHijos.map(option => 
                                                       <Option key={option.idUbicacion + ' - ' + option.ubicacionCOA}>
                                                            {option.idUbicacion + ' - ' + option.ubicacionCOA}
                                                       </Option>
                                                       )}
                                                  </Select>
                                             </Form.Item>
                                        </Col>

                                        <Col span={4}>
                                             <Button className='btnCargaArchivos' style={{ width: '100%' }} onClick={onAnterior}>
                                                  <ArrowLeftOutlined /> Anterior
                                             </Button>
                                        </Col>
                                        <Col span={13} />
                                        <Col span={4}>
                                             <Button className='btnCargaArchivos' style={{ width: '100%' }} onClick={onSiguienteConfirmar}>
                                                  Siguiente <ArrowRightOutlined />
                                             </Button>
                                        </Col>
                                        
                                   </>): false }

                                   { this.state.current === 2 ? (<> 
                                        
                                        <Col span={23}>
                                             <br/>
                                             <div> <h3>
                                                  { this.props.usuarioIdentificacion + ' le confirmo que el ' + this.state.hoy + ' a las ' + this.state.ahora + ' horas, usted reporta que: ' + this.formRef.current.getFieldValue('descripcion') + '. El incidente ocurre en: ' + this.formRef.current.getFieldValue('idUbicacion').split(' - ')[1] + ', ¿es correcto? '}
                                             </h3></div>
                                        </Col>

                                        <Col span={4}>
                                             <br/>
                                             <Button className='btnCargaArchivos' style={{ width: '100%' }} onClick={onAnteriorConfirmar} disabled={this.state.disabledButton}>
                                                  <ArrowLeftOutlined /> Anterior
                                             </Button>
                                        </Col>
                                        <Col span={13} />
                                        <Col span={4}>
                                             <br/>
                                             <Button className='btnPrueba' style={{ width: '100%' }} onClick={onConfirmarP3}>
                                                  Confirmar
                                             </Button>
                                        </Col>


                                   </>): false }
                              </Row>
                         </Card>
                         </Form>
                         <Identificacion/>
                         <Folio/>
                    </Spin>
                    {permisosUsuario.verMonitorDeIncidencias ? (
                    <Spin spinning={fetchingListaFolioSS}>
                         <Divider/>
                         <Card
                              className="listaCatalogos"
                              bordered={true}
                              style={{align: 'center'}}
                              title={<div className="headerLista">Monitor de Incidencias</div>}
                         >
                              <Row justify='space-around' style={{ textAlign: 'left' }}>                         
                                   <Col span={24}>
                                        <Table
                                             size="small" 
                                             rowKey="idSolicitudServicio" 
                                             columns={columns}
                                             dataSource={listaFoliosSS}
                                             loading={fetchingListaFolioSS}
                                             locale={{ emptyText: 'Sin datos' }}
                                             onChange={handleTableChange}
                                             pagination={{total: listaFoliosSSCantidad, simple: true, current: this.state.page}}
                                        />
                                   </Col>
                              </Row>
                         </Card>
                         <Detalle/>
                    </Spin>
                    ):false}
               </>
          );
     }
}

const mapStateToProps = state => {
     return {
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          identificacion: state.SolicitudDeServiciosReducer.identificacion,
          usuarioIdentificacion: state.SolicitudDeServiciosReducer.usuarioIdentificacion,
          areaPertenece: state.SolicitudDeServiciosReducer.areaPertenece,
          idUsuarioReporta: state.SolicitudDeServiciosReducer.idUsuarioReporta,
          showModalIdentificacion: state.SolicitudDeServiciosReducer.showModalIdentificacion,

          listaFoliosSS: state.SolicitudDeServiciosReducer.listaFoliosSS,
          listaFoliosSSCantidad: state.SolicitudDeServiciosReducer.listaFoliosSSCantidad,
          fetchingListaFolioSS: state.SolicitudDeServiciosReducer.fetchingListaFolioSS,

          segundos: state.SolicitudDeServiciosReducer.segundos,
          limpiarCrono: state.SolicitudDeServiciosReducer.limpiarCrono,

          fetchingListaFallasByServicio: state.SolicitudDeServiciosReducer.fetchingListaFallasByServicio,
          listaFallasByServicio: state.SolicitudDeServiciosReducer.listaFallasByServicio,

          ubicacionesSinHijos: state.SolicitudDeServiciosReducer.ubicacionesSinHijos,
          fetchingUbicacionesSinHijos: state.SolicitudDeServiciosReducer.fetchingUbicacionesSinHijos,

          fetchingListaServiciosActivosProveedor: state.SolicitudDeServiciosReducer.fetchingListaServiciosActivosProveedor,
          listaServiciosActivosProveedores: state.SolicitudDeServiciosReducer.listaServiciosActivosProveedores,
          listaProveedoresByServicio: state.SolicitudDeServiciosReducer.listaProveedoresByServicio,
          fetchingListaProveedoresByServicio: state.SolicitudDeServiciosReducer.fetchingListaProveedoresByServicio,

          sinIdentificar: state.SolicitudDeServiciosReducer.sinIdentificar,
          listaFallasActivas: state.SolicitudDeServiciosReducer.listaFallasActivas,
          fetchingListaFallasActivas: state.SolicitudDeServiciosReducer.fetchingListaFallasActivas,

          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          listaPartidasByFalla: state.SolicitudDeServiciosReducer.listaPartidasByFalla,
          fetchingListaPartidasByFalla: state.SolicitudDeServiciosReducer.fetchingListaPartidasByFalla,

     };
};
const mapDispatchToProps = dispatch => {
     return {
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false })
          },
          onShowModal: (showModalIdentificacion, identificacion, sinIdentificar, usuarioIdentificacion) => {
               dispatch({ type: 'SHOW_MODAL_IDENTIFICACION', showModalIdentificacion, usuarioIdentificacion, identificacion, sinIdentificar })
          },
          onUbicacionesSinHijosFiltro: (busqueda) => {
               dispatch({ type: 'UBICACIONES_SIN_HIJOS_REQUEST', busqueda });
          },
          onUbicacionesSinHijosFiltroLimpiar: () => {
               dispatch({ type: 'UBICACIONES_SIN_HIJOS_SUCCESS', ubicacionesSinHijos: [] });
          },
          onCrearSolicitud: (idUsuarioRegistra, idServicio, idFalla, idUbicacion, descripcionSolicitud, tiempoCaptura, idUsuarioReporta, usuarioIdentificacion, areaPertenece, horaCreacion,fechaCreacion, idsPartidas) => {
               dispatch({ type: 'CREAR_SOLICITUD_SERVICIO_REQUEST', idUsuarioRegistra, idServicio, idFalla, idUbicacion, descripcionSolicitud, tiempoCaptura, idUsuarioReporta, usuarioIdentificacion, areaPertenece, horaCreacion,fechaCreacion, idsPartidas });
          },
          onShowModalDetalle: (solicitudDeServicioSeleccionado, page) => {
               dispatch({ type: 'SHOW_MODAL_DETALLE_SS', showModalDetalleSS: true, solicitudDeServicioSeleccionado, page });
          },
          onGetFolios: (page, idServicio, idUbicacion) => {
               dispatch({ type: 'LISTA_FOLIOS_SS_REQUEST', page, idServicio, idUbicacion });
          },
          onGetFallas: () => {
               dispatch({ type: 'LISTA_FALLAS_ACTIVAS_REQUEST' });
          },
          onCronometro: (segundos) => {
               dispatch({ type: 'CRONOMETRO', segundos });
          },
          onRegistrarTiempoLimite: (usuario) => {
               dispatch({ type: 'REGISTRAR_TIEMPO_LIMITE_REQUEST', usuario });
          },
          onGetFallasByServicio: (idServicio) => {
               dispatch({ type: 'LISTA_FALLAS_ACTIVAS_BY_SERVICIO_REQUEST', idServicio });
          },
          onGetListaServiciosActivosProv: () => {
               dispatch({ type: 'LISTA_SERVICIOS_ACTIVOS_PROVEEDOR_REQUEST' });
          },
          listaFallasPorServicioLimpiar: () => {
               dispatch({ type: 'LISTA_FALLAS_ACTIVAS_BY_SERVICIO_SUCCESS', listaFallasByServicio: [] });
          },
          onGetProveedoresByServicio: (idServicio) => {
               dispatch({ type: 'LISTA_PROVEEDORES_BY_SERVICIO_ACTIVOS_REQUEST', idServicio })
          },
          onGetTrazabilidadFolio: (folio, page) => {
               dispatch({ type: "OBTENER_TRAZABILIDAD_FOLIO_REQUEST", folio, page});
          },
          onObtenerServicios: () => {
               dispatch({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST' });
          },
          onGetPartidasByFalla: (idFalla) => {
               dispatch({ type: 'LISTA_PARTIDAS_BY_FALLA_REQUEST', idFalla });
          },
          onCleanPartidasByFalla: () => {
               dispatch({ type: 'LISTA_PARTIDAS_BY_FALLA_SUCCESS', listaPartidasByFalla: [] });
          }
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecepcionCapturaContainer);
