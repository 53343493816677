import React, {useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {Row, Col, List, Tooltip, Pagination, Spin, Popover, Button} from 'antd';
import {InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css'


const ProcesamientoCargaMasiva = props => {
    const [page, setPage] = useState(1);
    const {onShowTablaErrores, onGetErroresPIH, listaArchivosEspera, onGetListArchivos, listInfoArchivosCargaMasiva, fetchingListCargaMasiva, listaArchivosEsperaCantidad} = props;

    useEffect(() => {
        onGetListArchivos(page)
    }, [page])

    const onChangePage = (value) =>{
        setPage(value)
        onGetListArchivos(value)
    }

    console.log("listInfoArchivosCargaMasiva",listInfoArchivosCargaMasiva)

    return (
        <Row justify='space-around'>
                <Col span={24}>
                    <Spin spinning={fetchingListCargaMasiva}>
                        <List
                            className="demo-loadmore-list"
                            /*loading={fetchingListIPH}*/
                            itemLayout="horizontal"
                            dataSource={listaArchivosEspera}
                            renderItem={item => (
                                <List.Item
                                    actions={[
                                        item.estado==="Finalizado" && <Tooltip placement="bottomRight" title="Ver errores del archivo"><Button onClick={() => {onShowTablaErrores(page); onGetErroresPIH(item.idArchivoMasivo, page)}} className="iconSecundary" shape='circle'><InfoCircleOutlined /></Button></Tooltip>,
                                        ]}
                                >
                                    <List.Item.Meta
                                        title={<strong>Titulo: {item.archivo}</strong>}
                                        description={<div>
                                            <p style={{marginBottom:0}}>Total de registros ingresados: <strong>{item.activosTotales}</strong></p>
                                            <p style={{marginBottom:0}}>Errores: <strong>{item.activosTotales-item.activosGuardados}</strong></p>
                                            <p >Registros subidos exitosamente: <strong>{item.activosGuardados}</strong></p>
                                            <p >Actualizado el {moment(item.fechaActualizacion).format('MMMM Do YYYY, h:mm:ss a')}</p>
                                        </div>}
                                    />
                                </List.Item>
                            )}
                        />
                        <br/>
                        <Pagination simple total={listaArchivosEsperaCantidad} current={page} onChange={onChangePage} /> 
                    </Spin>
                </Col>
        </Row>
    )
}

const mapStateToProps = state => {
     return {
        listaArchivosEspera: state.AlmacenesReducer.listaArchivosEspera,
        listaArchivosEsperaCantidad: state.AlmacenesReducer.listaArchivosEsperaCantidad,
        listInfoArchivosCargaMasiva: state.AlmacenesReducer.listInfoArchivosCargaMasiva,
        fetchingListCargaMasiva: state.AlmacenesReducer.fetchingListCargaMasiva,
        fetchingListaUsuariosActivos: state.AlmacenesReducer.fetchingListaUsuariosActivos,
        listaUsuariosActivos: state.AlmacenesReducer.listaUsuariosActivos,
        fetchingModalOpciones: state.AlmacenesReducer.fetchingModalOpciones,
     }
}

const mapDispatchToProps = dispatch => {
     return {
        onShowTablaErrores: (page) =>{
            dispatch({ type: "SHOW_MODAL_ERRORES_CARGA_PIH", showModalErroresPIH:true, page})
        },
        onGetErroresPIH: (idArchivoMasivo, page) =>{
            dispatch({ type: "ERRORES_CARGA_PIH_REQUEST", idArchivoMasivo, page})
        },
        onGetListArchivos: (page, estado) =>{
            dispatch({ type: "GET_ARCHIVOS_ESPERA_REQUEST", page, estado})
        },
        onChangeStatus:(estado, idArchivoMasivo, page) =>{
            dispatch({ type: "CHANGE_STATUS_IPH_REQUEST", estado, idArchivoMasivo, page})
        },
        onDescargarFolio: (movimientoSeleccionado, idUsuarioRecibe) => {
            dispatch({ type: "IMPRIMIR_FOLIO_REQUEST", movimientoSeleccionado, idUsuarioRecibe });
        },
        onVerificarSerieNum: (movimientoSeleccionado, idUsuarioRecibe) => {
            dispatch({ type: "VERIFICAR_SERIENUM_REQUEST", movimientoSeleccionado, idUsuarioRecibe });
        },
          
     }
}

export default connect(mapStateToProps, mapDispatchToProps) (ProcesamientoCargaMasiva);