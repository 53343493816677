import React, { Component } from 'react';
import { Row, Col, Modal, Table, Button , Divider, Spin, Tooltip, Descriptions, Typography, Input } from 'antd';
import { connect } from "react-redux";
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons';

const {Text} = Typography;
const { TextArea } = Input;
class ModalAprobarFolio extends Component {
    formRef = React.createRef();
    
    state = {
        page: 1,
        busqueda: {order: 'ASC', field: 'folio'},
        selectedRows: [],
        activarCancelar: false,
        nota: '',
    }
    render() { 
        const {
            requisicionSeleccionada, showModalAprobarRequisicion,
            onShowModalAprobarRequisicion, fetchingAprobarRequisicion
        } = this.props;
        
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        let userInfo = sessionStorage.getItem('userInfo');
        if(!userInfo.idUsuario){
            userInfo =JSON.parse(userInfo);
        }

        const handleCancel = () => {
            onShowModalAprobarRequisicion();
        };

        const aprobarRequisicion = () => {
            this.props.onAprobarRequisicion(requisicionSeleccionada, this.state.selectedRows, this.props.page, this.props.busqueda, this.props.orden, userInfo[0].idUsuario, permisosUsuario.especialAlmacén ? false : true);
        }

        const cancelarRequisicion = () => {
            this.props.onCerrarRequisicion(requisicionSeleccionada.idRequisicionMateriales, this.props.page, this.props.busqueda, this.props.orden, userInfo[0].idUsuario, this.state.nota);
        }

        const columns = [{
            title: 'Código de almacén', dataIndex: 'codigoAdicional', key: 'codigoAdicional', align: 'center',
        },{
            title: 'Nombre del Producto', dataIndex: 'nombre', key: 'nombre', align: 'center',
        },{
            title: 'Clave del producto', dataIndex: 'clave', key: 'clave', align: 'center',
        },{
            title: 'SKU', dataIndex: 'sku', key: 'sku', align: 'center',
        },{
            title: 'Precio Unitario', dataIndex: 'precioTabla', key: 'precioTabla', align: 'right', 
        },{
            title: 'Cantidad solicitada', dataIndex: 'cantidad', key: 'cantidad', align: 'center'
        }];

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                this.setState({
                    selectedRows
                })
            },
            getCheckboxProps: (record) => ({
                disabled: record.aprobar === true,
                // Column configuration not to be checked
                name: record.name,
            }),
        };

        const onNota = (value) => {
            console.log('value', value.target.value)
            if(value.target.value){
                this.setState({
                    activarCancelar: true,
                    nota: value.target.value,
                })
            }
            else{
                this.setState({
                    activarCancelar: false,
                    nota: '',
                })
            }
        }
          
        return (
            <div>
                <Modal
                    visible={showModalAprobarRequisicion}
                    title='Aprobar Solicitud'
                    onCancel={handleCancel}
                    footer={false}
                    width={'70%'}
                >
                    <Spin spinning={fetchingAprobarRequisicion || this.props.fetchingCambiarEstadoRequisicion}>
                    {requisicionSeleccionada ? (
                        <Row type='flex' justify='space-between'>
                            <Col span={22}>
                                <Divider orientation="left">Solicitud Seleccionada</Divider>
                            </Col>

                            <Col span={24}>
                                <Descriptions layout="horizontal" bordered={true}>
                                    <Descriptions.Item label='Número de Folio'> <Text type="warning" strong> {requisicionSeleccionada.folio} </Text> </Descriptions.Item>
                                    <Descriptions.Item label='Estado'>{requisicionSeleccionada.estado}</Descriptions.Item>
                                    <Descriptions.Item label='Fecha alta'>{requisicionSeleccionada.fechaAlta}</Descriptions.Item>
                                    <Descriptions.Item label='Usuario solicitante'>{requisicionSeleccionada.idUsuarioSolicitante}</Descriptions.Item>
                                </Descriptions>
                            </Col>

                            <Col span={24}>
                                <br />
                                <Table
                                    size="small"
                                    rowKey="idRequisicionMaterialesProductos"
                                    columns={columns}
                                    dataSource={requisicionSeleccionada.productosByIdRequisicion}
                                    locale={{ emptyText: 'Sin datos'}}
                                    scroll={{"x": 1000 | true, "y": 1000}}
                                    rowSelection={rowSelection}
                                />
                            </Col>
                            
                            <Col span={13}>
                                <Divider orientation='left'>Acciones</Divider>
                            </Col>
                            <Col span={8} style={{ paddingTop:'1em' }}>
                                <TextArea
                                    maxLength={500}
                                    placeholder='Nota de por qué se cierra la solicitud de materiales'
                                    autoSize 
                                    onChange={onNota}
                                />
                            </Col>
                            <Col span={1} style={{ paddingTop:'1em' }}>
                                <Tooltip title='Cerrar Folio'>
                                    <Button className="iconTer" shape="circle" icon={<StopOutlined />} disabled={!this.state.activarCancelar} onClick={cancelarRequisicion}/>
                                </Tooltip>
                            </Col>

                            <Col span={1} style={{ paddingTop:'1em' }}>
                                <Tooltip title='Aprobar Productos'>
                                    <Button className="iconTer" shape="circle" disabled={this.state.selectedRows.length === 0} icon={<CheckCircleOutlined />} onClick={aprobarRequisicion}/>
                                </Tooltip>
                            </Col>
                        </Row>
                        )  : false}
                    </Spin> 
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        requisicionSeleccionada: state.AlmacenesReducer.requisicionSeleccionada,
        showModalAprobarRequisicion: state.AlmacenesReducer.showModalAprobarRequisicion,
        fetchingAprobarRequisicion: state.AlmacenesReducer.fetchingAprobarRequisicion,
        page: state.AlmacenesReducer.page,
        busqueda: state.AlmacenesReducer.busqueda,
        orden: state.AlmacenesReducer.orden,
        fetchingCambiarEstadoRequisicion: state.AlmacenesReducer.fetchingCambiarEstadoRequisicion,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onShowModalAprobarRequisicion: () => {
            dispatch({ type: "SHOW_MODAL_APROBAR_REQUISICION", showModalAprobarRequisicion: false, requisicionSeleccionada: null })
        },
        onAprobarRequisicion: (requisicionSeleccionada, selectedRows, page, busqueda, orden, idUsuario, responsable) => {
            dispatch({ type: "APROBAR_REQUISICION_REQUEST", requisicionSeleccionada, selectedRows, page, busqueda, orden, idUsuario, responsable })
        },
        onCerrarRequisicion: (idRequisicionMateriales, page, busqueda, orden, idUsuario, nota) => {
            dispatch({ type: 'CAMBIAR_ESTADO_REQUISICION_REQUEST', idRequisicionMateriales, idEstado: 6, page, busqueda, orden, idUsuario, nota })
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ModalAprobarFolio);