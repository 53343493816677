import axios from "axios";

//const endpoint = "https://papantla-services.herokuapp.com/";
//const endpoint = "https://papantla-dev.herokuapp.com/";
const endpoint = 'https://morelos-dev.herokuapp.com/';
//const endpoint = "https://papantla-demo.herokuapp.com/";

//const endpoint = "http://172.24.0.170:8080/"

//const endpoint = 'https://papantla-services-jose.herokuapp.com/';
//const endpoint = 'https://papantla-dev-jose.herokuapp.com/';

//const endpoint = 'https://papantla-secret-jose.herokuapp.com/';
//const endpoint = 'https://papantla-secret.herokuapp.com/';

export function listaUsuariosParamCall(token, busqueda) {
	return axios({
	    method: "get",
	    url: endpoint + "usuarios/activos/by?param=" + busqueda,
	    headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaUsuariosSinFiltroCall(token, page) {
	return axios({
	    method: "get",
	    url: endpoint + "usuarios?page=" + page + "&offset=10",
	    headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function cambiarEstadoUsuarioCall(token, idUsuario) {
	return axios({
	    method: "get",
	    url: endpoint + "usuarios/activa/" + idUsuario,
	    headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaDominiosActivosCall(token) {
	return axios({
	    method: "get",
	    url: endpoint + "dominios/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function listaRolesCall(token) {
	return axios({
	    method: "get",
	    url: endpoint + "roles/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function verificarUsuarioCall(token, username) {
	return axios({
	    method: "get",
	    url: endpoint + "usuarios/verificar/" + username,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function proveedorPorDominioCall(token, idDominio) {
	return axios({
	    method: "get",
	    url: endpoint + "proveedores/dominio/" + idDominio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function recuperarPinCall(token, idUsuario) {
	return axios({
	    method: "get",
	    url: endpoint + "usuarios/restablecer/pin?idUsuario=" + idUsuario,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function cambiarContraseñaCall(token, idUsuario, data) {
	return axios({
	    method: "post",
	    url: endpoint + "usuarios/updatePassword?idUsuario=" + idUsuario,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function nuevoUsuarioCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "usuarios",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarUsuarioCall(token, idUsuario, data) {
	return axios({
	    method: "put",
	    url: endpoint + "usuarios/" + idUsuario,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function filtroDominioCall(token, dominio, page) {
	return axios({
	    method: "get",
	    url: endpoint + "usuarios?dominio=" + dominio + "&page="+ page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function filtroServicioCall(token, idsServicios, page) {
	return axios({
	    method: "get",
	    url: endpoint + "usuarios?idsServicios=" + idsServicios + "&page="+ page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function filtroDominioServicioCall(token, dominio, idsServicios, page) {
	return axios({
	    method: "get",
	    url: endpoint + "usuarios?" + dominio + idsServicios + "&page="+ page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getModulosCall(token, page) {
	return axios({
	    method: "get",
	    url: endpoint + "settings/modulos?page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getListaModulosCall(token) {
	return axios({
	    method: "get",
	    url: endpoint + "settings/modulos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getModulosBusquedaCall(token, page, busqueda) {
	return axios({
	    method: "get",
	    url: endpoint + "settings/modulos?param=" + busqueda + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function cambiarEstadoModuloCall(token, idModulo) {
	return axios({
	    method: "get",
	    url: endpoint + "settings/modulos/activa/" + idModulo,
	    headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function nuevoEstadoModuloCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "settings/modulos" ,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaModulosByIdUsuarioCall(token, idUsuario) {
	return axios({
	    method: "get",
	    url: endpoint + "settings/modulos/byUsuario?idUsuario=" + idUsuario,
	    headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
// Servicios de Seguridad
export function getPermisosCall(token) {
	return axios({
	    method: "get",
	    url: endpoint + "roles/permisos/activos",
	    headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function getPermisosByModuloCall(token, idModulo) {
	return axios({
	    method: "get",
	    url: endpoint + "roles/permisos/by/" + idModulo,
	    headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getPermisosBusquedaCall(token, page, busqueda) {
	return axios({
	    method: "get",
	    url: endpoint + "roles/permisos?" + busqueda + "page=" + page + "&offset=10",
	    headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function getLogsCall(token, page, usuario, modulo, fecha, campo, orden) {
	return axios({
	    method: "get",
	    url: endpoint + "settings/bitacora?" + usuario + modulo + fecha + campo + orden + 'page=' + page + '&offset=10',
	    headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Servicios de Roles para catálogo
export function getRolesBusquedaCall(token, page, busqueda) {
	return axios({
	    method: "get",
	    url: endpoint + "roles?" + busqueda + "page=" + page + "&offset=10",
	    headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function cambiarEstadoRolCall(token, idRol) {
	return axios({
	    method: "get",
	    url: endpoint + "roles/activa/" + idRol,
	    headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function crearRolCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "roles",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function editarRolCall(token, data, idRol) {
	return axios({
	    method: "put",
	    url: endpoint + "roles/" + idRol,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function serviciosPorProveedorCall(token, idProveedor) {
	return axios({
	    method: "get",
	    url: endpoint + "servicios/proveedor/" + idProveedor,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Services settigns
export function obtenerSettings(token) {
	return axios({
	    method: "get",
	    url: endpoint + "settings",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarSettingsCall(token, data, idSetting) {
	return axios({
	    method: "put",
	    url: endpoint + "settings/" + idSetting,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Servicios de Alertas
export function listaAlertasBusquedaCall(token, servicio, modulo, evento, page) {
	return axios({
		method: "get",
		url: endpoint + "alertas?" + servicio + modulo + evento + "page=" + page + "&offset=10",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function cambiarEstadoAlertaCall(token, idAlerta) {
	return axios({
	    method: "get",
	    url: endpoint + "alertas/activa/" + idAlerta,
	    headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaDiasAlertaCall(token) {
	return axios({
	    method: "get",
	    url: endpoint + "alertas/dias",
	    headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function listaEventosPorModuloCall(token, idModulo) {
	return axios({
	    method: "get",
	    url: endpoint + "alertas/eventos/" + idModulo,
	    headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function nuevaAlertaCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "alertas",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarAlertaCall(token, data, idAlerta) {
	return axios({
	    method: "put",
	    url: endpoint + "alertas/" + idAlerta,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Tipos de Bitacoras
export function listaTiposBitacoraBusquedaCall(token, param, page) {
	return axios({
		method: "get",
		url: endpoint + "bitacora/tipo?" + param + "page=" + page + "&offset=10",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaTiposBitacoraActivosCall(token, idServicio) {
	return axios({
		method: "get",
		url: endpoint + "bitacora/tipo/activos?" + idServicio,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function cambiarEstadoTipoBitacoraCall(token, idTipoBitacora) {
	return axios({
	    method: "get",
	    url: endpoint + "bitacora/tipo/activa/" + idTipoBitacora,
	    headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function nuevoTipoBitacoraCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "bitacora/tipo",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarTipoBitacoraCall(token, data, idTipoBitacora) {
	return axios({
	    method: "put",
	    url: endpoint + "bitacora/tipo/" + idTipoBitacora,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaTiposBitacoraByIdModuloCall(token, idsModulos) {
	return axios({
	    method: "get",
	    url: endpoint + "bitacora/tipos/byModulos/" + idsModulos,
	    headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Catálogo de Bitacoras
export function listaBitacorasBusquedaCall(token, param, idServicios, fecha, page, idModulo) {
	return axios({
		method: "get",
		url: endpoint + "bitacora?" + param + idServicios + fecha + idModulo + "page=" + page + "&offset=10",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function cambiarEstadoBitacoraCall(token, idBitacora) {
	return axios({
	    method: "get",
	    url: endpoint + "bitacora/activa/" + idBitacora,
	    headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function nuevaBitacoraCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "bitacora",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarBitacoraCall(token, idBitacora, data) {
	return axios({
	    method: "put",
	    url: endpoint + "bitacora/" + idBitacora,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Módulos Bitácoras
export function listaModulosBitacoraCall(token,  data) {
	return axios({
		method: "post",
		url: endpoint + "bitacora/modulos/obtener",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function nuevoModuloBitacoraCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "bitacora/modulos",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaModulosBitacoraByIdModuloCall(token, idModulo) {
	return axios({
	    method: "get",
	    url: endpoint + "bitacora/modulos/byModulo?idModulo=" + idModulo,
	    headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Reporte Dinámico
export function listaPartidasByIdsServiciosCall(token, data) {
	return axios({
		method: "post",
	    url: endpoint + "partidas/activas/servicios",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function reporteDinamicoBitacoraCall(token, data) {
	return axios({
		method: "post",
	    url: endpoint + "bitacora/reporte/dinamico",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function reporteDinamicoBitacoraPDFCall(token, data, titulo) {
	return axios({
		method: "post",
		responseType: 'blob',
	    	url: endpoint + "bitacora/reporte/dinamico/pdf?titulo=" + titulo,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function reporteDinamicoBitacoraExcelCall(token, data, titulo) {
	return axios({
		method: "post",
		responseType: 'blob',
	    	url: endpoint + "bitacora/reporte/dinamico/excel",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function reporteDinamicoBitacoraAgrupadoCall(token, data) {
	return axios({
		method: "post",
	    url: endpoint + "bitacora/reporte/dinamico/agrupado",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function reporteDinamicoBitacoraAgrupadoPDFCall(token, data, titulo) {
	return axios({
		method: "post",
		responseType: 'blob',
	    	url: endpoint + "bitacora/reporte/dinamico/agrupado/pdf?titulo=" + titulo,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function reporteDinamicoBitacoraAgrupadoExcelCall(token, data, titulo) {
	return axios({
		method: "post",
		responseType: 'blob',
	    	url: endpoint + "bitacora/reporte/dinamico/agrupado/excel",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getAllRegistrosBitacoraCall(token, page) {
	return axios({
		method: "get",
		url: endpoint + 'bitacora/reporte/dinamico/registro/todos?page=' + page + '&offset=10', 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getRegistrosByTokenBitacoraCall(token, page) {
	return axios({
		method: "get",
		url: endpoint + 'bitacora/reporte/dinamico/registro?page=' + page + '&offset=10', 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearRegistroReporteBitacoraCall(token, data) {
	return axios({
		method: "post",
	    url: endpoint + "bitacora/reporte/dinamico/registro",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoRegistroReporteBitacoraCall(token, idReporte) {
	return axios({
		method: "get",
		url: endpoint + 'bitacora/reporte/dinamico/registro/activar/' + idReporte , 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarRegistroReporteBitacoraCall(token, data, idReporte) {
	return axios({
		method: "put",
		url: endpoint + "bitacora/reporte/dinamico/registro/" + idReporte,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaPartidasActivasCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "partidas/activas/servicio",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Catálogo de Rangos de Validación
export function getListaRangosValidacionBusqueda(token, page, limiteInf, limiteSup, nombre, ordenamiento) {
	return axios({
		method: "get",
		url: endpoint + "movimientos/almacenes/obtener/rangosValidacion?" + nombre + limiteInf + limiteSup + ordenamiento + '&page=' + page + '&offeset=10',
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearRangoValidacionCall(token, data) {
	return axios({
		method: "post",
	    url: endpoint + "movimientos/almacenes/crear/rangoValidacion",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarRangosValidacionCall(token, data, idRangoDeValidacion) {
	return axios({
		method: "put",
		url: endpoint + "movimientos/almacenes/editar/rangoValidacion/" + idRangoDeValidacion,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoRangosValidacionCall(token, idRangoDeValidacion) {
	return axios({
		method: "get",
		url: endpoint + 'movimientos/almacenes/activarDesactivar/rangosValidacion?idRangoDeValidacion=' + idRangoDeValidacion , 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getRangosValidacionActivosCall(token) {
	return axios({
		method: "get",
		url: endpoint + "movimientos/almacenes/obtener/todos/rangosValidacion",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarExcelRangosValidacionCall(token) {
	return axios({
	    	method: "get",
	    	url: endpoint + "movimientos/almacenes/obtener/rangosValidacion/excel",
		headers: {'Authorization':token},
		responseType: 'blob',
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Responsable de servicios
export function getUsuariosByIdsServiciosCall(token, idsServicios) {
	return axios({
		method: "get",
		url: endpoint + "usuarios/activos/servicios?idsServicios=" + idsServicios,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getUsuariosByIdsServiciosAndTipoCall(token, idsServicios, tipoUsuario) {
	return axios({
		method: "get",
		url: endpoint + "usuarios/activos/servicios?idsServicios=" + idsServicios + tipoUsuario,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearResponsableCall(token, data) {
	return axios({
		method: "post",
	    	url: endpoint + "usuarios/crear/responsables",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaResponsablesBusquedaCall(token, page, nombre, servicio, proveedor, ordenamiento, campo) {
	return axios({
		method: "get",
		url: endpoint + "usuarios/obtener/responsables?" + nombre + servicio + proveedor + ordenamiento + campo + '&page=' + page + '&offset=10',
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaResponsablesPorServicioCall(token, idServicio) {
	return axios({
		method: "get",
		url: endpoint + "usuarios/obtener/todos/responsables?idServicio=" + idServicio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarResponsableServicioCall(token, data) {
	return axios({
		method: "put",
	    	url: endpoint + "usuarios/editar/responsables",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaHistoricoResponsablesCall(token, page, nombreServicio, nombreResponsable, fechaInicialSuplenciaIn, fechaInicialSuplenciaFn, fechaFinalSuplenciaIn, fechaFinalSuplenciaFn, fechaInicioIn, fechaInicioFn, fechaFinIn, fechaFinFn, ordenamiento, campo) {
	return axios({
		method: "get",
		url: endpoint + "usuarios/responsables/historial/obtener?" + nombreServicio + nombreResponsable + fechaInicialSuplenciaIn + fechaInicialSuplenciaFn + fechaFinalSuplenciaIn + fechaFinalSuplenciaFn + fechaInicioIn + fechaInicioFn + fechaFinIn + fechaFinFn +  ordenamiento + campo + '&page=' + page + '&offset=10',
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getNotificacionesCall(token, page) {
	return axios({
		method: "get",
		url: endpoint + "solicitud/servicio/notificaciones/obtener?page=0&offset=" + page,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getNotificacionesNoLeidasCall(token) {
	return axios({
		method: "get",
		url: endpoint + "solicitud/servicio/notificaciones/noLeidas/obtener",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function actualizarNotificacionesCall(token, idsNotificaciones) {
	return axios({
		method: "put",
	    	url: endpoint + "solicitud/servicio/notificaciones/editar?idsNotificaciones=" + idsNotificaciones,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}