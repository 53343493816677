import React, { Component } from 'react';
import {Form, Button, Modal, Col, Spin, Divider, InputNumber, Typography} from 'antd';
import { connect } from 'react-redux';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Text, Paragraph } = Typography;
//MODAL MODIFICAR DENTRO DE "NUEVO FOLIO" POSTERIOR A LA SELECCIÓN DE PIH

class ModalModificarRegistro extends Component {
     formRef = React.createRef();
     render() { 
          const {showModalModificarRequisicionMateriales, fetchingRegistrarRequisicionMateriales, registroProductoSeleccionado,
               listaSeleccionados
          } = this.props;
          
          const onFinish = formulario => {
               console.log('Formulario:' , formulario);
               const listaSeleccionadosLocal = listaSeleccionados;

               for (let i = 0; i < listaSeleccionadosLocal.length; i++) {
                    if(listaSeleccionadosLocal[i].idProducto === registroProductoSeleccionado.idProducto){
                         listaSeleccionadosLocal[i] = Object.assign(listaSeleccionadosLocal[i], formulario);
                    } 
               }
               console.log('lista seleccionados fuera for EditRegistro', listaSeleccionadosLocal);
               this.props.onGuardarProducto(listaSeleccionadosLocal);
               this.props.onShowModalModificar();
               //this.props.onActualizarTabla(listaSeleccionadosLocal);
               if(this.props.requisicionSeleccionada){
                    this.props.onOpenModalEditar(this.props.requisicionSeleccionada, this.props.page, this.props.busqueda, this.props.orden);
               }else{
                    this.props.onOpenModal(this.props.page, this.props.busqueda, this.props.orden);
               }
          };

          const handleCancel = () => {
               this.props.onShowModalModificar();
               if(this.formRef.current){
                    this.formRef.current.resetFields();
               }
               if(this.props.requisicionSeleccionada){
                    this.props.onOpenModalEditar(this.props.requisicionSeleccionada, this.props.page, this.props.busqueda, this.props.orden);
               }else{
                    this.props.onOpenModal(this.props.page, this.props.busqueda, this.props.orden);
               }
          };

          const onCantidad = (value) => {
               this.formRef.current.setFieldsValue({'cantidad': parseInt(value)});
          }
          
 
          return (
               <Modal
                    visible={showModalModificarRequisicionMateriales}
                    title={'Modificar información de: ' + registroProductoSeleccionado?.nombre}
                    onCancel={handleCancel}
                    footer={false}
               >
                    {registroProductoSeleccionado ? (
                    <Spin spinning={fetchingRegistrarRequisicionMateriales}>
                         <Form {...layout}
                              name='formulario'
                              initialValues={registroProductoSeleccionado}
                              onFinish={onFinish}
                              ref={this.formRef}
                         >
                              <Paragraph><Text strong> Producto: {registroProductoSeleccionado.nombre} </Text></Paragraph>
                              <Paragraph><Text type='secondary'> Descripción: {registroProductoSeleccionado.descripcion} </Text></Paragraph>

                              <Divider />
                            
                              <Form.Item
                                   label="Cantidad"
                                   name="cantidad"
                                   rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                              >
                                   <InputNumber min={1} style={{width: '100%'}} onChange={onCantidad} placeholder='Ingresa la cantidad'/>
                              </Form.Item>
                             
                              <Col span={24}>
                                   <Button htmlType="submit" className='nuevoUsuario'>Guardar Cambios</Button>
                              </Col>
                        </Form>
                    </Spin>
               ): false}
               </Modal>
          );
     }
}

const mapStateToProps = state => {
	return {
          showModalModificarRequisicionMateriales: state.AlmacenesReducer.showModalModificarRequisicionMateriales,
          fetchingRegistrarRequisicionMateriales: state.AlmacenesReducer.fetchingRegistrarRequisicionMateriales,
          registroProductoSeleccionado: state.AlmacenesReducer.registroProductoSeleccionado,
          listaSeleccionados: state.AlmacenesReducer.listaSeleccionados,
          requisicionSeleccionada: state.AlmacenesReducer.requisicionSeleccionada,
          page: state.AlmacenesReducer.page,
          busqueda: state.AlmacenesReducer.busqueda,
          orden: state.AlmacenesReducer.orden,
     };
};

const mapDispatchToProps = dispatch => {
	return {
          onShowModalModificar: () => {
               dispatch({ type: "SHOW_MODAL_MODIFICAR_REGISTRO_REQUISICION", showModalModificarRequisicionMateriales: false})
          },
          onGuardarProducto: (listaSeleccionados) => {
               dispatch({ type: "CAMBIAR_VISTA_MODAL_NUEVOFOLIO", vistaModalNuevoFolio: 1, listaSeleccionados: listaSeleccionados})  
          },
          onActualizarTabla: (listaSeleccionados) => {
               dispatch({ type: 'ACTUALIZAR_TABLA_MOVIMIENTOS', listaSeleccionados, hasSelected: true})
          },
          onOpenModal: (page, busqueda, orden) => {
               dispatch({ type: 'SHOW_MODAL_NUEVO_FOLIO_REQUISICION_MATERIALES', showModalRequisicionMateriales: true, page, busqueda, orden })
          },
          onOpenModalEditar: (requisicionSeleccionada, page, busqueda, orden) => {
               dispatch({ type: 'SHOW_MODAL_EDITAR_REQUISICION', showModalEditarRequisicion: true, requisicionSeleccionada, page, busqueda, orden })
          },
	};
};
 
export default connect(mapStateToProps, mapDispatchToProps)(ModalModificarRegistro);