
//Archivo Saga que ejecuta las acciones del Login

import { takeLatest, call, put } from "redux-saga/effects";
import * as configuracionYSeguridadActions from '../actions/ConfiguracionYSeguridadActions';
import { 
    listaUsuariosSinFiltroCall, cambiarEstadoUsuarioCall, proveedorPorDominioCall, recuperarPinCall, listaUsuariosParamCall, cambiarContraseñaCall,

    editarUsuarioCall, listaDominiosActivosCall, listaRolesCall, nuevoUsuarioCall, verificarUsuarioCall,

    filtroDominioCall, filtroServicioCall, filtroDominioServicioCall, 

    getModulosCall, getModulosBusquedaCall, nuevoEstadoModuloCall, cambiarEstadoModuloCall, getListaModulosCall, listaModulosByIdUsuarioCall,

    getPermisosCall, getPermisosBusquedaCall, getPermisosByModuloCall,

    cambiarEstadoRolCall, crearRolCall, editarRolCall, getRolesBusquedaCall, 

    getLogsCall, obtenerSettings, editarSettingsCall, serviciosPorProveedorCall,

    listaAlertasBusquedaCall, cambiarEstadoAlertaCall, listaDiasAlertaCall, listaEventosPorModuloCall, nuevaAlertaCall, editarAlertaCall,

    listaTiposBitacoraBusquedaCall, listaTiposBitacoraActivosCall, cambiarEstadoTipoBitacoraCall, nuevoTipoBitacoraCall, editarTipoBitacoraCall, listaTiposBitacoraByIdModuloCall,

    listaBitacorasBusquedaCall, cambiarEstadoBitacoraCall, nuevaBitacoraCall, editarBitacoraCall,

    listaModulosBitacoraCall, nuevoModuloBitacoraCall, listaModulosBitacoraByIdModuloCall,

    listaPartidasByIdsServiciosCall, reporteDinamicoBitacoraCall, reporteDinamicoBitacoraPDFCall, reporteDinamicoBitacoraExcelCall, reporteDinamicoBitacoraAgrupadoPDFCall, reporteDinamicoBitacoraAgrupadoExcelCall, reporteDinamicoBitacoraAgrupadoCall, crearRegistroReporteBitacoraCall, getAllRegistrosBitacoraCall, getRegistrosByTokenBitacoraCall, estadoRegistroReporteBitacoraCall, editarRegistroReporteBitacoraCall, listaPartidasActivasCall,

    getListaRangosValidacionBusqueda, crearRangoValidacionCall, editarRangosValidacionCall, estadoRangosValidacionCall, getRangosValidacionActivosCall, exportarExcelRangosValidacionCall,

    getUsuariosByIdsServiciosCall, crearResponsableCall, listaResponsablesBusquedaCall, editarResponsableServicioCall, getUsuariosByIdsServiciosAndTipoCall, listaHistoricoResponsablesCall,

    getNotificacionesCall, getNotificacionesNoLeidasCall, actualizarNotificacionesCall,
} from "../../services/ConfiguracionYSeguridadServices";
import moment from "moment";

//************************ Lista de usuarios con param para alertas ***************************
function* listaUsuariosParam(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const busqueda = action.busqueda;

        const response = yield call(listaUsuariosParamCall, token, busqueda);
        console.log(response);

        if(response.status==200){
            const listaUsuariosParam = response.data;
           
            yield put({ type: configuracionYSeguridadActions.LISTA_USUARIOS_PARAM_SUCCESS, listaUsuariosParam });
        }
        else{
            yield put({ type: configuracionYSeguridadActions.LISTA_USUARIOS_PARAM_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_USUARIOS_PARAM_FAILURE });
    }
}
export function* listaUsuariosParamSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_USUARIOS_PARAM_REQUEST, listaUsuariosParam);
}

function* getUsuariosByIdsServicios(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idsServicios = '';

        if(action.idsServicios){
            for(let i in action.idsServicios){
                idsServicios += action.idsServicios[i].split(' - ')[0] + ',';
            }
        }

        const response = yield call(getUsuariosByIdsServiciosCall, token, idsServicios);
        console.log(response);

        if(response.status==200){
            const listaUsuariosByIdsServicios = response.data;
           
            yield put({ type: configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_SUCCESS, listaUsuariosByIdsServicios });
        }
        else{
            yield put({ type: configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_FAILURE });
    }
}
export function* getUsuariosByIdsServiciosSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_REQUEST, getUsuariosByIdsServicios);
}

function* getUsuariosByIdsServiciosAndTipoRS(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idsServicios = '';
        let tipoUsuario = '&tipoUsuario=Servicio';

        if(action.idsServicios){
            for(let i in action.idsServicios){
                idsServicios += action.idsServicios[i].split(' - ')[0] + ',';
            }
        }

        const response = yield call(getUsuariosByIdsServiciosAndTipoCall, token, idsServicios, tipoUsuario);
        console.log(response);

        if(response.status==200){
            const listaUsuariosByIdsServiciosTipoRS = response.data;
           
            yield put({ type: configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_RS_SUCCESS, listaUsuariosByIdsServiciosTipoRS });
        }
        else{
            yield put({ type: configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_RS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_RS_FAILURE });
    }
}
export function* getUsuariosByIdsServiciosAndTipoRSSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_RS_REQUEST, getUsuariosByIdsServiciosAndTipoRS);
}

function* getUsuariosByIdsServiciosAndTipoAT(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idsServicios = '';
        let tipoUsuario = '&tipoUsuario=AT';

        if(action.idsServicios){
            for(let i in action.idsServicios){
                idsServicios += action.idsServicios[i].split(' - ')[0] + ',';
            }
        }

        const response = yield call(getUsuariosByIdsServiciosAndTipoCall, token, idsServicios, tipoUsuario);
        console.log(response);

        if(response.status==200){
            const listaUsuariosByIdsServiciosTipoAT = response.data;
           
            yield put({ type: configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_AT_SUCCESS, listaUsuariosByIdsServiciosTipoAT });
        }
        else{
            yield put({ type: configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_AT_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_AT_FAILURE });
    }
}
export function* getUsuariosByIdsServiciosAndTipoATSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_AT_REQUEST, getUsuariosByIdsServiciosAndTipoAT);
}

function* getUsuariosByIdsServiciosAndTipoPYRS(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idsServicios = '';
        let tipoUsuario = '&tipoUsuario=PYRS';

        if(action.idsServicios){
            for(let i in action.idsServicios){
                idsServicios += action.idsServicios[i].split(' - ')[0] + ',';
            }
        }

        const response = yield call(getUsuariosByIdsServiciosAndTipoCall, token, idsServicios, tipoUsuario);
        console.log(response);

        if(response.status==200){
            const listaUsuariosByIdsServiciosTipoPYRS = response.data;
           
            yield put({ type: configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_PYRS_SUCCESS, listaUsuariosByIdsServiciosTipoPYRS });
        }
        else{
            yield put({ type: configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_PYRS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_PYRS_FAILURE });
    }
}
export function* getUsuariosByIdsServiciosAndTipoPYRSSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_PYRS_REQUEST, getUsuariosByIdsServiciosAndTipoPYRS);
}

//************************ Lista de usuarios sin filtro en el componentDidMouth() ***************************
function* listaUsuariosSinFiltro(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = (action.page - 1) * 10;

        const response = yield call(listaUsuariosSinFiltroCall, token, page);
        console.log(response);

        if(response.status==200){
            const listaUsuarios = response.data.data;
            const cantidadListaUsuarios = response.data.cantidad;
            for(let i=0; i<listaUsuarios.length; i++){
                listaUsuarios[i].perfilUsuario = '';
                listaUsuarios[i].listaRoles = [];
                listaUsuarios[i].servicios = '';
                listaUsuarios[i].listaServicios = [];
                let aMaterno = '';
                if(listaUsuarios[i].aMaterno){
                    aMaterno = listaUsuarios[i].aMaterno;
                };
                listaUsuarios[i].nombreCompleto = listaUsuarios[i].nombre + ' ' + listaUsuarios[i].aPaterno + ' ' + aMaterno;
                if(listaUsuarios[i].dominioByIdDominio){
                    listaUsuarios[i].dominio = listaUsuarios[i].dominioByIdDominio.dominio + listaUsuarios[i].dominioByIdDominio.terminacion;
                }
                else{
                    listaUsuarios[i].dominio = 'Sin dominio';
                }
                if(listaUsuarios[i].proveedorByIdProveedor){
                    listaUsuarios[i].proveedor = listaUsuarios[i].proveedorByIdProveedor.nombre;
                }
                else{
                    listaUsuarios[i].proveedor = 'Sin proveedor';
                }
                if(listaUsuarios[i].serviciosByIdUsuario){
                    for(let x=0; x<listaUsuarios[i].serviciosByIdUsuario.length; x++){
                        listaUsuarios[i].servicios += '-' + listaUsuarios[i].serviciosByIdUsuario[x].nombre + ' ';
                        listaUsuarios[i].listaServicios.push(listaUsuarios[i].serviciosByIdUsuario[x].nombre);
                    }
                }
                else{
                    listaUsuarios[i].servicios = 'No cuenta con servicios';
                }

                if(listaUsuarios[i].roles){
                    for(let x=0; x<listaUsuarios[i].roles.length; x++){
                        listaUsuarios[i].perfilUsuario += '-' + listaUsuarios[i].roles[x].descripcion + ' ';
                        listaUsuarios[i].listaRoles.push(listaUsuarios[i].roles[x].descripcion);
                    }
                }
                else{
                    listaUsuarios[i].perfilUsuario = 'No cuenta con perfiles'
                }
            }
            yield put({ type: configuracionYSeguridadActions.LISTA_USUARIOS_SIN_FILTRO_SUCCESS, listaUsuarios, cantidadListaUsuarios });
        }
        else{
            yield put({ type: configuracionYSeguridadActions.LISTA_USUARIOS_SIN_FILTRO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_USUARIOS_SIN_FILTRO_FAILURE });
    }
}
export function* listaUsuariosSinFiltroSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_USUARIOS_SIN_FILTRO_REQUEST, listaUsuariosSinFiltro);
}

//********************** Cambiar el estado del usuario Activo/inactivo *****************************
function* cambiarEstadoUsuario(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idUsuario = action.idUsuario;

        const response = yield call(cambiarEstadoUsuarioCall, token, idUsuario);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const showSuccessMsg = true;
            const textMessage = "El estado del usuario cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.CAMBIAR_ESTADO_USUARIO_SUCCESS });
            yield put({ type: configuracionYSeguridadActions.LISTA_USUARIOS_SIN_FILTRO_REQUEST, page });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: configuracionYSeguridadActions.CAMBIAR_ESTADO_USUARIO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.CAMBIAR_ESTADO_USUARIO_FAILURE });
    }
}
export function* cambiarEstadoUsuarioSaga() {
    yield takeLatest(configuracionYSeguridadActions.CAMBIAR_ESTADO_USUARIO_REQUEST, cambiarEstadoUsuario);
}

//********************** Lista de dominios activos para formulario Alta y Edicion de usuario *****************************
function* listaDominiosActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaDominiosActivosCall, token);
        console.log(response);

        if(response.status==200){
            const listaDominiosActivos = response.data;
            for(let i=0; i<listaDominiosActivos.length; i++){
                listaDominiosActivos[i].dominioCompleto = listaDominiosActivos[i].dominio + listaDominiosActivos[i].terminacion;
            }

            yield put({ type: configuracionYSeguridadActions.LISTA_DOMINIOS_ACTIVOS_SUCCESS, listaDominiosActivos });
        }
        else{
            yield put({ type: configuracionYSeguridadActions.LISTA_DOMINIOS_ACTIVOS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_DOMINIOS_ACTIVOS_FAILURE });
    }
}
export function* listaDominiosActivosSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_DOMINIOS_ACTIVOS_REQUEST, listaDominiosActivos);
}

//********************** Lista de Roles para tabla en catálogo Roles *****************************
function* listaRoles(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let response = yield call(listaRolesCall, token);

        console.log(response);

        if(response.status==200){
            const listaRoles = response.data;
            
            for(let i=0; i<listaRoles.length; i++){
                let fechaAlta = listaRoles[i].fechaAlta.split('T')[0];
                listaRoles[i].fechaAlta =  fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
            }

            yield put({ type: configuracionYSeguridadActions.LISTA_ROLES_SUCCESS, listaRoles });
        }
        else{
            yield put({ type: configuracionYSeguridadActions.LISTA_ROLES_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_ROLES_FAILURE });
    }
}
export function* listaRolesSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_ROLES_REQUEST, listaRoles);
}

//********************** Verificar correo de nuevo usuario *****************************
function* verificarUsuario(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let dominio = null;
        const idDominio = parseInt(action.dominio)
        for(let i=0; i<action.listaDominiosActivos.length; i++){
            if(idDominio === action.listaDominiosActivos[i].idDominio){
                dominio = action.listaDominiosActivos[i].dominio + action.listaDominiosActivos[i].terminacion
            }
        }
        const username = action.username.toLowerCase() + '@' + dominio;
        console.log(username);
        const response = yield call(verificarUsuarioCall, token, username);
        console.log(response);

        if(response.status==200){
            const responseProveedor = yield call(proveedorPorDominioCall, token, idDominio);
            console.log(responseProveedor);
            if(responseProveedor.status==200){
                const showSuccessMsg = true;
                const textMessage = "El correo '" + username + "' es válido, favor de continuar";

                yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });

                const listaProveedoresDominio = responseProveedor.data;
                yield put({ type: configuracionYSeguridadActions.VERIFICAR_USUARIO_SUCCESS, username, idDominio });
                yield put({ type: configuracionYSeguridadActions.LISTA_PROVEEDOR_DOMINIO_SUCCESS, listaProveedoresDominio });
            }
            else{
                const showErrorMsg = true;
                const textMessage = responseProveedor.message;

                yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                yield put({ type: configuracionYSeguridadActions.LISTA_PROVEEDOR_DOMINIO_FAILURE });
            }
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: configuracionYSeguridadActions.VERIFICAR_USUARIO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.VERIFICAR_USUARIO_FAILURE });
    }
}
export function* verificarUsuarioSaga() {
    yield takeLatest(configuracionYSeguridadActions.VERIFICAR_USUARIO_REQUEST, verificarUsuario);
}

//********************** REESTABLECER pin de usuario *****************************
function* recuperarPinUsuario(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idUsuario = parseInt(action.usuario.idUsuario)

        const response = yield call(recuperarPinCall, token, idUsuario);
        console.log(response);

        if(response.status==200){
            const showSuccessMsg = true;
            const textMessage = "El pin del usuario '" + action.usuario.username + "' se reestableció de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });

            yield put({ type: configuracionYSeguridadActions.RECUPERAR_PIN_SUCCESS });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.RECUPERAR_PIN_FAILURE });
        }
        
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.RECUPERAR_PIN_FAILURE });
    }
}
export function* recuperarPinUsuarioSaga() {
    yield takeLatest(configuracionYSeguridadActions.RECUPERAR_PIN_REQUEST, recuperarPinUsuario);
}

//********************** REESTABLECER contraseña *****************************
function* editarContraseña(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idUsuario = parseInt(action.usuario.idUsuario)
        const data={
            'password': action.formulario.contraseñaNueva,
        }

        const response = yield call(cambiarContraseñaCall, token, idUsuario, data);
        console.log(response);

        if(response.status==200){
            const showSuccessMsg = true;
            const textMessage = "La contraseña se modificó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarContraseña'});
            yield put({ type: configuracionYSeguridadActions.EDITAR_CONTRASEÑA_SUCCESS });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.EDITAR_CONTRASEÑA_FAILURE });
        }
        
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.EDITAR_CONTRASEÑA_FAILURE });
    }
}
export function* editarContraseñaSaga() {
    yield takeLatest(configuracionYSeguridadActions.EDITAR_CONTRASEÑA_REQUEST, editarContraseña);
}

//********************** CREAR usuario *****************************
function* nuevoUsuario(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let roles = [];
        let servicios = [];
        const formularioSearch = action.formularioSearch;
        
        for(let i=0; i<action.formulario.serviciosPorProveedor.length; i++){
            for(let x=0; x<action.serviciosPorProveedor.length; x++){
                if(parseInt(action.formulario.serviciosPorProveedor[i]) === action.serviciosPorProveedor[x].idServicio){
                    const servicio = {
                        "idServicio": action.serviciosPorProveedor[x].idServicio,
                        "idGrupoSer": action.serviciosPorProveedor[x].idGrupoSer,
                        "nomenclatura": action.serviciosPorProveedor[x].nomenclatura,
                        "nombre": action.serviciosPorProveedor[x].nombre,
                        "nombreC": action.serviciosPorProveedor[x].nombreC,
                        "nombreManual": action.serviciosPorProveedor[x].nombreManual,
                        "urlImagen": action.serviciosPorProveedor[x].urlImagen,
                        "rutaManual": action.serviciosPorProveedor[x].rutaManual,
                        "peso": action.serviciosPorProveedor[x].peso,
                        "observaciones": action.serviciosPorProveedor[x].observaciones,
                        "fechaAlta": null,
                        "fechaActualizacion": null,
                        "activo": 1,
                        "ponderacionByIdServicio": null,
                        'idContrato': action.serviciosPorProveedor[x].idContrato,
                        "contratoByIdContrato": null,
                    }
                    servicios.push(servicio);
                }
            }
        }
        for(let i=0; i<action.formulario.listaRoles.length; i++){
            for(let x=0; x<action.listaRoles.length; x++){
                if(action.formulario.listaRoles[i] === action.listaRoles[x].descripcion){
                    const rol = {
                        'idRol': action.listaRoles[x].idRol,
                        'descripcion': action.listaRoles[x].descripcion,
                        'activo': action.listaRoles[x].activo,
                    }
                    roles.push(rol);
                }
            }
        }
        console.log(roles);
        const data = {
            'idUsuario': null,
            'username': action.username,
            'password': '',
            'nombre': action.formulario.nombre,
            'aPaterno': action.formulario.aPaterno,
            'aMaterno': action.formulario.aMaterno,
            'tipoUsuario': action.formulario.tipoUsuario,
            'idDominio': parseInt(action.idDominio),
            'idProveedor': action.formulario.idProveedor,
            'activo': 1,
            'emailVerified': 0,
            'intentos': 0,
            'roles': roles,
            'serviciosByIdUsuario': servicios,
            'pin': '',
        }
        console.log('data');
        console.log(data);
        const response = yield call(nuevoUsuarioCall, token, data);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const modalNuevoUsuario = false;
            
            const showSuccessMsg = true;
            const textMessage = 'El usuario ' + action.username + ' se creó de forma correcta';

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.NUEVO_USUARIO_SUCCESS });
            yield put({ type: configuracionYSeguridadActions.MODAL_NUEVO_USUARIO, modalNuevoUsuario });
            yield put({ type: configuracionYSeguridadActions.FILTRO_DOMINIO_SERVICIO_REQUEST, formulario:formularioSearch, page:page });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'verificarCorreo'});
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.NUEVO_USUARIO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.NUEVO_USUARIO_FAILURE });
    }
}
export function* nuevoUsuarioSaga() {
    yield takeLatest(configuracionYSeguridadActions.NUEVO_USUARIO_REQUEST, nuevoUsuario);
}

//********************** Editar al usuario seleccionado *****************************
function* editarUsuario(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idUsuario = action.usuario.idUsuario;
        let roles = [];
        const formularioSearch = action.formularioSearch;
        let servicios = [];
        
        for(let i=0; i<action.formulario.listaServicios.length; i++){
            for(let x=0; x<action.serviciosPorProveedor.length; x++){
                if(action.formulario.listaServicios[i] === action.serviciosPorProveedor[x].nombre){
                    const servicio = {
                        "idServicio": action.serviciosPorProveedor[x].idServicio,
                        "idGrupoSer": action.serviciosPorProveedor[x].idGrupoSer,
                        "nomenclatura": action.serviciosPorProveedor[x].nomenclatura,
                        "nombre": action.serviciosPorProveedor[x].nombre,
                        "nombreC": action.serviciosPorProveedor[x].nombreC,
                        "nombreManual": action.serviciosPorProveedor[x].nombreManual,
                        "urlImagen": action.serviciosPorProveedor[x].urlImagen,
                        "rutaManual": action.serviciosPorProveedor[x].rutaManual,
                        "peso": action.serviciosPorProveedor[x].peso,
                        "observaciones": action.serviciosPorProveedor[x].observaciones,
                        "fechaAlta": null,
                        "fechaActualizacion": null,
                        "activo": 1,
                        "ponderacionByIdServicio": null,
                        'idContrato': action.serviciosPorProveedor[x].idContrato,
                        "contratoByIdContrato": null,
                    }
                    servicios.push(servicio);
                }
            }
        }
        for(let i=0; i<action.formulario.listaRoles.length; i++){
            for(let x=0; x<action.listaRoles.length; x++){
                if(action.formulario.listaRoles[i] === action.listaRoles[x].descripcion){
                    const rol = {
                        'idRol': action.listaRoles[x].idRol,
                        'descripcion': action.listaRoles[x].descripcion,
                        'activo': action.listaRoles[x].activo,
                    }
                    roles.push(rol);
                }
            }
        }
        console.log(roles);
        const data = {
            'idUsuario': idUsuario,
            'username': action.formulario.username,
            'password': '',
            'nombre': action.formulario.nombre,
            'aPaterno': action.formulario.aPaterno,
            'aMaterno': action.formulario.aMaterno,
            'idDominio': action.usuario.idDominio,
            'idProveedor': action.usuario.idProveedor,
            'activo': action.usuario.activo,
            'emailVerified': action.usuario.emailVerified,
            'intentos': action.usuario.intentos,
            'roles': roles,
            'serviciosByIdUsuario': servicios,
            'pin': action.usuario.pin,
        }
        const response = yield call(editarUsuarioCall, token, idUsuario, data);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const modalEditarUsuario = false;
            const usuario = null;
            const showSuccessMsg = true;
            const textMessage = 'El usuario ' + action.formulario.username + ' se modificó de forma correcta';

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.EDITAR_USUARIO_SUCCESS });
            yield put({ type: configuracionYSeguridadActions.FILTRO_DOMINIO_SERVICIO_REQUEST, formulario:formularioSearch, page:page });
            yield put({ type: configuracionYSeguridadActions.MODAL_EDITAR_USUARIO, modalEditarUsuario, usuario });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.EDITAR_USUARIO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.EDITAR_USUARIO_FAILURE });
    }
}
export function* editarUsuarioSaga() {
    yield takeLatest(configuracionYSeguridadActions.EDITAR_USUARIO_REQUEST, editarUsuario);
}

//********************** Lista de usuarios con filtro Servicio *****************************
function* listaUsuariosServicio(action) {
    try {
        console.log("lo que estoy mandando action ",action);
        const token = sessionStorage.getItem('token');
        const page = (action.page - 1) * 10;
        let idsServicios = '';
        let servicio = ''

        for(let i in action.formulario.idsServicios){
            if(i == 0){
                idsServicios = action.formulario.idsServicios[i].split(' - ')[0];
            } else {
                idsServicios += ',' + action.formulario.idsServicios[i].split(' - ')[0];
            }
        }

        const response = yield call(filtroServicioCall, token, idsServicios, page);
        console.log(response);

        if(response.status==200){
            const listaUsuarios = response.data.data;
            const cantidadListaUsuarios = response.data.cantidad;
            for(let i=0; i<listaUsuarios.length; i++){
                listaUsuarios[i].tipoUsuario = '';
                listaUsuarios[i].listaRoles = [];
                listaUsuarios[i].servicios = '';
                listaUsuarios[i].listaServicios = [];
                let aMaterno = '';
                if(listaUsuarios[i].aMaterno){
                    aMaterno = listaUsuarios[i].aMaterno;
                };
                listaUsuarios[i].nombreCompleto = listaUsuarios[i].nombre + ' ' + listaUsuarios[i].aPaterno + ' ' + aMaterno;
                if(listaUsuarios[i].dominioByIdDominio){
                    listaUsuarios[i].dominio = listaUsuarios[i].dominioByIdDominio.dominio + listaUsuarios[i].dominioByIdDominio.terminacion;
                }
                else{
                    listaUsuarios[i].dominio = 'Sin dominio';
                }
                listaUsuarios[i].proveedor = listaUsuarios[i].proveedorByIdProveedor.nombre;
                if(listaUsuarios[i].serviciosByIdUsuario){
                    for(let x=0; x<listaUsuarios[i].serviciosByIdUsuario.length; x++){
                        listaUsuarios[i].servicios += '-' + listaUsuarios[i].serviciosByIdUsuario[x].nombre + ' ';
                        listaUsuarios[i].listaServicios.push(listaUsuarios[i].serviciosByIdUsuario[x].nombre);
                    }
                }
                else{
                    listaUsuarios[i].servicios = 'No cuenta con servicios';
                }

                if(listaUsuarios[i].roles){
                    for(let x=0; x<listaUsuarios[i].roles.length; x++){
                        listaUsuarios[i].tipoUsuario += '-' + listaUsuarios[i].roles[x].descripcion + ' ';
                        listaUsuarios[i].listaRoles.push(listaUsuarios[i].roles[x].descripcion);
                    }
                }
                else{
                    listaUsuarios[i].tipoUsuario = 'No cuenta con perfiles'
                }
            }
            yield put({ type: configuracionYSeguridadActions.FILTRO_SERVICIO_SUCCESS, listaUsuarios, cantidadListaUsuarios });
        }
        if(action.formulario.servicio){
            servicio = '&servicio='+action.formulario.servicio;
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.FILTRO_SERVICIO_FAILURE });
    }
}
export function* listaUsuariosServicioSaga() {
    yield takeLatest(configuracionYSeguridadActions.FILTRO_SERVICIO_REQUEST, listaUsuariosServicio);
}

//********************** Lista de usuarios con filtro Servicio *****************************
function* listaUsuariosDominioServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = (action.page - 1) * 10;
        const dominio = action.formulario.dominio ? 'dominio=' + action.formulario.dominio : '';
        let idsServicios = '';

        if(action.formulario.idsServicios){
            for(let i in action.formulario.idsServicios){
                if(i == 0){
                    idsServicios = '&idsServicios=' + action.formulario.idsServicios[i].split(' - ')[0];
                } else {
                    idsServicios = ',' + action.formulario.idsServicios[i].split(' - ')[0];
                }
            }
        }

        const response = yield call(filtroDominioServicioCall, token, dominio, idsServicios ,page);
        console.log(response);

        if(response.status==200){
            const listaUsuarios = response.data.data;
            const cantidadListaUsuarios = response.data.cantidad;
            for(let i=0; i<listaUsuarios.length; i++){
                listaUsuarios[i].tipoUsuario = '';
                listaUsuarios[i].listaRoles = [];
                listaUsuarios[i].servicios = '';
                listaUsuarios[i].listaServicios = [];
                let aMaterno = '';
                if(listaUsuarios[i].aMaterno){
                    aMaterno = listaUsuarios[i].aMaterno;
                };
                listaUsuarios[i].nombreCompleto = listaUsuarios[i].nombre + ' ' + listaUsuarios[i].aPaterno + ' ' + aMaterno;
                if(listaUsuarios[i].dominioByIdDominio){
                    listaUsuarios[i].dominio = listaUsuarios[i].dominioByIdDominio.dominio + listaUsuarios[i].dominioByIdDominio.terminacion;
                }
                else{
                    listaUsuarios[i].dominio = 'Sin dominio';
                }                listaUsuarios[i].proveedor = listaUsuarios[i].proveedorByIdProveedor.nombre;
                if(listaUsuarios[i].serviciosByIdUsuario){
                    for(let x=0; x<listaUsuarios[i].serviciosByIdUsuario.length; x++){
                        listaUsuarios[i].servicios += '-' + listaUsuarios[i].serviciosByIdUsuario[x].nombre + ' ';
                        listaUsuarios[i].listaServicios.push(listaUsuarios[i].serviciosByIdUsuario[x].nombre);
                    }
                }
                else{
                    listaUsuarios[i].servicios = 'No cuenta con servicios';
                }

                if(listaUsuarios[i].roles){
                    for(let x=0; x<listaUsuarios[i].roles.length; x++){
                        listaUsuarios[i].tipoUsuario += '-' + listaUsuarios[i].roles[x].descripcion + ' ';
                        listaUsuarios[i].listaRoles.push(listaUsuarios[i].roles[x].descripcion);
                    }
                }
                else{
                    listaUsuarios[i].tipoUsuario = 'No cuenta con perfiles'
                }
            }
            yield put({ type: configuracionYSeguridadActions.FILTRO_DOMINIO_SERVICIO_SUCCESS, listaUsuarios, cantidadListaUsuarios });
        }
        else{
            yield put({ type: configuracionYSeguridadActions.FILTRO_DOMINIO_SERVICIO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.FILTRO_DOMINIO_SERVICIO_FAILURE });
    }
}
export function* listaUsuariosDominioServicioSaga() {
    yield takeLatest(configuracionYSeguridadActions.FILTRO_DOMINIO_SERVICIO_REQUEST, listaUsuariosDominioServicio);
}
//********************** Lista de Modulos del Sistema para Alertas *****************************
function* listaModulosSelect(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(getListaModulosCall, token);
        
        console.log(response);

        if(response.status==200){
            const listaModulosSelect = response.data;

            yield put({ type: configuracionYSeguridadActions.LISTA_MODULOS_SUCCESS, listaModulosSelect });
        }
        else{
            yield put({ type: configuracionYSeguridadActions.LISTA_MODULOS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_MODULOS_FAILURE });
    }
}
export function* listaModulosSelectSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_MODULOS_REQUEST, listaModulosSelect);
}

//********************** Lista de Modulos del Sistema en catálogos *****************************
function* listaModulos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const busqueda = action.busqueda;

        let response = null;
        if(busqueda){
            response = yield call(getModulosBusquedaCall, token, page, busqueda);
        }
        else{
            response = yield call(getModulosCall, token, page);
        }
        console.log(response);

        if(response.status==200){
            const listaModulos = response.data;
            for(let i=0; i<listaModulos.length; i++){
                if(!listaModulos[i].urlModulo){
                    listaModulos[i].urlModulo = 'No cuenta con URL';
                }
                let fechaAlta = listaModulos[i].fechaAlta.split('T')[0];
                listaModulos[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
            }

            yield put({ type: configuracionYSeguridadActions.GET_MODULOS_SUCCESS, listaModulos });
        }
        else{
            yield put({ type: configuracionYSeguridadActions.GET_MODULOS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.GET_MODULOS_FAILURE });
    }
}
export function* listaModulosSaga() {
    yield takeLatest(configuracionYSeguridadActions.GET_MODULOS_REQUEST, listaModulos);
}

//********************** Cambiar el estado del Modulo Activo/inactivo *****************************
function* cambiarEstadoModulo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idModulo = action.idModulo;

        const response = yield call(cambiarEstadoModuloCall, token, idModulo);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const busqueda = null;
            const showSuccessMsg = true;
            const textMessage = "El estado del modulo cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.ESTADO_MODULO_SUCCESS });
            yield put({ type: configuracionYSeguridadActions.GET_MODULOS_REQUEST, page, busqueda });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: configuracionYSeguridadActions.ESTADO_MODULO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.ESTADO_MODULO_FAILURE });
    }
}
export function* cambiarEstadoModuloSaga() {
    yield takeLatest(configuracionYSeguridadActions.ESTADO_MODULO_REQUEST, cambiarEstadoModulo);
}

//********************** Nuevo Modulo *************************
function* nuevoEstadoModulo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const data = {
            "idModulo": null,
            "nombre": action.formulario.nombre,
            "descripcion": '',
            "urlModulo": "",
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }
        console.log('data');
        console.log(data);
        const response = yield call(nuevoEstadoModuloCall, token, data);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const busqueda = null;
            const showSuccessMsg = true;
            const textMessage = "El estado del modulo creó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.NUEVO_MODULO_SUCCESS });
            yield put({ type: configuracionYSeguridadActions.SHOW_NUEVO_MODULO, showModalNuevoModulo: false });
            yield put({ type: configuracionYSeguridadActions.GET_MODULOS_REQUEST, page, busqueda });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: configuracionYSeguridadActions.NUEVO_MODULO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.NUEVO_MODULO_FAILURE });
    }
}
export function* nuevoEstadoModuloSaga() {
    yield takeLatest(configuracionYSeguridadActions.NUEVO_MODULO_REQUEST, nuevoEstadoModulo);
}

function* listaModulosByIdUsuario(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let userInfo = sessionStorage.getItem('userInfo');
        if(userInfo[0]) { userInfo = JSON.parse(userInfo); }
        const idUsuario = action.idUsuario ? action.idUsuario : userInfo[0].idUsuario;

        const response = yield call(listaModulosByIdUsuarioCall, token, idUsuario);
        console.log(response);

        if(response.status==200){
            const listaModulosByIdUsuario = response.data;

            yield put({ type: configuracionYSeguridadActions.GET_MODULOS_BY_IDUSUARIO_SUCCESS, listaModulosByIdUsuario });
        }
        else{
            yield put({ type: configuracionYSeguridadActions.GET_MODULOS_BY_IDUSUARIO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.GET_MODULOS_BY_IDUSUARIO_FAILURE });
    }
}
export function* listaModulosByIdUsuarioSaga() {
    yield takeLatest(configuracionYSeguridadActions.GET_MODULOS_BY_IDUSUARIO_REQUEST, listaModulosByIdUsuario);
}

//********************** Obtener lista de permisos para tabla permisos *****************************
function* listaPermisos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(getPermisosCall, token);
        console.log(response);

        if(response.status==200){
            const listaPermisos = response.data;
            yield put({ type: configuracionYSeguridadActions.GET_PERMISOS_SUCCESS, listaPermisos });
        }
        else{

            yield put({ type: configuracionYSeguridadActions.GET_PERMISOS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.GET_PERMISOS_FAILURE });
    }
}
export function* listaPermisosSaga() {
    yield takeLatest(configuracionYSeguridadActions.GET_PERMISOS_REQUEST, listaPermisos);
}

//********************** Obtener lista de permisos por idModulo *****************************
function* listaPermisosByModulo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idModulo = action.idModulo;

        const response = yield call(getPermisosByModuloCall, token, idModulo);
        console.log(response);

        if(response.status==200){
            const listaPermisosByModulo = response.data;

            // for(let i in listaPermisosByModulo){
            //     if(!listaPermisosByModulo[i].descripcion){
            //         listaPermisosByModulo[i].descripcion = listaPermisosByModulo[i].eventoByIdEvento.evento;
            //     }
            // }
            yield put({ type: configuracionYSeguridadActions.GET_PERMISOS_BY_MODULO_SUCCESS, listaPermisosByModulo });
        }
        else{

            yield put({ type: configuracionYSeguridadActions.GET_PERMISOS_BY_MODULO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.GET_PERMISOS_BY_MODULO_FAILURE });
    }
}
export function* listaPermisosByModuloSaga() {
    yield takeLatest(configuracionYSeguridadActions.GET_PERMISOS_BY_MODULO_REQUEST, listaPermisosByModulo);
}

//********************** Lista de Roles por búsqueda *****************************
function* listaRolesBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let busqueda = '';
        if(action.busqueda){
            busqueda='param='+ action.busqueda + '&'
        }
        console.log('page');
        console.log(page);
        console.log('busqueda');
        console.log(busqueda);
        let response = yield call(getRolesBusquedaCall, token, page, busqueda);
        console.log(response);

        if(response.status==200){
            const listaRolesBusqueda = response.data.data;
            const listaRolesBusquedaCantidad = response.data.cantidad;
            for(let i=0; i<listaRolesBusqueda.length; i++){
                let fechaAlta = listaRolesBusqueda[i].fechaAlta.split('T')[0];
                listaRolesBusqueda[i].fechaAlta =  fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
                listaRolesBusqueda[i].permisos = '';
                listaRolesBusqueda[i].permisosSelect = [];
                if(listaRolesBusqueda[i].permisosByIdRol){
                    for(let x=0; x<listaRolesBusqueda[i].permisosByIdRol.length; x++){
                        listaRolesBusqueda[i].permisos += '-' + listaRolesBusqueda[i].permisosByIdRol[x].descripcion + ' ';
                        listaRolesBusqueda[i].permisosSelect.push(listaRolesBusqueda[i].permisosByIdRol[x].idPermiso + ' - ' + listaRolesBusqueda[i].permisosByIdRol[x].descripcion);
                    }
                }
                else{
                    listaRolesBusqueda[i].permisos = 'No cuenta con permisos';
                }
            }

            yield put({ type: configuracionYSeguridadActions.LISTA_ROLES_BUSQUEDA_SUCCESS, listaRolesBusqueda, listaRolesBusquedaCantidad });
        }
        else{
            yield put({ type: configuracionYSeguridadActions.LISTA_ROLES_BUSQUEDA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_ROLES_BUSQUEDA_FAILURE });
    }
}
export function* listaRolesBusquedaSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_ROLES_BUSQUEDA_REQUEST, listaRolesBusqueda);
}

//********************** Cambiar el estado del Rol Activo/inactivo *****************************
function* cambiarEstadoRol(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idRol = action.idRol;

        const response = yield call(cambiarEstadoRolCall, token, idRol);
        console.log(response);

        if(response.status==200){
            const showSuccessMsg = true;
            const textMessage = "El estado del rol cambió de forma correcta";
            const page = action.page;

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.ESTADO_ROL_SUCCESS });
            yield put({ type: configuracionYSeguridadActions.LISTA_ROLES_BUSQUEDA_REQUEST, page });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: configuracionYSeguridadActions.ESTADO_ROL_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.ESTADO_ROL_FAILURE });
    }
}
export function* cambiarEstadoRolSaga() {
    yield takeLatest(configuracionYSeguridadActions.ESTADO_ROL_REQUEST, cambiarEstadoRol);
}

//********************** CREAR nuevo rol *****************************
function* crearRol(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const valueSearch = action.valueSearch;
        let permisos = [];
        for(let i=0; i<action.formulario.idPermiso.length; i++){
            const permiso = {
                "idPermiso": action.formulario.idPermiso[i].split(' - ')[0],
                "descripcion": '',
                "activo": true,
                "fechaAlta": null,
                "fechaActualizacion": null
            }
            permisos.push(permiso);
        }
        console.log(permisos);
        let data = {
            "idRol": null,
            "descripcion": action.formulario.descripcion,
            "activo": 1,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "permisosByIdRol": permisos
        }
        console.log(data);
        const response = yield call(crearRolCall, token, data);
        console.log(response);

        if(response.status==200){
            const showSuccessMsg = true;
            const modalNuevoUsuario = false;
            const page = action.page;
            const textMessage = "El rol '" + action.formulario.descripcion + "' se creó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.NUEVO_ROL_SUCCESS });
            yield put({ type: configuracionYSeguridadActions.SHOW_MODAL_NUEVO_ROL, modalNuevoUsuario });
            yield put({ type: configuracionYSeguridadActions.LISTA_ROLES_BUSQUEDA_REQUEST, page });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearRol'});
            yield put({ type: configuracionYSeguridadActions.LISTA_ROLES_BUSQUEDA_REQUEST, valueSearch:valueSearch, page: page});
            
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: configuracionYSeguridadActions.NUEVO_ROL_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.NUEVO_ROL_FAILURE });
    }
}
export function* crearRolSaga() {
    yield takeLatest(configuracionYSeguridadActions.NUEVO_ROL_REQUEST, crearRol);
}

//********************** EDITAR rol seleccionado *****************************
function* editarRol(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let permisos = [];
        const valueSearch = action.valueSearch;
        const idRol = action.rolSeleccionado.idRol;
        for(let i=0; i<action.formulario.permisosSelect.length; i++){
            const permiso = {
                "idPermiso": action.formulario.permisosSelect[i].split(' - ')[0],
                "descripcion": '',
                "activo": true,
                "fechaAlta": null,
                "fechaActualizacion": null
            }
            permisos.push(permiso);
        }
        console.log(permisos);
        let data = {
            "idRol": idRol,
            "descripcion": action.formulario.descripcion,
            "activo": action.rolSeleccionado.activo,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "permisosByIdRol": permisos
        }
        console.log(data);
        const response = yield call(editarRolCall, token, data, idRol);
        console.log(response);

        if(response.status==200){
            const showModalEditarRol = false;
            const rolSeleccionado = null;
            const showSuccessMsg = true;
            const page = action.page;
            const textMessage = "El rol '" + action.formulario.descripcion + "' se editó de forma correcta. Los cambios hechos se verán afectados a los permisos de los usuarios";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.EDITAR_ROL_SUCCESS });
            yield put({ type: configuracionYSeguridadActions.LISTA_ROLES_BUSQUEDA_REQUEST, page });
            yield put({ type: configuracionYSeguridadActions.SHOW_MODAL_EDITAR_ROL, showModalEditarRol, rolSeleccionado });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarRol'});
            yield put({ type: configuracionYSeguridadActions.LISTA_ROLES_BUSQUEDA_REQUEST, page:page, valueSearchL:valueSearch});
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: configuracionYSeguridadActions.EDITAR_ROL_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.EDITAR_ROL_FAILURE });
    }
}
export function* editarRolSaga() {
    yield takeLatest(configuracionYSeguridadActions.EDITAR_ROL_REQUEST, editarRol);
}

//********************** Obtener lista de permisos para Seguridad *****************************
function* listaPermisosBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let busqueda = '';
        if(action.busqueda){
            busqueda = 'param=' + action.busqueda + '&';
        }
        console.log(busqueda);
        const response = yield call(getPermisosBusquedaCall, token, page, busqueda);
        console.log(response);

        if(response.status==200){
            const listaPermisosBusqueda = response.data.data;
            const listaPermisosBusquedaCantidad = response.data.cantidad;
            for(let i in listaPermisosBusqueda){
                if(!listaPermisosBusqueda[i].descripcion){
                    listaPermisosBusqueda[i].descripcion = listaPermisosBusqueda[i].eventoByIdEvento.evento;
                }
            }
            yield put({ type: configuracionYSeguridadActions.GET_PERMISOS_BUSQUEDA_SUCCESS, listaPermisosBusqueda, listaPermisosBusquedaCantidad });
        }
        else{

            yield put({ type: configuracionYSeguridadActions.GET_PERMISOS_BUSQUEDA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.GET_PERMISOS_BUSQUEDA_FAILURE });
    }
}
export function* listaPermisosBusquedaSaga() {
    yield takeLatest(configuracionYSeguridadActions.GET_PERMISOS_BUSQUEDA_REQUEST, listaPermisosBusqueda);
}

//********************** Obtener lista de logs para Seguridad *****************************
function* listaLogs(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let usuario = '';
        let campo = 'field=' + action.ordenamiento.campo + '&';
        let orden = 'order=' + action.ordenamiento.orden + '&';
        if(action.formulario.usuario){
            usuario = 'user=' + action.formulario.usuario + '&';
        }
        let modulo = '';
        if(action.formulario.modulo){
            modulo = 'modulo=' + action.formulario.modulo + '&';
        }
        let fecha = '';
        if(action.formulario.fecha){
            fecha = 'fecha=' + action.formulario.fecha + '&';
        }
        
        const response = yield call(getLogsCall, token, page, usuario, modulo, fecha, campo, orden);
        console.log(response);

        if(response.status==200){
            const listaLogs = response.data.data;
            const listaLogsCantidad = response.data.cantidad;
            for(let i=0; i<listaLogs.length; i++){
                listaLogs[i].accion = listaLogs[i].accionByIdAccion.descripcion;
                listaLogs[i].fechaLog = listaLogs[i].fecha.split('T')[0];
                listaLogs[i].fechaLog = listaLogs[i].fechaLog.split('-').reverse().join('-');
                listaLogs[i].horaLog = listaLogs[i].fecha.split('T')[1].split('.')[0];
                listaLogs[i].modulo = listaLogs[i].moduloByIdModulo.nombre;
                listaLogs[i].usuario = listaLogs[i].usuarioByIdUsuario.username + ' ' + listaLogs[i].usuarioByIdUsuario.nombre + ' ' + listaLogs[i].usuarioByIdUsuario.aPaterno;
                // action.listaLogsInfinity.push(listaLogs[i]);
            }
            yield put({ type: configuracionYSeguridadActions.OBTENER_LOGS_SUCCESS, listaLogs, listaLogsCantidad, listaLogsInfinity: action.listaLogsInfinity, hasMore: listaLogs.length ? true : false });
        }
        else{

            yield put({ type: configuracionYSeguridadActions.OBTENER_LOGS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.OBTENER_LOGS_FAILURE });
    }
}
export function* listaLogsSaga() {
    yield takeLatest(configuracionYSeguridadActions.OBTENER_LOGS_REQUEST, listaLogs);
}

//********************** Lista de servicios por proveedor *****************************
function* serviciosPorProveedor(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idProveedor = action.idProveedor;

        const response  = yield call(serviciosPorProveedorCall, token, idProveedor);
        console.log(response);

        if(response.status==200){
            let serviciosPorProveedor = response.data;
            yield put({ type: configuracionYSeguridadActions.SERVICIO_POR_PROVEEDOR_SUCCESS, serviciosPorProveedor });
        }
        else{
            yield put({ type: configuracionYSeguridadActions.SERVICIO_POR_PROVEEDOR_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.SERVICIO_POR_PROVEEDOR_FAILURE });
    }
}
export function* serviciosPorProveedorSaga() {
    yield takeLatest(configuracionYSeguridadActions.SERVICIO_POR_PROVEEDOR_REQUEST, serviciosPorProveedor);
}


// ***************  SETTINGS  **************************
function* listaSettings(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(obtenerSettings, token);
        console.log('response');
        console.log(response);

        if(response.status===200){
            const listaSettings = response.data;
            yield put({ type: configuracionYSeguridadActions.GET_SETTINGS_SUCCESS, listaSettings });
        }
        else{
            yield put({ type: configuracionYSeguridadActions.GET_SETTINGS_FAILURE });
        }
    }
    catch (error) {
        yield put({ type: configuracionYSeguridadActions.GET_SETTINGS_FAILURE });
    }
}
export function* listaSettingsSaga() {
    yield takeLatest(configuracionYSeguridadActions.GET_SETTINGS_REQUEST, listaSettings);
}


function* editarSettings(action) {
   try {
    console.log(action);
    const token = sessionStorage.getItem('token');
    const idSetting = action.settingSeleccionado.idSetting;

       const data = {
           "idSetting": idSetting,
           "vigenciaLink": action.formulario.vigenciaLink,
           "numeroIntentos": action.formulario.numeroIntentos,
           "vigenciaPass": action.formulario.vigenciaPass,
           "recordarToken": action.formulario.recordarToken,
           "considerVigenciaPass": action.settingSeleccionado.considerVigenciaPass,
           "considerRecordarToken": action.formulario.considerRecordarToken,
           "numeroPass" : action.formulario.numeroPass,
       }
    
       console.log(data);
       const response = yield call(editarSettingsCall, token, data, idSetting);
       console.log(response);  

       if (response.status == 200) {

        const showModalEditarSettings = false;
        const settingSeleccionado = null;
        const showSuccessMsg = true;
        const textMessage = "La configuración se modificó de forma correcta";
        const formulario = {
            usuario: '',
            modulo: '',
            fecha: '',
        }
        const ordenamiento = {
            orden: 'DESC',
            campo: 'idBitacoraSistema',
        }

        yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
        yield put({ type: configuracionYSeguridadActions.EDITAR_SETTINGS_SUCCESS });
        yield put({ type: configuracionYSeguridadActions.GET_SETTINGS_REQUEST });
        yield put({ type: configuracionYSeguridadActions.SHOW_MODAL_EDITAR_SETTINGS, showModalEditarSettings, settingSeleccionado });
        yield put({ type: configuracionYSeguridadActions.OBTENER_LOGS_REQUEST, page: 1, formulario, ordenamiento });
    }
    else {
        const showErrorMsg = true;
        const textMessage = response.message;

        yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: configuracionYSeguridadActions.EDITAR_SETTINGS_FAILURE });
    } 

    } catch (error) {
        yield put({ type: configuracionYSeguridadActions.EDITAR_SETTINGS_FAILURE });
    }
}

export function* editarSettingsSaga(){
    yield takeLatest( configuracionYSeguridadActions.EDITAR_SETTINGS_REQUEST, editarSettings );
}


//********************** Obtener lista de alertas para Seguridad *****************************
function* listaAlertasBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let servicio = '';
        let modulo = '';
        let evento = '';
        if(action.busqueda.servicio){
            servicio = 'servicio=' + action.busqueda.servicio + '&';
        }
        if(action.busqueda.modulo){
            modulo = 'modulo=' + action.busqueda.modulo + '&';
        }
        if(action.busqueda.evento){
            evento = 'evento=' + action.busqueda.evento + '&';
        }
        console.log(servicio, modulo, evento);
        const response = yield call(listaAlertasBusquedaCall, token, servicio, modulo, evento, page);
        console.log(response);

        if(response.status==200){
            const listaAlertaBusqueda = response.data.data;
            for(let i=0; i<listaAlertaBusqueda.length; i++){
                if(listaAlertaBusqueda[i].moduloByIdModulo){
                    listaAlertaBusqueda[i].modulo = listaAlertaBusqueda[i].moduloByIdModulo.nombre;
                }
                else{
                    listaAlertaBusqueda[i].modulo = 'No tiene módulo';
                }

                if(listaAlertaBusqueda[i].servicioByIdServicio){
                    listaAlertaBusqueda[i].servicio = listaAlertaBusqueda[i].servicioByIdServicio.nombre;
                }
                else{
                    listaAlertaBusqueda[i].servicio = 'No tiene servicio';
                }

                if(listaAlertaBusqueda[i].eventoByIdEvento){
                    listaAlertaBusqueda[i].evento = listaAlertaBusqueda[i].eventoByIdEvento.evento;
                }
                else{
                    listaAlertaBusqueda[i].evento = 'No tiene evento';
                }
                listaAlertaBusqueda[i].usuarios = [];
                if(listaAlertaBusqueda[i].usuariosByIdAlerta){
                    for(let x=0; x<listaAlertaBusqueda[i].usuariosByIdAlerta.length; x++){
                        listaAlertaBusqueda[i].usuarios.push(listaAlertaBusqueda[i].usuariosByIdAlerta[x].idUsuario + '-' + listaAlertaBusqueda[i].usuariosByIdAlerta[x].nombre + ' ' + listaAlertaBusqueda[i].usuariosByIdAlerta[x].aPaterno )
                    }
                }

                listaAlertaBusqueda[i].rangoFechas = [moment(listaAlertaBusqueda[i].fechaInicio.split('T')[0], 'YYYY-MM-DD'),moment(listaAlertaBusqueda[i].fechaFin.split('T')[0], 'YYYY-MM-DD')];

                if(listaAlertaBusqueda[i].horaAlerta){
                    listaAlertaBusqueda[i].horario = moment(listaAlertaBusqueda[i].horaAlerta, 'HH:mm:ss');
                }

                if(listaAlertaBusqueda[i].fechaDetonacion){
                    listaAlertaBusqueda[i].fechaMes = moment(listaAlertaBusqueda[i].fechaDetonacion, 'YYYY-MM-DD');
                }
                
            }
            const listaAlertaBusquedaCantidad = response.data.cantidad;
            const formulario = {
                usuario: '',
                modulo: '',
                fecha: '',
            }
            const ordenamiento = {
                orden: 'DESC',
                campo: 'idBitacoraSistema',
            }

            yield put({ type: configuracionYSeguridadActions.LISTA_ALERTAS_BUSQUEDA_SUCCESS, listaAlertaBusqueda, listaAlertaBusquedaCantidad });
            yield put({ type: configuracionYSeguridadActions.OBTENER_LOGS_REQUEST, page: 1, formulario, ordenamiento });
        }
        else{

            yield put({ type: configuracionYSeguridadActions.LISTA_ALERTAS_BUSQUEDA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_ALERTAS_BUSQUEDA_FAILURE });
    }
}
export function* listaAlertasBusquedaSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_ALERTAS_BUSQUEDA_REQUEST, listaAlertasBusqueda);
}

//********************** Cambiar el estado del usuario Activo/inactivo *****************************
function* cambiarEstadoAlerta(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idAlerta = action.idAlerta;

        const response = yield call(cambiarEstadoAlertaCall, token, idAlerta);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const showSuccessMsg = true;
            const textMessage = "El estado de la alerta cambió de forma correcta";
            const busqueda = {
                servicio: '',
                modulo: '',
                evento: '',
            }

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.ESTADO_ALERTA_SUCCESS });
            yield put({ type: configuracionYSeguridadActions.LISTA_ALERTAS_BUSQUEDA_REQUEST, page, busqueda });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: configuracionYSeguridadActions.ESTADO_ALERTA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.ESTADO_ALERTA_FAILURE });
    }
}
export function* cambiarEstadoAlertaSaga() {
    yield takeLatest(configuracionYSeguridadActions.ESTADO_ALERTA_REQUEST, cambiarEstadoAlerta);
}

//********************** Obtener lista de dias para alertas en Seguridad *****************************
function* listaDíasAlertas(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        
        const response = yield call(listaDiasAlertaCall, token);
        console.log(response);

        if(response.status==200){
            const listaDiasAlerta = response.data;
            for(let i=0; i<listaDiasAlerta.length; i++){
                listaDiasAlerta[i].activo = false;
                listaDiasAlerta[i].type = 'default';
                if(listaDiasAlerta[i].diaSemana === 'DOMINGO'){
                    listaDiasAlerta[i].dia = 'D';
                }
                if(listaDiasAlerta[i].diaSemana === 'LUNES'){
                    listaDiasAlerta[i].dia = 'L';
                }
                if(listaDiasAlerta[i].diaSemana === 'MARTES'){
                    listaDiasAlerta[i].dia = 'M';
                }
                if(listaDiasAlerta[i].diaSemana === 'MIÉRCOLES'){
                    listaDiasAlerta[i].dia = 'X';
                }
                if(listaDiasAlerta[i].diaSemana === 'JUEVES'){
                    listaDiasAlerta[i].dia = 'J';
                }
                if(listaDiasAlerta[i].diaSemana === 'VIERNES'){
                    listaDiasAlerta[i].dia = 'V';
                }
                if(listaDiasAlerta[i].diaSemana === 'SÁBADO'){
                    listaDiasAlerta[i].dia = 'S';
                }
            }

            yield put({ type: configuracionYSeguridadActions.LISTA_DIAS_ALERTA_SUCCESS, listaDiasAlerta });
        }
        else{

            yield put({ type: configuracionYSeguridadActions.LISTA_DIAS_ALERTA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_DIAS_ALERTA_FAILURE });
    }
}
export function* listaDíasAlertasSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_DIAS_ALERTA_REQUEST, listaDíasAlertas);
}

//********************** Obtener lista de eventos por modulo para alertas en Seguridad *****************************
function* listaEventosPorModulo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idModulo = action.idModulo;

        const response = yield call(listaEventosPorModuloCall, token, idModulo);
        console.log(response);

        if(response.status==200){
            const listaEventoPorModulo = response.data;

            yield put({ type: configuracionYSeguridadActions.LISTA_EVENTO_POR_MODULO_SUCCESS, listaEventoPorModulo });
        }
        else{

            yield put({ type: configuracionYSeguridadActions.LISTA_EVENTO_POR_MODULO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_EVENTO_POR_MODULO_FAILURE });
    }
}
export function* listaEventosPorModuloSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_EVENTO_POR_MODULO_REQUEST, listaEventosPorModulo);
}

//********************** Crear nueva Alerta *****************************
function* nuevaAlerta(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let horaAlerta = null;

        if(action.formulario.horario){
            horaAlerta = action.formulario.horario.format('HH:mm:ss');
        }

        const usuarios = [];
        for(let i=0; i<action.formulario.usuarios.length; i++){
            usuarios.push(action.formulario.usuarios[i].split('-')[0]);
        }

        const dias = []; 
        let fechaDetonacion = null;
        if(action.formulario.periodicidad === 'Mensual'){
            fechaDetonacion = action.formulario.fechaDetonacion.format('YYYY-MM-DD');
        }
        else{
            for(let i=0; i<action.listaDiasAlerta.length; i++){
                if(action.listaDiasAlerta[i].activo === true){
                    let dia = {
                        "idDiaSemana": action.listaDiasAlerta[i].idDiaSemana,
                        "diaSemana": action.listaDiasAlerta[i].diaSemana,
                    }
                    dias.push(dia);
                }
            }
        }
        

        const data = {
            "alerta":{
                "idAlerta":null,
                "nombreAlerta": action.formulario.nombreAlerta,
                "idModulo": action.formulario.modulo,
                "idServicio": action.formulario.servicio,
                "idEvento": action.formulario.evento,
                "fechaInicio": action.formulario.rangoVigencia.split(',')[0],
                "fechaFin": action.formulario.rangoVigencia.split(',')[1],
                "horaAlerta": horaAlerta,
                "periodicidad": action.formulario.periodicidad,
                'fechaDetonacion': fechaDetonacion,
                "fechaAlta": null,
                "fechaActualizacion": null,
                "activo": true
            },
            "usuarios":usuarios,
            "dias": dias,
        }
        console.log(data);
        const response = yield call(nuevaAlertaCall, token, data);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const busqueda = action.busqueda;
            const showModalNuevaAlerta = false;
            const showSuccessMsg = true;
            const textMessage = "La alerta " + action.formulario.nombreAlerta + " se creó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'nuevaAlerta' });
            yield put({ type: configuracionYSeguridadActions.NUEVA_ALERTA_SUCCESS });
            yield put({ type: configuracionYSeguridadActions.LISTA_ALERTAS_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: configuracionYSeguridadActions.SHOW_MODAL_NUEVA_ALERTA, showModalNuevaAlerta });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: configuracionYSeguridadActions.NUEVA_ALERTA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.NUEVA_ALERTA_FAILURE });
    }
}
export function* nuevaAlertaSaga() {
    yield takeLatest(configuracionYSeguridadActions.NUEVA_ALERTA_REQUEST, nuevaAlerta);
}

//********************** Editar Alerta *****************************
function* editarAlerta(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let horaAlerta = null;
        const idAlerta = action.alertaSeleccionado.idAlerta;
        if(action.formulario.horario){
            horaAlerta = action.formulario.horario.format('HH:mm:ss');
        }

        const usuarios = [];
        for(let i=0; i<action.formulario.usuarios.length; i++){
            usuarios.push(action.formulario.usuarios[i].split('-')[0]);
        }

        const dias = [];
        let fechaDetonacion = null;
        if(action.formulario.periodicidad === 'Mensual'){
            fechaDetonacion = action.formulario.fechaMes.format('YYYY-MM-DD');
        }
        else{
            for(let i=0; i<action.listaDiasAlerta.length; i++){
                if(action.listaDiasAlerta[i].activo === true){
                    let dia = {
                        "idDiaSemana": action.listaDiasAlerta[i].idDiaSemana,
                        "diaSemana": action.listaDiasAlerta[i].diaSemana,
                    }
                    dias.push(dia);
                }
            }
        }

        const data = {
            "alerta":{
                "idAlerta": idAlerta,
                "nombreAlerta": action.formulario.nombreAlerta,
                "idModulo": action.alertaSeleccionado.idModulo,
                "idServicio": action.alertaSeleccionado.idServicio,
                "idEvento": action.alertaSeleccionado.idEvento,
                "fechaInicio": action.formulario.rangoFechas.split(',')[0],
                "fechaFin": action.formulario.rangoFechas.split(',')[1],
                "horaAlerta": horaAlerta,
                "periodicidad": action.formulario.periodicidad,
                'fechaDetonacion': fechaDetonacion,
                "fechaAlta": null,
                "fechaActualizacion": null,
                "activo": action.alertaSeleccionado.activo
            },
            "usuarios":usuarios,
            "dias": dias,
        }
        console.log(data);
        const response = yield call(editarAlertaCall, token, data, idAlerta);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const busqueda = action.busqueda;
            const showModalEditarAlerta = false;
            const showSuccessMsg = true;
            const textMessage = "La alerta " + action.formulario.nombreAlerta + " se editó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarAlerta' });
            yield put({ type: configuracionYSeguridadActions.EDITAR_ALERTA_SUCCESS });
            yield put({ type: configuracionYSeguridadActions.LISTA_ALERTAS_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: configuracionYSeguridadActions.SHOW_MODAL_EDITAR_ALERTA, showModalEditarAlerta });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: configuracionYSeguridadActions.EDITAR_ALERTA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.EDITAR_ALERTA_FAILURE });
    }
}
export function* editarAlertaSaga() {
    yield takeLatest(configuracionYSeguridadActions.EDITAR_ALERTA_REQUEST, editarAlerta);
}


//********************** Obtener lista de Tipos de Bitacoras con búsqueda *****************************
function* listaTiposBitacoraBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let param = '';
        
        if(action.busqueda.param){
            param = 'param=' + action.busqueda.param + '&';
        }
        
        const response = yield call(listaTiposBitacoraBusquedaCall, token, param, page);
        console.log(response);

        if(response.status==200){
            const listaTiposBitacoraBusqueda = response.data.data;
            const listaTiposBitacoraBusquedaCantidad = response.data.cantidad;

            // for(let i in listaTiposBitacoraBusqueda){
            //     listaTiposBitacoraBusqueda[i].servicio = listaTiposBitacoraBusqueda[i].servicioByIdServicio.nombre;
            //     listaTiposBitacoraBusqueda[i].servicioEditar = listaTiposBitacoraBusqueda[i].servicioByIdServicio.idServicio + ' - ' + listaTiposBitacoraBusqueda[i].servicioByIdServicio.nombre;
            //     listaTiposBitacoraBusqueda[i].rolEditar = listaTiposBitacoraBusqueda[i].rolByIdRol.idRol + ' - ' + listaTiposBitacoraBusqueda[i].rolByIdRol.descripcion;
            // }

            yield put({ type: configuracionYSeguridadActions.LISTA_TIPOS_BITACORA_BUSQUEDA_SUCCESS, listaTiposBitacoraBusqueda, listaTiposBitacoraBusquedaCantidad });
        }
        else{

            yield put({ type: configuracionYSeguridadActions.LISTA_TIPOS_BITACORA_BUSQUEDA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_TIPOS_BITACORA_BUSQUEDA_FAILURE });
    }
}
export function* listaTiposBitacoraBusquedaSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_TIPOS_BITACORA_BUSQUEDA_REQUEST, listaTiposBitacoraBusqueda);
}

//********************** Obtener lista de Tipos de Bitacoras con búsqueda *****************************
function* listaTiposBitacoraActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idServicio = '';
    
        if(action.idServicio){
            idServicio = 'idServicio=' + parseInt(action.idServicio)
        }
        
        const response = yield call(listaTiposBitacoraActivosCall, token, idServicio);
        console.log(response);

        if(response.status==200){
            const listaTiposBitacoraActivos = response.data;

            yield put({ type: configuracionYSeguridadActions.LISTA_TIPOS_BITACORA_ACTIVOS_SUCCESS, listaTiposBitacoraActivos });
        }
        else{

            yield put({ type: configuracionYSeguridadActions.LISTA_TIPOS_BITACORA_ACTIVOS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_TIPOS_BITACORA_ACTIVOS_FAILURE });
    }
}
export function* listaTiposBitacoraActivosSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_TIPOS_BITACORA_ACTIVOS_REQUEST, listaTiposBitacoraActivos);
}

//********************** Cambiar el estado del tipo de bitacora *****************************
function* cambiarEstadoTipoBitacora(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idTipoBitacora = action.idTipoBitacora;

        const response = yield call(cambiarEstadoTipoBitacoraCall, token, idTipoBitacora);
        console.log(response);

        if(response.status==200){
            const textMessage = "El estado del tipo de bitácora cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage });
            yield put({ type: configuracionYSeguridadActions.ESTADO_TIPO_BITACORA_SUCCESS });

            yield put({ type: configuracionYSeguridadActions.LISTA_TIPOS_BITACORA_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: configuracionYSeguridadActions.ESTADO_TIPO_BITACORA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.ESTADO_TIPO_BITACORA_FAILURE });
    }
}
export function* cambiarEstadoTipoBitacoraSaga() {
    yield takeLatest(configuracionYSeguridadActions.ESTADO_TIPO_BITACORA_REQUEST, cambiarEstadoTipoBitacora);
}

//********************** Crear nuevo Tipo de Bitácora *****************************
function* nuevoTipoBitacora(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idTipoBitacora": null,
            "descripcion": action.formulario.descripcion,
            'tipoBitacora': action.formulario.tipoBitacora,
            // "idServicio": parseInt(action.formulario.idServicio),
            // "idRol": parseInt(action.formulario.idRol),
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
        }
        console.log(data);
        const response = yield call(nuevoTipoBitacoraCall, token, data);
        console.log(response);

        if (response.status == 200) {
            const textMessage = "El tipo de bitácora " + action.formulario.tipoBitacora + " se creó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'nuevoTipoBitacora' });
            yield put({ type: configuracionYSeguridadActions.NUEVO_TIPO_BITACORA_SUCCESS });

            yield put({ type: configuracionYSeguridadActions.LISTA_TIPOS_BITACORA_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda });
            yield put({ type: configuracionYSeguridadActions.MODAL_NUEVO_TIPO_BITACORA, showModalNuevaAlerta: false, page: action.page, busqueda: action.busqueda });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.NUEVO_TIPO_BITACORA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.NUEVO_TIPO_BITACORA_FAILURE });
    }
}
export function* nuevoTipoBitacoraSaga() {
    yield takeLatest(configuracionYSeguridadActions.NUEVO_TIPO_BITACORA_REQUEST, nuevoTipoBitacora);
}

//********************** Editar Tipo Bitácora *****************************
function* editarTipoBitacora(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idTipoBitacora = action.tipoBitacoraSeleccionada.idTipoBitacora;

        const data = {
            "idTipoBitacora": idTipoBitacora,
            "descripcion": action.formulario.descripcion,
            'tipoBitacora': action.formulario.tipoBitacora,
            // "idServicio": parseInt(action.formulario.servicioEditar),
            // "idRol": parseInt(action.formulario.rolEditar),
            "fechaAlta": action.tipoBitacoraSeleccionada.fechaAlta,
            "fechaActualizacion": action.tipoBitacoraSeleccionada.fechaActualizacion,
            "activo": action.tipoBitacoraSeleccionada.activo,
        }
        console.log(data);
        const response = yield call(editarTipoBitacoraCall, token, data, idTipoBitacora);
        console.log(response);

        if (response.status == 200) {
            const textMessage = "El tipo de bitácora " + action.formulario.tipoBitacora + " se editó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarTipoBitacora' });
            yield put({ type: configuracionYSeguridadActions.EDITAR_TIPO_BITACORA_SUCCESS });

            yield put({ type: configuracionYSeguridadActions.LISTA_TIPOS_BITACORA_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda });
            yield put({ type: configuracionYSeguridadActions.MODAL_EDITAR_TIPO_BITACORA, showModalEditarAlerta: false, page: action.page, busqueda: action.busqueda });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: configuracionYSeguridadActions.EDITAR_TIPO_BITACORA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.EDITAR_TIPO_BITACORA_FAILURE });
    }
}
export function* editarTipoBitacoraSaga() {
    yield takeLatest(configuracionYSeguridadActions.EDITAR_TIPO_BITACORA_REQUEST, editarTipoBitacora);
}

// Obtener tiposBitacoras por Módulo
function* listaTiposBitacorasByidModulo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idsModulos = '';

        for(let i in action.idsModulos){
            if(i == 0){
                idsModulos = action.idsModulos[i].split(' - ')[0];
            } else {
                idsModulos += ',' + action.idsModulos[i].split(' - ')[0];
            }
        }

        const response = yield call(listaTiposBitacoraByIdModuloCall, token, idsModulos);
        console.log(response);

        if(response.status==200){
            const listaTipoBitacoraByIdModulo = response.data;

            yield put({ type: configuracionYSeguridadActions.LISTA_TIPO_BITACORAS_BY_ID_MODULO_SUCCESS, listaTipoBitacoraByIdModulo });

            if(action.registroReporteSeleccionado){
                action.registroReporteSeleccionado.idsTiposBitacoras = [];
                for(let y in listaTipoBitacoraByIdModulo){
                    for(let j in action.registroReporteSeleccionado.formulario.idsTiposBitacoras){
                        if(listaTipoBitacoraByIdModulo[y].idTipoBitacora === parseInt(action.registroReporteSeleccionado.formulario.idsTiposBitacoras[j])){
                            action.registroReporteSeleccionado.idsTiposBitacoras.push(listaTipoBitacoraByIdModulo[y].idTipoBitacora + ' - ' + listaTipoBitacoraByIdModulo[y].tipoBitacora);
                        }
                    }
                    
                }
            }

            if(action.page){
                yield put({ type: configuracionYSeguridadActions.MODAL_EDITAR_REGISTRO_BITACORA, showModalEditarRegistro: true, registroReporteSeleccionado: action.registroReporteSeleccionado, page: action.page, personal: action.personal })
            }
        }
        else{

            yield put({ type: configuracionYSeguridadActions.LISTA_TIPO_BITACORAS_BY_ID_MODULO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_TIPO_BITACORAS_BY_ID_MODULO_FAILURE });
    }
}
export function* listaTiposBitacorasByidModuloSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_TIPO_BITACORAS_BY_ID_MODULO_REQUEST, listaTiposBitacorasByidModulo);
}

//********************** Obtener lista catálogo de Bitacoras con búsqueda *****************************
function* listaBitacoraBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let param = '';
        let idServicios = '';
        let fecha = '';

        let modulo = sessionStorage.getItem('vistaDashboard');
        let idModulo = null;

        if(modulo == 'Configuración y seguridad' || modulo == 'Configuración y Seguridad'){
            idModulo = 'idModulo=' + 1 + '&';
        } else if (modulo == 'Información del Proyecto' || modulo == 'Información del proyecto'){
            idModulo = 'idModulo=' + 2 + '&';
        } else if (modulo == 'Información operativa' || modulo == 'Información Operativa'){
            idModulo = 'idModulo=' + 3 + '&';
        } else if (modulo == 'Base de conocimiento' || modulo == 'Base de Conocimiento'){
            idModulo = 'idModulo=' + 4 + '&';
        } else if (modulo == 'Almacenes' || modulo == 'Almacen'){
            idModulo = 'idModulo=' + 5 + '&';
        } else if (modulo == 'Catálogos generales' || modulo == 'Catálogos Generales'){
            idModulo = 'idModulo=' + 6 + '&';
        } else if (modulo == 'Recursos humanos' || modulo == 'Recursos Humanos'){
            idModulo = 'idModulo=' + 7 + '&';
        } else if (modulo == 'Servicios variables' || modulo == 'Servicios Variables'){
            idModulo = 'idModulo=' + 8 + '&';
        } else if (modulo == 'Solicitud de servicios' || modulo == 'Solicitud de Servicios'){
            idModulo = 'idModulo=' + 9 + '&';
        } else if (modulo == 'Servicios planificados' || modulo == 'Servicios Planificados'){
            idModulo = 'idModulo=' + 10 + '&';
        } else if (modulo == 'Servicios públicos' || modulo == 'Servicios Públicos'){
            idModulo = 'idModulo=' + 11 + '&';
        } else if (modulo == 'Gestión de calidad' || modulo == 'Gestión de Calidad'){
            idModulo = 'idModulo=' + 12 + '&';
        } else if (modulo == 'Evaluación de la gestión' || modulo == 'Evaluación de la Gestión'){
            idModulo = 'idModulo=' + 13 + '&';
        } else if (modulo == 'Monitoreo integral' || modulo == 'Monitoreo Integral'){
            idModulo = 'idModulo=' + 14 + '&';
        } else if (modulo == 'Mecanismos de pago' || modulo == 'Mecanismos de Pago'){
            idModulo = 'idModulo=' + 15 + '&';
        } else if (modulo == 'RMDP'){
            idModulo = 'idModulo=' + 16 + '&';
        } 

        
        if(action.busqueda.param){
            param = 'param=' + action.busqueda.param + '&';
        }
        if(action.listaServiciosActivosF){
            let permisosUsuario = JSON.parse(sessionStorage.getItem('permisosUsuario'));
            if(!permisosUsuario.especialBitácora){
                let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
                const listaServiciosUsuario = [];
                if(userInfo[0].serviciosByIdUsuario){
                    for(let i in userInfo[0].serviciosByIdUsuario){
                        listaServiciosUsuario.push(userInfo[0].serviciosByIdUsuario[i].idServicio);
                    }
                }
                idServicios = 'idsServicios=' + listaServiciosUsuario + '&';
            }
        }
        else{
            if(action.busqueda.idServicio[0]){
                idServicios = 'idsServicios=' + action.busqueda.idServicio + '&';
            }
        }
        
        if(action.busqueda.fecha){
            fecha = 'fecha=' + action.busqueda.fecha + '&';
        }
        
        const response = yield call(listaBitacorasBusquedaCall, token, param, idServicios, fecha, page, idModulo);
        console.log(response);

        if(response.status==200){
            const listaBitacorasBusqueda = response.data.data;
            const listaBitacorasBusquedaCantidad = response.data.cantidad;

            for(let i in listaBitacorasBusqueda){
                listaBitacorasBusqueda[i].servicio = listaBitacorasBusqueda[i].servicioByIdServicio.nombre;
                listaBitacorasBusqueda[i].servicioEditar = listaBitacorasBusqueda[i].servicioByIdServicio.idServicio + ' - ' + listaBitacorasBusqueda[i].servicioByIdServicio.nombre;
                listaBitacorasBusqueda[i].fechaAltaGrid = listaBitacorasBusqueda[i].fechaAlta.split('T')[0].split('-').reverse().join('-') + ' ' + listaBitacorasBusqueda[i].fechaAlta.split('T')[1].split(':')[0] + ':' + listaBitacorasBusqueda[i].fechaAlta.split('T')[1].split(':')[1];
                listaBitacorasBusqueda[i].tipoBitacora = listaBitacorasBusqueda[i].tipoBitacoraByIdTipoBitacora.tipoBitacora;
                listaBitacorasBusqueda[i].descripcion = listaBitacorasBusqueda[i].tipoBitacoraByIdTipoBitacora.descripcion;
                listaBitacorasBusqueda[i].tipoBitacoraEditar = listaBitacorasBusqueda[i].tipoBitacoraByIdTipoBitacora.idTipoBitacora + ' - ' + listaBitacorasBusqueda[i].tipoBitacoraByIdTipoBitacora.tipoBitacora;
                if(listaBitacorasBusqueda[i].fecha){
                    listaBitacorasBusqueda[i].fechaHora = moment(listaBitacorasBusqueda[i].fecha.split('T')[0] + 'T' + listaBitacorasBusqueda[i].hora);
                }
                if(listaBitacorasBusqueda[i].bitacoraModuloByIdBitacoraModulo){
                    listaBitacorasBusqueda[i].bitacoraModuloEditar = listaBitacorasBusqueda[i].bitacoraModuloByIdBitacoraModulo.idBitacoraModulo + ' - ' + listaBitacorasBusqueda[i].bitacoraModuloByIdBitacoraModulo.nombre
                }
            }

            yield put({ type: configuracionYSeguridadActions.LISTA_BITACORAS_BUSQUEDA_SUCCESS, listaBitacorasBusqueda, listaBitacorasBusquedaCantidad });
        }
        else{

            yield put({ type: configuracionYSeguridadActions.LISTA_BITACORAS_BUSQUEDA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_BITACORAS_BUSQUEDA_FAILURE });
    }
}
export function* listaBitacoraBusquedaSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_BITACORAS_BUSQUEDA_REQUEST, listaBitacoraBusqueda);
}

//********************** Cambiar el estado de la bitacora *****************************
function* cambiarEstadoBitacora(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idBitacora = action.idBitacora;

        const response = yield call(cambiarEstadoBitacoraCall, token, idBitacora);
        console.log(response);

        if(response.status==200){
            const textMessage = "El estado de la bitácora cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage });
            yield put({ type: configuracionYSeguridadActions.ESTADO_BITACORA_SUCCESS });

            yield put({ type: configuracionYSeguridadActions.LISTA_BITACORAS_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: configuracionYSeguridadActions.ESTADO_BITACORA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.ESTADO_BITACORA_FAILURE });
    }
}
export function* cambiarEstadoBitacoraSaga() {
    yield takeLatest(configuracionYSeguridadActions.ESTADO_BITACORA_REQUEST, cambiarEstadoBitacora);
}

//********************** Crear nueva Bitácora *****************************
function* nuevaBitacora(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idBitacora": null,
            "asunto": action.formulario.asunto,
            // "idServicio": parseInt(action.formulario.idServicio),
            "idBitacoraModulo": parseInt(action.formulario.idBitacoraModulo),
            "observaciones": action.formulario.observaciones,
            "fecha": action.formulario.fechaHora ? action.formulario.fechaHora.split(' ')[0] : null,
            "hora": action.formulario.fechaHora ? action.formulario.fechaHora.split(' ')[1] + ':00' : null,
            "accion": action.formulario.accion ? action.formulario.accion : '',
            "conclusiones": action.formulario.conclusiones,
            "responsableReg": action.formulario.responsableReg,
            "responsableSer": action.formulario.responsableSer,
            "activo": true,
            "rutaEvidencia": "",
            "fechaAlta": null,
            "fechaActualizacion": null
        }
        console.log(data);
        const formData = new FormData();
            
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        if(action.formulario.evidencia) {
            formData.append('file', action.formulario.evidencia.file.originFileObj);
        }

        const response = yield call(nuevaBitacoraCall, token, formData);
        console.log(response);

        if (response.status == 200) {
            const textMessage = "La bitácora " + action.formulario.asunto + " se creó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'nuevaBitacora' });
            yield put({ type: configuracionYSeguridadActions.NUEVA_BITACORA_SUCCESS });

            yield put({ type: configuracionYSeguridadActions.LISTA_BITACORAS_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda });
            yield put({ type: configuracionYSeguridadActions.MODAL_NUEVA_BITACORA, showModalNuevaAlerta: false, page: action.page, busqueda: action.busqueda });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.NUEVA_BITACORA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.NUEVA_BITACORA_FAILURE });
    }
}
export function* nuevaBitacoraSaga() {
    yield takeLatest(configuracionYSeguridadActions.NUEVA_BITACORA_REQUEST, nuevaBitacora);
}

//********************** Editar Bitácora *****************************
function* editarBitacora(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idBitacora = action.bitacoraSeleccionada.idBitacora;

        const data = {
            "idBitacora": idBitacora,
            "asunto": action.formulario.asunto,
            // "idServicio": parseInt(action.formulario.servicioEditar),
            "idBitacoraModulo": parseInt(action.formulario.bitacoraModuloEditar),
            "observaciones": action.formulario.observaciones,
            "fecha": action.formulario.fechaHora ? action.formulario.fechaHora.split(' ')[0] : null,
            "hora": action.formulario.fechaHora ? action.formulario.fechaHora.split(' ')[1] + ':00' : null,
            "accion": action.formulario.accion ? action.formulario.accion : '',
            "conclusiones": action.formulario.conclusiones,
            "activo": action.bitacoraSeleccionada.activo,
            "rutaEvidencia": action.bitacoraSeleccionada.rutaEvidencia,
            "fechaAlta": action.bitacoraSeleccionada.fechaAlta,
            "fechaActualizacion": action.bitacoraSeleccionada.fechaActualizacion,
            "responsableReg": action.formulario.responsableReg,
            "responsableSer": action.formulario.responsableSer,
        }
        console.log(data);

        const formData = new FormData();
            
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        if(action.formulario.evidencia) {
            formData.append('file', action.formulario.evidencia.file.originFileObj);
        }

        const response = yield call(editarBitacoraCall, token, idBitacora, formData);
        console.log(response);

        if (response.status == 200) {
            const textMessage = "La bitácora " + action.formulario.asunto + " se editó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarBitacora' });
            yield put({ type: configuracionYSeguridadActions.EDITAR_BITACORA_SUCCESS });

            yield put({ type: configuracionYSeguridadActions.LISTA_BITACORAS_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda });
            yield put({ type: configuracionYSeguridadActions.MODAL_EDITAR_BITACORA, showModalEditarAlerta: false, page: action.page, busqueda: action.busqueda });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: configuracionYSeguridadActions.EDITAR_BITACORA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.EDITAR_BITACORA_FAILURE });
    }
}
export function* editarBitacoraSaga() {
    yield takeLatest(configuracionYSeguridadActions.EDITAR_BITACORA_REQUEST, editarBitacora);
}

//Módulo Bitácora
function* listaModulosBitacora(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idsTiposBitacoras": action.busqueda.idsTiposBitacoras,
            "nombre": action.busqueda.nombre,
            "field": action.busqueda.field,
            "order": action.busqueda.order,
            "page": action.page -1,
            "offset": 10
        }
        
        const response = yield call(listaModulosBitacoraCall, token, data);
        console.log(response);

        if(response.status==200){
            const listaModulosBitacora = response.data.data;
            const listaModulosBitacoraCantidad = response.data.cantidad;

            for(let i in listaModulosBitacora){
                listaModulosBitacora[i].fechaAltaGrid = listaModulosBitacora[i].fechaAlta.split('-').reverse().join('-');

                if(listaModulosBitacora[i].tipoBitacoraByIdTipoBitacora){
                    listaModulosBitacora[i].tipoBitacora = listaModulosBitacora[i].tipoBitacoraByIdTipoBitacora[0].tipoBitacora;
                }

                if(listaModulosBitacora[i].servicioByIdServicio){
                    listaModulosBitacora[i].servicio = listaModulosBitacora[i].servicioByIdServicio[0].nombre;
                }

                if(listaModulosBitacora[i].partidaByIdPartida){
                    listaModulosBitacora[i].partida = listaModulosBitacora[i].partidaByIdPartida[0].nombre;
                } else {
                    listaModulosBitacora[i].partida = '-';
                }

                if(listaModulosBitacora[i].moduloByIdModulo){
                    listaModulosBitacora[i].modulo = listaModulosBitacora[i].moduloByIdModulo[0].nombre;
                }
            }

            yield put({ type: configuracionYSeguridadActions.LISTA_BITACORAS_MODULOS_BUSQUEDA_SUCCESS, listaModulosBitacora, listaModulosBitacoraCantidad });
        }
        else{

            yield put({ type: configuracionYSeguridadActions.LISTA_BITACORAS_MODULOS_BUSQUEDA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_BITACORAS_MODULOS_BUSQUEDA_FAILURE });
    }
}
export function* listaModulosBitacoraSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_BITACORAS_MODULOS_BUSQUEDA_REQUEST, listaModulosBitacora);
}

function* nuevoModuloBitacora(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idBitacoraModulo": null,
            "nombre": action.formulario.nombre,
            "idTipoBitacora": parseInt(action.formulario.idTipoBitacora),
            // "fechaAlta": action.formulario.fechaHora ? action.formulario.fechaHora.split(' ')[0] : null,
            // "hora": action.formulario.fechaHora ? action.formulario.fechaHora.split(' ')[1] + ':00' : null,
            "idModulo": parseInt(action.formulario.idModulo),
            "idServicio": parseInt(action.formulario.idServicio),
            "idPartida":  action.formulario.idPartida ? parseInt(action.formulario.idPartida) : null,
            "fechaAlta": null,
        }
        console.log(data);

        const response = yield call(nuevoModuloBitacoraCall, token, data);
        console.log(response);

        if (response.status == 200) {
            const textMessage = "El módulo de bitácora se creó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'nuevoModuloBitacora' });
            yield put({ type: configuracionYSeguridadActions.NUEVO_MODULO_BITACORA_SUCCESS });

            yield put({ type: configuracionYSeguridadActions.LISTA_BITACORAS_MODULOS_BUSQUEDA_REQUEST, page: action.page});
            yield put({ type: configuracionYSeguridadActions.SHOW_MODAL_NUEVO_MODULO_BITACORA, showModalNuevoModuloBitacora: false, page: action.page });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.NUEVO_MODULO_BITACORA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.NUEVO_MODULO_BITACORA_FAILURE });
    }
}
export function* nuevoModuloBitacoraSaga() {
    yield takeLatest(configuracionYSeguridadActions.NUEVO_MODULO_BITACORA_REQUEST, nuevoModuloBitacora);
}

function* listaModulosBitacoraByIdModulo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let modulo = sessionStorage.getItem('vistaDashboard');
        let idModulo = null;

        if(modulo == 'Configuración y seguridad' || modulo == 'Configuración y Seguridad'){
            idModulo = 1;
        } else if (modulo == 'Información del Proyecto' || modulo == 'Información del proyecto'){
            idModulo = 2;
        } else if (modulo == 'Información operativa' || modulo == 'Información Operativa'){
            idModulo = 3;
        } else if (modulo == 'Base de conocimiento' || modulo == 'Base de Conocimiento'){
            idModulo = 4;
        } else if (modulo == 'Almacenes' || modulo == 'Almacen'){
            idModulo = 5;
        } else if (modulo == 'Catálogos generales' || modulo == 'Catálogos Generales'){
            idModulo = 6;
        } else if (modulo == 'Recursos humanos' || modulo == 'Recursos Humanos'){
            idModulo = 7;
        } else if (modulo == 'Servicios variables' || modulo == 'Servicios Variables'){
            idModulo = 8;
        } else if (modulo == 'Solicitud de servicios' || modulo == 'Solicitud de Servicios'){
            idModulo = 9;
        } else if (modulo == 'Servicios planificados' || modulo == 'Servicios Planificados'){
            idModulo = 10;
        } else if (modulo == 'Servicios públicos' || modulo == 'Servicios Públicos'){
            idModulo = 11;
        } else if (modulo == 'Gestión de calidad' || modulo == 'Gestión de Calidad'){
            idModulo = 12;
        } else if (modulo == 'Evaluación de la gestión' || modulo == 'Evaluación de la Gestión'){
            idModulo = 13;
        } else if (modulo == 'Monitoreo integral' || modulo == 'Monitoreo Integral'){
            idModulo = 14;
        } else if (modulo == 'Mecanismos de pago' || modulo == 'Mecanismos de Pago'){
            idModulo = 15;
        } else if (modulo == 'RMDP'){
            idModulo = 16;
        } 

        const response = yield call(listaModulosBitacoraByIdModuloCall, token, idModulo);
        console.log(response);

        if(response.status==200){
            const listaModulosBitacoraByIdModulo = response.data;

            yield put({ type: configuracionYSeguridadActions.LISTA_MODOULOS_BITACORAS_BY_IDMODULO_SUCCESS, listaModulosBitacoraByIdModulo });
        }
        else{

            yield put({ type: configuracionYSeguridadActions.LISTA_MODOULOS_BITACORAS_BY_IDMODULO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_MODOULOS_BITACORAS_BY_IDMODULO_FAILURE });
    }
}
export function* listaModulosBitacoraByIdModuloSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_MODOULOS_BITACORAS_BY_IDMODULO_REQUEST, listaModulosBitacoraByIdModulo);
}

function* listaPartidasByIdsServicios(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let data = [];
        let idServiciosSeleccionados = [];

        if(action.idsServicios){
            for(let i = 0; i < action.idsServicios.length; i++){
                const servicio = {
                    "idServicio": parseInt(action.idsServicios[i]),
                }
                data.push(servicio);
                idServiciosSeleccionados.push(parseInt(action.idsServicios[i]));
            }
        }

        const response = yield call(listaPartidasByIdsServiciosCall, token, idServiciosSeleccionados);
        console.log('response', response);
        if (response.status === 200) {
            let listaPartidasByIdsServicios = response.data;
            
            yield put({ type: configuracionYSeguridadActions.LISTA_PARTIDAS_BY_IDSSERVICIOS_SUCCESS, listaPartidasByIdsServicios });

            // if(action.registroReporteSeleccionado){
            //     action.registroReporteSeleccionado.idsPartidas = [];
            //     for(let y in listaPartidasByIdsServicios){
            //         for(let j in action.registroReporteSeleccionado.formulario.idsPartidas){
            //             if(listaPartidasByIdsServicios[y].idPartida === parseInt(action.registroReporteSeleccionado.formulario.idsPartidas[j])){
            //                 action.registroReporteSeleccionado.idsPartidas.push(listaPartidasByIdsServicios[y].idPartida + ' - ' + listaPartidasByIdsServicios[y].nombre);
            //             }
            //         }
                    
            //     }

            //     console.log('action.registroReporteSeleccionado', action.registroReporteSeleccionado)

            //     yield put({ type: configuracionYSeguridadActions.MODAL_EDITAR_REGISTRO_BITACORA, showModalEditarRegistro: true, registroReporteSeleccionado: action.registroReporteSeleccionado, page: action.page, personal: action.personal })
            // }

        } else {
            yield put({ type: configuracionYSeguridadActions.LISTA_PARTIDAS_BY_IDSSERVICIOS_FAILURE});
        }
    } catch (error) {
        yield put({ type: configuracionYSeguridadActions.LISTA_PARTIDAS_BY_IDSSERVICIOS_FAILURE});
    }
}
export function* listaPartidasByIdsServiciosSaga(){
    yield takeLatest(configuracionYSeguridadActions.LISTA_PARTIDAS_BY_IDSSERVICIOS_REQUEST, listaPartidasByIdsServicios);
}

// Reporte Dinámico
function* listaReporteDinamicoBitacora(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idsTiposBitacoras = [];
        let idsModulos = [];
        let idsServicios = [];
        let idsPartidas = [];
        

        if(action.formulario.idsTiposBitacoras){
            for(let i in action.formulario.idsTiposBitacoras){
                idsTiposBitacoras.push(action.formulario.idsTiposBitacoras[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsModulos){
            for(let i in action.formulario.idsModulos){
                idsModulos.push(action.formulario.idsModulos[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsServicios){
            for(let i in action.formulario.idsServicios){
                idsServicios.push(action.formulario.idsServicios[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsPartidas){
            for(let i in action.formulario.idsPartidas){
                idsPartidas.push(action.formulario.idsPartidas[i].split(' - ')[0]);
            }
        }

        const data = {
            "idsTiposBitacoras": action.formulario.idsTiposBitacoras ? idsTiposBitacoras : null,
            "idsModulos": action.formulario.idsModulos ? idsModulos : null,
            "idsPartidas": action.formulario.idsPartidas ? idsPartidas : null,
            "idsServicios": action.formulario.idsServicios ? idsServicios : null,
            "fechaInicio": action.formulario.fecha ? action.formulario.fecha[0].format('YYYY-MM-DD') : null,
            "fechaFin": action.formulario.fecha ? action.formulario.fecha[1].format('YYYY-MM-DD') : null,
            "page": (action.page - 1) * 10,
            "offset": 10,
            "order": action.order.order,
            "field": action.order.field,
            "responsableSer": action.formulario.responsableSer ? action.formulario.responsableSer : null,
            "responsableReg": action.formulario.responsableReg ? action.formulario.responsableReg : null,
            "nombre": action.formulario.nombre ? action.formulario.nombre : null,
            "tipoBitacoraC": action.checkboxData.tipoBitacoraC ? action.checkboxData.tipoBitacoraC : false,
            "descripcionC": action.checkboxData.descripcionC ? action.checkboxData.descripcionC : false,
            "nombreC": action.checkboxData.nombreC ? action.checkboxData.nombreC : false,
            "moduloC": action.checkboxData.moduloC ? action.checkboxData.moduloC : false,
            "servicioC": action.checkboxData.servicioC ? action.checkboxData.servicioC : false,
            "partidaC": action.checkboxData.partidaC ? action.checkboxData.partidaC : false,
            "asuntoC": action.checkboxData.asuntoC ? action.checkboxData.asuntoC : false,
            "observacionesC": action.checkboxData.observacionesC ? action.checkboxData.observacionesC : false,
            "fechaC": action.checkboxData.fechaC ? action.checkboxData.fechaC : false,
            "horaC":action.checkboxData.horaC ? action.checkboxData.horaC : false,
            "accionC":action.checkboxData.accionC ? action.checkboxData.accionC : false,
            "responsableSerC":action.checkboxData.responsableSerC ? action.checkboxData.responsableSerC : false,
            "responsableRegC":action.checkboxData.responsableRegC ? action.checkboxData.responsableRegC : false,
            "conclusionesC":action.checkboxData.conclusionesC ? action.checkboxData.conclusionesC : false,
            "agrupamiento":action.formulario.agrupamiento ? action.formulario.agrupamiento : null
        }
        console.log(`data`, data);

        const response = yield call(reporteDinamicoBitacoraCall, token, data)
        console.log(response)

        if (response.status === 200) {
            const listaReporteDinamicoBitacora = response.data.data;
            const listaReporteDinamicoCantidadBitacora= response.data.cantidad;

            for(let i in listaReporteDinamicoBitacora){
                if(!listaReporteDinamicoBitacora[i].partida){
                    listaReporteDinamicoBitacora[i].partida = '-'
                }

                if(listaReporteDinamicoBitacora[i].fecha){
                    listaReporteDinamicoBitacora[i].fechaTable = listaReporteDinamicoBitacora[i].fecha.split('-')[2] + '-' + listaReporteDinamicoBitacora[i].fecha.split('-')[1] + '-' + listaReporteDinamicoBitacora[i].fecha.split('-')[0];
                }
            }

            yield put({ type: configuracionYSeguridadActions.LISTA_REPORTE_DINAMICO_BITACORA_BUSQUEDA_SUCCESS, listaReporteDinamicoBitacora, listaReporteDinamicoCantidadBitacora })
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: configuracionYSeguridadActions.LISTA_REPORTE_DINAMICO_BITACORA_BUSQUEDA_FAILURE});
        }
    } catch (error) {
        yield put({type: configuracionYSeguridadActions.LISTA_REPORTE_DINAMICO_BITACORA_BUSQUEDA_FAILURE});
    }
}
export function* listaReporteDinamicoBitacoraSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_REPORTE_DINAMICO_BITACORA_BUSQUEDA_REQUEST, listaReporteDinamicoBitacora)
}

function* exportarReporteDinamicoBitacoraPDF(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idsTiposBitacoras = [];
        let idsModulos = [];
        let idsServicios = [];
        let idsPartidas = [];  
        let titulo = action.titulo ? action.titulo : '';

        if(action.formulario.idsTiposBitacoras){
            for(let i in action.formulario.idsTiposBitacoras){
                idsTiposBitacoras.push(action.formulario.idsTiposBitacoras[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsModulos){
            for(let i in action.formulario.idsModulos){
                idsModulos.push(action.formulario.idsModulos[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsServicios){
            for(let i in action.formulario.idsServicios){
                idsServicios.push(action.formulario.idsServicios[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsPartidas){
            for(let i in action.formulario.idsPartidas){
                idsPartidas.push(action.formulario.idsPartidas[i].split(' - ')[0]);
            }
        }

        const data = {
            "idsTiposBitacoras": action.formulario.idsTiposBitacoras ? idsTiposBitacoras : null,
            "idsModulos": action.formulario.idsModulos ? idsModulos : null,
            "idsPartidas": action.formulario.idsPartidas ? idsPartidas : null,
            "idsServicios": action.formulario.idsServicios ? idsServicios : null,
            "fechaInicio": action.formulario.fecha ? action.formulario.fecha[0].format('YYYY-MM-DD') : null,
            "fechaFin": action.formulario.fecha ? action.formulario.fecha[1].format('YYYY-MM-DD') : null,
            "page": 0,
            "offset": 10,
            "order": action.order.order,
            "field": action.order.field,
            "responsable": action.formulario.responsable ? action.formulario.responsable : null,
            "nombre": action.formulario.nombre ? action.formulario.nombre : null,
            "tipoBitacoraC": action.checkboxData.tipoBitacoraC ? action.checkboxData.tipoBitacoraC : false,
            "descripcionC": action.checkboxData.descripcionC ? action.checkboxData.descripcionC : false,
            "nombreC": action.checkboxData.nombreC ? action.checkboxData.nombreC : false,
            "moduloC": action.checkboxData.moduloC ? action.checkboxData.moduloC : false,
            "servicioC": action.checkboxData.servicioC ? action.checkboxData.servicioC : false,
            "partidaC": action.checkboxData.partidaC ? action.checkboxData.partidaC : false,
            "asuntoC": action.checkboxData.asuntoC ? action.checkboxData.asuntoC : false,
            "observacionesC": action.checkboxData.observacionesC ? action.checkboxData.observacionesC : false,
            "fechaC": action.checkboxData.fechaC ? action.checkboxData.fechaC : false,
            "horaC":action.checkboxData.horaC ? action.checkboxData.horaC : false,
            "accionC":action.checkboxData.accionC ? action.checkboxData.accionC : false,
            "responsableSerC":action.checkboxData.responsableSerC ? action.checkboxData.responsableSerC : false,
            "responsableRegC":action.checkboxData.responsableRegC ? action.checkboxData.responsableRegC : false,
            "conclusionesC":action.checkboxData.conclusionesC ? action.checkboxData.conclusionesC : false,
            "agrupamiento":action.formulario.agrupamiento ? action.formulario.agrupamiento : null
        }
        console.log(`data`, data);

        const response = yield call(reporteDinamicoBitacoraPDFCall, token, data, titulo)
        console.log(response)

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const titulo = action.tituloDocumento ? action.tituloDocumento : 'ReporeBitacora_'
            link.download = titulo + + new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({type: configuracionYSeguridadActions.PDF_REPORTE_DINAMICO_BITACORA_SUCCESS})

            yield put({
                type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG,
                showSuccessMsg: true,
                textMessage: 'El archivo se descargó de forma correcta'
            });

        } else {
            const showErrorMsg = true;
            const textMessage = 'No se pudo descargar el PDF con las columnas y filtros seleccionados';

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: configuracionYSeguridadActions.PDF_REPORTE_DINAMICO_BITACORA_FAILURE});
        }
    } catch (error) {
        yield put({type: configuracionYSeguridadActions.PDF_REPORTE_DINAMICO_BITACORA_FAILURE});
    }
}
export function* exportarReporteDinamicoBitacoraPDFSaga() {
    yield takeLatest(configuracionYSeguridadActions.PDF_REPORTE_DINAMICO_BITACORA_REQUEST, exportarReporteDinamicoBitacoraPDF)
}

function* reporteDinamicoBitacoraExcel(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idsTiposBitacoras = [];
        let idsModulos = [];
        let idsServicios = [];
        let idsPartidas = [];  
        let titulo = action.titulo ? '?titulo=' + action.titulo : '';

        if(action.formulario.idsTiposBitacoras){
            for(let i in action.formulario.idsTiposBitacoras){
                idsTiposBitacoras.push(action.formulario.idsTiposBitacoras[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsModulos){
            for(let i in action.formulario.idsModulos){
                idsModulos.push(action.formulario.idsModulos[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsServicios){
            for(let i in action.formulario.idsServicios){
                idsServicios.push(action.formulario.idsServicios[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsPartidas){
            for(let i in action.formulario.idsPartidas){
                idsPartidas.push(action.formulario.idsPartidas[i].split(' - ')[0]);
            }
        }

        const data = {
            "idsTiposBitacoras": action.formulario.idsTiposBitacoras ? idsTiposBitacoras : null,
            "idsModulos": action.formulario.idsModulos ? idsModulos : null,
            "idsPartidas": action.formulario.idsPartidas ? idsPartidas : null,
            "idsServicios": action.formulario.idsServicios ? idsServicios : null,
            "fechaInicio": action.formulario.fecha ? action.formulario.fecha[0].format('YYYY-MM-DD') : null,
            "fechaFin": action.formulario.fecha ? action.formulario.fecha[1].format('YYYY-MM-DD') : null,
            "page": 0,
            "offset": 10,
            "order": action.order.order,
            "field": action.order.field,
            "responsableSer": action.formulario.responsableSer ? action.formulario.responsableSer : null,
            "responsableReg": action.formulario.responsableReg ? action.formulario.responsableReg : null,
            "nombre": action.formulario.nombre ? action.formulario.nombre : null,
            "tipoBitacoraC": action.checkboxData.tipoBitacoraC ? action.checkboxData.tipoBitacoraC : false,
            "descripcionC": action.checkboxData.descripcionC ? action.checkboxData.descripcionC : false,
            "nombreC": action.checkboxData.nombreC ? action.checkboxData.nombreC : false,
            "moduloC": action.checkboxData.moduloC ? action.checkboxData.moduloC : false,
            "servicioC": action.checkboxData.servicioC ? action.checkboxData.servicioC : false,
            "partidaC": action.checkboxData.partidaC ? action.checkboxData.partidaC : false,
            "asuntoC": action.checkboxData.asuntoC ? action.checkboxData.asuntoC : false,
            "observacionesC": action.checkboxData.observacionesC ? action.checkboxData.observacionesC : false,
            "fechaC": action.checkboxData.fechaC ? action.checkboxData.fechaC : false,
            "horaC":action.checkboxData.horaC ? action.checkboxData.horaC : false,
            "accionC":action.checkboxData.accionC ? action.checkboxData.accionC : false,
            "responsableSerC":action.checkboxData.responsableSerC ? action.checkboxData.responsableSerC : false,
            "responsableRegC":action.checkboxData.responsableRegC ? action.checkboxData.responsableRegC : false,
            "conclusionesC":action.checkboxData.conclusionesC ? action.checkboxData.conclusionesC : false,
            "agrupamiento":action.formulario.agrupamiento ? action.formulario.agrupamiento : null
        }
        console.log(`data`, data);

        const response = yield call(reporteDinamicoBitacoraExcelCall, token, data, titulo)
        console.log(response)

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const titulo = action.tituloDocumento ? action.tituloDocumento : 'ReporteBitacora_'
            link.download = titulo + new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.EXCEL_REPORTE_DINAMICO_BITACORA_FAILURE })
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage: 'El archivo se descargó de forma correcta' });

        } else {
            const showErrorMsg = true;
            const textMessage = 'No se pudo descargar el Excel con las columnas y filtros seleccionados';

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: configuracionYSeguridadActions.EXCEL_REPORTE_DINAMICO_BITACORA_FAILURE});
        }
    } catch (error) {
        yield put({type: configuracionYSeguridadActions.EXCEL_REPORTE_DINAMICO_BITACORA_FAILURE});
    }
}
export function* reporteDinamicoBitacoraExcelSaga() {
    yield takeLatest(configuracionYSeguridadActions.EXCEL_REPORTE_DINAMICO_BITACORA_REQUEST, reporteDinamicoBitacoraExcel)
}

function* reporteDinamicoBitacoraAgrupadoPDF(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idsTiposBitacoras = [];
        let idsModulos = [];
        let idsServicios = [];
        let idsPartidas = [];  
        let titulo = action.titulo ? action.titulo : '';

        if(action.formulario.idsTiposBitacoras){
            for(let i in action.formulario.idsTiposBitacoras){
                idsTiposBitacoras.push(action.formulario.idsTiposBitacoras[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsModulos){
            for(let i in action.formulario.idsModulos){
                idsModulos.push(action.formulario.idsModulos[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsServicios){
            for(let i in action.formulario.idsServicios){
                idsServicios.push(action.formulario.idsServicios[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsPartidas){
            for(let i in action.formulario.idsPartidas){
                idsPartidas.push(action.formulario.idsPartidas[i].split(' - ')[0]);
            }
        }

        const data = {
            "idsTiposBitacoras": action.formulario.idsTiposBitacoras ? idsTiposBitacoras : null,
            "idsModulos": action.formulario.idsModulos ? idsModulos : null,
            "idsPartidas": action.formulario.idsPartidas ? idsPartidas : null,
            "idsServicios": action.formulario.idsServicios ? idsServicios : null,
            "fechaInicio": action.formulario.fecha ? action.formulario.fecha[0].format('YYYY-MM-DD') : null,
            "fechaFin": action.formulario.fecha ? action.formulario.fecha[1].format('YYYY-MM-DD') : null,
            "page": 0,
            "offset": 10,
            "order": action.order.order,
            "field": action.order.field,
            "responsableSer": action.formulario.responsableSer ? action.formulario.responsableSer : null,
            "responsableReg": action.formulario.responsableReg ? action.formulario.responsableReg : null,
            "nombre": action.formulario.nombre ? action.formulario.nombre : null,
            "tipoBitacoraC": action.checkboxData.tipoBitacoraC ? action.checkboxData.tipoBitacoraC : false,
            "descripcionC": action.checkboxData.descripcionC ? action.checkboxData.descripcionC : false,
            "nombreC": action.checkboxData.nombreC ? action.checkboxData.nombreC : false,
            "moduloC": action.checkboxData.moduloC ? action.checkboxData.moduloC : false,
            "servicioC": action.checkboxData.servicioC ? action.checkboxData.servicioC : false,
            "partidaC": action.checkboxData.partidaC ? action.checkboxData.partidaC : false,
            "asuntoC": action.checkboxData.asuntoC ? action.checkboxData.asuntoC : false,
            "observacionesC": action.checkboxData.observacionesC ? action.checkboxData.observacionesC : false,
            "fechaC": action.checkboxData.fechaC ? action.checkboxData.fechaC : false,
            "horaC":action.checkboxData.horaC ? action.checkboxData.horaC : false,
            "accionC":action.checkboxData.accionC ? action.checkboxData.accionC : false,
            "responsableSerC":action.checkboxData.responsableSerC ? action.checkboxData.responsableSerC : false,
            "responsableRegC":action.checkboxData.responsableRegC ? action.checkboxData.responsableRegC : false,
            "conclusionesC":action.checkboxData.conclusionesC ? action.checkboxData.conclusionesC : false,
            "agrupamiento": action.agrupado ? action.agrupado : action.formulario.tipoAgrupado
        }
        console.log(`data`, data);

        const response = yield call(reporteDinamicoBitacoraAgrupadoPDFCall, token, data, titulo)
        console.log(response)

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            const constAgrupado = action.agrupado ? (action.agrupado).replace(/\b\w/g, l => l.toUpperCase()) : (action.formulario.tipoAgrupado).replace(/\b\w/g, l => l.toUpperCase());
            link.href = url;
            const titulo = action.tituloDocumento ? action.tituloDocumento : 'ReporteBitacoraPor' + constAgrupado + '_'
            link.download = titulo + new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({type: configuracionYSeguridadActions.PDF_REPORTE_DINAMICO_BITACORA_AGRUPADO_SUCCESS})

            yield put({
                type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG,
                showSuccessMsg: true,
                textMessage: 'El archivo se descargó de forma correcta'
            });

        } else {
            const showErrorMsg = true;
            const textMessage = 'No se pudo descargar el PDF con las columnas y filtros seleccionados';

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: configuracionYSeguridadActions.PDF_REPORTE_DINAMICO_BITACORA_AGRUPADO_FAILURE});
        }
    } catch (error) {
        yield put({type: configuracionYSeguridadActions.PDF_REPORTE_DINAMICO_BITACORA_AGRUPADO_FAILURE});
    }
}
export function* reporteDinamicoBitacoraAgrupadoPDFSaga() {
    yield takeLatest(configuracionYSeguridadActions.PDF_REPORTE_DINAMICO_BITACORA_AGRUPADO_REQUEST, reporteDinamicoBitacoraAgrupadoPDF)
}

function* reporteDinamicoBitacoraAgrupadoExcel(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idsTiposBitacoras = [];
        let idsModulos = [];
        let idsServicios = [];
        let idsPartidas = [];  

        let titulo = action.titulo ? '?titulo=' + action.titulo : '';

        if(action.formulario.idsTiposBitacoras){
            for(let i in action.formulario.idsTiposBitacoras){
                idsTiposBitacoras.push(action.formulario.idsTiposBitacoras[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsModulos){
            for(let i in action.formulario.idsModulos){
                idsModulos.push(action.formulario.idsModulos[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsServicios){
            for(let i in action.formulario.idsServicios){
                idsServicios.push(action.formulario.idsServicios[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsPartidas){
            for(let i in action.formulario.idsPartidas){
                idsPartidas.push(action.formulario.idsPartidas[i].split(' - ')[0]);
            }
        }

        const data = {
            "idsTiposBitacoras": action.formulario.idsTiposBitacoras ? idsTiposBitacoras : null,
            "idsModulos": action.formulario.idsModulos ? idsModulos : null,
            "idsPartidas": action.formulario.idsPartidas ? idsPartidas : null,
            "idsServicios": action.formulario.idsServicios ? idsServicios : null,
            "fechaInicio": action.formulario.fecha ? action.formulario.fecha[0].format('YYYY-MM-DD') : null,
            "fechaFin": action.formulario.fecha ? action.formulario.fecha[1].format('YYYY-MM-DD') : null,
            "page": 0,
            "offset": 10,
            "order": action.order.order,
            "field": action.order.field,
            "responsableSer": action.formulario.responsableSer ? action.formulario.responsableSer : null,
            "responsableReg": action.formulario.responsableReg ? action.formulario.responsableReg : null,
            "nombre": action.formulario.nombre ? action.formulario.nombre : null,
            "tipoBitacoraC": action.checkboxData.tipoBitacoraC ? action.checkboxData.tipoBitacoraC : false,
            "descripcionC": action.checkboxData.descripcionC ? action.checkboxData.descripcionC : false,
            "nombreC": action.checkboxData.nombreC ? action.checkboxData.nombreC : false,
            "moduloC": action.checkboxData.moduloC ? action.checkboxData.moduloC : false,
            "servicioC": action.checkboxData.servicioC ? action.checkboxData.servicioC : false,
            "partidaC": action.checkboxData.partidaC ? action.checkboxData.partidaC : false,
            "asuntoC": action.checkboxData.asuntoC ? action.checkboxData.asuntoC : false,
            "observacionesC": action.checkboxData.observacionesC ? action.checkboxData.observacionesC : false,
            "fechaC": action.checkboxData.fechaC ? action.checkboxData.fechaC : false,
            "horaC":action.checkboxData.horaC ? action.checkboxData.horaC : false,
            "accionC":action.checkboxData.accionC ? action.checkboxData.accionC : false,
            "responsableSerC":action.checkboxData.responsableSerC ? action.checkboxData.responsableSerC : false,
            "responsableRegC":action.checkboxData.responsableRegC ? action.checkboxData.responsableRegC : false,
            "conclusionesC":action.checkboxData.conclusionesC ? action.checkboxData.conclusionesC : false,
            "agrupamiento": action.agrupado ? action.agrupado : action.formulario.tipoAgrupado
        }
        console.log(`data`, data);

        const response = yield call(reporteDinamicoBitacoraAgrupadoExcelCall, token, data, titulo)
        console.log(response)

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            const constAgrupado = action.agrupado ? (action.agrupado).replace(/\b\w/g, l => l.toUpperCase()) : (action.formulario.tipoAgrupado).replace(/\b\w/g, l => l.toUpperCase());
            link.href = url;
            const titulo = action.tituloDocumento ? action.tituloDocumento : 'ReporteBitacoraPor' + constAgrupado + '_'
            link.download = titulo + new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.EXCEL_REPORTE_DINAMICO_BITACORA_AGRUPADO_SUCCESS })
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage: 'El archivo se descargó de forma correcta' });

        } else {
            const showErrorMsg = true;
            const textMessage = 'No se pudo descargar el Excel con las columnas y filtros seleccionados';

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: configuracionYSeguridadActions.EXCEL_REPORTE_DINAMICO_BITACORA_AGRUPADO_FAILURE});
        }
    } catch (error) {
        yield put({type: configuracionYSeguridadActions.EXCEL_REPORTE_DINAMICO_BITACORA_AGRUPADO_FAILURE});
    }
}
export function* reporteDinamicoBitacoraAgrupadoExcelSaga() {
    yield takeLatest(configuracionYSeguridadActions.EXCEL_REPORTE_DINAMICO_BITACORA_AGRUPADO_REQUEST, reporteDinamicoBitacoraAgrupadoExcel)
}

function* reporteDinamicoBitacoraAgrupado(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        let idsTiposBitacoras = [];
        let idsModulos = [];
        let idsServicios = [];
        let idsPartidas = [];  

        if(action.formulario.idsTiposBitacoras){
            for(let i in action.formulario.idsTiposBitacoras){
                idsTiposBitacoras.push(action.formulario.idsTiposBitacoras[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsModulos){
            for(let i in action.formulario.idsModulos){
                idsModulos.push(action.formulario.idsModulos[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsServicios){
            for(let i in action.formulario.idsServicios){
                idsServicios.push(action.formulario.idsServicios[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsPartidas){
            for(let i in action.formulario.idsPartidas){
                idsPartidas.push(action.formulario.idsPartidas[i].split(' - ')[0]);
            }
        }

        const data = {
            "idsTiposBitacoras": action.formulario.idsTiposBitacoras ? idsTiposBitacoras : null,
            "idsModulos": action.formulario.idsModulos ? idsModulos : null,
            "idsPartidas": action.formulario.idsPartidas ? idsPartidas : null,
            "idsServicios": action.formulario.idsServicios ? idsServicios : null,
            "fechaInicio": action.formulario.fecha ? action.formulario.fecha[0].format('YYYY-MM-DD') : null,
            "fechaFin": action.formulario.fecha ? action.formulario.fecha[1].format('YYYY-MM-DD') : null,
            "page": 0,
            "offset": 10,
            "order": action.order.order,
            "field": action.order.field,
            "responsableSer": action.formulario.responsableSer ? action.formulario.responsableSer : null,
            "responsableReg": action.formulario.responsableReg ? action.formulario.responsableReg : null,
            "nombre": action.formulario.nombre ? action.formulario.nombre : null,
            "tipoBitacoraC": action.checkboxData.tipoBitacoraC ? action.checkboxData.tipoBitacoraC : false,
            "descripcionC": action.checkboxData.descripcionC ? action.checkboxData.descripcionC : false,
            "nombreC": action.checkboxData.nombreC ? action.checkboxData.nombreC : false,
            "moduloC": action.checkboxData.moduloC ? action.checkboxData.moduloC : false,
            "servicioC": action.checkboxData.servicioC ? action.checkboxData.servicioC : false,
            "partidaC": action.checkboxData.partidaC ? action.checkboxData.partidaC : false,
            "asuntoC": action.checkboxData.asuntoC ? action.checkboxData.asuntoC : false,
            "observacionesC": action.checkboxData.observacionesC ? action.checkboxData.observacionesC : false,
            "fechaC": action.checkboxData.fechaC ? action.checkboxData.fechaC : false,
            "horaC":action.checkboxData.horaC ? action.checkboxData.horaC : false,
            "accionC":action.checkboxData.accionC ? action.checkboxData.accionC : false,
            "responsableSerC":action.checkboxData.responsableSerC ? action.checkboxData.responsableSerC : false,
            "responsableRegC":action.checkboxData.responsableRegC ? action.checkboxData.responsableRegC : false,
            "conclusionesC":action.checkboxData.conclusionesC ? action.checkboxData.conclusionesC : false,
            "agrupamiento":action.agrupado ? action.agrupado : action.formulario.tipoAgrupado
        }
        console.log(`data`, data);

        const response = yield call(reporteDinamicoBitacoraAgrupadoCall, token, data)
        console.log(response)

        if(response.status === 200){
            const listaReporteDinamicoAgrupadoBitacora = response.data.data;
            const listaReporteDinamicoAgrupadoCantidadBitacora = response.data.cantidad;
            const agrupadoConst = action.agrupado ? action.agrupado : action.formulario.tipoAgrupado;

            if(agrupadoConst === 'partida'){
                for(let i in listaReporteDinamicoAgrupadoBitacora){
                    if(listaReporteDinamicoAgrupadoBitacora[i].id === 0){
                        listaReporteDinamicoAgrupadoBitacora[i].nombre = 'Sin partida';
                    }
                }
            }

            if(agrupadoConst === 'fecha'){
                for(let i in listaReporteDinamicoAgrupadoBitacora){
                    listaReporteDinamicoAgrupadoBitacora[i].nombreTable = listaReporteDinamicoAgrupadoBitacora[i].nombre.split('-')[2] + '-' + listaReporteDinamicoAgrupadoBitacora[i].nombre.split('-')[1] + '-' + listaReporteDinamicoAgrupadoBitacora[i].nombre.split('-')[0];
                }
            }

            for(let i in listaReporteDinamicoAgrupadoBitacora){
                const dataAgrupado = {
                    "idsTiposBitacoras": agrupadoConst === 'tipoBitacora' ? [listaReporteDinamicoAgrupadoBitacora[i].id] : action.formulario.idsTiposBitacoras ? idsTiposBitacoras : null,
                    "idsModulos": agrupadoConst === 'modulo' ? [listaReporteDinamicoAgrupadoBitacora[i].id] : action.formulario.idsModulos ? idsModulos : null,
                    "idsPartidas": agrupadoConst === 'partida' ? [listaReporteDinamicoAgrupadoBitacora[i].id] : action.formulario.idsPartidas ? idsPartidas : null,
                    "idsServicios": agrupadoConst === 'servicio' ? [listaReporteDinamicoAgrupadoBitacora[i].id] : (action.formulario.idsServicios ? idsServicios : null),
                    "fechaInicio": agrupadoConst === 'fecha' ? listaReporteDinamicoAgrupadoBitacora[i].nombre : action.formulario.fecha ? action.formulario.fecha[0].format('YYYY-MM-DD') : null,
                    "fechaFin": agrupadoConst === 'fecha' ? listaReporteDinamicoAgrupadoBitacora[i].nombre : action.formulario.fecha ? action.formulario.fecha[1].format('YYYY-MM-DD') : null,
                    "page": 0,
                    "offset": 10,
                    "order": action.order.order,
                    "field": action.order.field,
                    "responsableSer": agrupadoConst === 'responsableSer' ? listaReporteDinamicoAgrupadoBitacora[i].nombre : action.formulario.responsableSer ? action.formulario.responsableSer : null,
                    "responsableReg": agrupadoConst === 'responsableReg' ? listaReporteDinamicoAgrupadoBitacora[i].nombre : action.formulario.responsableReg ? action.formulario.responsableReg : null,
                    "nombre": action.formulario.nombre ? action.formulario.nombre : null,
                    "tipoBitacoraC": action.checkboxData.tipoBitacoraC ? action.checkboxData.tipoBitacoraC : false,
                    "descripcionC": action.checkboxData.descripcionC ? action.checkboxData.descripcionC : false,
                    "nombreC": action.checkboxData.nombreC ? action.checkboxData.nombreC : false,
                    "moduloC": action.checkboxData.moduloC ? action.checkboxData.moduloC : false,
                    "servicioC": action.checkboxData.servicioC ? action.checkboxData.servicioC : false,
                    "partidaC": action.checkboxData.partidaC ? action.checkboxData.partidaC : false,
                    "asuntoC": action.checkboxData.asuntoC ? action.checkboxData.asuntoC : false,
                    "observacionesC": action.checkboxData.observacionesC ? action.checkboxData.observacionesC : false,
                    "fechaC": action.checkboxData.fechaC ? action.checkboxData.fechaC : false,
                    "horaC":action.checkboxData.horaC ? action.checkboxData.horaC : false,
                    "accionC":action.checkboxData.accionC ? action.checkboxData.accionC : false,
                    "responsableSerC":action.checkboxData.responsableSerC ? action.checkboxData.responsableSerC : false,
                    "responsableRegC":action.checkboxData.responsableRegC ? action.checkboxData.responsableRegC : false,
                    "conclusionesC":action.checkboxData.conclusionesC ? action.checkboxData.conclusionesC : false,
                    "agrupamiento": null
                }
                console.log(`dataAgrupado`, dataAgrupado);

                const responseAgrupado = yield call(reporteDinamicoBitacoraCall, token, dataAgrupado)
                console.log(`responseAgrupado`, responseAgrupado)
                if(responseAgrupado.status === 200){
                    listaReporteDinamicoAgrupadoBitacora[i].agrupados = responseAgrupado.data;
                    listaReporteDinamicoAgrupadoBitacora[i].agrupados.page = 1;
                    for(let x in listaReporteDinamicoAgrupadoBitacora[i].agrupados.data){
                        listaReporteDinamicoAgrupadoBitacora[i].agrupados.data[x].id = parseInt(x) + 1;
                    };
                }
                else{
                    const showErrorMsg = true;
                    const textMessage = responseAgrupado.message;

                    yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                    yield put({ type: configuracionYSeguridadActions.REPORTE_DINAMICO_AGRUPADO_BITACORA_FAILURE });
                }
            }

            console.log('listaReporteDinamicoAgrupadoBitacora', listaReporteDinamicoAgrupadoBitacora);

            yield put({ type: configuracionYSeguridadActions.REPORTE_DINAMICO_AGRUPADO_BITACORA_SUCCESS, listaReporteDinamicoAgrupadoBitacora, listaReporteDinamicoAgrupadoCantidadBitacora })

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.REPORTE_DINAMICO_AGRUPADO_BITACORA_FAILURE });
        }
    } catch (error) {
        yield put({ type: configuracionYSeguridadActions.REPORTE_DINAMICO_AGRUPADO_BITACORA_FAILURE });
    }
}
export function* reporteDinamicoBitacoraAgrupadoSaga(){
    yield takeLatest(configuracionYSeguridadActions.REPORTE_DINAMICO_AGRUPADO_BITACORA_REQUEST, reporteDinamicoBitacoraAgrupado)
}

/////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////
/////////////////////////////////////

function* getAllRegistrosBitacora(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;

        const response = yield call(getAllRegistrosBitacoraCall, token, page);
        console.log(response);
        if(response.status === 200){
            let allRegistros = response.data.data;
            let allRegistrosCantidad = response.data.cantidad;

            for(let i in allRegistros){
                allRegistros[i].usuario = allRegistros[i].usuarioByIdUsuario.nombre + ' ' + allRegistros[i].usuarioByIdUsuario.aPaterno + (allRegistros[i].usuarioByIdUsuario.aMaterno ? (' ' + allRegistros[i].usuarioByIdUsuario.aMaterno) : '');

                allRegistros[i].formulario = {
                    idsTiposBitacoras: undefined,
                    idsModulos: undefined,
                    idsPartidas: undefined,
                    idsServicios: undefined,
                    fechaInicio: undefined,
                    fechaFin: undefined,
                    responsableSer: undefined,
                    responsableReg: undefined,
                    nombre: undefined,
                }
                allRegistros[i].checkbox = {
                    tipoBitacoraC: false,
                    descripcionC: false,
                    nombreC: false,
                    moduloC: false,
                    servicioC: false,
                    partidaC: false,
                    asuntoC: false,
                    observacionesC: false,
                    fechaC: false,
                    horaC: false,
                    accionC: false,
                    responsableSerC: false,
                    responsableRegC: false,
                    conclusionesC: false,
                }
                allRegistros[i].agrupamiento = false;

                allRegistros[i].valoresColumnasSplit = allRegistros[i].valoresColumnas.split(',');
                for (let x in allRegistros[i].valoresColumnasSplit) {
                    allRegistros[i].valoresColumnasSplit[x] = allRegistros[i].valoresColumnasSplit[x].charAt(0).toLowerCase() + allRegistros[i].valoresColumnasSplit[x].slice(1);
                    allRegistros[i].checkbox[allRegistros[i].valoresColumnasSplit[x]] = true
                }

                let tipoAgrupado = 'SinAgrupar';
                let order = 'ASC';
                let field = 'tbe.tipoBitacora'
                let nombreModulo = '';
                let responsable = '';

                for (let x in allRegistros[i].filtrosByIdReporteBitacora) {
                    allRegistros[i].formulario.idsTiposBitacoras = allRegistros[i].filtrosByIdReporteBitacora[x].filtro === 'IdsTiposBitacoras' ? allRegistros[i].filtrosByIdReporteBitacora[x].valor.split(',') : allRegistros[i].formulario.idsTiposBitacoras;

                    allRegistros[i].formulario.idsPartidas = allRegistros[i].filtrosByIdReporteBitacora[x].filtro === 'IdsPartidas' ? allRegistros[i].filtrosByIdReporteBitacora[x].valor.split(',') : allRegistros[i].formulario.idsPartidas;

                    allRegistros[i].formulario.idsModulos = allRegistros[i].filtrosByIdReporteBitacora[x].filtro === 'IdsModulos' ? allRegistros[i].filtrosByIdReporteBitacora[x].valor.split(',') : allRegistros[i].formulario.idsModulos;

                    allRegistros[i].formulario.idsServicios = allRegistros[i].filtrosByIdReporteBitacora[x].filtro === 'IdsServicios' ? allRegistros[i].filtrosByIdReporteBitacora[x].valor.split(',') : allRegistros[i].formulario.idsServicios;

                    if(allRegistros[i].filtrosByIdReporteBitacora[x].filtro === 'Nombre'){
                        nombreModulo = allRegistros[i].filtrosByIdReporteBitacora[x].valor
                    }

                    if(allRegistros[i].filtrosByIdReporteBitacora[x].filtro === 'Responsable'){
                        responsable = allRegistros[i].filtrosByIdReporteBitacora[x].valor
                    }


                    if(allRegistros[i].filtrosByIdReporteBitacora[x].filtro === 'FechaIngreso'){
                        if(allRegistros[i].filtrosByIdReporteBitacora[x].valor.includes(',')){
                            allRegistros[i].formulario.fechaIngresoInicio = new Date (allRegistros[i].filtrosByIdReporteBitacora[x].valor.split(',')[0]).toISOString().slice(0, 10);
                            allRegistros[i].formulario.fechaIngresoFin = new Date (allRegistros[i].filtrosByIdReporteBitacora[x].valor.split(',')[1]).toISOString().slice(0, 10);
                        }
                    }

                    if(allRegistros[i].filtrosByIdReporteBitacora[x].filtro === 'Agrupamiento'){
                        tipoAgrupado = allRegistros[i].filtrosByIdReporteBitacora[x].valor
                    }

                    if(allRegistros[i].filtrosByIdReporteBitacora[x].filtro === 'Order'){
                        order = allRegistros[i].filtrosByIdReporteBitacora[x].valor;
                    }

                    if(allRegistros[i].filtrosByIdReporteBitacora[x].filtro === 'Field'){
                        field = allRegistros[i].filtrosByIdReporteBitacora[x].valor;
                    }
                }

                allRegistros[i].checkboxData = Object.entries(allRegistros[i].checkbox);
                let checkAll = true;
                allRegistros[i].listaFields = [];
                for (let x in allRegistros[i].checkboxData) {
                    if (!allRegistros[i].checkboxData[x][1]) {
                        checkAll = false
                    }
                    allRegistros[i][allRegistros[i].checkboxData[x][0]] = allRegistros[i].checkboxData[x][1];
                    allRegistros[i].checkboxData[x].push(
                        allRegistros[i].checkboxData[x][0] === 'tipoBitacoraC' ? 'Tipo de bitácora' : (
                            allRegistros[i].checkboxData[x][0] === 'descripcionC' ? 'Descripción tipo de bitácora' : (
                                allRegistros[i].checkboxData[x][0] === 'nombreC' ? 'Nombre' : (
                                    allRegistros[i].checkboxData[x][0] === 'moduloC' ? 'Módulo' : (
                                        allRegistros[i].checkboxData[x][0] === 'servicioC' ? 'Servicio' : (
                                            allRegistros[i].checkboxData[x][0] === 'partidaC' ? 'Partida' : (
                                                allRegistros[i].checkboxData[x][0] === 'asuntoC' ? 'Asunto' : (
                                                    allRegistros[i].checkboxData[x][0] === 'observacionesC' ? 'Observaciones' : (
                                                        allRegistros[i].checkboxData[x][0] === 'fechaC' ? 'Fecha' : (
                                                            allRegistros[i].checkboxData[x][0] === 'horaC' ? 'Hora' : (
                                                                allRegistros[i].checkboxData[x][0] === 'accionC' ? 'Acción' : (
                                                                    allRegistros[i].checkboxData[x][0] === 'responsableSerC' ? 'Responsable de servicio' : (
                                                                        allRegistros[i].checkboxData[x][0] === 'conclusionesC' ? 'Conclusión' : (
                                                                            allRegistros[i].checkboxData[x][0] === 'responsableRegC' ? 'Responsable de registro' : false
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                }

                allRegistros[i].checkAll = checkAll;
                allRegistros[i].tipoAgrupado = tipoAgrupado;
                allRegistros[i].order = order;
                allRegistros[i].field = field;
                allRegistros[i].nombreModulo =  nombreModulo;
                allRegistros[i].responsable = responsable;
            }

            console.log('allRegistros', allRegistros)

            yield put({ type: configuracionYSeguridadActions.GET_ALL_REGISTROS_BITACORA_SUCCESS, allRegistros, allRegistrosCantidad });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.GET_ALL_REGISTROS_BITACORA_FAILURE });
        }
    } catch (error) {
        yield put({ type: configuracionYSeguridadActions.GET_ALL_REGISTROS_BITACORA_FAILURE });
    }
} export function* getAllRegistrosBitacoraSaga(){
    yield takeLatest(configuracionYSeguridadActions.GET_ALL_REGISTROS_BITACORA_REQUEST, getAllRegistrosBitacora)
}

function* getRegistrosByTokenBitacora(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;

        const response = yield call(getRegistrosByTokenBitacoraCall, token, page);
        console.log(response);
        if(response.status === 200){
            let registrosByToken = response.data.data;
            let registrosByTokenCantidad = response.data.cantidad;

            for(let i in registrosByToken){
                registrosByToken[i].usuario = registrosByToken[i].usuarioByIdUsuario.nombre + ' ' + registrosByToken[i].usuarioByIdUsuario.aPaterno + (registrosByToken[i].usuarioByIdUsuario.aMaterno ? (' ' + registrosByToken[i].usuarioByIdUsuario.aMaterno) : '');

                registrosByToken[i].formulario = {
                    idsTiposBitacoras: undefined,
                    idsModulos: undefined,
                    idsPartidas: undefined,
                    idsServicios: undefined,
                    fechaInicio: undefined,
                    fechaFin: undefined,
                    responsableSer: undefined,
                    responsableReg: undefined,
                    nombre: undefined,
                }
                registrosByToken[i].checkbox = {
                    tipoBitacoraC: false,
                    descripcionC: false,
                    nombreC: false,
                    moduloC: false,
                    servicioC: false,
                    partidaC: false,
                    asuntoC: false,
                    observacionesC: false,
                    fechaC: false,
                    horaC: false,
                    accionC: false,
                    responsableSerC: false,
                    responsableRegC: false,
                    conclusionesC: false,
                }
                registrosByToken[i].agrupado = false;
                registrosByToken[i].valoresColumnasSplit = registrosByToken[i].valoresColumnas.split(',');
                for (let x in registrosByToken[i].valoresColumnasSplit) {
                    registrosByToken[i].valoresColumnasSplit[x] = registrosByToken[i].valoresColumnasSplit[x].charAt(0).toLowerCase() + registrosByToken[i].valoresColumnasSplit[x].slice(1);
                    registrosByToken[i].checkbox[registrosByToken[i].valoresColumnasSplit[x]] = true
                }

                let tipoAgrupado = 'SinAgrupar';
                let order = 'ASC';
                let field = 'tbe.tipoBitacora'
                let nombreModulo = '';
                let responsable = '';

                for (let x in registrosByToken[i].filtrosByIdReporteBitacora) {

                    registrosByToken[i].formulario.idsModulos = registrosByToken[i].filtrosByIdReporteBitacora[x].filtro === 'IdsModulos' ? registrosByToken[i].filtrosByIdReporteBitacora[x].valor.split(',') : registrosByToken[i].formulario.idsModulos;

                    registrosByToken[i].formulario.idsServicios = registrosByToken[i].filtrosByIdReporteBitacora[x].filtro === 'IdsServicios' ? registrosByToken[i].filtrosByIdReporteBitacora[x].valor.split(',') : registrosByToken[i].formulario.idsServicios;

                    registrosByToken[i].formulario.idsTiposBitacoras = registrosByToken[i].filtrosByIdReporteBitacora[x].filtro === 'IdsTiposBitacoras' ? registrosByToken[i].filtrosByIdReporteBitacora[x].valor.split(',') : registrosByToken[i].formulario.idsTiposBitacoras;

                    registrosByToken[i].formulario.idsPartidas = registrosByToken[i].filtrosByIdReporteBitacora[x].filtro === 'IdsPartidas' ? registrosByToken[i].filtrosByIdReporteBitacora[x].valor.split(',') : registrosByToken[i].formulario.idsPartidas;

                    if(registrosByToken[i].filtrosByIdReporteBitacora[x].filtro === 'Fecha'){
                        if(registrosByToken[i].filtrosByIdReporteBitacora[x].valor.includes(',')){
                            registrosByToken[i].formulario.fechaInicio = new Date (registrosByToken[i].filtrosByIdReporteBitacora[x].valor.split(',')[0]).toISOString().slice(0, 10);
                            registrosByToken[i].formulario.fechaFin = new Date (registrosByToken[i].filtrosByIdReporteBitacora[x].valor.split(',')[1]).toISOString().slice(0, 10);
                        }
                    }

                    if(registrosByToken[i].filtrosByIdReporteBitacora[x].filtro === 'Nombre'){
                        nombreModulo = registrosByToken[i].filtrosByIdReporteBitacora[x].valor
                    }

                    if(registrosByToken[i].filtrosByIdReporteBitacora[x].filtro === 'Responsable'){
                        responsable = registrosByToken[i].filtrosByIdReporteBitacora[x].valor
                    }

                    if(registrosByToken[i].filtrosByIdReporteBitacora[x].filtro === 'Agrupamiento'){
                        tipoAgrupado = registrosByToken[i].filtrosByIdReporteBitacora[x].valor
                    }

                    if(registrosByToken[i].filtrosByIdReporteBitacora[x].filtro === 'Order'){
                        order = registrosByToken[i].filtrosByIdReporteBitacora[x].valor;
                    }

                    if(registrosByToken[i].filtrosByIdReporteBitacora[x].filtro === 'Field'){
                        field = registrosByToken[i].filtrosByIdReporteBitacora[x].valor;
                    }

                }

                registrosByToken[i].checkboxData = Object.entries(registrosByToken[i].checkbox);
                let checkAll = true;
                registrosByToken[i].listaFields = [];
                for (let x in registrosByToken[i].checkboxData) {
                    if (!registrosByToken[i].checkboxData[x][1]) {
                        checkAll = false
                    }
                    registrosByToken[i][registrosByToken[i].checkboxData[x][0]] = registrosByToken[i].checkboxData[x][1];
                    registrosByToken[i].checkboxData[x].push(
                        registrosByToken[i].checkboxData[x][0] === 'tipoBitacoraC' ? 'Tipo de bitácora' : (
                            registrosByToken[i].checkboxData[x][0] === 'descripcionC' ? 'Descripción tipo de bitácora' : (
                                registrosByToken[i].checkboxData[x][0] === 'nombreC' ? 'Nombre' : (
                                    registrosByToken[i].checkboxData[x][0] === 'moduloC' ? 'Módulo' : (
                                        registrosByToken[i].checkboxData[x][0] === 'servicioC' ? 'Servicio' : (
                                            registrosByToken[i].checkboxData[x][0] === 'partidaC' ? 'Partida' : (
                                                registrosByToken[i].checkboxData[x][0] === 'asuntoC' ? 'Asunto' : (
                                                    registrosByToken[i].checkboxData[x][0] === 'observacionesC' ? 'Observaciones' : (
                                                        registrosByToken[i].checkboxData[x][0] === 'fechaC' ? 'Fecha' : (
                                                            registrosByToken[i].checkboxData[x][0] === 'horaC' ? 'Hora' : (
                                                                registrosByToken[i].checkboxData[x][0] === 'accionC' ? 'Acción' : (
                                                                    registrosByToken[i].checkboxData[x][0] === 'responsableSerC' ? 'Responsable de servicio' : (
                                                                        registrosByToken[i].checkboxData[x][0] === 'conclusionesC' ? 'Conclusión' : (
                                                                            registrosByToken[i].checkboxData[x][0] === 'responsableRegC' ? 'Responsable de registro' :  false
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                }

                registrosByToken[i].checkAll = checkAll;
                registrosByToken[i].tipoAgrupado = tipoAgrupado;
                registrosByToken[i].order = order;
                registrosByToken[i].field = field;
                registrosByToken[i].nombreModulo = nombreModulo;
                registrosByToken[i].responsable = responsable;
            }

            console.log('registrosByToken', registrosByToken)

            yield put({ type: configuracionYSeguridadActions.GET_REGISTROS_BY_TOKEN_BITACORA_SUCCESS, registrosByToken, registrosByTokenCantidad });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.GET_REGISTROS_BY_TOKEN_BITACORA_FAILURE });
        }
    } catch (error) {
        yield put({ type: configuracionYSeguridadActions.GET_REGISTROS_BY_TOKEN_BITACORA_FAILURE });
    }
} export function* getRegistrosByTokenBitacoraSaga(){
    yield takeLatest(configuracionYSeguridadActions.GET_REGISTROS_BY_TOKEN_BITACORA_REQUEST, getRegistrosByTokenBitacora)
}

function* crearRegistroReporteBitacora(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idsTiposBitacoras = [];
        let idsModulos = [];
        let idsServicios = [];
        let idsPartidas = [];  

        if(action.data.formulario.idsTiposBitacoras){
            for(let i in action.data.formulario.idsTiposBitacoras){
                idsTiposBitacoras.push(action.data.formulario.idsTiposBitacoras[i].split(' - ')[0]);
            }
        }

        if(action.data.formulario.idsModulos){
            for(let i in action.data.formulario.idsModulos){
                idsModulos.push(action.data.formulario.idsModulos[i].split(' - ')[0]);
            }
        }

        if(action.data.formulario.idsServicios){
            for(let i in action.data.formulario.idsServicios){
                idsServicios.push(action.data.formulario.idsServicios[i].split(' - ')[0]);
            }
        }

        if(action.data.formulario.idsPartidas){
            for(let i in action.data.formulario.idsPartidas){
                idsPartidas.push(action.data.formulario.idsPartidas[i].split(' - ')[0]);
            }
        }   

        const data = {
            "reporteBitacoraRequest":{
                "idsTiposBitacoras": action.data.formulario.idsTiposBitacoras ? idsTiposBitacoras : null,
                "idsModulos": action.data.formulario.idsModulos ? idsModulos : null,
                "idsPartidas": action.data.formulario.idsPartidas ? idsPartidas : null,
                "idsServicios": action.data.formulario.idsServicios ? idsServicios : null,
                "fechaInicio": action.data.formulario.fecha ? action.data.formulario.fecha[0].format('YYYY-MM-DD') : null,
                "fechaFin": action.data.formulario.fecha ? action.data.formulario.fecha[1].format('YYYY-MM-DD') : null,
                "page": 0,
                "offset": 10,
                "order": action.data.order.order,
                "field": action.data.order.field,
                "responsableSer": action.data.formulario.responsableSer ? action.data.formulario.responsableSer : null,
                "responsableReg": action.data.formulario.responsableReg ? action.data.formulario.responsableReg : null,
                "nombre": action.data.formulario.nombre ? action.data.formulario.nombre : null,
                "tipoBitacoraC": action.data.checkboxData.tipoBitacoraC ? action.data.checkboxData.tipoBitacoraC : false,
                "descripcionC": action.data.checkboxData.descripcionC ? action.data.checkboxData.descripcionC : false,
                "nombreC": action.data.checkboxData.nombreC ? action.data.checkboxData.nombreC : false,
                "moduloC": action.data.checkboxData.moduloC ? action.data.checkboxData.moduloC : false,
                "servicioC": action.data.checkboxData.servicioC ? action.data.checkboxData.servicioC : false,
                "partidaC": action.data.checkboxData.partidaC ? action.data.checkboxData.partidaC : false,
                "asuntoC": action.data.checkboxData.asuntoC ? action.data.checkboxData.asuntoC : false,
                "observacionesC": action.data.checkboxData.observacionesC ? action.data.checkboxData.observacionesC : false,
                "fechaC": action.data.checkboxData.fechaC ? action.data.checkboxData.fechaC : false,
                "horaC":action.data.checkboxData.horaC ? action.data.checkboxData.horaC : false,
                "accionC":action.data.checkboxData.accionC ? action.data.checkboxData.accionC : false,
                "responsableSerC":action.data.checkboxData.responsableSerC ? action.data.checkboxData.responsableSerC : false,
                "responsableRegC":action.data.checkboxData.responsableRegC ? action.data.checkboxData.responsableRegC : false,
                "conclusionesC":action.data.checkboxData.conclusionesC ? action.data.checkboxData.conclusionesC : false,
                "agrupamiento":action.data.agrupado ? action.data.agrupado : action.data.formulario.tipoAgrupado
            },
            "reportesBitacora": {
                "idReporteBitacora": null,
                'titulo': action.formulario.titulo,
                "nombre": action.formulario.nombre,
                "descripcion": action.formulario.descripcion ? action.formulario.descripcion : '',
                "idUsuario": null,
                "valoresColumnas": "",
                "fechaAlta": null,
                "fechaActualizacion": null,
                "activo": true
            }
        }
        console.log(`data`, data);

        const response = yield call(crearRegistroReporteBitacoraCall, token, data)
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se creó el reporte predeterminado de manera exitosa";
            const order = { order: action.data.order.order, field: action.data.order.field}

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.MODAL_CREAR_REGISTRO_BITACORA, showModalCrearRegistro: false });

            let titulo = action.formulario.titulo;

            if(action.data.agrupado === 'SinAgrupar'){
                if(action.data.tipoDocumento === 'pdf'){
                    yield put({ type: configuracionYSeguridadActions.PDF_REPORTE_DINAMICO_BITACORA_REQUEST, formulario: action.data.formulario, checkboxData: action.data.checkboxData, order, containerRegistros: true, titulo: titulo });
                }
                else{
                    yield put({ type: configuracionYSeguridadActions.EXCEL_REPORTE_DINAMICO_BITACORA_REQUEST, formulario: action.data.formulario, checkboxData: action.data.checkboxData, order, containerRegistros: true, titulo: titulo  });
                }
            } else {
                action.formulario.horarioEntrada = action.formulario.horarioEntrada ? action.formulario.horarioEntrada.format('HH:mm:ss') : null;
                if(action.data.tipoDocumento === 'pdf'){
                    yield put({ type: configuracionYSeguridadActions.PDF_REPORTE_DINAMICO_BITACORA_AGRUPADO_REQUEST, formulario: action.data.formulario, checkboxData: action.data.checkboxData, agrupado: action.data.agrupado, order, containerRegistros: true, titulo: titulo  });
                }
                else{
                    yield put({ type: configuracionYSeguridadActions.EXCEL_REPORTE_DINAMICO_BITACORA_AGRUPADO_REQUEST, formulario: action.data.formulario, checkboxData: action.data.checkboxData, agrupado: action.data.agrupado, order, containerRegistros: true, titulo: titulo  });
                }
            }

            yield put({
                type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO,
                limpiarFormulario: 'crearRegistroReporteBitacora'
            });

            yield put({type: configuracionYSeguridadActions.CREATE_REGISTROS_BITACORA_SUCCESS});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: configuracionYSeguridadActions.CREATE_REGISTROS_BITACORA_FAILURE})
        }
    } catch (error) {
        yield put({type: configuracionYSeguridadActions.CREATE_REGISTROS_BITACORA_FAILURE})
    }
}

export function* crearRegistroReporteBitacoraSaga() {
    yield takeLatest(configuracionYSeguridadActions.CREATE_REGISTROS_BITACORA_REQUEST, crearRegistroReporteBitacora)
}

function* estadoRegistroReporteBitacora(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idReporte = action.idReporte;

        const response = yield call(estadoRegistroReporteBitacoraCall, token, idReporte);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El estado del reporte personalizado cambió de forma correcta";

            yield put({type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage});

            if (action.personal) {
                yield put({
                    type: configuracionYSeguridadActions.GET_ALL_REGISTROS_BITACORA_REQUEST,
                    page: action.page
                });
            } else {
                yield put({type: configuracionYSeguridadActions.GET_REGISTROS_BY_TOKEN_BITACORA_REQUEST, page: action.page});
            }

            yield put({type: configuracionYSeguridadActions.ESTADO_REGISTROS_BITACORA_SUCCESS});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});

            yield put({type: configuracionYSeguridadActions.ESTADO_REGISTROS_BITACORA_FAILURE});
        }

    } catch (error) {
        console.log(error);
        yield put({type: configuracionYSeguridadActions.ESTADO_REGISTROS_BITACORA_FAILURE});
    }
}

export function* estadoRegistroReporteBitacoraSaga() {
    yield takeLatest(configuracionYSeguridadActions.ESTADO_REGISTROS_BITACORA_REQUEST, estadoRegistroReporteBitacora);
}

function* editarRegistroReporteBitacora(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idReporteBitacora = action.registroReporteSeleccionado.idReporteBitacora;

        let idsTiposBitacoras = [];
        let idsModulos = [];
        let idsServicios = [];
        let idsPartidas = [];  

        if(action.formulario.idsTiposBitacoras){
            for(let i in action.formulario.idsTiposBitacoras){
                idsTiposBitacoras.push(action.formulario.idsTiposBitacoras[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsModulos){
            for(let i in action.formulario.idsModulos){
                idsModulos.push(action.formulario.idsModulos[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsServicios){
            for(let i in action.formulario.idsServicios){
                idsServicios.push(action.formulario.idsServicios[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsPartidas){
            for(let i in action.formulario.idsPartidas){
                idsPartidas.push(action.formulario.idsPartidas[i].split(' - ')[0]);
            }
        }   

        console.log('Validación');
        console.log('idsTiposBitacoras', idsTiposBitacoras)
        const data = {
            "reporteBitacoraRequest":{
                "idsTiposBitacoras": action.formulario.idsTiposBitacoras ? idsTiposBitacoras : null,
                "idsModulos": action.formulario.idsModulos ? idsModulos : null,
                "idsPartidas": action.formulario.idsPartidas ? idsPartidas : null,
                "idsServicios": action.formulario.idsServicios ? idsServicios : null,
                "fechaInicio": action.formulario.fecha ? action.formulario.fecha[0].format('YYYY-MM-DD') : null,
                "fechaFin": action.formulario.fecha ? action.formulario.fecha[1].format('YYYY-MM-DD') : null,
                "page": null,
                "offset": null,
                "order": action.formulario.order,
                "field": action.formulario.field,
                "responsableSer": action.formulario.responsableSer ? action.formulario.responsableSer : null,
                "responsableReg": action.formulario.responsableReg ? action.formulario.responsableReg : null,
                "nombre": action.formulario.nombreModulo ? action.formulario.nombreModulo : null,
                "tipoBitacoraC": action.registroReporteSeleccionado.tipoBitacoraC ? action.registroReporteSeleccionado.tipoBitacoraC : false,
                "descripcionC": action.registroReporteSeleccionado.descripcionC ? action.registroReporteSeleccionado.descripcionC : false,
                "nombreC": action.registroReporteSeleccionado.nombreC ? action.registroReporteSeleccionado.nombreC : false,
                "moduloC": action.registroReporteSeleccionado.moduloC ? action.registroReporteSeleccionado.moduloC : false,
                "servicioC": action.registroReporteSeleccionado.servicioC ? action.registroReporteSeleccionado.servicioC : false,
                "partidaC": action.registroReporteSeleccionado.partidaC ? action.registroReporteSeleccionado.partidaC : false,
                "asuntoC": action.registroReporteSeleccionado.asuntoC ? action.registroReporteSeleccionado.asuntoC : false,
                "observacionesC": action.registroReporteSeleccionado.observacionesC ? action.registroReporteSeleccionado.observacionesC : false,
                "fechaC": action.registroReporteSeleccionado.fechaC ? action.registroReporteSeleccionado.fechaC : false,
                "horaC":action.registroReporteSeleccionado.horaC ? action.registroReporteSeleccionado.horaC : false,
                "accionC":action.registroReporteSeleccionado.accionC ? action.registroReporteSeleccionado.accionC : false,
                "responsableSerC":action.registroReporteSeleccionado.responsableSerC ? action.registroReporteSeleccionado.responsableSerC : false,
                "responsableRegC":action.registroReporteSeleccionado.responsableRegC ? action.registroReporteSeleccionado.responsableRegC : false,
                "conclusionesC":action.registroReporteSeleccionado.conclusionesC ? action.registroReporteSeleccionado.conclusionesC : false,
                "agrupamiento":action.agrupado ? action.agrupado : action.formulario.tipoAgrupado
            },
            "reportesBitacora": {
                "idReporteBitacora": action.registroReporteSeleccionado.idReporteBitacora,
                'titulo': action.formulario.titulo,
                "nombre": action.formulario.nombre,
                "descripcion": action.formulario.descripcion ? action.formulario.descripcion : '',
                "idUsuario": action.registroReporteSeleccionado.idUsuario,
                "valoresColumnas": "",
                "fechaAlta": null,
                "fechaActualizacion": null,
                "activo": true
            }
        }
        console.log(`data`, data);

        const response = yield call(editarRegistroReporteBitacoraCall, token, data, idReporteBitacora);
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se editó el reporte predeterminado de manera exitosa";

            if (action.personal) {
                yield put({
                    type: configuracionYSeguridadActions.GET_ALL_REGISTROS_BITACORA_REQUEST,
                    page: action.page
                });
            } else {
                yield put({type: configuracionYSeguridadActions.GET_REGISTROS_BY_TOKEN_BITACORA_REQUEST, page: action.page});
            }

            yield put({type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage});
            yield put({
                type: configuracionYSeguridadActions.MODAL_EDITAR_REGISTRO_BITACORA,
                showModalEditarRegistro: false,
                page: action.page,
                personal: action.personal
            });

            yield put({type: configuracionYSeguridadActions.EDIT_REGISTROS_BITACORA_SUCCESS});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: configuracionYSeguridadActions.EDIT_REGISTROS_BITACORA_FAILURE})
        }
    } catch (error) {
        yield put({type: configuracionYSeguridadActions.EDIT_REGISTROS_BITACORA_FAILURE})
    }
}

export function* editarRegistroReporteBitacoraSaga() {
    yield takeLatest(configuracionYSeguridadActions.EDIT_REGISTROS_BITACORA_REQUEST, editarRegistroReporteBitacora)
}

function* listaPartidasActivas(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaPartidasActivasCall, token);
        console.log('response', response);
        
        if (response.status === 200) {
            let listaPartidasActivas = response.data;

            yield put({ type: configuracionYSeguridadActions.LISTA_PARTIDAS_ACTIVAS_SUCCESS, listaPartidasActivas });

        } else {
            yield put({ type: configuracionYSeguridadActions.LISTA_PARTIDAS_ACTIVAS_FAILURE});
        }
    } catch (error) {
        yield put({ type: configuracionYSeguridadActions.LISTA_PARTIDAS_ACTIVAS_FAILURE});
    }
}
export function* listaPartidasActivasSaga(){
    yield takeLatest(configuracionYSeguridadActions.LISTA_PARTIDAS_ACTIVAS_REQUEST, listaPartidasActivas);
}

//////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////RANGOS DE VALIDACIÓN///////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////
//************************* Búsqueda Catálogo de Rangos de Valicación ****************************
function* listaRangosValidacionBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page ? action.page - 1 : 0;
        let limiteInf =  action.busqueda ? (action.busqueda.limiteInferior ? '&limiteInferior=' + action.busqueda.limiteInferior : '') : '';
        let limiteSup = action.busqueda  ? (action.busqueda.limiteSuperior ? '&limiteSuperior=' + action.busqueda.limiteSuperior : '') : '';
        let nombre = action.busqueda  ? (action.busqueda.nombre ? '&nombre=' + action.busqueda.nombre : '') : '';
        let ordenamiento = action.orden ? (action.orden.order ? '&ordenamiento=' + action.orden.order : '&ordenamiento=ASC') : '&ordenamiento=ASC';
        let field = action.orden ? (action.orden.field ? '&field=' + action.orden.field : '') : '';

        let response = yield call(getListaRangosValidacionBusqueda, token, page, limiteInf, limiteSup, nombre, ordenamiento);
        console.log(response);

        if(response.status==200){
            const listaBusquedaRangosValidacion = response.data.data;
            const listaBusquedaRangosValidacionCantidad = response.data.cantidad;

            function numberWithCommas(x) { let parts = x.toString().split("."); parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); return parts.join("."); }

            for(let i in listaBusquedaRangosValidacion){
                let fechaAlta = listaBusquedaRangosValidacion[i].fecha.split('T')[0];
                listaBusquedaRangosValidacion[i].fechaAltaTabla =  fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];

                listaBusquedaRangosValidacion[i].rangInf = numberWithCommas(listaBusquedaRangosValidacion[i].limiteInferior.toFixed(2));
                listaBusquedaRangosValidacion[i].rangSup = numberWithCommas(listaBusquedaRangosValidacion[i].limiteSuperior.toFixed(2));
            }

            yield put({ type: configuracionYSeguridadActions.LISTA_BUSQUEDA_RANGOS_VALIDACION_SUCCESS, listaBusquedaRangosValidacion, listaBusquedaRangosValidacionCantidad });
        }
        else{
            yield put({ type: configuracionYSeguridadActions.LISTA_BUSQUEDA_RANGOS_VALIDACION_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_BUSQUEDA_RANGOS_VALIDACION_FAILURE });
    }
} export function* listaRangosValidacionBusquedaSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_BUSQUEDA_RANGOS_VALIDACION_REQUEST, listaRangosValidacionBusqueda);
}

function* listaRangosValidacionActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let response = yield call(getRangosValidacionActivosCall, token);
        console.log(response);

        if(response.status==200){
            const listaActivosRangosValidacion = response.data.data;

            function numberWithCommas(x) { let parts = x.toString().split("."); parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); return parts.join("."); }

            for(let i in listaActivosRangosValidacion){
                let fechaAlta = listaActivosRangosValidacion[i].fecha.split('T')[0];
                listaActivosRangosValidacion[i].fechaAltaTabla =  fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
                listaActivosRangosValidacion[i].rangInf = numberWithCommas(listaActivosRangosValidacion[i].limiteInferior.toFixed(2));
                listaActivosRangosValidacion[i].rangSup = numberWithCommas(listaActivosRangosValidacion[i].limiteSuperior.toFixed(2));
            }

            yield put({ type: configuracionYSeguridadActions.LISTA_ACTIVOS_RANGOS_VALIDACION_SUCCESS, listaActivosRangosValidacion });
        }
        else{
            yield put({ type: configuracionYSeguridadActions.LISTA_ACTIVOS_RANGOS_VALIDACION_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_ACTIVOS_RANGOS_VALIDACION_FAILURE });
    }
} export function* listaRangosValidacionActivosSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_ACTIVOS_RANGOS_VALIDACION_REQUEST, listaRangosValidacionActivos);
}

function* nuevoRangoValidacion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idRangoDeValidacion": null, 
            "nombre": action.formulario.nombre,
            "limiteInferior": action.formulario.limiteInferior,
            "limiteSuperior": action.formulario.limiteSuperior,
            "fecha": null,
            "fechaActualizacion": null,
            "activo": true
        }

        console.log(data);

        const response = yield call(crearRangoValidacionCall, token, data);
        console.log(response);

        if (response.status == 200) {
            const textMessage = "El rango de validación se creó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearRangoDeValidacion' });
            yield put({ type: configuracionYSeguridadActions.NUEVO_RANGO_VALIDACION_SUCCESS });

            yield put({ type: configuracionYSeguridadActions.LISTA_BUSQUEDA_RANGOS_VALIDACION_REQUEST, page: action.page, busqueda: action.busqueda, orden: action.orden});
            yield put({ type: configuracionYSeguridadActions.SHOW_MODAL_NUEVO_RANGO_VALIDACION, showModalNuevoRangoValidacion: false});
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.NUEVO_RANGO_VALIDACION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.NUEVO_RANGO_VALIDACION_FAILURE });
    }
} export function* nuevoRangoValidacionSaga() {
    yield takeLatest(configuracionYSeguridadActions.NUEVO_RANGO_VALIDACION_REQUEST, nuevoRangoValidacion);
}

function* cambiarEstadoRangoValidacion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idRangoDeValidacion = action.idRangoDeValidacion;

        const response = yield call(estadoRangosValidacionCall, token, idRangoDeValidacion);
        console.log(response);

        if(response.status==200){
            const textMessage = "El estado del rango de validación cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage });
            yield put({ type: configuracionYSeguridadActions.ESTADO_RANGO_VALIDACION_SUCCESS });
            yield put({ type: configuracionYSeguridadActions.LISTA_BUSQUEDA_RANGOS_VALIDACION_REQUEST, page: action.page, busqueda: action.busqueda, orden: action.orden });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.ESTADO_RANGO_VALIDACION_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.ESTADO_RANGO_VALIDACION_FAILURE });
    }
} export function* cambiarEstadoRangoValidacionSaga() {
    yield takeLatest(configuracionYSeguridadActions.ESTADO_RANGO_VALIDACION_REQUEST, cambiarEstadoRangoValidacion);
}

function* editarRangosValidacion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idRangoDeValidacion = action.rangoValidacionSeleccionado.idRangoDeValidacion;

        const data = {
            "idRangoDeValidacion": action.rangoValidacionSeleccionado.idRangoDeValidacion, 
            "nombre": action.formulario.nombre,
            "limiteInferior": action.formulario.limiteInferior,
            "limiteSuperior": action.formulario.limiteSuperior,
            "fecha": null,
            "fechaActualizacion": null,
            "activo": action.rangoValidacionSeleccionado.activo
        }
        console.log(data);

        const response = yield call(editarRangosValidacionCall, token, data, idRangoDeValidacion);
        console.log(response);

        if (response.status == 200) {
            const textMessage = "El rango de validaación: " + action.formulario.nombre + " se editó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarRangoDeValidacion' });
            yield put({ type: configuracionYSeguridadActions.EDITAR_RANGO_VALIDACION_SUCCESS });

            yield put({ type: configuracionYSeguridadActions.LISTA_BUSQUEDA_RANGOS_VALIDACION_REQUEST, page: action.page, busqueda: action.busqueda, orden: action.orden });
            yield put({ type: configuracionYSeguridadActions.SHOW_MODAL_EDITAR_RANGO_VALIDACION, showModalEditarRangoValidacion: false });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.EDITAR_RANGO_VALIDACION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.EDITAR_RANGO_VALIDACION_FAILURE });
    }
}
export function* editarRangosValidacionSaga() {
    yield takeLatest(configuracionYSeguridadActions.EDITAR_RANGO_VALIDACION_REQUEST, editarRangosValidacion);
}

function* exportarExcelRangosValidacion(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(exportarExcelRangosValidacionCall, token);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el excel de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "RangosValidacion" + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.EXPORT_EXCEL_RANGOS_VALIDACION_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el archivo";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.EXPORT_EXCEL_RANGOS_VALIDACION_FAILURE });
        }
    } catch (error) {
       yield put({ type: configuracionYSeguridadActions.EXPORT_EXCEL_RANGOS_VALIDACION_FAILURE });
    }
} export function* exportarExcelRangosValidacionSaga(){
    yield takeLatest(configuracionYSeguridadActions.EXPORT_EXCEL_RANGOS_VALIDACION_REQUEST, exportarExcelRangosValidacion)
}

// Responsables de Servicio
function* nuevoResponsableDeServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idsServicios = [];
        let idsPartidas = [];
        let objResponsables = [];

        for(let i in action.formulario.idsServicios){ idsServicios.push(action.formulario.idsServicios[i].split(' - ')[0]); }
        for(let i in action.formulario.idPartidas){ idsPartidas.push(action.formulario.idPartidas[i].split('-')[0]); }

        for(let x in idsServicios){
            let idServ = [idsServicios[x]];
            let idsPartidasByServ = [];
            let idsPartidasCompartidas = [];

            let responsePartidas = yield call(listaPartidasByIdsServiciosCall, token, idServ);
            if(responsePartidas.status === 200){
                for(let i in responsePartidas.data){
                    idsPartidasByServ.push(responsePartidas.data[i].idPartida);
                }
            }

            for(let i in idsPartidas){
                for(let j in idsPartidasByServ){
                    if(idsPartidas[i] == idsPartidasByServ[j]){
                        idsPartidasCompartidas.push(idsPartidas[i]);
                    }
                }
            }

            if(idsPartidasByServ.length === 0){
                //Caso en que el servicio no cuente con partidas.
                let responsablesServicioResponse = [];
                let responsablesATResponse = [];
                let responsablesPYRSResponse = [];

                if(action.formulario.responsablePYRS){
                    for(let i in action.formulario.responsablePYRS){
                        const respoPYRS = {
                            "idResponsable": null,
                            "idServicio": idsServicios[x],
                            "idPartida": null,
                            // "tipoResponsable": "PYRS",
                            'idUsuarioResponsable': action.formulario.responsablePYRS[i].responsablePYRS.split('-')[0],
                            'idRangoDeValidacion': null,
                            'idUsuarioSuplente': action.formulario.responsablePYRS[i].collapsedPYRS ? action.formulario.responsablePYRS[i].collapsedPYRS.length === 1 ? action.formulario.responsablePYRS[i].suplentePYRS.split('-')[0] : null : null,
                            'fechaInicialSuplencia': action.formulario.responsablePYRS[i].collapsedPYRS ? action.formulario.responsablePYRS[i].collapsedPYRS.length === 1 ? action.formulario.responsablePYRS[i].suplenciaPYRS[0].format('YYYY-MM-DD') : null : null,
                            'fechaFinalSuplencia': action.formulario.responsablePYRS[i].collapsedPYRS ? action.formulario.responsablePYRS[i].collapsedPYRS.length === 1 ? action.formulario.responsablePYRS[i].suplenciaPYRS[1].format('YYYY-MM-DD') : null : null,
                            "fechaAlta": null,
                            "fechaActualizacion": null,
                            "activo": true,
                            "partidaByIdPartida": null,
                            "rangoDeValidacionByIdRandeDeValidacion": null,
                            "servicioByIdServicio": null,
                            "usuarioResponsableByIdUsuario": null,
                            "usuarioSuplenteByIdUsuario": null
                        };
                        responsablesPYRSResponse.push(respoPYRS);
                    }
                }

                if(action.formulario.responsableAT){
                    for(let i in action.formulario.responsableAT){
                        const respoAT = {
                            "idResponsable": null,
                            "idServicio": idsServicios[x],
                            "idPartida": null,
                            // "tipoResponsable": "AT",
                            'idUsuarioResponsable': action.formulario.responsableAT[i].responsableAT.split('-')[0],
                            'idRangoDeValidacion': null,
                            'idUsuarioSuplente': action.formulario.responsableAT[i].collapsedAT ? action.formulario.responsableAT[i].collapsedAT.length === 1 ? action.formulario.responsableAT[i].suplenteAT.split('-')[0] : null : null,
                            'fechaInicialSuplencia': action.formulario.responsableAT[i].collapsedAT ? action.formulario.responsableAT[i].collapsedAT.length === 1 ? action.formulario.responsableAT[i].suplenciaAT[0].format('YYYY-MM-DD') : null : null,
                            'fechaFinalSuplencia': action.formulario.responsableAT[i].collapsedAT ? action.formulario.responsableAT[i].collapsedAT.length === 1 ? action.formulario.responsableAT[i].suplenciaAT[1].format('YYYY-MM-DD') : null : null,
                            "fechaAlta": null,
                            "fechaActualizacion": null,
                            "activo": true,
                            "partidaByIdPartida": null,
                            "rangoDeValidacionByIdRandeDeValidacion": null,
                            "servicioByIdServicio": null,
                            "usuarioResponsableByIdUsuario": null,
                            "usuarioSuplenteByIdUsuario": null
                        };
                        responsablesATResponse.push(respoAT);
                    }
                }

                if(action.formulario.responsableServicio){
                    for(let i in action.formulario.responsableServicio){
                        const respoSer = {
                            "idResponsable": null,
                            "idServicio": idsServicios[x],
                            "idPartida": null,
                            // "tipoResponsable": "Servicio",
                            'idUsuarioResponsable': action.formulario.responsableServicio[i].responsableS.split('-')[0],
                            'idRangoDeValidacion': action.formulario.responsableServicio[i].rangoValidacion,
                            'idUsuarioSuplente': action.formulario.responsableServicio[i].collapsedRS ? action.formulario.responsableServicio[i].collapsedRS.length === 1 ? action.formulario.responsableServicio[i].suplenteRS.split('-')[0] : null : null,
                            'fechaInicialSuplencia': action.formulario.responsableServicio[i].collapsedRS ? action.formulario.responsableServicio[i].collapsedRS.length === 1 ? action.formulario.responsableServicio[i].fechaSuplenciaRS[0].format('YYYY-MM-DD') : null : null,
                            'fechaFinalSuplencia': action.formulario.responsableServicio[i].collapsedRS ? action.formulario.responsableServicio[i].collapsedRS.length === 1 ? action.formulario.responsableServicio[i].fechaSuplenciaRS[1].format('YYYY-MM-DD') : null : null,
                            "fechaAlta": null,
                            "fechaActualizacion": null,
                            "firma": action.formulario.responsableServicio[i].firma === 'true' ? true : false,
                            "activo": true,
                            "partidaByIdPartida": null,
                            "rangoDeValidacionByIdRandeDeValidacion": null,
                            "servicioByIdServicio": null,
                            "usuarioResponsableByIdUsuario": null,
                            "usuarioSuplenteByIdUsuario": null
                        };
                        responsablesServicioResponse.push(respoSer);
                    }
                }

                let responsables = {
                    "idServicio": null,
                    "idPartida": null,
                    "nombreServicio": null,
                    "nombrePartida": null,
                    "responsablesServicioResponse": responsablesServicioResponse,
                    "responsablesATResponse": responsablesATResponse,
                    "responsablesPYRSResponse": responsablesPYRSResponse,
                }

                objResponsables.push(responsables);
            } else {
                //Caso en el que el servicio contenga partidas.
                if(idsPartidasCompartidas.length > 0){
                    //Validar que al menos se haya seleccionado una partida del servicio
                    for(let y in idsPartidasCompartidas){
                    let responsablesServicioResponse = [];
                    let responsablesATResponse = [];
                    let responsablesPYRSResponse = [];

                        if(action.formulario.responsablePYRS){
                            for(let i in action.formulario.responsablePYRS){
                                const respoPYRS = {
                                    "idResponsable": null,
                                    "idServicio": idsServicios[x],
                                    "idPartida": idsPartidasCompartidas[y],
                                    // "tipoResponsable": "PYRS",
                                    'idUsuarioResponsable': action.formulario.responsablePYRS[i].responsablePYRS.split('-')[0],
                                    'idRangoDeValidacion': null,
                                    'idUsuarioSuplente': action.formulario.responsablePYRS[i].collapsedPYRS ? action.formulario.responsablePYRS[i].collapsedPYRS.length === 1 ? action.formulario.responsablePYRS[i].suplentePYRS.split('-')[0] : null : null,
                                    'fechaInicialSuplencia': action.formulario.responsablePYRS[i].collapsedPYRS ? action.formulario.responsablePYRS[i].collapsedPYRS.length === 1 ? action.formulario.responsablePYRS[i].suplenciaPYRS[0].format('YYYY-MM-DD') : null : null,
                                    'fechaFinalSuplencia': action.formulario.responsablePYRS[i].collapsedPYRS ? action.formulario.responsablePYRS[i].collapsedPYRS.length === 1 ? action.formulario.responsablePYRS[i].suplenciaPYRS[1].format('YYYY-MM-DD') : null : null,
                                    "fechaAlta": null,
                                    "fechaActualizacion": null,
                                    "activo": true,
                                    "partidaByIdPartida": null,
                                    "rangoDeValidacionByIdRandeDeValidacion": null,
                                    "servicioByIdServicio": null,
                                    "usuarioResponsableByIdUsuario": null,
                                    "usuarioSuplenteByIdUsuario": null
                                };
                                responsablesPYRSResponse.push(respoPYRS);
                            }
                        }

                        if(action.formulario.responsableAT){
                            for(let i in action.formulario.responsableAT){
                                const respoAT = {
                                    "idResponsable": null,
                                    "idServicio": idsServicios[x],
                                    "idPartida": idsPartidasCompartidas[y],
                                    // "tipoResponsable": "AT",
                                    'idUsuarioResponsable': action.formulario.responsableAT[i].responsableAT.split('-')[0],
                                    'idRangoDeValidacion': null,
                                    'idUsuarioSuplente': action.formulario.responsableAT[i].collapsedAT ? action.formulario.responsableAT[i].collapsedAT.length === 1 ? action.formulario.responsableAT[i].suplenteAT.split('-')[0] : null : null,
                                    'fechaInicialSuplencia': action.formulario.responsableAT[i].collapsedAT ? action.formulario.responsableAT[i].collapsedAT.length === 1 ? action.formulario.responsableAT[i].suplenciaAT[0].format('YYYY-MM-DD') : null : null,
                                    'fechaFinalSuplencia': action.formulario.responsableAT[i].collapsedAT ? action.formulario.responsableAT[i].collapsedAT.length === 1 ? action.formulario.responsableAT[i].suplenciaAT[1].format('YYYY-MM-DD') : null : null,
                                    "fechaAlta": null,
                                    "fechaActualizacion": null,
                                    "activo": true,
                                    "partidaByIdPartida": null,
                                    "rangoDeValidacionByIdRandeDeValidacion": null,
                                    "servicioByIdServicio": null,
                                    "usuarioResponsableByIdUsuario": null,
                                    "usuarioSuplenteByIdUsuario": null
                                };
                                responsablesATResponse.push(respoAT);
                            }
                        }

                        if(action.formulario.responsableServicio){
                            for(let i in action.formulario.responsableServicio){
                                const respoSer = {
                                    "idResponsable": null,
                                    "idServicio": idsServicios[x],
                                    "idPartida": idsPartidasCompartidas[y],
                                    // "tipoResponsable": "Servicio",
                                    'idUsuarioResponsable': action.formulario.responsableServicio[i].responsableS.split('-')[0],
                                    'idRangoDeValidacion': action.formulario.responsableServicio[i].rangoValidacion,
                                    'idUsuarioSuplente': action.formulario.responsableServicio[i].collapsedRS ? action.formulario.responsableServicio[i].collapsedRS.length === 1 ? action.formulario.responsableServicio[i].suplenteRS.split('-')[0] : null : null,
                                    'fechaInicialSuplencia': action.formulario.responsableServicio[i].collapsedRS ? action.formulario.responsableServicio[i].collapsedRS.length === 1 ? action.formulario.responsableServicio[i].fechaSuplenciaRS[0].format('YYYY-MM-DD') : null : null,
                                    'fechaFinalSuplencia': action.formulario.responsableServicio[i].collapsedRS ? action.formulario.responsableServicio[i].collapsedRS.length === 1 ? action.formulario.responsableServicio[i].fechaSuplenciaRS[1].format('YYYY-MM-DD') : null : null,
                                    "fechaAlta": null,
                                    "fechaActualizacion": null,
                                    "firma": action.formulario.responsableServicio[i].firma === 'true' ? true : false,
                                    "activo": true,
                                    "partidaByIdPartida": null,
                                    "rangoDeValidacionByIdRandeDeValidacion": null,
                                    "servicioByIdServicio": null,
                                    "usuarioResponsableByIdUsuario": null,
                                    "usuarioSuplenteByIdUsuario": null
                                };
                                responsablesServicioResponse.push(respoSer);
                            }
                        }

                        let responsables = {
                            "idServicio": null,
                            "idPartida": null,
                            "nombreServicio": null,
                            "nombrePartida": null,
                            "responsablesServicioResponse": responsablesServicioResponse,
                            "responsablesATResponse": responsablesATResponse,
                            "responsablesPYRSResponse": responsablesPYRSResponse,
                        }

                        objResponsables.push(responsables);
                    }
                }
            }
        }

        console.log('objResponsables', objResponsables)

        let response = yield call(crearResponsableCall, token, objResponsables);
        console.log(response);

        if(response.status==200){
            const textMessage = "El responsable se creó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearResponsable' });
            yield put({ type: configuracionYSeguridadActions.NUEVO_RESPONSABLE_SUCCESS });

            yield put({ type: configuracionYSeguridadActions.LISTA_BUSQUEDA_RESPONSABLES_REQUEST, page: action.page, busqueda: action.busqueda, orden: action.orden});
            yield put({ type: configuracionYSeguridadActions.SHOW_MODAL_NUEVO_RESPONSABLE, showModalNuevoResponsable: false});

            
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: configuracionYSeguridadActions.NUEVO_RESPONSABLE_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.NUEVO_RESPONSABLE_FAILURE });
    }
} export function* nuevoResponsableDeServicioSaga() {
    yield takeLatest(configuracionYSeguridadActions.NUEVO_RESPONSABLE_REQUEST, nuevoResponsableDeServicio);
}

function* listaResponsablesBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page ? (action.page - 1) * 10 : 0;
        let nombre = action.busqueda  ? (action.busqueda.responsable ? '&nombre=' + action.busqueda.responsable : '') : '';
        let servicio = action.busqueda  ? (action.busqueda.servicio ? '&servicio=' + action.busqueda.servicio : '') : '';
        let proveedor = action.busqueda  ? (action.busqueda.proveedor ? '&proveedor=' + action.busqueda.proveedor : '') : '';
        let ordenamiento = action.orden ? (action.orden.order ? '&ordenamiento=' + action.orden.order : '&ordenamiento=ASC') : '&ordenamiento=ASC';
        let campo = action.orden ? (action.orden.field ? '&campo=' + action.orden.field : '&campo=IdResponsable') : '&campo=IdResponsable';

        let response = yield call(listaResponsablesBusquedaCall, token, page, nombre, servicio, proveedor, ordenamiento, campo);
        console.log(response);

        if(response.status==200){
            const listaBusquedaResponsableServicio = response.data.data;
            const listaBusquedaResponsableServicioCantidad = response.data.cantidad;

            function numberWithCommas(x) { let parts = x.toString().split("."); parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); return parts.join("."); }

            for(let i in listaBusquedaResponsableServicio){


                if(listaBusquedaResponsableServicio[i].responsablesServicioResponse){
                    if(listaBusquedaResponsableServicio[i].responsablesServicioResponse[0]){
                        if(listaBusquedaResponsableServicio[i].responsablesServicioResponse[0].servicioByIdServicio){
                            listaBusquedaResponsableServicio[i].servicioEditar = listaBusquedaResponsableServicio[i].idServicio + ' - ' + listaBusquedaResponsableServicio[i].responsablesServicioResponse[0].servicioByIdServicio.nomenclatura + ': ' + listaBusquedaResponsableServicio[i].responsablesServicioResponse[0].servicioByIdServicio.nombre;

                            listaBusquedaResponsableServicio[i].nombreServicioTabla = listaBusquedaResponsableServicio[i].responsablesServicioResponse[0].servicioByIdServicio.nombre;

                            listaBusquedaResponsableServicio[i].urlImgServicio = listaBusquedaResponsableServicio[i].responsablesServicioResponse[0].servicioByIdServicio.urlImagen;
                        }

                        if(listaBusquedaResponsableServicio[i].responsablesServicioResponse[0].partidaByIdPartida){
                            if(listaBusquedaResponsableServicio[i].idPartida){
                                listaBusquedaResponsableServicio[i].partidaEditar = listaBusquedaResponsableServicio[i].idPartida + '-' + listaBusquedaResponsableServicio[i].responsablesServicioResponse[0].partidaByIdPartida.nomenclatura + ': ' + listaBusquedaResponsableServicio[i].nombrePartida; 
                            }
                        }
                    }

                    listaBusquedaResponsableServicio[i].responsablesServicioResponse.forEach(item => {
                        item.tipoResponsable = 'Servicio';
                        if(item.usuarioResponsableByIdUsuario){
                            item.usuarioResponsableTabla = !item.usuarioResponsableByIdUsuario.aMaterno || item.usuarioResponsableByIdUsuario.aMaterno === '' ? item.usuarioResponsableByIdUsuario.nombre + ' ' + item.usuarioResponsableByIdUsuario.aPaterno :item.usuarioResponsableByIdUsuario.nombre + ' ' + item.usuarioResponsableByIdUsuario.aPaterno + ' ' + item.usuarioResponsableByIdUsuario.aMaterno;

                            item.correoResponsableTabla = item.usuarioResponsableByIdUsuario.username;

                            item.responsableS = !item.usuarioResponsableByIdUsuario.aMaterno || item.usuarioResponsableByIdUsuario.aMaterno === '' ?  item.usuarioResponsableByIdUsuario.idUsuario + '-' + item.usuarioResponsableByIdUsuario.nombre + ' ' + item.usuarioResponsableByIdUsuario.aPaterno + ': ' + item.usuarioResponsableByIdUsuario.username :  item.usuarioResponsableByIdUsuario.idUsuario + '-' + item.usuarioResponsableByIdUsuario.nombre + ' ' + item.usuarioResponsableByIdUsuario.aPaterno + ' ' + item.usuarioResponsableByIdUsuario.aMaterno + ': ' + item.usuarioResponsableByIdUsuario.username;
                        }

                        if(item.firma){
                            item.firmaEditar = "true";
                        } else {
                            item.firmaEditar = "false";
                        }

                        if(item.usuarioSuplenteByIdUsuario){
                            item.suplenteRS = !item.usuarioSuplenteByIdUsuario.aMaterno || item.usuarioSuplenteByIdUsuario.aMaterno === '' ? item.usuarioSuplenteByIdUsuario.idUsuario + '-' + item.usuarioSuplenteByIdUsuario.nombre + ' ' + item.usuarioSuplenteByIdUsuario.aPaterno + ': ' + item.usuarioSuplenteByIdUsuario.username : item.usuarioSuplenteByIdUsuario.idUsuario + '-' + item.usuarioSuplenteByIdUsuario.nombre + ' ' + item.usuarioSuplenteByIdUsuario.aPaterno + ' ' + item.usuarioSuplenteByIdUsuario.aMaterno + ': ' + item.usuarioSuplenteByIdUsuario.username;

                            item.suplenteTabla = !item.usuarioSuplenteByIdUsuario.aMaterno || item.usuarioSuplenteByIdUsuario.aMaterno === '' ? item.usuarioSuplenteByIdUsuario.nombre + ' ' + item.usuarioSuplenteByIdUsuario.aPaterno + ': ' + item.usuarioSuplenteByIdUsuario.username : item.usuarioSuplenteByIdUsuario.nombre + ' ' + item.usuarioSuplenteByIdUsuario.aPaterno + ' ' + item.usuarioSuplenteByIdUsuario.aMaterno + ': ' + item.usuarioSuplenteByIdUsuario.username;
                        }

                        if(item.fechaInicialSuplencia && item.fechaFinalSuplencia){
                            item.fechaSuplenciaRS = [moment(item.fechaInicialSuplencia.split('T')[0], 'YYYY-MM-DD'),moment(item.fechaFinalSuplencia.split('T')[0], 'YYYY-MM-DD')]

                            item.fechasSuplenciaTabla = item.fechaInicialSuplencia.split('T')[0].split('-').reverse().join('-') + ' al ' + item.fechaFinalSuplencia.split('T')[0].split('-').reverse().join('-');
                        }

                        if(item.rangoDeValidacionByIdRandeDeValidacion){
                            item.rangoValidacionTabla = item.rangoDeValidacionByIdRandeDeValidacion.nombre + ' (' + numberWithCommas(item.rangoDeValidacionByIdRandeDeValidacion.limiteInferior.toFixed(2)) + ' - ' + numberWithCommas(item.rangoDeValidacionByIdRandeDeValidacion.limiteSuperior.toFixed(2)) + ')';

                            item.rangoValidacionEditar = item.rangoDeValidacionByIdRandeDeValidacion.idRangoDeValidacion + '-' + item.rangoDeValidacionByIdRandeDeValidacion.nombre + ' (' + numberWithCommas(item.rangoDeValidacionByIdRandeDeValidacion.limiteInferior.toFixed(2)) + ' - ' + numberWithCommas(item.rangoDeValidacionByIdRandeDeValidacion.limiteSuperior.toFixed(2)) + ')';
                        }
                    });
                }

                if(listaBusquedaResponsableServicio[i].responsablesPYRSResponse){
                    listaBusquedaResponsableServicio[i].responsablesPYRSResponse.forEach(item => {
                        item.tipoResponsable = 'PYRS';
                        if(item.usuarioResponsableByIdUsuario){
                            item.usuarioResponsableTabla = !item.usuarioResponsableByIdUsuario.aMaterno || item.usuarioResponsableByIdUsuario.aMaterno === '' ? item.usuarioResponsableByIdUsuario.nombre + ' ' + item.usuarioResponsableByIdUsuario.aPaterno :item.usuarioResponsableByIdUsuario.nombre + ' ' + item.usuarioResponsableByIdUsuario.aPaterno + ' ' + item.usuarioResponsableByIdUsuario.aMaterno;

                            item.correoResponsableTabla = item.usuarioResponsableByIdUsuario.username;

                            item.responsablePYRS = !item.usuarioResponsableByIdUsuario.aMaterno || item.usuarioResponsableByIdUsuario.aMaterno === '' ?  item.usuarioResponsableByIdUsuario.idUsuario + '-' + item.usuarioResponsableByIdUsuario.nombre + ' ' + item.usuarioResponsableByIdUsuario.aPaterno + ': ' + item.usuarioResponsableByIdUsuario.username :  item.usuarioResponsableByIdUsuario.idUsuario + '-' + item.usuarioResponsableByIdUsuario.nombre + ' ' + item.usuarioResponsableByIdUsuario.aPaterno + ' ' + item.usuarioResponsableByIdUsuario.aMaterno + ': ' + item.usuarioResponsableByIdUsuario.username;
                        }

                        if(item.usuarioSuplenteByIdUsuario){
                            item.suplentePYRS = !item.usuarioSuplenteByIdUsuario.aMaterno || item.usuarioSuplenteByIdUsuario.aMaterno === '' ? item.usuarioSuplenteByIdUsuario.idUsuario + '-' + item.usuarioSuplenteByIdUsuario.nombre + ' ' + item.usuarioSuplenteByIdUsuario.aPaterno + ': ' + item.usuarioSuplenteByIdUsuario.username : item.usuarioSuplenteByIdUsuario.idUsuario + '-' + item.usuarioSuplenteByIdUsuario.nombre + ' ' + item.usuarioSuplenteByIdUsuario.aPaterno + ' ' + item.usuarioSuplenteByIdUsuario.aMaterno + ': ' + item.usuarioSuplenteByIdUsuario.username;

                            item.suplenteTabla = !item.usuarioSuplenteByIdUsuario.aMaterno || item.usuarioSuplenteByIdUsuario.aMaterno === '' ? item.usuarioSuplenteByIdUsuario.nombre + ' ' + item.usuarioSuplenteByIdUsuario.aPaterno + ': ' + item.usuarioSuplenteByIdUsuario.username : item.usuarioSuplenteByIdUsuario.nombre + ' ' + item.usuarioSuplenteByIdUsuario.aPaterno + ' ' + item.usuarioSuplenteByIdUsuario.aMaterno + ': ' + item.usuarioSuplenteByIdUsuario.username;
                        }

                        if(item.fechaInicialSuplencia && item.fechaFinalSuplencia){
                            item.suplenciaPYRS = [moment(item.fechaInicialSuplencia.split('T')[0], 'YYYY-MM-DD'),moment(item.fechaFinalSuplencia.split('T')[0], 'YYYY-MM-DD')]

                            item.fechasSuplenciaTabla = item.fechaInicialSuplencia.split('T')[0].split('-').reverse().join('-') + ' al ' + item.fechaFinalSuplencia.split('T')[0].split('-').reverse().join('-');
                        }
                    });
                }

                if(listaBusquedaResponsableServicio[i].responsablesATResponse){
                    listaBusquedaResponsableServicio[i].responsablesATResponse.forEach(item => {
                        item.tipoResponsable = 'AT';
                        if(item.usuarioResponsableByIdUsuario){
                            item.usuarioResponsableTabla = !item.usuarioResponsableByIdUsuario.aMaterno || item.usuarioResponsableByIdUsuario.aMaterno === '' ? item.usuarioResponsableByIdUsuario.nombre + ' ' + item.usuarioResponsableByIdUsuario.aPaterno :item.usuarioResponsableByIdUsuario.nombre + ' ' + item.usuarioResponsableByIdUsuario.aPaterno + ' ' + item.usuarioResponsableByIdUsuario.aMaterno;

                            item.correoResponsableTabla = item.usuarioResponsableByIdUsuario.username;

                            item.responsableAT = !item.usuarioResponsableByIdUsuario.aMaterno || item.usuarioResponsableByIdUsuario.aMaterno === '' ?  item.usuarioResponsableByIdUsuario.idUsuario + '-' + item.usuarioResponsableByIdUsuario.nombre + ' ' + item.usuarioResponsableByIdUsuario.aPaterno + ': ' + item.usuarioResponsableByIdUsuario.username :  item.usuarioResponsableByIdUsuario.idUsuario + '-' + item.usuarioResponsableByIdUsuario.nombre + ' ' + item.usuarioResponsableByIdUsuario.aPaterno + ' ' + item.usuarioResponsableByIdUsuario.aMaterno + ': ' + item.usuarioResponsableByIdUsuario.username;
                        }

                        if(item.usuarioSuplenteByIdUsuario){
                            item.suplenteAT = !item.usuarioSuplenteByIdUsuario.aMaterno || item.usuarioSuplenteByIdUsuario.aMaterno === '' ? item.usuarioSuplenteByIdUsuario.idUsuario + '-' + item.usuarioSuplenteByIdUsuario.nombre + ' ' + item.usuarioSuplenteByIdUsuario.aPaterno + ': ' + item.usuarioSuplenteByIdUsuario.username : item.usuarioSuplenteByIdUsuario.idUsuario + '-' + item.usuarioSuplenteByIdUsuario.nombre + ' ' + item.usuarioSuplenteByIdUsuario.aPaterno + ' ' + item.usuarioSuplenteByIdUsuario.aMaterno + ': ' + item.usuarioSuplenteByIdUsuario.username;

                            item.suplenteTabla = !item.usuarioSuplenteByIdUsuario.aMaterno || item.usuarioSuplenteByIdUsuario.aMaterno === '' ? item.usuarioSuplenteByIdUsuario.nombre + ' ' + item.usuarioSuplenteByIdUsuario.aPaterno + ': ' + item.usuarioSuplenteByIdUsuario.username : item.usuarioSuplenteByIdUsuario.nombre + ' ' + item.usuarioSuplenteByIdUsuario.aPaterno + ' ' + item.usuarioSuplenteByIdUsuario.aMaterno + ': ' + item.usuarioSuplenteByIdUsuario.username;
                        }

                        if(item.fechaInicialSuplencia && item.fechaFinalSuplencia){
                            item.suplenciaAT = [moment(item.fechaInicialSuplencia.split('T')[0], 'YYYY-MM-DD'),moment(item.fechaFinalSuplencia.split('T')[0], 'YYYY-MM-DD')]

                            item.fechasSuplenciaTabla = item.fechaInicialSuplencia.split('T')[0].split('-').reverse().join('-') + ' al ' + item.fechaFinalSuplencia.split('T')[0].split('-').reverse().join('-');
                        }
                    });
                }
            }

            yield put({ type: configuracionYSeguridadActions.LISTA_BUSQUEDA_RESPONSABLES_SUCCESS, listaBusquedaResponsableServicio, listaBusquedaResponsableServicioCantidad });
        }
        else{
            yield put({ type: configuracionYSeguridadActions.LISTA_BUSQUEDA_RESPONSABLES_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_BUSQUEDA_RESPONSABLES_FAILURE });
    }
} export function* listaResponsablesBusquedaSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_BUSQUEDA_RESPONSABLES_REQUEST, listaResponsablesBusqueda);
}

function* editarResponsableServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let responsableServicioSeleccionado = [];
        let responsableATSeleccionado = [];
        let responsablePYRSSeleccionado = [];

        // responsableServicioSeleccionado.forEach(element => { element.activo = false });
        // responsableATSeleccionado.forEach(element => { element.activo = false });
        // responsablePYRSSeleccionado.forEach(element => { element.activo = false });

        for(let i in action.responsableSeleccionado.responsablesServicioResponse){
            const respoSer = {
                "idResponsable": action.responsableSeleccionado.responsablesServicioResponse[i].idResponsable,
                "idServicio": action.responsableSeleccionado.responsablesServicioResponse[i].idServicio,
                "idPartida": action.responsableSeleccionado.responsablesServicioResponse[i].idPartida,
                // "tipoResponsable": "Servicio",
                'idUsuarioResponsable': action.responsableSeleccionado.responsablesServicioResponse[i].idUsuarioResponsable,
                'idRangoDeValidacion': action.responsableSeleccionado.responsablesServicioResponse[i].idRangoDeValidacion,
                'idUsuarioSuplente': action.responsableSeleccionado.responsablesServicioResponse[i].idUsuarioSuplente ?  action.responsableSeleccionado.responsablesServicioResponse[i].idUsuarioSuplente : null,
                'fechaInicialSuplencia': action.responsableSeleccionado.responsablesServicioResponse[i].fechaInicialSuplencia ? action.responsableSeleccionado.responsablesServicioResponse[i].fechaInicialSuplencia.split('T')[0] : null,
                'fechaFinalSuplencia': action.responsableSeleccionado.responsablesServicioResponse[i].fechaFinalSuplencia ? action.responsableSeleccionado.responsablesServicioResponse[i].fechaFinalSuplencia.split('T')[0] : null,
                "fechaAlta": null,
                "fechaActualizacion": null,
                "firma": action.responsableSeleccionado.responsablesServicioResponse[i].firma,
                "activo": false,
                "partidaByIdPartida": null,
                "rangoDeValidacionByIdRandeDeValidacion": null,
                "servicioByIdServicio": null,
                "usuarioResponsableByIdUsuario": null,
                "usuarioSuplenteByIdUsuario": null
            };
            responsableServicioSeleccionado.push(respoSer);
        }

        for(let i in action.responsableSeleccionado.responsablesATResponse){
            const respoSer = {
                "idResponsable": action.responsableSeleccionado.responsablesATResponse[i].idResponsable,
                "idServicio": action.responsableSeleccionado.responsablesATResponse[i].idServicio,
                "idPartida": action.responsableSeleccionado.responsablesATResponse[i].idPartida,
                "tipoResponsable": "AT",
                'idUsuarioResponsable': action.responsableSeleccionado.responsablesATResponse[i].idUsuarioResponsable,
                'idRangoDeValidacion': null,
                'idUsuarioSuplente': action.responsableSeleccionado.responsablesATResponse[i].idUsuarioSuplente ?  action.responsableSeleccionado.responsablesATResponse[i].idUsuarioSuplente : null,
                'fechaInicialSuplencia': action.responsableSeleccionado.responsablesATResponse[i].fechaInicialSuplencia ? action.responsableSeleccionado.responsablesATResponse[i].fechaInicialSuplencia.split('T')[0] : null,
                'fechaFinalSuplencia': action.responsableSeleccionado.responsablesATResponse[i].fechaFinalSuplencia ? action.responsableSeleccionado.responsablesATResponse[i].fechaFinalSuplencia.split('T')[0] : null,
                "fechaAlta": null,
                "fechaActualizacion": null,
                "activo": false,
                "partidaByIdPartida": null,
                "rangoDeValidacionByIdRandeDeValidacion": null,
                "servicioByIdServicio": null,
                "usuarioResponsableByIdUsuario": null,
                "usuarioSuplenteByIdUsuario": null
            };
            responsableATSeleccionado.push(respoSer);
        }

        for(let i in action.responsableSeleccionado.responsablesPYRSResponse){
            const respoSer = {
                "idResponsable": action.responsableSeleccionado.responsablesPYRSResponse[i].idResponsable,
                "idServicio": action.responsableSeleccionado.responsablesPYRSResponse[i].idServicio,
                "idPartida": action.responsableSeleccionado.responsablesPYRSResponse[i].idPartida,
                // "tipoResponsable": "PYRS",
                'idUsuarioResponsable': action.responsableSeleccionado.responsablesPYRSResponse[i].idUsuarioResponsable,
                'idRangoDeValidacion': null,
                'idUsuarioSuplente': action.responsableSeleccionado.responsablesPYRSResponse[i].idUsuarioSuplente ?  action.responsableSeleccionado.responsablesPYRSResponse[i].idUsuarioSuplente : null,
                'fechaInicialSuplencia': action.responsableSeleccionado.responsablesPYRSResponse[i].fechaInicialSuplencia ? action.responsableSeleccionado.responsablesPYRSResponse[i].fechaInicialSuplencia.split('T')[0] : null,
                'fechaFinalSuplencia': action.responsableSeleccionado.responsablesPYRSResponse[i].fechaFinalSuplencia ? action.responsableSeleccionado.responsablesPYRSResponse[i].fechaFinalSuplencia.split('T')[0] : null,
                "fechaAlta": null,
                "fechaActualizacion": null,
                "activo": false,
                "partidaByIdPartida": null,
                "rangoDeValidacionByIdRandeDeValidacion": null,
                "servicioByIdServicio": null,
                "usuarioResponsableByIdUsuario": null,
                "usuarioSuplenteByIdUsuario": null
            };
            responsablePYRSSeleccionado.push(respoSer);
        }

        let responsablesServicioResponse = [];
        let responsablesATResponse = [];
        let responsablesPYRSResponse = [];

        if(action.formulario.responsablesServicioResponse){
            for(let i in action.formulario.responsablesServicioResponse){
                const respoSer = {
                    "idResponsable": action.formulario.responsablesServicioResponse[i].idResponsable ? action.formulario.responsablesServicioResponse[i].idResponsable : null,
                    "idServicio": action.responsableSeleccionado.idServicio,
                    "idPartida": action.responsableSeleccionado.idPartida,
                    // "tipoResponsable": "Servicio",
                    'idUsuarioResponsable': action.formulario.responsablesServicioResponse[i].responsableS.split('-')[0],
                    'idRangoDeValidacion': action.formulario.responsablesServicioResponse[i].rangoValidacionEditar.split('-')[0],
                    'idUsuarioSuplente': action.formulario.responsablesServicioResponse[i].collapsedRS ? action.formulario.responsablesServicioResponse[i].collapsedRS.length === 1 ? action.formulario.responsablesServicioResponse[i].suplenteRS.split('-')[0] : null : action.formulario.responsablesServicioResponse[i].fechaSuplenciaRS && action.formulario.responsablesServicioResponse[i].suplenteRS ? action.formulario.responsablesServicioResponse[i].suplenteRS.split('-')[0] : null,
                    'fechaInicialSuplencia': action.formulario.responsablesServicioResponse[i].collapsedRS ? action.formulario.responsablesServicioResponse[i].collapsedRS.length === 1 ? action.formulario.responsablesServicioResponse[i].fechaSuplenciaRS[0].format('YYYY-MM-DD') : null : action.formulario.responsablesServicioResponse[i].fechaSuplenciaRS && action.formulario.responsablesServicioResponse[i].suplenteRS ? action.formulario.responsablesServicioResponse[i].fechaSuplenciaRS[0].format('YYYY-MM-DD') : null,
                    'fechaFinalSuplencia': action.formulario.responsablesServicioResponse[i].collapsedRS ? action.formulario.responsablesServicioResponse[i].collapsedRS.length === 1 ? action.formulario.responsablesServicioResponse[i].fechaSuplenciaRS[1].format('YYYY-MM-DD') : null : action.formulario.responsablesServicioResponse[i].fechaSuplenciaRS && action.formulario.responsablesServicioResponse[i].suplenteRS ? action.formulario.responsablesServicioResponse[i].fechaSuplenciaRS[1].format('YYYY-MM-DD') : null,
                    "fechaAlta": null,
                    "fechaActualizacion": null,
                    "firma": action.formulario.responsablesServicioResponse[i].firmaEditar === 'true' ? true : false,
                    "activo": true,
                    "partidaByIdPartida": null,
                    "rangoDeValidacionByIdRandeDeValidacion": null,
                    "servicioByIdServicio": null,
                    "usuarioResponsableByIdUsuario": null,
                    "usuarioSuplenteByIdUsuario": null
                };
                responsablesServicioResponse.push(respoSer);
            }
        }

        if(action.formulario.responsablesATResponse){
            for(let i in action.formulario.responsablesATResponse){
                const respoSer = {
                    "idResponsable": action.formulario.responsablesATResponse[i].idResponsable ? action.formulario.responsablesATResponse[i].idResponsable : null,
                    "idServicio": action.responsableSeleccionado.idServicio,
                    "idPartida": action.responsableSeleccionado.idPartida,
                    // "tipoResponsable": "AT",
                    'idUsuarioResponsable': action.formulario.responsablesATResponse[i].responsableAT.split('-')[0],
                    'idRangoDeValidacion': null,
                    'idUsuarioSuplente': action.formulario.responsablesATResponse[i].collapsedAT ? action.formulario.responsablesATResponse[i].collapsedAT.length === 1 ? action.formulario.responsablesATResponse[i].suplenteAT.split('-')[0] : null : action.formulario.responsablesATResponse[i].suplenteAT && action.formulario.responsablesATResponse[i].suplenciaAT ? action.formulario.responsablesATResponse[i].suplenteAT.split('-')[0] : null,
                    'fechaInicialSuplencia': action.formulario.responsablesATResponse[i].collapsedAT ? action.formulario.responsablesATResponse[i].collapsedAT.length === 1 ? action.formulario.responsablesATResponse[i].suplenciaAT[0].format('YYYY-MM-DD') : null : action.formulario.responsablesATResponse[i].suplenteAT && action.formulario.responsablesATResponse[i].suplenciaAT ? action.formulario.responsablesATResponse[i].suplenciaAT[0].format('YYYY-MM-DD') : null,
                    'fechaFinalSuplencia': action.formulario.responsablesATResponse[i].collapsedAT ? action.formulario.responsablesATResponse[i].collapsedAT.length === 1 ? action.formulario.responsablesATResponse[i].suplenciaAT[1].format('YYYY-MM-DD') : null : action.formulario.responsablesATResponse[i].suplenteAT && action.formulario.responsablesATResponse[i].suplenciaAT ? action.formulario.responsablesATResponse[i].suplenciaAT[1].format('YYYY-MM-DD') : null,
                    "fechaAlta": null,
                    "fechaActualizacion": null,
                    "activo": true,
                    "partidaByIdPartida": null,
                    "rangoDeValidacionByIdRandeDeValidacion": null,
                    "servicioByIdServicio": null,
                    "usuarioResponsableByIdUsuario": null,
                    "usuarioSuplenteByIdUsuario": null
                };
                responsablesATResponse.push(respoSer);
            }
        }

        if(action.formulario.responsablesPYRSResponse){
            for(let i in action.formulario.responsablesPYRSResponse){
                const respoSer = {
                    "idResponsable": action.formulario.responsablesPYRSResponse[i].idResponsable ? action.formulario.responsablesPYRSResponse[i].idResponsable : null,
                    "idServicio": action.responsableSeleccionado.idServicio,
                    "idPartida": action.responsableSeleccionado.idPartida,
                    // "tipoResponsable": "PYRS",
                    'idUsuarioResponsable': action.formulario.responsablesPYRSResponse[i].responsablePYRS.split('-')[0],
                    'idRangoDeValidacion': null,
                    'idUsuarioSuplente': action.formulario.responsablesPYRSResponse[i].collapsedPYRS ? action.formulario.responsablesPYRSResponse[i].collapsedPYRS.length === 1 ? action.formulario.responsablesPYRSResponse[i].suplentePYRS.split('-')[0] : null : action.formulario.responsablesPYRSResponse[i].suplentePYRS && action.formulario.responsablesPYRSResponse[i].suplenciaPYRS ? action.formulario.responsablesPYRSResponse[i].suplentePYRS.split('-')[0] : null,
                    'fechaInicialSuplencia': action.formulario.responsablesPYRSResponse[i].collapsedPYRS ? action.formulario.responsablesPYRSResponse[i].collapsedPYRS.length === 1 ? action.formulario.responsablesPYRSResponse[i].suplenciaPYRS[0].format('YYYY-MM-DD') : null : action.formulario.responsablesPYRSResponse[i].suplentePYRS && action.formulario.responsablesPYRSResponse[i].suplenciaPYRS ? action.formulario.responsablesPYRSResponse[i].suplenciaPYRS[0].format('YYYY-MM-DD') : null,
                    'fechaFinalSuplencia': action.formulario.responsablesPYRSResponse[i].collapsedPYRS ? action.formulario.responsablesPYRSResponse[i].collapsedPYRS.length === 1 ? action.formulario.responsablesPYRSResponse[i].suplenciaPYRS[1].format('YYYY-MM-DD') : null : action.formulario.responsablesPYRSResponse[i].suplentePYRS && action.formulario.responsablesPYRSResponse[i].suplenciaPYRS ? action.formulario.responsablesPYRSResponse[i].suplenciaPYRS[1].format('YYYY-MM-DD') : null,
                    "fechaAlta": null,
                    "fechaActualizacion": null,
                    "activo": true,
                    "partidaByIdPartida": null,
                    "rangoDeValidacionByIdRandeDeValidacion": null,
                    "servicioByIdServicio": null,
                    "usuarioResponsableByIdUsuario": null,
                    "usuarioSuplenteByIdUsuario": null
                };
                responsablesPYRSResponse.push(respoSer);
            }
        }


        for(let i in responsableServicioSeleccionado){
            let existe = false;
            for(let j in responsablesServicioResponse){
                if(responsableServicioSeleccionado[i]['idResponsable'] == responsablesServicioResponse[j]['idResponsable']){ 
                    existe = true;
                }
            }
            if(!existe){ responsablesServicioResponse.push(responsableServicioSeleccionado[i]); }
        }

        for(let i in responsableATSeleccionado){
            let existe = false;
            for(let j in responsablesATResponse){
                if(responsableATSeleccionado[i]['idResponsable'] == responsablesATResponse[j]['idResponsable']){ 
                    existe = true;
                }
            }
            if(!existe){ responsablesATResponse.push(responsableATSeleccionado[i]); }
        }

        console.log('responsablePYRSSeleccionado', responsablePYRSSeleccionado);

        for(let i in responsablePYRSSeleccionado){
            let existe = false;
            for(let j in responsablesPYRSResponse){
                if(responsablePYRSSeleccionado[i]['idResponsable'] == responsablesPYRSResponse[j]['idResponsable']){ 
                    existe = true;
                }
            }
            if(!existe){ responsablesPYRSResponse.push(responsablePYRSSeleccionado[i]); }
        }

        let objResponsables = [
            {
                "idServicio": null,
                "idPartida": null,
                "nombreServicio": null,
                "nombrePartida": null,
                "responsablesServicioResponse": responsablesServicioResponse,
                "responsablesATResponse": responsablesATResponse,
                "responsablesPYRSResponse": responsablesPYRSResponse
            },
        ];

        console.log('objResponsables', objResponsables)

        let response = yield call(editarResponsableServicioCall, token, objResponsables);
        console.log(response);

        if(response.status==200){
            const textMessage = "El responsable se editó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarResponsable' });
            yield put({ type: configuracionYSeguridadActions.EDITAR_RESPONSABLE_SUCCESS });

            yield put({ type: configuracionYSeguridadActions.LISTA_BUSQUEDA_RESPONSABLES_REQUEST, page: action.page, busqueda: action.busqueda, orden: action.orden});
            yield put({ type: configuracionYSeguridadActions.SHOW_MODAL_EDITAR_RESPONSABLE, showModalEditarResponsable: false});

            
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.EDITAR_RESPONSABLE_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.EDITAR_RESPONSABLE_FAILURE });
    }
} export function* editarResponsableServicioSaga() {
    yield takeLatest(configuracionYSeguridadActions.EDITAR_RESPONSABLE_REQUEST, editarResponsableServicio);
}

function* listaHistoricoResponsables(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = (action.page - 1) * 10;
        let ordenamiento = action.orden ? (action.orden.order ? '&ordenamiento=' + action.orden.order : '&ordenamiento=DESC') : '&ordenamiento=DESC';
        let campo = action.orden ? (action.orden.field ? '&campo=' + action.orden.field : '&campo=hrse.FechaMovimiento') : '&campo=hrse.FechaMovimiento';

        let nombreServicio =  action.busqueda ? action.busqueda.nombreServicio ? '&nombreServicio=' + action.busqueda.nombreServicio : '' : '';
        let nombreResponsable = action.busqueda ? action.busqueda.nombreResponsable ? '&nombreResponsable=' + action.busqueda.nombreResponsable : '' : '';
        
        let fechaInicialSuplenciaIn = action.busqueda ? action.busqueda.fechaInicialSuplenciaIn ? '&fechaInicialSuplenciaIn=' + action.busqueda.fechaInicialSuplenciaIn : '' : '';
        let fechaInicialSuplenciaFn = action.busqueda ? action.busqueda.fechaInicialSuplenciaFn ? '&fechaInicialSuplenciaFn=' + action.busqueda.fechaInicialSuplenciaFn : '' : '';
        let fechaFinalSuplenciaIn = action.busqueda ? action.busqueda.fechaFinalSuplenciaIn ? '&fechaFinalSuplenciaIn=' + action.busqueda.fechaFinalSuplenciaIn : '' : '';
        let fechaFinalSuplenciaFn = action.busqueda ? action.busqueda.fechaFinalSuplenciaFn ? '&fechaFinalSuplenciaFn=' + action.busqueda.fechaFinalSuplenciaFn : '' : '';

        let fechaInicioIn = '';
        let fechaInicioFn = '';
        let fechaFinIn = '';
        let fechaFinFn = '';

        let response = yield call(listaHistoricoResponsablesCall, token, page, nombreServicio, nombreResponsable, fechaInicialSuplenciaIn, fechaInicialSuplenciaFn, fechaFinalSuplenciaIn, fechaFinalSuplenciaFn, fechaInicioIn, fechaInicioFn, fechaFinIn, fechaFinFn, ordenamiento, campo);
        console.log(response);

        if(response.status==200){
            const listaBusquedaHistoricoResponsable = response.data.data;
            const listaBusquedaHistoricoResponsableCantidad = response.data.cantidad;

            for(let i in listaBusquedaHistoricoResponsable){
                listaBusquedaHistoricoResponsable[i].idsServicioPartidaResponsable = listaBusquedaHistoricoResponsable[i].idServicio + '-' + listaBusquedaHistoricoResponsable[i].idPartida + '-' + listaBusquedaHistoricoResponsable[i].idUsuarioResponsable;

                // listaBusquedaHistoricoResponsable[i].fechaAlta = listaBusquedaHistoricoResponsable[i].fechaInicio.split('T')[0].split('-').reverse().join('-');
                // listaBusquedaHistoricoResponsable[i].fechaActu = listaBusquedaHistoricoResponsable[i].fechaFin.split('T')[0].split('-').reverse().join('-');

                if(listaBusquedaHistoricoResponsable[i].fechaMovimiento){
                    let fechaMovimiento = listaBusquedaHistoricoResponsable[i].fechaMovimiento.split('T')[0].split('-').reverse().join('-'); 
                    let horaMovimiento = listaBusquedaHistoricoResponsable[i].fechaMovimiento.split('T')[1];

                    listaBusquedaHistoricoResponsable[i].fechaMovimientoTabla = fechaMovimiento + ' ' + horaMovimiento.split(':')[0] + ':' + horaMovimiento.split(':')[1];
                }

                if(listaBusquedaHistoricoResponsable[i].servicioByIdServicio){
                    listaBusquedaHistoricoResponsable[i].servicioTabla = listaBusquedaHistoricoResponsable[i].servicioByIdServicio.nomenclatura + ': ' + listaBusquedaHistoricoResponsable[i].servicioByIdServicio.nombre;
                }

                if(listaBusquedaHistoricoResponsable[i].partidaByIdPartida){
                    listaBusquedaHistoricoResponsable[i].partidaTabla = listaBusquedaHistoricoResponsable[i].partidaByIdPartida.nomenclatura + ': ' + listaBusquedaHistoricoResponsable[i].partidaByIdPartida.nombre;
                } else {
                    listaBusquedaHistoricoResponsable[i].partidaTabla = 'Sin partida'
                }

                if(listaBusquedaHistoricoResponsable[i].usuarioResponsableByIdUsuario){
                    listaBusquedaHistoricoResponsable[i].tipoRespo = listaBusquedaHistoricoResponsable[i].usuarioResponsableByIdUsuario.tipoUsuario;

                    listaBusquedaHistoricoResponsable[i].usuarioResponsableTabla = !listaBusquedaHistoricoResponsable[i].usuarioResponsableByIdUsuario.aMaterno || listaBusquedaHistoricoResponsable[i].usuarioResponsableByIdUsuario.aMaterno === '' ? listaBusquedaHistoricoResponsable[i].usuarioResponsableByIdUsuario.nombre + ' ' + listaBusquedaHistoricoResponsable[i].usuarioResponsableByIdUsuario.aPaterno : listaBusquedaHistoricoResponsable[i].usuarioResponsableByIdUsuario.nombre + ' ' + listaBusquedaHistoricoResponsable[i].usuarioResponsableByIdUsuario.aPaterno + ' ' + listaBusquedaHistoricoResponsable[i].usuarioResponsableByIdUsuario.aMaterno;
                }


                if(listaBusquedaHistoricoResponsable[i].usuarioSuplenteByIdUsuario){

                    listaBusquedaHistoricoResponsable[i].usuarioSuplenteTabla = !listaBusquedaHistoricoResponsable[i].usuarioSuplenteByIdUsuario.aMaterno || listaBusquedaHistoricoResponsable[i].usuarioSuplenteByIdUsuario.aMaterno === '' ? listaBusquedaHistoricoResponsable[i].usuarioSuplenteByIdUsuario.nombre + ' ' + listaBusquedaHistoricoResponsable[i].usuarioSuplenteByIdUsuario.aPaterno : listaBusquedaHistoricoResponsable[i].usuarioSuplenteByIdUsuario.nombre + ' ' + listaBusquedaHistoricoResponsable[i].usuarioSuplenteByIdUsuario.aPaterno + ' ' + listaBusquedaHistoricoResponsable[i].usuarioSuplenteByIdUsuario.aMaterno;

                    listaBusquedaHistoricoResponsable[i].fechaInicioSuplenciaTabla = listaBusquedaHistoricoResponsable[i].fechaInicialSuplencia.split('T')[0].split('-').reverse().join('-');
                    listaBusquedaHistoricoResponsable[i].fechaFinSuplenciaTabla = listaBusquedaHistoricoResponsable[i].fechaFinalSuplencia.split('T')[0].split('-').reverse().join('-');

                } else {
                    listaBusquedaHistoricoResponsable[i].usuarioSuplenteTabla = 'Sin suplente'
                    listaBusquedaHistoricoResponsable[i].fechaInicioSuplenciaTabla = 'N/A';
                    listaBusquedaHistoricoResponsable[i].fechaFinSuplenciaTabla = 'N/A';
                }
            }

            yield put({ type: configuracionYSeguridadActions.LISTA_HISTORICO_RESPONSABLE_BUSQUEDA_SUCCESS, listaBusquedaHistoricoResponsable, listaBusquedaHistoricoResponsableCantidad });
        }
        else{
            yield put({ type: configuracionYSeguridadActions.LISTA_HISTORICO_RESPONSABLE_BUSQUEDA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_HISTORICO_RESPONSABLE_BUSQUEDA_FAILURE });
    }
}
export function* listaHistoricoResponsablesSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_HISTORICO_RESPONSABLE_BUSQUEDA_REQUEST, listaHistoricoResponsables);
}


function* getNotificaciones(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page * 10; 

        const response = yield call(getNotificacionesCall, token, page);
        console.log(response);

        if(response.status==200){
            const listaNotificaciones = response.data.data;
            const cantidadNotificaciones = response.data.cantidad;

            for(let i in listaNotificaciones){
                let fecha = listaNotificaciones[i].fechaAlta.split('T')[0].split('-').reverse().join('-');
                let hora = listaNotificaciones[i].fechaAlta.split('T')[1];
                listaNotificaciones[i].fechaHora = fecha + ' ' + hora.split(':')[0] + ':' + hora.split(':')[1];

                let compareDate = listaNotificaciones[i].fechaAlta.split('T')[0] + 'T' + hora.split(':')[0] + ':' + hora.split(':')[1]  + ':' + hora.split(':')[2];
                let diff = Math.abs(new Date() - new Date(compareDate));
                listaNotificaciones[i].diferencia = diff;

                function getDuration(milli){
                    let minutes = Math.floor(milli / 60000);
                    let hours = Math.round(minutes / 60);
                    let days = Math.round(hours / 24);
                  
                    return (
                      (days && {value: days, unit: 'd'}) ||
                      (hours && {value: hours, unit: 'h'}) ||
                      {value: minutes, unit: 'm'}
                    )
                };
                
                let tDuration = getDuration(diff);
                listaNotificaciones[i].duracion = tDuration;

                if(listaNotificaciones[i].modulo === "Servicios planificados"){
                    listaNotificaciones[i].descripcionTicket = listaNotificaciones[i].descripcion.split('_')[1];
                    let servicio = listaNotificaciones[i].rutinaByIdRutina?.servicioByIdServicio?.nombre ?? '';
                    let partida = '';
                    const monthNames = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre" ];

                    function addMonths(date, months) {
                        let d = date.getDate();
                        date.setMonth(date.getMonth() + +months);
                        if (date.getDate() != d) {
                        date.setDate(0);
                        }
                        return date;
                    }
                    const d = addMonths(new Date(), 1);

                    if(listaNotificaciones[i].rutinaByIdRutina.partidaByIdPartida){
                        partida = listaNotificaciones[i].rutinaByIdRutina.partidaByIdPartida.nombre;
                        listaNotificaciones[i].descripcionTicket = "Existe al menos una rutina, mantenimiento o programa planificado para el mes perteneciente al servicio: " + servicio + " y partida: " + partida;
                    } else {
                        listaNotificaciones[i].descripcionTicket = "Existe al menos una rutina, mantenimiento o programa planificado para el mes perteneciente al servicio: " + servicio;
                    }
                    
                    listaNotificaciones[i].descripcion = "Servicios planificados para el siguiente mes";

                } else {
                    if(listaNotificaciones[i].solicitudServicioByIdSolicitudServicio){
                        listaNotificaciones[i].titulo = listaNotificaciones[i].solicitudServicioByIdSolicitudServicio.folio;
                        listaNotificaciones[i].descripcionTicket = listaNotificaciones[i].solicitudServicioByIdSolicitudServicio.descripcionSolicitud;
    
                    }
                }
                
            }
           
            yield put({ type: configuracionYSeguridadActions.LISTA_NOTIFICACIONES_SUCCESS, listaNotificaciones, cantidadNotificaciones });
        }
        else{
            yield put({ type: configuracionYSeguridadActions.LISTA_NOTIFICACIONES_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_NOTIFICACIONES_FAILURE });
    }
} export function* getNotificacionesSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_NOTIFICACIONES_REQUEST, getNotificaciones);
}

function* getNotificacionesNoLeidas(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(getNotificacionesNoLeidasCall, token);
        console.log(response);

        if(response.status==200){
            const listaNotificacionesNoLeidas = response.data.data;
            const cantidadNotificacionesNoLeidas = response.data.cantidad;
           
            yield put({ type: configuracionYSeguridadActions.LISTA_NOTIFICACIONES_NO_LEIDAS_SUCCESS, listaNotificacionesNoLeidas, cantidadNotificacionesNoLeidas });
        }
        else{
            yield put({ type: configuracionYSeguridadActions.LISTA_NOTIFICACIONES_NO_LEIDAS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.LISTA_NOTIFICACIONES_NO_LEIDAS_FAILURE });
    }
} export function* getNotificacionesNoLeidasSaga() {
    yield takeLatest(configuracionYSeguridadActions.LISTA_NOTIFICACIONES_NO_LEIDAS_REQUEST, getNotificacionesNoLeidas);
}

function* actualizarNotificaciones(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idsNotificaciones = '';

        for(let i in action.listaNotificacionesNoLeidas){
            idsNotificaciones += action.listaNotificacionesNoLeidas[i].idNotificacion + ',';
        }

        const response = yield call(actualizarNotificacionesCall, token, idsNotificaciones);
        console.log(response);

        if(response.status==200){
           
            yield put({ type: configuracionYSeguridadActions.ACTUALIZAR_NOTIFICACIONES_SUCCESS });
            yield put({ type: configuracionYSeguridadActions.LISTA_NOTIFICACIONES_NO_LEIDAS_REQUEST});
        }
        else{
            yield put({ type: configuracionYSeguridadActions.ACTUALIZAR_NOTIFICACIONES_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: configuracionYSeguridadActions.ACTUALIZAR_NOTIFICACIONES_FAILURE });
    }
}
export function* actualizarNotificacionesSaga() {
    yield takeLatest(configuracionYSeguridadActions.ACTUALIZAR_NOTIFICACIONES_REQUEST, actualizarNotificaciones);
}