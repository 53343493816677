export const CAMBIAR_CATALOGO_MECANISMOS_PAGOS = "CAMBIAR_CATALOGO_MECANISMOS_PAGOS";

// actions tiempo de garantia
export const LISTA_TIEMPODEGARANTIA_REQUEST = 'LISTA_TIEMPODEGARANTIA_REQUEST';
export const LISTA_TIEMPODEGARANTIA_SUCCESS = 'LISTA_TIEMPODEGARANTIA_SUCCESS';
export const LISTA_TIEMPODEGARANTIA_FAILURE = 'LISTA_TIEMPODEGARANTIA_FAILURE';

export const LISTA_TIEMPODEGARANTIA_FORM_REQUEST = 'LISTA_TIEMPODEGARANTIA_FORM_REQUEST';
export const LISTA_TIEMPODEGARANTIA_FORM_SUCCESS = 'LISTA_TIEMPODEGARANTIA_FORM_SUCCESS';
export const LISTA_TIEMPODEGARANTIA_FORM_FAILURE = 'LISTA_TIEMPODEGARANTIA_FORM_FAILURE';

export const LISTA_TIEMPODEGARANTIA_TODOS_REQUEST = 'LISTA_TIEMPODEGARANTIA_TODOS_REQUEST';
export const LISTA_TIEMPODEGARANTIA_TODOS_SUCCESS = 'LISTA_TIEMPODEGARANTIA_TODOS_SUCCESS';
export const LISTA_TIEMPODEGARANTIA_TODOS_FAILURE = 'LISTA_TIEMPODEGARANTIA_TODOS_FAILURE';

export const LISTA_TIEMPODEGARANTIA_BUSQUEDA_REQUEST = 'LISTA_TIEMPODEGARANTIA_BUSQUEDA_REQUEST';
export const LISTA_TIEMPODEGARANTIA_BUSQUEDA_SUCCESS = 'LISTA_TIEMPODEGARANTIA_BUSQUEDA_SUCCESS';
export const LISTA_TIEMPODEGARANTIA_BUSQUEDA_FAILURE = 'LISTA_TIEMPODEGARANTIA_BUSQUEDA_FAILURE';

export const ESTADO_TIEMPODEGARANTIA_REQUEST = 'ESTADO_TIEMPODEGARANTIA_REQUEST';
export const ESTADO_TIEMPODEGARANTIA_SUCCESS = 'ESTADO_TIEMPODEGARANTIA_SUCCESS';
export const ESTADO_TIEMPODEGARANTIA_FAILURE = 'ESTADO_TIEMPODEGARANTIA_FAILURE';

export const SHOW_MODAL_NUEVO_TIEMPODEGARANTIA = 'SHOW_MODAL_NUEVO_TIEMPODEGARANTIA';
export const SHOW_MODAL_EDITAR_TIEMPODEGARANTIA = 'SHOW_MODAL_EDITAR_TIEMPODEGARANTIA';

export const NUEVO_TIEMPODEGARANTIA_REQUEST = 'NUEVO_TIEMPODEGARANTIA_REQUEST';
export const NUEVO_TIEMPODEGARANTIA_SUCCESS = 'NUEVO_TIEMPODEGARANTIA_SUCCESS';
export const NUEVO_TIEMPODEGARANTIA_FAILURE = 'NUEVO_TIEMPODEGARANTIA_FAILURE';

export const EDITAR_TIEMPODEGARANTIA_REQUEST = 'EDITAR_TIEMPODEGARANTIA_REQUEST';
export const EDITAR_TIEMPODEGARANTIA_SUCCESS = 'EDITAR_TIEMPODEGARANTIA_SUCCESS';
export const EDITAR_TIEMPODEGARANTIA_FAILURE = 'EDITAR_TIEMPODEGARANTIA_FAILURE';

export const CAMBIAR_VISTA_TIEMPODEGARANTIA = "CAMBIAR_VISTA_TIEMPODEGARANTIA";

export const LISTA_SERVICIOS_FILTRO_REQUEST = "LISTA_SERVICIOS_FILTRO_REQUEST"
export const LISTA_SERVICIOS_FILTRO_SUCCESS = "LISTA_SERVICIOS_FILTRO_SUCCESS"
export const LISTA_SERVICIOS_FILTRO_FAILURE = "LISTA_SERVICIOS_FILTRO_FAILURE"


//CATEGORIAS DE FALLAS DE CALIDAD
export const LISTA_CATEGORIAS_FALLAS_CALIDAD_ACTIVOS_REQUEST = 'LISTA_CATEGORIAS_FALLAS_CALIDAD_ACTIVOS_REQUEST';
export const LISTA_CATEGORIAS_FALLAS_CALIDAD_ACTIVOS_SUCCESS = 'LISTA_CATEGORIAS_FALLAS_CALIDAD_ACTIVOS_SUCCESS';
export const LISTA_CATEGORIAS_FALLAS_CALIDAD_ACTIVOS_FAILURE = 'LISTA_CATEGORIAS_FALLAS_CALIDAD_ACTIVOS_FAILURE';

export const LISTA_CATEGORIAS_FALLAS_CALIDAD_EDICION_REQUEST = 'LISTA_CATEGORIAS_FALLAS_CALIDAD_EDICION_REQUEST';
export const LISTA_CATEGORIAS_FALLAS_CALIDAD_EDICION_SUCCESS = 'LISTA_CATEGORIAS_FALLAS_CALIDAD_EDICION_SUCCESS';
export const LISTA_CATEGORIAS_FALLAS_CALIDAD_EDICION_FAILURE = 'LISTA_CATEGORIAS_FALLAS_CALIDAD_EDICION_FAILURE';

export const CATEGORIAS_FALLAS_CALIDAD_BUSQUEDA_REQUEST = 'CATEGORIAS_FALLAS_CALIDAD_BUSQUEDA_REQUEST';
export const CATEGORIAS_FALLAS_CALIDAD_BUSQUEDA_SUCCESS = 'CATEGORIAS_FALLAS_CALIDAD_BUSQUEDA_SUCCESS';
export const CATEGORIAS_FALLAS_CALIDAD_BUSQUEDA_FAILURE = 'CATEGORIAS_FALLAS_CALIDAD_BUSQUEDA_FAILURE';

export const NUEVA_CATEGORIA_FALLA_CALIDAD_REQUEST = 'NUEVA_CATEGORIA_FALLA_CALIDAD_REQUEST';
export const NUEVA_CATEGORIA_FALLA_CALIDAD_SUCCESS = 'NUEVA_CATEGORIA_FALLA_CALIDAD_SUCCESS';
export const NUEVA_CATEGORIA_FALLA_CALIDAD_FAILURE = 'NUEVA_CATEGORIA_FALLA_CALIDAD_FAILURE';

export const EDITAR_CATEGORIA_FALLA_CALIDAD_REQUEST = 'EDITAR_CATEGORIA_FALLA_CALIDAD_REQUEST';
export const EDITAR_CATEGORIA_FALLA_CALIDAD_SUCCESS = 'EDITAR_CATEGORIA_FALLA_CALIDAD_SUCCESS';
export const EDITAR_CATEGORIA_FALLA_CALIDAD_FAILURE = 'EDITAR_CATEGORIA_FALLA_CALIDAD_FAILURE';

export const ESTADO_CATEGORIA_FALLA_CALIDAD_REQUEST = 'ESTADO_CATEGORIA_FALLA_CALIDAD_REQUEST';
export const ESTADO_CATEGORIA_FALLA_CALIDAD_SUCCESS = 'ESTADO_CATEGORIA_FALLA_CALIDAD_SUCCESS';
export const ESTADO_CATEGORIA_FALLA_CALIDAD_FAILURE = 'ESTADO_CATEGORIA_FALLA_CALIDAD_FAILURE';

export const SHOW_MODAL_NUEVA_CATEGORIA_FALLA_CALIDAD = 'SHOW_MODAL_NUEVA_CATEGORIA_FALLA_CALIDAD';
export const SHOW_MODAL_EDITAR_CATEGORIA_FALLA_CALIDAD = 'SHOW_MODAL_EDITAR_CATEGORIA_FALLA_CALIDAD';

export const CAMBIAR_VISTA_FALLA_CALIDAD = 'CAMBIAR_VISTA_FALLA_CALIDAD';

// Fallas de Disponibilidad

export const LISTA_FALLA_DISPONIBILIDAD_REQUEST = 'LISTA_FALLA_DISPONIBILIDAD_REQUEST';
export const LISTA_FALLA_DISPONIBILIDAD_SUCCESS = 'LISTA_FALLA_DISPONIBILIDAD_SUCCESS';
export const LISTA_FALLA_DISPONIBILIDAD_FAILURE = 'LISTA_FALLA_DISPONIBILIDAD_FAILURE';

export const FALLA_DISPONIBILIDAD_BUSQUEDA_REQUEST = 'FALLA_DISPONIBILIDAD_BUSQUEDA_REQUEST';
export const FALLA_DISPONIBILIDAD_BUSQUEDA_SUCCESS = 'FALLA_DISPONIBILIDAD_BUSQUEDA_SUCCESS';
export const FALLA_DISPONIBILIDAD_BUSQUEDA_FAILURE = 'FALLA_DISPONIBILIDAD_BUSQUEDA_FAILURE';

export const ESTADO_FALLA_DISPONIBILIDAD_REQUEST = 'ESTADO_FALLA_DISPONIBILIDAD_REQUEST';
export const ESTADO_FALLA_DISPONIBILIDAD_SUCCESS = 'ESTADO_FALLA_DISPONIBILIDAD_SUCCESS';
export const ESTADO_FALLA_DISPONIBILIDAD_FAILURE = 'ESTADO_FALLA_DISPONIBILIDAD_FAILURE';

export const SHOW_MODAL_NUEVO_FALLA_DISPONIBILIDAD = 'SHOW_MODAL_NUEVO_FALLA_DISPONIBILIDAD';
export const SHOW_MODAL_EDITAR_FALLA_DISPONIBILIDAD = 'SHOW_MODAL_EDITAR_FALLA_DISPONIBILIDAD';

export const NUEVO_FALLA_DISPONIBILIDAD_REQUEST = 'NUEVO_FALLA_DISPONIBILIDAD_REQUEST';
export const NUEVO_FALLA_DISPONIBILIDAD_SUCCESS = 'NUEVO_FALLA_DISPONIBILIDAD_SUCCESS';
export const NUEVO_FALLA_DISPONIBILIDAD_FAILURE = 'NUEVO_FALLA_DISPONIBILIDAD_FAILURE';

export const EDITAR_FALLA_DISPONIBILIDAD_REQUEST = 'EDITAR_FALLA_DISPONIBILIDAD_REQUEST';
export const EDITAR_FALLA_DISPONIBILIDAD_SUCCESS = 'EDITAR_FALLA_DISPONIBILIDAD_SUCCESS';
export const EDITAR_FALLA_DISPONIBILIDAD_FAILURE = 'EDITAR_FALLA_DISPONIBILIDAD_FAILURE';

export const CAMBIAR_VISTA_FALLA_DISPONIBILIDAD = 'CAMBIAR_VISTA_FALLA_DISPONIBILIDAD';

export const LISTA_CATEGORIAS_DISPONIBILIDAD_REQUEST = 'LISTA_CATEGORIAS_DISPONIBILIDAD_REQUEST';
export const LISTA_CATEGORIAS_DISPONIBILIDAD_SUCCESS = 'LISTA_CATEGORIAS_DISPONIBILIDAD_SUCCESS';
export const LISTA_CATEGORIAS_DISPONIBILIDAD_FAILURE = 'LISTA_CATEGORIAS_DISPONIBILIDAD_FAILURE';

//CATÁLOGO SERVICIOS Y SECCIONES ESTÁNDARES DE DESEMPEÑO ESPECÍFICOS
export const LISTA_ESTANDARES_DESEMPENO_ESPECIFICOS_ACTIVOS_REQUEST = 'LISTA_ESTANDARES_DESEMPENO_ESPECIFICOS_ACTIVOS_REQUEST';
export const LISTA_ESTANDARES_DESEMPENO_ESPECIFICOS_ACTIVOS_SUCCESS = 'LISTA_ESTANDARES_DESEMPENO_ESPECIFICOS_ACTIVOS_SUCCESS';
export const LISTA_ESTANDARES_DESEMPENO_ESPECIFICOS_ACTIVOS_FAILURE = 'LISTA_ESTANDARES_DESEMPENO_ESPECIFICOS_ACTIVOS_FAILURE';

export const ESTANDARES_DESEMPENO_ESPECIFICOS_BUSQUEDA_REQUEST = 'ESTANDARES_DESEMPENO_ESPECIFICOS_BUSQUEDA_REQUEST';
export const ESTANDARES_DESEMPENO_ESPECIFICOS_BUSQUEDA_SUCCESS = 'ESTANDARES_DESEMPENO_ESPECIFICOS_BUSQUEDA_SUCCESS';
export const ESTANDARES_DESEMPENO_ESPECIFICOS_BUSQUEDA_FAILURE = 'ESTANDARES_DESEMPENO_ESPECIFICOS_BUSQUEDA_FAILURE';

export const NUEVO_ESTANDAR_DESEMPENO_ESPECIFICO_REQUEST = 'NUEVO_ESTANDAR_DESEMPENO_ESPECIFICO_REQUEST';
export const NUEVO_ESTANDAR_DESEMPENO_ESPECIFICO_SUCCESS = 'NUEVO_ESTANDAR_DESEMPENO_ESPECIFICO_SUCCESS';
export const NUEVO_ESTANDAR_DESEMPENO_ESPECIFICO_FAILURE = 'NUEVO_ESTANDAR_DESEMPENO_ESPECIFICO_FAILURE';

export const EDITAR_ESTANDAR_DESEMPENO_ESPECIFICO_REQUEST = 'EDITAR_ESTANDAR_DESEMPENO_ESPECIFICO_REQUEST';
export const EDITAR_ESTANDAR_DESEMPENO_ESPECIFICO_SUCCESS = 'EDITAR_ESTANDAR_DESEMPENO_ESPECIFICO_SUCCESS';
export const EDITAR_ESTANDAR_DESEMPENO_ESPECIFICO_FAILURE = 'EDITAR_ESTANDAR_DESEMPENO_ESPECIFICO_FAILURE';

export const ESTADO_ESTANDAR_DESEMPENO_ESPECIFICO_REQUEST = 'ESTADO_ESTANDAR_DESEMPENO_ESPECIFICO_REQUEST';
export const ESTADO_ESTANDAR_DESEMPENO_ESPECIFICO_SUCCESS = 'ESTADO_ESTANDAR_DESEMPENO_ESPECIFICO_SUCCESS';
export const ESTADO_ESTANDAR_DESEMPENO_ESPECIFICO_FAILURE = 'ESTADO_ESTANDAR_DESEMPENO_ESPECIFICO_FAILURE';

export const SHOW_MODAL_NUEVO_ESTANDAR_DESEMPENO_ESPECIFICO = 'SHOW_MODAL_NUEVO_ESTANDAR_DESEMPENO_ESPECIFICO';
export const SHOW_MODAL_EDITAR_ESTANDAR_DESEMPENO_ESPECIFICO = 'SHOW_MODAL_EDITAR_ESTANDAR_DESEMPENO_ESPECIFICO';

// Ponderacion Servicios por Area
export const LISTA_PONDERACION_SERV_POR_AREA_REQUEST = 'LISTA_PONDERACION_SERV_POR_AREA_REQUEST';
export const LISTA_PONDERACION_SERV_POR_AREA_SUCCESS = 'LISTA_PONDERACION_SERV_POR_AREA_SUCCESS';
export const LISTA_PONDERACION_SERV_POR_AREA_FAILURE = 'LISTA_PONDERACION_SERV_POR_AREA_FAILURE';

export const LISTA_AREAS_POR_SERVICIO_REQUEST = 'LISTA_AREAS_POR_SERVICIO_REQUEST';
export const LISTA_AREAS_POR_SERVICIO_SUCCESS = 'LISTA_AREAS_POR_SERVICIO_SUCCESS';
export const LISTA_AREAS_POR_SERVICIO_FAILURE = 'LISTA_AREAS_POR_SERVICIO_FAILURE';

export const LISTA_PONDERACION_SERV_POR_AREA_BUSQUEDA_REQUEST = 'LISTA_PONDERACION_SERV_POR_AREA_BUSQUEDA_REQUEST';
export const LISTA_PONDERACION_SERV_POR_AREA_BUSQUEDA_SUCCESS = 'LISTA_PONDERACION_SERV_POR_AREA_BUSQUEDA_SUCCESS';
export const LISTA_PONDERACION_SERV_POR_AREA_BUSQUEDA_FAILURE = 'LISTA_PONDERACION_SERV_POR_AREA_BUSQUEDA_FAILURE';

export const ESTADO_PONDERACION_SERV_POR_AREA_REQUEST = 'ESTADO_PONDERACION_SERV_POR_AREA_REQUEST';
export const ESTADO_PONDERACION_SERV_POR_AREA_SUCCESS = 'ESTADO_PONDERACION_SERV_POR_AREA_SUCCESS';
export const ESTADO_PONDERACION_SERV_POR_AREA_FAILURE = 'ESTADO_PONDERACION_SERV_POR_AREA_FAILURE';

export const SHOW_MODAL_NUEVO_PONDERACION_SERV_POR_AREA = 'SHOW_MODAL_NUEVO_PONDERACION_SERV_POR_AREA';
export const SHOW_MODAL_EDITAR_PONDERACION_SERV_POR_AREA = 'SHOW_MODAL_EDITAR_PONDERACION_SERV_POR_AREA';

export const NUEVO_PONDERACION_SERV_POR_AREA_REQUEST = 'NUEVO_PONDERACION_SERV_POR_AREA_REQUEST';
export const NUEVO_PONDERACION_SERV_POR_AREA_SUCCESS = 'NUEVO_PONDERACION_SERV_POR_AREA_SUCCESS';
export const NUEVO_PONDERACION_SERV_POR_AREA_FAILURE = 'NUEVO_PONDERACION_SERV_POR_AREA_FAILURE';

export const EDITAR_PONDERACION_SERV_POR_AREA_REQUEST = 'EDITAR_PONDERACION_SERV_POR_AREA_REQUEST';
export const EDITAR_PONDERACION_SERV_POR_AREA_SUCCESS = 'EDITAR_PONDERACION_SERV_POR_AREA_SUCCESS';
export const EDITAR_PONDERACION_SERV_POR_AREA_FAILURE = 'EDITAR_PONDERACION_SERV_POR_AREA_FAILURE';

export const LIMPIAR_LISTA_PONDERADORES_SERVICIO_AREA = 'LIMPIAR_LISTA_PONDERADORES_SERVICIO_AREA';
export const CAMBIAR_VISTA_PONDERACION_SERV_POR_AREA = "CAMBIAR_VISTA_PONDERACION_SERV_POR_AREA";

//CATALOGO CLAVES DE MÉTODOS DE SUPERVISIÓN 
export const LISTA_CLAVES_METODOS_SUPERVISION_ACTIVOS_REQUEST = 'LISTA_CLAVES_METODOS_SUPERVISION_ACTIVOS_REQUEST';
export const LISTA_CLAVES_METODOS_SUPERVISION_ACTIVOS_SUCCESS = 'LISTA_CLAVES_METODOS_SUPERVISION_ACTIVOS_SUCCESS';
export const LISTA_CLAVES_METODOS_SUPERVISION_ACTIVOS_FAILURE = 'LISTA_CLAVES_METODOS_SUPERVISION_ACTIVOS_FAILURE';

export const CLAVES_METODOS_SUPERVISION_BUSQUEDA_REQUEST = 'CLAVES_METODOS_SUPERVISION_BUSQUEDA_REQUEST';
export const CLAVES_METODOS_SUPERVISION_BUSQUEDA_SUCCESS = 'CLAVES_METODOS_SUPERVISION_BUSQUEDA_SUCCESS';
export const CLAVES_METODOS_SUPERVISION_BUSQUEDA_FAILURE = 'CLAVES_METODOS_SUPERVISION_BUSQUEDA_FAILURE';

export const NUEVA_CLAVE_METODO_SUPERVISION_REQUEST = 'NUEVA_CLAVE_METODO_SUPERVISION_REQUEST';
export const NUEVA_CLAVE_METODO_SUPERVISION_SUCCESS = 'NUEVA_CLAVE_METODO_SUPERVISION_SUCCESS';
export const NUEVA_CLAVE_METODO_SUPERVISION_FAILURE = 'NUEVA_CLAVE_METODO_SUPERVISION_FAILURE';

export const EDITAR_CLAVE_METODO_SUPERVISION_REQUEST = 'EDITAR_CLAVE_METODO_SUPERVISION_REQUEST';
export const EDITAR_CLAVE_METODO_SUPERVISION_SUCCESS = 'EDITAR_CLAVE_METODO_SUPERVISION_SUCCESS';
export const EDITAR_CLAVE_METODO_SUPERVISION_FAILURE = 'EDITAR_CLAVE_METODO_SUPERVISION_FAILURE';

export const ESTADO_CLAVE_METODO_SUPERVISION_REQUEST = 'ESTADO_CLAVE_METODO_SUPERVISION_REQUEST';
export const ESTADO_CLAVE_METODO_SUPERVISION_SUCCESS = 'ESTADO_CLAVE_METODO_SUPERVISION_SUCCESS';
export const ESTADO_CLAVE_METODO_SUPERVISION_FAILURE = 'ESTADO_CLAVE_METODO_SUPERVISION_FAILURE';

export const SHOW_MODAL_NUEVO_CLAVE_METODO_SUPERVISION = 'SHOW_MODAL_NUEVO_CLAVE_METODO_SUPERVISION';
export const SHOW_MODAL_EDITAR_CLAVE_METODO_SUPERVISION = 'SHOW_MODAL_EDITAR_CLAVE_METODO_SUPERVISION';

// Catálogo de Referencias de Estandares de Desempeño Específico

export const LISTA_REFERENCIAS_ESTANDARES_ACTIVOS_REQUEST = 'LISTA_REFERENCIAS_ESTANDARES_ACTIVOS_REQUEST';
export const LISTA_REFERENCIAS_ESTANDARES_ACTIVOS_SUCCESS = 'LISTA_REFERENCIAS_ESTANDARES_ACTIVOS_SUCCESS';
export const LISTA_REFERENCIAS_ESTANDARES_ACTIVOS_FAILURE = 'LISTA_REFERENCIAS_ESTANDARES_ACTIVOS_FAILURE';

export const REFERENCIAS_ESTANDARES_BUSQUEDA_REQUEST = 'REFERENCIAS_ESTANDARES_BUSQUEDA_REQUEST';
export const REFERENCIAS_ESTANDARES_BUSQUEDA_SUCCESS = 'REFERENCIAS_ESTANDARES_BUSQUEDA_SUCCESS';
export const REFERENCIAS_ESTANDARES_BUSQUEDA_FAILURE = 'REFERENCIAS_ESTANDARES_BUSQUEDA_FAILURE';

export const NUEVA_REFERENCIA_ESTANDAR_REQUEST = 'NUEVA_REFERENCIA_ESTANDAR_REQUEST';
export const NUEVA_REFERENCIA_ESTANDAR_SUCCESS = 'NUEVA_REFERENCIA_ESTANDAR_SUCCESS';
export const NUEVA_REFERENCIA_ESTANDAR_FAILURE = 'NUEVA_REFERENCIA_ESTANDAR_FAILURE';

export const EDITAR_REFERENCIA_ESTANDAR_REQUEST = 'EDITAR_REFERENCIA_ESTANDAR_REQUEST';
export const EDITAR_REFERENCIA_ESTANDAR_SUCCESS = 'EDITAR_REFERENCIA_ESTANDAR_SUCCESS';
export const EDITAR_REFERENCIA_ESTANDAR_FAILURE = 'EDITAR_REFERENCIA_ESTANDAR_FAILURE';

export const ESTADO_REFERENCIA_ESTANDAR_REQUEST = 'ESTADO_REFERENCIA_ESTANDAR_REQUEST';
export const ESTADO_REFERENCIA_ESTANDAR_SUCCESS = 'ESTADO_REFERENCIA_ESTANDAR_SUCCESS';
export const ESTADO_REFERENCIA_ESTANDAR_FAILURE = 'ESTADO_REFERENCIA_ESTANDAR_FAILURE';

export const SHOW_MODAL_NUEVA_REFERENCIA_ESTANDAR = 'SHOW_MODAL_NUEVA_REFERENCIA_ESTANDAR';
export const SHOW_MODAL_EDITAR_REFERENCIA_ESTANDAR = 'SHOW_MODAL_EDITAR_REFERENCIA_ESTANDAR';

// Catálogo de Estándares de desempeño.
export const LISTA_ESTANDARES_FILTRO_REQUEST = 'LISTA_ESTANDARES_FILTRO_REQUEST';
export const LISTA_ESTANDARES_FILTRO_SUCCESS = 'LISTA_ESTANDARES_FILTRO_SUCCESS';
export const LISTA_ESTANDARES_FILTRO_FAILURE = 'LISTA_ESTANDARES_FILTRO_FAILURE';

export const LISTA_ESTANDARES_ACTIVOS_REQUEST = 'LISTA_ESTANDARES_ACTIVOS_REQUEST';
export const LISTA_ESTANDARES_ACTIVOS_SUCCESS = 'LISTA_ESTANDARES_ACTIVOS_SUCCESS';
export const LISTA_ESTANDARES_ACTIVOS_FAILURE = 'LISTA_ESTANDARES_ACTIVOS_FAILURE';

export const LISTA_ESTANDARES_DESEMPEÑO_REQUEST = 'LISTA_ESTANDARES_DESEMPEÑO_REQUEST';
export const LISTA_ESTANDARES_DESEMPEÑO_SUCCESS = 'LISTA_ESTANDARES_DESEMPEÑO_SUCCESS';
export const LISTA_ESTANDARES_DESEMPEÑO_FAILURE = 'LISTA_ESTANDARES_DESEMPEÑO_FAILURE';

export const ESTANDARES_DESEMPEÑO_BUSQUEDA_REQUEST = 'ESTANDARES_DESEMPEÑO_BUSQUEDA_REQUEST';
export const ESTANDARES_DESEMPEÑO_BUSQUEDA_SUCCESS = 'ESTANDARES_DESEMPEÑO_BUSQUEDA_SUCCESS';
export const ESTANDARES_DESEMPEÑO_BUSQUEDA_FAILURE = 'ESTANDARES_DESEMPEÑO_BUSQUEDA_FAILURE';

export const NUEVO_ESTANDAR_DESEMPEÑO_REQUEST = 'NUEVO_ESTANDAR_DESEMPEÑO_REQUEST';
export const NUEVO_ESTANDAR_DESEMPEÑO_SUCCESS = 'NUEVO_ESTANDAR_DESEMPEÑO_SUCCESS';
export const NUEVO_ESTANDAR_DESEMPEÑO_FAILURE = 'NUEVO_ESTANDAR_DESEMPEÑO_FAILURE';

export const EDITAR_ESTANDAR_DESEMPEÑO_REQUEST = 'EDITAR_ESTANDAR_DESEMPEÑO_REQUEST';
export const EDITAR_ESTANDAR_DESEMPEÑO_SUCCESS = 'EDITAR_ESTANDAR_DESEMPEÑO_SUCCESS';
export const EDITAR_ESTANDAR_DESEMPEÑO_FAILURE = 'EDITAR_ESTANDAR_DESEMPEÑO_FAILURE';

export const ESTADO_ESTANDAR_DESEMPEÑO_REQUEST = 'ESTADO_ESTANDAR_DESEMPEÑO_REQUEST';
export const ESTADO_ESTANDAR_DESEMPEÑO_SUCCESS = 'ESTADO_ESTANDAR_DESEMPEÑO_SUCCESS';
export const ESTADO_ESTANDAR_DESEMPEÑO_FAILURE = 'ESTADO_ESTANDAR_DESEMPEÑO_FAILURE';

export const SHOW_MODAL_EDITAR_ESTANDAR_DESEMPEÑO = 'SHOW_MODAL_EDITAR_ESTANDAR_DESEMPEÑO';
export const SHOW_MODAL_NUEVO_ESTANDAR_DESEMPEÑO = 'SHOW_MODAL_NUEVO_ESTANDAR_DESEMPEÑO';

export const CAMBIAR_VISTA_ESTANDAR_DESEMPEÑO = 'CAMBIAR_VISTA_ESTANDAR_DESEMPEÑO';

export const FILTRO_REFERENCIA_REQUEST = 'FILTRO_REFERENCIA_REQUEST';
export const FILTRO_REFERENCIA_SUCCESS = 'FILTRO_REFERENCIA_SUCCESS';
export const FILTRO_REFERENCIA_FAILURE = 'FILTRO_REFERENCIA_FAILURE';

export const FILTRO_DESCRIPCION_REQUEST = 'FILTRO_DESCRIPCION_REQUEST';
export const FILTRO_DESCRIPCION_SUCCESS = 'FILTRO_DESCRIPCION_SUCCESS';
export const FILTRO_DESCRIPCION_FAILURE = 'FILTRO_DESCRIPCION_FAILURE';

export const FILTRO_ESTANDAR_REQUEST = 'FILTRO_ESTANDAR_REQUEST';
export const FILTRO_ESTANDAR_SUCCESS = 'FILTRO_ESTANDAR_SUCCESS';
export const FILTRO_ESTANDAR_FAILURE = 'FILTRO_ESTANDAR_FAILURE';

export const FILTRO_SERVICIO_SECCION_REQUEST = 'FILTRO_SERVICIO_SECCION_REQUEST';
export const FILTRO_SERVICIO_SECCION_SUCCESS = 'FILTRO_SERVICIO_SECCION_SUCCESS';
export const FILTRO_SERVICIO_SECCION_FAILURE = 'FILTRO_SERVICIO_SECCION_FAILURE';

//AÑO MES CALENDARIO ACTIONS
export const LISTA_MESES_CALENDARIO_REQUEST = 'LISTA_MESES_CALENDARIO_REQUEST';
export const LISTA_MESES_CALENDARIO_SUCCESS = 'LISTA_MESES_CALENDARIO_SUCCESS';
export const LISTA_MESES_CALENDARIO_FAILURE = 'LISTA_MESES_CALENDARIO_FAILURE';

export const LISTA_AÑO_CALENDARIO_REQUEST = 'LISTA_AÑO_CALENDARIO_REQUEST';
export const LISTA_AÑO_CALENDARIO_SUCCESS = 'LISTA_AÑO_CALENDARIO_SUCCESS';
export const LISTA_AÑO_CALENDARIO_FAILURE = 'LISTA_AÑO_CALENDARIO_FAILURE';

export const DETALLE_MES_CALENDARIO_REQUEST = 'DETALLE_MES_CALENDARIO_REQUEST';
export const DETALLE_MES_CALENDARIO_SUCCESS = 'DETALLE_MES_CALENDARIO_SUCCESS';
export const DETALLE_MES_CALENDARIO_FAILURE = 'DETALLE_MES_CALENDARIO_FAILURE';

export const SHOW_MODAL_DETALLE_MES_CALENDARIO = 'SHOW_MODAL_DETALLE_MES_CALENDARIO';

export const CALCULAR_MES_CALENDARIO_REQUEST = 'CALCULAR_MES_CALENDARIO_REQUEST';
export const CALCULAR_MES_CALENDARIO_SUCCESS = 'CALCULAR_MES_CALENDARIO_SUCCESS';
export const CALCULAR_MES_CALENDARIO_FAILURE = 'CALCULAR_MES_CALENDARIO_FAILURE';

export const CERRAR_MES_CALENDARIO_REQUEST = 'CERRAR_MES_CALENDARIO_REQUEST';
export const CERRAR_MES_CALENDARIO_SUCCESS = 'CERRAR_MES_CALENDARIO_SUCCESS';
export const CERRAR_MES_CALENDARIO_FAILURE = 'CERRAR_MES_CALENDARIO_FAILURE';

export const SHOW_MODAL_CREAR_MES_CALENDARIO = 'SHOW_MODAL_CREAR_MES_CALENDARIO';

export const CREAR_MES_CALENDARIO_REQUEST = 'CREAR_MES_CALENDARIO_REQUEST';
export const CREAR_MES_CALENDARIO_SUCCESS = 'CREAR_MES_CALENDARIO_SUCCESS';
export const CREAR_MES_CALENDARIO_FAILURE = 'CREAR_MES_CALENDARIO_FAILURE';

export const SHOW_MODAL_EDITAR_MES_CALENDARIO = 'SHOW_MODAL_EDITAR_MES_CALENDARIO';

export const EDITAR_MES_CALENDARIO_REQUEST = 'EDITAR_MES_CALENDARIO_REQUEST';
export const EDITAR_MES_CALENDARIO_SUCCESS = 'EDITAR_MES_CALENDARIO_SUCCESS';
export const EDITAR_MES_CALENDARIO_FAILURE = 'EDITAR_MES_CALENDARIO_FAILURE';

export const SHOW_MODAL_AGREGAR_PPL = 'SHOW_MODAL_AGREGAR_PPL';

export const AGREGAR_PPL_REQUEST = 'AGREGAR_PPL_REQUEST';
export const AGREGAR_PPL_SUCCESS = 'AGREGAR_PPL_SUCCESS';
export const AGREGAR_PPL_FAILURE = 'AGREGAR_PPL_FAILURE';

export const EXPORTAR_EXCEL_MES_CALENDARIO_REQUEST = 'EXPORTAR_EXCEL_MES_CALENDARIO_REQUEST';
export const EXPORTAR_EXCEL_MES_CALENDARIO_SUCCESS = 'EXPORTAR_EXCEL_MES_CALENDARIO_SUCCESS';
export const EXPORTAR_EXCEL_MES_CALENDARIO_FAILURE = 'EXPORTAR_EXCEL_MES_CALENDARIO_FAILURE';


//VALORES DE PAGO ANUALES
export const LISTA_VALORES_PAGO_ANUAL_REQUEST = "LISTA_VALORES_PAGO_ANUAL_REQUEST";
export const LISTA_VALORES_PAGO_ANUAL_SUCCESS = "LISTA_VALORES_PAGO_ANUAL_SUCCESS";
export const LISTA_VALORES_PAGO_ANUAL_FAILURE = "LISTA_VALORES_PAGO_ANUAL_FAILURE"; 

export const ESTADO_INPC0_REQUEST = "ESTADO_INPC0_REQUEST";
export const ESTADO_INPC0_SUCCESS = "ESTADO_INPC0_SUCCESS";
export const ESTADO_INPC0_FAILURE = "ESTADO_INPC0_FAILURE"; 

export const CREAR_INPC0_REQUEST = "CREAR_INPC0_REQUEST";
export const CREAR_INPC0_SUCCESS = "CREAR_INPC0_SUCCESS";
export const CREAR_INPC0_FAILURE = "CREAR_INPC0_FAILURE"; 

export const SHOW_MODAL_CREAR_INPC0 = "SHOW_MODAL_CREAR_INPC0";
export const SHOW_MODAL_EDITAR_INPC0 = "SHOW_MODAL_EDITAR_INPC0";

export const EDITAR_INPC0_REQUEST = "EDITAR_INPC0_REQUEST";
export const EDITAR_INPC0_SUCCESS = "EDITAR_INPC0_SUCCESS";
export const EDITAR_INPC0_FAILURE = "EDITAR_INPC0_FAILURE"; 

export const LISTA_PARv_REQUEST = "LISTA_PARv_REQUEST";
export const LISTA_PARv_SUCCESS = "LISTA_PARv_SUCCESS";
export const LISTA_PARv_FAILURE = "LISTA_PARv_FAILURE"; 

export const SHOW_MODAL_CREAR_PARv = "SHOW_MODAL_CREAR_PARv";

export const CREAR_PARv_REQUEST = "CREAR_PARv_REQUEST";
export const CREAR_PARv_SUCCESS = "CREAR_PARv_SUCCESS";
export const CREAR_PARv_FAILURE = "CREAR_PARv_FAILURE";

export const VERIFICAR_PARv_REQUEST = "VERIFICAR_PARv_REQUEST";
export const VERIFICAR_PARv_SUCCESS = "VERIFICAR_PARv_SUCCESS";
export const VERIFICAR_PARv_FAILURE = "VERIFICAR_PARv_FAILURE";

export const LISTA_CATALOGO_PERIODO_FMD_REQUEST = "LISTA_CATALOGO_PERIODO_FMD_REQUEST";
export const LISTA_CATALOGO_PERIODO_FMD_SUCCESS = "LISTA_CATALOGO_PERIODO_FMD_SUCCESS";
export const LISTA_CATALOGO_PERIODO_FMD_FAILURE = "LISTA_CATALOGO_PERIODO_FMD_FAILURE";

export const CREAR_PERIODO_FMD_REQUEST = "CREAR_PERIODO_FMD_REQUEST";
export const CREAR_PERIODO_FMD_SUCCESS = "CREAR_PERIODO_FMD_SUCCESS";
export const CREAR_PERIODO_FMD_FAILURE = "CREAR_PERIODO_FMD_FAILURE";

export const EDITAR_PERIODO_FMD_REQUEST = "EDITAR_PERIODO_FMD_REQUEST";
export const EDITAR_PERIODO_FMD_SUCCESS = "EDITAR_PERIODO_FMD_SUCCESS";
export const EDITAR_PERIODO_FMD_FAILURE = "EDITAR_PERIODO_FMD_FAILURE";

export const SHOW_MODAL_EDITAR_PERIODO_FMD = "SHOW_MODAL_EDITAR_PERIODO_FMD";
export const SHOW_MODAL_CREAR_PERIODO_FMD = "SHOW_MODAL_CREAR_PERIODO_FMD";

export const CAMBIAR_VISTA_PERIODO_FMD = "CAMBIAR_VISTA_PERIODO_FMD";

export const VERIFICAR_AÑOS_PERIODO_REQUEST = "VERIFICAR_AÑOS_PERIODO_REQUEST";
export const VERIFICAR_AÑOS_PERIODO_SUCCESS = "VERIFICAR_AÑOS_PERIODO_SUCCESS";
export const VERIFICAR_AÑOS_PERIODO_FAILURE = "VERIFICAR_AÑOS_PERIODO_FAILURE";

//CÁLCULO DE DEDUCTIVAS
export const SHOW_MODAL_DETALLE_CALCULO_DEDUCTIVA = 'SHOW_MODAL_DETALLE_CALCULO_DEDUCTIVA';

export const VALOR_TIPO_CALCULO_DEDUCTIVA_REQUEST = 'VALOR_TIPO_CALCULO_DEDUCTIVA_REQUEST';
export const VALOR_TIPO_CALCULO_DEDUCTIVA_SUCCESS = 'VALOR_TIPO_CALCULO_DEDUCTIVA_SUCCESS';
export const VALOR_TIPO_CALCULO_DEDUCTIVA_FAILURE = 'VALOR_TIPO_CALCULO_DEDUCTIVA_FAILURE';

export const LISTA_MESES_ABIERTOS_REQUEST = 'LISTA_MESES_ABIERTOS_REQUEST';
export const LISTA_MESES_ABIERTOS_SUCCESS = 'LISTA_MESES_ABIERTOS_SUCCESS';
export const LISTA_MESES_ABIERTOS_FAILURE = 'LISTA_MESES_ABIERTOS_FAILURE';

export const LISTA_RESUMEN_SERVICIO_REQUEST = 'LISTA_RESUMEN_SERVICIO_REQUEST';
export const LISTA_RESUMEN_SERVICIO_SUCCESS = 'LISTA_RESUMEN_SERVICIO_SUCCESS';
export const LISTA_RESUMEN_SERVICIO_FAILURE = 'LISTA_RESUMEN_SERVICIO_FAILURE';

export const LISTA_RESUMEN_TIPO_REQUEST = 'LISTA_RESUMEN_TIPO_REQUEST';
export const LISTA_RESUMEN_TIPO_SUCCESS = 'LISTA_RESUMEN_TIPO_SUCCESS';
export const LISTA_RESUMEN_TIPO_FAILURE = 'LISTA_RESUMEN_TIPO_FAILURE';

export const DEDUCTIVA_RESUMEN_EXCEL_REQUEST = 'DEDUCTIVA_RESUMEN_EXCEL_REQUEST';
export const DEDUCTIVA_RESUMEN_EXCEL_SUCCESS = 'DEDUCTIVA_RESUMEN_EXCEL_SUCCESS';
export const DEDUCTIVA_RESUMEN_EXCEL_FAILURE = 'DEDUCTIVA_RESUMEN_EXCEL_FAILURE';

export const LISTA_DETALLE_CALCULO_DEDUCTIVA_REQUEST = 'LISTA_DETALLE_CALCULO_DEDUCTIVA_REQUEST';
export const LISTA_DETALLE_CALCULO_DEDUCTIVA_SUCCESS = 'LISTA_DETALLE_CALCULO_DEDUCTIVA_SUCCESS';
export const LISTA_DETALLE_CALCULO_DEDUCTIVA_FAILURE = 'LISTA_DETALLE_CALCULO_DEDUCTIVA_FAILURE';

export const DETALLE_DEDUCTIVA_EXCEL_REQUEST = 'DETALLE_DEDUCTIVA_EXCEL_REQUEST';
export const DETALLE_DEDUCTIVA_EXCEL_SUCCESS = 'DETALLE_DEDUCTIVA_EXCEL_SUCCESS';
export const DETALLE_DEDUCTIVA_EXCEL_FAILURE = 'DETALLE_DEDUCTIVA_EXCEL_FAILURE';

export const PROCESAR_DEDUCTIVA_REQUEST = 'PROCESAR_DEDUCTIVA_REQUEST';
export const PROCESAR_DEDUCTIVA_SUCCESS = 'PROCESAR_DEDUCTIVA_SUCCESS';
export const PROCESAR_DEDUCTIVA_FAILURE = 'PROCESAR_DEDUCTIVA_FAILURE';

export const DEDUCTIVA_TICKETS_NO_CONCILIADOS_REQUEST = 'DEDUCTIVA_TICKETS_NO_CONCILIADOS_REQUEST';
export const DEDUCTIVA_TICKETS_NO_CONCILIADOS_SUCCESS = 'DEDUCTIVA_TICKETS_NO_CONCILIADOS_SUCCESS';
export const DEDUCTIVA_TICKETS_NO_CONCILIADOS_FAILURE = 'DEDUCTIVA_TICKETS_NO_CONCILIADOS_FAILURE';

//
export const LISTA_PERIODOS_TODOS_REQUEST = "LISTA_PERIODOS_TODOS_REQUEST"
export const LISTA_PERIODOS_TODOS_SUCCESS = "LISTA_PERIODOS_TODOS_SUCCESS"
export const LISTA_PERIODOS_TODOS_FAILURE = "LISTA_PERIODOS_TODOS_FAILURE"

export const LISTA_PERIODOS_FORMULARIO_EDITAR_REQUEST = "LISTA_PERIODOS_FORMULARIO_EDITAR_REQUEST";
export const LISTA_PERIODOS_FORMULARIO_EDITAR_SUCCESS = "LISTA_PERIODOS_FORMULARIO_EDITAR_SUCCESS";
export const LISTA_PERIODOS_FORMULARIO_EDITAR_FAILURE = "LISTA_PERIODOS_FORMULARIO_EDITAR_FAILURE";

//NIVELES DE SEVERIDAD
export const LISTA_NIVELES_SEVERIDAD_ACTIVOS_REQUEST = 'LISTA_NIVELES_SEVERIDAD_ACTIVOS_REQUEST';
export const LISTA_NIVELES_SEVERIDAD_ACTIVOS_SUCCESS = 'LISTA_NIVELES_SEVERIDAD_ACTIVOS_SUCCESS';
export const LISTA_NIVELES_SEVERIDAD_ACTIVOS_FAILURE = 'LISTA_NIVELES_SEVERIDAD_ACTIVOS_FAILURE';

export const LISTA_NIVELES_SEVERIDAD_BUSQUEDA_REQUEST = 'LISTA_NIVELES_SEVERIDAD_BUSQUEDA_REQUEST';
export const LISTA_NIVELES_SEVERIDAD_BUSQUEDA_SUCCESS = 'LISTA_NIVELES_SEVERIDAD_BUSQUEDA_SUCCESS';
export const LISTA_NIVELES_SEVERIDAD_BUSQUEDA_FAILURE = 'LISTA_NIVELES_SEVERIDAD_BUSQUEDA_FAILURE';

export const ESTADO_NIVEL_SEVERIDAD_REQUEST = 'ESTADO_NIVEL_SEVERIDAD_REQUEST';
export const ESTADO_NIVEL_SEVERIDAD_SUCCESS = 'ESTADO_NIVEL_SEVERIDAD_SUCCESS';
export const ESTADO_NIVEL_SEVERIDAD_FAILURE = 'ESTADO_NIVEL_SEVERIDAD_FAILURE';

export const SHOW_MODAL_NUEVO_NIVEL_SEVERIDAD = 'SHOW_MODAL_NUEVO_NIVEL_SEVERIDAD';

export const NUEVO_NIVEL_SEVERIDAD_REQUEST = 'NUEVO_NIVEL_SEVERIDAD_REQUEST';
export const NUEVO_NIVEL_SEVERIDAD_SUCCESS = 'NUEVO_NIVEL_SEVERIDAD_SUCCESS';
export const NUEVO_NIVEL_SEVERIDAD_FAILURE = 'NUEVO_NIVEL_SEVERIDAD_FAILURE';

export const SHOW_MODAL_EDITAR_NIVEL_SEVERIDAD = 'SHOW_MODAL_EDITAR_NIVEL_SEVERIDAD';

export const EDITAR_NIVEL_SEVERIDAD_REQUEST = 'EDITAR_NIVEL_SEVERIDAD_REQUEST';
export const EDITAR_NIVEL_SEVERIDAD_SUCCESS = 'EDITAR_NIVEL_SEVERIDAD_SUCCESS';
export const EDITAR_NIVEL_SEVERIDAD_FAILURE = 'EDITAR_NIVEL_SEVERIDAD_FAILURE';

export const CAMBIAR_VISTA_NIVEL_SEVERIDAD = 'CAMBIAR_VISTA_NIVEL_SEVERIDAD';
//CATALOGO NIVELES TIEMPO RESPUESTA POR SECCION
export const LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_REQUEST = 'LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_REQUEST';
export const LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_SUCCESS = 'LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_SUCCESS';
export const LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_FAILURE = 'LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_FAILURE';

export const LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_E_REQUEST = 'LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_E_REQUEST';
export const LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_E_SUCCESS = 'LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_E_SUCCESS';
export const LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_E_FAILURE = 'LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_E_FAILURE';

export const NIVELES_TIEMPO_RESPUESTA_BUSQUEDA_REQUEST = 'NIVELES_TIEMPO_RESPUESTA_BUSQUEDA_REQUEST';
export const NIVELES_TIEMPO_RESPUESTA_BUSQUEDA_SUCCESS = 'NIVELES_TIEMPO_RESPUESTA_BUSQUEDA_SUCCESS';
export const NIVELES_TIEMPO_RESPUESTA_BUSQUEDA_FAILURE = 'NIVELES_TIEMPO_RESPUESTA_BUSQUEDA_FAILURE';

export const ESTADO_NIVEL_TIEMPO_RESPUESTA_REQUEST = 'ESTADO_NIVEL_TIEMPO_RESPUESTA_REQUEST';
export const ESTADO_NIVEL_TIEMPO_RESPUESTA_SUCCESS = 'ESTADO_NIVEL_TIEMPO_RESPUESTA_SUCCESS';
export const ESTADO_NIVEL_TIEMPO_RESPUESTA_FAILURE = 'ESTADO_NIVEL_TIEMPO_RESPUESTA_FAILURE';

export const NIVEL_TIEMPO_RESPUESTA_ANTERIOR_REQUEST = 'NIVEL_TIEMPO_RESPUESTA_ANTERIOR_REQUEST';
export const NIVEL_TIEMPO_RESPUESTA_ANTERIOR_SUCCESS = 'NIVEL_TIEMPO_RESPUESTA_ANTERIOR_SUCCESS';
export const NIVEL_TIEMPO_RESPUESTA_ANTERIOR_FAILURE = 'NIVEL_TIEMPO_RESPUESTA_ANTERIOR_FAILURE';

export const NUEVO_NIVEL_TIEMPO_RESPUESTA_REQUEST = 'NUEVO_NIVEL_TIEMPO_RESPUESTA_REQUEST';
export const NUEVO_NIVEL_TIEMPO_RESPUESTA_SUCCESS = 'NUEVO_NIVEL_TIEMPO_RESPUESTA_SUCCESS';
export const NUEVO_NIVEL_TIEMPO_RESPUESTA_FAILURE = 'NUEVO_NIVEL_TIEMPO_RESPUESTA_FAILURE';

export const EDITAR_NIVEL_TIEMPO_RESPUESTA_REQUEST = 'EDITAR_NIVEL_TIEMPO_RESPUESTA_REQUEST';
export const EDITAR_NIVEL_TIEMPO_RESPUESTA_SUCCESS = 'EDITAR_NIVEL_TIEMPO_RESPUESTA_SUCCESS';
export const EDITAR_NIVEL_TIEMPO_RESPUESTA_FAILURE = 'EDITAR_NIVEL_TIEMPO_RESPUESTA_FAILURE';

export const SHOW_MODAL_NUEVO_NIVEL_TIEMPO_RESPUESTA = 'SHOW_MODAL_NUEVO_NIVEL_TIEMPO_RESPUESTA';
export const SHOW_MODAL_EDITAR_NIVEL_TIEMPO_RESPUESTA = 'SHOW_MODAL_EDITAR_NIVEL_TIEMPO_RESPUESTA';

export const CAMBIAR_VISTA_NIVEL_TIEMPO_RESPUESTA = 'CAMBIAR_VISTA_NIVEL_TIEMPO_RESPUESTA';

// Número de Documento por Reiteración de Fallas
export const SHOW_MODAL_NUEVO_NO_DOCUMENTO = 'SHOW_MODAL_NUEVO_NO_DOCUMENTO';
export const SHOW_MODAL_BUSQUEDA_TICKET_REITERADO = 'SHOW_MODAL_BUSQUEDA_TICKET_REITERADO';
export const SHOW_MODAL_BUSQUEDA_TICKET_ORIGEN = 'SHOW_MODAL_BUSQUEDA_TICKET_ORIGEN';
export const SHOW_MODAL_DETALLE_TICKET_REITERADO = 'SHOW_MODAL_DETALLE_TICKET_REITERADO';
export const SHOW_MODAL_DETALLE_TICKET_ORIGEN = 'SHOW_MODAL_DETALLE_TICKET_ORIGEN';
export const SHOW_MODAL_BUSQUEDA_CODIGO_INTERNO = 'SHOW_MODAL_BUSQUEDA_CODIGO_INTERNO';

export const LISTA_DOCUMENTO_REITERADO_ULTIMOS_REQUEST = 'LISTA_DOCUMENTO_REITERADO_ULTIMOS_REQUEST';
export const LISTA_DOCUMENTO_REITERADO_ULTIMOS_SUCCESS = 'LISTA_DOCUMENTO_REITERADO_ULTIMOS_SUCCESS';
export const LISTA_DOCUMENTO_REITERADO_ULTIMOS_FAILURE = 'LISTA_DOCUMENTO_REITERADO_ULTIMOS_FAILURE';

export const LISTA_DOCUMENTO_REITERADO_ULTIMO_WITHOUT_REQUEST = 'LISTA_DOCUMENTO_REITERADO_ULTIMO_WITHOUT_REQUEST';
export const LISTA_DOCUMENTO_REITERADO_ULTIMO_WITHOUT_SUCCESS = 'LISTA_DOCUMENTO_REITERADO_ULTIMO_WITHOUT_SUCCESS';
export const LISTA_DOCUMENTO_REITERADO_ULTIMO_WITHOUT_FAILURE = 'LISTA_DOCUMENTO_REITERADO_ULTIMO_WITHOUT_FAILURE';

export const LISTA_TICKETS_REITERADOS_REQUEST = 'LISTA_TICKETS_REITERADOS_REQUEST';
export const LISTA_TICKETS_REITERADOS_SUCCESS = 'LISTA_TICKETS_REITERADOS_SUCCESS';
export const LISTA_TICKETS_REITERADOS_FAILURE = 'LISTA_TICKETS_REITERADOS_FAILURE';

export const LISTA_TICKETS_ORIGEN_REQUEST = 'LISTA_TICKETS_ORIGEN_REQUEST';
export const LISTA_TICKETS_ORIGEN_SUCCESS = 'LISTA_TICKETS_ORIGEN_SUCCESS';
export const LISTA_TICKETS_ORIGEN_FAILURE = 'LISTA_TICKETS_ORIGEN_FAILURE';

export const GET_DOCUMENTO_RELACIONADO_REQUEST = 'GET_DOCUMENTO_RELACIONADO_REQUEST';
export const GET_DOCUMENTO_RELACIONADO_SUCCESS = 'GET_DOCUMENTO_RELACIONADO_SUCCESS';
export const GET_DOCUMENTO_RELACIONADO_FAILURE = 'GET_DOCUMENTO_RELACIONADO_FAILURE';

export const GET_DOCUMENTO_RELACIONADO_BY_NUMERO_REQUEST = 'GET_DOCUMENTO_RELACIONADO_BY_NUMERO_REQUEST';
export const GET_DOCUMENTO_RELACIONADO_BY_NUMERO_SUCCESS = 'GET_DOCUMENTO_RELACIONADO_BY_NUMERO_SUCCESS';
export const GET_DOCUMENTO_RELACIONADO_BY_NUMERO_FAILURE = 'GET_DOCUMENTO_RELACIONADO_BY_NUMERO_FAILURE';

export const CREAR_DOCUMENTO_REITERACION_REQUEST = 'CREAR_DOCUMENTO_REITERACION_REQUEST';
export const CREAR_DOCUMENTO_REITERACION_SUCCESS = 'CREAR_DOCUMENTO_REITERACION_SUCCESS';
export const CREAR_DOCUMENTO_REITERACION_FAILURE = 'CREAR_DOCUMENTO_REITERACION_FAILURE';

export const TICKETS_REITERADOS_BY_DOCUMENTO_REQUEST = 'TICKETS_REITERADOS_BY_DOCUMENTO_REQUEST';
export const TICKETS_REITERADOS_BY_DOCUMENTO_SUCCESS = 'TICKETS_REITERADOS_BY_DOCUMENTO_SUCCESS';
export const TICKETS_REITERADOS_BY_DOCUMENTO_FAILURE = 'TICKETS_REITERADOS_BY_DOCUMENTO_FAILURE';

export const ELIMINAR_NUMERO_DOCUMENTO_REQUEST = 'ELIMINAR_NUMERO_DOCUMENTO_REQUEST';
export const ELIMINAR_NUMERO_DOCUMENTO_SUCCESS = 'ELIMINAR_NUMERO_DOCUMENTO_SUCCESS';
export const ELIMINAR_NUMERO_DOCUMENTO_FAILURE = 'ELIMINAR_NUMERO_DOCUMENTO_FAILURE';

export const LISTA_ACTIVOS_BY_CODIGO_INTERNO_REQUEST = 'LISTA_ACTIVOS_BY_CODIGO_INTERNO_REQUEST';
export const LISTA_ACTIVOS_BY_CODIGO_INTERNO_SUCCESS = 'LISTA_ACTIVOS_BY_CODIGO_INTERNO_SUCCESS';
export const LISTA_ACTIVOS_BY_CODIGO_INTERNO_FAILURE = 'LISTA_ACTIVOS_BY_CODIGO_INTERNO_FAILURE';

export const CLEAN_ELEMENTS_DOCUMENTO_REITERACION = 'CLEAN_ELEMENTS_DOCUMENTO_REITERACION';
export const CLEAN_ELEMENTS_DOCUMENTO_REITERACION_BEFORE = 'CLEAN_ELEMENTS_DOCUMENTO_REITERACION_BEFORE';

//Cálculo de deductivas por Reiteración para el mes i 
export const SHOW_MODAL_DETALLE_CALCULO_REITERACION = 'SHOW_MODAL_DETALLE_CALCULO_REITERACION';

export const REPORTE_RESUMEN_CALCULO_DEDUCTIVA_REITERACION_REQUEST = 'REPORTE_RESUMEN_CALCULO_DEDUCTIVA_REITERACION_REQUEST';
export const REPORTE_RESUMEN_CALCULO_DEDUCTIVA_REITERACION_SUCCESS = 'REPORTE_RESUMEN_CALCULO_DEDUCTIVA_REITERACION_SUCCESS';
export const REPORTE_RESUMEN_CALCULO_DEDUCTIVA_REITERACION_FAILURE = 'REPORTE_RESUMEN_CALCULO_DEDUCTIVA_REITERACION_FAILURE';

export const EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_REITERACION_REQUEST = 'EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_REITERACION_REQUEST';
export const EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_REITERACION_SUCCESS = 'EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_REITERACION_SUCCESS';
export const EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_REITERACION_FAILURE = 'EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_REITERACION_FAILURE';

export const DETALLE_CALCULO_REITERACION_REQUEST = 'DETALLE_CALCULO_REITERACION_REQUEST';
export const DETALLE_CALCULO_REITERACION_SUCCESS = 'DETALLE_CALCULO_REITERACION_SUCCESS';
export const DETALLE_CALCULO_REITERACION_FAILURE = 'DETALLE_CALCULO_REITERACION_FAILURE';

export const LISTA_DETALLE_CALCULO_REITERACION_REQUEST = 'LISTA_DETALLE_CALCULO_REITERACION_REQUEST';
export const LISTA_DETALLE_CALCULO_REITERACION_SUCCESS = 'LISTA_DETALLE_CALCULO_REITERACION_SUCCESS';
export const LISTA_DETALLE_CALCULO_REITERACION_FAILURE = 'LISTA_DETALLE_CALCULO_REITERACION_FAILURE';

export const EXPORTAR_EXCEL_DETALLE_CALCULO_REITERACION_REQUEST = 'EXPORTAR_EXCEL_DETALLE_CALCULO_REITERACION_REQUEST';
export const EXPORTAR_EXCEL_DETALLE_CALCULO_REITERACION_SUCCESS = 'EXPORTAR_EXCEL_DETALLE_CALCULO_REITERACION_SUCCESS';
export const EXPORTAR_EXCEL_DETALLE_CALCULO_REITERACION_FAILURE = 'EXPORTAR_EXCEL_DETALLE_CALCULO_REITERACION_FAILURE';

export const PROCESAR_DEDUCTIVA_REITERACION_REQUEST = 'PROCESAR_DEDUCTIVA_REITERACION_REQUEST';
export const PROCESAR_DEDUCTIVA_REITERACION_SUCCESS = 'PROCESAR_DEDUCTIVA_REITERACION_SUCCESS';
export const PROCESAR_DEDUCTIVA_REITERACION_FAILURE = 'PROCESAR_DEDUCTIVA_REITERACION_FAILURE';

// Conciliación
export const BUSQUEDA_FOLIO_TICKET_SS_REQUEST = 'BUSQUEDA_FOLIO_TICKET_SS_REQUEST';
export const BUSQUEDA_FOLIO_TICKET_SS_SUCCESS = 'BUSQUEDA_FOLIO_TICKET_SS_SUCCESS';
export const BUSQUEDA_FOLIO_TICKET_SS_FAILURE = 'BUSQUEDA_FOLIO_TICKET_SS_FAILURE';

export const FALLAS_POR_TIPO_DE_FALLA_REQUEST = 'FALLAS_POR_TIPO_DE_FALLA_REQUEST';
export const FALLAS_POR_TIPO_DE_FALLA_SUCCESS = 'FALLAS_POR_TIPO_DE_FALLA_SUCCESS';
export const FALLAS_POR_TIPO_DE_FALLA_FAILURE = 'FALLAS_POR_TIPO_DE_FALLA_FAILURE';

export const NIVELES_DE_SEVERIDAD_POR_FALLA_REQUEST = 'NIVELES_DE_SEVERIDAD_POR_FALLA_REQUEST';
export const NIVELES_DE_SEVERIDAD_POR_FALLA_SUCCESS = 'NIVELES_DE_SEVERIDAD_POR_FALLA_SUCCESS';
export const NIVELES_DE_SEVERIDAD_POR_FALLA_FAILURE = 'NIVELES_DE_SEVERIDAD_POR_FALLA_FAILURE';

export const CREAR_CONCILIACION_REQUEST = 'CREAR_CONCILIACION_REQUEST';
export const CREAR_CONCILIACION_SUCCESS = 'CREAR_CONCILIACION_SUCCESS';
export const CREAR_CONCILIACION_FAILURE = 'CREAR_CONCILIACION_FAILURE';

export const SECCIONES_POR_SERVICIO_REQUEST = 'SECCIONES_POR_SERVICIO_REQUEST';
export const SECCIONES_POR_SERVICIO_SUCCESS = 'SECCIONES_POR_SERVICIO_SUCCESS';
export const SECCIONES_POR_SERVICIO_FAILURE = 'SECCIONES_POR_SERVICIO_FAILURE';

export const FALLAS_POR_SERVICIO_REQUEST = 'FALLAS_POR_SERVICIO_REQUEST';
export const FALLAS_POR_SERVICIO_SUCCESS = 'FALLAS_POR_SERVICIO_SUCCESS';
export const FALLAS_POR_SERVICIO_FAILURE = 'FALLAS_POR_SERVICIO_FAILURE';

//export const NIVELES_DE_SEVERIDAD_VAL = 'NIVELES_DE_SEVERIDAD_VAL';

// Editar conciliacion
export const BUSQUEDA_FOLIO_CONCILIADO_REQUEST = 'BUSQUEDA_FOLIO_CONCILIADO_REQUEST';
export const BUSQUEDA_FOLIO_CONCILIADO_SUCCESS = 'BUSQUEDA_FOLIO_CONCILIADO_SUCCESS';
export const BUSQUEDA_FOLIO_CONCILIADO_FAILURE = 'BUSQUEDA_FOLIO_CONCILIADO_FAILURE';

export const EDITAR_FOLIO_CONCILIADO_REQUEST = 'EDITAR_FOLIO_CONCILIADO_REQUEST';
export const EDITAR_FOLIO_CONCILIADO_SUCCESS = 'EDITAR_FOLIO_CONCILIADO_SUCCESS';
export const EDITAR_FOLIO_CONCILIADO_FAILURE = 'EDITAR_FOLIO_CONCILIADO_FAILURE';

//Folio Conciliación
export const FOLIO_CONCILIACION_BUSQUEDA_REQUEST = 'FOLIO_CONCILIACION_BUSQUEDA_REQUEST';
export const FOLIO_CONCILIACION_BUSQUEDA_SUCCESS = 'FOLIO_CONCILIACION_BUSQUEDA_SUCCESS';
export const FOLIO_CONCILIACION_BUSQUEDA_FAILURE = 'FOLIO_CONCILIACION_BUSQUEDA_FAILURE';

export const MES_FOLIO_CONCILIAR_REQUEST = 'MES_FOLIO_CONCILIAR_REQUEST';
export const MES_FOLIO_CONCILIAR_SUCCESS = 'MES_FOLIO_CONCILIAR_SUCCESS';
export const MES_FOLIO_CONCILIAR_FAILURE = 'MES_FOLIO_CONCILIAR_FAILURE';

export const SERVICIOS_FOLIO_CONCILIACION_REQUEST = 'SERVICIOS_FOLIO_CONCILIACION_REQUEST';
export const SERVICIOS_FOLIO_CONCILIACION_SUCCESS = 'SERVICIOS_FOLIO_CONCILIACION_SUCCESS';
export const SERVICIOS_FOLIO_CONCILIACION_FAILURE = 'SERVICIOS_FOLIO_CONCILIACION_FAILURE';

export const SS_BY_SERVICIO_REQUEST = 'SS_BY_SERVICIO_REQUEST';
export const SS_BY_SERVICIO_SUCCESS = 'SS_BY_SERVICIO_SUCCESS';
export const SS_BY_SERVICIO_FAILURE = 'SS_BY_SERVICIO_FAILURE';

export const SS_BY_FOLIO_REQUEST = 'SS_BY_FOLIO_REQUEST';
export const SS_BY_FOLIO_SUCCESS = 'SS_BY_FOLIO_SUCCESS';
export const SS_BY_FOLIO_FAILURE = 'SS_BY_FOLIO_FAILURE';

export const NUEVO_FOLIO_CONCILIACION_REQUEST = 'NUEVO_FOLIO_CONCILIACION_REQUEST';
export const NUEVO_FOLIO_CONCILIACION_SUCCESS = 'NUEVO_FOLIO_CONCILIACION_SUCCESS';
export const NUEVO_FOLIO_CONCILIACION_FAILURE = 'NUEVO_FOLIO_CONCILIACION_FAILURE';

export const ELIMINAR_SS_FC_REQUEST = 'ELIMINAR_SS_FC_REQUEST';
export const ELIMINAR_SS_FC_SUCCESS = 'ELIMINAR_SS_FC_SUCCESS';
export const ELIMINAR_SS_FC_FAILURE = 'ELIMINAR_SS_FC_FAILURE';

export const EDITAR_FOLIO_CONCILIACION_REQUEST = 'EDITAR_FOLIO_CONCILIACION_REQUEST';
export const EDITAR_FOLIO_CONCILIACION_SUCCESS = 'EDITAR_FOLIO_CONCILIACION_SUCCESS';
export const EDITAR_FOLIO_CONCILIACION_FAILURE = 'EDITAR_FOLIO_CONCILIACION_FAILURE';

export const CERRAR_FOLIO_CONCILIACION_REQUEST = 'CERRAR_FOLIO_CONCILIACION_REQUEST';
export const CERRAR_FOLIO_CONCILIACION_SUCCESS = 'CERRAR_FOLIO_CONCILIACION_SUCCESS';
export const CERRAR_FOLIO_CONCILIACION_FAILURE = 'CERRAR_FOLIO_CONCILIACION_FAILURE';

export const IMPRIMIR_FOLIO_CONCILIACION_REQUEST = 'IMPRIMIR_FOLIO_CONCILIACION_REQUEST';
export const IMPRIMIR_FOLIO_CONCILIACION_SUCCESS = 'IMPRIMIR_FOLIO_CONCILIACION_SUCCESS';
export const IMPRIMIR_FOLIO_CONCILIACION_FAILURE = 'IMPRIMIR_FOLIO_CONCILIACION_FAILURE';

export const MODAL_NUEVO_FOLIO_CONCILIACION = 'MODAL_NUEVO_FOLIO_CONCILIACION';
export const MODAL_EDITAR_FOLIO_CONCILIACION = 'MODAL_EDITAR_FOLIO_CONCILIACION';
export const MODAL_CERRAR_FOLIO_CONCILIACION = 'MODAL_CERRAR_FOLIO_CONCILIACION';

// Folio por Errores u Omisiones
export const FOLIOS_ERROR_OMISION_BUSQUEDA_REQUEST = 'FOLIOS_ERROR_OMISION_BUSQUEDA_REQUEST';
export const FOLIOS_ERROR_OMISION_BUSQUEDA_SUCCESS = 'FOLIOS_ERROR_OMISION_BUSQUEDA_SUCCESS';
export const FOLIOS_ERROR_OMISION_BUSQUEDA_FAILURE = 'FOLIOS_ERROR_OMISION_BUSQUEDA_FAILURE';

export const TICKET_SS_CONCILIADO_REQUEST = 'TICKET_SS_CONCILIADO_REQUEST';
export const TICKET_SS_CONCILIADO_SUCCESS = 'TICKET_SS_CONCILIADO_SUCCESS';
export const TICKET_SS_CONCILIADO_FAILURE = 'TICKET_SS_CONCILIADO_FAILURE';

export const DOCUMENTOS_FISCALIZACION_REQUEST = 'DOCUMENTOS_FISCALIZACION_REQUEST';
export const DOCUMENTOS_FISCALIZACION_SUCCESS = 'DOCUMENTOS_FISCALIZACION_SUCCESS';
export const DOCUMENTOS_FISCALIZACION_FAILURE = 'DOCUMENTOS_FISCALIZACION_FAILURE';

export const NUEVO_FOLIO_ERROR_OMISION_REQUEST = 'NUEVO_FOLIO_ERROR_OMISION_REQUEST';
export const NUEVO_FOLIO_ERROR_OMISION_SUCCESS = 'NUEVO_FOLIO_ERROR_OMISION_SUCCESS';
export const NUEVO_FOLIO_ERROR_OMISION_FAILURE = 'NUEVO_FOLIO_ERROR_OMISION_FAILURE';

export const EDITAR_FOLIO_ERROR_OMISION_REQUEST = 'EDITAR_FOLIO_ERROR_OMISION_REQUEST';
export const EDITAR_FOLIO_ERROR_OMISION_SUCCESS = 'EDITAR_FOLIO_ERROR_OMISION_SUCCESS';
export const EDITAR_FOLIO_ERROR_OMISION_FAILURE = 'EDITAR_FOLIO_ERROR_OMISION_FAILURE';

export const CERRAR_FOLIO_ERROR_OMISION_REQUEST = 'CERRAR_FOLIO_ERROR_OMISION_REQUEST';
export const CERRAR_FOLIO_ERROR_OMISION_SUCCESS = 'CERRAR_FOLIO_ERROR_OMISION_SUCCESS';
export const CERRAR_FOLIO_ERROR_OMISION_FAILURE = 'CERRAR_FOLIO_ERROR_OMISION_FAILURE';

export const MODAL_NUEVO_FOLIO_EO = 'MODAL_NUEVO_FOLIO_EO';
export const MODAL_EDITAR_FOLIO_EO = 'MODAL_EDITAR_FOLIO_EO';
export const MODAL_DETALLE_FOLIO_EO = 'MODAL_DETALLE_FOLIO_EO';
export const MODAL_CERRAR_FOLIO_EO = 'MODAL_CERRAR_FOLIO_EO';
// Cálculo de Deductivas por Errores u Omisiones
export const SHOW_MODAL_DETALLE_CALCULO_ERROR = 'SHOW_MODAL_DETALLE_CALCULO_ERROR';

export const REPORTE_RESUMEN_CALCULO_DEDUCTIVA_ERROR_REQUEST = 'REPORTE_RESUMEN_CALCULO_DEDUCTIVA_ERROR_REQUEST';
export const REPORTE_RESUMEN_CALCULO_DEDUCTIVA_ERROR_SUCCESS = 'REPORTE_RESUMEN_CALCULO_DEDUCTIVA_ERROR_SUCCESS';
export const REPORTE_RESUMEN_CALCULO_DEDUCTIVA_ERROR_FAILURE = 'REPORTE_RESUMEN_CALCULO_DEDUCTIVA_ERROR_FAILURE';

export const EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_ERROR_REQUEST = 'EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_ERROR_REQUEST';
export const EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_ERROR_SUCCESS = 'EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_ERROR_SUCCESS';
export const EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_ERROR_FAILURE = 'EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_ERROR_FAILURE';

export const LISTA_DETALLE_CALCULO_ERROR_REQUEST = 'LISTA_DETALLE_CALCULO_ERROR_REQUEST';
export const LISTA_DETALLE_CALCULO_ERROR_SUCCESS = 'LISTA_DETALLE_CALCULO_ERROR_SUCCESS';
export const LISTA_DETALLE_CALCULO_ERROR_FAILURE = 'LISTA_DETALLE_CALCULO_ERROR_FAILURE';

export const EXPORTAR_EXCEL_DETALLE_CALCULO_ERROR_REQUEST = 'EXPORTAR_EXCEL_DETALLE_CALCULO_ERROR_REQUEST';
export const EXPORTAR_EXCEL_DETALLE_CALCULO_ERROR_SUCCESS = 'EXPORTAR_EXCEL_DETALLE_CALCULO_ERROR_SUCCESS';
export const EXPORTAR_EXCEL_DETALLE_CALCULO_ERROR_FAILURE = 'EXPORTAR_EXCEL_DETALLE_CALCULO_ERROR_FAILURE';

export const PROCESAR_DEDUCTIVA_ERROR_REQUEST = 'PROCESAR_DEDUCTIVA_ERROR_REQUEST';
export const PROCESAR_DEDUCTIVA_ERROR_SUCCESS = 'PROCESAR_DEDUCTIVA_ERROR_SUCCESS';
export const PROCESAR_DEDUCTIVA_ERROR_FAILURE = 'PROCESAR_DEDUCTIVA_ERROR_FAILURE';

// CU - Pago por Servicios
export const LISTA_PAGO_POR_SERVICIO_MESES_REQUEST = 'LISTA_PAGO_POR_SERVICIO_MESES_REQUEST';
export const LISTA_PAGO_POR_SERVICIO_MESES_SUCCESS = 'LISTA_PAGO_POR_SERVICIO_MESES_SUCCESS';
export const LISTA_PAGO_POR_SERVICIO_MESES_FAILURE = 'LISTA_PAGO_POR_SERVICIO_MESES_FAILURE';

export const CALCULAR_PAGO_SERVICIO_REQUEST = 'CALCULAR_PAGO_SERVICIO_REQUEST';
export const CALCULAR_PAGO_SERVICIO_SUCCESS = 'CALCULAR_PAGO_SERVICIO_SUCCESS';
export const CALCULAR_PAGO_SERVICIO_FAILURE = 'CALCULAR_PAGO_SERVICIO_FAILURE';

export const GUARDAR_CALCULO_PAGO_SERVICIO_REQUEST = 'GUARDAR_CALCULO_PAGO_SERVICIO_REQUEST';
export const GUARDAR_CALCULO_PAGO_SERVICIO_SUCCESS = 'GUARDAR_CALCULO_PAGO_SERVICIO_SUCCESS';
export const GUARDAR_CALCULO_PAGO_SERVICIO_FAILURE = 'GUARDAR_CALCULO_PAGO_SERVICIO_FAILURE';

export const PAGO_SERVICIO_DETALLE_REQUEST = 'PAGO_SERVICIO_DETALLE_REQUEST';
export const PAGO_SERVICIO_DETALLE_SUCCESS = 'PAGO_SERVICIO_DETALLE_SUCCESS';
export const PAGO_SERVICIO_DETALLE_FAILURE = 'PAGO_SERVICIO_DETALLE_FAILURE';

export const CERRAR_PAGO_SERVICIO_REQUEST = 'CERRAR_PAGO_SERVICIO_REQUEST';
export const CERRAR_PAGO_SERVICIO_SUCCESS = 'CERRAR_PAGO_SERVICIO_SUCCESS';
export const CERRAR_PAGO_SERVICIO_FAILURE = 'CERRAR_PAGO_SERVICIO_FAILURE';

// Pago de servicios públicos

export const LISTA_SERVICIOS_PUBLICOS_MES_REQUEST = 'LISTA_SERVICIOS_PUBLICOS_MES_REQUEST';
export const LISTA_SERVICIOS_PUBLICOS_MES_SUCCESS = 'LISTA_SERVICIOS_PUBLICOS_MES_SUCCESS';
export const LISTA_SERVICIOS_PUBLICOS_MES_FAILURE = 'LISTA_SERVICIOS_PUBLICOS_MES_FAILURE';

export const LISTA_SERVICIOS_PUBLICOS_GENERALES_REQUEST = 'LISTA_SERVICIOS_PUBLICOS_GENERALES_REQUEST';
export const LISTA_SERVICIOS_PUBLICOS_GENERALES_SUCCESS = 'LISTA_SERVICIOS_PUBLICOS_GENERALES_SUCCESS';
export const LISTA_SERVICIOS_PUBLICOS_GENERALES_FAILURE = 'LISTA_SERVICIOS_PUBLICOS_GENERALES_FAILURE';

export const EXPORTAR_SERVICIOS_PUBLICOS_PDF_REQUEST = 'EXPORTAR_SERVICIOS_PUBLICOS_PDF_REQUEST';
export const EXPORTAR_SERVICIOS_PUBLICOS_PDF_SUCCESS = 'EXPORTAR_SERVICIOS_PUBLICOS_PDF_SUCCESS';
export const EXPORTAR_SERVICIOS_PUBLICOS_PDF_FAILURE = 'EXPORTAR_SERVICIOS_PUBLICOS_PDF_FAILURE';

//RDO | Monitor de Deductivas
export const LISTA_MESES_PROCESADOS_REQUEST = 'LISTA_MESES_PROCESADOS_REQUEST';
export const LISTA_MESES_PROCESADOS_SUCCESS = 'LISTA_MESES_PROCESADOS_SUCCESS';
export const LISTA_MESES_PROCESADOS_FAILURE = 'LISTA_MESES_PROCESADOS_FAILURE';

export const RDO_DEDUCTIVAS_INTERNAS_REQUEST = 'RDO_DEDUCTIVAS_INTERNAS_REQUEST';
export const RDO_DEDUCTIVAS_INTERNAS_SUCCESS = 'RDO_DEDUCTIVAS_INTERNAS_SUCCESS';
export const RDO_DEDUCTIVAS_INTERNAS_FAILURE = 'RDO_DEDUCTIVAS_INTERNAS_FAILURE';

export const RDO_DEDUCTIVAS_EXTERNAS_REQUEST = 'RDO_DEDUCTIVAS_EXTERNAS_REQUEST';
export const RDO_DEDUCTIVAS_EXTERNAS_SUCCESS = 'RDO_DEDUCTIVAS_EXTERNAS_SUCCESS';
export const RDO_DEDUCTIVAS_EXTERNAS_FAILURE = 'RDO_DEDUCTIVAS_EXTERNAS_FAILURE';

export const DETALLE_DEDUCTIVA_RDO_REQUEST = 'DETALLE_DEDUCTIVA_RDO_REQUEST';
export const DETALLE_DEDUCTIVA_RDO_SUCCESS = 'DETALLE_DEDUCTIVA_RDO_SUCCESS';
export const DETALLE_DEDUCTIVA_RDO_FAILURE = 'DETALLE_DEDUCTIVA_RDO_FAILURE';

export const DETALLE_DEDUCTIVA_RDO_EXCEL_REQUEST = 'DETALLE_DEDUCTIVA_RDO_EXCEL_REQUEST';
export const DETALLE_DEDUCTIVA_RDO_EXCEL_SUCCESS = 'DETALLE_DEDUCTIVA_RDO_EXCEL_SUCCESS';
export const DETALLE_DEDUCTIVA_RDO_EXCEL_FAILURE = 'DETALLE_DEDUCTIVA_RDO_EXCEL_FAILURE';

export const DEDUCTIVA_POR_SERVICIO_REQUEST = 'DEDUCTIVA_POR_SERVICIO_REQUEST';
export const DEDUCTIVA_POR_SERVICIO_SUCCESS = 'DEDUCTIVA_POR_SERVICIO_SUCCESS';
export const DEDUCTIVA_POR_SERVICIO_FAILURE = 'DEDUCTIVA_POR_SERVICIO_FAILURE';

export const DEDUCTIVAS_ANTERIORES_RDO_REQUEST = 'DEDUCTIVAS_ANTERIORES_RDO_REQUEST';
export const DEDUCTIVAS_ANTERIORES_RDO_SUCCESS = 'DEDUCTIVAS_ANTERIORES_RDO_SUCCESS';
export const DEDUCTIVAS_ANTERIORES_RDO_FAILURE = 'DEDUCTIVAS_ANTERIORES_RDO_FAILURE';

//  Reporte de Deductivas 
export const REPORTE_DEDUCTIVAS_RESUMEN_REQUEST = 'REPORTE_DEDUCTIVAS_RESUMEN_REQUEST';
export const REPORTE_DEDUCTIVAS_RESUMEN_SUCCESS = 'REPORTE_DEDUCTIVAS_RESUMEN_SUCCESS';
export const REPORTE_DEDUCTIVAS_RESUMEN_FAILURE = 'REPORTE_DEDUCTIVAS_RESUMEN_FAILURE';

export const EXCEL_REPORTE_DEDUCTIVAS_RESUMEN_REQUEST = 'EXCEL_REPORTE_DEDUCTIVAS_RESUMEN_REQUEST';
export const EXCEL_REPORTE_DEDUCTIVAS_RESUMEN_SUCCESS = 'EXCEL_REPORTE_DEDUCTIVAS_RESUMEN_SUCCESS';
export const EXCEL_REPORTE_DEDUCTIVAS_RESUMEN_FAILURE = 'EXCEL_REPORTE_DEDUCTIVAS_RESUMEN_FAILURE';

export const DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_REQUEST = 'DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_REQUEST';
export const DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_SUCCESS = 'DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_SUCCESS';
export const DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_FAILURE = 'DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_FAILURE';

export const EXCEL_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_REQUEST = 'EXCEL_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_REQUEST';
export const EXCEL_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_SUCCESS = 'EXCEL_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_SUCCESS';
export const EXCEL_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_FAILURE = 'EXCEL_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_FAILURE';

export const CONCENTRADO_ANUAL_RESUMEN_REQUEST = 'CONCENTRADO_ANUAL_RESUMEN_REQUEST';
export const CONCENTRADO_ANUAL_RESUMEN_SUCCESS = 'CONCENTRADO_ANUAL_RESUMEN_SUCCESS';
export const CONCENTRADO_ANUAL_RESUMEN_FAILURE = 'CONCENTRADO_ANUAL_RESUMEN_FAILURE';

export const DETALLE_CONCENTRADO_ANUAL_RESUMEN_REQUEST = 'DETALLE_CONCENTRADO_ANUAL_RESUMEN_REQUEST';
export const DETALLE_CONCENTRADO_ANUAL_RESUMEN_SUCCESS = 'DETALLE_CONCENTRADO_ANUAL_RESUMEN_SUCCESS';
export const DETALLE_CONCENTRADO_ANUAL_RESUMEN_FAILURE = 'DETALLE_CONCENTRADO_ANUAL_RESUMEN_FAILURE';

export const EXCEL_CONCENTRADO_ANUAL_RESUMEN_REQUEST = 'EXCEL_CONCENTRADO_ANUAL_RESUMEN_REQUEST';
export const EXCEL_CONCENTRADO_ANUAL_RESUMEN_SUCCESS = 'EXCEL_CONCENTRADO_ANUAL_RESUMEN_SUCCESS';
export const EXCEL_CONCENTRADO_ANUAL_RESUMEN_FAILURE = 'EXCEL_CONCENTRADO_ANUAL_RESUMEN_FAILURE';

export const EXCEL_DETALLE_CONCENTRADO_ANUAL_RESUMEN_REQUEST = 'EXCEL_DETALLE_CONCENTRADO_ANUAL_RESUMEN_REQUEST';
export const EXCEL_DETALLE_CONCENTRADO_ANUAL_RESUMEN_SUCCESS = 'EXCEL_DETALLE_CONCENTRADO_ANUAL_RESUMEN_SUCCESS';
export const EXCEL_DETALLE_CONCENTRADO_ANUAL_RESUMEN_FAILURE = 'EXCEL_DETALLE_CONCENTRADO_ANUAL_RESUMEN_FAILURE';

export const PDF_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_REQUEST = 'PDF_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_REQUEST';
export const PDF_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_SUCCESS = 'PDF_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_SUCCESS';
export const PDF_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_FAILURE = 'PDF_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_FAILURE';