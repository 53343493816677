import React, { Component } from 'react';
import { Form, Input, Button, Row, Col, Spin, Modal, Select, Tooltip } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';

const {Option} = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalNuevoCentroDeCostos extends Component {
    formRef = React.createRef();
    render() {
        const { fetchingNuevoCentroDeCostos, showModalNuevoCentroDeCostos, onShowModalNuevoCentroDeCostos, 
            onNuevoCentroDeCostos, page, busqueda, limpiarFormulario, fetchingListaClasificacionesActivos, listaClasificacionesActivos,
            fetchingListaServiciosActivosF, listaServiciosActivosF,

        } = this.props

        const onFinish = formulario => {
            console.log('Success:' , formulario);
            onNuevoCentroDeCostos(formulario, page, busqueda, listaServiciosActivosF);
        }

        if (limpiarFormulario && limpiarFormulario==='crearCentro') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo);
        };

        const handleCancel = () => {
            onShowModalNuevoCentroDeCostos(page, busqueda);
            this.formRef.current.resetFields();
        }
        return (
            <Modal
                visible={showModalNuevoCentroDeCostos}
                title='Nuevo Centro de Costos'
                onCancel={handleCancel}
                footer={false}
                width='60%'
            >
                <Spin spinning={fetchingNuevoCentroDeCostos}>
                    <Form {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        
                        ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Centro de costos"
                                    name="centroDeCostos"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Input maxLength={80} placeholder='Centro de costos' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Servicios"
                                    name="servicios"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                   <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona un servicio"
                                        loading={fetchingListaServiciosActivosF}
                                        showSearch={true}
                                        mode="multiple"
                                    >
                                        {listaServiciosActivosF.map(option => 
                                        <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                            <Tooltip title={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                {option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}
                                            </Tooltip>
                                        </Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Gerencia"
                                    name="clasificacionGerencia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona una gerencia"
                                        loading={fetchingListaClasificacionesActivos}
                                        showSearch={true}
                                    >
                                        {listaClasificacionesActivos.map(option => 
                                        <Option key={option.idClasificacionGerencia}>
                                            <Tooltip title={option.nombreGerencia}>
                                                {option.nombreGerencia}
                                            </Tooltip>
                                        </Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Crear Centro de Costos</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>

            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingNuevoCentroDeCostos: state.CatalogosGeneralesReducer.fetchingNuevoCentroDeCostos,
        showModalNuevoCentroDeCostos: state.CatalogosGeneralesReducer.showModalNuevoCentroDeCostos,
        page: state.CatalogosGeneralesReducer.page,
        busqueda: state.CatalogosGeneralesReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.fetchingListaClasificacionesActivos,
        listaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.listaClasificacionesActivos,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevoCentroDeCostos: (page, busqueda) => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_CENTRO_COSTOS', showModalNuevoCentroDeCostos: false, page, busqueda });
        },
        onNuevoCentroDeCostos: (formulario, page, busqueda, listaServiciosActivosF) => {
            dispatch( { type: 'NUEVO_CENTRO_COSTOS_REQUEST', formulario, page, busqueda, listaServiciosActivosF });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoCentroDeCostos);