import React from 'react';
import { Card, Alert, Row, Col, List } from 'antd';
import { connect } from "react-redux";
import { ShrinkOutlined, ArrowsAltOutlined, ImportOutlined } from '@ant-design/icons';
//Importar catalogos aquí
import CatalogoTiempoDeGarantia from './TiemposDeGarantia/CatalogoTiemposDeGarantia';
import CatalogoFallasDeCalidad from './CatalogoCategoriaFallasDeCalidad/CatalogoFallasDeCalidad';
import CatalogoFallasDeDisponibilidad from './CatalogoFallasDeDisponibilidad/CatalogoFallasDeDisponibilidad';
import CatalogoEstandaresDesempenoEspecifico from './CatalogoServiciosySeccionesEstandaresDesempeñoEspecificos/CatalogoEstandaresDesempeñoEspecificos';
import CatalogoClavesMetodosSupervision from './CatalogoClavesMetodosDeSupervision/CatalogoClavesMetodosDeSupervision';
import CatalogoPonderacionServPorArea from './CatalogoPonderacionServPorArea/CatalogoPonderacionSerPorArea';
import CatalogoReferenciasDeEstandares from './CatalogoReferenciasDeEstandares/CatalogoReferenciasDeEstandares';
import CatalogoEstandaresDeDesempeño from './CatalogoEstandaresDeDesempeño/CatalogoEstandaresDeDesempeño';
import CatalogoPeriodoFraccionMaximaDescuento from './CatalogoPeriodoFMD/CatalogoPeriodoFraccionMaximaDescuento';
import NivelesSeveridadContainer from './CatalogoNivelesSeveridad/NivelesSeveridadContainer';
import CtlgNivelesTiempoRespuesta from './CatalogoNivelesTiempoRespuestaPorSeccion/CatalogoNivelesTiempoRespuesta';
import '../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

import CatalogoTiemposDeRehabilitacion from '../CatalogosGenerales/CatalogoDeFallasDeDisponibilidad/CatalogoDeFallasDeDisponibilidad';

import imaSearch from '../../assets/undraw_searching_re_3ra9.svg';
import ReactVivus from "react-vivus";

function isEmpty(obj) {
    for(var prop in obj) {
        if(obj.hasOwnProperty(prop))
            return false;
    }
    return true;
}
class DashboardContainer extends React.Component {
    state={
        catalogo: 'Selecciona un Catálogo',
        colLista: 8,
        colTabla: 15,
        maximizar: false
    }
    render() {
        const { onChangeCatalogo, catalogoMecanismosDePagos } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const listaCatalogos = [];
        if(permisosUsuario.buscarTiempoDeGarantiaservicio){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Tiempos de Garantía',
            })
        }
        if(permisosUsuario.buscarCategoriaDeFallasDeCalidad){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Categorías de Fallas de Calidad',
            })
        }
        if(permisosUsuario.buscarCategoriaPorFallaDeDisponibilidad){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Categorías de Fallas de Disponibilidad',
            })
        }
        if(permisosUsuario.buscarNuevaCategoríaDeTiempoDeRehabilitación){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Tiempos de Rehabilitación',
            })
        }
        if(permisosUsuario.verPonderaciónDeServiciosPorÁreaFuncional){
            listaCatalogos.push({
                'catalogo': 'Ponderación de Servicios por Área Funcional',
            })
        }
        if(permisosUsuario.buscarSecciónDeEstándaresEspecíficos){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Secciones de Estándares Específicos',
            })
        }
        if(permisosUsuario.buscarRefenciaDeEstándaresDeDesempeño){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Referencias de Estándares de Desempeño',
            })
        }
        if(permisosUsuario.buscarMetodoDeSupervision){
            listaCatalogos.push({
                'catalogo': 'Métodos de Supervisión',
            })
        }
        if(permisosUsuario.buscarEstándarDeDesempeño){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Estándares de Desempeño',
            })
        }
        if(permisosUsuario.verPeriodoDeFracciónMáximaDeDescuento){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Periodo Fracción Máxima de Descuento',
            })
        }
        if(permisosUsuario.buscarNivelDeSeveridad){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Niveles de Severidad',
            })
        }
        if(permisosUsuario.buscarTiempoDeRespuestaPorSección){
            listaCatalogos.push({
                'catalogo': 'Catálogo de Niveles de Tiempos de Respuesta por Sección',
            })
        }

        function ShowInfo(props) {
            if (isEmpty(catalogoMecanismosDePagos)) {
                return (
                    <Row justify='space-around' align='middle'>
                        <Col span={24}>
                            <Alert message="Favor de seleccionar un catálogo" type="info" />
                        </Col>
                        <Col span={10} style={{paddingTop:'8em', paddingBottom:'8em'}}>
                            {/* <img src={imaSearch} /> */}
                            <ReactVivus
                                id="imaSearch"
                                option={{
                                    file: imaSearch,
                                    animTimingFunction: "EASE",
                                    type: "delayed",
                                }}
                            />
                        </Col>
                    </Row>);
            }
            
	        return (
                <div>
                    <CatalogoApp/>
                </div>
            );
        }

        function CatalogoApp() {
            if(catalogoMecanismosDePagos === 'Catálogo de Tiempos de Garantía'){
                return(
                    <div>
                        <CatalogoTiempoDeGarantia/>
                    </div>
                );
            }
            else if(catalogoMecanismosDePagos === 'Catálogo de Categorías de Fallas de Calidad'){
                return(
                    <div>
                        <CatalogoFallasDeCalidad/>
                    </div>
                );
            }
            else if(catalogoMecanismosDePagos === 'Catálogo de Categorías de Fallas de Disponibilidad'){
                return(
                    <div>
                        <CatalogoFallasDeDisponibilidad/>
                    </div>
                );
            }
            else if(catalogoMecanismosDePagos === 'Catálogo de Tiempos de Rehabilitación'){
                    return(
                        //Tiempos de Rehabilitación
                        <div>
                            <CatalogoTiemposDeRehabilitacion/>
                        </div>
                    );
            }
            else if(catalogoMecanismosDePagos === 'Ponderación de Servicios por Área Funcional'){
                return(
                    <div>
                        <CatalogoPonderacionServPorArea/>
                    </div>
                );
            }
            else if(catalogoMecanismosDePagos === 'Catálogo de Estándares de Desempeño'){
                return(
                    <div>
                        <CatalogoEstandaresDeDesempeño/>
                    </div>
                );
            }
            else if(catalogoMecanismosDePagos === 'Catálogo de Secciones de Estándares Específicos'){
                return(
                    <div>
                        <CatalogoEstandaresDesempenoEspecifico />
                    </div>
                );
            }
            else if(catalogoMecanismosDePagos === 'Catálogo de Referencias de Estándares de Desempeño'){
                return(
                    <div>
                        <CatalogoReferenciasDeEstandares/>
                    </div>
                );
            }
            else if(catalogoMecanismosDePagos === 'Métodos de Supervisión'){
                return(
                    <div>
                        <CatalogoClavesMetodosSupervision />
                    </div>
                );
            }
            else if(catalogoMecanismosDePagos === 'Catálogo de Periodo Fracción Máxima de Descuento'){
                return(
                    <div>
                        <CatalogoPeriodoFraccionMaximaDescuento />
                    </div>
                );
            }
            else if(catalogoMecanismosDePagos === 'Catálogo de Niveles de Severidad'){
                return(
                    <div>
                        <NivelesSeveridadContainer/>
                    </div>
                );
            }
            else if(catalogoMecanismosDePagos === 'Catálogo de Niveles de Tiempos de Respuesta por Sección'){
                return(
                    <div>
                        <CtlgNivelesTiempoRespuesta />
                    </div>
                );
            }
			return <div>  </div>;
        };
        
        const changeCatalogo = (catalogo) => {
            this.setState({
                catalogo: catalogo.catalogo,
            })
            onChangeCatalogo(catalogo.catalogo);
        }
        const onExpandir = () => {
            if(!this.state.maximizar){
                this.setState({
                    colLista: 0,
                    colTabla: 24,
                    maximizar: true
                });
            }
            else{
                this.setState({
                    colLista: 8,
                    colTabla: 15,
                    maximizar: false,
                });
            }
        }

        return (
            <div className="imgCorp">
                <Row justify='space-between'>
                    <Col span={this.state.colLista}>
                        <List
                            header={
                                <div className='headerLista'>Catálogos Mecanismos de Pagos</div>
                            }
                            className="listaCatalogos"
                            bordered
                            dataSource={listaCatalogos}
                            renderItem={item => (
                            <List.Item className='listaHover'
                                actions={[<ImportOutlined onClick={(e) => { e.stopPropagation(); changeCatalogo(item); } } className="iconAbrir" />]}
                            >
                                <div>{item.catalogo}</div>
                            </List.Item>
                            )}
                        />                    
                    </Col>
                    <Col span={this.state.colTabla}>
                        <Card className="cardCatalogos" title={<div className='headerLista'>{this.state.catalogo}</div>} extra={this.state.maximizar ? (<ShrinkOutlined className='iconoMaxMin' onClick={onExpandir} />):(<ArrowsAltOutlined className='iconoMaxMin' onClick={onExpandir}/>)}>
                            <ShowInfo info={catalogoMecanismosDePagos} />
                        </Card> 
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        vistaDashboard: state.ConfiguracionYSeguridadReducer.vistaDashboard,
        catalogoMecanismosDePagos: state.MecanismosDePagosReducer.catalogoMecanismosDePagos,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onChangeCatalogo: (catalogoMecanismosDePagos) => {
            dispatch({ type: "CAMBIAR_CATALOGO_MECANISMOS_PAGOS" , catalogoMecanismosDePagos: catalogoMecanismosDePagos});
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);