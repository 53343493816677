import React from 'react';
import { Form, Spin, Row, Col, Tooltip, Menu, Button, Select, Typography, Descriptions, Empty} from 'antd';
import { connect } from "react-redux"; 
import '../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import {  FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Equation,  defaultErrorHandler  } from "react-equation";

const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
const {Text} = Typography;

class ResumenPagoServicio extends React.Component {
     formRef = React.createRef();
     
     componentDidMount(){ this.props.onGetResumen(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP); }

     state={ }
 
     render() {
          const { resumenPagoServicio, onGetPDF, onGetEXCEL } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){ permisosUsuario = JSON.parse(permisosUsuario); }

          const onFinish = (formulario) => {
               console.log('Success:', formulario);
          };

          console.log('resumenPagoServicio', resumenPagoServicio)
          let dataDescription = [];
          if(resumenPagoServicio[0]){
               dataDescription = [
                    {label: <Tooltip title='Valor del Pago Mensual de Inversión para el mes'> PMIi </Tooltip>, data: resumenPagoServicio[0].pmiTabla},
                    {label: <Tooltip title='Valor del Pago Mensual de Referencia para el mes'> PMRi </Tooltip>, data: resumenPagoServicio[0].pmrTabla},
                    {label: <Tooltip title='Valor de la Deducción Aplicable para el mes'> DA </Tooltip>, data: resumenPagoServicio[0].daTabla},
                    {label: <Tooltip title='Deducción de los Servicios Públicos utilizados por el Desarrollador en el mes'> CPDi </Tooltip>, data: resumenPagoServicio[0].cpdTabla},
                    {label: <Tooltip title='Pago de los Servicios Públicos para el mes'> SPi </Tooltip>, data: resumenPagoServicio[0].spTabla},
                    {label: <Tooltip title='Resultado de la fórmula'> PMSi </Tooltip>, data: resumenPagoServicio[0].pmsTabla},
               ];
          }

          const onExportar = (e) => {
               if(e === 'pdf'){
                    onGetPDF(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, this.props.mesTexto);
               } else {
                    onGetEXCEL(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, this.props.mesTexto);
               }
          };

          console.log('Entra a container raiz')

          return (<>
               <Form {...layout} name="formulario" ref={this.formRef} onFinish={onFinish}>
                    <Row justify='space-around' gutter={[0,16]}>
                         <Col span={20}>
                              <div style={{textAlign:'center'}}>
                                   <Text strong style={{ fontSize: 16 }}> MONTO A FACTURAR POR EL PAGO DE SERVICIO PARA {this.props.mesTexto.toUpperCase()} {this.props.anioSeleccionadoRMDP} </Text>
                              </div>
                         </Col>
                         <Col span={1}>
                              <Tooltip title='Exportar resumen del pago por servicio .pdf'>
                                   <Button className="iconSecundary" shape="circle" loading={this.props.fetchingResumenPagoPDF} icon={<FilePdfOutlined />} onClick={(e) => { onExportar('pdf'); }}/>
                              </Tooltip>
                         </Col>
                         <Col span={1}>
                              <Tooltip title='Exportar resumen del pago por servicio .xls'>
                                   <Button className="iconSecundary" shape="circle" loading={this.props.fetchingResumenPagoEXCEL} icon={<FileExcelOutlined />} onClick={(e) => { onExportar('xls'); }}/>
                              </Tooltip>
                         </Col>

                         <Col span={24}>
                              <div style={{textAlign:'center'}}>
                                   <Text style={{fontSize: 24}}>
                                        <Equation
                                             value="PMS_i = PMI_i + (PMR_i - DA_i) - CPD_i + SP_i"
                                             errorHandler={defaultErrorHandler}
                                        />
                                   </Text>
                              </div>
                         </Col>
                         
                         { this.props.resumenPagoServicio.length > 0 ? (
                              <Col span={24}>
                                   <Spin spinning={this.props.fetchingResumenPagoServicio}>
                                        <Descriptions bordered= {true} layout="horizontal">
                                             {dataDescription.map( item => <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item> )}
                                        </Descriptions>
                                   </Spin>
                              </Col>
                         ):(
                              <Col span={24}>
                                   <Spin spinning={this.props.fetchingResumenPagoServicio}>
                                        <Empty 
                                             description='No se ha calculado el pago por servicio para el mes.'
                                        />
                                   </Spin>
                              </Col>
                         )}
                    </Row>
               </Form>
          </>);
     }
}
 
const mapStateToProps = state => {
     return {
          anioSeleccionadoRMDP: state.RMDPReducer.anioSeleccionadoRMDP,
          mesTexto: state.RMDPReducer.mesTexto,
          mesSeleccionadoRMDP: state.RMDPReducer.mesSeleccionadoRMDP,
          fetchingResumenPagoServicio: state.RMDPReducer.fetchingResumenPagoServicio,
          resumenPagoServicio: state.RMDPReducer.resumenPagoServicio,
          fetchingResumenPagoPDF: state.RMDPReducer.fetchingResumenPagoPDF,
          fetchingResumenPagoEXCEL: state.RMDPReducer.fetchingResumenPagoEXCEL,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onGetResumen: (idMes, anio) => {
               dispatch({ type: 'RESUMEN_PAGO_SERVICIO_REQUEST', idMes, anio });
          },
          onGetPDF: (idMes, anio, mesTxt) => {
               dispatch({ type: 'PDF_RESUME_PAGO_REQUEST', idMes, anio, mesTxt})
          },
          onGetEXCEL: (idMes, anio, mesTxt) => {
               dispatch({ type: 'EXCEL_RESUME_PAGO_REQUEST', idMes, anio, mesTxt})
          },
     };
};
 export default connect(mapStateToProps, mapDispatchToProps)(ResumenPagoServicio);