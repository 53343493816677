import React, { Component } from 'react';
import {Row, Col, Modal, List} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';

class ModalDetalleMesCalendario extends Component {
    formRef = React.createRef();

    render() {
        const { onShowModalDetalleMesCalendario, showModalDetalleMesCalendario, detalleMesCalendario,
            fetchingDetalleMesCalendario,
        } = this.props;

        let data=[]
        if (detalleMesCalendario){            
            data = [
                {title:"Año", data: detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].anio},
                {title:"dai",data: detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].dai},
                {title:"Estatus del año",data: detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].estadoAnio},
                {title:"Fecha de cierre de año",data: detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].fechaCierreAnio},
                {title:"Mes",data: detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].mes},
                {title:"dmi",data: detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].dmi},
                {title:"Estatus del mes",data: detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].estadoMesDetalle},
                {title:"Fecha de cierre de mes",data: detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].fechaCierreMes},
                {title:"INPC i-1",data: detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].inpc},
                {title:"Fecha de publicación de INPC",data: detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].fechaPubInpc},
                {title:"Fuente de publicación de INPC",data: detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].fuentePubInpc},
                {title:"pri",data: detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].priDetalle},
                {title:"Pti",data: detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].ptiDetalle},
                {title:"Pvi",data: detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].pviDetalle},
                {title:"PMI",data: detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].pmiDetalle},
                {title:"PMR",data: detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].pmrDetalle},
                {title:"DMA",data: detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].dmaDetalle},
                {title:"di",data: detalleMesCalendario[0].mesesCalendarioByIdAnioCalendario[0].diDetalle},
            ];
        }
        const handleCancel = () => {
            onShowModalDetalleMesCalendario();
        }
        
        return (
            <Modal
                visible={showModalDetalleMesCalendario}
                title='Detalle del mes'
                onCancel={handleCancel}
                footer={false}
                width={'50%'}
            >
                <Row justify='space-around'>
                    <Col span={24}>
                        <List
                        className="listaCatalogos"
                        bordered
                        dataSource={data}
                        loading={fetchingDetalleMesCalendario}
                        renderItem={item => (
                           <List.Item className='listaHover' className='listaHover'>
                                <Col span={11}>
                                    <strong>{item.title}</strong>  
                                </Col>
                                <Col span={11}>
                                    {item.data ? item.data : '-'} 
                                </Col>
                            </List.Item>
                        )}
                        />
                    </Col>
                </Row>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalDetalleMesCalendario: state.MecanismosDePagosReducer.showModalDetalleMesCalendario,
        detalleMesCalendario: state.MecanismosDePagosReducer.detalleMesCalendario,
        fetchingDetalleMesCalendario: state.MecanismosDePagosReducer.fetchingDetalleMesCalendario,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalDetalleMesCalendario: () => {
            dispatch({ type: 'SHOW_MODAL_DETALLE_MES_CALENDARIO', onShowModalDetalleMesCalendario: false });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetalleMesCalendario);