import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {Table, Row, Col, Button, DatePicker, Card, Select, message, Form} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ReporteServiciosPlanificados extends React.Component {
    formRef = React.createRef();
    state = {
        page:1,
        tipo: null,
        programa: null,
        serviciosTodos: false
    }

    componentDidMount() {
        this.props.onGetServiciosActivos();
    }

    render() {

        const {listaReporteSPBusqueda, fetchingListaReporteSPBusqueda, listaReporteSPBusquedaCantidad, fetchingListaServiciosFiltro,listaServiciosFiltro, listaServiciosActivosF,fetchingDescargaListaReporteSPPDF, fetchingDescargaListaRerporteSPExcel,
            onListaServiciosFiltro, onListaServiciosFiltroLimpiar,onGetReporteSP,onDescargarPDF, onDescargarExcel} = this.props;

        const {tipo, programa, page} = this.state;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columnsOrden = [
            {title: 'Folio',dataIndex: 'folioOrden', key: 'folioOrden'},
            {title: 'Categoría de servicio',dataIndex: 'categoriaServicio',key: 'categoriaServicio'},
            {title: 'Servicio',dataIndex: 'servicio',key: 'servicio'},
            {
                title: 'Partida',
                dataIndex: 'partida',
                key: 'partida'
            },
            {title: 'Tipo de rutina',dataIndex: 'programa',key: 'programa'},
            {title: 'Nombre de rutina',dataIndex: 'nombreRutina',key: 'nombreRutina'},
            {title: 'Ubicación',dataIndex: 'ubicacion',key: 'ubicacion',width:'22%'},
            {title: 'Estatus',dataIndex: 'estado',key: 'estado'},
            programa !== 'Rutina' ? {title: 'Descripcion activo',dataIndex: 'descripcion',key: 'descripcion',} : {width:'0%'}, 
            programa !== 'Rutina' ? {title: 'Codigo equipo',dataIndex: 'codigoEquipo',key: 'codigoEquipo'} : {width:'0%'},
        ]
        const columnsTarea = [
            {title: 'Categoría de servicio',dataIndex: 'categoriaServicio',key: 'categoriaServicio'},
            {title: 'Servicio',dataIndex: 'servicio',key: 'servicio'},
            {
                title: 'Partida',
                dataIndex: 'partida',
                key: 'partida'
            },
            {title: 'Tipo de rutina',dataIndex: 'programa',key: 'programa'},
            {title: 'Nombre de rutina',dataIndex: 'nombreRutina',key: 'nombreRutina'},
            {title: 'Fecha',dataIndex: 'fechaEjecucion',key: 'fechaEjecucion'},
            {title: 'Ubicación',dataIndex: 'ubicacion',key: 'ubicacion', width:'22%'},
            {title: 'Estatus',dataIndex: 'estado',key: 'estado'},
            programa !== 'Rutina' ? {title: 'Descripcion activo',dataIndex: 'descripcion',key: 'descripcion'} : {width:'0%'}, 
            programa !== 'Rutina' ? {title: 'Codigo equipo',dataIndex: 'codigoEquipo',key: 'codigoEquipo'} : {width:'0%'},
        ]
        const columns = tipo !== 'Tarea' ? columnsOrden : columnsTarea;  

        const buscarServicios = value => {
            if (value.length >= 3) {
                if (value) {
                    console.log(value);
                    onListaServiciosFiltro(value)
                } else {
                    message.warning('No se encontró servicio con esas palabras')
                }
            } else {
                onListaServiciosFiltroLimpiar();
            }
        }
        const onChangeServicios = value => {
            console.log('value', value)
            if(value.length > 0){
                this.formRef.current.resetFields(['partida']);
                this.setState({serviciosTodos: false});
                const todos = value.some(item => item === 'todos');
                if(todos) {
                    let allServicios = [];
                    for(let i in listaServiciosActivosF){ allServicios.push(listaServiciosActivosF[i].idServicio) }
                    this.setState({serviciosTodos: true});
                    this.formRef.current.setFieldsValue({'servicio':['todos']})
                    this.props.onGetPartidasByIdsServicios(allServicios);
                } else {
                    this.props.onGetPartidasByIdsServicios(value);
                }
            } else {
                this.setState({serviciosTodos: false});
                this.formRef.current.resetFields(['partida']);
                this.props.onCleanPartidasByIdsServicios();
            }
        }

        const onPartidaSelect = (value) => {
            if(value.length > 0){
                let partidas = [];
                for(let i in value){partidas.push(value[i].split(' - ')[0])}
                this.setState({idPartida: partidas})
            } else {
                this.setState({ idPartida: ''})
                this.formRef.current.resetFields(['partida']);
            }
        };

        const handleSelect = (value, field) => {
            this.setState({[field]: value});
            if(field === 'tipo') {
                this.formRef.current.setFieldsValue({'estatus': undefined});
            }
        }

        const disabledRange = fecha => {
            const currentYear = new Date();
            const year = currentYear.getFullYear();
            const fechaVigencia = moment(`1/1/${year+1}`, "DD-MM-YYYY")
            const fechaInicio = moment(`1/1/${year}`, "DD-MM-YYYY")
            if (fecha && fecha < fechaInicio) {
                return fecha && fecha < fechaInicio;
            } else {
                return fecha && fecha > fechaVigencia;
            }
        }

        const onFinish = formulario => {
            console.log(formulario);
            onGetReporteSP(formulario,listaServiciosActivosF, 1);
            this.setState({page:1});
        }
    
        const descargarArchivo = (tipoArchivo) => {
            const formulario = this.formRef.current.getFieldsValue();
            if(formulario.tipo !== undefined){
                if(tipoArchivo === 'pdf'){
                    onDescargarPDF(formulario, listaServiciosActivosF);
                } else {
                    onDescargarExcel(formulario,listaServiciosActivosF);
                }
            } else {
                message.warning('Seleccione un tipo para descargar archivo.')
            }
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            const formulario = this.formRef.current.getFieldsValue();
            onGetReporteSP(formulario,listaServiciosActivosF, pagination.current);
        }

        const estatusValues = {orden: ['Generada','Realizada','Fuera de tiempo', 'Reprogramada','Cancelada'],
        tarea:['En proceso','Programada','Reprogramada','Realizada','Fuera de tiempo']}

        const scroll = {"x": 1500 | true, "y": 1000};

        return (
            <div>
                <Card className="cardCatalogos" title={<div className='headerLista'>Reporte Servicios Planificados</div>}>
                    <Form {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        ref={this.formRef}
                    >
                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item
                                    name='tipo'
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                    <Select
                                        allowClear
                                        onChange={value => handleSelect(value, 'tipo')}
                                        style={{ width: '100%' }} placeholder="Selecciona un tipo"
                                    >
                                        <Option value='Orden'>Ordenes programadas</Option>
                                        <Option value='Tarea'>Tareas programadas</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    name='estatus'
                                >
                                    <Select
                                        allowClear
                                        disabled={!tipo}
                                        style={{ width: '100%' }} placeholder="Selecciona un estatus"
                                    >
                                        {tipo === 'Orden' ? estatusValues.orden.map(item => <Option value={item} key={item}>{item}</Option>) :
                                            estatusValues.tarea.map(item => <Option value={item} key={item}>{item}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={1}>
                                <Button shape="circle" icon={<SearchOutlined />} className="btnFiltrar" htmlType="submit"></Button>
                            </Col>
                        </Row>
                        <Row justify='space-between' gutter={[8,8]}>
                            <Col span={6}>
                                <Form.Item
                                    name='servicio'
                                >
                                    <Select
                                        mode='multiple'
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona un servicio"
                                        loading={this.props.fetchingListaServiciosActivosF}
                                        showSearch
                                        onChange={onChangeServicios}
                                    >
                                        <OptGroup label='Todos los servicios'>
                                            <Option value="todos">Todos los servicios</Option>
                                        </OptGroup>
                                        <OptGroup label='Servicios'>
                                            {listaServiciosActivosF.map(option => <Option disabled={this.state.serviciosTodos} key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>{option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}</Option>)}
                                        </OptGroup>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item  name="partidas">
                                    <Select
                                        mode='multiple'
                                        allowClear
                                        showSearch={true}
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona una partidas"
                                        loading={this.props.fetchingListaPartidasByIdsServicios}
                                        disabled={!this.props.listaPartidasByIdsServicios[0]}
                                        onChange={onPartidaSelect}
                                        value={this.state.partida}
                                    >
                                        {this.props.listaPartidasByIdsServicios.map(option =>
                                            <Option key={option.idPartida + ' - ' + option.nomenclatura  + ': ' + option.nombre}>
                                                {option.idPartida + ' - ' + option.nomenclatura  + ': ' + option.nombre}
                                            </Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name='programa'
                                >
                                    <Select
                                        allowClear
                                        onChange={value => handleSelect(value, 'programa')}
                                        style={{ width: '100%' }} placeholder="Selecciona un programa"
                                    >
                                        <Option value='Rutina'>Rutina</Option>
                                        <Option value='Mantenimiento'>Mantenimiento</Option>
                                        <Option value='Prueba'>Prueba</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name='fecha'
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <RangePicker
                                        format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio", "Fecha fin"]}
                                        // disabledDate={disabledRange}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Row>
                        <Col span={24}>
                            <Table
                                size="small"
                                rowKey={'idFechaRutina'}
                                columns={columns}
                                dataSource={listaReporteSPBusqueda}
                                loading={fetchingListaReporteSPBusqueda}
                                locale={{ emptyText: 'Sin datos' }}
                                onChange={handleTableChange}
                                pagination={{ total: listaReporteSPBusquedaCantidad, simple: true, current: this.state.page }}
                                scroll={scroll}
                            />
                        </Col>
                    </Row>
                    <Row type='flex' justify='space-around'>
                        <Col span={11}>
                            <Button disabled={!permisosUsuario.descargarPdfDeReporteDeServiciosPlanificados ? true : (!listaReporteSPBusqueda.length>0 || fetchingDescargaListaReporteSPPDF)} loading={fetchingDescargaListaReporteSPPDF} className="btnFiltrar" style={{ width: '100%' }} onClick={()=>descargarArchivo('pdf')}>Descargar PDF</Button>
                        </Col>
                        <Col span={11}>
                            <Button disabled={!permisosUsuario.descargarExcelDeReporteDeServiciosPlanificados ? true : (!listaReporteSPBusqueda.length>0 || fetchingDescargaListaRerporteSPExcel)} loading={fetchingDescargaListaRerporteSPExcel} className="btnFiltrar" style={{ width: '100%' }} onClick={()=>descargarArchivo('excel')}>Descargar Excel</Button>
                        </Col>
                    </Row>
                </Card>
            </div>  
        );
    }
}

const mapStateToProps = state => {
    return {
        listaReporteSPBusqueda: state.ServiciosPlanificadosReducer.listaReporteSPBusqueda,
        fetchingListaReporteSPBusqueda: state.ServiciosPlanificadosReducer.fetchingListaReporteSPBusqueda,
        listaReporteSPBusquedaCantidad: state.ServiciosPlanificadosReducer.listaReporteSPBusquedaCantidad,
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        fetchingDescargaListaReporteSPPDF: state.ServiciosPlanificadosReducer.fetchingDescargaListaReporteSPPDF,
        fetchingDescargaListaRerporteSPExcel: state.ServiciosPlanificadosReducer.fetchingDescargaListaRerporteSPExcel,

        listaPartidasByIdsServicios: state.ConfiguracionYSeguridadReducer.listaPartidasByIdsServicios,
        fetchingListaPartidasByIdsServicios: state.ConfiguracionYSeguridadReducer.fetchingListaPartidasByIdsServicios,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda});
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []});
        },
        onGetServiciosActivos: () => {
            dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
        },
        onGetReporteSP: (formulario, listaServiciosActivosF, page) => {
            dispatch({ type: "LISTA_BUSQUEDA_REPORTE_SP_REQUEST", formulario, listaServiciosActivosF, page});
        },
        onDescargarPDF: (formulario, listaServiciosActivosF) => {
            dispatch({ type: "DESCARGAR_PDF_REPORTE_SP_REQUEST", formulario, listaServiciosActivosF});
        },
        onDescargarExcel: (formulario, listaServiciosActivosF) => {
            dispatch({ type: "DESCARGAR_EXCEL_REPORTE_SP_REQUEST", formulario, listaServiciosActivosF});
        },

        onGetPartidasByIdsServicios: (idsServicios) => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_IDSSERVICIOS_REQUEST', idsServicios });
        },
        onCleanPartidasByIdsServicios: () => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_IDSSERVICIOS_SUCCESS', listaPartidasByIdsServicios: [] });
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ReporteServiciosPlanificados);