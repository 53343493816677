import React, { Component } from 'react';
import { Row, Form, Input, Button, Modal, Col, Spin } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalNuevoModulo extends Component {
    formRef = React.createRef();
    render() {
        const { fetchingNuevoModulo, showModalNuevoModulo, onNuevoModulo, page, 
            limpiarFormulario, onLimpiarFormulario, onShowModalNuevoModulo,
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onNuevoModulo(formulario, page);
        };
        
        if(limpiarFormulario && limpiarFormulario === 'crearModulo'){
            this.formRef.current.resetFields();
            onLimpiarFormulario();
        }
        

        const handleCancel = () => {
            onShowModalNuevoModulo();
            this.formRef.current.resetFields();
        }
        return (
            <Modal
            visible={showModalNuevoModulo}
            title='Nuevo Módulo Sistema'
            onCancel={handleCancel}
            footer={false}
            >
                <Spin spinning={fetchingNuevoModulo}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={24}>
                                <Form.Item
                                    label="Nombre del módulo"
                                    name="nombre"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={60} placeholder="Ingresa el nombre del módulo"/>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Crear Módulo</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingNuevoModulo: state.ConfiguracionYSeguridadReducer.fetchingNuevoModulo,
        showModalNuevoModulo: state.ConfiguracionYSeguridadReducer.showModalNuevoModulo,
        page: state.ConfiguracionYSeguridadReducer.page,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalNuevoModulo: () => {
            dispatch({ type: 'SHOW_NUEVO_MODULO', showModalNuevoModulo: false, page: null })
        },
        onNuevoModulo: ( formulario, page ) => {
            dispatch({ type: "NUEVO_MODULO_REQUEST" , formulario: formulario, page: page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoModulo);