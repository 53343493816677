import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoPonderaciones from './ModalNuevoPonderaciones';
import ModalEditarPonderaciones from './ModalEditarPonderaciones';


const { Search } = Input;
class CatalogoDePonderaciones extends React.Component {
    componentDidMount(){
        this.props.onGetPonderacionesBusqueda(1, '');
        this.props.onGetAreasFuncionalesActivas();
        this.props.onGetCategoriaFallas();
        this.props.onRequestInfoProyecto();
    }
    state={
        page: 1,
        busqueda: '',
    }
    render() {
        const { fetchingListaPonderacionesBusqueda ,listaPonderacionesBusquedaCantidad, onGetPonderacionesBusqueda, 
            onModalEditarPonderacion, onModalNuevoPonderacion, listaPonderacionesBusqueda
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        const onBuscarActivoCaract = busqueda => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            onGetPonderacionesBusqueda(1, busqueda);
        }
        
        const columns = [{
                title: 'Área funcional',
                dataIndex: 'areaFuncional',
                key: 'areaFuncional',
                width: '13%',
            },{
                title: 'Código área',
                dataIndex: 'codigoArea',
                key: 'codigoArea',
                width: '10%',
            },{
                title: 'Clave área',
                dataIndex: 'claveArea',
                key: 'claveArea',
                width: '7%',
            },{
                title: 'Peso de área',
                dataIndex: 'pesoAreaTabla',
                key: 'pesoArea',
                align: 'right',
                width: '8%',
            },{
                title: 'Tiempo rehabilitación',
                dataIndex: 'fallaCategoria',
                key: 'fallaCategoria',
                width: '8%',
            },{
                title: 'Turnos',
                dataIndex: 'turnos',
                key: 'turnos',
                align: 'right',
                width: '5%',
            },{
                title: 'Factor 1',
                dataIndex: 'factor1',
                key: 'factor1',
                align: 'right',
                width: '5%',
            },{
                title: 'Considerar factor 1',
                dataIndex: 'factor1Activo',
                key: 'factor1Activo',
                width: '6%',
                render: (text) => (
                    <Switch checked={text} disabled={true}/>
                ),
            },{
                title: 'Factor 2',
                dataIndex: 'factor2',
                key: 'factor2',
                align: 'right',
                width: '5%',
            },{
                title: 'Considerar factor 2',
                dataIndex: 'factor2Activo',
                key: 'factor2Activo',
                width: '6%',
                render: (text) => (
                    <Switch checked={text} disabled={true}/>
                ),
            },{
                title: 'Factor 3',
                dataIndex: 'factor3',
                key: 'factor3',
                align: 'right',
                width: '5%',
            },{
                title: 'Considerar factor 3',
                dataIndex: 'factor3Activo',
                key: 'factor3Activo',
                width: '6%',
                render: (text) => (
                    <Switch checked={text} disabled={true}/>
                ),
            },{
                title: 'Cantidad',
                dataIndex: 'cantidad',
                key: 'cantidad',
                align: 'right',
                width: '5%',
            },{
                title: 'Editar',
                key: 'editar',
                width: '5%',
                render: (text, record) => (
                    <a className='editarIcono' disabled={record.areaFuncionalByIdAreaFuncional.visible ? (record.areaFuncionalByIdAreaFuncional.convenio ? !permisosUsuario.editarPonderaciónDeAreaFuncional : !permisosUsuario.editarPorConvenio) : true} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },
        ];
        const onEditar = (key) => {
            console.log(key);
            onModalEditarPonderacion(key, this.state.page, this.state.busqueda);
        }
        
        const handleTableChange = (pagination)=>{   
            console.log('pagination.current')
            console.log(pagination.current)
            this.setState({
                page: pagination.current,
            })
            onGetPonderacionesBusqueda(pagination.current, this.state.busqueda);
		}
        const onNuevoPonderacion = (bloqueado) => {
            onModalNuevoPonderacion(this.state.page, this.state.busqueda, bloqueado);
        }
        const scroll = {"x": 1800 | true, "y": 1000};

        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search className='buscarModulos' placeholder="Ingrese código de área, área funcional o clave" onSearch={value => onBuscarActivoCaract(value)} style={{ width: '100%' }}/>
                    </Col>
                    {/* <Col span={7}>
                        <Button onClick={() => onNuevoPonderacion(listaPonderacionesBusqueda[0] ? listaPonderacionesBusqueda[0].areaFuncionalByIdAreaFuncional.convenio : true)} disabled={listaPonderacionesBusqueda[0] ? (listaPonderacionesBusqueda[0].areaFuncionalByIdAreaFuncional.visible ? (listaPonderacionesBusqueda[0].areaFuncionalByIdAreaFuncional.convenio ? !permisosUsuario.crearPonderaciónDeAreaFuncional : !permisosUsuario.crearPorConvenio) : true): true } className='nuevoUsuario'>Nueva Ponderación</Button>
                        <br/>
                        <br/>
                    </Col> */}

                    <Col span={1}>
                        <Tooltip title='Nueva Ponderación' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={() => onNuevoPonderacion(listaPonderacionesBusqueda[0] ? listaPonderacionesBusqueda[0].areaFuncionalByIdAreaFuncional.convenio : true)} disabled={listaPonderacionesBusqueda[0] ? (listaPonderacionesBusqueda[0].areaFuncionalByIdAreaFuncional.visible ? (listaPonderacionesBusqueda[0].areaFuncionalByIdAreaFuncional.convenio ? !permisosUsuario.crearPonderaciónDeAreaFuncional : !permisosUsuario.crearPorConvenio) : true): true }  icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="areaFuncional" 
                            columns={columns}
                            dataSource={listaPonderacionesBusqueda}
                            loading={fetchingListaPonderacionesBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaPonderacionesBusquedaCantidad, simple: true, current: this.state.page}}
                            scroll={scroll}
                        />         
                    </Col>
                </Row>
                <ModalNuevoPonderaciones/>
                <ModalEditarPonderaciones/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaPonderacionesBusquedaCantidad: state.CatalogosGeneralesReducer.listaPonderacionesBusquedaCantidad,
        fetchingListaPonderacionesBusqueda: state.CatalogosGeneralesReducer.fetchingListaPonderacionesBusqueda,
        listaPonderacionesBusqueda: state.CatalogosGeneralesReducer.listaPonderacionesBusqueda,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetPonderacionesBusqueda: (page, busqueda) => {
	    	dispatch({ type: "LISTA_PONDERACIONES_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
        },
        onModalNuevoPonderacion: (page, busqueda, bloqueado) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_PONDERACION", showModalNuevoPonderacion: true, page, busqueda, bloqueado });
        },
        onModalEditarPonderacion: (ponderacionSeleccionado, page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_PONDERACION", showModalEditarPonderacion: true, ponderacionSeleccionado, page, busqueda });
        },
        onGetAreasFuncionalesActivas: () => {
            dispatch({ type: "LISTA_AREASFUNCIONALES_ACTIVOS_REQUEST" });
        },
        onGetCategoriaFallas: () => {
            dispatch({ type: "LISTA_CATEGORIAS_FALLAS_ACTIVOS_REQUEST" });
        },
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoDePonderaciones);