import React from 'react';
import {connect} from 'react-redux';
import {Card, message, Form, Row, Col, Upload, Button, List, Modal, Spin, Select, Pagination, Typography } from 'antd';
import { UploadOutlined, DeleteOutlined, VerticalAlignBottomOutlined, VerticalAlignTopOutlined, FileExcelFilled } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
//
const { confirm } = Modal;
const { Option } = Select;
const { Dragger } = Upload;
const { Text, Paragraph } = Typography;
const layout = {  labelCol: { span: 24 },  wrapperCol: { span: 24 }, };
class CargaServiciosPlanificados extends React.Component {
    formRef = React.createRef();

    state = {
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
        page: 1,
        estado: 'Revision',
        rutaFormato: "",
        fileList: [],
    }

    componentDidMount() {
        this.props.onGetArchivosEspera(1);
    }

    render () {

        const {fetchingListaArchivosEsperaSP,listaArchivosEsperaSP,listaArchivosEsperaSPCantidad,fetchingSubirArchivoSP, limpiarFormulario,
            fetchingListaServiciosFiltro,listaServiciosFiltro,
            onGetArchivosEspera,onSubirArchivoSP,onEliminarArchivo,onExportarErroresArchivoSP,onProcesarArchivoSP,onLimpiarFormulario, onListaServiciosFiltro,onListaServiciosFiltroLimpiar} = this.props;
        
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        if (limpiarFormulario && limpiarFormulario === 'cargarArchivoSP') {
            this.formRef.current.resetFields(['csv']);
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
            onLimpiarFormulario();
        }

        const buscarServicios = value => {
            if (value.length >= 3) {
                if (value) {
                    console.log(value);
                    onListaServiciosFiltro(value)
                } else {
                    message.warning('No se encontró servicio con esas palabras')
                }
            } else {
                onListaServiciosFiltroLimpiar();
            }
        }

        const rutaDocumento = {'Catalogo Rutinas': "https://appcontrollersolutions.com/FormatoSPCatalogoRutinas.csv",'Catalogo Mantenimientos':"https://appcontrollersolutions.com/FormatoSPCatalogoMantenimiento.csv",
            'Programa Rutinas':"https://appcontrollersolutions.com/FormatoSPProgramaRutina.csv",'Programa Mantenimientos':"https://appcontrollersolutions.com/FormatoSPProgramaMantenimiento.csv"}

        const handleDocumento = value => {
            const ruta = rutaDocumento[value];
            this.setState({...this.state,rutaFormato: ruta });
        }
        
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };
        const onChangePDF = (info) => {
            let fileList = [...info.fileList];
            // 1. Limit the number of uploaded files. Only to show two recent uploaded files, and old ones will be replaced by the new
            fileList = fileList.slice(-1);

            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else {
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                    fileList
                })
            }
            if (info.file.status === 'done') {
                var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,40}$");
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['csv']);
                    this.setState({fileList: []});
                }
                else {
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true,
                        fileList
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['csv']);
                    this.setState({fileList: []});
                }
                else {
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true,
                        fileList
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }

        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
                fileList: [],
            });
        }

        const eliminarArchivo = (listaArchivoSeleccionado) => {
            const page = this.state.page;
            confirm({
                title: '¿Estás seguro que deseas cancelar el archivo '+ listaArchivoSeleccionado.archivos +'?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEliminarArchivo(listaArchivoSeleccionado.idArchivosSPMasivos, page);
                },
                onCancel() {
                console.log('Cancel');
                },
            });	  
        };

        const onEstadoArchivos = (estado) => {
            console.log(estado);
            this.setState({
                estado: estado,
                page: 1,
            });
            onGetArchivosEspera(1, estado);
        }

        const procesarArchivo = (archivo) => {
            console.log(archivo);
            const page = this.state.page;
            confirm({
                title: '¿Estás seguro que deseas procesar el archivo '+ archivo.archivos +'?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onProcesarArchivoSP(archivo.idArchivosSPMasivos, page);
                },
                onCancel() {
                console.log('Cancel');
                },
            });	  
            
        }

        const descargarErroresArchivo = (archivo) => {
            console.log(archivo);
            onExportarErroresArchivoSP(archivo.idArchivosSPMasivos)
        }

        const cambiarPagina = (page) => {
            console.log(page);
            this.setState({
                page: page,
            })
            onGetArchivosEspera(page, this.state.estado);
        }

        const onFinish = formulario => {
            console.log(formulario)
            onSubirArchivoSP(formulario, this.state.page);
        };

        const onFinishFailed = () => {
            console.log('Cancel');
        }

        return(
            <Card className="cardCatalogos" title={<div className="headerLista">Carga de Servicios Planificados</div>}>
                <Row justify='space-between' style={{ textAlign: 'left' }}>
                    <Col span={11}>
                        <Spin spinning={fetchingSubirArchivoSP}>
                            <Form {...layout}
                                name="formulario"
                                onFinish={onFinish}
                                
                                ref={this.formRef}>
                                <Row justify='space-between'>
                                    <Col span={24}>
                                        <Paragraph>
                                             Favor de agregar un archivo de extensión CSV. El nombre del archivo no debe contener caracteres especiales (*, /, acentos,.,comas,etc.).
                                        </Paragraph>
                                            
                                        <Paragraph>
                                             <pre style={{backgroundColor:'#fafafa', borderRadius:'1em', padding:'.5em'}}>Ejemplo: tabladatos.csv</pre>
                                        </Paragraph>

                                        <Paragraph>
                                             <Text strong>
                                                  Nota: Revisa que el formato no haya tenido pérdidas en data antes de subirlo a revisión.
                                             </Text>
                                        </Paragraph>
                                        <Form.Item
                                            name='tipoDocumento'
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <Select
                                                allowClear
                                                style={{ width: '100%' }}
                                                placeholder="Selecciona tipo de documento"
                                                onChange={handleDocumento}
                                            >  
                                                <Option value='Catalogo Rutinas'>Catálogo Rutinas</Option>
                                                <Option value='Catalogo Mantenimientos'>Catálogo Mantenimientos</Option>
                                                <Option value='Programa Rutinas'>Programa Rutinas</Option>
                                                <Option value='Programa Mantenimientos'>Programa Mantenimientos</Option>
                                            </Select>
                                        </Form.Item>
                                        
                                        <Form.Item
                                            name='servicio'
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <Select
                                                allowClear
                                                style={{ width: '100%' }}
                                                placeholder="Selecciona un servicio"
                                                loading={fetchingListaServiciosFiltro}
                                                showSearch
                                                filterOption={false}
                                                onSearch={buscarServicios}
                                                notFoundContent={null}
                                            >
                                                {listaServiciosFiltro.map(option => <Option  key={option.idServicio}>{option.nombre}</Option>)}
                                            </Select>

                                        </Form.Item>
                                        <Form.Item
                                            label="Archivo CSV"
                                            name="csv"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            {/* <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.csv' multiple={false}>
                                                {this.state.filePDF === false ? (
                                                    <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                                        <UploadOutlined /> Click para subir archivo CSV
                                                    </Button>
                                                ) : false}
                                            </Upload> */}
                                            <Dragger customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.csv' multiple={false} style={{borderRadius:'1em'}} fileList={this.state.fileList}>
                                                  <Row justify='center'>
                                                       <FileExcelFilled className='iconTabPane'/>
                                                       <Col span={24} style={{paddingTop:'1em'}}>
                                                            <Text strong> Click o arrastra a ésta área el achivo </Text>
                                                       </Col>
                                                  </Row>
                                             </Dragger>
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Button disabled={!permisosUsuario.descargarFormatoDeServicioPlanificado ? true : !this.state.rutaFormato} className="btnFiltrar" style={{ width: '100%' }}><a href={this.state.rutaFormato}>Descargar Formato</a></Button>
                                    </Col>
                                    <Col span={11}>
                                        <Button disabled={!permisosUsuario.cargarArchivoCsvDeServicioPlanificado ? true : !this.state.filePDF} className="btnFormato" style={{ width: '100%' }} htmlType="submit">Cargar Archivo CSV</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Spin>
                    </Col>
                    <Col span={11}>
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Selecciona el estado a buscar"
                            onChange={onEstadoArchivos}
                            defaultValue="Revision"
                        >
                            <Option key='Revision'>Revisión</Option>
                            <Option key='Cancelado'>Cancelado</Option>
                            <Option key='Finalizado'>Finalizado</Option>
                        </Select>
                        {this.state.estado === 'Revision' ? (
                            <List
                                className="demo-loadmore-list"
                                locale={{ emptyText: 'Sin datos' }}
                                itemLayout="horizontal"
                                loading={fetchingListaArchivosEsperaSP}
                                dataSource={listaArchivosEsperaSP}
                                renderItem={item => (
                                   <List.Item className='listaHover' className='listaHover' actions={[
                                        <div className="iconoLista borrarIcono" onClick={(e) => { e.stopPropagation(); eliminarArchivo(item); }}><DeleteOutlined /></div>,
                                        <div className="iconoLista cargaIcono" onClick={(e) => { e.stopPropagation(); procesarArchivo(item); }}><VerticalAlignTopOutlined /></div>
                                    ]}>
                                       <List.Item.Meta
                                            title={'Nombre de Archivo:  ' + item.archivos}
                                            description={'Fecha de alta: ' + item.fechaAlta}
                                        />
                                    </List.Item>
                                )}
                            />
                        ) : (
                                <List
                                    className="demo-loadmore-list"
                                    locale={{ emptyText: 'Sin datos' }}
                                    itemLayout="horizontal"
                                    loading={fetchingListaArchivosEsperaSP}
                                    dataSource={listaArchivosEsperaSP}
                                    renderItem={item => (
                                       <List.Item className='listaHover' className='listaHover' actions={[
                                            <div>
                                                {this.state.estado === 'Finalizado' ? (
                                                    <div className="iconoLista cargaIcono" onClick={(e) => { e.stopPropagation(); descargarErroresArchivo(item); }}><VerticalAlignBottomOutlined /></div>
                                                ) : false}
                                            </div>
                                        ]}>
                                           <List.Item.Meta
                                                title={'Nombre de Archivo:  ' + item.archivos}
                                                description={'Fecha de alta: ' + item.fechaAlta}
                                            />
                                        </List.Item>
                                    )}
                                />
                            )}
                        <Pagination total={listaArchivosEsperaSPCantidad} simple={true} current={this.state.page} onChange={cambiarPagina} />
                    </Col>
                </Row>
            </Card>
        )
    }
    
}

const mapStateToProps = state => {
    return {
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
        fetchingListaArchivosEsperaSP: state.ServiciosPlanificadosReducer.fetchingListaArchivosEsperaSP,
        listaArchivosEsperaSP: state.ServiciosPlanificadosReducer.listaArchivosEsperaSP,
        listaArchivosEsperaSPCantidad: state.ServiciosPlanificadosReducer.listaArchivosEsperaSPCantidad,
        fetchingSubirArchivoSP: state.ServiciosPlanificadosReducer.fetchingSubirArchivoSP,
        fetchingBorrarArchivoSP: state.ServiciosPlanificadosReducer.fetchingBorrarArchivoSP,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda});
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []});
        },
        onGetArchivosEspera: (page, estado) => {
            dispatch({ type: "GET_ARCHIVOS_ESPERA_SP_REQUEST", page, estado });
        },
        onSubirArchivoSP: (formulario, page) => {
            dispatch({ type: 'SUBIR_ARCHIVO_SP_REQUEST', formulario, page });
        },
        onEliminarArchivo: (idArchivosSPMasivos, page) => {
			dispatch({ type: "ELIMINAR_ARCHIVO_SP_REQUEST", idArchivosSPMasivos, page });
		},
        onProcesarArchivoSP: (idArchivosSPMasivos, page) => {
            dispatch({ type: 'PROCESAR_ARCHIVO_SP_REQUEST', idArchivosSPMasivos, page})
        },
        onExportarErroresArchivoSP: (idArchivosSPMasivos) => {
            dispatch({ type: 'EXPORTAR_ERRORES_ARCHIVO_SP_REQUEST', idArchivosSPMasivos})
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        },
    }
}

export default connect (mapStateToProps, mapDispatchToProps) (CargaServiciosPlanificados);