import React, { useState } from 'react';
import {connect} from 'react-redux';
import { Alert, Button, Row, Col, Steps, Form, Card, Select, Input, DatePicker, TimePicker, Tooltip} from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import FilterSection from './FilterSection';
import TableSection from './TableSection';

import ReporteExistencias from './ReporteExistencias';

const { Step } = Steps;

const ReporteDeExistencias = props => {

     const {listaReporteDinamicoSP, formularioReporte,listaServiciosActivosF,onGetReporteDinamico,onLimpiarFormulario, onGetGrupoServicioByIdUsuario, onGetReporteDinamicoAgrupado } = props;

     const [state, setState] = useState({page: 1, currentStep:0});
     const [order, setOrder] = useState({field:null, order:'ASC'});
     const [checkArray, setCheckArray] = useState([]);
    
     let permisosUsuario = sessionStorage.getItem('permisosUsuario');
     if(permisosUsuario[0]) {
          permisosUsuario = JSON.parse(permisosUsuario);
     }

     const handleSteps = value => {
          console.log('Entra al handle')

          if (value === 'siguiente') return  setState({...state, currentStep: state.currentStep + 1});

          setOrder({field: null, order:'ASC'});
          setState({page:1, currentStep: state.currentStep -1});
          onLimpiarFormulario(formularioReporte);
     }

     const handleTableChange = (pagination, filter, orden, agrupado) => {
          setState({...state, page: pagination.current});
          console.log(pagination, filter, orden, agrupado)
          if(agrupado === true){
               console.log('Entró al if de agrupado')
               // return onGetReporteDinamicoAgrupado(formularioReporte, pagination.current, undefined, order)
          }
          if(orden) {
               let sorter = {field: null, order: 'ASC'}
               if(orden.order){
                    const orderField = orden.order === 'descend' ? 'DESC': 'ASC';
                    sorter = {field: orden.field, order: orderField, ordenReiniciar: orden.order}
               }
               setOrder(sorter);
               console.log('sorter', sorter, formularioReporte)
               // return onGetReporteDinamico(formularioReporte, pagination.current, sorter );
          }

          onGetReporteDinamico(formularioReporte, pagination.current, order );
     }

     const handleOrden = (field, order, ordenReiniciar) => {
          let agrupado = undefined;
          console.log(`field, orden`, field, order)
          const sorter = {field, order}
          setOrder({field, order, ordenReiniciar});
          // onGetReporteDinamicoAgrupado(formularioReporte, state.page, agrupado, sorter)
      }

     const SelectSection = () => {
          return (
               <Card className="cardCatalogos" title={<div className='headerLista'>Reporte Dinámico</div>}>
                    <Steps
                         type='navigation'
                         size='small'
                         current={state.currentStep}
                         style={{marginBottom:'20px', paddingTop:'0px'}}
                    >
                         <Step status={state.currentStep>0? 'finish':'process'} title='Seleccionar Columnas' />
                         <Step status={state.currentStep>1? 'finish':'process'} title='Filtrar' />
                         <Step status={listaReporteDinamicoSP.length>0? 'finish':'process'} title='Exportar Reporte' />
                    </Steps>
                    {state.currentStep === 0 ? <FilterSection handleSteps={handleSteps} order={order} /> : false}
                    {state.currentStep === 1 ? <TableSection handlers={handleProps} data={dataProps}/> : false}
                    {/* {state.currentStep === 0 ? <ReporteExistencias/> : false} */}

               </Card>
          )
     }
    
     const dataProps = { checkArray, state, order }
     const handleProps = {handleTableChange , handleSteps, handleOrden}

     return (
          <Card className="cardCatalogos" title={<div className='headerLista'>Reporte de Existencias</div>}>
               <Steps
                    type='navigation'
                    size='small'
                    current={state.currentStep}
                    style={{marginBottom:'20px', paddingTop:'0px'}}
               >
                    <Step status={state.currentStep>0? 'finish':'process'} title='Filtrar' />
                    <Step status={listaReporteDinamicoSP.length>0? 'finish':'process'} title='Exportar Reporte' />
               </Steps>
               {state.currentStep === 0 ? <FilterSection handleSteps={handleSteps} order={order} /> : false}
               {state.currentStep === 1 ? <TableSection handlers={handleProps} data={dataProps}/> : false}
               {/* {state.currentStep === 0 ? <ReporteExistencias/> : false} */}

          </Card>
     );
}

const mapStateToProps = state => {
     return {
          listaReporteDinamicoSP: state.ServiciosPlanificadosReducer.listaReporteDinamicoSP,
          formularioReporte: state.ServiciosPlanificadosReducer.formularioReporte,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
     }
}

const mapDispatchToProps = dispatch => {
     return {
          onGetReporteDinamico: (formulario, page, order) => {
               dispatch({ type: 'LISTA_REPORTE_EXISTENCIAS_REQUEST', formulario, page, order});
          },
          onLimpiarFormulario: (formularioReporte) => {
               dispatch({ type: 'LIMPIAR_FORMULARIO_RD', formularioReporte, listaReporteDinamicoSP: [], listaReporteDinamicoSPCantidad: null})
          },
          onGetReporteDinamicoAgrupado: (formulario, page, agrupado, order) => {
               dispatch({ type: 'LISTA_REPORTE_EXISTENCIAS_AGRUPADO_REQUEST', formulario, page, agrupado, order});
          },
     }
}

export default connect (mapStateToProps, mapDispatchToProps) (ReporteDeExistencias);