import React, { Component } from 'react';
import { Form, Input, Button, Modal, Row, Col, Spin, Radio } from 'antd';
import { connect } from 'react-redux';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalEditarMotivoSalida extends Component {
    formRef = React.createRef();
    state = {
        tipoSalida: 'Definitiva'
    }
    render() { 
        const {
            showModalEditarMotivoSalida,
            fetchingEditarMotivoSalida,
            onEditarMotivo, 
            onShowModalEditarMotivoSalida,
            motivoSeleccionado,
            page,
            limpiarFormulario
        } = this.props;
        console.log('motivoSeleccionada en modal: ', motivoSeleccionado);

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onEditarMotivo(formulario, motivoSeleccionado, page);
        };

        if (limpiarFormulario && limpiarFormulario==='editarMotivo') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        

        const handleCancel = () => {
            onShowModalEditarMotivoSalida();
            
        }

        const cambiarMotivo = tipoSalida => {
            console.log(tipoSalida.target.value)
            this.setState({
                tipoSalida: tipoSalida.target.value
            });
        }

        return ( 
            <Modal
            visible={showModalEditarMotivoSalida}
            title='Editar Motivo de Salida'
            onCancel={handleCancel}
            footer={false}
            >
                <Spin spinning={fetchingEditarMotivoSalida}>
                    {motivoSeleccionado ? (
                        <Form {...layout}
                        name="formulario"
                        initialValues={motivoSeleccionado}
                        onFinish={onFinish}
                        ref={this.formRef}
                    >
                        <Col span={24}>
                            <Form.Item
                                label="Descripcion motivo de salida"
                                name="descripcion"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Input maxLength={80} placeholder="Ingrese descripcion" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Tipo de salida"
                                name="tipoSalida"
                                rules={[{ required: true, message: 'Favor de elegir tipo de salida', }]}
                            >
                                <Radio.Group onChange={cambiarMotivo} >
                                    <Radio value='Definitiva'>Definitiva</Radio>
                                    <Radio value='Temporal'>Temporal</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                        <Row justify='space-around'>
                            <Col span={12}>
                                <Button className="btnFiltrar" htmlType="submit">Editar Motivo de Salida</Button>
                            </Col>
                        </Row>
                    </Form>  
                    )  : false}
                </Spin>
            </Modal>
        );
    }
}
 
const mapStateToProps = state => {
	return {
        showModalEditarMotivoSalida: state.AlmacenesReducer.showModalEditarMotivoSalida,
        motivoSeleccionado: state.AlmacenesReducer.motivoSeleccionado,
        fetchingEditarMotivoSalida: state.AlmacenesReducer.fetchingEditarMotivoSalida,
        page: state.AlmacenesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarMotivoSalida: () => {
            dispatch({ type: "SHOW_MODAL_EDITAR_MOTIVOS_DE_SALIDA", showModalEditarMotivoSalida: false, motivoSeleccionado: null})
        },
        onEditarMotivo: (formulario, motivoSeleccionado, page) => {
            dispatch({ type: "EDITAR_MOTIVOS_DE_SALIDA_REQUEST", formulario: formulario, motivoSeleccionado: motivoSeleccionado, page: page})
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarMotivoSalida)