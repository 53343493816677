import React, { Component } from 'react';
import {Row, Col, Modal, List} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';

class ModalDetalleAlerta extends Component {
    formRef = React.createRef();

    render() {
        const { onShowModalDetalleRutina, showModalDetalleRutina, rutinaSeleccionada} = this.props;

        let data=[]
        if (rutinaSeleccionada){            
            data = [
                {title:"Actividades", data: rutinaSeleccionada.actividades},
                {title:"Afectación",data: rutinaSeleccionada.afectacion},
                {title:"Calve de rutina",data: rutinaSeleccionada.claveRutina},
                {title:"Días",data: rutinaSeleccionada.dias},
                {title:"EPP",data: rutinaSeleccionada.epp},
                {title:"Fecha específica",data: rutinaSeleccionada.fechaEspecificaDetalle},
                {title:"Frecuencia",data: rutinaSeleccionada.frecuencia},
                {title:"Herramientas",data: rutinaSeleccionada.herramientas},
                {title:"Insumos",data: rutinaSeleccionada.insumos},
                {title:"Personal",data: rutinaSeleccionada.personal},
                {title:"Subfamilia",data: rutinaSeleccionada.subfamilia},
                {title:"Familia",data: rutinaSeleccionada.familia},
                {title:"Programa",data: rutinaSeleccionada.programa},
            ];
        }
        const handleCancel = () => {
            onShowModalDetalleRutina();
        }
        
        return (
            <Modal
                visible={showModalDetalleRutina}
                title='Detalle de la RMP'
                onCancel={handleCancel}
                footer={false}
                width={'50%'}
            >
                {rutinaSeleccionada ? (
                    <Row justify='space-around'>
                        <Col span={24}>
                            <List
                            className="listaCatalogos"
                            bordered
                            dataSource={data}
                            renderItem={item => (
                               <List.Item className='listaHover'>
                                        <Col span={11}>
                                            <strong>{item.title}</strong>  
                                        </Col>
                                        <Col span={11}>
                                            {item.data ? item.data : '-'} 
                                        </Col>
                                </List.Item>
                            )}
                            />
                        </Col>
                    </Row>
                ) : false}
                
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalDetalleRutina: state.ServiciosPlanificadosReducer.showModalDetalleRutina,
        rutinaSeleccionada: state.ServiciosPlanificadosReducer.rutinaSeleccionada,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalDetalleRutina: () => {
            dispatch({ type: 'MODAL_DETALLE_RUTINA', showModalDetalleRutina: false });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetalleAlerta);