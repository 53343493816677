import React from 'react';
import {Card, Table, Row, Col, Select, Button, Input, Form} from 'antd';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalEvaluacionDeDesempeno from "./ModalEvaluacionDeDesempeno";
import * as GestionDeCalidadActions from "../../../store/actions/GestionDeCalidadActions";
import ModalConsultaDeEvaluaciones from "./ModalConsultaDeEvaluaciones";

const { Option } = Select;
const { Search } = Input;
class EvaluacionDeIndicadoresDeDesempenoContainer extends React.Component {
    componentDidMount(){
        this.props.onListaMesesActivos();
        this.props.onListaServiciosActivos();
        this.props.onListaGerencias();
        this.props.onListaObjetivosActivos();
        this.props.onListaEvaluacionesDeDesempeno(
            this.state.idClasificacionGerencia,
            this.state.idMes,
            this.state.idObjetivo,
            this.state.page,
            this.state.idProveedor,
            this.state.order,
            this.state.field,
            this.state.idServicio);
    }
    state = {
        listaAños: [],
        page: 1,
        busqueda: {},
        orden: {},
        idServicio: '',
        idClasificacionGerencia: '',
        idMes: '',
        idObjetivo: '',
        idProveedor: '',
        order: 'ASC',
        field: 'idEvaluacionIndicador'
    }

    render() {
        const { fetchingListaMesesEvaluacion, listaMesesEvaluacion, fetchingListaGerenciasByServicio,
            listaGerenciasByServicio, listaEvaluacionesDesempeno, fetchingListaEvaluacionesDesempeno, listaEvaluacionesDesempenoCantidad,
            fetchingListaServiciosActivos, listaServiciosActivos, fetchingListaObjetivosActivos, listaObjetivosActivos
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columns = [{
            title: 'Gerencia',
            dataIndex: 'nombreGerencia',
            key: 'nombreGerencia',
            width: '10%',
            sorter: true
        }, {
            title: 'Objetivo',
            dataIndex: 'objetivo',
            key: 'objetivo',
            width: '10%',
            sorter: true
        }, {
            title: 'Indicador de desempeño',
            dataIndex: 'indicadorDesempeno',
            key: 'indicadorDesempeno',
            width: '10%'
        }, {
            title: 'Servicio',
            dataIndex: 'servicio',
            key: 'servicio',
            width: '10%',
            sorter: true
        }, {
            title: 'Partida',
            dataIndex: 'partida',
            key: 'partida',
            width: '10%',
        }, {
            title: 'Proveedor',
            dataIndex: 'proveedor',
            key: 'proveedor',
            width: '10%'
        }, {
            title: 'Tema',
            dataIndex: 'tema',
            key: 'tema',
            width: '10%',
            sorter: true
        }, {
            title: 'Centro de costo',
            dataIndex: 'centroDeCosto',
            key: 'centroDeCosto',
            width: '10%',
            sorter: true
        }, {
            title: 'Valor esperado',
            dataIndex: 'valorEsperado',
            key: 'valorEsperado',
            align: 'right',
            width: '5%'
        }, {
            title: 'Valor real',
            dataIndex: 'valorReal',
            key: 'valorReal',
            align: 'right',
            width: '5%'
        }, {
            title: 'Fecha evaluación',
            dataIndex: 'fechaEvaluacion',
            key: 'fechaEvaluacion',
            align: 'right',
            width: '10%',
            sorter: true
        },
        ];

        const onNuevaEvaluacionDesempeno = () => {
            this.props.onListaMesesActivos();
            this.props.onShowModalEvaluacionDesempeno();
        }
        const onConsultaEvaluaciones = () => {
            this.props.onShowModalConsultaEvaluaciones();
        }
        const onChangeServicio = (value) => {
            if (value) {
                this.setState({
                    idServicio: value
                });
            } else {
                this.setState({
                    idServicio: ''
                });
                value = '';
            }
            this.props.onListaEvaluacionesDeDesempeno(
                this.state.idClasificacionGerencia,
                this.state.idMes,
                this.state.idObjetivo,
                this.state.page,
                this.state.idProveedor,
                this.state.order,
                this.state.field,
                value);
        };
        const onChangeGerencia = (value) => {
            if (value) {
                this.setState({
                    idClasificacionGerencia: value
                });
            } else {
                this.setState({
                    idClasificacionGerencia: ''
                });
                value = '';
            }
            this.props.onListaEvaluacionesDeDesempeno(
                value,
                this.state.idMes,
                this.state.idObjetivo,
                this.state.page,
                this.state.idProveedor,
                this.state.order,
                this.state.field,
                this.state.idServicio);
        };
        const onChangeObjetivo = (value) => {
            if (value) {
                this.setState({
                    idObjetivo: value
                });
            } else {
                this.setState({
                    idObjetivo: ''
                });
                value = '';
            }
            this.props.onListaEvaluacionesDeDesempeno(
                this.state.idClasificacionGerencia,
                this.state.idMes,
                value,
                this.state.page,
                this.state.idProveedor,
                this.state.order,
                this.state.field,
                this.state.idServicio);
        };
        const onChangeMes = (value) => {
            if (value) {
                this.setState({
                    idMes: value
                });
            } else {
                this.setState({
                    idMes: ''
                });
                value = '';
            }
            this.props.onListaEvaluacionesDeDesempeno(
                this.state.idClasificacionGerencia,
                value,
                this.state.idObjetivo,
                this.state.page,
                this.state.idProveedor,
                this.state.order,
                this.state.field,
                this.state.idServicio);
        };
        const handleTableChange = (pagination, filtro, ordenTabla)=>{
            console.log('ordenTabla', ordenTabla)
            this.setState({
                page: pagination.current,
            })
            if (ordenTabla.columnKey) {
                let order = 'ASC';

                if (ordenTabla.order === 'descend') {
                    order = 'DESC';
                }

                let field = ordenTabla.columnKey;

                if (field === 'fechaEvaluacion') {
                    field = 'fechaAlta';
                } else if (field === 'centroDeCosto') {
                    field = 'te.idCentroCosto';
                } else if (field === 'tema') {
                    field = 'te.idTema';
                } else if (field === 'servicio') {
                    field = 'idServicio';
                } else if (field === 'objetivo') {
                    field = 'idObjetivo';
                } else if (field === 'nombreGerencia') {
                    field = 'idClasificacionGerencia';
                }

                this.setState ({
                    order: order,
                    field: field
                });

                this.props.onListaEvaluacionesDeDesempeno(
                    this.state.idClasificacionGerencia,
                    this.state.idMes,
                    this.state.idObjetivo,
                    pagination.current,
                    this.state.idProveedor,
                    order,
                    field,
                    this.state.idServicio);
            } else {
                this.props.onListaEvaluacionesDeDesempeno(
                    this.state.idClasificacionGerencia,
                    this.state.idMes,
                    this.state.idObjetivo,
                    pagination.current,
                    this.state.idProveedor,
                    this.state.order,
                    this.state.field,
                    this.state.idServicio);
            }
        };

        return (
            <Card className="cardCatalogos" title={<div className='headerLista'>Evaluación de Indicadores de Desempeño</div>}>
                <Row justify='space-between'>
                    <Col span={7}>
                        <Select allowClear
                                style={{ width: '100%' }}
                                placeholder="Selecciona un servicio a filtrar"
                                loading={fetchingListaServiciosActivos}
                                onChange={onChangeServicio}
                                showSearch={true}
                        >
                            {listaServiciosActivos.map(option => <Option key={option.idServicio}>{option.idServicio + ' - ' + option.nombre}</Option>)}
                        </Select>
                    </Col>
                    <Col span={7}>
                        <Button onClick={onConsultaEvaluaciones} className='nuevoUsuario'>Consultar evaluaciones</Button>
                    </Col>
                    <Col span={7}>
                        <Button onClick={onNuevaEvaluacionDesempeno} disabled={!permisosUsuario.crearEvaluaciónDeDesempeño ? true: !this.state.busqueda } className='nuevoUsuario'>Nueva evaluación de desempeño</Button>
                    </Col>
                </Row>
                <Row justify='space-between'>
                    <Col span={7}>
                        <br/>
                        <Select allowClear
                                style={{width: '100%'}}
                                placeholder="Selecciona una gerencia"
                                loading={fetchingListaGerenciasByServicio}
                                onChange={onChangeGerencia}
                                showSearch={true}
                        >
                            {listaGerenciasByServicio.map(option => <Option
                                key={option.idClasificacionGerencia}>{option.nombreGerencia}</Option>)}
                        </Select>
                    </Col>
                    <Col span={7}>
                        <br/>
                        <Select allowClear
                                style={{width: '100%'}}
                                placeholder="Selecciona un objetivo"
                                loading={fetchingListaObjetivosActivos}
                                onChange={onChangeObjetivo}
                                showSearch={true}
                        >
                            {listaObjetivosActivos.map(option => <Option
                                key={option.idObjetivo}>{option.objetivo}</Option>)}
                        </Select>
                    </Col>
                    <Col span={7}>
                        <br/>
                        <Select allowClear
                                style={{width: '100%'}}
                                placeholder="Selecciona un mes"
                                loading={fetchingListaMesesEvaluacion}
                                onChange={onChangeMes}
                                showSearch={true}
                        >
                            {listaMesesEvaluacion.map(option => <Option
                                key={option.idMes}>{option.mes}</Option>)}
                        </Select>
                    </Col>
                </Row>
                <Row justify='space-between'>
                    <Col span={24}>
                        <br/>
                        <Table
                            size="small"
                            rowKey="idEvaluacionIndicador"
                            columns={columns}
                            dataSource={listaEvaluacionesDesempeno}
                            loading={fetchingListaEvaluacionesDesempeno}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaEvaluacionesDesempenoCantidad, simple: true, current: this.state.page}}
                        />
                    </Col>
                </Row>
                <ModalEvaluacionDeDesempeno/>
                <ModalConsultaDeEvaluaciones/>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaMesesEvaluacion: state.EvaluacionDeLaGestionReducer.fetchingListaMesesEvaluacion,
        listaMesesEvaluacion: state.EvaluacionDeLaGestionReducer.listaMesesEvaluacion,
        fetchingListaGerenciasByServicio: state.EvaluacionDeLaGestionReducer.fetchingListaGerenciasByServicio,
        listaGerenciasByServicio: state.EvaluacionDeLaGestionReducer.listaGerenciasByServicio,
        listaEvaluacionesDesempeno: state.EvaluacionDeLaGestionReducer.listaEvaluacionesDesempeno,
        fetchingListaEvaluacionesDesempeno: state.EvaluacionDeLaGestionReducer.fetchingListaEvaluacionesDesempeno,
        listaEvaluacionesDesempenoCantidad: state.EvaluacionDeLaGestionReducer.listaEvaluacionesDesempenoCantidad,
        fetchingListaServiciosActivos: state.ServiciosVariablesReducer.fetchingListaServiciosActivos,
        listaServiciosActivos: state.ServiciosVariablesReducer.listaServiciosActivos,
        fetchingListaObjetivosActivos: state.EvaluacionDeLaGestionReducer.fetchingListaObjetivosActivos,
        listaObjetivosActivos: state.EvaluacionDeLaGestionReducer.listaObjetivosActivos
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onListaServiciosActivos: () => {
            dispatch ({ type: 'LISTA_SERVICIOS_ACTIVOS_REQUEST' });
        },
        onShowModalVerPDFPlanCorrectivo: (planCorrectivoSeleccionado) => {
            dispatch ({ type: 'MODAL_VER_PLAN_CORRECTIVO', showModalVerPlanCorrectivo: true, planCorrectivoSeleccionado })
        },
        onShowModalEditarPlanCorrectivo: (planCorrectivoSeleccionado, page, busqueda, orden) => {
            dispatch ({ type: 'MODAL_EDITAR_PLAN_CORRECTIVO', showModalEditarPlanCorrectivo: true, planCorrectivoSeleccionado, page, busqueda, orden })
        },
        onListaMesesActivos: () => {
            dispatch({type: 'LISTA_MESES_EVALUACION_REQUEST'})
        },
        onListaGerencias: () => {
            dispatch({type: 'LISTA_GERENCIAS_BY_SERVICIO_REQUEST' })
        },
        onListaObjetivosActivos: () => {
            dispatch({type: 'LISTA_OBJETIVOS_ACTIVOS_REQUEST' })
        },
        onShowModalEvaluacionDesempeno: () => {
            dispatch({type: 'MODAL_EVALUACION_DESEMPENO', showModalEvaluacionDesempeno: true, modalEvaluacionDesempenoData: null})
        },
        onListaEvaluacionesDeDesempeno: (idClasificacionGerencia, idMes, idObjetivo, page, idProveedor, order, field, idServicio) => {
            dispatch({type: 'LISTA_EVALUACIONES_DESEMPENO_REQUEST', idClasificacionGerencia, idMes, idObjetivo, page, idProveedor, order, field, idServicio})
        },
        onShowModalConsultaEvaluaciones: () => {
            dispatch({type: 'MODAL_CONSULTA_EVALUACIONES', showModalConsultaDeEvaluaciones: true })
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EvaluacionDeIndicadoresDeDesempenoContainer);
