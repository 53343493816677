import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoCentroDeCostos from './ModalNuevoCentroDeCostos';
import ModalEditarCentroDeCostos from './ModalEditarCentroDeCostos';

const confirm = Modal.confirm;
const { Search } = Input;

class CatalogoCentroDeCostos extends React.Component {
    state = {
        page:1,
        busqueda: '',
    }

    componentDidMount() {
        this.props.onGetCentroDeCostos(1);
        this.props.onGetClasificacionActivos(1);
        this.props.onListaServiciosActivos();
    }

    render() {
        const {fetchingListaCentroDeCostosBusqueda, listaCentroDeCostosBusqueda, onGetCentroDeCostos,
            onModalNuevoCentroDeCostos, onModalEditarCentrodeCostos, onEstadoCentroDeCostos,listaCentroDeCostosBusquedaCantidad} = this.props;  
        
        console.log('ListaCetrosDeCosto');
        console.log(listaCentroDeCostosBusqueda)
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columns = [{
            title: 'Centro de costos',
            dataIndex: 'centroCosto',
            key: 'centroCosto',
        }, {
            title: 'Nombre de gerencia',
            dataIndex: 'nombreGerencia',
            key: 'nombreGerencia',
        }, {
            title: 'Servicios',
            dataIndex: 'servicios',
            key: 'servicios',
        }, {
            title: 'Activo',
            dataIndex: 'activo',
            key: 'activo',
            render: (text, record) => (
                <Switch checked={text} disabled={!permisosUsuario.centroDeCostosActivoInactivo} onChange={() => handleDesactivar(record)}/>
            ),
        }, {
            title: 'Editar',
            key: 'editar',
            //width: '6%',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.editarCentroDeCostos} onClick={() => onEditar(record)}><EditOutlined /></a>
            ),
        },
        ];

        const onBuscarCentroDeCostos = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            });
            console.log(busqueda);
            onGetCentroDeCostos(1, busqueda);
        }

        const handleDesactivar = (key) => {
            console.log(key);
            let page = this.state.page;
            let busqueda = this.state.busqueda;
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del Centro de Costos "'+ key.centroCosto +'"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoCentroDeCostos(key.idCentroCosto, page, busqueda);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }

        const onEditar = (key) => {
            onModalEditarCentrodeCostos(key, this.state.page, this.state.busqueda);

        }

        const onNuevoCentroDeCostos = () => {
            onModalNuevoCentroDeCostos(this.state.page, this.state.busqueda);
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            onGetCentroDeCostos(pagination.current, this.state.busqueda);
        }

        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search className='buscarModulos' placeholder='Ingresar centro de costos' onSearch={value => onBuscarCentroDeCostos(value)}/>
                    </Col>

                    <Col span={1}>
                        <Tooltip title='Nuevo Centro de Costos' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoCentroDeCostos} disabled={!permisosUsuario.crearCentroDeCostos} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>

                    {/* <Col span={8}>
                        <Button onClick={onNuevoCentroDeCostos} disabled={!permisosUsuario.crearCentroDeCostos} className='nuevoUsuario'>Nuevo Centro de Costos</Button>
                    </Col> */}
                    <Col span={24}>
                        <Table
                            size="small"
                            rowKey="centroCosto"
                            columns={columns}
                            dataSource={listaCentroDeCostosBusqueda}
                            loading={fetchingListaCentroDeCostosBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            // scroll={scroll}
                            onChange={handleTableChange}
                            pagination={{ total: listaCentroDeCostosBusquedaCantidad, simple: true, current: this.state.page}}
                        />
                    </Col>
                </Row>
                <ModalNuevoCentroDeCostos/>
                <ModalEditarCentroDeCostos/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        listaCentroDeCostosBusqueda: state.CatalogosGeneralesReducer.listaCentroDeCostosBusqueda,
        fetchingListaCentroDeCostosBusqueda: state.CatalogosGeneralesReducer.fetchingListaCentroDeCostosBusqueda,
        listaCentroDeCostosBusquedaCantidad: state.CatalogosGeneralesReducer.listaCentroDeCostosBusquedaCantidad
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetCentroDeCostos: (page, busqueda) => {
            dispatch({ type: "CENTRO_COSTOS_BUSQUEDA_REQUEST", page: page, busqueda: busqueda});
        },
        onEstadoCentroDeCostos: (idCentroCosto, page, busqueda) => {
            dispatch({ type: "ESTADO_CENTRO_COSTOS_REQUEST", idCentroCosto, page, busqueda});
        },
        onModalNuevoCentroDeCostos: (page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_CENTRO_COSTOS", showModalNuevoCentroDeCostos: true, page, busqueda})
        },
        onModalEditarCentrodeCostos: (CentroDeCostosSeleccionado, page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_CENTRO_COSTOS", showModalEditarCentroDeCostos: true, CentroDeCostosSeleccionado, page, busqueda})
        },
        onGetClasificacionActivos: () => {
            dispatch({ type: 'LISTA_CLASIFICACION_ACTIVOS_REQUEST' });
        },
        onListaServiciosActivos: () => {
            dispatch ({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST' });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps) (CatalogoCentroDeCostos);