import React, {Component} from 'react'
import { Form, Select, Button, Col, Spin, InputNumber, Row, Input, Tooltip } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import moment from 'moment';

const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalNuevaPreguntaEncuesta extends Component {
    formRef = React.createRef();
    
    componentDidMount(){
        this.props.onListaAñosPreguntas();
    }
    state = {
        listaAños: [],
        idServicio: null,
        año: null,
        listaMeses: [],
        añoPasado: false,
    }
    render() { 
        const {
            onNuevoFolioEncuesta, listaInfoProyecto,
            limpiarFormulario, fetchingNuevoFolioEncuesta, onListaMesesPreguntas,
            listaServiciosActivosF, fetchingListaServiciosActivosF,
        } = this.props;

        const onListaMeses = () => {
            let list = [];
            if(listaInfoProyecto){
                if(moment().format('YYYY-MM-DD') <= (parseInt(listaInfoProyecto.fechaInicioOperacion.format('YYYY-MM-DD').split('-')[0]) + listaInfoProyecto.vigencia) + '-' + listaInfoProyecto.fechaInicioOperacion.format('YYYY-MM-DD').split('-')[1] + '-' + listaInfoProyecto.fechaInicioOperacion.format('YYYY-MM-DD').split('-')[2]){
                    console.log('Entra a 2do if')
                    console.log(`Ifs`, moment().format('YYYY-MM-DD').split('-')[0], listaInfoProyecto.fechaInicioOperacion.format('YYYY-MM-DD').split('-')[0], moment().format('YYYY-MM-DD').split('-')[1], listaInfoProyecto.fechaInicioOperacion.format('YYYY-MM-DD').split('-')[1])
                    if(moment().format('YYYY-MM-DD').split('-')[0] > listaInfoProyecto.fechaInicioOperacion.format('YYYY-MM-DD').split('-')[0] ){
                        console.log('antes de if de diciembre', this.state.listaAños[1], this.state.año)
                        if(this.state.listaAños[1] === parseInt(this.state.año)){
                            list.push('Diciembre');
                        }
                        else{
                            const mesActual = moment().format('MMMM').charAt(0).toUpperCase() + moment().format('MMMM').slice(1);
                            console.log(`mesActual`, mesActual)
                            list.push(mesActual);
                            if(moment().format('MMMM') !== 'enero'){
                                const mes = moment().subtract(1, 'months');
                                console.log(`mes`, mes)
                                list.push(mes.format('MMMM').charAt(0).toUpperCase() + mes.format('MMMM').slice(1));
                            } 
                        }
                    }
                    else if(moment().format('YYYY-MM-DD').split('-')[0] === listaInfoProyecto.fechaInicioOperacion.format('YYYY-MM-DD').split('-')[0]){
                        if(moment().format('YYYY-MM-DD').split('-')[1] > listaInfoProyecto.fechaInicioOperacion.format('YYYY-MM-DD').split('-')[1]){
                            const mesActual = moment().format('MMMM').charAt(0).toUpperCase() + moment().format('MMMM').slice(1);
                            console.log(`mesActual`, mesActual)
                            list.push(mesActual);
                            
                            const mes = moment().subtract(1, 'months');
                            console.log(`mes`, mes)
                            list.push(mes.format('MMMM').charAt(0).toUpperCase() + mes.format('MMMM').slice(1));
                            
                        }
                        else if(moment().format('YYYY-MM-DD').split('-')[1] === listaInfoProyecto.fechaInicioOperacion.format('YYYY-MM-DD').split('-')[1]){
                            const mesActual = moment().format('MMMM').charAt(0).toUpperCase() + moment().format('MMMM').slice(1);
                            console.log(`mesActual`, mesActual)
                            list.push(mesActual);
                        }
                    }
                }
            }
            this.setState({
                listaMeses: list
            }); 
        };

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onNuevoFolioEncuesta(formulario);
        };

        if (limpiarFormulario && limpiarFormulario === 'crearFolioEncuesta') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        const onServicioSeleccionado = (value) => {
            this.setState({
                idServicio: parseInt(value),
                año: null,
            });
            this.formRef.current.setFieldsValue({'año': undefined, 'mes': undefined})
        }
        const onListaAños = () => {
            let list = [];
            if(listaInfoProyecto){
                if(moment().format('YYYY-MM-DD') <= (parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2]) + listaInfoProyecto.vigencia) + '-' + listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[1] + '-' + listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[0]){
                    list.push(parseInt(moment().format('YYYY')));
                    if(parseInt(moment().format('YYYY'))>parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2])){
                        console.log('Entró a if de años')
                        if(moment().format('MMMM') === 'enero'){
                            console.log('Entró a if de enero')
                            list.push(parseInt(moment().format('YYYY')) - 1);
                        } 
                    }
                }
            }
            console.log(`list`, list)
            this.setState({
               listaAños: list
            }); 
        };

        const onAñoSeleccionado = (año) => {
            this.setState({
                año,
            })
            onListaMesesPreguntas(año, this.state.idServicio);
            this.formRef.current.setFieldsValue({'mes': undefined})
        }

        const changeDecimal = (value) => {
            const cantidad = parseInt(value)
            this.formRef.current.setFieldsValue({'cantidad': cantidad})
        }

        return (
            <Spin spinning={fetchingNuevoFolioEncuesta}>
                <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    ref={this.formRef}
                >
                    <Row justify='space-around'>
                        <Col span={11}>
                            <Form.Item
                                label="Servicio"
                                name="servicio"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona un servicio"
                                    loading={fetchingListaServiciosActivosF}
                                    showSearch={true}
                                    onChange={onServicioSeleccionado}
                                >
                                    {listaServiciosActivosF.map(option => 
                                    <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                        <Tooltip title={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                            {option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}
                                        </Tooltip>
                                    </Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        {this.state.idServicio ? (
                        <Col span={11}>
                            <Form.Item
                                label="Año"
                                name="año"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select 
                                    style={{ width: '100%' }}
                                    placeholder="Seleccione el año"
                                    loading={this.state.fetchingListaAños}
                                    onClick={onListaAños}
                                    onChange={onAñoSeleccionado}
                                >
                                    {this.state.listaAños.map(option => <Option key={option}>{option}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        ):false}
                        {this.state.año ? (
                        <Col span={11}>
                            <Form.Item
                                label="Mes"
                                name="mes"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select 
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Seleccione el año"
                                    onClick={onListaMeses}
                                >
                                    {this.state.listaMeses.map(option => <Option key={option}>{option}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        ):false}
                        <Col span={11}>
                            <Form.Item
                                label="Cantidad de folios a generar"
                                name="cantidad"
                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                            >
                                <InputNumber min={1} onChange={changeDecimal} placeholder='Ingresa la cantidad de folios a generar' style={{width: '100%'}} />
                            </Form.Item>
                        </Col>

                        <Col span={11} className='marginBoton'>
                            <br/>
                            <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Generar Folio de Encuesta de Satisfacción</Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        );
    }
}
const mapStateToProps = state => {
    return {
        fetchingNuevoFolioEncuesta: state.GestionDeCalidadReducer.fetchingNuevoFolioEncuesta,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingAñosPreguntas: state.GestionDeCalidadReducer.fetchingAñosPreguntas,
        listaAñosPreguntas: state.GestionDeCalidadReducer.listaAñosPreguntas,
        fetchingMesesPreguntas: state.GestionDeCalidadReducer.fetchingMesesPreguntas,
        listaMesesPreguntas: state.GestionDeCalidadReducer.listaMesesPreguntas,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onListaAñosPreguntas: () => {
            dispatch ({ type: 'LISTA_AÑOS_PREGUNTAS_REQUEST' });
        },
        onListaMesesPreguntas: (año, idServicio) => {
            dispatch ({ type: 'LISTA_MESES_FOLIO_REQUEST', año, idServicio });
        },
        onNuevoFolioEncuesta: (formulario) => {
            dispatch({ type: "NUEVO_FOLIO_ENCUESTA_REQUEST", formulario })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevaPreguntaEncuesta);