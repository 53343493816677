import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Select, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoTipoBitacora from './ModalNuevoTipoBitacora';
import ModalEditarTipoBitacora from './ModalEditarTipoBitacora';

const confirm = Modal.confirm;
const { Search } = Input;
const { Option } = Select;
class TiposBitacoraContainer extends React.Component {
    componentDidMount(){
        this.props.onListaServiciosActivos();
        this.props.getTiposBitacoraBusqueda(1,{'idServicio': null, 'param': null});
        this.props.onListaRoles();
    }
    state={
        page: 1,
        busqueda: {
            idServicio: null,
            param: null,
        },
    }
    render() {
        const { fetchingListaTiposBitacoraBusqueda ,listaTiposBitacoraBusqueda, onEstadoTipoBitacora, 
            getTiposBitacoraBusqueda, onModalEditarTipoBitacora, onModalNuevoTipoBitacora, 
            listaTiposBitacoraBusquedaCantidad, fetchingListaServiciosActivosF, listaServiciosActivosF
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        // const onChangeServicio = (value) => {
        //     const busqueda = {
        //         param: this.state.busqueda.param,
        //         idServicio: value
        //     }
        //     this.setState({
        //         busqueda,
        //     })
        //     console.log(busqueda);
        //     getTiposBitacoraBusqueda(1, busqueda);
        // }
        const onBuscarParam = (value) => {
            const busqueda = {
                param: value,
                idServicio: this.state.busqueda.idServicio
            }
            this.setState({
                busqueda,
            })
            console.log(busqueda);
            getTiposBitacoraBusqueda(1, busqueda);
        }

        const columns = [{
                title: 'Tipo de bitácora',
                dataIndex: 'tipoBitacora',
                key: 'tipoBitacora',
            },{
                title: 'Descripción',
                dataIndex: 'descripcion',
                key: 'descripcion',
            },
            // {
            //     title: 'Servicio',
            //     dataIndex: 'servicio',
            //     key: 'servicio',
            // },
            {
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                align: 'center',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.tipoDeBitácoraActivoInactivo} onChange={() => handleDesactivar(record)}/>
                ),
            },{
                title: 'Editar',
                key: 'editar',
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.crearTipoDeBitácora} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },
        ];
        const onEditar = (key) => {
            onModalEditarTipoBitacora(key, this.state.page, this.state.busqueda);
        }
        const handleDesactivar = (key) => {
            console.log(key);
            const page = this.state.page;
            const busqueda = this.state.busqueda;
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del tipo de bitácora "'+ key.tipoBitacora +'"?',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoTipoBitacora(key.idTipoBitacora, page, busqueda);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            getTiposBitacoraBusqueda(pagination.current, this.state.busqueda);
		}
        const onNuevoRol = () => {
            onModalNuevoTipoBitacora(this.state.page, this.state.busqueda);
        }

        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    {/* <Col span={8}>
                        <Select allowClear
                            style={{ width: '100%' }}
                            placeholder="Selecciona un servicio a filtrar"
                            loading={fetchingListaServiciosActivosF}
                            onChange={onChangeServicio}
                            showSearch={true}
                        >
                            {listaServiciosActivosF.map(option => 
                            <Option key={option.idServicio + ' - ' + option.nombre}>
                                <Tooltip placement="topLeft" title={option.idServicio + ' - ' + option.nombre}>
                                    {option.idServicio + ' - ' + option.nombre}
                                </Tooltip>
                            </Option>)}
                        </Select>
                    </Col> */}
                    <Col span={22}>
                        <Search className='buscarModulos' placeholder="Ingresa el tipo de bitácora o descripción a filtrar" onSearch={value => onBuscarParam(value)} style={{ width: '100%' }}/>
                    </Col>
                    {/* <Col span={7}>
                        <Button onClick={onNuevoRol} disabled={!permisosUsuario.crearTipoDeBitácora} className='nuevoUsuario'>Nuevo Tipo de Bitácora</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nuevo Tipo de Bitácora' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoRol} disabled={!permisosUsuario.crearTipoDeBitácora} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="idTipoBitacora" 
                            columns={columns}
                            dataSource={listaTiposBitacoraBusqueda}
                            loading={fetchingListaTiposBitacoraBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaTiposBitacoraBusquedaCantidad, simple: true, current: this.state.page }}
                        />         
                    </Col>
                </Row>
                <ModalNuevoTipoBitacora/>
                <ModalEditarTipoBitacora/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaTiposBitacoraBusqueda: state.ConfiguracionYSeguridadReducer.listaTiposBitacoraBusqueda,
        fetchingListaTiposBitacoraBusqueda: state.ConfiguracionYSeguridadReducer.fetchingListaTiposBitacoraBusqueda,
        listaTiposBitacoraBusquedaCantidad: state.ConfiguracionYSeguridadReducer.listaTiposBitacoraBusquedaCantidad,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        getTiposBitacoraBusqueda: (page, busqueda) => {
	    	dispatch({ type: "LISTA_TIPOS_BITACORA_BUSQUEDA_REQUEST", page, busqueda });
        },
        onEstadoTipoBitacora: (idTipoBitacora, page, busqueda) => {
	    	dispatch({ type: "ESTADO_TIPO_BITACORA_REQUEST", idTipoBitacora, page, busqueda });
        },
        onModalNuevoTipoBitacora: (page, busqueda) => {
            dispatch({ type: "MODAL_NUEVO_TIPO_BITACORA", showModalNuevoTipoBitacora: true, page, busqueda });
        },
        onModalEditarTipoBitacora: (tipoBitacoraSeleccionada, page, busqueda) => {
            dispatch({ type: "MODAL_EDITAR_TIPO_BITACORA", showModalEditarTipoBitacora: true, tipoBitacoraSeleccionada, page, busqueda});
        },
        onListaServiciosActivos: () => {
            dispatch ({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST' });
        },
        onListaRoles: () => {
            dispatch ({ type: 'LISTA_ROLES_REQUEST' });
        }
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(TiposBitacoraContainer);