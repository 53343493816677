import * as EvaluacionDeLaGestionActions from '../actions/EvaluacionDeLaGestionActions';
import * as GestionDeCalidadActions from "../actions/GestionDeCalidadActions";

const initialState = {
     // States generales para el módulo
     catalogoEvaluacionDeLaGestion: '',
     page: 1,
     busqueda: null,

     // States para el CU - Catálogo de Clasificación de Gerencias
     fetchingListaClasificaciones: false,
     listaClasificaciones: [],
     listaClasificacionesCantidad: 0,

     fetchingListaClasificacionesActivos: false,
     listaClasificacionesActivos: [],

     showModalCrearClasificacion: false,
     fetchingCrearClasificacion: false,

     showModalEditarClasificacion: false,
     fetchingEditarClasificacion: false,
     clasificacionSeleccionada: null,
     listaClasificacionesGerenciaActivas: [],
     fetchingListaClasificacionesGerenciaActivas: false,

     // States para el CU -  Catálogo de Temas
     fetchingListaTemasBusqueda: false,
     listaTemasBusqueda: [],
     listaTemasBusquedaCantidad: 0,

     fetchingListaTemasActivos: false,
     listaTemasActivos: [],

     showModalNuevoTema: false,
     fetchingNuevoTema: false,

     showModalEditarTema: false,
     fetchingEditarTema: false,
     temaSeleccionado: null,

     // States para el CU -  Catálogo de Objetivos para Evaluación de Calidad
     showModalNuevoObjetivo: false,
     showModalEditarObjetivo: false,
     listaObjetivosBusqueda: [],
     fetchingListaObjetivosBusqueda: false,
     listaObjetivosBusquedaCantidad: null,
     fetchingNuevoObjetivo: false,
     fetchingEditarObjetivo: false,
     objetivoSeleccionado: null,
     fetchingListaObjetivosActivos: false,
     listaObjetivosActivos: [],
     serviciosByIdCentroCosto: [],
     fetchingServiciosByIdCentroCosto: false,
     serviciosMapping: [],
     fetchingListaEstandaresByServicio: false,
     listaEstandaresDesempenoFiltroActivosByServicio: [],

     // States para el CU - Catálogo de  Indicadores de Desempeño
     showModalAltaIndicaresDesempeno: false,
     showModalEditarIndicadorDesempeno: false,
     indicadorDesempenoSeleccionado: [],
     fetchingObjetivosByGerencia: false,
     objetivosByGerencia: [],
     fetchingListaIndicadoresDesempeno: false,
     listaIndicadoresDesempeno: [],
     listaIndicadoresDesempenoCantidad: null,
     fetchingAltaIndicadorDesempeno: false,
     fetchingEditarIndicadorDesempeno: false,
     disabledObjEdit: false,
     indicadorDesempeno: '', fechaVigencia: '',

     //Clasificación de Solicitudes de Servicios y Servicios Programados por Gerencia
     fetchingListaEstadisticasGerencia: false,
     estadisticasGerenciaSP: {},
     estadisticasGerenciaSS: {},
     
     //Reporte dinámico para evaluaciones de calidad
     fetchingListaReportesDinamico: false,
     listaReportesDinamico: [],
     listaReportesDinamicoCantidad: 0,

     fetchingListaReportesDinamicoAgrupado: false,
     listaReportesDinamicoAgrupado: [],
     listaReportesDinamicoAgrupadoCantidad: 0,

     fetchingExportarReportesDinamico: false,

     fetchingListaRegistrosReporte: false,
     listaRegistrosReporte: [],
     listaRegistrosReporteCantidad: 0,

     fetchingListaRegistrosEspecialReporte: false,
     listaRegistrosEspecialReporte: [],
     listaRegistrosEspecialReporteCantidad: 0,

     showModalNuevoRegistroReporte: false,
     fetchingNuevoRegistroReporte: false,
     data: null,

     showModalEditarRegistroReporte: false,
     fetchingEditarRegistroReporte: false,
     registroReporteSeleccionado: false,
     personal: false,

     // Evaluacion de indicadores de desempeño

     fetchingListaGerenciasByServicio: false,
     listaGerenciasByServicio: [],

     fetchingListaObjetivosByGerencia: false,
     listaObjetivosByGerencia: [],

     fetchingListaServiciosEvaluacionesDesempeno: false,
     listaServiciosEvaluacionesDesempeno: [],

     showModalEvaluacionDesempeno: false,
     modalEvaluacionDesempenoData: null,

     fetchingListaIndicadoresByGerenciaObjetibo: false,
     listaIndicadoresByGerenciaObjetibo: [],

     fetchingListaPartidasByServicio: false,
     listaPartidasByServicio: [],
     mostrarTablaSolicitudMateriales: false,

     fetchingListaPartidasByServicio2: false,
     listaPartidasByServicio2: [],

     fetchingCrearEvaluacionDeDesempeno: false,

     fetchingListaMesesEvaluacion: false,
     listaMesesEvaluacion: [],

     fetchingListaEvaluacionesDesempeno: false,
     listaEvaluacionesDesempeno: [],
     listaEvaluacionesDesempenoCantidad: 0,

     fetchingListaEvaluacionesDesempeno1: false,
     listaEvaluacionesDesempeno1: [],
     nomenclaturaElemento1: '',
     listaEvaluacionesDesempenoCantidad1: 0,

     fetchingListaEvaluacionesDesempeno2: false,
     listaEvaluacionesDesempeno2: [],
     nomenclaturaElemento2: '',
     listaEvaluacionesDesempenoCantidad2: 0,

     showModalConsultaDeEvaluaciones: false,

     fetchingListaServiciosByCentro: false,
     listaServiciosByCentro: [],
}

export default (state = initialState, action) => {
     switch (action.type) {
          // Reducers generales para el módulo
          case EvaluacionDeLaGestionActions.CAMBIAR_CATALOGO_EVALUACION_GESTION:
               return { ...state, catalogoEvaluacionDeLaGestion: action.catalogoEvaluacionDeLaGestion };

          // Reducers para el CU - Catálogo de Clasificación de Gerencias
          case EvaluacionDeLaGestionActions.LISTA_CLASIFICACION_REQUEST:
               return { ...state, fetchingListaClasificaciones: true };
          case EvaluacionDeLaGestionActions.LISTA_CLASIFICACION_SUCCESS:
               return { ...state, fetchingListaClasificaciones: false, listaClasificaciones: action.listaClasificaciones, listaClasificacionesCantidad: action.listaClasificacionesCantidad };
          case EvaluacionDeLaGestionActions.LISTA_CLASIFICACION_FAILURE:
               return { ...state, fetchingListaClasificaciones: false };

          case EvaluacionDeLaGestionActions.LISTA_CLASIFICACION_ACTIVOS_REQUEST:
               return { ...state, fetchingListaClasificacionesActivos: true };
          case EvaluacionDeLaGestionActions.LISTA_CLASIFICACION_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaClasificacionesActivos: false, listaClasificacionesActivos: action.listaClasificacionesActivos };
          case EvaluacionDeLaGestionActions.LISTA_CLASIFICACION_ACTIVOS_FAILURE:
               return { ...state, fetchingListaClasificacionesActivos: false };

          case EvaluacionDeLaGestionActions.ESTADO_CLASIFICACION_REQUEST:
               return { ...state, fetchingListaClasificaciones: true };
          case EvaluacionDeLaGestionActions.ESTADO_CLASIFICACION_SUCCESS:
               return { ...state, fetchingListaClasificaciones: false };
          case EvaluacionDeLaGestionActions.ESTADO_CLASIFICACION_FAILURE:
               return { ...state, fetchingListaClasificaciones: false };

          case EvaluacionDeLaGestionActions.SHOW_MODAL_CREAR_CLASIFICACION:
               return { ...state, showModalCrearClasificacion: action.showModalCrearClasificacion, page: action.page, busqueda: action.busqueda };

          case EvaluacionDeLaGestionActions.CREAR_CLASIFICACION_REQUEST:
               return { ...state, fetchingCrearClasificacion: true };
          case EvaluacionDeLaGestionActions.CREAR_CLASIFICACION_SUCCESS:
               return { ...state, fetchingCrearClasificacion: false };
          case EvaluacionDeLaGestionActions.CREAR_CLASIFICACION_FAILURE:
               return { ...state, fetchingCrearClasificacion: false };

          case EvaluacionDeLaGestionActions.SHOW_MODAL_EDITAR_CLASIFICACION:
               return { ...state, showModalEditarClasificacion: action.showModalEditarClasificacion, clasificacionSeleccionada: action.clasificacionSeleccionada, page: action.page, busqueda: action.busqueda };

          case EvaluacionDeLaGestionActions.EDITAR_CLASIFICACION_REQUEST:
               return { ...state, fetchingEditarClasificacion: true };
          case EvaluacionDeLaGestionActions.EDITAR_CLASIFICACION_SUCCESS:
               return { ...state, fetchingEditarClasificacion: false };
          case EvaluacionDeLaGestionActions.EDITAR_CLASIFICACION_FAILURE:
               return { ...state, fetchingEditarClasificacion: false };

          case EvaluacionDeLaGestionActions.LISTA_CLASIFICACIONES_GERENCIA_ACTIVAS_REQUEST:
               return { ...state, fetchingListaClasificacionesGerenciaActivas: true };
          case EvaluacionDeLaGestionActions.LISTA_CLASIFICACIONES_GERENCIA_ACTIVAS_SUCCESS:
               return { ...state, fetchingListaClasificacionesGerenciaActivas: false, listaClasificacionesGerenciaActivas: action.listaClasificacionesGerenciaActivas };
          case EvaluacionDeLaGestionActions.LISTA_CLASIFICACIONES_GERENCIA_ACTIVAS_FAILURE:
               return { ...state, fetchingListaClasificacionesGerenciaActivas: false };

          // Reducers para el CU -  Catálogo de Temas
          case EvaluacionDeLaGestionActions.LISTA_TEMAS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaTemasBusqueda: true };
          case EvaluacionDeLaGestionActions.LISTA_TEMAS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaTemasBusqueda: false, listaTemasBusqueda: action.listaTemasBusqueda, listaTemasBusquedaCantidad: action.listaTemasBusquedaCantidad };
          case EvaluacionDeLaGestionActions.LISTA_TEMAS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaTemasBusqueda: false };

          case EvaluacionDeLaGestionActions.LISTA_TEMAS_ACTIVOS_REQUEST:
               return { ...state, fetchingListaTemasActivos: true };
          case EvaluacionDeLaGestionActions.LISTA_TEMAS_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaTemasActivos: false, listaTemasActivos: action.listaTemasActivos };
          case EvaluacionDeLaGestionActions.LISTA_TEMAS_ACTIVOS_FAILURE:
               return { ...state, fetchingListaTemasActivos: false };

          case EvaluacionDeLaGestionActions.ESTADO_TEMA_REQUEST:
               return { ...state, fetchingListaTemasBusqueda: true };
          case EvaluacionDeLaGestionActions.ESTADO_TEMA_SUCCESS:
               return { ...state, fetchingListaTemasBusqueda: false };
          case EvaluacionDeLaGestionActions.ESTADO_TEMA_FAILURE:
               return { ...state, fetchingListaTemasBusqueda: false };

          case EvaluacionDeLaGestionActions.SHOW_MODAL_NUEVO_TEMA:
               return { ...state, showModalNuevoTema: action.showModalNuevoTema, page: action.page, busqueda: action.busqueda };

          case EvaluacionDeLaGestionActions.NUEVO_TEMA_REQUEST:
               return { ...state, fetchingNuevoTema: true };
          case EvaluacionDeLaGestionActions.NUEVO_TEMA_SUCCESS:
               return { ...state, fetchingNuevoTema: false };
          case EvaluacionDeLaGestionActions.NUEVO_TEMA_FAILURE:
               return { ...state, fetchingNuevoTema: false };

          case EvaluacionDeLaGestionActions.SHOW_MODAL_EDITAR_TEMA:
               return { ...state, showModalEditarTema: action.showModalEditarTema, temaSeleccionado: action.temaSeleccionado, page: action.page, busqueda: action.busqueda };

          case EvaluacionDeLaGestionActions.EDITAR_TEMA_REQUEST:
               return { ...state, fetchingEditarTema: true };
          case EvaluacionDeLaGestionActions.EDITAR_TEMA_SUCCESS:
               return { ...state, fetchingEditarTema: false };
          case EvaluacionDeLaGestionActions.EDITAR_TEMA_FAILURE:
               return { ...state, fetchingEditarTema: false };

          // Reducers para el CU -  Catálogo de Objetivos para Evaluación de Calidad
          case EvaluacionDeLaGestionActions.SHOW_MODAL_NUEVO_OBJETIVO:
               return { ...state, showModalNuevoObjetivo: action.showModalNuevoObjetivo, page: action.page, busqueda: action.busqueda };

          case EvaluacionDeLaGestionActions.SHOW_MODAL_EDITAR_OBJETIVO:
               return { ...state, showModalEditarObjetivo: action.showModalEditarObjetivo, objetivoSeleccionado: action.objetivoSeleccionado, page: action.page, busqueda: action.busqueda };

          case EvaluacionDeLaGestionActions.CLEAN_OBJECT_SELECTED:
               return { ...state, objetivoSeleccionado: action.objetivoSeleccionado };

          case EvaluacionDeLaGestionActions.UPDATE_OBJETCT_SELECTED:
               return { ...state, serviciosMapping: action.serviciosMapping };
          
          case EvaluacionDeLaGestionActions.LISTA_OBJETIVOS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaObjetivosBusqueda: true };
          case EvaluacionDeLaGestionActions.LISTA_OBJETIVOS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaObjetivosBusqueda: false, listaObjetivosBusqueda: action.listaObjetivosBusqueda, listaObjetivosBusquedaCantidad: action.listaObjetivosBusquedaCantidad };
          case EvaluacionDeLaGestionActions.LISTA_OBJETIVOS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaObjetivosBusqueda: false };

          case EvaluacionDeLaGestionActions.LISTA_OBJETIVOS_ACTIVOS_REQUEST:
               return { ...state, fetchingListaObjetivosActivos: true };
          case EvaluacionDeLaGestionActions.LISTA_OBJETIVOS_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaObjetivosActivos: false, listaObjetivosActivos: action.listaObjetivosActivos };
          case EvaluacionDeLaGestionActions.LISTA_OBJETIVOS_ACTIVOS_FAILURE:
               return { ...state, fetchingListaObjetivosActivos: false };

          case EvaluacionDeLaGestionActions.ESTADO_OBJETIVO_REQUEST:
               return { ...state, fetchingListaObjetivosBusqueda: true };
          case EvaluacionDeLaGestionActions.ESTADO_OBJETIVO_SUCCESS:
               return { ...state, fetchingListaObjetivosBusqueda: false };
          case EvaluacionDeLaGestionActions.ESTADO_OBJETIVO_FAILURE:
               return { ...state, fetchingListaObjetivosBusqueda: false };

          case EvaluacionDeLaGestionActions.NUEVO_OBJETIVO_REQUEST:
               return { ...state, fetchingNuevoObjetivo: true };
          case EvaluacionDeLaGestionActions.NUEVO_OBJETIVO_SUCCESS:
               return { ...state, fetchingNuevoObjetivo: false };
          case EvaluacionDeLaGestionActions.NUEVO_OBJETIVO_FAILURE:
               return { ...state, fetchingNuevoObjetivo: false };

          case EvaluacionDeLaGestionActions.EDITAR_OBJETIVO_REQUEST:
               return { ...state, fetchingEditarObjetivo: true };
          case EvaluacionDeLaGestionActions.EDITAR_OBJETIVO_SUCCESS:
               return { ...state, fetchingEditarObjetivo: false };
          case EvaluacionDeLaGestionActions.EDITAR_OBJETIVO_FAILURE:
               return { ...state, fetchingEditarObjetivo: false };

          case EvaluacionDeLaGestionActions.SERVICIOS_BY_IDCENTROCOSTO_REQUEST:
               return { ...state, fetchingServiciosByIdCentroCosto: true };
          case EvaluacionDeLaGestionActions.SERVICIOS_BY_IDCENTROCOSTO_SUCCESS:
               return { ...state, fetchingServiciosByIdCentroCosto: false, serviciosByIdCentroCosto: action.serviciosByIdCentroCosto };
          case EvaluacionDeLaGestionActions.SERVICIOS_BY_IDCENTROCOSTO_FAILURE:
               return { ...state, fetchingServiciosByIdCentroCosto: false };

          case EvaluacionDeLaGestionActions.LISTA_ESTANDARES_ACTIVOS_PARAM_BY_SERVICIO_REQUEST:
               return { ...state, fetchingListaEstandaresByServicio: true };
          case EvaluacionDeLaGestionActions.LISTA_ESTANDARES_ACTIVOS_PARAM_BY_SERVICIO_SUCCESS:
               return { ...state, fetchingListaEstandaresByServicio: false, listaEstandaresDesempenoFiltroActivosByServicio: action.listaEstandaresDesempenoFiltroActivosByServicio };
          case EvaluacionDeLaGestionActions.LISTA_ESTANDARES_ACTIVOS_PARAM_BY_SERVICIO_FAILURE:
               return { ...state, fetchingListaEstandaresByServicio: false };

          // Reducers para el CU - Catálogo de  Indicadores de Desempeño
          case EvaluacionDeLaGestionActions.SHOW_MODAL_ALTA_INDICADORES_DESEMPENO:
               return { ...state, showModalAltaIndicaresDesempeno: action.showModalAltaIndicaresDesempeno, page: action.page, busqueda: action.busqueda, indicadorDesempeno: action.indicadorDesempeno, fechaVigencia: action.fechaVigencia };

          case EvaluacionDeLaGestionActions.SHOW_MODAL_EDITAR_INDICADOR_DESEMPENO:
               return { ...state, showModalEditarIndicadorDesempeno: action.showModalEditarIndicadorDesempeno, indicadorDesempenoSeleccionado: action.indicadorDesempenoSeleccionado, page: action.page, busqueda: action.busqueda, indicadorDesempeno: action.indicadorDesempeno, fechaVigencia: action.fechaVigencia };

          case EvaluacionDeLaGestionActions.BOOLEAN_OBJETO_BY_GERENCIA:
               return { ...state, disabledObjEdit: action.disabledObjEdit };
          
          case EvaluacionDeLaGestionActions.OBJETIVOS_BY_GERENCIA_REQUEST:
               return { ...state, fetchingObjetivosByGerencia: true };
          case EvaluacionDeLaGestionActions.OBJETIVOS_BY_GERENCIA_SUCCESS:
               return { ...state, fetchingObjetivosByGerencia: false, objetivosByGerencia: action.objetivosByGerencia, disabledObjEdit: action.disabledObjEdit };
          case EvaluacionDeLaGestionActions.OBJETIVOS_BY_GERENCIA_FAILURE:
               return { ...state, fetchingObjetivosByGerencia: false };

          case EvaluacionDeLaGestionActions.LISTA_INDICADORES_DESEMPENO_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaIndicadoresDesempeno: true };
          case EvaluacionDeLaGestionActions.LISTA_INDICADORES_DESEMPENO_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaIndicadoresDesempeno: false, listaIndicadoresDesempeno: action.listaIndicadoresDesempeno, listaIndicadoresDesempenoCantidad: action.listaIndicadoresDesempenoCantidad };
          case EvaluacionDeLaGestionActions.LISTA_INDICADORES_DESEMPENO_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaIndicadoresDesempeno: false };

          case EvaluacionDeLaGestionActions.ALTA_INDICADOR_DESEMPENO_REQUEST:
               return { ...state, fetchingAltaIndicadorDesempeno: true };
          case EvaluacionDeLaGestionActions.ALTA_INDICADOR_DESEMPENO_SUCCESS:
               return { ...state, fetchingAltaIndicadorDesempeno: false };
          case EvaluacionDeLaGestionActions.ALTA_INDICADOR_DESEMPENO_FAILURE:
               return { ...state, fetchingAltaIndicadorDesempeno: false };

          case EvaluacionDeLaGestionActions.EDITAR_INDICADOR_DESEMPENO_REQUEST:
               return { ...state, fetchingEditarIndicadorDesempeno: true };
          case EvaluacionDeLaGestionActions.EDITAR_INDICADOR_DESEMPENO_SUCCESS:
               return { ...state, fetchingEditarIndicadorDesempeno: false };
          case EvaluacionDeLaGestionActions.EDITAR_INDICADOR_DESEMPENO_FAILURE:
               return { ...state, fetchingEditarIndicadorDesempeno: false };

          //Clasificación de Solicitudes de Servicios y Servicios Programados por Gerencia
          case EvaluacionDeLaGestionActions.GET_ESTADISTICAS_GERENCIA_REQUEST:
               return { ...state, fetchingListaEstadisticasGerencia: true};
          case EvaluacionDeLaGestionActions.GET_ESTADISTICAS_GERENCIA_SUCCESS:
               return { ...state, fetchingListaEstadisticasGerencia: false, estadisticasGerenciaSS: action.estadisticasGerenciaSS, estadisticasGerenciaSP: action.estadisticasGerenciaSP};
          case EvaluacionDeLaGestionActions.GET_ESTADISTICAS_GERENCIA_FAILURE:
               return { ...state, fetchingListaEstadisticasGerencia: false};
               
          // Reducers para el CU -  Reporte Dinámico para Evaluaciones de Calidad
          case EvaluacionDeLaGestionActions.LISTA_REPORTE_DINAMICO_REQUEST:
               return { ...state, fetchingListaReportesDinamico: true, formularioReporte: action.formulario };
          case EvaluacionDeLaGestionActions.LISTA_REPORTE_DINAMICO_SUCCESS:
               return { ...state, fetchingListaReportesDinamico: false, listaReportesDinamico: action.listaReportesDinamico, listaReportesDinamicoCantidad: action.listaReportesDinamicoCantidad };
          case EvaluacionDeLaGestionActions.LISTA_REPORTE_DINAMICO_FAILURE:
               return { ...state, fetchingListaReportesDinamico: false };

          case EvaluacionDeLaGestionActions.LISTA_REPORTE_DINAMICO_AGRUPADO_REQUEST:
               return { ...state, fetchingListaReportesDinamicoAgrupado: true, formularioReporte: action.formulario };
          case EvaluacionDeLaGestionActions.LISTA_REPORTE_DINAMICO_AGRUPADO_SUCCESS:
               return { ...state, fetchingListaReportesDinamicoAgrupado: false, listaReportesDinamicoAgrupado: action.listaReportesDinamicoAgrupado, listaReportesDinamicoAgrupadoCantidad: action.listaReportesDinamicoAgrupadoCantidad };
          case EvaluacionDeLaGestionActions.LISTA_REPORTE_DINAMICO_AGRUPADO_FAILURE:
               return { ...state, fetchingListaReportesDinamicoAgrupado: false };

          case EvaluacionDeLaGestionActions.EXPORTAR_REPORTE_EXCEL_REQUEST:
               return { ...state, fetchingExportarReportesDinamico: true };
          case EvaluacionDeLaGestionActions.EXPORTAR_REPORTE_EXCEL_SUCCESS:
               return { ...state, fetchingExportarReportesDinamico: false };
          case EvaluacionDeLaGestionActions.EXPORTAR_REPORTE_EXCEL_FAILURE:
               return { ...state, fetchingExportarReportesDinamico: false };

          case EvaluacionDeLaGestionActions.EXPORTAR_REPORTE_PDF_REQUEST:
               return { ...state, fetchingExportarReportesDinamico: true };
          case EvaluacionDeLaGestionActions.EXPORTAR_REPORTE_PDF_SUCCESS:
               return { ...state, fetchingExportarReportesDinamico: false };
          case EvaluacionDeLaGestionActions.EXPORTAR_REPORTE_PDF_FAILURE:
               return { ...state, fetchingExportarReportesDinamico: false };

          case EvaluacionDeLaGestionActions.LIMPIAR_FORMULARIO_REPORTE:
               return { ...state, formularioReporte : action.formularioReporte, listaReportesDinamico: action.listaReportesDinamico, listaReportesDinamicoCantidad: action.listaReportesDinamicoCantidad};

          case EvaluacionDeLaGestionActions.LISTA_REGISTRO_REPORTE_REQUEST:
               return { ...state, fetchingListaRegistrosReporte: true };
          case EvaluacionDeLaGestionActions.LISTA_REGISTRO_REPORTE_SUCCESS:
               return { ...state, fetchingListaRegistrosReporte: false, listaRegistrosReporte: action.listaRegistrosReporte, listaRegistrosReporteCantidad: action.listaRegistrosReporteCantidad };
          case EvaluacionDeLaGestionActions.LISTA_REGISTRO_REPORTE_FAILURE:
               return { ...state, fetchingListaRegistrosReporte: false };

          case EvaluacionDeLaGestionActions.LISTA_REGISTRO_ESPECIAL_REPORTE_REQUEST:
               return { ...state, fetchingListaRegistrosEspecialReporte: true };
          case EvaluacionDeLaGestionActions.LISTA_REGISTRO_ESPECIAL_REPORTE_SUCCESS:
               return { ...state, fetchingListaRegistrosEspecialReporte: false, listaRegistrosEspecialReporte: action.listaRegistrosEspecialReporte, listaRegistrosEspecialReporteCantidad: action.listaRegistrosEspecialReporteCantidad };
          case EvaluacionDeLaGestionActions.LISTA_REGISTRO_ESPECIAL_REPORTE_FAILURE:
               return { ...state, fetchingListaRegistrosEspecialReporte: false };

          case EvaluacionDeLaGestionActions.ESTADO_REGISTRO_REPORTE_REQUEST:
               return { ...state, fetchingListaRegistrosReporte: true };
          case EvaluacionDeLaGestionActions.ESTADO_REGISTRO_REPORTE_SUCCESS:
               return { ...state, fetchingListaRegistrosReporte: false };
          case EvaluacionDeLaGestionActions.ESTADO_REGISTRO_REPORTE_FAILURE:
               return { ...state, fetchingListaRegistrosReporte: false };

          case EvaluacionDeLaGestionActions.MODAL_NUEVO_REGISTRO_REPORTE:
               return { ...state, showModalNuevoRegistroReporte: action.showModalNuevoRegistroReporte, busqueda: action.busqueda, data: action.data };

          case EvaluacionDeLaGestionActions.NUEVO_REGISTRO_REPORTE_REQUEST:
               return { ...state, fetchingNuevoRegistroReporte: true };
          case EvaluacionDeLaGestionActions.NUEVO_REGISTRO_REPORTE_SUCCESS:
               return { ...state, fetchingNuevoRegistroReporte: false };
          case EvaluacionDeLaGestionActions.NUEVO_REGISTRO_REPORTE_FAILURE:
               return { ...state, fetchingNuevoRegistroReporte: false };

          case EvaluacionDeLaGestionActions.MODAL_EDITAR_REGISTRO_REPORTE:
               return { ...state, showModalEditarRegistroReporte: action.showModalEditarRegistroReporte, registroReporteSeleccionado: action.registroReporteSeleccionado, page: action.page, personal: action.personal };

          case EvaluacionDeLaGestionActions.EDITAR_REGISTRO_REPORTE_REQUEST:
               return { ...state, fetchingEditarRegistroReporte: true };
          case EvaluacionDeLaGestionActions.EDITAR_REGISTRO_REPORTE_SUCCESS:
               return { ...state, fetchingEditarRegistroReporte: false };
          case EvaluacionDeLaGestionActions.EDITAR_REGISTRO_REPORTE_FAILURE:
               return { ...state, fetchingEditarRegistroReporte: false };


         // Evaluación de Indicadores de Desempeño

          case EvaluacionDeLaGestionActions.LISTA_GERENCIAS_BY_SERVICIO_REQUEST:
               return { ...state, fetchingListaGerenciasByServicio: true };
          case EvaluacionDeLaGestionActions.LISTA_GERENCIAS_BY_SERVICIO_SUCCESS:
               return { ...state, fetchingListaGerenciasByServicio: false, listaGerenciasByServicio: action.listaGerenciasByServicio };
          case EvaluacionDeLaGestionActions.LISTA_GERENCIAS_BY_SERVICIO_FAILURE:
               return { ...state, fetchingListaGerenciasByServicio: false };

          case EvaluacionDeLaGestionActions.LISTA_OBJETIVOS_BY_GERENCIA_REQUEST:
               return { ...state, fetchingListaObjetivosByGerencia: true };
          case EvaluacionDeLaGestionActions.LISTA_OBJETIVOS_BY_GERENCIA_SUCCESS:
               return { ...state, fetchingListaObjetivosByGerencia: false, listaObjetivosByGerencia: action.listaObjetivosByGerencia };
          case EvaluacionDeLaGestionActions.LISTA_OBJETIVOS_BY_GERENCIA_FAILURE:
               return { ...state, fetchingListaObjetivosByGerencia: false };

          case EvaluacionDeLaGestionActions.LISTA_SERVICIOS_EVALUACIONES_DE_DESEMPENO_REQUEST:
               return { ...state, fetchingListaServiciosEvaluacionesDesempeno: true };
          case EvaluacionDeLaGestionActions.LISTA_SERVICIOS_EVALUACIONES_DE_DESEMPENO_SUCCESS:
               return { ...state, fetchingListaServiciosEvaluacionesDesempeno: false, listaServiciosEvaluacionesDesempeno: action.listaServiciosEvaluacionesDesempeno };
          case EvaluacionDeLaGestionActions.LISTA_SERVICIOS_EVALUACIONES_DE_DESEMPENO_FAILURE:
               return { ...state, fetchingListaServiciosEvaluacionesDesempeno: false };

          case EvaluacionDeLaGestionActions.LISTA_INDICADORES_BY_GERENCIA_OBJETIVO_REQUEST:
               return { ...state, fetchingListaIndicadoresByGerenciaObjetibo: true };
          case EvaluacionDeLaGestionActions.LISTA_INDICADORES_BY_GERENCIA_OBJETIVO_SUCCESS:
               return { ...state, fetchingListaIndicadoresByGerenciaObjetibo: false, listaIndicadoresByGerenciaObjetibo: action.listaIndicadoresByGerenciaObjetibo };
          case EvaluacionDeLaGestionActions.LISTA_INDICADORES_BY_GERENCIA_OBJETIVO_FAILURE:
               return { ...state, fetchingListaIndicadoresByGerenciaObjetibo: false };

          case EvaluacionDeLaGestionActions.MODAL_EVALUACION_DESEMPENO:
               return { ...state, showModalEvaluacionDesempeno: action.showModalEvaluacionDesempeno, modalEvaluacionDesempenoData: action.modalEvaluacionDesempenoData };

          case EvaluacionDeLaGestionActions.LISTA_PARTIDAS_BY_SERVICIO_REQUEST:
               return { ...state, fetchingListaPartidasByServicio: true };
          case EvaluacionDeLaGestionActions.LISTA_PARTIDAS_BY_SERVICIO_SUCCESS:
               return { ...state, fetchingListaPartidasByServicio: false, listaPartidasByServicio: action.listaPartidasByServicio, mostrarTablaSolicitudMateriales: action.mostrarTablaSolicitudMateriales };
          case EvaluacionDeLaGestionActions.LISTA_PARTIDAS_BY_SERVICIO_FAILURE:
               return { ...state, fetchingListaPartidasByServicio: false };

          case EvaluacionDeLaGestionActions.LISTA_PARTIDAS_BY_SERVICIO_REQUEST2:
               return { ...state, fetchingListaPartidasByServicio2: true };
          case EvaluacionDeLaGestionActions.LISTA_PARTIDAS_BY_SERVICIO_SUCCESS2:
               return { ...state, fetchingListaPartidasByServicio2: false, listaPartidasByServicio2: action.listaPartidasByServicio };
          case EvaluacionDeLaGestionActions.LISTA_PARTIDAS_BY_SERVICIO_FAILURE2:
               return { ...state, fetchingListaPartidasByServicio2: false };

          case EvaluacionDeLaGestionActions.CREAR_EVALUACION_DE_DESEMPENO_REQUEST:
               return { ...state, fetchingCrearEvaluacionDeDesempeno: true };
          case EvaluacionDeLaGestionActions.CREAR_EVALUACION_DE_DESEMPENO_SUCCESS:
               return { ...state, fetchingCrearEvaluacionDeDesempeno: false};
          case EvaluacionDeLaGestionActions.CREAR_EVALUACION_DE_DESEMPENO_FAILURE:
               return { ...state, fetchingCrearEvaluacionDeDesempeno: false };

          case EvaluacionDeLaGestionActions.LISTA_MESES_EVALUACION_REQUEST:
               return { ...state, fetchingListaMesesEvaluacion: true };
          case EvaluacionDeLaGestionActions.LISTA_MESES_EVALUACION_SUCCESS:
               return { ...state, fetchingListaMesesEvaluacion: false, listaMesesEvaluacion: action.listaMesesEvaluacion };
          case EvaluacionDeLaGestionActions.LISTA_MESES_EVALUACION_FAILURE:
               return { ...state, fetchingListaMesesEvaluacion: false };

          case EvaluacionDeLaGestionActions.LISTA_EVALUACIONES_DESEMPENO_REQUEST:
               return { ...state, fetchingListaEvaluacionesDesempeno: true };
          case EvaluacionDeLaGestionActions.LISTA_EVALUACIONES_DESEMPENO_SUCCESS:
               return { ...state, fetchingListaEvaluacionesDesempeno: false, listaEvaluacionesDesempeno: action.listaEvaluacionesDesempeno, listaEvaluacionesDesempenoCantidad: action.listaEvaluacionesDesempenoCantidad };
          case EvaluacionDeLaGestionActions.LISTA_EVALUACIONES_DESEMPENO_FAILURE:
               return { ...state, fetchingListaEvaluacionesDesempeno: false };

          case EvaluacionDeLaGestionActions.LISTA_EVALUACIONES_DESEMPENO_1_REQUEST:
               return { ...state, fetchingListaEvaluacionesDesempeno1: true };
          case EvaluacionDeLaGestionActions.LISTA_EVALUACIONES_DESEMPENO_1_SUCCESS:
               return { ...state, fetchingListaEvaluacionesDesempeno1: false, listaEvaluacionesDesempeno1: action.listaEvaluacionesDesempeno, nomenclaturaElemento1: action.nomenclaturaElemento, listaEvaluacionesDesempenoCantidad1: action.listaEvaluacionesDesempenoCantidad };
          case EvaluacionDeLaGestionActions.LISTA_EVALUACIONES_DESEMPENO_1_FAILURE:
               return { ...state, fetchingListaEvaluacionesDesempeno1: false };

          case EvaluacionDeLaGestionActions.LISTA_EVALUACIONES_DESEMPENO_2_REQUEST:
               return { ...state, fetchingListaEvaluacionesDesempeno2: true };
          case EvaluacionDeLaGestionActions.LISTA_EVALUACIONES_DESEMPENO_2_SUCCESS:
               return { ...state, fetchingListaEvaluacionesDesempeno2: false, listaEvaluacionesDesempeno2: action.listaEvaluacionesDesempeno, nomenclaturaElemento2: action.nomenclaturaElemento, listaEvaluacionesDesempenoCantidad2: action.listaEvaluacionesDesempenoCantidad };
          case EvaluacionDeLaGestionActions.LISTA_EVALUACIONES_DESEMPENO_2_FAILURE:
               return { ...state, fetchingListaEvaluacionesDesempeno2: false };

          case EvaluacionDeLaGestionActions.MODAL_CONSULTA_EVALUACIONES:
               return { ...state, showModalConsultaDeEvaluaciones: action.showModalConsultaDeEvaluaciones };

          case EvaluacionDeLaGestionActions.LISTA_SERVICIOS_BY_CENTRO_REQUEST:
               return { ...state, fetchingListaServiciosByCentro: true };
          case EvaluacionDeLaGestionActions.LISTA_SERVICIOS_BY_CENTRO_SUCCESS:
               return { ...state, fetchingListaServiciosByCentro: false, listaServiciosByCentro: action.listaServiciosByCentro };
          case EvaluacionDeLaGestionActions.LISTA_SERVICIOS_BY_CENTRO_FAILURE:
               return { ...state, fetchingListaServiciosByCentro: false };

          default:
               return state;
     }
}
