import React from 'react';
import { Row, Col, Divider, Modal, List } from 'antd';
import { connect } from "react-redux";
import {  } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };

class ModalDetalleVariable extends React.Component {
     formRef = React.createRef();

     componentDidMount() {
          
     }

     state = {
          page: 1,
     }


     render() {
          const { 
               onCloseModalDetalle,
               showModalDetalleVariable,
               variableMedicionSeleccionado
          } = this.props;

          const handleCancel = () => {
               onCloseModalDetalle();
          };

          let data=[]
          if (variableMedicionSeleccionado){
               const {
                    nombre,
                    operacion,
                    unidadMedida,
                    fechaAlta,
                    fechaActualizacion
               } = variableMedicionSeleccionado;
               
               data = [
                    {title: "Nombre", data: nombre},
                    {title: "Operación", data: operacion}, 
                    {title: "Unidad de Medida", data: unidadMedida},
                    {title: "Alta", data: fechaAlta},
                    {title: "Actualización", data: fechaActualizacion}
               ];
          }

          return (
               <Modal
                    visible={showModalDetalleVariable}
                    title={'Detalle de Variable de Medición'}
                    onCancel={handleCancel}
                    footer={false}
                    width={'30%'}
               >
                    { variableMedicionSeleccionado ? (
                         <Row justify='space-around'>
                              <Col span={24}>
                                   {/* <Divider orientation="left">{variableMedicionSeleccionado.nombre}</Divider> */}
                                   <List
                                   className="listaCatalogos"
                                   bordered={true}
                                   dataSource={data}
                                   renderItem={item => (
                                       <List.Item className='listaHover' className='listaHover'>
                                                  <Col span={11}>
                                                       <strong>{item.title}</strong>  
                                                  </Col>
                                                  <Col span={11}>
                                                       {item.data !==null ? item.data : '-'} 
                                                  </Col>
                                        </List.Item>
                                   )}
                                   />
                              </Col>
                         </Row>
                    ) : false }                
               </Modal>
          );             
     }
}

const mapStateToProps = state => {
     return {
          showModalDetalleVariable: state.ServiciosPublicosReducer.showModalDetalleVariable,
          variableMedicionSeleccionado: state.ServiciosPublicosReducer.variableMedicionSeleccionado,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onCloseModalDetalle: () => {
               dispatch({ type: 'SHOW_MODAL_DETALLE_VARIABLE', showModalDetalleVariable: false });
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetalleVariable);