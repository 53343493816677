import React from 'react';
import { Row, Col, Button, Select, Form, Card} from 'antd';
import { connect } from 'react-redux';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

import ModalListadoInventarioFisicoGeneral from './ModalListaInventarioFisicoGeneral';

const { Option, OptGroup } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ListadoInventarioFisico extends React.Component {

    state = {
        page:1,
        almacenesTodos: false,
        pisoTodos: false,
        areasTodos: false,
        pasilloTodos: false,
        rackTodos: false,
        almacenes: [],
        pisos: [],
        areas: [],
        pasillos: [],
        racks: [],
    }

    formRef = React.createRef();

    componentDidMount() {
        this.props.onGetAlmacenes()
    }

    render() {
        const {listaAlmacenesActivos, fetchingListaAlmacenesActivos, fetchingListaPisosByAlmacen, listaPisosByAlmacen, fetchingListaAreasByPisos, listaAreasByPisos, 
            fetchingListaPasillosByAreas, listaPasillosByAreas, fetchingListaRacksByPasillos, listaRacksByPasillos,
            onGetPisosByAlmacenes, onGetAreasByPisos, onGetPasillosByAreas,onGetRacksByPasillos,onBusquedaListadoInventario, onShowModalInventarioFisico, listaUbicacionesProductosBusqueda} = this.props;
        const {almacenes,pisos,areas,pasillos,racks, almacenesTodos, pisoTodos, areasTodos, pasilloTodos, rackTodos, page} = this.state;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onBusquedaListadoInventario(almacenes, pisos, areas, pasillos, racks, page);
            onShowModalInventarioFisico();
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo)
        };
        
        const onChangeAlmacen = (value, data) => {
            console.log(value, data);
            this.formRef.current.setFieldsValue({'piso':undefined, 'area': undefined, 'pasillo':undefined, 'rack':undefined});
            this.setState({
                almacenesTodos:false, pisoTodos:false, areasTodos:false, pasilloTodos:false, rackTodos:false,
                almacenes: value, pisos: [], areas: [], pasillos: [], racks: []
            })
            if (data.length>0) {
                let almacenesIds = value;
                const todos = value.some(item => item === 'todos');
                if(todos) {
                    let almacenesIdsTodos = []
                    this.setState({almacenesTodos: true});
                    this.formRef.current.setFieldsValue({'idAlmacen':['todos']})
                    listaAlmacenesActivos.map(item => almacenesIdsTodos.push(item.idAlmacen));
                    almacenesIds = almacenesIdsTodos
                }
                this.setState({almacenes:almacenesIds})
                console.log(almacenesIds)
                onGetPisosByAlmacenes(almacenesIds); 
            } 
        }
        
        const onChangePiso = value => {
            this.formRef.current.setFieldsValue({'area':undefined, 'pasillo':undefined, 'rack':undefined});
            this.setState({
                pisoTodos:false, areasTodos:false, pasilloTodos:false, rackTodos:false,
                pisos:value, areas: [], pasillos: [], racks: []
            })
            if(value.length>0) {
                let pisoData = value;
                const todos = value.some(item => item === 'todos');
                if(todos) {
                    this.setState({pisoTodos:true});
                    this.formRef.current.setFieldsValue({'piso':['todos']});
                    pisoData = listaPisosByAlmacen;
                }
                console.log(pisoData);
                onGetAreasByPisos(pisoData)
                this.setState({pisos:pisoData})
            }
        }

        const onChangeArea = value => {
            this.formRef.current.setFieldsValue({'pasillo':undefined, 'rack':undefined});
            this.setState({
                areasTodos:false, pasilloTodos:false, rackTodos:false,
                areas:value ,pasillos: [], racks: []
            })
            if(value.length>0) {
                let areaData = value;
                const todos = value.some(item => item === 'todos');
                if(todos) {
                    this.setState({areasTodos:true});
                    this.formRef.current.setFieldsValue({'area':['todos']});
                    areaData = listaAreasByPisos;
                }
                console.log(areaData);
                onGetPasillosByAreas(areaData);
                this.setState({areas: areaData});
            }
        }

        const onChangePasillo = value => {
            this.formRef.current.setFieldsValue({'rack': undefined});
            this.setState({pasilloTodos:false, rackTodos:false, pasillos:value, racks: []});
            if(value.length>0) {
                let pasilloData = value;
                const todos = value.some(item => item === 'todos');
                if(todos) {
                    this.setState({pasilloTodos:true});
                    this.formRef.current.setFieldsValue({'pasillo':['todos']});
                    pasilloData = listaPasillosByAreas;
                }
                console.log(pasilloData)
                onGetRacksByPasillos(pasilloData);
                this.setState({pasillos: pasilloData});
            }
        }

        const onChangeRack = value => {
            this.setState({rackTodos:false, racks:value});
            if(value.length>0) {
                let rackData = value;
                const todos = value.some(item => item === 'todos');
                if(todos) {
                    this.setState({rackTodos:true});
                    this.formRef.current.setFieldsValue({'rack':['todos']});
                    rackData = listaRacksByPasillos;
                }
                console.log(rackData);
                this.setState({racks:rackData});
            }
        }

        return (
            <Card className="cardCatalogos" title={<div className="headerLista">Listado para Toma de Inventario Físico General</div>}>
            <Form
            {...layout}
            name="formularios"
            ref={this.formRef}
            onFinish= {onFinish}
            
            >
                <Row justify='space-between' >
                    <Col span={11}>
                        <Form.Item
                            label='Almacén'
                            name='idAlmacen'
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Select
                                mode='multiple'
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Seleccione un almacén"
                                loading={fetchingListaAlmacenesActivos}
                                onChange={onChangeAlmacen}
                            >
                                <OptGroup label="Todos los almacenes">
                                    <Option value="todos">Todos</Option>
                                </OptGroup>
                                <OptGroup label="Almacenes">
                                    {listaAlmacenesActivos.map(option => <Option disabled={almacenesTodos} key={option.idAlmacen}>{option.nombreC}</Option>)}
                                </OptGroup>

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Piso"
                            name='piso'
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Select
                                mode='multiple'
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Seleccione un piso"
                                loading={fetchingListaPisosByAlmacen}
                                onChange={onChangePiso}
                                disabled={!almacenes.length > 0}
                            >
                                <OptGroup label="Todos los pisos">
                                    <Option value="todos">Todos</Option>
                                </OptGroup>
                                <OptGroup label="Pisos">
                                    {listaPisosByAlmacen.map(option => <Option disabled={pisoTodos} key={option}>{option}</Option>)}
                                </OptGroup>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label='Área'
                            name='area'
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Select
                                mode='multiple'
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Seleccione un área"
                                loading={fetchingListaAreasByPisos}
                                onChange={onChangeArea}
                                disabled={!pisos.length > 0}
                            >
                                <OptGroup label="Todos las áreas">
                                    <Option value="todos">Todos</Option>
                                </OptGroup>
                                <OptGroup label="Áreas">
                                    {listaAreasByPisos.map(option => <Option disabled={areasTodos} key={option}>{option}</Option>)}
                                </OptGroup>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label='Pasillo'
                            name='pasillo'
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Select
                                mode='multiple'
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Seleccione un pasillo"
                                loading={fetchingListaPasillosByAreas}
                                onChange={onChangePasillo}
                                disabled={!areas.length > 0}
                            >
                                <OptGroup label="Todos los pasillos">
                                    <Option value="todos">Todos</Option>
                                </OptGroup>
                                <OptGroup label="Pasillos">
                                    {listaPasillosByAreas.map(option => <Option disabled={pasilloTodos} key={option}>{option}</Option>)}
                                </OptGroup>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label='Rack'
                            name='rack'
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Select
                                mode='multiple'
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Seleccione un rack"
                                loading={fetchingListaRacksByPasillos}
                                onChange={onChangeRack}
                                disabled={!pasillos.length > 0}
                            >
                                <OptGroup label="Todos los rack">
                                    <Option value="todos">Todos</Option>
                                </OptGroup>
                                <OptGroup label="Rack">
                                    {listaRacksByPasillos.map(option => <Option disabled={rackTodos} key={option}>{option}</Option>)}
                                </OptGroup>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={11} className='marginBoton'>
                        <br />
                        <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Seleccionar Listado de Productos</Button>
                    </Col>
                    
                </Row>    
                <ModalListadoInventarioFisicoGeneral  data = {{almacenes: almacenes, pisos: pisos, areas: areas, pasillos: pasillos, racks: racks}}/>        
            </Form>
            </Card>         
        );
    };
};

const mapStateToProps = state => {
    return {
        fetchingListaAlmacenesActivos: state.AlmacenesReducer.fetchingListaAlmacenesActivos,
        listaAlmacenesActivos: state.AlmacenesReducer.listaAlmacenesActivos,
        listaUbicacionesProductosBusqueda: state.AlmacenesReducer.listaUbicacionesProductosBusqueda,
        fetchingListaPisosByAlmacen: state.AlmacenesReducer.fetchingListaPisosByAlmacen,
        listaPisosByAlmacen: state.AlmacenesReducer.listaPisosByAlmacen,
        fetchingListaAreasByPisos: state.AlmacenesReducer.fetchingListaAreasByPisos,
        listaAreasByPisos: state.AlmacenesReducer.listaAreasByPisos,
        fetchingListaPasillosByAreas: state.AlmacenesReducer.fetchingListaPasillosByAreas,
        listaPasillosByAreas: state.AlmacenesReducer.listaPasillosByAreas,
        fetchingListaRacksByPasillos: state.AlmacenesReducer.fetchingListaRacksByPasillos,
        listaRacksByPasillos: state.AlmacenesReducer.listaRacksByPasillos,
        listaInvetarioFisicoBusqueda: state.AlmacenesReducer.listaInvetarioFisicoBusqueda,
        listaInvetarioFisicoBusquedaCantidad: state.AlmacenesReducer.listaInvetarioFisicoBusquedaCantidad,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        onBusquedaListadoInventario: (almacenes,pisos, areas, pasillos, racks, page) => {
            dispatch({ type: 'LISTA_INVENTARIO_FISICO_GENERAL_REQUEST', almacenes:almacenes, pisos:pisos, areas:areas, pasillos: pasillos, racks: racks, page: page});
        },
        onGetAlmacenes: () => {
            dispatch({ type: 'ALMACENES_ACTIVOS_BUSQUEDA_REQUEST' });
        }, 
        onGetPisosByAlmacenes: (idsAlmacenes) => {
            dispatch({ type:"LISTA_PISOS_BY_ALMACEN_REQUEST", idsAlmacenes: idsAlmacenes})
        },
        onGetAreasByPisos: (pisos) => {
            dispatch({ type:"LISTA_AREAS_BY_PISOS_REQUEST", pisos: pisos});
        },
        onGetPasillosByAreas: (areas) => {
            dispatch({ type:"LISTA_PASILLOS_BY_AREAS_REQUEST", areas: areas});
        },
        onGetRacksByPasillos: (pasillos) => {
            dispatch({ type:"LISTA_RACKS_BY_PASILLOS_REQUEST", pasillos: pasillos});
        },
        onGetUbicacionesProductos: (page,nombreAlmacen) => {
            dispatch({ type: "UBICACIONES_PRODUCTOS_BUSQUEDA_REQUEST", page: page,nombreAlmacen:nombreAlmacen });
        },
        onShowModalInventarioFisico: () => {
            dispatch({ type: 'SHOW_MODAL_LISTA_INVENTARIO_FISICO_GENERAL', showModalInventarioFisicoGeneral: true });
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListadoInventarioFisico);