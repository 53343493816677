import React from 'react';
import { Row, Col, Input, Table, Switch, Modal, Button, Tooltip } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import { PlusOutlined } from '@ant-design/icons';

import ModalNuevoModulo from './ModalNuevoModulo';
const { Search } = Input;
const confirm = Modal.confirm;
class CatalogoDeModulosSistema extends React.Component {
    componentDidMount(){
        this.props.onGetModulos(1);
    }
    state={
        page: 1,
        busqueda: null,
    }
    render() {
        const { onEstadoModulo, listaModulos, fetchingListaModulos, onGetModulos, onNuevoModulo } = this.props;
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        const onBuscarModulo = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            onGetModulos(1, busqueda);
        }
        console.log('listaModulos');
        console.log(listaModulos);
        const columns = [{
                title: 'Nombre módulo',
                dataIndex: 'nombre',
                key: 'nombre',
            },{
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.moduloActivoInactivo} onChange={() => handleDesactivar(record)}/>
                ),
            },
        ];
        const handleDesactivar = (key) => {
            console.log(key);
            const page = this.state.page;
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del módulo "'+ key.nombre +'"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoModulo(key.idModulo, page)
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onGetModulos(pagination.current, this.state.busqueda);
        }
        const nuevoModulo = () => {
            onNuevoModulo(this.state.page);
        }
        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search allowClear className='buscarModulos' placeholder="Ingresa el nombre del módulo" onSearch={value => onBuscarModulo(value)} style={{ width: '100%' }}/>
                    </Col>
                    {/* <Col span={5}>
                        <Button onClick={nuevoModulo} disabled={!permisosUsuario.crearModulo} className='nuevoUsuario'>Nuevo Módulo</Button>
                    </Col> */}

                    <Col span={1}>
                        <Tooltip title='Nuevo Módulo' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={nuevoModulo} disabled={!permisosUsuario.crearModulo} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="nombre" 
                            columns={columns}
                            dataSource={listaModulos}
                            loading={fetchingListaModulos}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: 15, simple: true, current: this.state.page}}
                        />
                    </Col>
                </Row>
                <ModalNuevoModulo/>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        catalogoSeleccionado: state.ConfiguracionYSeguridadReducer.catalogoSeleccionado,
        listaModulos: state.ConfiguracionYSeguridadReducer.listaModulos,
        fetchingListaModulos: state.ConfiguracionYSeguridadReducer.fetchingListaModulos,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetModulos: (page, busqueda) => {
	    	dispatch({ type: "GET_MODULOS_REQUEST", busqueda: busqueda, page: page });
        },
        onChangeCatalogo: (catalogoSeleccionado) => {
            dispatch({ type: "CAMBIAR_CATALOGO_SELECCIONADO" , catalogoSeleccionado: catalogoSeleccionado });
        },
        onEstadoModulo: (idModulo, page) => {
            dispatch({ type: 'ESTADO_MODULO_REQUEST', idModulo: idModulo, page: page })
        },
        onNuevoModulo: (page) => {
            dispatch({ type: 'SHOW_NUEVO_MODULO', showModalNuevoModulo: true, page: page });
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoDeModulosSistema);