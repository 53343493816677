import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, Select, DatePicker, Row, InputNumber, message, Upload, Typography } from 'antd';
import moment from 'moment';
import { UploadOutlined, FileImageFilled, FilePdfFilled } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Dragger } = Upload;
const { Text, Paragraph } = Typography;
const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 }, };
  
class ModalEditarServicio extends Component {
    formRef = React.createRef();
    state = {
        rangoVigencia: '',
        fechaFin: null,
        fechaInicio: null,
        fileImagen: false,
        filePDF: false,
        botonImagen: false,
        botonPDF: false,
        botonEditar: false,
        buenRango: false,
        imageList: [],
        fileList: [],
    }
    render() {
        const { fetchingEditarServicio, showModalEditarServicio, onEditarServicio, onShowModalEditarServicio,
            servicioSeleccionado, listaGruposServicios, fetchingListaGruposServicios, page, busqueda,
            fetchingListaContratosActivosIdServicio, listaContratosActivosIdServicio, listaInfoProyecto, limpiarFormulario, onLimpiarFormulario
        } = this.props;
        
        if(limpiarFormulario && limpiarFormulario === 'editarServicio'){
            console.log('entró a limpiar');
            this.formRef.current.resetFields();
            this.setState({
                filePDF: false,
                botonPDF: false,
                botonEditar: false,
            });
            onLimpiarFormulario();
        }

        const onFinish = formulario => {
            console.log('Success:', formulario);
            if(this.state.rangoVigencia){
                if(this.state.buenRango === true){
                    formulario.rangoVigencia = this.state.rangoVigencia[0].format('YYYY-MM-DD') + "," + this.state.rangoVigencia[1].format('YYYY-MM-DD');
                    onEditarServicio(formulario, servicioSeleccionado, listaGruposServicios, listaContratosActivosIdServicio, page, busqueda);
                    this.setState({
                        fileImagen: false,
                        botonImagen: false,
                        filePDF: false,
                        botonPDF: false,
                        imageList: [],
                        fileList: [],
                    });
                }
                else{
                    message.error('Favor de seleccionar una fecha correcta');
                }
            }
            else{
                formulario.rangoVigencia = servicioSeleccionado.fechaAltaPonderado.split('-')[2] + '-' + servicioSeleccionado.fechaAltaPonderado.split('-')[1] + '-' + servicioSeleccionado.fechaAltaPonderado.split('-')[0] + ',' + servicioSeleccionado.fechaFinalPonderador.split('-')[2] + '-' + servicioSeleccionado.fechaFinalPonderador.split('-')[1] + '-' + servicioSeleccionado.fechaFinalPonderador.split('-')[0];
                onEditarServicio(formulario, servicioSeleccionado, listaGruposServicios, listaContratosActivosIdServicio, page, busqueda);
                this.setState({
                    fileImagen: false,
                    botonImagen: false,
                    filePDF: false,
                    botonPDF: false,
                    imageList: [],
                    fileList: [],
                });
            }
                
        };
        

        const handleCancel = () => {
            onShowModalEditarServicio(page, busqueda);
            this.setState({
                fileImagen: false,
                botonImagen: false,
                filePDF: false,
                botonPDF: false,
                botonEditar: false,
                imageList: [],
                fileList: [],
            });
            this.formRef.current.resetFields();
        }

        const onChangeImagen = (info) => {
            console.log('info');
            console.log(info);

            let imageList = [...info.fileList];
   
            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            imageList = imageList.slice(-1);

            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonImagen: true,
                    botonEditar: true,
                    imageList,
                })
            }
            var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,30}$");
            if (info.file.status === 'done') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['imagen']);
                    this.setState({imageList: []});
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        fileImagen: true,
                        imageList,
                    });
                }
                this.setState({
                    botonImagen: false,
                    botonEditar: false,
                })
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['imagen']);
                    this.setState({imageList: []});
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        fileImagen: true,
                        imageList,
                    });
                }
                this.setState({
                    botonImagen: false,
                    botonEditar: false,
                })
            }
        };
        
        const onChangePDF = (info) => {
            let fileList = [...info.fileList];
   
            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            fileList = fileList.slice(-1);

            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                    fileList,
                })
            }
            var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
            if (info.file.status === 'done') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                    this.setState({fileList: []});
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true,
                        fileList,
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                    this.setState({fileList: []});
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true,
                        fileList,
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }
        const onRemoveImagen = () => {
            this.setState({
                fileImagen: false,
                botonImagen: false,
                imageList: [],
            });
        }
        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
                fileList: []
            });
        }
        const changePonderador = (value) => {
            const valorPonderador = parseFloat(value).toFixed(2)
            this.formRef.current.setFieldsValue({'valorPonderado': valorPonderador})
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };
        const fechas = (rangeValue) => {
            if( rangeValue) {
                if(listaInfoProyecto.fechaInicio){
                    const fechaInicio = listaInfoProyecto.fechaInicio.diff(moment(rangeValue[0].format('YYYY-MM-DD')), "days");
                    const fechaVigencia = moment([listaInfoProyecto.fechaVigencia.split('-')[2], (listaInfoProyecto.fechaVigencia.split('-')[1] - 1), listaInfoProyecto.fechaVigencia.split('-')[0]]).diff(moment(rangeValue[1].format('YYYY-MM-DD')), "days");
                    
                    if(fechaInicio>0){
                        message.error('No es posible seleccionar una fecha de inicio menor al inicio de contrato del proyecto', 7);
                        this.formRef.current.setFieldsValue({'rangoVigencia': null});
                        this.setState({
                            buenRango: false,
                        })
                    }
                    else if(fechaVigencia<0){
                        message.error('No es posible seleccionar una vigencia mayor al final de contrato del proyecto', 7);
                        this.formRef.current.setFieldsValue({'rangoVigencia': null});
                        this.setState({
                            buenRango: false,
                        })
                    }
                    else{
                        this.setState({
                            rangoVigencia: rangeValue,
                            buenRango: true,
                        })
                    }
                }
                else{
                    message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                    this.formRef.current.resetFields(['rangoVigencia']);
                }
            } else {
                message.warning('Favor seleccionar un rango de vigencia.')
            }
        }
        console.log('servicioSeleccionado');
        console.log(servicioSeleccionado);
        return (
            <Modal
            visible={showModalEditarServicio}
            title='Editar Servicio'
            onCancel={handleCancel}
            footer={false}
            width={'70%'}
            >
                <Spin spinning={fetchingEditarServicio}>
                    {servicioSeleccionado ? (
                    <Form {...layout}
                    name="formulario"
                    initialValues={servicioSeleccionado}
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Nombre"
                                    name="nombre"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={200} placeholder="Ingresa el nombre" title="No se permiten números ni caracteres especiales" pattern="^[a-zñA-ZÑ ÁÉÓÚÍáéóíú.,]+$"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Nombre corto"
                                    name="nombreC"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={80} placeholder="Ingresa el nombre corto" title="No se permiten números ni caracteres especiales" pattern="^[a-zñA-ZÑ ÁÉÓÚÍáéóíú.,]+$"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Grupo servicio"
                                    name="grupoServicio"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el grupo de servicio"
                                        loading={fetchingListaGruposServicios}
                                    >
                                        {listaGruposServicios.map(option => <Option defaultValue={servicioSeleccionado.grupoServicio} key={option.nombre}>{ option.nombre }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Valor ponderador"
                                    name="valorPonderado"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber min={0}
                                        max={100}
                                        style={{ width:'100%', textAlign: 'right'}}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                        onChange={changePonderador}
                                        step={0.05}/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Rango de vigencia"
                                    name="rangoVigencia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <RangePicker onChange={fechas} style={{width: '100%'}} format='DD-MM-YYYY' placeholder={["Fecha inicio","Fecha fin"]}/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Nomenclatura"
                                    name="nomenclatura"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={20} placeholder="Ingresa la nomenclatura" title="Sólo se permiten mayúsculas" pattern="^[A-ZÑ ÁÉÓÚÍ]+$"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Observaciones"
                                    name="observaciones"
                                >
                                    <Input maxLength={200} placeholder="Ingresa las observaciones" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Contrato"
                                    name="nombreContrato"
                                    rules={[{ required: true, message: 'Favor de seleccionar un valor', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona un contrato"
                                        loading={fetchingListaContratosActivosIdServicio}
                                        mode='multiple'
                                    >
                                        {listaContratosActivosIdServicio.map(option => <Option key={option.clave}>{ option.clave }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Imagen"
                                    name="imagen"
                                >
                                    {/* <Upload customRequest={dummyRequest} onChange={onChangeImagen} onRemove={onRemoveImagen} accept='.jpg, .jpeg, .ico, .png' multiple={false}>
                                        {this.state.fileImagen === false ? (
                                            <Button disabled={this.state.botonImagen} className='btnCargaArchivos'>
                                                <UploadOutlined /> Click para subir imagen
                                            </Button>
                                        ):false}
                                    </Upload> */}

                                    <Dragger fileList={this.state.listaImagen} customRequest={dummyRequest} onRemove={onRemoveImagen} onChange={onChangeImagen} accept='.jpg, .jpeg, .png' multiple={false} style={{borderRadius:'1em'}} fileList={this.state.imageList}>
                                        <Row justify='center'>
                                            <FileImageFilled className='iconTabPane'/>
                                            <Col span={24} style={{paddingTop:'1em'}}>
                                                <Paragraph>
                                                    <Text strong> Click o arrastra a ésta área el achivo: .jpg, .jpeg, .png </Text>
                                                </Paragraph>
                                            </Col>
                                        </Row>
                                    </Dragger>
                                    
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Procedimiento PDF"
                                    name="pdf"
                                >
                                    {/* <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                        {this.state.filePDF === false ? (
                                        <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                            <UploadOutlined /> Click para subir procedimiento PDF
                                        </Button>
                                        ):false}
                                    </Upload> */}
                                    <Dragger customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false} style={{borderRadius:'1em'}} fileList={this.state.fileList}>
                                        <Row justify='center'>
                                            <FilePdfFilled className='iconTabPane'/>
                                            <Col span={24} style={{paddingTop:'1em'}}>
                                                <Paragraph>
                                                    <Text strong> Click o arrastra a ésta área el procedimiento PDF </Text>
                                                </Paragraph>
                                            </Col>
                                        </Row>
                                    </Dragger>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <p style={{color: '#000000d9'}}>Imagen actual</p>
                                {servicioSeleccionado.urlImagen ? (
                                <img src={servicioSeleccionado.urlImagen} className='fotoServicio'/>
                                ):(
                                <p>No cuenta con una imagen al momento</p>
                                )}
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Procedimiento actual"
                                    name="procedimientoActual"
                                >
                                    {servicioSeleccionado.rutaManual ? (
                                    <a target='_blank' href={servicioSeleccionado.rutaManual}>{servicioSeleccionado.rutaManual}</a>
                                    ):(
                                    <p>No cuenta con un procedimiento al momento</p>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button disabled={this.state.botonEditar} className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Editar Servicio</Button>
                            </Col>
                        </Row>
                    </Form>
                    ):false}
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarServicio: state.CatalogosGeneralesReducer.fetchingEditarServicio,
        showModalEditarServicio: state.CatalogosGeneralesReducer.showModalEditarServicio,
        servicioSeleccionado: state.CatalogosGeneralesReducer.servicioSeleccionado,
        fetchingListaGruposServicios: state.CatalogosGeneralesReducer.fetchingListaGruposServicios,
        listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,
        fetchingListaContratosActivosIdServicio: state.InformacionOperativaReducer.fetchingListaContratosActivosIdServicio,
        listaContratosActivosIdServicio: state.InformacionOperativaReducer.listaContratosActivosIdServicio,
        page: state.CatalogosGeneralesReducer.page,
        busqueda: state.CatalogosGeneralesReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarServicio: (page, busqueda) => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_SERVICIO', showModalEditarServicio: false, servicioSeleccionado: null, page, busqueda})
        },
        onEditarServicio: ( formulario, servicioSeleccionado, listaGruposServicios, listaContratos, page, busqueda ) => {
            dispatch({ type: "EDITAR_SERVICIO_REQUEST" , formulario, servicioSeleccionado, listaGruposServicios, listaContratos, page, busqueda });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarServicio);