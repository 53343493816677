import React from 'react';
import { Card, Col, Row, Button, Form, Input, Progress, Divider, Tooltip, Select, Spin, Result, Statistic, Typography } from 'antd';
import { UserOutlined, RetweetOutlined, RightCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { Doughnut} from 'react-chartjs-2';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 }, color: 'white' };
const { Option } = Select;
const { Text } = Typography
class RDOContainer extends React.Component {
     formRef = React.createRef();
     componentDidMount(){ this.props.onGetGruposServicios(); this.props.onGetServicios(); this.props.onGetGerencias(); this.props.onGetDataSolicitudesServicio(); this.props.onGetDataServiciosPlanificados(); this.props.onGetDatosSP(); this.props.onRequestInfoProyecto(); this.props.onGetValorCalculo(); this.props.onGetDataServiciosPublicos(); };

     state = { idServicio:null, idGrupoSer:null, idPartida:null, idClasificacionGerencia:null, idServicioSP:null, idGrupoSerSP:null, idPartidaSP:null, idClasificacionGerenciaSP:null, colDer: 19,  colIzq: 5, maximizar: false }
 
     render() {
          const { fetchingListaGruposServicios, listaGruposServicios, fetchingListaServiciosActivosF, listaServiciosActivosF, fetchingListaClasificacionesActivos,listaClasificacionesActivos, fetchingDataRDOSolicitudes, infoRDO_SolictudesServicio, fetchingDataRDOPlanificados, infoRDO_ServiciosPlanificados, onGetDataSolicitudesServicio, onGetDataServiciosPlanificados, onGetPartidasByIdServicio, listaPartidasByServicio4Fallas, onGetDatosSP, } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
             permisosUsuario = JSON.parse(permisosUsuario);
          }

          const cambiarVista = (value) => {
               this.props.onCambiarVista(value);
          };

          const grupServicio = (value) => {
               if(value){
                    let idGrupoSer = parseInt(value.split('-'[0]));
                    onGetDataSolicitudesServicio(this.state.idServicio, idGrupoSer, this.state.idPartida, this.state.idClasificacionGerencia);
                    this.setState({ idGrupoSer });
               } else {
                    onGetDataSolicitudesServicio(this.state.idServicio, null, this.state.idPartida, this.state.idClasificacionGerencia);
                    this.setState({ idGrupoSer: null });
               }
          };

          const grupServicioSP = (value) => {
               if(value){
                    let idGrupoSerSP = parseInt(value.split('-'[0]));
                    onGetDataServiciosPlanificados(this.state.idServicioSP, idGrupoSerSP, this.state.idPartidaSP, this.state.idClasificacionGerenciaSP);
                    this.setState({ idGrupoSerSP });
                    onGetDatosSP(this.state.idServicioSP, idGrupoSerSP, this.state.idPartidaSP, this.state.idClasificacionGerenciaSP);
                    this.formRef.current.resetFields(['servicioSS', 'gerenciaSS', 'partidaSS']);
               } else {
                    onGetDataServiciosPlanificados(this.state.idServicioSP, null, this.state.idPartidaSP, this.state.idClasificacionGerenciaSP);
                    onGetDatosSP(this.state.idServicioSP, null, this.state.idPartidaSP, this.state.idClasificacionGerenciaSP);
                    this.setState({ idGrupoSerSP: null });
                    this.formRef.current.resetFields(['servicioSS', 'gerenciaSS', 'partidaSS']);
               }
          };

          const onServicioSeleccionado = (value) => {
               if(value){
                    let idServicio = parseInt(value.split('-'[0]));
                    onGetDataSolicitudesServicio(idServicio, this.state.idGrupoSer, this.state.idPartida, this.state.idClasificacionGerencia);
                    onGetPartidasByIdServicio(idServicio);
                    this.setState({ idServicio });
                    this.formRef.current.resetFields(['grupoServicioSS', 'gerenciaSS', 'partidaSS']);
               } else {
                    onGetDataSolicitudesServicio(null, this.state.idGrupoSer, this.state.idPartida, this.state.idClasificacionGerencia);
                    this.setState({ idServicio: null });
                    this.formRef.current.resetFields(['grupoServicioSS', 'gerenciaSS', 'partidaSS']);
               }
          };

          const onServicioSeleccionadoSP = (value) => {
               if(value){
                    let idServicioSP = parseInt(value.split('-'[0]));
                    onGetDataServiciosPlanificados(idServicioSP, this.state.idGrupoSerSP, this.state.idPartidaSP, this.state.idClasificacionGerenciaSP);
                    onGetPartidasByIdServicio(idServicioSP);
                    onGetDatosSP(idServicioSP, this.state.idGrupoSerSP, this.state.idPartidaSP, this.state.idClasificacionGerenciaSP);
                    this.setState({ idServicioSP });
                    this.formRef.current.resetFields(['grupoServicioSP', 'gerenciaSP', 'partidaSP']);
               } else {
                    onGetDataServiciosPlanificados(null, this.state.idGrupoSerSP, this.state.idPartidaSP, this.state.idClasificacionGerenciaSP);
                    onGetDatosSP(null, this.state.idGrupoSerSP, this.state.idPartidaSP, this.state.idClasificacionGerenciaSP);
                    this.setState({ idServicioSP: null });
                    this.formRef.current.resetFields(['grupoServicioSP', 'gerenciaSP', 'partidaSP']);
               }
          };

          const gerenciaSeleccionada = (value) => {
               if(value){
                    let idClasificacionGerencia = parseInt(value.split('-'[0]));
                    onGetDataSolicitudesServicio(this.state.idServicio, this.state.idGrupoSer, this.state.idPartida, idClasificacionGerencia);
                    this.setState({ idClasificacionGerencia });
               } else {
                    onGetDataSolicitudesServicio(this.state.idServicio, this.state.idGrupoSer, this.state.idPartida, null);
                    this.setState({ idClasificacionGerencia: null });
               }
          };

          const gerenciaSeleccionadaSP = (value) => {
               if(value){
                    let idClasificacionGerenciaSP = parseInt(value.split('-'[0]));
                    onGetDataServiciosPlanificados(this.state.idServicioSP, this.state.idGrupoSerSP, this.state.idPartidaSP, idClasificacionGerenciaSP);
                    onGetDatosSP(this.state.idServicioSP, this.state.idGrupoSerSP, this.state.idPartidaSP, idClasificacionGerenciaSP);
                    this.setState({ idClasificacionGerenciaSP });
               } else {
                    onGetDataServiciosPlanificados(this.state.idServicioSP, this.state.idGrupoSerSP, this.state.idPartidaSP, null);
                    onGetDatosSP(this.state.idServicioSP, this.state.idGrupoSerSP, this.state.idPartidaSP, null);
                    this.setState({ idClasificacionGerenciaSP: null });
               }
          };


          const partidaSeleccionada = (value) => {
               if(value){
                    let idPartida = parseInt(value.split('-'[0]));
                    onGetDataSolicitudesServicio(null, this.state.idGrupoSer, idPartida, this.state.idClasificacionGerencia);
                    this.setState({ idPartida });
               } else {
                    onGetDataSolicitudesServicio(this.state.idServicio, this.state.idGrupoSer, null, this.state.idClasificacionGerencia);
                    this.setState({ idPartida: null });
               }
          };

          const partidaSeleccionadaSP = (value) => {
               if(value){
                    let idPartidaSP = parseInt(value.split('-'[0]));
                    onGetDataServiciosPlanificados(null, this.state.idGrupoSerSP, idPartidaSP, this.state.idClasificacionGerenciaSP);
                    onGetDatosSP(null, this.state.idGrupoSerSP, idPartidaSP, this.state.idClasificacionGerenciaSP);
                    this.setState({ idPartidaSP });
               } else {
                    onGetDataServiciosPlanificados(this.state.idServicioSP, this.state.idGrupoSerSP, null, this.state.idClasificacionGerenciaSP);
                    onGetDatosSP(this.state.idServicioSP, this.state.idGrupoSerSP, null, this.state.idClasificacionGerenciaSP);
                    this.setState({ idPartidaSP: null });
               }
          };

          const onExpandir = () => {
               if(!this.state.maximizar){ this.setState({ colIzq: 0, colDer: 24, maximizar: true }); }
               else{ this.setState({  colIzq: 5, colDer: 19, maximizar: false }); }
          };

          return (
                    <Row>
                         <Col span={this.state.colIzq} style={{backgroundColor: '#737373', paddingTop: '1em', paddingBottom: '1em', borderTopLeftRadius:'1em', borderBottomLeftRadius:'1em'}}>
                              <Text className='textoBlanco' style={{textAlign: 'center', fontSize: 14, color: 'white', paddingTop: '.6em', paddingBottom: '.6em'}} strong>Filtro de Búsqueda</Text>
                              <Form name="formulario" ref={this.formRef} {...layout}  >
                                   <Row justify='space-around'>
                                        <Divider style={{color:'white'}}> SP </Divider>

                                        <Col span={22}>
                                             <Form.Item name='grupoServicioSP'>
                                                  <Select 
                                                       allowClear  style={{ width: '100%'}} placeholder="Selecciona un grupo de servicio" loading={fetchingListaGruposServicios} showSearch onChange={grupServicioSP} disabled={this.state.idClasificacionGerenciaSP ? true: (this.state.idServicioSP ? true: (this.state.idPartidaSP ? true: false))}
                                                  >
                                                       {listaGruposServicios.map(option => <Option key={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}><Tooltip title={option.nombre + ': ' + option.descripcion}>{option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}</Tooltip></Option>)}
                                                  </Select>
                                             </Form.Item>
                                        </Col>

                                        <Col span={22}>
                                             <Form.Item name='gerenciaSP'>
                                                  <Select
                                                       allowClear
                                                       style={{width: "100%"}}
                                                       placeholder='Selecciona una gerencia'
                                                       loading={fetchingListaClasificacionesActivos}
                                                       onChange={gerenciaSeleccionadaSP}
                                                       disabled={this.state.idGrupoSerSP ? true: (this.state.idServicioSP ? true: (this.state.idPartidaSP ? true: false))}
                                                  >
                                                       {listaClasificacionesActivos.map(option => (
                                                            <Option key={option.idClasificacionGerencia}>{option.idClasificacionGerencia + ' - ' + option.nombreGerencia}</Option>
                                                       ))}
                                                  </Select>
                                             </Form.Item>
                                        </Col>
                                        <Col span={22}>
                                             <Form.Item name='servicioSP'>
                                                  <Select allowClear  style={{ width: '100%'}} placeholder="Selecciona un servicio" loading={fetchingListaServiciosActivosF} showSearch onChange={onServicioSeleccionadoSP} disabled={this.state.idClasificacionGerenciaSP ? true: (this.state.idGrupoSerSP ? true: (this.state.idPartidaSP ? true: false))}
                                                  >
                                                       {listaServiciosActivosF.map(option => 
                                                       <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                            <Tooltip title={option.nomenclatura + ': ' + option.nombre}>
                                                                 {option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}
                                                            </Tooltip>
                                                       </Option>
                                                       )}
                                                  </Select>
                                             </Form.Item>
                                        </Col>

                                        <Col span={22}>
                                             <Form.Item name="partidaSP" >
                                                  { this.state.idServicioSP ? (<>
                                                       { listaPartidasByServicio4Fallas.length > 0 ? (
                                                            <Select 
                                                                 allowClear 
                                                                 style={{width: '100%'}}
                                                                 placeholder="Selecciona una partida"
                                                                 loading={this.props.fetchingListaPartidasByServicio4Fallas}
                                                                 showSearch
                                                                 onChange={partidaSeleccionadaSP} disabled={this.state.idClasificacionGerenciaSP ? true: (this.state.idGrupoSerSP ? true: false)}
                                                            >
                                                                 {listaPartidasByServicio4Fallas.map(option => 
                                                                      <Option key={option.idPartida + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                                           <Tooltip title={option.nomenclatura + ': ' + option.nombre}>
                                                                                {option.idPartida + ' - ' + option.nomenclatura + ': ' + option.nombre}
                                                                           </Tooltip>
                                                                      </Option>)}
                                                            </Select>
                                                       ):(
                                                            <Input style={{textAlign:'center'}} disabled placeholder='Servicio sin partidas' />
                                                       ) }
                                                  </>):(
                                                       <Select allowClear  style={{ width: '100%'}} placeholder="Selecciona una partida" loading={fetchingListaServiciosActivosF} showSearch onChange={partidaSeleccionadaSP} disabled={this.state.idClasificacionGerenciaSP ? true: (this.state.idGrupoSerSP ? true: false)}
                                                       >
                                                            {listaServiciosActivosF.map(option => 
                                                            <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                                 <Tooltip title={option.nomenclatura + ': ' + option.nombre}>
                                                                      {option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}
                                                                 </Tooltip>
                                                            </Option>
                                                            )}
                                                       </Select>
                                                  ) }
                                             </Form.Item>
                                             <br/><br/><br/><br/>
                                        </Col>

                                        <Divider style={{color:'white'}}> SS </Divider>
                                        <Col span={22}>
                                             <Form.Item name='grupoServicioSS'>
                                                  <Select 
                                                       allowClear  style={{ width: '100%'}} placeholder="Selecciona un grupo de servicio" loading={fetchingListaGruposServicios} showSearch onChange={grupServicio} disabled={this.state.idClasificacionGerencia ? true: (this.state.idServicio ? true: (this.state.idPartida ? true: false))}
                                                  >
                                                       {listaGruposServicios.map(option => <Option key={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}><Tooltip title={option.nombre + ': ' + option.descripcion}>{option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}</Tooltip></Option>)}
                                                  </Select>
                                             </Form.Item>
                                        </Col>

                                        <Col span={22}>
                                             <Form.Item name='gerenciaSS'>
                                                  <Select
                                                       allowClear
                                                       style={{width: "100%"}}
                                                       placeholder='Selecciona una gerencia'
                                                       loading={fetchingListaClasificacionesActivos}
                                                       onChange={gerenciaSeleccionada}
                                                       disabled={this.state.idGrupoSer ? true: (this.state.idServicio ? true: (this.state.idPartida ? true: false))}
                                                  >
                                                       {listaClasificacionesActivos.map(option => (
                                                            <Option key={option.idClasificacionGerencia}>{option.idClasificacionGerencia + ' - ' + option.nombreGerencia}</Option>
                                                       ))}
                                                  </Select>
                                             </Form.Item>
                                        </Col>

                                        <Col span={22}>
                                             <Form.Item name='servicioSS'>
                                                  <Select allowClear  style={{ width: '100%'}} placeholder="Selecciona un servicio" loading={fetchingListaServiciosActivosF} showSearch onChange={onServicioSeleccionado} disabled={this.state.idClasificacionGerencia ? true: (this.state.idGrupoSer ? true: (this.state.idPartida ? true: false))}
                                                  >
                                                       {listaServiciosActivosF.map(option => 
                                                       <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                            <Tooltip title={option.nomenclatura + ': ' + option.nombre}>
                                                                 {option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}
                                                            </Tooltip>
                                                       </Option>
                                                       )}
                                                  </Select>
                                             </Form.Item>
                                        </Col>

                                        <Col span={22}>
                                             <Form.Item name="partidaSS" >
                                                  { this.state.idServicio ? (<>
                                                       { listaPartidasByServicio4Fallas.length > 0 ? (
                                                            <Select 
                                                                 allowClear 
                                                                 style={{width: '100%'}}
                                                                 placeholder="Selecciona una partida"
                                                                 loading={this.props.fetchingListaPartidasByServicio4Fallas}
                                                                 showSearch
                                                                 onChange={partidaSeleccionada} disabled={this.state.idClasificacionGerencia ? true: (this.state.idGrupoSer ? true: false)}
                                                            >
                                                                 {listaPartidasByServicio4Fallas.map(option => 
                                                                      <Option key={option.idPartida + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                                           <Tooltip title={option.nomenclatura + ': ' + option.nombre}>
                                                                                {option.idPartida + ' - ' + option.nomenclatura + ': ' + option.nombre}
                                                                           </Tooltip>
                                                                      </Option>)}
                                                            </Select>
                                                       ):(
                                                            <Input style={{textAlign:'center'}} disabled placeholder='Servicio sin partidas' />
                                                       ) }
                                                  </>):(
                                                       <Select allowClear  style={{ width: '100%'}} placeholder="Selecciona una partida" loading={fetchingListaServiciosActivosF} showSearch onChange={partidaSeleccionada} disabled={this.state.idClasificacionGerencia ? true: (this.state.idGrupoSer ? true: false)}
                                                       >
                                                            {listaServiciosActivosF.map(option => 
                                                            <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                                 <Tooltip title={option.nomenclatura + ': ' + option.nombre}>
                                                                      {option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}
                                                                 </Tooltip>
                                                            </Option>
                                                            )}
                                                       </Select>
                                                  ) }
                                             </Form.Item>
                                        </Col>
                                   </Row>
                              </Form>
                         </Col>
                         <Col span={this.state.colDer} style={{backgroundColor: '#F2F2F2' }}>
                              <Row type="flex" align='middle' style={{ backgroundColor: '#E4E4E4', borderTopRightRadius:'1em' }}>
                                   <Col span={23} style={{backgroundColor: '#E4E4E4', paddingTop: '1em', paddingBottom: '1em', paddingLeft: '1em'}}>
                                        <Row justify='start' align='middle' gutter={[0,0]}>
                                             <Text style={{textAlign: 'left', fontSize: '2em', color: '#737373', borderLeftColor: '#E4E4E4', borderRightColor:'#737373', borderTopColor:'#E4E4E4', borderBottomColor:'#E4E4E4', borderStyle: 'solid', paddingRight: '.5em'}} strong>RDO </Text> 
                                             
                                             <Text style={{textAlign: 'left', fontSize: '2em', color: '#737373', paddingLeft: '0.5em'}}>Monitoreo de Módulos Operativos</Text>
                                        </Row>
                                   </Col>
                                   <Col span={1} style={{backgroundColor: '#E4E4E4', paddingTop: '1em', paddingBottom:'1em'}}>
                                        { this.state.maximizar ? (<> <RightCircleOutlined className='iconoMaxMin' onClick={onExpandir} /> </>):(<> <LeftCircleOutlined className='iconoMaxMin' onClick={onExpandir}/> </>) }
                                   </Col>
                              </Row>
                              <Row type="flex">
                                   <Col span={12} style={{backgroundColor: '#F2F2F2', paddingLeft: '1em', paddingTop:'1em', paddingBottom: '1em'}}>
                                        <Col span={23}>
                                             <Card className="cardCatalogos" bordered={false} 
                                             title={
                                                  <Row justify='space-around'> 
                                                  <p style={{textAlign:'left', color: '#0e2c82'}}><b> <Tooltip title='Servicios Planificados'>SP</Tooltip></b> | Solicitudes Programadas</p> 

                                                  {/* <Tooltip title='Seguimiento SP'> 
                                                       <a className='iconButton' disabled={!permisosUsuario.verServiciosPlanificados}><RetweetOutlined onClick={e => cambiarVista('Servicios planificados')} /> </a> 
                                                  </Tooltip>  */}
                                                  </Row>
                                             } style={{backgroundColor:'#F2F2F2'}}>
                                                  <Row justify='space-around'>
                                                       <Col span={24}>
                                                            <Spin spinning={fetchingDataRDOPlanificados}>
                                                                 { infoRDO_ServiciosPlanificados.total > 0 ? (
                                                                      <Doughnut data={{
                                                                           labels: infoRDO_ServiciosPlanificados.etiquetas, datasets: [{ data: infoRDO_ServiciosPlanificados.informacion,  backgroundColor: ['#d1dbaf', '#b1c379', '#6b7c37', '#e64d14', '#b08010', '#ce9613', '#efc050', '#f2cd75', '#f9e6ba'], borderColor: '#f8faf3',  borderWidth: 1 }],
                                                                      }} 
                                                                      options={{
                                                                           pieceLabel: { fontSize: 12, fontColor: '#FFF' },
                                                                           tooltips: {
                                                                                callbacks: {
                                                                                     title: function(tooltipItem, data) {
                                                                                          return data['labels'][tooltipItem[0]['index']];
                                                                                     },
                                                                                     label: function(tooltipItem, data) {
                                                                                          let dataset = data.datasets[tooltipItem.datasetIndex];
                                                                                          let currentValue = dataset.data[tooltipItem.index];
                                                                                          let percentage = (((currentValue * 100) / infoRDO_ServiciosPlanificados.total)).toFixed(2);
                                                                                          
                                                                                          return 'Servicios Planificados: ' +  data['datasets'][0]['data'][tooltipItem['index']] + ' | Porcentaje: ' + percentage + '%';
                                                                                     }
                                                                                },
                                                                                backgroundColor: '#FFF', titleFontSize: 14, titleFontColor: '#0e2c82', bodyFontColor: '#1747ca', bodyFontSize: 12, displayColors: false
                                                                           },
                                                                           hover: { animationDuration: 7 },
                                                                           legend: { position: "left", align: "center", labels: {boxWidth: 15},
                                                                           onHover: function (event, legendItem) {
                                                                                let chart = this.chart;
                                                                                let index = legendItem.index;
                                                                                let segment = chart.getDatasetMeta(0).data[index];
                                                                                chart.tooltip._active = [segment]
                                                                                chart.tooltip.update()
                                                                                chart.draw()
                                                                           },
                                                                           onLeave: function (event, legendItem) {
                                                                                let chart = this.chart;
                                                                                let index = legendItem.index;
                                                                                chart.tooltip._active = []
                                                                                chart.tooltip.update()
                                                                                chart.draw()
                                                                           } },
                                                                      }} />
                                                                 ):(
                                                                      <Result
                                                                           status="info"
                                                                           subTitle="Lo siento, no existe información para mostrar con el filtro seleccionado."
                                                                      />
                                                                 ) }
                                                            </Spin>
                                                       </Col>
                                                  </Row>
                                             </Card>
                                        </Col>
                                        <br />
                                        <Col span={23}>
                                             <Card className="cardCatalogos" bordered={false} 
                                             title={ 
                                                  <Row justify='space-around'> <p style={{textAlign:'left', color: '#0e2c82'}}><b> <Tooltip title='Solicitud de Servicios'>SS</Tooltip></b> | Solicitudes de Incidencias</p> 
                                                  
                                                  {/* <Tooltip title='Seguimiento SS'> <a className='iconButton' disabled={!permisosUsuario.verSolicitudDeServicios}><RetweetOutlined onClick={e => cambiarVista('Solicitud de servicios')} /> </a> </Tooltip>  */}
                                                  </Row> 
                                             } style={{backgroundColor:'#F2F2F2'}}>
                                                  <Row justify='space-around'>
                                                       <Col span={24}>
                                                            <Spin spinning={fetchingDataRDOSolicitudes}>
                                                                 { infoRDO_SolictudesServicio.total > 0 ? (
                                                                      <Doughnut data={{
                                                                           labels: infoRDO_SolictudesServicio.etiquetas, datasets: [{ data: infoRDO_SolictudesServicio.informacion,  backgroundColor: ['#d1dbaf', '#b1c379', '#6b7c37', '#e64d14', '#936b0e','#b08010', '#ce9613', '#e9ab18', '#efc050', '#f2cd75', '#f9e6ba'], borderColor: '#f8faf3',  borderWidth: 1 }],
                                                                      }} 
                                                                      options={{
                                                                           pieceLabel: { fontSize: 12, fontColor: '#FFF' },
                                                                           tooltips: {
                                                                                callbacks: {
                                                                                     title: function(tooltipItem, data) {
                                                                                          return data['labels'][tooltipItem[0]['index']];
                                                                                     },
                                                                                     label: function(tooltipItem, data) {
                                                                                          let dataset = data.datasets[tooltipItem.datasetIndex];
                                                                                          let currentValue = dataset.data[tooltipItem.index];
                                                                                          let percentage = (((currentValue * 100) / infoRDO_SolictudesServicio.total)).toFixed(2);
                                                                                          
                                                                                          return 'Solicitudes de Servicio: ' +  data['datasets'][0]['data'][tooltipItem['index']] + ' | Porcentaje: ' + percentage + '%';
                                                                                     }
                                                                                },
                                                                                backgroundColor: '#FFF', titleFontSize: 14, titleFontColor: '#0e2c82', bodyFontColor: '#1747ca', bodyFontSize: 12, displayColors: false
                                                                           },
                                                                           hover: { animationDuration: 7 },
                                                                           legend: { position: "left", align: "center", labels: {boxWidth: 15},
                                                                                onHover: function (event, legendItem) {
                                                                                     let chart = this.chart;
                                                                                     let index = legendItem.index;
                                                                                     let segment = chart.getDatasetMeta(0).data[index];
                                                                                     chart.tooltip._active = [segment]
                                                                                     chart.tooltip.update()
                                                                                     chart.draw()
                                                                                },
                                                                                onLeave: function (event, legendItem) {
                                                                                     let chart = this.chart;
                                                                                     let index = legendItem.index;
                                                                                     chart.tooltip._active = []
                                                                                     chart.tooltip.update()
                                                                                     chart.draw()
                                                                                }
                                                                           },
                                                                      }} />
                                                                 ):(
                                                                      <Result
                                                                           status="info"
                                                                           subTitle="Lo siento, no existe información para mostrar con el filtro seleccionado."
                                                                      />
                                                                 ) }
                                                            </Spin>
                                                       </Col>
                                                  </Row>
                                             </Card>
                                        </Col>
                                   </Col>
                                   <Col span={12} style={{backgroundColor: '#F2F2F2', paddingTop:'1em'}}>
                                        <Col span={23}>
                                                  <Row justify='space-around' gutter={[0,8]}>
                                                       <Col span={12}>
                                                            <Spin spinning={fetchingDataRDOPlanificados}>
                                                                 { this.props.monitorModulosPlanificadosDatos[0] ? (<>
                                                                      <Row justify='space-around' type='flex' align='middle' gutter={[0,8]}>
                                                                           <Col span={23}>
                                                                                <Card className="cardCatalogos" bordered={false} style={{backgroundColor:'#E4E4E4'}}>
                                                                                     <Statistic
                                                                                          title={'SP totales'}
                                                                                          value={this.props.monitorModulosPlanificadosDatos[0].cantidad}
                                                                                          precision={0}
                                                                                          valueStyle={{ color: '#0e2c82' }}
                                                                                     />
                                                                                </Card>
                                                                           </Col>
                                                                           <Col span={23}>
                                                                                <Card className="cardCatalogos" bordered={false} style={{backgroundColor:'#E4E4E4'}}>
                                                                                     <Statistic
                                                                                          title={(this.props.monitorModulosPlanificadosDatos[1].etiqueta)}
                                                                                          value={this.props.monitorModulosPlanificadosDatos[1].cantidad}
                                                                                          precision={0}
                                                                                          valueStyle={{ color: '#0e2c82' }}
                                                                                     />
                                                                                </Card>
                                                                           </Col>
                                                                      </Row>
                                                                 </>):false }
                                                            </Spin>
                                                       </Col>
                                                       <Col span={12}>
                                                            <Spin spinning={fetchingDataRDOPlanificados}>
                                                                 { this.props.monitorModulosPlanificadosDatos[0] ? (<>
                                                                      <Row justify='space-around' align='middle' gutter={[0,8]}>
                                                                           <Col span={23}>
                                                                                <Card className="cardCatalogos" bordered={false} style={{backgroundColor:'#E4E4E4'}}>
                                                                                     <Statistic
                                                                                          title='SP en proceso'
                                                                                          value={infoRDO_ServiciosPlanificados.enProceso}
                                                                                          precision={0}
                                                                                          valueStyle={{ color: '#0e2c82' }}
                                                                                     />
                                                                                </Card>
                                                                           </Col>
                                                                           <Col span={23}>
                                                                                <Card className="cardCatalogos" bordered={false} style={{backgroundColor:'#E4E4E4'}}>
                                                                                     <Statistic
                                                                                          title={(this.props.monitorModulosPlanificadosDatos[2].etiqueta)}
                                                                                          value={this.props.monitorModulosPlanificadosDatos[2].cantidad}
                                                                                          precision={0}
                                                                                          valueStyle={{ color: '#0e2c82' }}
                                                                                     />
                                                                                </Card>
                                                                           </Col>
                                                                      </Row>
                                                                 </>):false }
                                                            </Spin>
                                                       </Col>
                                                  </Row>
                                                  <Row justify='space-around'>
                                                       <Col span={23}>
                                                       <Card className="cardCatalogos" bordered={false} style={{backgroundColor:'#E4E4E4'}}>
                                                            <Row type="flex" justify='center' align='middle' gutter={[0,8]}>
                                                                 <Col span={4}>
                                                                      <UserOutlined style={{fontSize:'3em', color: '#0e2c82'}}/>
                                                                 </Col>
                                                                 { console.log('this.props.listaInfoProyecto', this.props.listaInfoProyecto) }
                                                                 { console.log('this.props.valorCalculoTipoDeductiva', this.props.valorCalculoTipoDeductiva) }
                                                                 { this.props.valorCalculoTipoDeductiva.pti? (
                                                                      <Col span={8}>
                                                                           <Spin spinning={this.props.fetchingValorCalculoTipoDeductiva}>
                                                                                <Tooltip title='Promedio de PPL’s para el mes en curso'>
                                                                                     <div style={{color: '#0e2c82', fontSize:'1.6em', textAlign: 'center'}}>{ this.props.valorCalculoTipoDeductiva.pti.toFixed(2) }</div>
                                                                                </Tooltip>
                                                                           </Spin>
                                                                      </Col>
                                                                 ):(
                                                                      <Col span={8}>
                                                                           <Spin spinning={this.props.fetchingValorCalculoTipoDeductiva}>
                                                                                <Tooltip title='No hay información registrada para el mes en curso'>
                                                                                     <div style={{color: '#0e2c82', fontSize:'1.6em', textAlign: 'center'}}> - </div>
                                                                                </Tooltip>
                                                                           </Spin>
                                                                      </Col>
                                                                 ) }
                                                                 <Col span={1}>
                                                                     <div style={{fontSize:'3em', color: '#0e2c82'}}> | </div>
                                                                 </Col>
                                                                 { this.props.listaInfoProyecto.poblacionPtvMax ? (
                                                                      <Col span={11}>
                                                                           <Spin spinning={this.props.fetchingListaInfoProyecto}>
                                                                                <Tooltip title='Máximo por contrato'>
                                                                                     <div style={{color: '#0e2c82', fontSize:'1.6em', textAlign: 'center'}}> { this.props.listaInfoProyecto.poblacionPtvMax } PPL</div>
                                                                                </Tooltip>
                                                                           </Spin>
                                                                      </Col>
                                                                 ):false }
                                                            </Row>
                                                            </Card>
                                                       </Col>
                                                  </Row>
                                             {/* </Card> */}
                                        </Col>

                                        <br/>

                                        <Col span={23}>
                                             <Card className="cardCatalogos" bordered={false} title={
                                             <Row justify='space-around'> <p style={{textAlign:'left', color: '#0e2c82'}}><b> Energéticos</b></p> 
                                             
                                             {/* <Tooltip title='Seguimiento Servicios Públicos'> <a className='iconButton' disabled={!permisosUsuario.verServiciosPublicos}><RetweetOutlined onClick={e => cambiarVista('Servicios Públicos')} /> </a> </Tooltip>  */}
                                             </Row>
                                             } style={{backgroundColor:'#F2F2F2'}}>
                                                  {this.props.dataRDOServiciosPublicos.map(data => 
                                                       <Row justify='space-around'>
                                                            <Col span={6}>
                                                                 <Spin spinning={this.props.fertchingDataRDOServiciosPublicos}>
                                                                      <p style={{fontSize:'12px'}}>{data.etiqueta}</p>
                                                                 </Spin>
                                                            </Col>
                                                            <Col span={18}>
                                                                 
                                                                 <Spin spinning={this.props.fertchingDataRDOServiciosPublicos}>
                                                                      <Progress percent={Math.round(data.cantidad)} steps={ !this.state.maximizar ? 20 : 100} size={ this.state.maximizar ? 'small' : 'default' } strokeColor={ Math.round(data.cantidad) < 40 ? '#ff0000' : (Math.round(data.cantidad) < 70 ? '#0e2c82' : '#6b7c37') }/>
                                                                 </Spin>
                                                                 
                                                            </Col>
                                                       </Row>
                                                  )}
                                             </Card>
                                        </Col>
                                        <br/>
                                   </Col>
                              </Row>
                              
                         </Col>
                         
                    </Row>
          );
     }
}
 
 const mapStateToProps = state => {
     return {
          listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,
          fetchingListaGruposServicios: state.CatalogosGeneralesReducer.fetchingListaGruposServicios,

          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,

          fetchingListaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.fetchingListaClasificacionesActivos,
          listaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.listaClasificacionesActivos,

          listaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.listaPartidasByServicio4Fallas,
          fetchingListaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.fetchingListaPartidasByServicio4Fallas,
          
          dataRDOSolicitudes: state.MonitorIntegralReducer.dataRDOSolicitudes,
          fetchingDataRDOSolicitudes: state.MonitorIntegralReducer.fetchingDataRDOSolicitudes,
          infoRDO_SolictudesServicio: state.MonitorIntegralReducer.infoRDO_SolictudesServicio,

          fetchingDataRDOPlanificados: state.MonitorIntegralReducer.fetchingDataRDOPlanificados,
          infoRDO_ServiciosPlanificados: state.MonitorIntegralReducer.infoRDO_ServiciosPlanificados,
          dataRDOPlanificados: state.MonitorIntegralReducer.dataRDOPlanificados,
          monitorModulosPlanificadosDatos: state.MonitorIntegralReducer.monitorModulosPlanificadosDatos,

          listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
          fetchingListaInfoProyecto: state.InformacionDelProyectoReducer.fetchingListaInfoProyecto,

          valorCalculoTipoDeductiva: state.MecanismosDePagosReducer.valorCalculoTipoDeductiva,
          fetchingValorCalculoTipoDeductiva: state.MecanismosDePagosReducer.fetchingValorCalculoTipoDeductiva,

          fertchingDataRDOServiciosPublicos: state.MonitorIntegralReducer.fertchingDataRDOServiciosPublicos,
          dataRDOServiciosPublicos: state.MonitorIntegralReducer.dataRDOServiciosPublicos
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          onCambiarVista: (vistaDashboard) => {
               dispatch({ type: "CAMBIAR_VISTA_DASHBOARD" , vistaDashboard:vistaDashboard});
          },
          onGetGruposServicios: () => {
               dispatch({ type: "LISTA_GRUPOS_SERVICIOS_REQUEST" });
          },
          onGetServicios: () => {
               dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
          },
          onGetServiciosLimpiar: () => {
               dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_SUCCESS", listaServiciosActivosF: [] });
          },
          onGetGerencias: () => {
               dispatch({ type: 'LISTA_CLASIFICACION_ACTIVOS_REQUEST'})
          },
          onGetPartidasByIdServicio: (idServicio) => {
               dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_REQUEST', idServicio})
          },
          onGetDataServiciosPublicos: () => {
               dispatch({ type: 'DATA_SERV_PUBLIC_REQUEST' });
          },

          onGetDataSolicitudesServicio: (idServicio, idGrupoSer, idPartida, idClasificacionGerencia) => {
               dispatch({ type: 'MONITOR_MODULOS_OPERATIVOS_SS_REQUEST', idServicio, idGrupoSer, idPartida, idClasificacionGerencia });
          },
          onGetDataServiciosPlanificados: (idServicio, idGrupoSer, idPartida, idClasificacionGerencia) => {
               dispatch({ type: 'MONITOR_MODULOS_OPERATIVOS_SP_REQUEST', idServicio, idGrupoSer, idPartida, idClasificacionGerencia })
          },
          onGetDatosSP: (idServicio, idGrupoSer, idPartida, idClasificacionGerencia) => {
               dispatch({ type: 'MONITOR_MODULOS_PLANIFICADOS_DATOS_REQUEST', idServicio, idGrupoSer, idPartida, idClasificacionGerencia });
          },
          onRequestInfoProyecto: () => {
               dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
          },
          onGetValorCalculo: () => {
               dispatch({ type: 'VALOR_TIPO_CALCULO_DEDUCTIVA_REQUEST',  });
          },
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(RDOContainer);