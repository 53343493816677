import React from 'react';
import {connect} from 'react-redux';
import {Table, Row, Col, Modal} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

class ModalDetalleCalculosInterno extends React.Component {
    state = {
        page: 1,
     }
    render() {
        const {showModalDetalleCalculosInterno, listaDetalleCalculosInterno, fetchingListaDetalleCalculosInterno, servicioPublicoSeleccionadoCCDInterno, listaDetalleCalculosInternoCantidad,
            onShowModalDetalle, onGetDetalleCalculosInterno, onLimpiarDetalle} = this.props;

        const columns = [{
            title: 'Servicio público',
            dataIndex: 'servicioPublico',
            key: 'servicioPublico',
        }, {
            title: 'Folio',
            dataIndex: 'folio',
            key: 'folio',
        }, {
            title: 'Tipo',
            dataIndex: 'tipo',
            key: 'tipo',
        }, {
            title: 'Consumo',
            dataIndex: 'total',
            key: 'total',
            align: 'right',
        }, {
            title: 'Precio unitario',
            dataIndex: 'precioUnitario',
            key: 'precioUnitario',
            align: 'right',
        }, {
            title: 'Cobro por consumo',
            dataIndex: 'cobroConsumo',
            key: 'cobroConsumo',
            align: 'right',
        }];

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            onGetDetalleCalculosInterno(servicioPublicoSeleccionadoCCDInterno, pagination.current);
        }

        const handleCancel = () => {
            onShowModalDetalle();
            onLimpiarDetalle();
        }
        return (
            <div>
                {servicioPublicoSeleccionadoCCDInterno ? (
                    <Modal
                        visible={showModalDetalleCalculosInterno}
                        title={`Cálculos de Consumo Diferenciado Interno`}
                        onCancel={handleCancel}
                        footer={false}
                        width='70%'
                    >
                        <Row justify='space-between' >
                            <Col span={24}>
                                <br />
                                <Table
                                    size="small"
                                    rowKey="idConsumoDiferenciado"
                                    columns={columns}
                                    dataSource={listaDetalleCalculosInterno}
                                    loading={fetchingListaDetalleCalculosInterno}
                                    locale={{ emptyText: 'Sin datos' }}
                                    onChange={handleTableChange}
                                    pagination={{ total: listaDetalleCalculosInternoCantidad, simple: true, current: this.state.page }}
                                />
                            </Col>
                        </Row>
                        

                    </Modal>
                ) : false}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalDetalleCalculosInterno: state.ServiciosPublicosReducer.showModalDetalleCalculosInterno,
        servicioPublicoSeleccionadoCCDInterno: state.ServiciosPublicosReducer.servicioPublicoSeleccionadoCCDInterno,
        listaDetalleCalculosInterno: state.ServiciosPublicosReducer.listaDetalleCalculosInterno,
        fetchingListaDetalleCalculosInterno: state.ServiciosPublicosReducer.fetchingListaDetalleCalculosInterno,
        listaDetalleCalculosInternoCantidad: state.ServiciosPublicosReducer.listaDetalleCalculosInternoCantidad,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onShowModalDetalle : () => {
            dispatch({ type: 'SHOW_MODAL_DETALLE_CCD_INTERNO', showModalDetalleCalculos: false, servicioPublicoSeleccionadoCCDInterno: null })
        },
        onGetDetalleCalculosInterno: (servicioPublicoSeleccionadoCCDInterno, page) => {
            dispatch({ type: 'LISTA_DETALLE_CCD_INTERNO_REQUEST', servicioPublicoSeleccionadoCCDInterno: servicioPublicoSeleccionadoCCDInterno, page:page})
       },
       onLimpiarDetalle: () => {
           dispatch({ type: 'LISTA_DETALLE_CCD_INTERNO_SUCCESS', listaDetalleCalculosInterno: [], listaDetalleCalculosInternoCantidad: null  })
       }
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalDetalleCalculosInterno);