import React, { Component } from 'react';
import { Form, Input, Button, Col, Spin, Modal, DatePicker, Row } from 'antd';
import { connect } from 'react-redux';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';


const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalNuevoINPC0 extends Component {
    formRef = React.createRef();

    render() {

        const { showModalNuevoINPC0, onCerrarModal, fetchingCrearINCP0, page,
                onLimpiarFormulario, limpiarFormulario, onCrearINPC0
        } = this.props;

        const onFinish = formulario => {
            console.log('formulario:', formulario);
            onCrearINPC0(formulario,  page);
        };

        if (limpiarFormulario && limpiarFormulario==='crearINPC0') {
            this.formRef.current.resetFields();
            onLimpiarFormulario();
        }

        const handleCancel = () => {
            onCerrarModal();
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo);
        };


        return (
            <Modal
                visible={showModalNuevoINPC0}
                title='Nuevo INCP0'
                onCancel={handleCancel}
                footer={false}
                width='40%'
            >
                <Spin spinning={fetchingCrearINCP0}>

                    <Form {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        
                        ref={this.formRef}
                    >
                        <Row type='flex' justify='space-around'>
                            <Col span={24}>
                                <Form.Item
                                    label="Valor INPC0"
                                    name="inpc0"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Input
                                        pattern="^^[.0-9]+$"
                                        title="Nombre deben ser solo numeros."
                                        maxLength={100} placeholder='Ingrese Valor INPC0' />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    label="Fecha Publicación de Valor para INPC0"
                                    name="fechaPublicacion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        format='DD-MM-YYYY'
                                        placeholder={["Seleccione Fecha Publicación"]}

                                    />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    label="Fuente de Publicación de Valor para INPC0"
                                    name="fuente"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Input maxLength={100} placeholder='Ingrese Fuente de Publicación' />
                                </Form.Item>
                            </Col>


                            <Col span={24}>
                                <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Crear INPC0</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        showModalNuevoINPC0: state.MecanismosDePagosReducer.showModalNuevoINPC0,
        fetchingCrearINCP0: state.MecanismosDePagosReducer.fetchingCrearINCP0,
        page: state.MecanismosDePagosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario, 
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCerrarModal: () => {
            dispatch({ type: "SHOW_MODAL_CREAR_INPC0", showModalNuevoINPC0: false })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        },
        onCrearINPC0: (formulario,  page ) => {
            dispatch({ type: 'CREAR_INPC0_REQUEST', formulario , page })
        }  
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoINPC0);