import React, { Component } from 'react';
import { Form, Input, Button, Col, Spin, Select ,Row, Divider, message, Tooltip, DatePicker} from 'antd';
import { connect } from 'react-redux';
import { ArrowRightOutlined} from '@ant-design/icons';
import moment from 'moment';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Option } = Select;

class ModalNuevoFolio extends Component {
    formRef = React.createRef();
    state = {
        folioOrigen: '',
        suma: false,
        resta: false,
        ambas: false,
        selectedOrigen: true,
        selectedDestino: true,
        caracteristica: true,
        idAlmacenOrigen: ''
    }
    componentDidMount(){
        this.props.onGetTiposFolio();
        this.props.onGetAlmacenes();
        this.props.onRequestInfoProyecto();
    }
    render() {
        const {
            fetchingNuevoFolio,
            listaTiposFoliosMovimientosActivos,
            fetchingListaTiposFoliosMovimientosActivos,
            listaAlmacenesActivos,
            fetchingListaAlmacenesActivos, onGetInventario, listaInfoProyecto
        } = this.props;

        let userInfo = sessionStorage.getItem('userInfo');
        if(!userInfo.idUsuario){
            userInfo = JSON.parse(userInfo);
        }
    
        const onFinish = formulario => {
           console.log('formulario', formulario);
           let op = formulario.idTipoFolio.split('-')[1]; // operacion pa validar consultas
           let requiereFolio = formulario.idTipoFolio.split('-')[2]; // requiere folio, consulta lista requisicion dir
           let folioOrigen = formulario.folioOrigen;
            
            if(op === "Suma"){
                if(requiereFolio === "true"){
                        console.log('entro a requisicion');
                        this.props.onGetRequisicion(1, folioOrigen);
                } else {
                        console.log('entro a lista de productos')
                        this.props.onGetProductos(1)
                }
            } else if(op === "Ambas"){
                if(formulario.idAlmacenOrigen.split('-')[0] !== formulario.idAlmacenDestino.split('-')[0]){
                    console.log(' entro a lista de inventario')
                    onGetInventario(1, this.state.idAlmacenOrigen); // obtener inventario por almacen
                } else {
                   return message.error('Elegir almaces distintos');
                }
            } else if(op === "Resta"){
                if(requiereFolio === "true"){
                    console.log('entro a resta productos con folio');
                    this.props.onGetRequisicion(1, folioOrigen);
                } else {
                    for(let i in listaTiposFoliosMovimientosActivos){
                        if(parseInt(formulario.idTipoFolio.split('-')[0]) === listaTiposFoliosMovimientosActivos[i].idTipoFolio){
                            if(listaTiposFoliosMovimientosActivos[i].nombre === 'Salida por instalación'){
                                this.props.onGetSubfamilias();
                                this.props.onGetInventarioTipoActivos(1, this.state.idAlmacenOrigen, true);
                                return this.props.onCambiarVista(2, formulario, true); 
                            }
                            if(listaTiposFoliosMovimientosActivos[i].nombre === 'Salida por solicitud de materiales'){
                                this.props.onGetListaFoliosRequisicionMateriales(1, {busqueda: '', estado: 'Autorizado,Parcialmente surtida', idEncargado: '',}, {field: 'idRequisicionMateriales', order: 'DESC',}, userInfo[0].idUsuario);
                                return this.props.onCambiarVista(4, formulario, false); 
                            }
                        }
                    }
                    onGetInventario(1, this.state.idAlmacenOrigen); // obtener inventario por almacen
                }
            }
            this.props.onCambiarVista(2, formulario);   
        };

        const onOperacionFolio = (value) => {
            let operacion = value.split('-')[1];
            this.setState({
                selectedOrigen: true,
                selectedDestino: true,
            })

            if(value.split('-')[2] === 'true'){
                this.setState({
                    folioOrigen: 'Si',
                    suma: true
                })
            } else {
                this.setState({
                    folioOrigen: 'No',
                    suma: false,
                })
            }
            
            if (operacion === "Suma") {
                this.setState({
                    selectedDestino: false,
                    resta: false,
                    ambas: false,
                })
            } else if (operacion === "Resta") {
                this.setState({
                    resta: true,
                    selectedOrigen: false,
                    ambas: false,
                })
            } else if (operacion === "Ambas") {
                this.setState({
                    resta: false,
                    suma: false,
                    ambas: true,
                    selectedOrigen: false,
                    selectedDestino: false,
                })
            }
        }

        const onAlmacenOrigen = value => {
            console.log(value.split('-')[0])
            let idAlmacenOrigenValue = value.split('-')[0]
            this.setState({
                idAlmacenOrigen: idAlmacenOrigenValue
            })
            console.log(this.state.idAlmacenOrigen)
        }

        const  disabledDate = (fecha) => {
            if( fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicioOperacion) {
                return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicioOperacion;
            } else {
                if(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos){
                    return fecha && moment(fecha.format('YYYY-MM-DD')) > moment((listaInfoProyecto.vigencia +  parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2])) + '-' +listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[1] + '-' + listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[0]);
                }
            }
        }

        return (
            <Spin spinning={fetchingNuevoFolio}>
                <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    ref={this.formRef}
                >
                    <Row type='flex' justify='space-around'>
                        <Col span={23}>
                            <Form.Item
                                label="Tipo de Folio"
                                name="idTipoFolio"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Seleccione el tipo de folio"
                                    loading={fetchingListaTiposFoliosMovimientosActivos}
                                    onChange={onOperacionFolio}
                                >
                                    {listaTiposFoliosMovimientosActivos.map(option => <Option key={option.idTipoFolio + "-" + option.operacion + "-" + option.folioOrigen}>{ option.nombre }</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={23}>
                            <Form.Item
                                label="Documento Origen"
                                name="documentoOrigen"
                            >
                                 <Input maxLength={60} placeholder="Ingrese Documento Origen"
                                            title="Documento Origen debe contener sólo letras y números" pattern="^[a-zñA-ZÑ0-9 ÁÉÓÚÍáéóíú /-]+$" />
                            </Form.Item>
                        </Col>
                    
                        { this.state.suma ? (
                        <Col span={23}>
                            <Form.Item
                            label="Folio o Numero de Requisicion Directa"
                            name="folioOrigen"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Input placeholder="Folio o Numero de Requisicion Directa " />
                            </Form.Item>
                        </Col>
                        ): false}

                        <Col span={22}>
                            <Divider orientation="left">Asignar Almacenes</Divider>
                        </Col>

                        <Col span={1} style={{ paddingTop:'1em' }}>
                            <Tooltip title='Siguiente Paso'>
                                <Button className="iconTer" shape="circle"icon={<ArrowRightOutlined />} htmlType="submit"/>
                            </Tooltip>
                        </Col>
                           
                        {!this.state.selectedOrigen ? (<>
                        <Col span={11}>
                            <Form.Item
                                label="Almacen Origen"
                                name="idAlmacenOrigen"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Seleccione Origen"
                                        loading={fetchingListaAlmacenesActivos}
                                        onChange={onAlmacenOrigen}
                                >
                                    {listaAlmacenesActivos.map(option => <Option key={option.idAlmacen + "-" + option.caracteristica}>{ option.nombreC }</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        </>): false}
                        
                        {!this.state.selectedDestino ? (<>
                        <Col span={11}>
                            <Form.Item
                                label="Almacen Destino"
                                name="idAlmacenDestino"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Seleccione Destino"
                                        loading={fetchingListaAlmacenesActivos}
                                >
                                    {listaAlmacenesActivos.map(option => <Option key={option.idAlmacen + "-" + option.caracteristica}>{ option.nombreC }</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                            
                        </>): false}

                        {!this.state.selectedDestino || !this.state.selectedOrigen ? 
                        <Col span={11}>
                            <Form.Item
                                label="Fecha Real del Registro"
                                name="fechaMovimiento"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    format='DD-MM-YYYY'
                                    disabledDate={disabledDate}
                                />
                            </Form.Item>
                        </Col>
                        : false }
                        
                         {/* <Col span={23}>
                              <Button htmlType="submit" className='nuevoUsuario'>Siguiente Paso</Button>
                          </Col> */}
                    </Row>
                </Form>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingNuevoFolio: state.AlmacenesReducer.fetchingNuevoFolio,
        fetchingListaTiposFoliosMovimientosActivos: state.AlmacenesReducer.fetchingListaTiposFoliosMovimientosActivos,
        listaTiposFoliosMovimientosActivos: state.AlmacenesReducer.listaTiposFoliosMovimientosActivos,
        listaAlmacenesActivos: state.AlmacenesReducer.listaAlmacenesActivos,
        fetchingListaAlmacenesActivos: state.AlmacenesReducer.fetchingListaAlmacenesActivos,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onGetTiposFolio: () => {
            dispatch({ type: "LISTA_TIPOS_FOLIOS_ACTIVOS_REQUEST"});
        },
        onGetAlmacenes: () => {
            dispatch({ type: "ALMACENES_ACTIVOS_BUSQUEDA_REQUEST"});
        },
        onCambiarVista: (vistaModalNuevoFolio, formulario, tipoActivos) => {
            dispatch({ type: "CAMBIAR_VISTA_MODAL_NUEVOFOLIO", vistaModalNuevoFolio, formularioNuevoFolio:  formulario, tipoActivos});
        },
        onGetProductos: (page, busqueda, idSubFamilia) => {
            dispatch({ type: "PRODUCTOS_BUSQUEDA_REQUEST", page: page, busqueda: busqueda, idSubFamilia:idSubFamilia });
        },
        onGetUbicacionOrigen: (page, idAlmacen) => {
            dispatch({ type: "OBTENER_UBICACION_POR_ALMACEN_REQUEST", page: page, idAlmacen: idAlmacen})
        },
        onGetUbicacionDestino: (page, idAlmacen) => { 
            dispatch({ type: "UBICACION_DESTINO_POR_ALMACEN_REQUEST", page: page, idAlmacen: idAlmacen})
        },
        onGetRequisicion: (page, folioOrigen, busqueda, idFamilia, idSubFamilia) => {
            dispatch({ type: "LISTA_REQUISICION_DIRECTA_REQUEST", page: page, folioOrigen: folioOrigen, busqueda: busqueda, idFamilia: idFamilia, idSubFamilia: idSubFamilia});
        },
        onGetInventario: (page, idAlmacenOrigen) => {
            dispatch({ type: "LISTA_INVENTARIO_REQUEST", page: page, idAlmacenOrigen: idAlmacenOrigen})
        },
        onGetInventarioTipoActivos: (page, idAlmacenOrigen) => {
            dispatch({ type: 'LISTA_INVENTARIO_TIPO_ACTIVOS_REQUEST', page, idAlmacenOrigen })
        },
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        },
        onGetSubfamilias: () => {
            dispatch({ type: 'LISTA_SUBFAMILIAS_REQUEST' });
        },
        onGetListaFoliosRequisicionMateriales: (page, busqueda, orden, idUsuario) => {
            dispatch({ type: "LISTA_FOLIOS_REQUISICION_MATERIALES_REQUEST", page, busqueda, orden, idUsuario});
       },
	};
};
 
export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoFolio);