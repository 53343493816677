import * as informacionOperativaActions from '../actions/InformacionOperativaActions.js';

const initialState = {
    catalogoProveedor: null,

    //Reducers de Dominios
    fetchingListaDominiosBusqueda: false,
    listaPermisosBusqueda: false,
    listaDominiosBusquedaCantidad: null,

    showModalNuevoDominio: false,
    showModalEditarDominio: false,
    dominioSeleccionado: null,

    fetchingNuevoDominio: false,
    fetchingEditarDominio: false,

    //Reducers de Expedientes
    fetchingListaExpedientes: false,
    listaExpedientes: [],

    fetchingListaExpedientesBusqueda: false,
    listaExpedientesBusqueda: [],
    listaExpedientesBusquedaCantidad: null,

    showModalNuevoExpediente: false,
    showModalEditarExpediente: false,
    expedienteSeleccionado: null,
    tipoEdicion: false,

    fetchingNuevoExpediente: false,
    fetchingEditarExpediente: false,

    showArchivosExpediente: false,
    fetchingEliminarArchivoExpediente: false,
    fetchingArchivosExpediente: false,
    archivosExpediente: [],

    //Reducers de Contratos
    fetchingListaContratos: false,
    listaContratos: [],

    fetchingListaContratosActivosIdServicio: false,
    listaContratosActivosIdServicio: [],

    fetchingListaContratosBusqueda: false,
    listaContratosBusqueda: [],
    listaContratosBusquedaCantidad: null,

    fetchingListaContratosPorServicio: false,
    listaContratosPorServicio: null,

    showModalNuevoContrato: false,
    showModalEditarContrato: false,
    ContratoSeleccionado: null,

    fetchingNuevoContrato: false,
    fetchingEditarContrato: false,

    showArchivosContrato: false,
    idContrato: null,
    
    //Reducers de proveedores
    fetchingListaProveedores: false,
    listaProveedores: [],

    fetchingListaParamProveedores: false,
    listaParamProveedores: [],

    fetchingProveedoresBusqueda: false,
    proveedoresBusqueda: [],
    proveedoresBusquedaCantidad: null,

    showModalNuevoProveedor: false,
    showModalEditarProveedor: false,
    proveedorSeleccionado: null,

    fetchingNuevoProveedor: false,
    fetchingEditarProveedor: false,

    fetchingProveedorPorDominio: false,
    proveedorPorDominio: null,

    fetchingListaPartidasByServicio: false,
    listaPartidasByServicio: [],
    listaPartidasByServicioWithout: [],
    idsServicios: [],

    fetchingListaPartidasByServicios: false,
    listaPartidasByServicios: [],
    
    fetchingListaServiciosActivosForProveedor: false,
    listaServiciosActivosForProveedor: [],
    fetchingListaAreasProveedores: false,
    listaAreasProveedores: [],

    //Catálogo Partidas
    fetchingListaPartidasBusqueda: false,
    listaPartidasBusqueda: [],
    listaPartidasBusquedaCantidad: 0,

    showModalNuevaPartida: false,
    fetchingNuevaPartida: false,

    showModalEditarPartida: false,
    fetchingEditarPartida: false,
    partidaSeleccionada: null,

}

export default (state = initialState, action) => {
    switch (action.type) {
        
        case informacionOperativaActions.CAMBIAR_CATALOGO_PROVEEDOR:
            return { ...state, catalogoProveedor: action.catalogoProveedor };

        //REDUCERS DE DOMINIOS
        case informacionOperativaActions.LISTA_DOMINIOS_BUSQUEDA_REQUEST:
            return { ...state, fetchingListaDominiosBusqueda: true };
        case informacionOperativaActions.LISTA_DOMINIOS_BUSQUEDA_SUCCESS:
            return { ...state, fetchingListaDominiosBusqueda: false, listaDominiosBusqueda: action.listaDominiosBusqueda, listaDominiosBusquedaCantidad: action.listaDominiosBusquedaCantidad };
        case informacionOperativaActions.LISTA_DOMINIOS_BUSQUEDA_FAILURE:
            return { ...state, fetchingListaDominiosBusqueda: false };

        case informacionOperativaActions.ESTADO_DOMINIO_REQUEST:
            return { ...state, fetchingListaDominiosBusqueda: true };
        case informacionOperativaActions.ESTADO_DOMINIO_SUCCESS:
            return { ...state, fetchingListaDominiosBusqueda: false };
        case informacionOperativaActions.ESTADO_DOMINIO_FAILURE:
            return { ...state, fetchingListaDominiosBusqueda: false };

        case informacionOperativaActions.SHOW_MODAL_NUEVO_DOMINIO:
            return { ...state, showModalNuevoDominio: action.showModalNuevoDominio, page: action.page };

        case informacionOperativaActions.SHOW_MODAL_EDITAR_DOMINIO:
            return { ...state, showModalEditarDominio: action.showModalEditarDominio, dominioSeleccionado: action.dominioSeleccionado, page: action.page };

        case informacionOperativaActions.NUEVO_DOMINIO_REQUEST:
            return { ...state, fetchingNuevoDominio: true };
        case informacionOperativaActions.NUEVO_DOMINIO_SUCCESS:
            return { ...state, fetchingNuevoDominio: false };
        case informacionOperativaActions.NUEVO_DOMINIO_FAILURE:
            return { ...state, fetchingNuevoDominio: false };

        case informacionOperativaActions.EDITAR_DOMINIO_REQUEST:
            return { ...state, fetchingEditarDominio: true };
        case informacionOperativaActions.EDITAR_DOMINIO_SUCCESS:
            return { ...state, fetchingEditarDominio: false };
        case informacionOperativaActions.EDITAR_DOMINIO_FAILURE:
            return { ...state, fetchingEditarDominio: false };
        
        //REDUCERS DE EXPEDIENTES
        case informacionOperativaActions.LISTA_EXPEDIENTES_REQUEST:
            return { ...state, fetchingListaExpedientes: true };
        case informacionOperativaActions.LISTA_EXPEDIENTES_SUCCESS:
            return { ...state, fetchingListaExpedientes: false, listaExpedientes: action.listaExpedientes };
        case informacionOperativaActions.LISTA_EXPEDIENTES_FAILURE:
            return { ...state, fetchingListaExpedientes: false };

        case informacionOperativaActions.LISTA_EXPEDIENTES_BUSQUEDA_REQUEST:
            return { ...state, fetchingListaExpedientesBusqueda: true };
        case informacionOperativaActions.LISTA_EXPEDIENTES_BUSQUEDA_SUCCESS:
            return { ...state, fetchingListaExpedientesBusqueda: false, listaExpedientesBusqueda: action.listaExpedientesBusqueda, listaExpedientesBusquedaCantidad: action.listaExpedientesBusquedaCantidad };
        case informacionOperativaActions.LISTA_EXPEDIENTES_BUSQUEDA_FAILURE:
            return { ...state, fetchingListaExpedientesBusqueda: false };

        case informacionOperativaActions.LISTA_EXPEDIENTES_SIN_INFO_REQUEST:
            return { ...state, fetchingListaExpedientesBusqueda: true };
        case informacionOperativaActions.LISTA_EXPEDIENTES_SIN_INFO_SUCCESS:
            return { ...state, fetchingListaExpedientesBusqueda: false, listaExpedientesBusqueda: action.listaExpedientesBusqueda, listaExpedientesBusquedaCantidad: action.listaExpedientesBusquedaCantidad };
        case informacionOperativaActions.LISTA_EXPEDIENTES_SIN_INFO_FAILURE:
            return { ...state, fetchingListaExpedientesBusqueda: false };

        case informacionOperativaActions.ESTADO_EXPEDIENTE_REQUEST:
            return { ...state, fetchingListaExpedientesBusqueda: true };
        case informacionOperativaActions.ESTADO_EXPEDIENTE_SUCCESS:
            return { ...state, fetchingListaExpedientesBusqueda: false };
        case informacionOperativaActions.ESTADO_EXPEDIENTE_FAILURE:
            return { ...state, fetchingListaExpedientesBusqueda: false };

        case informacionOperativaActions.SHOW_MODAL_NUEVO_EXPEDIENTE:
            return { ...state, showModalNuevoExpediente: action.showModalNuevoExpediente, page: action.page };

        case informacionOperativaActions.SHOW_MODAL_EDITAR_EXPEDIENTE:
            return { ...state, showModalEditarExpediente: action.showModalEditarExpediente, expedienteSeleccionado: action.expedienteSeleccionado, page: action.page, tipoEdicion: action.tipoEdicion };

        case informacionOperativaActions.ARCHIVOS_EXPEDIENTE_REQUEST:
            return { ...state, fetchingArchivosExpediente: true };
        case informacionOperativaActions.ARCHIVOS_EXPEDIENTE_SUCCESS:
            return { ...state, fetchingArchivosExpediente: false, archivosExpediente: action.archivosExpediente };
        case informacionOperativaActions.ARCHIVOS_EXPEDIENTE_FAILURE:
            return { ...state, fetchingArchivosExpediente: false, archivosExpediente: action.archivosExpediente };

        case informacionOperativaActions.ELIMIAR_ARCHIVO_EXPEDIENTE_REQUEST:
            return { ...state, fetchingEliminarArchivoExpediente: true };
        case informacionOperativaActions.ELIMIAR_ARCHIVO_EXPEDIENTE_SUCCESS:
            return { ...state, fetchingEliminarArchivoExpediente: false };
        case informacionOperativaActions.ELIMIAR_ARCHIVO_EXPEDIENTE_FAILURE:
            return { ...state, fetchingEliminarArchivoExpediente: false };

        case informacionOperativaActions.NUEVO_EXPEDIENTE_REQUEST:
            return { ...state, fetchingNuevoExpediente: true };
        case informacionOperativaActions.NUEVO_EXPEDIENTE_SUCCESS:
            return { ...state, fetchingNuevoExpediente: false };
        case informacionOperativaActions.NUEVO_EXPEDIENTE_FAILURE:
            return { ...state, fetchingNuevoExpediente: false };

        case informacionOperativaActions.EDITAR_EXPEDIENTE_REQUEST:
            return { ...state, fetchingEditarExpediente: true };
        case informacionOperativaActions.EDITAR_EXPEDIENTE_SUCCESS:
            return { ...state, fetchingEditarExpediente: false };
        case informacionOperativaActions.EDITAR_EXPEDIENTE_FAILURE:
            return { ...state, fetchingEditarExpediente: false };

        case informacionOperativaActions.SHOW_MODAL_ARCHIVOS_EXPEDIENTE:
            return { ...state, showArchivosExpediente: action.showArchivosExpediente, expedienteSeleccionado: action.expedienteSeleccionado, page: action.page, archivosExpediente: action.archivosExpediente };

        //REDUCERS DE CONTRATOS
        case informacionOperativaActions.LISTA_CONTRATOS_REQUEST:
            return { ...state, fetchingListaContratos: true };
        case informacionOperativaActions.LISTA_CONTRATOS_SUCCESS:
            return { ...state, fetchingListaContratos: false, listaContratos: action.listaContratos };
        case informacionOperativaActions.LISTA_CONTRATOS_FAILURE:
            return { ...state, fetchingListaContratos: false };

        case informacionOperativaActions.LISTA_CONTRATOS_ACTIVOS_ID_SERVICIO_REQUEST:
            return { ...state, fetchingListaContratosActivosIdServicio: true };
        case informacionOperativaActions.LISTA_CONTRATOS_ACTIVOS_ID_SERVICIO_SUCCESS:
            return { ...state, fetchingListaContratosActivosIdServicio: false, listaContratosActivosIdServicio: action.listaContratosActivosIdServicio };
        case informacionOperativaActions.LISTA_CONTRATOS_ACTIVOS_ID_SERVICIO_FAILURE:
            return { ...state, fetchingListaContratosActivosIdServicio: false };

        case informacionOperativaActions.LISTA_CONTRATOS_BUSQUEDA_REQUEST:
            return { ...state, fetchingListaContratosBusqueda: true };
        case informacionOperativaActions.LISTA_CONTRATOS_BUSQUEDA_SUCCESS:
            return { ...state, fetchingListaContratosBusqueda: false, listaContratosBusqueda: action.listaContratosBusqueda, listaContratosBusquedaCantidad: action.listaContratosBusquedaCantidad };
        case informacionOperativaActions.LISTA_CONTRATOS_BUSQUEDA_FAILURE:
            return { ...state, fetchingListaContratosBusqueda: false };

        case informacionOperativaActions.LISTA_CONTRATOS_SIN_INFO_REQUEST:
            return { ...state, fetchingListaContratosBusqueda: true };
        case informacionOperativaActions.LISTA_CONTRATOS_SIN_INFO_SUCCESS:
            return { ...state, fetchingListaContratosBusqueda: false, listaContratosBusqueda: action.listaContratosBusqueda, listaContratosBusquedaCantidad: action.listaContratosBusquedaCantidad };
        case informacionOperativaActions.LISTA_CONTRATOS_SIN_INFO_FAILURE:
            return { ...state, fetchingListaContratosBusqueda: false };

        case informacionOperativaActions.LISTA_CONTRATOS_POR_SERVICIO_REQUEST:
            return { ...state, fetchingListaContratosPorServicio: true };
        case informacionOperativaActions.LISTA_CONTRATOS_POR_SERVICIO_SUCCESS:
            return { ...state, fetchingListaContratosPorServicio: false, listaContratosPorServicio: action.listaContratosPorServicio };
        case informacionOperativaActions.LISTA_CONTRATOS_POR_SERVICIO_FAILURE:
            return { ...state, fetchingListaContratosPorServicio: false };

        case informacionOperativaActions.ESTADO_CONTRATO_REQUEST:
            return { ...state, fetchingListaContratosBusqueda: true };
        case informacionOperativaActions.ESTADO_CONTRATO_SUCCESS:
            return { ...state, fetchingListaContratosBusqueda: false };
        case informacionOperativaActions.ESTADO_CONTRATO_FAILURE:
            return { ...state, fetchingListaContratosBusqueda: false };

        case informacionOperativaActions.SHOW_MODAL_NUEVO_CONTRATO:
            return { ...state, showModalNuevoContrato: action.showModalNuevoContrato, page: action.page };

        case informacionOperativaActions.SHOW_MODAL_EDITAR_CONTRATO:
            return { ...state, showModalEditarContrato: action.showModalEditarContrato, contratoSeleccionado: action.contratoSeleccionado, page: action.page };

        case informacionOperativaActions.NUEVO_CONTRATO_REQUEST:
            return { ...state, fetchingNuevoContrato: true };
        case informacionOperativaActions.NUEVO_CONTRATO_SUCCESS:
            return { ...state, fetchingNuevoContrato: false, idContrato: action.idContrato };
        case informacionOperativaActions.NUEVO_CONTRATO_FAILURE:
            return { ...state, fetchingNuevoContrato: false };

        case informacionOperativaActions.EDITAR_CONTRATO_REQUEST:
            return { ...state, fetchingEditarContrato: true };
        case informacionOperativaActions.EDITAR_CONTRATO_SUCCESS:
            return { ...state, fetchingEditarContrato: false };
        case informacionOperativaActions.EDITAR_CONTRATO_FAILURE:
            return { ...state, fetchingEditarContrato: false };

        case informacionOperativaActions.SHOW_MODAL_ARCHIVOS_CONTRATO:
            return { ...state, showArchivosContrato: action.showArchivosContrato, contratoSeleccionado: action.contratoSeleccionado, page: action.page };

        //REDUCERS DE PROVEEDORES
        case informacionOperativaActions.GET_PROVEEDORES_REQUEST:
            return { ...state, fetchingListaProveedores: true };
        case informacionOperativaActions.GET_PROVEEDORES_SUCCESS:
            return { ...state, fetchingListaProveedores: false, listaProveedores: action.listaProveedores };
        case informacionOperativaActions.GET_PROVEEDORES_FAILURE:
            return { ...state, fetchingListaProveedores: false };

        case informacionOperativaActions.LISTA_PROVEEDORES_PARAM_REQUEST:
            return { ...state, fetchingListaParamProveedores: true };
        case informacionOperativaActions.LISTA_PROVEEDORES_PARAM_SUCCESS:
            return { ...state, fetchingListaParamProveedores: false, listaParamProveedores: action.listaParamProveedores };
        case informacionOperativaActions.LISTA_PROVEEDORES_PARAM_FAILURE:
            return { ...state, fetchingListaParamProveedores: false };

        case informacionOperativaActions.PROVEEDOR_POR_DOMINIO_REQUEST:
            return { ...state, fetchingProveedorPorDominio: true };
        case informacionOperativaActions.PROVEEDOR_POR_DOMINIO_SUCCESS:
            return { ...state, fetchingProveedorPorDominio: false, proveedorPorDominio: action.proveedorPorDominio };
        case informacionOperativaActions.PROVEEDOR_POR_DOMINIO_FAILURE:
            return { ...state, fetchingProveedorPorDominio: false };

        case informacionOperativaActions.LISTA_PROVEEDORES_BUSQUEDA_REQUEST:
            return { ...state, fetchingProveedoresBusqueda: true };
        case informacionOperativaActions.LISTA_PROVEEDORES_BUSQUEDA_SUCCESS:
            return { ...state, fetchingProveedoresBusqueda: false, proveedoresBusqueda: action.proveedoresBusqueda, proveedoresBusquedaCantidad: action.proveedoresBusquedaCantidad };
        case informacionOperativaActions.LISTA_PROVEEDORES_BUSQUEDA_FAILURE:
            return { ...state, fetchingProveedoresBusqueda: false };

        case informacionOperativaActions.ESTADO_PROVEEDOR_REQUEST:
            return { ...state, fetchingProveedoresBusqueda: true };
        case informacionOperativaActions.ESTADO_PROVEEDOR_SUCCESS:
            return { ...state, fetchingProveedoresBusqueda: false };
        case informacionOperativaActions.ESTADO_PROVEEDOR_FAILURE:
            return { ...state, fetchingProveedoresBusqueda: false };

        case informacionOperativaActions.SHOW_MODAL_NUEVO_PROVEEDOR:
            return { ...state, showModalNuevoProveedor: action.showModalNuevoProveedor, page: action.page };

        case informacionOperativaActions.SHOW_MODAL_EDITAR_PROVEEDOR:
            return { ...state, showModalEditarProveedor: action.showModalEditarProveedor, proveedorSeleccionado: action.proveedorSeleccionado, page: action.page, idsServicios: action.idsServicios };

        case informacionOperativaActions.NUEVO_PROVEEDOR_REQUEST:
            return { ...state, fetchingNuevoProveedor: true };
        case informacionOperativaActions.NUEVO_PROVEEDOR_SUCCESS:
            return { ...state, fetchingNuevoProveedor: false };
        case informacionOperativaActions.NUEVO_PROVEEDOR_FAILURE:
            return { ...state, fetchingNuevoProveedor: false };

        case informacionOperativaActions.EDITAR_PROVEEDOR_REQUEST:
            return { ...state, fetchingEditarProveedor: true };
        case informacionOperativaActions.EDITAR_PROVEEDOR_SUCCESS:
            return { ...state, fetchingEditarProveedor: false };
        case informacionOperativaActions.EDITAR_PROVEEDOR_FAILURE:
            return { ...state, fetchingEditarProveedor: false };

        case informacionOperativaActions.LISTA_PARTIDAS_BY_SERVICIO_INFO_OP_REQUEST:
            return { ...state, fetchingListaPartidasByServicio: true };
        case informacionOperativaActions.LISTA_PARTIDAS_BY_SERVICIO_INFO_OP_SUCCESS:
            return { ...state, fetchingListaPartidasByServicio: false, listaPartidasByServicio: action.listaPartidasByServicio, listaPartidasByServicioWithout: action.listaPartidasByServicioWithout };
        case informacionOperativaActions.LISTA_PARTIDAS_BY_SERVICIO_INFO_OP_FAILURE:
            return { ...state, fetchingListaPartidasByServicio: false }; 

        case informacionOperativaActions.LISTA_PARTIDAS_BY_SERVICIOS_REQUEST:
            return { ...state, fetchingListaPartidasByServicios: true };
        case informacionOperativaActions.LISTA_PARTIDAS_BY_SERVICIOS_SUCCESS:
            return { ...state, fetchingListaPartidasByServicios: false, listaPartidasByServicios: action.listaPartidasByServicios };
        case informacionOperativaActions.LISTA_PARTIDAS_BY_SERVICIOS_FAILURE:
            return { ...state, fetchingListaPartidasByServicios: false }; 

        case informacionOperativaActions.LISTA_SERVICIOS_PROVEEDORES_REQUEST:
            return { ...state, fetchingListaServiciosActivosForProveedor: true };
        case informacionOperativaActions.LISTA_SERVICIOS_PROVEEDORES_SUCCESS:
            return { ...state, fetchingListaServiciosActivosForProveedor: false, listaServiciosActivosForProveedor: action.listaServiciosActivosForProveedor };
        case informacionOperativaActions.LISTA_SERVICIOS_PROVEEDORES_FAILURE:
            return { ...state, fetchingListaServiciosActivosForProveedor: false };

            case informacionOperativaActions.LISTA_AREAS_PROVEEDORES_REQUEST:
                return { ...state, fetchingListaAreasProveedores: true };
            case informacionOperativaActions.LISTA_AREAS_PROVEEDORES_SUCCESS:
                return { ...state, fetchingListaAreasProveedores: false, listaAreasProveedores: action.listaAreasProveedores };
            case informacionOperativaActions.LISTA_AREAS_PROVEEDORES_FAILURE:
                return { ...state, fetchingListaAreasProveedores: false };

        // PARTIDAS    

        case informacionOperativaActions.LISTA_PARTIDAS_BUSQUEDA_REQUEST:
            return { ...state, fetchingListaPartidasBusqueda: true };
        case informacionOperativaActions.LISTA_PARTIDAS_BUSQUEDA_SUCCESS:
            return { ...state, fetchingListaPartidasBusqueda: false, listaPartidasBusqueda: action.listaPartidasBusqueda, listaPartidasBusquedaCantidad: action.listaPartidasBusquedaCantidad };
        case informacionOperativaActions.LISTA_PARTIDAS_BUSQUEDA_FAILURE:
            return { ...state, fetchingListaPartidasBusqueda: false };

        case informacionOperativaActions.CAMBIAR_ESTADO_PARTIDA_REQUEST:
            return { ...state, fetchingListaPartidasBusqueda: true };
        case informacionOperativaActions.CAMBIAR_ESTADO_PARTIDA_SUCCESS:
            return { ...state, fetchingListaPartidasBusqueda: false };
        case informacionOperativaActions.CAMBIAR_ESTADO_PARTIDA_FAILURE:
            return { ...state, fetchingListaPartidasBusqueda: false };

        case informacionOperativaActions.SHOW_MODAL_NUEVA_PARTIDA:
            return { ...state, showModalNuevaPartida: action.showModalNuevaPartida, page: action.page, busqueda: action.busqueda };

        case informacionOperativaActions.SHOW_MODAL_EDITAR_PARTIDA:
            return { ...state, showModalEditarPartida: action.showModalEditarPartida, partidaSeleccionada: action.partidaSeleccionada, page: action.page, busqueda: action.busqueda };

        case informacionOperativaActions.NUEVA_PARTIDA_REQUEST:
            return { ...state, fetchingNuevaPartida: true };
        case informacionOperativaActions.NUEVA_PARTIDA_SUCCESS:
            return { ...state, fetchingNuevaPartida: false };
        case informacionOperativaActions.NUEVA_PARTIDA_FAILURE:
            return { ...state, fetchingNuevaPartida: false };

        case informacionOperativaActions.EDITAR_PARTIDA_REQUEST:
            return { ...state, fetchingEditarPartida: true };
        case informacionOperativaActions.EDITAR_PARTIDA_SUCCESS:
            return { ...state, fetchingEditarPartida: false };
        case informacionOperativaActions.EDITAR_PARTIDA_FAILURE:
            return { ...state, fetchingEditarPartida: false };


        default:
            return state;
    }
}

