import React, { Component } from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, FileExcelOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoMotivo from './ModalNuevoMotivo';
import ModalEditarMotivo from './ModalEditarMotivo';

const { Search } = Input;
const confirm = Modal.confirm;
class CatalogoMotivosDeSalida extends Component {
    
    state = {
          page: 1,
          busqueda: {order: 'ASC', field: 'idMotivoSalida'},
          order: 'ASC',
          field: 'idMotivoSalida',
          param: '',
    }
    componentDidMount(){
        this.props.onGetMotivos(1, this.state.busqueda)
    }
    
    render() { 
        const {
            onGetMotivos,
            listaMotivosSalidaBusqueda,
            fetchingListaMotivosSalidaBusqueda,
            listaMotivosSalidaBusquedaCantidad,
            onEstadoMotivo,
            onModalNuevoMotivo,
            onModalEditarMotivo,
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const onBuscarMotivo = (param) => {
            const busqueda = { param: param, field: this.state.field , order: this.state.order, }
            this.setState({ busqueda: busqueda, page: 1 });
            console.log(busqueda);
            onGetMotivos(1, busqueda);
       }

        const handleTableChange = (pagination, filtro,  orden) => {
            this.setState({ page: pagination.current });
            if (orden) {
                let campo = 'idMotivoSalida';
                let order = 'ASC';

                if (orden.order === 'descend') {
                    order = 'DESC';
                }

                if (orden.columnKey === 'tipoSalida') {
                    campo = 'tipoSalida';
                } else if (orden.columnKey === 'descripcion') {
                    campo = 'descripcion';
                } 

                const busqueda = { param: this.state.param, field: campo, order: order }
                onGetMotivos(pagination.current, busqueda);
                this.setState({
                    order: order,
                    field: campo,
                    busqueda: busqueda,
                });
            }
         else{
            onGetMotivos(pagination.current, this.state.busqueda);
         }
      }

      const handleDesactivar = (key) => {
        console.log(key);
        const page = this.state.page;
        confirm({
            title: '¿Estás seguro que deseas cambiar el estado del motivo?',
            content: '',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                onEstadoMotivo(key.idMotivoSalida, page)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const disabledField =['Préstamo', 'Reparación','Baja administrativa', 'Baja física'];

        const columns = [{
            title: 'Descripcion motivo de salida',
            dataIndex: 'descripcion',
            key: 'descripcion',
            sorter: true
        },{
            title: 'Tipo de salida',
            dataIndex: 'tipoSalida',
            key: 'tipoSalida',
            sorter: true
        }, {
            title: 'Activo',
            dataIndex: 'activo',
            key: 'activo',
            align: 'center',
            render: (text, record) => (
                <Switch checked={text} disabled={!permisosUsuario.motivoDeSalidaActivoInactivo ? true : disabledField.includes(record.descripcion)} onChange={() => handleDesactivar(record)} />
            ),
        }, {
            title: 'Editar',
            key: 'editar',
            align: 'center',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.editarMotivoDeSalida ? true : disabledField.includes(record.descripcion)} onClick={() => onEditar(record)} ><EditOutlined /></a>
            ),
        },
        ];

        const onNuevoMotivo = () => {
            onModalNuevoMotivo(this.state.page)
        }
        const onEditar = (key) => {
            console.log(key);
            onModalEditarMotivo(key, this.state.page);
        }

        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={20}>
                        <Search className='buscarModulos' placeholder="Buscar descripcion de motivo de salida" onSearch={value => onBuscarMotivo(value)} style={{ width: '100%' }} />
                    </Col>
                    {/* <Col span={7}>
                        <Button onClick={onNuevoMotivo} disabled={!permisosUsuario.crearMotivoDeSalida} className='nuevoUsuario'>Nuevo Motivo de Salida</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nuevo PIH' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoMotivo} disabled={!permisosUsuario.crearMotivoDeSalida} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={1}>
                        <Tooltip title='Exportar archivo .xls' placement="topRight">
                            <Button disabled={!permisosUsuario.exportarExcelCatálogoCategoríasMotivosSalida} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } loading={this.props.fetchingExportarExcelMotivosSalidaActivo} onClick={() => this.props.onExport(this.state.order, this.state.field)}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <br />
                        <Table
                            size="small"
                            rowKey="descripcion"
                            columns={columns}
                            dataSource={listaMotivosSalidaBusqueda}
                            loading={fetchingListaMotivosSalidaBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaMotivosSalidaBusquedaCantidad, simple: true, current: this.state.page }}
                        />
                    </Col>
                </Row>
                <ModalNuevoMotivo/>
                <ModalEditarMotivo/>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        listaMotivosSalidaBusqueda: state.AlmacenesReducer.listaMotivosSalidaBusqueda,
        fetchingListaMotivosSalidaBusqueda: state.AlmacenesReducer.fetchingListaMotivosSalidaBusqueda,
        listaMotivosSalidaBusquedaCantidad: state.AlmacenesReducer.listaMotivosSalidaBusquedaCantidad,
        fetchingExportarExcelMotivosSalidaActivo: state.AlmacenesReducer.fetchingExportarExcelMotivosSalidaActivo,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onGetMotivos: (page, busqueda) => {
            dispatch({ type: "LISTA_MOTIVOS_DE_SALIDA_BUSQUEDA_REQUEST", page: page, busqueda: busqueda});
        }, 
        onEstadoMotivo: (idMotivoSalida, page) => {
            dispatch({ type: "ESTADO_MOTIVOS_DE_SALIDA_REQUEST", idMotivoSalida: idMotivoSalida, page: page})
        }, 
        onModalNuevoMotivo: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_MOTIVOS_DE_SALIDA", showModalNuevoMotivoSalida: true, page: page})
        },
        onModalEditarMotivo: (motivoSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_MOTIVOS_DE_SALIDA", showModalEditarMotivoSalida: true, motivoSeleccionado: motivoSeleccionado, page: page })
        },
        onExport: (order, field) => {
            dispatch({ type: "EXPORTAR_MOTIVOS_DE_SALIDA_ACTIVOS_EXCEL_REQUEST", order, field });
        },
    }
}
export default connect(mapStateToProps,mapDispatchToProps )(CatalogoMotivosDeSalida) 