import React from 'react';
import { Button, Form, Row, Col, Modal, Typography, Descriptions, Input, Collapse, Table, Divider, Steps, DatePicker, message, TimePicker, Tooltip, Spin } from 'antd';
import moment from 'moment'
import { connect } from "react-redux";
import { RightSquareFilled, MinusCircleOutlined, PlusOutlined, AppstoreAddOutlined, CalendarOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const {Text} = Typography;
const { TextArea } = Input;
const { Panel } = Collapse;
const { Step } = Steps;
const confirm = Modal.confirm;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
};

class ModalProgramar extends React.Component {
    formRef = React.createRef();

     state = {
          current: 0,
          idAsignacionServ: null,
          idUbi: null,
          dataProgramar: [],
          ubiCOA: '',
          actDes: '',
          idAct: null,
          colorAsignar: '#0e2c82'
     }

     render() {
          const { 
               showModalProgramarRMP,
               onCloseModal,
               programa,
               programaRMPSeleccionado,
               onProgramarRMP,
               listaRMPBusqueda,
               fetchingProgramarRMP
          } = this.props;

          let dp = {};
          const onFinish = formulario => {
               console.log('Success:', formulario);
               let fechas = [];
               let fechasModal = '';
               let dataProgramar = this.state.dataProgramar;
               const _this = this;
               let programaRMP = '';

               if( programa === "Rutina"){programaRMP = 'ésta ubicación'} else {programaRMP = 'éste activo'}

               console.log('dataProgramar antes de iniciar', dataProgramar);
               if(programaRMPSeleccionado.fechaEspecifica){
                    if(formulario.fechas){

                         for(let j = 0; j < formulario.fechas.length; j++){
                              let fch = { 
                                   "fecha": (formulario.fechas[j].fecha).format('YYYY-MM-DD')
                              }
                              fechas.push(fch);

                              fechasModal += (formulario.fechas[j].fecha).format('DD-MM-YYYY') + ', '
                         }

                         confirm({
                         title: <p>¿Estás seguro que deseas guardar la hora propuesta: "<a style={{ color: "#ff8c62" }}>{ (formulario.horaPropuesta).format('HH:mm')}</a>" con fecha(s): <a style={{ color: "#1747ca" }}>{ fechasModal }</a> para {programaRMP}?</p>,
                              content: '',
                              okText: 'Sí',
                              cancelText: 'No',
                              onOk() {
                                   dp = {
                                        "idAsignacionServicioPlanificado": _this.state.idAsignacionServ,
                                        "idUbicacion": _this.state.idUbi,
                                        "fechasRutinasByIdProgramacion": fechas,
                                        "hora": (formulario.horaPropuesta).format('HH:mm:ss'),
                                   }
                                   dataProgramar.push(dp);
          
                                   console.log('dataProgramar fechaEspecifica', dataProgramar);

                                   actualizarTabla(dp); 
                              
                                   _this.formRef.current.resetFields();
                                   _this.setState({ current: 0, dataProgramar: dataProgramar });
                              },
                              onCancel() {
                                   console.log('Cancel');
                              },
                         });
                         
                    } else {
                         message.error('Debes agregar mínimo una fecha');
                    }
               } else {
                    if(formulario.fechaInicio){
                         confirm({
                              title: <p>¿Estás seguro que deseas guardar la hora propuesta: "<a style={{ color: "#ff8c62" }}>{ (formulario.horaPropuesta).format('HH:mm')}</a>" con fecha: <a style={{ color: "#1747ca" }}>{ (formulario.fechaInicio).format('DD-MM-YYYY') }</a> para {programaRMP}?</p>,
                              content: '',
                              okText: 'Sí',
                              cancelText: 'No',
                              onOk() {
                                   dp = {
                                        "idAsignacionServicioPlanificado": _this.state.idAsignacionServ,
                                        "idUbicacion": _this.state.idUbi,
                                        "fechaInicial": (formulario.fechaInicio).format('YYYY-MM-DD'),
                                        "hora": (formulario.horaPropuesta).format('HH:mm:ss'),
                                   }
                                   dataProgramar.push(dp);

                                   actualizarTabla(dp); 
          
                                   console.log('dataProgramar frecuencia', dataProgramar);
          
                                   _this.formRef.current.resetFields();
                                   _this.setState({ current: 0, dataProgramar: dataProgramar });
                              },
                              onCancel() {
                                   console.log('Cancel');
                              },
                         });
                    } else {
                         message.error('Debes agregar una fecha de inicio');
                    }
               }
          };

          const handleCancel = () => {
               for(let i in listaRMPBusqueda){
                    for(let j in listaRMPBusqueda[i].asignacionByIdAsignacionServicioPlanificado)
                    listaRMPBusqueda[i].asignacionByIdAsignacionServicioPlanificado[j].asignado = false;
               }

               onCloseModal();

               this.setState({ 
                    listaDiasSemana: [{activo: false, dia: "D", diaSemana: "DOMINGO", idDiaSemana: 1, type: "default"},{activo: false, dia: "L", diaSemana: "LUNES", idDiaSemana: 2, type: "default"},{activo: false, dia: "M", diaSemana: "MARTES", idDiaSemana: 3, type: "default"},{activo: false, dia: "I", diaSemana: "MIERCOLES", idDiaSemana: 4, type: "default"},{activo: false, dia: "J", diaSemana: "JUEVES", idDiaSemana: 5, type: "default"},{activo: false, dia: "V", diaSemana: "VIERNES", idDiaSemana: 6, type: "default"},{activo: false, dia: "S", diaSemana: "SABADO", idDiaSemana: 7, type: "default"},], current: 0, dataProgramar: [],
               });
          };

          const columns = [
               {title: 'Descripcion',dataIndex: 'descripcion',key: 'descripcion',align: 'center', width: '60%',},
               {title: 'Cantidad',dataIndex: 'cantidad',key: 'cantidad',align: 'center', width: '15%',},
               {title: 'Unidad de Medida',dataIndex: 'unidadMedida',key: 'unidadMedida',align: 'center', width: '25%',}
          ];

          let dataUbi = [];
          let dataAct = [];
          
          if (programaRMPSeleccionado){
               if(programa === 'Rutina' ){
                    if(programaRMPSeleccionado.asignacionByIdAsignacionServicioPlanificado){
                         const {asignacionByIdAsignacionServicioPlanificado} = programaRMPSeleccionado;
                         
                         dataUbi = [
                              {title: 'Ubicacion', data: asignacionByIdAsignacionServicioPlanificado},
                         ];
                    }
               } else {
                    if(programaRMPSeleccionado.asignacionByIdAsignacionServicioPlanificado){
                        const {asignacionByIdAsignacionServicioPlanificado} = programaRMPSeleccionado;

                         for(let i = 0; i < asignacionByIdAsignacionServicioPlanificado.length; i++){
                              if(asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo){
                                   asignacionByIdAsignacionServicioPlanificado[i].sku = asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.sku;

                                   asignacionByIdAsignacionServicioPlanificado[i].codigoInterno = asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.codigoInterno;

                                   // idEstadoActivo = 'En operación'
                                   asignacionByIdAsignacionServicioPlanificado[i].idEstadoActivo = asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.idEstadoActivo;
                                   if(asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.ubicacionByIdUbicacion){
                                        //Ubicación del Activo

                                        if(asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional){
                                             asignacionByIdAsignacionServicioPlanificado[i].ubicacionCOA = asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.codigoArea + ' - ' + asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                 
                                             if(asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional){
                                                  asignacionByIdAsignacionServicioPlanificado[i].ubicacionCOA += ', ' + asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                 
                                                  if(asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional){
                                                       asignacionByIdAsignacionServicioPlanificado[i].ubicacionCOA += ', ' + asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional;
                    
                                                       if(asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional){
                                                            asignacionByIdAsignacionServicioPlanificado[i].ubicacionCOA += ', ' + asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                                                       }
                                                  }
                                             }
                                        }
                                   }

                                   if(asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.activoCaractByIdActivoCaract){
                                        asignacionByIdAsignacionServicioPlanificado[i].descripcion = asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.activoCaractByIdActivoCaract.productoByIdProducto.nombre;
                                   }
                              }

                              if(asignacionByIdAsignacionServicioPlanificado[i].idEstadoActivo === 1){
                                   dataAct.push(asignacionByIdAsignacionServicioPlanificado[i]);
                              }
                         }

                         
                         dataAct = [
                              {title: 'Activo', data: asignacionByIdAsignacionServicioPlanificado},
                         ];
                    }
               }

               
          }

          let boolBut = true;
          if(dataUbi[0]){
               if(dataUbi[0].data.length === this.state.dataProgramar.length){ boolBut = false; }
          }

          const columnsUbi = [
               {title: 'Ubicación', dataIndex: 'ubicacionCOA',key: 'ubicacionCOA',align: 'left', width: '90%'},
               {title: 'Asignar', key: 'programar', align: 'center', width: '10%', fixed: 'right',
               render: (record) => (
                    <a className='editarIcono' disabled={record.asignado} onClick={() => onProgramar(record)} ><RightSquareFilled /></a>
               ),},
          ];

          const columnsAct = [
               {title: 'Descripción',dataIndex: 'descripcion',key: 'descripcion',align: 'center', width: '20%'},
               {title: 'Número de Serie',dataIndex: 'serieNum',key: 'serieNum',align: 'center', width: '10%'},
               {title: 'Código Interno', dataIndex: 'codigoInterno',key: 'codigoInterno',align: 'center', width: '10%'},
               {title: 'Ubicación del Activo', dataIndex: 'ubicacionCOA',key: 'ubicacionCOA',align: 'center', width: '30%'},
               {title: 'Asignar', key: 'programar', align: 'center', width: '5%', fixed: 'right',
               render: (record) => (
                    <a className='editarIcono' disabled={record.asignado} onClick={() => onProgramar(record)} ><RightSquareFilled /></a>
               ),},
          ];

          const onProgramar = (key) => {
               console.log('key', key);

               if(programa === 'Rutina'){
                    if(this.state.dataProgramar.length > 0){
                         for( let i = 0; i < this.state.dataProgramar.length; i++ ){
                              if(this.state.dataProgramar[i].idAsignacionServicioPlanificado === key.idAsignacionServicioPlanificado){
                                   message.warning('Ésta ubicación ya cuenta con una propuesta.');
                                   this.setState({ current: 0, idAsignacionServ: key.idAsignacionServicioPlanificado, idUbi: key.idUbicacion, ubiCOA: key.ubicacionCOA });
                                   break;
                              } else {
                                   this.setState({ current: 1, idAsignacionServ: key.idAsignacionServicioPlanificado, idUbi: key.idUbicacion, ubiCOA: key.ubicacionCOA });
                              }
                         }
                    } else {
                         this.setState({ current: 1, idAsignacionServ: key.idAsignacionServicioPlanificado, idUbi: key.idUbicacion, ubiCOA: key.ubicacionCOA });
                    }
               } else {
                    if(this.state.dataProgramar.length > 0){
                         for( let i = 0; i < this.state.dataProgramar.length; i++ ){
                              if(this.state.dataProgramar[i].idAsignacionServicioPlanificado === key.idAsignacionServicioPlanificado){
                                   message.warning('Éste activo ya cuenta con una propuesta.');
                                   this.setState({ current: 0, idAsignacionServ: key.idAsignacionServicioPlanificado, idAct: key.idActivo, actDes: key.descripcion });
                                   break;
                              } else {
                                   this.setState({ current: 1, idAsignacionServ: key.idAsignacionServicioPlanificado, idAct: key.idActivo, actDes: key.descripcion });
                              }
                         }
                    } else {
                         this.setState({ current: 1, idAsignacionServ: key.idAsignacionServicioPlanificado, idAct: key.idActivo, actDes: key.descripcion });
                    }
               }
          };

          const onProgramarRutina = () => {
               onProgramarRMP(this.props.page, this.props.busqueda, this.state.dataProgramar, this.props.programa, this.props.idsServicios);

               this.setState({dataProgramar: [] });
          };

          const onFecha = (value, field) => { 
               let today = new Date();
               let yyyy = today.getFullYear() + 1;
               let beYear = yyyy + '-01-01'; // Debería funcionar con 01
               let formulario = this.formRef.current.getFieldsValue('fechas');
               console.log('beYear', beYear)
               const fechaFinLocal = new Date(beYear);

               console.log('fechaFinLocal', fechaFinLocal)

               if(!programaRMPSeleccionado.fechaEspecifica){
                    if(value._d > fechaFinLocal ){

                         message.error('No es posible seleccionar una fecha mayor al fin del presente año');
                         this.formRef.current.resetFields(['fechaInicio']);
                    }
               } else {
                    if(value._d > fechaFinLocal ){

                         formulario.fechas[field.name].fecha = null;
                         message.error('No es posible seleccionar una fecha mayor al fin del presente año');
                         this.formRef.current.setFieldsValue({'fechas': formulario.fechas});
                    }
               }
          };

          const  disabledDate = (fecha) => {
               let today = new Date();
               let dd = String(today.getDate()).padStart(2, '0');
               let mm = String(today.getMonth() + 1).padStart(2, '0');
               let yyyy = today.getFullYear();

               today = yyyy + '-' + mm + '-' + dd;
               let endYear = yyyy + '-' + 12 + '-' + 31
               return fecha && moment(fecha.format('YYYY-MM-DD')) < moment(today);
          }

          let boolButAct = true;
          if(dataAct[0]){
               if(dataAct[0].data.length === this.state.dataProgramar.length){ boolButAct = false; }
          }

          const actualizarTabla = (dp) => {
               if(programa === 'Rutina'){
                    if(dataUbi[0]){
                         let dataUbiLocal = dataUbi[0].data;
                         dataUbi[0].data = [];
                         for (let i = 0; i < dataUbiLocal.length; i++) {
                              if(dataUbiLocal[i].idAsignacionServicioPlanificado === dp.idAsignacionServicioPlanificado) {
                                   dataUbiLocal[i].asignado = true;

                                   dataUbi[0].data = dataUbiLocal;
                              }
                         }
                    }
               } else {
                    if(dataAct[0]){
                         let dataActLocal = dataAct[0].data;
                         dataAct[0].data = [];
                         for (let i = 0; i < dataActLocal.length; i++) {
                              if(dataActLocal[i].idAsignacionServicioPlanificado === dp.idAsignacionServicioPlanificado) {
                                   dataActLocal[i].asignado = true;

                                   dataAct[0].data = dataActLocal;
                              }
                         }
                    }
               }
               
          };

          return (
               
               <Modal
                    visible={showModalProgramarRMP}
                    title={'Programar ' + programa}
                    onCancel={handleCancel}
                    footer={false}
                    width={'65%'}
               >
                    <Spin spinning={fetchingProgramarRMP}>
                         { this.props.programaRMPSeleccionado ? (
                         <Form {...layout}
                              name="formulario"
                              ref={this.formRef}
                              onFinish={onFinish}
                              initialValues={this.props.programaRMPSeleccionado}
                         >
                              <Row justify='space-around' gutter={[16,16]}>
                                   { programa ? (
                                        <>
                                             <Col span={24}>
                                                  <Descriptions layout="horizontal" bordered={true}>
                                                       <Descriptions.Item label='Nombre de rutina'> <Text type="warning" strong> {this.props.programaRMPSeleccionado.rutina} </Text> </Descriptions.Item>
                                                       <Descriptions.Item label='Categoría'>{this.props.programaRMPSeleccionado.categoria}</Descriptions.Item>
                                                       <Descriptions.Item label='Nomenclatura'>{this.props.programaRMPSeleccionado.nomenclatura}</Descriptions.Item>
                                                       <Descriptions.Item label='Programa'>{this.props.programaRMPSeleccionado.programa}</Descriptions.Item>
                                                       <Descriptions.Item label='Tipo'>{this.props.programaRMPSeleccionado.tipoRutina}</Descriptions.Item>
                                                       <Descriptions.Item label='Clave R/M/P'>{this.props.programaRMPSeleccionado.claveRutina}</Descriptions.Item>
                                                       <Descriptions.Item label='Personal'>{this.props.programaRMPSeleccionado.personal}</Descriptions.Item>
                                                       <Descriptions.Item label='EPP'>{this.props.programaRMPSeleccionado.epp}</Descriptions.Item>
                                                       <Descriptions.Item label='Equipo/Herramienta'>{this.props.programaRMPSeleccionado.herramientas}</Descriptions.Item>
                                                       <Descriptions.Item label='Actividades'>{this.props.programaRMPSeleccionado.actividades}</Descriptions.Item>
                                                  </Descriptions>
                                             </Col>
                                             <Col span={24}>
                                                  <Row justify='space-around' gutter={[8,8]}>
                                                       <Col span={12}>
                                                            <Divider orientation="left">Insumos</Divider>
                                                            <Table 
                                                                 size='small'
                                                                 rowKey='descripcion'
                                                                 columns={columns}
                                                                 dataSource={programaRMPSeleccionado.insumosByIdRutina}
                                                                 locale={{ emptyText: 'Sin datos' }}
                                                                 pagination={{
                                                                      total: programaRMPSeleccionado.insumosByIdRutina ? programaRMPSeleccionado.insumosByIdRutina.length : 0,
                                                                      pageSize: programaRMPSeleccionado.insumosByIdRutina ? programaRMPSeleccionado.insumosByIdRutina.length : 0,
                                                                      hideOnSinglePage: true
                                                                 }}
                                                                 scroll={{ y: 450 }}
                                                            />
                                                            <br/>
                                                       </Col>
                                                       <Col span={12}>
                                                            <Divider orientation="left">Herramientas</Divider>
                                                            <Table 
                                                                 size='small'
                                                                 rowKey='descripcion'
                                                                 columns={columns}
                                                                 dataSource={programaRMPSeleccionado.herramientasByIdRutina ? programaRMPSeleccionado.herramientasByIdRutina : []}
                                                                 locale={{ emptyText: 'Sin datos' }}
                                                                 pagination={{
                                                                      total: programaRMPSeleccionado.herramientasByIdRutina ? programaRMPSeleccionado.herramientasByIdRutina.length : 0,
                                                                      pageSize: programaRMPSeleccionado.herramientasByIdRutina ? programaRMPSeleccionado.herramientasByIdRutina.length : 0,
                                                                      hideOnSinglePage: true
                                                                 }}
                                                                 scroll={{ y: 450 }}
                                                            />
                                                       </Col>
                                                  </Row>
                                             </Col>
                                   
                                             { programa === 'Rutina' ? ( <> 
                                                  <Col span={24}>
                                                       <br/>
                                                       <Steps current={this.state.current} type="navigation" size="small">
                                                            <Step  title={'Ubicaciones asignadas a la rutina: ' + programaRMPSeleccionado.rutina} icon={ <a style={{ color: "#ff8c62" }}><AppstoreAddOutlined /> </a>  } />
                                                            <Step title="Fecha de Ejecución" icon={ <a style={{ color: "#ff8c62" }}>  <CalendarOutlined /> </a>} disabled={true} />
                                                       </Steps>
                                                  </Col>
                                                  { this.state.current === 0 ? (<>
                                                       <Col span={24}>
                                                            <Table 
                                                                 size='small'
                                                                 rowKey='idAsignacionServicioPlanificado'
                                                                 columns={columnsUbi}
                                                                 dataSource={dataUbi[0].data}
                                                                 locale={{ emptyText: 'Sin datos' }}
                                                                 pagination={{
                                                                      total: dataUbi[0].data.length,
                                                                      pageSize: dataUbi[0].data.length,
                                                                      hideOnSinglePage: true
                                                                 }}
                                                                 scroll={{ x: 1500, y: 450 }}
                                                            />
                                                       </Col>

                                                       <Col span={11}>
                                                            <Button style={{width:'100%'}} className='btnPrueba' disabled={boolBut} onClick={onProgramarRutina} loading={fetchingProgramarRMP}>
                                                                 Programar
                                                            </Button>
                                                       </Col>
                                                  </>): false}
                                             
                                             { this.state.current === 1 ? (<>

                                                  <Divider orientation='left'>{this.state.ubiCOA}</Divider>
                                                  <Col span={5}>
                                                       <Form.Item
                                                            label="Tipo de frecuencia"
                                                            name="frecuencia"
                                                       >
                                                            <Input disabled style={{ textAlign: 'left' }} />
                                                       </Form.Item>
                                                  </Col>

                                                  <Col span={5}>
                                                       <Tooltip placement="bottom" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                                            <Form.Item
                                                                 label="Hora propuesta"
                                                                 name="horaPropuesta"
                                                                 rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                            >
                                                                 <TimePicker showNow={false} format='HH:mm' placeholder={["Hora propuesta"]} style={{width: '100%'}} />
                                                            </Form.Item>
                                                       </Tooltip>
                                                  </Col>

                                                  { programaRMPSeleccionado.fechaEspecifica ? (<>
                                                       <Form.List name="fechas">
                                                            {(fields, { add, remove }) => (
                                                            <>
                                                            {fields.map(field => (
                                                            <Row justify='space-around' key={field.key}>
                                                                 
                                                                 <Col span={17}>
                                                                      <Form.Item
                                                                           {...field}
                                                                           label='Fecha'
                                                                           name={[field.name, 'fecha']}
                                                                           fieldKey={[field.fieldKey, 'fecha']}
                                                                           rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                                      >
                                                                           

                                                                           <DatePicker 
                                                                                onChange={(value) => onFecha(value, field)}
                                                                                style={{width: '100%'}} 
                                                                                placeholder='Selecciona una fecha'
                                                                                format='DD-MM-YYYY'
                                                                                disabledDate={disabledDate}
                                                                                allowClear={false}
                                                                           />
                                                                      </Form.Item>
                                                                 </Col>
                                                                 <Col span={2}>
                                                                      {fields.length > 1 ? (
                                                                      <Form.Item>
                                                                           <MinusCircleOutlined className='borrarInsumo' onClick={() => remove(field.name)} />
                                                                      </Form.Item>
                                                                      ):false}
                                                                 </Col>
                                                            </Row>
                                                            ))}

                                                            <Col span={24}>
                                                                 <Form.Item>
                                                                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                           Agregar Fecha
                                                                      </Button>
                                                                 </Form.Item>
                                                            </Col>

                                                            <Divider />

                                                            <Col span={11}>
                                                                 <Button htmlType='submit' className="btnFiltrar" style={{width: '100%'}}>
                                                                           Guardar
                                                                 </Button>
                                                            </Col>

                                                            </>
                                                            )}
                                                       </Form.List>

                                                  </>): false }

                                                  { !programaRMPSeleccionado.fechaEspecifica  ? (<>
                                                       <Col span={5}>
                                                            <Form.Item
                                                                 label="Días de frecuencia"
                                                                 name="dias"
                                                            >
                                                                 <Input disabled style={{ textAlign: 'right' }} />
                                                            </Form.Item>
                                                       </Col>
                                                       <Col span={5}>
                                                            <Form.Item
                                                                 name='fechaInicio'
                                                                 label='Fecha de inicio'
                                                                 rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                                            >
                                                                 <DatePicker
                                                                      style={{width: '100%'}} 
                                                                      placeholder='Selecciona una fecha'
                                                                      format='DD-MM-YYYY'
                                                                      disabledDate={disabledDate}
                                                                      onChange={(value) => onFecha(value)}
                                                                 />
                                                            </Form.Item>
                                                       </Col>

                                                       <Divider />

                                                       <Col span={11}>
                                                            <Button htmlType='submit' className="btnFiltrar" style={{width: '100%'}}>
                                                                      Guardar
                                                            </Button>
                                                       </Col>
                                                  </>): false }
                                             </>): false}
                                             
                                        </> ) : false }
                                        
                                        { programa === 'Mantenimiento' || programa === 'Prueba' ? ( <> 
                                             <Col span={23}>
                                                  <br/>
                                                  <Steps current={this.state.current} type="navigation" size="small">
                                                       <Step title={'Activo para: ' + programaRMPSeleccionado.rutina} icon={<a style={{ color: "#ff8c62" }}> <AppstoreAddOutlined /> </a>} />
                                                       <Step title="Fecha de Ejecución" icon={<a style={{ color: "#ff8c62" }}> <CalendarOutlined /> </a>} />
                                                  </Steps>
                                                  <br/>
                                             </Col>

                                             { this.state.current === 0 ? (<>
                                                  <Col span={23}>
                                                       <br/>
                                                       <Table 
                                                            size='small'
                                                            rowKey='idAsignacionServicioPlanificado'
                                                            columns={columnsAct}
                                                            dataSource={dataAct[0].data}
                                                            locale={{ emptyText: 'Sin datos' }}
                                                            pagination={{
                                                                 total: dataAct[0].data.length,
                                                                 pageSize: dataAct[0].data.length,
                                                                 hideOnSinglePage: true
                                                            }}
                                                            scroll={{ x: 1500, y: 450 }}
                                                       />
                                                  </Col>

                                                  <Col span={11}>
                                                       <br/>
                                                       <Button style={{width:'100%'}} className='btnPrueba' disabled={boolButAct} onClick={onProgramarRutina}>
                                                            Programar
                                                       </Button>
                                                  </Col>
                                             </>): false}

                                             { this.state.current === 1 ? (<>

                                                  <Divider orientation='left'>{this.state.actDes}</Divider>
                                                  <Col span={5}>
                                                       <Form.Item
                                                            label="Tipo de frecuencia"
                                                            name="frecuencia"
                                                       >
                                                            <Input disabled style={{ textAlign: 'left' }} />
                                                       </Form.Item>
                                                  </Col>

                                                  <Col span={5}>
                                                       <Tooltip placement="bottom" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                                            <Form.Item
                                                                 label="Hora propuesta"
                                                                 name="horaPropuesta"
                                                                 rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                            >
                                                                 <TimePicker showNow={false} format='HH:mm' placeholder={["Hora propuesta"]} style={{width: '100%'}} />
                                                            </Form.Item>
                                                       </Tooltip>
                                                  </Col>

                                                  { programaRMPSeleccionado.fechaEspecifica ? (<>
                                                       <Form.List name="fechas">
                                                            {(fields, { add, remove }) => (
                                                            <>
                                                            {fields.map(field => (
                                                            <Row justify='space-around' key={field.key}>
                                                                 
                                                                 <Col span={17}>
                                                                      <Form.Item
                                                                           {...field}
                                                                           label='Fecha'
                                                                           name={[field.name, 'fecha']}
                                                                           fieldKey={[field.fieldKey, 'fecha']}
                                                                           rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                                      >
                                                                           

                                                                           <DatePicker 
                                                                                onChange={(value) => onFecha(value, field)}
                                                                                style={{width: '100%'}} 
                                                                                placeholder='Selecciona una fecha'
                                                                                format='DD-MM-YYYY'
                                                                                disabledDate={disabledDate}
                                                                                allowClear={false}
                                                                           />
                                                                      </Form.Item>
                                                                 </Col>
                                                                 <Col span={2}>
                                                                      {fields.length > 1 ? (
                                                                      <Form.Item>
                                                                           <MinusCircleOutlined className='borrarInsumo' onClick={() => remove(field.name)} />
                                                                      </Form.Item>
                                                                      ):false}
                                                                 </Col>
                                                            </Row>
                                                            ))}

                                                            <Col span={24}>
                                                                 <Form.Item>
                                                                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                                           Agregar Fecha
                                                                      </Button>
                                                                 </Form.Item>
                                                            </Col>

                                                            <Divider />

                                                            <Col span={11}>
                                                                 <Button htmlType='submit' className="btnFiltrar" style={{width: '100%'}}>
                                                                      Guardar
                                                                 </Button>
                                                            </Col>

                                                            </>
                                                            )}
                                                       </Form.List>

                                                  </>): false }

                                                  { !programaRMPSeleccionado.fechaEspecifica  ? (<>
                                                       <Col span={5}>
                                                            <Form.Item
                                                                 label="Días de frecuencia"
                                                                 name="dias"
                                                            >
                                                                 <Input disabled style={{ textAlign: 'right' }} />
                                                            </Form.Item>
                                                       </Col>
                                                       <Col span={5}>
                                                            <Form.Item
                                                                 name='fechaInicio'
                                                                 label='Fecha de inicio'
                                                                 rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                                            >
                                                                 <DatePicker
                                                                      style={{width: '100%'}} 
                                                                      placeholder='Selecciona una fecha'
                                                                      format='DD-MM-YYYY'

                                                                      disabledDate={disabledDate}
                                                                      onChange={(value) => onFecha(value)}
                                                                 />
                                                            </Form.Item>
                                                       </Col>

                                                       <Divider />

                                                       <Col span={11}>
                                                            <Button htmlType='submit' className="btnFiltrar" style={{width: '100%'}}>
                                                                      Guardar
                                                            </Button>
                                                       </Col>
                                                  </>): false }
                                             </>): false}
                                        </> ) : false }
                                   </> ) : false }
                                   
                              </Row>
                         </Form>
                         ): false }
                    </Spin>
               </Modal>
          );
     }
}

const mapStateToProps = state => {
     return {
          showModalProgramarRMP: state.ServiciosPlanificadosReducer.showModalProgramarRMP,
          programa: state.ServiciosPlanificadosReducer.programa,
          programaRMPSeleccionado: state.ServiciosPlanificadosReducer.programaRMPSeleccionado,
          fetchingProgramarRMP: state.ServiciosPlanificadosReducer.fetchingProgramarRMP,

          page: state.ServiciosPlanificadosReducer.page,
          busqueda: state.ServiciosPlanificadosReducer.busqueda,
          programa: state.ServiciosPlanificadosReducer.programa, 
          idsServicios: state.ServiciosPlanificadosReducer.idsServicios,
          listaRMPBusqueda: state.ServiciosPlanificadosReducer.listaRMPBusqueda
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onCloseModal: () => {
               dispatch({ type: 'SHOW_MODAL_PROGRAMAR_RMP', showModalProgramarRMP: false });
          },
          onProgramarRMP: (page, busqueda, dataProgramar, programa, idsServicios) => {
               dispatch({ type: 'PROGRAMAR_RMP_REQUEST', page, busqueda, dataProgramar, programa, idsServicios });
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalProgramar);
