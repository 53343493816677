import React, { Component } from 'react';
import {Row, Col, Modal, Table, Button, Spin} from 'antd';
import { CalendarOutlined, DeleteOutlined} from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import {connect} from 'react-redux';
import ModalReprogramarTarea from './ModalReprogramarTarea';
const confirm = Modal.confirm;

class ModalEditarOrden extends Component {
    
    render() { 

        const {showModalEditarOrden, onCerrarModal, tareaSeleccionada,
               onEliminarTarea, onShowModalReprogramarTarea, page, busqueda,
               fetchingReprogramarTarea, fetchingGenerarOrden, onGenerarOrden,
            } = this.props;
               
        const onGenerar = () => {
            onGenerarOrden(tareaSeleccionada, page, busqueda, tareaSeleccionada.idRutina);
        }

        const handleCancel = () => {
            onCerrarModal();
        };

        const showReprogramar = (key) => {
            console.log('key', key);
            onShowModalReprogramarTarea(key, page, busqueda);
        };

        const eliminarTarea = key => {
            console.log(key);
            confirm({
                title: '¿Estás seguro que deseas eliminar la tarea?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEliminarTarea(key, busqueda, page, tareaSeleccionada.idRutina);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        };

        const columns =[{
            title: 'Clave Ubicación',
            dataIndex: 'claveUbicacion',
            key: 'claveUbicacion',
            align: 'center',
        },{
            title: 'Activo',
            dataIndex: 'descripcion',
            key: 'descripcion',
            align: 'center',
        }];

        const columnsAnidada = [{
            title: 'Fecha Tarea',
            dataIndex: 'fecha',
            key: 'fecha',
            align: 'center',
        },{
            title: 'Estado Rutina',
            dataIndex: 'estadoRutina',
            key: 'estadoRutina',
        },{
            title: 'Reprogramar',
            key: 'reprogramar',
            align: 'center',
            render: (record) => (
               <a className='editarIcono'  onClick={ () => showReprogramar(record) }> <CalendarOutlined />
               </a>
            ),          
        }, {
            title: 'Eliminar',
            key: 'eliminar',
            align: 'center',
            render: (record) => (
               <a className='editarIcono'  onClick={ () => eliminarTarea(record) }> <DeleteOutlined />
               </a>
            ),          
        }]

        

        return ( 
            <div>
                {tareaSeleccionada ? (
                
                <Modal
                visible={showModalEditarOrden}
                title={`Generar Orden`}
                onCancel={handleCancel}
                footer={false}
                width='60%'
                >
                    <Spin spinning={fetchingGenerarOrden}>
                        <Row >
                            <Col span={24}>
                                <br />
                                <Table
                                    size="small"
                                    rowKey="idProgramacionRutina"
                                    columns={columns}
                                    dataSource={tareaSeleccionada.programacionRutinasByIdProgramacionRutina}
                                    loading={fetchingReprogramarTarea}
                                    locale={{ emptyText: 'Sin datos' }}
                                    expandable={{expandedRowRender: record => <Table rowKey="idFechaRutina" style={{ backgroundColor: '#CACFD2', padding: '2em'}} columns={columnsAnidada} dataSource={record.fechasRutinasByIdProgramacion} pagination={false} />} }
                                    pagination={false}
                                >

                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <br/>
                                    <Button className="btnFiltrar" style={{ width: '100%' }} onClick={onGenerar}>Generar Orden</Button>
                            </Col>
                        </Row>
                    </Spin>
                    <ModalReprogramarTarea/>
                </Modal>
                ): false}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showModalEditarOrden: state.ServiciosPlanificadosReducer.showModalEditarOrden,
        tareaSeleccionada: state.ServiciosPlanificadosReducer.tareaSeleccionada,
        page: state.ServiciosPlanificadosReducer.page,
        busqueda: state.ServiciosPlanificadosReducer.busqueda,
        fetchingReprogramarTarea: state.ServiciosPlanificadosReducer.fetchingReprogramarTarea,
        listaProgramacionOrden: state.ServiciosPlanificadosReducer.listaProgramacionOrden,
        fetchingGenerarOrden: state.ServiciosPlanificadosReducer.fetchingGenerarOrden,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCerrarModal: () => {
            dispatch({ type: "SHOW_MODAL_EDITAR_ORDEN", showModalEditarOrden: false, tareaSeleccionada: null});
        },
        onEliminarTarea: (tarea, busqueda, page, idRutina) => {
            dispatch({ type: "ELIMINAR_TAREA_ORDEN_REQUEST", tarea, busqueda, page, idRutina});
        },
        onShowModalReprogramarTarea: (tareaSeleccionadaReprogramacion, page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_REPROGRAMAR_TAREA_ORDEN", showModalReprogramarTarea: true, tareaSeleccionadaReprogramacion, page, busqueda})
        },
        onGenerarOrden: (tareaSeleccionada, page , busqueda, idRutina) => {
            dispatch({ type: "GENERAR_ORDEN_PROGRAMA_REQUEST" , tareaSeleccionada, page , busqueda, idRutina})
        },
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarOrden);