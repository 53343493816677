import React, { Component } from 'react';
import {Form, Row, Col, Modal, Select, Input, InputNumber, Button, DatePicker, message, Spin} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';
import moment from 'moment';

const { Option } = Select;

class ModalAltaIndicadoresDesempeno extends Component {
    formRef = React.createRef();

    state={ disabledObjetivo: true, dateString: '' }

     render() {
          const { onCloseModalAlta, page, busqueda, indicadorDesempeno, fechaVigencia, showModalAltaIndicaresDesempeno, listaClasificacionesGerenciaActivas, fetchingListaClasificacionesGerenciaActivas, onGetObjetivosByGerencia, listaInfoProyecto, onAltaIndicador, fetchingAltaIndicadorDesempeno, limpiarFormulario, objetivosByGerencia } = this.props;

          if (limpiarFormulario && limpiarFormulario === 'crearIndicadorDesempeno') {
               this.formRef.current.resetFields();
               this.props.onLimpiarFormulario();
          }

          const handleCancel = () => {
               onCloseModalAlta();
               this.formRef.current.resetFields();
               this.setState({ disabledObjetivo: true });
          };

          const onFinish = (formulario) => {
               console.log('Success:', formulario);
               onAltaIndicador(formulario, page, busqueda, this.state.dateString, indicadorDesempeno, fechaVigencia);
          };

          const onFinishFailed = (errorInfo) => {
               console.log('Failed:', errorInfo);
          };

          const selectGerencia = (value) => { 
               if(value){
                    this.formRef.current.resetFields(['objetivo']);
                    this.setState({ disabledObjetivo: false });
                    onGetObjetivosByGerencia(value.split(' - ')[0]);
               }
          };

          const fecha = (date, dateString) => {
               if(date){
                    if(listaInfoProyecto.fechaInicio && date){
                         const diferencia = listaInfoProyecto.fechaInicio.diff(moment(date.format('YYYY-MM-DD')), "days");
                         const fechaVigenciaNuevo = listaInfoProyecto.fechaVigencia.split('-')[2] + '-' + listaInfoProyecto.fechaVigencia.split('-')[1] + '-' + listaInfoProyecto.fechaVigencia.split('-')[0];
                         const fechaVigenciaLocal = new Date(fechaVigenciaNuevo);

                         if(diferencia>0){
                              message.error('No es posible seleccionar una fecha de inicio menor al inicio de contrato del proyecto', 5);
                              this.formRef.current.resetFields(['vigencia']);
                         }

                         if(date > fechaVigenciaLocal){
                              message.error('No es posible seleccionar una fecha de mayor a la vigencia del contrato del proyecto', 5);
                              this.formRef.current.resetFields(['vigencia']);
                         }

                         this.setState({ dateString: dateString.split('-').reverse().join('-') });
                    }
                    else{
                         message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                         this.formRef.current.resetFields(['vigencia']);
                    }
               }
          };

          //En caso de que soliciten que se vea el "%"
          //formatter={value => `${value}%`} parser={value => value.replace('%', '')}
        
          return (
               <Modal
                    visible={showModalAltaIndicaresDesempeno}
                    title='Nuevo Indicador de Desempeño'
                    onCancel={handleCancel}
                    footer={false}
                    width={'60%'}
               >
                    <Spin spinning={fetchingAltaIndicadorDesempeno}>
                         <Form name="formulario" onFinish={onFinish}  ref={this.formRef} layout='vertical'>
                              <Row justify='space-around'>
                                   <Col span={11}>
                                        <Form.Item name='gerencia' label='Gerencia' rules={[{ required: true, message: 'Favor de llenar el campo'}]}>
                                             <Select style={{ width: '100%', textAlign: 'left' }} showSearch placeholder="Selecciona la gerencia" onChange={selectGerencia} loading={fetchingListaClasificacionesGerenciaActivas}>
                                                  {listaClasificacionesGerenciaActivas.map(option => 
                                                       <Option key={option.idClasificacionGerencia + ' - ' + option.nombreGerencia} >
                                                            {option.idClasificacionGerencia + ' - ' + option.nombreGerencia}
                                                       </Option>
                                                  )}
                                             </Select>
                                        </Form.Item>
                                   </Col>

                                   <Col span={11}>
                                        <Form.Item name='objetivo' label='Objetivo' rules={[{ required: true, message: 'Favor de llenar el campo'}]}>
                                             <Select style={{ width: '100%', textAlign: 'left' }} showSearch placeholder="Selecciona el objetivo" disabled={this.state.disabledObjetivo || objetivosByGerencia.length === 0}>
                                                  {objetivosByGerencia.map(option => 
                                                       <Option key={option.idObjetivo + ' - ' + option.objetivo} >
                                                            {option.idObjetivo + ' - ' + option.objetivo}
                                                       </Option>
                                                  )} 
                                             </Select>
                                        </Form.Item>
                                   </Col>

                                   <Col span={11}>
                                        <Form.Item name='indicadorDesempeno' label='Indicador de desempeño' rules={[{ required: true, message: 'Favor de llenar el campo'}]}>
                                             <Input maxLength={50} placeholder='Ingresa el indicador de desempeño'/>
                                        </Form.Item>
                                   </Col>

                                   <Col span={11}>
                                        <Form.Item name='origenInformacion' label='Origen de la información' rules={[{ required: true, message: 'Favor de llenar el campo'}]}>
                                             <Input maxLength={50} placeholder='Ingresa el origen de la información'/>
                                        </Form.Item>
                                   </Col>

                                   <Col span={11}>
                                        <Form.Item name='parteInformacion' label='Parte que genera la información inicial' rules={[{ required: true, message: 'Favor de llenar el campo'}]}>
                                             <Input maxLength={50} placeholder='Ingresa la información inicial'/>
                                        </Form.Item>
                                   </Col>

                                   <Col span={11}>
                                        <Form.Item name='valorEsperado' label='Valor esperado' rules={[{ required: true, message: 'Favor de llenar el campo'}]}>
                                             <InputNumber style={{width: '100%'}} precision={2} placeholder='Ingresa el valor esperado'/>
                                        </Form.Item>
                                   </Col>

                                   <Col span={11}>
                                        <Form.Item name='vigencia' label='Vigencia' rules={[{ required: true, message: 'Favor de llenar el campo'}]}>
                                             <DatePicker onChange={fecha} style={{ width: '100%' }} format='DD-MM-YYYY' placeholder={["Seleccione fecha vigencia"]} />
                                        </Form.Item>
                                   </Col>

                                   <Col span={11}>
                                        <Form.Item name='frecuenciaEvaluacion' label='Frecuencia de evaluación en días' rules={[{ required: false, message: 'Favor de llenar el campo'}]}>
                                             <InputNumber min={0} max={364} precision={0} placeholder='Ingresa la frecuencia de evaluación en días'/>
                                        </Form.Item>
                                   </Col>

                                   <Col span={11}>
                                        <br />
                                        <Button className="nuevoUsuario" htmlType='submit'>
                                             Guardar
                                        </Button>
                                   </Col>
                              </Row>
                         </Form>
                    </Spin>
               </Modal>
          )
     }
}

const mapStateToProps = state => {
     return {
          showModalAltaIndicaresDesempeno: state.EvaluacionDeLaGestionReducer.showModalAltaIndicaresDesempeno,
          page: state.EvaluacionDeLaGestionReducer.page,
          busqueda: state.EvaluacionDeLaGestionReducer.busqueda,
          indicadorDesempeno: state.EvaluacionDeLaGestionReducer.indicadorDesempeno,
          fechaVigencia: state.EvaluacionDeLaGestionReducer.fechaVigencia,
          listaClasificacionesGerenciaActivas: state.EvaluacionDeLaGestionReducer.listaClasificacionesGerenciaActivas,
          fetchingListaClasificacionesGerenciaActivas: state.EvaluacionDeLaGestionReducer.fetchingListaClasificacionesGerenciaActivas,
          listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
          fetchingAltaIndicadorDesempeno: state.EvaluacionDeLaGestionReducer.fetchingAltaIndicadorDesempeno,
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          objetivosByGerencia: state.EvaluacionDeLaGestionReducer.objetivosByGerencia,
     };
};

const mapDispatchToProps = dispatch => {
     return {
          onCloseModalAlta: () => {
               dispatch({ type: 'SHOW_MODAL_ALTA_INDICADORES_DESEMPENO', showModalAltaIndicaresDesempeno: false });
          },
          onGetObjetivosByGerencia: (idClasificacionGerencia) => {
               dispatch({ type: 'OBJETIVOS_BY_GERENCIA_REQUEST', idClasificacionGerencia });
          },
          onAltaIndicador: (formulario, page, busqueda, dateString, indicadorDesempeno, fechaVigencia) => {
               dispatch({ type: 'ALTA_INDICADOR_DESEMPENO_REQUEST', formulario, page, busqueda, dateString, indicadorDesempeno, fechaVigencia });
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAltaIndicadoresDesempeno);
