import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import { Row, Col, Modal, Spin, InputNumber, Input, Button, DatePicker, Form, Select } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import EditarCategoria from './EditarCategoria';
import VerificarContraseña from './VerificarContraseña';
import VerificarEdicion from './VerificarEdicion';
import ArchivoEditarCategoria from './ArchivoEditarCategoria';

const { RangePicker } = DatePicker;
const {Option} = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const ModalEditarNivelTiempoR = props => {

    const {showModalEditarNivelTiempoRespuesta,vistaModalNivelTiempoRespuesta,formularioNivelTiempoRespuesta,
        onShowModalEditarNivelTiempoR, onLimpiarFormulario, onCambiarVista
    } = props;

    const ContenidoEditar = () => {
        if(vistaModalNivelTiempoRespuesta === 1) {
            return <EditarCategoria />
        }
        if(vistaModalNivelTiempoRespuesta === 2) {
            return <VerificarContraseña />
        }
        if(vistaModalNivelTiempoRespuesta === 3) {
            return <VerificarEdicion />
        }
        if(vistaModalNivelTiempoRespuesta === 4) {
            return <ArchivoEditarCategoria />
        }
    }

    const handleCancel = () => {
        onShowModalEditarNivelTiempoR();
        onLimpiarFormulario('');
        onCambiarVista(1);
    }

    return (
        <>
        <Modal 
            visible={showModalEditarNivelTiempoRespuesta}
            title='Editar Nivel de Tiempo de Respuesta por Sección'
            onCancel={handleCancel}
            footer={false}
            width='70%'
        >
            <ContenidoEditar />
        </Modal>
        </>
    )
}

const mapStateToProps = state => {
    return {
        showModalEditarNivelTiempoRespuesta: state.MecanismosDePagosReducer.showModalEditarNivelTiempoRespuesta,
        vistaModalNivelTiempoRespuesta: state.MecanismosDePagosReducer.vistaModalNivelTiempoRespuesta,
        formularioNivelTiempoRespuesta: state.MecanismosDePagosReducer.formularioNivelTiempoRespuesta,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarNivelTiempoR: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_NIVEL_TIEMPO_RESPUESTA', showModalEditarNivelTiempoRespuesta: false });
        },
        onLimpiarFormulario: (limpiarFormulario) => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: limpiarFormulario});
        },
        onCambiarVista: (vistaModalNivelTiempoRespuesta) => {
            dispatch({ type: 'CAMBIAR_VISTA_NIVEL_TIEMPO_RESPUESTA', vistaModalNivelTiempoRespuesta})
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalEditarNivelTiempoR);