import React, {Component} from 'react';
import { Form, Spin, Row, Col, Input, Button, DatePicker, message} from "antd";
import {connect} from 'react-redux';


import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalContraseña from '../ModalContraseña';

const { TextArea } = Input;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class CancelarForm extends Component {
    formRef = React.createRef();
    state = {
        formulario: null,
    }
    render() { 
        const {fetchingCambioEstadoSeguimiento, onActualizarSeguimiento, 
               folioSolicitudServicioSeleccionado, page, busqueda ,
               onShowModalContraseña, isEspecial,} = this.props;

               let permisosUsuario = sessionStorage.getItem('permisosUsuario')
               if(permisosUsuario[0]){
                   permisosUsuario = JSON.parse(permisosUsuario);
               }      
               if(isEspecial){
                    onActualizarSeguimiento(this.state.formulario, folioSolicitudServicioSeleccionado, page, busqueda);
                    onShowModalContraseña(false, false);
               };
       
               const onFinish = (formulario) => {
                   console.log(formulario)
                   if(permisosUsuario.especialSolicitudDeServicio){
                       this.setState({ formulario })
                       onShowModalContraseña(true, false);
                   } else {
                       onActualizarSeguimiento(formulario, folioSolicitudServicioSeleccionado, page, busqueda);
                   }
                   
               };

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo);
        };

        return ( 
            <div>
                <Spin spinning={fetchingCambioEstadoSeguimiento}>
                {folioSolicitudServicioSeleccionado ? (
                  <Form {...layout}
                       name='formulario'
                       onFinish={onFinish}
                       initialValues={folioSolicitudServicioSeleccionado}
                       
                       ref={this.formRef} 
                       >
                           <Row type='flex' justify='space-around'>
                                <Col span={23}>
                                   <Form.Item
                                      label="Observaciones"
                                      name="observacionesF"
                                      rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <TextArea
                                        rows={1}
                                        pattern="^^[ñíóáéú a-zA-Z 0-9]+$"
                                        title="Observaciones de cancelación debe tener letras y numeros."
                                        maxLength={100} placeholder='Ingrese observaciones' />
                                  </Form.Item>
                                </Col>

                               
                                <Col span={23}>
                                <Button className="btnFiltrar" style={{ width: '40%' }} htmlType="submit">Registrar</Button>
                                  </Col>
                            </Row>
                           

                     </Form>
                     ): false }
                </Spin>
                <ModalContraseña/>
            </div>
         );
    }
}

const mapStateToProps = (state) => {
    return {
        fetchingCambioEstadoSeguimiento: state.SolicitudDeServiciosReducer.fetchingCambioEstadoSeguimiento,
        folioSolicitudServicioSeleccionado: state.SolicitudDeServiciosReducer.folioSolicitudServicioSeleccionado,
        listaTrazabilidad: state.SolicitudDeServiciosReducer.listaTrazabilidad,
        page: state.SolicitudDeServiciosReducer.page,
        busqueda: state.SolicitudDeServiciosReducer.busqueda,
        isEspecial: state.SolicitudDeServiciosReducer.isEspecial,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onActualizarSeguimiento: (formulario, folioSolicitudServicioSeleccionado, page, busqueda) => {
                dispatch({ type: "CANCELAR_FOLIO_SS_REQUEST", formulario, folioSolicitudServicioSeleccionado , page, busqueda});
        },
        onShowModalContraseña: (showModalContraseña, isEspecial) => {
            dispatch({ type: "SHOW_MODAL_CONTRASEÑA", showModalContraseña , isEspecial})
        } 
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(CancelarForm);