import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, DatePicker, Row, message, Upload, Typography } from 'antd';
import { UploadOutlined, FilePdfFilled } from '@ant-design/icons';
import moment from 'moment';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const { RangePicker } = DatePicker;
const { Dragger } = Upload;
const { Text, Paragraph } = Typography;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalEditarContrato extends Component {
    formRef = React.createRef();
    state = {
        rangoVigencia: '',
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
        buenRango: false,
        uploadOk: true,
    }
    render() {
        const { fetchingEditarContrato, showModalEditarContrato, onEditarContrato, onShowModalEditarContrato,
            contratoSeleccionado, listaProveedores, limpiarFormulario, onLimpiarFormulario, listaInfoProyecto, page
        } = this.props;

        if(limpiarFormulario && limpiarFormulario === 'editarContrato'){
            console.log('entró a limpiar');
            this.formRef.current.resetFields();
            this.setState({
                filePDF: false,
                botonPDF: false,
                botonEditar: false,
                uploadOk: false,
            });
            onLimpiarFormulario();
        }
        
        const onFinish = formulario => {
            console.log('Success:', formulario);
            if(this.state.uploadOk){
                if(this.state.rangoVigencia){
                    if(this.state.buenRango === true){
                        formulario.rangoVigencia = this.state.rangoVigencia[0].format('YYYY-MM-DD') + "," + this.state.rangoVigencia[1].format('YYYY-MM-DD');
                        onEditarContrato(formulario, contratoSeleccionado, listaProveedores, page);
                    }
                    else{
                        message.error('Favor de seleccionar una fecha correcta');
                    }
                }
                else{
                    formulario.rangoVigencia = contratoSeleccionado.fechaInicio.split('-')[2] + '-' + contratoSeleccionado.fechaInicio.split('-')[1] + '-' + contratoSeleccionado.fechaInicio.split('-')[0] + ',' + contratoSeleccionado.fechaFin.split('-')[2] + '-' + contratoSeleccionado.fechaFin.split('-')[1] + '-' + contratoSeleccionado.fechaFin.split('-')[0];
                    onEditarContrato(formulario, contratoSeleccionado, listaProveedores, page);
                }
            }
            else{
                message.warning('Favor de seleccionar un contrato.');
            }
        };
        

        const handleCancel = () => {
            onShowModalEditarContrato();
            this.setState({
                filePDF: false,
                botonPDF: false,
                botonEditar: false,
                uploadOk: false,
            });
            this.formRef.current.resetFields();
        }
        
        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                })
            }
            var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
            if (info.file.status === 'done') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true,
                        uploadOk: true,
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true,
                        uploadOk: true,
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }
        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
                uploadOk: false,
            });
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };
        const fechas = (rangeValue) => {
            if (rangeValue) {
                if(listaInfoProyecto.fechaInicio){
                    console.log('listaInfoProyecto');
                    console.log(listaInfoProyecto);
                    const fechaInicio = listaInfoProyecto.fechaInicio.diff(moment(rangeValue[0].format('YYYY-MM-DD')), "days");
                    const fechaVigencia = moment([listaInfoProyecto.fechaVigencia.split('-')[2], (listaInfoProyecto.fechaVigencia.split('-')[1] - 1), listaInfoProyecto.fechaVigencia.split('-')[0]]).diff(moment(rangeValue[1].format('YYYY-MM-DD')), "days");
                    
                    if(fechaInicio>0){
                        message.error('No es posible seleccionar una fecha de inicio menor al inicio de contrato del proyecto', 7);
                        this.formRef.current.resetFields(['rangoVigencia']);
                        this.setState({
                            buenRango: false,
                        })
                    }
                    else if(fechaVigencia<0){
                        message.error('No es posible seleccionar una vigencia mayor al final de contrato del proyecto', 7);
                        this.formRef.current.resetFields(['rangoVigencia']);
                        this.setState({
                            buenRango: false,
                        })
                    }
                    else{
                        this.setState({
                            rangoVigencia: rangeValue,
                            buenRango: true,
                        })
                    }
                }
                else{
                    message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                    this.formRef.current.resetFields(['rangoVigencia']);
                }
            } else {
                message.warning('Favor de seleccionar un rango de vigencia.')
            }
        }

        return (
            <Modal
            visible={showModalEditarContrato}
            title='Editar Contrato'
            onCancel={handleCancel}
            footer={false}
            width={'70%'}
            >
                <Spin spinning={fetchingEditarContrato}>
                    {contratoSeleccionado ? (
                    <Form {...layout}
                    name="formulario"
                    initialValues={contratoSeleccionado}
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Row justify='space-around' gutter={[8,8]}>
                            <Col span={8}>
                                <Form.Item
                                    label="Clave contrato"
                                    name="clave"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={30} placeholder="Ingresa la clave" pattern='^[-A-Z._0-9]+$' title='Sólo se admiten letras mayúsculas'/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Descripción del contrato"
                                    name="descripcion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={150} placeholder="Ingresa la descripción" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Rango de vigencia"
                                    name='rangoVigencia'
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <RangePicker format='DD-MM-YYYY' placeholder={["Fecha Inicio","Fecha Fin"]} style={{width: '100%'}} onChange={fechas} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Contrato PDF"
                                    name="pdf"
                                >
                                    <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                        {this.state.filePDF === false ? (
                                        <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                            <UploadOutlined /> Click para subir procedimiento PDF
                                        </Button>
                                        ):false}
                                    </Upload>
                                    {/* <Dragger customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false} style={{borderRadius:'1em'}} fileList={this.state.fileList}>
                                        <Row justify='center'>
                                            <FilePdfFilled className='iconTabPane'/>
                                            <Col span={24} style={{paddingTop:'1em'}}>
                                                <Paragraph>
                                                    <Text strong> Click o arrastra a ésta área el procedimiento PDF </Text>
                                                </Paragraph>
                                            </Col>
                                        </Row>
                                    </Dragger> */}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Contrato actual"
                                    name="contratoActual"
                                >
                                    {contratoSeleccionado.rutaArchivo ? (
                                    <a target='_blank' href={contratoSeleccionado.rutaArchivo}>{contratoSeleccionado.rutaArchivo}</a>
                                    ):(
                                    <p>No cuenta con un procedimiento al momento</p>
                                    )}
                                </Form.Item>
                            </Col>
                        
                            <Col span={8} className='marginBoton'>
                                <br/>
                                <Button disabled={this.state.botonEditar} className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Editar contrato</Button>
                            </Col>
                        </Row>
                    </Form>
                    ):false}
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarContrato: state.InformacionOperativaReducer.fetchingEditarContrato,
        showModalEditarContrato: state.InformacionOperativaReducer.showModalEditarContrato,
        contratoSeleccionado: state.InformacionOperativaReducer.contratoSeleccionado,
        listaProveedores: state.InformacionOperativaReducer.listaProveedores,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        page: state.InformacionOperativaReducer.page,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarContrato: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_CONTRATO', showModalEditarContrato: false, contratoSeleccionado: null})
        },
        onEditarContrato: ( formulario, contratoSeleccionado, listaProveedores, page ) => {
            dispatch({ type: "EDITAR_CONTRATO_REQUEST" , formulario: formulario, contratoSeleccionado: contratoSeleccionado, listaProveedores: listaProveedores, page: page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarContrato);