import React, { Component } from 'react';
import { Row, Col, Modal, Descriptions, Table, Badge, Spin, Button, DatePicker, Input, Form } from 'antd';
import moment from 'moment';
import { CalendarOutlined} from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';
import ReprogramarTareas from '../VistaCalendarioSP/ReprogramarTareas';
const {TextArea} = Input;
class ModalCalendarioDetalle extends Component {
    formRef = React.createRef();
    state = {
        selectedRowKeys: []
    };
    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };
    render() {
        const { showModalCalendarioDetalle, onShowModalCalendarioDetalle, tareaSeleccionada, calendarioDetalle,
            fetchingCalendarioDetalle, onModalReprogramarTareaVC, fetchingReprogramarTareaMultiple, limpiarFormulario, onLimpiarFormulario, rmdp
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const handleCancel = () => {
            onShowModalCalendarioDetalle();
            this.setState({ selectedRowKeys:[] });
        }
        const columns = [{
                dataIndex: 'color',
                key: 'color',
                render: (text, record) => (
                    <Badge status={text}/>
                )
            },{
                title: 'Ubicación',
                dataIndex: 'ubicacion',
                key: 'ubicacion',
            },{
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
            },{
                title: 'Estado',
                dataIndex: 'estado',
                key: 'estado',
            },{
                title: 'Hora',
                dataIndex: 'hora',
                key: 'hora',
            },{
                title: 'Reprogramar',
                dataIndex: 'reprogramar',
                key: 'reprogramar',
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.reprogramarTarea ? true : record.estado === 'Realizada' ? true : false} onClick={ () => reprogramarTarea(record) }> <CalendarOutlined /></a>
                ), 
            }
        ];

        const columnsRMDP = [
            {
                dataIndex: 'color',
                key: 'color',
                render: (text, record) => (
                    <Badge status={text}/>
                )
            },{
                title: 'Ubicación',
                dataIndex: 'ubicacion',
                key: 'ubicacion',
            },
            {
                title: 'Familia',
                dataIndex: 'familia',
                key: 'familia',
            },

            {
                title: 'Subfamilia',
                dataIndex: 'subFamilia',
                key: 'subFamilia',
            },
            {
                title: 'Código interno',
                dataIndex: 'codigoInterno',
                key: 'codigoInterno',
            },
            {
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
            },{
                title: 'Estado',
                dataIndex: 'estado',
                key: 'estado',
            },{
                title: 'Hora',
                dataIndex: 'hora',
                key: 'hora',
            },
        ];

        if (limpiarFormulario && limpiarFormulario === 'reprogramarTareas') {
            this.formRef.current.resetFields();
            onLimpiarFormulario();
            this.setState({ selectedRowKeys:[] });
        }

        const disabledRange = fecha => {
            const currentYear = new Date();
            const year = currentYear.getFullYear();
            //let fechaEjecucion = tareaSeleccionada.calendarioProgramacionQueryResponse[0].fechaRutina.split('-').reverse().join('-');
            const fechaVigencia = moment(`31/12/${year}`, "DD-MM-YYYY")
            const fechaInicio = moment();
            if (fecha && fecha < fechaInicio) {
                return fecha && fecha < fechaInicio;
            } else {                
                return fecha && fecha > fechaVigencia;
            }
        }

        const reprogramarTarea = record => {
            onModalReprogramarTareaVC(record, this.props.busqueda, 0)
        }
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            getCheckboxProps: (record) => ({
                disabled: record.estado === 'Realizada'
            })
        };
        const onFinish = (formulario) => {
            this.props.onReprogramarTareaMultiple(formulario, selectedRowKeys, calendarioDetalle, this.props.busqueda);
        }
        
        return (
            <>
            {tareaSeleccionada ? (
                <Modal
                    visible={showModalCalendarioDetalle}
                    title={
                        <Row>
                            <Col span={24} className='díaCalendario'>
                                {tareaSeleccionada.fechaModal.split(', ')[0]}
                            </Col>
                            <Col span={24} className='fechaCalendario'>
                            {tareaSeleccionada.fechaModal.split(', ')[1]}
                            </Col>
                        </Row>
                    }
                    onCancel={handleCancel}
                    footer={false}
                    width={'60%'}
                >
                    <Spin spinning={fetchingCalendarioDetalle}>
                        <Row justify='space-around'>
                            <Col span={24}>
                                <Descriptions bordered>
                                    <Descriptions.Item label="Rutina / Mantenimiento / Prueba">{tareaSeleccionada.rutina}</Descriptions.Item>
                                </Descriptions>
                                <br/>
                                <Descriptions bordered>
                                <Descriptions.Item label="Clave rutina">{calendarioDetalle ? calendarioDetalle[0].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.rutinaByIdRutina.claveRutina : ''}</Descriptions.Item>
                                    <Descriptions.Item label="Servicio">{tareaSeleccionada.calendarioProgramacionQueryResponse[0].nombreServicio}</Descriptions.Item>
                                </Descriptions>
                                <br/>
                            </Col>
                            {this.state.selectedRowKeys.length>0 ? (
                            <Col span={24}>
                                <Form
                                    name="formulario"
                                    onFinish={onFinish}
                                    ref={this.formRef}
                                >
                                    <Row justify='space-around'>
                                        <Col span={9}>
                                        <Form.Item
                                                label='Fecha reprogramación'
                                                name='fechaReprogramacion'
                                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                            >
                                                <DatePicker
                                                    format='DD-MM-YYYY' style={{ width: '100%' }} placeholder='Ingresa la fecha.'
                                                    disabledDate={disabledRange}
                                                    allowClear={true}
                                                    showToday={false}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item
                                                label='Observaciones'
                                                name='observacion'
                                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                            >
                                                <TextArea
                                                    pattern="^^[a-zñA-ZÑ0-9 ÁÉÓÚÍáéóíú,.]+$"
                                                    title="Observaciones sin carácteres especiales"
                                                    maxLength={250}
                                                    placeholder='Observaciones de reprogramación.'
                                                    autoSize
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <Button loading={fetchingReprogramarTareaMultiple} className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Reprogramar Tareas</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            ):false}
                            {calendarioDetalle ? (
                            <Col span={24}>
                                <Table 
                                    size="small" 
                                    rowKey="idProgramacionRutina" 
                                    columns={rmdp ? columnsRMDP : columns}
                                    dataSource={calendarioDetalle}
                                    loading={fetchingCalendarioDetalle}
                                    locale={{ emptyText: 'Sin datos'}}
                                    rowSelection={ rmdp ? false : (!permisosUsuario.reprogramarTarea ? false : rowSelection)}
                                    pagination={false}
                                    scroll={rmdp ? {x:1300} : false}
                                /> 
                            </Col>
                                
                            ):false}
                        </Row>
                        <ReprogramarTareas/>
                    </Spin>
                </Modal>
                ) : false}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalCalendarioDetalle: state.ServiciosPlanificadosReducer.showModalCalendarioDetalle,
        tareaSeleccionada: state.ServiciosPlanificadosReducer.tareaSeleccionada,
        calendarioDetalle: state.ServiciosPlanificadosReducer.calendarioDetalle,
        fetchingCalendarioDetalle: state.ServiciosPlanificadosReducer.fetchingCalendarioDetalle,
        busqueda: state.ServiciosPlanificadosReducer.busqueda,
        fetchingReprogramarTareaMultiple: state.ServiciosPlanificadosReducer.fetchingReprogramarTareaMultiple,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        rmdp: state.ServiciosPlanificadosReducer.rmdp
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalCalendarioDetalle: () => {
            dispatch({ type: 'SHOW_MODAL_CALENDARIO_DETALLE', showModalCalendarioDetalle: false });
        },
        onModalReprogramarTareaVC: (tareaSeleccionadaVC, busqueda, page) => {
            dispatch({ type: 'SHOW_MODAL_REPROGRAMAR_TAREA_VC', showModalReprogramarTareaVC: true, tareaSeleccionadaVC, busqueda, page, calendarioProgramacion: true });
        },
        onReprogramarTareaMultiple: (formulario, listaIdsTareas, calendarioDetalle, busqueda) => {
            dispatch({ type: 'REPROGRAMAR_TAREA_MULTIPLE_REQUEST', formulario, listaIdsTareas, calendarioDetalle, busqueda });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCalendarioDetalle);