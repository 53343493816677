import * as ServiciosVariablesActions from '../actions/ServiciosVariablesActions';

const initialState = {
    page: 1,
    catalogoServiciosVariables: null,
    busqueda: '',
    formulario: null,

    fetchingListaVariables: false,
    listaVariables: [],
    listaVariablesCantidad: null,
    variableSeleccionada: null,
    showModalEditarVariable: false,
    fetchingEditarVariable: false,
    fetchingCrearVariable: false,
    showModalNuevaVariable: false,
    // servicios variables
    fetchingListaServiciosVariables: false,
    listaServiciosVariables: [],
    listServiciosVariablesSearch: [],
    listaServiciosVariablesCantidad: null,
    fetchingNuevoServicioVariable: false,
    showModalNuevoServicioVariable: false,
    fetchingListaVariablesActivas: false,
    listaVariablesActivas: [],
    listaVariablesByServicio: [],
    fetchingListaVariablesByServicio: false,
    fetchingListaSubvariables: false, 
    listSubvariablesByVariable: [], 
    listaVariablesActivasCantidad: null,
    fetchingEditarServicioVariable: false,
    showModalEditarServicioVariable: false,
    servicioVariableSeleccionado: null,
    showModalCapturarInfoServicioVariable: false,
    servicioCapturarSeleccionado: false,
    fetchingListaDetalleServicioVariable: false,
    listaDetalleServicioVariable: [],
    //listaDetalleServicioVariableCantidad: null,
    showModalAgregarProductividadVariable: false,
    registroSeleccionado: null,
    fetchingCapturarProductividadVariable: false,
    productividadAgregada: false,
    fetchingUbicacionesActivas: false,
    ubicacionesActivas: [],
    ubicacionesActivasCantidad: null,
    listaVariablesFiltradas: [],
    fetchingListaVariablesFiltradas: false,
    fetchingPresentacionServicioVariable: false,
    servicioPresentacion: {},
    showModalPresentacionServicioVariable: false,
    servicioPresentacionSeleccionado: null,
    fetchingExportacionExcel: false,
    fetchingListaServiciosActivos: false,
    listaServiciosActivos: [],
    serVariablesResponse: [],
    fetchingPresentacionSVPDF: false,

    dataGraficaUnitarioPSV: {},
    optionsGraficaPSV: {},


    //Segumiento Diario de Productividades
    serviciosVariablesProductividades: [],
    etiquetaProductividad: [],
    dataSetProductividad: [],
    dataGraficaProductividad: {},
    optionsGraficaProductividad: {},
    fetchingServiciosVariablesProductividades: false,
    fetchingPDFServiciosVariablesProductividades: false,
    fetchingExcelServiciosVariablesProductividades: false,

    serviciosVariablesProductividadesTable: [],
    serviciosVariablesProductividadesTableCantidad: null,
    fetchingServiciosVariablesProductividadesTable: false,

    fetchingListaServiciosVariablesByIdServicio: false,
    listaServiciosVariablesByIdServicio: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ServiciosVariablesActions.CAMBIAR_CATALOGO_SERVICIOS_VARIABLES:
            return { ...state, catalogoServiciosVariables: action.catalogoServiciosVariables };

        case ServiciosVariablesActions.LISTA_SERVICIOS_ACTIVOS_REQUEST:
            return { ...state, fetchingListaServiciosActivos: true };
        case ServiciosVariablesActions.LISTA_SERVICIOS_ACTIVOS_SUCCESS:
            return { ...state, fetchingListaServiciosActivos: false, listaServiciosActivos: action.listaServiciosActivos };
        case ServiciosVariablesActions.LISTA_SERVICIOS_ACTIVOS_FAILURE:
            return { ...state, fetchingListaServiciosActivos: false };

        case ServiciosVariablesActions.OBTENER_VARIABLES_REQUEST:
            return { ...state, fetchingListaVariables: true };
        case ServiciosVariablesActions.OBTENER_VARIABLES_SUCCESS:
            return { ...state, fetchingListaVariables: false, listaVariables: action.listaVariables, listaVariablesCantidad: action.listaVariablesCantidad };
        case ServiciosVariablesActions.OBTENER_VARIABLES_FAILURE:
            return { ...state, fetchingListaVariables: false };

        case ServiciosVariablesActions.ESTADO_VARIABLES_REQUEST:
            return { ...state, fetchingListaVariables: true };
        case ServiciosVariablesActions.ESTADO_VARIABLES_SUCCESS:
            return { ...state, fetchingListaVariables: false };
        case ServiciosVariablesActions.ESTADO_VARIABLES_FAILURE:
            return { ...state, fetchingListaVariables: false };

        case ServiciosVariablesActions.SHOW_MODAL_EDITAR_VARIABLE:
            return { ...state, showModalEditarVariable: action.showModalEditarVariable, variableSeleccionada: action.variableSeleccionada };

        case ServiciosVariablesActions.EDITAR_VARIABLES_REQUEST:
            return { ...state, fetchingEditarVariable: true };
        case ServiciosVariablesActions.EDITAR_VARIABLES_SUCCESS:
            return { ...state, fetchingEditarVariable: false };
        case ServiciosVariablesActions.EDITAR_VARIABLES_FAILURE:
            return { ...state, fetchingEditarVariable: false };

        case ServiciosVariablesActions.CREAR_VARIABLES_REQUEST:
            return { ...state, fetchingCrearVariable: true };
        case ServiciosVariablesActions.CREAR_VARIABLES_SUCCESS:
            return { ...state, fetchingCrearVariable: false };
        case ServiciosVariablesActions.CREAR_VARIABLES_FAILURE:
            return { ...state, fetchingCrearVariable: false };

        case ServiciosVariablesActions.OBTENER_VARIABLES_BY_SERVICIO_REQUEST:
            return { ...state, fetchingListaVariablesByServicio: true };
        case ServiciosVariablesActions.OBTENER_VARIABLES_BY_SERVICIO_SUCCESS:
            return { ...state, fetchingListaVariablesByServicio: false, listaVariablesByServicio:action.listaVariablesByServicio };
        case ServiciosVariablesActions.OBTENER_VARIABLES_BY_SERVICIO_FAILURE:
            return { ...state, fetchingListaVariablesByServicio: false };

        case ServiciosVariablesActions.SHOW_MODAL_CREAR_VARIABLE:
            return { ...state, showModalNuevaVariable: action.showModalNuevaVariable };

        case ServiciosVariablesActions.OBTENER_SERVICIOS_VARIABLES_REQUEST:
            return { ...state, fetchingListaServiciosVariables: true };
        case ServiciosVariablesActions.OBTENER_SERVICIOS_VARIABLES_SUCCESS:
            return { ...state, fetchingListaServiciosVariables: false, listaServiciosVariables: action.listaServiciosVariables, listaServiciosVariablesCantidad: action.listaServiciosVariablesCantidad};
        case ServiciosVariablesActions.OBTENER_SERVICIOS_VARIABLES_FAILURE:
            return { ...state, fetchingListaServiciosVariables: false };

        case ServiciosVariablesActions.BUSCAR_SERVICIOS_VARIABLES_REQUEST:
            return { ...state, fetchingListaServiciosVariables: true };
        case ServiciosVariablesActions.BUSCAR_SERVICIOS_VARIABLES_SUCCESS:
            return { ...state, fetchingListaServiciosVariables: false,listaServiciosVariablesBusqueda: action.listaServiciosVariablesBusqueda };
        case ServiciosVariablesActions.BUSCAR_SERVICIOS_VARIABLES_FAILURE:
            return { ...state, fetchingListaServiciosVariables: false };

        case ServiciosVariablesActions.CREAR_SERVICIO_VARIABLE_REQUEST:
            return { ...state, fetchingNuevoServicioVariable: true };
        case ServiciosVariablesActions.CREAR_SERVICIO_VARIABLE_SUCCESS:
            return { ...state, fetchingNuevoServicioVariable: false };
        case ServiciosVariablesActions.CREAR_SERVICIO_VARIABLE_FAILURE:
            return { ...state, fetchingNuevoServicioVariable: false };

        case ServiciosVariablesActions.SHOW_MODAL_CREAR_SERVICIO_VARIABLE:
            return { ...state, showModalNuevoServicioVariable: action.showModalNuevoServicioVariable };

        case ServiciosVariablesActions.EDITAR_SERVICIO_VARIABLE_REQUEST:
            return { ...state, fetchingEditarServicioVariable: true };
        case ServiciosVariablesActions.EDITAR_SERVICIO_VARIABLE_SUCCESS:
            return { ...state, fetchingEditarServicioVariable: false };
        case ServiciosVariablesActions.EDITAR_SERVICIO_VARIABLE_FAILURE:
            return { ...state, fetchingEditarServicioVariable: false };

        case ServiciosVariablesActions.SHOW_MODAL_EDITAR_SERVICIO_VARIABLE:
            return { ...state, showModalEditarServicioVariable: action.showModalEditarServicioVariable, servicioVariableSeleccionado: action.servicioVariableSeleccionado };

        case ServiciosVariablesActions.OBTENER_VARIABLES_ACTIVAS_REQUEST:
            return { ...state, fetchingListaVariablesActivas: true };
        case ServiciosVariablesActions.OBTENER_VARIABLES_ACTIVAS_SUCCESS:
            return { ...state, fetchingListaVariablesActivas: false, listaVariablesActivas: action.listaVariablesActivas, listaVariablesActivasCantidad: action.listaVariablesActivasCantidad };
        case ServiciosVariablesActions.OBTENER_VARIABLES_ACTIVAS_FAILURE:
            return { ...state, fetchingListaVariablesActivas: false };

        case ServiciosVariablesActions.ESTADO_SERVICIO_VARIABLES_REQUEST:
            return { ...state, fetchingListaServiciosVariables: true };
        case ServiciosVariablesActions.ESTADO_SERVICIO_VARIABLES_SUCCESS:
            return { ...state, fetchingListaServiciosVariables: false };
        case ServiciosVariablesActions.ESTADO_SERVICIO_VARIABLES_FAILURE:
            return { ...state, fetchingListaServiciosVariables: false };

        case ServiciosVariablesActions.GET_SUBVARIABLE_BY_IDS_REQUEST:
            return { ...state, fetchingListaSubvariables: true };
        case ServiciosVariablesActions.GET_SUBVARIABLE_BY_IDS_SUCCESS:
            return { ...state, fetchingListaSubvariables: false, listSubvariablesByVariable: action.listSubvariablesByVariable };
        case ServiciosVariablesActions.GET_SUBVARIABLE_BY_IDS_FAILURE:
            return { ...state, fetchingListaSubvariables: false };

        case ServiciosVariablesActions.GET_SUBVARIABLE_BY_ID_VARIABLE_REQUEST:
            return { ...state, fetchingListaSubvariables: true };
        case ServiciosVariablesActions.GET_SUBVARIABLE_BY_ID_VARIABLE_SUCCESS:
            return { ...state, fetchingListaSubvariables: false, listSubvariablesByVariable: action.listSubvariablesByVariable };
        case ServiciosVariablesActions.GET_SUBVARIABLE_BY_ID_VARIABLE_FAILURE:
            return { ...state, fetchingListaSubvariables: false };

        case ServiciosVariablesActions.SHOW_MODAL_CAPTURAR_INFO_SERVICIO_VARIABLE:
            return { ...state, showModalCapturarInfoServicioVariable: action.showModalCapturarInfoServicioVariable, servicioCapturarSeleccionado: action.servicioCapturarSeleccionado };

        case ServiciosVariablesActions.LISTA_DETALLE_SERVICIO_VARIABLE_REQUEST:
            return { ...state, fetchingListaDetalleServicioVariable: true };
        case ServiciosVariablesActions.LISTA_DETALLE_SERVICIO_VARIABLE_SUCCESS:
            return { ...state, fetchingListaDetalleServicioVariable: false, listaDetalleServicioVariable: action.listaDetalleServicioVariable };
        case ServiciosVariablesActions.LISTA_DETALLE_SERVICIO_VARIABLE_FAILURE:
            return { ...state, fetchingListaDetalleServicioVariable: false };

        case ServiciosVariablesActions.SHOW_MODAL_AGREGAR_PRODUCTIVDAD_VARIABLE:
            return { ...state, showModalAgregarProductividadVariable: action.showModalAgregarProductividadVariable, registroSeleccionado: action.registroSeleccionado }

        case ServiciosVariablesActions.CAPTURAR_PRODUCTIVIDAD_VARIABLE_REQUEST:
            return { ...state, fetchingCapturarProductividadVariable: true };
        case ServiciosVariablesActions.CAPTURAR_PRODUCTIVIDAD_VARIABLE_SUCCESS:
            return { ...state, fetchingCapturarProductividadVariable: false };
        case ServiciosVariablesActions.CAPTURAR_PRODUCTIVIDAD_VARIABLE_FAILURE:
            return { ...state, fetchingCapturarProductividadVariable: false };

        case ServiciosVariablesActions.GUARDAR_PRODUCTIVIDAD_VARIABLE:
            return { ...state, formulario: action.formulario, productividadAgregada: action.productividadAgregada }

        case ServiciosVariablesActions.OBTENER_UBICACIONES_ACTIVAS_REQUEST:
            return { ...state, fetchingUbicacionesActivas: true };
        case ServiciosVariablesActions.OBTENER_UBICACIONES_ACTIVAS_SUCCESS:
            return { ...state, fetchingUbicacionesActivas: false, ubicacionesActivas: action.ubicacionesActivas, ubicacionesActivasCantidad: action.ubicacionesActivasCantidad };
        case ServiciosVariablesActions.OBTENER_UBICACIONES_ACTIVAS_FAILURE:
            return { ...state, fetchingUbicacionesActivas: false };

        case ServiciosVariablesActions.OBTENER_VARIABLES_FILTRADAS_POR_SERVICIO_REQUEST:
            return { ...state, fetchingListaVariablesFiltradas: true };
        case ServiciosVariablesActions.OBTENER_VARIABLES_FILTRADAS_POR_SERVICIO_SUCCESS:
            return { ...state, fetchingListaVariablesFiltradas: false, listaVariablesFiltradas: action.listaVariablesFiltradas };
        case ServiciosVariablesActions.OBTENER_VARIABLES_FILTRADAS_POR_SERVICIO_FAILURE:
            return { ...state, fetchingListaVariablesFiltradas: false };

        case ServiciosVariablesActions.ESTADO_VARIABLE_SERVICIO_VARIABLES_REQUEST:
            return { ...state, fetchingListaServiciosVariables: true };
        case ServiciosVariablesActions.ESTADO_VARIABLE_SERVICIO_VARIABLES_SUCCESS:
            return { ...state, fetchingListaServiciosVariables: false };
        case ServiciosVariablesActions.ESTADO_VARIABLE_SERVICIO_VARIABLES_FAILURE:
            return { ...state, fetchingListaServiciosVariables: false };

        case ServiciosVariablesActions.SHOW_MODAL_PRESENTACION_SERVICIO_VARIABLE:
            return { ...state,  showModalPresentacionServicioVariable: action. showModalPresentacionServicioVariable, servicioPresentacionSeleccionado: action.servicioPresentacionSeleccionado }

        case ServiciosVariablesActions.PRESENTACION_SERVICIO_VARIABLES_REQUEST:
            return { ...state, fetchingPresentacionServicioVariable: true };
        case ServiciosVariablesActions.PRESENTACION_SERVICIO_VARIABLES_SUCCESS:
            return { ...state, fetchingPresentacionServicioVariable: false , servicioPresentacion: action.servicioPresentacion, serVariablesResponse: action.serVariablesResponse, dataGraficaUnitarioPSV: action.dataGraficaUnitarioPSV, optionsGraficaPSV: action.optionsGraficaPSV};
        case ServiciosVariablesActions.PRESENTACION_SERVICIO_VARIABLES_FAILURE:
            return { ...state, fetchingPresentacionServicioVariable: false };  

        case ServiciosVariablesActions.EXPORTAR_EXCEL_SERVICIO_VARIABLES_REQUEST:
            return { ...state, fetchingExportacionExcel: true };
        case ServiciosVariablesActions.EXPORTAR_EXCEL_SERVICIO_VARIABLES_SUCCESS:
            return { ...state, fetchingExportacionExcel: false };
        case ServiciosVariablesActions.EXPORTAR_EXCEL_SERVICIO_VARIABLES_FAILURE:
            return { ...state, fetchingExportacionExcel: false };   

        case ServiciosVariablesActions.PRESENTACION_SV_PDF_REQUEST:
            return { ...state, fetchingPresentacionSVPDF: true };
        case ServiciosVariablesActions.PRESENTACION_SV_PDF_SUCCESS:
            return { ...state, fetchingPresentacionSVPDF: false };
        case ServiciosVariablesActions.PRESENTACION_SV_PDF_FAILURE:
            return { ...state, fetchingPresentacionSVPDF: false };

         //Segumiento Diario de Productividades
          
        case ServiciosVariablesActions.GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_REQUEST:
            return { ...state, fetchingServiciosVariablesProductividades: true };
        case ServiciosVariablesActions.GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_SUCCESS:
            return { ...state, fetchingServiciosVariablesProductividades: false, serviciosVariablesProductividades: action.serviciosVariablesProductividades, etiquetaProductividad: action.etiquetaProductividad, dataSetProductividad: action.dataSetProductividad, dataGraficaProductividad: action.dataGraficaProductividad, optionsGraficaProductividad: action.optionsGraficaProductividad };
        case ServiciosVariablesActions.GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_FAILURE:
            return { ...state, fetchingServiciosVariablesProductividades: false };

        case ServiciosVariablesActions.GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_TABLA_REQUEST:
            return { ...state, fetchingServiciosVariablesProductividadesTable: true };
        case ServiciosVariablesActions.GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_TABLA_SUCCESS:
            return { ...state, fetchingServiciosVariablesProductividadesTable: false, serviciosVariablesProductividadesTable: action.serviciosVariablesProductividadesTable, serviciosVariablesProductividadesTableCantidad: action.serviciosVariablesProductividadesTableCantidad };
        case ServiciosVariablesActions.GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_TABLA_FAILURE:
            return { ...state, fetchingServiciosVariablesProductividadesTable: false };

        case ServiciosVariablesActions.PDF_SERVICIOS_VARIABLES_PRODUCTIVIDADES_REQUEST:
            return { ...state, fetchingPDFServiciosVariablesProductividades: true };
        case ServiciosVariablesActions.PDF_SERVICIOS_VARIABLES_PRODUCTIVIDADES_SUCCESS:
            return { ...state, fetchingPDFServiciosVariablesProductividades: false };
        case ServiciosVariablesActions.PDF_SERVICIOS_VARIABLES_PRODUCTIVIDADES_FAILURE:
            return { ...state, fetchingPDFServiciosVariablesProductividades: false };   

        case ServiciosVariablesActions.EXCEL_SERVICIOS_VARIABLES_PRODUCTIVIDADES_REQUEST:
            return { ...state, fetchingExcelServiciosVariablesProductividades: true };
        case ServiciosVariablesActions.EXCEL_SERVICIOS_VARIABLES_PRODUCTIVIDADES_SUCCESS:
            return { ...state, fetchingExcelServiciosVariablesProductividades: false };
        case ServiciosVariablesActions.EXCEL_SERVICIOS_VARIABLES_PRODUCTIVIDADES_FAILURE:
            return { ...state, fetchingExcelServiciosVariablesProductividades: false }; 
            
        case ServiciosVariablesActions.LISTA_SERVICIOS_VARIABLES_BY_IDSERVICIO_REQUEST:
            return { ...state, fetchingListaServiciosVariablesByIdServicio: true };
        case ServiciosVariablesActions.LISTA_SERVICIOS_VARIABLES_BY_IDSERVICIO_SUCCESS:
            return { ...state, fetchingListaServiciosVariablesByIdServicio: false, listaServiciosVariablesByIdServicio: action.listaServiciosVariablesByIdServicio };
        case ServiciosVariablesActions.LISTA_SERVICIOS_VARIABLES_BY_IDSERVICIO_FAILURE:
            return { ...state, fetchingListaServiciosVariablesByIdServicio: false }; 
   
        default:
            return state;
    }
}