import { takeLatest, call, put } from "redux-saga/effects";
import * as configuracionYSeguridadActions from '../actions/ConfiguracionYSeguridadActions';
import moment from 'moment';
import * as serviciosVariablesActions from '../actions/ServiciosVariablesActions';
import {obtenerVariablesGeneralCall, activarVariablesCall, editarVariablesCall, crearVariablesCall,serviciosActivosCall,
        obtenerServiciosVariablesCall,obtenerVariablesActivasCall,crearServicioVariableCall, editarServicioVariableCall,
        activarServicioVariableCall,obtenerUbicacionesActivasCall, detalleServicioVariableCall, capturarProductividadCall,
        obtenerVariablesFiltradasCall, activarVariableServicioVariableCall, presentacionServicioVariableCall, 
        exportacionExcelServicioCall, getMonitorProductividadesCall, pdfMonitorProductividadesCall, excelMonitorProductividadesCall, 
        presentacionSVPDFCall, serviciosVariablesByIdServicioCall, obtenerSubVariableByIdCall, obtenerSubVariableByIdVariableCall,
        obtenersVariablesByServicioCall} from '../../services/ServiciosVariablesServices';

// Monitor de Productividad
function* getMonitorProductividades(action){
    try {
        console.log("action getMonitorProductividadesCall gráfica",action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idsVariables": action.idsVariables ? action.idsVariables : null,
            "idsSubvariables": action.idsSubvariables ? action.idsSubvariables : null,
            "idsUbicaciones": action.idsUbicaciones ? action.idsUbicaciones : null,
            "fechaProductividadInicio": action.fechas ? action.fechas[0].format('YYYY-MM-DD') : null,
            "fechaProductividadFin": action.fechas ? action.fechas[1].format('YYYY-MM-DD') : null,
            "idServicio": action.servicio ? parseInt(action.servicio) : null,
            "page": (action.page - 1) * 10,
            "offset": 10,
            "field": action.field ? action.field : "var.idVariable",
            "order": action.order ? action.order : "ASC",
        }
        console.log('data getMonitorProductividadesCall gráfica', data)

        yield put({ type: serviciosVariablesActions.GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_SUCCESS, serviciosVariablesProductividades:[], etiquetaProductividad:[], dataSetProductividad:[], dataGraficaProductividad:{}, optionsGraficaProductividad:{} });

        const response = yield call(getMonitorProductividadesCall, token, data)
        console.log("response getMonitorProductividadesCall gráfica", response)
        if(response.status === 200){
            const serviciosVariablesProductividades = response.data.data;
            let etiquetaProductividad = [];
            let dataSetProductividad = [];
            let etiquetasFechas = [];

            for(let i in serviciosVariablesProductividades){
                if(serviciosVariablesProductividades[i].fechaProductividad){
                    let fechaProductividadTabla = serviciosVariablesProductividades[i].fechaProductividad;
                    serviciosVariablesProductividades[i].fechaProductividadTabla = fechaProductividadTabla.split('-')[2] + '-' + fechaProductividadTabla.split('-')[1] + '-' + fechaProductividadTabla.split('-')[0];
                }
            }

            const uniqueSubVars = [];

            const unique = serviciosVariablesProductividades.filter(element => {
                const isDuplicate = uniqueSubVars.includes(element.subvariable);
            
                if (!isDuplicate) {
                uniqueSubVars.push(element.subvariable);
            
                return true;
                }
            
                return false;
            });

            console.log("unique",unique)


            for(let i in unique){
                etiquetaProductividad.push(unique[i]);
            }

            let nuevoArray = []
            let arrayTemporal = []
            for(let i in serviciosVariablesProductividades){
                arrayTemporal = nuevoArray.filter(resp => resp["fecha"] == serviciosVariablesProductividades[i]["fechaProductividadTabla"]);
                if(arrayTemporal.length>0){
                    nuevoArray[nuevoArray.indexOf(arrayTemporal[0])]["Data"].push(serviciosVariablesProductividades[i]["cantidad"])
                    nuevoArray[nuevoArray.indexOf(arrayTemporal[0])]["Etiq"].push(serviciosVariablesProductividades[i]["variable"])
                }else{
                    nuevoArray.push({
                        "fecha" : serviciosVariablesProductividades[i]["fechaProductividadTabla"] , 
                        "Data" : [serviciosVariablesProductividades[i]["cantidad"]],
                        "Etiq" : [serviciosVariablesProductividades[i]["subvariable"]],
                        "Var" : [serviciosVariablesProductividades[i]["variable"]],
                    })
                }
            }


            for(let i in nuevoArray){
                if(nuevoArray.length>0){
                    etiquetasFechas.push(nuevoArray[i].fecha);
                }
            }


            // Recorrer fechas y variables
            let nvoObjetoConjunto = {};
            for(let i in nuevoArray){
                for(let j in nuevoArray[i].Etiq){
                    let acumulador = [];

                    etiquetaProductividad.forEach( k => {
                        if(nuevoArray[i].Etiq[j] === k){
                            acumulador.push(nuevoArray[i].Data[j])
                        } else {
                            acumulador.push(0);
                        }
                    })
                    
                    etiquetaProductividad.forEach( l => { 
                        nvoObjetoConjunto[l] = acumulador
                    })
                }
            }

            let nvoObjetoConjuntoPBA = [];
            let etiquetasPBA = [];
            let objtVDD = {}
            for(let i in nuevoArray){
                if(nuevoArray[i]){
                    for(let j in nuevoArray[i].Data){
                        let cantidadDia = [];

                        etiquetasPBA.push(nuevoArray[i].Etiq[j]);

                        for(let k in nuevoArray){
                            cantidadDia.push(0);
                        }
                        
                        cantidadDia[i] = nuevoArray[i].Data[j];
                        nvoObjetoConjuntoPBA.push(cantidadDia);                        
                    }
                }
            }



            etiquetasPBA.forEach( (k, i) => { 
                let indices = [];
                let idx = etiquetasPBA.indexOf(k);
                while (idx != -1) {
                    indices.push(idx);
                    idx = etiquetasPBA.indexOf(k, idx + 1);
                }
                // [0, 2, 4]
                let arr = [];
                for(let x in indices){
                    let suma = nvoObjetoConjuntoPBA[indices[0]];

                    if(indices[1]){
                        for(let y in nvoObjetoConjuntoPBA[i]){
                            if(suma[y] == 0){
                                suma[y] += nvoObjetoConjuntoPBA[indices[x]][y]
                            }
                        }
                    }
                    objtVDD[k] = suma; 
                    
                }
            })

            function generarNumero(numero){
                return (Math.random()*numero).toFixed(0);
            }

            function colorRGB(){
                var coolor = "("+generarNumero(255)+"," + generarNumero(255) + "," + generarNumero(255) +")";
                return "rgb" + coolor;
            }

            let etiquetas = [];
            for(let i in etiquetaProductividad){
                if(etiquetaProductividad.length>0){
                    console.log("objtVDD[etiquetaProductividad[i].subvariable]",etiquetaProductividad)
                    etiquetas.push({
                        label: etiquetaProductividad[i].subvariable,
                        backgroundColor: colorRGB(),
                        data: objtVDD[etiquetaProductividad[i].subvariable]
                    });
                }
            }

            const dataGraficaProductividad = {
                labels: etiquetasFechas,
                datasets: etiquetas
            };

            console.log('dataGraficaProductividad', dataGraficaProductividad);

            const optionsGraficaProductividad = {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                     yAxes: [{
                          display: true,
                          ticks: {
                               suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
                               suggestedMax: 10, 
                          },
                     }],
                     xAxes: [{  
                          display: true,
                          ticks: {mirror: false},
                          gridLines: { display : true },
                      }]
                },
                tooltips: {
                     callbacks: {
                          title: function(tooltipItem, data) {
                               return data['labels'][tooltipItem[0]['index']];
                          },
                          label: function(tooltipItem, data) {
                               let dataset = data.datasets[tooltipItem.datasetIndex];
                               let currentValue = dataset.data[tooltipItem.index];
                               
                               return 'Servicio: ' + etiquetaProductividad[tooltipItem.datasetIndex].serviciosVariablesByIdServicioVariable.descripcion +  ' | Variable: ' + etiquetaProductividad[tooltipItem.datasetIndex].variable + ' | Subvariable: ' + etiquetaProductividad[tooltipItem.datasetIndex].subvariable + ' | Cantidad: ' +  currentValue ;                           
                          },
                     },
                     backgroundColor: '#FFF',
                     titleFontSize: 14,
                     titleFontColor: '#1747ca',
                     bodyFontColor: '#0e2c82',
                     bodyFontSize: 12,
                     displayColors: false
                },
                legend: { display: true, labels: {boxWidth: 15} },
            }


            yield put({ type: serviciosVariablesActions.GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_SUCCESS, serviciosVariablesProductividades, etiquetaProductividad, dataSetProductividad, dataGraficaProductividad, optionsGraficaProductividad });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosVariablesActions.GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_FAILURE });
        }

    } catch (error) {
        yield put({ type: serviciosVariablesActions.GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_FAILURE });
    }
}
export function* getMonitorProductividadesSaga(){
    yield takeLatest(serviciosVariablesActions.GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_REQUEST, getMonitorProductividades)
}

function* getMonitorProductividadesTabla(action){
    try {
        console.log("action getMonitorProductividadesCall tabla",action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idsVariables": action.idsVariables ? action.idsVariables : null,
            "idsSubvariables": action.idsSubvariables ? action.idsSubvariables : null,
            "idsUbicaciones": action.idsUbicaciones ? action.idsUbicaciones : null,
            "fechaProductividadInicio": action.fechas ? action.fechas[0].format('YYYY-MM-DD') : null,
            "fechaProductividadFin": action.fechas ? action.fechas[1].format('YYYY-MM-DD') : null,
            "idServicio": action.servicio ? parseInt(action.servicio) : null,
            "page": (action.page - 1) * 10,
            "offset": 10,
            "field": action.field ? action.field : "var.idVariable",
            "order": action.order ? action.order : "ASC",
        }
        console.log('data getMonitorProductividadesCall Tabla', data)

        const response = yield call(getMonitorProductividadesCall, token, data)
        console.log('response getMonitorProductividadesCall tabla',response);
        if(response.status === 200){
            const serviciosVariablesProductividadesTable = response.data.data;
            const serviciosVariablesProductividadesTableCantidad = response.data.cantidad;

            for(let i in serviciosVariablesProductividadesTable){
                if(serviciosVariablesProductividadesTable[i].variableByIdVariable){
                    serviciosVariablesProductividadesTable[i].variable = serviciosVariablesProductividadesTable[i].variableByIdVariable.variable;
                }

                if(serviciosVariablesProductividadesTable[i].serviciosVariablesByIdServicioVariable){
                    serviciosVariablesProductividadesTable[i].servicioVariable = serviciosVariablesProductividadesTable[i].serviciosVariablesByIdServicioVariable.descripcion;
                }

                if(serviciosVariablesProductividadesTable[i].fechaProductividad){
                    let fechaProductividadTabla = serviciosVariablesProductividadesTable[i].fechaProductividad.split('T')[0]
                    serviciosVariablesProductividadesTable[i].fechaProductividadTabla = fechaProductividadTabla.split('-')[2] + '-' + fechaProductividadTabla.split('-')[1] + '-' + fechaProductividadTabla.split('-')[0];
                }
            }

            yield put({ type: serviciosVariablesActions.GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_TABLA_SUCCESS, serviciosVariablesProductividadesTable, serviciosVariablesProductividadesTableCantidad });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosVariablesActions.GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_TABLA_FAILURE });
        }

    } catch (error) {
        yield put({ type: serviciosVariablesActions.GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_TABLA_FAILURE });
    }
}
export function* getMonitorProductividadesTablaSaga(){
    yield takeLatest(serviciosVariablesActions.GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_TABLA_REQUEST, getMonitorProductividadesTabla)
}

function* pdfMonitorProductividades(action){
    try {
        console.log("action pdfMonitorProductividades", action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idsVariables": action.idsVariables ? action.idsVariables : null,
            "idsSubvariables": action.idsSubvariables ? action.idsSubvariables : null,
            "idsUbicaciones": action.idsUbicaciones ? action.idsUbicaciones : null,
            "fechaProductividadInicio": action.fechas ? action.fechas[0].format('YYYY-MM-DD') : null,
            "fechaProductividadFin": action.fechas ? action.fechas[1].format('YYYY-MM-DD') : null,
            "idServicio": action.servicio ? parseInt(action.servicio) : null,
            "page": (action.page - 1) * 10,
            "offset": 10,
            "field": action.field ? action.field : "var.idVariable",
            "order": action.order ? action.order : "ASC",
        }
        console.log('data pdfMonitorProductividades', data)

        const response = yield call(pdfMonitorProductividadesCall, token, data)
        console.log("response pdfMonitorProductividades", response)
        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se descargó el archivo de forma exitosa";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            let month = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);

            link.href = url;
            link.download = "Productividades " + new Date().getDate() + "-" + month  + "-" + + new Date().getFullYear() + "_" + new Date().getHours() + "" + new Date().getMinutes() +  ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosVariablesActions.PDF_SERVICIOS_VARIABLES_PRODUCTIVIDADES_SUCCESS });
        } else {
            const showErrorMsg = true;
            const textMessage = "No se encuentra data de ese servicio. Por favor elije otro.";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosVariablesActions.PDF_SERVICIOS_VARIABLES_PRODUCTIVIDADES_FAILURE });
        }

    } catch (error) {
        yield put({ type: serviciosVariablesActions.PDF_SERVICIOS_VARIABLES_PRODUCTIVIDADES_FAILURE });
    }
}
export function* pdfMonitorProductividadesSaga(){
    yield takeLatest(serviciosVariablesActions.PDF_SERVICIOS_VARIABLES_PRODUCTIVIDADES_REQUEST, pdfMonitorProductividades)
}

function* excelMonitorProductividades(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idsVariables": action.idsVariables ? action.idsVariables : null,
            "idsSubvariables": action.idsSubvariables ? action.idsSubvariables : null,
            "idsUbicaciones": action.idsUbicaciones ? action.idsUbicaciones : null,
            "fechaProductividadInicio": action.fechas ? action.fechas[0].format('YYYY-MM-DD') : null,
            "fechaProductividadFin": action.fechas ? action.fechas[1].format('YYYY-MM-DD') : null,
            "idServicio": action.servicio ? parseInt(action.servicio) : null,
            "page": (action.page - 1) * 10,
            "offset": 10,
            "field": action.field ? action.field : "var.idVariable",
            "order": action.order ? action.order : "ASC",
        }
        console.log('data', data)

        const response = yield call(excelMonitorProductividadesCall, token, data)
        console.log(response)
        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se descargó el archivo de forma exitosa";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            let month = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);

            link.href = url;
            link.download = "Productividades " + new Date().getDate() + "-" + month + "-" + + new Date().getFullYear() + "_" + new Date().getHours() + "" + new Date().getMinutes() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosVariablesActions.EXCEL_SERVICIOS_VARIABLES_PRODUCTIVIDADES_SUCCESS });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosVariablesActions.EXCEL_SERVICIOS_VARIABLES_PRODUCTIVIDADES_FAILURE });
        }

    } catch (error) {
        yield put({ type: serviciosVariablesActions.EXCEL_SERVICIOS_VARIABLES_PRODUCTIVIDADES_FAILURE });
    }
}
export function* excelMonitorProductividadesSaga(){
    yield takeLatest(serviciosVariablesActions.EXCEL_SERVICIOS_VARIABLES_PRODUCTIVIDADES_REQUEST, excelMonitorProductividades)
}

// Saga para obtener variables busqueda
function* obtenerVariablesBusqueda(action){
    try{
        console.log("action variables",action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let orden = 'ASC';
        let campo = 'fechaAlta';
        let param = ''
        if(action.busqueda.order){
            orden = action.busqueda.order
        };
        if(action.busqueda.field){
            campo = action.busqueda.field
        };
        if(action.busqueda.param){
            param = action.busqueda.param
        };

        //console.log("param", param, "campo",  campo, "orden",  orden, "page", page)
        
        const response = yield call(obtenerVariablesGeneralCall, token,param, campo, orden, page);
        console.log('response obtenerVariablesBusqueda', response)

        if(response.status === 200){
            let listaVariables = response.data.data;
            let listaVariablesCantidad = response.data.cantidad;
            for (let i in listaVariables) {
                listaVariables[i].fechaAlta = listaVariables[i].fechaAlta.split('T')[0].split('-').reverse().join('-');
                if(listaVariables[i].unidadMedidaByIdunidadMedida){
                    listaVariables[i].unidadMedida = listaVariables[i].unidadMedidaByIdunidadMedida.unidadMedida;
                }
                if(listaVariables[i].tiempo){
                    listaVariables[i].tiempo = listaVariables[i].tiempo
                    listaVariables[i].tiempoColumn = listaVariables[i].tiempo
                } else {
                    listaVariables[i].tiempoColumn = "No tiene tiempo"
                }
                
            }
            yield put({ type: serviciosVariablesActions.OBTENER_VARIABLES_SUCCESS, listaVariables , listaVariablesCantidad});

        } else {
            yield put({ type: serviciosVariablesActions.OBTENER_VARIABLES_FAILURE });
        }
    } catch(error){
        yield put({ type: serviciosVariablesActions.OBTENER_VARIABLES_FAILURE });
    }
}
export function* obtenerVariablesBusquedaSaga(){
    yield takeLatest(serviciosVariablesActions.OBTENER_VARIABLES_REQUEST, obtenerVariablesBusqueda)
}
// servicios activos
function* serviciosActivos(action){
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        
        const response = yield call(serviciosActivosCall, token);
        console.log('response serviciosActivos', response)

        if(response.status === 200){
            let listaServiciosActivos = response.data.data;
            
            yield put({ type: serviciosVariablesActions.LISTA_SERVICIOS_ACTIVOS_SUCCESS, listaServiciosActivos });

        } else {
            yield put({ type: serviciosVariablesActions.LISTA_SERVICIOS_ACTIVOS_FAILURE});
        }
    } catch(error){
        yield put({ type: serviciosVariablesActions.LISTA_SERVICIOS_ACTIVOS_FAILURE});
    }
}
export function* serviciosActivosSaga(){
    yield takeLatest(serviciosVariablesActions.LISTA_SERVICIOS_ACTIVOS_REQUEST, serviciosActivos)
}

// variables activas
function* obtenerVariablesActivas(action){
    try{
        console.log("action variablesActivas",action);
        const token = sessionStorage.getItem('token');
        
        const response = yield call(obtenerVariablesActivasCall, token);
        console.log('response obtenerVariablesActivas', response)

        if(response.status === 200){
            let listaVariablesActivas = response.data;
            let listaVariablesActivasCantidad = response.cantidad;

            console.log("listaVariablesActivas en saga",listaVariablesActivas)
            
            yield put({ type: serviciosVariablesActions.OBTENER_VARIABLES_ACTIVAS_SUCCESS, listaVariablesActivas , listaVariablesActivasCantidad});

        } else {
            yield put({ type: serviciosVariablesActions.OBTENER_VARIABLES_ACTIVAS_FAILURE});
        }
    } catch(error){
        yield put({ type: serviciosVariablesActions.OBTENER_VARIABLES_ACTIVAS_FAILURE});
    }
}
export function* oobtenerVariablesActivasSaga(){
    yield takeLatest(serviciosVariablesActions.OBTENER_VARIABLES_ACTIVAS_REQUEST, obtenerVariablesActivas)
}

// Saga para cambiar estado variables 
function* estadoVariables(action){
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idVariable = action.idVariable;
        
        const response = yield call(activarVariablesCall, token, idVariable);
        console.log('response obtenerVariablesBusqueda', response)

        if(response.status === 200){
            let page = action.page;
            let busqueda = {campo: '', orden: ''}
            const showSuccessMsg = true;
            const textMessage = "El estado de la variable se cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosVariablesActions.ESTADO_VARIABLES_SUCCESS });
            yield put({ type: serviciosVariablesActions.OBTENER_VARIABLES_REQUEST, page , busqueda});

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosVariablesActions.ESTADO_VARIABLES_FAILURE });
        }
    } catch(error){
        yield put({ type: serviciosVariablesActions.ESTADO_VARIABLES_FAILURE });
    }
}
export function* estadoVariablesSaga(){
    yield takeLatest(serviciosVariablesActions.ESTADO_VARIABLES_REQUEST, estadoVariables)
}

function* editarVariable(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const formulario = action.formulario;
        let idVariable = action.variableSeleccionada.idVariable;
        let tiempo = '';

        if(formulario.tiempo){
            tiempo = formulario.tiempo
        }

        const data = {
            "idVariable": idVariable,
            "variable": "Prueba 10",
            "considerTiempo": formulario.considerTiempo,
            "tiempo": tiempo,
            "fechaAlta": "",
            "fechaActualizacion": "",
            "activo": null,
            "subvariablesByVariable": formulario.subvariablesByVariable
        }
        console.log('data', data)

        const response = yield call(editarVariablesCall, token, idVariable, data)
        console.log('response editarVariable', response)
        if(response.status === 200){
            let page = action.page;
            let busqueda = {campo: '', orden: ''}
            let showModalEditarVariable = false
            const showSuccessMsg = true;
            const textMessage = "La variable de editó de forma correcta";
        

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosVariablesActions.EDITAR_VARIABLES_SUCCESS });
            yield put({ type: serviciosVariablesActions.SHOW_MODAL_EDITAR_VARIABLE, showModalEditarVariable });
            yield put({ type: serviciosVariablesActions.OBTENER_VARIABLES_REQUEST, page , busqueda});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosVariablesActions.EDITAR_VARIABLES_FAILURE });
        }

    } catch (error) {
        yield put({ type: serviciosVariablesActions.EDITAR_VARIABLES_FAILURE });
    }
}
export function* editarVariableSaga(){
    yield takeLatest(serviciosVariablesActions.EDITAR_VARIABLES_REQUEST, editarVariable)
}

// crear variable saga
function* crearVariable(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const formulario = action.formulario;
        let tiempo = '';
        let considerTiempo = false;
        if(formulario.tiempo){
            tiempo = formulario.tiempo
        }
        if(formulario.considerTiempo){
            considerTiempo = formulario.considerTiempo
        }
        console.log('tiempo', tiempo)

        const data = {
            "idVariable": null,
            "variable": formulario.variable,
            "considerTiempo": considerTiempo,
            "tiempo": tiempo,
            "activo": true,
            "subvariablesByVariable": formulario.subVariables
        }
        console.log('data', data)

        const response = yield call(crearVariablesCall, token, data)
       console.log('response crearVariable', response)
        if(response.status === 200){
            let page = action.page;
            let busqueda = {campo: '', orden: ''}
            let showModalNuevaVariable = false
            const showSuccessMsg = true;
            const textMessage = "La variable se creó de forma correcta";
        

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosVariablesActions.CREAR_VARIABLES_SUCCESS });
            yield put({ type: serviciosVariablesActions.SHOW_MODAL_CREAR_VARIABLE, showModalNuevaVariable });
            yield put({ type: serviciosVariablesActions.OBTENER_VARIABLES_REQUEST, page , busqueda});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosVariablesActions.CREAR_VARIABLES_FAILURE });
        }

    } catch (error) {
        yield put({ type: serviciosVariablesActions.CREAR_VARIABLES_FAILURE });
    }
}
export function* crearVariableSaga(){
    yield takeLatest(serviciosVariablesActions.CREAR_VARIABLES_REQUEST, crearVariable)
}

// ***  Saga obtener Servicios Variables busqueda  *** //
function* obtenerServiciosVariablesBusqueda(action){
    try{
        console.log("action de obtenerServiciosVariablesBusqueda",action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let orden = 'ASC';
        const offset = 10;
        let campo = 'idServicio';
        let param = ''
        if(action.busqueda.order){
            orden = action.busqueda.order
        };
        if(action.busqueda.field){
            campo = action.busqueda.field
        };
        if(action.busqueda.param){
            param = "&param=" +action.busqueda.param
        };

        console.log(param, campo, orden, page)
        const response = yield call(obtenerServiciosVariablesCall, token,param, campo, orden, page, offset);
        console.log('response obtenerServiciosVariablesBusqueda', response)

        if(response.status === 200){
            const listServiciosVariablesSearch = response.data.data;
            console.log("listServiciosVariablesSearch en saga",listServiciosVariablesSearch)
            let listaServiciosVariables = response.data.data;
            console.log("listaServiciosVariables en saga",listaServiciosVariables)
            let listaServiciosVariablesCantidad = response.data.cantidad;
            
            for (const i in listaServiciosVariables) {
                if(listaServiciosVariables[i].servicioByIdServicio){
                    listaServiciosVariables[i].listaVariables = [];
                    listaServiciosVariables[i].nombre = listaServiciosVariables[i].servicioByIdServicio.nombre
                }
                if (listaServiciosVariables[i].variablesByIdServicioVariable) {

                    listaServiciosVariables[i].variablesByIdServicioVariable.forEach(item => {
                        listaServiciosVariables[i].listaVariables.push(item.variable);
                        item.idServicioVariable = listaServiciosVariables[i].idServicioVariable;
                        for(let y in item.variablesServiciosVariablesByIdVariable){
                            item.variablesServiciosVariablesByIdVariable[y].idServicioVariable = listaServiciosVariables[i].idServicioVariable;
                        }
                    });
                }
            }
            console.log('listaServiciosVariables al final', listaServiciosVariables)
            
            yield put({ type: serviciosVariablesActions.OBTENER_SERVICIOS_VARIABLES_SUCCESS, listaServiciosVariables , listaServiciosVariablesCantidad, listServiciosVariablesSearch});

        } else {
            yield put({ type: serviciosVariablesActions.OBTENER_SERVICIOS_VARIABLES_FAILURE });
        }
    } catch(error){
        yield put({ type: serviciosVariablesActions.OBTENER_SERVICIOS_VARIABLES_FAILURE });
    }
}
export function* obtenerServiciosVariablesBusquedaSaga(){
    yield takeLatest(serviciosVariablesActions.OBTENER_SERVICIOS_VARIABLES_REQUEST, obtenerServiciosVariablesBusqueda)
}


function* buscarServiciosVariables(action){
    try{
        console.log("action de buscarServiciosVariables",action);
        const token = sessionStorage.getItem('token');
        const page = 0;
        const offset = 50;
        let orden = 'ASC';
        let campo = 'idServicio';
        let param = ''
        
        console.log(param, campo, orden, page)
        const response = yield call(obtenerServiciosVariablesCall, token,param, campo, orden, page, offset);
        console.log('response buscarServiciosVariables', response)

        if(response.status === 200){
            const listaServiciosVariablesBusqueda = response.data.data;
            
            for (const i in listaServiciosVariablesBusqueda) {
                if(listaServiciosVariablesBusqueda[i].servicioByIdServicio){
                    listaServiciosVariablesBusqueda[i].nombre = listaServiciosVariablesBusqueda[i].servicioByIdServicio.nombre
                }
            }
            
            yield put({ type: serviciosVariablesActions.BUSCAR_SERVICIOS_VARIABLES_SUCCESS, listaServiciosVariablesBusqueda});

        } else {
            yield put({ type: serviciosVariablesActions.BUSCAR_SERVICIOS_VARIABLES_FAILURE });
        }
    } catch(error){
        yield put({ type: serviciosVariablesActions.BUSCAR_SERVICIOS_VARIABLES_FAILURE });
    }
}
export function* buscarServiciosVariablesSaga(){
    yield takeLatest(serviciosVariablesActions.BUSCAR_SERVICIOS_VARIABLES_REQUEST, buscarServiciosVariables)
}

//variables by servicio
function* obtenersVariablesByServicio(action){
    try{
        console.log("action de obtenersVariablesByServicio",action);
        const token = sessionStorage.getItem('token');
        const idServicioVariable = action.idServicioVariable;
        const response = yield call(obtenersVariablesByServicioCall, token, idServicioVariable);
        console.log('response obtenersVariablesByServicio', response)

        if(response.status === 200){
            const listaVariablesByServicio = response.data;
            yield put({ type: serviciosVariablesActions.OBTENER_VARIABLES_BY_SERVICIO_SUCCESS, listaVariablesByServicio});

        } else {
            yield put({ type: serviciosVariablesActions.OBTENER_VARIABLES_BY_SERVICIO_FAILURE });
        }
    } catch(error){
        yield put({ type: serviciosVariablesActions.OBTENER_VARIABLES_BY_SERVICIO_FAILURE });
    }
}
export function* obtenersVariablesByServicioSaga(){
    yield takeLatest(serviciosVariablesActions.OBTENER_VARIABLES_BY_SERVICIO_REQUEST, obtenersVariablesByServicio)
}

// crear servicio variable
function* crearServicioVariable(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const formulario = action.formulario;
        let variablesByIdServicioVariable = [];
        const listaVariablesActivas = action.listaVariablesActivas;

        for (const i of formulario.variablesByIdServicioVariable) {
            for (const k in listaVariablesActivas) {
                if(listaVariablesActivas[k].idVariable == i){
                    variablesByIdServicioVariable.push({
                        idVariable: listaVariablesActivas[k].idVariable,
                        variable: listaVariablesActivas[k].variable,
                        considerTiempo:  listaVariablesActivas[k].considerTiempo,
                        tiempo: listaVariablesActivas[k].tiempo
                    })
                }
            }
       }
        
        const data = {
            "idServicio": parseInt(formulario.idServicio),
            "descripcion": formulario.descripcion,
            "activo": true,
            "variablesByIdServicioVariable": variablesByIdServicioVariable
        }

        console.log("data",data)

         const response = yield call(crearServicioVariableCall, token, data)
         console.log('response crearServicioVariable', response);

         if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "El servicio variable creó de forma correcta";
            const page = action.page;
            const busqueda = action.busqueda;
            let showModalNuevoServicioVariable = false;

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosVariablesActions.CREAR_SERVICIO_VARIABLE_SUCCESS });
            yield put({ type: serviciosVariablesActions.SHOW_MODAL_CREAR_SERVICIO_VARIABLE, showModalNuevoServicioVariable });
            yield put({ type: serviciosVariablesActions.OBTENER_SERVICIOS_VARIABLES_REQUEST , page, busqueda});
         } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosVariablesActions.CREAR_SERVICIO_VARIABLE_FAILURE });
         }
    } catch (error) {
        yield put({ type: serviciosVariablesActions.CREAR_SERVICIO_VARIABLE_FAILURE });
    }
}

export function* crearServicioVariableSaga(){
    yield takeLatest(serviciosVariablesActions.CREAR_SERVICIO_VARIABLE_REQUEST, crearServicioVariable)
}
// editar servicio variable
function* editarServicioVariable(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const formulario = action.formulario;
        let variablesByIdServicioVariable = [];
        console.log("formulario",formulario, "listaVariablesActivas",listaVariablesActivas)
        const listaVariablesActivas = action.listaVariablesActivas;
        const idServicioVariable = action.idServicioVariable;

        for (const i of formulario.listaVariables) {
            for (const k in listaVariablesActivas) {
                if(listaVariablesActivas[k].idVariable == i || listaVariablesActivas[k].variable == i){
                    variablesByIdServicioVariable.push({
                        idVariable: listaVariablesActivas[k].idVariable,
                        variable: listaVariablesActivas[k].variable,
                        considerTiempo:  listaVariablesActivas[k].considerTiempo,
                        tiempo: listaVariablesActivas[k].tiempo
                    })
                }
            }
        }

        const data = {
            "idServicioVariable": action.idServicioVariable,
            "idServicio": action.idServicio,
            "descripcion": formulario.descripcion,
            "activo": true,
            "variablesByIdServicioVariable": variablesByIdServicioVariable
        }
        console.log('data', data)

        const response = yield call(editarServicioVariableCall, token, idServicioVariable, data)
         console.log('response editarServicioVariable', response);

         if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "El servicio variable editó de forma correcta";
            const page = action.page;
            const busqueda = action.busqueda;
            let showModalEditarServicioVariable = false;

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosVariablesActions.EDITAR_SERVICIO_VARIABLE_SUCCESS });
            yield put({ type: serviciosVariablesActions.SHOW_MODAL_EDITAR_SERVICIO_VARIABLE, showModalEditarServicioVariable });
            yield put({ type: serviciosVariablesActions.OBTENER_SERVICIOS_VARIABLES_REQUEST , page, busqueda});
         } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosVariablesActions.EDITAR_SERVICIO_VARIABLE_FAILURE });
         }
    } catch (error) {
        yield put({ type: serviciosVariablesActions.EDITAR_SERVICIO_VARIABLE_FAILURE });
    }
}

export function* editarServicioVariableSaga(){
    yield takeLatest(serviciosVariablesActions.EDITAR_SERVICIO_VARIABLE_REQUEST, editarServicioVariable)
};
// activar/desactivar servicio variable
function* estadoServiciosVariables(action){
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServicioVariable = action.idServicioVariable;
        
        const response = yield call(activarServicioVariableCall, token, idServicioVariable);
        console.log('response estadoServiciosVariables', response)

        if(response.status === 200){
            let page = action.page;
            let busqueda = {campo: '', orden: ''}
            const showSuccessMsg = true;
            const textMessage = "El estado del servicio variable se cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosVariablesActions.ESTADO_SERVICIO_VARIABLES_SUCCESS });
            yield put({ type: serviciosVariablesActions.OBTENER_SERVICIOS_VARIABLES_REQUEST , page, busqueda});

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosVariablesActions.ESTADO_SERVICIO_VARIABLES_FAILURE });
        }
    } catch(error){
        yield put({ type: serviciosVariablesActions.ESTADO_SERVICIO_VARIABLES_FAILURE });
    }
}
export function* estadoServiciosVariablesSaga(){
    yield takeLatest(serviciosVariablesActions.ESTADO_SERVICIO_VARIABLES_REQUEST, estadoServiciosVariables)
}

// ubicaciones activas
function* obtenerUbicacionActivas(action){
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        
        const response = yield call(obtenerUbicacionesActivasCall, token);
        console.log('response obtenerUbicacionesActivas', response)

        if(response.status === 200){
            let ubicacionesActivas = response.data;
            let ubicacionesActivasCantidad = response.cantidad;
            

            ubicacionesActivas.forEach(ubicacionActiva => {
                if (ubicacionActiva.subInteriorFuncionalByIdSubInteriorFuncional) {
                    ubicacionActiva.ubicacion = ubicacionActiva.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                } else if(ubicacionActiva.interiorFuncionalByIdInteriorFuncional){
                    ubicacionActiva.ubicacion = ubicacionActiva.interiorFuncionalByIdInteriorFuncional.interiorFuncional;
                } else if(ubicacionActiva.unidadFuncionalByIdUnidadFuncional){
                    ubicacionActiva.ubicacion = ubicacionActiva.unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                } else if(ubicacionActiva.areaFuncionalByIdAreaFuncional){
                    ubicacionActiva.ubicacion = ubicacionActiva.areaFuncionalByIdAreaFuncional.areaFuncional
                }
            })

            yield put({ type: serviciosVariablesActions.OBTENER_UBICACIONES_ACTIVAS_SUCCESS, ubicacionesActivas , ubicacionesActivasCantidad});

        } else {
            yield put({ type: serviciosVariablesActions.OBTENER_UBICACIONES_ACTIVAS_FAILURE});
        }
    } catch(error){
        yield put({ type: serviciosVariablesActions.OBTENER_UBICACIONES_ACTIVAS_FAILURE});
    }
}
export function* obtenerUbicacionActivasSaga(){
    yield takeLatest(serviciosVariablesActions.OBTENER_UBICACIONES_ACTIVAS_REQUEST, obtenerUbicacionActivas)
}

//obtener subvariable by idVariable y idServicioVariable
function* obtenerSubVariableById(action){
    try {
        console.log("action obtenerSubVariableById", action);
        const token = sessionStorage.getItem('token');
        const idServicioVariable = '?idServicioVariable=' + action.idServicioVariable;
        const idVariable = '&idVariable=' + parseInt(action.idVariable);

        const response = yield call(obtenerSubVariableByIdCall, token, idServicioVariable, idVariable);
        console.log('response obtenerSubVariableByIdCall', response);

        if(response.status === 200){
            let listSubvariablesByVariable = response.data;
            console.log("listSubvariablesByVariable: ",listSubvariablesByVariable)

            for(let i =0;  i<listSubvariablesByVariable.length; i++){
                if(listSubvariablesByVariable[i].fechaProductividad!=null ){
                    listSubvariablesByVariable[i].fechaProductividad = moment(listSubvariablesByVariable[i].fechaProductividad).format('YYYY-MM-DD').split('-').reverse().join('-');
                };
                listSubvariablesByVariable[i].productividadAgregada = false;
            }
           
            yield put({type: serviciosVariablesActions.GET_SUBVARIABLE_BY_IDS_SUCCESS,  listSubvariablesByVariable})
        } else {
            yield put({type: serviciosVariablesActions.GET_SUBVARIABLE_BY_IDS_FAILURE})
        }
    } catch (error) {
        yield put({type: serviciosVariablesActions.GET_SUBVARIABLE_BY_IDS_FAILURE})
    }
}
export function* obtenerSubVariableByIdSaga(){
    yield takeLatest(serviciosVariablesActions.GET_SUBVARIABLE_BY_IDS_REQUEST, obtenerSubVariableById)
}

//obtener subvariable by idVariable
function* obtenerSubVariableByIdVariable(action){
    try {
        console.log("action obtenerSubVariableById", action);
        const token = sessionStorage.getItem('token');
        const idVariable = parseInt(action.idVariable);

        const response = yield call(obtenerSubVariableByIdVariableCall, token, idVariable);
        console.log('response obtenerSubVariableByIdCall', response);

        if(response.status === 200){
            let listSubvariablesByVariable = response.data;
            console.log("listSubvariablesByVariable: ",listSubvariablesByVariable)
           
            yield put({type: serviciosVariablesActions.GET_SUBVARIABLE_BY_ID_VARIABLE_SUCCESS,  listSubvariablesByVariable})
        } else {
            yield put({type: serviciosVariablesActions.GET_SUBVARIABLE_BY_ID_VARIABLE_FAILURE})
        }
    } catch (error) {
        yield put({type: serviciosVariablesActions.GET_SUBVARIABLE_BY_ID_VARIABLE_FAILURE})
    }
}
export function* obtenerSubVariableByIdVariableSaga(){
    yield takeLatest(serviciosVariablesActions.GET_SUBVARIABLE_BY_ID_VARIABLE_REQUEST, obtenerSubVariableByIdVariable)
}

// detalle servicio variable(variables)
function* obtenerDetalleServicioVariable(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServicioVariable = action.idServicioVariable;

        const response = yield call(detalleServicioVariableCall, token, idServicioVariable);
        console.log('response obtenerDetalleServicioVariable', response);

        if(response.status === 200){
            let listaDetalleServicioVariable = response.data[0];
            console.log("listaDetalleServicioVariable",listaDetalleServicioVariable)
            yield put({type: serviciosVariablesActions.LISTA_DETALLE_SERVICIO_VARIABLE_SUCCESS,  listaDetalleServicioVariable})
        } else {
            yield put({type: serviciosVariablesActions.LISTA_DETALLE_SERVICIO_VARIABLE_FAILURE})
        }
    } catch (error) {
        yield put({type: serviciosVariablesActions.LISTA_DETALLE_SERVICIO_VARIABLE_FAILURE})
    }
}
export function* obtenerDetalleServicioVariableSaga(){
    yield takeLatest(serviciosVariablesActions.LISTA_DETALLE_SERVICIO_VARIABLE_REQUEST, obtenerDetalleServicioVariable)
}
// captura de productividades
function* capturarProductividadVariable(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const formulario = action.formulario;
        
        const data ={
            "idServicioVariable": action.idServicioVariable,
            "variablesServiciosVariablesByIdVariable": formulario.variablesServiciosVariablesByIdVariable
        }

        console.log('data en capturarProductividadVariable', data)
        const formData = new FormData();
        if (action.pdf) {
            formData.append('file', action.pdf.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        console.log('formData',formData);
        const response = yield call(capturarProductividadCall, token, formData);
        console.log('response capturarProductividadVariable', response);

        if (response.status === 200) {
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage: "La productividad de la variable se agregó de forma correcta" });
            yield put({ type: serviciosVariablesActions.CAPTURAR_PRODUCTIVIDAD_VARIABLE_SUCCESS })
            yield put({ type: serviciosVariablesActions.SHOW_MODAL_CAPTURAR_INFO_SERVICIO_VARIABLE, showModalCapturarInfoServicioVariable: false })
            yield put({ type: serviciosVariablesActions.GUARDAR_PRODUCTIVIDAD_VARIABLE, formulario: null, productividadAgregada: false })
            yield put({ type: serviciosVariablesActions.LISTA_DETALLE_SERVICIO_VARIABLE_REQUEST , idServicioVariable:action.idServicioVariable})
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosVariablesActions.CAPTURAR_PRODUCTIVIDAD_VARIABLE_FAILURE })
        }
    } catch (error) {
        const showErrorMsg = true;
        const textMessage = "Hubo un error";
        console.log("error",error)

        yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: serviciosVariablesActions.CAPTURAR_PRODUCTIVIDAD_VARIABLE_FAILURE })
    }
}
export function* capturarProductividadVariableSaga(){
    yield takeLatest(serviciosVariablesActions.CAPTURAR_PRODUCTIVIDAD_VARIABLE_REQUEST, capturarProductividadVariable)
}

// obtener variables filtradas
function* obtenerVariablesFiltradas(action){
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServicioVariable = action.idServicioVariable;
        
        const response = yield call(obtenerVariablesFiltradasCall, token, idServicioVariable);
        console.log('response obtenerVariablesFiltradas', response)

        if(response.status === 200){
            let listaVariablesFiltradas = response.data;
            yield put({ type: serviciosVariablesActions.OBTENER_VARIABLES_FILTRADAS_POR_SERVICIO_SUCCESS, listaVariablesFiltradas });
        } else {
            yield put({ type: serviciosVariablesActions.OBTENER_VARIABLES_FILTRADAS_POR_SERVICIO_FAILURE});
        }
    } catch(error){
        yield put({ type: serviciosVariablesActions.OBTENER_VARIABLES_FILTRADAS_POR_SERVICIO_FAILURE});
    }
}
export function* obtenerVariablesFiltradasSaga(){
    yield takeLatest(serviciosVariablesActions.OBTENER_VARIABLES_FILTRADAS_POR_SERVICIO_REQUEST, obtenerVariablesFiltradas)
}

// estado variables servicios variables
function* estadoVariableServicioVariable(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idVariable = action.idVariable;
        const idServicioVariable = action.idServicioVariable;

        const response = yield call(activarVariableServicioVariableCall, token, idServicioVariable, idVariable)
        console.log('response estadoVariableServicioVariable', response)

        if(response.status === 200){
            const page = 1;
            const busqueda = '';
            let showModalEditarServicioVariable = true;
        
            yield put({ type: serviciosVariablesActions.ESTADO_VARIABLE_SERVICIO_VARIABLES_SUCCESS});
            yield put({ type: serviciosVariablesActions.OBTENER_VARIABLES_FILTRADAS_POR_SERVICIO_REQUEST, idServicioVariable});
            yield put({ type: serviciosVariablesActions.OBTENER_SERVICIOS_VARIABLES_REQUEST, page, busqueda});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosVariablesActions.ESTADO_VARIABLE_SERVICIO_VARIABLES_FAILURE});
        }
    } catch (error) {
        yield put({ type: serviciosVariablesActions.ESTADO_VARIABLE_SERVICIO_VARIABLES_FAILURE});
    }
}
export function* estadoVariableServicioVariableSaga(){
    yield takeLatest(serviciosVariablesActions.ESTADO_VARIABLE_SERVICIO_VARIABLES_REQUEST, estadoVariableServicioVariable)
}

// saga presentacion servicio variable
function* presentacionServicioVariable(action){
    try {
        console.log("action presentacionServicioVariable",action);
        const token = sessionStorage.getItem('token');
        const formulario = action.formulario;
        const idServicioVariable = parseInt(action.idServicioVariable)+'?';
        let comparativa = action.comparativa;
        let page = action.page-1;
        let idSubvariable = '';
        let idVariable = '';
        let idUbicacion = '';
        const label = action.label ? action.label : '';
        console.log("label",label, label[0])

        if(action.idSubvariable){
            idSubvariable = '&idSubvariable=' + parseInt(action.idSubvariable) ;
        }
        if(action.idVariable){
            idVariable = '&idVariable=' + parseInt(action.idVariable);
        }
        if(formulario.idUbicacion){
            idUbicacion = '&idUbicacion=' + parseInt(formulario.idUbicacion);
        }

        let fechaInicio = formulario.fechas[0].format('YYYY-MM-DD');
        let fechaFin = formulario.fechas[1].format('YYYY-MM-DD');
        console.log("lo que mando presentacionServicioVariable",idServicioVariable, idVariable, idSubvariable, idUbicacion, comparativa, fechaInicio, fechaFin, page);

        yield put({ type: serviciosVariablesActions.PRESENTACION_SERVICIO_VARIABLES_SUCCESS , servicioPresentacion:{}, serVariablesResponse:[], dataGraficaUnitarioPSV:{}, optionsGraficaPSV:{}});

        const response = yield call(presentacionServicioVariableCall, token, idServicioVariable, idVariable, idSubvariable, idUbicacion, comparativa, fechaInicio, fechaFin, page);
        console.log('response presentacionServicioVariable', response)
        if(response.status ===200){
            let servicioPresentacion;
            let serVariablesResponse = response.data.serVariablesResponse; // data para descarga del excel
            let serVariableProductividades = response.data.data; // data para graficar

            console.log("serVariablesResponse",serVariablesResponse)

            //Caso Unitario
            let etiquetaProductividad = [];
            let labelHover = []
            let etiquetasFechas = [];


            function generarNumero(numero){
                return (Math.random()*numero).toFixed(0);
            }

            function colorRGB(){
                let color = "("+generarNumero(255)+"," + generarNumero(255) + "," + generarNumero(255) +")";
                return "rgb" + color;
            }

            if(comparativa === 'Unitario'){
                for(let i in serVariableProductividades){
                    if(serVariableProductividades[i].fechaProductividad){
                        let fechaProductividadTabla = serVariableProductividades[i].fechaProductividad.split('T')[0];
                        serVariableProductividades[i].fechaProductividadTabla = fechaProductividadTabla.split('-')[2] + '-' + fechaProductividadTabla.split('-')[1] + '-' + fechaProductividadTabla.split('-')[0];
                    }
                }

                console.log("serVariableProductividades",serVariableProductividades);

                const uniqueSubVars = [];

                const unique = serVariableProductividades.filter(element => {
                  const isDuplicate = uniqueSubVars.includes(element.subvariable);
                
                  if (!isDuplicate) {
                    uniqueSubVars.push(element.subvariable);
                
                    return true;
                  }
                
                  return false;
                });

                console.log("unique",unique)

                for(let i in unique){
                    etiquetaProductividad.push(unique[i]);
                }

                let nuevoArray = []
                let arrayTemporal = []
                for(let i in serVariableProductividades){
                    arrayTemporal = nuevoArray.filter(resp => resp["fecha"] == serVariableProductividades[i]["fechaProductividadTabla"]);
                    console.log("arrayTemporal",arrayTemporal)
                    if(arrayTemporal.length>0){
                        nuevoArray[nuevoArray.indexOf(arrayTemporal[0])]["Data"].push(serVariableProductividades[i]["cantidad"])
                        nuevoArray[nuevoArray.indexOf(arrayTemporal[0])]["Etiq"].push(serVariableProductividades[i]["subvariable"])
                    }
                    else{
                        nuevoArray.push({
                            "fecha" : serVariableProductividades[i]["fechaProductividadTabla"] , 
                            "Data" : [serVariableProductividades[i]["cantidad"]],
                            "Etiq" : [serVariableProductividades[i]["subvariable"]],
                            "Var" : [serVariableProductividades[i]["variable"]],
                        })
                    }
                }
                console.log("nuevoArray",nuevoArray)

                for(let i in nuevoArray){
                    if(nuevoArray.length>0){
                        etiquetasFechas.push(nuevoArray[i].fecha);
                    }
                }

                let nvoObjetoConjuntoPBA = [];
                let etiquetasPBA = [];
                let objtVDD = {}
                for(let i in nuevoArray){
                    if(nuevoArray[i]){
                        for(let j in nuevoArray[i].Data){
                            let cantidadDia = [];
                            etiquetasPBA.push(nuevoArray[i].Etiq[j]);

                            for(let k in nuevoArray){
                                cantidadDia.push(0);
                            }
                            
                            cantidadDia[i] = nuevoArray[i].Data[j];
                            nvoObjetoConjuntoPBA.push(cantidadDia);                        
                        }
                    }
                }

                etiquetasPBA.forEach( (k, i) => { 
                    let indices = [];
                    let idx = etiquetasPBA.indexOf(k);
                    while (idx != -1) {
                        indices.push(idx);
                        idx = etiquetasPBA.indexOf(k, idx + 1);
                    }
                    for(let x in indices){
                        let suma = nvoObjetoConjuntoPBA[indices[0]];

                        if(indices[1]){
                            for(let y in nvoObjetoConjuntoPBA[i]){
                                if(suma[y] == 0){
                                    suma[y] += nvoObjetoConjuntoPBA[indices[x]][y]
                                }
                            }
                        }
                        objtVDD[k] = suma; 
                        
                    }
                })

                console.log("objtVDD",objtVDD)

                let etiquetas = [];
                for(let i in etiquetaProductividad){
                    if(etiquetaProductividad.length>0){
                        etiquetas.push({
                            label: etiquetaProductividad[i].subvariable,
                            backgroundColor: colorRGB(),
                            data: objtVDD[etiquetaProductividad[i].subvariable]
                        });
                    }
                }

                const dataGraficaUnitarioPSV = {
                    labels: etiquetasFechas,
                    datasets: etiquetas
                };

                const optionsGraficaPSV = {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            display: true,
                            ticks: {
                                suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
                                suggestedMax: 10, 
                            },
                        }],
                        xAxes: [{  
                            display: true,
                            ticks: {mirror: false},
                            gridLines: { display : true },
                        }]
                    },
                    tooltips: {
                        callbacks: {
                            title: function(tooltipItem, data) {
                                return data['labels'][tooltipItem[0]['index']];
                            },
                            label: function(tooltipItem, data) {
                                console.log("tooltipItem",tooltipItem,"data",data)
                                let dataset = data.datasets[tooltipItem.datasetIndex];
                                let currentValue = dataset.data[tooltipItem.index];
                                const servicio = label[0] ? 'Servicio: ' + label[0].servicio + " | " : ''
                                console.log("servicio",servicio)
                                const subvariable = label[1] ? ' | Subvariable:' + label[1].subvariable : ' | Subvariable:' + etiquetaProductividad[tooltipItem.datasetIndex].subvariable
                                
                                return  servicio + 'Variable: ' + etiquetaProductividad[tooltipItem.datasetIndex].variable + subvariable + ' | Cantidad: ' +  currentValue ;
                            },
                        },
                        backgroundColor: '#FFF',
                        titleFontSize: 14,
                        titleFontColor: '#1747ca',
                        bodyFontColor: '#0e2c82',
                        bodyFontSize: 12,
                        displayColors: false
                    },
                    legend: { display: true, labels: {boxWidth: 15} },
                }

                console.log("dataGraficaUnitarioPSV",dataGraficaUnitarioPSV)

                yield put({ type: serviciosVariablesActions.PRESENTACION_SERVICIO_VARIABLES_SUCCESS , servicioPresentacion, serVariablesResponse, dataGraficaUnitarioPSV, optionsGraficaPSV});
            } else {
                //Caso General
                let cantidadProductividad = [];
                let colorsBar = [];

                for(let i in serVariableProductividades){
                    if(serVariableProductividades[i].subvariableByIdSubvariable){
                        etiquetaProductividad.push(serVariableProductividades[i].subvariableByIdSubvariable.nombre);
                    }
                    if(serVariableProductividades[i].servicioByIdServicio){
                        labelHover.push(serVariableProductividades[i].servicioByIdServicio.nombre);
                    }
                    cantidadProductividad.push(serVariableProductividades[i].cantidad);
                    colorsBar.push(colorRGB());
                }

                const dataGraficaUnitarioPSV = {
                    labels: etiquetaProductividad,
                    datasets: [{  data: cantidadProductividad,  backgroundColor: colorsBar, borderColor: '#f8faf3',  borderWidth: 2 }],
                };

                const optionsGraficaPSV = {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            display: true,
                            ticks: {
                                suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
                                suggestedMax: 10, 
                            },
                        }],
                        xAxes: [{  
                            display: true,
                            ticks: {mirror: false},
                            gridLines: { display : true },
                        }]
                    },
                    tooltips: {
                        callbacks: {
                            title: function(tooltipItem, data) {
                                return data['labels'][tooltipItem[0]['index']];
                            },
                            label: function(tooltipItem, data) {
                                let dataset = data.datasets[tooltipItem.datasetIndex];
                                let currentValue = dataset.data[tooltipItem.index];
                                
                                return labelHover[tooltipItem.index] + ' | Cantidad: ' +  currentValue ;
                            },
                        },
                        backgroundColor: '#FFF',
                        titleFontSize: 14,
                        titleFontColor: '#1747ca',
                        bodyFontColor: '#0e2c82',
                        bodyFontSize: 12,
                        displayColors: false
                    },
                    legend: { display: false, labels: {boxWidth: 15} },
                }

                yield put({ type: serviciosVariablesActions.PRESENTACION_SERVICIO_VARIABLES_SUCCESS , servicioPresentacion, serVariablesResponse, dataGraficaUnitarioPSV, optionsGraficaPSV});
            }

            
        } else {
            yield put({ type: serviciosVariablesActions.PRESENTACION_SERVICIO_VARIABLES_FAILURE})
        }
    } catch (error) {
        console.log("error presentacionServicioVariable", error)
        yield put({ type: serviciosVariablesActions.PRESENTACION_SERVICIO_VARIABLES_FAILURE})
    }
}
export function* presentacionServicioVariableSaga(){
    yield takeLatest(serviciosVariablesActions.PRESENTACION_SERVICIO_VARIABLES_REQUEST, presentacionServicioVariable)
}

// exportar servicio var excel
function* exportarExcelServicioVariable(action){
    try{
        console.log("action exportarExcelServicioVariable",action);
        const token = sessionStorage.getItem('token');
        const data = action.serVariablesResponse

        const response = yield call(exportacionExcelServicioCall, token, data);
        console.log('response exportarExcelServicioVariable', response);

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se descargó el archivo de forma exitosa";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Servicios_Variables " + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + "_" + new Date().getHours() + "_" + new Date().getMinutes() + "_" + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosVariablesActions.EXPORTAR_EXCEL_SERVICIO_VARIABLES_SUCCESS});

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosVariablesActions.EXPORTAR_EXCEL_SERVICIO_VARIABLES_FAILURE});
        }
        
    } catch(error){
        yield put({ type: serviciosVariablesActions.EXPORTAR_EXCEL_SERVICIO_VARIABLES_FAILURE});
    }
}
export function* exportarExcelServicioVariableSaga(){
    yield takeLatest(serviciosVariablesActions.EXPORTAR_EXCEL_SERVICIO_VARIABLES_REQUEST, exportarExcelServicioVariable)
}

function* presentacionServiciosVariablesPDF(action){
    try {
        console.log("action presentacionServiciosVariablesPDF",action );
        const token = sessionStorage.getItem('token');
        let comparativa = action.formulario.comparativa;
        const idVariable = action.formulario.idVariable ? "&idVariable=" + parseInt(action.idVariable) : '' 
        const idSubvariable = action.formulario.idSubvariable ? "&idSubvariable=" + parseInt(action.idSubvariable) : '' 
        const idServicioVariable = comparativa === 'Unitario' ? "&idServicioVariable=" + parseInt(action.idServicioVariable) : '';
        let page = action.page-1;

        let fechaInicio = action.rangeValue[0].format('YYYY-MM-DD');
        let fechaFin = action.rangeValue[1].format('YYYY-MM-DD');

        console.log("lo que mando presentacionServiciosVariablesPDF",idServicioVariable, idVariable, idSubvariable, comparativa, fechaInicio, fechaFin, page)
        const response = yield call(presentacionSVPDFCall, token, idServicioVariable,idVariable, idSubvariable, comparativa, fechaInicio, fechaFin, page);
        console.log("response presentacionServiciosVariablesPDF",response)
        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se descargó el archivo de forma exitosa";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            let month = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);

            link.href = url;
            link.download = "PresentacionSV " + new Date().getDate() + "-" + month  + "-" + + new Date().getFullYear() + "_" + new Date().getHours() + "" + new Date().getMinutes() +  ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosVariablesActions.PRESENTACION_SV_PDF_SUCCESS });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosVariablesActions.PRESENTACION_SV_PDF_FAILURE });
        }

    } catch (error) {
        yield put({ type: serviciosVariablesActions.PRESENTACION_SV_PDF_FAILURE });
    }
}
export function* presentacionServiciosVariablesPDFSaga(){
    yield takeLatest(serviciosVariablesActions.PRESENTACION_SV_PDF_REQUEST, presentacionServiciosVariablesPDF)
}

function* serviciosVariablesByIdServicio(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServicio = parseInt(action.idServicio);

        const response = yield call(serviciosVariablesByIdServicioCall, token, idServicio)
        console.log('response',response);
        if(response.status === 200){
            const listaServiciosVariablesByIdServicio = response.data.data;
            console.log("listaServiciosVariablesByIdServicio",listaServiciosVariablesByIdServicio)

            yield put({ type: serviciosVariablesActions.LISTA_SERVICIOS_VARIABLES_BY_IDSERVICIO_SUCCESS, listaServiciosVariablesByIdServicio });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosVariablesActions.LISTA_SERVICIOS_VARIABLES_BY_IDSERVICIO_FAILURE });
        }

    } catch (error) {
        yield put({ type: serviciosVariablesActions.LISTA_SERVICIOS_VARIABLES_BY_IDSERVICIO_FAILURE });
    }
}
export function* serviciosVariablesByIdServicioSaga(){
    yield takeLatest(serviciosVariablesActions.LISTA_SERVICIOS_VARIABLES_BY_IDSERVICIO_REQUEST, serviciosVariablesByIdServicio)
}