import React from 'react';
import { Card, Alert, Row, Col, List } from 'antd';
import { connect } from "react-redux";
import { ShrinkOutlined, ArrowsAltOutlined, ImportOutlined } from '@ant-design/icons';

import CatalogoUnidadesDeMedicion from './CatalogoDeUnidadesDeMedicion/CatalogoDeUnidadesDeMedicion';
import CatalogoCategoriasDeMedicion from './CatalogoCategoriasDeMedicion/CatalogoCategoriasDeMedicion';
import CatalogoActivosCaract from './CatálogoActivosCaraterísticas/CatalogoActivosCaract';
import CatalogoActivos from './CatalogoActivos/CatalogoActivos';
import ReingresoActivos from './ReingresoActivos/ReingresoActivoContainer'
import AsignacionDeActivosFijos from './AsignacionDeActivosFijos/AsignacionActivosContainer';
import CatalogoRangoRenovacionActivo from './CatalogoRangosRenovacionActivoFijo/CatalogoRangosRenovacionActivoFijo';
import CatalogoEstatusDelActivo from './CatalogoEstatusDelActivo/CatalogoEstatusDelActivo';
import SalidaDeBienesContainer from './SalidaDeBienes/SalidaDeBienesContainer';
import RequisicionActualizacionTecnologica from './RequisicionPorActualizacionTecnologica/RequisicionPorActualizacionTecnologica';

import CatalogoMotivosDeSalida from './CatalogoMotivosDeSalida/CatalogoMotivosDeSalida'
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import CargaMasivaActivosContainer from './CargaMasivaActivos/CargaMasivaActivosContainer';

import imaSearch from '../../../assets/undraw_searching_re_3ra9.svg';
import ReactVivus from "react-vivus";


function isEmpty(obj) {
    for(var prop in obj) {
        if(obj.hasOwnProperty(prop))
            return false;
    }
    return true;
}
class DashboardContainer extends React.Component {
    state={
        catalogo: 'Selecciona un Catálogo',
        colLista: 8,
        colTabla: 15,
        maximizar: false
    }
    render() {
        const { onChangeCatalogo, catalogoAlmacenes } = this.props;

        const listaCatalogos = [{
                'catalogo': 'Catálogo de Categorías de Medición',
            },{
                'catalogo': 'Catálogo de Unidades de Medición',
            },{
                'catalogo': 'Catálogo de Características de Activos',
            },{
                'catalogo': 'Catálogo de Activos',  
            },{
                'catalogo': 'Carga Masiva de Activos',
            },{
                'catalogo': 'Catálogo de Estatus del Activo',
            },{
                'catalogo': 'Catálogo de Rangos para Renovación del Activo Fijo',
            },{
                'catalogo': 'Catálogo de Categorías de Motivos de Salida',
            },{
                'catalogo': 'Solicitud de Salida'
            },{
                'catalogo': 'Reingreso de Activos',
            },{
                'catalogo': 'Monitor de Actualización Tecnológica',
            },
        ];
        function ShowInfo(props) {
            if (isEmpty(catalogoAlmacenes)) {
                return (
                    <Row justify='space-around' align='middle'>
                        <Col span={24}>
                            <Alert message="Favor de seleccionar un catálogo" type="info" />
                        </Col>
                        <Col span={10} style={{paddingTop:'8em', paddingBottom:'8em'}}>
                            {/* <img src={imaSearch} /> */}
                            <ReactVivus
                                id="imaSearch"
                                option={{
                                    file: imaSearch,
                                    animTimingFunction: "EASE",
                                    type: "delayed",
                                }}
                            />
                        </Col>
                    </Row>);
            }
            
	        return (
                <div>
                    <CatalogoApp/>
                </div>
            );
        }

        function CatalogoApp() {
            if(catalogoAlmacenes === 'Carga Masiva de Activos'){
                return(
                    <div>
                        <CargaMasivaActivosContainer />
                    </div>
                );
            }
            else if(catalogoAlmacenes === 'Catálogo de Activos'){
                return(
                    <div>
                        <CatalogoActivos />
                    </div>
                );
            }
            else if(catalogoAlmacenes === 'Catálogo de Características de Activos'){
                return(
                    <div>
                        <CatalogoActivosCaract />
                    </div>
                );
            }
            else if(catalogoAlmacenes === 'Catálogo de Unidades de Medición'){
                return(
                    <div>
                        <CatalogoUnidadesDeMedicion/>
                    </div>
                );
            }
            else if(catalogoAlmacenes === 'Catálogo de Categorías de Medición'){
                return(
                    <div>
                        <CatalogoCategoriasDeMedicion/>
                    </div>
                );
            }
            else if(catalogoAlmacenes === 'Catálogo de Rangos para Renovación del Activo Fijo'){
                return(
                    <div>
                        <CatalogoRangoRenovacionActivo/>
                    </div>
                );
            }
            else if(catalogoAlmacenes === 'Catálogo de Estatus del Activo'){
                return(
                    <div>
                        <CatalogoEstatusDelActivo/>
                    </div>
                )
            }
            else if(catalogoAlmacenes === 'Catálogo de Categorías de Motivos de Salida'){
                return(
                    <div>
                        <CatalogoMotivosDeSalida/>
                    </div>
                );
            }
            else if(catalogoAlmacenes === 'Solicitud de Salida'){
                return(
                    <div>
                        <SalidaDeBienesContainer/>
                    </div>
                )
            }
            else if(catalogoAlmacenes === 'Monitor de Actualización Tecnológica'){
                return(
                    <div>
                        <RequisicionActualizacionTecnologica/>
                    </div>
                );
            }
            else if(catalogoAlmacenes === 'Reingreso de Activos'){
                return(
                    <div>
                        <ReingresoActivos/>
                    </div>
                );
            }
			return <div>  </div>;
        };
        
        const changeCatalogo = (catalogo) => {
            this.setState({
                catalogo: catalogo.catalogo,
            })
            onChangeCatalogo(catalogo.catalogo);
        }
        const onExpandir = () => {
            if(!this.state.maximizar){
                this.setState({
                    colLista: 0,
                    colTabla: 24,
                    maximizar: true
                });
            }
            else{
                this.setState({
                    colLista: 8,
                    colTabla: 15,
                    maximizar: false,
                });
            }
        }

        
        return (
            <div className="imgCorp">
                <Row justify='space-between'>
                    <Col span={this.state.colLista}>
                        <List
                            header={
                                <div className='headerLista'>Catálogos Activo Fijo</div>
                            }
                            className="listaCatalogos"
                            bordered
                            dataSource={listaCatalogos}
                            renderItem={item => (
                            <List.Item className='listaHover'
                                actions={[<ImportOutlined onClick={(e) => { e.stopPropagation(); changeCatalogo(item); } } className="iconAbrir" />]}
                            >
                                <div>{item.catalogo}</div>
                            </List.Item>
                            )}
                        />                    
                    </Col>
                    <Col span={this.state.colTabla}>
                        <Card className="cardCatalogos" title={<div className='headerLista'>{this.state.catalogo}</div>} extra={this.state.maximizar ? (<ShrinkOutlined className='iconoMaxMin' onClick={onExpandir} />):(<ArrowsAltOutlined className='iconoMaxMin' onClick={onExpandir}/>)}>
                            <ShowInfo info={catalogoAlmacenes} />
                        </Card> 
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        vistaDashboard: state.ConfiguracionYSeguridadReducer.vistaDashboard,
        catalogoAlmacenes: state.AlmacenesReducer.catalogoAlmacenes,
    };
};
const mapDispatchToProps = dispatch => {
	return {
	  	onRequestLogin: (loginInfo) => {
	    	dispatch({ type: "LOGIN_API_CALL_REQUEST", loginInfo:loginInfo });
        },
        onChangeCatalogo: (catalogoAlmacenes) => {
            dispatch({ type: "CAMBIAR_CATALOGO_ALMACENES" , catalogoAlmacenes: catalogoAlmacenes});
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);