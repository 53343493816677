import React, { Component } from 'react';
import { Form, Input, Button, Col, Row, Spin, Modal, Select, InputNumber, DatePicker, message } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalNuevaEstandarDeDesempeño extends Component {
     formRef = React.createRef();
     state = {
          idUnidadMedida: null,
          claseReferenciaSeleccionado: '',
          referenciaConSubnivel: '',
          referenciaSin: '',
          banderaTiempo: true,
     }
     render() {
          const { 
               fetchingNuevoEstandaresDeDesempeño,
               fetchingListaReferenciasDeEstandaresActivos,
               listaReferenciasDeEstandaresActivos, 
               showModalNuevoEstandaresDeDesempeño, 
               onNuevoEstandaresDeDesempeño, 
               onShowModalNuevoEstandaresDeDesempeño, 
               limpiarFormulario,
               listaCategoriasFallasDeCalidadActivos,
               fetchingListaCategoriasFallasDeCalidadActivos,
               fetchingListaClavesMetodosSupervisionActivos,
               listaClavesMetodosSupervisionActivos,
               listaInfoProyecto,
               fetchingUnidadesMediPorCategoria,
               unidadesMediPorCategoria,
               listaEstandaresDesempenoEspecificosActivos,
               fetchingListaEstandaresDesempenoEspecificosActivos,

               fetchingListaServiciosActivosF,
               listaServiciosActivosF,
               listaServiciosFiltro,
               onListaServiciosFiltroLimpiar,
               fetchingListaServiciosFiltro,
               onListaServiciosFiltro ,

               listaEstandaresDeDesempeñoBusqueda,
               fetchingListaEstandaresDeDesempeñoBusqueda,
               listaClavesMetodosSupervisionExternos,
          } = this.props;
          const onFinish = formulario => {
               onNuevoEstandaresDeDesempeño(formulario, this.props.page, this.props.busqueda);
          };
          
          if (limpiarFormulario && limpiarFormulario === 'crearEstandarDesempeño') {
               this.formRef.current.resetFields();
               this.props.onLimpiarFormulario();
               this.state.claseReferenciaSeleccionado = '';
               this.state.referenciaConSubnivel = '';
               this.state.referenciaSin= '';
          }
            
          const onFinishFailed = errorInfo => {
               console.log('Failed:', errorInfo);
          };

          const handleCancel = () => {
               onShowModalNuevoEstandaresDeDesempeño();
               this.setState({
                    claseReferenciaSeleccionado: '',
                    referenciaConSubnivel: '',
                    referenciaSin: '',
                });
               this.formRef.current.resetFields();
          }

          const changeDecimales = (value) => {
               let valueDecimal = parseInt(value);
               if(isNaN(valueDecimal)){
               valueDecimal = 0;
               }
               this.formRef.current.setFieldsValue({'tolerancia': valueDecimal});
           }

          const changeTiempoRespuesta = (value) => {
               const tiempoRespuesta = parseInt(value);
               this.formRef.current.setFieldsValue({'tiempoRespuesta': tiempoRespuesta})
               let banderaTiempo = true;

               if(tiempoRespuesta === 0){
                    banderaTiempo = false;
                    this.formRef.current.setFieldsValue({'idUnidadMedida': null});
               }

               this.setState({
                    banderaTiempo: banderaTiempo
               });          
          }

          const fechas = (rangeValue) => {
               if(rangeValue[0]){
                    if(listaInfoProyecto.fechaInicio){
                         {}
                         const diferencia = listaInfoProyecto.fechaInicio.diff(moment(rangeValue[0].format('YYYY-MM-DD')), "days");
                    
                         if(diferencia > 0){
                              message.error('No es posible seleccionar una fecha de inicio menor al inicio de contrato del proyecto', 7);
                              this.formRef.current.resetFields(['rangoVigencia']);
                         }
                    }
                    else{
                         message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                         this.formRef.current.resetFields(['rangoVigencia']);
                    }
               }
          }
          const seleccionClaseReferencia = (value) => {
               let claseReferenciaSeleccionado = '';
               let referenciaConSubnivel = '';
               let referenciaSin = '';
               let tiempoCompleto = '';
               for(let i=0; i<listaReferenciasDeEstandaresActivos.length; i++){
                    if(listaReferenciasDeEstandaresActivos[i].idReferenciaDesempeno == value){
                         claseReferenciaSeleccionado = listaReferenciasDeEstandaresActivos[i].claseReferencia;
                    
                         if(listaReferenciasDeEstandaresActivos[i].subnivel !== ''){ 
                              referenciaConSubnivel = listaReferenciasDeEstandaresActivos[i].referencia + '.' + listaReferenciasDeEstandaresActivos[i].subnivel + ', ' + listaReferenciasDeEstandaresActivos[i].claseReferencia + ', ' + listaReferenciasDeEstandaresActivos[i].descripcion;
                         } else {
                              referenciaConSubnivel = listaReferenciasDeEstandaresActivos[i].referencia + ', ' + listaReferenciasDeEstandaresActivos[i].claseReferencia + ', ' + listaReferenciasDeEstandaresActivos[i].descripcion;
                         }
                         referenciaSin = referenciaConSubnivel.split(', ')[0] + '.';
                    }
               }
               this.setState({
                    claseReferenciaSeleccionado: claseReferenciaSeleccionado,
                    referenciaConSubnivel: referenciaConSubnivel,
                    referenciaSin: referenciaSin
                });
                this.formRef.current.setFieldsValue({'referenciaEspecificaPrimera': referenciaSin})
          }

          const buscarServicios = value => {
               if(value.length>=3){
                   if(value){
                   onListaServiciosFiltro(value)
                   } else {
                       message.warning('No se encontró servicio con esas palabras')
                   }
               } else {
               onListaServiciosFiltroLimpiar();
               } 
          }

          return (
               <Modal
               visible={showModalNuevoEstandaresDeDesempeño}
               title='Nuevo Estándar'
               onCancel={handleCancel}
               footer={false}
               width= '80%'
               >
                    
                    <Spin spinning={fetchingNuevoEstandaresDeDesempeño}>
                         <Form {...layout}
                         name="formulario"
                         onFinish={onFinish}
                         
                         ref={this.formRef}
                         >
                         <Row type="flex" justify="space-around">
                              <Col span={23}>
                                   <Form.Item
                                        label="Referencia"
                                        name="idReferenciaDesempeno"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                   >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona la referencia"
                                            loading={fetchingListaReferenciasDeEstandaresActivos}
                                            onChange={seleccionClaseReferencia}
                                        >
                                            {listaReferenciasDeEstandaresActivos.map(option => <Option key={option.idReferenciaDesempeno}>{ option.referencia + '.' + option.subnivel + ', ' + option.claseReferencia + ', ' + option.descripcion}</Option>)}
                                        </Select>
                                        
                                   </Form.Item>
                              </Col>
                              
                              <Col span={11}>
                                   <Row>
                                        <Col span={4}>
                                             <Form.Item
                                             label="Referencia"
                                             name="referenciaEspecificaPrimera"
                                             rules={[{ required: false, message: 'Favor de llenar el campo'}]}
                                             >
                                                  <Input
                                                  title="Referencia" 
                                                  disabled={true}
                                                  />
                                             </Form.Item>
                                        </Col>
                                        <Col span={20}>
                                             <Form.Item
                                             label="Específica"
                                             name="referenciaEspecifica"
                                             rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                                  <Input
                                                  pattern="^([0-9]{1,2})$"
                                                  title="Específica" 
                                                  maxLength={80} 
                                                  placeholder='Específica'
                                                  title='Sólo es permitido ingresar dos carácteres numéricos'/>
                                             </Form.Item>
                                        </Col>
                                   </Row>
                              </Col>

                              {/* {this.state.claseReferenciaSeleccionado ===  'Específica' ? (
                              <Col span={11}>
                                    <Form.Item
                                        label="Servicio"
                                        name="servicios"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select
                                            mode='multiple'
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona un servicio"
                                            loading={fetchingListaServiciosFiltro}
                                            showSearch
                                            filterOption={false}
                                            onSearch={buscarServicios}
                                            notFoundContent={null}
                                        >
                                            {listaServiciosFiltro.map(option => <Option key={option.nombre}>{option.nombre}</Option>)}
                                        </Select>
                                    </Form.Item>
                              </Col>
                              ): false} */}

                              {this.state.claseReferenciaSeleccionado ===  'Específica' ? (
                              <Col span={11}>
                                   <Form.Item     
                                        label="Sección"
                                        name="idServSeccionEspecifico"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                   >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Descripción de sección"
                                            loading={fetchingListaEstandaresDesempenoEspecificosActivos}
                                        >
                                            {listaEstandaresDesempenoEspecificosActivos.map(option => <Option key={option.idServSeccionEspecifico}>{ option.descripcion}</Option>)}
                                        </Select>
                                   </Form.Item>
                              </Col>                              
                              ): false}

                              <Col span={11}>
                                        <Form.Item
                                        label="Estándar de Desempeño"
                                        name="descripcion"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                             <TextArea
                                             pattern="^[ñíóáéú a-zA-Z ]+$"
                                             title="Descripción sin carácteres especiales" 
                                             maxLength={500} 
                                             placeholder='Descripción estándar de desempeño'
                                             autoSize />
                                        </Form.Item>
                              </Col>

                              <Col span={11}>
                                   <Form.Item
                                        label="Categoría"
                                        name="idFallaCalidad"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                   >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona la categoría de falla de calidad"
                                            loading={fetchingListaCategoriasFallasDeCalidadActivos}
                                        >
                                            {listaCategoriasFallasDeCalidadActivos.map(option => <Option key={option.idFallaCalidad}>{ option.fallaCalidad + ', ' + option.descripcion }</Option>)}
                                        </Select>
                                   </Form.Item>
                              </Col>

                              <Col span={11}>
                                   <Form.Item
                                        label="Tolerancia "
                                        name="tolerancia"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <InputNumber min={0.00}  placeholder="Ingresa el valor de tolerancia"
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                        onChange={changeDecimales}
                                        step={1.00} />
                                   </Form.Item>
                              </Col>

                              <Col span={11}>
                                   <Row>
                                        <Col span={12}>
                                             <Form.Item
                                                  label="Tiempo de Respuesta"
                                                  name="tiempoRespuesta"
                                                  rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                             >
                                                  <InputNumber min={0} onChange={changeTiempoRespuesta} placeholder="Ingresa el tiempo de respuesta" 
                                                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 
                                                  parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                                             </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                             <Form.Item
                                                  label="Unidad de medida"
                                                  name="idUnidadMedida"
                                                  rules={[{ required: this.state.banderaTiempo, message: 'Favor de llenar el campo', }]}
                                             >
                                                  <Select allowClear
                                                  style={{ width: '100%' }}
                                                  placeholder="Selecciona "
                                                  loading={fetchingUnidadesMediPorCategoria}
                                                  disabled={!this.state.banderaTiempo}
                                                  >
                                                       {unidadesMediPorCategoria.map(option => <Option key={option.idUnidadMedida}>{ option.unidadMedida}</Option>)}
                                                  </Select>
                                             </Form.Item>
                                        </Col>
                                   </Row>
                              </Col>

                              <Col span={11}>
                                   <Form.Item
                                        label="Método de Supervisión"
                                        name="idClaveMetodoSupervision"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                   >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el método de supervisión"
                                            loading={fetchingListaClavesMetodosSupervisionActivos}
                                        >
                                            {listaClavesMetodosSupervisionExternos.map(option => <Option key={option.idClaveMetodoSupervision}>{ option.claveMetodoSupervision + ', ' + option.descripcionC}</Option>)}
                                        </Select>
                                   </Form.Item>
                              </Col>
                              {listaInfoProyecto ? (     
                                   <Col span={11}>
                                        <Form.Item
                                             label="Fechas vigencia"
                                             name="rangoVigencia"
                                             rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                             <RangePicker
                                             style={{width: '100%'}} format='DD-MM-YYYY' onChange={fechas} placeholder={["Ingresa fecha inicio","Fecha fin"]}
                                             defaultValue={[, moment(listaInfoProyecto.fechaVigencia, 'DD-MM-YYYY')]}
                                             disabled={[false, true]}
                                             />
                                        </Form.Item>
                                   </Col>
                              ): false}
                              <Col span={11} className='marginBoton'>
                                   <br/>
                                   <Button className="btnFiltrar" htmlType="submit" style={{ width: '100%' }}>Crear Estándar</Button>
                              </Col>
                         </Row>
                         </Form>
                    </Spin>
                    
               </Modal>
          );
     }
}

const mapStateToProps = state => {
	return {
          fetchingNuevoEstandaresDeDesempeño: state.MecanismosDePagosReducer.fetchingNuevoEstandaresDeDesempeño,
          showModalNuevoEstandaresDeDesempeño: state.MecanismosDePagosReducer.showModalNuevoEstandaresDeDesempeño,
          fetchingListaReferenciasDeEstandaresActivos: state.MecanismosDePagosReducer.fetchingListaReferenciasDeEstandaresActivos,
          listaReferenciasDeEstandaresActivos: state.MecanismosDePagosReducer.listaReferenciasDeEstandaresActivos,
          page: state.MecanismosDePagosReducer.page,
          busqueda: state.MecanismosDePagosReducer.busqueda,
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          fetchingListaCategoriasFallasDeCalidadActivos: state.MecanismosDePagosReducer.fetchingListaCategoriasFallasDeCalidadActivos,
          listaCategoriasFallasDeCalidadActivos: state.MecanismosDePagosReducer.listaCategoriasFallasDeCalidadActivos,
          fetchingListaClavesMetodosSupervisionActivos: state.MecanismosDePagosReducer.fetchingListaClavesMetodosSupervisionActivos,
          listaClavesMetodosSupervisionActivos: state.MecanismosDePagosReducer.listaClavesMetodosSupervisionActivos,
          listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
          fetchingUnidadesMediPorCategoria: state.AlmacenesReducer.fetchingUnidadesMediPorCategoria,
          unidadesMediPorCategoria: state.AlmacenesReducer.unidadesMediPorCategoria,
          fetchingListaEstandaresDesempenoEspecificosActivos: state.MecanismosDePagosReducer.fetchingListaEstandaresDesempenoEspecificosActivos,
          listaEstandaresDesempenoEspecificosActivos: state.MecanismosDePagosReducer.listaEstandaresDesempenoEspecificosActivos,
          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
          fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
          listaEstandaresDeDesempeñoBusqueda: state.MecanismosDePagosReducer.listaEstandaresDeDesempeñoBusqueda,
          fetchingListaEstandaresDeDesempeñoBusqueda: state.MecanismosDePagosReducer.fetchingListaEstandaresDeDesempeñoBusqueda,
          listaClavesMetodosSupervisionExternos: state.MecanismosDePagosReducer.listaClavesMetodosSupervisionExternos,
	};
};

const mapDispatchToProps = dispatch => {
	return {
          onShowModalNuevoEstandaresDeDesempeño: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_ESTANDAR_DESEMPEÑO', showModalNuevoEstandaresDeDesempeño: false})
          },
          onNuevoEstandaresDeDesempeño: ( formulario, page, busqueda ) => {
               dispatch({ type: "NUEVO_ESTANDAR_DESEMPEÑO_REQUEST" , formulario: formulario, page: page, busqueda: busqueda });
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
          onListaServiciosFiltroLimpiar: () => {
               dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
          },
          onListaServiciosFiltro: (busqueda) => {
               dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevaEstandarDeDesempeño);