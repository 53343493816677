import React, { Component } from 'react';
import { Form, Input, Button, Col, Spin, Modal, InputNumber, message, DatePicker, Row } from 'antd';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import moment from 'moment';
import ModalAgregarPPL from './ModalAgregarPPL';
import { wait } from '@testing-library/react';


const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalEditarMesCalendario extends Component {
    formRef = React.createRef();
    render() {
        const { 
            fetchingEditarMesCalendario, showModalEditarMesCalendario, onEditarMesCalendario, 
            onShowModalEditarMesCalendario, limpiarFormulario, listaInfoProyecto, fechaAnterior,
            mesCalendarioEditar, busqueda,
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        
        const onFinish = formulario => {
            console.log('Success:', formulario);
            const rangeValue = formulario['fechaPubInpc'];
            formulario.fechaPubInpc = rangeValue.format('YYYY-MM-DD');
            onEditarMesCalendario(formulario, busqueda, listaInfoProyecto, mesCalendarioEditar);
        };

        console.log('limpiar formulario', limpiarFormulario);
        
        if (limpiarFormulario && limpiarFormulario === 'editarMesCalendario') {
            //this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }
            
        const handleCancel = () => {
            onShowModalEditarMesCalendario();
            // this.formRef.current.resetFields();
        }

        const fechas = (rangeValue) => {
            if(listaInfoProyecto.fechaFin){
                const diferencia = moment(rangeValue.format('YYYY-MM-DD') + ' 00:00:00').diff(moment(listaInfoProyecto.fechaFin.format('YYYY-MM-DD') + ' 00:00:00'), "days");
                console.log('diferencia', diferencia);
                if(diferencia > 0){
                    message.error('No es posible seleccionar una fecha de publicación mayor al final de contrato del proyecto', 7);
                    this.formRef.current.resetFields(['fechaPubInpc']);
                }
            }
            else{
                message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                this.formRef.current.resetFields(['fechaPubInpc']);
            }
        }
        const disabledRange = fecha => {
            return fecha && moment(fecha.format('YYYY-MM-DD')) < moment(fechaAnterior);
        }
        const agregarPPL = (value) => {
            this.props.onAgregarPPL(false, busqueda, null, 0, false);
            console.log('value en editar', value)
            let dias = new Date(value.anio, value.idMes, 0).getDate()
            value.listaDiasMes = [];
            value.listaDiasMes = Array.from({length: value.dmi}, (v, i) => dias - i);
            value.listaDiasMes.reverse();

            value.objetoFormulario = {'nuevo': true};
            let numPplMes = 0;
            let terminado = false;
            if(value.pplCalendariosByIdMesCalendario){
                value.objetoFormulario = {}
                if(value.pplCalendariosByIdMesCalendario.length === value.dmi){
                    terminado = true;
                }
                numPplMes = value.pplCalendariosByIdMesCalendario[0].numPplMes;
                for(let i in value.pplCalendariosByIdMesCalendario){
                    let idMes = 'numPpl-' + value.pplCalendariosByIdMesCalendario[i].diaMes;
                    value.objetoFormulario[idMes] = value.pplCalendariosByIdMesCalendario[i].numPpl;
                }
            }
            console.log('busqueda, value, numPplMes, terminado', busqueda, value, numPplMes, terminado)
            this.props.onAgregarPPL(true, busqueda, value, numPplMes, terminado);
        }
        return (
            <div>
                <ModalAgregarPPL/>
                <Modal
                visible={showModalEditarMesCalendario}
                title='Editar Mes Calendario'
                onCancel={handleCancel}
                footer={false}
                >
                    {listaInfoProyecto ? (
                    <Spin spinning={fetchingEditarMesCalendario}>
                        {mesCalendarioEditar ? (
                        <Form {...layout}
                            name="formulario"
                            onFinish={onFinish}
                            ref={this.formRef}
                            initialValues={mesCalendarioEditar}
                        >
                            <Row justify='space-around'>
                                <Col span={24}>
                                    <Form.Item
                                        label="INPC i-1"
                                        name="inpc"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <InputNumber min={0} placeholder="Ingresa el INPC i-1"
                                            step={1.000} 
                                        />
                                    </Form.Item>
                                </Col>
                                
                                <Col span={24}>
                                    <Form.Item
                                        label="Fecha de publicación INPC"
                                        name="fechaPubInpc"
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <DatePicker
                                            disabledDate={disabledRange} showToday={false}
                                            style={{width: '100%'}} format='DD-MM-YYYY' onChange={fechas} placeholder={"Ingresa fecha de publicación INPC"}
                                        />
                                    </Form.Item>
                                </Col>
                                
                                <Col span={24}>
                                    <Form.Item
                                        label="Fuente de publicación INPC"
                                        name="fuentePubInpc"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Input maxLength={250} placeholder="Ingresa la fuente de publicación INPC" />
                                    </Form.Item>
                                </Col>
                                
                                <Col span={11}>
                                    <Button className="btnCargaArchivos" disabled={!permisosUsuario.verSumaDePpls} style={{ width: '100%' }} onClick={() => agregarPPL(mesCalendarioEditar)}>Agregar PPL</Button>
                                </Col>
                                <Col span={11}>
                                    <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Editar Mes Calendario</Button>
                                </Col>
                            </Row>
                        </Form>
                        ):false}
                    </Spin>
                    ):<h1>Favor de crear un proyecto en el módulo de 'Información del proyecto'</h1>}
                    
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarMesCalendario: state.MecanismosDePagosReducer.fetchingEditarMesCalendario,
        showModalEditarMesCalendario: state.MecanismosDePagosReducer.showModalEditarMesCalendario,
        busqueda: state.MecanismosDePagosReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        fechaAnterior: state.MecanismosDePagosReducer.fechaAnterior,
        listaMesesCalendario: state.MecanismosDePagosReducer.listaMesesCalendario,
        mesCalendarioEditar: state.MecanismosDePagosReducer.mesCalendarioEditar,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarMesCalendario: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_MES_CALENDARIO', showModalEditarMesCalendario: false, mesCalendarioEditar: null})
        },
        onEditarMesCalendario: ( formulario, busqueda, listaInfoProyecto, mesCalendarioSeleccionado ) => {
            dispatch({ type: "EDITAR_MES_CALENDARIO_REQUEST" , formulario, busqueda, listaInfoProyecto, mesCalendarioSeleccionado });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        },
        onAgregarPPL: (showModalAgregarPPL, busqueda, mesCalendarioSeleccionado, numPplMes, terminado) => {
            dispatch({ type: 'SHOW_MODAL_AGREGAR_PPL', showModalAgregarPPL, busqueda, mesCalendarioSeleccionado, numPplMes, terminado, editando: true })
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarMesCalendario);