import React, { useEffect } from 'react';
import { Form, Input, Button, Col, Spin, Modal, Select, Row, Tooltip, message, Radio } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

const { Option } = Select;

const ModalEditarServicioVariable = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const showModalEditarServicioVariable = useSelector((state) => state.ServiciosVariablesReducer.showModalEditarServicioVariable);
    const fetchingEditarServicioVariable = useSelector((state) => state.ServiciosVariablesReducer.fetchingEditarServicioVariable);
    const fetchingListaVariablesActivas = useSelector((state) => state.ServiciosVariablesReducer.fetchingListaVariablesActivas);
    const fetchingListaServiciosActivos = useSelector((state) => state.ServiciosVariablesReducer.fetchingListaServiciosActivos);
    const listaServiciosActivos = useSelector((state) =>  state.ServiciosVariablesReducer.listaServiciosActivos);
    const listaVariablesActivas = useSelector((state) =>  state.ServiciosVariablesReducer.listaVariablesActivas);
    const servicioVariableSeleccionado =  useSelector((state) =>  state.ServiciosVariablesReducer.servicioVariableSeleccionado);
    const busqueda =  useSelector((state) => state.ServiciosVariablesReducer.busqueda);
    const page = useSelector((state) => state.ServiciosVariablesReducer.page);

    const handleCancel = () => {
        dispatch({ type: "SHOW_MODAL_EDITAR_SERVICIO_VARIABLE", showModalEditarServicioVariable: false, servicioVariableSeleccionado:[]})
        form.resetFields();
    };

    useEffect(() => {
        dispatch({ type: "OBTENER_VARIABLES_ACTIVAS_REQUEST"});
        dispatch({ type: "LISTA_SERVICIOS_ACTIVOS_REQUEST"});
        if(servicioVariableSeleccionado){
            form.setFieldsValue(servicioVariableSeleccionado);
        }
    }, [form, servicioVariableSeleccionado]);    

    const onFinish = formulario => {
        console.log('formulario:', formulario);
        if(!formulario.listaVariables[0]){
                message.error('Debe de ingresarse al menos una variable');
        }
        else{
           console.log("formulario",formulario)
        dispatch({ 
            type: "EDITAR_SERVICIO_VARIABLE_REQUEST",
            formulario, 
            idServicioVariable: servicioVariableSeleccionado.idServicioVariable,
            idServicio: servicioVariableSeleccionado.idServicio,
            listaVariablesActivas,
            page,
            busqueda
        })
      }
    }  

    console.log("servicioVariableSeleccionado",servicioVariableSeleccionado)

    const layout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    return(
        <div>
        {servicioVariableSeleccionado &&
            <Modal
                visible={showModalEditarServicioVariable}
                title='Nuevo Servicio Variable'
                onCancel={handleCancel}
                destroyOnClose
                footer={false}
                width='40%'
            >
                <Spin spinning={fetchingEditarServicioVariable}>
                    <Form 
                        {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        initialValues={servicioVariableSeleccionado}
                        preserve={false}
                        form={form} 
                    >
                       <Row type='flex'>
                            <Col span={24}>
                                <Form.Item
                                    label="Servicio"
                                    name="nombre"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el servicio"
                                        loading={fetchingListaServiciosActivos}
                                    >
                                        {listaServiciosActivos.map(option => <Option key={option.idServicio}>{option.nombre}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Descripción"
                                    name="descripcion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Input
                                        pattern="^^[ñíóáéú a-zA-Z]+$"
                                        title="Debe contener letras ."
                                        maxLength={100} placeholder='Ingrese la descripción' />
                                </Form.Item>
                            </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Nombre Variable"
                                        name="listaVariables"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            mode='multiple'
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el servicio"
                                            loading={fetchingListaVariablesActivas}
                                        >
                                            {listaVariablesActivas.map(option => <Option key={option.idVariable}>{option.variable}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Button className="btnFiltrar" htmlType="submit">Editar Servicio Variable</Button>
                                </Col>
                            </Row>
                    </Form>
                </Spin>
            </Modal>
        }
        </div>
    )
}



export default ModalEditarServicioVariable;