import React from 'react';
import {Form, Spin, Row, Col, Typography, Button, Tooltip, message} from 'antd';
import {connect} from "react-redux";
import '../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-classic-base64-upload/build/ckeditor';
import { SaveOutlined } from '@ant-design/icons';

const { Text } = Typography;

ClassicEditor.defaultConfig = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'insertTable',
        '|',
        'imageUpload',
        '|',
        'undo',
        'redo'
      ]
    },
    image: {
      toolbar: [
        'imageStyle:full',
        'imageStyle:side',
        '|',
        'imageTextAlternative'
      ]
    },
    table: {
      contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
    },
    language: 'es'
  };

class InformacionDeTerreno extends React.Component {
    formRef = React.createRef();

    componentDidMount() {
        this.props.onObtenerArchivoMesActual(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, 'informacion_de_terreno_' + this.props.mesSeleccionadoRMDP + '_' + this.props.anioSeleccionadoRMDP + '.txt'); 
        this.props.onObtenerArchivoMesAnterior( this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP);
    }

    state = {
        data: this.props.archivoMesActualTerreno ? this.props.archivoMesActualTerreno : this.props.archivoMesAnteriorTerreno ? this.props.archivoMesAnteriorTerreno : ''
    }

    render() {
        const {
            anioSeleccionadoRMDP,
            mesSeleccionadoRMDP,
            onCrearInformacionDeTerreno,
            onEditarInformacionDeTerreno,
            mesTexto,
            fetchingObtenerArchivoMesAnteriorTerreno,
            archivoMesAnteriorTerreno,
            fetchingGetRMDP,
            RMDPObject, archivoMesActualTerreno
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        let baseData = `
            <h1>1.1 Información General del Terreno</h1>
            <br />
            <h2>1.1.1 Topografía</h2>
            <br />
            <h2>1.1.2 Visibilidad</h2>
            <br />
            <h2>1.1.3 Hidrología</h2>
            <br />
            <h2>1.1.4 Geología</h2>
            <br />
            <h2>1.1.5 Proporciones y Dimensiones</h2>
            <br />
            <h2>1.1.6 Riesgos y Vulnerabilidades</h2>
            <br />
            <h2>1.1.7 Reservas Ecológicas y Arqueológicas</h2>
            <br />
            <h2>1.1.8 Asentamientos Humanos</h2>
            <br />
            <h2>1.1.9 Servicios Eléctricos</h2>
            <br />
            <h2>1.1.10 Servicios Sanitarios</h2>
            <br />
            <h2>1.1.11 Servicios de Comunicación Vial</h2>
            <br />
            <h2>1.1.12 Servicios de Telecomunicaciones</h2>
            <br />
            <h2>1.1.13 Otros Servicios Públicos</h2>
        `

        const onGuardarInformacionTerreno = () => {
            console.log('baseData', baseData)
            console.log("RMDPObject", RMDPObject);
            console.log('this.state.data', this.state.data)
            if (this.state.data.length > 0) {
                if (RMDPObject && RMDPObject.informacionTerrenoByIdRmdp) {
                    onEditarInformacionDeTerreno(mesSeleccionadoRMDP, anioSeleccionadoRMDP, this.state.data, RMDPObject);
                } else {
                    onCrearInformacionDeTerreno(mesSeleccionadoRMDP, anioSeleccionadoRMDP, this.state.data, RMDPObject);
                }
            }
        };

        archivoMesActualTerreno ? console.log('Primer caso') : archivoMesAnteriorTerreno ? console.log('Segundo caso') : console.log('Tercer caso')

        console.log('archivoMesActualTerreno', archivoMesActualTerreno);
        console.log('archivoMesAnteriorTerreno', archivoMesAnteriorTerreno)

        return (<Spin spinning={this.props.fetchingEditarInformacionTerreno || this.props.fetchingCrearInformacionTerreno || this.props.fetchingObtenerArchivoMesAnteriorTerreno || this.props.fetchingObtenerArchivoMesActualTerreno}>
            <Row justify='end'>
                <Col span={4} style={{backgroundColor: '#737373', borderTopLeftRadius: '1em', borderTopRightRadius: '1em', textAlign:'center'}}>
                    <Text style={{ fontSize: 16, color: "white"}} strong> {anioSeleccionadoRMDP} - {mesTexto} </Text>
                </Col>
            </Row>

                <CKEditor
                    editor={ClassicEditor}
                    data={archivoMesActualTerreno ? archivoMesActualTerreno : archivoMesAnteriorTerreno ? archivoMesAnteriorTerreno : baseData}
                    onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        this.setState({ data });
                        console.log({event, editor, data});
                    }}
                    onBlur={(event, editor) => {
                        console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                        console.log('Focus.', editor);
                    }}
                />

            <Row justify='end' style={{padding:'1em'}}>
                {/* <Col span={11} className='marginBoton'>
                    <Button className="btnFiltrar" style={{width: '100%'}}
                            onClick={onGuardarInformacionTerreno}>Guardar Cambios</Button>
                </Col> */}
                <Col span={1}>
                    <Tooltip title='Guardar Cambios'>
                        <Button className="iconTer" shape="circle" icon={<SaveOutlined />} onClick={onGuardarInformacionTerreno} loading={ RMDPObject && RMDPObject.informacionTerrenoByIdRmdp ?  this.props.fetchingEditarInformacionTerreno: this.props.fetchingCrearInformacionTerreno}/>
                    </Tooltip>
                </Col>
            </Row>
        </Spin>);
    }
}

const mapStateToProps = state => {
    return {
        anioSeleccionadoRMDP: state.RMDPReducer.anioSeleccionadoRMDP,
        mesSeleccionadoRMDP: state.RMDPReducer.mesSeleccionadoRMDP,
        mesTexto: state.RMDPReducer.mesTexto,
        fetchingObtenerArchivoMesAnteriorTerreno: state.RMDPReducer.fetchingObtenerArchivoMesAnteriorTerreno,
        archivoMesAnteriorTerreno: state.RMDPReducer.archivoMesAnteriorTerreno,
        RMDPObject: state.RMDPReducer.RMDPObject,

        archivoMesActualTerreno: state.RMDPReducer.archivoMesActualTerreno,
        fetchingCrearInformacionTerreno: state.RMDPReducer.fetchingCrearInformacionTerreno,
        fetchingEditarInformacionTerreno: state.RMDPReducer.fetchingEditarInformacionTerreno,
        fetchingObtenerArchivoMesActualTerreno: state.RMDPReducer.fetchingObtenerArchivoMesActualTerreno
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditorDeTexto: () => {
            dispatch({type: 'MODAL_EDITOR_TEXTO', showModalEditorTexto: true, dataEditorDeTexto: null})
        },
        onCrearInformacionDeTerreno: (mes, anio, fileData, RMDPObject) => {
            dispatch({type: 'CREAR_INFORMACION_TERRENO_REQUEST', mes, anio, fileData, RMDPObject})
        },
        onEditarInformacionDeTerreno: (mes, anio, fileData, RMDPObject) => {
            dispatch({type: 'EDITAR_INFORMACION_TERRENO_REQUEST', mes, anio, fileData, RMDPObject})
        },
        onObtenerArchivoMesAnterior: (mes, anio) => {
            dispatch({type: 'OBTENER_ARCHIVO_MES_ANTERIOR_TERRENO_REQUEST', mes, anio})
        },
        onObtenerArchivoMesActual:(mes, anio, nombreArchivo) => {
            dispatch({ type: 'OBTENER_ARCHIVO_MES_ACTUAL_TERRENO_REQUEST', mes, anio, nombreArchivo });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(InformacionDeTerreno);
