import React, {Component} from 'react';
import {Row, Col, Modal, List, Table, Card } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';


class ModalDetalleSeguimientoFolioSS extends Component {

    render() {

        const { showModalDetalleFolioSS, onCerrarModal, folioSolicitudServicioSeleccionado2,
            listaTrazabilidad, fetchingListaTrazabilidad, listaTrazabilidadCantidad, onGetTrazabilidadFolio
        } = this.props;

        let data = []
        if (folioSolicitudServicioSeleccionado2) {
            const { servicio, descripcionSolicitud, fechaCompromiso, tipoReporte, areaDetalle, unidadDetalle, usuarioReporta, interiorDetalle } = folioSolicitudServicioSeleccionado2;

            data = [
                { title: "Servicio", data: servicio },
                { title: "Descripción de Solicitud de Servicio", data: descripcionSolicitud },
                { title: "Fecha Compromiso", data: fechaCompromiso.split('T')[0].split('-').reverse().join('-') + ' ' +  fechaCompromiso.split('T')[1].split(':')[0] + ':' + fechaCompromiso.split('T')[1].split(':')[1] },
                { title: "Tipo de Reporte", data: tipoReporte },
                { title: "Area Funcional", data: areaDetalle },
                { title: "Unidad Funcional", data: unidadDetalle },
                { title: "Interior Funcional", data: interiorDetalle },
                { title: "Usuario que Reporta", data: usuarioReporta }]
        }

        const handleTableChange = (pagination) => {
            onGetTrazabilidadFolio(folioSolicitudServicioSeleccionado2.folio, pagination.current);
        }

        const handleCancel = () => {
            onCerrarModal();
        };

        const columns = [{
            title: 'Fecha de Estado',
            dataIndex: 'fechaEstado',
            key: 'fechaEstado',
            align: 'center'
        },{
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            align: 'center'
        },{
            title: 'Descripcion',
            dataIndex: 'descripcion',
            key: 'descripcion',
            align: 'center'
        },{
            title: 'Fecha de Actualización',
            dataIndex: 'fechaActualizacion',
            key: 'fechaActualizacion',
            align: 'center'
        },{
            title: 'Usuario que registra',
            dataIndex: 'username',
            key: 'username',
            align: 'center'
        }];

        const scroll = {"x": 700 | true, "y": 1000}; 
        return (
            <div>
            {folioSolicitudServicioSeleccionado2 ? (
            <Modal
                visible={showModalDetalleFolioSS}
                title={'Detalle de la Solicitud de Servicio con Folio: ' + folioSolicitudServicioSeleccionado2.folio }
                onCancel={handleCancel}
                footer={false}
                width='90%'
            >
                {folioSolicitudServicioSeleccionado2 ? (
                    <>
                    <Row type="flex" justify='space-between'>
                        <Col span={11}>
                            <List
                                className="listaCatalogos"
                                bordered
                                dataSource={data}
                                renderItem={item => (
                                   <List.Item className='listaHover' className='listaHover'>
                                        <Col span={11}>
                                            <strong>{item.title}</strong>
                                        </Col>
                                        <Col span={11}>
                                            {item.data !== null ? item.data : '-'}
                                        </Col>
                                    </List.Item>
                                )}
                            />
                        </Col>
                        <br />
                        <Col span={11}>
                          <Card className="cardCatalogos" title={<div className='headerLista'>Trazabilidad</div>}>
                             <br />
                                <Table
                                    size="small"
                                    rowKey="idTrazabilidadFolio"
                                    columns={columns}
                                    dataSource={listaTrazabilidad}
                                    loading={fetchingListaTrazabilidad}
                                    onChange={handleTableChange}
                                    locale={{ emptyText: 'Sin datos' }}
                                    pagination={{ total: listaTrazabilidadCantidad, simple: true }}
                                    scroll={scroll}
                                />
                        </Card>
                     </Col>
                    </Row>
                    
                    </>
                ) : false}

            </Modal>
            ) : false}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showModalDetalleFolioSS: state.SolicitudDeServiciosReducer.showModalDetalleFolioSS,
        folioSolicitudServicioSeleccionado2: state.SolicitudDeServiciosReducer.folioSolicitudServicioSeleccionado2,
        listaTrazabilidad: state.SolicitudDeServiciosReducer.listaTrazabilidad,
        fetchingListaTrazabilidad: state.SolicitudDeServiciosReducer.fetchingListaTrazabilidad,
        listaTrazabilidadCantidad: state.SolicitudDeServiciosReducer.listaTrazabilidadCantidad,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onCerrarModal: () => {
            dispatch({ type: "SHOW_MODAL_VER_DETALLE_FOLIO_SS", showModalDetalleFolioSS: false, folioSolicitudServicioSeleccionado2: null})
        },
        onGetTrazabilidadFolio: (folio, page) => {
            dispatch({ type: "OBTENER_TRAZABILIDAD_FOLIO_REQUEST", folio, page});
        },
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ModalDetalleSeguimientoFolioSS);