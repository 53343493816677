import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import { Form, Input, Button, Col, Spin, Row, Select, Divider, DatePicker, InputNumber, message } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { RangePicker } = DatePicker;
const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const EditarCategoria = props => {
    let formRef = React.createRef();
    const [state, setState] = useState ({tipoEdicion: null, disabledSelect: false});

    const {fetchingEditarNivelTiempoRespuesta, page,busqueda, limpiarFormulario, listaInfoProyecto, fetchingListaInfoProyecto,
        nivelTiempoRespuestaSeleccionado, listaEstandaresDesempenoEspecificosActivos,onEditarNivelTiempoR, onLimpiarFormulario, onCambiarVista
    } = props;

    useEffect (() => {
        onLimpiarFormulario();
        onCambiarVista(1);
        if(limpiarFormulario === 'ENTRS') {
            formRef.current.resetFields();
        }
    }, [limpiarFormulario])

    const onSelectTipoEdicion = value => {
        setState({tipoEdicion: value,disabledSelect: true})
    }
    
    const disabledRange = fecha => {
        return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicio;
    }

    const fechas = (rangeValue) => {
        if(listaInfoProyecto.fechaInicio){
            if (rangeValue[0]) {
                const fechaInicioAnterior = nivelTiempoRespuestaSeleccionado.fechaInicioVigencia.split('T')[0];
                const diferenciaAnterior = moment(fechaInicioAnterior, 'YYYY-MM-DD').diff(moment(rangeValue[0].format('YYYY-MM-DD')), "days");
                const nuevoNivel = nivelTiempoRespuestaSeleccionado.tiempoRespuesta === formRef.current.getFieldValue(['tiempoRespuesta']);
                const nuevoUnidadM = nivelTiempoRespuestaSeleccionado.unidadMedida === formRef.current.getFieldValue(['unidadMedida']);

                if (!nuevoNivel || !nuevoUnidadM) {
                    if (diferenciaAnterior > -1) {
                        message.error('No es posible seleccionar una fecha de inicio menor ni igual a la fecha de inicio anterior (' + fechaInicioAnterior.split('-').reverse().join('-') + ')', 7);
                        formRef.current.setFieldsValue({ 'rangoVigencia': [null, nivelTiempoRespuestaSeleccionado.rangoVigencia[1]] });
                    }
                }
            }
        }
        else{
            message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
            formRef.current.setFieldsValue({'rangoVigencia': [null, nivelTiempoRespuestaSeleccionado.rangoVigencia[1]]});
        }
    };


    const handleFecha = () => {
        const tiempoChange = nivelTiempoRespuestaSeleccionado.tiempoRespuesta === formRef.current.getFieldValue(['tiempoRespuesta']);
        const unidadMedidaChange = nivelTiempoRespuestaSeleccionado.unidadMedida === formRef.current.getFieldValue(['unidadMedida']);
        if(state.tipoEdicion === 'completa'){
            if (!tiempoChange || !unidadMedidaChange) {
                message.warning('Por motivos de seguridad, favor de seleccionar de nuevo la fecha de inicio de vigencia', 7);
                formRef.current.setFieldsValue({'rangoVigencia': [null, nivelTiempoRespuestaSeleccionado.rangoVigencia[1]]});
            }
        }
    }

    const onFinish = formulario => {
        console.log(formulario)
        const tiempoChange = nivelTiempoRespuestaSeleccionado.tiempoRespuesta === formRef.current.getFieldValue(['tiempoRespuesta']);
        const unidadMedidaChange = nivelTiempoRespuestaSeleccionado.unidadMedida === formRef.current.getFieldValue(['unidadMedida']);

        if(state.tipoEdicion === 'simple') {
            return onEditarNivelTiempoR(formulario, page, busqueda,nivelTiempoRespuestaSeleccionado);
        }
        if(state.tipoEdicion === 'completa' && (!tiempoChange || !unidadMedidaChange)) {
            return onCambiarVista(2, formulario);
        }
        onEditarNivelTiempoR(formulario, page, busqueda, nivelTiempoRespuestaSeleccionado);
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed: ', errorInfo);

    }

    return(
        <>
        {nivelTiempoRespuestaSeleccionado ? (
            <Spin spinning={fetchingEditarNivelTiempoRespuesta}>
            <Form {...layout}
                name='formulario'
                onFinish={onFinish}
                
                initialValues={nivelTiempoRespuestaSeleccionado}
                ref={formRef}
            >
                <Row justify='space-around'>
                    <Col span={9}>
                        <p>Selecciona el tipo de edición</p>
                    </Col>
                    <Col span={13}>
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Selecciona el tipo de edición"
                            onChange={onSelectTipoEdicion}
                            disabled={state.disabledSelect}
                        >
                            <Option key='simple'>Edición simple</Option>
                            <Option key='completa'>Edición por convenio</Option>
                        </Select>
                    </Col>
                </Row>
                <Divider />
                {state.tipoEdicion ? (
                <Row type='flex' justify='space-between'>
                    <Col span={11}>
                        <Form.Item
                            label='Sección'
                            name='seccion'
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Select
                                allowClear
                                style={{ width: '100%' }} placeholder="Selecciona una sección"
                            >
                                {listaEstandaresDesempenoEspecificosActivos.map(item => (<Option key={item.idServSeccionEspecifico}>{item.descripcion}</Option>))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label='Categoría'
                            name='categoria'
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Input
                                pattern="^[ñíóáéúÁÉÍÓÚÑ a-zA-Z0-9,. ]+$"
                                title="Categoría debe tener letras o números."
                                maxLength={300} placeholder='Categoría' 
                            />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label='Tiempo respuesta'
                            name='tiempoRespuesta'
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <InputNumber 
                                min={1} max={1000} placeholder='Tiempo respuesta'
                                title="Número debe ser entero" pattern="^[0-9]+$"
                                onChange={handleFecha} 
                            />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label='Unidad de medida'
                            name='unidadMedida'
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Select
                                allowClear
                                style={{ width: '100%' }} placeholder="Selecciona unidad de medida"
                                onChange={handleFecha}
                            >
                                <Option key={4}>{'Minutos'}</Option>
                                <Option key={1}>{'Horas'}</Option>
                                <Option key={2}>{'Días'}</Option>
                                <Option key={3}>{'Años'}</Option>   
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Fechas vigencias"
                            name="rangoVigencia"
                            rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                        >
                            <RangePicker
                                format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio", "Fecha fin"]}
                                disabledDate={disabledRange}
                                loading={fetchingListaInfoProyecto}
                                defaultPickerValue={[nivelTiempoRespuestaSeleccionado.rangoVigencia[0], nivelTiempoRespuestaSeleccionado.rangoVigencia[1]]}
                                disabled={[false, true]}
                                onChange={fechas}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Button className="btnFiltrar" style={{ width: '100%', marginTop: '40px' }} htmlType="submit">Editar Nivel Tiempo Respuesta</Button>
                    </Col>
                </Row>
                ): false}
            </Form>
        </Spin>
        ): false}
        </>
    )
}

const mapStateToProps = state => {
    return {
        fetchingEditarNivelTiempoRespuesta: state.MecanismosDePagosReducer.fetchingEditarNivelTiempoRespuesta,
        page: state.MecanismosDePagosReducer.page,
        busqueda: state.MecanismosDePagosReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        fetchingListaInfoProyecto: state.InformacionDelProyectoReducer.fetchingListaInfoProyecto,
        nivelTiempoRespuestaSeleccionado: state.MecanismosDePagosReducer.nivelTiempoRespuestaSeleccionado,
        listaEstandaresDesempenoEspecificosActivos: state.MecanismosDePagosReducer.listaEstandaresDesempenoEspecificosActivos,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onEditarNivelTiempoR: (formularioNivelTiempoRespuesta, page, busqueda, nivelTiempoRespuestaSeleccionado) => {
            dispatch( { type: 'EDITAR_NIVEL_TIEMPO_RESPUESTA_REQUEST', formularioNivelTiempoRespuesta, page, busqueda, nivelTiempoRespuestaSeleccionado });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        },
        onCambiarVista: (vistaModalNivelTiempoRespuesta, formularioNivelTiempoRespuesta) => {
            dispatch({ type: 'CAMBIAR_VISTA_NIVEL_TIEMPO_RESPUESTA', vistaModalNivelTiempoRespuesta, formularioNivelTiempoRespuesta})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (EditarCategoria);