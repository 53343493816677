import React, { Component } from 'react';
import { Row, Col, Card, Input, Button, Radio, Form, Spin , Select, Divider, DatePicker, Tooltip, message, Upload, Typography} from 'antd';
import { UploadOutlined, SearchOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import moment from 'moment';

import imaSearch from '../../../assets/undraw_file_searching_re_3evy.svg';
import ReactVivus from "react-vivus";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const {Search} = Input;
const {Option} = Select;
const {TextArea} = Input;
const {Text} = Typography;

class ConciliacionContainer extends Component {
    formRef = React.createRef();
    formRefEdit = React.createRef();
    componentDidMount(){
        this.props.onGetListaTiposFallasActivas();
        this.props.onGetNivelSeveridad();
        this.props.onGetListaMesesAbiertos();
        this.props.onMesesProcesadosAnioActual();
    }
    state = {
        busqueda: '',
        idTipoFalla: null,
        filePDF: false,
        botonPDF: false,
        habilitarSeveridad: false,
        fechaInicio: ''
    }
    componentDidUpdate(prevProps) {
        if(prevProps.busquedaTicketSS !== this.props.busquedaTicketSS) {
            if(this.formRef.current) {
                this.formRef.current.setFieldsValue({
                    'folio': this.props.busquedaTicketSS[0].folio, 
                    'tipoFalla': this.props.busquedaTicketSS[0].tipoFalla,
                    'nombreFalla': this.props.busquedaTicketSS[0].nombreFalla,
                    'descripcionSolicitud': this.props.busquedaTicketSS[0].descripcionSolicitud,
                    'nivelSeveridad': this.props.busquedaTicketSS[0].nivelSeveridad,
                    'clasificacionTicket': this.props.busquedaTicketSS[0].clasificacionTicket,
                    'fechaCierre': this.props.busquedaTicketSS[0].fechaCierre,
                    'fechaConciliacion': this.props.busquedaTicketSS[0].fechaConciliacion,
                });

                this.formRefEdit.current.setFieldsValue({
                    'tipoFalla': this.props.busquedaTicketSSobj.tipoFalla, 
                    'nombreFalla': this.props.busquedaTicketSSobj.nombreFalla,
                    'descripcionSolicitud': this.props.busquedaTicketSSobj.descripcionSolicitud,
                    'nivelSeveridad': this.props.busquedaTicketSSobj.nivelSeveridad,
                    'clasificacionTicket': this.props.busquedaTicketSSobj.clasificacionTicket,
                    'fechaCierreEditar': this.props.busquedaTicketSSobj.fechaCierreEditar,
                    'fechaConciliacionEditar': this.props.busquedaTicketSSobj.fechaConciliacionEditar,
                });
            }
        }

    }
    render() { 

        const {
            onGetTicketSS,
            busquedaTicketSS,
            fetchingBusquedaTicketSS,
            fetchingListaTiposFallasActivas,
            listaTiposFallasActivas,
            onFallasPorServicioLimpiar,
            listaNivelesSeveridadPorFalla,
            fetchingNivelesSeveridadPorFalla,
            onGetNivelSeveridad,
            busquedaTicketSSobj,
            onCrearConciliacion,
            seccionesPorServicio,
            fetchingFallasPorServicio,
            fallasPorServicio,
            onFallasPorServicio,
            onSeccionesPorServicio,

            habilitarSeveridad,
            nivelSeveridad,
        } = this.props;

        let fecha = new Date();
          let anno = fecha.getFullYear();
          let annoSt = anno.toLocaleString('en-US');
          const addOn = 'SS' + annoSt.substr(-2) + '-';

        const onFinish = formulario => {
            console.log('Success:', formulario);
            // const fechaAltaFormato = (busquedaTicketSSobj.fechaAltaFormato._i).split(' ')[0];
            // let fechaInicio = moment(`${fechaAltaFormato}`, "YYYY-MM-DD");

            // if( moment(formulario.fechaConciliacionEditar.format('YYYY-MM-DD')) < fechaInicio){
            //     message.warning('La fecha conciliada no puede ser menor a la fecha alta del ticket: ' + moment(fechaInicio.format('DD-MM-YYYY')))
            // } else {
                onCrearConciliacion(formulario, busquedaTicketSSobj);
                this.formRefEdit.current.resetFields();
            // }
        };


        const onSearch = (value) => {
            console.log(value);
            this.setState({
                busqueda: addOn + value,
            });
            onGetTicketSS(addOn + value);
        }; 


        const buscarFalla = (value) => {
            if(value.length>=3){
                console.log(value)
                onFallasPorServicio(busquedaTicketSSobj.idServicio, value);
            } else {
                onFallasPorServicioLimpiar();
            }
        };

        const onChangeFalla = (fallaSeleccionada) => {
            console.log(`fallaSeleccionada.split(': ')[1]`, fallaSeleccionada.split(': ')[1]);
            let busquedaTicketSSobjLocal = busquedaTicketSSobj;
            if(parseInt(fallaSeleccionada.split(': ')[1]) === 1) {
                this.props.onActualizarTicket(busquedaTicketSS, null);
                busquedaTicketSSobjLocal.habilitarSeveridad = true;
                this.props.onActualizarTicket(busquedaTicketSS, busquedaTicketSSobjLocal);
            } else {
                this.props.onActualizarTicket(busquedaTicketSS, null);
                busquedaTicketSSobjLocal.habilitarSeveridad = false;
                this.props.onActualizarTicket(busquedaTicketSS, busquedaTicketSSobjLocal);
            }   
        }

        console.log('busquedaTicketSSobj', busquedaTicketSSobj)

        const onChangeTipoFalla = (value) => {
            console.log(value.split(' - ')[0])
            if(value) {
                let idTipo = parseInt(value);
                let busquedaTicketSSobjLocal = busquedaTicketSSobj;
                if(idTipo === 1) {
                    this.props.onActualizarTicket(busquedaTicketSS, null)
                    busquedaTicketSSobjLocal.fallaCalidad = true
                    this.props.onActualizarTicket(busquedaTicketSS, busquedaTicketSSobjLocal)
                } else {
                    this.props.onActualizarTicket(busquedaTicketSS, null)
                    busquedaTicketSSobjLocal.fallaCalidad = false
                    this.props.onActualizarTicket(busquedaTicketSS, busquedaTicketSSobjLocal)
                }
                
            }
        }

        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                })
            }
            var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
            if (info.file.status === 'done') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRefEdit.current.resetFields(['pdf']);
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRefEdit.current.resetFields(['pdf']);
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }
        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };

        const onReiniciarValores = () => {
            this.formRefEdit.current.setFieldsValue({
                'tipoFalla': busquedaTicketSSobj.tipoFalla, 
                'nombreFalla': busquedaTicketSSobj.nombreFalla,
                'descripcionSolicitud': busquedaTicketSSobj.descripcionSolicitud,
                'nivelSeveridad': busquedaTicketSSobj.nivelSeveridad,
                'clasificacionTicket': busquedaTicketSSobj.clasificacionTicket,
                'fechaCierreEditar': busquedaTicketSSobj.fechaCierreEditar,
                'fechaConciliacionEditar': busquedaTicketSSobj.fechaConciliacionEditar,
            });

        }


        const disabledRange = fecha => {
            const fechaAltaFormato = (busquedaTicketSSobj.fechaAltaFormato._i).split(' ')[0];
            let fechaInicio = {};
            const mesAlta = parseInt(fechaAltaFormato.split('-')[1]);
            let mesAltaProcesado = false;

            //validar que el mes en el que se dio de alta ya fue procesado
            for(let i in this.props.listaMesesProcesados){
                let mes = parseInt(this.props.listaMesesProcesados[i].idMes);
                if(mesAlta === mes){
                    mesAltaProcesado = true;
                }
            }

            let fechaFin = {};
            if(mesAltaProcesado){
                if(this.props.listaMesesProcesados[0]){
                    let anio = parseInt(this.props.listaMesesProcesados[0].anio);
                    let mes = parseInt(this.props.listaMesesProcesados[0].idMes);
                    mes = mes + 1;
                    let dias = 1;
                    if(mes <10){mes = '0' + mes}

                    let fechaInicioFormato = anio + '-' + mes + '-' + dias;
                    fechaInicio = moment(`${fechaInicioFormato}`, "YYYY-MM-DD");
                }

                fechaFin = new Date()
            } else {
                fechaInicio = moment(`${fechaAltaFormato}`, "YYYY-MM-DD");

                fechaFin = new Date()
            }

            return moment(fecha.format('YYYY-MM-DD')) < fechaInicio || moment(fecha.format('YYYY-MM-DD')) > fechaFin;
        }

        function range(start, end) {
            const result = [];
            for (let i = start; i < end; i++) {
              result.push(i);
            }
            return result;
        }

        const disabledTime = (time) => {
            if(time){
                // fecha inicial.

                let timeLocal = moment(busquedaTicketSSobj.fechaAltaFormato.format('HH:mm'));
                let hour = null
                let minute = null
                if(timeLocal._i){
                    hour = parseInt(timeLocal._i.split(':')[0]);
                    minute = parseInt(timeLocal._i.split(':')[1]);
                    minute = minute + 1;
                }

                if (time && time.format("YYYY-MM-DD") === busquedaTicketSSobj.fechaAltaFormato.format("YYYY-MM-DD")) {
                    if(time.format("HH") === busquedaTicketSSobj.fechaAltaFormato.format("HH")){
                        return {
                            disabledHours: () => range(0, hour),
                            disabledMinutes: () => range(0, minute)
                        };
                    }

                    return {
                        disabledHours: () => range(0, hour),
                    };
                }
            }
        }

        return (
            <Row justify='space-around' gutter={[8,8]}>
                <Col span={12}>
                    <Card className="cardCatalogos" title={<div className='headerLista'>Conciliación</div>} >
                        <Spin spinning={fetchingBusquedaTicketSS}>
                            <Search addonBefore={addOn} placeholder="No. Ticket" allowClear onSearch={onSearch} style={{ width: '100%' }} enterButton={<SearchOutlined />}/>

                            <Divider/>

                            {Object.entries(busquedaTicketSSobj).length > 0 ? (

                            <Form {...layout}
                                name="formulario"
                                onFinish={onFinish}
                                initialValues={busquedaTicketSSobj}
                                ref={this.formRefEdit}
                            >
                                <Col span={24}>
                                    <Form.Item 
                                        label="Tipo de Falla"
                                        name='tipoFalla'
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                        <Select
                                            style={{ width: '100%' }} 
                                            placeholder="Selecciona el tipo de falla" 
                                            loading={fetchingListaTiposFallasActivas}   
                                            showSearch 
                                            allowClear
                                            onChange={onChangeTipoFalla}
                                        >
                                            {listaTiposFallasActivas.map(option => 
                                            <Option key={option.idTipoFalla + ' - ' + option.nombre}>
                                                <Tooltip placement="topLeft" title={option.idTipoFalla + ' - ' + option.nombre}>
                                                        {option.idTipoFalla + ' - ' + option.nombre}
                                                </Tooltip>
                                            </Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            
                            
                                <Col span={24}>
                                    <Form.Item
                                        label="Falla"
                                        name="nombreFalla"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                        <Select 
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona la falla"
                                            loading={fetchingFallasPorServicio}
                                            onSearch={buscarFalla}
                                            notFoundContent={null}
                                            filterOption={false}
                                            onChange={onChangeFalla}
                                            
                                        >
                                            {fallasPorServicio.map(option => 
                                            <Option key={option.idFalla + '-' + option.nombre + ': ' + option.estandarByIdEstandar.idServSeccionEspecifico}>
                                                <Tooltip placement="topLeft" title={option.idFalla + '-' + option.nombre + ': ' + option.estandarByIdEstandar.idServSeccionEspecifico}>
                                                    {option.idFalla + '-' +option.nombre + ': ' + option.estandarByIdEstandar.idServSeccionEspecifico}
                                                </Tooltip>
                                            </Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="Descripcion de Falla"
                                        name="descripcionSolicitud"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <TextArea rows={3} disabled={true}/>
                                    </Form.Item>
                                </Col>

                                {console.log('fallaCalidad ternaria', busquedaTicketSSobj.fallaCalidad, busquedaTicketSSobj.habilitarSeveridad)}
                                { busquedaTicketSSobj.fallaCalidad ? (busquedaTicketSSobj.habilitarSeveridad ? (
                                <Col span={24}>
                                    <Form.Item 
                                        label="Nivel de Severidad"
                                        name='nivelSeveridad'
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                        <Select
                                            style={{ width: '100%' }} 
                                            placeholder="Selecciona el nivel de severidad" 
                                            loading={fetchingNivelesSeveridadPorFalla}  
                                            showSearch 
                                            allowClear
                                        >
                                            {listaNivelesSeveridadPorFalla.map(option => 
                                            <Option key={option.idNivelSeveridad}>
                                                <Tooltip placement="topLeft" title={option.idNivelSeveridad + '-' +option.descripcion}>
                                                        {option.idNivelSeveridad + ' - ' + option.descripcion}
                                                </Tooltip>
                                            </Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                
                                ): false  ): false}
                                

                                <Form.Item
                                    label="Clasificacion del Ticket"
                                    name="clasificacionTicket"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Radio.Group style={{ width: '100%' }} >
                                        <Radio value='Solicitud de Servicio'>Solicitud de Servicio</Radio>
                                        <Radio value='SNP'>SNP</Radio>
                                        <Radio value='Queja'>Queja</Radio>
                                    </Radio.Group>
                                </Form.Item> 
                                        
                            
                                <Col span={24}>                   
                                    <Form.Item
                                        label="Fecha de Cierre"
                                        name="fechaCierreEditar"
                                    >
                                        <DatePicker 
                                            disabled
                                            style={{ width: '100%' }} 
                                            format='DD-MM-YYYY HH:mm' 
                                            showTime={true}
                                            />      
                                    </Form.Item> 
                                </Col>
                        
                                <Col span={24}>
                                    <Form.Item
                                        label="Fecha Conciliada"
                                        name="fechaConciliacionEditar"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <DatePicker 
                                            style={{ width: '100%' }} 
                                            format='DD-MM-YYYY HH:mm' 
                                            showTime={true}
                                            disabledDate={disabledRange}
                                            disabledTime={disabledTime}
                                            />      
                                    </Form.Item> 
                                </Col>  
                                        
                                <Col span={24}>
                                    <Form.Item
                                        label="Evidencia PDF"
                                        name="pdf"
                                    >
                                        <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false} style={{ width: '100%' }}>
                                            {this.state.filePDF === false ? (
                                            <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                                <UploadOutlined /> Click para subir evidencia PDF
                                            </Button>
                                            ):false}
                                        </Upload>
                                    </Form.Item>
                                </Col>
                            

                                <Divider/>

                            <Row justify='space-between'>
                                    <Col span={11}>
                                        <Button className="nuevoUsuario" style={{ width: '100%' }} onClick={onReiniciarValores} >Reiniciar Valores</Button>
                                    </Col>

                                    <Col span={11}>
                                        <Button className="nuevoUsuario" style={{ width: '100%' }} htmlType="submit">Guardar Cambios</Button>
                                    </Col>
                                </Row>                 
                                

                            </Form>
                            ) : false}
                    
                        </Spin>
                    </Card>
                </Col>

                {/* FORMULARIO DE CONSULTA*/}
                <Col span={12}>
                    <Card className="cardCatalogos" title={<div className='headerLista'>Consulta Ticket</div>} > 

                    {busquedaTicketSS.length > 0 ? (
                        <Spin spinning={fetchingBusquedaTicketSS}>
                            <Form {...layout}
                                name="formularioConsulta"
                                initialValues={busquedaTicketSS[0]} 
                                ref={this.formRef}
                            >
                                <Row justify='space-between'>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Folio"
                                            name="folio"
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </Col>

                                    <Divider/>

                                    <Col span={24}>
                                        <Form.Item
                                            label='Tipo de Falla'
                                            name='tipoFalla'>
                                            <Select 
                                                style={{ width: '100%' }}
                                                disabled
                                            >
                                                </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item
                                            label="Falla"
                                            name="falla"
                                        >
                                            <Select 
                                                style={{ width: '100%' }}
                                                disabled
                                            >
                                        </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item
                                            label="Descripcion de Falla"
                                            name="descripcionSolicitud"
                                        >
                                            <TextArea 
                                                rows={3}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Col>

                                    {busquedaTicketSS[0].habilitarSeveridad ? (
                                    <Col span={24}>
                                        <Form.Item
                                            label="Nivel de Severidad"
                                            name="nivelSeveridad"
                                        >
                                            <Select 
                                                style={{ width: '100%' }}
                                                disabled
                                            >
                                        </Select>
                                        </Form.Item>
                                    </Col>
                                    ): false}

                                    <Divider/>

                                    <Col span={24}>
                                        <Form.Item
                                            label="Clasificación Ticket"
                                            name="clasificacionTicket"
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </Col>


                                    <Divider/>

                                    <Col span={24}>
                                        <Form.Item
                                            label="Fecha de Cierre"
                                            name="fechaCierre"
                                        >
                                            <DatePicker  
                                                disabled 
                                                style={{ width: '100%' }} 
                                                format='DD-MM-YYYY HH:mm' 
                                                showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
                                                />
                                        </Form.Item>
                                    </Col>
                                    
                                    {busquedaTicketSS[0].fechaConciliacion !== 'No tiene fecha de conciliacion'? (
                                        <Col span={24}>
                                            <Form.Item
                                                label="Fecha Conciliada"
                                                name="fechaConciliacion"
                                            >
                                                <DatePicker
                                                    disabled style={{ width: '100%' }} 
                                                    format='DD-MM-YYYY HH:mm' 
                                                    showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
                                                    />
                                            </Form.Item>
                                        </Col>
                                    ) : (
                                        <Col span={24}>
                                            <Form.Item
                                                label="Fecha de Conciliacion"
                                                name="fechaConciliacion"
                                            >
                                                <Input disabled />
                                            </Form.Item>
                                        </Col> 
                                    )}
                                </Row>
                            </Form>
                        </Spin>
                    ): (
                        <Row justify='space-around' align='middle'>
                            <Col span={10} style={{paddingTop:'8em', paddingBottom:'8em'}}>
                                {/* <img src={imaSearch} /> */}
                                <ReactVivus
                                    id="imaSearch"
                                    option={{
                                        file: imaSearch,
                                        animTimingFunction: "EASE",
                                        type: "delayed",
                                    }}
                                />
                                <Text type="secondary">Escribe un número de ticket a consultar</Text>
                            </Col>
                        </Row>
                    )}
                    </Card> 
                </Col>
            </Row>
         );
    }
}

const mapStateToProps = state => {
	return {
        busquedaTicketSS:  state.MecanismosDePagosReducer.busquedaTicketSS,
        busquedaTicketSSobj:  state.MecanismosDePagosReducer.busquedaTicketSSobj,
        fetchingBusquedaTicketSS:  state.MecanismosDePagosReducer.fetchingBusquedaTicketSS,

        fallasPorTipoFalla: state.MecanismosDePagosReducer.fallasPorTipoFalla,
        fetchingFallasPorTipoFalla: state.MecanismosDePagosReducer.fetchingFallasPorTipoFalla,
        fetchingListaTiposFallasActivas: state.SolicitudDeServiciosReducer.fetchingListaTiposFallasActivas,
        listaTiposFallasActivas: state.SolicitudDeServiciosReducer.listaTiposFallasActivas,

        listaNivelesSeveridadPorFalla: state.MecanismosDePagosReducer.listaNivelesSeveridadPorFalla,
        fetchingNivelesSeveridadPorFalla: state.MecanismosDePagosReducer.fetchingNivelesSeveridadPorFalla,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,

        seccionesPorServicio: state.MecanismosDePagosReducer.seccionesPorServicio,
        fallasPorServicio: state.MecanismosDePagosReducer.fallasPorServicio,
        fetchingFallasPorServicio: state.MecanismosDePagosReducer.fetchingFallasPorServicio,
        
        listaMesesAbiertos: state.MecanismosDePagosReducer.listaMesesAbiertos,
        listaMesesProcesados: state.MecanismosDePagosReducer.listaMesesProcesados,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onGetTicketSS: (busqueda) => {
            dispatch({ type: 'BUSQUEDA_FOLIO_TICKET_SS_REQUEST', busqueda});
        },
        onGetListaTiposFallasActivas: () => {
            dispatch({ type: "LISTA_TIPOS_FALLAS_ACTIVAS_REQUEST" });
        },
        onGetFallasPorTipoFalla: (idTipoFalla, param) => {
                dispatch({ type: "FALLAS_POR_TIPO_DE_FALLA_REQUEST", idTipoFalla, param});
        },
        onFallasPorServicioLimpiar: () => {
            dispatch({ type: "FALLAS_POR_SERVICIO_SUCCESS", fallasPorServicio: []});
        },
        onGetNivelSeveridad: () => {
            dispatch({ type: 'NIVELES_DE_SEVERIDAD_POR_FALLA_REQUEST' });
        },
        onCrearConciliacion: (formulario, busquedaTicketSSobj) => {
            dispatch({ type: 'CREAR_CONCILIACION_REQUEST', formulario, busquedaTicketSSobj });
        },
        onSeccionesPorServicio: (idServicio) => {
            dispatch({ type: 'SECCIONES_POR_SERVICIO_REQUEST', idServicio});
        },
        onFallasPorServicio: (idServicio, param) => {
            dispatch({ type: 'FALLAS_POR_SERVICIO_REQUEST', idServicio, param })
        },
        onActualizarTicket: (busquedaTicketSS, busquedaTicketSSobj) => {
            dispatch({ type: 'BUSQUEDA_FOLIO_TICKET_SS_SUCCESS', busquedaTicketSS, busquedaTicketSSobj})
        },

        onGetListaMesesAbiertos: () => {
            dispatch({ type: 'LISTA_MESES_ABIERTOS_REQUEST' });
        },

        onMesesProcesadosAnioActual: () => {
         dispatch({ type: 'LISTA_MESES_PROCESADOS_REQUEST' });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ConciliacionContainer);
 
