import React from 'react';
import {connect} from 'react-redux';
import { Row, Col, Modal, Form, Button, Collapse, message,Upload, Spin} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import InsumosForm from './InsumosForm';
import TareasTable from './TareasTable';
import PersonalForm from './PersonalForm';

const { Panel } = Collapse;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalCambiarEstatus extends React.Component {
    formRef = React.createRef();

    state = {
        dataSource: [],
        handlePanel : ['2'],
        errorMessage: false,
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
        pagina: 1,
        ordenReprogramada: true,
    }

    componentDidUpdate (prevProps) {
        if (this.props.cancelacionOrdenesSPDetalle !== prevProps.cancelacionOrdenesSPDetalle) {
            const data = this.props.cancelacionOrdenesSPDetalle;
            this.setState({dataSource: data, handlePanel:['2'], errorMessage: false, filePDF: false, botonPDF: false,botonEditar: false});
        }
    }
  
    render() {
        const {estatusOrdenSPSeleccionado, showModalCambiarEstatusOrdenSP,onShowModalCambiarEstatus, onEditarListaInsumosEstatus,fetchingCambiarEstatusOrdenSP, fetchingCancelacionOrdenesSPDetalle,
            onCambiarEstatus, busqueda, page, ordenReprogramada
        } = this.props;
        const {dataSource, handlePanel, pagina} = this.state;
        

        const handleEdit = (value, data, field, ordenReprogramada) => {
            console.log(value, data, field, ordenReprogramada, dataSource);
            let ordenReprogramadaLocal = this.state.ordenReprogramada;
            if(field === 'paginacion'){
                this.setState({pagina: value});
            }
            else{
                const copyDataSource = dataSource;
                if(field === 'estadoOrden') {
                    ordenReprogramadaLocal = true;
                }
                for ( let elemento of copyDataSource) {
                    if(field === 'estadoCompleto'){
                        elemento.fechasRutinasByIdOrdenServicio.estado = value;
                        ordenReprogramadaLocal = ordenReprogramada;
                    }
                    if( elemento.idOrdenServicioPlanificado === data.idOrdenServicioPlanificado) {
                        if(field === 'estadoOrden') {
                            elemento.fechasRutinasByIdOrdenServicio.estado = value;
                            // elemento.estadoOrden = value;
                            // elemento.programacionRutinasByIdProgramacionRutina.estadoRutina = value;
                        }
                        if(field === 'fecha') {
                            const fechaReprogramacion = value.format('DD-MM-YYYY')
                            elemento.fechaReprogramacion = fechaReprogramacion;
                        }
                        if(field === 'fechaRealizacion') {
                            const fechaRealizacion = value.format('DD-MM-YYYY HH:mm')
                            elemento.fechaRealizacion = fechaRealizacion;
                        }
                    }
                    if(field === 'estadoOrden'){
                        if(elemento.fechasRutinasByIdOrdenServicio.estado === 'Realizada'){
                            ordenReprogramadaLocal = false;
                        }
                    }
                }
                this.setState({dataSource: copyDataSource, ordenReprogramada: ordenReprogramadaLocal});
            }
        }

        const onEditInput = (row) => {
            console.log(row);
            const copyDataSource = dataSource;
            for ( let elemento of copyDataSource) {
                if( elemento.idOrdenServicioPlanificado === row.idOrdenServicioPlanificado) {
                    elemento.observaciones = row.observaciones;
                    elemento.resultados = row.resultados;
                    break;
                }
            }
            this.setState({dataSource: copyDataSource});
        }

        const onHandleError = (value) =>{
            this.setState({errorMessage: value})
        }

        const onChangePanel = (value) => {
            this.setState({handlePanel: value})
        }

        const ContenidoEstatus = () => {
            return (
                <Collapse onChange={onChangePanel} defaultActiveKey={handlePanel} ghost={true} style={{backgroundColor:'inherit', border:'inherit',}}>
                    <Panel header='Editar Insumos y Herramientas' key='1' style={{borderBottom:'inherit', borderTop:'inherit'}}>
                        <InsumosForm listaTareas={dataSource}/>
                    </Panel>
                    <Panel header='Editar Personal' key='3' style={{borderBottom:'inherit'}}>
                        <PersonalForm listaTareas={dataSource}/>
                    </Panel>
                    <Panel header='Editar Tareas' key='2' style={{borderBottom:'inherit'}}>
                        <TareasTable data={dataSource} pagina={pagina} onEditData={handleEdit} onEditInput={onEditInput} onHandleError={onHandleError} />
                    </Panel>
                </Collapse>
            );
        }

        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                })
            }
            var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
            if (info.file.status === 'done') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['rutaArchivo']);
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['rutaArchivo']);
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }
        const onRemovePDF = () => {
            this.formRef.current.resetFields(['rutaArchivo']);
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };

        const onFinish = (formulario) => {
            let validarEstatus = true;
            let estadoOrden = 'Realizada';
            console.log(`dataSource`, dataSource)
            for (let tarea of dataSource) {     
                tarea.estadoOrden = estadoOrden;           
                if(tarea.fechasRutinasByIdOrdenServicio.estado === 'En proceso') {
                    message.warning('Favor de seleccionar un estado para cada tarea');
                    validarEstatus = false;
                    return;
                }
                if(tarea.fechasRutinasByIdOrdenServicio.estado === 'Reprogramada' && !tarea.observaciones) {
                    message.warning('Describe las observaciones para cada tarea de la lista');
                    validarEstatus = false;
                    return;
                }
            }
            console.log(formulario, this.props.listaPersonal)
            onCambiarEstatus(formulario, estatusOrdenSPSeleccionado, dataSource, estadoOrden, busqueda, page, this.props.listaPersonal)
        }
        
        const handleCancel = () =>{
            const estatusOrdenSPSeleccionadoLocal = estatusOrdenSPSeleccionado;
            estatusOrdenSPSeleccionadoLocal.estado = undefined;
            onShowModalCambiarEstatus(estatusOrdenSPSeleccionadoLocal);
            onEditarListaInsumosEstatus();
            this.setState({dataSource: [], handlePanel:['2']})   
            this.props.onActualizarRevisarTareas(null, false);         
            this.props.onActualizarRevisarInsumos(null, false);
        }
        console.log('this.state.ordenReprogramada', this.state.ordenReprogramada)
        return (
            <div>
                {estatusOrdenSPSeleccionado ? (
                    <Modal
                        visible={showModalCambiarEstatusOrdenSP}
                        title={`Modificar Estado de la Órden: '${estatusOrdenSPSeleccionado.folioOrden}'`}
                        onCancel={handleCancel}
                        footer={false}
                        width='90%'
                    >
                        <Spin spinning={fetchingCambiarEstatusOrdenSP || fetchingCancelacionOrdenesSPDetalle}>
                            <ContenidoEstatus />

                            <Form {...layout}
                                name='formulario'
                                onFinish={onFinish}
                                ref={this.formRef}
                            >
                                <Row type='flex' justify='space-around'>
                                    <Col span={11}>
                                        <Form.Item
                                            name="rutaArchivo"
                                            rules={[{required: !this.state.ordenReprogramada, message: 'Favor de adjuntar la evidencia PDF'}]}
                                        >
                                            <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                                {this.state.filePDF === false ? (
                                                    <Button disabled={this.state.botonPDF || this.state.ordenReprogramada} className='btnCargaArchivos'>
                                                        <UploadOutlined /> Subir Evidencia PDF
                                                    </Button>
                                                ) : false}
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Button className="btnFiltrar" disabled={this.state.botonPDF} style={{ width: '100%' }} htmlType="submit">Cambiar Estatus</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Spin>
                    </Modal>
                ): false}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        busqueda: state.ServiciosPlanificadosReducer.busqueda,
        page: state.ServiciosPlanificadosReducer.page,
        fetchingCambiarEstatusOrdenSP: state.ServiciosPlanificadosReducer.fetchingCambiarEstatusOrdenSP,
        estatusOrdenSPSeleccionado: state.ServiciosPlanificadosReducer.estatusOrdenSPSeleccionado,
        showModalCambiarEstatusOrdenSP: state.ServiciosPlanificadosReducer.showModalCambiarEstatusOrdenSP,
        cancelacionOrdenesSPDetalle: state.ServiciosPlanificadosReducer.cancelacionOrdenesSPDetalle,
        fetchingCancelacionOrdenesSPDetalle: state.ServiciosPlanificadosReducer.fetchingCancelacionOrdenesSPDetalle,
        listaPersonal: state.ServiciosPlanificadosReducer.listaPersonal,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalCambiarEstatus: (estatusOrdenSPSeleccionadoLocal) => {
            dispatch ({type: 'SHOW_MODAL_CAMBIAR_ESTATUS_OSP', showModalCambiarEstatusOrdenSP: false, estatusOrdenSPSeleccionadoLocal, listaSinRepetir: [], listaSinRepetirHerramienta: [] })
        },
        onEditarListaInsumosEstatus: () => {
            dispatch({ type: 'ACTUALIZAR_LISTA_INSUMOS_ESTATUS',listaInsumos: []})
        },
        onCambiarEstatus:(formulario, estatusOrdenSPSeleccionado, listaTareas, tipoEstatus, busqueda, page, listaPersonal) => {
            dispatch({ type: 'CAMBIAR_ESTATUS_ORDEN_SP_REQUEST', formulario, estatusOrdenSPSeleccionado, listaTareas, tipoEstatus, busqueda, page, listaPersonal })
        },
        onActualizarRevisarTareas: (listaPersonal, revisarTareas) => {
            dispatch({ type: "ACTUALIZAR_LISTA_PERSONAL_ESTATUS",  listaPersonal, revisarTareas });
        },
        onActualizarRevisarInsumos: (listaInsumos, revisarInsumos) => {
            dispatch({ type: "ACTUALIZAR_LISTA_INSUMOS_ESTATUS",  listaInsumos, revisarInsumos });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalCambiarEstatus);