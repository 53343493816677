import React, { Component } from 'react';
import {Row, Col, Modal, Form, Descriptions, Steps, DatePicker, Button, Spin, message, Tooltip, Select} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';
import { CalendarOutlined, FilePdfOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Step } = Steps;
const { Option } = Select;

class ModalComparativos extends Component {
    formRef = React.createRef();

     state = {
          current: 0, booleanDate: false, booleanSecondMonth: true, lastDay_MonthOne: '', booleanSecondYear: true, lastDay_YearOne: '', monthSelectedOne: '', monthSelectedTwo: '',yearSelectedOne: '', yearSelectedTwo: '', idServicio: null, buttonExport: true, exportarServicioP: true, exportarServicioS: true, buttonExportYear: true
     }
     componentDidMount(){
          this.props.onGetServiciosActivos();
     }

     render() {
          const { onCloseModalComparativos, showModalComparativos, tipoReporteSeleccionado, infoReporteSeleccionado, 
               listaInfoProyecto, onExportComparativaServicio, onGetInformacionPrimerComparativo, 
               onGetInformacionSegundoComparativo, fetchingListaServiciosActivosF, listaServiciosActivosF, 
               fetchingComparativaServicioPDF, onListaServiciosFiltroLimpiar, onListaServiciosFiltro
          } = this.props;

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){ permisosUsuario = JSON.parse(permisosUsuario); }

          const onFinish = (formulario) => {
               console.log('Success:', formulario);

               if(formulario.rangeMonthOne){
                    // Entra a caso mes
                    if(infoReporteSeleccionado){
                         if( tipoReporteSeleccionado === 'Servicio' ){
                              let { servicio } = infoReporteSeleccionado;
                              let monthOne = (formulario.rangeMonthOne._d).getMonth() + 1;
                              let yearOne = (formulario.rangeMonthOne._d).getFullYear();

                              
                              onGetInformacionPrimerComparativo(servicio.idServicio, monthOne, yearOne, tipoReporteSeleccionado)

                              let monthTwo = (formulario.rangeMonthTwo._d).getMonth() + 1;
                              let yearTwo = (formulario.rangeMonthTwo._d).getFullYear();

                              onGetInformacionSegundoComparativo(servicio.idServicio, monthTwo, yearTwo, tipoReporteSeleccionado)

                              this.setState({ idServicio: servicio.idServicio });
                         }
                    }
               } else {
                    // Entra a caso año
                    let yearOne = (formulario.rangeYearOne._d).getFullYear();
                    let yearTwo = (formulario.rangeYearTwo._d).getFullYear();
               }
               this.setState({ booleanDate: true });
          }

          const handleCancel = () => {
               onCloseModalComparativos();
               this.setState({ booleanDate: false, booleanSecondMonth: true, lastDay_MonthOne: '', booleanSecondYear: true, lastDay_YearOne: '', buttonExport: true, buttonExportYear: true });
               this.formRef.current.resetFields();
          };

          let data=[];
          if (infoReporteSeleccionado){
               let nombre = '';
               if( tipoReporteSeleccionado === 'Servicio' ){
                    let { servicioEncuesta } = infoReporteSeleccionado;
                    nombre = servicioEncuesta;
               } else if ( tipoReporteSeleccionado === 'Pregunta' ) {
                    let { preguntaEncuesta } = infoReporteSeleccionado;
                    nombre = preguntaEncuesta;
               } else if ( tipoReporteSeleccionado === 'Folio' ) {
                    let { folioEncuesta } = infoReporteSeleccionado;
                    nombre = folioEncuesta;
               }
               
               data = [
                    {label: tipoReporteSeleccionado, data: nombre, key: tipoReporteSeleccionado},
               ];
          }

          const onChangeStep = (current) => {
               this.setState({ current, booleanDate: false, booleanSecondMonth: true, lastDay_MonthOne: '', booleanSecondYear: true, lastDay_YearOne: '', buttonExport: true, buttonExportYear: true, exportarServicioP: true, exportarServicioS: true, serviciosPrimera: [], serviciosSegunda: [] });
               this.formRef.current.resetFields();
          };

          // En caso de que sean comparativos por rangos
          const onChangeMonth = (date, dateString) => {
               if(date){
                    if(listaInfoProyecto.fechaInicio){

                         let mesInicio = dateString[0].split('-')[0];
                         let annioInicio = dateString[0].split('-')[1];
                         let mesFin = dateString[1].split('-')[0];
                         let annioFin = dateString[1].split('-')[1];

                         let idMesUNOLocal = (date[0]._d).getMonth();
                         let idMesDOSLocal = (date[1]._d).getMonth();
                         let diasVigenciaUNO = new Date(annioInicio, idMesUNOLocal, 0).getDate();

                         let mesUNOFechaInicio = new Date(annioInicio, idMesUNOLocal, diasVigenciaUNO, 23,59,59,59);
                         let mesDOSFechaFin = new Date(annioFin, idMesDOSLocal, 1, 0,0,0,0);

                         const diferenciaInicio = listaInfoProyecto.fechaInicio.diff(moment(mesUNOFechaInicio), "days");

                         const fechaVigenciaNuevo = listaInfoProyecto.fechaVigencia.split('-')[2] + '-' + listaInfoProyecto.fechaVigencia.split('-')[1] + '-' + listaInfoProyecto.fechaVigencia.split('-')[0];
                         
                         const fechaVigenciaLocal = new Date(fechaVigenciaNuevo); 

                         if(diferenciaInicio > 0){
                         message.error('No es posible seleccionar un mes de inicio menor al inicio del contrato del proyecto', 5);
                         this.formRef.current.resetFields(['rangeMonth']);
                         }
                         if(mesDOSFechaFin > fechaVigenciaLocal){
                              message.error('No es posible seleccionar un mes de fin mayor a la vigencia del contrato del proyecto', 5);
                              this.formRef.current.resetFields(['rangeMonth']);
                         }
                    }
                    else{
                         message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                         this.formRef.current.resetFields(['rangeMonth']);
                    }
               } 
          };

          const onChangeMonthFirst = (date, dateString) => {
               if(date){
                    if(listaInfoProyecto.fechaInicio){
                         let mes = dateString.split('-')[0];
                         let annio = dateString.split('-')[1];

                         let idMesLocal = (date._d).getMonth();
                         let diasVigencia = new Date(annio, idMesLocal + 1, 0).getDate();

                         let mesFechaInicio = new Date(annio, idMesLocal, 1, 0,0,0,0)
                         let mesFechaFin = new Date(annio, idMesLocal, diasVigencia, 23,59,59,59);

                         this.setState({ lastDay_MonthOne: mesFechaFin, monthSelectedOne: dateString, booleanSecondMonth: false  });
                         console.log('mesFechaFin', mesFechaFin)

                         const diferenciaInicio = listaInfoProyecto.fechaInicio.diff(moment(mesFechaFin), "days");

                         const fechaVigenciaNuevo = listaInfoProyecto.fechaVigencia.split('-')[2] + '-' + listaInfoProyecto.fechaVigencia.split('-')[1] + '-' + listaInfoProyecto.fechaVigencia.split('-')[0];
                         
                         const fechaVigenciaLocal = new Date(fechaVigenciaNuevo); 
                         if(diferenciaInicio > 0){
                              message.error('No es posible seleccionar un mes de inicio menor al inicio del contrato del proyecto', 5);
                              this.formRef.current.resetFields(['rangeMonthOne']);
                              this.setState({ booleanSecondMonth: true });
                         }
                         if(mesFechaInicio > fechaVigenciaLocal){
                              message.error('No es posible seleccionar un mes de fin mayor a la vigencia del contrato del proyecto', 5);
                              this.formRef.current.resetFields(['rangeMonthOne']);
                              this.setState({ booleanSecondMonth: true });
                         }
                    }
                    else{
                         message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                         this.formRef.current.resetFields(['rangeMonthOne']);
                    }
                    this.formRef.current.resetFields(['rangeMonthTwo']);
               } else { this.setState({ booleanSecondMonth: true, buttonExport: true  }); this.formRef.current.resetFields(['rangeMonthTwo']); }
          };

          const disabledDateMonth = (current) => {
               return current && current < moment(this.state.lastDay_MonthOne, "YYYY-MM-DD");
          };

          const onChangeMonthSecond = (date, dateString) => {
               if(date){
                    if(listaInfoProyecto.fechaInicio){
                         let mes = dateString.split('-')[0];
                         let annio = dateString.split('-')[1];
                         let idMesLocal = (date._d).getMonth();
                         let mesFechaInicio = new Date(annio, idMesLocal, 1, 0,0,0,0);
                         const fechaVigenciaNuevo = listaInfoProyecto.fechaVigencia.split('-')[2] + '-' + listaInfoProyecto.fechaVigencia.split('-')[1] + '-' + listaInfoProyecto.fechaVigencia.split('-')[0];
                         const fechaVigenciaLocal = new Date(fechaVigenciaNuevo);

                         this.setState({ monthSelectedTwo: dateString, buttonExport: false });

                         if(mesFechaInicio > fechaVigenciaLocal){
                              message.error('No es posible seleccionar un mes de fin mayor a la vigencia del contrato del proyecto', 5);
                              this.formRef.current.resetFields(['rangeMonthTwo']);
                         }
                    }
                    else{
                         message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                         this.formRef.current.resetFields(['rangeMonthTwo']);
                    }
               } else { this.setState({ buttonExport: true }); }
          };

          const onExportComparativaByMonth = () => {
               onExportComparativaServicio(this.state.monthSelectedOne.split('-')[0], this.state.monthSelectedOne.split('-')[1], this.state.monthSelectedTwo.split('-')[0], this.state.monthSelectedTwo.split('-')[1], this.state.serviciosPrimera, this.state.serviciosSegunda);
          };

          const onChangeServciosP = (value) => {
               console.log(`value`, value);
               let exportar = true;
               if(value[0]){
                    exportar = false;
               }
               this.setState({
                    serviciosPrimera: value,
                    exportarServicioP: exportar,
               })
          }
          const onChangeServciosS = (value) => {
               console.log(`value`, value);
               let exportar = true;
               if(value[0]){
                    exportar = false;
               }
               this.setState({
                    serviciosSegunda: value,
                    exportarServicioS: exportar,
               })
          }

          return (
               <Modal
                    visible={showModalComparativos}
                    title={'Comparativos / Reporte por ' + tipoReporteSeleccionado}
                    onCancel={handleCancel}
                    footer={false}
                    width={'60%'}
               >
                    <Spin spinning={fetchingComparativaServicioPDF}>
                    <Form name="formulario" ref={this.formRef} onFinish={onFinish} >
                         <Row justify='space-around'>
                              {/* <Col span={24}>
                                   <Descriptions 
                                        layout="horizontal" bordered= {true}
                                   >
                                        {data.map(item => 
                                             <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                        )}
                                   </Descriptions>
                              </Col> */}
                              
                              <Col span = {24}>
                                   <Steps
                                        type="navigation"
                                        size="small"
                                        current={this.state.current}
                                        onChange={onChangeStep}
                                   >
                                        <Step icon={<CalendarOutlined className="stepIcon"/>} title="Por Mes" />
                                   </Steps>
                              </Col>

                              { this.state.current === 0 ? (<>
                                   <Col span={5}>
                                        <br/>
                                        <Tooltip title='Selecciona el primer mes a comparar'>
                                             <Form.Item  name='rangeMonthOne' rules={[{ required: true, message: 'Favor de llenar el campo' }]} >
                                                  <DatePicker onChange={onChangeMonthFirst} picker="month" style={{width:"100%"}} format='MM-YYYY' placeholder='Primer mes' />
                                             </Form.Item>
                                        </Tooltip>
                                   </Col>

                                   <Col span={18}>
                                        <br/>
                                        <Tooltip title='Selecciona la primer lista de servicios a comparar'>
                                             <Form.Item name='serviciosPrimera' rules={[{ required: true, message: 'Favor de llenar el campo' }]} >
                                                  <Select allowClear
                                                       style={{ width: '100%' }}
                                                       placeholder="Primera lista de servicios"
                                                       loading={fetchingListaServiciosActivosF}
                                                       onChange={onChangeServciosP}
                                                       showSearch={true}
                                                       mode='multiple'
                                                  >
                                                       {listaServiciosActivosF.map(option => <Option key={option.idServicio + ' - ' + option.nombre}>{option.idServicio + ' - ' + option.nombre}</Option>)}
                                                  </Select>
                                             </Form.Item>
                                        </Tooltip>
                                   </Col>

                                   <Col span={5}>
                                        <br/>
                                        <Tooltip title='Selecciona el segundo mes a comparar'>
                                             <Form.Item  name='rangeMonthTwo' rules={[{ required: true, message: 'Favor de llenar el campo' }]} >
                                                  <DatePicker disabled={this.state.booleanSecondMonth} onChange={onChangeMonthSecond} picker="month" style={{width:"100%"}} format='MM-YYYY' placeholder='Segundo mes' disabledDate={disabledDateMonth} />
                                             </Form.Item>
                                        </Tooltip>
                                   </Col>
                                   

                                   <Col span={18}>
                                        <br/>
                                        <Tooltip title='Selecciona la segunda lista de servicios a comparar'>
                                             <Form.Item name='serviciosSegunda' rules={[{ required: true, message: 'Favor de llenar el campo' }]} >
                                             <Select allowClear
                                                       style={{ width: '100%' }}
                                                       placeholder="Segunda lista de servicios"
                                                       loading={fetchingListaServiciosActivosF}
                                                       onChange={onChangeServciosS}
                                                       showSearch={true}
                                                       mode='multiple'
                                                  >
                                                       {listaServiciosActivosF.map(option => <Option key={option.idServicio + ' - ' + option.nombre}>{option.idServicio + ' - ' + option.nombre}</Option>)}
                                                  </Select>
                                             </Form.Item>
                                        </Tooltip>
                                   </Col>

                                   {/* <Col span={11}>
                                        <br />
                                        <Button className='btnCargaArchivos' onClick={ onExportComparativaByMonth } disabled={ this.state.buttonExport || this.state.exportarServicioP || this.state.exportarServicioS } >
                                             Exportar Comparativo
                                        </Button>
                                   </Col> */}

                                   <Col span={24}>
                                        <Row justify='end' gutter={[8,8]}>
                                             <Col span={1} style={{paddingRight:'2.8em', paddingTop:'2em'}}>
                                                  <Tooltip title='Exportar Comparativo'>
                                                       <Button className="iconSecundary" shape="circle" onClick={ onExportComparativaByMonth } disabled={ this.state.buttonExport || this.state.exportarServicioP || this.state.exportarServicioS } icon={<FilePdfOutlined />}/>
                                                  </Tooltip> 
                                             </Col>
                                        </Row>
                                   </Col>
                              </>): false }
                         </Row>
                    </Form>
                    </Spin>
               </Modal>
          )
     }
}

const mapStateToProps = state => {
     return {
          showModalComparativos: state.GestionDeCalidadReducer.showModalComparativos,
          tipoReporteSeleccionado: state.GestionDeCalidadReducer.tipoReporteSeleccionado,
          infoReporteSeleccionado: state.GestionDeCalidadReducer.infoReporteSeleccionado,
          listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
          informacionPrimerComparativo: state.GestionDeCalidadReducer.informacionPrimerComparativo,
          informacionSegundoComparativo: state.GestionDeCalidadReducer.informacionSegundoComparativo,
          fetchingComparativaServicioPDF: state.GestionDeCalidadReducer.fetchingComparativaServicioPDF,
          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
     };
};

const mapDispatchToProps = dispatch => {
     return {
          onCloseModalComparativos: () => {
               dispatch({ type: 'SHOW_MODAL_COMPARATIVOS', showModalComparativos: false });
          },
          onExportComparativaServicio: (idMesUno, anioUno, idMesDos, anioDos, serviciosPrimera, serviciosSegunda) => {
               dispatch({ type: 'COMPARATIVO_SERVICIO_PDF_REQUEST', idMesUno, anioUno, idMesDos, anioDos, serviciosPrimera, serviciosSegunda });
          },
          onGetInformacionPrimerComparativo: (idServicio, idMes, anio, tipo) => {
               dispatch({ type: 'OBTENER_INFORMACION_COMPARATIVO1_REQUEST', idServicio, idMes, anio, tipo});
          },
          onGetInformacionSegundoComparativo: (idServicio, idMes, anio, tipo) => {
               dispatch({ type: 'OBTENER_INFORMACION_COMPARATIVO2_REQUEST', idServicio, idMes, anio, tipo});
          },
          onGetServiciosActivos: () => {
               dispatch ({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST' });
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalComparativos);