import {exportarFoliosErroresUOmisionesPDFCall} from "../../services/RMDPServices";

export const MES_SELECCIONADO_RMDP = 'MES_SELECCIONADO_RMDP';

export const ANIOS_RMDP_REQUEST = 'ANIOS_RMDP_REQUEST';
export const ANIOS_RMDP_SUCCESS = 'ANIOS_RMDP_SUCCESS';
export const ANIOS_RMDP_FAILURE = 'ANIOS_RMDP_FAILURE';

export const MESES_RMDP_REQUEST = 'MESES_RMDP_REQUEST';
export const MESES_RMDP_SUCCESS = 'MESES_RMDP_SUCCESS';
export const MESES_RMDP_FAILURE = 'MESES_RMDP_FAILURE';

export const GET_RMDP_REQUEST = 'GET_RMDP_REQUEST';
export const GET_RMDP_SUCCESS = 'GET_RMDP_SUCCESS';
export const GET_RMDP_FAILURE = 'GET_RMDP_FAILURE';

export const GET_REPORTE_RMDP_REQUEST = 'GET_REPORTE_RMDP_REQUEST'
export const GET_REPORTE_RMDP_SUCCESS = 'GET_REPORTE_RMDP_SUCCESS';
export const GET_REPORTE_RMDP_FAILURE = 'GET_REPORTE_RMDP_FAILURE';

// ACREDITAMIENTO DE LA PRESTACIÓN DE LOS SERVICIOS DURANTE EL PERIODO DE CONFORMIDAD CON LO DISPUESTO EN EL ANEXO 12 DEL CONTRATO

// Información del Terreno
export const CREAR_INFORMACION_TERRENO_REQUEST = 'CREAR_INFORMACION_TERRENO_REQUEST';
export const CREAR_INFORMACION_TERRENO_SUCCESS = 'CREAR_INFORMACION_TERRENO_SUCCESS';
export const CREAR_INFORMACION_TERRENO_FAILURE = 'CREAR_INFORMACION_TERRENO_FAILURE';

export const EDITAR_INFORMACION_TERRENO_REQUEST = 'EDITAR_INFORMACION_TERRENO_REQUEST';
export const EDITAR_INFORMACION_TERRENO_SUCCESS = 'EDITAR_INFORMACION_TERRENO_SUCCESS';
export const EDITAR_INFORMACION_TERRENO_FAILURE = 'EDITAR_INFORMACION_TERRENO_FAILURE';

export const OBTENER_ARCHIVO_MES_ANTERIOR_TERRENO_REQUEST = 'OBTENER_ARCHIVO_MES_ANTERIOR_TERRENO_REQUEST';
export const OBTENER_ARCHIVO_MES_ANTERIOR_TERRENO_SUCCESS = 'OBTENER_ARCHIVO_MES_ANTERIOR_TERRENO_SUCCESS';
export const OBTENER_ARCHIVO_MES_ANTERIOR_TERRENO_FAILURE = 'OBTENER_ARCHIVO_MES_ANTERIOR_TERRENO_FAILURE';

export const OBTENER_ARCHIVO_MES_ACTUAL_TERRENO_REQUEST = 'OBTENER_ARCHIVO_MES_ACTUAL_TERRENO_REQUEST';
export const OBTENER_ARCHIVO_MES_ACTUAL_TERRENO_SUCCESS = 'OBTENER_ARCHIVO_MES_ACTUAL_TERRENO_SUCCESS';
export const OBTENER_ARCHIVO_MES_ACTUAL_TERRENO_FAILURE = 'OBTENER_ARCHIVO_MES_ACTUAL_TERRENO_FAILURE';

// Infraestructura
export const CREAR_INFRAESTRUCTURA_REQUEST = 'CREAR_INFRAESTRUCTURA_REQUEST';
export const CREAR_INFRAESTRUCTURA_SUCCESS = 'CREAR_INFRAESTRUCTURA_SUCCESS';
export const CREAR_INFRAESTRUCTURA_FAILURE = 'CREAR_INFRAESTRUCTURA_FAILURE';

export const EDITAR_INFRAESTRUCTURA_REQUEST = 'EDITAR_INFRAESTRUCTURA_REQUEST';
export const EDITAR_INFRAESTRUCTURA_SUCCESS = 'EDITAR_INFRAESTRUCTURA_SUCCESS';
export const EDITAR_INFRAESTRUCTURA_FAILURE = 'EDITAR_INFRAESTRUCTURA_FAILURE';

export const OBTENER_ARCHIVO_MES_ANTERIOR_INFRAESTRUCTURA_REQUEST = 'OBTENER_ARCHIVO_MES_ANTERIOR_INFRAESTRUCTURA_REQUEST';
export const OBTENER_ARCHIVO_MES_ANTERIOR_INFRAESTRUCTURA_SUCCESS = 'OBTENER_ARCHIVO_MES_ANTERIOR_INFRAESTRUCTURA_SUCCESS';
export const OBTENER_ARCHIVO_MES_ANTERIOR_INFRAESTRUCTURA_FAILURE = 'OBTENER_ARCHIVO_MES_ANTERIOR_INFRAESTRUCTURA_FAILURE';

export const OBTENER_ARCHIVO_MES_ACTUAL_INFRAESTRUCTURA_REQUEST = 'OBTENER_ARCHIVO_MES_ACTUAL_INFRAESTRUCTURA_REQUEST';
export const OBTENER_ARCHIVO_MES_ACTUAL_INFRAESTRUCTURA_SUCCESS = 'OBTENER_ARCHIVO_MES_ACTUAL_INFRAESTRUCTURA_SUCCESS';
export const OBTENER_ARCHIVO_MES_ACTUAL_INFRAESTRUCTURA_FAILURE = 'OBTENER_ARCHIVO_MES_ACTUAL_INFRAESTRUCTURA_FAILURE';

export const OBTENER_UBICACIONES_INFRAESTRUCTURA_REQUEST = 'OBTENER_UBICACIONES_INFRAESTRUCTURA_REQUEST';
export const OBTENER_UBICACIONES_INFRAESTRUCTURA_SUCCESS = 'OBTENER_UBICACIONES_INFRAESTRUCTURA_SUCCESS';
export const OBTENER_UBICACIONES_INFRAESTRUCTURA_FAILURE = 'OBTENER_UBICACIONES_INFRAESTRUCTURA_FAILURE';

// Equipo
export const CREAR_EQUIPO_REQUEST = 'CREAR_EQUIPO_REQUEST';
export const CREAR_EQUIPO_SUCCESS = 'CREAR_EQUIPO_SUCCESS';
export const CREAR_EQUIPO_FAILURE = 'CREAR_EQUIPO_FAILURE';

export const EDITAR_EQUIPO_REQUEST = 'EDITAR_EQUIPO_REQUEST';
export const EDITAR_EQUIPO_SUCCESS = 'EDITAR_EQUIPO_SUCCESS';
export const EDITAR_EQUIPO_FAILURE = 'EDITAR_EQUIPO_FAILURE';

export const LISTA_GRUPOS_SERVICIOS_RDMP_REQUEST = 'LISTA_GRUPOS_SERVICIOS_RDMP_REQUEST';
export const LISTA_GRUPOS_SERVICIOS_RDMP_SUCCESS = 'LISTA_GRUPOS_SERVICIOS_RDMP_SUCCESS';
export const LISTA_GRUPOS_SERVICIOS_RDMP_FAILURE = 'LISTA_GRUPOS_SERVICIOS_RDMP_FAILURE';

export const OBTENER_ARCHIVO_MES_ANTERIOR_EQUIPO_REQUEST = 'OBTENER_ARCHIVO_MES_ANTERIOR_EQUIPO_REQUEST';
export const OBTENER_ARCHIVO_MES_ANTERIOR_EQUIPO_SUCCESS = 'OBTENER_ARCHIVO_MES_ANTERIOR_EQUIPO_SUCCESS';
export const OBTENER_ARCHIVO_MES_ANTERIOR_EQUIPO_FAILURE = 'OBTENER_ARCHIVO_MES_ANTERIOR_EQUIPO_FAILURE';

export const OBTENER_ARCHIVO_MES_ACTUAL_EQUIPO_REQUEST = 'OBTENER_ARCHIVO_MES_ACTUAL_EQUIPO_REQUEST';
export const OBTENER_ARCHIVO_MES_ACTUAL_EQUIPO_SUCCESS = 'OBTENER_ARCHIVO_MES_ACTUAL_EQUIPO_SUCCESS';
export const OBTENER_ARCHIVO_MES_ACTUAL_EQUIPO_FAILURE = 'OBTENER_ARCHIVO_MES_ACTUAL_EQUIPO_FAILURE';

export const BUSCAR_TAREAS_RMDP_REQUEST = 'BUSCAR_TAREAS_RMDP_REQUEST';
export const BUSCAR_TAREAS_RMDP_SUCCESS = 'BUSCAR_TAREAS_RMDP_SUCCESS';
export const BUSCAR_TAREAS_RMDP_FAILURE = 'BUSCAR_TAREAS_RMDP_FAILURE';

export const LISTA_SERVICIOS_FOR_EQUIPO_REQUEST = 'LISTA_SERVICIOS_FOR_EQUIPO_REQUEST';
export const LISTA_SERVICIOS_FOR_EQUIPO_SUCCESS = 'LISTA_SERVICIOS_FOR_EQUIPO_SUCCESS';
export const LISTA_SERVICIOS_FOR_EQUIPO_FAILURE = 'LISTA_SERVICIOS_FOR_EQUIPO_FAILURE';

export const LISTA_PROGRAMADOS_FOR_EQUIPO_REQUEST = 'LISTA_PROGRAMADOS_FOR_EQUIPO_REQUEST';
export const LISTA_PROGRAMADOS_FOR_EQUIPO_SUCCESS = 'LISTA_PROGRAMADOS_FOR_EQUIPO_SUCCESS';
export const LISTA_PROGRAMADOS_FOR_EQUIPO_FAILURE = 'LISTA_PROGRAMADOS_FOR_EQUIPO_FAILURE';

export const LISTA_SERVICIOS_BYIDSERVICIO_COUNT_REQUEST = 'LISTA_SERVICIOS_BYIDSERVICIO_COUNT_REQUEST';
export const LISTA_SERVICIOS_BYIDSERVICIO_COUNT_SUCCESS = 'LISTA_SERVICIOS_BYIDSERVICIO_COUNT_SUCCESS';
export const LISTA_SERVICIOS_BYIDSERVICIO_COUNT_FAILURE = 'LISTA_SERVICIOS_BYIDSERVICIO_COUNT_FAILURE';

// Acciones
export const CREAR_ACCIONES_REQUEST = 'CREAR_ACCIONES_REQUEST';
export const CREAR_ACCIONES_SUCCESS = 'CREAR_ACCIONES_SUCCESS';
export const CREAR_ACCIONES_FAILURE = 'CREAR_ACCIONES_FAILURE';

export const EDITAR_ACCIONES_REQUEST = 'EDITAR_ACCIONES_REQUEST';
export const EDITAR_ACCIONES_SUCCESS = 'EDITAR_ACCIONES_SUCCESS';
export const EDITAR_ACCIONES_FAILURE = 'EDITAR_ACCIONES_FAILURE';

export const OBTENER_ARCHIVO_MES_ANTERIOR_ACCIONES_REQUEST = 'OBTENER_ARCHIVO_MES_ANTERIOR_ACCIONES_REQUEST';
export const OBTENER_ARCHIVO_MES_ANTERIOR_ACCIONES_SUCCESS = 'OBTENER_ARCHIVO_MES_ANTERIOR_ACCIONES_SUCCESS';
export const OBTENER_ARCHIVO_MES_ANTERIOR_ACCIONES_FAILURE = 'OBTENER_ARCHIVO_MES_ANTERIOR_ACCIONES_FAILURE';

export const OBTENER_ARCHIVO_MES_ACTUAL_ACCIONES_REQUEST = 'OBTENER_ARCHIVO_MES_ACTUAL_ACCIONES_REQUEST';
export const OBTENER_ARCHIVO_MES_ACTUAL_ACCIONES_SUCCESS = 'OBTENER_ARCHIVO_MES_ACTUAL_ACCIONES_SUCCESS';
export const OBTENER_ARCHIVO_MES_ACTUAL_ACCIONES_FAILURE = 'OBTENER_ARCHIVO_MES_ACTUAL_ACCIONES_FAILURE';

// Folios Errores U Omisiones
export const LISTA_FOLIOS_ERRORES_OMISIONES_REQUEST = 'LISTA_FOLIOS_ERRORES_OMISIONES_REQUEST';
export const LISTA_FOLIOS_ERRORES_OMISIONES_SUCCESS = 'LISTA_FOLIOS_ERRORES_OMISIONES_SUCCESS';
export const LISTA_FOLIOS_ERRORES_OMISIONES_FAILURE = 'LISTA_FOLIOS_ERRORES_OMISIONES_FAILURE';

export const EXPORTAR_FOLIOS_ERRORES_OMISIONES_PDF_REQUEST = 'EXPORTAR_FOLIOS_ERRORES_OMISIONES_PDF_REQUEST';
export const EXPORTAR_FOLIOS_ERRORES_OMISIONES_PDF_SUCCESS = 'EXPORTAR_FOLIOS_ERRORES_OMISIONES_PDF_SUCCESS';
export const EXPORTAR_FOLIOS_ERRORES_OMISIONES_PDF_FAILURE = 'EXPORTAR_FOLIOS_ERRORES_OMISIONES_PDF_FAILURE';

export const EXPORTAR_FOLIOS_ERRORES_OMISIONES_EXCEL_REQUEST = 'EXPORTAR_FOLIOS_ERRORES_OMISIONES_EXCEL_REQUEST';
export const EXPORTAR_FOLIOS_ERRORES_OMISIONES_EXCEL_SUCCESS = 'EXPORTAR_FOLIOS_ERRORES_OMISIONES_EXCEL_SUCCESS';
export const EXPORTAR_FOLIOS_ERRORES_OMISIONES_EXCEL_FAILURE = 'EXPORTAR_FOLIOS_ERRORES_OMISIONES_EXCEL_FAILURE';


// Listados del (i) al (vii) enunciados en el Anexo 1, Mecanismo de Pagos
export const DATA_SS_AGRUPAMIENTO_REQUEST = 'DATA_SS_AGRUPAMIENTO_REQUEST';
export const DATA_SS_AGRUPAMIENTO_SUCCESS = 'DATA_SS_AGRUPAMIENTO_SUCCESS';
export const DATA_SS_AGRUPAMIENTO_FAILURE = 'DATA_SS_AGRUPAMIENTO_FAILURE';

export const DATA_SS_AGRUPAMIENTO_BY_GRUPO_REQUEST = 'DATA_SS_AGRUPAMIENTO_BY_GRUPO_REQUEST';
export const DATA_SS_AGRUPAMIENTO_BY_GRUPO_SUCCESS = 'DATA_SS_AGRUPAMIENTO_BY_GRUPO_SUCCESS';
export const DATA_SS_AGRUPAMIENTO_BY_GRUPO_FAILURE = 'DATA_SS_AGRUPAMIENTO_BY_GRUPO_FAILURE';

//  (i) Relación de Fallas en la Presentación del Servicio
export const LISTA_REPORTE_FALLAS_REQUEST = 'LISTA_REPORTE_FALLAS_REQUEST';
export const LISTA_REPORTE_FALLAS_SUCCESS = 'LISTA_REPORTE_FALLAS_SUCCESS';
export const LISTA_REPORTE_FALLAS_FAILURE = 'LISTA_REPORTE_FALLAS_FAILURE';

export const EXCEL_REPORTE_FALLAS_REQUEST = 'EXCEL_REPORTE_FALLAS_REQUEST';
export const EXCEL_REPORTE_FALLAS_SUCCESS = 'EXCEL_REPORTE_FALLAS_SUCCESS';
export const EXCEL_REPORTE_FALLAS_FAILURE = 'EXCEL_REPORTE_FALLAS_FAILURE';

export const PDF_REPORTE_FALLAS_REQUEST = 'PDF_REPORTE_FALLAS_REQUEST';
export const PDF_REPORTE_FALLAS_SUCCESS = 'PDF_REPORTE_FALLAS_SUCCESS';
export const PDF_REPORTE_FALLAS_FAILURE = 'PDF_REPORTE_FALLAS_FAILURE';

export const DATA_GRAFICA_RELACION_FALLAS_REQUEST = 'DATA_GRAFICA_RELACION_FALLAS_REQUEST';
export const DATA_GRAFICA_RELACION_FALLAS_SUCCESS = 'DATA_GRAFICA_RELACION_FALLAS_SUCCESS';
export const DATA_GRAFICA_RELACION_FALLAS_FAILURE = 'DATA_GRAFICA_RELACION_FALLAS_FAILURE';

// (iv) Relación de Deducciones en el Mes i
export const RELACION_DEDUCCIONES_REQUEST = 'RELACION_DEDUCCIONES_REQUEST';
export const RELACION_DEDUCCIONES_SUCCESS = 'RELACION_DEDUCCIONES_SUCCESS';
export const RELACION_DEDUCCIONES_FAILURE = 'RELACION_DEDUCCIONES_FAILURE';

export const PDF_RELACION_DEDUCCION_REQUEST = 'PDF_RELACION_DEDUCCION_REQUEST';
export const PDF_RELACION_DEDUCCION_SUCCESS = 'PDF_RELACION_DEDUCCION_SUCCESS';
export const PDF_RELACION_DEDUCCION_FAILURE = 'PDF_RELACION_DEDUCCION_FAILURE';

export const EXCEL_RELACION_DEDUCCION_REQUEST = 'EXCEL_RELACION_DEDUCCION_REQUEST';
export const EXCEL_RELACION_DEDUCCION_SUCCESS = 'EXCEL_RELACION_DEDUCCION_SUCCESS';
export const EXCEL_RELACION_DEDUCCION_FAILURE = 'EXCEL_RELACION_DEDUCCION_FAILURE';

//  (v) Relación de Deducciones por Reiteraciones 
export const RELACION_DEDUCCIONES_REITERACION_REQUEST = 'RELACION_DEDUCCIONES_REITERACION_REQUEST';
export const RELACION_DEDUCCIONES_REITERACION_SUCCESS = 'RELACION_DEDUCCIONES_REITERACION_SUCCESS';
export const RELACION_DEDUCCIONES_REITERACION_FAILURE = 'RELACION_DEDUCCIONES_REITERACION_FAILURE';

export const PDF_RELACION_DEDUCCION_REITERACION_REQUEST = 'PDF_RELACION_DEDUCCION_REITERACION_REQUEST';
export const PDF_RELACION_DEDUCCION_REITERACION_SUCCESS = 'PDF_RELACION_DEDUCCION_REITERACION_SUCCESS';
export const PDF_RELACION_DEDUCCION_REITERACION_FAILURE = 'PDF_RELACION_DEDUCCION_REITERACION_FAILURE';

export const EXCEL_RELACION_DEDUCCION_REITERACION_REQUEST = 'EXCEL_RELACION_DEDUCCION_REITERACION_REQUEST';
export const EXCEL_RELACION_DEDUCCION_REITERACION_SUCCESS = 'EXCEL_RELACION_DEDUCCION_REITERACION_SUCCESS';
export const EXCEL_RELACION_DEDUCCION_REITERACION_FAILURE = 'EXCEL_RELACION_DEDUCCION_REITERACION_FAILURE';

// EDITOR DE TEXTO
export const MODAL_EDITOR_TEXTO = 'MODAL_EDITOR_TEXTO';

// Cálculo de Pago por Servicio para el Mes i
//  3.1 Resumen del Pago por Servicio 
export const RESUMEN_PAGO_SERVICIO_REQUEST = 'RESUMEN_PAGO_SERVICIO_REQUEST';
export const RESUMEN_PAGO_SERVICIO_SUCCESS = 'RESUMEN_PAGO_SERVICIO_SUCCESS';
export const RESUMEN_PAGO_SERVICIO_FAILURE = 'RESUMEN_PAGO_SERVICIO_FAILURE';

export const PDF_RESUME_PAGO_REQUEST = 'PDF_RESUME_PAGO_REQUEST';
export const PDF_RESUME_PAGO_SUCCESS = 'PDF_RESUME_PAGO_SUCCESS';
export const PDF_RESUME_PAGO_FAILURE = 'PDF_RESUME_PAGO_FAILURE';

export const EXCEL_RESUME_PAGO_REQUEST = 'EXCEL_RESUME_PAGO_REQUEST';
export const EXCEL_RESUME_PAGO_SUCCESS = 'EXCEL_RESUME_PAGO_SUCCESS';
export const EXCEL_RESUME_PAGO_FAILURE = 'EXCEL_RESUME_PAGO_FAILURE';

//  3.2 Resumen para el Cálculo del PMIi & 3.3 Resumen para el Cálculo del PMRi 
export const RESUMEN_CALCULO_REQUEST = 'RESUMEN_CALCULO_REQUEST';
export const RESUMEN_CALCULO_SUCCESS = 'RESUMEN_CALCULO_SUCCESS';
export const RESUMEN_CALCULO_FAILURE = 'RESUMEN_CALCULO_FAILURE';

export const PDF_RESUMEN_CALCULO_REQUEST = 'PDF_RESUMEN_CALCULO_REQUEST';
export const PDF_RESUMEN_CALCULO_SUCCESS = 'PDF_RESUMEN_CALCULO_SUCCESS';
export const PDF_RESUMEN_CALCULO_FAILURE = 'PDF_RESUMEN_CALCULO_FAILURE';

export const EXCEL_RESUMEN_CALCULO_REQUEST = 'EXCEL_RESUMEN_CALCULO_REQUEST';
export const EXCEL_RESUMEN_CALCULO_SUCCESS = 'EXCEL_RESUMEN_CALCULO_SUCCESS';
export const EXCEL_RESUMEN_CALCULO_FAILURE = 'EXCEL_RESUMEN_CALCULO_FAILURE';

//  3.4 Deducción Aplicable 
export const DEDUCCION_APLICABLE_RESUMEN_REQUEST = 'DEDUCCION_APLICABLE_RESUMEN_REQUEST';
export const DEDUCCION_APLICABLE_RESUMEN_SUCCESS = 'DEDUCCION_APLICABLE_RESUMEN_SUCCESS';
export const DEDUCCION_APLICABLE_RESUMEN_FAILURE = 'DEDUCCION_APLICABLE_RESUMEN_FAILURE';

export const PDF_DEDUCCION_APLICABLE_RESUMEN_REQUEST = 'PDF_DEDUCCION_APLICABLE_RESUMEN_REQUEST';
export const PDF_DEDUCCION_APLICABLE_RESUMEN_SUCCESS = 'PDF_DEDUCCION_APLICABLE_RESUMEN_SUCCESS';
export const PDF_DEDUCCION_APLICABLE_RESUMEN_FAILURE = 'PDF_DEDUCCION_APLICABLE_RESUMEN_FAILURE';

export const EXCEL_DEDUCCION_APLICABLE_RESUMEN_REQUEST = 'EXCEL_DEDUCCION_APLICABLE_RESUMEN_REQUEST';
export const EXCEL_DEDUCCION_APLICABLE_RESUMEN_SUCCESS = 'EXCEL_DEDUCCION_APLICABLE_RESUMEN_SUCCESS';
export const EXCEL_DEDUCCION_APLICABLE_RESUMEN_FAILURE = 'EXCEL_DEDUCCION_APLICABLE_RESUMEN_FAILURE';

//  3.5 Deducción por Consumo de los Servicios Públicos del Desarrollador
export const GET_TIPO_CONSUMO_RMDP_REQUEST = 'GET_TIPO_CONSUMO_RMDP_REQUEST';
export const GET_TIPO_CONSUMO_RMDP_SUCCESS = 'GET_TIPO_CONSUMO_RMDP_SUCCESS'; 
export const GET_TIPO_CONSUMO_RMDP_FAILURE = 'GET_TIPO_CONSUMO_RMDP_FAILURE';

export const PDF_TIPO_CONSUMO_RMDP_REQUEST = 'PDF_TIPO_CONSUMO_RMDP_REQUEST';
export const PDF_TIPO_CONSUMO_RMDP_SUCCESS = 'PDF_TIPO_CONSUMO_RMDP_SUCCESS'; 
export const PDF_TIPO_CONSUMO_RMDP_FAILURE = 'PDF_TIPO_CONSUMO_RMDP_FAILURE';

export const GET_TIPO_CONSUMO_GENERAL_RMDP_REQUEST = 'GET_TIPO_CONSUMO_GENERAL_RMDP_REQUEST';
export const GET_TIPO_CONSUMO_GENERAL_RMDP_SUCCESS = 'GET_TIPO_CONSUMO_GENERAL_RMDP_SUCCESS'; 
export const GET_TIPO_CONSUMO_GENERAL_RMDP_FAILURE = 'GET_TIPO_CONSUMO_GENERAL_RMDP_FAILURE';

export const PDF_TIPO_CONSUMO_GENERAL_RMDP_REQUEST = 'PDF_TIPO_CONSUMO_GENERAL_RMDP_REQUEST';
export const PDF_TIPO_CONSUMO_GENERAL_RMDP_SUCCESS = 'PDF_TIPO_CONSUMO_GENERAL_RMDP_SUCCESS'; 
export const PDF_TIPO_CONSUMO_GENERAL_RMDP_FAILURE = 'PDF_TIPO_CONSUMO_GENERAL_RMDP_FAILURE';

export const EXCEL_TIPO_CONSUMO_RMDP_REQUEST = 'EXCEL_TIPO_CONSUMO_RMDP_REQUEST';
export const EXCEL_TIPO_CONSUMO_RMDP_SUCCESS = 'EXCEL_TIPO_CONSUMO_RMDP_SUCCESS'; 
export const EXCEL_TIPO_CONSUMO_RMDP_FAILURE = 'EXCEL_TIPO_CONSUMO_RMDP_FAILURE';

export const EXCEL_TIPO_CONSUMO_GENERAL_RMDP_REQUEST = 'EXCEL_TIPO_CONSUMO_GENERAL_RMDP_REQUEST';
export const EXCEL_TIPO_CONSUMO_GENERAL_RMDP_SUCCESS = 'EXCEL_TIPO_CONSUMO_GENERAL_RMDP_SUCCESS'; 
export const EXCEL_TIPO_CONSUMO_GENERAL_RMDP_FAILURE = 'EXCEL_TIPO_CONSUMO_GENERAL_RMDP_FAILURE';
