import React from 'react';
import {connect} from 'react-redux';
import {Form, Row, Col, Button, Modal, Spin, Select, message,Popover, Divider, Input, Table, Alert, Descriptions} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalEditarAsignacion extends  React.Component {
    formRef = React.createRef();

    state = {
        activosSelected: [],
    }

    componentDidUpdate ( preProps) {
        if(this.props.limpiarFormulario !== preProps.limpiarFormulario) {
            this.setState({ activosSelected: []});
        }
    }

    render () {

        const {showModalEditarAsignacionSPlanificado, fetchingEditarAsignacionSPlanificado, servicioPlanificadoSeleccionado, page, 
            limpiarFormulario, fetchingUbicacionesSinHijos, ubicacionesSinHijos, fetchingListaActivosCaractFiltroAsignacionSP, listaActivosCaractFiltroAsignacionSP, 
            onShowModalEditarAsignacionSP, onLimpiarFormulario, onListaUbicacionesFiltro, onListaUbicacionesFiltroLimpiar, onListaActivosCaracteristicasFiltro, onListaActiviosCaracteristicasFiltroLimpiar,
            onEditarAsignacionesSP } = this.props;

        const {busqueda} = this.props;

        const {activosSelected} = this.state;

        if (limpiarFormulario && limpiarFormulario === 'EASPlanificado') {
            this.formRef.current.resetFields();
            onLimpiarFormulario();
        }

        const columns =  
        [{
            title: 'Descripción',
            dataIndex: 'descripcion',
            key: 'descripcion',
            // width: '40%',
        },{
            title: 'Cantidad',
            dataIndex: 'cantidad',
            key: 'cantidad',
            align: 'center'
        },{
            title: 'Unidad de Medida',
            dataIndex: 'unidadMedida',
            key: 'unidadMedida',
            align: 'center'
        }]

        const handleSearch = (value) => {
            if(value.length>=3){
                if (value) {
                    console.log(value)
                    onListaUbicacionesFiltro(value);
                } else {
                    message.warning('No se encontró ubicación con esas palabras')
                }
            }
            else{
                onListaUbicacionesFiltroLimpiar();
            }
        };

        const handleSearchActivos = (value) => {
            if(value.length>=3){
                if (value) {
                    console.log(value)
                    onListaActivosCaracteristicasFiltro(value);
                } else {
                    message.warning('No se encontró el activo característica con esas palabras')
                }
            }
            else{
                onListaActiviosCaracteristicasFiltroLimpiar();
            }
        };

        const selectActivos = (value, data) => {
            let addActivo = activosSelected;
            addActivo.push(data.data);
            this.setState({activosSelected: addActivo}); 
        }

        const deselectActivos = (value, data) => {
            let deletedActivo = activosSelected.filter(item => item.idActivoCaract != value.split('-')[0]);
            this.setState({activosSelected: deletedActivo});
        }

        const onFinish = (formulario) => {
            console.log(formulario, activosSelected, page, busqueda);
            onEditarAsignacionesSP(formulario, activosSelected,servicioPlanificadoSeleccionado, page, busqueda)
        }

        const onFinishFailed = () =>{
            console.log('Cancel')
        }

        const handleCancel = () => {
            onShowModalEditarAsignacionSP();
            this.formRef.current.resetFields();
            this.setState({ activosSelected: []});
        }    

        return (
            <Modal
                visible={showModalEditarAsignacionSPlanificado}
                title='Editar Asignación de Servicios Planificados'
                onCancel={handleCancel}
                footer={false}
                width='70%'
            >
                <Spin spinning={fetchingEditarAsignacionSPlanificado}>
                    {servicioPlanificadoSeleccionado ? (
                        <Form {...layout}
                            name='formulario'
                            initialValues={servicioPlanificadoSeleccionado}
                            onFinish={onFinish}
                            
                            ref={this.formRef}
                        >
                                <Row type='flex' justify='space-between' gutter={[8,8]}>
                                    <Col span={24}>
                                        <Descriptions bordered>
                                            <Descriptions.Item label="Categoría">{servicioPlanificadoSeleccionado.categoria}</Descriptions.Item>
                                            <Descriptions.Item label="Nomenclatura">{servicioPlanificadoSeleccionado.nomenclatura}</Descriptions.Item>
                                            <Descriptions.Item label="Partida">{servicioPlanificadoSeleccionado.nomenclaturaPartida}</Descriptions.Item>
                                            <Descriptions.Item label="Programa">{servicioPlanificadoSeleccionado.programa}</Descriptions.Item>
                                            <Descriptions.Item label="Tipo">{servicioPlanificadoSeleccionado.tipoRutina}</Descriptions.Item>
                                            <Descriptions.Item label="Clave R/M/P">{servicioPlanificadoSeleccionado.claveRutina}</Descriptions.Item>
                                            <Descriptions.Item label="EPP">{servicioPlanificadoSeleccionado.epp}</Descriptions.Item>
                                            <Descriptions.Item label="Personal">{servicioPlanificadoSeleccionado.personal}</Descriptions.Item>
                                            <Descriptions.Item label="Actividades">{servicioPlanificadoSeleccionado.actividades}</Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                    
                                    <Col span={12}>
                                        <Divider orientation="left">Insumos</Divider>
                                        <Table
                                            size='small'
                                            rowKey='idProducto'
                                            columns={columns}
                                            dataSource={servicioPlanificadoSeleccionado.insumosByIdRutina}
                                            locale={{ emptyText: 'Sin datos' }}
                                            pagination={{ pageSize: servicioPlanificadoSeleccionado.insumosByIdRutina ? servicioPlanificadoSeleccionado.insumosByIdRutina.length : 0 }}
                                        />               
                                    </Col>
                                    <Col span={12}>
                                        <Divider orientation="left">Herramientas</Divider>
                                        <Table
                                            size='small'
                                            rowKey='idProducto'
                                            columns={columns}
                                            dataSource={servicioPlanificadoSeleccionado.herramientasByIdRutina ? servicioPlanificadoSeleccionado.herramientasByIdRutina : []}
                                            locale={{ emptyText: 'Sin datos' }}
                                            pagination={{ pageSize: servicioPlanificadoSeleccionado.herramientasByIdRutina ? servicioPlanificadoSeleccionado.herramientasByIdRutina.length : 0 }}
                                        />                       
                                    </Col>
                                </Row>

                                <Divider orientation="left">Asignación de Servicios Planificados</Divider>
                                {servicioPlanificadoSeleccionado.programacionRutinasByIdProgramacionRutina?.length > 0 ? 
                                    <Alert
                                        message={`El servicio planificado seleccionado ya cuenta con una asignación programada ${servicioPlanificadoSeleccionado.programa == 'Rutina' ? 'en la o las ubicaciones' : 'en la o las características del activo'}:`}
                                        type="success"
                                    />
                                :
                                    <Alert
                                        message={`Favor de asignar ${servicioPlanificadoSeleccionado.programa == 'Rutina' ? 'una ubicación al' : 'una característica del activo al'} servicio planificado seleccionado.`}
                                        type="info"
                                    />
                                }
                                <br />
                            {servicioPlanificadoSeleccionado.programa == 'Rutina' ? (
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            label='Ubicación'
                                            name='ubicaciones'
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <Select allowClear
                                                mode='multiple'
                                                style={{ width: '100%' }}
                                                placeholder="Ingrese la ubicación"
                                                loading={fetchingUbicacionesSinHijos}
                                                showSearch
                                                filterOption={false}
                                                onSearch={handleSearch}
                                                notFoundContent={null}
                                                dropdownStyle={{ minWidth: "60%" }}
                                                disabled={servicioPlanificadoSeleccionado.programacionRutinasByIdProgramacionRutina?.length > 0 ? true : false}
                                            >
                                                {ubicacionesSinHijos.map(option =>
                                                    <Option key={`${option.idUbicacion} - ${option.ubicacionCOA}`} >
                                                        <Popover content={option.ubicacionCOA} title={false}>
                                                            {`${option.idUbicacion} - ${option.ubicacionCOA}`}
                                                        </Popover>
                                                    </Option>
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ) : false
                            }
                            {servicioPlanificadoSeleccionado.programa !== 'Rutina' ? (
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            label='Característica del activo'
                                            name='activos'
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <Select allowClear
                                                mode='multiple'
                                                style={{ width: '100%' }}
                                                placeholder="Ingrese la característica del activo"
                                                loading={fetchingListaActivosCaractFiltroAsignacionSP}
                                                showSearch
                                                filterOption={false}
                                                onSearch={handleSearchActivos}
                                                onSelect={selectActivos}
                                                onDeselect={deselectActivos}
                                                notFoundContent={null}
                                                dropdownStyle={{ minWidth: "60%" }}
                                                disabled={servicioPlanificadoSeleccionado.programacionRutinasByIdProgramacionRutina?.length > 0 ? true : false}
                                            >
                                                {listaActivosCaractFiltroAsignacionSP.map(option =>
                                                    <Option key={`${option.idActivoCaract} - ${option.descripcion} - ${option.codigoAdicional}`} data={option}>
                                                        <Popover content={option.descripcion} title={false}>
                                                            {`${option.idActivoCaract} - ${option.descripcion} - ${option.codigoAdicional}`}
                                                        </Popover>
                                                    </Option>
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ) : false
                            }
                            <Row type='flex' justify='center'>
                                <Col span={12}>
                                    <Button disabled={servicioPlanificadoSeleccionado.programacionRutinasByIdProgramacionRutina?.length > 0 ? true : false} className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Editar Asignacion para Servicios Planificados</Button>
                                </Col>
                            </Row>
                        </Form>
                    ) : false}
                </Spin>

            </Modal>
        )

    }
}
const mapStateToProps = state => {
    return {
        showModalEditarAsignacionSPlanificado: state.ServiciosPlanificadosReducer.showModalEditarAsignacionSPlanificado,
        fetchingEditarAsignacionSPlanificado: state.ServiciosPlanificadosReducer.fetchingEditarAsignacionSPlanificado,
        servicioPlanificadoSeleccionado: state.ServiciosPlanificadosReducer.servicioPlanificadoSeleccionado,
        page: state.ServiciosPlanificadosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingUbicacionesSinHijos: state.SolicitudDeServiciosReducer.fetchingUbicacionesSinHijos,
        ubicacionesSinHijos: state.SolicitudDeServiciosReducer.ubicacionesSinHijos,
        fetchingListaActivosCaractFiltroAsignacionSP: state.AlmacenesReducer.fetchingListaActivosCaractFiltroAsignacionSP,
        listaActivosCaractFiltroAsignacionSP: state.AlmacenesReducer.listaActivosCaractFiltroAsignacionSP,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarAsignacionSP: () => {
            dispatch({ type: "SHOW_MODAL_EDITAR_ASIGNACION_SP", showModalEditarAsignacionSPlanificado: false, servicioPlanificadoSeleccionado: null})
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
        },
        onListaUbicacionesFiltro: (busqueda) => {
            dispatch({ type: 'UBICACIONES_SIN_HIJOS_REQUEST', busqueda: busqueda });
        },
        onListaUbicacionesFiltroLimpiar: () => {
            dispatch({ type: 'UBICACIONES_SIN_HIJOS_SUCCESS', ubicacionesSinHijos: [] });
        },
        onListaActivosCaracteristicasFiltro: (busqueda) => {
            dispatch({ type: 'LISTA_ACTIVOS_CARACT_FILTRO_ASIGNACION_SP_REQUEST', busqueda: busqueda });
        },
        onListaActiviosCaracteristicasFiltroLimpiar: () => {
            dispatch({ type: 'LISTA_ACTIVOS_CARACT_FILTRO_ASIGNACION_SP_SUCCESS', listaActivosCaractFiltroAsignacionSP: [] });
        },
        onEditarAsignacionesSP: (formulario, activosSelected, servicioPlanificadoSeleccionado, page, busqueda) => {
            dispatch({ type: 'EDITAR_ASIGNACION_SP_REQUEST', formulario: formulario, activosSelected:activosSelected, servicioPlanificadoSeleccionado:servicioPlanificadoSeleccionado, page: page, busqueda: busqueda});
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalEditarAsignacion);