import React, { Component } from 'react';
import {Modal, Calendar , Card, Tag, Tooltip} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import {connect} from 'react-redux';

class ModalVerProgramacion extends Component {
    formRef = React.createRef();
    render() {
        const { showModalVerProgramacionOrden, verProgramacionSeleccionadoOrden,
            onCerrarModal, listaProgramacionOrden } = this.props;

        const handleCancel = () => {
            onCerrarModal();
        };

        const getListData = (value) => {
            let listData;
            for (let i in listaProgramacionOrden) {
                if (listaProgramacionOrden[i].fecha === value.format('YYYY-MM-DD')) {

                    if (listaProgramacionOrden[i].claveUbicacion !== "No hay Ubicacion") {
                        listData = [
                            {
                                type: 'success',
                                content: listaProgramacionOrden[i].claveUbicacion
                            }
                        ]
                    } else {
                        listData = [
                            {
                                type: 'success',
                                content: listaProgramacionOrden[i].descripcion
                            }
                        ]
                    }

                }
            }
            console.log('listData', listData);
            return listData || [];
        }



        const dateCellRender = (value) => {
            let listData = getListData(value);

            return (
                <ul className="events" >
                    {listData.map(item => (
                        <li key={item.content}>
                            <Tag color={item.type} className='tag-rad' style={{ width:'90%', textAlign:'center' }}><Tooltip placement="topLeft" title={item.content}>{item.content}</Tooltip></Tag>
                        </li>
                    ))}
                </ul>
            );
        }



        return (
            <div>
                {verProgramacionSeleccionadoOrden ? (
                    <Modal
                        visible={showModalVerProgramacionOrden}
                        title={`Ver programacion de la Orden: ${verProgramacionSeleccionadoOrden.folioOrden}`}
                        onCancel={handleCancel}
                        footer={false}
                        width='70%'>
                        <Card className="cardCatalogos">
                            <Calendar

                                dateCellRender={dateCellRender}
                            />
                        </Card>

                    </Modal>
                ) : false}

            </div>

        );
    }
}
 
const mapStateToProps = (state) => {
    return {
        showModalVerProgramacionOrden: state.ServiciosPlanificadosReducer.showModalVerProgramacionOrden,
        verProgramacionSeleccionadoOrden: state.ServiciosPlanificadosReducer.verProgramacionSeleccionadoOrden,
        listaProgramacionOrden: state.ServiciosPlanificadosReducer.listaProgramacionOrden,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCerrarModal: () => {
            dispatch({ type: "SHOW_MODAL_VER_PROGRAMACION_ORDEN" , showModalVerProgramacionOrden: false, verProgramacionSeleccionadoOrden: null})
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalVerProgramacion);