import React, {Component} from 'react';
import { Row, Col, Form, Table, Input, Card, Button, Modal, Switch, Tooltip } from 'antd';
import { EditOutlined, SearchOutlined, FileSearchOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalDetalleAlerta from './ModalDetalleAlerta';
import ModalNuevaAlerta from './ModalNuevaAlerta';
import ModalEditarAlerta from './ModalEditarAlerta';

const confirm = Modal.confirm;

class ListaSettings extends Component {
    state = {
        page: 1
    }
    componentDidMount(){
        this.props.onRequestListaAlerta(1, this.props.formularioSearchAlertas);
        this.props.onGetDiasAlertas();
        this.props.onGetModulos();
        this.props.onRequestInfoProyecto();
    }

    render() {
        const { 
            fetchingListaAlertaBusqueda, onRequestListaAlerta, onShowModalDetalleAlerta, listaDiasAlerta,
            listaAlertaBusqueda, listaAlertaBusquedaCantidad, onEstadoAlerta, onNuevaAlerta, formularioSearchAlertas, onGetValuesFormulario
        } = this.props
       
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const onEditar = (key) => {
            console.log(key);
            this.props.onModalEditarAlerta(null, null, null);
            this.props.onModalEditarAlerta(key, this.state.page, formularioSearchAlertas);
            this.props.cambiarPerioricidad(key.periodicidad, key.rangoFechas);

            if(key.diasByIdAlerta){
                const listaDiasAlertaLocal = listaDiasAlerta;
                this.props.onActualizarBotonesDias(null);
                for(let x=0; x<listaDiasAlertaLocal.length; x++){
                    listaDiasAlertaLocal[x].type = 'default';
                    listaDiasAlertaLocal[x].activo = false;
                }
                for(let x=0; x<listaDiasAlertaLocal.length; x++){
                    for(let i=0; i<key.diasByIdAlerta.length; i++){
                        if(listaDiasAlertaLocal[x].idDiaSemana === key.diasByIdAlerta[i].idDiaSemana){
                            listaDiasAlertaLocal[x].type = 'primary';
                            listaDiasAlertaLocal[x].activo = true;
                        }
                    }
                }
                this.props.onActualizarBotonesDias(listaDiasAlertaLocal);
            }
            
        }

        const columns = [{
                title: 'Nombre de la alerta',
                dataIndex: 'nombreAlerta',
                key: 'nombreAlerta',
            },{
                title: 'Módulo',
                dataIndex: 'modulo',
                key: 'modulo',
            },{
                title: 'Servicio',
                dataIndex: 'servicio',
                key: 'servicio',  
            },{
                title: 'Evento',
                dataIndex: 'evento',
                key: 'evento',
            },{
                title: 'Detalle',
                key: 'detalle',
                width: '5%',
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono'  onClick={() => onDetalle(record)} ><FileSearchOutlined /></a>
                ),
            },{
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                width: '5%',
                align: 'center',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.alertaActivoInactivo} onChange={() => handleDesactivar(record)}/>
                ),
            },{
                title: 'Editar',
                key: 'editar',
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarAlerta} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },
        ];

        const onDetalle = (key) => {
            console.log('key');
            console.log(key);
            key.diasAlerta = '';
            key.usuariosAlerta = '';
            if(key.diasByIdAlerta){
                for(let i=0; i<key.diasByIdAlerta.length; i++){
                    key.diasAlerta += '-' + key.diasByIdAlerta[i].diaSemana + ' ';
                }
            }
            else{
                key.diasAlerta = key.fechaDetonacion.split('T')[0].split('-')[2];
            }
            if(key.usuariosByIdAlerta){
                for(let i=0; i<key.usuariosByIdAlerta.length; i++){
                    key.usuariosAlerta += '-' + key.usuariosByIdAlerta[i].nombre + ' ' + key.usuariosByIdAlerta[i].aPaterno + ' ';
                }
            }
            onShowModalDetalleAlerta(key);
        }

        const handleDesactivar = (key) => {
            console.log(key);
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado de la alerta "'+ key.nombreAlerta +'"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoAlerta(key.idAlerta)
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }

        const onFinish = busqueda => {
            console.log(busqueda);
            onGetValuesFormulario(formularioSearchAlertas, this.state.page)
            onRequestListaAlerta(1, busqueda);
        }

        

        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onRequestListaAlerta(pagination.current, formularioSearchAlertas);
		}
        const nuevaAlerta = () => {
            onNuevaAlerta(this.state.page, formularioSearchAlertas);
        }
        return (
            <div>
                <Card className="cardCatalogos" title={<div className='headerLista'>Alertas</div>}>
                    <Row justify='space-between' gutter={[8,8]}>
                    <Col span={24}>
                            <Form
                                name="formulario"
                                onFinish={onFinish}
                                
                            >
                                <Row justify='space-around' gutter={[8,8]}>
                                    <Col span={7}>
                                        <Form.Item
                                            name="servicio"
                                        >
                                            <Input allowClear className='inputFiltro' placeholder="Ingresa servicio a filtrar" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item
                                            name="modulo"
                                        >
                                            <Input allowClear className='inputFiltro' placeholder="Ingresa módulo a filtrar" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item
                                            name="evento"
                                        >
                                            <Input allowClear className='inputFiltro' placeholder="Ingresa evento a filtrar" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}>
                                        <Button shape="circle" icon={<SearchOutlined />} className="btnFiltrar" htmlType="submit"></Button>
                                    </Col>
                                    {/* <Col span={5}>
                                        <Button onClick={nuevaAlerta} disabled={!permisosUsuario.crearAlerta} className='nuevoUsuario'>Nueva Alerta</Button>
                                    </Col> */}
                                    <Col span={1}>
                                        <Tooltip title='Nueva Alerta' placement="topRight">
                                            <Button className="iconTer" shape="circle" onClick={nuevaAlerta} disabled={!permisosUsuario.crearAlerta} icon={<PlusOutlined />}/>
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col span={24}>
                            <Table
                                size="small"
                                rowKey="idAlerta"
                                columns={columns}
                                dataSource={listaAlertaBusqueda}
                                loading={fetchingListaAlertaBusqueda}
                                locale={{ emptyText: 'Sin datos' }}
                                onChange={handleTableChange}
                                pagination={{ total: listaAlertaBusquedaCantidad, simple: true, current: this.state.page }}
                            />
                        </Col>
                    </Row>
                </Card>
                <ModalDetalleAlerta/>
                <ModalNuevaAlerta/>
                <ModalEditarAlerta/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaAlertaBusqueda: state.ConfiguracionYSeguridadReducer.fetchingListaAlertaBusqueda,
        listaAlertaBusqueda: state.ConfiguracionYSeguridadReducer.listaAlertaBusqueda,
        listaAlertaBusquedaCantidad: state.ConfiguracionYSeguridadReducer.listaAlertaBusquedaCantidad,
        listaDiasAlerta: state.ConfiguracionYSeguridadReducer.listaDiasAlerta,
        formularioSearchAlertas: state.ConfiguracionYSeguridadReducer.formularioSearchAlertas
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRequestListaAlerta: (page, busqueda) => {
            dispatch({ type: "LISTA_ALERTAS_BUSQUEDA_REQUEST", page, busqueda})
        }, 
        onEstadoAlerta: (idAlerta) => {
            dispatch({ type: 'ESTADO_ALERTA_REQUEST', page: 1, idAlerta})
        },
        onGetDiasAlertas: () => {
            dispatch({ type: 'LISTA_DIAS_ALERTA_REQUEST'})
        },
        onGetModulos: () => {
            dispatch({ type: 'LISTA_MODULOS_REQUEST'})
        },
        onNuevaAlerta: (page, busqueda) => {
            dispatch({ type: 'SHOW_MODAL_NUEVA_ALERTA', showModalNuevaAlerta: true, page, busqueda })
        },
        onModalEditarAlerta: (alertaSeleccionado, page, busqueda) => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_ALERTA', showModalEditarAlerta: true, alertaSeleccionado, page, busqueda })
        },
        onShowModalDetalleAlerta: (alertaSeleccionado) => {
            dispatch({ type: 'SHOW_MODAL_DETALLE_ALERTA', showModalDetalleAlerta: true, alertaSeleccionado });
        },
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        },
        cambiarPerioricidad: (periodicidad, rangoFechas) => {
            dispatch({ type: 'CAMBIAR_PERIODICIDAD', periodicidad, rangoFechas });
        },
        onActualizarBotonesDias: (listaDiasAlerta) => {
            dispatch({ type: 'LISTA_DIAS_ALERTA_SUCCESS', listaDiasAlerta})
        },
        onGetValuesFormulario: (formulario, page) =>{
            dispatch({ type: "GET_VALUES_FORMULARIO_ALERTAS" , busqueda: formulario, page:page});
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListaSettings) 