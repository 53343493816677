import React from 'react';
import {connect} from 'react-redux';
import {Table, Row, Col, Button, Input, Card, Select, message, Popover, Tooltip, Form, Typography} from 'antd';
import { EditOutlined, FileSearchOutlined, PlusOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevaAsignacionSP from './ModalNuevaAsignacion';
import ModalDetalle from './ModalDetalle';
import ModalDetalleAsignaciones from './ModalDetalleAsignaciones';
import ModalEditarAsignacion from './ModalEditarAsignacion';

const { Search } = Input;
const {Option} = Select;
const {Text} = Typography;

class AsignacionServiciosPlanificados extends React.Component {
    formRef = React.createRef();

    state = {
        page: 1,
        idServicio: undefined,
        idPartida: undefined,
        idUbicacion: undefined,
        programa: undefined,
        idActivoCaract: undefined,
    }

    componentDidMount () { this.props.onGetServiciosPlanificados("", "","","", "", 1); this.props.onGetServiciosActivos(); }

    render () {
        const {fetchingListaServiciosFiltro, listaServiciosFiltro, fetchingUbicacionesSinHijos, ubicacionesSinHijos, fetchingListaAsignacionesSPBusqueda, listaAsignacionesSPBusqueda, listaAsignacionesSPCantidad, 
            fetchingListaActivosCaractFiltroAsignacionSP,listaActivosCaractFiltroAsignacionSP,
            onListaServiciosFiltro,onListaServiciosFiltroLimpiar, onListaUbicacionesFiltro, onListaUbicacionesFiltroLimpiar, onModalNuevaAsignacionSP, onGetServiciosPlanificados, onModalDetalle, onModalDetalleAsignaciones, onModalEditarAsignacionSP,
            onListaActivosCaracteristicasFiltro, onListaActiviosCaracteristicasFiltroLimpiar} = this.props;

        const {idServicio, idPartida, idUbicacion, programa,idActivoCaract, page} = this.state;
            
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columns = [{
            title: 'Nomenclatura',
            dataIndex: 'nomenclatura',
            key: 'nomenclatura',
            // width: '40%',
        },{
            title: 'Partida',
            dataIndex: 'nomenclaturaPartida',
            key: 'nomenclaturaPartida',
            render: (text, record) => (
                text === ' - ' ? <Text italic style={{color:'#dddddd'}}>{text}</Text> : <Text>{text}</Text>
            ),
        },{
            title: 'Programa',
            dataIndex: 'programa',
            key: 'programa',
            // width: '30%',
        },{
            title: 'Nombre de rutina',
            dataIndex: 'rutina',
            key: 'rutina',
            // width: '30%',
        },{
            title: 'Tipo',
            dataIndex: 'tipoRutina',
            key: 'tipoRutina',
            // width: '30%',
        },{
            title: 'Clave',
            dataIndex: 'claveRutina',
            key: 'claveRutina',
            // width: '30%',
        },{
            title: 'Detalle',
            key: 'detalle',
            // width: '15%',
            align: 'center',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.detalleDeAsignaciónDeServiciosPlanificados}   onClick={() => onDetalle(record)} ><FileSearchOutlined /></a>
            ),
        },{
            title: 'Servicios planificados',
            key: 'serviciosPlanificados',
            // width: '15%',
            align: 'center',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.serviciosPlanificadosAsignados}   onClick={() => onDetalleAsignacionesSP(record)} ><FileSearchOutlined /></a>
            ),
        },{
            title: 'Editar',
            key: 'editar',
            // width: '15%',
            render: (text, record) => (
                record.programacionRutinasByIdProgramacionRutina?.length > 0 ? 
                <Tooltip title={`Este servicio planificado ya cuenta con una asignación programada ${record.programa == 'Rutina' ? 'en una o más ubicaciones' : 'en una o más características del activo'}.`} placement="left"> <a className='editarIcono' disabled><EditOutlined /></a> </Tooltip>
                :
                <a className='editarIcono' disabled={!permisosUsuario.editarAsignaciónDeServiciosPlanificados}   onClick={() => onEditar(record)}  ><EditOutlined /></a>
            ),
        }];

        const onChangeSelect = (value, field) => {
            if(field === 'idServicio'){
                if(value) this.props.onGetPartidasByIdServicio(value.split(' - ')[0]); else this.props.onCleanPartidasByIdServicio();

                this.formRef.current.setFieldsValue({'idPartida': undefined});
                this.setState({[field]: value}, () => onGetServiciosPlanificados(this.state.idServicio, "", this.state.programa, this.state.idUbicacion, this.state.idActivoCaract, page));
            } else {
                this.setState({[field]: value}, () => onGetServiciosPlanificados(this.state.idServicio, this.state.idPartida, this.state.programa, this.state.idUbicacion, this.state.idActivoCaract, page));
            }
            
        }

        const handleSearch = (value) => {
            if(value.length>=3){
                if (value) {
                    console.log(value)
                    onListaUbicacionesFiltro(value);
                } else {
                    message.warning('No se encontró ubicación con esas palabras')
                }
            }
            else{
                onListaUbicacionesFiltroLimpiar();
            }
        };

        const handleSearchActivos = (value) => {
            if(value.length>=3){
                if (value) {
                    console.log(value)
                    onListaActivosCaracteristicasFiltro(value);
                } else {
                    message.warning('No se encontró el activo característica con esas palabras')
                }
            }
            else{
                onListaActiviosCaracteristicasFiltroLimpiar();
            }
        };

        const onNuevaAsignacion = () => {
            onModalNuevaAsignacionSP(page);

        }

        const onDetalle = element => {
            onModalDetalle(element);
        }

        const onDetalleAsignacionesSP = element => {
            onModalDetalleAsignaciones(element)
        }

        const onEditar = element => {
            console.log('element', element);
            onModalEditarAsignacionSP(element, page);
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            onGetServiciosPlanificados(idServicio, idPartida, programa, idUbicacion, idActivoCaract, pagination.current)
        }

        return (
            <div>
                <Card className="cardCatalogos" title={<div className='headerLista'>Asignación de Servicios Planificados</div>}>
                <Form   name="formulario" ref={this.formRef}  >
                    <Row justify='space-between' gutter={[8,8]}>
                        <Col span={12}>
                            <Select
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Selecciona un servicio"
                                loading={this.props.fetchingListaServiciosActivosF}
                                showSearch
                                onChange={value => onChangeSelect(value, 'idServicio')}
                            >
                                {this.props.listaServiciosActivosF.map(option => <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>{option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}</Option>)}
                            </Select>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="idPartida" >
                                <Select
                                    allowClear
                                    showSearch={true}
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona una partidas"
                                    loading={this.props.fetchingListaPartidasByServicio4Fallas}
                                    onChange={value => onChangeSelect(value, 'idPartida')}
                                    disabled={!this.props.listaPartidasByServicio4Fallas[0]}
                                >
                                    {this.props.listaPartidasByServicio4Fallas.map(option =>
                                        <Option key={option.idPartida + ' - ' + option.nomenclatura  + ': ' + option.nombre}>
                                            {option.idPartida + ' - ' + option.nomenclatura  + ': ' + option.nombre}
                                        </Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    {/* </Row>
                    <Row justify='space-between' gutter={[8,8]}> */}
                        <Col span={12}>
                            <Select allowClear
                                style={{ width: '100%' }}
                                placeholder="Ingrese la ubicación"
                                loading={fetchingUbicacionesSinHijos}
                                showSearch
                                filterOption={false}
                                onSearch={handleSearch}
                                onChange={value => onChangeSelect(value, 'idUbicacion')}
                                notFoundContent={null}
                                dropdownStyle={{ minWidth: "60%" }}
                            >
                                {ubicacionesSinHijos.map(option =>
                                    <Option key={`${option.idUbicacion} - ${option.ubicacionCOA}`} >
                                        <Popover content={option.ubicacionCOA} title={false}>
                                            {`${option.idUbicacion} - ${option.ubicacionCOA}`}
                                        </Popover>
                                    </Option>
                                )}
                            </Select>
                        </Col>
                        <Col span={6}>
                            <Select allowClear
                                style={{ width: '100%' }}
                                placeholder="Ingrese el activo característica"
                                loading={fetchingListaActivosCaractFiltroAsignacionSP}
                                showSearch
                                filterOption={false}
                                onSearch={handleSearchActivos}
                                onChange={value => onChangeSelect(value, 'idActivoCaract')}
                                notFoundContent={null}
                                dropdownStyle={{ minWidth: "60%" }}
                            >
                                {listaActivosCaractFiltroAsignacionSP.map(option =>
                                    <Option key={`${option.idActivoCaract} - ${option.descripcion}`} data={option}>
                                        <Popover content={option.descripcion} title={false}>
                                            {`${option.idActivoCaract} - ${option.descripcion}`}
                                        </Popover>
                                    </Option>
                                )}
                            </Select>
                        </Col>
                        <Col span={5}>
                            <Select allowClear onChange={value => onChangeSelect(value, 'programa')}
                                style={{ width: '100%' }} placeholder="Selecciona un programa">
                                <Option value='Rutina'>Rutina</Option>
                                <Option value='Mantenimiento'>Mantenimiento</Option>
                                <Option value='Prueba'>Prueba</Option>
                            </Select>
                        </Col>
                        {/* <Col span={5}>
                            <Button onClick={onNuevaAsignacion} disabled={!permisosUsuario.nuevaAsignaciónDeServiciosPlanificados} className='nuevoUsuario' style={{ width: '100%' }}>Nueva Asignación</Button>
                        </Col> */}
                        <Col span={1}>
                            <Tooltip title='Nueva Asignación' placement="topRight">
                                <Button className="iconTer" shape="circle" onClick={onNuevaAsignacion} disabled={!permisosUsuario.nuevaAsignaciónDeServiciosPlanificados} icon={<PlusOutlined />}/>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row justify='space-between'>
                        <Col span={24}>
                            <Table
                                size='small'
                                rowKey='nomenclatura'
                                columns={columns}
                                dataSource={listaAsignacionesSPBusqueda}
                                loading={fetchingListaAsignacionesSPBusqueda}
                                locale={{ emptyText: 'Sin datos' }}
                                onChange={handleTableChange}
                                pagination={{ total: listaAsignacionesSPCantidad, simple: true, current: this.state.page }}
                            /> 
                        </Col>
                    </Row>
                </Form>
                </Card>
                <ModalDetalle /> 
                <ModalDetalleAsignaciones />  
                <ModalNuevaAsignacionSP busqueda={{idServicio,idUbicacion,programa, idActivoCaract}}/>   
                <ModalEditarAsignacion busqueda={{idServicio,idUbicacion,programa, idActivoCaract}}/>       
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
        fetchingUbicacionesSinHijos: state.SolicitudDeServiciosReducer.fetchingUbicacionesSinHijos,
        ubicacionesSinHijos: state.SolicitudDeServiciosReducer.ubicacionesSinHijos,
        fetchingListaActivosCaractFiltroAsignacionSP: state.AlmacenesReducer.fetchingListaActivosCaractFiltroAsignacionSP,
        listaActivosCaractFiltroAsignacionSP: state.AlmacenesReducer.listaActivosCaractFiltroAsignacionSP,
        fetchingListaAsignacionesSPBusqueda: state.ServiciosPlanificadosReducer.fetchingListaAsignacionesSPBusqueda,
        listaAsignacionesSPBusqueda: state.ServiciosPlanificadosReducer.listaAsignacionesSPBusqueda,
        listaAsignacionesSPCantidad: state.ServiciosPlanificadosReducer.listaAsignacionesSPCantidad,

        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        listaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.listaPartidasByServicio4Fallas,
        fetchingListaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.fetchingListaPartidasByServicio4Fallas,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda});
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []});
        },
        onListaUbicacionesFiltro: (busqueda) => {
            dispatch({ type: 'UBICACIONES_SIN_HIJOS_REQUEST', busqueda: busqueda });
        },
        onListaUbicacionesFiltroLimpiar: () => {
            dispatch({ type: 'UBICACIONES_SIN_HIJOS_SUCCESS', ubicacionesSinHijos: [] });
        },
        onListaActivosCaracteristicasFiltro: (busqueda) => {
            dispatch({ type: 'LISTA_ACTIVOS_CARACT_FILTRO_ASIGNACION_SP_REQUEST', busqueda: busqueda });
        },
        onListaActiviosCaracteristicasFiltroLimpiar: () => {
            dispatch({ type: 'LISTA_ACTIVOS_CARACT_FILTRO_ASIGNACION_SP_SUCCESS', listaActivosCaractFiltroAsignacionSP: [] });
        },
        onGetServiciosPlanificados: (idServicio, idPartida, programa, idUbicacion, idActivoCaract, page) => {
            dispatch({ type: "LISTA_ASIGNACIONES_SP_BUSQUEDA_REQUEST", idServicio: idServicio, idPartida, programa: programa, idUbicacion: idUbicacion, idActivoCaract:idActivoCaract, page: page});
        },
        onModalNuevaAsignacionSP: (page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_NUEVA_ASIGNACION_SP", showModalNuevaAsignacionSPlanificado: true, page: page, busqueda: busqueda});
        },
        onModalDetalle: (servicioPlanificadoSeleccionado) => {
            dispatch({ type: "SHOW_MODAL_DETALLE_RUTINA_SP", showModalDetalleRutinaSP: true, servicioPlanificadoSeleccionado: servicioPlanificadoSeleccionado});
        },
        onModalDetalleAsignaciones: (servicioPlanificadoSeleccionado) => {
            dispatch({ type: "SHOW_MODAL_DETALLE_ASIGNACIONES_SP", showModalDetalleAsignacionesSP: true, servicioPlanificadoSeleccionado: servicioPlanificadoSeleccionado});
        },
        onModalEditarAsignacionSP: (servicioPlanificadoSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_ASIGNACION_SP", showModalEditarAsignacionSPlanificado:true, servicioPlanificadoSeleccionado:servicioPlanificadoSeleccionado, page: page});
        },

        onGetServiciosActivos: () => {
            dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
        },
        onGetPartidasByIdServicio: (idServicio) => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_REQUEST', idServicio})
        },
        onCleanPartidasByIdServicio: () => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_SUCCESS', listaPartidasByServicio4Fallas: []})
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AsignacionServiciosPlanificados);