import React, { Component, message } from 'react';
import { Form, Input, Button, Modal, Col, Spin, Select } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Option } = Select;

class ModalEditarFamilia extends Component {
    formRef = React.createRef();

    state = {
        letras: true
    }

    render() {       
        const { fetchingEditarFamiliasDeActivos, showModalEditarFamiliasDeActivos, onEditarFamiliasDeActivos, 
            onShowModalEditarFamiliasDeActivos, familiasDeActivosSeleccionado, listaServiciosActivosF, 
            fetchingListaServiciosActivosF, limpiarFormulario, onLimpiarPartidasByServicio,
        } = this.props;

        console.log('FamiliasDeActivosSeleccionado en modal');
        console.log(familiasDeActivosSeleccionado);
        
        const onFinish = formulario => {
            const x = formulario['familia'];
            console.log('formulario');
            console.log(formulario);
            if (x>0){
                if(this.state.letras) {
                    onEditarFamiliasDeActivos(formulario, familiasDeActivosSeleccionado, this.props.page, listaServiciosActivosF);
                    this.formRef.current.resetFields();
                }  else {
                    message.error('El nombre de la familia sólo acepta letras')
                }
            }
            console.log('Success:', formulario);
            onEditarFamiliasDeActivos(formulario, familiasDeActivosSeleccionado, this.props.page, listaServiciosActivosF);
        };

        if (limpiarFormulario && limpiarFormulario === 'editarFamilia') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
            onLimpiarPartidasByServicio();
        }

        const handleCancel = () => {
            onLimpiarPartidasByServicio();
            onShowModalEditarFamiliasDeActivos()
        }

        const onServicioSeleccionado = (value) => {
            console.log('value', value)
            if(value){
                this.props.onListaPartidasByServicio(parseInt(value));
            }
            else{
                onLimpiarPartidasByServicio();
            }
        }

        return (
            <Modal
            visible={showModalEditarFamiliasDeActivos}
            title='Editar Familia'
            onCancel={handleCancel}
            footer={false}
            >
                <Spin spinning={fetchingEditarFamiliasDeActivos}>
                    {familiasDeActivosSeleccionado ? (
                    <Form {...layout}
                    name="formulario"
                    initialValues={familiasDeActivosSeleccionado}
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Form.Item
                            label="Familia"
                            name="familia"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Input maxLength={30} placeholder="Ingrese el nombre de familia, sólo debe de contener letras" pattern="^[a-zñA-ZÑ ÁÉÓÚÍáéóíú]+$" title="El nombre sólo debe contener letras"/>
                        </Form.Item>

                        <Form.Item
                            label="Tipo de familia"
                            name="tipoFamilia"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <Select 
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Selecciona el tipo de familia">
                                <Option value="Activos">Activos</Option>
                                <Option value="Productos e insumos">Productos e insumos</Option>
                                <Option value="Herramientas">Herramientas</Option>
                                <Option value="Rutinas">Rutinas</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Servicio"
                            name="nombre"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Select allowClear
                                style={{ width: '100%' }}
                                placeholder="Selecciona el servicio"
                                loading={fetchingListaServiciosActivosF}
                                onChange={onServicioSeleccionado}
                                showSearch={true}
                            >
                                {listaServiciosActivosF.map(option => <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>{option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}</Option>)}
                            </Select>
                        </Form.Item>

                        {this.props.listaPartidasByServicio.length ? (
                        <Form.Item
                            label="Partida"
                            name="partida"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                             <Select allowClear
                                style={{width: '100%'}}
                                placeholder="Selecciona una partida"
                                loading={this.props.fetchingListaPartidasByServicio}
                                showSearch={true}
                            >
                                {this.props.listaPartidasByServicio.map(option => <Option
                                    key={option.idPartida + ' - ' + option.nombre}>{option.idPartida + ' - ' + option.nombre}</Option>)}
                            </Select>
                        </Form.Item>
                        ):(
                        <Spin spinning={this.props.fetchingListaPartidasByServicio}>
                            <br/>
                        </Spin>
                        )}
                        
                        <Col span={12}>
                            <Button className="btnFiltrar" htmlType="submit">Editar Familia</Button>
                        </Col>
                    </Form>
                    ):false}
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarFamiliasDeActivos: state.CatalogosGeneralesReducer.fetchingEditarFamiliasDeActivos,
        showModalEditarFamiliasDeActivos: state.CatalogosGeneralesReducer.showModalEditarFamiliasDeActivos,
        fetchingListaPermisos: state.CatalogosGeneralesReducer.fetchingListaPermisos,
        listaPermisos: state.CatalogosGeneralesReducer.listaPermisos,
        familiasDeActivosSeleccionado: state.CatalogosGeneralesReducer.familiasDeActivosSeleccionado,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        page: state.CatalogosGeneralesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaPartidasByServicio: state.EvaluacionDeLaGestionReducer.listaPartidasByServicio,
        fetchingListaPartidasByServicio: state.EvaluacionDeLaGestionReducer.fetchingListaPartidasByServicio,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarFamiliasDeActivos: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_FAMILIAS_ACTIVOS', showModalEditarFamiliasDeActivos: false, FamiliasDeActivosSeleccionado: null})
        },
        onEditarFamiliasDeActivos: ( formulario, familiasDeActivosSeleccionado, page, listaServiciosActivosF ) => {
            dispatch({ type: "EDITAR_FAMILIAS_ACTIVOS_REQUEST" , formulario: formulario, familiasDeActivosSeleccionado: familiasDeActivosSeleccionado, page: page, listaServiciosActivosF: listaServiciosActivosF });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        },
        onListaPartidasByServicio: (idServicio) => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_REQUEST', idServicio: idServicio})
        },
        onLimpiarPartidasByServicio: () => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_SERVICIO_SUCCESS', listaPartidasByServicio:[] })
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarFamilia);