import React, { useEffect, useRef, useState } from 'react';
import {connect} from 'react-redux';
import { Alert, Button, Row, Col,Select, Form, Input, DatePicker, TimePicker, Tooltip, message} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const {Option} = Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const FilterSection = props => {
     let formRef = useRef();
    
     const {
          checkboxData, order, onGetReporteDinamico,formularioReporte,listaServiciosActivosF,
          handleSteps, onLimpiarFormulario,
          onGetServiciosActivos, onGetReporteDinamicoAgrupado, fetchingListaProveedores, listaProveedores, listaParamProveedores, fetchingListaParamProveedores, onListaProveedoresFiltro, onListaProveedoresFiltroLimpiar, fetchingListaGrupoServiciosByIdUsuario, listaGrupoServiciosByIdUsuario, onListaServiciosByIdsGerencias, fetchingListaClasificacionesActivos, listaClasificacionesActivos, fetchingListaServiciosByIdsGerencias, listaServiciosByIdsGerencias, listaGruposServicios, fetchingListaGruposServicios, listaServiciosByIdsGrupos, fetchingListaServiciosByIdsGrupos, serviciosByIdUsuarioGrupos,  serviciosByIdUsuarioGerencia,

          fetchingListaGerenciasByIdUsuario, listaGerenciasByIdUsuario, onListaServiciosByIdsGrupos
     } = props;

     let permisosUsuario = sessionStorage.getItem('permisosUsuario');
     if (permisosUsuario[0]) {
          permisosUsuario = JSON.parse(permisosUsuario);
     }

     const [boolGrupo, setBoolGrupo] = useState(true);
     const [boolClasificacion, setBoolClasificacion] = useState(true);

     useEffect(() => {
          onGetServiciosActivos();
     }, [])

     let userInfo = sessionStorage.getItem('userInfo');
     if(userInfo[0]) {
          userInfo = JSON.parse(userInfo);
     }

     const onHandleSteps = value => {
          handleSteps(value);
          onLimpiarFormulario();
          setBoolGrupo(false);
          setBoolClasificacion(false);
     }

     const onFinish = formulario => {
          console.log('order', order)
          console.log('formulario', formulario);
          onGetReporteDinamico(formulario, 1, order, checkboxData);
          onGetReporteDinamicoAgrupado(formulario, 1, null);
          handleSteps('siguiente');
     }

     const buscarProveedor = value => {
          if(value.length>=3){
               if(value){
                  onListaProveedoresFiltro(value)
               } else {
                  message.warning('No se encontró proveedor con esas palabras')
               }
          } else {
              onListaProveedoresFiltroLimpiar();
          } 
     };

     const onGrupoSeleccionado = (value) => {
          formRef.current.setFieldsValue({'IdsGerencias': undefined});
          if(value.length > 0){
               formRef.current.setFieldsValue({'IdsServicios': undefined});
               setBoolGrupo(false);
               onListaServiciosByIdsGrupos(value);
          } else {
               setBoolGrupo(true);
               formRef.current.setFieldsValue({'idsGruposServicios': undefined, 'IdsServicios': undefined});
          }
     };

     const onClasificacionSeleccionado = (value) => {
          formRef.current.setFieldsValue({'idsGruposServicios': undefined});
          if(value.length > 0){
               formRef.current.setFieldsValue({'IdsServicios': undefined});
               setBoolClasificacion(false);
               onListaServiciosByIdsGerencias(value);
          } else {
               setBoolClasificacion(true);
               formRef.current.setFieldsValue({'IdsGerencias': undefined, 'IdsServicios': undefined});
          }
     };

     return (
          <>
               <Row style={{padding:'1em'}}>
                    <Col span={24}>
                         <Alert message="Selecciona los filtros a aplicar en el reporte." type="info" style={{ marginBottom: '16px' }} />
                    </Col>
               </Row>
               <Form {...layout}
                    name='formulario'
                    onFinish={onFinish}
                    
                    initialValues={formularioReporte}
                    ref={formRef}
               >
                    <Row justify='space-between' gutter={[8,8]} style={{padding:'1em'}}>
                         <Col span={11}>
                              <Form.Item
                              label="Gerencia"
                              name="IdsGerencias"
                              rules={[{ required: false, message: 'Favor de llenar el campo' }]}
                              >
                              {permisosUsuario.especialRecursosHumanos ? (
                              <Select
                                   allowClear
                                   style={{ width: '100%' }} 
                                   placeholder="Selecciona las gerencias"
                                   loading={fetchingListaClasificacionesActivos}
                                   mode='multiple'
                                   onChange={onClasificacionSeleccionado}
                                   disabled={!boolGrupo}
                              >
                                   {listaClasificacionesActivos.map(item => <Option key={item.idClasificacionGerencia + ' - ' + item.nombreGerencia}>{item.idClasificacionGerencia + ' - ' + item.nombreGerencia}</Option>)}
                              </Select>
                              ):(
                              <Select allowClear
                                   style={{ width: '100%' }}
                                   placeholder="Selecciona una gerencia"
                                   loading={fetchingListaGerenciasByIdUsuario}
                                   mode='multiple'
                                   onChange={onClasificacionSeleccionado}
                                   disabled={!boolGrupo}
                              >
                                   {listaGerenciasByIdUsuario.map(option => 
                                   <Option key={option.idClasificacionGerencia + ' - ' + option.nombreGerencia}>
                                        <Tooltip title={option.idClasificacionGerencia + ' - ' + option.nombreGerencia}>
                                             {option.idClasificacionGerencia + ' - ' + option.nombreGerencia}
                                        </Tooltip>
                                   </Option>
                                   )}
                              </Select>
                              )}
                              </Form.Item>
                         </Col>

                         <Col span={11}>
                              <Form.Item
                                   name='idsGruposServicios'
                                   label='Grupo de Servicios'
                                   rules={[{ required: false, message: 'Favor de llenar el campo' }]}
                              >
                                   {permisosUsuario.especialRecursosHumanos ? (
                                   <Select
                                        allowClear
                                        style={{ width: '100%' }} 
                                        placeholder="Selecciona un grupo de servicios"
                                        loading={fetchingListaGruposServicios}
                                        mode='multiple'
                                        onChange={onGrupoSeleccionado}
                                        disabled={!boolClasificacion}
                                   >
                                        {listaGruposServicios.map(option => <Option key={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}><Tooltip title={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}>{option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}</Tooltip></Option>)}
                                   </Select>
                                   ):(
                                   <Select
                                        mode='multiple'
                                        allowClear
                                        style={{ width: '100%' }} placeholder="Selecciona un grupo de servicios"
                                        loading={fetchingListaGrupoServiciosByIdUsuario}
                                        onChange = {onGrupoSeleccionado}
                                        disabled={!boolClasificacion}
                                   >
                                        {listaGrupoServiciosByIdUsuario.map(option => <Option key={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}><Tooltip title={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}>{option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}</Tooltip></Option>)}
                                   </Select>
                                   )}
                              </Form.Item>
                         </Col>

                         <Col span={11}>
                              <Form.Item
                                   name='IdsServicios'
                                   label='Servicio'
                                   rules={[{ required: true, message: 'Favor de seleccionar un servicio', }]}
                              >
                                   {permisosUsuario.especialGestiónDeCalidad ? (
                                   <Select
                                        allowClear
                                        style={{ width: '100%' }} placeholder="Selecciona los servicios"
                                        mode='multiple'
                                        disabled={boolGrupo && boolClasificacion}
                                        loading={boolClasificacion ? fetchingListaServiciosByIdsGrupos : fetchingListaServiciosByIdsGerencias}
                                   >
                                        {boolClasificacion ? (<>
                                             { listaServiciosByIdsGrupos ? (<>
                                                  {listaServiciosByIdsGrupos.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nombre}</Option>)}
                                             </>):false }
                                        </>) : (<>
                                             { listaServiciosByIdsGerencias ? (<>
                                                  {listaServiciosByIdsGerencias.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nombre}</Option>)}
                                             </>):false }
                                        </>)}
                                   </Select>
                                   ):(
                                   <Select
                                        mode='multiple'
                                        allowClear
                                        style={{ width: '100%' }} 
                                        placeholder="Selecciona un servicio"
                                        disabled={boolGrupo && boolClasificacion}
                                        loading={boolClasificacion ? fetchingListaServiciosByIdsGrupos : fetchingListaServiciosByIdsGerencias}
                                   >
                                        {boolClasificacion ? (<>
                                             { serviciosByIdUsuarioGrupos ? (<>
                                                  {serviciosByIdUsuarioGrupos.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nombre}</Option>)}
                                             </>):false }
                                        </>) : (<>
                                             { serviciosByIdUsuarioGerencia ? (<>
                                                  {serviciosByIdUsuarioGerencia.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nombre}</Option>)}
                                             </>):false }
                                        </>)}
                                   </Select>
                                   )}
                              </Form.Item>
                         </Col>
                         
                         {checkboxData.nombreProveedorC ? (
                         <Col span={11}>
                              <Form.Item
                                   name='idsProveedores'
                                   label='Nombre del proveedor'
                              >
                                   <Select
                                        mode='multiple'
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Escribe el proveedor a buscar"
                                        loading={fetchingListaParamProveedores}
                                        showSearch={true}
                                        filterOption={false}
                                        onSearch={buscarProveedor}
                                        notFoundContent={null}
                                   >
                                        {listaParamProveedores.map(option => <Option key={option.idProveedor + ' - ' + option.razonSocial + ' (' + option.nombre + ')'}>{option.idProveedor + ' - ' + option.razonSocial + ' (' + option.nombre + ')'}</Option>)}
                                   </Select>
                              </Form.Item>
                         </Col>
                         ):false}

                         {checkboxData.fechaIngresoC ? (
                         <Col span={11}>
                              <Form.Item
                                   label="Fecha ingreso"
                                   name="fechaIngreso"
                              >
                                   <RangePicker
                                        format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio", "Fecha fin"]}
                                        // disabledDate={disabledRange}
                                   />
                              </Form.Item>
                         </Col>
                         ) : false}

                         { checkboxData.tipoTurnoC ? (
                              <Col span={11}>
                                   <Form.Item
                                        label="Tipo de turno"
                                        name="tipoHorario"
                                   >
                                        <Select
                                             allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Selecciona el tipo de horario"
                                        >
                                             <Option key='Corrido'>Corrido</Option>
                                             <Option key='Libre'>Libre</Option>
                                        </Select>
                                   </Form.Item>
                              </Col>
                         ): false }

                         { checkboxData.horarioEntradaC || checkboxData.horarioSalidaC ? (
                              <Col span={11}>
                                   <Row justify='space-between'>
                                   { checkboxData.horarioEntradaC ? (
                                        <Col span={11}>
                                             <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                                  <Form.Item
                                                       label="Horario entrada"
                                                       name="horarioEntrada"
                                                  >
                                                       <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de entrada"]} style={{width: '100%'}}/>
                                                  </Form.Item>
                                             </Tooltip>
                                        </Col>
                                   ): false }
                                   { checkboxData.horarioSalidaC ? (
                                        <Col span={11}>
                                             <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                                  <Form.Item
                                                       label="Horario salida"
                                                       name="horarioSalida"
                                                  >
                                                       <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de salida"]} style={{width: '100%'}}/>
                                                  </Form.Item>
                                             </Tooltip>
                                        </Col>
                                   ): false }
                                   </Row>
                              </Col>
                         ): false }

                         { checkboxData.salidaComidaC || checkboxData.regresoComidaC ? (
                              <Col span={11}>
                                   <Row justify='space-between'>
                                   { checkboxData.salidaComidaC ? (
                                        <Col span={11}>
                                             <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                                  <Form.Item
                                                       label="Salida Comida"
                                                       name="salidaComida"
                                                  >
                                                       <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de entrada"]} style={{width: '100%'}}/>
                                                  </Form.Item>
                                             </Tooltip>
                                        </Col>
                                   ): false }
                                   { checkboxData.regresoComidaC ? (
                                        <Col span={11}>
                                             <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                                  <Form.Item
                                                       label="Regreso comida"
                                                       name="regresoComida"
                                                  >
                                                       <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de salida"]} style={{width: '100%'}}/>
                                                  </Form.Item>
                                             </Tooltip>
                                        </Col>
                                   ): false }
                                   </Row>
                              </Col>
                         ): false }

                         { checkboxData.activoC ? (
                              <Col span={11}>
                                   <Form.Item
                                        label="Condición"
                                        name="activo"
                                   >
                                        <Select
                                             allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Selecciona un estatus"
                                        >
                                             <Option key='true'>Activo</Option>
                                             <Option key='false'>Inactivo</Option>
                                        </Select>
                                   </Form.Item>
                              </Col>
                         ): false }
                    </Row>
                    <Row style={{padding:'1em'}}>
                         <Col span={6}>
                         <Button className="btnFiltrar" onClick={() => onHandleSteps('anterior')} style={{ width: '100%', marginTop: '20px' }}>Anterior</Button>
                         </Col>
                         <Col span={6} offset={12}>
                         <Button className="btnFiltrar"  htmlType='submit' style={{ width: '100%', marginTop: '20px' }}>Siguiente</Button>
                         </Col>
                    </Row>
               </Form>
          </>
     )
}

const mapStateToProps = state => {
     return {

          formularioReporte: state.RecursosHumanosReducer.formularioReporte,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,

          fetchingListaProveedores: state.InformacionOperativaReducer.fetchingListaProveedores,
          listaProveedores: state.InformacionOperativaReducer.listaProveedores,

          listaParamProveedores: state.InformacionOperativaReducer.listaParamProveedores,
          fetchingListaParamProveedores: state.InformacionOperativaReducer.fetchingListaParamProveedores,

          listaGrupoServiciosByIdUsuario: state.RecursosHumanosReducer.listaGrupoServiciosByIdUsuario,
          fetchingListaGrupoServiciosByIdUsuario: state.RecursosHumanosReducer.fetchingListaGrupoServiciosByIdUsuario,

          fetchingListaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.fetchingListaClasificacionesActivos,
          listaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.listaClasificacionesActivos,

          listaServiciosByIdsGerencias: state.CatalogosGeneralesReducer.listaServiciosByIdsGerencias,
          fetchingListaServiciosByIdsGerencias: state.CatalogosGeneralesReducer.fetchingListaServiciosByIdsGerencias,

          fetchingListaGruposServicios: state.CatalogosGeneralesReducer.fetchingListaGruposServicios,
          listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,

          fetchingListaGerenciasByIdUsuario: state.RecursosHumanosReducer.fetchingListaGerenciasByIdUsuario,
          listaGerenciasByIdUsuario: state.RecursosHumanosReducer.listaGerenciasByIdUsuario,

          listaServiciosByIdsGrupos: state.CatalogosGeneralesReducer.listaServiciosByIdsGrupos,
          fetchingListaServiciosByIdsGrupos: state.CatalogosGeneralesReducer.fetchingListaServiciosByIdsGrupos,

          serviciosByIdUsuarioGrupos: state.CatalogosGeneralesReducer.serviciosByIdUsuarioGrupos,
          serviciosByIdUsuarioGerencia: state.CatalogosGeneralesReducer.serviciosByIdUsuarioGerencia,
     }
}

const mapDispatchToProps = dispatch => {
     return {
          
          onGetReporteDinamico: (formulario, page, order, checkboxData) => {
               dispatch({ type: 'LISTA_REPORTE_DINAMICO_RH_BUSQUEDA_REQUEST', formulario, page, order, checkboxData});
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO_RH', formularioReporte: {},listaReporteDinamicoBusqueda: [], listaReporteDinamicoBusquedaCantidad: null})
          },
          onGetServiciosActivos: () => {
               dispatch({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST'})
          },
          onGetReporteDinamicoAgrupado: (formulario, page, agrupado) => {
               dispatch({ type: 'REPORTE_DINAMICO_AGRUPADO_RH_REQUEST', formulario, page, agrupado});
          },
          onListaProveedoresFiltro: (busqueda) => {
               dispatch({ type: "LISTA_PROVEEDORES_PARAM_REQUEST", busqueda })
          },
          onListaProveedoresFiltroLimpiar: () => {
               dispatch({ type: "LISTA_PROVEEDORES_PARAM_SUCCESS", listaParamProveedores: []})
          },
          onListaServiciosByIdsGerencias: (idsGerencias) => {
               dispatch({ type: 'LISTA_SERVICIOS_BY_IDGERENCIAS_REQUEST', idsGerencias });
          },
          onListaServiciosByIdsGrupos: (idsGruposSer) => {
               dispatch({ type: 'LISTA_SERVICIOS_BY_IDSGRUPOS_REQUEST', idsGruposSer });
          },  
     }
}

export default connect (mapStateToProps, mapDispatchToProps) (FilterSection);