import React, { useEffect, useRef, useState } from 'react';
import {connect} from 'react-redux';
import { Alert, Button, Row, Col, Select, Form, DatePicker, TimePicker, Tooltip, message} from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const {Option} = Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

let servicioLocal = undefined;

const FilterSection = props => {
     let formRef = useRef();
    
     const {
          checkboxData, order, onGetReporteDinamico,formularioReporte,listaServiciosActivosF, fetchingListaServiciosActivosF, handleSteps, onLimpiarFormulario,
          onGetServiciosActivos, listaAlmacenesActivos, onListaFamilias, fetchingListaFamiliasActivasServ, fetchingListaParamProveedores, listaFamiliasActivasServ, onSubfamiliasPorServicio, fetchingListaSubfamiliasServicio, listaSubfamiliasServicio, onGetAlmacenesActivos, onGetServiciosAlmacen, listaServiciosbyAlmacen, fetchingListaAlmacenesActivos, fetchingListaServiciosbyAlmacen, onCleanFamilias, onCleanSubfamiliasPorServicio, onCleanServiciosAlmacen, fetchingListaTiposFoliosMovimientosActivos, listaTiposFoliosMovimientosActivos, onGetTiposFolio
     } = props;

     let permisosUsuario = sessionStorage.getItem('permisosUsuario');
     if (permisosUsuario[0]) {
          permisosUsuario = JSON.parse(permisosUsuario);
     }

     const [boolGrupo, setBoolGrupo] = useState(true);
     const [disabledUnidades, setDisabledUnidades] = useState(true);
     const [tipoProgramaRutina, setTipoProgramaRutina] = useState(true);
     const [boolClasificacion, setBoolClasificacion] = useState(true);

     useEffect(() => {
          onGetServiciosActivos();
          onGetAlmacenesActivos();
          onGetTiposFolio();
     }, [])

     let userInfo = sessionStorage.getItem('userInfo');
     if(userInfo[0]) { userInfo = JSON.parse(userInfo); }

     const serviciosByIdUsuario = userInfo[0].serviciosByIdUsuario ? userInfo[0].serviciosByIdUsuario : [];

     const onHandleSteps = value => {
          handleSteps(value);
          onLimpiarFormulario();
          setBoolGrupo(false);
          setBoolClasificacion(false);
     }

     const onFinish = formulario => {
          console.log('order', order)
          console.log('formulario', formulario);
          onGetReporteDinamico(formulario, 1, order);
          //onGetReporteDinamicoAgrupado(formulario, 1, null);
          handleSteps('siguiente');
     }

     const servicioSeleccionado = (value) => {
          console.log('value', value)
          if(value){
               formRef.current.setFieldsValue({'idFamilia': undefined, 'idSubFamilia': undefined});
               onListaFamilias(value.split(' - ')[0]); onSubfamiliasPorServicio(value.split(' - ')[0]);
               servicioLocal = value.split(' - ')[0];
          } else {
               formRef.current.setFieldsValue({'idFamilia': undefined, 'idSubFamilia': undefined});
               onCleanFamilias(); onCleanSubfamiliasPorServicio();
               servicioLocal = undefined;
          }
     };

     const onChangeAlmacen = (value) => {
          console.log('value', value)
          let almacenesSelect = [];

          if(value){
               for(let j in listaAlmacenesActivos){
                    if(value.split(' - ')[0] == listaAlmacenesActivos[j].idAlmacen){
                         almacenesSelect.push(listaAlmacenesActivos[j])
                    }
               }
               onGetServiciosAlmacen(false, almacenesSelect, listaAlmacenesActivos);
               formRef.current.setFieldsValue({'idFamilia': undefined, 'idSubFamilia': undefined, 'idServicio': undefined});
               onCleanFamilias(); onCleanSubfamiliasPorServicio(); onCleanServiciosAlmacen();
          } else {
               formRef.current.setFieldsValue({'idFamilia': undefined, 'idSubFamilia': undefined, 'idServicio': undefined});
               onCleanFamilias(); onCleanSubfamiliasPorServicio(); onCleanServiciosAlmacen();
          }

          
     };

     const seleccionFamSubfam = (value, str) => {
          if(value){
               if(str === 'familia'){
                    formRef.current.setFieldsValue({'idSubFamilia': undefined});
                    onCleanSubfamiliasPorServicio();
               } else {
                    formRef.current.setFieldsValue({'idFamilia': undefined});
                    onCleanFamilias();
               }
          } else {
               if(servicioLocal){
                    onListaFamilias(servicioLocal); onSubfamiliasPorServicio(servicioLocal);
               }
          }
     };

     return (
          <>
               <Row style={{padding:'1em'}}>
                    <Col span={24}>
                         <Alert message="Selecciona los filtros a aplicar en el reporte." type="info" style={{ marginBottom: '16px' }} />
                    </Col>
               </Row>
               <Form {...layout}
                    name='formulario'
                    onFinish={onFinish}
                    initialValues={formularioReporte}
                    ref={formRef}
               >
                    <Row justify='space-between' gutter={[8,8]} style={{padding:'1em'}}>
                         <Col span={11}>
                                <Form.Item
                                        label="Almacen"
                                        name="idAlmacen"
                                    >
                                        <Select
                                             // mode='multiple'
                                             allowClear showSearch
                                             style={{ width: '100%' }}
                                             placeholder="Seleccione un almacén"
                                             loading={fetchingListaAlmacenesActivos}
                                             onChange={onChangeAlmacen}
                                        >    
                                             
                                             {listaAlmacenesActivos.map(option => <Option key={option.idAlmacen + ' - ' +option.nombreC}>{option.idAlmacen + ' - ' +option.nombreC}</Option>)}

                                        </Select>
                                </Form.Item>
                         </Col>

                         

                         <Col span={11}>
                              <Form.Item
                                   name='idServicio'
                                   label='Servicio'
                              >
                                   {/* {permisosUsuario.especialAlmacén ? (
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el servicio"
                                            loading={fetchingListaServiciosActivosF}
                                            showSearch
                                            onChange={(value) => servicioSeleccionado(value, "servicio")}
                                        >
                                            {listaServiciosActivosF.map(option => <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>{option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}</Option>)}
                                        </Select>
                                        ):(
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el servicio"
                                            showSearch
                                            onChange={(value) => servicioSeleccionado(value, "servicio")}
                                        >
                                            {serviciosByIdUsuario.map(option => <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>{option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}</Option>)}
                                        </Select>
                                   )} */}
                                   <Select
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el servicio"
                                        loading={fetchingListaServiciosbyAlmacen}
                                        showSearch allowClear
                                        onChange={(value) => servicioSeleccionado(value, "servicio")}
                                        disabled={!(listaServiciosbyAlmacen.length > 0)}
                                   >
                                        {listaServiciosbyAlmacen.map(option => <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>{option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}</Option>)}
                                   </Select>
                              </Form.Item>
                         </Col>
                         
                         <Col span={11}>
                                <Form.Item
                                        label="Familia"
                                        name="idFamilia"
                                    >
                                        <Select allowClear showSearch
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona la familia de activos"
                                            loading={fetchingListaFamiliasActivasServ}
                                            disabled={!(listaFamiliasActivasServ.length > 0)}
                                            onChange={(value) => seleccionFamSubfam(value, "familia")}
                                        >
                                                 {listaFamiliasActivasServ.map(option => <Option key={option.idFamilia + ' - ' + option.familia + ' (' + option.tipoFamilia + ')'}>{option.idFamilia + ' - ' + option.familia + ' (' + option.tipoFamilia + ')'}</Option>)}
                                        </Select>
                                </Form.Item>
                         </Col>
                         <Col span={11}>
                                <Form.Item
                                        label="Subfamilia"
                                        name="idSubFamilia"
                                    >
                                       <Select
                                            allowClear showSearch
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona la subfamilia"
                                            loading={fetchingListaSubfamiliasServicio}
                                            disabled={!(listaSubfamiliasServicio.length > 0)}
                                            onChange={(value) => seleccionFamSubfam(value, "subfamilia")}
                                        >
                                            {listaSubfamiliasServicio.map(option => <Option key={option.idSubFamilia + ' - ' + option.subFamilia}>{option.idSubFamilia + ' - ' + option.subFamilia}</Option>)}
                                        </Select>
                                </Form.Item>
                         </Col>
                         <Col span={11}>
                              <Form.Item
                                   label="Rango de Fecha"
                                   name="rangoFechas"
                                   rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                              >
                                   <RangePicker
                                        format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Fecha inicio", "Fecha fin"]}
                                        // disabledDate={disabledRange}
                                   />
                              </Form.Item>
                         </Col>

                         <Col span={11}>
                              <Form.Item
                                   name='idTipoMovimiento'
                                   label='Tipo de folio de movimiento'
                              >
                                   <Select allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Seleccione el tipo de folio"
                                    loading={fetchingListaTiposFoliosMovimientosActivos}
                                >
                                    {listaTiposFoliosMovimientosActivos.map(option => <Option key={option.idTipoFolio + " - " + option.nomenclatura + ": " + option.nombre}>{ option.idTipoFolio + " - " + option.nomenclatura + ": " + option.nombre }</Option>)}
                                </Select>
                              </Form.Item>
                         </Col>
                    </Row>
                    <Row style={{padding:'1em'}}>
                         <Col span={6}>
                         </Col>
                         <Col span={6} offset={12}>
                              <Button className="btnFiltrar"  htmlType='submit' style={{ width: '100%', marginTop: '20px' }}>Siguiente</Button>
                         </Col>
                    </Row>
                    
               </Form>
          </>
     )
}

const mapStateToProps = state => {
     return {

          formularioReporte: state.AlmacenesReducer.formularioReporte,

          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,

          fetchingListaFamiliasActivasServ: state.CatalogosGeneralesReducer.fetchingListaFamiliasActivasServ,
          listaFamiliasActivasServ: state.CatalogosGeneralesReducer.listaFamiliasActivasServ,

          fetchingListaSubfamiliasServicio: state.CatalogosGeneralesReducer.fetchingListaSubfamiliasServicio,
          listaSubfamiliasServicio: state.CatalogosGeneralesReducer.listaSubfamiliasServicio,

          fetchingListaAlmacenesActivos: state.AlmacenesReducer.fetchingListaAlmacenesActivos,
          listaAlmacenesActivos: state.AlmacenesReducer.listaAlmacenesActivos,

          listaServiciosbyAlmacen: state.AlmacenesReducer.listaServiciosbyAlmacen,
          fetchingListaServiciosbyAlmacen: state.AlmacenesReducer.fetchingListaServiciosbyAlmacen,

          fetchingListaTiposFoliosMovimientosActivos: state.AlmacenesReducer.fetchingListaTiposFoliosMovimientosActivos,
          listaTiposFoliosMovimientosActivos: state.AlmacenesReducer.listaTiposFoliosMovimientosActivos,
     }
}

const mapDispatchToProps = dispatch => {
     return {
          onGetReporteDinamico: (formulario, page, order) => {
               dispatch({ type: 'LISTA_REPORTE_EXISTENCIAS_REQUEST', formulario, page, order});
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO_RH', formularioReporte: {},listaReporteDinamicoBusqueda: [], listaReporteDinamicoBusquedaCantidad: null})
          },
          onGetServiciosActivos: () => {
               dispatch({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST'})
          },

          onListaFamilias: (idServicio) => {
               dispatch({ type: 'LISTA_FAMILIAS_ACTIVAS_SERVICIO_REQUEST', idServicio: idServicio })
          },
          onCleanFamilias: () => {
               dispatch({ type: 'LISTA_FAMILIAS_ACTIVAS_SERVICIO_SUCCESS', listaFamiliasActivasServ: [] })
          },

          onSubfamiliasPorServicio: (idServicio) => {
               dispatch({ type: 'LISTA_SUBFAMILIAS_POR_SERVICIO_REQUEST', idServicio });
          },
          onCleanSubfamiliasPorServicio: () => {
               dispatch({ type: 'LISTA_SUBFAMILIAS_POR_SERVICIO_SUCCESS', listaSubfamiliasServicio: [] });
          },

          onGetAlmacenesActivos: () => {
               dispatch({ type: 'ALMACENES_ACTIVOS_BUSQUEDA_REQUEST' })
          },

          onGetServiciosAlmacen: (todosAlmacenes, almacenesSeleccionados, listaAlmacenesActivos, todosAlmacenesSeleccionados) => {
               dispatch({ type: 'LISTA_SERVICIOS_ALMACEN_REQUEST', todosAlmacenes, almacenesSeleccionados, listaAlmacenesActivos, todosAlmacenesSeleccionados })
          },
          onCleanServiciosAlmacen: () => {
               dispatch({ type: 'LISTA_SERVICIOS_ALMACEN_SUCCESS', listaServiciosbyAlmacen: [] })
          },

          onGetTiposFolio: () => {
               dispatch({ type: "LISTA_TIPOS_FOLIOS_ACTIVOS_REQUEST"});
          },
     }
}

export default connect (mapStateToProps, mapDispatchToProps) (FilterSection);