import React, { Component } from 'react';
import { Form, Input, Button, Modal, Row, Col, Spin, Select, DatePicker, Radio, Divider, message, Popover, notification } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalNuevoProveedor extends Component {
    formRef = React.createRef();
    state = {
        persona: 'fisica',
        showProvPartida: false,
        asignado: 'Todo',
        disabledPartida: true,
        disabledArea: true,
        idsServiciosSeleccionados: [],
        servSeleccionandos: [],
    };

    componentDidMount() {
        this.props.onObtenerAreasFuncionales();
        this.props.onGetListaServicios(null);
    };

    render() {
        const { fetchingNuevoProveedor, showModalNuevoProveedor, onNuevoProveedor, onShowModalNuevoProveedor,
            listaDominiosActivos, fetchingListaDominiosActivos, listaContratos, fetchingListaExpedientes,
            listaExpedientes, listaServiciosActivosForProveedor, fetchingListaServiciosActivosForProveedor,limpiarFormulario, onLimpiarFormulario,

            listaAreasProveedores, fetchingListaAreasProveedores, onObtenerPartidasByidServicio, listaPartidasByServicio, fetchingListaPartidasByServicio, actualizarAsig, onLimpiarListaPartidas, listaPartidasByServicioWithout, listaServiciosActivosF, listaAreasFuncionalesActivas,
            fetchingListaAreasFuncionalesActivas
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            const rangeValue = formulario['rangoVigencia'];

            if(rangeValue){
                formulario.rangoVigencia = rangeValue[0].format('YYYY-MM-DD') + "," + rangeValue[1].format('YYYY-MM-DD');
                let listaAsignacionProveedor = [];

                for (var key in formulario) {
                    if (Object.prototype.hasOwnProperty.call(formulario, key)) {
                        let idServicio = key.split('-');
                        if (idServicio.length === 2) {
                            let idCaptura = idServicio[1];
                            let campo = idServicio[0];
                            let valor = formulario[key];
        
                            let capturaFormulario = {[campo]: valor, 'idServicio': parseInt(idCaptura)};
                            listaAsignacionProveedor.push(capturaFormulario);
                        }
                    }
                }

                onNuevoProveedor(formulario, listaDominiosActivos, listaExpedientes, listaContratos, listaServiciosActivosForProveedor, this.props.page, listaPartidasByServicioWithout, listaAreasFuncionalesActivas, listaAsignacionProveedor );

                this.setState({ showProvPartida: false, asignado: 'Todo', disabledPartida: true, disabledArea: true });
            }
        };

        

        if (limpiarFormulario && limpiarFormulario === 'crearProveedor') {
            this.formRef.current.resetFields();
            onLimpiarFormulario();
            onLimpiarListaPartidas();
        }

        const handleCancel = () => {
            onShowModalNuevoProveedor();
            onLimpiarListaPartidas();
            this.formRef.current.resetFields();
            this.setState({ showProvPartida: false, asignado: 'Todo', disabledPartida: true, disabledArea: true });
        }
          
        const cambiarPersona = (persona) => {
            this.setState({
                persona: persona.target.value
            });
        }

        const onServicioSel = (value) => {
            console.log('servicios seleccionados', value)
            if(value[0]){
                onObtenerPartidasByidServicio(value, listaServiciosActivosForProveedor, listaServiciosActivosF);

                let serviciosLocal = [];
                let nombreServiciosLocal = [];
                let serviciosArray = [];
                for(let i = 0; i < value.length; i++){
                    serviciosLocal.push(parseInt(value[i]));
                    this.formRef.current.resetFields(['partida-' + value[i].split(' - ')[0], 'area-' + value[i].split(' - ')[0]]);
                    this.formRef.current.setFieldsValue({['asignado-' + value[i].split(' - ')[0]]: 'Todo'})
                    for(let j = 0; j < listaServiciosActivosForProveedor.length; j++){
                        if(parseInt(value[i]) === listaServiciosActivosForProveedor[j].idServicio){
                            nombreServiciosLocal.push(listaServiciosActivosForProveedor[j].nombre);
                            serviciosArray.push(listaServiciosActivosForProveedor[j]);

                            let showMSG = false;
                            let proveedores = [];
                            let servicio = '';

                            for(let j in listaServiciosActivosForProveedor){
                                if(parseInt(value[i]) === listaServiciosActivosForProveedor[j].idServicio){
                                    if(listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio){
                                        showMSG = true;
                                        servicio = listaServiciosActivosForProveedor[j].nomenclatura + ' - ' + listaServiciosActivosForProveedor[j].nombre;
                                        for(let k in listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio){
                                            proveedores.push(' ' + listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio[k].proveedorByIdProveedor.nombre + ', ');
                                        }
                                    }
                                }
                            }

                            let uniqueProveedores = proveedores.reduce(function(a,b){
                                if (a.indexOf(b) < 0 ) a.push(b);
                                return a;
                            },[]);

                            while(showMSG){
                                // message.info(
                                //     <p> El/Los proveedor/es: <b>{uniqueProveedores}</b> ya se le han asignado áreas del servicio <b>{servicio}</b>. Si se genera la asignación a este proveedor el o los anteriores las perderan</p>, 10
                                // );
                                notification.warning({
                                    duration: 5,
                                    placement: 'topLeft',
                                    message: servicio,
                                    description:
                                        <p> El/Los proveedor/es: <b>{uniqueProveedores}</b> ya se le han asignado áreas de este servicio. Si se genera la asignación a este proveedor el o los anteriores las perderan.</p>,
                                });
                                showMSG = false
                            }
                        }
                    }
                }

                this.setState({ showProvPartida: true, idsServiciosSeleccionados: serviciosLocal, servSeleccionandos: serviciosArray });

            } else {
                this.setState({ showProvPartida: false });
            }
        };

        const cambiarAsignacion = (asignado, idServicio, key) => {

            let listaPartidasActualizada = listaPartidasByServicio;
            listaPartidasActualizada[key][0].asignado = asignado.target.value;

            actualizarAsig(listaPartidasActualizada, listaPartidasByServicioWithout);

            // for(let i in listaPartidasActualizada){
            //     for(let j in listaPartidasActualizada[i]){
            //         if(listaPartidasActualizada[i][j].idServicio === idServicio){
            //             let areasTxt = [];

            //             for(let k in listaPartidasActualizada[i][j].areas){
            //                 areasTxt.push(listaPartidasActualizada[i][j].areas[k].idAreaFuncional + ' - ' + listaPartidasActualizada[i][j].areas[k].codigoArea + ', ' + listaPartidasActualizada[i][j].areas[k].areaFuncional);
            //             }

            //             console.log('areasTxt', areasTxt);
            //             listaPartidasActualizada[i][j].areasTxt = areasTxt;
            //         }
            //     }
            // }

            // console.log('listaPartidasActualizada', listaPartidasActualizada);

            if(asignado.target.value === 'Todo'){
                this.formRef.current.resetFields(['partida-' + idServicio, 'area-' + idServicio]);
                this.formRef.current.setFieldsValue({['partida-' + idServicio]: undefined, ['area-' + idServicio]: undefined});

                let showMSG = false;
                let proveedores = [];
                let servicio = '';

                for(let j in listaServiciosActivosForProveedor){
                    if(idServicio === listaServiciosActivosForProveedor[j].idServicio){
                        if(listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio){
                            showMSG = true;
                            servicio = listaServiciosActivosForProveedor[j].nomenclatura + ' - ' + listaServiciosActivosForProveedor[j].nombre;
                            for(let k in listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio){
                                proveedores.push(' ' + listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio[k].proveedorByIdProveedor.nombre + ', ');
                            }
                        }
                    }
                }

                let uniqueProveedores = proveedores.reduce(function(a,b){
                    if (a.indexOf(b) < 0 ) a.push(b);
                    return a;
                  },[]);

                while(showMSG){
                    // message.info(
                    //     <p> El/Los proveedor/es: <b>{uniqueProveedores}</b> ya se le han asignado áreas del servicio <b>{servicio}</b>. Si se genera la asignación a este proveedor el o los anteriores las perderan</p>, 10
                    // );
                    notification.warning({
                        duration: 5,
                        placement: 'topLeft',
                        message: servicio,
                        description:
                            <p> El/Los proveedor/es: <b>{uniqueProveedores}</b> ya se le han asignado áreas de este servicio. Si se genera la asignación a este proveedor el o los anteriores las perderan.</p>,
                    });
                    showMSG = false
                }

            } else if(asignado.target.value === 'Partida'){
                
                this.formRef.current.resetFields(['partida-' + idServicio]);
                this.formRef.current.setFieldsValue({['area-' + idServicio]: undefined});

                if(!listaPartidasByServicio[0]){
                    message.warning('El o los servicios seleccionados no cuentan con partidas.');
                }
            } else if(asignado.target.value === 'Area'){
                this.formRef.current.setFieldsValue({['partida-' + idServicio]: undefined});
                this.formRef.current.resetFields(['area-' + idServicio]);
            }

            this.setState({ asignado: asignado.target.value });
        };

        const onPartidaSeleccionada = (value, idServicio, key) => {
            console.log('value', value);
            console.log('idServicio', idServicio);
            let asignacionPartida = false;
            let proveedor = [];
            let partida = [];

            if(value){
                for(let i in value){
                    for(let j in listaServiciosActivosForProveedor){
                        if(idServicio === listaServiciosActivosForProveedor[j].idServicio){
                            if(listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio){
                                for(let k in listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio){
                                    if(listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio[k].idPartida === parseInt(value[i])){
                                        if(listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio[k].asignacion === "Partida"){
                                            asignacionPartida = true;
                                            proveedor.push(' ' + listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio[k].proveedorByIdProveedor.nombre + ', ');
                                            partida.push(' ' + listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio[k].partidaByIdPartida.nombre + ', ')
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                let uniquePartidas = partida.reduce(function(a,b){
                    if (a.indexOf(b) < 0 ) a.push(b);
                    return a;
                },[]);

                let uniqueProveedores = proveedor.reduce(function(a,b){
                    if (a.indexOf(b) < 0 ) a.push(b);
                    return a;
                },[]);

                while(asignacionPartida === true){
                    message.info(
                        <p> La/Las partida/s: <b>{uniquePartidas}</b> ya se encuentran en uso por el/los proveedores: <b>{uniqueProveedores}</b> si se genera la asignación a este proveedor los anteriores las perderan</p>, 10
                    );
                    asignacionPartida = false
                }

            }
        };

        const onAreasSeleccionadas = (value, idServicio, key) => {

            let asignacionArea = false;
            let proveedor = [];
            let area = [];
            if(value){
                for(let i in value){
                    for(let j in listaServiciosActivosForProveedor){
                        if(idServicio === listaServiciosActivosForProveedor[j].idServicio){
                            if(listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio){
                                for(let k in listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio){
                                    if(listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio[k].idAreaFuncional === parseInt(value[i])){
                                        asignacionArea = true;
                                        proveedor.push(' ' + listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio[k].proveedorByIdProveedor.nombre + ', ');
                                        area.push(' ' + listaServiciosActivosForProveedor[j].serviciosProveedoresByIdServicio[k].areaFuncionalByIdAreaFuncional.areaFuncional + ', ');
                                    }
                                }
                            }
                        }
                    }
                }

                let uniqueAreas = area.reduce(function(a,b){
                    if (a.indexOf(b) < 0 ) a.push(b);
                    return a;
                },[]);

                let uniqueProveedores = proveedor.reduce(function(a,b){
                    if (a.indexOf(b) < 0 ) a.push(b);
                    return a;
                },[]);

                while(asignacionArea === true){
                    message.info(
                        <p> El/Las área/s: <b>{uniqueAreas}</b> ya se encuentra en uso por el/los proveedor/es: <b>{uniqueProveedores}</b> si se genera la asignación a este proveedor los anteriores las perderan</p>, 10
                    );
                    asignacionArea = false
                }

            }
        };
        
        return (
            <Modal
            visible={showModalNuevoProveedor}
            title='Nuevo Proveedor'
            onCancel={handleCancel}
            footer={false}
            width={'70%'}
            >
                <Spin spinning={fetchingNuevoProveedor}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Persona"
                                    name="persona"
                                >
                                    <Radio.Group onChange={cambiarPersona} defaultValue='fisica'>
                                        <Radio value='fisica'>Física</Radio>
                                        <Radio value='moral'>Moral</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            {this.state.persona === 'fisica' ? (
                            <Col span={11}>
                                <Form.Item
                                    label="RFC"
                                    name="rfc"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={13} minLength={13}
                                    placeholder="Ingresa el RFC" 
                                    pattern='^([A-ZÑ&]{4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$'
                                    title='No es un formato válido para RFC persona física'/>
                                </Form.Item>
                            </Col>
                            ):(
                            <Col span={11}>
                                <Form.Item
                                    label="RFC"
                                    name="rfc"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={12}
                                    minLength={12} placeholder="Ingresa el RFC"
                                    pattern='^([A-ZÑ&]{3}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$'
                                    title='No es un formato válido para RFC persona moral'/>
                                </Form.Item>
                            </Col>
                            )}
                            <Col span={11}>
                                <Form.Item
                                    label="Razón social"
                                    name="razonSocial"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={80} placeholder="Ingresa la razón social" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Nombre de contacto"
                                    name="nombre"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={120} placeholder="Ingresa el nombre de contacto" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Correo electrónico de contacto"
                                    name="correo"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', type: 'email' }]}
                                >
                                    <Input maxLength={50} placeholder="Ingresa el correo electrónico de contacto" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Dominio"
                                    name="idDominio"
                                >
                                    <Select mode="multiple"
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el dominio"
                                        loading={fetchingListaDominiosActivos}
                                    >
                                        {listaDominiosActivos.map(option => <Option key={option.idDominio + ' - ' + option.dominioCompleto}>{option.idDominio + ' - ' +  option.dominioCompleto }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Rango de vigencia"
                                    name="rangoVigencia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <RangePicker format = 'DD-MM-YYYY' placeholder={["Fecha Inicio","Fecha Fin"]} style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>

                            <Col span={11}>
                                <Form.Item
                                    label="Clave de expediente"
                                    name="nombreExpediente"
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona la clave de expediente"
                                        loading={fetchingListaExpedientes}
                                    >
                                        {listaExpedientes.map(option => <Option key={option.idExpediente}>{ option.clave }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={23}>
                                <Form.Item
                                    label="Servicio"
                                    name="idServicio"
                                    rules={[{ required: true, message: 'Favor de seleccionar una opción', }]}
                                >
                                    <Select 
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el servicio"
                                        loading={fetchingListaServiciosActivosForProveedor}
                                        onChange={onServicioSel}
                                        showSearch
                                    >
                                        {/* Muestra todos los servicios disponibles que no han sido asignados a un proveedor */}
                                        {listaServiciosActivosForProveedor.map(option => <Option key={option.idServicio + ' - ' + option.nombre}>{ option.idServicio + ' - ' + option.nombre }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>

{/********************************************************************************************************************/}
                        <Col span={23}>
                        { listaPartidasByServicio && this.state.showProvPartida ? (<> 
                            { listaPartidasByServicio.map((index, key) =>
                            <Row justify='space-around' key={index[0].idServicio}>
                            { index[0].idPartida !== null ? (
                                <Divider orientation='left' style={{fontSize: 14}}>Asignación proveedores-partidas-áreas:  <b style={{color:'#1747ca'}}>{ index[0].servicioByIdServicio.nombre }</b></Divider>
                            ): (
                                <Divider orientation='left' style={{fontSize: 14}}>Asignación proveedores-áreas:  <b style={{color:'#1747ca'}}>{ index[0].servicioByIdServicio.nombre }</b></Divider>
                            )}

                            { index[0].idPartida !== null ? (
                                <Col span={6}>
                                    <Form.Item
                                        label="Asignado por"
                                        name={"asignado-" + index[0].idServicio}
                                    >
                                        <Radio.Group onChange={(e) => cambiarAsignacion(e, index[0].idServicio, key)} defaultValue={'Todo'}>
                                            <Radio value={'Todo'}>Todo</Radio>
                                            <Radio value={'Partida'}>Partida</Radio>
                                            <Radio value={'Area'}>Área</Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                </Col>
                            ):(
                                <Col span={6}>
                                    <Form.Item
                                        label="Asignado por"
                                        name={"asignado-" + index[0].idServicio}
                                    >
                                        <Radio.Group onChange={(e) => cambiarAsignacion(e, index[0].idServicio, key)} defaultValue={'Todo'}>
                                            <Radio value={'Todo'}>Todas</Radio>
                                            <Radio value={'Area'}>Área</Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                </Col>
                            ) }

                            { index[0].idPartida !== null ? (
                                <Col span={8}>
                                    <Form.Item
                                        label='Partida'
                                        name={'partida-' + index[0].idServicio}
                                        rules={[{ required: !(index[0].asignado !== 'Partida'), message: 'Favor de seleccionar una opción', }]}
                                    >
                                        <Select
                                            showSearch={true}
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona una partida"
                                            disabled={index[0].asignado !== 'Partida'}
                                            loading={fetchingListaPartidasByServicio}
                                            onChange={(e) => onPartidaSeleccionada(e, index[0].idServicio, key)}
                                        >
                                            {index.map(option =>
                                                <Option key={option.idPartida + ' - ' + option.nombre }>
                                                    <Popover content={option.idPartida + ' - ' + option.nombre} title={false}>
                                                        { option.idPartida + ' - ' + option.nombre }
                                                    </Popover>
                                                </Option>
                                            )}
                                        </Select>

                                    </Form.Item>
                                </Col>
                            ):(
                                <Col span={0}>
                                   
                                </Col>
                            )}

                            { index[0].idPartida !== null ? (
                                <Col span={8}>
                                    <Form.Item
                                        label='Área'
                                        name={'area-' + index[0].idServicio}
                                        rules={[{ required: !(index[0].asignado !== 'Area'), message: 'Favor de seleccionar una opción', }]}
                                    >
                                        <Select
                                            showSearch={true}
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona una área"
                                            disabled={index[0].asignado !== 'Area'}
                                            loading={fetchingListaAreasFuncionalesActivas}
                                            onChange={(e) => onAreasSeleccionadas(e, index[0].idServicio, key)}
                                        >
                                            {listaAreasFuncionalesActivas.map(option =>
                                                <Option key={option.idAreaFuncional + ' - ' + option.codigoArea + ', ' + option.areaFuncional}>
                                                    <Popover content={option.idAreaFuncional + ' - ' + option.codigoArea + ', ' + option.areaFuncional} title={false}>
                                                        { option.idAreaFuncional + ' - ' + option.codigoArea + ', ' + option.areaFuncional }
                                                    </Popover>
                                                </Option>
                                            )}

                                        </Select>
                                    </Form.Item>
                                </Col>
                            ): (
                                <Col span={17}>
                                    <Form.Item
                                        label='Área'
                                        name={'area-' + index[0].idServicio}
                                        rules={[{ required: !(index[0].asignado !== 'Area'), message: 'Favor de seleccionar una opción', }]}
                                    >
                                        <Select
                                            showSearch={true}
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona una área"
                                            disabled={index[0].asignado !== 'Area'}
                                            loading={fetchingListaAreasFuncionalesActivas}
                                            onChange={(e) => onAreasSeleccionadas(e, index[0].idServicio, key)}
                                        >
                                            {listaAreasFuncionalesActivas.map(option =>
                                                <Option key={option.idAreaFuncional + ' - ' + option.codigoArea + ', ' + option.areaFuncional}>
                                                    <Popover content={option.idAreaFuncional + ' - ' + option.codigoArea + ', ' + option.areaFuncional} title={false}>
                                                        { option.idAreaFuncional + ' - ' + option.codigoArea + ', ' + option.areaFuncional }
                                                    </Popover>
                                                </Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}
                            </Row>
                            ) }
                            
                        </>):false }
                        </Col>

{/********************************************************************************************************************/}

                            <Divider orientation="left">Dirección</Divider>
                            <Col span={11}>
                                <Form.Item
                                    label="Calle"
                                    name="calle"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={150} placeholder="Ingresa la calle" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Número exterior"
                                    name="numeroExterior"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={50} placeholder="Ingresa el número exterior" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Número interior"
                                    name="numeroInterior"
                                >
                                    <Input maxLength={50} placeholder="Ingresa el número interior" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Nombre colonia"
                                    name="nombreColonia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={100} placeholder="Ingresa la colonia" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Código Postal"
                                    name="cp"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input minLength='5' maxLength='5' placeholder="Ingresa el Código Postal" title="El código postal debe ser números" pattern="^[0-9]+$"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Municipio o alcaldía"
                                    name="municipio"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={50} placeholder="Ingresa el municipio o alcaldía" title="El municipio debe contener sólo letras" pattern="^[a-zñA-ZÑ ÁÉÓÚÍáéóíú]+$"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Estado"
                                    name="estado"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={50} placeholder="Ingresa el estado" title="El estado debe contener sólo letras" pattern="^[a-zñA-ZÑ ÁÉÓÚÍáéóíú]+$"/>
                                </Form.Item>
                            </Col>
                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Crear Proveedor</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingNuevoProveedor: state.InformacionOperativaReducer.fetchingNuevoProveedor,
        showModalNuevoProveedor: state.InformacionOperativaReducer.showModalNuevoProveedor,
        fetchingListaDominiosActivos: state.ConfiguracionYSeguridadReducer.fetchingListaDominiosActivos,
        listaDominiosActivos: state.ConfiguracionYSeguridadReducer.listaDominiosActivos,
        fetchingListaExpedientes: state.InformacionOperativaReducer.fetchingListaExpedientes,
        listaExpedientes: state.InformacionOperativaReducer.listaExpedientes,
        fetchingListaContratos: state.InformacionOperativaReducer.fetchingListaContratos,
        listaContratos: state.InformacionOperativaReducer.listaContratos,
        fetchingListaServiciosActivosForProveedor: state.InformacionOperativaReducer.fetchingListaServiciosActivosForProveedor,
        listaServiciosActivosForProveedor: state.InformacionOperativaReducer.listaServiciosActivosForProveedor,
        page: state.InformacionOperativaReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,

        listaAreasProveedores: state.InformacionOperativaReducer.listaAreasProveedores,
        fetchingListaAreasProveedores: state.InformacionOperativaReducer.fetchingListaAreasProveedores,
        listaPartidasByServicio: state.InformacionOperativaReducer.listaPartidasByServicio,
        fetchingListaPartidasByServicio: state.InformacionOperativaReducer.fetchingListaPartidasByServicio,
        listaPartidasByServicioWithout: state.InformacionOperativaReducer.listaPartidasByServicioWithout,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,

        listaAreasFuncionalesActivas: state.AlmacenesReducer.listaAreasFuncionalesActivas,
        fetchingListaAreasFuncionalesActivas: state.AlmacenesReducer.fetchingListaAreasFuncionalesActivas,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalNuevoProveedor: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_PROVEEDOR', showModalNuevoProveedor: false, page: null })
        },
        onNuevoProveedor: ( formulario, listaDominiosActivos, listaExpedientes, listaContratos, listaServicios, page, listaPartidasByServicioWithout, listaAreasFuncionalesActivas, listaAsignacionProveedor ) => {
            dispatch({ type: "NUEVO_PROVEEDOR_REQUEST" , formulario, listaDominiosActivos, listaExpedientes, listaContratos, listaServicios, page, listaPartidasByServicioWithout, listaAreasFuncionalesActivas, listaAsignacionProveedor });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onObtenerAreasFuncionales: () => {
            dispatch({ type: "LISTA_AREA_FUNCIONAL_ACTIVA_REQUEST", });
        },
        onObtenerPartidasByidServicio: (idsServicios, listaServiciosActivosForProveedor, serviciosActivos) => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_SERVICIO_INFO_OP_REQUEST', idsServicios, listaServiciosActivosForProveedor, serviciosActivos });
        },
        onLimpiarListaPartidas: () => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_SERVICIO_INFO_OP_SUCCESS', listaPartidasByServicio: [] });
        },
        actualizarAsig: (listaPartidasByServicio, listaPartidasByServicioWithout) => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_SERVICIO_INFO_OP_SUCCESS', listaPartidasByServicio, listaPartidasByServicioWithout });
        },
        onGetListaServicios: (idProveedor) => {
            dispatch({ type: 'LISTA_SERVICIOS_PROVEEDORES_REQUEST', idProveedor });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoProveedor);