import React, {Component} from 'react'
import { Modal } from 'antd';

import { connect } from "react-redux";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-classic-base64-upload/build/ckeditor';

ClassicEditor.defaultConfig = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'insertTable',
        '|',
        'imageUpload',
        '|',
        'undo',
        'redo'
      ]
    },
    image: {
      toolbar: [
        'imageStyle:full',
        'imageStyle:side',
        '|',
        'imageTextAlternative'
      ]
    },
    table: {
      contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
    },
    language: 'es'
  };

class ModalEditorTexto extends Component {
    render() { 
        const {onShowModalEditorDeTexto, showModalEditorTexto,
        } = this.props;

        const handleCancel = () => {
            onShowModalEditorDeTexto()
        }

        return ( 
            <Modal
                visible={showModalEditorTexto}
                title='Editor de texto'
                onCancel={handleCancel}
                footer={false}
                width='40%'
            >
                <CKEditor
                    editor={ ClassicEditor }
                    data="<p>Hello from CKEditor 5!</p>"
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />
            </Modal>
         );
    }
}
 
const mapStateToProps = state => {
	return {
        showModalEditorTexto: state.RMDPReducer.showModalEditorTexto,
        dataEditorDeTexto: state.RMDPReducer.dataEditorDeTexto,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditorDeTexto: () => {
            dispatch ({ type: 'MODAL_EDITOR_TEXTO', showModalEditorTexto: false, dataEditorDeTexto: null })
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditorTexto);
