import React, {Component} from 'react';
import { Form, Spin, Row, Col, Input, Button, DatePicker, message} from "antd";
import {connect} from 'react-redux';
import moment from 'moment';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalContraseña from '../ModalContraseña';

const { TextArea } = Input;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class AbiertoForm extends Component {
    formRef = React.createRef();
    state = {
        btnRegistrar: false,
        formulario: null,
    }
    render() {
        const { fetchingCambioEstadoSeguimiento, onActualizarSeguimiento,
            folioSolicitudServicioSeleccionado, page, busqueda, listaTrazabilidad,
            onShowModalContraseña, isEspecial,
        } = this.props;


        const currentYear = moment().format('YYYY');
        console.log('currentYear', currentYear);

        let permisosUsuario = sessionStorage.getItem('permisosUsuario')
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        if (isEspecial) {
            onActualizarSeguimiento(this.state.formulario, folioSolicitudServicioSeleccionado, page, busqueda);
            onShowModalContraseña(false, false);
        };

        const onFinish = (formulario) => {
            console.log(formulario)
            if (permisosUsuario.especialSolicitudDeServicio) {
                this.setState({ formulario })
                onShowModalContraseña(true, false);
            } else {
                onActualizarSeguimiento(formulario, folioSolicitudServicioSeleccionado, page, busqueda);
            }

        };

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo);
        };

        const onChangeDate = (value) => {

            if (value || listaTrazabilidad) {

                let fechaEstado;
                if (listaTrazabilidad.length > 0) {
                    if (listaTrazabilidad[0].fechaEstado) {
                        console.log(listaTrazabilidad[0].fechaEstado);
                        fechaEstado = listaTrazabilidad[0].fechaEstado
                    } else {
                        fechaEstado = null;
                    }
                } else {
                    fechaEstado = null
                }

                let resultadoValidacionFechaAlta = moment(value, 'DD-MM-YYYY HH:mm').isAfter(moment(folioSolicitudServicioSeleccionado.fechaAlta, 'DD-MM-YYYY HH:mm'));
                let resultadoValidacionFechaEstado = moment(value, 'DD-MM-YYYY HH:mm').isAfter(moment(fechaEstado, 'DD-MM-YYYY HH:mm'));
                let resultadoValidacionAñoCurrent = moment(value, 'YYYY').isAfter(moment(currentYear, 'YYYY'));
                console.log(resultadoValidacionFechaAlta, resultadoValidacionFechaEstado, resultadoValidacionAñoCurrent)
                if (!resultadoValidacionFechaAlta) {
                    message.warning('La fecha seleccionada tiene que ser mayor a la "Fecha de Alta" de la solicitud de servicio', 5);
                    this.setState({ btnRegistrar: true })
                } else if (!resultadoValidacionFechaEstado && fechaEstado !== null) {
                    message.warning('La fecha seleccionada tiene que ser mayor a la ultima "Fecha de Estado" de la solicitud de servicio', 5);
                    this.setState({ btnRegistrar: true })
                } else if (!resultadoValidacionAñoCurrent) {
                    message.warning('La fecha seleccionada tiene que ser durante el año en curso', 5);
                    this.setState({ btnRegistrar: true })
                } else {
                    this.setState({ btnRegistrar: false })
                }
            }
        };

        return ( 
            <div>
                <Spin spinning={fetchingCambioEstadoSeguimiento}>
                {folioSolicitudServicioSeleccionado ? (
                    <Form {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        initialValues={folioSolicitudServicioSeleccionado}
                        
                        ref={this.formRef} 
                    >
                        <Row type='flex' justify='space-around'>
                            <Col span={23}>
                                <Form.Item
                                    label="Observaciones"
                                    name="observacionesF"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <TextArea
                                        rows={1}
                                        pattern="^^[ñíóáéú a-zA-Z 0-9]+$"
                                        title="Observaciones de abierto debe tener letras y numeros."
                                        maxLength={100} placeholder='Ingrese observaciones' />
                                </Form.Item>
                            </Col>

                            <Col span={23}>
                                <Form.Item
                                    label="Hipótesis"
                                    name="hipotesis"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <TextArea
                                        rows={1}
                                        pattern="^^[ñíóáéú a-zA-Z 0-9]+$"
                                        title="Hipótesis debe tener letras y numeros."
                                        maxLength={150} placeholder='Ingrese hipótesis' />
                                </Form.Item>
                            </Col>
                           <Col span={23}>
                              <Form.Item
                                label="Fecha y Hora asignado"
                                name="fechaAbierto"
                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                              >
                               <DatePicker
                                    style={{ width: '100%' }}
                                    format='DD-MM-YYYY HH:mm' 
                                    placeholder={["Seleccione fecha y hora asginado"]}
                                    onChange={onChangeDate}
                                    showTime={{ defaultValue: moment('00:00', 'HH:mm') }} 
                               />
                             </Form.Item>
                          </Col>  
                               
                                <Col span={23}>
                                <Button className="btnFiltrar" style={{ width: '40%' }} htmlType="submit">Registrar</Button>
                                  </Col>
                            </Row>
                           

                     </Form>
                     ): false }
                </Spin>
                <ModalContraseña/>
            </div>
         );
    }
}

const mapStateToProps = (state) => {
    return {
        fetchingCambioEstadoSeguimiento: state.SolicitudDeServiciosReducer.fetchingCambioEstadoSeguimiento,
        folioSolicitudServicioSeleccionado: state.SolicitudDeServiciosReducer.folioSolicitudServicioSeleccionado,
        listaTrazabilidad: state.SolicitudDeServiciosReducer.listaTrazabilidad,
        page: state.SolicitudDeServiciosReducer.page,
        busqueda: state.SolicitudDeServiciosReducer.busqueda,
        isEspecial: state.SolicitudDeServiciosReducer.isEspecial,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onActualizarSeguimiento: (formulario, folioSolicitudServicioSeleccionado, page, busqueda) => {
                dispatch({ type: "ABIERTO_FOLIO_SS_REQUEST", formulario, folioSolicitudServicioSeleccionado , page, busqueda});
        },
        onShowModalContraseña: (showModalContraseña, isEspecial) => {
            dispatch({ type: "SHOW_MODAL_CONTRASEÑA", showModalContraseña , isEspecial})
        } 
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(AbiertoForm);