import React from 'react';
import { Form, Row, Col, Button, Select, Table, DatePicker, Divider, Spin, Tooltip, Alert } from 'antd';
import { connect } from "react-redux";
import { FilePdfOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalCambiarHora from './ModalCambiarHora';
import moment from 'moment';

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
};

class ReporteDiarioAutoContainer extends React.Component {
     formRef = React.createRef();

     componentDidMount() {
          this.props.onGetSolicitudes(1);
     }

     state = {
          page: 1,
          fechaReporte: '',
          buttonBoolean: true,
          fechaLista: '',
          leyendaInfo: 'Ingrese una fecha válida para continuar',
     }

     render() {
          const { 
               limpiarFormulario, 
               onLimpiarFormulario,
               listaSSDiarios,
               listaSSDiariosCantidad,
               fetchingListaSSDiarios,
               onGetSolicitudes,
               onGetReporte,
               fetchingVisualizarReporte,
          } = this.props;

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
               permisosUsuario = JSON.parse(permisosUsuario);
          }
               
          const onFinish = formulario => {
               console.log('Success:', formulario);

               let fecha = moment(formulario.fechaVerificar.format('DD-MM-YYYY'));

               onGetSolicitudes(this.state.page, fecha._i)
          };

          if (limpiarFormulario && limpiarFormulario === 'inventarioFisico') {
               onLimpiarFormulario();
          }

          const columns = [
               {title: 'Folio', dataIndex: 'folio', key: 'folio'},
               {title: 'Descripción', dataIndex: 'descripcionSolicitud', key: 'descripcionSolicitud'},
               {title: 'Servicio', dataIndex: 'servicio', key: 'servicio'},
               {title: 'Área', dataIndex: 'area', key: 'area'},
               {title: 'Unidad', dataIndex: 'unidad', key: 'unidad'},
               {title: 'Estado', dataIndex: 'estado', key: 'estado'},
               {title: 'Fecha de alta', dataIndex: 'fechaAlta', key: 'fechaAlta'},
               {title: 'Hora de alta', dataIndex: 'horaAlta', key: 'horaAlta'},
          ];

          const handleTableChange = (pagination) => {
               this.setState({ page: pagination.current});
               onGetSolicitudes(pagination.current, this.state.fechaLista);
          };

          const scroll = {"x": 1500};

          const onFecha = (value) => {
               if (value) { 
                    let fechaReporteLocal  = moment(value.format('YYYY-MM-DD'));
                    let fecha = moment(value.format('DD-MM-YYYY'));
                    let leyendaInfo = 'No existen coincidencias de Solicitudes de Servicio que cuenten con un estado diferente a "Cerrado" o "Terminado" a la fecha seleccionada.';

                    this.setState({ buttonBoolean: false, fechaReporte: fechaReporteLocal._i, fechaLista: fecha._i, leyendaInfo }); 
                    onGetSolicitudes(this.state.page, fecha._i);
               } else { 
                    this.setState({ buttonBoolean: true }); 
                    onGetSolicitudes(this.state.page, '');
               }
          };

          const onVisualizar = () => { onGetReporte(this.state.fechaReporte); };

          return (
               <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    ref={this.formRef}
               >
                    <Row justify='end' style={{ textAlign: 'left' }}>
                         <Col span={5}>
                              <Tooltip placement="top" title="Mostrará las solicitudes de servicio que cuenten con un estado diferente a 'Cerrado' o 'Terminado' de la fecha seleccionada." >
                                   <Form.Item name='fechaVerificar' rules={[{ required: true, message: 'Favor de llenar el campo'}]}>
                                        <DatePicker
                                             className='input'
                                             style={{width: '100%'}} 
                                             format='DD-MM-YYYY' 
                                             onChange={onFecha} 
                                             placeholder={["Fecha a verificar"]}
                                             allowClear
                                             allowEmpty
                                             showToday={false}
                                             disabledDate={(current) => {
                                                  return current && current >= moment().add(-1, 'days');
                                             }}
                                        />
                                   </Form.Item>
                              </Tooltip>
                         </Col>
                    </Row>

                    <Row justify='space-around' style={{ textAlign: 'left' }}>
                         { listaSSDiariosCantidad > 0 ? (
                         <Col span={24}>
                              <br/>
                              <Table
                                   size="small" 
                                   rowKey="idSolicitudServicio" 
                                   columns={columns}
                                   dataSource={listaSSDiarios}
                                   loading={fetchingListaSSDiarios}
                                   locale={{ emptyText: 'Sin datos' }}
                                   onChange={handleTableChange}
                                   scroll={scroll}
                                   pagination={{total: listaSSDiariosCantidad, simple: true, current: this.state.page}}
                              />
                         </Col>
                         ):(
                         <Col span= {24}>
                              <Spin spinning={fetchingListaSSDiarios}>
                                   <Alert
                                        message="Fecha a Verificar"
                                        description={this.state.leyendaInfo}
                                        type="info"
                                        showIcon
                                        style={{ borderRadius: '2em' }}
                                   />
                                   <br />
                              </Spin>
                         </Col>
                         )}
                         <Divider />
                         <Col span={11}>
                              <Button className={'btnSecundario'} style={{ width: '100%' }} disabled={!permisosUsuario.descargarReporteAutomatico ? true : this.state.buttonBoolean} onClick={onVisualizar} loading={fetchingVisualizarReporte} >
                                   Descargar Reporte <FilePdfOutlined />
                              </Button>
                         </Col>
                    </Row>
                    <ModalCambiarHora />
               </Form>
          );
     }
}

const mapStateToProps = state => {
     return {
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          fetchingListaSSDiarios: state.SolicitudDeServiciosReducer.fetchingListaSSDiarios,
          listaSSDiarios: state.SolicitudDeServiciosReducer.listaSSDiarios,
          listaSSDiariosCantidad: state.SolicitudDeServiciosReducer.listaSSDiariosCantidad,
          reporterBoolean: state.SolicitudDeServiciosReducer.reporterBoolean,
          fetchingVisualizarReporte: state.SolicitudDeServiciosReducer.fetchingVisualizarReporte,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
          onShowModal: () => {
               dispatch({ type: 'SHOW_MODAL_CAMBIAR_HR', showModalCambiarHora: true });
          },
          onGetSolicitudes: (page, fechaVerificar) => {
               dispatch({ type: 'LISTA_SS_DIARIOS_REQUEST', page, fechaVerificar });
          },
          onGetReporte: (fechaReporte) => {
               dispatch({ type: 'VISUALIZAR_REPORTE_REQUEST', fechaReporte });
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReporteDiarioAutoContainer);
