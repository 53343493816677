import React, { Component } from 'react';
import { Form, Button, Divider, Row, Col, Spin, Select, DatePicker, InputNumber, Switch, message } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class EditarPonderador extends Component {
    formRef = React.createRef();
    state = {
        pesoEditado: false,
        tipoEdicion: null,
        disabledSelect: false,
    }
    render() {
        const { fetchingEditarPonderacion, onEditarPonderacion,
            listaAreasFuncionalesActivos, fetchingListaAreasFuncionalesActivos, fetchingListaCategoriasDeFallasActivos, listaCategoriasDeFallasActivos,
            limpiarFormulario, onLimpiarFormulario, page, listaInfoProyecto, fetchingListaInfoProyecto, ponderacionSeleccionado,
            onCambiarVista, onGetListaPonderaciones,
        } = this.props;

        if(limpiarFormulario && limpiarFormulario === 'editarPonderacion'){
            console.log('entró a limpiar');
            this.formRef.current.resetFields();
            onLimpiarFormulario();
            onCambiarVista(1);
        }

        const onFinish = formulario => {
            console.log('Success:', formulario);
            const rangeValue = formulario['rangoVigencia'];
            if(rangeValue[0]){
                formulario.rangoVigencia = rangeValue[0].format('YYYY-MM-DD') + "," + rangeValue[1].format('YYYY-MM-DD');
                if(ponderacionSeleccionado.areaFuncionalByIdAreaFuncional.convenio){
                    onEditarPonderacion(formulario, page, ponderacionSeleccionado, this.props.busqueda);
                }
                else{
                    if(ponderacionSeleccionado.pesoArea === formulario['pesoArea']){
                        console.log('terminado:', formulario);
                        onEditarPonderacion(formulario, page, ponderacionSeleccionado, this.props.busqueda);
                    }
                    else{
                        onGetListaPonderaciones(formulario, ponderacionSeleccionado)
                        onCambiarVista(2, formulario);
                        console.log('Pedir contraseña Krnal');
                    }
                }
            }
            else{
                message.warning('Favor de seleccionar una fecha de inicio de vigencia', 7);
            }
            
        };
        

        const changeDecimales = (value) => {
            let valueDecimal = parseFloat(value);
            if(isNaN(valueDecimal)){
                valueDecimal = 0;
            }
            if(ponderacionSeleccionado.areaFuncionalByIdAreaFuncional.convenio){
                this.formRef.current.setFieldsValue({'pesoArea': valueDecimal.toFixed(4)});
            }
            else{
                if(this.formRef.current.getFieldValue('rangoVigencia')[0]){
                    message.warning('Por motivos de seguridad, favor de seleccionar de nuevo la fecha de inicio de vigencia', 7);
                }
                
                if(value !== parseFloat(ponderacionSeleccionado.pesoArea)){
                    this.setState({
                        pesoEditado: true,
                    });
                }
                else{
                    this.setState({
                        pesoEditado: false,
                    });
                }
                this.formRef.current.setFieldsValue({'pesoArea': valueDecimal.toFixed(4), 'rangoVigencia': [null, ponderacionSeleccionado.rangoVigencia[1]]});
            }
        }
        const disabledRange = fecha => {
            return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicio;
        }
        const fechas = (rangeValue) => {
            if(listaInfoProyecto.fechaInicio){
                if(rangeValue[0]){
                    const fechaInicioAnterior = ponderacionSeleccionado.fechaInicioVigencia.split('T')[0];
                    const diferenciaAnterior = moment(fechaInicioAnterior, 'YYYY-MM-DD').diff(moment(rangeValue[0].format('YYYY-MM-DD')), "days");
                    console.log('diferenciaAnterior');
                    console.log(diferenciaAnterior);
                    if(this.state.pesoEditado && this.formRef.current){
                        if(diferenciaAnterior > -1){
                            message.error('No es posible seleccionar una fecha de inicio menor ni igual a la fecha de inicio anterior (' + fechaInicioAnterior.split('-').reverse().join('-') + ')', 7);
                            this.formRef.current.setFieldsValue({'rangoVigencia': [null, ponderacionSeleccionado.rangoVigencia[1]]});
                        }
                    }
                }
            }
            else{
                message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                this.formRef.current.setFieldsValue({'rangoVigencia': [null, ponderacionSeleccionado.rangoVigencia[1]]});
            }
        };
        // const onSelectTipoEdicion = (value) => {
        //     this.setState({
        //         tipoEdicion: value,
        //         disabledSelect: true,
        //     })
        // }
        return (
            <div>
                {ponderacionSeleccionado ? (
                <Spin spinning={fetchingEditarPonderacion}>
                    <Form {...layout}
                    name="formulario"
                    initialValues={ponderacionSeleccionado}
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        {/* <Row justify='center'>
                            <Col span={6}>
                                <p>Selecciona el tipo de edición</p>
                            </Col>
                            <Col span={11}>
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona el tipo de edición"
                                    onChange={onSelectTipoEdicion}
                                    disabled={this.state.disabledSelect}
                                >
                                    <Option key='simple' disabled={!ponderacionSeleccionado.areaFuncionalByIdAreaFuncional.visible}>Edición simple</Option>
                                    <Option key='completa'>Edición por convenio</Option>
                                </Select>
                            </Col>
                        </Row>
                        <Divider/>
                        {this.state.tipoEdicion ?( */}
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Área funcional"
                                    name="areaFuncional"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el área funcional"
                                        loading={fetchingListaAreasFuncionalesActivos}
                                    >
                                        {listaAreasFuncionalesActivos.map(option => <Option key={option.idAreaFuncional}>{option.areaFuncional}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Peso de área"
                                    name="pesoArea"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber min={0.0000} max={99.9999} placeholder="Ingresa el peso de área"
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                        onChange={changeDecimales}
                                        step={0.0010} />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Tiempo de rehabilitación"
                                    name="fallaCategoria"
                                    rules={[{ required: true, message: 'Favor de seleccionar un valor', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona un tiempo de rehabilitación"
                                        loading={fetchingListaCategoriasDeFallasActivos}
                                    >
                                        {listaCategoriasDeFallasActivos.map(option => <Option key={option.idFallaCategoria}>{ option.fallaCategoriaCompleta }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Turnos"
                                    name="turnos"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber min={0} max={4} style={{ width: '100%' }} placeholder="Ingresa el turno" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Row justify='space-between'>
                                    <Col span={17}>
                                        <Form.Item
                                            label="Factor 1"
                                            name="factor1"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <InputNumber min={1} max={100} placeholder="Ingresa el factor 1" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item
                                            label="Considerar factor 1"
                                            name="factor1Activo"
                                            valuePropName="checked"
                                        >
                                            <Switch />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={11}>
                                <Row justify='space-between'>
                                    <Col span={17}>
                                        <Form.Item
                                            label="Factor 2"
                                            name="factor2"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <InputNumber min={1} max={100} placeholder="Ingresa el factor 2"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item
                                            label="Considerar factor 2"
                                            name="factor2Activo"
                                            valuePropName="checked"
                                        >
                                            <Switch />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={11}>
                                <Row justify='space-between'>
                                    <Col span={17}>
                                        <Form.Item
                                            label="Factor 3"
                                            name="factor3"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <InputNumber min={1} max={100} placeholder="Ingresa el factor 3" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item
                                            label="Considerar factor 3"
                                            name="factor3Activo"
                                            valuePropName="checked"
                                        >
                                            <Switch />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Cantidad"
                                    name="cantidad"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber min={1} max={100} placeholder="Ingresa la cantidad" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Fechas vigencia"
                                    name="rangoVigencia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <RangePicker
                                        loading={fetchingListaInfoProyecto}
                                        style={{width: '100%'}} format='DD-MM-YYYY' onChange={fechas} placeholder={["Ingresa fecha inicio","Fecha fin"]}
                                        disabled={[false, true]}
                                        disabledDate={disabledRange}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Editar Ponderación Área</Button>
                            </Col>
                        </Row>
                        {/* ):false} */}
                    </Form>
                </Spin>
                ): false}
            </div>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarPonderacion: state.CatalogosGeneralesReducer.fetchingEditarPonderacion,
        fetchingListaAreasFuncionalesActivos: state.CatalogosGeneralesReducer.fetchingListaAreasFuncionalesActivos,
        listaAreasFuncionalesActivos: state.CatalogosGeneralesReducer.listaAreasFuncionalesActivos,
        fetchingListaCategoriasDeFallasActivos: state.CatalogosGeneralesReducer.fetchingListaCategoriasDeFallasActivos,
        listaCategoriasDeFallasActivos: state.CatalogosGeneralesReducer.listaCategoriasDeFallasActivos,
        page: state.CatalogosGeneralesReducer.page,
        busqueda: state.CatalogosGeneralesReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        fetchingListaInfoProyecto: state.InformacionDelProyectoReducer.fetchingListaInfoProyecto,
        ponderacionSeleccionado: state.CatalogosGeneralesReducer.ponderacionSeleccionado,
        vistaModalPonderacion: state.CatalogosGeneralesReducer.vistaModalPonderacion,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onEditarPonderacion: ( formulario, page, ponderacionSeleccionado, busqueda ) => {
            dispatch({ type: "EDITAR_PONDERACION_REQUEST" , formulario, page, ponderacionSeleccionado, busqueda });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onCambiarVista: (vistaModalPonderacion, formulario) => {
            dispatch({ type: 'CAMBIAR_VISTA_PONDERACION', vistaModalPonderacion, formulario, ponderacionEditados: null})
        },
        onGetListaPonderaciones: (formulario, ponderacionSeleccionado) => {
            dispatch({ type: 'LISTA_PONDERACIONES_ACTIVAS_REQUEST', formulario, ponderacionSeleccionado });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditarPonderador);