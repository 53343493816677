import React, { Component } from 'react';
import { Row, Col, Modal, Descriptions, Table, Badge, Spin, Button, Result, Input, Form } from 'antd';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';
import ReprogramarTareas from '../VistaCalendarioSP/ReprogramarTareas';
const {TextArea} = Input;
class ModalReprogramarDragDrop extends Component {
    formRef = React.createRef();
    render() {
        const { showModalReprogramarDragDrop, onShowModalCalendarioDetalle, anteriorRMP, calendarioDetalle,
            fetchingCalendarioDetalle, fetchingReprogramarTareaMultiple, limpiarFormulario, onLimpiarFormulario,
            noDragDrop, nuevoRMP, busqueda
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const handleCancel = () => {
            onShowModalCalendarioDetalle();
            this.props.onCalendarioProgramacion(busqueda);
        }
        const columns = [{
                dataIndex: 'color',
                key: 'color',
                render: (text, record) => (
                    <Badge status={text}/>
                )
            },{
                title: 'Ubicación',
                dataIndex: 'ubicacion',
                key: 'ubicacion',
            },{
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
            },{
                title: 'Estado',
                dataIndex: 'estado',
                key: 'estado',
            },{
                title: 'Hora',
                dataIndex: 'hora',
                key: 'hora',
            }
        ];

        if (limpiarFormulario && limpiarFormulario === 'reprogramarTareasDragDrop') {
            this.formRef.current.resetFields();
            onLimpiarFormulario();
        }

        const onFinish = (formulario) => {
            console.log('nuevoRMP', nuevoRMP)
            formulario.fechaReprogramacion = nuevoRMP.fechaDragDrop;
            this.props.onReprogramarTareaMultiple(formulario, calendarioDetalle, busqueda);
        }
        
        return (
            <>
            {anteriorRMP ? (
                <Modal
                    visible={showModalReprogramarDragDrop}
                    title={
                        <Row align="middle">
                            <Col>
                                <Row align="middle">
                                    <Col span={24} className='díaCalendario'>
                                        {anteriorRMP.fechaModal.split(', ')[0]}
                                    </Col>
                                    <Col span={24} className='fechaCalendario'>
                                    {anteriorRMP.fechaModal.split(', ')[1]}
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='fechaCalendario'>
                                <p> reprogramar a: </p>
                            </Col>
                            <Col offset={2}>
                                <Row align="middle">
                                    <Col span={24} className='díaCalendario'>
                                        {nuevoRMP.fechaModal.split(', ')[0]}
                                    </Col>
                                    <Col span={24} className='fechaCalendario'>
                                    {nuevoRMP.fechaModal.split(', ')[1]}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                    onCancel={handleCancel}
                    footer={false}
                    width={'60%'}
                >
                    <Spin spinning={fetchingCalendarioDetalle || fetchingReprogramarTareaMultiple}>
                        <Row justify='space-around'>
                            <Col span={24}>
                                <Descriptions bordered>
                                    <Descriptions.Item label="Rutina / Mantenimiento / Prueba">{anteriorRMP.rutina}</Descriptions.Item>
                                </Descriptions>
                                <br/>
                                <Descriptions bordered>
                                <Descriptions.Item label="Clave rutina">{calendarioDetalle ? calendarioDetalle[0].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.rutinaByIdRutina.claveRutina : ''}</Descriptions.Item>
                                    <Descriptions.Item label="Servicio">{anteriorRMP.calendarioProgramacionQueryResponse[0].nombreServicio}</Descriptions.Item>
                                </Descriptions>
                                <br/>
                            </Col>
                            <Col span={24}>
                                {noDragDrop ? (
                                <Result
                                    status="404"
                                    title="Favor de elegir otra rutina/mantenimiento/prueba"
                                    subTitle="No es posible reprogramar una RMP que tenga al menos una tarea en estatus 'Realizada'"
                                />
                                ):(
                                <Form
                                    name="formulario"
                                    onFinish={onFinish}
                                    ref={this.formRef}
                                >
                                    <Row justify='space-around'>
                                        <Col span={18}>
                                            <Form.Item
                                                label='Observaciones'
                                                name='observacion'
                                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                            >
                                                <TextArea
                                                    pattern="^^[a-zñA-ZÑ0-9 ÁÉÓÚÍáéóíú,.]+$"
                                                    title="Observaciones sin carácteres especiales"
                                                    maxLength={250}
                                                    placeholder='Observaciones de reprogramación.'
                                                    autoSize
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Reprogramar Tareas</Button>
                                        </Col>
                                    </Row>
                                </Form>
                                )}
                            </Col>
                            <Col span={24}>
                                <Table 
                                    size="small" 
                                    rowKey="idProgramacionRutina" 
                                    columns={columns}
                                    dataSource={calendarioDetalle}
                                    loading={fetchingCalendarioDetalle}
                                    locale={{ emptyText: 'Sin datos'}}
                                    pagination={false}
                                /> 
                            </Col>
                        </Row>
                        <ReprogramarTareas/>
                    </Spin>
                </Modal>
                ) : false}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalReprogramarDragDrop: state.ServiciosPlanificadosReducer.showModalReprogramarDragDrop,
        anteriorRMP: state.ServiciosPlanificadosReducer.anteriorRMP,
        nuevoRMP: state.ServiciosPlanificadosReducer.nuevoRMP,
        calendarioDetalle: state.ServiciosPlanificadosReducer.calendarioDetalle,
        noDragDrop: state.ServiciosPlanificadosReducer.noDragDrop,
        fetchingCalendarioDetalle: state.ServiciosPlanificadosReducer.fetchingCalendarioDetalle,
        busqueda: state.ServiciosPlanificadosReducer.busqueda,
        fetchingReprogramarTareaMultiple: state.ServiciosPlanificadosReducer.fetchingReprogramarTareaMultiple,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingReprogramarTareaMultiple: state.ServiciosPlanificadosReducer.fetchingReprogramarTareaMultiple
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalCalendarioDetalle: () => {
            dispatch({ type: 'SHOW_MODAL_REPROGRAMAR_DRAG_DROP', showModalReprogramarDragDrop: false });
        },
        onModalReprogramarTareaVC: (tareaSeleccionadaVC, busqueda, page) => {
            dispatch({ type: 'SHOW_MODAL_REPROGRAMAR_TAREA_VC', showModalReprogramarTareaVC: true, tareaSeleccionadaVC, busqueda, page, calendarioProgramacion: true });
        },
        onReprogramarTareaMultiple: (formulario, calendarioDetalle, busqueda) => {
            dispatch({ type: 'REPROGRAMAR_TAREA_MULTIPLE_REQUEST', formulario, calendarioDetalle, busqueda });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        },
        onCalendarioProgramacion: (busqueda) => {
            dispatch({ type: 'CALENDARIO_PROGRAMACION_REQUEST', busqueda });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalReprogramarDragDrop);