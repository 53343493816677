import React, { Component } from 'react';
import { Row, Col, Modal, Table, Button , Divider, Form,Spin, Select, Tooltip, Popover, Descriptions, Typography} from 'antd';
import { connect } from "react-redux";
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ContenedorEditarFolio from './ContenedorEditarFolio';
import { EditOutlined, FilePdfOutlined, ExceptionOutlined, FileDoneOutlined, ExportOutlined } from '@ant-design/icons';
import ModalCrearInstalacion from './ModalCrearInstalacion';

const { Option } = Select;
const {Text} = Typography;
const layout = { 
    labelCol: { span: 24 }, 
    wrapperCol: { span: 24 }
 };
class ModalCatalogoMovimientos extends Component {
    formRef = React.createRef();
    componentDidMount(){
        this.props.onGetUsuarios();
        this.props.onRequestInfoProyecto();
    }
    state = {
        page: 1,
        busqueda: {order: 'ASC', field: 'folio'},
    }
    render() { 
        const {
            movimientoSeleccionado, fetchingListaUsuariosActivos,listaDetalle,fetchinglistaDetalle,
            listaDetalleCantidad,onGetConsultaFolio, onModalEditarFolio,onCerrarFolioSinSalida,
            showModalOpcionesMovimientosAlmacen,listaUsuariosActivos, obtenerAlmacenPorUbicacionOrigen,
            onShowModalOpcionesMovimientosAlmacen, fetchingModalOpciones,obtenerAlmacenPorUbicacionDestino,
            onCancelarFolio,onCerrarFolio, onDescargarFolio, onBorrarConsulta
        } = this.props;
        
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const handleCancel = () => {
            onShowModalOpcionesMovimientosAlmacen();
            onBorrarConsulta();
        };

        const submitCancelar = () => {
            onCancelarFolio(movimientoSeleccionado, this.props.page, this.props.busqueda);
        };

        const submitCerrar = () => {
            onCerrarFolio(movimientoSeleccionado, this.props.page, this.props.busqueda);   
        }

        const editarFolio = () => {
            for (let i = 0; i < listaDetalle.length; i++) {
                for (let x = 0; x < movimientoSeleccionado.length; x++) {
                    if(movimientoSeleccionado[x].folio === listaDetalle[i].folio){
                        if(listaDetalle[i].tiposFoliosByIdTipoFolio.operacion === "Ambas"){
                            obtenerAlmacenPorUbicacionOrigen(listaDetalle[0].idUbicacionProductoOrigen);
                            obtenerAlmacenPorUbicacionDestino(listaDetalle[0].idUbicacionProductoDestino);
                        } else if(listaDetalle[i].tiposFoliosByIdTipoFolio.operacion === "Suma"){
                            obtenerAlmacenPorUbicacionDestino(listaDetalle[0].idUbicacionProductoDestino);
                        } else if(listaDetalle[i].tiposFoliosByIdTipoFolio.operacion === "Resta"){
                            obtenerAlmacenPorUbicacionOrigen(listaDetalle[0].idUbicacionProductoOrigen);
                        }
                            
                        onModalEditarFolio(movimientoSeleccionado, this.state.page);
                        onGetConsultaFolio(movimientoSeleccionado[x].folio, 1);
                    }
                }                 
            }
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            onGetConsultaFolio(listaDetalle[0].folio, pagination.current);
        }
        const darSalidaFolio = () => {            
            onCerrarFolioSinSalida(movimientoSeleccionado, this.props.page, this.props.busqueda);
        }
        
        console.log("movimientoSeleccionado",movimientoSeleccionado)

        const columns = [{
            title: 'Código de almacén',
            dataIndex: 'codigoAdicional',
            key: 'codigoAdicional',
            align: 'center',
        },{
            title: 'Nombre del Producto',
            dataIndex: 'nombre',
            key: 'nombre',
            align: 'center',
        },{
            title: 'Clave del producto',
            dataIndex: 'clave',
            key: 'clave',
            align: 'center',
        },{
        //     title: 'Número de serie',
        //     dataIndex: 'serieNum',
        //     key: 'serieNum',
        //     align: 'center',
        //     render: (text, record) => (
        //         record.serieNum ? text : 'No aplica'
        //     ),
        // },{
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
            align: 'center',
        },{

            title: 'Costo unitario',
            dataIndex: 'costoOriginalTabla',
            key: 'costoOriginalTabla',
            align: 'right'
        },{

            title: 'Tipo cambio',
            dataIndex: 'tipoCambioTabla',
            key: 'tipoCambioTabla',
            align: 'right'
        // }, {
        //         title: 'Moneda',
        //     dataIndex: 'tipoMoneda',
        //     key: 'tipoMoneda',
        //     align: 'center',
        },{
            title: 'Cantidad total',
            dataIndex: 'cantidadTotal',
            key: 'cantidadTotal',
            align: 'right'
        },{

            title: 'Costo total',
            dataIndex: 'costoTotal',
            key: 'costoTotal',
            align: 'right'
        }, {
            title: 'Lote',
            dataIndex: 'lote',
            key: 'lote',
            align: 'right'
        },{
            title: 'Fecha de caducidad',
            dataIndex: 'fechaCaducidad',
            key: 'fechaCaducidad',
            align: 'center',
        }];

        const content = (
        <Select allowClear
            style={{ width: '100%' }}
            placeholder="Selecciona el usuario responsable"
            loading={fetchingListaUsuariosActivos}
            onChange={(a) => { onDescargarFolio(movimientoSeleccionado, a); this.props.onVerificarSerieNum(movimientoSeleccionado, a)}}
        >
            {listaUsuariosActivos.map(option => <Option key={option.idUsuario}>{ option.nombre + " " + option.aPaterno + ": " + option.username}</Option>)}
        </Select>
        );

        const descargarSalidaInstalacion = () => {
            this.props.onDescargarSalidaInstalacion(movimientoSeleccionado[0].folio);
        }
        
        return (
            <div>
                <Modal
                    visible={showModalOpcionesMovimientosAlmacen}
                    title='Movimientos Almacén'
                    onCancel={handleCancel}
                    footer={false}
                    width={'70%'}
                >
                    <Spin spinning={fetchingModalOpciones || this.props.fetchingCerrarSinSalidaFolio || this.props.fetchingCancelarFolio || this.props.fetchingCerrarFolio || this.props.fetchingDescargarSalidaInstalacion || this.props.fetchingDescargarSeries || this.props.fetchingListaMovimientosGeneralBusqueda}>
                    {listaDetalle.length > 0 && movimientoSeleccionado ? ( 
                        <Form {...layout}
                            name="formulario"
                            initialValues={listaDetalle[0]}
                            ref={this.formRef}
                        >
                            <Row type='flex' justify='space-between'>
                                <Col span={22}>
                                    <Divider orientation="left">Movimiento Seleccionado</Divider>
                                </Col>
                                <Col span={1} style={{ paddingTop:'1em' }}>
                                    <Tooltip title='Editar Folio'>
                                        <Button className="iconTer" shape="circle" disabled={!permisosUsuario.editarFolioDeMovimientoDeAlmacén ? true : listaDetalle[listaDetalle?.length - 1].estadoFolio != "Abierto"} icon={<EditOutlined />} onClick={editarFolio}/>
                                    </Tooltip>
                                </Col>

                                <Col span={24}>
                                    <Descriptions layout="horizontal" bordered={true}>
                                        <Descriptions.Item label='Numero de Folio'> <Text type="warning" strong> {listaDetalle[0].folio} </Text> </Descriptions.Item>
                                        <Descriptions.Item label='Fecha de generación'>{listaDetalle[0].fechaAlta}</Descriptions.Item>
                                        <Descriptions.Item label='Fecha real de movimiento'>{listaDetalle[0].fechaMovimientoTabla}</Descriptions.Item>
                                        <Descriptions.Item label='Tipo'>{listaDetalle[0].nombreTipo}</Descriptions.Item>
                                        <Descriptions.Item label='Usuario que generó'>{listaDetalle[0].nombreUsuarioGenero}</Descriptions.Item>
                                        <Descriptions.Item label='Usuario que canceló o cerró'>{listaDetalle[0].nombreUsuarioCancelo}</Descriptions.Item>
                                    </Descriptions>
                                </Col>

                                <Col span={24}>
                                    <br />
                                    <Table
                                        size="small"
                                        rowKey="idMovimientoAlmacen"
                                        columns={columns}
                                        dataSource={listaDetalle}
                                        loading={fetchinglistaDetalle}
                                        locale={{ emptyText: 'Sin datos'}}
                                        onChange={handleTableChange}
                                        scroll={{"x": 1000 | true, "y": 1000}}
                                        pagination={{ total: listaDetalleCantidad, simple: true, current: this.state.page }}
                                    />
                                </Col>
                                
                                <Col span={17}>
                                    <Divider orientation='left'>Acciones</Divider>
                                </Col>

                                <Col span={1} style={{ paddingTop:'1em' }}>
                                    <Tooltip title='Dar Salida'>
                                        <Button className="iconTer" loading={this.props.fetchingCerrarSinSalidaFolio} shape="circle" disabled={!permisosUsuario.darSalidaFolioDeMovimientoDeAlmacén ? true : listaDetalle[0].estadoFolio != "Cerrado Sin Salida"} icon={<ExportOutlined />} onClick={darSalidaFolio}/>
                                    </Tooltip>
                                </Col>

                                <Col span={1} style={{ paddingTop:'1em' }}>
                                    <Tooltip title='Cancelar Folio'>
                                        <Button className="iconTer" loading={this.props.fetchingCancelarFolio} shape="circle" disabled={!permisosUsuario.cancelarFolioDeMovimientoDeAlmacén ? true : listaDetalle[0].estadoFolio != "Abierto"} icon={<ExceptionOutlined />} onClick={submitCancelar}/>
                                    </Tooltip>
                                </Col>

                                <Col span={1} style={{ paddingTop:'1em' }}>
                                    <Tooltip title='Cerrar Folio'>
                                        <Button className="iconTer" loading={this.props.fetchingCerrarFolio} shape="circle" disabled={!permisosUsuario.cerrarFolioFolioDeMovimientoDeAlmacén ? true : (listaDetalle[listaDetalle?.length - 1].estadoFolio === "Cerrado" || listaDetalle[listaDetalle?.length - 1].estadoFolio === "Cancelado")} icon={<FileDoneOutlined />} onClick={submitCerrar}/>
                                    </Tooltip>
                                </Col>
                                <Col span={1} style={{ paddingTop:'1em' }}>
                                    <Tooltip title='Crear Salida Instalación'>
                                        <Button className="iconTer" loading={this.props.fetchingCerrarFolio} shape="circle" disabled={listaDetalle[0]?.instalado ? true : movimientoSeleccionado[0]?.nombreTipoFolio === 'Salida por instalación' ? (!permisosUsuario.descargarFolioDeMovimientoDeAlmacén ? true : listaDetalle[listaDetalle?.length - 1].estadoFolio != "Cerrado") : true} icon={<FileDoneOutlined />} onClick={() => this.props.onShowModalCrearActivos()}/>
                                    </Tooltip>
                                </Col>

                                <Col span={1} style={{ paddingTop:'1em' }}>
                                    <Tooltip title='Descargar Salida Instalación'>
                                        <Button className="iconSecundary" shape='circle' disabled={movimientoSeleccionado[0]?.nombreTipoFolio === 'Salida por instalación' ? (!permisosUsuario.descargarFolioDeMovimientoDeAlmacén ? true : listaDetalle[listaDetalle?.length - 1].estadoFolio != "Cerrado") : true} icon={<FilePdfOutlined /> } loading={fetchingModalOpciones} onClick={descargarSalidaInstalacion}/>
                                    </Tooltip>
                                </Col>
                                
                                <Col span={1} style={{ paddingTop:'1em' }}>
                                    <Popover content={content} title="Usuario responsable para descargar folio" trigger="click" placement='bottomLeft'>
                                        <Tooltip title='Descargar Folio'>
                                            <Button className="iconSecundary" shape='circle' disabled={!permisosUsuario.descargarFolioDeMovimientoDeAlmacén} icon={<FilePdfOutlined /> } loading={fetchingModalOpciones}/>
                                        </Tooltip>
                                    </Popover>
                                </Col>
                            </Row>  
                        </Form>  
                        )  : false}
                    </Spin> 
                </Modal>
                <ContenedorEditarFolio />
                <ModalCrearInstalacion />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        movimientoSeleccionado: state.AlmacenesReducer.movimientoSeleccionado,
        showModalOpcionesMovimientosAlmacen: state.AlmacenesReducer.showModalOpcionesMovimientosAlmacen,
        listaDetalle: state.AlmacenesReducer.listaDetalle,
        listaDetalleCantidad: state.AlmacenesReducer.listaDetalleCantidad,
        fetchinglistaDetalle: state.AlmacenesReducer.fetchinglistaDetalle,
        listaUsuariosActivos: state.AlmacenesReducer.listaUsuariosActivos,
        fetchingListaUsuariosActivos: state.AlmacenesReducer.fetchingListaUsuariosActivos,
        fetchingModalOpciones: state.AlmacenesReducer.fetchingModalOpciones,
        page: state.AlmacenesReducer.page,
        busqueda: state.AlmacenesReducer.busqueda,
        fetchingCerrarFolio: state.AlmacenesReducer.fetchingCerrarFolio,
        fetchingCancelarFolio: state.AlmacenesReducer.fetchingCancelarFolio,
        fetchingCerrarSinSalidaFolio: state.AlmacenesReducer.fetchingCerrarSinSalidaFolio,
        fetchingDescargarSeries: state.AlmacenesReducer.fetchingDescargarSeries,
        fetchingDescargarSalidaInstalacion: state.AlmacenesReducer.fetchingDescargarSalidaInstalacion,
        fetchingListaMovimientosGeneralBusqueda: state.AlmacenesReducer.fetchingListaMovimientosGeneralBusqueda,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onShowModalOpcionesMovimientosAlmacen: () => {
            dispatch({ type: "SHOW_MODAL_OPCIONES_MOVIMIENTOS_ALMACEN", showModalOpcionesMovimientosAlmacen: false, movimientoSeleccionado: null })
        },
        onCancelarFolio: (movimientoSeleccionado, page, busqueda) => {
            dispatch({ type: "CANCELAR_FOLIO_REQUEST", movimientoSeleccionado: movimientoSeleccionado, page, busqueda});
        },
        onDescargarFolio: (movimientoSeleccionado, idUsuarioRecibe) => {
            dispatch({ type: "IMPRIMIR_FOLIO_REQUEST", movimientoSeleccionado, idUsuarioRecibe });
        },
        onVerificarSerieNum: (movimientoSeleccionado, idUsuarioRecibe) => {
            dispatch({ type: "VERIFICAR_SERIENUM_REQUEST", movimientoSeleccionado, idUsuarioRecibe });
        },
        onGetUsuarios: () => {
            dispatch({ type: "LISTA_USUARIOS_ACTIVOS_REQUEST" });
        },
        onCerrarFolio: (movimientoSeleccionado, page, busqueda) => {
            dispatch({ type: "CERRAR_FOLIO_REQUEST", movimientoSeleccionado, page, busqueda})
        },
        onModalEditarFolio: (movimientoSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_FOLIO", showModalEditarFolio: true, movimientoSeleccionado: movimientoSeleccionado, page: page})
        },
        onGetConsultaFolio: (folio, page) => {
            dispatch({ type: "LISTA_DETALLE_CONSULTA_REQUEST", folio: folio, page: page});
        },
        onCerrarFolioSinSalida: (movimientoSeleccionado, page, busqueda) => {
            dispatch({ type: "CERRAR_FOLIO_SIN_SALIDA_REQUEST", movimientoSeleccionado: movimientoSeleccionado, page: page, busqueda: busqueda})
        },
        onBorrarConsulta: () => {
            dispatch({ type: "LISTA_DETALLE_CONSULTA_SUCCESS", listaDetalle : []});
        },
        obtenerAlmacenPorUbicacionOrigen: (idUbicacionProductoOrigen) => {
            dispatch({ type: "OBTENER_ALMACEN_POR_UBICACION_REQUEST" , idUbicacionProductoOrigen});
        },
        obtenerAlmacenPorUbicacionDestino: (idUbicacionProductoDestino) => {
            dispatch({ type: "OBTENER_ALMACEN_POR_UBICACION_DESTINO_REQUEST", idUbicacionProductoDestino})
        },
        onShowModalCrearActivos: () => {
            dispatch({ type: 'SHOW_MODAL_CREAR_INSTALACION', showModalCrearActivos: true })
        },
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        },
        onDescargarSalidaInstalacion: (folio) => {
            dispatch({ type: 'DESCARGAR_SALIDA_INSTALACION_REQUEST', folio })
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ModalCatalogoMovimientos);