import React from 'react';
import { Card, Pagination, List, Input } from 'antd';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Sistema.css';
const { Search } = Input;
class ListaPermisos extends React.Component {
    componentDidMount(){
        this.props.onRequestPermisosBusqueda(1,'');
    }
    state={
        page: 1,
        busqueda: '',
    }
    render() {
        const { fetchingListaPermisosBusqueda, listaPermisosBusqueda, onRequestPermisosBusqueda, 
            listaPermisosBusquedaCantidad, 
        } = this.props;
        console.log(listaPermisosBusquedaCantidad);
        const onBuscarPermiso = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            onRequestPermisosBusqueda(1, busqueda);
        }

        const onChangePage = (e) => {
            this.setState({
                page: e,
            })
            onRequestPermisosBusqueda(e, this.state.busqueda);
        }
        return (
            <div>
                <Card className="cardCatalogos" title={<div className='headerLista'>Permisos</div>}>
                <Search allowClear className='buscarModulos' placeholder="Ingresa el permiso" onSearch={value => onBuscarPermiso(value)} style={{ width: '100%' }}/>
                    {listaPermisosBusqueda ? (
                        <List 
                            className="listaSistema"
                            loading={fetchingListaPermisosBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            dataSource={listaPermisosBusqueda}
                            renderItem={item => (
                               <List.Item className='listaHover' className='listaHover'>
                                    <p>{item.descripcion}</p>
                                </List.Item>
                            )}
                        />
                    ):(false)} 
                    <Pagination simple total={listaPermisosBusquedaCantidad} current={this.state.page} onChange={onChangePage} /> 
                </Card> 
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaPermisosBusqueda: state.ConfiguracionYSeguridadReducer.fetchingListaPermisosBusqueda,
        listaPermisosBusqueda: state.ConfiguracionYSeguridadReducer.listaPermisosBusqueda,
        listaPermisosBusquedaCantidad: state.ConfiguracionYSeguridadReducer.listaPermisosBusquedaCantidad,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onRequestPermisosBusqueda: (page, busqueda) => {
	    	dispatch({ type: "GET_PERMISOS_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ListaPermisos);