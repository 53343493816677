import React, { Component } from 'react';
import { Row, Form, Input, Button, Col, Select, InputNumber, message, Divider, Spin } from 'antd';
import { MinusCircleOutlined, PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import ModalContraseña from './ModalContraseña';

const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ListasEppInsumosHerramienta extends Component {
    formRef = React.createRef();
    state={
        listaInsumos: [],
        formulario: null,
        formularioTerminado: null,
    }
    render() {


        const {
            limpiarFormulario, onLimpiarFormulario, fetchingListaProductosActivosBusqueda,
            listaProductosActivosBusqueda, onSiguienteVista, formularioTerminado,
            onListaProductosActivosBusqueda, onListaProductosActivosBusquedaLimpiar,
            folioSolicitudServicioSeleccionado, ubicacionSeguimiento, page, busqueda,
            onActualizarSeguimiento, onShowModalContraseña, isEspecial,fetchingCambioEstadoSeguimiento,
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario')
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        if (isEspecial) {
            onActualizarSeguimiento(this.state.formulario, this.state.formularioTerminado, folioSolicitudServicioSeleccionado,ubicacionSeguimiento, page, busqueda);
            onShowModalContraseña(false, false);
        };

        const onFinish = (formulario) => {
            console.log(formulario)
            if (permisosUsuario.especialSolicitudDeServicio) {
                this.setState({ formulario: formulario, formularioTerminado: formularioTerminado })
                onShowModalContraseña(true, false);
            } else {
                onActualizarSeguimiento(formulario, formularioTerminado, folioSolicitudServicioSeleccionado, ubicacionSeguimiento, page, busqueda);
            }

        };

        if (limpiarFormulario && limpiarFormulario === 'terminado') {
            this.formRef.current.resetFields();
            onLimpiarFormulario();
            onSiguienteVista(false, null, null, null, null, folioSolicitudServicioSeleccionado );
        }
        const onInteger = (value, field) => {
            if (value) {
                if (!Number.isInteger(value)) {
                    let formulario = this.formRef.current.getFieldsValue('insumos');
                    formulario.insumos[field.key].cantidad = parseInt(value);
                    this.formRef.current.setFieldsValue({ 'insumos': formulario.insumos });
                }
            }
        }

        const pasoAnterior = () => {
            onSiguienteVista(false, formularioTerminado, null, null, null, folioSolicitudServicioSeleccionado);

        }

        const buscarActivoCarac = (value) => {
            if (value.length >= 3) {
                console.log(value)
                onListaProductosActivosBusqueda(value);
            }
            else {
                onListaProductosActivosBusquedaLimpiar();
            }
        };

        const onProductoSeleccionado = (value, field) => {
            console.log(value, field);
        }

        return (
            <div>
           <Spin spinning={fetchingCambioEstadoSeguimiento} >
            <Form {...layout}
                name="formulario"
                onFinish={onFinish}
                ref={this.formRef}
            >
                
                <Divider>Agregar insumos</Divider>
                <Form.List name="insumos">
                    {(fields, { add, remove }) => (
                    <>
                        {fields.map(field => (
                        <Row justify='space-around' key={field.key}>
                            <Col span={14}>
                                <Form.Item
                                    {...field}
                                    label='Producto insumo'
                                    name={[field.name, 'insumo']}
                                    fieldKey={[field.fieldKey, 'insumo']}
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Ingresa nombre, clave o SKU del producto"
                                        loading={fetchingListaProductosActivosBusqueda}
                                        showSearch
                                        filterOption={false}
                                        onSearch={buscarActivoCarac}
                                        onChange={(value) => onProductoSeleccionado(value, field)}
                                        notFoundContent={null}
                                    >
                                        {listaProductosActivosBusqueda.map(option => <Option key={option.idProducto + ' - ' + option.nombre + ' - Unidad de medida: ' + option.unidadMedida}>{option.idProducto + ' - ' + option.nombre + ' - Unidad de medida: ' + option.unidadMedida}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    {...field}
                                    label='Cantidad'
                                    name={[field.name, 'cantidad']}
                                    fieldKey={[field.fieldKey, 'cantidad']}
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <InputNumber min={1} onChange={(value) => onInteger(value, field)} style={{width: '100%'}} placeholder='Ingresa la cantidad'/>
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                {fields.length > 1 ? (
                                <Form.Item>
                                    <MinusCircleOutlined className='borrarInsumo' onClick={() => remove(field.name)} />
                                </Form.Item>
                                ):false}
                            </Col>
                        </Row>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>Agregar Insumo</Button>
                        </Form.Item>
                    </>
                    )}
                </Form.List>

                {/* form Herramientas */}
                <Divider>Agregar herramientas</Divider>
                <Form.List name="herramientas">
                    {(fields, { add, remove }) => (
                    <>
                        {fields.map(field => (
                        <Row justify='space-around' key={field.key}>
                            <Col span={14}>
                                <Form.Item
                                    {...field}
                                    label='Herramientas'
                                    name={[field.name, 'herramienta']}
                                    fieldKey={[field.fieldKey, 'herramienta']}
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input pattern="^^[ñíóáéú a-zA-Z 0-9]+$"
                                           title="Nombre debe tener letras y numeros."
                                           maxLength={100} placeholder='Ingrese Herramienta'/>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    {...field}
                                    label='Cantidad'
                                    name={[field.name, 'cantidad']}
                                    fieldKey={[field.fieldKey, 'cantidad']}
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <InputNumber min={1} onChange={(value) => onInteger(value, field)} style={{width: '100%'}} placeholder='Ingresa la cantidad'/>
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                {fields.length > 1 ? (
                                <Form.Item>
                                    <MinusCircleOutlined className='borrarInsumo' onClick={() => remove(field.name)} />
                                </Form.Item>
                                ):false}
                            </Col>
                        </Row>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}> Agregar herramienta</Button>
                        </Form.Item>
                    </>
                    )}
                </Form.List>


                {/* form Epp */}
                <Divider>Agregar equipo de protección personal</Divider>
                <Form.List name="epp">
                    {(fields, { add, remove }) => (
                    <>
                        {fields.map(field => (
                        <Row justify='space-around' key={field.key}>
                            <Col span={14}>
                                <Form.Item
                                    {...field}
                                    label='Equipo de protección personal'
                                    name={[field.name, 'epp']}
                                    fieldKey={[field.fieldKey, 'epp']}
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input pattern="^^[ñíóáéú a-zA-Z 0-9]+$"
                                           title="Nombre debe tener letras y numeros."
                                           maxLength={100} placeholder='Ingrese Epp'/>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    {...field}
                                    label='Cantidad'
                                    name={[field.name, 'cantidad']}
                                    fieldKey={[field.fieldKey, 'cantidad']}
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <InputNumber min={1} onChange={(value) => onInteger(value, field)} style={{width: '100%'}} placeholder='Ingresa la cantidad'/>
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                {fields.length > 1 ? (
                                <Form.Item>
                                    <MinusCircleOutlined className='borrarInsumo' onClick={() => remove(field.name)} />
                                </Form.Item>
                                ):false}
                            </Col>
                        </Row>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}> Agregar Epp</Button>
                        </Form.Item>
                    </>
                    )}
                </Form.List>


                <Row justify='space-around'>
                    <Col span={11} className='marginBoton'>
                        <br/>
                        <Button className='btnCargaArchivos' onClick={pasoAnterior}><ArrowLeftOutlined /> Paso Anterior</Button>
                    </Col>
                    
                    <Col span={11} className='marginBoton'>
                        <br/>
                        <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Registrar</Button>
                    </Col>   
                    
                </Row>
            </Form>
            </Spin>
            <ModalContraseña/>
            </div>
        );
    }
}

const mapStateToProps = state => {
	return {
        formularioTerminado: state.SolicitudDeServiciosReducer.formularioTerminado,
        page: state.SolicitudDeServiciosReducer.page,
        busqueda: state.SolicitudDeServiciosReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        folioSolicitudServicioSeleccionado: state.SolicitudDeServiciosReducer.folioSolicitudServicioSeleccionado, 
        ubicacionSeguimiento: state.SolicitudDeServiciosReducer.ubicacionSeguimiento, 
        fetchingListaProductosActivosBusqueda: state.AlmacenesReducer.fetchingListaProductosActivosBusqueda,
        listaProductosActivosBusqueda: state.AlmacenesReducer.listaProductosActivosBusqueda,
        listaEpp : state.SolicitudDeServiciosReducer.listaEpp,
        listaHerramienta: state.SolicitudDeServiciosReducer.listaHerramienta,
        listaInsumosT: state.SolicitudDeServiciosReducer.listaInsumosT,
        isEspecial: state.SolicitudDeServiciosReducer.isEspecial,
        fetchingCambioEstadoSeguimiento: state.SolicitudDeServiciosReducer.fetchingCambioEstadoSeguimiento,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onActualizarSeguimiento: ( formulario, formularioTerminado, folioSolicitudServicioSeleccionado, ubicacionSeguimiento , page, busqueda ) => {
            dispatch({ type: "TERMINADO_FOLIO_SS_REQUEST", formulario, formularioTerminado, folioSolicitudServicioSeleccionado, ubicacionSeguimiento ,page, busqueda});
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onSiguienteVista: (cambiarVistaModalEstadoTerminado, formularioTerminado, listaEpp, listaHerramienta, listaInsumosT, folioSolicitudServicioSeleccionado) => {
            dispatch({ type: "CAMBIAR_VISTA_MODAL_ESTADO_TERMINADO", cambiarVistaModalEstadoTerminado, formularioTerminado, listaEpp, listaHerramienta, listaInsumosT, folioSolicitudServicioSeleccionado});
        },
        onListaProductosActivosBusqueda: (busqueda) => {
            dispatch({ type: 'LISTA_PRODUCTOS_BUSQUEDA_REQUEST', busqueda });
        },
        onListaProductosActivosBusquedaLimpiar: () => {
            dispatch({ type: 'LISTA_PRODUCTOS_BUSQUEDA_SUCCESS', listaProductosActivosBusqueda: [] });
        },
        onShowModalContraseña: (showModalContraseña, isEspecial) => {
            dispatch({ type: "SHOW_MODAL_CONTRASEÑA", showModalContraseña , isEspecial})
        } 
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ListasEppInsumosHerramienta);