import React, { Component } from 'react';
import {Row, Col, Modal, List} from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalDetalleProducto extends Component {
    formRef = React.createRef();

    render() {
        const { onShowModalDetalleProducto, showModalDetalleProducto, productoSeleccionado} = this.props;
//
        let data=[]
        if (productoSeleccionado){
            const {caracteristicaLote, minimo, maximo, reorden, unidadMedida, familia, subFamilia, fechaAlta,
                marca, modelo, fabricante
            } = productoSeleccionado;
            
            data = [
            {title: "Marca", data: marca}, {title: "Modelo", data: modelo}, {title: "Fabricante", data: fabricante}, 
            {title:"Característica lote", data: caracteristicaLote===true?'Si':'No'}, {title: "Mínimo", data: minimo}, 
            {title: "Máximo",data: maximo}, {title: "Punto de reorden", data: reorden}, {title: "Unidad de medida", data: unidadMedida}, 
            {title: "Familia", data: familia}, {title: "Subfamilia", data: subFamilia},
            {title: "Fecha de alta", data: fechaAlta}
            ];
        }


        const handleCancel = () => {
            onShowModalDetalleProducto();
        }
        
        return (
            <Modal
                visible={showModalDetalleProducto}
                title='Detalle del Producto/Insumo/Herramienta'
                onCancel={handleCancel}
                footer={false}
            >
                {productoSeleccionado ? (
                    <Row justify='space-around'>
                        <Col span={24}>
                            <List
                            className="listaCatalogos"
                            bordered
                            dataSource={data}
                            renderItem={item => (
                               <List.Item className='listaHover'>
                                        <Col span={11}>
                                            <strong>{item.title}</strong>  
                                        </Col>
                                        <Col span={11}>
                                            {item.data!==null ? item.data : '-'} 
                                        </Col>
                                </List.Item>
                            )}
                            />
                        </Col>
                    </Row>
                ) : false}
                
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalDetalleProducto: state.AlmacenesReducer.showModalDetalleProducto,
        productoSeleccionado: state.AlmacenesReducer.productoSeleccionado,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalDetalleProducto: () => {
            dispatch({ type: 'SHOW_MODAL_DETALLE_PRODUCTO', showModalDetalleProducto: false });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetalleProducto);