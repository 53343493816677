import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import { Row, Col, Spin, Modal, Select} from 'antd';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import NuevoFolioError from './NuevoFolioError';
import NuevoFolioOmision from './NuevoFolioOmision';

const { Option } = Select;

function ModalNuevoFolioEO (props) {

    const {fetchingNuevoFolioEO, showModalNuevoFolioEO,
        onShowModalNuevoFolio
    } = props;

    const [tipo, setTipo] = useState(null)

    useEffect (() => {
        setTipo(null);
    },[showModalNuevoFolioEO])

    const handleSelect= value => {
        setTipo(value);
    }

    const handleCancel = () => {
        onShowModalNuevoFolio();
        setTipo(null);
    }

    return (
    <>
        <Modal
            visible={showModalNuevoFolioEO}
            title='Generar Nuevo Folio de Error u Omisión'
            onCancel={handleCancel}
            footer={false}
            width={tipo ? '90%' : '40%'}
        >
            <Spin spinning={fetchingNuevoFolioEO}>
                {!tipo ? (
                    <Row justify='center'>
                        <Col span={5} >
                            Tipo de Folio:
                        </Col>
                        <Col span={18} >
                            <Select
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Selecciona un tipo de folio"
                                filterOption={false}
                                notFoundContent={null}
                                onChange = {handleSelect}
                            >
                                <Option key={1} value={'Error'}> 
                                    Registro de Errores en la Información 
                                </Option>
                                <Option key={2} value={'Omision'}> 
                                    Registro de Omisiones en la Información  
                                </Option>
                            </Select>
                        </Col>
                    </Row>
                ) : 
                tipo === 'Error' ? 
                <NuevoFolioError /> : <NuevoFolioOmision /> 
                }
               

            </Spin>

        </Modal>
    </>
    )
}

const mapStateToProps = state => {
    return {
        fetchingNuevoFolioEO: state.MecanismosDePagosReducer.fetchingNuevoFolioEO,
        showModalNuevoFolioEO: state.MecanismosDePagosReducer.showModalNuevoFolioEO,
        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevoFolio: () => {
            dispatch({ type:'MODAL_NUEVO_FOLIO_EO', showModalNuevoFolioEO: false})
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalNuevoFolioEO);