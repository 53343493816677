import React from 'react';
import { Row, Col, Modal, Input, Button, Form, Tooltip, Table, Switch, Select, DatePicker } from 'antd';
import { connect } from "react-redux";
import { FileExcelOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

class DetalleCalculoDeductivasEO extends React.Component {
     componentDidMount(){
          this.props.onGetGruposServicios();
          this.props.onGetListaTiposFallasActivas();
          this.props.onGetAreasFuncionales();
          this.props.onGetServiciosActivos();
     }
     formRef = React.createRef();
     state = {
          page: 1,
          idTipoFallaState: '', idAreaFuncionalState: '', idGrupoSerState: '', idServicioState: '', fechaCierre1State: '', fechaCierre2State: '',
     }
     render() {
          const { 
               onCloseModal, showModalDetalleCalculoError, listaDetalleCalculoDEO, fetchingListaDetalleCalculoError, listaDetalleCalculoDEOCantidad, fetchingListaGruposServicios, listaGruposServicios, onGetDetelle, idMesCalendario, fechaProyeccion, proyeccion, fetchingListaTiposFallasActivas, listaTiposFallasActivas, listaAreasFuncionalesActivas, fetchingListaAreasFuncionalesActivas, listaServiciosActivosF, fetchingListaServiciosActivosF, listaServiciosByGrupoServicio, onGetServiciosByGrupoServicio, onGetDetalleExcel, fetchingExportarExcelDetalleCDEO, onProcesarDeductiva, fetchingProcesarDeductivaError
          } = this.props;

          const handleCancel = () => {
               onCloseModal();
               this.formRef.current.resetFields();
          };

          const handleTableChange = (pagination) => {
               this.setState({ page: pagination.current });
          };

          const onDescarga = () => {
               onGetDetalleExcel(fechaProyeccion, idMesCalendario, proyeccion, this.state.idTipoFallaState, this.state.idAreaFuncionalState, this.state.idGrupoSerState, this.state.idServicioState, this.state.fechaCierre1State, this.state.fechaCierre2State);
          };

          const procesarDeductiva = () => {
               onProcesarDeductiva(fechaProyeccion, idMesCalendario);
          };

          const columns = [
               { title: 'Ticket', dataIndex: 'ticket', key: 'ticket', align: 'center',  width: '2%' },
               { title: 'Tipo ticket', dataIndex: 'tipoTicket', key: 'tipoTicket', align: 'center', width: "3%"  },
               { title: <Tooltip title='Persona que reportó la falla '>Origen</Tooltip>, dataIndex: 'origen', key: 'origen',  align: 'center', width: "5%"  },
               { title: <Tooltip title='Persona que capturó la Solicitud de Servicio'>Personal CCO</Tooltip>, dataIndex: 'personalCCO', key: 'personalCCO', align: 'center', width: "5%" },
               { title: <Tooltip title='Nombre del servicio al que corresponde la falla'>Servicio</Tooltip>, dataIndex: 'nombreServicio', key: 'nombreServicio', align: 'center', width: "8%" },
               { title: <Tooltip title='Clave del área en la que ocurrió la falla'>Clave área</Tooltip>, dataIndex: 'claveArea', key: 'claveArea', align: 'center', width: "2%" },
               { title: <Tooltip title='Nombre del área donde ocurrió la falla '>Nombre área</Tooltip>, dataIndex: 'areaFuncional', key: 'areaFuncional',  align: 'center', width: "8%" },
               { title: 'Código área', key: 'codigoArea', dataIndex: 'codigoArea', align: 'center', width:'2%' },
               // { title: 'Tipo deductiva', dataIndex: 'tipoDeductiva', key: 'tipoDeductiva', align: 'center', width: "4%" },
               { title: <Tooltip title='Tipo de falla, según lo capturado por CCO'>Tipo falla</Tooltip>, dataIndex: 'tipoFalla', key: 'tipoFalla', align: 'center', width: "6%" },
               { title: <Tooltip title='Descripción de la falla capturada por el CCO'>Descripción falla</Tooltip>, dataIndex: 'descripcionSolicitud', key: 'descripcionSolicitud', align: 'center', width: "18%" },
               { title: <Tooltip title='Fecha y hora de creación de la Solicitud de Servicio'>Fecha creación</Tooltip>, dataIndex: 'fechaAltaTabla', key: 'fechaAltaTabla', align: 'center', width: "3%"  },
               { title: <Tooltip title='Fecha y hora de cierre de la Solicitud de Servicio'>Fecha cierre</Tooltip>, dataIndex: 'fechaCierreTabla', key: 'fechaCierreTabla', align: 'center', width: "3%"  },
               { title: <Tooltip title='Nombre de la categoría a la que corresponde la ubicación de la falla'>Categoría área</Tooltip>, dataIndex: 'numeroFallaCategoriaTabla', key: 'numeroFallaCategoriaTabla', align: 'center', width: "2%" },
               { title: 'Clave estándar', dataIndex: 'referenciaEstandarTabla', key: 'referenciaEstandarTabla', align: 'center', width: "2%" },
               { title: 'Descripción estándar', dataIndex: 'descripcionEstandarTabla', key: 'descripcionEstandarTabla', align: 'center', width: "13%" },
               { title: 'Categoría calidad', dataIndex: 'fallaCalidadTabla', key: 'fallaCalidadTabla', align: 'center', width: "3%" },
               { title: 'Categoría disponibilidad', dataIndex: 'fallaDisponibilidadTabla', key: 'fallaDisponibilidadTabla', align: 'center', width: "10%" },
               { title: <Tooltip title='Valor del Pago Mensual de Inversión'>PMI</Tooltip>, dataIndex: 'pmiTabla', key: 'pmiTabla', align: 'right', width: "3%" },
               { title: <Tooltip title='Valor del Pago Mensual de Referencia'>PMR</Tooltip>, dataIndex: 'pmrTabla', key: 'pmrTabla', align: 'right', width: "3%" },
               { title: <Tooltip title='Valor de los días el mes en que se da el servicio para el mes i '>dm</Tooltip>, dataIndex: 'dmi', key: 'dmi', align: 'center', width: "2%" },
               { title: <Tooltip title='Valor del peso del de área funcional'>psa</Tooltip>, dataIndex: 'psaTabla', key: 'psaTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor para del factor de seguridad'>fsa</Tooltip>, dataIndex: 'fsaTabla', key: 'fsaTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor para el inpc de la fecha de inicio de operaciones'>INPC0</Tooltip>, dataIndex: 'inpc0', key: 'inpc0', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor del INPC para el mes i-1'>INPCi-1</Tooltip>, dataIndex: 'inpcCalculo', key: 'inpcCalculo', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor del número de turnos existentes para el área funcional'>ut</Tooltip>, dataIndex: 'utTabla', key: 'utTabla', align: 'center', width: "2%" },
               { title: <Tooltip title='Valor del número de turnos en los que ocurre la falla'>utf</Tooltip>, dataIndex: 'utfTabla', key: 'utfTabla', align: 'center', width: "2%" },
               { title: <Tooltip title='Valor del porcentaje de dedución por fallas de disponibilidad'>pdfd</Tooltip>, dataIndex: 'pdfdTabla', key: 'pdfdTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor del ponderador de servicios'>ps</Tooltip>, dataIndex: 'psTabla', key: 'psTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor del ponderador de categoría de falla de calidad'>pcfc</Tooltip>, dataIndex: 'pcfcTabla', key: 'pcfcTabla', align: 'right', width: "2%" },
               { title: 'Deductiva', dataIndex: 'deductivaTabla', key: 'deductivaTabla', align: 'right', width: "3.5%" },
               { title: 'Deductiva parcial', dataIndex: 'deductivaParcialTexto', key: 'deductivaParcialTexto', align: 'center', width: "2%" },
               { title: 'Conciliada', dataIndex: 'conciliada', key: 'conciliada', align: 'center', width: "2%",
               render: (text) => (
                   <Switch checked={text} disabled={true}/>
               )},
               { title: <Tooltip title='Valor para el ponderador de servicio por área'>pss_x_a</Tooltip>, dataIndex: 'ponderacionServAreaTabla', key: 'ponderacionServAreaTabla', align: 'center', width: "2%" },
               { title: 'Fecha cierre mes', dataIndex: 'fechaCierreMesTabla', key: 'fechaCierreMesTabla', align: 'center', width: "3%" },
               { title: 'Ticket reiterado', dataIndex: 'ticketReiteradoTabla', key: 'ticketReiteradoTabla', align: 'center', width: "2%" },
               { title: 'Número referencia', dataIndex: 'numReferenciaTabla', key: 'numReferenciaTabla', align: 'center', width: "2%" },
               { title: 'Tiempo garantía', dataIndex: 'tiempoGarantiaTabla', key: 'tiempoGarantiaTabla', align: 'center', width: "2.5%" },
          ];

          const scroll = {"x": 6800 | true, "y": 1000};

          const tipoFallaSelected = (value) => {
               if(value){
                    let idTipoFallaLocal = parseInt(value.split(' -')[0]);
                    this.setState({ idTipoFallaState: idTipoFallaLocal, page: 1 });
                    onGetDetelle(fechaProyeccion, idMesCalendario, idTipoFallaLocal, this.state.idAreaFuncionalState, this.state.idGrupoSerState, this.state.idServicioState, this.state.fechaCierre1State, this.state.fechaCierre2State);
               } else {
                    this.setState({ idTipoFallaState: '', page: 1 });
                    onGetDetelle(fechaProyeccion, idMesCalendario, '', this.state.idAreaFuncionalState, this.state.idGrupoSerState, this.state.idServicioState, this.state.fechaCierre1State, this.state.fechaCierre2State);
               }
          };

          const areaFuncionalSelected = (value) => {
               if(value){
                    let idAFLocal = parseInt(value.split(' -')[0]);
                    this.setState({ idAreaFuncionalState: idAFLocal, page: 1 });
                    onGetDetelle(fechaProyeccion, idMesCalendario, this.state.idTipoFallaState, idAFLocal, this.state.idGrupoSerState, this.state.idServicioState, this.state.fechaCierre1State, this.state.fechaCierre2State);
               } else {
                    this.setState({ idAreaFuncionalState: '', page: 1 });
                    onGetDetelle(fechaProyeccion, idMesCalendario, this.state.idTipoFallaState, '', this.state.idGrupoSerState, this.state.idServicioState, this.state.fechaCierre1State, this.state.fechaCierre2State);
               }
          };

          const grupoServicioSelected = (value) => {
               if(value){
                    let idGrupoLocal = parseInt(value.split(' -')[0]);
                    this.setState({ idGrupoSerState: idGrupoLocal, idServicioState: '', page: 1 });
                    onGetServiciosByGrupoServicio(idGrupoLocal);

                    this.formRef.current.resetFields(['servicio']);
                    //this.formRef.current.setFieldsValue({'servicioByGrupo': null, 'servicio': null});
                    onGetDetelle(fechaProyeccion, idMesCalendario, this.state.idTipoFallaState, this.state.idAreaFuncionalState, idGrupoLocal, '', this.state.fechaCierre1State, this.state.fechaCierre2State);
               } else {
                    this.setState({ idGrupoSerState: '', idServicioState: '', page: 1 });
                    this.formRef.current.resetFields(['servicio']);
                    //this.formRef.current.setFieldsValue({'servicioByGrupo': null, 'servicio': null});
                    onGetDetelle(fechaProyeccion, idMesCalendario, this.state.idTipoFallaState, this.state.idAreaFuncionalState, '', '', this.state.fechaCierre1State, this.state.fechaCierre2State);
               }
          };

          const servicioSelected = (value) => {
               if(value){
                    let idServicioLocal = parseInt(value.split(' -')[0]);
                    this.setState({ idServicioState: idServicioLocal, page: 1 });
                    onGetDetelle(fechaProyeccion, idMesCalendario, this.state.idTipoFallaState, this.state.idAreaFuncionalState, this.state.idGrupoSerState, idServicioLocal, this.state.fechaCierre1State, this.state.fechaCierre2State);
               } else {
                    this.setState({ idServicioState: '', page: 1 });
                    onGetDetelle(fechaProyeccion, idMesCalendario, this.state.idTipoFallaState, this.state.idAreaFuncionalState, this.state.idGrupoSerState, '', this.state.fechaCierre1State, this.state.fechaCierre2State);
               }

          };

          const fechasSelected = (rangeValue) => {
               if(rangeValue){
                    this.setState({ fechaCierre1State: rangeValue[0].format('YYYY-MM-DD'), fechaCierre2State: rangeValue[1].format('YYYY-MM-DD'), page: 1 });
                    this.setState({ fechaCierre1State: '', fechaCierre2State: '' });
                    onGetDetelle(fechaProyeccion, idMesCalendario, this.state.idTipoFallaState, this.state.idAreaFuncionalState, this.state.idGrupoSerState, this.state.idServicioState, rangeValue[0].format('YYYY-MM-DD'), rangeValue[1].format('YYYY-MM-DD')); 
               } else {
                    this.setState({ fechaCierre1State: '', fechaCierre2State: '', page: 1 });
                    onGetDetelle(fechaProyeccion, idMesCalendario, this.state.idTipoFallaState, this.state.idAreaFuncionalState, this.state.idGrupoSerState, this.state.idServicioState, '', ''); 
               }
          };

          const disabledRange = current => {
               if(fechaProyeccion){
                    let fechaDisabled = fechaProyeccion.split('-')[0] + '-' + fechaProyeccion.split('-')[1] + '-01';
                    return current && current < moment(fechaDisabled, "YYYY-MM-DD");
               }
          };

          return (
               <>
                    { true ? (
                         <Modal
                              visible={showModalDetalleCalculoError}
                              title={'Detalle para Cálculo de Deductivas por Errores u Omisiones'}
                              onCancel={handleCancel}
                              footer={false}
                              width={'90%'}
                         >
                              <Form
                                   name="formulario"
                                   ref={this.formRef}
                              >
                                   <Row justify='space-around'>
                                        <Col span={4}>
                                             <Form.Item name='tipoFalla'>
                                                  <Select
                                                       style={{ width: '100%', textAlign: 'left' }} placeholder="Selecciona el tipo de falla" loading={fetchingListaTiposFallasActivas} onChange={tipoFallaSelected} showSearch allowClear
                                                  >
                                                       {listaTiposFallasActivas.map(option => 
                                                       <Option key={option.idTipoFalla + ' - ' + option.clave + ': ' + option.nombre  + ' (' + option.tipo + ')'}>
                                                            <Tooltip placement="topLeft" title={option.idTipoFalla + ' - ' + option.clave + ': ' + option.nombre  + ' (' + option.tipo + ')'}>
                                                                 {option.idTipoFalla + ' - ' + option.clave + ': ' + option.nombre  + ' (' + option.tipo + ')'}
                                                            </Tooltip>
                                                       </Option>)}
                                                  </Select>
                                             </Form.Item>
                                        </Col>

                                        <Col span={4}>
                                             <Form.Item name='areaFuncional'>
                                                  <Select 
                                                       allowClear showSearch style={{ width: '100%' }} placeholder="Seleccione la área funcional" loading={fetchingListaAreasFuncionalesActivas} onChange={areaFuncionalSelected}
                                                  >
                                                       {listaAreasFuncionalesActivas.map(option => 
                                                       <Option key={option.idAreaFuncional + ' - ' +  option.claveArea + ': ' + option.areaFuncional}>
                                                            <Tooltip title={option.idAreaFuncional + ' - ' +  option.claveArea + ': ' + option.areaFuncional}> {option.idAreaFuncional + ' - ' +  option.claveArea + ': ' + option.areaFuncional} </Tooltip>
                                                       </Option>)}
                                                  </Select>
                                             </Form.Item>
                                        </Col>

                                        <Col span={5}>
                                             <Form.Item name='grupoServicio'>
                                                  <Select 
                                                       allowClear  style={{ width: '100%' }} placeholder="Selecciona un grupo de servicio" loading={fetchingListaGruposServicios} onChange={grupoServicioSelected} showSearch
                                                  >
                                                       {listaGruposServicios.map(option => <Option key={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}><Tooltip title={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}>{option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}</Tooltip></Option>)}
                                                  </Select>
                                             </Form.Item>
                                        </Col>

                                        <Col span={5}>
                                             <Form.Item name='servicio'>
                                                  <Select
                                                       allowClear showSearch style={{ width: '100%' }} placeholder="Selecciona el servicio" loading={fetchingListaServiciosActivosF} onChange={servicioSelected}
                                                  >
                                                       { this.state.idGrupoSerState === '' ? (<>
                                                            {listaServiciosActivosF.map(option => 
                                                            <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                                 <Tooltip placement="topLeft" title={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                                      { option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre }
                                                                 </Tooltip>
                                                            </Option>)}
                                                       </>):(<>
                                                            {listaServiciosByGrupoServicio.map(option => 
                                                            <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                                 <Tooltip placement="topLeft" title={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                                      { option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre }
                                                                 </Tooltip>
                                                            </Option>)}
                                                       </>)}
                                                  </Select>
                                             </Form.Item>
                                        </Col>

                                        <Col span={5}>
                                             <Tooltip title='Rango para validar fecha de cierre de la Solicitud de Servicio'>
                                                  <Form.Item name='rangoFechaCierre'>
                                                  
                                                       <RangePicker 
                                                            format='DD-MM-YYYY'
                                                            onChange={fechasSelected}
                                                            disabledDate={disabledRange}
                                                       />
                                                  
                                                  </Form.Item>
                                             </Tooltip>
                                        </Col>
                                   </Row>
                              </Form>
                              <Row justify='space-around'>
                                   <Col span={24}> <br/>
                                        <div className="ant-descriptions-view" >
                                             <Table
                                                  size="small"
                                                  rowKey="idSolicitudServicio"
                                                  columns={columns}
                                                  dataSource={listaDetalleCalculoDEO}
                                                  loading={fetchingListaDetalleCalculoError}
                                                  onChange={handleTableChange}
                                                  locale={{ emptyText: 'Sin datos' }}
                                                  scroll={scroll}
                                                  pagination={{ total: listaDetalleCalculoDEOCantidad, simple: true, }}
                                             />
                                        </div>
                                   </Col>

                                   <Col span={8}>
                                   <br />
                                        <Button disabled={!listaDetalleCalculoDEO[0]} className='btnCargaArchivos' style={{ width: '100%' }} onClick={onDescarga} loading={fetchingExportarExcelDetalleCDEO}>
                                             <b>Exportar a Excel <FileExcelOutlined /></b>
                                        </Button>
                                   </Col>

                                   <Col span={8}>
                                   <br />
                                        <Button disabled={!listaDetalleCalculoDEO[0]} className='nuevoUsuario' style={{ width: '100%' }} onClick={procesarDeductiva} loading={fetchingProcesarDeductivaError} >
                                             Procesar Deductivas
                                        </Button>
                                   </Col>
                              </Row>   
                         </Modal>
                    ) : false }
               </>
          );             
     }
}

const mapStateToProps = state => {
     return {
          showModalDetalleCalculoError: state.MecanismosDePagosReducer.showModalDetalleCalculoError,
          listaDetalleCalculoDEO: state.MecanismosDePagosReducer.listaDetalleCalculoDEO, 
          fetchingListaDetalleCalculoError: state.MecanismosDePagosReducer.fetchingListaDetalleCalculoError, 
          listaDetalleCalculoDEOCantidad: state.MecanismosDePagosReducer.listaDetalleCalculoDEOCantidad,

          fetchingListaGruposServicios: state.CatalogosGeneralesReducer.fetchingListaGruposServicios,
          listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,
          fechaProyeccion: state.MecanismosDePagosReducer.fechaProyeccion, 
          idMesCalendario: state.MecanismosDePagosReducer.idMesCalendario,
          proyeccion: state.MecanismosDePagosReducer.proyeccion,
          fetchingListaTiposFallasActivas: state.SolicitudDeServiciosReducer.fetchingListaTiposFallasActivas,
          listaTiposFallasActivas: state.SolicitudDeServiciosReducer.listaTiposFallasActivas,
          listaAreasFuncionalesActivas: state.AlmacenesReducer.listaAreasFuncionalesActivas,
          fetchingListaAreasFuncionalesActivas: state.AlmacenesReducer.fetchingListaAreasFuncionalesActivas,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
          listaServiciosByGrupoServicio: state.CatalogosGeneralesReducer.listaServiciosByGrupoServicio,


          fetchingExportarExcelDetalleCDEO: state.MecanismosDePagosReducer.fetchingExportarExcelDetalleCDEO,
          fetchingProcesarDeductivaError: state.MecanismosDePagosReducer.fetchingProcesarDeductivaError,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onCloseModal: () => {
               dispatch({ type: 'SHOW_MODAL_DETALLE_CALCULO_ERROR', showModalDetalleCalculoError: false, });
          },
          onGetGruposServicios: () => {
               dispatch({ type: "LISTA_GRUPOS_SERVICIOS_REQUEST" });
          },
          onGetDetelle: (fechaProyeccion, idMesCalendario, idTipoFalla, idAreaFuncional, idGrupoSer, idServicio, fechaCierre1, fechaCierre2) => {
               dispatch({ type: 'LISTA_DETALLE_CALCULO_ERROR_REQUEST', fechaProyeccion, idMesCalendario, idTipoFalla, idAreaFuncional, idGrupoSer, idServicio, fechaCierre1, fechaCierre2 });
          },
          onGetListaTiposFallasActivas: () => {
               dispatch({ type: "LISTA_TIPOS_FALLAS_ACTIVAS_REQUEST" })
          },
          onGetAreasFuncionales: () => {
               dispatch({ type: "LISTA_AREA_FUNCIONAL_ACTIVA_REQUEST", });
          },
          onGetServiciosActivos: () => {
               dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
          },
          onGetServiciosByGrupoServicio: (idGrupoSer) => {
               dispatch({ type: 'LISTA_SERVICIOS_BY_GRUPO_SERV_REQUEST', idGrupoSer });
          },
          onGetDetalleExcel: (fechaProyeccion, idMesCalendario, idTipoFalla, idAreaFuncional, idGrupoSer, idServicio, fechaCierre1, fechaCierre2) => {
               dispatch({ type: 'EXPORTAR_EXCEL_DETALLE_CALCULO_ERROR_REQUEST', fechaProyeccion, idMesCalendario, idTipoFalla, idAreaFuncional, idGrupoSer, idServicio, fechaCierre1, fechaCierre2});
          },
          onProcesarDeductiva: (fechaProyeccion, idMesCalendario) => {
               dispatch({ type: 'PROCESAR_DEDUCTIVA_ERROR_REQUEST', fechaProyeccion, idMesCalendario });
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetalleCalculoDeductivasEO);