import React from 'react';
import {connect} from 'react-redux';
import {  Button, Row, Col, Table, Switch, Radio, Modal, Tooltip} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { FilePdfFilled, FileExcelFilled } from '@ant-design/icons';
import CrearRegistro from './ModalNuevoRegistro';

const {confirm} = Modal;
class TableSection extends React.Component {
    formRef = React.createRef();
    // componentDidMount(){ 
    //     onGetProveedores();
    // }

    state={ 
        agrupado: 'SinAgrupar',
        page: 1,
        orden: {
            field: 'osp.IdOrdenServicioPlanificado',
            order: null,
        }
    }

    render() {
        const {fetchingListaReporteDinamicoSP, listaReporteDinamicoSP, listaReporteDinamicoSPCantidad, fetchingReporteDinamicoEXCEL, fetchingReporteDinamicoPDF,  formularioReporte,listaServiciosActivosF, handlers, data, onDescargarReportePDF, onDescargarReporteExcel, onGetReporteDinamico, onDescargarReporteAgrupadoPDF, onDescargarReporteAgrupadoExcel, onGetReporteDinamicoAgrupado, listaReporteDinamicoAgrupadoSP, listaReporteDinamicoAgrupadoSPCantidad, fetchingReporteDinamicoAgrupadoSP, onModalNuevoRegistroReporte, fetchingReporteDinamicoAgrupadoEXCEL, fetchingReporteDinamicoAgrupadoPDF
        } = this.props;
        
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const {checkbox, checkArray, state, order} = data;
        console.log('order  orden', order)
        const {handleTableChange, handleSteps, handleOrden} = handlers;
        
        const onHandleTableChange = (pagination, filter, orden, agrupado) => {
            handleTableChange(pagination, filter, orden, agrupado);
        }

        const onHandleSteps = (value) => {
            handleSteps(value);
        }
        
        const descargarReporte = (value) => {
            console.log("estoy en descargar", value)
            let _this = this;
            confirm({
                title: '¿Deseas guardar el reporte como una vista predeterminada?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onModalNuevoRegistroReporte(formularioReporte, checkbox, value, _this.state.agrupado, order);
                },
                onCancel() {
                    if(_this.state.agrupado === 'SinAgrupar'){
                        if (value === 'pdf') { 
                            onDescargarReportePDF(formularioReporte, checkbox, order, formularioReporte.titulo); 
                        }  else { 
                            onDescargarReporteExcel(formularioReporte, checkbox, order, formularioReporte.titulo); 
                        }
                    } else {
                        if (value === 'pdf') { 
                            onDescargarReporteAgrupadoPDF(formularioReporte, checkbox, _this.state.agrupado, order, formularioReporte.titulo); 
                        }  else { 
                            onDescargarReporteAgrupadoExcel(formularioReporte, checkbox, _this.state.agrupado, order, formularioReporte.titulo); 
                        }
                    }
                },
            });
        }

        
        const columns = [
            {title: 'Folio', dataIndex: 'folioOrden', key: 'folioOrdenC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true, sortOrder: order.field === 'folioOrden' && order.ordenReiniciar},
            {title: 'Tipo', dataIndex: 'tipo', key: 'tipoC', align: 'right', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true, sortOrder: order.field === 'tipo' && order.ordenReiniciar}, 
            {title: 'Gerencia', dataIndex: 'gerencia', key: 'gerenciaC', align: 'center'},
            {title: 'Grupo de Servicio',dataIndex: 'grupoServicios', key: 'grupoServiciosC', align: 'center'},
            {title: 'Servicio', dataIndex: 'servicio', key: 'servicioC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true, sortOrder: order.field === 'servicio' && order.ordenReiniciar},
            {title: 'Partida', dataIndex: 'partida', key: 'partidaC', align: 'center'},
            {title: 'Tipo de Programa', dataIndex: 'tipoPrograma', key: 'tipoProgramaC', align: 'center'},
            {title: 'Nombre de Rutina', dataIndex: 'nombreRutina', key: 'nombreRutinaC', align: 'center'},
            {title: 'Clave de Rutina', dataIndex: 'claveRutina', key: 'claveRutinaC', align: 'center'},
            {title: 'Ubicación', dataIndex: 'ubicacion', key: 'ubicacionC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true, sortOrder: order.field === 'ubicacion' && order.ordenReiniciar},
            {title: 'Estatus', dataIndex: 'estatusOrden', key: 'estatusOrdenC', align: 'center'},
            {title: 'Fecha', dataIndex: 'fecha', key: 'fechaC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true, sortOrder: order.field === 'fecha' && order.ordenReiniciar},
            {title: 'Nombre del Activo', dataIndex: 'nombreActivo', key: 'nombreActivoC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true, sortOrder: order.field === 'nombreActivo' && order.ordenReiniciar},
            {title: 'Número de Serie', dataIndex: 'numeroSerie', key: 'numeroSerieC', align: 'center'}, 
            {title: 'Código Interno', dataIndex: 'codigoInterno', key: 'codigoInternoC', align: 'center'},
            {title: 'Código de Equipo', dataIndex: 'codigoEquipo', key: 'codigoEquipoC', align: 'right'},
            {title: 'Código de Activo', dataIndex: 'codigoActivo', key: 'codigoActivoC', align: 'right'},            
            {title: 'Estado', dataIndex: 'estado', key: 'estadoC',
                render: (text, record) => (
                    <Switch checked={text} disabled checkedChildren="Activo" unCheckedChildren="Inactivo"/>
                ),
            },
        ];
        
        const columnsSelected = columns.filter( field => checkArray.includes(field.key));
        const scroll = columnsSelected.length > 4 ? {x: columnsSelected.length * 200} : {x: 700}
        columnsSelected.push({title: 'Tipo', dataIndex: 'tipo', key: 'tipo', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true})
        columnsSelected.push({title: 'Fecha', dataIndex: 'fecha', key: 'fecha', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true})

        const onAgrupar = (value) => {
            console.log('value Agrupar', value)

            this.setState({
                agrupado: value.target.value,
            });
            formularioReporte.tipoAgrupado = value.target.value;
            handleOrden('osp.IdOrdenServicioPlanificado', 'DESC', null)

            if(value.target.value !== 'SinAgrupar'){
                onGetReporteDinamicoAgrupado(formularioReporte, 1, value.target.value, checkbox, order)
            }
            else{
                const orden = {field : 'osp.IdOrdenServicioPlanificado', order: 'DESC'};
                onGetReporteDinamico(formularioReporte, 1, orden, checkbox)
            }
        }

        const columnsAgrupamiento = [
            { title: this.state.agrupado, dataIndex: 'nombre', key: 'nombre' },
        ];

        const onChangeAgrupado = (record, pagination, filter, orden) => {
            console.log(`record, e`, record, pagination, filter, orden);
            const orderFields = {tipoC: 'ru.Tipo', grupoServiciosC:'gser.Nombre', folioOrdenC:'osp.FolioOrden', 
            ubicacionC: 'af.CodigoArea', nombreActivoC: 'ace.Descripcion', fechaC: 'fre.Fecha'}

            record.listaReporteDinamicoAgrupadoSP = listaReporteDinamicoAgrupadoSP;
            record.listaReporteDinamicoAgrupadoSPCantidad = listaReporteDinamicoAgrupadoSPCantidad;
            if(orden) {
                const field = orden.columnKey ? orderFields[orden.columnKey] : 'osp.IdOrdenServicioPlanificado';
                const orderField = orden.order === 'descend' ? 'DESC': 'ASC';
                const sorter = {field: field, order: orderField}
                this.setState({orden: sorter})
                console.log(`sorter`, sorter)
                return onGetReporteDinamico(formularioReporte, pagination.current, sorter, checkbox, record );
            }

            onGetReporteDinamico(formularioReporte, pagination.current, order, checkbox, record);
        };

        const ordenamientoGeneral = (value, tipo) => {
            console.log(`value`, value); console.log('tipo', tipo)
            if(tipo === 'field'){
                handleOrden(value.target.value, order.order)
            }
            else{
                handleOrden(order.field, value.target.value)
            }
        };

        return (
            <>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={3}>
                        <Row justify='space-around' gutter={[8,8]}>
                            <Col span={12}>
                                <Tooltip title='Exportar archivo .pdf' placement="top">
                                    <Button disabled={listaReporteDinamicoSPCantidad <= 0 ? true : !permisosUsuario.exportarPdfReporteDinamicoRecursosHumanos} className="iconSecundary" shape='circle' icon={<FilePdfFilled /> } loading={fetchingReporteDinamicoPDF || fetchingReporteDinamicoAgrupadoPDF} onClick={()=> descargarReporte('pdf')}/>
                                </Tooltip>
                            </Col>
                            <Col span={12}>
                                <Tooltip title='Exportar archivo .xls' placement="top">
                                    <Button disabled={listaReporteDinamicoSPCantidad <= 0 ? true : !permisosUsuario.exportarExcelReporteDinamicoRecursosHumanos} className="iconSecundary" shape='circle' icon={<FileExcelFilled /> } loading={fetchingReporteDinamicoEXCEL || fetchingReporteDinamicoAgrupadoEXCEL} onClick={()=> descargarReporte('excel')}/>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Col>
                    <Col style={{ marginTop: '.4em' }}>
                        <Radio.Group defaultValue='SinAgrupar' onChange={onAgrupar}  size="small">
                            { checkbox.gerenciaC ? (<Radio.Button value="Gerencia">Gerencia</Radio.Button>): false }
                            { checkbox.grupoServiciosC ? (<Radio.Button value="Grupo de Servicios">Grupo Servicios</Radio.Button>): false }
                            { checkbox.servicioC ? (<Radio.Button value="Servicio">Servicio</Radio.Button>): false }
                            { checkbox.partidaC ? (<Radio.Button value="Partida">Partida</Radio.Button>): false }
                            <Radio.Button value="Tipos">Tipo</Radio.Button>
                            { checkbox.estatusOrdenC ? (<Radio.Button value="Estatus">Estatus</Radio.Button>): false }
                            { checkbox.tipoProgramaC ? (<Radio.Button value="Tipo de Programa">Tipo de Programa</Radio.Button>): false }
                            { checkbox.ubicacionC ? ( <Radio.Button value="Area Funcional">Ubicación</Radio.Button>): false }
                            { checkbox.nombreActivoC ? ( <Radio.Button value="Activo">Nombre Activo</Radio.Button>): false }
                            <Radio.Button value='SinAgrupar'>Sin Agrupamiento</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Row style={{ paddingTop: '20px' }}>
                            <Col span={24}>
                                { this.state.agrupado !== 'SinAgrupar' ? (
                                    <Table
                                        title={() => 
                                            <Row justify='space-around' gutter={[8,8]} align='middle'>
                                                <Col span={20}>
                                                    <Radio.Group defaultValue={order.field} onChange={(e) => ordenamientoGeneral(e, 'field')}  size="small">
                                                        <Radio.Button value="osp.IdOrdenServicioPlanificado">ID</Radio.Button>
                                                        <Radio.Button value="ru.Tipo">Tipo</Radio.Button>
                                                        { checkbox.grupoServiciosC ? (<Radio.Button value="ser.Nombre">Servicio</Radio.Button>) : false }
                                                        { checkbox.folioOrdenC ? (<Radio.Button value="osp.FolioOrden">Folio</Radio.Button>) : false }
                                                        { checkbox.ubicacionC ? (<Radio.Button value="af.CodigoArea">Ubicación</Radio.Button>) : false }
                                                        { checkbox.nombreActivoC ? (<Radio.Button value="ace.Descripcion">Nombre Activo</Radio.Button>) : false }
                                                        <Radio.Button value="fre.Fecha">Fecha</Radio.Button>
                                                    </Radio.Group>
                                                </Col>
                                                <Col span={4}>
                                                    <Radio.Group defaultValue={order.order} onChange={(e) => ordenamientoGeneral(e, 'orden')} size="small">
                                                        <Radio.Button value="ASC">Ascendente</Radio.Button>
                                                        <Radio.Button value="DESC">Descendente</Radio.Button>
                                                    </Radio.Group>
                                                </Col>
                                            </Row>
                                        }
                                        size='small'
                                        rowKey='id'
                                        columns={columnsAgrupamiento}
                                        dataSource={listaReporteDinamicoAgrupadoSP}
                                        expandable={{expandedRowRender: record => 
                                            <Table 
                                                rowKey="id" 
                                                columns={columnsSelected} 
                                                loading={fetchingListaReporteDinamicoSP} 
                                                onChange={(e,u,i) => onChangeAgrupado(record, e, u, i)} 
                                                dataSource={record.agrupados.data} 
                                                pagination={{ total: record.agrupados.cantidad, simple: true, current: record.agrupados.page }} 
                                                scroll={scroll}
                                            /> 
                                        } }
                                        loading={fetchingReporteDinamicoAgrupadoSP}
                                        locale={{ emptyText: 'Sin datos' }}
                                        onChange={(a,b,c) => onHandleTableChange(a,b,c, true)}
                                        pagination={{ total: listaReporteDinamicoAgrupadoSPCantidad, simple: true, current: state.page }}
                                        scroll={scroll}
                                    />
                                ):  (
                                    <Table
                                        size='small'
                                        rowKey='id'
                                        columns={columnsSelected}
                                        dataSource={listaReporteDinamicoSP}
                                        loading={fetchingListaReporteDinamicoSP}
                                        locale={{ emptyText: 'Sin datos' }}
                                        onChange={onHandleTableChange}
                                        scroll={scroll}
                                        pagination={{ total: listaReporteDinamicoSPCantidad, simple: true, current: state.page }}
                                    />
                                ) }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row justify='start'>
                    <Col span={6}>
                        <Button className="btnFiltrar" onClick={() => onHandleSteps('atras')} style={{ width: '100%', marginTop: '20px' }}>Anterior</Button>
                    </Col>
                </Row>
                <CrearRegistro/>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaReporteDinamicoSP: state.ServiciosPlanificadosReducer.fetchingListaReporteDinamicoSP,
        listaReporteDinamicoSP: state.ServiciosPlanificadosReducer.listaReporteDinamicoSP,
        listaReporteDinamicoSPCantidad: state.ServiciosPlanificadosReducer.listaReporteDinamicoSPCantidad,

        fetchingReporteDinamicoEXCEL: state.ServiciosPlanificadosReducer.fetchingReporteDinamicoEXCEL,
        fetchingReporteDinamicoPDF: state.ServiciosPlanificadosReducer.fetchingReporteDinamicoPDF,
        fetchingReporteDinamicoAgrupadoEXCEL: state.ServiciosPlanificadosReducer.fetchingReporteDinamicoAgrupadoEXCEL,
        fetchingReporteDinamicoAgrupadoPDF: state.ServiciosPlanificadosReducer.fetchingReporteDinamicoAgrupadoPDF,
        formularioReporte: state.ServiciosPlanificadosReducer.formularioReporte,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,

        listaReporteDinamicoAgrupadoSP: state.ServiciosPlanificadosReducer.listaReporteDinamicoAgrupadoSP,
        listaReporteDinamicoAgrupadoSPCantidad: state.ServiciosPlanificadosReducer.listaReporteDinamicoAgrupadoSPCantidad,
        fetchingReporteDinamicoAgrupadoSP: state.ServiciosPlanificadosReducer.fetchingReporteDinamicoAgrupadoSP,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onDescargarReportePDF: (formulario, checkboxData, order, tituloDocumento) => {
            dispatch({ type: 'PDF_REPORTE_DINAMICO_SP_REQUEST', formulario, checkboxData, order, tituloDocumento});
        },
        onDescargarReporteExcel: (formulario, checkboxData, order, tituloDocumento) => {
            dispatch({ type: 'EXCEL_REPORTE_DINAMICO_SP_REQUEST', formulario,checkboxData, order, tituloDocumento});
        },
        onGetReporteDinamico: (formulario, page, order, checkboxData, agrupado) => {
            dispatch({ type: 'LISTA_REPORTE_DINAMICO_SP_REQUEST', formulario, page, order, checkboxData, agrupado});
        },
        onDescargarReporteAgrupadoPDF: (formulario, checkboxData, agrupado, order, tituloDocumento) => {
            dispatch({ type: 'PDF_REPORTE_DINAMICO_AGRUPADO_SP_REQUEST', formulario, checkboxData, agrupado, order, tituloDocumento });
        },
        onDescargarReporteAgrupadoExcel: (formulario, checkboxData, agrupado, order, tituloDocumento) => {
            dispatch({ type: 'EXCEL_REPORTE_DINAMICO_AGRUPADO_SP_REQUEST', formulario, checkboxData, agrupado, order, tituloDocumento });
        },
        onGetReporteDinamicoAgrupado: (formulario, page, agrupado, checkboxData, order) => {
            dispatch({ type: 'LISTA_REPORTE_DINAMICO_AGRUPADO_SP_REQUEST', formulario, page, agrupado, checkboxData, order});
        },
        onModalNuevoRegistroReporte: (formulario, checkboxData, tipoDocumento, agrupado, order) => {
            dispatch({ type: "MODAL_CREAR_REGISTRO_SP", showModalCrearRegistro: true, data: {formulario, checkboxData, tipoDocumento, agrupado, order} });
        },
    }
}

export default connect (mapStateToProps, mapDispatchToProps) (TableSection);