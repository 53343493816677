import axios from "axios";

//const endpoint = "https://papantla-services.herokuapp.com/";
//const endpoint = "https://papantla-dev.herokuapp.com/";
const endpoint = 'https://morelos-dev.herokuapp.com/';
//const endpoint = "https://papantla-demo.herokuapp.com/";

//const endpoint = "http://172.24.0.170:8080/"

//const endpoint = 'https://papantla-services-jose.herokuapp.com/';
// const endpoint = 'https://papantla-dev-jose.herokuapp.com/';

//const endpoint = 'https://papantla-secret-jose.herokuapp.com/';
//const endpoint = 'https://papantla-secret.herokuapp.com/';

// Services para CU - Catálogo de Clasificación de Gerencias
export function listaClasificacionesCall(token, param, page) {
	return axios({
		method: "get",
		url: endpoint + 'clasificacion/gerencias?' + param + 'page=' + page + '&offset=10', 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaClasificacionesActivosCall(token) {
	return axios({
		method: "get",
		url: endpoint + 'clasificacion/gerencias/activos?', 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoClasificacionesCall(token, idClasificacionGerencia) {
	return axios({
		method: "get",
		url: endpoint + 'clasificacion/gerencias/activa/' + idClasificacionGerencia , 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearClasificacionCall(token, data) {
	return axios({
		method: "post",
	    url: endpoint + "clasificacion/gerencias",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarClasificacionCall(token, data, idClasificacionGerencia) {
	return axios({
		method: "put",
		url: endpoint + "clasificacion/gerencias/" + idClasificacionGerencia,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaClasificacionesGerenciaActivasCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "clasificacion/gerencias/activas",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Services para CU -  Catálogo de Temas
export function listaTemasBusquedaCall(token, tema, centro, page) {
	return axios({
		method: "get",
		url: endpoint + 'temas?' + tema + centro + 'page=' + page + '&offset=10', 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaTemasActivosCall(token, param) {
	return axios({
		method: "get",
		url: endpoint + 'temas/activos?' + param,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoTemaCall(token, idTema) {
	return axios({
		method: "get",
		url: endpoint + 'temas/activa/' + idTema , 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearTemaCall(token, data) {
	return axios({
		method: "post",
	    url: endpoint + "temas",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarTemaCall(token, data, idTema) {
	return axios({
		method: "put",
		url: endpoint + "temas/" + idTema,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Services para CU -  Catálogo de Objetivos para Evaluación de Calidad
export function listaObjetivosBusquedaCall(token, gerencia, centro, page) {
	return axios({
		method: "get",
		url: endpoint + 'temas/objetivos?' + gerencia + centro + 'page=' + page + '&offset=10', 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaObjetivosActivosCall(token, param) {
	return axios({
		method: "get",
		url: endpoint + 'temas/objetivos/activos?' + param,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoObjetivosCall(token, idObjetivo) {
	return axios({
		method: "get",
		url: endpoint + 'temas/objetivos/activa/' + idObjetivo , 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearObjetivoCall(token, data) {
	return axios({
		method: "post",
	    url: endpoint + "temas/objetivos",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarObjetivoCall(token, data, idObjetivo) {
	return axios({
		method: "put",
		url: endpoint + "temas/objetivos/" + idObjetivo,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaServiciosByIdCentroCostoCall(token, idCentroCosto) {
	return axios({
		method: "get",
		url: endpoint + 'servicios/objetivos?idCentroCosto=' + idCentroCosto,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaEstandaresDesempenoFiltroActivosByServicioCall(token, param, idServicio ) {
	return axios({
	    method: "get",
	    url: endpoint + "estandaresDesempeno/activos/servicio/by?param=" + param + "&idServicio=" + idServicio,
		headers: {'Content-Type': 'application/json', 'Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Services para CU - Catálogo de  Indicadores de Desempeño
export function objetivosByGerenciaCall(token, idClasificacionGerencia) {
	return axios({
	    method: "get",
		url: endpoint + "temas/objetivos/activos?idClasificacionGerencia=" + idClasificacionGerencia,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaIndicadoresDesempenoCall(token, objetivo, fechaVigencia, indicadorDesempeno, page) {
	return axios({
		method: "get",
		url: endpoint + 'indicadores/desempeno?' + objetivo + fechaVigencia + indicadorDesempeno + 'page=' + page + '&offset=10', 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function altaIndicadorDesempenoCall(token, data) {
	return axios({
		method: "post",
	    	url: endpoint + "indicadores/desempeno",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarIndicadorDesempenoCall(token, data, idIndicadorDesempeno) {
	return axios({
		method: "put",
		url: endpoint + "indicadores/desempeno/" + idIndicadorDesempeno,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Clasificación de Solicitudes de Servicios y Servicios Programados por Gerencia
export function getEstadisticasSPSSCall(token, idClasificacionGerencia, mes) {
	return axios({
		method: 'get',
		url: endpoint + `clasf/solicitudes/gerencia?idClasificacionGerencia=${idClasificacionGerencia}&mes=${mes}`,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => response)
	.catch(error => error.response.data);
}

//Reporte Dinámico para Evaluaciones de Calidad
export function listaReporteDinamicoCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + 'gestion/calidad/evaluacion/reporte/dinamico', 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaReporteDinamicoAgrupadoCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + 'gestion/calidad/evaluacion/reporte/dinamico/agrupado', 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarExcelReporteDinamico(token, data) {
	return axios({
		method: "post",
		responseType: 'blob',
		url: endpoint + 'gestion/calidad/evaluacion/reporte/dinamico/excel', 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarPDFReporteDinamico(token, data) {
	return axios({
		method: "post",
		responseType: 'blob',
		url: endpoint + 'gestion/calidad/evaluacion/reporte/dinamico/pdf', 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaRegistrosReporteCall(token, page) {
	return axios({
		method: "get",
		url: endpoint + 'gestion/calidad/evaluacion/registro?page=' + page + '&offset=10', 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaRegistrosEspecialReporteCall(token, page) {
	return axios({
		method: "get",
		url: endpoint + 'gestion/calidad/evaluacion/registro/todos?page=' + page + '&offset=10', 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoRegistroReporteCall(token, idReporteEvaluacion) {
	return axios({
		method: "get",
		url: endpoint + 'gestion/calidad/evaluacion/registro/activa/' + idReporteEvaluacion , 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearRegistroReporteCall(token, data) {
	return axios({
		method: "post",
	    url: endpoint + "gestion/calidad/evaluacion/registro",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarRegistroReporteCall(token, data, idReporteEvaluacion) {
	return axios({
		method: "put",
		url: endpoint + "gestion/calidad/evaluacion/registro/" + idReporteEvaluacion,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Evaluación de Indicadores de Desempeño
export function listaGerenciasByServicioCall(token) {
	return axios({
		method: "get",
		url: endpoint + 'gestion/calidad/evaluacion/gerencias',
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaObjetivosByGerenciaCall(token, idClasificacionGerencia) {
	return axios({
		method: "get",
		url: endpoint + 'gestion/calidad/evaluacion/objetivos/' + idClasificacionGerencia,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaServiciosEvaluacionesDeDesempenoCall(token, idProveedor, idClasificacionGerencia) {
	return axios({
		method: "get",
		url: endpoint + 'gestion/calidad/evaluacion/servicios/?idProveedor=' + idProveedor + '&idClasificacionGerencia=' + idClasificacionGerencia,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaIndicadoresByGerenciaObjetiboCall(token, idClasificacionGerencia, idObjetivo) {
	return axios({
		method: "get",
		url: endpoint + 'gestion/calidad/evaluacion/indicador?idClasificacionGerencia=' + idClasificacionGerencia + '&idObjetivo=' + idObjetivo,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaPartidasByServicioCall(token, idServicio) {
	return axios({
		method: "get",
		url: endpoint + 'gestion/calidad/evaluacion/partidas/' + idServicio,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearEvaluacionDeDesempenoCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "gestion/calidad/evaluacion/crear",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaMesesEvaluacionCall(token) {
	return axios({
		method: "get",
		url: endpoint + 'gestion/calidad/evaluacion/meses',
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaEvaluacionesDeDesempenoCall(token, idClasificacionGerencia, idMes, idObjetivo, page, idProveedor, order, field, idServicio, idPartida) {
	return axios({
		method: "get",
		url: endpoint + 'gestion/calidad/evaluacion?idClasificacionGerencia=' + idClasificacionGerencia +
			'&idMes=' + idMes + '&idObjetivo=' + idObjetivo + '&page=' + page + '&offset=10&idProveedor=' + idProveedor +
			'&order=' + order + '&field=' + field + '&idServicio=' + idServicio + '&idPartida=' + idPartida,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaEvaluacionesDeDesempeno2Call(token, idClasificacionGerencia, idMes, idObjetivo, page, idProveedor, order, field, idServicio, idPartida) {
	return axios({
		method: "get",
		url: endpoint + 'gestion/calidad/evaluacion?idClasificacionGerencia=' + idClasificacionGerencia +
			'&idMes=' + idMes + '&idObjetivo=' + idObjetivo + '&page=' + page + '&offset=10&idProveedor=' + idProveedor +
			'&order=' + order + '&field=' + field + '&idServicio=' + idServicio + '&idPartida=' + idPartida,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaServiciosByCentroCall(token, idServicio) {
	return axios({
		method: "get",
		url: endpoint + 'gestion/calidad/evaluacion/servicios/' + idServicio,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
