import React, {Component} from 'react';
import { Form, Spin, Row, Col, Input, Button, DatePicker, message} from "antd";
import {connect} from 'react-redux'
import moment from 'moment';
import ModalContraseña from '../ModalContraseña';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { TextArea } = Input;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class EsperaForm extends Component {
    formRef = React.createRef();
    state = {
        btnRegistrar: false,
        formulario: null,
    }
    render() { 
        const {fetchingCambioEstadoSeguimiento, onActualizarSeguimiento, folioSolicitudServicioSeleccionado, 
               listaTrazabilidad, page, busqueda ,onShowModalContraseña, isEspecial 
        } = this.props;

                console.log( 'listaTrazabilidad',listaTrazabilidad[0]) 
        

        console.log('folioSolicitudServicioSeleccionado', folioSolicitudServicioSeleccionado)

        const currentYear = moment().format('YYYY');    

        let permisosUsuario = sessionStorage.getItem('permisosUsuario')
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }      
        if(isEspecial){
             onActualizarSeguimiento(this.state.formulario, folioSolicitudServicioSeleccionado, page, busqueda);
             onShowModalContraseña(false, false);
        };
       
        const onFinish = (formulario) => {
            console.log(formulario)
            if(permisosUsuario.especialSolicitudDeServicio){
                this.setState({ formulario })
                onShowModalContraseña(true, false);
            } else {
                onActualizarSeguimiento(formulario, folioSolicitudServicioSeleccionado, page, busqueda);
            }
            
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo);
        };

        const onChangeDate = (value) => {
            console.log(value);
            if (value || listaTrazabilidad) {
                let fechaEstado;
                if (listaTrazabilidad.length > 0) {
                    if (listaTrazabilidad[0].fechaEstado) {
                        console.log(listaTrazabilidad[0].fechaEstado);
                        fechaEstado = listaTrazabilidad[0].fechaEstado
                    } else {
                        fechaEstado = null;
                    }
                } else {
                    fechaEstado = null
                }

                let resultadoValidacionFechaProceso;
                if (folioSolicitudServicioSeleccionado) {
                    if (folioSolicitudServicioSeleccionado.fechaProcesoDate) {
                        resultadoValidacionFechaProceso = moment(value, 'DD-MM-YYYY HH:mm').isAfter(moment(folioSolicitudServicioSeleccionado.fechaProcesoDate, 'DD-MM-YYYY HH:mm'));
                    }
                    
                }

                let resultadoValidacionFechaAlta    = moment(value, 'DD-MM-YYYY HH:mm').isAfter(moment(folioSolicitudServicioSeleccionado.fechaAlta, 'DD-MM-YYYY HH:mm'));
                let resultadoValidacionFechaEstado  = moment(value, 'DD-MM-YYYY HH:mm').isAfter(moment(fechaEstado, 'DD-MM-YYYY HH:mm'));
                let resultadoValidacionAñoCurrent   = moment(value, 'YYYY').isAfter(moment(currentYear, 'YYYY'));
                console.log(resultadoValidacionFechaAlta, resultadoValidacionFechaEstado, resultadoValidacionAñoCurrent)
                if (!resultadoValidacionFechaAlta) {
                    message.warning('La fecha seleccionada tiene que ser mayor a fecha Alta de la solicitud de servicio', 5);
                    this.setState({ btnRegistrar: true })

                } else if (!resultadoValidacionFechaEstado && fechaEstado !== null) {
                    message.warning('La fecha seleccionada tiene que ser mayor a la ultima fecha de estado de la solicitud de servicio', 5);
                    this.setState({ btnRegistrar: true })

                }
                
                 else if (!resultadoValidacionAñoCurrent) {
                    message.warning('La fecha seleccionada tiene que ser durante el año en curso', 5);
                    this.setState({ btnRegistrar: true })

                } else if (resultadoValidacionFechaProceso) {

                    if (!resultadoValidacionFechaProceso) {
                        message.warning('La fecha seleccionada tiene que ser mayor a la "fecha de Proceso"  de la solicitud de servicio', 5);
                        this.setState({ btnRegistrar: true })
                    }

                }
                
                else {
                    this.setState({ btnRegistrar: false })
                }
            }
        };

        
        return ( 
            <div>
                <Spin spinning={fetchingCambioEstadoSeguimiento}>

                {folioSolicitudServicioSeleccionado ? (

                  <Form {...layout}
                       name='formulario'
                       onFinish={onFinish}
                       initialValues={folioSolicitudServicioSeleccionado}
                       
                       ref={this.formRef} 
                       >
                           
                           <Row type='flex' justify='space-around'>
                                <Col span={23}>
                                   <Form.Item
                                      label="Observaciones"
                                      name="observacionesF"
                                      rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <TextArea
                                        rows={1}
                                        pattern="^^[ñíóáéú a-zA-Z 0-9]+$"
                                        title="Nombre debe tener letras y numeros."
                                        maxLength={100} placeholder='Ingrese observaciones' />
                                  </Form.Item>
                                </Col>
                                

                                <Col span={23}>
                                  <Form.Item
                                    label="Fecha y Hora En Espera"
                                    name="fechaEspera"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                   >
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        format='DD-MM-YYYY HH:mm'
                                        placeholder={["Seleccione fecha y hora En Espera"]}
                                        onChange={onChangeDate}
                                        showTime={{ defaultValue: moment('00:00', 'HH:mm') }} 
                                    />
                                  </Form.Item>
                               </Col>
                            
                                
                                <Col span={23}>
                                <Button className="btnFiltrar" disabled={this.state.btnRegistrar} style={{ width: '40%' }} htmlType="submit">Registrar</Button>
                                  </Col>
                            </Row>
                           

                     </Form>
                      ): false }
                </Spin>
                <ModalContraseña/>
            </div>
         );
    }
}

const mapStateToProps = (state) => {
    return {
        fetchingCambioEstadoSeguimiento: state.SolicitudDeServiciosReducer.fetchingCambioEstadoSeguimiento,
        folioSolicitudServicioSeleccionado: state.SolicitudDeServiciosReducer.folioSolicitudServicioSeleccionado,
        listaTrazabilidad: state.SolicitudDeServiciosReducer.listaTrazabilidad,
        page: state.SolicitudDeServiciosReducer.page,
        busqueda: state.SolicitudDeServiciosReducer.busqueda,
        isEspecial: state.SolicitudDeServiciosReducer.isEspecial,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onActualizarSeguimiento: (formulario, folioSolicitudServicioSeleccionado, page, busqueda) => {
                dispatch({ type: "EN_ESPERA_FOLIO_SS_REQUEST", formulario, folioSolicitudServicioSeleccionado, page, busqueda});
        },
        onShowModalContraseña: (showModalContraseña, isEspecial) => {
            dispatch({ type: "SHOW_MODAL_CONTRASEÑA", showModalContraseña , isEspecial})
        } 
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(EsperaForm);