import * as informacionDelProyectoActions from '../actions/InformacionDelProyectoActions.js';

const initialState = {
    fetchingListaInfoProyecto: false,
    listaInfoProyecto : [],

    fetchingEditarListaInfoProyecto: false,

    fetchingNuevoListaInfoProyecto: false,
    siguientePaso: false,

}


export default (state = initialState, action) => {
    switch (action.type) {
        case informacionDelProyectoActions.GET_INFOPROYECTO_REQUEST:
            return { ...state, fetchingListaInfoProyecto: true };
        case informacionDelProyectoActions.GET_INFOPROYECTO_SUCCESS:
            return { ...state, fetchingListaInfoProyecto: false, listaInfoProyecto: action.listaInfoProyecto };
        case informacionDelProyectoActions.GET_INFOPROYECTO_FAILURE:
            return { ...state, fetchingListaInfoProyecto: false };

        case informacionDelProyectoActions.EDITAR_INFOPROYECTO_REQUEST:
            return { ...state, fetchingEditarListaInfoProyecto: true };
        case informacionDelProyectoActions.EDITAR_INFOPROYECTO_SUCCESS:
            return { ...state, fetchingEditarListaInfoProyecto: false };
        case informacionDelProyectoActions.EDITAR_INFOPROYECTO_FAILURE:
            return { ...state, fetchingEditarListaInfoProyecto: false };

       case informacionDelProyectoActions.NUEVO_INFOPROYECTO_REQUEST:
            return { ...state, fetchingNuevoListaInfoProyecto: true };
        case informacionDelProyectoActions.NUEVO_INFOPROYECTO_SUCCESS:
            return { ...state, fetchingNuevoListaInfoProyecto: false , siguientePaso: true};
        case informacionDelProyectoActions.NUEVO_INFOPROYECTO_FAILURE:
            return { ...state, fetchingNuevoListaInfoProyecto: false , siguientePaso: false };  
            
            
        default:
            return state;
    }
}

