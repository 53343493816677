import React, { Component } from 'react';
import {Row, Col, Modal, Form, Spin, Input, message, Button, DatePicker, Upload, InputNumber, Radio, Card, Table} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import {connect} from 'react-redux';
import moment from 'moment'

const { TextArea } = Input;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalProrroga extends Component {
    formRef = React.createRef();
    state = {
        filePDF: false,
        botonPDF: false,
        btnCheckProrroga: null,
        btnRegistrar: false,
    }
    render() { 
        const { showModalProrroga, onCerrarModal, folioSeleccionadoProrroga,
               fetchingStatusProrroga, listaProrrogas , listaProrrogasCantidad,
               onCrearProrrogaRequest, limpiarFormulario } = this.props;
           
            
        const handleCancel = () => {
            onCerrarModal(false, null, true);
        };

        if (limpiarFormulario && limpiarFormulario === 'crearProrroga') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        
        const onFinish = formulario => {
            console.log('formulario', formulario);  
            onCrearProrrogaRequest(formulario, folioSeleccionadoProrroga );
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo);
        };

        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                })
            }
            var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
            if (info.file.status === 'done') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
             }
             }
             const onRemovePDF = () => {
                 this.setState({
                     filePDF: false,
                     botonPDF: false,
                 });
             }
             const dummyRequest = ({ file, onSuccess }) => {
                 setTimeout(() => {
                     onSuccess("ok");
                 }, 0);
             };


        const checkProrroga = (e) => {
            console.log('value :>> ', e.target.value);
            if(e.target.value === 'tiempoRespuesta'){
                this.setState({ btnCheckProrroga: true })
            } else {
                this.setState({ btnCheckProrroga: false });
            }
        } 
        
        const columns = [{
            title: 'Observaciones',
            dataIndex: 'observaciones',
            key: 'observaciones',
            align: 'center'
        },{
            title: 'Fecha de Compromiso',
            dataIndex: 'fechaCompromiso',
            key: 'fechaCompromiso',
            align: 'center'
        },{
            title: 'Tiempo de respuesta',
            dataIndex: 'tiempoRespuesta',
            key: 'tiempoRespuesta',
            align: 'center'
        },{
            title: 'Fecha de acuerdo',
            dataIndex: 'fechaAcuerdo',
            key: 'fechaAcuerdo',
            align: 'center'
        },{
            title: 'Usuario PYRS',
            dataIndex: 'usuarioPyrs',
            key: 'usuarioPyrs',
            align: 'center'
        }];


        const onChangeFecha = (value) => {
            let resultadoValidacionFechaAlta = moment(value, 'DD-MM-YYYY HH:mm').isAfter(moment(folioSeleccionadoProrroga.fechaAlta, 'DD-MM-YYYY HH:mm'));
            if (!resultadoValidacionFechaAlta) {
                message.warning('La fecha seleccionada tiene que ser mayor a la "Fecha de Alta" de la solicitud de servicio', 5);
                this.setState({ btnRegistrar: true })
            } else {
                this.setState({ btnRegistrar: false })
            }
        }
        return (
            <Modal
                visible={showModalProrroga}
                title={'Folio a Prorroga'}
                onCancel={handleCancel}
                footer={false}
                width='70%'>

                <Spin spinning={fetchingStatusProrroga}>

                    <Form {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        
                        ref={this.formRef}>

                        <Row justify='space-around'>

                            <Col span={23}>
                                <Radio.Group defaultValue="fechaCompromiso" onChange={checkProrroga} style={{ marginTop: 16 }}>
                                    <Radio value="fechaCompromiso"> Fecha Compromiso</Radio>
                                    <Radio value="tiempoRespuesta"> Tiempo Respuesta</Radio>
                                </Radio.Group>
                            </Col>
                            { !this.state.btnCheckProrroga ? (
                                
                                   <Col span={10}>
                                       <br></br>
                                       <Form.Item
                                           label="Fecha Compromiso"
                                           name="fechaCompromiso"
                                           rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                       >
                                           <DatePicker
                                               style={{ width: '100%' }}
                                               format='DD-MM-YYYY HH:mm'
                                               placeholder={["Seleccione fecha Compromiso"]}
                                               onChange={onChangeFecha}
                                               showTime={{ defaultValue: moment('00:00', 'HH:mm') }} 
                                           />
                                       </Form.Item>
                                   </Col>

                            ) :  false}
                        {this.state.btnCheckProrroga  ? (
                          <Col span={10}>
                               <br></br>
                                 <Form.Item
                                     label="Tiempo de Respuesta"
                                     name="tiempoRespuesta"
                                     rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                 >
                                     <InputNumber
                                         min={1}
                                         placeholder="Ingrese tiempo de respuesta" />
                                 </Form.Item>
                           </Col>
                                
                            ) : false}

                                  <Col span={10}>
                                      <br></br>
                                       <Form.Item
                                           label="Fecha de Acuerdo"
                                           name="fechaAcuerdo"
                                           rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                       >
                                           <DatePicker
                                               style={{ width: '100%' }}
                                               format='DD-MM-YYYY'
                                               placeholder={["Seleccione fecha Acuerdo"]}
                                               onChange={onChangeFecha} 
                                           />
                                       </Form.Item>
                                   </Col>

                            <Col span={10}>
                                <Form.Item
                                    label="Observaciones"
                                    name="observaciones"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <TextArea
                                        rows={1}
                                        pattern="^^[ñíóáéú a-zA-Z 0-9]+$"
                                        title="Observaciones en Reasignación debe tener letras y numeros."
                                        maxLength={100} placeholder='Ingrese observaciones' />
                                </Form.Item>
                            </Col>

                            <Col span={10}>
                                <Form.Item
                                    label="Usuario PYRS"
                                    name="usuarioPYRS"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <TextArea
                                        rows={1}
                                        pattern="^^[ñíóáéú a-zA-Z 0-9]+$"
                                        title="Usuario PYRS debe tener letras y numeros."
                                        maxLength={100} placeholder='Ingrese Usuario PYRS' />
                                </Form.Item>
                            </Col>

                            <Col span={10}>
                                <Form.Item
                                    label="Archivo PDF"
                                    name="pdf"
    
                                >
                                    <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                        {this.state.filePDF === false ? (
                                            <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                                <UploadOutlined /> Click para subir archivo PDF
                                            </Button>
                                        ) : false}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={10} className='marginBoton'>
                                    <br />
                                    <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit" disabled={this.state.btnRegistrar}>Registrar Prorroga</Button>
                            </Col>

                            
                            <Col span={24} style={{margin: '2em'}} >
                             <Card className="cardCatalogos" title={<div className='headerLista'>Historial de Prorroga Folio</div>}>
                              <br />
                                <Table
                                    size="small"
                                    rowKey="idHistorialProrroga"
                                    columns={columns}
                                    dataSource={listaProrrogas}
                                    loading={fetchingStatusProrroga}
                                    locale={{ emptyText: 'Sin datos' }}
                                    //onChange={handleTableChange}
                                    pagination={{ total: listaProrrogasCantidad, simple: true }}
                                />
                            </Card>
                        </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}
 
const mapStateToProps = (state) => {
    return {
        folioSeleccionadoProrroga: state.SolicitudDeServiciosReducer.folioSeleccionadoProrroga,
        showModalProrroga: state.SolicitudDeServiciosReducer.showModalProrroga,
        fetchingStatusProrroga: state.SolicitudDeServiciosReducer.fetchingStatusProrroga,
        listaProrrogas: state.SolicitudDeServiciosReducer.listaProrrogas,
        listaProrrogasCantidad: state.SolicitudDeServiciosReducer.listaProrrogasCantidad,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCerrarModal: (showModalProrroga , folioSeleccionadoProrroga, limpiarClasificacion) => {
            dispatch({ type: "SHOW_MODAL_PRORROGA" , showModalProrroga , folioSeleccionadoProrroga, limpiarClasificacion })
        },
        onCrearProrrogaRequest: (formulario, folioSeleccionadoProrroga) => {
            dispatch({  type: "CREAR_PRORROGA_REQUEST", formulario, folioSeleccionadoProrroga })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalProrroga);