import React from 'react';
import { Card, Table, Row, Col, Button, Switch, Modal, Spin, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoPonderadorEncuesta from './ModalNuevoPonderadorEncuesta';
import ModalEditarPonderadorEncuesta from './ModalEditarPonderadorEncuesta';

const confirm = Modal.confirm;
class PonderadoresEncuestaContainer extends React.Component {
     componentDidMount(){
          this.props.onListaPonderadoresEncuesta(1);
          this.props.onRequestInfoProyecto();
          this.props.onAñosPonderadoresEncuesta();
     }

     state = {
          page: 1,
     }
 
     render() {
          const { onListaPonderadoresEncuesta, fetchingListaPonderadoresEncuesta, onRepetirPonderadores, fetchingAñosPonderadoresEncuesta,
               listaPonderadoresEncuesta, listaPonderadoresEncuestaCantidad, onEstadoPonderadorEncuesta, fetchingRepetirPonderadoresEncuesta,
               onShowModalNuevoPonderadorEncuesta, onShowModalEditarPonderadorEncuesta, listaAñosPonderadoresEncuesta
          } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
             permisosUsuario = JSON.parse(permisosUsuario);
          }

          const columns = [{
                    title: 'Etiqueta de calificación',
                    dataIndex: 'etiqueta',
                    key: 'etiqueta',
                    width: '20%',
               }, {
                    title: 'Año de vigencia',
                    dataIndex: 'anio',
                    key: 'anio',
                    width: '10%',
               }, {
                    title: 'Factor',
                    dataIndex: 'factor',
                    key: 'factor',
                    width: '10%',
               }, {
                    title: 'Fecha alta',
                    dataIndex: 'fechaAlta',
                    key: 'fechaAlta',
                    width: '10%',
               }, {
                    title: 'Activo', 
                    dataIndex: 'activo', 
                    key: 'activo',
                    width: '10%',
                    align: 'center',
                    render: (text, record) => (
                         <Switch checked={text} disabled={!permisosUsuario.ponderadorEncuestasActivoinactivo} onChange={() => handleDesactivar(record)}/>
                    ),
               },{
                    title: 'Editar',
                    key: 'editar',
                    width: '10%',
                    align: 'center',
                    render: (text, record) => (
                         <a className='editarIcono' disabled={!permisosUsuario.editarPonderadorEncuestasDeSatisfaccion} onClick={() => onEditar(record)}><EditOutlined /></a>
                    ),
               }
          ];

          const handleDesactivar = (key) => {
               console.log(key);
               const page = this.state.page;
               confirm({
                    title: '¿Estás seguro que deseas cambiar el estado del ponderador para encuestas de satisfacción?',
                    content: '',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                         onEstadoPonderadorEncuesta(key.idPonderacionEncuesta, page)
                    },
                    onCancel() {
                         console.log('Cancel');
                    },
               });
          }

          const onEditar = (key) => {
               console.log(key);
               onShowModalEditarPonderadorEncuesta(key, this.state.page);
          }

          const onNuevoPonderadorEncuesta = () => {
               onShowModalNuevoPonderadorEncuesta(this.state.page);
          }

          const repetirPonderadores = () => {
               console.log('repetir ponderadores')
               if(listaAñosPonderadoresEncuesta[0]){
                    onRepetirPonderadores(listaAñosPonderadoresEncuesta, this.state.page);
               }
               else{
                    message.error('Favor de agregar al menos un ponderador para duplicar en nuevo año.')
               }
          }

          const handleTableChange = (pagination)=>{
               this.setState({
                   page: pagination.current,
               })
               onListaPonderadoresEncuesta(pagination.current);
          };

          return (
               <Card className="cardCatalogos" title={<div className='headerLista'>Ponderadores para Encuestas de Satisfacción</div>}>
                    <Spin spinning={fetchingRepetirPonderadoresEncuesta}>
                         <Row justify='space-between'>
                              <Col span={10}>
                                   {listaAñosPonderadoresEncuesta[0] ? (
                                   <Button onClick={repetirPonderadores} loading={fetchingAñosPonderadoresEncuesta} disabled={!permisosUsuario.duplicarPonderadores ? true: !listaAñosPonderadoresEncuesta[0]} className='btnCargaArchivos'>Duplicar Ponderadores del Año {listaAñosPonderadoresEncuesta[0]} al Año {parseInt(listaAñosPonderadoresEncuesta[0]) + 1}</Button>
                                   ):false}
                              </Col>
                              <Col span={10}>
                                   <Button onClick={onNuevoPonderadorEncuesta} disabled={!permisosUsuario.crearPonderadorEncuestaDeSatisfacción} className='nuevoUsuario'>Nuevo Ponderador para Encuesta de Satisfacción</Button>
                              </Col>
                              <Col span={24}>
                                   <br/>
                                   <Table 
                                        size="small" 
                                        rowKey="idPonderacionEncuesta"
                                        columns={columns}
                                        dataSource={listaPonderadoresEncuesta}
                                        loading={fetchingListaPonderadoresEncuesta}
                                        locale={{ emptyText: 'Sin datos' }}
                                        onChange={handleTableChange}
                                        pagination={{ total: listaPonderadoresEncuestaCantidad, simple: true, current: this.state.page}}
                                   />         
                              </Col>
                         </Row>
                         <ModalNuevoPonderadorEncuesta/>
                         <ModalEditarPonderadorEncuesta/>
                    </Spin>
               </Card>
          );
     }
}
 
 const mapStateToProps = state => {
     return {
          fetchingListaPonderadoresEncuesta: state.GestionDeCalidadReducer.fetchingListaPonderadoresEncuesta,
          listaPonderadoresEncuesta: state.GestionDeCalidadReducer.listaPonderadoresEncuesta,
          listaPonderadoresEncuestaCantidad: state.GestionDeCalidadReducer.listaPonderadoresEncuestaCantidad,
          fetchingRepetirPonderadoresEncuesta: state.GestionDeCalidadReducer.fetchingRepetirPonderadoresEncuesta,
          listaAñosPonderadoresEncuesta: state.GestionDeCalidadReducer.listaAñosPonderadoresEncuesta,
          fetchingAñosPonderadoresEncuesta: state.GestionDeCalidadReducer.fetchingAñosPonderadoresEncuesta,
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          onListaPonderadoresEncuesta: (page) => {
               dispatch({ type: 'LISTA_PONDERADORES_ENCUESTA_REQUEST', page });
          },
          onAñosPonderadoresEncuesta: () => {
               dispatch({ type: 'AÑOS_PONDERADORES_ENCUESTA_REQUEST' });
          },
          onEstadoPonderadorEncuesta: (idPonderacionEncuesta, page) => {
               dispatch({ type: 'ESTADO_PONDERADOR_ENCUESTA_REQUEST', idPonderacionEncuesta, page });
          },
          onShowModalNuevoPonderadorEncuesta: (page) => {
               dispatch ({ type: 'MODAL_NUEVO_PONDERADOR_ENCUESTA', showModalNuevoPonderadorEncuesta: true, page });
          },
          onShowModalEditarPonderadorEncuesta: (ponderadorEncuestaSeleccionado, page) => {
               dispatch ({ type: 'MODAL_EDITAR_PONDERADOR_ENCUESTA', showModalEditarPonderadorEncuesta: true, ponderadorEncuestaSeleccionado, page });
          },
          onRequestInfoProyecto: () => {
               dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
          },
          onRepetirPonderadores: (listaAñosPonderadoresEncuesta, page) => {
               dispatch({ type: 'REPETIR_PONDERADORES_ENCUESTA_REQUEST', listaAñosPonderadoresEncuesta, page });
          }
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(PonderadoresEncuestaContainer);