import React from 'react';
import { Card, Table, Row, Col, Button, Spin, Select, Tooltip, Form, Modal, Switch } from 'antd';
import { MonitorOutlined, IssuesCloseOutlined, CloseCircleOutlined, SearchOutlined, ScheduleOutlined, FolderViewOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalEvaluarFolioEstandar from './ModalEvaluarFolioEstandar';
import ModalDetalleFolioEvaluado from './ModalDetalleFolioEvaluado';

import imaSearch from '../../../assets/undraw_agree_re_hor9.svg';
import ReactVivus from "react-vivus";

const confirm = Modal.confirm;
const {Option} = Select;
class CumplimientoEstandaresContainer extends React.Component {
    formRef = React.createRef();
    state = {
        page: 1,
        step: 0,
        busqueda: null,
        hasSelected: true,
        selectedRowKeys: [],
        evaluados: false,
    }
    componentDidMount(){
        this.props.onListaServiciosActivos();
    }
    render() {
        const { onListaCumplimientoEstandares, fetchingListaCumplimientosEstandares,
            listaCumplimientosEstandares, listaCumplimientosEstandaresCantidad,
            fetchingListaServiciosActivosF, listaServiciosActivosF, onGenerarFolio,
            fetchingCrearFolioEstandar, limpiarFormulario, onListaFoliosEstandaresEvaluar,
            fetchingListaFoliosEstandaresEvaluar, listaFoliosEstandaresEvaluar, listaFoliosEstandaresEvaluarCantidad,
            onListaFoliosEstandaresEvaluados, listaFoliosEstandaresEvaluadosCantidad, listaFoliosEstandaresEvaluados,
            fetchingListaFoliosEstandaresEvaluados,
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columnsFoliosEvaluar = [{
                title: 'Folio del estándar',
                dataIndex: 'folioEstandar',
                key: 'folioEstandar',
                width: '20%',
            }, {
                title: 'Estado',
                dataIndex: 'estado',
                key: 'estado',
                width: '15%',
            }, {
                title: 'Servicio',
                dataIndex: 'servicio',
                key: 'servicio',
                width: '35%',
            }, {
                title: 'Fecha alta',
                dataIndex: 'fechaAltaGrid',
                key: 'fechaAltaGrid',
                width: '15%',
            }, {
                title: this.state.evaluados ? 'Evidencia' : 'Evaluar',
                key: 'evaluar',
                width: '15%',
                align: 'center',
                render: (text, record) => (
                    this.state.evaluados ? (
                        <a className='editarIcono' onClick={() => onDetalleEvaluados(record)}><FolderViewOutlined /></a>
                    ):(
                        <a className='editarIcono' onClick={() => onEvaluar(record)}><ScheduleOutlined /></a>
                    )
                ),
            }
        ];

        const onDetalleEvaluados = (folioSeleccionado) => {
            console.log(`folioSeleccionado`, folioSeleccionado);
            this.props.onShowModalFolioEvaluado(this.state.page, folioSeleccionado);
        }

        const onEvaluar = (folioSeleccionado) => {
            console.log(`folioSeleccionado`, folioSeleccionado)
            this.props.onShowModalEvaluarFolioEstandar(folioSeleccionado, this.state.page);
        }
        const columns = [{
                title: 'Estándar',
                dataIndex: 'referencia',
                key: 'referencia',
                width: '10%',
                sorter: true,
            }, {
                title: 'Referencia',
                dataIndex: 'referenciaGrid',
                key: 'referenciaGrid',
                width: '10%',
                sorter: true,
            }, {
                title: 'Descripción de la referencia',
                dataIndex: 'descripcionGrid',
                key: 'descripcionGrid',
                width: '20%',
                sorter: true,
            }, {
                title: 'Estándar de desempeño',
                dataIndex: 'descripcion',
                key: 'descripcion',
                width: '45%',
            }, {
                title: 'Método de Supervisión',
                dataIndex: 'claveMetodoSupervision',
                key: 'claveMetodoSupervision',
                width: '15%',
            }
        ];

        if (limpiarFormulario && limpiarFormulario === 'generarFolioEstandar') {
            this.setState({
                page: 1,
                step: 0,
                busqueda: null,
                hasSelected: true,
                selectedRowKeys: []
            });
            this.props.onLimpiarListaCumplimientoEstandares();
            this.props.onLimpiarFormulario();
        }

        const onCrearFolio = () => {
            console.log(`selectedRowKeys`, this.state.selectedRowKeys);
            let page = this.state.page;
            let busqueda = this.state.busqueda;
            let listaSeleccionados = this.state.selectedRowKeys;
            confirm({
                title: 'Estás seguro de generar el folio de evaluación?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onGenerarFolio(listaSeleccionados, page, busqueda);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }

        const handleTableChange = (pagination, filtro, ordenado)=>{
            this.setState({
                page: pagination.current,
            })
            if(ordenado){
                let campo = 'referencia';
                let order = 'ASC';
            
                if(ordenado.order === 'descend'){
                    order = 'DESC';
                }

                if(ordenado.columnKey === 'referenciaGrid'){
                    campo = 'rde.referencia';
                }else if(ordenado.columnKey === 'descripcionGrid'){
                    campo = 'rde.descripcion';
                }
                const busqueda = { 
                    estandar: this.state.busqueda.estandar, 
                    servicio: this.state.busqueda.servicio, 
                    orden: {
                        order: order, 
                        field: campo
                    } 
                }
                onListaCumplimientoEstandares(pagination.current, busqueda);
                this.setState({
                    busqueda: busqueda,
                });
            }
            else{
                onListaCumplimientoEstandares(pagination.current, this.state.busqueda);
            }
        };

        const onSeleccionEstandares = () => {
            this.setState({
                step: 1,
            });
        }

        const evaluarFolio = () => {
            this.setState({
                step: 2,
            });
            onListaFoliosEstandaresEvaluar(1, null)
        }

        const onCancelarEmisionEncuesta = () => {
            this.setState({
                page: 1,
                step: 0,
                busqueda: null,
                hasSelected: true,
                selectedRowKeys: [],
                evaluados: false,
            });
            this.props.onLimpiarListaCumplimientoEstandares();
        }

        const onFinish = (formulario) => {
            console.log(`formulario`, formulario);
            this.setState({
                busqueda: formulario,
            })
            onListaCumplimientoEstandares(1, formulario);
        }

        const onChangeTabla = (record) => {
            let hasSelected = true;

            if(record.length !== 0){
                hasSelected = false;
            }
            this.setState({
                hasSelected,
                selectedRowKeys: record
            })
        };

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: onChangeTabla,
        };

        const changeSwitch = (value) => {
            this.setState({
                evaluados: value,
                page: 1,
            });
            value ? onListaFoliosEstandaresEvaluados(1) : onListaFoliosEstandaresEvaluar(1, null);
        }

        const handleTableChangeFolios = (pagination) => {
            this.setState({
                page: pagination.current,
            })
            this.state.evaluados ? onListaFoliosEstandaresEvaluados(pagination.current) : onListaFoliosEstandaresEvaluar(pagination.current, null)
        }

        return (
            <Card className="cardCatalogos" title={<div className='headerLista'>Cumplimiento de Estándares Específicos y Estándares Generales</div>}>
                <Row justify='space-between'>
                    <Col span={10}>
                        <Button disabled={this.state.step !== 0 ? true : !permisosUsuario.selecciónDeEstandaresEspecificosYGeneralesYGeneraciónDeFolio} onClick={onSeleccionEstandares} className='botonesEmision'>Selección de Estándares y Generación de Folio. <MonitorOutlined /></Button> 
                    </Col>
                    <Col span={10}>
                        <Button disabled={this.state.step !== 0 ? true : !permisosUsuario.evaluarFolioDeEstándaresEspecíficosYGenerales} onClick={evaluarFolio} className='botonesEmision'>Evaluar Folio <IssuesCloseOutlined /></Button> 
                    </Col>
                </Row>
                <br/>
                {this.state.step === 1 ? (
                <Spin spinning={fetchingListaCumplimientosEstandares}>
                    <Form
                        name="formulario"
                        ref={this.formRef}
                        onFinish={onFinish}
                    >
                        <Row justify='space-around'>
                            <Col span={7}>
                                <Form.Item
                                    name="estandar"
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona un tipo de estándar"
                                        showSearch={true}
                                    >
                                        <Option key='Especifica'>Especifica</Option>
                                        <Option key='General'>General</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={14}>
                                <Form.Item
                                    name="servicio"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona un servicio"
                                        loading={fetchingListaServiciosActivosF}
                                        showSearch={true}
                                    >
                                        {listaServiciosActivosF.map(option => 
                                        <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                            <Tooltip title={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                {option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}
                                            </Tooltip>
                                        </Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={1}>
                                <Button className="btnFiltrar" shape="circle" icon={ <SearchOutlined />} htmlType="submit" />
                            </Col>
                        </Row>
                    </Form>
                    {listaCumplimientosEstandares[0] ? (
                    <Col span={24}>
                        <Col span={8}>
                            <Button onClick={onCrearFolio} disabled={this.state.hasSelected} className='nuevoUsuario'>Generar Folio</Button>
                        </Col>
                        <br/>
                        <Table 
                            size="small" 
                            rowKey="idEstandarDesempeno"
                            columns={columns}
                            dataSource={listaCumplimientosEstandares}
                            loading={fetchingCrearFolioEstandar}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: this.state.evaluados ? listaFoliosEstandaresEvaluadosCantidad : listaCumplimientosEstandaresCantidad, simple: true, current: this.state.page}}
                            rowSelection={rowSelection}
                        />
                    </Col>
                    ):false}
                    
                    <Row justify='start'>
                        <Col span={8}>
                            <Button onClick={onCancelarEmisionEncuesta} className='btnCargaArchivos'>Cancelar <CloseCircleOutlined /></Button> 
                        </Col>
                    </Row>
                </Spin> 
                ): (
                    this.state.step === 2 ? (
                        <Row justify='center'>
                            <Col span={3}>
                                <Switch 
                                checkedChildren={'Evaluados'}
                                unCheckedChildren={'Evaluar'}
                                defaultChecked={false}
                                onChange={changeSwitch}
                                />
                            </Col>
                            
                            <Col span={24}>
                                <br/>
                                <Table 
                                    size="small" 
                                    rowKey="idFolioEstandar"
                                    columns={columnsFoliosEvaluar}
                                    dataSource={this.state.evaluados ? listaFoliosEstandaresEvaluados : listaFoliosEstandaresEvaluar}
                                    loading={this.state.evaluados ? fetchingListaFoliosEstandaresEvaluados : fetchingListaFoliosEstandaresEvaluar}
                                    locale={{ emptyText: 'Sin datos' }}
                                    onChange={handleTableChangeFolios}
                                    pagination={{ total: this.state.evaluados ? listaFoliosEstandaresEvaluadosCantidad : listaFoliosEstandaresEvaluarCantidad, simple: true, current: this.state.page}}
                                />     
                            </Col>
                            <Col span={8}>
                                <Button onClick={onCancelarEmisionEncuesta} className='btnCargaArchivos'>Cancelar <CloseCircleOutlined /></Button> 
                            </Col>
                        </Row>
                        ):(
                            <Row justify='center' justify='space-around' align='middle' gutter={[8,8]}>
                                <Col span={10} style={{paddingTop:'4em', paddingBottom:'1em', textAlign:'center'}}>
                                {/* <img src={imaSearch} /> */}
                                    <ReactVivus
                                        id="imaSearch"
                                        option={{
                                                file: imaSearch,
                                                animTimingFunction: "EASE",
                                                type: "delayed",
                                        }}
                                    />
                                </Col>
                            </Row>
                        )
                )}
                
                <ModalEvaluarFolioEstandar/>
                <ModalDetalleFolioEvaluado/>
            </Card>
        );
    }
}
 
const mapStateToProps = state => {
    return {
        fetchingListaCumplimientosEstandares: state.GestionDeCalidadReducer.fetchingListaCumplimientosEstandares,
        listaCumplimientosEstandares: state.GestionDeCalidadReducer.listaCumplimientosEstandares,
        listaCumplimientosEstandaresCantidad: state.GestionDeCalidadReducer.listaCumplimientosEstandaresCantidad,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        fetchingCrearFolioEstandar: state.GestionDeCalidadReducer.fetchingCrearFolioEstandar,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaFoliosEstandaresEvaluar: state.GestionDeCalidadReducer.fetchingListaFoliosEstandaresEvaluar,
        listaFoliosEstandaresEvaluar: state.GestionDeCalidadReducer.listaFoliosEstandaresEvaluar,
        listaFoliosEstandaresEvaluarCantidad: state.GestionDeCalidadReducer.listaFoliosEstandaresEvaluarCantidad,
        fetchingListaFoliosEstandaresEvaluados: state.GestionDeCalidadReducer.fetchingListaFoliosEstandaresEvaluados,
        listaFoliosEstandaresEvaluados: state.GestionDeCalidadReducer.listaFoliosEstandaresEvaluados,
        listaFoliosEstandaresEvaluadosCantidad: state.GestionDeCalidadReducer.listaFoliosEstandaresEvaluadosCantidad,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onListaCumplimientoEstandares: (page, busqueda) => {
            dispatch({ type: 'LISTA_CUMPLIMIENTO_ESTANDARES_REQUEST', page, busqueda });
        },
        onLimpiarListaCumplimientoEstandares: () => {
            dispatch({ type: 'LISTA_CUMPLIMIENTO_ESTANDARES_SUCCESS', listaCumplimientosEstandares: [], listaCumplimientosEstandaresCantidad: 0 });
        },
        onListaServiciosActivos: () => {
            dispatch ({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST' });
        },
        onGenerarFolio: (listaSeleccionados, page, busqueda) => {
            dispatch ({ type: 'CREAR_FOLIO_ESTANDAR_REQUEST', listaSeleccionados, page, busqueda });
        },
        onListaFoliosEstandaresEvaluar: (page, busqueda) => {
            dispatch({ type: 'LISTA_FOLIOS_ESTANDARES_EVALUAR_REQUEST', page, busqueda });
        },
        onShowModalEvaluarFolioEstandar: (folioEstandarSeleccionado, page) => {
            dispatch ({ type: 'MODAL_EVALUAR_FOLIO_ESTANDAR', showModalEvaluarFolioEstandar: true, folioEstandarSeleccionado, page })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onListaFoliosEstandaresEvaluados: (page) => {
            dispatch({ type: 'LISTA_FOLIOS_ESTANDARES_EVALUADOS_REQUEST', page });
        },
        onShowModalFolioEvaluado: (page, folioEstandarSeleccionado) => {
            dispatch ({ type: 'MODAL_DETALLE_FOLIO_EVALUADO', showModalDetalleFolioEvaluado: true, folioEstandarSeleccionado, page })
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CumplimientoEstandaresContainer);