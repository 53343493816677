import React from 'react';
import { message, Form, Row, Col, Upload, Button, Modal, Spin, Select, Progress, Alert, Card, Typography } from 'antd';
import { connect } from "react-redux";
import { UploadOutlined, DownloadOutlined, FileExcelFilled } from '@ant-design/icons';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

import imaSearch from '../../../../assets/undraw_uploading_re_okvh.svg';
import ReactVivus from "react-vivus";

const { confirm } = Modal;
const { Option } = Select;
const { Dragger } = Upload;
const { Text, Paragraph } = Typography;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class RequisicionDirectaContainer extends React.Component {
    formRef = React.createRef();

    state = {
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
        page: 1,
        estado: 'revision',
        percent: 50,
        loadings: [],
        loading: false,
        servicioSelect: false,
        botonAgregar: true,
        arrayLimpio: [],
        fileList: [],
    }

    enterLoading = index => {
        this.setState(({ loadings }) => {
            const newLoadings = [...loadings];
            newLoadings[index] = true;
        
            return {
                loadings: newLoadings,
            };
        });
            setTimeout(() => {
            this.setState(({ loadings }) => {
                const newLoadings = [...loadings];
                newLoadings[index] = false;
        
                return {
                loadings: newLoadings,
                };
            });
        }, 1000);
    };

    render() {
        const { 
            limpiarFormulario, 
            onSubirArchivoRequisicion, 
            fetchingSubirArchivoRequisicion, 
            onLimpiarFormulario,
            folio,
            registros,
            responseStatus,
            textMessage,
            onFolioRequisicion,
            mesaggeSpin, 
            descriptionSpin, 
            typeSpin,
            onListaServiciosFiltro,
            onListaServiciosFiltroLimpiar,
            fetchingListaServiciosFiltro,
            listaServiciosFiltro,
        } = this.props;

        const { loadings } = this.state;

        const onFinish = formulario => {

            onSubirArchivoRequisicion(formulario, this.state.page);
            console.log('Success:', formulario);
            
            this.setState ({
                //filePDF: false,
                botonAgregar: true,
            });
            this.formRef.current.resetFields(['idServicio']);
            onListaServiciosFiltroLimpiar();
        };

        if (limpiarFormulario && limpiarFormulario === 'cargarArchivoActivo') {
            this.formRef.current.resetFields(['csv']);
            this.setState({
                filePDF: false,
                botonPDF: false,
                loading: false,
                mesaggeSpin: 'Folio de Requisición Directa',
                descriptionSpin: 'Seleccione un archivo, por favor.',
                typeSpin: 'info',
            });
            onLimpiarFormulario();
        }
        
        
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 1000);           
        };
        const onChangePDF = (info) => {
            let fileList = [...info.fileList];

            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            fileList = fileList.slice(-1);

            console.log('fileList', fileList);

            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else {
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                    fileList
                })
            }
            if (info.file.status === 'done') {
                var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,40}$");
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc.). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['csv']);
                }
                else {
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true,
                        loading: false,
                        typeSpin: 'info',
                        fileList
                    });

                    if(this.state.loading){
                        this.state.mesaggeSpin = "Iniciando Revisión de Formato de Archivo";
                        this.state.descriptionSpin = "Presione el botón 'Generar Folio' para continuar"
                        this.state.typeSpin = 'warning'
                    } 
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                    fileList
                });
            } else if (info.file.status === 'error') {
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc. ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['csv']);
                }
                else {
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true,
                        loading: false,
                        typeSpin: 'info',
                        fileList
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                    fileList
                });
            }
        }

        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
                loading: false,
                mesaggeSpin: 'Folio de Requisición Directa',
                descriptionSpin: 'Seleccione un archivo, por favor.',
                typeSpin: 'info',
                responseStatusLocal: false,
                fileList: []
            });
        }

        const handleLoading = (formulario) => {
            let percent = this.state.percent + 10;
            
                if(percent < 100){
                    percent+=1;
                }
                else if (percent > 100) {
                    percent = 100;
                }
            
            this.setState({ percent });

            Modal.info({
                title: 'Ejecutando Revisión de Formato de Archivo, espere',
                content: (
                    <Row type='flex' justify='space-around'>
                        <Progress percent={this.state.percent} size="big" status="active" type="line" strokeColor={{'0%': '#0e2c82', '99%': '#ff8c62'}}/>
                    </Row>
                  
                ),
                onOk() {},
              });
        };

        const valorInicialStatus = () => {
            console.log("Entra a valorInicialStatus")
            this.setState({
                mesaggeSpin: 'Folio de Requisición Directa',
                descriptionSpin: 'Seleccione un archivo, por favor.',
                typeSpin: 'info',
                //loading: true,
            });
        }

        const container = (
            <Alert
              message= {mesaggeSpin}
              description={descriptionSpin}
              type={typeSpin}
              showIcon
            />
        );

        const buscarServicios = value => {
            if(value.length>=3){
                 if(value){
                      onListaServiciosFiltro(value)
                 } else {
                      message.warning('No se encontró servicio con esas palabras')
                 }
            } else {
                 onListaServiciosFiltroLimpiar();
            } 
        }

        const servicioSeleccion = (value) => {
            console.log('this.state.filePDF', this.state.filePDF)
            console.log('value', value);
            if(value != undefined && (this.state.filePDF) === true){
                //this.state.botonAgregar = false
                this.setState ({
                    botonAgregar: false
                });
            } else {
                //this.state.botonAgregar = true
                this.setState ({
                    botonAgregar: true
                });
            }
        }

        return (
        <Card className="cardCatalogos" title={<div className="headerLista">Generar Folio de Requisición Directa</div>}>
            <Row justify='space-between' style={{textAlign: 'left'}}>
                <Col span={11}>
                    <Spin spinning={fetchingSubirArchivoRequisicion}>
                        <Form {...layout}
                        name="formulario"
                        onFinish={onFinish}
                        
                        ref={this.formRef}>
                            <Row justify='space-around'>
                                <Col span={24}>
                                    <Paragraph>
                                        Favor de agregar un archivo de extensión CSV. El nombre del archivo no debe contener caracteres especiales (*, /, acentos,.,comas,etc.).
                                    </Paragraph>
                                        
                                    <Paragraph>
                                        <pre style={{backgroundColor:'#fafafa', borderRadius:'1em', padding:'.5em'}}>Ejemplo: tabladatos.csv</pre>
                                    </Paragraph>

                                    <Paragraph>
                                        <Text strong>
                                            Nota: Revisa que el formato no haya tenido pérdidas en data antes de subirlo a revisión.
                                        </Text>
                                    </Paragraph>
                                    <Form.Item
                                        label="Archivo CSV"
                                        name="csv"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Dragger customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.csv' multiple={false} style={{borderRadius:'1em'}} fileList={this.state.fileList}>
                                            <Row justify='center'>
                                                <FileExcelFilled className='iconTabPane'/>
                                                <Col span={24} style={{paddingTop:'1em'}}>
                                                    <Text strong> Click o arrastra a ésta área el achivo </Text>
                                                </Col>
                                            </Row>
                                        </Dragger>

                                        {/* <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.csv' multiple={false}>
                                            {this.state.filePDF === false ? (
                                            <Button disabled={this.state.botonPDF} className='btnCargaArchivos' onClick={valorInicialStatus}>
                                                <UploadOutlined /> Click para Subir Archivo CSV
                                            </Button>
                                            ):false}
                                        </Upload> */}
                                        
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                    name="idServicio"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    
                                    >
                                        <Select
                                                //mode='tags'
                                                allowClear
                                                style={{ width: '100%' }}
                                                placeholder="Escribe el nombre del servicio a buscar "
                                                loading={fetchingListaServiciosFiltro}
                                                showSearch={true}
                                                filterOption={false}
                                                onSearch={buscarServicios}
                                                notFoundContent={null}
                                                onChange={servicioSeleccion}
                                        >
                                                {listaServiciosFiltro.map(option => <Option key={option.idServicio}>{option.nomenclatura + ': ' + option.nombre}</Option>)}
                                        </Select>
                                    </Form.Item>

                                </Col>

                                <Col span={12}>
                                    <Button className="btnFiltrar" style={{ width: '100%' }} icon={<DownloadOutlined />} loading={loadings[1]} onClick={() => this.enterLoading(1)} href="https://appcontrollersolutions.com/FormatoRequisicionDirecta.csv">
                                        Descargar Formato
                                    </Button>
                                </Col>
                                <Col span={11}>
                                    <Button disabled={this.state.botonAgregar} className="btnFormato" style={{ width: '100%' }} htmlType="submit">Generar Folio</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                </Col>

                <Col span={11}>
                    
                    <Row justify='center'>
                        <Col span= {24}>
                            <Spin spinning={this.state.loading} delay={500}>
                                    {container}
                            </Spin>
                        </Col>
                        <Col span={12} style={{paddingTop:'3em', paddingBottom:'3em'}}>
                        {/* <img src={imaSearch} /> */}
                            <ReactVivus
                                id="imaSearch"
                                option={{
                                    file: imaSearch,
                                    animTimingFunction: "EASE",
                                    type: "delayed",
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaArchivosRequisicionEspera: state.AlmacenesReducer.fetchingListaArchivosRequisicionEspera,
        listaArchivosRequisicionEspera: state.AlmacenesReducer.listaArchivosRequisicionEspera,
        listaArchivosRequisicionEsperaCantidad: state.AlmacenesReducer.listaArchivosRequisicionEsperaCantidad,
        fetchingSubirArchivoRequisicion: state.AlmacenesReducer.fetchingSubirArchivoRequisicion,
        folio: state.AlmacenesReducer.folio,
        registros: state.AlmacenesReducer.registros,
        responseStatus: state.AlmacenesReducer.responseStatus,
        textMessage: state.AlmacenesReducer.textMessage,
        mesaggeSpin: state.AlmacenesReducer.mesaggeSpin,
        descriptionSpin: state.AlmacenesReducer.descriptionSpin,
        typeSpin: state.AlmacenesReducer.typeSpin,
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onSubirArchivoRequisicion: (formulario, page) => {
            dispatch({ type: 'SUBIR_ARCHIVO_REQUISICION_ACTIVO_REQUEST', formulario, page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        },
        onMessageFailure: () => {
            dispatch({ type: 'SUBIR_ARCHIVO_REQUISICION_ACTIVO_FAILURE' })
        },
        onFolioRequisicion: (folio) => {
            dispatch({ type: 'FOLIO_REQUISICION_DIRECTA_REQUEST', folio });
        },
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(RequisicionDirectaContainer);