import React, { Component } from 'react';
import { Row, Form, Input, Button, Modal, Col, Spin, Select, DatePicker, message, InputNumber, Popover, Divider, Typography } from 'antd';
import { VideoCameraOutlined } from '@ant-design/icons';

import moment from 'moment';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const {Text} = Typography;
const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalCrearInstalacion extends Component {
    state = {
        showCalendario: false,
    }

    formRef = React.createRef();

    componentDidMount() {
        this.props.onGetActivosCaract();
    }

    render() {
        const { fetchingSalidaInstalacion, showModalCrearActivos, onShowModalNuevoActivo,
            fetchingListaUbicacionesFiltro, listaUbicacionesFiltro, limpiarFormulario, onLimpiarFormulario, 
            listaInfoProyecto, onListaUbicacionesFiltro, onListaUbicacionesFiltroLimpiar,
            listaDetalle, onCrearInstalacion, movimientoSeleccionado,
        } = this.props;

        const onFinish = formulario => {
            const listaActivos = [];
            for (var key in formulario) {
                if (Object.prototype.hasOwnProperty.call(formulario, key)) {
                    let idCampo = key.split('-');
                    if (idCampo.length === 2) {
                        let idMovimientoAlmacen = idCampo[1];
                        let campo = idCampo[0];
                        let valor = formulario[key];
    
                        let activo = {[campo]: valor, 'idMovimientoAlmacen': parseInt(idMovimientoAlmacen)};
                        listaActivos.push(activo);
                    }
                }
            }
            console.log('listaActivos', listaActivos);
            onCrearInstalacion(listaActivos, listaDetalle, movimientoSeleccionado, this.props.page, this.props.busqueda);
        };

        if (limpiarFormulario && limpiarFormulario === 'crearInstalacion') {
            this.formRef.current.resetFields();
            this.setState({
                showCalendario: false,
            })
            onLimpiarFormulario();
            onListaUbicacionesFiltroLimpiar();
        }
        

        const handleCancel = () => {
            onShowModalNuevoActivo();
            onListaUbicacionesFiltroLimpiar();
            this.setState({
                showCalendario: false,
            })
            this.formRef.current.resetFields();
        }

        const fechas = (rangeValue, idMovimientoAlmacen ) => {
            if(listaInfoProyecto.fechaInicio && rangeValue){
                const diferencia = listaInfoProyecto.fechaInicio.diff(moment(rangeValue.format('YYYY-MM-DD')), "days");
                this.formRef.current.resetFields(['fechaGarantia-' + idMovimientoAlmacen]);
                if(diferencia>0){
                    message.error('No es posible seleccionar una fecha de inicio menor al inicio de contrato del proyecto', 7);
                    this.formRef.current.resetFields(['fechaInstalacion-' + idMovimientoAlmacen]);
                    this.setState({
                        showCalendario: false,
                    });
                }
                else{
                    this.setState({
                        showCalendario: true,
                    });
                }
            }
            else{
                message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                this.formRef.current.resetFields(['fechaGarantia-' + idMovimientoAlmacen, 'fechaInstalacion-' + idMovimientoAlmacen]);
                this.setState({
                    showCalendario: false,
                });
            }
        }
        const fechaValidarGarantia = (fecha, idMovimientoAlmacen) => {
            if(this.state.showCalendario){
                if(fecha) {
                    const fechaInstalacion = this.formRef.current.getFieldValue('fechaInstalacion-' + idMovimientoAlmacen);
                    if(fechaInstalacion){
                        const diferenciaGarantia = moment(fechaInstalacion.format('YYYY-MM-DD')).diff(moment(fecha.format('YYYY-MM-DD')), "days");
                        if(diferenciaGarantia<0){
                            message.error('No es posible seleccionar una fecha garantía mayor a la fecha instalación', 7);
                            this.formRef.current.resetFields(['fechaGarantia-' + idMovimientoAlmacen]);
                        }
                    }
                }
            }else{
                message.error('Selecciona una fecha de instalación');
                this.formRef.current.resetFields(['fechaGarantia-' + idMovimientoAlmacen]);
            }
        }
        
        const handleSearch = (value) => {
            if(value.length>=3){
                if (value) {
                    onListaUbicacionesFiltro(value);
                } else {
                    message.warning('No se encontró ubicación con esas palabras')
                }
            }
            else{
                onListaUbicacionesFiltroLimpiar();
            }
        };
        
        return (
            <Modal
                visible={showModalCrearActivos}
                title='Crear Instalación de Activos'
                onCancel={handleCancel}
                footer={false}
                width='90%'>
                <Spin spinning={fetchingSalidaInstalacion}>
                    <Form {...layout}
                        name="formulario"
                        onFinish={onFinish}
                        ref={this.formRef}>
                        {listaDetalle.map(activo => 
                        <>
                            {activo.idMovimientoAlmacen_serieNum.map(serieNum => 
                            <Row justify='space-around' gutter={[8,8]} key={serieNum.identificador}>
                                <Col span={24}>
                                    <Divider orientation="left"><VideoCameraOutlined style={{fontSize:'1.5em', color: '#1747ca'}}/><Text style={{color:'#0e2c82'}} strong> {' ' + activo.nombre + ' - ' + serieNum.etiqueta} </Text> </Divider>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Ubicación"
                                        name={"idUbicacion-" + serieNum.identificador}
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Ingrese la ubicación"
                                            loading={fetchingListaUbicacionesFiltro}
                                            showSearch
                                            filterOption={false}
                                            onSearch={handleSearch}
                                            notFoundContent={null}
                                        >
                                            {listaUbicacionesFiltro.map(option => 
                                            <Option key={option.idUbicacion}>
                                                <Popover content={option.todoUbicacion} title={false}>
                                                    {option.todoUbicacion}
                                                </Popover>
                                            </Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Observaciones"
                                        name={"observaciones-" + serieNum.identificador}
                                    >
                                        <Input maxLength={100} placeholder="Ingrese observaciones" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label="Fecha instalación"
                                        name={"fechaInstalacion-" + serieNum.identificador}
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <DatePicker onChange={e => fechas(e, serieNum.identificador)} style={{ width: '100%' }} 
                                        format='DD-MM-YYYY' placeholder={["Seleccione fecha instalación"]} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label="Fecha garantía"
                                        name={"fechaGarantia-" + serieNum.identificador}
                                    >
                                        <DatePicker onChange={e => fechaValidarGarantia(e, serieNum.identificador)} style={{ width: '100%' }} 
                                        format='DD-MM-YYYY'  placeholder={["Seleccione fecha garantía"]} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label="Garantía años"
                                        name={"garantia-" + serieNum.identificador}
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <InputNumber min={1} placeholder="Ingrese la garantía" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            )}
                        </>
                        )}
                        <Row justify='space-around'>
                            <Col span={11} className='marginBoton'>
                                <br />
                                <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Crear Instalación de Activos</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingSalidaInstalacion: state.AlmacenesReducer.fetchingSalidaInstalacion,
        showModalCrearActivos: state.AlmacenesReducer.showModalCrearActivos,
        
        listaDetalle: state.AlmacenesReducer.listaDetalle,
        movimientoSeleccionado: state.AlmacenesReducer.movimientoSeleccionado,
        page: state.AlmacenesReducer.page,
        busqueda: state.AlmacenesReducer.busqueda,

        fetchingListaUbicacionesFiltro: state.CatalogosGeneralesReducer.fetchingListaUbicacionesFiltro,
        listaUbicacionesFiltro: state.CatalogosGeneralesReducer.listaUbicacionesFiltro,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevoActivo: () => {
            dispatch({ type: 'SHOW_MODAL_CREAR_INSTALACION', showModalCrearActivos: false })
        },
        onCrearInstalacion: (formulario, listaDetalle, movimientoSeleccionado, page, busqueda) => {
            dispatch({ type: "SALIDA_INSTALACION_REQUEST", formulario, listaDetalle, movimientoSeleccionado, page, busqueda });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onGetActivosCaract: () => {
            dispatch({ type: 'LISTA_ACTIVOS_CARACT_REQUEST', });
        },
        onListaUbicacionesFiltro: (busqueda) => {
            dispatch({ type: 'LISTA_UBICACIONES_FILTRO_REQUEST', busqueda: busqueda });
        },
        onListaUbicacionesFiltroLimpiar: () => {
            dispatch({ type: 'LISTA_UBICACIONES_FILTRO_SUCCESS', listaUbicacionesFiltro: [] });
        },
        onListaActivosCaractFiltro: (busqueda) => {
            dispatch({ type: 'LISTA_ACTIVOS_CARACT_FILTRO_REQUEST', busqueda: busqueda });
        },
        onListaActivosCaractFiltroLimpiar: () => {
            dispatch({ type: 'LISTA_ACTIVOS_CARACT_FILTRO_SUCCESS', listaActivosCaractFiltro: [] });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalCrearInstalacion);