import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, FileExcelOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevaCategoriaDeFalla from './ModalNuevoCatalogoDeFallas';
import ModalEditarCategoriaDeFalla from './ModalEditarCatalogoDeFallas';

const confirm = Modal.confirm;
const { Search } = Input;

class CatalogoFallasDisponibilidad extends React.Component {
    state = {
        page:1,
        busqueda: '',
    }

    componentDidMount() {
        this.props.onGetCategoriasDeFallas(1);
    }

    render() {
        const {fetchingListaCategoriasDeFallasBusqueda, listaCategoriasDeFallasBusqueda, onGetCategoriasDeFallas,
            onModalNuevaCategoriaDeFalla, onModalEditarCategoriaDeFalla, onEstadoCategoriaDeFalla,listaCategoriasDeFallasBusquedaCantidad} = this.props;  
        
        console.log('ListaCetrosDeCosto');
        console.log(listaCategoriasDeFallasBusqueda)
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columns = [{
            title: 'Categoría',
            dataIndex: 'numeroFallaCategoria',
            key: 'numeroFallaCategoria',
        }, {
            title: 'Tiempo de rehabilitación',
            dataIndex: 'fallaCategoria',
            key: 'fallaCategoria',
        },{
            title: 'Unidad de medición',
            dataIndex: 'idUnidadMedida',
            key: 'idUnidadMedida',
        },{
            title: 'Fecha alta',
            dataIndex: 'fechaAlta',
            key: 'fechaAlta',
        }, {
            title: 'Activo',
            dataIndex: 'activo',
            key: 'activo',
            render: (text, record) => (
                <Switch checked={text} disabled={!permisosUsuario.categoriaDeTiempoDeRehabilitaciónActivoInactivo} onChange={() => handleDesactivar(record)}/>
            ),
        }, {
            title: 'Editar',
            key: 'editar',
            //width: '6%',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.editarCategoríaDeTiempoDeRehabilitación} onClick={() => onEditar(record)}><EditOutlined /></a>
            ),
        },
        ];

        const onBuscarCategoriaDeFalla = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            });
            console.log(busqueda);
            onGetCategoriasDeFallas(1, busqueda);
        }

        const handleDesactivar = (key) => {
            console.log(key);
            let page = this.state.page
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado de la categoría de falla "'+ key.numeroFallaCategoria +'"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoCategoriaDeFalla(key.idFallaCategoria, page);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }

        const onEditar = (key) => {
            onModalEditarCategoriaDeFalla(key, this.state.page);

        }

        const onNuevaCategoriaFallas = () => {
            onModalNuevaCategoriaDeFalla(this.state.page);
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            onGetCategoriasDeFallas(pagination.current, this.state.busqueda);
        }


        // const scroll = { "x": 1000 | true, "y": 1000 };
        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search className='buscarModulos' placeholder='Ingresar número de categoría' onSearch={value => onBuscarCategoriaDeFalla(value)}/>
                    </Col>
                    {/* <Col span={11}>
                        <Button onClick={onNuevaCategoriaFallas} disabled={!permisosUsuario.crearCategoríaDeTiempoDeRehabilitación} className='nuevoUsuario'>Nueva Categoría de Tiempo de Rehabilitación</Button>
                    </Col> */}

                    <Col span={1}>
                        <Tooltip title='Nueva Categoría de Tiempo de Rehabilitación' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevaCategoriaFallas} disabled={!permisosUsuario.crearCategoríaDeTiempoDeRehabilitación} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>

                    {/* <Col span={1}>
                        <Tooltip title='Exportar archivo .xls' placement="topRight">
                            <Button disabled={!permisosUsuario.exportarExcelCatálogoActivos} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } loading={this.props.fetchingExportarExcelTiemposRehabilitacion} onClick={() => this.props.onExport()}/>
                        </Tooltip>
                    </Col>  */}

                    <Col span={24}>
                        <br />
                        <Table
                            size="small"
                            rowKey="numeroFallaCategoria"
                            columns={columns}
                            dataSource={listaCategoriasDeFallasBusqueda}
                            loading={fetchingListaCategoriasDeFallasBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            // scroll={scroll}
                            onChange={handleTableChange}
                            pagination={{ total: listaCategoriasDeFallasBusquedaCantidad, simple: true, current: this.state.page}}
                        />
                    </Col>
                </Row>
                <ModalNuevaCategoriaDeFalla/>
                <ModalEditarCategoriaDeFalla/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        listaCategoriasDeFallasBusqueda: state.CatalogosGeneralesReducer.listaCategoriasDeFallasBusqueda,
        fetchingListaCategoriasDeFallasBusqueda: state.CatalogosGeneralesReducer.fetchingListaCategoriasDeFallasBusqueda,
        listaCategoriasDeFallasBusquedaCantidad: state.CatalogosGeneralesReducer.listaCategoriasDeFallasBusquedaCantidad,
        fetchingExportarExcelTiemposRehabilitacion: state.CatalogosGeneralesReducer.fetchingExportarExcelTiemposRehabilitacion,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetCategoriasDeFallas: (page, busqueda) => {
            dispatch({ type: "CATEGORIAS_FALLAS_BUSQUEDA_REQUEST", page: page, busqueda: busqueda});
        },
        onEstadoCategoriaDeFalla: (idFallaCategoria, page) => {
            dispatch({ type: "ESTADO_CATEGORIA_FALLA_REQUEST", idFallaCategoria: idFallaCategoria, page:page});
        },
        onModalNuevaCategoriaDeFalla: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVA_CATEGORIA_FALLA", showModalNuevaCategoriaDeFalla: true, page:page})
        },
        onModalEditarCategoriaDeFalla: (CategoriaDeFallaSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_CATEGORIA_FALLA", showModalEditarCategoriaDeFalla: true, CategoriaDeFallaSeleccionado: CategoriaDeFallaSeleccionado, page:page})
        },
        onExport: (page, busqueda) => {
            dispatch({ type: "EXPORTAR_EXCEL_TIEMPOS_REHABILITACION_REQUEST", page, busqueda });
       },
       
    };
};

export default connect(mapStateToProps, mapDispatchToProps) (CatalogoFallasDisponibilidad);