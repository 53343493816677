import React from 'react';
import { Form, Spin, Row, Col, Table, Tooltip, Button, Radio, Select, message} from 'antd';
import { connect } from "react-redux"; 
import '../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';

const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };

class DeduccionConsumoSP extends React.Component {
     formRef = React.createRef();
     
     componentDidMount(){ this.props.getTipoConsumo(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, 1); this.props.getTipoConsumoGeneral(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, 1); }

     state={ page: 1, currentRadioValue: 'tipo' }
 
     render() {
          const {  } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){ permisosUsuario = JSON.parse(permisosUsuario); }

          const onFinish = (formulario) => {
               console.log('Success:', formulario);
          };

          const columnsGenerales = [{
                    title: 'Tipo de consumo',
                    dataIndex: 'nombre',
                    key: 'nombre',
                    width: '10%',
               }, {
                    title: 'Cantidad consumida por el desarrollador',
                    dataIndex: 'totalConsumidaDesarrollador',
                    key: 'totalConsumidaDesarrollador',
                    align: 'right',
                    width: '10%',
               }, {
                    title: 'Cantidad consumida por el PYRS',
                    dataIndex: 'totalPyrs',
                    key: 'totalPyrs',
                    align: 'right',
                    width: '10%',
               }, {
                    title: 'Cantidad total consumida',
                    dataIndex: 'totalConsumida',
                    key: 'totalConsumida',
                    align: 'right',
                    width: '10%',
               }, {
                    title: 'Monto total a pagar',
                    dataIndex: 'totalPagar',
                    key: 'totalPagar',
                    align: 'right',
                    width: '10%',
               }, {
                    title: 'Expectativa para el siguiente mes',
                    dataIndex: 'expectativaMes',
                    key: 'expectativaMes',
                    align: 'right',
                    width: '10%',
               }, {
                    title: 'Expectativa para el siguiente año',
                    dataIndex: 'expectativaMesAnio',
                    key: 'expectativaMesAnio',
                    align: 'right',
                    width: '10%',
               }
           ];
   
           const columnsMes = [
               {
                    title: 'Folio',
                    dataIndex: 'folio',
                    key: 'folio',
                    width: '10%',
               }, {
                    title: 'Tipo de consumo',
                    dataIndex: 'tipoConsumo',
                    key: 'tipoConsumo',
                    width: '10%',
               }, {
                    title: 'Unidad de medición',
                    dataIndex: 'unidadMedicion',
                    key: 'unidadMedicion',
                    width: '10%',
               }, {
                    title: 'Costo unitario',
                    dataIndex: 'precioUnitario',
                    key: 'precioUnitario',
                    align: 'right',
                    width: '10%',
               }, {
                    title: 'Cantidad consumida por el desarrollador',
                    dataIndex: 'consumoDesarrollador',
                    key: 'consumoDesarrollador',
                    align: 'right',
                    width: '10%',
               }, {
                    title: 'Cantidad consumida por el PYRS',
                    dataIndex: 'consumoPyrs',
                    key: 'consumoPyrs',
                    align: 'right',
                    width: '10%',
               }, {
                    title: 'Cantidad total consumida',
                    dataIndex: 'totalConsumida',
                    key: 'totalConsumida',
                    align: 'right',
                    width: '10%',
               }, {
                    title: 'Monto total a pagar',
                    dataIndex: 'totalFacturado',
                    key: 'totalFacturado',
                    align: 'right',
                    width: '10%',
               },
               // {
               //      title: 'Expectativa para el siguiente mes',
               //      dataIndex: 'expectativaMes',
               //      key: 'expectativaMes',
               //      align: 'right',
               //      width: '10%',
               // }, {
               //      title: 'Expectativa para el siguiente año',
               //      dataIndex: 'expectativaMesAnio',
               //      key: 'expectativaMesAnio',
               //      align: 'right',
               //      width: '10%',
               // }
          ];

          const handleTableChange = (pagination) => {
               this.setState({ page: pagination.current })

               if(this.state.currentRadioValue === 'tipo'){
                    this.props.getTipoConsumo(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, pagination.current);
               } else {
                    this.props.getTipoConsumoGeneral(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, pagination.current);
               }
          };

          const onChangePS = (e) => {
               this.setState({ currentRadioValue: e.target.value });
          }

          const onExportPDF = () => {
               if(this.state.currentRadioValue === 'tipo'){
                    this.props.onExportTipoConsumosPDF(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, this.props.mesTexto);
               } else {
                    this.props.onExportGeneralPDF(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, this.props.mesTexto);
               }
          };

          const onExportEXCEL = () => {
               if(this.state.currentRadioValue === 'tipo'){
                    this.props.onExportTipoConsumosEXCEL(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, this.props.mesTexto);
               } else {
                    this.props.onExportGeneralEXCEL(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, this.props.mesTexto);
               }
          };

          return (<>
               <Form {...layout} name="formulario" ref={this.formRef} onFinish={onFinish}>
                    <Row justify='space-around'>
                         <Col span={24}>
                              <br/>
                              <Table
                                   title={ () => (
                                        <>
                                             <Row justify='center' type="flex" align='middle'>
                                                  { this.state.currentRadioValue === 'tipo' ? (
                                                       <h3>REPORTE DE FACTURACIÓN DE SERVICIOS PÚBLICOS POR TIPO DE CONSUMO <b>{ this.props.mesTexto.toUpperCase() } {this.props.anioSeleccionadoRMDP}</b></h3>
                                                  ):(
                                                       <h3>REPORTE DE FACTURACIÓN DE SERVICIOS PÚBLICOS <b>{ this.props.mesTexto.toUpperCase() } {this.props.anioSeleccionadoRMDP}</b></h3>
                                                  ) }
                                             </Row>
                                             <Row justify='space-between' gutter={[8,8]} type="flex" align='middle'>
                                                  <Col span={6}>
                                                       <Radio.Group defaultValue="tipo" buttonStyle="solid" onChange={(e) => onChangePS(e)}>
                                                            <Radio value="tipo">Servicios Públicos por Tipo de Consumo</Radio>
                                                            <Radio value="consumo">Servicios Públicos Generales</Radio>
                                                       </Radio.Group>
                                                  </Col>
                                                  <Col span={5}>
                                                       <Row justify='space-around' gutter={[8,8]} type="flex" align='middle'>
                                                            <Col span={1}>
                                                                 <Tooltip title='Exportar a PDF'>
                                                                      <Button className="iconSecundary" shape='circle' icon={<FilePdfOutlined /> } loading={ this.state.currentRadioValue === 'tipo' ? this.props.fetchingPDFTipoConsumoRMDP: this.props.fetchingPDFTipoConsumoGeneral } onClick={onExportPDF}/>
                                                                 </Tooltip>
                                                            </Col>

                                                            <Col span={1}>
                                                                 <Tooltip title='Exportar a Excel'>
                                                                      <Button className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } loading={ this.state.currentRadioValue === 'tipo' ? this.props.fetchingEXCELTipoConsumoRMDP: this.props.fetchingEXCELTipoConsumoGeneral } onClick={onExportEXCEL}/>
                                                                 </Tooltip>
                                                            </Col>
                                                       </Row>
                                                  </Col>
                                             </Row>
                                        </>)
                                   }
                                   size="small"
                                   rowKey="idServicioPublico"
                                   columns={ this.state.currentRadioValue === 'tipo' ? columnsMes : columnsGenerales}
                                   dataSource={ this.state.currentRadioValue === 'tipo' ? this.props.dataTipoConsumoRMDP : this.props.dataTipoConsumoGeneral}
                                   loading={ this.state.currentRadioValue === 'tipo' ? this.props.fetchingGetTipoConsumoRMDP: this.props.fetchingDataTipoConsumoGeneral}
                                   locale={{emptyText: 'Sin datos'}}
                                   onChange={handleTableChange}
                                   pagination={{
                                        total: this.state.currentRadioValue === 'tipo' ? this.props.dataTipoConsumoRMDPCantidad: this.props.dataTipoConsumoGeneralCantidad,
                                        simple: true,
                                        current: this.state.page
                                   }}
                              />
                         </Col>
                    </Row>
               </Form>
          </>);
     }
}
 
const mapStateToProps = state => {
     return {
          anioSeleccionadoRMDP: state.RMDPReducer.anioSeleccionadoRMDP,
          mesTexto: state.RMDPReducer.mesTexto,
          mesSeleccionadoRMDP: state.RMDPReducer.mesSeleccionadoRMDP,

          fetchingGetTipoConsumoRMDP: state.RMDPReducer.fetchingGetTipoConsumoRMDP,
          dataTipoConsumoRMDP: state.RMDPReducer.dataTipoConsumoRMDP,
          dataTipoConsumoRMDPCantidad: state.RMDPReducer.dataTipoConsumoRMDPCantidad,

          dataTipoConsumoGeneral: state.RMDPReducer.dataTipoConsumoGeneral,
          dataTipoConsumoGeneralCantidad: state.RMDPReducer.dataTipoConsumoGeneralCantidad,
          fetchingDataTipoConsumoGeneral: state.RMDPReducer.fetchingDataTipoConsumoGeneral,

          fetchingPDFTipoConsumoRMDP: state.RMDPReducer.fetchingPDFTipoConsumoRMDP,
          fetchingPDFTipoConsumoGeneral: state.RMDPReducer.fetchingPDFTipoConsumoGeneral,

          fetchingEXCELTipoConsumoRMDP: state.RMDPReducer.fetchingEXCELTipoConsumoRMDP,
          fetchingEXCELTipoConsumoGeneral: state.RMDPReducer.fetchingEXCELTipoConsumoGeneral,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          getTipoConsumo: (idMes, anio, page) => {
               dispatch({ type: 'GET_TIPO_CONSUMO_RMDP_REQUEST', idMes, anio, page });
          },
          getTipoConsumoGeneral: (idMes, anio, page) => {
               dispatch({ type: 'GET_TIPO_CONSUMO_GENERAL_RMDP_REQUEST', idMes, anio, page });
          },
          onExportTipoConsumosPDF: (idMes, anio, mesTxt) => {
               dispatch({ type: 'PDF_TIPO_CONSUMO_RMDP_REQUEST', idMes, anio, mesTxt });
          },
          onExportGeneralPDF: (idMes, anio, mesTxt) => {
               dispatch({ type: 'PDF_TIPO_CONSUMO_GENERAL_RMDP_REQUEST', idMes, anio, mesTxt });
          },
          onExportTipoConsumosEXCEL: (idMes, anio, mesTxt) => {
               dispatch({ type: 'EXCEL_TIPO_CONSUMO_RMDP_REQUEST', idMes, anio, mesTxt });
          },
          onExportGeneralEXCEL: (idMes, anio, mesTxt) => {
               dispatch({ type: 'EXCEL_TIPO_CONSUMO_GENERAL_RMDP_REQUEST', idMes, anio, mesTxt });
          },
     };
};
export default connect(mapStateToProps, mapDispatchToProps)(DeduccionConsumoSP);