import React, { Component } from 'react';
import {Row, Col, Modal, Table} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';

class ModalDetalleReporte extends Component {
    formRef = React.createRef();

    render() {
        const { onShowModalDetalleReporte, showModalDetalleReporte, reporteSeleccionado, 
            listaTrazabilidad, fetchingListaTrazabilidad, listaTrazabilidadCantidad, 
            onGetTrazabilidadFolio, 
        } = this.props;

        const handleCancel = () => {
            onShowModalDetalleReporte();
        }

        const columns = [{
            title: 'Fecha de Actualización',
            dataIndex: 'fechaActualizacion',
            key: 'fechaActualizacion',
            align: 'center'
        },{
            title: 'Usuario CCO(usuario que registra)',
            dataIndex: 'username',
            key: 'username',
            align: 'center'
        },{
            title: 'Fecha de Estado',
            dataIndex: 'fechaEstado',
            key: 'fechaEstado',
            align: 'center'
        },{
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            align: 'center'
        }];

        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onGetTrazabilidadFolio(reporteSeleccionado.folio, pagination.current);
        }
        
        return (
            <Modal
                visible={showModalDetalleReporte}
                title='Detalle del Reporte'
                onCancel={handleCancel}
                footer={false}
                width={'50%'}
            >
                {reporteSeleccionado ? (
                    <Row justify='space-around'>
                        <Col span={24}>
                            <Table
                                size="small"
                                rowKey="idTrazabilidadFolio"
                                columns={columns}
                                dataSource={listaTrazabilidad}
                                loading={fetchingListaTrazabilidad}
                                locale={{ emptyText: 'Sin datos' }}
                                onChange={handleTableChange}
                                pagination={{ total: listaTrazabilidadCantidad, simple: true }}
                            />
                        </Col>
                    </Row>
                ) : false}
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalDetalleReporte: state.SolicitudDeServiciosReducer.showModalDetalleReporte,
        reporteSeleccionado: state.SolicitudDeServiciosReducer.reporteSeleccionado,
        listaTrazabilidad: state.SolicitudDeServiciosReducer.listaTrazabilidad,
        fetchingListaTrazabilidad: state.SolicitudDeServiciosReducer.fetchingListaTrazabilidad,
        listaTrazabilidadCantidad: state.SolicitudDeServiciosReducer.listaTrazabilidadCantidad,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalDetalleReporte: () => {
            dispatch({ type: 'SHOW_MODAL_DETALLE_REPORTE', showModalDetalleReporte: false });
        },
        onGetTrazabilidadFolio: (folio, page) => {
            dispatch({ type: "OBTENER_TRAZABILIDAD_FOLIO_REQUEST", folio, page});
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetalleReporte);