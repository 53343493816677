import React, { Component } from 'react';
import { Form, Input, Button, Row, Col, Spin, Modal, Select, InputNumber, message} from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';
import moment from 'moment';

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalEditarRangoRenovacion extends Component {
    formRef = React.createRef();

    state = {
        numDias: null,
        selectedValue: true,
    }

    render() {

        const { fetchingEditarRangosRenovacionActivo, showModalEditarRangoRenovacionActivo, onEditarRangoRenovacion, onShowModalEditarRangoRenovacion,
            RangoRenovacionActivoSeleccionado, page, limpiarFormulario, fetchingUnidadesMediPorCategoria, unidadesMediPorCategoria, listaInfoProyecto } = this.props;
        const {numDias, selectedValue} = this.state;

        const unidadesMedida = {"Días":1, "Semanas": 7, "Meses":31, "Años":365, "Horas":1/24, "Minutos":1/24/60, "Segundos":1/24/60/60}

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onEditarRangoRenovacion(formulario, RangoRenovacionActivoSeleccionado, page);
            this.setState({selectedValue:true})


        };
        if (limpiarFormulario && limpiarFormulario === 'editarRango') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        

        const handleCancel = () => {
            onShowModalEditarRangoRenovacion();
            this.setState({numDias: null, selectedValue: true})
        }

        const rangoChange = (value) => {
            if (numDias === null) {
                this.setState({numDias: unidadesMedida[RangoRenovacionActivoSeleccionado.unidadMedida]})
            }
            let fechaVigencia = listaInfoProyecto.fechaVigencia.split('-').reverse().join('-')
            const vigenciaProyectoDias = moment(fechaVigencia,'YYYY-MM-DD').diff(listaInfoProyecto.fechaInicio, "days");
            const diasMax = value * numDias
            console.log(vigenciaProyectoDias, diasMax)
            if(diasMax > vigenciaProyectoDias){
                message.error('No es posible seleccionar un período de tiempo que sobrepase la vigencia del proyecto');
                this.formRef.current.setFieldsValue({'maxNum':null});
            }
        }

        const rangoMinChange = (value) => {
            const rangoMax = this.formRef.current.getFieldValue('maxNum');
            if (rangoMax && rangoMax<value) {
                message.error('Número máximo debe ser mayor que número mínimo');
                this.formRef.current.setFieldsValue({'minNum':null})
            }
        }


        const selectChange = (value, data) => {
            if(value){
                let selected = data.children;
                let numDias = unidadesMedida[selected]
                this.setState({numDias: numDias,selectedValue: true})
                this.formRef.current.setFieldsValue({"maxNum":null, "minNum":null})
            } else {
                this.setState({numDias: null,selectedValue: false})
                this.formRef.current.setFieldsValue({"maxNum":null, "minNum":null})
            }
        }
        
        return (
            <Modal
                visible={showModalEditarRangoRenovacionActivo}
                title='Editar Rango para Renovación'
                onCancel={handleCancel}
                footer={false}>
                <Spin spinning={fetchingEditarRangosRenovacionActivo}>
                    {RangoRenovacionActivoSeleccionado ? (
                        <Form {...layout}
                            name="formulario"
                            initialValues={RangoRenovacionActivoSeleccionado}
                            onFinish={onFinish}
                            
                            ref={this.formRef}
                        >
                            <Form.Item
                                label="Descripción del rango"
                                name="descripcion"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                <Input
                                    pattern="^[ñíóáéú a-zA-Z0-9 ]+$"
                                    title="Descripción debe tener letras/números"
                                    maxLength={80}
                                    placeholder='Descripción del rango' />
                            </Form.Item>
                            <Form.Item
                                label="Unidad de medida"
                                name="unidadMedida"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona la unidad de medida"
                                    onChange={selectChange}
                                    loading={fetchingUnidadesMediPorCategoria}
                                >
                                    {unidadesMediPorCategoria.map(option => <Option key={option.idUnidadMedida}>{option.unidadMedida}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Máximo"
                                name="maxNum"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                <InputNumber
                                    onChange={rangoChange}
                                    pattern="^[0-9]+$"
                                    disabled={!selectedValue}
                                    title="Número debe de ser entero."
                                    maxLength={11} min={1} placeholder='Máximo' />
                            </Form.Item>
                            <Form.Item
                                label="Mínimo"
                                name="minNum"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                <InputNumber
                                    onChange={rangoMinChange}
                                    pattern="^[0-9]+$"
                                    disabled={!selectedValue}
                                    title="Número debe de ser entero."
                                    maxLength={11} min={0} placeholder='Mínimo' />
                            </Form.Item>
                            {/* <Form.Item
                                label="CSS"
                                name="css"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                <Input
                                    maxLength={150}
                                    placeholder='CSS'
                                    type='color' />
                            </Form.Item> */}
                            <Row justify='space-around'>
                                <Col span={12}>
                                    <Button style={{width:'100%'}} className="btnFiltrar" htmlType="submit">Editar Rango</Button>
                                </Col>
                            </Row>
                        </Form>
                    ) : false}
                </Spin>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingEditarRangosRenovacionActivo: state.AlmacenesReducer.fetchingEditarRangosRenovacionActivo,
        showModalEditarRangoRenovacionActivo: state.AlmacenesReducer.showModalEditarRangoRenovacionActivo,
        RangoRenovacionActivoSeleccionado: state.AlmacenesReducer.RangoRenovacionActivoSeleccionado,
        page: state.AlmacenesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingUnidadesMediPorCategoria: state.AlmacenesReducer.fetchingUnidadesMediPorCategoria,
        unidadesMediPorCategoria: state.AlmacenesReducer.unidadesMediPorCategoria,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarRangoRenovacion: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_RANGO_RENOVACION_ACTIVO', showModalEditarRangoRenovacionActivo: false });
        },
        onEditarRangoRenovacion: (formulario, RangoRenovacionActivoSeleccionado, page) => {
            dispatch({ type: 'EDITAR_RANGO_RENOVACION_ACTIVO_REQUEST', formulario: formulario, RangoRenovacionActivoSeleccionado:RangoRenovacionActivoSeleccionado, page: page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarRangoRenovacion);