import React, { Component } from 'react';
import { Row, Form, Input, Button, Modal, Col, Spin, Select } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Option } = Select;
class ModalNuevoTipoFalla extends Component {
    formRef = React.createRef();
    render() {
        const { fetchingNuevoTipoFalla, showModalNuevoTipoFalla, onNuevoTipoFalla, onShowModalNuevoTipoFalla,
            limpiarFormulario, onLimpiarFormulario, 
        } = this.props;

        const onFinish = formulario => {
            onNuevoTipoFalla(formulario, this.props.page, this.props.busqueda);
        };

        if (limpiarFormulario && limpiarFormulario === 'crearTipoFalla') {
            this.formRef.current.resetFields();
            onLimpiarFormulario();
        }

        const handleCancel = () => {
            onShowModalNuevoTipoFalla(this.props.page, this.props.busqueda);
            this.formRef.current.resetFields();
        }

        return (
            <Modal
                visible={showModalNuevoTipoFalla}
                title='Nuevo Tipo de Falla'
                onCancel={handleCancel}
                footer={false}
                width='50%'
            >
                <Spin spinning={fetchingNuevoTipoFalla}>
                    <Form {...layout}
                        name="formulario"
                        onFinish={onFinish}
                        ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Clave"
                                    name="clave"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={5} placeholder="Ingresa la clave"
                                        title="Clave debe contener sólo letras mayúsculas, números y espacios" pattern="^[A-ZÑ0-9 ÁÉÓÚÍ]+$" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Nombre del tipo de falla"
                                    name="nombre"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={100} placeholder="Ingresa el nombre de tipo de falla"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Descripción"
                                    name="descripcion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={250} placeholder="Ingresa la descripción"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Categoría de falla"
                                    name="tipo"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Select
                                        style={{ width: '100%', textAlign: 'left' }} showSearch
                                        placeholder="Selecciona la categoría de falla"
                                    >
                                        <Option key='General'>General</Option>
                                        <Option key='Específica'>Específica</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                           
                            <Col span={11} className='marginBoton'>
                                <br />
                                <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Crear Tipo de Falla</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingNuevoTipoFalla: state.SolicitudDeServiciosReducer.fetchingNuevoTipoFalla,
        showModalNuevoTipoFalla: state.SolicitudDeServiciosReducer.showModalNuevoTipoFalla,
        page: state.SolicitudDeServiciosReducer.page,
        busqueda: state.SolicitudDeServiciosReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevoTipoFalla: (page, busqueda) => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_TIPO_FALLA', showModalNuevoTipoFalla: false, page, busqueda })
        },
        onNuevoTipoFalla: (formulario, page, busqueda) => {
            dispatch({ type: "NUEVO_TIPO_FALLA_REQUEST", formulario, page, busqueda });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoTipoFalla);