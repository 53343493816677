import React from 'react';
import { Row, Col, Input, Button, Table, Switch, Modal, Tooltip } from 'antd';
import { connect } from "react-redux";
import { EditOutlined, FileExcelOutlined, PlusOutlined } from '@ant-design/icons';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoAlmacen from '../CatalogoDeAlmacenes/ModalNuevoAlmacen';
import ModalEditarAlmacen from '../CatalogoDeAlmacenes/ModalEditarAlmacen';

const confirm = Modal.confirm;
const { Search } = Input;

class CatalogoDeAlmacenesContainer extends React.Component {
     formRef = React.createRef();
     state = {
          page: 1,
          busqueda: {order: 'ASC', field: 'idAlmacen'},
          order: 'ASC',
          field: 'idAlmacen',
          param: '',
     }

     componentDidMount() {
          this.props.onGetAlmacenes(1, this.state.busqueda);
          this.props.onObtenerServicios();
          this.props.onObtenerAreas();
     }

     render() {
          const { 
               listaAlmacenesBusqueda,
               fetchingListaAlmacenesBusqueda,
               listaAlmacenesBusquedaCantidad,

               onEstadoAlmacenes,
               onEditarAlmacen,
               onModalNuevoAlmacen,
               onGetAlmacenes,
               onListaServiciosFiltroLimpiar
          } = this.props;
               
     let permisosUsuario = sessionStorage.getItem('permisosUsuario');
     if (permisosUsuario[0]) {
          permisosUsuario = JSON.parse(permisosUsuario);
     }

          const columns = [
               {title: 'Nombre', dataIndex: 'nombreC', key: 'nombreC', sorter: true, width: '15%'},
               {title: 'Descripción', dataIndex: 'nombreL', key: 'nombreL', sorter: true, width: '15%'}, 
               //booleano, indica si el inventario es o no disponible, caso de requisición directa
               {title: 'Disponible', dataIndex: 'caracteristica', key: 'caracteristica', sorter: true, width: '5%', align: 'center',
               render: (text) => (
                    <Switch checked={text} disabled={true} />
               ),},
               {title: 'Servicio', dataIndex: 'nombreServicio', key: 'nombreServicio', width: '40%', sorter: true},
               {title: 'Área Funcional', dataIndex: 'areaFuncional', key: 'areaFuncional', width: '15%', sorter: true}, 
               {title: 'Activo', dataIndex: 'activo', key: 'activo', width: '5%', align: 'center',
               render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.almacénActivoInactivo} onChange = {() => handleDesactivar(record)} />
               ),}, 
               {title: 'Editar', key: 'editar', width: '5%', align: 'center',
               render: (record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarAlmacén}  onClick={() => onEditar(record)}><EditOutlined /></a>
               ),},
          ];

          const onBuscarAlmacen = (param) => {
               const busqueda = { param: param, order: this.state.order, field: this.state.field }
               this.setState({ busqueda: busqueda, page: 1 });
               console.log('busqueda',busqueda);
               onGetAlmacenes(1, busqueda);
          } 

          const handleDesactivar = (key) => {
               console.log(key.idAlmacen);
               let page = this.state.page;
               let busqueda = this.state.busqueda;
               confirm({
                    title: '¿Estás seguro que deseas cambiar el estado del almacen "' + key.nombreC + '"?',
                    content: '',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                         onEstadoAlmacenes(key.idAlmacen, page, busqueda);
                    },
                    onCancel() {
                         console.log('Cancel');
                    },
               });
          }

          const onEditar = (key) => {
               onEditarAlmacen(key, this.state.page, this.state.busqueda);
               onListaServiciosFiltroLimpiar();
          }

          const onNuevoAlmacen = () => {
               onModalNuevoAlmacen(this.state.page);
               onListaServiciosFiltroLimpiar();
          }

          const handleTableChange = (pagination, filtro, orden) => {
               this.setState({ page: pagination.current,});
               if(orden){
                    let campo = 'idAlmacen';
                    let order = 'ASC';
               
                    if(orden.order === 'descend'){
                    order = 'DESC';
                    }

                    if(orden.columnKey === 'nombreC'){
                    campo = 'nombreC';
                    } else if(orden.columnKey === 'nombreL'){
                    campo = 'nombreL';
                    } else if(orden.columnKey === 'nombre'){
                         campo = 'ser.nombre';
                    } else if(orden.columnKey === 'areaFuncional'){
                         campo = 'afe.areaFuncional';
                    } else if(orden.columnKey === 'caracteristica'){
                         campo = 'caracteristica';
                    }
                    
                    const busqueda = { param: this.state.param, order: order, field: campo }
                    onGetAlmacenes(pagination.current, busqueda);
                    this.setState({
                    order: order,
                    field: campo,
                    busqueda: busqueda,
                    });                   
               }
               else{
                    onGetAlmacenes(pagination.current, this.state.busqueda);
               }
          }

          const scroll = {"x": 1500};

          return (
               <div>
                    <Row justify='space-between' gutter={[8,8]}>
                         <Col span={20}>
                              <Search className='buscarModulos' placeholder='Ingresar valor a buscar nombre, descripción, servicio o área' onSearch={value => onBuscarAlmacen(value)} />
                         </Col>
                         {/* <Col span={6}>
                              <Button onClick={onNuevoAlmacen} disabled={!permisosUsuario.crearAlmacén} className='nuevoUsuario'>Nuevo Almacen</Button>
                         </Col> */}

                         <Col span={1}>
                              <Tooltip title='Nuevo Almacén' placement="topRight">
                                   <Button className="iconTer" shape="circle" onClick={onNuevoAlmacen} disabled={!permisosUsuario.crearAlmacén} icon={<PlusOutlined />}/>
                              </Tooltip>
                         </Col>

                         <Col span={1}>
                              <Tooltip title='Exportar archivo .xls' placement="topRight">
                                   <Button disabled={!permisosUsuario.exportarExcelCatálogoAlmacenes} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } loading={this.props.fetchingExportarExcelAlmacenes} onClick={() => this.props.onExport(this.state.order, this.state.field)}/>
                              </Tooltip>
                         </Col>
                         <Col span={24}>
                              <br />
                              <Table
                                   size="small"
                                   rowKey="idAlmacen"
                                   columns={columns}
                                   dataSource={listaAlmacenesBusqueda}
                                   loading={fetchingListaAlmacenesBusqueda}
                                   locale={{ emptyText: 'Sin datos' }}
                                   onChange={handleTableChange}
                                   scroll={scroll}
                                   pagination={{ total: listaAlmacenesBusquedaCantidad, simple: true, current: this.state.page }}
                              />
                         </Col>
                    </Row>
                    <ModalNuevoAlmacen/>
                    <ModalEditarAlmacen/>
               </div>
          )
     }
}

const mapStateToProps = state => {
     return {
          page: state.AlmacenesReducer.page,
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          listaAlmacenesBusqueda: state.AlmacenesReducer.listaAlmacenesBusqueda,
          fetchingListaAlmacenesBusqueda: state.AlmacenesReducer.fetchingListaAlmacenesBusqueda,
          listaAlmacenesBusquedaCantidad: state.AlmacenesReducer.listaAlmacenesBusquedaCantidad,
          fetchingExportarExcelAlmacenes: state.AlmacenesReducer.fetchingExportarExcelAlmacenes,
     };
};
const mapDispatchToProps = dispatch => {
	return {
          onGetAlmacenes: (page, busqueda) => {
               dispatch({ type: "ALMACEN_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
          },
          onEstadoAlmacenes: (idAlmacen, page, busqueda) => {
               dispatch({ type: "ESTADO_ALMACEN_REQUEST", idAlmacen: idAlmacen, page: page, busqueda: busqueda });
          },
          onModalNuevoAlmacen: (page) => {
               dispatch({ type: "SHOW_MODAL_NUEVO_ALMACEN", showModalNuevoAlmacen: true, page: page })
          },
          onEditarAlmacen: (almacenSeleccionado, page, busqueda) => {
               dispatch({ type: "SHOW_MODAL_EDITAR_ALMACEN", showModalEditarAlmacen: true, almacenSeleccionado: almacenSeleccionado, page: page, busqueda: busqueda});
          },
          onObtenerServicios: () => {
               dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
          },
          onObtenerAreas: () => {
               dispatch({ type: 'LISTA_AREASFUNCIONALES_ACTIVOS_REQUEST' });
          },
          onListaServiciosFiltroLimpiar: () => {
               dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
          },
          onExport: (order, field) => {
               dispatch({ type: "EXPORTAR_ALMACENES_EXCEL_REQUEST", order, field });
          },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoDeAlmacenesContainer);