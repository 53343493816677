import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import { Form, Button, Row, Col, Spin, message, Modal, InputNumber, Divider, Select} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const confirm = Modal.confirm;
const { Option } = Select;

const VerificarEdicion = props => {
    let formRef = React.createRef();

    const {formularioNivelTiempoRespuesta, fetchingListaNivelesTiempoRespuestaEdicion, listaNivelesTiempoRespuestaEdicion,listaValoresInicialesNTR,
        nivelTiempoRespuestaSeleccionado,unidadesMediPorCategoria, onCambiarVista} = props;
    const [state,setState] = useState({unidadMedidaObj:{}})
    
    useEffect(() => {
        let unidadesMedidaObjeto ={}
        for (let unidad of unidadesMediPorCategoria) {
            unidadesMedidaObjeto[`${unidad.idUnidadMedida}`] = unidad.unidadMedida;
            unidadesMedidaObjeto[`${unidad.unidadMedida}`] = unidad.idUnidadMedida;
        }
        setState({unidadMedidaObj:unidadesMedidaObjeto})
    }, [unidadesMediPorCategoria])

    const onFinish = formulario => {
        console.log(formulario);
        let listaTiemposRespuesta = [];

        let tiempoRFormulario = { 'idTiempoRespuestaSeccion': null, 'tiempoRespuesta': null, 'idUnidadMedida': null};
        let contador = 0;
        for (var key in formulario) {
            if (Object.prototype.hasOwnProperty.call(formulario, key)) {
                let campoSplit = key.split('-');
                let idTiempoRespuestaSeccion = campoSplit[1];
                let campo = campoSplit[0];
                let valor = formulario[key];
                tiempoRFormulario[campo] = isNaN(parseInt(valor)) ? parseInt(campoSplit[2]) : parseInt(valor);
                contador++;
                if( contador === 2 ){
                    tiempoRFormulario['idTiempoRespuestaSeccion'] = parseInt(idTiempoRespuestaSeccion);
                    listaTiemposRespuesta.push(tiempoRFormulario);
                    tiempoRFormulario = { 'idTiempoRespuestaSeccion': null, 'tiempoRespuesta': null, 'idUnidadMedida': null};
                    contador = 0;
                }
            }
        }
        console.log(listaTiemposRespuesta);
        
        const findNivelTR = (nivelTiempoRItem) => {
            const elemento = listaNivelesTiempoRespuestaEdicion.find(item => item.idTiempoRespuestaSeccion == nivelTiempoRItem.idTiempoRespuestaSeccion);
            const tiempoRespuestaV = nivelTiempoRItem.tiempoRespuesta !== elemento.tiempoRespuesta;
            const unidadMedidaV = nivelTiempoRItem.idUnidadMedida !== elemento.idUnidadMedida;
            return (tiempoRespuestaV || unidadMedidaV) ? elemento : false;
        }

        const listaFiltradaEditados = listaTiemposRespuesta.filter(nivelTiempoRespuesta => {
            const filtro = findNivelTR(nivelTiempoRespuesta);
            const { idServSeccionEspecifico, categoria, fechaInicioVigencia, fechaFinVigencia, rutaArchivo,fechaAlta, fechaActualizacion, activo } = filtro
            nivelTiempoRespuesta.idServSeccionEspecifico = idServSeccionEspecifico;
            nivelTiempoRespuesta.categoria = categoria;
            nivelTiempoRespuesta.fechaInicioVigencia = fechaInicioVigencia;
            nivelTiempoRespuesta.fechaFinVigencia = fechaFinVigencia;
            nivelTiempoRespuesta.rutaArchivo = rutaArchivo;
            nivelTiempoRespuesta.fechaAlta = fechaAlta;
            nivelTiempoRespuesta.fechaActualizacion = fechaActualizacion;
            nivelTiempoRespuesta.activo = activo;
            return filtro;
        });

        const tiempoRespuestaEditado = listaTiemposRespuesta.find(item => item.idTiempoRespuestaSeccion == nivelTiempoRespuestaSeleccionado.idTiempoRespuestaSeccion);
        console.log(listaFiltradaEditados)

        const unidadMedidaFormulario = isNaN(parseInt(formularioNivelTiempoRespuesta.unidadMedida)) ? state.unidadMedidaObj[formularioNivelTiempoRespuesta.unidadMedida] : parseInt(formularioNivelTiempoRespuesta.unidadMedida);
        const condicionTR = tiempoRespuestaEditado.tiempoRespuesta === formularioNivelTiempoRespuesta.tiempoRespuesta;
        const condicionUM = tiempoRespuestaEditado.idUnidadMedida === unidadMedidaFormulario;

        if (condicionTR && condicionUM) {
            confirm({
                title: 'Estos son los niveles de tiempos respuesta que fueron modificados. ¿Estás seguro de modificarlos?',
                content:
                    <>
                        {listaFiltradaEditados.map(tiempoRespuesta => (
                            <Row justify='space-around' key={tiempoRespuesta.idTiempoRespuestaSeccion}>
                                <Divider>{`Nivel Tiempo Respuesta: ${tiempoRespuesta.idTiempoRespuestaSeccion}`}</Divider>
                                <Col span={11}>
                                    <p><strong>Tiempo respuesta: </strong>{tiempoRespuesta.tiempoRespuesta}</p>
                                </Col>
                                <Col span={11}>
                                    <p><strong>Unidad de medida: </strong>{state.unidadMedidaObj[tiempoRespuesta.idUnidadMedida]}</p>
                                </Col>
                            </Row>
                        ))}
                    </>,
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                width: '40%',
                onOk() {
                    console.log("CAMBIAR VISTA");
                    onCambiarVista(4, formularioNivelTiempoRespuesta, listaFiltradaEditados);
                },
                onCancel() {
                    onCambiarVista(1, null);
                },
            });
        } else {
            message.warning(`El tiempo respuesta de sección ${tiempoRespuestaEditado.idTiempoRespuestaSeccion}, debe ser el mismo que ingresó al editar ('${formularioNivelTiempoRespuesta.tiempoRespuesta}, ${state.unidadMedidaObj[unidadMedidaFormulario]}').`);
            formRef.current.resetFields([`tiempoRespuesta-${tiempoRespuestaEditado.idTiempoRespuestaSeccion}`]);
            formRef.current.resetFields([`idUnidadMedida-${tiempoRespuestaEditado.idTiempoRespuestaSeccion}-${tiempoRespuestaEditado.idUnidadMedida}`]);

        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
        <Form {...layout}
            name="formulario"
            onFinish={onFinish}
            
            ref={formRef}
            initialValues={listaValoresInicialesNTR}
            >
                <>
                    <h2>Favor de ingresar nuevamente los tiempos de respuesta y unidades de medida.</h2>
                    {listaNivelesTiempoRespuestaEdicion.map(nivel =>
                     <Row justify='space-around' key={nivel.idTiempoRespuestaSeccion}>
                        <Divider>{`Nivel Tiempo Respuesta: ${nivel.idTiempoRespuestaSeccion}`}</Divider>
                        <Col span={11}>
                            <Form.Item
                                label="Tiempo Respuesta"
                                name={`tiempoRespuesta-${nivel.idTiempoRespuestaSeccion}`}
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                <InputNumber 
                                    min={1} max={1000} placeholder='Tiempo respuesta'
                                    title="Número debe ser entero" pattern="^[0-9]+$"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="Unidad de medida"
                                name={`idUnidadMedida-${nivel.idTiempoRespuestaSeccion}-${nivel.idUnidadMedida}`}
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select
                                    allowClear
                                    style={{ width: '100%' }} placeholder="Selecciona unidad de medida"
                                >
                                    <Option key={4}>{'Minutos'}</Option>
                                    <Option key={1}>{'Horas'}</Option>
                                    <Option key={2}>{'Días'}</Option>
                                    <Option key={3}>{'Años'}</Option> 
                                </Select>
                            </Form.Item>
                        </Col>
                     </Row>   
                    )}
                    <Col span={24} className='marginBoton'>
                        <br/>
                        <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Guardar Cambios</Button>
                    </Col>
                </>
            </Form>
        </>
    )
}

const mapStateToProps = state => {
    return {
        formularioNivelTiempoRespuesta: state.MecanismosDePagosReducer.formularioNivelTiempoRespuesta,
        fetchingListaNivelesTiempoRespuestaEdicion: state.MecanismosDePagosReducer.fetchingListaNivelesTiempoRespuestaEdicion,
        listaNivelesTiempoRespuestaEdicion: state.MecanismosDePagosReducer.listaNivelesTiempoRespuestaEdicion,
        nivelTiempoRespuestaSeleccionado: state.MecanismosDePagosReducer.nivelTiempoRespuestaSeleccionado,
        listaValoresInicialesNTR: state.MecanismosDePagosReducer.listaValoresInicialesNTR,
        unidadesMediPorCategoria: state.AlmacenesReducer.unidadesMediPorCategoria,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCambiarVista: (vistaModalNivelTiempoRespuesta, formularioNivelTiempoRespuesta, listaFiltradaEditadosNTR) => {
            dispatch({ type: 'CAMBIAR_VISTA_NIVEL_TIEMPO_RESPUESTA', vistaModalNivelTiempoRespuesta, formularioNivelTiempoRespuesta, listaFiltradaEditadosNTR})
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (VerificarEdicion);