import axios from "axios";

//const endpoint = "https://papantla-services.herokuapp.com/";
//const endpoint = "https://papantla-dev.herokuapp.com/";
const endpoint = 'https://morelos-dev.herokuapp.com/';
//const endpoint = "https://papantla-demo.herokuapp.com/";

//const endpoint = "http://172.24.0.170:8080/"

//const endpoint = 'https://papantla-services-jose.herokuapp.com/';
//const endpoint = 'https://papantla-dev-jose.herokuapp.com/';

//const endpoint = 'https://papantla-secret-jose.herokuapp.com/';
//const endpoint = 'https://papantla-secret.herokuapp.com/';


//SERVICIOS PLAN DE CALIDAD
export function listaPlanesCalidadBusquedaCall(token, page, año) {
	return axios({
		method: "get",
		url: endpoint + "gestion/calidad/plan?" + año + 'page=' + page + '&offset=10',
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaPlanCalidadAñosCall(token) {
	return axios({
		method: "get",
		url: endpoint + "gestion/calidad/plan/anios",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoPlanCalidadCall(token, idGestionCalidad) {
	return axios({
		method: "get",
		url: endpoint + 'gestion/calidad/plan/activa/' + idGestionCalidad,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function nuevoPlanCalidadCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "gestion/calidad/plan",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarPlanCalidadCall(token, data, idGestionCalidad) {
	return axios({
		method: "put",
		url: endpoint + "gestion/calidad/plan/" + idGestionCalidad,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//SERVICIOS GESTIÓN DE PLANES CORRECTIVOS
export function listaPlanesCorrectivosBusquedaCall(token, page, idServicio, origen, field, order, estado, fechaInicio, fechaFin) {
	return axios({
		method: "get",
		url: endpoint + "gestion/calidad/plan/correctivo?idServicio=" + idServicio + '&origen=' + origen + '&page=' + page + '&offset=10&field=' + field + '&order=' + order + '&estado=' + estado + fechaInicio + fechaFin,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function nuevoPlanCorrectivoCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "gestion/calidad/plan/correctivo",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarPlanCorrectivoCall(token, data, idPlanCorrectivo) {
	return axios({
		method: "put",
		url: endpoint + "gestion/calidad/plan/correctivo/" + idPlanCorrectivo,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaMesesActivosCall(token) {
	return axios({
		method: "get",
		url: endpoint + 'gestion/calidad/plan/correctivo/origen/mes',
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaFoliosEstandaresCall(token, param, idServicio) {
	return axios({
		method: "get",
		url: endpoint + 'gestion/calidad/plan/correctivo/origen/foliosEstandares?param=' + param + '&idServicio='+idServicio,
		headers: { 'Content-Type': 'application/json', 'Authorization': token }
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaFoliosSolicitudesCall(token, param, idServicio) {
	return axios({
		method: "get",
		url: endpoint + 'gestion/calidad/plan/correctivo/origen/foliosSolicitudes?param=' + param + '&idServicio='+idServicio,
		headers: { 'Content-Type': 'application/json', 'Authorization': token }
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaEstandaresDesempeñoAuditoriasCall(token, param, idFolioEstandar) {
	return axios({
		method: "get",
		url: endpoint + 'gestion/calidad/plan/correctivo/origen/estandarNoCumplen?param=' + param + '&idFolioEstandar=' + idFolioEstandar,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaEstandaresDesempeñoCall(token, param, idServicio) {
	return axios({
		method: "get",
		url: endpoint + 'gestion/calidad/plan/correctivo/origen/estandar?param=' + param + '&idServicio=' + idServicio,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
//PONDERADORES PARA ENCUESTAS DE SATISFACCIÓN
export function listaPonderadoresEncuestaCAll(token, page) {
	return axios({
		method: "get",
		url: endpoint + "encuestas/ponderador?&page=" + page + "&offset=10",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function duplicarPonderadoresEncuestaCAll(token, año) {
	return axios({
		method: "get",
		url: endpoint + "encuestas/ponderador/anio/anterior?anio=" + año,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function añosPonderadoresEncuestaCall(token) {
	return axios({
		method: "get",
		url: endpoint + 'encuestas/ponderador/anios',
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoPonderadorEncuestaCall(token, idPonderacionEncuesta) {
	return axios({
		method: "get",
		url: endpoint + 'encuestas/ponderador/activa/' + idPonderacionEncuesta,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function nuevoPonderadorEncuestaCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "encuestas/ponderador",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarPonderadorEncuestaCall(token, data, idPonderacionEncuesta) {
	return axios({
		method: "put",
		url: endpoint + "encuestas/ponderador/" + idPonderacionEncuesta,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Emisión de encuestas de satisfacción
export function listaAñosPreguntaEncuestaCAll(token) {
	return axios({
		method: "get",
		url: endpoint + "encuestas/preguntas/anios",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaPreguntasEncuestaCAll(token, idServicio, año, page) {
	return axios({
		method: "get",
		url: endpoint + "encuestas/preguntas?" + idServicio + año + "page=" + page + "&offset=10",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoEncuestaCall(token, idPreguntaEncuesta) {
	return axios({
		method: "get",
		url: endpoint + 'encuestas/preguntas/activa/' + idPreguntaEncuesta,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function nuevaPreguntaEncuestaCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "encuestas/preguntas",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarPreguntaEncuestaCall(token, data, idPreguntaEncuesta) {
	return axios({
		method: "put",
		url: endpoint + "encuestas/preguntas/" + idPreguntaEncuesta,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function nuevoFolioEncuestaCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "encuestas/folios",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarFolioEncuestaCall(token, folio) {
	return axios({
		method: "post",
		responseType: 'blob',
	    url: endpoint + "encuestas/folios/export/pdf",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: folio,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Registro de Encuestas de Satisfacción
export function mesesByAñoServicioEncuestaCall(token, año, idServicio) {
	return axios({
		method: "get",
		url: endpoint + 'encuestas/registro/anios/meses?' + año + idServicio,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaFoliosBusquedaCall(token, idServicio, idMes, año, estado, page) {
	return axios({
		method: "get",
		url: endpoint + 'encuestas/registro?' + idServicio + idMes + año + estado + '&page=' + page + '&offset=10' ,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function registrarEncuestaCall(token, data) {
	return axios({
		method: "post",
	    url: endpoint + "encuestas/registro",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function evaluarEncuestaCall(token, idPregunta, folio, idServicio, idMes) {
	return axios({
		method: "get",
		url: endpoint + 'encuestas/registro/evaluar?' + idPregunta + folio + idServicio + idMes,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Reportes de encuestas de satisfacción
export function getInformacionReporteCall(token, idServicio, idMes, anio, tipo, page) {
	return axios({
		method: "get",
		url: endpoint + 'encuestas/reporte?idServicio=' +  idServicio + '&idMes=' + idMes + '&anio=' + anio + '&tipo=' + tipo + '&page=' + page + '&offset=10', 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarReporteEncuestasCall(token, data) {
	return axios({
		method: "post",
		responseType: 'blob',
	    url: endpoint + "encuestas/reporte/export/pdf",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function generalReportExportCall(token, idServicio, idMes, anio, tipo) {
	return axios({
		method: "get",
		url: endpoint + "encuestas/reporte/general/export/pdf?idServicio=" + idServicio + '&idMes=' + idMes + '&anio=' + anio + '&tipo=' + tipo,
		headers: {'Content-Type': 'application/json','Authorization':token},
		responseType: 'blob',
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
} 

export function comparativoServicioPDFCall(token, data) {
	return axios({
		method: "post",
		responseType: 'blob',
	    url: endpoint + "encuestas/reporte/comparativa/export/pdf",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Reporte Dinámico Encuestas de Satisfacción
export function reporteDinamicoESCall(token, data) {
	return axios({
		method: "post",
	    url: endpoint + "encuestas/reporte/dinamico",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function reporteDinamicoAgrupadoESCall(token, data) {
	return axios({
		method: "post",
	    url: endpoint + "encuestas/reporte/dinamico/agrupado",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function reporteDinamicoPDFESCall(token, data) {
	return axios({
		method: "post",
		responseType: 'blob',
	    url: endpoint + "encuestas/reporte/dinamico/pdf",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function reporteDinamicoExcelESCall(token, data) {
	return axios({
		method: "post",
		responseType: 'blob',
	    url: endpoint + "encuestas/reporte/dinamico/excel",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Cumplimiento de Estándares
export function listaCumplimientoEstandaresCall(token, idServicio, referencia, order, field, page) {
	return axios({
		method: "get",
		url: endpoint + 'estandaresDesempeno/cumplimiento?' + idServicio + referencia + order + field + '&page=' + page + '&offset=10', 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearFolioEstandarCall(token, data) {
	return axios({
		method: "post",
	    url: endpoint + "cumplimiento",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarFolioEstandarCall(token, folio) {
	return axios({
		method: "get",
		url: endpoint + "cumplimiento/export/pdf?folio=" + folio,
		headers: {'Content-Type': 'application/json','Authorization':token},
		responseType: 'blob',
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
} 

export function listaFoliosEstandaresEvaluarCall(token, page) {
	return axios({
		method: "get",
		url: endpoint + 'cumplimiento?page=' + page + '&offset=10', 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function evaluarFolioEstandarCall(token, data) {
	return axios({
		method: "post",
	    url: endpoint + "cumplimiento/evaluar",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaFoliosEstandaresEvaluadosCall(token, page) {
	return axios({
		method: "get",
		url: endpoint + 'cumplimiento/evaluados?&page=' + page + '&offset=10', 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Reportes de desempeño de estandares generales
export function listaFoliosEstandaresDesempenoCall(token, page, idServicio, order, field) {
	return axios({
		method: "get",
		url: endpoint + 'cumplimiento/repositorio/folios?&page=' + page + '&offset=10&idServicio=' + idServicio + '&order=' + order + '&field=' + field,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaEstandaresPorFolioCall(token, page, idFolioEstandar, param) {
	return axios({
		method: "get",
		url: endpoint + 'cumplimiento/repositorio/folios/' + idFolioEstandar + '?&page=' + page + '&offset=10&param=' + param,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function subirArchivoPorEstandarCall(token, data, idCumplimientoEstandar) {
	return axios({
		method: "post",
		url: endpoint + "cumplimiento/repositorio/" + idCumplimientoEstandar,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
