import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import { Alert, Button, Row, Col, Steps, Checkbox, Typography } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import FilterSection from './FilterSection';
import TableSection from './TableSection';
import RegistrosContainer from './RegistrosPersonalizados/RegistrosContainer';

const { Step } = Steps; const {Text} = Typography;

const ReporteDinamico = props => {

     const {listaReporteDinamicoBitacora, formularioReporte,onGetReporteDinamico,onLimpiarFormulario, onGetReporteDinamicoAgrupado } = props;

     const [state, setState] = useState({page: 1, currentStep:0});
     const [order, setOrder] = useState({field:'tbe.tipoBitacora',order:'ASC'});
     
     const [checkAll, setCheckAll] = useState (false)
     const [checkArray, setCheckArray] = useState([]);
     const [checkbox, setCheckbox] = useState ({
          nombreServicio: false, folioSolicitudServicio: false, descripcionFalla: false,
          areaFuncional: false, unidadFuncional: false, interiorFuncional: false, 
          usuarioSolicitoName: false, usuarioRegistro: false, fechaAlta: false,
          fechaCierre: false, fechaTerminacion: false, estado: false, actividadesRealizadas: false
     });
    
     let permisosUsuario = sessionStorage.getItem('permisosUsuario');
     if(permisosUsuario[0]) {
          permisosUsuario = JSON.parse(permisosUsuario);
     }

     useEffect(()=> {
          const arrayCheckbox = checkboxArray();
          setCheckArray(arrayCheckbox);
     },[checkbox]);

    
     const checkboxArray = () => {
          let fieldArray = []
          for (var key in checkbox) {
               if(Object.prototype.hasOwnProperty.call(checkbox, key)) {
                    if(checkbox[key] === true){
                    fieldArray.push(key);
                    }
               }
          }
          return fieldArray;
     }

     const handleSteps = value => {
          console.log('Entra al handle')

          if (value === 'siguiente') return  setState({...state, currentStep: state.currentStep + 1});

          setOrder({field:'tbe.tipoBitacora',order:'ASC'});
          setState({page:1, currentStep: state.currentStep -1});
          onLimpiarFormulario(formularioReporte);
     }


     const selectHandle = (event,field) => {
          const checked = event.target.checked
          setCheckAll(false);

          if (checked && field === 'numeroEmpleado') return setCheckbox({...checkbox, tipoBitacoraC: true, descripcionC: true, nombreC: true, [field]:checked});
          
          if(checked) return setCheckbox({...checkbox,[field]:event.target.checked })
          setCheckbox({...checkbox,[field]:event.target.checked});
     }

     const selectAll = event => {
          setCheckAll(event.target.checked);
          if (event.target.checked) {
               return setCheckbox({
                    tipoBitacoraC :true, descripcionC :true, nombreC:true, moduloC:true, servicioC:true, partidaC:true, asuntoC:true, observacionesC:true, fechaC:true, horaC:true, accionC:true, responsableSerC:true, responsableRegC:true, conclusionesC:true
               });
          }
          setCheckbox({
               tipoBitacoraC :false, descripcionC :false, nombreC:false, moduloC:false, servicioC:false, partidaC:false, asuntoC:false, observacionesC:false, fechaC:false, horaC:false, accionC:false, responsableSerC:false, responsableRegC:false, conclusionesC:false
          })
     }

     const handleTableChange = (pagination, filter, orden) => {
          setState({...state, page: pagination.current});
          console.log('orden en RD', orden)
          
          const orderFields = { tipoBitacoraC:'tbe.tipoBitacora', descripcionC:'tbe.descripcion', nombreC:'bme.nombre', moduloC: 'me.nombre', servicioC: 'se.nombre', partidaC: 'pe.nombre', asuntoC: 'be.asunto', observacionesC: 'be.observaciones', fechaC: 'be.fecha', horaC: 'be.hora', accionC: 'be.accion', responsableSerC: 'be.responsableSer', responsableRegC: 'be.responsableReg', conclusionesC: 'be.conclusiones' }

          if(orden) {
               const field = orderFields[orden.columnKey];
               const orderField = orden.order === 'descend' ? 'DESC': 'ASC';
               const sorter = {field: field, order: orderField}
               setOrder({field: field, order: orderField});
               return onGetReporteDinamico(formularioReporte, pagination.current, sorter, checkbox );
          }

          onGetReporteDinamico(formularioReporte, pagination.current, order, checkbox );
     }

     const handleOrden = (field, order) => {
          let agrupado = null;
          console.log(`field, orden`, field, order)
          const sorter = {field, order}
          setOrder({field, order});
          onGetReporteDinamicoAgrupado(formularioReporte, state.page, agrupado, checkbox, sorter)
      }

     const checkFields = [
          {field: 'tipoBitacoraC', title: 'Tipo de bitácora'}, 
          {field: 'descripcionC', title:'Descripción tipo de bitácora'},
          {field: 'nombreC', title: 'Nombre'}, 
          {field: 'moduloC', title: 'Módulo'},
          {field: 'servicioC', title:'Servicio'}, 
          {field: 'partidaC', title: 'Partida'},
          {field: 'asuntoC', title:'Asunto'}, 
          {field: 'observacionesC', title:'Observaciones'},
          {field: 'fechaC', title: 'Fecha'}, 
          {field: 'horaC', title: 'Hora'}, 
          {field: 'accionC', title:'Acción'},
          {field: 'responsableSerC', title: 'Responsable de servicio'},
          {field: 'responsableRegC', title: 'Responsable de registro'}, 
          {field: 'conclusionesC', title: 'Conclusión'}, 
     ];

     const SelectSection = () => {
          return (
               <>
                    <Row style={{padding:'1em'}}>
                         <Col span={24}>
                         <Alert message="Selecciona las columnas a incluir en el reporte." type="info" style={{ marginBottom: '16px' }} />
                         </Col>
                    </Row>
                    <Row style={{ width: '100%', textAlign: 'left', marginBottom: '10px', padding:'1em'}}>
                         <Col span={11}>
                              <Checkbox defaultChecked={checkAll}  onChange={selectAll} key='todos'><strong>Todas</strong></Checkbox>
                         </Col>
                    </Row>
                    <Row style={{ width: '100%', textAlign: 'left', padding:'1em'}}>
                         {checkFields.map( item => {
                         return (
                              <Col key={item.field} span={8}>
                                   <Checkbox defaultChecked={checkbox[item.field]} onChange={(event)=>selectHandle(event,item.field)} key={item.field}>{item.title}</Checkbox>
                              </Col>
                         )
                         })}
                    </Row>
                    <Row justify='end' style={{padding:'1em'}}>
                         <Col span={6}>
                              <Button className="btnFiltrar" disabled={!checkArray.length>0} onClick={()=> handleSteps('siguiente')} style={{ width: '100%', marginTop: '20px' }}>
                                   Siguiente
                              </Button>
                         </Col>
                    </Row>
               </>
          )
     }
    
     const dataProps = { checkbox, checkArray, state, order }
     const handleProps = {handleTableChange , handleSteps, handleOrden}
     return (
     <Row gutter={[8,8]}>
          <Col span={24} style={{backgroundColor:'#E4E4E4', borderTopRightRadius:'2em', borderTopLeftRadius:'2em', padding:'1em', textAlign: 'left',}}>
               <Text style={{textAlign: 'left', fontSize: '2em', color: '#737373' }} strong> Reporte Dinámico </Text>
          </Col>

          <Col span={24} style={{paddingTop:'1em'}}>
               <Steps
                    type='navigation'
                    size='small'
                    current={state.currentStep}
                    style={{marginBottom:'20px', paddingTop:'0px'}}
               >
                    <Step status={state.currentStep>0? 'finish':'process'} title='Seleccionar Columnas' />
                    <Step status={state.currentStep>1? 'finish':'process'} title='Filtrar' />
                    <Step status={listaReporteDinamicoBitacora.length>0? 'finish':'process'} title='Exportar Reporte' />
               </Steps>
               {state.currentStep === 0 ? <SelectSection/> : false}
               {state.currentStep === 1 ? <FilterSection handleSteps={handleSteps} checkboxData={checkbox} order={order} /> : false}
               {state.currentStep === 2 ? <TableSection handlers={handleProps} data={dataProps}/> : false}
               {state.currentStep === 0 ?<RegistrosContainer/> : false}
          </Col>
     </Row>
     )
}

const mapStateToProps = state => {
     return {
          listaReporteDinamicoBitacora: state.ConfiguracionYSeguridadReducer.listaReporteDinamicoBitacora,
          formularioReporte: state.ConfiguracionYSeguridadReducer.formularioReporte,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
     }
}

const mapDispatchToProps = dispatch => {
     return {
          onGetReporteDinamico: (formulario, page, order, checkboxData) => {
               dispatch({ type: 'LISTA_REPORTE_DINAMICO_BITACORA_BUSQUEDA_REQUEST', formulario, page, order, checkboxData});
          },
          onLimpiarFormulario: (formularioReporte) => {
               dispatch({ type: 'LIMPIAR_FORMULARIO_REPORTE_BITACORA', formularioReporte: formularioReporte, listaReporteDinamicoBitacora: [], listaReporteDinamicoBitacoraCantidad: null})
          },
          onGetReporteDinamicoAgrupado: (formulario, page, agrupado, checkboxData, order) => {
               dispatch({ type: 'REPORTE_DINAMICO_AGRUPADO_BITACORA_REQUEST', formulario, page, agrupado, checkboxData, order});
          },
     }
}

export default connect (mapStateToProps, mapDispatchToProps) (ReporteDinamico);