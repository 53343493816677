// Actions generales para el módulo
export const CAMBIAR_CATALOGO_EVALUACION_GESTION = 'CAMBIAR_CATALOGO_EVALUACION_GESTION';

// Actions para el CU - Catálogo de Clasificación de Gerencias
export const LISTA_CLASIFICACION_REQUEST = 'LISTA_CLASIFICACION_REQUEST';
export const LISTA_CLASIFICACION_SUCCESS = 'LISTA_CLASIFICACION_SUCCESS';
export const LISTA_CLASIFICACION_FAILURE = 'LISTA_CLASIFICACION_FAILURE';

export const LISTA_CLASIFICACION_ACTIVOS_REQUEST = 'LISTA_CLASIFICACION_ACTIVOS_REQUEST';
export const LISTA_CLASIFICACION_ACTIVOS_SUCCESS = 'LISTA_CLASIFICACION_ACTIVOS_SUCCESS';
export const LISTA_CLASIFICACION_ACTIVOS_FAILURE = 'LISTA_CLASIFICACION_ACTIVOS_FAILURE';

export const ESTADO_CLASIFICACION_REQUEST = 'ESTADO_CLASIFICACION_REQUEST';
export const ESTADO_CLASIFICACION_SUCCESS = 'ESTADO_CLASIFICACION_SUCCESS';
export const ESTADO_CLASIFICACION_FAILURE = 'ESTADO_CLASIFICACION_FAILURE';

export const SHOW_MODAL_CREAR_CLASIFICACION = 'SHOW_MODAL_CREAR_CLASIFICACION';

export const CREAR_CLASIFICACION_REQUEST = 'CREAR_CLASIFICACION_REQUEST';
export const CREAR_CLASIFICACION_SUCCESS = 'CREAR_CLASIFICACION_SUCCESS';
export const CREAR_CLASIFICACION_FAILURE = 'CREAR_CLASIFICACION_FAILURE';

export const SHOW_MODAL_EDITAR_CLASIFICACION = 'SHOW_MODAL_EDITAR_CLASIFICACION';

export const EDITAR_CLASIFICACION_REQUEST = 'EDITAR_CLASIFICACION_REQUEST';
export const EDITAR_CLASIFICACION_SUCCESS = 'EDITAR_CLASIFICACION_SUCCESS';
export const EDITAR_CLASIFICACION_FAILURE = 'EDITAR_CLASIFICACION_FAILURE';

export const LISTA_CLASIFICACIONES_GERENCIA_ACTIVAS_REQUEST = 'LISTA_CLASIFICACIONES_GERENCIA_ACTIVAS_REQUEST';
export const LISTA_CLASIFICACIONES_GERENCIA_ACTIVAS_SUCCESS = 'LISTA_CLASIFICACIONES_GERENCIA_ACTIVAS_SUCCESS';
export const LISTA_CLASIFICACIONES_GERENCIA_ACTIVAS_FAILURE = 'LISTA_CLASIFICACIONES_GERENCIA_ACTIVAS_FAILURE';

// Actions para el CU -  Catálogo de Temas
export const LISTA_TEMAS_BUSQUEDA_REQUEST = 'LISTA_TEMAS_BUSQUEDA_REQUEST';
export const LISTA_TEMAS_BUSQUEDA_SUCCESS = 'LISTA_TEMAS_BUSQUEDA_SUCCESS';
export const LISTA_TEMAS_BUSQUEDA_FAILURE = 'LISTA_TEMAS_BUSQUEDA_FAILURE';

export const LISTA_TEMAS_ACTIVOS_REQUEST = 'LISTA_TEMAS_ACTIVOS_REQUEST';
export const LISTA_TEMAS_ACTIVOS_SUCCESS = 'LISTA_TEMAS_ACTIVOS_SUCCESS';
export const LISTA_TEMAS_ACTIVOS_FAILURE = 'LISTA_TEMAS_ACTIVOS_FAILURE';

export const ESTADO_TEMA_REQUEST = 'ESTADO_TEMA_REQUEST';
export const ESTADO_TEMA_SUCCESS = 'ESTADO_TEMA_SUCCESS';
export const ESTADO_TEMA_FAILURE = 'ESTADO_TEMA_FAILURE';

export const SHOW_MODAL_NUEVO_TEMA = 'SHOW_MODAL_NUEVO_TEMA';

export const NUEVO_TEMA_REQUEST = 'NUEVO_TEMA_REQUEST';
export const NUEVO_TEMA_SUCCESS = 'NUEVO_TEMA_SUCCESS';
export const NUEVO_TEMA_FAILURE = 'NUEVO_TEMA_FAILURE';

export const SHOW_MODAL_EDITAR_TEMA = 'SHOW_MODAL_EDITAR_TEMA';

export const EDITAR_TEMA_REQUEST = 'EDITAR_TEMA_REQUEST';
export const EDITAR_TEMA_SUCCESS = 'EDITAR_TEMA_SUCCESS';
export const EDITAR_TEMA_FAILURE = 'EDITAR_TEMA_FAILURE';

// Actions para el CU -  Catálogo de Objetivos para Evaluación de Calidad
export const SHOW_MODAL_NUEVO_OBJETIVO = 'SHOW_MODAL_NUEVO_OBJETIVO';
export const SHOW_MODAL_EDITAR_OBJETIVO = 'SHOW_MODAL_EDITAR_OBJETIVO';

export const LISTA_OBJETIVOS_BUSQUEDA_REQUEST = 'LISTA_OBJETIVOS_BUSQUEDA_REQUEST';
export const LISTA_OBJETIVOS_BUSQUEDA_SUCCESS = 'LISTA_OBJETIVOS_BUSQUEDA_SUCCESS';
export const LISTA_OBJETIVOS_BUSQUEDA_FAILURE = 'LISTA_OBJETIVOS_BUSQUEDA_FAILURE';

export const LISTA_OBJETIVOS_ACTIVOS_REQUEST = 'LISTA_OBJETIVOS_ACTIVOS_REQUEST';
export const LISTA_OBJETIVOS_ACTIVOS_SUCCESS = 'LISTA_OBJETIVOS_ACTIVOS_SUCCESS';
export const LISTA_OBJETIVOS_ACTIVOS_FAILURE = 'LISTA_OBJETIVOS_ACTIVOS_FAILURE';

export const ESTADO_OBJETIVO_REQUEST = 'ESTADO_OBJETIVO_REQUEST';
export const ESTADO_OBJETIVO_SUCCESS = 'ESTADO_OBJETIVO_SUCCESS';
export const ESTADO_OBJETIVO_FAILURE = 'ESTADO_OBJETIVO_FAILURE';

export const NUEVO_OBJETIVO_REQUEST = 'NUEVO_OBJETIVO_REQUEST';
export const NUEVO_OBJETIVO_SUCCESS = 'NUEVO_OBJETIVO_SUCCESS';
export const NUEVO_OBJETIVO_FAILURE = 'NUEVO_OBJETIVO_FAILURE';

export const EDITAR_OBJETIVO_REQUEST = 'EDITAR_OBJETIVO_REQUEST';
export const EDITAR_OBJETIVO_SUCCESS = 'EDITAR_OBJETIVO_SUCCESS';
export const EDITAR_OBJETIVO_FAILURE = 'EDITAR_OBJETIVO_FAILURE';

export const SERVICIOS_BY_IDCENTROCOSTO_REQUEST = 'SERVICIOS_BY_IDCENTROCOSTO_REQUEST';
export const SERVICIOS_BY_IDCENTROCOSTO_SUCCESS = 'SERVICIOS_BY_IDCENTROCOSTO_SUCCESS';
export const SERVICIOS_BY_IDCENTROCOSTO_FAILURE = 'SERVICIOS_BY_IDCENTROCOSTO_FAILURE'; 

export const CLEAN_OBJECT_SELECTED = 'CLEAN_OBJECT_SELECTED';
export const UPDATE_OBJETCT_SELECTED = 'UPDATE_OBJETCT_SELECTED';

export const LISTA_ESTANDARES_ACTIVOS_PARAM_BY_SERVICIO_REQUEST = 'LISTA_ESTANDARES_ACTIVOS_PARAM_BY_SERVICIO_REQUEST';
export const LISTA_ESTANDARES_ACTIVOS_PARAM_BY_SERVICIO_SUCCESS = 'LISTA_ESTANDARES_ACTIVOS_PARAM_BY_SERVICIO_SUCCESS';
export const LISTA_ESTANDARES_ACTIVOS_PARAM_BY_SERVICIO_FAILURE = 'LISTA_ESTANDARES_ACTIVOS_PARAM_BY_SERVICIO_FAILURE';

// Actions para el CU - Catálogo de  Indicadores de Desempeño
export const SHOW_MODAL_ALTA_INDICADORES_DESEMPENO = 'SHOW_MODAL_ALTA_INDICADORES_DESEMPENO';
export const SHOW_MODAL_EDITAR_INDICADOR_DESEMPENO = 'SHOW_MODAL_EDITAR_INDICADOR_DESEMPENO';
export const BOOLEAN_OBJETO_BY_GERENCIA = 'BOOLEAN_OBJETO_BY_GERENCIA';

export const OBJETIVOS_BY_GERENCIA_REQUEST = 'OBJETIVOS_BY_GERENCIA_REQUEST';
export const OBJETIVOS_BY_GERENCIA_SUCCESS = 'OBJETIVOS_BY_GERENCIA_SUCCESS';
export const OBJETIVOS_BY_GERENCIA_FAILURE = 'OBJETIVOS_BY_GERENCIA_FAILURE';

export const LISTA_INDICADORES_DESEMPENO_BUSQUEDA_REQUEST = 'LISTA_INDICADORES_DESEMPENO_BUSQUEDA_REQUEST';
export const LISTA_INDICADORES_DESEMPENO_BUSQUEDA_SUCCESS = 'LISTA_INDICADORES_DESEMPENO_BUSQUEDA_SUCCESS';
export const LISTA_INDICADORES_DESEMPENO_BUSQUEDA_FAILURE = 'LISTA_INDICADORES_DESEMPENO_BUSQUEDA_FAILURE';

export const ALTA_INDICADOR_DESEMPENO_REQUEST = 'ALTA_INDICADOR_DESEMPENO_REQUEST';
export const ALTA_INDICADOR_DESEMPENO_SUCCESS = 'ALTA_INDICADOR_DESEMPENO_SUCCESS';
export const ALTA_INDICADOR_DESEMPENO_FAILURE = 'ALTA_INDICADOR_DESEMPENO_FAILURE';

export const EDITAR_INDICADOR_DESEMPENO_REQUEST = 'EDITAR_INDICADOR_DESEMPENO_REQUEST';
export const EDITAR_INDICADOR_DESEMPENO_SUCCESS = 'EDITAR_INDICADOR_DESEMPENO_SUCCESS';
export const EDITAR_INDICADOR_DESEMPENO_FAILURE = 'EDITAR_INDICADOR_DESEMPENO_FAILURE';

//Clasificación de Solicitudes de Servicios y Servicios Programados por Gerencia
export const GET_ESTADISTICAS_GERENCIA_REQUEST = 'GET_ESTADISTICAS_GERENCIA_REQUEST';
export const GET_ESTADISTICAS_GERENCIA_SUCCESS = 'GET_ESTADISTICAS_GERENCIA_SUCCESS';
export const GET_ESTADISTICAS_GERENCIA_FAILURE = 'GET_ESTADISTICAS_GERENCIA_FAILURE';

//REPORTE DINÁMICO PARA EVALUACIONES DE CALIDAD
export const LISTA_REPORTE_DINAMICO_REQUEST = 'LISTA_REPORTE_DINAMICO_REQUEST';
export const LISTA_REPORTE_DINAMICO_SUCCESS = 'LISTA_REPORTE_DINAMICO_SUCCESS';
export const LISTA_REPORTE_DINAMICO_FAILURE = 'LISTA_REPORTE_DINAMICO_FAILURE';

export const LISTA_REPORTE_DINAMICO_AGRUPADO_REQUEST = 'LISTA_REPORTE_DINAMICO_AGRUPADO_REQUEST';
export const LISTA_REPORTE_DINAMICO_AGRUPADO_SUCCESS = 'LISTA_REPORTE_DINAMICO_AGRUPADO_SUCCESS';
export const LISTA_REPORTE_DINAMICO_AGRUPADO_FAILURE = 'LISTA_REPORTE_DINAMICO_AGRUPADO_FAILURE';

export const EXPORTAR_REPORTE_EXCEL_REQUEST = 'EXPORTAR_REPORTE_EXCEL_REQUEST';
export const EXPORTAR_REPORTE_EXCEL_SUCCESS = 'EXPORTAR_REPORTE_EXCEL_SUCCESS';
export const EXPORTAR_REPORTE_EXCEL_FAILURE = 'EXPORTAR_REPORTE_EXCEL_FAILURE';

export const EXPORTAR_REPORTE_PDF_REQUEST = 'EXPORTAR_REPORTE_PDF_REQUEST';
export const EXPORTAR_REPORTE_PDF_SUCCESS = 'EXPORTAR_REPORTE_PDF_SUCCESS';
export const EXPORTAR_REPORTE_PDF_FAILURE = 'EXPORTAR_REPORTE_PDF_FAILURE';

export const LIMPIAR_FORMULARIO_REPORTE = 'LIMPIAR_FORMULARIO_REPORTE';

export const LISTA_REGISTRO_REPORTE_REQUEST = 'LISTA_REGISTRO_REPORTE_REQUEST';
export const LISTA_REGISTRO_REPORTE_SUCCESS = 'LISTA_REGISTRO_REPORTE_SUCCESS';
export const LISTA_REGISTRO_REPORTE_FAILURE = 'LISTA_REGISTRO_REPORTE_FAILURE';

export const LISTA_REGISTRO_ESPECIAL_REPORTE_REQUEST = 'LISTA_REGISTRO_ESPECIAL_REPORTE_REQUEST';
export const LISTA_REGISTRO_ESPECIAL_REPORTE_SUCCESS = 'LISTA_REGISTRO_ESPECIAL_REPORTE_SUCCESS';
export const LISTA_REGISTRO_ESPECIAL_REPORTE_FAILURE = 'LISTA_REGISTRO_ESPECIAL_REPORTE_FAILURE';

export const ESTADO_REGISTRO_REPORTE_REQUEST = 'ESTADO_REGISTRO_REPORTE_REQUEST';
export const ESTADO_REGISTRO_REPORTE_SUCCESS = 'ESTADO_REGISTRO_REPORTE_SUCCESS';
export const ESTADO_REGISTRO_REPORTE_FAILURE = 'ESTADO_REGISTRO_REPORTE_FAILURE';

export const MODAL_NUEVO_REGISTRO_REPORTE = 'MODAL_NUEVO_REGISTRO_REPORTE';

export const NUEVO_REGISTRO_REPORTE_REQUEST = 'NUEVO_REGISTRO_REPORTE_REQUEST';
export const NUEVO_REGISTRO_REPORTE_SUCCESS = 'NUEVO_REGISTRO_REPORTE_SUCCESS';
export const NUEVO_REGISTRO_REPORTE_FAILURE = 'NUEVO_REGISTRO_REPORTE_FAILURE';

export const MODAL_EDITAR_REGISTRO_REPORTE = 'MODAL_EDITAR_REGISTRO_REPORTE';

export const EDITAR_REGISTRO_REPORTE_REQUEST = 'EDITAR_REGISTRO_REPORTE_REQUEST';
export const EDITAR_REGISTRO_REPORTE_SUCCESS = 'EDITAR_REGISTRO_REPORTE_SUCCESS';
export const EDITAR_REGISTRO_REPORTE_FAILURE = 'EDITAR_REGISTRO_REPORTE_FAILURE';

// Evaluación de Indicadores de Desempeño

export const LISTA_GERENCIAS_BY_SERVICIO_REQUEST = 'LISTA_GERENCIAS_BY_SERVICIO_REQUEST';
export const LISTA_GERENCIAS_BY_SERVICIO_SUCCESS = 'LISTA_GERENCIAS_BY_SERVICIO_SUCCESS';
export const LISTA_GERENCIAS_BY_SERVICIO_FAILURE = 'LISTA_GERENCIAS_BY_SERVICIO_FAILURE';

export const LISTA_OBJETIVOS_BY_GERENCIA_REQUEST = 'LISTA_OBJETIVOS_BY_GERENCIA_REQUEST';
export const LISTA_OBJETIVOS_BY_GERENCIA_SUCCESS = 'LISTA_OBJETIVOS_BY_GERENCIA_SUCCESS';
export const LISTA_OBJETIVOS_BY_GERENCIA_FAILURE = 'LISTA_OBJETIVOS_BY_GERENCIA_FAILURE';

export const LISTA_SERVICIOS_EVALUACIONES_DE_DESEMPENO_REQUEST = 'LISTA_SERVICIOS_EVALUACIONES_DE_DESEMPENO_REQUEST';
export const LISTA_SERVICIOS_EVALUACIONES_DE_DESEMPENO_SUCCESS = 'LISTA_SERVICIOS_EVALUACIONES_DE_DESEMPENO_SUCCESS';
export const LISTA_SERVICIOS_EVALUACIONES_DE_DESEMPENO_FAILURE = 'LISTA_SERVICIOS_EVALUACIONES_DE_DESEMPENO_FAILURE';

export const LISTA_INDICADORES_BY_GERENCIA_OBJETIVO_REQUEST = 'LISTA_INDICADORES_BY_GERENCIA_OBJETIVO_REQUEST';
export const LISTA_INDICADORES_BY_GERENCIA_OBJETIVO_SUCCESS = 'LISTA_INDICADORES_BY_GERENCIA_OBJETIVO_SUCCESS';
export const LISTA_INDICADORES_BY_GERENCIA_OBJETIVO_FAILURE = 'LISTA_INDICADORES_BY_GERENCIA_OBJETIVO_FAILURE';

export const LISTA_PARTIDAS_BY_SERVICIO_REQUEST = 'LISTA_PARTIDAS_BY_SERVICIO_REQUEST';
export const LISTA_PARTIDAS_BY_SERVICIO_SUCCESS = 'LISTA_PARTIDAS_BY_SERVICIO_SUCCESS';
export const LISTA_PARTIDAS_BY_SERVICIO_FAILURE = 'LISTA_PARTIDAS_BY_SERVICIO_FAILURE';

export const LISTA_PARTIDAS_BY_SERVICIO_REQUEST2 = 'LISTA_PARTIDAS_BY_SERVICIO_REQUEST2';
export const LISTA_PARTIDAS_BY_SERVICIO_SUCCESS2 = 'LISTA_PARTIDAS_BY_SERVICIO_SUCCESS2';
export const LISTA_PARTIDAS_BY_SERVICIO_FAILURE2 = 'LISTA_PARTIDAS_BY_SERVICIO_FAILURE2';

export const CREAR_EVALUACION_DE_DESEMPENO_REQUEST = 'CREAR_EVALUACION_DE_DESEMPENO_REQUEST';
export const CREAR_EVALUACION_DE_DESEMPENO_SUCCESS = 'CREAR_EVALUACION_DE_DESEMPENO_SUCCESS';
export const CREAR_EVALUACION_DE_DESEMPENO_FAILURE = 'CREAR_EVALUACION_DE_DESEMPENO_FAILURE';

export const LISTA_MESES_EVALUACION_REQUEST = 'LISTA_MESES_EVALUACION_REQUEST';
export const LISTA_MESES_EVALUACION_SUCCESS = 'LISTA_MESES_EVALUACION_SUCCESS';
export const LISTA_MESES_EVALUACION_FAILURE = 'LISTA_MESES_EVALUACION_FAILURE';

export const LISTA_EVALUACIONES_DESEMPENO_REQUEST = 'LISTA_EVALUACIONES_DESEMPENO_REQUEST';
export const LISTA_EVALUACIONES_DESEMPENO_SUCCESS = 'LISTA_EVALUACIONES_DESEMPENO_SUCCESS';
export const LISTA_EVALUACIONES_DESEMPENO_FAILURE = 'LISTA_EVALUACIONES_DESEMPENO_FAILURE';

export const LISTA_EVALUACIONES_DESEMPENO_1_REQUEST = 'LISTA_EVALUACIONES_DESEMPENO_1_REQUEST';
export const LISTA_EVALUACIONES_DESEMPENO_1_SUCCESS = 'LISTA_EVALUACIONES_DESEMPENO_1_SUCCESS';
export const LISTA_EVALUACIONES_DESEMPENO_1_FAILURE = 'LISTA_EVALUACIONES_DESEMPENO_1_FAILURE';

export const LISTA_EVALUACIONES_DESEMPENO_2_REQUEST = 'LISTA_EVALUACIONES_DESEMPENO_2_REQUEST';
export const LISTA_EVALUACIONES_DESEMPENO_2_SUCCESS = 'LISTA_EVALUACIONES_DESEMPENO_2_SUCCESS';
export const LISTA_EVALUACIONES_DESEMPENO_2_FAILURE = 'LISTA_EVALUACIONES_DESEMPENO_2_FAILURE';

export const MODAL_EVALUACION_DESEMPENO = 'MODAL_EVALUACION_DESEMPENO';

export const MODAL_CONSULTA_EVALUACIONES = 'MODAL_CONSULTA_EVALUACIONES';

export const LISTA_SERVICIOS_BY_CENTRO_REQUEST = 'LISTA_SERVICIOS_BY_CENTRO_REQUEST';
export const LISTA_SERVICIOS_BY_CENTRO_SUCCESS = 'LISTA_SERVICIOS_BY_CENTRO_SUCCESS';
export const LISTA_SERVICIOS_BY_CENTRO_FAILURE = 'LISTA_SERVICIOS_BY_CENTRO_FAILURE';

