export const CAMBIAR_VISTA_DASHBOARD = 'CAMBIAR_VISTA_DASHBOARD';
export const CAMBIAR_CATALOGO_GENERAL = 'CAMBIAR_CATALOGO_GENERAL';

export const LISTA_FAMILIAS_ACTIVOS_REQUEST = 'LISTA_FAMILIAS_ACTIVOS_REQUEST';
export const LISTA_FAMILIAS_ACTIVOS_SUCCESS = 'LISTA_FAMILIAS_ACTIVOS_SUCCESS';
export const LISTA_FAMILIAS_ACTIVOS_FAILURE = 'LISTA_FAMILIAS_ACTIVOS_FAILURE';

// FAMILIAS
export const FAMILIAS_ACTIVOS_BUSQUEDA_REQUEST = 'FAMILIAS_ACTIVOS_BUSQUEDA_REQUEST';
export const FAMILIAS_ACTIVOS_BUSQUEDA_SUCCESS = 'FAMILIAS_ACTIVOS_BUSQUEDA_SUCCESS';
export const FAMILIAS_ACTIVOS_BUSQUEDA_FAILURE = 'FAMILIAS_ACTIVOS_BUSQUEDA_FAILURE';

export const LISTA_FAMILIAS_ACTIVAS_SERVICIO_REQUEST = 'LISTA_FAMILIAS_ACTIVAS_SERVICIO_REQUEST';
export const LISTA_FAMILIAS_ACTIVAS_SERVICIO_SUCCESS = 'LISTA_FAMILIAS_ACTIVAS_SERVICIO_SUCCESS';
export const LISTA_FAMILIAS_ACTIVAS_SERVICIO_FAILURE = 'LISTA_FAMILIAS_ACTIVAS_SERVICIO_FAILURE';

export const ESTADO_FAMILIAS_ACTIVOS_REQUEST = 'ESTADO_FAMILIAS_ACTIVOS_REQUEST';
export const ESTADO_FAMILIAS_ACTIVOS_SUCCESS = 'ESTADO_FAMILIAS_ACTIVOS_SUCCESS';
export const ESTADO_FAMILIAS_ACTIVOS_FAILURE = 'ESTADO_FAMILIAS_ACTIVOS_FAILURE';

export const SHOW_MODAL_NUEVO_FAMILIAS_ACTIVOS = 'SHOW_MODAL_NUEVO_FAMILIAS_ACTIVOS';
export const SHOW_MODAL_EDITAR_FAMILIAS_ACTIVOS = 'SHOW_MODAL_EDITAR_FAMILIAS_ACTIVOS';

export const NUEVO_FAMILIAS_ACTIVOS_REQUEST = 'NUEVO_FAMILIAS_ACTIVOS_REQUEST';
export const NUEVO_FAMILIAS_ACTIVOS_SUCCESS = 'NUEVO_FAMILIAS_ACTIVOS_SUCCESS';
export const NUEVO_FAMILIAS_ACTIVOS_FAILURE = 'NUEVO_FAMILIAS_ACTIVOS_FAILURE';

export const EDITAR_FAMILIAS_ACTIVOS_REQUEST = 'EDITAR_FAMILIAS_ACTIVOS_REQUEST';
export const EDITAR_FAMILIAS_ACTIVOS_SUCCESS = 'EDITAR_FAMILIAS_ACTIVOS_SUCCESS';
export const EDITAR_FAMILIAS_ACTIVOS_FAILURE = 'EDITAR_FAMILIAS_ACTIVOS_FAILURE';

// SUBFAMILIAS
export const LISTA_SUBFAMILIAS_PRODUCTOS_REQUEST = 'LISTA_SUBFAMILIAS_PRODUCTOS_REQUEST';
export const LISTA_SUBFAMILIAS_PRODUCTOS_SUCCESS = 'LISTA_SUBFAMILIAS_PRODUCTOS_SUCCESS';
export const LISTA_SUBFAMILIAS_PRODUCTOS_FAILURE = 'LISTA_SUBFAMILIAS_PRODUCTOS_FAILURE';

export const LISTA_SUBFAMILIAS_REQUEST = 'LISTA_SUBFAMILIAS_REQUEST';
export const LISTA_SUBFAMILIAS_SUCCESS = 'LISTA_SUBFAMILIAS_SUCCESS';
export const LISTA_SUBFAMILIAS_FAILURE = 'LISTA_SUBFAMILIAS_FAILURE';

export const LISTA_SUBFAMILIAS_POR_SERVICIO_REQUEST = 'LISTA_SUBFAMILIAS_POR_SERVICIO_REQUEST';
export const LISTA_SUBFAMILIAS_POR_SERVICIO_SUCCESS = 'LISTA_SUBFAMILIAS_POR_SERVICIO_SUCCESS';
export const LISTA_SUBFAMILIAS_POR_SERVICIO_FAILURE = 'LISTA_SUBFAMILIAS_POR_SERVICIO_FAILURE';

export const LISTA_SUBFAMILIAS_RUTINAS_REQUEST = 'LISTA_SUBFAMILIAS_RUTINAS_REQUEST';
export const LISTA_SUBFAMILIAS_RUTINAS_SUCCESS = 'LISTA_SUBFAMILIAS_RUTINAS_SUCCESS';
export const LISTA_SUBFAMILIAS_RUTINAS_FAILURE = 'LISTA_SUBFAMILIAS_RUTINAS_FAILURE';

export const SUBFAMILIAS_BUSQUEDA_REQUEST = 'SUBFAMILIAS_BUSQUEDA_REQUEST';
export const SUBFAMILIAS_BUSQUEDA_SUCCESS = 'SUBFAMILIAS_BUSQUEDA_SUCCESS';
export const SUBFAMILIAS_BUSQUEDA_FAILURE = 'SUBFAMILIAS_BUSQUEDA_FAILURE';

export const ESTADO_SUBFAMILIAS_REQUEST = 'ESTADO_SUBFAMILIAS_REQUEST';
export const ESTADO_SUBFAMILIAS_SUCCESS = 'ESTADO_SUBFAMILIAS_SUCCESS';
export const ESTADO_SUBFAMILIAS_FAILURE = 'ESTADO_SUBFAMILIAS_FAILURE';

export const SHOW_MODAL_NUEVO_SUBFAMILIAS = 'SHOW_MODAL_NUEVO_SUBFAMILIAS';
export const SHOW_MODAL_EDITAR_SUBFAMILIAS = 'SHOW_MODAL_EDITAR_SUBFAMILIAS';

export const NUEVO_SUBFAMILIAS_REQUEST = 'NUEVO_SUBFAMILIAS_REQUEST';
export const NUEVO_SUBFAMILIAS_SUCCESS = 'NUEVO_SUBFAMILIAS_SUCCESS';
export const NUEVO_SUBFAMILIAS_FAILURE = 'NUEVO_SUBFAMILIAS_FAILURE';

export const EDITAR_SUBFAMILIAS_REQUEST = 'EDITAR_SUBFAMILIAS_REQUEST';
export const EDITAR_SUBFAMILIAS_SUCCESS = 'EDITAR_SUBFAMILIAS_SUCCESS';
export const EDITAR_SUBFAMILIAS_FAILURE = 'EDITAR_SUBFAMILIAS_FAILURE';

export const GET_PERMISOS_REQUEST = 'GET_PERMISOS_REQUEST';
export const GET_PERMISOS_SUCCESS = 'GET_PERMISOS_SUCCESS';
export const GET_PERMISOS_FAILURE = 'GET_PERMISOS_FAILURE';

export const GET_PERMISOS_BUSQUEDA_REQUEST = 'GET_PERMISOS_BUSQUEDA_REQUEST';
export const GET_PERMISOS_BUSQUEDA_SUCCESS = 'GET_PERMISOS_BUSQUEDA_SUCCESS';
export const GET_PERMISOS_BUSQUEDA_FAILURE = 'GET_PERMISOS_BUSQUEDA_FAILURE';

export const LISTA_SUBFAMILIAS_BY_PARTIDA_REQUEST = 'LISTA_SUBFAMILIAS_BY_PARTIDA_REQUEST';
export const LISTA_SUBFAMILIAS_BY_PARTIDA_SUCCESS = 'LISTA_SUBFAMILIAS_BY_PARTIDA_SUCCESS';
export const LISTA_SUBFAMILIAS_BY_PARTIDA_FAILURE = 'LISTA_SUBFAMILIAS_BY_PARTIDA_FAILURE';

//Catálogo Centro de Costos
export const LISTA_CENTRO_COSTOS_ACTIVOS_REQUEST = 'LISTA_CENTRO_COSTOS_ACTIVOS_REQUEST';
export const LISTA_CENTRO_COSTOS_ACTIVOS_SUCCESS = 'LISTA_CENTRO_COSTOS_ACTIVOS_SUCCESS';
export const LISTA_CENTRO_COSTOS_ACTIVOS_FAILURE = 'LISTA_CENTRO_COSTOS_ACTIVOS_FAILURE';

export const LISTA_CENTRO_COSTOS_BY_GERENCIA_REQUEST = 'LISTA_CENTRO_COSTOS_BY_GERENCIA_REQUEST';
export const LISTA_CENTRO_COSTOS_BY_GERENCIA_SUCCESS = 'LISTA_CENTRO_COSTOS_BY_GERENCIA_SUCCESS';
export const LISTA_CENTRO_COSTOS_BY_GERENCIA_FAILURE = 'LISTA_CENTRO_COSTOS_BY_GERENCIA_FAILURE';

export const CENTRO_COSTOS_BUSQUEDA_REQUEST = 'CENTRO_COSTOS_BUSQUEDA_REQUEST';
export const CENTRO_COSTOS_BUSQUEDA_SUCCESS = 'CENTRO_COSTOS_BUSQUEDA_SUCCESS';
export const CENTRO_COSTOS_BUSQUEDA_FAILURE = 'CENTRO_COSTOS_BUSQUEDA_FAILURE';

export const NUEVO_CENTRO_COSTOS_REQUEST = 'NUEVO_CENTRO_COSTOS_REQUEST';
export const NUEVO_CENTRO_COSTOS_SUCCESS = 'NUEVO_CENTRO_COSTOS_SUCCESS';
export const NUEVO_CENTRO_COSTOS_FAILURE = 'NUEVO_CENTRO_COSTOS_FAILURE';

export const EDITAR_CENTRO_COSTOS_REQUEST = 'EDITAR_CENTRO_COSTOS_REQUEST';
export const EDITAR_CENTRO_COSTOS_SUCCESS = 'EDITAR_CENTRO_COSTOS_SUCCESS';
export const EDITAR_CENTRO_COSTOS_FAILURE = 'EDITAR_CENTRO_COSTOS_FAILURE';

export const ESTADO_CENTRO_COSTOS_REQUEST = 'ESTADO_CENTRO_COSTOS_REQUEST';
export const ESTADO_CENTRO_COSTOS_SUCCESS = 'ESTADO_CENTRO_COSTOS_SUCCESS';
export const ESTADO_CENTRO_COSTOS_FAILURE = 'ESTADO_CENTRO_COSTOS_FAILURE';

export const SHOW_MODAL_NUEVO_CENTRO_COSTOS = 'SHOW_MODAL_NUEVO_CENTRO_COSTOS';
export const SHOW_MODAL_EDITAR_CENTRO_COSTOS = 'SHOW_MODAL_EDITAR_CENTRO_COSTOS';

// ACTIONS GRUPO DE SERVICIOS
export const LISTA_GRUPOS_SERVICIOS_REQUEST = 'LISTA_GRUPOS_SERVICIOS_REQUEST';
export const LISTA_GRUPOS_SERVICIOS_SUCCESS = 'LISTA_GRUPOS_SERVICIOS_SUCCESS';
export const LISTA_GRUPOS_SERVICIOS_FAILURE = 'LISTA_GRUPOS_SERVICIOS_FAILURE';

export const GRUPOS_SERVICIOS_BUSQUEDA_REQUEST = 'GRUPOS_SERVICIOS_BUSQUEDA_REQUEST';
export const GRUPOS_SERVICIOS_BUSQUEDA_SUCCESS = 'GRUPOS_SERVICIOS_BUSQUEDA_SUCCESS';
export const GRUPOS_SERVICIOS_BUSQUEDA_FAILURE = 'GRUPOS_SERVICIOS_BUSQUEDA_FAILURE';

export const ESTADO_GRUPO_SERVICIO_REQUEST = 'ESTADO_GRUPO_SERVICIO_REQUEST';
export const ESTADO_GRUPO_SERVICIO_SUCCESS = 'ESTADO_GRUPO_SERVICIO_SUCCESS';
export const ESTADO_GRUPO_SERVICIO_FAILURE = 'ESTADO_GRUPO_SERVICIO_FAILURE';

export const SHOW_MODAL_NUEVO_GRUPO_SERVICIO = 'SHOW_MODAL_NUEVO_GRUPO_SERVICIO';
export const SHOW_MODAL_EDITAR_GRUPO_SERVICIO = 'SHOW_MODAL_EDITAR_GRUPO_SERVICIO';

export const NUEVO_GRUPO_SERVICIO_REQUEST = 'NUEVO_GRUPO_SERVICIO_REQUEST';
export const NUEVO_GRUPO_SERVICIO_SUCCESS = 'NUEVO_GRUPO_SERVICIO_SUCCESS';
export const NUEVO_GRUPO_SERVICIO_FAILURE = 'NUEVO_GRUPO_SERVICIO_FAILURE';

export const EDITAR_GRUPO_SERVICIO_REQUEST = 'EDITAR_GRUPO_SERVICIO_REQUEST';
export const EDITAR_GRUPO_SERVICIO_SUCCESS = 'EDITAR_GRUPO_SERVICIO_SUCCESS';
export const EDITAR_GRUPO_SERVICIO_FAILURE = 'EDITAR_GRUPO_SERVICIO_FAILURE';

//ACTIONS SERVICIOS
export const LISTA_SERVICIOS_REQUEST = 'LISTA_SERVICIOS_REQUEST';
export const LISTA_SERVICIOS_SUCCESS = 'LISTA_SERVICIOS_SUCCESS';
export const LISTA_SERVICIOS_FAILURE = 'LISTA_SERVICIOS_FAILURE';

export const LISTA_SERVICIOS_BY_CENTRO_COSTO_REQUEST = 'LISTA_SERVICIOS_BY_CENTRO_COSTO_REQUEST';
export const LISTA_SERVICIOS_BY_CENTRO_COSTO_SUCCESS = 'LISTA_SERVICIOS_BY_CENTRO_COSTO_SUCCESS';
export const LISTA_SERVICIOS_BY_CENTRO_COSTO_FAILURE = 'LISTA_SERVICIOS_BY_CENTRO_COSTO_FAILURE';

export const LISTA_SERVICIOS_BY_PROVEEDOR_REQUEST = 'LISTA_SERVICIOS_BY_PROVEEDOR_REQUEST';
export const LISTA_SERVICIOS_BY_PROVEEDOR_SUCCESS = 'LISTA_SERVICIOS_BY_PROVEEDOR_SUCCESS';
export const LISTA_SERVICIOS_BY_PROVEEDOR_FAILURE = 'LISTA_SERVICIOS_BY_PROVEEDOR_FAILURE';

export const LISTA_SERVICIOS_BY_GRUPO_SERV_REQUEST = 'LISTA_SERVICIOS_BY_GRUPO_SERV_REQUEST';
export const LISTA_SERVICIOS_BY_GRUPO_SERV_SUCCESS = 'LISTA_SERVICIOS_BY_GRUPO_SERV_SUCCESS';
export const LISTA_SERVICIOS_BY_GRUPO_SERV_FAILURE = 'LISTA_SERVICIOS_BY_GRUPO_SERV_FAILURE';

export const LISTA_SERVICIOS_BY_GRUPOS_SERV_REQUEST = 'LISTA_SERVICIOS_BY_GRUPOS_SERV_REQUEST';
export const LISTA_SERVICIOS_BY_GRUPOS_SERV_SUCCESS = 'LISTA_SERVICIOS_BY_GRUPOS_SERV_SUCCESS';
export const LISTA_SERVICIOS_BY_GRUPOS_SERV_FAILURE = 'LISTA_SERVICIOS_BY_GRUPOS_SERV_FAILURE';

export const LISTA_SERVICIOS_BUSQUEDA_REQUEST = 'LISTA_SERVICIOS_BUSQUEDA_REQUEST';
export const LISTA_SERVICIOS_BUSQUEDA_SUCCESS = 'LISTA_SERVICIOS_BUSQUEDA_SUCCESS';
export const LISTA_SERVICIOS_BUSQUEDA_FAILURE = 'LISTA_SERVICIOS_BUSQUEDA_FAILURE';

export const LISTA_SERVICIOS_SIN_INFO_REQUEST = 'LISTA_SERVICIOS_SIN_INFO_REQUEST';
export const LISTA_SERVICIOS_SIN_INFO_SUCCESS = 'LISTA_SERVICIOS_SIN_INFO_SUCCESS';
export const LISTA_SERVICIOS_SIN_INFO_FAILURE = 'LISTA_SERVICIOS_SIN_INFO_FAILURE';

export const SHOW_MODAL_NUEVO_MODULO_SERVICIO = 'SHOW_MODAL_NUEVO_MODULO_SERVICIO';
export const SHOW_MODAL_EDITAR_MODULO_SERVICIO = 'SHOW_MODADL_EDITAR_MODULO_SERVICIO';

export const NUEVO_MODULO_SERVICIO_REQUEST = 'NUEVO_MODULO_SERVICIO_REQUEST';
export const NUEVO_MODULO_SERVICIO_SUCCESS = 'NUEVO_MODULO_SERVICIO_SUCCESS';
export const NUEVO_MODULO_SERVICIO_FAILURE = 'NUEVO_MODULO_SERVICIO_FAILURE';

export const EDITAR_MODULO_SERVICIO_REQUEST = 'EDITAR_MODULO_SERVICIO_REQUEST';
export const EDITAR_MODULO_SERVICIO_SUCCESS = 'EDITAR_MODULO_SERVICIO_SUCCESS';
export const EDITAR_MODULO_SERVICIO_FAILURE = 'EDITAR_MODULO_SERVICIO_FAILURE';

export const ESTADO_SERVICIO_REQUEST = 'ESTADO_SERVICIO_REQUEST';
export const ESTADO_SERVICIO_SUCCESS = 'ESTADO_SERVICIO_SUCCESS';
export const ESTADO_SERVICIO_FAILURE = 'ESTADO_SERVICIO_FAILURE';

export const SHOW_MODAL_NUEVO_SERVICIO = 'SHOW_MODAL_NUEVO_SERVICIO';
export const SHOW_MODAL_EDITAR_SERVICIO = 'SHOW_MODAL_EDITAR_SERVICIO';
export const SHOW_MODAL_ARCHIVOS_SERVICIO = 'SHOW_MODAL_ARCHIVOS_SERVICIO';

export const NUEVO_SERVICIO_REQUEST = 'NUEVO_SERVICIO_REQUEST';
export const NUEVO_SERVICIO_SUCCESS = 'NUEVO_SERVICIO_SUCCESS';
export const NUEVO_SERVICIO_FAILURE = 'NUEVO_SERVICIO_FAILURE';

export const EDITAR_SERVICIO_REQUEST = 'EDITAR_SERVICIO_REQUEST';
export const EDITAR_SERVICIO_SUCCESS = 'EDITAR_SERVICIO_SUCCESS';
export const EDITAR_SERVICIO_FAILURE = 'EDITAR_SERVICIO_FAILURE';

export const LISTA_SERVICIOS_ACTIVOSF_REQUEST = 'LISTA_SERVICIOS_ACTIVOSF_REQUEST';
export const LISTA_SERVICIOS_ACTIVOSF_SUCCESS = 'LISTA_SERVICIOS_ACTIVOSF_SUCCESS';
export const LISTA_SERVICIOS_ACTIVOSF_FAILURE = 'LISTA_SERVICIOS_ACTIVOSF_FAILURE';

export const LISTA_SERVICIOS_BY_IDGERENCIAS_REQUEST = 'LISTA_SERVICIOS_BY_IDGERENCIAS_REQUEST';
export const LISTA_SERVICIOS_BY_IDGERENCIAS_SUCCESS = 'LISTA_SERVICIOS_BY_IDGERENCIAS_SUCCESS';
export const LISTA_SERVICIOS_BY_IDGERENCIAS_FAILURE = 'LISTA_SERVICIOS_BY_IDGERENCIAS_FAILURE';

export const LISTA_SERVICIOS_BY_IDSGRUPOS_REQUEST = 'LISTA_SERVICIOS_BY_IDSGRUPOS_REQUEST';
export const LISTA_SERVICIOS_BY_IDSGRUPOS_SUCCESS = 'LISTA_SERVICIOS_BY_IDSGRUPOS_SUCCESS';
export const LISTA_SERVICIOS_BY_IDSGRUPOS_FAILURE = 'LISTA_SERVICIOS_BY_IDSGRUPOS_FAILURE';

// AREAS FUNCIONALES
export const LISTA_AREASFUNCIONALES_REQUEST = 'LISTA_AREASFUNCIONALES_REQUEST';
export const LISTA_AREASFUNCIONALES_SUCCESS = 'LISTA_AREASFUNCIONALES_SUCCESS';
export const LISTA_AREASFUNCIONALES_FAILURE = 'LISTA_AREASFUNCIONALES_FAILURE';

export const LISTA_AREASFUNCIONALES_ACTIVOS_REQUEST = 'LISTA_AREASFUNCIONALES_ACTIVOS_REQUEST';
export const LISTA_AREASFUNCIONALES_ACTIVOS_SUCCESS = 'LISTA_AREASFUNCIONALES_ACTIVOS_SUCCESS';
export const LISTA_AREASFUNCIONALES_ACTIVOS_FAILURE = 'LISTA_AREASFUNCIONALES_ACTIVOS_FAILURE';

export const AREASFUNCIONALES_BUSQUEDA_REQUEST = 'AREASFUNCIONALES_BUSQUEDA_REQUEST';
export const AREASFUNCIONALES_BUSQUEDA_SUCCESS = 'AREASFUNCIONALES_BUSQUEDA_SUCCESS';
export const AREASFUNCIONALES_BUSQUEDA_FAILURE = 'AREASFUNCIONALES_BUSQUEDA_FAILURE';

export const ESTADO_AREASFUNCIONALES_REQUEST = 'ESTADO_AREASFUNCIONALES_REQUEST';
export const ESTADO_AREASFUNCIONALES_SUCCESS = 'ESTADO_AREASFUNCIONALES_SUCCESS';
export const ESTADO_AREASFUNCIONALES_FAILURE = 'ESTADO_AREASFUNCIONALES_FAILURE';

export const SHOW_MODAL_NUEVO_AREASFUNCIONALES = 'SHOW_MODAL_NUEVO_AREASFUNCIONALES';
export const SHOW_MODAL_EDITAR_AREASFUNCIONALES = 'SHOW_MODAL_EDITAR_AREASFUNCIONALES';
export const SHOW_MODAL_MAPA_AREA_FUNCIONAL = 'SHOW_MODAL_MAPA_AREA_FUNCIONAL';
export const CAMBIAR_VISTA_AREAS = 'CAMBIAR_VISTA_AREAS';

export const NUEVO_AREASFUNCIONALES_REQUEST = 'NUEVO_AREASFUNCIONALES_REQUEST';
export const NUEVO_AREASFUNCIONALES_SUCCESS = 'NUEVO_AREASFUNCIONALES_SUCCESS';
export const NUEVO_AREASFUNCIONALES_FAILURE = 'NUEVO_AREASFUNCIONALES_FAILURE';

export const EDITAR_AREASFUNCIONALES_REQUEST = 'EDITAR_AREASFUNCIONALES_REQUEST';
export const EDITAR_AREASFUNCIONALES_SUCCESS = 'EDITAR_AREASFUNCIONALES_SUCCESS';
export const EDITAR_AREASFUNCIONALES_FAILURE = 'EDITAR_AREASFUNCIONALES_FAILURE';

export const LISTA_AREAS_FUNCIONALES_ACTIVAS_REQUEST = 'LISTA_AREAS_FUNCIONALES_ACTIVAS_REQUEST';
export const LISTA_AREAS_FUNCIOANLES_ACTIVAS_SUCCESS = 'LISTA_AREAS_FUNCIOANLES_ACTIVAS_SUCCESS';
export const LISTA_AREAS_FUNCIONALES_ACTIVAS_FAILURE = 'LISTA_AREAS_FUNCIONALES_ACTIVAS_FAILURE';

export const AREAS_BLOQUEAR_REQUEST = 'AREAS_BLOQUEAR_REQUEST';
export const AREAS_BLOQUEAR_SUCCESS = 'AREAS_BLOQUEAR_SUCCESS';
export const AREAS_BLOQUEAR_FAILURE = 'AREAS_BLOQUEAR_FAILURE';

// UNIDADES FUNCIONALES
export const LISTA_UNIDADESFUNCIONALES_REQUEST = 'LISTA_UNIDADESFUNCIONALES_REQUEST';
export const LISTA_UNIDADESFUNCIONALES_SUCCESS = 'LISTA_UNIDADESFUNCIONALES_SUCCESS';
export const LISTA_UNIDADESFUNCIONALES_FAILURE = 'LISTA_UNIDADESFUNCIONALES_FAILURE';

export const LISTA_UNIDADESFUNCIONALES_ACTIVOS_REQUEST = 'LISTA_UNIDADESFUNCIONALES_ACTIVOS_REQUEST';
export const LISTA_UNIDADESFUNCIONALES_ACTIVOS_SUCCESS = 'LISTA_UNIDADESFUNCIONALES_ACTIVOS_SUCCESS';
export const LISTA_UNIDADESFUNCIONALES_ACTIVOS_FAILURE = 'LISTA_UNIDADESFUNCIONALES_ACTIVOS_FAILURE';

export const LISTA_UNI_FUNCIO_ACT_COMPLETA_REQUEST = 'LISTA_UNI_FUNCIO_ACT_COMPLETA_REQUEST';
export const LISTA_UNI_FUNCIO_ACT_COMPLETA_SUCCESS = 'LISTA_UNI_FUNCIO_ACT_COMPLETA_SUCCESS';
export const LISTA_UNI_FUNCIO_ACT_COMPLETA_FAILURE = 'LISTA_UNI_FUNCIO_ACT_COMPLETA_FAILURE';

export const LISTA_UNI_FUNCIO_ACT_POR_AREA_REQUEST = 'LISTA_UNI_FUNCIO_ACT_POR_AREA_REQUEST';
export const LISTA_UNI_FUNCIO_ACT_POR_AREA_SUCCESS = 'LISTA_UNI_FUNCIO_ACT_POR_AREA_SUCCESS';
export const LISTA_UNI_FUNCIO_ACT_POR_AREA_FAILURE = 'LISTA_UNI_FUNCIO_ACT_POR_AREA_FAILURE';

export const UNIDADESFUNCIONALES_BUSQUEDA_REQUEST = 'UNIDADESFUNCIONALES_BUSQUEDA_REQUEST';
export const UNIDADESFUNCIONALES_BUSQUEDA_SUCCESS = 'UNIDADESFUNCIONALES_BUSQUEDA_SUCCESS';
export const UNIDADESFUNCIONALES_BUSQUEDA_FAILURE = 'UNIDADESFUNCIONALES_BUSQUEDA_FAILURE';

export const ESTADO_UNIDADESFUNCIONALES_REQUEST = 'ESTADO_UNIDADESFUNCIONALES_REQUEST';
export const ESTADO_UNIDADESFUNCIONALES_SUCCESS = 'ESTADO_UNIDADESFUNCIONALES_SUCCESS';
export const ESTADO_UNIDADESFUNCIONALES_FAILURE = 'ESTADO_UNIDADESFUNCIONALES_FAILURE';

export const SHOW_MODAL_NUEVO_UNIDADESFUNCIONALES = 'SHOW_MODAL_NUEVO_UNIDADESFUNCIONALES';
export const SHOW_MODAL_EDITAR_UNIDADESFUNCIONALES = 'SHOW_MODAL_EDITAR_UNIDADESFUNCIONALES';
export const SHOW_MODAL_MAPA_UNIDAD_FUNCIONAL = 'SHOW_MODAL_MAPA_UNIDAD_FUNCIONAL';
export const CAMBIAR_VISTA_UNIDAD = 'CAMBIAR_VISTA_UNIDAD';

export const NUEVO_UNIDADESFUNCIONALES_REQUEST = 'NUEVO_UNIDADESFUNCIONALES_REQUEST';
export const NUEVO_UNIDADESFUNCIONALES_SUCCESS = 'NUEVO_UNIDADESFUNCIONALES_SUCCESS';
export const NUEVO_UNIDADESFUNCIONALES_FAILURE = 'NUEVO_UNIDADESFUNCIONALES_FAILURE';

export const EDITAR_UNIDADESFUNCIONALES_REQUEST = 'EDITAR_UNIDADESFUNCIONALES_REQUEST';
export const EDITAR_UNIDADESFUNCIONALES_SUCCESS = 'EDITAR_UNIDADESFUNCIONALES_SUCCESS';
export const EDITAR_UNIDADESFUNCIONALES_FAILURE = 'EDITAR_UNIDADESFUNCIONALES_FAILURE';


// INTERIORES 
export const LISTA_INTERIOR_REQUEST = 'LISTA_INTERIOR_REQUEST';
export const LISTA_INTERIOR_SUCCESS = 'LISTA_INTERIOR_SUCCESS';
export const LISTA_INTERIOR_FAILURE = 'LISTA_INTERIOR_FAILURE';

export const LISTA_INTERIOR_ACTIVOS_REQUEST = 'LISTA_INTERIOR_ACTIVOS_REQUEST';
export const LISTA_INTERIOR_ACTIVOS_SUCCESS = 'LISTA_INTERIOR_ACTIVOS_SUCCESS';
export const LISTA_INTERIOR_ACTIVOS_FAILURE = 'LISTA_INTERIOR_ACTIVOS_FAILURE';

export const LISTA_INTERIORES_ACT_COMPLETA_REQUEST = 'LISTA_INTERIORES_ACT_COMPLETA_REQUEST';
export const LISTA_INTERIORES_ACT_COMPLETA_SUCCESS = 'LISTA_INTERIORES_ACT_COMPLETA_SUCCESS';
export const LISTA_INTERIORES_ACT_COMPLETA_FAILURE = 'LISTA_INTERIORES_ACT_COMPLETA_FAILURE';

export const LISTA_INTERIORES_ACT_POR_UNIDAD_REQUEST = 'LISTA_INTERIORES_ACT_POR_UNIDAD_REQUEST';
export const LISTA_INTERIORES_ACT_POR_UNIDAD_SUCCESS = 'LISTA_INTERIORES_ACT_POR_UNIDAD_SUCCESS';
export const LISTA_INTERIORES_ACT_POR_UNIDAD_FAILURE = 'LISTA_INTERIORES_ACT_POR_UNIDAD_FAILURE';

export const INTERIOR_BUSQUEDA_REQUEST = 'INTERIOR_BUSQUEDA_REQUEST';
export const INTERIOR_BUSQUEDA_SUCCESS = 'INTERIOR_BUSQUEDA_SUCCESS';
export const INTERIOR_BUSQUEDA_FAILURE = 'INTERIOR_BUSQUEDA_FAILURE';

export const ESTADO_INTERIOR_REQUEST = 'ESTADO_INTERIOR_REQUEST';
export const ESTADO_INTERIOR_SUCCESS = 'ESTADO_INTERIOR_SUCCESS';
export const ESTADO_INTERIOR_FAILURE = 'ESTADO_INTERIOR_FAILURE';

export const SHOW_MODAL_NUEVO_INTERIOR = 'SHOW_MODAL_NUEVO_INTERIOR';
export const SHOW_MODAL_EDITAR_INTERIOR = 'SHOW_MODAL_EDITAR_INTERIOR';
export const SHOW_MODAL_MAPA_INTERIOR = 'SHOW_MODAL_MAPA_INTERIOR';

export const NUEVO_INTERIOR_REQUEST = 'NUEVO_INTERIOR_REQUEST';
export const NUEVO_INTERIOR_SUCCESS = 'NUEVO_INTERIOR_SUCCESS';
export const NUEVO_INTERIOR_FAILURE = 'NUEVO_INTERIOR_FAILURE';

export const EDITAR_INTERIOR_REQUEST = 'EDITAR_INTERIOR_REQUEST';
export const EDITAR_INTERIOR_SUCCESS = 'EDITAR_INTERIOR_SUCCESS';
export const EDITAR_INTERIOR_FAILURE = 'EDITAR_INTERIOR_FAILURE';

// SUB INTERIORES 
export const LISTA_SUBINTERIOR_REQUEST = 'LISTA_SUBINTERIOR_REQUEST';
export const LISTA_SUBINTERIOR_SUCCESS = 'LISTA_SUBINTERIOR_SUCCESS';
export const LISTA_SUBINTERIOR_FAILURE = 'LISTA_SUBINTERIOR_FAILURE';

export const LISTA_SUBINTERIOR_ACTIVOS_REQUEST = 'LISTA_SUBINTERIOR_ACTIVOS_REQUEST';
export const LISTA_SUBINTERIOR_ACTIVOS_SUCCESS = 'LISTA_SUBINTERIOR_ACTIVOS_SUCCESS';
export const LISTA_SUBINTERIOR_ACTIVOS_FAILURE = 'LISTA_SUBINTERIOR_ACTIVOS_FAILURE';

export const LISTA_SUBINTERIOR_ACTIVOS_POR_INTERIOR_REQUEST = 'LISTA_SUBINTERIOR_ACTIVOS_POR_INTERIOR_REQUEST';
export const LISTA_SUBINTERIOR_ACTIVOS_POR_INTERIOR_SUCCESS = 'LISTA_SUBINTERIOR_ACTIVOS_POR_INTERIOR_SUCCESS';
export const LISTA_SUBINTERIOR_ACTIVOS_POR_INTERIOR_FAILURE = 'LISTA_SUBINTERIOR_ACTIVOS_POR_INTERIOR_FAILURE';

export const SUBINTERIOR_BUSQUEDA_REQUEST = 'SUBINTERIOR_BUSQUEDA_REQUEST';
export const SUBINTERIOR_BUSQUEDA_SUCCESS = 'SUBINTERIOR_BUSQUEDA_SUCCESS';
export const SUBINTERIOR_BUSQUEDA_FAILURE = 'SUBINTERIOR_BUSQUEDA_FAILURE';

export const ESTADO_SUBINTERIOR_REQUEST = 'ESTADO_SUBINTERIOR_REQUEST';
export const ESTADO_SUBINTERIOR_SUCCESS = 'ESTADO_SUBINTERIOR_SUCCESS';
export const ESTADO_SUBINTERIOR_FAILURE = 'ESTADO_SUBINTERIOR_FAILURE';

export const SHOW_MODAL_NUEVO_SUBINTERIOR = 'SHOW_MODAL_NUEVO_SUBINTERIOR';
export const SHOW_MODAL_EDITAR_SUBINTERIOR = 'SHOW_MODAL_EDITAR_SUBINTERIOR';
export const SHOW_MODAL_MAPA_SUBINTERIOR = 'SHOW_MODAL_MAPA_SUBINTERIOR';

export const NUEVO_SUBINTERIOR_REQUEST = 'NUEVO_SUBINTERIOR_REQUEST';
export const NUEVO_SUBINTERIOR_SUCCESS = 'NUEVO_SUBINTERIOR_SUCCESS';
export const NUEVO_SUBINTERIOR_FAILURE = 'NUEVO_SUBINTERIOR_FAILURE';

export const EDITAR_SUBINTERIOR_REQUEST = 'EDITAR_SUBINTERIOR_REQUEST';
export const EDITAR_SUBINTERIOR_SUCCESS = 'EDITAR_SUBINTERIOR_SUCCESS';
export const EDITAR_SUBINTERIOR_FAILURE = 'EDITAR_SUBINTERIOR_FAILURE';

//CATEGORÍAS DE FALLAS
export const LISTA_CATEGORIAS_FALLAS_ACTIVOS_REQUEST = 'LISTA_CATEGORIAS_FALLAS_ACTIVOS_REQUEST';
export const LISTA_CATEGORIAS_FALLAS_ACTIVOS_SUCCESS = 'LISTA_CATEGORIAS_FALLAS_ACTIVOS_SUCCESS';
export const LISTA_CATEGORIAS_FALLAS_ACTIVOS_FAILURE = 'LISTA_CATEGORIAS_FALLAS_ACTIVOS_FAILURE';

export const CATEGORIAS_FALLAS_BUSQUEDA_REQUEST = 'CATEGORIAS_FALLAS_BUSQUEDA_REQUEST';
export const CATEGORIAS_FALLAS_BUSQUEDA_SUCCESS = 'CATEGORIAS_FALLAS_BUSQUEDA_SUCCESS';
export const CATEGORIAS_FALLAS_BUSQUEDA_FAILURE = 'CATEGORIAS_FALLAS_BUSQUEDA_FAILURE';

export const NUEVA_CATEGORIA_FALLA_REQUEST = 'NUEVA_CATEGORIA_FALLA_REQUEST';
export const NUEVA_CATEGORIA_FALLA_SUCCESS = 'NUEVA_CATEGORIA_FALLA_SUCCESS';
export const NUEVA_CATEGORIA_FALLA_FAILURE = 'NUEVA_CATEGORIA_FALLA_FAILURE';

export const EDITAR_CATEGORIA_FALLA_REQUEST = 'EDITAR_CATEGORIA_FALLA_REQUEST';
export const EDITAR_CATEGORIA_FALLA_SUCCESS = 'EDITAR_CATEGORIA_FALLA_SUCCESS';
export const EDITAR_CATEGORIA_FALLA_FAILURE = 'EDITAR_CATEGORIA_FALLA_FAILURE';

export const ESTADO_CATEGORIA_FALLA_REQUEST = 'ESTADO_CATEGORIA_FALLA_REQUEST';
export const ESTADO_CATEGORIA_FALLA_SUCCESS = 'ESTADO_CATEGORIA_FALLA_SUCCESS';
export const ESTADO_CATEGORIA_FALLA_FAILURE = 'ESTADO_CATEGORIA_FALLA_FAILURE';

export const SHOW_MODAL_NUEVA_CATEGORIA_FALLA = 'SHOW_MODAL_NUEVA_CATEGORIA_FALLA';
export const SHOW_MODAL_EDITAR_CATEGORIA_FALLA = 'SHOW_MODAL_EDITAR_CATEGORIA_FALLA';

export const EXPORTAR_EXCEL_TIEMPOS_REHABILITACION_REQUEST = 'EXPORTAR_EXCEL_TIEMPOS_REHABILITACION_REQUEST';
export const EXPORTAR_EXCEL_TIEMPOS_REHABILITACION_SUCCESS = 'EXPORTAR_EXCEL_TIEMPOS_REHABILITACION_SUCCESS';
export const EXPORTAR_EXCEL_TIEMPOS_REHABILITACION_FAILURE = 'EXPORTAR_EXCEL_TIEMPOS_REHABILITACION_FAILURE';

// Ubicaciones 
export const LISTA_UBICACIONES_TODAS_BUSQUEDA_REQUEST = 'LISTA_UBICACIONES_TODAS_BUSQUEDA_REQUEST';
export const LISTA_UBICACIONES_TODAS_BUSQUEDA_SUCCESS = 'LISTA_UBICACIONES_TODAS_BUSQUEDA_SUCCESS';
export const LISTA_UBICACIONES_TODAS_BUSQUEDA_FAILURE = 'LISTA_UBICACIONES_TODAS_BUSQUEDA_FAILURE';

export const LISTA_UBICACIONES_REQUEST = 'LISTA_UBICACIONES_REQUEST';
export const LISTA_UBICACIONES_SUCCESS = 'LISTA_UBICACIONES_SUCCESS';
export const LISTA_UBICACIONES_FAILURE = 'LISTA_UBICACIONES_FAILURE';

export const LISTA_UBICACIONES_FILTRO_REQUEST = 'LISTA_UBICACIONES_FILTRO_REQUEST';
export const LISTA_UBICACIONES_FILTRO_SUCCESS = 'LISTA_UBICACIONES_FILTRO_SUCCESS';
export const LISTA_UBICACIONES_FILTRO_FAILURE = 'LISTA_UBICACIONES_FILTRO_FAILURE';

export const LISTA_UBICACIONES_BUSQUEDA_REQUEST = 'LISTA_UBICACIONES_BUSQUEDA_REQUEST';
export const LISTA_UBICACIONES_BUSQUEDA_SUCCESS = 'LISTA_UBICACIONES_BUSQUEDA_SUCCESS';
export const LISTA_UBICACIONES_BUSQUEDA_FAILURE = 'LISTA_UBICACIONES_BUSQUEDA_FAILURE';

export const ESTADO_UBICACIONES_REQUEST = 'ESTADO_UBICACIONES_REQUEST';
export const ESTADO_UBICACIONES_SUCCESS = 'ESTADO_UBICACIONES_SUCCESS';
export const ESTADO_UBICACIONES_FAILURE = 'ESTADO_UBICACIONES_FAILURE';

export const SHOW_MODAL_NUEVO_UBICACION = 'SHOW_MODAL_NUEVO_UBICACION';
export const SHOW_MODAL_EDITAR_UBICACION = 'SHOW_MODAL_EDITAR_UBICACION';

export const NUEVO_UBICACIONES_REQUEST = 'NUEVO_UBICACIONES_REQUEST';
export const NUEVO_UBICACIONES_SUCCESS = 'NUEVO_UBICACIONES_SUCCESS';
export const NUEVO_UBICACIONES_FAILURE = 'NUEVO_UBICACIONES_FAILURE';

export const EDITAR_UBICACIONES_REQUEST = 'EDITAR_UBICACIONES_REQUEST';
export const EDITAR_UBICACIONES_SUCCESS = 'EDITAR_UBICACIONES_SUCCESS';
export const EDITAR_UBICACIONES_FAILURE = 'EDITAR_UBICACIONES_FAILURE';

export const LISTA_UBICACIONES_ACTIVOS_REQUEST = 'LISTA_UBICACIONES_ACTIVOS_REQUEST';
export const LISTA_UBICACIONES_ACTIVOS_SUCCESS = 'LISTA_UBICACIONES_ACTIVOS_SUCCESS';
export const LISTA_UBICACIONES_ACTIVOS_FAILURE = 'LISTA_UBICACIONES_ACTIVOS_FAILURE';

export const EXPORTAR_UBICACIONES_PDF_REQUEST = 'EXPORTAR_UBICACIONES_PDF_REQUEST';
export const EXPORTAR_UBICACIONES_PDF_SUCCESS = 'EXPORTAR_UBICACIONES_PDF_SUCCESS';
export const EXPORTAR_UBICACIONES_PDF_FAILURE = 'EXPORTAR_UBICACIONES_PDF_FAILURE';

export const EXPORTAR_UBICACIONES_EXCEL_REQUEST = 'EXPORTAR_UBICACIONES_EXCEL_REQUEST';
export const EXPORTAR_UBICACIONES_EXCEL_SUCCESS = 'EXPORTAR_UBICACIONES_EXCEL_SUCCESS';
export const EXPORTAR_UBICACIONES_EXCEL_FAILURE = 'EXPORTAR_UBICACIONES_EXCEL_FAILURE';

//PONDERACIONES ACTIONS
export const LISTA_PONDERACIONES_ACTIVAS_REQUEST = 'LISTA_PONDERACIONES_ACTIVAS_REQUEST';
export const LISTA_PONDERACIONES_ACTIVAS_SUCCESS = 'LISTA_PONDERACIONES_ACTIVAS_SUCCESS';
export const LISTA_PONDERACIONES_ACTIVAS_FAILURE = 'LISTA_PONDERACIONES_ACTIVAS_FAILURE';

export const LISTA_PONDERACIONES_BUSQUEDA_REQUEST = 'LISTA_PONDERACIONES_BUSQUEDA_REQUEST';
export const LISTA_PONDERACIONES_BUSQUEDA_SUCCESS = 'LISTA_PONDERACIONES_BUSQUEDA_SUCCESS';
export const LISTA_PONDERACIONES_BUSQUEDA_FAILURE = 'LISTA_PONDERACIONES_BUSQUEDA_FAILURE';

export const SHOW_MODAL_NUEVO_PONDERACION = 'SHOW_MODAL_NUEVO_PONDERACION';
export const SHOW_MODAL_EDITAR_PONDERACION = 'SHOW_MODAL_EDITAR_PONDERACION';

export const NUEVO_PONDERACION_REQUEST = 'NUEVO_PONDERACION_REQUEST';
export const NUEVO_PONDERACION_SUCCESS = 'NUEVO_PONDERACION_SUCCESS';
export const NUEVO_PONDERACION_FAILURE = 'NUEVO_PONDERACION_FAILURE';

export const EDITAR_PONDERACION_REQUEST = 'EDITAR_PONDERACION_REQUEST';
export const EDITAR_PONDERACION_SUCCESS = 'EDITAR_PONDERACION_SUCCESS';
export const EDITAR_PONDERACION_FAILURE = 'EDITAR_PONDERACION_FAILURE';

export const CAMBIAR_VISTA_PONDERACION = 'CAMBIAR_VISTA_PONDERACION';

//TIPO DE MONEDA
export const LISTA_TIPOS_MONEDAS_REQUEST = 'LISTA_TIPOS_MONEDAS_REQUEST';
export const LISTA_TIPOS_MONEDAS_SUCCESS = 'LISTA_TIPOS_MONEDAS_SUCCESS';
export const LISTA_TIPOS_MONEDAS_FAILURE = 'LISTA_TIPOS_MONEDAS_FAILURE';

export const ESTADO_TIPO_MONEDA_REQUEST = 'ESTADO_TIPO_MONEDA_REQUEST';
export const ESTADO_TIPO_MONEDA_SUCCESS = 'ESTADO_TIPO_MONEDA_SUCCESS';
export const ESTADO_TIPO_MONEDA_FAILURE = 'ESTADO_TIPO_MONEDA_FAILURE';

export const SHOW_MODAL_NUEVO_TIPO_MONEDA = 'SHOW_MODAL_NUEVO_TIPO_MONEDA';

export const NUEVO_TIPO_MONEDA_REQUEST = 'NUEVO_TIPO_MONEDA_REQUEST';
export const NUEVO_TIPO_MONEDA_SUCCESS = 'NUEVO_TIPO_MONEDA_SUCCESS';
export const NUEVO_TIPO_MONEDA_FAILURE = 'NUEVO_TIPO_MONEDA_FAILURE';

export const SHOW_MODAL_EDITAR_TIPO_MONEDA = 'SHOW_MODAL_EDITAR_TIPO_MONEDA';

export const EDITAR_TIPO_MONEDA_REQUEST = 'EDITAR_TIPO_MONEDA_REQUEST';
export const EDITAR_TIPO_MONEDA_SUCCESS = 'EDITAR_TIPO_MONEDA_SUCCESS';
export const EDITAR_TIPO_MONEDA_FAILURE = 'EDITAR_TIPO_MONEDA_FAILURE';

export const LISTA_TIPOS_MONEDAS_ACTIVOS_REQUEST = 'LISTA_TIPOS_MONEDAS_ACTIVOS_REQUEST';
export const LISTA_TIPOS_MONEDAS_ACTIVOS_SUCCESS = 'LISTA_TIPOS_MONEDAS_ACTIVOS_SUCCESS';
export const LISTA_TIPOS_MONEDAS_ACTIVOS_FAILURE = 'LISTA_TIPOS_MONEDAS_ACTIVOS_FAILURE';

export const EXPORTAR_EXCEL_TIPO_MONEDA_REQUEST = 'EXPORTAR_EXCEL_TIPO_MONEDA_REQUEST';
export const EXPORTAR_EXCEL_TIPO_MONEDA_SUCCESS = 'EXPORTAR_EXCEL_TIPO_MONEDA_SUCCESS';
export const EXPORTAR_EXCEL_TIPO_MONEDA_FAILURE = 'EXPORTAR_EXCEL_TIPO_MONEDA_FAILURE';

//TIPO DE CAMBIO
export const LISTA_TIPO_CAMBIO_REQUEST = 'LISTA_TIPO_CAMBIO_REQUEST';
export const LISTA_TIPO_CAMBIO_SUCCESS = 'LISTA_TIPO_CAMBIO_SUCCESS';
export const LISTA_TIPO_CAMBIO_FAILURE = 'LISTA_TIPO_CAMBIO_FAILURE';

export const NUEVO_TIPO_CAMBIO_REQUEST = 'NUEVO_TIPO_CAMBIO_REQUEST';
export const NUEVO_TIPO_CAMBIO_SUCCESS = 'NUEVO_TIPO_CAMBIO_SUCCESS';
export const NUEVO_TIPO_CAMBIO_FAILURE = 'NUEVO_TIPO_CAMBIO_FAILURE';

export const SHOW_MODAL_NUEVO_TIPO_CAMBIO = 'SHOW_MODAL_NUEVO_TIPO_CAMBIO';
export const SHOW_MODAL_EDITAR_TIPO_CAMBIO = 'SHOW_MODAL_EDITAR_TIPO_CAMBIO';

export const EDITAR_TIPO_CAMBIO_REQUEST = 'EDITAR_TIPO_CAMBIO_REQUEST';
export const EDITAR_TIPO_CAMBIO_SUCCESS = 'EDITAR_TIPO_CAMBIO_SUCCESS';
export const EDITAR_TIPO_CAMBIO_FAILURE = 'EDITAR_TIPO_CAMBIO_FAILURE';

export const EXPORTAR_EXCEL_TIPO_CAMBIO_REQUEST = 'EXPORTAR_EXCEL_TIPO_CAMBIO_REQUEST';
export const EXPORTAR_EXCEL_TIPO_CAMBIO_SUCCESS = 'EXPORTAR_EXCEL_TIPO_CAMBIO_SUCCESS';
export const EXPORTAR_EXCEL_TIPO_CAMBIO_FAILURE = 'EXPORTAR_EXCEL_TIPO_CAMBIO_FAILURE';