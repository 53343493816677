import React from 'react';
import { connect } from 'react-redux';
import { Table, Col, Switch, Modal, Divider, Row, Spin, Radio } from 'antd';
import { EditOutlined, FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalEditar from './ModalEditarRegistro';
import moment from 'moment';

const { confirm } = Modal;

class RegistroReportesContainer extends React.Component {
     state = {
          page: 1,
          busqueda: null,
          personal: false
     }

     componentDidMount () { this.props.onListaRegistrosReporte(1); this.props.onListaRegistrosEspecialReporte(1); this.props.onGetServiciosActivos(); this.props.onGerencias(); this.props.onGetProveedores(); }

     render () {
          const {onListaRegistrosReporte, onListaRegistrosEspecialReporte, onEstadoRegistroReporte,
               onModalEditarRegistroReporte, fetchingExportarReportesDinamico,
          } = this.props;

               
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if (permisosUsuario[0]) {
               permisosUsuario = JSON.parse(permisosUsuario);
          }

          const columns = [{
               title: 'Título del documento',
               dataIndex: 'titulo',
               key: 'titulo',
               width: '15%',
          },{
               title: 'Nombre',
               dataIndex: 'nombre',
               key: 'nombre',
               width: '20%',
          },{
               title: 'Descripción',
               dataIndex: 'descripcion',
               key: 'descripcion',
               width: '30%',
          },{ 
               title: 'Usuario',
               dataIndex: 'usuario',
               key: 'usuario',
               width: '15%',
          },{
               title: 'Editar',
               key: 'editar',
               width: '5%',
               align: 'center',
               render: (text, record) => (
                    <a className='editarIcono' disabled={this.state.personal ? true : !record.activo}   onClick={() => onEditar(record)}  ><EditOutlined /></a>
               ),
          }, {
               title: 'Exportar',
               key: 'exportar',
               width: '10%',
               align: 'center',
               render: (text, record) => (
                    <Row justify='space-around'>
                         <Col span={6}>
                         <a className='editarIcono' disabled={!permisosUsuario.exportarPdfReporteDinamicoRecursosHumanos ? false : !record.activo} onClick={() => onExportar(record, 'pdf')}><FilePdfOutlined /></a>
                         </Col>
                         <Col span={6}>
                         <a className='editarIcono' disabled={!permisosUsuario.exportarExcelReporteDinamicoRecursosHumanos ? false : !record.activo} onClick={() => onExportar(record, 'excel')}><FileExcelOutlined /></a>
                         </Col>
                    </Row>
               ),
          }, {
               title: 'Activo',
               dataIndex: 'activo',
               key: 'activo',
               width: '5%',
                    align: 'center',
               render: (text, record) => (
                    <Switch checked={text} disabled={this.state.personal} onChange = {() => handleDesactivar(record)} />
               ),
          }];

          const onExportar = (value, tipo) => {
               console.log(`value, tipo`, value, tipo);
               let order = { order: value.order, field: value.field }

               if(value.formulario.horarioEntrada){
                    value.formulario.horarioEntrada = value.formulario.horarioEntrada[0];
               }

               if(value.formulario.horarioSalida){
                    value.formulario.horarioSalida = value.formulario.horarioSalida[0];
               }

               if(value.formulario.regresoComida){
                    value.formulario.regresoComida = value.formulario.regresoComida[0];
               }

               if(value.formulario.salidaComida){
                    value.formulario.salidaComida = value.formulario.salidaComida[0];
               }

               if(value.tipoAgrupado === 'SinAgrupar'){
                    if(tipo === 'pdf'){
                         this.props.onDescargarReportePDF(value.formulario, value.checkbox, order);
                    }
                    else{
                         this.props.onDescargarReporteExcel(value.formulario, value.checkbox, order);
                    }
               } else {
                    if(tipo === 'pdf'){
                         this.props.onDescargarReporteAgrupadoPDF(value.formulario, value.checkbox, value.tipoAgrupado, order);
                    }
                    else{
                         this.props.onDescargarReporteAgrupadoExcel(value.formulario, value.checkbox, value.tipoAgrupado, order);
                    }
               }
              
          }

          const handleDesactivar = (key) => {
               console.log('key', key)

               let page = this.state.page;
               let personal = this.state.personal;
               confirm({
                    title: '¿Estás seguro que deseas cambiar el estado del reporte predeterminado "' + key.nombre + '"?',
                    content: '',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                         onEstadoRegistroReporte(key.idReportePersonal, page, personal);
                    },
                    onCancel() {
                         console.log('Cancel');
                    },
               });
          }
        
          const onEditar = (registroReporteSeleccionado) => {
               console.log('registroReporteSeleccionado', registroReporteSeleccionado);
               const IdsGerencias = [];
               const IdsGruposServicios = [];
               const IdsProveedores = [];
               const IdsServicios = [];
               
               if(registroReporteSeleccionado.formulario.IdsGerencias){
                    for(let i in this.props.listaClasificacionesActivos){
                         for(let j in registroReporteSeleccionado.formulario.IdsGerencias){
                              if(this.props.listaClasificacionesActivos[i].idClasificacionGerencia === parseInt(registroReporteSeleccionado.formulario.IdsGerencias[j])){
                                   //registroReporteSeleccionado.IdsGerencias = this.props.listaClasificacionesActivos[i].idClasificacionGerencia + ' - ' + this.props.listaClasificacionesActivos[i].nombreGerencia;
                                   IdsGerencias.push(this.props.listaClasificacionesActivos[i].idClasificacionGerencia + ' - ' + this.props.listaClasificacionesActivos[i].nombreGerencia);
                              }
                         }
                    }
                    registroReporteSeleccionado.IdsGerencias = IdsGerencias;
                    this.props.onListaServiciosByIdsGerencias(IdsGerencias);
               }

               if(registroReporteSeleccionado.formulario.IdsGruposServicios){
                    for(let i in this.props.listaGruposServicios){
                         for(let j in registroReporteSeleccionado.formulario.IdsGruposServicios){
                              if(this.props.listaGruposServicios[i].idGrupoSer === parseInt(registroReporteSeleccionado.formulario.IdsGruposServicios[j])){
                                   //registroReporteSeleccionado.idsGruposServicios = this.props.listaGruposServicios[i].idGrupoSer + ' - ' + this.props.listaGruposServicios[i].nombre + ': ' + this.props.listaGruposServicios[i].descripcion;
                                   IdsGruposServicios.push(this.props.listaGruposServicios[i].idGrupoSer + ' - ' + this.props.listaGruposServicios[i].nombre + ': ' + this.props.listaGruposServicios[i].descripcion);
                              }
                         }
                    }
                    registroReporteSeleccionado.idsGruposServicios = IdsGruposServicios;
                    this.props.onListaServiciosByIdsGrupos(IdsGruposServicios);
               }

               if(registroReporteSeleccionado.formulario.IdsServicios){
                    for(let i in this.props.listaServiciosActivosF){
                         for(let j in registroReporteSeleccionado.formulario.IdsServicios){
                              if(this.props.listaServiciosActivosF[i].idServicio === parseInt(registroReporteSeleccionado.formulario.IdsServicios[j])){
                                   //registroReporteSeleccionado.IdsServicios = this.props.listaServiciosActivosF[i].idServicio + ' - ' + this.props.listaServiciosActivosF[i].nombre;
                                   IdsServicios.push(this.props.listaServiciosActivosF[i].idServicio + ' - ' + this.props.listaServiciosActivosF[i].nombre);
                              }
                         }
                    }
                    registroReporteSeleccionado.IdsServicios = IdsServicios;
               }

               if(registroReporteSeleccionado.formulario.IdsProveedores){
                    for(let i in this.props.listaProveedores){
                         for(let j in registroReporteSeleccionado.formulario.IdsProveedores){
                              if(this.props.listaProveedores[i].idProveedor === parseInt(registroReporteSeleccionado.formulario.IdsProveedores[j])){
                                   //registroReporteSeleccionado.idsProveedores = this.props.listaProveedores[i].idProveedor + ' - ' + this.props.listaProveedores[i].razonSocial + ' (' + this.props.listaProveedores[i].nombre + ')';
                                   IdsProveedores.push(this.props.listaProveedores[i].idProveedor + ' - ' + this.props.listaProveedores[i].razonSocial + ' (' + this.props.listaProveedores[i].nombre + ')');
                              }
                         }
                    }
                    registroReporteSeleccionado.IdsProveedores = IdsProveedores;
               }

               registroReporteSeleccionado.activoCondicion = registroReporteSeleccionado.formulario.activo ? registroReporteSeleccionado.formulario.activo : undefined;
               registroReporteSeleccionado.tipoHorario = registroReporteSeleccionado.formulario.tipoTurno ? registroReporteSeleccionado.formulario.tipoTurno : undefined;
               
               // registroReporteSeleccionado.horarioEntrada = registroReporteSeleccionado.formulario.horarioEntrada ? moment(registroReporteSeleccionado.formulario.horarioEntrada[0], 'HH:mm:ss') : undefined;

               if(registroReporteSeleccionado.formulario.horarioEntrada){
                    if(typeof registroReporteSeleccionado.formulario.horarioEntrada === "string"){
                         registroReporteSeleccionado.horarioEntrada = registroReporteSeleccionado.formulario.horarioEntrada;
                    } else if(typeof registroReporteSeleccionado.formulario.horarioEntrada === "object"){
                         registroReporteSeleccionado.horarioEntrada = moment(registroReporteSeleccionado.formulario.horarioEntrada[0], 'HH:mm:ss')
                    }
               }

               // registroReporteSeleccionado.horarioSalida = registroReporteSeleccionado.formulario.horarioSalida ? moment(registroReporteSeleccionado.formulario.horarioSalida[0], 'HH:mm:ss') : undefined;

               if(registroReporteSeleccionado.formulario.horarioSalida){
                    if(typeof registroReporteSeleccionado.formulario.horarioSalida === "string"){
                         registroReporteSeleccionado.horarioSalida = registroReporteSeleccionado.formulario.horarioSalida;
                    } else if(typeof registroReporteSeleccionado.formulario.horarioSalida === "object"){
                         registroReporteSeleccionado.horarioSalida = moment(registroReporteSeleccionado.formulario.horarioSalida[0], 'HH:mm:ss')
                    }
               }

               // registroReporteSeleccionado.salidaComida = registroReporteSeleccionado.formulario.salidaComida ? moment(registroReporteSeleccionado.formulario.salidaComida[0], 'HH:mm:ss') : undefined;

               if(registroReporteSeleccionado.formulario.salidaComida){
                    if(typeof registroReporteSeleccionado.formulario.salidaComida === "string"){
                         registroReporteSeleccionado.salidaComida = registroReporteSeleccionado.formulario.salidaComida;
                    } else if(typeof registroReporteSeleccionado.formulario.salidaComida === "object"){
                         registroReporteSeleccionado.salidaComida = moment(registroReporteSeleccionado.formulario.salidaComida[0], 'HH:mm:ss')
                    }
               }

               // registroReporteSeleccionado.regresoComida = registroReporteSeleccionado.formulario.regresoComida ? moment(registroReporteSeleccionado.formulario.regresoComida[0], 'HH:mm:ss') : undefined;

               if(registroReporteSeleccionado.formulario.regresoComida){
                    if(typeof registroReporteSeleccionado.formulario.regresoComida === "string"){
                         registroReporteSeleccionado.regresoComida = registroReporteSeleccionado.formulario.regresoComida;
                    } else if(typeof registroReporteSeleccionado.formulario.regresoComida === "object"){
                         registroReporteSeleccionado.regresoComida = moment(registroReporteSeleccionado.formulario.regresoComida[0], 'HH:mm:ss')
                    }
               }

               registroReporteSeleccionado.fechaIngreso = registroReporteSeleccionado.formulario.fechaIngresoFin ? [moment(registroReporteSeleccionado.formulario.fechaIngresoInicio, 'YYYY-MM-DD'), moment(registroReporteSeleccionado.formulario.fechaIngresoFin, 'YYYY-MM-DD')] : undefined;

               
               onModalEditarRegistroReporte(registroReporteSeleccionado, this.state.page, this.state.personal);
          }

          const handleTableChange = (pagination) => {
               this.setState({
                    page: pagination.current,
               });
               if(!this.state.personal){
                    onListaRegistrosEspecialReporte(pagination.current);
               }
               else{
                    onListaRegistrosReporte(pagination.current);
               }
          }
     
          const cambiarTablaRegistros = (value) => {
               console.log(`value`, value)
               if(value.target.value === 'Todos'){
                    this.setState({
                         page: 1,
                         personal: true
                    });
                    
                    onListaRegistrosReporte(1)
               }
               else{
                    this.setState({
                         page: 1,
                         personal: false
                    });
                    onListaRegistrosEspecialReporte(1)
               }
          }

          return (
               <div>
                    <Divider orientation='left'>Registros Guardados</Divider>
                         <Row justify='end' style={{padding:'1em'}}>
                              <Radio.Group defaultValue='Personal' onChange={cambiarTablaRegistros}  size="small">
                                   {permisosUsuario.especialRecursosHumanos ? (
                                   <Radio.Button value="Todos">Todos</Radio.Button>
                                   ):false}
                                   <Radio.Button value="Personal">Personal</Radio.Button>
                              </Radio.Group>
                         </Row>
                    <Spin spinning={fetchingExportarReportesDinamico || this.props.fetchingReporteDinamicoEXCEL || this.props.fetchingReporteDinamicoPDF || this.props.fetchingReporteDinamicoAgrupadoEXCEL || this.props.fetchingReporteDinamicoAgrupadoPDF}>
                         <Col span={24}>
                              <Table
                                   size='small'
                                   rowKey='idReporteEvaluacion'
                                   columns={columns}
                                   dataSource={this.state.personal ? this.props.allRegistros : this.props.registrosByToken }
                                   loading={this.state.personal ? this.props.fetchingGetAllRegistros : this.props.fetchingGetRegistrosByToken }
                                   locale={{ emptyText: 'Sin datos' }}
                                   onChange={handleTableChange}
                                   pagination={{ total: this.state.personal ? this.props.allRegistrosCantidad : this.props.registrosByTokenCantidad, simple: true, current: this.state.page }}
                              />
                         </Col>
                    </Spin>
                    <ModalEditar />
               </div>
          )
     }
}

const mapStateToProps = state => {
     return {
          allRegistros: state.RecursosHumanosReducer.allRegistros,
          allRegistrosCantidad: state.RecursosHumanosReducer.allRegistrosCantidad,
          fetchingGetAllRegistros: state.RecursosHumanosReducer.fetchingGetAllRegistros,

          registrosByToken: state.RecursosHumanosReducer.registrosByToken,
          registrosByTokenCantidad: state.RecursosHumanosReducer.registrosByTokenCantidad,
          fetchingGetRegistrosByToken: state.RecursosHumanosReducer.fetchingGetRegistrosByToken,

          fetchingExportarReportesDinamico: state.RecursosHumanosReducer.fetchingExportarReportesDinamico,

          listaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.listaClasificacionesActivos,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,
          listaProveedores: state.InformacionOperativaReducer.listaProveedores,

          fetchingReporteDinamicoEXCEL: state.RecursosHumanosReducer.fetchingReporteDinamicoEXCEL,
          fetchingReporteDinamicoPDF: state.RecursosHumanosReducer.fetchingReporteDinamicoPDF,
          fetchingReporteDinamicoAgrupadoEXCEL: state.RecursosHumanosReducer.fetchingReporteDinamicoAgrupadoEXCEL,
          fetchingReporteDinamicoAgrupadoPDF: state.RecursosHumanosReducer.fetchingReporteDinamicoAgrupadoPDF,
     }
}

const mapDispatchToProps = dispatch => {
     return {
          onListaRegistrosReporte: (page) => {
               dispatch({ type: 'GET_ALL_REGISTROS_REQUEST', page })
          },
          onListaRegistrosEspecialReporte: (page) => {
               dispatch({ type: 'GET_REGISTROS_BY_TOKEN_REQUEST', page })
          },
          onEstadoRegistroReporte: (idReporte, page, personal) => {
               dispatch({ type: "ESTADO_REGISTROS_REQUEST", idReporte, page, personal });
          },
          onModalEditarRegistroReporte: (registroReporteSeleccionado, page, personal) => {
               dispatch({ type: "MODAL_EDITAR_REGISTRO", showModalEditarRegistro: true, registroReporteSeleccionado, page, personal });
          },
          onDescargarReportePDF: (formulario, checkboxData, order) => {
               dispatch({ type: 'PDF_REPORTE_DINAMICO_RH_REQUEST', formulario, checkboxData, order, containerRegistros: true});
          },
          onDescargarReporteExcel: (formulario, checkboxData, order) => {
               dispatch({ type: 'EXCEL_REPORTE_DINAMICO_RH_REQUEST', formulario,checkboxData, order, containerRegistros: true});
          },
          onDescargarReporteAgrupadoPDF: (formulario, checkboxData, agrupado, order) => {
               dispatch({ type: 'PDF_REPORTE_DINAMICO_RH_AGRUPADO_REQUEST', formulario, checkboxData, agrupado, order, containerRegistros: true });
          },
          onDescargarReporteAgrupadoExcel: (formulario, checkboxData, agrupado, order) => {
               dispatch({ type: 'EXCEL_REPORTE_DINAMICO_RH_AGRUPADO_REQUEST', formulario, checkboxData, agrupado, order, containerRegistros: true });
          },
          onGetServiciosActivos: () => {
               dispatch({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST'})
          },
          onGerencias: () => {
               dispatch({ type: 'LISTA_CLASIFICACION_ACTIVOS_REQUEST' })
          },
          onListaServiciosByIdsGerencias: (idsGerencias) => {
               dispatch({ type: 'LISTA_SERVICIOS_BY_IDGERENCIAS_REQUEST', idsGerencias });
          },
          onListaServiciosByIdsGrupos: (idsGruposSer) => {
               dispatch({ type: 'LISTA_SERVICIOS_BY_IDSGRUPOS_REQUEST', idsGruposSer });
          },
          onGetProveedores: () => {
               dispatch({ type: 'GET_PROVEEDORES_REQUEST' })
          },
     }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistroReportesContainer);