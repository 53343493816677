import React from 'react';
import { Form, Row, Col, Table, Tooltip, Button, Typography} from 'antd';
import { connect } from "react-redux"; 
import '../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import {  FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Equation,  defaultErrorHandler  } from "react-equation";

const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
const { Text } = Typography

class ResumenCalculoPMR extends React.Component {
     formRef = React.createRef();
     
     componentDidMount(){ this.props.onGetResumen(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP); }

     state={ }
 
     render() {
          const { resumenCalculo } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){ permisosUsuario = JSON.parse(permisosUsuario); }

          const onFinish = (formulario) => {
               console.log('Success:', formulario);
          };

          const onExportar = (e) => {
               if(e === 'pdf'){
                    this.props.onGetPDF(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, this.props.mesTexto);
               } else {
                    this.props.onGetEXCEL(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, this.props.mesTexto);
               }
          };

          return (<>
               <Form {...layout} name="formulario" ref={this.formRef} onFinish={onFinish}>
                    <Row justify='space-around' gutter={[0,16]}>
                         <Col span={20}>
                              <div style={{textAlign:'center'}}>
                                   <Text strong style={{ fontSize: 16 }}> VALORES PARA EL CÁLCULO DEL PAGO MENSUAL DE REFERENCIA PARA {this.props.mesTexto.toUpperCase()} {this.props.anioSeleccionadoRMDP} </Text>
                              </div>
                         </Col>
                         <Col span={1}>
                              <Tooltip title='Exportar resumen para el cálculo del PMR .pdf'>
                                   <Button className="iconSecundary" shape="circle" loading={this.props.fetchingResumenCalculoPDF} icon={<FilePdfOutlined />} onClick={(e) => { onExportar('pdf'); }}/>
                              </Tooltip>
                         </Col>
                         <Col span={1}>
                              <Tooltip title='Exportar resumen para el cálculo del PMR .xls'>
                                   <Button className="iconSecundary" shape="circle" loading={this.props.fetchingResumenCalculoEXCEL} icon={<FileExcelOutlined />} onClick={(e) => { onExportar('xls'); }}/>
                              </Tooltip>
                         </Col>

                         <Col span={24}>
                              <div style={{textAlign:'center'}}>
                                   <Text style={{fontSize: 24}}>
                                        <Equation
                                             value="PMR_i = [PAR_f * ((INPC_i-1) / INPC_0) * (dm_i/da_i)] + [PAR_v * ((INPC_i-1) / INPC_0) * (dm_i/da_i) * (P_vi/P_tv)]"
                                             errorHandler={defaultErrorHandler}
                                        />
                                   </Text>
                              </div>
                         </Col>
                         <Col span={24}>
                              <Table
                                   size="small"
                                   rowKey="idMesCalendario"
                                   columns={resumenCalculo.columns}
                                   dataSource={this.props.resumenCalculo.dataTablePMR}
                                   loading={this.props.fetchingResumenCalculo}
                                   pagination={false}
                              />
                         </Col>
                    </Row>
               </Form>
          </>);
     }
}
 
 const mapStateToProps = state => {
     return {
          anioSeleccionadoRMDP: state.RMDPReducer.anioSeleccionadoRMDP,
          mesTexto: state.RMDPReducer.mesTexto,
          mesSeleccionadoRMDP: state.RMDPReducer.mesSeleccionadoRMDP,
          fetchingResumenCalculo: state.RMDPReducer.fetchingResumenCalculo,
          resumenCalculo: state.RMDPReducer.resumenCalculo,
          fetchingResumenCalculoPDF: state.RMDPReducer.fetchingResumenCalculoPDF,
          fetchingResumenCalculoEXCEL: state.RMDPReducer.fetchingResumenCalculoEXCEL,
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          onGetResumen: (idMes, anio) => {
               dispatch({ type: 'RESUMEN_CALCULO_REQUEST', idMes, anio });
          },
          onGetPDF: (idMes, anio, mesTxt) => {
               dispatch({ type: 'PDF_RESUMEN_CALCULO_REQUEST', idMes, anio, mesTxt, selector: false})
          },
          onGetEXCEL: (idMes, anio, mesTxt) => {
               dispatch({ type: 'EXCEL_RESUMEN_CALCULO_REQUEST', idMes, anio, mesTxt, selector: false})
          },
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(ResumenCalculoPMR);