import { takeLatest, call, put } from "redux-saga/effects";
import * as almacenesActions from '../actions/AlmacenesActions';
import * as configuracionYSeguridadActions from '../actions/ConfiguracionYSeguridadActions';
import listCurrencies from '../../components/CatalogosGenerales/Currencies.json';
import {
    listaUnidadesDeMedicionActivosCall, getUnidadesDeMedicionPageCall, crearUnidadDeMedicionCall, editarUnidadDeMedicionCall, estadoUnidadDeMedicionCall, listaUnidadesMediPorCategoriaCall, exportarExcelUnidadesMedicionCall,

    listaCategoriasDeMedicionActivosCall, getCategoriasDeMedicionPageCall, crearCategoriaDeMedicionCall, editarCategoriaDeMedicionCall, estadoCategoriaDeMedicionCall, exportarExcelCategoriasMedicionCall,

    listaActivosCaractCall, listaActivosCaractBusquedaCall, estadoActivoCaractCall, crearActivoCaractCall, editarActivoCaractCall, listaAsignacionActivosBusquedaCall, crearAsignacionActivosCall, exportarExcelCaracteristicasActivosCall,

    listaActivosBusquedaCall, estadoActivoCall, crearActivoCall, editarActivoCall, exportarExcelActivosCall,
    
    getAllAreasCall, listaUnidadesFuncionalesActivasCall, listaUsuariosCall, listaInterioresFuncionalesCall, listaActivosBusquedaAUISCall, listaSubinterioresFuncionalesCall, getAllAreasExCall, listaUnidadesFuncionalesActivasExCall, listaInterioresFuncionalesExCall, listaSubinterioresFuncionalesExCall, 

    listaEstatusActivoActivosCall, getEstatusActivosPageCall, crearEstatusActivoCall, editarEstatusActivoCall, estadoEstatusActivoCall, exportarExcelEstatusActivoCall,

    listaRangosRenovacionActivosCall, getRangosRenovacionPageCall, crearRangoRenovacionCall, editarRangoRenovacionCall, estadoRangoRenovacionCall, exportarExcelRangosRenovacionActivoCall,

    listaActivosCaractFiltroCall, listaActivosCaractFiltroAsignacionSPCall, listaMotivosBusquedaCall, cambiarEstadoMotivosCall, crearMotivosCall, editarMotivosCall, listaMotivosActivosCall, exportarExcelMotivosSalidaActivosCall,

    getListaActualizacionActivosPageCall, ActualizacionActivosDescargaArchivoCall,
    generadorFolioSalidaCall, listaSalidaDeBienesBusquedaCall, crearSalidaDeBienesCall, listaActivosEnOperacionServicioCall,
    archivosEsperaActivosCall, subirArchivoActivoCall, eliminarArchivoActivoCall, exportarErroresArchivoActivoCall, procesarArchivoActivoCall,

    listarReingresoActivosBusquedaCall, crearReingresoActivoCall,  crearReingresoActivoSinAsignacionCall, ReingresofoliosActivosCall,

    listaTiposFoliosMovimientosActivosCall, getTiposFoliosMovimientosPageCall, crearTipoFolioMovimientoCall, editarTipoFolioMovimientoCall, estadoTipoFolioMovimientoCall, exportarExcelTipoFolioCall,

    listaProductosActivosCall, listaProductosActivosBusquedaCall, getProductosPageCall, crearProductoCall, editarProductoCall, estadoProductoCall, exportarExcelProductosCall,

    listaMovimientosGeneralBusquedaCall, listaMovimientosConsultaCall, cancelarFolioCall,listaInventarioConsultaCall, listaInventarioTipoActivoCall,listaRequisicionDirectaConsultaCall, imprimirFolioCall, ubicacionPorAlmacenCall,obtenerInfoIdInventarioCall,obtenerAlmacenPorUbicacionCall, generarFolioCall,cerrarFolioCall,cancelarProductoCall,agregarProductosEdicionCall, salidaInstalacionCall,
    editarFolioCall,cerrarFolioSinSalidaCall, catalogoPuntoReordenBusquedaCall, generarFolioRequisicionPuntoReordenCall,consultaFolioCall, editarProductoRequisicionPuntoReordenCall, cerrarFolioRequisicionPuntoReordenCall, descargarFolioRequisicionPuntoReordenCall, cancelarFolioPuntoReordenCall, listaProductosFiltradosConsultaCall,listaInventarioFiltradosConsultaCall,listaRequisicionFiltradosConsultaCall, descargarFolioRequisicionCompraCall,
    
    listaAlmacenesBusquedaCall, estadoAlmacenCall, crearAlmacenCall, editarAlmacenCall, listaAlmacenesActivosCall, exportarExcelAlmacenesCall,

    subirArchivoRequisicionCall, folioRequisicionCall,

    listaUbicacionesProductosActivosCall, getUbicacionesProductosPageCall, estadoUbicacionProductoCall, crearUbicacionProductoCall, editarUbicacionProductoCall, exportarExcelUbicacionesProductosCall,

    listaSubfamiliasFiltroCall, listaFamiliasFiltroCall, listaServiciosbyAlmacenCall, listaFamiliasbyServicioCall, listaSubfamiliasbyFamiliaCall,
    listaPisosByAlmacenCall, listaAreasByPisosCall, listaPasillosByAreasCall, listaRacksByPasillos, listaInventarioFisicoBusquedaCall,listaInventarioFisicoDescargaCall, listaPisosByFolioCall, listaAreasByFolioCall, listaPasillosByFolioCall, listaRacksByFolioCall, capturarConteoCall,
    listaFaltantesInventarioCall, listaFaltantesInventarioDescargaCall, CerrarFolioInventarioCall,
    
    listadoInventarioCiclicoCall, listaInventarioCiclicoDescargaCall, listaProductosByFolioCall, listaFiltroFolioCall, generarFolioCapturaCall, listaAlmacenesByFolioCall, productoExistenteCall, almacenesByProductoCall, obtenerKardexProductoCall, obtenerKardexMovimientosCall, kardexReporteCall, validarConteoCall,
    getFoliosVerCalidadCall, crearFolioVerificacionCalCall, detalleFolioVCCall, verificacionCalidadCall,imprimirPdfVerificacionCalidadCall, busquedaHerramientasCall, prestamoHerramientasCall, bajaHerramientasCall, obtenerFoliosPrestamosCall, devolucionHerramientasCall, obtenerFolioPrestamoCall, obtenerNumerosInventarioPrestadosCall,obtenerNumerosInventarioPorFolioCall, crearNumerosInventarioCall, obtenerNumerosInventarioDisponiblesCall,

    verificarSerieNumCall, descargarListaSeriesCall, descargarSalidaInstalacionCall,

    cargaMasivaIPHCall, getErroresCargaMasivaID, getListArchivosCargaMasiva, changeEstadoArchivos, cargaMasivaSeriesCall,

    cargaMasivaAlmacenCall, cargaMasivaSeriesAlmacenCall,

    getRequisicionesGeneradasCall, getProductosPageRequisicionMaterialesCall, registrarRequisicionMaterialesCall, editarRequisicionMaterialesCall, cambiarEstadoRequisicionCall, aprobarProductosRequisicionCall, descargarFolioRequisicionCall, productosFolioRequisicionCall, surtirProductosFolioRequisicionCall,

    reporteExistenciasCall, reporteExistenciasAgrupadoCall, reporteExistenciasExcelCall, reporteExistenciasPDFCall
} from '../../services/AlmacenesServices';
import { listaResponsablesPorServicioCall } from "../../services/ConfiguracionYSeguridadServices";
import moment from 'moment';

//CÁTALOGO CATEGORIAS DE MEDICION
function* listaCategoriasDeMedicionActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaCategoriasDeMedicionActivosCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaCategoriasDeMedicionActivos = response.data;

            yield put({ type: almacenesActions.LISTA_CATEGORIAS_MEDICION_ACTIVOS_SUCCESS, listaCategoriasDeMedicionActivos });
        }
        else {
            yield put({ type: almacenesActions.LISTA_CATEGORIAS_MEDICION_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_CATEGORIAS_MEDICION_ACTIVOS_FAILURE });
    }
}
export function* listaCategoriasDeMedicionActivosSaga() {
    yield takeLatest(almacenesActions.LISTA_CATEGORIAS_MEDICION_ACTIVOS_REQUEST, listaCategoriasDeMedicionActivos);
}

function* listaCategoriasDeMedicionBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let busqueda = '';
        if (action.busqueda) {
            console.log("ENTRA CON BUSQUEDA");
            busqueda = action.busqueda;
        }
        console.log(page);
        console.log(busqueda);
        const response = yield call(getCategoriasDeMedicionPageCall, token, page, busqueda);
        console.log(response);
        console.log(response.data.data)

        if (response.status == 200) {
            let listaCategoriasDeMedicionBusqueda = response.data.data;
            let listaCategoriasDeMedicionBusquedaCantidad = response.data.cantidad;
            for (let i = 0; i < listaCategoriasDeMedicionBusqueda.length; i++) {
                let fechaAlta = listaCategoriasDeMedicionBusqueda[i].fechaAlta.split('T')[0];
                listaCategoriasDeMedicionBusqueda[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
            }
            yield put({ type: almacenesActions.CATEGORIAS_MEDICION_BUSQUEDA_SUCCESS, listaCategoriasDeMedicionBusqueda, listaCategoriasDeMedicionBusquedaCantidad });
        }
        else {
            yield put({ type: almacenesActions.CATEGORIAS_MEDICION_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.CATEGORIAS_MEDICION_BUSQUEDA_FAILURE });
    }
}
export function* listaCategoriasDeMedicionBusquedaSaga() {
    yield takeLatest(almacenesActions.CATEGORIAS_MEDICION_BUSQUEDA_REQUEST, listaCategoriasDeMedicionBusqueda);
}

function* crearNuevaCategoriaDeMedicion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idCategoriaUnidadMedida": null,
            "categoriaUnidadMedida": action.formulario.categoriaUnidadMedida,
            "claveCategoria": action.formulario.claveCategoria,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }
        console.log(data);
        const response = yield call(crearCategoriaDeMedicionCall, token, data);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showModalNuevaCategoriaDeMedicion = false;
            const showSuccessMsg = true;
            const textMessage = "La categoria de medición " + action.formulario.categoriaUnidadMedida + " fue agregada con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "crearCategoria" });
            yield put({ type: almacenesActions.NUEVA_CATEGORIA_MEDICION_SUCCESS });
            yield put({ type: almacenesActions.CATEGORIAS_MEDICION_BUSQUEDA_REQUEST, page });
            yield put({ type: almacenesActions.SHOW_MODAL_NUEVA_CATEGORIA_MEDICION, showModalNuevaCategoriaDeMedicion });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.NUEVA_CATEGORIA_MEDICION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.NUEVA_CATEGORIA_MEDICION_FAILURE });
    }
}
export function* crearCategoriaDeMedicionSaga() {
    yield takeLatest(almacenesActions.NUEVA_CATEGORIA_MEDICION_REQUEST, crearNuevaCategoriaDeMedicion);
}

function* editarCategoriaDeMedicion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idCategoriaUnidadMedida = action.CategoriaDeMedicionSeleccionado.idCategoriaUnidadMedida;
        const data = {
            "idCategoriaUnidadMedida": null,
            "categoriaUnidadMedida": action.formulario.categoriaUnidadMedida,
            "claveCategoria": action.formulario.claveCategoria,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }
        console.log(data);
        const response = yield call(editarCategoriaDeMedicionCall, token, data, idCategoriaUnidadMedida);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showModalEditarCategoriaDeMedicion = false;
            const CategoriaDeMedicionSeleccionado = null;
            const limpiarFormulario = "editarCategoria";
            const showSuccessMsg = true;
            const textMessage = "La categoria de medición " + action.formulario.categoriaUnidadMedida + " fue actualizada con éxito";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario });
            yield put({ type: almacenesActions.EDITAR_CATEGORIA_MEDICION_SUCCESS });
            yield put({ type: almacenesActions.CATEGORIAS_MEDICION_BUSQUEDA_REQUEST, page });
            yield put({ type: almacenesActions.SHOW_MODAL_EDITAR_CATEGORIA_MEDICION, showModalEditarCategoriaDeMedicion, CategoriaDeMedicionSeleccionado });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message + " Modificar la categoria de medición.";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.EDITAR_CATEGORIA_MEDICION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.EDITAR_CATEGORIA_MEDICION_FAILURE });
    }
}
export function* editarCategoriaDeMedicionSaga() {
    yield takeLatest(almacenesActions.EDITAR_CATEGORIA_MEDICION_REQUEST, editarCategoriaDeMedicion);
}

//********************** Cambiar el estado de una Unidad de Medición *****************************
function* cambiarEstadoCategoriaDeMedicion(action) {
    try {
        console.log(action.idCategoriaUnidadMedida + "AQUI KEY");
        const token = sessionStorage.getItem('token');
        const idCategoriaUnidadMedida = action.idCategoriaUnidadMedida;

        const response = yield call(estadoCategoriaDeMedicionCall, token, idCategoriaUnidadMedida);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showSuccessMsg = true;
            const textMessage = "El estado de la categoria de medición se cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.ESTADO_CATEGORIA_MEDICION_SUCCESS });
            yield put({ type: almacenesActions.CATEGORIAS_MEDICION_BUSQUEDA_REQUEST, page });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.ESTADO_CATEGORIA_MEDICION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.ESTADO_CATEGORIA_MEDICION_FAILURE });
    }
}
export function* cambiarEstadoCategoriaDeMedicionSaga() {
    yield takeLatest(almacenesActions.ESTADO_CATEGORIA_MEDICION_REQUEST, cambiarEstadoCategoriaDeMedicion);
}

function* exportarExcelCategoriasMedicion(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(exportarExcelCategoriasMedicionCall, token);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el excel de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "CategoriasDeMedicion" + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_CATEGORIA_MEDICION_EXCEL_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el archivo";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_CATEGORIA_MEDICION_EXCEL_FAILURE });
        }
    } catch (error) {
       yield put({ type: almacenesActions.EXPORTAR_CATEGORIA_MEDICION_EXCEL_FAILURE });
    }
} export function* exportarExcelCategoriasMedicionSaga(){
    yield takeLatest(almacenesActions.EXPORTAR_CATEGORIA_MEDICION_EXCEL_REQUEST, exportarExcelCategoriasMedicion)
}

//CÁTALOGO UNIDADES DE MEDICION
function* listaUnidadesDeMedicionActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaUnidadesDeMedicionActivosCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaUnidadesDeMedicionActivos = response.data;

            yield put({ type: almacenesActions.LISTA_UNIDADES_MEDICION_ACTIVOS_SUCCESS, listaUnidadesDeMedicionActivos });
        }
        else {
            yield put({ type: almacenesActions.LISTA_UNIDADES_MEDICION_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_UNIDADES_MEDICION_ACTIVOS_FAILURE });
    }
}
export function* listaUnidadesDeMedicionActivosSaga() {
    yield takeLatest(almacenesActions.LISTA_UNIDADES_MEDICION_ACTIVOS_REQUEST, listaUnidadesDeMedicionActivos);
}

function* listaUnidadesDeMedicionBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let busqueda = '';
        if (action.busqueda) {
            console.log("ENTRA CON BUSQUEDA");
            busqueda = action.busqueda;
        }
        console.log(page);
        console.log(busqueda);
        const categoriaMedida = yield call(listaCategoriasDeMedicionActivosCall, token);
        console.log(categoriaMedida)
        const response = yield call(getUnidadesDeMedicionPageCall, token, page, busqueda);
        console.log(response);
        console.log(response.data.data)

        if (response.status == 200) {
            //Condición no data
            let listaCategoriasDeMedicion = categoriaMedida.data
            let listaUnidadesDeMedicionBusqueda = response.data.data;
            let listaUnidadesDeMedicionBusquedaCantidad = response.data.cantidad;
            for (let i = 0; i < listaUnidadesDeMedicionBusqueda.length; i++) {
                let fechaAlta = listaUnidadesDeMedicionBusqueda[i].fechaAlta.split('T')[0];
                listaUnidadesDeMedicionBusqueda[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
                for (let a = 0; a < listaCategoriasDeMedicion.length; a++) {
                    if (listaUnidadesDeMedicionBusqueda[i].idCategoriaUnidadMedida === listaCategoriasDeMedicion[a].idCategoriaUnidadMedida) {
                        listaUnidadesDeMedicionBusqueda[i].idCategoriaUnidadMedida = listaCategoriasDeMedicion[a].categoriaUnidadMedida;
                    }
                }
            }
            yield put({ type: almacenesActions.UNIDADES_MEDICION_BUSQUEDA_SUCCESS, listaUnidadesDeMedicionBusqueda, listaUnidadesDeMedicionBusquedaCantidad });
        }
        else {
            yield put({ type: almacenesActions.UNIDADES_MEDICION_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.UNIDADES_MEDICION_BUSQUEDA_FAILURE });
    }
}
export function* listaUnidadesDeMedicionBusquedaSaga() {
    yield takeLatest(almacenesActions.UNIDADES_MEDICION_BUSQUEDA_REQUEST, listaUnidadesDeMedicionBusqueda);
}

function* crearNuevaUnidadDeMedicion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idUnidadMedida": null,
            "unidadMedida": action.formulario.unidadMedida,
            "abreviacion": action.formulario.abreviacion,
            "idCategoriaUnidadMedida": parseInt(action.formulario.categoriaMedida), //por definirse,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }
        console.log(data);
        const response = yield call(crearUnidadDeMedicionCall, token, data);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showModalNuevaUnidadDeMedicion = false;
            const showSuccessMsg = true;
            const textMessage = "La unidad de medición " + action.formulario.unidadMedida + " fue agregada con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "crearUnidadMedicion" });
            yield put({ type: almacenesActions.NUEVA_UNIDAD_MEDICION_SUCCESS });
            yield put({ type: almacenesActions.UNIDADES_MEDICION_BUSQUEDA_REQUEST, page });
            yield put({ type: almacenesActions.SHOW_MODAL_NUEVA_UNIDAD_MEDICION, showModalNuevaUnidadDeMedicion });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.NUEVA_UNIDAD_MEDICION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.NUEVA_UNIDAD_MEDICION_FAILURE });
    }
}
export function* crearUnidadDeMedicionSaga() {
    yield takeLatest(almacenesActions.NUEVA_UNIDAD_MEDICION_REQUEST, crearNuevaUnidadDeMedicion);
}

function* editarUnidadDeMedicion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idUnidadMedida = action.UnidadDeMedicionSeleccionado.idUnidadMedida;
        let idCategoriaUnidadMedida = action.formulario.idCategoriaUnidadMedida;

        if (isNaN(idCategoriaUnidadMedida)) {
            for (let i = 0; i < action.listaCategoriasDeMedicionActivos.length; i++) {
                if (idCategoriaUnidadMedida === action.listaCategoriasDeMedicionActivos[i].categoriaUnidadMedida) {
                    idCategoriaUnidadMedida = action.listaCategoriasDeMedicionActivos[i].idCategoriaUnidadMedida
                }
            }
        }

        const data = {
            "idUnidadMedida": null,
            "unidadMedida": action.formulario.unidadMedida,
            "abreviacion": action.formulario.abreviacion,
            "idCategoriaUnidadMedida": parseInt(idCategoriaUnidadMedida),
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }
        console.log(data);
        const response = yield call(editarUnidadDeMedicionCall, token, data, idUnidadMedida);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showModalEditarUnidadDeMedicion = false;
            const UnidadDeMedicionSeleccionado = null;
            const limpiarFormulario = "editarUnidadMedicion";
            const showSuccessMsg = true;
            const textMessage = "La unidad de medición " + action.formulario.unidadMedida + " fue actualizada con éxito";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario });
            yield put({ type: almacenesActions.EDITAR_UNIDAD_MEDICION_SUCCESS });
            yield put({ type: almacenesActions.UNIDADES_MEDICION_BUSQUEDA_REQUEST, page });
            yield put({ type: almacenesActions.SHOW_MODAL_EDITAR_UNIDAD_MEDICION, showModalEditarUnidadDeMedicion, UnidadDeMedicionSeleccionado });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message + " Modificar la unidad de medición.";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.EDITAR_UNIDAD_MEDICION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.EDITAR_UNIDAD_MEDICION_FAILURE });
    }
}
export function* editarUnidadDeMedicionSagas() {
    yield takeLatest(almacenesActions.EDITAR_UNIDAD_MEDICION_REQUEST, editarUnidadDeMedicion);
}

//********************** Cambiar el estado de una Unidad de Medición *****************************
function* cambiarEstadoUnidadDeMedicion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idUnidadMedida = action.idUnidadMedida;

        const response = yield call(estadoUnidadDeMedicionCall, token, idUnidadMedida);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showSuccessMsg = true;
            const textMessage = "El estado de la unidad de medida se cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.ESTADO_UNIDADES_MEDICION_SUCCESS });
            yield put({ type: almacenesActions.UNIDADES_MEDICION_BUSQUEDA_REQUEST, page });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.ESTADO_UNIDADES_MEDICION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.ESTADO_UNIDADES_MEDICION_FAILURE });
    }
}
export function* cambiarEstadoUnidadDeMedicionSaga() {
    yield takeLatest(almacenesActions.ESTADO_UNIDADES_MEDICION_REQUEST, cambiarEstadoUnidadDeMedicion);
}

function* unidadesDeMediPorCategoria(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idCategoriaUnidadMedida = action.idCategoriaUnidadMedida;

        const response = yield call(listaUnidadesMediPorCategoriaCall, token, idCategoriaUnidadMedida);
        console.log(response);

        if (response.status == 200) {
            const unidadesMediPorCategoria = response.data;

            yield put({ type: almacenesActions.UNIDADES_MEDI_POR_CATEGORIA_SUCCESS, unidadesMediPorCategoria });
        }
        else {
            yield put({ type: almacenesActions.UNIDADES_MEDI_POR_CATEGORIA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.UNIDADES_MEDI_POR_CATEGORIA_FAILURE });
    }
}
export function* unidadesDeMediPorCategoriaSaga() {
    yield takeLatest(almacenesActions.UNIDADES_MEDI_POR_CATEGORIA_REQUEST, unidadesDeMediPorCategoria);
}

function* exportarExcelUnidadesMedicion(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(exportarExcelUnidadesMedicionCall, token);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el excel de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "UnidadesDeMedicion" + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_UNIDAD_MEDICION_EXCEL_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el archivo";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_UNIDAD_MEDICION_EXCEL_FAILURE });
        }
    } catch (error) {
       yield put({ type: almacenesActions.EXPORTAR_UNIDAD_MEDICION_EXCEL_FAILURE });
    }
} export function* exportarExcelUnidadesMedicionSaga(){
    yield takeLatest(almacenesActions.EXPORTAR_UNIDAD_MEDICION_EXCEL_REQUEST, exportarExcelUnidadesMedicion)
}

//********************** Lista de Expedientes para nuevo/editar proveedor *****************************
function* listaActivosCaract(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaActivosCaractCall, token);
        console.log(response);

        if (response.status == 200) {
            let listaActivosCaract = response.data;
            yield put({ type: almacenesActions.LISTA_ACTIVOS_CARACT_SUCCESS, listaActivosCaract });
        }
        else {
            yield put({ type: almacenesActions.LISTA_ACTIVOS_CARACT_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_ACTIVOS_CARACT_FAILURE });
    }
}
export function* listaActivosCaractSaga() {
    yield takeLatest(almacenesActions.LISTA_ACTIVOS_CARACT_REQUEST, listaActivosCaract);
}

//********************** Lista de Expedientes para Catálogo de Expedientes *****************************
function* listaActivosCaractBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let busqueda = '';
        if (action.busqueda) {
            busqueda = 'param=' + action.busqueda + '&';
        }
        console.log('page');
        console.log(page);
        console.log('busqueda');
        console.log(busqueda);

        let response = yield call(listaActivosCaractBusquedaCall, token, page, busqueda);
        console.log("response listaActivosCaractBusqueda",response);

        if (response.status == 200) {
            let listaActivosCaractBusqueda = response.data.data;
            const listaActivosCaractBusquedaCantidad = response.data.cantidad;
            for (let i = 0; i < listaActivosCaractBusqueda.length; i++) {
                let symbol = "$"
                let code = '';

                if(listaActivosCaractBusqueda[i].tipoMonedaByIdTipoMoneda){
                    listaActivosCaractBusqueda[i].tipoMoneda = listaActivosCaractBusqueda[i].tipoMonedaByIdTipoMoneda.nombre + ' (' + listaActivosCaractBusqueda[i].tipoMonedaByIdTipoMoneda.nomenclatura + ')';
                    listaActivosCaractBusqueda[i].tipoMonedaEditar = listaActivosCaractBusqueda[i].tipoMonedaByIdTipoMoneda.idTipoMoneda + ' - ' + listaActivosCaractBusqueda[i].tipoMonedaByIdTipoMoneda.nombre + ' (' + listaActivosCaractBusqueda[i].tipoMonedaByIdTipoMoneda.nomenclatura + ')';
                    code = listaActivosCaractBusqueda[i].tipoMonedaByIdTipoMoneda.nomenclatura;
                }

                if(listCurrencies.hasOwnProperty(code)){
                    symbol =  listCurrencies[code].symbol_native;
                }

                listaActivosCaractBusqueda[i].costoUnidad = listaActivosCaractBusqueda[i].productoByIdProducto.precio.toFixed(2);
                listaActivosCaractBusqueda[i].descripcion = listaActivosCaractBusqueda[i].productoByIdProducto.nombre;
                listaActivosCaractBusqueda[i].codigoAdicional = listaActivosCaractBusqueda[i].productoByIdProducto?.codigoAdicional
                listaActivosCaractBusqueda[i].marca = listaActivosCaractBusqueda[i].productoByIdProducto.marca;
                listaActivosCaractBusqueda[i].modelo = listaActivosCaractBusqueda[i].productoByIdProducto.modelo;
                listaActivosCaractBusqueda[i].fabricante = listaActivosCaractBusqueda[i].productoByIdProducto.fabricante;

                let a = listaActivosCaractBusqueda[i].costoUnidad.toString();
                console.log('a');
                console.log(a);
                listaActivosCaractBusqueda[i].costoUnidadTabla = symbol + ' ' + a.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                if(listaActivosCaractBusqueda[i].vidaUtilSugerida){
                    listaActivosCaractBusqueda[i].vidaSugerida = listaActivosCaractBusqueda[i].vidaUtilSugerida.split(' ')[0];
                    listaActivosCaractBusqueda[i].unidadMedidaSugerida = listaActivosCaractBusqueda[i].vidaUtilSugerida.split(' ')[1];
                }
                else {
                    listaActivosCaractBusqueda[i].vidaSugerida = '';
                    listaActivosCaractBusqueda[i].unidadMedidaSugerida = '';
                }
                if (listaActivosCaractBusqueda[i].unidadMedidaByIdUnidadMedida) {
                    listaActivosCaractBusqueda[i].unidadMedida = listaActivosCaractBusqueda[i].unidadMedidaByIdUnidadMedida.unidadMedida;
                    if (listaActivosCaractBusqueda[i].unidadMedidaByIdUnidadMedida.unidadMedida === 'Años') {
                        listaActivosCaractBusqueda[i].vidaUtilHora = ((listaActivosCaractBusqueda[i].vidaUtil * 365) * 24).toFixed(2);
                        listaActivosCaractBusqueda[i].vidaUtilDia = (listaActivosCaractBusqueda[i].vidaUtil * 365).toFixed(2);
                        listaActivosCaractBusqueda[i].vidaUtilAño = listaActivosCaractBusqueda[i].vidaUtil.toFixed(2);
                    }
                    else if (listaActivosCaractBusqueda[i].unidadMedidaByIdUnidadMedida.unidadMedida === 'Días') {
                        listaActivosCaractBusqueda[i].vidaUtilHora = ((listaActivosCaractBusqueda[i].vidaUtil * 24).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        listaActivosCaractBusqueda[i].vidaUtilDia = (listaActivosCaractBusqueda[i].vidaUtil.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        listaActivosCaractBusqueda[i].vidaUtilAño = (listaActivosCaractBusqueda[i].vidaUtil / 365).toFixed(2);
                    }
                    else {
                        listaActivosCaractBusqueda[i].vidaUtilHora = (listaActivosCaractBusqueda[i].vidaUtil.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        listaActivosCaractBusqueda[i].vidaUtilDia = listaActivosCaractBusqueda[i].vidaUtil / 24;
                        listaActivosCaractBusqueda[i].vidaUtilDia = (listaActivosCaractBusqueda[i].vidaUtilDia.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        listaActivosCaractBusqueda[i].vidaUtilAño = listaActivosCaractBusqueda[i].vidaUtil / (24 * 365);
                        listaActivosCaractBusqueda[i].vidaUtilAño = listaActivosCaractBusqueda[i].vidaUtilAño.toFixed(2);
                    }
                }
                else {
                    listaActivosCaractBusqueda[i].vidaUtilHora = '0';
                    listaActivosCaractBusqueda[i].vidaUtilDia = '0';
                    listaActivosCaractBusqueda[i].vidaUtilAño = '0';
                }

                if (listaActivosCaractBusqueda[i].subFamiliaByIdSubFamilia) {
                    listaActivosCaractBusqueda[i].subfamilia = listaActivosCaractBusqueda[i].subFamiliaByIdSubFamilia.subFamilia;

                    if (listaActivosCaractBusqueda[i].subFamiliaByIdSubFamilia.familiaByIdFamilia) {
                        listaActivosCaractBusqueda[i].grupoServicio = listaActivosCaractBusqueda[i].subFamiliaByIdSubFamilia.familiaByIdFamilia.servicioByIdServicio.grupoSerByIdGrupoSer.nombre;
                        listaActivosCaractBusqueda[i].nomenclatura = listaActivosCaractBusqueda[i].subFamiliaByIdSubFamilia.familiaByIdFamilia.servicioByIdServicio.nomenclatura;
                        listaActivosCaractBusqueda[i].familia = listaActivosCaractBusqueda[i].subFamiliaByIdSubFamilia.familiaByIdFamilia.familia;
                    }
                    else {
                        listaActivosCaractBusqueda[i].grupoServicio = 'No tiene grupo de servicio';
                        listaActivosCaractBusqueda[i].familia = 'No tiene familia';
                    }

                    if (listaActivosCaractBusqueda[i].subFamiliaByIdSubFamilia.centroCostoByIdCentroCosto) {
                        listaActivosCaractBusqueda[i].centroCosto = listaActivosCaractBusqueda[i].subFamiliaByIdSubFamilia.centroCostoByIdCentroCosto.centroCosto;
                    }
                    else {
                        listaActivosCaractBusqueda[i].centroCosto = 'No tiene centro de costos';
                    }
                }
                else {
                    listaActivosCaractBusqueda[i].subfamilia = 'No tiene subfamilia';
                    listaActivosCaractBusqueda[i].grupoServicio = 'No tiene grupo de servicio';
                    listaActivosCaractBusqueda[i].familia = 'No tiene familia';
                    listaActivosCaractBusqueda[i].centroCosto = 'No tiene centro de costos';
                }
                if(listaActivosCaractBusqueda[i].condicion === 'N'){
                    listaActivosCaractBusqueda[i].condicionTabla = 'Ninguno';
                    listaActivosCaractBusqueda[i].condicion = '';
                }
                else if (listaActivosCaractBusqueda[i].condicion === 'M'){
                    listaActivosCaractBusqueda[i].condicionTabla = 'Móvil';
                }
                else{
                    listaActivosCaractBusqueda[i].condicionTabla = 'Fijo';
                }
            }
            yield put({ type: almacenesActions.LISTA_ACTIVOS_CARACT_BUSQUEDA_SUCCESS, listaActivosCaractBusqueda, listaActivosCaractBusquedaCantidad });
        }
        else {
            yield put({ type: almacenesActions.LISTA_ACTIVOS_CARACT_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_ACTIVOS_CARACT_BUSQUEDA_FAILURE });
    }
}
export function* listaActivosCaractBusquedaSaga() {
    yield takeLatest(almacenesActions.LISTA_ACTIVOS_CARACT_BUSQUEDA_REQUEST, listaActivosCaractBusqueda);
}

//********************** Lista de Expedientes para nuevo/editar proveedor *****************************
function* listaActivosCaractFiltro(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const busqueda = action.busqueda;

        const response = yield call(listaActivosCaractFiltroCall, token, busqueda);
        console.log(response);

        if (response.status == 200) {
            let listaActivosCaractFiltro = response.data;
            yield put({ type: almacenesActions.LISTA_ACTIVOS_CARACT_FILTRO_SUCCESS, listaActivosCaractFiltro });
        }
        else {
            yield put({ type: almacenesActions.LISTA_ACTIVOS_CARACT_FILTRO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_ACTIVOS_CARACT_FILTRO_FAILURE });
    }
}
export function* listaActivosCaractFiltroSaga() {
    yield takeLatest(almacenesActions.LISTA_ACTIVOS_CARACT_FILTRO_REQUEST, listaActivosCaractFiltro);
}

function* listaActivosCaractFiltroAsignacionSP(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const busqueda = action.busqueda;

        const response = yield call(listaActivosCaractFiltroAsignacionSPCall, token, busqueda);
        console.log(response);

        if (response.status == 200) {
            let listaActivosCaractFiltroAsignacionSP = response.data;
            for(let i in listaActivosCaractFiltroAsignacionSP){
                listaActivosCaractFiltroAsignacionSP[i].descripcion = listaActivosCaractFiltroAsignacionSP[i].productoByIdProducto?.nombre;
                listaActivosCaractFiltroAsignacionSP[i].codigoAdicional = listaActivosCaractFiltroAsignacionSP[i].productoByIdProducto?.codigoAdicional;
            }
            yield put({ type: almacenesActions.LISTA_ACTIVOS_CARACT_FILTRO_ASIGNACION_SP_SUCCESS, listaActivosCaractFiltroAsignacionSP });
        }
        else {
            yield put({ type: almacenesActions.LISTA_ACTIVOS_CARACT_FILTRO_ASIGNACION_SP_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_ACTIVOS_CARACT_FILTRO_ASIGNACION_SP_FAILURE });
    }
}
export function* listaActivosCaractFiltroAsignacionSPSaga() {
    yield takeLatest(almacenesActions.LISTA_ACTIVOS_CARACT_FILTRO_ASIGNACION_SP_REQUEST, listaActivosCaractFiltroAsignacionSP);
}

//********************** Cambiar el estado del Activo Caract Activo/inactivo *****************************
function* cambiarEstadoActivoCaract(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idActivoCaract = action.idActivoCaract;

        const response = yield call(estadoActivoCaractCall, token, idActivoCaract);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const busqueda = '';
            const showSuccessMsg = true;
            const textMessage = "El estado del activo característica cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.ESTADO_ACTIVO_CARACT_SUCCESS });
            yield put({ type: almacenesActions.LISTA_ACTIVOS_CARACT_REQUEST });
            yield put({ type: almacenesActions.LISTA_ACTIVOS_CARACT_BUSQUEDA_REQUEST, page, busqueda });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.ESTADO_ACTIVO_CARACT_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.ESTADO_ACTIVO_CARACT_FAILURE });
    }
}
export function* cambiarEstadoActivoCaractSaga() {
    yield takeLatest(almacenesActions.ESTADO_ACTIVO_CARACT_REQUEST, cambiarEstadoActivoCaract);
}

//********************** Crear nuevo Activo Caract *****************************
function* crearActivoCaract(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let vidaUtilSugerida = '';
        let fabricante = '';
        let condicion = 'N';
        let usoBasico = '';
        let idUnidadMedida = null;
        if(action.formulario.usoBasico){
            usoBasico = action.formulario.usoBasico;
        }
        if (action.formulario.condicion) {
            condicion = action.formulario.condicion.toUpperCase()
        }
        if (action.formulario.fabricante) {
            fabricante = action.formulario.fabricante;
        }
        for (let i = 0; i < action.listaUnidadesDeMedicionActivos.length; i++) {
            if (action.formulario.vidaUtilSugerida && action.formulario.unidadMedidaSugerida) {
                vidaUtilSugerida = action.formulario.vidaUtilSugerida + ' ' + action.formulario.unidadMedidaSugerida
            }
            if(action.formulario.unidadMedida === action.listaUnidadesDeMedicionActivos[i].unidadMedida){
                idUnidadMedida = action.listaUnidadesDeMedicionActivos[i].idUnidadMedida;
            }
        }
        const data = {
            "idActivoCaract": null,
            "descripcion": action.formulario.descripcion,
            "marca": action.formulario.marca,
            "modelo": action.formulario.modelo,
            "fabricante": fabricante,
            "especificaciones": action.formulario.especificaciones,
            "usoBasico": usoBasico,
            "costoUnidad": action.formulario.costoUnidad,
            'idTipoMoneda': parseInt(action.formulario.tipoMoneda.split(' - ')[0]),
            "vidaUtil": action.formulario.vidaUtil,
            "idUnidadMedida": idUnidadMedida,
            "vidaUtilSugerida": vidaUtilSugerida,
            "idSubFamilia": parseInt(action.formulario.idSubFamilia),
            "condicion": condicion,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
        }
        console.log(data);
        const response = yield call(crearActivoCaractCall, token, data);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const busqueda = '';
            const showModalNuevoActivoCaract = false;
            const showSuccessMsg = true;
            const textMessage = "El activo característica " + action.formulario.descripcion + " se creó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearActivoCaract' });
            yield put({ type: almacenesActions.NUEVO_ACTIVO_CARACT_SUCCESS });
            yield put({ type: almacenesActions.LISTA_ACTIVOS_CARACT_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: almacenesActions.LISTA_ACTIVOS_CARACT_REQUEST });
            yield put({ type: almacenesActions.SHOW_MODAL_NUEVO_ACTIVO_CARACT, showModalNuevoActivoCaract });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.NUEVO_ACTIVO_CARACT_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.NUEVO_ACTIVO_CARACT_FAILURE });
    }
}
export function* crearActivoCaractSaga() {
    yield takeLatest(almacenesActions.NUEVO_ACTIVO_CARACT_REQUEST, crearActivoCaract);
}

//********************** Editar Activo Carat *****************************
function* editarActivoCaract(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idActivoCaract = action.activoCaractSeleccionado.idActivoCaract;
        let vidaUtilSugerida = '';
        let fabricante = '';
        let condicion = 'N';
        let idSubFamilia = action.activoCaractSeleccionado.idSubFamilia;
        let idUnidadMedida = action.activoCaractSeleccionado.idUnidadMedida;
        let usoBasico = '';
        if(action.formulario.usoBasico){
            usoBasico = action.formulario.usoBasico;
        }
        if(action.formulario.condicion){
            condicion = action.formulario.condicion.toUpperCase();
        }
        if (action.formulario.fabricante) {
            fabricante = action.formulario.fabricante;
        }

        for (let i = 0; i < action.listaUnidadesDeMedicionActivos.length; i++) {
            if (action.formulario.vidaSugerida && action.formulario.unidadMedidaSugerida) {
                vidaUtilSugerida = action.formulario.vidaSugerida + ' ' + action.formulario.unidadMedidaSugerida
            }
            if (action.formulario.unidadMedida === action.listaUnidadesDeMedicionActivos[i].unidadMedida) {
                idUnidadMedida = action.listaUnidadesDeMedicionActivos[i].idUnidadMedida;
            }
        }
        for (let i = 0; i < action.listaSubfamilias.length; i++) {
            if (action.formulario.subfamilia === action.listaSubfamilias[i].subFamilia) {
                idSubFamilia = action.listaSubfamilias[i].idSubFamilia;
            }
        }

        let data = {
            "idActivoCaract": idActivoCaract,
            "descripcion": action.formulario.descripcion,
            "marca": action.formulario.marca,
            "modelo": action.formulario.modelo,
            "fabricante": fabricante,
            "especificaciones": action.formulario.especificaciones,
            "usoBasico": usoBasico,
            "costoUnidad": action.formulario.costoUnidad,
            'idTipoMoneda': parseInt(action.formulario.tipoMonedaEditar.split(' - ')[0]),
            "vidaUtil": parseInt(action.formulario.vidaUtil),
            "idUnidadMedida": idUnidadMedida,
            "vidaUtilSugerida": vidaUtilSugerida,
            "idSubFamilia": idSubFamilia,
            "condicion": condicion,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": action.activoCaractSeleccionado.activo
        }

        console.log(data);
        const response = yield call(editarActivoCaractCall, token, data, idActivoCaract);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const busqueda = '';
            const showModalEditarActivoCaract = false;
            const showSuccessMsg = true;
            let textMessage = "El activo característica " + action.formulario.descripcion + " se modificó de forma correcta";
            let activoCaractSeleccionado = null;
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarActivoCaract' });
            yield put({ type: almacenesActions.EDITAR_ACTIVO_CARACT_SUCCESS });
            yield put({ type: almacenesActions.LISTA_ACTIVOS_CARACT_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: almacenesActions.LISTA_ACTIVOS_CARACT_REQUEST });
            yield put({ type: almacenesActions.SHOW_MODAL_EDITAR_ACTIVO_CARACT, showModalEditarActivoCaract, activoCaractSeleccionado });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.EDITAR_ACTIVO_CARACT_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.EDITAR_ACTIVO_CARACT_FAILURE });
    }
}
export function* editarActivoCaractSaga() {
    yield takeLatest(almacenesActions.EDITAR_ACTIVO_CARACT_REQUEST, editarActivoCaract);
}

function* exportarExcelCaracteristicasActivos(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(exportarExcelCaracteristicasActivosCall, token);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el excel de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "CaracteristicasDelActivo" + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_CARACTERISTICAS_ACTIVOS_EXCEL_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el archivo";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_CARACTERISTICAS_ACTIVOS_EXCEL_FAILURE });
        }
    } catch (error) {
       yield put({ type: almacenesActions.EXPORTAR_CARACTERISTICAS_ACTIVOS_EXCEL_FAILURE });
    }
} export function* exportarExcelCaracteristicasActivosSaga(){
    yield takeLatest(almacenesActions.EXPORTAR_CARACTERISTICAS_ACTIVOS_EXCEL_REQUEST, exportarExcelCaracteristicasActivos)
}

// *************************** ASIGNACIÓN DE ACTIVOS ****************************
// function* listaAsignacionActivosActivos(action) {
//     try {
//         console.log(action);
//         const token = sessionStorage.getItem('token');

//         const response = yield call(listaActivosCall, token);
//         console.log(response);

//         if (response.status == 200) {
//             const listaAsignacionActivosActivos = response.data;

//             yield put({ type: almacenesActions.LISTA_ASIGNACION_ACTIVOS_ACTIVOS_SUCCESS, listaAsignacionActivosActivos });
//         }
//         else {
//             yield put({ type: almacenesActions.LISTA_ASIGNACION_ACTIVOS_ACTIVOS_FAILURE });
//         }

//         console.log('listaActivosActivos en SAGA', response.data)

//     } catch (error) {
//         console.log(error);
//         yield put({ type: almacenesActions.LISTA_ASIGNACION_ACTIVOS_ACTIVOS_FAILURE });
//     }
// }
// export function* listaAsignacionActivosActivosSaga() {
//     yield takeLatest(almacenesActions.LISTA_ASIGNACION_ACTIVOS_ACTIVOS_REQUEST, listaAsignacionActivosActivos);
// }


function* listaAreasFuncionalesActivas(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(getAllAreasCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaAreasFuncionalesActivas = response.data;

            yield put({ type: almacenesActions.LISTA_AREA_FUNCIONAL_ACTIVA_SUCCESS, listaAreasFuncionalesActivas });
        }
        else {
            yield put({ type: almacenesActions.LISTA_AREA_FUNCIONAL_ACTIVA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_AREA_FUNCIONAL_ACTIVA_FAILURE });
    }
}
export function* listaAreasFuncionalesActivasSaga() {
    yield takeLatest(almacenesActions.LISTA_AREA_FUNCIONAL_ACTIVA_REQUEST, listaAreasFuncionalesActivas);
}

function* listaAreasFuncionalesActivasEx(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idUbicacionAnterior = parseInt(action.idUbicacionAnterior);
        console.log("ID UBICACIÓN ANTERIOR SAGA: ", idUbicacionAnterior)

        const response = yield call(getAllAreasExCall, token, idUbicacionAnterior);
        console.log(response);

        if (response.status == 200) {
            const listaAreasFuncionalesActivasEx = response.data;

            yield put({ type: almacenesActions.LISTA_AREA_FUNCIONAL_ACTIVA_EX_SUCCESS, listaAreasFuncionalesActivasEx });
        }
        else {
            yield put({ type: almacenesActions.LISTA_AREA_FUNCIONAL_ACTIVA_EX_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_AREA_FUNCIONAL_ACTIVA_EX_FAILURE });
    }
}
export function* listaAreasFuncionalesActivasExSaga() {
    yield takeLatest(almacenesActions.LISTA_AREA_FUNCIONAL_ACTIVA_EX_REQUEST, listaAreasFuncionalesActivasEx);
}

function* listaUnidadesFuncionalesActivas(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idAreaFuncional = parseInt(action.idAreaFuncional);
        console.log('idAreaFuncional SAGA');
        console.log(idAreaFuncional);

        const response = yield call(listaUnidadesFuncionalesActivasCall, token, idAreaFuncional);
        console.log(response);

        if (response.status == 200) {
            const listaUnidadesFuncionalesActivas = response.data;
            let showAlertMsg = false;
            if(!listaUnidadesFuncionalesActivas[0]){
                showAlertMsg = true;
            }

            yield put({ type: almacenesActions.LISTA_UNIDAD_FUNCIONAL_SUCCESS, listaUnidadesFuncionalesActivas, showAlertMsg:showAlertMsg });
        }
        else {
            yield put({ type: almacenesActions.LISTA_UNIDAD_FUNCIONAL_FAILURE,  showAlertMsg:true });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_UNIDAD_FUNCIONAL_FAILURE, showAlertMsg:true });
    }
}
export function* listaUnidadesFuncionalesActivasSaga() {
    yield takeLatest(almacenesActions.LISTA_UNIDAD_FUNCIONAL_REQUEST, listaUnidadesFuncionalesActivas);
}

function* listaUnidadesFuncionalesActivasEx(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idUbicacionAnterior = action.idUbicacionAnterior;
        const idAreaFuncional = action.idAreaFuncional;
        console.log('idAreaFuncional SAGA EXCLUYENTE: ', idAreaFuncional);
        console.log('idUbicacionAnterior SAGA EXCLUYENTE: ', idUbicacionAnterior);
        

        const response = yield call(listaUnidadesFuncionalesActivasExCall, token, idUbicacionAnterior, idAreaFuncional);
        console.log(response);

        if (response.status == 200) {
            const listaUnidadesFuncionalesActivasEx = response.data;
            let showAlertMsgEx = false;
            if(!listaUnidadesFuncionalesActivasEx[0]){
                showAlertMsgEx = true;
            }

            yield put({ type: almacenesActions.LISTA_UNIDAD_FUNCIONAL_EX_SUCCESS, listaUnidadesFuncionalesActivasEx, showAlertMsgEx:showAlertMsgEx  });
        }
        else {
            yield put({ type: almacenesActions.LISTA_UNIDAD_FUNCIONAL_EX_FAILURE, showAlertMsgEx:true  });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_UNIDAD_FUNCIONAL_EX_FAILURE, showAlertMsgEx:true  });
    }
}
export function* listaUnidadesFuncionalesActivasExSaga() {
    yield takeLatest(almacenesActions.LISTA_UNIDAD_FUNCIONAL_EX_REQUEST, listaUnidadesFuncionalesActivasEx);
}

function* listaUsuariosActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaUsuariosCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaUsuariosActivos = response.data;

            yield put({ type: almacenesActions.LISTA_USUARIOS_ACTIVOS_SUCCESS, listaUsuariosActivos });
        }
        else {
            yield put({ type: almacenesActions.LISTA_USUARIOS_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_USUARIOS_ACTIVOS_FAILURE });
    }
}
export function* listaUsuariosActivosSaga() {
    yield takeLatest(almacenesActions.LISTA_USUARIOS_ACTIVOS_REQUEST, listaUsuariosActivos);
}

function* listaInterioresFuncionales(action) {
    try {
        console.log("listaInterioresFuncionales ACTION",action);
        const token = sessionStorage.getItem('token');
        const idAreaFuncional = parseInt(action.idAreaFuncional);
        const idUnidadFuncional = parseInt(action.idUnidadFuncional);

        const response = yield call(listaInterioresFuncionalesCall, token, idAreaFuncional, idUnidadFuncional);
        console.log("listaInterioresFuncionales response",response);

        if (response.status == 200) {
            const listaInteriorFuncional = response.data;
            let showAlertMsg = false;
            if(!listaInteriorFuncional[0]){
                showAlertMsg = true;
            }

            yield put({ type: almacenesActions.LISTA_INTERIOR_FUNCIONAL_SUCCESS, listaInteriorFuncional, showAlertMsg:showAlertMsg  });
        }
        else {
            yield put({ type: almacenesActions.LISTA_INTERIOR_FUNCIONAL_FAILURE, showAlertMsg:true  });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_INTERIOR_FUNCIONAL_FAILURE, showAlertMsg:true  });
    }
}
export function* listaInterioresFuncionalesSaga() {
    yield takeLatest(almacenesActions.LISTA_INTERIOR_FUNCIONAL_REQUEST, listaInterioresFuncionales);
}

function* listaInterioresFuncionalesEx(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idUbicacionAnterior = parseInt(action.idUbicacionAnterior);
        const idAreaFuncional = parseInt(action.idAreaFuncional);
        const idUnidadFuncional = parseInt(action.idUnidadFuncional);

        const response = yield call(listaInterioresFuncionalesExCall, token, idUbicacionAnterior, idAreaFuncional, idUnidadFuncional);
        console.log(response);

        if (response.status == 200) {
            const listaInteriorFuncionalEx = response.data;
            let showAlertMsgEx = false;
            if(!listaInteriorFuncionalEx[0]){
                showAlertMsgEx = true;
            }
            yield put({ type: almacenesActions.LISTA_INTERIOR_FUNCIONAL_EX_SUCCESS, listaInteriorFuncionalEx, showAlertMsgEx:showAlertMsgEx  });
        }
        else {
            yield put({ type: almacenesActions.LISTA_INTERIOR_FUNCIONAL_EX_FAILURE, showAlertMsgEx:true  });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_INTERIOR_FUNCIONAL_EX_FAILURE, showAlertMsgEx:true  });
    }
}
export function* listaInterioresFuncionalesExSaga() {
    yield takeLatest(almacenesActions.LISTA_INTERIOR_FUNCIONAL_EX_REQUEST, listaInterioresFuncionalesEx);
}

function* listaSubinterioresFuncionales(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idAreaFuncional = parseInt(action.idAreaFuncional);
        const idUnidadFuncional = parseInt(action.idUnidadFuncional);
        const idInteriorFuncional = parseInt(action.idInteriorFuncional);
        console.log('');

        const response = yield call(listaSubinterioresFuncionalesCall, token, idAreaFuncional, idUnidadFuncional, idInteriorFuncional);
        console.log(response);

        if (response.status == 200) {
            const listaSubinteriorFuncional = response.data;

            yield put({ type: almacenesActions.LISTA_SUBINTERIOR_FUNCIONAL_SUCCESS, listaSubinteriorFuncional });
        }
        else {
            yield put({ type: almacenesActions.LISTA_SUBINTERIOR_FUNCIONAL_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_SUBINTERIOR_FUNCIONAL_FAILURE });
    }
}
export function* listaSubinterioresFuncionalesSaga() {
    yield takeLatest(almacenesActions.LISTA_SUBINTERIOR_FUNCIONAL_REQUEST, listaSubinterioresFuncionales);
}

function* listaSubinterioresFuncionalesEx(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idUbicacionAnterior = parseInt(action.idUbicacionAnterior);
        const idAreaFuncional = parseInt(action.idAreaFuncional);
        const idUnidadFuncional = parseInt(action.idUnidadFuncional);
        const idInteriorFuncional = parseInt(action.idInteriorFuncional);
        console.log('');

        const response = yield call(listaSubinterioresFuncionalesExCall, token, idUbicacionAnterior, idAreaFuncional, idUnidadFuncional, idInteriorFuncional);
        console.log(response);

        if (response.status == 200) {
            const listaSubinteriorFuncionalEx = response.data;

            yield put({ type: almacenesActions.LISTA_SUBINTERIOR_FUNCIONAL_EX_SUCCESS, listaSubinteriorFuncionalEx });
        }
        else {
            yield put({ type: almacenesActions.LISTA_SUBINTERIOR_FUNCIONAL_EX_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_SUBINTERIOR_FUNCIONAL_EX_FAILURE });
    }
}
export function* listaSubinterioresFuncionalesExSaga() {
    yield takeLatest(almacenesActions.LISTA_SUBINTERIOR_FUNCIONAL_EX_REQUEST, listaSubinterioresFuncionalesEx);
}

// Lista asignación Busqueda

function* listaAsignacionActivosBusqueda(action) {
    try {
        console.log("action listaAsignacionActivosBusqueda",action);
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let idAreaFuncional = '';
        let idUnidadFuncional = '';
        let idInteriorFuncional = '';
        let idSubInteriorFuncional = '';
        let any = '';
        let order = 'ASC';
        let field = 'ace.descripcion';
        if (action.busqueda.any) {
            any = 'any=' + action.busqueda.any + '&';
        }
        if (action.busqueda.order){
            order = 'order=' + action.busqueda.order + '&';
        }
        if (action.busqueda.field){
            field = 'field=' + action.busqueda.field + '&';
        }
        if(action.busqueda.idAreaFuncional){
            idAreaFuncional = 'idAreaFuncional=' + action.busqueda.idAreaFuncional + '&';
        }
        if(action.busqueda.idUnidadFuncional){
            idUnidadFuncional = 'idUnidadFuncional=' + action.busqueda.idUnidadFuncional + '&';
        }
        if(action.busqueda.idInteriorFuncional){
            idInteriorFuncional = 'idInteriorFuncional=' + action.busqueda.idInteriorFuncional + '&';
        }
        if(action.busqueda.idSubInteriorFuncional){
            idSubInteriorFuncional = 'idSubInteriorFuncional=' + action.busqueda.idSubInteriorFuncional + '&';
        }
            // else{
            //     idSubInteriorFuncional = 'idSubInteriorFuncional=' + '' + '&';
            // }  

        console.log('page: ', page);
        
        console.log('idAreaFuncional en SAGA: ', idAreaFuncional);
        console.log(idUnidadFuncional);
        console.log(idInteriorFuncional);
        console.log(idSubInteriorFuncional);
        console.log(any);
        console.log(order);
        console.log(field);
        console.log('+++++++***********++++++ ');

        console.log("lo que mando listaAsignacionActivosBusqueda ","idAreaFuncional",idAreaFuncional,"idUnidadFuncional",idUnidadFuncional,"idInteriorFuncional",idInteriorFuncional,"idSubInteriorFuncional",idSubInteriorFuncional,"any",any,"order",order,"field",field)

        const response = yield call(listaActivosBusquedaAUISCall ,token, page, any, order, field, idAreaFuncional, idUnidadFuncional, idInteriorFuncional, idSubInteriorFuncional);
        console.log("response listaAsignacionActivosBusqueda",response);

        if (response.status == 200) {
            let listaAsignacionActivosBusqueda = response.data.data;
            let listaAsignacionActivosBusquedaCantidad = response.data.cantidad;
            for (let i = 0; i < listaAsignacionActivosBusqueda.length; i++) {
                listaAsignacionActivosBusqueda[i].fechaGarantia = moment(listaAsignacionActivosBusqueda[i].fechaGarantia, 'DD-MM-YYYY')
                listaAsignacionActivosBusqueda[i].fechaInstalacion =  moment(listaAsignacionActivosBusqueda[i].fechaInstalacion, 'DD-MM-YYYY')
                listaAsignacionActivosBusqueda[i].fechaSuministro = moment(listaAsignacionActivosBusqueda[i].fechaSuministro, 'DD-MM-YYYY')
                listaAsignacionActivosBusqueda[i].fechaAltaCompleta = moment(listaAsignacionActivosBusqueda[i].fechaAltaCompleta, 'DD-MM-YYYY')
                listaAsignacionActivosBusqueda[i].fechaAlta = moment(listaAsignacionActivosBusqueda[i].fechaAlta, 'DD-MM-YYYY')
                listaAsignacionActivosBusqueda[i].fechaActualizacion = moment(listaAsignacionActivosBusqueda[i].fechaActualizacion, 'DD-MM-YYYY')

                if (listaAsignacionActivosBusqueda[i].activoCaractByIdActivoCaract) {
                    listaAsignacionActivosBusqueda[i].descripcion = listaAsignacionActivosBusqueda[i].activoCaractByIdActivoCaract.descripcion;

                    listaAsignacionActivosBusqueda[i].marca = listaAsignacionActivosBusqueda[i].activoCaractByIdActivoCaract.marca;

                    listaAsignacionActivosBusqueda[i].modelo = listaAsignacionActivosBusqueda[i].activoCaractByIdActivoCaract.modelo;

                    listaAsignacionActivosBusqueda[i].usoBasico = listaAsignacionActivosBusqueda[i].activoCaractByIdActivoCaract.usoBasico;

                    listaAsignacionActivosBusqueda[i].especificaciones = listaAsignacionActivosBusqueda[i].activoCaractByIdActivoCaract.especificaciones;

                    listaAsignacionActivosBusqueda[i].costoUnidad = listaAsignacionActivosBusqueda[i].activoCaractByIdActivoCaract.costoUnidad;

                    listaAsignacionActivosBusqueda[i].condicion = listaAsignacionActivosBusqueda[i].activoCaractByIdActivoCaract.condicion;

                    listaAsignacionActivosBusqueda[i].fabricante = listaAsignacionActivosBusqueda[i].activoCaractByIdActivoCaract.fabricante;
                }

                if(listaAsignacionActivosBusqueda[i].ubicacionByIdUbicacion) {
                    listaAsignacionActivosBusqueda[i].claveUbicacion = listaAsignacionActivosBusqueda[i].ubicacionByIdUbicacion.claveUbicacion;

                    listaAsignacionActivosBusqueda[i].claveUnidad = listaAsignacionActivosBusqueda[i].ubicacionByIdUbicacion.claveUnidad;
                }
                
                // ByIds dentro de ubicaciones
            
                if(listaAsignacionActivosBusqueda[i].activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia) {
                    listaAsignacionActivosBusqueda[i].subFamilia = listaAsignacionActivosBusqueda[i].activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia.subFamilia;

                    if(listaAsignacionActivosBusqueda[i].activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia.centroCostoByIdCentroCosto) {
                        listaAsignacionActivosBusqueda[i].centroCosto = listaAsignacionActivosBusqueda[i].activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia.centroCostoByIdCentroCosto.centroCosto;
                    }

                    if(listaAsignacionActivosBusqueda[i].activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia.familiaByIdFamilia) {
                        listaAsignacionActivosBusqueda[i].familia = listaAsignacionActivosBusqueda[i].activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia.familiaByIdFamilia.familia;

                        if(listaAsignacionActivosBusqueda[i].activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia.familiaByIdFamilia.servicioByIdServicio) {
                            listaAsignacionActivosBusqueda[i].nombre = listaAsignacionActivosBusqueda[i].activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia.familiaByIdFamilia.servicioByIdServicio.nombre;

                            if(listaAsignacionActivosBusqueda[i].activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia.familiaByIdFamilia.servicioByIdServicio.grupoSerByIdGrupoSer) {
                                listaAsignacionActivosBusqueda[i].nombre = listaAsignacionActivosBusqueda[i].activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia.familiaByIdFamilia.servicioByIdServicio.grupoSerByIdGrupoSer.nombre;
                            }
                        }
                    }
                }

                if(listaAsignacionActivosBusqueda[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional) {
                    listaAsignacionActivosBusqueda[i].areaFuncional = listaAsignacionActivosBusqueda[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;

                    listaAsignacionActivosBusqueda[i].codigoArea = listaAsignacionActivosBusqueda[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.codigoArea;

                    listaAsignacionActivosBusqueda[i].claveArea = listaAsignacionActivosBusqueda[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.claveArea;

                }

                if(listaAsignacionActivosBusqueda[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional) {
                    listaAsignacionActivosBusqueda[i].interiorFuncional = listaAsignacionActivosBusqueda[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional;
                }else {
                    listaAsignacionActivosBusqueda[i].interiorFuncional = 'No tiene interior';
                }

                if(listaAsignacionActivosBusqueda[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional) {
                    listaAsignacionActivosBusqueda[i].subInteriorFuncional = listaAsignacionActivosBusqueda[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                }else {
                    listaAsignacionActivosBusqueda[i].subInteriorFuncional = 'No tiene subinterior';
                }

                if(listaAsignacionActivosBusqueda[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional) {
                    listaAsignacionActivosBusqueda[i].unidadFuncional = listaAsignacionActivosBusqueda[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                }
            }
            yield put({ type: almacenesActions.ASIGNACION_ACTIVOS_BUSQUEDA_SUCCESS, listaAsignacionActivosBusqueda, listaAsignacionActivosBusquedaCantidad });
        }
        else {
            yield put({ type: almacenesActions.ASIGNACION_ACTIVOS_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.ASIGNACION_ACTIVOS_BUSQUEDA_FAILURE });
    }
}
export function* listaAsignacionActivosBusquedaSaga() {
    yield takeLatest(almacenesActions.ASIGNACION_ACTIVOS_BUSQUEDA_REQUEST, listaAsignacionActivosBusqueda);
}


//********************** Crear nuevo  *****************************
function* crearAsignacionActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let data =  [];
        let idAreaFuncional = action.formulario.idAreaFuncional;
        let idUnidadFuncional = action.formulario.idUnidadFuncional;
        let idInteriorFuncional = action.formulario.idInteriorFuncional;
        let idSubInteriorFuncional = '';      
        let idUsuarioRecibe = action.formulario.idUsuario;

        if(action.formulario.idSubInteriorFuncional){
            idSubInteriorFuncional = action.formulario.idSubInteriorFuncional;
        }

        for(let i = 0; i < action.AsignacionActivoSeleccionado.length; i ++){
            let rp = action.AsignacionActivoSeleccionado[i];
            const activo = {
                "idActivo": rp.idActivo,
                "idActivoCaract": rp.idActivoCaract,
                "idUbicacion": rp.idUbicacion,
                "codigoInterno": rp.codigoInterno,
                "codigoEquipo": rp.codigoEquipo,
                "sku": rp.sku,
                "serieNum": rp.serieNum,
                "estado": rp.estado,
                "fechaSuministro": moment(rp.fechaSuministro, 'YYYY-MM-DD'),
                "fechaInstalacion":  moment(rp.fechaInstalacion, 'YYYY-MM-DD'),
                "fechaGarantia": moment(rp.fechaGarantia, 'YYYY-MM-DD'),
                "garantia": rp.garantia,
                "cartaFabricante": rp.cartaFabricante,
                "observaciones": rp.observaciones,
                "activo": true,
                "fechaAlta": rp.fechaAltaCompleta,
                "fechaActualizacion": null,
                "ubicacionByIdUbicacion": null,
                "activoCaractByIdActivoCaract": null
            }
            data.push(activo);
        }      

        console.log(data);
        console.log('idAreaFuncional: ',idAreaFuncional, '| idUnidadFuncional: ',idUnidadFuncional, '| idInteriorFuncional: ', idInteriorFuncional, '| idSubInteriorFuncional: ', idSubInteriorFuncional, '| idUsuarioRecibe: ', idUsuarioRecibe);
        const response = yield call(crearAsignacionActivosCall, token, data, idAreaFuncional, idUnidadFuncional, idInteriorFuncional, idSubInteriorFuncional, idUsuarioRecibe);
        console.log("response crearAsignacionActivos",response);

        let descargar = true;
        let hasSelected = true;

        if(response.status==200){
            const page = action.page;
            const busqueda = '';
            const showModalAsignarActivos = false;
            const AsignacionActivoSeleccionado = null;
            const showSuccessMsg = true;
            const textMessage = "La reasignación se creó de forma correcta";
            descargar = false;
            hasSelected = false;

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Reasignacion_" + new Date().getDate() + "-" + new Date().getMonth()  + "-" + new Date().getFullYear() + "_" + new Date().getHours() + new Date().getMinutes() + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            const asignacionPDF = link;

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearAsignacionActivos' });
            yield put({ type: almacenesActions.NUEVA_ASIGNACION_ACTIVOS_SUCCESS, asignacionPDF, descargar: descargar, hasSelected: hasSelected });
            yield put({ type: almacenesActions.ASIGNACION_ACTIVOS_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: almacenesActions.SHOW_MODAL_ASIGNACION_ACTIVOS, showModalAsignarActivos, AsignacionActivoSeleccionado });
        }
        else{
            const showErrorMsg = true;
            const textMessage = "No es posible reasignar activos en esta ubicación, debido a que cuenta con subinterior funcional";
            console.log('Entra a else 200');

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.NUEVA_ASIGNACION_ACTIVOS_FAILURE, descargar: descargar });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.NUEVA_ASIGNACION_ACTIVOS_FAILURE, descargar: true });
    }
}
export function* crearAsignacionActivosSaga() {
    yield takeLatest(almacenesActions.NUEVA_ASIGNACION_ACTIVOS_REQUEST, crearAsignacionActivos);
}

//CATALOGO ACTIVOS
//********************** Lista de Catalogos Activos*****************************
function* listaActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaActivosCaractCall, token);
        console.log(response);

        if (response.status == 200) {
            let listaActivosCaract = response.data;
            yield put({ type: almacenesActions.LISTA_ACTIVOS_CARACT_SUCCESS, listaActivosCaract });
        }
        else {
            yield put({ type: almacenesActions.LISTA_ACTIVOS_CARACT_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_ACTIVOS_CARACT_FAILURE });
    }
}
export function* listaActivosSaga() {
    yield takeLatest(almacenesActions.LISTA_ACTIVOS_REQUEST, listaActivos);
}

function* listaActivoBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let busqueda = '';
        if (action.busqueda) {
            busqueda = action.busqueda;
        }
        console.log('page');
        console.log(page);
        console.log('busqueda');
        console.log(busqueda);

        let response = yield call(listaActivosBusquedaCall, token, page, busqueda);
        console.log(response);

        if (response.status == 200) {
            let listaActivosBusqueda = response.data.data;
            const listaActivosBusquedaCantidad = response.data.cantidad;
            for (let i = 0; i < listaActivosBusqueda.length; i++) {
                
                let fechaAlta = listaActivosBusqueda[i].fechaAlta.split('T')[0];
                listaActivosBusqueda[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
                
                let fechaSuministro = listaActivosBusqueda[i].fechaSuministro.split('T')[0];
                listaActivosBusqueda[i].fechaSuministroTable = fechaSuministro.split('-')[2] + '-' + fechaSuministro.split('-')[1] + '-' + fechaSuministro.split('-')[0];
                listaActivosBusqueda[i].fechaSuministro = moment(fechaSuministro, 'YYYY-MM-DD')
                
                let fechaInstalacion = listaActivosBusqueda[i].fechaInstalacion.split('T')[0];
                listaActivosBusqueda[i].fechaInstalacionTable = fechaInstalacion.split('-')[2] + '-' + fechaInstalacion.split('-')[1] + '-' + fechaInstalacion.split('-')[0];
                listaActivosBusqueda[i].fechaInstalacion = moment(fechaInstalacion, 'YYYY-MM-DD')
                
                
                listaActivosBusqueda[i].fechaGarantiaTable = listaActivosBusqueda[i].fechaGarantia ? listaActivosBusqueda[i].fechaGarantia.split('T')[0].split('-').reverse().join('-') : 'No hay fecha';
                listaActivosBusqueda[i].fechaGarantia = listaActivosBusqueda[i].fechaGarantia ? moment(listaActivosBusqueda[i].fechaGarantia.split('T')[0], 'YYYY-MM-DD'): 'No hay fecha';

                if(listaActivosBusqueda[i].estadoActivoByIdEstadoActivo){
                    listaActivosBusqueda[i].estadoActivo = listaActivosBusqueda[i].estadoActivoByIdEstadoActivo.descripcionC;
                }
                else{
                    listaActivosBusqueda[i].estadoActivo = 'No tiene estatus'
                }

                if (listaActivosBusqueda[i].activoCaractByIdActivoCaract) {
                    listaActivosBusqueda[i].descripcionActivo = listaActivosBusqueda[i].activoCaractByIdActivoCaract.productoByIdProducto.nombre;

                    if (listaActivosBusqueda[i].activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia) {
                        listaActivosBusqueda[i].subFamilia = listaActivosBusqueda[i].activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia.subFamilia;

                        if (listaActivosBusqueda[i].activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia.familiaByIdFamilia) {
                            listaActivosBusqueda[i].grupoServicio = listaActivosBusqueda[i].activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia.familiaByIdFamilia.servicioByIdServicio.grupoSerByIdGrupoSer.nombre;
                            listaActivosBusqueda[i].nomenclatura = listaActivosBusqueda[i].activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia.familiaByIdFamilia.servicioByIdServicio.nomenclatura;
                            listaActivosBusqueda[i].familia = listaActivosBusqueda[i].activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia.familiaByIdFamilia.familia;

                        }
                        else {
                            listaActivosBusqueda[i].grupoServicio = 'No tiene grupo de servicio';
                            listaActivosBusqueda[i].familia = 'No tiene familia';
                        }
                        if (listaActivosBusqueda[i].activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia.centroCostoByIdCentroCosto) {
                            listaActivosBusqueda[i].centroCosto = listaActivosBusqueda[i].activoCaractByIdActivoCaract.subFamiliaByIdSubFamilia.centroCostoByIdCentroCosto.centroCosto;
                        }
                        else {
                            listaActivosBusqueda[i].centroCosto = 'No tiene centro de costos';
                        }
                    }
                    else {
                        listaActivosBusqueda[i].subFamilia = "No tiene subfamilia";

                    }
                } else {
                    listaActivosBusqueda[i].descripcionActivo = 'No tiene característica'
                    listaActivosBusqueda[i].subfamilia = 'No tiene subfamilia';
                    listaActivosBusqueda[i].grupoServicio = 'No tiene grupo de servicio';
                    listaActivosBusqueda[i].familia = 'No tiene familia';
                    listaActivosBusqueda[i].centroCosto = 'No tiene centro de costos';
                }
                if (listaActivosBusqueda[i].ubicacionByIdUbicacion) {
                    listaActivosBusqueda[i].claveUbicacion = listaActivosBusqueda[i].ubicacionByIdUbicacion.claveUbicacion;

                    listaActivosBusqueda[i].todoUbicacion = listaActivosBusqueda[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional + ', ' + listaActivosBusqueda[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional
                    if(listaActivosBusqueda[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional){
                        listaActivosBusqueda[i].todoUbicacion += ', ' + listaActivosBusqueda[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional 
                    }
                    if(listaActivosBusqueda[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional){
                        listaActivosBusqueda[i].todoUbicacion += ', ' + listaActivosBusqueda[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional 
                    }

                    if(listaActivosBusqueda[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional) {
                        listaActivosBusqueda[i].areaFuncional = listaActivosBusqueda[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional
                    } else {
                        listaActivosBusqueda[i].areaFuncional = 'No tiene área funcional';
                    }
                    if (listaActivosBusqueda[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional) {
                        listaActivosBusqueda[i].unidadFuncional = listaActivosBusqueda[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                    } else {
                        listaActivosBusqueda[i].unidadFuncional = 'No tiene unidad funcional';
                    }
                    if (listaActivosBusqueda[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional) {
                        listaActivosBusqueda[i].interior = listaActivosBusqueda[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional;
                    } else {
                        listaActivosBusqueda[i].interior  = 'No tiene interior';
                    }
                    if (listaActivosBusqueda[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional) {
                        listaActivosBusqueda[i].subInterior = listaActivosBusqueda[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                    } else {
                        listaActivosBusqueda[i].subInterior = 'No tiene subinterior';
                    }
                } else {
                    listaActivosBusqueda[i].claveUbicacion = 'No tiene clave ubicación';
                    listaActivosBusqueda[i].areaFuncional = 'No tiene área funcional';
                    listaActivosBusqueda[i].unidadFuncional = 'No tiene unidad funcional';
                    listaActivosBusqueda[i].interior  = 'No tiene interior';
                    listaActivosBusqueda[i].subInterior = 'No tiene subinterior';
                };
            };
      
            yield put({ type: almacenesActions.LISTA_ACTIVO_BUSQUEDA_SUCCESS, listaActivosBusqueda, listaActivosBusquedaCantidad });
        }
        else {
            yield put({ type: almacenesActions.LISTA_ACTIVO_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_ACTIVO_BUSQUEDA_FAILURE });
    }
}
export function* listaActivoBusquedaSaga() {
    yield takeLatest(almacenesActions.LISTA_ACTIVO_BUSQUEDA_REQUEST, listaActivoBusqueda);
}

// //********************** Cambiar el estado del Activo de Activo/inactivo *****************************
function* cambiarEstadoActivo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idActivo = action.idActivo;

        const response = yield call(estadoActivoCall, token, idActivo);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const busqueda = '';
            const showSuccessMsg = true;
            const textMessage = "El estado del activo cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.ESTADO_ACTIVO_SUCCESS });
            yield put({ type: almacenesActions.LISTA_ACTIVOS_REQUEST });
            yield put({ type: almacenesActions.LISTA_ACTIVO_BUSQUEDA_REQUEST, page, busqueda });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.ESTADO_ACTIVO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.ESTADO_ACTIVO_FAILURE });
    }
}
export function* cambiarEstadoActivoSaga() {
    yield takeLatest(almacenesActions.ESTADO_ACTIVO_REQUEST, cambiarEstadoActivo);
}

//********************** Crear nuevo Activo *****************************
function* crearActivo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let observaciones = '';
        let serieNum = '';
        if(action.formulario.serieNum){
            serieNum = action.formulario.serieNum;
        }
        if(action.formulario.observaciones) {
            observaciones=action.formulario.observaciones;
        }
        
        const data = {
            "idActivo": null,
            "idActivoCaract": parseInt(action.formulario.idActivoCaract),
            "idUbicacion":parseInt(action.formulario.idUbicacion),
            "codigoInterno": '',
            "codigoEquipo": '',
            "sku": action.formulario.sku,
            "serieNum": serieNum,
            "estado": action.formulario.estado,
            "fechaSuministro": action.formulario.fechaSuministro,
            "fechaInstalacion": action.formulario.fechaInstalacion,
            "fechaGarantia": action.formulario.fechaGarantia,
            "garantia": action.formulario.garantia,
            "cartaFabricante": '',
            "observaciones": observaciones,
            "activo": true,
            "fechaAlta": null,
            "fechaActualizacion": null,
            'idEstadoActivo': null,
        }
        const formData = new FormData();
        if(action.formulario.cartaFabricante) {
            formData.append('file', action.formulario.cartaFabricante.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        console.log('formData');
        console.log(formData);
        const response = yield call(crearActivoCall, token, formData);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const busqueda = '';
            const showModalNuevoActivo = false;
            const showSuccessMsg = true;
            const textMessage = "El activo fijo " + action.formulario.sku + " se creó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearActivo' });
            yield put({ type: almacenesActions.NUEVO_ACTIVO_SUCCESS });
            yield put({ type: almacenesActions.LISTA_ACTIVO_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: almacenesActions.LISTA_ACTIVOS_REQUEST });
            yield put({ type: almacenesActions.SHOW_MODAL_NUEVO_ACTIVO, showModalNuevoActivo });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.NUEVO_ACTIVO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.NUEVO_ACTIVO_FAILURE });
    }
}
export function* crearActivoSaga() {
    yield takeLatest(almacenesActions.NUEVO_ACTIVO_REQUEST, crearActivo);
}

function* exportarExcelActivos(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(exportarExcelActivosCall, token);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el excel de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Activos" + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_ACTIVOS_EXCEL_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el archivo";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_ACTIVOS_EXCEL_FAILURE });
        }
    } catch (error) {
       yield put({ type: almacenesActions.EXPORTAR_ACTIVOS_EXCEL_FAILURE });
    }
} export function* exportarExcelActivosSaga(){
    yield takeLatest(almacenesActions.EXPORTAR_ACTIVOS_EXCEL_REQUEST, exportarExcelActivos)
}

//********************** Editar Activo Carat *****************************
function* editarActivo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idActivo = action.activoSeleccionado.idActivo;
        let codigoInterno = action.activoSeleccionado.codigoInterno;
        let codigoEquipo = action.activoSeleccionado.codigoEquipo;
        let idActivoCaract = action.formulario.descripcionActivo;
        let idUbicacion = action.formulario.todoUbicacion;
        let observaciones = '';
        let serieNum = action.activoSeleccionado.serieNum;

        if(action.formulario.serieNum || action.formulario.serieNum === ''){
            serieNum = action.formulario.serieNum;
        }
        if(action.formulario.observaciones) {
            observaciones=action.formulario.observaciones;
        }

        if (isNaN(idActivoCaract)) {
            idActivoCaract = action.activoSeleccionado.idActivoCaract
        } 

        if (isNaN(idUbicacion)) {
            idUbicacion = action.activoSeleccionado.idUbicacion
        } 

        const data = {
            "idActivo": null,
            "idActivoCaract": parseInt(idActivoCaract),
            "idUbicacion": parseInt(idUbicacion),
            "codigoInterno": codigoInterno,
            "codigoEquipo": codigoEquipo,
            "sku": action.formulario.sku,
            "serieNum": serieNum,
            "estado": action.formulario.estado,
            "fechaSuministro": action.formulario.fechaSuministro,
            "fechaInstalacion": action.formulario.fechaInstalacion,
            "fechaGarantia": action.formulario.fechaGarantia,
            "garantia": action.formulario.garantia,
            "cartaFabricante": action.activoSeleccionado.cartaFabricante,
            "observaciones": observaciones,
            'idEstadoActivo': action.activoSeleccionado.idEstadoActivo,
            "activo": true,
            "fechaAlta": null,
            "fechaActualizacion": null,
        }
        console.log('data');
        console.log(data);
        const formData = new FormData();
        if(action.formulario.cartaFabricantePDF){
            formData.append('file', action.formulario.cartaFabricantePDF.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));

        console.log("formData")
        console.log(formData);
        const response = yield call(editarActivoCall, token, formData, idActivo);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const busqueda = '';
            const showModalEditarActivo = false;
            const showSuccessMsg = true;
            let textMessage = "El activo " + action.formulario.sku + " se modificó de forma correcta";
            let activoSeleccionado = null;
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarActivo' });
            yield put({ type: almacenesActions.EDITAR_ACTIVO_SUCCESS });
            yield put({ type: almacenesActions.LISTA_ACTIVO_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: almacenesActions.LISTA_ACTIVOS_REQUEST });
            yield put({ type: almacenesActions.SHOW_MODAL_EDITAR_ACTIVO, showModalEditarActivo, activoSeleccionado });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.EDITAR_ACTIVO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.EDITAR_ACTIVO_FAILURE });
    }
}
export function* editarActivoSaga() {
    yield takeLatest(almacenesActions.EDITAR_ACTIVO_REQUEST, editarActivo);
}

/// ********* ESTATUS ACTIVOS ****** //
//CÁTALOGO ESTATUS DEL ACTIVO
function* listaActivoArchivosEspera(action) {
    try {
        console.log("action archivosEsperaActivosCall",action);
        const token = sessionStorage.getItem('token');
        const page = action.page ? action.page - 1 : 0;
        let estado = 'finalizado';
        console.log('page');
        console.log(page);

        let response = yield call(archivosEsperaActivosCall, token, estado, page);
        console.log("response archivosEsperaActivosCall",response);

        if (response.status == 200) {
            let listaArchivosEspera = response.data.data;
            const listaArchivosEsperaCantidad = response.data.cantidad;
            for(let i=0; i<listaArchivosEspera.length; i++){
                listaArchivosEspera[i].fechaAlta = listaArchivosEspera[i].fechaAlta.split('T')[0].split('-').reverse().join('-') + ' ' + listaArchivosEspera[i].fechaAlta.split('T')[1].split('.')[0]
            }
            console.log("listaArchivosEsperaCantidad",listaArchivosEsperaCantidad)
            
            yield put({ type: almacenesActions.GET_ARCHIVOS_ESPERA_SUCCESS, listaArchivosEspera, listaArchivosEsperaCantidad });
        }
        else {
            yield put({ type: almacenesActions.GET_ARCHIVOS_ESPERA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.GET_ARCHIVOS_ESPERA_FAILURE });
    }
}
export function* listaActivoArchivosEsperaSaga() {
    yield takeLatest(almacenesActions.GET_ARCHIVOS_ESPERA_REQUEST, listaActivoArchivosEspera);
}

function* subirActivoArchivo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page;
        console.log(page);

        const formData = new FormData();
        formData.append('file', action.formulario.csv.file.originFileObj);
        

        let response = yield call(subirArchivoActivoCall, token, formData);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El archivo fue cargado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'cargarArchivoActivo' });
            yield put({ type: almacenesActions.GET_ARCHIVOS_ESPERA_REQUEST, page });
            yield put({ type: almacenesActions.SUBIR_ARCHIVO_ACTIVO_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.SUBIR_ARCHIVO_ACTIVO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.SUBIR_ARCHIVO_ACTIVO_FAILURE });
    }
}
export function* subirActivoArchivoSaga() {
    yield takeLatest(almacenesActions.SUBIR_ARCHIVO_ACTIVO_REQUEST, subirActivoArchivo);
}

function* borrarActivoArchivo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idArchivoMasivo = action.idArchivoMasivo;
        const page = action.page;
        console.log(page);

        let response = yield call(eliminarArchivoActivoCall, token, idArchivoMasivo);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El archivo fue cancelado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.GET_ARCHIVOS_ESPERA_REQUEST, page });
            yield put({ type: almacenesActions.ELIMINAR_ARCHIVO_ACTIVO_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.ELIMINAR_ARCHIVO_ACTIVO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.ELIMINAR_ARCHIVO_ACTIVO_FAILURE });
    }
}
export function* borrarActivoArchivoSaga() {
    yield takeLatest(almacenesActions.ELIMINAR_ARCHIVO_ACTIVO_REQUEST, borrarActivoArchivo);
}

function* exportarErroresActivoArchivo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idArchivoMasivo = action.idArchivoMasivo;

        let response = yield call(exportarErroresArchivoActivoCall, token, idArchivoMasivo);
        console.log(response);

        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Errores-Archivo-" + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".csv";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            
            yield put({ type: almacenesActions.EXPORTAR_ERRORES_ARCHIVO_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_ERRORES_ARCHIVO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.EXPORTAR_ERRORES_ARCHIVO_FAILURE });
    }
}
export function* exportarErroresActivoArchivoSaga() {
    yield takeLatest(almacenesActions.EXPORTAR_ERRORES_ARCHIVO_REQUEST, exportarErroresActivoArchivo);
}

function* procesarActivoArchivo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idArchivoMasivo = action.idArchivoMasivo;
        const page = action.page;
        console.log(page);

        let response = yield call(procesarArchivoActivoCall, token, idArchivoMasivo);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El archivo fue procesado con éxito, favor de revisar en estado 'Finalizado'.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.GET_ARCHIVOS_ESPERA_REQUEST, page });
            yield put({ type: almacenesActions.PROCESAR_ARCHIVO_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.PROCESAR_ARCHIVO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.PROCESAR_ARCHIVO_FAILURE });
    }
}
export function* procesarActivoArchivoSaga() {
    yield takeLatest(almacenesActions.PROCESAR_ARCHIVO_REQUEST, procesarActivoArchivo);
}

//CÁTALOGO ESTATUS DEL ACTIVO
function* listaEstatusActivosActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaEstatusActivoActivosCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaEstatusActivoActivos = response.data;

            yield put({ type: almacenesActions.LISTA_ESTATUS_ACTIVOS_SUCCESS, listaEstatusActivoActivos });
        }
        else {
            yield put({ type: almacenesActions.LISTA_ESTATUS_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_ESTATUS_ACTIVOS_FAILURE });
    }
}
export function* listaEstatusActivosActivosSaga() {
    yield takeLatest(almacenesActions.LISTA_ESTATUS_ACTIVOS_REQUEST, listaEstatusActivosActivos);
}

function* listaEstatusActivosBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let busqueda = '';
        if (action.busqueda) {
            console.log("ENTRA CON BUSQUEDA");
            busqueda = action.busqueda;
        }
        console.log(page);
        console.log(busqueda);
        const response = yield call(getEstatusActivosPageCall, token, page, busqueda);
        console.log(response);
        console.log(response.data.data)

        if (response.status == 200) {
            let listaEstatusActivoBusqueda = response.data.data;
            let listaEstatusActivoBusquedaCantidad = response.data.cantidad;
            
            yield put({ type: almacenesActions.ESTATUS_ACTIVO_BUSQUEDA_SUCCESS, listaEstatusActivoBusqueda, listaEstatusActivoBusquedaCantidad });
        }
        else {
            yield put({ type: almacenesActions.ESTATUS_ACTIVO_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.ESTATUS_ACTIVO_BUSQUEDA_FAILURE });
    }
}
export function* listaEstatusActivosBusquedaSaga() {
    yield takeLatest(almacenesActions.ESTATUS_ACTIVO_BUSQUEDA_REQUEST, listaEstatusActivosBusqueda);
}

function* crearNuevoEstatusActivo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idEstadoActivo": null,
            "descripcionC": action.formulario.descripcionC,
            "descripcionL": action.formulario.descripcionL,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }
        console.log(data);
        const response = yield call(crearEstatusActivoCall, token, data);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showModalNuevoEstatusActivo = false;
            const showSuccessMsg = true;
            const textMessage = "El estatus del activo " + action.formulario.descripcionC + " fue agregado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "crearEstatus" });
            yield put({ type: almacenesActions.NUEVO_ESTATUS_ACTIVO_SUCCESS });
            yield put({ type: almacenesActions.ESTATUS_ACTIVO_BUSQUEDA_REQUEST, page });
            yield put({ type: almacenesActions.LISTA_ESTATUS_ACTIVOS_REQUEST, page });
            yield put({ type: almacenesActions.SHOW_MODAL_NUEVO_ESTATUS_ACTIVO, showModalNuevoEstatusActivo });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.NUEVO_ESTATUS_ACTIVO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.NUEVO_ESTATUS_ACTIVO_FAILURE });
    }
}
export function* crearNuevoEstatusActivoSaga() {
    yield takeLatest(almacenesActions.NUEVO_ESTATUS_ACTIVO_REQUEST, crearNuevoEstatusActivo);
}

function* editarEstatusActivo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idEstadoActivo = action.EstatusActivoSeleccionado.idEstadoActivo;
        const data = {
            "idEstadoActivo": null,
            "descripcionC": action.formulario.descripcionC,
            "descripcionL": action.formulario.descripcionL,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }
        console.log(data);
        const response = yield call(editarEstatusActivoCall, token, data, idEstadoActivo);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showModalEditarEstatusActivo = false;
            const EstatusActivoSeleccionado = null;
            const limpiarFormulario = "editarEstatus";
            const showSuccessMsg = true;
            const textMessage = "El estatus del activo " + action.formulario.descripcionC + " fue actualizado con éxito";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario });
            yield put({ type: almacenesActions.EDITAR_ESTATUS_ACTIVO_SUCCESS });
            yield put({ type: almacenesActions.ESTATUS_ACTIVO_BUSQUEDA_REQUEST, page });
            yield put({ type: almacenesActions.LISTA_ESTATUS_ACTIVOS_REQUEST, page });
            yield put({ type: almacenesActions.SHOW_MODAL_EDITAR_ESTATUS_ACTIVO, showModalEditarEstatusActivo, EstatusActivoSeleccionado });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message + " .Modificar el estatus del activo.";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.EDITAR_ESTATUS_ACTIVO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.EDITAR_ESTATUS_ACTIVO_FAILURE });
    }
}
export function* editarEstatusActivoSaga() {
    yield takeLatest(almacenesActions.EDITAR_ESTATUS_ACTIVO_REQUEST, editarEstatusActivo);
}

function* cambiarEstadoEstatusActivo(action) {
    try {
        const token = sessionStorage.getItem('token');
        const idEstadoActivo = action.idEstadoActivo;

        const response = yield call(estadoEstatusActivoCall, token, idEstadoActivo);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showSuccessMsg = true;
            const textMessage = "El estado del estatus del activo se cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.ESTADO_ESTATUS_ACTIVO_SUCCESS });
            yield put({ type: almacenesActions.ESTATUS_ACTIVO_BUSQUEDA_REQUEST, page });
            yield put({ type: almacenesActions.LISTA_ESTATUS_ACTIVOS_REQUEST, page });
        }
        else {

            yield put({ type: almacenesActions.ESTADO_ESTATUS_ACTIVO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.ESTADO_ESTATUS_ACTIVO_FAILURE });
    }
}
export function* cambiarEstadoEstatusActivoSaga() {
    yield takeLatest(almacenesActions.ESTADO_ESTATUS_ACTIVO_REQUEST, cambiarEstadoEstatusActivo);
}

function* exportarExcelEstatusActivo(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(exportarExcelEstatusActivoCall, token);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el excel de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "EstatusActivos" + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_ESTATUS_ACTIVO_EXCEL_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el archivo";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_ESTATUS_ACTIVO_EXCEL_FAILURE });
        }
    } catch (error) {
       yield put({ type: almacenesActions.EXPORTAR_ESTATUS_ACTIVO_EXCEL_FAILURE });
    }
} export function* exportarExcelEstatusActivoSaga(){
    yield takeLatest(almacenesActions.EXPORTAR_ESTATUS_ACTIVO_EXCEL_REQUEST, exportarExcelEstatusActivo)
}

//  BUSQUEDA
//   ************   SAGA MOTIVOS DE SALIDA ************ //
function* listaMotivosActivos(action){
    try {
        const token = sessionStorage.getItem('token');

        const response = yield call(listaMotivosActivosCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaMotivosSalidaActivos = response.data;

            yield put({ type: almacenesActions.MOTIVOS_DE_SALIDA_ACTIVOS_SUCCESS, listaMotivosSalidaActivos });
        }
        else {
            yield put({ type: almacenesActions.MOTIVOS_DE_SALIDA_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.MOTIVOS_DE_SALIDA_ACTIVOS_FAILURE });
    }
}
export function* listaMotivosActivosSaga() {
    yield takeLatest(almacenesActions.MOTIVOS_DE_SALIDA_ACTIVOS_REQUEST, listaMotivosActivos);
}

//  BUSQUEDA
function* listaMotivosBusqueda(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let param = '';
        let field = 'idMotivoSalida';
        let order = 'ASC';
        
        if (action.busqueda.param) {
            param = 'param=' + action.busqueda.param + '&';
        }
        if (action.busqueda.field){
            field = 'field=' + action.busqueda.field + '&';
        }
        if (action.busqueda.order){
            order = 'order=' + action.busqueda.order ;
        }
        
        console.log(param)
        console.log(order)
        console.log(field)
        const response =yield call(listaMotivosBusquedaCall, token ,page, param, field, order)
        console.log(response);

        if (response.status === 200) {
            let listaMotivosSalidaBusqueda = response.data.data
            let listaMotivosSalidaBusquedaCantidad = response.data.cantidad

            yield put({ type: almacenesActions.LISTA_MOTIVOS_DE_SALIDA_BUSQUEDA_SUCCESS, listaMotivosSalidaBusqueda, listaMotivosSalidaBusquedaCantidad})
        } else {
            yield put({ type: almacenesActions.LISTA_MOTIVOS_DE_SALIDA_BUSQUEDA_FAILURE })
        }

    } catch (error) {
        yield put({ type: almacenesActions.LISTA_MOTIVOS_DE_SALIDA_BUSQUEDA_FAILURE })
    }
}

export function* listaMotivosBusquedaSaga(){
    yield takeLatest(almacenesActions.LISTA_MOTIVOS_DE_SALIDA_BUSQUEDA_REQUEST, listaMotivosBusqueda)
}
// ACTIVAR/DESACTIVAR ESTADO
function* cambiarEstadoMotivos(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idMotivoSalida = action.idMotivoSalida;

        const response = yield call(cambiarEstadoMotivosCall, token , idMotivoSalida)
        console.log(response);

        if (response.status === 200) {
            const page = action.page;
            const busqueda = {order: 'ASC', field: 'idMotivoSalida'};
            const showSuccessMsg = true;
            const textMessage = "El estado de motivo de salida cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.ESTADO_MOTIVOS_DE_SALIDA_SUCCESS})
            yield put({ type: almacenesActions.LISTA_MOTIVOS_DE_SALIDA_BUSQUEDA_REQUEST, page, busqueda})
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.ESTADO_MOTIVOS_DE_SALIDA_FAILURE})
        }
    } catch (error) {
        yield put({ type: almacenesActions.ESTADO_MOTIVOS_DE_SALIDA_FAILURE})
    }
}

export function* cambiarEstadoMotivosSaga(){
    yield takeLatest( almacenesActions.ESTADO_MOTIVOS_DE_SALIDA_REQUEST ,cambiarEstadoMotivos)
}
// CREAR MOTIVO
function* crearMotivoSalida(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token')
        const data = {
            "idMotivoSalida": null,
            "descripcion": action.formulario.descripcion,
            "tipoSalida": action.formulario.tipoSalida,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": null
        }
        const response = yield call(crearMotivosCall, token, data)
        console.log(response);
        if (response.status === 200) {
            const page = action.page;
            const busqueda = {order: 'ASC', field: 'idMotivoSalida'}
            const showModalNuevoMotivoSalida = false;
            const showSuccessMsg = true;
            const textMessage = "El motivo de salida fue creado correctamente"

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.NUEVO_MOTIVOS_DE_SALIDA_SUCCESS})
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearMotivo'});
            yield put({ type: almacenesActions.LISTA_MOTIVOS_DE_SALIDA_BUSQUEDA_REQUEST, page, busqueda})
            yield put({ type: almacenesActions.SHOW_MODAL_NUEVO_MOTIVOS_DE_SALIDA, showModalNuevoMotivoSalida})
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.NUEVO_MOTIVOS_DE_SALIDA_FAILURE})
        }
    } catch (error) {
         yield put({ type: almacenesActions.NUEVO_MOTIVOS_DE_SALIDA_FAILURE})
    }
}

export function* crearMotivoSalidaSaga(){
    yield takeLatest(almacenesActions.NUEVO_MOTIVOS_DE_SALIDA_REQUEST, crearMotivoSalida)
}
// EDITAR MOTIVO
function* editarMotivoSalida(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token')
        const idMotivoSalida = action.motivoSeleccionado.idMotivoSalida;

        const data = {
            "idMotivoSalida": idMotivoSalida,
            "descripcion": action.formulario.descripcion,
            "tipoSalida": action.formulario.tipoSalida,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": null
        }

        console.log('data: ', data);
        const response = yield call(editarMotivosCall, token, data, idMotivoSalida)
        console.log('reponse: ', response)

        if (response.status === 200) {
            const page = action.page;
            const busqueda = {order: 'ASC', field: 'idMotivoSalida'}
            const showModalEditarMotivoSalida = false;
            let motivoSeleccionado = null
            const showSuccessMsg = true;
            const textMessage = "El motivo de salida se modificó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarMotivo'});
            yield put({ type: almacenesActions.EDITAR_MOTIVOS_DE_SALIDA_SUCCESS})
            yield put({ type: almacenesActions.LISTA_MOTIVOS_DE_SALIDA_BUSQUEDA_REQUEST, page, busqueda})
            yield put({ type: almacenesActions.SHOW_MODAL_EDITAR_MOTIVOS_DE_SALIDA, showModalEditarMotivoSalida, motivoSeleccionado })
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.EDITAR_MOTIVOS_DE_SALIDA_FAILURE})
        }
    } catch (error) {
        yield put({ type: almacenesActions.EDITAR_MOTIVOS_DE_SALIDA_FAILURE})
    }
}

export function* editarMotivoSalidaSaga(){
    yield takeLatest(almacenesActions.EDITAR_MOTIVOS_DE_SALIDA_REQUEST, editarMotivoSalida)
}

function* exportarExcelMotivosSalidaActivos(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const order = action.order;
        const field = action.field;

        const response = yield call(exportarExcelMotivosSalidaActivosCall, token, order, field);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el excel de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "CategoriasMotivosDeSalida" + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_MOTIVOS_DE_SALIDA_ACTIVOS_EXCEL_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el archivo";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_MOTIVOS_DE_SALIDA_ACTIVOS_EXCEL_FAILURE });
        }
    } catch (error) {
       yield put({ type: almacenesActions.EXPORTAR_MOTIVOS_DE_SALIDA_ACTIVOS_EXCEL_FAILURE });
    }
} export function* exportarExcelMotivosSalidaActivosSaga(){
    yield takeLatest(almacenesActions.EXPORTAR_MOTIVOS_DE_SALIDA_ACTIVOS_EXCEL_REQUEST, exportarExcelMotivosSalidaActivos)
}

// ****** RANGOS DE RENOVACION **** //
function* listaRangosRenovacionActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaRangosRenovacionActivosCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaRangosRenovacionActivoActivos = response.data;

            yield put({ type: almacenesActions.LISTA_RANGOS_RENOVACION_ACTIVOS_SUCCESS, listaRangosRenovacionActivoActivos });
        }
        else {
            yield put({ type: almacenesActions.LISTA_RANGOS_RENOVACION_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_RANGOS_RENOVACION_ACTIVOS_FAILURE });
    }
}
export function* listaRangosRenovacionActivosSaga() {
    yield takeLatest(almacenesActions.LISTA_RANGOS_RENOVACION_ACTIVOS_REQUEST, listaRangosRenovacionActivos);
}

function* listaRangosRenovacionBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let busqueda = '';
        if (action.busqueda) {
            console.log("ENTRA CON BUSQUEDA");
            busqueda = action.busqueda;
        }
        console.log(page);
        console.log(busqueda);
        const response = yield call(getRangosRenovacionPageCall, token, page, busqueda);
        console.log(response);
        console.log(response.data.data)

        if (response.status == 200) {
            let listaRangosRenovacionActivoBusqueda = response.data.data;
            let listaRangosRenovacionActivoBusquedaCantidad = response.data.cantidad;
            for (let i=0; listaRangosRenovacionActivoBusqueda.length > i; i++) {
                if(listaRangosRenovacionActivoBusqueda[i].unidadMedidaByIdUnidadMedida) {
                    listaRangosRenovacionActivoBusqueda[i].unidadMedida = listaRangosRenovacionActivoBusqueda[i].unidadMedidaByIdUnidadMedida.unidadMedida
                }
            }            
            yield put({ type: almacenesActions.RANGOS_RENOVACION_ACTIVO_BUSQUEDA_SUCCESS, listaRangosRenovacionActivoBusqueda, listaRangosRenovacionActivoBusquedaCantidad });
        }
        else {
            yield put({ type: almacenesActions.RANGOS_RENOVACION_ACTIVO_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.RANGOS_RENOVACION_ACTIVO_BUSQUEDA_FAILURE });
    }
}
export function* listaRangosRenovacionBusquedaSaga() {
    yield takeLatest(almacenesActions.RANGOS_RENOVACION_ACTIVO_BUSQUEDA_REQUEST, listaRangosRenovacionBusqueda);
}

function* crearNuevoRangoRenovacion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idRangoRenovacion": null,
            "descripcion": action.formulario.descripcion,
            "maxNum": action.formulario.maxNum,
            "minNum": action.formulario.minNum,
            "idUnidadMedida": parseInt(action.formulario.idUnidadMedida),
            "css": '#000000	',
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
            "unidadMedidaByIdUnidadMedida": null
        }
        console.log(data);
        const response = yield call(crearRangoRenovacionCall, token, data);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showModalNuevoRangoRenovacionActivo = false;
            const showSuccessMsg = true;
            const textMessage = "El rango de renovación del activo " + action.formulario.descripcion + " fue agregado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "crearRango" });
            yield put({ type: almacenesActions.NUEVO_RANGO_RENOVACION_ACTIVO_SUCCESS });
            yield put({ type: almacenesActions.RANGOS_RENOVACION_ACTIVO_BUSQUEDA_REQUEST, page });
            yield put({ type: almacenesActions.LISTA_RANGOS_RENOVACION_ACTIVOS_REQUEST, page });
            yield put({ type: almacenesActions.SHOW_MODAL_NUEVO_RANGO_RENOVACION_ACTIVO, showModalNuevoRangoRenovacionActivo });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.NUEVO_RANGO_RENOVACION_ACTIVO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.NUEVO_RANGO_RENOVACION_ACTIVO_FAILURE });
    }
}
export function* crearNuevoRangoRenovacionSaga() {
    yield takeLatest(almacenesActions.NUEVO_RANGO_RENOVACION_ACTIVO_REQUEST, crearNuevoRangoRenovacion);
}

function* editarRangoRenovacion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idRangoRenovacion = action.RangoRenovacionActivoSeleccionado.idRangoRenovacion;
        let idUnidadMedida = action.formulario.unidadMedida;
        if (isNaN(idUnidadMedida)) {
            idUnidadMedida = action.RangoRenovacionActivoSeleccionado.idUnidadMedida
        }

        const data = {
            "idRangoRenovacion": null,
            "descripcion": action.formulario.descripcion,
            "maxNum": action.formulario.maxNum,
            "minNum": action.formulario.minNum,
            "idUnidadMedida": parseInt(idUnidadMedida),
            "css": '#000000	',
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
            "unidadMedidaByIdUnidadMedida": null
        }
        console.log(data);
        const response = yield call(editarRangoRenovacionCall, token, data, idRangoRenovacion);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showModalEditarRangoRenovacionActivo = false;
            const RangoRenovacionActivoSeleccionado = null;
            const limpiarFormulario = "editarRango";
            const showSuccessMsg = true;
            const textMessage = "El rango de renovación del activo " + action.formulario.descripcion + " fue actualizado con éxito";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario });
            yield put({ type: almacenesActions.EDITAR_RANGO_RENOVACION_ACTIVO_SUCCESS });
            yield put({ type: almacenesActions.RANGOS_RENOVACION_ACTIVO_BUSQUEDA_REQUEST, page });
            yield put({ type: almacenesActions.LISTA_RANGOS_RENOVACION_ACTIVOS_REQUEST, page });
            yield put({ type: almacenesActions.SHOW_MODAL_EDITAR_RANGO_RENOVACION_ACTIVO, showModalEditarRangoRenovacionActivo, RangoRenovacionActivoSeleccionado });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message + " Modificar el rango de renovación del activo.";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.EDITAR_RANGO_RENOVACION_ACTIVO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.EDITAR_RANGO_RENOVACION_ACTIVO_FAILURE });
    }
}
export function* editarRangoRenovacionSaga() {
    yield takeLatest(almacenesActions.EDITAR_RANGO_RENOVACION_ACTIVO_REQUEST, editarRangoRenovacion);
}

function* cambiarEstadoRangoRenovacion(action) {
    try {
        const token = sessionStorage.getItem('token');
        const idRangoRenovacion = action.idRangoRenovacion;

        const response = yield call(estadoRangoRenovacionCall, token, idRangoRenovacion);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showSuccessMsg = true;
            const textMessage = "El estado del rango de renovación del activo se cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.ESTADO_RANGO_RENOVACION_ACTIVO_SUCCESS });
            yield put({ type: almacenesActions.RANGOS_RENOVACION_ACTIVO_BUSQUEDA_REQUEST, page });
            yield put({ type: almacenesActions.LISTA_RANGOS_RENOVACION_ACTIVOS_REQUEST, page });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.ESTADO_RANGO_RENOVACION_ACTIVO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.ESTADO_RANGO_RENOVACION_ACTIVO_FAILURE });
    }
}
export function* cambiarEstadoRangoRenovacionSaga() {
    yield takeLatest(almacenesActions.ESTADO_RANGO_RENOVACION_ACTIVO_REQUEST, cambiarEstadoRangoRenovacion);
}

function* exportarExcelRangosRenovacionActivo(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(exportarExcelRangosRenovacionActivoCall, token);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el excel de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "RangosRenovacionActivo" + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_RANGO_RENOVACION_ACTIVO_EXCEL_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el archivo";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_RANGO_RENOVACION_ACTIVO_EXCEL_FAILURE });
        }
    } catch (error) {
       yield put({ type: almacenesActions.EXPORTAR_RANGO_RENOVACION_ACTIVO_EXCEL_FAILURE });
    }
} export function* exportarExcelRangosRenovacionActivoSaga(){
    yield takeLatest(almacenesActions.EXPORTAR_RANGO_RENOVACION_ACTIVO_EXCEL_REQUEST, exportarExcelRangosRenovacionActivo)
}

//  *****  SAGA REINGRESO ACTIVOS *** //
function* listareingresoFoliosActivos(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(ReingresofoliosActivosCall, token);
        console.log('response', response);
        if (response.status === 200) {
            const listaReingresoActivosFoliosAct = response.data;

            for(let i=0; i<listaReingresoActivosFoliosAct.length; i++){
                if(listaReingresoActivosFoliosAct[i].activoByIdActivo){
                    listaReingresoActivosFoliosAct[i].codigoEquipo = listaReingresoActivosFoliosAct[i].activoByIdActivo.codigoEquipo
                    listaReingresoActivosFoliosAct[i].codigoInterno = listaReingresoActivosFoliosAct[i].activoByIdActivo.codigoInterno
                    listaReingresoActivosFoliosAct[i].serieNum = listaReingresoActivosFoliosAct[i].activoByIdActivo.serieNum
                    listaReingresoActivosFoliosAct[i].sku = listaReingresoActivosFoliosAct[i].activoByIdActivo.sku
                } else {
                    listaReingresoActivosFoliosAct[i].codigoEquipo = "No tiene codigo equipo"
                    listaReingresoActivosFoliosAct[i].codigoInterno = "no tiene codigo interno"
                    listaReingresoActivosFoliosAct[i].serieNum = "No tiene numero de serie"
                    listaReingresoActivosFoliosAct[i].sku = "No tiene sku"
                }

                if(listaReingresoActivosFoliosAct[i].estadosActivosByIdEstadoActivo){
                    listaReingresoActivosFoliosAct[i].descripcion = listaReingresoActivosFoliosAct[i].estadosActivosByIdEstadoActivo.descripcionC
                } else {
                    listaReingresoActivosFoliosAct[i].descripcion = "No tiene descripcion"
                }

                if(listaReingresoActivosFoliosAct[i].ubicacionByIdUbicacion){
                    listaReingresoActivosFoliosAct[i].claveUbicacion = listaReingresoActivosFoliosAct[i].ubicacionByIdUbicacion.claveUbicacion
                } else {
                    listaReingresoActivosFoliosAct[i].claveUbicacion = "No tiene ubicacion"
                }
            }

            yield put({ type: almacenesActions.REINGRESO_ACTIVOS_LISTA_ACT_SUCCESS, listaReingresoActivosFoliosAct});
        } else {
            yield put({ type: almacenesActions.REINGRESO_ACTIVOS_LISTA_ACT_FAILURE});
        }
    } catch (error) {
        yield put({ type: almacenesActions.REINGRESO_ACTIVOS_LISTA_ACT_FAILURE});
    }
}
export function* listareingresoFoliosActivosSaga(){
    yield takeLatest(almacenesActions.REINGRESO_ACTIVOS_LISTA_ACT_REQUEST, listareingresoFoliosActivos);
}


function* listaReingresoBusqueda(action) {
    try {
        console.log("listaReingresoActivosBusqueda action",action);
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let folio = '';
        let field = 'field=fechaSalida&';
        let order = 'order=ASC';
        
        if (action.busqueda.folio) {
            folio = 'folio=' + action.busqueda.folio;
        }
        
        const response = yield call(listarReingresoActivosBusquedaCall, token , folio, page, field, order)
        console.log("listaReingresoActivosBusqueda response",response);

        if(response.status === 200){
            let listaReingresoActivosBusqueda = response.data.data;
            let listaReingresoActivosBusquedaCantidad = response.data.cantidad;
            console.log('listaReingresoActivosBusqueda en saga: ', listaReingresoActivosBusqueda)

            for (let i = 0; i < listaReingresoActivosBusqueda.length; i++) {
                listaReingresoActivosBusqueda.folio = listaReingresoActivosBusqueda[i].folio

                if(listaReingresoActivosBusqueda[i].activoByIdActivo){
                    listaReingresoActivosBusqueda[i].codigoInterno = listaReingresoActivosBusqueda[i].activoByIdActivo.codigoInterno
                    listaReingresoActivosBusqueda[i].codigoEquipo = listaReingresoActivosBusqueda[i].activoByIdActivo.codigoEquipo
                    listaReingresoActivosBusqueda[i].sku = listaReingresoActivosBusqueda[i].activoByIdActivo.sku
                    listaReingresoActivosBusqueda[i].serieNum = listaReingresoActivosBusqueda[i].activoByIdActivo.serieNum
                }
                if(listaReingresoActivosBusqueda[i].activosCaractByIdActivosCaract){
                    listaReingresoActivosBusqueda[i].descripcion = listaReingresoActivosBusqueda[i].activosCaractByIdActivosCaract.descripcion
                }
                if(listaReingresoActivosBusqueda[i].ubicacionByIdUbicacion){
                    listaReingresoActivosBusqueda[i].claveUbicacion = listaReingresoActivosBusqueda[i].ubicacionByIdUbicacion.claveUbicacion
                }
            }
            yield put({ type: almacenesActions.REINGRESO_ACTIVOS_BUSQUEDA_SUCCESS, listaReingresoActivosBusqueda, listaReingresoActivosBusquedaCantidad})
        } else {
            yield put({ type: almacenesActions.REINGRESO_ACTIVOS_BUSQUEDA_FAILURE})
        }
    } catch (error) {
        yield put({ type: almacenesActions.REINGRESO_ACTIVOS_BUSQUEDA_FAILURE})
    }
}
export function* listaReingresoBusquedaSaga(){
    yield takeLatest(almacenesActions.REINGRESO_ACTIVOS_BUSQUEDA_REQUEST, listaReingresoBusqueda)
}

// ***  CREAR REINGRESO ACTIVO **** //
function* crearReingresoActivo(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token')
        
        let idAreaFuncional = '';
        if(action.formulario.idAreaFuncional){
            idAreaFuncional = action.formulario.idAreaFuncional
        }
        let idUnidadFuncional = '';
        if(action.formulario.idUnidadFuncional){
            idUnidadFuncional = action.formulario.idUnidadFuncional
        }
        let idInteriorFuncional = '';
        if(action.formulario.idInteriorFuncional) {
            idInteriorFuncional = action.formulario.idInteriorFuncional
        }
        let idSubInteriorFuncional = '';      
        let idUsuarioRecibe = action.formulario.idUsuario;
        let idEstadoActivo = action.formulario.idEstadoActivo;
        let comentario = '';

        if(action.formulario.comentarioReingreso){
            comentario = action.formulario.comentarioReingreso
        }


        if(action.formulario.idSubInteriorFuncional){
            idSubInteriorFuncional = "&idSubInteriorFuncional=" + action.formulario.idSubInteriorFuncional;
        } 

        let data = [];
        let response = ''

        for(let i = 0; i < action.reingresoSeleccionado.length; i++){
            let reingreso = {
                "idMovimientoActivo": action.reingresoSeleccionado[i].idMovimientoActivo,
                "tipoMovimiento": action.reingresoSeleccionado[i].tipoMovimiento,
                "folio": action.reingresoSeleccionado[i].folio,
                "idServicio": parseInt(action.reingresoSeleccionado[i].idServicio),
                "idMotivoSalida": parseInt(action.reingresoSeleccionado[i].idMotivoSalida),
                "idEstadoActivo": parseInt(action.reingresoSeleccionado[i].idEstadoActivo),
                "idActivo": parseInt(action.reingresoSeleccionado[i].idActivo),
                "idUbicacion": null,
                "idUsuarioSalida": parseInt(action.reingresoSeleccionado[i].idUsuarioSalida),
                "idUsuarioReingreso":  null,
                "comentarioSalida":  action.reingresoSeleccionado[i].comentarioSalida,
                "condicion":  action.reingresoSeleccionado[i].condicion,
                "estadoFolio":  action.reingresoSeleccionado[i].estadoFolio,
                "fechaSalida": action.reingresoSeleccionado[i].fechaSalida,
                "fechaProbableRetorno": action.reingresoSeleccionado[i].fechaProbableRetorno,
                "fechaRetorno": null,
                "rutaArchivo":null,
            }
            data.push(reingreso)
        }
        
        console.log('data', data);

        if(idAreaFuncional === '' && idUnidadFuncional === '' && idInteriorFuncional === ''){
            response = yield call(crearReingresoActivoSinAsignacionCall, token, data, idUsuarioRecibe, idEstadoActivo, comentario)
        } else {
            response = yield call(crearReingresoActivoCall, token, data, idAreaFuncional, idUnidadFuncional, idInteriorFuncional, idSubInteriorFuncional, idUsuarioRecibe, idEstadoActivo, comentario)
        }
         
        console.log('response', response)

        let descargar = true;
        let hasSelected = true;


        if (response.status === 200) {
            console.log('dentro if');
            const page = action.page;
            const busqueda = {order: 'ASC', field: 'fechaSalida'}
            const showModalReingresoActivos = false;
            const reingresoSeleccionado = null;
            const showSuccessMsg = true;
            const textMessage = "El reingreso de activo se creó de forma correcta";

            descargar = false;
            hasSelected = false;

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Reingreso " + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            const asignacionPDF = link;
           
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearReingresoActivos' });
            yield put({ type: almacenesActions.NUEVO_REINGRESO_ACTIVO_SUCCESS, asignacionPDF, descargar: descargar, hasSelected: hasSelected });
            yield put({ type: almacenesActions.REINGRESO_ACTIVOS_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: almacenesActions.SHOW_MODAL_NUEVO_REINGRESO, showModalReingresoActivos, reingresoSeleccionado });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.NUEVO_REINGRESO_ACTIVO_FAILURE})
        }
    } catch (error) {
        yield put({ type: almacenesActions.NUEVO_REINGRESO_ACTIVO_FAILURE})
    }
}
export function* crearReingresoActivoSaga(){
    yield takeLatest(almacenesActions.NUEVO_REINGRESO_ACTIVO_REQUEST, crearReingresoActivo)
}

// Salida de Bienes :)
// Generador de Folio
function* generadorFolioSalida(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const tipoMovimiento = action.tipoMovimiento;

        const response = yield call(generadorFolioSalidaCall, token , tipoMovimiento)
        console.log(response);

        if (response.status === 200) {
            let tipoMovimiento = response.data;
            yield put({ type: almacenesActions.GENERAR_FOLIO_SALIDA_SUCCESS, tipoMovimiento })

        } else {
            yield put({ type: almacenesActions.GENERAR_FOLIO_SALIDA_FAILURE })
        }
    } catch (error) {
        yield put({ type: almacenesActions.GENERAR_FOLIO_SALIDA_FAILURE })
    }
}

export function* generadorFolioSalidaSaga(){
    yield takeLatest( almacenesActions.GENERAR_FOLIO_SALIDA_REQUEST, generadorFolioSalida)
}

// Salida de Bienes - Busqueda Activos en operacion por Servicio sin page

function* listaActivosEnOperacionServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idServicio = action.idServicio;

        const response = yield call(listaActivosEnOperacionServicioCall, token, idServicio);
        console.log(response);

        if (response.status == 200) {
            const listaActivosEnOperacionServicio = response.data;

            console.log('listaActivosEnOperacionServicio en SAGA',response.data);

            for(let i = 0; i < listaActivosEnOperacionServicio.length; i++){
                // activoCaractByIdActivoCaract
                if(listaActivosEnOperacionServicio[i].activoCaractByIdActivoCaract){
                    listaActivosEnOperacionServicio[i].descripcion = listaActivosEnOperacionServicio[i].activoCaractByIdActivoCaract.descripcion;
                }

                // estadoActivoByIdEstadoActivo
                if (listaActivosEnOperacionServicio[i].estadoActivoByIdEstadoActivo) {
                    listaActivosEnOperacionServicio[i].estadoActivo = listaActivosEnOperacionServicio[i].estadoActivoByIdEstadoActivo.descripcionC;
                }
                else {
                    listaActivosEnOperacionServicio[i].estadoActivo = "No tiene estatus";
                }

                // ubicacionByIdUbicacion
                if(listaActivosEnOperacionServicio[i].ubicacionByIdUbicacion) {
                    listaActivosEnOperacionServicio[i].claveUbicacion = listaActivosEnOperacionServicio[i].ubicacionByIdUbicacion.claveUbicacion;
            
                    // areaFuncionalByIdAreaFuncional
                    if (listaActivosEnOperacionServicio[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional) {
                        listaActivosEnOperacionServicio[i].areaFuncional = listaActivosEnOperacionServicio[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                    }

                    // unidadFuncionalByIdUnidadFuncional
                    if (listaActivosEnOperacionServicio[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional) {
                        listaActivosEnOperacionServicio[i].unidadFuncional = listaActivosEnOperacionServicio[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                    }
                    // interiorFuncionalByIdInteriorFuncional
                    if (listaActivosEnOperacionServicio[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional) {
                        listaActivosEnOperacionServicio[i].interiorFuncional = listaActivosEnOperacionServicio[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional;
                    }
                    else{
                        listaActivosEnOperacionServicio[i].interiorFuncional = "No tiene interior";
                    }

                    // subInteriorFuncionalByIdSubInteriorFuncional
                    if (listaActivosEnOperacionServicio[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional) {
                        listaActivosEnOperacionServicio[i].subInteriorFuncional = listaActivosEnOperacionServicio[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                    }
                    else {
                        listaActivosEnOperacionServicio[i].subInteriorFuncional = "No tiene subinterior";
                    }
                }
            }

            yield put({ type: almacenesActions.LISTA_ACTIVOS_OPERACION_SERVICIO_SUCCESS, listaActivosEnOperacionServicio });
        }
        else {
            yield put({ type: almacenesActions.LISTA_ACTIVOS_OPERACION_SERVICIO_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_ACTIVOS_OPERACION_SERVICIO_FAILURE });
    }
}
export function* listaActivosEnOperacionServicioSaga() {
    yield takeLatest(almacenesActions.LISTA_ACTIVOS_OPERACION_SERVICIO_REQUEST, listaActivosEnOperacionServicio);
}

// Salida de Bienes - Busqueda de Activos por Servicio
function* listaSalidaDeBienesBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idServicio = action.idServicio;
        let page = action.page - 1;
        let busqueda = '';

        if (action.busqueda) {
            busqueda =  action.busqueda;
        }

        console.log('page: ', page);
        console.log('busqueda: ', busqueda);
        console.log('idServicio: ', idServicio);

        const response = yield call(listaSalidaDeBienesBusquedaCall, token, idServicio, page, busqueda);

        console.log(response);
        console.log(response.data.data)

        if (response.status === 200) {
            let listaSalidaDeBienesBusqueda = response.data.data;
            let listaSalidaDeBienesBusquedaCantidad = response.data.cantidad;
    
            for(let i = 0; i < listaSalidaDeBienesBusqueda.length; i++){
                // activoCaractByIdActivoCaract
                if(listaSalidaDeBienesBusqueda[i].activoCaractByIdActivoCaract){
                    listaSalidaDeBienesBusqueda[i].descripcion = listaSalidaDeBienesBusqueda[i].activoCaractByIdActivoCaract.descripcion;
                }

                // estadoActivoByIdEstadoActivo
                if (listaSalidaDeBienesBusqueda[i].estadoActivoByIdEstadoActivo) {
                    listaSalidaDeBienesBusqueda[i].estadoActivo = listaSalidaDeBienesBusqueda[i].estadoActivoByIdEstadoActivo.descripcionC;
                }
                else {
                    listaSalidaDeBienesBusqueda[i].estadoActivo = "No tiene estatus";
                }

                // ubicacionByIdUbicacion
                if(listaSalidaDeBienesBusqueda[i].ubicacionByIdUbicacion) {
                    listaSalidaDeBienesBusqueda[i].claveUbicacion = listaSalidaDeBienesBusqueda[i].ubicacionByIdUbicacion.claveUbicacion;
            
                    // areaFuncionalByIdAreaFuncional
                    if (listaSalidaDeBienesBusqueda[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional) {
                        listaSalidaDeBienesBusqueda[i].areaFuncional = listaSalidaDeBienesBusqueda[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                    }

                    // unidadFuncionalByIdUnidadFuncional
                    if (listaSalidaDeBienesBusqueda[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional) {
                        listaSalidaDeBienesBusqueda[i].unidadFuncional = listaSalidaDeBienesBusqueda[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                    }
                    // interiorFuncionalByIdInteriorFuncional
                    if (listaSalidaDeBienesBusqueda[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional) {
                        listaSalidaDeBienesBusqueda[i].interiorFuncional = listaSalidaDeBienesBusqueda[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional;
                    }
                    else{
                        listaSalidaDeBienesBusqueda[i].interiorFuncional = "No tiene interior";
                    }

                    // subInteriorFuncionalByIdSubInteriorFuncional
                    if (listaSalidaDeBienesBusqueda[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional) {
                        listaSalidaDeBienesBusqueda[i].subInteriorFuncional = listaSalidaDeBienesBusqueda[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                    }
                    else {
                        listaSalidaDeBienesBusqueda[i].subInteriorFuncional = "No tiene subinterior";
                    }
                }
            }

            console.log('listaSalidaDeBienesBusqueda', listaSalidaDeBienesBusqueda)
          
            yield put({ type: almacenesActions.LISTA_SALIDA_BIENES_BUSQUEDA_SUCCESS, listaSalidaDeBienesBusqueda, listaSalidaDeBienesBusquedaCantidad });
        }
        else {
            yield put({ type: almacenesActions.LISTA_SALIDA_BIENES_BUSQUEDA_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_SALIDA_BIENES_BUSQUEDA_FAILURE });
    }
}
export function* listaSalidaDeBienesBusquedaSaga() {
    yield takeLatest(almacenesActions.LISTA_SALIDA_BIENES_BUSQUEDA_REQUEST, listaSalidaDeBienesBusqueda);
}

// Crear Salida de Bienes :)
function* crearSalidaDeBienes(action) {
    try {
        console.log("action crearSalidaDeBienes",action);
        const token = sessionStorage.getItem('token');
        let data = [];    
        let idUbicacionDestino = null;
        let ubicacionDestino = '';
        let usuarioRecibe = '';
        let condicion = '';
        let condicionPath = '';
        let fechaRetorno = null;
        let comentarioSalida = '';
        let comentarioReingreso = '';

        if(action.formulario.todoUbicacion){
            idUbicacionDestino = parseInt(action.formulario.todoUbicacion);
            ubicacionDestino = 'idUbicacionDestino=' + action.formulario.todoUbicacion + "&";
        }

        if(action.formulario.usuarioResponsable){
            usuarioRecibe = action.formulario.usuarioResponsable;
        }

        if(action.formulario.condicionBien){
            condicion = action.formulario.condicionBien;
            condicionPath = '&condicion=' + action.formulario.condicionBien;
        }

        if(action.formulario.comentarioSalida){
            comentarioSalida = action.formulario.comentarioSalida;
        }

        if(action.formulario.fechaRetorno){
            fechaRetorno = action.formulario.fechaRetorno;
        }

        for(let i = 0; i < action.salidaActivoSeleccionado.length; i ++){
            let rp = action.salidaActivoSeleccionado[i];
            const activo = {
                "comentarioReingreso": comentarioReingreso,
                "comentarioSalida": comentarioSalida,
                "condicion": condicion,
                "fechaProbableRetorno": fechaRetorno,
                "idActivo": rp.idActivo,
                "idMotivoSalida": action.idMotivoSalidaLocal,
                "idServicio": action.idServicioSalida,
                "idUbicacion": idUbicacionDestino,
            }
            data.push(activo);
        }
        console.log('data crearSalidaDeBienes', data)      

        const formData = new FormData();
        
        formData.append('file', action.formularioPDF.pdf.file.originFileObj);
        
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));

        console.log("lo que mando crearSalidaDeBienes ", "data", data, "usuarioRecibe",usuarioRecibe,"condicionPath",condicionPath, "ubicacionDestino",ubicacionDestino)
        
        const response = yield call(crearSalidaDeBienesCall, token, formData, ubicacionDestino, usuarioRecibe, condicionPath);
        console.log('response crearSalidaDeBienes: ',response);

        let descargar = true;
        let hasSelected = true;

        if(response.status == 200){
            const page = action.page;
            const busqueda = '';
            const showSuccessMsg = true;
            const textMessage = "La solicitud de salida se creó de forma correcta";
            descargar = false;
            hasSelected = false;

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = 'Solicitud_Salida ' + new Date().getDate() + '-' + + new Date().getMonth()  + '-' + + new Date().getFullYear() + ' ' + new Date().getHours() + ' ' + new Date().getMinutes() + ' ' + new Date().getSeconds() + '.pdf';

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            const salidaPDF = link;

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearSolicitudDeSalida' });
            yield put({ type: almacenesActions.NUEVA_SOLICITUD_SALIDA_BIENES_SUCCESS, salidaPDF, descargar: descargar, hasSelected: hasSelected });
            yield put({ type: almacenesActions.SHOW_MODAL_SALIDA_BIENES, showModalSalidaDeBienes: false });
        }
        else{
            const showErrorMsg = true;
            const textMessage = "Hubo un error al generar la solicitud de salida";
            //response.message;
            console.log('response.message', response.message)
            console.log('Entra a else 200');

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.NUEVA_SOLICITUD_SALIDA_BIENES_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.NUEVA_SOLICITUD_SALIDA_BIENES_FAILURE, descargar: true });
    }
}
export function* crearSalidaDeBienesSaga() {
    yield takeLatest(almacenesActions.NUEVA_SOLICITUD_SALIDA_BIENES_REQUEST, crearSalidaDeBienes);
}

//Requesición por Actualización Tecnológica
function* listaActualizacionActivosBusqueda(action) {
    try {
        console.log("action listaActualizacionActivosBusqueda",action);
        const token = sessionStorage.getItem('token');
        let page = action.page>1 ? (action.page -1)*10 : action.page -1;
        let {formulario,listaAreasFuncionalesActivas,listaServiciosActivosF, rangoRenovacionData} = action
        formulario.busqueda = action.busqueda;
        const {maximoDias, minimoDias} = rangoRenovacionData;
        let {idServicio, idAreaFuncional,fecha, busqueda = ""} = formulario;
        fecha = fecha.format('YYYY-MM-DD');

        if (!idServicio || idServicio.length===0){
            let serviciosArray = [];
            listaServiciosActivosF.map(item => serviciosArray.push(item.idServicio))
            idServicio = serviciosArray;
        } else {
            idServicio = idServicio.map(item =>parseInt(item.split('-')[0]));       
        }
        if (!idAreaFuncional || idAreaFuncional.length===0){
            let areasFuncionalesArray = [];
            listaAreasFuncionalesActivas.map(item => areasFuncionalesArray.push(item.idAreaFuncional));
            idAreaFuncional = areasFuncionalesArray;
        } else {
            idAreaFuncional = idAreaFuncional.map(item => parseInt(item));
        }

        const data = {
            "idsServicios":idServicio,
            "idsAreas":idAreaFuncional
        }
        console.log("listaActualizacionActivosBusqueda data",data)

        console.log("lo que mando listaActualizacionActivosBusqueda", "maximoDias", maximoDias, "minimoDias", minimoDias, "busqueda", busqueda, "fecha", fecha, "page", page);
        const response = yield call(getListaActualizacionActivosPageCall, token, data, maximoDias, minimoDias, fecha, page, busqueda);
        console.log("response listaActualizacionActivosBusqueda",response);

        if (response.status == 200) {
            let listaActualizacionActivosBusqueda = response.data.data;
            let listaActualizacionActivosBusquedaCantidad = response.data.cantidad;

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            for (let i=0; listaActualizacionActivosBusqueda.length>i; i++) {
                let fechaInstalacion = listaActualizacionActivosBusqueda[i].fechaInstalacion.split('T')[0];
                listaActualizacionActivosBusqueda[i].fechaInstalacion = fechaInstalacion.split('-')[2] + '-' + fechaInstalacion.split('-')[1] + '-' + fechaInstalacion.split('-')[0]; 
                let fechaAlta = listaActualizacionActivosBusqueda[i].fechaAlta.split('T')[0];
                listaActualizacionActivosBusqueda[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0]; 
                if(listaActualizacionActivosBusqueda[i].diasParaVencer<0) {
                    listaActualizacionActivosBusqueda[i].diasParaVencer = 0;
                }

                if(listaActualizacionActivosBusqueda[i].costoUnidad){
                    listaActualizacionActivosBusqueda[i].precioTabla = '$ ' + numberWithCommas(parseInt(listaActualizacionActivosBusqueda[i].costoUnidad).toFixed(2));
                } else {
                    listaActualizacionActivosBusqueda[i].precioTabla = '$ 0.00';
                }

            }
                       
            yield put({ type: almacenesActions.ACTUALIZACION_ACTIVO_BUSQUEDA_SUCCESS, listaActualizacionActivosBusqueda, listaActualizacionActivosBusquedaCantidad });
        }
        else {
            yield put({ type: almacenesActions.ACTUALIZACION_ACTIVO_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log("error listaActualizacionActivosBusqueda", error);
        yield put({ type: almacenesActions.ACTUALIZACION_ACTIVO_BUSQUEDA_FAILURE });
    }
}
export function* listaActualizacionActivosBusquedaSaga() {
    yield takeLatest(almacenesActions.ACTUALIZACION_ACTIVO_BUSQUEDA_REQUEST, listaActualizacionActivosBusqueda);
}

function* ActualizacionActivosDescargaArchivo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let {formulario,listaAreasFuncionalesActivas,listaServiciosActivosF, rangoRenovacionData} = action
        formulario.busqueda = action.busqueda;
        const {maximoDias, minimoDias} = rangoRenovacionData;
        let {idServicio, idAreaFuncional,fecha, busqueda = ""} = formulario;
        fecha = fecha.format('YYYY-MM-DD');

        if (!idServicio || idServicio.length===0){
            let serviciosArray = [];
            listaServiciosActivosF.map(item => serviciosArray.push(item.idServicio))
            idServicio = serviciosArray;
        } else {
            idServicio = idServicio.map(item =>parseInt(item.split('-')[0]));       
        }
        if (!idAreaFuncional || idAreaFuncional.length===0){
            let areasFuncionalesArray = [];
            listaAreasFuncionalesActivas.map(item => areasFuncionalesArray.push(item.idAreaFuncional));
            idAreaFuncional = areasFuncionalesArray;
        } else {
            idAreaFuncional = idAreaFuncional.map(item => parseInt(item));
        }

        const data = {
            "idsServicios":idServicio,
            "idsAreas":idAreaFuncional
        }

        console.log(data, maximoDias, minimoDias, busqueda, fecha);
        let response = yield call(ActualizacionActivosDescargaArchivoCall, token, data, maximoDias, minimoDias, fecha, busqueda);
        console.log(response);

        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "ActualizacionTecnologica-Archivo-" + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            
            yield put({ type: almacenesActions.ACTUALIZACION_ACTIVO_DESCARGA_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.ACTUALIZACION_ACTIVO_DESCARGA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.ACTUALIZACION_ACTIVO_DESCARGA_FAILURE });
    }
}
export function* ActualizacionActivosDescargaArchivoSaga() {
    yield takeLatest(almacenesActions.ACTUALIZACION_ACTIVO_DESCARGA_REQUEST, ActualizacionActivosDescargaArchivo);
}

// Catálogo de Almacenes
// Lista Almacenes Activos
function* listaAlmacenesActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaAlmacenesActivosCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaAlmacenesActivos = response.data;

            yield put({ type: almacenesActions.ALMACENES_ACTIVOS_BUSQUEDA_SUCCESS, listaAlmacenesActivos });
        }
        else {
            yield put({ type: almacenesActions.ALMACENES_ACTIVOS_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.ALMACENES_ACTIVOS_BUSQUEDA_FAILURE });
    }
}
export function* listaAlmacenesActivosSaga() {
    yield takeLatest(almacenesActions.ALMACENES_ACTIVOS_BUSQUEDA_REQUEST, listaAlmacenesActivos);
}

// Lista búsqueda Almacenes
function* listaAlmacenesBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let param = '';
        let order = 'idAlmacen';
        let field = 'ASC';
        if (action.busqueda.param) {
            param = 'param=' + action.busqueda.param + '&';
        }
        if (action.busqueda.order){
            order = 'order=' + action.busqueda.order + '&';
        }
        if (action.busqueda.field){
            field = 'field=' + action.busqueda.field + '&';
        }

        const response = yield call(listaAlmacenesBusquedaCall, token, page, param, order, field);
        console.log(response);

        if(response.status == 200){
            let listaAlmacenesBusquedaCantidad = response.data.cantidad;
            let listaAlmacenesBusqueda = response.data.data;
            
            for(let i=0; i<listaAlmacenesBusqueda.length; i++){
                let fechaAlta = listaAlmacenesBusqueda[i].fechaAlta.split('T')[0];
                listaAlmacenesBusqueda[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];

                let fechaActualizacion = listaAlmacenesBusqueda[i].fechaActualizacion.split('T')[0];
                listaAlmacenesBusqueda[i].fechaActualizacion = fechaActualizacion.split('-')[2] + '-' + fechaActualizacion.split('-')[1] + '-' + fechaActualizacion.split('-')[0];

                // areaFuncionalByIdAreaFuncional
                if (listaAlmacenesBusqueda[i].areaFuncionalByIdAreaFuncional) {
                    listaAlmacenesBusqueda[i].areaFuncional = listaAlmacenesBusqueda[i].areaFuncionalByIdAreaFuncional.areaFuncional;
                }

                if(listaAlmacenesBusqueda[i].serviciosByIdAlmacen) {
                    listaAlmacenesBusqueda[i].idServicio = '';
                    listaAlmacenesBusqueda[i].nombreServicio = '';
                    listaAlmacenesBusqueda[i].servicios = [];
                    for(let x=0; x<listaAlmacenesBusqueda[i].serviciosByIdAlmacen.length; x++) {
                        listaAlmacenesBusqueda[i].nombreServicio += '-' +  listaAlmacenesBusqueda[i].serviciosByIdAlmacen[x].nombre + ' ';
                        listaAlmacenesBusqueda[i].servicios.push(listaAlmacenesBusqueda[i].serviciosByIdAlmacen[x].nombre);   
                    }
                }

                if(listaAlmacenesBusqueda[i].caracteristica){
                    listaAlmacenesBusqueda[i].caracteristicaTabla = 'Sí'
                } else {
                    listaAlmacenesBusqueda[i].caracteristicaTabla = 'No'
                }

                // for(let x=0; x<listaAlmacenesBusqueda[i].serviciosByIdAlmacen.length; x++) {
                //     listaAlmacenesBusqueda[i].nombreServicio += '-' +  listaAlmacenesBusqueda[i].serviciosByIdAlmacen[x].nombre + ' ';
                //     listaAlmacenesBusqueda[i].servicios.push(listaAlmacenesBusqueda[i].serviciosByIdAlmacen[x].nombre);   
                // }
            }

            yield put({ type: almacenesActions.ALMACEN_BUSQUEDA_SUCCESS, listaAlmacenesBusqueda, listaAlmacenesBusquedaCantidad });
        }
        else{
              yield put({ type: almacenesActions.ALMACEN_BUSQUEDA_FAILURE });
        }
     
    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.ALMACEN_BUSQUEDA_FAILURE });
    }
}
export function* listaAlmacenesBusquedaSaga() {
    yield takeLatest(almacenesActions.ALMACEN_BUSQUEDA_REQUEST, listaAlmacenesBusqueda);
}

// Crear Almacen
function* crearAlmacen(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let caracteristica = false;
        let servicios = [];
        let allServices = action.checked;
        if(action.formulario.caracteristicaInventario){
            caracteristica = true;
        }
        console.log('checked', action.checked)
        if(allServices === true){
            for(let i = 0; i < action.listaServiciosActivosF.length; i ++){
                servicios[i] = action.listaServiciosActivosF[i];
            }
            console.log('servicios', servicios)
        } else {
            for(let i=0; i<action.formulario.idServicio.length; i++){
                for(let x=0; x<action.listaServiciosActivosF.length; x++){
                    if(parseInt(action.formulario.idServicio[i]) === action.listaServiciosActivosF[x].idServicio){
                        
                        const servicio = {
                            "idServicio": action.listaServiciosActivosF[x].idServicio,
                            "idGrupoSer": action.listaServiciosActivosF[x].idGrupoSer,
                            "nomenclatura": action.listaServiciosActivosF[x].nomenclatura,
                            "nombre": action.listaServiciosActivosF[x].nombre,
                            "nombreC": action.listaServiciosActivosF[x].nombreC,
                            "nombreManual": action.listaServiciosActivosF[x].nombreManual,
                            "rutaManual": action.listaServiciosActivosF[x].rutaManual,
                            "urlImagen": action.listaServiciosActivosF[x].urlImagen,
                            "peso": action.listaServiciosActivosF[x].peso,
                            "observaciones": action.listaServiciosActivosF[x].observaciones,
                            "fechaAlta": null,
                            "fechaActualizacion": null,
                            "activo": true,
                            "ponderacionByIdServicio": null,
                            "contratoByIdContrato": null,
                            "grupoSerByIdGrupoSer": null
                        }
                        servicios.push(servicio);
                    }
                }
            }
        }
        console.log('servicios', servicios)
        const data = {
            "idAlmacen": null,
            "nombreC": (action.formulario.nombreC).trim(),
            "nombreL": (action.formulario.nombreL).trim(),
            "caracteristica": caracteristica,
            "serviciosByIdAlmacen": servicios,
            "idAreaFuncional": action.formulario.idAreaFuncional,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
        }

        console.log(data);
        const response = yield call(crearAlmacenCall, token, data)
        console.log(response)

        if(response.status === 200){
            const page = action.page;
            const busqueda = {order: 'ASC', field: 'idAlmacen'};
            const showModalNuevoAlmacen = false;
            const showSuccessMsg = true;
            const textMessage = "El almacen se creó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.NUEVO_ALMACEN_SUCCESS})
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearAlmacen'});
            yield put({ type: almacenesActions.ALMACEN_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: almacenesActions.SHOW_MODAL_NUEVO_ALMACEN, showModalNuevoAlmacen});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.NUEVO_ALMACEN_FAILURE})
        }
    } catch (error) {
        yield put({ type: almacenesActions.NUEVO_ALMACEN_FAILURE})
    }
}
export function* crearAlmacenSaga(){
    yield takeLatest(almacenesActions.NUEVO_ALMACEN_REQUEST, crearAlmacen)
}

// Activar-Desactivar Almacen
function* cambiarEstadoAlmacen(action) {
    try {
         console.log(action);
         const token = sessionStorage.getItem('token');
         const idAlmacen  = action.idAlmacen;

         const response = yield call(estadoAlmacenCall, token, idAlmacen);
         console.log(response);

         if(response.status === 200){
              const page = action.page;
              const busqueda = action.busqueda;
              const showSuccessMsg = true;
              const textMessage = "El estado del almacen cambió de forma correcta";

              yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
              yield put({ type: almacenesActions.ESTADO_ALMACEN_SUCCESS });
              yield put({ type: almacenesActions.ALMACEN_BUSQUEDA_REQUEST, page, busqueda });
         }
         else{
              const showErrorMsg = true;
              const textMessage = response.message;

              yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

              yield put({ type: almacenesActions.ESTADO_ALMACEN_FAILURE });
         }

    } catch (error) {
         console.log(error);
         yield put({ type: almacenesActions.ESTADO_ALMACEN_FAILURE });
    }
}
export function* cambiarEstadoAlmacenSaga() {
    yield takeLatest(almacenesActions.ESTADO_ALMACEN_REQUEST, cambiarEstadoAlmacen);
}

// Editar Almacen
function* editarAlmacen(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idAlmacen = parseInt(action.almacenSeleccionado.idAlmacen);
        let servicios = [];
        let allServices = action.checked;
        let {areaFuncional} = action.formulario;

        areaFuncional = isNaN(areaFuncional) ? action.almacenSeleccionado.idAreaFuncional : areaFuncional;

        console.log('allServices', allServices)
        if(allServices){
            for(let i = 0; i < action.listaServiciosActivosF.length; i ++){
                servicios[i] = action.listaServiciosActivosF[i];
            }
        } else {

            for (let i=0; i<action.formulario.servicios.length; i++) {
                if(action.almacenSeleccionado.serviciosByIdAlmacen) {
                    for(let y=0; y<action.almacenSeleccionado.serviciosByIdAlmacen.length; y++) {
                        if(action.formulario.servicios[i] === action.almacenSeleccionado.serviciosByIdAlmacen[y].nombre){
                            servicios.push(action.almacenSeleccionado.serviciosByIdAlmacen[y])
                        }
                    }
                }
                
                for(let x=0; x<action.listaServiciosActivosF.length; x++){
                    if(parseInt(action.formulario.servicios[i].split('-')[0]) === action.listaServiciosActivosF[x].idServicio){
                        console.log('entra al if servicio')
                        const servicio = {
                            "idServicio": action.listaServiciosActivosF[x].idServicio,
                            "idGrupoSer": action.listaServiciosActivosF[x].idGrupoSer,
                            "nomenclatura": action.listaServiciosActivosF[x].nomenclatura,
                            "nombre": action.listaServiciosActivosF[x].nombre,
                            "nombreC": action.listaServiciosActivosF[x].nombreC,
                            "nombreManual": action.listaServiciosActivosF[x].nombreManual,
                            "rutaManual": action.listaServiciosActivosF[x].rutaManual,
                            "urlImagen": action.listaServiciosActivosF[x].urlImagen,
                            "peso": action.listaServiciosActivosF[x].peso,
                            "observaciones": action.listaServiciosActivosF[x].observaciones,
                            "fechaAlta": null,
                            "fechaActualizacion": null,
                            "activo": true,
                            "ponderacionByIdServicio": null,
                            "contratoByIdContrato": null,
                            "grupoSerByIdGrupoSer": null
                        }
                        servicios.push(servicio);
                    }
                }
            }
        }

        console.log('servicios', servicios)
        const data = {
            "idAlmacen": null,
            "nombreC": (action.formulario.nombreC).trim(),
            "nombreL": (action.formulario.nombreL).trim(),
            "caracteristica": action.formulario.caracteristica,
            "idAreaFuncional": parseInt(areaFuncional),
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
            "serviciosByIdAlmacen": servicios
        }
        console.log('data', data);
        const response = yield call(editarAlmacenCall, token, data, idAlmacen);
        console.log('response', response);

        if(response.status === 200){
            const page = action.page;
            const busqueda = action.busqueda;
            const showModalEditarAlmacen= false;
            const showSuccessMsg = true;
            const textMessage = "El almacen " + action.formulario.nombreC + " se modificó de forma correcta";
            let almacenSeleccionado = null;

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarAlmacen'});
            yield put({ type: almacenesActions.EDITAR_ALMACEN_SUCCESS });
            yield put({ type: almacenesActions.ALMACEN_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: almacenesActions.SHOW_MODAL_EDITAR_ALMACEN, showModalEditarAlmacen, almacenSeleccionado });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.EDITAR_ALMACEN_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
yield put({ type: almacenesActions.EDITAR_ALMACEN_FAILURE });
    }
}
export function* editarAlmacenSaga() {
    yield takeLatest(almacenesActions.EDITAR_ALMACEN_REQUEST, editarAlmacen);
}

//Exportar almancenes
function* exportarExcelAlmacenes(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const order = action.order;
        const field = action.field;

        const response = yield call(exportarExcelAlmacenesCall, token, order, field);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el excel de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Almacenes" + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_ALMACENES_EXCEL_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el archivo";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_ALMACENES_EXCEL_FAILURE });
        }
    } catch (error) {
       yield put({ type: almacenesActions.EXPORTAR_ALMACENES_EXCEL_FAILURE });
    }
} export function* exportarExcelAlmacenesSaga(){
    yield takeLatest(almacenesActions.EXPORTAR_ALMACENES_EXCEL_REQUEST, exportarExcelAlmacenes)
}

// Requisición Directa

function* subirArchivoRequisicion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page;
        const idServicio = parseInt(action.formulario.idServicio);
        console.log(page);

        const formData = new FormData();
        formData.append('file', action.formulario.csv.file.originFileObj);
        

        let response = yield call(subirArchivoRequisicionCall, token, formData, idServicio);
        console.log('response', response);

        const responseStatus = response.status;
    
        let mesaggeSpin = 'Folio de Requisición Directa';
        let descriptionSpin = 'Seleccione un archivo, por favor.';
        let typeSpin = 'info';
        
        if (response.status == 200) {
            const showSuccessMsg = true;

            const textMessage = 'La carga fue procesada con éxito. Verifica el informe para más información';

            const dataFolio = '';
            const folio =  '';
            const registros = '';

            mesaggeSpin = 'Carga generada';
            descriptionSpin = 'La carga fue procesada, verifica el informe para más información';
            typeSpin = 'success';

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            link.href = url;
            link.download = "Informe-Verificacion-" + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".csv";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'cargarArchivoActivo' });
            yield put({ type: almacenesActions.SUBIR_ARCHIVO_REQUISICION_ACTIVO_SUCCESS, folio, registros, responseStatus, mesaggeSpin, descriptionSpin, typeSpin });
        }
        else {
            const showErrorMsg = true;
            //const textMessage = response.message;
            const textMessage = 'Error realizando la requisición directa';

            mesaggeSpin = 'Error';
            descriptionSpin = 'Error realizando la requisición directa, favor de verificar el archivo. Campo(s) incompleto(s).';
            typeSpin = 'error';

            console.log('response.data', response.data)

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.SUBIR_ARCHIVO_REQUISICION_ACTIVO_FAILURE, textMessage, mesaggeSpin, descriptionSpin, typeSpin });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.SUBIR_ARCHIVO_REQUISICION_ACTIVO_FAILURE });
    }
}
export function* subirArchivoRequisicionSaga() {
    yield takeLatest(almacenesActions.SUBIR_ARCHIVO_REQUISICION_ACTIVO_REQUEST, subirArchivoRequisicion);
}

// Folio Requisicion
function* folioRequisicion(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServicio = parseInt(action.idServicio);

        const response = yield call(folioRequisicionCall, token, idServicio)
        console.log(response);

        if (response.status === 200) {
            let folio = response.data;
            yield put({ type: almacenesActions.FOLIO_REQUISICION_DIRECTA_SUCCESS, folio })

        } else {
            yield put({ type: almacenesActions.FOLIO_REQUISICION_DIRECTA_FAILURE })
        }
    } catch (error) {
        yield put({ type: almacenesActions.FOLIO_REQUISICION_DIRECTA_FAILURE })
    }
}

export function* folioRequisicionSaga(){
    yield takeLatest( almacenesActions.FOLIO_REQUISICION_DIRECTA_REQUEST, folioRequisicion)
}

//TIPOS DE FOLIO DE MOVIMIENTOS
function* listaTiposFoliosMovimientosActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaTiposFoliosMovimientosActivosCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaTiposFoliosMovimientosActivos = response.data;

            yield put({ type: almacenesActions.LISTA_TIPOS_FOLIOS_ACTIVOS_SUCCESS, listaTiposFoliosMovimientosActivos });
        }
        else {
            yield put({ type: almacenesActions.LISTA_TIPOS_FOLIOS_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_TIPOS_FOLIOS_ACTIVOS_FAILURE });
    }
}
export function* listaTiposFoliosMovimientosActivosSaga() {
    yield takeLatest(almacenesActions.LISTA_TIPOS_FOLIOS_ACTIVOS_REQUEST, listaTiposFoliosMovimientosActivos);
}

function* listaTiposFoliosMovimientosBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let busqueda = '';
        if (action.busqueda) {
            console.log("ENTRA CON BUSQUEDA");
            busqueda = action.busqueda;
        }
        console.log(page);
        console.log(busqueda);
        const response = yield call(getTiposFoliosMovimientosPageCall, token, page, busqueda);
        console.log(response);
        console.log(response.data.data)

        if (response.status == 200) {
            let listaTiposFoliosMovimientosBusqueda = response.data.data;
            let listaTiposFoliosMovimientosBusquedaCantidad = response.data.cantidad;
            
            yield put({ type: almacenesActions.TIPOS_FOLIOS_BUSQUEDA_SUCCESS, listaTiposFoliosMovimientosBusqueda, listaTiposFoliosMovimientosBusquedaCantidad });
        }
        else {
            yield put({ type: almacenesActions.TIPOS_FOLIOS_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.TIPOS_FOLIOS_BUSQUEDA_FAILURE });
    }
}
export function* listaTiposFoliosMovimientosBusquedaSaga() {
    yield takeLatest(almacenesActions.TIPOS_FOLIOS_BUSQUEDA_REQUEST, listaTiposFoliosMovimientosBusqueda);
}

function* cambiarTipoFolioMovimiento(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idTipoFolio = action.idTipoFolio;

        const response = yield call(estadoTipoFolioMovimientoCall, token, idTipoFolio);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showSuccessMsg = true;
            const textMessage = "El estado del tipo de folio se cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.ESTADO_TIPOS_FOLIOS_SUCCESS });
            yield put({ type: almacenesActions.TIPOS_FOLIOS_BUSQUEDA_REQUEST, page });
            yield put({ type: almacenesActions.LISTA_TIPOS_FOLIOS_ACTIVOS_REQUEST});
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.ESTADO_TIPOS_FOLIOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.ESTADO_TIPOS_FOLIOS_FAILURE });
    }
}
export function* cambiarTipoFolioMovimientoSaga() {
    yield takeLatest(almacenesActions.ESTADO_TIPOS_FOLIOS_REQUEST, cambiarTipoFolioMovimiento);
}

function* exportarExcelTipoFolio(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(exportarExcelTipoFolioCall, token);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el excel de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "TiposFoliosMovimiento" + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_TIPOS_FOLIO_EXCEL_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el archivo";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_TIPOS_FOLIO_EXCEL_FAILURE });
        }
    } catch (error) {
       yield put({ type: almacenesActions.EXPORTAR_TIPOS_FOLIO_EXCEL_FAILURE });
    }
} export function* exportarExcelTipoFolioSaga(){
    yield takeLatest(almacenesActions.EXPORTAR_TIPOS_FOLIO_EXCEL_REQUEST, exportarExcelTipoFolio)
}

function* crearNuevoTipoFolioMovimiento(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idTipoFolio": null,
            "nombre": action.formulario.nombre.trim(),
            "nomenclatura": action.formulario.nomenclatura.trim(),
            "operacion": action.formulario.operacion,
            "folioOrigen": action.formulario.folioOrigen,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": null
        }
        console.log(data);
        const response = yield call(crearTipoFolioMovimientoCall, token, data);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showModalNuevoTipoFolioMovimiento = false;
            const showSuccessMsg = true;
            const textMessage = "El tipo de folio de movimiento " + action.formulario.nombre + " fue agregado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "crearTipoFolio" });
            yield put({ type: almacenesActions.NUEVO_TIPO_FOLIO_SUCCESS });
            yield put({ type: almacenesActions.TIPOS_FOLIOS_BUSQUEDA_REQUEST, page });
            yield put({ type: almacenesActions.LISTA_TIPOS_FOLIOS_ACTIVOS_REQUEST });
            yield put({ type: almacenesActions.SHOW_MODAL_NUEVO_TIPO_FOLIO, showModalNuevoTipoFolioMovimiento });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.NUEVO_TIPO_FOLIO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.NUEVO_TIPO_FOLIO_FAILURE });
    }
}
export function* crearNuevoTipoFolioMovimientoSaga() {
    yield takeLatest(almacenesActions.NUEVO_TIPO_FOLIO_REQUEST, crearNuevoTipoFolioMovimiento);
}

function* editarTipoFolioMovimiento(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idTipoFolio = action.TipoFolioMovimientoSeleccionado.idTipoFolio;

        const data = {
            "idTipoFolio": null,
            "nombre": action.formulario.nombre.trim(),
            "nomenclatura": action.formulario.nomenclatura.trim(),
            "operacion": action.formulario.operacion,
            "folioOrigen": action.formulario.folioOrigen,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": null
        }

        console.log(data);
        const response = yield call(editarTipoFolioMovimientoCall, token, data, idTipoFolio);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showModalEditarTipoFolioMovimiento = false;
            const TipoFolioMovimientoSeleccionado = null;
            const limpiarFormulario = "editarTipoFolio";
            const showSuccessMsg = true;
            const textMessage = "El tipo de folio de movimiento " + action.formulario.nombre  + " fue actualizado con éxito";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario });
            yield put({ type: almacenesActions.EDITAR_TIPO_FOLIO_SUCCESS });
            yield put({ type: almacenesActions.TIPOS_FOLIOS_BUSQUEDA_REQUEST, page });
            yield put({ type: almacenesActions.LISTA_TIPOS_FOLIOS_ACTIVOS_REQUEST });
            yield put({ type: almacenesActions.SHOW_MODAL_EDITAR_TIPO_FOLIO, showModalEditarTipoFolioMovimiento, TipoFolioMovimientoSeleccionado });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message + " Modificar el tipo de folio de movimiento.";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.EDITAR_TIPO_FOLIO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.EDITAR_TIPO_FOLIO_FAILURE });
    }
}
export function* editarTipoFolioMovimientoSaga() {
    yield takeLatest(almacenesActions.EDITAR_TIPO_FOLIO_REQUEST, editarTipoFolioMovimiento);
}


// Saga Busqueda lista general movimientos almacen 
function* listaMovimientosGeneralBusqueda(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = (action.page - 1) * 10;
        let param = '';
        let field = 'folio';
        let order = 'ASC';
        if(action.busqueda){
            if (action.busqueda.param) {
                param = action.busqueda.param;
            }
            if (action.busqueda.field){
                field = 'field=' + action.busqueda.field + '&';
            }
            if (action.busqueda.order){
                order = 'order=' + action.busqueda.order + '&';
            }
        }
        
        const response = yield call(listaMovimientosGeneralBusquedaCall, token, order, field, param, page);
        console.log("response listaMovimientosGeneralBusqueda", response);

        if(response.status === 200){
            let listaMovimientosGeneralBusqueda = response.data.data;
            let listaMovimientosGeneralBusquedaCantidad = response.data.cantidad;
            for (let i = 0; i < listaMovimientosGeneralBusqueda.length; i++) {
                listaMovimientosGeneralBusqueda[i].fechaAlta  = listaMovimientosGeneralBusqueda[i].fechaAlta.split('T')[0].split('-').reverse().join('-')
            }
            yield put({ type: almacenesActions.LISTA_MOVIMIENTOS_GENERAL_BUSQUEDA_SUCCESS, listaMovimientosGeneralBusqueda, listaMovimientosGeneralBusquedaCantidad});
        } else {
            yield put({ type: almacenesActions.LISTA_MOVIMIENTOS_GENERAL_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        yield put({ type: almacenesActions.LISTA_MOVIMIENTOS_GENERAL_BUSQUEDA_FAILURE });
    }
}

export function* listaMovimientosGeneralBusquedaSaga() {
    yield takeLatest(almacenesActions.LISTA_MOVIMIENTOS_GENERAL_BUSQUEDA_REQUEST, listaMovimientosGeneralBusqueda);
}

//Saga Consulta de cancelados o cerrados 
function* consultaDetalle(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = (action.page - 1) * 10;
        const folio = action.folio;

        const response = yield call(listaMovimientosConsultaCall, token, folio, page);
        console.log("response consultaDetalle", response);

        if (response.status === 200) {
            let listaDetalle = response.data.data
            let listaDetalleCantidad = response.data.cantidad;

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }


            for(let i=0; i<listaDetalle.length; i++){
                listaDetalle[i].fechaAlta = listaDetalle[i].fechaAlta?.split('T')[0].split('-').reverse().join('-');
                let fechaMovimiento = listaDetalle[i].fechaMovimiento.split('T')[0].split('-').reverse().join('-');

                listaDetalle[i].fechaMovimientoTabla = listaDetalle[i].fechaMovimiento.split('T')[0].split('-').reverse().join('-');
                listaDetalle[i].fechaMovimiento = moment(fechaMovimiento, 'DD-MM-YYYY')

                if(listaDetalle[i].fechaCaducidad){
                    listaDetalle[i].fechaCaducidad  = listaDetalle[i].fechaCaducidad.split('T')[0].split('-').reverse().join('-');
                    listaDetalle[i].fechaCaducidadEditar  = moment(listaDetalle[i].fechaCaducidad, 'DD-MM-YYYY');
                } else {
                    listaDetalle[i].fechaCaducidad = "No tiene fecha de caducidad"
                    listaDetalle[i].fechaCaducidadEditar = "No tiene fecha de caducidad"
                }
                if(listaDetalle[i].lote){
                    listaDetalle[i].lote = listaDetalle[i].lote
                } else {
                    listaDetalle[i].lote = "No tiene lote"
                }

                if(listaDetalle[i].usuariosByIdUsuarioGenero){
                    listaDetalle[i].nombreUsuarioGenero = listaDetalle[i].usuariosByIdUsuarioGenero.aPaterno ? listaDetalle[i].usuariosByIdUsuarioGenero.nombre + ' ' + listaDetalle[i].usuariosByIdUsuarioGenero.aPaterno : listaDetalle[i].usuariosByIdUsuarioGenero.aMaterno ? listaDetalle[i].usuariosByIdUsuarioGenero.nombre + ' ' + listaDetalle[i].usuariosByIdUsuarioGenero.aPaterno + ' ' + listaDetalle[i].usuariosByIdUsuarioGenero.aMaterno : listaDetalle[i].usuariosByIdUsuarioGenero.nombre;
                
                } else {
                    listaDetalle[i].nombreUsuarioGenero = "No hay usuario que generó folio";
                }

                if(listaDetalle[i].usuariosByIdUsuarioCerroCancelo){
                    listaDetalle[i].nombreUsuarioCancelo = listaDetalle[i].usuariosByIdUsuarioCerroCancelo.aPaterno ? listaDetalle[i].usuariosByIdUsuarioCerroCancelo.nombre + ' ' + listaDetalle[i].usuariosByIdUsuarioCerroCancelo.aPaterno : listaDetalle[i].usuariosByIdUsuarioCerroCancelo.aMaterno ? listaDetalle[i].usuariosByIdUsuarioCerroCancelo.nombre + ' ' + listaDetalle[i].usuariosByIdUsuarioCerroCancelo.aPaterno + ' ' + listaDetalle[i].usuariosByIdUsuarioCerroCancelo.aMaterno : listaDetalle[i].usuariosByIdUsuarioCerroCancelo.nombre;
                    
                } else {
                    listaDetalle[i].nombreUsuarioCancelo = "No hay usuario que canceló/cerró";
                }
                if(listaDetalle[i].tiposFoliosByIdTipoFolio){
                    listaDetalle[i].nombreTipo = listaDetalle[i].tiposFoliosByIdTipoFolio.nombre;
                    
                } else {
                    listaDetalle[i].nombreTipo = "No hay tipo"
                }
                if(listaDetalle[i].productosByIdProducto){
                    listaDetalle[i].idProducto = listaDetalle[i].productosByIdProducto.idProducto;
                    listaDetalle[i].nombre = listaDetalle[i].productosByIdProducto.nombre;
                    listaDetalle[i].sku = listaDetalle[i].productosByIdProducto.sku;
                    listaDetalle[i].clave = listaDetalle[i].productosByIdProducto.clave;
                    listaDetalle[i].codigoAdicional = listaDetalle[i].productosByIdProducto.codigoAdicional;
                    listaDetalle[i].precio = listaDetalle[i].productosByIdProducto.precio.toFixed(2);
                    listaDetalle[i].precioTabla = numberWithCommas(Number(listaDetalle[i].productosByIdProducto.precio).toFixed(2));
                   
                } else {
                    listaDetalle[i].idProducto = "No tiene id producto";
                    listaDetalle[i].nombre = "No tiene nombre el producto";
                    listaDetalle[i].sku = "No tiene sku el producto";
                    listaDetalle[i].clave = "No tiene clave el producto";
                    listaDetalle[i].codigoAdicional = "No tiene código de almacén el producto";
                    listaDetalle[i].precio = "No tiene precio";
                    listaDetalle[i].precioTabla = "No tiene precio";
                }

                //////

                if(listaDetalle[i].precioCosto){
                    listaDetalle[i].precioCosto = listaDetalle[i].precioCosto.toFixed(2);
                    
                    let costoTotal = (listaDetalle[i].precioCosto * listaDetalle[i].cantidadTotal).toFixed(2);
                    if(listaDetalle[i].tipoMonedaByIdTipoMoneda){ 
                        listaDetalle[i].precioCostoTabla = numberWithCommas(Number(listaDetalle[i].precioCosto).toFixed(2)) + ' ' + listaDetalle[i].tipoMonedaByIdTipoMoneda.nomenclatura;
                        listaDetalle[i].costoTotal = numberWithCommas(costoTotal) + ' ' + listaDetalle[i].tipoMonedaByIdTipoMoneda.nomenclatura;
                        
                    } else {
                        listaDetalle[i].precioCostoTabla = numberWithCommas(Number(listaDetalle[i].precioCosto));
                    }
                }

                if(listaDetalle[i].costoOriginal){
                    listaDetalle[i].costoOriginal = listaDetalle[i].costoOriginal.toFixed(2);
                    if(listaDetalle[i].tipoMonedaByIdTipoMoneda){
                        if(listaDetalle[i].tipoMonedaByIdTipoMoneda){
                            listaDetalle[i].costoOriginalTabla = numberWithCommas(Number(listaDetalle[i].costoOriginal).toFixed(2)) + ' ' + listaDetalle[i].tipoMonedaByIdTipoMoneda.nomenclatura;
                        } else {
                            listaDetalle[i].costoOriginalTabla = numberWithCommas(Number(listaDetalle[i].costoOriginal));
                        }
                    }
                    
                }

                if(listaDetalle[i].tipoCambio){
                    if(listaDetalle[i].tipoCambio == 1){
                        listaDetalle[i].tipoCambioTabla = 'N/A';
                    } else {
                        let costoTotal = (Number(listaDetalle[i].costoOriginal) * Number(listaDetalle[i].tipoCambio) * listaDetalle[i].cantidadTotal).toFixed(2);
                        listaDetalle[i].costoTotal = numberWithCommas(costoTotal) + ' MXN';
                        listaDetalle[i].tipoCambioTabla = listaDetalle[i].tipoCambio.toFixed(2) + ' MXN';
                    }
                }

                if(listaDetalle[i].tipoMonedaByIdTipoMoneda){ 
                    console.log('validar 10');
                    listaDetalle[i].tipoMoneda = listaDetalle[i].tipoMonedaByIdTipoMoneda.nombre + ' (' + listaDetalle[i].tipoMonedaByIdTipoMoneda.nomenclatura + ')';
                    listaDetalle[i].tipoMonedaEditar = listaDetalle[i].tipoMonedaByIdTipoMoneda.idTipoMoneda + ' - ' + listaDetalle[i].tipoMonedaByIdTipoMoneda.nombre + ' (' + listaDetalle[i].tipoMonedaByIdTipoMoneda.nomenclatura + ')';
                }
            }

            
            yield put({ type: almacenesActions.LISTA_DETALLE_CONSULTA_SUCCESS, listaDetalle, listaDetalleCantidad});
            //yield put({ type: almacenesActions.LISTA_MOVIMIENTOS_GENERAL_BUSQUEDA_REQUEST, page, busqueda: action.busqueda})

        } else {
            yield put({ type: almacenesActions.LISTA_DETALLE_CONSULTA_FAILURE});
        }
    } catch (error) {
        yield put({ type: almacenesActions.LISTA_DETALLE_CONSULTA_FAILURE});
    }

}

export function* consultaDetalleSaga(){
    yield takeLatest(almacenesActions.LISTA_DETALLE_CONSULTA_REQUEST,consultaDetalle);
}

// Saga cancelar folio
function* cancelarFolio(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let folio = null
        if(action.folio){
            folio = action.folio;
        } else {
            folio = action.movimientoSeleccionado[0].folio;
        }
        

        const response = yield call(cancelarFolioCall,token, folio);
        console.log("response cancelarFolio", response);

        if(response.status === 200){
            const page = action.page;
            const busqueda = action.busqueda;
            const showSuccessMsg = true;
            
            const textMessage = "El folio del movimiento se canceló de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.CANCELAR_FOLIO_SUCCESS});
            yield put({ type: almacenesActions.LISTA_MOVIMIENTOS_GENERAL_BUSQUEDA_REQUEST, page, busqueda});
            yield put({ type: almacenesActions.SHOW_MODAL_OPCIONES_MOVIMIENTOS_ALMACEN, showModalOpcionesMovimientosAlmacen: false, movimientoSeleccionado: null });
            yield put({ type: almacenesActions.SHOW_MODAL_EDITAR_FOLIO, showModalEditarFolio: false });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

             yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.CANCELAR_FOLIO_FAILURE});
        }
    } catch (error) {
        yield put({ type: almacenesActions.CANCELAR_FOLIO_FAILURE});
    }
}

export function* cancelarFolioSaga(){
    yield takeLatest(almacenesActions.CANCELAR_FOLIO_REQUEST, cancelarFolio)
}

// Saga consulta lista inventario 
function* listaInventarioConsulta(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let {busqueda = '', idSubFamilia = '', idFamilia = '', idAlmacenOrigen = ''} = action;
        console.log(page);
        console.log(busqueda,idSubFamilia);
        console.log('id almacen saga',idAlmacenOrigen);
        const response = yield call(listaInventarioConsultaCall, token, busqueda, idFamilia,idSubFamilia, page, idAlmacenOrigen);
        console.log("response listaInventarioConsulta", response);

        if(response.status === 200){
            let listaProductosBusqueda = response.data.data;
            let listaProductosBusquedaCantidad = response.data.cantidad;

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            console.log(listaProductosBusqueda);
            for (let i = 0; i < listaProductosBusqueda.length; i++) {
                if(listaProductosBusqueda[i].productosByIdProducto){
                    listaProductosBusqueda[i].nombre = listaProductosBusqueda[i].productosByIdProducto.nombre;
                    listaProductosBusqueda[i].clave = listaProductosBusqueda[i].productosByIdProducto.clave;
                    listaProductosBusqueda[i].codigoAdicional = listaProductosBusqueda[i].productosByIdProducto.codigoAdicional;
                    listaProductosBusqueda[i].sku = listaProductosBusqueda[i].productosByIdProducto.sku;
                    listaProductosBusqueda[i].presentacion = listaProductosBusqueda[i].productosByIdProducto.presentacion;
                    listaProductosBusqueda[i].descripcion = listaProductosBusqueda[i].productosByIdProducto.descripcion;
                    listaProductosBusqueda[i].precio = listaProductosBusqueda[i].productosByIdProducto.precio.toFixed(2);
                } 
                if(listaProductosBusqueda[i].fechaCaducidad){
                    listaProductosBusqueda[i].fechaCaducidadC = listaProductosBusqueda[i].fechaCaducidad.split('T')[0].split('-').reverse().join('-')
                    listaProductosBusqueda[i].fechaCaducidad = moment(listaProductosBusqueda[i].fechaCaducidad, 'DD-MM-YYYY')
                } else { 
                    listaProductosBusqueda[i].fechaCaducidadC =  "No tiene informacion" 
                    listaProductosBusqueda[i].fechaCaducidad = "No tiene informacion"
                }
                if(listaProductosBusqueda[i].ubicacionesProductosByIdUbicaciones){
                    listaProductosBusqueda[i].ubicacionO = listaProductosBusqueda[i].ubicacionesProductosByIdUbicaciones.area + "-" + listaProductosBusqueda[i].ubicacionesProductosByIdUbicaciones.nivelRack + "-" + listaProductosBusqueda[i].ubicacionesProductosByIdUbicaciones.pasillo + "-" + listaProductosBusqueda[i].ubicacionesProductosByIdUbicaciones.posicionRack 
                } else {
                    listaProductosBusqueda[i].ubicacionO = "No tiene ubicacion origen"
                }
                listaProductosBusqueda[i].rowKey = listaProductosBusqueda[i].idInventario;
                listaProductosBusqueda[i].cantidadInventario = listaProductosBusqueda[i].cantidad;


                //DESDE AQUÍ
                if(listaProductosBusqueda[i].precioCosto){
                    listaProductosBusqueda[i].precioCosto = Number(listaProductosBusqueda[i].precio);
                    
                    let costoTotal = (listaProductosBusqueda[i].precioCosto * listaProductosBusqueda[i].cantidadTotal).toFixed(2);
                    if(listaProductosBusqueda[i].tipoMonedaByIdTipoMoneda){ 
                        listaProductosBusqueda[i].precioCostoTabla = numberWithCommas(Number(listaProductosBusqueda[i].precioCosto)) + ' ' + listaProductosBusqueda[i].tipoMonedaByIdTipoMoneda.nomenclatura;
                        listaProductosBusqueda[i].costoTotal = numberWithCommas(costoTotal) + ' ' + listaProductosBusqueda[i].tipoMonedaByIdTipoMoneda.nomenclatura;
                        
                    } else {
                        listaProductosBusqueda[i].precioCostoTabla = numberWithCommas(Number(listaProductosBusqueda[i].precioCosto));
                    }
                }

                if(listaProductosBusqueda[i].costoOriginal){
                    listaProductosBusqueda[i].costoOriginal = listaProductosBusqueda[i].costoOriginal.toFixed(2);
                    if(listaProductosBusqueda[i].productosByIdProducto){
                        if(listaProductosBusqueda[i].productosByIdProducto.tipoMonedaByIdTipoMoneda){
                            listaProductosBusqueda[i].precioTabla = numberWithCommas(Number(listaProductosBusqueda[i].precioCosto).toFixed(2)) + ' ' + listaProductosBusqueda[i].productosByIdProducto.tipoMonedaByIdTipoMoneda.nomenclatura;
                        } else {
                            listaProductosBusqueda[i].precioTabla = numberWithCommas(Number(listaProductosBusqueda[i].precioCosto).toFixed(2));
                        }
                    }
                    
                }

                if(listaProductosBusqueda[i].tipoCambio){
                    if(listaProductosBusqueda[i].tipoCambio == 1){
                        listaProductosBusqueda[i].tipoCambio = 'N/A';
                    } else {
                        let costoTotal = (listaProductosBusqueda[i].precioCosto * listaProductosBusqueda[i].tipoCambio * listaProductosBusqueda[i].cantidad).toFixed(2);
                        listaProductosBusqueda[i].costoTotal = numberWithCommas(costoTotal) + ' MXN';
                        listaProductosBusqueda[i].tipoCambio = listaProductosBusqueda[i].tipoCambio.toFixed(2) + ' MXN';
                    }
                }

                if(listaProductosBusqueda[i].tipoMonedaByIdTipoMoneda){ 
                    listaProductosBusqueda[i].tipoMoneda = listaProductosBusqueda[i].tipoMonedaByIdTipoMoneda.nombre + ' (' + listaProductosBusqueda[i].tipoMonedaByIdTipoMoneda.nomenclatura + ')';
                    listaProductosBusqueda[i].tipoMonedaEditar = listaProductosBusqueda[i].tipoMonedaByIdTipoMoneda.idTipoMoneda + ' - ' + listaProductosBusqueda[i].tipoMonedaByIdTipoMoneda.nombre + ' (' + listaProductosBusqueda[i].tipoMonedaByIdTipoMoneda.nomenclatura + ')';
                }
            }

            yield put({ type: almacenesActions.LISTA_INVENTARIO_SUCCESS, listaProductosBusqueda, listaProductosBusquedaCantidad})

        } else {
            yield put({ type: almacenesActions.LISTA_INVENTARIO_FAILURE})
        }

    } catch (error) {
        yield put({ type: almacenesActions.LISTA_INVENTARIO_FAILURE})
    }
}
export function* listaInventarioConsultaSaga(){
    yield takeLatest(almacenesActions.LISTA_INVENTARIO_REQUEST, listaInventarioConsulta)
}


// Saga lista inventario tipo activos
function* listaInventarioTipoActivos(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const idAlmacen = action.idAlmacenOrigen;
        let param = '';
        let idSubFamilia = '';
        if(action.busqueda){
            param = '&param=' + action.busqueda;
        }
        if(action.idSubFamilia){
            idSubFamilia = '&idSubFamilia=' + action.idSubFamilia;
        }
        const response = yield call(listaInventarioTipoActivoCall, token, page, idAlmacen, param, idSubFamilia);
        console.log("response listaInventarioTipoActivoCall", response);

        if(response.status === 200){
            let listaInventarioTipoActivos = response.data.data;
            let listaInventarioTipoActivosCantidad = response.data.cantidad;

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            for (let i = 0; i < listaInventarioTipoActivos.length; i++) {
                if(listaInventarioTipoActivos[i].productosByIdProducto){
                    listaInventarioTipoActivos[i].nombre = listaInventarioTipoActivos[i].productosByIdProducto.nombre;
                    listaInventarioTipoActivos[i].clave = listaInventarioTipoActivos[i].productosByIdProducto.clave;
                    listaInventarioTipoActivos[i].sku = listaInventarioTipoActivos[i].productosByIdProducto.sku;
                    listaInventarioTipoActivos[i].presentacion = listaInventarioTipoActivos[i].productosByIdProducto.presentacion;
                    listaInventarioTipoActivos[i].descripcion = listaInventarioTipoActivos[i].productosByIdProducto.descripcion;
                    listaInventarioTipoActivos[i].precio = listaInventarioTipoActivos[i].productosByIdProducto.precio.toFixed(2);
                    listaInventarioTipoActivos[i].codigoAdicional = listaInventarioTipoActivos[i].productosByIdProducto.codigoAdicional;
                } 
                if(listaInventarioTipoActivos[i].fechaCaducidad){
                    listaInventarioTipoActivos[i].fechaCaducidadC = listaInventarioTipoActivos[i].fechaCaducidad.split('T')[0].split('-').reverse().join('-')
                    listaInventarioTipoActivos[i].fechaCaducidad = moment(listaInventarioTipoActivos[i].fechaCaducidad, 'DD-MM-YYYY')
                } else { 
                    listaInventarioTipoActivos[i].fechaCaducidadC =  "No tiene informacion" 
                    listaInventarioTipoActivos[i].fechaCaducidad = "No tiene informacion"
                }
                if(listaInventarioTipoActivos[i].ubicacionesProductosByIdUbicaciones){
                    listaInventarioTipoActivos[i].ubicacionO = listaInventarioTipoActivos[i].ubicacionesProductosByIdUbicaciones.area + "-" + listaInventarioTipoActivos[i].ubicacionesProductosByIdUbicaciones.nivelRack + "-" + listaInventarioTipoActivos[i].ubicacionesProductosByIdUbicaciones.pasillo + "-" + listaInventarioTipoActivos[i].ubicacionesProductosByIdUbicaciones.posicionRack 
                } else {
                    listaInventarioTipoActivos[i].ubicacionO = "No tiene ubicacion origen"
                }
                listaInventarioTipoActivos[i].rowKey = listaInventarioTipoActivos[i].idInventario;
                listaInventarioTipoActivos[i].cantidadInventario = listaInventarioTipoActivos[i].cantidad;


                //DESDE AQUÍ
                if(listaInventarioTipoActivos[i].precioCosto){
                    listaInventarioTipoActivos[i].precioCosto = Number(listaInventarioTipoActivos[i].precio);
                    
                    let costoTotal = (listaInventarioTipoActivos[i].precioCosto * listaInventarioTipoActivos[i].cantidadTotal).toFixed(2);
                    if(listaInventarioTipoActivos[i].tipoMonedaByIdTipoMoneda){ 
                        listaInventarioTipoActivos[i].precioCostoTabla = numberWithCommas(Number(listaInventarioTipoActivos[i].precioCosto)) + ' ' + listaInventarioTipoActivos[i].tipoMonedaByIdTipoMoneda.nomenclatura;
                        listaInventarioTipoActivos[i].costoTotal = numberWithCommas(costoTotal) + ' ' + listaInventarioTipoActivos[i].tipoMonedaByIdTipoMoneda.nomenclatura;
                        
                    } else {
                        listaInventarioTipoActivos[i].precioCostoTabla = numberWithCommas(Number(listaInventarioTipoActivos[i].precioCosto));
                    }
                }

                if(listaInventarioTipoActivos[i].costoOriginal){
                    listaInventarioTipoActivos[i].costoOriginal = listaInventarioTipoActivos[i].costoOriginal.toFixed(2);
                    if(listaInventarioTipoActivos[i].productosByIdProducto){
                        if(listaInventarioTipoActivos[i].productosByIdProducto.tipoMonedaByIdTipoMoneda){
                            listaInventarioTipoActivos[i].precioTabla = numberWithCommas(Number(listaInventarioTipoActivos[i].precioCosto).toFixed(2)) + ' ' + listaInventarioTipoActivos[i].productosByIdProducto.tipoMonedaByIdTipoMoneda.nomenclatura;
                        } else {
                            listaInventarioTipoActivos[i].precioTabla = numberWithCommas(Number(listaInventarioTipoActivos[i].precioCosto).toFixed(2));
                        }
                    }
                    
                }

                if(listaInventarioTipoActivos[i].tipoCambio){
                    if(listaInventarioTipoActivos[i].tipoCambio == 1){
                        listaInventarioTipoActivos[i].tipoCambio = 'N/A';
                    } else {
                        let costoTotal = (listaInventarioTipoActivos[i].precioCosto * listaInventarioTipoActivos[i].tipoCambio * listaInventarioTipoActivos[i].cantidad).toFixed(2);
                        listaInventarioTipoActivos[i].costoTotal = numberWithCommas(costoTotal) + ' MXN';
                        listaInventarioTipoActivos[i].tipoCambio = listaInventarioTipoActivos[i].tipoCambio.toFixed(2) + ' MXN';
                    }
                }

                if(listaInventarioTipoActivos[i].tipoMonedaByIdTipoMoneda){ 
                    listaInventarioTipoActivos[i].tipoMoneda = listaInventarioTipoActivos[i].tipoMonedaByIdTipoMoneda.nombre + ' (' + listaInventarioTipoActivos[i].tipoMonedaByIdTipoMoneda.nomenclatura + ')';
                    listaInventarioTipoActivos[i].tipoMonedaEditar = listaInventarioTipoActivos[i].tipoMonedaByIdTipoMoneda.idTipoMoneda + ' - ' + listaInventarioTipoActivos[i].tipoMonedaByIdTipoMoneda.nombre + ' (' + listaInventarioTipoActivos[i].tipoMonedaByIdTipoMoneda.nomenclatura + ')';
                }
            }
            yield put({ type: almacenesActions.LISTA_INVENTARIO_TIPO_ACTIVOS_SUCCESS, listaInventarioTipoActivos, listaInventarioTipoActivosCantidad})

        } else {
            yield put({ type: almacenesActions.LISTA_INVENTARIO_TIPO_ACTIVOS_FAILURE})
        }

    } catch (error) {
        yield put({ type: almacenesActions.LISTA_INVENTARIO_TIPO_ACTIVOS_FAILURE})
    }
}
export function* listaInventarioTipoActivosSaga(){
    yield takeLatest(almacenesActions.LISTA_INVENTARIO_TIPO_ACTIVOS_REQUEST, listaInventarioTipoActivos)
}

// Saga consulta lista requisicion directa 
function* listaRequisicionConsulta(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const folio = action.folioOrigen;

        let {busqueda = '', idSubFamilia = '', idFamilia = ''} = action;
        if(idFamilia){
            idFamilia = "&idFamilia=" + idFamilia;
        }
        
        console.log('folio:', folio)
        const response = yield call(listaRequisicionDirectaConsultaCall, token, folio, busqueda, idFamilia,idSubFamilia, page);
        console.log("response listaRequisicionConsulta", response);

        if(response.status === 200){
            let listaProductosBusqueda = response.data.data;
            let listaProductosBusquedaCantidad = response.data.cantidad;

            for (let i = 0; i < listaProductosBusqueda.length; i++) {
                if(listaProductosBusqueda[i].productoByIdProducto){
                    listaProductosBusqueda[i].nombre = listaProductosBusqueda[i].productoByIdProducto.nombre;
                    listaProductosBusqueda[i].clave = listaProductosBusqueda[i].productoByIdProducto.clave;
                    listaProductosBusqueda[i].sku = listaProductosBusqueda[i].productoByIdProducto.sku;
                    listaProductosBusqueda[i].descripcion = listaProductosBusqueda[i].productoByIdProducto.descripcion;
                    listaProductosBusqueda[i].precio = listaProductosBusqueda[i].productoByIdProducto.precio.toFixed(2);
                 }
                 listaProductosBusqueda[i].rowKey = listaProductosBusqueda[i].idRequisicionDirecta
            }
            yield put({ type: almacenesActions.LISTA_REQUISICION_DIRECTA_SUCCESS, listaProductosBusqueda, listaProductosBusquedaCantidad})

        } else {
            yield put({ type: almacenesActions.LISTA_REQUISICION_DIRECTA_FAILURE})
        }

    } catch (error) {
        yield put({ type: almacenesActions.LISTA_REQUISICION_DIRECTA_FAILURE})
    }
}
export function* listaRequisicionConsultaSaga(){
    yield takeLatest(almacenesActions.LISTA_REQUISICION_DIRECTA_REQUEST, listaRequisicionConsulta)
}

// imprimir folio
function* imprimirFolio(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const folio = action.movimientoSeleccionado[0].folio;
        const idUsuarioRecibe = parseInt(action.idUsuarioRecibe);
        console.log("folio y idUsuarioRecibe", folio, idUsuarioRecibe)
        const response = yield call(imprimirFolioCall, token, folio, idUsuarioRecibe);
        console.log("response folio",response);

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "El folio del movimiento se descargó de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "MovimientoAlmacen" + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.IMPRIMIR_FOLIO_SUCCESS});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.IMPRIMIR_FOLIO_FAILURE});
        }
    } catch (error) {
        yield put({ type: almacenesActions.IMPRIMIR_FOLIO_FAILURE});
    }
}

export function* imprimirFolioSaga(){
    yield takeLatest(almacenesActions.IMPRIMIR_FOLIO_REQUEST, imprimirFolio)
}

// generacion nuevo movimiento folio
function* generarFolio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token'); 
        
        let idUbicacionOrigen = null;
        let idUbicacionDestino = null;
        let folioOrigen = null;
        if(action.formularioNuevoFolio.idUbicacionProductoOrigen){
            idUbicacionOrigen = action.formularioNuevoFolio.idUbicacionProductoOrigen.split('-')[0]
        }
        if(action.formularioNuevoFolio.idAlmacenOrigen){
            idUbicacionOrigen = action.formularioNuevoFolio.idAlmacenOrigen.split('-')[0]
        }
        if(action.formularioNuevoFolio.idAlmacenDestino){
            idUbicacionDestino = action.formularioNuevoFolio.idAlmacenDestino.split('-')[0]
        }
        if(action.formularioNuevoFolio.idUbicacionProductoDestino){
            idUbicacionDestino = action.formularioNuevoFolio.idUbicacionProductoDestino.split('-')[0]
        }
        if(action.formularioNuevoFolio.folioOrigen){
            folioOrigen = action.formularioNuevoFolio.folioOrigen;
        }
        
        const data = {
                "idMovimientoAlmacen": '',
                "folio": '',
                "estadoFolio": '',
                "idTipoFolio": action.formularioNuevoFolio.idTipoFolio.split('-')[0],
                "idRequisicionDirecta": null,
                "fechaAlta": '',
                "fechaActualizacion": '',
                "fechaFolioCerrado": '',
                // ACÁ
                "fechaMovimiento": action.formularioNuevoFolio.fechaMovimiento.toISOString().split('T')[0],
                "idUsuarioGenero": action.userInfo[0].idUsuario,
                "idUsuarioCanceloCerro": null,
                "idProducto": '',
                "documentoOrigen": action.formularioNuevoFolio.documentoOrigen,
                "folioOrigen": folioOrigen,
                "idUbicacionProductoOrigen": idUbicacionOrigen,
                "idUbicacionProductoDestino": idUbicacionDestino,
                "cantidadTotal": '',
                "precioCosto": '',
                "lote": '',
                "fechaCaducidad": '',
                "activo": ''
            }
            console.log("data", data);
            
            
        const listProduct = [];
        for(let i = 0; i < action.listaSeleccionados.length; i++){
            let lote = null;
            let fechaCaducidad = null;
            let idRequisicion = null;
            let idUbicacionProductoDestino = null;
            let idUbicacionProductoOrigen = null;
            let idUbicacionProducto = null; // mandar de la lista inventario
            if(action.listaSeleccionados[i].lote){
                lote = action.listaSeleccionados[i].lote
            }
            if(action.listaSeleccionados[i].fechaCaducidad && action.listaSeleccionados[i].fechaCaducidad !== "No tiene informacion"){
                fechaCaducidad = typeof action.listaSeleccionados[i].fechaCaducidad === 'string' ? action.listaSeleccionados[i].fechaCaducidad.split('T')[0] : action.listaSeleccionados[i].fechaCaducidad.format('YYYY-MM-DD')
            }
            if(action.listaSeleccionados[i].idRequisicionDirecta){
                idRequisicion = action.listaSeleccionados[i].idRequisicionDirecta;
            }
            if(action.listaSeleccionados[i].idUbicacionProductoDestino){
                idUbicacionProductoDestino = action.listaSeleccionados[i].idUbicacionProductoDestino;
            }
            if(action.listaSeleccionados[i].idUbicacionProductoOrigen){
                idUbicacionProductoOrigen = action.listaSeleccionados[i].idUbicacionProductoOrigen;
            }
            if(action.formularioNuevoFolio.idUbicacionProductoOrigen){
                idUbicacionProductoOrigen = action.formularioNuevoFolio.idUbicacionProductoOrigen;
            }
            if(action.formularioNuevoFolio.idUbicacionProductoDestino){
                idUbicacionProductoDestino = action.formularioNuevoFolio.idUbicacionProductoDestino;
            }
            if(action.listaSeleccionados[i].idUbicacionProducto){
                idUbicacionProducto = action.listaSeleccionados[i].idUbicacionProducto
            }
            if(action.listaSeleccionados[i].subFamiliaByIdSubFamilia?.familiaByIdFamilia.tipoFamilia === 'Activos'){
                let listaSerieNum = [];
                if(action.listaSeleccionados[i]['serieNum-1']){
                    for(let x=1; x<=action.listaSeleccionados[i].cantidad; x++){
                        if(action.listaSeleccionados[i]['serieNum-' + x]){
                            listaSerieNum.push(action.listaSeleccionados[i]['serieNum-' + x])
                        }
                    }
                }
                else{
                    listaSerieNum.push(action.listaSeleccionados[i].serieNum)
                }
                for(let x in listaSerieNum){
                    const producto = {
                        "idRequisicion":idRequisicion,		
                        "idProducto": action.listaSeleccionados[i].idProducto,
                        "lote": lote,
                        "fechaCaducidad": fechaCaducidad,
                        "cantidad": 1,
                        "precio": action.listaSeleccionados[i].precio,
                        "idTipoMoneda": action.listaSeleccionados[i].tipoMonedaEditar ? action.listaSeleccionados[i].tipoMonedaEditar.split(' - ')[0] : action.listaSeleccionados[i].idTipoMoneda,
                        "tipoCambio": action.listaSeleccionados[i].tipoCambio ? action.listaSeleccionados[i].tipoCambio === 'N/A' ? 1 : action.listaSeleccionados[i].tipoCambio.split(' MXN')[0] : 1,
                        'serieNum': listaSerieNum[x],
                        "idUbicacionProductoOrigen": idUbicacionProducto,
                        "idUbicacionProductoDestino": idUbicacionProductoDestino,
                    }
                    listProduct.push(producto)
                }
            }else{
                const producto = {
                    "idRequisicion":idRequisicion,		
                    "idProducto": action.listaSeleccionados[i].idProducto,
                    "lote": lote,
                    "fechaCaducidad": fechaCaducidad,
                    "cantidad": action.listaSeleccionados[i].cantidadSurtir ? action.listaSeleccionados[i].cantidadSurtir === 'No aplica' ? action.listaSeleccionados[i].cantidad : action.listaSeleccionados[i].cantidadSurtir : action.listaSeleccionados[i].cantidad,
                    "precio": action.listaSeleccionados[i].precio,
                    "idTipoMoneda": action.listaSeleccionados[i].tipoMonedaEditar ? action.listaSeleccionados[i].tipoMonedaEditar.split(' - ')[0] : action.listaSeleccionados[i].idTipoMoneda,
                    "tipoCambio": action.listaSeleccionados[i].tipoCambio ? action.listaSeleccionados[i].tipoCambio === 'N/A' ? 1 : action.listaSeleccionados[i].tipoCambio.split(' MXN')[0] : 1,
                    'serieNum': action.listaSeleccionados[i].serieNum ? action.listaSeleccionados[i].serieNum === 'No aplica' ? null : action.listaSeleccionados[i].serieNum : null,
                    "idUbicacionProductoOrigen": idUbicacionProducto,
                    "idUbicacionProductoDestino": idUbicacionProductoDestino,
                }
                listProduct.push(producto)
            }
        }
        console.log('list product', listProduct)
        const formData = new FormData();
        
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        formData.append('listProduct', new Blob([JSON.stringify(listProduct)], {
            type: "application/json"
        }));
        console.log('formData');
        console.log(formData);
        console.log(action.formularioNuevoFolio.documentoOrigen)
        const response = yield call(generarFolioCall, token, formData);
        console.log("response generarFolio", response);

        if(response.status==200){
            const textMessage = "El folio se creó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage });
            yield put({ type: almacenesActions.NUEVO_FOLIO_SUCCESS });
            yield put({ type: almacenesActions.LISTA_MOVIMIENTOS_GENERAL_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda });
            yield put({ type: almacenesActions.SHOW_MODAL_NUEVO_FOLIO, showModalNuevoFolio: false });
            yield put({ type: almacenesActions.CAMBIAR_VISTA_MODAL_NUEVOFOLIO, vistaModalNuevoFolio: 1 });
            yield put({ type: almacenesActions.LISTA_INVENTARIO_SUCCESS, listaProductosBusqueda: null });
            yield put({ type: almacenesActions.OBTENER_UBICACION_POR_ALMACEN_SUCCESS, listaUbicaciones: null });
            yield put({ type: almacenesActions.UBICACION_DESTINO_POR_ALMACEN_SUCCESS, listaUbicacionesDestino: null });
            yield put({ type: almacenesActions.ACTUALIZAR_TABLA_MOVIMIENTOS, listaSeleccionados: null, selectedRowKeys: [], pageMovimientos: 1, hasSelected: true })
            
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.NUEVO_FOLIO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.NUEVO_FOLIO_FAILURE });
    }
}
export function* generarFolioSaga() {
    yield takeLatest(almacenesActions.NUEVO_FOLIO_REQUEST, generarFolio);
}


// Saga ubicacion por almacen 
function* ubicacionPorAlmacenOrigen(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idAlmacen = action.idAlmacen;
        const response = yield call(ubicacionPorAlmacenCall,token, idAlmacen);
        console.log("response ubicacionPorAlmacenOrigen", response);

        if(response.status === 200){
            const page = 1;
            const listaUbicaciones = response.data;
           
            yield put({ type: almacenesActions.OBTENER_UBICACION_POR_ALMACEN_SUCCESS, listaUbicaciones});
        } else {
            yield put({ type: almacenesActions.OBTENER_UBICACION_POR_ALMACEN_FAILURE});
        }
    } catch (error) {
        yield put({ type: almacenesActions.OBTENER_UBICACION_POR_ALMACEN_FAILURE});
    }
}

export function* ubicacionPorAlmacenOrigenSaga(){
    yield takeLatest(almacenesActions.OBTENER_UBICACION_POR_ALMACEN_REQUEST, ubicacionPorAlmacenOrigen)
}

// Saga ubicacion por almacen 
function* ubicacionPorAlmacenDestino(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idAlmacen = action.idAlmacen;
        const response = yield call(ubicacionPorAlmacenCall,token, idAlmacen);
        console.log("response ubicacionPorAlmacenDestino", response);

        if(response.status === 200){
            
            const listaUbicacionesDestino = response.data;
           
            yield put({ type: almacenesActions.UBICACION_DESTINO_POR_ALMACEN_SUCCESS, listaUbicacionesDestino});
        } else {
            yield put({ type: almacenesActions.UBICACION_DESTINO_POR_ALMACEN_FAILURE});
        }
    } catch (error) {
        yield put({ type: almacenesActions.UBICACION_DESTINO_POR_ALMACEN_FAILURE});
    }
}

export function* ubicacionPorAlmacenDestinoSaga(){
    yield takeLatest(almacenesActions.UBICACION_DESTINO_POR_ALMACEN_REQUEST, ubicacionPorAlmacenDestino)
}

// Saga cerrar folio
function* cerrarFolio(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const folio = action.movimientoSeleccionado[0].folio;

        const response = yield call(cerrarFolioCall,token, folio);
        console.log("response cerrarFolio", response);

        if(response.status === 200){
            const page = action.page ? action.page : 0;
            const busqueda = action.busqueda;
            const showSuccessMsg = true;
            const textMessage = "El folio del movimiento se cerró de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.CERRAR_FOLIO_SUCCESS });
            yield put({ type: almacenesActions.LISTA_DETALLE_CONSULTA_REQUEST, folio: action.movimientoSeleccionado[0].folio, page: action.page, busqueda: action.busqueda })
            yield put({ type: almacenesActions.LISTA_MOVIMIENTOS_GENERAL_BUSQUEDA_REQUEST, page, busqueda });
            
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

             yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.CERRAR_FOLIO_FAILURE});
        }
    } catch (error) {
        yield put({ type: almacenesActions.CERRAR_FOLIO_FAILURE});
    }
}

export function* cerrarFolioSaga(){
    yield takeLatest(almacenesActions.CERRAR_FOLIO_REQUEST, cerrarFolio)
}

// Saga cerrar folio Sin salida
function* cerrarFolioSinSalida(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const folio = action.movimientoSeleccionado[0].folio;

        const response = yield call(cerrarFolioSinSalidaCall,token, folio);
        console.log("response cerrarFolioSinSalida", response);

        if(response.status === 200){
            const page = action.page ? action.page : 0;
            const busqueda = action.busqueda;
            let showModalOpcionesMovimientosAlmacen = false;
            const showSuccessMsg = true;
            const textMessage = "El folio se le dio salida de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.CERRAR_FOLIO_SIN_SALIDA_SUCCESS});
            yield put({ type: almacenesActions.LISTA_MOVIMIENTOS_GENERAL_BUSQUEDA_REQUEST, page, busqueda});
            yield put({ type: almacenesActions.SHOW_MODAL_OPCIONES_MOVIMIENTOS_ALMACEN, showModalOpcionesMovimientosAlmacen})
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

             yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.CERRAR_FOLIO_SIN_SALIDA_FAILURE});
        }
    } catch (error) {
        yield put({ type: almacenesActions.CERRAR_FOLIO_SIN_SALIDA_FAILURE});
    }
}

export function* cerrarFolioSinSalidaSaga(){
    yield takeLatest(almacenesActions.CERRAR_FOLIO_SIN_SALIDA_REQUEST, cerrarFolioSinSalida)
}

// Saga cancelar producto
function* cancelarProducto(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idsMovimientoAlmacen = action.idsMovimientoAlmacen;
        let eliminados = 0;
        let showError = false;
        let textMessage = '';
        for(let i in idsMovimientoAlmacen){
            const response = yield call(cancelarProductoCall,token, idsMovimientoAlmacen[i].identificador);
            console.log('response cancelarProducto', response);
            if(response.status === 200){
                eliminados++;
            }
            else{
                showError = true;
                textMessage = response.message;
            }
        }
        if(!showError){
            const folio = action.folio;
            console.log('folio action', folio)
            const page = action.page;
            const textMessage = eliminados === 1 ? 'Se eliminó el producto de forma correcta' : "Se eliminaron " + eliminados + " productos de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage });
            yield put({ type: almacenesActions.CANCELAR_PRODUCTO_SUCCESS});
            yield put({ type: almacenesActions.LISTA_DETALLE_CONSULTA_REQUEST,folio, page});
        } else {
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg: true, textMessage });
            yield put({ type: almacenesActions.CANCELAR_PRODUCTO_FAILURE});
        }
    } catch (error) {
        yield put({ type: almacenesActions.CANCELAR_PRODUCTO_FAILURE});
    }
}

export function* cancelarProductoSaga(){
    yield takeLatest(almacenesActions.CANCELAR_PRODUCTO_REQUEST, cancelarProducto)
}

function* salidaInstalacion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let data = [];
        for(let i in action.listaDetalle){
            for(let y in action.listaDetalle[i].idMovimientoAlmacen_serieNum){
                data.push({
                    'idProducto': action.listaDetalle[i].idProducto,
                    "idUbicacion": null,
                    "serieNum": action.listaDetalle[i].idMovimientoAlmacen_serieNum[y].etiqueta,
                    "estado": "Bueno",
                    "fechaInstalacion": "",
                    "fechaGarantia": null,
                    "garantia": "",
                    "observaciones": ''
                })
                for(let x in action.formulario){
                    if(action.listaDetalle[i].idMovimientoAlmacen_serieNum[y].identificador === action.formulario[x].idMovimientoAlmacen){
                        if(action.formulario[x].idUbicacion){
                            data[data.length - 1].idUbicacion = parseInt(action.formulario[x].idUbicacion);
                        }
                        else if(action.formulario[x].fechaInstalacion){
                            data[data.length - 1].fechaInstalacion = action.formulario[x].fechaInstalacion.format('YYYY-MM-DD');
                        }
                        else if(action.formulario[x].fechaGarantia){
                            data[data.length - 1].fechaGarantia = action.formulario[x].fechaGarantia.format('YYYY-MM-DD');
                        }
                        else if(action.formulario[x].garantia){
                            data[data.length - 1].garantia = action.formulario[x].garantia;
                        }
                        else if(action.formulario[x].observaciones){
                            data[data.length - 1].observaciones = action.formulario[x].observaciones;
                        }
                    }
                }
            }
        }
        for(let i in data){
            if(data[i].observaciones === undefined){
                data[i].observaciones = '';
            }
        }

        console.log(data);
        let response = yield call(salidaInstalacionCall, token, data);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = 'La instalación se realizó con éxito.';

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.SALIDA_INSTALACION_SUCCESS });
            yield put({ type: almacenesActions.SHOW_MODAL_CREAR_INSTALACION, showModalCrearActivos: false });
            yield put({ type: almacenesActions.SHOW_MODAL_OPCIONES_MOVIMIENTOS_ALMACEN, showModalOpcionesMovimientosAlmacen: false });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.SALIDA_INSTALACION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.SALIDA_INSTALACION_FAILURE });
    }
}
export function* salidaInstalacionSaga() {
    yield takeLatest(almacenesActions.SALIDA_INSTALACION_REQUEST, salidaInstalacion);
}

// Saga agregar productos edicion
function* agregarProductoEdicion(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const folio = action.folio;
        console.log('folio', folio);

        const listProduct = []
        for(let i = 0; i < action.listaSeleccionadosEditar.length; i++){
            console.log(action.listaSeleccionadosEditar[i])
            let lote = null;
            let fechaCaducidad = null;
            let idRequisicion = null;
            let idUbicacionProductoOrigen = null;
            let idUbicacionProducto = null;
            let idUbicacionProductoDestino = null;
            if(action.listaSeleccionadosEditar[i].lote && action.listaSeleccionadosEditar[i].lote !== 'No tiene lote'){
                lote = action.listaSeleccionadosEditar[i].lote
            }
            if(action.listaSeleccionadosEditar[i].fechaCaducidad && action.listaSeleccionadosEditar[i].fechaCaducidad !== "No tiene informacion"){
                
               // fechaCaducidad = action.listaSeleccionadosEditar[i].fechaCaducidad.format('YYYY-MM-DD')
                fechaCaducidad = action.listaSeleccionadosEditar[i].fechaCaducidad
                console.log(fechaCaducidad)
            }
            if(action.listaSeleccionadosEditar[i].idUbicacionProducto){
                idUbicacionProducto = action.listaSeleccionadosEditar[i].idUbicacionProducto
            }
            if(action.listaSeleccionadosEditar[i].idRequisicionDirecta){
                console.log(action.listaSeleccionadosEditar[i].idRequisicion)
                idRequisicion = action.listaSeleccionadosEditar[i].idRequisicionDirecta
            }
            if(action.listaSeleccionadosEditar[i].idUbicacionProductoOrigen){
                console.log(action.listaSeleccionadosEditar[i].idUbicacionProductoOrigen)
                idUbicacionProductoOrigen = action.listaSeleccionadosEditar[i].idUbicacionProductoOrigen
            }
            // if(action.listaSeleccionadosEditar[i].idUbicacionProducto){
            //     idUbicacionProductoOrigen = action.listaSeleccionadosEditar[i].idUbicacionProducto
            // }
            if(action.listaSeleccionadosEditar[i].idUbicacionProductoDestino){
                console.log(action.listaSeleccionadosEditar[i].idUbicacionProductoDestino)
                idUbicacionProductoDestino = action.listaSeleccionadosEditar[i].idUbicacionProductoDestino
            }
            if(action.listaSeleccionadosEditar[i].idUbicacionProductoO){
                console.log(action.listaSeleccionadosEditar[i].idUbicacionProductoO)
                idUbicacionProductoOrigen = action.listaSeleccionadosEditar[i].idUbicacionProductoO
            }
            if(action.listaSeleccionadosEditar[i].idUbicacionProductoD){
                console.log(action.listaSeleccionadosEditar[i].idUbicacionProductoD)
                idUbicacionProductoDestino = action.listaSeleccionadosEditar[i].idUbicacionProductoD
            }
            if(action.listaSeleccionadosEditar[i].tipoCambio === 'N/A'){
                action.listaSeleccionadosEditar[i].tipoCambio = null;
            }
            if(action.listaSeleccionadosEditar[i].subFamiliaByIdSubFamilia.familiaByIdFamilia.tipoFamilia === 'Activos'){
                let listaSerieNum = [];
                if(action.listaSeleccionadosEditar[i]['serieNum-1']){
                    for(let x=1; x<=action.listaSeleccionadosEditar[i].cantidad; x++){
                        if(action.listaSeleccionadosEditar[i]['serieNum-' + x]){
                            listaSerieNum.push(action.listaSeleccionadosEditar[i]['serieNum-' + x])
                        }
                    }
                }
                else{
                    listaSerieNum.push(action.listaSeleccionadosEditar[i].serieNum);
                }
                console.log('Antes de producto', listaSerieNum)
                for(let x in listaSerieNum){
                    const producto = {
                        "idRequisicion":idRequisicion,		
                        "idProducto": action.listaSeleccionadosEditar[i].idProducto,
                        "lote": lote,
                        "fechaCaducidad": fechaCaducidad,
                        "cantidad": 1,
                        "precio": parseInt(action.listaSeleccionadosEditar[i].precio),
                        'serieNum': listaSerieNum[x],
                        "idTipoMoneda": action.listaSeleccionadosEditar[i].tipoMonedaEditar ? action.listaSeleccionadosEditar[i].tipoMonedaEditar.split(' - ')[0] : action.listaSeleccionadosEditar[i].idTipoMoneda,
                        "tipoCambio": action.listaSeleccionadosEditar[i].tipoCambio ? action.listaSeleccionadosEditar[i].tipoCambio === 'N/A' ? 1 : isNaN(action.listaSeleccionadosEditar[i].tipoCambio) ? action.listaSeleccionadosEditar[i].tipoCambio.split(' MXN')[0] : action.listaSeleccionadosEditar[i].tipoCambio : 1,
                        "idUbicacionProductoOrigen": idUbicacionProducto,
                        "idUbicacionProductoDestino": idUbicacionProductoDestino,
                    }
                    listProduct.push(producto)
                }
            }else{
                const producto = {
                    "idRequisicion": idRequisicion,		
                    "idProducto": action.listaSeleccionadosEditar[i].idProducto,
                    "lote": lote,
                    "fechaCaducidad": fechaCaducidad,
                    "cantidad": action.listaSeleccionadosEditar[i].cantidad,
                    "precio": parseInt(action.listaSeleccionadosEditar[i].precio),
                    'serieNum': action.listaSeleccionadosEditar[i].serieNum,
                    "idTipoMoneda": action.listaSeleccionadosEditar[i].tipoMonedaEditar ? action.listaSeleccionadosEditar[i].tipoMonedaEditar.split(' - ')[0] : action.listaSeleccionadosEditar[i].idTipoMoneda,
                    "tipoCambio": action.listaSeleccionadosEditar[i].tipoCambio ? action.listaSeleccionadosEditar[i].tipoCambio === 'N/A' ? 1 : isNaN(action.listaSeleccionadosEditar[i].tipoCambio) ? action.listaSeleccionadosEditar[i].tipoCambio.split(' MXN')[0] : action.listaSeleccionadosEditar[i].tipoCambio : 1,
                    "idUbicacionProductoOrigen": idUbicacionProducto,
                    "idUbicacionProductoDestino": idUbicacionProductoDestino,
                }
                console.log('producto', producto)
                listProduct.push(producto)
            }
        }
        console.log('list product', listProduct)
        const formData = new FormData();
        
        formData.append('listProduct', new Blob([JSON.stringify(listProduct)], {
            type: "application/json"
        }));
        console.log('formData');
        console.log(formData);

        const response = yield call(agregarProductosEdicionCall, token, folio, formData);
        console.log('response agregarProductoEdicionSaga', response);

        if(response.status === 200){
            const page = action.page;
            const showSuccessMsg = true;
            const textMessage = "El producto de guardó correctamente";
           
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.NUEVO_AGREGAR_PRODUCTOS_SUCCESS });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "agregarProductosFolio" });
            yield put({ type: almacenesActions.LISTA_DETALLE_CONSULTA_REQUEST,folio, page});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.NUEVO_AGREGAR_PRODUCTOS_FAILURE });
        } 
    } catch (error) {
          yield put({ type: almacenesActions.NUEVO_AGREGAR_PRODUCTOS_FAILURE });
    }
}
export function* agregarProductoEdicionSaga(){
    yield takeLatest(almacenesActions.NUEVO_AGREGAR_PRODUCTOS_REQUEST, agregarProductoEdicion)
}
// Saga editar folio movimiento
function* editarFolioMovimiento(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const folio = action.listaDetalle[0].folio;
        
        const movimientosList = [];
        for (let i = 0; i < action.listaDetalle.length; i++) {
            console.log('action.listaDetalle', action.listaDetalle[i]);
            let lote = null;
            let fechaCaducidad = null;
        
            if (action.listaDetalle[i].lote && action.listaDetalle[i].lote !== "No tiene lote") {
                lote = action.listaDetalle[i].lote;
            }
            if (action.listaDetalle[i].fechaCaducidadEditar && action.listaDetalle[i].fechaCaducidadEditar !== "No tiene fecha de caducidad") {
                fechaCaducidad = action.listaDetalle[i].fechaCaducidadEditar.format('YYYY-MM-DD');
            }
            

            const movimiento = {
                "idMovimientoAlmacen": action.listaDetalle[i].idMovimientoAlmacen,
                "folio": action.listaDetalle[i].folio,
                "estadoFolio": action.listaDetalle[i].estadoFolio,
                "idTipoFolio": action.listaDetalle[i].idTipoFolio,
                "idRequisicionDirecta": action.listaDetalle[i].idRequisicionDirecta,
                "fechaAlta": action.listaDetalle[i].fechaAlta.split('-').reverse().join('-'),
                "fechaActualizacion": action.listaDetalle[i].fechaActualizacion,
                "fechaFolioCerrado": action.listaDetalle[i].fechaFolioCerrado,
                "fechaMovimiento": action.formulario.fechaMovimientoEditar,
                "idUsuarioGenero": action.listaDetalle[i].idUsuarioGenero,
                "idUsuarioCanceloCerro": action.listaDetalle[i].idUsuarioCanceloCerro,
                "idProducto": action.listaDetalle[i].idProducto,
                "documentoOrigen": action.formulario.documentoOrigen,
                "folioOrigen": action.listaDetalle[i].folioOrigen,
                "idUbicacionProductoOrigen": action.listaDetalle[i].idUbicacionProductoOrigen,
                "idUbicacionProductoDestino": action.listaDetalle[i].idUbicacionProductoDestino,
                "cantidadTotal": action.listaDetalle[i].cantidadTotal,
                "precioCosto": action.listaDetalle[i].precioCosto,
                "idTipoMoneda": action.listaDetalle[i].tipoMonedaEditar.split(' - ')[0],
                "tipoCambio": action.listaDetalle[i].tipoCambio ? action.listaDetalle[i].tipoCambio : 1,
                'serieNum': action.listaDetalle[i].serieNum,
                "lote": lote,
                "fechaCaducidad": fechaCaducidad,
                "activo": true,
                "usuariosByIdUsuarioGenero": action.listaDetalle[i].usuariosByIdUsuarioGenero, 
                "usuariosByIdUsuarioCerroCancelo": null,
                "tiposFoliosByIdTipoFolio": action.listaDetalle[i].tiposFoliosByIdTipoFolio,
                "productosByIdProducto": action.listaDetalle[i].productosByIdProducto,
                "almacenesByIdAlmacen": null,
                "requisicionByIdRequisicion": null,
                "unidadesMedidasByIdUnidad": null,
                "familiasByIdFamilia": null
            }

           movimientosList.push(movimiento);
        }

        console.log('movimientosList',movimientosList);
        const formData = new FormData();

        formData.append('movimientosList', new Blob([JSON.stringify(movimientosList)], {
            type: "application/json"
        }));
        console.log('formData');
        console.log(formData);
        console.log(folio);
         const response = yield call(editarFolioCall, token, folio, formData);
         console.log('response editarFolioMovimiento', response)

        if(response.status==200){
            const showSuccessMsg = true;
            const textMessage = "El folio del movimiento se edito de forma correcta";
            let showModalEditarFolio = false;
            let showModalOpcionesMovimientosAlmacen = false;

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.EDITAR_FOLIO_SUCCESS });
            yield put({ type: almacenesActions.SHOW_MODAL_EDITAR_FOLIO , showModalEditarFolio});
            yield put({ type: almacenesActions.SHOW_MODAL_OPCIONES_MOVIMIENTOS_ALMACEN , showModalOpcionesMovimientosAlmacen});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.EDITAR_FOLIO_FAILURE });
        }
    } catch (error) {
         yield put({ type: almacenesActions.EDITAR_FOLIO_FAILURE });
    }
}
export function* editarFolioMovimientoSaga(){
    yield takeLatest(almacenesActions.EDITAR_FOLIO_REQUEST, editarFolioMovimiento)
}

// Saga para obtener almacen por idUbicacion
function* obtenerAlmacenPorUbicacion(action){
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idUbicacionProductoOrigen = action.idUbicacionProductoOrigen

        const response = yield call(obtenerAlmacenPorUbicacionCall, token, idUbicacionProductoOrigen);
        console.log(' response obtenerAlmacenPorUbicacion', response)

        if(response.status === 200){
            let almacenOrigen = response.data;
            console.log('almacenOrigen', almacenOrigen)
            yield put({ type: almacenesActions.OBTENER_ALMACEN_POR_UBICACION_SUCCESS , almacenOrigen});
        } else {
            yield put({ type: almacenesActions.OBTENER_ALMACEN_POR_UBICACION_FAILURE });
        }
    } catch(error){
        yield put({ type: almacenesActions.OBTENER_ALMACEN_POR_UBICACION_FAILURE });
    }
}
export function* obtenerAlmacenPorUbicacionSaga(){
    yield takeLatest(almacenesActions.OBTENER_ALMACEN_POR_UBICACION_REQUEST, obtenerAlmacenPorUbicacion)
}

// Saga para obtener almacen por idUbicacion Destino
function* obtenerAlmacenPorUbicacionDestino(action){
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idUbicacionProductoDestino = action.idUbicacionProductoDestino

        const response = yield call(obtenerAlmacenPorUbicacionCall, token, idUbicacionProductoDestino);
        console.log(' response obtenerAlmacenPorUbicacionDestino', response)

        if(response.status === 200){
            let almacenDestino = response.data;
            console.log('almacenDestino', almacenDestino)
            yield put({ type: almacenesActions.OBTENER_ALMACEN_POR_UBICACION_DESTINO_SUCCESS , almacenDestino});
        } else {
            yield put({ type: almacenesActions.OBTENER_ALMACEN_POR_UBICACION_DESTINO_FAILURE });
        }
    } catch(error){
        yield put({ type: almacenesActions.OBTENER_ALMACEN_POR_UBICACION_DESTINO_FAILURE });
    }
}
export function* obtenerAlmacenPorUbicacionDestinoSaga(){
    yield takeLatest(almacenesActions.OBTENER_ALMACEN_POR_UBICACION_DESTINO_REQUEST, obtenerAlmacenPorUbicacionDestino)
}

// saga obtener info por idventario
function* obtenerInfoIdInventario(action){
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idProducto = action.registroProductoSeleccionado.idProducto;
        let lote = '';
        const idUbicacionProductoOrigen = action.registroProductoSeleccionado.idUbicacionProductoOrigen;

        if(action.registroProductoSeleccionado.lote && action.registroProductoSeleccionado.lote !== "No tiene lote"){ 
            lote = action.registroProductoSeleccionado.lote;
        }
        console.log(idProducto)
        console.log(lote)
        console.log(idUbicacionProductoOrigen)

        const response = yield call(obtenerInfoIdInventarioCall, token, idProducto, lote, idUbicacionProductoOrigen);
        console.log(' response obtenerInfoIdIventario', response)

        if(response.status === 200){
            if(response.data.length > 0){
                let productoInventario = response.data;
                action.registroProductoSeleccionado.cantidadInventario = productoInventario[0].cantidad
                console.log("action.registroProductoSeleccionado en saga", action.registroProductoSeleccionado);
                
                yield put({ type: almacenesActions.OBTENER_INFO_IDINVENTARIO_SUCCESS , productoInventario});
                yield put({ type: almacenesActions.SHOW_MODAL_MODIFICAR_EDITARFOLIO , showModalModificarEditarFolio: true, registroProductoSeleccionado: action.registroProductoSeleccionado, page: action.page});
            } else {
                yield put({ type: configuracionYSeguridadActions.SHOW_WARNING_MSG, showWarningMsg: true, textMessage: 'El inventario del producto no está disponible' });
            }
        } else {
            yield put({ type: almacenesActions.OBTENER_INFO_IDINVENTARIO_FAILURE });
        }
    } catch(error){
        yield put({ type: almacenesActions.OBTENER_INFO_IDINVENTARIO_FAILURE });
    }
}
export function* obtenerInfoIdInventarioSaga(){
    yield takeLatest(almacenesActions.OBTENER_INFO_IDINVENTARIO_REQUEST, obtenerInfoIdInventario)
}

//Saga obtener lista Productos filtrados
function* obtenerListaProductosFiltrados(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let {busqueda = '', campo = 'nombre', orden = 'ASC', idSubFamilia = ''} = action;
        let folio = action.folio;
        console.log(folio)
        console.log(page);
        console.log(busqueda,campo,orden,idSubFamilia);
        const response = yield call(listaProductosFiltradosConsultaCall, token, busqueda ,idSubFamilia,orden,campo ,page,folio);
        console.log("response obtenerListaProductosFiltrados", response);
        

        if (response.status == 200) {
            let listaProductosFiltradoRequest = response.data.data;
            let listaProductosFiltradoRequestCantidad = response.data.cantidad;

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            for(let i in listaProductosFiltradoRequest){
                listaProductosFiltradoRequest[i].rowKey = listaProductosFiltradoRequest[i].idProducto;
                
                if(listaProductosFiltradoRequest[i].tipoMonedaByIdTipoMoneda){
                    listaProductosFiltradoRequest[i].precioTabla = numberWithCommas(listaProductosFiltradoRequest[i].precio.toFixed(2)) + ' ' + listaProductosFiltradoRequest[i].tipoMonedaByIdTipoMoneda.nomenclatura; 
                } else {
                    listaProductosFiltradoRequest[i].precioTabla = numberWithCommas(listaProductosFiltradoRequest[i].precio.toFixed(2));
                }
                listaProductosFiltradoRequest[i].precio = listaProductosFiltradoRequest[i].precio.toFixed(2);

                if(listaProductosFiltradoRequest[i].tipoMonedaByIdTipoMoneda){
                    listaProductosFiltradoRequest[i].tipoMoneda = listaProductosFiltradoRequest[i].tipoMonedaByIdTipoMoneda.nombre + ' (' + listaProductosFiltradoRequest[i].tipoMonedaByIdTipoMoneda.nomenclatura + ')';
                    listaProductosFiltradoRequest[i].tipoMonedaEditar = listaProductosFiltradoRequest[i].tipoMonedaByIdTipoMoneda.idTipoMoneda + ' - ' + listaProductosFiltradoRequest[i].tipoMonedaByIdTipoMoneda.nombre + ' (' + listaProductosFiltradoRequest[i].tipoMonedaByIdTipoMoneda.nomenclatura + ')';
                }
            }
            yield put({ type: almacenesActions.LISTA_PRODUCTOS_FILTRADO_SUCCESS, listaProductosFiltradoRequest, listaProductosFiltradoRequestCantidad });
        }
        else {
            yield put({ type: almacenesActions.LISTA_PRODUCTOS_FILTRADO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_PRODUCTOS_FILTRADO_FAILURE });
    }
}
export function* obtenerListaProductosFiltradosSaga() {
    yield takeLatest(almacenesActions.LISTA_PRODUCTOS_FILTRADO_REQUEST, obtenerListaProductosFiltrados);
}


//Saga obtener lista Inventario filtrados
function* obtenerListaInventarioFiltrados(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let {busqueda = '', idSubFamilia = '', idAlmacenOrigen = ''} = action;
        let folio = action.folio;

        const response = yield call(listaInventarioFiltradosConsultaCall, token, busqueda ,idSubFamilia, page, idAlmacenOrigen, folio);
        console.log(response);
        

        if (response.status == 200) {
            let listaProductosFiltradoRequest = response.data.data;
            let listaProductosFiltradoRequestCantidad = response.data.cantidad;
            
            for(let i in listaProductosFiltradoRequest){
                listaProductosFiltradoRequest[i].cantidadInventario = listaProductosFiltradoRequest[i].cantidad;

                listaProductosFiltradoRequest[i].rowKey = listaProductosFiltradoRequest[i].idInventario;
                listaProductosFiltradoRequest[i].lote = listaProductosFiltradoRequest[i].lote ? listaProductosFiltradoRequest[i].lote : 'No tiene lote';
                listaProductosFiltradoRequest[i].precio = listaProductosFiltradoRequest[i].precioCosto.toFixed(2);
                if(listaProductosFiltradoRequest[i].productosByIdProducto){
                    listaProductosFiltradoRequest[i].precioTabla = listaProductosFiltradoRequest[i].precio + ' ' + listaProductosFiltradoRequest[i].productosByIdProducto.tipoMonedaByIdTipoMoneda.nomenclatura;
                    listaProductosFiltradoRequest[i].codigoAdicional = listaProductosFiltradoRequest[i].productosByIdProducto.codigoAdicional;
                    listaProductosFiltradoRequest[i].nombre = listaProductosFiltradoRequest[i].productosByIdProducto.nombre;
                    listaProductosFiltradoRequest[i].clave = listaProductosFiltradoRequest[i].productosByIdProducto.clave;
                    listaProductosFiltradoRequest[i].sku = listaProductosFiltradoRequest[i].productosByIdProducto.sku;
                }
                if(listaProductosFiltradoRequest[i].ubicacionesProductosByIdUbicaciones){
                    listaProductosFiltradoRequest[i].ubicacionO = listaProductosFiltradoRequest[i].ubicacionesProductosByIdUbicaciones.area + "-" + listaProductosFiltradoRequest[i].ubicacionesProductosByIdUbicaciones.nivelRack + "-" + listaProductosFiltradoRequest[i].ubicacionesProductosByIdUbicaciones.pasillo  + "-" + listaProductosFiltradoRequest[i].ubicacionesProductosByIdUbicaciones.piso + "-" + listaProductosFiltradoRequest[i].ubicacionesProductosByIdUbicaciones.posicionRack
                }
            }
            yield put({ type: almacenesActions.LISTA_INVENTARIO_FILTRADO_SUCCESS, listaProductosFiltradoRequest, listaProductosFiltradoRequestCantidad });
        }
        else {
            yield put({ type: almacenesActions.LISTA_INVENTARIO_FILTRADO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_INVENTARIO_FILTRADO_FAILURE });
    }
}
export function* obtenerListaInventarioFiltradosSaga() {
    yield takeLatest(almacenesActions.LISTA_INVENTARIO_FILTRADO_REQUEST, obtenerListaInventarioFiltrados);
}

// Saga consulta lista requisicion filtrados 
function* listaRequisicionFiltrado(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const folio = action.folioOrigen;
        const folioMov = action.folio;

        let {busqueda = '', idSubFamilia = '', idFamilia = ''} = action;
        if(idFamilia){
            idFamilia = "&idFamilia=" + idFamilia;
        }
        
        console.log('folio:', folio)
        console.log('folioMov:', folioMov)
        const response = yield call(listaRequisicionFiltradosConsultaCall, token, folio, busqueda,idSubFamilia, page, folioMov);
        console.log("response listaRequisicionFiltrado", response);

        if(response.status === 200){
            let listaProductosFiltradoRequest = response.data.data;
            let listaProductosFiltradoRequestCantidad = response.data.cantidad;
           
            for(let i in listaProductosFiltradoRequest){
                console.log('listaProductosFiltradoRequest[i]', listaProductosFiltradoRequest[i])
                listaProductosFiltradoRequest[i].rowKey = listaProductosFiltradoRequest[i].idRequisicionDirecta;
                if(listaProductosFiltradoRequest[i].productoByIdProducto){
                    listaProductosFiltradoRequest[i].nombre = listaProductosFiltradoRequest[i].productoByIdProducto.nombre;
                    listaProductosFiltradoRequest[i].precio = listaProductosFiltradoRequest[i].productoByIdProducto.precio.toFixed(2);
                    listaProductosFiltradoRequest[i].sku = listaProductosFiltradoRequest[i].productoByIdProducto.sku;
                    listaProductosFiltradoRequest[i].clave = listaProductosFiltradoRequest[i].productoByIdProducto.clave;
                }
            }
            yield put({ type: almacenesActions.LISTA_REQUISICION_FILTRADO_SUCCESS, listaProductosFiltradoRequest, listaProductosFiltradoRequestCantidad})

        } else {
            yield put({ type: almacenesActions.LISTA_REQUISICION_FILTRADO_FAILURE})
        }

    } catch (error) {
        yield put({ type: almacenesActions.LISTA_REQUISICION_FILTRADO_FAILURE})
    }
}
export function* listaRequisicionFiltradoSaga(){
    yield takeLatest(almacenesActions.LISTA_REQUISICION_FILTRADO_REQUEST, listaRequisicionFiltrado)
}

// SAGAS REQUISICION `PUNTO DE REORDEN
function* requisicionPuntoReordenBusqueda(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = (action.page - 1) * 10;
        let param = '';
        let field = 'folio';
        let order = 'ASC';
        let tipo = '';

        if (action.busqueda.param) param = action.busqueda.param;
        if (action.busqueda.field) field = action.busqueda.field;
        if (action.busqueda.order) order = action.busqueda.order;
        if (action.busqueda.tipo !== null) tipo = '&isCompra=' + action.busqueda.tipo;

        const response = yield call(catalogoPuntoReordenBusquedaCall, token, param, order, field, tipo, page);
        console.log("response requisicionPuntoReordenBusqueda", response);

        if(response.status === 200){
            let listaRequisicionPuntoReorden = response.data.data;
            let listaRequisicionPuntoReordenCantidad = response.data.cantidad;

            for (let i in listaRequisicionPuntoReorden) {
                listaRequisicionPuntoReorden[i].fechaAlta  = listaRequisicionPuntoReorden[i].fechaAlta.split('T')[0].split('-').reverse().join('-')
                listaRequisicionPuntoReorden[i].tipo = 'Punto de Reorden';
                if(listaRequisicionPuntoReorden[i].folioRequisicionMateriales){
                    listaRequisicionPuntoReorden[i].tipo = 'Solicitud de Materiales';
                }
            }
            yield put({ type: almacenesActions.REQUISICION_PUNTO_REORDEN_BUSQUEDA_SUCCESS, listaRequisicionPuntoReorden, listaRequisicionPuntoReordenCantidad});
        } else {
            yield put({ type: almacenesActions.REQUISICION_PUNTO_REORDEN_BUSQUEDA_FAILURE });
        }
    } catch (error) {
        yield put({ type: almacenesActions.REQUISICION_PUNTO_REORDEN_BUSQUEDA_FAILURE });
    }
}

export function* requisicionPuntoReordenBusquedaSaga() {
    yield takeLatest(almacenesActions.REQUISICION_PUNTO_REORDEN_BUSQUEDA_REQUEST, requisicionPuntoReordenBusqueda);
}

// generar folio requisicion de punto de reorden
function* generarFolioReq(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(generarFolioRequisicionPuntoReordenCall, token);
        console.log('response generarFolioReq', response)

        if(response.status === 200){
            const busqueda = {order: 'DESC', field: 'numeroFolio'};
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage: 'El folio de Requisición por Punto de Reorden se generó de forma correcta' });
            yield put({ type: almacenesActions.GENERAR_REQUISICION_PUNTO_REORDEN_SUCCESS });
            yield put({ type: almacenesActions.REQUISICION_PUNTO_REORDEN_BUSQUEDA_REQUEST, page: 1, busqueda});
        } else {
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg: true, textMessage: response.message });
            yield put({ type: almacenesActions.GENERAR_REQUISICION_PUNTO_REORDEN_FAILURE });
        }
    } catch (error) {
        yield put({ type: almacenesActions.GENERAR_REQUISICION_PUNTO_REORDEN_FAILURE });
    }
}

export function* generarFolioReqSaga() {
    yield takeLatest(almacenesActions.GENERAR_REQUISICION_PUNTO_REORDEN_REQUEST, generarFolioReq);
}
// consulta por folio
function* consultaFolio(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const folio = action.folio;

        const response = yield call(consultaFolioCall, token, folio, page);
        console.log('response consultaFolio', response);

        if(response.status === 200){
            let listaConsultaFolio = response.data.data;
            let listaConsultaFolioCantidad = response.data.cantidad;

            for(let i in listaConsultaFolio){
                if(listaConsultaFolio[i].productosByIdProducto){
                    listaConsultaFolio[i].nombre = listaConsultaFolio[i].productosByIdProducto.nombre
                    listaConsultaFolio[i].clave = listaConsultaFolio[i].productosByIdProducto.clave
                    listaConsultaFolio[i].codigoAdicional = listaConsultaFolio[i].productosByIdProducto.codigoAdicional
                    listaConsultaFolio[i].descripcion = listaConsultaFolio[i].productosByIdProducto.descripcion
                    listaConsultaFolio[i].presentacion = listaConsultaFolio[i].productosByIdProducto.presentacion
                }
                if(listaConsultaFolio[i].nombreProveedor === null) listaConsultaFolio[i].nombreProveedor = "No tiene proveedor";

                if(listaConsultaFolio[i].fechaProbableEntrega) {
                   listaConsultaFolio[i].fechaProbableEntrega = listaConsultaFolio[i].fechaProbableEntrega.split('T')[0].split('-').reverse().join('-');
                } else {
                    listaConsultaFolio[i].fechaProbableEntrega = "No tiene fecha probable de entrega";
                }

            }

            yield put({ type: almacenesActions.CONSULTA_FOLIO_REQUISICION_SUCCESS, listaConsultaFolio, listaConsultaFolioCantidad});
        } else {
            yield put({ type: almacenesActions.CONSULTA_FOLIO_REQUISICION_FAILURE })
        }
    } catch (error) {
        yield put({ type: almacenesActions.CONSULTA_FOLIO_REQUISICION_FAILURE })
    }
}

export function* consultaFolioSaga() {
    yield takeLatest(almacenesActions.CONSULTA_FOLIO_REQUISICION_REQUEST, consultaFolio);
}

// saga editar producto de requisicion punto de reorden
function* editarProductoRequisicionPuntoReorden(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idRequisicionPuntoReorden = action.productoReqSeleccionado.idRequisicionPuntoReorden;

        const data = {
            "idRequisicionPuntoReorden": idRequisicionPuntoReorden,
             "idProducto": action.productoReqSeleccionado.idProducto,
             "numeroFolio": action.productoReqSeleccionado.numeroFolio,
             "estadoFolio": action.productoReqSeleccionado.estadoFolio,
             "nombreProveedor": action.formulario.nombreProveedor,
             "fechaAlta": action.productoReqSeleccionado.fechaAlta,
             "fechaActualizacion": action.productoReqSeleccionado.fechaActualizacion,
             "fechaProbableEntrega": action.formulario.fechaProbableEntrega.format('YYYY-MM-DD'),
             "rutaArchivo": null
        }
        console.log('data', data);

        const response = yield call(editarProductoRequisicionPuntoReordenCall, token, data, idRequisicionPuntoReorden);
        console.log('response editarProductoRequisicion', response);

        if (response.status == 200) {
            const page = action.page;
            const folio = action.productoReqSeleccionado.numeroFolio;
            const showSuccessMsg = true;
            const textMessage = "El producto fue actualizado con éxito";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.EDITAR_PRODUCTO_REQUISICION_PUNTOREORDEN_SUCCESS });
            yield put({ type: almacenesActions.CONSULTA_FOLIO_REQUISICION_REQUEST, folio, page });

        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message + " Modificar el producto.";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.EDITAR_PRODUCTO_REQUISICION_PUNTOREORDEN_FAILURE });
        }
        
    } catch (error) {
        yield put({ type: almacenesActions.EDITAR_PRODUCTO_REQUISICION_PUNTOREORDEN_FAILURE });
    }
}

export function* editarProductoRequisicionPuntoReordenSaga() {
    yield takeLatest(almacenesActions.EDITAR_PRODUCTO_REQUISICION_PUNTOREORDEN_REQUEST, editarProductoRequisicionPuntoReorden);
}

// cerrar folio requisicion punto de reorden
function* cerrarFolioRequisicion(action){
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        const folio = action.folioSeleccionado.numeroFolio;
        
        const formData = new FormData();
        if(action.formulario){
            formData.append('file', action.formulario.pdf.file.originFileObj);
        }
        console.log('formData', formData);
        
        const response = yield call(cerrarFolioRequisicionPuntoReordenCall, token, folio, formData);
        console.log('response cerrarFolioRequisicion', response);

        if(response.status === 200){
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage: 'El folio se cerró de forma correcta' });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "cerrarFolio" });
            yield put({ type: almacenesActions.CERRAR_FOLIO_REQUISICION_PUNTOREORDEN_SUCCESS });
            yield put({ type: almacenesActions.REQUISICION_PUNTO_REORDEN_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda});
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.CERRAR_FOLIO_REQUISICION_PUNTOREORDEN_FAILURE });
        }

    } catch (error) {
        yield put({ type: almacenesActions.CERRAR_FOLIO_REQUISICION_PUNTOREORDEN_FAILURE });
    }
}
export function* cerrarFolioRequisicionSaga(){
    yield takeLatest(almacenesActions.CERRAR_FOLIO_REQUISICION_PUNTOREORDEN_REQUEST, cerrarFolioRequisicion);
}

// saga descargar folio
function* descargarFolioRequisicion(action){
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        const folio = action.folioSeleccionado.numeroFolio;
        const idUsuarioRecibe = action.idUsuario;
        console.log('folio', folio);
        console.log('idUsuarioRecibe', idUsuarioRecibe);
        
        
        const response = yield call(descargarFolioRequisicionPuntoReordenCall, token, folio, idUsuarioRecibe);
        console.log('response descagarFolioRequisicion', response);

        if(response.status === 200){
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            let month = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);

            link.href = url;
            link.download = "PuntoReorden_" + new Date().getDate() + "-" + month  + "-" + + new Date().getFullYear() + "_" + new Date().getHours() + "" + new Date().getMinutes() +  ".pdf"

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);


            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage: 'El folio se descargó de forma correcta' });
            yield put({ type: almacenesActions.DESCARGAR_FOLIO_REQUISICION_PUNTOREORDEN_SUCCESS });
        }
        else {
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg: true, textMessage: response.message });
            yield put({ type: almacenesActions.DESCARGAR_FOLIO_REQUISICION_PUNTOREORDEN_FAILURE });
        }

    } catch (error) {
        yield put({ type: almacenesActions.DESCARGAR_FOLIO_REQUISICION_PUNTOREORDEN_FAILURE });
    }
}
export function* descargarFolioRequisicionSaga(){
    yield takeLatest(almacenesActions.DESCARGAR_FOLIO_REQUISICION_PUNTOREORDEN_REQUEST, descargarFolioRequisicion);
}

// saga descargar folio
function* descargarFolioRequisicionCompra(action){
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        const folio = action.folioSeleccionado.folioRequisicionMateriales;
        console.log('folio', folio);
        
        
        const response = yield call(descargarFolioRequisicionCompraCall, token, folio);
        console.log('response descagarFolioRequisicion', response);

        if(response.status === 200){
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            let month = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);

            link.href = url;
            link.download = "Requisición_de_Compra_" + new Date().getDate() + "-" + month  + "-" + + new Date().getFullYear() + "_" + new Date().getHours() + "" + new Date().getMinutes() +  ".pdf"

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage: 'El folio se descargó de forma correcta' });
            yield put({ type: almacenesActions.DESCARGAR_FOLIO_REQUISICION_COMPRA_SUCCESS });
        }
        else {
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg: true, textMessage: response.message });
            yield put({ type: almacenesActions.DESCARGAR_FOLIO_REQUISICION_COMPRA_FAILURE });
        }

    } catch (error) {
        yield put({ type: almacenesActions.DESCARGAR_FOLIO_REQUISICION_COMPRA_FAILURE });
    }
}
export function* descargarFolioRequisicionCompraSaga(){
    yield takeLatest(almacenesActions.DESCARGAR_FOLIO_REQUISICION_COMPRA_REQUEST, descargarFolioRequisicionCompra);
}

// Saga cancelar folio de requisicion punto de reorden
function* cancelarFolioRequisicion(action){
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        const folio = action.folioSeleccionado.numeroFolio;
        
        const response = yield call(cancelarFolioPuntoReordenCall, token, folio);
        console.log('response cancelarFolioRequisicion', response);

        if(response.status === 200){
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage: "El folio se canceló de forma correcta" });
            yield put({ type: almacenesActions.CANCELAR_FOLIO_REQUISICION_PUNTOREORDEN_SUCCESS });
            yield put({ type: almacenesActions.SHOW_MODAL_REQUISICION_PUNTO_REORDEN, showModalRequisicionPuntoReorden: false });
            yield put({ type: almacenesActions.REQUISICION_PUNTO_REORDEN_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda});
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.CANCELAR_FOLIO_REQUISICION_PUNTOREORDEN_FAILURE });
        }

    } catch (error) {
        yield put({ type: almacenesActions.CANCELAR_FOLIO_REQUISICION_PUNTOREORDEN_FAILURE });
    }
}
export function* cancelarFolioRequisicionSaga(){
    yield takeLatest(almacenesActions.CANCELAR_FOLIO_REQUISICION_PUNTOREORDEN_REQUEST, cancelarFolioRequisicion);
}



//CATÁLOGO DE PRODUCTOS
function* listaProductosActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaProductosActivosCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaProductosActivos = response.data;

            yield put({ type: almacenesActions.LISTA_PRODUCTOS_ACTIVOS_SUCCESS, listaProductosActivos });
        }
        else {
            yield put({ type: almacenesActions.LISTA_PRODUCTOS_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_PRODUCTOS_ACTIVOS_FAILURE });
    }
}
export function* listaProductosActivosSaga() {
    yield takeLatest(almacenesActions.LISTA_PRODUCTOS_ACTIVOS_REQUEST, listaProductosActivos);
}

function* listaProductosActivosBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const busqueda = action.busqueda;
        const tipoFamilia = 'Productos e insumos';

        const response = yield call(listaProductosActivosBusquedaCall, token, busqueda, tipoFamilia);
        console.log(response);

        if (response.status == 200) {
            const listaProductosActivosBusqueda = response.data;
            for(let i=0; i<listaProductosActivosBusqueda.length; i++){
                if(listaProductosActivosBusqueda[i].unidadMedidaByIdUnidadMedida){
                    listaProductosActivosBusqueda[i].unidadMedida = listaProductosActivosBusqueda[i].unidadMedidaByIdUnidadMedida.unidadMedida
                }
                else{
                    listaProductosActivosBusqueda[i].unidadMedida = 'La unidad de medida está desactivada.';
                }
            }

            yield put({ type: almacenesActions.LISTA_PRODUCTOS_BUSQUEDA_SUCCESS, listaProductosActivosBusqueda });
        }
        else {
            yield put({ type: almacenesActions.LISTA_PRODUCTOS_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_PRODUCTOS_BUSQUEDA_FAILURE });
    }
}
export function* listaProductosActivosBusquedaSaga() {
    yield takeLatest(almacenesActions.LISTA_PRODUCTOS_BUSQUEDA_REQUEST, listaProductosActivosBusqueda);
}

function* listaHerramientasActivasBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const busqueda = action.busqueda;
        const tipoFamilia = 'Herramientas';

        const response = yield call(listaProductosActivosBusquedaCall, token, busqueda, tipoFamilia);
        console.log(response);

        if (response.status == 200) {
            const listaHerramientasActivasBusqueda = response.data;
            for(let i=0; i<listaHerramientasActivasBusqueda.length; i++){
                if(listaHerramientasActivasBusqueda[i].unidadMedidaByIdUnidadMedida){
                    listaHerramientasActivasBusqueda[i].unidadMedida = listaHerramientasActivasBusqueda[i].unidadMedidaByIdUnidadMedida.unidadMedida
                }
                else{
                    listaHerramientasActivasBusqueda[i].unidadMedida = 'La unidad de medida está desactivada.';
                }
            }

            yield put({ type: almacenesActions.LISTA_HERRAMIENTAS_BUSQUEDA_SUCCESS, listaHerramientasActivasBusqueda });
        }
        else {
            yield put({ type: almacenesActions.LISTA_HERRAMIENTAS_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_HERRAMIENTAS_BUSQUEDA_FAILURE });
    }
}
export function* listaHerramientasActivasBusquedaSaga() {
    yield takeLatest(almacenesActions.LISTA_HERRAMIENTAS_BUSQUEDA_REQUEST, listaHerramientasActivasBusqueda);
}

function* listaProductosBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let {busqueda = '', campo = 'nombre', orden = 'ASC', idSubFamilia = '', familiaTipoActivo = ''} = action;

        console.log(page);
        console.log(busqueda,campo,orden,idSubFamilia);
        const response = yield call(getProductosPageCall, token, page, busqueda,campo,orden,idSubFamilia, familiaTipoActivo);
        console.log("response listaProductosBusqueda", response);
        console.log(response.data.data)

        if (response.status == 200) {
            let listaProductosBusqueda = response.data.data;
            let listaProductosBusquedaCantidad = response.data.cantidad;

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            listaProductosBusqueda.forEach( producto => {
                let fechaAlta = producto.fechaAlta.split('T')[0];
                producto.fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
                producto.fechaCaducidadC = "No tiene fecha"
                producto.loteC = "No tiene lote"
                const {familia} = producto.subFamiliaByIdSubFamilia.familiaByIdFamilia;
                const  {subFamilia} = producto.subFamiliaByIdSubFamilia;

                producto.condicion = producto.activoCaractByIdProducto?.condicion;
                producto.especificaciones = producto.activoCaractByIdProducto?.especificaciones;
                producto.usoBasico = producto.activoCaractByIdProducto?.usoBasico;
                producto.vidaUtil = producto.activoCaractByIdProducto?.vidaUtil;
                producto.unidadMedidaVidaUtil = producto.activoCaractByIdProducto?.unidadMedidaByIdUnidadMedida.unidadMedida;
                producto.vidaUtilSugerida = producto.activoCaractByIdProducto?.vidaUtilSugerida.split(' ')[0];
                producto.unidadMedidaSugerida = producto.activoCaractByIdProducto?.vidaUtilSugerida.split(' ')[1];

                producto.familia = familia;
                producto.subFamilia = subFamilia;
                producto.rowKey = producto.idProducto;
                producto.unidadMedida = producto.unidadMedidaByIdUnidadMedida.unidadMedida;
                producto.precio = producto.precio.toFixed(2);
                producto.tipoMoneda = producto.tipoMonedaByIdTipoMoneda.nombre + ' (' + producto.tipoMonedaByIdTipoMoneda.nomenclatura + ')';
                producto.tipoMonedaEditar = producto.tipoMonedaByIdTipoMoneda.idTipoMoneda + ' - ' + producto.tipoMonedaByIdTipoMoneda.nombre + ' (' + producto.tipoMonedaByIdTipoMoneda.nomenclatura + ')';

                producto.precioTabla = numberWithCommas(producto.precio) + ' ' + producto.tipoMonedaByIdTipoMoneda.nomenclatura;
                producto.precioTablaGridProductos = numberWithCommas(producto.precio);
                //ACÁ
            })
            
            yield put({ type: almacenesActions.PRODUCTOS_BUSQUEDA_SUCCESS, listaProductosBusqueda, listaProductosBusquedaCantidad });
        }
        else {
            yield put({ type: almacenesActions.PRODUCTOS_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.PRODUCTOS_BUSQUEDA_FAILURE });
    }
}
export function* listaProductosBusquedaSaga() {
    yield takeLatest(almacenesActions.PRODUCTOS_BUSQUEDA_REQUEST, listaProductosBusqueda);
}

function* cambiarEstadoProducto(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idProducto = action.idProducto;

        const response = yield call(estadoProductoCall, token, idProducto);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showSuccessMsg = true;
            const textMessage = "El estado del producto se cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.ESTADO_PRODUCTO_SUCCESS });
            yield put({ type: almacenesActions.PRODUCTOS_BUSQUEDA_REQUEST, page });
            yield put({ type: almacenesActions.LISTA_PRODUCTOS_ACTIVOS_REQUEST});
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.ESTADO_PRODUCTO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.ESTADO_PRODUCTO_FAILURE });
    }
}
export function* cambiarEstadoProductoSaga() {
    yield takeLatest(almacenesActions.ESTADO_PRODUCTO_REQUEST, cambiarEstadoProducto);
}

function* exportarExcelProductos(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const order = action.order;
        const field = action.field;
        const idSubFamilia = action.idSubFamilia ? action.idSubFamilia : null;

        const response = yield call(exportarExcelProductosCall, token, order, field, idSubFamilia);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el excel de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Productos" + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_PRODUCTOS_EXCEL_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el archivo";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_PRODUCTOS_EXCEL_FAILURE });
        }
    } catch (error) {
       yield put({ type: almacenesActions.EXPORTAR_PRODUCTOS_EXCEL_FAILURE });
    }
} export function* exportarExcelProductosSaga(){
    yield takeLatest(almacenesActions.EXPORTAR_PRODUCTOS_EXCEL_REQUEST, exportarExcelProductos)
}

function* crearNuevoProducto(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let {nombre, marca, modelo, fabricante, clave, sku, descripcion, presentacion, codigoAdicional, idSubFamilia, consideraciones = "",
            caracteristicaLote, minimo, maximo,reorden,idUnidadMedida, precio, idTipoMoneda, especificaciones, 
            usoBasico, vidaUtil, unidadMedidaVidaUtil,  vidaUtilSugerida, unidadMedidaSugerida, condicion
        } = action.formulario;

        let idFamilia = null;
        for(let i in action.listaSubfamiliasProductosActivos){
            if(parseInt(idSubFamilia) === action.listaSubfamiliasProductosActivos[i].idSubFamilia){
                idFamilia = action.listaSubfamiliasProductosActivos[i].idFamilia;
            }
        }
        if(vidaUtil){
            if (vidaUtilSugerida && unidadMedidaSugerida) {
                vidaUtilSugerida = vidaUtilSugerida + ' ' + unidadMedidaSugerida;
            }
            for (let i in action.listaUnidadesDeMedicionActivos) {
                if(unidadMedidaVidaUtil === action.listaUnidadesDeMedicionActivos[i].unidadMedida){
                    unidadMedidaVidaUtil = action.listaUnidadesDeMedicionActivos[i].idUnidadMedida;
                }
            }
        }
        
        const data = {
            "idProducto":null,
            "nombre":nombre.trim(),
            'marca': marca.trim(),
            'modelo': modelo.trim(),
            'fabricante': fabricante.trim(),
            "clave": clave.trim(),
            "sku": sku.trim(),
            "descripcion": descripcion.trim(),
            "presentacion": presentacion.trim(),
            'codigoAdicional': codigoAdicional.trim(),
            "idFamilia": idFamilia,
            "idSubFamilia": parseInt(idSubFamilia),
            "consideraciones": consideraciones.trim(),
            "refrigerado": false,
            "caracteristicaLote": caracteristicaLote,
            "minimo": minimo,
            "maximo": maximo,
            "reorden": reorden,
            "idUnidadMedidaReorden": parseInt(idUnidadMedida),
            "idTipoMoneda": parseInt(idTipoMoneda),
            "precio": parseFloat(precio),
            "activoProducto": true,

            "idActivoCaract": null,
            "especificaciones": vidaUtil ? descripcion.trim() : null,
            "usoBasico": usoBasico ? usoBasico : null,
            "vidaUtil": vidaUtil ? vidaUtil : null,
            "vidaUtilSugerida": vidaUtilSugerida ? vidaUtilSugerida : null,
            "idUnidadMedidaVidaUtil": unidadMedidaVidaUtil ? parseInt(unidadMedidaVidaUtil) : null,
            "condicion": condicion ? condicion.toUpperCase() : null,
            "activoActivoCaract": vidaUtil ? true : null,

            "fechaAlta":null,
            "fechaActualizacion":null,
            "familiaByIdFamilia":null,
            "subFamiliaByIdSubFamilia":null,
            "unidadMedidaByIdUnidadMedida":null,
            "tipoMonedaByIdTipoMoneda":null
        }

        console.log(data);
        const formData = new FormData();
        if (action.formulario.cartaFabricante) {
            formData.append('file', action.formulario.cartaFabricante.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        const response = yield call(crearProductoCall, token, formData);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showModalNuevoProducto = false;
            const showSuccessMsg = true;
            const textMessage = "El producto " + nombre + " fue agregado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "crearProducto" });
            yield put({ type: almacenesActions.NUEVO_PRODUCTO_SUCCESS });
            yield put({ type: almacenesActions.PRODUCTOS_BUSQUEDA_REQUEST, page });
            yield put({ type: almacenesActions.LISTA_PRODUCTOS_ACTIVOS_REQUEST});
            yield put({ type: almacenesActions.SHOW_MODAL_NUEVO_PRODUCTO, showModalNuevoProducto });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.NUEVO_PRODUCTO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.NUEVO_PRODUCTO_FAILURE });
    }
}
export function* crearNuevoProductoSaga() {
    yield takeLatest(almacenesActions.NUEVO_PRODUCTO_REQUEST, crearNuevoProducto);
}

function* editarProducto(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idProducto = action.productoSeleccionado.idProducto;
        const productoSeleccionado = action.productoSeleccionado;

        let {nombre, marca, modelo, fabricante, clave, sku, descripcion, presentacion, codigoAdicional, subFamilia, consideraciones="",
            caracteristicaLote, minimo, maximo,reorden,unidadMedida, tipoMoneda, precio, especificaciones, 
            usoBasico, vidaUtil, unidadMedidaVidaUtil,  vidaUtilSugerida, unidadMedidaSugerida, condicion
        } = action.formulario;

        unidadMedida = isNaN(unidadMedida) ? productoSeleccionado.idUnidadMedida : unidadMedida
        tipoMoneda = isNaN(tipoMoneda) ? productoSeleccionado.idTipoMoneda : tipoMoneda

        let familia = null;
        for(let i in action.listaSubfamiliasProductosActivos){
            if(subFamilia === action.listaSubfamiliasProductosActivos[i].subFamilia){
                familia = action.listaSubfamiliasProductosActivos[i].idFamilia;
                subFamilia = action.listaSubfamiliasProductosActivos[i].idSubFamilia;
            }
        }

        if(vidaUtil){
            if (vidaUtilSugerida && unidadMedidaSugerida) {
                vidaUtilSugerida = vidaUtilSugerida + ' ' + unidadMedidaSugerida;
            }
            for (let i in action.listaUnidadesDeMedicionActivos) {
                if(unidadMedidaVidaUtil === action.listaUnidadesDeMedicionActivos[i].unidadMedida){
                    unidadMedidaVidaUtil = action.listaUnidadesDeMedicionActivos[i].idUnidadMedida;
                }
            }
        }

        const data = {
            "idProducto": idProducto,
            "nombre": nombre.trim(),
            'marca': marca.trim(),
            'modelo': modelo.trim(),
            'fabricante': fabricante.trim(),
            "clave": clave.trim(),
            "sku": sku.trim(),
            "descripcion": descripcion.trim(),
            "presentacion": presentacion.trim(),
            'codigoAdicional': codigoAdicional.trim(),
            "idFamilia": familia,
            "idSubFamilia": subFamilia,
            "consideraciones": consideraciones.trim(),
            "refrigerado": false,
            "caracteristicaLote": caracteristicaLote,
            "minimo": minimo,
            "maximo": maximo,
            "reorden": reorden,
            "idUnidadMedidaReorden": parseInt(unidadMedida),
            "idTipoMoneda": parseInt(tipoMoneda),
            "precio": parseFloat(precio),
            "activoProducto": true,
            'cartaFabricante': action.productoSeleccionado.activoCaractByIdProducto ? action.productoSeleccionado.activoCaractByIdProducto.cartaFabricante : '',

            "idActivoCaract": productoSeleccionado.activoCaractByIdProducto ? productoSeleccionado.activoCaractByIdProducto.idActivoCaract : null,
            "especificaciones": vidaUtil ? descripcion.trim() : null,
            "usoBasico": usoBasico ? usoBasico : null,
            "vidaUtil": vidaUtil ? vidaUtil : null,
            "vidaUtilSugerida": vidaUtilSugerida ? vidaUtilSugerida : null,
            "idUnidadMedidaVidaUtil": unidadMedidaVidaUtil ? parseInt(unidadMedidaVidaUtil) : null,
            "condicion": condicion ? condicion.toUpperCase() : null,
            "activoActivoCaract": vidaUtil ? true : null,

            "fechaAlta": null,
            "fechaActualizacion": null,
            "familiaByIdFamilia": null,
            "subFamiliaByIdSubFamilia": null,
            "unidadMedidaByIdUnidadMedida": null,
            "tipoMonedaByIdTipoMoneda":null
        }

        console.log(data);
        const formData = new FormData();
        if (action.formulario.cartaFabricante) {
            formData.append('file', action.formulario.cartaFabricante.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        const response = yield call(editarProductoCall, token, formData, idProducto);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showModalEditarProducto = false;
            const productoSeleccionado = null;
            const limpiarFormulario = "EP";
            const showSuccessMsg = true;
            const textMessage = "El producto/insumo/herramienta " + nombre  + " fue actualizado con éxito";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario });
            yield put({ type: almacenesActions.EDITAR_PRODUCTO_SUCCESS });
            yield put({ type: almacenesActions.PRODUCTOS_BUSQUEDA_REQUEST, page, busqueda: action.busqueda });
            yield put({ type: almacenesActions.LISTA_PRODUCTOS_ACTIVOS_REQUEST});
            yield put({ type: almacenesActions.SHOW_MODAL_EDITAR_PRODUCTO, showModalEditarProducto, productoSeleccionado });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.EDITAR_PRODUCTO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.EDITAR_PRODUCTO_FAILURE });
    }
}
export function* editarProductoSaga() {
    yield takeLatest(almacenesActions.EDITAR_PRODUCTO_REQUEST, editarProducto);
}

//CATÁLOGO DE UBICACIÓN DE PRODUCTOS 

function* listaUbicacionesProductosActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaUbicacionesProductosActivosCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaUbicacionesProductosActivos = response.data;

            yield put({ type: almacenesActions.LISTA_UBICACIONES_PRODUCTOS_ACTIVOS_SUCCESS, listaUbicacionesProductosActivos });
        }
        else {
            yield put({ type: almacenesActions.LISTA_UBICACIONES_PRODUCTOS_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_UBICACIONES_PRODUCTOS_ACTIVOS_FAILURE });
    }
}
export function* listaUbicacionesProductosActivosSaga() {
    yield takeLatest(almacenesActions.LISTA_UBICACIONES_PRODUCTOS_ACTIVOS_REQUEST, listaUbicacionesProductosActivos);
}

function* listaUbicacionesProductosBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let {busqueda = '', campo = 'idUbicacionProducto', orden = 'ASC', nombreAlmacen=''} = action;

        console.log(page,busqueda, campo, orden, nombreAlmacen);
        const response = yield call(getUbicacionesProductosPageCall, token, page, busqueda, campo, orden, nombreAlmacen);
        console.log(response);
        console.log(response.data.data)

        if (response.status == 200) {
            let listaUbicacionesProductosBusqueda = response.data.data;
            let listaUbicacionesProductosBusquedaCantidad = response.data.cantidad;

            listaUbicacionesProductosBusqueda.forEach( producto => {
                     
                const  {nombreC} = producto.almacenByIdAlmacen;
                producto.almacen = nombreC;
                
            })
            
            yield put({ type: almacenesActions.UBICACIONES_PRODUCTOS_BUSQUEDA_SUCCESS, listaUbicacionesProductosBusqueda, listaUbicacionesProductosBusquedaCantidad });
        }
        else {
            yield put({ type: almacenesActions.UBICACIONES_PRODUCTOS_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.UBICACIONES_PRODUCTOS_BUSQUEDA_FAILURE });
    }
}
export function* listaUbicacionesProductosBusquedaSaga() {
    yield takeLatest(almacenesActions.UBICACIONES_PRODUCTOS_BUSQUEDA_REQUEST, listaUbicacionesProductosBusqueda);
}

function* cambiarEstadoProductoUbicacion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idUbicacionProducto = action.idUbicacionProducto;

        const response = yield call(estadoUbicacionProductoCall, token, idUbicacionProducto);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showSuccessMsg = true;
            const textMessage = "El estado de la ubicación del producto se cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.ESTADO_UBICACION_PRODUCTO_SUCCESS });
            yield put({ type: almacenesActions.UBICACIONES_PRODUCTOS_BUSQUEDA_REQUEST, page });
            yield put({ type: almacenesActions.LISTA_UBICACIONES_PRODUCTOS_ACTIVOS_REQUEST});
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.ESTADO_UBICACION_PRODUCTO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.ESTADO_UBICACION_PRODUCTO_FAILURE });
    }
}
export function* cambiarEstadoProductoUbicacionSaga() {
    yield takeLatest(almacenesActions.ESTADO_UBICACION_PRODUCTO_REQUEST, cambiarEstadoProductoUbicacion);
}

function* crearUbicacionProducto(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let {piso, area, pasillo, rack, nivelRack, posicionRack, idAlmacen} = action.formulario;

        const data = {
            "idUbicacionProducto": null,
            "piso": piso.trim(),
            "area": area.trim(),
            "pasillo": pasillo.trim(),
            "rack": rack.trim(),
            "nivelRack": nivelRack.trim(),
            "posicionRack": posicionRack.trim(),
            "idAlmacen": idAlmacen,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": null
        }

        console.log(data);
        const response = yield call(crearUbicacionProductoCall, token, data);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showModalNuevaUbicacionProducto = false;
            const showSuccessMsg = true;
            const textMessage = "La ubicación del producto fue agregada con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "CUP" });
            yield put({ type: almacenesActions.NUEVA_UBICACION_PRODUCTO_SUCCESS });
            yield put({ type: almacenesActions.UBICACIONES_PRODUCTOS_BUSQUEDA_REQUEST, page });
            yield put({ type: almacenesActions.LISTA_UBICACIONES_PRODUCTOS_ACTIVOS_REQUEST});
            yield put({ type: almacenesActions.SHOW_MODAL_NUEVA_UBICACION_PRODUCTO, showModalNuevaUbicacionProducto });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.NUEVA_UBICACION_PRODUCTO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.NUEVA_UBICACION_PRODUCTO_FAILURE });
    }
}
export function* crearUbicacionProductoSaga() {
    yield takeLatest(almacenesActions.NUEVA_UBICACION_PRODUCTO_REQUEST, crearUbicacionProducto);
}

function* editarUbicacionProducto(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const ubicacionProductoSeleccionado = action.ubicacionProductoSeleccionado;
        const idUbicacionProducto = ubicacionProductoSeleccionado.idUbicacionProducto;

        let {piso, area, pasillo, rack, nivelRack, posicionRack, almacen} = action.formulario;

        almacen = isNaN(almacen) ? ubicacionProductoSeleccionado.idAlmacen : almacen;;
        
        const data = {
            "idUbicacionProducto": null,
            "piso": piso.trim(),
            "area": area.trim(),
            "pasillo": pasillo.trim(),
            "rack": rack.trim(),
            "nivelRack": nivelRack.trim(),
            "posicionRack": posicionRack.trim(),
            "idAlmacen": almacen,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": null
        }

        console.log(data);
        const response = yield call(editarUbicacionProductoCall, token, data, idUbicacionProducto);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showModalEditarUbicacionProducto = false;
            const ubicacionProductoSeleccionado = null;
            const limpiarFormulario = "EUP";
            const showSuccessMsg = true;
            const textMessage = "La ubicación del producto fue actualizada con éxito";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario });
            yield put({ type: almacenesActions.EDITAR_UBICACION_PRODUCTO_SUCCESS });
            yield put({ type: almacenesActions.UBICACIONES_PRODUCTOS_BUSQUEDA_REQUEST, page });
            yield put({ type: almacenesActions.LISTA_UBICACIONES_PRODUCTOS_ACTIVOS_REQUEST});
            yield put({ type: almacenesActions.SHOW_MODAL_EDITAR_UBICACION_PRODUCTO, showModalEditarUbicacionProducto, ubicacionProductoSeleccionado });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message + " Modificar la ubicación del producto.";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: almacenesActions.EDITAR_UBICACION_PRODUCTO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.EDITAR_UBICACION_PRODUCTO_FAILURE });
    }
}
export function* editarUbicacionProductoSaga() {
    yield takeLatest(almacenesActions.EDITAR_UBICACION_PRODUCTO_REQUEST, editarUbicacionProducto);
}

function* exportarExcelUbicacionesProductos(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const order = action.order;
        const field = action.field;

        const response = yield call(exportarExcelUbicacionesProductosCall, token, order, field);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Se exportó el excel de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "UbicacionesProductos" + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_UBICACIONES_PRODUCTOS_EXCEL_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el archivo";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.EXPORTAR_UBICACIONES_PRODUCTOS_EXCEL_FAILURE });
        }
    } catch (error) {
       yield put({ type: almacenesActions.EXPORTAR_UBICACIONES_PRODUCTOS_EXCEL_FAILURE });
    }
} export function* exportarExcelUbicacionesProductosSaga(){
    yield takeLatest(almacenesActions.EXPORTAR_UBICACIONES_PRODUCTOS_EXCEL_REQUEST, exportarExcelUbicacionesProductos)
}

// Filtro para búsqueda en Select Familias
export function* listaFamiliasFiltro(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const busqueda = action.busqueda;
      
        const response = yield call(listaFamiliasFiltroCall, token, busqueda)
        console.log(response);

        if (response.status === 200) {
            let listaFamiliasFiltro = response.data;
            
            yield put({ type: almacenesActions.LISTA_FAMILIAS_FILTRO_SUCCESS, listaFamiliasFiltro});
        } else {
            yield put ({ type: almacenesActions.LISTA_FAMILIAS_FILTRO_FAILURE});
        }
    } catch (error) {
        yield put ({ type: almacenesActions.LISTA_FAMILIAS_FILTRO_FAILURE});
    }
}
export function* listaFamiliasFiltroSaga() {
    yield takeLatest(almacenesActions.LISTA_FAMILIAS_FILTRO_REQUEST, listaFamiliasFiltro);
}

// Filtro para búsqueda en Select Subfamilias

// export function* listaSubfamiliasFiltro(action) {
//     try {
//         console.log(action);
//         const token = sessionStorage.getItem('token');
//         const busqueda = action.busqueda;
      
//         const response = yield call(listaSubfamiliasFiltroCall, token, busqueda)
//         console.log(response);

//         if (response.status === 200) {
//             let listaSubfamiliasFiltro = response.data;
            
//             yield put({ type: almacenesActions.LISTA_SUBFAMILIAS_FILTRO_SUCCESS, listaSubfamiliasFiltro});
//         } else {
//             yield put ({ type: almacenesActions.LISTA_SUBFAMILIAS_FILTRO_FAILURE});
//         }
//     } catch (error) {
//         yield put ({ type: almacenesActions.LISTA_SUBFAMILIAS_FILTRO_FAILURE});
//     }
// }
// export function* listaSubfamiliasFiltroSaga() {
//     yield takeLatest(almacenesActions.LISTA_SUBFAMILIAS_FILTRO_REQUEST, listaSubfamiliasFiltro);
// }

// Servicios por Almacenes
function* listaServiciosbyAlmacen(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = [];

            if(action.todosAlmacenes){
                for(let i = 0; i < action.listaAlmacenesActivos.length; i++){
                    data[i] = action.listaAlmacenesActivos[i];
                }
            } else {
                for(let i = 0; i < action.listaAlmacenesActivos.length; i++){
                    for(let j = 0; j < action.almacenesSeleccionados.length; j ++){
                        if(action.listaAlmacenesActivos[i].idAlmacen === parseInt(action.almacenesSeleccionados[j].idAlmacen)){
                            data[j] = action.listaAlmacenesActivos[i];
                        }
                    }
                }
            }
        console.log('data', data);

        const response = yield call(listaServiciosbyAlmacenCall, token, data);
        console.log('response', response);

        if (response.status == 200) {
            const listaServiciosbyAlmacen = response.data;

            yield put({ type: almacenesActions.LISTA_SERVICIOS_ALMACEN_SUCCESS, listaServiciosbyAlmacen });
        }
        else {
            yield put({ type: almacenesActions.LISTA_SERVICIOS_ALMACEN_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_SERVICIOS_ALMACEN_FAILURE });
    }
}
export function* listaServiciosbyAlmacenSaga() {
    yield takeLatest(almacenesActions.LISTA_SERVICIOS_ALMACEN_REQUEST, listaServiciosbyAlmacen);
}

// Familias por Servicios
function* listaFamiliasbyServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const data = [];

            if(action.todosServicios){
                for(let i = 0; i < action.listaServiciosbyAlmacen.length; i++){
                    data[i] = action.listaServiciosbyAlmacen[i];
                }
            } else {
                // for(let i = 0; i < action.listaServiciosbyAlmacen.length; i++){
                //     for(let j = 0; j < action.serviciosSeleccionados.length; j ++){
                //         if(action.listaServiciosbyAlmacen[i].idAlmacen === parseInt(action.serviciosSeleccionados[j].idAlmacen)){
                //             data[j] = action.listaServiciosbyAlmacen[i];
                //         }
                //     }
                // }
                for(let i = 0; i < action.serviciosSeleccionados.length; i++){
                    data[i] = action.serviciosSeleccionados[i];
                }
            }
        console.log('data', data);

        const response = yield call(listaFamiliasbyServicioCall, token, data);
        console.log('response', response);

        if (response.status == 200) {
            const listaFamiliasbyServicio = response.data;

            yield put({ type: almacenesActions.LISTA_FAMILIAS_SERVICIO_SUCCESS, listaFamiliasbyServicio });
        }
        else {
            yield put({ type: almacenesActions.LISTA_FAMILIAS_SERVICIO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_FAMILIAS_SERVICIO_FAILURE });
    }
}
export function* listaFamiliasbyServicioSaga() {
    yield takeLatest(almacenesActions.LISTA_FAMILIAS_SERVICIO_REQUEST, listaFamiliasbyServicio);
}

// Subfamilias por Familias
function* listaSubfamiliasbyFamilia(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const data = [];

            if(action.todasFamilias){
                for(let i = 0; i < action.listaFamiliasbyServicio.length; i++){
                    data[i] = action.listaFamiliasbyServicio[i];
                }
            } else {
                for(let i = 0; i < action.familiasSeleccionadas.length; i++){
                    data[i] = action.familiasSeleccionadas[i];
                }
            }
               
        console.log('data', data);

        const response = yield call(listaSubfamiliasbyFamiliaCall, token, data);
        console.log('response', response);

        if (response.status == 200) {
            const listaSubfamiliasbyFamilia = response.data;

            yield put({ type: almacenesActions.LISTA_SUBFAMILIAS_FAMILIA_SUCCESS, listaSubfamiliasbyFamilia });
        }
        else {
            yield put({ type: almacenesActions.LISTA_SUBFAMILIAS_FAMILIA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_SUBFAMILIAS_FAMILIA_FAILURE });
    }
}
export function* listaSubfamiliasbyFamiliaSaga() {
    yield takeLatest(almacenesActions.LISTA_SUBFAMILIAS_FAMILIA_REQUEST, listaSubfamiliasbyFamilia);
}

// Lista productos ciclico

function* listadoInventarioCiclico(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const subfamilias = [];
        const almacenes = [];
        let porcentajeUso = '';
        const offset = (action.page - 1) * 10;
        let mayor = '';
        let menor = '';

        console.log('offset', offset)

        if (action.rangoMayor) {
            mayor = '&mayor=' + parseFloat(action.rangoMayor);
        }
        if (action.rangoMenor) {
            menor = '&menor=' + parseFloat(action.rangoMenor);
        }
        if (action.porcentaje) {
            porcentajeUso = '&porcentajeUso=' + parseInt(action.porcentaje);
        }

        for(let i = 0; i < action.almacenesSeleccionados.length; i ++){
            let rp = action.almacenesSeleccionados[i];
                const almacenesData = {            
                    "idAlmacen": rp.idAlmacen,
                    "nombreC": rp.nombreC,
                    "nombreL": rp.nombreL,
                    "caracteristica": rp.caracteristica,
                    "idAreaFuncional":	rp.idAreaFuncional,
                    "fechaAlta": rp.fechaAlta,
                    "fechaActualizacion": rp.fechaActualizacion,
                    "activo": rp.activo,
                }
            almacenes.push(almacenesData);
        }

        for(let i = 0; i < action.subfamiliasSeleccionadas.length; i ++){
            let rp = action.subfamiliasSeleccionadas[i];
                const subfamiliasData = {            
                    "idSubFamilia":	rp.idSubFamilia,
                    "subFamilia": rp.subFamilia,
                    "idCentroCosto": rp.idCentroCosto,
                    "idFamilia": rp.idFamilia,
                    "fechaAlta": rp.fechaAlta,
                    "fechaActualizacion": rp.fechaActualizacion,
                    "activo": rp.activo,
                }
            subfamilias.push(subfamiliasData);
        }

        const formData = new FormData();

        console.log('almacenes', almacenes);
        console.log('subfamilias', subfamilias);
        
        formData.append('almacenes', new Blob([JSON.stringify(almacenes)], {
            type: 'application/json'
        }));

        formData.append('subfamilias', new Blob([JSON.stringify(subfamilias)], {
            type: 'application/json'
        }));

        console.log('formData', formData);

        const response = yield call(listadoInventarioCiclicoCall, token, formData, porcentajeUso, menor, mayor, offset);
        
        console.log('response', response);

        if (response.status === 200) {
            const listadoInventarioCiclico = response.data;
            let listadoInventarioCiclicoCantidad = response.data.cantidad;

            if(listadoInventarioCiclicoCantidad > 0){
                for(let i=0; i<listadoInventarioCiclico.data.length; i++){
                    listadoInventarioCiclico.data[i].ubicacion = listadoInventarioCiclico.data[i].piso + " - " + listadoInventarioCiclico.data[i].area + " - " + listadoInventarioCiclico.data[i].pasillo + " - " + listadoInventarioCiclico.data[i].rack + " - " + listadoInventarioCiclico.data[i].nivelRack + " - " + listadoInventarioCiclico.data[i].posicionRack
                }
            }
            yield put({ type: almacenesActions.LISTA_INVENTARIO_CICLICO_SUCCESS, listadoInventarioCiclico, listadoInventarioCiclicoCantidad });
        }
        else {
            yield put({ type: almacenesActions.LISTA_INVENTARIO_CICLICO_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_INVENTARIO_CICLICO_FAILURE });
    }
    }
export function* listadoInventarioCiclicoSaga() {
    yield takeLatest(almacenesActions.LISTA_INVENTARIO_CICLICO_REQUEST, listadoInventarioCiclico);
}

function* listaInventarioCiclicoDescarga(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        //const idUsuarioRevisa = parseInt(action.idUsuario);
        const subfamilias = [];
        const almacenes = [];
        let porcentajeUso = '';
        let mayor = '';
        let menor = '';

        if (!isNaN(action.rangoMayor)) {
            mayor = '&mayor=' + parseFloat(action.rangoMayor);
        }
        if (!isNaN(action.rangoMenor)) {
            if (action.porcentaje) {
                menor = '&menor=' + parseFloat(action.rangoMenor);
            } else {
                menor = 'menor=' + parseFloat(action.rangoMenor);
            }
        }
        if (action.porcentaje) {
            porcentajeUso = 'porcentajeUso=' + parseInt(action.porcentaje);
        }

        for(let i = 0; i < action.almacenesSeleccionados.length; i ++){
            let rp = action.almacenesSeleccionados[i];
                const almacenesData = {            
                    "idAlmacen": rp.idAlmacen,
                    "nombreC": rp.nombreC,
                    "nombreL": rp.nombreL,
                    "caracteristica": rp.caracteristica,
                    "idAreaFuncional":	rp.idAreaFuncional,
                    "fechaAlta": rp.fechaAlta,
                    "fechaActualizacion": rp.fechaActualizacion,
                    "activo": rp.activo,
                }
            almacenes.push(almacenesData);
        }

        for(let i = 0; i < action.subfamiliasSeleccionadas.length; i ++){
            let rp = action.subfamiliasSeleccionadas[i];
                const subfamiliasData = {            
                    "idSubFamilia":	rp.idSubFamilia,
                    "subFamilia": rp.subFamilia,
                    "idCentroCosto": rp.idCentroCosto,
                    "idFamilia": rp.idFamilia,
                    "fechaAlta": rp.fechaAlta,
                    "fechaActualizacion": rp.fechaActualizacion,
                    "activo": rp.activo,
                }
            subfamilias.push(subfamiliasData);
        }

        const formData = new FormData();

        formData.append('almacenes', new Blob([JSON.stringify(almacenes)], {
            type: 'application/json'
        }));

        formData.append('subfamilias', new Blob([JSON.stringify(subfamilias)], {
            type: 'application/json'
        }));

        let response = yield call(listaInventarioCiclicoDescargaCall, token, formData, porcentajeUso, mayor, menor);
        console.log('response', response);

        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "ListaInventarioCiclico-Archivo-" + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            
            yield put({ type: almacenesActions.LISTA_INVENTARIO_CICLICO_DESCARGA_SUCCESS });
            // yield put({ type: almacenesActions.SHOW_MODAL_INVENTARIO_CICLICO, showModalInventarioCiclico: false });
            yield put({
                type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG,
                showSuccessMsg: true,
                textMessage: 'El archivo se descargó de forma correcta'
            });

        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.LISTA_INVENTARIO_CICLICO_DESCARGA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_INVENTARIO_CICLICO_DESCARGA_FAILURE });
    }
}
export function* listaInventarioCiclicoDescargaSaga() {
    yield takeLatest(almacenesActions.LISTA_INVENTARIO_CICLICO_DESCARGA_REQUEST, listaInventarioCiclicoDescarga);
}

// Captura de inventario - validar conteo

function* validarConteo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const folioInventario = action.folioInventario;

        for(let i = 1; i <= 3; i ++){
            const response = yield call(validarConteoCall, token, folioInventario, i);
            //console.log(response);

            if (response.status == 200) {

                let validacionConteoUno = false;
                let validacionConteoDos = false;
                let validacionConteoTres = false;
            
                switch (i){
                    case 1: 
                        validacionConteoUno = response.data;
                        yield put({ type: almacenesActions.VALIDACION_CONTEO_UNO, validacionConteoUno });
                        break;
                    case 2:
                        validacionConteoDos = response.data;
                        yield put({ type: almacenesActions.VALIDACION_CONTEO_DOS, validacionConteoDos });
                        break;
                    case 3:
                        validacionConteoTres = response.data;
                        yield put({ type: almacenesActions.VALIDACION_CONTEO_TRES, validacionConteoTres });
                        break;
                    default:
                        console.log('Entra a default');
                        break;
                }

                // yield put({ type: almacenesActions.VALIDACION_CONTEO_BY_FOLIO_SUCCESS, validacionConteoUno, validacionConteoDos, validacionConteoTres });
            }
            else {
                yield put({ type: almacenesActions.VALIDACION_CONTEO_BY_FOLIO_FAILURE });
            }
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.VALIDACION_CONTEO_BY_FOLIO_FAILURE });
    }
}
export function* validarConteoSaga() {
    yield takeLatest(almacenesActions.VALIDACION_CONTEO_BY_FOLIO_REQUEST, validarConteo);
}

//Obtener almacenes por folio

function* listaAlmacenesByFolio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const folioInventario = action.folioInventario;
        const numConteo = action.numConteo;

        const response = yield call(listaAlmacenesByFolioCall, token, folioInventario, numConteo);
        
        console.log('response', response);

        if (response.status == 200) {
            const listaAlmacenesByFolio = response.data;
            let buttonReporte = true;

            if (numConteo !== 1) {
                const response2 = yield call(listaAlmacenesByFolioCall, token, folioInventario, 1);

                if(response2.status == 200) {
                    
                    if(response2.data[0]){
                        if(!listaAlmacenesByFolio[0]) {
                            buttonReporte = false;
                        }
                    } else {
                        buttonReporte = false;
                    }
                }

            } else {
                if(listaAlmacenesByFolio[0]) {
                    buttonReporte = true;
                } else {
                    buttonReporte = false;
                }
            }

            yield put({ type: almacenesActions.LISTA_ALMACENES_BY_FOLIO_SUCCESS, listaAlmacenesByFolio, buttonReporte });
        }
        else {
            yield put({ type: almacenesActions.LISTA_ALMACENES_BY_FOLIO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_ALMACENES_BY_FOLIO_FAILURE });
    }
}
export function* listaAlmacenesByFolioSaga() {
    yield takeLatest(almacenesActions.LISTA_ALMACENES_BY_FOLIO_REQUEST, listaAlmacenesByFolio);
}

// Obtener Pisos by Folio
function* listaPisosByFolio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const folioInventario = action.folioInventario;
        const numConteo = action.numConteo;  
        let { almacenes } = action;
        const dataIdAlmacen =  almacenes;

        const response = yield call(listaPisosByFolioCall, token, dataIdAlmacen, folioInventario, numConteo);
        
        console.log('response', response);

        if (response.status == 200) {
            const listaPisosByFolio = response.data;

            yield put({ type: almacenesActions.LISTA_PISOS_BY_FOLIO_SUCCESS, listaPisosByFolio });
        }
        else {
            yield put({ type: almacenesActions.LISTA_PISOS_BY_FOLIO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_PISOS_BY_FOLIO_FAILURE });
    }
}
export function* listaPisosByFolioSaga() {
    yield takeLatest(almacenesActions.LISTA_PISOS_BY_FOLIO_REQUEST, listaPisosByFolio);
}

// Obtener Areas by Folio
function* listaAreasByFolio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const folioInventario = action.folioInventario;
        const numConteo = action.numConteo;  
        let { areas } = action;
        const dataAreas =  areas;

        const response = yield call(listaAreasByFolioCall, token, dataAreas, folioInventario, numConteo);       
        console.log('response', response);

        if (response.status == 200) {
            const listaAreasByFolio = response.data;

            yield put({ type: almacenesActions.LISTA_AREAS_BY_FOLIO_SUCCESS, listaAreasByFolio });
        }
        else {
            yield put({ type: almacenesActions.LISTA_AREAS_BY_FOLIO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_AREAS_BY_FOLIO_FAILURE });
    }
}
export function* listaAreasByFolioSaga() {
    yield takeLatest(almacenesActions.LISTA_AREAS_BY_FOLIO_REQUEST, listaAreasByFolio);
}

// Obtener Pasillos by Folio
function* listaPasillosByFolio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const folioInventario = action.folioInventario;
        const numConteo = action.numConteo;  
        let { pasillos } = action;
        const dataPasillos = pasillos;

        const response = yield call(listaPasillosByFolioCall, token, dataPasillos, folioInventario, numConteo);       
        console.log('response', response);

        if (response.status == 200) {
            const listaPasillosByFolio = response.data;

            yield put({ type: almacenesActions.LISTA_PASILLOS_BY_FOLIO_SUCCESS, listaPasillosByFolio });
        }
        else {
            yield put({ type: almacenesActions.LISTA_PASILLOS_BY_FOLIO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_PASILLOS_BY_FOLIO_FAILURE });
    }
}
export function* listaPasillosByFolioSaga() {
    yield takeLatest(almacenesActions.LISTA_PASILLOS_BY_FOLIO_REQUEST, listaPasillosByFolio);
}

// Obtener Racks by Folio
function* listaRacksByFolio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const folioInventario = action.folioInventario;
        const numConteo = action.numConteo;  
        let { racks } = action;
        const dataRacks = racks;

        const response = yield call(listaRacksByFolioCall, token, dataRacks, folioInventario, numConteo);       
        console.log('response', response);

        if (response.status == 200) {
            const listaRacksByFolio = response.data;

            yield put({ type: almacenesActions.LISTA_RACKS_BY_FOLIO_SUCCESS, listaRacksByFolio });
        }
        else {
            yield put({ type: almacenesActions.LISTA_RACKS_BY_FOLIO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_RACKS_BY_FOLIO_FAILURE });
    }
}
export function* listaRacksByFolioSaga() {
    yield takeLatest(almacenesActions.LISTA_RACKS_BY_FOLIO_REQUEST, listaRacksByFolio);
}

// Obtener Productos by Folio
function* listaProductosByFolio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const folioInventario = action.folioInventario;
        const numConteo = action.numConteo;  
        let { almacenes, pisos, areas, pasillos, racks } = action;
        const dataFisico = 
            {
                "idAlmacen": almacenes, 
                "piso": pisos,
                "area": areas,
                "pasillo": pasillos,
                "rack": racks 
            };

        const data = new FormData();

        data.append('data', new Blob([JSON.stringify(dataFisico)], {
            type: 'application/json'
        }));

        const response = yield call(listaProductosByFolioCall, token, dataFisico, folioInventario, numConteo);       
        console.log('response', response);

        if (response.status == 200) {
            const listaProductosByFolio = response.data;
            const listaProductosByFolioCantidad = response.data.cantidad;

            for (let i = 0; i < listaProductosByFolio.data.length; i++) {
                if (listaProductosByFolio.data[i].productoByIdProducto) {

                    listaProductosByFolio.data[i].sku = listaProductosByFolio.data[i].productoByIdProducto.sku;

                    listaProductosByFolio.data[i].clave = listaProductosByFolio.data[i].productoByIdProducto.clave;

                    listaProductosByFolio.data[i].nombre = listaProductosByFolio.data[i].productoByIdProducto.nombre;

                    if(listaProductosByFolio.data[i].productoByIdProducto.unidadMedidaByIdUnidadMedida){
                        listaProductosByFolio.data[i].unidadMedida = listaProductosByFolio.data[i].productoByIdProducto.unidadMedidaByIdUnidadMedida.unidadMedida;
                    }
                    
                    if (listaProductosByFolio.data[i].ubicacionProductoByIdUbicacionProducto) {
                        listaProductosByFolio.data[i].ubicacion = listaProductosByFolio.data[i].ubicacionProductoByIdUbicacionProducto.piso + " - " + listaProductosByFolio.data[i].ubicacionProductoByIdUbicacionProducto.area + " - " + listaProductosByFolio.data[i].ubicacionProductoByIdUbicacionProducto.pasillo + " - " + listaProductosByFolio.data[i].ubicacionProductoByIdUbicacionProducto.rack + " - " + listaProductosByFolio.data[i].ubicacionProductoByIdUbicacionProducto.nivelRack + " - " + listaProductosByFolio.data[i].ubicacionProductoByIdUbicacionProducto.posicionRack
                    }
                }
                if (numConteo === 1) {
                    listaProductosByFolio.data[i].conteo = listaProductosByFolio.data[i].conteo1
                    if (listaProductosByFolio.data[i].conteo1 === -1){
                        listaProductosByFolio.data[i].conteo = 0;
                    } 
                } else if (numConteo === 2) {
                    listaProductosByFolio.data[i].conteo = listaProductosByFolio.data[i].conteo2
                    if (listaProductosByFolio.data[i].conteo2 === -1){
                        listaProductosByFolio.data[i].conteo = 0;
                    } 
                } else if (numConteo === 3) {
                    listaProductosByFolio.data[i].conteo = listaProductosByFolio.data[i].conteo3
                    if (listaProductosByFolio.data[i].conteo3 === -1){
                        listaProductosByFolio.data[i].conteo = 0;
                    } 
                }
            }

            yield put({ type: almacenesActions.LISTA_PRODUCTOS_BY_FOLIO_SUCCESS, listaProductosByFolio, listaProductosByFolioCantidad });
        }
        else {
            yield put({ type: almacenesActions.LISTA_PRODUCTOS_BY_FOLIO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_PRODUCTOS_BY_FOLIO_FAILURE });
    }
}
export function* listaProductosByFolioSaga() {
    yield takeLatest(almacenesActions.LISTA_PRODUCTOS_BY_FOLIO_REQUEST, listaProductosByFolio);
}

// Capturar Conteo de Inventario
function* capturarConteo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const numConteo = action.numConteo;  
        let { listaProductosByFolio } = action;
        const data = listaProductosByFolio;

        const response = yield call(capturarConteoCall, token, data, numConteo);       
        console.log('response', response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "La captura del inventario fue guardada con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.CAPTURAR_CONTEO_SUCCESS });
            yield put({ type: almacenesActions.SHOW_MODAL_CAPTURA_INVENTARIO, showModalCapturaInventario: false });
            yield put({ type: almacenesActions.SHOW_MODAL_CAPTURA_INVENTARIO, showModalCapturaInventario: false, numeroConteo: null });
            yield put({ type: almacenesActions.LISTA_ALMACENES_BY_FOLIO_SUCCESS, listaAlmacenesByFolio: [] });
        }
        else {
            yield put({ type: almacenesActions.CAPTURAR_CONTEO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.CAPTURAR_CONTEO_FAILURE });
    }
}
export function* capturarConteoSaga() {
    yield takeLatest(almacenesActions.CAPTURAR_CONTEO_REQUEST, capturarConteo);
}

// Generar Folio Captura 
function* generarFolioCaptura(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const subfamilias = [];
        const almacenes = [];
        const tipoInventario = action.tipoSeleccionado;
        let menor = '';
        let mayor = '';
        let porcentajeUso = '';
        let almacenesFisicos = action.almacenes;
        let { pisos, areas, pasillos, racks } = action;
        const data = new FormData();

        if(action.rangoMayor){
            if (!isNaN(action.rangoMayor)) {
                mayor = '&mayor=' + parseFloat(action.rangoMayor);
            }
        }
        if(action.rangoMenor){
            if (!isNaN(action.rangoMenor)) {
                menor = '&menor=' + parseFloat(action.rangoMenor);
            }
        }
        if (action.porcentaje) {
            porcentajeUso = '&porcentajeUso=' + parseInt(action.porcentaje);
        }

        console.log('menor', menor);

        if(tipoInventario === "Cíclico"){
            for(let i = 0; i < action.almacenesSeleccionados.length; i ++){
                let rp = action.almacenesSeleccionados[i];
                    const almacenesData = {            
                        "idAlmacen": rp.idAlmacen,
                        "nombreC": rp.nombreC,
                        "nombreL": rp.nombreL,
                        "caracteristica": rp.caracteristica,
                        "idAreaFuncional":	rp.idAreaFuncional,
                        "fechaAlta": rp.fechaAlta,
                        "fechaActualizacion": rp.fechaActualizacion,
                        "activo": rp.activo,
                    }
                almacenes.push(almacenesData);
            }

            for(let i = 0; i < action.subfamiliasSeleccionadas.length; i ++){
                let rp = action.subfamiliasSeleccionadas[i];
                    const subfamiliasData = {            
                        "idSubFamilia":	rp.idSubFamilia,
                        "subFamilia": rp.subFamilia,
                        "idCentroCosto": rp.idCentroCosto,
                        "idFamilia": rp.idFamilia,
                        "fechaAlta": rp.fechaAlta,
                        "fechaActualizacion": rp.fechaActualizacion,
                        "activo": rp.activo,
                    }
                subfamilias.push(subfamiliasData);
            }

            console.log('almacenes', almacenes);
            console.log('subfamilias', subfamilias);
            
            data.append('almacenes', new Blob([JSON.stringify(almacenes)], {
                type: 'application/json'
            }));

            data.append('subfamilias', new Blob([JSON.stringify(subfamilias)], {
                type: 'application/json'
            }));
        } else {
            const dataFisico = {
                "idAlmacen": almacenesFisicos,
                "piso": pisos,
                "area": areas,
                "pasillo": pasillos,
                "rack": racks
            }

            console.log('dataFisico', dataFisico);

            data.append('listInvFisGenRequest', new Blob([JSON.stringify(dataFisico)], {
                type: 'application/json'
            }));

        }

        console.log('data',data)

        const response = yield call(generarFolioCapturaCall, token, data, menor, mayor, porcentajeUso);
        
        console.log('response', response);

        if (response.status === 200) {
            const folioInventarioGenerado = response.data;
            const showModalFolioCreado = true;

            yield put({ type: almacenesActions.GENERAR_FOLIO_CAPTURA_SUCCESS, folioInventarioGenerado });

            if(response.data.message !== 'No existen productos para conteo con las especificaciones señaladas, no se ha generado ningún folio'){
                yield put({ type: almacenesActions.SHOW_MODAL_FOLIO_CREADO, showModalFolioCreado });
            } else {
                yield put({ type: almacenesActions.SHOW_MODAL_NO_FOLIO, showModalNoFolio: true });
            }
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.GENERAR_FOLIO_CAPTURA_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.GENERAR_FOLIO_CAPTURA_FAILURE });
    }
    }
export function* generarFolioCapturaSaga() {
    yield takeLatest(almacenesActions.GENERAR_FOLIO_CAPTURA_REQUEST, generarFolioCaptura);
}

// Filtro para búsqueda en Select Filtro

export function* listaFiltroFolio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const busqueda = action.busqueda;
      
        const response = yield call(listaFiltroFolioCall, token, busqueda);

        console.log(response);

        if (response.status === 200) {
            let listaFiltroFolio = response.data;
            
            yield put({ type: almacenesActions.FOLIOS_FILTRADOS_SUCCESS, listaFiltroFolio});
        } else {
            yield put ({ type: almacenesActions.FOLIOS_FILTRADOS_FAILURE});
        }
    } catch (error) {
        yield put ({ type: almacenesActions.FOLIOS_FILTRADOS_FAILURE});
    }
}
export function* listaFiltroFolioSaga() {
    yield takeLatest(almacenesActions.FOLIOS_FILTRADOS_REQUEST, listaFiltroFolio);
}

//Listado para toma de Inventarío Físico
function* listaPisosByAlmacenActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idsAlmacenes = action.idsAlmacenes.join();

        const response = yield call(listaPisosByAlmacenCall, token, idsAlmacenes);
        console.log(response);

        if (response.status == 200) {
            const listaPisosByAlmacen = response.data;

            yield put({ type: almacenesActions.LISTA_PISOS_BY_ALMACEN_SUCCESS, listaPisosByAlmacen });
        }
        else {
            yield put({ type: almacenesActions.LISTA_PISOS_BY_ALMACEN_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_PISOS_BY_ALMACEN_FAILURE });
    }
}
export function* listaPisosByAlmacenActivosSaga() {
    yield takeLatest(almacenesActions.LISTA_PISOS_BY_ALMACEN_REQUEST, listaPisosByAlmacenActivos);
}

function* listaAreasByPisosActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const pisos = action.pisos.join();

        const response = yield call(listaAreasByPisosCall, token, pisos);
        console.log(response);

        if (response.status == 200) {
            const listaAreasByPisos = response.data;

            yield put({ type: almacenesActions.LISTA_AREAS_BY_PISOS_SUCCESS, listaAreasByPisos });
        }
        else {
            yield put({ type: almacenesActions.LISTA_AREAS_BY_PISOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_AREAS_BY_PISOS_FAILURE });
    }
}
export function* listaAreasByPisosActivosSaga() {
    yield takeLatest(almacenesActions.LISTA_AREAS_BY_PISOS_REQUEST, listaAreasByPisosActivos);
}

function* listaPasillosByAreasActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const areas = action.areas.join();

        const response = yield call(listaPasillosByAreasCall, token, areas);
        console.log(response);

        if (response.status == 200) {
            const listaPasillosByAreas = response.data;

            yield put({ type: almacenesActions.LISTA_PASILLOS_BY_AREAS_SUCCESS, listaPasillosByAreas });
        }
        else {
            yield put({ type: almacenesActions.LISTA_PASILLOS_BY_AREAS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_PASILLOS_BY_AREAS_FAILURE });
    }
}
export function* listaPasillosByAreasActivosSaga() {
    yield takeLatest(almacenesActions.LISTA_PASILLOS_BY_AREAS_REQUEST, listaPasillosByAreasActivos);
}

function* listaRacksByPasillosActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const pasillos = action.pasillos.join();

        const response = yield call(listaRacksByPasillos, token, pasillos);
        console.log(response);

        if (response.status == 200) {
            const listaRacksByPasillos = response.data;

            yield put({ type: almacenesActions.LISTA_RACKS_BY_PASILLOS_SUCCESS, listaRacksByPasillos });
        }
        else {
            yield put({ type: almacenesActions.LISTA_RACKS_BY_PASILLOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_RACKS_BY_PASILLOS_FAILURE });
    }
}
export function* listaRacksByPasillosActivosSaga() {
    yield takeLatest(almacenesActions.LISTA_RACKS_BY_PASILLOS_REQUEST, listaRacksByPasillosActivos);
}

function* listaInventarioFisicoBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page -1;
        let {almacenes,pisos,areas, pasillos, racks} = action

        const data = {
            "idAlmacen": almacenes,
	        "piso":pisos,
            "area":areas,
            "pasillo":pasillos,
            "rack":racks
        }

        console.log(page);
        console.log(data, page);
        const response = yield call(listaInventarioFisicoBusquedaCall, token, data, page);
        console.log(response);
        console.log(response.data.data)

        if (response.status == 200) {
            let listaInvetarioFisicoBusqueda = response.data.data;
            let listaInvetarioFisicoBusquedaCantidad = response.data.cantidad;

            listaInvetarioFisicoBusqueda.forEach(item => {
                const {sku="", clave="", nombre=""} = item.productosByIdProducto;
                const {unidadMedida=""} = item.productosByIdProducto.unidadMedidaByIdUnidadMedida;
                item.sku = sku;
                item.clave = clave;
                item.nombre = nombre;
                item.unidadMedida = unidadMedida;
            })
                       
            yield put({ type: almacenesActions.LISTA_INVENTARIO_FISICO_GENERAL_SUCCESS, listaInvetarioFisicoBusqueda, listaInvetarioFisicoBusquedaCantidad });
        }
        else {
            yield put({ type: almacenesActions.LISTA_INVENTARIO_FISICO_GENERAL_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_INVENTARIO_FISICO_GENERAL_FAILURE });
    }
}
export function* listaInventarioFisicoBusquedaSaga() {
    yield takeLatest(almacenesActions.LISTA_INVENTARIO_FISICO_GENERAL_REQUEST, listaInventarioFisicoBusqueda);
}

function* listaInvetarioFisicoDescargaArchivo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let userInfo = sessionStorage.getItem('userInfo');
        const idUsuario  = !userInfo.idUsuario ? JSON.parse(userInfo)[0].idUsuario : userInfo.idUsuario;
        let {almacenes,pisos,areas, pasillos, racks} = action

        const data = {
            "idAlmacen": almacenes,
	        "piso":pisos,
            "area":areas,
            "pasillo":pasillos,
            "rack":racks
        }

        console.log(data);
        let response = yield call(listaInventarioFisicoDescargaCall, token, data, idUsuario);
        console.log(response);

        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "ListaInventarioFisicoGeneral-Archivo-" + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            
            yield put({ type: almacenesActions.LISTA_INVENTARIO_FISICO_DESCARGA_SUCCESS });

        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.LISTA_INVENTARIO_FISICO_DESCARGA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_INVENTARIO_FISICO_DESCARGA_FAILURE });
    }
}
export function* listaInvetarioFisicoDescargaArchivoSaga() {
    yield takeLatest(almacenesActions.LISTA_INVENTARIO_FISICO_DESCARGA_REQUEST, listaInvetarioFisicoDescargaArchivo);
}

//Reporte Faltantes y Ajuste Inventario Físico General
function* listaFaltantesInventarioBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page -1;
        let {folio, conteo} = action.formulario;
        folio.trim();

        console.log(folio, conteo, page);
        const response = yield call(listaFaltantesInventarioCall, token, folio, conteo, page);
        console.log(response);

        if (response.status == 200) {
            console.log(response.data.data)
            let listaFaltantesInvetarioFisico = response.data.data;
            let listaFaltantesInvetarioFisicoCantidad = response.data.cantidad;

            listaFaltantesInvetarioFisico.forEach(item => {
                const {sku="", clave="", nombre=""} = item.productoByIdProducto;
                const {unidadMedida=""} = item.productoByIdProducto.unidadMedidaByIdUnidadMedida;
                const {cantidadInventario="", conteo1="", conteo2="", conteo3=""} = item;
                const {piso="",area="", pasillo="", rack="", nivelRack="",posicionRack="" } = item.ubicacionProductoByIdUbicacionProducto;
                const conteos = {1:conteo1, 2:conteo2, 3:conteo3};
                item.sku = sku;
                item.clave = clave;
                item.nombre = nombre;
                item.unidadMedida = unidadMedida;
                item.cantidadInventario = cantidadInventario;
                item.cantidadFisico = conteos[conteo];
                item.ubicacion= `${piso}-${area}-${pasillo}-${rack}-${nivelRack}-${posicionRack}`;
            })
                       
            yield put({ type: almacenesActions.LISTA_FALTANTES_INVENTARIO_FISICO_SUCCESS, listaFaltantesInvetarioFisico, listaFaltantesInvetarioFisicoCantidad });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.LISTA_FALTANTES_INVENTARIO_FISICO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_FALTANTES_INVENTARIO_FISICO_FAILURE });
    }
}
export function* listaFaltantesInventarioBusquedaSaga() {
    yield takeLatest(almacenesActions.LISTA_FALTANTES_INVENTARIO_FISICO_REQUEST, listaFaltantesInventarioBusqueda);
}

function* listaFaltantesInventarioDescarga(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let {folio, conteo} = action.formulario;
        folio.trim();

        console.log(folio, conteo);
        let response = yield call(listaFaltantesInventarioDescargaCall, token, folio, conteo);
        console.log(response);

        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "ReporteFaltantes-Archivo-" + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            
            yield put({ type: almacenesActions.LISTA_FALTANTES_INVENTARIO_FISICO_DESCARGA_SUCCESS });

        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.LISTA_FALTANTES_INVENTARIO_FISICO_DESCARGA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.LISTA_FALTANTES_INVENTARIO_FISICO_DESCARGA_FAILURE });
    }
}
export function* listaFaltantesInventarioDescargaSaga() {
    yield takeLatest(almacenesActions.LISTA_FALTANTES_INVENTARIO_FISICO_DESCARGA_REQUEST, listaFaltantesInventarioDescarga);
}

function* cerrarFolioInventario(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let folio = action.formulario.folio.trim();

        console.log(folio);
        let response = yield call(CerrarFolioInventarioCall, token, folio);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = `El número de folio '${folio} se ha cerrado con éxito.`;

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.CERRAR_FOLIO_AJUSTE_INVENTARIO_SUCCESS });
            yield put({ type: almacenesActions.CAMBIAR_CATALOGO_ALMACENES, catalogoAlmacenes: "Captura de Toma de Inventarios Físicos"});
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.CERRAR_FOLIO_AJUSTE_INVENTARIO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.CERRAR_FOLIO_AJUSTE_INVENTARIO_FAILURE });
    }
}
export function* cerrarFolioInventarioSaga() {
    yield takeLatest(almacenesActions.CERRAR_FOLIO_AJUSTE_INVENTARIO_REQUEST, cerrarFolioInventario);
}


//Kardex - Producto existente
function* productoExistente(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let {skuClave} = action;

        let response = yield call(productoExistenteCall, token, skuClave);
        console.log(response);

        if (response.status == 200) {

            yield put({ type: almacenesActions.KARDEX_PRODUCTOS_EXISTENETES_SUCCESS, kardexEncabezado: true, kardexCardsMovimientos: true });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.KARDEX_PRODUCTOS_EXISTENETES_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.KARDEX_PRODUCTOS_EXISTENETES_FAILURE });
    }
}
export function* productoExistenteSaga() {
    yield takeLatest(almacenesActions.KARDEX_PRODUCTOS_EXISTENETES_REQUEST, productoExistente);
}

//Kardex - Producto existente
function* almacenesByProducto(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let {skuClave} = action;
        
        let response = yield call(almacenesByProductoCall, token, skuClave);
        console.log(response);

        if (response.status == 200) {
            const listaAlmacenesByProducto = response.data;

            yield put({ type: almacenesActions.ALMACENES_BY_PRODUCTO_SUCCESS, listaAlmacenesByProducto });
        }
        else {
            
            yield put({ type: almacenesActions.ALMACENES_BY_PRODUCTO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.ALMACENES_BY_PRODUCTO_FAILURE });
    }
}
export function* almacenesByProductoSaga() {
    yield takeLatest(almacenesActions.ALMACENES_BY_PRODUCTO_REQUEST, almacenesByProducto);
}

// Kardex - Obtener kardex producto
function* obtenerKardexProducto(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let { idAlmacen, claveSku, operacion, fechaInicio, fechaFin } = action;
        
        let response = yield call(obtenerKardexProductoCall, token, idAlmacen, claveSku, operacion, fechaInicio, fechaFin);
        console.log(response);

        if (response.status == 200) {
            const listaKardexProductosObtenidos = response.data;

            yield put({ type: almacenesActions.KARDEX_OBTENER_PRODUCTOS_SUCCESS, listaKardexProductosObtenidos });
        }
        else {
            
            yield put({ type: almacenesActions.KARDEX_OBTENER_PRODUCTOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.KARDEX_OBTENER_PRODUCTOS_FAILURE });
    }
}
export function* obtenerKardexProductoSaga() {
    yield takeLatest(almacenesActions.KARDEX_OBTENER_PRODUCTOS_REQUEST, obtenerKardexProducto);
}

// Kardex - Obtener kardex movimientos
function* obtenerKardexMovimientos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let { idAlmacen, claveSku, operacion, fechaInicio, fechaFin, todosAlmacenes } = action;
        let response = yield;


        if(!todosAlmacenes[0]){
            response = yield call(obtenerKardexMovimientosCall, token, idAlmacen, claveSku, operacion, fechaInicio, fechaFin);
            console.log(response);
        } else {
            for(let i = 0; i < todosAlmacenes.length; i++){
                console.log('todosAlmacenes[i]', todosAlmacenes[i])
                response = yield call(obtenerKardexMovimientosCall, token, todosAlmacenes[i], claveSku, operacion, fechaInicio, fechaFin);
            }
        }

        console.log('response', response)
        

        if (response.status == 200) {
            const listaKardexMovimientos = response.data;
            let dataMovimientosSumas = [];
            let dataMovimientosRestas = [];
            let cS = 0;
            let cR = 0;
            let buttonExport = true;
            let cantidadMovimientoSuma = null;
            let cantidadMovimientoResta = null;

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            for(let i in listaKardexMovimientos.data){
                if(listaKardexMovimientos.data[i].costoPromedio === 0){
                    listaKardexMovimientos.data[i].costoPromedioTabla = 'N/A';
                } else {
                    // listaKardexMovimientos.data[i].costoPromedioTabla = "$ " + parseFloat(listaKardexMovimientos.data[i].costoPromedio).toFixed(2);
                    listaKardexMovimientos.data[i].costoPromedioTabla = "$ " + numberWithCommas(Number(listaKardexMovimientos.data[i].costoPromedio).toFixed(2));
                }
                let fecha = listaKardexMovimientos.data[i].fecha.split('T')[0];
                let hora = (listaKardexMovimientos.data[i].fecha.split('T')[1]).slice(0,5);
                listaKardexMovimientos.data[i].fechaHora = fecha.split('-')[2] + '-' + fecha.split('-')[1] + '-' + fecha.split('-')[0] + " " + hora;
                
                // listaKardexMovimientos.data[i].costoUnitarioTabla = "$ " + numberWithCommas(parseFloat(listaKardexMovimientos.data[i].costoUnitario)).toFixed(2);

                listaKardexMovimientos.data[i].costoUnitarioTabla = "$ " + numberWithCommas(Number(listaKardexMovimientos.data[i].costoUnitario).toFixed(2));

                if(listaKardexMovimientos.data[i].tipoMovimiento === 'Salida'){
                    listaKardexMovimientos.data[i].operacion = 'Resta';
                    dataMovimientosRestas[cR] = listaKardexMovimientos.data[i];
                    cantidadMovimientoResta = cantidadMovimientoResta + listaKardexMovimientos.data[i].cantidad;
                    cR = cR + 1;
                } else {
                    listaKardexMovimientos.data[i].operacion = 'Suma';
                    dataMovimientosSumas[cS] = listaKardexMovimientos.data[i];
                    cantidadMovimientoSuma = cantidadMovimientoSuma + listaKardexMovimientos.data[i].cantidad;
                    cS = cS + 1;
                }

                if(listaKardexMovimientos.data[i].aMaterno === null){
                    listaKardexMovimientos.data[i].usuario = listaKardexMovimientos.data[i].nombre + ' ' + listaKardexMovimientos.data[i].aPaterno;
                } else {
                    listaKardexMovimientos.data[i].usuario = listaKardexMovimientos.data[i].nombre + ' ' + listaKardexMovimientos.data[i].aPaterno + ' ' + listaKardexMovimientos.data[i].aMaterno;
                }
            }

            console.log('cantidadMovimientoSuma', cantidadMovimientoSuma)
            console.log('cantidadMovimientoResta', cantidadMovimientoResta)

            if(response.data.data[0]){
                buttonExport = false;
            }

            yield put({ type: almacenesActions.KARDEX_OBTENER_MOVIMIENTOS_SUCCESS, listaKardexMovimientos, dataMovimientosSumas, dataMovimientosRestas, buttonExport, cantidadMovimientoSuma, cantidadMovimientoResta });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });  
            yield put({ type: almacenesActions.KARDEX_OBTENER_MOVIMIENTOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.KARDEX_OBTENER_MOVIMIENTOS_FAILURE });
    }
}
export function* obtenerKardexMovimientosSaga() {
    yield takeLatest(almacenesActions.KARDEX_OBTENER_MOVIMIENTOS_REQUEST, obtenerKardexMovimientos);
}

function* kardexReporte(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let {kardexProducto, kardexMovimientos} = action

        const data = new FormData();

        data.append('kardexProducto', new Blob([JSON.stringify(kardexProducto)], {
            type: 'application/json'
        }));

        data.append('kardexMovimientos', new Blob([JSON.stringify(kardexMovimientos)], {
            type: 'application/json'
        }));

        let response = yield call(kardexReporteCall, token, data,);

        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Kardex-Reporte-Archivo-" + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            
            yield put({ type: almacenesActions.KARDEX_REPORTE_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = "No existen movimientos que exportar";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.KARDEX_REPORTE_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.KARDEX_REPORTE_FAILURE });
    }
}
export function* kardexReporteSaga() {
    yield takeLatest(almacenesActions.KARDEX_REPORTE_REQUEST, kardexReporte);
}

//SAGA VERIFICACION DE CALIDAD ALMACEN
function* busquedaFoliosVerCalidad(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = (action.page - 1)*10;
        let param;
        let order;
        let field;
        let fechaInicio;
        let fechaFinal;

        action.busqueda.param ? param = action.busqueda.param : param = ''; 
        action.busqueda.order ? order = action.busqueda.order : order = 'DESC'; 
        action.busqueda.field ? field = action.busqueda.field : field = 'folio'; 
        action.busqueda.fechaInicio ? fechaInicio = action.busqueda.fechaInicio : fechaInicio = ''
        action.busqueda.fechaFinal ? fechaFinal = action.busqueda.fechaFinal : fechaFinal = ''
         
        
         
        const response = yield call(getFoliosVerCalidadCall, token, param,fechaInicio, fechaFinal, order, field , page );
        console.log('response busquedaFoliosVerCalidad', response)

        if(response.status === 200){
            let listaFVerificacionCalidad = response.data.data;
            let listaFVerificacionCalidadCantidad = response.data.cantidad;

            for(let i in listaFVerificacionCalidad){
                listaFVerificacionCalidad[i].fechaAlta = listaFVerificacionCalidad[i].fechaAlta.split('T')[0].split('-').reverse().join('-');
                listaFVerificacionCalidad[i].fechaIngreso = listaFVerificacionCalidad[i].fechaIngreso.split('T')[0].split('-').reverse().join('-');
            }

            yield put({ type: almacenesActions.LISTA_FOLIOS_VERIFICACION_CALIDAD_SUCCESS,  listaFVerificacionCalidad, listaFVerificacionCalidadCantidad});
        } else {
            yield put({ type: almacenesActions.LISTA_FOLIOS_VERIFICACION_CALIDAD_FAILURE });
        }
    } catch(error){
        yield put({ type: almacenesActions.LISTA_FOLIOS_VERIFICACION_CALIDAD_FAILURE });
    }
}

export function* busquedaFoliosVerCalidadSaga() {
    yield takeLatest(almacenesActions.LISTA_FOLIOS_VERIFICACION_CALIDAD_REQUEST, busquedaFoliosVerCalidad);
}

// crear folio verificacion calidad
function* crearFolioVerificacion(action){
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        let producto;

        let dataList = [];
        action.listaSeleccionados.forEach( p => {
            console.log('p', p)
            let lote = null;
            let fechaCaducidad = null;
            
            if(p.lote !== undefined) {   
                lote = p.lote
            }  
            if(p.fechaCaducidad !== undefined) {
                fechaCaducidad = p.fechaCaducidad.format('YYYY-MM-DD') + "T00:00:00"
            } 

            if(p.subFamiliaByIdSubFamilia.familiaByIdFamilia.tipoFamilia === 'Activos'){
                let listaSerieNum = [];
                for(let x=1; x<=p.cantidad; x++){
                    if(p['serieNum-' + x]){
                        listaSerieNum.push(p['serieNum-' + x])
                    }
                }
                for(let x in listaSerieNum){
                    const producto = {
                        "idIngresoAlmacenCalidad": null,
                        "idServicio": parseInt(action.formulario.idServicio),
                        "idProducto": p.idProducto,
                        "idUnidadMedida": p.idUnidadMedida,
                        "folio": null,
                        "estado": null,
                        "motivoRechazo": null,
                        "cantidad": 1,
                        "cantidadRechazada": 0,
                        'serieNum': listaSerieNum[x],
                        "rutaArchivo": null,
                        "lote": lote ,
                        "idUbicacionProductoDestino": p.ubicacion,
                        "costoOriginal": p.precio,
                        "idTipoMoneda": p.tipoMonedaEditar.split(' - ')[0],
                        "tipoCambio": p.tipoCambio !== 'N/A' ? p.tipoCambio.split(' MXN')[0] : 1,
                        "fechaCaducidad": fechaCaducidad,
                        "fechaIngreso": action.formulario.fechaIngreso.format('YYYY-MM-DD') + "T00:00:00",
                        "fechaAlta": null,
                        "fechaActualizacion": null,
                        "activo": null
                    }
                    dataList.push(producto)
                }
            }else{
                producto = {
                    "idIngresoAlmacenCalidad": null,
                    "idServicio": parseInt(action.formulario.idServicio),
                    "idProducto": p.idProducto,
                    "idUnidadMedida": p.idUnidadMedida,
                    "folio": null,
                    "estado": null,
                    "motivoRechazo": null,
                    "cantidad": parseInt(p.cantidad),
                    "cantidadRechazada": 0,
                    'serieNum': null,
                    "rutaArchivo": null,
                    "lote": lote ,
                    "idUbicacionProductoDestino": p.ubicacion,
                    "costoOriginal": p.precio,
                    "idTipoMoneda": p.tipoMonedaEditar.split(' - ')[0],
                    "tipoCambio": p.tipoCambio !== 'N/A' ? p.tipoCambio.split(' MXN')[0] : 1,
                    "fechaCaducidad": fechaCaducidad,
                    "fechaIngreso": action.formulario.fechaIngreso.format('YYYY-MM-DD') + "T00:00:00",
                    "fechaAlta": null,
                    "fechaActualizacion": null,
                    "activo": null
                }
                dataList.push(producto)
            }
        })

        console.log('dataList', dataList);

        const formData = new FormData();
        if (action.formulario.pdf) {
            formData.append('file', action.formulario.pdf.file.originFileObj);
        }
        formData.append('dataList', new Blob([JSON.stringify(dataList)], {
            type: "application/json"
        }));
        console.log('formData', formData);
        

        const response = yield call(crearFolioVerificacionCalCall, token, formData);
        console.log('response crearFolioVerificacion', response);

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "El folio de Entrada se generó de manera correcta.";
            const page = action.page ? action.page : 1;
            const busqueda = action.busqueda ? action.busqueda : { param: '', order: 'DESC', field: 'fechaAlta', fechaInicio: '', fechaFinal: '', idUsuario: undefined, };

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.CREAR_FOLIO_VERIFICACION_CALIDAD_SUCCESS });
            yield put({ type: almacenesActions.SHOW_MODAL_NUEVOFOLIO_VERIFICACION_CAL , showModalNuevoFolioVC: false });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "crearEVDC" });
            yield put({ type: almacenesActions.LISTA_FOLIOS_VERIFICACION_CALIDAD_REQUEST, page, busqueda })

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.CREAR_FOLIO_VERIFICACION_CALIDAD_FAILURE })
        }


    } catch(error){
        yield put({ type: almacenesActions.CREAR_FOLIO_VERIFICACION_CALIDAD_FAILURE });
    }
}

export function* crearFolioVerificacionSaga() {
    yield takeLatest(almacenesActions.CREAR_FOLIO_VERIFICACION_CALIDAD_REQUEST, crearFolioVerificacion);
}

// detalle folio VC
function* detalleFoliosVerCalidad(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        const folio = action.folio;
        const page = action.page - 1;
        const response = yield call(detalleFolioVCCall, token, folio, page );
        console.log('response detalleFoliosVerCalidad', response)

        if(response.status === 200){
            let listaFolioVC = response.data.data;
            let listaFolioVCCalidad = response.data.cantidad;

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
           }

            for(let i in listaFolioVC){
                if(listaFolioVC[i].productosByIdProducto){
                    listaFolioVC[i].producto = listaFolioVC[i].productosByIdProducto.nombre
                    listaFolioVC[i].clave = listaFolioVC[i].productosByIdProducto.clave;
                    listaFolioVC[i].codigoAdicional = listaFolioVC[i].productosByIdProducto.codigoAdicional;
                    //listaFolioVC[i].precio = listaFolioVC[i].productosByIdProducto.precio.toFixed(2)
                }

                if(listaFolioVC[i].unidadesMedidasByIdUnidadMedida){
                    listaFolioVC[i].unidadMedida = listaFolioVC[i].unidadesMedidasByIdUnidadMedida.unidadMedida
                }

                if(listaFolioVC[i].ubicacionesProductosByIdUbicacionProducto){
                    listaFolioVC[i].ubicacion = ` Area: ${listaFolioVC[i].ubicacionesProductosByIdUbicacionProducto.area} - Piso: ${listaFolioVC[i].ubicacionesProductosByIdUbicacionProducto.piso} -
                                                  Pasillo: ${listaFolioVC[i].ubicacionesProductosByIdUbicacionProducto.pasillo} - Rack: ${listaFolioVC[i].ubicacionesProductosByIdUbicacionProducto.rack} -
                                                  Nivel Rack: ${listaFolioVC[i].ubicacionesProductosByIdUbicacionProducto.nivelRack} - Posición Rack: ${listaFolioVC[i].ubicacionesProductosByIdUbicacionProducto.posicionRack}`
                    listaFolioVC[i].idUbicacion = listaFolioVC[i].ubicacionesProductosByIdUbicacionProducto.idUbicacionProducto                               
                }

                if(listaFolioVC[i].tipoMonedaByIdTipoMoneda){
                    listaFolioVC[i].tipoMoneda = listaFolioVC[i].tipoMonedaByIdTipoMoneda.nombre + ' (' + listaFolioVC[i].tipoMonedaByIdTipoMoneda.nomenclatura + ')';
                }
                listaFolioVC[i].precioCostoTabla = numberWithCommas(Number(listaFolioVC[i].precioCosto).toFixed(2));
                listaFolioVC[i].costoOriginalTabla = numberWithCommas(Number(listaFolioVC[i].costoOriginal).toFixed(2));

            }

            
            yield put({ type: almacenesActions.DETALLE_FOLIO_VERIFICACION_CALIDAD_SUCCESS,  listaFolioVC, listaFolioVCCalidad});
        } else {
            yield put({ type: almacenesActions.DETALLE_FOLIO_VERIFICACION_CALIDAD_FAILURE });
        }
    } catch(error){
        yield put({ type: almacenesActions.DETALLE_FOLIO_VERIFICACION_CALIDAD_FAILURE });
    }
}

export function* detalleFoliosVerCalidadSaga() {
    yield takeLatest(almacenesActions.DETALLE_FOLIO_VERIFICACION_CALIDAD_REQUEST, detalleFoliosVerCalidad);
}

// saga verificacion de calidad
function* verificacionCalidad(action){
    try{    
        console.log(action);
        const token = sessionStorage.getItem('token');

        let dataList = [];
        let producto;
        action.listaFolioVC.forEach( p => {
            
            producto = {
                "idIngresoAlmacenCalidad": p.idIngresoAlmacenCalidad,
                "idServicio": parseInt(p.idServicio),
                "idProducto": p.idProducto,
                "idUnidadMedida": p.idUnidadMedida,
                "folio": p.folio,
                "estado": p.estado,
                "motivoRechazo": p.motivoRechazo,
                "cantidadRechazada": p.cantidadRechazada,
                "cantidad": parseInt(p.cantidad),
                "rutaArchivo": null,
                "lote": p.lote ,
                'serieNum': p.serieNum,
                "idUbicacionProductoDestino": p.idUbicacion,
                // "precioCosto": p.precioCosto,
                "costoOriginal": p.costoOriginal,
                "idTipoMoneda": p.idTipoMoneda,
                "tipoCambio": p.tipoCambio ? p.tipoCambio : 1,
                "fechaCaducidad": p.fechaCaducidad,
                "fechaIngreso": p.fechaIngreso.split('T')[0],
                "fechaAlta": p.fechaAlta,
                "fechaActualizacion": p.fechaActualizacion,
                "activo": null
            }
            dataList.push(producto)


        });

        console.log('dataList', dataList);

        const formData = new FormData();
        formData.append('dataList', new Blob([JSON.stringify(dataList)], {
            type: "application/json"
        }));
        console.log('formData', formData);

        const response = yield call(verificacionCalidadCall, token, formData);
        console.log('response verificacionCalidad', response);

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "La verificación se realizó de manera correcta.";
            const showModalFolioVC = false
            const page = action.page ? action.page : 1;
            const busqueda = action.busqueda ? action.busqueda : { param: '', order: 'DESC', field: 'fechaAlta', fechaInicio: '', fechaFinal: '', idUsuario: undefined, };

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.FOLIO_VERIFICACION_CALIDAD_SUCCESS });
            yield put({ type: almacenesActions.SHOW_MODAL_VERIFICARFOLIO_CAL, showModalFolioVC});
            yield put({ type: almacenesActions.LISTA_FOLIOS_VERIFICACION_CALIDAD_REQUEST, page, busqueda })

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.FOLIO_VERIFICACION_CALIDAD_FAILURE })
        }


    } catch(error){
        yield put({ type: almacenesActions.FOLIO_VERIFICACION_CALIDAD_FAILURE })
    }

}

export function* verificacionCalidadSaga(){
    yield takeLatest(almacenesActions.FOLIO_VERIFICACION_CALIDAD_REQUEST, verificacionCalidad);
}

// imprimir pdf verificacion de calidad
function* imprimirPDFverificacionCalidad(action){
    try{
        console.log(action);
        const token = sessionStorage.getItem('token')
        const idUsuario = action.idUsuario
        const folio = action.folio;

        const response = yield call(imprimirPdfVerificacionCalidadCall, token, idUsuario, folio);
        console.log('response imprimirPDFverificacionCalidad', response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "El pdf se descargó de manera correcta.";
            const page = action.page ? action.page : 1;
            const busqueda = action.busqueda ? action.busqueda : { param: '', order: 'DESC', field: 'fechaAlta', fechaInicio: '', fechaFinal: '', idUsuario: undefined, };

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            let month = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);
            link.download = "FolioVerificación_" + new Date().getDate() + "-" + month + "-" + + new Date().getFullYear() + "_" + new Date().getHours() + "-" + new Date().getMinutes() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.IMPRIMIR_PDF_VERIFICACION_CALIDAD_SUCCESS });
            yield put({ type: almacenesActions.LISTA_FOLIOS_VERIFICACION_CALIDAD_REQUEST, page, busqueda })

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.IMPRIMIR_PDF_VERIFICACION_CALIDAD_FAILURE })
        }
    } catch(error){
        yield put({ type: almacenesActions.IMPRIMIR_PDF_VERIFICACION_CALIDAD_FAILURE })
    }
}

export function* imprimirPDFverificacionCalidadSaga(){
    yield takeLatest(almacenesActions.IMPRIMIR_PDF_VERIFICACION_CALIDAD_REQUEST, imprimirPDFverificacionCalidad)
}

//Sagas de Descargas para mivimientos de almacen
function* verificarSerieNum(action){
    try{
        console.log(action);
        const token = sessionStorage.getItem('token')
        const folio = action.movimientoSeleccionado[0].folio;

        const response = yield call(verificarSerieNumCall, token, folio);
        console.log("response num serie",response)

        if(response.status === 200){
            yield put({ type: almacenesActions.VERIFICAR_SERIENUM_SUCCESS });
            if(response.data === true){
                yield put({ type: almacenesActions.DESCARGAR_LISTA_SERIES_REQUEST, idUsuario: action.idUsuarioRecibe, folio: action.movimientoSeleccionado[0].folio })
            }
        } else {
            yield put({ type: almacenesActions.VERIFICAR_SERIENUM_FAILURE })
        }
    } catch(error){
        yield put({ type: almacenesActions.VERIFICAR_SERIENUM_FAILURE })
    }
}

export function* verificarSerieNumSaga(){
    yield takeLatest(almacenesActions.VERIFICAR_SERIENUM_REQUEST, verificarSerieNum)
}
function* descargarListaSeries(action){
    try{
        console.log(action);
        const token = sessionStorage.getItem('token')
        const idUsuario = action.idUsuario
        const folio = action.folio;

        const response = yield call(descargarListaSeriesCall, token, idUsuario, folio);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "La lista de números de serie se descargó de manera correcta.";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            let month = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);
            link.download = "ListaSeries" + new Date().getDate() + "-" + month + "-" + + new Date().getFullYear() + "_" + new Date().getHours() + "-" + new Date().getMinutes() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.DESCARGAR_LISTA_SERIES_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.DESCARGAR_LISTA_SERIES_FAILURE })
        }
    } catch(error){
        yield put({ type: almacenesActions.DESCARGAR_LISTA_SERIES_FAILURE })
    }
}

export function* descargarListaSeriesSaga(){
    yield takeLatest(almacenesActions.DESCARGAR_LISTA_SERIES_REQUEST, descargarListaSeries)
}
function* descargarSalidaInstalacion(action){
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        const folio = action.folio;

        const response = yield call(descargarSalidaInstalacionCall, token, folio);
        console.log(response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "La salida de instalación se descargó de manera correcta.";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            let month = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);
            link.download = "SalidaInstalacion-" + new Date().getDate() + "-" + month + "-" + + new Date().getFullYear() + "_" + new Date().getHours() + "-" + new Date().getMinutes() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.DESCARGAR_SALIDA_INSTALACION_SUCCESS });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.DESCARGAR_SALIDA_INSTALACION_FAILURE })
        }
    } catch(error){
        yield put({ type: almacenesActions.DESCARGAR_SALIDA_INSTALACION_FAILURE })
    }
}

export function* descargarSalidaInstalacionSaga(){
    yield takeLatest(almacenesActions.DESCARGAR_SALIDA_INSTALACION_REQUEST, descargarSalidaInstalacion)
}


// MOVIMIENTOS DE HERRAMIENTAS
function* busquedaHerramientas(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const idAlmacen = action.idAlmacen;
        let param;

        if(action.busqueda){
            param = 'param=' + action.busqueda
        } else {
            param = 'param='
        }

        const response = yield call(busquedaHerramientasCall, token, param, idAlmacen, page);
        console.log('response busquedaHerramientas', response)

        if(response.status === 200){
            let listaHerramientas = response.data.data;
            let listaHerramientasCalidad = response.data.cantidad;

            for(let i in listaHerramientas){
                if(listaHerramientas[i].productosByIdProducto) {
                    listaHerramientas[i].nombre = listaHerramientas[i].productosByIdProducto.nombre
                    listaHerramientas[i].clave = listaHerramientas[i].productosByIdProducto.clave
                    listaHerramientas[i].sku = listaHerramientas[i].productosByIdProducto.sku
                    listaHerramientas[i].presentacion = listaHerramientas[i].productosByIdProducto.presentacion
                    listaHerramientas[i].descripcion = listaHerramientas[i].productosByIdProducto.descripcion
                    //listaHerramientas[i].cantidad = listaHerramientas[i].productosByIdProducto.cantidad
                   
                }
                if(listaHerramientas[i].ubicacionesProductosByIdUbicaciones){
                    listaHerramientas[i].ubicacion = ` Area: ${listaHerramientas[i].ubicacionesProductosByIdUbicaciones.area} - Piso: ${listaHerramientas[i].ubicacionesProductosByIdUbicaciones.piso} -
                                                  Pasillo: ${listaHerramientas[i].ubicacionesProductosByIdUbicaciones.pasillo} - Rack: ${listaHerramientas[i].ubicacionesProductosByIdUbicaciones.rack} -
                                                  Nivel Rack: ${listaHerramientas[i].ubicacionesProductosByIdUbicaciones.nivelRack} - Posición Rack: ${listaHerramientas[i].ubicacionesProductosByIdUbicaciones.posicionRack}`
                    listaHerramientas[i].idUbicacion = listaHerramientas[i].ubicacionesProductosByIdUbicaciones.idUbicacionProducto   
                }
                listaHerramientas[i].rowKey = listaHerramientas[i].idInventario
            }


            
            yield put({ type: almacenesActions.HERRAMIENTAS_BUSQUEDA_SUCCESS,  listaHerramientas, listaHerramientasCalidad});
        } else {
            yield put({ type: almacenesActions.HERRAMIENTAS_BUSQUEDA_FAILURE });
        }
    } catch(error){
        yield put({ type: almacenesActions.HERRAMIENTAS_BUSQUEDA_FAILURE });
    }
}

export function* busquedaHerramientasSaga() {
    yield takeLatest(almacenesActions.HERRAMIENTAS_BUSQUEDA_REQUEST, busquedaHerramientas);
}

// Prestamos herramientas
function* prestamosHerramientas(action){
    try{
        console.log(action);
        const token = sessionStorage.getItem('token')
        const fechaRetorno = action.formulario.fechaRetorno.format('YYYY-MM-DD');
        const fechaMovimiento = action.formulario.fechaMovimiento.format('YYYY-MM-DD');
        
        let data = [];
        let producto;
        
        action.selectedRows.forEach( p => {
            console.log('producto en saga prestamo', p)

            
            producto = {
                "idMovimientoHerramienta": null,
                "idUsuario": null,
                "idServicio": parseInt(action.formulario.idServicio),
                "idProducto": parseInt(p.idProducto),
                "idUbicacionProducto": parseInt(p.idUbicacionProducto),
                "cantidad": parseInt(p.herramientas.length),
                "faltantes": parseInt(p.herramientas.length),
                "nombrePersonal": action.formulario.nombrePersonal.toString(),
                "folio": null,
                "estado": null,
                "observaciones": null,
                "fechaRetorno": fechaRetorno + "T00:00:00",
                "fechaAlta": null,
                "fechaActualizacion": null,
                "fechaReal": fechaMovimiento,
                "numerosInventariosByIdProducto": p.herramientas.map( item => {
                    let herr = {
                        "idNumeroInventario": parseInt(item.codigoInventario.split(' - ')[0]),
                        "idProducto": parseInt(p.idProducto),
                        "idUbicacionProducto": parseInt(p.idUbicacionProducto),
                        "folio": null,
                        "numeroInventario": item.codigoInventario.split(' - ')[1],
                        "estado": null,
                        "fechaAlta": item.codigoInventario.split(' - ')[2],
                        "fechaActualizacion": null,
                        "activo": null
                    }
                    return herr
                })
            }
            data.push(producto)


        });

        console.log('data', data)

        const response = yield call(prestamoHerramientasCall, token, data);
        console.log('response prestamosHerramientas', response)

        if(response.status === 200){
            const showSuccessMsg = true;;
            const textMessage = "El prestamo se realizó de manera correcta.";
            const page = action.page
            const busqueda = action.busqueda
            const idAlmacen = action.idAlmacen

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "MovimientoPrestamo_" + new Date().getDate() + "-" + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.PRESTAMO_HERRAMIENTAS_SUCCESS });
            yield put({ type: almacenesActions.HERRAMIENTAS_BUSQUEDA_REQUEST, page, busqueda, idAlmacen})
            yield put({ type: almacenesActions.OBTENER_FOLIOS_PRESTAMOS_REQUEST });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.PRESTAMO_HERRAMIENTAS_FAILURE })
        }
    } catch(error){
        yield put({ type: almacenesActions.PRESTAMO_HERRAMIENTAS_FAILURE })
    }
}

export function* prestamosHerramientasSaga(){
    yield takeLatest(almacenesActions.PRESTAMO_HERRAMIENTAS_REQUEST, prestamosHerramientas)
}


// baja herramientas
function* bajaHerramientas(action){
    try{
        console.log(action);
        const token = sessionStorage.getItem('token')
        const fechaMovimiento = action.formulario.fechaMovimiento.format('YYYY-MM-DD');
        
        let data = [];
        let producto;

        action.selectedRows.forEach( p => {
            console.log('producto en saga baja', p)
            producto = {
                "idMovimientoHerramienta": null,
                "idUsuario": null,
                "idServicio": null,
                "idProducto": parseInt(p.idProducto),
                "idUbicacionProducto": parseInt(p.idUbicacionProducto),
                "cantidad": parseInt(p.herramientas.length),
                "faltantes": 0,
                "nombrePersonal": null,
                "folio": null,
                "estado": null,
                "observaciones": p.observaciones,
                "fechaRetorno": null,
                "fechaAlta": null,
                "fechaActualizacion": null,
                "fechaReal": fechaMovimiento,
                "numerosInventariosByIdProducto": p.herramientas.map( item => {
                    let herr = {
                        "idNumeroInventario": parseInt(item.codigoInventario.split(' - ')[0]),
                        "idProducto": parseInt(p.idProducto),
                        "idUbicacionProducto": parseInt(p.idUbicacionProducto),
                        "folio": null,
                        "numeroInventario": item.codigoInventario.split(' - ')[1],
                        "estado": item.observaciones,
                        "fechaAlta": item.codigoInventario.split(' - ')[2],
                        "fechaActualizacion": null,
                        "activo": null
                    }
                    return herr
                })
            }
            data.push(producto)
        });

        console.log('data', data)

        const response = yield call(bajaHerramientasCall, token, data);
        console.log('response bajaHerramientas', response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "La baja se realizó de manera correcta.";
            const page = action.page
            const busqueda = action.busqueda
            const idAlmacen = action.idAlmacen
            
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.BAJA_HERRAMIENTAS_SUCCESS });
            yield put({ type: almacenesActions.HERRAMIENTAS_BUSQUEDA_REQUEST, page, busqueda, idAlmacen})
            yield put({ type: almacenesActions.OBTENER_FOLIOS_PRESTAMOS_REQUEST });


        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.BAJA_HERRAMIENTAS_FAILURE })
        }
    } catch(error){
        yield put({ type: almacenesActions.BAJA_HERRAMIENTAS_FAILURE })
    }
}

export function* bajaHerramientasSaga(){
    yield takeLatest(almacenesActions.BAJA_HERRAMIENTAS_REQUEST, bajaHerramientas)
}

// folios prestamos request
function* foliosPrestamosActivos(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
         
        const response = yield call(obtenerFoliosPrestamosCall, token );
        console.log('response FoliosPrestamosActivos', response)

        if(response.status === 200){
            let foliosPrestamos = response.data;

            yield put({ type: almacenesActions.OBTENER_FOLIOS_PRESTAMOS_SUCCESS,  foliosPrestamos });
        } else {
            yield put({ type: almacenesActions.OBTENER_FOLIOS_PRESTAMOS_FAILURE });
        }
    } catch(error){
        yield put({ type: almacenesActions.OBTENER_FOLIOS_PRESTAMOS_FAILURE });
    }
}

export function* foliosPrestamosActivosSaga() {
    yield takeLatest(almacenesActions.OBTENER_FOLIOS_PRESTAMOS_REQUEST, foliosPrestamosActivos)
}

//devolucion de herramientas
function* devolucionHerramientas(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        const fechaMovimiento = action.formulario.fechaMovimiento.format('YYYY-MM-DD');

        let data = []
        let producto;
        action.selectedRows.forEach( p => {
            console.log('producto en saga devolucion', p)

            p.faltantesDev =  p.faltantes - parseInt(p.herramientas.length) 
            console.log('faltantes', p.faltantesDev);


            producto = {
                "idMovimientoHerramienta": p.idMovimientoHerramienta,
                "idUsuario": parseInt(p.idUsuario),
                "idServicio": parseInt(p.idServicio),
                "idProducto": parseInt(p.idProducto),
                "idUbicacionProducto": parseInt(p.idUbicacionProducto),
                "cantidad": parseInt(p.cantidad),
                "faltantes": parseInt(p.faltantesDev),
                "nombrePersonal": p.nombrePersonal,
                "folio": action.formulario.folioPrestamo,
                "estado": p.estado,
                "observaciones": p.observaciones,
                "fechaRetorno": p.fechaRetorno,
                "fechaAlta": p.fechaAlta,
                "fechaActualizacion": p.fechaActualizacion,
                "fechaReal": fechaMovimiento,
                "activo": true,
                "numerosInventariosByIdProducto": p.herramientas.map( item => {
                    let herr = {
                        "idNumeroInventario": item.idNumeroInventario,
                        "idProducto": item.idProducto,
                        "idUbicacionProducto": item.idUbicacionProducto,
                        "folio": null,
                        "numeroInventario": item.numeroInventario,
                        "estado": item.observaciones,
                        "fechaAlta": item.fechaAlta,
                        "fechaActualizacion": item.fechaActualizacion,
                        "activo": null
                    }
                    return herr
                }) 
            }
            data.push(producto)
        });

        console.log('data', data)

        const response = yield call(devolucionHerramientasCall, token, data);
        console.log('response devolucionHerramientas', response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "La devolución se realizó de manera correcta.";
            const folioPrestamo = []

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.DEVOLUCION_HERRAMIENTAS_SUCCESS });
            yield put({ type: almacenesActions.DETALLE_FOLIO_PRESTAMO_SUCCESS, folioPrestamo });
            yield put({ type: almacenesActions.OBTENER_FOLIOS_PRESTAMOS_REQUEST });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.DEVOLUCION_HERRAMIENTAS_FAILURE })
        }

    } catch(error){
        yield put({ type: almacenesActions.DEVOLUCION_HERRAMIENTAS_FAILURE })
    }
}

export function* devolucionHerramientasSaga(){
    yield takeLatest(almacenesActions.DEVOLUCION_HERRAMIENTAS_REQUEST, devolucionHerramientas)
}

// folios prestamos request
function* folioPrestamo(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.pageFolioPrestamo - 1
        const folio = action.folio
         
        const response = yield call(obtenerFolioPrestamoCall, token, folio, page );
        console.log('response folioPrestamo', response)

        if(response.status === 200){
            let folioPrestamo = response.data.data;
            let folioPrestamoCantidad = response.data.cantidad

            for(let i in folioPrestamo) {
                if(folioPrestamo[i].productosByIdProducto) {
                    folioPrestamo[i].nombre = folioPrestamo[i].productosByIdProducto.nombre
                    folioPrestamo[i].clave = folioPrestamo[i].productosByIdProducto.clave
                    folioPrestamo[i].sku = folioPrestamo[i].productosByIdProducto.sku
                    folioPrestamo[i].presentacion = folioPrestamo[i].productosByIdProducto.presentacion
                    folioPrestamo[i].descripcion = folioPrestamo[i].productosByIdProducto.descripcion
                   
                }
                if(folioPrestamo[i].ubicacionesProductosByIdUbicacionProducto){
                    folioPrestamo[i].ubicacion = ` Area: ${folioPrestamo[i].ubicacionesProductosByIdUbicacionProducto.area} - Piso: ${folioPrestamo[i].ubicacionesProductosByIdUbicacionProducto.piso} -
                                                  Pasillo: ${folioPrestamo[i].ubicacionesProductosByIdUbicacionProducto.pasillo} - Rack: ${folioPrestamo[i].ubicacionesProductosByIdUbicacionProducto.rack} -
                                                  Nivel Rack: ${folioPrestamo[i].ubicacionesProductosByIdUbicacionProducto.nivelRack} - Posición Rack: ${folioPrestamo[i].ubicacionesProductosByIdUbicacionProducto.posicionRack}`
                    folioPrestamo[i].idUbicacion = folioPrestamo[i].ubicacionesProductosByIdUbicacionProducto.idUbicacionProducto   
                }
                folioPrestamo[i].rowKey = folioPrestamo[i].idMovimientoHerramienta;
                folioPrestamo[i].fechaRealTabla = folioPrestamo[i].fechaReal.split('T')[0].split('-').reverse().join('-');
            }

            yield put({ type: almacenesActions.DETALLE_FOLIO_PRESTAMO_SUCCESS, folioPrestamo, folioPrestamoCantidad });
        } else {
            yield put({ type: almacenesActions.DETALLE_FOLIO_PRESTAMO_FAILURE });
        }
    } catch(error){
        yield put({ type: almacenesActions.DETALLE_FOLIO_PRESTAMO_FAILURE });
    }
}

export function* folioPrestamoSaga(){
    yield takeLatest(almacenesActions.DETALLE_FOLIO_PRESTAMO_REQUEST, folioPrestamo)
}

//numeros inventario prestados request
function* numerosInventarioPrestamosActivos(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
         
        const response = yield call(obtenerNumerosInventarioPrestadosCall, token);
        console.log('response numerosInventarioPrestamosActivos', response)

        if(response.status === 200){
            let listaNumerosInventarioPrestados = response.data;

            yield put({ type: almacenesActions.NUMEROS_INVENTARIO_PRESTADO_SUCCESS,  listaNumerosInventarioPrestados });
        } else {
            yield put({ type: almacenesActions.NUMEROS_INVENTARIO_PRESTADO_FAILURE });
        }
    } catch(error){
        yield put({ type: almacenesActions.NUMEROS_INVENTARIO_PRESTADO_FAILURE });
    }
}

export function* numerosInventarioPrestamosActivosSaga() {
    yield takeLatest(almacenesActions.NUMEROS_INVENTARIO_PRESTADO_REQUEST, numerosInventarioPrestamosActivos)
}

//numeros inventario por folio request
function* numerosInventarioFolio(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        const folio = action.folio;
        const idUbicacionProducto = action.idUbicacionProducto;
        const idProducto = action.idProducto;
         
        const response = yield call(obtenerNumerosInventarioPorFolioCall, token, folio, idUbicacionProducto, idProducto);
        console.log('response numerosInventarioFolio', response)

        if(response.status === 200){
            let listaNumerosInventarioFolio = response.data;

            yield put({ type: almacenesActions.OBTENER_NUMEROS_INVENTARIO_FOLIO_SUCCESS,  listaNumerosInventarioFolio });
        } else {
            yield put({ type: almacenesActions.OBTENER_NUMEROS_INVENTARIO_FOLIO_FAILURE });
        }
    } catch(error){
        yield put({ type: almacenesActions.OBTENER_NUMEROS_INVENTARIO_FOLIO_FAILURE });
    }
}

export function* numerosInventarioFolioSaga() {
    yield takeLatest(almacenesActions.OBTENER_NUMEROS_INVENTARIO_FOLIO_REQUEST, numerosInventarioFolio)
}


//crear numeros inventario request
function* crearNumerosInventario(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');

        let data = []
        let dataHerra
        for(let i in action.formulario.herramientas) {
            dataHerra = {
                "idNumeroInventario": null,
                "idProducto": action.herrSeleccionada.idProducto,
                "idUbicacionProducto": action.herrSeleccionada.idUbicacionProducto,
                "folio": null,
                "numeroInventario": action.formulario.herramientas[i].codigoInventario,
                "estado": null,
                "fechaAlta": null,
                "fechaActualizacion": null,
                "activo": null
            }
            data.push(dataHerra)
        };

        console.log('data', data)
        
         
        const response = yield call(crearNumerosInventarioCall, token, data);
        console.log('response crearNumerosInventario', response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "Los numeros se crearon de manera correcta.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.CREAR_NUMEROS_INVENTARIO_FOLIO_SUCCESS });
            
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.CREAR_NUMEROS_INVENTARIO_FOLIO_FAILURE });
        }
    } catch(error){
        yield put({ type: almacenesActions.CREAR_NUMEROS_INVENTARIO_FOLIO_FAILURE });
    }
}

export function* crearNumerosInventarioSaga() {
    yield takeLatest(almacenesActions.CREAR_NUMEROS_INVENTARIO_FOLIO_REQUEST, crearNumerosInventario)
}


//numeros inventario por folio request
function* numerosInventarioDisponibles(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idUbicacionProducto = action.idUbicacionProducto;
        const idProducto = action.idProducto;
         
        const response = yield call(obtenerNumerosInventarioDisponiblesCall, token, idProducto, idUbicacionProducto);
        console.log('response numerosInventarioDisponibles', response)

        if(response.status === 200){
            let numerosInventarioDisponibles = response.data.data 
            let numerosInventarioDisponiblesCantidad = response.data.cantidad

            yield put({ type: almacenesActions.OBTENER_NUMEROS_INVENTARIO_DISPONIBLES_SUCCESS,  numerosInventarioDisponibles, numerosInventarioDisponiblesCantidad });
        } else {
            yield put({ type: almacenesActions.OBTENER_NUMEROS_INVENTARIO_DISPONIBLES_FAILURE });
        }
    } catch(error){
        yield put({ type: almacenesActions.OBTENER_NUMEROS_INVENTARIO_DISPONIBLES_FAILURE });
    }
}

export function* numerosInventarioDisponiblesSaga() {
    yield takeLatest(almacenesActions.OBTENER_NUMEROS_INVENTARIO_DISPONIBLES_REQUEST, numerosInventarioDisponibles)
}

//Carga masiva IPH

function* cargaMasivaIPH(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page;
        const typeUpload = action.typeUpload;
        console.log(page);

        const formData = new FormData();
        formData.append('file', action.formulario.file.originFileObj);
        

        let response = yield call(cargaMasivaIPHCall, token, formData);
        console.log("response de carga iph",response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = `El archivo fue cargado con éxito. Se guardaron ${response.data.info.activosGuardados} con éxito y hubo ${response.data.info.activosTotales - response.data.info.activosGuardados} errores`;
            let folio = [];
            let descargarFolio = false;
            let descargarArchivoSeries = false;
            const disableBtnAdd = true;

            if(response.data.info.activosGuardados >0){
                folio.push({folio:response.data.info.folio});
                if(typeUpload!='series'){
                    descargarArchivoSeries = true;
                }
               
                yield put({ type: almacenesActions.CARGA_MASIVA_PIH_SUCCESS, descargarFolio:descargarFolio, folio:folio });
                yield put({ type: almacenesActions.DESCARGAR_ARCHIVO_SERIES, descargarArchivoSeries:descargarArchivoSeries, folio:folio });
            }

            yield put({ type: almacenesActions.CHANGE_VALUE_BTN_ADD, disableBtnAdd:disableBtnAdd});

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'cargaMasivaAlmacen' });
            yield put({ type: almacenesActions.CARGA_MASIVA_PIH_SUCCESS, descargarFolio:descargarFolio, folio:folio });
            yield put({ type: almacenesActions.GET_ARCHIVOS_ESPERA_REQUEST, page:page });
            
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.CARGA_MASIVA_PIH_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = "Hubo un error";
        console.log("error", error)

        yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: almacenesActions.CARGA_MASIVA_PIH_FAILURE });
    }
}
export function* cargaMasivaIPHSaga() {
    yield takeLatest(almacenesActions.CARGA_MASIVA_PIH_REQUEST, cargaMasivaIPH);
}


function* erroresArchivoPIH(action) {
    try {
        console.log("action idArchivoMasivo",action);
        const idArchivoMasivo = parseInt(action.idArchivoMasivo);
        const token = sessionStorage.getItem('token');
        const page = action.page-1;
        console.log(page);

        let response = yield call(getErroresCargaMasivaID, token, idArchivoMasivo, page);
        console.log("response errores archivo",response);

        if (response.status == 200) {
            const listInfoErroresCargaMasiva = response.data;
            const listErroresCargaMasiva = listInfoErroresCargaMasiva.content;
            yield put({ type: almacenesActions.ERRORES_CARGA_PIH_SUCCESS, listInfoErroresCargaMasiva, listErroresCargaMasiva });
        }
        else {
            yield put({ type: almacenesActions.ERRORES_CARGA_PIH_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.ERRORES_CARGA_PIH_FAILURE });
    }
}
export function* erroresArchivoPIHSaga() {
    yield takeLatest(almacenesActions.ERRORES_CARGA_PIH_REQUEST, erroresArchivoPIH);
}


function* ProcesamientoArchivosPIH(action) {
    try {
        console.log("action idArchivoMasivo",action);
        let estado = 'Finalizado';
        const token = sessionStorage.getItem('token');
        const page = action.page-1;
        console.log(page);

        let response = yield call(getListArchivosCargaMasiva, token, page, estado);
        console.log("response list archivos",response);

        if (response.status == 200) {
            const listInfoArchivosCargaMasiva = response.data;
            const listArchivosCargaMasivaPIH = listInfoArchivosCargaMasiva.content;
            yield put({ type: almacenesActions.GET_LIST_PROCESAMIENTO_PIH_SUCCESS, listInfoArchivosCargaMasiva, listArchivosCargaMasivaPIH });
        }
        else {
            yield put({ type: almacenesActions.GET_LIST_PROCESAMIENTO_PIH_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: almacenesActions.GET_LIST_PROCESAMIENTO_PIH_FAILURE });
    }
}
export function* ProcesamientoArchivosPIHSaga() {
    yield takeLatest(almacenesActions.GET_LIST_PROCESAMIENTO_PIH_REQUEST, ProcesamientoArchivosPIH);
}

function* cargaMasivaSeries(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page;
        let folio = action.folio;
        console.log("folio",folio)
        console.log(page);

        const formData = new FormData();
        formData.append('file', action.formulario[0].originFileObj);

        let response = yield call(cargaMasivaSeriesCall, token, formData, folio);
        console.log("response cargaMasivaSeries",response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = `El archivo fue cargado con éxito. Se guardaron ${response.data.info.activosGuardados} con éxito y hubo ${response.data.info.activosTotales - response.data.info.activosGuardados} errores`;
            const disableBtnFolio = false;
            const disableBtnSeries = true;

            yield put({ type: almacenesActions.CHANGE_VALUE_BTN_FOLIO, disableBtnFolio:disableBtnFolio});
            yield put({ type: almacenesActions.CHANGE_VALUE_BTN_SERIES, disableBtnSeries:disableBtnSeries});
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.GET_ARCHIVOS_ESPERA_REQUEST, page:page });
            yield put({ type: almacenesActions.CARGA_MASIVA_SERIES_SUCCESS});
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.CARGA_MASIVA_SERIES_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = "Hubo un error";
        console.log("error", error)

        yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: almacenesActions.CARGA_MASIVA_SERIES_FAILURE });
    }
}
export function* cargaMasivaSeriesSaga() {
    yield takeLatest(almacenesActions.CARGA_MASIVA_SERIES_REQUEST, cargaMasivaSeries);
}


//Carga masiva Almacén

function* cargaMasivaAlmacen(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page;
        console.log(page);

        const formData = new FormData();
        formData.append('file', action.formulario.file.originFileObj);
        

        let response = yield call(cargaMasivaAlmacenCall, token, formData);
        console.log("response cargaMasivaAlmacen",response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const disableBtnAdd = true;
            let descargarFolio = false;
            const textMessage = `El archivo fue cargado con éxito. Se guardaron ${response.data.info.activosGuardados} con éxito y hubo ${response.data.info.activosTotales - response.data.info.activosGuardados} errores`;
            let folio = [];
            if(response.data.info.activosGuardados > 0){
                folio.push({folio:response.data.info.folio});
                console.log("folio",folio)
            }
            yield put({ type: almacenesActions.CHANGE_VALUE_BTN_ADD, disableBtnAdd:disableBtnAdd});
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.CARGA_MASIVA_ALMACEN_SUCCESS, descargarFolio:descargarFolio, folio:folio });
            yield put({ type: almacenesActions.GET_ARCHIVOS_ESPERA_REQUEST, page:page });

        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.CARGA_MASIVA_ALMACEN_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = "Hubo un error";
        console.log("error", error)

        yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: almacenesActions.CARGA_MASIVA_ALMACEN_FAILURE });
    }
}
export function* cargaMasivaAlmacenSaga() {
    yield takeLatest(almacenesActions.CARGA_MASIVA_ALMACEN_REQUEST, cargaMasivaAlmacen);
}

function* cargaMasivaSeriesAlmacen(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page;
        let folio = action.folio;
        console.log("action.formulario.formulario",action.formulario[0].originFileObj)
        console.log(page);

        const formData = new FormData();
        formData.append('file', action.formulario[0].originFileObj);
        

        let response = yield call(cargaMasivaSeriesAlmacenCall, token, formData, folio);
        console.log("response",response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El archivo fue cargado con éxito.";
            const descargarArchivoSeries = false;
            const disableBtnSeries = true;

            yield put({ type: almacenesActions.CHANGE_VALUE_BTN_SERIES, disableBtnSeries:disableBtnSeries});
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.CARGA_MASIVA_ALMACEN_SERIES_SUCCESS });
            yield put({ type: almacenesActions.GET_ARCHIVOS_ESPERA_REQUEST, page:page });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.CARGA_MASIVA_ALMACEN_SERIES_FAILURE });
        }

    } catch (error) {
        const showErrorMsg = true;
        const textMessage = "Hubo un error";
        console.log("error", error)

        yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: almacenesActions.CARGA_MASIVA_ALMACEN_SERIES_FAILURE });
    }
}
export function* cargaMasivaSeriesAlmacenSaga() {
    yield takeLatest(almacenesActions.CARGA_MASIVA_ALMACEN_SERIES_REQUEST, cargaMasivaSeriesAlmacen);
}



//-------------------------------------------------REQUISICION DE MATERIALES SAGAS--------------------------------------------------
//Lista Requisiciones generadas
function* listaRequisicionesMaterialesBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let busqueda = '';
        let estado = '';
        let idEncargado = '';
        let order = action.orden.order;
        let field = action.orden.field;
        let responsable = '';
        if(action.busqueda.busqueda){
            busqueda = 'busqueda=' + action.busqueda.busqueda + '&';
        }
        if(action.busqueda.estado){
            estado = 'estado=' + action.busqueda.estado + '&';
        }
        if(action.busqueda.idEncargado){
            idEncargado = 'idEncargado=' + action.busqueda.idEncargado + '&';
        }
        if(action.responsable){
            responsable = 'responsable=' + action.responsable + '&';
        }

        const response = yield call(getRequisicionesGeneradasCall, token, page, order, field, busqueda, estado, idEncargado, responsable );
        console.log(response);

        if (response.status === 200) {
            let listaFoliosRequisicionMateriales = response.data.data;
            let listaFoliosRequisicionMaterialesCantidad = response.data.cantidad;
            for(let i in listaFoliosRequisicionMateriales){
                function numberWithCommas(x) {
                    let parts = x.toString().split(".");
                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return parts.join(".");
                }
                listaFoliosRequisicionMateriales[i].totalSolicitud = '$' + numberWithCommas(listaFoliosRequisicionMateriales[i].precioByIdRequisicionMateriales.toFixed(2));

                listaFoliosRequisicionMateriales[i].fechaAlta = listaFoliosRequisicionMateriales[i].fechaAlta?.split('T')[0].split('-').reverse().join('-');
                listaFoliosRequisicionMateriales[i].idUsuarioSolicitante = listaFoliosRequisicionMateriales[i].usuarioByIdUsuario.aMaterno ? listaFoliosRequisicionMateriales[i].usuarioByIdUsuario.nombre + ' ' + listaFoliosRequisicionMateriales[i].usuarioByIdUsuario.aPaterno + ' ' + listaFoliosRequisicionMateriales[i].usuarioByIdUsuario.aMaterno : listaFoliosRequisicionMateriales[i].usuarioByIdUsuario.nombre + ' ' + listaFoliosRequisicionMateriales[i].usuarioByIdUsuario.aPaterno;
                for(let x in listaFoliosRequisicionMateriales[i].productosByIdRequisicion){
                    listaFoliosRequisicionMateriales[i].productosByIdRequisicion[x].cantidad = listaFoliosRequisicionMateriales[i].productosByIdRequisicion[x].cantidadSolicitada;
                    listaFoliosRequisicionMateriales[i].productosByIdRequisicion[x].codigoAdicional = listaFoliosRequisicionMateriales[i].productosByIdRequisicion[x].productoByIdProducto.codigoAdicional;
                    listaFoliosRequisicionMateriales[i].productosByIdRequisicion[x].nombre = listaFoliosRequisicionMateriales[i].productosByIdRequisicion[x].productoByIdProducto.nombre;
                    listaFoliosRequisicionMateriales[i].productosByIdRequisicion[x].clave = listaFoliosRequisicionMateriales[i].productosByIdRequisicion[x].productoByIdProducto.clave;
                    listaFoliosRequisicionMateriales[i].productosByIdRequisicion[x].sku = listaFoliosRequisicionMateriales[i].productosByIdRequisicion[x].productoByIdProducto.sku;

                    listaFoliosRequisicionMateriales[i].productosByIdRequisicion[x].precioTabla = numberWithCommas(listaFoliosRequisicionMateriales[i].productosByIdRequisicion[x].productoByIdProducto.precio) + ' ' + listaFoliosRequisicionMateriales[i].productosByIdRequisicion[x].productoByIdProducto.tipoMonedaByIdTipoMoneda.nomenclatura;
                }
                const idServicio = listaFoliosRequisicionMateriales[i].productosByIdRequisicion[0].productoByIdProducto.subFamiliaByIdSubFamilia.familiaByIdFamilia.idServicio;

                const responseResponsables = yield call(listaResponsablesPorServicioCall, token, idServicio);
                const listaResponsablesServicio = responseResponsables.data.data;

                listaFoliosRequisicionMateriales[i].responsablesRangos = [];
                listaFoliosRequisicionMateriales[i].idsResponsables = [];
                listaFoliosRequisicionMateriales[i].activarAprobar = false;

                if(responseResponsables.status === 200){
                    for(let x in listaResponsablesServicio){
                        if(!listaFoliosRequisicionMateriales[i].idsResponsables.includes(listaResponsablesServicio[x].idUsuarioResponsable)){
                            listaFoliosRequisicionMateriales[i].idsResponsables.push(listaResponsablesServicio[x].idUsuarioResponsable);
                            if(listaResponsablesServicio[x].rangoDeValidacionByIdRandeDeValidacion){
                                listaFoliosRequisicionMateriales[i].responsablesRangos.push({'limiteInferior': listaResponsablesServicio[x].rangoDeValidacionByIdRandeDeValidacion.limiteInferior, 'limiteSuperior': listaResponsablesServicio[x].rangoDeValidacionByIdRandeDeValidacion.limiteSuperior});
                            }
                            else{
                                listaFoliosRequisicionMateriales[i].responsablesRangos.push(null);
                            }
                        }
                        
                    }
                }
                for(let x in listaFoliosRequisicionMateriales[i].idsResponsables){
                    if(listaFoliosRequisicionMateriales[i].idsResponsables[x] === action.idUsuario){
                        if(listaFoliosRequisicionMateriales[i].responsablesRangos[x]?.limiteInferior <= listaFoliosRequisicionMateriales[i].precioByIdRequisicionMateriales && listaFoliosRequisicionMateriales[i].responsablesRangos[x]?.limiteSuperior >= listaFoliosRequisicionMateriales[i].precioByIdRequisicionMateriales){
                            listaFoliosRequisicionMateriales[i].activarAprobar = true;
                        }
                    }
                }
            }

            yield put({ type: almacenesActions.LISTA_FOLIOS_REQUISICION_MATERIALES_SUCCESS, listaFoliosRequisicionMateriales, listaFoliosRequisicionMaterialesCantidad });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.LISTA_FOLIOS_REQUISICION_MATERIALES_FAILURE });
        }
    } catch (error) {
        const showErrorMsg = true;
        const textMessage = "Hubo un error";
        console.log("error", error)

        yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: almacenesActions.LISTA_FOLIOS_REQUISICION_MATERIALES_FAILURE });
    }
}
export function* listaRequisicionesMaterialesBusquedaSaga() {
    yield takeLatest(almacenesActions.LISTA_FOLIOS_REQUISICION_MATERIALES_REQUEST, listaRequisicionesMaterialesBusqueda);
}

//Lista IPH con filtro y sin iltro
function* listaProductosRequisicionMaterialesBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let servicio = '';
        let partida = '';
        let campo = '';
        let subFamilia = '';
        if(action.servicio){
            servicio = 'servicio=' + action.servicio + '&';
        }
        if(action.partida){
            partida = 'idPartida=' + action.partida + '&';
        }
        if(action.campo){
            campo = 'campo=' + action.campo + '&';
        }
        if(action.subFamilia){
            subFamilia = 'subFamilia=' + action.subFamilia + '&';
        }

        const response = yield call(getProductosPageRequisicionMaterialesCall, token, page, servicio, partida, campo, subFamilia );
        console.log(response);

        if (response.status === 200) {
            let listaProductosBusqueda = response.data.data === null ? [] : response.data.data;
            let listaProductosBusquedaCantidad = response.data.cantidad;

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            listaProductosBusqueda.forEach( producto => {
                let fechaAlta = producto.fechaAlta.split('T')[0];
                producto.fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
                producto.fechaCaducidadC = "No tiene fecha"
                producto.loteC = "No tiene lote"
                // const {familia} = producto.familiaByIdFamilia;
                // const  {subFamilia} = producto.subFamiliaByIdSubFamilia;

                // producto.condicion = producto.activoCaractByIdProducto?.condicion;
                producto.especificaciones = producto.activoCaractByIdProducto?.especificaciones;
                producto.usoBasico = producto.activoCaractByIdProducto?.usoBasico;
                producto.vidaUtil = producto.activoCaractByIdProducto?.vidaUtil;
                producto.unidadMedidaVidaUtil = producto.activoCaractByIdProducto?.unidadMedidaByIdUnidadMedida.unidadMedida;
                producto.vidaUtilSugerida = producto.activoCaractByIdProducto?.vidaUtilSugerida.split(' ')[0];
                producto.unidadMedidaSugerida = producto.activoCaractByIdProducto?.vidaUtilSugerida.split(' ')[1];

                // producto.familia = familia;
                // producto.subFamilia = subFamilia;
                producto.rowKey = producto.idProducto;
                // producto.unidadMedida = producto.unidadMedidaByIdUnidadMedida.unidadMedida;
                producto.precio = producto.precio.toFixed(2);
                // producto.tipoMoneda = producto.tipoMonedaByIdTipoMoneda.nombre + ' (' + producto.tipoMonedaByIdTipoMoneda.nomenclatura + ')';
                // producto.tipoMonedaEditar = producto.tipoMonedaByIdTipoMoneda.idTipoMoneda + ' - ' + producto.tipoMonedaByIdTipoMoneda.nombre + ' (' + producto.tipoMonedaByIdTipoMoneda.nomenclatura + ')';

                producto.precioTabla = numberWithCommas(producto.precio) + ' ' +producto.tipoMonedaByIdTipoMoneda.nomenclatura;
                //+ ' ' + producto.tipoMonedaByIdTipoMoneda.nomenclatura;
                producto.precioTablaGridProductos = numberWithCommas(producto.precio);
                if(action.productosByIdRequisicion){
                    for(let i in action.productosByIdRequisicion){
                        if(action.productosByIdRequisicion[i].idProducto === producto.idProducto){
                            producto.cantidad = action.productosByIdRequisicion[i].cantidad;
                        }
                    }
                }
            })
            
            yield put({ type: almacenesActions.LISTA_INVENTARIO_REQUISICION_MATERIALES_SUCCESS, listaProductosBusqueda, listaProductosBusquedaCantidad });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.LISTA_INVENTARIO_REQUISICION_MATERIALES_FAILURE });
        }
    } catch (error) {
        const showErrorMsg = true;
        const textMessage = "Hubo un error";
        console.log("error", error)

        yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: almacenesActions.LISTA_INVENTARIO_REQUISICION_MATERIALES_FAILURE });
    }
}
export function* listaProductosRequisicionMaterialesBusquedaSaga() {
    yield takeLatest(almacenesActions.LISTA_INVENTARIO_REQUISICION_MATERIALES_REQUEST, listaProductosRequisicionMaterialesBusqueda);
}

//Crear Solicitud de Materiales
function* registrarRequisicionMateriales(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = [];

        for(let i in action.listaSeleccionados){
            let producto = {
                "idProducto": action.listaSeleccionados[i].idProducto,
                "cantidadSolicitada": action.listaSeleccionados[i].cantidad,
            }
            data.push(producto);
        }
        console.log('data', data);
        const response = yield call(registrarRequisicionMaterialesCall, token, data );
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "La solicitud fue creada con éxito";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'registrarRequisicion' });
            yield put({ type: almacenesActions.REGISTRAR_REQUISICION_MATERIALES_SUCCESS });
            yield put({ type: almacenesActions.SHOW_MODAL_NUEVO_FOLIO_REQUISICION_MATERIALES, showModalRequisicionMateriales: false });
            yield put({ type: almacenesActions.LISTA_FOLIOS_REQUISICION_MATERIALES_REQUEST, page: action.page, busqueda: action.busqueda, orden: action.orden })
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.REGISTRAR_REQUISICION_MATERIALES_FAILURE });
        }
    } catch (error) {
        const showErrorMsg = true;
        const textMessage = "Hubo un error";
        console.log("error", error)

        yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: almacenesActions.REGISTRAR_REQUISICION_MATERIALES_FAILURE });
    }
}
export function* registrarRequisicionMaterialesSaga() {
    yield takeLatest(almacenesActions.REGISTRAR_REQUISICION_MATERIALES_REQUEST, registrarRequisicionMateriales);
}

//Editar Solicitud de Materiales
function* editarRequisicionMateriales(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let idRequisicionMateriales = action.requisicionSeleccionada.idRequisicionMateriales;
        const data = [];

        for(let i in action.listaSeleccionados){
            let producto = {
                "idProducto": action.listaSeleccionados[i].idProducto,
                "cantidadSolicitada": action.listaSeleccionados[i].cantidad,
            }
            data.push(producto);
        }
        console.log('data', data);
        const response = yield call(editarRequisicionMaterialesCall, token, idRequisicionMateriales, data );
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "La solicitud fue editada con éxito";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarRequisicion' });
            yield put({ type: almacenesActions.EDITAR_REQUISICION_SUCCESS });
            yield put({ type: almacenesActions.SHOW_MODAL_EDITAR_REQUISICION, showModalEditarRequisicion: false });
            yield put({ type: almacenesActions.LISTA_FOLIOS_REQUISICION_MATERIALES_REQUEST, page: action.page, busqueda: action.busqueda, orden: action.orden })
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.EDITAR_REQUISICION_FAILURE });
        }
    } catch (error) {
        const showErrorMsg = true;
        const textMessage = "Hubo un error";
        console.log("error", error)

        yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: almacenesActions.EDITAR_REQUISICION_FAILURE });
    }
}
export function* editarRequisicionMaterialesSaga() {
    yield takeLatest(almacenesActions.EDITAR_REQUISICION_REQUEST, editarRequisicionMateriales);
}

//Cambiar estado Solicitud de Materiales
function* cambiarEstadoRequisicion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const {idRequisicionMateriales, idEstado} = action;
        const data = {
            'nota': action.nota ? action.nota : '',
        }

        const response = yield call(cambiarEstadoRequisicionCall, token, idRequisicionMateriales, idEstado, data );
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = idEstado === 2 ? 'El folio fue autorizado con éxito' : idEstado === 3 ? 'El folio fue surtido con éxito' : idEstado === 4 ? 'El folio fue parcialmente surtido con éxito' : idEstado === 5 ? 'El folio fue cancelado con éxito' : 'El folio fue cerrado con éxito' ;

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.CAMBIAR_ESTADO_REQUISICION_SUCCESS });
            yield put({ type: almacenesActions.LISTA_FOLIOS_REQUISICION_MATERIALES_REQUEST, page: action.page, busqueda: action.busqueda, orden: action.orden, idUsuario: action.idUsuario, responsable: action.responsable })
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.CAMBIAR_ESTADO_REQUISICION_FAILURE });
        }
    } catch (error) {
        const showErrorMsg = true;
        const textMessage = "Hubo un error";
        console.log("error", error)

        yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: almacenesActions.CAMBIAR_ESTADO_REQUISICION_FAILURE });
    }
}
export function* cambiarEstadoRequisicionSaga() {
    yield takeLatest(almacenesActions.CAMBIAR_ESTADO_REQUISICION_REQUEST, cambiarEstadoRequisicion);
}

//Aprobar Solicitud de Materiales
function* aprobarRequisicionMateriales(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idRequisicionMateriales = action.requisicionSeleccionada.idRequisicionMateriales;
        const data = [];

        for(let i in action.selectedRows){
            let producto = {
                "idProducto": action.selectedRows[i].idProducto,
            }
            data.push(producto);
        }
        console.log('data', data);
        const response = yield call(aprobarProductosRequisicionCall, token, idRequisicionMateriales, data );
        console.log(response);

        if (response.status === 200) {
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'aprobarRequisicion' });
            yield put({ type: almacenesActions.APROBAR_REQUISICION_SUCCESS });
            yield put({ type: almacenesActions.SHOW_MODAL_APROBAR_REQUISICION, showModalAprobarRequisicion: false });
            if(action.requisicionSeleccionada.estado === 'Generado'){
                yield put({ type: almacenesActions.CAMBIAR_ESTADO_REQUISICION_REQUEST, idRequisicionMateriales, idEstado: 2, page: action.page, busqueda: action.busqueda, orden: action.orden, idUsuario: action.idUsuario, responsable: action.responsable })
            }
            else{
                const textMessage = 'El folio fue autorizado con éxito';

                yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage });
                yield put({ type: almacenesActions.LISTA_FOLIOS_REQUISICION_MATERIALES_REQUEST, page: action.page, busqueda: action.busqueda, orden: action.orden, idUsuario: action.idUsuario, responsable: action.responsable })
            }
            if(response.data.id){
                yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage: 'Se generó la requisición de compra con el folio: "' + response.data.id + '"' });                
            }
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.APROBAR_REQUISICION_FAILURE });
        }
    } catch (error) {
        const showErrorMsg = true;
        const textMessage = "Hubo un error";
        console.log("error", error)

        yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: almacenesActions.APROBAR_REQUISICION_FAILURE });
    }
}
export function* aprobarRequisicionMaterialesSaga() {
    yield takeLatest(almacenesActions.APROBAR_REQUISICION_REQUEST, aprobarRequisicionMateriales);
}

// Descargar pdf de folio de requisicion de materiales
function* descargarFolioRequisicionMateriales(action){
    try{
        console.log(action);
        const token = sessionStorage.getItem('token')
        const folio = action.folio;

        const response = yield call(descargarFolioRequisicionCall, token, folio);
        console.log('response', response)

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "El folio se descargó de manera correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            let month = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);
            link.download = "folioSolicitudMateriales" + new Date().getDate() + "-" + month + "-" + + new Date().getFullYear() + "_" + new Date().getHours() + "-" + new Date().getMinutes() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.DESCARGAR_REQUISICION_SUCCESS });
        } else {
            const showErrorMsg = true;
            const textMessage = 'Hubo un error, favor de intentar de nuevo mas tarde';

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.DESCARGAR_REQUISICION_FAILURE })
        }
    } catch(error){
        yield put({ type: almacenesActions.DESCARGAR_REQUISICION_FAILURE })
    }
}

export function* descargarFolioRequisicionMaterialesSaga(){
    yield takeLatest(almacenesActions.DESCARGAR_REQUISICION_REQUEST, descargarFolioRequisicionMateriales)
}

//Lista Productos por Folio Requisición
function* productosFolioRequisicion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let folio = action.folio;
        let idAlmacen = action.idAlmacen;
        let response = yield call(productosFolioRequisicionCall, token, folio, idAlmacen );
        console.log(response);

        if (response.status === 200) {
            response = response.data.data[0];
            let listaProductosFolioRequisicion = response;
            listaProductosFolioRequisicion.productos = [];
            listaProductosFolioRequisicion.productosAprobados = [];
            for(let i in listaProductosFolioRequisicion.productosByIdRequisicion){
                listaProductosFolioRequisicion.productosByIdRequisicion[i].codigoAdicional = listaProductosFolioRequisicion.productosByIdRequisicion[i].productoByIdProducto.codigoAdicional;
                listaProductosFolioRequisicion.productosByIdRequisicion[i].nombre = listaProductosFolioRequisicion.productosByIdRequisicion[i].productoByIdProducto.nombre;
                listaProductosFolioRequisicion.productosByIdRequisicion[i].clave = listaProductosFolioRequisicion.productosByIdRequisicion[i].productoByIdProducto.clave;
                listaProductosFolioRequisicion.productosByIdRequisicion[i].sku = listaProductosFolioRequisicion.productosByIdRequisicion[i].productoByIdProducto.sku;
                listaProductosFolioRequisicion.productosByIdRequisicion[i].presentacion = listaProductosFolioRequisicion.productosByIdRequisicion[i].productoByIdProducto.presentacion;
                listaProductosFolioRequisicion.productosByIdRequisicion[i].cantidadSurtir = 0;
                if(listaProductosFolioRequisicion.productosByIdRequisicion[i].aprobar){
                    listaProductosFolioRequisicion.productosAprobados.push(listaProductosFolioRequisicion.productosByIdRequisicion[i]);

                    for(let x in listaProductosFolioRequisicion.productosByIdRequisicion[i].stockByIdProducto){
                        listaProductosFolioRequisicion.productosByIdRequisicion[i].stockByIdProducto[x].codigoAdicional = listaProductosFolioRequisicion.productosByIdRequisicion[i].productoByIdProducto.codigoAdicional;
                        listaProductosFolioRequisicion.productosByIdRequisicion[i].stockByIdProducto[x].nombre = listaProductosFolioRequisicion.productosByIdRequisicion[i].productoByIdProducto.nombre;
                        listaProductosFolioRequisicion.productosByIdRequisicion[i].stockByIdProducto[x].clave = listaProductosFolioRequisicion.productosByIdRequisicion[i].productoByIdProducto.clave;
                        listaProductosFolioRequisicion.productosByIdRequisicion[i].stockByIdProducto[x].sku = listaProductosFolioRequisicion.productosByIdRequisicion[i].productoByIdProducto.sku;
                        listaProductosFolioRequisicion.productosByIdRequisicion[i].stockByIdProducto[x].presentacion = listaProductosFolioRequisicion.productosByIdRequisicion[i].productoByIdProducto.presentacion;
                        listaProductosFolioRequisicion.productosByIdRequisicion[i].stockByIdProducto[x].cantidadSolicitada = listaProductosFolioRequisicion.productosByIdRequisicion[i].cantidadSolicitada;
                        listaProductosFolioRequisicion.productosByIdRequisicion[i].stockByIdProducto[x].cantidadSurtida = listaProductosFolioRequisicion.productosByIdRequisicion[i].cantidadSurtida;
                        listaProductosFolioRequisicion.productosByIdRequisicion[i].stockByIdProducto[x].cantidadSurtir = listaProductosFolioRequisicion.productosByIdRequisicion[i].stockByIdProducto[x].serieNum ? 'No aplica' : 0;
                        listaProductosFolioRequisicion.productosByIdRequisicion[i].stockByIdProducto[x].serieNum = listaProductosFolioRequisicion.productosByIdRequisicion[i].stockByIdProducto[x].serieNum ? listaProductosFolioRequisicion.productosByIdRequisicion[i].stockByIdProducto[x].serieNum : 'No aplica';
                        listaProductosFolioRequisicion.productosByIdRequisicion[i].stockByIdProducto[x].tipoFamilia = listaProductosFolioRequisicion.productosByIdRequisicion[i].productoByIdProducto.subFamiliaByIdSubFamilia.familiaByIdFamilia.tipoFamilia;
                        listaProductosFolioRequisicion.productosByIdRequisicion[i].stockByIdProducto[x].idUbicacionProductoOrigen = listaProductosFolioRequisicion.productosByIdRequisicion[i].stockByIdProducto[x].ubicacionesProductosByIdUbicaciones.idUbicacionProducto;
                        listaProductosFolioRequisicion.productosByIdRequisicion[i].stockByIdProducto[x].precio = listaProductosFolioRequisicion.productosByIdRequisicion[i].stockByIdProducto[x].costoOriginal;
                        listaProductosFolioRequisicion.productosByIdRequisicion[i].stockByIdProducto[x].tipoCambio = listaProductosFolioRequisicion.productosByIdRequisicion[i].stockByIdProducto[x].tipoCambio + ' MXN';
                        
    
                        listaProductosFolioRequisicion.productos.push(listaProductosFolioRequisicion.productosByIdRequisicion[i].stockByIdProducto[x]);
                    }
                }
            }

            yield put({ type: almacenesActions.PRODUCTOS_FOLIO_REQUISICION_SUCCESS, listaProductosFolioRequisicion });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.PRODUCTOS_FOLIO_REQUISICION_FAILURE });
        }
    } catch (error) {
        const showErrorMsg = true;
        const textMessage = "Hubo un error";
        console.log("error", error)

        yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: almacenesActions.PRODUCTOS_FOLIO_REQUISICION_FAILURE });
    }
}
export function* productosFolioRequisicionSaga() {
    yield takeLatest(almacenesActions.PRODUCTOS_FOLIO_REQUISICION_REQUEST, productosFolioRequisicion);
}

//surtir Folio Solicitud de Materiales
function* surtirRequisicionMateriales(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idRequisicionMateriales = action.listaProductosFolioRequisicion.idRequisicionMateriales;
        const data = [];

        for(let i in action.listaProductosFolioRequisicion.productosByIdRequisicion){
            if(action.listaProductosFolioRequisicion.productosByIdRequisicion[i].cantidadSurtir !== 0){
                let producto = {
                    "idProducto": action.listaProductosFolioRequisicion.productosByIdRequisicion[i].idProducto,
                    "cantidadSurtida": action.listaProductosFolioRequisicion.productosByIdRequisicion[i].cantidadSurtir,
                }
                data.push(producto);
            }
        }
        console.log('data', data);
        const response = yield call(surtirProductosFolioRequisicionCall, token, idRequisicionMateriales, data );
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "La solicitud fue surtida con éxito, creando folio de movimiento...";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: almacenesActions.SURTIR_PRODUCTOS_FOLIO_REQUISICION_SUCCESS });
            yield put({ type: almacenesActions.NUEVO_FOLIO_REQUEST, formularioNuevoFolio: action.formularioNuevoFolio, listaSeleccionados: action.listaSeleccionados, userInfo: action.userInfo, page: action.page, busqueda: action.busqueda });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.SURTIR_PRODUCTOS_FOLIO_REQUISICION_FAILURE });
        }
    } catch (error) {
        const showErrorMsg = true;
        const textMessage = "Hubo un error";
        console.log("error", error)

        yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
        yield put({ type: almacenesActions.SURTIR_PRODUCTOS_FOLIO_REQUISICION_FAILURE });
    }
}
export function* surtirRequisicionMaterialesSaga() {
    yield takeLatest(almacenesActions.SURTIR_PRODUCTOS_FOLIO_REQUISICION_REQUEST, surtirRequisicionMateriales);
}

//Reporte de Existencias
function* listaReporteDeExistencias(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const data = {
            "idsAlmacenes" : action.formulario.idAlmacen ? [action.formulario.idAlmacen.split(' - ')[0]] : null,
            "idsServicios" : action.formulario.idServicio ? [action.formulario.idServicio.split(' - ')[0]] : null,
            "idsFamilias" : action.formulario.idFamilia ? [action.formulario.idFamilia.split(' - ')[0]] : null,
            "idsSubfamilias" : action.formulario.idSubFamilia ? [action.formulario.idSubFamilia.split(' - ')[0]] : null,
            "fechaInicio" : action.formulario.rangoFechas ? action.formulario.rangoFechas[0].format('YYYY-MM-DD') + ' 00:00:00' : null,
            "fechaFin" : action.formulario.rangoFechas ? action.formulario.rangoFechas[1].format('YYYY-MM-DD') + ' 23:59:59' : null,
            "idsTipoMovimientos" : action.formulario.idTipoMovimiento ? [action.formulario.idTipoMovimiento.split(' - ')[0]] : null,
            "page" : (action.page - 1) * 10,
            "offset" : 10,
            "order" : action.order.order,
            "field" : action.order.field,
            "agrupamiento" : null
        }
        console.log(`data AQUÍ`, data);

        const response = yield call(reporteExistenciasCall, token, data)
        console.log("response list reporte existencia",response)

        if (response.status === 200) {
            const listaReporteExistencias = response.data.data;
            const listaReporteExistenciasCantidad = response.data.cantidad;

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            for(let i in listaReporteExistencias){
                const fecha = listaReporteExistencias[i].fechaMovimiento.split('T')[0].split('-').reverse().join('-');
                const hora = listaReporteExistencias[i].fechaMovimiento.split('T')[1].split(':')[0] + ':' + listaReporteExistencias[i].fechaMovimiento.split('T')[1].split(':')[1];
                listaReporteExistencias[i].fechaMovimientoTable = fecha + ' ' + hora;

                listaReporteExistencias[i].tipoCambioTable = numberWithCommas(parseInt(listaReporteExistencias[i].tipoCambio).toFixed(2));
                listaReporteExistencias[i].costoUnitarioTable = numberWithCommas(parseInt(listaReporteExistencias[i].costoUnitario).toFixed(2));
                listaReporteExistencias[i].costoPromedioTable = numberWithCommas(parseInt(listaReporteExistencias[i].costoPromedio).toFixed(2));
                listaReporteExistencias[i].costoPesosTable = numberWithCommas(parseInt(listaReporteExistencias[i].costoPesos).toFixed(2));
            }

            if(action.agrupado){
                const listaReporteExistenciasAgrupado = action.agrupado.listaReporteExistenciasAgrupado;
                const listaReporteExistenciasAgrupadoCantidad = action.agrupado.listaReporteExistenciasAgrupadoCantidad;
                for(let i in listaReporteExistenciasAgrupado){
                    if(action.agrupado.id === listaReporteExistenciasAgrupado[i].id){
                        listaReporteExistenciasAgrupado[i].agrupados = response.data;
                        listaReporteExistenciasAgrupado[i].agrupados.page = action.page;
                        for(let x in listaReporteExistenciasAgrupado[i].agrupados.data){
                            listaReporteExistenciasAgrupado[i].agrupados.data[x].id = x + 1;
                        };
                    }
                }
                yield put({ type: almacenesActions.LISTA_REPORTE_EXISTENCIAS_AGRUPADO_SUCCESS, listaReporteExistenciasAgrupado, listaReporteExistenciasAgrupadoCantidad })
                yield put({ type: almacenesActions.LISTA_REPORTE_EXISTENCIAS_FAILURE });
            }
            else{
                yield put({ type: almacenesActions.LISTA_REPORTE_EXISTENCIAS_SUCCESS, listaReporteExistencias, listaReporteExistenciasCantidad })
            }
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: almacenesActions.LISTA_REPORTE_EXISTENCIAS_FAILURE});
        }
    } catch (error) {
        yield put({type: almacenesActions.LISTA_REPORTE_EXISTENCIAS_FAILURE});
    }
}
export function* listaReporteDeExistenciasSaga() {
    yield takeLatest(almacenesActions.LISTA_REPORTE_EXISTENCIAS_REQUEST, listaReporteDeExistencias)
}


function* reporteExistenciasAgrupado(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const data = {
            "idsAlmacenes" : action.formulario.idAlmacen ? [action.formulario.idAlmacen.split(' - ')[0]] : null,
            "idsServicios" : action.formulario.idServicio ? [action.formulario.idServicio.split(' - ')[0]] : null,
            "idsFamilias" : action.formulario.idFamilia ? [action.formulario.idFamilia.split(' - ')[0]] : null,
            "idsSubfamilias" : action.formulario.idSubFamilia ? [action.formulario.idSubFamilia.split(' - ')[0]] : null,
            "fechaInicio" : action.formulario.rangoFechas ? action.formulario.rangoFechas[0].format('YYYY-MM-DD') + ' 00:00:00' : null,
            "fechaFin" : action.formulario.rangoFechas ? action.formulario.rangoFechas[1].format('YYYY-MM-DD') + ' 23:59:59' : null,
            "idsTipoMovimientos" : action.formulario.idTipoMovimiento ? [action.formulario.idTipoMovimiento.split(' - ')[0]] : null,
            "page" : (action.page - 1) * 10,
            "offset" : 10,
            "order" : action.order.order,
            "field" : action.order.field,
            "agrupamiento" :  action.agrupado !== 'SinAgrupar' ? action.agrupado : '',
        }
        console.log(`data`, data);

        const response = yield call(reporteExistenciasAgrupadoCall, token, data)
        console.log("response en reporte existencias agrupado",response)

        if(response.status === 200){
            const listaReporteExistenciasAgrupado = response.data.data;
            const listaReporteExistenciasAgrupadoCantidad = response.data.cantidad;
            const agrupadoConst = action.agrupado ? action.agrupado : action.formulario.tipoAgrupado;

            for(let i in listaReporteExistenciasAgrupado){
                if(listaReporteExistenciasAgrupado[i].nombre === '0'){
                    listaReporteExistenciasAgrupado[i].nombre = agrupadoConst === 'familias' ? 'Sin familia asignada' : agrupadoConst === 'subfamilias' ? 'Sin subfamilia asignada' : 'Sin tipo de folio asignado';
                }
            }
            
            for(let i in listaReporteExistenciasAgrupado){
                if(listaReporteExistenciasAgrupado[i].nombre === 'Sin asignacion'){
                    listaReporteExistenciasAgrupado[i].nombre = 'Sin asignación'
                }
                const dataAgrupado = {
                    "idsAlmacenes" : agrupadoConst === 'almacen' ? [listaReporteExistenciasAgrupado[i].id] : action.formulario.idAlmacen ? [action.formulario.idAlmacen.split(' - ')[0]] : null,
                    "idsServicios" : agrupadoConst === 'servicio' ? [listaReporteExistenciasAgrupado[i].id] : action.formulario.idServicio ? [action.formulario.idServicio.split(' - ')[0]] : null,
                    "idsFamilias" : agrupadoConst === 'familias' ? [listaReporteExistenciasAgrupado[i].id] : action.formulario.idFamilia ? [action.formulario.idFamilia.split(' - ')[0]] : null,
                    "idsSubfamilias" : agrupadoConst === 'subfamilias' ? [listaReporteExistenciasAgrupado[i].id] : action.formulario.idSubFamilia ? [action.formulario.idSubFamilia.split(' - ')[0]] : null,
                    "fechaInicio" : action.formulario.rangoFechas ? action.formulario.rangoFechas[0].format('YYYY-MM-DD') + ' 00:00:00' : null,
                    "fechaFin" : action.formulario.rangoFechas ? action.formulario.rangoFechas[1].format('YYYY-MM-DD') + ' 23:59:59' : null,
                    "idsTipoMovimientos" : agrupadoConst === 'tipoDeFolios' ? [listaReporteExistenciasAgrupado[i].id] : action.formulario.idTipoMovimiento ? [action.formulario.idTipoMovimiento.split(' - ')[0]] : null,
                    "page" : (action.page - 1) * 10,
                    "offset" : 10,
                    "order" : action.order.order,
                    "field" : action.order.field,
                }
                console.log('dataAgrupado', dataAgrupado);

                const responseAgrupado = yield call(reporteExistenciasCall, token, dataAgrupado)
                console.log('responseAgrupado', responseAgrupado)
                if(responseAgrupado.status === 200){
                    listaReporteExistenciasAgrupado[i].agrupados = responseAgrupado.data;
                    listaReporteExistenciasAgrupado[i].agrupados.page = 1;
                    for(let x in listaReporteExistenciasAgrupado[i].agrupados.data){
                        listaReporteExistenciasAgrupado[i].agrupados.data[x].id = parseInt(x) + 1;
                        const fecha = listaReporteExistenciasAgrupado[i].agrupados.data[x].fechaMovimiento.split('T')[0].split('-').reverse().join('-');
                        const hora = listaReporteExistenciasAgrupado[i].agrupados.data[x].fechaMovimiento.split('T')[1].split(':')[0] + ':' + listaReporteExistenciasAgrupado[i].agrupados.data[x].fechaMovimiento.split('T')[1].split(':')[1];
                        listaReporteExistenciasAgrupado[i].agrupados.data[x].fechaMovimientoTable = fecha + ' ' + hora;
                    };
                }
                else{
                    const showErrorMsg = true;
                    const textMessage = responseAgrupado.message;

                    yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                    yield put({ type: almacenesActions.LISTA_REPORTE_EXISTENCIAS_AGRUPADO_FAILURE });
                }
            }

            console.log('listaReporteExistenciasAgrupado', listaReporteExistenciasAgrupado)
            yield put({ type: almacenesActions.LISTA_REPORTE_EXISTENCIAS_AGRUPADO_SUCCESS, listaReporteExistenciasAgrupado, listaReporteExistenciasAgrupadoCantidad })

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: almacenesActions.LISTA_REPORTE_EXISTENCIAS_AGRUPADO_FAILURE });
        }
    } catch (error) {
        yield put({ type: almacenesActions.LISTA_REPORTE_EXISTENCIAS_AGRUPADO_FAILURE });
    }
}
export function* reporteExistenciasAgrupadoSaga(){
    yield takeLatest(almacenesActions.LISTA_REPORTE_EXISTENCIAS_AGRUPADO_REQUEST, reporteExistenciasAgrupado)
}

function* reporteExistenciasExcel(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const data = {
            "idsAlmacenes" : action.formulario.idAlmacen ? [action.formulario.idAlmacen.split(' - ')[0]] : null,
            "idsServicios" : action.formulario.idServicio ? [action.formulario.idServicio.split(' - ')[0]] : null,
            "idsFamilias" : action.formulario.idFamilia ? [action.formulario.idFamilia.split(' - ')[0]] : null,
            "idsSubfamilias" : action.formulario.idSubFamilia ? [action.formulario.idSubFamilia.split(' - ')[0]] : null,
            "fechaInicio" : action.formulario.rangoFechas ? action.formulario.rangoFechas[0].format('YYYY-MM-DD') + ' 00:00:00' : null,
            "fechaFin" : action.formulario.rangoFechas ? action.formulario.rangoFechas[1].format('YYYY-MM-DD') + ' 23:59:59' : null,
            "idsTipoMovimientos" : action.formulario.idTipoMovimiento ? [action.formulario.idTipoMovimiento.split(' - ')[0]] : null,
            "page" : 0,
            "offset" : 10,
            "order" : action.order.order,
            "field" : action.order.field,
            "agrupamiento" : null
        }

        console.log(`data`, data);

        const response = yield call(reporteExistenciasExcelCall, token, data)
        console.log(response)

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dec" ];
            const d = new Date();

            const titulo = action.tituloDocumento ? action.tituloDocumento : 'ReporteExistencias_'
            link.download = titulo + d.getDate() + "-" + monthNames[d.getMonth()] + "-" + d.getFullYear() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: almacenesActions.EXCEL_REPORTE_EXISTENCIAS_SUCCESS })
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage: 'El archivo se descargó de forma correcta' });

        } else {
            const showErrorMsg = true;
            const textMessage = 'No se pudo descargar el archivo con los filtros seleccionados';

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: almacenesActions.EXCEL_REPORTE_EXISTENCIAS_FAILURE});
        }
    } catch (error) {
        yield put({type: almacenesActions.EXCEL_REPORTE_EXISTENCIAS_FAILURE});
    }
}
export function* reporteExistenciasExcelSaga() {
    yield takeLatest(almacenesActions.EXCEL_REPORTE_EXISTENCIAS_REQUEST, reporteExistenciasExcel)
}

function* reporteExistenciasPDF(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const data = {
            "idsAlmacenes" : action.formulario.idAlmacen ? [action.formulario.idAlmacen.split(' - ')[0]] : null,
            "idsServicios" : action.formulario.idServicio ? [action.formulario.idServicio.split(' - ')[0]] : null,
            "idsFamilias" : action.formulario.idFamilia ? [action.formulario.idFamilia.split(' - ')[0]] : null,
            "idsSubfamilias" : action.formulario.idSubFamilia ? [action.formulario.idSubFamilia.split(' - ')[0]] : null,
            "fechaInicio" : action.formulario.rangoFechas ? action.formulario.rangoFechas[0].format('YYYY-MM-DD') + ' 00:00:00' : null,
            "fechaFin" : action.formulario.rangoFechas ? action.formulario.rangoFechas[1].format('YYYY-MM-DD') + ' 23:59:59' : null,
            "idsTipoMovimientos" : action.formulario.idTipoMovimiento ? [action.formulario.idTipoMovimiento.split(' - ')[0]] : null,
            "page" : 0,
            "offset" : 10,
            "order" : action.order.order,
            "field" : action.order.field,
            "agrupamiento" : null
        }

        console.log(`data`, data);

        const titulo = "ReporteExistencias";

        const response = yield call(reporteExistenciasPDFCall, token, data, titulo)
        console.log(response)

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dec" ];
            const d = new Date();

            const titulo = action.tituloDocumento ? action.tituloDocumento : 'ReporteExistencias_'
            link.download = titulo + d.getDate() + "-" + monthNames[d.getMonth()] + "-" + d.getFullYear() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: almacenesActions.PDF_REPORTE_EXISTENCIAS_SUCCESS })
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage: 'El archivo se descargó de forma correcta' });

        } else {
            const showErrorMsg = true;
            const textMessage = 'No se pudo descargar el archivo con los filtros seleccionados';

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: almacenesActions.PDF_REPORTE_EXISTENCIAS_FAILURE});
        }
    } catch (error) {
        yield put({type: almacenesActions.PDF_REPORTE_EXISTENCIAS_FAILURE});
    }
}
export function* reporteExistenciasPDFSaga() {
    yield takeLatest(almacenesActions.PDF_REPORTE_EXISTENCIAS_REQUEST, reporteExistenciasPDF)
}