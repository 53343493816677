import React, { Component } from 'react';
import {  Modal, Row, Col } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

class ModalArchivoContrato extends Component {
    render() {
        const { contratoSeleccionado, showArchivosContrato, onShowModalArchivosContrato } = this.props;

        const handleCancel = () => {
            onShowModalArchivosContrato()
        }
          
        return (
            <Modal
            visible={showArchivosContrato}
            title='Archivos Contrato'
            onCancel={handleCancel}
            footer={false}
            >
                <Row justify='space-around'>
                    <Col span={24}>
                        {contratoSeleccionado?(
                        <div>
                            <h2>Contrato actual:</h2>
                            {contratoSeleccionado.rutaArchivo ? (
                            <a target='_blank' href={contratoSeleccionado.rutaArchivo}>{contratoSeleccionado.rutaArchivo}</a>
                            ):(
                            <p>No cuenta con un contrato, favor de agregar uno.</p>
                            )}
                        </div>):false}
                    </Col>
                </Row>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarContrato: state.InformacionOperativaReducer.fetchingEditarContrato,
        showArchivosContrato: state.InformacionOperativaReducer.showArchivosContrato,
        contratoSeleccionado: state.InformacionOperativaReducer.contratoSeleccionado,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalArchivosContrato: () => {
            dispatch({ type: 'SHOW_MODAL_ARCHIVOS_CONTRATO', showArchivosContrato: false, servicioSeleccionado: null})
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalArchivoContrato);