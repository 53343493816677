import React from 'react';
import { Row, Col, Switch, Modal, Table, Button, Input, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import '../../../stylesheets/RecursosHumanos/AltaPersonal.css';
import ModalNuevoTipoFalla from './ModalNuevoTipoFalla';
import ModalEditarTipoFalla from './ModalEditarTipoFalla';

const confirm = Modal.confirm;
const { Search } = Input;
class TiposDeFallasContainer extends React.Component {
    componentDidMount(){
        this.props.onGetListaTiposFallas(1);
    }
    state={
        page: 1,
        busqueda: null, 
    }
    render() {
        const { fetchingListaTiposFallasBusqueda, listaTiposFallasBusqueda, onEstadoTipoFalla,
            onGetListaTiposFallas, onModalEditarTipoFalla, onModalNuevoTipoFalla, listaTiposFallasBusquedaCantidad,
            
        } = this.props;
        
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        const onBusqueda = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            onGetListaTiposFallas(1, busqueda);
        }

        const columns = [{
                title: 'Clave',
                dataIndex: 'clave',
                key: 'clave',
            },{
                title: 'Nombre de tipo de falla',
                dataIndex: 'nombre',
                key: 'nombre',
            },{
                title: 'Categoría de falla',
                dataIndex: 'tipo',
                key: 'tipo',
            },{
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                align: 'center',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.tipoDeFallaActivoInactivo} onChange={() => onCambiarEstado(record)}/>
                ),
            },{
                title: 'Editar',
                key: 'editar',
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarTipoDeFalla} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            }
        ];

        const onEditar = (key) => {
            console.log('personal seleccionado');
            console.log(key);
            onModalEditarTipoFalla(key, this.state.page, this.state.busqueda)
        }

        const onCambiarEstado = (key) => {
            console.log('rutina seleccionado');
            console.log(key);

            const page = this.state.page;
            const busqueda = this.state.busqueda;

            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del tipo de falla "'+ key.nombre + '"?',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoTipoFalla(key.idTipoFalla, page, busqueda);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
            
        }
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onGetListaTiposFallas(pagination.current, this.state.busqueda);
            
        }
        
        const onNuevoTipoFalla = () => {
            onModalNuevoTipoFalla(this.state.page, this.state.busqueda);
        }

        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search className='buscarModulos' placeholder="Ingresa nombre, clave y descripcion del tipo de falla a buscar" onSearch={value => onBusqueda(value)} style={{ width: '100%' }}/>
                    </Col>
                    {/* <Col span={6}>
                        <Button onClick={onNuevoTipoFalla} disabled={!permisosUsuario.crearTipoDeFalla} className='nuevoUsuario'>Nuevo Tipo de Falla</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nuevo Tipo de Falla' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoTipoFalla} disabled={!permisosUsuario.crearTipoDeFalla} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="idTipoFalla" 
                            columns={columns}
                            dataSource={listaTiposFallasBusqueda}
                            loading={fetchingListaTiposFallasBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaTiposFallasBusquedaCantidad, simple: true, current: this.state.page}}
                        />         
                    </Col>
                </Row>
                <ModalNuevoTipoFalla/>
                <ModalEditarTipoFalla/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaTiposFallasBusqueda: state.SolicitudDeServiciosReducer.listaTiposFallasBusqueda,
        fetchingListaTiposFallasBusqueda: state.SolicitudDeServiciosReducer.fetchingListaTiposFallasBusqueda,
        listaTiposFallasBusquedaCantidad: state.SolicitudDeServiciosReducer.listaTiposFallasBusquedaCantidad,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetListaTiposFallas: (page, busqueda) => {
	    	dispatch({ type: "LISTA_TIPOS_FALLAS_BUSQUEDA_REQUEST", page, busqueda });
        },
        onEstadoTipoFalla: (idTipoFalla, page, busqueda) => {
            console.log('entró a dispatch estado')
	    	dispatch({ type: "ESTADO_TIPO_FALLA_REQUEST", idTipoFalla, page, busqueda });
        },
        onModalNuevoTipoFalla: (page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_TIPO_FALLA", showModalNuevoTipoFalla: true, page, busqueda });
        },
        onModalEditarTipoFalla: (tipoFallaSeleccionado, page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_TIPO_FALLA", showModalEditarTipoFalla: true, tipoFallaSeleccionado, page, busqueda });
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(TiposDeFallasContainer);