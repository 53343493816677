import React from 'react';
import { message, Form, Row, Col, Button, Modal, Select, Table, Tooltip } from 'antd';
import { connect } from "react-redux";
import { FilePdfFilled } from '@ant-design/icons';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option } = Select;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };

class ModalProductos extends React.Component {
    formRef = React.createRef();

    componentDidMount() {
          this.props.onGetUsuarios();
     }

    state = {
        page: 1,
        checked: true
     }


    render() {
          const { 
               page,
               limpiarFormulario, 
               onLimpiarFormulario,
               onShowModalProductos,
               showModalInventarioCiclico,
               listaUsuariosActivos,
               fetchingListaUsuariosActivos,
               almacenesSeleccionados, 
               serviciosSeleccionados, 
               familiasSeleccionadas, 
               subfamiliasSeleccionadas, 
               rangoMayor, 
               rangoMenor, 
               porcentaje,
               onBusquedaListadoInventario,
               onDescargarInventario,
               fetchingListaProductosCiclico,
               listadoInventarioCiclico,
               listadoInventarioCiclicoCantidad,
               onLimpiarTabla,
               fetchingListaInventarioCiclicoDescarga
          } = this.props;

          const onFinish = formulario => {              
               console.log('Success:', formulario);
               if(listadoInventarioCiclico.data !== null){
                    if(!listadoInventarioCiclico.data[0] || listadoInventarioCiclico.cantidad === 0){
                         console.log('tabla', this.formRef.current.getFieldValue('productosFiltrados'));
                         message.warning('No se encuentran productos dentro del listado para la toma de inventario cíclico');
                    } else {
                         this.formRef.current.resetFields();
                         onDescargarInventario(listadoInventarioCiclico.data, parseInt(formulario.idUsuario), almacenesSeleccionados, subfamiliasSeleccionadas, rangoMayor, rangoMenor, porcentaje);
                    }
               } else {
                    message.warning('No se encuentran productos dentro del listado para la toma de inventario cíclico');
               }
          };

          if (limpiarFormulario && limpiarFormulario === 'inventarioFisico') {
               onLimpiarFormulario();
          }

          const onFinishFailed = errorInfo => {
               console.log('Failed:', errorInfo);
          };

          const handleCancel = () => {
               onShowModalProductos();
               this.formRef.current.resetFields();
               this.setState({ page: 1, checked: true })
               onLimpiarTabla();
          };

          const handleTableChange = (pagination) => {
               this.setState({
                   page: pagination.current,
               });
               onBusquedaListadoInventario(
                    almacenesSeleccionados, 
                    serviciosSeleccionados, 
                    familiasSeleccionadas, 
                    subfamiliasSeleccionadas, 
                    rangoMayor, 
                    rangoMenor, 
                    porcentaje, 
                    pagination.current);
           }

          const onUsuarios = (value) => {
               if(value !== undefined){
                    this.setState({ checked: false })
               } else {
                    this.setState({ checked: true })
               }
          }

          const columns = [
               { title: 'SKU', dataIndex: 'sku', key: 'sku', width:'8%' },
               { title: 'Clave de Producto', dataIndex: 'clave', key: 'clave', width:'9%' },
               { title: 'Nombre de Producto', dataIndex: 'nombreProducto', key: 'nombreProducto', width:'15%' },
               { title: 'Unidad de Medida', dataIndex: 'unidadMedida', key: 'unidadMedida', width:'7%' },
               { title: 'Almacén', dataIndex: 'almacen', key: 'almacen', width:'10%' },
               { title: 'Ubicación', dataIndex: 'ubicacion', key: 'ubicacion', width:'18%' },
          ];

          console.log('listadoInventarioCiclico', listadoInventarioCiclico)

          return (

               <Modal
                    visible={showModalInventarioCiclico}
                    title='Listado para Toma de Inventario Cíclico'
                    onCancel={handleCancel}
                    footer={false}
                    width={'85%'}
               >
                    <Form {...layout}
                         name="formulario"
                         onFinish={onFinish}
                         ref={this.formRef}
                    >
                         <Row justify='space-around' style={{ textAlign: 'left' }}> 
                              <Col span={24}>
                                   <Form.Item
                                        name="productosFiltrados"
                                   >
                                        <Table 
                                             title = { () => 
                                                  <Row justify='end' gutter={[8,8]} style={{paddingRight:'1em'}}>
                                                       <Col span={1}>
                                                            <Tooltip title='Exportar a PDF '>
                                                                 <Button className="iconSecundary" shape='circle' disabled={listadoInventarioCiclicoCantidad <= 0} icon={<FilePdfFilled /> } loading={fetchingListaInventarioCiclicoDescarga} htmlType='submit'/>
                                                            </Tooltip>
                                                       </Col>
                                                  </Row>
                                             }
                                             size="small" 
                                             rowKey="idProducto" 
                                             columns={columns}
                                             locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar'}}
                                             onChange={handleTableChange}
                                             dataSource={listadoInventarioCiclico.data ? listadoInventarioCiclico.data : [] }
                                             loading={fetchingListaProductosCiclico || fetchingListaInventarioCiclicoDescarga}
                                             pagination={{ total: (listadoInventarioCiclicoCantidad), simple: true, current: this.state.page } }                                            
                                        />  
                                   </Form.Item>
                              </Col>

                              {/* <Col span={6}>
                                   <br/>
                                   <Button className="btnSecundario" type="primary" block htmlType='submit' disabled={listadoInventarioCiclicoCantidad <= 0} loading={fetchingListaInventarioCiclicoDescarga} >
                                        Exportar a PDF <FilePdfOutlined />
                                   </Button>
                              </Col> */}
                         
                         </Row>
                    </Form>
               </Modal>
          );
     }
}

const mapStateToProps = state => {
     return {
          page: state.AlmacenesReducer.page,
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          showModalInventarioCiclico: state.AlmacenesReducer.showModalInventarioCiclico,
          almacenesSeleccionados: state.AlmacenesReducer.almacenesSeleccionados, 
          serviciosSeleccionados: state.AlmacenesReducer.serviciosSeleccionados, 
          familiasSeleccionadas: state.AlmacenesReducer.familiasSeleccionadas, 
          subfamiliasSeleccionadas: state.AlmacenesReducer.subfamiliasSeleccionadas,
          listaUsuariosActivos: state.AlmacenesReducer.listaUsuariosActivos,
          fetchingListaUsuariosActivos: state.AlmacenesReducer.fetchingListaUsuariosActivos, 
          rangoMayor: state.AlmacenesReducer.rangoMayor, 
          rangoMenor: state.AlmacenesReducer.rangoMenor, 
          porcentaje: state.AlmacenesReducer.porcentaje,
          fetchingListaProductosCiclico: state.AlmacenesReducer.fetchingListaProductosCiclico,
          listadoInventarioCiclico: state.AlmacenesReducer.listadoInventarioCiclico,
          listadoInventarioCiclicoCantidad: state.AlmacenesReducer.listadoInventarioCiclicoCantidad,
          fetchingListaInventarioCiclicoDescarga: state.AlmacenesReducer.fetchingListaInventarioCiclicoDescarga
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
          onShowModalProductos: () => {
               dispatch({ type: 'SHOW_MODAL_INVENTARIO_CICLICO', showModalInventarioCiclico: false })
          },
          onGetUsuarios: () => {
               dispatch({ type: 'LISTA_USUARIOS_ACTIVOS_REQUEST', });
          },
          onBusquedaListadoInventario: (almacenesSeleccionados, serviciosSeleccionados, familiasSeleccionadas, subfamiliasSeleccionadas, rangoMayor, rangoMenor, porcentaje, page) => {
               dispatch({ type: 'LISTA_INVENTARIO_CICLICO_REQUEST', almacenesSeleccionados,serviciosSeleccionados, familiasSeleccionadas, subfamiliasSeleccionadas, rangoMayor, rangoMenor, porcentaje, page});
          },
          onDescargarInventario: (productosFiltrados, idUsuario, almacenesSeleccionados, subfamiliasSeleccionadas, rangoMayor, rangoMenor, porcentaje) => {
               dispatch( { type: 'LISTA_INVENTARIO_CICLICO_DESCARGA_REQUEST', productosFiltrados, idUsuario, almacenesSeleccionados, subfamiliasSeleccionadas, rangoMayor, rangoMenor, porcentaje});
           },
          onLimpiarTabla: () => {
               dispatch({ type: 'LISTA_INVENTARIO_CICLICO_SUCCESS', listadoInventarioCiclico: [] });
          }
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalProductos);
