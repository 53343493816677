import React, { Component } from 'react';
import { Form, Button, Row, Col, Modal, InputNumber, message, Input, Select, Divider } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Option } = Select;
const confirm = Modal.confirm;

class VerificarPonderador extends Component {
    formRef = React.createRef();
    state={
        addServicio: false,
    }
    render() {
        const {onCambiarVista, formularioPonderacionServ, listaAreasPorServicio, ponderacionServPorAreaSeleccionado,
            fetchingListaServiciosFiltro,
            listaServiciosFiltro,
            onListaServiciosFiltroLimpiar,
            onListaServiciosFiltro,
            onGetListaAreasPorServicio,
            onGuardarListaPonderadoresEditados,
            listaPonderacionServicioPorArea,
            onLimpiarListaPonderadoresServciosPorArea,
        } = this.props;

        const onFinish = formulario => {
            let listaPonderacion = [];
            let ponderacionEditados = [];
            let noIguales = true;
            for (var key in formulario) {
                if (Object.prototype.hasOwnProperty.call(formulario, key)) {
                    let idCampo = key.split('-');
                    if (idCampo.length === 2) {
                        let idPonderacionServArea = idCampo[1];
                        let campo = idCampo[0];
                        let valor = formulario[key];
    
                        let ponderacionFormulario = {[campo]: valor, 'idPonderacionServArea': parseInt(idPonderacionServArea), 'agregar': true};
                        listaPonderacion.push(ponderacionFormulario);
                    }
                }
            }
            
            for(let i=0; i<listaAreasPorServicio.length; i++){
                for(let x=0; x<listaPonderacion.length; x++){
                    if(listaAreasPorServicio[i].ponderacionServArea === listaPonderacion[x].ponderacionServAreaNuevo && listaAreasPorServicio[i].idPonderacionServArea === listaPonderacion[x].idPonderacionServArea){
                        if(ponderacionServPorAreaSeleccionado.idPonderacionServArea === listaPonderacion[x].idPonderacionServArea){
                            listaPonderacion[x].agregar = true;
                        }
                        else{
                            listaPonderacion[x].agregar = false;
                        }
                    }
                }
            }

            for(let i=0; i<listaAreasPorServicio.length; i++){
                for(let x=0; x<listaPonderacion.length; x++){
                    if(listaAreasPorServicio[i].ponderacionServArea !== listaPonderacion[x].ponderacionServAreaNuevo && listaPonderacion[x].agregar && listaAreasPorServicio[i].idPonderacionServArea === listaPonderacion[x].idPonderacionServArea ){
                        console.log('primer if')
                        if(this.props.ponderacionEditados[0]){
                            console.log('1.1 if')
                            for(let y=0; y<this.props.ponderacionEditados.length; y++){
                                console.log('this.props.ponderacionEditados[y].idPonderacionServArea');
                                console.log(this.props.ponderacionEditados[y].idPonderacionServArea);
                                console.log('listaPonderacion[x].idPonderacionServArea');
                                console.log(listaPonderacion[x].idPonderacionServArea);
                                if(this.props.ponderacionEditados[y].idPonderacionServArea === listaPonderacion[x].idPonderacionServArea){
                                    listaPonderacion[x].agregar = false;
                                }
                            }
                        }
                        if(!ponderacionEditados[0]){
                            console.log('segundo if')
                            if(listaPonderacion[x].agregar === true){
                                if(ponderacionServPorAreaSeleccionado.idPonderacionServArea === listaPonderacion[x].idPonderacionServArea){
                                    console.log('tercer if')
                                    if(formularioPonderacionServ.ponderacionServArea === listaPonderacion[x].ponderacionServAreaNuevo && listaPonderacion[x].agregar === true){
                                        console.log('cuarto if')
                                        listaPonderacion[x] = Object.assign(listaPonderacion[x], listaAreasPorServicio[i])
                                        listaPonderacion[x].areaFuncional = listaAreasPorServicio[i].areasFuncionalesEntityByIdAreaFuncional.codigoArea + ' - ' + listaAreasPorServicio[i].areasFuncionalesEntityByIdAreaFuncional.areaFuncional + ' - ' + listaAreasPorServicio[i].areasFuncionalesEntityByIdAreaFuncional.claveArea;
                                        listaPonderacion[x].servicio = listaAreasPorServicio[i].serviciosEntityByIdServicio.nombre;
                                        listaPonderacion[x].ponderacionServArea = listaPonderacion[x].ponderacionServAreaNuevo
                                        ponderacionEditados.push(listaPonderacion[x]);
                                        listaPonderacion[x].agregar = false;
                                        noIguales = false;
                                    }
                                }
                                else{
                                    console.log('else del tercer if')
                                    listaPonderacion[x] = Object.assign(listaPonderacion[x], listaAreasPorServicio[i])
                                    listaPonderacion[x].areaFuncional = listaAreasPorServicio[i].areasFuncionalesEntityByIdAreaFuncional.codigoArea + ' - ' + listaAreasPorServicio[i].areasFuncionalesEntityByIdAreaFuncional.areaFuncional + ' - ' + listaAreasPorServicio[i].areasFuncionalesEntityByIdAreaFuncional.claveArea
                                    listaPonderacion[x].servicio = listaAreasPorServicio[i].serviciosEntityByIdServicio.nombre;
                                    listaPonderacion[x].ponderacionServArea = listaPonderacion[x].ponderacionServAreaNuevo
                                    ponderacionEditados.push(listaPonderacion[x]);
                                }
                            }
                        }
                        console.log('ponderacionEditados antes del for de editados');
                        console.log(ponderacionEditados);
                        for(let y=0; y<ponderacionEditados.length; y++){
                            if(ponderacionEditados[y].idPonderacionServArea === listaPonderacion[x].idPonderacionServArea){
                                listaPonderacion[x].agregar = false;
                            }
                            else if (listaPonderacion[x].agregar){
                                if(ponderacionServPorAreaSeleccionado.idPonderacionServArea === listaPonderacion[x].idPonderacionServArea){
                                    if(formularioPonderacionServ.ponderacionServArea === listaPonderacion[x].ponderacionServAreaNuevo){
                                        listaPonderacion[x] = Object.assign(listaPonderacion[x], listaAreasPorServicio[i])
                                        listaPonderacion[x].areaFuncional = listaAreasPorServicio[i].areasFuncionalesEntityByIdAreaFuncional.codigoArea + ' - ' + listaAreasPorServicio[i].areasFuncionalesEntityByIdAreaFuncional.areaFuncional + ' - ' + listaAreasPorServicio[i].areasFuncionalesEntityByIdAreaFuncional.claveArea
                                        listaPonderacion[x].servicio = listaAreasPorServicio[i].serviciosEntityByIdServicio.nombre;
                                        listaPonderacion[x].ponderacionServArea = listaPonderacion[x].ponderacionServAreaNuevo
                                        ponderacionEditados.push(listaPonderacion[x]);
                                        listaPonderacion[x].agregar = false;
                                        noIguales = false;
                                    }
                                }
                                else{
                                    listaPonderacion[x] = Object.assign(listaPonderacion[x], listaAreasPorServicio[i])
                                    listaPonderacion[x].areaFuncional = listaAreasPorServicio[i].areasFuncionalesEntityByIdAreaFuncional.codigoArea + ' - ' + listaAreasPorServicio[i].areasFuncionalesEntityByIdAreaFuncional.areaFuncional + ' - ' + listaAreasPorServicio[i].areasFuncionalesEntityByIdAreaFuncional.claveArea
                                    listaPonderacion[x].servicio = listaAreasPorServicio[i].serviciosEntityByIdServicio.nombre;
                                    listaPonderacion[x].ponderacionServArea = listaPonderacion[x].ponderacionServAreaNuevo
                                    ponderacionEditados.push(listaPonderacion[x]);
                                    listaPonderacion[x].agregar = false;
                                }
                            }
                        } 
                    }
                }
            }
            let porcentaje = 0; 
            for(let q=0; q<listaPonderacion.length; q++){
                porcentaje += parseFloat(listaPonderacion[q].ponderacionServAreaNuevo);
            }
            if(this.props.ponderacionEditados[0]){
                noIguales = false;
            }
            if(noIguales){
                message.warning('El valor de la ponderacion de servicio ' + ponderacionServPorAreaSeleccionado.nombre + ' debe ser el mismo que ingresó al editar (' + formularioPonderacionServ.ponderacionServArea + ').')
                this.formRef.current.resetFields(['ponderacionServAreaNuevo-' + ponderacionServPorAreaSeleccionado.idPonderacionServArea]);
            }
            else if(porcentaje !== 100){
                message.warning('La suma de todos los ponderadores de servicio deben dar 100%, favor de verificar. Porcentaje actual: ' + porcentaje + '%')
            }
            else{
                const ponderacionEditadosViejos = this.props.ponderacionEditados;
                ponderacionEditados.push(...ponderacionEditadosViejos);
                
                confirm({
                    title: 'Estas son las ponderaciones de los servicios por área que fueron modificados. ¿Estás seguro de modificarlos?',
                    content: 
                    <div>
                        {ponderacionEditados.map(ponderacionEditado =>
                        <Row justify='space-around' key={ponderacionEditado.idPonderacionServArea}>
                            <Divider>{'Ponderación del Servicio por Área Funcional ' + ponderacionEditado.idPonderacionServArea}</Divider>
                            <Col span={8}>
                                <p><strong>Área Funcional: </strong>{ponderacionEditado.areaFuncional}</p>
                            </Col>
                            <Col span={8}>
                                <p><strong>Servicio: </strong>{ponderacionEditado.servicio}</p>
                            </Col>
                            <Col span={7}>
                                <p><strong>Ponderación por servicio: </strong>{ponderacionEditado.ponderacionServArea}%</p>
                            </Col>
                        </Row>
                        )}
                        <Col span={24} className='marginBoton'>
                        <br />
                        <Button className="btnFormato" style={{ width: '100%' }} onClick={() => onAddServicio(ponderacionEditados)}>Modificar Otro Servicio</Button>
                    </Col>
                    </div>,
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    width: '80%',
                    onOk() {
                        onCambiarVista(4, formularioPonderacionServ, ponderacionEditados);
                    },
                });
            }
        }
        

        const changeDecimales = (value, idPonderacionServArea) => {
            let valueDecimal = parseFloat(value).toFixed(1);
            let campo = 'ponderacionServAreaNuevo-' + idPonderacionServArea;
            if(isNaN(valueDecimal)){
                valueDecimal = 0.0
            }
            this.formRef.current.setFieldsValue({[campo]: valueDecimal});
        }
        const buscarServicios = value => {
            if(value.length>=3){
                if(value){
                    console.log(value);
                onListaServiciosFiltro(value)
                } else {
                    message.warning('No se encontró servicio con esas palabras')
                }
            } else {
               onListaServiciosFiltroLimpiar();
            } 
        };
        const onListaAreasPorServicio = (idServicio) => {
            onGetListaAreasPorServicio(idServicio, formularioPonderacionServ, ponderacionServPorAreaSeleccionado);
        }
        const onAddServicio = (ponderacionEditados) =>{
            console.log('ponderacionEditados');
            console.log(ponderacionEditados);
            onGuardarListaPonderadoresEditados(3, formularioPonderacionServ, ponderacionEditados);
            onLimpiarListaPonderadoresServciosPorArea();
            this.setState({
                addServicio: true,
            })
            Modal.destroyAll();
        }
        console.log('listaPonderacionServicioPorArea en verificarPonderador');
        console.log(listaPonderacionServicioPorArea);
        return (
            <div>
                {!this.state.addServicio ? (
                <p><strong>Servicio: </strong>{formularioPonderacionServ.nombre}</p>
                ):(
                <Col span={24}>
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Selecciona el servicio"
                        loading={fetchingListaServiciosFiltro}
                        showSearch
                        filterOption={false}
                        onSearch={buscarServicios}
                        notFoundContent={null}
                        onChange={onListaAreasPorServicio}
                    >
                        {listaServiciosFiltro.map(option => <Option key={option.idServicio}>{option.nombre}</Option>)} */}
                        </Select>
                </Col>
                )}
                {listaPonderacionServicioPorArea.good ? (
                <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    initialValues={listaPonderacionServicioPorArea}
                    ref={this.formRef}
                >
                    {listaAreasPorServicio.map(servicioEnArea => 
                    <Row justify='space-around' key={servicioEnArea.idPonderacionServArea} >
                        <Divider>{'Ponderación del Servicio por Área Funcional ' + servicioEnArea.idPonderacionServArea}</Divider>
                        <Col span={7}>
                            <Form.Item
                                label="Ponderacion por Servicio"
                                name={'ponderacionServAreaNuevo-' + servicioEnArea.idPonderacionServArea}
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <InputNumber min={1} max={100} placeholder='Ingrese ponderacion'
                                    onChange={value => changeDecimales(value, servicioEnArea.idPonderacionServArea)}
                                    formatter={value => `${value}%`}
                                    parser={value => value.replace('%', '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={15}>
                            <Form.Item
                                label="Área funcional"
                                name='areaFuncional'
                            >
                                <Input disabled={true} placeholder={servicioEnArea.areasFuncionalesEntityByIdAreaFuncional.codigoArea + ' - ' + servicioEnArea.areasFuncionalesEntityByIdAreaFuncional.areaFuncional + ' - ' + servicioEnArea.areasFuncionalesEntityByIdAreaFuncional.claveArea} ></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    )}
                    <Col span={24} className='marginBoton'>
                        <br />
                        <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Guardar Cambios</Button>
                    </Col>
                </Form>
                ):false}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        formularioPonderacionServ: state.MecanismosDePagosReducer.formularioPonderacionServ,
        listaAreasPorServicio: state.MecanismosDePagosReducer.listaAreasPorServicio,
        ponderacionServPorAreaSeleccionado: state.MecanismosDePagosReducer.ponderacionServPorAreaSeleccionado,
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
        ponderacionEditados: state.MecanismosDePagosReducer.ponderacionEditados,
        listaPonderacionServicioPorArea: state.MecanismosDePagosReducer.listaPonderacionServicioPorArea,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCambiarVista: (vistaModalPonderacionServPorArea, formularioPonderacionServ, ponderacionEditados) => {
            dispatch({ type: 'CAMBIAR_VISTA_PONDERACION_SERV_POR_AREA', vistaModalPonderacionServPorArea, formularioPonderacionServ, ponderacionEditados, listaAreasPorServicio: [] })
        },
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
        },
        onGetListaAreasPorServicio: (idServicio, formularioPonderacionServ, ponderacionServPorAreaSeleccionado) => {
            dispatch({ type: 'LISTA_AREAS_POR_SERVICIO_REQUEST', idServicio, formulario: formularioPonderacionServ, ponderacionServPorAreaSeleccionado });
        },
        onGuardarListaPonderadoresEditados: (vistaModalPonderacionServPorArea, formularioPonderacionServ, ponderacionEditados) => {
            dispatch({ type: 'CAMBIAR_VISTA_PONDERACION_SERV_POR_AREA', vistaModalPonderacionServPorArea, formularioPonderacionServ, ponderacionEditados, listaAreasPorServicio: [] })
        },
        onLimpiarListaPonderadoresServciosPorArea: () => {
            dispatch({ type: 'LIMPIAR_LISTA_PONDERADORES_SERVICIO_AREA' })
        }
    }
}
 
export default connect(mapStateToProps,mapDispatchToProps)(VerificarPonderador);