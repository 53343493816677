export const CAMBIAR_CATALOGO_BASE_CONOCIMIENTO = 'CAMBIAR_CATALOGO_BASE_CONOCIMIENTO';

//BASE DE CONOCIMIENTO
export const LISTA_BASE_CONOCIMIENTO_REQUEST = 'LISTA_BASE_CONOCIMIENTO_REQUEST';
export const LISTA_BASE_CONOCIMIENTO_SUCCESS = 'LISTA_BASE_CONOCIMIENTO_SUCCESS';
export const LISTA_BASE_CONOCIMIENTO_FAILURE = 'LISTA_BASE_CONOCIMIENTO_FAILURE';

export const NUEVO_DOCUMENTO_BASE_REQUEST = 'NUEVO_DOCUMENTO_BASE_REQUEST';
export const NUEVO_DOCUMENTO_BASE_SUCCESS = 'NUEVO_DOCUMENTO_BASE_SUCCESS';
export const NUEVO_DOCUMENTO_BASE_FAILURE = 'NUEVO_DOCUMENTO_BASE_FAILURE';

export const EDITAR_DOCUMENTO_BASE_REQUEST = 'EDITAR_DOCUMENTO_BASE_REQUEST';
export const EDITAR_DOCUMENTO_BASE_SUCCESS = 'EDITAR_DOCUMENTO_BASE_SUCCESS';
export const EDITAR_DOCUMENTO_BASE_FAILURE = 'EDITAR_DOCUMENTO_BASE_FAILURE';

export const SHOW_MODAL_NUEVO_DOCUMENTO_BASE = 'SHOW_MODAL_NUEVO_DOCUMENTO_BASE';
export const SHOW_MODAL_EDITAR_DOCUMENTO_BASE = 'SHOW_MODAL_EDITAR_DOCUMENTO_BASE';

export const EXPORTAR_EXCEL_REQUEST = 'EXPORTAR_EXCEL_REQUEST';
export const EXPORTAR_EXCEL_SUCCESS = 'EXPORTAR_EXCEL_SUCCESS';
export const EXPORTAR_EXCEL_FAILURE = 'EXPORTAR_EXCEL_FAILURE';

export const SHOW_MODAL_DOCUMENTO = 'SHOW_MODAL_DOCUMENTO';