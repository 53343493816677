import React, { Component } from 'react';
import { Form, Input, Button, Row, Col, Spin, Modal, Radio, Select } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';
const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalEditarTipoFolio extends Component {
    formRef = React.createRef();

    render() {

        const { fetchingEditarTipoFolioMovimiento, showModalEditarTipoFolioMovimiento, onEditarTipoFolioMovimiento, onShowModalEditarTipoFolioMovimiento,
            TipoFolioMovimientoSeleccionado, page, limpiarFormulario } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onEditarTipoFolioMovimiento(formulario, TipoFolioMovimientoSeleccionado, page);
        };

        if (limpiarFormulario && limpiarFormulario==='editarTipoFolio') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        

        const handleCancel = () => {
            onShowModalEditarTipoFolioMovimiento();
        }

        return (
            <Modal
                visible={showModalEditarTipoFolioMovimiento}
                title='Editar Tipo de Folio de Movimiento'
                onCancel={handleCancel}
                footer={false}>
                <Spin spinning={fetchingEditarTipoFolioMovimiento}>
                    {TipoFolioMovimientoSeleccionado ? (
                        <Form {...layout}
                            name="formulario"
                            initialValues={TipoFolioMovimientoSeleccionado}
                            onFinish={onFinish}
                            
                            ref={this.formRef}
                        >
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                <Input
                                    pattern="^^[ñíóáéú a-zA-Z]+$"
                                    title="Nombre debe tener letras."
                                    maxLength={50} placeholder='Nombre tipo de folio' />
                            </Form.Item>
                            <Form.Item
                                label="Nomenclatura"
                                name="nomenclatura"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                <Input
                                    pattern="^^[Ñ A-Z0-9]+$"
                                    title="Nomenclaruta debe tener solo mayúsculas."
                                    maxLength={4} placeholder='Nomenclatura' />
                            </Form.Item>
                            <Form.Item
                                label="Operación al inventario"
                                name="operacion"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Seleccione el tipo de folio">
                                    <Option value="Suma">Suma</Option>
                                    <Option value="Resta">Resta</Option>
                                    <Option value="Ambas">Ambas</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Requiere folio origen "
                                name="folioOrigen"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                <Radio.Group>
                                    <Radio value={true}>Si</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Row justify='space-around'>
                                <Col span={24}>
                                    <Button style={{width:'100%'}} className="btnFiltrar" htmlType="submit">Editar Tipo de Folio de Movimiento</Button>
                                </Col>
                            </Row>
                        </Form>
                    ) : false}
                </Spin>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingEditarTipoFolioMovimiento: state.AlmacenesReducer.fetchingEditarTipoFolioMovimiento,
        showModalEditarTipoFolioMovimiento: state.AlmacenesReducer.showModalEditarTipoFolioMovimiento,
        TipoFolioMovimientoSeleccionado: state.AlmacenesReducer.TipoFolioMovimientoSeleccionado,
        page: state.AlmacenesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarTipoFolioMovimiento: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_TIPO_FOLIO', showModalEditarTipoFolioMovimiento: false, TipoFolioMovimientoSeleccionado: null })
        },
        onEditarTipoFolioMovimiento: (formulario, TipoFolioMovimientoSeleccionado, page) => {
            dispatch({ type: 'EDITAR_TIPO_FOLIO_REQUEST', formulario: formulario, TipoFolioMovimientoSeleccionado: TipoFolioMovimientoSeleccionado, page:page, })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarTipoFolio);