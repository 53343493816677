import React, { Component } from 'react';
import { Row, Form, Input, Button, Modal, Col, Spin, Switch, Select, message, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Option } = Select;
class ModalNuevaFalla extends Component {
    formRef = React.createRef();
    state={
        tipo: null,
        tipoOk: false,
        servicio: null,
        servicioOk: false,
    }
    render() {
        const { fetchingNuevaFalla, showModalNuevaFalla, onNuevaFalla, onShowModalNuevaFalla,
            limpiarFormulario, onLimpiarFormulario, onListaServiciosFiltro, onListaServiciosFiltroLimpiar, 
            onListaEstandarFiltro, onListaEstandarFiltroLimpiar, fetchingListaTiposFallasActivas, listaTiposFallasActivas,
            fetchingListaServiciosFiltro, listaServiciosFiltro, fetchingListaEstandaresFiltro, listaEstandaresFiltro,
            onObtenerPartidasByidServicio, listaPartidasByServicio4Fallas, fetchingListaPartidasByServicio4Fallas, onLimpiarPartidas
        } = this.props;

        const onFinish = formulario => {
            console.log('formulario');
            console.log(formulario);
            onNuevaFalla(formulario, this.props.page, this.props.busqueda, listaTiposFallasActivas);
        };

        if (limpiarFormulario && limpiarFormulario === 'crearFalla') {
            this.formRef.current.resetFields();
            onLimpiarFormulario();
            onListaEstandarFiltroLimpiar();
            onListaServiciosFiltroLimpiar();
            onLimpiarPartidas();
        }

        const handleCancel = () => {
            onShowModalNuevaFalla(this.props.page, this.props.busqueda);
            this.formRef.current.resetFields();
            onLimpiarPartidas();
            onListaServiciosFiltroLimpiar();
            onListaEstandarFiltroLimpiar();
        }

        const buscarServicios = value => {
            if (value.length >= 3) {
                if (value) {
                    console.log(value);
                    onListaServiciosFiltro(value)
                } else {
                    message.warning('No se encontró servicio con esas palabras');
                }
            } else {
                onListaServiciosFiltroLimpiar();
            }
        }
        const buscarEstandar = value => {
            if (value.length >= 3) {
                onListaEstandarFiltro(value, this.state.tipo, this.state.servicio)
            } else {
                onListaEstandarFiltroLimpiar();
            }
        }
        const formularioInicial = {
            'conversion': true,
            'visible': true,
        }
        const servicioSeleccionado = value => {
            onListaEstandarFiltroLimpiar();
            let servSeleccionado = []
            for(let i in listaServiciosFiltro){
                if(listaServiciosFiltro[i].idServicio === parseInt(value)){
                    servSeleccionado = listaServiciosFiltro[i];
                }
            }

            if(value){
                if(servSeleccionado.proveedorByServicio){
                    this.setState({
                        servicio: value, 
                        servicioOk: true,
                    })
                    this.formRef.current.resetFields(['idEstandarDesempeno', 'idsPartidas']);
                    onObtenerPartidasByidServicio(value);
                } else {
                    message.warning('Es necesario asignar el servicio a un proveedor');
                    this.setState({
                        servicio: value, 
                        servicioOk: true,
                    })
                    this.formRef.current.resetFields(['idEstandarDesempeno', 'idsPartidas']);
                    onObtenerPartidasByidServicio(value);
                }
            }
            else{
                this.setState({
                    servicioOk: false,
                })
            }
        }
        const onCategoriaFalla = value => {
            onListaEstandarFiltroLimpiar();
            
            if(value){
                for(let i=0; i<listaTiposFallasActivas.length; i++){
                    if(value.split(' (')[0] === listaTiposFallasActivas[i].clave + ' - ' + listaTiposFallasActivas[i].nombre){
                        this.setState({
                            tipo: listaTiposFallasActivas[i].tipo, 
                        })
                    }
                }
                this.formRef.current.resetFields(['idEstandarDesempeno']);
                this.setState({
                    tipoOk: true,
                })
            }
            else{
                this.setState({
                    tipoOk: false,
                })
            }
        }
        return (
            <Modal
                visible={showModalNuevaFalla}
                title='Nueva Falla'
                onCancel={handleCancel}
                footer={false}
                width='60%'
            >
                <Spin spinning={fetchingNuevaFalla}>
                    <Form {...layout}
                        name="formulario"
                        onFinish={onFinish}
                        ref={this.formRef}
                        initialValues={formularioInicial}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Nombre falla"
                                    name="nombre"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={250} placeholder="Ingresa el nombre de falla"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Ingresa el servicio a buscar"
                                    name="idServicio"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Select
                                        style={{ width: '100%', textAlign: 'left' }}
                                        placeholder="Ingresa el servicio a buscar"
                                        loading={fetchingListaServiciosFiltro}
                                        showSearch
                                        filterOption={false}
                                        onSearch={buscarServicios}
                                        onChange={servicioSeleccionado}
                                        notFoundContent={null}
                                    >
                                        {listaServiciosFiltro.map(option => 
                                        <Option key={option.idServicio}>
                                            <Tooltip placement="topLeft" title={option.nomenclatura + ' - ' + option.nombre}>
                                                {option.nomenclatura + ' - ' + option.nombre}
                                            </Tooltip>
                                        </Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            { listaPartidasByServicio4Fallas ? (<>
                            { listaPartidasByServicio4Fallas[0] ? (<Col span={11}>
                                <Form.Item
                                    label="Selecciona una o varias partidas"
                                    name="idsPartidas"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Select
                                        showSearch={true}
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona una o varias partidas"
                                        loading={fetchingListaPartidasByServicio4Fallas}
                                    >
                                        {listaPartidasByServicio4Fallas.map(option =>
                                            <Option key={option.idPartida + ' - ' + option.nomenclatura  + ' (' + option.nombre + ')'}>
                                                {option.idPartida + ' - ' + option.nomenclatura  + ' (' + option.nombre + ')'}
                                            </Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>):false }
                            </>):false }
                            <Col span={11}>
                                <Form.Item
                                    label="Selecciona el tipo de falla"
                                    name="idTipoFalla"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Select
                                        style={{ width: '100%', textAlign: 'left' }}
                                        placeholder="Selecciona el tipo de falla"
                                        loading={fetchingListaTiposFallasActivas}
                                        notFoundContent={null}
                                        onChange={onCategoriaFalla}
                                        showSearch
                                    >
                                        {listaTiposFallasActivas.map(option => 
                                        <Option key={option.clave + ' - ' + option.nombre + ' (' + option.tipo + ')'}>
                                            <Tooltip placement="topLeft" title={option.clave + ' - ' + option.nombre + ' (' + option.tipo + ')'}>
                                                {option.clave + ' - ' + option.nombre + ' (' + option.tipo + ')'}
                                            </Tooltip>
                                        </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>

                            {this.state.tipoOk && this.state.servicioOk ? (
                            <Col span={11}>
                                <Form.Item
                                    label="Ingresa el estándar de desempeño a buscar"
                                    name="idEstandarDesempeno"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Select
                                        style={{ width: '100%', textAlign: 'left' }}
                                        placeholder="Ingresa el estándar de desempeño a buscar"
                                        loading={fetchingListaEstandaresFiltro}
                                        showSearch
                                        filterOption={false}
                                        onSearch={buscarEstandar}
                                        notFoundContent={null}
                                    >
                                        {listaEstandaresFiltro.map(option => 
                                        <Option key={option.idEstandarDesempeno}>
                                            <Tooltip placement="topLeft" title={option.referencia + ' - ' + option.descripcion}>
                                                {option.referencia + ' - ' + option.descripcion}
                                            </Tooltip>
                                        </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            ):false}
                            <Col span={5}>
                                <Form.Item
                                label='Conversión'
                                name='conversion'
                                valuePropName="checked"
                                >
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                    />                                            
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                label='Visible'
                                name='visible'
                                valuePropName="checked"
                                >
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                    />                                            
                                </Form.Item>
                            </Col>
                           
                            <Col span={11} className='marginBoton'>
                                <br />
                                <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Crear Falla</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingNuevaFalla: state.SolicitudDeServiciosReducer.fetchingNuevaFalla,
        showModalNuevaFalla: state.SolicitudDeServiciosReducer.showModalNuevaFalla,
        page: state.SolicitudDeServiciosReducer.page,
        busqueda: state.SolicitudDeServiciosReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaTiposFallasActivas: state.SolicitudDeServiciosReducer.fetchingListaTiposFallasActivas,
        listaTiposFallasActivas: state.SolicitudDeServiciosReducer.listaTiposFallasActivas,
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
        fetchingListaEstandaresFiltro: state.MecanismosDePagosReducer.fetchingListaEstandaresFiltro,
        listaEstandaresFiltro: state.MecanismosDePagosReducer.listaEstandaresFiltro,
        listaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.listaPartidasByServicio4Fallas,
        fetchingListaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.fetchingListaPartidasByServicio4Fallas,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevaFalla: (page, busqueda) => {
            dispatch({ type: 'SHOW_MODAL_NUEVA_FALLA', showModalNuevaFalla: false, page, busqueda })
        },
        onNuevaFalla: (formulario, page, busqueda, listaTiposFallasActivas) => {
            dispatch({ type: "NUEVA_FALLA_REQUEST", formulario, page, busqueda, listaTiposFallasActivas });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
        },
        onListaEstandarFiltro: (param, tipo, idServicio) => {
            dispatch({ type: "LISTA_ESTANDARES_FILTRO_REQUEST", param, tipo, idServicio})
        },
        onListaEstandarFiltroLimpiar: () => {
            dispatch({ type: "LISTA_ESTANDARES_FILTRO_SUCCESS", listaEstandaresFiltro: []})
        },
        onObtenerPartidasByidServicio: (idsServicios) => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_REQUEST', idsServicios });
        },
        onLimpiarPartidas: () => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_SUCCESS', listaPartidasByServicio4Fallas: []  });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevaFalla);