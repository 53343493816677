import React, { Component } from 'react';
import { Form, Button, Row, Col, Spin, Table, Input, Divider } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import { FieldNumberOutlined, SearchOutlined } from '@ant-design/icons';

const { Search } = Input;

const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };

class ModalAsignarActivos extends Component {
    formRef = React.createRef();
    
    componentDidMount() {
        
    }

     state={
          page: 1,
          busqueda: '',
          selectedRowKeys: [],
          selectedRows: [],
          datosSolicitudSeleccionados: [],
          recordLocal: [],
          activosSeleccionadosTabla: [],
          hasSelected: true
     }

     render() {
          const { 
               onShowModalSalidaDeBienes, 
               showModalSalidaDeBienes,
               fetchingNuevaSalidaDeBienes,
               limpiarFormulario,
               
               listaSalidaDeBienesBusqueda,
               fetchingListaSalidaDeBienesBusqueda,
               listaSalidaDeBienesBusquedaCantidad,
               salidaActivoSeleccionado,

               onObtenerActivosPorServicio,
               onCambiarVista,

               tipoMovimiento,
               motivoSalida,
               servicioSalida,
               idServicioSalida,

               selectedRowKeys,
               onActualizarTabla,
               hasSelected,
               page,
               listaEstatusActivoActivos,
               listaAsignacionActivosActivos
          } = this.props;

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');

          if(permisosUsuario[0]){
               permisosUsuario = JSON.parse(permisosUsuario);
          }

          let userInfo = sessionStorage.getItem('userInfo');
          if(!userInfo.idUsuario){ userInfo = JSON.parse(userInfo); }

          const onFinish = formulario => {
               console.log('formulario', formulario)
               console.log('Activos seleccionados on finish: ', this.state.selectedRows);
               //onCambiarVista(2, formulario, salidaActivoSeleccionado, this.state.page);
          };

          if (limpiarFormulario && limpiarFormulario === 'crearSolicitudDeSalida') {
               this.setState({ hasSelected: true });
               this.props.onLimpiarFormulario();
               //this.formRef.current.resetFields();
          }
           
          const onFinishFailed = errorInfo => {
               console.log('Failed:', errorInfo);
          };

          const handleTableChange = (pagination) => {
               onObtenerActivosPorServicio(idServicioSalida, pagination.current, this.state.busqueda);
               this.setState({page: pagination.current});
               onActualizarTabla( salidaActivoSeleccionado, selectedRowKeys, hasSelected, pagination.current );
          }
          
          const onChangeTabla = (record, selected, selectedRows) => {
               console.log('record: ', record);
               console.log('page', page)
               console.log('selected: ', selected);
               let hasSelected = true;

               if(record.length > 0 && record !== 'undefined'){
               // Array.isArray(array) && array.length
                   hasSelected = false;
               }else{
                   hasSelected = true;
               }
     
               this.setState({ selectedRowKeys: record, selectedRows: selected, hasSelected: hasSelected, page: page });
               onActualizarTabla( selected, record, hasSelected, page );           
          };

          const rowSelection = {
               selectedRowKeys,
               onChange: onChangeTabla,
               fixed: true,
               columnWidth: '60px',             
          };
          

          const columns = [
               { title: 'Descripción', dataIndex: 'descripcion', key: 'descripcion',width:'12%' },
               { title: 'Código de Equipo', dataIndex: 'codigoEquipo', key: 'codigoEquipo', width:'12%' },
               { title: 'Código Interno', dataIndex: 'codigoInterno', key: 'codigoInterno', width:'8%' },
               { title: 'Num Serie', dataIndex: 'serieNum', key: 'serieNum', width:'8%' },
               { title: 'SKU', dataIndex: 'sku', key: 'sku', width:'9%' },
               { title: 'Estatus', dataIndex: 'estadoActivo', key: 'estadoActivo', width:'9%' },
               { title: 'Clave Ubicación', dataIndex: 'claveUbicacion', key: 'claveUbicacion', width:'10%' },
               { title: 'Área Funcional', dataIndex: 'areaFuncional', key: 'areaFuncional', width:'12%' },
               { title: 'Unidad Funcional', dataIndex: 'unidadFuncional', key: 'unidadFuncional', width:'12%' },
               { title: 'Interior Funcional', dataIndex: 'interiorFuncional', key: 'interiorFuncional', width:'12%' },
               { title: 'Subinterior Funcional', dataIndex: 'subInteriorFuncional', key: 'subInteriorFuncional', width:'12%' }
          ];

          const onBuscar = (value) => {
               this.setState({ busqueda: value, page: 1 });
               console.log('busqueda en onBuscar: ', value);
               
               onObtenerActivosPorServicio(idServicioSalida, 1, value);
          };

          const onAgregarActivo = () => {
               onCambiarVista(2, salidaActivoSeleccionado, 1);
          };

          return (
               <div>
                    <Spin 
                    spinning={fetchingNuevaSalidaDeBienes}
                    >
                         <Form {...layout}
                         name="formulario"
                         onFinish={onFinish}
                         
                         ref={this.formRef}
                         >
                              <Row align="middle" justify="center" gutter={[8,8]}>
                                   <Col span={4}>
                                        <Form.Item
                                        //name='FolioSalida'
                                        label='Folio de Salida'
                                        >
                                             <Input
                                             disabled={true}
                                             // prefix={<FieldNumberOutlined />}
                                             value={tipoMovimiento}
                                             addonBefore={<FieldNumberOutlined />}
                                             />
                                        </Form.Item>
                                   </Col>
                                   <Col span={12}>
                                        <Form.Item
                                        //name="idServicio"
                                        label="Servicio"
                                        rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                        
                                        >
                                             <Input
                                             disabled={true}
                                             value={servicioSalida}
                                             />
                                        </Form.Item>                   
                                   </Col>
                                   <Col span={8}>
                                        <Form.Item
                                        label="Motivos de Salida: "
                                        //name="idMotivoSalida"
                                        rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                        >
                                             <Input
                                             disabled={true}
                                             value={motivoSalida}
                                             />
                                        </Form.Item>
                                   </Col>
                              </Row>

                              <Divider orientation="left"> Agregar Activo para Salida </Divider>

                         <Row justify='space-around'>
                              <Col span={23}>
                                   <Search 
                                   className='busqueda' 
                                   placeholder="Ingresa la descripción, código interno o código del equipo a buscar" 
                                   onSearch={value => onBuscar(value)} 
                                   style={{ width: '100%' }}
                                   // enterButton='Buscar'
                                   enterButton={<SearchOutlined />}
                                   />
                              </Col>
                         </Row>
                         <br/>
                              <Row justify='space-around'>
          
                                   <Col span={23}>
                                        <Form.Item
                                             name="activosSeleccionados"
                                        >
                                             <Table 
                                                  size="small" 
                                                  rowKey="idActivo" 
                                                  columns={columns}
                                                  rowSelection={rowSelection}
                                                  dataSource={listaSalidaDeBienesBusqueda}
                                                  loading={fetchingListaSalidaDeBienesBusqueda}
                                                  locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar'}}
                                                  onChange={handleTableChange}
                                                  pagination={{ total: listaSalidaDeBienesBusquedaCantidad, simple: true, current: this.state.page }}
                                                  scroll={{ x: 1500 }}
                                                  showSorterTooltip={true}
                                             />  
                                        </Form.Item>
                                   </Col>
                                   

                                   <Col span={11}>
                                        <br/>
                                        <Button onClick={onAgregarActivo} className="btnFiltrar" style={{width: '100%'}} disabled={hasSelected} >Agregar Activo</Button> 
                                   </Col>
                              </Row>
                         </Form>
                    </Spin>
               </div>
          );   
     }
}

const mapStateToProps = state => {
	return {
          fetchingNuevaSalidaDeBienes: state. AlmacenesReducer.fetchingNuevaSalidaDeBienes,
          showModalSalidaDeBienes: state.AlmacenesReducer.showModalSalidaDeBienes,
          page: state.AlmacenesReducer.page,
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          listaUsuariosActivos: state.AlmacenesReducer.listaUsuariosActivos,
          fetchingListaUsuariosActivos: state.AlmacenesReducer.fetchingListaUsuariosActivos,
          listaAsignacionActivosActivos: state.AlmacenesReducer.listaAsignacionActivosActivos,
          fetchingListaAsignacionActivosActivos: state.AlmacenesReducer.fetchingListaAsignacionActivosActivos,

          listaSalidaDeBienesBusqueda: state.AlmacenesReducer.listaSalidaDeBienesBusqueda,
          fetchingListaSalidaDeBienesBusqueda: state.AlmacenesReducer.fetchingListaSalidaDeBienesBusqueda, 
          listaSalidaDeBienesBusquedaCantidad: state.AlmacenesReducer.listaSalidaDeBienesBusquedaCantidad,

          tipoMovimiento: state.AlmacenesReducer.tipoMovimiento,
          motivoSalida: state.AlmacenesReducer.motivoSalida,
          servicioSalida: state.AlmacenesReducer.servicioSalida,
          idServicioSalida: state.AlmacenesReducer.idServicioSalida,
          datosSolicitudSeleccionados: state.AlmacenesReducer.datosSolicitudSeleccionados,
          salidaActivoSeleccionado: state.AlmacenesReducer.salidaActivoSeleccionado,
          selectedRowKeys: state.AlmacenesReducer.selectedRowKeys,
          hasSelected: state.AlmacenesReducer.hasSelected,
          listaActivosActivos: state.AlmacenesReducer.listaActivosActivos,
          listaEstatusActivoActivos: state.AlmacenesReducer.listaEstatusActivoActivos
	};
};

const mapDispatchToProps = dispatch => {
	return {
          onObtenerActivosPorServicio: (idServicio, page, busqueda) => {
               dispatch({ type: "LISTA_SALIDA_BIENES_BUSQUEDA_REQUEST", idServicio: idServicio, page: page, busqueda: busqueda });
          },
          onCambiarVista: (vistaModalSalidaDeBienes, salidaActivoSeleccionado) => {
               dispatch({ type: 'CAMBIAR_VISTA_SALIDA_BIENES', vistaModalSalidaDeBienes: vistaModalSalidaDeBienes, salidaActivoSeleccionado: salidaActivoSeleccionado })
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
          onGetUsuarioRes: () => {
               dispatch({ type: 'LISTA_USUARIOS_ACTIVOS_REQUEST', });
          },
          onActualizarTabla: (salidaActivoSeleccionado, selectedRowKeys, hasSelected, page) => {
               dispatch({ type: 'ACTUALIZAR_TABLA_SALIDA', salidaActivoSeleccionado: salidaActivoSeleccionado, selectedRowKeys: selectedRowKeys, hasSelected, page})
          },
          onListaServiciosFiltroLimpiar: () => {
               dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
          },
          onListaMotivosActivosLimpiar: () => {
               dispatch({ type: "MOTIVOS_DE_SALIDA_ACTIVOS_SUCCESS", listaMotivosSalidaActivos: [] });
          },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAsignarActivos);