import * as AlmacenesActions from '../actions/AlmacenesActions';

const initialState = {
     page: 1,
     orden: {},
     busqueda: '',
     catalogoAlmacenes: null,

     //Reducers Categorías Unidades de Medición
     fetchingListaCategoriasDeMedicionBusqueda: false,
     listaCategoriasDeMedicionBusqueda: [],
     listaCategoriasDeMedicionBusquedaCantidad: null,
     fetchingListaCategoriasDeMedicionActivos: false,
     listaCategoriasDeMedicionActivos: [],

     showModalNuevaCategoriaDeMedicion: false,
     showModalEditarCategoriaDeMedicion: false,
     CategoriaDeMedicionSeleccionado: null,

     fetchingUnidadesMediPorCategoria: false,
     unidadesMediPorCategoria: [],
     editarVidaUtil: true,

     fetchingNuevaCategoriaDeMedicion: false,
     fetchingEditarCategoriaDeMedicion: false,
     fetchingExportarExcelCategoriasMedicion: false,

     //Reducers Unidades de Medicion
     fetchingListaUnidadesDeMedicionBusqueda: false,
     listaUnidadesDeMedicionBusqueda: [],
     listaUnidadesDeMedicionBusquedaCantidad: null,
     fetchingListaUnidadesDeMedicionActivos: false,
     listaUnidadesDeMedicionActivos: [],

     showModalNuevaUnidadDeMedicion: false,
     showModalEditarUnidadDeMedicion: false,
     UnidadDeMedicionSeleccionado: null,

     fetchingNuevaUnidadDeMedicion: false,
     fetchingEditarUnidadDeMedicion: false,
     fetchingExportarExcelUnidadesMedicion: false,

     //Reducers de Activos Características
     fetchingListaActivosCaract: false,
     listaActivosCaract: [],

     fetchingListaActivosCaractBusqueda: false,
     listaActivosCaractBusqueda: [],
     listaActivosCaractBusquedaCantidad: null,

     showModalNuevoActivoCaract: false,
     showModalEditarActivoCaract: false,
     activoCaractSeleccionado: null,

     fetchingNuevoActivoCaract: false,
     fetchingEditarActivoCaract: false,

     fetchingListaActivosCaractFiltro: false,
     listaActivosCaractFiltro: [],

     fetchingListaActivosCaractFiltroAsignacionSP: false,
     listaActivosCaractFiltroAsignacionSP: [],

     fetchingExportarExcelCaracteristicasActivos: false,

     //Reducers de Catálogo de Activos 
     fetchingListaActivos: false,
     listaActivos: [],

     fetchingListaActivosBusqueda: false,
     listaActivosBusqueda: [],
     listaActivosBusquedaCantidad: null,

     showModalNuevoActivo: false,
     showModalEditarActivo: false,
     activoSeleccionado: null,

     fetchingNuevoActivo: false,
     fetchingEditarActivo: false,

     fetchingListaArchivosEspera: false,
     listaArchivosEspera: [],
     listaArchivosEsperaCantidad: 0,

     fetchingSubirArchivoActivo: false,
     fetchingBorrarArchivoActivo: false,
     fetchingExportarExcelActivos: false,

     //Reducers de Asignación de Activos
     fetchinglistaAsignacionActivosBusqueda: false,
     listaAsignacionActivosBusqueda: [],
     listaAsignacionActivosBusquedaCantidad: null,

     showModalAsignarActivos: false,
     AsignacionActivoSeleccionado: [],

     fetchingListaAreasFuncionalesActivas: false,
     listaAreasFuncionalesActivas: [],

     fetchingListaAreasFuncionalesActivasEx: false,
     listaAreasFuncionalesActivasEx: [],

     fetchingNuevaAsignacionActivos: false,

     fetchingListaUsuariosActivos: false,
     listaUsuariosActivos: [],

     fetchingListaSubinteriorFuncional: false,
     listaSubinteriorFuncional: [],

     fetchingListaInteriorFuncional: false,
     listaInteriorFuncional: [],

     fetchingListaSubinteriorFuncionalEx: false,
     listaSubinteriorFuncionalEx: [],

     fetchingListaInteriorFuncionalEx: false,
     listaInteriorFuncionalEx: [],

     fetchingListaUnidadesFuncionalesActivas: false,
     showAlertMsg:false,
     listaUnidadesFuncionalesActivas: [],

     fetchingListaUnidadesFuncionalesActivasEx: false,
     listaUnidadesFuncionalesActivasEx: [],
     showAlertMsgEx: false,

     //Reducers Rangos Renovación para Activo Fijo
     fetchingListaRangosRenovacionActivoBusqueda: false,
     listaRangosRenovacionActivoBusqueda: [],
     listaRangosRenovacionActivoBusquedaCantidad: null,

     fetchingListaRangosRenovacionActivoActivos: false,
     listaRangosRenovacionActivoActivos: [],

     showModalNuevoRangoRenovacionActivo: false,
     showModalEditarRangoRenovacionActivo: false,
     RangoRenovacionActivoSeleccionado: null,

     fetchingNuevoRangosRenovacionActivo: false,
     fetchingEditarRangosRenovacionActivo: false,
     fetchingExportarExcelRangosRenovacionActivo: false,

     //Reducers Catálogo Estatus del Activo
     fetchingListaEstatusActivoBusqueda: false,
     listaEstatusActivoBusqueda: [],
     listaEstatusActivoBusquedaCantidad: null,

     fetchingListaEstatusActivoActivos: false,
     listaEstatusActivoActivos: [],

     showModalNuevoEstatusActivo: false,
     showModalEditarEstatusActivo: false,
     EstatusActivoSeleccionado: null,

     fetchingNuevoEstatusActivo: false,
     fetchingEditarEstatusActivo: false,
     fetchingExportarExcelEstatusActivo: false,

     // Reducers Motivos de Salida
     fetchingListaMotivosSalidaBusqueda: false,
     listaMotivosSalidaBusqueda: [],
     listaMotivosSalidaBusquedaCantidad: null,
     listaMotivosSalidaActivos: [],
     fetchingListaMotivosSalidaActivos: false,

     showModalNuevoMotivoSalida: false,
     showModalEditarMotivoSalida: false,
     motivoSeleccionado: null,

     fetchingNuevoMotivoSalida: false,
     fetchingEditarMotivoSalida: false,
     fetchingExportarExcelMotivosSalidaActivo: false,

     // Reducers Reingreso de activos
     fetchinglistaReingresoActivosFoliosAct: false,
     listaReingresoActivosFoliosAct: [],

     fetchinglistaReingresoActivosBusqueda: false,
     listaReingresoActivosBusqueda: [],
     listaReingresoActivosBusquedaCantidad: null,

     fetchingNuevoReingresoActivo: false,
     showModalReingresoActivos: false,
     reingresoSeleccionado: [],

     //Reducers Actualización tecnológica
     fetchingListaActualizacionActivosBusqueda: false,
     listaActualizacionActivosBusqueda: [],
     listaActualizacionActivosBusquedaCantidad: null,

     showModalDetalleActivo: false,
     ActualizacionActivoSeleccionado: null,
     fetchingActualizacionActivosDescarga: false,

     //
     selectedRowKeys: [],
     selectedRow: false,

     asignacionPDF: null,
     errorAsignacionPDF: null,
     descargar: true,
     hasSelected: true,

     // Reducers Salida de Bienes
     fetchingListaSalidaDeBienesBusqueda: false,
     listaSalidaDeBienesBusqueda: [],
     listaSalidaDeBienesBusquedaCantidad: null,

     showModalSalidaDeBienes: false,

     fetchingFolioSalidaDeBienes: false,
     fetchingNuevaSalidaDeBienes: false,

     vistaModalSalidaDeBienes: 1,
     formularioSalidaDeBienes: null,

     salidaActivoSeleccionado: [],
     datosSolicitudSeleccionados: [],

     fetchingListaActivosEnOperacionServicio: false,
     listaActivosEnOperacionServicio: [],

     salidaPDF: null,
     errorSalidaPDF: null,
     tipoMovimiento: [],
     servicioSalida: [],
     motivoSalida: [],
     idServicioSalida: null,
     idMotivoSalidaLocal: null,
     motivoSalidaTex: [],
     motivoSalidaTex: [],

     // Reducer Almacenes
     fetchingListaAlmacenesBusqueda: false,
     listaAlmacenesBusqueda: [],
     listaAlmacenesBusquedaCantidad: null,

     fetchingListaAlmacenesActivos: false,
     listaAlmacenesActivos: [],

     showModalNuevoAlmacen: false,
     showModalEditarAlmacen: false,
     almacenSeleccionado: null,

     fetchingNuevoAlmacen: false,
     fetchingEditarAlmacen: false,

     checked: false,
     fetchingExportarExcelAlmacenes: false,

     //Tipos de Folios de Movimientos
     fetchingListaTiposFoliosMovimientosBusqueda: false,
     listaTiposFoliosMovimientosBusqueda: [],
     listaTiposFoliosMovimientosBusquedaCantidad: null,
     fetchingListaTiposFoliosMovimientosActivos: false,
     listaTiposFoliosMovimientosActivos: [],

     showModalNuevoTipoFolioMovimiento: false,
     showModalEditarTipoFolioMovimiento: false,
     TipoFolioMovimientoSeleccionado: null,

     fetchingNuevoTipoFolioMovimiento: false,
     fetchingEditarTipoFolioMovimiento: false,
     fetchingExportarExcelTiposFolios: false,

     //Catálogo de Productos
     fetchingListaProductosBusqueda: false,
     listaProductosBusqueda: [],
     listaProductosBusquedaCantidad: null,

     fetchingListaProductosActivos: false,
     listaProductosActivos: [],
     fetchingListaProductosActivosBusqueda: false,
     listaProductosActivosBusqueda: [],

     fetchingListaHerramientasActivasBusqueda: false,
     listaHerramientasActivasBusqueda: [],

     showModalNuevoProducto: false,
     showModalEditarProducto: false,
     showModalDetalleProducto: false,
     productoSeleccionado: null,

     fetchingNuevoProducto: false,
     fetchingEditarProducto: false,
     fetchingExportarExcelProductos: false,

     //  Movimientos Entradas/Salidas Reducers 
     fetchingListaMovimientosGeneralBusqueda: false,
     listaMovimientosGeneralBusqueda: [],
     listaMovimientosGeneralBusquedaCantidad: null,
     movimientoSeleccionado: null,
     showModalOpcionesMovimientosAlmacen: false,

     pageMovimientos: 1,
     // lista consulta Cancelados o cerrados
     fetchinglistaDetalle: false,
     listaDetalle: [],
     listaDetalleCantidad: null,
     showModalDetalle: false,
     fetchingCancelarFolio: false,
     fetchingNuevoFolio: false,
     showModalNuevoFolio: false,
     //vistas para generacion de folio
     vistaModalNuevoFolio: 1,
     fetchingListaProductosBusqueda: false,
     listaProductosBusqueda: [],
     listaProductosBusquedaCantidad: null,
     formularioNuevoFolio: null,

     //Lista inventario tipo activos
     fetchingListaInventarioTipoActivos: false,
     listaInventarioTipoActivos: [],
     listaInventarioTipoActivosCantidad: 0,
     //lista filas seleccionadas nuevo folio
     listaSeleccionados: null,
     tipoActivos: false,
     showModalModificarRegistroNuevoFolio: false,
     registroProductoSeleccionado: null,
     fetchingModalOpciones: false,
     fetchingObtenerUbicacion: false,
     fetchingObtenerUbicacionDestino: false,
     listaUbicaciones: null,
     listaUbicacionesDestino: null,
     showModalListaSeleccionadosNuevoFolio: false,
     fetchingCerrarFolio: false,
     //editar
     fetchingEditarFolio: false,
     showModalEditarFolio: false,
     vistaModalEditarFolio: 1,
     formularioEditarFolio: null,
     listaSeleccionadosEditar: null,
     showModalModificarEditarFolio: false,
     fetchingCancelarProducto: false,
     fetchingCerrarSinSalidaFolio: false,
     fetchingObtenerAlmacenPorUbicacion: false,
     fetchingObtenerAlmacenPorUbicacionDestino: false,
     fetchingInfoIdInventario: false,
     productoInventario: [],
     almacenOrigen: [],
     almacenDestino: [],
     fetchingListaProductosFiltradoRequest: false,
     listaProductosFiltradoRequest: [],
     listaProductosFiltradoRequestCantidad: [],
     fetchingListaInventarioFiltradoRequest: false,
     listaInventarioFiltradoRequest: [],
     listaInventarioFiltradoRequestCantidad: [],
     fetchingListaRequisicionFiltradoRequest: false,
     listaRequisicionFiltradoRequest: [],
     listaRequisicionFiltradoRequestCantidad: [],

     //Reducer Salida por instalación
     showModalCrearActivos: false,
     fetchingSalidaInstalacion: false,

     //Requisicion punto de reorden
     fetchingRequisicionPuntoReordenBusqueda: false,
     listaRequisicionPuntoReorden: [],
     listaRequisicionPuntoReordenCantidad: null,
     fetchingRequisicionPuntoReordenNuevo: false,
     vistaPuntoReorden: 1,
     showModalRequisicionPuntoReorden: false,
     folioSeleccionado: null,
     fetchingListaConsultaFolio: false,
     listaConsultaFolio: [],
     listaConsultaFolioCantidad: null,
     showModalEditarProductoRequsicion: false,
     productoReqSeleccionado: null,
     fetchingEditarProductoRequisicionPuntoReorden: false,
     fetchingCerrarFolioRequisicionPuntoReorden: false,
     showModalCerrarFolioRequisicion: false,
     fetchingDescargarFolioRequisicionPuntoReorden: false,
     showModalDescargarFolio: false,
     fetchingCancelarFolioRequisicionPuntoReorden: false,

     fetchingDescargarFolioRequisicionCompra: false,




     //Catálogo Ubicaciones de Productos
     fetchingListaUbicacionesProductosBusqueda: false,
     listaUbicacionesProductosBusqueda: [],
     listaUbicacionesProductosBusquedaCantidad: null,
     fetchingListaUbicacionesProductosActivos: false,
     listaUbicacionesProductosActivos: [],

     showModalNuevaUbicacionProducto: false,
     showModalEditarUbicacionProducto: false,
     ubicacionProductoSeleccionado: null,

     fetchingNuevaUbicacionProducto: false,
     fetchingEditarUbicacionProducto: false,
     fetchingExportarExcelUbicacionesProductos: false,

     // Requisicion Directa
     fetchingListaArchivosRequisicionEspera: false,
     listaArchivosRequisicionEspera: [],
     listaArchivosRequisicionEsperaCantidad: 0,

     fetchingSubirArchivoRequisicion: false,
     fetchingBorrarArchivoRequisicion: false,
     folio: null,
     registros: null,
     responseStatus: null,
     textMessage: null,
     mesaggeSpin: 'Folio de Requisición Directa',
     descriptionSpin: 'Seleccione un archivo, por favor.',
     typeSpin: 'info',

     //Lista para toma de Inventario Físico General
     fetchingListaInventarioFisicoBusqueda: false,
     listaInvetarioFisicoBusqueda: [],
     listaInvetarioFisicoBusquedaCantidad: null,
     fetchingListaInventarioFisicoDescarga: false,
     showModalInventarioFisicoGeneral: false,

     fetchingListaPisosByAlmacen: false,
     listaPisosByAlmacen: [],
     fetchingListaAreasByPisos: false,
     listaAreasByPisos: [],
     fetchingListaPasillosByAreas: false,
     listaPasillosByAreas: [],
     fetchingListaRacksByPasillos: false,
     listaRacksByPasillos: [],

     //
     fetchingListaFamiliasFiltro: false,
     listaFamiliasFiltro: [],
     fetchingListaServiciosbyAlmacen: false,
     listaServiciosbyAlmacen: [],
     fetchingListaFamiliasbyServicio: false,
     listaFamiliasbyServicio: [],
     fetchingListaSubfamiliasbyFamilia: false,
     listaSubfamiliasbyFamilia: [],
     showModalInventarioCiclico: false,
     almacenesSeleccionados: [],
     serviciosSeleccionados: [],
     familiasSeleccionadas: [],
     subfamiliasSeleccionadas: [],
     rangoMayor: null,
     rangoMenor: null,
     porcentaje: null,
     fetchingListaProductosCiclico: false,
     listadoInventarioCiclico: [],
     listadoInventarioCiclicoCantidad: 0,
     fetchingListaInventarioCiclicoDescarga: false,

     //
     showModalIngresarFolio: false,
     tipoInventario: '',
     showModalInformacionFolio: false,
     showModalCapturaInventario: false,
     showModalNoFolio: false,
     folioInventario: '',
     folioInventarioGenerado: '',
     fetchingListaFiltroFolio: false,
     listaFiltroFolio: [],
     fetchingGenerarFolioCaptura: false,
     showModalFolioCreado: false,
     fetchingListaAlmacenesByFolio: false,
     listaAlmacenesByFolio: [],
     fetchingListaPisosByFolio: false,
     listaPisosByFolio: [],
     fetchingListaAreasByFolio: false,
     listaAreasByFolio: [],
     fetchingListaPasillosByFolio: false,
     listaPasillosByFolio: [],
     fetchingListaRacksByFolio: false,
     listaRacksByFolio: [],
     fetchingListaProductosByFolio: false,
     listaProductosByFolio: [],
     listaProductosByFolioCantidad: null,
     numeroConteo: null,
     fetchingCapturaConteo: false,
     buttonReporte: true,
     listaAlmacenesByFolio1: [],
     listaAlmacenesByFolio2: [],
     fetchingValidacionConteoByFolio: false,
     validacionConteoUno: false,
     validacionConteoDos: false,
     validacionConteoTres: false,

     //Reporte faltantes y Ajuste Inventario Físico 
     fetchingListaFaltantesInventarioFisico: false,
     listaFaltantesInvetarioFisico: [],
     listaFaltantesInvetarioFisicoCantidad: null,
     fetchingListaFaltantesInventarioFisicoDescarga: false,
     habilitarCerrarFolioInventarioFisico: false,
     fetchingCerrarFolioAjusteInventario: false,

     // Kardex
     fetchingKardexProductosExistentes: false,
     kardexCardsMovimientos: false,
     kardexEncabezado: false,
     fetchingListaAlmacenesByProducto: false,
     listaAlmacenesByProducto: [],
     fetchingListaKardexProductosObtenidos: false,
     listaKardexProductosObtenidos: [],
     fetchingListaKardexMovimientos: false,
     listaKardexMovimientos: [],
     fetchingKardexReporte: false,
     dataMovimientosSumas: [],
     dataMovimientosRestas: [],
     buttonExport: true,
     cantidadMovimientoSuma: null,
     cantidadMovimientoResta: null,

     // reducer folios de verificacion de calidad
     fetchingListaFVerificacionCalidad: false,
     listaFVerificacionCalidad: [],
     listaFVerificacionCalidadCantidad: null,
     showModalNuevoFolioVC: false,
     fetchingNuevoFolioVC: false,
     showModalFolioVC: false,
     fetchingFolioVC: false,
     folioEVDCseleccionado: null,
     listaFolioVC: [],
     listaFolioVCCalidad: null,
     fetchingImprimirPDF: false,
     showModalModificarRegistro: false,

     //reducer desacarga de salida por instalación
     fetchingDescargarSalidaInstalacion: false,
     fetchingDescargarSeries: false,

     // Movimientos de herramientas
     fetchingListaHerramientas: false,
     listaHerramientas: [],
     listaHerramientasCantidad: null,
     fetchingPrestamoHerramientas: false,
     herramientaSeleccionada: null,
     showModalDevolucion: false,
     fetchingBajaHerramientas: false,
     fetchingFoliosPrestamos: false,
     foliosPrestamos: [],
     folioPrestamo: [],
     folioPrestamoCantidad: null,
     fetchingFolioPrestamo: false,
     tipoMov: '',
     showModalPrestamo: false,
     herramientaSeleccionadaPrestamo: null,
     showModalBaja: false,
     herramientaSeleccionadaBaja: null,
     fetchingNumeroPrestados: false,
     listaNumerosInventarioPrestados: [],
     listaNumerosInventarioFolio: [],
     showModalEstadoDevolucion: false,
     codigoDevolucionSeleccionado: null,
     fetchingCrearNumeros: false,
     showModalCrearNumeros: false,
     herrSeleccionada: null,
     fetchingNumerosDisponibles: false,
     numerosInventarioDisponibles: [],
     numerosInventarioDisponiblesCantidad: null,

     // Solicitud de Materiales
     showModalRequisicionMateriales: false,
     fetchingListaFoliosRequisicionMateriales: false,
     listaFoliosRequisicionMateriales: [],
     listaFoliosRequisicionMaterialesCantidad: null,

     fetchingRegistrarRequisicionMateriales: false,

     fetchingCambiarEstadoRequisicion: false,
     showModalEditarRequisicion: false,
     fetchingEditarRequisicion: false,
     requisicionSeleccionada: null,

     showModalAprobarRequisicion: false,
     fetchingAprobarRequisicion: false,

     fetchingDescargarRequisicion: false,
     showModalModificarRequisicionMateriales: false,

     fetchingProductosFolioRequisicion: false,
     listaProductosFolioRequisicion: [],
     fetchingSurtirFolioRequisicion: false,

     // Carga Masiva de Productos Insumos y Herramientas
     showModalCargaMasivaPIH: false,
     descargarArchivoSeries: false,
     disableBtnAdd:true, 
     disableBtnSeries:true,
     disableBtnFolio:true,
     resetValues:false,
     fetchingCargaMasiva:false,
     descargarFolioSeries: false,
     descargarFolio: false,
     showModalErroresPIH:false,
     idArchivoMasivo: null,
     fetchingListCargaMasiva:false,
     listInfoArchivosCargaMasiva:[],
     listArchivosCargaMasivaPIH:[],
     fetchingErroresCargaMasiva:false,
     listInfoErroresCargaMasiva:[],
     listErroresCargaMasiva: [],

      // Carga Masiva Saldos Iniciales
      fetchingCargaMasivaAlmacen:false,
      showModalErroresAlmacen:false,
      fetchingListCargaMasivaAlmacen:false,
      listInfoArchivosCargaMasivaAlmacen:[],
      listArchivosCargaMasivaAlmacen:[],
      fetchingErroresCargaMasivaAlmacen:false,
      listInfoErroresCargaMasivaAlmacen:[],
      listErroresCargaMasivaAlmacen:[],

      //Reporte de Existencias
      fetchingListaReporteExistencias: false,
      listaReporteExistencias: [],
      listaReporteExistenciasCantidad: null,
      formularioReporte: [],

     fetchingReporteExistenciasAgrupado: false,
     listaReporteExistenciasAgrupado: [],
     listaReporteExistenciasAgrupadoCantidad: null,

     fetchingReporteExistenciasEXCEL: false,
     fetchingReporteExistenciasPDF: false,

}

export default (state = initialState, action) => {
     switch (action.type) {
          case AlmacenesActions.CAMBIAR_CATALOGO_ALMACENES:
               return { ...state, catalogoAlmacenes: action.catalogoAlmacenes };

          //CÁTALOG CATEGORIAS UNIDADES DE MEDICION
          //Lista Unidades de Medición Activos
          case AlmacenesActions.LISTA_CATEGORIAS_MEDICION_ACTIVOS_REQUEST:
               return { ...state, fetchingListaCategoriasDeMedicionActivos: true };
          case AlmacenesActions.LISTA_CATEGORIAS_MEDICION_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaCategoriasDeMedicionActivos: false, listaCategoriasDeMedicionActivos: action.listaCategoriasDeMedicionActivos };
          case AlmacenesActions.LISTA_CATEGORIAS_MEDICION_ACTIVOS_FAILURE:
               return { ...state, fetchingListaCategoriasDeMedicionActivos: false };

          case AlmacenesActions.CATEGORIAS_MEDICION_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaCategoriasDeMedicionBusqueda: true };
          case AlmacenesActions.CATEGORIAS_MEDICION_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaCategoriasDeMedicionBusqueda: false, listaCategoriasDeMedicionBusqueda: action.listaCategoriasDeMedicionBusqueda, listaCategoriasDeMedicionBusquedaCantidad: action.listaCategoriasDeMedicionBusquedaCantidad };
          case AlmacenesActions.CATEGORIAS_MEDICION_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaCategoriasDeMedicionBusqueda: false };

          case AlmacenesActions.ESTADO_CATEGORIA_MEDICION_REQUEST:
               return { ...state, fetchingListaCategoriasDeMedicionBusqueda: true };
          case AlmacenesActions.ESTADO_CATEGORIA_MEDICION_SUCCESS:
               return { ...state, fetchingListaCategoriasDeMedicionBusqueda: false };
          case AlmacenesActions.ESTADO_CATEGORIA_MEDICION_FAILURE:
               return { ...state, fetchingListaCategoriasDeMedicionBusqueda: false };

          case AlmacenesActions.SHOW_MODAL_NUEVA_CATEGORIA_MEDICION:
               return { ...state, showModalNuevaCategoriaDeMedicion: action.showModalNuevaCategoriaDeMedicion, page: action.page };

          case AlmacenesActions.SHOW_MODAL_EDITAR_CATEGORIA_MEDICION:
               return { ...state, showModalEditarCategoriaDeMedicion: action.showModalEditarCategoriaDeMedicion, CategoriaDeMedicionSeleccionado: action.CategoriaDeMedicionSeleccionado, page: action.page };

          case AlmacenesActions.NUEVA_CATEGORIA_MEDICION_REQUEST:
               return { ...state, fetchingNuevaCategoriaDeMedicion: true };
          case AlmacenesActions.NUEVA_CATEGORIA_MEDICION_SUCCESS:
               return { ...state, fetchingNuevaCategoriaDeMedicion: false };
          case AlmacenesActions.NUEVA_CATEGORIA_MEDICION_FAILURE:
               return { ...state, fetchingNuevaCategoriaDeMedicion: false };

          case AlmacenesActions.EDITAR_CATEGORIA_MEDICION_REQUEST:
               return { ...state, fetchingEditarCategoriaDeMedicion: true };
          case AlmacenesActions.EDITAR_CATEGORIA_MEDICION_SUCCESS:
               return { ...state, fetchingEditarCategoriaDeMedicion: false };
          case AlmacenesActions.EDITAR_CATEGORIA_MEDICION_FAILURE:
               return { ...state, fetchingEditarCategoriaDeMedicion: false };

          case AlmacenesActions.EXPORTAR_CATEGORIA_MEDICION_EXCEL_REQUEST:
               return { ...state, fetchingExportarExcelCategoriasMedicion: true };
          case AlmacenesActions.EXPORTAR_CATEGORIA_MEDICION_EXCEL_SUCCESS:
               return { ...state, fetchingExportarExcelCategoriasMedicion: false };
          case AlmacenesActions.EXPORTAR_CATEGORIA_MEDICION_EXCEL_FAILURE:
               return { ...state, fetchingExportarExcelCategoriasMedicion: false };

          //CÁTALOG UNIDADES DE MEDICION
          //Lista Unidades de Medición Activos
          case AlmacenesActions.LISTA_UNIDADES_MEDICION_ACTIVOS_REQUEST:
               return { ...state, fetchingListaUnidadesDeMedicionActivos: true };
          case AlmacenesActions.LISTA_UNIDADES_MEDICION_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaUnidadesDeMedicionActivos: false, listaUnidadesDeMedicionActivos: action.listaUnidadesDeMedicionActivos };
          case AlmacenesActions.LISTA_UNIDADES_MEDICION_ACTIVOS_FAILURE:
               return { ...state, fetchingListaUnidadesDeMedicionActivos: false };

          case AlmacenesActions.UNIDADES_MEDICION_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaUnidadesDeMedicionBusqueda: true };
          case AlmacenesActions.UNIDADES_MEDICION_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaUnidadesDeMedicionBusqueda: false, listaUnidadesDeMedicionBusqueda: action.listaUnidadesDeMedicionBusqueda, listaUnidadesDeMedicionBusquedaCantidad: action.listaUnidadesDeMedicionBusquedaCantidad };
          case AlmacenesActions.UNIDADES_MEDICION_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaUnidadesDeMedicionBusqueda: false };

          case AlmacenesActions.ESTADO_UNIDADES_MEDICION_REQUEST:
               return { ...state, fetchingListaUnidadesDeMedicionBusqueda: true };
          case AlmacenesActions.ESTADO_UNIDADES_MEDICION_SUCCESS:
               return { ...state, fetchingListaUnidadesDeMedicionBusqueda: false };
          case AlmacenesActions.ESTADO_UNIDADES_MEDICION_FAILURE:
               return { ...state, fetchingListaUnidadesDeMedicionBusqueda: false };

          case AlmacenesActions.SHOW_MODAL_NUEVA_UNIDAD_MEDICION:
               return { ...state, showModalNuevaUnidadDeMedicion: action.showModalNuevaUnidadDeMedicion, page: action.page };

          case AlmacenesActions.SHOW_MODAL_EDITAR_UNIDAD_MEDICION:
               return { ...state, showModalEditarUnidadDeMedicion: action.showModalEditarUnidadDeMedicion, UnidadDeMedicionSeleccionado: action.UnidadDeMedicionSeleccionado, page: action.page };

          case AlmacenesActions.NUEVA_UNIDAD_MEDICION_REQUEST:
               return { ...state, fetchingNuevaUnidadDeMedicion: true };
          case AlmacenesActions.NUEVA_UNIDAD_MEDICION_SUCCESS:
               return { ...state, fetchingNuevaUnidadDeMedicion: false };
          case AlmacenesActions.NUEVA_UNIDAD_MEDICION_FAILURE:
               return { ...state, fetchingNuevaUnidadDeMedicion: false };

          case AlmacenesActions.EDITAR_UNIDAD_MEDICION_REQUEST:
               return { ...state, fetchingEditarUnidadDeMedicion: true };
          case AlmacenesActions.EDITAR_UNIDAD_MEDICION_SUCCESS:
               return { ...state, fetchingEditarUnidadDeMedicion: false };
          case AlmacenesActions.EDITAR_UNIDAD_MEDICION_FAILURE:
               return { ...state, fetchingEditarUnidadDeMedicion: false };

          case AlmacenesActions.UNIDADES_MEDI_POR_CATEGORIA_REQUEST:
               return { ...state, fetchingUnidadesMediPorCategoria: true };
          case AlmacenesActions.UNIDADES_MEDI_POR_CATEGORIA_SUCCESS:
               return { ...state, fetchingUnidadesMediPorCategoria: false, unidadesMediPorCategoria: action.unidadesMediPorCategoria, editarVidaUtil: false };
          case AlmacenesActions.UNIDADES_MEDI_POR_CATEGORIA_FAILURE:
               return { ...state, fetchingUnidadesMediPorCategoria: false };

          case AlmacenesActions.EXPORTAR_UNIDAD_MEDICION_EXCEL_REQUEST:
               return { ...state, fetchingExportarExcelUnidadesMedicion: true };
          case AlmacenesActions.EXPORTAR_UNIDAD_MEDICION_EXCEL_SUCCESS:
               return { ...state, fetchingExportarExcelUnidadesMedicion: false };
          case AlmacenesActions.EXPORTAR_UNIDAD_MEDICION_EXCEL_FAILURE:
               return { ...state, fetchingExportarExcelUnidadesMedicion: false };

          //REDUCERS DE ACTIVOS CARACTERÍSTICAS
          case AlmacenesActions.LISTA_ACTIVOS_CARACT_REQUEST:
               return { ...state, fetchingListaActivosCaract: true };
          case AlmacenesActions.LISTA_ACTIVOS_CARACT_SUCCESS:
               return { ...state, fetchingListaActivosCaract: false, listaActivosCaract: action.listaActivosCaract };
          case AlmacenesActions.LISTA_ACTIVOS_CARACT_FAILURE:
               return { ...state, fetchingListaActivosCaract: false };

          case AlmacenesActions.LISTA_ACTIVOS_CARACT_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaActivosCaractBusqueda: true };
          case AlmacenesActions.LISTA_ACTIVOS_CARACT_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaActivosCaractBusqueda: false, listaActivosCaractBusqueda: action.listaActivosCaractBusqueda, listaActivosCaractBusquedaCantidad: action.listaActivosCaractBusquedaCantidad };
          case AlmacenesActions.LISTA_ACTIVOS_CARACT_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaActivosCaractBusqueda: false };

          case AlmacenesActions.LISTA_ACTIVOS_CARACT_FILTRO_REQUEST:
               return { ...state, fetchingListaActivosCaractFiltro: true };
          case AlmacenesActions.LISTA_ACTIVOS_CARACT_FILTRO_SUCCESS:
               return { ...state, fetchingListaActivosCaractFiltro: false, listaActivosCaractFiltro: action.listaActivosCaractFiltro };
          case AlmacenesActions.LISTA_ACTIVOS_CARACT_FILTRO_FAILURE:
               return { ...state, fetchingListaActivosCaractFiltro: false };

          case AlmacenesActions.LISTA_ACTIVOS_CARACT_FILTRO_ASIGNACION_SP_REQUEST:
               return { ...state, fetchingListaActivosCaractFiltroAsignacionSP: true };
          case AlmacenesActions.LISTA_ACTIVOS_CARACT_FILTRO_ASIGNACION_SP_SUCCESS:
               return { ...state, fetchingListaActivosCaractFiltroAsignacionSP: false, listaActivosCaractFiltroAsignacionSP: action.listaActivosCaractFiltroAsignacionSP };
          case AlmacenesActions.LISTA_ACTIVOS_CARACT_FILTRO_ASIGNACION_SP_FAILURE:
               return { ...state, fetchingListaActivosCaractFiltroAsignacionSP: false };

          case AlmacenesActions.ESTADO_ACTIVO_CARACT_REQUEST:
               return { ...state, fetchingListaExpedientesBusqueda: true };
          case AlmacenesActions.ESTADO_ACTIVO_CARACT_SUCCESS:
               return { ...state, fetchingListaExpedientesBusqueda: false };
          case AlmacenesActions.ESTADO_ACTIVO_CARACT_FAILURE:
               return { ...state, fetchingListaExpedientesBusqueda: false };

          case AlmacenesActions.SHOW_MODAL_NUEVO_ACTIVO_CARACT:
               return { ...state, showModalNuevoActivoCaract: action.showModalNuevoActivoCaract, page: action.page, unidadesMediPorCategoria: [] };

          case AlmacenesActions.SHOW_MODAL_EDITAR_ACTIVO_CARACT:
               return { ...state, showModalEditarActivoCaract: action.showModalEditarActivoCaract, activoCaractSeleccionado: action.activoCaractSeleccionado, page: action.page, editarVidaUtil: true };

          case AlmacenesActions.NUEVO_ACTIVO_CARACT_REQUEST:
               return { ...state, fetchingNuevoActivoCaract: true };
          case AlmacenesActions.NUEVO_ACTIVO_CARACT_SUCCESS:
               return { ...state, fetchingNuevoActivoCaract: false };
          case AlmacenesActions.NUEVO_ACTIVO_CARACT_FAILURE:
               return { ...state, fetchingNuevoActivoCaract: false };

          case AlmacenesActions.EDITAR_ACTIVO_CARACT_REQUEST:
               return { ...state, fetchingEditarActivoCaract: true };
          case AlmacenesActions.EDITAR_ACTIVO_CARACT_SUCCESS:
               return { ...state, fetchingEditarActivoCaract: false };
          case AlmacenesActions.EDITAR_ACTIVO_CARACT_FAILURE:
               return { ...state, fetchingEditarActivoCaract: false };

          case AlmacenesActions.EXPORTAR_CARACTERISTICAS_ACTIVOS_EXCEL_REQUEST:
               return { ...state, fetchingExportarExcelCaracteristicasActivos: true };
          case AlmacenesActions.EXPORTAR_CARACTERISTICAS_ACTIVOS_EXCEL_SUCCESS:
               return { ...state, fetchingExportarExcelCaracteristicasActivos: false };
          case AlmacenesActions.EXPORTAR_CARACTERISTICAS_ACTIVOS_EXCEL_FAILURE:
               return { ...state, fetchingExportarExcelCaracteristicasActivos: false };

          //REDUCERS DE CATÁLAGO DE ACTIVOS
          case AlmacenesActions.LISTA_ACTIVOS_REQUEST:
               return { ...state, fetchingListaActivos: true };
          case AlmacenesActions.LISTA_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaActivos: false, listaActivos: action.listaActivos };
          case AlmacenesActions.LISTA_ACTIVOS_FAILURE:
               return { ...state, fetchingListaActivos: false };

          case AlmacenesActions.LISTA_ACTIVO_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaActivosBusqueda: true };
          case AlmacenesActions.LISTA_ACTIVO_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaActivosBusqueda: false, listaActivosBusqueda: action.listaActivosBusqueda, listaActivosBusquedaCantidad: action.listaActivosBusquedaCantidad };
          case AlmacenesActions.LISTA_ACTIVO_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaActivosBusqueda: false };

          case AlmacenesActions.ESTADO_ACTIVO_REQUEST:
               return { ...state, fetchingListaActivosBusqueda: true };
          case AlmacenesActions.ESTADO_ACTIVO_SUCCESS:
               return { ...state, fetchingListaActivosBusqueda: false };
          case AlmacenesActions.ESTADO_ACTIVO_FAILURE:
               return { ...state, fetchingListaActivosBusqueda: false };

          case AlmacenesActions.SHOW_MODAL_NUEVO_ACTIVO:
               return { ...state, showModalNuevoActivo: action.showModalNuevoActivo, page: action.page, unidadesMediPorCategoria: [] };

          case AlmacenesActions.SHOW_MODAL_EDITAR_ACTIVO:
               return { ...state, showModalEditarActivo: action.showModalEditarActivo, activoSeleccionado: action.activoSeleccionado, page: action.page };

          case AlmacenesActions.NUEVO_ACTIVO_REQUEST:
               return { ...state, fetchingNuevoActivo: true };
          case AlmacenesActions.NUEVO_ACTIVO_SUCCESS:
               return { ...state, fetchingNuevoActivo: false };
          case AlmacenesActions.NUEVO_ACTIVO_FAILURE:
               return { ...state, fetchingNuevoActivo: false };

          case AlmacenesActions.EDITAR_ACTIVO_REQUEST:
               return { ...state, fetchingEditarActivo: true };
          case AlmacenesActions.EDITAR_ACTIVO_SUCCESS:
               return { ...state, fetchingEditarActivo: false };
          case AlmacenesActions.EDITAR_ACTIVO_FAILURE:
               return { ...state, fetchingEditarActivo: false };

          case AlmacenesActions.GET_ARCHIVOS_ESPERA_REQUEST:
               return { ...state, fetchingListaArchivosEspera: true };
          case AlmacenesActions.GET_ARCHIVOS_ESPERA_SUCCESS:
               return { ...state, fetchingListaArchivosEspera: false, listaArchivosEspera: action.listaArchivosEspera, listaArchivosEsperaCantidad: action.listaArchivosEsperaCantidad };
          case AlmacenesActions.GET_ARCHIVOS_ESPERA_FAILURE:
               return { ...state, fetchingListaArchivosEspera: false };

          case AlmacenesActions.SUBIR_ARCHIVO_ACTIVO_REQUEST:
               return { ...state, fetchingSubirArchivoActivo: true };
          case AlmacenesActions.SUBIR_ARCHIVO_ACTIVO_SUCCESS:
               return { ...state, fetchingSubirArchivoActivo: false };
          case AlmacenesActions.SUBIR_ARCHIVO_ACTIVO_FAILURE:
               return { ...state, fetchingSubirArchivoActivo: false };

          case AlmacenesActions.ELIMINAR_ARCHIVO_ACTIVO_REQUEST:
               return { ...state, fetchingBorrarArchivoActivo: true };
          case AlmacenesActions.ELIMINAR_ARCHIVO_ACTIVO_SUCCESS:
               return { ...state, fetchingBorrarArchivoActivo: false };
          case AlmacenesActions.ELIMINAR_ARCHIVO_ACTIVO_FAILURE:
               return { ...state, fetchingBorrarArchivoActivo: false };

          case AlmacenesActions.EXPORTAR_ERRORES_ARCHIVO_REQUEST:
               return { ...state, fetchingListaArchivosEspera: true };
          case AlmacenesActions.EXPORTAR_ERRORES_ARCHIVO_SUCCESS:
               return { ...state, fetchingListaArchivosEspera: false };
          case AlmacenesActions.EXPORTAR_ERRORES_ARCHIVO_FAILURE:
               return { ...state, fetchingListaArchivosEspera: false };

          case AlmacenesActions.PROCESAR_ARCHIVO_REQUEST:
               return { ...state, fetchingListaArchivosEspera: true };
          case AlmacenesActions.PROCESAR_ARCHIVO_SUCCESS:
               return { ...state, fetchingListaArchivosEspera: false };
          case AlmacenesActions.PROCESAR_ARCHIVO_FAILURE:
               return { ...state, fetchingListaArchivosEspera: false };

          case AlmacenesActions.EXPORTAR_ACTIVOS_EXCEL_REQUEST:
               return { ...state, fetchingExportarExcelActivos: true };
          case AlmacenesActions.EXPORTAR_ACTIVOS_EXCEL_SUCCESS:
               return { ...state, fetchingExportarExcelActivos: false };
          case AlmacenesActions.EXPORTAR_ACTIVOS_EXCEL_FAILURE:
               return { ...state, fetchingExportarExcelActivos: false };

          //REDUCERS ASIGNACIÓN DE ACTIVOS
          case AlmacenesActions.ASIGNACION_ACTIVOS_BUSQUEDA_REQUEST:
               return { ...state, fetchinglistaAsignacionActivosBusqueda: true };
          case AlmacenesActions.ASIGNACION_ACTIVOS_BUSQUEDA_SUCCESS:
               return { ...state, fetchinglistaAsignacionActivosBusqueda: false, listaAsignacionActivosBusqueda: action.listaAsignacionActivosBusqueda, listaAsignacionActivosBusquedaCantidad: action.listaAsignacionActivosBusquedaCantidad };
          case AlmacenesActions.ASIGNACION_ACTIVOS_BUSQUEDA_FAILURE:
               return { ...state, fetchinglistaAsignacionActivosBusqueda: false };

          case AlmacenesActions.LISTA_AREA_FUNCIONAL_ACTIVA_REQUEST:
               return { ...state, fetchingListaAreasFuncionalesActivas: true };
          case AlmacenesActions.LISTA_AREA_FUNCIONAL_ACTIVA_SUCCESS:
               return { ...state, fetchingListaAreasFuncionalesActivas: false, listaAreasFuncionalesActivas: action.listaAreasFuncionalesActivas };
          case AlmacenesActions.LISTA_AREA_FUNCIONAL_ACTIVA_FAILURE:
               return { ...state, fetchingListaAreasFuncionalesActivas: false };

          case AlmacenesActions.LISTA_AREA_FUNCIONAL_ACTIVA_EX_REQUEST:
               return { ...state, fetchingListaAreasFuncionalesActivasEx: true };
          case AlmacenesActions.LISTA_AREA_FUNCIONAL_ACTIVA_EX_SUCCESS:
               return { ...state, fetchingListaAreasFuncionalesActivasEx: false, listaAreasFuncionalesActivasEx: action.listaAreasFuncionalesActivasEx };
          case AlmacenesActions.LISTA_AREA_FUNCIONAL_ACTIVA_EX_FAILURE:
               return { ...state, fetchingListaAreasFuncionalesActivasEx: false };

          case AlmacenesActions.LISTA_SUBINTERIOR_FUNCIONAL_REQUEST:
               return { ...state, fetchingListaSubinteriorFuncional: true };
          case AlmacenesActions.LISTA_SUBINTERIOR_FUNCIONAL_SUCCESS:
               return { ...state, fetchingListaSubinteriorFuncional: false, listaSubinteriorFuncional: action.listaSubinteriorFuncional };
          case AlmacenesActions.LISTA_SUBINTERIOR_FUNCIONAL_FAILURE:
               return { ...state, fetchingListaSubinteriorFuncional: false };

          case AlmacenesActions.LISTA_SUBINTERIOR_FUNCIONAL_EX_REQUEST:
               return { ...state, fetchingListaSubinteriorFuncionalEx: true };
          case AlmacenesActions.LISTA_SUBINTERIOR_FUNCIONAL_EX_SUCCESS:
               return { ...state, fetchingListaSubinteriorFuncionalEx: false, listaSubinteriorFuncionalEx: action.listaSubinteriorFuncionalEx };
          case AlmacenesActions.LISTA_SUBINTERIOR_FUNCIONAL_EX_FAILURE:
               return { ...state, fetchingListaSubinteriorFuncionalEx: false };

          case AlmacenesActions.LISTA_INTERIOR_FUNCIONAL_REQUEST:
               return { ...state, fetchingListaInteriorFuncional: true };
          case AlmacenesActions.LISTA_INTERIOR_FUNCIONAL_SUCCESS:
               return { ...state, fetchingListaInteriorFuncional: false, listaInteriorFuncional: action.listaInteriorFuncional, showAlertMsg:action.showAlertMsg };
          case AlmacenesActions.LISTA_INTERIOR_FUNCIONAL_FAILURE:
               return { ...state, fetchingListaInteriorFuncional: false, showAlertMsg:action.showAlertMsg };

          case AlmacenesActions.LISTA_INTERIOR_FUNCIONAL_EX_REQUEST:
               return { ...state, fetchingListaInteriorFuncionalEx: true };
          case AlmacenesActions.LISTA_INTERIOR_FUNCIONAL_EX_SUCCESS:
               return { ...state, fetchingListaInteriorFuncionalEx: false, listaInteriorFuncionalEx: action.listaInteriorFuncionalEx, showAlertMsgEx:action.showAlertMsgEx  };
          case AlmacenesActions.LISTA_INTERIOR_FUNCIONAL_EX_FAILURE:
               return { ...state, fetchingListaInteriorFuncionalEx: false, showAlertMsgEx:action.showAlertMsgEx  };

          case AlmacenesActions.LISTA_UNIDAD_FUNCIONAL_REQUEST:
               return { ...state, fetchingListaUnidadesFuncionalesActivas: true };
          case AlmacenesActions.LISTA_UNIDAD_FUNCIONAL_SUCCESS:
               return { ...state, fetchingListaUnidadesFuncionalesActivas: false, listaUnidadesFuncionalesActivas: action.listaUnidadesFuncionalesActivas, showAlertMsg:action.showAlertMsg };
          case AlmacenesActions.LISTA_UNIDAD_FUNCIONAL_FAILURE:
               return { ...state, fetchingListaUnidadesFuncionalesActivas: false, showAlertMsg:action.showAlertMsg };

          case AlmacenesActions.LISTA_UNIDAD_FUNCIONAL_EX_REQUEST:
               return { ...state, fetchingListaUnidadesFuncionalesActivasEx: true };
          case AlmacenesActions.LISTA_UNIDAD_FUNCIONAL_EX_SUCCESS:
               return { ...state, fetchingListaUnidadesFuncionalesActivasEx: false, listaUnidadesFuncionalesActivasEx: action.listaUnidadesFuncionalesActivasEx, showAlertMsgEx:action.showAlertMsgEx  };
          case AlmacenesActions.LISTA_UNIDAD_FUNCIONAL_EX_FAILURE:
               return { ...state, fetchingListaUnidadesFuncionalesActivasEx: false, showAlertMsgEx:action.showAlertMsgEx };

          case AlmacenesActions.LISTA_USUARIOS_ACTIVOS_REQUEST:
               return { ...state, fetchingListaUsuariosActivos: true };
          case AlmacenesActions.LISTA_USUARIOS_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaUsuariosActivos: false, listaUsuariosActivos: action.listaUsuariosActivos };
          case AlmacenesActions.LISTA_USUARIOS_ACTIVOS_FAILURE:
               return { ...state, fetchingListaUsuariosActivos: false };

          case AlmacenesActions.SHOW_MODAL_ASIGNACION_ACTIVOS:
               return { ...state, showModalAsignarActivos: action.showModalAsignarActivos, AsignacionActivoSeleccionado: action.AsignacionActivoSeleccionado, page: action.page };

          //DESCARGAR FACTURA PDF

          case AlmacenesActions.NUEVA_ASIGNACION_ACTIVOS_REQUEST:
               return { ...state, fetchingNuevaAsignacionActivos: true, errorAsignacionPDF: null };
          case AlmacenesActions.NUEVA_ASIGNACION_ACTIVOS_SUCCESS:
               return { ...state, fetchingNuevaAsignacionActivos: false, asignacionPDF: action.asignacionPDF, asignacionXML: action.asignacionXML, errorAsignacionPDF: null, descargar: action.descargar, hasSelected: action.hasSelected };
          case AlmacenesActions.NUEVA_ASIGNACION_ACTIVOS_FAILURE:
               return { ...state, fetchingNuevaAsignacionActivos: false, errorAsignacionPDF: action.errorAsignacionPDF, asignacionPDF: action.asignacionPDF, descargar: action.descargar, hasSelected: action.hasSelected };

          // case AlmacenesActions.GET_ASIGNACION_PDF_REQUEST:
          //      return { ...state, fetchingAsignacionPDF: true, errorAsignacionPDF: null };
          // case AlmacenesActions.GET_ASIGNACION_PDF_SUCCESS:
          //      return { ...state, asignacionPDF: action.asignacionPDF, errorAsignacionPDF: null, fetchingAsignacionPDF: false, descargar: action.descargar};
          // case AlmacenesActions.GET_ASIGNACION_PDF_FAILURE:
          //      return { ...state, errorAsignacionPDF: action.errorAsignacionPDF, asignacionPDF: action.asignacionPDF, fetchingAsignacionPDF: false, descargar: action.descargar};

          //CATÁLOGO RANGO RENOVACION PARA ACTIVO FIJO

          case AlmacenesActions.LISTA_RANGOS_RENOVACION_ACTIVOS_REQUEST:
               return { ...state, fetchingListaRangosRenovacionActivoActivos: true };
          case AlmacenesActions.LISTA_RANGOS_RENOVACION_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaRangosRenovacionActivoActivos: false, listaRangosRenovacionActivoActivos: action.listaRangosRenovacionActivoActivos };
          case AlmacenesActions.LISTA_RANGOS_RENOVACION_ACTIVOS_FAILURE:
               return { ...state, fetchingListaRangosRenovacionActivoActivos: false };

          case AlmacenesActions.RANGOS_RENOVACION_ACTIVO_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaRangosRenovacionActivoBusqueda: true };
          case AlmacenesActions.RANGOS_RENOVACION_ACTIVO_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaRangosRenovacionActivoBusqueda: false, listaRangosRenovacionActivoBusqueda: action.listaRangosRenovacionActivoBusqueda, listaRangosRenovacionActivoBusquedaCantidad: action.listaRangosRenovacionActivoBusquedaCantidad };
          case AlmacenesActions.RANGOS_RENOVACION_ACTIVO_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaRangosRenovacionActivoBusqueda: false };

          case AlmacenesActions.ESTADO_RANGO_RENOVACION_ACTIVO_REQUEST:
               return { ...state, fetchingListaRangosRenovacionActivoBusqueda: true };
          case AlmacenesActions.ESTADO_RANGO_RENOVACION_ACTIVO_SUCCESS:
               return { ...state, fetchingListaRangosRenovacionActivoBusqueda: false };
          case AlmacenesActions.ESTADO_RANGO_RENOVACION_ACTIVO_FAILURE:
               return { ...state, fetchingListaRangosRenovacionActivoBusqueda: false };

          case AlmacenesActions.SHOW_MODAL_NUEVO_RANGO_RENOVACION_ACTIVO:
               return { ...state, showModalNuevoRangoRenovacionActivo: action.showModalNuevoRangoRenovacionActivo, page: action.page };

          case AlmacenesActions.SHOW_MODAL_EDITAR_RANGO_RENOVACION_ACTIVO:
               return { ...state, showModalEditarRangoRenovacionActivo: action.showModalEditarRangoRenovacionActivo, RangoRenovacionActivoSeleccionado: action.RangoRenovacionActivoSeleccionado, page: action.page };

          case AlmacenesActions.NUEVO_RANGO_RENOVACION_ACTIVO_REQUEST:
               return { ...state, fetchingNuevoRangosRenovacionActivo: true };
          case AlmacenesActions.NUEVO_RANGO_RENOVACION_ACTIVO_SUCCESS:
               return { ...state, fetchingNuevoRangosRenovacionActivo: false };
          case AlmacenesActions.NUEVO_RANGO_RENOVACION_ACTIVO_FAILURE:
               return { ...state, fetchingNuevoRangosRenovacionActivo: false };

          case AlmacenesActions.EDITAR_RANGO_RENOVACION_ACTIVO_REQUEST:
               return { ...state, fetchingEditarRangosRenovacionActivo: true };
          case AlmacenesActions.EDITAR_RANGO_RENOVACION_ACTIVO_SUCCESS:
               return { ...state, fetchingEditarRangosRenovacionActivo: false };
          case AlmacenesActions.EDITAR_RANGO_RENOVACION_ACTIVO_FAILURE:
               return { ...state, fetchingEditarRangosRenovacionActivo: false };

          case AlmacenesActions.EXPORTAR_RANGO_RENOVACION_ACTIVO_EXCEL_REQUEST:
               return { ...state, fetchingExportarExcelRangosRenovacionActivo: true };
          case AlmacenesActions.EXPORTAR_RANGO_RENOVACION_ACTIVO_EXCEL_SUCCESS:
               return { ...state, fetchingExportarExcelRangosRenovacionActivo: false };
          case AlmacenesActions.EXPORTAR_RANGO_RENOVACION_ACTIVO_EXCEL_FAILURE:
               return { ...state, fetchingExportarExcelRangosRenovacionActivo: false };

          //CATÁLOGO ESTATUS DEL ACTIVO 

          case AlmacenesActions.LISTA_ESTATUS_ACTIVOS_REQUEST:
               return { ...state, fetchingListaEstatusActivoActivos: true };
          case AlmacenesActions.LISTA_ESTATUS_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaEstatusActivoActivos: false, listaEstatusActivoActivos: action.listaEstatusActivoActivos };
          case AlmacenesActions.LISTA_ESTATUS_ACTIVOS_FAILURE:
               return { ...state, fetchingListaEstatusActivoActivos: false };

          case AlmacenesActions.ESTATUS_ACTIVO_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaEstatusActivoBusqueda: true };
          case AlmacenesActions.ESTATUS_ACTIVO_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaEstatusActivoBusqueda: false, listaEstatusActivoBusqueda: action.listaEstatusActivoBusqueda, listaEstatusActivoBusquedaCantidad: action.listaEstatusActivoBusquedaCantidad };
          case AlmacenesActions.ESTATUS_ACTIVO_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaEstatusActivoBusqueda: false };

          case AlmacenesActions.ESTADO_ESTATUS_ACTIVO_REQUEST:
               return { ...state, fetchingListaEstatusActivoBusqueda: true };
          case AlmacenesActions.ESTADO_ESTATUS_ACTIVO_SUCCESS:
               return { ...state, fetchingListaEstatusActivoBusqueda: false };
          case AlmacenesActions.ESTADO_ESTATUS_ACTIVO_FAILURE:
               return { ...state, fetchingListaEstatusActivoBusqueda: false };

          case AlmacenesActions.SHOW_MODAL_NUEVO_ESTATUS_ACTIVO:
               return { ...state, showModalNuevoEstatusActivo: action.showModalNuevoEstatusActivo, page: action.page };

          case AlmacenesActions.SHOW_MODAL_EDITAR_ESTATUS_ACTIVO:
               return { ...state, showModalEditarEstatusActivo: action.showModalEditarEstatusActivo, EstatusActivoSeleccionado: action.EstatusActivoSeleccionado, page: action.page };

          case AlmacenesActions.NUEVO_ESTATUS_ACTIVO_REQUEST:
               return { ...state, fetchingNuevoEstatusActivo: true };
          case AlmacenesActions.NUEVO_ESTATUS_ACTIVO_SUCCESS:
               return { ...state, fetchingNuevoEstatusActivo: false };
          case AlmacenesActions.NUEVO_ESTATUS_ACTIVO_FAILURE:
               return { ...state, fetchingNuevoEstatusActivo: false };

          case AlmacenesActions.EDITAR_ESTATUS_ACTIVO_REQUEST:
               return { ...state, fetchingEditarEstatusActivo: true };
          case AlmacenesActions.EDITAR_ESTATUS_ACTIVO_SUCCESS:
               return { ...state, fetchingEditarEstatusActivo: false };
          case AlmacenesActions.EDITAR_ESTATUS_ACTIVO_FAILURE:
               return { ...state, fetchingEditarEstatusActivo: false };

          case AlmacenesActions.EXPORTAR_ESTATUS_ACTIVO_EXCEL_REQUEST:
               return { ...state, fetchingExportarExcelEstatusActivo: true };
          case AlmacenesActions.EXPORTAR_ESTATUS_ACTIVO_EXCEL_SUCCESS:
               return { ...state, fetchingExportarExcelEstatusActivo: false };
          case AlmacenesActions.EXPORTAR_ESTATUS_ACTIVO_EXCEL_FAILURE:
               return { ...state, fetchingExportarExcelEstatusActivo: false };

          // Reducers Motivos de Salida
          case AlmacenesActions.LISTA_MOTIVOS_DE_SALIDA_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaMotivosSalidaBusqueda: true };
          case AlmacenesActions.LISTA_MOTIVOS_DE_SALIDA_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaMotivosSalidaBusqueda: false, listaMotivosSalidaBusqueda: action.listaMotivosSalidaBusqueda, listaMotivosSalidaBusquedaCantidad: action.listaMotivosSalidaBusquedaCantidad };
          case AlmacenesActions.LISTA_MOTIVOS_DE_SALIDA_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaMotivosSalidaBusqueda: false };

          case AlmacenesActions.ESTADO_MOTIVOS_DE_SALIDA_REQUEST:
               return { ...state, fetchingListaMotivosSalidaBusqueda: true };
          case AlmacenesActions.ESTADO_MOTIVOS_DE_SALIDA_SUCCESS:
               return { ...state, fetchingListaMotivosSalidaBusqueda: false };
          case AlmacenesActions.ESTADO_MOTIVOS_DE_SALIDA_FAILURE:
               return { ...state, fetchingListaMotivosSalidaBusqueda: false };

          case AlmacenesActions.SHOW_MODAL_NUEVO_MOTIVOS_DE_SALIDA:
               return { ...state, showModalNuevoMotivoSalida: action.showModalNuevoMotivoSalida, page: action.page };

          case AlmacenesActions.SHOW_MODAL_EDITAR_MOTIVOS_DE_SALIDA:
               return { ...state, showModalEditarMotivoSalida: action.showModalEditarMotivoSalida, motivoSeleccionado: action.motivoSeleccionado, page: action.page };

          case AlmacenesActions.NUEVO_MOTIVOS_DE_SALIDA_REQUEST:
               return { ...state, fetchingNuevoMotivoSalida: true };
          case AlmacenesActions.NUEVO_MOTIVOS_DE_SALIDA_SUCCESS:
               return { ...state, fetchingNuevoMotivoSalida: false };
          case AlmacenesActions.NUEVO_MOTIVOS_DE_SALIDA_FAILURE:
               return { ...state, fetchingNuevoMotivoSalida: false };

          case AlmacenesActions.EDITAR_MOTIVOS_DE_SALIDA_REQUEST:
               return { ...state, fetchingEditarMotivoSalida: true };
          case AlmacenesActions.EDITAR_MOTIVOS_DE_SALIDA_SUCCESS:
               return { ...state, fetchingEditarMotivoSalida: false };
          case AlmacenesActions.EDITAR_MOTIVOS_DE_SALIDA_FAILURE:
               return { ...state, fetchingEditarMotivoSalida: false };

          case AlmacenesActions.EXPORTAR_MOTIVOS_DE_SALIDA_ACTIVOS_EXCEL_REQUEST:
               return { ...state, fetchingExportarExcelMotivosSalidaActivo: true };
          case AlmacenesActions.EXPORTAR_MOTIVOS_DE_SALIDA_ACTIVOS_EXCEL_SUCCESS:
               return { ...state, fetchingExportarExcelMotivosSalidaActivo: false };
          case AlmacenesActions.EXPORTAR_MOTIVOS_DE_SALIDA_ACTIVOS_EXCEL_FAILURE:
               return { ...state, fetchingExportarExcelMotivosSalidaActivo: false };

          // REDUCERS REINGRESO ACTIVOS
          case AlmacenesActions.REINGRESO_ACTIVOS_LISTA_ACT_REQUEST:
               return { ...state, fetchinglistaReingresoActivosFoliosAct: true };
          case AlmacenesActions.REINGRESO_ACTIVOS_LISTA_ACT_SUCCESS:
               return { ...state, fetchinglistaReingresoActivosFoliosAct: false, listaReingresoActivosFoliosAct: action.listaReingresoActivosFoliosAct };
          case AlmacenesActions.REINGRESO_ACTIVOS_LISTA_ACT_FAILURE:
               return { ...state, fetchinglistaReingresoActivosFoliosAct: false };


          case AlmacenesActions.REINGRESO_ACTIVOS_BUSQUEDA_REQUEST:
               return { ...state, fetchinglistaReingresoActivosBusqueda: true };
          case AlmacenesActions.REINGRESO_ACTIVOS_BUSQUEDA_SUCCESS:
               return { ...state, fetchinglistaReingresoActivosBusqueda: false, listaReingresoActivosBusqueda: action.listaReingresoActivosBusqueda, listaReingresoActivosBusquedaCantidad: action.listaReingresoActivosBusquedaCantidad };
          case AlmacenesActions.ASIGNACION_ACTIVOS_BUSQUEDA_FAILURE:
               return { ...state, fetchinglistaReingresoActivosBusqueda: false };

          case AlmacenesActions.NUEVO_REINGRESO_ACTIVO_REQUEST:
               return { ...state, fetchingNuevoReingresoActivo: true };
          case AlmacenesActions.NUEVO_REINGRESO_ACTIVO_SUCCESS:
               return { ...state, fetchingNuevoReingresoActivo: false, asignacionPDF: action.asignacionPDF, asignacionXML: action.asignacionXML, errorAsignacionPDF: null, descargar: action.descargar, hasSelected: action.hasSelected };
          case AlmacenesActions.NUEVO_REINGRESO_ACTIVO_FAILURE:
               return { ...state, fetchingNuevoReingresoActivo: false, errorAsignacionPDF: action.errorAsignacionPDF, asignacionPDF: action.asignacionPDF, descargar: action.descargar, hasSelected: action.hasSelected };

          case AlmacenesActions.SHOW_MODAL_NUEVO_REINGRESO:
               return { ...state, showModalReingresoActivos: action.showModalReingresoActivos, reingresoSeleccionado: action.reingresoSeleccionado, page: action.page };

          case AlmacenesActions.MOTIVOS_DE_SALIDA_ACTIVOS_REQUEST:
               return { ...state, fetchingListaMotivosSalidaActivos: true };
          case AlmacenesActions.MOTIVOS_DE_SALIDA_ACTIVOS_SUCCESS:
               return {
                    ...state, fetchingListaMotivosSalidaActivos: false, listaMotivosSalidaActivos: action.listaMotivosSalidaActivos,
               };
          case AlmacenesActions.MOTIVOS_DE_SALIDA_ACTIVOS_FAILURE:
               return { ...state, fetchingListaMotivosSalidaActivos: false };

          // Salida de Bienes

          case AlmacenesActions.SHOW_MODAL_SALIDA_BIENES:
               return { ...state, showModalSalidaDeBienes: action.showModalSalidaDeBienes, datosSolicitudSeleccionados: action.datosSolicitudSeleccionados };

          case AlmacenesActions.NUEVA_SOLICITUD_SALIDA_BIENES_REQUEST:
               return { ...state, fetchingNuevaSalidaDeBienes: true, errorSalidaPDF: null };
          case AlmacenesActions.NUEVA_SOLICITUD_SALIDA_BIENES_SUCCESS:
               return { ...state, fetchingNuevaSalidaDeBienes: false, salidaPDF: action.salidaPDF, errorSalidaPDF: null };
          case AlmacenesActions.NUEVA_SOLICITUD_SALIDA_BIENES_FAILURE:
               return { ...state, fetchingNuevaSalidaDeBienes: false, errorSalidaPDF: action.errorSalidaPDF, salidaPDF: action.salidaPDF };

          case AlmacenesActions.CAMBIAR_VISTA_SALIDA_BIENES:
               return { ...state, vistaModalSalidaDeBienes: action.vistaModalSalidaDeBienes, formularioSalidaDeBienes: action.formularioSalidaDeBienes, salidaActivoSeleccionado: action.salidaActivoSeleccionado };

          case AlmacenesActions.ACTUALIZAR_TABLA_SALIDA:
               return { ...state, salidaActivoSeleccionado: action.salidaActivoSeleccionado, selectedRowKeys: action.selectedRowKeys, hasSelected: action.hasSelected, page: action.page };

          case AlmacenesActions.GENERAR_FOLIO_SALIDA_REQUEST:
               return { ...state, fetchingFolioSalidaDeBienes: true };
          case AlmacenesActions.GENERAR_FOLIO_SALIDA_SUCCESS:
               return { ...state, fetchingFolioSalidaDeBienes: false, tipoMovimiento: action.tipoMovimiento };
          case AlmacenesActions.GENERAR_FOLIO_SALIDA_FAILURE:
               return { ...state, fetchingFolioSalidaDeBienes: false, servicioSalida: action.servicioSalida, idServicioSalida: action.idServicioSalida, motivoSalida: action.motivoSalida, motivoSalidaTex: action.motivoSalidaTex, idMotivoSalidaLocal: action.idMotivoSalidaLocal };

          case AlmacenesActions.LISTA_SALIDA_BIENES_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaSalidaDeBienesBusqueda: true };
          case AlmacenesActions.LISTA_SALIDA_BIENES_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaSalidaDeBienesBusqueda: false, listaSalidaDeBienesBusqueda: action.listaSalidaDeBienesBusqueda, listaSalidaDeBienesBusquedaCantidad: action.listaSalidaDeBienesBusquedaCantidad };
          case AlmacenesActions.LISTA_SALIDA_BIENES_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaSalidaDeBienesBusqueda: false };

          case AlmacenesActions.LISTA_ACTIVOS_OPERACION_SERVICIO_REQUEST:
               return { ...state, fetchingListaActivosEnOperacionServicio: true };
          case AlmacenesActions.LISTA_ACTIVOS_OPERACION_SERVICIO_SUCCESS:
               return { ...state, fetchingListaAsignacionActivosActivos: false, listaActivosEnOperacionServicio: action.listaActivosEnOperacionServicio };
          case AlmacenesActions.LISTA_ACTIVOS_OPERACION_SERVICIO_FAILURE:
               return { ...state, fetchingListaActivosEnOperacionServicio: false };

          //ACTUALIZACION TECNOLOGICA ACTIVO 

          case AlmacenesActions.ACTUALIZACION_ACTIVO_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaActualizacionActivosBusqueda: true };
          case AlmacenesActions.ACTUALIZACION_ACTIVO_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaActualizacionActivosBusqueda: false, listaActualizacionActivosBusqueda: action.listaActualizacionActivosBusqueda, listaActualizacionActivosBusquedaCantidad: action.listaActualizacionActivosBusquedaCantidad };
          case AlmacenesActions.ACTUALIZACION_ACTIVO_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaActualizacionActivosBusqueda: false };

          case AlmacenesActions.ACTUALIZACION_ACTIVO_DESCARGA_REQUEST:
               return { ...state, fetchingActualizacionActivosDescarga: true };
          case AlmacenesActions.ACTUALIZACION_ACTIVO_DESCARGA_SUCCESS:
               return { ...state, fetchingActualizacionActivosDescarga: false };
          case AlmacenesActions.ACTUALIZACION_ACTIVO_DESCARGA_FAILURE:
               return { ...state, fetchingActualizacionActivosDescarga: false };

          case AlmacenesActions.SHOW_MODAL_ACTUALIZACION_ACTIVO_DETALLE:
               return { ...state, showModalDetalleActivo: action.showModalDetalleActivo, ActualizacionActivoSeleccionado: action.ActualizacionActivoSeleccionado, page: action.page };

          // Almacenes

          case AlmacenesActions.ALMACEN_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaAlmacenesBusqueda: true };
          case AlmacenesActions.ALMACEN_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaAlmacenesBusqueda: false, listaAlmacenesBusqueda: action.listaAlmacenesBusqueda, listaAlmacenesBusquedaCantidad: action.listaAlmacenesBusquedaCantidad };
          case AlmacenesActions.ALMACEN_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaAlmacenesBusqueda: false };

          case AlmacenesActions.ESTADO_ALMACEN_REQUEST:
               return { ...state, fetchingListaAlmacenesBusqueda: true };
          case AlmacenesActions.ESTADO_ALMACEN_SUCCESS:
               return { ...state, fetchingListaAlmacenesBusqueda: false };
          case AlmacenesActions.ESTADO_ALMACEN_FAILURE:
               return { ...state, fetchingListaAlmacenesBusqueda: false };

          case AlmacenesActions.SHOW_MODAL_NUEVO_ALMACEN:
               return { ...state, showModalNuevoAlmacen: action.showModalNuevoAlmacen, page: action.page };

          case AlmacenesActions.SHOW_MODAL_EDITAR_ALMACEN:
               return { ...state, showModalEditarAlmacen: action.showModalEditarAlmacen, almacenSeleccionado: action.almacenSeleccionado, page: action.page, busqueda: action.busqueda };

          case AlmacenesActions.NUEVO_ALMACEN_REQUEST:
               return { ...state, fetchingNuevoAlmacen: true };
          case AlmacenesActions.NUEVO_ALMACEN_SUCCESS:
               return { ...state, fetchingNuevoAlmacen: false };
          case AlmacenesActions.NUEVO_ALMACEN_FAILURE:
               return { ...state, fetchingNuevoAlmacen: false };

          case AlmacenesActions.EDITAR_ALMACEN_REQUEST:
               return { ...state, fetchingEditarAlmacen: true };
          case AlmacenesActions.EDITAR_ALMACEN_SUCCESS:
               return { ...state, fetchingEditarAlmacen: false };
          case AlmacenesActions.EDITAR_ALMACEN_FAILURE:
               return { ...state, fetchingEditarAlmacen: false };

          case AlmacenesActions.EXPORTAR_ALMACENES_EXCEL_REQUEST:
               return { ...state, fetchingExportarExcelAlmacenes: true };
          case AlmacenesActions.EXPORTAR_ALMACENES_EXCEL_SUCCESS:
               return { ...state, fetchingExportarExcelAlmacenes: false };
          case AlmacenesActions.EXPORTAR_ALMACENES_EXCEL_FAILURE:
               return { ...state, fetchingExportarExcelAlmacenes: false };

          //CÁTALOGO TIPOS FOLIOS DE MOVIMIENTOS
          case AlmacenesActions.LISTA_TIPOS_FOLIOS_ACTIVOS_REQUEST:
               return { ...state, fetchingListaTiposFoliosMovimientosActivos: true };
          case AlmacenesActions.LISTA_TIPOS_FOLIOS_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaTiposFoliosMovimientosActivos: false, listaTiposFoliosMovimientosActivos: action.listaTiposFoliosMovimientosActivos };
          case AlmacenesActions.LISTA_TIPOS_FOLIOS_ACTIVOS_FAILURE:
               return { ...state, fetchingListaTiposFoliosMovimientosActivos: false };

          case AlmacenesActions.TIPOS_FOLIOS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaTiposFoliosMovimientosBusqueda: true };
          case AlmacenesActions.TIPOS_FOLIOS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaTiposFoliosMovimientosBusqueda: false, listaTiposFoliosMovimientosBusqueda: action.listaTiposFoliosMovimientosBusqueda, listaTiposFoliosMovimientosBusquedaCantidad: action.listaTiposFoliosMovimientosBusquedaCantidad };
          case AlmacenesActions.TIPOS_FOLIOS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaTiposFoliosMovimientosBusqueda: false };

          case AlmacenesActions.ESTADO_TIPOS_FOLIOS_REQUEST:
               return { ...state, fetchingListaTiposFoliosMovimientosBusqueda: true };
          case AlmacenesActions.ESTADO_TIPOS_FOLIOS_SUCCESS:
               return { ...state, fetchingListaTiposFoliosMovimientosBusqueda: false };
          case AlmacenesActions.ESTADO_TIPOS_FOLIOS_FAILURE:
               return { ...state, fetchingListaTiposFoliosMovimientosBusqueda: false };

          case AlmacenesActions.SHOW_MODAL_NUEVO_TIPO_FOLIO:
               return { ...state, showModalNuevoTipoFolioMovimiento: action.showModalNuevoTipoFolioMovimiento, page: action.page };

          case AlmacenesActions.SHOW_MODAL_EDITAR_TIPO_FOLIO:
               return { ...state, showModalEditarTipoFolioMovimiento: action.showModalEditarTipoFolioMovimiento, TipoFolioMovimientoSeleccionado: action.TipoFolioMovimientoSeleccionado, page: action.page };

          case AlmacenesActions.NUEVO_TIPO_FOLIO_REQUEST:
               return { ...state, fetchingNuevoTipoFolioMovimiento: true };
          case AlmacenesActions.NUEVO_TIPO_FOLIO_SUCCESS:
               return { ...state, fetchingNuevoTipoFolioMovimiento: false };
          case AlmacenesActions.NUEVO_TIPO_FOLIO_FAILURE:
               return { ...state, fetchingNuevoTipoFolioMovimiento: false };

          case AlmacenesActions.EDITAR_TIPO_FOLIO_REQUEST:
               return { ...state, fetchingEditarTipoFolioMovimiento: true };
          case AlmacenesActions.EDITAR_TIPO_FOLIO_SUCCESS:
               return { ...state, fetchingEditarTipoFolioMovimiento: false };
          case AlmacenesActions.EDITAR_TIPO_FOLIO_FAILURE:
               return { ...state, fetchingEditarTipoFolioMovimiento: false };

          case AlmacenesActions.ACTUALIZAR_TABLA_MOVIMIENTOS:
               return { ...state, listaSeleccionados: action.listaSeleccionados, selectedRowKeys: action.selectedRowKeys, pageMovimientos: action.pageMovimientos, hasSelected: action.hasSelected }

          case AlmacenesActions.EXPORTAR_TIPOS_FOLIO_EXCEL_REQUEST:
               return { ...state, fetchingExportarExcelTiposFolios: true };
          case AlmacenesActions.EXPORTAR_TIPOS_FOLIO_EXCEL_SUCCESS:
               return { ...state, fetchingExportarExcelTiposFolios: false };
          case AlmacenesActions.EXPORTAR_TIPOS_FOLIO_EXCEL_FAILURE:
               return { ...state, fetchingExportarExcelTiposFolios: false };

          //CÁTALOGO DE PRODUCTOS
          case AlmacenesActions.LISTA_PRODUCTOS_ACTIVOS_REQUEST:
               return { ...state, fetchingListaProductosActivos: true };
          case AlmacenesActions.LISTA_PRODUCTOS_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaProductosActivos: false, listaProductosActivos: action.listaProductosActivos };
          case AlmacenesActions.LISTA_PRODUCTOS_ACTIVOS_FAILURE:
               return { ...state, fetchingListaProductosActivos: false };

          case AlmacenesActions.LISTA_PRODUCTOS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaProductosActivosBusqueda: true };
          case AlmacenesActions.LISTA_PRODUCTOS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaProductosActivosBusqueda: false, listaProductosActivosBusqueda: action.listaProductosActivosBusqueda };
          case AlmacenesActions.LISTA_PRODUCTOS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaProductosActivosBusqueda: false };

          case AlmacenesActions.LISTA_HERRAMIENTAS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaHerramientasActivasBusqueda: true };
          case AlmacenesActions.LISTA_HERRAMIENTAS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaHerramientasActivasBusqueda: false, listaHerramientasActivasBusqueda: action.listaHerramientasActivasBusqueda };
          case AlmacenesActions.LISTA_HERRAMIENTAS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaHerramientasActivasBusqueda: false };

          case AlmacenesActions.PRODUCTOS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaProductosBusqueda: true };
          case AlmacenesActions.PRODUCTOS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaProductosBusqueda: false, listaProductosBusqueda: action.listaProductosBusqueda, listaProductosBusquedaCantidad: action.listaProductosBusquedaCantidad };
          case AlmacenesActions.PRODUCTOS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaProductosBusqueda: false };

          case AlmacenesActions.ESTADO_PRODUCTO_REQUEST:
               return { ...state, fetchingListaProductosBusqueda: true };
          case AlmacenesActions.ESTADO_PRODUCTO_SUCCESS:
               return { ...state, fetchingListaProductosBusqueda: false };
          case AlmacenesActions.ESTADO_PRODUCTO_FAILURE:
               return { ...state, fetchingListaProductosBusqueda: false };

          case AlmacenesActions.SHOW_MODAL_NUEVO_PRODUCTO:
               return { ...state, showModalNuevoProducto: action.showModalNuevoProducto, page: action.page };

          case AlmacenesActions.SHOW_MODAL_EDITAR_PRODUCTO:
               return { ...state, showModalEditarProducto: action.showModalEditarProducto, productoSeleccionado: action.productoSeleccionado, page: action.page, busqueda: action.busqueda };

          case AlmacenesActions.SHOW_MODAL_DETALLE_PRODUCTO:
               return { ...state, showModalDetalleProducto: action.showModalDetalleProducto, productoSeleccionado: action.productoSeleccionado };

          case AlmacenesActions.NUEVO_PRODUCTO_REQUEST:
               return { ...state, fetchingNuevoProducto: true };
          case AlmacenesActions.NUEVO_PRODUCTO_SUCCESS:
               return { ...state, fetchingNuevoProducto: false };
          case AlmacenesActions.NUEVO_PRODUCTO_FAILURE:
               return { ...state, fetchingNuevoProducto: false };

          case AlmacenesActions.EDITAR_PRODUCTO_REQUEST:
               return { ...state, fetchingEditarProducto: true };
          case AlmacenesActions.EDITAR_PRODUCTO_SUCCESS:
               return { ...state, fetchingEditarProducto: false };
          case AlmacenesActions.EDITAR_PRODUCTO_FAILURE:
               return { ...state, fetchingEditarProducto: false };

          case AlmacenesActions.EXPORTAR_PRODUCTOS_EXCEL_REQUEST:
               return { ...state, fetchingExportarExcelProductos: true };
          case AlmacenesActions.EXPORTAR_PRODUCTOS_EXCEL_SUCCESS:
               return { ...state, fetchingExportarExcelProductos: false };
          case AlmacenesActions.EXPORTAR_PRODUCTOS_EXCEL_FAILURE:
               return { ...state, fetchingExportarExcelProductos: false };


          // reducers lista movimientos de almacen 
          case AlmacenesActions.LISTA_MOVIMIENTOS_GENERAL_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaMovimientosGeneralBusqueda: true };
          case AlmacenesActions.LISTA_MOVIMIENTOS_GENERAL_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaMovimientosGeneralBusqueda: false, listaMovimientosGeneralBusqueda: action.listaMovimientosGeneralBusqueda, listaMovimientosGeneralBusquedaCantidad: action.listaMovimientosGeneralBusquedaCantidad };
          case AlmacenesActions.LISTA_MOVIMIENTOS_GENERAL_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaMovimientosGeneralBusqueda: false };

          case AlmacenesActions.SHOW_MODAL_OPCIONES_MOVIMIENTOS_ALMACEN:
               return { ...state, showModalOpcionesMovimientosAlmacen: action.showModalOpcionesMovimientosAlmacen, movimientoSeleccionado: action.movimientoSeleccionado, page: action.page, busqueda: action.busqueda };


          case AlmacenesActions.LISTA_DETALLE_CONSULTA_REQUEST:
               return { ...state, fetchinglistaDetalle: true };
          case AlmacenesActions.LISTA_DETALLE_CONSULTA_SUCCESS:
               return { ...state, fetchinglistaDetalle: false, listaDetalle: action.listaDetalle, listaDetalleCantidad: action.listaDetalleCantidad };
          case AlmacenesActions.LISTA_DETALLE_CONSULTA_FAILURE:
               return { ...state, fetchinglistaDetalle: false };


          case AlmacenesActions.CANCELAR_FOLIO_REQUEST:
               return { ...state, fetchingCancelarFolio: true };
          case AlmacenesActions.CANCELAR_FOLIO_SUCCESS:
               return {...state, fetchingCancelarFolio: false };  
          case AlmacenesActions.CANCELAR_FOLIO_FAILURE:
               return {...state, fetchingCancelarFolio: false }; 
               
          case AlmacenesActions.NUEVO_FOLIO_REQUEST:
               return {...state, fetchingNuevoFolio: true};   
          case AlmacenesActions.NUEVO_FOLIO_SUCCESS:
               return {...state, fetchingNuevoFolio: false}; 
          case AlmacenesActions.NUEVO_FOLIO_FAILURE:
               return {...state, fetchingNuevoFolio: false};    
                
          case AlmacenesActions.SHOW_MODAL_NUEVO_FOLIO:
               return { ...state, showModalNuevoFolio: action.showModalNuevoFolio, page: action.page, busqueda: action.busqueda};    
                 
          case AlmacenesActions.CAMBIAR_VISTA_MODAL_NUEVOFOLIO:
               return { ...state, vistaModalNuevoFolio: action.vistaModalNuevoFolio, formularioNuevoFolio: action.formularioNuevoFolio, listaSeleccionados: action.listaSeleccionados, tipoActivos: action.tipoActivos };


          case AlmacenesActions.LISTA_INVENTARIO_REQUEST:
               return { ...state, fetchingListaProductosBusqueda: true };
          case AlmacenesActions.LISTA_INVENTARIO_SUCCESS:
               return { ...state, fetchingListaProductosBusqueda: false, listaProductosBusqueda: action.listaProductosBusqueda, listaProductosBusquedaCantidad: action.listaProductosBusquedaCantidad };
          case AlmacenesActions.LISTA_INVENTARIO_FAILURE:
               return { ...state, fetchingListaProductosBusqueda: false };

          case AlmacenesActions.LISTA_REQUISICION_DIRECTA_REQUEST:
               return { ...state, fetchingListaProductosBusqueda: true };
          case AlmacenesActions.LISTA_REQUISICION_DIRECTA_SUCCESS:
               return { ...state, fetchingListaProductosBusqueda: false, listaProductosBusqueda: action.listaProductosBusqueda, listaProductosBusquedaCantidad: action.listaProductosBusquedaCantidad };
          case AlmacenesActions.LISTA_REQUISICION_DIRECTA_FAILURE:
               return { ...state, fetchingListaProductosBusqueda: false };

          case AlmacenesActions.SHOW_MODAL_MODIFICAR_REGISTRO_NUEVOFOLIO:
               return { ...state, showModalModificarRegistroNuevoFolio: action.showModalModificarRegistroNuevoFolio, registroProductoSeleccionado: action.registroProductoSeleccionado }

          case AlmacenesActions.SHOW_MODAL_LISTA_SELECCIONADOS_NUEVOFOLIO:
               return { ...state, showModalListaSeleccionadosNuevoFolio: action.showModalListaSeleccionadosNuevoFolio };

          //REDUCERS LISTA INVENTARIO TIPO ACTIVOS
          case AlmacenesActions.LISTA_INVENTARIO_TIPO_ACTIVOS_REQUEST:
               return { ...state, fetchingListaInventarioTipoActivos: true };
          case AlmacenesActions.LISTA_INVENTARIO_TIPO_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaInventarioTipoActivos: false, listaInventarioTipoActivos: action.listaInventarioTipoActivos, listaInventarioTipoActivosCantidad: action.listaInventarioTipoActivosCantidad };
          case AlmacenesActions.LISTA_INVENTARIO_TIPO_ACTIVOS_FAILURE:
               return { ...state, fetchingListaInventarioTipoActivos: false };

          //REDUCERS LISTA INVENTARIO CONSULTA
          case AlmacenesActions.IMPRIMIR_FOLIO_REQUEST:
               return { ...state, fetchingModalOpciones: true };
          case AlmacenesActions.IMPRIMIR_FOLIO_SUCCESS:
               return { ...state, fetchingModalOpciones: false };
          case AlmacenesActions.IMPRIMIR_FOLIO_FAILURE:
               return { ...state, fetchingModalOpciones: false };

          case AlmacenesActions.OBTENER_UBICACION_POR_ALMACEN_REQUEST:
               return { ...state, fetchingObtenerUbicacion: true };
          case AlmacenesActions.OBTENER_UBICACION_POR_ALMACEN_SUCCESS:
               return { ...state, fetchingObtenerUbicacion: false, listaUbicaciones: action.listaUbicaciones };
          case AlmacenesActions.OBTENER_UBICACION_POR_ALMACEN_FAILURE:
               return { ...state, fetchingObtenerUbicacion: false };

          case AlmacenesActions.UBICACION_DESTINO_POR_ALMACEN_REQUEST:
               return { ...state, fetchingObtenerUbicacionDestino: true };
          case AlmacenesActions.UBICACION_DESTINO_POR_ALMACEN_SUCCESS:
               return { ...state, fetchingObtenerUbicacionDestino: false, listaUbicacionesDestino: action.listaUbicacionesDestino };
          case AlmacenesActions.UBICACION_DESTINO_POR_ALMACEN_FAILURE:
               return { ...state, fetchingObtenerUbicacionDestino: false };

          case AlmacenesActions.CERRAR_FOLIO_SIN_SALIDA_REQUEST:
               return { ...state, fetchingCerrarSinSalidaFolio: true };
          case AlmacenesActions.CERRAR_FOLIO_SIN_SALIDA_SUCCESS:
               return { ...state, fetchingCerrarSinSalidaFolio: false };
          case AlmacenesActions.CERRAR_FOLIO_SIN_SALIDA_FAILURE:
               return { ...state, fetchingCerrarSinSalidaFolio: false };

          case AlmacenesActions.CERRAR_FOLIO_REQUEST:
               return { ...state, fetchingCerrarFolio: true };
          case AlmacenesActions.CERRAR_FOLIO_SUCCESS:
               return { ...state, fetchingCerrarFolio: false };
          case AlmacenesActions.CERRAR_FOLIO_FAILURE:
               return { ...state, fetchingCerrarFolio: false };

          case AlmacenesActions.EDITAR_FOLIO_REQUEST:
               return { ...state, fetchingEditarFolio: true };
          case AlmacenesActions.EDITAR_FOLIO_SUCCESS:
               return { ...state, fetchingEditarFolio: false };
          case AlmacenesActions.EDITAR_FOLIO_FAILURE:
               return { ...state, fetchingEditarFolio: false };

          case AlmacenesActions.CAMBIAR_VISTA_MODAL_EDITARFOLIO:
               return { ...state, vistaModalEditarFolio: action.vistaModalEditarFolio, formularioEditarFolio: action.formularioEditarFolio, listaSeleccionadosEditar: action.listaSeleccionadosEditar, tipoActivos: action.tipoActivos, busqueda: action.busqueda };

          case AlmacenesActions.SHOW_MODAL_EDITAR_FOLIO:
               return { ...state, showModalEditarFolio: action.showModalEditarFolio, movimientoSeleccionado: action.movimientoSeleccionado, page: action.page }

          case AlmacenesActions.SHOW_MODAL_MODIFICAR_EDITARFOLIO:
               return { ...state, showModalModificarEditarFolio: action.showModalModificarEditarFolio, registroProductoSeleccionado: action.registroProductoSeleccionado, page: action.page }

          case AlmacenesActions.CANCELAR_PRODUCTO_REQUEST:
               return { ...state, fetchingCancelarProducto: true };
          case AlmacenesActions.CANCELAR_PRODUCTO_SUCCESS:
               return { ...state, fetchingCancelarProducto: false };
          case AlmacenesActions.CANCELAR_PRODUCTO_FAILURE:
               return { ...state, fetchingCancelarProducto: false };

          //REDUCERS SALIDA POR INSTALACIÓN
          case AlmacenesActions.SHOW_MODAL_CREAR_INSTALACION:
               return { ...state, showModalCrearActivos: action.showModalCrearActivos };

          case AlmacenesActions.SALIDA_INSTALACION_REQUEST:
               return { ...state, fetchingSalidaInstalacion: true };
          case AlmacenesActions.SALIDA_INSTALACION_SUCCESS:
               return { ...state, fetchingSalidaInstalacion: false };
          case AlmacenesActions.SALIDA_INSTALACION_FAILURE:
               return { ...state, fetchingSalidaInstalacion: false }; 

          case AlmacenesActions.NUEVO_AGREGAR_PRODUCTOS_REQUEST:
               return { ...state, fetchingEditarFolio: true };
          case AlmacenesActions.NUEVO_AGREGAR_PRODUCTOS_SUCCESS:
               return { ...state, fetchingEditarFolio: false };
          case AlmacenesActions.NUEVO_AGREGAR_PRODUCTOS_FAILURE:
               return { ...state, fetchingEditarFolio: false };

          case AlmacenesActions.OBTENER_ALMACEN_POR_UBICACION_REQUEST:
               return { ...state, fetchingObtenerAlmacenPorUbicacion: true };
          case AlmacenesActions.OBTENER_ALMACEN_POR_UBICACION_SUCCESS:
               return { ...state, fetchingObtenerAlmacenPorUbicacion: false, almacenOrigen: action.almacenOrigen };
          case AlmacenesActions.OBTENER_ALMACEN_POR_UBICACION_FAILURE:
               return { ...state, fetchingObtenerAlmacenPorUbicacion: false };

          case AlmacenesActions.OBTENER_ALMACEN_POR_UBICACION_DESTINO_REQUEST:
               return { ...state, fetchingObtenerAlmacenPorUbicacionDestino: true };
          case AlmacenesActions.OBTENER_ALMACEN_POR_UBICACION_DESTINO_SUCCESS:
               return { ...state, fetchingObtenerAlmacenPorUbicacionDestino: false, almacenDestino: action.almacenDestino };
          case AlmacenesActions.OBTENER_ALMACEN_POR_UBICACION_DESTINO_FAILURE:
               return { ...state, fetchingObtenerAlmacenPorUbicacionDestino: false };

          case AlmacenesActions.OBTENER_INFO_IDINVENTARIO_REQUEST:
               return { ...state, fetchingInfoIdInventario: true };
          case AlmacenesActions.OBTENER_INFO_IDINVENTARIO_SUCCESS:
               return { ...state, fetchingInfoIdInventario: false, productoInventario: action.productoInventario };
          case AlmacenesActions.OBTENER_INFO_IDINVENTARIO_FAILURE:
               return { ...state, fetchingInfoIdInventario: false };

          case AlmacenesActions.LISTA_PRODUCTOS_FILTRADO_REQUEST:
               return { ...state, fetchingListaProductosFiltradoRequest: true };
          case AlmacenesActions.LISTA_PRODUCTOS_FILTRADO_SUCCESS:
               return { ...state, fetchingListaProductosFiltradoRequest: false, listaProductosFiltradoRequest: action.listaProductosFiltradoRequest, listaProductosFiltradoRequestCantidad: action.listaProductosFiltradoRequestCantidad };
          case AlmacenesActions.LISTA_PRODUCTOS_FILTRADO_FAILURE:
               return { ...state, fetchingListaProductosFiltradoRequest: false };

          case AlmacenesActions.LISTA_INVENTARIO_FILTRADO_REQUEST:
               return { ...state, fetchingListaProductosFiltradoRequest: true };
          case AlmacenesActions.LISTA_INVENTARIO_FILTRADO_SUCCESS:
               return { ...state, fetchingListaProductosFiltradoRequest: false, listaProductosFiltradoRequest: action.listaProductosFiltradoRequest, listaProductosFiltradoRequestCantidad: action.listaProductosFiltradoRequestCantidad };
          case AlmacenesActions.LISTA_INVENTARIO_FILTRADO_FAILURE:
               return { ...state, fetchingListaProductosFiltradoRequest: false };

          case AlmacenesActions.LISTA_REQUISICION_FILTRADO_REQUEST:
               return { ...state, fetchingListaProductosFiltradoRequest: true };
          case AlmacenesActions.LISTA_REQUISICION_FILTRADO_SUCCESS:
               return { ...state, fetchingListaProductosFiltradoRequest: false, listaProductosFiltradoRequest: action.listaProductosFiltradoRequest, listaProductosFiltradoRequestCantidad: action.listaProductosFiltradoRequestCantidad };
          case AlmacenesActions.LISTA_REQUISICION_FILTRADO_FAILURE:
               return { ...state, fetchingListaProductosFiltradoRequest: false };


          // REQUISICION PUNTO REORDEN
          case AlmacenesActions.REQUISICION_PUNTO_REORDEN_BUSQUEDA_REQUEST:
               return { ...state, fetchingRequisicionPuntoReordenBusqueda: true };
          case AlmacenesActions.REQUISICION_PUNTO_REORDEN_BUSQUEDA_SUCCESS:
               return { ...state, fetchingRequisicionPuntoReordenBusqueda: false, listaRequisicionPuntoReorden: action.listaRequisicionPuntoReorden, listaRequisicionPuntoReordenCantidad: action.listaRequisicionPuntoReordenCantidad };
          case AlmacenesActions.REQUISICION_PUNTO_REORDEN_BUSQUEDA_FAILURE:
               return { ...state, fetchingRequisicionPuntoReordenBusqueda: false };

          case AlmacenesActions.GENERAR_REQUISICION_PUNTO_REORDEN_REQUEST:
               return { ...state, fetchingRequisicionPuntoReordenNuevo: true };
          case AlmacenesActions.GENERAR_REQUISICION_PUNTO_REORDEN_SUCCESS:
               return { ...state, fetchingRequisicionPuntoReordenNuevo: false };
          case AlmacenesActions.GENERAR_REQUISICION_PUNTO_REORDEN_FAILURE:
               return { ...state, fetchingRequisicionPuntoReordenNuevo: false };

          case AlmacenesActions.CAMBIAR_VISTA_PUNTO_REORDEN:
               return { ...state, vistaPuntoReorden: action.vistaPuntoReorden };

          case AlmacenesActions.SHOW_MODAL_REQUISICION_PUNTO_REORDEN:
               return { ...state, showModalRequisicionPuntoReorden: action.showModalRequisicionPuntoReorden, folioSeleccionado: action.folioSeleccionado, page: action.page, busqueda: action.busqueda };

          case AlmacenesActions.CONSULTA_FOLIO_REQUISICION_REQUEST:
               return { ...state, fetchingListaConsultaFolio: true };
          case AlmacenesActions.CONSULTA_FOLIO_REQUISICION_SUCCESS:
               return { ...state, fetchingListaConsultaFolio: false, listaConsultaFolio: action.listaConsultaFolio, listaConsultaFolioCantidad: action.listaConsultaFolioCantidad };
          case AlmacenesActions.CONSULTA_FOLIO_REQUISICION_FAILURE:
               return { ...state, fetchingListaConsultaFolio: false };

          case AlmacenesActions.SHOW_MODAL_EDITAR_PRODUCTO_REQUISICION:
               return { ...state, showModalEditarProductoRequsicion: action.showModalEditarProductoRequsicion, productoReqSeleccionado: action.productoReqSeleccionado };

          case AlmacenesActions.EDITAR_PRODUCTO_REQUISICION_PUNTOREORDEN_REQUEST:
               return { ...state, fetchingEditarProductoRequisicionPuntoReorden: true };
          case AlmacenesActions.EDITAR_PRODUCTO_REQUISICION_PUNTOREORDEN_SUCCESS:
               return { ...state, fetchingEditarProductoRequisicionPuntoReorden: false };
          case AlmacenesActions.EDITAR_PRODUCTO_REQUISICION_PUNTOREORDEN_FAILURE:
               return { ...state, fetchingEditarProductoRequisicionPuntoReorden: false };

          case AlmacenesActions.CERRAR_FOLIO_REQUISICION_PUNTOREORDEN_REQUEST:
               return { ...state, fetchingCerrarFolioRequisicionPuntoReorden: true };
          case AlmacenesActions.CERRAR_FOLIO_REQUISICION_PUNTOREORDEN_SUCCESS:
               return { ...state, fetchingCerrarFolioRequisicionPuntoReorden: false };
          case AlmacenesActions.CERRAR_FOLIO_REQUISICION_PUNTOREORDEN_FAILURE:
               return { ...state, fetchingCerrarFolioRequisicionPuntoReorden: false };

          case AlmacenesActions.SHOW_MODAL_CERRAR_FOLIO_REQUISICION:
               return { ...state, showModalCerrarFolioRequisicion: action.showModalCerrarFolioRequisicion };

          case AlmacenesActions.DESCARGAR_FOLIO_REQUISICION_PUNTOREORDEN_REQUEST:
               return { ...state, fetchingDescargarFolioRequisicionPuntoReorden: true };
          case AlmacenesActions.DESCARGAR_FOLIO_REQUISICION_PUNTOREORDEN_SUCCESS:
               return { ...state, fetchingDescargarFolioRequisicionPuntoReorden: false };
          case AlmacenesActions.DESCARGAR_FOLIO_REQUISICION_PUNTOREORDEN_FAILURE:
               return { ...state, fetchingDescargarFolioRequisicionPuntoReorden: false };

          case AlmacenesActions.DESCARGAR_FOLIO_REQUISICION_COMPRA_REQUEST:
               return { ...state, fetchingDescargarFolioRequisicionCompra: true };
          case AlmacenesActions.DESCARGAR_FOLIO_REQUISICION_COMPRA_SUCCESS:
               return { ...state, fetchingDescargarFolioRequisicionCompra: false };
          case AlmacenesActions.DESCARGAR_FOLIO_REQUISICION_COMPRA_FAILURE:
               return { ...state, fetchingDescargarFolioRequisicionCompra: false };

          case AlmacenesActions.SHOW_MODAL_DESCARGAR_FOLIO:
               return { ...state, showModalDescargarFolio: action.showModalDescargarFolio, folioSeleccionado: action.folioSeleccionado }

          case AlmacenesActions.CANCELAR_FOLIO_REQUISICION_PUNTOREORDEN_REQUEST:
               return { ...state, fetchingCancelarFolioRequisicionPuntoReorden: true };
          case AlmacenesActions.CANCELAR_FOLIO_REQUISICION_PUNTOREORDEN_SUCCESS:
               return { ...state, fetchingCancelarFolioRequisicionPuntoReorden: false };
          case AlmacenesActions.CANCELAR_FOLIO_REQUISICION_PUNTOREORDEN_FAILURE:
               return { ...state, fetchingCancelarFolioRequisicionPuntoReorden: false };

          //CÁTALOGO DE UBICACIONES DE PRODUCTOS
          case AlmacenesActions.LISTA_UBICACIONES_PRODUCTOS_ACTIVOS_REQUEST:
               return { ...state, fetchingListaUbicacionesProductosActivos: true };
          case AlmacenesActions.LISTA_UBICACIONES_PRODUCTOS_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaUbicacionesProductosActivos: false, listaUbicacionesProductosActivos: action.listaUbicacionesProductosActivos };
          case AlmacenesActions.LISTA_UBICACIONES_PRODUCTOS_ACTIVOS_FAILURE:
               return { ...state, fetchingListaUbicacionesProductosActivos: false };

          case AlmacenesActions.UBICACIONES_PRODUCTOS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaUbicacionesProductosBusqueda: true };
          case AlmacenesActions.UBICACIONES_PRODUCTOS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaUbicacionesProductosBusqueda: false, listaUbicacionesProductosBusqueda: action.listaUbicacionesProductosBusqueda, listaUbicacionesProductosBusquedaCantidad: action.listaUbicacionesProductosBusquedaCantidad };
          case AlmacenesActions.UBICACIONES_PRODUCTOS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaUbicacionesProductosBusqueda: false };

          case AlmacenesActions.ESTADO_UBICACION_PRODUCTO_REQUEST:
               return { ...state, fetchingListaUbicacionesProductosBusqueda: true };
          case AlmacenesActions.ESTADO_UBICACION_PRODUCTO_SUCCESS:
               return { ...state, fetchingListaUbicacionesProductosBusqueda: false };
          case AlmacenesActions.ESTADO_UBICACION_PRODUCTO_FAILURE:
               return { ...state, fetchingListaUbicacionesProductosBusqueda: false };

          case AlmacenesActions.SHOW_MODAL_NUEVA_UBICACION_PRODUCTO:
               return { ...state, showModalNuevaUbicacionProducto: action.showModalNuevaUbicacionProducto, page: action.page };

          case AlmacenesActions.SHOW_MODAL_EDITAR_UBICACION_PRODUCTO:
               return { ...state, showModalEditarUbicacionProducto: action.showModalEditarUbicacionProducto, ubicacionProductoSeleccionado: action.ubicacionProductoSeleccionado, page: action.page };

          case AlmacenesActions.NUEVA_UBICACION_PRODUCTO_REQUEST:
               return { ...state, fetchingNuevaUbicacionProducto: true };
          case AlmacenesActions.NUEVA_UBICACION_PRODUCTO_SUCCESS:
               return { ...state, fetchingNuevaUbicacionProducto: false };
          case AlmacenesActions.NUEVA_UBICACION_PRODUCTO_FAILURE:
               return { ...state, fetchingNuevaUbicacionProducto: false };

          case AlmacenesActions.EDITAR_UBICACION_PRODUCTO_REQUEST:
               return { ...state, fetchingEditarUbicacionProducto: true };
          case AlmacenesActions.EDITAR_UBICACION_PRODUCTO_SUCCESS:
               return { ...state, fetchingEditarUbicacionProducto: false };
          case AlmacenesActions.EDITAR_UBICACION_PRODUCTO_FAILURE:
               return { ...state, fetchingEditarUbicacionProducto: false };

          case AlmacenesActions.EXPORTAR_UBICACIONES_PRODUCTOS_EXCEL_REQUEST:
               return { ...state, fetchingExportarExcelUbicacionesProductos: true };
          case AlmacenesActions.EXPORTAR_UBICACIONES_PRODUCTOS_EXCEL_SUCCESS:
               return { ...state, fetchingExportarExcelUbicacionesProductos: false };
          case AlmacenesActions.EXPORTAR_UBICACIONES_PRODUCTOS_EXCEL_FAILURE:
               return { ...state, fetchingExportarExcelUbicacionesProductos: false };

          //CATALOGOS ALMACENES
          case AlmacenesActions.ALMACENES_ACTIVOS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaAlmacenesActivos: true };
          case AlmacenesActions.ALMACENES_ACTIVOS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaAlmacenesActivos: false, listaAlmacenesActivos: action.listaAlmacenesActivos };
          case AlmacenesActions.ALMACENES_ACTIVOS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaAlmacenesActivos: false };

          // Requisición Directa

          // case AlmacenesActions.GET_ARCHIVO_REQUISICION_ESPERA_REQUEST:
          //      return { ...state, fetchingListaArchivosRequisicionEspera: true };
          // case AlmacenesActions.GET_ARCHIVO_REQUISICION_ESPERA_SUCCESS:
          //      return { ...state, fetchingListaArchivosRequisicionEspera: false, listaArchivosRequisicionEspera: action.listaArchivosRequisicionEspera, listaArchivosRequisicionEsperaCantidad: action.listaArchivosRequisicionEsperaCantidad };
          // case AlmacenesActions.GET_ARCHIVO_REQUISICION_ESPERA_FAILURE:
          //      return { ...state, fetchingListaArchivosRequisicionEspera: false };

          case AlmacenesActions.SUBIR_ARCHIVO_REQUISICION_ACTIVO_REQUEST:
               return { ...state, fetchingSubirArchivoRequisicion: true };
          case AlmacenesActions.SUBIR_ARCHIVO_REQUISICION_ACTIVO_SUCCESS:
               return { ...state, fetchingSubirArchivoRequisicion: false, folio: action.folio, registros: action.registros, responseStatus: action.responseStatus, mesaggeSpin: action.mesaggeSpin, descriptionSpin: action.descriptionSpin, typeSpin: action.typeSpin };
          case AlmacenesActions.SUBIR_ARCHIVO_REQUISICION_ACTIVO_FAILURE:
               return { ...state, fetchingSubirArchivoRequisicion: false, textMessage: action.textMessage, mesaggeSpin: action.mesaggeSpin, descriptionSpin: action.descriptionSpin, typeSpin: action.typeSpin };

          // case AlmacenesActions.ELIMINAR_ARCHIVO_REQUISICION_ACTIVO_REQUEST:
          //      return { ...state, fetchingBorrarArchivoRequisicion: true };
          // case AlmacenesActions.ELIMINAR_ARCHIVO_REQUISICION_ACTIVO_SUCCESS:
          //      return { ...state, fetchingBorrarArchivoRequisicion: false };
          // case AlmacenesActions.ELIMINAR_ARCHIVO_REQUISICION_ACTIVO_FAILURE:
          //      return { ...state, fetchingBorrarArchivoRequisicion: false };

          // case AlmacenesActions.EXPORTAR_ERRORES_ARCHIVO_REQUISICION_REQUEST:
          //      return { ...state, fetchingListaArchivosRequisicionEspera: true };
          // case AlmacenesActions.EXPORTAR_ERRORES_ARCHIVO_REQUISICION_SUCCESS:
          //      return { ...state, fetchingListaArchivosRequisicionEspera: false };
          // case AlmacenesActions.EXPORTAR_ERRORES_ARCHIVO_REQUISICION_FAILURE:
          //      return { ...state, fetchingListaArchivosRequisicionEspera: false };

          // case AlmacenesActions.PROCESAR_ARCHIVO_REQUISICION_REQUEST:
          //      return { ...state, fetchingListaArchivosRequisicionEspera: true };
          // case AlmacenesActions.PROCESAR_ARCHIVO_REQUISICION_SUCCESS:
          //      return { ...state, fetchingListaArchivosRequisicionEspera: false };
          // case AlmacenesActions.PROCESAR_ARCHIVO_REQUISICION_FAILURE:
          //      return { ...state, fetchingListaArchivosRequisicionEspera: false };

          case AlmacenesActions.FOLIO_REQUISICION_DIRECTA_REQUEST:
               return { ...state, fetchingSubirArchivoRequisicion: true };
          case AlmacenesActions.FOLIO_REQUISICION_DIRECTA_SUCCESS:
               return { ...state, fetchingSubirArchivoRequisicion: false, folio: action.folio };
          case AlmacenesActions.FOLIO_REQUISICION_DIRECTA_FAILURE:
               return { ...state, fetchingSubirArchivoRequisicion: false };

          //LISTA PARA TOMA DE INVENTARIO FISICO GENERAL
          case AlmacenesActions.LISTA_PISOS_BY_ALMACEN_REQUEST:
               return { ...state, fetchingListaPisosByAlmacen: true };
          case AlmacenesActions.LISTA_PISOS_BY_ALMACEN_SUCCESS:
               return { ...state, fetchingListaPisosByAlmacen: false, listaPisosByAlmacen: action.listaPisosByAlmacen };
          case AlmacenesActions.LISTA_PISOS_BY_ALMACEN_FAILURE:
               return { ...state, fetchingListaPisosByAlmacen: false };

          case AlmacenesActions.LISTA_AREAS_BY_PISOS_REQUEST:
               return { ...state, fetchingListaAreasByPisos: true };
          case AlmacenesActions.LISTA_AREAS_BY_PISOS_SUCCESS:
               return { ...state, fetchingListaAreasByPisos: false, listaAreasByPisos: action.listaAreasByPisos };
          case AlmacenesActions.LISTA_AREAS_BY_PISOS_FAILURE:
               return { ...state, fetchingListaAreasByPisos: false };

          case AlmacenesActions.LISTA_PASILLOS_BY_AREAS_REQUEST:
               return { ...state, fetchingListaPasillosByAreas: true };
          case AlmacenesActions.LISTA_PASILLOS_BY_AREAS_SUCCESS:
               return { ...state, fetchingListaPasillosByAreas: false, listaPasillosByAreas: action.listaPasillosByAreas };
          case AlmacenesActions.LISTA_PASILLOS_BY_AREAS_FAILURE:
               return { ...state, fetchingListaPasillosByAreas: false };

          case AlmacenesActions.LISTA_RACKS_BY_PASILLOS_REQUEST:
               return { ...state, fetchingListaRacksByPasillos: true };
          case AlmacenesActions.LISTA_RACKS_BY_PASILLOS_SUCCESS:
               return { ...state, fetchingListaRacksByPasillos: false, listaRacksByPasillos: action.listaRacksByPasillos };
          case AlmacenesActions.LISTA_RACKS_BY_PASILLOS_FAILURE:
               return { ...state, fetchingListaRacksByPasillos: false };

          case AlmacenesActions.LISTA_INVENTARIO_FISICO_GENERAL_REQUEST:
               return { ...state, fetchingListaInventarioFisicoBusqueda: true };
          case AlmacenesActions.LISTA_INVENTARIO_FISICO_GENERAL_SUCCESS:
               return { ...state, fetchingListaInventarioFisicoBusqueda: false, listaInvetarioFisicoBusqueda: action.listaInvetarioFisicoBusqueda, listaInvetarioFisicoBusquedaCantidad: action.listaInvetarioFisicoBusquedaCantidad };
          case AlmacenesActions.LISTA_INVENTARIO_FISICO_GENERAL_FAILURE:
               return { ...state, fetchingListaInventarioFisicoBusqueda: false };

          case AlmacenesActions.LISTA_INVENTARIO_FISICO_DESCARGA_REQUEST:
               return { ...state, fetchingListaInventarioFisicoDescarga: true };
          case AlmacenesActions.LISTA_INVENTARIO_FISICO_DESCARGA_SUCCESS:
               return { ...state, fetchingListaInventarioFisicoDescarga: false };
          case AlmacenesActions.LISTA_INVENTARIO_FISICO_DESCARGA_FAILURE:
               return { ...state, fetchingListaInventarioFisicoDescarga: false };

          case AlmacenesActions.SHOW_MODAL_LISTA_INVENTARIO_FISICO_GENERAL:
               return { ...state, showModalInventarioFisicoGeneral: action.showModalInventarioFisicoGeneral, page: action.page };

          //
          case AlmacenesActions.LISTA_FAMILIAS_FILTRO_REQUEST:
               return { ...state, fetchingListaFamiliasFiltro: true };
          case AlmacenesActions.LISTA_FAMILIAS_FILTRO_SUCCESS:
               return { ...state, fetchingListaFamiliasFiltro: false, listaFamiliasFiltro: action.listaFamiliasFiltro };
          case AlmacenesActions.LISTA_FAMILIAS_FILTRO_FAILURE:
               return { ...state, fetchingListaFamiliasFiltro: false };

          case AlmacenesActions.LISTA_SERVICIOS_ALMACEN_REQUEST:
               return { ...state, fetchingListaServiciosbyAlmacen: true };
          case AlmacenesActions.LISTA_SERVICIOS_ALMACEN_SUCCESS:
               return { ...state, fetchingListaServiciosbyAlmacen: false, listaServiciosbyAlmacen: action.listaServiciosbyAlmacen };
          case AlmacenesActions.LISTA_SERVICIOS_ALMACEN_FAILURE:
               return { ...state, fetchingListaServiciosbyAlmacen: false };

          case AlmacenesActions.LISTA_FAMILIAS_SERVICIO_REQUEST:
               return { ...state, fetchingListaFamiliasbyServicio: true };
          case AlmacenesActions.LISTA_FAMILIAS_SERVICIO_SUCCESS:
               return { ...state, fetchingListaFamiliasbyServicio: false, listaFamiliasbyServicio: action.listaFamiliasbyServicio };
          case AlmacenesActions.LISTA_FAMILIAS_SERVICIO_FAILURE:
               return { ...state, fetchingListaFamiliasbyServicio: false };

          case AlmacenesActions.LISTA_SUBFAMILIAS_FAMILIA_REQUEST:
               return { ...state, fetchingListaSubfamiliasbyFamilia: true };
          case AlmacenesActions.LISTA_SUBFAMILIAS_FAMILIA_SUCCESS:
               return { ...state, fetchingListaSubfamiliasbyFamilia: false, listaSubfamiliasbyFamilia: action.listaSubfamiliasbyFamilia };
          case AlmacenesActions.LISTA_SUBFAMILIAS_FAMILIA_FAILURE:
               return { ...state, fetchingListaSubfamiliasbyFamilia: false };

          //REPORTE FALTANTES Y AJUSTE INVENTARIO FISICO GENERAL

          case AlmacenesActions.LISTA_FALTANTES_INVENTARIO_FISICO_REQUEST:
               return { ...state, fetchingListaFaltantesInventarioFisico: true };
          case AlmacenesActions.LISTA_FALTANTES_INVENTARIO_FISICO_SUCCESS:
               return { ...state, fetchingListaFaltantesInventarioFisico: false, listaFaltantesInvetarioFisico: action.listaFaltantesInvetarioFisico, listaFaltantesInvetarioFisicoCantidad: action.listaFaltantesInvetarioFisicoCantidad, habilitarCerrarFolioInventarioFisico: action.habilitarCerrarFolioInventarioFisico };
          case AlmacenesActions.LISTA_FALTANTES_INVENTARIO_FISICO_FAILURE:
               return { ...state, fetchingListaFaltantesInventarioFisico: false, listaFaltantesInvetarioFisico: [], listaFaltantesInvetarioFisicoCantidad: null, habilitarCerrarFolioInventarioFisico: action.habilitarCerrarFolioInventarioFisico };

          case AlmacenesActions.LISTA_FALTANTES_INVENTARIO_FISICO_DESCARGA_REQUEST:
               return { ...state, fetchingListaFaltantesInventarioFisicoDescarga: true };
          case AlmacenesActions.LISTA_FALTANTES_INVENTARIO_FISICO_DESCARGA_SUCCESS:
               return { ...state, fetchingListaFaltantesInventarioFisicoDescarga: false, habilitarCerrarFolioInventarioFisico: true };
          case AlmacenesActions.LISTA_FALTANTES_INVENTARIO_FISICO_DESCARGA_FAILURE:
               return { ...state, fetchingListaFaltantesInventarioFisicoDescarga: false };

          case AlmacenesActions.CERRAR_FOLIO_AJUSTE_INVENTARIO_REQUEST:
               return { ...state, fetchingCerrarFolioAjusteInventario: true };
          case AlmacenesActions.CERRAR_FOLIO_AJUSTE_INVENTARIO_SUCCESS:
               return { ...state, fetchingCerrarFolioAjusteInventario: false, habilitarCerrarFolioInventarioFisico: false };
          case AlmacenesActions.CERRAR_FOLIO_AJUSTE_INVENTARIO_FAILURE:
               return { ...state, fetchingCerrarFolioAjusteInventario: false };

          //
          case AlmacenesActions.SHOW_MODAL_INVENTARIO_CICLICO:
               return { ...state, showModalInventarioCiclico: action.showModalInventarioCiclico, almacenesSeleccionados: action.almacenesSeleccionados, serviciosSeleccionados: action.serviciosSeleccionados, familiasSeleccionadas: action.familiasSeleccionadas, subfamiliasSeleccionadas: action.subfamiliasSeleccionadas, rangoMayor: action.rangoMayor, rangoMenor: action.rangoMenor, porcentaje: action.porcentaje };

          case AlmacenesActions.SHOW_MODAL_INGRESAR_FOLIO:
               return { ...state, showModalIngresarFolio: action.showModalIngresarFolio, tipoInventario: action.tipoInventario, loadingBoolean: action.loadingBoolean };

          case AlmacenesActions.SHOW_MODAL_INFORMACION_FOLIO:
               return { ...state, showModalInformacionFolio: action.showModalInformacionFolio, folioInventario: action.folioInventario };

          case AlmacenesActions.SHOW_MODAL_NO_FOLIO:
               return { ...state, showModalNoFolio: action.showModalNoFolio };

          case AlmacenesActions.SHOW_MODAL_FOLIO_CREADO:
               return { ...state, showModalFolioCreado: action.showModalFolioCreado, folioInventario: action.folioInventario };

          case AlmacenesActions.SHOW_MODAL_CAPTURA_INVENTARIO:
               return { ...state, showModalCapturaInventario: action.showModalCapturaInventario, numeroConteo: action.numeroConteo };

          case AlmacenesActions.FOLIOS_FILTRADOS_REQUEST:
               return { ...state, fetchingListaFiltroFolio: true };
          case AlmacenesActions.FOLIOS_FILTRADOS_SUCCESS:
               return { ...state, fetchingListaFiltroFolio: false, listaFiltroFolio: action.listaFiltroFolio };
          case AlmacenesActions.FOLIOS_FILTRADOS_FAILURE:
               return { ...state, fetchingListaFiltroFolio: false };

          case AlmacenesActions.GENERAR_FOLIO_CAPTURA_REQUEST:
               return { ...state, fetchingGenerarFolioCaptura: true };
          case AlmacenesActions.GENERAR_FOLIO_CAPTURA_SUCCESS:
               return { ...state, fetchingGenerarFolioCaptura: false, folioInventarioGenerado: action.folioInventarioGenerado };
          case AlmacenesActions.GENERAR_FOLIO_CAPTURA_FAILURE:
               return { ...state, fetchingGenerarFolioCaptura: false };

          case AlmacenesActions.LISTA_ALMACENES_BY_FOLIO_REQUEST:
               return { ...state, fetchingListaAlmacenesByFolio: true };
          case AlmacenesActions.LISTA_ALMACENES_BY_FOLIO_SUCCESS:
               return { ...state, fetchingListaAlmacenesByFolio: false, listaAlmacenesByFolio: action.listaAlmacenesByFolio, buttonReporte: action.buttonReporte, listaAlmacenesByFolio1: action.listaAlmacenesByFolio1, listaAlmacenesByFolio2: action.listaAlmacenesByFolio2 };
          case AlmacenesActions.LISTA_ALMACENES_BY_FOLIO_FAILURE:
               return { ...state, fetchingListaAlmacenesByFolio: false };

          case AlmacenesActions.LISTA_PISOS_BY_FOLIO_REQUEST:
               return { ...state, fetchingListaPisosByFolio: true };
          case AlmacenesActions.LISTA_PISOS_BY_FOLIO_SUCCESS:
               return { ...state, fetchingListaPisosByFolio: false, listaPisosByFolio: action.listaPisosByFolio };
          case AlmacenesActions.LISTA_PISOS_BY_FOLIO_FAILURE:
               return { ...state, fetchingListaPisosByFolio: false };

          case AlmacenesActions.LISTA_AREAS_BY_FOLIO_REQUEST:
               return { ...state, fetchingListaAreasByFolio: true };
          case AlmacenesActions.LISTA_AREAS_BY_FOLIO_SUCCESS:
               return { ...state, fetchingListaAreasByFolio: false, listaAreasByFolio: action.listaAreasByFolio };
          case AlmacenesActions.LISTA_AREAS_BY_FOLIO_FAILURE:
               return { ...state, fetchingListaAreasByFolio: false };

          case AlmacenesActions.LISTA_PASILLOS_BY_FOLIO_REQUEST:
               return { ...state, fetchingListaPasillosByFolio: true };
          case AlmacenesActions.LISTA_PASILLOS_BY_FOLIO_SUCCESS:
               return { ...state, fetchingListaPasillosByFolio: false, listaPasillosByFolio: action.listaPasillosByFolio };
          case AlmacenesActions.LISTA_PASILLOS_BY_FOLIO_FAILURE:
               return { ...state, fetchingListaPasillosByFolio: false };

          case AlmacenesActions.LISTA_RACKS_BY_FOLIO_REQUEST:
               return { ...state, fetchingListaRacksByFolio: true };
          case AlmacenesActions.LISTA_RACKS_BY_FOLIO_SUCCESS:
               return { ...state, fetchingListaRacksByFolio: false, listaRacksByFolio: action.listaRacksByFolio };
          case AlmacenesActions.LISTA_RACKS_BY_FOLIO_FAILURE:
               return { ...state, fetchingListaRacksByFolio: false };

          case AlmacenesActions.LISTA_PRODUCTOS_BY_FOLIO_REQUEST:
               return { ...state, fetchingListaProductosByFolio: true };
          case AlmacenesActions.LISTA_PRODUCTOS_BY_FOLIO_SUCCESS:
               return { ...state, fetchingListaProductosByFolio: false, listaProductosByFolio: action.listaProductosByFolio, listaProductosByFolioCantidad: action.listaProductosByFolioCantidad };
          case AlmacenesActions.LISTA_PRODUCTOS_BY_FOLIO_FAILURE:
               return { ...state, fetchingListaProductosByFolio: false };

          case AlmacenesActions.VALIDACION_CONTEO_BY_FOLIO_REQUEST:
               return { ...state, fetchingValidacionConteoByFolio: true };
          case AlmacenesActions.VALIDACION_CONTEO_BY_FOLIO_SUCCESS:
               return { ...state, fetchingValidacionConteoByFolio: false };
          case AlmacenesActions.VALIDACION_CONTEO_BY_FOLIO_FAILURE:
               return { ...state, fetchingValidacionConteoByFolio: false };

          case AlmacenesActions.VALIDACION_CONTEO_UNO:
               return { ...state, validacionConteoUno: action.validacionConteoUno };

          case AlmacenesActions.VALIDACION_CONTEO_DOS:
               return { ...state, validacionConteoDos: action.validacionConteoDos };

          case AlmacenesActions.VALIDACION_CONTEO_TRES:
               return { ...state, validacionConteoTres: action.validacionConteoTres };

          case AlmacenesActions.CAPTURAR_CONTEO_REQUEST:
               return { ...state, fetchingCapturaConteo: true };
          case AlmacenesActions.CAPTURAR_CONTEO_SUCCESS:
               return { ...state, fetchingCapturaConteo: false };
          case AlmacenesActions.CAPTURAR_CONTEO_FAILURE:
               return { ...state, fetchingCapturaConteo: false };

          case AlmacenesActions.GENERAR_FOLIO_CAPTURA_REQUEST:
               return { ...state, fetchingGenerarFolioCaptura: true };
          case AlmacenesActions.GENERAR_FOLIO_CAPTURA_SUCCESS:
               return { ...state, fetchingGenerarFolioCaptura: false, folioInventarioGenerado: action.folioInventarioGenerado };
          case AlmacenesActions.GENERAR_FOLIO_CAPTURA_FAILURE:
               return { ...state, fetchingGenerarFolioCaptura: false };

          case AlmacenesActions.LISTA_INVENTARIO_CICLICO_REQUEST:
               return { ...state, fetchingListaProductosCiclico: true };
          case AlmacenesActions.LISTA_INVENTARIO_CICLICO_SUCCESS:
               return { ...state, fetchingListaProductosCiclico: false, listadoInventarioCiclico: action.listadoInventarioCiclico, listadoInventarioCiclicoCantidad: action.listadoInventarioCiclicoCantidad, page: action.page };
          case AlmacenesActions.LISTA_INVENTARIO_CICLICO_FAILURE:
               return { ...state, fetchingListaProductosCiclico: false };

          case AlmacenesActions.LISTA_INVENTARIO_CICLICO_DESCARGA_REQUEST:
               return { ...state, fetchingListaInventarioCiclicoDescarga: true };
          case AlmacenesActions.LISTA_INVENTARIO_CICLICO_DESCARGA_SUCCESS:
               return { ...state, fetchingListaInventarioCiclicoDescarga: false };
          case AlmacenesActions.LISTA_INVENTARIO_CICLICO_DESCARGA_FAILURE:
               return { ...state, fetchingListaInventarioCiclicoDescarga: false };

          // Kardex
          case AlmacenesActions.KARDEX_PRODUCTOS_EXISTENETES_REQUEST:
               return { ...state, fetchingKardexProductosExistentes: true };
          case AlmacenesActions.KARDEX_PRODUCTOS_EXISTENETES_SUCCESS:
               return { ...state, fetchingKardexProductosExistentes: false, kardexEncabezado: action.kardexEncabezado, kardexCardsMovimientos: action.kardexCardsMovimientos };
          case AlmacenesActions.KARDEX_PRODUCTOS_EXISTENETES_FAILURE:
               return { ...state, fetchingKardexProductosExistentes: false };

          case AlmacenesActions.ALMACENES_BY_PRODUCTO_REQUEST:
               return { ...state, fetchingListaAlmacenesByProducto: true };
          case AlmacenesActions.ALMACENES_BY_PRODUCTO_SUCCESS:
               return { ...state, fetchingListaAlmacenesByProducto: false, listaAlmacenesByProducto: action.listaAlmacenesByProducto };
          case AlmacenesActions.ALMACENES_BY_PRODUCTO_FAILURE:
               return { ...state, fetchingListaAlmacenesByProducto: false };

          case AlmacenesActions.KARDEX_OBTENER_PRODUCTOS_REQUEST:
               return { ...state, fetchingListaKardexProductosObtenidos: true };
          case AlmacenesActions.KARDEX_OBTENER_PRODUCTOS_SUCCESS:
               return { ...state, fetchingListaKardexProductosObtenidos: false, listaKardexProductosObtenidos: action.listaKardexProductosObtenidos };
          case AlmacenesActions.KARDEX_OBTENER_PRODUCTOS_FAILURE:
               return { ...state, fetchingListaKardexProductosObtenidos: false };

          case AlmacenesActions.KARDEX_OBTENER_MOVIMIENTOS_REQUEST:
               return { ...state, fetchingListaKardexMovimientos: true };
          case AlmacenesActions.KARDEX_OBTENER_MOVIMIENTOS_SUCCESS:
               return { ...state, fetchingListaKardexMovimientos: false, listaKardexMovimientos: action.listaKardexMovimientos, dataMovimientosSumas: action.dataMovimientosSumas, dataMovimientosRestas: action.dataMovimientosRestas, buttonExport: action.buttonExport, cantidadMovimientoSuma: action.cantidadMovimientoSuma, cantidadMovimientoResta: action.cantidadMovimientoResta };
          case AlmacenesActions.KARDEX_OBTENER_MOVIMIENTOS_FAILURE:
               return { ...state, fetchingListaKardexMovimientos: false };

          case AlmacenesActions.KARDEX_REPORTE_REQUEST:
               return { ...state, fetchingKardexReporte: true };
          case AlmacenesActions.KARDEX_REPORTE_SUCCESS:
               return { ...state, fetchingKardexReporte: false };
          case AlmacenesActions.KARDEX_REPORTE_FAILURE:
               return { ...state, fetchingKardexReporte: false };

          case AlmacenesActions.LISTA_FOLIOS_VERIFICACION_CALIDAD_REQUEST:
               return { ...state, fetchingListaFVerificacionCalidad: true }
          case AlmacenesActions.LISTA_FOLIOS_VERIFICACION_CALIDAD_SUCCESS:
               return { ...state, fetchingListaFVerificacionCalidad: false, listaFVerificacionCalidad: action.listaFVerificacionCalidad, listaFVerificacionCalidadCantidad: action.listaFVerificacionCalidadCantidad }
          case AlmacenesActions.LISTA_FOLIOS_VERIFICACION_CALIDAD_FAILURE:
               return { ...state, fetchingListaFVerificacionCalidad: false }

          case AlmacenesActions.SHOW_MODAL_MODIFICAR_REGISTRO_VERIFICACION:
               return { ...state, showModalModificarRegistro: action.showModalModificarRegistro, registroProductoSeleccionado: action.registroProductoSeleccionado }

          case AlmacenesActions.SHOW_MODAL_NUEVOFOLIO_VERIFICACION_CAL:
               return { ...state, showModalNuevoFolioVC: action.showModalNuevoFolioVC, page: action.page, busqueda: action.busqueda }

          case AlmacenesActions.SHOW_MODAL_VERIFICARFOLIO_CAL:
               return { ...state, showModalFolioVC: action.showModalFolioVC, folioEVDCseleccionado: action.folioEVDCseleccionado, page: action.page, busqueda: action.busqueda }

          case AlmacenesActions.SHOW_MODAL_MODIFICAR_PRODUCTO:
               return { ...state, showModalModificarProducto: action.showModalModificarProducto, productoSeleccionadoModificar: action.productoSeleccionadoModificar }

          case AlmacenesActions.CREAR_FOLIO_VERIFICACION_CALIDAD_REQUEST:
               return { ...state, fetchingNuevoFolioVC: true };
          case AlmacenesActions.CREAR_FOLIO_VERIFICACION_CALIDAD_SUCCESS:
               return { ...state, fetchingNuevoFolioVC: false };
          case AlmacenesActions.CREAR_FOLIO_VERIFICACION_CALIDAD_FAILURE:
               return { ...state, fetchingNuevoFolioVC: false };

          case AlmacenesActions.FOLIO_VERIFICACION_CALIDAD_REQUEST:
               return { ...state, fetchingFolioVC: true };
          case AlmacenesActions.FOLIO_VERIFICACION_CALIDAD_SUCCESS:
               return { ...state, fetchingFolioVC: false };
          case AlmacenesActions.FOLIO_VERIFICACION_CALIDAD_FAILURE:
               return { ...state, fetchingFolioVC: false };

          case AlmacenesActions.DETALLE_FOLIO_VERIFICACION_CALIDAD_REQUEST:
               return { ...state, fetchingListaFVerificacionCalidad: true }
          case AlmacenesActions.DETALLE_FOLIO_VERIFICACION_CALIDAD_SUCCESS:
               return { ...state, fetchingListaFVerificacionCalidad: false, listaFolioVC: action.listaFolioVC, listaFolioVCCalidad: action.listaFolioVCCalidad }
          case AlmacenesActions.DETALLE_FOLIO_VERIFICACION_CALIDAD_FAILURE:
               return { ...state, fetchingListaFVerificacionCalidad: false }

          case AlmacenesActions.ACTUALIZAR_TABLA_PRODUCTOS_VERIFICACION:
               return { ...state, listaFolioVC: action.listaFolioVC }

          case AlmacenesActions.IMPRIMIR_PDF_VERIFICACION_CALIDAD_REQUEST:
               return { ...state, fetchingImprimirPDF: true }
          case AlmacenesActions.IMPRIMIR_PDF_VERIFICACION_CALIDAD_SUCCESS:
               return { ...state, fetchingImprimirPDF: false }
          case AlmacenesActions.IMPRIMIR_PDF_VERIFICACION_CALIDAD_FAILURE:
               return { ...state, fetchingImprimirPDF: false }

          //REDUCERS DESCARGA DE FORMATOS PARA MOVIMIENTOS DE ALMACEN
          case AlmacenesActions.VERIFICAR_SERIENUM_REQUEST:
               return {...state, fetchingModalOpciones: true}    
          case AlmacenesActions.VERIFICAR_SERIENUM_SUCCESS:
               return {...state, fetchingModalOpciones: false} 
          case AlmacenesActions.VERIFICAR_SERIENUM_FAILURE:
               return {...state, fetchingModalOpciones: false}

          case AlmacenesActions.DESCARGAR_LISTA_SERIES_REQUEST:
               return {...state, fetchingDescargarSeries: true}    
          case AlmacenesActions.DESCARGAR_LISTA_SERIES_SUCCESS:
               return {...state, fetchingDescargarSeries: false} 
          case AlmacenesActions.DESCARGAR_LISTA_SERIES_FAILURE:
               return {...state, fetchingDescargarSeries: false}

          case AlmacenesActions.DESCARGAR_SALIDA_INSTALACION_REQUEST:
               return {...state, fetchingDescargarSalidaInstalacion: true}    
          case AlmacenesActions.DESCARGAR_SALIDA_INSTALACION_SUCCESS:
               return {...state, fetchingDescargarSalidaInstalacion: false} 
          case AlmacenesActions.DESCARGAR_SALIDA_INSTALACION_FAILURE:
               return {...state, fetchingDescargarSalidaInstalacion: false}

          case AlmacenesActions.HERRAMIENTAS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaHerramientas: true }
          case AlmacenesActions.HERRAMIENTAS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaHerramientas: false, listaHerramientas: action.listaHerramientas, listaHerramientasCantidad: action.listaHerramientasCantidad }
          case AlmacenesActions.HERRAMIENTAS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaHerramientas: false }

          case AlmacenesActions.PRESTAMO_HERRAMIENTAS_REQUEST:
               return { ...state, fetchingPrestamoHerramientas: true }
          case AlmacenesActions.PRESTAMO_HERRAMIENTAS_SUCCESS:
               return { ...state, fetchingPrestamoHerramientas: false }
          case AlmacenesActions.PRESTAMO_HERRAMIENTAS_FAILURE:
               return { ...state, fetchingPrestamoHerramientas: false }

          case AlmacenesActions.SHOW_MODAL_DEVOLUCION_HERRAMIENTA:
               return { ...state, showModalDevolucion: action.showModalDevolucion, herramientaSeleccionada: action.herramientaSeleccionada, tipoMov: action.tipoMov } // meter state del tipo folio     


          case AlmacenesActions.BAJA_HERRAMIENTAS_REQUEST:
               return { ...state, fetchingBajaHerramientas: true }
          case AlmacenesActions.BAJA_HERRAMIENTAS_SUCCESS:
               return { ...state, fetchingBajaHerramientas: false }
          case AlmacenesActions.BAJA_HERRAMIENTAS_FAILURE:
               return { ...state, fetchingBajaHerramientas: false }

          case AlmacenesActions.ACTUALIZAR_TABLA_HERRAMIENTAS:
               return { ...state, listaHerramientas: action.listaHerramientas }

          case AlmacenesActions.OBTENER_FOLIOS_PRESTAMOS_REQUEST:
               return { ...state, fetchingFoliosPrestamos: true }
          case AlmacenesActions.OBTENER_FOLIOS_PRESTAMOS_SUCCESS:
               return { ...state, fetchingFoliosPrestamos: false, foliosPrestamos: action.foliosPrestamos }
          case AlmacenesActions.OBTENER_FOLIOS_PRESTAMOS_FAILURE:
               return { ...state, fetchingFoliosPrestamos: false }

          case AlmacenesActions.DEVOLUCION_HERRAMIENTAS_REQUEST:
               return { ...state, fetchingBajaHerramientas: true }
          case AlmacenesActions.DEVOLUCION_HERRAMIENTAS_SUCCESS:
               return { ...state, fetchingBajaHerramientas: false }
          case AlmacenesActions.DEVOLUCION_HERRAMIENTAS_FAILURE:
               return { ...state, fetchingBajaHerramientas: false }

          case AlmacenesActions.DETALLE_FOLIO_PRESTAMO_REQUEST:
               return { ...state, fetchingFolioPrestamo: true }
          case AlmacenesActions.DETALLE_FOLIO_PRESTAMO_SUCCESS:
               return { ...state, fetchingFolioPrestamo: false, folioPrestamo: action.folioPrestamo, folioPrestamoCantidad: action.folioPrestamoCantidad }
          case AlmacenesActions.DETALLE_FOLIO_PRESTAMO_FAILURE:
               return { ...state, fetchingFolioPrestamo: false }

          case AlmacenesActions.ACTUALIZAR_FOLIO_PRESTAMO:
               return { ...state, folioPrestamo: action.folioPrestamo }

          case AlmacenesActions.SHOW_MODAL_PRESTAMO_HERRAMIENTA:
               return { ...state, showModalPrestamo: action.showModalPrestamo, herramientaSeleccionadaPrestamo: action.herramientaSeleccionadaPrestamo }

          case AlmacenesActions.SHOW_MODAL_BAJA_HERRAMIENTA:
               return { ...state, showModalBaja: action.showModalBaja, herramientaSeleccionadaBaja: action.herramientaSeleccionadaBaja }

          case AlmacenesActions.SHOW_MODAL_DEVOLUCION_ESTADO_HERRAMIENTA:
               return { ...state, showModalEstadoDevolucion: action.showModalEstadoDevolucion, codigoDevolucionSeleccionado: action.codigoDevolucionSeleccionado }

          case AlmacenesActions.NUMEROS_INVENTARIO_PRESTADO_REQUEST:
               return { ...state, fetchingNumeroPrestados: true }
          case AlmacenesActions.NUMEROS_INVENTARIO_PRESTADO_SUCCESS:
               return { ...state, fetchingNumeroPrestados: false, listaNumerosInventarioPrestados: action.listaNumerosInventarioPrestados }
          case AlmacenesActions.NUMEROS_INVENTARIO_PRESTADO_FAILURE:
               return { ...state, fetchingNumeroPrestados: false }

          case AlmacenesActions.OBTENER_NUMEROS_INVENTARIO_FOLIO_REQUEST:
               return { ...state, fetchingNumeroPrestados: true }
          case AlmacenesActions.OBTENER_NUMEROS_INVENTARIO_FOLIO_SUCCESS:
               return { ...state, fetchingNumeroPrestados: false, listaNumerosInventarioFolio: action.listaNumerosInventarioFolio }
          case AlmacenesActions.OBTENER_NUMEROS_INVENTARIO_FOLIO_FAILURE:
               return { ...state, fetchingNumeroPrestados: false }

          case AlmacenesActions.ACTUALIZAR_TABLA_NUMEROS_INVENTARIO_FOLIO:
               return { ...state, listaNumerosInventarioFolio: action.listaNumerosInventarioFolio }

          case AlmacenesActions.CREAR_NUMEROS_INVENTARIO_FOLIO_REQUEST:
               return { ...state, fetchingCrearNumeros: true }
          case AlmacenesActions.CREAR_NUMEROS_INVENTARIO_FOLIO_SUCCESS:
               return { ...state, fetchingCrearNumeros: false }
          case AlmacenesActions.CREAR_NUMEROS_INVENTARIO_FOLIO_FAILURE:
               return { ...state, fetchingCrearNumeros: false }

          case AlmacenesActions.MODAL_CREAR_NUMEROS_INVENTARIO:
               return { ...state, showModalCrearNumeros: action.showModalCrearNumeros, herrSeleccionada: action.herrSeleccionada }

          case AlmacenesActions.OBTENER_NUMEROS_INVENTARIO_DISPONIBLES_REQUEST:
               return { ...state, fetchingNumerosDisponibles: true }
          case AlmacenesActions.OBTENER_NUMEROS_INVENTARIO_DISPONIBLES_SUCCESS:
               return { ...state, fetchingNumerosDisponibles: false, numerosInventarioDisponibles: action.numerosInventarioDisponibles, numerosInventarioDisponiblesCantidad: action.numerosInventarioDisponiblesCantidad }
          case AlmacenesActions.OBTENER_NUMEROS_INVENTARIO_DISPONIBLES_FAILURE:
               return {...state, fetchingNumerosDisponibles: false }      
          

          // Requisicion de Materiales
          case AlmacenesActions.LISTA_FOLIOS_REQUISICION_MATERIALES_REQUEST:
               return { ...state, fetchingListaFoliosRequisicionMateriales: true };
          case AlmacenesActions.LISTA_FOLIOS_REQUISICION_MATERIALES_SUCCESS:
               return { ...state, fetchingListaFoliosRequisicionMateriales: false, listaFoliosRequisicionMateriales: action.listaFoliosRequisicionMateriales, listaFoliosRequisicionMaterialesCantidad: action.listaFoliosRequisicionMaterialesCantidad };
          case AlmacenesActions.LISTA_FOLIOS_REQUISICION_MATERIALES_FAILURE:
               return { ...state, fetchingListaFoliosRequisicionMateriales: false };

          case AlmacenesActions.LISTA_INVENTARIO_REQUISICION_MATERIALES_REQUEST:
               return { ...state, fetchingListaProductosBusqueda: true };
          case AlmacenesActions.LISTA_INVENTARIO_REQUISICION_MATERIALES_SUCCESS:
               return { ...state, fetchingListaProductosBusqueda: false, listaProductosBusqueda: action.listaProductosBusqueda, listaProductosBusquedaCantidad: action.listaProductosBusquedaCantidad };
          case AlmacenesActions.LISTA_INVENTARIO_REQUISICION_MATERIALES_FAILURE:
               return { ...state, fetchingListaProductosBusqueda: false };

          case AlmacenesActions.SHOW_MODAL_NUEVO_FOLIO_REQUISICION_MATERIALES:
               return { ...state, showModalRequisicionMateriales: action.showModalRequisicionMateriales, page: action.page, busqueda: action.busqueda, orden: action.orden };

          case AlmacenesActions.REGISTRAR_REQUISICION_MATERIALES_REQUEST:
               return { ...state, fetchingRegistrarRequisicionMateriales: true };
          case AlmacenesActions.REGISTRAR_REQUISICION_MATERIALES_SUCCESS:
               return { ...state, fetchingRegistrarRequisicionMateriales: false };
          case AlmacenesActions.REGISTRAR_REQUISICION_MATERIALES_FAILURE:
               return { ...state, fetchingRegistrarRequisicionMateriales: false };

          case AlmacenesActions.ACTUALIZAR_TABLA_REQUISICION_MATERIALES:
               return { ...state, listaSeleccionados: action.listaSeleccionados, selectedRowKeys: action.selectedRowKeys, pageMovimientos: action.pageMovimientos, hasSelected: action.hasSelected }
          
          case AlmacenesActions.SHOW_MODAL_MODIFICAR_REGISTRO_REQUISICION:
               return { ...state, showModalModificarRequisicionMateriales: action.showModalModificarRequisicionMateriales, registroProductoSeleccionado: action.registroProductoSeleccionado }

          case AlmacenesActions.CAMBIAR_ESTADO_REQUISICION_REQUEST:
               return { ...state, fetchingCambiarEstadoRequisicion: true };
          case AlmacenesActions.CAMBIAR_ESTADO_REQUISICION_SUCCESS:
               return { ...state, fetchingCambiarEstadoRequisicion: false };
          case AlmacenesActions.CAMBIAR_ESTADO_REQUISICION_FAILURE:
               return { ...state, fetchingCambiarEstadoRequisicion: false };

          case AlmacenesActions.SHOW_MODAL_EDITAR_REQUISICION:
               return { ...state, showModalEditarRequisicion: action.showModalEditarRequisicion, requisicionSeleccionada: action.requisicionSeleccionada, page: action.page, busqueda: action.busqueda, orden: action.orden };

          case AlmacenesActions.EDITAR_REQUISICION_REQUEST:
               return { ...state, fetchingEditarRequisicion: true };
          case AlmacenesActions.EDITAR_REQUISICION_SUCCESS:
               return { ...state, fetchingEditarRequisicion: false };
          case AlmacenesActions.EDITAR_REQUISICION_FAILURE:
               return { ...state, fetchingEditarRequisicion: false };

          case AlmacenesActions.SHOW_MODAL_APROBAR_REQUISICION:
               return { ...state, showModalAprobarRequisicion: action.showModalAprobarRequisicion, requisicionSeleccionada: action.requisicionSeleccionada, page: action.page, busqueda: action.busqueda, orden: action.orden };

          case AlmacenesActions.APROBAR_REQUISICION_REQUEST:
               return { ...state, fetchingAprobarRequisicion: true };
          case AlmacenesActions.APROBAR_REQUISICION_SUCCESS:
               return { ...state, fetchingAprobarRequisicion: false };
          case AlmacenesActions.APROBAR_REQUISICION_FAILURE:
               return { ...state, fetchingAprobarRequisicion: false };

          case AlmacenesActions.DESCARGAR_REQUISICION_REQUEST:
               return { ...state, fetchingDescargarRequisicion: true };
          case AlmacenesActions.DESCARGAR_REQUISICION_SUCCESS:
               return { ...state, fetchingDescargarRequisicion: false };
          case AlmacenesActions.DESCARGAR_REQUISICION_FAILURE:
               return { ...state, fetchingDescargarRequisicion: false };

          case AlmacenesActions.PRODUCTOS_FOLIO_REQUISICION_REQUEST:
               return { ...state, fetchingProductosFolioRequisicion: true };
          case AlmacenesActions.PRODUCTOS_FOLIO_REQUISICION_SUCCESS:
               return { ...state, fetchingProductosFolioRequisicion: false, listaProductosFolioRequisicion: action.listaProductosFolioRequisicion };
          case AlmacenesActions.PRODUCTOS_FOLIO_REQUISICION_FAILURE:
               return { ...state, fetchingProductosFolioRequisicion: false };

          case AlmacenesActions.SURTIR_PRODUCTOS_FOLIO_REQUISICION_REQUEST:
               return { ...state, fetchingSurtirFolioRequisicion: true };
          case AlmacenesActions.SURTIR_PRODUCTOS_FOLIO_REQUISICION_SUCCESS:
               return { ...state, fetchingSurtirFolioRequisicion: false };
          case AlmacenesActions.SURTIR_PRODUCTOS_FOLIO_REQUISICION_FAILURE:
               return { ...state, fetchingSurtirFolioRequisicion: false };

          // Carga Masiva de Productos, Herramientas e Insumos.
          case AlmacenesActions.SHOW_MODAL_CARGA_MASIVA_PIH:
               return { ...state, showModalCargaMasivaPIH: action.showModalCargaMasivaPIH, page: action.page}; 

          case AlmacenesActions.CHANGE_VALUE_BTN_ADD:
               return { ...state, disableBtnAdd: action.disableBtnAdd}; 
          case AlmacenesActions.CHANGE_VALUE_BTN_SERIES:
               return { ...state, disableBtnSeries: action.disableBtnSeries}; 
          case AlmacenesActions.CHANGE_VALUE_BTN_FOLIO:
               return { ...state, disableBtnFolio: action.disableBtnFolio}; 

          case AlmacenesActions.CARGA_MASIVA_PIH_REQUEST:
               return {...state, fetchingCargaMasiva: true, descargarFolio:false } 
          case AlmacenesActions.CARGA_MASIVA_PIH_SUCCESS:
               return {...state, fetchingCargaMasiva: false, descargarFolio:action.descargarFolio, folio: action.folio }
          case AlmacenesActions.CARGA_MASIVA_PIH_FAILURE:
               return {...state, fetchingCargaMasiva: false, descargarFolio:false } 

          case AlmacenesActions.SHOW_MODAL_ERRORES_CARGA_PIH:
               return { ...state, showModalErroresPIH: action.showModalErroresPIH, page: action.page}; 
          
          case AlmacenesActions.GET_LIST_PROCESAMIENTO_PIH_REQUEST:
               return {...state, fetchingListCargaMasiva: true } 
          case AlmacenesActions.GET_LIST_PROCESAMIENTO_PIH_SUCCESS:
               return {...state, fetchingListCargaMasiva: false , listInfoArchivosCargaMasiva:action.listInfoArchivosCargaMasiva, listArchivosCargaMasivaPIH: action.listArchivosCargaMasivaPIH}
          case AlmacenesActions.GET_LIST_PROCESAMIENTO_PIH_FAILURE:
               return {...state, fetchingListCargaMasiva: false } 
     
          case AlmacenesActions.ERRORES_CARGA_PIH_REQUEST:
               return {...state, fetchingErroresCargaMasiva: true, idArchivoMasivo:action.idArchivoMasivo } 
          case AlmacenesActions.ERRORES_CARGA_PIH_SUCCESS:
               return {...state, fetchingErroresCargaMasiva: false, listInfoErroresCargaMasiva: action.listInfoErroresCargaMasiva, listErroresCargaMasiva:action.listErroresCargaMasiva }
          case AlmacenesActions.ERRORES_CARGA_PIH_FAILURE:
               return {...state, fetchingErroresCargaMasiva: false } 

          case AlmacenesActions.CHANGE_STATUS_IPH_REQUEST:
               return {...state, fetchingListCargaMasiva: true } 
          case AlmacenesActions.CHANGE_STATUS_IPH_SUCCESS:
               return {...state, fetchingListCargaMasiva: false }
          case AlmacenesActions.CHANGE_STATUS_IPH_FAILURE:
               return {...state, fetchingListCargaMasiva: false } 

          case AlmacenesActions.CARGA_MASIVA_SERIES_REQUEST:
               return {...state, fetchingCargaMasiva: true, descargarFolioSeries:false } 
          case AlmacenesActions.CARGA_MASIVA_SERIES_SUCCESS:
               return {...state, fetchingCargaMasiva: false, descargarFolioSeries:action.descargarFolioSeries }
          case AlmacenesActions.CARGA_MASIVA_SERIES_FAILURE:
               return {...state, fetchingCargaMasiva: false, descargarFolioSeries:false } 

          // Carga Masiva Saldos Iniciales

          case AlmacenesActions.CARGA_MASIVA_ALMACEN_REQUEST:
               return {...state, fetchingCargaMasivaAlmacen: true } 
          case AlmacenesActions.CARGA_MASIVA_ALMACEN_SUCCESS:
               return {...state, fetchingCargaMasivaAlmacen: false, descargarFolio:action.descargarFolio, folio: action.folio }
          case AlmacenesActions.CARGA_MASIVA_ALMACEN_FAILURE:
               return {...state, fetchingCargaMasivaAlmacen: false } 

          case AlmacenesActions.CARGA_MASIVA_ALMACEN_SERIES_REQUEST:
               return {...state, fetchingCargaMasivaAlmacen: true } 
          case AlmacenesActions.CARGA_MASIVA_ALMACEN_SERIES_SUCCESS:
               return {...state, fetchingCargaMasivaAlmacen: false }
          case AlmacenesActions.CARGA_MASIVA_ALMACEN_SERIES_FAILURE:
               return {...state, fetchingCargaMasivaAlmacen: false } 

          //Reporte de Existencias
          case AlmacenesActions.LISTA_REPORTE_EXISTENCIAS_REQUEST:
               return { ...state, fetchingListaReporteExistencias: true, formularioReporte: action.formulario };
          case AlmacenesActions.LISTA_REPORTE_EXISTENCIAS_SUCCESS:
               return { ...state, fetchingListaReporteExistencias: false, listaReporteExistencias: action.listaReporteExistencias, listaReporteExistenciasCantidad: action.listaReporteExistenciasCantidad };
          case AlmacenesActions.LISTA_REPORTE_EXISTENCIAS_FAILURE:
               return { ...state, fetchingListaReporteExistencias: false };

          case AlmacenesActions.PDF_REPORTE_EXISTENCIAS_REQUEST:
               return { ...state, fetchingReporteExistenciasPDF: true };
          case AlmacenesActions.PDF_REPORTE_EXISTENCIAS_SUCCESS:
               return { ...state, fetchingReporteExistenciasPDF: false };
          case AlmacenesActions.PDF_REPORTE_EXISTENCIAS_FAILURE:
               return { ...state, fetchingReporteExistenciasPDF: false };

          case AlmacenesActions.EXCEL_REPORTE_EXISTENCIAS_REQUEST:
               return { ...state, fetchingReporteExistenciasEXCEL: true };
          case AlmacenesActions.EXCEL_REPORTE_EXISTENCIAS_SUCCESS:
               return { ...state, fetchingReporteExistenciasEXCEL: false };
          case AlmacenesActions.EXCEL_REPORTE_EXISTENCIAS_FAILURE:
               return { ...state, fetchingReporteExistenciasEXCEL: false };

          case AlmacenesActions.LISTA_REPORTE_EXISTENCIAS_AGRUPADO_REQUEST:
               return { ...state, fetchingReporteExistenciasAgrupado: true, formularioReporte: action.formulario };
          case AlmacenesActions.LISTA_REPORTE_EXISTENCIAS_AGRUPADO_SUCCESS:
               return { ...state, fetchingReporteExistenciasAgrupado: false, listaReporteExistenciasAgrupado: action.listaReporteExistenciasAgrupado, listaReporteExistenciasAgrupadoCantidad: action.listaReporteExistenciasAgrupadoCantidad };
          case AlmacenesActions.LISTA_REPORTE_EXISTENCIAS_AGRUPADO_FAILURE:
               return { ...state, fetchingReporteExistenciasAgrupado: false };

          // case AlmacenesActions.PDF_REPORTE_EXISTENCIAS_AGRUPADO_REQUEST:
          //      return { ...state, fetchingReporteDinamicoAgrupadoPDF: true };
          // case AlmacenesActions.PDF_REPORTE_EXISTENCIAS_AGRUPADO_SUCCESS:
          //      return { ...state, fetchingReporteDinamicoAgrupadoPDF: false };
          // case AlmacenesActions.PDF_REPORTE_EXISTENCIAS_AGRUPADO_FAILURE:
          //      return { ...state, fetchingReporteDinamicoAgrupadoPDF: false };

          // case AlmacenesActions.EXCEL_REPORTE_EXISTENCIAS_AGRUPADO_REQUEST:
          //      return { ...state, fetchingReporteDinamicoAgrupadoEXCEL: true };
          // case AlmacenesActions.EXCEL_REPORTE_EXISTENCIAS_AGRUPADO_SUCCESS:
          //      return { ...state, fetchingReporteDinamicoAgrupadoEXCEL: false };
          // case AlmacenesActions.EXCEL_REPORTE_EXISTENCIAS_AGRUPADO_FAILURE:
          //      return { ...state, fetchingReporteDinamicoAgrupadoEXCEL: false };

          default:
               return state;
     }
};