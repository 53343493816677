import React from 'react';
import { Row, Col, Switch, Tooltip, Table, Input, Button } from 'antd';
import { EditOutlined, MailOutlined, FileExcelOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoActivo from './ModalNuevoActivo';
import ModalEditarActivo from './ModalEditarActivo'

const { Search } = Input;
class CatalogoDeActivos extends React.Component {
    componentDidMount() { this.props.onGetActivosBusqueda(1, ''); this.props.onRequestInfoProyecto(); }
    state = { page: 1, busqueda: '', }

    render() {
        const {listaActivosBusqueda, fetchingListaActivosBusqueda,listaActivosBusquedaCantidad,
            onGetActivosBusqueda, 
            // onModalNuevoActivo, onModalEditarActivo
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {  permisosUsuario = JSON.parse(permisosUsuario);  }

        console.log('Entra al container')

        const onBuscarActivo = (busqueda) => { this.setState({ busqueda: busqueda, page: 1, }); onGetActivosBusqueda(1, busqueda); }

        const columns = [{
            title: 'Característica',
            dataIndex: 'descripcionActivo',
            key: 'descripcionActivo',
            width: '6%'
        }, {
            title: 'Clave ubicación',
            dataIndex: 'claveUbicacion',
            key: 'claveUbicacion',
            width: '5%'
        }, {
            title: 'Área funcional',
            dataIndex: 'areaFuncional',
            key: 'areaFuncional',
            width: '5%'
        }, {
            title: 'Unidad funcional',
            dataIndex: 'unidadFuncional',
            key: 'unidadFuncional',
            width: '5%'
        }, {
            title: 'Interior',
            dataIndex: 'interior',
            key: 'interior',
            width: '5%'
        }, {
            title: 'SubInterior',
            dataIndex: 'subInterior',
            key: 'subInterior',
            width: '5%'
        }, {
            title: 'Código interno',
            dataIndex: 'codigoInterno',
            key: 'codigoInterno',
            width: '3%'
        }, {
            title: 'Código equipo',
            dataIndex: 'codigoEquipo',
            key: 'codigoEquipo',
            width: '3%'
        }, {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
            width: '4%'
        }, {
            title: 'Num. serie',
            dataIndex: 'serieNum',
            key: 'serieNum',
            width: '4%'
        }, {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            width: '3%'
        }, {
            title: 'Fecha suministro',
            dataIndex: 'fechaSuministroTable',
            key: 'fechaSuministroTable',
            width: '4%'
        }, {
            title: 'Fecha instalación',
            dataIndex: 'fechaInstalacionTable',
            key: 'fechaInstalacionTable',
            width: '4%'
        }, {
            title: 'Fecha garantía',
            dataIndex: 'fechaGarantiaTable',
            key: 'fechaGarantiaTable',
            width: '4%'
        }, {
            title: 'Garantía años',
            dataIndex: 'garantia',
            key: 'garantia',
            width: '3%',
            align: 'center',
        }, {
        //     title: 'Carta fabricante',
        //     dataIndex: 'cartaFabricante',
        //     key: 'cartaFabricante',
        //     width: '4%',
        //     align: 'center',
        //     render: (text, record) => (
        //         <a className='editarIcono' target='_blank' href={record.cartaFabricante} disabled={ record.cartaFabricante === "" }> <MailOutlined /></a>
        //     ),
        // }, {
            title: 'Fecha alta',
            dataIndex: 'fechaAlta',
            key: 'fechaAlta',
            width: '4%',
        },{
            title: 'Estatus',
            dataIndex: 'estadoActivo',
            key: 'estadoActivo',
            width: '4%',
        },
        // {
        //     title: 'Activo',
        //     dataIndex: 'activo',
        //     key: 'activo',
        //     width: '4%',
        //     render: (text) => (
        //         <Switch checked={text} disabled={true} />
        //     ),
        // }, 
        // {
        //     title: 'Editar',
        //     key: 'editar',
        //     width: '4%',
        //     align: 'center',
        //     render: (text, record) => (
        //         <a className='editarIcono' disabled={!permisosUsuario.editarActivo} onClick={() => onEditar(record)}><EditOutlined /></a>
        //     ),
        // },
        ];

        // const onEditar = (key) => {  console.log('key', key); onModalEditarActivo(key, this.state.page); }

        // const onNuevoActivo = () => { onModalNuevoActivo(this.state.page); }

        const handleTableChange = (pagination) => { this.setState({ page: pagination.current, }); onGetActivosBusqueda(pagination.current, this.state.busqueda); }

        const scroll = {"x": 2500 | true, "y": 1000};

        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={23}>
                        <Search className='buscarModulos' placeholder="Ingresa código interno, código equipo, SKU o número de serie"  onSearch={value => onBuscarActivo(value)} style={{ width: '100%' }} />
                        <br />
                        <br />
                    </Col>
                    {/* <Col span={1}>
                        <Tooltip title='Nuevo Activo' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoActivo} disabled={!permisosUsuario.crearActivo} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Exportar archivo .xls' placement="topRight">
                            <Button disabled={!permisosUsuario.exportarExcelCatálogoActivos} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } loading={this.props.fetchingExportarExcelActivos} onClick={() => this.props.onExport()}/>
                        </Tooltip>
                    </Col> 
                    <Col span={24}>
                        <Table
                            size="small"
                            rowKey="descripcionActivo"
                            columns={columns}
                            dataSource={listaActivosBusqueda}
                            loading={fetchingListaActivosBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaActivosBusquedaCantidad, simple: true, current: this.state.page }}
                            scroll={scroll}
                        />
                    </Col>
                </Row>
                
                <ModalNuevoActivo />
                <ModalEditarActivo />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        listaActivosBusqueda: state.AlmacenesReducer.listaActivosBusqueda,
        fetchingListaActivosBusqueda: state.AlmacenesReducer.fetchingListaActivosBusqueda,
        listaActivosBusquedaCantidad: state.AlmacenesReducer.listaActivosBusquedaCantidad,
        fetchingExportarExcelActivos: state.AlmacenesReducer.fetchingExportarExcelActivos,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetActivosBusqueda: (page, busqueda) => {
            dispatch({ type: "LISTA_ACTIVO_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
        },
        onEstadoActivo: (idActivo, page) => {
	    	dispatch({ type: "ESTADO_ACTIVO_REQUEST", idActivo: idActivo, page: page });
        },
        onModalNuevoActivo: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_ACTIVO", showModalNuevoActivo: true, page: page });
        },
        onModalEditarActivo: (activoSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_ACTIVO", showModalEditarActivo: true, activoSeleccionado: activoSeleccionado, page: page });
        },
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        },
        onExport: () => {
            dispatch({ type: "EXPORTAR_ACTIVOS_EXCEL_REQUEST" });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoDeActivos);