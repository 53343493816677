import React, { Component } from 'react';
import { Form,Row, Input, Button, Col, Spin, Modal, Select, message, Upload, Tooltip, Typography } from 'antd';
import { FilePdfFilled } from '@ant-design/icons';
import { connect } from 'react-redux';
import {cancelUploadE} from '../../services/BaseDeConocimientoServices';
import '../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option, OptGroup } = Select;
const confirm = Modal.confirm;
const { Dragger } = Upload;
const { Text, Paragraph } = Typography;
const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 }, };

class ModalEditarDocumentoBase extends Component {
    formRef = React.createRef();
    state = {
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
        serviciosTodos:false,
        fileList: [],
        requiredDocument: false
    }

    componentDidUpdate( prevProps) {
        if (this.props.DocumentoBaseSeleccionado !== prevProps.DocumentoBaseSeleccionado) {
            console.log("CAMBIO ESTADO")
            this.setState({
                filePDF: false,
                botonPDF: false,
                botonEditar: false,
                serviciosTodos: false,
                fileList: [],
                requiredDocument: false,
            })
        }
    }

    render() {
        const { fetchingEditarDocumentoBase, showModalEditarDocumentoBase, fetchingListaServiciosFiltro, listaServiciosFiltro, listaServiciosActivosF, fetchingListaServiciosActivosF,DocumentoBaseSeleccionado, onShowModalEditarDocumentoBase, onEditarDocumentoBase, onListaServiciosFiltro, onListaServiciosFiltroLimpiar, page, limpiarFormulario } = this.props
            
        if (limpiarFormulario && limpiarFormulario === 'EDB') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        const buscarServicios = value => {
            if (value.length >= 3) {
                if (value) {
                    console.log(value);
                    onListaServiciosFiltro(value)
                } else {
                    message.warning('No se encontró servicio con esas palabras')
                }
            } else {
                onListaServiciosFiltroLimpiar();
            }
        }

        const onChangeServicios = value => {
            console.log(value);
            this.setState({serviciosTodos: false});
            const todos = value.some(item => item === 'todos');
            if(todos) {
                this.setState({serviciosTodos: true});
                this.formRef.current.setFieldsValue({'servicios':['todos']})
            }
        }

        const onChangePDF = (info) => {
            console.log('info', info)
            let fileList = [...info.fileList];
            // 1. Limit the number of uploaded files. Only to show two recent uploaded files, and old ones will be replaced by the new
            fileList = fileList.slice(-1);

            let condicionFileList = true;

            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                console.log('info.file, info.fileList', info.file, info.fileList)
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                })
            }
            let reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
            console.log('info.file.status', info.file.status)
            if (info.file.status === 'done') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['rutaArchivo']);
                    this.setState({fileList:[]});
                    condicionFileList = false;
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true,
                        fileList
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['rutaArchivo']);
                    this.setState({fileList: []});
                    condicionFileList = false;
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true,
                        fileList
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }

            if(condicionFileList){ this.setState({ fileList  }); } else { this.setState({ fileList: []  }); }
            
        }
        const onRemovePDF = () => {
            this.formRef.current.resetFields(['rutaArchivo']);
            this.setState({
                filePDF: false,
                botonPDF: false,
                fileList: []
            });
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };

        const onFinish = formulario => {
            let {servicios} = formulario;
            const serviciosObject = servicios.map(id => listaServiciosActivosF.find(item => item.nombre ==  id.split('-').reverse()[0] ));
            const serviciosArray = servicios[0] === "todos" ? listaServiciosActivosF : serviciosObject;
            const version = formulario.version;
            const documentoBaseFile = this.formRef.current.getFieldValue('rutaArchivo');
            console.log("DOCUMENTO", documentoBaseFile);
            if ((version !== DocumentoBaseSeleccionado.version && !documentoBaseFile.file) ||
             (version == DocumentoBaseSeleccionado.version && documentoBaseFile.file) )  {
                 message.error('Favor de actualizar la versión y el documento base.')
                } else {
                    console.log('Success:' , formulario, serviciosArray);
                    onEditarDocumentoBase(formulario, serviciosArray, DocumentoBaseSeleccionado, page, this.props.busqueda);
            }
            this.setState({fileList:[]});
        }

        const handleCancel = () => {
            if(fetchingEditarDocumentoBase) {
                confirm({
                    title: `¿Estás seguro que deseas cancelar la carga del documento?`,
                    content: '',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                        cancelUploadE();
                        onShowModalEditarDocumentoBase();     
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            } else {     
                onShowModalEditarDocumentoBase();
                this.formRef.current.resetFields();
                this.setState({
                    filePDF: false,
                    botonPDF: false,
                    botonEditar: false,
                    fileList:[]
                })
            }
        }

        const onVersion = () => {
            if(DocumentoBaseSeleccionado.version !== this.formRef.current.getFieldValue(['version']))
                this.setState({requiredDocument: true});
            else
                this.setState({requiredDocument: false});
        };

        return (
            <Modal
                visible={showModalEditarDocumentoBase}
                title='Editar Documento Base'
                onCancel={handleCancel}
                footer={false}
                width='80%'
            >
                <Spin spinning={fetchingEditarDocumentoBase}>
                    {DocumentoBaseSeleccionado ? (

                        <Form {...layout}
                        name='formulario'
                        initialValues={DocumentoBaseSeleccionado}
                        onFinish={onFinish}
                        
                        ref={this.formRef}
                        >
                        <Row type='flex' justify='space-around' gutter={[8,8]}>
                            <Col span={12}>
                                <Form.Item
                                    label="Descripción del documento"
                                    name="descripcion"
                                    hasFeedback
                                    rules={[{ required: true, message: 'Favor de llenar el campo', },
                                        ({getFieldValue}) => ({
                                            validator(_, value){
                                                console.log('value', value)
                                                if(/[*$%"¢@#&{}/]/.test(value)){
                                                    return Promise.reject(new Error('La descripción sólo debe tener letras, números, punto, paréntesis o guiones'));
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            }
                                        })
                                    ]}>
                                    <Input
                                        // pattern="^^[a-zñA-ZÑ0-9 ÁÉÓÚÍáéóíú /-()]+$"
                                        // title="Nombre debe tener letras."
                                        maxLength={50} placeholder='Descripción del documento' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Tooltip placement="top" title="Al cambiar la versión, debes actualizar el documento base." >
                                    <Form.Item
                                        label="Versión"
                                        name="version"
                                        hasFeedback
                                        rules={[{ required: true, message: 'Favor de llenar el campo', },
                                        ({getFieldValue}) => ({
                                            validator(_, value){
                                                console.log('value', value)
                                                if(!/[0-9]/.test(value)){
                                                    return Promise.reject(new Error('Versión debe tener solo números'));
                                                }else if(/[A-ZÑa-zñ]/.test(value)) {
                                                    return Promise.reject(new Error('Versión debe tener solo números'));
                                                }else {
                                                    return Promise.resolve();
                                                }
                                            }
                                        })
                                    ]}>
                                        <Input
                                            // pattern="^^[. 0-9]+$"
                                            // title="Versión debe tener solo números."
                                            maxLength={10} placeholder='Versión' onChange={onVersion}/>
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Servicios"
                                    name="servicios"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Select
                                        mode='multiple'
                                        allowClear
                                        style={{ width: '100%', textAlign: 'left' }}
                                        placeholder="Busque un servicio"
                                        loading={fetchingListaServiciosFiltro}
                                        showSearch
                                        filterOption={false}
                                        onSearch={buscarServicios}
                                        onChange={onChangeServicios}
                                        notFoundContent={null}
                                        >
                                        <OptGroup label="Todos los servicios">
                                            <Option value="todos">Todos</Option>
                                        </OptGroup>
                                        <OptGroup label="Servicios">
                                            {listaServiciosFiltro.map(option => <Option disabled={this.state.serviciosTodos} key={option.idServicio + '-' + option.nombre}>{option.idServicio + '-' + option.nombre}</Option>)}
                                        </OptGroup>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Tooltip placement="bottom" title="Al actualizar el documento base, debes cambiar la versión.">
                                    <Form.Item
                                        label="Documento base PDF"
                                        name="rutaArchivo"
                                        rules={[{ required: this.state.requiredDocument, message: 'Favor de llenar el campo' }]}
                                        >
                                        {/* <Upload  customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                            {this.state.filePDF === false ? (
                                                <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                                    <UploadOutlined /> Click para subir Documento Base PDF
                                                </Button>
                                            ) : false}
                                        </Upload> */}
                                        {console.log('this.state.fileList', this.state.fileList)}
                                        <Dragger customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false} style={{borderRadius:'1em'}} fileList={this.state.fileList} >
                                            <Row justify='center'>
                                                <FilePdfFilled className='iconTabPane'/>
                                                <Col span={24} style={{paddingTop:'1em'}}>
                                                    <Paragraph>
                                                        <Text strong> Click o arrastra a ésta área el archivo PDF </Text>
                                                    </Paragraph>
                                                </Col>
                                            </Row>
                                        </Dragger>
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                            <Col span={12}>
                                <Button className="btnFiltrar"  style={{ width: '100%' }} htmlType="submit">Editar Documento Base</Button>
                            </Col>
                        </Row>
                    </Form>
                ): false}
                </Spin>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingEditarDocumentoBase: state.BaseDeConocimientoReducer.fetchingEditarDocumentoBase,
        showModalEditarDocumentoBase: state.BaseDeConocimientoReducer.showModalEditarDocumentoBase,
        DocumentoBaseSeleccionado: state.BaseDeConocimientoReducer.DocumentoBaseSeleccionado,
        page: state.BaseDeConocimientoReducer.page,
        busqueda: state.BaseDeConocimientoReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarDocumentoBase: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_DOCUMENTO_BASE', showModalEditarDocumentoBase: false, DocumentoBaseSeleccionado: null });
        },
        onEditarDocumentoBase: (formulario, serviciosArray, DocumentoBaseSeleccionado, page, busqueda) => {
            dispatch( { type: 'EDITAR_DOCUMENTO_BASE_REQUEST', formulario: formulario, serviciosArray:serviciosArray, DocumentoBaseSeleccionado, page, busqueda });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        },
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
        },
        onGetServiciosActivos: () => {
            dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarDocumentoBase);