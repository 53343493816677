import React from 'react';
import {connect} from 'react-redux';
import {  Button, Row, Col, Table, Switch, Radio, Modal, Tooltip} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { FilePdfFilled, FileExcelFilled } from '@ant-design/icons';
import CrearRegistro from './ModalNuevoRegistro';

const {confirm} = Modal;
class TableSection extends React.Component {
    formRef = React.createRef();
    // componentDidMount(){ 
    //     onGetProveedores();
    // }

    state={ 
        agrupado: 'SinAgrupar',
        tipoAgrupado: '',
        page: 1,
        orden: {
            field: 'ser.nombre',
            order: 'ASC'
        }
    }

    render() {
            const {fetchingListaReporteDinamicoRH, listaReporteDinamicoRH, listaReporteDinamicoCantidadRH, fetchingReporteDinamicoEXCEL, fetchingReporteDinamicoPDF,  formularioReporte,listaServiciosActivosF, handlers, data, onDescargarReportePDF, onDescargarReporteExcel, onGetReporteDinamico, onDescargarReporteAgrupadoPDF, onDescargarReporteAgrupadoExcel, onGetReporteDinamicoAgrupado, listaReporteDinamicoAgrupadoRH, listaReporteDinamicoAgrupadoCantidadRH, fetchingReporteDinamicoAgrupadoRH, onModalNuevoRegistroReporte, fetchingReporteDinamicoAgrupadoEXCEL, fetchingReporteDinamicoAgrupadoPDF
            } = this.props;
            
            let permisosUsuario = sessionStorage.getItem('permisosUsuario');
            if(permisosUsuario[0]) {
                permisosUsuario = JSON.parse(permisosUsuario);
            }

            const {checkbox, checkArray, state, order} = data;
            const {handleTableChange, handleSteps, handleOrden} = handlers;
            
            const onHandleTableChange = (pagination, filter, orden) => {
                handleTableChange(pagination, filter, orden);
            }

            const onHandleSteps = (value) => {
                handleSteps(value);
            }
            
            const descargarReporte = (value) => {
                let _this = this;
                confirm({
                    title: '¿Deseas guardar el reporte como una vista predeterminada?',
                    content: '',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                        onModalNuevoRegistroReporte(formularioReporte, checkbox, value, _this.state.agrupado, order);
                    },
                    onCancel() {
                        if(_this.state.agrupado === 'SinAgrupar'){
                            if (value === 'pdf') { 
                                onDescargarReportePDF(formularioReporte, checkbox, order); 
                            }  else { 
                                onDescargarReporteExcel(formularioReporte, checkbox, order); 
                            }
                        } else {
                            if (value === 'pdf') { 
                                onDescargarReporteAgrupadoPDF(formularioReporte, checkbox, _this.state.agrupado, order); 
                            }  else { 
                                onDescargarReporteAgrupadoExcel(formularioReporte, checkbox, _this.state.agrupado, order); 
                            }
                        }
                    },
                });
            }

            const dateWidth = checkArray.length>4 ? '170px' : '170px';
            
            const columns = [
                {title: 'Número de empleado',dataIndex: 'numeroEmpleado',key: 'numeroEmpleadoC', align: 'right', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true}, 
                {title: 'Nombre',dataIndex: 'nombre', key: 'nombreC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Apellido paterno',dataIndex: 'aPaterno',key: 'aPaternoC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Apellido materno',dataIndex: 'aMaterno',key: 'aMaternoC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'RFC',dataIndex: 'rfc',key: 'rfcC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Folio ID',dataIndex: 'folioID',key: 'folioIDC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Proveedor',dataIndex: 'nombreProveedor',key: 'nombreProveedorC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Servicio',dataIndex: 'nombreServicio',key: 'nombreServicioC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Correo',dataIndex: 'correo',key: 'correoC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Puesto',dataIndex: 'puesto',key: 'puestoC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Fecha ingreso',dataIndex: 'fechaIngreso',key: 'fechaIngresoC',width: dateWidth, align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Tipo turno',dataIndex: 'tipoTurno',key: 'tipoTurnoC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true}, 
                {title: 'Días laborales',dataIndex: 'diasLaborales',key: 'diasLaboralesC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Horario entrada',dataIndex: 'horarioEntrada',key: 'horarioEntradaC', align: 'right', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Horario salida',dataIndex: 'horarioSalida',key: 'horarioSalidaC', align: 'right', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Salida comida',dataIndex: 'salidaComida',key: 'salidaComidaC', align: 'right', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Regreso comida',dataIndex: 'regresoComida',key: 'regresoComidaC', align: 'right', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Clave de expediente',dataIndex: 'expedienteClave',key: 'expedienteClaveC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
                {title: 'Condición',dataIndex: 'activo',key: 'activoC', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true,
                    render: (text, record) => (
                        <Switch checked={text} disabled checkedChildren="Activo" unCheckedChildren="Inactivo"/>
                    ),
                },
                {title: 'Razón de baja',dataIndex: 'razonBaja',key: 'razonBajaC', align: 'center', sorter: this.state.agrupado !== 'SinAgrupar' ? false : true},
            ];
            
            const columnsSelected = columns.filter( field => checkArray.includes(field.key));
            const scroll = columnsSelected.length > 4 ? {x: columnsSelected.length * 200} : {x: 700}

            const onAgrupar = (value) => {
                console.log('value Agrupar', value)

                const tipoAgrupado = value.target.value === 'Por servicios' ? 'Servicio' : value.target.value === 'Por grupo de servicios' ? 'Grupo de Servicios' : value.target.value === 'Por gerencia' ? 'Gerencia' : value.target.value === 'Por estado' ? 'Estado' : value.target.value === 'Por grupo servicio' ? 'Grupo de Servicio' : 'Mes'
                this.setState({
                    agrupado: value.target.value,
                    tipoAgrupado
                });
                formularioReporte.tipoAgrupado = value.target.value;
                if(value.target.value !== 'SinAgrupar'){
                    console.log('Entra a != sin agrupar')
                    onGetReporteDinamicoAgrupado(formularioReporte, 1, value.target.value, checkbox, order)
                }
                else{
                    onGetReporteDinamico(formularioReporte, 1, order, checkbox)
                }
            }

            const columnsAgrupamiento = [
                { title: this.state.tipoAgrupado, dataIndex: 'nombre', key: 'nombre' },
            ];

            const onChangeAgrupado = (record, pagination, filter, orden) => {
                console.log(`record, e`, record, pagination, filter, orden);
                const orderFields = {numeroEmpleadoC:'pr.numeroEmpleado', nombreC:'pr.nombre', aPaternoC:'pr.aPaterno', aMaternoC: 'pr.aMaterno', rfcC: 'pr.rfc', folioIDC: 'pr.folioID', nombreProveedorC: 'pro.nombre', nombreServicioC: 'ser.nombre', correoC: 'pr.correo', puestoC: 'pr.puesto', fechaIngresoC: 'pr.fechaIngreso', tipoTurnoC: 'pr.tipoTurno', diasLaboralesC: 'pr.diasLaborales', horarioEntradaC: 'pr.horarioEntrada', horarioSalidaC: 'pr.horarioSalida', salidaComidaC: 'pr.salidaComida', regresoComidaC: 'pr.regresoComida', expedienteClaveC: 'ex.clave', activoC: 'pr.activo', razonBajaC: 'pr.razonBaja'}
    
                record.listaReporteDinamicoAgrupadoRH = listaReporteDinamicoAgrupadoRH;
                record.listaReporteDinamicoAgrupadoCantidadRH = listaReporteDinamicoAgrupadoCantidadRH;
                if(orden) {
                    const field = orden.columnKey ? orderFields[orden.columnKey] : 'ser.nombre';
                    const orderField = orden.order === 'descend' ? 'DESC': 'ASC';
                    const sorter = {field: field, order: orderField}
                    this.setState({orden: sorter})
                    console.log(`sorter`, sorter)
                    return onGetReporteDinamico(formularioReporte, pagination.current, sorter, checkbox, record );
                }

                onGetReporteDinamico(formularioReporte, pagination.current, order, checkbox, record);
            };

            const ordenamientoGeneral = (value, tipo) => {
                console.log(`value`, value); console.log('tipo', tipo)
                if(tipo === 'field'){
                    handleOrden(value.target.value, order.order)
                }
                else{
                    handleOrden(order.field, value.target.value)
                }
            };

            return (
                <>
                    <Row justify='space-between' gutter={[16,16]}>
                        <Col span={3}>
                            <Row justify='space-around'>
                                <Col span={11}>
                                    {/* <Button className="btnCargaArchivos" disabled={listaReporteDinamicoCantidadRH <= 0 ? true : !permisosUsuario.exportarPdfReporteDinamicoRecursosHumanos} loading={fetchingReporteDinamicoPDF || fetchingReporteDinamicoAgrupadoPDF}
                                    onClick={()=> descargarReporte('pdf')} style={{ width: '100%', marginTop: '20px' }}
                                    >
                                        Exportar Reporte PDF <FilePdfFilled/>
                                    </Button> */}
                                    <Tooltip title='Exportar archivo .pdf'>
                                        <Button className="iconSecundary" shape='circle' icon={<FilePdfFilled /> } disabled={listaReporteDinamicoCantidadRH <= 0 ? true : !permisosUsuario.exportarPdfReporteDinamicoRecursosHumanos} loading={fetchingReporteDinamicoPDF || fetchingReporteDinamicoAgrupadoPDF} onClick={()=> descargarReporte('pdf')} style={{marginTop: '20px' }}/>
                                    </Tooltip>
                                </Col>
                                <Col span={11}>
                                    {/* <Button className="btnCargaArchivos" disabled={listaReporteDinamicoCantidadRH <= 0 ? true : !permisosUsuario.exportarExcelReporteDinamicoRecursosHumanos} loading={fetchingReporteDinamicoEXCEL || fetchingReporteDinamicoAgrupadoEXCEL}
                                    onClick={()=> descargarReporte('excel')} style={{ width: '100%', marginTop: '20px' }}
                                    >
                                        Exportar Reporte Excel  <FileExcelFilled/> 
                                    </Button> */}
                                    <Tooltip title='Exportar archivo .xls'>
                                        <Button className="iconSecundary" shape='circle' icon={<FileExcelFilled /> } disabled={listaReporteDinamicoCantidadRH <= 0 ? true : !permisosUsuario.exportarExcelReporteDinamicoRecursosHumanos} loading={fetchingReporteDinamicoEXCEL || fetchingReporteDinamicoAgrupadoEXCEL} onClick={()=> descargarReporte('excel')} style={{marginTop: '20px' }}/>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={9} style={{paddingTop:'2em'}}>
                            <Radio.Group defaultValue='SinAgrupar' onChange={onAgrupar}  size="small">
                                <Radio.Button value="Por gerencia">Gerencia</Radio.Button>
                                <Radio.Button value="Por grupo de servicios">Grupo Servicios</Radio.Button>
                                { checkbox.nombreServicioC ? (<Radio.Button value="Por servicios">Servicio</Radio.Button>): false }
                                { checkbox.activoC ? ( <Radio.Button value="Por estado">Condición</Radio.Button>): false }
                                <Radio.Button value='SinAgrupar'>Sin Agrupamiento</Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Row style={{ paddingTop: '20px' }}>
                                <Col span={24}>
                                    { this.state.agrupado !== 'SinAgrupar' ? (
                                        <Table
                                            title={() => 
                                                <Row justify='space-around' gutter={[8,8]} align='middle'>
                                                    <Col span={20}>
                                                        <Radio.Group defaultValue={order.field} onChange={(e) => ordenamientoGeneral(e, 'field')}  size="small">
                                                            { checkbox.numeroEmpleadoC ? (<Radio.Button value="pr.numeroEmpleado">Número de empleado</Radio.Button>) : false }
                                                            { checkbox.nombreC ? (<Radio.Button value="pr.nombre">Nombre</Radio.Button>) : false }
                                                            { checkbox.aPaternoC ? (<Radio.Button value="pr.aPaterno">Apellido paterno</Radio.Button>) : false }
                                                            { checkbox.aMaternoC ? (<Radio.Button value="pr.aMaterno">Apellido materno</Radio.Button>) : false }
                                                            { checkbox.rfcC ? (<Radio.Button value="pr.rfc">RFC</Radio.Button>) : false }
                                                            { checkbox.folioIDC ? (<Radio.Button value="pr.folioID">Folio ID</Radio.Button>) : false }
                                                            { checkbox.nombreProveedorC ? (<Radio.Button value="pro.nombre">Proveedor</Radio.Button>) : false }
                                                            { checkbox.nombreServicioC ? (<Radio.Button value="ser.nombre">Servicio</Radio.Button>) : false }
                                                            { checkbox.correoC ? (<Radio.Button value="pr.correo">Correo</Radio.Button>) : false }
                                                            { checkbox.puestoC ? (<Radio.Button value="pr.puesto">Puesto</Radio.Button>) : false }
                                                            { checkbox.fechaIngresoC ? (<Radio.Button value="pr.fechaIngreso">Fecha ingreso</Radio.Button>) : false }
                                                            { checkbox.tipoTurnoC ? (<Radio.Button value="pr.tipoTurno">Tipo turno</Radio.Button>) : false }
                                                            { checkbox.diasLaboralesC ? (<Radio.Button value="pr.diasLaborales">Días laborales</Radio.Button>) : false }
                                                            { checkbox.horarioEntradaC ? (<Radio.Button value="pr.horarioEntrada">Horario entrada</Radio.Button>) : false }
                                                            { checkbox.horarioSalidaC ? (<Radio.Button value="pr.horarioSalida">Horario salida</Radio.Button>) : false }
                                                            { checkbox.salidaComidaC ? (<Radio.Button value="pr.salidaComida">Salida comida</Radio.Button>) : false }
                                                            { checkbox.regresoComidaC ? (<Radio.Button value="pr.regresoComida">Regreso comida</Radio.Button>) : false }
                                                            { checkbox.expedienteClaveC ? (<Radio.Button value="ex.clave">Clave expediente</Radio.Button>) : false }
                                                            { checkbox.activoC ? (<Radio.Button value="pr.activo">Condición</Radio.Button>) : false }
                                                            { checkbox.razonBajaC ? (<Radio.Button value="pr.razonBaja">Razón de baja</Radio.Button>) : false }
                                                        </Radio.Group>
                                                    </Col>
                                                    <Col span={4}>
                                                        <Radio.Group defaultValue={order.order} onChange={(e) => ordenamientoGeneral(e, 'orden')} size="small">
                                                            <Radio.Button value="ASC">Ascendente</Radio.Button>
                                                            <Radio.Button value="DESC">Descendente</Radio.Button>
                                                        </Radio.Group>
                                                    </Col>
                                                </Row>
                                            }
                                            size='small'
                                            rowKey='id'
                                            columns={columnsAgrupamiento}
                                            dataSource={listaReporteDinamicoAgrupadoRH}
                                            expandable={{expandedRowRender: record => 
                                                <Table 
                                                    rowKey="id" 
                                                    columns={columnsSelected} 
                                                    loading={fetchingListaReporteDinamicoRH} 
                                                    onChange={(e,u,i) => onChangeAgrupado(record, e, u, i)} 
                                                    dataSource={record.agrupados.data} 
                                                    pagination={{ total: record.agrupados.cantidad, simple: true, current: record.agrupados.page }} 
                                                    scroll={scroll}
                                                /> 
                                            } }
                                            loading={fetchingReporteDinamicoAgrupadoRH}
                                            locale={{ emptyText: 'Sin datos' }}
                                            onChange={onHandleTableChange}
                                            pagination={{ total: listaReporteDinamicoAgrupadoCantidadRH, simple: true, current: state.page }}
                                            scroll={scroll}
                                        />
                                    ):  (
                                        <Table
                                            size='small'
                                            rowKey='id'
                                            columns={columnsSelected}
                                            dataSource={listaReporteDinamicoRH}
                                            loading={fetchingListaReporteDinamicoRH}
                                            locale={{ emptyText: 'Sin datos' }}
                                            onChange={onHandleTableChange}
                                            scroll={scroll}
                                            pagination={{ total: listaReporteDinamicoCantidadRH, simple: true, current: state.page }}
                                        />
                                    ) }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row justify='start'>
                        <Col span={6}>
                            <Button className="btnFiltrar" onClick={() => onHandleSteps('atras')} style={{ width: '100%', marginTop: '20px' }}>Anterior</Button>
                        </Col>
                    </Row>
                    <CrearRegistro />
                </>
            );
        }
    }


const mapStateToProps = state => {
    return {
        fetchingListaReporteDinamicoRH: state.RecursosHumanosReducer.fetchingListaReporteDinamicoRH,
        listaReporteDinamicoRH: state.RecursosHumanosReducer.listaReporteDinamicoRH,
        listaReporteDinamicoCantidadRH: state.RecursosHumanosReducer.listaReporteDinamicoCantidadRH,

        fetchingReporteDinamicoEXCEL: state.RecursosHumanosReducer.fetchingReporteDinamicoEXCEL,
        fetchingReporteDinamicoPDF: state.RecursosHumanosReducer.fetchingReporteDinamicoPDF,
        fetchingReporteDinamicoAgrupadoEXCEL: state.RecursosHumanosReducer.fetchingReporteDinamicoAgrupadoEXCEL,
        fetchingReporteDinamicoAgrupadoPDF: state.RecursosHumanosReducer.fetchingReporteDinamicoAgrupadoPDF,
        formularioReporte: state.RecursosHumanosReducer.formularioReporte,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,

        listaReporteDinamicoAgrupadoRH: state.RecursosHumanosReducer.listaReporteDinamicoAgrupadoRH,
        listaReporteDinamicoAgrupadoCantidadRH: state.RecursosHumanosReducer.listaReporteDinamicoAgrupadoCantidadRH,
        fetchingReporteDinamicoAgrupadoRH: state.RecursosHumanosReducer.fetchingReporteDinamicoAgrupadoRH,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onDescargarReportePDF: (formulario, checkboxData, order) => {
            dispatch({ type: 'PDF_REPORTE_DINAMICO_RH_REQUEST', formulario, checkboxData, order});
        },
        onDescargarReporteExcel: (formulario, checkboxData, order) => {
            dispatch({ type: 'EXCEL_REPORTE_DINAMICO_RH_REQUEST', formulario,checkboxData, order});
        },
        onGetReporteDinamico: (formulario, page, order, checkboxData, agrupado) => {
            dispatch({ type: 'LISTA_REPORTE_DINAMICO_RH_BUSQUEDA_REQUEST', formulario, page, order, checkboxData, agrupado});
        },
        onDescargarReporteAgrupadoPDF: (formulario, checkboxData, agrupado, order) => {
            dispatch({ type: 'PDF_REPORTE_DINAMICO_RH_AGRUPADO_REQUEST', formulario, checkboxData, agrupado, order });
        },
        onDescargarReporteAgrupadoExcel: (formulario, checkboxData, agrupado, order) => {
            dispatch({ type: 'EXCEL_REPORTE_DINAMICO_RH_AGRUPADO_REQUEST', formulario, checkboxData, agrupado, order });
        },
        onGetReporteDinamicoAgrupado: (formulario, page, agrupado, checkboxData, order) => {
            dispatch({ type: 'REPORTE_DINAMICO_AGRUPADO_RH_REQUEST', formulario, page, agrupado, checkboxData, order});
        },
        onModalNuevoRegistroReporte: (formulario, checkboxData, tipoDocumento, agrupado, order) => {
            dispatch({ type: "MODAL_CREAR_REGISTRO", showModalCrearRegistro: true, data: {formulario, checkboxData, tipoDocumento, agrupado, order} });
        },
    }
}

export default connect (mapStateToProps, mapDispatchToProps) (TableSection);