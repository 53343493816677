import React, {Component} from 'react'
import { Form, InputNumber, Button, Col, Spin, Modal, Select, Row, DatePicker , message} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import moment from 'moment';


const { Option } = Select;
const { RangePicker } = DatePicker;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalNuevoTiempoDeGarantia extends Component {
   componentDidMount(){
       this.props.onGetServicios()
       this.props.onGetUnidadesdeMedicion(1);
       this.props.onGetlistaInfoProyecto();
   }
    formRef = React.createRef();
    render() { 
        const {
            onNuevoTiempoDeGarantia,
            onShowModalNuevoTiempoDeGarantia,
            showModalNuevoTiempoDeGarantia,
            fetchingNuevoTiempoDeGarantia,
            fetchingListaServiciosActivosF,
            listaServiciosActivosF,
            fetchingListaUnidadesDeMedicionActivos,
            listaUnidadesDeMedicionActivos,
            listaInfoProyecto,
            page,
            limpiarFormulario,
            unidadesMediPorCategoria,
            fetchingUnidadesMediPorCategoria,
            onListaServiciosFiltro,
            onListaServiciosFiltroLimpiar,
            fetchingListaServiciosFiltro,
            listaServiciosFiltro,
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            const rangeValue = formulario['rangoVigencia'];
            formulario.rangoVigencia = rangeValue[0].format('YYYY-MM-DD') + "," + rangeValue[1].format('YYYY-MM-DD');
            formulario.fechaInicioVigencia = formulario.rangoVigencia.split(',')[0];
            onNuevoTiempoDeGarantia(formulario, page, listaServiciosActivosF, listaUnidadesDeMedicionActivos);
        };

        

        if (limpiarFormulario && limpiarFormulario === 'crearTiempoDeGarantia') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }
        const handleCancel = () => {
            onShowModalNuevoTiempoDeGarantia();
            this.formRef.current.resetFields();
        }
        const disabledRange = fecha => {
            return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicio;
        }

        const buscarServicios = value => {
            if(value.length>=3){
                if(value){
                    console.log(value);
                onListaServiciosFiltro(value)
                } else {
                    message.warning('No se encontró servicio con esas palabras')
                }
            } else {
               onListaServiciosFiltroLimpiar();
            } 
        }

        return (
            <Modal
                visible={showModalNuevoTiempoDeGarantia}
                title='Nuevo Tiempo de Garantia'
                onCancel={handleCancel}
                footer={false}
            >
                <Spin spinning={fetchingNuevoTiempoDeGarantia}>
                    <Form {...layout}
                        name="formulario"
                        onFinish={onFinish}
                        
                        ref={this.formRef}
                    >
                        <Row justify='space-around'></Row>

                        <Col span={24}>
                            <Form.Item
                                label="Servicio"
                                name="idServicio"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona el servicio"
                                    loading={fetchingListaServiciosFiltro}
                                    showSearch
                                    filterOption={false}
                                    onSearch={buscarServicios}
                                    notFoundContent={null}
                                >
                                    {listaServiciosFiltro.map(option => <Option key={option.idServicio}>{option.nombre}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Tiempo de garantia"
                                name="tiempoGarantia"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <InputNumber min={1} max={100} placeholder='Ingrese tiempo de garantia'
                                title="Número debe ser entero o fracción a dos decímales" 
                                pattern='^[0-9]*(\.[0-9]{0,2})?$' /> 
                            </Form.Item>
                        </Col>


                        <Col span={24}>
                            <Form.Item
                                label="Unidad de medida"
                                name="idUnidadMedida"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona la unidad de medida"
                                    loading={fetchingUnidadesMediPorCategoria}
                                >
                                    {unidadesMediPorCategoria.map(option => <Option key={option.idUnidadMedida}>{option.unidadMedida}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                        <Form.Item
                            label="Fechas vigencias tiempo de garantia"
                            name="rangoVigencia"
                            rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                        >
                            <RangePicker
                                format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio","Fecha fin"]}
                                disabledDate={disabledRange}
                                defaultValue={[, moment(listaInfoProyecto.fechaVigencia, 'DD-MM-YYYY')]}  
                                disabled={[false, true]} 
                                />
                        </Form.Item>
                            </Col>

                        <Col span={24}>
                            <Button className="btnFiltrar" htmlType="submit">Crear Tiempo de Garantia</Button>
                        </Col>
                        <Row />
                    </Form>
                </Spin>
            </Modal>
        );
    }
}
 const mapStateToProps = state => {
     return {
        showModalNuevoTiempoDeGarantia: state.MecanismosDePagosReducer.showModalNuevoTiempoDeGarantia,
        fetchingNuevoTiempoDeGarantia: state.MecanismosDePagosReducer.fetchingNuevoTiempoDeGarantia,
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,


        fetchingListaUnidadesDeMedicionActivos: state.AlmacenesReducer.fetchingListaUnidadesDeMedicionActivos,
        listaUnidadesDeMedicionActivos: state.AlmacenesReducer.listaUnidadesDeMedicionActivos,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,

        page: state.MecanismosDePagosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,

        unidadesMediPorCategoria: state.AlmacenesReducer.unidadesMediPorCategoria,
        fetchingUnidadesMediPorCategoria: state.AlmacenesReducer.fetchingUnidadesMediPorCategoria,
     }
 }

 const mapDispatchToProps = dispatch => {
     return {
            onShowModalNuevoTiempoDeGarantia: () => {
                dispatch({ type: "SHOW_MODAL_NUEVO_TIEMPODEGARANTIA", showModalNuevoTiempoDeGarantia: false })
            },
            onNuevoTiempoDeGarantia: (formulario, page ,listaServiciosActivosF, listaUnidadesDeMedicionActivos) => {
                dispatch({ type: "NUEVO_TIEMPODEGARANTIA_REQUEST", formulario: formulario, page: page, listaServiciosActivosF: listaServiciosActivosF, listaUnidadesDeMedicionActivos: listaUnidadesDeMedicionActivos})
            },
            onLimpiarFormulario: () => {
                dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
            },
            onGetServicios: () => {
                dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"})
            },
            onGetUnidadesdeMedicion: (idCategoriaUnidadMedida) => {    //Filtrar request por categoría
                dispatch({ type: "UNIDADES_MEDI_POR_CATEGORIA_REQUEST", idCategoriaUnidadMedida:idCategoriaUnidadMedida});
            },
            onGetlistaInfoProyecto: () => {
                dispatch({ type: "GET_INFOPROYECTO_REQUEST"})
            },
            onListaServiciosFiltro: (busqueda) => {
                dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
            },
            onListaServiciosFiltroLimpiar: () => {
                dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
            }
     }
 }

 export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoTiempoDeGarantia);