import React from 'react';
import {connect} from 'react-redux';
import {Table, Row, Col, Modal} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

class ModalDetallePrograma extends React.Component {


    render() {

        const {fetchingCancelacionOrdenesSPDetalle,cancelacionOrdenesSPDetalle,cancelacionOrdenesSPDetalleCantidad,showModalDetalleEstatusOrdenesSPPrograma,estatusOrdenSPSeleccionado,onShowModalDetalle} = this.props;
        
        const columns = estatusOrdenSPSeleccionado?.programa == "Rutina" ? 
        [{
            title: 'Fecha de Ejecución',
            dataIndex: 'fechaEjecucion',
            key: 'fechaEjecucion',
            // width: '40%',
        },{
            title: 'Ubicacion',
            dataIndex: 'ubicacion',
            key: 'ubicacion',
            // width: '40%',
        },{
            title: 'Resultados',
            dataIndex: 'resultados',
            key: 'resultados',
            // width: '40%',
        },{
            title: 'Observaciones',
            dataIndex: 'observaciones',
            key: 'observaciones',
            // width: '40%',
        }]: 
        [{
            title: 'Fecha de Ejecución',
            dataIndex: 'fechaEjecucion',
            key: 'fechaEjecucion',
            // width: '40%',
        },{
            title: 'Ubicacion',
            dataIndex: 'ubicacion',
            key: 'ubicacion',
            // width: '40%',
        },{
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
            // width: '40%',
        },{
            title: 'Código Interno',
            dataIndex: 'codigoInterno',
            key: 'codigoInterno',
            // width: '30%',
        },{
            title: 'Descripcion activo',
            dataIndex: 'descripcion',
            key: 'descripcion',
            // width: '40%',
        },{
            title: 'Resultados',
            dataIndex: 'resultados',
            key: 'resultados',
            // width: '40%',
        },{
            title: 'Observaciones',
            dataIndex: 'observaciones',
            key: 'observaciones',
            // width: '40%',
        }]

        const handleCancel = () =>{
            onShowModalDetalle();
        }

        return (
            <div>
                {estatusOrdenSPSeleccionado ? (
                    <Modal
                        visible={showModalDetalleEstatusOrdenesSPPrograma}
                        title={`Detalle del Programa de la Orden: '${estatusOrdenSPSeleccionado.folioOrden}'`}
                        onCancel={handleCancel}
                        footer={false}
                        width='85%'
                    >    
                    <Row type='flex' justify='space-around'>
                        <Col span={23}>
                            <Table
                                size='small'
                                rowKey={estatusOrdenSPSeleccionado?.programa == "Rutina" ? "ubicacion" : "codigoInterno"}
                                columns={columns}
                                dataSource={cancelacionOrdenesSPDetalle}
                                loading={fetchingCancelacionOrdenesSPDetalle}
                                locale={{ emptyText: 'Sin datos' }}
                                pagination={{ total: cancelacionOrdenesSPDetalleCantidad, simple: true}}
                            />
                        </Col>
                    </Row>                    
                    </Modal>
                ) : false}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingCancelacionOrdenesSPDetalle: state.ServiciosPlanificadosReducer.fetchingCancelacionOrdenesSPDetalle,
        cancelacionOrdenesSPDetalle: state.ServiciosPlanificadosReducer.cancelacionOrdenesSPDetalle,
        cancelacionOrdenesSPDetalleCantidad: state.ServiciosPlanificadosReducer.cancelacionOrdenesSPDetalleCantidad,
        showModalDetalleEstatusOrdenesSPPrograma: state.ServiciosPlanificadosReducer.showModalDetalleEstatusOrdenesSPPrograma,
        estatusOrdenSPSeleccionado: state.ServiciosPlanificadosReducer.estatusOrdenSPSeleccionado,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalDetalle: () => {
            dispatch({ type: 'SHOW_MODAL_DETALLE_ESTATUS_ORDEN_SP_PROGRAMA', showModalDetalleEstatusOrdenesSPPrograma: false, cancelacionOrdenesSPDetalle: [], cancelacionOrdenesSPDetalleCantidad: null});
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalDetallePrograma);