import React, { Component } from 'react';
import {Card, Form, InputNumber, Input, Button, Col, Row, Divider, Spin, message, DatePicker, Upload, Select, Typography} from 'antd'; 
import { UploadOutlined, PaperClipOutlined, FileSearchOutlined, EditOutlined, FileImageFilled } from '@ant-design/icons';

import {connect} from 'react-redux';
import '../../stylesheets/ConfiguracionYSeguridad/Catalogos.css'
import NuevoInfoProyecto from './NuevoInfoProyecto';
import ModalEditarExpediente from '../InformaciónOperativa/CatalogoDeExpedientes/ModalEditarExpediente';
import ModalArchivoExpediente from '../InformaciónOperativa/CatalogoDeExpedientes/ModalArchivoExpediente';
import ModalEditarContrato from '../InformaciónOperativa/CatalogoDeContratos/ModalEditarContrato';
import ModalArchivoContrato from '../InformaciónOperativa/CatalogoDeContratos/ModalArchivoContrato';
import imagenNoResult from '../../assets/no-result-icon.png'

const { Dragger } = Upload;
const { Text, Paragraph } = Typography;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const {Option} = Select;

class InfoProyectoContainer extends Component {
    formRef = React.createRef();
    componentDidMount() {
        this.props.onRequestInfoProyecto();
        this.props.onGetExpedientesActivos();
        this.props.verificarRequest();
    }
    state = {
        fileImagen: false,
        botonImagen: false,
        filePlano: false,
        botonPlano: false,
        botoEditar: false,
        rangoVigencia: '',
        imageListaPlano: [],
        imageLista: [],
    }
    addDefaultSrc(ev){
        ev.target.src = imagenNoResult;
    }
    
    render() { 
        const { fetchingListaInfoProyecto,
            listaInfoProyecto,
            onEditarInfoProyecto,
            listaContratos,
            onModalArchivoContrato,
            onModalEditarContrato,
            onModalEditarExpediente,
            onModalArchivoExpediente,
            onArchivosExpediente,
            listaVerificarPARv, fetchingEditarListaInfoProyecto
        } = this.props

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        const onFinish = formulario => {
            console.log('formulario', formulario);
            this.setState({
                fileImagen: false,
                botonImagen: false,
                filePlano: false,
                botonPlano: false,
                botoEditar: false,
                rangoVigencia: '',
            })
            onEditarInfoProyecto(formulario, listaInfoProyecto, listaContratos);

        }       
        

        const onChangeImagen = (info, tipo) => {
            console.log('info');
            console.log(info);
            let imageLista = [...info.fileList];
   
            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            imageLista = imageLista.slice(-1);

            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonImagen: true,
                    botonPlano: true,
                    botonEditar: true,
                });

                if(tipo === 'imagen'){
                    this.setState({
                        imageLista: imageLista,
                    });
                } else {
                    this.setState({
                        imageListaPlano: imageLista,
                    });
                }
            }
            if (info.file.status === 'done') {
                var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,30}$");

                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['imagen']);
                    this.setState({imageLista: [], imageListaPlano: []});
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    if(tipo === 'imagen'){
                        this.setState({
                            fileImagen: true,
                            imageLista: imageLista,
                        });
                    }
                    else{
                        this.setState({
                            filePlano: true,
                            imageListaPlano: imageLista,
                        });
                    }
                }
                this.setState({
                    botonImagen: false,
                    botonEditar: false,
                })
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['imagen']);
                    this.setState({imageLista: [], imageListaPlano: []});
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    if(tipo === 'imagen'){
                        this.setState({
                            fileImagen: true,
                            imageLista: imageLista,
                        });
                    }
                    else{
                        this.setState({
                            filePlano: true,
                            imageListaPlano: imageLista,
                        });
                    }
                }
                this.setState({
                    botonImagen: false,
                    botonEditar: false,
                })
            }
        }
        const onRemoveImagen = (tipo) => {
            if(tipo === 'imagen'){
                this.setState({
                    fileImagen: false,
                    botonImagen: false,
                    imageLista: [],
                });
            }
            else{
                this.setState({
                    filePlano: false,
                    botonImagen: false,
                    imageListaPlano: [],
                });
            }
        }

        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };

        const onVerArchivo = (key) => {
            console.log(key);
            onArchivosExpediente(key.expedienteByIdExpediente.nombreCarpeta);
            onModalArchivoExpediente(key.expedienteByIdExpediente, 1);
        }
        const onAdjuntarArchivo = (key) => {
            onModalEditarExpediente(key.expedienteByIdExpediente, 1, true);
        }
        const onVerContrato = (key) => {
            console.log(key);
            onModalArchivoContrato(key.contratoByIdContrato);
        }
        const onEditar = (key) => {
            console.log(key);
            onModalEditarContrato(key.contratoByIdContrato, 1);
        }

        console.log("listaInfoProyecto.idProyecto && listaInfoProyecto.idContrato",listaInfoProyecto.idProyecto, listaInfoProyecto.idContrato)
        return (
            <div>
                { typeof listaInfoProyecto === "object" && 
                <Card className="cardCatalogos" title={<div className="headerLista">Información del Proyecto</div>}>
                    {listaInfoProyecto.idProyecto && listaInfoProyecto.idContrato ? (
                <Spin spinning={fetchingListaInfoProyecto || fetchingEditarListaInfoProyecto} >
                       
                            <Form {...layout}
                                name="formulario"
                                initialValues={listaInfoProyecto}
                                onFinish={onFinish}
                                
                                ref={this.formRef}
                            >
                                <Row justify='space-around'>
                                    <Col span={11}>
                                        <Form.Item
                                            label="RFC"
                                            name="rfc"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <Input maxLength={12}
                                                minLength={12} placeholder="Ingresa el RFC"
                                                pattern='^([A-ZÑ&]{3}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$'
                                                title='No es un formato válido para RFC persona moral' />
                                        </Form.Item>
                                    </Col>

                                    <Col span={11}>
                                        <Form.Item
                                            label="Razón social"
                                            name="razonSocial"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <Input maxLength={80} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={11}>
                                        <Form.Item
                                            label="Nombre del cliente"
                                            name="nombreCliente"
                                            rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                            <Input maxLength={80} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={11}>
                                        <Form.Item
                                            label="Centro negocios"
                                            name="centroNegocios"
                                            rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                            <Input maxLength={40} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={11}>
                                        <Form.Item
                                            label="Años de vigencia del contrato"
                                            name="vigencia"
                                            rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                            <InputNumber placeholder="Ingresa los años de vigencia del contrato" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={11}>
                                        <Form.Item
                                            label="Pago anual de referencia"
                                            name="pagoAnualReferencia"
                                        >
                                            <InputNumber formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={11}>
                                        <Form.Item
                                            label="Poblacion PTV maxima"
                                            name="poblacionPtvMax"
                                            rules={[{ required: true, message: 'Favor de llenar el campo (Campo editable)' }]}
                                        >
                                            <InputNumber formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\s?|(,*)/g, '')}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </Col>
                                  {listaVerificarPARv.length > 0 ? (
                                    <Col span={11}>
                                        <Form.Item
                                            label="Pago de referencia variable"
                                            name="pagoReferenciaVar"
                                        >
                                            <InputNumber formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                style={{ width: '100%' }}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Col>
                                    ): (
                                        <Col span={11}>
                                        <Form.Item
                                            label="Pago de referencia variable"
                                            name="pagoReferenciaVar"
                                        >
                                            <InputNumber formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    ) }

                                    <Col span={11}>
                                        <Form.Item
                                            label="Poblacion PTV minima"
                                            name="poblacionPtvMin"
                                        >
                                            <InputNumber formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={value => value.replace(/\s?|(,*)/g, '')}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={11}>
                                        <Form.Item
                                            label="Pago anual de inversion"
                                            name="pagoAnualInversion"
                                        >
                                            <InputNumber formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}

                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={11}>
                                        <Form.Item
                                            label="Fecha de inicio del proyecto"
                                            name="fechaInicio"
                                            rules={[{ required: true, message: 'Favor de llenar el campo ' }]}
                                        >
                                            <DatePicker style={{ width: '100%' }} format='DD-MM-YYYY'   />
                                        </Form.Item>
                                    </Col>

                                    <Col span={11}>
                                        <Form.Item
                                            label="Fecha de inicio de operacion"
                                            name="fechaInicioOperacion"
                                            rules={[{ required: true, message: 'Favor de llenar el campo ' }]}
                                        >
                                            <DatePicker style={{ width: '100%' }} format='DD-MM-YYYY'   />
                                        </Form.Item>
                                    </Col>

                                    <Col span={11}>
                                        <Form.Item
                                            label="Contrato"
                                            name="contrato"
                                        >
                                            <Row justify='space-around'>
                                            <Col span={8}>
                                                <p>Ver contrato: <a className='editarIcono' onClick={() => onVerContrato(listaInfoProyecto)}><FileSearchOutlined /></a></p>
                                            </Col>
                                            <Col span={14}>
                                                <p>Editar contrato: <a className='editarIcono' onClick={() => onEditar(listaInfoProyecto)}><EditOutlined /></a></p>
                                            </Col>
                                        </Row>
                                        </Form.Item>
                                    </Col>

                                    <Col span={11}>
                                        <Form.Item
                                            label="Expediente"
                                            name="expediente"
                                        >
                                            <Row justify='space-around'>
                                            <Col span={8}>
                                                <p>Ver archivos: <a className='editarIcono' onClick={() => onVerArchivo(listaInfoProyecto)}><FileSearchOutlined /></a></p>
                                            </Col>
                                            <Col span={14}>
                                                <p>Adjuntar archivo a expediente: <a className='editarIcono' onClick={() => onAdjuntarArchivo(listaInfoProyecto)}><PaperClipOutlined /></a></p>
                                            </Col>
                                        </Row>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Divider>Dirección</Divider>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item
                                            label="Calle"
                                            name="calle"
                                            rules={[{ required: true, message: 'Favor de llenar el campo (Campo editable)' }]}
                                        >
                                            <Input maxLength={40} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label="Número exterior"
                                            name="numeroExterior"
                                            rules={[{ required: true, message: 'Favor de llenar el campo (Campo editable)' }]}
                                        >
                                            <Input maxLength={10} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label="Número interior"
                                            name="numeroInterior"
                                        // rules={[{ required: true, message: 'Favor de llenar el campo (Campo editable)' }]}
                                        >
                                            <Input maxLength={5} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label="Nombre colonia"
                                            name="nombreColonia"
                                            rules={[{ required: true, message: 'Favor de llenar el campo (Campo editable)' }]}
                                        >
                                            <Input maxLength={50} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Código postal"
                                            name="cp"
                                            rules={[{ required: true, message: 'Favor de llenar el campo (Campo editable)' }]}
                                        >
                                            <Input minLength='5' maxLength='5' title="El código postal debe ser números" pattern="^[0-9]+$" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Municipio o alcaldía"
                                            name="municipio"
                                            rules={[{ required: true, message: 'Favor de llenar el campo (Campo editable)' }]}
                                        >
                                            <Input name="municipio" maxLength={50} title="El municipio debe contener sólo letras" pattern="^[a-zñA-ZÑ ÁÉÓÚÍáéóíú]+$" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Estado"
                                            name="estado"
                                            rules={[{ required: true, message: 'Favor de llenar el campo (Campo editable)' }]}
                                        >
                                            <Input name="estado" maxLength={50} title="El estado debe contener sólo letras" pattern="^[a-zñA-ZÑ ÁÉÓÚÍáéóíú]+$" />
                                        </Form.Item>
                                    </Col>
                                
                                </Row>
                                <Row justify='space-between'>
                                    <Col span={11}>
                                        <Form.Item
                                            label="Imagen"
                                            name="imagen"
                                        >
                                            {/* <Upload onChange={e => onChangeImagen(e, 'imagen')} onRemove={() => onRemoveImagen('imagen')} customRequest={dummyRequest} accept='.jpg, .jpeg, .png' multiple={false}>
                                                {this.state.fileImagen === false ? (
                                                    <Button type="dashed" disabled={this.state.botonImagen} className='btnCargaArchivos'>
                                                        <UploadOutlined /> Click para subir imagen
                                                    </Button>
                                                ) : false}
                                            </Upload> */}
                                            <Dragger fileList={this.state.imageLista} customRequest={dummyRequest} onChange={e => onChangeImagen(e, 'imagen')} onRemove={() => onRemoveImagen('imagen')} accept='.jpg, .jpeg, .png' multiple={false} style={{borderRadius:'1em'}}>
                                                <Row justify='center'>
                                                    <FileImageFilled className='iconTabPane'/>
                                                    <Col span={24} style={{paddingTop:'1em'}}>
                                                        <Paragraph>
                                                            <Text strong> Click o arrastra a ésta área el achivo: jpg, png, jpeg </Text>
                                                        </Paragraph>
                                                    </Col>
                                                </Row>
                                            </Dragger>
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item
                                            label="Plano del centro"
                                            name="plano"
                                        >
                                            {/* <Upload customRequest={dummyRequest} onChange={e => onChangeImagen(e, 'plano')} onRemove={() => onRemoveImagen('plano')}  accept='.jpg, .jpeg, .png' multiple={false}>
                                                {this.state.filePlano === false ? (
                                                    <Button type="dashed" className='btnCargaArchivos'>
                                                        <UploadOutlined /> Click para subir plano
                                                    </Button>
                                                ) : false}
                                            </Upload> */}

                                            <Dragger fileList={this.state.imageListaPlano} customRequest={dummyRequest} onChange={e => onChangeImagen(e, 'plano')} onRemove={() => onRemoveImagen('plano')} accept='.jpg, .jpeg, .png, .svg' multiple={false} style={{borderRadius:'1em'}}>
                                                <Row justify='center'>
                                                    <FileImageFilled className='iconTabPane'/>
                                                    <Col span={24} style={{paddingTop:'1em'}}>
                                                        <Paragraph>
                                                            <Text strong> Click o arrastra a ésta área el achivo: jpg, png, jpeg </Text>
                                                        </Paragraph>
                                                    </Col>
                                                </Row>
                                            </Dragger>

                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row justify='center'>
                                    <Col span={11} className='marginBoton'>
                                        <br />
                                        <Button loading={fetchingListaInfoProyecto} disabled={!permisosUsuario.editarProyecto || !permisosUsuario.editarProyectoMod2} className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Guardar Cambios</Button>
                                    </Col>
                                    <Row justify='space-around'>
                                        <Col span={11}>
                                            <Divider orientation="left">Imagen actual</Divider>
                                            <Col span={18} offset={2}>
                                                {listaInfoProyecto.logo ? (
                                                    <img onError={this.addDefaultSrc} src={listaInfoProyecto.logo} className='fotoServicio' />
                                                ) : (
                                                    <p>No cuenta con una imagen al momento</p>
                                                )}
                                            </Col>
                                        </Col>
                                        <Col span={11}>
                                            <Divider orientation="left">Plano actual</Divider>
                                            <Col span={18} offset={2}>
                                                {listaInfoProyecto.rutaImagenCentro ? (
                                                    <img onError={this.addDefaultSrc} src={listaInfoProyecto.rutaImagenCentro} className='fotoServicio' />
                                                ) : (
                                                    <p>No cuenta con un plano al momento</p>
                                                )}
                                            </Col>
                                        </Col>
                                    </Row>
                                </Row>
                            </Form>
                    </Spin>
                    ) : 
                        <Spin spinning={fetchingListaInfoProyecto || fetchingEditarListaInfoProyecto} >
                            <NuevoInfoProyecto />
                        </Spin>
                    }
                </Card>
                }
                <ModalEditarExpediente/>
                <ModalArchivoExpediente/>
                <ModalEditarContrato/>
                <ModalArchivoContrato/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaInfoProyecto: state.InformacionDelProyectoReducer.fetchingListaInfoProyecto,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        listaContratos: state.InformacionOperativaReducer.listaContratos,
        fetchingListaContratos: state.InformacionOperativaReducer.fetchingListaContratos,
        listaVerificarPARv: state.MecanismosDePagosReducer.listaVerificarPARv,
        fetchingEditarListaInfoProyecto: state.InformacionDelProyectoReducer.fetchingEditarListaInfoProyecto
    };
};
 
const mapDispatchToProps = dispatch => {
    return {
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        },
        onEditarInfoProyecto: (formulario, listaInfoProyecto, listaContratos) => {
            dispatch({ type: 'EDITAR_INFOPROYECTO_REQUEST', formulario: formulario, listaInfoProyecto: listaInfoProyecto , listaContratos: listaContratos});
        },
        onGetExpedientesActivos: () => {
            dispatch({ type: "LISTA_EXPEDIENTES_REQUEST"})
        },
        onModalEditarExpediente: (expedienteSeleccionado, page, tipoEdicion) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_EXPEDIENTE", showModalEditarExpediente: true, expedienteSeleccionado, page, tipoEdicion });
        },
        onModalArchivoExpediente: (expedienteSeleccionado, page) => {
            dispatch({ type: 'SHOW_MODAL_ARCHIVOS_EXPEDIENTE', showArchivosExpediente: true, expedienteSeleccionado: expedienteSeleccionado, page: page });
        },
        onArchivosExpediente: (nombreCarpeta) => {
            dispatch({ type: 'ARCHIVOS_EXPEDIENTE_REQUEST', nombreCarpeta: nombreCarpeta });
        },
        onModalEditarContrato: (contratoSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_CONTRATO", showModalEditarContrato: true, contratoSeleccionado: contratoSeleccionado, page: page});
        },
        onModalArchivoContrato: (contratoSeleccionado, page) => {
            dispatch({ type: 'SHOW_MODAL_ARCHIVOS_CONTRATO', showArchivosContrato: true, contratoSeleccionado: contratoSeleccionado, page: page})
        },
        verificarRequest: () => {
            dispatch({ type: 'VERIFICAR_PARv_REQUEST' })
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(InfoProyectoContainer);