import React, { Component } from 'react';
import { Form, Input, InputNumber, Button, Row, Col, Spin, Modal, Result, Tooltip, Switch, Select, Divider } from 'antd';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import { SearchOutlined, FieldNumberOutlined } from '@ant-design/icons';
import ModalBusquedaTicketReiteracion from './ModalBusquedaTicketReiteracion';
import ModalBusquedaTicketOrigen from './ModalBusquedaTicketOrigen';
import ModalBusquedaCodigoInterno from './ModalBusquedaCodigoInterno';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalNuevoNumeroDocumento extends Component {
     formRef = React.createRef();
     
     state={
          codigoInternoBtn: false, doSomethingOnce: false,
     }

     render() {
          const { 
               showModalNuevoNumeroDocumento, fetchingCrearDocumentoReiteracion,
               onCloseModalNuevoNumeroDocumento, limpiarFormulario, onShowModalBusquedaTicketReiterado, onShowModalBusquedaTicketOrigen, onShowModalBusquedaCodigoInterno, codigoInterno, ticketReiteradoSeleccionado, idSolicitudServicioReiterada, onCleanActivosByCodigoInterno, onCleanTicketsOrigen, onCleanTicketsReiterados, ticketOrigenSeleccionadoMODAL, documentoRelacionado, idSolicitudServicioOrigen, fetchingDocumentoRelacionado, onCrear, reiteracion, onCleanElements, 
          } = this.props;
        
          const onFinish = formulario => {
               console.log('Success:', formulario);
               let numDoc = formulario.numeroDocumento;
               let idDocumentoReiteracion = null

               if(documentoRelacionado[0]){ numDoc = documentoRelacionado[0].numeroDocumento; idDocumentoReiteracion = documentoRelacionado[0].idDocumentoReiteracion; }

               onCrear(idSolicitudServicioReiterada, idSolicitudServicioOrigen, numDoc, reiteracion, codigoInterno, idDocumentoReiteracion);
               onCleanElements(); onCleanActivosByCodigoInterno(); onCleanTicketsOrigen(); onCleanTicketsReiterados();
          }
        
          if (limpiarFormulario && limpiarFormulario === 'nuevoNumeroDocumento') {
               this.formRef.current.resetFields();
               this.props.onLimpiarFormulario();
          }
            
          const handleCancel = () => {
               onCloseModalNuevoNumeroDocumento();
               this.formRef.current.resetFields();
               this.setState({ codigoInternoBtn: false });
               onCleanActivosByCodigoInterno(); onCleanTicketsOrigen(); onCleanTicketsReiterados();
               onCleanElements();
          };

          const onNumeroDocto = (e) => {
               if(e){
                    if(this.formRef.current.getFieldValue('numeroDocumento')){
                         this.setState({ codigoInternoBtn: true });
                    } else { this.setState({ codigoInternoBtn: false }); }
               } else { this.setState({ codigoInternoBtn: false }); }
          };

          const onBusquedaReiterado = () => { onShowModalBusquedaTicketReiterado(); };
          const onBusquedaOrigen = () => { 
               if(ticketReiteradoSeleccionado){
                    onShowModalBusquedaTicketOrigen();
               } else {
                    onShowModalBusquedaTicketOrigen(null);
               }
          };
          const onBusquedaCodigo = () => { onShowModalBusquedaCodigoInterno(); };

          return (
               <Modal
                    visible={showModalNuevoNumeroDocumento}
                    title='Nuevo Número de Documento de Reiteración de Fallas'
                    onCancel={handleCancel}
                    footer={false}
               >
                    { true ? (
                    <Spin spinning={fetchingCrearDocumentoReiteracion}>
                         <Form {...layout}
                         name="formulario"
                         onFinish={onFinish}
                         ref={this.formRef}
                         // initialValues={{ numReiteracion: reiteracion }}
                         >
                              <Row justify='space-around'>
                                   <Col span={22}>
                                        <Form.Item
                                             label="Reiteración"
                                             name="reiteracion"
                                             rules={[{ required: !this.props.idSolicitudServicioReiterada, message: 'Favor de llenar el campo', }]}
                                        >
                                             { !this.props.idSolicitudServicioReiterada ? (
                                                  <Input placeholder="Selecciona un ticket" disabled />
                                             ):(
                                                  <Select defaultValue={ticketReiteradoSeleccionado.folio} disabled />
                                             ) }
                                        </Form.Item>
                                   </Col>

                                   <Col span={1}>
                                        <br/> <br/>
                                        <Tooltip title='Búsqueda de ticket reiterado' >
                                             <Button className="btnFiltrar" shape="circle" icon={ <SearchOutlined />} onClick={onBusquedaReiterado} />
                                        </Tooltip>
                                   </Col>
                                   
                                   <Col span={22}>
                                        <Form.Item
                                             label="Ticket origen"
                                             name="ticketOrigen"
                                             rules={[{ required: !this.props.idSolicitudServicioOrigen, message: 'Favor de llenar el campo', }]}
                                        >
                                             { !this.props.idSolicitudServicioOrigen ? (
                                                  <Input placeholder="Selecciona un ticket" disabled />
                                             ):(<>
                                                  { this.props.ticketOrigenSeleccionadoMODAL ? (
                                                  <Select defaultValue={ticketOrigenSeleccionadoMODAL.folio} disabled />
                                                  ):false}
                                             </>) }
                                        </Form.Item>
                                   </Col>

                                   <Col span={1}>
                                        <br/> <br/>
                                        <Tooltip title='Búsqueda de ticket origen' >
                                             <Button className="btnFiltrar" shape="circle" icon={ <SearchOutlined />} onClick={onBusquedaOrigen} />
                                        </Tooltip>
                                   </Col>

                                   { documentoRelacionado[0] || idSolicitudServicioOrigen ? (<Spin spinning={fetchingDocumentoRelacionado}>
                                        <Row justify='space-around'>
                                             { reiteracion === 1 ? (<>
                                                  { !codigoInterno ? (
                                                       <Col span={16}>
                                                            <Form.Item
                                                                 label="Número de documento"
                                                                 name="numeroDocumento"
                                                                 rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                                            >
                                                                 <Input placeholder="Ingresa el número de documento" onChange={e => onNumeroDocto(e)}/>
                                                            </Form.Item>
                                                       </Col>
                                                  ):(
                                                       <Col span={16}>
                                                            <Form.Item
                                                                 label="Número de documento"
                                                                 name="numeroDocumento"
                                                                 rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                                            >
                                                                 <Input placeholder="Ingresa el número de documento" disabled/>
                                                            </Form.Item>
                                                       </Col>
                                                  ) }
                                             </>):(<>
                                                  { documentoRelacionado[0] ? (
                                                  <Col span={16}>
                                                       <Form.Item
                                                            label="Número de documento"
                                                            name="numeroDocumento"
                                                            rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                                       >
                                                            <Select defaultValue={documentoRelacionado[0].numeroDocumento} disabled/>
                                                       </Form.Item>
                                                  </Col>
                                                  ):false }
                                             </>) }
                                             
                                             { reiteracion >= 0 ? (
                                             <Col span={7}>
                                                  <Form.Item
                                                       label="No. Reiteración"
                                                       name="numReiteracion"
                                                       rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                                  >
                                                       <Select defaultValue={reiteracion} disabled style={{textAlign:'right'}} />
                                                  </Form.Item>
                                             </Col>
                                             ):false }
                                        </Row>
                                        
                                        { documentoRelacionado[0] ? false :(<Row justify='space-around'>
                                             <Col span={22}>
                                                  <Form.Item
                                                  label="Código interno"
                                                  name="codigoInterno"
                                                  rules={[{ required: !this.state.codigoInternoBtn && !this.props.codigoInterno, message: 'Favor de llenar el campo', }]}
                                                  >
                                                       { !this.props.codigoInterno ? (
                                                            <Input placeholder="Selecciona un código interno"  disabled />
                                                       ):(
                                                            <Select  defaultValue={codigoInterno} disabled />
                                                       ) }
                                                  </Form.Item>
                                             </Col>

                                             <Col span={1}>
                                                  <br/> <br/>
                                                  <Tooltip title='Búsqueda de código interno' >
                                                       
                                                            <Button className="btnFiltrar" shape="circle" icon={ <SearchOutlined />} disabled={this.state.codigoInternoBtn} onClick={onBusquedaCodigo} />
                                                       
                                                  </Tooltip>
                                             </Col>
                                        </Row>)}
                                        
                                   </Spin>) : false }

                                   
                                   
                                   <Divider />

                                   <Col span={24}>
                                        <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Crear Nuevo Número de Documento</Button>
                                   </Col>
                              </Row>
                              <ModalBusquedaTicketReiteracion />
                              <ModalBusquedaTicketOrigen />
                              <ModalBusquedaCodigoInterno />
                         </Form>
                    </Spin>
                    ):
                         <Result
                         status="500"
                         title="Algo salió mal"
                         subTitle="Lo siento, vuelve a intentarlo más tarde."
                         />
                    }
               </Modal>
          );
     }
}

const mapStateToProps = state => {
	return {
          showModalNuevoNumeroDocumento: state.MecanismosDePagosReducer.showModalNuevoNumeroDocumento,
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          codigoInterno: state.MecanismosDePagosReducer.codigoInterno,
          ticketReiteradoSeleccionado: state.MecanismosDePagosReducer.ticketReiteradoSeleccionado,
          idSolicitudServicioReiterada: state.MecanismosDePagosReducer.idSolicitudServicioReiterada,
          ticketOrigenSeleccionadoMODAL: state.MecanismosDePagosReducer.ticketOrigenSeleccionadoMODAL,
          idSolicitudServicioOrigen: state.MecanismosDePagosReducer.idSolicitudServicioOrigen,
          documentoRelacionado: state.MecanismosDePagosReducer.documentoRelacionado,
          fetchingDocumentoRelacionado: state.MecanismosDePagosReducer.fetchingDocumentoRelacionado,
          reiteracion: state.MecanismosDePagosReducer.reiteracion,
          ticketOrigenSeleccionado: state.MecanismosDePagosReducer.ticketOrigenSeleccionado,
          fetchingCrearDocumentoReiteracion: state.MecanismosDePagosReducer.fetchingCrearDocumentoReiteracion,
	};
};

const mapDispatchToProps = dispatch => {
	return {
          onCloseModalNuevoNumeroDocumento: () => {
               dispatch({ type: 'SHOW_MODAL_NUEVO_NO_DOCUMENTO', showModalNuevoNumeroDocumento: false})
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
          onShowModalBusquedaTicketReiterado: () => {
               dispatch({ type: 'SHOW_MODAL_BUSQUEDA_TICKET_REITERADO', showModalBusquedaTicketReiterado: true })
          },
          onShowModalBusquedaTicketOrigen: () => {
               dispatch({ type: 'SHOW_MODAL_BUSQUEDA_TICKET_ORIGEN', showModalBusquedaTicketOrigen: true})
          },
          onShowModalBusquedaCodigoInterno: () => {
               dispatch({ type: 'SHOW_MODAL_BUSQUEDA_CODIGO_INTERNO', showModalBusquedaCodigoInterno: true });
          },
          onCleanTicketsOrigen: () => {
               dispatch({ type: 'LISTA_TICKETS_ORIGEN_SUCCESS', listaTicketsOrigen: [] });
          },
          onCleanTicketsReiterados: () => {
               dispatch({ type: 'LISTA_TICKETS_REITERADOS_SUCCESS', listaTicketsReiterados: [] });
          },
          onCleanActivosByCodigoInterno: () => {
               dispatch({ type: 'LISTA_ACTIVOS_BY_CODIGO_INTERNO_SUCCESS', listaActivosByCodigoInterno: [] });
          },
          onCrear: (idSolicitudServicioReiterada, idSolicitudServicioOrigen, numeroDocumento, reiteracion, codigoInterno, idDocumentoReiteracion) => {
               dispatch({ type: 'CREAR_DOCUMENTO_REITERACION_REQUEST', idSolicitudServicioReiterada, idSolicitudServicioOrigen, numeroDocumento, reiteracion, codigoInterno, idDocumentoReiteracion });
          },
          onCleanElements: () => {
               dispatch({ type: 'CLEAN_ELEMENTS_DOCUMENTO_REITERACION', idSolicitudServicioReiterada: null, idSolicitudServicioOrigen: null, reiteracion: null, codigoInterno: null, ticketOrigenSeleccionadoMODAL: [], ticketOrigenSeleccionado: [], ticketReiteradoSeleccionado: [], documentoRelacionado: [] });
          },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoNumeroDocumento);