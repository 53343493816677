import React, { Component } from 'react';
import { Form, Button, Row, Col, Spin, Select, Input, InputNumber, Divider, DatePicker } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
const { TextArea } = Input;

const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class EditarPeriodo extends Component {
    formRef = React.createRef();
    
    state={
        tipoEdicion: null,
        disabledSelect: false,
    }
    render() {
        
        const {
            fetchingEditarPeriodoFMD,  onLimpiarFormulario,  limpiarFormulario,
            onCambiarVista, periodoSeleccionado, onEditarPeriodo, page, añosMaximoPeriodo,
            obGetPeriodosFormulario,
        } = this.props;

        if(limpiarFormulario && limpiarFormulario === 'editarPeriodoFMD'){
            console.log('entró a limpiar');
            this.formRef.current.resetFields();
            onLimpiarFormulario();
            onCambiarVista(1);
        }

        const onFinish = formulario => {
            console.log('formulario :>> ', formulario);
            
            if (this.state.tipoEdicion === 'simple') {
                onEditarPeriodo(formulario, page, periodoSeleccionado);
            } else {
                //this.formRef.current.resetFields([])
                onCambiarVista(2, formulario);
                obGetPeriodosFormulario(formulario, periodoSeleccionado)
                console.log('Pedir contraseña ');
            }
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        }

        const onSelectTipoEdicion = (value) => {
            this.setState({
                tipoEdicion: value,
                disabledSelect: true,
            })
        }

        return ( 
            <div>
                {periodoSeleccionado ? (

                    <Spin spinning={fetchingEditarPeriodoFMD} >

                        <Form {...layout}
                            name="formulario"
                            initialValues={periodoSeleccionado}
                            onFinish={onFinish}
                            
                            ref={this.formRef}
                        >

                            <Row justify='space-around'>
                                <Col span={11}>
                                    <p>Selecciona el tipo de edición</p>
                                </Col>
                                <Col span={11}>
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el tipo de edición"
                                        onChange={onSelectTipoEdicion}
                                        disabled={this.state.disabledSelect}
                                    >
                                        <Option key='simple'>Edición simple</Option>
                                        <Option key='completa'>Edición por convenio</Option>
                                    </Select>
                                </Col>
                            </Row>

                            <Divider/>
                            {this.state.tipoEdicion ? (
                            <Row justify='space-around'>
                                <Col span={24}>
                                    <Form.Item
                                        label="Periodo Años"
                                        name="periodoAnios"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <TextArea
                                            autoSize
                                            pattern="^^[ñíóáéú a-zA-Z 0-9]+$"
                                            title="Nombre debe tener letras y numeros."
                                            maxLength={100} placeholder='Ingrese Periodo Años' />
                                    </Form.Item>
                                </Col> 

                                <Col span={24}>
                                    <Form.Item
                                        label="Periodo Mes"
                                        name="periodoMes"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <TextArea
                                            autoSize
                                            pattern="^^[ñíóáéú a-zA-Z 0-9]+$"
                                            title="Nombre debe tener letras y numeros."
                                            maxLength={100} placeholder='Ingrese Periodo periodoMes' />
                                    </Form.Item>
                                </Col> 
                        
                                <Col span={24}>
                                    <Form.Item
                                        label="Cantidad de Años"
                                        name="cantidadAnios"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <InputNumber min={1} max={añosMaximoPeriodo + periodoSeleccionado.cantidadAnios } placeholder="Ingrese la cantidad de años" />
                                    </Form.Item>
                                </Col> 

                                <Col span={24}>
                                    <Form.Item
                                        label="Fracción Maxima de Descuento"
                                        name="fraccionMaxDescuento"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <InputNumber  min={1} max={100} placeholder="Ingrese la fraccion maxima de descuento"/>
                                    </Form.Item>
                                </Col> 


                                <Col span={24}>
                                    <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit"> Editar Periodo</Button>
                                </Col>
                            </Row>
                            ): false}

                        </Form>
                    </Spin>
                ): false}
            </div>
         );
    }
}

const mapStateToProps = state => {
    return {
        fetchingEditarPeriodoFMD: state.MecanismosDePagosReducer.fetchingEditarPeriodoFMD,
        periodoSeleccionado: state.MecanismosDePagosReducer.periodoSeleccionado,
        page: state.MecanismosDePagosReducer.page,
        cambiarVistaModalPeriodoFMD: state.MecanismosDePagosReducer.cambiarVistaModalPeriodoFMD,
        añosMaximoPeriodo: state.MecanismosDePagosReducer.añosMaximoPeriodo,

    }
}
 
const mapDispatchToProps = dispatch => {
    return {
        onEditarPeriodo: (formulario, page, periodoSeleccionado ) => {
            dispatch({ type: "EDITAR_PERIODO_FMD_REQUEST",formulario, page, periodoSeleccionado });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onCambiarVista: (cambiarVistaModalPeriodoFMD, formularioPeriodo ) => {
            dispatch({ type: 'CAMBIAR_VISTA_PERIODO_FMD', cambiarVistaModalPeriodoFMD, formularioPeriodo })
        },
        obGetPeriodosFormulario: (formulario, periodoSeleccionado) => {
            dispatch({ type: 'LISTA_PERIODOS_FORMULARIO_EDITAR_REQUEST', formulario, periodoSeleccionado})
        },
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (EditarPeriodo);