import React from 'react';
import {connect} from 'react-redux';
import {Table, Row, Col, Modal, Descriptions, Divider} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

class ModalDetalleActividades extends React.Component {
    formRef = React.createRef();


    render () {

        const { showModalDetalleEstatusOrdenesSPActividad,estatusOrdenSPSeleccionado,onShowModalDetalle} = this.props;

        const columns = 
        [{
            title: 'Descripción',
            dataIndex: 'descripcion',
            key: 'descripcion',
            // width: '40%',
        },{
            title: 'Unidad de medida',
            dataIndex: 'unidadMedida',
            key: 'unidadMedida',
            // width: '40%',
        },{
            title: 'Cantidad',
            dataIndex: 'cantidad',
            key: 'cantidad',
            // width: '40%',
        }]

        const handleCancel = () =>{
            onShowModalDetalle();
        }

        console.log(`estatusOrdenSPSeleccionado`, estatusOrdenSPSeleccionado)
        return (
            <div>
                {estatusOrdenSPSeleccionado ? (
                    <Modal
                        visible={showModalDetalleEstatusOrdenesSPActividad}
                        title={`Detalle de Actividad de la Orden: '${estatusOrdenSPSeleccionado.folioOrden}'`}
                        onCancel={handleCancel}
                        footer={false}
                        width='60%'
                    >
                        <Row type='flex' justify='space-around'>
                            <Col span={24}>
                                <Descriptions layout="horizontal" bordered={true}>
                                    <Descriptions.Item label='Actividades'>{estatusOrdenSPSeleccionado.actividades}</Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Divider orientation='left'>Insumos</Divider>
                                <Table
                                    size='small'
                                    rowKey='descripcion'
                                    columns={columns}
                                    dataSource={estatusOrdenSPSeleccionado.insumosByOrden ? estatusOrdenSPSeleccionado.insumosByOrden : []}
                                    locale={{ emptyText: 'Sin datos' }}
                                    pagination={{ total: estatusOrdenSPSeleccionado.insumosByOrden ? estatusOrdenSPSeleccionado.insumosByOrden.length : 0, simple: true, hideOnSinglePage: true }}
                                />     
                            </Col>
                            <Col span={11}>
                                <Divider orientation='left'>Herramientas</Divider>
                                <Table
                                    size='small'
                                    rowKey='descripcion'
                                    columns={columns}
                                    dataSource={estatusOrdenSPSeleccionado.herramientasByOrden ? estatusOrdenSPSeleccionado.herramientasByOrden : []}
                                    locale={{ emptyText: 'Sin datos' }}
                                    pagination={{ total: estatusOrdenSPSeleccionado.herramientasByOrden ? estatusOrdenSPSeleccionado.herramientasByOrden.length : 0, simple: true, hideOnSinglePage: true }}
                                />     
                            </Col>
                        </Row>
                    </Modal>
                ) : false}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalDetalleEstatusOrdenesSPActividad: state.ServiciosPlanificadosReducer.showModalDetalleEstatusOrdenesSPActividad,
        estatusOrdenSPSeleccionado: state.ServiciosPlanificadosReducer.estatusOrdenSPSeleccionado,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalDetalle: () => {
            dispatch({ type: 'SHOW_MODAL_DETALLE_ESTATUS_ORDEN_SP_ACTIVIDAD', showModalDetalleEstatusOrdenesSPActividad: false});
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalDetalleActividades);