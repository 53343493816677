import React, { Component, useContext, useEffect, useRef, useState } from 'react';
import { Row, Col, Table, Input, Spin, Divider, Tooltip, Button, Form, InputNumber, message } from 'antd';
import { connect } from "react-redux";
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { ShoppingCartOutlined, ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
const EditableContext = React.createContext(null);
const { Search } = Input;

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        handleSave,
        listaSeleccionados,
        ...restProps
    }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);
  
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };
  
    let seleccionado = false;
    
    if(listaSeleccionados?.length){
        for(let i in listaSeleccionados){
            if(record.idInventario === listaSeleccionados[i].idInventario){
                seleccionado = true;
            }
        }
    }
    let maximo = 0;
    if(record){
        if(record.cantidadSurtida === 0){
            if(record.cantidad > record.cantidadSolicitada){
                maximo = record.cantidadSolicitada;
            }
            else{
                maximo = record.cantidad;
            }
        }
        else{
            let restante = record.cantidadSolicitada - record.cantidadSurtida;
            if(record.cantidad > restante){
                maximo = restante;
            }
            else{
                maximo = record.cantidad;
            }
        }
    }

    const save = async () => {
        try {
            const values = await form.validateFields();
            if(maximo < values.cantidadSurtir){
                values.cantidadSurtir = maximo;
            }
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };
    const verificarMaximo = async () => {
        try {
            const values = await form.validateFields();
            if(maximo < values.cantidadSurtir){
                message.warning('No es posible superar la cantidad solicitada o del inventario');
            }
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    }
  
    let childNode = children;
    
    if (editable && record?.cantidadSurtida != record?.cantidadSolicitada && record?.tipoFamilia != 'Activos' && seleccionado) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                    textAlign: 'right',
                }}
                name={dataIndex}
                rules={[
                    {
                    required: true,
                    message: `${title} es obligatoria.`,
                    },
                ]}
            >
                <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} min={0} onChange={verificarMaximo} max={maximo} />
            </Form.Item>
        ) : (
        <div
            className="editable-cell-value-wrap"
            style={{
                paddingRight: 24,
            }}
            onClick={toggleEdit}
        >
            {children}
        </div>
        );
    }
  
    return <td {...restProps}>{childNode}</td>;
};

  
class SalidaPorRequisicion extends Component {
    state = {
        pageFolios: 1,
        busquedaFolios: {
            busqueda: '',
            estado: '',
            idEncargado: '',
        },
        ordenFolios: {
            field: 'idRequisicionMateriales',
            order: 'DESC',
        },
        step: 1,
        selectedRows: [],
        selectedRowKeys: [],
        desactivarGenerar: true,
    }
    render() {
        const { fetchingListaFoliosRequisicionMateriales, listaFoliosRequisicionMaterialesCantidad, limpiarFormulario,
            listaFoliosRequisicionMateriales, onGetListaFoliosRequisicionMateriales, formularioNuevoFolio,
            fetchingProductosFolioRequisicion, listaProductosFolioRequisicion, fetchingSurtirFolioRequisicion,
            onActualizarCantidad,
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        };

        let userInfo = sessionStorage.getItem('userInfo');
        if(!userInfo.idUsuario){
            userInfo = JSON.parse(userInfo);
        }

        if (limpiarFormulario && limpiarFormulario === 'surtirRequisicion') {
            this.setState({ step: 1 });
            this.props.onCambiarVista(1);
            this.props.onLimpiarFormulario();
        }
        const vistaAnterior = (step) => {
            if(step === 1){
                this.props.onCambiarVista(1);
            }
            else{
                this.setState({step: 1});
            }
        }

        const onSearchFolio = (value) => {
            const busquedaFolios = {
                'busqueda': value,
                'estado': this.state.busquedaFolios.estado,
                'idEncargado': this.state.busquedaFolios.idEncargado,
            }
            onGetListaFoliosRequisicionMateriales(this.state.pageFolios, busquedaFolios, this.state.ordenFolios, userInfo[0].idUsuario);
            this.setState({ busquedaFolios });
        };

        const handleTableChangeAprobar = (pagination, filtro, orden) => {
            this.setState({
                pageFolios: pagination.current,
            });
            if(orden){
                let field = orden.columnKey;
                let order = 'ASC';
            
                if(orden.order === 'descend'){
                    order = 'DESC';
                }

                if(orden.columnKey === 'folio'){
                    field = 'idRequisicionMateriales';
                }

                if(!orden.order){
                    field = 'idRequisicionMateriales';
                    order = 'DESC';
                }

                onGetListaFoliosRequisicionMateriales(this.state.pageFolios, this.state.busquedaFolios, {'order': order, 'field': field}, userInfo[0].idUsuario);
                this.setState({
                    ordenFolios: {'order': order, 'field': field,},
                });
            }
            else{
                onGetListaFoliosRequisicionMateriales(pagination.current, this.state.busquedaFolios, this.state.ordenFolios, userInfo[0].idUsuario);
            }
        }
        const onSurtirFolio = (value) => {
            console.log('value', value, formularioNuevoFolio)
            this.setState({
                step: 2,
            })
            this.props.productosFolioRequisicion(value.folio, formularioNuevoFolio.idAlmacenOrigen.split('-')[0]);
        }

        const columnsFolios = [{
            title: 'Número de Folio',
            dataIndex: 'folio',
            key: 'folio',
            sorter: true
        }, {
            title: 'Fecha de Alta',
            dataIndex: 'fechaAlta',
            key: 'fechaAlta',
            sorter: true
        }, {
            title: 'Solicitante',
            dataIndex: 'idUsuarioSolicitante',
            key: 'idUsuarioSolicitante',
            sorter: true
        }, {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            sorter: true
        },{
            title: 'Surtir',
            key: '',
            align: 'center',
            render: (record) => (
                <a className='editarIcono' onClick={()=>onSurtirFolio(record)} disabled={!permisosUsuario.descargarPdfDelFolioDeVerificación}> <ShoppingCartOutlined /></a>
            ),
        }];
          
        const defaultColumns = [{
            title: 'Código de almacén',
            dataIndex: 'codigoAdicional',
            key: 'codigoAdicional',
            align: 'center',
        }, {
            title: 'Nombre del producto',
            dataIndex: 'nombre',
            key: 'nombre',
            align: 'center',
        }, {
            title: 'Clave del producto',
            dataIndex: 'clave',
            key: 'clave',
            align: 'center',
        }, {
            title: 'Número de serie',
            dataIndex: 'serieNum',
            key: 'serieNum',
            align: 'center',
        },{
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
            align: 'center',
        },{
            title: 'Existencias',
            dataIndex: 'cantidad',
            key: 'cantidad',
            align: 'right',
        },{
            title: 'Cantidad a surtir',
            dataIndex: 'cantidadSurtir',
            key: 'cantidadSurtir',
            align: 'right',
            editable: true,
        },];

        const columnsDetalle = [{
            title: 'Código de almacén',
            dataIndex: 'codigoAdicional',
            key: 'codigoAdicional',
            align: 'center',
        }, {
            title: 'Nombre del producto',
            dataIndex: 'nombre',
            key: 'nombre',
            align: 'center',
        }, {
            title: 'Por surtir',
            dataIndex: 'cantidadSurtir',
            key: 'cantidadSurtir',
            align: 'right',
            editable: true,
        },{
            title: 'Surtido',
            dataIndex: 'cantidadSurtida',
            key: 'cantidadSurtida',
            align: 'right',
        },{
            title: 'Solicitado',
            dataIndex: 'cantidadSolicitada',
            key: 'cantidadSolicitada',
            align: 'right',
        }];

        const columns = defaultColumns.map((col) => {
            if (!col.editable) {
                return col;
            }
        
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave,
                    listaSeleccionados: this.state.selectedRows,
                }),
            };
        });

        const components = {
            body: {
                row: EditableRow,
                cell: EditableCell,
            },
        };
        
        const handleSave = (row) => {
            let desactivarGenerarLocal = true;
            let listaProductosLocal = listaProductosFolioRequisicion;
            onActualizarCantidad([]);
            for(let i in listaProductosLocal.productosByIdRequisicion){
                if(row.idProducto === listaProductosLocal.productosByIdRequisicion[i].idProducto){
                    listaProductosLocal.productosByIdRequisicion[i].cantidadSurtir = row.cantidadSurtir;
                    listaProductosLocal.productosByIdRequisicion[i].stockByIdProducto[0].cantidadSurtir = row.cantidadSurtir;
                }
                if(listaProductosLocal.productosByIdRequisicion[i].cantidadSurtir !== 0){
                    desactivarGenerarLocal = false;
                }
            }
            onActualizarCantidad(listaProductosLocal);
            this.setState({
                desactivarGenerar: desactivarGenerarLocal
            })
        };
        
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                let listaProductosLocal = listaProductosFolioRequisicion;
                let selectedRowsLocal = selectedRows;
                let selectedRowKeysLocal = selectedRowKeys;
                let supera = [];
                let desactivarGenerarLocal = true;
                let listaIncludes = []

                onActualizarCantidad([]);
                if(selectedRowKeys?.length){
                    for(let i in selectedRows){ 
                        for(let x in listaProductosLocal.productosByIdRequisicion){
                            if(listaProductosLocal.productosByIdRequisicion[x].idProducto === selectedRows[i].idProducto){
                                if(listaProductosLocal.productosByIdRequisicion[x].productoByIdProducto.subFamiliaByIdSubFamilia.familiaByIdFamilia.tipoFamilia === 'Activos'){
                                    listaProductosLocal.productosByIdRequisicion[x].cantidadSurtir = 0;
                                }
                            }
                        }
                        if(!listaIncludes.includes(selectedRows[i].idProducto)){
                            listaIncludes.push(selectedRows[i].idProducto);
                        }
                    }
                    if(selectedRows.length < this.state.selectedRows.length){
                        //console.log('selectedRows.length', selectedRows.length, this.state.selectedRows)
                        for(let i in this.state.selectedRows){
                            if(!listaIncludes.includes(this.state.selectedRows[i].idProducto)){
                                for(let x in listaProductosLocal.productosByIdRequisicion){
                                    if(listaProductosLocal.productosByIdRequisicion[x].idProducto === this.state.selectedRows[i].idProducto){
                                        listaProductosLocal.productosByIdRequisicion[x].cantidadSurtir = 0;
                                        if(listaProductosLocal.productosByIdRequisicion[x].productoByIdProducto.subFamiliaByIdSubFamilia.familiaByIdFamilia.tipoFamilia != 'Activos'){
                                            for(let y in listaProductosLocal.productos){
                                                if(listaProductosLocal.productos[y].idProducto === listaProductosLocal.productosByIdRequisicion[x].idProducto){
                                                    listaProductosLocal.productos[y].cantidadSurtir = 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            
                        }
                    }
                    for(let i in selectedRows){
                        for(let x in listaProductosLocal.productosByIdRequisicion){
                            if(listaProductosLocal.productosByIdRequisicion[x].idProducto === selectedRows[i].idProducto){
                                if(listaProductosLocal.productosByIdRequisicion[x].productoByIdProducto.subFamiliaByIdSubFamilia.familiaByIdFamilia.tipoFamilia === 'Activos'){
                                    if(listaProductosLocal.productosByIdRequisicion[x].cantidadSurtir + listaProductosLocal.productosByIdRequisicion[x].cantidadSurtida < selectedRows[i].cantidadSolicitada){
                                        listaProductosLocal.productosByIdRequisicion[x].cantidadSurtir += 1;
                                    }
                                    else{
                                        supera.push(i);
                                        message.warning('No es posible superar la cantidad solicitada');
                                    }
                                }
                            }
                            if(listaProductosLocal.productosByIdRequisicion[x].cantidadSurtir !== 0){
                                desactivarGenerarLocal = false;
                            }
                        }
                    }
                }
                else{
                    for(let x in listaProductosLocal.productosByIdRequisicion){
                        listaProductosLocal.productosByIdRequisicion[x].cantidadSurtir = 0;
                    }
                }
                if(supera.length){
                    supera = supera.reverse();
                    for(let i in supera){
                        selectedRowsLocal.splice(supera[i], 1);
                        selectedRowKeysLocal.splice(supera[i], 1);
                    }
                }
                onActualizarCantidad(listaProductosLocal);
                this.setState({
                    selectedRows: selectedRowsLocal,
                    selectedRowKeys: selectedRowKeysLocal,
                    desactivarGenerar: desactivarGenerarLocal
                })
            },
            getCheckboxProps: (record) => ({
                disabled: record.cantidadSurtida === record.cantidadSolicitada,
                // Column configuration not to be checked
                name: record.name,
            }),
        };

        const onGenerarFolio = () => {
            let valido = true;
            for (let i = 0; i < this.state.selectedRows.length; i++) {
                if(!this.state.selectedRows[i].cantidadSurtir){
                    message.warning('Debe ingresar cantidad del producto con código de almacén "'+ this.state.selectedRows[i].codigoAdicional + '" antes de generar folio') 
                    i = this.state.selectedRows.length
                    valido = false;
                }    
            }
            if(valido){
                this.props.onGenerarFolioRequest(listaProductosFolioRequisicion, formularioNuevoFolio, this.state.selectedRows, userInfo, this.props.page, this.props.busqueda);
            }
        }

        return (
            <Spin spinning={fetchingProductosFolioRequisicion || fetchingSurtirFolioRequisicion || this.props.fetchingNuevoFolio}>
                <Row type='flex' justify='space-around' gutter={[8, 8]}>
                    {this.state.step === 1 ? (
                    <Col span={24}>
                        <Row justify='space-around'>
                            <Col span={24}>
                                <Search className='buscarModulos' placeholder='Ingresa folio o servicio a buscar' onSearch={value => onSearchFolio(value, 'aprobar')} />
                            </Col>
                            <Col span={22}>
                                <Divider orientation="left">Elegir folio a surtir</Divider>
                            </Col>
                            <Col span={1} style={{ paddingTop:'1em' }}>
                                <Tooltip title='Paso Anterior'>
                                    <Button className="iconTer" shape="circle" onClick={() => vistaAnterior(this.state.step)} icon={<ArrowLeftOutlined />}/>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Col>
                    ):(
                    <Col span={24}>
                        <Row justify='space-around'>
                            <Col span={21}>
                                <Divider orientation="left">Generar Folio</Divider>
                            </Col>
                            <Col span={1} style={{ paddingTop:'1em' }}>
                                <Tooltip title='Paso Anterior'>
                                    <Button className="iconTer" shape="circle" onClick={() => vistaAnterior(this.state.step)} icon={<ArrowLeftOutlined />}/>
                                </Tooltip>
                            </Col>
                            <Col span={1} style={{ paddingTop:'1em' }}>
                                <Tooltip title='Generar Folio'>
                                    <Button className="iconTer" shape="circle" disabled={this.state.desactivarGenerar} onClick={onGenerarFolio} icon={<SaveOutlined />}/>
                                </Tooltip>
                            </Col>
                        </Row>
                        <Row justify='space-around'>
                            <Col span={24}>
                                <Table
                                    size="small"
                                    rowKey="idProducto"
                                    columns={columnsDetalle}
                                    dataSource={listaProductosFolioRequisicion?.productosAprobados}
                                    locale={{ emptyText: 'Sin datos' }}
                                    pagination={{ simple: true, hideOnSinglePage: true }}
                                    scroll={{"x": 1000 | true, "y": 1000}}
                                />
                            </Col>
                        </Row>
                    </Col>
                    )}
                    
                    {this.state.step === 1 ? (
                    <Col span={24}>
                        <br />
                        <Table
                            size="small"
                            rowKey="idRequisicionMateriales"
                            columns={columnsFolios}
                            dataSource={listaFoliosRequisicionMateriales}
                            loading={fetchingListaFoliosRequisicionMateriales}
                            locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar' }}
                            onChange={handleTableChangeAprobar}
                            pagination={{ total: listaFoliosRequisicionMaterialesCantidad, simple: true, current: this.state.pageFolios }}
                        />
                    </Col>
                    ):(
                    <Col span={24}>
                        <Divider orientation="left">Surtir productos</Divider>
                        <Table
                            size="small"
                            rowKey="idInventario"
                            columns={columns}
                            components={components}
                            dataSource={listaProductosFolioRequisicion?.productos}
                            locale={{ emptyText: 'Sin datos' }}
                            pagination={{ simple: true, hideOnSinglePage: true }}
                            scroll={{"x": 1000 | true, "y": 1000}}
                            rowSelection={rowSelection}
                        />
                    </Col>
                    )}
                </Row>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaFoliosRequisicionMateriales: state.AlmacenesReducer.fetchingListaFoliosRequisicionMateriales,
        listaFoliosRequisicionMateriales: state.AlmacenesReducer.listaFoliosRequisicionMateriales,
        listaFoliosRequisicionMaterialesCantidad: state.AlmacenesReducer.listaFoliosRequisicionMaterialesCantidad,
        formularioNuevoFolio: state.AlmacenesReducer.formularioNuevoFolio,
        fetchingProductosFolioRequisicion: state.AlmacenesReducer.fetchingProductosFolioRequisicion,
        listaProductosFolioRequisicion: state.AlmacenesReducer.listaProductosFolioRequisicion,
        fetchingSurtirFolioRequisicion: state.AlmacenesReducer.fetchingSurtirFolioRequisicion,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        page: state.AlmacenesReducer.page,
        busqueda: state.AlmacenesReducer.busqueda,
        fetchingNuevoFolio: state.AlmacenesReducer.fetchingNuevoFolio,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onGetListaFoliosRequisicionMateriales: (page, busqueda, orden, idUsuario) => {
            dispatch({ type: "LISTA_FOLIOS_REQUISICION_MATERIALES_REQUEST", page, busqueda, orden, idUsuario });
        },
        productosFolioRequisicion: (folio, idAlmacen) => {
            dispatch({ type: "PRODUCTOS_FOLIO_REQUISICION_REQUEST", folio, idAlmacen });
        },
        onCambiarVista: (vistaModalNuevoFolio, listaSeleccionados, formularioNuevoFolio, tipoActivos) => {
            dispatch({ type: "CAMBIAR_VISTA_MODAL_NUEVOFOLIO", vistaModalNuevoFolio, listaSeleccionados, formularioNuevoFolio, tipoActivos});
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        },
        onCambiarVista: (vistaModalNuevoFolio) => {
            dispatch({ type: "CAMBIAR_VISTA_MODAL_NUEVOFOLIO", vistaModalNuevoFolio });
        },
        onActualizarCantidad: (listaProductosFolioRequisicion) => {
            dispatch({ type: 'PRODUCTOS_FOLIO_REQUISICION_SUCCESS', listaProductosFolioRequisicion })
        },
        onGenerarFolioRequest: (listaProductosFolioRequisicion, formularioNuevoFolio, listaSeleccionados, userInfo, page, busqueda) => {
            dispatch({ type: "SURTIR_PRODUCTOS_FOLIO_REQUISICION_REQUEST", listaProductosFolioRequisicion, formularioNuevoFolio, listaSeleccionados, userInfo, page, busqueda});
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalidaPorRequisicion);
