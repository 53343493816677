import React, { useRef, useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {Form, Row, Col, Button, Modal, Spin, Input, Divider, Checkbox, Select, DatePicker, Switch, message, Tooltip, TimePicker, Radio} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const ModalEditarRegistroSP = props => {
     const formRef = useRef();
    
     const {showModalEditarRegistro, fetchingEditarRegistro, page, onActualizarRegistro,
          onShowModalRegistroReporte, onEditarRegistroReporte, registroReporteSeleccionado, personal,
          onGetUnidades, fetchingListaClasificacionesActivos, listaClasificacionesActivos, onListaServiciosByIdsGerencias,
          fetchingListaGerenciasByIdUsuario, listaGerenciasByIdUsuario, fetchingListaGruposServicios, listaGruposServicios,
          onListaServiciosByIdsGrupos, listaGrupoServiciosByIdUsuario, fetchingListaGrupoServiciosByIdUsuario,
          listaServiciosByIdsGrupos, fetchingListaServiciosByIdsGrupos, serviciosByIdUsuarioGrupos, listaServiciosByIdsGerencias,
          fetchingListaServiciosByIdsGerencias, serviciosByIdUsuarioGerencia, fetchingListaAreasFuncionalesActivas,
          listaAreasFuncionalesActivas, fetchingListaUnidadesFuncionalesActivas, listaUnidadesFuncionalesActivas, limpiarUnidades,
          onlistaRegistrosSP, listaPartidasByIdsServicios, fetchingListaPartidasByIdsServicios, onGetPartidasByIdsServicios, onCleanPartidasByIdsServicios

     } = props;
     
     let permisosUsuario = sessionStorage.getItem('permisosUsuario');
     if(permisosUsuario[0]){
          permisosUsuario = JSON.parse(permisosUsuario);
     }

     let userInfo = sessionStorage.getItem('userInfo');
     if(userInfo[0]) {
          userInfo = JSON.parse(userInfo);
     }

     const onFinish = (formulario) => {
          console.log('formulario', formulario)
          onEditarRegistroReporte(formulario, page, personal, registroReporteSeleccionado);
          onCleanPartidasByIdsServicios();
     }

     const handleCancel = () => {
          onShowModalRegistroReporte(page, personal);
          onlistaRegistrosSP(page);
          formRef.current.resetFields();
          onCleanPartidasByIdsServicios();
     }

     const selectAll = (value) => {
          const registroReporteLocal = registroReporteSeleccionado;
          if(value.target.checked){
               onActualizarRegistro(null, page, personal)
               for(let i in registroReporteLocal.checkboxData){
                    registroReporteLocal.checkboxData[i][1] = true;
               }
               registroReporteLocal.folioOrdenC  = true;
               registroReporteLocal.gerenciaC = true;
               registroReporteLocal.grupoServiciosC  = true;
               registroReporteLocal.servicioC = true;
               registroReporteLocal.tipoProgramaC = true;
               registroReporteLocal.nombreRutinaC = true;
               registroReporteLocal.claveRutinaC = true;
               registroReporteLocal.ubicacionC = true;
               registroReporteLocal.estatusOrdenC = true;
               registroReporteLocal.nombreActivoC = true;
               registroReporteLocal.numeroSerieC = true;
               registroReporteLocal.codigoInternoC = true;
               registroReporteLocal.codigoEquipoC = true;
               registroReporteLocal.codigoActivoC = true;
               registroReporteLocal.estadoC = true;
               registroReporteLocal.checkAll = true;
               onActualizarRegistro(registroReporteLocal, page, personal)
          }
          else {
               onActualizarRegistro(null, page, personal)
               for(let i in registroReporteLocal.checkboxData){
                    registroReporteLocal.checkboxData[i][1] = false;
               }
               
               registroReporteLocal.folioOrdenC  = false;
               registroReporteLocal.gerenciaC = false;
               registroReporteLocal.grupoServiciosC  = false;
               registroReporteLocal.servicioC = false;
               registroReporteLocal.tipoProgramaC = false;
               registroReporteLocal.nombreRutinaC = false;
               registroReporteLocal.claveRutinaC = false;
               registroReporteLocal.ubicacionC = false;
               registroReporteLocal.estatusOrdenC = false;
               registroReporteLocal.nombreActivoC = false;
               registroReporteLocal.numeroSerieC = false;
               registroReporteLocal.codigoInternoC = false;
               registroReporteLocal.codigoEquipoC = false;
               registroReporteLocal.codigoActivoC = false;
               registroReporteLocal.estadoC = false;
              registroReporteLocal.checkAll = false;
              formRef.current.setFieldsValue({'field': undefined});
              onActualizarRegistro(registroReporteLocal, page, personal)
          }
     };
     const changeColumna = (value, columna) => {
          const registroReporteLocal = registroReporteSeleccionado;
          onActualizarRegistro(null, page, personal);
          let checkAll = true;
          registroReporteLocal[columna] = value.target.checked;
          for(let i in registroReporteLocal.listaFields){
               if(registroReporteLocal.listaFields[i].key === columna){
                    if(registroReporteLocal.listaFields[i].campo === registroReporteLocal.field){
                    formRef.current.setFieldsValue({'field': undefined});
                    }
                    registroReporteLocal.listaFields[i].mostrar = value.target.checked;
               }
          }
          for(let i in registroReporteLocal.checkboxData){
               if(registroReporteLocal.checkboxData[i][0] === columna){
                    registroReporteLocal.checkboxData[i][1] = value.target.checked;
               }
          }
          for(let i in registroReporteLocal.checkboxData){
               if(!registroReporteLocal.checkboxData[i][1]){
                    checkAll = false;
               }
          }
          registroReporteLocal.checkAll = checkAll;

          onActualizarRegistro(registroReporteLocal, page, personal);
     };

     const onChangeSelect = (value) => {
          formRef.current.setFieldsValue({'unidadFuncional': undefined});
          if(value){
               onGetUnidades(value.split(' - ')[1]);
          }
          else{
              limpiarUnidades();
          }
     }
     const onClasificacionSeleccionado = (value) => {
          const registroReporteLocal = registroReporteSeleccionado;
          onActualizarRegistro(null, page, personal);
          formRef.current.setFieldsValue({'idsGruposServicios': undefined});
          registroReporteLocal.idsGerencias = value;
          if(value.length > 0){
               registroReporteLocal.idsGruposServicios = undefined;
               formRef.current.setFieldsValue({'idsServicios': undefined});
               onListaServiciosByIdsGerencias(value);
          } else {
               registroReporteLocal.idsGruposServicios = [];
               formRef.current.setFieldsValue({'idsGerencias': undefined, 'idsServicios': undefined});
          }
          onActualizarRegistro(registroReporteLocal, page, personal);
     };

     const onGrupoSeleccionado = (value) => {
          const registroReporteLocal = registroReporteSeleccionado;
          onActualizarRegistro(null, page, personal);
          formRef.current.setFieldsValue({'idsGerencias': undefined});
          registroReporteLocal.idsGruposServicios = value;
          if(value.length > 0){
               registroReporteLocal.idsGerencias = undefined;
               formRef.current.setFieldsValue({'idsServicios': undefined, 'idsPartidas': undefined});
               onListaServiciosByIdsGrupos(value);
          } else {
               registroReporteLocal.idsGerencias = [];
               formRef.current.setFieldsValue({'idsGruposServicios': undefined, 'idsServicios': undefined, 'idsPartidas': undefined});
          }
          onActualizarRegistro(registroReporteLocal, page, personal);
     };

     const setTipoProgramaRutina = (value) =>{
          const registroReporteLocal = registroReporteSeleccionado;
          onActualizarRegistro(null, page, personal);
          registroReporteLocal.showArea = value;
          onActualizarRegistro(registroReporteLocal, page, personal);
     };

     const servicioSeleccionado = (value) => {
          console.log('value', value)
          if(value.length > 0){
               onGetPartidasByIdsServicios(value);
               formRef.current.setFieldsValue({'idsPartidas': undefined});
          } else {
               onCleanPartidasByIdsServicios();
               formRef.current.setFieldsValue({'idsPartidas': undefined});
          }
     };


     return (
          <div>
               <Modal
                    visible={showModalEditarRegistro}
                    title='Editar Registro Predeterminado'
                    onCancel={handleCancel}
                    footer={false}
                    width='75%'
               >
                    {registroReporteSeleccionado ? (
                    <Spin spinning={fetchingEditarRegistro}>
                         <Form {...layout}
                              name='formulario'
                              onFinish={onFinish}
                              ref={formRef}
                              initialValues={registroReporteSeleccionado}
                         >
                              <Row type='flex' justify='space-around' gutter={[8,8]}>
                                   <Col span={12}>
                                        <Form.Item
                                             label='Título del documento'
                                             name='titulo'
                                             rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                             >
                                             <Input maxLength={200} placeholder='Ingresa el título del documento' style={{ width: '100%' }} />
                                        </Form.Item>
                                   </Col>
                                   <Col span={12}>
                                        <Form.Item
                                             label='Nombre'
                                             name='nombre'
                                             rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                             >
                                             <Input maxLength={100} placeholder='Ingresa el nombre' style={{ width: '100%' }} />
                                        </Form.Item>
                                   </Col>
                              
                                   <Col span={24}>
                                        <Form.Item
                                             label='Descripción'
                                             name='descripcion'
                                             >
                                             <TextArea autoSize maxLength={450} placeholder='Ingresa la descripción' style={{ width: '100%' }} />
                                        </Form.Item>
                                   </Col>

                                   <Divider orientation='left'>Columnas</Divider>
                                   <Row style={{ width: '100%', textAlign: 'left', marginBottom: '2em', paddingLeft:'1em' }}>
                                        <Col span={11}>
                                             <Checkbox onChange={selectAll} checked={registroReporteSeleccionado.checkAll}><strong>Todas</strong></Checkbox>
                                        </Col>
                                   </Row>
                                   
                                   {registroReporteSeleccionado.checkboxData ? (
                                   <Row style={{ width: '100%', textAlign: 'left', paddingLeft:'1em'}}>
                                        {registroReporteSeleccionado.checkboxData.map( item => 
                                             <>
                                             {typeof item[2] != "boolean" &&
                                                  <Col key={item[0]} span={8}>
                                                       <Form.Item
                                                       name={item[0]}
                                                       >
                                                            <Checkbox checked={item[1]} onChange={e => changeColumna(e, item[0])} key={item[0]}>{item[2]}</Checkbox>
                                                       </Form.Item>
                                                  </Col>
                                             }
                                             </>
                                        )}
                                   </Row>
                                   ) : false }
                              </Row>
                              <Row>
                                   <Col span={24}>
                                        <Divider orientation='left'>Filtros</Divider>
                                   
                                        <Row justify='space-between' gutter={[8,8]} style={{padding:'1em'}}>
                                             <Col span={11}>
                                                  <Form.Item
                                                       name='tipo'
                                                       label='Tipo'
                                                       rules={[{ required: false, message: 'Favor de llenar el campo' }]}
                                                  >
                                                       <Select
                                                            allowClear
                                                            style={{ width: '100%' }}
                                                            placeholder="Selecciona el tipo"
                                                            showSearch={true}
                                                            notFoundContent={null}
                                                       >
                                                            <Option key='Ordenes'>Ordenes</Option>
                                                            <Option key='Tareas'>Tareas</Option>
                                                       </Select>
                                                  </Form.Item>
                                             </Col>
                                        
                                             <Col span={11}>
                                                  <Form.Item
                                                       name="idsGerencias"
                                                       label="Gerencia"
                                                       rules={[{ required: false, message: 'Favor de llenar el campo' }]}
                                                  >
                                                  {permisosUsuario.especialRecursosHumanos ? (
                                                  <Select
                                                       allowClear
                                                       style={{ width: '100%' }} 
                                                       placeholder="Selecciona las gerencias"
                                                       loading={fetchingListaClasificacionesActivos}
                                                       mode='multiple'
                                                       onChange={onClasificacionSeleccionado}
                                                       disabled={registroReporteSeleccionado.idsGerencias===undefined}
                                                  >
                                                       {listaClasificacionesActivos.map(item => <Option key={item.idClasificacionGerencia + ' - ' + item.nombreGerencia}>{item.idClasificacionGerencia + ' - ' + item.nombreGerencia}</Option>)}
                                                  </Select>
                                                  ):(
                                                  <Select allowClear
                                                       style={{ width: '100%' }}
                                                       placeholder="Selecciona las gerencias"
                                                       loading={fetchingListaGerenciasByIdUsuario}
                                                       mode='multiple'
                                                       onChange={onClasificacionSeleccionado}
                                                       disabled={registroReporteSeleccionado.idsGerencias===undefined}
                                                  >
                                                       {listaGerenciasByIdUsuario.map(option => 
                                                       <Option key={option.idClasificacionGerencia + ' - ' + option.nombreGerencia}>
                                                            <Tooltip title={option.idClasificacionGerencia + ' - ' + option.nombreGerencia}>
                                                                 {option.idClasificacionGerencia + ' - ' + option.nombreGerencia}
                                                            </Tooltip>
                                                       </Option>
                                                       )}
                                                  </Select>
                                                  )}
                                                  </Form.Item>
                                             </Col>

                                             <Col span={11}>
                                                  <Form.Item
                                                       name='idsGruposServiciosEditar'
                                                       label='Grupo de Servicios'
                                                       rules={[{ required: false, message: 'Favor de llenar el campo' }]}
                                                  >
                                                       {permisosUsuario.especialRecursosHumanos ? (
                                                       <Select
                                                            allowClear
                                                            style={{ width: '100%' }} 
                                                            placeholder="Selecciona los grupos de servicios"
                                                            loading={fetchingListaGruposServicios}
                                                            mode='multiple'
                                                            onChange={onGrupoSeleccionado}
                                                            disabled={registroReporteSeleccionado.idsGruposServicios===undefined}
                                                       >
                                                            {listaGruposServicios.map(option => <Option key={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}><Tooltip title={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}>{option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}</Tooltip></Option>)}
                                                       </Select>
                                                       ):(
                                                       <Select
                                                            mode='multiple'
                                                            allowClear
                                                            style={{ width: '100%' }} placeholder="Selecciona los grupos de servicios"
                                                            loading={fetchingListaGrupoServiciosByIdUsuario}
                                                            onChange={onGrupoSeleccionado}
                                                            disabled={registroReporteSeleccionado.idsGruposServicios===undefined}
                                                       >
                                                            {listaGrupoServiciosByIdUsuario.map(option => <Option key={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}><Tooltip title={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}>{option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}</Tooltip></Option>)}
                                                       </Select>
                                                       )}
                                                  </Form.Item>
                                             </Col>

                                             <Col span={11}>
                                                  <Form.Item
                                                       name='idsServiciosEditar'
                                                       label='Servicio'
                                                  >
                                                       {permisosUsuario.especialGestiónDeCalidad ? (
                                                       <Select
                                                            allowClear
                                                            style={{ width: '100%' }} placeholder="Selecciona los servicios"
                                                            mode='multiple'
                                                            disabled={registroReporteSeleccionado.idsGruposServicios && registroReporteSeleccionado.idsGerencias}
                                                            loading={registroReporteSeleccionado.idsGruposServicios ? fetchingListaServiciosByIdsGrupos : fetchingListaServiciosByIdsGerencias}
                                                            onChange={servicioSeleccionado}
                                                       >
                                                            {registroReporteSeleccionado.idsGruposServicios ? (<>
                                                                 { listaServiciosByIdsGrupos ? (<>
                                                                      {listaServiciosByIdsGrupos.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nomenclatura + ": " + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nomenclatura + ": " + servicio.nombre }</Option>)}
                                                                 </>):false }
                                                            </>) : (<>
                                                                 { listaServiciosByIdsGerencias ? (<>
                                                                      {listaServiciosByIdsGerencias.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nomenclatura + ": " + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nomenclatura + ": " + servicio.nombre}</Option>)}
                                                                 </>):false }
                                                            </>)}
                                                       </Select>
                                                       ):(
                                                       <Select
                                                            mode='multiple'
                                                            allowClear
                                                            style={{ width: '100%' }} 
                                                            placeholder="Selecciona los servicios"
                                                            disabled={registroReporteSeleccionado.idsGruposServicios && registroReporteSeleccionado.idsGerencias}
                                                            loading={registroReporteSeleccionado.idsGruposServicios ? fetchingListaServiciosByIdsGrupos : fetchingListaServiciosByIdsGerencias}
                                                       >
                                                            {registroReporteSeleccionado.idsGruposServicios ? (<>
                                                                 { serviciosByIdUsuarioGrupos ? (<>
                                                                      {serviciosByIdUsuarioGrupos.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nomenclatura + ": " + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nomenclatura + ": " + servicio.nombre}</Option>)}
                                                                 </>):false }
                                                            </>) : (<>
                                                                 { serviciosByIdUsuarioGerencia ? (<>
                                                                      {serviciosByIdUsuarioGerencia.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nomenclatura + ": " + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nomenclatura + ": " + servicio.nombre}</Option>)}
                                                                 </>):false }
                                                            </>)}
                                                       </Select>
                                                       )}
                                                  </Form.Item>
                                             </Col>

                                             {registroReporteSeleccionado.partidaC && (
                                                  <Col span={11}>
                                                       <Form.Item
                                                            name='idsPartidasEditar'
                                                            label='Partida'
                                                       >
                                                            <Select
                                                                 mode='multiple'
                                                                 allowClear
                                                                 style={{ width: '100%' }} 
                                                                 placeholder="Selecciona las partidas"
                                                                 disabled={listaPartidasByIdsServicios.length <= 0}
                                                                 loading={fetchingListaPartidasByIdsServicios}
                                                            >
                                                                 {listaPartidasByIdsServicios.map(partida => <Option key={partida.idPartida + ' - ' + partida.nombre}>{partida.idPartida + ' - ' + partida.nombre}</Option>)}
                                                            </Select>
                                                       </Form.Item>
                                                  </Col>
                                             )}
                                        
                                             {registroReporteSeleccionado.estatusOrdenC ? (
                                             <Col span={11}>
                                                  <Form.Item
                                                       name='estatusOrden'
                                                       label='Estatus'
                                                  >
                                                       <Select
                                                            mode='multiple'
                                                            allowClear
                                                            style={{ width: '100%' }}
                                                            placeholder="Selecciona el estatus de la orden"
                                                            showSearch={true}
                                                            notFoundContent={null}
                                                       >
                                                            <Option key='Generada'>Generada</Option>
                                                            <Option key='Realizada'>Realizada</Option>
                                                       </Select>
                                                  </Form.Item>
                                             </Col>
                                             ):false}
                                             
                                             {registroReporteSeleccionado.tipoProgramaC ? (
                                             <Col span={11}>
                                                  <Form.Item
                                                       name='tipoPrograma'
                                                       label='Tipo de Programa'
                                                  >
                                                       <Select
                                                            mode='multiple'
                                                            allowClear
                                                            style={{ width: '100%' }}
                                                            placeholder="Selecciona el tipo de programa"
                                                            onChange={(value) => {value.includes('Rutina')===true ? setTipoProgramaRutina(true)  : value.length === 0 ? setTipoProgramaRutina(true) : setTipoProgramaRutina(false)}}
                                                            showSearch={true}
                                                            notFoundContent={null}
                                                       >
                                                            <Option key='Rutina'>Rutina</Option>
                                                            <Option key='Mantenimiento'>Mantenimiento</Option>
                                                            <Option key='Prueba'>Prueba</Option>
                                                       </Select>
                                                  </Form.Item>
                                             </Col>
                                             ):false}

                                             {registroReporteSeleccionado.showArea &&
                                             <>
                                                  <Col span={11}>
                                                       <Form.Item
                                                            name='areaFuncional'
                                                            label='Área Funcional'
                                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                                       >
                                                            <Select allowClear
                                                                 style={{ width: '100%' }}
                                                                 placeholder="Selecciona el área funcional"
                                                                 onChange={onChangeSelect}
                                                                 loading={fetchingListaAreasFuncionalesActivas}
                                                            >
                                                            {listaAreasFuncionalesActivas.map(option => <Option key={option.codigoArea + ': ' +  option.areaFuncional}>{option.codigoArea + ': ' + option.areaFuncional }</Option>)}
                                                            </Select>
                                                       </Form.Item>
                                                  </Col>
                                                  
                                                  <Col span={11}>
                                                       <Form.Item
                                                            name='unidadFuncionalEditar'
                                                            label='Unidad Funcional'
                                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                                       >
                                                            <Select allowClear
                                                                 style={{ width: '100%' }}
                                                                 disabled={!listaUnidadesFuncionalesActivas.length}
                                                                 placeholder="Selecciona el unidad funcional"
                                                                 loading={fetchingListaUnidadesFuncionalesActivas}
                                                            >
                                                            {listaUnidadesFuncionalesActivas.map(option => <Option key={option.claveUnidad }>{option.claveUnidad + ": " + option.unidadFuncional}</Option>)}
                                                            </Select>
                                                       </Form.Item>
                                                  </Col>
                                             </>
                                             }
                                             <Col span={11}>
                                                  <Form.Item
                                                       label="Rango de Fecha"
                                                       name="fecha"
                                                  >
                                                       <RangePicker
                                                            format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Fecha inicio", "Fecha fin"]}
                                                            // disabledDate={disabledRange}
                                                       />
                                                  </Form.Item>
                                             </Col>
                                        </Row>
                                        <Divider orientation='left'>Agrupamiento</Divider>
                                        <Row>
                                             <Col span={24}>
                                                  <Form.Item name='tipoAgrupado'>
                                                       <Radio.Group defaultValue={registroReporteSeleccionado.field} size="small">
                                                            { registroReporteSeleccionado.gerenciaC ? (<Radio.Button value="Gerencia">Gerencia</Radio.Button>): false }
                                                            { registroReporteSeleccionado.grupoServiciosC ? (<Radio.Button value="Grupo de Servicios">Grupo Servicios</Radio.Button>): false }
                                                            { registroReporteSeleccionado.servicioC ? (<Radio.Button value="Servicio">Servicio</Radio.Button>): false }
                                                            { registroReporteSeleccionado.partidaC ? (<Radio.Button value="Partida">Partida</Radio.Button>): false }
                                                            { registroReporteSeleccionado.tipoC ? (<Radio.Button value="Tipos">Tipo</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.estatusOrdenC ? (<Radio.Button value="Estatus">Estatus</Radio.Button>): false }
                                                            { registroReporteSeleccionado.tipoProgramaC ? (<Radio.Button value="Tipo de Programa">Tipo de Programa</Radio.Button>): false }
                                                            { registroReporteSeleccionado.ubicacionC ? ( <Radio.Button value="Area Funcional">Ubicación</Radio.Button>): false }
                                                            { registroReporteSeleccionado.nombreActivoC ? (<Radio.Button value="Activo">Nombre Activo</Radio.Button>): false }
                                                            <Radio.Button value='SinAgrupar'>Sin Agrupamiento</Radio.Button>
                                                       </Radio.Group>
                                                  </Form.Item>
                                             </Col>
                                        </Row>
                                        <Divider orientation='left'>Ordenamiento</Divider>
                                        <Row justify='space-around' gutter={[8,8]} align='middle'>
                                             <Col span={19}>
                                                  <Form.Item name='field'>
                                                       <Radio.Group defaultValue={registroReporteSeleccionado.tipoAgrupado} size="small">
                                                            { registroReporteSeleccionado.idReporteOrdenesServiciosPlanificados ? ( <Radio.Button value={'osp.IdOrdenServicioPlanificado'}>ID</Radio.Button>): false }
                                                            { registroReporteSeleccionado.tipoC ? (<Radio.Button value={"ru.Tipo"}>Tipo</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.grupoServiciosC ? (<Radio.Button value={"ser.Nombre"}>Servicio</Radio.Button>): false }
                                                            { registroReporteSeleccionado.folioOrdenC ? ( <Radio.Button value={"osp.FolioOrden"}>Folio</Radio.Button>): false }
                                                            { registroReporteSeleccionado.ubicacionC ? ( <Radio.Button value={"af.CodigoArea"}>Ubicación</Radio.Button>): false }
                                                            { registroReporteSeleccionado.nombreActivoC ? (<Radio.Button value={"ace.Descripcion"}>Nombre del Activo</Radio.Button>): false }
                                                            { registroReporteSeleccionado.fecha ? (<Radio.Button value={"fre.Fecha"}>Fecha</Radio.Button>): false }
                                                       </Radio.Group>
                                                  </Form.Item>
                                             </Col>
                                             <Col span={5}>
                                                  <Form.Item name='order'>
                                                       <Radio.Group defaultValue={registroReporteSeleccionado.order} size="small">
                                                            <Radio.Button value="ASC">Ascendente</Radio.Button>
                                                            <Radio.Button value="DESC">Descendente</Radio.Button>
                                                       </Radio.Group>
                                                  </Form.Item>
                                             </Col>
                                        </Row>
                                   </Col>
                                   <Col span={24}>
                                        <Row justify='space-around' gutter={[8,8]} align='middle'>
                                             <Col span={11} className='marginBoton'>
                                                  <br/>
                                                  <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Editar Registro Predeterminado</Button>
                                             </Col>
                                        </Row>
                                   </Col>
                              </Row>
                         </Form>
                    </Spin>
                    ):false}
               </Modal>
          </div>
     )
}

const mapStateToProps = state => {
     return {
          showModalEditarRegistro: state.ServiciosPlanificadosReducer.showModalEditarRegistro,
          fetchingEditarRegistro: state.ServiciosPlanificadosReducer.fetchingEditarRegistro,
          page: state.ServiciosPlanificadosReducer.page,
          personal: state.ServiciosPlanificadosReducer.personal,
          registroReporteSeleccionado: state.ServiciosPlanificadosReducer.registroReporteSeleccionado,
          fetchingListaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.fetchingListaClasificacionesActivos,
          listaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.listaClasificacionesActivos,
          fetchingListaGruposServicios: state.CatalogosGeneralesReducer.fetchingListaGruposServicios,
          listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,
          fetchingListaGerenciasByIdUsuario: state.ServiciosPlanificadosReducer.fetchingListaGerenciasByIdUsuario,
          listaGerenciasByIdUsuario: state.ServiciosPlanificadosReducer.listaGerenciasByIdUsuario,
          listaGrupoServiciosByIdUsuario: state.ServiciosPlanificadosReducer.listaGrupoServiciosByIdUsuario,
          fetchingListaGrupoServiciosByIdUsuario: state.ServiciosPlanificadosReducer.fetchingListaGrupoServiciosByIdUsuario,
          listaServiciosByIdsGrupos: state.CatalogosGeneralesReducer.listaServiciosByIdsGrupos,
          fetchingListaServiciosByIdsGrupos: state.CatalogosGeneralesReducer.fetchingListaServiciosByIdsGrupos,
          serviciosByIdUsuarioGrupos: state.CatalogosGeneralesReducer.serviciosByIdUsuarioGrupos,
          listaServiciosByIdsGerencias: state.CatalogosGeneralesReducer.listaServiciosByIdsGerencias,
          fetchingListaServiciosByIdsGerencias: state.CatalogosGeneralesReducer.fetchingListaServiciosByIdsGerencias,
          serviciosByIdUsuarioGerencia: state.CatalogosGeneralesReducer.serviciosByIdUsuarioGerencia,
          fetchingListaAreasFuncionalesActivas: state.AlmacenesReducer.fetchingListaAreasFuncionalesActivas,
          listaUnidadesFuncionalesActivas: state. AlmacenesReducer.listaUnidadesFuncionalesActivas,
          fetchingListaUnidadesFuncionalesActivas: state.AlmacenesReducer.fetchingListaUnidadesFuncionalesActivas,
          listaAreasFuncionalesActivas: state.AlmacenesReducer.listaAreasFuncionalesActivas,

          listaPartidasByIdsServicios: state.ConfiguracionYSeguridadReducer.listaPartidasByIdsServicios,
          fetchingListaPartidasByIdsServicios: state.ConfiguracionYSeguridadReducer.fetchingListaPartidasByIdsServicios,
     }
}

const mapDispatchToProps = dispatch => {
     return {
          onShowModalRegistroReporte: (page, personal) => {
               dispatch({ type: "MODAL_EDITAR_REGISTRO_SP", showModalEditarRegistro: false, registroReporteSeleccionado: null,  page, personal});
          },
          onEditarRegistroReporte: (formulario, page, personal, registroReporteSeleccionado) => {
               dispatch({ type: 'EDITAR_REGISTROS_SP_REQUEST', formulario, page, personal, registroReporteSeleccionado });
          },
          onActualizarRegistro: (registroReporteSeleccionado, page, personal) => {
               dispatch({ type: "MODAL_EDITAR_REGISTRO_SP", showModalEditarRegistro: true, registroReporteSeleccionado, page, personal });
          },
          onGetUnidades: (idAreaFuncional) => {
               dispatch({ type: "LISTA_UNIDAD_FUNCIONAL_REQUEST" , idAreaFuncional});
          },
          onListaServiciosByIdsGerencias: (idsGerencias) => {
               dispatch({ type: 'LISTA_SERVICIOS_BY_IDGERENCIAS_REQUEST', idsGerencias });
          },
          onListaServiciosByIdsGrupos: (idsGruposSer) => {
               dispatch({ type: 'LISTA_SERVICIOS_BY_IDSGRUPOS_REQUEST', idsGruposSer });
          },
          limpiarUnidades: () => {
               dispatch({ type: "LISTA_UNIDAD_FUNCIONAL_SUCCESS", listaUnidadesFuncionalesActivas:[]});
          },
          onlistaRegistrosSP: (page) => {
               dispatch({ type: 'LISTA_REGISTROS_SP_REQUEST', page })
          },

          onGetPartidasByIdsServicios: (idsServicios) => {
               dispatch({ type: 'LISTA_PARTIDAS_BY_IDSSERVICIOS_REQUEST', idsServicios });
          },
          onCleanPartidasByIdsServicios: () => {
               dispatch({ type: 'LISTA_PARTIDAS_BY_IDSSERVICIOS_SUCCESS', listaPartidasByIdsServicios: [] });
          },
          
     }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalEditarRegistroSP);