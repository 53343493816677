import React, { Component } from 'react';
import { Modal } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';
import EditarCategoria from './EditarCategoria';
import VerificarContraseña from './VerificarContraseña';
import VerificarEdicion from './VerificarEdicion';
import ArchivoEditarCategoria from './ArchivoEditarCategoria';

class ModalEditarCategoriaFallaDeCalidad extends Component {
    formRef = React.createRef();
    render() {
        const {showModalEditarCategoriaFallaDeCalidad, onShowModalEditarCategoriaFallaDeCalidad,
             onLimpiarFormulario, vistaModalFallaCalidad, onCambiarVista } = this.props

        const handleCancel = () => {
            onShowModalEditarCategoriaFallaDeCalidad();
            onLimpiarFormulario('');
            onCambiarVista(1);
        }

        function ContenidoEditar() {
            if(vistaModalFallaCalidad === 1){
                return(
                    <EditarCategoria/>
                );
            }
            else if(vistaModalFallaCalidad === 2){
                return(
                    <VerificarContraseña/>
                );
            }
            else if(vistaModalFallaCalidad === 3){
                return(
                    <VerificarEdicion />
                );
            }
            else if (vistaModalFallaCalidad === 4) {
                return (
                    <ArchivoEditarCategoria />
                );
            }
        };

        return (
            <Modal
                visible={showModalEditarCategoriaFallaDeCalidad}
                title='Editar Categoria de Fallas de Calidad'
                onCancel={handleCancel}
                footer={false}
                width={'55%'}
            >
                <ContenidoEditar />
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        showModalEditarCategoriaFallaDeCalidad: state.MecanismosDePagosReducer.showModalEditarCategoriaFallaDeCalidad,
        vistaModalFallaCalidad: state.MecanismosDePagosReducer.vistaModalFallaCalidad,
        formularioFallaCalidad: state.MecanismosDePagosReducer.formularioFallaCalidad,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarCategoriaFallaDeCalidad: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_CATEGORIA_FALLA_CALIDAD', showModalEditarCategoriaFallaDeCalidad: false });
        },
        onLimpiarFormulario: (limpiarFormulario) => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: limpiarFormulario});
        },
        onCambiarVista: (vistaModalFallaCalidad) => {
            dispatch({ type: 'CAMBIAR_VISTA_FALLA_CALIDAD', vistaModalFallaCalidad: vistaModalFallaCalidad})
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarCategoriaFallaDeCalidad);