import React, { Component } from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined, FileExcelOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import Nuevo from './NuevoTipoMoneda';
import Editar from './EditarTipoMoneda';

const { Search } = Input;
const confirm = Modal.confirm;
class TipoMonedaContainer extends Component {
     componentDidMount(){ this.props.onGetListaTipoMoneda(1); }
     state = { page: 1, busqueda: '', nomenclatura : '' }
     render() {
          const { onEstado } = this.props;

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
               permisosUsuario = JSON.parse(permisosUsuario);
          };
          const onBuscarNombre = (busqueda) => {
               this.setState({
                    busqueda: busqueda,
                    page: 1,
               });
               this.props.onGetListaTipoMoneda(1, busqueda, this.state.nomenclatura);
          };

          const onBuscarNomenclatura = (nomenclatura) => {
               this.setState({
                    nomenclatura: nomenclatura,
                    page: 1,
               });
               this.props.onGetListaTipoMoneda(1, this.state.busqueda, nomenclatura);
          };


          const columns = [
               {
                    title: 'Moneda',
                    dataIndex: 'nombre',
                    key: 'nombre',
                    align: 'center'
               },{
                    title: 'Nomenclatura',
                    dataIndex: 'nomenclatura',
                    key: 'nomenclatura',
                    align: 'center'
               },{
                    title: 'Activo',
                    dataIndex: 'activo',
                    key: 'activo',
                    align: 'center',
                    render: (text, record) => (
                         <Switch checked={text} disabled={!permisosUsuario.interiorFuncionalActivoInactivo} onChange={() => handleDesactivar(record)} />
                    ),
               }, {
                    title: 'Editar',
                    key: 'editar',
                    align: 'center',
                    render: (text, record) => (
                         <a className='editarIcono' disabled={!permisosUsuario.editarInteriorFuncional} onClick={() => onEditar(record)}><EditOutlined /></a>
                    ),
               },
          ];

          const onEditar = (key) => {
               console.log(key);
               this.props.onModalEditar(key, this.state.page, this.state.busqueda);
          };

          const handleDesactivar = (key) => {
               const page = this.state.page;
               const busqueda =  this.state.busqueda;
               confirm({
                    title: '¿Estás seguro que deseas cambiar el estado del tipo de moneda "'+ key.nombre +'"?',
                    content: '',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                         onEstado(key.idTipoMoneda, page, busqueda);
                    },
                    onCancel() {
                         console.log('Cancel');
                    },
               });
          } 

          const handleTableChange = (pagination)=>{
               this.setState({
                    page: pagination.current,
               })
               this.props.onGetListaTipoMoneda(pagination.current, this.state.busqueda, this.state.nomenclatura);
          };

          return (
               <div>
                    <Row justify='space-between'>
                         <Col span={10}>
                              <Search allowClear className='buscarModulos' placeholder="Buscar moneda" onSearch={value => onBuscarNombre(value)} style={{ width: '100%' }} />
                         </Col>

                         <Col span={10}>
                              <Search allowClear className='buscarModulos' placeholder="Buscar nomenclatura" onSearch={value => onBuscarNomenclatura(value)} style={{ width: '100%' }} />
                         </Col>

                         <Col span={1}>
                              <Tooltip title='Nuevo Tipo de Moneda' placement="topRight">
                                   <Button className="iconTer" shape="circle" onClick={() => this.props.onModalNuevo(this.state.page, this.state.busqueda)} disabled={false} icon={<PlusOutlined />}/>
                              </Tooltip>
                         </Col>

                         <Col span={1}>
                              <Tooltip title='Exportar archivo .xls' placement="topRight">
                                   <Button disabled={!permisosUsuario.exportarExcelCatálogoActivos} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } loading={this.props.fetchingExportarExcelTipoMoneda} onClick={() => this.props.onExport()}/>
                              </Tooltip>
                         </Col> 

                         <Col span={24} style={{paddingTop:'2em'}}>
                              <Table
                                   size="small"
                                   rowKey="idTipoMoneda"
                                   columns={columns}
                                   dataSource={this.props.listTipoMoneda}
                                   loading={this.props.fetchingListTipoMoneda}
                                   locale={{ emptyText: 'Sin datos' }}
                                   onChange={handleTableChange}
                                   pagination={{ total: this.props.listTipoMonedaCount, simple: true, current: this.state.page }}
                              />
                         </Col>
                    </Row>
                    <Nuevo />
                    <Editar />
               </div>
          );
     }
}

const mapStateToProps = state => {
     return {
          fetchingListTipoMoneda: state.CatalogosGeneralesReducer.fetchingListTipoMoneda,
          listTipoMoneda: state.CatalogosGeneralesReducer.listTipoMoneda,
          listTipoMonedaCount: state.CatalogosGeneralesReducer.listTipoMonedaCount,
          fetchingExportarExcelTipoMoneda: state.CatalogosGeneralesReducer.fetchingExportarExcelTipoMoneda,
     };
};


const mapDispatchToProps = (dispatch) => {
     return { 
          onGetListaTipoMoneda: (page, busqueda, nomenclatura) => {
               dispatch({ type: 'LISTA_TIPOS_MONEDAS_REQUEST', page, busqueda, nomenclatura });
          },
          onEstado: (idTipoMoneda, page, busqueda) => {
               dispatch({ type: "ESTADO_TIPO_MONEDA_REQUEST", idTipoMoneda, page, busqueda })
          }, 
          onModalNuevo: (page, busqueda) => {
               dispatch({ type: "SHOW_MODAL_NUEVO_TIPO_MONEDA", showModalNuevoTipoMoneda: true, page, busqueda});
          },
          onModalEditar: (tipoMonedaSeleccionado, page, busqueda) => {
               dispatch({ type: "SHOW_MODAL_EDITAR_TIPO_MONEDA", showModalEditarTipoMoneda: true, tipoMonedaSeleccionado, page, busqueda })
          },
          onExport: () => {
               dispatch({ type: "EXPORTAR_EXCEL_TIPO_MONEDA_REQUEST" });
          },
     }
    
}
export default connect(mapStateToProps, mapDispatchToProps)(TipoMonedaContainer);