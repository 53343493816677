import React, { Component } from 'react';
import {Row, Col, Modal, Select, Form, Button, InputNumber, message, Input} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

const {Option} = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};


class ModalPrestamo extends Component {
    formRef = React.createRef();
    render() { 
        const {
            showModalPrestamo,
            onCerrarModalPrestamo,
            herramientaSeleccionadaPrestamo,
            listaHerramientas,
            onGuardarDatos,
            listaSinRepetir,
            listaNumerosInventarioPrestados,
            numerosInventarioDisponibles,
            numerosInventarioDisponiblesCantidad,
            limpiarFormulario,
            onLimpiarFormulario,
        } = this.props;

        const handleCancel = () => {
            onCerrarModalPrestamo();
            onLimpiarFormulario();
            this.formRef.current.resetFields()
        };

        const onFinish = (formulario) => {
            console.log('formulario', formulario)

            let listaHerramientasLocal = listaHerramientas
            onGuardarDatos(null);
            for(let i in listaHerramientasLocal){
                if(listaHerramientas[i].idInventario === herramientaSeleccionadaPrestamo.idInventario){
                    listaHerramientasLocal[i].cantidadPrestamo = formulario.herramientas.length;
                    listaHerramientasLocal[i] = Object.assign(listaHerramientasLocal[i], formulario)
                }
            }
            console.log('lista fuera del for', listaHerramientasLocal)
            onGuardarDatos(listaHerramientasLocal);
            onCerrarModalPrestamo();
            this.formRef.current.resetFields()
        };
        
        const onProductoSeleccionado = (value, field) => {
            
            let formulario = this.formRef.current.getFieldsValue('herramientas')

            console.log(value, field, formulario, listaSinRepetir)

            if(listaNumerosInventarioPrestados) {
                for(let i in listaNumerosInventarioPrestados){
                    if(listaNumerosInventarioPrestados[i].numeroInventario == value){
                        formulario.herramientas[field.name].codigoInventario = null;
                        message.warning('La herramienta se encuentra en un prestamo o una baja')
                        this.formRef.current.setFieldsValue({'herramientas': formulario.herramientas});
                    }
                }
            }

            let repetido = false;
            if(listaSinRepetir){
                for(let i=0; i<listaSinRepetir.length; i++){
                    if(listaSinRepetir[i] === value){
                        if(listaSinRepetir[field]){
                            console.log('entra al if')
                            formulario.herramientas[field.key].codigoInventario = listaSinRepetir[field.key];
                            message.warning('No es posible seleccionar una herramienta que ya esté en la lista');
                            this.formRef.current.setFieldsValue({'herramientas': formulario.herramientas});
                            repetido = true;
                        }
                        formulario.herramientas[field.name].codigoInventario = null;
                        message.warning('No es posible seleccionar una herramienta que ya esté en la lista');
                        this.formRef.current.setFieldsValue({'herramientas': formulario.herramientas});
                        repetido = true;
                        break;
                    }
                }
            }
            
            if(!repetido){
                let listaActualizada = [];
                for(let x=0; x<formulario.herramientas.length; x++){
                    listaActualizada.push(formulario.herramientas[x]?.codigoInventario ?? 'undefined'); //
                }
                this.props.onActualizarListaSinRepetir(listaActualizada)
            }
             
        }
        return ( 
            <div>
                <Modal
                    visible={showModalPrestamo}
                    title='Prestamos'
                    onCancel={handleCancel}
                    footer={false}
                    width={'40%'}
                >
                    <Row justify='space-between'> 
                        <Col span={24}>
                            
                            <Form {...layout}
                                name="formulario"
                                onFinish={onFinish}
                                ref={this.formRef}
                            >
                                <Form.List name="herramientas">
                                    {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(field => (
                                        <Row justify='space-around' key={field.key}>
                                            <Col span={21}>
                                                <Form.Item
                                                    {...field}
                                                    label='Codigo Inventario'
                                                    name={[field.name, 'codigoInventario']}
                                                    fieldKey={[field.fieldKey, 'codigoInventario']}
                                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                >
                                                    <Select
                                                        style={{ width: '100%' }}
                                                        placeholder="Seleccionar numero de Inventario"
                                                        showSearch
                                                        filterOption={false}
                                                        onChange={(value) => onProductoSeleccionado(value, field)}
                                                        notFoundContent={null}
                                                    >
                                                        {numerosInventarioDisponibles.map(option => <Option key={option.idNumeroInventario + ' - ' + option.numeroInventario + ' - ' + option.fechaAlta}>{option.idNumeroInventario + ' - ' + option.numeroInventario}</Option>)}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            
                                            <Col span={2}>
                                                {fields.length > 1 ? (
                                                <Form.Item>
                                                    <MinusCircleOutlined className='borrarInsumo' onClick={() => remove(field.name)} />
                                                </Form.Item>
                                                ):false}
                                            </Col>
                                        </Row>
                                        ))}
                                        {herramientaSeleccionadaPrestamo && herramientaSeleccionadaPrestamo.cantidad > fields.length ? (
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Agregar Herramienta
                                            </Button>
                                        </Form.Item>
                                        ): false }
                                    </>
                                    )}
                                </Form.List>
                                <Row justify='space-around'>
                                    <Col span={11} className='marginBoton'>
                                        <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Guardar Herramientas</Button>
                                    </Col>  
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Modal>    
            </div>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        page: state.AlmacenesReducer.page,
        busqueda: state.AlmacenesReducer.busqueda,
        showModalPrestamo: state.AlmacenesReducer.showModalPrestamo,
        herramientaSeleccionadaPrestamo: state.AlmacenesReducer.herramientaSeleccionadaPrestamo,
        listaHerramientas: state.AlmacenesReducer.listaHerramientas,
        listaNumerosInventarioPrestados: state.AlmacenesReducer.listaNumerosInventarioPrestados,

        listaSinRepetir: state.ServiciosPlanificadosReducer.listaSinRepetir,
        numerosInventarioDisponibles : state.AlmacenesReducer.numerosInventarioDisponibles,
        numerosInventarioDisponiblesCantidad: state.AlmacenesReducer.numerosInventarioDisponiblesCantidad,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCerrarModalPrestamo: () => {
            dispatch({ type: 'SHOW_MODAL_PRESTAMO_HERRAMIENTA', showModalPrestamo: false })
        },
        onGuardarDatos: (listaHerramientasLocal) => {
            dispatch({ type: 'ACTUALIZAR_TABLA_HERRAMIENTAS' , listaHerramientas: listaHerramientasLocal})
        },
        onActualizarListaSinRepetir: (listaSinRepetir) => {
            dispatch({ type: "ACTUALIZAR_LISTA_INSUMOS_SIN_REPETIR",  listaSinRepetir });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPrestamo);