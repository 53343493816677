import React from 'react';
import { Form, Card, Spin, Row, Col, Result, Table, Tooltip, Button, Modal, Input, message, Descriptions, Divider, Tag } from 'antd';
import { UnlockOutlined, LockOutlined, CalculatorOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { loginCall } from "../../../services/LoginServices";

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
};
class PagoServicioContainer extends React.Component {
     formRef = React.createRef();
     componentDidMount(){ this.props.onGetUltimos12Meses(); }

     state={ mesSeleccionado: {}, visibleIdentificar: false, visibleDA: false, dataDescription: [] }
 
     render() {
          const { listaPagoServiciosMeses, fetchingListaPagoServiciosMeses, fetchingCalcularPagoServicio, onCalcularPago, calculoPagoServicio, onSaveCalculo, onDetalle, detallePagoServicio, onCerrarPago } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){ permisosUsuario = JSON.parse(permisosUsuario); }

          let userInfo = sessionStorage.getItem('userInfo');
          if(!userInfo.idUsuario){
               userInfo = JSON.parse(userInfo);
          }

          const onFinish = formulario => {
               console.log('formulario');
               console.log(formulario);
               const userName = userInfo[0].username;
               const values = {
                   'userName': userName,
                   'password': formulario.password,  
               }
               loginCall(values).then(response => {
                   console.log('response');
                   console.log(response);
                   
                    if(response.status === 200){
                         onSaveCalculo(this.state.mesSeleccionado.mesCalendarioByIdMesCalendario.idMesCalendario);
                         this.setState({ visibleIdentificar: false })
                    }
                    else{
                         let errorMensaje = '';
                         if(response.error_message === 'Bad credentials'){
                              errorMensaje = 'Contraseña incorrecta. Por favor intente de nuevo';
                         }
                         else{
                              errorMensaje = response.error_message;
                         }
                         message.error(errorMensaje);
                         return;
                    }
                    this.setState({
                         password: '',
                    });
               }).catch(error => {
                   console.log(error);
                   message.error('Hubo un error, favor de intentar después');
                   return;
               })
          };

          const onFinishFailed = errorInfo => { console.log('Failed:', errorInfo); };

          const detalleDA = (record) => {
               if(record){
                    console.log('record', record)
                    onDetalle(record.mesCalendarioByIdMesCalendario.idMesCalendario);
                    
                    this.setState({ visibleDA: true, mesSeleccionado: record });
               }
          };

          let dataDescrition = []; let dataDescriptionAditional = [];
          if(detallePagoServicio){
               dataDescrition = [
                    
                    { label: <Tooltip title='Fracción Máxima de Descuento del mes * Pago Mensual de Referencia del mes'>PMR * di</Tooltip>, data:

                          this.props.dtfsMayor ? (<Tag className='tag-rad' color="success" style={{fontSize:14}}> {detallePagoServicio.pmr_diTabla} <CheckCircleOutlined /> </Tag>) : (<Tag className='tag-rad' color="error" style={{fontSize:14}}> {detallePagoServicio.pmr_diTabla} <CloseCircleOutlined /> </Tag>) , 
                         
                    id: 4 },
                    { label: <Tooltip title='Fracción Máxima de Descuento del mes'>di</Tooltip>, data: detallePagoServicio.diTabla, id: 2 },
                    { label: <Tooltip title='Pago Mensual de Referencia del mes'>PMR</Tooltip>, data: detallePagoServicio.pmrTabla, id: 3 },
                    { label: <Tooltip title='Deducción Total por Fallas en la Prestación del Servicio en el mes'>DTFS</Tooltip>, data: 
   
                         !this.props.dtfsMayor ? (<Tag className='tag-rad' color="success" style={{fontSize:14}}> {detallePagoServicio.dtfsTabla} <CheckCircleOutlined /> </Tag>) : (<Tag className='tag-rad' color="error" style={{fontSize:14}}> {detallePagoServicio.dtfsTabla} <CloseCircleOutlined /> </Tag>),
                    
                    id: 1 },
                    
               ];

               dataDescriptionAditional = [
                    { label: <Tooltip title='Deducción Total por Fallas de Disponibilidad en el mes'>DTFD</Tooltip>, data: detallePagoServicio.dtfdTabla, id: 1 },
                    { label: <Tooltip title='Deducción Total por Fallas de Calidad en el mes'>DTFC</Tooltip>, data: detallePagoServicio.dtfcTabla, id: 2 },
                    { label: <Tooltip title='Deducción Total por Fallas de Generales del Servicio en el mes'>DTFGS</Tooltip>, data: detallePagoServicio.dtfgsTabla, id: 3 },
                    { label: <Tooltip title='Deducción Total por Reiteración de en el mes'>DTRF</Tooltip>, data: detallePagoServicio.dtrfTabla, id: 4 },
                    { label: <Tooltip title='Deducción Total por Errores u Omisiones de en el mes'>DTEOI</Tooltip>, data: detallePagoServicio.dteoTabla, id: 4 },
                    { label: <Tooltip title='Deducción Pendiente por Aplicar para el mes'>DPA</Tooltip>, data: detallePagoServicio.dpaTabla, id: 4 },
               ];
          }

          const onCalcular = (value) => {
               console.log('value', value);
               if(value){
                    onCalcularPago(value.mesCalendarioByIdMesCalendario.idMesCalendario);
                    this.setState({ mesSeleccionado: value, visible: true });
               }
          };

          const onCerrar = (value) => {
               console.log('value', value)
               Modal.confirm({
                    //title: 'This is a warning message',
                    content: <Result
                    status="warning"
                    title= {<p style={{ fontSize: '16px' }}>¿Desea dar por cerrado el monto de Pago por Servicio del mes: <b style={{color:'#0e2c82'}}>{value.mesCalendario + ' ' + value.anioCalendario}</b>?</p>}
                  />,
                    okText: 'Confirmar',
                    cancelText: 'Cancelar',
                    width: '35%',
                    onOk() {
                         onCerrarPago(value.idPagoServicio);
                    },
               });
          };

          const  validacion = (idMesCalendario) => {
               let validacionMesesAnteriores = false;
               for(let i in listaPagoServiciosMeses){
                    if(listaPagoServiciosMeses[i].idMesCalendario < idMesCalendario){
                         if(listaPagoServiciosMeses[i].estado === true){
                              validacionMesesAnteriores = true;
                         }
                    }
               }
               console.log('validacionMesesAnteriores', validacionMesesAnteriores)
               return validacionMesesAnteriores;
          }

          const columnas = [
               { title: 'Año', dataIndex: 'anioCalendario', key: 'anioCalendario', align: 'center', width:'7%' },
               { title: 'Mes', dataIndex: 'mesCalendario', key: 'mesCalendario', align: 'center', width:'7%' },
               { title: <Tooltip title='Pago Mensual de Inversión'>PMI</Tooltip>, dataIndex: 'pmiTabla', key: 'pmiTabla', align: 'right' },
               { title: <Tooltip title='Pago Mensual de Referencia'>PMR</Tooltip>, dataIndex: 'pmrTabla', key: 'pmrTabla', align: 'right' },
               { title: <Tooltip title='Deducción Pendiente por Aplicar'>DPA</Tooltip>, dataIndex: 'dpaTabla', key: 'dpaTabla', align: 'right' },
               { title: <Tooltip title='Deducción Aplicable'>DA</Tooltip>, dataIndex: '', key: '', align: 'right',
               render: (record) => (
                    <Tooltip placement="right" title={'Detalle de la Deducción Aplicable del mes: ' + record.mesCalendario + ' ' + record.anioCalendario}>
                         <Button type="link" className='naranja' style={{width:'100%', textAlign:'right'}} disabled={!permisosUsuario.detalleDeLaDeducciónAplicableDelMes ? true : record.idPagoServicio > 0  ? false: true } onClick={() => detalleDA(record)}> 
                              {record.daTabla}
                         </Button>
                    </Tooltip>
               )},
               { title: <Tooltip title='Deducción por Consumo de los Servicios Públicos'>CPD</Tooltip>, dataIndex: 'cpdTabla', key: 'cpdTabla', align: 'right' },
               { title: <Tooltip title='Pago de los Servicios Públicos'>SP</Tooltip>, dataIndex: 'spTabla', key: 'spTabla', align: 'right' },
               { title: <Tooltip title='Pago por Servicio'>PMS</Tooltip>, dataIndex: 'pmsTabla', key: 'pmsTabla', align: 'right' },
               {title: 'Acciones', dataIndex: 'pri', key: 'pri', width: '15%', align: 'center',
                    render: (text, record) => (
                         <Row justify='space-around'>
                              <Col span={6}>
                                   <a className='editarIcono' disabled={!permisosUsuario.calcularPagoDeServicio ? true: !record.calculable} onClick={() => onCalcular(record)}>
                                        <Tooltip placement="topRight" title={'Cálcular Pago por Servicio del mes: ' + record.mesCalendario + ' ' + record.anioCalendario}>
                                             <CalculatorOutlined />
                                        </Tooltip>
                                   </a>
                              </Col>
                              <Col span={6}>
                                   {record.estado ? (
                                        <a className='editarIcono' disabled={!permisosUsuario.cerrarPagoDeServicio} onClick={() => onCerrar(record)}>
                                             <Tooltip placement="topRight" title={'Cerrar Pago por Servicio del mes: ' + record.mesCalendario + ' ' + record.anioCalendario}>
                                                  <UnlockOutlined />
                                             </Tooltip>
                                        </a>
                                   ):(
                                        <a className='editarIcono' disabled={true}><LockOutlined /></a>
                                   )}
                              </Col>
                         </Row>
                    )
               }
          ];

          const guardarCambios = () => {
               this.setState({ visibleIdentificar: true });
          };

          const onCancelModalCalculo = () => {
               this.props.setVisible(calculoPagoServicio, false)
               this.setState({ visibleIdentificar: false });
          };

          const handleCancelDA = () => {
               this.setState({ visibleDA: false, dataDescription: [], mesSeleccionado: {}  });
          };

          return (
               <Card className="cardCatalogos" title={<div className='headerLista'>Pago por Servicios</div>}>
                    <br/>
                    { permisosUsuario ? (<>
                         <Spin spinning={fetchingListaPagoServiciosMeses}>
                              <Row justify='space-around'>
                                   <Col span={24}>
                                        <Table size='small' rowKey='idPagoServicio' columns={columnas} dataSource={listaPagoServiciosMeses} pagination={false} loading={fetchingListaPagoServiciosMeses} />
                                   </Col>

                                   { calculoPagoServicio ? (
                                        <Col span={24}>
                                             <Modal
                                                  visible={this.props.visibleCalculoPagoServicio}
                                                  footer={false}
                                                  onCancel={onCancelModalCalculo}
                                                  width= '35%'
                                             >
                                                  <Spin spinning={this.props.fetchingGuardarCalculoPagoServicio}>
                                                       <Spin spinning={fetchingCalcularPagoServicio}>
                                                            <Result
                                                                 status="success"
                                                                 title= {<p>{ calculoPagoServicio.showPMS }</p>}
                                                                 subTitle= {<p style={{ fontSize: '14px' }}>Cálculo para el Pago por Servicio del mes: <b style={{color:'#ff8c62'}}>{this.state.mesSeleccionado.mesCalendario + ' ' + this.state.mesSeleccionado.anioCalendario}</b>, ¿desea guardarlo?</p>}
                                                                 extra={
                                                                      <Button className='btnCargaArchivos' onClick={guardarCambios}>
                                                                           Confirmar
                                                                      </Button>
                                                                 }
                                                            />
                                                       </Spin>
                                                       { this.state.visibleIdentificar ? (
                                                            <Form {...layout} name="formulario" ref={this.formRef} onFinish={onFinish}  >
                                                                 <Card className="cardCatalogos" title='Identificar Usuario'>
                                                                      <Col span={24}>
                                                                           <Form.Item
                                                                                label="Ingresa tu contraseña"
                                                                                name="password"
                                                                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                                                           >
                                                                                <Input.Password prefix={<LockOutlined/>} placeholder="Ingresa tu contraseña"/>
                                                                           </Form.Item>
                                                                      </Col>

                                                                      <Col span={24} className='marginBoton'>
                                                                           <Button className="nuevoUsuario" style={{width: '100%'}} htmlType="submit">Verificar Contraseña y Guardar</Button>
                                                                      </Col>
                                                                 </Card>
                                                            </Form>
                                                       ):false }
                                                  </Spin>
                                             </Modal>
                                        </Col>
                                   ):false }

                                   <Modal
                                        title={"Detalle de Deducción Aplicable: " + this.state.mesSeleccionado.mesCalendario + ' ' + this.state.mesSeleccionado.anioCalendario }
                                        visible={this.state.visibleDA}
                                        footer={false}
                                        onCancel={handleCancelDA}
                                        width = {'80%'}
                                   >
                                        <Spin spinning={this.props.fetchingPagoServicioDetalle}>
                                             <Descriptions  layout="horizontal" bordered={true}  >
                                                  {dataDescrition.map(item => 
                                                       <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                  )}
                                             </Descriptions>
                                             <Divider orientation='left' style={{fontSize: 16, color:'#1747ca'}}> Valores de la sumatoria con los que se determinó DTFS </Divider>
                                             <Descriptions  layout="horizontal" bordered={true}>
                                                  {dataDescriptionAditional.map(item => 
                                                       <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                  )}
                                             </Descriptions>
                                        </Spin>
                                   </Modal>
                              </Row>
                         </Spin>
                    </>): (<>
                         <Row justify='center'>
                              <Result
                                   status="403"
                                   title="Acceso denegado"
                                   subTitle="Lo siento, no tiene permiso autorizado para acceder."
                              />
                         </Row>
                    </>) }
               </Card>
          );
     }
}
 
 const mapStateToProps = state => {
     return {
          fetchingListaPagoServiciosMeses: state.MecanismosDePagosReducer.fetchingListaPagoServiciosMeses,
          listaPagoServiciosMeses: state.MecanismosDePagosReducer.listaPagoServiciosMeses,
          fetchingCalcularPagoServicio: state.MecanismosDePagosReducer.fetchingCalcularPagoServicio,
          fetchingGuardarCalculoPagoServicio: state.MecanismosDePagosReducer.fetchingGuardarCalculoPagoServicio,
          calculoPagoServicio: state.MecanismosDePagosReducer.calculoPagoServicio,
          visibleCalculoPagoServicio: state.MecanismosDePagosReducer.visibleCalculoPagoServicio,
          detallePagoServicio: state.MecanismosDePagosReducer.detallePagoServicio,
          fetchingPagoServicioDetalle: state.MecanismosDePagosReducer.fetchingPagoServicioDetalle,
          dtfsMayor: state.MecanismosDePagosReducer.dtfsMayor
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          onGetUltimos12Meses: () => {
               dispatch({ type: 'LISTA_PAGO_POR_SERVICIO_MESES_REQUEST' });
          },
          onCalcularPago: (idMesCalendario) => {
               dispatch({ type: 'CALCULAR_PAGO_SERVICIO_REQUEST', idMesCalendario});
          },
          setVisible: (calculoPagoServicio, visibleCalculoPagoServicio) => {
               dispatch({ type: 'CALCULAR_PAGO_SERVICIO_SUCCESS', calculoPagoServicio, visibleCalculoPagoServicio });
          },
          onSaveCalculo: (idMesCalendario) => {
               dispatch({ type: 'GUARDAR_CALCULO_PAGO_SERVICIO_REQUEST',idMesCalendario });
          }, 
          onDetalle: (idMesCalendario) => {
               dispatch({ type: 'PAGO_SERVICIO_DETALLE_REQUEST',idMesCalendario });
          },
          onCerrarPago: (idPagoServicio) => {
               dispatch({ type: 'CERRAR_PAGO_SERVICIO_REQUEST', idPagoServicio });
          },
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(PagoServicioContainer);