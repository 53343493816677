import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, FileExcelOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css'
import ModalNuevoRangoRenovacionActivo from './ModalNuevoRangoRenovacionActivoFijo';
import ModalEditarRangoRenovacionActivo from './ModalEditarRangoRenovacionActivoFijo';

const confirm = Modal.confirm;
const { Search } = Input;

class CatalogoRangoRenovacionActivo extends React.Component {
    state = {
        page: 1,
        busqueda: '',
    }

    componentDidMount() {
        this.props.onGetRangosRenovacion(1);
        this.props.onGetUnidadesdeMedicion(1);
        this.props.onRequestInfoProyecto();

    }

    render() {
        const { fetchingListaRangosRenovacionActivoBusqueda, listaRangosRenovacionActivoBusqueda, onGetRangosRenovacion,
            onModalNuevoRangoRenovacion, onModalEditarRangoRenovacion, onEstadoRangosRenovacion, listaRangosRenovacionActivoBusquedaCantidad } = this.props;
    
    
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columns = [{
            title: 'Rango',
            dataIndex: 'descripcion',
            key: 'descripcion',
        }, {
            title: 'Máximo',
            dataIndex: 'maxNum',
            key: 'maxNum',
            align: 'center',
        }, {
            title: 'Mínimo',
            dataIndex: 'minNum',
            key: 'minNum',
            align: 'center',
        },{
            title: 'Unidad de medida',
            dataIndex: 'unidadMedida',
            key: 'unidadMedida',
        },/* {
            title: 'CSS',
            dataIndex: 'css',
            key: 'css',
            align: 'center',
            render: (text) => (
                <div style={{color:text, backgroundColor:text}}>{text}</div>
            ), 
        } */,{
            title: 'Activo',
            dataIndex: 'activo',
            key: 'activo',
            align: 'center',
            render: (text, record) => (
                <Switch checked={text} disabled={!permisosUsuario.rangoDeRenovaciónActivoInactivo} onChange = {() => handleDesactivar(record)} />
            ),
        }, {
            title: 'Editar',
            key: 'editar',
            align: 'center',
            //width: '6%',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.editarRangoDeRenovación} onClick={() => onEditar(record)}><EditOutlined /></a>
            ),
        },
        ];

        const onBuscarRangoRenovacion = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            });
            console.log(busqueda);
            onGetRangosRenovacion(1, busqueda);
        }

        const handleDesactivar = (key) => {
            console.log(key);
            let page = this.state.page
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del rango de renovación "' + key.descripcion + '"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoRangosRenovacion(key.idRangoRenovacion, page);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }

        const onEditar = (key) => {
            onModalEditarRangoRenovacion(key, this.state.page);

        }

        const onNuevoRangoRenovacion = () => {
            onModalNuevoRangoRenovacion(this.state.page);
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            onGetRangosRenovacion(pagination.current, this.state.busqueda);
        }


        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={20}>
                        <Search className='buscarModulos' placeholder='Ingresar rango de renovacion para activos fijos' onSearch={value => onBuscarRangoRenovacion(value)} />
                    </Col>
                    {/* <Col span={9}>
                        <Button onClick={onNuevoRangoRenovacion} disabled={!permisosUsuario.crearRangoDeRenovaciónDeActivosFijos} className='nuevoUsuario'>Nuevo Rango</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nuevo Rango' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoRangoRenovacion} disabled={!permisosUsuario.crearRangoDeRenovaciónDeActivosFijos} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={1}>
                        <Tooltip title='Exportar archivo .xls' placement="topRight">
                            <Button disabled={!permisosUsuario.exportarExcelCatálogoRangosRenovaciónActivoFijo} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } loading={this.props.fetchingExportarExcelRangosRenovacionActivo} onClick={() => this.props.onExport()}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <br />
                        <Table
                            size="small"
                            rowKey="descripcion"
                            columns={columns}
                            dataSource={listaRangosRenovacionActivoBusqueda}
                            loading={fetchingListaRangosRenovacionActivoBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                        onChange={handleTableChange}
                        pagination={{ total: listaRangosRenovacionActivoBusquedaCantidad, simple: true, current: this.state.page }}
                        />
                    </Col>
                </Row>
                < ModalNuevoRangoRenovacionActivo/>
                <ModalEditarRangoRenovacionActivo />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        listaRangosRenovacionActivoBusqueda: state.AlmacenesReducer.listaRangosRenovacionActivoBusqueda,
        fetchingListaRangosRenovacionActivoBusqueda: state.AlmacenesReducer.fetchingListaRangosRenovacionActivoBusqueda,
        listaRangosRenovacionActivoBusquedaCantidad: state.AlmacenesReducer.listaRangosRenovacionActivoBusquedaCantidad,
        fetchingExportarExcelRangosRenovacionActivo: state.AlmacenesReducer.fetchingExportarExcelRangosRenovacionActivo,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetRangosRenovacion: (page, busqueda) => {
            dispatch({ type: "RANGOS_RENOVACION_ACTIVO_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
        },
        onEstadoRangosRenovacion: (idRangoRenovacion, page) => {
            dispatch({ type: "ESTADO_RANGO_RENOVACION_ACTIVO_REQUEST", idRangoRenovacion: idRangoRenovacion, page: page});
        },
        onModalNuevoRangoRenovacion: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_RANGO_RENOVACION_ACTIVO", showModalNuevoRangoRenovacionActivo: true, page: page })
        },
        onModalEditarRangoRenovacion: (RangoRenovacionActivoSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_RANGO_RENOVACION_ACTIVO", showModalEditarRangoRenovacionActivo: true, RangoRenovacionActivoSeleccionado:RangoRenovacionActivoSeleccionado, page:page});
        },
        onGetUnidadesdeMedicion: (idCategoriaUnidadMedida) => {    
            dispatch({ type: "UNIDADES_MEDI_POR_CATEGORIA_REQUEST", idCategoriaUnidadMedida:idCategoriaUnidadMedida});
        },
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        },
        onExport: () => {
            dispatch({ type: "EXPORTAR_RANGO_RENOVACION_ACTIVO_EXCEL_REQUEST" });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoRangoRenovacionActivo);