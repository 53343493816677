import React, {Component} from 'react'
import { Modal, Spin, Button, Row, Col } from 'antd';
import {ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';


class ModalVerPDFPlanCalidad extends Component {
    state={
        pageNumber: 1,
        numPages: null,
    }
    render() { 
        const {onShowModalVerPDFPlanCalidad, planCalidadSeleccionado, showModalVerPlanCalidad,
        } = this.props;

        const handleCancel = () => {
            onShowModalVerPDFPlanCalidad()
            this.setState({pageNumber: 1})
        }
        const onDocumentLoadSuccess = ({ numPages }) => {
            this.setState({numPages})
        }

        const cambiarPagina = (tipo) => {
            console.log('tipo', tipo)
            if(tipo === 'atras'){
                this.setState({pageNumber: this.state.pageNumber-1})
            }
            else{
                this.setState({pageNumber: this.state.pageNumber+1})
            }
        }
        return ( 
            <Modal
                visible={showModalVerPlanCalidad}
                title='Consulta de Documento'
                onCancel={handleCancel}
                footer={false}
                width={1050}
            >
                {planCalidadSeleccionado ? (
                    <>
                        <Row justify='space-around'>
                            <Col span={24}>
                                <Document
                                    loading={<Spin spinning={true}/>}
                                    file={planCalidadSeleccionado.rutaArchivo} onLoadSuccess={onDocumentLoadSuccess}>
                                    
                                    <Page pageNumber={this.state.pageNumber} width={1000}/>
                                </Document>
                            </Col>
                        </Row>
                        <br/>
                        <Row justify='space-around'>
                            <Col span={5}>
                                <p>
                                    Página {this.state.pageNumber} de {this.state.numPages}
                                </p>
                            </Col>
                            <Col span={2}>
                                <Button className="iconTer" shape="circle" disabled={this.state.pageNumber === 1} onClick={()=>cambiarPagina('atras')} icon={<ArrowLeftOutlined />}/>
                            </Col>
                            <Col span={2}>
                                <Button className="iconTer" shape="circle" disabled={this.state.pageNumber === this.state.numPages} onClick={()=>cambiarPagina('adelante')} icon={<ArrowRightOutlined />}/>
                            </Col>
                        </Row>
                    </>
                ):false}
            </Modal>
         );
    }
}
 
const mapStateToProps = state => {
	return {
        showModalVerPlanCalidad: state.GestionDeCalidadReducer.showModalVerPlanCalidad,
        planCalidadSeleccionado: state.GestionDeCalidadReducer.planCalidadSeleccionado,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalVerPDFPlanCalidad: () => {
            dispatch ({ type: 'MODAL_VER_PLAN_CALIDAD', showModalVerPlanCalidad: false, planCalidadSeleccionado: null })
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalVerPDFPlanCalidad);