import React, { Component } from 'react';
import { Form, Input, Button, Col, Spin, Modal, Select } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalNuevoFamiliasDeActivos extends Component {
    formRef = React.createRef();
    render() {
        const { fetchingNuevoFamiliasDeActivos, showModalNuevoFamiliasDeActivos, onNuevoFamiliasDeActivos, 
            onShowModalNuevoFamiliasDeActivos, listaServiciosActivosF, fetchingListaServiciosActivosF, 
            limpiarFormulario, onLimpiarPartidasByServicio,
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onNuevoFamiliasDeActivos(formulario, listaServiciosActivosF, this.props.page);
        };
        console.log('limpiar formulario')
        console.log(limpiarFormulario)
        if (limpiarFormulario && limpiarFormulario === 'crearFamiliasDeActivos') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
            onLimpiarPartidasByServicio();
        }
        
        const handleCancel = () => {
            onLimpiarPartidasByServicio();
            onShowModalNuevoFamiliasDeActivos();
            this.formRef.current.resetFields();
        }

        const onServicioSeleccionado = (value) => {
            console.log('value', value)
            if(value){
                this.props.onListaPartidasByServicio(parseInt(value));
            }
            else{
                onLimpiarPartidasByServicio();
            }
        }

        return (
            <Modal
            visible={showModalNuevoFamiliasDeActivos}
            title='Nueva Familia'
            onCancel={handleCancel}
            footer={false}
            >
                <Spin spinning={fetchingNuevoFamiliasDeActivos}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Form.Item
                            label="Familia"
                            name="familia"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Input maxLength={30} placeholder="Ingrese nombre de familia, sólo debe contener letras" pattern="^[a-zñA-ZÑ ÁÉÓÚÍáéóíú]+$" title="El nombre sólo debe contener letras"/>
                        </Form.Item>

                        <Form.Item
                            label="Tipo de familia"
                            name="tipoFamilia"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <Select 
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Selecciona el tipo de familia">
                                <Option value="Activos">Activos</Option>
                                <Option value="Productos e insumos">Productos e insumos</Option>
                                <Option value="Herramientas">Herramientas</Option>
                                <Option value="Rutinas">Rutinas</Option>
                            </Select>
                        </Form.Item>
                        
                        <Form.Item
                            label="Servicio"
                            name="idServicio"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Select allowClear
                                style={{ width: '100%' }}
                                placeholder="Selecciona el servicio"
                                loading={fetchingListaServiciosActivosF}
                                onChange={onServicioSeleccionado}
                                showSearch={true}
                            >
                                {listaServiciosActivosF.map(option => <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>{ option.nomenclatura + ': ' + option.nombre }</Option>)}
                            </Select>
                        </Form.Item>
                        {this.props.listaPartidasByServicio.length ? (
                        <Form.Item
                            label="Partida"
                            name="idPartida"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Select allowClear
                                style={{width: '100%'}}
                                placeholder="Selecciona una partida"
                                loading={this.props.fetchingListaPartidasByServicio}
                                showSearch={true}
                            >
                                {this.props.listaPartidasByServicio.map(option => <Option
                                    key={option.idPartida + ' - ' + option.nombre}>{option.nombre}</Option>)}
                            </Select>
                        </Form.Item>
                        ):(
                        <Spin spinning={this.props.fetchingListaPartidasByServicio}>
                            <br/>
                        </Spin>
                        )}
                        <Col span={12}>
                            <Button className="btnFiltrar" htmlType="submit">Crear Familia</Button>
                        </Col>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingNuevoFamiliasDeActivos: state.CatalogosGeneralesReducer.fetchingNuevoFamiliasDeActivos,
        showModalNuevoFamiliasDeActivos: state.CatalogosGeneralesReducer.showModalNuevoFamiliasDeActivos,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        page: state.CatalogosGeneralesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaPartidasByServicio: state.EvaluacionDeLaGestionReducer.listaPartidasByServicio,
        fetchingListaPartidasByServicio: state.EvaluacionDeLaGestionReducer.fetchingListaPartidasByServicio,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalNuevoFamiliasDeActivos: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_FAMILIAS_ACTIVOS', showModalNuevoFamiliasDeActivos: false})
        },
        onNuevoFamiliasDeActivos: ( formulario, listaServicios, page ) => {
            dispatch({ type: "NUEVO_FAMILIAS_ACTIVOS_REQUEST" , formulario: formulario, listaServicios: listaServicios, page: page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        },
        onListaPartidasByServicio: (idServicio) => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_REQUEST', idServicio: idServicio})
        },
        onLimpiarPartidasByServicio: () => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_SERVICIO_SUCCESS', listaPartidasByServicio:[] })
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoFamiliasDeActivos);