import React, { Component } from 'react';
import { Row, Form, Spin, Button, Col, Select, InputNumber, message, Divider } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class PersonalForm extends Component {
    formRef = React.createRef();

    render() {
        const {  limpiarFormulario, onLimpiarFormulario, fetchingListaProductosActivosBusqueda,
            listaPersonalServicio, listaPersonal, onListaPersonalServicio, onListaPersonalServicioLimpiar,
            onActualizarListaSinRepetir, estatusOrdenSPSeleccionado,
            fetchinEditarPersonalSP, listaTareas, revisarTareas
        } = this.props;
        console.log('listaTareas', listaTareas)

        if(limpiarFormulario && limpiarFormulario === 'editarPersonalSP'){
            this.formRef.current.resetFields();
            onLimpiarFormulario();
            this.props.onActualizarListaSinRepetir(null, false);
            this.props.onActualizarRevisarInsumos(null, false);
        }
        let todoReprogramado = true;
        if(revisarTareas){
            for(let tarea of listaTareas){
                if(tarea.fechasRutinasByIdOrdenServicio.estado !== 'Reprogramada'){
                    todoReprogramado = false;
                }
            }
            console.log('todoReprogramado', todoReprogramado)
            if(todoReprogramado){
                if(this.formRef.current){
                    this.formRef.current.resetFields();
                }
                onActualizarListaSinRepetir({personal: []}, false);
            }
        }
        

        const buscarPersonal = (value) => {
            if(value.length>=3){
                console.log(estatusOrdenSPSeleccionado.programacionRutinasByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.rutinaByIdRutina.idServicio)
                onListaPersonalServicio(estatusOrdenSPSeleccionado.programacionRutinasByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.rutinaByIdRutina.idServicio, value);
            }
            else{
                onListaPersonalServicioLimpiar();
            }
        };
        
        const onPersonalSeleccionado = (value) => {
            let listaLocal = listaPersonal.personal;
            let listaIds = [];
            if(listaLocal.length){
                for(let i in listaLocal){
                    listaIds.push(listaLocal[i].split(' - ')[0]);
                }
            }
            if(!listaIds.includes(value.split(' - ')[0])){
                listaIds.push(value.split(' - ')[0]);
                listaLocal.push(value);
                console.log('listaLocal en if', listaLocal)
                let nuevaLista = {personal: listaLocal}
                console.log('nuevaLista', nuevaLista)
                onActualizarListaSinRepetir(nuevaLista, true);
            }
            else{
                message.warning('No es posible seleccionar un personal que ya esté en la lista');
                console.log('listaLocal en else', listaLocal, listaPersonal.personal)
                this.formRef.current.setFieldsValue({'personal': listaPersonal.personal});
            }
        }
        const borrarPersonal = () => {
            const formulario = this.formRef.current.getFieldValue('personal');
            console.log('formulario', formulario)
            let nuevaLista = {personal: formulario}
            onActualizarListaSinRepetir(nuevaLista, true);
        }
        
        // const onFinish = (formulario) => {
        //     console.log('Formulario', formulario);
        //     onEditarPersonal(formulario, estatusOrdenSPSeleccionado, busqueda, page);
        // };
        console.log('listaPersonal', listaPersonal)
    
        return (
            <Form {...layout}
            name="formulario"
            // onFinish={onFinish}
            ref={this.formRef}
            initialValues={listaPersonal}
            >
                <Spin spinning={fetchinEditarPersonalSP}>
                    <Divider orientation="left">Personal</Divider>
                    <Form.List name="personal">
                        {(fields, { add, remove }) => (
                        <>
                        {console.log('fields', fields)}
                            {fields.map(field => (
                            <Row justify='space-between' key={field.key}>
                                <Col span={21}>
                                    <Form.Item
                                        {...field}
                                        label='Nombre del personal'
                                        name={[field.name]}
                                        fieldKey={[field.fieldKey]}
                                        // rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <Select
                                            disabled={todoReprogramado}
                                            style={{ width: '100%' }}
                                            placeholder="Ingresa nombre"
                                            loading={fetchingListaProductosActivosBusqueda}
                                            showSearch
                                            filterOption={false}
                                            onSearch={buscarPersonal}
                                            onChange={onPersonalSeleccionado}
                                            notFoundContent={null}
                                        >
                                            {listaPersonalServicio.map(option => <Option key={option.idPersonal + ' - ' + option.nombreCompleto + ' - ' + option.puesto}>{option.idPersonal + ' - ' + option.nombreCompleto + ' - ' + option.puesto}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    {fields.length > 0 ? (
                                    <Form.Item>
                                        <MinusCircleOutlined disabled={todoReprogramado} className='borrarInsumo' onClick={() => {remove(field.name); borrarPersonal()}} />
                                    </Form.Item>
                                    ):false}
                                </Col>
                            </Row>
                            ))}
                            <Form.Item>
                                <Button type="dashed" disabled={todoReprogramado} onClick={() => add()} block icon={<PlusOutlined />}>
                                    Agregar Personal
                                </Button>
                            </Form.Item>
                        </>
                        )}
                    </Form.List>
                    {/* <Row justify='space-around'>
                        <Col span={11} className='marginBoton'>
                            <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Editar Personal</Button>
                        </Col>  
                    </Row> */}
                </Spin>
            </Form>
        );
    }
}

const mapStateToProps = state => {
	return {
        page: state.ServiciosPlanificadosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        busqueda: state.ServiciosPlanificadosReducer.busqueda,
        estatusOrdenSPSeleccionado: state.ServiciosPlanificadosReducer.estatusOrdenSPSeleccionado,
        formularioRutina: state.ServiciosPlanificadosReducer.formularioRutina,
        listaPersonal: state.ServiciosPlanificadosReducer.listaPersonal, 
        revisarTareas: state.ServiciosPlanificadosReducer.revisarTareas,
        fetchingListaPersonalBusqueda: state.RecursosHumanosReducer.fetchingListaPersonalBusqueda,

        listaPersonalServicio: state.RecursosHumanosReducer.listaPersonalServicio,

        fetchingListaHerramientasActivasBusqueda: state.AlmacenesReducer.fetchingListaHerramientasActivasBusqueda,
        listaHerramientasActivasBusqueda: state.AlmacenesReducer.listaHerramientasActivasBusqueda,
        fetchinEditarPersonalSP: state.ServiciosPlanificadosReducer.fetchinEditarPersonalSP,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onListaPersonalServicio: (idServicio, param) => {
            dispatch({ type: 'LISTA_PERSONAL_SERVICIO_REQUEST', idServicio, param });
        },
        onListaPersonalServicioLimpiar: () => {
            dispatch({ type: 'LISTA_PERSONAL_SERVICIO_SUCCESS', listaPersonalServicio: [] });
        },
        onActualizarListaSinRepetir: (listaPersonal, revisarTareas) => {
            dispatch({ type: "ACTUALIZAR_LISTA_PERSONAL_ESTATUS",  listaPersonal, revisarTareas });
        },
        onEditarPersonal: (formulario, estatusOrdenSPSeleccionado, busqueda, page) => {
            dispatch({ type: 'EDITAR_PERSONAL_SP_REQUEST', formulario, estatusOrdenSPSeleccionado, busqueda, page})
        },
        onActualizarRevisarInsumos: (listaInsumos, revisarInsumos) => {
            dispatch({ type: "ACTUALIZAR_LISTA_INSUMOS_ESTATUS",  listaInsumos, revisarInsumos });
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalForm);