import React, { Component } from 'react';
import { Form, Input, Button, Modal, Row, Col, Spin, InputNumber } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {  labelCol: { span: 24 },  wrapperCol: { span: 24 }, };

class ModalNuevoRangoDeValidacion extends Component {
     formRef = React.createRef();
     state={ modulo: '' }

     render() {
          const { fetchingNuevoRangoValidacion, showModalNuevoRangoValidacion, onNuevoRangoValidacion, limpiarFormulario } = this.props;

          const onFinish = formulario => {
               console.log('Success:', formulario);
               function numberWithCommas(x) {
                    let parts = x.toString().split(".");
                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return parts.join(".");
               }

               if(parseFloat(formulario.limiteInferior) > parseFloat(formulario.limiteSuperior)){
                    Modal.error({
                         title: <p> <strong>Error en Límites</strong> </p>,
                         content: <p> El límite inferior ingresado: <strong style={{color: '#ff7875'}}>$ {numberWithCommas(formulario.limiteInferior)}</strong> no puede ser mayor al límite superior: $ {numberWithCommas(formulario.limiteSuperior)}, favor de verificar. </p>,
                    });
               } else {
                    onNuevoRangoValidacion(formulario, this.props.page, this.props.busqueda, this.props.orden);
               }
               
          };
          

          const handleCancel = () => {
               this.props.onShowModalNuevoRangoDeValidacion();
               this.formRef.current.resetFields();
          }

          if(limpiarFormulario && limpiarFormulario === 'crearRangoDeValidacion'){
               this.formRef.current.resetFields();
               this.props.onLimpiarFormulario();
          };

          return (
               <Modal
                    visible={showModalNuevoRangoValidacion}
                    title='Nuevo Rango de Validación'
                    onCancel={handleCancel}
                    footer={false}
                    width={'50%'}
               >
                    <Spin spinning={fetchingNuevoRangoValidacion}>
                         <Form {...layout} name="formulario" onFinish={onFinish}  ref={this.formRef}>
                              <Row justify='space-around' gutter={[8,8]}>
                                   <Col span={12}>
                                        <Form.Item
                                             label="Nombre del rango de validación"
                                             name="nombre"
                                             rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                             <Input maxLength={80} placeholder="Ingrese nombre del rango" />
                                        </Form.Item>
                                   </Col>

                                   <Col span={6}>
                                        <Form.Item
                                             label="Límite inferior"
                                             name="limiteInferior"
                                             rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                             <InputNumber
                                                  placeholder="Ingresa el límite inferior"
                                                  maxLength={25}
                                                  min={0}
                                                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 
                                                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                             />
                                        </Form.Item>
                                   </Col>

                                   <Col span={6}>
                                        <Form.Item
                                             label="Límite superior"
                                             name="limiteSuperior"
                                             rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                             <InputNumber
                                                  placeholder="Ingresa el límite superior"
                                                  maxLength={25}
                                                  min={0}
                                                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 
                                                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                             />
                                        </Form.Item>
                                   </Col>

                                   <Col span={12}>
                                        <Button style={{width:'100%'}} className="btnFiltrar" htmlType="submit">Crear Rango de Validación</Button>
                                   </Col>
                              </Row>
                         </Form>
                    </Spin>
               </Modal>
          );
     }
}

const mapStateToProps = state => {
	return {
        fetchingNuevoRangoValidacion: state.ConfiguracionYSeguridadReducer.fetchingNuevoRangoValidacion,
        showModalNuevoRangoValidacion: state.ConfiguracionYSeguridadReducer.showModalNuevoRangoValidacion,
        page: state.ConfiguracionYSeguridadReducer.page,
        busqueda: state.ConfiguracionYSeguridadReducer.busqueda,
        orden: state.ConfiguracionYSeguridadReducer.orden,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
	};
};

const mapDispatchToProps = dispatch => {
	return {
          onShowModalNuevoRangoDeValidacion: () => {
               dispatch({ type: 'SHOW_MODAL_NUEVO_RANGO_VALIDACION', showModalNuevoRangoValidacion: false })
          },
          onNuevoRangoValidacion: ( formulario, page, busqueda, orden ) => {
               dispatch({ type: "NUEVO_RANGO_VALIDACION_REQUEST" , formulario, page, busqueda, orden });
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoRangoDeValidacion);