import React, { Component } from 'react';
import { Form, Button, Row, Col, Spin, message, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import {loginCall} from "../../../services/LoginServices";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class VerificarContraseña extends Component {
    state={
        fetchingLogin: false,
    }
    render() { 
        const {
            onCambiarVista, formularioUnidad, editedGeometry,
        } = this.props;

        let userInfo = sessionStorage.getItem('userInfo');
        if(!userInfo.idUsuario){
            userInfo = JSON.parse(userInfo);
        }

        const onFinish = formulario => {
            console.log('formulario');
            console.log(formulario);
            this.setState({
                fetchingLogin: true,
            })
            const userName = userInfo[0].username;
            const values = {
                'userName': userName,
                'password': formulario.password,  
            }
            loginCall(values).then(response => {
                console.log('response');
                console.log(response);
                
                if(response.status === 200){
                    onCambiarVista(3, formularioUnidad, editedGeometry);
                }
                else{
                    let errorMensaje = '';
                    if(response.error_message === 'Bad credentials'){
                        errorMensaje = 'Contraseña incorrecta. Por favor intente de nuevo';
                    }
                    else{
                        errorMensaje = response.error_message;
                    }
                    message.error(errorMensaje);
                    return;
                }
                this.setState({
                    fetchingLogin: false,
                });
            }).catch(error => {
                console.log(error);
                message.error('Hubo un error, favor de intentar después');
                return;
            })
            
        };
        return ( 
            <Spin spinning={this.state.fetchingLogin}>
                <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                >
                    <Row justify='space-around'>
                        <Col span={11}>
                            <Form.Item
                                label="Ingresa tu contraseña"
                                name="password"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Input.Password prefix={<LockOutlined/>} placeholder="Ingresa tu contraseña"/>
                            </Form.Item>
                        </Col>
                        <Col span={11} className='marginBoton'>
                            <br/>
                            <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Verificar Contraseña</Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        formularioUnidad: state.CatalogosGeneralesReducer.formularioUnidad,
        editedGeometry: state.CatalogosGeneralesReducer.editedGeometry
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCambiarVista: (cambiarVistaModalUnidad, formularioUnidad, editedGeometry) => {
            dispatch({ type: "CAMBIAR_VISTA_UNIDAD", cambiarVistaModalUnidad, formularioUnidad, editedGeometry })
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(VerificarContraseña);