import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import { Alert, Button, Row, Col, Steps, Checkbox, Card} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import FilterSection from './FilterSection';
import TableSection from './TableSection';
import RegistrosReportesContainer from './RegistrosReportesContainer';
import ModalNuevoRegistroReporte from './ModalNuevoRegistroReporte';
import ModalEditarRegistroReporte from './ModalEditarRegistroReporte';
const { Step } = Steps;

const ReporteDinamico = props => {

    const {listaReporteDinamicoBusqueda, formularioReporte, onGetReporteDinamico, 
        onLimpiarFormulario, 
    } = props;

    const [state, setState] = useState({page: 1, currentStep:0});
    const [order, setOrder] = useState({field:'ser.Nombre',order:'ASC'});
    const [checkAll, setCheckAll] = useState (false)
    const [checkArray, setCheckArray] = useState([]);
    const [checkbox, setCheckbox] = useState ({
        servicioC: false, gerenciaC: false, centroC: false,
        partidaC: false, proveedorC: false, objetivoC: false, temaC: false, 
        indicadorC: false, valorEsperadoC: false,
        valorRealC: false, fechaEvaluacionC: false
    });
    
    let permisosUsuario = sessionStorage.getItem('permisosUsuario');
    if(permisosUsuario[0]) {
        permisosUsuario = JSON.parse(permisosUsuario);
    }

    useEffect(()=> {
        const arrayCheckbox = checkboxArray();
        setCheckArray(arrayCheckbox);
    },[checkbox]);

    
    const checkboxArray = () => {
        let fieldArray = []
        for (var key in checkbox) {
            if(Object.prototype.hasOwnProperty.call(checkbox, key)) {
               if(checkbox[key] === true){
                   fieldArray.push(key);
               }
            }
        }
        return fieldArray;
    }

    const handleSteps = value => {
        if (value === 'siguiente') return  setState({...state, currentStep: state.currentStep + 1});

        setOrder({field:'ser.Nombre',order:'ASC'});
        setState({page:1, currentStep: state.currentStep -1});
        onLimpiarFormulario(formularioReporte);
    }


    const selectHandle = (event,field) => {
        const checked = event.target.checked
        setCheckAll(false);

        if(checked) return setCheckbox({...checkbox,[field]:event.target.checked })
        setCheckbox({...checkbox,[field]:event.target.checked});
    }

    const selectAll = event => {
        setCheckAll(event.target.checked);
        if (event.target.checked) {
            return setCheckbox({
                servicioC: true, gerenciaC: true, centroC: true,partidaC: true, proveedorC: true, objetivoC: true, 
                temaC: true, indicadorC: true, valorEsperadoC: true,valorRealC: true, fechaEvaluacionC: true});
        }
        setCheckbox({servicioC: false, gerenciaC: false, centroC: false, partidaC: false, proveedorC: false, objetivoC: false,
            temaC: false, indicadorC: false, valorEsperadoC: false, valorRealC: false, fechaEvaluacionC: false})
    }

    const handleTableChange = (pagination, filter, orden) => {
        setState({...state, page: pagination.current});
        console.log(`orden`, orden)
        const orderFields = {gerenciaC:'cg.NombreGerencia', centroC:'cc.CentroCosto', servicioC:'ser.Nombre',
            partidaC:'part.Nombre', proveedorC:'pro.Nombre', objetivoC: 'obj.Objetivo', temaC: 'tem.tema', indicadorC:' id.IndicadorDesempeño',
            valorEsperadoC: 'id.ValorEsperado', valorRealC: 'ei.ValorReal', mesC:'meses.Mes', anioC: 'ei.Anio'
        }

        if(orden) {
            const field = orden.columnKey ? orderFields[orden.columnKey] : 'ser.Nombre';
            const orderField = orden.order === 'descend' ? 'DESC': 'ASC';
            const sorter = {field: field, order: orderField}
            setOrder({field: field, order: orderField});
            console.log(`sorter`, sorter)
            return onGetReporteDinamico(formularioReporte, pagination.current, checkbox, sorter, permisosUsuario.especialEvaluaciónDeLaGestión );
        }
        
        onGetReporteDinamico(formularioReporte, pagination.current, checkbox, order, permisosUsuario.especialEvaluaciónDeLaGestión);
    }

    const checkFields = [{field: 'gerenciaC', title: 'Gerencia'}, {field:'centroC', title:'Centro de costos'},
    {field:'servicioC', title: 'Servicio'},{field: 'partidaC', title: 'Partida'},
    {field: 'proveedorC', title:'Proveedor'}, {field:'objetivoC', title: 'Objetivo'}, {field:'temaC', title: 'Tema'},
    {field:'indicadorC', title:'Indicador de desempeño'}, {field:'valorEsperadoC', title:'Valor esperado'},
    {field: 'valorRealC', title: 'Valor real'}, {field:'fechaEvaluacionC', title: 'Mes evaluado'}];
    
    const SelectSection = () => {
        return (
            <>
                <Row>
                    <Col span={24}>
                        <Alert message="Selecciona las columnas a incluir en el reporte." type="info" style={{ marginBottom: '16px' }} />
                    </Col>
                </Row>
                <Row style={{ width: '100%', textAlign: 'left', marginBottom: '10px' }}>
                    <Col span={11}>
                        <Checkbox defaultChecked={checkAll}  onChange={selectAll} key='todos'><strong>Todas</strong></Checkbox>
                    </Col>
                </Row>
                <Row style={{ width: '100%', textAlign: 'left' }}>
                    {checkFields.map( item => {
                        return (
                            <Col key={item.field} span={11}>
                                <Checkbox defaultChecked={checkbox[item.field]} onChange={(event)=>selectHandle(event,item.field)} key={item.field}>{item.title}</Checkbox>
                            </Col>
                        )
                    })}
                </Row>
                <Row justify='end'>
                    <Col span={6}>
                        <Button className="btnFiltrar" disabled={!checkArray.length>0} onClick={()=> handleSteps('siguiente')} style={{ width: '100%', marginTop: '20px' }}>Siguiente</Button>
                    </Col>
                </Row>
            </>
        )
    }
    
    const dataProps = { checkbox, checkArray, state, order}
    const handleProps = {handleTableChange , handleSteps}
    return (
        <Card className="cardCatalogos" title={<div className='headerLista'>Reporte Dinámico para Evaluación de la Gestión</div>}>
            <Steps
                type='navigation'
                size='small'
                current={state.currentStep}
                style={{marginBottom:'20px', paddingTop:'0px'}}
            >
                <Step status={state.currentStep>0? 'finish':'process'} title='Seleccionar Columnas' />
                <Step status={state.currentStep>1? 'finish':'process'} title='Filtrar' />
                <Step status={listaReporteDinamicoBusqueda.length>0? 'finish':'process'} title='Exportar Reporte' />
            </Steps>
            {state.currentStep === 0 ? <SelectSection/> : false}
            {state.currentStep === 1 ? <FilterSection handleSteps={handleSteps} checkboxData={checkbox} /> : false}
            {state.currentStep === 2 ? <TableSection handlers={handleProps} data={dataProps}/> : false}
            {state.currentStep === 0 ?<RegistrosReportesContainer/> : false}
            <ModalNuevoRegistroReporte/>
            <ModalEditarRegistroReporte />
        </Card>
    )
}

const mapStateToProps = state => {
    return {
        listaReporteDinamicoBusqueda: state.SolicitudDeServiciosReducer.listaReporteDinamicoBusqueda,
        formularioReporte: state.EvaluacionDeLaGestionReducer.formularioReporte,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetReporteDinamico: (formulario, page, checkboxData, order, especial) => {
            dispatch({ type: 'LISTA_REPORTE_DINAMICO_REQUEST', formulario, page, checkboxData, order, especial});
        },
        onLimpiarFormulario: (formularioReporte) => {
            dispatch({ type: 'LIMPIAR_FORMULARIO_REPORTE', formularioReporte: formularioReporte, listaReportesDinamico: [], listaReportesDinamicoCantidad: 0})
        },
    }
}

export default connect (mapStateToProps, mapDispatchToProps) (ReporteDinamico);