import React from 'react';
import {connect} from 'react-redux';
import {Form, Row, Col, Button, Modal, Spin, Input, Divider, Checkbox, Select, DatePicker, Switch, message, Tooltip, TimePicker, Radio} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalEditarClasificacion extends React.Component {
     state={   boolGrupo: true, boolClasificacion: true, }
     formRef = React.createRef();
     render() {
          const {showModalEditarRegistro, fetchingEditRegistros, page, onActualizarRegistro,
               onShowModalRegistroReporte, onEditarRegistroReporte, registroReporteSeleccionado, personal

          } = this.props;
          
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
               permisosUsuario = JSON.parse(permisosUsuario);
          }

          let userInfo = sessionStorage.getItem('userInfo');
          if(userInfo[0]) {
               userInfo = JSON.parse(userInfo);
          }

          const onFinish = (formulario) => {
               onEditarRegistroReporte(formulario, page, personal, registroReporteSeleccionado);
               this.setState({boolGrupo: true, boolClasificacion: true,});    
          }

          const handleCancel = () => {
               onShowModalRegistroReporte(page, personal);
               this.formRef.current.resetFields();
               this.setState({boolGrupo: true, boolClasificacion: true,});
          }

          const selectAll = (value) => {
               const registroReporteLocal = registroReporteSeleccionado;
               if(value.target.checked){
                    onActualizarRegistro(null, page, personal)
                    for(let i in registroReporteLocal.checkboxData){
                         registroReporteLocal.checkboxData[i][1] = true;
                    }
                    // for(let i in registroReporteLocal.listaFields){
                    //      registroReporteLocal[registroReporteLocal.listaFields[i].key] = true;
                    //      registroReporteLocal.fechaEvaluacionC = true;
                    //      registroReporteLocal.listaFields[i].mostrar = true;
                    // }

                    registroReporteLocal.numeroEmpleadoC=true;
                    registroReporteLocal.nombreC=true;
                    registroReporteLocal.aPaternoC=true;
                    registroReporteLocal.aMaternoC=true;
                    registroReporteLocal.rfcC=true;
                    registroReporteLocal.folioIDC=true;
                    registroReporteLocal.nombreProveedorC=true;
                    registroReporteLocal.nombreServicioC=true;
                    registroReporteLocal.correoC=true;
                    registroReporteLocal.puestoC=true;
                    registroReporteLocal.fechaIngresoC=true;
                    registroReporteLocal.tipoTurnoC=true;
                    registroReporteLocal.diasLaboralesC=true;
                    registroReporteLocal.horarioEntradaC=true;
                    registroReporteLocal.horarioSalidaC=true;
                    registroReporteLocal.salidaComidaC=true;
                    registroReporteLocal.regresoComidaC=true;
                    registroReporteLocal.expedienteClaveC=true;
                    registroReporteLocal.activoC=true;
                    registroReporteLocal.razonBajaC=true;
                    
                    registroReporteLocal.checkAll = true;
                    onActualizarRegistro(registroReporteLocal, page, personal)
               }
               else {
                    onActualizarRegistro(null, page, personal)
                    for(let i in registroReporteLocal.checkboxData){
                         registroReporteLocal.checkboxData[i][1] = false;
                    }
                    // for(let i in registroReporteLocal.listaFields){
                    //      registroReporteLocal[registroReporteLocal.listaFields[i].key] = false;
                    //      registroReporteLocal.fechaEvaluacionC = false;
                    //      registroReporteLocal.listaFields[i].mostrar = false;
                    // }

                    registroReporteLocal.numeroEmpleadoC=false;
                    registroReporteLocal.nombreC=false;
                    registroReporteLocal.aPaternoC=false;
                    registroReporteLocal.aMaternoC=false;
                    registroReporteLocal.rfcC=false;
                    registroReporteLocal.folioIDC=false;
                    registroReporteLocal.nombreProveedorC=false;
                    registroReporteLocal.nombreServicioC=false;
                    registroReporteLocal.correoC=false;
                    registroReporteLocal.puestoC=false;
                    registroReporteLocal.fechaIngresoC=false;
                    registroReporteLocal.tipoTurnoC=false;
                    registroReporteLocal.diasLaboralesC=false;
                    registroReporteLocal.horarioEntradaC=false;
                    registroReporteLocal.horarioSalidaC=false;
                    registroReporteLocal.salidaComidaC=false;
                    registroReporteLocal.regresoComidaC=false;
                    registroReporteLocal.expedienteClaveC=false;
                    registroReporteLocal.activoC=false;
                    registroReporteLocal.razonBajaC=false;

                    registroReporteLocal.checkAll = false;
                    this.formRef.current.setFieldsValue({'field': undefined});
                    onActualizarRegistro(registroReporteLocal, page, personal)
               }
          }
          const changeColumna = (value, columna) => {
               const registroReporteLocal = registroReporteSeleccionado;
               onActualizarRegistro(null, page, personal);
               let checkAll = true;
               registroReporteLocal[columna] = value.target.checked;
               for(let i in registroReporteLocal.listaFields){
                    if(registroReporteLocal.listaFields[i].key === columna){
                         if(registroReporteLocal.listaFields[i].campo === registroReporteLocal.field){
                         this.formRef.current.setFieldsValue({'field': undefined});
                         }
                         registroReporteLocal.listaFields[i].mostrar = value.target.checked;
                    }
               }
               for(let i in registroReporteLocal.checkboxData){
                    if(registroReporteLocal.checkboxData[i][0] === columna){
                         registroReporteLocal.checkboxData[i][1] = value.target.checked;
                    }
               }
               for(let i in registroReporteLocal.checkboxData){
                    if(!registroReporteLocal.checkboxData[i][1]){
                         checkAll = false;
                    }
               }
               registroReporteLocal.checkAll = checkAll;

               console.log('registroReporteLocal', registroReporteLocal)
               onActualizarRegistro(registroReporteLocal, page, personal);
          };

          const buscarProveedor = value => {
               if(value.length>=3){
                    if(value){
                       this.props.onListaProveedoresFiltro(value)
                    } else {
                       message.warning('No se encontró proveedor con esas palabras')
                    }
               } else {
                   this.props.onListaProveedoresFiltroLimpiar();
               } 
          };

          const onGrupoSeleccionado = (value) => {
               this.formRef.current.setFieldsValue({'IdsGerencias': undefined});
               if(value.length > 0){
                    this.formRef.current.setFieldsValue({'IdsServicios': undefined});
                    this.setState({ boolGrupo: false });
                    this.props.onListaServiciosByIdsGrupos(value);
                    this.props.onListaServiciosByIdsGerenciasLimpiar();
               } else {
                    this.setState({ boolGrupo: true });
                    this.formRef.current.setFieldsValue({'idsGruposServicios': undefined, 'IdsServicios': undefined});
                    this.props.onListaServiciosByIdsGruposLimpiar();
               }
          };
     
          const onClasificacionSeleccionado = (value) => {
               this.formRef.current.setFieldsValue({'idsGruposServicios': undefined});
               if(value.length > 0){
                    this.formRef.current.setFieldsValue({'IdsServicios': undefined});
                    this.setState({boolClasificacion: false});
                    this.props.onListaServiciosByIdsGerencias(value);
                    this.props.onListaServiciosByIdsGruposLimpiar();
               } else {
                    this.setState({boolClasificacion: true});
                    this.formRef.current.setFieldsValue({'IdsGerencias': undefined, 'IdsServicios': undefined});
                    this.props.onListaServiciosByIdsGerenciasLimpiar();
               }
          };
          
          return (
               <div>
                    <Modal
                         visible={showModalEditarRegistro}
                         title='Editar Registro Predeterminado'
                         onCancel={handleCancel}
                         footer={false}
                         width='75%'
                    >
                         {registroReporteSeleccionado ? (
                         <Spin spinning={fetchingEditRegistros}>
                         <Form {...layout}
                              name='formulario'
                              onFinish={onFinish}
                              ref={this.formRef}
                              initialValues={registroReporteSeleccionado}
                         >
                              <Row type='flex' justify='space-around' gutter={[8,8]}>
                                   <Col span={12}>
                                        <Form.Item
                                             label='Título del documento'
                                             name='titulo'
                                             rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                             >
                                             <Input maxLength={200} placeholder='Ingresa el título del documento' style={{ width: '100%' }} />
                                        </Form.Item>
                                   </Col>
                                   <Col span={12}>
                                        <Form.Item
                                             label='Nombre'
                                             name='nombre'
                                             rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                             >
                                             <Input maxLength={100} placeholder='Ingresa el nombre' style={{ width: '100%' }} />
                                        </Form.Item>
                                   </Col>
                                   
                                   <Col span={24}>
                                        <Form.Item
                                             label='Descripción'
                                             name='descripcion'
                                             >
                                             <TextArea autoSize maxLength={450} placeholder='Ingresa la descripción' style={{ width: '100%' }} />
                                        </Form.Item>
                                   </Col>

                                   <Divider orientation='left'>Columnas</Divider>
                                   <Row style={{ width: '100%', textAlign: 'left', marginBottom: '2em' }}>
                                        <Col span={24}>
                                             <Form.Item name='checkboxColumns'>
                                                  <Checkbox onChange={selectAll} checked={registroReporteSeleccionado.checkAll}><strong>Todas</strong></Checkbox>
                                             </Form.Item>
                                        </Col>
                                   </Row>

                                   { registroReporteSeleccionado.checkboxData ? (
                                   <Row style={{ width: '100%', textAlign: 'left' }}>
                                        {registroReporteSeleccionado.checkboxData.map( item => 
                                        <Col key={item[0]} span={6}>
                                             <Form.Item
                                             name={item[0]}
                                             >
                                             <Checkbox checked={item[1]} onChange={e => changeColumna(e, item[0])}>{item[2]}</Checkbox>
                                             </Form.Item>
                                        </Col>
                                        )}
                                   </Row>
                                   ) : false }

                                   <Divider orientation='left'>Filtros</Divider>
                                   
                                   <Col span={24}>
                                        <Row justify='space-between' gutter={[8,8]} style={{padding:'1em'}}>
                                             <Col span={12}>
                                                  <Form.Item
                                                  label="Gerencia"
                                                  name="IdsGerencias"
                                                  rules={[{ required: false, message: 'Favor de llenar el campo' }]}
                                                  >
                                                       {permisosUsuario.especialRecursosHumanos ? (
                                                       <Select
                                                            allowClear
                                                            style={{ width: '100%' }} 
                                                            placeholder="Selecciona las gerencias"
                                                            loading={this.props.fetchingListaClasificacionesActivos}
                                                            mode='multiple'
                                                            onChange={onClasificacionSeleccionado}
                                                            disabled={!this.state.boolGrupo}
                                                       >
                                                            {this.props.listaClasificacionesActivos.map(item => <Option key={item.idClasificacionGerencia + ' - ' + item.nombreGerencia}>{item.idClasificacionGerencia + ' - ' + item.nombreGerencia}</Option>)}
                                                       </Select>
                                                       ):(
                                                       <Select allowClear
                                                            style={{ width: '100%' }}
                                                            placeholder="Selecciona una gerencia"
                                                            loading={this.props.fetchingListaGerenciasByIdUsuario}
                                                            mode='multiple'
                                                            onChange={onClasificacionSeleccionado}
                                                            disabled={!this.state.boolGrupo}
                                                       >
                                                            {this.props.listaGerenciasByIdUsuario.map(option => 
                                                            <Option key={option.idClasificacionGerencia + ' - ' + option.nombreGerencia}>
                                                                 <Tooltip title={option.idClasificacionGerencia + ' - ' + option.nombreGerencia}>
                                                                      {option.idClasificacionGerencia + ' - ' + option.nombreGerencia}
                                                                 </Tooltip>
                                                            </Option>
                                                            )}
                                                       </Select>
                                                       )}
                                                  </Form.Item>
                                             </Col>

                                             <Col span={12}>
                                                  <Form.Item
                                                       name='idsGruposServicios'
                                                       label='Grupo de Servicios'
                                                       rules={[{ required: false, message: 'Favor de llenar el campo' }]}
                                                  >
                                                       {permisosUsuario.especialRecursosHumanos ? (
                                                       <Select
                                                            allowClear
                                                            style={{ width: '100%' }} 
                                                            placeholder="Selecciona un grupo de servicios"
                                                            loading={this.props.fetchingListaGruposServicios}
                                                            mode='multiple'
                                                            onChange={onGrupoSeleccionado}
                                                            disabled={!this.state.boolClasificacion}
                                                       >
                                                            {this.props.listaGruposServicios.map(option => <Option key={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}><Tooltip title={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}>{option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}</Tooltip></Option>)}
                                                       </Select>
                                                       ):(
                                                       <Select
                                                            mode='multiple'
                                                            allowClear
                                                            style={{ width: '100%' }} placeholder="Selecciona un grupo de servicios"
                                                            loading={this.props.fetchingListaGrupoServiciosByIdUsuario}
                                                            onChange = {onGrupoSeleccionado}
                                                            disabled={!this.state.boolClasificacion}
                                                            // disabled={!this.formRef.current.getFieldValue('IdsGerencias')}
                                                       >
                                                            {this.props.listaGrupoServiciosByIdUsuario.map(option => <Option key={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}><Tooltip title={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}>{option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}</Tooltip></Option>)}
                                                       </Select>
                                                       )}
                                                  </Form.Item>
                                             </Col>

                                             <Col span={12}>
                                                  <Form.Item
                                                       name='IdsServicios'
                                                       label='Servicio'
                                                       rules={[{ required: true, message: 'Favor de seleccionar un servicio', }]}
                                                  >
                                                       { this.props.serviciosByIdUsuarioGrupos.length > 0 || this.props.listaServiciosByIdsGrupos.length > 0 ? (
                                                       <Select
                                                            mode='multiple'
                                                            allowClear
                                                            style={{ width: '100%' }} 
                                                            placeholder="Selecciona un servicio"
                                                            // disabled={this.state.boolGrupo && this.state.boolClasificacion}
                                                            loading={this.state.boolClasificacion ? this.props.fetchingListaServiciosByIdsGrupos : this.props.fetchingListaServiciosByIdsGerencias}
                                                            onChange={() => this.props.onListaServiciosByIdsGerenciasLimpiar()}
                                                       >
                                                            {permisosUsuario.especialGestiónDeCalidad ? (
                                                            <>
                                                                 {this.props.listaServiciosByIdsGrupos.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nombre}</Option>)}
                                                            </>
                                                            ):(
                                                            <>
                                                                 {this.props.serviciosByIdUsuarioGrupos.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nombre}</Option>)}
                                                            </>
                                                            ) }
                                                       </Select>
                                                       ):(
                                                       <Select
                                                       mode='multiple'
                                                       allowClear
                                                       style={{ width: '100%' }} 
                                                       placeholder="Selecciona un servicio"
                                                       // disabled={this.state.boolGrupo && this.state.boolClasificacion}
                                                       loading={this.state.boolClasificacion ? this.props.fetchingListaServiciosByIdsGrupos : this.props.fetchingListaServiciosByIdsGerencias}
                                                       onChange={() => this.props.onListaServiciosByIdsGruposLimpiar()}
                                                       >
                                                           {permisosUsuario.especialGestiónDeCalidad ? (
                                                            <>
                                                                 {this.props.listaServiciosByIdsGerencias.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nombre}</Option>)}
                                                            </>
                                                            ):(
                                                            <>
                                                                 {this.props.serviciosByIdUsuarioGerencia.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nombre}</Option>)}
                                                            </>
                                                            ) }
                                                            
                                                       </Select>
                                                       ) }
                                                  </Form.Item>
                                             </Col>
                                             
                                             {registroReporteSeleccionado.nombreProveedorC ? (
                                             <Col span={12}>
                                                  <Form.Item
                                                       name='idsProveedores'
                                                       label='Nombre del proveedor'
                                                  >
                                                       <Select
                                                            mode='multiple'
                                                            allowClear
                                                            style={{ width: '100%' }}
                                                            placeholder="Escribe el proveedor a buscar"
                                                            loading={this.props.fetchingListaParamProveedores}
                                                            showSearch={true}
                                                            filterOption={false}
                                                            onSearch={buscarProveedor}
                                                            notFoundContent={null}
                                                       >
                                                            {this.props.listaParamProveedores.map(option => <Option key={option.idProveedor + ' - ' + option.razonSocial + ' (' + option.nombre + ')'}>{option.idProveedor + ' - ' + option.razonSocial + ' (' + option.nombre + ')'}</Option>)}
                                                       </Select>
                                                  </Form.Item>
                                             </Col>
                                             ):false}

                                             {registroReporteSeleccionado.fechaIngresoC ? (
                                             <Col span={12}>
                                                  <Form.Item
                                                       label="Fecha ingreso"
                                                       name="fechaIngreso"
                                                  >
                                                       <RangePicker
                                                            format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio", "Fecha fin"]}
                                                            // disabledDate={disabledRange}
                                                       />
                                                  </Form.Item>
                                             </Col>
                                             ) : false}

                                             { registroReporteSeleccionado.tipoTurnoC ? (
                                             <Col span={12}>
                                                  <Form.Item
                                                       label="Tipo de turno"
                                                       name="tipoHorario"
                                                  >
                                                       <Select
                                                            allowClear
                                                            style={{ width: '100%' }}
                                                            placeholder="Selecciona el tipo de horario"
                                                       >
                                                            <Option key='Corrido'>Corrido</Option>
                                                            <Option key='Libre'>Libre</Option>
                                                       </Select>
                                                  </Form.Item>
                                             </Col>
                                             ): false }

                                             { registroReporteSeleccionado.horarioEntradaC || registroReporteSeleccionado.horarioSalidaC ? (
                                             <Col span={12}>
                                                  <Row justify='space-between' gutter={[8,8]}>
                                                       { registroReporteSeleccionado.horarioEntradaC ? (
                                                       <Col span={12}>
                                                            <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                                                 <Form.Item
                                                                      label="Horario entrada"
                                                                      name="horarioEntrada"
                                                                 >
                                                                      <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de entrada"]} style={{width: '100%'}}/>
                                                                 </Form.Item>
                                                            </Tooltip>
                                                       </Col>
                                                       ): false }
                                                       { registroReporteSeleccionado.horarioSalidaC ? (
                                                       <Col span={12}>
                                                            <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                                                 <Form.Item
                                                                      label="Horario salida"
                                                                      name="horarioSalida"
                                                                 >
                                                                      <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de salida"]} style={{width: '100%'}}/>
                                                                 </Form.Item>
                                                            </Tooltip>
                                                       </Col>
                                                       ): false }
                                                  </Row>
                                             </Col>
                                             ): false }

                                             { registroReporteSeleccionado.salidaComidaC || registroReporteSeleccionado.regresoComidaC ? (
                                             <Col span={12}>
                                                  <Row justify='space-between' gutter={[8,8]}>
                                                       { registroReporteSeleccionado.salidaComidaC ? (
                                                       <Col span={12}>
                                                            <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                                                 <Form.Item
                                                                      label="Salida Comida"
                                                                      name="salidaComida"
                                                                 >
                                                                      <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de entrada"]} style={{width: '100%'}}/>
                                                                 </Form.Item>
                                                            </Tooltip>
                                                       </Col>
                                                       ): false }
                                                       { registroReporteSeleccionado.regresoComidaC ? (
                                                       <Col span={12}>
                                                            <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                                                 <Form.Item
                                                                      label="Regreso comida"
                                                                      name="regresoComida"
                                                                 >
                                                                      <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de salida"]} style={{width: '100%'}}/>
                                                                 </Form.Item>
                                                            </Tooltip>
                                                       </Col>
                                                       ): false }
                                                  </Row>
                                             </Col>
                                             ): false }

                                             { registroReporteSeleccionado.activoC ? (
                                                  <Col span={12}>
                                                       <Form.Item
                                                            label="Condición"
                                                            name="activoCondicion"
                                                       >
                                                            <Select
                                                                 allowClear
                                                                 style={{ width: '100%' }}
                                                                 placeholder="Selecciona un estatus"
                                                            >
                                                                 <Option key='Activo'>Activo</Option>
                                                                 <Option key='Inactivo'>Inactivo</Option>
                                                            </Select>
                                                       </Form.Item>
                                                  </Col>
                                             ): false }

                                             <Col span={12}>
                                                  <Form.Item label='Agrupamiento' name='tipoAgrupado'>
                                                       <Radio.Group defaultValue={registroReporteSeleccionado.tipoAgrupado} size="small">
                                                            <Radio.Button value="Por gerencia">Gerencia</Radio.Button>
                                                            <Radio.Button value="Por grupo de servicios">Grupo Servicios</Radio.Button>
                                                            { registroReporteSeleccionado.nombreServicioC ? (<Radio.Button value="Por servicios">Servicio</Radio.Button>): false }
                                                            { registroReporteSeleccionado.activoC ? (<Radio.Button value="Por estado">Condición</Radio.Button>): false }
                                                            <Radio.Button value='SinAgrupar'>Sin Agrupamiento</Radio.Button>
                                                       </Radio.Group>
                                                  </Form.Item>
                                             </Col>
                                        </Row>

                                        <Divider orientation='left'>Ordenación</Divider>
                                        <Row justify='space-around' gutter={[8,8]} align='middle'>
                                             <Col span={18}>
                                                  <Form.Item name='field'>
                                                       <Radio.Group defaultValue={registroReporteSeleccionado.field} size="small">
                                                            { registroReporteSeleccionado.numeroEmpleadoC ? (<Radio.Button value="pr.numeroEmpleado">Número de empleado</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.nombreC ? (<Radio.Button value="pr.nombre">Nombre</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.aPaternoC ? (<Radio.Button value="pr.aPaterno">Apellido paterno</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.aMaternoC ? (<Radio.Button value="pr.aMaterno">Apellido materno</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.rfcC ? (<Radio.Button value="pr.rfc">RFC</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.folioIDC ? (<Radio.Button value="pr.folioID">Folio ID</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.nombreProveedorC ? (<Radio.Button value="pro.nombre">Proveedor</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.nombreServicioC ? (<Radio.Button value="ser.nombre">Servicio</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.correoC ? (<Radio.Button value="pr.correo">Correo</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.puestoC ? (<Radio.Button value="pr.puesto">Puesto</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.fechaIngresoC ? (<Radio.Button value="pr.fechaIngreso">Fecha ingreso</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.tipoTurnoC ? (<Radio.Button value="pr.tipoTurno">Tipo turno</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.diasLaboralesC ? (<Radio.Button value="pr.diasLaborales">Días laborales</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.horarioEntradaC ? (<Radio.Button value="pr.horarioEntrada">Horario entrada</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.horarioSalidaC ? (<Radio.Button value="pr.horarioSalida">Horario salida</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.salidaComidaC ? (<Radio.Button value="pr.salidaComida">Salida comida</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.regresoComidaC ? (<Radio.Button value="pr.regresoComida">Regreso comida</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.expedienteClaveC ? (<Radio.Button value="ex.clave">Clave expediente</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.activoC ? (<Radio.Button value="pr.activo">Condición</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.razonBajaC ? (<Radio.Button value="pr.razonBaja">Razón de baja</Radio.Button>) : false }
                                                       </Radio.Group>
                                                  </Form.Item>
                                             </Col>
                                             <Col span={6}>
                                                  <Form.Item name='order'>
                                                       <Radio.Group defaultValue={registroReporteSeleccionado.order} size="small">
                                                            <Radio.Button value="ASC">Ascendente</Radio.Button>
                                                            <Radio.Button value="DESC">Descendente</Radio.Button>
                                                       </Radio.Group>
                                                  </Form.Item>
                                             </Col>
                                        </Row>
                                   </Col>

                                   <Col span={11} className='marginBoton'>
                                        <br/>
                                        <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Editar Registro Predeterminado</Button>
                                   </Col>
                              </Row>
                         </Form>
                         </Spin>
                         ):false}
                    </Modal>
               </div>
          )
     }
}

const mapStateToProps = state => {
     return {
          showModalEditarRegistro: state.RecursosHumanosReducer.showModalEditarRegistro,
          fetchingEditRegistros: state.RecursosHumanosReducer.fetchingEditRegistros,
          page: state.RecursosHumanosReducer.page,
          personal: state.RecursosHumanosReducer.personal,
          registroReporteSeleccionado: state.RecursosHumanosReducer.registroReporteSeleccionado,



          listaParamProveedores: state.InformacionOperativaReducer.listaParamProveedores,
          fetchingListaParamProveedores: state.InformacionOperativaReducer.fetchingListaParamProveedores,

          listaGrupoServiciosByIdUsuario: state.RecursosHumanosReducer.listaGrupoServiciosByIdUsuario,
          fetchingListaGrupoServiciosByIdUsuario: state.RecursosHumanosReducer.fetchingListaGrupoServiciosByIdUsuario,

          fetchingListaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.fetchingListaClasificacionesActivos,
          listaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.listaClasificacionesActivos,

          listaServiciosByIdsGerencias: state.CatalogosGeneralesReducer.listaServiciosByIdsGerencias,
          fetchingListaServiciosByIdsGerencias: state.CatalogosGeneralesReducer.fetchingListaServiciosByIdsGerencias,

          fetchingListaGruposServicios: state.CatalogosGeneralesReducer.fetchingListaGruposServicios,
          listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,

          fetchingListaGerenciasByIdUsuario: state.RecursosHumanosReducer.fetchingListaGerenciasByIdUsuario,
          listaGerenciasByIdUsuario: state.RecursosHumanosReducer.listaGerenciasByIdUsuario,

          listaServiciosByIdsGrupos: state.CatalogosGeneralesReducer.listaServiciosByIdsGrupos,
          fetchingListaServiciosByIdsGrupos: state.CatalogosGeneralesReducer.fetchingListaServiciosByIdsGrupos,

          serviciosByIdUsuarioGrupos: state.CatalogosGeneralesReducer.serviciosByIdUsuarioGrupos,
          serviciosByIdUsuarioGerencia: state.CatalogosGeneralesReducer.serviciosByIdUsuarioGerencia,
     }
}

const mapDispatchToProps = dispatch => {
     return {
          onShowModalRegistroReporte: (page, personal) => {
               dispatch({ type: "MODAL_EDITAR_REGISTRO", showModalEditarRegistro: false, page, personal});
          },
          onEditarRegistroReporte: (formulario, page, personal, registroReporteSeleccionado) => {
               dispatch({ type: 'EDIT_REGISTROS_REQUEST', formulario, page, personal, registroReporteSeleccionado });
          },
          onActualizarRegistro: (registroReporteSeleccionado, page, personal) => {
               dispatch({ type: "MODAL_EDITAR_REGISTRO", showModalEditarRegistro: true, registroReporteSeleccionado, page, personal });
          },
          onListaProveedoresFiltro: (busqueda) => {
               dispatch({ type: "LISTA_PROVEEDORES_PARAM_REQUEST", busqueda })
          },
          onListaProveedoresFiltroLimpiar: () => {
               dispatch({ type: "LISTA_PROVEEDORES_PARAM_SUCCESS", listaParamProveedores: []})
          },
          onListaServiciosByIdsGerencias: (idsGerencias) => {
               dispatch({ type: 'LISTA_SERVICIOS_BY_IDGERENCIAS_REQUEST', idsGerencias });
          },
          onListaServiciosByIdsGrupos: (idsGruposSer) => {
               dispatch({ type: 'LISTA_SERVICIOS_BY_IDSGRUPOS_REQUEST', idsGruposSer });
          },

          onListaServiciosByIdsGerenciasLimpiar: () => {
               dispatch({ type: 'LISTA_SERVICIOS_BY_IDGERENCIAS_SUCCESS', listaServiciosByIdsGerencias: [], serviciosByIdUsuarioGerencia: [] });
          },
          onListaServiciosByIdsGruposLimpiar: () => {
               dispatch({ type: 'LISTA_SERVICIOS_BY_IDSGRUPOS_SUCCESS', listaServiciosByIdsGrupos: [], serviciosByIdUsuarioGrupos: [] });
          },
     }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalEditarClasificacion);