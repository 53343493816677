import React from 'react';
import { Form, Spin, Row, Col, Table, Tooltip, Menu, Button, Collapse, Typography, Descriptions, Select, message, Empty, Result} from 'antd';
import { connect } from "react-redux";
import '../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { FileExcelOutlined, FilePdfOutlined, EyeOutlined, FileSearchOutlined, SearchOutlined, SelectOutlined } from '@ant-design/icons';

import { Equation,  defaultErrorHandler  } from "react-equation";

const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
const { SubMenu } = Menu;
const { Panel } = Collapse;
const {Text} = Typography;
const {Option} = Select;

class RelacionDeducciones extends React.Component {
     formRef = React.createRef();
     
     componentDidMount(){ 
          this.props.onGetListaGrupoSerForRMDP(); this.props.onGetGruposServiciosActivos();
          
          this.props.onGetListaTiposFallasActivas();
     }

     state={ currentMenu: null, nombreGrupoSer: '', nombreServicio: '', page: 1, idServicio: '', claveTipoFalla: null, dataVariablesBase: [], dataDetalleDeductiva: [], dataDeducctionTotal: [], idTipoFalla: null }
 
     render() {
          const { listaGruposServiciosRMDP, fetchingListaServiciosFiltro, listaServiciosFiltro, onListaServiciosFiltro } = this.props;
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){ permisosUsuario = JSON.parse(permisosUsuario); }

          const onFinish = (formulario) => {
               console.log('Success:', formulario);
          };

          const handleTableChange = (pagination) => {
               this.setState({ page: pagination.current });
               this.props.onGetRelacion(this.state.idServicio, this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, pagination.current, 'ASC', 'idSolicitudServicio', this.state.idTipoFalla);
          };

          const onDesgloce = (e) => {
               console.log('e', e);

               function numberWithCommas(x) {
                    let parts = x.toString().split(".");
                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return parts.join(".");
               }

               let pmiFx = '0.00'; if(e.pmi){pmiFx = numberWithCommas(e.pmi.toFixed(2))}
               let pmrFx = '0.00'; if(e.pmr){pmrFx = numberWithCommas(e.pmr.toFixed(2))}
               let fsaFx = e.fsa.toFixed(2);
               
               let dataVariablesBase = [
                    { label: <b><Tooltip title="Número de días del mes i">dm</Tooltip></b>, data: e.dmi},
                    { label: <b><Tooltip title='Pago Mensual de Inversión'>PMI</Tooltip></b>, data: pmiFx, align: 'center' },
                    { label: <b><Tooltip title='Pago Mensual de Referencia'>PMR</Tooltip></b>, data: pmrFx, align: 'center' },
               ];

               let dataDetalleDeductiva = [];
               let dataDeducctionTotal = [];
               
               if(e.claveTipoFalla === 'FLCL'){
                    let tipoDeductiva = 'Definitiva'; if(e.deductivaParcial){ tipoDeductiva = 'Parcial' }
                    dataDetalleDeductiva = [
                         { label: <b><Tooltip title="Número de ticket o solicitud de servicio">No. Ticket</Tooltip></b>, data: <b className='naranja'>{e.folio}</b>},
                         { label: <b><Tooltip title="Nombre del tipo de falla">Tipo de falla</Tooltip></b>, data: e.tipoFalla},
                         { label: <b><Tooltip title="Descripción completa de la falla, capturada al momento en que se reportó la falla">Descripción de la falla</Tooltip></b>, data: e.descripcionSolicitudTabla},
                         { label: 'Fecha y hora de creación', data: e.fechaAltaTabla },
                         { label: 'Fecha y hora de cierre', data: e.fechaCierreTabla },
                         
                         { label: <b><Tooltip title="Porcentaje correspondiente del servicio">Ponderador del servicio</Tooltip></b>, data: e.ponderacionServAreaTabla},
                         { label: <b><Tooltip title="Nombre de la categoría, relacionada al catálogo de categorías de fallas de calidad">Categoría de falla de Calidad </Tooltip></b>, data: e.categoria},
                         { label: <b><Tooltip title="Porcentaje de deducción correspondiente, según la categoría de falla de calidad">pcfc</Tooltip></b>, data: e.pcfc + " %"},
                         { label: <b><Tooltip title="Nombre del área funcional">Área funcional</Tooltip></b>, data: e.areaFuncional},
                         { label: <b><Tooltip title="Peso del área funcional">psa</Tooltip></b>, data: e.psa  + " %"},
                         { label: <b><Tooltip title="Factor de Seguridad">fsa</Tooltip></b>, data: fsaFx},
                         { label: <b><Tooltip title="Número de turnos afectados">utfc</Tooltip></b>, data: e.utf},
                         { label: <b><Tooltip title="Tipo de deductiva">Tipo</Tooltip></b>, data: tipoDeductiva},
                         { label: <b><Tooltip title="Nombre del servicio">Servicio</Tooltip></b>, data: e.nombreServicioTabla},
                         
                    ];

                    dataDeducctionTotal = [
                         { label: 'Deducción total: ', data: "$ " + e.deduccion }
                    ];
               } else if(e.claveTipoFalla === 'FLDL') {
                    let tipoDeductiva = 'Definitiva'; if(e.deductivaParcial){ tipoDeductiva = 'Parcial' }
                    dataDetalleDeductiva = [
                         { label: <b><Tooltip title="Número de ticket o solicitud de servicio">No. Ticket</Tooltip></b>, data: <b className='naranja'>{e.folio}</b>},
                         { label: <b><Tooltip title="Nombre del tipo de falla">Tipo de falla</Tooltip></b>, data: e.tipoFalla},
                         { label: <b><Tooltip title="Descripción completa de la falla, capturada al momento en que se reportó la falla">Descripción de la falla</Tooltip></b>, data: e.descripcionSolicitudTabla},
                         { label: 'Fecha y hora de creación', data: e.fechaAltaTabla },
                         { label: 'Fecha y hora de cierre', data: e.fechaCierreTabla },
                         
                         { label: <b><Tooltip title="Nombre de la categoría, relacionada al catálogo de categorías de fallas de disponibilidad">Categoría de falla de Disponibilidad </Tooltip></b>, data: e.categoria},
                         { label: <b><Tooltip title="Porcentaje de deducción correspondiente, según la categoría de falla de disponibilidad">pdfd</Tooltip></b>, data: e.pdfd  + " %"},
                         { label: <b><Tooltip title="Nombre del área funcional">Área funcional</Tooltip></b>, data: e.areaFuncional},
                         { label: <b><Tooltip title="Peso del área funcional">psam</Tooltip></b>, data: e.psa  + " %"},
                         { label: <b><Tooltip title="Factor de Seguridad">fsam</Tooltip></b>, data: fsaFx},
                         { label: <b><Tooltip title="Número de turnos afectados">utfd</Tooltip></b>, data: e.utf},
                         { label: <b><Tooltip title="Tipo de deductiva">Tipo</Tooltip></b>, data: tipoDeductiva},
                         { label: <b><Tooltip title="Nombre del servicio">Servicio</Tooltip></b>, data: e.nombreServicioTabla},
                    ];

                    dataDeducctionTotal = [
                         { label: 'Deducción total: ', data: "$ " + e.deduccion }
                    ];
               } else {
                    let tipoDeductiva = 'Definitiva'; if(e.deductivaParcial){ tipoDeductiva = 'Parcial' }
                    dataDetalleDeductiva = [
                         { label: <b><Tooltip title="Número de ticket o solicitud de servicio">No. Ticket</Tooltip></b>, data: <b className='naranja'>{e.folio}</b>},
                         { label: <b><Tooltip title="Nombre del tipo de falla">Tipo de falla</Tooltip></b>, data: e.tipoFalla},
                         { label: <b><Tooltip title="Descripción completa de la falla, capturada al momento en que se reportó la falla">Descripción de la falla</Tooltip></b>, data: e.descripcionSolicitudTabla},
                         { label: 'Fecha y hora de creación', data: e.fechaAltaTabla },
                         { label: 'Fecha y hora de cierre', data: e.fechaCierreTabla },
                         
                         { label: <b><Tooltip title="Nombre del área funcional">Área funcional</Tooltip></b>, data: e.areaFuncional},
                         { label: <b><Tooltip title="Número de turnos afectados">tmfgs</Tooltip></b>, data: e.utf},
                         { label: <b><Tooltip title="Tipo de deductiva">Tipo</Tooltip></b>, data: tipoDeductiva},
                         { label: <b><Tooltip title="Nombre del servicio">Servicio</Tooltip></b>, data: e.nombreServicioTabla},
                    ];

                    dataDeducctionTotal = [
                         { label: 'Deducción total: ', data: "$ " + e.deduccion }
                    ];
               }

               this.setState({ claveTipoFalla: e.claveTipoFalla, dataVariablesBase, dataDetalleDeductiva, dataDeducctionTotal });
          }

          const columns = [
               { title: <Tooltip title='Número de ticket'>Folio</Tooltip>, dataIndex: 'folio', key: 'folio', align: 'center' },
               { title: <Tooltip title='Fecha y hora en la que se reportó la falla'>Fecha</Tooltip>, dataIndex: 'fechaAltaTabla', key: 'fechaAltaTabla', align: 'center' },
               { title: <Tooltip title='Código del área en la que ocurrió la falla'>Código área</Tooltip>, dataIndex: 'codigoArea', key: 'codigoArea', align: 'center' },
               { title: <Tooltip title='Nombre del área funcional en la que ocurrió la falla'>Área funcional</Tooltip>, dataIndex: 'areaFuncional', key: 'areaFuncional', align: 'center' },
               { title: <Tooltip title='Nombre de la unidad funcional en la que ocurrió la falla'>Unidad funcional</Tooltip>, dataIndex: 'unidadFuncional', key: 'unidadFuncional', align: 'center' },
               { title: <Tooltip title='Monto de deducción'>Deducción</Tooltip>, dataIndex: 'deduccion', key: 'deduccion', align: 'right' },
               { title: <Tooltip title='Tipo de falla relacionado al catálogo de falla para cada ticket'>Tipo de falla</Tooltip>, dataIndex: 'tipoFalla', key: 'tipoFalla', align: 'center' },
               { title: '', dataIndex: '', key: '', align: 'center',
               render: (record) => (
                    <a className='editarIcono' onClick={() => onDesgloce(record)}> <FileSearchOutlined /> </a>
               )},
          ];

          const handleClickMenu = e => {
               this.formRef.current.resetFields(['servicioSelect']);
               this.props.onListaServiciosFiltroLimpiar();

               const nombreGrupoSer = e.keyPath[1].split('-')[1];
               const idGrupoSer = e.keyPath[1].split('-')[0];
               const nombreServicio = e.keyPath[0].split('-')[1];
               const idServicio = e.keyPath[0].split('-')[0];
               this.props.onGetRelacion(idServicio, this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, 1, 'ASC', 'idServicio', this.state.idTipoFalla);
               this.setState({ currentMenu: e.key.split('-')[0], nombreGrupoSer, nombreServicio, idServicio, claveTipoFalla: null });
          };

          const onExportar = (e) => {
               if(e === 'pdf'){
                    this.props.onGetPDF(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, this.props.mesTexto);
               } else {
                    this.props.onGetXLS(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, this.props.mesTexto);
               }
          };

          const genExtra = () => ( <EyeOutlined onClick={event => { event.stopPropagation(); }} /> );

          const buscarServicios = (value) => {
               console.log('value', value)
               if (value.length >= 3) {
                    if (value) {
                         onListaServiciosFiltro(value);
                    } else {
                         message.warning('No se encontró servicio con esas palabras')
                    }
               } else {
                   this.props.onListaServiciosFiltroLimpiar();
               }
          };

          const servicioSeleccionado = (e) => {
               if(e){
                    let idServicio = e.split('-')[0];
                    let nombreServicio = (e.split('_')[0]).split('-')[1];
                    let idGrupoSer = e.split('_')[1];
                    let nombreGrupoSer = '';

                    for(let i in this.props.listaGruposServicios){
                         if(parseInt(idGrupoSer) === this.props.listaGruposServicios[i].idGrupoSer){
                              nombreGrupoSer = this.props.listaGruposServicios[i].nombre;
                         }
                    }

                    //console.log('idServicio', idServicio); console.log('nombreServicio', nombreServicio); console.log('idGrupoSer', idGrupoSer); console.log('nombreGrupoSer', nombreGrupoSer);
                    this.props.onGetRelacion(idServicio, this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, 1, 'ASC', 'idServicio');
                    this.setState({ idServicio, nombreServicio, nombreGrupoSer, currentMenu: idServicio, claveTipoFalla: null });
               } else {
                    this.props.onGetRelacion('', this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, 1, 'ASC', 'idServicio');
                    this.setState({ idServicio: '', currentMenu: null, claveTipoFalla: null });
               }
          };

          const titleTable = () => {
               return (
                    <Row justify='space-around' align='middle' gutter={[0,0]}>
                         <Col span={20}>
                              <div style={{textAlign:'center'}}>
                                   <h4>FALLAS EN LA PRESTACIÓN DEL SERVICIO DE <b>{ this.state.nombreGrupoSer.toUpperCase() }</b> PARA EL MES <b>{this.props.mesTexto.toUpperCase()} {this.props.anioSeleccionadoRMDP}</b></h4>
                                   {/* <h5>SERVICIO <b>{ this.state.nombreServicio.toUpperCase() }</b></h5> */}
                              </div>
                         </Col>

                         <Col span={2}>
                              <Tooltip title='Exportar reporte de fallas .pdf'>
                                   <Button className="iconSecundary" shape="circle" loading={this.props.fetchingPDFRelacionDeduccion} icon={<FilePdfOutlined />} onClick={(e) => { onExportar('pdf'); }} disabled={this.props.relacionDeduccionCantidad < 1}/>
                              </Tooltip>
                         </Col>
                         
                         <Col span={2}>
                              <Tooltip title='Exportar reporte de fallas .xls'>
                                   <Button className="iconSecundary" shape="circle" loading={this.props.fetchingExcelRelacionDeduccion} icon={<FileExcelOutlined />} onClick={(e) => { onExportar('xls'); }} disabled={this.props.relacionDeduccionCantidad < 1}/>
                              </Tooltip>
                         </Col>
                    </Row>
               );
          };

          const tipoFallaSelected = (e) => {
               console.log('e', e);
               if(e.length > 0){
                    let idTipoFalla = [];
                    for(let i in e){
                         idTipoFalla.push(parseInt(e[i].split(' - ')[0]));
                    }

                    console.log('idTipoFalla', idTipoFalla)
                    this.props.onGetRelacion('', this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, 1, 'ASC', 'idSolicitudServicio', idTipoFalla);
                    this.setState({ idTipoFalla });
               }
          }

          return (<>
               <Form {...layout} name="formulario" ref={this.formRef} onFinish={onFinish}>

                    <Row justify='end' style={{ paddingBottom:'1em' }}>
                         <Col span={8}>
                              <Select mode='multiple' style={{ width: '100%', textAlign: 'left' }} placeholder="Selecciona el tipo de falla" loading={this.props.fetchingListaTiposFallasActivas} onChange={tipoFallaSelected}>
                                   {this.props.listaTiposFallasActivas.map(option => 
                                        <Option key={option.idTipoFalla + ' - ' + option.nombre}>
                                             <Tooltip placement="topLeft" title={option.idTipoFalla + ' - ' + option.nombre }>
                                                  {option.idTipoFalla + ' - ' + option.nombre}
                                             </Tooltip>
                                        </Option>)}
                                   )}
                              </Select>
                         </Col>
                    </Row>

                    <Row justify='space-around'>
                         { this.state.idTipoFalla ? (
                              <Col span={24} >
                                   { this.props.relacionDeduccionCantidad > 0 ? (
                                        <Table
                                             title={titleTable}
                                             size="small"
                                             rowKey="idSolicitudServicio"
                                             columns={columns}
                                             dataSource={this.props.relacionDeduccion}
                                             loading={this.props.fetchingRelacionDeduccion}
                                             onChange={handleTableChange}
                                             pagination={{ total: this.props.relacionDeduccionCantidad, simple: true, current: this.state.page }}
                                        />
                                   ): (
                                        <Spin spinning={this.props.fetchingRelacionDeduccion}>
                                             <Row justify='space-around' align='middle' style={{ height:'30em' }}>
                                                  <Col span={24} >
                                                       <Empty description='Sin datos' />
                                                  </Col>
                                             </Row>
                                        </Spin>
                                   ) }
                              </Col>
                         ):(
                              <Col span={24}>
                                   <Row justify='space-around' align='middle' style={{height:'30em'}}>
                                        <Result  icon={<SelectOutlined />} subTitle="Selecciona un tipo de falla para continuar." />
                                   </Row>
                              </Col>
                         ) }

                         { this.state.claveTipoFalla === 'FLCL' ? (
                              <Col span={24}>
                                   <Collapse expandIconPosition='right' className='collapseDetalleRDO' defaultActiveKey={['1']}>
                                        <Panel header="DEDUCTIVAS POR FALLAS DE CALIDAD" key="1" extra={genExtra()} showArrow={true}>
                                             <Row justify='center'>
                                                  <Text style={{fontSize: 20}}>
                                                       <Equation
                                                            value="DFC_c^i = ((PMI_i + PMR_i)/dm_i) * psa_m * fsa_m * (utfc_c_i / ut_m) * ps_i * pcfc_i"
                                                            errorHandler={defaultErrorHandler}
                                                       />
                                                  </Text>

                                                  <Col span={24}>
                                                       <Descriptions layout="vertical" bordered={true} title='VARIABLES BASE'>
                                                            {this.state.dataVariablesBase.map(item => 
                                                                 <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                            )}
                                                       </Descriptions>
                                                       <br/>
                                                  </Col>

                                                  <Col span={24}>
                                                       <Descriptions layout="vertical" bordered={true}>
                                                            {this.state.dataDetalleDeductiva.map(item => 
                                                                 <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                            )}
                                                       </Descriptions>
                                                       <br/>
                                                  </Col>

                                                  <Col span={24}>
                                                       <Descriptions layout="horizontal" bordered={true}>
                                                            {this.state.dataDeducctionTotal.map(item => 
                                                                 <Descriptions.Item column={{ xs: 1 }} label={item.label}>{item.data}</Descriptions.Item>
                                                            )}
                                                       </Descriptions>
                                                       <br/>
                                                  </Col>
                                             </Row>
                                        </Panel>
                                   </Collapse>
                                   <br/>
                              </Col>
                         ): ( this.state.claveTipoFalla === 'FLDL' ? (
                              <Col span={24}>
                                   <Collapse expandIconPosition='right' className='collapseDetalleRDO' defaultActiveKey={['1']}>
                                        <Panel header="DEDUCTIVAS POR FALLAS DE DISPONIBILIDAD" key="1" extra={genExtra()} showArrow={true}>
                                             <Row justify='center'>
                                                  <Text style={{fontSize: 20}}>
                                                       <Equation
                                                            value="DFD_d^i = ((PMI_i + PMR_i)/dm_i) * psa_m * fsa_m * (utfd_d^i / ut_m) * pdfd_i"
                                                            errorHandler={defaultErrorHandler}
                                                       />
                                                  </Text>

                                                  <Col span={24}>
                                                       <Descriptions layout="vertical" bordered={true} title='VARIABLES BASE'>
                                                            {this.state.dataVariablesBase.map(item => 
                                                                 <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                            )}
                                                       </Descriptions>
                                                       <br/>
                                                  </Col>

                                                  <Col span={24}>
                                                       <Descriptions layout="vertical" bordered={true}>
                                                            {this.state.dataDetalleDeductiva.map(item => 
                                                                 <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                            )}
                                                       </Descriptions>
                                                       <br/>
                                                  </Col>
                                                  <Col span={24}>
                                                       <Descriptions layout="horizontal" bordered={true}>
                                                            {this.state.dataDeducctionTotal.map(item => 
                                                                 <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                            )}
                                                       </Descriptions>
                                                       <br/>
                                                  </Col>
                                             </Row>
                                        </Panel>
                                   </Collapse>
                                   <br/>
                              </Col>
                         ): ( this.state.claveTipoFalla === 'FLGS' ? (
                              <Col span={24}>
                                   <Collapse expandIconPosition='right' className='collapseDetalleRDO' defaultActiveKey={['1']}>
                                        <Panel header="DEDUCTIVAS POR FALLAS GENERALES DEL SERVICIO" key="1" extra={genExtra()} showArrow={true}>
                                             <Row justify='center'>
                                                  <Text style={{fontSize: 20}}>
                                                       <Equation
                                                            value="DFGS_g^i = 0.1 * (PMI_i + PMR_i) * (TMFGS_i / dm_i)"
                                                            errorHandler={defaultErrorHandler}
                                                       />
                                                  </Text>

                                                  <Col span={24}>
                                                       <Descriptions layout="vertical" bordered={true} title='VARIABLES BASE'>
                                                            {this.state.dataVariablesBase.map(item => 
                                                                 <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                            )}
                                                       </Descriptions>
                                                       <br/>
                                                  </Col>

                                                  <Col span={24}>
                                                       <Descriptions layout="vertical" bordered={true}>
                                                            {this.state.dataDetalleDeductiva.map(item => 
                                                                 <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                            )}
                                                       </Descriptions>
                                                       <br/>
                                                  </Col>

                                                  <Col span={24}>
                                                       <Descriptions layout="horizontal" bordered={true}>
                                                            {this.state.dataDeducctionTotal.map(item => 
                                                                 <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                            )}
                                                       </Descriptions>
                                                       <br/>
                                                  </Col>
                                             </Row>
                                        </Panel>
                                   </Collapse> <br/>
                              </Col>  
                         ):false ) ) }
                    </Row>
               </Form>
          </>);
     }
}
 
 const mapStateToProps = state => {
     return {
          anioSeleccionadoRMDP: state.RMDPReducer.anioSeleccionadoRMDP,
          mesTexto: state.RMDPReducer.mesTexto,
          mesSeleccionadoRMDP: state.RMDPReducer.mesSeleccionadoRMDP,

          listaGruposServiciosRMDP: state.RMDPReducer.listaGruposServiciosRMDP,
          fetchingListaGruposServicios: state.RMDPReducer.fetchingListaGruposServicios,

          fetchingPDFRelacionDeduccion: state.RMDPReducer.fetchingPDFRelacionDeduccion,
          fetchingExcelRelacionDeduccion: state.RMDPReducer.fetchingExcelRelacionDeduccion,

          relacionDeduccion: state.RMDPReducer.relacionDeduccion,
          relacionDeduccionCantidad: state.RMDPReducer.relacionDeduccionCantidad,
          fetchingRelacionDeduccion: state.RMDPReducer.fetchingRelacionDeduccion,

          fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
          listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,

          listaGruposServicios: state.MonitorIntegralReducer.listaGruposServicios,

          fetchingListaTiposFallasActivas: state.SolicitudDeServiciosReducer.fetchingListaTiposFallasActivas,
          listaTiposFallasActivas: state.SolicitudDeServiciosReducer.listaTiposFallasActivas,
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          onGetListaGrupoSerForRMDP: () => {
               dispatch({ type: 'LISTA_GRUPOS_SERVICIOS_RDMP_REQUEST' });
          },
          onGetRelacion: (idServicio, idMes, anio, page, order, field, idTipoFalla) => {
               dispatch({ type: 'RELACION_DEDUCCIONES_REQUEST', idServicio, idMes, anio, page, order, field, idTipoFalla});
          },
          onGetPDF: (idMes, anio, mesTxt) => {
               dispatch({ type: 'PDF_RELACION_DEDUCCION_REQUEST', idMes, anio, mesTxt})
          },
          onGetXLS: (idMes, anio, mesTxt) => {
               dispatch({ type: 'EXCEL_RELACION_DEDUCCION_REQUEST', idMes, anio, mesTxt });
          },
          onListaServiciosFiltro: (busqueda) => {
               dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
          },
          onListaServiciosFiltroLimpiar: () => {
               dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
          },
          onGetGruposServiciosActivos: () => {
               dispatch({ type: 'LISTA_GRUPOSERVICIOS_REQUEST' });
          },
          onGetListaTiposFallasActivas: () => {
               dispatch({ type: "LISTA_TIPOS_FALLAS_ACTIVAS_REQUEST" })
          },
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(RelacionDeducciones);