import React, { Component } from 'react';
import {Form,Input,Button,Modal,Col,Row,Spin,Divider,DatePicker,InputNumber, message,Select} from 'antd';
import { connect } from 'react-redux';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import moment from 'moment';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Option } = Select;

//MODAL DENTRO DE OPCIONES > AGREGAR NUEVO PRODUCTO
class ModalModificarRegistroEditar extends Component {
    state = {  tipoMonedaSeleccionada: null, cantidad: [] }
    
    formRef = React.createRef();
    render() { 
        const {showModalModificarEditarFolio, fetchingEditarFolio,
            registroProductoSeleccionado,vistaModalEditarFolio, listaUbicacionesDestino,
            listaSeleccionadosEditar, listaDetalle, fetchingObtenerUbicacionDestino,
        } = this.props;
                 

        const onFinish = formulario => {
            console.log('Success: ' , formulario);
            
            const listaSeleccionadosEditarLocal = listaSeleccionadosEditar;
            this.props.onGuardarProducto(vistaModalEditarFolio, formulario, null);
            for (let i = 0; i < listaSeleccionadosEditarLocal.length; i++) {
                if(listaSeleccionadosEditarLocal[i].idProducto === registroProductoSeleccionado.idProducto){
                    console.log('listaSeleccionadosEditarLocal[i].serieNum', listaSeleccionadosEditarLocal[i].serieNum, listaSeleccionadosEditarLocal[i].ambas)
                    if(listaSeleccionadosEditarLocal[i].serieNum){
                        if(listaSeleccionadosEditarLocal[i].serieNum === registroProductoSeleccionado.serieNum){
                            listaSeleccionadosEditarLocal[i] = Object.assign(listaSeleccionadosEditarLocal[i], formulario)
                        }
                    }
                    else{
                        listaSeleccionadosEditarLocal[i] = Object.assign(listaSeleccionadosEditarLocal[i], formulario);
                        
                        if(listaSeleccionadosEditarLocal[i].subFamiliaByIdSubFamilia.familiaByIdFamilia.tipoFamilia === 'Activos'){
                            console.log('Entró a comparar serieNum y ambas')
                            listaSeleccionadosEditarLocal[i].serieNum = '';
                            for(let x=1; x<=listaSeleccionadosEditarLocal[i].cantidad; x++){
                                if(x===listaSeleccionadosEditarLocal[i].cantidad){
                                    listaSeleccionadosEditarLocal[i].serieNum += listaSeleccionadosEditarLocal[i]['serieNum-' + x];
                                }else{
                                    if(listaSeleccionadosEditarLocal[i]['serieNum-' + x]){
                                        listaSeleccionadosEditarLocal[i].serieNum += listaSeleccionadosEditarLocal[i]['serieNum-' + x] + ', ';
                                    }
                                }
                            }
                        }
                    }
                    if( formulario.cantidad > registroProductoSeleccionado.cantidadTotal){
                        formulario.cantidad = registroProductoSeleccionado.cantidadTotal
                    }
                }  
            }
            console.log('listaSeleccionadosEditar fuera del for', listaSeleccionadosEditarLocal)
            this.props.onGuardarProducto(vistaModalEditarFolio, formulario, listaSeleccionadosEditarLocal);
            this.props.onShowModalModificar();
        }; 

        const handleCancel = () => {
            this.props.onShowModalModificar();
            this.props.onBorrarUbicacionDestino(); // quitar ubicaciones destino
            this.formRef.current.resetFields();
            this.setState({ tipoMonedaSeleccionada: null, cantidad: [] });
        }

        const onListaUbicacionDestino = value => {
            console.log('value', value);
            let id = value
            for (let i = 0; i < listaSeleccionadosEditar.length; i++) {
                listaSeleccionadosEditar[i].idUbicacionProductoD = id;
            }
            console.log('listaSeleccionadosEditar con value', listaSeleccionadosEditar)
        };

        const  disabledDate = (current) => {
            // Can not select days before today and today
            return current && current < moment();
        }

        const onCantidad = (value) => {
            this.formRef.current.setFieldsValue({'cantidad': parseInt(value)});
            const registroProductoSeleccionadoLocal = registroProductoSeleccionado;
            this.props.onActualizarListaSerieNum(null, this.props.page);
            registroProductoSeleccionadoLocal.listaSerieNum = [];
            for (let i=1; i<=parseInt(value); i++) {
                registroProductoSeleccionadoLocal.listaSerieNum.push(i);
            }
            this.props.onActualizarListaSerieNum(registroProductoSeleccionadoLocal, this.props.page);
        }

        const onNumeroSerie = (id) => {
            if(this.formRef.current){
                const formulario = this.formRef.current.getFieldsValue();
                const campo = 'serieNum-' + id;
                const valor = this.formRef.current.getFieldValue(campo);
                if(valor){
                    const listaSerieNum = [];
    
                    for (var key in formulario) {
                        if (Object.prototype.hasOwnProperty.call(formulario, key)) {
                            let idCampo = key.split('-');
                            if (idCampo.length === 2) {
                            let id = idCampo[1];
                            let campo = idCampo[0];
                            let valor = formulario[key];
        
                            let serieNum = {[campo]: valor, 'id': parseInt(id)};
                            listaSerieNum.push(serieNum);
                            }
                        }
                    }
                    for(let i in listaSerieNum){
                        if(listaSerieNum[i].id !== id){
                            if(valor === listaSerieNum[i].serieNum){
                                message.warning('No es posible asignar el número de serie en más de un producto.');
                                this.formRef.current.resetFields([campo]);
                            }
                        }
                    }
                }
            }
        }

        return ( 
            <Modal
                visible={showModalModificarEditarFolio}
                title={'Modificar información de: ' + registroProductoSeleccionado?.nombre}
                onCancel={handleCancel}
                footer={false}
            >
                {registroProductoSeleccionado ? (
                    <Spin spinning={fetchingEditarFolio}>
                        <Form {...layout}
                            name='formulario'
                            initialValues={registroProductoSeleccionado}
                            onFinish={onFinish}
                            ref={this.formRef}
                        >
                            {listaUbicacionesDestino && registroProductoSeleccionado ? (
                            <Form.Item
                                label="Ubicacion Destino"
                                name="idUbicacionProductoDestino"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona la ubicación destino"
                                    loading={fetchingObtenerUbicacionDestino}
                                    onChange={onListaUbicacionDestino}
                                >
                                    {listaUbicacionesDestino.map(option => <Option key={option.idUbicacionProducto}>{option.area + "-" + option.piso + "-" + option.pasillo + "-" + option.rack + "-" + option.nivelRack + "-" + option.posicionRack}</Option>)}
                                </Select>
                            </Form.Item>
                            ): false}
                            
                            {registroProductoSeleccionado.subFamiliaByIdSubFamilia.familiaByIdFamilia.tipoFamilia === 'Activos' && registroProductoSeleccionado.ambas ? false : (
                            <Form.Item
                                label="Cantidad"
                                name="cantidad"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <InputNumber min={1} placeholder='Cantidad' onChange={onCantidad} max={registroProductoSeleccionado.cantidadInventario}/>
                            </Form.Item>
                            )}

                            {registroProductoSeleccionado.subFamiliaByIdSubFamilia.familiaByIdFamilia.tipoFamilia === 'Activos' ? ( 
                                <>
                                {registroProductoSeleccionado.listaSerieNum.map(i => 
                                    <Form.Item
                                        key={i}
                                        label={"Número de serie para producto Nº" + i}
                                        name={"serieNum-" + i}
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Input maxLength={30} onBlur={() => onNumeroSerie(i)} placeholder='Ingresa el número de serie' />
                                    </Form.Item>
                                )}
                                <Divider orientation="left" /> 
                                </> 
                            ): false}

                            {!registroProductoSeleccionado.idInventario ? ( <>
                                <Form.Item
                                    label="Tipo de Moneda"
                                    name="tipoMonedaEditar"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el tipo de moneda"
                                        loading={this.props.fetchingListaTipoMonedasActivas}
                                        onChange={(a, b) => { this.setState({ tipoMonedaSeleccionada: b.children }); }}
                                    >
                                        {this.props.listaTipoMonedasActivas.map(option => <Option key={option.idTipoMoneda + ' - ' + option.nombre + ' (' + option.nomenclatura + ')'}>{option.idTipoMoneda + ' - ' + option.nombre + ' (' + option.nomenclatura + ')'}</Option>)}
                                    </Select>
                                </Form.Item>
                    
                                <Form.Item
                                    label="Costo Original"
                                    name="precio"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <InputNumber min={1} placeholder='Precio' formatter={value =>  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                                </Form.Item>

                                { this.state.tipoMonedaSeleccionada ? (<>
                                    {this.state.tipoMonedaSeleccionada.split('(')[1] !== 'MXN)' ? (
                                        <Form.Item
                                            label="Tipo de Cambio"
                                            name="tipoCambio"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <InputNumber min={1} placeholder='Tipo de cambio' formatter={value =>  `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                                        </Form.Item>
                                    ) : false}
                                </>): (<>
                                    {registroProductoSeleccionado.tipoMoneda.split('(')[1] !== 'MXN)' ? (
                                        <Form.Item
                                            label="Tipo de Cambio"
                                            name="tipoCambio"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <InputNumber min={1} placeholder='Tipo de cambio' formatter={value =>  `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                                        </Form.Item>
                                    ) : false}
                                </>) }

                                {registroProductoSeleccionado.caracteristicaLote ? ( <>
                                    <Divider orientation="left"></Divider>
                                    <Form.Item
                                        label="Lote"
                                        name="lote"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <InputNumber min={1} placeholder='Lote' />
                                    </Form.Item>
                                    
                                    <Form.Item
                                        label="Fecha de Caducidad"
                                        name="fechaCaducidad"
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            format='DD-MM-YYYY'
                                            showToday={false}
                                            disabledDate={disabledDate}
                                        />
                                    </Form.Item>
                                </> ): false}
                            </> ): false}
                                
                            <Col span={24}>
                               <Button htmlType="submit" className='nuevoUsuario'>Guardar Cambios</Button>
                            </Col>
                            
                        </Form>
                    </Spin>
                ): false}
                
            </Modal>
        );
    }
}
const mapStateToProps = state => {
	return {
        showModalModificarEditarFolio: state.AlmacenesReducer.showModalModificarEditarFolio,
        fetchingEditarFolio: state.AlmacenesReducer.fetchingEditarFolio,
        registroProductoSeleccionado: state.AlmacenesReducer.registroProductoSeleccionado,
        listaDetalle: state.AlmacenesReducer.listaDetalle,
        page: state.AlmacenesReducer.page,
        vistaModalEditarFolio: state.AlmacenesReducer.vistaModalEditarFolio, 
        listaSeleccionadosEditar: state.AlmacenesReducer.listaSeleccionadosEditar,
        listaUbicacionesDestino: state.AlmacenesReducer.listaUbicacionesDestino,
        fetchingObtenerUbicacionDestino: state.AlmacenesReducer.fetchingObtenerUbicacionDestino,
        formularioEditarFolio: state.AlmacenesReducer.formularioEditarFolio,
        listaTipoMonedasActivas: state.CatalogosGeneralesReducer.listaTipoMonedasActivas,
        fetchingListaTipoMonedasActivas: state.CatalogosGeneralesReducer.fetchingListaTipoMonedasActivas,
    };
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalModificar: () => {
            dispatch({ type: "SHOW_MODAL_MODIFICAR_EDITARFOLIO", showModalModificarEditarFolio: false})
        },
        onGuardarProducto: (vistaModalEditarFolio, formulario, listaSeleccionadosEditar) => {
            dispatch({ type: "CAMBIAR_VISTA_MODAL_EDITARFOLIO", vistaModalEditarFolio: vistaModalEditarFolio, formularioEditarFolio: formulario, listaSeleccionadosEditar: listaSeleccionadosEditar})  
        },
        onBorrarUbicacionDestino: () => {
            dispatch({ type: "UBICACION_DESTINO_POR_ALMACEN_SUCCESS", listaUbicacionesDestino: null});
        },
        onActualizarListaSerieNum: (registroProductoSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_MODIFICAR_EDITARFOLIO", showModalModificarEditarFolio: true, registroProductoSeleccionado:registroProductoSeleccionado, page: page})
        },
	};
};
 
export default connect(mapStateToProps, mapDispatchToProps)(ModalModificarRegistroEditar);