import React, { Component } from 'react';
import { Form, Input, Button, Col, Spin, Modal, message, Row, InputNumber } from 'antd';
import { connect } from 'react-redux';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { TextArea } = Input;


class ModalNuevoPeriodoFMD extends Component {
    formRef = React.createRef();
    componentDidMount(){
        this.props.onRequestInfoProyecto();
    }
    
    render() { 

        const {
            showModalCrearPeriodoFMD, onCrearPeriodo,onLimpiarFormulario, limpiarFormulario,
            fetchingCrearPeriodoFMD, onCerrarModal, añosMaximoPeriodo, page, listaPeriodoFMD,
            listaInfoProyecto, 
        } = this.props;
        

        const onFinish = (formulario) => {
            console.log('formulario >> ', formulario );
            let dias;
            let mesAño; 
            if(listaInfoProyecto){
                dias = listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[0]
                mesAño  = listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[1] + '-' + listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2]
                console.log(dias, mesAño);
            }
            let fecha = "01-" + mesAño.toString();
           console.log('fecha :>> ', fecha);

            
            if(listaPeriodoFMD.length > 0) {
                onCrearPeriodo(formulario, page);
            } else {
                message.warning(`La fecha de Inicio se creará a partir del primer dia del mes "${fecha}"`, 7);
                onCrearPeriodo(formulario, page);
            }
            
        };

        

        if (limpiarFormulario && limpiarFormulario==='crearPeriodoFMD') {
            this.formRef.current.resetFields();
            onLimpiarFormulario();
        }

        const handleCancel = () => {
            this.formRef.current.resetFields();
            onCerrarModal();
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo);
        };

        return ( 
            <Modal
                visible={showModalCrearPeriodoFMD}
                title='Nuevo Periodo'
                onCancel={handleCancel}
                footer={false}
                width='40%'
            >
                
                <Spin spinning={fetchingCrearPeriodoFMD}>

                    <Form {...layout}
                            name='formulario'
                            onFinish={onFinish}
                            
                            ref={this.formRef}
                        >
                            <Row type='flex' justify='space-around'>

                                <Col span={24}>
                                    <Form.Item
                                        label="Periodo Años"
                                        name="periodoAnios"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <TextArea
                                            autoSize
                                            maxLength={100} placeholder='Ingrese Periodo Años' />
                                    </Form.Item>
                                </Col> 

                                <Col span={24}>
                                    <Form.Item
                                        label="Periodo Mes"
                                        name="periodoMes"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <TextArea
                                            autoSize
                                            maxLength={100} placeholder='Ingrese Periodo periodoMes' />
                                    </Form.Item>
                                </Col> 
                            
                                <Col span={24}>
                                    <Form.Item
                                        label="Cantidad de Años"
                                        name="cantidadAnios"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <InputNumber min={1} max={listaInfoProyecto.vigencia} placeholder="Ingrese la cantidad de años" />
                                    </Form.Item>
                                </Col> 

                                <Col span={24}>
                                    <Form.Item
                                        label="Fracción Maxima de Descuento"
                                        name="fraccionMaxDescuento"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <InputNumber  min={1} max={100} placeholder="Ingrese la fraccion maxima de descuento"/>
                                    </Form.Item>
                                </Col> 

                                <Col span={24}>
                                    <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Crear Periodo</Button>
                                </Col>
                             </Row> 
                        
                    </Form>
                </Spin>
               
            </Modal>
         );
    }
}
const mapStateToProps = state => {
    return {
        showModalCrearPeriodoFMD: state.MecanismosDePagosReducer.showModalCrearPeriodoFMD,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario, 
        fetchingCrearPeriodoFMD: state.MecanismosDePagosReducer.fetchingCrearPeriodoFMD, 
        añosMaximoPeriodo: state.MecanismosDePagosReducer.añosMaximoPeriodo, 
        page: state.MecanismosDePagosReducer.page, 
        listaPeriodoFMD: state.MecanismosDePagosReducer.listaPeriodoFMD, 
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCerrarModal: () => {
            dispatch({ type: "SHOW_MODAL_CREAR_PERIODO_FMD", showModalCrearPeriodoFMD: false })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        },
        onCrearPeriodo: (formulario, page) => {
           dispatch({ type: "CREAR_PERIODO_FMD_REQUEST" , formulario, page});
        },
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        }, 
    };
};
export default connect(mapStateToProps, mapDispatchToProps) (ModalNuevoPeriodoFMD);