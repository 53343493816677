import { takeLatest, call, put } from "redux-saga/effects";
import * as monitorIntegralActions from '../actions/MonitorIntegralActions';
import * as configuracionYSeguridadActions from '../actions/ConfiguracionYSeguridadActions'
import {
    areasConNumeroCall, unidadesConNumeroCall, interioresConNumeroCall, subInterioresConNumeroCall, detalleSSCall, infoDetalleSSCall,

    grupoServiciosCall,servicioPorGrupoServiciosCall,solicitudesDeServicioPorServicioCall, obtenerCantidadFoliosPorIdGrupoServCall,

    getDataEncuestasSatisfaccionCall, getDataEncuestasDesempenoCall, getDataQuejasReclamosCall, getDataEvaluacionDesempenoCall, listaServiciosByClasificacionGerenciaCall, getDataPromedioSatisfaccionCall, getDataEstandaresByTipoCall,

    getDataRDOSolicitudesServicioCall, getDataRDOServiciosPlanificadosCall, getDatosMonitorPlanificadosCall, getDataRDOServiciosPublicosCall,

    getListReporteGraficoCall, getListReporteGraficoGrupoCall,
} from '../../services/MonitorIntegralServices';

// SAGA PARA MAPA DE INCIDENCIAS
function* areasConNumero(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idGrupoSer = action.busqueda.idGrupoSer ? 'idGrupoSer=' + action.busqueda.idGrupoSer : '';
        let estado = action.busqueda.estado ? '&estado=' + action.busqueda.estado : '';
        let idServicio = action.busqueda.idServicio ? '&idServicio=' + action.busqueda.idServicio : '';

        const response = yield call(areasConNumeroCall, token, idGrupoSer, estado, idServicio );
        console.log(response)

        if(response.status === 200){
            let listaAreasNumero = response.data;
            for(let i in listaAreasNumero){
                var img = new Image();
                img.onload = function(){
                    listaAreasNumero[i].bounds = [[0, 0], this.width, this.height]
                };
                img.src = listaAreasNumero[i].rutaArchivo;

                //Coordenadas
                if(listaAreasNumero[i].ubicacionByIds.coordenadasByIdUbicacion){
                    let {coordenadasByIdUbicacion} = listaAreasNumero[i].ubicacionByIds;
                    let coordenadasArray = [];
                    
                    for(let j=0; j<coordenadasByIdUbicacion.length; j++) {
                        coordenadasArray.push([coordenadasByIdUbicacion[j].coordenadaX, coordenadasByIdUbicacion[j].coordenadaY]);
                    }
                    listaAreasNumero[i].coordenadas = coordenadasArray;
                }
                else{
                    listaAreasNumero[i].coordenadas = []
                }
            }
            if(!action.noAtras){
                yield put({ type: monitorIntegralActions.ATRAS_MAPA, atrasMapa: true});
            }
            yield put({ type: monitorIntegralActions.LISTA_AREAS_NUMERO_SUCCESS, listaAreasNumero});
        } else {
            yield put({ type: monitorIntegralActions.LISTA_AREAS_NUMERO_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.LISTA_AREAS_NUMERO_FAILURE });
    }
}

export function* areasConNumeroSaga() {
    yield takeLatest(monitorIntegralActions.LISTA_AREAS_NUMERO_REQUEST, areasConNumero)
}

function* unidadesConNumero(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idGrupoSer = action.busqueda.idGrupoSer ? '&idGrupoSer=' + action.busqueda.idGrupoSer : '';
        let estado = action.busqueda.estado ? '&estado=' + action.busqueda.estado : '';
        let idServicio = action.busqueda.idServicio ? '&idServicio=' + action.busqueda.idServicio : '';
        let folio = action.busqueda.folio ? '&folio=' + action.busqueda.folio : '';
        let idAreaFuncional = action.idAreaFuncional;
        console.log(`folio en saga`, folio)
        const response = yield call(unidadesConNumeroCall, token, idGrupoSer, estado, idServicio, idAreaFuncional, folio );
        console.log(response)

        if(response.status === 200){
            let listaUnidadesNumero = response.data;
            for(let i in listaUnidadesNumero){
                var img = new Image();
                img.onload = function(){
                    listaUnidadesNumero[i].bounds = [[0, 0], this.width, this.height]
                };
                img.src = listaUnidadesNumero[i].rutaArchivo;

                //Coordenadas
                if(listaUnidadesNumero[i].ubicacionByIds.coordenadasByIdUbicacion){
                    let {coordenadasByIdUbicacion} = listaUnidadesNumero[i].ubicacionByIds;
                    let coordenadasArray = [];
                    for(let j=0; j<coordenadasByIdUbicacion.length; j++) {
                        let coordX =coordenadasByIdUbicacion[j].coordenadaX;
                        let coordY = coordenadasByIdUbicacion[j].coordenadaY;
                        let xyArray = [coordX, coordY];
                        coordenadasArray.push(xyArray);
                    }
                    listaUnidadesNumero[i].coordenadas = coordenadasArray;
                }
                else{
                    listaUnidadesNumero[i].coordenadas = []
                }
            }
            if(!action.noAtras){
                yield put({ type: monitorIntegralActions.ATRAS_MAPA, atrasMapa: true});
            }
            yield put({ type: monitorIntegralActions.LISTA_UNIDADES_NUMERO_SUCCESS, listaUnidadesNumero});
        } else {
            yield put({ type: monitorIntegralActions.LISTA_UNIDADES_NUMERO_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.LISTA_UNIDADES_NUMERO_FAILURE });
    }
}

export function* unidadesConNumeroSaga() {
    yield takeLatest(monitorIntegralActions.LISTA_UNIDADES_NUMERO_REQUEST, unidadesConNumero)
}

function* interioresConNumero(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idGrupoSer = action.busqueda.idGrupoSer ? '&idGrupoSer=' + action.busqueda.idGrupoSer : '';
        let estado = action.busqueda.estado ? '&estado=' + action.busqueda.estado : '';
        let idServicio = action.busqueda.idServicio ? '&idServicio=' + action.busqueda.idServicio : '';
        let folio = action.busqueda.folio ? '&folio=' + action.busqueda.folio : '';
        let idUnidadFuncional = action.idUnidadFuncional;
        console.log(`folio en saga`, folio)
        const response = yield call(interioresConNumeroCall, token, idGrupoSer, estado, idServicio, idUnidadFuncional, folio );
        console.log(response)

        if(response.status === 200){
            let listaInterioresNumero = response.data;
            for(let i in listaInterioresNumero){
                var img = new Image();
                img.onload = function(){
                    listaInterioresNumero[i].bounds = [[0, 0], this.width, this.height]
                };
                img.src = listaInterioresNumero[i].rutaArchivo;

                //Coordenadas
                if(listaInterioresNumero[i].ubicacionByIds.coordenadasByIdUbicacion){
                    let {coordenadasByIdUbicacion} = listaInterioresNumero[i].ubicacionByIds;
                    let coordenadasArray = [];
                    let coordenadasX = [];
                    let coordenadasY = [];
                    for(let j in coordenadasByIdUbicacion) {
                        coordenadasX.push(coordenadasByIdUbicacion[j].coordenadaX);
                        coordenadasY.push(coordenadasByIdUbicacion[j].coordenadaY);
                    }
                    console.log(`coordenadasX,coordenadasY`, coordenadasX,coordenadasY)
                    const minimoX = Math.min.apply(Math, coordenadasX);
                    const mayorX = Math.max.apply(Math, coordenadasX);
                    const minimoY = Math.min.apply(Math, coordenadasY);
                    const mayorY = Math.max.apply(Math, coordenadasY);

                    console.log(`minimoX, mayorX, minimoY, mayorY`, minimoX, mayorX, minimoY, mayorY)

                    for(let j=0; j<coordenadasByIdUbicacion.length; j++) {
                        let cercano = coordenadasByIdUbicacion[j].coordenadaX - minimoX > mayorX - coordenadasByIdUbicacion[j].coordenadaX ? 'izquierda' : 'derecha';
                        coordenadasArray.push([coordenadasByIdUbicacion[j].coordenadaX, coordenadasByIdUbicacion[j].coordenadaY, cercano]);
                    }
                    listaInterioresNumero[i].coordenadas = coordenadasArray;
                }
                else{
                    listaInterioresNumero[i].coordenadas = []
                }
            }
            if(!action.noAtras){
                yield put({ type: monitorIntegralActions.ATRAS_MAPA, atrasMapa: true});
            }
            yield put({ type: monitorIntegralActions.LISTA_INTERIORES_NUMERO_SUCCESS, listaInterioresNumero});
        } else {
            yield put({ type: monitorIntegralActions.LISTA_INTERIORES_NUMERO_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.LISTA_INTERIORES_NUMERO_FAILURE });
    }
}

export function* interioresConNumeroSaga() {
    yield takeLatest(monitorIntegralActions.LISTA_INTERIORES_NUMERO_REQUEST, interioresConNumero)
}

function* subInterioresConNumero(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idGrupoSer = action.busqueda.idGrupoSer ? '&idGrupoSer=' + action.busqueda.idGrupoSer : '';
        let estado = action.busqueda.estado ? '&estado=' + action.busqueda.estado : '';
        let idServicio = action.busqueda.idServicio ? '&idServicio=' + action.busqueda.idServicio : '';
        let folio = action.busqueda.folio ? '&folio=' + action.busqueda.folio : '';
        let idInteriorFuncional = action.idInteriorFuncional;
        console.log(`folio en saga`, folio)
        const response = yield call(subInterioresConNumeroCall, token, idGrupoSer, estado, idServicio, idInteriorFuncional, folio );
        console.log(response)

        if(response.status === 200){
            let listaSubInterioresNumero = response.data;
            for(let i in listaSubInterioresNumero){
                var img = new Image();
                img.onload = function(){
                    listaSubInterioresNumero[i].bounds = [[0, 0], this.width, this.height]
                };
                img.src = listaSubInterioresNumero[i].rutaArchivo;

                //Coordenadas
                if(listaSubInterioresNumero[i].ubicacionByIds.coordenadasByIdUbicacion){
                    let {coordenadasByIdUbicacion} = listaSubInterioresNumero[i].ubicacionByIds;
                    let coordenadasArray = [];
                    let coordenadasX = [];
                    let coordenadasY = [];
                    for(let j in coordenadasByIdUbicacion) {
                        coordenadasX.push(coordenadasByIdUbicacion[j].coordenadaX);
                        coordenadasY.push(coordenadasByIdUbicacion[j].coordenadaY);
                    }
                    console.log(`coordenadasX,coordenadasY`, coordenadasX,coordenadasY)
                    const minimoX = Math.min.apply(Math, coordenadasX);
                    const mayorX = Math.max.apply(Math, coordenadasX);
                    const minimoY = Math.min.apply(Math, coordenadasY);
                    const mayorY = Math.max.apply(Math, coordenadasY);

                    console.log(`minimoX, mayorX, minimoY, mayorY`, minimoX, mayorX, minimoY, mayorY)

                    for(let j=0; j<coordenadasByIdUbicacion.length; j++) {
                        let cercano = coordenadasByIdUbicacion[j].coordenadaX - minimoX > mayorX - coordenadasByIdUbicacion[j].coordenadaX ? 'izquierda' : 'derecha';
                        coordenadasArray.push([coordenadasByIdUbicacion[j].coordenadaX, coordenadasByIdUbicacion[j].coordenadaY, cercano]);
                    }
                    listaSubInterioresNumero[i].coordenadas = coordenadasArray;
                }
                else{
                    listaSubInterioresNumero[i].coordenadas = []
                }
            }
            if(!action.noAtras){
                yield put({ type: monitorIntegralActions.ATRAS_MAPA, atrasMapa: true});
            }
            yield put({ type: monitorIntegralActions.LISTA_SUBINTERIORES_NUMERO_SUCCESS, listaSubInterioresNumero});
        } else {
            yield put({ type: monitorIntegralActions.LISTA_SUBINTERIORES_NUMERO_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.LISTA_SUBINTERIORES_NUMERO_FAILURE });
    }
}

export function* subInterioresConNumeroSaga() {
    yield takeLatest(monitorIntegralActions.LISTA_SUBINTERIORES_NUMERO_REQUEST, subInterioresConNumero)
}

function* detalleSS(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idGrupoSer = action.busqueda.idGrupoSer ? 'idGrupoSer=' + action.busqueda.idGrupoSer + '&' : '';
        let estado = action.busqueda.estado ? 'estado=' + action.busqueda.estado + '&' : '';
        let idServicio = action.busqueda.idServicio ? 'idServicio=' + action.busqueda.idServicio + '&' : '';
        let folio = action.busqueda.folio ? 'folio=' + action.busqueda.folio + '&' : '';
        let idAreaFuncional = action.busqueda.idAreaFuncional ? 'idAreaFuncional=' + action.busqueda.idAreaFuncional + '&' : '';
        let idUnidadFuncional = action.busqueda.idUnidadFuncional ? 'idUnidadFuncional=' + action.busqueda.idUnidadFuncional + '&' : '';
        let idInteriorFuncional = action.busqueda.idInteriorFuncional ? 'idInteriorFuncional=' + action.busqueda.idInteriorFuncional + '&' : '';
        let idSubInteriorFuncional = action.busqueda.idSubInteriorFuncional ? 'idSubInteriorFuncional=' + action.busqueda.idSubInteriorFuncional + '&' : '';
        const page = 'page=' + (action.page - 1)*10 + '&offset=10';

        const response = yield call(detalleSSCall, token, idGrupoSer, estado, idServicio, idAreaFuncional, idUnidadFuncional, idInteriorFuncional, idSubInteriorFuncional, folio? folio: '', page);
        console.log(response)

        const response2 = yield call(detalleSSCall, token, idGrupoSer, estado, idServicio, idAreaFuncional, idUnidadFuncional, idInteriorFuncional, idSubInteriorFuncional, folio? folio: '', '');
        console.log(response2)

        if(response.status === 200){
            let listaDetallesSS = response.data.data;
            let listaDetallesSSCantidad = response.data.cantidad;
            for(let i in listaDetallesSS){
                if(listaDetallesSS[i].ubicacionByIdUbicacion){
                    //Coordenadas
                    if(listaDetallesSS[i].ubicacionByIdUbicacion.coordenadasByIdUbicacion){
                        let {coordenadasByIdUbicacion} = listaDetallesSS[i].ubicacionByIdUbicacion;
                        let coordenadasArray = [];
                        for(let j=0; j<coordenadasByIdUbicacion.length; j++) {
                            let coordX =coordenadasByIdUbicacion[j].coordenadaX;
                            let coordY = coordenadasByIdUbicacion[j].coordenadaY;
                            let xyArray = [coordX, coordY];
                            coordenadasArray.push(xyArray);
                        }
                        listaDetallesSS[i].coordenadas = coordenadasArray;
                    }
                    else{
                        listaDetallesSS[i].coordenadas = []
                    }

                    if(listaDetallesSS[i].servicioByIdServicio){
                        listaDetallesSS[i].servicio = listaDetallesSS[i].servicioByIdServicio.nombre;
                    }

                    listaDetallesSS[i].ubicacion = '';
                    if (listaDetallesSS[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional) {
                        listaDetallesSS[i].area = listaDetallesSS[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.idAreaFuncional + " - " + listaDetallesSS[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                        listaDetallesSS[i].idAreaFuncional = listaDetallesSS[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.idAreaFuncional;
                        listaDetallesSS[i].areaDetalle = listaDetallesSS[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                        listaDetallesSS[i].ubicacion = listaDetallesSS[i].areaDetalle;
                        listaDetallesSS[i].plano = listaDetallesSS[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.rutaImagen
                    } else {
                        listaDetallesSS[i].area = "No tiene Area Funcional";
                        listaDetallesSS[i].idAreaFuncional = null;
                    }

                    if (listaDetallesSS[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional) {
                        listaDetallesSS[i].unidad = listaDetallesSS[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.idUnidadFuncional + " - " + listaDetallesSS[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                        listaDetallesSS[i].idUnidadFuncional = listaDetallesSS[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.idUnidadFuncional;
                        listaDetallesSS[i].unidadDetalle = listaDetallesSS[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                        listaDetallesSS[i].ubicacion += ', ' + listaDetallesSS[i].unidadDetalle;
                        listaDetallesSS[i].plano = listaDetallesSS[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.rutaImagen
                    } else {
                        listaDetallesSS[i].unidad = "No tiene Unidad Funcional";
                        listaDetallesSS[i].unidadDetalle = "No tiene Unidad Funcional";
                        listaDetallesSS[i].idUnidadFuncional = null;
                    }

                    if (listaDetallesSS[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional) {
                        listaDetallesSS[i].interior = listaDetallesSS[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.idInteriorFuncional + " - " + listaDetallesSS[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional;
                        listaDetallesSS[i].idInteriorFuncional = listaDetallesSS[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.idInteriorFuncional;
                        listaDetallesSS[i].interiorDetalle = listaDetallesSS[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional;
                        listaDetallesSS[i].ubicacion += ', ' + listaDetallesSS[i].interiorDetalle;
                    } else {
                        listaDetallesSS[i].interiorDetalle = "No tiene Interior Funcional";
                        listaDetallesSS[i].interior = "No tiene Interior Funcional";
                        listaDetallesSS[i].idInteriorFuncional = null
                    }
                    
                    if (listaDetallesSS[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional) {
                        listaDetallesSS[i].subInterior = listaDetallesSS[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.idSubInteriorFuncional + " - " + listaDetallesSS[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                        listaDetallesSS[i].idSubInteriorFuncional = listaDetallesSS[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.idSubInteriorFuncional;
                        listaDetallesSS[i].subInteriorDetalle = listaDetallesSS[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                        listaDetallesSS[i].ubicacion += ', ' + listaDetallesSS[i].subInteriorDetalle;
                    } else {
                        listaDetallesSS[i].subInterior = "No tiene SubInterior Funcional";
                        listaDetallesSS[i].idSubInteriorFuncional = null;
                    }
                }
            }
            let listaDetalleMapa = [];
            if(response2.status === 200){
                listaDetalleMapa = response2.data.data;
                yield put({ type: monitorIntegralActions.LISTA_DETALLES_SS_SUCCESS, listaDetallesSS, listaDetallesSSCantidad, listaDetalleMapa});
            }
            else{
                yield put({ type: monitorIntegralActions.LISTA_DETALLES_SS_FAILURE });
            }
            
        } else {
            yield put({ type: monitorIntegralActions.LISTA_DETALLES_SS_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.LISTA_DETALLES_SS_FAILURE });
    }
}

export function* detalleSSSaga() {
    yield takeLatest(monitorIntegralActions.LISTA_DETALLES_SS_REQUEST, detalleSS)
}

function* infoDetalleSS(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idSolicitudServicio = action.idSolicitudServicio

        const response = yield call(infoDetalleSSCall, token, idSolicitudServicio);
        console.log(response)

        if(response.status === 200){
            let infoDetalleSS = response.data[0];
            //Coordenadas
            if(infoDetalleSS.ubicacionByIdUbicacion.coordenadasByIdUbicacion){
                let {coordenadasByIdUbicacion} = infoDetalleSS.ubicacionByIdUbicacion;
                let coordenadasArray = [];
                for(let j=0; j<coordenadasByIdUbicacion.length; j++) {
                    let coordX =coordenadasByIdUbicacion[j].coordenadaX;
                    let coordY = coordenadasByIdUbicacion[j].coordenadaY;
                    let xyArray = [coordX, coordY];
                    coordenadasArray.push(xyArray);
                }
                infoDetalleSS.coordenadas = coordenadasArray;
            }
            else{
                infoDetalleSS.coordenadas = []
            }

            infoDetalleSS.ubicacion = '';
            if (infoDetalleSS.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional) {
                infoDetalleSS.area = infoDetalleSS.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.idAreaFuncional + " - " + infoDetalleSS.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                infoDetalleSS.idAreaFuncional = infoDetalleSS.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.idAreaFuncional;
                infoDetalleSS.areaDetalle = infoDetalleSS.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                infoDetalleSS.ubicacion = infoDetalleSS.areaDetalle;
                infoDetalleSS.plano = infoDetalleSS.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.rutaImagen
            } else {
                infoDetalleSS.area = "No tiene Area Funcional";
                infoDetalleSS.idAreaFuncional = null;
            }

            if (infoDetalleSS.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional) {
                infoDetalleSS.unidad = infoDetalleSS.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.idUnidadFuncional + " - " + infoDetalleSS.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                infoDetalleSS.idUnidadFuncional = infoDetalleSS.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.idUnidadFuncional;
                infoDetalleSS.unidadDetalle = infoDetalleSS.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                infoDetalleSS.ubicacion += ', ' + infoDetalleSS.unidadDetalle;
                infoDetalleSS.plano = infoDetalleSS.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.rutaImagen
            } else {
                infoDetalleSS.unidad = "No tiene Unidad Funcional";
                infoDetalleSS.unidadDetalle = "No tiene Unidad Funcional";
                infoDetalleSS.idUnidadFuncional = null;
            }

            if (infoDetalleSS.ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional) {
                infoDetalleSS.interior = infoDetalleSS.ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.idInteriorFuncional + " - " + infoDetalleSS.ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional;
                infoDetalleSS.idInteriorFuncional = infoDetalleSS.ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.idInteriorFuncional;
                infoDetalleSS.interiorDetalle = infoDetalleSS.ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional;
                infoDetalleSS.ubicacion += ', ' + infoDetalleSS.interiorDetalle;
            } else {
                infoDetalleSS.interiorDetalle = "No tiene Interior Funcional";
                infoDetalleSS.interior = "No tiene Interior Funcional";
                infoDetalleSS.idInteriorFuncional = null
            }
            
            if (infoDetalleSS.ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional) {
                infoDetalleSS.subInterior = infoDetalleSS.ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.idSubInteriorFuncional + " - " + infoDetalleSS.ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                infoDetalleSS.idSubInteriorFuncional = infoDetalleSS.ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.idSubInteriorFuncional;
                infoDetalleSS.subInteriorDetalle = infoDetalleSS.ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                infoDetalleSS.ubicacion += ', ' + infoDetalleSS.subInteriorDetalle;
            } else {
                infoDetalleSS.subInterior = "No tiene SubInterior Funcional";
                infoDetalleSS.idSubInteriorFuncional = null;
            }
            if(infoDetalleSS.servicioByIdServicio){
                infoDetalleSS.servicio = infoDetalleSS.servicioByIdServicio.nombre;
            }

            yield put({ type: monitorIntegralActions.INFO_DETALLE_SS_SUCCESS, infoDetalleSS, reiniciarMapa: true});
            
        } else {
            yield put({ type: monitorIntegralActions.INFO_DETALLE_SS_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.INFO_DETALLE_SS_REQUEST });
    }
}

export function* infoDetalleSSSaga() {
    yield takeLatest(monitorIntegralActions.INFO_DETALLE_SS_REQUEST, infoDetalleSS)
}


// SAGA PARA TABLERO DE OPERACIONES
function* grupoServiciosActivos(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = 0;
        const response = yield call(grupoServiciosCall, token, page );

        if(response.status === 200){
            let listaGruposServicios = response.data.data;
            yield put({ type: monitorIntegralActions.LISTA_GRUPOSERVICIOS_SUCCESS, listaGruposServicios});
        } else {
            yield put({ type: monitorIntegralActions.LISTA_GRUPOSERVICIOS_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.LISTA_GRUPOSERVICIOS_FAILURE });
    }
}

export function* grupoServiciosActivosSaga() {
    yield takeLatest(monitorIntegralActions.LISTA_GRUPOSERVICIOS_REQUEST, grupoServiciosActivos)
}
// saga obtener servicio por grupo servicio
function* ServicioPorGrupoServicio(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let idGrupoSer; 
        action.filtro.busqueda ? idGrupoSer = action.filtro.busqueda : idGrupoSer = '';
        let tipo = action.filtro.estadoFolios;
         
        const response = yield call(servicioPorGrupoServiciosCall, token, page, idGrupoSer, tipo);
        console.log('response ServicioPorGrupoServicio', response)

        if(response.status === 200){
            let servicioPorGrupo = response.data.data;
            let servicioPorGrupoCantidad = response.data.cantidad;

            for(let i in servicioPorGrupo){
                servicioPorGrupo[i].cerrados = []
                servicioPorGrupo[i].dataTableroResponseByIdServicioLocal = []

                if(servicioPorGrupo[i].grupoSerByIdGrupoSer){
                    servicioPorGrupo[i].grupoServicio = servicioPorGrupo[i].grupoSerByIdGrupoSer.nombre
                }
                if(servicioPorGrupo[i].dataTableroResponseByIdServicio) {
                    servicioPorGrupo[i].dataTableroResponseByIdServicioLocal = servicioPorGrupo[i].dataTableroResponseByIdServicio.filter((i) => {return i.color !== 'Verde' && i.color !== 'Rojo'})

                    for(let x in servicioPorGrupo[i].dataTableroResponseByIdServicio){
                        if(servicioPorGrupo[i].dataTableroResponseByIdServicio[x].color === 'Verde'){
                            
                            servicioPorGrupo[i].cerrados.push(servicioPorGrupo[i].dataTableroResponseByIdServicio[x])
                        }
                        if(servicioPorGrupo[i].dataTableroResponseByIdServicio[x].color === 'Rojo'){
                            
                            servicioPorGrupo[i].cerrados.push(servicioPorGrupo[i].dataTableroResponseByIdServicio[x])
                        }
                    }

                }
            }

            console.log('servicioPorGrupo', servicioPorGrupo)


            yield put({ type: monitorIntegralActions.SERVICIO_POR_GRUPOSERVICIO_SUCCESS, servicioPorGrupo, servicioPorGrupoCantidad});
        } else {
            yield put({ type: monitorIntegralActions.SERVICIO_POR_GRUPOSERVICIO_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.SERVICIO_POR_GRUPOSERVICIO_FAILURE });
    }
}

export function* ServicioPorGrupoServicioSaga() {
    yield takeLatest(monitorIntegralActions.SERVICIO_POR_GRUPOSERVICIO_REQUEST, ServicioPorGrupoServicio)
}

function* solicitudesDeServicioPorServicio(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const idServicio = action.idServicio;
        const color = action.color;
        const idGrupoSer = action.idGrupoSer;

        console.log('saga',idServicio, color, page, idGrupoSer)
         
        const response = yield call(solicitudesDeServicioPorServicioCall, token,idServicio, color, page, idGrupoSer );
        console.log('response solicitudesDeServicioPorServicio', response)

        if(response.status === 200){
            let solicitudesDeServicioPorServicio = response.data.data;
            let solicitudesDeServicioPorServicioCantidad = response.data.cantidad;
            let solicitudesDeServicioPorServicioPorcentaje = response.data.porcentaje.toFixed(2);

            for(let i in solicitudesDeServicioPorServicio) {
                if(solicitudesDeServicioPorServicio[i].fallaByIdFalla){
                    solicitudesDeServicioPorServicio[i].descripcionFalla = solicitudesDeServicioPorServicio[i].fallaByIdFalla.nombre
                }
                
                if(solicitudesDeServicioPorServicio[i].servicioByIdServicio){
                    solicitudesDeServicioPorServicio[i].servicio = solicitudesDeServicioPorServicio[i].servicioByIdServicio.nombre
                }

                if (solicitudesDeServicioPorServicio[i].ubicacionByIdUbicacion) {
                    if (solicitudesDeServicioPorServicio[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional) {
                        solicitudesDeServicioPorServicio[i].area = solicitudesDeServicioPorServicio[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.idAreaFuncional +" - "+ solicitudesDeServicioPorServicio[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional
                        
                    } else {
                        solicitudesDeServicioPorServicio[i].area = "No tiene Area Funcional"
                    }

                    if (solicitudesDeServicioPorServicio[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional) {
                        solicitudesDeServicioPorServicio[i].unidad = solicitudesDeServicioPorServicio[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.idUnidadFuncional+" - "+ solicitudesDeServicioPorServicio[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional
                       
                    } else {
                        solicitudesDeServicioPorServicio[i].unidad = "No tiene Unidad Funcional"
                    }

                    if (solicitudesDeServicioPorServicio[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional) {
                        solicitudesDeServicioPorServicio[i].interior = solicitudesDeServicioPorServicio[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.idInteriorFuncional+" - "+ solicitudesDeServicioPorServicio[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional
                        
                    } else {
                        solicitudesDeServicioPorServicio[i].interior = "No tiene Interior Funcional"
                    }
                }
            }

            yield put({ type: monitorIntegralActions.SOLICITUDES_DE_SERVICIO_POR_SERVICIO_SUCCESS, solicitudesDeServicioPorServicio, solicitudesDeServicioPorServicioCantidad, solicitudesDeServicioPorServicioPorcentaje});
        } else {
            yield put({ type: monitorIntegralActions.SOLICITUDES_DE_SERVICIO_POR_SERVICIO_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.SOLICITUDES_DE_SERVICIO_POR_SERVICIO_FAILURE });
    }
}

export function* solicitudesDeServicioPorServicioSaga() {
    yield takeLatest(monitorIntegralActions.SOLICITUDES_DE_SERVICIO_POR_SERVICIO_REQUEST, solicitudesDeServicioPorServicio)
}

function* cantidadFoliosPorIdGrupoServicio(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        let estado;
        action.estado ? estado = action.estado : estado = ''
        let idGrupoSer;
        action.idGrupoSer ? idGrupoSer = action.idGrupoSer : idGrupoSer = ''
         
        const response = yield call(obtenerCantidadFoliosPorIdGrupoServCall, token, estado, idGrupoSer );
        console.log('response cantidadFoliosPorIdGrupoServicio', response)

        if(response.status === 200){
            let cantidadFoliosPorIdGrupoServ = response.data;

            yield put({ type: monitorIntegralActions.CANTIDAD_FOLIOS_POR_IDGRUPOSERVICIO_SUCCESS, cantidadFoliosPorIdGrupoServ });
        } else {
            yield put({ type: monitorIntegralActions.CANTIDAD_FOLIOS_POR_IDGRUPOSERVICIO_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.CANTIDAD_FOLIOS_POR_IDGRUPOSERVICIO_FAILURE });
    }
}

export function* cantidadFoliosPorIdGrupoServicioSaga() {
    yield takeLatest(monitorIntegralActions.CANTIDAD_FOLIOS_POR_IDGRUPOSERVICIO_REQUEST, cantidadFoliosPorIdGrupoServicio)
}


function* getDataEncuestasSatisfaccion(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idGrupoSer  = ''; let idCentroCosto = ''; let idClasificacionGerencia = ''; let idMes = ''; let anio = '';

        if(action.idGrupoSer){ idGrupoSer = "&idGrupoSer=" + action.idGrupoSer }
        if(action.idCentroCosto){ idCentroCosto = "&idCentroCosto=" + action.idCentroCosto }
        if(action.idClasificacionGerencia){ idClasificacionGerencia = "&idClasificacionGerencia=" + action.idClasificacionGerencia }
        if(action.idMes){ idMes = "&idMes=" + action.idMes }
        if(action.anio){ anio = "&anio=" + action.anio }
         
        const response = yield call(getDataEncuestasSatisfaccionCall, token, idGrupoSer, idCentroCosto, idClasificacionGerencia, idMes, anio );
        console.log('response', response)

        if(response.status === 200){
            let dataEncustasSatisfaccion = response.data.data;
            yield put({ type: monitorIntegralActions.DATA_GRAFICAS_ENCUESTAS_SATISFACCION_SUCCESS, dataEncustasSatisfaccion});
        } else {
            yield put({ type: monitorIntegralActions.DATA_GRAFICAS_ENCUESTAS_SATISFACCION_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.DATA_GRAFICAS_ENCUESTAS_SATISFACCION_FAILURE });
    }
} export function* getDataEncuestasSatisfaccionSaga() {
    yield takeLatest(monitorIntegralActions.DATA_GRAFICAS_ENCUESTAS_SATISFACCION_REQUEST, getDataEncuestasSatisfaccion)
}

function* getDataEncuestasDesempeno(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idMes = ''; let anio = ''; let idServicio = '';

        if(action.idServicio){ idServicio = "&idServicio=" + action.idServicio }
        if(action.idMes){ idMes = "&idMes=" + action.idMes }
        if(action.anio){ anio = "&anio=" + action.anio }
         
        const response = yield call(getDataEncuestasDesempenoCall, token, idMes, anio, idServicio );
        console.log('response', response);

        if(response.status === 200){
            let dataGraficasByServicio = response.data.data;
            yield put({ type: monitorIntegralActions.DATA_GRAFICAS_ENCUSTAS_SATISFACCION_SERVICIO_SUCCESS, dataGraficasByServicio});
        } else {
            yield put({ type: monitorIntegralActions.DATA_GRAFICAS_ENCUSTAS_SATISFACCION_SERVICIO_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.DATA_GRAFICAS_ENCUSTAS_SATISFACCION_SERVICIO_FAILURE });
    }
} export function* getDataEncuestasDesempenoSaga() {
    yield takeLatest(monitorIntegralActions.DATA_GRAFICAS_ENCUSTAS_SATISFACCION_SERVICIO_REQUEST, getDataEncuestasDesempeno)
}

function* getDataQuejasReclamos(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idGrupoSer = ''; let idClasificacionGerencia = ''; let idServicio = '';

        if(action.idClasificacionGerencia){ idClasificacionGerencia = "&idClasificacionGerencia=" + action.idClasificacionGerencia }
        if(action.idServicio){ idServicio = "&idServicio=" + action.idServicio }
        if(action.idGrupoSer){ idGrupoSer = "&idGrupoSer=" + action.idGrupoSer }
         
        const response = yield call(getDataQuejasReclamosCall, token, idGrupoSer, idServicio, idClasificacionGerencia );
        console.log('response', response);

        if(response.status === 200){
            let dataGraficasQuejas = response.data.data;
            yield put({ type: monitorIntegralActions.DATA_GRAFICAS_QUEJAS_SUCCESS, dataGraficasQuejas});
        } else {
            yield put({ type: monitorIntegralActions.DATA_GRAFICAS_QUEJAS_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.DATA_GRAFICAS_QUEJAS_FAILURE });
    }
} export function* getDataQuejasReclamosSaga() {
    yield takeLatest(monitorIntegralActions.DATA_GRAFICAS_QUEJAS_REQUEST, getDataQuejasReclamos)
}

function* getDataEvaluacionDesempeno(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idGrupoSer = ''; let idClasificacionGerencia = ''; let idServicio = ''; let idMes = ''; let anio = '';

        if(action.idClasificacionGerencia){ idClasificacionGerencia = "&idClasificacionGerencia=" + action.idClasificacionGerencia }
        if(action.idServicio){ idServicio = "&idServicio=" + action.idServicio }
        if(action.idGrupoSer){ idGrupoSer = "&idGrupoSer=" + action.idGrupoSer }
        if(action.idMes){ idMes = "&idMes=" + action.idMes }
        if(action.anio){ anio = "&anio=" + action.anio }
         
        const response = yield call(getDataEvaluacionDesempenoCall, token, idMes, anio, idServicio, idGrupoSer, idClasificacionGerencia );
        console.log('response', response);

        if(response.status === 200){
            let dataGraficasEvaluacionDesempeno = response.data.data;
            yield put({ type: monitorIntegralActions.DATA_GRAFICAS_EVALUACION_DESEMPENO_SUCCESS, dataGraficasEvaluacionDesempeno});
        } else {
            yield put({ type: monitorIntegralActions.DATA_GRAFICAS_EVALUACION_DESEMPENO_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.DATA_GRAFICAS_EVALUACION_DESEMPENO_FAILURE });
    }
} export function* getDataEvaluacionDesempenoSaga() {
    yield takeLatest(monitorIntegralActions.DATA_GRAFICAS_EVALUACION_DESEMPENO_REQUEST, getDataEvaluacionDesempeno)
}

function* listaServiciosByClasificacionGerencia(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(listaServiciosByClasificacionGerenciaCall, token, action.idClasificacionGerencia);
        console.log(response);
        if(response.status === 200){
            let listaServiciosByClasificacionGerencia = response.data;

            yield put({ type: monitorIntegralActions.LISTA_SERVICIOS_BY_CLASIFICACION_GERENCIA_SUCCESS, listaServiciosByClasificacionGerencia });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: monitorIntegralActions.LISTA_SERVICIOS_BY_CLASIFICACION_GERENCIA_FAILURE });
        }
    } catch (error) {
        yield put({ type: monitorIntegralActions.LISTA_SERVICIOS_BY_CLASIFICACION_GERENCIA_FAILURE });
    }
} export function* listaServiciosByClasificacionGerenciaSaga(){
    yield takeLatest(monitorIntegralActions.LISTA_SERVICIOS_BY_CLASIFICACION_GERENCIA_REQUEST, listaServiciosByClasificacionGerencia)
}

function* getDataQuejasReclamosByServicio(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idGrupoSer = ''; let idClasificacionGerencia = ''; let idServicio = '';

        if(action.idClasificacionGerencia){ idClasificacionGerencia = "&idClasificacionGerencia=" + action.idClasificacionGerencia }
        if(action.idServicio){ idServicio = "&idServicio=" + action.idServicio }
        if(action.idGrupoSer){ idGrupoSer = "&idGrupoSer=" + action.idGrupoSer }
         
        const response = yield call(getDataQuejasReclamosCall, token, idGrupoSer, idServicio, idClasificacionGerencia );
        console.log('response', response);

        if(response.status === 200){
            let dataGraficasQuejasByServicio = response.data.data;
            yield put({ type: monitorIntegralActions.DATA_GRAFICAS_QUEJAS_SERVICIO_SUCCESS, dataGraficasQuejasByServicio});
        } else {
            yield put({ type: monitorIntegralActions.DATA_GRAFICAS_QUEJAS_SERVICIO_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.DATA_GRAFICAS_QUEJAS_SERVICIO_FAILURE });
    }
} export function* getDataQuejasReclamosByServicioSaga() {
    yield takeLatest(monitorIntegralActions.DATA_GRAFICAS_QUEJAS_SERVICIO_REQUEST, getDataQuejasReclamosByServicio)
}

function* getDataPromedioSatisfaccion(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idGrupoSer  = ''; let idCentroCosto = ''; let idClasificacionGerencia = ''; let idMes = ''; let anio = '';

        if(action.idGrupoSer){ idGrupoSer = "&idGrupoSer=" + action.idGrupoSer }
        if(action.idCentroCosto){ idCentroCosto = "&idCentroCosto=" + action.idCentroCosto }
        if(action.idClasificacionGerencia){ idClasificacionGerencia = "&idClasificacionGerencia=" + action.idClasificacionGerencia }
        if(action.idMes){ idMes = "&idMes=" + action.idMes }
        if(action.anio){ anio = "&anio=" + action.anio }
         
        const response = yield call(getDataPromedioSatisfaccionCall, token, idGrupoSer, idCentroCosto, idClasificacionGerencia, idMes, anio );
        console.log('response', response)

        if(response.status === 200){
            let dataGraficaPromedioSatisfaccion = response.data.data;
            yield put({ type: monitorIntegralActions.DATA_GRAFICA_PROMEDIO_SATISFACCION_SUCCESS, dataGraficaPromedioSatisfaccion});
        } else {
            yield put({ type: monitorIntegralActions.DATA_GRAFICA_PROMEDIO_SATISFACCION_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.DATA_GRAFICA_PROMEDIO_SATISFACCION_FAILURE });
    }
} export function* getDataPromedioSatisfaccionSaga() {
    yield takeLatest(monitorIntegralActions.DATA_GRAFICA_PROMEDIO_SATISFACCION_REQUEST, getDataPromedioSatisfaccion)
}

function* getDataEstandaresByTipo(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idGrupoSer = ''; let idClasificacionGerencia = ''; let idServicio = ''; let idMes = ''; let anio = '';

        if(action.idClasificacionGerencia){ idClasificacionGerencia = "&idClasificacionGerencia=" + action.idClasificacionGerencia }
        if(action.idServicio){ idServicio = "&idServicio=" + action.idServicio }
        if(action.idGrupoSer){ idGrupoSer = "&idGrupoSer=" + action.idGrupoSer }
        if(action.idMes){ idMes = "&idMes=" + action.idMes }
        if(action.anio){ anio = "&anio=" + action.anio }
         
        const response = yield call(getDataEstandaresByTipoCall, token, idMes, anio, idServicio, idGrupoSer, idClasificacionGerencia );
        console.log('response', response);

        if(response.status === 200){
            let dataEstandaresByTipo = response.data.data;
            yield put({ type: monitorIntegralActions.DATA_ESTANDARES_BY_TIPO_SUCCESS, dataEstandaresByTipo});
        } else {
            yield put({ type: monitorIntegralActions.DATA_ESTANDARES_BY_TIPO_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.DATA_ESTANDARES_BY_TIPO_FAILURE });
    }
} export function* getDataEstandaresByTipoSaga() {
    yield takeLatest(monitorIntegralActions.DATA_ESTANDARES_BY_TIPO_REQUEST, getDataEstandaresByTipo)
}

// RDO | Monitoreo de Módulos Operativos
function* getDataRDOSolicitudesServicio(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idGrupoSer = ''; let idClasificacionGerencia = ''; let idServicio = ''; let idPartida = '';

        if(action.idServicio){ idServicio = "&idServicio=" + action.idServicio }
        if(action.idGrupoSer){ idGrupoSer = "&idGrupoSer=" + action.idGrupoSer }
        if(action.idPartida){ idPartida = "&idPartida=" + action.idPartida }
        if(action.idClasificacionGerencia){ idClasificacionGerencia = "&idClasificacionGerencia=" + action.idClasificacionGerencia }
         
        const response = yield call(getDataRDOSolicitudesServicioCall, token, idServicio, idGrupoSer, idPartida, idClasificacionGerencia );
        console.log('response', response);

        if(response.status === 200){
            let dataRDOSolicitudes = response.data.data;

            let etiquetas = [];
            let informacion = [];
            let totalEncuestas = 0;
            let etiquetasLarga = [];
                if(dataRDOSolicitudes){
                    for(let i in dataRDOSolicitudes){
                        etiquetas.push(dataRDOSolicitudes[i].etiqueta);
                        informacion.push(dataRDOSolicitudes[i].cantidad);
                        etiquetasLarga.push(dataRDOSolicitudes[i].etiquetaLarga);
                        totalEncuestas += dataRDOSolicitudes[i].cantidad;
                    }
                }

                let infoRDO_SolictudesServicio = {
                    'etiquetas': etiquetas,
                    'informacion': informacion,
                    'total': totalEncuestas,
                    'eLargas': etiquetasLarga
                };

            yield put({ type: monitorIntegralActions.MONITOR_MODULOS_OPERATIVOS_SS_SUCCESS, dataRDOSolicitudes, infoRDO_SolictudesServicio});
        } else {
            yield put({ type: monitorIntegralActions.MONITOR_MODULOS_OPERATIVOS_SS_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.MONITOR_MODULOS_OPERATIVOS_SS_FAILURE });
    }
} export function* getDataRDOSolicitudesServicioSaga() {
    yield takeLatest(monitorIntegralActions.MONITOR_MODULOS_OPERATIVOS_SS_REQUEST, getDataRDOSolicitudesServicio)
}

function* getDataRDOServiciosPlanificado(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idGrupoSer = ''; let idClasificacionGerencia = ''; let idServicio = ''; let idPartida = '';

        if(action.idServicio){ idServicio = "&idServicio=" + action.idServicio }
        if(action.idGrupoSer){ idGrupoSer = "&idGrupoSer=" + action.idGrupoSer }
        if(action.idPartida){ idPartida = "&idPartida=" + action.idPartida }
        if(action.idClasificacionGerencia){ idClasificacionGerencia = "&idClasificacionGerencia=" + action.idClasificacionGerencia }
         
        const response = yield call(getDataRDOServiciosPlanificadosCall, token, idServicio, idGrupoSer, idPartida, idClasificacionGerencia );
        console.log('response', response);

        if(response.status === 200){
            let dataRDOPlanificados = response.data.data;

            let etiquetas = [];
            let informacion = [];
            let totalEncuestas = 0;
            let etiquetasLarga = [];
            let enProceso = 0;
                if(dataRDOPlanificados){
                    for(let i in dataRDOPlanificados){
                        etiquetas.push(dataRDOPlanificados[i].etiqueta);
                        informacion.push(dataRDOPlanificados[i].cantidad);
                        etiquetasLarga.push(dataRDOPlanificados[i].etiquetaLarga);
                        totalEncuestas += dataRDOPlanificados[i].cantidad;

                        if(dataRDOPlanificados[i].etiqueta === 'En proceso'){
                            enProceso += dataRDOPlanificados[i].cantidad;
                        }
                    }
                }

                const infoRDO_ServiciosPlanificados = {
                    'etiquetas': etiquetas,
                    'informacion': informacion,
                    'total': totalEncuestas,
                    'eLargas': etiquetasLarga,
                    'enProceso': enProceso,
                };

                console.log('infoRDO_ServiciosPlanificados', infoRDO_ServiciosPlanificados)

            yield put({ type: monitorIntegralActions.MONITOR_MODULOS_OPERATIVOS_SP_SUCCESS, dataRDOPlanificados, infoRDO_ServiciosPlanificados});
        } else {
            yield put({ type: monitorIntegralActions.MONITOR_MODULOS_OPERATIVOS_SP_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.MONITOR_MODULOS_OPERATIVOS_SP_FAILURE });
    }
} export function* getDataRDOServiciosPlanificadoSaga() {
    yield takeLatest(monitorIntegralActions.MONITOR_MODULOS_OPERATIVOS_SP_REQUEST, getDataRDOServiciosPlanificado)
}

function* getDatosMonitorPlanificados(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idGrupoSer = ''; let idClasificacionGerencia = ''; let idServicio = ''; let idPartida = '';

        if(action.idServicio){ idServicio = "&idServicio=" + action.idServicio }
        if(action.idGrupoSer){ idGrupoSer = "&idGrupoSer=" + action.idGrupoSer }
        if(action.idPartida){ idPartida = "&idPartida=" + action.idPartida }
        if(action.idClasificacionGerencia){ idClasificacionGerencia = "&idClasificacionGerencia=" + action.idClasificacionGerencia }
         
        const response = yield call(getDatosMonitorPlanificadosCall, token, idServicio, idGrupoSer, idPartida, idClasificacionGerencia );
        console.log('response', response);

        if(response.status === 200){
            let monitorModulosPlanificadosDatos = response.data.data;

            yield put({ type: monitorIntegralActions.MONITOR_MODULOS_PLANIFICADOS_DATOS_SUCCESS, monitorModulosPlanificadosDatos });
        } else {
            yield put({ type: monitorIntegralActions.MONITOR_MODULOS_PLANIFICADOS_DATOS_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.MONITOR_MODULOS_PLANIFICADOS_DATOS_FAILURE });
    }
} export function* getDatosMonitorPlanificadosSaga() {
    yield takeLatest(monitorIntegralActions.MONITOR_MODULOS_PLANIFICADOS_DATOS_REQUEST, getDatosMonitorPlanificados)
}

function* getDataRDOServiciosPublicos(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');
         
        const response = yield call(getDataRDOServiciosPublicosCall, token);
        console.log('response', response);

        if(response.status === 200){
            let dataRDOServiciosPublicos = response.data.data;
            yield put({ type: monitorIntegralActions.DATA_SERV_PUBLIC_SUCCESS, dataRDOServiciosPublicos});
        } else {
            yield put({ type: monitorIntegralActions.DATA_SERV_PUBLIC_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.DATA_SERV_PUBLIC_FAILURE });
    }
} export function* getDataRDOServiciosPublicosSaga() {
    yield takeLatest(monitorIntegralActions.DATA_SERV_PUBLIC_REQUEST, getDataRDOServiciosPublicos)
}

//Reporte Gráfico 
function* getListReporteGrafico(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');

        const { gerencias, gruposSer, servicios, estados, fechas, idAreaFuncional, idUnidadFuncional, idInteriorFuncional, clasificaciones } = action.filtro;
        const { orden, field } = action.order;

        let idsGerencias = [];
        if(gerencias){ if(gerencias.length > 0){ for(let i in gerencias){ idsGerencias.push(Number(gerencias[i].split(' - ')[0])); } } }

        let idsGruposSer = [];
        if(gruposSer){ if(gruposSer.length > 0){  gruposSer.forEach(element => {  idsGruposSer.push(element.split(' - ')[0]); }); }  }

        let idsServicios = [];
        if(servicios){ if(servicios.length > 0){ for(let i in servicios){ idsServicios.push(Number(servicios[i].split(' - ')[0])); } } }

        let estadosSeleccionados = [];
        if(estados){ if(estados.length > 0){ for(let i in estados){ estadosSeleccionados.push(estados[i]); } } }

        let clasificacionesSeleccionadas = [];
        if(clasificaciones){ if(clasificaciones.length > 0){ for(let i in clasificaciones){ clasificacionesSeleccionadas.push(clasificaciones[i]); } } }

        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0');
        let yyyy = today.getFullYear();
        let fechaIncio = yyyy + '-01-01';
        let fechaFin = yyyy + '-' + mm + '-' + dd;
        if(fechas){ if(fechas.length > 0){ fechaIncio = fechas[0].split("-").reverse().join("-"); fechaFin = fechas[1].split("-").reverse().join("-"); } }

        let idsAreasFuncionales = [];
        if(idAreaFuncional){ idsAreasFuncionales.push(idAreaFuncional); }

        let idsUnidades = [];
        if(idUnidadFuncional){ idsUnidades.push(idUnidadFuncional); }

        let idsInteriores = [];
        if(idInteriorFuncional){ idsInteriores.push(idInteriorFuncional); }

        const data = {
            "idsGerencias": action.agrupamiento === 'Gerencia' ? [action.agrupado.identificador] : idsGerencias.length > 0 ? idsGerencias : null,
            "idsGruposSer": action.agrupamiento === 'GrupoSer' ? [action.agrupado.identificador] :idsGruposSer.length > 0 ? idsGruposSer : null,
            "idsServicios":  action.agrupamiento === 'Servicio' ? [action.agrupado.identificador] :idsServicios.length > 0 ? idsServicios : null,
            "idsAreas": action.agrupamiento === 'Area' ? [action.agrupado.identificador] :idsAreasFuncionales.length > 0 ? idsAreasFuncionales : null,
            "idsUnidades": action.agrupamiento === 'Unidad' ? [action.agrupado.identificador] :idsUnidades.length > 0 ? idsUnidades : null,
            "idsInteriores": action.agrupamiento === 'Interior' ? [action.agrupado.identificador] :idsInteriores.length > 0 ? idsInteriores : null,
            "estados": action.agrupamiento === 'Estado' ? [action.agrupado.etiqueta] : estadosSeleccionados.length > 0 ? estadosSeleccionados : null,
            "clasificaciones": action.agrupamiento === 'Clasificacion' ? [action.agrupado.etiqueta] : clasificacionesSeleccionadas.length > 0 ? clasificacionesSeleccionadas : null,
            "fechaAltaInicio": action.agrupamiento === 'Fecha' ? action.agrupado.etiqueta : fechaIncio,
            "fechaAltaFin": action.agrupamiento === 'Fecha' ? action.agrupado.etiqueta : fechaFin,
            "page": (action.page - 1) * 48,
            "offset": 48,
            "order": orden,
            "field": field,
            "agrupamiento": null
        }

        console.log('data', data)

        const response = yield call(getListReporteGraficoCall, token, data );
        console.log(response)

        if(response.status === 200){
            let listRerpoteGrafico = response.data.data;
            let listReporteGraficoCount = response.data.cantidad;

            if(action.agrupado){
                const listReporteGraficoAgrupado = action.agrupado.listReporteGraficoAgrupado;
                const listReporteGraficoAgrupadoCount = action.agrupado.listReporteGraficoAgrupadoCount;
                for(let i in listReporteGraficoAgrupado){
                    if(action.agrupado.identificador === listReporteGraficoAgrupado[i].identificador){
                        listReporteGraficoAgrupado[i].agrupados = response.data;
                        listReporteGraficoAgrupado[i].agrupados.page = action.page;
                        for(let x in listReporteGraficoAgrupado[i].agrupados.data){
                            listReporteGraficoAgrupado[i].agrupados.data[x].identificador = parseInt(x) + 1;
                        };
                    }
                }
                console.log(`listaReportesDinamicoAgrupado cambio`, listReporteGraficoAgrupado)
                yield put({ type: monitorIntegralActions.GET_REPORTE_GRAFICO_GRUPO_SUCCESS, listReporteGraficoAgrupado, listReporteGraficoAgrupadoCount});
                yield put({ type: monitorIntegralActions.GET_REPORTE_GRAFICO_FAILURE });
            }
            else{
                yield put({ type: monitorIntegralActions.GET_REPORTE_GRAFICO_SUCCESS, listRerpoteGrafico, listReporteGraficoCount});
            } 
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: monitorIntegralActions.GET_REPORTE_GRAFICO_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.GET_REPORTE_GRAFICO_FAILURE });
    }
}

export function* getListReporteGraficoSaga() {
    yield takeLatest(monitorIntegralActions.GET_REPORTE_GRAFICO_REQUEST, getListReporteGrafico)
}

function* getListReporteGraficoGrupo(action){
    try{
        console.log(action)
        const token = sessionStorage.getItem('token');

        const { gerencias, gruposSer, servicios, estados, fechas, idAreaFuncional, idUnidadFuncional, idInteriorFuncional, clasificaciones } = action.filtro;
        const { orden, field } = action.order;

        let idsGerencias = [];
        if(gerencias){ if(gerencias.length > 0){ for(let i in gerencias){ idsGerencias.push(Number(gerencias[i].split(' - ')[0])); } } }

        let idsGruposSer = [];
        if(gruposSer){ if(gruposSer.length > 0){  gruposSer.forEach(element => {  idsGruposSer.push(element.split(' - ')[0]); }); }  }

        let idsServicios = [];
        if(servicios){ if(servicios.length > 0){ for(let i in servicios){ idsServicios.push(Number(servicios[i].split(' - ')[0])); } } }

        let estadosSeleccionados = [];
        if(estados){ if(estados.length > 0){ for(let i in estados){ estadosSeleccionados.push(estados[i]); } } }

        let clasificacionesSeleccionadas = [];
        if(clasificaciones){ if(clasificaciones.length > 0){ for(let i in clasificaciones){ clasificacionesSeleccionadas.push(clasificaciones[i]); } } }

        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0');
        let yyyy = today.getFullYear();
        let fechaIncio = yyyy + '-01-01';
        let fechaFin = yyyy + '-' + mm + '-' + dd;
        if(fechas){ if(fechas.length > 0){ fechaIncio = fechas[0].split("-").reverse().join("-"); fechaFin = fechas[1].split("-").reverse().join("-"); } }

        let idsAreasFuncionales = [];
        if(idAreaFuncional){ idsAreasFuncionales.push(idAreaFuncional); }

        let idsUnidades = [];
        if(idUnidadFuncional){ idsUnidades.push(idUnidadFuncional); }

        let idsInteriores = [];
        if(idInteriorFuncional){ idsInteriores.push(idInteriorFuncional); }

        const data = {
            "idsGerencias": idsGerencias.length > 0 ? idsGerencias : null,
            "idsGruposSer": idsGruposSer.length > 0 ? idsGruposSer : null,
            "idsServicios":  idsServicios.length > 0 ? idsServicios : null,
            "idsAreas": idsAreasFuncionales.length > 0 ? idsAreasFuncionales : null,
            "idsUnidades": idsUnidades.length > 0 ? idsUnidades : null,
            "idsInteriores": idsInteriores.length > 0 ? idsInteriores : null,
            "estados": estadosSeleccionados.length > 0 ? estadosSeleccionados : null,
            "clasificaciones": clasificacionesSeleccionadas.length > 0 ? clasificacionesSeleccionadas : null,
            "fechaAltaInicio": fechaIncio,
            "fechaAltaFin": fechaFin,
            "page": (action.pageAgrupado - 1) * 10,
            "offset": 10,
            "order": action.orderAgrupado ? action.orderAgrupado.orden : 'DESC' ,
            "field": action.orderAgrupado ? action.orderAgrupado.field : null,
            "agrupamiento": action.agrupamiento ? action.agrupamiento : null,
        }

        console.log('data', data)

        const response = yield call(getListReporteGraficoGrupoCall, token, data );
        console.log(response)

        if(response.status === 200){
            let listReporteGraficoAgrupado = response.data.data;
            let listReporteGraficoAgrupadoCount = response.data.cantidad;
            const agrupadoConst = action.agrupamiento;

            if(agrupadoConst === 'Fecha'){
                for(let i in listReporteGraficoAgrupado){
                    listReporteGraficoAgrupado[i].nombreTable = listReporteGraficoAgrupado[i].etiqueta.split('-')[2] + '-' + listReporteGraficoAgrupado[i].etiqueta.split('-')[1] + '-' + listReporteGraficoAgrupado[i].etiqueta.split('-')[0];
                }
            }

            for(let i in listReporteGraficoAgrupado){
                const dataAgrupado = {
                    "idsGerencias": agrupadoConst === 'Gerencia' ? [listReporteGraficoAgrupado[i].identificador] : idsGerencias.length > 0 ? idsGerencias : null,
                    "idsGruposSer": agrupadoConst === 'GrupoSer' ? [listReporteGraficoAgrupado[i].identificador] : idsGruposSer.length > 0 ? idsGruposSer : null,
                    "idsServicios":  agrupadoConst === 'Servicio' ? [listReporteGraficoAgrupado[i].identificador] : idsServicios.length > 0 ? idsServicios : null,
                    "idsAreas": agrupadoConst === 'Area' ? [listReporteGraficoAgrupado[i].identificador] : idsAreasFuncionales.length > 0 ? idsAreasFuncionales : null,
                    "idsUnidades": agrupadoConst === 'Unidad' ? [listReporteGraficoAgrupado[i].identificador] : idsUnidades.length > 0 ? idsUnidades : null,
                    "idsInteriores": agrupadoConst === 'Interior' ? [listReporteGraficoAgrupado[i].identificador] : idsInteriores.length > 0 ? idsInteriores : null,
                    "estados": agrupadoConst === 'Estado' ? [listReporteGraficoAgrupado[i].etiqueta] : estadosSeleccionados.length > 0 ? estadosSeleccionados : null,
                    "clasificaciones": agrupadoConst === 'Clasificacion' ? [listReporteGraficoAgrupado[i].etiqueta] : clasificacionesSeleccionadas.length > 0 ? clasificacionesSeleccionadas : null,
                    "fechaAltaInicio": agrupadoConst === 'Fecha' ? listReporteGraficoAgrupado[i].etiqueta : fechaIncio,
                    "fechaAltaFin": agrupadoConst === 'Fecha' ? listReporteGraficoAgrupado[i].etiqueta : fechaFin,
                    "page": (action.page - 1) * 48,
                    "offset": 48,
                    "order": orden,
                    "field": field,
                    "agrupamiento": null
                }

                const responseAgrupado = yield call(getListReporteGraficoCall, token, dataAgrupado);
                if(responseAgrupado.status === 200){
                    listReporteGraficoAgrupado[i].agrupados = responseAgrupado.data;
                    listReporteGraficoAgrupado[i].agrupados.page = 1;
                    for(let x in listReporteGraficoAgrupado[i].agrupados.data){
                        listReporteGraficoAgrupado[i].agrupados.data[x].id = parseInt(x) + 1;
                    };
                }
                else{
                    const showErrorMsg = true;
                    const textMessage = responseAgrupado.message;

                    yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                    yield put({ type: monitorIntegralActions.GET_REPORTE_GRAFICO_GRUPO_FAILURE });
                }
            }

            console.log('listReporteGraficoAgrupado', listReporteGraficoAgrupado);
            
            yield put({ type: monitorIntegralActions.GET_REPORTE_GRAFICO_GRUPO_SUCCESS, listReporteGraficoAgrupado, listReporteGraficoAgrupadoCount});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: monitorIntegralActions.GET_REPORTE_GRAFICO_GRUPO_FAILURE });
        }
    } catch(error){
        yield put({ type: monitorIntegralActions.GET_REPORTE_GRAFICO_GRUPO_FAILURE });
    }
}

export function* getListReporteGraficoGrupoSaga() {
    yield takeLatest(monitorIntegralActions.GET_REPORTE_GRAFICO_GRUPO_REQUEST, getListReporteGraficoGrupo)
}