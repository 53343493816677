import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoGrupoServicio from './ModalNuevoGrupoServicio';
import ModalEditarGrupoServicio from './ModalEditarGrupoServicio';

const { Search } = Input;
const confirm = Modal.confirm;
class CatalogoDeGruposDeServicios extends React.Component {
    componentDidMount(){
        this.props.onGetGruposServicios(1, '');
    }
    state={
        page: 1,
        busqueda: '',
    }
    render() {
        const { fetchingListaGruposServiciosBusqueda, listaGruposServiciosBusqueda, onEstadoGrupoServicio, 
            onGetGruposServicios, onModalEditarGrupoServicio, onModalNuevoGrupoServicio, listaGruposServiciosBusquedaCantidad
        } = this.props;
        console.log('listaGruposServiciosBusqueda');
        console.log(listaGruposServiciosBusqueda);
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        const onBuscarGrupoServicio = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            onGetGruposServicios(1, busqueda);
        }

        const columns = [{
                title: 'Nombre',
                dataIndex: 'nombre',
                key: 'nombre',
            },{
                title: 'Descripción',
                dataIndex: 'descripcion',
                key: 'descripcion',
            },{
                title: 'Fecha alta',
                dataIndex: 'fechaAlta',
                key: 'fechaAlta',
            },{
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.grupoDeServicioActivoInactivo} onChange={() => handleDesactivar(record)}/>
                ),
            },{
                title: 'Editar',
                key: 'editar',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarGrupoDeServicio} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },
        ];
        const onEditar = (key) => {
            onModalEditarGrupoServicio(key, this.state.page, key.equipamientoTec);
        }
        const handleDesactivar = (key) => {
            console.log(key);
            const page = this.state.page;
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del grupo de servicio "'+ key.descripcion +'"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoGrupoServicio(key.idGrupoSer, page)
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onGetGruposServicios(pagination.current, this.state.busqueda);
		}
        const onNuevoRol = () => {
            onModalNuevoGrupoServicio(this.state.page);
        }

        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search className='buscarModulos' placeholder="Ingresa el nombre del grupo de servicio" onSearch={value => onBuscarGrupoServicio(value)} style={{ width: '100%' }}/>
                    </Col>
                    {/* <Col span={7}>
                        <Button onClick={onNuevoRol} disabled={!permisosUsuario.crearGrupoDeServicio} className='nuevoUsuario'>Nuevo Grupo de Servicio</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nuevo Grupo de Servicio' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoRol} disabled={!permisosUsuario.crearGrupoDeServicio} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="nombre" 
                            columns={columns}
                            dataSource={listaGruposServiciosBusqueda}
                            loading={fetchingListaGruposServiciosBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaGruposServiciosBusquedaCantidad, simple: true, current: this.state.page}}
                        />         
                    </Col>
                </Row>
                <ModalNuevoGrupoServicio/>
                <ModalEditarGrupoServicio/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaGruposServiciosBusqueda: state.CatalogosGeneralesReducer.listaGruposServiciosBusqueda,
        fetchingListaGruposServiciosBusqueda: state.CatalogosGeneralesReducer.fetchingListaGruposServiciosBusqueda,
        listaGruposServiciosBusquedaCantidad: state.CatalogosGeneralesReducer.listaGruposServiciosBusquedaCantidad,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetGruposServicios: (page, busqueda) => {
	    	dispatch({ type: "GRUPOS_SERVICIOS_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
        },
        onEstadoGrupoServicio: (idGrupoSer, page) => {
	    	dispatch({ type: "ESTADO_GRUPO_SERVICIO_REQUEST", idGrupoSer: idGrupoSer, page: page });
        },
        onModalNuevoGrupoServicio: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_GRUPO_SERVICIO", showModalNuevoGrupoServicio: true, page: page});
        },
        onModalEditarGrupoServicio: (grupoServicioSeleccionado, page, equipamientoTec) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_GRUPO_SERVICIO", showModalEditarGrupoServicio: true, grupoServicioSeleccionado: grupoServicioSeleccionado, page: page, equipamientoTec});
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoDeGruposDeServicios);