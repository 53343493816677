import React, { Component } from 'react';
import { Form, Button, Row, Col, Spin, message, DatePicker, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";


const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ArchivoPeriodo extends Component {
    formRef = React.createRef();
    state = {
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
    }
    
    render() { 

        const {
            fetchingEditarPeriodoFMD, periodoSeleccionado, page, 
            formularioPeriodo, limpiarFormulario, onLimpiarFormulario,
            onEditarPeriodo, periodosEditados, 
        } = this.props;

        const onFinish = formularioPDF => {
            
            onEditarPeriodo(formularioPeriodo, page, formularioPDF, periodoSeleccionado, periodosEditados );
        }

        if(limpiarFormulario && limpiarFormulario === 'editarPeriodoPDF'){
            this.formRef.current.resetFields();
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
            onLimpiarFormulario();
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        }

        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                })
            }
            var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
            if (info.file.status === 'done') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }
        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };

        return ( 
            <Spin spinning={fetchingEditarPeriodoFMD}>
                <Form {...layout}
                name="formulario"
                onFinish={onFinish}
                
                ref={this.formRef}
                >
                    <Row justify='space-around'>
                        <Col span={24}>
                            <Form.Item
                                label="Archivo PDF"
                                name="pdf"
                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                            >
                                <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                    {this.state.filePDF === false ? (
                                    <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                        <UploadOutlined /> Click para subir Convenio PDF
                                    </Button>
                                    ):false}
                                </Upload>
                            </Form.Item>
                        </Col>
                        
                        <Col span={24} className='marginBoton'>
                            <br/>
                            <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Editar Periodo</Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        formularioPeriodo: state.MecanismosDePagosReducer.formularioPeriodo,
        fetchingEditarPeriodoFMD: state.MecanismosDePagosReducer.fetchingEditarPeriodoFMD,
        periodoSeleccionado: state.MecanismosDePagosReducer.periodoSeleccionado,
        page: state.MecanismosDePagosReducer.page,
        periodosEditados: state.MecanismosDePagosReducer.periodosEditados,
        
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onEditarPeriodo: (formulario, page, formularioPDF, periodoSeleccionado, periodosEditados) => {
            dispatch({ type: 'EDITAR_PERIODO_FMD_REQUEST', formulario, page, formularioPDF, periodoSeleccionado, periodosEditados})
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps) (ArchivoPeriodo);