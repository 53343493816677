import React, { Component } from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoPonderacionServArea from './ModalNuevoPonderacionServArea';
import ModalEditarPonderacionServArea from './ModalEditarPonderacionServArea';


const { Search } = Input;
const confirm = Modal.confirm;

class CatalogoPonderacionServPorArea extends Component {
    componentDidMount(){
        this.props.onGetPonderacionServPorArea(1, '')
    }
    state = {
        page: 1,
        busqueda: ''
    }
    render() { 

        const {onGetPonderacionServPorArea,
            listaPonderacionServPorAreaBusqueda,
            fetchingListaPonderacionServPorAreaBusqueda,
            listaPonderacionServPorAreaBusquedaCantidad,
            onEstadoPonderacionServArea,
            onModalNuevoPonderacionServArea,
            onModalEditarPonderacionServArea,
        } =  this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        const onBuscarPonderacionServ = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            onGetPonderacionServPorArea(1, busqueda);
        };


        const columns = [{
            title: 'Clave Área',
            dataIndex: 'claveArea',
            key: 'claveArea',
        },{
            title: 'Área Funcional',
            dataIndex: 'areaFuncional',
            key: 'areaFuncional',
        },{
            title: 'Servicio',
            dataIndex: 'nombre',
            key: 'nombre',
        }, {
            title: 'Ponderador',
            dataIndex: 'ponderacionServAreaTabla',
            key: 'ponderacionServAreaTabla',
             align: 'right',
        }, {
            title: 'Activo',
            dataIndex: 'activo',
            key: 'activo',
            render: (text, record) => (
                  <Switch checked={text} disabled={!permisosUsuario.ponderadorActivoinactivo} onChange={() => handleDesactivar(record)} />
            ),
        }, {
            title: 'Editar',
            key: 'editar',
            render: (text, record) => (
                  <a className='editarIcono' disabled={!permisosUsuario.editarPonderador} onClick={() => onEditar(record)}><EditOutlined /></a>
            ),
        },
        ];

        const onEditar = (key) => {
            console.log('key');
            console.log(key);
            onModalEditarPonderacionServArea(key, this.state.page);
        }

        const handleDesactivar = (key) => {
            console.log(key);
            const page = this.state.page;
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del ponderador?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoPonderacionServArea(key.idPonderacionServArea, page)
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }

        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onGetPonderacionServPorArea(pagination.current, this.state.busqueda);
        };

       const onNuevoPonderacionServArea = () => {
           onModalNuevoPonderacionServArea(this.state.page)
       }
        return ( 
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                     <Search className='buscarModulos' placeholder="Buscar servicio/área funcional/clave área" onSearch={value => onBuscarPonderacionServ(value)} style={{ width: '100%' }}/> 
                    </Col>
                    {/* <Col span={7}>
                        <Button onClick={onNuevoPonderacionServArea} disabled={!permisosUsuario.crearPonderador} className='nuevoUsuario'>Nuevo Ponderador</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nuevo Ponderador' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoPonderacionServArea} disabled={!permisosUsuario.crearPonderador} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="ponderacionServArea" 
                            columns={columns}
                            dataSource={listaPonderacionServPorAreaBusqueda}
                            loading={fetchingListaPonderacionServPorAreaBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaPonderacionServPorAreaBusquedaCantidad, simple: true, current: this.state.page}}
                        />         
                    </Col>
                </Row>
                <ModalNuevoPonderacionServArea/>
                <ModalEditarPonderacionServArea/>
            </div>
         );
    }
}

const mapStateToProps = state => {
    return {
        listaPonderacionServPorAreaBusqueda: state.MecanismosDePagosReducer.listaPonderacionServPorAreaBusqueda,
        fetchingListaPonderacionServPorAreaBusqueda: state.MecanismosDePagosReducer.fetchingListaPonderacionServPorAreaBusqueda,
        listaPonderacionServPorAreaBusquedaCantidad: state.MecanismosDePagosReducer.listaPonderacionServPorAreaBusquedaCantidad,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onGetPonderacionServPorArea: (page, busqueda) => {
            dispatch({ type: "LISTA_PONDERACION_SERV_POR_AREA_BUSQUEDA_REQUEST", page: page, busqueda: busqueda })
        },
        onEstadoPonderacionServArea: (idPonderacionServArea, page) => {
            dispatch({ type: "ESTADO_PONDERACION_SERV_POR_AREA_REQUEST", idPonderacionServArea: idPonderacionServArea, page: page})
        },
        onModalNuevoPonderacionServArea: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_PONDERACION_SERV_POR_AREA", showModalNuevoPonderacionServPorArea: true, page: page })
        },
        onModalEditarPonderacionServArea: (ponderacionServPorAreaSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_PONDERACION_SERV_POR_AREA", showModalEditarPonderacionServPorArea: true, ponderacionServPorAreaSeleccionado: ponderacionServPorAreaSeleccionado, page: page})
        },
    }
}
 
export default connect (mapStateToProps, mapDispatchToProps)(CatalogoPonderacionServPorArea);