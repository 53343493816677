import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalPresentacionServicioUnitario from './ModalPresentacionServicioUnitario';
import ModalPresentacionServicioGeneral from './ModalPresentacionServicioGeneral';

const ModalPresentacionServicio = () => {
    const dispatch = useDispatch();
    const showModalPresentacionServicioVariable = useSelector((state) =>state.ServiciosVariablesReducer.showModalPresentacionServicioVariable);
    const servicioPresentacionSeleccionado = useSelector((state) =>state.ServiciosVariablesReducer.servicioPresentacionSeleccionado);
       
     //funciones        
     const handleCancel = () => {
          dispatch({ type: "SHOW_MODAL_PRESENTACION_SERVICIO_VARIABLE", showModalPresentacionServicioVariable: false})
          dispatch({ type: "PRESENTACION_SERVICIO_VARIABLES_SUCCESS", dataGraficaUnitarioPSV: {datasets: [], labels:[]}, optionsGraficaPSV: {}})
     };

    return (
         <>
         {servicioPresentacionSeleccionado!=null ? (
               <Modal
                    visible={showModalPresentacionServicioVariable}
                    title={'Presentación Productividad ' + servicioPresentacionSeleccionado.servicioVariable}
                    onCancel={handleCancel}
                    footer={false}
                    width='85%'
               >
                    <ModalPresentacionServicioUnitario/>
               </Modal>
          ) : (
               <Modal
               visible={showModalPresentacionServicioVariable}
               title={'Presentación Productividad General'}
               onCancel={handleCancel}
               footer={false}
               width='85%'
          >
              <ModalPresentacionServicioGeneral/>
          </Modal>)}
          </> 
    );
}
export default ModalPresentacionServicio;