import React from 'react';
import { Tabs, Tooltip, Row, Col } from 'antd';
import { ProfileOutlined, AppstoreAddOutlined, FieldTimeOutlined, SelectOutlined, IssuesCloseOutlined, 
    DiffOutlined, InsertRowAboveOutlined, CloudDownloadOutlined, CloudUploadOutlined, CalendarOutlined, 
    DollarOutlined, ScheduleOutlined, CalculatorOutlined, UsergroupAddOutlined, InfoCircleOutlined, 
    AlertOutlined, UserAddOutlined, ContactsOutlined, IdcardOutlined, FileDoneOutlined, ReconciliationOutlined, 
    FileSyncOutlined, BookOutlined, EnvironmentOutlined, ApiOutlined, AuditOutlined, CarryOutOutlined, 
    TableOutlined, GlobalOutlined, SafetyCertificateOutlined, SecurityScanOutlined, FileAddOutlined, ExceptionOutlined,
    SyncOutlined, QuestionCircleOutlined, FieldNumberOutlined, RiseOutlined, FormOutlined, ReadOutlined, FileTextOutlined,
    MonitorOutlined, CloseCircleOutlined, CreditCardOutlined, BarChartOutlined, FundProjectionScreenOutlined, DollarCircleOutlined, TagOutlined, TagsOutlined, FundViewOutlined, BarcodeOutlined, OrderedListOutlined, UnorderedListOutlined, FileSearchOutlined, LogoutOutlined, LoginOutlined, ToolOutlined, FileProtectOutlined, FileImageOutlined, CommentOutlined
} from '@ant-design/icons';
import '../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

import { connect } from "react-redux";
import CatalogoContainer from '../components/ConfiguracionYSeguridad/Catalogos/CatalogoContainer';
import CatalogoGContainer from '../components/Almacenes/ActivoFijo/CatalogoContainer';
import CatalogosGeneralesContainer from '../components/CatalogosGenerales/CatalogosGeneralesContainer';
import UsuariosContainer from '../components/ConfiguracionYSeguridad/Usuarios/UsuariosContainer';
import SistemaContainer from '../components/ConfiguracionYSeguridad/Sistema/SistemaContainer';
import EditarPerfil from '../components/EditarPerfil';
import ProveedoresContainer from '../components/InformaciónOperativa/Proveedores/ProveedoresContainer';
import InfoProyectoContainer from '../components/InformacionDelProyecto/InfoProyectoContainer';
import CatalogosProveedorContainer from '../components/InformaciónOperativa/CatalogosProveedorContainer';
import AsignacionDeActivosFijos from '../components/Almacenes/ActivoFijo/AsignacionDeActivosFijos/AsignacionActivosContainer';
import MecanismosDePagos from '../components/MecanismosDePago/MecanismosDePagoContainer'
import InformacionProyectoContainer from '../components/InformacionDelProyecto/InfoProyectoContainer';
import AltaDePersonalContainer from '../components/RecursosHumanos/AltaDePersonal/AltaDePersonalContainer';
import ControlDeAsistenciaContainer from '../components/RecursosHumanos/ControlDeAsistencia/ControlDeAsistenciaContainer';

import CatalogoContainerAlmacenes from '../components/Almacenes/Almacenes/CatalogoContainerAlmacenes';
import ServiciosPublicosContainer from '../components/ServiciosPublicos/ServiciosPublicosContainer';
import ServiciosVariablesContainer from '../components/ServiciosVariables/CatalogoServiciosVariablesContainer';
import BaseDeConocimiento from '../components/BaseDeConocimiento/BaseDeConocimientoContainer';
import SolicitudDeServiciosCatalogosContainer from '../components/SolicitudDeServicios/SolicitudDeServiciosCatalogosContainer';
import SolicitudDeServiciosContainer from '../components/SolicitudDeServicios/SolicitudDeServiciosContainer';
import RecepcionCapturaContainer from '../components/SolicitudDeServicios/RecepcionCaptura/RecepcionCapturaContainer';
import ServiciosPlanificadosContainer from '../components/ServiciosPlanificados/ServiciosPlanificadosContainer';
import SeguimientoContainer from '../components/SolicitudDeServicios/SeguimientoSolicitudes/SeguimientoContainer';
import OrdenRutinaMantenimiento from '../components/ServiciosPlanificados/OrdenRutinasMantenimiento/OrdenRutinaMantenimientoContainer';
import AsignacionServiciosPlanificados from '../components/ServiciosPlanificados/AsignacionServiciosPlanificados/AsignacionServiciosPlanificados';
import ProgramasDeRutinasContainer from '../components/ServiciosPlanificados/ProgramasDeRutinas/ProgramasDeRutinasContainer'
import CancelacionOrdenesSP from '../components/ServiciosPlanificados/CancelacionOrdenesSP/CancelacionOrdenesSP';
import EstatusOrdenesSP from '../components/ServiciosPlanificados/EstatusOrdenesSP/EstatusOrdenesSP';
import VistaCalendarioSP from '../components/ServiciosPlanificados/VistaCalendarioSP/VistaCalendarioSP';
import ReporteServiciosPlanificados from '../components/ServiciosPlanificados/ReporteServiciosPlanificados/ReporteServiciosPlanificados';
import CargaServiciosPlanificados from '../components/ServiciosPlanificados/CargaServiciosPlanificados/CargaServiciosPlanificados';
import ValoresPagoAnualContainer from '../components/MecanismosDePago/ValoresPagoAnual/ValoresPagoAnualContainer';
import AñoMesCalendarioContainer from '../components/MecanismosDePago/AñoMesCalendario/AñoMesCalendarioContainer';
import CalcularDeductivasContainer from '../components/MecanismosDePago/CalcularDeductivas/CalcularDeductivasContainer';
import CalendarioProgramaciónContainer from '../components/ServiciosPlanificados/CalendarioProgramación/CalendarioProgramaciónContainer';
import AsignacionDeUbicacionCapacidadContainer from '../components/ServiciosPublicos/AsignacionCapacidadYUbicacion/AsignacionCapacidadYUbicacion';
import ProgramacionMedicionConsumosContainer from '../components/ServiciosPublicos/ProgramacionMedicionDeConsumos/ProgramacionMedicionConsumosContainer';
import RegistroLecturasContainer from '../components/ServiciosPublicos/RegistrosDeLectura/RegistroDeLecturaContainer';
import CalculoConsumoDiferenciadoContainer from '../components/ServiciosPublicos/CalculosDeConsumoDiferenciado/CalculosDeConsumoDiferenciadoContainer';
import GestionDePlanDeCalidadContainer from '../components/GestionDeCalidad/GestionDePlanDeCalidad/GestionDePlanDeCalidadContainer';
import GestionDePlanesCorrectivosContainer from '../components/GestionDeCalidad/GestionDePlanesCorrectivos/GestionDePlanesCorrectivosContainer';
import MapaDeIncidenciasContainer from '../components/MonitorIntegral/MapaDeIncidencias/MapaDeIncidenciasContainer';
import TableroDeOperacionesContainer from '../components/MonitorIntegral/TableroDeOperaciones/TableroDeOperacionesContainer';
import NoDocumentoCalcularReiteracionFallasContainer from '../components/MecanismosDePago/NoDocumentoCalcularReiteracionFallas/NoDocumentoCalcularReiteracionFallasContainer';
import CalcularDeductivasReiteracionContainer from '../components/MecanismosDePago/CalcularDeductivasReiteracion/CalcularDeductivasReiteracionContainer';
import PonderadoresEncuestaContainer from '../components/GestionDeCalidad/PonderadoresEncuestaSatisfaccion/PonderadoresEncuestaContainer';
import EmisionEncuestaContainer from '../components/GestionDeCalidad/EmisionEncuestaSatisfaccion/EmisionEncuestaContainer';
import ConciliacionContainer from '../components/MecanismosDePago/Conciliacion/ConciliacionContainer';
import FolioConciliacion from '../components/MecanismosDePago/FolioConciliacion/FolioConciliacion';
import RegistroEncuestaContainer from '../components/GestionDeCalidad/RegistroEncuestaSatisfaccion/RegistroEncuestaContainer';
import ReportesEncuestasContainer from '../components/GestionDeCalidad/ReporteResultadosEncuestasSatisfaccion/ReporteResultadosContainer';
import EditarConciliacionContainer from '../components/MecanismosDePago/EditarConciliacion/EditarConciliacionContainer';
import EvaluacionDeLaGestionContainer from '../components/EvaluacionDeLaGestion/EvaluacionDeLaGestionContainer';
import CalculoDeductivasErroresContainer from '../components/MecanismosDePago/CalculoDeductivasEO/CalculoDeductivasErroresContainer';
import CumplimientoEstandaresContainer from '../components/GestionDeCalidad/CumplimientoEstandares/CumplimientoEstandaresContainer';
import PagoServicioContainer from '../components/MecanismosDePago/PagosServicio/PagoServicioContainer';
import FolioErrorOmision from "../components/MecanismosDePago/FolioErrorOmision/FolioErrorOmision";
import EstadisticasSSySP from '../components/EvaluacionDeLaGestion/EstadisticasSSySPGerencias/EstadisticasSSySP';
import ReporteDinamico from '../components/EvaluacionDeLaGestion/ReporteDinamico/ReporteDinamico';
import MonitoreoModulosCalidadContainer from '../components/MonitorIntegral/MonitoreoModulosCalidad/MonitoreModulosCalidadContainer';
import ReportesDeDesempenoDeEstandaresGeneralesContainer
    from "../components/GestionDeCalidad/ReportesDeDesempenoDeEstándaresGenerales/ReportesDeDesempenoDeEstandaresGeneralesContainer";
import PagoDeServiciosPublicosContainer
    from "../components/MecanismosDePago/PagoDeServiciosPublicos/PagoDeServiciosPublicosContainer";
import EvaluacionDeIndicadoresDeDesempenoContainer
    from "../components/EvaluacionDeLaGestion/EvaluacionDeIndicadoresDeDesempeno/EvaluacionDeIndicadoresDeDesempenoContainer";
import RMDPContainer from "../components/RMDP/RMDPContainer"
import RDOContainer from "../components/MonitorIntegral/MonitoreoModulosOpertativos/RDOContainer";
import RDODeductivas from "../components/MonitorIntegral/MonitoreoDeductivas/RDODeductivas";
import ReporteDeductivasContainer from "../components/MecanismosDePago/ReporteDeductivas/ReporteDeductivasContainer";
import ReporteDinamicoES from '../components/GestionDeCalidad/ReporteDinamicoES/ReporteDinamicoES';
import ReporteDinamicoRH from "../components/RecursosHumanos/ReporteDinamico/ReporteDinamico";
import RerpoteGrafico from "../components/MonitorIntegral/ReporteGrafico/ReporteGraficoContainer";

//Almacenes
import GenerarFolioRequisicion from '../components/Almacenes/Almacenes/RequisiciónDirecta/RequisicionDirectaContainer';
import RequisicionPuntoReorden from '../components/Almacenes/Almacenes/RequisicionPuntodeReorden/CatalogoPuntoReorden';
import RegistroMovimientosAlmacen from '../components/Almacenes/Almacenes/MovimientosAlmacen/MovimientoAlmacenContainer';
import ListadoTomaInventarioFisico from '../components/Almacenes/Almacenes/ListadoInventarioFisicoGeneral/ListadoInvetarioFisicoGeneral';
import ListadoTomaInventarioCiclico from '../components/Almacenes/Almacenes/InventarioCiclico/InventarioCiclicoContainer';
import Kardex from '../components/Almacenes/Almacenes/Kardex/KardexContainer';
import CapturaInventarioFisico from '../components/Almacenes/Almacenes/CapturaInventarioFisico/CapturaInventarioFisicoContainer';
import ReporteDiferenciasInventario from '../components/Almacenes/Almacenes/ReporteFaltantesAjusteInventarioFisico/ReporteFaltantesAjusteInventarioFisico';
import VerificacionCalidad from '../components/Almacenes/Almacenes/VerificacionCalidad/VerificacionCalidadContainer';
import MovimientosHerramientas from '../components/Almacenes/Almacenes/MovimientosHerramientas/MovimientoHerramientasContainer';
import RequisicionMateriales from '../components/Almacenes/Almacenes/RequisicionMateriales/RequisicionMaterialesContainer';
import ReporteExistencias from '../components/Almacenes/Almacenes/ReporteDeExistencias/ReporteExistencias';
//Activo Fijo
import CargaMasivaActivos from '../components/Almacenes/ActivoFijo/CargaMasivaActivos/CargaMasivaActivosContainer';
import SolicitudSalidaActivos from '../components/Almacenes/ActivoFijo/SalidaDeBienes/SalidaDeBienesContainer';
import ReingresoActivos from '../components/Almacenes/ActivoFijo/ReingresoActivos/ReingresoActivoContainer';
import MonitorActualizacionTecnologica from '../components/Almacenes/ActivoFijo/RequisicionPorActualizacionTecnologica/RequisicionPorActualizacionTecnologica';

import Bitacora from '../components/ConfiguracionYSeguridad/Catalogos/CatalogoDeBitacoras/CatalogoBitacorasContainer';
import ReporteDinamicoBitacora from '../components/ConfiguracionYSeguridad/ReporteDinamico/ReporteDinamico';

import SeguimientoProductividad from '../components/ServiciosVariables/SeguimientoDiarioProductividades/SeguimientoProductividadesContainer';
import ReporteDinamicoSP from '../components/ServiciosPlanificados/ReporteDinamicoSP.js/ReporteDinamicoSP';

const { TabPane } = Tabs;

class DashboardContainer extends React.Component {
    componentDidMount(){
        this.props.onRequestInfoProyecto();
    }
    render() {
        let { vistaDashboard, listaModulosBitacoraByIdModulo } = this.props;
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const cargarTodo = () => {
            this.props.onGetListaServicios();
            this.props.onGetListaDominios();
            this.props.onGetListaUsuariosSinFiltros(1);
            this.props.onListaRoles();
            this.props.onGetProveedores();
            this.props.onGetListaExpedientes();
            this.props.onGetListaContratos();
        }
        const cargarInformacionOperativa = () => {
            const formulario = {
                busqueda: '',
                fechaFin: '',
            };
            
            this.props.onGetListaDominios();
            this.props.onGetProveedoresBusqueda(1, formulario);
        }
        const cargarAlmacenes = () => {
            this.props.onGetSubfamilias();
            this.props.onGetCategoriasUnidadesMedidas();
            this.props.onLimpiarTablaAsignacion();
            this.props.onLimpiarContainerAlmacenes();
            this.props.onNuevaConsultaKardex();
            this.props.onLimpiarKardex();
            this.props.onCleanListaSelectedAlmacenes();
        }

        const limpiarSolicitudDeServicios = () => {
            this.props.onCloseModal(); // este saca identificación a true
            this.props.onLimpiarContainerSolicitudServicio();
            this.props.onCleanPartidasByFalla();
        };

        const limpiarServiciosPlanificados = () => {
            this.props.onLimpiarProgramaRMP();
        };

        const limpiarMonitoreoIntegral = () => {
            this.props.onCleanDataSatisfaccionServicio();
            this.props.onCleanDataQuejas();
            this.props.onCleanDataQuejasByServicio();
        };

        const limpiarMecanismosDePago = () => {
            this.props.onLimiarConcentradoAnual()
        };

        let userInfo = sessionStorage.getItem('userInfo');
        if(userInfo[0]) {
            userInfo = JSON.parse(userInfo);
        }

        const recursosHumanosChange = () => {
            this.props.onGetProveedores();
            this.props.onGetGrupoServicioByIdUsuario(userInfo[0].idUsuario);
            this.props.onGetGerenciasByIdUsuario(userInfo[0].idUsuario);
            this.props.onGetClasificacionActivos();
            this.props.onGetGruposServiciosActivos();
        };

        const limpiarServiciosVariables = () => {
            this.props.cleanProductividad();
        };


        function ContentApp() {
            if (vistaDashboard === 'Configuración y Seguridad') {
				return (
                    <div>
                        <Tabs destroyInactiveTabPane={true} defaultActiveKey="1" onChange={cargarTodo} className='tabDashboard'>
                            {permisosUsuario.buscarUsuario ? (
                            <TabPane tab={<Tooltip placement="top" title='Usuarios'><UsergroupAddOutlined className='iconTabPane' /> </Tooltip>} key='1'>
                                <UsuariosContainer/>
                            </TabPane>
                            ):false}
                            {permisosUsuario.buscarRol || permisosUsuario.buscarModulo || permisosUsuario.buscarTipoDeBitácora || permisosUsuario.buscarBitácora ? (
                            <TabPane tab={<Tooltip placement="top" title='Catálogos'><ProfileOutlined className='iconTabPane'/> </Tooltip>} key='2'>
                                <CatalogoContainer />
                            </TabPane>
                            ):false}
                            {permisosUsuario.buscarProyecto ? (
                            <TabPane tab={<Tooltip placement="top" title='Información del Proyecto'><InfoCircleOutlined className='iconTabPane'/> </Tooltip>} key='3'>
                                <InformacionProyectoContainer />
                            </TabPane>
                            ):false}
                            {permisosUsuario.buscarPermiso || permisosUsuario.verConfiguraciones || permisosUsuario.buscarAlertas || permisosUsuario.buscarAuditoriaDelSistema ? (
                            <TabPane tab={<Tooltip placement="top" title='Sistema'><AlertOutlined className='iconTabPane' /> </Tooltip>} key='4'>
                                <SistemaContainer />
                            </TabPane>
                            ):false}
                            <TabPane tab={<Tooltip placement="top" title='Reporte Dinámico'> <FileDoneOutlined className='iconTabPane' /> </Tooltip>} key='5'>
                                <ReporteDinamicoBitacora/>
                            </TabPane>
                            { permisosUsuario.verBitácoraConfiguraciónYSeguridad && listaModulosBitacoraByIdModulo.length > 0 ? (
                                <TabPane tab={<Tooltip placement="top" title='Bitácora'><BookOutlined className='iconTabPaneSecondary'/> </Tooltip>} key='6'>
                                    <Bitacora />
                                </TabPane>
                            ): false }
                        </Tabs>
                    </div>
                );
            }
            else if(vistaDashboard === 'Información del Proyecto'){
                return(
                    <div>
                        <Tabs destroyInactiveTabPane={true} defaultActiveKey="1" className='tabDashboard'>
                            <TabPane tab={<Tooltip placement="top" title='Información del Proyecto'><InfoCircleOutlined className='iconTabPane'/> </Tooltip>} key='1'>
                                <InfoProyectoContainer/>
                            </TabPane>
                            { permisosUsuario.verBitácoraInformaciónDelProyecto && listaModulosBitacoraByIdModulo.length > 0 ? (
                                <TabPane tab={<Tooltip placement="top" title='Bitácora'><BookOutlined className='iconTabPaneSecondary'/> </Tooltip>} key='2'>
                                    <Bitacora />
                                </TabPane>
                            ): false }
                        </Tabs>
                    </div>
                );
            }
            else if(vistaDashboard === 'Información Operativa'){
                return(
                    <div>
                        <Tabs destroyInactiveTabPane={true} defaultActiveKey="1" onChange={cargarInformacionOperativa} className='tabDashboard'>
                            {permisosUsuario.buscarProveedor ? (
                            <TabPane tab={<Tooltip placement="top" title='Proveedores'><IdcardOutlined className='iconTabPane'/> </Tooltip>} key='1'>
                                <ProveedoresContainer />
                            </TabPane>
                            ):false}
                            {permisosUsuario.buscarDominio || permisosUsuario.buscarContrato || permisosUsuario.buscarExpediente || permisosUsuario.buscarPartida ? (
                            <TabPane tab={<Tooltip placement="top" title='Catálogos'><ProfileOutlined className='iconTabPane'/> </Tooltip>} key='2'>
                                <CatalogosProveedorContainer />
                            </TabPane>
                            ):false}
                            { permisosUsuario.verBitácoraInformaciónOperativa && listaModulosBitacoraByIdModulo.length > 0 ? (
                                <TabPane tab={<Tooltip placement="top" title='Bitácora'><BookOutlined className='iconTabPaneSecondary'/> </Tooltip>} key='3'>
                                    <Bitacora />
                                </TabPane>
                            ): false }
                        </Tabs>
                    </div>
                );
            }
            else if(vistaDashboard === 'Base de Conocimiento'){
                return(
                    <div>
                        <Tabs destroyInactiveTabPane={true} defaultActiveKey="1" className='tabDashboard'>
                            <TabPane tab={<Tooltip placement="top" title='Base de Conocimiento'><ReadOutlined className='iconTabPane'/> </Tooltip>} key='1'>
                                <BaseDeConocimiento/>
                            </TabPane>
                            { permisosUsuario.verBitácoraBaseDeConocimiento && listaModulosBitacoraByIdModulo.length > 0 ? (
                                <TabPane tab={<Tooltip placement="top" title='Bitácora'><BookOutlined className='iconTabPaneSecondary'/> </Tooltip>} key='2'>
                                    <Bitacora />
                                </TabPane>
                            ): false }
                        </Tabs>
                    </div>
                );
            }
            else if(vistaDashboard === 'Almacenes'){
                return (
                    <Tabs destroyInactiveTabPane={true} defaultActiveKey="1" className='tabDashboard' onChange={cargarAlmacenes}>
                        {permisosUsuario.buscarUbicaciónDelProducto || permisosUsuario.buscarPih || permisosUsuario.buscarAlmacén || permisosUsuario.buscarTipoDeFolioDeMovimiento || permisosUsuario.buscarCategoriaDeMedicion || permisosUsuario.buscarUnidadDeMedicion || permisosUsuario.buscarCaracterísticaDeActivos || permisosUsuario.verActivo || permisosUsuario.buscarEstatusDelActivo || permisosUsuario.buscarRangoDeRenovaciónParaActivosFijos || permisosUsuario.buscarMotivosDeSalida  || permisosUsuario.buscarNuevaCategoríaDeTiempoDeRehabilitación || permisosUsuario.buscarNuevaCategoríaDeTiempoDeRehabilitación ? (
                            <TabPane tab={<Tooltip placement="top" title='Catálogos'><ProfileOutlined className='iconTabPane'/> </Tooltip>} key='1'>
                                <CatalogoContainerAlmacenes />
                            </TabPane>
                        ):false}
                        {permisosUsuario.subirArchivoCsv ? (
                            <TabPane tab={<Tooltip placement="top" title='Generar Folio de Requisición Directa'><BarcodeOutlined className='iconTabPane'/> </Tooltip>} key='2'>
                                <GenerarFolioRequisicion />
                            </TabPane>
                        ):false}
                        { permisosUsuario.buscarRequisiciónPorPuntoDeReorden ? (
                            <TabPane tab={<Tooltip placement="top" title='Requisición por Punto de Reorden y Compra'><FieldNumberOutlined className='iconTabPane'/> </Tooltip>} key='3'>
                                <RequisicionPuntoReorden />
                            </TabPane>
                        ) : false }
                        { permisosUsuario.buscarRequisiciónPorPuntoDeReorden ? (
                            <TabPane tab={<Tooltip placement="top" title='Solicitud de Materiales'><CommentOutlined className='iconTabPane'/> </Tooltip>} key='4'>
                                <RequisicionMateriales />
                            </TabPane>
                        ) : false }
                        { permisosUsuario.verVerificaciónDeCalidad ? (
                            <TabPane tab={<Tooltip placement="top" title='Entrada por Verificación de Calidad'><FileProtectOutlined className='iconTabPane'/> </Tooltip>} key='5'>
                                <VerificacionCalidad />
                            </TabPane>
                        ) : false }
                        { permisosUsuario.buscarFolioDeMovimientoDeAlmacén ? (
                            <TabPane tab={<Tooltip placement="top" title='Registro de Movimientos de Almacén'><SyncOutlined className='iconTabPane'/> </Tooltip>} key='6'>
                                <RegistroMovimientosAlmacen />
                            </TabPane>
                        ) : false }
                        { permisosUsuario.buscarMovimientosEsAlAlmacén ? ( 
                            <TabPane tab={<Tooltip placement="top" title='Kárdex de Movimientos Entrada/Salidas al Almacén'><AuditOutlined className='iconTabPane'/> </Tooltip>} key='7'>
                                <Kardex />
                            </TabPane>
                        ) : false } 
                        { permisosUsuario.verListadoInventarioFísicoGeneral ? (
                            <TabPane tab={<Tooltip placement="top" title='Listado para Toma de Inventario Físico General'><OrderedListOutlined className='iconTabPane'/> </Tooltip>} key='8'>
                                <ListadoTomaInventarioFisico />
                            </TabPane>
                        ) : false}
                        { permisosUsuario.verListadoInventarioCíclico  ? (
                            <TabPane tab={<Tooltip placement="top" title='Listado para Toma de Inventario Cíclico'><UnorderedListOutlined className='iconTabPane'/> </Tooltip>} key='9'>
                                <ListadoTomaInventarioCiclico />
                            </TabPane>
                        ) : false }
                        { permisosUsuario.verCapturaInventariosFísicos ? (
                            <TabPane tab={<Tooltip placement="top" title='Captura de Toma de Inventarios Físicos'><FormOutlined className='iconTabPane'/> </Tooltip>} key='10'>
                                <CapturaInventarioFisico />
                            </TabPane>
                        ) : false }
                        { permisosUsuario.obtenerDiferenciasDeInventario ? (
                            <TabPane tab={<Tooltip placement="top" title='Reporte de Diferencias de Inventario'><FileSearchOutlined className='iconTabPane'/> </Tooltip>} key='11'>
                                <ReporteDiferenciasInventario />
                            </TabPane>
                        ) : false }
                        { permisosUsuario.verMovimientosDeHerramientas ? (
                            <TabPane tab={<Tooltip placement="top" title='Movimientos de Herramientas'><ToolOutlined className='iconTabPane'/> </Tooltip>} key='12'>
                                <MovimientosHerramientas />
                            </TabPane>
                        ) : false }

                        {/* Activo Fijo */}
                        { permisosUsuario.cargaMasivaDeActivosRevisioncanceladofinalizado ? (
                        <TabPane tab={<Tooltip placement="top" title='Carga Masiva de Activos'><CloudUploadOutlined className='iconTabPane'/> </Tooltip>} key='13'>
                            <CargaMasivaActivos />
                        </TabPane>
                        ) : false }
                        { permisosUsuario.resignarYReubicarActivo ? (
                        <TabPane tab={<Tooltip placement="top" title='Reasignación y Reubicación de Activos'><FileSyncOutlined className='iconTabPane'/> </Tooltip>} key='14'>
                            <AsignacionDeActivosFijos />
                        </TabPane>
                        ) : false }
                        { permisosUsuario.verificarSolicitudDeSalida ? (
                        <TabPane tab={<Tooltip placement="top" title='Solicitud de Salida de Activos'><LogoutOutlined className='iconTabPane'/> </Tooltip>} key='15'>
                            <SolicitudSalidaActivos />
                        </TabPane>
                        ) : false }
                        { permisosUsuario.generarReingresoDeActivo ? (
                        <TabPane tab={<Tooltip placement="top" title='Reingreso de Activos'><LoginOutlined className='iconTabPane'/> </Tooltip>} key='16'>
                            <ReingresoActivos />
                        </TabPane>
                        ) : false }
                        { permisosUsuario.buscarActualizaciónTecnologica ? (
                        <TabPane tab={<Tooltip placement="top" title='Monitor de Actualización Tecnológica'><FundViewOutlined className='iconTabPane'/> </Tooltip>} key='17'>
                            <MonitorActualizacionTecnologica />
                        </TabPane>
                        ) : false }
                        {permisosUsuario.verReporteDinámicoParaEncuestasDeSatisfacción ? (
                        <TabPane tab={<Tooltip placement="top" title='Reporte de Existencias'><FileDoneOutlined className='iconTabPane'/> </Tooltip>} key='18'>
                            <ReporteExistencias />
                        </TabPane>
                        ):false}
                        { permisosUsuario.verBitácoraAlmacenes && listaModulosBitacoraByIdModulo.length > 0 ? (
                            <TabPane tab={<Tooltip placement="top" title='Bitácora'><BookOutlined className='iconTabPaneSecondary'/> </Tooltip>} key='19'>
                                <Bitacora />
                            </TabPane>
                        ): false }
                    </Tabs>                    
                );
                
            }
            else if(vistaDashboard === 'Catálogos Generales'){
                return (
                    <div>
                        <Tabs destroyInactiveTabPane={true} defaultActiveKey="1" className='tabDashboard'>
                            {permisosUsuario.buscarGrupoDeServicio || permisosUsuario.buscarServicio || permisosUsuario.buscarGerencia || permisosUsuario.buscarCentroDeCostos || permisosUsuario.buscarFamilia || permisosUsuario.buscarSubfamilia || permisosUsuario.buscarAreaFuncional || permisosUsuario.buscarUnidadFuncional || permisosUsuario.buscarInteriorFuncional || permisosUsuario.buscarSubinteriorFuncional || permisosUsuario.buscarUbicacion || permisosUsuario.buscarPonderaciónDeAreaFuncional ? (
                            <TabPane tab={<Tooltip placement="top" title='Catálogos Generales'><ProfileOutlined className='iconTabPane'/> </Tooltip>} key='1'>
                                <CatalogosGeneralesContainer />
                            </TabPane>
                            ):false}
                            { permisosUsuario.verBitácoraCatálogosGenerales && listaModulosBitacoraByIdModulo.length > 0 ? (
                                <TabPane tab={<Tooltip placement="top" title='Bitácora'><BookOutlined className='iconTabPaneSecondary'/> </Tooltip>} key='2'>
                                    <Bitacora />
                                </TabPane>
                            ): false }
                        </Tabs>  
                    </div>
                );
            }
            else if(vistaDashboard === 'Recursos Humanos'){
                return (
                    <Tabs destroyInactiveTabPane={true} defaultActiveKey="1" className='tabDashboard' onChange={recursosHumanosChange}>
                        {permisosUsuario.buscarPersonal ? (
                        <TabPane tab={<Tooltip placement="top" title='Alta de Personal'><UserAddOutlined className='iconTabPane'/> </Tooltip>} key='1'>
                            <AltaDePersonalContainer/>
                        </TabPane>
                        ):false}
                        {permisosUsuario.buscarInformaciónControlDeAsistencia ? (
                        <TabPane tab={<Tooltip placement="top" title='Control de Asistencia'><ContactsOutlined className='iconTabPane'/> </Tooltip>} key='2'>
                            <ControlDeAsistenciaContainer/>
                        </TabPane>
                        ):false}
                        {permisosUsuario.generarReporteDinamicoRecursosHumanos ? (
                        <TabPane tab={<Tooltip placement="top" title='Reporte Dinámico'> <FileDoneOutlined className='iconTabPane' /> </Tooltip>} key='4'>
                            <ReporteDinamicoRH/>
                        </TabPane>
                        ):false}
                        { permisosUsuario.verBitácoraRecursosHumanos && listaModulosBitacoraByIdModulo.length > 0 ? (
                            <TabPane tab={<Tooltip placement="top" title='Bitácora'><BookOutlined className='iconTabPaneSecondary'/> </Tooltip>} key='5'>
                                <Bitacora />
                            </TabPane>
                        ): false }
                    </Tabs>                    
                );
            }
            else if(vistaDashboard === 'Servicios Variables'){
                return(
                    <div>
                        <Tabs destroyInactiveTabPane={true} defaultActiveKey="1" className='tabDashboard' onChange={limpiarServiciosVariables}>
                            <TabPane tab={<Tooltip placement="top" title='Catálogos'><ProfileOutlined className='iconTabPane'/> </Tooltip>} key='1'>
                                <ServiciosVariablesContainer />
                            </TabPane>
                                <TabPane tab={<Tooltip placement="top" title='Monitor de Productividad'><MonitorOutlined className='iconTabPane'/> </Tooltip>} key='2'>
                                    <SeguimientoProductividad />
                                </TabPane>
                            { permisosUsuario.verBitácoraServiciosVariables && listaModulosBitacoraByIdModulo.length > 0 ? (
                                <TabPane tab={<Tooltip placement="top" title='Bitácora'><BookOutlined className='iconTabPaneSecondary'/> </Tooltip>} key='3'>
                                    <Bitacora />
                                </TabPane>
                            ): false }
                        </Tabs>  
                    </div>
                );
            }
            else if(vistaDashboard === 'Solicitud de Servicios'){
                return(
                    <div>
                        <Tabs destroyInactiveTabPane={true} defaultActiveKey="1" className='tabDashboard' onChange={limpiarSolicitudDeServicios}>
                            {permisosUsuario.buscarTipoDeFalla || permisosUsuario.buscarEnCatalogoDeFallas ? (
                            <TabPane tab={<Tooltip placement="top" title='Catálogos'><ProfileOutlined className='iconTabPane'/> </Tooltip>} key='1'>
                                <SolicitudDeServiciosCatalogosContainer />
                            </TabPane>
                            ):false}
                            {permisosUsuario.verAltaSolicitudDeServicio || permisosUsuario.verMonitorDeIncidencias ? (
                            <TabPane tab={<Tooltip placement="top" title='Alta'><FileDoneOutlined className='iconTabPane'/> </Tooltip>} key='2'>
                                <RecepcionCapturaContainer />
                            </TabPane>
                            ):false}
                            {permisosUsuario.buscarSeguimientoDeSolicitudesDeServicio ? (
                            <TabPane tab={<Tooltip placement="top" title='Seguimiento'><FileSyncOutlined className='iconTabPane'/> </Tooltip>} key='3'>
                                <SeguimientoContainer />
                            </TabPane>
                            ):false}
                            {permisosUsuario.buscarReporteDeSolicitudDeServicio || permisosUsuario.verReporteAutomático || permisosUsuario.verReporteDinámico ? (
                            <TabPane tab={<Tooltip placement="top" title='Reporteador'><ReconciliationOutlined className='iconTabPane'/> </Tooltip>} key='4'>
                                <SolicitudDeServiciosContainer />
                            </TabPane>
                            ):false}
                            { permisosUsuario.verBitácoraSolicitudDeServicios && listaModulosBitacoraByIdModulo.length > 0 ? (
                                <TabPane tab={<Tooltip placement="top" title='Bitácora'><BookOutlined className='iconTabPaneSecondary'/> </Tooltip>} key='5'>
                                    <Bitacora />
                                </TabPane>
                            ): false }
                            
                        </Tabs>
                    </div>
                );
            }
            else if(vistaDashboard === 'Servicios Planificados'){
                return(
                    <div>
                        <Tabs destroyInactiveTabPane={true} defaultActiveKey="1" className='tabDashboard' onChange={limpiarServiciosPlanificados}>
                            {permisosUsuario.buscarRutinasmantenimientopruebas ? (
                            <TabPane tab={<Tooltip placement="top" title='Catálogos'><ProfileOutlined className='iconTabPane'/> </Tooltip>} key='1'>
                                <ServiciosPlanificadosContainer />
                            </TabPane>
                            ):false}
                            {permisosUsuario.verAsignaciónDeServiciosPlanificados ? (
                            <TabPane tab={<Tooltip placement="top" title='Asignación'><AppstoreAddOutlined className='iconTabPane'/> </Tooltip>} key='2'>
                                <AsignacionServiciosPlanificados/>
                            </TabPane>
                            ):false}
                            {permisosUsuario.buscarProgramaDeRutinasmantenimientospruebas ? (
                            <TabPane tab={<Tooltip placement="top" title='Programas de Rutinas y Mantenimientos'><FieldTimeOutlined className='iconTabPane'/> </Tooltip>} key='3'>
                                <ProgramasDeRutinasContainer />
                            </TabPane>
                            ):false}
                            {permisosUsuario.verCalendarioDeProgramaciónDeServiciosPlanificados ? (
                            <TabPane tab={<Tooltip placement="top" title='Calendario de Programación'><CalendarOutlined className='iconTabPane'/> </Tooltip>} key='4'>
                                <CalendarioProgramaciónContainer />
                            </TabPane>
                            ):false}
                            {permisosUsuario.verVistaCalendarioDeRutinasmantenimientos ? (
                            <TabPane tab={<Tooltip placement="top" title='Vista Calendario de Rutinas y Mantenimientos'><InsertRowAboveOutlined className='iconTabPane'/> </Tooltip>} key='5'>
                                <VistaCalendarioSP />
                            </TabPane>
                            ):false}
                            {permisosUsuario.verGenerarOrdenDeRutinamantenimiento ? (
                            <TabPane tab={<Tooltip placement="top" title='Generar Orden para Rutinas o Mantenimientos'><SelectOutlined className='iconTabPane'/> </Tooltip>} key='6'>
                                <OrdenRutinaMantenimiento/>
                            </TabPane>
                            ):false}
                            {permisosUsuario.verCancelaciónDeOrdenes ? (
                            <TabPane tab={<Tooltip placement="top" title='Cancelación de Ordenes'><IssuesCloseOutlined className='iconTabPane'/> </Tooltip>} key='7'>
                                <CancelacionOrdenesSP />
                            </TabPane>
                            ):false}
                            {permisosUsuario.verCambiarEstadoDeOrdenes ? (
                            <TabPane tab={<Tooltip placement="top" title='Cambiar Estado de las Órdenes e Ingresar Insumos'><DiffOutlined className='iconTabPane'/> </Tooltip>} key='8'>
                                <EstatusOrdenesSP />
                            </TabPane>
                            ):false}
                            {permisosUsuario.verReporteDeServiciosPlanificados ? (
                            <TabPane tab={<Tooltip placement="top" title='Reporte Servicios Planificados'><CloudDownloadOutlined className='iconTabPane'/> </Tooltip>} key='9'>
                                <ReporteServiciosPlanificados />
                            </TabPane>
                            ):false}
                            {permisosUsuario.descargarFormatoDeServicioPlanificado || permisosUsuario.cargarArchivoCsvDeServicioPlanificado  ? (
                            <TabPane tab={<Tooltip placement="top" title='Carga Servicios Planificados'><CloudUploadOutlined className='iconTabPane'/> </Tooltip>} key='10'>
                                <CargaServiciosPlanificados />
                            </TabPane>
                            ): false }
                            {permisosUsuario.descargarFormatoDeServicioPlanificado || permisosUsuario.cargarArchivoCsvDeServicioPlanificado  ? (
                            <TabPane tab={<Tooltip placement="top" title='Reporte Dinámico para Servicios Planificados'><FileDoneOutlined className='iconTabPane'/> </Tooltip>} key='11'>
                                <ReporteDinamicoSP />
                            </TabPane>
                            ): false }
                            { permisosUsuario.verBitácoraServiciosPlanificados && listaModulosBitacoraByIdModulo.length > 0 ? (
                                <TabPane tab={<Tooltip placement="top" title='Bitácora'><BookOutlined className='iconTabPaneSecondary'/> </Tooltip>} key='12'>
                                    <Bitacora />
                                </TabPane>
                            ): false }
                        </Tabs>
                    </div>
                );
            }
            else if(vistaDashboard === 'Servicios Públicos'){
                return(
                    <div>
                        <Tabs destroyInactiveTabPane={true} defaultActiveKey="1" className='tabDashboard'>
                            {permisosUsuario.buscarServicioPublico || permisosUsuario.buscarVariablesDeMedicion ? (
                            <TabPane tab={<Tooltip placement="top" title='Catálogos'><ProfileOutlined className='iconTabPane'/> </Tooltip>} key='1'>
                                <ServiciosPublicosContainer />
                            </TabPane>
                            ):false}
                            {permisosUsuario.buscarAsignaciónYCapacidadDeServiciosPúblicos ? (
                            <TabPane tab={<Tooltip placement="top" title='Asignación de Ubicación y Capacidad'><EnvironmentOutlined className='iconTabPane'/> </Tooltip>} key='2'>
                                <AsignacionDeUbicacionCapacidadContainer />
                            </TabPane>
                            ):false}
                            {permisosUsuario.buscarProgramaDeMedicionYConsumoDeServiciosPúblicos ? (
                            <TabPane tab={<Tooltip placement="top" title='Programación de Medición y Consumos'><CarryOutOutlined className='iconTabPane'/> </Tooltip>} key='3'>
                                <ProgramacionMedicionConsumosContainer />
                            </TabPane>
                            ):false}
                            {permisosUsuario.buscarServicioPublicoParaRegistroDeLecturas ? (
                            <TabPane tab={<Tooltip placement="top" title='Registro de Lectura'><ApiOutlined className='iconTabPane'/> </Tooltip>} key='4'>
                                <RegistroLecturasContainer />
                            </TabPane>
                            ):false}
                            {permisosUsuario.buscarServicioPublicoParaCalculoDeConsumoDiferenciado ? (
                            <TabPane tab={<Tooltip placement="top" title='Cálculos de Consumo Diferenciado'><AuditOutlined className='iconTabPane'/> </Tooltip>} key='5'>
                                <CalculoConsumoDiferenciadoContainer />
                            </TabPane>
                            ): false }
                            { permisosUsuario.verBitácoraServiciosPúblicos && listaModulosBitacoraByIdModulo.length > 0 ? (
                                <TabPane tab={<Tooltip placement="top" title='Bitácora'><BookOutlined className='iconTabPaneSecondary'/> </Tooltip>} key='6'>
                                    <Bitacora />
                                </TabPane>
                            ): false }
                        </Tabs>  
                    </div>
                );
            }
            else if(vistaDashboard === 'Gestión de Calidad'){
                return(
                    <Tabs destroyInactiveTabPane={true} defaultActiveKey="1" className='tabDashboard'>
                        {permisosUsuario.verPlanesDeCalidad ? (
                        <TabPane tab={<Tooltip placement="top" title='Gestión de Plan de Calidad'>  <SafetyCertificateOutlined className='iconTabPane' /> </Tooltip>} key='1'>
                            <GestionDePlanDeCalidadContainer/>
                        </TabPane>
                        ):false}
                        {permisosUsuario.verPonderadoresParaEncuestasDeSatisfacción ? (
                        <TabPane tab={<Tooltip placement="top" title='Ponderadores para Encuestas de Satisfacción'>  <FieldNumberOutlined className='iconTabPane' /> </Tooltip>} key='3'>
                            <PonderadoresEncuestaContainer/>
                        </TabPane>
                        ):false}
                        {permisosUsuario.verEmisiónDeEncuestaDeSatisfacción ? (
                        <TabPane tab={<Tooltip placement="top" title='Emisión de Encuestas de Satisfacción'>  <QuestionCircleOutlined className='iconTabPane' /> </Tooltip>} key='4'>
                            <EmisionEncuestaContainer/>
                        </TabPane>
                        ):false}
                        {permisosUsuario.verRegistroDeEncuestasDeSatisfacción ? (
                        <TabPane tab={<Tooltip placement="top" title='Registro de Encuestas de Satisfacción'>  <IssuesCloseOutlined className='iconTabPane' /> </Tooltip>} key='5'>
                            <RegistroEncuestaContainer/>
                        </TabPane>
                        ):false}
                        {permisosUsuario.buscarReportesDeEncuestasDeSatisfacción ? (
                        <TabPane tab={<Tooltip placement="top" title='Reporte de Resultados de Encuestas de Satisfacción'>  <RiseOutlined className='iconTabPane' /> </Tooltip>} key='6'>
                            <ReportesEncuestasContainer/>
                        </TabPane>
                        ):false}
                        {permisosUsuario.verReporteDinámicoParaEncuestasDeSatisfacción ? (
                        <TabPane tab={<Tooltip placement="top" title='Reporte Dinámico para Encuestas de Satisfacción'><FileDoneOutlined className='iconTabPane'/> </Tooltip>} key='7'>
                            <ReporteDinamicoES />
                        </TabPane>
                        ):false}
                        {permisosUsuario.verCumplimientoDeEstándaresEspecíficosYEstándaresGenerales ? (
                        <TabPane tab={<Tooltip placement="top" title='Cumplimiento de Estándares Específicos y Estándares Generales'>  <MonitorOutlined className='iconTabPane' /> </Tooltip>} key='8'>
                            <CumplimientoEstandaresContainer/>
                        </TabPane>
                        ):false}
                        {permisosUsuario.consultarReporteDeEstándaresGenerales ? (
                        <TabPane tab={<Tooltip placement="top" title='Reportes de desempeño de estándares generales y específicos'><ReconciliationOutlined className='iconTabPane'/> </Tooltip>} key='9'>
                            <ReportesDeDesempenoDeEstandaresGeneralesContainer/>
                        </TabPane>
                        ):false}
                        {permisosUsuario.buscarOrigenDeServicio ? (
                        <TabPane tab={<Tooltip placement="top" title='Gestión de Planes Correctivos'>  <SecurityScanOutlined className='iconTabPane' /> </Tooltip>} key='2'>
                            <GestionDePlanesCorrectivosContainer/>
                        </TabPane>
                        ):false}
                        { permisosUsuario.verBitácoraGestiónDeCalidad && listaModulosBitacoraByIdModulo.length > 0 ? (
                            <TabPane tab={<Tooltip placement="top" title='Bitácora'><BookOutlined className='iconTabPaneSecondary'/> </Tooltip>} key='10'>
                                <Bitacora />
                            </TabPane>
                        ): false }
                    </Tabs>
                );
            }
            else if(vistaDashboard === 'Evaluación de la Gestión'){
                return(
                    <div>
                        <Tabs destroyInactiveTabPane={true} defaultActiveKey="1" className='tabDashboard'>
                            {permisosUsuario.verTemas || permisosUsuario.verObjetivosParaLaEvaluaciónDeLaGestión || permisosUsuario.buscarIndicadorDeDesempeño ? (
                            <TabPane tab={<Tooltip placement="top" title='Catálogos'><ProfileOutlined className='iconTabPane'/> </Tooltip>} key='1'>
                                <EvaluacionDeLaGestionContainer />
                            </TabPane>
                            ):false}
                            {permisosUsuario.buscarClasificaciónDeSolicitudesDeServiciosYServiciosProgramadosPorGerencia ? (
                            <TabPane tab={<Tooltip placement="top" title='Clasificación de Solicitudes de Servicios y Servicios Programados por Gerencia'><BarChartOutlined className='iconTabPane'/> </Tooltip>} key='2'>
                                <EstadisticasSSySP />
                            </TabPane>
                            ):false}
                            {permisosUsuario.verReporteDinámicoParaEvaluaciónDeLaGestión ? (
                            <TabPane tab={<Tooltip placement="top" title='Reporte Dinámico para Evaluación de la Gestión'><FileDoneOutlined className='iconTabPane'/> </Tooltip>} key='3'>
                                <ReporteDinamico />
                            </TabPane>
                            ):false}
                            {permisosUsuario.consultarEvaluaciónDeIndicadoresDeDesempeño ? (
                            <TabPane tab={<Tooltip placement="top" title='Evaluación de Indicadores de Desempeño'><AuditOutlined className='iconTabPane'/> </Tooltip>} key='4'>
                                <EvaluacionDeIndicadoresDeDesempenoContainer />
                            </TabPane>
                            ):false}
                            { permisosUsuario.verBitácoraEvaluaciónDeLaGestión && listaModulosBitacoraByIdModulo.length > 0 ? (
                                <TabPane tab={<Tooltip placement="top" title='Bitácora'><BookOutlined className='iconTabPaneSecondary'/> </Tooltip>} key='5'>
                                    <Bitacora />
                                </TabPane>
                            ): false }
                        </Tabs>  
                    </div>
                );
            }
            else if(vistaDashboard === 'Monitoreo Integral'){
                return(
                    <Tabs destroyInactiveTabPane={true} defaultActiveKey="1" className='tabDashboard' onChange={limpiarMonitoreoIntegral}>
                        {permisosUsuario.mapaDeIncidencias ? (
                        <TabPane tab={<Tooltip placement="top" title='Mapa de Incidencias'>  <GlobalOutlined className='iconTabPane' /> </Tooltip>} key='1'>
                            <MapaDeIncidenciasContainer/>
                        </TabPane>
                        ):false}
                        {permisosUsuario.verTableroDeOperaciones ? (
                        <TabPane tab={<Tooltip placement="top" title='Tablero de Operaciones'>  <TableOutlined className='iconTabPane' /> </Tooltip>} key='2'>
                            <TableroDeOperacionesContainer/>
                        </TabPane>
                        ):false}
                        {permisosUsuario.verMonitoreoDeMódulosDeCalidad && (permisosUsuario.evaluaciónDeDesempeñoEnElMonitoreoDeMódulosDeCalidad || permisosUsuario.encuestasDeSatisfacciónEnElMonitoreoDeMódulosDeCalidad || permisosUsuario.seguimientoDeQuejasYReclamosEnElMonitoreoDeMódulosDeCalidad) ? (
                        <TabPane tab={<Tooltip placement="top" title='Monitoreo de Módulos de Calidad'>  <MonitorOutlined className='iconTabPane' /> </Tooltip>} key='3'>
                            <MonitoreoModulosCalidadContainer/>
                        </TabPane>
                        ):false}
                        {permisosUsuario.verMonitoreoDeModulosOperativos ? (
                        <TabPane tab={<Tooltip placement="top" title='Monitoreo de Módulos Operativos'>  <FundViewOutlined className='iconTabPane' /> </Tooltip>} key='4'>
                            <RDOContainer/>
                        </TabPane>
                        ):false}
                        {permisosUsuario.verMonitoreoDeModulosOperativos ? (
                        <TabPane tab={<Tooltip placement="top" title='Reporte Gráfico'>  <FileImageOutlined className='iconTabPane' /> </Tooltip>} key='5'>
                            <RerpoteGrafico/>
                        </TabPane>
                        ):false}
                        { permisosUsuario.verBitácoraMonitoreoIntegral && listaModulosBitacoraByIdModulo.length > 0 ? (
                            <TabPane tab={<Tooltip placement="top" title='Bitácora'><BookOutlined className='iconTabPaneSecondary'/> </Tooltip>} key='6'>
                                <Bitacora />
                            </TabPane>
                        ): false }
                    </Tabs>
                );
            }
            else if(vistaDashboard === 'Mecanismos de Pago'){
                return(
                    <Tabs destroyInactiveTabPane={true} defaultActiveKey="1" className='tabDashboard' onChange={limpiarMecanismosDePago}>
                        {permisosUsuario.buscarTiempoDeGarantiaservicio || permisosUsuario.buscarCategoriaDeFallasDeCalidad || permisosUsuario.buscarCategoriaPorFallaDeDisponibilidad ||permisosUsuario.verPonderaciónDeServiciosPorÁreaFuncional || permisosUsuario.buscarSecciónDeEstándaresEspecíficos || permisosUsuario.buscarRefenciaDeEstándaresDeDesempeño || permisosUsuario.buscarMetodoDeSupervision || permisosUsuario.buscarEstándarDeDesempeño || permisosUsuario.verPeriodoDeFracciónMáximaDeDescuento || permisosUsuario.buscarNivelDeSeveridad || permisosUsuario.buscarTiempoDeRespuestaPorSección ? (
                        <TabPane tab={<Tooltip placement="top" title='Catálogos'>  <ProfileOutlined className='iconTabPane' /> </Tooltip>} key='1'>
                            <MecanismosDePagos/>
                        </TabPane>
                        ):false}
                        {permisosUsuario.verValoresDePagoAnual ? (
                        <TabPane tab={<Tooltip placement="top" title='Valores de Pago Anual'>  <DollarOutlined className='iconTabPane' /> </Tooltip>} key='2'>
                            <ValoresPagoAnualContainer/>
                        </TabPane>
                        ):false}
                        {permisosUsuario.verAñoMesCalendario ? (
                        <TabPane tab={<Tooltip placement="top" title='Año/Mes Calendario'>  <ScheduleOutlined className='iconTabPane' /> </Tooltip>} key='3'>
                            <AñoMesCalendarioContainer/>
                        </TabPane> 
                        ):false}
                        {permisosUsuario.buscarFolioPorErroresUOmisión ? (
                        <TabPane tab={<Tooltip placement="top" title='Folio por Errores u Omisiones'> <ExceptionOutlined className='iconTabPane' /> </Tooltip>} key='9'>
                            <FolioErrorOmision />
                        </TabPane>
                        ):false}
                        {permisosUsuario.buscarFolioDeConciliación ? (
                        <TabPane tab={<Tooltip placement="top" title='Folio de Conciliación'> <ReadOutlined className='iconTabPane' /> </Tooltip>} key='7'>
                            <FolioConciliacion />
                        </TabPane>
                        ):false}
                        {permisosUsuario.conciliarTicket ? (
                        <TabPane tab={<Tooltip placement="top" title='Conciliación'> <FileTextOutlined className='iconTabPane' /> </Tooltip>} key='8'>
                            <ConciliacionContainer/>
                        </TabPane>
                        ):false}
                        
                        {permisosUsuario.editarConciliación ? (
                        <TabPane tab={<Tooltip placement="top" title='Editar Conciliación'> <FormOutlined className='iconTabPane' /> </Tooltip>} key='10'>
                            <EditarConciliacionContainer />
                        </TabPane>
                        ):false}
                        {permisosUsuario.verDeductivas ? (
                        <TabPane tab={<Tooltip placement="top" title='Cálculo de Deductivas'> <CalculatorOutlined className='iconTabPane' /> </Tooltip>} key='4'>
                            <CalcularDeductivasContainer/>
                        </TabPane>
                        ):false}
                        {permisosUsuario.buscarNumeroDeDocumentoPorReiteracionesDeFallasABuscar ? (
                        <TabPane tab={<Tooltip placement="top" title='Número de Documento por Reiteranción de Fallas'> <FileAddOutlined className='iconTabPane' /> </Tooltip>} key='5'>
                            <NoDocumentoCalcularReiteracionFallasContainer/>
                        </TabPane>
                        ):false}
                        {permisosUsuario.verCálculoDeDeductivasPorReiteración ? (
                        <TabPane tab={<Tooltip placement="top" title='Cálculo de Deductivas por Reiteración'> <SyncOutlined className='iconTabPane' /> </Tooltip>} key='6'>
                            <CalcularDeductivasReiteracionContainer/>
                        </TabPane>
                        ):false}
                        {permisosUsuario.verPagoPorServicios ? (
                        <TabPane tab={<Tooltip placement="top" title='Pago por Servicio'> <TagOutlined className='iconTabPane' /> </Tooltip>} key='11'>
                            <PagoServicioContainer />
                        </TabPane>
                        ):false}
                        {permisosUsuario.verPagoDeServiciosPúblicos ? (
                        <TabPane tab={<Tooltip placement="top" title='Pago de servicios públicos'> <TagsOutlined className='iconTabPane' /> </Tooltip>} key='12'>
                            <PagoDeServiciosPublicosContainer />
                        </TabPane>
                        ):false}
                        {permisosUsuario.verDeductivasPorFallasEnElServicio || permisosUsuario.verDeductivasInternas || permisosUsuario.verConcentradoAnual  ? (
                        <TabPane tab={<Tooltip placement="top" title='Reporte Deductivas'>  <ReconciliationOutlined className='iconTabPane' /> </Tooltip>} key='13'>
                            <ReporteDeductivasContainer/>
                        </TabPane>
                        ):false}
                        {permisosUsuario.verMonitorDeDeductivas ? (
                        <TabPane tab={<Tooltip placement="top" title='Monitor de Deductivas'>  <FundProjectionScreenOutlined className='iconTabPane' /> </Tooltip>} key='14'>
                            <RDODeductivas/>
                        </TabPane>
                        ):false}
                        { permisosUsuario.verBitácoraMecanismosDePago && listaModulosBitacoraByIdModulo.length > 0 ? (
                            <TabPane tab={<Tooltip placement="top" title='Bitácora'><BookOutlined className='iconTabPaneSecondary'/> </Tooltip>} key='15'>
                                <Bitacora />
                            </TabPane>
                        ): false }
                    </Tabs>
                );
            }
            else if(vistaDashboard === 'RMDP'){
                return(
                    <Tabs destroyInactiveTabPane={true} defaultActiveKey="1" className='tabDashboard'>
                        {permisosUsuario.buscarPorAñoYMesElRmdp}
                        <TabPane tab={<Tooltip placement="top" title='RMDP'><ReconciliationOutlined className='iconTabPane'/> </Tooltip>} key='1'>
                            <RMDPContainer />
                        </TabPane>
                        { permisosUsuario.verBitácoraRmdp && listaModulosBitacoraByIdModulo.length > 0 ? (
                            <TabPane tab={<Tooltip placement="top" title='Bitácora'><BookOutlined className='iconTabPaneSecondary'/> </Tooltip>} key='2'>
                                <Bitacora />
                            </TabPane>
                        ): false }
                    </Tabs>  
                );
            }
            else if(vistaDashboard === 'editarPerfil'){
                return(
                    <div>
                        <EditarPerfil/>
                    </div>
                );
            }
        return <div>  </div>;

		};

        return (
            <div className="imgCorp" style={{backgroundColor: '#F2F2F2'}}>
                <ContentApp/>
                <p style={{textAlign: 'right', fontStyle: 'italic'}}>V-1.25.1</p>
            </div>
        );  
    }
}

const mapStateToProps = state => {
    return {
        vistaDashboard: state.ConfiguracionYSeguridadReducer.vistaDashboard,
        listaModulosBitacoraByIdModulo: state.ConfiguracionYSeguridadReducer.listaModulosBitacoraByIdModulo,
    };
};

const mapDispatchToProps = dispatch => {
	return {
        onGetProveedoresBusqueda: (page, formulario) => {
	    	dispatch({ type: "LISTA_PROVEEDORES_BUSQUEDA_REQUEST", page: page, formulario: formulario });
        },
        onGetListaServicios: () => {
            dispatch({ type: 'LISTA_SERVICIOS_REQUEST' });
        },
        onGetListaDominios: () => {
            dispatch({ type: 'LISTA_DOMINIOS_ACTIVOS_REQUEST' });
        },
        onGetListaUsuariosSinFiltros: (page) => {
	    	dispatch({ type: "LISTA_USUARIOS_SIN_FILTRO_REQUEST", page:page });
        },
        onListaRoles: () => {
            dispatch({ type: 'LISTA_ROLES_REQUEST'})
        },
        onGetProveedores: () => {
            dispatch({ type: 'GET_PROVEEDORES_REQUEST' })
        },
        onGetListaExpedientes: () => {
            dispatch({ type: 'LISTA_EXPEDIENTES_REQUEST' });
        },
        onGetListaContratos: () => {
            dispatch({ type: 'LISTA_CONTRATOS_REQUEST' });
        },
        onGetSubfamilias: () => {
            dispatch({ type: 'LISTA_SUBFAMILIAS_REQUEST' });
        },
        onGetCategoriasUnidadesMedidas: () => {
            dispatch({ type: 'LISTA_CATEGORIAS_MEDICION_ACTIVOS_REQUEST' });
        },
        onLimpiarTablaAsignacion: () => {
            dispatch({ type: 'ASIGNACION_ACTIVOS_BUSQUEDA_SUCCESS', listaAsignacionActivosBusqueda: [], })
        },
        onLimpiarContainerAlmacenes: () => {
            dispatch({ type: 'CAMBIAR_CATALOGO_ALMACENES', catalogoAlmacenes: null})
        },
        onLimpiarContainerSolicitudServicio: () => {
            dispatch({ type: "CAMBIAR_SOLICITUD_SERVICIOS" , solicitudDeServicios: null, identificacion: true});
        },
        onCloseModal: () => {
            dispatch({ type: 'SHOW_MODAL_FOLIO_SOLICITUD', showModalFolioSolicitud: false, horaCreacion: '',fechaCreacion: '', folio: '', identificacion: true });
        },
        onLimpiarProgramaRMP: () => {
            dispatch({ type: 'LISTA_RMP_BUSQUEDA_SUCCESS', listaRMPBusqueda: [] });
        },
        onCleanPartidasByFalla: () => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_FALLA_SUCCESS', listaPartidasByFalla: [] });
        },
        onCleanDataSatisfaccionServicio: () => {
            dispatch({ type: 'DATA_GRAFICAS_ENCUSTAS_SATISFACCION_SERVICIO_SUCCESS', dataGraficasByServicio: [] });
        },
        onCleanDataQuejasByServicio: () => {
            dispatch({ type: 'DATA_GRAFICAS_QUEJAS_SERVICIO_SUCCESS', dataGraficasQuejasByServicio: [] });
        },
        onCleanDataQuejas: () => {
            dispatch({ type: 'DATA_GRAFICAS_QUEJAS_REQUEST', dataGraficasQuejas: [] });
        },
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        },

        onLimiarConcentradoAnual: () => {
            dispatch({ type: 'CONCENTRADO_ANUAL_RESUMEN_SUCCESS', concentradoAnualResumen: [], concentrado: [] });
        },
        onGetGrupoServicioByIdUsuario: (idUsuario) => {
            dispatch({ type: 'LISTA_GRUPO_SERVICIOS_BYIDUSUARIO_REQUEST', idUsuario });
        },
        onGetClasificacionActivos: () => {
            dispatch({ type: 'LISTA_CLASIFICACION_ACTIVOS_REQUEST' });
        },
        onGetGruposServiciosActivos: () => {
            dispatch({ type: "LISTA_GRUPOS_SERVICIOS_REQUEST" });
        },
        onGetGerenciasByIdUsuario: idUsuario => {
            dispatch({ type: 'LISTA_GERENCIAS_BYIDUSUARIO_REQUEST', idUsuario });
        },
        onGetModulosBitacoraByIdModulo: () => {
            dispatch({ type: 'LISTA_MODOULOS_BITACORAS_BY_IDMODULO_REQUEST' });
        },
        onNuevaConsultaKardex: () => {
            dispatch({ type: 'KARDEX_PRODUCTOS_EXISTENETES_SUCCESS', kardexEncabezado: false, kardexCardsMovimientos: false });
        },
        onLimpiarKardex: () => {
            dispatch({ type: 'KARDEX_OBTENER_MOVIMIENTOS_SUCCESS', listaKardexMovimientos: [], dataMovimientosSumas: [], dataMovimientosRestas: [], buttonExport: true, cantidadMovimientoSuma: null, cantidadMovimientoResta: null });
        },
        cleanProductividad: () => {
            dispatch({ type: "GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_SUCCESS", dataGraficaProductividad: {}, optionsGraficaProductividad: {} });
        },
        onCleanListaSelectedAlmacenes: () => {
            dispatch({ type: 'ACTUALIZAR_TABLA_MOVIMIENTOS', listaSeleccionados: [], hasSelected: true });
        }
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
