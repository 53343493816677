import React from 'react';
import {connect} from 'react-redux';
import {Table, Row, Col, Input, Select, Form, DatePicker, message} from 'antd';
import moment from 'moment';
import CeldaEditable from './CeldaEditable';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const {Option} = Select;
const {TextArea} = Input;
export const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

class TareasTable extends React.Component {
    render() {
        const { cancelacionOrdenesSPDetalleCantidad,estatusOrdenSPSeleccionado } = this.props;
        const { data, pagina, onEditData, onEditInput, onHandleError } = this.props;

        const components = {
            body: {
                row: EditableRow,
                cell: CeldaEditable,
            },
        };

        const columns = estatusOrdenSPSeleccionado?.programa == "Rutina" ? 
        [{
            title: 'Fecha de Ejecución',
            dataIndex: 'fechaEjecucion',
            key: 'fechaEjecucion',
            width: '7%',
        },{
            title: 'Ubicacion',
            dataIndex: 'ubicacion',
            key: 'ubicacion',
            width: '25%',
        }, {
            title: 'Estado',
            dataIndex: 'estadoOrden',
            key: 'estadoOrden',
            width: '23%',
            align: 'center',
            render: (text, record) => {
                return (
                    <Row justify='space-around'>
                        <Col span={record.fechasRutinasByIdOrdenServicio.estado === 'Reprogramada' ? 11:24}>
                            <Select defaultValue={record.fechasRutinasByIdOrdenServicio.estado === 'En proceso' ? undefined : record.fechasRutinasByIdOrdenServicio.estado} onChange={value => onHandleEstado(value, record, 'estadoOrden')}
                                style={{ width: '100%' }} placeholder="Selecciona un programa">
                                <Option value='Realizada'>Realizada</Option>
                                <Option value='Reprogramada'>Reprogramada</Option>
                            </Select>
                        </Col>
                        {record.fechasRutinasByIdOrdenServicio.estado === 'Reprogramada' ? (
                        <Col span={11}>
                            <DatePicker style={{ width: '100%' }} format='DD-MM-YYYY' 
                                allowClear={false}
                                showToday={false}
                                placeholder={["Selecciona fecha"]}
                                defaultValue={moment(record.fechaReprogramacion,'DD-MM-YYYY')}
                                disabledDate={value => disabledRange(value, record)}
                                onChange={value => onHandleEstado(value, record, 'fecha')}
                            />
                        </Col>
                        ):false}
                    </Row>
                )
            }
        },{
            title: 'Observaciones',
            dataIndex: 'observaciones',
            key: 'observaciones',
            width: '15%',
            editable: true,
            render: (text, record) => {
                return (
                    <TextArea
                        pattern="^^[a-zñA-ZÑ0-9 ÁÉÓÚÍáéóíú]+$"
                        title="Observaciones sin carácteres especiales"
                        maxLength={250}
                        placeholder={record.fechasRutinasByIdOrdenServicio.estado !== 'Reprogramada' ? 'No aplica' : 'Ingrese las observaciones.'}
                        autoSize
                        disabled={record.fechasRutinasByIdOrdenServicio.estado !== 'Reprogramada'}
                        value={record.observaciones}
                    />
                );
            }
        }, {
            title: 'Fecha y Hora Realización',
            dataIndex: 'fechaRealizacion',
            key: 'fechaRealizacion',
            width: '15%',
            align: 'center',
            render: (text, record) => {
                return (
                    <DatePicker style={{ width: '100%' }} format='DD-MM-YYYY HH:mm' 
                        allowClear={false}
                        showToday={false}
                        showTime={{ format: 'HH:mm' }}
                        placeholder={["Selecciona fecha"]}
                        defaultValue={moment(record.fechaRealizacion,'DD-MM-YYYY HH:mm')}
                        disabledDate={disabledRangeRealizacion}
                        onChange={value => onHandleEstado(value, record, 'fechaRealizacion')}
                    />
                )
            }
        },{
            title: 'Resultados',
            dataIndex: 'resultados',
            key: 'resultados',
            editable: true,
            width: '15%',
            render: (text, record) => {
                return (
                    <TextArea
                        pattern="^^[a-zñA-ZÑ0-9 ÁÉÓÚÍáéóíú]+$"
                        title="Resultados sin caracteres especiales"
                        maxLength={250}
                        placeholder='Ingrese los resultados.'
                        autoSize
                        value={record.resultados}
                    />
                );
            }
        }
    ]: 
        [{
            title: 'Fecha de Ejecución',
            dataIndex: 'fechaEjecucion',
            key: 'fechaEjecucion',
            width: '9%',
        },{
            title: 'Ubicacion',
            dataIndex: 'ubicacion',
            key: 'ubicacion',
            width: '20%',
        },{
            title: 'Código Interno',
            dataIndex: 'codigoInterno',
            key: 'codigoInterno',
            width: '7%',
        },{
            title: 'Descripcion activo',
            dataIndex: 'descripcion',
            key: 'descripcion',
            width: '13%',
        },{
            title: 'Estado',
            dataIndex: 'estadoOrden',
            key: 'estadoOrden',
            width: '17%',
            align: 'center',
            render: (text, record) => {
                return (
                    <Row justify='space-around'>
                        <Col span={record.fechasRutinasByIdOrdenServicio.estado === 'Reprogramada' ? 11:24}>
                            <Select  defaultValue={record.fechasRutinasByIdOrdenServicio.estado === 'En proceso' ? undefined : record.fechasRutinasByIdOrdenServicio.estado} onChange={value => onHandleEstado(value, record, 'estadoOrden')}
                                style={{ width: '100%' }} placeholder="Selecciona un programa">
                                <Option value='Realizada'>Realizada</Option>
                                <Option value='Reprogramada'>Reprogramada</Option>
                            </Select>
                        </Col>
                        {record.fechasRutinasByIdOrdenServicio.estado === 'Reprogramada' ? (
                        <Col span={11}>
                            <DatePicker style={{ width: '100%' }} format='DD-MM-YYYY' 
                                allowClear={false}
                                showToday={false}
                                placeholder={["Seleccione fecha suministro"]}
                                defaultValue={moment(record.fechaReprogramacion,'DD-MM-YYYY')}
                                disabledDate={value => disabledRange(value, record)}
                                onChange={value => onHandleEstado(value, record, 'fecha')}
                            />
                        </Col>
                        ):false}
                    </Row>
                )
            }
        },{
            title: 'Fecha y Hora Realización',
            dataIndex: 'fechaRealizacion',
            key: 'fechaRealizacion',
            width: '20%',
            align: 'center',
            render: (text, record) => {
                return (
                    <DatePicker style={{ width: '100%' }} format='DD-MM-YYYY HH:mm' 
                        allowClear={false}
                        showToday={false}
                        showTime={{ format: 'HH:mm' }}
                        placeholder={["Selecciona fecha"]}
                        defaultValue={moment(record.fechaRealizacion,'DD-MM-YYYY HH:mm')}
                        disabledDate={disabledRangeRealizacion}
                        onChange={value => onHandleEstado(value, record, 'fechaRealizacion')}
                    />
                )
            }
        },{
            title: 'Observaciones',
            dataIndex: 'observaciones',
            key: 'observaciones',
            width: '15%',
            editable: true,
            render: (text, record) => {
                return (
                    <TextArea
                        pattern="^^[a-zñA-ZÑ0-9 ÁÉÓÚÍáéóíú.,]+$"
                        title="Observaciones sin carácteres especiales"
                        maxLength={250}
                        placeholder='Ingrese las observaciones.'
                        autoSize
                        value={record.observaciones}
                    />
                );
            }
        }]
        
        const disabledRangeRealizacion = (fecha) => {
            const currentYear = new Date();
            const year = currentYear.getFullYear();
            const fechaVigencia = moment(`31/12/${year}`, "DD-MM-YYYY");
            const fechaInicio = moment(estatusOrdenSPSeleccionado.fechaOrden, "DD-MM-YYYY");
            if (fecha && fecha < fechaInicio) {
                return fecha && fecha < fechaInicio;
            } else {
                return fecha && fecha > fechaVigencia;
            }
        }
        const disabledRange = (fecha, record) => {
            const currentYear = new Date();
            const year = currentYear.getFullYear();
            const fechaVigencia = moment(`31/12/${year}`, "DD-MM-YYYY");
            const fechaInicio = moment(`${record.fechasRutinasByIdOrdenServicio.fecha.split('T')[0].split('-').reverse().join('-')}`, "DD-MM-YYYY").add(1, 'd');
            if (fecha && fecha < fechaInicio) {
                return fecha && fecha < fechaInicio;
            } else {
                return fecha && fecha > fechaVigencia;
            }
        }

        const onHandleEstado = (value, data, field, ordenReprogramada) => {
            this.props.onActualizarRevisarTareas(this.props.listaPersonal, true);
            this.props.onActualizarRevisarInsumos(this.props.listaInsumos, true);
            console.log('value, data, field, ordenReprogramada', value, data, field, ordenReprogramada)
            onEditData(value, data, field, ordenReprogramada);
        }

        const handleTableChange = (pagination) => {
            onEditData(pagination.current, data, 'paginacion');
        }

        const columnsTable = columns.map((col) => {
            if (!col.editable) {
                return col;
            }
      
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: onEditInput,
                    handleError:onHandleError,
              }),
            };
        });

        const estadoSeleccionado = (value) => {
            const estatusOrdenSPSeleccionadoLocal = estatusOrdenSPSeleccionado;
            estatusOrdenSPSeleccionadoLocal.estado = value;
            if(value != 'Realizada'){
                message.warning('No es posible seleccionar personal cuando todas las tareas son reprogramadas');
                message.warning('No es posible seleccionar insumos y herramientas cuando todas las tareas son reprogramadas');
            }
            this.props.actualizarEstado(estatusOrdenSPSeleccionadoLocal, this.props.listaInsumos, this.props.listaSinRepetir, this.props.listaSinRepetirHerramienta, this.props.busqueda, this.props.page, this.props.listaPersonal)
            onHandleEstado(value, data, 'estadoCompleto', value === 'Realizada' ? false : true)
        }
        return (
            <div>
                {estatusOrdenSPSeleccionado? (    
                    <Row type='flex' justify='space-around'>
                        <Col span={24}>
                            <Row justify='end' style={{paddingTop:'20px'}}>
                                <p><strong>Al seleccionar un programa afectará a todas las tareas </strong></p>
                                <Col span={8} offset={1}>
                                    <Select allowClear value={estatusOrdenSPSeleccionado.estado} onChange={estadoSeleccionado}
                                        style={{ width: '100%' }} placeholder="Selecciona un programa"
                                    >
                                        <Option value='Realizada'>Realizada</Option>
                                        <Option value='Reprogramada'>Reprogramada</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <br/>

                            <Table
                                components={components}
                                size='small'
                                rowClassName={() => 'editable-row'}
                                rowKey={estatusOrdenSPSeleccionado?.programa == "Rutina" ? "ubicacion" : "codigoInterno"}
                                columns={columnsTable}
                                dataSource={data}
                                locale={{ emptyText: 'Sin datos' }}
                                onChange={handleTableChange}
                                pagination={{ total: cancelacionOrdenesSPDetalleCantidad, simple: true, current: pagina, hideOnSinglePage: true }}
                                scroll={{ "x": 1500 | true, "y": 1000 }}
                            />
                        </Col>
                    </Row>                    
                ) : false}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingCancelacionOrdenesSPDetalle: state.ServiciosPlanificadosReducer.fetchingCancelacionOrdenesSPDetalle,
        cancelacionOrdenesSPDetalle: state.ServiciosPlanificadosReducer.cancelacionOrdenesSPDetalle,
        cancelacionOrdenesSPDetalleCantidad: state.ServiciosPlanificadosReducer.cancelacionOrdenesSPDetalleCantidad,
        estatusOrdenSPSeleccionado: state.ServiciosPlanificadosReducer.estatusOrdenSPSeleccionado,
        listaInsumos: state.ServiciosPlanificadosReducer.listaInsumos, 
        listaSinRepetir: state.ServiciosPlanificadosReducer.listaSinRepetir, 
        listaSinRepetirHerramienta: state.ServiciosPlanificadosReducer.listaSinRepetirHerramienta,
        busqueda: state.ServiciosPlanificadosReducer.busqueda, 
        page: state.ServiciosPlanificadosReducer.page,
        listaPersonal: state.ServiciosPlanificadosReducer.listaPersonal, 
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalDetalle: () => {
            dispatch({ type: 'SHOW_MODAL_DETALLE_ESTATUS_ORDEN_SP_PROGRAMA', showModalDetalleEstatusOrdenesSPPrograma: false});
        },
        actualizarEstado: (estatusOrdenSPSeleccionado, listaInsumos, listaSinRepetir, listaSinRepetirHerramienta, busqueda, page, listaPersonal) => {
            dispatch({ type: 'SHOW_MODAL_CAMBIAR_ESTATUS_OSP', showModalCambiarEstatusOrdenSP: true, estatusOrdenSPSeleccionado, listaInsumos, listaSinRepetir, listaSinRepetirHerramienta, busqueda, page, listaPersonal});
        },
        onActualizarRevisarTareas: (listaPersonal, revisarTareas) => {
            dispatch({ type: "ACTUALIZAR_LISTA_PERSONAL_ESTATUS",  listaPersonal, revisarTareas });
        },
        onActualizarRevisarInsumos: (listaInsumos, revisarInsumos) => {
            dispatch({ type: "ACTUALIZAR_LISTA_INSUMOS_ESTATUS",  listaInsumos, revisarInsumos });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (TareasTable);