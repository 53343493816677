import React, { Component } from 'react';
import { Form, Input, Button, Modal, Row, Col, Spin, Upload, message, Tooltip } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalNuevoRol extends Component {
    formRef = React.createRef();
    state={
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
    }
    render() {
        const { fetchingNuevoExpediente, showModalNuevoExpediente, onNuevoExpediente, onShowModalNuevoExpediente,
            limpiarFormulario, onLimpiarFormulario
        } = this.props;
        console.log('limpiarFormulario');
        console.log(limpiarFormulario);
        if(limpiarFormulario && limpiarFormulario === 'crearExpediente'){
            console.log('entró a limpiar');
            this.formRef.current.resetFields();
            this.setState({
                filePDF: false,
                botonPDF: false,
                botonEditar: false,
            });
            onLimpiarFormulario();
        }

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onNuevoExpediente(formulario, this.props.page);
        };
        

        const handleCancel = () => {
            onShowModalNuevoExpediente();
            this.formRef.current.resetFields();
        }
        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                })
            }
            var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,30}$");
            if (info.file.status === 'done') {
                if(info.file.size > 70000000){
                    message.error('El tamaño máximo aceptado es de 70 MB', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if(info.file.size > 70000000){
                    message.error('El tamaño máximo aceptado es de 70 MB', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }
        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };
          
        return (
            <Modal
            visible={showModalNuevoExpediente}
            title='Nuevo Expediente'
            onCancel={handleCancel}
            footer={false}
            width={'70%'}
            >
                <Spin spinning={fetchingNuevoExpediente}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Clave expediente"
                                    name="clave"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={25} placeholder="Ingresa la clave" pattern='^[-A-Z._0-9]+$' title='Sólo se admiten letras mayúsculas, números, punto o guiones'/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Descripción del expediente"
                                    name="descripcion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={250} placeholder="Ingresa la descripción" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Tooltip
                                    title='El nombre del expediente no podrá ser cambiado en un futuro'
                                    placement="topLeft"
                                    overlayClassName="numeric-input"
                                >
                                    <Form.Item
                                        label="Nombre del expediente"
                                        name="nombreCarpeta"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Input maxLength={30} placeholder="Ingresa el nombre del expediente" pattern='^[a-zñA-ZÑ0-9]+$' title='Sólo se permiten letras y números sin espacios.'/>
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Archivo PDF"
                                    name="pdf"
                                >
                                    <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                        {this.state.filePDF === false ? (
                                        <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                            <UploadOutlined /> Click para subir archivo PDF
                                        </Button>
                                        ):false}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        
                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button disabled={this.state.botonEditar} className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Crear Expediente</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingNuevoExpediente: state.InformacionOperativaReducer.fetchingNuevoExpediente,
        showModalNuevoExpediente: state.InformacionOperativaReducer.showModalNuevoExpediente,
        page: state.InformacionOperativaReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalNuevoExpediente: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_EXPEDIENTE', showModalEditarExpediente: false, page: null })
        },
        onNuevoExpediente: ( formulario, page) => {
            dispatch({ type: "NUEVO_EXPEDIENTE_REQUEST" , formulario: formulario, page: page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoRol);