import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, FileExcelOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoTipoDeFolio from './ModalNuevoTipoFolioMovimiento';
import ModalEditarTipoFolio from './ModalEditarTipoFolioMovimiento';

const confirm = Modal.confirm;
const { Search } = Input;

class CatalogoTiposFoliosMovimientos extends React.Component {
    state = {
        page: 1,
        busqueda: '',
    }

    componentDidMount() {
        this.props.onGetTiposFoliosMovimientos(1);
    }

    render() {
        const { fetchingListaTiposFoliosMovimientosBusqueda, listaTiposFoliosMovimientosBusqueda,listaTiposFoliosMovimientosBusquedaCantidad, onGetTiposFoliosMovimientos,
            onModalNuevoTipoFolioMovimiento, onModalEditarTipoFolioMovimiento, onEstadoTipoFolioMovimiento } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const disabledField =['EPRD', 'SPRD', 'EVDC', 'BAJA', 'SPIN', 'SPSM'];

        const columns = [{
            title: 'Nombre',
            dataIndex: 'nombre',
            key: 'nombre',
        }, {
            title: 'Nomenclatura',
            dataIndex: 'nomenclatura',
            key: 'nomenclatura',
        }, {
            title: 'Operación al inventario',
            dataIndex: 'operacion',
            key: 'operacion',
        },{
            title: 'Requiere folio origen',
            dataIndex: 'folioOrigen',
            key: 'folioOrigen',
            render: (text,record) => (
                <div>
                    {record.folioOrigen == true ? 'Si':'No' }
                </div>
            )
        }, {
            title: 'Activo',
            dataIndex: 'activo',
            key: 'activo',
            align: 'center',
            render: (text, record) => (
                <Switch checked={text} disabled={!permisosUsuario.folioDeMovimientoActivoInactivo || disabledField.includes(record.nomenclatura)} onChange = {() => handleDesactivar(record)} />
            ),
        }, {
            title: 'Editar',
            key: 'editar',
            align: 'center',
            //width: '6%',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.editarFolioDeMovimiento || disabledField.includes(record.nomenclatura)}  onClick={() => onEditar(record)}><EditOutlined /></a>
            ),
        },
        ];

        const onBuscarTipodeFolio = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            });
            console.log(busqueda);
            onGetTiposFoliosMovimientos(1, busqueda);
        } 

        const handleDesactivar = (key) => {
            console.log(key.idTipoFolio);
            let page = this.state.page
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del tipo de folio de movimiento "' + key.nombre + '"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoTipoFolioMovimiento(key.idTipoFolio, page);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }

        const onEditar = (key) => {
            onModalEditarTipoFolioMovimiento(key, this.state.page);

        }

        const onNuevoTipoDeFolio = () => {
            onModalNuevoTipoFolioMovimiento(this.state.page);
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            onGetTiposFoliosMovimientos(pagination.current, this.state.busqueda);
        }

        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={20}>
                        <Search className='buscarModulos' placeholder='Ingresar tipo de folio de movimiento' onSearch={value => onBuscarTipodeFolio(value)} />
                    </Col>
                    {/* <Col span={8}>
                        <Button onClick={onNuevoTipoDeFolio} disabled={!permisosUsuario.crearTipoDeFolioDeMovimiento} className='nuevoUsuario'>Nuevo Tipo de Folio de Movimiento</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nuevo Tipo de Folio de Movimiento' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoTipoDeFolio} disabled={!permisosUsuario.crearTipoDeFolioDeMovimiento} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={1}>
                        <Tooltip title='Exportar archivo .xls' placement="topRight">
                            <Button disabled={!permisosUsuario.exportarExcelCatálogoTiposFoliosMovimiento} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } loading={this.props.fetchingExportarExcelProductos} onClick={() => this.props.onExport()}/>
                        </Tooltip>
                    </Col>

                    <Col span={24}>
                        <br />
                        <Table
                            size="small"
                            rowKey="nombre"
                            columns={columns}
                            dataSource={listaTiposFoliosMovimientosBusqueda}
                            loading={fetchingListaTiposFoliosMovimientosBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaTiposFoliosMovimientosBusquedaCantidad, simple: true, current: this.state.page }}
                        />
                    </Col>
                </Row>
                <ModalNuevoTipoDeFolio/>
                <ModalEditarTipoFolio/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        listaTiposFoliosMovimientosBusqueda: state.AlmacenesReducer.listaTiposFoliosMovimientosBusqueda,
        fetchingListaTiposFoliosMovimientosBusqueda: state.AlmacenesReducer.fetchingListaTiposFoliosMovimientosBusqueda,
        listaTiposFoliosMovimientosBusquedaCantidad: state.AlmacenesReducer.listaTiposFoliosMovimientosBusquedaCantidad,
        fetchingExportarExcelTiposFolios: state.AlmacenesReducer.fetchingExportarExcelTiposFolios,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetTiposFoliosMovimientos: (page, busqueda) => {
            dispatch({ type: "TIPOS_FOLIOS_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
        },
        onEstadoTipoFolioMovimiento: (idTipoFolio, page) => {
            dispatch({ type: "ESTADO_TIPOS_FOLIOS_REQUEST", idTipoFolio: idTipoFolio, page: page});
        },
        onModalNuevoTipoFolioMovimiento: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_TIPO_FOLIO", showModalNuevoTipoFolioMovimiento: true, page: page })
        },
        onModalEditarTipoFolioMovimiento: (TipoFolioMovimientoSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_TIPO_FOLIO", showModalEditarTipoFolioMovimiento: true, TipoFolioMovimientoSeleccionado:TipoFolioMovimientoSeleccionado, page:page});
        },
        onExport: () => {
            dispatch({ type: "EXPORTAR_TIPOS_FOLIO_EXCEL_REQUEST" });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoTiposFoliosMovimientos);