import React, { Component } from 'react';
import { Form, Button, Modal, Row, Col, Spin, Select, DatePicker, InputNumber, Divider, message } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class EditarTiempoDeGarantia extends Component {
    formRef = React.createRef();
    componentDidMount() {
        this.props.onGetUnidadesDeMedicion(1);
    }
    state={
        tipoEdicion: null,
        disabledSelect: false,
    }
    render() { 

        const {
            onEditarTiempoDeGarantia,
            tiempoDeGarantiaSeleccionado,
            fetchingEditarTiempoDeGarantia,
            listaInfoProyecto,
            fetchingListaInfoProyecto,
            onLimpiarFormulario,
            limpiarFormulario,
            onCambiarVista,
            page,
            unidadesMediPorCategoria,
            fetchingUnidadesMediPorCategoria,
            onListaServiciosFiltro,
            fetchingListaServiciosFiltro,
            onListaServiciosFiltroLimpiar,
            listaServiciosFiltro,
            onGetTiempos,
        } = this.props;
        

        if(limpiarFormulario && limpiarFormulario === 'editarTiempoDeGarantia'){
            console.log('entró a limpiar');
            this.formRef.current.resetFields();
            onLimpiarFormulario();
            onListaServiciosFiltroLimpiar();
            onCambiarVista(1);
        }

        const onFinish = formulario => {
            console.log('Success:', formulario);
            console.log('tiempo de garantia seleccionado', tiempoDeGarantiaSeleccionado);
            const rangeValue = formulario['rangoVigencia'];
            formulario.rangoVigencia = rangeValue[0].format('YYYY-MM-DD') + "," + rangeValue[1].format('YYYY-MM-DD');
            formulario.fechaInicioVigencia = formulario.rangoVigencia.split(',')[0];
            formulario.fechaFinVigencia = formulario.rangoVigencia.split(',')[1];
            if(this.state.tipoEdicion === 'simple'){
                onEditarTiempoDeGarantia(formulario, page, tiempoDeGarantiaSeleccionado, unidadesMediPorCategoria)
            }
            else{
                if (tiempoDeGarantiaSeleccionado.tiempoGarantia === formulario['tiempoGarantia'] && isNaN(formulario['unidadMedida'])) {
                    console.log('terminado:', formulario);
                    onEditarTiempoDeGarantia(formulario, page, tiempoDeGarantiaSeleccionado, unidadesMediPorCategoria)
                } else {
                    if(tiempoDeGarantiaSeleccionado.idUnidadMedida === parseInt(formulario['unidadMedida']) && tiempoDeGarantiaSeleccionado.tiempoGarantia === formulario['tiempoGarantia']){
                        onEditarTiempoDeGarantia(formulario, page, tiempoDeGarantiaSeleccionado, unidadesMediPorCategoria);
                    } else {
                        onCambiarVista(2, formulario);
                        console.log('Pedir contraseña ');
                        this.props.onGetTiempos(formulario, tiempoDeGarantiaSeleccionado, unidadesMediPorCategoria);
                    }

                }
            }
        }

        

        const fechas = (rangeValue) => {
            if(listaInfoProyecto.fechaInicio){
                if(rangeValue[0]){
                    const fechaInicioAnterior = tiempoDeGarantiaSeleccionado.fechaInicioVigencia.split('T')[0];
                    const diferenciaAnterior = moment(fechaInicioAnterior, 'YYYY-MM-DD').diff(moment(rangeValue[0].format('YYYY-MM-DD')), "days");
                    const nuevoTiempo = tiempoDeGarantiaSeleccionado.tiempoGarantia === this.formRef.current.getFieldValue(['tiempoGarantia']);

                    if (!nuevoTiempo) {
                        if (diferenciaAnterior > -1) {
                            message.error('No es posible seleccionar una fecha de inicio menor ni igual a la fecha de inicio anterior (' + fechaInicioAnterior.split('-').reverse().join('-') + ')', 7);
                            this.formRef.current.setFieldsValue({ 'rangoVigencia': [null, tiempoDeGarantiaSeleccionado.rangoVigencia[1]] });
                        }
                    }
                }
            } else {
                message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                this.formRef.current.setFieldsValue({ 'rangoVigencia': [null, tiempoDeGarantiaSeleccionado.rangoVigencia[1]] });
            }
        }

        const handleFecha = () => {
            const tiempoChange = tiempoDeGarantiaSeleccionado.tiempoGarantia === this.formRef.current.getFieldValue(['tiempoGarantia']);
            if(this.state.tipoEdicion === 'completa'){
                if (!tiempoChange) {
                    if(this.formRef.current.getFieldValue('rangoVigencia')[0]){
                        message.warning('Por motivos de seguridad, favor de seleccionar de nuevo la fecha de inicio de vigencia', 7);
                    }
                    this.formRef.current.setFieldsValue({'rangoVigencia': [null, tiempoDeGarantiaSeleccionado.rangoVigencia[1]]});
                }
            }
        }
        const handleUnidadMedida = () => {
            const unidadChange = tiempoDeGarantiaSeleccionado.idUnidadMedida === this.formRef.current.getFieldValue(['unidadMedida']);
            if(this.state.tipoEdicion === 'completa'){
                if (!unidadChange) {
                    if(this.formRef.current.getFieldValue('rangoVigencia')[0]){
                        message.warning('Por motivos de seguridad, favor de seleccionar de nuevo la fecha de inicio de vigencia', 7);
                    }
                    this.formRef.current.setFieldsValue({'rangoVigencia': [null, tiempoDeGarantiaSeleccionado.rangoVigencia[1]]});
                }
            }
        }

        const disableRange = fecha => {
            return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicio
        }

        const buscarServicios = value => {
            if(value.length>=3){
                if(value){
                    console.log(value);
                onListaServiciosFiltro(value)
                } else {
                    message.warning('No se encontró servicio con esas palabras')
                }
            } else {
               onListaServiciosFiltroLimpiar();
            } 
        };
        const onSelectTipoEdicion = (value) => {
            this.setState({
                tipoEdicion: value,
                disabledSelect: true,
            })
        }

        return ( 
            <div>
                {tiempoDeGarantiaSeleccionado ? (
                    <Spin spinning={fetchingEditarTiempoDeGarantia}>
                        <Form {...layout}
                            name="formulario"
                            initialValues={tiempoDeGarantiaSeleccionado}
                            onFinish={onFinish}
                            
                            ref={this.formRef}
                        >
                            <Row justify='space-around'>
                                <Col span={11}>
                                    <p>Selecciona el tipo de edición</p>
                                </Col>
                                <Col span={11}>
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el tipo de edición"
                                        onChange={onSelectTipoEdicion}
                                        disabled={this.state.disabledSelect}
                                    >
                                        <Option key='simple'>Edición simple</Option>
                                        <Option key='completa'>Edición por convenio</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Divider/>
                            {this.state.tipoEdicion ?(
                            <Row justify='space-around'>

                                <Col span={24}>
                                    <Form.Item
                                        label="Servicio (escriba el servicio a buscar)"
                                        name="nombre"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el servicio"
                                            loading={fetchingListaServiciosFiltro}
                                            showSearch
                                            filterOption={false}
                                            onSearch={buscarServicios}
                                            notFoundContent={null}
                                        >
                                            {listaServiciosFiltro.map(option => <Option key={option.idServicio + '-' + option.nombre}>{option.nombre}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                 <Col span={24}>
                                    <Form.Item
                                        label="Tiempo de garantia"
                                        name="tiempoGarantia"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <InputNumber min={1} max={100} placeholder='Ingrese tiempo de garantia'
                                             onChange={handleFecha}
                                            title="Número debe ser entero o fracción a dos decímales"
                                            pattern='^[0-9]*(\.[0-9]{0,2})?$' />
                                    </Form.Item>
                                </Col>


                                <Col span={24}>
                                    <Form.Item
                                        label="Unidad de medida"
                                        name="unidadMedida"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona la unidad de medida"
                                            loading={fetchingUnidadesMediPorCategoria}
                                            onChange={handleUnidadMedida}
                                        >
                                            {unidadesMediPorCategoria.map(option => <Option key={option.idUnidadMedida}>{option.unidadMedida}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="Fechas vigencias tiempo de garantia"
                                        name="rangoVigencia"
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <RangePicker
                                            format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio", "Fecha fin"]}
                                            onChange={fechas}
                                            loading={fetchingListaInfoProyecto}
                                            disabledDate={disableRange}
                                            // defaultValue={[, moment(listaInfoProyecto.fechaVigencia, 'DD-MM-YYYY')]}
                                            disabled={[false, true]}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Button className="btnFiltrar" htmlType="submit">Editar Tiempo de Garantia</Button>
                                </Col>
                            </Row>
                            ):false}
                        </Form>
                    </Spin>
                ) : false}
            </div>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        fetchingEditarTiempoDeGarantia: state.MecanismosDePagosReducer.fetchingEditarTiempoDeGarantia,
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,

       fetchingListaUnidadesDeMedicionActivos: state.AlmacenesReducer.fetchingListaUnidadesDeMedicionActivos,
       listaUnidadesDeMedicionActivos: state.AlmacenesReducer.listaUnidadesDeMedicionActivos,
       listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
       fetchingListaInfoProyecto: state.InformacionDelProyectoReducer.fetchingListaInfoProyecto,

       page: state.MecanismosDePagosReducer.page,
       limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
       tiempoDeGarantiaSeleccionado: state.MecanismosDePagosReducer.tiempoDeGarantiaSeleccionado,
       vistaModalTiempoDeGarantia: state.MecanismosDePagosReducer.vistaModalTiempoDeGarantia,
       
       unidadesMediPorCategoria: state.AlmacenesReducer.unidadesMediPorCategoria,
       fetchingUnidadesMediPorCategoria: state.AlmacenesReducer.fetchingUnidadesMediPorCategoria,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onEditarTiempoDeGarantia: (formulario, page, tiempoDeGarantiaSeleccionado ) => {
            dispatch({ type: "EDITAR_TIEMPODEGARANTIA_REQUEST", formulario: formulario, page: page, tiempoDeGarantiaSeleccionado: tiempoDeGarantiaSeleccionado })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onCambiarVista: (vistaModalTiempoDeGarantia, formularioGarantia) => {
            dispatch({ type: 'CAMBIAR_VISTA_TIEMPODEGARANTIA', vistaModalTiempoDeGarantia: vistaModalTiempoDeGarantia, formularioGarantia: formularioGarantia, tiemposEditados: null})
        },
        onGetUnidadesDeMedicion: (idCategoriaUnidadMedida) => {
            dispatch({ type: "UNIDADES_MEDI_POR_CATEGORIA_REQUEST", idCategoriaUnidadMedida:idCategoriaUnidadMedida});
        },
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
        },
        onGetTiempos: (formulario, tiempoDeGarantiaSeleccionado, unidadesMediPorCategoria) => {
            dispatch({ type: "LISTA_TIEMPODEGARANTIA_FORM_REQUEST", formulario,  tiempoDeGarantiaSeleccionado, unidadesMediPorCategoria})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditarTiempoDeGarantia);