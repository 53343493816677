import React, {Component} from 'react';
import { Form, Spin, Row, Col, Input, Button, DatePicker, message, Select, Modal, Divider, Tooltip} from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {connect} from 'react-redux';
import moment from 'moment';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalContraseña from '../ModalContraseña';

const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class TrasladoForm extends Component {
    formRef = React.createRef();
    state = {
        btnRegistrar: false,
        formulario: null,
    }
    render() {
        const { fetchingCambioEstadoSeguimiento, onActualizarSeguimiento,
            folioSolicitudServicioSeleccionado, page, busqueda, listaTrazabilidad,
            onShowModalContraseña, isEspecial ,onUbicacionesSinHijosFiltro ,
            onUbicacionesSinHijosFiltroLimpiar ,fetchingUbicacionesSinHijos ,
            ubicacionesSinHijos , showModalTraslado, folioSeleccionadoTraslado,
            onCerrarModal, 
        } = this.props;


        const currentYear = moment().format('YYYY');

        let permisosUsuario = sessionStorage.getItem('permisosUsuario')
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        if (isEspecial) {
            onActualizarSeguimiento(this.state.formulario, folioSeleccionadoTraslado, page, busqueda);
            onShowModalContraseña(false, false);
        };

        const onFinish = (formulario) => {
            console.log(formulario)
            if (permisosUsuario.especialSolicitudDeServicio) {
                this.setState({ formulario })
                onShowModalContraseña(true, false);
            } else {
                onActualizarSeguimiento(formulario, folioSeleccionadoTraslado, page, busqueda);
            }

        };

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo);
        };

        const onChangeDate = (value) => {

            if (value || listaTrazabilidad) {

                let fechaEstado;
                if (listaTrazabilidad.length > 0) {
                    if (listaTrazabilidad[0].fechaEstado) {
                        console.log(listaTrazabilidad[0].fechaEstado);
                        fechaEstado = listaTrazabilidad[0].fechaEstado
                    } else {
                        fechaEstado = null;
                    }
                } else {
                    fechaEstado = null
                }

                let resultadoValidacionFechaAlta = moment(value, 'DD-MM-YYYY HH:mm').isAfter(moment(folioSolicitudServicioSeleccionado.fechaAlta, 'DD-MM-YYYY HH:mm'));
                let resultadoValidacionFechaEstado = moment(value, 'DD-MM-YYYY HH:mm').isAfter(moment(fechaEstado, 'DD-MM-YYYY HH:mm'));
                let resultadoValidacionAñoCurrent = moment(value, 'YYYY').isAfter(moment(currentYear, 'YYYY'));
                console.log(resultadoValidacionFechaAlta, resultadoValidacionFechaEstado, resultadoValidacionAñoCurrent)
                if (!resultadoValidacionFechaAlta) {
                    message.warning('La fecha seleccionada tiene que ser mayor a la "Fecha de Alta" de la solicitud de servicio', 5);
                    this.setState({ btnRegistrar: true })
                } else if (!resultadoValidacionFechaEstado && fechaEstado !== null) {
                    message.warning('La fecha seleccionada tiene que ser mayor a la ultima "Fecha de Estado" de la solicitud de servicio', 5);
                    this.setState({ btnRegistrar: true })
                } else if (!resultadoValidacionAñoCurrent) {
                    message.warning('La fecha seleccionada tiene que ser durante el año en curso', 5);
                    this.setState({ btnRegistrar: true })
                } else {
                    this.setState({ btnRegistrar: false })
                }
            }
        };

        const buscarUbicacionSinHijo = (value) => {
            if(value.length>=3){
                if (value) {
                   console.log(value)
                   onUbicacionesSinHijosFiltro(value);
                     
                } else {
                     message.warning('No se encontró ubicación con esas palabras')
                }
            }
            else{
                 onUbicacionesSinHijosFiltroLimpiar();
            }
       };

       const handleCancel = () => {
           onCerrarModal();
       }

        return (
            <div>
                <Modal
                    visible={showModalTraslado}
                    title={'Ruta de Traslado'}
                    onCancel={handleCancel}
                    footer={false}
                    width='90%'>

                    <Spin spinning={fetchingCambioEstadoSeguimiento}>

                        {folioSeleccionadoTraslado ? (

                            <Form {...layout}
                                name='formulario'
                                onFinish={onFinish}
                                initialValues={folioSeleccionadoTraslado}
                                
                                ref={this.formRef}
                            >
                                

                                    <Divider>Agregar Rutas de Traslado</Divider>
                                    <Form.List name="listaTraslado">
                                        {(fields, { add, remove }) => (
                                            <>
                                              {fields.map(field => (
                                                <Row justify='space-around' key={field.key}>
                                                    <Col span={5}>
                                                        <Form.Item
                                                            {...field}
                                                            label='Tecnico Responsable'
                                                            name={[field.name, 'tecnicoResponsable']}
                                                            fieldKey={[field.fieldKey, 'tecnicoResponsable']}
                                                            rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                        >
                                                            <Input pattern="^^[ñíóáéú a-zA-Z 0-9]+$"
                                                                title="Nombre debe tener letras y numeros."
                                                                maxLength={100} placeholder='Ingrese nombre del tecnico' />
                                                            </Form.Item>
                                                    </Col>

                                                    <Col span={5}>
                                                        <Form.Item
                                                            {...field}
                                                            label='Observaciones'
                                                            name={[field.name, 'observaciones']}
                                                            fieldKey={[field.fieldKey, 'observaciones']}
                                                            rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                        >
                                                            <Input pattern="^^[ñíóáéú a-zA-Z 0-9]+$"
                                                                title="observaciones debe tener letras y numeros."
                                                                maxLength={100} placeholder='Ingrese observaciones' />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={7}>
                                                        <Form.Item
                                                            {...field}
                                                            label='Ubicaciones'
                                                            name={[field.name, 'ubicacion']}
                                                            fieldKey={[field.fieldKey, 'idUbicacion']}
                                                            rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                        >
                                                            <Select allowClear
                                                                style={{ width: '100%' }}
                                                                placeholder="Escriba la ubicación a buscar"
                                                                loading={fetchingUbicacionesSinHijos}
                                                                showSearch
                                                                filterOption={false}
                                                                onSearch={buscarUbicacionSinHijo}
                                                                notFoundContent={null}
                                                            >
                                                                {ubicacionesSinHijos.map(option => 
                                                                    <Option key={option.idUbicacion+"-"+option.ubicacionCOA}>
                                                                        <Tooltip placement="topLeft" title={option.idUbicacion+"-"+option.ubicacionCOA}>
                                                                            {option.idUbicacion+"-"+option.ubicacionCOA}
                                                                        </Tooltip>
                                                                    </Option>
                                                                )}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={4}>
                                                        <Form.Item
                                                            {...field}
                                                            label='Fecha y Hora de Traslado'
                                                            name={[field.name, 'fechaTraslado']}
                                                            fieldKey={[field.fieldKey, 'fechaTraslado']}
                                                            rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                        >
                                                            <DatePicker
                                                                style={{ width: '100%' }}
                                                                format='DD-MM-YYYY HH:mm'
                                                                placeholder={["Seleccione fecha y hora Traslado"]}
                                                                onChange={onChangeDate}
                                                                showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={2}>
                                                        {fields.length > 1 ? (
                                                            <Form.Item>
                                                                <MinusCircleOutlined className='borrarInsumo' onClick={() => remove(field.name)} />
                                                            </Form.Item>
                                                        ) : false}
                                                    </Col>
                                                    </Row>
                                                ))}
                                               
                                                <Col span={23}>
                                                    <Form.Item>
                                                       <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>Agregar Ruta</Button>
                                                     </Form.Item>
                                                </Col>
                                             
                                              
                                            </>
                                        )}
                                    </Form.List>

                                   <Col span={23}>
                                      <Button className="btnFiltrar" style={{ width: '100%' }} disabled={this.state.btnRegistrar} htmlType="submit">Registrar</Button>
                                   </Col>                         
                               

                            </Form>
                        ) : false}
                    </Spin>
                    <ModalContraseña/>
                </Modal>
               
            </div>
         );
    }
}

const mapStateToProps = (state) => {
    return {
        fetchingCambioEstadoSeguimiento: state.SolicitudDeServiciosReducer.fetchingCambioEstadoSeguimiento,
        folioSolicitudServicioSeleccionado: state.SolicitudDeServiciosReducer.folioSolicitudServicioSeleccionado,
        listaTrazabilidad: state.SolicitudDeServiciosReducer.listaTrazabilidad,
        page: state.SolicitudDeServiciosReducer.page,
        busqueda: state.SolicitudDeServiciosReducer.busqueda,
        isEspecial: state.SolicitudDeServiciosReducer.isEspecial,
        ubicacionesSinHijos: state.SolicitudDeServiciosReducer.ubicacionesSinHijos,
        fetchingUbicacionesSinHijos: state.SolicitudDeServiciosReducer.fetchingUbicacionesSinHijos,

        folioSeleccionadoTraslado: state.SolicitudDeServiciosReducer.folioSeleccionadoTraslado,
        showModalTraslado: state.SolicitudDeServiciosReducer.showModalTraslado,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onActualizarSeguimiento: (formulario, folioSeleccionadoTraslado, page, busqueda) => {
                dispatch({ type: "TRASLADO_FOLIO_SS_REQUEST", formulario, folioSeleccionadoTraslado , page, busqueda});
        },
        onShowModalContraseña: (showModalContraseña, isEspecial) => {
            dispatch({ type: "SHOW_MODAL_CONTRASEÑA", showModalContraseña , isEspecial})
        },
        onUbicacionesSinHijosFiltro: (busqueda) => {
            dispatch({ type: 'UBICACIONES_SIN_HIJOS_REQUEST', busqueda });
        },
        onUbicacionesSinHijosFiltroLimpiar: () => {
            dispatch({ type: 'UBICACIONES_SIN_HIJOS_SUCCESS', ubicacionesSinHijos: [] });
        }, 
        onCerrarModal: () => {
            dispatch({ type: "SHOW_MODAL_TRASLADO", showModalTraslado: false, folioSeleccionadoTraslado: null });
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(TrasladoForm);