import React, { Component } from 'react';
import { Form, Input, Button, Row, Col, Spin, Modal} from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';

const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalEditarEstatusActivo extends Component {
    formRef = React.createRef();


    render() {

        const { fetchingEditarEstatusActivo, showModalEditarEstatusActivo, onEditarEstatus, onShowModalEditarEstatus,
            EstatusActivoSeleccionado, page, limpiarFormulario } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onEditarEstatus(formulario, EstatusActivoSeleccionado, page);

        };
        if (limpiarFormulario && limpiarFormulario === 'editarEstatus') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        

        const handleCancel = () => {
            onShowModalEditarEstatus();
        }
        
        return (
            <Modal
                visible={showModalEditarEstatusActivo}
                title='Editar Estatus del Activo'
                onCancel={handleCancel}
                footer={false}>
                <Spin spinning={fetchingEditarEstatusActivo}>
                    {EstatusActivoSeleccionado ? (
                        <Form {...layout}
                            name="formulario"
                            initialValues={EstatusActivoSeleccionado}
                            onFinish={onFinish}
                            
                            ref={this.formRef}
                        >
                            <Form.Item
                                label="Descripción corta"
                                name="descripcionC"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                <Input
                                    pattern="^[ñíóáéú a-zA-Z0-9 ]+$"
                                    title="Descripción sin carácteres especiales"
                                    maxLength={100}
                                    placeholder='Descripción corta' />
                            </Form.Item>

                            <Form.Item
                                label="Descripción larga"
                                name="descripcionL"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                <TextArea
                                    pattern="^[ñíóáéú a-zA-Z0-9 ]+$"
                                    title="Descripción sin carácteres especiales"
                                    maxLength={200}
                                    placeholder='Descripción Larga'
                                    autoSize />
                            </Form.Item>
                            <Row justify='space-around'>
                                <Col span={12}>
                                    <Button style={{width:'100%'}} className="btnFiltrar" htmlType="submit">Editar Estatus del Activo</Button>
                                </Col>
                            </Row>
                        </Form>
                    ) : false}
                </Spin>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingEditarEstatusActivo: state.AlmacenesReducer.fetchingEditarEstatusActivo,
        showModalEditarEstatusActivo: state.AlmacenesReducer.showModalEditarEstatusActivo,
        EstatusActivoSeleccionado: state.AlmacenesReducer.EstatusActivoSeleccionado,
        page: state.AlmacenesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarEstatus: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_ESTATUS_ACTIVO', showModalEditarEstatusActivo: false });
        },
        onEditarEstatus: (formulario, EstatusActivoSeleccionado, page) => {
            dispatch({ type: 'EDITAR_ESTATUS_ACTIVO_REQUEST', formulario: formulario, EstatusActivoSeleccionado:EstatusActivoSeleccionado,page: page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarEstatusActivo);