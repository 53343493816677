import React ,{ Component }from 'react';
import { Card, Alert, Row, Col, List } from 'antd';
import { connect } from "react-redux";
import { ShrinkOutlined, ArrowsAltOutlined, ImportOutlined } from '@ant-design/icons';
//Importar catalogos aqui
import CatalogoVariables from './Variables/CatalogoVariables';
import CatalogoServiciosVariables from './ServiciosVariables/CatalogoServiciosVariables';

import imaSearch from '../../assets/undraw_searching_re_3ra9.svg';
import ReactVivus from "react-vivus";

function isEmpty(obj) {
     for(var prop in obj) {
          if(obj.hasOwnProperty(prop))
               return false;
     }
     return true;
}

class CatalogoServiciosVariablesContainer extends Component {
     state={
          catalogo: 'Selecciona un Catálogo',
          colLista: 8,
          colTabla: 15,
          maximizar: false
     }
     render() { 

          const { onChangeCatalogo, catalogoServiciosVariables } = this.props;

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
              permisosUsuario = JSON.parse(permisosUsuario);
          }

          const listaCatalogos = [];
          if(permisosUsuario.buscarVariable){
              listaCatalogos.push({
                  'catalogo': 'Catálogo de Variables',
              })
          }
          if(permisosUsuario.buscarServicioVariable){
              listaCatalogos.push({
                  'catalogo': 'Catálogo de Servicios Variables',
              })
          }

          function ShowInfo(props) {
          if (isEmpty(catalogoServiciosVariables)) {
               return (
                    <Row justify='space-around' align='middle'>
                        <Col span={24}>
                            <Alert message="Favor de seleccionar un catálogo" type="info" />
                        </Col>
                        <Col span={10} style={{paddingTop:'8em', paddingBottom:'8em'}}>
                            {/* <img src={imaSearch} /> */}
                            <ReactVivus
                                id="imaSearch"
                                option={{
                                    file: imaSearch,
                                    animTimingFunction: "EASE",
                                    type: "delayed",
                                }}
                            />
                        </Col>
                    </Row>);
          }
          
          return (
               <div>
                    <CatalogoApp/>
               </div>
          );
          }

          function CatalogoApp() {
          if(catalogoServiciosVariables === 'Catálogo de Variables'){
               return(
                    <CatalogoVariables/>
               );
          }
          else if(catalogoServiciosVariables === 'Catálogo de Servicios Variables'){
               return(
                    <CatalogoServiciosVariables/>
               );
          }
               return <div>  </div>;
          };

          const changeCatalogo = (catalogo) => {
          this.setState({
               catalogo: catalogo.catalogo,
          })
          onChangeCatalogo(catalogo.catalogo);
     }
     const onExpandir = () => {
          if(!this.state.maximizar){
               this.setState({
                    colLista: 0,
                    colTabla: 24,
                    maximizar: true
               });
          }
          else{
               this.setState({
                    colLista: 8,
                    colTabla: 15,
                    maximizar: false,
               });
          }
     }
          return ( 
               <div className="imgCorp">
                    <Row justify='space-between'>
                         <Col span={this.state.colLista}>
                              <List
                                   header={
                                        <div className='headerLista'>Catálogos Servicios Variables</div>
                                   }
                                   className="listaCatalogos"
                                   bordered
                                   dataSource={listaCatalogos}
                                   renderItem={item => (
                                   <List.Item className='listaHover'
                                        actions={[<ImportOutlined onClick={(e) => { e.stopPropagation(); changeCatalogo(item); } } className="iconAbrir" />]}
                                   >
                                        <div>{item.catalogo}</div>
                                   </List.Item>
                                   )}
                              />                    
                         </Col>

                         <Col span={this.state.colTabla}>
                              <Card className="cardCatalogos" title={<div className='headerLista'>{this.state.catalogo}</div>} extra={this.state.maximizar ? (<ShrinkOutlined className='iconoMaxMin' onClick={onExpandir} />):(<ArrowsAltOutlined className='iconoMaxMin' onClick={onExpandir}/>)}>
                                   <ShowInfo info={catalogoServiciosVariables} />
                              </Card> 
                         </Col>
                    </Row>
               </div>
          );
     }
}
 
const mapStateToProps = state => {
     return {
          vistaDashboard: state.ConfiguracionYSeguridadReducer.vistaDashboard,
          catalogoServiciosVariables: state.ServiciosVariablesReducer.catalogoServiciosVariables,
     };
};
const mapDispatchToProps = dispatch => {
	return {
          onChangeCatalogo: (catalogoServiciosVariables) => {
               dispatch({ type: "CAMBIAR_CATALOGO_SERVICIOS_VARIABLES" , catalogoServiciosVariables});
          },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoServiciosVariablesContainer);
