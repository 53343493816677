import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css'
import ModalNuevoEstandarDesempenoEspecifico from './ModalNuevoEstandarDesempeñoEspecifico';
import ModalEditarEstandarDesempenoEspecifico from './ModalEditarEstandarDesempeñoEspecifico';

const confirm = Modal.confirm;
const { Search } = Input;

class CatalogoEstandaresDesempenoEspecifico extends React.Component {
    state = {
        page: 1,
        busqueda: '',
    }

    componentDidMount() {
        this.props.onGetEstandaresDesempenoEspecificos(1);
        this.props.onGetListaServicios();
    }

    render() {
        const { fetchingListaEstandaresDesempenoEspecificosBusqueda, listaEstandaresDesempenoEspecificosBusqueda, onGetEstandaresDesempenoEspecificos,
            onModalNuevoEstandarDesempenoEspecifico, onModalEditarEstandarDesempenoEspecifico, onEstadoEstandaresDesempenoEspecificos, listaEstandaresDesempenoEspecificosBusquedaCantidad } = this.props;
                
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columns = [{
            title: 'Sección',
            dataIndex: 'descripcion',
            key: 'descripcion',
        }, {
            title: 'Servicio',
            dataIndex: 'nombreServicio',
            key: 'nombreServicio',
        }, {
            title: 'Activo',
            dataIndex: 'activo',
            key: 'activo',
            render: (text, record) => (
                <Switch checked={text} disabled={!permisosUsuario.secciónDeEstándaresEspecíficosActivoinactivo} onChange = {() => handleDesactivar(record)} />
            ),
        }, {
            title: 'Editar',
            key: 'editar',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.editarSecciónDeEstándaresEspecificos}  onClick={() => onEditar(record)}><EditOutlined /></a>
            ),
        },
        ];

        const onBuscarEstandaresDesempenoEspecificos = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            });
            console.log(busqueda);
            onGetEstandaresDesempenoEspecificos(1, busqueda);
        } 

        const handleDesactivar = (key) => {
            console.log(key.idServSeccionEspecifico);
            let page = this.state.page
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado de la sección de estándares específico "' + key.descripcion + '"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoEstandaresDesempenoEspecificos(key.idServSeccionEspecifico, page);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }

        const onEditar = (key) => {
            onModalEditarEstandarDesempenoEspecifico(key, this.state.page);

        }

        const onNuevoEstandarDesempenoEspecifico = () => {
            console.log("Modal");
            onModalNuevoEstandarDesempenoEspecifico(this.state.page);
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            onGetEstandaresDesempenoEspecificos(pagination.current, this.state.busqueda);
        }


        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search className='buscarModulos' placeholder='Ingresar sección de estándares específicos.' onSearch={value => onBuscarEstandaresDesempenoEspecificos(value)} />
                    </Col>
                    {/* <Col span={10}>
                        <Button onClick={onNuevoEstandarDesempenoEspecifico} disabled={!permisosUsuario.crearSecciónDeEstándaresEspecificos} className='nuevoUsuario'>Nueva Sección de Estándares Específicos</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nueva Sección de Estándares Específicos' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoEstandarDesempenoEspecifico} disabled={!permisosUsuario.crearSecciónDeEstándaresEspecificos} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table
                            size="small"
                            rowKey="descripcion"
                            columns={columns}
                            dataSource={listaEstandaresDesempenoEspecificosBusqueda}
                            loading={fetchingListaEstandaresDesempenoEspecificosBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                        // scroll={scroll}
                            onChange={handleTableChange}
                            pagination={{ total: listaEstandaresDesempenoEspecificosBusquedaCantidad, simple: true, current: this.state.page }}
                        />
                    </Col>
                </Row>
                <ModalNuevoEstandarDesempenoEspecifico/>
                <ModalEditarEstandarDesempenoEspecifico/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        listaEstandaresDesempenoEspecificosBusqueda: state.MecanismosDePagosReducer.listaEstandaresDesempenoEspecificosBusqueda,
        fetchingListaEstandaresDesempenoEspecificosBusqueda: state.MecanismosDePagosReducer.fetchingListaEstandaresDesempenoEspecificosBusqueda,
        listaEstandaresDesempenoEspecificosBusquedaCantidad: state.MecanismosDePagosReducer.listaEstandaresDesempenoEspecificosBusquedaCantidad,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetEstandaresDesempenoEspecificos: (page, busqueda) => {
            dispatch({ type: "ESTANDARES_DESEMPENO_ESPECIFICOS_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
        },
        onEstadoEstandaresDesempenoEspecificos: (idServSeccionEspecifico, page) => {
            dispatch({ type: "ESTADO_ESTANDAR_DESEMPENO_ESPECIFICO_REQUEST", idServSeccionEspecifico: idServSeccionEspecifico, page: page});
        },
        onModalNuevoEstandarDesempenoEspecifico: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_ESTANDAR_DESEMPENO_ESPECIFICO", showModalNuevoEstandarDesempenoEspecifico: true, page: page })
        },
        onModalEditarEstandarDesempenoEspecifico: (EstandarDesempenoEspecificoSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_ESTANDAR_DESEMPENO_ESPECIFICO", showModalEditarEstandarDesempenoEspecifico: true, EstandarDesempenoEspecificoSeleccionado:EstandarDesempenoEspecificoSeleccionado, page:page});
        },
        onGetListaServicios: () => {
            dispatch({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST' });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoEstandaresDesempenoEspecifico);