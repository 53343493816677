import React from 'react';
import { Form, Row, Col, Modal, Input, Spin, Select, Button, Divider, DatePicker, InputNumber, message, Alert, Tooltip,Collapse, Switch } from 'antd';
import { connect } from "react-redux";
import { CaretRightOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import moment from 'moment';

const { Option } = Select;
const { Panel } = Collapse;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
};

class EditarCapturaConsumo extends React.Component {
     formRef = React.createRef();

     state = {
          page: 1,
          ubicacionDepo: 'Ubicación',
          boton: false,
          boolean: true,
          admin: false,
          messageAlert: (<p>Favor de seleccionar un horario para proceder con la captura de éste servicio público.
               </p>),
          typeAlert: 'info',
     }

     render() {
          const { 
               page,
               busqueda,
               onCloseModal,
               listaDepositosByIdServiciosPublicos,
               programacionByServicio,
               onVariableByProgramacion,
               onLimpiarVariableByProgramacion,
               variableMedicionByProgramacion,
               fetchingVariableMedicionByProgramacion,
               onLimpiarProgramacionByServicio,
               onRegistroLectura,
               onUnidadByCategoria,
               fetchingUnidadesMediPorCategoria,
               unidadesMediPorCategoria,
               onLimpiarDepositoByidServicioPublico,
               listaInitialValueDepos
          } = this.props;

          let hoy = new Date().toLocaleDateString();

          const handleCancel = () => {
               onCloseModal();
               this.setState({ boton: false, boolean: true, admin: false, messageAlert: (<p>Favor de seleccionar un horario para proceder con la captura de éste servicio público.
                    </p>),
               typeAlert: 'info',});
               onLimpiarProgramacionByServicio();
               onLimpiarVariableByProgramacion();
               onLimpiarDepositoByidServicioPublico();
               this.formRef.current.resetFields();
          };

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
               permisosUsuario = JSON.parse(permisosUsuario);
          }

          const onFinish = formulario => {
               let fecha = new Date().toLocaleDateString();
               let listaCaptura = [];
               let listaCapturaConjunta = listaDepositosByIdServiciosPublicos;
               formulario.idProgramacionHorario = this.props.idProgramacionHorario;

               console.log('Success:', formulario);

               for (var key in formulario) {
                    if (Object.prototype.hasOwnProperty.call(formulario, key)) {
                         let idCampo = key.split('-');
                         if (idCampo.length === 2) {
                              let idCaptura = idCampo[1];
                              let campo = idCampo[0];
                              let valor = formulario[key];
          
                              let capturaFormulario = {[campo]: valor, 'idCaptura': parseInt(idCaptura)};
                              listaCaptura.push(capturaFormulario);
                         }
                    }
               }

                    for(let i = 0; i < listaDepositosByIdServiciosPublicos.length; i++){
                         for(let j = 0; j < listaCaptura.length; j++){
                              if(listaDepositosByIdServiciosPublicos[i].idAsignacionServicioPublico === listaCaptura[j].idCaptura && listaCaptura[j].lecturaRegistrada){
                                   listaCapturaConjunta[i].lecturaRegistrada = listaCaptura[j].lecturaRegistrada
                              }
                              if(listaDepositosByIdServiciosPublicos[i].idAsignacionServicioPublico === listaCaptura[j].idCaptura && listaCaptura[j].nombre){
                                   listaCapturaConjunta[i].nombre = listaCaptura[j].nombre
                              }
                              if(listaDepositosByIdServiciosPublicos[i].idAsignacionServicioPublico === listaCaptura[j].idCaptura && listaCaptura[j].unidadMedida){
                                   listaCapturaConjunta[i].unidadMedida = listaCaptura[j].unidadMedida
                              }
                         }
                    }

                    console.log('listaCapturaConjunta', listaCapturaConjunta)

               onRegistroLectura(formulario, listaCapturaConjunta, page, busqueda);

               this.setState({ boton: false, boolean: true, admin: false, messageAlert: (<p>Favor de seleccionar un horario para proceder con la captura de éste servicio público.
                    </p>),
               typeAlert: 'info',});
               onLimpiarProgramacionByServicio();
               onLimpiarVariableByProgramacion();
               this.formRef.current.resetFields();
               onLimpiarDepositoByidServicioPublico();
          };

          const onFinishFailed = errorInfo => {
               console.log('Failed:', errorInfo);
          };

          if(programacionByServicio.length > 0 && this.state.boolean === true){
               onVariableByProgramacion(programacionByServicio[0].idProgramacionConsumo);
               this.setState({ boolean: false });
          }

          const onVariable = (value, idAsignacionServicioPublico) => {
               for(let i = 0; i < variableMedicionByProgramacion.length; i++){
                    if(variableMedicionByProgramacion[i].idVariableMedicion === parseInt(value)){
                         onUnidadByCategoria(variableMedicionByProgramacion[i].idCategoriaUnidadMedida);
                    }
               }

               this.formRef.current.resetFields(['unidadMedida-' + idAsignacionServicioPublico]);
          };

          return (
               <>
                    { listaInitialValueDepos ? (
                              <Form {...layout}
                                   name="formulario"
                                   initialValues={listaInitialValueDepos}
                                   onFinish={onFinish}
                                   
                                   ref={this.formRef}
                              >
                                   <Row>
                                             {console.log('Entra a edición', listaDepositosByIdServiciosPublicos)}
                                             {/* ////////////////////////////////////////////////////////////////// */}
                                             {listaDepositosByIdServiciosPublicos.map(item =>
                                                  <Row justify='space-around' key={item.idAsignacionServicioPublico}>
                                                       <Col span={10}>
                                                            <Form.Item
                                                                 name={'ubicacion-' + item.idAsignacionServicioPublico}
                                                                 label={<strong>Ubicación </strong>}
                                                            >
                                                                 <p>{item.claveUbicacion + ': '}<i>{item.nombreUbicacion}</i></p>
                                                            </Form.Item>
                                                       </Col>
                                                       <Col span={3}>
                                                            <Form.Item
                                                                 //name={`lecturaRegistrada-${item.idAsignacionServicioPublico}`}
                                                                 name={'lecturaRegistrada-' + item.idAsignacionServicioPublico}
                                                                 label='Lectura'
                                                                 rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                                            >
                                                                 <InputNumber
                                                                      placeholder='Lectura'
                                                                      min={0}
                                                                 />
                                                            </Form.Item>
                                                       </Col>
                                                       <Col span={5}>
                                                            <Form.Item
                                                                 name={`nombre-${item.idAsignacionServicioPublico}`}
                                                                 label='Variable de Medición'
                                                                 rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                                            >
                                                                 <Select
                                                                      allowClear
                                                                      style={{ width: '100%' }}
                                                                      placeholder="Seleccione una variable de medicion"
                                                                      loading={fetchingVariableMedicionByProgramacion}
                                                                      onChange={(value) => onVariable(value, item.idAsignacionServicioPublico)}
                                                                 >
                                                                      {variableMedicionByProgramacion.map(option => <Option key={option.idVariableMedicion}>{ option.nombre}</Option>)}
                                                                 </Select> 
                                                            </Form.Item>
                                                       </Col>

                                                       <Col span={5}>
                                                            <Form.Item
                                                                 name={`unidadMedida-${item.idAsignacionServicioPublico}`}
                                                                 label='Unidad de Medida'
                                                                 rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                                            >
                                                                 <Select
                                                                      allowClear
                                                                      style={{ width: '100%' }}
                                                                      placeholder="Seleccione una unidad de medida"
                                                                      loading={fetchingUnidadesMediPorCategoria}
                                                                 >
                                                                      {unidadesMediPorCategoria.map(option => <Option key={option.idUnidadMedida}>{ option.unidadMedida}</Option>)}
                                                                 </Select>    
                                                            </Form.Item>
                                                       </Col>
                                                  </Row>
                                             )}

                                        <Divider style={{margin: '1px'}}/>

                                        <Col span={24}>
                                             <br/> <br/>
                                             <Button className='btnPrueba' style={{ width: '100%' }} htmlType='submit' >
                                                  Guardar
                                             </Button>
                                        </Col>

                                   </Row>  
                              </Form> 
                    ): false }        
               </>
          );             
     }
}

const mapStateToProps = state => {
     return {
          page: state.ServiciosPublicosReducer.page,
          busqueda: state.ServiciosPublicosReducer.busqueda,
          fetchingCapturarLectura: state.ServiciosPublicosReducer.fetchingCapturarLectura,
          showModalCapturaConsumos: state.ServiciosPublicosReducer.showModalCapturaConsumos,
          servicioPublicoSeleccionado: state.ServiciosPublicosReducer.servicioPublicoSeleccionado,
          fetchingListaDepositosByIdServiciosPublicos: state.ServiciosPublicosReducer.fetchingListaDepositosByIdServiciosPublicos,
          listaDepositosByIdServiciosPublicos: state.ServiciosPublicosReducer.listaDepositosByIdServiciosPublicos,

          fetchingProgramacionByServicio: state.ServiciosPublicosReducer.fetchingProgramacionByServicio,
          programacionByServicio: state.ServiciosPublicosReducer.programacionByServicio,
          programacionHorariosByIdProgramacionConsumo: state.ServiciosPublicosReducer.programacionHorariosByIdProgramacionConsumo,
          variableMedicionByProgramacion: state.ServiciosPublicosReducer.variableMedicionByProgramacion,
          fetchingVariableMedicionByProgramacion: state.ServiciosPublicosReducer.fetchingVariableMedicionByProgramacion,
          horariosByServicio: state.ServiciosPublicosReducer.horariosByServicio,
          fetchingHorariosByServicio: state.ServiciosPublicosReducer.fetchingHorariosByServicio,
          horariosByServicioAdmin: state.ServiciosPublicosReducer.horariosByServicioAdmin,
          fetchingHorariosByServicioAdmin: state.ServiciosPublicosReducer.fetchingHorariosByServicioAdmin,

          unidadesMediPorCategoria: state.AlmacenesReducer.unidadesMediPorCategoria,
          fetchingUnidadesMediPorCategoria: state.AlmacenesReducer.fetchingUnidadesMediPorCategoria,

          listaInitialValueDepos: state.ServiciosPublicosReducer.listaInitialValueDepos,
          idProgramacionHorario: state.ServiciosPublicosReducer.idProgramacionHorario
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onCloseModal: () => {
               dispatch({ type: 'SHOW_MODAL_CAPTURA_CONSUMOS', showModalCapturaConsumos: false });
          },
          onVariableByProgramacion: (idProgramacionConsumo) => {
               dispatch({ type: 'VARIABLES_POR_PROGRAMACION_REQUEST', idProgramacionConsumo });
          },
          onLimpiarVariableByProgramacion: () => {
               dispatch({ type: 'VARIABLES_POR_PROGRAMACION_SUCCESS', variableMedicionByProgramacion: [] });
          },
          onLimpiarProgramacionByServicio: () => {
               dispatch({ type: 'PROGRAMACION_POR_SERVICIO_SUCCESS', programacionByServicio: [], programacionHorariosByIdProgramacionConsumo: [] });
          },
          onRegistroLectura: (formulario, listaDepositosByIdServiciosPublicos, page, busqueda) => {
               dispatch({ type: 'REGISTRO_LECTURA_REQUEST', formulario, listaDepositosByIdServiciosPublicos, page, busqueda});
          },
          onUnidadByCategoria: (idCategoriaUnidadMedida) => {
               dispatch({ type: 'UNIDADES_MEDI_POR_CATEGORIA_REQUEST', idCategoriaUnidadMedida });
          },
          onDepositoByidServicioPublico: (idServicioPublico, idProgramacionHorario) => {
               dispatch({ type: 'LISTA_DEPOSITO_BY_SERVICIO_REQUEST', idServicioPublico, idProgramacionHorario});
          },
          onLimpiarDepositoByidServicioPublico: () => {
               dispatch({ type: 'LISTA_DEPOSITO_BY_SERVICIO_SUCCESS', listaDepositosByIdServiciosPublicos: []});
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditarCapturaConsumo);