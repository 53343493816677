import React from 'react';
import {connect} from 'react-redux';
import {Form, Row, Col, Button, Modal, Spin, Input, Divider, Checkbox, Select, DatePicker, Switch, message, Tooltip, TimePicker, Radio} from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import moment from 'moment';

const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalEditarRegistro extends React.Component {
     state={   boolGrupo: true, boolClasificacion: true, }
     formRef = React.createRef();
     render() {
          const {showModalEditarRegistro, fetchingEditRegistros, page, onActualizarRegistro,
               onShowModalRegistroReporte, onEditarRegistroReporte, registroReporteSeleccionado, personal, listaInfoProyecto,

          } = this.props;
          
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
               permisosUsuario = JSON.parse(permisosUsuario);
          }

          let userInfo = sessionStorage.getItem('userInfo');
          if(userInfo[0]) {
               userInfo = JSON.parse(userInfo);
          }

          const onFinish = (formulario) => {
               onEditarRegistroReporte(formulario, page, personal, registroReporteSeleccionado);
               this.setState({boolGrupo: true, boolClasificacion: true,});    
          }

          const handleCancel = () => {
               onShowModalRegistroReporte(page, personal);
               this.formRef.current.resetFields();
               this.setState({boolGrupo: true, boolClasificacion: true,});
          }

          const selectAll = (value) => {
               const registroReporteLocal = registroReporteSeleccionado;
               if(value.target.checked){
                    onActualizarRegistro(null, page, personal)
                    for(let i in registroReporteLocal.checkboxData){
                         registroReporteLocal.checkboxData[i][1] = true;
                    }

                    registroReporteLocal.tipoBitacoraC = true;
                    registroReporteLocal.descripcionC =true;
                    registroReporteLocal.nombreC=true; 
                    registroReporteLocal.moduloC=true;
                    registroReporteLocal.servicioC=true; 
                    registroReporteLocal.partidaC=true; 
                    registroReporteLocal.asuntoC=true; 
                    registroReporteLocal.observacionesC=true; 
                    registroReporteLocal.fechaC=true; 
                    registroReporteLocal.horaC=true; 
                    registroReporteLocal.accionC=true; 
                    registroReporteLocal.responsableSerC=true;
                    registroReporteLocal.responsableRegC=true; 
                    registroReporteLocal.conclusionesC=true
                    
                    registroReporteLocal.checkAll = true;
                    onActualizarRegistro(registroReporteLocal, page, personal)
               }
               else {
                    onActualizarRegistro(null, page, personal)
                    for(let i in registroReporteLocal.checkboxData){
                         registroReporteLocal.checkboxData[i][1] = false;
                    }
                    
                    registroReporteLocal.tipoBitacoraC = false;
                    registroReporteLocal.descripcionC =false;
                    registroReporteLocal.nombreC=false; 
                    registroReporteLocal.moduloC=false;
                    registroReporteLocal.servicioC=false; 
                    registroReporteLocal.partidaC=false; 
                    registroReporteLocal.asuntoC=false; 
                    registroReporteLocal.observacionesC=false; 
                    registroReporteLocal.fechaC=false; 
                    registroReporteLocal.horaC=false; 
                    registroReporteLocal.accionC=false; 
                    registroReporteLocal.responsableSerC=false; 
                    registroReporteLocal.responsableRegC=false; 
                    registroReporteLocal.conclusionesC=false;

                   registroReporteLocal.checkAll = false;
                   this.formRef.current.setFieldsValue({'field': undefined});
                   onActualizarRegistro(registroReporteLocal, page, personal)
               }
               console.log('registroReporteLocal', registroReporteLocal)
          };

          const changeColumna = (value, columna) => {
               const registroReporteLocal = registroReporteSeleccionado;
               onActualizarRegistro(null, page, personal);
               let checkAll = true;
               registroReporteLocal[columna] = value.target.checked;
               for(let i in registroReporteLocal.listaFields){
                    if(registroReporteLocal.listaFields[i].key === columna){
                         if(registroReporteLocal.listaFields[i].campo === registroReporteLocal.field){
                         this.formRef.current.setFieldsValue({'field': undefined});
                         }
                         registroReporteLocal.listaFields[i].mostrar = value.target.checked;
                    }
               }
               for(let i in registroReporteLocal.checkboxData){
                    if(registroReporteLocal.checkboxData[i][0] === columna){
                         registroReporteLocal.checkboxData[i][1] = value.target.checked;
                    }
               }
               for(let i in registroReporteLocal.checkboxData){
                    if(!registroReporteLocal.checkboxData[i][1]){
                         checkAll = false;
                    }
               }
               registroReporteLocal.checkAll = checkAll;

               console.log('registroReporteLocal', registroReporteLocal)
               onActualizarRegistro(registroReporteLocal, page, personal);
          };

          const servicioSeleccionado = (value) => {
               if(value.length > 0){
                    this.props.onGetPartidasByIdsServicios(value);
                    this.formRef.current.setFieldsValue({'idsPartidas': undefined});
               } else {
                    this.formRef.current.setFieldsValue({'idsPartidas': undefined});
                    this.props.onClearPartidasByIdsServicios();
               }
          };
     
          const  disabledDate = (fecha) => {
               if( fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicioOperacion) {
                   return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicioOperacion;
               } else {
                   if(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos){
                return fecha && moment(fecha.format('YYYY-MM-DD')) > moment((listaInfoProyecto.vigencia +  parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2])) + '-' +listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[1] + '-' + listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[0]);
            }
               }
          };
     
          const modulosSeleccionados = (record) => {
               if(record.length > 0){
                    this.props.onGetListaTipoBitacoraByIdsModulos(record);
                    this.formRef.current.setFieldsValue({'idsTiposBitacoras': undefined});
               } else {
                    this.props.onCleanListaTipoBitacoraByIdsModulos();
                    this.formRef.current.setFieldsValue({'idsTiposBitacoras': undefined});
               }
          };
          
          return (
               <div>
                    <Modal
                         visible={showModalEditarRegistro}
                         title='Editar Registro Predeterminado'
                         onCancel={handleCancel}
                         footer={false}
                         width='75%'
                    >
                         {registroReporteSeleccionado ? (
                         <Spin spinning={fetchingEditRegistros}>
                         <Form {...layout}
                              name='formulario'
                              onFinish={onFinish}
                              ref={this.formRef}
                              initialValues={registroReporteSeleccionado}
                         >
                              <Row type='flex' justify='space-around' gutter={[8,8]}>
                                   <Col span={12}>
                                        <Form.Item
                                             label='Título del documento'
                                             name='titulo'
                                             rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                             >
                                             <Input maxLength={200} placeholder='Ingresa el título del documento' style={{ width: '100%' }} />
                                        </Form.Item>
                                   </Col>
                                   <Col span={12}>
                                        <Form.Item
                                             label='Nombre'
                                             name='nombre'
                                             rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                             >
                                             <Input maxLength={100} placeholder='Ingresa el nombre' style={{ width: '100%' }} />
                                        </Form.Item>
                                   </Col>
                                   
                                   <Col span={24}>
                                        <Form.Item
                                             label='Descripción'
                                             name='descripcion'
                                             >
                                             <TextArea autoSize maxLength={450} placeholder='Ingresa la descripción' style={{ width: '100%' }} />
                                        </Form.Item>
                                   </Col>

                                   <Divider orientation='left'>Columnas</Divider>
                                   <Row style={{ width: '100%', textAlign: 'left', marginBottom: '2em' }}>
                                        <Col span={24}>
                                             <Form.Item name='checkboxColumns'>
                                                  <Checkbox onChange={selectAll} checked={registroReporteSeleccionado.checkAll}><strong>Todas</strong></Checkbox>
                                             </Form.Item>
                                        </Col>
                                   </Row>

                                   { registroReporteSeleccionado.checkboxData ? (
                                   <Row style={{ width: '100%', textAlign: 'left' }}>
                                        {registroReporteSeleccionado.checkboxData.map( item => 
                                        <Col key={item[0]} span={6}>
                                             <Form.Item
                                             name={item[0]}
                                             >
                                             <Checkbox checked={item[1]} onChange={e => changeColumna(e, item[0])}>{item[2]}</Checkbox>
                                             </Form.Item>
                                        </Col>
                                        )}
                                   </Row>
                                   ) : false }

                                   <Divider orientation='left'>Filtros</Divider>
                                   
                                   <Col span={24}>
                                        <Row justify='space-between' gutter={[8,8]} style={{padding:'1em'}}>
                                             {/* {registroReporteSeleccionado.moduloC ? ( */}
                                             <Col span={12}>
                                                  <Form.Item
                                                       label="Módulo"
                                                       name="idsModulos"
                                                       rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                                  >
                                                       <Select
                                                            allowClear
                                                            style={{ width: '100%' }}
                                                            placeholder="Selecciona el módulo de sistema"
                                                            loading={this.props.fetchingListaModulosByIdUsuario}
                                                            notFoundContent={null}
                                                            mode="multiple"
                                                            onChange={modulosSeleccionados}
                                                       >
                                                            {this.props.listaModulosByIdUsuario.map(option => <Option key={option.idModulo + ' - ' + option.nombre}>{option.idModulo + ' - ' + option.nombre}</Option>)}
                                                       </Select>
                                                  </Form.Item>
                                             </Col>
                                             {/* ) : false} */}

                                             {/* {registroReporteSeleccionado.tipoBitacoraC ? ( */}
                                             <Col span={12}>
                                                  <Form.Item
                                                       label="Tipo de bitácora"
                                                       name="idsTiposBitacoras"
                                                       rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                                  >
                                                       <Select allowClear
                                                            style={{ width: '100%' }}
                                                            placeholder="Selecciona un tipo de bitácora"
                                                            loading={this.props.fetchingListaTipoBitacoraByIdModulo}
                                                            showSearch={true}
                                                            mode="multiple"
                                                       >
                                                            {this.props.listaTipoBitacoraByIdModulo.map(option => 
                                                            <Option key={option.idTipoBitacora + ' - ' + option.tipoBitacora}>
                                                                 <Tooltip placement="topLeft" title={option.idTipoBitacora + ' - ' + option.tipoBitacora}>
                                                                      {option.idTipoBitacora + ' - ' + option.tipoBitacora}
                                                                 </Tooltip>
                                                            </Option>)}
                                                       </Select>
                                                  </Form.Item>
                                             </Col>
                                             {/* ):false} */}

                                             { registroReporteSeleccionado.servicioC ? (
                                                  <Col span={12}>
                                                       <Form.Item
                                                            label="Servicio"
                                                            name="idsServicios"
                                                            rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                                       >
                                                            <Select allowClear
                                                                 style={{ width: '100%' }}
                                                                 placeholder="Selecciona un servicio a filtrar"
                                                                 loading={this.props.fetchingListaServiciosActivosF}
                                                                 onChange={servicioSeleccionado}
                                                                 showSearch={true}
                                                                 mode="multiple"
                                                            >
                                                                 {this.props.listaServiciosActivosF.map(option => 
                                                                 <Option key={option.idServicio + ' - ' + option.nombre}>
                                                                      <Tooltip placement="topLeft" title={option.idServicio + ' - ' + option.nombre}>
                                                                           {option.idServicio + ' - ' + option.nombre}
                                                                      </Tooltip>
                                                                 </Option>)}
                                                            </Select>
                                                       </Form.Item>
                                                  </Col>
                                             ): false }

                                             { registroReporteSeleccionado.partidaC ? (
                                                  <Col span={12}>
                                                       <Form.Item
                                                            label="Partida"
                                                            name="idsPartidas"
                                                            rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                                       >
                                                            <Select 
                                                                 allowClear 
                                                                 style={{width: '100%'}}
                                                                 placeholder="Selecciona una partida"
                                                                 loading={this.props.fetchingListaPartidasByIdsServicios}
                                                                 showSearch
                                                                 mode="multiple"
                                                            >
                                                                 {this.props.listaPartidasByIdsServicios.map(option => 
                                                                      <Option key={option.idPartida + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                                           <Tooltip title={option.nomenclatura + ': ' + option.nombre}>
                                                                                {option.idPartida + ' - ' + option.nomenclatura + ': ' + option.nombre}
                                                                           </Tooltip>
                                                                      </Option>)}
                                                            </Select>
                                                       </Form.Item>
                                                  </Col>
                                             ): false }

                                             { registroReporteSeleccionado.nombreC ? (
                                                  <Col span={12}>
                                                       <Form.Item
                                                            label="Nombre Módulo de Bitácora"
                                                            name="nombreModulo"
                                                       >
                                                            <Input maxLength={50} placeholder='Escribe el nombre del módulo de bitácora'/>
                                                       </Form.Item>
                                                  </Col>
                                             ): false }

                                             { registroReporteSeleccionado.responsableSerC ? (
                                                  <Col span={12}>
                                                       <Form.Item
                                                            label="Responsable de Servicio"
                                                            name="responsableSer"
                                                       >
                                                            <Input maxLength={50} placeholder='Escribe el nombre del responsable'/>
                                                       </Form.Item>
                                                  </Col>
                                             ): false }

                                             { registroReporteSeleccionado.responsableRegC ? (
                                                  <Col span={12}>
                                                       <Form.Item
                                                            label="Responsable de Registro"
                                                            name="responsableReg"
                                                       >
                                                            <Input maxLength={50} placeholder='Escribe el nombre del responsable'/>
                                                       </Form.Item>
                                                  </Col>
                                             ): false }
                                             
                                             {registroReporteSeleccionado.fechaC ? (
                                             <Col span={12}>
                                                  <Form.Item
                                                       label="Fecha"
                                                       name="fecha"
                                                  >
                                                       <RangePicker
                                                            format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Fecha inicio", "Fecha fin"]}
                                                            disabledDate={disabledDate}
                                                       />
                                                  </Form.Item>
                                             </Col>
                                             ) : false}

                                             <Col span={12}>
                                                  <Form.Item label='Agrupamiento' name='tipoAgrupado'>
                                                       <Radio.Group defaultValue={registroReporteSeleccionado.tipoAgrupado} size="small">
                                                            { registroReporteSeleccionado.moduloC ? ( <Radio.Button value="modulo">Módulo</Radio.Button>): false }
                                                            { registroReporteSeleccionado.tipoBitacoraC ? ( <Radio.Button value="tipoBitacora">Tipo Bitácora</Radio.Button>): false }
                                                            { registroReporteSeleccionado.servicioC ? (<Radio.Button value="servicio">Servicio</Radio.Button>): false }
                                                            { registroReporteSeleccionado.partidaC ? (<Radio.Button value="partida">Partida</Radio.Button>): false }
                                                            { registroReporteSeleccionado.fechaC ? (<Radio.Button value="fecha">Fecha</Radio.Button>): false }
                                                            { registroReporteSeleccionado.responsableSerC ? (<Radio.Button value="responsableSer">Responsable de Servicio</Radio.Button>): false }
                                                            { registroReporteSeleccionado.responsableRegC ? (<Radio.Button value="responsableReg">Responsable de Registro</Radio.Button>): false }
                                                            <Radio.Button value='SinAgrupar'>Sin Agrupamiento</Radio.Button>
                                                       </Radio.Group>
                                                  </Form.Item>
                                             </Col>
                                        </Row>

                                        <Divider orientation='left'>Ordenación</Divider>
                                        <Row justify='space-around' gutter={[8,8]} align='middle'>
                                             <Col span={19}>
                                                  <Form.Item name='field'>
                                                       <Radio.Group defaultValue={registroReporteSeleccionado.field} size="small">
                                                            { registroReporteSeleccionado.tipoBitacoraC ? (<Radio.Button value="tbe.tipoBitacora">Tipo de bitácora</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.descripcionC ? (<Radio.Button value="tbe.descripcion">Descripción</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.nombreC ? (<Radio.Button value="bme.nombre">Nombre</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.moduloC ? (<Radio.Button value="me.nombre">Módulo</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.servicioC ? (<Radio.Button value="se.nombre">Servicio</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.partidaC ? (<Radio.Button value="pe.nombre">Partida</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.asuntoC ? (<Radio.Button value="be.asunto">Asunto</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.observacionesC ? (<Radio.Button value="be.observaciones">Observaciones</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.fechaC ? (<Radio.Button value="be.fecha">Fecha</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.horaC ? (<Radio.Button value="be.hora">Hora</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.accionC ? (<Radio.Button value="be.accion">Acción</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.responsableSerC ? (<Radio.Button value="be.responsableSer">Responsable de Servicio</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.responsableRegC ? (<Radio.Button value="be.responsableReg">Responsable de Registro</Radio.Button>) : false }
                                                            { registroReporteSeleccionado.conclusionesC ? (<Radio.Button value="be.conclusiones">Conclusión</Radio.Button>) : false }
                                                       </Radio.Group>
                                                  </Form.Item>
                                             </Col>
                                             <Col span={5}>
                                                  <Form.Item name='order'>
                                                       <Radio.Group defaultValue={registroReporteSeleccionado.order} size="small">
                                                            <Radio.Button value="ASC">Ascendente</Radio.Button>
                                                            <Radio.Button value="DESC">Descendente</Radio.Button>
                                                       </Radio.Group>
                                                  </Form.Item>
                                             </Col>
                                        </Row>
                                   </Col>

                                   <Col span={11} className='marginBoton'>
                                        <br/>
                                        <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Editar Registro Predeterminado</Button>
                                   </Col>
                              </Row>
                         </Form>
                         </Spin>
                         ):false}
                    </Modal>
               </div>
          )
     }
}

const mapStateToProps = state => {
     return {
          showModalEditarRegistro: state.ConfiguracionYSeguridadReducer.showModalEditarRegistro,
          fetchingEditRegistros: state.ConfiguracionYSeguridadReducer.fetchingEditRegistros,
          page: state.ConfiguracionYSeguridadReducer.page,
          personal: state.ConfiguracionYSeguridadReducer.personal,
          registroReporteSeleccionado: state.ConfiguracionYSeguridadReducer.registroReporteSeleccionado,



          listaParamProveedores: state.InformacionOperativaReducer.listaParamProveedores,
          fetchingListaParamProveedores: state.InformacionOperativaReducer.fetchingListaParamProveedores,

          listaGrupoServiciosByIdUsuario: state.RecursosHumanosReducer.listaGrupoServiciosByIdUsuario,
          fetchingListaGrupoServiciosByIdUsuario: state.RecursosHumanosReducer.fetchingListaGrupoServiciosByIdUsuario,

          fetchingListaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.fetchingListaClasificacionesActivos,
          listaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.listaClasificacionesActivos,

          listaServiciosByIdsGerencias: state.CatalogosGeneralesReducer.listaServiciosByIdsGerencias,
          fetchingListaServiciosByIdsGerencias: state.CatalogosGeneralesReducer.fetchingListaServiciosByIdsGerencias,

          fetchingListaGruposServicios: state.CatalogosGeneralesReducer.fetchingListaGruposServicios,
          listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,

          fetchingListaGerenciasByIdUsuario: state.RecursosHumanosReducer.fetchingListaGerenciasByIdUsuario,
          listaGerenciasByIdUsuario: state.RecursosHumanosReducer.listaGerenciasByIdUsuario,

          listaServiciosByIdsGrupos: state.CatalogosGeneralesReducer.listaServiciosByIdsGrupos,
          fetchingListaServiciosByIdsGrupos: state.CatalogosGeneralesReducer.fetchingListaServiciosByIdsGrupos,

          serviciosByIdUsuarioGrupos: state.CatalogosGeneralesReducer.serviciosByIdUsuarioGrupos,
          serviciosByIdUsuarioGerencia: state.CatalogosGeneralesReducer.serviciosByIdUsuarioGerencia,



          listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,

          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
          listaPartidasByIdsServicios: state.ConfiguracionYSeguridadReducer.listaPartidasByIdsServicios,
          fetchingListaPartidasByIdsServicios: state.ConfiguracionYSeguridadReducer.fetchingListaPartidasByIdsServicios,

          listaModulosByIdUsuario: state.ConfiguracionYSeguridadReducer.listaModulosByIdUsuario,
          fetchingListaModulosByIdUsuario: state.ConfiguracionYSeguridadReducer.fetchingListaModulosByIdUsuario,
          listaTipoBitacoraByIdModulo: state.ConfiguracionYSeguridadReducer.listaTipoBitacoraByIdModulo,
          fetchingListaTipoBitacoraByIdModulo: state.ConfiguracionYSeguridadReducer.fetchingListaTipoBitacoraByIdModulo,
     }
}

const mapDispatchToProps = dispatch => {
     return {
          onShowModalRegistroReporte: (page, personal) => {
               dispatch({ type: "MODAL_EDITAR_REGISTRO_BITACORA", showModalEditarRegistro: false, page, personal});
          },
          onEditarRegistroReporte: (formulario, page, personal, registroReporteSeleccionado) => {
               dispatch({ type: 'EDIT_REGISTROS_BITACORA_REQUEST', formulario, page, personal, registroReporteSeleccionado });
          },
          onActualizarRegistro: (registroReporteSeleccionado, page, personal) => {
               dispatch({ type: "MODAL_EDITAR_REGISTRO_BITACORA", showModalEditarRegistro: true, registroReporteSeleccionado, page, personal });
          },

          onGetPartidasByIdsServicios: (idsServicios) => {
               dispatch({ type: 'LISTA_PARTIDAS_BY_IDSSERVICIOS_REQUEST', idsServicios });
          },
          onClearPartidasByIdsServicios: () => {
               dispatch({ type: 'LISTA_PARTIDAS_BY_IDSSERVICIOS_SUCCESS', listaPartidasByIdsServicios: [] });
          },
          onGetListaModulosByIdUsuario: (idUsuario) => {
               dispatch({ type: 'GET_MODULOS_BY_IDUSUARIO_REQUEST', idUsuario });
          },
          onGetListaTipoBitacoraByIdsModulos: (idsModulos) => {
               dispatch({ type: 'LISTA_TIPO_BITACORAS_BY_ID_MODULO_REQUEST', idsModulos });
          },
          onCleanListaTipoBitacoraByIdsModulos: () => {
               dispatch({ type: 'LISTA_TIPO_BITACORAS_BY_ID_MODULO_SUCCESS', listaTipoBitacoraByIdModulo: [] });
          },
     }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalEditarRegistro);