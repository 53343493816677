import {takeLatest, call, put} from "redux-saga/effects";
import * as evaluacionDeLaGestionActions from '../actions/EvaluacionDeLaGestionActions';
import * as configuracionYSeguridadActions from '../actions/ConfiguracionYSeguridadActions';
import moment from 'moment';
import {
    // Importaciones para el CU - Catálogo de Clasificación de Gerencias
    listaClasificacionesCall,
    estadoClasificacionesCall,
    crearClasificacionCall,
    editarClasificacionCall,
    listaClasificacionesGerenciaActivasCall,
    listaClasificacionesActivosCall,

    // Importaciones para el CU -  Catálogo de Temas
    listaTemasBusquedaCall,
    listaTemasActivosCall,
    estadoTemaCall,
    crearTemaCall,
    editarTemaCall,

    // Importaciones para el CU -  Catálogo de Objetivos para Evaluación de Calidad
    listaObjetivosBusquedaCall,
    listaObjetivosActivosCall,
    estadoObjetivosCall,
    crearObjetivoCall,
    editarObjetivoCall,
    listaServiciosByIdCentroCostoCall,
    listaEstandaresDesempenoFiltroActivosByServicioCall,

    // Importaciones para el CU - Catálogo de  Indicadores de Desempeño
    objetivosByGerenciaCall,
    listaIndicadoresDesempenoCall,
    altaIndicadorDesempenoCall,
    editarIndicadorDesempenoCall,

    // Importaciones para el CU - Clasificación de Solicitudes de Servicios y Servicios Programados por Gerencia
    getEstadisticasSPSSCall,

    //Importaciones para el CU - Reporte Dinámico para Evaluaciones de Calidad
    listaReporteDinamicoCall,
    listaReporteDinamicoAgrupadoCall,
    exportarExcelReporteDinamico,
    exportarPDFReporteDinamico,
    listaRegistrosReporteCall,
    listaRegistrosEspecialReporteCall,
    estadoRegistroReporteCall,
    crearRegistroReporteCall,
    editarRegistroReporteCall,

    //Importaciones para el CU - Evaluación de Indicadores de Desempeño
    crearEvaluacionDeDesempenoCall,
    listaEvaluacionesDeDesempenoCall,
    listaGerenciasByServicioCall,
    listaIndicadoresByGerenciaObjetiboCall,
    listaMesesEvaluacionCall,
    listaObjetivosByGerenciaCall,
    listaPartidasByServicioCall,
    listaServiciosEvaluacionesDeDesempenoCall,
    listaEvaluacionesDeDesempeno2Call,
    listaServiciosByCentroCall

} from '../../services/EvaluacionDeLaGestionServices';

// SAGA para el CU - Catálogo de Clasificación de Gerencias
function* listaClasificaciones(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let param = '';
        if (action.busqueda) {
            param = 'param=' + action.busqueda + '&';
        }

        const response = yield call(listaClasificacionesCall, token, param, page)
        console.log(response)

        if (response.status === 200) {
            const listaClasificaciones = response.data.data;
            const listaClasificacionesCantidad = response.data.cantidad;

            yield put({
                type: evaluacionDeLaGestionActions.LISTA_CLASIFICACION_SUCCESS,
                listaClasificaciones,
                listaClasificacionesCantidad
            })

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.LISTA_CLASIFICACION_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.LISTA_CLASIFICACION_FAILURE});
    }
}

export function* listaClasificacionesSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_CLASIFICACION_REQUEST, listaClasificaciones)
}

function* listaClasificacionesActivos(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(listaClasificacionesActivosCall, token)
        console.log(response)

        if (response.status === 200) {
            const listaClasificacionesActivos = response.data;
            if(action.reporteDinamicoES){
                listaClasificacionesActivos.push({
                    'idClasificacionGerencia': '0',
                    'nombreGerencia': 'Sin gerencia asignada',
                })
            }
            yield put({ type: evaluacionDeLaGestionActions.LISTA_CLASIFICACION_ACTIVOS_SUCCESS, listaClasificacionesActivos })
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.LISTA_CLASIFICACION_ACTIVOS_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.LISTA_CLASIFICACION_ACTIVOS_FAILURE});
    }
}

export function* listaClasificacionesActivosSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_CLASIFICACION_ACTIVOS_REQUEST, listaClasificacionesActivos)
}

function* cambiarEstadoClasificacion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idClasificacionGerencia = action.idClasificacionGerencia;

        const response = yield call(estadoClasificacionesCall, token, idClasificacionGerencia);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El estado de la gerencia cambió de forma correcta";

            yield put({type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage});
            yield put({
                type: evaluacionDeLaGestionActions.LISTA_CLASIFICACION_REQUEST,
                page: action.page,
                busqueda: action.busqueda
            });
            yield put({type: evaluacionDeLaGestionActions.LISTA_CLASIFICACION_ACTIVOS_REQUEST});

            yield put({type: evaluacionDeLaGestionActions.ESTADO_CLASIFICACION_SUCCESS});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});

            yield put({type: evaluacionDeLaGestionActions.ESTADO_CLASIFICACION_FAILURE});
        }

    } catch (error) {
        console.log(error);
        yield put({type: evaluacionDeLaGestionActions.ESTADO_CLASIFICACION_FAILURE});
    }
}

export function* cambiarEstadoClasificacionSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.ESTADO_CLASIFICACION_REQUEST, cambiarEstadoClasificacion);
}

function* crearClasificacion(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const data = {
            "idClasificacionGerencia": null,
            "nombreGerencia": action.formulario.nombreGerencia,
            "descripcion": action.formulario.descripcion ? action.formulario.descripcion : '',
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }
        console.log(`data`, data);

        const response = yield call(crearClasificacionCall, token, data)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se creó la gerencia de manera exitosa";

            yield put({type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage});
            yield put({
                type: evaluacionDeLaGestionActions.SHOW_MODAL_CREAR_CLASIFICACION,
                showModalCrearClasificacion: false,
                page: action.page,
                busqueda: action.busqueda
            });
            yield put({
                type: evaluacionDeLaGestionActions.LISTA_CLASIFICACION_REQUEST,
                page: action.page,
                busqueda: action.busqueda
            });
            yield put({type: evaluacionDeLaGestionActions.LISTA_CLASIFICACION_ACTIVOS_REQUEST});
            yield put({
                type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO,
                limpiarFormulario: 'crearClasificacion'
            });

            yield put({type: evaluacionDeLaGestionActions.CREAR_CLASIFICACION_SUCCESS});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.CREAR_CLASIFICACION_FAILURE})
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.CREAR_CLASIFICACION_FAILURE})
    }
}

export function* crearClasificacionSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.CREAR_CLASIFICACION_REQUEST, crearClasificacion)
}

function* editarClasificacion(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idClasificacionGerencia = action.clasificacionSeleccionada.idClasificacionGerencia;
        const data = {
            "idClasificacionGerencia": idClasificacionGerencia,
            "nombreGerencia": action.formulario.nombreGerencia,
            "descripcion": action.formulario.descripcion ? action.formulario.descripcion : '',
            "fechaAlta": action.clasificacionSeleccionada.fechaAlta,
            "fechaActualizacion": action.clasificacionSeleccionada.fechaActualizacion,
            "activo": action.clasificacionSeleccionada.activo
        }
        console.log(`data`, data);

        const response = yield call(editarClasificacionCall, token, data, idClasificacionGerencia)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se editó la gerencia de manera exitosa";

            yield put({type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage});
            yield put({
                type: evaluacionDeLaGestionActions.SHOW_MODAL_EDITAR_CLASIFICACION,
                showModalCrearClasificacion: false,
                page: action.page,
                busqueda: action.busqueda
            });
            yield put({
                type: evaluacionDeLaGestionActions.LISTA_CLASIFICACION_REQUEST,
                page: action.page,
                busqueda: action.busqueda
            });
            yield put({type: evaluacionDeLaGestionActions.LISTA_CLASIFICACION_ACTIVOS_REQUEST});

            yield put({type: evaluacionDeLaGestionActions.EDITAR_CLASIFICACION_SUCCESS});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.EDITAR_CLASIFICACION_FAILURE})
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.EDITAR_CLASIFICACION_FAILURE})
    }
}

export function* editarClasificacionSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.EDITAR_CLASIFICACION_REQUEST, editarClasificacion)
}

function* listaClasificacionesGerenciaActivas(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaClasificacionesGerenciaActivasCall, token);

        if (response.status == 200) {
            const listaClasificacionesGerenciaActivas = response.data;

            yield put({
                type: evaluacionDeLaGestionActions.LISTA_CLASIFICACIONES_GERENCIA_ACTIVAS_SUCCESS,
                listaClasificacionesGerenciaActivas
            });
        } else {
            yield put({type: evaluacionDeLaGestionActions.LISTA_CLASIFICACIONES_GERENCIA_ACTIVAS_FAILURE});
        }

    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.LISTA_CLASIFICACIONES_GERENCIA_ACTIVAS_FAILURE});
    }
}

export function* listaClasificacionesGerenciaActivasSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_CLASIFICACIONES_GERENCIA_ACTIVAS_REQUEST, listaClasificacionesGerenciaActivas);
}

// SAGA para el CU -  Catálogo de Temas
function* listaTemasBusqueda(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let tema = '';
        let centro = '';
        if (action.busqueda.tema) {
            tema = 'tema=' + action.busqueda.tema + '&';
        }
        if (action.busqueda.centro) {
            centro = 'centro=' + action.busqueda.centro + '&';
        }
        const response = yield call(listaTemasBusquedaCall, token, tema, centro, page)
        console.log(response)

        if (response.status === 200) {
            const listaTemasBusqueda = response.data.data;
            const listaTemasBusquedaCantidad = response.data.cantidad;

            for (let i in listaTemasBusqueda) {
                listaTemasBusqueda[i].centroCosto = listaTemasBusqueda[i].centroCostoByIdCentroCosto.centroCosto;
                listaTemasBusqueda[i].centroCostoEditar = listaTemasBusqueda[i].centroCostoByIdCentroCosto.idCentroCosto + ' - ' + listaTemasBusqueda[i].centroCostoByIdCentroCosto.centroCosto;
                listaTemasBusqueda[i].gerencia = listaTemasBusqueda[i].centroCostoByIdCentroCosto.clasificacionByIdClasificacion.nombreGerencia;
            }

            yield put({
                type: evaluacionDeLaGestionActions.LISTA_TEMAS_BUSQUEDA_SUCCESS,
                listaTemasBusqueda,
                listaTemasBusquedaCantidad
            })

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.LISTA_TEMAS_BUSQUEDA_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.LISTA_TEMAS_BUSQUEDA_FAILURE});
    }
}

export function* listaTemasBusquedaSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_TEMAS_BUSQUEDA_REQUEST, listaTemasBusqueda)
}

function* listaTemasActivos(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let param = '';
        if (action.param) {
            param = 'param=' + action.param;
        }
        const response = yield call(listaTemasActivosCall, token, param)
        console.log(response)

        if (response.status === 200) {
            const listaTemasActivos = response.data;
            yield put({type: evaluacionDeLaGestionActions.LISTA_TEMAS_ACTIVOS_SUCCESS, listaTemasActivos})

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.LISTA_TEMAS_ACTIVOS_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.LISTA_TEMAS_ACTIVOS_FAILURE});
    }
}

export function* listaTemasActivosSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_TEMAS_ACTIVOS_REQUEST, listaTemasActivos)
}

function* cambiarEstadoTema(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idTema = action.idTema;

        const response = yield call(estadoTemaCall, token, idTema);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El estado del tema cambió de forma correcta";

            yield put({type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage});
            yield put({
                type: evaluacionDeLaGestionActions.LISTA_TEMAS_BUSQUEDA_REQUEST,
                page: action.page,
                busqueda: action.busqueda
            });
            yield put({type: evaluacionDeLaGestionActions.LISTA_TEMAS_ACTIVOS_REQUEST});

            yield put({type: evaluacionDeLaGestionActions.ESTADO_TEMA_SUCCESS});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});

            yield put({type: evaluacionDeLaGestionActions.ESTADO_TEMA_FAILURE});
        }

    } catch (error) {
        console.log(error);
        yield put({type: evaluacionDeLaGestionActions.ESTADO_TEMA_FAILURE});
    }
}

export function* cambiarEstadoTemaSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.ESTADO_TEMA_REQUEST, cambiarEstadoTema);
}

function* crearTema(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const data = {
            "idTema": null,
            "tema": action.formulario.tema,
            "idCentroCosto": action.formulario.idCentroCosto,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": null
        }
        console.log(`data`, data);

        const response = yield call(crearTemaCall, token, data)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se creó el tema de manera exitosa";

            yield put({type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage});
            yield put({
                type: evaluacionDeLaGestionActions.SHOW_MODAL_NUEVO_TEMA,
                showModalNuevoTema: false,
                page: action.page,
                busqueda: action.busqueda
            });
            yield put({
                type: evaluacionDeLaGestionActions.LISTA_TEMAS_BUSQUEDA_REQUEST,
                page: action.page,
                busqueda: action.busqueda
            });
            yield put({type: evaluacionDeLaGestionActions.LISTA_TEMAS_ACTIVOS_REQUEST});
            yield put({type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearTema'});

            yield put({type: evaluacionDeLaGestionActions.NUEVO_TEMA_SUCCESS});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.NUEVO_TEMA_FAILURE})
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.NUEVO_TEMA_FAILURE})
    }
}

export function* crearTemaSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.NUEVO_TEMA_REQUEST, crearTema)
}

function* editarTema(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idTema = action.temaSeleccionado.idTema;
        const data = {
            "idTema": idTema,
            "tema": action.formulario.tema,
            "idCentroCosto": parseInt(action.formulario.centroCostoEditar),
            "fechaAlta": action.temaSeleccionado.fechaAlta,
            "fechaActualizacion": action.temaSeleccionado.fechaActualizacion,
            "activo": action.temaSeleccionado.activo
        }
        console.log(`data`, data);

        const response = yield call(editarTemaCall, token, data, idTema)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se editó el tema de manera exitosa";

            yield put({type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage});
            yield put({
                type: evaluacionDeLaGestionActions.SHOW_MODAL_EDITAR_TEMA,
                showModalEditarTema: false,
                page: action.page,
                busqueda: action.busqueda
            });
            yield put({
                type: evaluacionDeLaGestionActions.LISTA_TEMAS_BUSQUEDA_REQUEST,
                page: action.page,
                busqueda: action.busqueda
            });
            yield put({type: evaluacionDeLaGestionActions.LISTA_TEMAS_ACTIVOS_REQUEST});

            yield put({type: evaluacionDeLaGestionActions.EDITAR_TEMA_SUCCESS});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.EDITAR_TEMA_FAILURE})
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.EDITAR_TEMA_FAILURE})
    }
}

export function* editarTemaSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.EDITAR_TEMA_REQUEST, editarTema)
}

// SAGA para el CU -  Catálogo de Objetivos para Evaluación de Calidad 
function* listaObjetivosBusqueda(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let gerencia = '';
        let centro = '';

        if (action.busqueda.gerencia) {
            gerencia = 'gerencia=' + action.busqueda.gerencia + '&';
        }
        if (action.busqueda.centro) {
            centro = 'centro=' + action.busqueda.centro + '&';
        }

        const response = yield call(listaObjetivosBusquedaCall, token, gerencia, centro, page)
        console.log('response', response)

        if (response.status === 200) {
            const listaObjetivosBusqueda = response.data.data;
            const listaObjetivosBusquedaCantidad = response.data.cantidad;

            for (let i in listaObjetivosBusqueda) {
                if (listaObjetivosBusqueda[i].temaByIdTema) {
                    listaObjetivosBusqueda[i].tema = listaObjetivosBusqueda[i].temaByIdTema.tema;
                } else {
                    listaObjetivosBusqueda[i].tema = '-';
                }

                if (listaObjetivosBusqueda[i].centroByIdCentroCosto) {
                    listaObjetivosBusqueda[i].centroCosto = listaObjetivosBusqueda[i].centroByIdCentroCosto.centroCosto;
                    if (listaObjetivosBusqueda[i].centroByIdCentroCosto.clasificacionByIdClasificacion) {
                        listaObjetivosBusqueda[i].gerencia = listaObjetivosBusqueda[i].centroByIdCentroCosto.clasificacionByIdClasificacion.nombreGerencia;
                    } else {
                        listaObjetivosBusqueda[i].gerencia = '-';
                    }
                } else {
                    listaObjetivosBusqueda[i].centroCosto = '-';
                }

                listaObjetivosBusqueda[i].estandar = '';
                if (listaObjetivosBusqueda[i].objetivosEstandaresByIdObjetivo) {
                    for (let j in listaObjetivosBusqueda[i].objetivosEstandaresByIdObjetivo) {
                        listaObjetivosBusqueda[i].estandar += ' - ' + listaObjetivosBusqueda[i].objetivosEstandaresByIdObjetivo[j].estandarByIdEstandar.referencia + ': ' + listaObjetivosBusqueda[i].objetivosEstandaresByIdObjetivo[j].estandarByIdEstandar.descripcion;
                    }
                } else {
                    listaObjetivosBusqueda[i].estandar = '-';
                }
            }

            yield put({
                type: evaluacionDeLaGestionActions.LISTA_OBJETIVOS_BUSQUEDA_SUCCESS,
                listaObjetivosBusqueda,
                listaObjetivosBusquedaCantidad
            });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.LISTA_OBJETIVOS_BUSQUEDA_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.LISTA_OBJETIVOS_BUSQUEDA_FAILURE});
    }
}

export function* listaObjetivosBusquedaSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_OBJETIVOS_BUSQUEDA_REQUEST, listaObjetivosBusqueda)
}

function* listaObjetivosActivos(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let param = '';
        if (action.param) {
            param = 'param=' + param;
        }
        const response = yield call(listaObjetivosActivosCall, token, param)
        console.log(response)

        if (response.status === 200) {
            const listaObjetivosActivos = response.data;
            yield put({type: evaluacionDeLaGestionActions.LISTA_OBJETIVOS_ACTIVOS_SUCCESS, listaObjetivosActivos})

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.LISTA_OBJETIVOS_ACTIVOS_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.LISTA_OBJETIVOS_ACTIVOS_FAILURE});
    }
}

export function* listaObjetivosActivosSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_OBJETIVOS_ACTIVOS_REQUEST, listaObjetivosActivos)
}

function* estadoObjetivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idObjetivo = action.idObjetivo;

        const response = yield call(estadoObjetivosCall, token, idObjetivo);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El estado del objetivo cambió de forma correcta";

            yield put({type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage});
            yield put({
                type: evaluacionDeLaGestionActions.LISTA_OBJETIVOS_BUSQUEDA_REQUEST,
                busqueda: action.busqueda,
                page: action.page
            });
            yield put({type: evaluacionDeLaGestionActions.LISTA_OBJETIVOS_ACTIVOS_REQUEST});

            yield put({type: evaluacionDeLaGestionActions.ESTADO_OBJETIVO_SUCCESS});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});

            yield put({type: evaluacionDeLaGestionActions.ESTADO_OBJETIVO_FAILURE});
        }

    } catch (error) {
        console.log(error);
        yield put({type: evaluacionDeLaGestionActions.ESTADO_OBJETIVO_FAILURE});
    }
}

export function* estadoObjetivosSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.ESTADO_OBJETIVO_REQUEST, estadoObjetivos);
}

function* crearObjetivo(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        let estandares = [];

        for (let i in action.formulario.servicios) {
            let conctAsig = 'estandar-' + action.formulario.servicios[i].split(' - ')[0];
            estandares.push({
                idServicio: parseInt(action.formulario.servicios[i].split(' - ')[0]),
                idEstandarDesempeno: parseInt(action.formulario[conctAsig].split(' - ')[0])
            });
        }

        const data = {
            "idObjetivo": null,
            "objetivo": action.formulario.objetivo.trim(),
            "idTema": (action.formulario.tema).split(' - ')[0],
            "idCentroCosto": (action.formulario.centroCosto).split(' - ')[0],
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
            "objetivosEstandaresByIdObjetivo": estandares,
        }
        console.log(`data`, data);

        const response = yield call(crearObjetivoCall, token, data)
        console.log('response', response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se creó el objetivo de manera exitosa";

            yield put({type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage});
            yield put({
                type: evaluacionDeLaGestionActions.SHOW_MODAL_NUEVO_OBJETIVO,
                showModalNuevoObjetivo: false,
                page: action.page,
                busqueda: action.busqueda
            });
            yield put({
                type: evaluacionDeLaGestionActions.LISTA_OBJETIVOS_BUSQUEDA_REQUEST,
                page: action.page,
                busqueda: action.busqueda
            });
            yield put({type: evaluacionDeLaGestionActions.LISTA_OBJETIVOS_ACTIVOS_REQUEST});
            yield put({type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearObjetivo'});

            yield put({type: evaluacionDeLaGestionActions.NUEVO_OBJETIVO_SUCCESS});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.NUEVO_OBJETIVO_FAILURE})
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.NUEVO_OBJETIVO_FAILURE})
    }
}

export function* crearObjetivoSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.NUEVO_OBJETIVO_REQUEST, crearObjetivo)
}

function* editarObjetivo(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idObjetivo = action.objetivoSeleccionado.idObjetivo;
        let estandares = [];

        for (let i in action.formulario.servicioEditar) {
            let conctAsig = 'estandar-' + action.formulario.servicioEditar[i].split(' - ')[0];
            estandares.push({
                idServicio: parseInt(action.formulario.servicioEditar[i].split(' - ')[0]),
                idEstandarDesempeno: parseInt(action.formulario[conctAsig].split(' - ')[0])
            });
        }

        const data = {
            "idObjetivo": idObjetivo,
            "objetivo": action.formulario.objetivo.trim(),
            "idTema": (action.formulario.temaEditar).split(' - ')[0],
            "idCentroCosto": (action.formulario.centroCostoEditar).split(' - ')[0],
            "fechaAlta": action.objetivoSeleccionado.fechaAlta,
            "fechaActualizacion": action.objetivoSeleccionado.fechaActualizacion,
            "activo": action.objetivoSeleccionado.activo,
            "objetivosEstandaresByIdObjetivo": estandares,
        }
        console.log(`data`, data);

        const response = yield call(editarObjetivoCall, token, data, idObjetivo)
        console.log('response', response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se editó el objetivo de manera exitosa";

            yield put({type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage});
            yield put({
                type: evaluacionDeLaGestionActions.SHOW_MODAL_EDITAR_OBJETIVO,
                showModalEditarObjetivo: false,
                page: action.page,
                busqueda: action.busqueda
            });
            yield put({
                type: evaluacionDeLaGestionActions.LISTA_OBJETIVOS_BUSQUEDA_REQUEST,
                page: action.page,
                busqueda: action.busqueda
            });
            yield put({type: evaluacionDeLaGestionActions.LISTA_OBJETIVOS_ACTIVOS_REQUEST});

            yield put({type: evaluacionDeLaGestionActions.EDITAR_OBJETIVO_SUCCESS});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.EDITAR_OBJETIVO_FAILURE})
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.EDITAR_OBJETIVO_FAILURE})
    }
}

export function* editarObjetivoSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.EDITAR_OBJETIVO_REQUEST, editarObjetivo)
}

function* listaServiciosByIdCentroCosto(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaServiciosByIdCentroCostoCall, token, action.idCentroCosto)
        console.log('response', response);

        if (response.status === 200) {
            const serviciosByIdCentroCosto = response.data;

            yield put({
                type: evaluacionDeLaGestionActions.SERVICIOS_BY_IDCENTROCOSTO_SUCCESS,
                serviciosByIdCentroCosto
            });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.SERVICIOS_BY_IDCENTROCOSTO_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.SERVICIOS_BY_IDCENTROCOSTO_FAILURE});
    }
}

export function* listaServiciosByIdCentroCostoSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.SERVICIOS_BY_IDCENTROCOSTO_REQUEST, listaServiciosByIdCentroCosto)
}

function* listaEstandaresDesempenoFiltroActivosByServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const param = action.param;
        const idServicio = action.idServicio.toString();

        const response = yield call(listaEstandaresDesempenoFiltroActivosByServicioCall, token, param, idServicio);
        console.log(response);

        if (response.status == 200) {
            let listaEstandaresDesempenoFiltroActivosByServicio = response.data;

            yield put({
                type: evaluacionDeLaGestionActions.LISTA_ESTANDARES_ACTIVOS_PARAM_BY_SERVICIO_SUCCESS,
                listaEstandaresDesempenoFiltroActivosByServicio
            });
        } else {
            yield put({type: evaluacionDeLaGestionActions.LISTA_ESTANDARES_ACTIVOS_PARAM_BY_SERVICIO_FAILURE});
        }

    } catch (error) {
        console.log(error);
        yield put({type: evaluacionDeLaGestionActions.LISTA_ESTANDARES_ACTIVOS_PARAM_BY_SERVICIO_FAILURE});
    }
}

export function* listaEstandaresDesempenoFiltroActivosByServicioSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_ESTANDARES_ACTIVOS_PARAM_BY_SERVICIO_REQUEST, listaEstandaresDesempenoFiltroActivosByServicio);
}


// SAGA para el CU - Catálogo de  Indicadores de Desempeño
function* objetivosByGerencia(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(objetivosByGerenciaCall, token, action.idClasificacionGerencia);
        console.log('response', response)

        if (response.status == 200) {
            const objetivosByGerencia = response.data;
            let disabledObjEdit = false;

            if (objetivosByGerencia.length === 0) {
                console.log('entra la condicion')
                const showWarningMsg = true;
                disabledObjEdit = true;
                const textMessage = 'La gerencia seleccionada no cuenta con objetivos relaciandos'
                yield put({type: configuracionYSeguridadActions.SHOW_WARNING_MSG, showWarningMsg, textMessage});
            }

            yield put({
                type: evaluacionDeLaGestionActions.OBJETIVOS_BY_GERENCIA_SUCCESS,
                objetivosByGerencia,
                disabledObjEdit
            });
        } else {
            yield put({type: evaluacionDeLaGestionActions.OBJETIVOS_BY_GERENCIA_FAILURE});
        }

    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.OBJETIVOS_BY_GERENCIA_FAILURE});
    }
}

export function* objetivosByGerenciaSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.OBJETIVOS_BY_GERENCIA_REQUEST, objetivosByGerencia);
}

function* listaIndicadoresDesempeno(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let objetivo = '';
        let fechaVigencia = '';
        let indicadorDesempeno = '';

        if (action.busqueda) {
            objetivo = 'objetivo=' + action.busqueda + '&';
        }
        if (action.fechaVigencia) {
            fechaVigencia = 'fechaVigencia=' + action.fechaVigencia + '&';
        }
        if (action.indicadorDesempeno) {
            indicadorDesempeno = 'indicadorDesempeno=' + action.indicadorDesempeno + '&';
        }

        const response = yield call(listaIndicadoresDesempenoCall, token, objetivo, fechaVigencia, indicadorDesempeno, page)
        console.log('response', response)

        if (response.status === 200) {
            const listaIndicadoresDesempeno = response.data.data;
            const listaIndicadoresDesempenoCantidad = response.data.cantidad;

            for (let i in listaIndicadoresDesempeno) {
                if (listaIndicadoresDesempeno[i].clasificacionGerenciaByIdClasificacionGerencia) {
                    listaIndicadoresDesempeno[i].gerencia = listaIndicadoresDesempeno[i].clasificacionGerenciaByIdClasificacionGerencia.nombreGerencia
                }

                if (listaIndicadoresDesempeno[i].objetivoByIdObjetivo) {
                    listaIndicadoresDesempeno[i].objetivo = listaIndicadoresDesempeno[i].objetivoByIdObjetivo.objetivo
                }

                if (!listaIndicadoresDesempeno[i].informacionInicial) {
                    listaIndicadoresDesempeno[i].informacionInicial = '-'
                }

                if (!listaIndicadoresDesempeno[i].frecuenciaEvaluacion) {
                    listaIndicadoresDesempeno[i].frecuenciaEvaluacion = '-'
                }

                listaIndicadoresDesempeno[i].valorEsperadoTabla = listaIndicadoresDesempeno[i].valorEsperado.toFixed(2);
                listaIndicadoresDesempeno[i].vigencia = listaIndicadoresDesempeno[i].fechaVigencia.split('T')[0].split('-').reverse().join('-');
                listaIndicadoresDesempeno[i].fechaVigenciaEditar = moment(listaIndicadoresDesempeno[i].fechaVigencia);
            }

            yield put({
                type: evaluacionDeLaGestionActions.LISTA_INDICADORES_DESEMPENO_BUSQUEDA_SUCCESS,
                listaIndicadoresDesempeno,
                listaIndicadoresDesempenoCantidad
            });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.LISTA_INDICADORES_DESEMPENO_BUSQUEDA_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.LISTA_INDICADORES_DESEMPENO_BUSQUEDA_FAILURE});
    }
}

export function* listaIndicadoresDesempenoSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_INDICADORES_DESEMPENO_BUSQUEDA_REQUEST, listaIndicadoresDesempeno)
}

function* altaIndicadorDesempeno(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const data = {
            "idClasificacionGerencia": parseInt(action.formulario.gerencia.split(' - ')[0]),
            "idObjetivo": parseInt(action.formulario.objetivo.split(' - ')[0]),
            "indicadorDesempeno": action.formulario.indicadorDesempeno.trim(),
            "origenInformacion": action.formulario.origenInformacion,
            "informacionInicial": action.formulario.parteInformacion.trim(),
            "valorEsperado": action.formulario.valorEsperado,
            "fechaVigencia": action.dateString,
            "frecuenciaEvaluacion": action.formulario.frecuenciaEvaluacion,
        }
        console.log(`data`, data);

        const response = yield call(altaIndicadorDesempenoCall, token, data);
        console.log('response', response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se creó el indicador de desempeño de manera exitosa";

            yield put({type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage});
            yield put({
                type: evaluacionDeLaGestionActions.SHOW_MODAL_ALTA_INDICADORES_DESEMPENO,
                showModalAltaIndicaresDesempeno: false,
                page: action.page,
                busqueda: action.busqueda,
                indicadorDesempeno: action.indicadorDesempeno,
                fechaVigencia: action.fechaVigencia
            });
            yield put({
                type: evaluacionDeLaGestionActions.LISTA_INDICADORES_DESEMPENO_BUSQUEDA_REQUEST,
                page: action.page,
                busqueda: action.busqueda,
                indicadorDesempeno: action.indicadorDesempeno,
                fechaVigencia: action.fechaVigencia
            });
            yield put({
                type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO,
                limpiarFormulario: 'crearIndicadorDesempeno'
            });

            yield put({type: evaluacionDeLaGestionActions.ALTA_INDICADOR_DESEMPENO_SUCCESS});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.ALTA_INDICADOR_DESEMPENO_FAILURE})
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.ALTA_INDICADOR_DESEMPENO_FAILURE})
    }
}

export function* altaIndicadorDesempenoSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.ALTA_INDICADOR_DESEMPENO_REQUEST, altaIndicadorDesempeno)
}

function* editarIndicadorDesempeno(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idIndicadorDesempeno = action.indicadorDesempenoSeleccionado.idIndicadorDesempeno;

        const fechaVigencia = moment(action.formulario.fechaVigenciaEditar.format('YYYY-MM-DD'))

        const data = {
            "idClasificacionGerencia": parseInt(action.formulario.gerenciaEditar.split(' - ')[0]),
            "idObjetivo": parseInt(action.formulario.objetivoEditar.split(' - ')[0]),
            "indicadorDesempeno": action.formulario.indicadorDesempeno.trim(),
            "origenInformacion": action.formulario.origenInformacion.trim(),
            "informacionInicial": action.formulario.informacionInicial,
            "valorEsperado": action.formulario.valorEsperado,
            "fechaVigencia": fechaVigencia._i,
            "frecuenciaEvaluacion": action.formulario.frecuenciaEvaluacion,
        }
        console.log(`data`, data);

        const response = yield call(editarIndicadorDesempenoCall, token, data, idIndicadorDesempeno)
        console.log('response', response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se editó el indicador de desempeño de manera exitosa";

            yield put({type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage});
            yield put({
                type: evaluacionDeLaGestionActions.SHOW_MODAL_EDITAR_INDICADOR_DESEMPENO,
                showModalEditarIndicadorDesempeno: false,
                indicadorDesempenoSeleccionado: [],
                page: action.page,
                busqueda: action.busqueda,
                indicadorDesempeno: action.indicadorDesempeno,
                fechaVigencia: action.fechaVigencia
            });
            yield put({
                type: evaluacionDeLaGestionActions.LISTA_INDICADORES_DESEMPENO_BUSQUEDA_REQUEST,
                page: action.page,
                busqueda: action.busqueda,
                indicadorDesempeno: action.indicadorDesempeno,
                fechaVigencia: action.fechaVigencia
            });

            yield put({type: evaluacionDeLaGestionActions.EDITAR_INDICADOR_DESEMPENO_SUCCESS});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.EDITAR_INDICADOR_DESEMPENO_FAILURE})
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.EDITAR_INDICADOR_DESEMPENO_FAILURE})
    }
}

export function* editarIndicadorDesempenoSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.EDITAR_INDICADOR_DESEMPENO_REQUEST, editarIndicadorDesempeno)
}

//Clasificación de Solicitudes de Servicios y Servicios Programados por Gerencia
function* getEstadisticasPorGerencia(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let {gerencia = "", mes = ""} = action.formulario;
        mes = mes.format('MM');

        const response = yield call(getEstadisticasSPSSCall, token, gerencia, mes)
        console.log('response', response)

        if (response.status === 200) {
            let estadisticasGerencia = response.data;
            let estadisticasGerenciaSS = {};
            let estadisticasGerenciaSP = {};

            let estadisticasSS = estadisticasGerencia['dataSolicitudesDeServicio'];
            let estadisticasSP = estadisticasGerencia['dataServiciosPlanificados'];

            let serviciosArraySS = [];
            let estadosObjetoArraySS = {};
            let listaSSEstadisticasCantidad = 0;
            let estadisticasSSObj = {};
            // Estadisticas SS
            //Estructura datos
            for (let prop in estadisticasSS) {
                let estadoObjeto = {
                    'Generada': 0,
                    'Abierto': 0,
                    'Traslado': 0,
                    'En Proceso': 0,
                    'En Espera': 0,
                    'Terminada': 0,
                    'Cancelado': 0,
                    'Cerrado': 0
                }
                const propData = prop.split('-');
                const value = estadisticasSS[prop];
                const servicioName = propData[0]
                const estadoName = propData[1];
                listaSSEstadisticasCantidad += value;
                if (estadisticasSSObj[servicioName]) {
                    let estadoObjActual = estadisticasSSObj[servicioName];
                    estadisticasSSObj[servicioName] = {...estadoObjActual, [estadoName]: value};
                } else {
                    estadoObjeto[estadoName] = value;
                    estadisticasSSObj[servicioName] = {...estadoObjeto}
                }
            }

            //Estadisticas
            estadosObjetoArraySS = {
                'Generada': [],
                'Abierto': [],
                'Traslado': [],
                'En Proceso': [],
                'En Espera': [],
                'Terminada': [],
                'Cancelado': [],
                'Cerrado': []
            }
            for (let servicio in estadisticasSSObj) {
                if (estadisticasSSObj.hasOwnProperty(servicio)) {
                    serviciosArraySS.push(servicio);

                    let estadosServicio = estadisticasSSObj[servicio];
                    estadosObjetoArraySS['Generada'] = [...estadosObjetoArraySS['Generada'], estadosServicio['Generada']];
                    estadosObjetoArraySS['Abierto'] = [...estadosObjetoArraySS['Abierto'], estadosServicio['Abierto']];
                    estadosObjetoArraySS['Traslado'] = [...estadosObjetoArraySS['Traslado'], estadosServicio['Traslado']];
                    estadosObjetoArraySS['En Proceso'] = [...estadosObjetoArraySS['En Proceso'], estadosServicio['En Proceso']];
                    estadosObjetoArraySS['En Espera'] = [...estadosObjetoArraySS['En Espera'], estadosServicio['En Espera']];
                    estadosObjetoArraySS['Terminada'] = [...estadosObjetoArraySS['Terminada'], estadosServicio['Terminada']];
                    estadosObjetoArraySS['Cancelado'] = [...estadosObjetoArraySS['Cancelado'], estadosServicio['Cancelado']];
                    estadosObjetoArraySS['Cerrado'] = [...estadosObjetoArraySS['Cerrado'], estadosServicio['Cerrado']];
                }
            }

            estadisticasGerenciaSS = {serviciosArraySS, estadosObjetoArraySS, listaSSEstadisticasCantidad}
            console.log(estadisticasGerenciaSS);

            // Estadisticas SP
            let serviciosArraySP = [];
            let estadosObjetoArraySP = {};
            let listaSPEstadisticasCantidad = 0;
            let estadisticasSPObj = {};

            //Estructura datos
            for (let prop in estadisticasSP) {
                let estadoObjeto = {
                    'Generada': 0,
                    'Programada': 0,
                    'En proceso': 0,
                    'Realizada': 0,
                    'Fuera de tiempo': 0,
                    'Reprogramada': 0,
                    'Cancelada': 0
                }
                const propData = prop.split('-');
                const value = estadisticasSP[prop];
                const servicioName = propData[0]
                const estadoName = propData[1];
                listaSPEstadisticasCantidad += value;
                if (estadisticasSPObj[servicioName]) {
                    let estadoObjActual = estadisticasSPObj[servicioName];
                    estadisticasSPObj[servicioName] = {...estadoObjActual, [estadoName]: value};
                } else {
                    estadoObjeto[estadoName] = value
                    estadisticasSPObj[servicioName] = {...estadoObjeto}
                }
            }

            //Estadisticas
            estadosObjetoArraySP = {
                'Generada': [],
                'Programada': [],
                'En proceso': [],
                'Realizada': [],
                'Fuera de tiempo': [],
                'Reprogramada': [],
                'Cancelada': []
            }
            for (let servicio in estadisticasSPObj) {
                if (estadisticasSPObj.hasOwnProperty(servicio)) {
                    serviciosArraySP.push(servicio);

                    let estadosServicio = estadisticasSPObj[servicio];
                    estadosObjetoArraySP['Generada'] = [...estadosObjetoArraySP['Generada'], estadosServicio['Generada']];
                    estadosObjetoArraySP['Programada'] = [...estadosObjetoArraySP['Programada'], estadosServicio['Programada']];
                    estadosObjetoArraySP['En proceso'] = [...estadosObjetoArraySP['En proceso'], estadosServicio['En proceso']];
                    estadosObjetoArraySP['Realizada'] = [...estadosObjetoArraySP['Realizada'], estadosServicio['Realizada']];
                    estadosObjetoArraySP['Fuera de tiempo'] = [...estadosObjetoArraySP['Fuera de tiempo'], estadosServicio['Fuera de tiempo']];
                    estadosObjetoArraySP['Reprogramada'] = [...estadosObjetoArraySP['Reprogramada'], estadosServicio['Reprogramada']];
                    estadosObjetoArraySP['Cancelada'] = [...estadosObjetoArraySP['Cancelada'], estadosServicio['Cancelada']];
                }
            }

            estadisticasGerenciaSP = {serviciosArraySP, estadosObjetoArraySP, listaSPEstadisticasCantidad}
            console.log(estadisticasGerenciaSP);

            yield put({
                type: evaluacionDeLaGestionActions.GET_ESTADISTICAS_GERENCIA_SUCCESS,
                estadisticasGerenciaSS,
                estadisticasGerenciaSP
            });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.GET_ESTADISTICAS_GERENCIA_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.GET_ESTADISTICAS_GERENCIA_FAILURE});
    }
}

export function* getEstadisticasPorGerenciaSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.GET_ESTADISTICAS_GERENCIA_REQUEST, getEstadisticasPorGerencia)
}

// SAGA para el CU -  Reporte Dinámico para Evaluaciones de Calidad
function* listaReporteDinamicoEvaluacion(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idsClasificacionesGerencias = [];
        for(let i in action.formulario.gerencia){
            idsClasificacionesGerencias.push(parseInt(action.formulario.gerencia[i]));
        }
        const idsCentrosCostos = [];
        for(let i in action.formulario.centroCosto){
            idsCentrosCostos.push(parseInt(action.formulario.centroCosto[i]))
        }
        const idsServicios = [];
        for(let i in action.formulario.idServicio){
            idsServicios.push(parseInt(action.formulario.idServicio[i]));
        }
        const idsPartidas = [];
        for(let i in action.formulario.partida){
            idsPartidas.push(parseInt(action.formulario.partida[i]));
        }
        const idsTemas = [];
        for(let i in action.formulario.temas){
            idsTemas.push(parseInt(action.formulario.temas[i]));
        }
        const data = {
            "idsClasificacionesGerencias": action.formulario.tipoAgrupado === 'Por gerencia' ? [action.agrupado.id] : idsClasificacionesGerencias,
            "idsCentrosCostos": action.formulario.tipoAgrupado === 'Por centro de costo' ? [action.agrupado.id] : action.formulario.centroCosto ? idsCentrosCostos : null,
            "idsServicios": action.formulario.tipoAgrupado === 'Por servicio' ? [action.agrupado.id] : action.formulario.idServicio ? idsServicios : null,
            "idsPartidas": action.formulario.partida ? idsPartidas : null,
            "idsTemas": action.formulario.tipoAgrupado === 'Por tema' ? [action.agrupado.id] : action.formulario.temas ? idsTemas : null,
            "proveedor": action.formulario.proveedor ? action.formulario.proveedor : null,
            'idsObjetivos': action.formulario.tipoAgrupado === 'Por objetivo' ? [action.agrupado.id] : null,
            'idsGruposSer': action.formulario.tipoAgrupado === 'Por grupo servicio' ? [action.agrupado.id] : null,
            "idMes": action.formulario.fechaEvaluacion ? parseInt(action.formulario.fechaEvaluacion.format('MM-YYYY').split('-')[0]) : null,
            "anio": action.formulario.fechaEvaluacion ? parseInt(action.formulario.fechaEvaluacion.format('MM-YYYY').split('-')[1]) : null,
            "page": (action.page - 1) * 10,
            "offset": 10,
            'especial': action.especial,
            "order": action.order.order,
            "field": action.order.field,
            "gerenciaC": action.checkboxData.gerenciaC ? action.checkboxData.gerenciaC : false,
            "centroC": action.checkboxData.centroC ? action.checkboxData.centroC : false,
            "servicioC": action.checkboxData.servicioC ? action.checkboxData.servicioC : false,
            "partidaC": action.checkboxData.partidaC ? action.checkboxData.partidaC : false,
            "proveedorC": action.checkboxData.proveedorC ? action.checkboxData.proveedorC : false,
            "objetivoC": action.checkboxData.objetivoC ? action.checkboxData.objetivoC : false,
            "estandarC": action.checkboxData.estandarC ? action.checkboxData.estandarC : false,
            "temaC": action.checkboxData.temaC ? action.checkboxData.temaC : false,
            "indicadorC": action.checkboxData.indicadorC ? action.checkboxData.indicadorC : false,
            "valorEsperadoC": action.checkboxData.valorEsperadoC ? action.checkboxData.valorEsperadoC : false,
            "valorRealC": action.checkboxData.valorRealC ? action.checkboxData.valorRealC : false,
            "fechaEvaluacionC": action.checkboxData.fechaEvaluacionC ? action.checkboxData.fechaEvaluacionC : false
        }
        console.log(`data`, data);

        const response = yield call(listaReporteDinamicoCall, token, data)
        console.log(response)

        if (response.status === 200) {
            const listaReportesDinamico = response.data.data;
            const listaReportesDinamicoCantidad = response.data.cantidad;

            for (let i in listaReportesDinamico) {
                listaReportesDinamico[i].idReporte = i;
                listaReportesDinamico[i].fechaEvaluacion = listaReportesDinamico[i].mes + ' ' + listaReportesDinamico[i].anio;
            }
            if(action.agrupado){
                const listaReportesDinamicoAgrupado = action.agrupado.listaReportesDinamicoAgrupado;
                const listaReportesDinamicoAgrupadoCantidad = action.agrupado.listaReportesDinamicoAgrupadoCantidad;
                for(let i in listaReportesDinamicoAgrupado){
                    if(action.agrupado.id === listaReportesDinamicoAgrupado[i].id){
                        listaReportesDinamicoAgrupado[i].agrupados = response.data;
                        listaReportesDinamicoAgrupado[i].agrupados.page = action.page;
                        for(let x in listaReportesDinamicoAgrupado[i].agrupados.data){
                            listaReportesDinamicoAgrupado[i].agrupados.data[x].id = parseInt(x) + 1;
                        };
                    }
                }
                console.log(`listaReportesDinamicoAgrupado cambio`, listaReportesDinamicoAgrupado)
                yield put({ type: evaluacionDeLaGestionActions.LISTA_REPORTE_DINAMICO_AGRUPADO_SUCCESS, listaReportesDinamicoAgrupado, listaReportesDinamicoAgrupadoCantidad })
                yield put({ type: evaluacionDeLaGestionActions.LISTA_REPORTE_DINAMICO_FAILURE });
            }
            else{
                yield put({ type: evaluacionDeLaGestionActions.LISTA_REPORTE_DINAMICO_SUCCESS, listaReportesDinamico, listaReportesDinamicoCantidad })
            }
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.LISTA_REPORTE_DINAMICO_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.LISTA_REPORTE_DINAMICO_FAILURE});
    }
}

export function* listaReporteDinamicoEvaluacionSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_REPORTE_DINAMICO_REQUEST, listaReporteDinamicoEvaluacion)
}

function* listaReporteDinamicoEvaluacionAgrupado(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idsClasificacionesGerencias = [];
        for(let i in action.formulario.gerencia){
            idsClasificacionesGerencias.push(parseInt(action.formulario.gerencia[i]));
        }
        const idsCentrosCostos = [];
        for(let i in action.formulario.centroCosto){
            idsCentrosCostos.push(parseInt(action.formulario.centroCosto[i]))
        }
        const idsServicios = [];
        for(let i in action.formulario.idServicio){
            idsServicios.push(parseInt(action.formulario.idServicio[i]));
        }
        const idsPartidas = [];
        for(let i in action.formulario.partida){
            idsPartidas.push(parseInt(action.formulario.partida[i]));
        }
        const idsTemas = [];
        for(let i in action.formulario.temas){
            idsTemas.push(parseInt(action.formulario.temas[i]));
        }
        const data = {
            "idsClasificacionesGerencias": idsClasificacionesGerencias,
            "idsCentrosCostos": action.formulario.centroCosto ? idsCentrosCostos : null,
            "idsServicios": action.formulario.idServicio ? idsServicios : null,
            "idsPartidas": action.formulario.partida ? idsPartidas : null,
            "idsTemas": action.formulario.temas ? idsTemas : null,
            "proveedor": action.formulario.proveedor ? action.formulario.proveedor : null,
            "idMes": action.formulario.fechaEvaluacion ? parseInt(action.formulario.fechaEvaluacion.format('MM-YYYY').split('-')[0]) : null,
            "anio": action.formulario.fechaEvaluacion ? parseInt(action.formulario.fechaEvaluacion.format('MM-YYYY').split('-')[1]) : null,
            "page": (action.page -1) * 10,
            "offset": 10,
            'especial': action.especial,
            "order": action.order.order,
            "field": action.order.field,
            "gerenciaC": action.checkboxData.gerenciaC ? action.checkboxData.gerenciaC : false,
            "centroC": action.checkboxData.centroC ? action.checkboxData.centroC : false,
            "servicioC": action.checkboxData.servicioC ? action.checkboxData.servicioC : false,
            "partidaC": action.checkboxData.partidaC ? action.checkboxData.partidaC : false,
            "proveedorC": action.checkboxData.proveedorC ? action.checkboxData.proveedorC : false,
            "objetivoC": action.checkboxData.objetivoC ? action.checkboxData.objetivoC : false,
            "estandarC": action.checkboxData.estandarC ? action.checkboxData.estandarC : false,
            "temaC": action.checkboxData.temaC ? action.checkboxData.temaC : false,
            "indicadorC": action.checkboxData.indicadorC ? action.checkboxData.indicadorC : false,
            "valorEsperadoC": action.checkboxData.valorEsperadoC ? action.checkboxData.valorEsperadoC : false,
            "valorRealC": action.checkboxData.valorRealC ? action.checkboxData.valorRealC : false,
            "fechaEvaluacionC": action.checkboxData.fechaEvaluacionC ? action.checkboxData.fechaEvaluacionC : false,
            "tipoAgrupado": action.formulario.tipoAgrupado,
            "orderAgrupado": "DESC",
            "fieldAgrupado": action.formulario.tipoAgrupado === 'Por servicio' ? 'ser.Nombre' : action.formulario.tipoAgrupado === 'Por gerencia' ? 'cg.NombreGerencia' : action.formulario.tipoAgrupado === 'Por centro de costo' ? 'cc.CentroCosto' : action.formulario.tipoAgrupado === 'Por objetivo' ? 'obj.Objetivo' : 'tem.Tema'
        }
        console.log(`data`, data);

        const response = yield call(listaReporteDinamicoAgrupadoCall, token, data)
        console.log(response)

        if(response.status === 200){
            const listaReportesDinamicoAgrupado = response.data.data;
            const listaReportesDinamicoAgrupadoCantidad = response.data.cantidad;

            for(let i in listaReportesDinamicoAgrupado){
                const dataAgrupado = {
                    "idsClasificacionesGerencias": action.formulario.tipoAgrupado === 'Por gerencia' ? [listaReportesDinamicoAgrupado[i].id] : idsClasificacionesGerencias,
                    "idsCentrosCostos": action.formulario.tipoAgrupado === 'Por centro de costo' ? [listaReportesDinamicoAgrupado[i].id] : (action.formulario.centroCosto ? idsCentrosCostos : null),
                    "idsServicios": action.formulario.tipoAgrupado === 'Por servicio' ? [listaReportesDinamicoAgrupado[i].id] : (action.formulario.idServicio ? idsServicios : null),
                    "idsPartidas": action.formulario.partida ? idsPartidas : null,
                    "idsTemas": action.formulario.tipoAgrupado === 'Por tema' ? [listaReportesDinamicoAgrupado[i].id] : (action.formulario.temas ? idsTemas : null),
                    "proveedor": action.formulario.proveedor ? action.formulario.proveedor : null,
                    'idsObjetivos': action.formulario.tipoAgrupado === 'Por objetivo' ? [listaReportesDinamicoAgrupado[i].id] : null,
                    'idsGruposSer': action.formulario.tipoAgrupado === 'Por grupo servicio' ? [listaReportesDinamicoAgrupado[i].id] : null,
                    "idMes": action.formulario.fechaEvaluacion ? parseInt(action.formulario.fechaEvaluacion.format('MM-YYYY').split('-')[0]) : null,
                    "anio": action.formulario.fechaEvaluacion ? parseInt(action.formulario.fechaEvaluacion.format('MM-YYYY').split('-')[1]) : null,
                    "page": 0,
                    "offset": 10,
                    'especial': action.especial,
                    "order": action.order.order,
                    "field": action.order.field,
                    "gerenciaC": action.checkboxData.gerenciaC ? action.checkboxData.gerenciaC : false,
                    "centroC": action.checkboxData.centroC ? action.checkboxData.centroC : false,
                    "servicioC": action.checkboxData.servicioC ? action.checkboxData.servicioC : false,
                    "partidaC": action.checkboxData.partidaC ? action.checkboxData.partidaC : false,
                    "proveedorC": action.checkboxData.proveedorC ? action.checkboxData.proveedorC : false,
                    "objetivoC": action.checkboxData.objetivoC ? action.checkboxData.objetivoC : false,
                    "estandarC": action.checkboxData.estandarC ? action.checkboxData.estandarC : false,
                    "temaC": action.checkboxData.temaC ? action.checkboxData.temaC : false,
                    "indicadorC": action.checkboxData.indicadorC ? action.checkboxData.indicadorC : false,
                    "valorEsperadoC": action.checkboxData.valorEsperadoC ? action.checkboxData.valorEsperadoC : false,
                    "valorRealC": action.checkboxData.valorRealC ? action.checkboxData.valorRealC : false,
                    "fechaEvaluacionC": action.checkboxData.fechaEvaluacionC ? action.checkboxData.fechaEvaluacionC : false,

                }
                console.log(`dataAgrupado`, dataAgrupado);

                const responseAgrupado = yield call(listaReporteDinamicoCall, token, dataAgrupado)
                console.log(`responseAgrupado`, responseAgrupado)
                if(responseAgrupado.status === 200){
                    listaReportesDinamicoAgrupado[i].agrupados = responseAgrupado.data;
                    listaReportesDinamicoAgrupado[i].agrupados.page = 1;
                    for(let x in listaReportesDinamicoAgrupado[i].agrupados.data){
                        listaReportesDinamicoAgrupado[i].agrupados.data[x].id = parseInt(x) + 1;

                    };
                }
                else{
                    const showErrorMsg = true;
                    const textMessage = responseAgrupado.message;

                    yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                    yield put({ type: evaluacionDeLaGestionActions.LISTA_REPORTE_DINAMICO_AGRUPADO_FAILURE });
                }
            }

            yield put({ type: evaluacionDeLaGestionActions.LISTA_REPORTE_DINAMICO_AGRUPADO_SUCCESS, listaReportesDinamicoAgrupado, listaReportesDinamicoAgrupadoCantidad })

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: evaluacionDeLaGestionActions.LISTA_REPORTE_DINAMICO_AGRUPADO_FAILURE });
        }
    } catch (error) {
        yield put({ type: evaluacionDeLaGestionActions.LISTA_REPORTE_DINAMICO_AGRUPADO_FAILURE });
    }
}
export function* listaReporteDinamicoEvaluacionAgrupadoSaga(){
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_REPORTE_DINAMICO_AGRUPADO_REQUEST, listaReporteDinamicoEvaluacionAgrupado)
}

function* exportarReporteDinamicoPDF(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idsClasificacionesGerencias = [];
        for(let i in action.formulario.gerencia){
            idsClasificacionesGerencias.push(parseInt(action.formulario.gerencia[i]));
        }
        const idsCentrosCostos = [];
        for(let i in action.formulario.centroCosto){
            idsCentrosCostos.push(parseInt(action.formulario.centroCosto[i]))
        }
        const idsServicios = [];
        for(let i in action.formulario.idServicio){
            idsServicios.push(parseInt(action.formulario.idServicio[i]));
        }
        const idsPartidas = [];
        for(let i in action.formulario.partida){
            idsPartidas.push(parseInt(action.formulario.partida[i]));
        }
        const idsTemas = [];
        for(let i in action.formulario.temas){
            idsTemas.push(parseInt(action.formulario.temas[i]));
        }
        const data = {
            "idsClasificacionesGerencias": idsClasificacionesGerencias,
            "idsCentrosCostos": action.formulario.centroCosto ? idsCentrosCostos : null,
            "idsServicios": action.formulario.idServicio ? idsServicios : null,
            "idsPartidas": action.formulario.partida ? idsPartidas : null,
            "idsTemas": action.formulario.temas ? idsTemas : null,
            "proveedor": action.formulario.proveedor ? action.formulario.proveedor : null,
            "idMes": action.formulario.fechaEvaluacion ? parseInt(action.formulario.fechaEvaluacion.format('MM-YYYY').split('-')[0]) : null,
            "anio": action.formulario.fechaEvaluacion ? parseInt(action.formulario.fechaEvaluacion.format('MM-YYYY').split('-')[1]) : null,
            "page": null,
            "offset": null,
            'especial': action.especial,
            'idReporteEvaluacion': action.idReporteEvaluacion,
            "order": action.order.order,
            "field": action.order.field,
            "gerenciaC": action.checkbox.gerenciaC ? action.checkbox.gerenciaC : false,
            "centroC": action.checkbox.centroC ? action.checkbox.centroC : false,
            "servicioC": action.checkbox.servicioC ? action.checkbox.servicioC : false,
            "partidaC": action.checkbox.partidaC ? action.checkbox.partidaC : false,
            "proveedorC": action.checkbox.proveedorC ? action.checkbox.proveedorC : false,
            "objetivoC": action.checkbox.objetivoC ? action.checkbox.objetivoC : false,
            "estandarC": action.checkbox.estandarC ? action.checkbox.estandarC : false,
            "temaC": action.checkbox.temaC ? action.checkbox.temaC : false,
            "indicadorC": action.checkbox.indicadorC ? action.checkbox.indicadorC : false,
            "valorEsperadoC": action.checkbox.valorEsperadoC ? action.checkbox.valorEsperadoC : false,
            "valorRealC": action.checkbox.valorRealC ? action.checkbox.valorRealC : false,
            "fechaEvaluacionC": action.checkbox.fechaEvaluacionC ? action.checkbox.fechaEvaluacionC : false,
            "tipoAgrupado": action.agrupado !== 'SinAgrupar' ? action.agrupado : '',
            "orderAgrupado": action.agrupado ? "ASC" : '',
            "fieldAgrupado": action.agrupado === 'Por servicio' ? "ser.Nombre" : action.agrupado === 'Por gerencia' ? 'cg.NombreGerencia' : action.agrupado === 'Por centro de costo' ? 'cc.CentroCosto' : action.agrupado === 'Por grupo servicio' ? 'gsr.Nombre' : action.agrupado === 'Por objetivo' ? 'obj.Objetivo' : action.agrupado === 'Por tema' ? 'tem.Tema' : ''   ,
        }
        console.log(`data`, data);

        const response = yield call(exportarPDFReporteDinamico, token, data)
        console.log(response)

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const titulo = action.tituloDocumento ? action.tituloDocumento : 'Reporte'
            link.download = titulo + new Date().getDate() + "-" + new Date().getMonth() + "-" + new Date().getFullYear() + "_" + new Date().getHours() + new Date().getMinutes() + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({type: evaluacionDeLaGestionActions.EXPORTAR_REPORTE_PDF_SUCCESS})

            yield put({
                type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG,
                showSuccessMsg: true,
                textMessage: 'El archivo se descargó de forma correcta'
            });

        } else {
            const showErrorMsg = true;
            const textMessage = 'No se pudo descargar el PDF con las columnas y filtros seleccionados';

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.EXPORTAR_REPORTE_PDF_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.EXPORTAR_REPORTE_PDF_FAILURE});
    }
}

export function* exportarReporteDinamicoPDFSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.EXPORTAR_REPORTE_PDF_REQUEST, exportarReporteDinamicoPDF)
}

function* exportarReporteDinamicoExcel(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idsClasificacionesGerencias = [];
        for(let i in action.formulario.gerencia){
            idsClasificacionesGerencias.push(parseInt(action.formulario.gerencia[i]));
        }
        const idsCentrosCostos = [];
        for(let i in action.formulario.centroCosto){
            idsCentrosCostos.push(parseInt(action.formulario.centroCosto[i]))
        }
        const idsServicios = [];
        for(let i in action.formulario.idServicio){
            idsServicios.push(parseInt(action.formulario.idServicio[i]));
        }
        const idsPartidas = [];
        for(let i in action.formulario.partida){
            idsPartidas.push(parseInt(action.formulario.partida[i]));
        }
        const idsTemas = [];
        for(let i in action.formulario.temas){
            idsTemas.push(parseInt(action.formulario.temas[i]));
        }
        const data = {
            "idsClasificacionesGerencias": idsClasificacionesGerencias,
            "idsCentrosCostos": action.formulario.centroCosto ? idsCentrosCostos : [],
            "idsServicios": action.formulario.idServicio ? idsServicios : [],
            "idsPartidas": action.formulario.partida ? idsPartidas : [],
            "idsTemas": action.formulario.temas ? idsTemas : [],
            "proveedor": action.formulario.proveedor ? action.formulario.proveedor : null,
            "idMes": action.formulario.fechaEvaluacion ? parseInt(action.formulario.fechaEvaluacion.format('MM-YYYY').split('-')[0]) : null,
            "anio": action.formulario.fechaEvaluacion ? parseInt(action.formulario.fechaEvaluacion.format('MM-YYYY').split('-')[1]) : null,
            "page": null,
            "offset": null,
            'especial': action.especial,
            'idReporteEvaluacion': action.idReporteEvaluacion,
            "order": action.order.order,
            "field": action.order.field,
            "gerenciaC": action.checkbox.gerenciaC ? action.checkbox.gerenciaC : false,
            "centroC": action.checkbox.centroC ? action.checkbox.centroC : false,
            "servicioC": action.checkbox.servicioC ? action.checkbox.servicioC : false,
            "partidaC": action.checkbox.partidaC ? action.checkbox.partidaC : false,
            "proveedorC": action.checkbox.proveedorC ? action.checkbox.proveedorC : false,
            "objetivoC": action.checkbox.objetivoC ? action.checkbox.objetivoC : false,
            "estandarC": action.checkbox.estandarC ? action.checkbox.estandarC : false,
            "temaC": action.checkbox.temaC ? action.checkbox.temaC : false,
            "indicadorC": action.checkbox.indicadorC ? action.checkbox.indicadorC : false,
            "valorEsperadoC": action.checkbox.valorEsperadoC ? action.checkbox.valorEsperadoC : false,
            "valorRealC": action.checkbox.valorRealC ? action.checkbox.valorRealC : false,
            "fechaEvaluacionC": action.checkbox.fechaEvaluacionC ? action.checkbox.fechaEvaluacionC : false,
            "tipoAgrupado": action.agrupado !== 'SinAgrupar' ? action.agrupado : '',
            "orderAgrupado": action.agrupado ? "ASC" : '',
            "fieldAgrupado": action.agrupado === 'Por servicio' ? "ser.Nombre" : action.agrupado === 'Por gerencia' ? 'cg.NombreGerencia' : action.agrupado === 'Por centro de costo' ? 'cc.CentroCosto' : action.agrupado === 'Por grupo servicio' ? 'gsr.Nombre' : action.agrupado === 'Por objetivo' ? 'obj.Objetivo' : action.agrupado === 'Por tema' ? 'tem.Tema' : ''   ,
        }
        console.log(`data`, data);

        const response = yield call(exportarExcelReporteDinamico, token, data)
        console.log(response)

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const titulo = action.tituloDocumento ? action.tituloDocumento : 'Reporte'
            link.download = titulo + new Date().getDate() + "-" + new Date().getMonth() + "-" + new Date().getFullYear() + "_" + new Date().getHours() + new Date().getMinutes() + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({type: evaluacionDeLaGestionActions.EXPORTAR_REPORTE_EXCEL_SUCCESS});

            yield put({
                type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG,
                showSuccessMsg: true,
                textMessage: 'El archivo se descargó de forma correcta'
            });

        } else {
            const showErrorMsg = true;
            const textMessage = 'No se pudo descargar el PDF con las columnas y filtros seleccionados';

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.EXPORTAR_REPORTE_EXCEL_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.EXPORTAR_REPORTE_EXCEL_FAILURE});
    }
}

export function* exportarReporteDinamicoExcelSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.EXPORTAR_REPORTE_EXCEL_REQUEST, exportarReporteDinamicoExcel)
}

function* listaRegistrosReporte(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;

        const response = yield call(listaRegistrosReporteCall, token, page)
        console.log(response)

        if (response.status === 200) {
            const listaRegistrosReporte = response.data.data;
            const listaRegistrosReporteCantidad = response.data.cantidad;
            for (let i in listaRegistrosReporte) {
                listaRegistrosReporte[i].formulario = {
                    fechaEvaluacion: undefined,
                    gerencia: undefined,
                    centroCosto: undefined,
                    idServicio: undefined,
                    partida: undefined,
                    temas: undefined,
                    proveedor: undefined
                }
                listaRegistrosReporte[i].checkbox = {
                    servicioC: false,
                    gerenciaC: false,
                    centroC: false,
                    partidaC: false,
                    fechaEvaluacionC: false,
                    proveedorC: false,
                    objetivoC: false,
                    temaC: false,
                    indicadorC: false,
                    valorEsperadoC: false,
                    valorRealC: false,
                }
                listaRegistrosReporte[i].orden = {
                    field: 'ser.Nombre',
                    order: 'ASC'
                }
                listaRegistrosReporte[i].valoresColumnasSplit = listaRegistrosReporte[i].valoresColumnas.split(',');
                for (let x in listaRegistrosReporte[i].valoresColumnasSplit) {
                    listaRegistrosReporte[i].valoresColumnasSplit[x] = listaRegistrosReporte[i].valoresColumnasSplit[x].charAt(0).toLowerCase() + listaRegistrosReporte[i].valoresColumnasSplit[x].slice(1);
                    listaRegistrosReporte[i].checkbox[listaRegistrosReporte[i].valoresColumnasSplit[x]] = true
                }
                for (let x in listaRegistrosReporte[i].filtrosByIdReporteEvaluacion) {

                    if (listaRegistrosReporte[i].filtrosByIdReporteEvaluacion[x].filtro === 'IdMes') {
                        let fecha = listaRegistrosReporte[i].filtrosByIdReporteEvaluacion[x].valor + '-';
                        for (let y in listaRegistrosReporte[i].filtrosByIdReporteEvaluacion) {
                            if (listaRegistrosReporte[i].filtrosByIdReporteEvaluacion[y].filtro === 'Anio') {
                                fecha += listaRegistrosReporte[i].filtrosByIdReporteEvaluacion[y].valor;
                            }
                        }
                        listaRegistrosReporte[i].formulario.fechaEvaluacion = moment(fecha, 'MM-YYYY');
                    }
                    listaRegistrosReporte[i].formulario.gerencia = listaRegistrosReporte[i].filtrosByIdReporteEvaluacion[x].filtro === 'IdClasificacionGerencia' ? listaRegistrosReporte[i].filtrosByIdReporteEvaluacion[x].valor.split(',') : listaRegistrosReporte[i].formulario.gerencia;
                    listaRegistrosReporte[i].formulario.centroCosto = listaRegistrosReporte[i].filtrosByIdReporteEvaluacion[x].filtro === 'IdCentrosCostos' ? listaRegistrosReporte[i].filtrosByIdReporteEvaluacion[x].valor.split(',') : listaRegistrosReporte[i].formulario.centroCosto;
                    listaRegistrosReporte[i].formulario.idServicio = listaRegistrosReporte[i].filtrosByIdReporteEvaluacion[x].filtro === 'IdServicio' ? listaRegistrosReporte[i].filtrosByIdReporteEvaluacion[x].valor.split(',') : listaRegistrosReporte[i].formulario.idServicio;
                    listaRegistrosReporte[i].formulario.partida = listaRegistrosReporte[i].filtrosByIdReporteEvaluacion[x].filtro === 'IdPartida' ? listaRegistrosReporte[i].filtrosByIdReporteEvaluacion[x].valor.split(',') : listaRegistrosReporte[i].formulario.partida;
                    listaRegistrosReporte[i].formulario.temas = listaRegistrosReporte[i].filtrosByIdReporteEvaluacion[x].filtro === 'IdTemas' ? listaRegistrosReporte[i].filtrosByIdReporteEvaluacion[x].valor.split(',') : listaRegistrosReporte[i].formulario.temas;
                    listaRegistrosReporte[i].formulario.proveedor = listaRegistrosReporte[i].filtrosByIdReporteEvaluacion[x].filtro === 'Proveedor' ? listaRegistrosReporte[i].filtrosByIdReporteEvaluacion[x].valor : listaRegistrosReporte[i].formulario.proveedor;
                    listaRegistrosReporte[i].orden.field = listaRegistrosReporte[i].filtrosByIdReporteEvaluacion[x].filtro === 'Field' ? listaRegistrosReporte[i].filtrosByIdReporteEvaluacion[x].valor : listaRegistrosReporte[i].orden.field;
                    listaRegistrosReporte[i].orden.order = listaRegistrosReporte[i].filtrosByIdReporteEvaluacion[x].filtro === 'Order' ? listaRegistrosReporte[i].filtrosByIdReporteEvaluacion[x].valor : listaRegistrosReporte[i].orden.order;
                    listaRegistrosReporte[i].agrupado = listaRegistrosReporte[i].filtrosByIdReporteEvaluacion[x].filtro === 'TipoAgrupado' ? true : listaRegistrosReporte[i].agrupado;
                }
                listaRegistrosReporte[i].checkboxData = Object.entries(listaRegistrosReporte[i].checkbox);
                let checkAll = true;
                listaRegistrosReporte[i].listaFields = [];
                for (let x in listaRegistrosReporte[i].checkboxData) {
                    if (!listaRegistrosReporte[i].checkboxData[x][1]) {
                        checkAll = false
                    }
                    listaRegistrosReporte[i][listaRegistrosReporte[i].checkboxData[x][0]] = listaRegistrosReporte[i].checkboxData[x][1];
                    listaRegistrosReporte[i].checkboxData[x].push(
                        listaRegistrosReporte[i].checkboxData[x][0] === 'centroC' ? 'Centro de costos' : (
                            listaRegistrosReporte[i].checkboxData[x][0] === 'fechaEvaluacionC' ? 'Mes evaluado' : (
                                listaRegistrosReporte[i].checkboxData[x][0] === 'gerenciaC' ? 'Gerencia' : (
                                    listaRegistrosReporte[i].checkboxData[x][0] === 'indicadorC' ? 'Indicador de desempeño' : (
                                        listaRegistrosReporte[i].checkboxData[x][0] === 'objetivoC' ? 'Objetivo' : (
                                            listaRegistrosReporte[i].checkboxData[x][0] === 'temaC' ? 'Temas' : (
                                                listaRegistrosReporte[i].checkboxData[x][0] === 'partidaC' ? 'Partida' : (
                                                    listaRegistrosReporte[i].checkboxData[x][0] === 'proveedorC' ? 'Provedor' : (
                                                        listaRegistrosReporte[i].checkboxData[x][0] === 'servicioC' ? 'Servicio' : (
                                                            listaRegistrosReporte[i].checkboxData[x][0] === 'valorEsperadoC' ? 'Valor esperado' : 'Valor real'
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                    if (listaRegistrosReporte[i].checkboxData[x][0] !== 'fechaEvaluacionC') {
                        listaRegistrosReporte[i].listaFields.push(
                            listaRegistrosReporte[i].checkboxData[x][0] === 'servicioC' ? {
                                'campo': 'ser.Nombre',
                                'mostrar': listaRegistrosReporte[i].checkboxData[x][1],
                                'nombre': 'Servicio',
                                'key': 'servicioC'
                            } : (
                                listaRegistrosReporte[i].checkboxData[x][0] === 'gerenciaC' ? {
                                    'campo': 'cg.NombreGerencia',
                                    'mostrar': listaRegistrosReporte[i].checkboxData[x][1],
                                    'nombre': 'Gerencia',
                                    'key': 'gerenciaC'
                                } : (
                                    listaRegistrosReporte[i].checkboxData[x][0] === 'centroC' ? {
                                        'campo': 'cc.CentroCosto',
                                        'mostrar': listaRegistrosReporte[i].checkboxData[x][1],
                                        'nombre': 'Centro de costos',
                                        'key': 'centroC'
                                    } : (
                                        listaRegistrosReporte[i].checkboxData[x][0] === 'partidaC' ? {
                                            'campo': 'part.Nombre',
                                            'mostrar': listaRegistrosReporte[i].checkboxData[x][1],
                                            'nombre': 'Partida',
                                            'key': 'partidaC'
                                        } : (
                                            listaRegistrosReporte[i].checkboxData[x][0] === 'proveedorC' ? {
                                                'campo': 'pro.Nombre',
                                                'mostrar': listaRegistrosReporte[i].checkboxData[x][1],
                                                'nombre': 'Proveedor',
                                                'key': 'proveedorC'
                                            } : (
                                                listaRegistrosReporte[i].checkboxData[x][0] === 'objetivoC' ? {
                                                    'campo': 'obj.Objetivo',
                                                    'mostrar': listaRegistrosReporte[i].checkboxData[x][1],
                                                    'nombre': 'Objetivo',
                                                    'key': 'objetivoC'
                                                } : (
                                                    listaRegistrosReporte[i].checkboxData[x][0] === 'temaC' ? {
                                                        'campo': 'tem.Tema',
                                                        'mostrar': listaRegistrosReporte[i].checkboxData[x][1],
                                                        'nombre': 'Temas',
                                                        'key': 'temaC'
                                                    } : (
                                                        listaRegistrosReporte[i].checkboxData[x][0] === 'indicadorC' ? {
                                                            'campo': 'id.IndicadorDesempeño',
                                                            'mostrar': listaRegistrosReporte[i].checkboxData[x][1],
                                                            'nombre': 'Indicador de desempeño',
                                                            'key': 'indicadorC'
                                                        } : (
                                                            listaRegistrosReporte[i].checkboxData[x][0] === 'valorEsperadoC' ? {
                                                                'campo': 'id.ValorEsperado',
                                                                'mostrar': listaRegistrosReporte[i].checkboxData[x][1],
                                                                'nombre': 'Valor esperado',
                                                                'key': 'valorEsperadoC'
                                                            } : (
                                                                listaRegistrosReporte[i].checkboxData[x][0] === 'valorRealC' ? {
                                                                    'campo': 'ei.ValorReal',
                                                                    'mostrar': listaRegistrosReporte[i].checkboxData[x][1],
                                                                    'nombre': 'Valor real',
                                                                    'key': 'valorRealC'
                                                                } : false
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    }
                }
                listaRegistrosReporte[i].checkAll = checkAll;
                listaRegistrosReporte[i].servicio = listaRegistrosReporte[i].formulario.idServicio;
                listaRegistrosReporte[i].gerencia = listaRegistrosReporte[i].formulario.gerencia;
                listaRegistrosReporte[i].centroCosto = listaRegistrosReporte[i].formulario.centroCosto;
                listaRegistrosReporte[i].partida = listaRegistrosReporte[i].formulario.partida;
                listaRegistrosReporte[i].temas = listaRegistrosReporte[i].formulario.temas;
                listaRegistrosReporte[i].proveedor = listaRegistrosReporte[i].formulario.proveedor;
                listaRegistrosReporte[i].fechaEvaluacion = listaRegistrosReporte[i].formulario.fechaEvaluacion;
                listaRegistrosReporte[i].field = listaRegistrosReporte[i].orden.field;
                listaRegistrosReporte[i].order = listaRegistrosReporte[i].orden.order;
                listaRegistrosReporte[i].usuario = listaRegistrosReporte[i].usuarioByIdUsuario.nombre + ' ' + listaRegistrosReporte[i].usuarioByIdUsuario.aPaterno + (listaRegistrosReporte[i].usuarioByIdUsuario.aMaterno ? (' ' + listaRegistrosReporte[i].usuarioByIdUsuario.aMaterno) : '');
            }

            yield put({
                type: evaluacionDeLaGestionActions.LISTA_REGISTRO_REPORTE_SUCCESS,
                listaRegistrosReporte,
                listaRegistrosReporteCantidad
            })

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.LISTA_REPORTE_DINAMICO_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.LISTA_REGISTRO_REPORTE_FAILURE});
    }
}

export function* listaRegistrosReporteSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_REGISTRO_REPORTE_REQUEST, listaRegistrosReporte)
}

function* listaRegistrosEspecialReporte(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;

        const response = yield call(listaRegistrosEspecialReporteCall, token, page)
        console.log(response)

        if (response.status === 200) {
            const listaRegistrosEspecialReporte = response.data.data;
            const listaRegistrosEspecialReporteCantidad = response.data.cantidad;
            for (let i in listaRegistrosEspecialReporte) {
                listaRegistrosEspecialReporte[i].formulario = {
                    fechaEvaluacion: undefined,
                    gerencia: undefined,
                    centroCosto: undefined,
                    idServicio: undefined,
                    partida: undefined,
                    temas: undefined,
                    proveedor: undefined
                }
                listaRegistrosEspecialReporte[i].checkbox = {
                    servicioC: false,
                    gerenciaC: false,
                    centroC: false,
                    partidaC: false,
                    fechaEvaluacionC: false,
                    proveedorC: false,
                    objetivoC: false,
                    temaC: false,
                    indicadorC: false,
                    valorEsperadoC: false,
                    valorRealC: false,
                }
                listaRegistrosEspecialReporte[i].orden = {
                    field: 'ser.Nombre',
                    order: 'ASC'
                }
                listaRegistrosEspecialReporte[i].agrupado = false;
                listaRegistrosEspecialReporte[i].valoresColumnasSplit = listaRegistrosEspecialReporte[i].valoresColumnas.split(',');
                for (let x in listaRegistrosEspecialReporte[i].valoresColumnasSplit) {
                    listaRegistrosEspecialReporte[i].valoresColumnasSplit[x] = listaRegistrosEspecialReporte[i].valoresColumnasSplit[x].charAt(0).toLowerCase() + listaRegistrosEspecialReporte[i].valoresColumnasSplit[x].slice(1);
                    listaRegistrosEspecialReporte[i].checkbox[listaRegistrosEspecialReporte[i].valoresColumnasSplit[x]] = true
                }
                for (let x in listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion) {

                    if (listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion[x].filtro === 'IdMes') {
                        let fecha = listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion[x].valor + '-';
                        for (let y in listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion) {
                            if (listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion[y].filtro === 'Anio') {
                                fecha += listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion[y].valor;
                            }
                        }
                        listaRegistrosEspecialReporte[i].formulario.fechaEvaluacion = moment(fecha, 'MM-YYYY');
                    }
                    listaRegistrosEspecialReporte[i].formulario.gerencia = listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion[x].filtro === 'IdClasificacionGerencia' ? listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion[x].valor.split(',') : listaRegistrosEspecialReporte[i].formulario.gerencia;
                    listaRegistrosEspecialReporte[i].formulario.centroCosto = listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion[x].filtro === 'IdCentrosCostos' ? listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion[x].valor.split(',') : listaRegistrosEspecialReporte[i].formulario.centroCosto;
                    listaRegistrosEspecialReporte[i].formulario.idServicio = listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion[x].filtro === 'IdServicio' ? listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion[x].valor.split(',') : listaRegistrosEspecialReporte[i].formulario.idServicio;
                    listaRegistrosEspecialReporte[i].formulario.partida = listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion[x].filtro === 'IdPartida' ? listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion[x].valor.split(',') : listaRegistrosEspecialReporte[i].formulario.partida;
                    listaRegistrosEspecialReporte[i].formulario.temas = listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion[x].filtro === 'IdTemas' ? listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion[x].valor.split(',') : listaRegistrosEspecialReporte[i].formulario.temas;
                    listaRegistrosEspecialReporte[i].formulario.proveedor = listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion[x].filtro === 'Proveedor' ? listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion[x].valor : listaRegistrosEspecialReporte[i].formulario.proveedor;
                    listaRegistrosEspecialReporte[i].orden.field = listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion[x].filtro === 'Field' ? listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion[x].valor : listaRegistrosEspecialReporte[i].orden.field;
                    listaRegistrosEspecialReporte[i].orden.order = listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion[x].filtro === 'Order' ? listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion[x].valor : listaRegistrosEspecialReporte[i].orden.order;
                    listaRegistrosEspecialReporte[i].agrupado = listaRegistrosEspecialReporte[i].filtrosByIdReporteEvaluacion[x].filtro === 'TipoAgrupado' ? true : listaRegistrosEspecialReporte[i].agrupado;
                }
                listaRegistrosEspecialReporte[i].checkboxData = Object.entries(listaRegistrosEspecialReporte[i].checkbox);
                let checkAll = true;
                listaRegistrosEspecialReporte[i].listaFields = [];
                for (let x in listaRegistrosEspecialReporte[i].checkboxData) {
                    if (!listaRegistrosEspecialReporte[i].checkboxData[x][1]) {
                        checkAll = false
                    }
                    listaRegistrosEspecialReporte[i][listaRegistrosEspecialReporte[i].checkboxData[x][0]] = listaRegistrosEspecialReporte[i].checkboxData[x][1];
                    listaRegistrosEspecialReporte[i].checkboxData[x].push(
                        listaRegistrosEspecialReporte[i].checkboxData[x][0] === 'centroC' ? 'Centro de costos' : (
                            listaRegistrosEspecialReporte[i].checkboxData[x][0] === 'fechaEvaluacionC' ? 'Mes evaluado' : (
                                listaRegistrosEspecialReporte[i].checkboxData[x][0] === 'gerenciaC' ? 'Gerencia' : (
                                    listaRegistrosEspecialReporte[i].checkboxData[x][0] === 'indicadorC' ? 'Indicador de desempeño' : (
                                        listaRegistrosEspecialReporte[i].checkboxData[x][0] === 'objetivoC' ? 'Objetivo' : (
                                            listaRegistrosEspecialReporte[i].checkboxData[x][0] === 'temaC' ? 'Temas' : (
                                                listaRegistrosEspecialReporte[i].checkboxData[x][0] === 'partidaC' ? 'Partida' : (
                                                    listaRegistrosEspecialReporte[i].checkboxData[x][0] === 'proveedorC' ? 'Provedor' : (
                                                        listaRegistrosEspecialReporte[i].checkboxData[x][0] === 'servicioC' ? 'Servicio' : (
                                                            listaRegistrosEspecialReporte[i].checkboxData[x][0] === 'valorEsperadoC' ? 'Valor esperado' : 'Valor real'
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                    if (listaRegistrosEspecialReporte[i].checkboxData[x][0] !== 'fechaEvaluacionC') {
                        listaRegistrosEspecialReporte[i].listaFields.push(
                            listaRegistrosEspecialReporte[i].checkboxData[x][0] === 'servicioC' ? {
                                'campo': 'ser.Nombre',
                                'mostrar': listaRegistrosEspecialReporte[i].checkboxData[x][1],
                                'nombre': 'Servicio',
                                'key': 'servicioC'
                            } : (
                                listaRegistrosEspecialReporte[i].checkboxData[x][0] === 'gerenciaC' ? {
                                    'campo': 'cg.NombreGerencia',
                                    'mostrar': listaRegistrosEspecialReporte[i].checkboxData[x][1],
                                    'nombre': 'Gerencia',
                                    'key': 'gerenciaC'
                                } : (
                                    listaRegistrosEspecialReporte[i].checkboxData[x][0] === 'centroC' ? {
                                        'campo': 'cc.CentroCosto',
                                        'mostrar': listaRegistrosEspecialReporte[i].checkboxData[x][1],
                                        'nombre': 'Centro de costos',
                                        'key': 'centroC'
                                    } : (
                                        listaRegistrosEspecialReporte[i].checkboxData[x][0] === 'partidaC' ? {
                                            'campo': 'part.Nombre',
                                            'mostrar': listaRegistrosEspecialReporte[i].checkboxData[x][1],
                                            'nombre': 'Partida',
                                            'key': 'partidaC'
                                        } : (
                                            listaRegistrosEspecialReporte[i].checkboxData[x][0] === 'proveedorC' ? {
                                                'campo': 'pro.Nombre',
                                                'mostrar': listaRegistrosEspecialReporte[i].checkboxData[x][1],
                                                'nombre': 'Proveedor',
                                                'key': 'proveedorC'
                                            } : (
                                                listaRegistrosEspecialReporte[i].checkboxData[x][0] === 'objetivoC' ? {
                                                    'campo': 'obj.Objetivo',
                                                    'mostrar': listaRegistrosEspecialReporte[i].checkboxData[x][1],
                                                    'nombre': 'Objetivo',
                                                    'key': 'objetivoC'
                                                } : (
                                                    listaRegistrosEspecialReporte[i].checkboxData[x][0] === 'temaC' ? {
                                                        'campo': 'tem.Tema',
                                                        'mostrar': listaRegistrosEspecialReporte[i].checkboxData[x][1],
                                                        'nombre': 'Temas',
                                                        'key': 'temaC'
                                                    } : (
                                                        listaRegistrosEspecialReporte[i].checkboxData[x][0] === 'indicadorC' ? {
                                                            'campo': 'id.IndicadorDesempeño',
                                                            'mostrar': listaRegistrosEspecialReporte[i].checkboxData[x][1],
                                                            'nombre': 'Indicador de desempeño',
                                                            'key': 'indicadorC'
                                                        } : (
                                                            listaRegistrosEspecialReporte[i].checkboxData[x][0] === 'valorEsperadoC' ? {
                                                                'campo': 'id.ValorEsperado',
                                                                'mostrar': listaRegistrosEspecialReporte[i].checkboxData[x][1],
                                                                'nombre': 'Valor esperado',
                                                                'key': 'valorEsperadoC'
                                                            } : (
                                                                listaRegistrosEspecialReporte[i].checkboxData[x][0] === 'valorRealC' ? {
                                                                    'campo': 'ei.ValorReal',
                                                                    'mostrar': listaRegistrosEspecialReporte[i].checkboxData[x][1],
                                                                    'nombre': 'Valor real',
                                                                    'key': 'valorRealC'
                                                                } : false
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    }
                }
                listaRegistrosEspecialReporte[i].checkAll = checkAll;
                listaRegistrosEspecialReporte[i].servicio = listaRegistrosEspecialReporte[i].formulario.idServicio;
                listaRegistrosEspecialReporte[i].gerencia = listaRegistrosEspecialReporte[i].formulario.gerencia;
                listaRegistrosEspecialReporte[i].centroCosto = listaRegistrosEspecialReporte[i].formulario.centroCosto;
                listaRegistrosEspecialReporte[i].partida = listaRegistrosEspecialReporte[i].formulario.partida;
                listaRegistrosEspecialReporte[i].temas = listaRegistrosEspecialReporte[i].formulario.temas;
                listaRegistrosEspecialReporte[i].proveedor = listaRegistrosEspecialReporte[i].formulario.proveedor;
                listaRegistrosEspecialReporte[i].fechaEvaluacion = listaRegistrosEspecialReporte[i].formulario.fechaEvaluacion;
                listaRegistrosEspecialReporte[i].field = listaRegistrosEspecialReporte[i].orden.field;
                listaRegistrosEspecialReporte[i].order = listaRegistrosEspecialReporte[i].orden.order;
                listaRegistrosEspecialReporte[i].usuario = listaRegistrosEspecialReporte[i].usuarioByIdUsuario.nombre + ' ' + listaRegistrosEspecialReporte[i].usuarioByIdUsuario.aPaterno + (listaRegistrosEspecialReporte[i].usuarioByIdUsuario.aMaterno ? (' ' + listaRegistrosEspecialReporte[i].usuarioByIdUsuario.aMaterno) : '');
            }

            yield put({
                type: evaluacionDeLaGestionActions.LISTA_REGISTRO_ESPECIAL_REPORTE_SUCCESS,
                listaRegistrosEspecialReporte,
                listaRegistrosEspecialReporteCantidad
            })

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.LISTA_REGISTRO_ESPECIAL_REPORTE_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.LISTA_REGISTRO_ESPECIAL_REPORTE_FAILURE});
    }
}

export function* listaRegistrosEspecialReporteSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_REGISTRO_ESPECIAL_REPORTE_REQUEST, listaRegistrosEspecialReporte)
}

function* estadoRegistroReporte(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idReporteEvaluacion = action.idReporteEvaluacion;

        const response = yield call(estadoRegistroReporteCall, token, idReporteEvaluacion);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El estado del reporte de evaluación cambió de forma correcta";

            yield put({type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage});

            if (action.personal) {
                yield put({
                    type: evaluacionDeLaGestionActions.LISTA_REGISTRO_ESPECIAL_REPORTE_REQUEST,
                    page: action.page
                });
            } else {
                yield put({type: evaluacionDeLaGestionActions.LISTA_REGISTRO_REPORTE_REQUEST, page: action.page});
            }

            yield put({type: evaluacionDeLaGestionActions.LISTA_CLASIFICACION_ACTIVOS_REQUEST});

            yield put({type: evaluacionDeLaGestionActions.ESTADO_REGISTRO_REPORTE_SUCCESS});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});

            yield put({type: evaluacionDeLaGestionActions.ESTADO_REGISTRO_REPORTE_FAILURE});
        }

    } catch (error) {
        console.log(error);
        yield put({type: evaluacionDeLaGestionActions.ESTADO_REGISTRO_REPORTE_FAILURE});
    }
}

export function* estadoRegistroReporteSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.ESTADO_REGISTRO_REPORTE_REQUEST, estadoRegistroReporte);
}

function* crearRegistroReporte(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const data = {
            "evaluacionesCalidadDinamicoRequest": {
                "idsClasificacionesGerencias": action.busqueda.formularioReporte.gerencia,
                "idsCentrosCostos": action.busqueda.formularioReporte.centroCosto,
                "idsServicios": action.busqueda.formularioReporte.idServicio,
                "idsPartidas": action.busqueda.formularioReporte.partida ? action.busqueda.formularioReporte.partida : null,
                "idsTemas": action.busqueda.formularioReporte.temas ? action.busqueda.formularioReporte.temas : null,
                "proveedor": action.busqueda.formularioReporte.proveedor ? action.busqueda.formularioReporte.proveedor : null,
                "idMes": action.busqueda.formularioReporte.fechaEvaluacion ? parseInt(action.busqueda.formularioReporte.fechaEvaluacion.format('MM-YYYY').split('-')[0]) : null,
                "anio": action.busqueda.formularioReporte.fechaEvaluacion ? parseInt(action.busqueda.formularioReporte.fechaEvaluacion.format('MM-YYYY').split('-')[1]) : null,
                "page": null,
                "offset": null,
                'especial': null,
                'idReporteEvaluacion': null,
                "order": action.busqueda.order.order,
                "field": action.busqueda.order.field,
                "gerenciaC": action.busqueda.checkbox.gerenciaC ? action.busqueda.checkbox.gerenciaC : false,
                "centroC": action.busqueda.checkbox.centroC ? action.busqueda.checkbox.centroC : false,
                "servicioC": action.busqueda.checkbox.servicioC ? action.busqueda.checkbox.servicioC : false,
                "partidaC": action.busqueda.checkbox.partidaC ? action.busqueda.checkbox.partidaC : false,
                "proveedorC": action.busqueda.checkbox.proveedorC ? action.busqueda.checkbox.proveedorC : false,
                "objetivoC": action.busqueda.checkbox.objetivoC ? action.busqueda.checkbox.objetivoC : false,
                "temaC": action.busqueda.checkbox.temaC ? action.busqueda.checkbox.temaC : false,
                "estandarC": action.busqueda.checkbox.estandarC ? action.busqueda.checkbox.estandarC : false,
                "indicadorC": action.busqueda.checkbox.indicadorC ? action.busqueda.checkbox.indicadorC : false,
                "valorEsperadoC": action.busqueda.checkbox.valorEsperadoC ? action.busqueda.checkbox.valorEsperadoC : false,
                "valorRealC": action.busqueda.checkbox.valorRealC ? action.busqueda.checkbox.valorRealC : false,
                "fechaEvaluacionC": action.busqueda.checkbox.fechaEvaluacionC ? action.busqueda.checkbox.fechaEvaluacionC : false,
                "tipoAgrupado": action.busqueda.agrupado ? "Por servicio" : '',
                "orderAgrupado": action.busqueda.agrupado ? "ASC" : '',
                "fieldAgrupado": action.busqueda.agrupado ? "ser.Nombre" : '',
            },
            "reportesEvaluacion": {
                "idReporteEvaluacion": null,
                'titulo': action.formulario.titulo,
                "nombre": action.formulario.nombre,
                "descripcion": action.formulario.descripcion ? action.formulario.descripcion : '',
                "idUsuario": null,
                "valoresColumnas": "",
                "fechaAlta": null,
                "fechaActualizacion": null,
                "activo": true
            }
        }
        console.log(`data`, data);

        const response = yield call(crearRegistroReporteCall, token, data)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se creó el reporte predeterminado de manera exitosa";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: evaluacionDeLaGestionActions.MODAL_NUEVO_REGISTRO_REPORTE, showModalNuevoRegistroReporte: false, busqueda: action.busqueda });
            if(action.data.tipoDocumento === 'pdf'){
                yield put({ type: evaluacionDeLaGestionActions.EXPORTAR_REPORTE_PDF_REQUEST, formulario: action.data.formulario, checkbox: action.data.checkbox, order: action.data.order, tituloDocumento: action.formulario.titulo, idReporteEvaluacion: response.data.id, especial: action.especial, agrupado: action.busqueda.agrupado });
            }
            else{
                yield put({ type: evaluacionDeLaGestionActions.EXPORTAR_REPORTE_EXCEL_REQUEST, formulario: action.data.formulario, checkbox: action.data.checkbox, order: action.data.order, tituloDocumento: action.formulario.titulo, idReporteEvaluacion: response.data.id, especial: action.especial, agrupado: action.busqueda.agrupado });
            }

            yield put({
                type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO,
                limpiarFormulario: 'crearRegistroReporte'
            });

            yield put({type: evaluacionDeLaGestionActions.NUEVO_REGISTRO_REPORTE_SUCCESS});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.NUEVO_REGISTRO_REPORTE_FAILURE})
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.NUEVO_REGISTRO_REPORTE_FAILURE})
    }
}

export function* crearRegistroReporteSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.NUEVO_REGISTRO_REPORTE_REQUEST, crearRegistroReporte)
}

function* editarRegistroReporte(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idReporteEvaluacion = action.registroReporteSeleccionado.idReporteEvaluacion

        const data = {
            "evaluacionesCalidadDinamicoRequest": {
                "idsClasificacionesGerencias": action.formulario.gerencia,
                "idsCentrosCostos": action.formulario.centroCosto,
                "idsServicios": action.formulario.servicio,
                "idsPartidas": action.formulario.partida ? action.formulario.partida : null,
                "idsTemas": action.formulario.temas ? action.formulario.temas : null,
                "proveedor": action.formulario.proveedor ? action.formulario.proveedor : null,
                "idMes": action.formulario.fechaEvaluacion ? parseInt(action.formulario.fechaEvaluacion.format('MM-YYYY').split('-')[0]) : null,
                "anio": action.formulario.fechaEvaluacion ? parseInt(action.formulario.fechaEvaluacion.format('MM-YYYY').split('-')[1]) : null,
                "page": null,
                "offset": null,
                'especial': null,
                'idReporteEvaluacion': action.registroReporteSeleccionado.idReporteEvaluacion,
                "order": action.formulario.order,
                "field": action.formulario.field,
                "gerenciaC": action.registroReporteSeleccionado.gerenciaC ? action.registroReporteSeleccionado.gerenciaC : false,
                "centroC": action.registroReporteSeleccionado.centroC ? action.registroReporteSeleccionado.centroC : false,
                "servicioC": action.registroReporteSeleccionado.servicioC ? action.registroReporteSeleccionado.servicioC : false,
                "partidaC": action.registroReporteSeleccionado.partidaC ? action.registroReporteSeleccionado.partidaC : false,
                "proveedorC": action.registroReporteSeleccionado.proveedorC ? action.registroReporteSeleccionado.proveedorC : false,
                "objetivoC": action.registroReporteSeleccionado.objetivoC ? action.registroReporteSeleccionado.objetivoC : false,
                "temaC": action.registroReporteSeleccionado.temaC ? action.registroReporteSeleccionado.temaC : false,
                "estandarC": action.registroReporteSeleccionado.estandarC ? action.registroReporteSeleccionado.estandarC : false,
                "indicadorC": action.registroReporteSeleccionado.indicadorC ? action.registroReporteSeleccionado.indicadorC : false,
                "valorEsperadoC": action.registroReporteSeleccionado.valorEsperadoC ? action.registroReporteSeleccionado.valorEsperadoC : false,
                "valorRealC": action.registroReporteSeleccionado.valorRealC ? action.registroReporteSeleccionado.valorRealC : false,
                "fechaEvaluacionC": action.registroReporteSeleccionado.fechaEvaluacionC ? action.registroReporteSeleccionado.fechaEvaluacionC : false,
                "tipoAgrupado": action.formulario.agrupado ? "Por servicio" : '',
                "orderAgrupado": action.formulario.agrupado ? "ASC" : '',
                "fieldAgrupado": action.formulario.agrupado ? "ser.Nombre" : '',
            },
            "reportesEvaluacion": {
                "idReporteEvaluacion": idReporteEvaluacion,
                'titulo': action.formulario.titulo,
                "nombre": action.formulario.nombre,
                "descripcion": action.formulario.descripcion ? action.formulario.descripcion : '',
                "idUsuario": action.registroReporteSeleccionado.idUsuario,
                "valoresColumnas": '',
                "fechaAlta": action.registroReporteSeleccionado.fechaAlta,
                "fechaActualizacion": action.registroReporteSeleccionado.fechaActualizacion,
                "activo": action.registroReporteSeleccionado.activo
            }
        }
        console.log(`data`, data);

        const response = yield call(editarRegistroReporteCall, token, data, idReporteEvaluacion)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se editó el reporte predeterminado de manera exitosa";

            if(!action.personal){
                yield put({ type: evaluacionDeLaGestionActions.LISTA_REGISTRO_REPORTE_REQUEST, page: action.page, personal: action.personal });
            }
            else{
                yield put({ type: evaluacionDeLaGestionActions.LISTA_REGISTRO_ESPECIAL_REPORTE_REQUEST, page: action.page, personal: action.personal });
            }

            yield put({type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage});
            yield put({
                type: evaluacionDeLaGestionActions.MODAL_EDITAR_REGISTRO_REPORTE,
                showModalEditarRegistroReporte: false,
                page: action.page,
                personal: action.personal
            });

            yield put({type: evaluacionDeLaGestionActions.EDITAR_REGISTRO_REPORTE_SUCCESS});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.EDITAR_REGISTRO_REPORTE_FAILURE})
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.EDITAR_REGISTRO_REPORTE_FAILURE})
    }
}

export function* editarRegistroReporteSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.EDITAR_REGISTRO_REPORTE_REQUEST, editarRegistroReporte)
}


// Evaluación de Indicadores de Desempeño

function* listaGerenciasByServicio(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(listaGerenciasByServicioCall, token);
        console.log(response);
        if (response.status === 200) {
            var listaGerenciaByServicio = response.data;

            for (let i in listaGerenciaByServicio) {
                listaGerenciaByServicio[i].fechaAlta = listaGerenciaByServicio[i].fechaAlta.split('T')[0].split('-').reverse().join('-');
                ;
                listaGerenciaByServicio[i].fechaActualizacion = listaGerenciaByServicio[i].fechaActualizacion.split('T')[0].split('-').reverse().join('-');
                ;
            }

            console.log('lista', listaGerenciaByServicio);
            yield put({
                type: evaluacionDeLaGestionActions.LISTA_GERENCIAS_BY_SERVICIO_SUCCESS,
                listaGerenciasByServicio: listaGerenciaByServicio
            });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.LISTA_GERENCIAS_BY_SERVICIO_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.LISTA_GERENCIAS_BY_SERVICIO_FAILURE});
    }
}

export function* listaGerenciasByServicioSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_GERENCIAS_BY_SERVICIO_REQUEST, listaGerenciasByServicio)
}

function* listaObjetivosByGerencia(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idClasificacionGerencia = action.idClasificacionGerencia;

        const response = yield call(listaObjetivosByGerenciaCall, token, idClasificacionGerencia);
        console.log(response);
        if (response.status === 200) {
            var data = response.data;

            for (let i in data) {
                data[i].fechaAlta = data[i].fechaAlta.split('T')[0].split('-').reverse().join('-');
                ;
                data[i].fechaActualizacion = data[i].fechaActualizacion.split('T')[0].split('-').reverse().join('-');
                ;
            }

            console.log('lista', data);
            yield put({
                type: evaluacionDeLaGestionActions.LISTA_OBJETIVOS_BY_GERENCIA_SUCCESS,
                listaObjetivosByGerencia: data
            });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.LISTA_OBJETIVOS_BY_GERENCIA_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.LISTA_OBJETIVOS_BY_GERENCIA_FAILURE});
    }
}

export function* listaObjetivosByGerenciaSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_OBJETIVOS_BY_GERENCIA_REQUEST, listaObjetivosByGerencia)
}

function* listaServiciosEvaluacionesDesempeno(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        let userInfo = sessionStorage.getItem('userInfo');
        if (!userInfo.idUsuario) {
            userInfo = JSON.parse(userInfo);
        }

        const idProveedor = userInfo[0].idProveedor;

        const response = yield call(listaServiciosEvaluacionesDeDesempenoCall, token, idProveedor, action.idClasificacionGerencia);
        console.log(response);
        if (response.status === 200) {
            var data = response.data;

            for (let i in data) {
                data[i].fechaAlta = data[i].fechaAlta.split('T')[0].split('-').reverse().join('-');
                ;
                data[i].fechaActualizacion = data[i].fechaActualizacion.split('T')[0].split('-').reverse().join('-');
                ;
            }

            console.log('lista', data);
            yield put({
                type: evaluacionDeLaGestionActions.LISTA_SERVICIOS_EVALUACIONES_DE_DESEMPENO_SUCCESS,
                listaServiciosEvaluacionesDesempeno: data
            });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.LISTA_SERVICIOS_EVALUACIONES_DE_DESEMPENO_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.LISTA_SERVICIOS_EVALUACIONES_DE_DESEMPENO_FAILURE});
    }
}

export function* listaServiciosEvaluacionesDesempenoSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_SERVICIOS_EVALUACIONES_DE_DESEMPENO_REQUEST, listaServiciosEvaluacionesDesempeno)
}

function* listaIndicadoresByGerenciaObjetibo(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idClasificacionGerencia = action.idClasificacionGerencia;
        const idObjetivo = action.idObjetivo;

        const response = yield call(listaIndicadoresByGerenciaObjetiboCall, token, idClasificacionGerencia, idObjetivo);
        console.log(response);
        if (response.status === 200) {
            var data = response.data;

            for (let i in data) {
                data[i].fechaAlta = data[i].fechaAlta.split('T')[0].split('-').reverse().join('-');
                ;
                data[i].fechaActualizacion = data[i].fechaActualizacion.split('T')[0].split('-').reverse().join('-');
                ;
            }

            console.log('lista', data);
            yield put({
                type: evaluacionDeLaGestionActions.LISTA_INDICADORES_BY_GERENCIA_OBJETIVO_SUCCESS,
                listaIndicadoresByGerenciaObjetibo: data
            });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.LISTA_INDICADORES_BY_GERENCIA_OBJETIVO_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.LISTA_INDICADORES_BY_GERENCIA_OBJETIVO_FAILURE});
    }
}

export function* listaIndicadoresByGerenciaObjetiboSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_INDICADORES_BY_GERENCIA_OBJETIVO_REQUEST, listaIndicadoresByGerenciaObjetibo)
}

function* listaPartidasByServicio(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idServicio = action.idServicio;

        const response = yield call(listaPartidasByServicioCall, token, idServicio);
        console.log(response);
        if (response.status === 200) {
            var data = response.data;

            for (let i in data) {
                data[i].fechaAlta = data[i].fechaAlta.split('T')[0].split('-').reverse().join('-');
                ;
                data[i].fechaActualizacion = data[i].fechaActualizacion.split('T')[0].split('-').reverse().join('-');
                ;
            }

            console.log('lista', data);
            yield put({
                type: evaluacionDeLaGestionActions.LISTA_PARTIDAS_BY_SERVICIO_SUCCESS,
                listaPartidasByServicio: data, mostrarTablaSolicitudMateriales: data.length ? true : false,
            });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.LISTA_PARTIDAS_BY_SERVICIO_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.LISTA_PARTIDAS_BY_SERVICIO_FAILURE});
    }
}

export function* listaPartidasByServicioSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_PARTIDAS_BY_SERVICIO_REQUEST, listaPartidasByServicio)
}


function* listaPartidasByServicio2(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idServicio = action.idServicio;

        const response = yield call(listaPartidasByServicioCall, token, idServicio);
        console.log(response);
        if (response.status === 200) {
            var data = response.data;

            for (let i in data) {
                data[i].fechaAlta = data[i].fechaAlta.split('T')[0].split('-').reverse().join('-');
                ;
                data[i].fechaActualizacion = data[i].fechaActualizacion.split('T')[0].split('-').reverse().join('-');
                ;
            }

            console.log('lista', data);
            yield put({
                type: evaluacionDeLaGestionActions.LISTA_PARTIDAS_BY_SERVICIO_SUCCESS2,
                listaPartidasByServicio: data
            });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.LISTA_PARTIDAS_BY_SERVICIO_FAILURE2});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.LISTA_PARTIDAS_BY_SERVICIO_FAILURE2});
    }
}

export function* listaPartidasByServicioSaga2() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_PARTIDAS_BY_SERVICIO_REQUEST2, listaPartidasByServicio2)
}

function* crearEvaluacionDeDesempeno(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        let userInfo = sessionStorage.getItem('userInfo');
        if (!userInfo.idUsuario) {
            userInfo = JSON.parse(userInfo);
        }

        var year = moment().format('YYYY');

        console.log(userInfo);

        const data = {
            "idEvaluacionIndicador": null,
            "idUsuarioRegistro": userInfo[0].idUsuario,
            "idClasificacionGerencia": action.formulario.idClasificacionGerencia,
            "idObjetivo": action.formulario.idObjetivo,
            "idIndicadorDesempeno": action.formulario.idIndicadorDesempeno,
            "idServicio": action.formulario.idServicio,
            "idPartida": action.formulario.idPartida,
            "idProveedor": userInfo[0].idProveedor,
            "valorReal": action.formulario.valorReal,
            "idMes": action.formulario.idMes,
            "anio": year,
            "fechaAlta": null
        };

        const formData = new FormData();

        formData.append('data', new Blob([JSON.stringify(data)], {
            type: 'application/json'
        }));

        console.log(data);
        const response = yield call(crearEvaluacionDeDesempenoCall, token, data)
        console.log(response)

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se creó la evaluación de forma correcta";

            yield put({type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.CREAR_EVALUACION_DE_DESEMPENO_SUCCESS})
            yield put({
                type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO,
                limpiarFormulario: 'evaluacionDeDesempeno'
            });

            yield put({
                type: evaluacionDeLaGestionActions.MODAL_EVALUACION_DESEMPENO,
                showModalEvaluacionDesempeno: false
            });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.CREAR_EVALUACION_DE_DESEMPENO_FAILURE})
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.CREAR_EVALUACION_DE_DESEMPENO_FAILURE})
    }
}

export function* crearEvaluacionDeDesempenoSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.CREAR_EVALUACION_DE_DESEMPENO_REQUEST, crearEvaluacionDeDesempeno)
}

function* listaMesesEvaluacion(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(listaMesesEvaluacionCall, token);
        console.log(response);
        if (response.status === 200) {
            var data = response.data;

            console.log('lista', data);
            yield put({type: evaluacionDeLaGestionActions.LISTA_MESES_EVALUACION_SUCCESS, listaMesesEvaluacion: data});

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.LISTA_MESES_EVALUACION_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.LISTA_MESES_EVALUACION_FAILURE});
    }
}

export function* listaMesesEvaluacionSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_MESES_EVALUACION_REQUEST, listaMesesEvaluacion)
}

function* listaEvaluacionesDeDesempeno(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(listaEvaluacionesDeDesempenoCall, token,
            action.idClasificacionGerencia, action.idMes, action.idObjetivo, action.page - 1,
            action.idProveedor, action.order, action.field, action.idServicio, '');

        console.log(response);
        if (response.status === 200) {
            var data = response.data.data;
            var cantidad = response.data.cantidad;


            for (let i in data) {

                data[i].nombreGerencia = null;
                data[i].objetivo = null;
                data[i].indicadorDesempeno = null;
                data[i].servicio = null;
                data[i].partida = null;
                data[i].proveedor = null;

                if (data[i].clasificacionGerenciasByIdClasificacionGenrencia) {
                    data[i].nombreGerencia = data[i].clasificacionGerenciasByIdClasificacionGenrencia.nombreGerencia;
                }
                if (data[i].objetivosByIdObjetivo) {
                    if (data[i].objetivosByIdObjetivo.temaByIdTema) {
                        data[i].tema = data[i].objetivosByIdObjetivo.temaByIdTema.tema;
                    }
                    if (data[i].objetivosByIdObjetivo.centroByIdCentroCosto) {
                        data[i].centroDeCosto = data[i].objetivosByIdObjetivo.centroByIdCentroCosto.centroCosto;
                    }
                    data[i].objetivo = data[i].objetivosByIdObjetivo.objetivo;
                    data[i].idTema = data[i].objetivosByIdObjetivo.idTema;
                    data[i].idCentroCosto = data[i].objetivosByIdObjetivo.idCentroCosto;
                }
                if (data[i].indicadoresDesempenoByIdIndicadorDesempeno) {
                    data[i].indicadorDesempeno = data[i].indicadoresDesempenoByIdIndicadorDesempeno.indicadorDesempeno;
                    data[i].valorEsperado = data[i].indicadoresDesempenoByIdIndicadorDesempeno.valorEsperado;
                }
                if (data[i].serviciosByIdServicio) {
                    data[i].servicio = data[i].serviciosByIdServicio.nombre;
                }
                if (data[i].partidasByIdPartida) {
                    data[i].partida = data[i].partidasByIdPartida.nombre;
                }
                if (data[i].proveedoresByIdProveedor) {
                    data[i].proveedor = data[i].proveedoresByIdProveedor.nombre;
                }
                data[i].fechaEvaluacion = data[i].fechaAlta.split('T')[0].split('-').reverse().join('-');
            }

            console.log('lista', data);
            yield put({
                type: evaluacionDeLaGestionActions.LISTA_EVALUACIONES_DESEMPENO_SUCCESS,
                listaEvaluacionesDesempeno: data,
                listaEvaluacionesDesempenoCantidad: cantidad
            });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.LISTA_EVALUACIONES_DESEMPENO_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.LISTA_EVALUACIONES_DESEMPENO_FAILURE});
    }
}

export function* listaEvaluacionesDeDesempenoSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_EVALUACIONES_DESEMPENO_REQUEST, listaEvaluacionesDeDesempeno)
}

function* listaEvaluacionesDeDesempeno1(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(listaEvaluacionesDeDesempenoCall, token,
            action.idClasificacionGerencia, action.idMes.split(' - ')[0], action.idObjetivo, action.page - 1,
            action.idProveedor, action.order, action.field, action.idServicio, action.idPartida);

        console.log(response);
        if (response.status === 200) {
            var data = response.data.data;
            var cantidad = response.data.cantidad;
            var dataServicio1 = [];
            var nomenclatura = "";

            for (let i in data) {
                if (data[i].indicadoresDesempenoByIdIndicadorDesempeno && data[i].serviciosByIdServicio) {
                    nomenclatura = data[i].serviciosByIdServicio.nomenclatura;

                    dataServicio1[i * 2] = {};
                    dataServicio1[i * 2].label = 'Valor Real ' + data[i].idEvaluacionIndicador + ' ' + data[i].indicadoresDesempenoByIdIndicadorDesempeno.indicadorDesempeno;
                    dataServicio1[i * 2].data = [{x: action.idMes.split(' - ')[1] + ' ' + data[i].serviciosByIdServicio.nomenclatura, y: data[i].valorReal}];
                    dataServicio1[i * 2].backgroundColor = 'hsla(0, 50%, 50%, 1)';
                    dataServicio1[i * 2].stack = 'Stack ' + i;

                    dataServicio1[i * 2 + 1] = {};
                    dataServicio1[i * 2 + 1].label = 'Valor Esperado ' + data[i].idEvaluacionIndicador + ' ' + data[i].indicadoresDesempenoByIdIndicadorDesempeno.indicadorDesempeno;
                    dataServicio1[i * 2 + 1].data = [{
                        x: action.idMes.split(' - ')[1] + ' ' + data[i].serviciosByIdServicio.nomenclatura,
                        y: data[i].indicadoresDesempenoByIdIndicadorDesempeno.valorEsperado
                    }];
                    dataServicio1[i * 2 + 1].backgroundColor = 'hsla(90, 50%, 50%, 1)';
                    dataServicio1[i * 2 + 1].stack = 'Stack ' + i;
                }
            }

            console.log('lista1', dataServicio1);
            yield put({
                type: evaluacionDeLaGestionActions.LISTA_EVALUACIONES_DESEMPENO_1_SUCCESS,
                listaEvaluacionesDesempeno: dataServicio1,
                listaEvaluacionesDesempenoCantidad1: cantidad,
                nomenclaturaElemento: nomenclatura
            });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.LISTA_EVALUACIONES_DESEMPENO_1_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.LISTA_EVALUACIONES_DESEMPENO_1_FAILURE});
    }
}

export function* listaEvaluacionesDeDesempenoSaga1() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_EVALUACIONES_DESEMPENO_1_REQUEST, listaEvaluacionesDeDesempeno1)
}


function* listaEvaluacionesDeDesempeno2(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(listaEvaluacionesDeDesempeno2Call, token,
            action.idClasificacionGerencia, action.idMes.split(' - ')[0], action.idObjetivo, action.page - 1,
            action.idProveedor, action.order, action.field, action.idServicio, action.idPartida);

        console.log(response);
        if (response.status === 200) {
            var data = response.data.data;
            var cantidad = response.data.cantidad;
            var dataServicio2 = [];
            var nomenclatura = '';

            for (let i in data) {
                if (data[i].indicadoresDesempenoByIdIndicadorDesempeno && data[i].serviciosByIdServicio) {
                    nomenclatura = data[i].serviciosByIdServicio.nomenclatura;

                    dataServicio2[i * 2] = {};
                    dataServicio2[i * 2].label = 'Valor Real ' + data[i].idEvaluacionIndicador + ' ' + data[i].indicadoresDesempenoByIdIndicadorDesempeno.indicadorDesempeno;
                    dataServicio2[i * 2].data = [{x: action.idMes.split(' - ')[1] + ' ' + data[i].serviciosByIdServicio.nomenclatura, y: data[i].valorReal}];
                    dataServicio2[i * 2].backgroundColor = 'hsla(180, 50%, 50%, 1)';
                    dataServicio2[i * 2].stack = 'Stack ' + i;

                    dataServicio2[i * 2 + 1] = {};
                    dataServicio2[i * 2 + 1].label = 'Valor Esperado ' + data[i].idEvaluacionIndicador + ' ' + data[i].indicadoresDesempenoByIdIndicadorDesempeno.indicadorDesempeno;
                    dataServicio2[i * 2 + 1].data = [{
                        x: action.idMes.split(' - ')[1] + ' ' + data[i].serviciosByIdServicio.nomenclatura,
                        y: data[i].indicadoresDesempenoByIdIndicadorDesempeno.valorEsperado
                    }];
                    dataServicio2[i * 2 + 1].backgroundColor = 'hsla(270, 50%, 50%, 1)';
                    dataServicio2[i * 2 + 1].stack = 'Stack ' + i;
                }
            }

            console.log('lista2', dataServicio2);
            yield put({
                type: evaluacionDeLaGestionActions.LISTA_EVALUACIONES_DESEMPENO_2_SUCCESS,
                listaEvaluacionesDesempeno: dataServicio2,
                listaEvaluacionesDesempenoCantidad: cantidad,
                nomenclaturaElemento: nomenclatura
            });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.LISTA_EVALUACIONES_DESEMPENO_2_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.LISTA_EVALUACIONES_DESEMPENO_2_FAILURE});
    }
}

export function* listaEvaluacionesDeDesempenoSaga2() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_EVALUACIONES_DESEMPENO_2_REQUEST, listaEvaluacionesDeDesempeno2)
}

function* listaServiciosByCentro(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const response = yield call(listaServiciosByCentroCall, token, action.idServicio);
        console.log(response);
        if (response.status === 200) {
            var data = response.data;

            for (let i in data) {
                data[i].fechaAlta = data[i].fechaAlta.split('T')[0].split('-').reverse().join('-');
                data[i].fechaActualizacion = data[i].fechaActualizacion.split('T')[0].split('-').reverse().join('-');
            }

            console.log('lista', data);
            yield put({
                type: evaluacionDeLaGestionActions.LISTA_SERVICIOS_BY_CENTRO_SUCCESS,
                listaServiciosByCentro: data
            });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: evaluacionDeLaGestionActions.LISTA_SERVICIOS_BY_CENTRO_FAILURE});
        }
    } catch (error) {
        yield put({type: evaluacionDeLaGestionActions.LISTA_SERVICIOS_BY_CENTRO_FAILURE});
    }
}

export function* listaServiciosByCentroSaga() {
    yield takeLatest(evaluacionDeLaGestionActions.LISTA_SERVICIOS_BY_CENTRO_REQUEST, listaServiciosByCentro)
}
