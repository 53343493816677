import React, { Component } from 'react';
import { Form, Button, Row, Col, Modal, InputNumber, Input, message, Select } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const confirm = Modal.confirm;

class DatosEditados extends Component {
    formRef = React.createRef();
    render() {
        const { 
            onCambiarVista, 
            formularioEstandaresDeDesempeño,
            EstandaresDeDesempeñoSeleccionado,
            listaCategoriasFallasDeCalidadActivos,
            fetchingListaCategoriasFallasDeCalidadActivos,
            unidadesMediPorCategoria,
            fetchingUnidadesMediPorCategoria,
        } = this.props;


        const onFinish = formulario => {
            if (formulario.tolerancia && parseFloat(formulario.tolerancia) !== parseFloat(formularioEstandaresDeDesempeño.tolerancia)) {
                message.warning('El valor de tolerancia, debe ser el mismo que se ingresó al editar (' + formularioEstandaresDeDesempeño.tolerancia + '%).');
            } else if(formulario.fallaCalidad && formulario.fallaCalidad !== formularioEstandaresDeDesempeño.fallaCalidadCompleta) {
                message.warning('La falla de calidad, debe ser el mismo que se ingresó al editar (' + formularioEstandaresDeDesempeño.fallaCalidadCompleta + ').');
            } else if(formulario.tiempoRespuesta && formulario.tiempoRespuesta !== formularioEstandaresDeDesempeño.tiempoRespuesta) {
                message.warning('El valor de tiempo de respuesta, debe ser el mismo que ingresó al editar (' + formularioEstandaresDeDesempeño.tiempoRespuesta + ').');
            } else if(formulario.unidadMedida && formulario.unidadMedida !== formularioEstandaresDeDesempeño.unidadMedida) {
                message.warning('El valor de la unidad de medida, debe ser el mismo que ingresó al editar (' + formularioEstandaresDeDesempeño.unidadMedida + ').');
            }else{
                confirm({
                    title: <div>Estandar de Desempeño con Referencia Específica: <strong>{EstandaresDeDesempeñoSeleccionado.referencia}</strong> fue modificado, ¿estás seguro de continuar?</div>, 
                    content: 
                    <div>
                        <Row justify='space-around' key={EstandaresDeDesempeñoSeleccionado.idEstandarDesempeno}>
                            { formulario.fallaCalidad ? (
                            <Col span={8}>
                                <p><strong>Falla Calidad: </strong>{formulario.fallaCalidad}</p>
                            </Col>
                            ): false}
                            { formulario.tolerancia ? (
                            <Col span={8}>
                                <p><strong>Tolerancia: </strong>{formulario.tolerancia}%</p>
                            </Col>
                            ): false}
                            { formulario.tiempoRespuesta  ? (
                            <Col span={8}>
                                <p><strong>Tiempo Respuesta: </strong>{formulario.tiempoRespuesta + ' ' + formulario.unidadMedida}</p>
                            </Col>
                            ): false}
                        </Row>               
                    </div>,
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    width: '50%',
                    onOk() {
                        onCambiarVista(4, formularioEstandaresDeDesempeño);
                    },
                    onCancel() {
                        onCambiarVista(1, null);
                    },
                });
            }
        };
       
        

        const changeDecimales = (value) => {
            let valueDecimal = parseFloat(value)
            let campo = 'tolerancia';
            if(isNaN(valueDecimal)){
                valueDecimal = 0
            }
            this.formRef.current.setFieldsValue({[campo]: valueDecimal});
        }
        console.log('categoria sin editar')
        console.log(formularioEstandaresDeDesempeño.fallaCalidadCompleta);
        console.log(EstandaresDeDesempeñoSeleccionado.fallaCalidadCompleta)
        return (
            <Form {...layout}
            name="formulario"
            onFinish={onFinish}
            
            ref={this.formRef}
            >
                { formularioEstandaresDeDesempeño ? (
                <div>
                    <h2>Ingresa nuevamente el/los dato(s)</h2>                   
                    <Row justify='space-around'>
                        { formularioEstandaresDeDesempeño.fallaCalidadCompleta !== EstandaresDeDesempeñoSeleccionado.fallaCalidadCompleta ? (
                            <Col span={11}>
                                <Form.Item
                                label="Categoría de Falla de Calidad"
                                name='fallaCalidad'
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona la categoría de falla de calidad"
                                        loading={fetchingListaCategoriasFallasDeCalidadActivos}>
                                            {listaCategoriasFallasDeCalidadActivos.map(option => <Option key={option.fallaCalidad + ', ' + option.descripcion}>{ option.fallaCalidad + ', ' + option.descripcion}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        ): false }

                        { formularioEstandaresDeDesempeño.tolerancia !== EstandaresDeDesempeñoSeleccionado.tolerancia ? (
                            <Col span={11}>
                                <Form.Item
                                label="Tolerancia"
                                name='tolerancia'
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber 
                                    min={0.01} 
                                    max={100.00}
                                    step={1.00} 
                                    formatter={value => `${value}%`}
                                    parser={value => value.replace('%', '')}
                                    placeholder="Ingresa la tolerancia"
                                    onChange={changeDecimales}
                                    title="Número debe ser entero o fracción a dos decímales" 
                                    pattern='^[0-9]*(\.[0-9]{0,2})?%' />
                                </Form.Item>
                            </Col>
                        ): false }

                        { formularioEstandaresDeDesempeño.tiempoRespuesta !== EstandaresDeDesempeñoSeleccionado.tiempoRespuesta || formularioEstandaresDeDesempeño.unidadMedida !== EstandaresDeDesempeñoSeleccionado.unidadMedida ? (
                            <Col span={11}>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Tiempo de Respuesta"
                                            name="tiempoRespuesta"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <InputNumber min={1} 
                                            placeholder="Ingresa el tiempo de respuesta" 
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Unidad de medida"
                                            name="unidadMedida"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona una unidad de medida"
                                            loading={fetchingUnidadesMediPorCategoria}
                                            >
                                                {unidadesMediPorCategoria.map(option => <Option key={option.unidadMedida}>{option.unidadMedida}</Option>)}
                                            </Select>
                                            </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        ): false }
                    
                 
                        <Col span={11} className='marginBoton'>
                            <br/>
                            <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">
                                Guardar Cambios
                            </Button>
                        </Col>
                    </Row>
                </div>
                ): false }
            </Form>
        );
    }
}

const mapStateToProps = state => {
	return {
          formularioEstandaresDeDesempeño: state.MecanismosDePagosReducer.formularioEstandaresDeDesempeño,
          EstandaresDeDesempeñoSeleccionado: state.MecanismosDePagosReducer.EstandaresDeDesempeñoSeleccionado,
          EstandaresDeDesempeñoEditados: state.MecanismosDePagosReducer.EstandaresDeDesempeñoEditados,
          fetchingListaCategoriasFallasDeCalidadActivos: state.MecanismosDePagosReducer.fetchingListaCategoriasFallasDeCalidadActivos,
          listaCategoriasFallasDeCalidadActivos: state.MecanismosDePagosReducer.listaCategoriasFallasDeCalidadActivos,
          fetchingUnidadesMediPorCategoria: state.AlmacenesReducer.fetchingUnidadesMediPorCategoria,
          unidadesMediPorCategoria: state.AlmacenesReducer.unidadesMediPorCategoria,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onCambiarVista: (vistaModalEstandaresDeDesempeño, formularioEstandaresDeDesempeño) => {
            dispatch({ type: 'CAMBIAR_VISTA_ESTANDAR_DESEMPEÑO', vistaModalEstandaresDeDesempeño: vistaModalEstandaresDeDesempeño, formularioEstandaresDeDesempeño: formularioEstandaresDeDesempeño, EstandaresDeDesempeñoEditados: null})
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DatosEditados);