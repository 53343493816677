import React, {useState, useRef, useEffect} from 'react'
import {connect} from 'react-redux'
import moment from 'moment';
import {Steps, Row, Col, Card, Input, Button, Form , Select, DatePicker, Tooltip, Upload, message} from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined, UploadOutlined } from '@ant-design/icons';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import useArchivo from './useArchivo';

const { Option } = Select;
const { Step } = Steps;
const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};


function NuevoFolioOmision (props) {
    const {fetchingFallasPorServicio, fallasPorServicio, fetchingListaTiposFallasActivas, listaTiposFallasActivas, ubicacionesSinHijos, fetchingUbicacionesSinHijos,
        fetchingDocumentosFiscalizacion, listaDocumentosFiscalizacion, page, busqueda, onFallasPorServicio, onFallasPorServicioLimpiar,
        onUbicacionesSinHijosFiltro, onUbicacionesSinHijosFiltroLimpiar, onGetDocumentoFiscalizacion, onCrearFolioEO, onGetListaTiposFallasActivas,
    } = props;

    const formRef = useRef();
    const [steps,setSteps] = useState(0);
    const [servicio,setServicio] = useState(null);
    const [formState, setFormState] = useState({});
    const [fechaOmision, setFechaOmision] = useState(null);

    const {status, dummyRequest, onChangePDF, onRemovePDF} = useArchivo(formRef)

    useEffect (() => {
        onGetDocumentoFiscalizacion();
        onGetListaTiposFallasActivas();
        //Unmount
        return () => {
            setSteps(0);
            setServicio(null);
            setFormState({});
            setFechaOmision(null);
            if(formRef?.current) {
                formRef.current.resetFields()
            }
        };
    },[])

    const buscarFalla = (value) => {
        if(value.length>=3) {
            onFallasPorServicio(null, value);
        } else {
            onFallasPorServicioLimpiar()
        }
    };

    const handleFalla = (value, props) => {
        console.log(props);
        const {servicioByIdServicio} = props.data;
        formRef.current.setFieldsValue({'tipoFalla': `${props.data.idTipoFalla}` });
        setFormState({...formState, tipoFalla: props.data.idTipoFalla})
        setServicio(servicioByIdServicio)
        formRef.current.setFieldsValue({'servicio': null})
    }

    const buscarUbicacionSinHijo = (value) => {
        if(value.length>=3){
            onUbicacionesSinHijosFiltro(value);
        }
        else{
             onUbicacionesSinHijosFiltroLimpiar();
        }
    };

    const fechaHandler = (value) => {
        formRef.current.setFieldsValue({'fechaCierreEditar': null});
        setFechaOmision(value);
    }

    const disabledRange = fecha => {
        let fechaInicio = formRef.current.getFieldValue('fechaOmision');
        fechaInicio = fechaInicio.format('DD-MM-YYYY');
        fechaInicio = moment(fechaInicio, 'DD-MM-YYYY');
        if( fecha && fecha < fechaInicio) {
            return fecha && fecha < fechaInicio;
        } 
    }

    function range(start, end) {
        const result = [];
        for (let i = start; i < end; i++) {
          result.push(i);
        }
        return result;
    }

    const disabledTime = fecha => {
        let hours = 0;
        let minutes = 0;
        let fechaInicio = ""
        let fechaOmision = formRef.current.getFieldValue('fechaOmision');
        fechaOmision = fechaOmision.format('DD-MM-YYYYTHH:mm').split('T');
        fechaInicio = fechaOmision[0];
        const time = fechaOmision[1];
        hours = parseInt(time.split(':')[0]);
        minutes = parseInt(time.split(':')[1]);

        let dateSelected="";
        let hourSelected=0;
        if(fecha) {
            dateSelected = fecha.format('DD-MM-YYYY');
            hourSelected = fecha._d.getHours();
        }
        
        hours = dateSelected == fechaInicio ? hours : 0;
        minutes = hourSelected == hours ? minutes +1 : 0;
        return {
            disabledHours: () => range(0, hours),
            disabledMinutes: () => range(0 , minutes),
        };
    }

    const handleSteps = value => {
        if(steps === 0) {
            const {descripcionDeFalla, nombreFalla, servicio, tipoFalla} = formRef.current.getFieldsValue()
            setFormState({...formState, descripcionDeFalla, nombreFalla, servicio})
            if(!descripcionDeFalla || !nombreFalla || !servicio || !tipoFalla) return message.warning('Favor de llenar los campos requeridos');
        }

        if(steps ===1 && value === 'siguiente') {
            const {ubicacion} = formRef.current.getFieldsValue()
            setFormState({...formState, ubicacion})
            if(!ubicacion) return message.warning('Favor de llenar los campos requeridos');
        }

        if (value === 'siguiente') return setSteps(steps+1);

        setSteps(steps-1);
    }

    const onFinish = formulario => {
        const tipoFolio = 'Omision';
        const data = {...formulario, ...formState}

        onCrearFolioEO(data ,tipoFolio, page, busqueda);
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo)
    }

    const onSearch = (val) => {
        console.log('search:', val);
    }

    return (
        <>
            <Card
                className="listaCatalogos"
                bordered={true}
                style={{align: 'center'}}
                title={<div className="headerLista">Registro de Omisiones en la Información</div>}
                >
                <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={formRef}
                    >
                    <Row justify='space-around' style={{ textAlign: 'left' }}>                         
                        <Col span={24}>
                            <Steps current={steps}>
                                <Step title="Detalle de la solicitud" />
                                <Step title="Ubicación" />
                                <Step title="Confirmar datos" />
                            </Steps>
                        </Col>
                    </Row>
                    {steps === 0 && (
                        <>
                            <Row justify='space-between' style={{ marginTop: '15px' }}>
                                <Col span={24}>
                                    <Form.Item
                                        label='Descripción'
                                        name='descripcionDeFalla'
                                        rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                        >
                                        <TextArea autoSize maxLength={500} placeholder="Escribe una descripción de la falla o solicitud que desea reportar"/>
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        label='Falla'
                                        name='nombreFalla'
                                        rules={[{ required: true, message: 'Favor de seleccionar una opción'}]}
                                        >
                                        <Select 
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder="Seleccione la falla"
                                            loading={fetchingFallasPorServicio}
                                            onSearch={buscarFalla}
                                            notFoundContent={null}
                                            filterOption={false}
                                            onChange={handleFalla}
                                        >
                                            {fallasPorServicio.map(option => 
                                            <Option key={option.idFalla} data={option}>
                                                <Tooltip placement="topLeft" title={option.idFalla + '-' + option.nombre}>
                                                    {option.nombre}
                                                </Tooltip>
                                            </Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>   
                                <Col span={7}>
                                    <Form.Item 
                                        label="Tipo de falla" 
                                        name="tipoFalla"
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                        <Select
                                            style={{ width: "100%" }}
                                            placeholder="Selecciona el tipo de falla"
                                            loading={fetchingListaTiposFallasActivas}
                                            showSearch
                                            allowClear
                                        >
                                            {listaTiposFallasActivas.map((option) => (
                                            <Option key={option.idTipoFalla}>
                                                <Tooltip
                                                placement="topLeft"
                                                title={option.idTipoFalla + " - " + option.nombre}
                                                >
                                                {option.nombre}
                                                </Tooltip>
                                            </Option>
                                            ))}
                                        </Select>
                                        
                                    </Form.Item>
                                </Col> 
                                <Col span={7}>
                                    <Form.Item
                                        label="Servicio"
                                        name="servicio"
                                        rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona un servicio"
                                        >
                                            <Option key={servicio?.idServicio}>{servicio?.nombre}</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='end'>
                                <Col span={7}>
                                    <Button className='btnCargaArchivos' style={{ width: '100%' }} onClick={()=>handleSteps('siguiente')} >
                                        Siguiente <ArrowRightOutlined />
                                    </Button>
                                </Col>                
                            </Row>
                        </>
                    )}
                    {steps === 1 && (
                        <>
                            <Row justify='space-between' style={{ marginTop: '15px' }}>
                                <Col span={23}>
                                    <Form.Item
                                        label="Ubicación"
                                        name="ubicacion"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Escriba la ubicación a buscar"
                                            loading={fetchingUbicacionesSinHijos}
                                            showSearch
                                            filterOption={false}
                                            onSearch={buscarUbicacionSinHijo}
                                            notFoundContent={null}
                                        >
                                            {ubicacionesSinHijos.map(option => 
                                            <Option key={option.idUbicacion + ' - ' + option.ubicacionCOA}>
                                                {option.idUbicacion + ' - ' + option.ubicacionCOA}
                                            </Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col span={7}>
                                    <Button className='btnCargaArchivos' style={{ width: '100%' }} onClick={()=>handleSteps('anterior')}>
                                        <ArrowLeftOutlined /> Anterior
                                    </Button>
                                </Col>
                                <Col span={7}>
                                    <Button className='btnCargaArchivos' style={{ width: '100%' }} onClick={()=>handleSteps('siguiente')}>
                                        Siguiente <ArrowRightOutlined />
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}
                    {steps === 2 && (
                        <>
                            <Row justify='space-between' style={{ marginTop: '15px' }}>
                                <Col span={7}>                   
                                    <Form.Item
                                        label="Fecha de omisión"
                                        name="fechaOmision"
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <DatePicker 
                                            style={{ width: '100%' }} 
                                            format='DD-MM-YYYY HH:mm' 
                                            showTime={true}
                                            onChange={fechaHandler}
                                            />      
                                    </Form.Item> 
                                </Col>
                                <Col span={7}>                   
                                    <Form.Item
                                        label="Fecha de cierre"
                                        name="fechaCierreEditar"
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <DatePicker 
                                            style={{ width: '100%' }} 
                                            format='DD-MM-YYYY HH:mm' 
                                            showTime={true}
                                            disabledDate={disabledRange}
                                            disabledTime={disabledTime}
                                            disabled={!fechaOmision}
                                            />      
                                    </Form.Item> 
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        label='No. Documento fizcalización'
                                        name='noDocFiscalizacion'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <Input 
                                            maxLength={100} 
                                            placeholder='Ingrese No. Documento'
                                            pattern="^[-/a-zñA-ZÑ0-9 ÁÉÓÚÍáéóíú]+$"
                                        />

                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        label='Tipo documento fizcalización'
                                        name='tipoDocFiscalizacion'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <Select
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Seleccione el tipo de documento"
                                            loading={fetchingDocumentosFiscalizacion}
                                            filterOption={false}
                                            notFoundContent={null}
                                        >
                                            {listaDocumentosFiscalizacion.map(option => 
                                            <Option key={option.idDocumentoFiscalizacion}>
                                                {option.tipo}
                                            </Option>
                                            )}
                                        </Select>

                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        label="Evidencia PDF"
                                        name="pdf"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Upload  customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false} style={{ width: '100%' }}>
                                            {status.filePDF === false ? (
                                            <Button disabled={status.botonPDF} className='btnCargaArchivos'>
                                                <UploadOutlined /> Click para subir evidencia PDF
                                            </Button>
                                            ):false}
                                        </Upload>
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Button className='btnPrueba' style={{ width: '100%', marginTop:'40px' }} htmlType='submit '>
                                        Confirmar
                                    </Button>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col span={7}>
                                    <Button className='btnCargaArchivos' style={{ width: '100%' }} onClick={()=>handleSteps('anterior')}>
                                        <ArrowLeftOutlined /> Anterior
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}
                </Form>
            </Card>
        </>
    )
}

const mapStateToProps = state => {
    return {
        fetchingFallasPorServicio: state.MecanismosDePagosReducer.fetchingFallasPorServicio,
        fallasPorServicio: state.MecanismosDePagosReducer.fallasPorServicio,
        fetchingListaTiposFallasActivas: state.SolicitudDeServiciosReducer.fetchingListaTiposFallasActivas,
        listaTiposFallasActivas: state.SolicitudDeServiciosReducer.listaTiposFallasActivas,
        ubicacionesSinHijos: state.SolicitudDeServiciosReducer.ubicacionesSinHijos,
        fetchingUbicacionesSinHijos: state.SolicitudDeServiciosReducer.fetchingUbicacionesSinHijos,
        fetchingDocumentosFiscalizacion: state.MecanismosDePagosReducer.fetchingDocumentosFiscalizacion,
        listaDocumentosFiscalizacion: state.MecanismosDePagosReducer.listaDocumentosFiscalizacion,
        page: state.MecanismosDePagosReducer.page,
        busqueda: state.MecanismosDePagosReducer.busqueda,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFallasPorServicio: (idServicio, param) => {
            dispatch({ type: 'FALLAS_POR_SERVICIO_REQUEST', idServicio, param })
        },
        onFallasPorServicioLimpiar: () => {
            dispatch({ type: "FALLAS_POR_SERVICIO_SUCCESS", fallasPorServicio: []});
        },
        onGetListaTiposFallasActivas: () => {
            dispatch({ type: "LISTA_TIPOS_FALLAS_ACTIVAS_REQUEST" })
       },
        onUbicacionesSinHijosFiltro: (busqueda) => {
            dispatch({ type: 'UBICACIONES_SIN_HIJOS_REQUEST', busqueda });
        },
        onUbicacionesSinHijosFiltroLimpiar: () => {
            dispatch({ type: 'UBICACIONES_SIN_HIJOS_SUCCESS', ubicacionesSinHijos: [] });
        },
        onGetDocumentoFiscalizacion: () => {
            dispatch({ type: 'DOCUMENTOS_FISCALIZACION_REQUEST'});
        },
        onCrearFolioEO: (formulario, tipoFolio, page, busqueda) => {
            dispatch({ type: 'NUEVO_FOLIO_ERROR_OMISION_REQUEST', formulario, tipoFolio, page, busqueda});
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NuevoFolioOmision);