import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, DatePicker, Select, InputNumber } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css'
import { connect } from 'react-redux';

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalEditarCatalogoDeFalla extends Component {
    formRef = React.createRef();

    componentDidMount() {
        this.props.onGetUnidadesDeMedicion(1);
    }

    render() {

        const { fetchingEditarCategoriaDeFalla, showModalEditarCategoriaDeFalla, onEditarCategoriaDeFalla, onShowModalEditarCategoriaDeFalla,
            CategoriaDeFallaSeleccionado, page, limpiarFormulario, fetchingUnidadesMediPorCategoria, unidadesMediPorCategoria } = this.props;

        console.log('CategoriaDeFallaSeleccionado en modal');
        console.log(CategoriaDeFallaSeleccionado);

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onEditarCategoriaDeFalla(formulario, CategoriaDeFallaSeleccionado, page, unidadesMediPorCategoria);

        };
        if (limpiarFormulario && limpiarFormulario === 'editarCategoriaFalla') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        

        const handleCancel = () => {
            onShowModalEditarCategoriaDeFalla();
        }

        return (
            <Modal
                visible={showModalEditarCategoriaDeFalla}
                title='Editar Categoría de Tiempo de Rehabilitación'
                onCancel={handleCancel}
                footer={false}>
                <Spin spinning={fetchingEditarCategoriaDeFalla}>
                    {CategoriaDeFallaSeleccionado ? (
                        <Form {...layout}
                            name="formulario"
                            initialValues={CategoriaDeFallaSeleccionado}
                            onFinish={onFinish}
                            
                            ref={this.formRef}
                        >
                            <Form.Item
                            label="Categoría"
                            name="numeroFallaCategoria"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <InputNumber min={1} max={100} placeholder='Categoría'
                            title="Categoría debe ser número entero" pattern="^[0-9]+$" />
                        </Form.Item>
                        <Form.Item 
                            label="Unidad de medición"
                            name="idUnidadMedida"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Select 
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Seleccione la unidad de medición"
                            loading={fetchingUnidadesMediPorCategoria}
                            >
                                {unidadesMediPorCategoria.map(option => <Option key={option.idUnidadMedida}>{option.unidadMedida}</Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Tiempo de rehabilitación"
                            name="fallaCategoria"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <InputNumber min={1} max={100} placeholder='Tiempo de rehabilitación'
                            title="Tiempo de rehabilitación debe ser número entero" pattern="^[0-9]+$" />
                        </Form.Item>
                            <Col span={12}>
                                <Button className="btnFiltrar" htmlType="submit">Editar Categoría de Tiempo de Rehabilitación</Button>
                            </Col>
                        </Form>
                    ) : false}
                </Spin>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingEditarCategoriaDeFalla: state.CatalogosGeneralesReducer.fetchingEditarCategoriaDeFalla,
        showModalEditarCategoriaDeFalla: state.CatalogosGeneralesReducer.showModalEditarCategoriaDeFalla,
        CategoriaDeFallaSeleccionado: state.CatalogosGeneralesReducer.CategoriaDeFallaSeleccionado,
        page: state.CatalogosGeneralesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        unidadesMediPorCategoria: state.AlmacenesReducer.unidadesMediPorCategoria,
        fetchingUnidadesMediPorCategoria: state.AlmacenesReducer.fetchingUnidadesMediPorCategoria,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarCategoriaDeFalla: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_CATEGORIA_FALLA', showModalEditarCategoriaDeFalla: false, CategoriaDeFallaSeleccionado: null })
        },
        onEditarCategoriaDeFalla: (formulario, CategoriaDeFallaSeleccionado, page, listaUnidadesDeMedicionActivos) => {
            dispatch({ type: 'EDITAR_CATEGORIA_FALLA_REQUEST', formulario: formulario, CategoriaDeFallaSeleccionado: CategoriaDeFallaSeleccionado, page: page, listaUnidadesDeMedicionActivos:listaUnidadesDeMedicionActivos })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
        },
        onGetUnidadesDeMedicion: (idCategoriaUnidadMedida) => {
            dispatch({ type: "UNIDADES_MEDI_POR_CATEGORIA_REQUEST", idCategoriaUnidadMedida:idCategoriaUnidadMedida});
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarCatalogoDeFalla);