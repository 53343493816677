import React from 'react';
import {Card, Table, Row, Col, Select, Button, Switch, Modal} from 'antd';
import {FileSearchOutlined, EyeOutlined, DownloadOutlined} from '@ant-design/icons';
import {connect} from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalEstandaresDesempeno from "./ModalEstandaresDesempeno";
import ModalVerPDFReportesDesempeno from "./ModalVerPDFReportesDesempeno";
import ModalSubirArchivoPorEstandar from "./ModalSubirArchivoPorEstandar";

const {Option} = Select;
const confirm = Modal.confirm;

class ReportesDeDesempenoDeEstandaresGeneralesContainer extends React.Component {
    componentDidMount(){
        this.props.onListaServiciosActivos();
        this.props.onListaFoliosEstandares(1, '', 'ASC', 'idFolioEstandar')
    }
    componentWillUnmount() {
        this.props.onListaFoliosEstandaresLimpiar();
    }

    state = {
        page: 1,
        order: 'ASC',
        field: 'idFolioEstandar',
        idServicio: ''
    }

    render() {
        const {
            fetchingListaFoliosEstandaresDesempeno,
            listaFoliosEstandaresDesempeno,
            listaFoliosEstandaresDesempenoCantidad,
            listaServiciosActivosF,
            fetchingListaServiciosActivosF,
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){ permisosUsuario = JSON.parse(permisosUsuario); }

        const columns = [{
            title: 'Folio estándar',
            dataIndex: 'folioEstandar',
            key: 'folioEstandar',
            width: '10%',
            sorter: true
        }, {
            title: 'Fecha de alta',
            dataIndex: 'fechaAlta',
            key: 'fechaAlta',
            width: '10%',
        }, {
            title: 'Fecha de evaluación',
            dataIndex: 'fechaEvalua',
            key: 'fechaEvalua',
            width: '10%',
            sorter: true
        }, {
            title: 'Usuario',
            dataIndex: 'usuarioEvalua',
            key: 'usuarioEvalua',
            width: '10%',
        }, {
            title: 'Puesto',
            dataIndex: 'puestoEvalua',
            key: 'puestoEvalua',
            width: '10%',
        }, {
            title: 'Consultar estándares',
            key: 'consultar',
            width: '8%',
            align: 'center',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.consultarEstándaresGenerales} onClick={() => onShowEstandares(record.idFolioEstandar)}><FileSearchOutlined /></a>
            ),
        }, {
            title: 'Consultar evidencia',
            key: 'consultar',
            width: '8%',
            align: 'center',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.consultarEvidenciaDeEstándaresGenerales ? true : !record.rutaEvidencia} onClick={() => onMostrarPDF(record.rutaEvidencia)}><EyeOutlined/></a>
            ),
        }, {
            title: 'Descargar evidencia',
            key: 'desacrgar',
            width: '8%',
            align: 'center',
            render: (text, record) => (
                <a className='editarIcono' target='_blank' disabled={!permisosUsuario.descargarEvidenciaDeEstándaresGenerales ? true : !record.rutaEvidencia}
                   href={record.rutaEvidencia}><DownloadOutlined/></a>
            ),
        },
        ];
        const onChangeServicio = (value) => {
            this.setState({
                idServicio: value.split(' - ')[0],
            });
            if (value) {
                this.props.onListaFoliosEstandares(this.state.page, value.split(' - ')[0], this.state.order, this.state.field);
            }
        };
        const onMostrarPDF = (key) => {
            this.props.onShowModalVerPDFReportesDesempeno(key);
        }
        const handleTableChange = (pagination, filtro, ordenTabla) => {
            this.setState({
                page: pagination.current,
            })
            if (ordenTabla.columnKey) {
                let field = ''
                if (ordenTabla.columnKey === 'folioEstandar') {
                    field = 'idFolioEstandar';
                } else {
                    field = ordenTabla.columnKey;
                }
                let order = 'ASC';

                if (ordenTabla.order === 'descend') {
                    order = 'DESC';
                }
                this.setState ({
                    order,
                    field
                });
                this.props.onListaFoliosEstandares(this.state.page, this.state.idServicio, order, field);
            } else {
                this.props.onListaFoliosEstandares(pagination.current, this.state.idServicio, this.state.order, this.state.field);
            }
        };
        const onShowEstandares = (idFolioEstandar) => {
            this.props.onListaEstandaresPorFolio(1, idFolioEstandar, '');
            this.props.onShowModalEstandaresDesempeno(idFolioEstandar);
        }

        return (
            <Card className="cardCatalogos"
                  title={<div className='headerLista'>Reportes De Desempeño De Estándares Generales y Específicos</div>}>
                <Row justify='space-between'>
                    <Col span={7}>
                        <Select allowClear
                                style={{ width: '100%' }}
                                placeholder="Selecciona un servicio a filtrar"
                                loading={fetchingListaServiciosActivosF}
                                onChange={onChangeServicio}
                                showSearch={true}
                        >
                            {listaServiciosActivosF.map(option => <Option key={option.idServicio + ' - ' + option.nombre}>{option.idServicio + ' - ' + option.nombre}</Option>)}
                        </Select>
                    </Col>
                    <Col span={24}>
                        <br/>
                        <Table
                            size="small"
                            rowKey="idFolioEstandar"
                            columns={columns}
                            dataSource={listaFoliosEstandaresDesempeno}
                            loading={fetchingListaFoliosEstandaresDesempeno}
                            locale={{emptyText: 'Sin datos'}}
                            onChange={handleTableChange}
                            pagination={{
                                total: listaFoliosEstandaresDesempenoCantidad,
                                simple: true,
                                current: this.state.page
                            }}
                        />
                    </Col>
                    <ModalSubirArchivoPorEstandar/>
                    <ModalVerPDFReportesDesempeno/>
                    <ModalEstandaresDesempeno/>
                </Row>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaFoliosEstandaresDesempeno: state.GestionDeCalidadReducer.fetchingListaFoliosEstandaresDesempeno,
        listaFoliosEstandaresDesempeno: state.GestionDeCalidadReducer.listaFoliosEstandaresDesempeno,
        listaFoliosEstandaresDesempenoCantidad: state.GestionDeCalidadReducer.listaFoliosEstandaresDesempenoCantidad,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onListaEstandaresPorFolio: (page, idFolioEstandar, param) => {
            dispatch({type: 'LISTA_ESTANDARES_POR_FOLIO_REQUEST', page, idFolioEstandar, param})
        },
        onListaFoliosEstandares: (page, idServicio, order, field) => {
            dispatch({type: 'LISTA_FOLIOS_ESTANDARES_DESEMPENO_REQUEST', page, idServicio, order, field})
        },
        onShowModalEstandaresDesempeno: (idFolioEstandar) => {
            dispatch({
                type: 'MODAL_SHOW_ESTANDARES',
                showModalEstandaresDesempeno: true,
                folioEstandarDesempenoSeleccionado: idFolioEstandar
            })
        },
        onListaFoliosEstandaresLimpiar: () => {
            dispatch({
                type: "LISTA_FOLIOS_ESTANDARES_DESEMPENO_SUCCESS",
                listaFoliosEstandaresDesempeno: [],
                listaFoliosEstandaresDesempenoCantidad: 0
            });
        },
        onShowModalVerPDFReportesDesempeno: (rutaArchivo) => {
            dispatch({
                type: 'MODAL_SHOW_PDF_ESTANDARES_DESEMPENO',
                showModalPDFEstandaresDesempeno: true,
                rutaArchivoEstandaresDesempeno: rutaArchivo
            })
        },
        onListaServiciosActivos: () => {
            dispatch ({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST' });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportesDeDesempenoDeEstandaresGeneralesContainer);
