import React, { Component } from 'react';
import {Form, Row, Col, Modal, Input, Spin, Table, Alert, Divider, Button, Descriptions} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
const { Search } = Input;
const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };

class ModalBusquedaCodigoInterno extends Component {
    formRef = React.createRef();

     render() {
          const { onCloseModalBusquedaCodigoInterno, showModalBusquedaCodigoInterno, onGetActivosByCodigoInterno, listaActivosByCodigoInterno, fetchingListaActivosByCodigoInterno, onLimpiarActivosByCodigoInterno
          } = this.props;

          const onFinish = formulario => {
               console.log('Success:', formulario);
               onCloseModalBusquedaCodigoInterno(listaActivosByCodigoInterno[0].codigoInterno);
               onLimpiarActivosByCodigoInterno();
               this.formRef.current.resetFields();
          }

          const handleCancel = () => {
               onCloseModalBusquedaCodigoInterno();
               onLimpiarActivosByCodigoInterno();
               this.formRef.current.resetFields();
          };

          const onBusqueCodigo = (value) => {
               onGetActivosByCodigoInterno(value);
          };

          let data=[];
          if (listaActivosByCodigoInterno[0]){
               let { descripcion, codigoInterno, especificaciones, marca, modelo } = listaActivosByCodigoInterno[0];
               
               data = [
                    {label: 'Característica', data: descripcion, key: 'descripcion'},
                    {label: 'Código interno', data: codigoInterno, key: 'codigoInterno'},
                    {label: 'Marca', data: marca, key: 'marca'},
                    {label: 'Modelo', data: modelo, key: 'modelo'},
                    {label: 'Especificaciones', data: especificaciones, key: 'especificaciones'},
               ];
          }
        
          return (
               <Modal
                    visible={showModalBusquedaCodigoInterno}
                    title='Búsqueda de Código Interno'
                    onCancel={handleCancel}
                    footer={false}
                    width={'60%'}
               >
                     <Spin spinning={fetchingListaActivosByCodigoInterno}>
                         <Form {...layout}
                         name="formulario"
                         onFinish={onFinish}
                         ref={this.formRef}
                         >
                              <Row justify='space-around'>
                                   <Col span={24}>
                                        <Form.Item name='codigoInterno'>
                                             <Search placeholder="Ingresa código interno" allowClear onSearch={onBusqueCodigo} style={{ width: '100%' }} enterButton={<SearchOutlined />} />
                                        </Form.Item>
                                   </Col>

                                   { listaActivosByCodigoInterno[0] ? (
                                        <Col span={24}>
                                             {/* <Table 
                                                  size='small' columns={columnas}
                                                  size='small'
                                                  rowKey='idActivo'
                                                  dataSource={listaActivosByCodigoInterno}
                                                  // loading={fetchingListaActivosByCodigoInterno}
                                                  locale={{ emptyText: 'Sin datos' }}
                                                  pagination={false}
                                             /> */}

                                             <Descriptions 
                                                  layout="horizontal" bordered={true}
                                             >
                                                  {data.map(item => 
                                                       <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                  )}
                                             </Descriptions>

                                             <br/>
                                        </Col>
                                   ):(<>
                                        <Col span={24}>
                                             <Alert
                                                  message="Código Interno"
                                                  description="Ingresa un código interno válido para continuar."
                                                  type="info"
                                                  showIcon
                                                  style={{ borderRadius: '2em' }}
                                             />
                                        </Col>

                                        <Divider />
                                   </>)}

                                   <Button className="btnFiltrar" style={{ width: '50%' }} htmlType="submit" disabled={!listaActivosByCodigoInterno[0]} >
                                        Seleccionar
                                   </Button>

                              </Row>
                         </Form>
                    </Spin>
               </Modal>
          )
     }
}

const mapStateToProps = state => {
     return {
          showModalBusquedaCodigoInterno: state.MecanismosDePagosReducer.showModalBusquedaCodigoInterno,
          listaActivosByCodigoInterno: state.MecanismosDePagosReducer.listaActivosByCodigoInterno,
          fetchingListaActivosByCodigoInterno: state.MecanismosDePagosReducer.fetchingListaActivosByCodigoInterno,
     };
};

const mapDispatchToProps = dispatch => {
     return {
          onCloseModalBusquedaCodigoInterno: (codigoInterno) => {
               dispatch({ type: 'SHOW_MODAL_BUSQUEDA_CODIGO_INTERNO', showModalBusquedaCodigoInterno: false, codigoInterno });
          },
          onGetActivosByCodigoInterno: (codigoInterno) => {
               dispatch({ type: 'LISTA_ACTIVOS_BY_CODIGO_INTERNO_REQUEST', codigoInterno });
          },
          onLimpiarActivosByCodigoInterno: () => {
               dispatch({ type: 'LISTA_ACTIVOS_BY_CODIGO_INTERNO_SUCCESS', listaActivosByCodigoInterno: [] });
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalBusquedaCodigoInterno);