import React from 'react';
import {connect} from 'react-redux';
import {Form, Row, Col, Button, Modal, Spin, Input, Select, Tooltip} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const { Option } = Select;
class ModalEditarTema extends React.Component {
    formRef = React.createRef();
    render() {
        const {showModalEditarTema, fetchingEditarTema, page, fetchingListaCentroDeCostosActivos,
            onShowModalTema, onEditarTema, temaSeleccionado, busqueda, listaCentroDeCostosActivos,
        } = this.props;
        

        const onFinish = (formulario) => {
            onEditarTema(formulario, page, busqueda, temaSeleccionado);        
        }

        const handleCancel = () => {
            onShowModalTema(page, busqueda);
            this.formRef.current.resetFields();
        }

        return (
            <div>
                <Modal
                    visible={showModalEditarTema}
                    title='Editar Tema'
                    onCancel={handleCancel}
                    footer={false}
                    width='70%'
                >
                    {temaSeleccionado ? (
                    <Spin spinning={fetchingEditarTema}>
                        <Form {...layout}
                            name='formulario'
                            onFinish={onFinish}
                            ref={this.formRef}
                            initialValues={temaSeleccionado}
                        >
                            <Row type='flex' justify='space-around'>
                            <Col span={11}>
                                    <Form.Item
                                        label='Nombre del tema'
                                        name='tema'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                        <Input maxLength={100} placeholder='Ingresa el nombre del tema' style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                
                                <Col span={11}>
                                    <Form.Item
                                        label='Centro de costos'
                                        name='centroCostoEditar'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el centro de costo"
                                            loading={fetchingListaCentroDeCostosActivos}
                                        >
                                            {listaCentroDeCostosActivos.map(option => 
                                            <Option key={option.idCentroCosto + ' - ' + option.centroCosto}>
                                                <Tooltip title={option.idCentroCosto + ' - ' + option.centroCosto}>
                                                    {option.idCentroCosto + ' - ' + option.centroCosto}
                                                </Tooltip>
                                            </Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={11} className='marginBoton'>
                                    <br/>
                                    <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Editar Tema</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                    ):false}
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalEditarTema: state.EvaluacionDeLaGestionReducer.showModalEditarTema,
        fetchingEditarTema: state.EvaluacionDeLaGestionReducer.fetchingEditarTema,
        page: state.EvaluacionDeLaGestionReducer.page,
        busqueda: state.EvaluacionDeLaGestionReducer.busqueda,
        temaSeleccionado: state.EvaluacionDeLaGestionReducer.temaSeleccionado,
        fetchingListaCentroDeCostosActivos: state.CatalogosGeneralesReducer.fetchingListaCentroDeCostosActivos,
        listaCentroDeCostosActivos: state.CatalogosGeneralesReducer.listaCentroDeCostosActivos,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalTema: (page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_TEMA", showModalEditarTema: false, page, busqueda});
        },
        onEditarTema: (formulario, page, busqueda, temaSeleccionado) => {
            dispatch({ type: 'EDITAR_TEMA_REQUEST', formulario, page, busqueda, temaSeleccionado });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalEditarTema);