import React, {Component} from 'react'
import {Row, Col, Select, Input, Table, Button, DatePicker, Spin, message, Form, Tooltip, Card} from 'antd';
import {EditOutlined, FormOutlined} from '@ant-design/icons';
import {connect} from "react-redux";
import moment from 'moment';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalDevolucion from './ModalDevolucion';
import ModalPrestamo from './ModalPrestamo';
import ModalBaja from './ModalBaja';
import ModalCrearNumeros from './ModalCrearNumeros';
const {Option} = Select;
const {Search} = Input;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class MovimientoHerramientasContainer extends Component {
    formRef = React.createRef();
    componentDidMount(){
        this.props.onGetAlmacenes();
        this.props.onGetServiciosAct();
        this.props.onGetFoliosPrestamos();
        this.props.onRequestInfoProyecto();
    }
    state = {
        selectedRowKeys: [], 
        selectedRows: [],
        hasSelected: true,
        page: 1,
        busqueda: '',
        tipoMovimiento: undefined,
        idServicio: null,
        idAlmacen: null,
        fechaRetorno: null,
        folioPrestamo: null,
        pageFolioPrestamo: 1
    }
    render() { 

        const {
            fetchingListaHerramientas,
            listaHerramientas,
            fetchingListaAlmacenesActivos,
            listaAlmacenesActivos,
            listaHerramientasCantidad,
            onGetHerramientas,
            listaServiciosActivosF,
            fetchingListaServiciosActivosF,
            prestamoRequest,
            onLimpiarHerramientas,
            fetchingPrestamoHerramientas,
            onShowModalCantidad,
            bajaRequest,
            foliosPrestamos,
            fetchingFoliosPrestamos,
            devolucionRequest,
            onDetalleFolioPrestamo,
            folioPrestamo,
            onLimpiarFolioPrestamo,
            folioPrestamoCantidad,
            fetchingFolioPrestamo,
            onShowModalPrestamo,
            onShowModalBaja,
            onNumeroInventarioPorFolios,
            onShowModalDevolucion,
            onShowModalCrearNumerosInventario,
            onGetNumerosDisponibles,
            listaInfoProyecto
        } = this.props;

        const scroll = {"x": 1600 | true, "y": 1000};
        let disabledFields = ['0']

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        let userInfo = sessionStorage.getItem('userInfo');
        if(!userInfo.idUsuario){
            userInfo =JSON.parse(userInfo);
        }

        const onFinish = formulario => {
            console.log(formulario);

            if(this.state.tipoMovimiento == 'Prestamo'){
                prestamoRequest(formulario, this.state.selectedRows, userInfo[0].nombre, this.state.page, this.state.busqueda, this.state.idAlmacen);
            }

            if(this.state.tipoMovimiento == 'Baja'){
                bajaRequest(this.state.selectedRows, formulario, this.state.page, this.state.busqueda, this.state.idAlmacen)
            }

            if(this.state.tipoMovimiento == 'Devolucion'){
                devolucionRequest(this.state.selectedRows, formulario, folioPrestamo )
            }

            this.formRef.current.resetFields();
            this.setState({
                selectedRowKeys: [],
            })
        }

        const onSearchHerramienta = (param) => {
            const busqueda = param
            this.setState({ 
                busqueda: busqueda
            }) 
            onGetHerramientas(this.state.idAlmacen ,this.state.page, busqueda)
        };
        
        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current
            })
            onGetHerramientas(this.state.idAlmacen ,pagination.current, this.state.busqueda)
        };


        const onChangeTabla = (record, selected ) => {
            console.log('record: ', record);
            console.log('selected: ', selected);
            
            if(record.length !== 0 || selected[0] !== undefined){
                this.setState({ 
                    hasSelected: false 
                });
            }else{
                this.setState({
                    hasSelected: true 
                });
            }
            this.setState({ 
                selectedRowKeys: record, 
                selectedRows: selected 
            });          
        };

        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: onChangeTabla,
        };

        const onChangeAlmacen = (value) => {
            if(value){
                onGetHerramientas(value, this.state.page, this.state.busqueda)
                this.setState({ 
                    idAlmacen: value
                })
            } else {
                onLimpiarHerramientas();
            }
        }; 

        const onChangeTipoMovimiento = (value) => {
            if(value != 'Devolucion') {
                onLimpiarFolioPrestamo();
                this.formRef.current.resetFields(['idAlmacen', 'idServicio', 'folioPrestamo']);
                onLimpiarHerramientas();
            };
            this.setState({ 
                tipoMovimiento: value 
            });
        };

        const showCantidadModal = (key) => {
            console.log('key', key);
            this.props.onGetNumerosInventarioPrestados();
            onGetNumerosDisponibles(key.idProducto, key.idUbicacionProducto)
            
            let tipoMov = this.state.tipoMovimiento;

            if(tipoMov === 'Prestamo'){
                onShowModalPrestamo(key)
            }
            if(tipoMov === 'Baja'){
                onShowModalBaja(key);
            }
            if(tipoMov == 'Devolucion') {
                onNumeroInventarioPorFolios(key.folio, key.idUbicacionProducto, key.idProducto);
                onShowModalDevolucion(key);
            }
        };

        const modalCrearNumerosInventario = (key) => {
            console.log(key);
            onGetNumerosDisponibles(key.idProducto, key.idUbicacionProducto)
            onShowModalCrearNumerosInventario(key);
        }

        const onChangeDevolucion = (value) => {
            this.setState({ 
                folioPrestamo: value 
            })
            onDetalleFolioPrestamo(value, this.state.pageFolioPrestamo);// folio de prestamo
        };

        const  disabledDate = (current) => {
            return current && current < moment().subtract(1, 'd');
        };

    
        const columns = [{
            title: 'Nombre Herramienta',
            dataIndex: 'nombre',
            key: 'nombre',
            align: 'center'
        },{
            title: 'Clave',
            dataIndex: 'clave',
            key: 'clave',
            align: 'center'
        },{
            title: 'Sku',
            dataIndex: 'sku',
            key: 'sku',
            align: 'center'
        }, folioPrestamo && folioPrestamo.length > 0 ? {
           title: 'Cantidad Faltante',
           dataIndex: 'faltantes',
           key: 'faltantes',
           align: 'center'
        }: {
           title: 'Cantidad Disponible',
           dataIndex: 'cantidad',
           key: 'cantidad',
           align: 'center'
        }, this.state.tipoMovimiento == 'Devolucion' ? {
            title: 'Fecha Movimiento',
            dataIndex: 'fechaRealTabla',
            key: 'fechaRealTabla',
            align: 'center'
        }: {},
        
        this.state.tipoMovimiento == 'Prestamo' ? {
            title: 'Cantidad Prestamo',
            dataIndex: 'cantidadPrestamo',
            key: 'cantidadPrestamo',
            align: 'center'
        }: this.state.tipoMovimiento == 'Baja' ? {
            title: 'Cantidad Baja',
            dataIndex: 'cantidadBaja',
            key: 'cantidadBaja',
            align: 'center'
        }: this.state.tipoMovimiento == 'Devolucion' ? {
            title: 'Cantidad Devolucion',
            dataIndex: 'cantidadDevolucion',
            key: 'cantidadDevolucion',
            align: 'center'
        }: {},{
           title: 'Descripción',
           dataIndex: 'descripcion',
           key: 'descripcion',
           align: 'center'
        }, {
            title: 'Presentación',
            dataIndex: 'presentacion',
            key: 'presentacion',
            align: 'center'
        }, {
            title: 'Ubicación',
            dataIndex: 'ubicacion',
            key: 'ubicacion',
            align: 'center'
        }, this.state.tipoMovimiento == 'Prestamo' ? {
            title: 'Agregar Numeros Inventario',
            key: '',
            align: 'center',
            render: (record) => (
               <a className='editarIcono' onClick={ () => modalCrearNumerosInventario(record) }> <EditOutlined />
               </a>
            ),          
        } : {}, {
            title: 'Cantidad',
            key: '',
            align: 'center',
            render: (record) => (
               <a className='editarIcono' disabled={disabledFields.includes(record.cantidad.toString())} onClick={ () => showCantidadModal(record) }> <FormOutlined />
               </a>
            ),          
        },];

        const  disabledDateFechaMovimiento = (fecha) => {
            if( fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicioOperacion) {
                return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicioOperacion;
            } else {
                if(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos){
                return fecha && moment(fecha.format('YYYY-MM-DD')) > moment((listaInfoProyecto.vigencia +  parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2])) + '-' +listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[1] + '-' + listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[0]);
            }
            }
        };
        
    
        return ( 
            <Card className="cardCatalogos" title={<div className="headerLista">Movimientos de Herramientas</div>}>
                <Row justify='space-between'>
                    <Col span={24}>
                        <Form {...layout}
                            name="formulario"
                            onFinish={onFinish}
                            ref={this.formRef}
                        >
                            <Row justify='space-between' gutter={[8,8]}>
                                <Col span={5}>
                                    <Form.Item
                                        name='tipoMovimiento'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <Select 
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Seleccione el tipo de movimiento"
                                            onChange={onChangeTipoMovimiento}
                                        >
                                            <Option value='Prestamo'>Prestamo</Option>
                                            <Option value='Baja'>Baja</Option>
                                            <Option value='Devolucion'>Devolución</Option>
                                        </Select>
                                    </Form.Item>
                                    
                                </Col>

                                <Col span={4}>
                                    <Form.Item
                                        name='fechaMovimiento'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            format='DD-MM-YYYY'
                                            placeholder='Fecha real del movimiento'
                                            disabledDate={disabledDateFechaMovimiento}
                                        />
                                    </Form.Item>
                                    
                                </Col>
                               {this.state.tipoMovimiento != 'Devolucion' ? ( 
                                <>
                                <Col span={5}>
                                    <Form.Item
                                        name='idAlmacen'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}>
                                        <Select 
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Seleccione Almacen"
                                            loading={fetchingListaAlmacenesActivos}
                                            onChange={onChangeAlmacen}
                                        >
                                        {listaAlmacenesActivos.map(option => 
                                            <Option key={option.idAlmacen}>
                                                <Tooltip placement="topLeft" title={option.nombreC}>
                                                { option.nombreC }
                                                </Tooltip>
                                            </Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                </>
                                ): false}

                                {this.state.tipoMovimiento == 'Devolucion' ? (
                                <Col span={10}>
                                    <Form.Item
                                        name='folioPrestamo'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}>
                                        <Select
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el Folio Prestamo"
                                            loading={fetchingFoliosPrestamos}
                                            onChange={onChangeDevolucion}
                                        >
                                            {foliosPrestamos.map(option => <Option key={option}>{ option }</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                ): false}


                                {this.state.tipoMovimiento == 'Prestamo' ? (
                                <>   
                                <Col span={4}>
                                    <Form.Item
                                        name='fechaRetorno'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}>
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            format='DD-MM-YYYY'
                                            placeholder='Fecha de retorno'
                                            disabledDate={disabledDate}
                                        />
                                    </Form.Item>
                                </Col>
                                </>
                                ): false}

                                {this.state.tipoMovimiento == 'Prestamo' ? ( 
                                <Col span={6}>
                                    <Form.Item
                                        name='idServicio'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}>
                                    <Select
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el servicio"
                                        loading={fetchingListaServiciosActivosF}
                                        >
                                            {listaServiciosActivosF.map(option => 
                                            <Option key={option.idServicio}>
                                                <Tooltip placement="topLeft" title={option.nombre + ': ' + option.nombreC}>
                                                { option.nombre + ': ' + option.nombreC }
                                                </Tooltip>
                                            </Option>)}
                                    </Select>
                                    </Form.Item>
                                </Col>
                                ): false}
                    
                            </Row>

                            
                            {this.state.tipoMovimiento === 'Prestamo' ? (
                            <>
                            <Col span={24}>
                                <br/>
                                <Search className='buscarModulos' placeholder='Ingresar nombre, clave, sku..' onSearch={value => onSearchHerramienta(value)} />
                            </Col>
                            <Col span={24}>
                                <Spin spinning={fetchingPrestamoHerramientas}>
                                    <br/>                           
                                    <Table 
                                        size="small" 
                                        rowKey="rowKey" 
                                        columns={columns}
                                        rowSelection={rowSelection}
                                        dataSource={listaHerramientas}
                                        loading={fetchingListaHerramientas}
                                        locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar'}}
                                        onChange={handleTableChange}
                                        pagination={{ total: listaHerramientasCantidad, simple: true  }}
                                        scroll={scroll}
                                        showSorterTooltip={true}
                                        rowClassName={record => record.cantidad === 0 && "disabled-row" }
                                    />  
                                </Spin>                          
                            </Col> 
                            <Col span={24}>
                                <Form.Item
                                    name='nombrePersonal'
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input placeholder='Ingresar usuario que recibe prestamo' maxLength={150} />
                                </Form.Item>
                            </Col>
                            </>
                            ) : this.state.tipoMovimiento === 'Baja' ? ( 
                            <>
                            <Col span={24}>
                                <br/>
                                <Search className='buscarModulos' placeholder='Ingresar nombre, clave, sku..' onSearch={value => onSearchHerramienta(value)} />
                            </Col>
                            <Col span={24}>
                                <Spin spinning={fetchingPrestamoHerramientas}>
                                    <br/>                           
                                    <Table 
                                        size="small" 
                                        rowKey="rowKey" 
                                        columns={columns}
                                        rowSelection={rowSelection}
                                        dataSource={listaHerramientas}
                                        loading={fetchingListaHerramientas}
                                        locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar'}}
                                        onChange={handleTableChange}
                                        pagination={{ total: listaHerramientasCantidad, simple: true  }}
                                        scroll={scroll}
                                        showSorterTooltip={true}
                                    />  
                                </Spin>                          
                            </Col> 
                            </>
                            ) : (    
                            <Col span={24}>
                                <Spin spinning={fetchingPrestamoHerramientas}>
                                    <br/>                           
                                    <Table 
                                        size="small" 
                                        rowKey="rowKey" 
                                        columns={columns}
                                        rowSelection={rowSelection}
                                        dataSource={folioPrestamo}
                                        loading={fetchingFolioPrestamo}
                                        locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar'}}
                                        onChange={handleTableChange}
                                        pagination={{ total: folioPrestamoCantidad, simple: true  }}
                                        scroll={scroll}
                                        showSorterTooltip={true}
                                    />  
                                </Spin>                          
                            </Col> 
                            )}
                        
                            {this.state.tipoMovimiento === 'Prestamo' ? (
                            <Col span={24}>
                                <br/>
                                <Button className="btnFiltrar" disabled={!permisosUsuario.generarPréstamoHerramienta} style={{width: '40%'}} htmlType="submit"  disabled={this.state.hasSelected}> Generar Prestamo</Button> 
                            </Col>
                            ) : this.state.tipoMovimiento == 'Baja' ? (
                            <Col span={24}>
                                <br/>
                                <Button className="btnFiltrar" disabled={!permisosUsuario.generarBajaHerramienta} style={{width: '40%'}} htmlType="submit" disabled={this.state.hasSelected}> Generar Baja</Button>  
                            </Col>
                            ): this.state.tipoMovimiento == 'Devolucion' ? (
                            <Col span={24}>
                                <br/>
                                <Button className="btnFiltrar" disabled={!permisosUsuario.generarDevoluciónHerramienta} style={{width: '40%'}} htmlType="submit" disabled={this.state.hasSelected}> Generar Devolucion</Button>  
                            </Col>
                            ): false }
                        </Form>
                    </Col>
                </Row>
                <ModalDevolucion/>
                <ModalPrestamo/>
                <ModalBaja/>
                <ModalCrearNumeros/>
            </Card>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        fetchingListaHerramientas: state.AlmacenesReducer.fetchingListaHerramientas,
        listaHerramientas: state.AlmacenesReducer.listaHerramientas,
        listaHerramientasCantidad: state.AlmacenesReducer.listaHerramientasCantidad,
        listaAlmacenesActivos: state.AlmacenesReducer.listaAlmacenesActivos,
        fetchingListaAlmacenesActivos: state.AlmacenesReducer.fetchingListaAlmacenesActivos,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        fetchingPrestamoHerramientas: state.AlmacenesReducer.fetchingPrestamoHerramientas,
        foliosPrestamos: state.AlmacenesReducer.foliosPrestamos,
        folioPrestamo: state.AlmacenesReducer.folioPrestamo,
        folioPrestamoCantidad: state.AlmacenesReducer.folioPrestamoCantidad,
        fetchingFolioPrestamo: state.AlmacenesReducer.fetchingFolioPrestamo,

        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onGetNumerosDisponibles: (idProducto, idUbicacionProducto) => {
            dispatch({ type: 'OBTENER_NUMEROS_INVENTARIO_DISPONIBLES_REQUEST', idProducto, idUbicacionProducto})
        },
        onShowModalCrearNumerosInventario: (herrSeleccionada) => {
            dispatch({ type: 'MODAL_CREAR_NUMEROS_INVENTARIO', showModalCrearNumeros: true, herrSeleccionada})
        },
        onGetFoliosPrestamos: () => {
            dispatch({ type: 'OBTENER_FOLIOS_PRESTAMOS_REQUEST' });
        },
        onGetAlmacenes: () => {
            dispatch({ type: "ALMACENES_ACTIVOS_BUSQUEDA_REQUEST"});
        },
        onGetServiciosAct: () => {
            dispatch({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST' });
        },
        onGetHerramientas: (idAlmacen, page, busqueda) => {
            dispatch({ type: "HERRAMIENTAS_BUSQUEDA_REQUEST", idAlmacen, page, busqueda});
        },
        onLimpiarHerramientas: () => {
            dispatch({ type: "HERRAMIENTAS_BUSQUEDA_SUCCESS", listaHerramientas: []})
        },
        prestamoRequest: (formulario, selectedRows,nombre, page, busqueda, idAlmacen ) => {
            dispatch({ type: 'PRESTAMO_HERRAMIENTAS_REQUEST', formulario, selectedRows,nombre, page, busqueda, idAlmacen })
        },
        bajaRequest: (selectedRows, formulario, page, busqueda, idAlmacen ) => {
            dispatch({ type: 'BAJA_HERRAMIENTAS_REQUEST', selectedRows, formulario, page, busqueda, idAlmacen })
        },
        devolucionRequest: (selectedRows, formulario) => {
            dispatch({ type: 'DEVOLUCION_HERRAMIENTAS_REQUEST', selectedRows, formulario })
        },
        onShowModalDevolucion: (herramientaSeleccionada) => {
            dispatch({ type: 'SHOW_MODAL_DEVOLUCION_HERRAMIENTA', showModalDevolucion: true, herramientaSeleccionada})
        },
        onDetalleFolioPrestamo: (folio, pageFolioPrestamo) => {
            dispatch({ type: 'DETALLE_FOLIO_PRESTAMO_REQUEST', folio, pageFolioPrestamo })
        },
        onLimpiarFolioPrestamo: () => {
            dispatch({ type: 'DETALLE_FOLIO_PRESTAMO_SUCCESS', folioPrestamo: [] })
        },
        onShowModalPrestamo: (herramientaSeleccionadaPrestamo) => {
            dispatch({ type: 'SHOW_MODAL_PRESTAMO_HERRAMIENTA', showModalPrestamo: true, herramientaSeleccionadaPrestamo })
        },
        onShowModalBaja: (herramientaSeleccionadaBaja) => {
            dispatch({ type: 'SHOW_MODAL_BAJA_HERRAMIENTA', showModalBaja: true, herramientaSeleccionadaBaja})
        },
        onGetNumerosInventarioPrestados: () => {
            dispatch({ type: 'NUMEROS_INVENTARIO_PRESTADO_REQUEST' })
        },
        onNumeroInventarioPorFolios: (folio, idUbicacionProducto, idProducto) => {
            dispatch({ type: 'OBTENER_NUMEROS_INVENTARIO_FOLIO_REQUEST', folio, idUbicacionProducto, idProducto })
        },
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        },
        
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MovimientoHerramientasContainer);