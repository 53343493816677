import React, { Component } from 'react';
import { Form, Input, Button, Modal, Row, Col, Spin, Select, DatePicker, Upload, message, InputNumber, Typography } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { UploadOutlined, ArrowRightOutlined, FileImageFilled } from '@ant-design/icons';
import { connect } from "react-redux";
import moment from 'moment';
import EditarTurnoPersonal from './EditarTurnoPersonal';

const { Option } = Select;
const { Dragger } = Upload;
const { Text, Paragraph } = Typography;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalEditarPersonal extends Component {
    formRef = React.createRef();
    componentDidMount() {
        this.props.onRequestInfoProyecto();
    }
    state={
        listaImagen: [],
        botonEditar: false,
        formulario: null,
    }
    render() {
        const { fetchingEditarPersonal, showModalEditarPersonal, onShowModalEditarPersonal, onListaServiciosByProveedorLimpiar,
            onListaProveedoresFiltroLimpiar, listaInfoProyecto, personalSeleccionado, listaServiciosByProveedor, 
            fetchingListaServiciosByProveedor, listaParamProveedores, fetchingListaParamProveedores, 
            onListaProveedoresFiltro, onListaServiciosByProveedor, onVistaNuevoPersonal, vistaNuevoPersonal,
        } = this.props;

      

        

        const handleCancel = () => {
            onShowModalEditarPersonal();
            onListaServiciosByProveedorLimpiar();
            onListaProveedoresFiltroLimpiar();
            onVistaNuevoPersonal(1, null);
            this.setState({
                fileImagen: false,
                botonImagen: false,
                botonEditar: false,
            });
        }

        const onChangeImagen = (info) => {
            this.setState({
                botonEditar: true,
            });
            if(info.file.status!== 'removed'){
                console.log('info');
                console.log(info);
                var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['foto']);
                    this.setState({
                        listaImagen: [],
                        botonEditar: false,
                    });
                }
                else{
                    let listaImagen = [info.file];
                    
                    this.setState({
                        listaImagen,
                        botonEditar: false,
                    });
                }
            }
            else{
                this.formRef.current.resetFields(['foto']);
                this.setState({
                    listaImagen: [],
                    botonEditar: false,
                });
            }
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };

        const buscarProveedor = value => {
            if(value.length>=3){
                if(value){
                    onListaProveedoresFiltro(value);
                } else {
                    message.warning('No se encontró proveedor con esas palabras')
                }
            } else {
                onListaProveedoresFiltroLimpiar();
            } 
        }
        const onProveedorSeleccionado = (idProveedor) => {
            console.log('idProveedor');
            console.log(idProveedor);
            this.formRef.current.setFieldsValue({'servicio': null});
            if(idProveedor){
                onListaServiciosByProveedor(idProveedor);
                
            }
            else{
                onListaServiciosByProveedorLimpiar();
            }
        }
        const onSelectDate = (rangeValue) => {
            console.log('listaInfoProyecto');
            console.log(listaInfoProyecto);
            if(listaInfoProyecto.fechaInicio){
                if(rangeValue){
                    const diferencia = listaInfoProyecto.fechaInicio.diff(moment(rangeValue.format('YYYY-MM-DD')), "days");

                    const fechaVigenciaNuevo = listaInfoProyecto.fechaVigencia.split('-')[2] + '-' + listaInfoProyecto.fechaVigencia.split('-')[1] + '-' + listaInfoProyecto.fechaVigencia.split('-')[0];
                    const fechaVigenciaLocal = new Date(fechaVigenciaNuevo);

                    if(diferencia>0){
                        message.error('No es posible seleccionar una fecha de inicio menor al inicio de contrato del proyecto', 7);
                        this.formRef.current.setFieldsValue({'fechaIngreso': null});
                    }

                    if(rangeValue > fechaVigenciaLocal){
                        message.error('No es posible seleccionar una fecha de inicio mayor a la vigencia del contrato del proyecto', 7);
                        this.formRef.current.setFieldsValue({'fechaIngreso': null});
                    }
                }
                
            }
            else{
                message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                this.formRef.current.resetFields(['fechaIngreso'])
            }
        }

        const siguientePaso = formulario => {
            console.log('Success:', formulario);
            
            onVistaNuevoPersonal(0, formulario);
        };

        return (
            <Modal
            visible={showModalEditarPersonal}
            title='Editar Personal'
            onCancel={handleCancel}
            footer={false}
            width={'70%'}
            >
                {personalSeleccionado ? (
                <Spin spinning={fetchingEditarPersonal}>
                    {vistaNuevoPersonal === 1 ? (
                    <Form {...layout}
                    name="formulario"
                    onFinish={siguientePaso}
                    
                    initialValues={personalSeleccionado}
                    ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="RFC"
                                    name="rfc"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={13} minLength={13}
                                    placeholder="Ingresa el RFC" 
                                    pattern='^([A-ZÑ&]{4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$'
                                    title='No es un formato válido para RFC persona física'/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Folio ID"
                                    name="folioID"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={50} placeholder="Ingresa el folio ID a elección" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Número de empleado"
                                    name="numeroEmpleado"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={11} placeholder="Ingresa el número de empleado"
                                        pattern='^[0-9]+$' title='Solo se aceptan números'/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Nombre(s)"
                                    name="nombre"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={50} placeholder="Ingresa el nombre(s)" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Apellido paterno"
                                    name="aPaterno"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={50} placeholder="Ingresa el apellido paterno" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Apellido materno"
                                    name="aMaterno"
                                >
                                    <Input maxLength={50} placeholder="Ingresa el apellido materno" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Correo electrónico"
                                    name="correo"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', type: 'email' }]}
                                >
                                    <Input maxLength={50} placeholder="Ingresa el correo electrónico" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="ID biométrico"
                                    name="idEmpleadoBiometrico"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <InputNumber min={1} placeholder="Ingresa el id biométrico" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Proveedor"
                                    name="proveedor"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Select
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Escribe el proveedor a buscar"
                                        loading={fetchingListaParamProveedores}
                                        showSearch={true}
                                        filterOption={false}
                                        onSearch={buscarProveedor}
                                        onChange={onProveedorSeleccionado}
                                        notFoundContent={null}
                                    >
                                        {listaParamProveedores.map(option => <Option key={option.idProveedor}>{option.razonSocial}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {listaServiciosByProveedor ? (
                            <Col span={11}>
                                <Form.Item
                                    label="Servicio"
                                    name="servicio"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Select
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el servicio"
                                        loading={fetchingListaServiciosByProveedor}
                                    >
                                        {listaServiciosByProveedor.map(option => <Option key={option.idServicio}>{option.nombre}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                             ):false}
                            <Col span={11}>
                                <Form.Item
                                    label="Puesto"
                                    name="puesto"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={100} placeholder="Ingresa el puesto" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Fecha de ingreso"
                                    name="fechaIngreso"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <DatePicker onChange={onSelectDate} placeholder={["Selecciona fecha de ingreso"]} format='DD-MM-YYYY' style={{width: '100%'}} />
                                </Form.Item>
                            </Col>
                            
                            <Col span={11}>
                                <Form.Item
                                    label="Adjuntar fotografía"
                                    name="foto"
                                    valuePropName="upload"
                                >
                                    <Dragger fileList={this.state.listaImagen} customRequest={dummyRequest} onChange={onChangeImagen} accept='.jpg, .jpeg, .png' multiple={false} style={{borderRadius:'1em'}}>
                                        <Row justify='center'>
                                            <FileImageFilled className='iconTabPane'/>
                                            <Col span={24} style={{paddingTop:'1em'}}>
                                                <Paragraph>
                                                    <Text strong> Click o arrastra a ésta área el achivo: jpg, png, jpeg </Text>
                                                </Paragraph>
                                            </Col>
                                        </Row>
                                    </Dragger>
                                </Form.Item>
                            </Col>

                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFormato" disabled={this.state.botonEditar} style={{width: '100%'}} htmlType="submit"><ArrowRightOutlined /> Siguiente Paso</Button>
                            </Col>
                        </Row>
                    </Form>
                    ) : (
                    <EditarTurnoPersonal/>
                    )}
                </Spin>
                ):false}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarPersonal: state.RecursosHumanosReducer.fetchingEditarPersonal,
        showModalEditarPersonal: state.RecursosHumanosReducer.showModalEditarPersonal,
        personalSeleccionado: state.RecursosHumanosReducer.personalSeleccionado,
        fetchingListaServiciosByProveedor: state.CatalogosGeneralesReducer.fetchingListaServiciosByProveedor,
        listaServiciosByProveedor: state.CatalogosGeneralesReducer.listaServiciosByProveedor,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        listaParamProveedores: state.InformacionOperativaReducer.listaParamProveedores,
        fetchingListaParamProveedores: state.InformacionOperativaReducer.fetchingListaParamProveedores,
        vistaNuevoPersonal: state.RecursosHumanosReducer.vistaNuevoPersonal,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarPersonal: (page, personalSeleccionado, tipoTurno) => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_PERSONAL', showModalEditarPersonal: false, page, personalSeleccionado, tipoTurno: null });
        },
        onListaProveedoresFiltro: (busqueda) => {
            dispatch({ type: "LISTA_PROVEEDORES_PARAM_REQUEST", busqueda })
        },
        onListaServiciosByProveedor: (idProveedor) => {
            dispatch({ type: "LISTA_SERVICIOS_BY_PROVEEDOR_REQUEST", idProveedor })
        },
        onListaServiciosByProveedorLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_BY_PROVEEDOR_SUCCESS", listaServiciosByProveedor: null })
        },
        onListaProveedoresFiltroLimpiar: () => {
            dispatch({ type: "LISTA_PROVEEDORES_PARAM_SUCCESS", listaParamProveedores: []})
        },
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        },
        onVistaNuevoPersonal: (vistaNuevoPersonal, formularioPersonal) => {
            dispatch({ type: 'VISTA_NUEVO_PERSONAL', vistaNuevoPersonal, formularioPersonal})
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarPersonal);