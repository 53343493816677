import React, { Component } from 'react';
import { Form, Button, Row, Col, Modal, InputNumber, Input, Divider, message , Select} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const confirm = Modal.confirm;
const {Option} = Select;
class ListaTiempoDeGarantia extends Component {
    formRef = React.createRef();
    componentDidMount(){
        this.props.onGetListaTiempos();
        this.props.onGetUnidadesDeMedicion(1);
    }
    render() { 
        const { 
               onCambiarVista, 
               formularioGarantia, 
               tiempoDeGarantiaSeleccionado,
               listaTiemposTodos,
               fetchingUnidadesMediPorCategoria,
               unidadesMediPorCategoria,
               listaTiemposDeGar,
            } = this.props;
           

        const onFinish = formulario => {
            console.log('formulario entrando onFinish: ', formulario)
            
            let listaTiemposPrimera = []
            let tiemposEditados = []
            let noIguales = true
            for (var key in formulario) {
                if (Object.prototype.hasOwnProperty.call(formulario, key)) {
                    let idCampo = key.split('-');
                    if (idCampo.length === 2) {
                        let idTiempoGarantia = idCampo[1];
                        let campo = idCampo[0];
                        let valor = formulario[key];
    
                        let tiemposFormulario = {[campo]: valor, 'idTiempoGarantia': parseInt(idTiempoGarantia),  'agregar2': true};
                        listaTiemposPrimera.push(tiemposFormulario);
                        
                    }
                }
            }
            for (let i = 0; i < listaTiemposTodos.length; i++) {
                if(isNaN(formularioGarantia.unidadMedida)){
                    if(listaTiemposTodos[i].unidadMedida === formularioGarantia.unidadMedida){
                        formularioGarantia.idUnidadMedida = listaTiemposTodos[i].idUnidadMedida;
                    }
                } else {
                    formularioGarantia.idUnidadMedida = parseInt(formularioGarantia.unidadMedida)
                }
                
            }


            let listaTiempos = [];
            listaTiempos.push(listaTiemposPrimera[0])
            for (let i = 0; i < unidadesMediPorCategoria.length; i++) {
                if(!isNaN(formularioGarantia.unidadMedida)){
                    
                    if(parseInt(formularioGarantia.unidadMedida) === unidadesMediPorCategoria[i].idUnidadMedida){
                        
                        formularioGarantia.unidadMedidaUltima = unidadesMediPorCategoria[i].unidadMedida;
                    } 
                } else {
                    formularioGarantia.unidadMedidaUltima = formularioGarantia.unidadMedida
                }
                
            }
            for (let i = 0; i < listaTiempos.length; i++) {
                
               for( let x = 1; x < listaTiemposPrimera.length; x++){
                  
                    if (listaTiemposPrimera[x].agregar2 ){
                       
                        if(listaTiempos[i].idTiempoGarantia === listaTiemposPrimera[x].idTiempoGarantia){
                            if(isNaN(listaTiemposPrimera[x].unidadMedidaNuevo)){
                                 for (let j = 0; j < unidadesMediPorCategoria.length; j++){
                                
                                    if(listaTiemposPrimera[x].unidadMedidaNuevo === unidadesMediPorCategoria[j].unidadMedida){
                                        listaTiempos[i].idUnidadMedida = unidadesMediPorCategoria[j].idUnidadMedida
                                        
                                    } 
                                } 
                            }else {
                                listaTiempos[i].idUnidadMedida = parseInt(listaTiemposPrimera[x].unidadMedidaNuevo)
                               
                                for (let j = 0; j < unidadesMediPorCategoria.length; j++){
                                
                                    if(parseInt(listaTiemposPrimera[x].unidadMedidaNuevo) === unidadesMediPorCategoria[j].idUnidadMedida){
                                        listaTiemposPrimera[x].unidadMedidaNuevo = unidadesMediPorCategoria[j].unidadMedida
                                        
                                    } 
                                } 
                            }
                            
                            listaTiempos[i].unidadMedidaNuevo = listaTiemposPrimera[x].unidadMedidaNuevo
                            listaTiempos[i].agregar = true;
                            listaTiemposPrimera[x].agregar2 = false;
                        } else {
                           
                            listaTiempos.push(listaTiemposPrimera[x]);
                            listaTiemposPrimera[x].agregar2 = false;
                            x = listaTiemposPrimera.length;
                            console.log(x)
                        }
                    }
               }   
            }
            console.log('primer for lista tiempos ok', listaTiempos)

            for(let i=0; i<listaTiemposTodos.length; i++){
                for(let x=0; x<listaTiempos.length; x++){
                    if(listaTiemposTodos[i].tiempoGarantia === listaTiempos[x].tiempoGarantiaNuevo && listaTiemposTodos[i].idUnidadMedida === listaTiempos[x].idUnidadMedida && listaTiemposTodos[i].idTiempoGarantia === listaTiempos[x].idTiempoGarantia){
                        if(tiempoDeGarantiaSeleccionado.idTiempoGarantia === listaTiempos[x].idTiempoGarantia){
                            listaTiempos[x].agregar = true;
                        }
                        else{
                            listaTiempos[x].agregar = false;
                        }
                    }
                }
            }
            console.log('lista tiempos saliendo del for', listaTiempos);
            console.log('lista listaTiemposTodos', listaTiemposTodos);
            console.log('lformularioGarantia', formularioGarantia);
           
            for (let i = 0; i < listaTiemposTodos.length; i++) {
                for (let x = 0; x < listaTiempos.length; x++) {
                    
                    if (listaTiemposTodos[i].tiempoGarantia !== listaTiempos[x].tiempoGarantiaNuevo || listaTiemposTodos[i].idUnidadMedida !== listaTiempos[x].idUnidadMedida && listaTiempos[x].agregar && listaTiemposTodos[i].idTiempoGarantia === listaTiempos[x].idTiempoGarantia) {
                    
                        if (!tiemposEditados[0]) {
                           
                            if(listaTiempos[x].agregar === true){
                                if (tiempoDeGarantiaSeleccionado.idTiempoGarantia === listaTiempos[x].idTiempoGarantia ) {
                                   
                                    if (formularioGarantia.tiempoGarantia === listaTiempos[x].tiempoGarantiaNuevo && formularioGarantia.idUnidadMedida === listaTiempos[x].idUnidadMedida) {
                                        
                                        listaTiempos[x].nombre = listaTiemposTodos[i].serviciosByIdServicio.nombre
                                        tiemposEditados.push(listaTiempos[x]);
                                        listaTiempos[x].agregar = false;
                                        noIguales = false;
                                    }
                                } else {
                                    
                                    listaTiempos[x].nombre = listaTiemposTodos[i].serviciosByIdServicio.nombre
                                    tiemposEditados.push(listaTiempos[x]);
                                }
                            }
                                

                            
                        }
                       
                        for (let y = 0; y < tiemposEditados.length; y++) {
                          
                            if (tiemposEditados[y].idTiempoGarantia === listaTiempos[x].idTiempoGarantia) {
                                listaTiempos[x].agregar = false;
                            }
                            if (listaTiempos[x].agregar === true) {
                               
                                if (tiempoDeGarantiaSeleccionado.idTiempoGarantia === listaTiempos[x].idTiempoGarantia) {
                                    if (formularioGarantia.tiempoGarantia === listaTiempos[x].tiempoGarantiaNuevo && formularioGarantia.idUnidadMedida === listaTiempos[x].idUnidadMedida) {
                                      
                                        listaTiempos[x].nombre = listaTiemposTodos[i].serviciosByIdServicio.nombre
                                        tiemposEditados.push(listaTiempos[x]);
                                        listaTiempos[x].agregar = false;
                                        noIguales = false;
                                    }
                                }
                                else {
                                    listaTiempos[x].nombre = listaTiemposTodos[i].serviciosByIdServicio.nombre
                                    tiemposEditados.push(listaTiempos[x]);
                                    listaTiempos[x].agregar = false;
                                }
                            }

                        }
                        
                    }

                }// for listaTiempos
            } // for lista todos 
            // const formularioAnterior = this.props.formulario;
           
            if(noIguales){
                message.warning('El valor tiempo de garantia ' + tiempoDeGarantiaSeleccionado.idTiempoGarantia + ', debe ser el mismo que ingresó al editar (' + formularioGarantia.tiempoGarantia + ' ' + formularioGarantia.unidadMedidaUltima + ').')
                this.formRef.current.resetFields(['tiempoGarantia-' + tiempoDeGarantiaSeleccionado.idTiempoGarantia]);
            } else {
                confirm({
                    title: 'Estos son los Tiempos de Garantia que fueron modificados. ¿Estás seguro de modificarlos?',
                    content: 
                    <div>
                        {tiemposEditados.map(tiempoEditado =>
                        <Row justify='space-around' key={tiempoEditado.idTiempoGarantia}>
                            <Divider>{'Tiempo de Garantia ' + tiempoEditado.idTiempoGarantia}</Divider>
                            <Col span={8}>
                                <p><strong>Servicio: </strong>{tiempoEditado.nombre}</p>
                            </Col>
                            <Col span={8}>
                                <p><strong>Tiempo de Garantia: </strong>{tiempoEditado.tiempoGarantiaNuevo}</p>
                            </Col>
                            <Col span={8}>
                                <p><strong>Unidad de Medidad: </strong>{tiempoEditado.unidadMedidaNuevo}</p>
                            </Col>
                            
                        </Row>
                        )}
                    </div>,
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    width: '50%',
                    onOk() {
                        onCambiarVista(4, formularioGarantia, tiemposEditados);
                    },
                    onCancel() {
                        onCambiarVista(1, null);
                    },
                });
            }   
        } // fin onFinish


        

       console.log('lista tiempos todos ', listaTiemposTodos);

        return ( 
            <Form {...layout}
            name="formulario"
            onFinish={onFinish}
            initialValues={listaTiemposDeGar}
            
            ref={this.formRef}
            >
                <div>
                    <h2>Favor de ingresar nuevamente los tiempos de garantia</h2>
                    {listaTiemposTodos.map(tiempoGarantia => 
                        <Row justify='space-around' key={tiempoGarantia.idTiempoGarantia}>
                            <Divider>{'Tiempo de Garantia ' + tiempoGarantia.idTiempoGarantia}</Divider>
                            <Col span={11}>
                                <Form.Item
                                    label="Tiempo de garantia"
                                    name={'tiempoGarantiaNuevo-' + tiempoGarantia.idTiempoGarantia}
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber min={1} max={100} placeholder='Ingrese tiempo de garantia'
                                        title="Número debe ser entero o fracción a dos decímales"
                                        pattern='^[0-9]*(\.[0-9]{0,2})?$' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                    <Form.Item
                                        label="Unidad de medida"
                                        name={"unidadMedidaNuevo-" + tiempoGarantia.idTiempoGarantia} 
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona la unidad de medida"
                                            loading={fetchingUnidadesMediPorCategoria}
                                        >
                                            {unidadesMediPorCategoria.map(option => <Option key={option.idUnidadMedida}>{option.unidadMedida}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Servicio"
                                    name='nombre'
                                >
                                    <Input disabled={true} placeholder={tiempoGarantia.nombre } ></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                        )}
                    
                    <Col span={24} className='marginBoton'>
                        <br/>
                        <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Guardar Cambios</Button>
                    </Col>
                </div>
            </Form>
         );
    }
}

const mapStateToProps = state => {
    return {
        formularioGarantia: state.MecanismosDePagosReducer.formularioGarantia,
        listaTiemposTodos: state.MecanismosDePagosReducer.listaTiemposTodos,
        tiempoDeGarantiaSeleccionado: state.MecanismosDePagosReducer.tiempoDeGarantiaSeleccionado,
        unidadesMediPorCategoria: state.AlmacenesReducer.unidadesMediPorCategoria,
       fetchingUnidadesMediPorCategoria: state.AlmacenesReducer.fetchingUnidadesMediPorCategoria,
       listaTiemposDeGar: state.MecanismosDePagosReducer.listaTiemposDeGar,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCambiarVista: (vistaModalTiempoDeGarantia, formularioGarantia, tiemposEditados) => {
            dispatch({ type: 'CAMBIAR_VISTA_TIEMPODEGARANTIA', vistaModalTiempoDeGarantia: vistaModalTiempoDeGarantia, formularioGarantia: formularioGarantia, tiemposEditados: tiemposEditados})
        },
        onGetListaTiempos: () => {
            dispatch({ type: 'LISTA_TIEMPODEGARANTIA_TODOS_REQUEST'})
        },
        onGetUnidadesDeMedicion: (idCategoriaUnidadMedida) => {
            dispatch({ type: "UNIDADES_MEDI_POR_CATEGORIA_REQUEST", idCategoriaUnidadMedida:idCategoriaUnidadMedida});
        },
        onGetTiempos: () => {
            dispatch({ type: "LISTA_TIEMPODEGARANTIA_FORM_REQUEST"})
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ListaTiempoDeGarantia);