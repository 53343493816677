import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Form, DatePicker, Checkbox, Tooltip } from 'antd';
import { EditOutlined, SearchOutlined, FileSearchOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoContrato from './ModalNuevoContrato';
import ModalEditarContrato from './ModalEditarContrato';
import ModalArchivoContrato from './ModalArchivoContrato';

const confirm = Modal.confirm;
class CatalogoDeContratos extends React.Component {
    formRef = React.createRef();
    componentDidMount(){
        const formulario = {
            proveedor: '',
            servicio: null,
            fechaFin: '',
        };
        this.props.onGetContratos(1, formulario);
        this.props.onRequestInfoProyecto();
    }
    state={
        page: 1,
        formulario: {
            proveedor: '',
            servicio: null,
            fechaFin: '',
        },
    }
    render() {
        const { fetchingListaContratosBusqueda ,listaContratosBusqueda, onEstadoContrato, onGetContratos, 
            onModalEditarContrato, onModalNuevoContrato , onModalArchivoContrato, listaContratosBusquedaCantidad,
            onGetContratosSinInfo,
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        const onFinish = formulario => {
            this.setState({
                formulario: formulario,
                page: 1,
            })
            console.log(formulario);
            onGetContratos(1, formulario);
        }

        
        const onVerArchivo = (key) => {
            console.log(key);
            onModalArchivoContrato(key);
        }
        
        const columns = [{
                title: 'Clave',
                dataIndex: 'clave',
                key: 'clave',
                width: '8%',
            },{
                title: 'Descripción',
                dataIndex: 'descripcion',
                key: 'descripcion',
                width: '14%',
            },{
                title: 'Nombre archivo',
                dataIndex: 'nombreArchivo',
                key: 'nombreArchivo',
                width: '8%',
            },{
                title: 'Ruta archivo',
                dataIndex: 'rutaArchivo',
                key: 'rutaArchivo',
                width: '12%',
            },{
                title: 'Nombre servicio',
                dataIndex: 'nombreServicio',
                key: 'nombreServicio',
                width: '7%',
            },{
                title: 'Nombre de contacto',
                dataIndex: 'nombreProveedor',
                key: 'nombreProveedor',
                width: '7%',
            },{
                title: 'Fecha inicio vigencia',
                dataIndex: 'fechaInicio',
                key: 'fechaInicio',
                width: '6%',
            },{
                title: 'Fecha fin vigencia',
                dataIndex: 'fechaFin',
                key: 'fechaFin',
                width: '6%',
            },{
                title: 'Fecha alta',
                dataIndex: 'fechaAlta',
                key: 'fechaAlta',
                width: '6%',
            },{
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                width: '5%',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.contratoActivoInactivo} onChange={() => handleDesactivar(record)}/>
                ),
            },{
                title: 'Ver archivo',
                key: 'ver',
                width: '4%',
                render: (text, record) => (
                    <a className='editarIcono' onClick={() => onVerArchivo(record)}><FileSearchOutlined /></a>
                ),
            },{
                title: 'Editar',
                key: 'editar',
                width: '4%',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarContrato} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },
        ];
        const onEditar = (key) => {
            console.log(key);
            onModalEditarContrato(key, this.state.page);
        }
        const handleDesactivar = (key) => {
            console.log(key);
            const page = this.state.page;
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del contrato "'+ key.clave +'"?',
                content: <strong style={{color: '#ff7875'}}>Los servicios con este contrato asignado se verán afectados.</strong>,
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoContrato(key.idContrato, page);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            if(this.state.sinFiltros){
                onGetContratosSinInfo(pagination.current);
            }
            else{
                onGetContratos(pagination.current, this.state.formulario);
            }
		}
        const onNuevoContrato = () => {
            onModalNuevoContrato(this.state.page);
        }
        const onSinInfo = (value) => {
            if(value.target.checked === true){
                onGetContratosSinInfo(this.state.page);
            }
            else{
                const formulario = {
                    proveedor: '',
                    servicio: null,
                    fechaFin: '',
                };
                onGetContratos(1, formulario);
            }
            this.setState({
                sinFiltros: value.target.checked,
            })
            this.formRef.current.resetFields();
        }
        const scroll = {"x": 2000 | true, "y": 1000};
        return (
            <div>
                <Row justify='space-between'>
                    <Col span={24}>
                        <Form
                        name="formulario"
                        onFinish={onFinish}
                        
                        ref={this.formRef}
                        >
                            <Row justify='space-around' gutter={[8,8]}>
                                <Col span={7}>
                                    <Form.Item
                                    name="proveedor"
                                    >
                                        <Input disabled={this.state.sinFiltros} className='inputFiltro' placeholder="Ingrese proveedor a filtrar"/>
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        name="servicio"
                                    >
                                        <Input disabled={this.state.sinFiltros} className='inputFiltro' placeholder="Ingrese servicio a filtrar"/>
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        name="fechaFin"
                                    >
                                        <DatePicker disabled={this.state.sinFiltros} style={{width: '100%'}} format='DD-MM-YYYY' placeholder='Ingrese fecha fin vigencia'/>
                                    </Form.Item>
                                </Col>
                                <Col span={1}>
                                    <Button disabled={this.state.sinFiltros} shape="circle" icon={<SearchOutlined />} className="btnFiltrar" htmlType="submit"></Button>
                                </Col>
                                <Col span={1}>
                                    <Tooltip title='Nuevo Contrato' placement="topRight">
                                        <Button className="iconTer" shape="circle" onClick={onNuevoContrato} disabled={!permisosUsuario.crearContrato} icon={<PlusOutlined />}/>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={5}>
                        <Tooltip title="Mostrar los registros que no cuentan con un servicio y/o un proveedor">
                            <Checkbox defaultChecked={false} onChange={onSinInfo}>Sin información</Checkbox>
                        </Tooltip>
                    </Col>
                    {/* <Col offset={13} span={5}>
                        <Button onClick={onNuevoContrato} disabled={!permisosUsuario.crearContrato} className='nuevoUsuario'>Nuevo Contrato</Button>
                        <br/>
                        <br/>
                    </Col> */}
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="clave" 
                            columns={columns}
                            dataSource={listaContratosBusqueda}
                            loading={fetchingListaContratosBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaContratosBusquedaCantidad, simple: true, current: this.state.page}}
                            scroll={scroll}
                        />         
                    </Col>
                </Row>
                <ModalNuevoContrato/>
                <ModalEditarContrato/>
                <ModalArchivoContrato/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaContratosBusqueda: state.InformacionOperativaReducer.listaContratosBusqueda,
        fetchingListaContratosBusqueda: state.InformacionOperativaReducer.fetchingListaContratosBusqueda,
        listaContratosBusquedaCantidad: state.InformacionOperativaReducer.listaContratosBusquedaCantidad,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetContratos: (page, formulario) => {
	    	dispatch({ type: "LISTA_CONTRATOS_BUSQUEDA_REQUEST", page: page, formulario: formulario });
        },
        onEstadoContrato: (idContrato, page) => {
	    	dispatch({ type: "ESTADO_CONTRATO_REQUEST", idContrato: idContrato, page: page });
        },
        onModalNuevoContrato: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_CONTRATO", showModalNuevoContrato: true, page: page});
        },
        onModalEditarContrato: (contratoSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_CONTRATO", showModalEditarContrato: true, contratoSeleccionado: contratoSeleccionado, page: page});
        },
        onModalArchivoContrato: (contratoSeleccionado, page) => {
            dispatch({ type: 'SHOW_MODAL_ARCHIVOS_CONTRATO', showArchivosContrato: true, contratoSeleccionado: contratoSeleccionado, page: page})
        },
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        },
        onGetContratosSinInfo: (page) => {
            dispatch({ type: 'LISTA_CONTRATOS_SIN_INFO_REQUEST', page: page })
        }
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoDeContratos);