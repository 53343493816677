import React, { Component } from 'react';
import { connect } from "react-redux";
import { Form, Input, Button, Col, Spin, Modal, Row , InputNumber, Select, message, Popover} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

import L from 'leaflet';
import { Map, ImageOverlay, FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const {Option} = Select;

class ModalNuevoInterior extends Component {
    formRef = React.createRef();

    state = {
        fileImage: false,
        botonImage: false,
        botonEditar: false,
        fetchingImagen: false,
        imageUrl: null,
        height: 0,
        width: 0,
        geometry: [],
    }

    render() { 
        const {
            onNuevoInterior,
            onShowModalNuevoInterior,
            showModalNuevoInterior,
            fetchingNuevoInterior,
            page,
            limpiarFormulario,
            onLimpiarFormulario, listaUnidadesFuncioActCompleta, fetchingListaUnidadesFuncioActCompleta,
            onGetListaUniFuncioActBusqueda, onGetListaUniFuncioActBusquedaLimpiar,
        } = this.props;

        if (limpiarFormulario && limpiarFormulario==='crearInterior') {
            this.formRef.current.resetFields();
            onLimpiarFormulario();
            onGetListaUniFuncioActBusquedaLimpiar();
            this.setState({
                fileImage: false,
                botonImage: false,
                botonEditar: false,
                fetchingImagen: false,
                imageUrl: null,
                height: 0,
                width: 0,
                geometry: [],
            });
        }
        // const enteroInput = (value) => {
        //     this.formRef.current.setFieldsValue({'cantidad': parseInt(value)});
        // }

        const handleUnidad = (value, data) => {
            console.log(value, data);
            this.setState({
                fetchingImagen: true,
            })
            if(data) {
                const {rutaImagen} = data.data;
                const img = new Image();
                const updateState = (width, height) => {
                this.setState({
                    ...this.state,
                    imageUrl: rutaImagen,
                    width, 
                    height,
                    fetchingImagen: false,
                    });
                }
                img.onload = function() {
                    console.log(this.width + 'x' + this.height);
                    updateState(this.width, this.height);
                }
                img.src = rutaImagen;

                if(!rutaImagen){
                    return this.setState({...this.state, imageUrl: null, width: 0, height: 0});
                }

            }
            this.setState({...this.state, imageUrl: null, width: 0, height: 0});
        }

        const buscarUnidadFuncional = (value) => {
            if(value.length>=3){
                if (value) {
                    console.log(value)
                    onGetListaUniFuncioActBusqueda(value);
                } else {
                    message.warning('No se encontró unidad funcional con esas palabras')
                }
            }
            else {
                onGetListaUniFuncioActBusquedaLimpiar();
            }
        };

        const onFinish = formulario => {
            const {geometry, imageUrl} = this.state;
            if(!imageUrl){
                return message.error('La unidad funcional seleccionada no cuenta con un plano. Favor de seleccionar una unidad funcional con plano.');
            }
            if(geometry.length>1){
                return message.error('El interior funcional cuenta con más de una geometría. Favor de trazar solo una geometría.');
            }
            if(geometry.length === 0) {
                return message.error('Favor de trazar una geometría para el interior funcional');
            }
            console.log('Success:', formulario, geometry);
            onNuevoInterior(formulario, geometry, page);

        };

        const handleCancel = () => {
            onShowModalNuevoInterior();
            onGetListaUniFuncioActBusquedaLimpiar();
            this.formRef.current.resetFields();
            this.setState({
                fileImage: false,
                botonImage: false,
                botonEditar: false,
                fetchingImagen: false,
                imageUrl: null,
                height: 0,
                width: 0,
                geometry: [],
            });
        };

        const bounds = [
            [0, 0],
            [this.state.height, this.state.width],
        ]
        const style = { height: '30em', width: '100%' }

        const setGeometry = (layer) => {
            this.setState({...this.state, geometry: [...this.state.geometry, layer]});
        }
        const _created = (e) => {
            const {layer, layerType} = e;
            if(this.state.geometry.length>0){
                message.warning('No se permite más de una geometría para el área funcional');
            }
            if(layerType === 'polygon') {
                const {_leaflet_id} = layer;
                setGeometry({id: _leaflet_id, latlngs: layer.getLatLngs()[0]});
            }
            
        }

        const deleteGeometry = (geometriesD) => {
            let geoArray = [...this.state.geometry]
            geometriesD.map(deleted => {
                geoArray = geoArray.filter(geo => geo.id !== deleted)
            });
            
            this.setState({...this.state, geometry: geoArray});
        }
        const _deleted = (e) =>{ 
            const {layers: {_layers}} = e;
            let deletedArray = [];
            Object.values(_layers).map(({_leaflet_id, editing}) =>{
                //edited array
                deletedArray = [...deletedArray, _leaflet_id ]
            });
            deleteGeometry(deletedArray);
        }

        const getGeoJson = () => {
            console.log(`this.state.geometry`, this.state.geometry)
            return {
                type: "FeatureCollection",
                features: [{
                    type: "Feature",
                    properties: {},
                    geometry: {
                        type: "Polygon",
                        coordinates: 
                            this.state.geometry
                    }
                }]
            }
        }

        const _onFeatureGroupReady = reactFGref => {
            // populate the leaflet FeatureGroup with the geoJson layers
            if(!reactFGref ) return;

            let leafletGeoJSON = new L.GeoJSON(getGeoJson());
            let leafletFG = reactFGref.leafletElement;
            
            let layers = leafletFG._layers;
            let layers_ids = Object.keys(layers);
            
            if(layers_ids.length === 0) {
                //add layers
                leafletGeoJSON.eachLayer(layer => {
                    leafletFG.addLayer(layer);
                });
            }
        };

        return ( 
            <Modal
            visible={showModalNuevoInterior}
            title='Nuevo Interior Funcional'
            onCancel={handleCancel}
            footer={false}
            width={'80%'}
            >
                <Spin spinning={fetchingNuevoInterior}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Unidad funcional a la que pertenece"
                                    name="idUnidadFuncional"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Seleccione la unidad funcional"
                                        loading={fetchingListaUnidadesFuncioActCompleta}
                                        showSearch
                                        filterOption={false}
                                        onSearch={buscarUnidadFuncional}
                                        onChange={handleUnidad}
                                        notFoundContent={null}
                                    >
                                        {listaUnidadesFuncioActCompleta.map(option => 
                                        <Option key={option.idUnidadFuncional} data={option}>
                                            <Popover content={option.unidadFuncional} title={false}>
                                                {option.unidadFuncional}
                                            </Popover>
                                        </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Interior funcional"
                                    name="interiorFuncional"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={100} placeholder="Ingrese el interior funcional" />
                                </Form.Item>
                            </Col>
                            {/* <Col span={11}>
                                <Form.Item
                                    label="Cantidad"
                                    name="cantidad"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber min={1} max={100} onChange={enteroInput} placeholder="Ingrese la cantidad" />
                                </Form.Item>
                            </Col> */}
                            <Col span={23}>
                                <Spin spinning={this.state.fetchingImagen}>
                                    {this.state.imageUrl ? (<p>Geometría</p>):(<p>Favor de seleccionar una área funcional con plano</p>)}
                                    
                                    {this.state.imageUrl ? (
                                    <Map
                                        crs={L.CRS.Simple}
                                        minZoom={-4}
                                        bounds={bounds}
                                        style={style}
                                    >
                                        <FeatureGroup
                                            ref={reactFGref => {
                                            _onFeatureGroupReady(reactFGref);
                                            }}
                                        >
                                            <EditControl
                                                position="topright"
                                                onCreated={_created}
                                                onDeleted={_deleted}
                                                draw={{
                                                    circle: false,
                                                    circlemarker: false,
                                                    marker: false,
                                                    rectangle: false,
                                                    polyline: false,
                                                }}
                                                edit={{
                                                    remove: true,
                                                    edit: false,
                                                }}
                                            />
                                        </FeatureGroup>
                                        <ImageOverlay
                                            url={this.state.imageUrl}
                                            bounds={bounds}
                                            zIndex={10}
                                        />
                                    </Map>
                                    ):false}
                                </Spin>
                            </Col>

                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Nuevo Interior Funcional</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}
 
const mapStateToProps = state => {
	return {
        fetchingNuevoInterior: state.CatalogosGeneralesReducer.fetchingNuevoInterior,
        showModalNuevoInterior: state.CatalogosGeneralesReducer.showModalNuevoInterior,
        page: state.CatalogosGeneralesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaUnidadesFuncioActCompleta: state.CatalogosGeneralesReducer.fetchingListaUnidadesFuncioActCompleta,
        listaUnidadesFuncioActCompleta: state.CatalogosGeneralesReducer.listaUnidadesFuncioActCompleta,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalNuevoInterior: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_INTERIOR', showModalNuevoInterior: false })
        },
        onNuevoInterior: (formulario, geometry, page) => {
            dispatch({ type: "NUEVO_INTERIOR_REQUEST" , formulario, geometry, page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        },
        onGetListaUniFuncioActBusqueda: (busqueda) => {
            dispatch({ type: 'LISTA_UNI_FUNCIO_ACT_COMPLETA_REQUEST', busqueda: busqueda });
        },
        onGetListaUniFuncioActBusquedaLimpiar: () => {
            dispatch({ type: 'LISTA_UNI_FUNCIO_ACT_COMPLETA_SUCCESS', listaUnidadesFuncioActCompleta: [] });
        },
        onShowModalMapa: () => {
            dispatch({ type: 'SHOW_MODAL_MAPA_INTERIOR', showModalMapaInterior: true})
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoInterior);