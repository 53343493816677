import React, { Component } from 'react';
import { Form, Divider, Button, Row, Col, Spin, Select, TimePicker, Tooltip } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalEditarTurno extends Component {
    formRef = React.createRef();
    render() {
        const { fetchingEditarPersonal, page, busqueda, orden, formularioPersonal, onEditarPersonal,
            personalSeleccionado, tipoTurno, onCambiarTipoTurno, limpiarFormulario, onLimpiarFormulario, 
            onVistaNuevoPersonal, onListaServiciosByProveedorLimpiar, onListaProveedoresFiltroLimpiar,
        } = this.props;

        if (limpiarFormulario && limpiarFormulario === 'editarPersonal') {
            onLimpiarFormulario();
            onVistaNuevoPersonal(1, null);
            onListaServiciosByProveedorLimpiar();
            onListaProveedoresFiltroLimpiar();
        }
        
        const onFinish = formulario => {
            console.log('Success:', formulario);
            console.log('formularioPersonal', formularioPersonal)
            formulario = Object.assign(formulario, formularioPersonal);

            const horarioEntrada = formulario['horarioEntradaForm'];
            const horarioSalida = formulario['horarioSalidaForm'];

            if(horarioEntrada!==undefined){
                formulario.horarioEntrada = horarioEntrada.format('HH:mm:ss');
                formulario.horarioSalida = horarioSalida.format('HH:mm:ss');
                console.log('formulario');
                console.log(formulario);
                onEditarPersonal(formulario, page, busqueda, orden, personalSeleccionado );
            }
            else{
                formulario.horarioEntrada = null;
                formulario.horarioSalida = null;
                onEditarPersonal(formulario, page, busqueda, orden, personalSeleccionado );
            }
        };
        const onTipoHorario = (tipoHorario) => {
            onCambiarTipoTurno(personalSeleccionado, this.props.page, tipoHorario )
        }
        const onRegresar = () => {
            onVistaNuevoPersonal(1, formularioPersonal)
        }
        return (
            <Spin spinning={fetchingEditarPersonal}>
                <Col span={5}>
                    <Button className="btnFormato" style={{width: '100%'}} onClick={onRegresar}><ArrowLeftOutlined /> Paso Anterior</Button>
                </Col>
                <Divider>Turno y Horario</Divider>
                {personalSeleccionado ? (
                <Form {...layout}
                name="formulario"
                onFinish={onFinish}
                initialValues={personalSeleccionado}
                ref={this.formRef}
                >
                    <Row justify='space-around'>
                        <Col span={11}>
                            <Form.Item
                                label="Días laborales"
                                name="diasLaboralesLista"
                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona los días laborales"
                                >
                                    <Option key='Domingo'>Domingo</Option>
                                    <Option key='Lunes'>Lunes</Option>
                                    <Option key='Martes'>Martes</Option>
                                    <Option key='Miércoles'>Miércoles</Option>
                                    <Option key='Jueves'>Jueves</Option>
                                    <Option key='Viernes'>Viernes</Option>
                                    <Option key='Sábado'>Sábado</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="Tipo de turno"
                                name="tipoTurno"
                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                            >
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona el tipo de turno"
                                    onChange={onTipoHorario}
                                >
                                    <Option key='Corrido'>Corrido</Option>
                                    <Option key='Libre'>Libre</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {tipoTurno === 'Corrido' ?(
                        <Col span={11}>
                            <Row justify='space-between'>
                                <Col span={11}>
                                    <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                        <Form.Item
                                            label="Horario entrada"
                                            name="horarioEntradaForm"
                                            rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                            <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de entrada"]} style={{width: '100%'}}/>
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={11}>
                                    <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                        <Form.Item
                                            label="Horario salida"
                                            name="horarioSalidaForm"
                                            rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                            <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de salida"]} style={{width: '100%'}}/>
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Col>
                        ):false}

                        <Col span={11} className='marginBoton'>
                            <br/>
                            <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Editar Personal</Button>
                        </Col>
                    </Row>
                </Form>
                ):false}
            </Spin>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarPersonal: state.RecursosHumanosReducer.fetchingEditarPersonal,
        showModalEditarTurno: state.RecursosHumanosReducer.showModalEditarTurno,
        tipoTurno: state.RecursosHumanosReducer.tipoTurno,
        busqueda: state.RecursosHumanosReducer.busqueda,
        orden: state.RecursosHumanosReducer.orden,
        page: state.RecursosHumanosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        personalSeleccionado: state.RecursosHumanosReducer.personalSeleccionado,
        formularioPersonal: state.RecursosHumanosReducer.formularioPersonal
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onCambiarTipoTurno: (personalSeleccionado, page, tipoTurno) => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_PERSONAL', showModalEditarPersonal: true, personalSeleccionado, page, tipoTurno });
        },
        onEditarPersonal: ( formulario, page, busqueda, orden, personalSeleccionado ) => {
            dispatch({ type: "EDITAR_PERSONAL_REQUEST" , formulario, page, busqueda, orden, personalSeleccionado });
        },
        onVistaNuevoPersonal: (vistaNuevoPersonal, formularioPersonal) => {
            dispatch({ type: 'VISTA_NUEVO_PERSONAL', vistaNuevoPersonal, formularioPersonal})
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onListaServiciosByProveedorLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_BY_PROVEEDOR_SUCCESS", listaServiciosByProveedor: null })
        },
        onListaProveedoresFiltroLimpiar: () => {
            dispatch({ type: "LISTA_PROVEEDORES_PARAM_SUCCESS", listaParamProveedores: []})
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarTurno);