import React, { Component } from 'react';
import { Row, Form, Input, Button, Modal, Col, Spin, Select } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Option } = Select;
class ModalEditarTipoFalla extends Component {
    formRef = React.createRef();
    render() {
        const { fetchingEditarTipoFalla, showModalEditarTipoFalla, onEditarTipoFalla, onShowModalEditarTipoFalla,
            limpiarFormulario, onLimpiarFormulario, tipoFallaSeleccionado,
        } = this.props;

        const onFinish = formulario => {
            onEditarTipoFalla(formulario, this.props.page, this.props.busqueda, tipoFallaSeleccionado);
        };

        if (limpiarFormulario && limpiarFormulario === 'crearTipoFalla') {
            this.formRef.current.resetFields();
            onLimpiarFormulario();
        }

        const handleCancel = () => {
            onShowModalEditarTipoFalla(this.props.page, this.props.busqueda);
            this.formRef.current.resetFields();
        }

        return (
            <Modal
                visible={showModalEditarTipoFalla}
                title='Editar Tipo de Falla'
                onCancel={handleCancel}
                footer={false}
                width='50%'
            >
                <Spin spinning={fetchingEditarTipoFalla}>
                    <Form {...layout}
                        name="formulario"
                        onFinish={onFinish}
                        ref={this.formRef}
                        initialValues={tipoFallaSeleccionado}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Clave"
                                    name="clave"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={5} placeholder="Ingresa la clave"
                                        title="Clave debe contener sólo letras mayúsculas, números y espacios" pattern="^[A-ZÑ0-9 ÁÉÓÚÍ]+$" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Nombre del tipo de falla"
                                    name="nombre"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={100} placeholder="Ingresa el nombre de tipo de falla"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Descripción"
                                    name="descripcion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={250} placeholder="Ingresa la descripción"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Categoría de falla"
                                    name="tipo"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                     <Select
                                        style={{ width: '100%', textAlign: 'left' }}
                                        placeholder="Selecciona la categoría de falla"
                                    >
                                        <Option key='General'>General</Option>
                                        <Option key='Específica'>Específica</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                           
                            <Col span={11} className='marginBoton'>
                                <br />
                                <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Editar Tipo de Falla</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingEditarTipoFalla: state.SolicitudDeServiciosReducer.fetchingEditarTipoFalla,
        showModalEditarTipoFalla: state.SolicitudDeServiciosReducer.showModalEditarTipoFalla,
        page: state.SolicitudDeServiciosReducer.page,
        busqueda: state.SolicitudDeServiciosReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        tipoFallaSeleccionado: state.SolicitudDeServiciosReducer.tipoFallaSeleccionado,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarTipoFalla: (page, busqueda) => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_TIPO_FALLA', showModalEditarTipoFalla: false, page, busqueda })
        },
        onEditarTipoFalla: (formulario, page, busqueda, tipoFallaSeleccionado) => {
            dispatch({ type: "EDITAR_TIPO_FALLA_REQUEST", formulario, page, busqueda, tipoFallaSeleccionado });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarTipoFalla);