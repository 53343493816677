export const SHOW_ERROR_MSG = "SHOW_ERROR_MSG";
export const SHOW_SUCCESS_MSG = "SHOW_SUCCESS_MSG";

export const GET_INFOPROYECTO_REQUEST = 'GET_INFOPROYECTO_REQUEST';
export const GET_INFOPROYECTO_SUCCESS = 'GET_INFOPROYECTO_SUCCESS';
export const GET_INFOPROYECTO_FAILURE = 'GET_INFOPROYECTO_FAILURE';

export const EDITAR_INFOPROYECTO_REQUEST = 'EDITAR_INFOPROYECTO_REQUEST';
export const EDITAR_INFOPROYECTO_SUCCESS = 'EDITAR_INFOPROYECTO_SUCCESS';
export const EDITAR_INFOPROYECTO_FAILURE = 'EDITAR_INFOPROYECTO_FAILURE';

export const NUEVO_INFOPROYECTO_REQUEST = 'NUEVO_INFOPROYECTO_REQUEST';
export const NUEVO_INFOPROYECTO_SUCCESS = 'NUEVO_INFOPROYECTO_SUCCESS';
export const NUEVO_INFOPROYECTO_FAILURE = 'NUEVO_INFOPROYECTO_FAILURE';