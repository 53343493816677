import React,{useState, useEffect} from 'react';
import {connect} from 'react-redux';
import { Card, Row, Col,Button, Table, Input, Tooltip } from 'antd';
import { EditOutlined, CloseCircleOutlined, FilePdfOutlined, PlusOutlined } from '@ant-design/icons';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoFolio from './ModalNuevoFolioConciliacion';
import ModalEditarFolio from './ModalEditarFolioConciliacion';
import ModalCerrarFolio from './ModalCerrarFolioConciliación';

const Search = Input.Search;

const FolioConciliacion = (props) => {
    const {fetchingFoliosConciliacion,listaFoliosConciliacion,listaFolicosConciliacionCantidad,fetchingImprimirFolioConciliacion,
        onListaFolios,onMesConciliacion, onModalNuevoFolio, onDescargarPDF, onModalEditarFolio, onModalCerrarFolio, onGetTicketsByFolio} = props;

    const [state, setState] = useState({page:1, busqueda:""});
    const {page, busqueda} = state;

    let permisosUsuario = sessionStorage.getItem('permisosUsuario');
    if (permisosUsuario[0]) {
        permisosUsuario = JSON.parse(permisosUsuario);
    }

    useEffect (() => {
        onListaFolios(busqueda, page);
        onMesConciliacion()
    },[])

    const columns = [
        {title: 'Folio de conciliación', dataIndex: 'folioConciliacion', key: 'folioConciliacion'},
        {title: 'Año', dataIndex: 'anio', key: 'anio'},
        {title: 'Mes', dataIndex: 'mes', key: 'mes'},
        {title: 'Número de tickets', dataIndex: 'numeroTickets', key: 'numeroTickets', align:'center'},
        {title: 'Fecha de generación', dataIndex: 'fechaAlta', key: 'fechaAlta'},
        {title: 'Imprimir', key: 'imprimir',align: 'center',width: '7%',
            render: (text, record) => <a className='editarIcono' disabled={!permisosUsuario.imprimirFolioDeConciliación || fetchingImprimirFolioConciliacion}  onClick={() => imprimirFolio(record)} ><FilePdfOutlined /></a>
        },
        {title: 'Editar', key: 'cancelar',align: 'center',width: '7%',
            render: (text, record) => <a className='editarIcono' disabled={!permisosUsuario.editarFolioDeConciliación || !record.estatus}  onClick={() => editarFolio(record)} ><EditOutlined /></a>
        },
        {title: 'Cerrar', key: 'cancelar',align: 'center',width: '7%',
            render: (text, record) => <a className='editarIcono' disabled={!permisosUsuario.cerrarFolioDeConciliación || !record.estatus}  onClick={() => cerrarFolio(record)} ><CloseCircleOutlined /></a>
        }
    ];

    const onBuscar = ( value ) => {
        const busqueda = value;
        console.log(value);
        setState({...state, busqueda});
        onListaFolios(busqueda, state.page);
    }

    const onNuevoFolio = () => {
        console.log('Nuevo');
        onModalNuevoFolio(busqueda, page)
    }

    const imprimirFolio = record => {
        const {idFolioConciliacion} = record;
        onDescargarPDF(idFolioConciliacion)
    }

    const editarFolio = record => {
        const {idFolioConciliacion} = record;
        onModalEditarFolio (record, busqueda, page);
        onGetTicketsByFolio(idFolioConciliacion, 1);
    }

    const cerrarFolio = record => {
        onModalCerrarFolio (record, busqueda, page);
    }

    const handleTable = (pagination) => {
        const page = pagination.current;
        setState({...state, page});
        onListaFolios(state.busqueda, pagination.current);
    }
    

    return (
        <>
            <Card className="cardCatalogos" title={<div className='headerLista'>Folio de Conciliación</div>} >
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search className='buscarModulos' placeholder='Ingrese numero de folio de conciliación' onSearch={(value)=> onBuscar(value)} />
                    </Col>
                    {/* <Col span={8}>
                        <Button onClick={onNuevoFolio} disabled={!permisosUsuario.crearFolioDeConciliación} className='nuevoUsuario'>Nuevo Folio Conciliación</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nuevo Folio Conciliación' placement="topRight">
                                <Button className="iconTer" shape="circle" onClick={onNuevoFolio} disabled={!permisosUsuario.crearFolioDeConciliación} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                </Row>
                <Row gutter={[8,8]}>
                    <Col span={24}>
                        <Table 
                            size="small"
                            rowKey="folioConciliacion"
                            columns={columns}
                            dataSource={listaFoliosConciliacion}
                            loading={fetchingFoliosConciliacion}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTable}
                            pagination={{ total: listaFolicosConciliacionCantidad, simple: true, current: state.page }}

                        />
                    </Col>
                </Row>
            </Card>
            <ModalNuevoFolio />
            <ModalEditarFolio />
            <ModalCerrarFolio />
        </>
    )
}

const mapStateToProps = state => {
    return {
        fetchingFoliosConciliacion: state.MecanismosDePagosReducer.fetchingFoliosConciliacion,
        listaFoliosConciliacion: state.MecanismosDePagosReducer.listaFoliosConciliacion,
        listaFolicosConciliacionCantidad: state.MecanismosDePagosReducer.listaFolicosConciliacionCantidad,
        fetchingImprimirFolioConciliacion: state.MecanismosDePagosReducer.fetchingImprimirFolioConciliacion,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onListaFolios: (busqueda, page) => {
            dispatch({type:'FOLIO_CONCILIACION_BUSQUEDA_REQUEST', busqueda, page});
        },
        onMesConciliacion: () => {
            dispatch({type:'MES_FOLIO_CONCILIAR_REQUEST'});
        },
        onDescargarPDF: (idFolioConciliacion) => {
            dispatch({ type:'IMPRIMIR_FOLIO_CONCILIACION_REQUEST',idFolioConciliacion})
        },
        onModalNuevoFolio: (busqueda, page) => {
            dispatch({type:'MODAL_NUEVO_FOLIO_CONCILIACION', showModalNuevoFolioConciliacion: true, busqueda, page})
        },
        onModalEditarFolio: (folioConciliacionSeleccionado, busqueda, page) => {
            dispatch({type:'MODAL_EDITAR_FOLIO_CONCILIACION', showModalEditarFolioConciliacion: true, folioConciliacionSeleccionado, busqueda, page})
        },
        onModalCerrarFolio: (folioConciliacionSeleccionado, busqueda, page) => {
            dispatch({type:'MODAL_CERRAR_FOLIO_CONCILIACION', showModalCerrarFolioConciliacion:true, folioConciliacionSeleccionado, busqueda, page})
        },
        onGetTicketsByFolio: (idFolioConciliacion, page) => {
            dispatch({type:'SS_BY_FOLIO_REQUEST', idFolioConciliacion, page})
        },  

    }
}

export default connect(mapStateToProps, mapDispatchToProps) (FolioConciliacion);
