//GESTIÓN DE PLAN DE CALIDAD
export const LISTA_PLANES_CALIDAD_BUSQUEDA_REQUEST = 'LISTA_PLANES_CALIDAD_BUSQUEDA_REQUEST';
export const LISTA_PLANES_CALIDAD_BUSQUEDA_SUCCESS = 'LISTA_PLANES_CALIDAD_BUSQUEDA_SUCCESS';
export const LISTA_PLANES_CALIDAD_BUSQUEDA_FAILURE = 'LISTA_PLANES_CALIDAD_BUSQUEDA_FAILURE';

export const LISTA_AÑOS_PLAN_CALIDAD_REQUEST = 'LISTA_AÑOS_PLAN_CALIDAD_REQUEST';
export const LISTA_AÑOS_PLAN_CALIDAD_SUCCESS = 'LISTA_AÑOS_PLAN_CALIDAD_SUCCESS';
export const LISTA_AÑOS_PLAN_CALIDAD_FAILURE = 'LISTA_AÑOS_PLAN_CALIDAD_FAILURE';

export const ESTADO_PLAN_CALIDAD_REQUEST = 'ESTADO_PLAN_CALIDAD_REQUEST';
export const ESTADO_PLAN_CALIDAD_SUCCESS = 'ESTADO_PLAN_CALIDAD_SUCCESS';
export const ESTADO_PLAN_CALIDAD_FAILURE = 'ESTADO_PLAN_CALIDAD_FAILURE';

export const MODAL_VER_PLAN_CALIDAD = 'MODAL_VER_PLAN_CALIDAD';
export const MODAL_NUEVO_PLAN_CALIDAD = 'MODAL_NUEVO_PLAN_CALIDAD';
export const MODAL_EDITAR_PLAN_CALIDAD = 'MODAL_EDITAR_PLAN_CALIDAD';

export const NUEVO_PLAN_CALIDAD_REQUEST = 'NUEVO_PLAN_CALIDAD_REQUEST';
export const NUEVO_PLAN_CALIDAD_SUCCESS = 'NUEVO_PLAN_CALIDAD_SUCCESS';
export const NUEVO_PLAN_CALIDAD_FAILURE = 'NUEVO_PLAN_CALIDAD_FAILURE';

export const EDITAR_PLAN_CALIDAD_REQUEST = 'EDITAR_PLAN_CALIDAD_REQUEST';
export const EDITAR_PLAN_CALIDAD_SUCCESS = 'EDITAR_PLAN_CALIDAD_SUCCESS';
export const EDITAR_PLAN_CALIDAD_FAILURE = 'EDITAR_PLAN_CALIDAD_FAILURE';

//GESTIÓN DE PLANES CORRECTIVOS
export const LISTA_PLANES_CORRECTIVOS_BUSQUEDA_REQUEST = 'LISTA_PLANES_CORRECTIVOS_BUSQUEDA_REQUEST';
export const LISTA_PLANES_CORRECTIVOS_BUSQUEDA_SUCCESS = 'LISTA_PLANES_CORRECTIVOS_BUSQUEDA_SUCCESS';
export const LISTA_PLANES_CORRECTIVOS_BUSQUEDA_FAILURE = 'LISTA_PLANES_CORRECTIVOS_BUSQUEDA_FAILURE';

export const MODAL_VER_PLAN_CORRECTIVO = 'MODAL_VER_PLAN_CORRECTIVO';
export const MODAL_NUEVO_PLAN_CORRECTIVO = 'MODAL_NUEVO_PLAN_CORRECTIVO';
export const MODAL_EDITAR_PLAN_CORRECTIVO = 'MODAL_EDITAR_PLAN_CORRECTIVO';

export const NUEVO_PLAN_CORRECTIVO_REQUEST = 'NUEVO_PLAN_CORRECTIVO_REQUEST';
export const NUEVO_PLAN_CORRECTIVO_SUCCESS = 'NUEVO_PLAN_CORRECTIVO_SUCCESS';
export const NUEVO_PLAN_CORRECTIVO_FAILURE = 'NUEVO_PLAN_CORRECTIVO_FAILURE';

export const EDITAR_PLAN_CORRECTIVO_REQUEST = 'EDITAR_PLAN_CORRECTIVO_REQUEST';
export const EDITAR_PLAN_CORRECTIVO_SUCCESS = 'EDITAR_PLAN_CORRECTIVO_SUCCESS';
export const EDITAR_PLAN_CORRECTIVO_FAILURE = 'EDITAR_PLAN_CORRECTIVO_FAILURE';

export const LISTA_MESES_ACTIVOS_REQUEST = 'LISTA_MESES_ACTIVOS_REQUEST';
export const LISTA_MESES_ACTIVOS_SUCCESS = 'LISTA_MESES_ACTIVOS_SUCCESS';
export const LISTA_MESES_ACTIVOS_FAILURE = 'LISTA_MESES_ACTIVOS_FAILURE';

export const LISTA_FOLIOS_ESTANDARES_REQUEST = 'LISTA_FOLIOS_ESTANDARES_REQUEST';
export const LISTA_FOLIOS_ESTANDARES_SUCCESS = 'LISTA_FOLIOS_ESTANDARES_SUCCESS';
export const LISTA_FOLIOS_ESTANDARES_FAILURE = 'LISTA_FOLIOS_ESTANDARES_FAILURE';

export const LISTA_FOLIOS_SOLICITUDES_REQUEST = 'LISTA_FOLIOS_SOLICITUDES_REQUEST';
export const LISTA_FOLIOS_SOLICITUDES_SUCCESS = 'LISTA_FOLIOS_SOLICITUDES_SUCCESS';
export const LISTA_FOLIOS_SOLICITUDES_FAILURE = 'LISTA_FOLIOS_SOLICITUDES_FAILURE';

export const LISTA_ESTANDARES_DESEMPENO_AUDITORIAS_REQUEST = 'LISTA_ESTANDARES_DESEMPENO_AUDITORIAS_REQUEST';
export const LISTA_ESTANDARES_DESEMPENO_AUDITORIAS_SUCCESS = 'LISTA_ESTANDARES_DESEMPENO_AUDITORIAS_SUCCESS';
export const LISTA_ESTANDARES_DESEMPENO_AUDITORIAS_FAILURE = 'LISTA_ESTANDARES_DESEMPENO_AUDITORIAS_FAILURE';

export const LISTA_ESTANDARES_DESEMPENO_REQUEST = 'LISTA_ESTANDARES_DESEMPENO_REQUEST';
export const LISTA_ESTANDARES_DESEMPENO_SUCCESS = 'LISTA_ESTANDARES_DESEMPENO_SUCCESS';
export const LISTA_ESTANDARES_DESEMPENO_FAILURE = 'LISTA_ESTANDARES_DESEMPENO_FAILURE';

//PONDERADORES PARA ENCUESTAS DE SATISFACCIÓN
export const LISTA_PONDERADORES_ENCUESTA_REQUEST = 'LISTA_PONDERADORES_ENCUESTA_REQUEST';
export const LISTA_PONDERADORES_ENCUESTA_SUCCESS = 'LISTA_PONDERADORES_ENCUESTA_SUCCESS';
export const LISTA_PONDERADORES_ENCUESTA_FAILURE = 'LISTA_PONDERADORES_ENCUESTA_FAILURE';

export const REPETIR_PONDERADORES_ENCUESTA_REQUEST = 'REPETIR_PONDERADORES_ENCUESTA_REQUEST';
export const REPETIR_PONDERADORES_ENCUESTA_SUCCESS = 'REPETIR_PONDERADORES_ENCUESTA_SUCCESS';
export const REPETIR_PONDERADORES_ENCUESTA_FAILURE = 'REPETIR_PONDERADORES_ENCUESTA_FAILURE';

export const AÑOS_PONDERADORES_ENCUESTA_REQUEST = 'AÑOS_PONDERADORES_ENCUESTA_REQUEST';
export const AÑOS_PONDERADORES_ENCUESTA_SUCCESS = 'AÑOS_PONDERADORES_ENCUESTA_SUCCESS';
export const AÑOS_PONDERADORES_ENCUESTA_FAILURE = 'AÑOS_PONDERADORES_ENCUESTA_FAILURE';

export const ESTADO_PONDERADOR_ENCUESTA_REQUEST = 'ESTADO_PONDERADOR_ENCUESTA_REQUEST';
export const ESTADO_PONDERADOR_ENCUESTA_SUCCESS = 'ESTADO_PONDERADOR_ENCUESTA_SUCCESS';
export const ESTADO_PONDERADOR_ENCUESTA_FAILURE = 'ESTADO_PONDERADOR_ENCUESTA_FAILURE';

export const MODAL_NUEVO_PONDERADOR_ENCUESTA = 'MODAL_NUEVO_PONDERADOR_ENCUESTA';
export const MODAL_EDITAR_PONDERADOR_ENCUESTA = 'MODAL_EDITAR_PONDERADOR_ENCUESTA';

export const NUEVO_PONDERADOR_ENCUESTA_REQUEST = 'NUEVO_PONDERADOR_ENCUESTA_REQUEST';
export const NUEVO_PONDERADOR_ENCUESTA_SUCCESS = 'NUEVO_PONDERADOR_ENCUESTA_SUCCESS';
export const NUEVO_PONDERADOR_ENCUESTA_FAILURE = 'NUEVO_PONDERADOR_ENCUESTA_FAILURE';

export const EDITAR_PONDERADOR_ENCUESTA_REQUEST = 'EDITAR_PONDERADOR_ENCUESTA_REQUEST';
export const EDITAR_PONDERADOR_ENCUESTA_SUCCESS = 'EDITAR_PONDERADOR_ENCUESTA_SUCCESS';
export const EDITAR_PONDERADOR_ENCUESTA_FAILURE = 'EDITAR_PONDERADOR_ENCUESTA_FAILURE';

//EMISIÓN DE ENCUESTAS DE SATISFACCIÓN
export const LISTA_AÑOS_PREGUNTAS_REQUEST = 'LISTA_AÑOS_PREGUNTAS_REQUEST';
export const LISTA_AÑOS_PREGUNTAS_SUCCESS = 'LISTA_AÑOS_PREGUNTAS_SUCCESS';
export const LISTA_AÑOS_PREGUNTAS_FAILURE = 'LISTA_AÑOS_PREGUNTAS_FAILURE';

export const LISTA_PREGUNTAS_ENCUESTA_REQUEST = 'LISTA_PREGUNTAS_ENCUESTA_REQUEST';
export const LISTA_PREGUNTAS_ENCUESTA_SUCCESS = 'LISTA_PREGUNTAS_ENCUESTA_SUCCESS';
export const LISTA_PREGUNTAS_ENCUESTA_FAILURE = 'LISTA_PREGUNTAS_ENCUESTA_FAILURE';

export const ESTADO_ENCUESTA_REQUEST = 'ESTADO_ENCUESTA_REQUEST';
export const ESTADO_ENCUESTA_SUCCESS = 'ESTADO_ENCUESTA_SUCCESS';
export const ESTADO_ENCUESTA_FAILURE = 'ESTADO_ENCUESTA_FAILURE';

export const MODAL_NUEVA_PREGUNTA_ENCUESTA = 'MODAL_NUEVA_PREGUNTA_ENCUESTA';
export const MODAL_EDITAR_PREGUNTA_ENCUESTA = 'MODAL_EDITAR_PREGUNTA_ENCUESTA';

export const NUEVA_PREGUNTA_ENCUESTA_REQUEST = 'NUEVA_PREGUNTA_ENCUESTA_REQUEST';
export const NUEVA_PREGUNTA_ENCUESTA_SUCCESS = 'NUEVA_PREGUNTA_ENCUESTA_SUCCESS';
export const NUEVA_PREGUNTA_ENCUESTA_FAILURE = 'NUEVA_PREGUNTA_ENCUESTA_FAILURE';

export const EDITAR_PREGUNTA_ENCUESTA_REQUEST = 'EDITAR_PREGUNTA_ENCUESTA_REQUEST';
export const EDITAR_PREGUNTA_ENCUESTA_SUCCESS = 'EDITAR_PREGUNTA_ENCUESTA_SUCCESS';
export const EDITAR_PREGUNTA_ENCUESTA_FAILURE = 'EDITAR_PREGUNTA_ENCUESTA_FAILURE';

export const NUEVO_FOLIO_ENCUESTA_REQUEST = 'NUEVO_FOLIO_ENCUESTA_REQUEST';
export const NUEVO_FOLIO_ENCUESTA_SUCCESS = 'NUEVO_FOLIO_ENCUESTA_SUCCESS';
export const NUEVO_FOLIO_ENCUESTA_FAILURE = 'NUEVO_FOLIO_ENCUESTA_FAILURE';

export const EXPORTAR_FOLIO_ENCUESTA_REQUEST = 'EXPORTAR_FOLIO_ENCUESTA_REQUEST';
export const EXPORTAR_FOLIO_ENCUESTA_SUCCESS = 'EXPORTAR_FOLIO_ENCUESTA_SUCCESS';
export const EXPORTAR_FOLIO_ENCUESTA_FAILURE = 'EXPORTAR_FOLIO_ENCUESTA_FAILURE';

//REGISTRO DE ENCUESTAS DE SATISFACCIÓN
export const LISTA_MESES_FOLIO_REQUEST = 'LISTA_MESES_FOLIO_REQUEST';
export const LISTA_MESES_FOLIO_SUCCESS = 'LISTA_MESES_FOLIO_SUCCESS';
export const LISTA_MESES_FOLIO_FAILURE = 'LISTA_MESES_FOLIO_FAILURE';

export const LISTA_FOLIOS_BUSQUEDA_REQUEST = 'LISTA_FOLIOS_BUSQUEDA_REQUEST';
export const LISTA_FOLIOS_BUSQUEDA_SUCCESS = 'LISTA_FOLIOS_BUSQUEDA_SUCCESS';
export const LISTA_FOLIOS_BUSQUEDA_FAILURE = 'LISTA_FOLIOS_BUSQUEDA_FAILURE';

export const MODAL_REGISTRAR_ENCUESTA = 'MODAL_REGISTRAR_ENCUESTA';

export const REGISTRAR_ENCUESTA_REQUEST = 'REGISTRAR_ENCUESTA_REQUEST';
export const REGISTRAR_ENCUESTA_SUCCESS = 'REGISTRAR_ENCUESTA_SUCCESS';
export const REGISTRAR_ENCUESTA_FAILURE = 'REGISTRAR_ENCUESTA_FAILURE';

export const EVALUAR_ENCUESTA_REQUEST = 'EVALUAR_ENCUESTA_REQUEST';
export const EVALUAR_ENCUESTA_SUCCESS = 'EVALUAR_ENCUESTA_SUCCESS';
export const EVALUAR_ENCUESTA_FAILURE = 'EVALUAR_ENCUESTA_FAILURE';

export const MODAL_DETALLE_FOLIO = 'MODAL_DETALLE_FOLIO';

// Reportes de encuestas de satisfacción 
export const OBTENER_INFORMACION_REPORTE_REQUEST = 'OBTENER_INFORMACION_REPORTE_REQUEST';
export const OBTENER_INFORMACION_REPORTE_SUCCESS = 'OBTENER_INFORMACION_REPORTE_SUCCESS';
export const OBTENER_INFORMACION_REPORTE_FAILURE = 'OBTENER_INFORMACION_REPORTE_FAILURE';

export const OBTENER_INFORMACION_REPORTE_PDF_REQUEST = 'OBTENER_INFORMACION_REPORTE_PDF_REQUEST';
export const OBTENER_INFORMACION_REPORTE_PDF_SUCCESS = 'OBTENER_INFORMACION_REPORTE_PDF_SUCCESS';
export const OBTENER_INFORMACION_REPORTE_PDF_FAILURE = 'OBTENER_INFORMACION_REPORTE_PDF_FAILURE';

export const GET_GENERAL_REPORT_PDF_REQUEST = 'GET_GENERAL_REPORT_PDF_REQUEST';
export const GET_GENERAL_REPORT_PDF_SUCCESS = 'GET_GENERAL_REPORT_PDF_SUCCESS';
export const GET_GENERAL_REPORT_PDF_FAILURE = 'GET_GENERAL_REPORT_PDF_FAILURE';

export const SHOW_MODAL_COMPARATIVOS = 'SHOW_MODAL_COMPARATIVOS';

export const COMPARATIVO_SERVICIO_PDF_REQUEST = 'COMPARATIVO_SERVICIO_PDF_REQUEST';
export const COMPARATIVO_SERVICIO_PDF_SUCCESS = 'COMPARATIVO_SERVICIO_PDF_SUCCESS';
export const COMPARATIVO_SERVICIO_PDF_FAILURE = 'COMPARATIVO_SERVICIO_PDF_FAILURE';

export const OBTENER_INFORMACION_COMPARATIVO1_REQUEST = 'OBTENER_INFORMACION_COMPARATIVO1_REQUEST';
export const OBTENER_INFORMACION_COMPARATIVO1_SUCCESS = 'OBTENER_INFORMACION_COMPARATIVO1_SUCCESS';
export const OBTENER_INFORMACION_COMPARATIVO1_FAILURE = 'OBTENER_INFORMACION_COMPARATIVO1_FAILURE';

export const OBTENER_INFORMACION_COMPARATIVO2_REQUEST = 'OBTENER_INFORMACION_COMPARATIVO2_REQUEST';
export const OBTENER_INFORMACION_COMPARATIVO2_SUCCESS = 'OBTENER_INFORMACION_COMPARATIVO2_SUCCESS';
export const OBTENER_INFORMACION_COMPARATIVO2_FAILURE = 'OBTENER_INFORMACION_COMPARATIVO2_FAILURE';

//REPORTE DINÁMICO DE ENCUESTAS DE SATISFACCIÓN
export const REPORTE_DINAMICO_ES_REQUEST = 'REPORTE_DINAMICO_ES_REQUEST';
export const REPORTE_DINAMICO_ES_SUCCESS = 'REPORTE_DINAMICO_ES_SUCCESS';
export const REPORTE_DINAMICO_ES_FAILURE = 'REPORTE_DINAMICO_ES_FAILURE';

export const REPORTE_DINAMICO_AGRUPADO_ES_REQUEST = 'REPORTE_DINAMICO_AGRUPADO_ES_REQUEST';
export const REPORTE_DINAMICO_AGRUPADO_ES_SUCCESS = 'REPORTE_DINAMICO_AGRUPADO_ES_SUCCESS';
export const REPORTE_DINAMICO_AGRUPADO_ES_FAILURE = 'REPORTE_DINAMICO_AGRUPADO_ES_FAILURE';

export const PDF_REPORTE_DINAMICO_ES_REQUEST = 'PDF_REPORTE_DINAMICO_ES_REQUEST';
export const PDF_REPORTE_DINAMICO_ES_SUCCESS = 'PDF_REPORTE_DINAMICO_ES_SUCCESS';
export const PDF_REPORTE_DINAMICO_ES_FAILURE = 'PDF_REPORTE_DINAMICO_ES_FAILURE';

export const EXCEL_REPORTE_DINAMICO_ES_REQUEST = 'EXCEL_REPORTE_DINAMICO_ES_REQUEST';
export const EXCEL_REPORTE_DINAMICO_ES_SUCCESS = 'EXCEL_REPORTE_DINAMICO_ES_SUCCESS';
export const EXCEL_REPORTE_DINAMICO_ES_FAILURE = 'EXCEL_REPORTE_DINAMICO_ES_FAILURE';

export const LIMPIAR_FORMULARIO_REPORTE_ES = 'LIMPIAR_FORMULARIO_REPORTE_ES';

//Cumplimiento de Estándares
export const LISTA_CUMPLIMIENTO_ESTANDARES_REQUEST = 'LISTA_CUMPLIMIENTO_ESTANDARES_REQUEST';
export const LISTA_CUMPLIMIENTO_ESTANDARES_SUCCESS = 'LISTA_CUMPLIMIENTO_ESTANDARES_SUCCESS';
export const LISTA_CUMPLIMIENTO_ESTANDARES_FAILURE = 'LISTA_CUMPLIMIENTO_ESTANDARES_FAILURE';

export const CREAR_FOLIO_ESTANDAR_REQUEST = 'CREAR_FOLIO_ESTANDAR_REQUEST';
export const CREAR_FOLIO_ESTANDAR_SUCCESS = 'CREAR_FOLIO_ESTANDAR_SUCCESS';
export const CREAR_FOLIO_ESTANDAR_FAILURE = 'CREAR_FOLIO_ESTANDAR_FAILURE';

export const EXPORTAR_FOLIO_ESTANDAR_REQUEST = 'EXPORTAR_FOLIO_ESTANDAR_REQUEST';
export const EXPORTAR_FOLIO_ESTANDAR_SUCCESS = 'EXPORTAR_FOLIO_ESTANDAR_SUCCESS';
export const EXPORTAR_FOLIO_ESTANDAR_FAILURE = 'EXPORTAR_FOLIO_ESTANDAR_FAILURE';

export const LISTA_FOLIOS_ESTANDARES_EVALUAR_REQUEST = 'LISTA_FOLIOS_ESTANDARES_EVALUAR_REQUEST';
export const LISTA_FOLIOS_ESTANDARES_EVALUAR_SUCCESS = 'LISTA_FOLIOS_ESTANDARES_EVALUAR_SUCCESS';
export const LISTA_FOLIOS_ESTANDARES_EVALUAR_FAILURE = 'LISTA_FOLIOS_ESTANDARES_EVALUAR_FAILURE';

export const MODAL_EVALUAR_FOLIO_ESTANDAR = 'MODAL_EVALUAR_FOLIO_ESTANDAR';

export const EVALUAR_FOLIO_ESTANDAR_REQUEST = 'EVALUAR_FOLIO_ESTANDAR_REQUEST';
export const EVALUAR_FOLIO_ESTANDAR_SUCCESS = 'EVALUAR_FOLIO_ESTANDAR_SUCCESS';
export const EVALUAR_FOLIO_ESTANDAR_FAILURE = 'EVALUAR_FOLIO_ESTANDAR_FAILURE';

export const LISTA_FOLIOS_ESTANDARES_EVALUADOS_REQUEST = 'LISTA_FOLIOS_ESTANDARES_EVALUADOS_REQUEST';
export const LISTA_FOLIOS_ESTANDARES_EVALUADOS_SUCCESS = 'LISTA_FOLIOS_ESTANDARES_EVALUADOS_SUCCESS';
export const LISTA_FOLIOS_ESTANDARES_EVALUADOS_FAILURE = 'LISTA_FOLIOS_ESTANDARES_EVALUADOS_FAILURE';

export const MODAL_DETALLE_FOLIO_EVALUADO = 'MODAL_DETALLE_FOLIO_EVALUADO';

// Reportes de desempeño de estandares generales
export const LISTA_FOLIOS_ESTANDARES_DESEMPENO_REQUEST = 'LISTA_FOLIOS_ESTANDARES_DESEMPENO_REQUEST';
export const LISTA_FOLIOS_ESTANDARES_DESEMPENO_SUCCESS = 'LISTA_FOLIOS_ESTANDARES_DESEMPENO_SUCCESS';
export const LISTA_FOLIOS_ESTANDARES_DESEMPENO_FAILURE = 'LISTA_FOLIOS_ESTANDARES_DESEMPENO_FAILURE';

export const MODAL_SHOW_ESTANDARES = 'MODAL_SHOW_ESTANDARES';

export const LISTA_ESTANDARES_POR_FOLIO_REQUEST = 'LISTA_ESTANDARES_POR_FOLIO_REQUEST';
export const LISTA_ESTANDARES_POR_FOLIO_SUCCESS = 'LISTA_ESTANDARES_POR_FOLIO_SUCCESS';
export const LISTA_ESTANDARES_POR_FOLIO_FAILURE = 'LISTA_ESTANDARES_POR_FOLIO_FAILURE';

export const MODAL_SHOW_PDF_ESTANDARES_DESEMPENO = 'MODAL_SHOW_PDF_ESTANDARES_DESEMPENO';
export const MODAL_UPLOAD_PDF_POR_ESTANDAR = 'MODAL_UPLOAD_PDF_POR_ESTANDAR';

export const SUBIR_ARCHIVO_POR_ESTANDAR_REQUEST = 'SUBIR_ARCHIVO_POR_ESTANDAR_REQUEST';
export const SUBIR_ARCHIVO_POR_ESTANDAR_SUCCESS = 'SUBIR_ARCHIVO_POR_ESTANDAR_SUCCESS';
export const SUBIR_ARCHIVO_POR_ESTANDAR_FAILURE = 'SUBIR_ARCHIVO_POR_ESTANDAR_FAILURE';

export const MODAL_LISTA_ACTIVIDADES = 'MODAL_LISTA_ACTIVIDADES';
