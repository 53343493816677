import React from 'react';
import { Form, Row, Col, Divider, Modal, Card, Select, Button, DatePicker, message, Table, Spin, Steps, Tooltip, Tabs, Descriptions, Skeleton, Alert } from 'antd';
import {Bar, Line} from 'react-chartjs-2';
import moment from 'moment';
import { connect } from "react-redux";
import { FolderAddOutlined, FileAddOutlined, BarChartOutlined, FolderViewOutlined, ExceptionOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Step } = Steps;
const { TabPane } = Tabs;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };

class PresentacionConsumo extends React.Component {
     formRef = React.createRef();

     componentDidMount() { this.props.onRequestInfoProyecto(); }

     state = { page: 1, busqueda: '', current: 0, booleanSecondMonth: true, columns: [], mostarGrafico: false, monthSelectedOne: '', monthSelectedTwo: '', lastDay_MonthOne: '', idUbicacionDeposito: null, FI1: '', FF2: '', FI2: '', FF2: '', 
          idAsigConsSelected: false, idUbiConsSelected: false, idAsigDispoSelected: false, idUbiDispoSelected: false, infoFormDispoYConsumo: [] 
     }
     


     render() {
          const { 
               onCloseModal,
               onLimpiarDepositoByidServicioPublico,
               onPresentacion,
               onReporte,
               showModalPresentacionConsumos,
               servicioPublicoSeleccionado,
               listaInfoProyecto,
               fetchingListaDepositosByIdServiciosPublicosSinProgra,
               listaDepositosByIdServiciosPublicosSinProgra,
               registroLecturasPresentacion,
               chartData,
               optionsData,
               onGetExcelConsumo, 
               onGetExcelDisponibilidad,
               onGetPdfConsumo, 
               onGetPdfDisponibilidad,
               fetchingRegistroLecturasPresentacion, fetchingRegistroLecturaReporte
          } = this.props;

          const { infoFormDispoYConsumo} = this.state;

          const handleCancel = () => {
               onCloseModal();
               this.setState({ current: 0, mostarGrafico: false, lastDay_MonthOne: '', monthSelectedOne: '', monthSelectedTwo: '',  idAsigConsSelected: false, idUbiConsSelected: false, idAsigDispoSelected: false, idUbiDispoSelected: false});
               onLimpiarDepositoByidServicioPublico();
               this.props.onCleanPresentacionDisponibilidad();
               this.props.onCleanPresentacionConsumo();
               this.props.onCleanUbicacionesByIdServicioPublico();
          };

          const onFinish = (formulario) => {
               console.log('Success:', formulario);
               let columnsLocal
               let mesONE = parseInt(this.state.monthSelectedOne.split('-')[0]) -1;
               let annioONE = this.state.monthSelectedOne.split('-')[1];
               let mesTWO = parseInt(this.state.monthSelectedTwo.split('-')[0]) -1;
               let annioTWO = this.state.monthSelectedTwo.split('-')[1];

               let diasVigencia = new Date(annioONE, mesONE + 1, 0).getDate();

               let mesFechaInicioONE = new Date(annioONE, mesONE, 1, 0,0,0,0)
               let mesFechaFinONE = new Date(annioONE, mesONE, diasVigencia, 23,59,59,59);
               let mesFechaInicioTWO = new Date(annioTWO, mesTWO, 1, 0,0,0,0);

               function addDays(date, days) {
                    const copy = new Date(Number(date))
                    copy.setDate(date.getDate() + days)
                    return copy
               }
               let mesFechaFinTWO= addDays(mesFechaInicioTWO, diasVigencia-1);


               if(this.state.current === 0){
                    // Graficación General
                    
                    onPresentacion(null, servicioPublicoSeleccionado.idServicioPublico, moment(mesFechaInicioONE).format('YYYY-MM-DD'), moment(mesFechaFinONE).format('YYYY-MM-DD'), moment(mesFechaInicioTWO).format('YYYY-MM-DD'), moment(mesFechaFinTWO).format('YYYY-MM-DD'), servicioPublicoSeleccionado, "General");

                    columnsLocal = [
                         { title: 'Dato Gráficado', dataIndex: 'datoGraficado', align:'center' },
                         { title: 'Primer Periodo', dataIndex: 'p1', align:'center' },
                         { title: 'Consumo', dataIndex: 'cp1', align:'center' },
                         { title: 'Segundo Periodo', dataIndex: 'p2', align:'center' },
                         { title: 'Consumo', dataIndex: 'cp2', align:'center' },
                    ];
               } else {
                    // Graficación Unitaria

                    onPresentacion(parseInt(formulario.ubicacionDeposito), null, moment(mesFechaInicioONE).format('YYYY-MM-DD'), moment(mesFechaFinONE).format('YYYY-MM-DD'), moment(mesFechaInicioTWO).format('YYYY-MM-DD'), moment(mesFechaFinTWO).format('YYYY-MM-DD'), servicioPublicoSeleccionado, "Unitario");

                    let nombreUbiLocal = '';

                    for(let i = 0; i < listaDepositosByIdServiciosPublicosSinProgra.length; i++){
                         if(parseInt(formulario.ubicacionDeposito) === listaDepositosByIdServiciosPublicosSinProgra[i].idAsignacionServicioPublico){
                              nombreUbiLocal= listaDepositosByIdServiciosPublicosSinProgra[i].nombreUbicacion
                         }
                    }

                    this.setState({ idUbicacionDeposito: parseInt(formulario.ubicacionDeposito) });

                    columnsLocal = [
                         { title: 'Dato Gráficado', dataIndex: 'datoGraficado', align:'center' },
                         { title: 'Ubicación del Depósito', dataIndex: 'ubidepo', align:'center' },
                         { title: 'Primer Periodo', dataIndex: 'p1', align:'center' },
                         { title: 'Consumo', dataIndex: 'cp1', align:'center' },
                         { title: 'Segundo Periodo', dataIndex: 'p2', align:'center' },
                         { title: 'Consumo', dataIndex: 'cp2', align:'center' },
                    ];
               }

               this.setState({ columns: columnsLocal, mostarGrafico: true, FI1: moment(mesFechaInicioONE).format('YYYY-MM-DD'), FF1: moment(mesFechaFinONE).format('YYYY-MM-DD'), FI2: moment(mesFechaInicioTWO).format('YYYY-MM-DD'), FF2: moment(mesFechaFinTWO).format('YYYY-MM-DD') });
          }

          const onFinishFailed = (errorInfo) => {  console.log('Failed:', errorInfo); };

          const onChangeStep = (current) => {
               let value = '';
               if(parseInt(current) === 0){
                    this.setState({ ubicacionUnitario: true, booleanSecondMonth: true });
                    this.formRef.current.resetFields();
                    value = 'Generales';
               } else if(parseInt(current) === 1) {
                    this.setState({ ubicacionUnitario: false, booleanSecondMonth: true });
                    value = 'Unitarios';
                    this.formRef.current.resetFields();
               }
               this.setState({ datoGraficado: value, current: parseInt(current), mostarGrafico: false });
          };

          const onExportar = () => {
               if(this.state.current === 1){
                    onReporte(this.state.idUbicacionDeposito, null, this.state.FI1, this.state.FF1, this.state.FI2, this.state.FF2);

               } else if(this.state.current === 0) {
                    onReporte(null, servicioPublicoSeleccionado.idServicioPublico, this.state.FI1, this.state.FF1, this.state.FI2, this.state.FF2);
               }
          };

          const onExportarExcelType = (type) =>{
               //console.log("fecha",fecha[0].format("YYYY-MM-DD"), fecha[1].format("YYYY-MM-DD"))
               if(type==='consumo'){
                    onGetExcelConsumo(servicioPublicoSeleccionado.idServicioPublico, infoFormDispoYConsumo.idAsignacionServicioPublicoDispo, infoFormDispoYConsumo.rangoFechasDispo[0].format('YYYY-MM-DD'), infoFormDispoYConsumo.rangoFechasDispo[1].format('YYYY-MM-DD'), infoFormDispoYConsumo.idUbicacionDispo, infoFormDispoYConsumo.idUbicacion)
                }
                else if(type==='disponibilidad'){
                     onGetExcelDisponibilidad(servicioPublicoSeleccionado.idServicioPublico, infoFormDispoYConsumo.idAsignacionServicioPublicoDispo, infoFormDispoYConsumo.rangoFechasDispo[0].format('YYYY-MM-DD'), infoFormDispoYConsumo.rangoFechasDispo[1].format('YYYY-MM-DD'), infoFormDispoYConsumo.idUbicacionDispo, infoFormDispoYConsumo.idUbicacion)
                }
          }
          const onDescargar = () => {
               if(this.state.current === 1){
                    this.props.onReportePDF(this.state.idUbicacionDeposito, null, this.state.FI1, this.state.FF1, this.state.FI2, this.state.FF2);

               } else if(this.state.current === 0) {
                    this.props.onReportePDF(null, servicioPublicoSeleccionado.idServicioPublico, this.state.FI1, this.state.FF1, this.state.FI2, this.state.FF2);
               }
          };

          const onDescargarPdfType = (type) =>{
               if(type==='consumo'){
                   onGetPdfConsumo(servicioPublicoSeleccionado.idServicioPublico, infoFormDispoYConsumo.idAsignacionServicioPublicoDispo, infoFormDispoYConsumo.rangoFechasDispo[0].format('YYYY-MM-DD'), infoFormDispoYConsumo.rangoFechasDispo[1].format('YYYY-MM-DD'), infoFormDispoYConsumo.idUbicacionDispo, infoFormDispoYConsumo.idUbicacion)
               }
               else if(type==='disponibilidad'){
                   onGetPdfDisponibilidad(servicioPublicoSeleccionado.idServicioPublico, infoFormDispoYConsumo.idAsignacionServicioPublicoDispo, infoFormDispoYConsumo.rangoFechasDispo[0].format('YYYY-MM-DD'), infoFormDispoYConsumo.rangoFechasDispo[1].format('YYYY-MM-DD'), infoFormDispoYConsumo.idUbicacionDispo, infoFormDispoYConsumo.idUbicacion)
               }
          }

          const onChangeMonthFirst = (date, dateString) => {
               console.log('date', date);
               if(date){
                    console.log('dateString', dateString)
                    if(listaInfoProyecto.fechaInicio){
                         let mes = date._d.getMonth() <= 8 ? '0' + (date._d.getMonth() + 1) : date._d.getMonth() + 1;
                         let annio = date._d.getFullYear();

                         let idMesLocal = (date._d).getMonth();
                         let diasVigencia = new Date(annio, idMesLocal + 1, 0).getDate();

                         let mesFechaInicio = new Date(annio, idMesLocal, 1, 0,0,0,0)
                         let mesFechaFin = new Date(annio, idMesLocal, diasVigencia, 23,59,59,59);

                         this.setState({ lastDay_MonthOne: mesFechaFin, monthSelectedOne: mes + '-' + annio, booleanSecondMonth: false  });

                         const diferenciaInicio = listaInfoProyecto.fechaInicio.diff(moment(mesFechaFin), "days");

                         const fechaVigenciaNuevo = listaInfoProyecto.fechaVigencia.split('-')[2] + '-' + listaInfoProyecto.fechaVigencia.split('-')[1] + '-' + listaInfoProyecto.fechaVigencia.split('-')[0];
                         
                         const fechaVigenciaLocal = new Date(fechaVigenciaNuevo); 
                         if(diferenciaInicio > 0){
                              message.error('No es posible seleccionar un mes de inicio menor al inicio del contrato del proyecto', 5);
                              this.formRef.current.resetFields(['rangeMonthOne']);
                              this.setState({ booleanSecondMonth: true });
                         }
                         if(mesFechaInicio > fechaVigenciaLocal){
                              message.error('No es posible seleccionar un mes de fin mayor a la vigencia del contrato del proyecto', 5);
                              this.formRef.current.resetFields(['rangeMonthOne']);
                              this.setState({ booleanSecondMonth: true });
                         }
                    }
                    else{
                         message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                         this.formRef.current.resetFields(['rangeMonthOne']);
                    }
                    this.formRef.current.resetFields(['rangeMonthTwo']);
                    this.setState({ mostarGrafico:false });
               } else { this.setState({ booleanSecondMonth: true, buttonExport: true  }); this.formRef.current.resetFields(['rangeMonthTwo']); }
          };

          const onChangeMonthSecond = (date, dateString) => {
               if(date){
                    console.log('dateString', dateString)
                    if(listaInfoProyecto.fechaInicio){
                         let mes = date._d.getMonth() <= 8 ? '0' + (date._d.getMonth() + 1) : date._d.getMonth() + 1;
                         let annio = date._d.getFullYear();
                         let idMesLocal = (date._d).getMonth();
                         let mesFechaInicio = new Date(annio, idMesLocal, 1, 0,0,0,0);
                         const fechaVigenciaNuevo = listaInfoProyecto.fechaVigencia.split('-')[2] + '-' + listaInfoProyecto.fechaVigencia.split('-')[1] + '-' + listaInfoProyecto.fechaVigencia.split('-')[0];
                         const fechaVigenciaLocal = new Date(fechaVigenciaNuevo);

                         this.setState({ monthSelectedTwo: mes + '-' + annio, buttonExport: false });

                         if(mesFechaInicio > fechaVigenciaLocal){
                              message.error('No es posible seleccionar un mes de fin mayor a la vigencia del contrato del proyecto', 5);
                              this.formRef.current.resetFields(['rangeMonthTwo']);
                         }
                    }
                    else{
                         message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                         this.formRef.current.resetFields(['rangeMonthTwo']);
                    }
               } else { this.setState({ buttonExport: true }); }
          };

          const disabledDateMonth = (current) => {
               return current && current < moment(this.state.lastDay_MonthOne, "YYYY-MM-DD");
          };

          const  disabledDate = (fecha) => {
               if( fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicioOperacion) {
                   return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicioOperacion;
               } else {
                   if(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos){
                return fecha && moment(fecha.format('YYYY-MM-DD')) > moment((listaInfoProyecto.vigencia +  parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2])) + '-' +listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[1] + '-' + listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[0]);
            }
               }
          };

          const onFinishDispo = (form) => {
               console.log('form', form.rangoFechasDispo[0].format("YYYY-MM-DD"))
               const fechaInicio = form.rangoFechasDispo[0].format("YYYY-MM-DD")
               const fechaFin = form.rangoFechasDispo[1].format("YYYY-MM-DD")
               this.setState({
                    infoFormDispoYConsumo: form
               })
               this.props.onPresentacionDisponibilidad(form.idAsignacionServicioPublicoDispo, servicioPublicoSeleccionado.idServicioPublico, form.idUbicacionDispo, fechaInicio, fechaFin);
               this.props.onPresentacionConsumo(form.idAsignacionServicioPublicoDispo, servicioPublicoSeleccionado.idServicioPublico, form.idUbicacionDispo, fechaInicio, fechaFin);
          };

          const onFinishCons = (form) => {
               console.log('form', form)
               const fechaI = form.rangoFechasCons[0]._d.toLocaleDateString().replace(/[/]/gi,'-');
               const mesI = parseInt(fechaI.split('-')[1]) <= 9 ? '0' + fechaI.split('-')[1] : fechaI.split('-')[1];
               const diaI = parseInt(fechaI.split('-')[0]) <= 9 ? '0' + fechaI.split('-')[0] : fechaI.split('-')[0];
               const fechaInicio = fechaI.split('-')[2] + '-' + mesI + '-' + diaI;

               const fechaF = form.rangoFechasCons[1]._d.toLocaleDateString().replace(/[/]/gi,'-');
               const mesF = parseInt(fechaF.split('-')[1]) <= 9 ? '0' + fechaF.split('-')[1] : fechaF.split('-')[1];
               const diaF = parseInt(fechaF.split('-')[0]) <= 9 ? '0' + fechaF.split('-')[0] : fechaF.split('-')[0];
               const fechaFin = fechaF.split('-')[2] + '-' + mesF + '-' + diaF;

               this.props.onPresentacionConsumo(form.idAsignacionServicioPublicoCons, servicioPublicoSeleccionado.idServicioPublico, form.idUbicacionCons, fechaInicio, fechaFin);
          };

          function numberWithCommas(x) {
               let parts = x.toString().split(".");
               parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
               return parts.join(".");
          }

          return (<>
               { servicioPublicoSeleccionado ? (
               <Modal
                    visible={showModalPresentacionConsumos}
                    title={'Presentación de Consumos: ' + servicioPublicoSeleccionado.nombre}
                    onCancel={handleCancel}
                    footer={false}
                    width={'80%'}
               >
                    
                    <Spin spinning={this.props.fetchingRegistroLecturasPresentacion}>
                         <Form {...layout} name="formulario" ref={this.formRef} onFinish={onFinish}  >    
                         <div>
                              <Row justify='space-around' id='cuerpo'>

                                   <Col span={24}>
                                        {/* <Steps
                                             type="navigation"
                                             size="small"
                                             current={this.state.current}
                                             onChange={onChangeStep}
                                        >
                                                  <Step icon={<FolderAddOutlined className="stepIcon"/>} title="Graficación General" />
                                                  <Step icon={<FileAddOutlined className="stepIcon"/>} title="Graficación Unitaria" />
                                                  <Step icon={<FileAddOutlined className="stepIcon"/>} title="Graficación Disponibilidad" />
                                                  <Step icon={<FileAddOutlined className="stepIcon"/>} title="Graficación Consumos" />
                                        </Steps> */}

                                        <Tabs className='tabDashboard' defaultActiveKey="0" onChange={(key) => onChangeStep(key)} >
                                             <TabPane
                                                  tab={
                                                  <span>
                                                       <FolderAddOutlined />
                                                       Graficación General
                                                  </span>
                                                  }
                                                  key="0"
                                             >
                                                  <Row justify='space-around'>
                                                       <Col span={14}>
                                                            <Form.Item name='ubicacionDeposito' rules={[{ required: parseInt(this.state.current) !== 0, message:'Favor de llenar el campo'}]} >
                                                                 <Select
                                                                      allowClear
                                                                      style={{ width: '100%' }}
                                                                      placeholder="Seleccione el depósito a gráficar"
                                                                      loading={fetchingListaDepositosByIdServiciosPublicosSinProgra}
                                                                      disabled={parseInt(this.state.current) === 0}
                                                                 >
                                                                      {listaDepositosByIdServiciosPublicosSinProgra.map(option => 
                                                                           <Option key={option.idAsignacionServicioPublico}>
                                                                                <Tooltip title={option.nombreDeposito + ' ' + option.posicion + ' (' + option.claveUbicacion + ': ' + option.nombreUbicacion + ')'}> 
                                                                                     { option.nombreDeposito + ' ' + option.posicion + ' (' +  option.claveUbicacion + ': ' + option.nombreUbicacion + ')'}
                                                                                </Tooltip>
                                                                           </Option> 
                                                                      )}
                                                                 </Select> 

                                                            </Form.Item>
                                                       </Col>

                                                       <Col span={4}>
                                                            <Tooltip title='Seleccione el primer mes a comparar'>
                                                                 <Form.Item  name='rangeMonthOne' rules={[{ required: true, message: 'Favor de llenar el campo' }]} >
                                                                      <DatePicker onChange={onChangeMonthFirst} picker="month" style={{width:"100%"}} format='MMM-YYYY' placeholder='Primer mes' />
                                                                 </Form.Item>
                                                            </Tooltip>
                                                       </Col>

                                                       <Col span={4}>
                                                            <Tooltip title='Seleccione el segundo mes a comparar'>
                                                                 <Form.Item  name='rangeMonthTwo' rules={[{ required: true, message: 'Favor de llenar el campo' }]} >
                                                                      <DatePicker disabled={this.state.booleanSecondMonth} onChange={onChangeMonthSecond} picker="month" style={{width:"100%"}} format='MMM-YYYY' placeholder='Segundo mes' disabledDate={disabledDateMonth} />
                                                                 </Form.Item>
                                                            </Tooltip>
                                                       </Col>

                                                       <Col span={1}>
                                                            <Tooltip title='Graficar'><Button className="btnFiltrar" shape="circle" icon={<BarChartOutlined/>} htmlType='submit' /></Tooltip>
                                                       </Col>

                                                       <Divider style={{margin: '10px'}}/>

                                                       { this.state.mostarGrafico ? ( <>
                                                            <Row justify='space-around'>

                                                                 <Col span={24} >
                                                                      <br/>
                                                                      <Card
                                                                           // title={'Consumo: ' + servicioPublicoSeleccionado.nombre}
                                                                           className="listaCatalogos"
                                                                           bordered={true}
                                                                           style={{align: 'center'}}
                                                                           title={ 
                                                                                <Row justify='space-around'>
                                                                                     <Col span={22} style={{fontSize:18, textAlign:"left", color:"#0e2c82"}}>     
                                                                                          Consumo: {servicioPublicoSeleccionado.nombre}
                                                                                     </Col>
                                                                                     <Col span={1}>
                                                                                          <Tooltip title='Exportar a Excel'>
                                                                                               <Button className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } disabled={!this.state.mostarGrafico} onClick={onExportar} loading={fetchingRegistroLecturaReporte}/>
                                                                                          </Tooltip>
                                                                                     </Col>
                              
                                                                                     <Col span={1}>
                                                                                          <Tooltip title='Exportar a PDF'>
                                                                                               <Button className="iconSecundary" shape='circle' icon={<FilePdfOutlined /> } disabled={!this.state.mostarGrafico} onClick={onDescargar} loading={this.props.fetchingRegistroLecturaPDF}/>
                                                                                          </Tooltip>
                                                                                     </Col>
                                                                                </Row> }
                                                                      >
                                                                           <Bar
                                                                                data={chartData}
                                                                                options={optionsData}
                                                                           />
                                                                      </Card>
                                                                 </Col>

                                                                 <Col span={24}>
                                                                      <br/> <Table columns={this.state.columns} dataSource={this.props.data} size="small" pagination={false}/> <br/>
                                                                 </Col>
                                                            </Row>
                                                       
                                                       </> ): (
                                                            <Col span={24}>
                                                                 <Row justify='space-around' gutter={[8,8]}>
                                                                      <Col span={24}>
                                                                           <Alert message="Selecciona los meses a graficar." type="info" style={{ marginBottom: '16px' }} />
                                                                      </Col>
                                                                      <Col span={24}>
                                                                           <Skeleton active paragraph={{ rows: 4 }} shape="circle" />
                                                                      </Col>
                                                                 </Row>
                                                            </Col>
                                                       ) }
                                                  </Row>
                                             </TabPane>
                                             <TabPane
                                                  tab={
                                                  <span>
                                                       <FileAddOutlined />
                                                       Graficación Unitaria
                                                  </span>
                                                  }
                                                  key="1"
                                             >
                                                  <Row justify='space-around'>
                                                       <Col span={14}>
                                                            <Form.Item name='ubicacionDeposito' rules={[{ required: parseInt(this.state.current) !== 0, message:'Favor de llenar el campo'}]} >
                                                                 <Select
                                                                      allowClear
                                                                      style={{ width: '100%' }}
                                                                      placeholder="Seleccione el depósito a gráficar"
                                                                      loading={fetchingListaDepositosByIdServiciosPublicosSinProgra}
                                                                      disabled={parseInt(this.state.current) === 0}
                                                                 >
                                                                      {listaDepositosByIdServiciosPublicosSinProgra.map(option => 
                                                                           <Option key={option.idAsignacionServicioPublico}>
                                                                                <Tooltip title={option.nombreDeposito + ' ' + option.posicion + ' (' + option.claveUbicacion + ': ' + option.nombreUbicacion + ')'}> 
                                                                                     { option.nombreDeposito + ' ' + option.posicion + ' (' +  option.claveUbicacion + ': ' + option.nombreUbicacion + ')'}
                                                                                </Tooltip>
                                                                           </Option> 
                                                                      )}
                                                                 </Select> 

                                                            </Form.Item>
                                                       </Col>

                                                       <Col span={4}>
                                                            <Tooltip title='Seleccione el primer mes a comparar'>
                                                                 <Form.Item  name='rangeMonthOne' rules={[{ required: true, message: 'Favor de llenar el campo' }]} >
                                                                      <DatePicker onChange={onChangeMonthFirst} picker="month" style={{width:"100%"}} format='MMM-YYYY' placeholder='Primer mes' />
                                                                 </Form.Item>
                                                            </Tooltip>
                                                       </Col>

                                                       <Col span={4}>
                                                            <Tooltip title='Seleccione el segundo mes a comparar'>
                                                                 <Form.Item  name='rangeMonthTwo' rules={[{ required: true, message: 'Favor de llenar el campo' }]} >
                                                                      <DatePicker disabled={this.state.booleanSecondMonth} onChange={onChangeMonthSecond} picker="month" style={{width:"100%"}} format='MMM-YYYY' placeholder='Segundo mes' disabledDate={disabledDateMonth} />
                                                                 </Form.Item>
                                                            </Tooltip>
                                                       </Col>

                                                       <Col span={1}>
                                                            <Tooltip title='Graficar'><Button className="btnFiltrar" shape="circle" icon={<BarChartOutlined/>} htmlType='submit' /></Tooltip>
                                                       </Col>

                                                       <Divider style={{margin: '10px'}}/>

                                                       { this.state.mostarGrafico ? ( <>
                                                            <Row justify='space-around'>

                                                                 <Col span={24} >
                                                                      <br/>
                                                                      <Card
                                                                           // title={'Consumo: ' + servicioPublicoSeleccionado.nombre}
                                                                           className="listaCatalogos"
                                                                           bordered={true}
                                                                           style={{align: 'center'}}
                                                                           title={ 
                                                                                <Row justify='space-around'>
                                                                                     <Col span={22} style={{fontSize:18, textAlign:"left", color:"#0e2c82"}}>     
                                                                                          Consumo: {servicioPublicoSeleccionado.nombre}
                                                                                     </Col>
                                                                                     <Col span={1}>
                                                                                          <Tooltip title='Exportar a Excel'>
                                                                                               <Button className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } disabled={!this.state.mostarGrafico} onClick={onExportar} loading={fetchingRegistroLecturaReporte}/>
                                                                                          </Tooltip>
                                                                                     </Col>
                              
                                                                                     <Col span={1}>
                                                                                          <Tooltip title='Exportar a PDF'>
                                                                                               <Button className="iconSecundary" shape='circle' icon={<FilePdfOutlined /> } disabled={!this.state.mostarGrafico} onClick={onDescargar} loading={this.props.fetchingRegistroLecturaPDF}/>
                                                                                          </Tooltip>
                                                                                     </Col>
                                                                                </Row> }
                                                                      >
                                                                           <Bar
                                                                                data={chartData}
                                                                                options={optionsData}
                                                                           />
                                                                      </Card>
                                                                 </Col>

                                                                 <Col span={24}>
                                                                      <br/> <Table columns={this.state.columns} dataSource={this.props.data} size="small" pagination={false}/> <br/>
                                                                 </Col>
                                                            </Row>
                                                       
                                                       </> ): (
                                                            <Col span={24}>
                                                                 <Row justify='space-around' gutter={[8,8]}>
                                                                      <Col span={24}>
                                                                           <Alert message="Selecciona el depósito y los meses a graficar." type="info" style={{ marginBottom: '16px' }} />
                                                                      </Col>
                                                                      <Col span={24}>
                                                                           <Skeleton active paragraph={{ rows: 4 }} shape="circle" />
                                                                      </Col>
                                                                 </Row>
                                                            </Col>
                                                       ) }
                                                  </Row>
                                             </TabPane>
                                             <TabPane
                                                  tab={
                                                       <span>
                                                            <FolderViewOutlined />
                                                            Disponibilidad y Consumo
                                                       </span>
                                                       }
                                                  key="2"
                                             >

                                                  <Form {...layout} name="formularioDispo" ref={this.formRef} onFinish={onFinishDispo}  >
                                                       <Row justify='space-around' gutter={[8,8]} align='middle'>
                                                            <Col span={15}>
                                                                 <Row justify='space-around'>
                                                                      <Col span={24}>
                                                                           <Form.Item name='idAsignacionServicioPublicoDispo' rules={[{ required: !this.state.idUbiDispoSelected, message: this.state.idUbiDispoSelected ? 'Favor de llenar el campo' : '' }]} >
                                                                                <Select
                                                                                     allowClear
                                                                                     style={{ width: '100%' }}
                                                                                     placeholder="Seleccione el depósito a gráficar"
                                                                                     loading={fetchingListaDepositosByIdServiciosPublicosSinProgra}
                                                                                     disabled={this.state.idUbiDispoSelected}
                                                                                     onChange={ (value) => { if(value){ this.setState({ idAsigDispoSelected: true }); } else { this.setState({ idAsigDispoSelected: false });   } } }
                                                                                >
                                                                                     {listaDepositosByIdServiciosPublicosSinProgra.map(option => 
                                                                                          <Option key={option.idAsignacionServicioPublico}>
                                                                                               <Tooltip title={option.nombreDeposito + ' ' + option.posicion + ' (' + option.claveUbicacion + ': ' + option.nombreUbicacion + ')'}> 
                                                                                                    { option.nombreDeposito + ' ' + option.posicion + ' (' +  option.claveUbicacion + ': ' + option.nombreUbicacion + ')'}
                                                                                               </Tooltip>
                                                                                          </Option> 
                                                                                     )}
                                                                                </Select> 

                                                                           </Form.Item>
                                                                      </Col>

                                                                      <Col span={24}>
                                                                           <Form.Item name='idUbicacionDispo' rules={[{ required: !this.state.idAsigDispoSelected, message:this.state.idAsigDispoSelected ? 'Favor de llenar el campo' : ''}]} >
                                                                                <Select
                                                                                     allowClear
                                                                                     style={{ width: '100%' }}
                                                                                     placeholder="Seleccione la ubicación a gráficar"
                                                                                     loading={this.props.fetchingListaUbicacionesByIdServicioPublico}
                                                                                     disabled={this.state.idAsigDispoSelected}
                                                                                     onChange={ (value) => { if(value){ this.setState({ idUbiDispoSelected: true }); } else { this.setState({ idUbiDispoSelected: false });  } } }
                                                                                >
                                                                                     {this.props.listaUbicacionesByIdServicioPublico.map(option => 
                                                                                          <Option key={option.idUbicacion}>
                                                                                               <Tooltip title={option.claveUbicacion + ': ' + option.nombreUbicacion}> 
                                                                                                    { option.claveUbicacion + ': ' + option.nombreUbicacion}
                                                                                               </Tooltip>
                                                                                          </Option> 
                                                                                     )}
                                                                                </Select> 

                                                                           </Form.Item>
                                                                      </Col>
                                                                 </Row>
                                                            </Col>

                                                            <Col span={8}>
                                                                 <Form.Item
                                                                      name="rangoFechasDispo"
                                                                      rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                                 >
                                                                      <RangePicker
                                                                           style={{width: '100%'}} format='DD-MM-YYYY' placeholder={["Ingresa fecha inicio","Fecha fin"]}
                                                                           disabledDate={disabledDate}
                                                                      />
                                                                 </Form.Item>
                                                            </Col>

                                                            <Col span={1} style={{paddingBottom:'2em'}}>
                                                                 <Tooltip title='Graficar'><Button className="btnFiltrar" shape="circle" icon={<BarChartOutlined/>} htmlType='submit' /></Tooltip>
                                                            </Col>

                                                            <Divider style={{margin: '10px'}}/>
                                                            
                                                            <Col span={24}>
                                                                 <Tabs className='tabDashboard' defaultActiveKey="Dispo" type='card'>
                                                                      <TabPane
                                                                           tab={
                                                                                <span>
                                                                                     Graficación Disponibilidad
                                                                                </span>
                                                                                }
                                                                           key="Dispo"
                                                                      >
                                                                           <Row justify='space-around' gutter={[8,8]}>
                                                                                <Col span={24} >
                                                                                <Spin spinning={this.props.fetchingRegistroLecturaPresentacionDispo}>
                                                                                { this.props.registroLecturaPresentacionDispo.length > 0 ? (
                                                                                     <Card
                                                                                          title={
                                                                                               <Row justify='space-around'>
                                                                                                    <Col span={22} style={{fontSize:18, textAlign:"left", color:"#0e2c82"}}>     
                                                                                                         {'Disponibilidad: ' + servicioPublicoSeleccionado.nombre}
                                                                                                    </Col>
                                                                                                    <Col span={1}>
                                                                                                         <Tooltip title='Exportar a Excel'>
                                                                                                              <Button className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } onClick={() => onExportarExcelType('disponibilidad')} loading={fetchingRegistroLecturaReporte}/>
                                                                                                         </Tooltip>
                                                                                                    </Col>
                                             
                                                                                                    <Col span={1}>
                                                                                                         <Tooltip title='Exportar a PDF'>
                                                                                                              <Button className="iconSecundary" shape='circle' icon={<FilePdfOutlined /> } onClick={() => onDescargarPdfType('disponibilidad')} loading={this.props.fetchingRegistroLecturaPDF}/>
                                                                                                         </Tooltip>
                                                                                                    </Col>
                                                                                               </Row>
                                                                                            }
                                                                                          className="listaCatalogos"
                                                                                          bordered={true}
                                                                                          style={{align: 'center'}}
                                                                                     >
                                                                                          <Line
                                                                                               data={{
                                                                                                    labels: this.props.etiquetasPresentacionDispo,
                                                                                                    datasets: [{  data: this.props.dataPresentacionDispo,  backgroundColor: '#ff8c62', borderColor: '#e64d14',  borderWidth: 1.5, label: this.props.unidadMedidaDispo }],
                                                                                               }}
                                                                                               options={
                                                                                                    {
                                                                                                         responsive: true,
                                                                                                         scales: {
                                                                                                              xAxes: [{
                                                                                                                   display: true,
                                                                                                                   ticks: {mirror: false},
                                                                                                                   gridLines: { display : true },
                                                                                                                   position: 'left',
                                                                                                                   // scaleLabel: { display: true, labelString: ['Servicios'] }
                                                                                                              }],
                                                                                                              yAxes: [{  
                                                                                                                   display: true,
                                                                                                                   //scaleLabel: { display: true, labelString: ['No. Quejas y Reclamos'] } 
                                                                                                                   ticks: {
                                                                                                                        beginAtZero:true,
                                                                                                                        userCallback: function(value, index, values) {
                                                                                                                        value = value.toString();
                                                                                                                        value = value.split(/(?=(?:...)*$)/);
                                                                                                                        value = value.join(',');
                                                                                                                        return value;
                                                                                                                        },
                                                                                                                   },
                                                                                                                   position: 'bottom',
                                                                                                                   type: "linear",
                                                                                                              }]
                                                                                                         },
                                                                                                         tooltips: {
                                                                                                              callbacks: {
                                                                                                                   title: function(tooltipItem, data) {
                                                                                                                        return data['labels'][tooltipItem[0]['index']];
                                                                                                                   },
                                                                                                                   label: function(tooltipItem, data) { 
                                                                                                                        return 'Disponible: ' + tooltipItem.yLabel.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
                                                                                                                   },
                                                                                                              },
                                                                                                              backgroundColor: '#FFF',
                                                                                                              titleFontColor: '#1747ca',
                                                                                                              bodyFontColor: '#0e2c82',
                                                                                                              position: 'nearest',
                                                                                                              mode: 'nearest',
                                                                                                              "enabled": true,
                                                                                                              displayColors: false,
                                                                                                              caretSize: 0,
                                                                                                              titleFontSize: 12,
                                                                                                              bodyFontSize: 11,
                                                                                                              bodySpacing: 0,
                                                                                                              titleSpacing: 0,
                                                                                                              xPadding: 1,
                                                                                                              yPadding: 1,
                                                                                                              cornerRadius: 2,
                                                                                                              titleMarginBottom: 2,
                                                                                                         },
                                                                                                         legend: { display: true, position: "top", labels: {boxWidth: 15} },
                                                                                                         plugins: {
                                                                                                              datalabels: {
                                                                                                              anchor: 'end',
                                                                                                              align: 'end',
                                                                                                              rotation: -45
                                                                                                              }
                                                                                                         }
                                                                                                    }
                                                                                               }
                                                                                          />
                                                                                     </Card>
                                                                                
                                                                                ):(
                                                                                     <Col span={24}>
                                                                                          <Row justify='space-around' gutter={[8,8]}>
                                                                                               <Col span={24}>
                                                                                                    <Alert message="Selecciona el depósito o ubicación y el rango de fechas a graficar." type="info" style={{ marginBottom: '16px' }} />
                                                                                               </Col>
                                                                                               <Col span={24}>
                                                                                                    <Skeleton active paragraph={{ rows: 4 }} shape="circle" />
                                                                                               </Col>
                                                                                          </Row>
                                                                                     </Col>
                                                                                ) }
                                                                                </Spin>
                                                                                </Col>

                                                                                { this.props.registroLecturaPresentacionDispo.length > 0 ? (
                                                                                     <Col span={24}>
                                                                                          <Descriptions layout="vertical" bordered={true} size="small" column={{ xxl: 8, xl: 8, lg: 8, md: 8, sm: 8, xs: 8 }}>
                                                                                               {this.props.dataDescriptionDispo.map(item => 
                                                                                                    <Descriptions.Item label={item.label}>{numberWithCommas(item.data)}</Descriptions.Item>
                                                                                               )}
                                                                                          </Descriptions>
                                                                                     </Col>
                                                                                ):false}
                                                                           </Row>
                                                                      </TabPane>

                                                                      <TabPane
                                                                           tab={
                                                                                <span>
                                                                                     Graficación Consumo
                                                                                </span>
                                                                                }
                                                                           key="Cons"
                                                                      >
                                                                           <Row justify='space-around' gutter={[8,8]}>
                                                                                <Col span={24} >
                                                                                <Spin spinning={this.props.fetchingRegistroLecturaPresentacionCons}>
                                                                                { this.props.registroLecturaPresentacionCons.length > 0 ? (
                                                                                     <Card
                                                                                          title={
                                                                                               <Row justify='space-around'>
                                                                                                    <Col span={22} style={{fontSize:18, textAlign:"left", color:"#0e2c82"}}>     
                                                                                                         {'Consumos: ' + servicioPublicoSeleccionado.nombre}
                                                                                                    </Col>
                                                                                                    <Col span={1}>
                                                                                                         <Tooltip title='Exportar a Excel'>
                                                                                                              <Button className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } onClick={() => onExportarExcelType('consumo')} loading={fetchingRegistroLecturaReporte}/>
                                                                                                         </Tooltip>
                                                                                                    </Col>
                                             
                                                                                                    <Col span={1}>
                                                                                                         <Tooltip title='Exportar a PDF'>
                                                                                                              <Button className="iconSecundary" shape='circle' icon={<FilePdfOutlined /> } onClick={() => onDescargarPdfType('consumo')} loading={this.props.fetchingRegistroLecturaPDF}/>
                                                                                                         </Tooltip>
                                                                                                    </Col>
                                                                                               </Row> }
                                                                                          className="listaCatalogos"
                                                                                          bordered={true}
                                                                                          style={{align: 'center'}}
                                                                                     >
                                                                                          <Bar
                                                                                               data={{
                                                                                                    labels: this.props.etiquetasPresentacionCons,
                                                                                                    datasets: [{  data: this.props.dataPresentacionCons,  backgroundColor: '#1747ca', borderColor: '#1747ca',  borderWidth: 1.5, label: this.props.unidadMedidaCons }],
                                                                                               }}
                                                                                               
                                                                                               options={
                                                                                                    {
                                                                                                         responsive: true,
                                                                                          
                                                                                                         scales: {
                                                                                                              xAxes: [{
                                                                                                                   display: true,
                                                                                                                   ticks: {mirror: false},
                                                                                                                   gridLines: { display : true },
                                                                                                                   position: 'left',
                                                                                                                   // scaleLabel: { display: true, labelString: ['Servicios'] }
                                                                                                              }],
                                                                                                              yAxes: [{  
                                                                                                                   display: true,
                                                                                                                   //scaleLabel: { display: true, labelString: ['No. Quejas y Reclamos'] } 
                                                                                                                   ticks: {
                                                                                                                        beginAtZero:true,
                                                                                                                        userCallback: function(value, index, values) {
                                                                                                                        value = value.toString();
                                                                                                                        value = value.split(/(?=(?:...)*$)/);
                                                                                                                        value = value.join(',');
                                                                                                                        return value;
                                                                                                                        },
                                                                                                                   },
                                                                                                                   position: 'bottom',
                                                                                                                   type: "linear",
                                                                                                              }]
                                                                                                         },
                                                                                                         tooltips: {
                                                                                                              callbacks: {
                                                                                                                   title: function(tooltipItem, data) {
                                                                                                                        return data['labels'][tooltipItem[0]['index']];
                                                                                                                   },
                                                                                                                   label: function(tooltipItem, data) { 
                                                                                                                        return 'Consumo: ' + tooltipItem.yLabel.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
                                                                                                                   },
                                                                                                              },
                                                                                                              backgroundColor: '#FFF',
                                                                                                              titleFontColor: '#1747ca',
                                                                                                              bodyFontColor: '#1747ca',
                                                                                                              position: 'nearest',
                                                                                                              mode: 'nearest',
                                                                                                              "enabled": true,
                                                                                                              displayColors: false,
                                                                                                              caretSize: 0,
                                                                                                              titleFontSize: 12,
                                                                                                              bodyFontSize: 11,
                                                                                                              bodySpacing: 0,
                                                                                                              titleSpacing: 0,
                                                                                                              xPadding: 1,
                                                                                                              yPadding: 1,
                                                                                                              cornerRadius: 2,
                                                                                                              titleMarginBottom: 2,
                                                                                                         },
                                                                                                         legend: { display: true, position: "top", labels: {boxWidth: 15} },
                                                                                                         plugins: {
                                                                                                              datalabels: {
                                                                                                              anchor: 'end',
                                                                                                              align: 'end',
                                                                                                              rotation: -45
                                                                                                              }
                                                                                                         }
                                                                                                    }
                                                                                               }
                                                                                          />
                                                                                     </Card>
                                                                                
                                                                                ):(<Col span={24}>
                                                                                     <Row justify='space-around' gutter={[8,8]}>
                                                                                          <Col span={24}>
                                                                                               <Alert message="Selecciona el depósito o ubicación y el rango de fechas a graficar." type="info" style={{ marginBottom: '16px' }} />
                                                                                          </Col>
                                                                                          <Col span={24}>
                                                                                               <Skeleton active paragraph={{ rows: 4 }} shape="circle" />
                                                                                          </Col>
                                                                                     </Row>
                                                                                </Col>) }
                                                                                </Spin>
                                                                                </Col>
                                                                           
                                                                                { this.props.registroLecturaPresentacionCons.length > 0 ? (
                                                                                     <Col span={24}>
                                                                                          <Descriptions layout="vertical" bordered={true} size="small" column={{ xxl: 8, xl: 8, lg: 8, md: 8, sm: 8, xs: 8 }}>
                                                                                               {this.props.dataDescriptionCons.map(item => 
                                                                                                    <Descriptions.Item label={item.label}>{numberWithCommas(item.data)}</Descriptions.Item>
                                                                                               )}
                                                                                          </Descriptions>
                                                                                     </Col>
                                                                                ):false}
                                                                           </Row>
                                                                      </TabPane>
                                                                 </Tabs>
                                                            </Col>
                                                       </Row>
                                                  </Form>

                                             </TabPane>
                                        </Tabs>
                                        <br />
                                   </Col>


                              </Row>
                         
                         </div>
                              {/* { this.state.current === 1 || this.state.current === 0 ? (
                                   <Row justify='space-around'>
                                        <Col span={11}>
                                             <Button className='btnCargaArchivos' style={{ width:'100%' }} disabled={!this.state.mostarGrafico} onClick={onExportar} loading={fetchingRegistroLecturaReporte}>
                                                  Exportar a Excel
                                             </Button>
                                        </Col>
                                        <Col span={11}>
                                             <Button className='btnCargaArchivos' style={{ width:'100%' }} disabled={!this.state.mostarGrafico} onClick={onDescargar} loading={this.props.fetchingRegistroLecturaPDF}>
                                                  Exportar a PDF
                                             </Button>
                                        </Col>
                                   </Row>
                              ): false } */}
                         </Form>
                    </Spin>
                                
               </Modal>
               ) : false }    
          </>);             
     }
}

const mapStateToProps = state => {
     return {
          showModalPresentacionConsumos: state.ServiciosPublicosReducer.showModalPresentacionConsumos,
          servicioPublicoSeleccionado: state.ServiciosPublicosReducer.servicioPublicoSeleccionado,
          listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
          fetchingListaDepositosByIdServiciosPublicosSinProgra: state.ServiciosPublicosReducer.fetchingListaDepositosByIdServiciosPublicosSinProgra,
          listaDepositosByIdServiciosPublicosSinProgra: state.ServiciosPublicosReducer.listaDepositosByIdServiciosPublicosSinProgra,
          registroLecturasPresentacion: state.ServiciosPublicosReducer.registroLecturasPresentacion,
          chartData: state.ServiciosPublicosReducer.chartData,
          optionsData: state.ServiciosPublicosReducer.optionsData,
          consumo1: state.ServiciosPublicosReducer.consumo1,
          consumo2: state.ServiciosPublicosReducer.consumo2,
          data: state.ServiciosPublicosReducer.data,
          fetchingRegistroLecturasPresentacion: state.ServiciosPublicosReducer.fetchingRegistroLecturasPresentacion,
          fetchingRegistroLecturaReporte: state.ServiciosPublicosReducer.fetchingRegistroLecturaReporte,

          etiquetasPresentacionDispo: state.ServiciosPublicosReducer.etiquetasPresentacionDispo,
          dataPresentacionDispo: state.ServiciosPublicosReducer.dataPresentacionDispo,
          fetchingRegistroLecturaPresentacionDispo: state.ServiciosPublicosReducer.fetchingRegistroLecturaPresentacionDispo,
          registroLecturaPresentacionDispo: state.ServiciosPublicosReducer.registroLecturaPresentacionDispo,
          unidadMedidaDispo: state.ServiciosPublicosReducer.unidadMedidaDispo,
          dataDescriptionDispo: state.ServiciosPublicosReducer.dataDescriptionDispo,

          etiquetasPresentacionCons: state.ServiciosPublicosReducer.etiquetasPresentacionCons,
          dataPresentacionCons: state.ServiciosPublicosReducer.dataPresentacionCons,
          fetchingRegistroLecturaPresentacionCons: state.ServiciosPublicosReducer.fetchingRegistroLecturaPresentacionCons,
          registroLecturaPresentacionCons: state.ServiciosPublicosReducer.registroLecturaPresentacionCons,
          unidadMedidaCons: state.ServiciosPublicosReducer.unidadMedidaCons,
          dataDescriptionCons: state.ServiciosPublicosReducer.dataDescriptionCons,

          listaUbicacionesByIdServicioPublico: state.ServiciosPublicosReducer.listaUbicacionesByIdServicioPublico,
          fetchingListaUbicacionesByIdServicioPublico: state.ServiciosPublicosReducer.fetchingListaUbicacionesByIdServicioPublico,

          fetchingRegistroLecturaPDF: state.ServiciosPublicosReducer.fetchingRegistroLecturaPDF,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onCloseModal: () => {
               dispatch({ type: 'SHOW_MODAL_PRESENTACION_CONSUMOS', showModalPresentacionConsumos: false });
          },
          onRequestInfoProyecto: () => {
               dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
          },
          onLimpiarDepositoByidServicioPublico: () => {
               dispatch({ type: 'LISTA_DEPOSITO_BY_SERVICIO_SIN_PROGRA_SUCCESS', listaDepositosByIdServiciosPublicosSinProgra: []});
          },
          onPresentacion: (idAsignacionServicioPublico, idServicioPublico, fechaInicio1, fechaFin1, fechaInicio2, fechaFin2, servicioPublicoSeleccionado, datoGraficado) => {
               dispatch({ type: 'REGISTRO_LECTURA_PRESENTACION_REQUEST', idAsignacionServicioPublico, idServicioPublico, fechaInicio1, fechaFin1, fechaInicio2, fechaFin2, servicioPublicoSeleccionado, datoGraficado });
          },
          onReporte: (idAsignacionServicioPublico, idServicioPublico, fechaInicio1, fechaFin1, fechaInicio2, fechaFin2) => {
               dispatch({ type: 'REGISTRO_LECTURA_EXCEL_REQUEST', idAsignacionServicioPublico, idServicioPublico, fechaInicio1, fechaFin1, fechaInicio2, fechaFin2 });
          },
          onReportePDF: (idAsignacionServicioPublico, idServicioPublico, fechaInicio1, fechaFin1, fechaInicio2, fechaFin2) => {
               dispatch({ type: 'REGISTRO_LECTURAS_PDF_REQUEST', idAsignacionServicioPublico, idServicioPublico, fechaInicio1, fechaFin1, fechaInicio2, fechaFin2 });
          },
          onPresentacionDisponibilidad: (idAsignacionServicioPublico, idServicioPublico, idUbicacion, fechaInicio, fechaFin) => {
               dispatch({ type: 'REGISTRO_LECTURA_PRESENTACION_DISPONIBILIDAD_REQUEST', idAsignacionServicioPublico, idServicioPublico, idUbicacion, fechaInicio, fechaFin });
          },
          onCleanPresentacionDisponibilidad: () => {
               dispatch({ type: 'REGISTRO_LECTURA_PRESENTACION_DISPONIBILIDAD_SUCCESS', registroLecturaPresentacionDispo: [], etiquetasPresentacionDispo: [], dataPresentacionDispo: [], unidadMedidaDispo: '' });
          }, 
          onPresentacionConsumo: (idAsignacionServicioPublico, idServicioPublico, idUbicacion, fechaInicio, fechaFin) => {
               dispatch({ type: 'REGISTRO_LECTURA_PRESENTACION_CONSUMO_REQUEST', idAsignacionServicioPublico, idServicioPublico, idUbicacion, fechaInicio, fechaFin });
          },
          onCleanPresentacionConsumo: () => {
               dispatch({ type: 'REGISTRO_LECTURA_PRESENTACION_CONSUMO_SUCCESS', registroLecturaPresentacionCons: [], etiquetasPresentacionCons: [], dataPresentacionCons: [], unidadMedidaCons: '' });
          },
          onCleanUbicacionesByIdServicioPublico: () => {
               dispatch({ type:'LISTA_UBICACIONES_BY_IDSERVICIOPUBLICO_SUCCESS', listaUbicacionesByIdServicioPublico: [] })
          },
          onGetPdfDisponibilidad: (idServicioPublico, idAsignacionServicioPublico,fechaInicio,fechaFin, idUbicacion) => {
               dispatch({ type:'REGISTRO_LECTURA_DISPONIBILIDAD_PDF_REQUEST',idServicioPublico, idAsignacionServicioPublico,fechaInicio,fechaFin, idUbicacion})
          },
          onGetPdfConsumo: (idServicioPublico, idAsignacionServicioPublico,fechaInicio,fechaFin, idUbicacion) => {
               dispatch({ type:'REGISTRO_LECTURA_CONSUMO_PDF_REQUEST',idServicioPublico, idAsignacionServicioPublico,fechaInicio,fechaFin, idUbicacion })
          },
          onGetExcelConsumo: (idServicioPublico, idAsignacionServicioPublico,fechaInicio,fechaFin, idUbicacion) => {
               dispatch({ type:'REGISTRO_LECTURA_CONSUMO_EXCEL_REQUEST', idServicioPublico, idAsignacionServicioPublico,fechaInicio,fechaFin, idUbicacion})
          },
          onGetExcelDisponibilidad: (idServicioPublico, idAsignacionServicioPublico,fechaInicio,fechaFin, idUbicacion) => {
               dispatch({ type:'REGISTRO_LECTURA_DISPONIBILIDAD_EXCEL_REQUEST', idServicioPublico, idAsignacionServicioPublico,fechaInicio,fechaFin, idUbicacion})
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(PresentacionConsumo);