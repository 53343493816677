import React, {useState, useEffect, useRef} from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import {  Col, Input, Button, Form , Select, DatePicker, Tooltip, Row, Upload, message} from 'antd';
import { UploadOutlined} from '@ant-design/icons';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import useArchivo from './useArchivo';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const {Search} = Input;
const {Option} = Select;
const {TextArea} = Input;

const Formulario = props => {
    const {ticketSSConciliado, disabled, id, children, action, page, busqueda,
        fallasPorServicio, fetchingFallasPorServicio, fetchingListaTiposFallasActivas, listaTiposFallasActivas, fetchingUbicacionesSinHijos, ubicacionesSinHijos,
        fetchingDocumentosFiscalizacion, listaDocumentosFiscalizacion, fetchingNivelesSeveridadPorFalla, listaNivelesSeveridadPorFalla,
        onGetListaTiposFallasActivas, onFallasPorServicio, onFallasPorServicioLimpiar, onGetNivelSeveridad, onLimpiarTicketSS,
        onUbicacionesSinHijosFiltro, onUbicacionesSinHijosFiltroLimpiar, onGetDocumentoFiscalizacion, onCrearFolioEO, onEditarFolioEO,
    } = props;

    const formRef = useRef();
    const [tipoFalla, setTipoFalla] = useState(null)
    const [severidad, setSeveridad] = useState(false);
    const [tipoFolio, setTipoFolio] = useState(null);
    const {status, dummyRequest, onChangePDF, onRemovePDF} = useArchivo(formRef)


    useEffect (() => {
        onGetListaTiposFallasActivas();
        onGetDocumentoFiscalizacion();
        onGetNivelSeveridad();

        //Unmount
        return () => {
            setTipoFalla(null);
            if(formRef?.current) {
                formRef.current.resetFields()
                onLimpiarTicketSS();
            }
        };
    }, []);

    useEffect (() => {
        
        setTipoFalla(ticketSSConciliado?.fallaByIdFalla?.idTipoFalla);
        
        if(action==='Crear') {
            setSeveridad(ticketSSConciliado?.severidad)
            setTipoFolio('Error')
        }

        if(action==='Editar') {
            const severidad = ticketSSConciliado?.idNivelSeveridad;
            setSeveridad(severidad)
            setTipoFolio(ticketSSConciliado?.tipoFolio);
            setTipoFalla(ticketSSConciliado?.idTipoFalla)
        } 

        if(formRef?.current) {
            formRef.current.resetFields()
        }
        
    },[ticketSSConciliado])


    const onChangeTipoFalla = (value) => {
        if(value) {
            const idTipoFalla = value.split(' - ')[0]
            setTipoFalla(idTipoFalla);
        }
    };

    const buscarFalla = (value) => {
        const idServicio = ticketSSConciliado?.solicitudServicioByIdSolicitudServicio?.idServicio || ticketSSConciliado?.idServicio;
        if(value.length>=3) {
            onFallasPorServicio(idServicio, value);
        } else {
            onFallasPorServicioLimpiar()
        }
    };

    const onChangeFalla = (value, data) => {
        if(value) {
            const servicioData = data.servicio.servicioByIdServicio;
            const campoServicio = `${servicioData.idServicio} - ${servicioData.nomenclatura} (${servicioData.nombre})`;
            formRef.current.setFieldsValue({'servicio': campoServicio});
            
            const severidad = data.servicio.estandarByIdEstandar.servSeccionesEspecificasById.idServSeccionEspecifico === 1 ? true : false;
            setSeveridad(severidad);
        }
    }

    const buscarUbicacionSinHijo = (value) => {
        if(value.length>=3){
            onUbicacionesSinHijosFiltro(value);
        }
        else{
             onUbicacionesSinHijosFiltroLimpiar();
        }
    };

    const disabledRange = fecha => {
        let fechaInicio;
        if(tipoFolio === 'Error') {
            fechaInicio = ticketSSConciliado.solicitudServicioByIdSolicitudServicio.fechaAlta.split('T')[0];
            fechaInicio = fechaInicio.split('-').reverse().join('-');
            fechaInicio = moment(fechaInicio, 'DD-MM-YYYY');
        }
        if(tipoFolio === 'Omision') {
            fechaInicio = formRef.current.getFieldValue('fechaOmision');
            fechaInicio = fechaInicio.format('DD-MM-YYYY');
            fechaInicio = moment(fechaInicio, 'DD-MM-YYYY');
        }
        if( fecha && fecha < fechaInicio) {
            return fecha && fecha < fechaInicio;
        } 
    }

    function range(start, end) {
        const result = [];
        for (let i = start; i < end; i++) {
          result.push(i);
        }
        return result;
    }

    const disabledTime = fecha => {
        let hours = 0;
        let minutes = 0;
        let fechaInicio = ""
        if(tipoFolio==='Error') {
            const fechaAlta = ticketSSConciliado.solicitudServicioByIdSolicitudServicio.fechaAlta.split('T');
            fechaInicio = fechaAlta[0].split('-').reverse().join('-');
            const time = fechaAlta[1] 
            hours = parseInt(time.split(':')[0]);
            minutes = parseInt(time.split(':')[1]);
        }
        if(tipoFolio==='Omision'){
            let fechaOmision = formRef.current.getFieldValue('fechaOmision');
            fechaOmision = fechaOmision.format('DD-MM-YYYYTHH:mm').split('T');
            fechaInicio = fechaOmision[0];
            const time = fechaOmision[1];
            hours = parseInt(time.split(':')[0]);
            minutes = parseInt(time.split(':')[1]);
        }

        let dateSelected="";
        let hourSelected=0;
        if(fecha) {
            dateSelected = fecha.format('DD-MM-YYYY');
            hourSelected = fecha._d.getHours();
        }
        
        hours = dateSelected == fechaInicio ? hours : 0;
        minutes = hourSelected == hours ? minutes +1 : 0;
        return {
            disabledHours: () => range(0, hours),
            disabledMinutes: () => range(0 , minutes),
        };
    }

    const onFinish = formulario => {
        const {fechaCierreEditar, fechaOmision} = formRef.current.getFieldsValue();
        if(fechaOmision > fechaCierreEditar) return message.warning('La fecha de cierre debe ser mayor a fecha de omisión');

        if (action === 'Crear') return onCrearFolioEO(formulario, ticketSSConciliado, tipoFolio, page, busqueda)
        if (action === 'Editar') return onEditarFolioEO(formulario, ticketSSConciliado, page, busqueda)
    }

    const spanValue = action === 'Editar' ? 11 : 24;

    return (
      <>
      {ticketSSConciliado?.tipoFalla && 
        <Form
          {...layout}
          name={`formulario${id}`}
          onFinish={onFinish}
          initialValues={ticketSSConciliado}
          ref={formRef}
        >
            <Row justify='space-between'>

                <Col span={spanValue}>
                    <Form.Item 
                        label="Tipo de falla" 
                        name="tipoFalla"
                        rules={[{ required: !disabled, message: 'Favor de llenar el campo' }]}
                    >
                        <Select
                            style={{ width: "100%" }}
                            placeholder="Selecciona el tipo de falla"
                            loading={fetchingListaTiposFallasActivas}
                            onChange={onChangeTipoFalla}
                            showSearch
                            allowClear
                            disabled={disabled}
                        >
                            {listaTiposFallasActivas.map((option) => (
                            <Option key={option.idTipoFalla}>
                                <Tooltip
                                placement="topLeft"
                                title={option.idTipoFalla + " - " + option.nombre}
                                >
                                {option.nombre}
                                </Tooltip>
                            </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={spanValue}>
                    <Form.Item
                        label="Falla"
                        name="nombreFalla"
                        rules={[{ required: !disabled, message: 'Favor de llenar el campo' }]}
                        >
                        <Select 
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Seleccione la falla"
                            loading={fetchingFallasPorServicio}
                            onSearch={buscarFalla}
                            notFoundContent={null}
                            filterOption={false}
                            onChange={onChangeFalla}
                            disabled={disabled}
                        >
                            {fallasPorServicio.map(option => 
                            <Option key={option.idFalla} servicio={option}>
                                <Tooltip placement="topLeft" title={option.idFalla + '-' + option.nombre}>
                                    {option.nombre}
                                </Tooltip>
                            </Option>)}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label="Descripcion de falla"
                        name="descripcionDeFalla"
                    >
                        <TextArea disabled rows={2}/>
                    </Form.Item>
                </Col>

                {tipoFalla == 1 && severidad && tipoFolio!=='Omision' &&(
                    <Col span={spanValue}>
                        <Form.Item 
                            label="Nivel de Severidad"
                            name='nivelSeveridad'
                            rules={[{ required: !disabled, message: 'Favor de llenar el campo', }]}
                            >
                            <Select
                                style={{ width: '100%' }} 
                                placeholder="Selecciona el nivel de severidad" 
                                loading={fetchingNivelesSeveridadPorFalla}  
                                showSearch 
                                allowClear
                                disabled={disabled}
                            >
                                {listaNivelesSeveridadPorFalla.map(option => 
                                <Option key={option.idNivelSeveridad}>
                                    <Tooltip placement="topLeft" title={option.idNivelSeveridad + '-' +option.descripcion}>
                                            {option.descripcion}
                                    </Tooltip>
                                </Option>)}
                            </Select>
                        </Form.Item>
                    </Col>

                ) }

                <Col span={spanValue}>
                    <Form.Item
                        label="Ubicación"
                        name="ubicacion"
                        rules={[{ required: !disabled, message: 'Favor de llenar el campo', }]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Escriba la ubicación a buscar"
                            loading={fetchingUbicacionesSinHijos}
                            showSearch
                            filterOption={false}
                            onSearch={buscarUbicacionSinHijo}
                            notFoundContent={null}
                            disabled={disabled}
                        >
                            {ubicacionesSinHijos.map(option => 
                            <Option key={option.idUbicacion + ' - ' + option.ubicacionCOA}>
                                {option.idUbicacion + ' - ' + option.ubicacionCOA}
                            </Option>
                            )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={spanValue}>
                    <Form.Item
                        label="Servicio"
                        name="servicio"
                        rules={[{ required: !disabled, message: 'Favor de llenar el campo', }]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Selecciona una falla"
                            disabled
                        />
                    </Form.Item>
                </Col>
                {tipoFolio === 'Omision' && 
                    <Col span={spanValue}>                   
                        <Form.Item
                            label="Fecha de omisión"
                            name="fechaOmision"
                            rules={[{ required: !disabled, message: 'Favor de llenar el campo' }]}
                        >
                            <DatePicker 
                                style={{ width: '100%' }} 
                                format='DD-MM-YYYY HH:mm' 
                                showTime={true}
                                disabled={disabled}
                            />      
                        </Form.Item> 
                    </Col>
                }
                <Col span={spanValue}>                   
                    <Form.Item
                        label="Fecha de cierre"
                        name="fechaCierreEditar"
                        rules={[{ required: !disabled, message: 'Favor de llenar el campo' }]}
                    >
                        <DatePicker 
                            style={{ width: '100%' }} 
                            format='DD-MM-YYYY HH:mm' 
                            showTime={true}
                            disabled={disabled}
                            disabledDate={disabledRange}
                            disabledTime={disabledTime}
                        />      
                    </Form.Item> 
                </Col>
                <Col span={spanValue}>
                    <Form.Item
                        label='No. Documento fizcalización'
                        name='noDocFiscalizacion'
                        rules={[{ required: !disabled, message: 'Favor de llenar el campo' }]}
                    >
                        <Input 
                            maxLength={100} 
                            placeholder='Ingrese No. Documento'
                            pattern="^[-/a-zñA-ZÑ0-9 ÁÉÓÚÍáéóíú]+$"
                            disabled={disabled}
                        />

                    </Form.Item>
                </Col>
                <Col span={spanValue}>
                    <Form.Item
                        label='Tipo documento fizcalización'
                        name='tipoDocFiscalizacion'
                        rules={[{ required: !disabled, message: 'Favor de llenar el campo' }]}
                    >
                        <Select
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Seleccione el tipo de documento"
                            loading={fetchingDocumentosFiscalizacion}
                            filterOption={false}
                            notFoundContent={null}
                            disabled={disabled}
                        >
                            {listaDocumentosFiscalizacion.map(option => 
                            <Option key={option.idDocumentoFiscalizacion}>
                                {option.tipo}
                            </Option>
                            )}
                        </Select>

                    </Form.Item>
                </Col>
                <Col span={spanValue}>
                    <Form.Item
                        label="Evidencia PDF"
                        name="pdf"
                    >
                        {!disabled ? (
                            <Upload  customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false} style={{ width: '100%' }}>
                                {status.filePDF === false ? (
                                <Button disabled={status.botonPDF} className='btnCargaArchivos'>
                                    <UploadOutlined /> Click para subir evidencia PDF
                                </Button>
                                ):false}
                            </Upload>

                        ): (
                            <a target='_blank' href={ticketSSConciliado.rutaArchivo}>Documento PDF</a>
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Row justify='center'>
                {children && 
                    <Col span={spanValue}>
                        {children}
                    </Col>
                }
            </Row>
        </Form>
      }
      </>
    );
};

const mapStateToProps = state => {
    return {
        fetchingFallasPorServicio: state.MecanismosDePagosReducer.fetchingFallasPorServicio,
        fallasPorServicio: state.MecanismosDePagosReducer.fallasPorServicio,
        fetchingListaTiposFallasActivas: state.SolicitudDeServiciosReducer.fetchingListaTiposFallasActivas,
        listaTiposFallasActivas: state.SolicitudDeServiciosReducer.listaTiposFallasActivas,
        fetchingNivelesSeveridadPorFalla: state.MecanismosDePagosReducer.fetchingNivelesSeveridadPorFalla,
        listaNivelesSeveridadPorFalla: state.MecanismosDePagosReducer.listaNivelesSeveridadPorFalla,
        ubicacionesSinHijos: state.SolicitudDeServiciosReducer.ubicacionesSinHijos,
        fetchingUbicacionesSinHijos: state.SolicitudDeServiciosReducer.fetchingUbicacionesSinHijos,
        fetchingDocumentosFiscalizacion: state.MecanismosDePagosReducer.fetchingDocumentosFiscalizacion,
        listaDocumentosFiscalizacion: state.MecanismosDePagosReducer.listaDocumentosFiscalizacion,
        page: state.MecanismosDePagosReducer.page,
        busqueda: state.MecanismosDePagosReducer.busqueda,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLimpiarTicketSS: () => {
            dispatch({ type: 'TICKET_SS_CONCILIADO_SUCCESS', ticketSSConciliado:null});
        },
        onGetListaTiposFallasActivas: () => {
            dispatch({ type: "LISTA_TIPOS_FALLAS_ACTIVAS_REQUEST" })
       },
        onFallasPorServicio: (idServicio, param) => {
            dispatch({ type: 'FALLAS_POR_SERVICIO_REQUEST', idServicio, param })
        },
        onFallasPorServicioLimpiar: () => {
            dispatch({ type: "FALLAS_POR_SERVICIO_SUCCESS", fallasPorServicio: []});
        },
        onGetNivelSeveridad: () => {
            dispatch({ type: 'NIVELES_DE_SEVERIDAD_POR_FALLA_REQUEST' });
       },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        },
        onUbicacionesSinHijosFiltro: (busqueda) => {
            dispatch({ type: 'UBICACIONES_SIN_HIJOS_REQUEST', busqueda });
       },
       onUbicacionesSinHijosFiltroLimpiar: () => {
            dispatch({ type: 'UBICACIONES_SIN_HIJOS_SUCCESS', ubicacionesSinHijos: [] });
       },
       onGetDocumentoFiscalizacion: () => {
           dispatch({ type: 'DOCUMENTOS_FISCALIZACION_REQUEST'});
       },
       onCrearFolioEO: (formulario, ticketSSConciliado, tipoFolio, page, busqueda) => {
           dispatch({ type: 'NUEVO_FOLIO_ERROR_OMISION_REQUEST', formulario, ticketSSConciliado, tipoFolio, page, busqueda});
       },
       onEditarFolioEO: (formulario, ticketSSConciliado, page, busqueda) => {
        dispatch({ type: 'EDITAR_FOLIO_ERROR_OMISION_REQUEST', formulario, ticketSSConciliado, page, busqueda});
    }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Formulario);