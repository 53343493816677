import React from 'react';
import { Table, Row, Col, Switch, Modal, Spin, Button, Form, Input, message, Select, Tooltip, Card } from 'antd';
import { connect } from "react-redux";
import { EditOutlined, SearchOutlined, KeyOutlined, PlusOutlined, UnlockOutlined } from '@ant-design/icons';

import '../../../stylesheets/ConfiguracionYSeguridad/Usuarios.css';
import ModalEditarUsuario from './ModalEditarUsuario';
import ModalNuevoUsuario from './ModalNuevoUsuario';
import EditarPerfil from '../../EditarPerfil';

const confirm = Modal.confirm;
const {Option} = Select

class UsuariosContainer extends React.Component {
    componentDidMount(){
        this.props.onGetListaUsuariosSinFiltros(1);
        this.props.onListaDominiosActivos();
        this.props.onListaRoles();
        this.props.onGetProveedores();
        const formulario = {
            dominio: null,
            servidor: null,
        }
        this.setState({
            formulario: formulario,
        })
        this.props.onGetServiciosActivos();
    }
    state={
        page: 1,
        recuperarContraseña: false,
        usuarioSeleccionado: null,
    }
    render() {
        const { fetchingListaUsuarios, listaUsuarios, fetchingEstadoUsuario, onRequestCambiarEstadoUsuario, 
            onModalEditarUsuario, onModalNuevoUsuario, onFiltrarUsuarioDominioServicio, onFiltrarUsuarioDominio,
            onFiltrarUsuarioServicio, onGetListaUsuariosSinFiltros, onServiciosPorProveedor, cantidadListaUsuarios,
            onRequestRecuperarPin, limpiarFormulario
        } = this.props;

        if (limpiarFormulario && limpiarFormulario === 'editarContraseña') {
            this.props.onLimpiarFormulario('editarContraseñaFormulario');
            this.setState({
                recuperarContraseña: false,
                usuarioSeleccionado: null,
            })
        }

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        let userInfo = sessionStorage.getItem('userInfo');
        if(!userInfo.idUsuario){
            userInfo =JSON.parse(userInfo);
        }
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columns = [{
                title: 'Nombre completo',
                dataIndex: 'nombreCompleto',
                key: 'nombreCompleto', width:'9%'
            },{
                title: 'Correo electrónico',
                dataIndex: 'username',
                key: 'username',
            },{
                title: 'Dominio del usuario',
                dataIndex: 'dominio',
                key: 'dominio',
            },{
                title: 'Nombre de contacto',
                dataIndex: 'proveedor',
                key: 'proveedor', width:'9%'
            },{
                title: 'Conjunto de servicios',
                dataIndex: 'servicios',
                key: 'servicios',
            },{
                title: 'Tipo de usuario',
                dataIndex: 'perfilUsuario',
                key: 'perfilUsuario',
            },{
                title: 'Recuperar PIN',
                dataIndex: 'pin',
                key: 'pin',
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.recuperarPin} onClick={() => handleRecuperarPin(record)}><KeyOutlined /></a>
                ),
            },{
                title: 'Recuperar Contraseña',
                dataIndex: 'password',
                key: 'password',
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.recuperarContraseña} onClick={() => handleRecuperarContraseña(record, true)}><UnlockOutlined /></a>
                ),
            },{
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                align: 'center',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.usuarioActivoInactivo} onChange={(e) => handleDesactivar(record, e)}/>
                ),
            },{
                title: 'Editar',
                key: 'editar',
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarUsuario} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },
        ];
        const onEditar = (key) => {
            console.log(key);
            onModalEditarUsuario(key, this.state.page);
            onServiciosPorProveedor(key.idProveedor)
        }
        const handleRecuperarPin = (key) => {
            console.log(key);
            confirm({
                title: '¿Estás seguro que deseas recuperar el PIN del usuario "'+ key.nombreCompleto +'"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onRequestRecuperarPin(key);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }

        const handleRecuperarContraseña = (key, recuperarContraseña) => {
            this.setState({
                recuperarContraseña,
                usuarioSeleccionado: key,
            })
        }

        const handleDesactivar = (key, e) => {
            console.log(key, e);
            const page = this.state.page
            console.log('key.idUsuario, userInfo');
            console.log(key.idUsuario, userInfo[0]);
            if(key.idUsuario != userInfo[0].idUsuario){
                confirm({
                    title: '¿Estás seguro que deseas cambiar el estado del usuario "'+ key.nombreCompleto +'"?',
                    content: '',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                        onRequestCambiarEstadoUsuario(key.idUsuario, page);
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            }
            else{
                message.warning('No es posible cambiar el estado de tu mismo usuario');
            }
            
        }
        const onNuevoUsuario = () => {
            onModalNuevoUsuario(this.state.page);
        }
        const onFinish = formulario => {
            console.log('Success:', formulario);
            if(formulario.idsServicios && formulario.dominio){
                if(formulario.idsServicios.length > 0) onFiltrarUsuarioDominioServicio(1, formulario);
            }
            else if(formulario.dominio){
                onFiltrarUsuarioDominioServicio(1, formulario);
            }
            else if(formulario.idsServicios){
                if(formulario.idsServicios.length > 0) onFiltrarUsuarioServicio(1,formulario); else onGetListaUsuariosSinFiltros(1);
            }
            else{
                onGetListaUsuariosSinFiltros(1);
            }
            this.setState({
                page: 1, formulario: formulario
            })
        }      

        
        const handleTableChange = (pagination)=>{
            this.setState({ page: pagination.current })
            if(this.state.formulario.idsServicios){
                if(this.state.formulario.idsServicios.length > 0 && this.state.formulario.dominio){
                    onFiltrarUsuarioDominioServicio(pagination.current, this.state.formulario);
                } else if(this.state.formulario.idsServicios.length > 0){
                    onFiltrarUsuarioServicio(pagination.current, this.state.formulario);
                }
            }
            else if(this.state.formulario.dominio){
                onFiltrarUsuarioDominio(pagination.current, this.state.formulario);
            }
            else{
                onGetListaUsuariosSinFiltros(pagination.current);
            }
        };

        return (
            <div className="imgCorp">
                <Modal 
                    visible={this.state.recuperarContraseña}
                    title='Recuperar Contraseña'
                    onCancel={() => handleRecuperarContraseña(null, false)}
                    width='70%'
                    footer={false}
                >
                    <EditarPerfil usuario={this.state.usuarioSeleccionado}/>
                </Modal>
                <Row justify='space-around'>
                    <Col span={24}>
                        <Spin spinning={fetchingEstadoUsuario}>
                            <Card className="cardCatalogos" title={<div className="headerLista">Control de Usuarios</div>}>
                                <Row justify='space-between' gutter={[8,8]}>
                                    <Col span={24}>
                                        <Form name="formulario"  onFinish={onFinish}  >
                                            <Row justify='space-around' gutter={[8,8]}>
                                                <Col span={9}>
                                                    <Form.Item
                                                    name="dominio"
                                                    >
                                                        <Input className='inputFiltro' placeholder="Ingresa dominio a buscar"/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={13}>
                                                    <Form.Item
                                                        name="idsServicios"
                                                    >
                                                        <Select 
                                                            allowClear
                                                            style={{ width: '100%' }}
                                                            placeholder="Selecciona un servicio a filtrar"
                                                            loading={this.props.fetchingListaServiciosActivosF}
                                                            showSearch={true}
                                                            mode="multiple"
                                                            
                                                        >
                                                            {this.props.listaServiciosActivosF.map(option => 
                                                            <Option key={option.idServicio + ' - ' + option.nombre}>
                                                                <Tooltip placement="topLeft" title={option.idServicio + ' - ' + option.nombre}>
                                                                    {option.idServicio + ' - ' + option.nombre}
                                                                </Tooltip>
                                                            </Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={1}>
                                                    <Button shape="circle" disabled={!permisosUsuario.buscarUsuario} icon={<SearchOutlined />} className="btnFiltrar" htmlType="submit"></Button>
                                                </Col>

                                                <Col span={1}>
                                                    <Tooltip title='Nuevo Usuario' placement="topRight">
                                                        <Button className="iconTer" shape="circle" onClick={onNuevoUsuario} disabled={!permisosUsuario.crearUsuario} icon={<PlusOutlined />}/>
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                    {/* <Col span={4}>
                                        <Button onClick={onNuevoUsuario} disabled={!permisosUsuario.crearUsuario} className='nuevoUsuario'>Nuevo Usuario</Button>
                                    </Col> */}

                                    <Col span={24}>
                                        <Table size='small'
                                        className='tablaUsuarios'
                                        locale={{ emptyText: 'Sin datos' }}
                                        rowKey="nombreCompleto" 
                                        columns={columns}
                                        dataSource={permisosUsuario.buscarUsuario ? listaUsuarios : []}
                                        loading={fetchingListaUsuarios}
                                        onChange={handleTableChange}
                                        scroll={{ x: 2200 }}
                                        pagination={{ total: cantidadListaUsuarios, simple: true, current: this.state.page }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Spin>
                        
                    </Col>
                </Row>
                <ModalEditarUsuario/>
                <ModalNuevoUsuario/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaUsuarios: state.ConfiguracionYSeguridadReducer.fetchingListaUsuarios,
        listaUsuarios: state.ConfiguracionYSeguridadReducer.listaUsuarios,
        fetchingEstadoUsuario: state.ConfiguracionYSeguridadReducer.fetchingEstadoUsuario,
        cantidadListaUsuarios: state.ConfiguracionYSeguridadReducer.cantidadListaUsuarios,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetListaUsuariosSinFiltros: (page) => {
	    	dispatch({ type: "LISTA_USUARIOS_SIN_FILTRO_REQUEST", page:page });
        },
        onRequestCambiarEstadoUsuario: (idUsuario, page) => {
            dispatch({ type: "CAMBIAR_ESTADO_USUARIO_REQUEST", idUsuario: idUsuario, page: page });
        },
        onModalNuevoUsuario: (page) => {
            dispatch({ type: 'MODAL_NUEVO_USUARIO', modalNuevoUsuario: true, page: page })
        },
        onModalEditarUsuario: (usuario, page) => {
            dispatch({ type: 'MODAL_EDITAR_USUARIO', modalEditarUsuario: true, usuario: usuario, page: page })
        },
        onListaDominiosActivos: () => {
            dispatch({ type: 'LISTA_DOMINIOS_ACTIVOS_REQUEST'})
        },
        onListaRoles: () => {
            dispatch({ type: 'LISTA_ROLES_REQUEST'})
        },
        onFiltrarUsuarioDominioServicio: (page, formulario) => {
            dispatch({ type: "FILTRO_DOMINIO_SERVICIO_REQUEST" , formulario: formulario, page: page });
        },
        onFiltrarUsuarioServicio: (page, formulario) => {
            dispatch({ type: "FILTRO_SERVICIO_REQUEST" , formulario: formulario, page: page });
        },
        onGetProveedores: () => {
            dispatch({ type: 'GET_PROVEEDORES_REQUEST' })
        },
        onServiciosPorProveedor: (idProveedor) => {
            dispatch({ type: 'SERVICIO_POR_PROVEEDOR_REQUEST', idProveedor: idProveedor})
        },
        onRequestRecuperarPin: (usuario) => {
            dispatch({ type: 'RECUPERAR_PIN_REQUEST', usuario: usuario })
        },
        onGetServiciosActivos: () => {
            dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
        },
        onLimpiarFormulario: (limpiarFormulario) => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario})
        }
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(UsuariosContainer);