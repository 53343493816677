import React, { Component } from 'react';
import { Modal, Col, Table } from 'antd';
import { } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

class ModalSolicitudesServicio extends Component {
    state = {
        page: 1,
    }
    render() { 
        const {
            showModalTrazabilidad, listaTrazabilidadCantidad, fetchingListaTrazabilidad,
            solicitudDeServicioSeleccionado, listaTrazabilidad, onGetTrazabilidadFolio,
            onCerrarModal
        } =  this.props;

        const handleCancel = () => {
            onCerrarModal();
        };

        const handleTableChange = (pagination) => {
            onGetTrazabilidadFolio(solicitudDeServicioSeleccionado.folio, pagination.current);
       }

        const columns = [{
                title: 'Fecha de Estado',
                dataIndex: 'fechaEstado',
                key: 'fechaEstado',
                align: 'center'
            },{
                title: 'Estado',
                dataIndex: 'estado',
                key: 'estado',
                align: 'center'
            },{
                title: 'Descripcion',
                dataIndex: 'descripcion',
                key: 'descripcion',
                align: 'center'
            },{
                title: 'Fecha de Actualización',
                dataIndex: 'fechaActualizacion',
                key: 'fechaActualizacion',
                align: 'center'
            },{
                title: 'Usuario que registra',
                dataIndex: 'username',
                key: 'username',
                align: 'center'
            }
        ];

        const scroll = {"x": 700 | true, "y": 1000};


        return ( 
            <div>
                <Modal
                    visible={showModalTrazabilidad}
                    title={`Trazabilidad`}
                    onCancel={handleCancel}
                    footer={false}
                    width={'85%'}
                >
                    <Col span={24}>
                        <Table
                            size="small"
                            rowKey="idTrazabilidadFolio"
                            columns={columns}
                            dataSource={listaTrazabilidad}
                            loading={fetchingListaTrazabilidad}
                            onChange={handleTableChange}
                            locale={{ emptyText: 'Sin datos' }}
                            scroll={scroll}
                            pagination={{ total: listaTrazabilidadCantidad, simple: true }}
                        />
                    </Col>
                </Modal>
            </div>
        );
    }
}
 
const mapStateToProps = state => {
    return {
        showModalTrazabilidad: state.MonitorIntegralReducer.showModalTrazabilidad, 
        page: state.MonitorIntegralReducer.page,
        busqueda: state.MonitorIntegralReducer.busqueda, 
        listaTrazabilidad: state.SolicitudDeServiciosReducer.listaTrazabilidad,
        fetchingListaTrazabilidad: state.SolicitudDeServiciosReducer.fetchingListaTrazabilidad,
        listaTrazabilidadCantidad: state.SolicitudDeServiciosReducer.listaTrazabilidadCantidad,
        solicitudDeServicioSeleccionado: state.MonitorIntegralReducer.solicitudDeServicioSeleccionado
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onCerrarModal: () => {
            dispatch({ type: 'SHOW_MODAL_TRAZABILIDAD', showModalTrazabilidad: false})
        },
        onGetTrazabilidadFolio: (folio, page) => {
            dispatch({ type: "OBTENER_TRAZABILIDAD_FOLIO_REQUEST", folio, page});
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps) (ModalSolicitudesServicio);