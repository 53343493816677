import React from 'react';
import {connect} from 'react-redux';
import {Form, Row, Col, Button, Modal, Spin, Input, Divider, Checkbox, Select, DatePicker, Switch} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { TextArea } = Input;
const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalEditarClasificacion extends React.Component {
    formRef = React.createRef();
    render() {
        const {showModalEditarRegistroReporte, fetchingEditarRegistroReporte, page, onActualizarRegistro,
            onShowModalRegistroReporte, onEditarRegistroReporte, registroReporteSeleccionado, personal,
            fetchingListaPartidasByServicios, listaPartidasByServicios,
            fetchingListaClasificacionesActivos, listaClasificacionesActivos, onListaPartidas, listaTemasActivos,
            fetchingListaCentroDeCostosByGerencia, fetchingListaServiciosByCentroCosto, listaServiciosByCentroCosto,
            fetchingListaTemasActivos, onCetroCostosByGerencia, onServiciosByCentroCosto, listaCentroDeCostosByGerencia,

        } = this.props;
        
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        let userInfo = sessionStorage.getItem('userInfo');
        if(userInfo[0]) {
            userInfo = JSON.parse(userInfo);
        }

        const serviciosByIdUsuario = []
        if(userInfo[0].serviciosByIdUsuario && listaServiciosByCentroCosto){
            for(let i in listaServiciosByCentroCosto){
                for(let x in userInfo[0].serviciosByIdUsuario){
                    if(listaServiciosByCentroCosto[i].idServicio === userInfo[0].serviciosByIdUsuario[x].idServicio){
                        serviciosByIdUsuario.push(userInfo[0].serviciosByIdUsuario[x]);
                    }
                }
            }
        }

        const onFinish = (formulario) => {
            onEditarRegistroReporte(formulario, page, personal, registroReporteSeleccionado);        
        }

        const handleCancel = () => {
            onShowModalRegistroReporte(page, personal);
            this.formRef.current.resetFields();
        }

        const selectAll = (value) => {
            const registroReporteLocal = registroReporteSeleccionado;
            if(value.target.checked){
                onActualizarRegistro(null, page, personal)
                for(let i in registroReporteLocal.checkboxData){
                    registroReporteLocal.checkboxData[i][1] = true;
                }
                for(let i in registroReporteLocal.listaFields){
                    registroReporteLocal[registroReporteLocal.listaFields[i].key] = true;
                    registroReporteLocal.fechaEvaluacionC = true;
                    registroReporteLocal.listaFields[i].mostrar = true;
                }
                registroReporteLocal.checkAll = true;
                onActualizarRegistro(registroReporteLocal, page, personal)
            }
            else {
                onActualizarRegistro(null, page, personal)
                for(let i in registroReporteLocal.checkboxData){
                    registroReporteLocal.checkboxData[i][1] = false;
                }
                for(let i in registroReporteLocal.listaFields){
                    registroReporteLocal[registroReporteLocal.listaFields[i].key] = false;
                    registroReporteLocal.fechaEvaluacionC = false;
                    registroReporteLocal.listaFields[i].mostrar = false;
                }
                registroReporteLocal.checkAll = false;
                this.formRef.current.setFieldsValue({'field': undefined});
                onActualizarRegistro(registroReporteLocal, page, personal)
            }
        }
        const changeColumna = (value, columna) => {
            const registroReporteLocal = registroReporteSeleccionado;
            onActualizarRegistro(null, page, personal);
            let checkAll = true;
            registroReporteLocal[columna] = value.target.checked;
            for(let i in registroReporteLocal.listaFields){
                if(registroReporteLocal.listaFields[i].key === columna){
                    if(registroReporteLocal.listaFields[i].campo === registroReporteLocal.field){
                        this.formRef.current.setFieldsValue({'field': undefined});
                    }
                    registroReporteLocal.listaFields[i].mostrar = value.target.checked;
                }
            }
            for(let i in registroReporteLocal.checkboxData){
                if(registroReporteLocal.checkboxData[i][0] === columna){
                    registroReporteLocal.checkboxData[i][1] = value.target.checked;
                }
            }
            for(let i in registroReporteLocal.checkboxData){
                if(!registroReporteLocal.checkboxData[i][1]){
                    checkAll = false;
                }
            }
            registroReporteLocal.checkAll = checkAll;
            onActualizarRegistro(registroReporteLocal, page, personal);
        }

        const gerenciaSeleccionada = (value) => {
            this.formRef.current.setFieldsValue({'centroCosto': undefined, 'servicio': undefined});
            if(this.formRef.current.getFieldValue('partida')){
                this.formRef.current.setFieldsValue({'partida': undefined});
            }
            this.props.limpiarListaCentroCostoByGerencia();
                this.props.limpiarListaServiciosByCentroCosto();
                this.props.limpiarListaPartidasByServicios();
            if(value[0]){
                onCetroCostosByGerencia(value);
            }
        }
    
        const centroCostoSeleccionado = (value) => {
            this.formRef.current.setFieldsValue({'servicio': undefined});
            if(this.formRef.current.getFieldValue('partida')){
                this.formRef.current.setFieldsValue({'partida': undefined});
            }
            this.props.limpiarListaServiciosByCentroCosto();
                this.props.limpiarListaPartidasByServicios();
            if(value[0]){
                onServiciosByCentroCosto(value);
            }
        }
    
        const onServicioSelect = (value) => {
            if(this.formRef.current.getFieldValue('partida')){
                this.formRef.current.setFieldsValue({'partida': undefined});
            }
            this.props.limpiarListaPartidasByServicios();
            if(value[0]){
                onListaPartidas(value);
            }
        }
        return (
            <div>
                <Modal
                    visible={showModalEditarRegistroReporte}
                    title='Editar Registro Predeterminado'
                    onCancel={handleCancel}
                    footer={false}
                    width='70%'
                >
                    {registroReporteSeleccionado ? (
                    <Spin spinning={fetchingEditarRegistroReporte}>
                        <Form {...layout}
                            name='formulario'
                            onFinish={onFinish}
                            ref={this.formRef}
                            initialValues={registroReporteSeleccionado}
                        >
                            <Row type='flex' justify='space-around'>
                                <Col span={11}>
                                    <Form.Item
                                        label='Título del documento'
                                        name='titulo'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                        <Input maxLength={200} placeholder='Ingresa el título del documento' style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label='Nombre'
                                        name='nombre'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                        <Input maxLength={100} placeholder='Ingresa el nombre' style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                
                                <Col span={11}>
                                    <Form.Item
                                        label='Descripción'
                                        name='descripcion'
                                        >
                                        <TextArea autoSize maxLength={450} placeholder='Ingresa la descripción' style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Divider orientation='left'>Columnas</Divider>
                                <Row style={{ width: '100%', textAlign: 'left', marginBottom: '10px' }}>
                                    <Col span={24}>
                                        <Checkbox onChange={selectAll} checked={registroReporteSeleccionado.checkAll}><strong>Todas</strong></Checkbox>
                                    </Col>
                                </Row>
                                <Row style={{ width: '100%', textAlign: 'left' }}>
                                    {registroReporteSeleccionado.checkboxData.map( item => 
                                    <Col key={item[0]} span={6}>
                                        <Form.Item
                                            name={item[0]}
                                        >
                                            <Checkbox checked={item[1]} onChange={e => changeColumna(e, item[0])}>{item[2]}</Checkbox>
                                        </Form.Item>
                                    </Col>
                                    )}
                                </Row>
                                <Divider orientation='left'>Filtros</Divider>
                                <Col span={11}>
                                    <Form.Item
                                        label='Gerencia'
                                        name='gerencia'
                                        rules={[{ required: true, message: 'Favor de seleccionar una gerencia', }]}
                                    >
                                         <Select
                                            allowClear
                                            style={{ width: '100%' }} placeholder="Selecciona las gerencias"
                                            loading={fetchingListaClasificacionesActivos}
                                            onChange={gerenciaSeleccionada}
                                            mode='multiple'
                                        >
                                            {listaClasificacionesActivos.map(item => <Option key={item.idClasificacionGerencia}>{item.nombreGerencia}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {registroReporteSeleccionado.partidaC ? (
                                <Col span={11}>
                                    <Form.Item
                                        label='Centro de costos'
                                        name='centroCosto'
                                    >
                                        <Select
                                            allowClear
                                            style={{ width: '100%' }} placeholder="Selecciona un centro de costos"
                                            mode='multiple'
                                            loading={fetchingListaCentroDeCostosByGerencia}
                                            onChange={centroCostoSeleccionado}
                                        >
                                            {listaCentroDeCostosByGerencia.map(item => <Option key={item.idCentroCosto}>{item.centroCosto}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                ):false}
                                {registroReporteSeleccionado.partidaC ? (
                                <Col span={11}>
                                    <Form.Item
                                        label='Servicio'
                                        name='servicio'
                                    >
                                        {permisosUsuario.especialEvaluaciónDeLaGestión ? (
                                        <Select
                                            allowClear
                                            style={{ width: '100%' }} placeholder="Selecciona un servicio"
                                            mode='multiple'
                                            onChange={onServicioSelect}
                                            loading={fetchingListaServiciosByCentroCosto}
                                        >
                                            {listaServiciosByCentroCosto.map(servicio => <Option key={servicio.idServicio}>{servicio.nombre}</Option>)})
                                        </Select>
                                        ):(
                                        <Select
                                            allowClear
                                            style={{ width: '100%' }} placeholder="Selecciona un servicio"
                                            onChange={onServicioSelect}
                                            mode='multiple'
                                            loading={fetchingListaServiciosByCentroCosto}
                                        >
                                            {serviciosByIdUsuario.map(item => <Option key={item.idServicio}>{item.nombre}</Option>)})
                                        </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                ):false}
                                {registroReporteSeleccionado.partidaC ? (
                                <Col span={11}>
                                    <Form.Item
                                        label="Partida"
                                        name="partida"
                                    >
                                        <Select
                                            allowClear
                                            style={{ width: '100%' }} placeholder="Selecciona una partida"
                                            loading={fetchingListaPartidasByServicios}
                                            mode='multiple'
                                        >
                                            {listaPartidasByServicios.map(item => <Option key={item.idPartida}>{item.nombre}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                ) :false}
                                {registroReporteSeleccionado.temaC ? (
                                <Col span={11}>
                                    <Form.Item
                                        label="Temas"
                                        name="temas"
                                    >
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Ingresa los temas a buscar"
                                            loading={fetchingListaTemasActivos}
                                            mode='multiple'
                                        >
                                            {listaTemasActivos.map(item => <Option key={item.idTema}>{item.tema}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                ) :false}
                                {registroReporteSeleccionado.proveedorC ? (
                                <Col span={11}>
                                    <Form.Item
                                        label="Proveedor"
                                        name="proveedor"
                                    >
                                        <Input style={{ width: '100%' }} placeholder="Ingresa el proveedor" />
                                    </Form.Item>
                                </Col>
                                ): false}
                                {registroReporteSeleccionado.fechaEvaluacionC ? (
                                <Col span={11}>
                                    <Form.Item
                                        label="Mes evaluado"
                                        name="fechaEvaluacion"
                                    >
                                        <DatePicker picker="month" format='MM-YYYY' style={{ width: '100%' }} 
                                            placeholder={"Ingresa el mes evaluado"}
                                        />
                                    </Form.Item>
                                </Col>
                                ) : false}
                                <Divider orientation='left'>Ordenamiento</Divider>
                                <Col span={11}>
                                    <Form.Item
                                        label="Campo"
                                        name="field"
                                        rules={[{ required: true, message: 'Favor de seleccionar un servicio', }]}
                                    >
                                        <Select
                                            allowClear
                                            style={{ width: '100%' }} placeholder="Selecciona un campo"
                                        >
                                            {registroReporteSeleccionado.listaFields.map(item =>
                                                <Option disabled={!item.mostrar} key={item.campo}>{item.nombre}</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label="Orden"
                                        name="order"
                                        rules={[{ required: true, message: 'Favor de seleccionar un servicio', }]}
                                    >
                                        <Select
                                            allowClear
                                            style={{ width: '100%' }} placeholder="Selecciona un orden"
                                            loading={fetchingListaPartidasByServicios}
                                        >
                                            <Option key='ASC'>Ascendente</Option>
                                            <Option key='DESC'>Descendente</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label="Agrupado"
                                        name="agrupado"
                                        valuePropName="checked"
                                        rules={[{ required: true, message: 'Favor de seleccionar un servicio', }]}
                                    >
                                        <Switch
                                            checkedChildren={'Agrupado'}
                                            unCheckedChildren={'Sin Agrupar'}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={11} className='marginBoton'>
                                    <br/>
                                    <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Editar Registro Predeterminado</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                    ):false}
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalEditarRegistroReporte: state.EvaluacionDeLaGestionReducer.showModalEditarRegistroReporte,
        fetchingEditarRegistroReporte: state.EvaluacionDeLaGestionReducer.fetchingEditarRegistroReporte,
        page: state.EvaluacionDeLaGestionReducer.page,
        personal: state.EvaluacionDeLaGestionReducer.personal,
        registroReporteSeleccionado: state.EvaluacionDeLaGestionReducer.registroReporteSeleccionado,
        fetchingListaPartidasByServicios: state.InformacionOperativaReducer.fetchingListaPartidasByServicios,
        listaPartidasByServicios: state.InformacionOperativaReducer.listaPartidasByServicios,
        fetchingListaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.fetchingListaClasificacionesActivos,
        listaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.listaClasificacionesActivos,
        fetchingListaCentroDeCostosByGerencia: state.CatalogosGeneralesReducer.fetchingListaCentroDeCostosByGerencia,
        listaCentroDeCostosByGerencia: state.CatalogosGeneralesReducer.listaCentroDeCostosByGerencia,
        fetchingListaServiciosByCentroCosto: state.CatalogosGeneralesReducer.fetchingListaServiciosByCentroCosto,
        listaServiciosByCentroCosto: state.CatalogosGeneralesReducer.listaServiciosByCentroCosto,
        listaTemasActivos: state.EvaluacionDeLaGestionReducer.listaTemasActivos,
        fetchingListaTemasActivos: state.EvaluacionDeLaGestionReducer.fetchingListaTemasActivos,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalRegistroReporte: (page, personal) => {
            dispatch({ type: "MODAL_EDITAR_REGISTRO_REPORTE", showModalEditarRegistroReporte: false, page, personal});
        },
        onEditarRegistroReporte: (formulario, page, personal, registroReporteSeleccionado) => {
            dispatch({ type: 'EDITAR_REGISTRO_REPORTE_REQUEST', formulario, page, personal, registroReporteSeleccionado });
        },
        onActualizarRegistro: (registroReporteSeleccionado, page, personal) => {
            dispatch({ type: "MODAL_EDITAR_REGISTRO_REPORTE", showModalEditarRegistroReporte: true, registroReporteSeleccionado, page, personal });
        },
        onCetroCostosByGerencia: (idsGerencias) => {
            dispatch({ type: 'LISTA_CENTRO_COSTOS_BY_GERENCIA_REQUEST', idsGerencias });
        },
        onServiciosByCentroCosto: (idsCentrosCostos) => {
            dispatch({ type: 'LISTA_SERVICIOS_BY_CENTRO_COSTO_REQUEST', idsCentrosCostos });
        },
        onListaPartidas: (idsServicios) => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_SERVICIOS_REQUEST', idsServicios })
        },
        onLimpiarListaGerencias: () => {
            dispatch({ type: 'LISTA_CLASIFICACION_ACTIVOS_SUCCESS', listaClasificacionesActivos: [] })
        },
        limpiarListaCentroCostoByGerencia: () => {
            dispatch({ type: 'LISTA_CENTRO_COSTOS_BY_GERENCIA_SUCCESS', listaCentroDeCostosByGerencia: [] })
        },
        limpiarListaServiciosByCentroCosto: () => {
            dispatch({ type: 'LISTA_SERVICIOS_BY_CENTRO_COSTO_SUCCESS', listaServiciosByCentroCosto: [] })
        },
        limpiarListaPartidasByServicios: () => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_SERVICIOS_SUCCESS', listaPartidasByServicios: [] })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalEditarClasificacion);