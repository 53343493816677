import React, { Component } from 'react';
import {Form,Button,Modal,Col,Spin,Divider,DatePicker,InputNumber, Input, Select, Alert, Typography, message} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Option } = Select;
const { Text, Paragraph } = Typography;
//MODAL MODIFICAR DENTRO DE "NUEVO FOLIO" POSTERIOR A LA SELECCIÓN DE PIH

class ModalModificarRegistro extends Component {
    state = {  tipoMonedaSeleccionada: null, cantidad: [] }

     formRef = React.createRef();
     render() { 
          const {showModalModificarRegistro, fetchingNuevoFolio, registroProductoSeleccionado,
            listaSeleccionados, vistaModalNuevoFolio, formularioNuevoFolio, fetchingObtenerUbicacionDestino,
            listaUbicacionesDestino, 
          } = this.props;
    
          const onFinish = formulario => {
               console.log('Formulario:' , formulario);
               const listaSeleccionadosLocal = listaSeleccionados;

               function numberWithCommas(x) {
                    let parts = x.toString().split(".");
                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return parts.join(".");
               }

               for (let i = 0; i < listaSeleccionadosLocal.length; i++) {
                    if(listaSeleccionadosLocal[i].idProducto === registroProductoSeleccionado.idProducto){
                         listaSeleccionadosLocal[i] = Object.assign(listaSeleccionadosLocal[i], formulario);
                         
                         if(listaSeleccionadosLocal[i].tipoMonedaEditar){
                              let tipoMonedaEdt = listaSeleccionadosLocal[i].tipoMonedaEditar.split('(')[1]
                              listaSeleccionadosLocal[i].tipoMoneda = listaSeleccionadosLocal[i].tipoMonedaEditar.split(' - ')[1];
                              listaSeleccionadosLocal[i].precioTabla = numberWithCommas(Number(formulario.precio).toFixed(2)) + ' ' + tipoMonedaEdt.split(')')[0];
                         } else {
                              listaSeleccionadosLocal[i].precioTabla = numberWithCommas(Number(formulario.precio).toFixed(2));
                         }
                         if(listaSeleccionadosLocal[i].subFamiliaByIdSubFamilia.familiaByIdFamilia.tipoFamilia === 'Activos'){
                              listaSeleccionadosLocal[i].serieNum = '';
                              for(let x=1; x<=listaSeleccionadosLocal[i].cantidad; x++){
                                   if(x===listaSeleccionadosLocal[i].cantidad){
                                        listaSeleccionadosLocal[i].serieNum += listaSeleccionadosLocal[i]['serieNum-' + x];
                                   }else{
                                        if(listaSeleccionadosLocal[i]['serieNum-' + x]){
                                             listaSeleccionadosLocal[i].serieNum += listaSeleccionadosLocal[i]['serieNum-' + x] + ', ';
                                        }
                                   }
                              }
                         }

                         if(formulario.tipoCambio){
                              let costoTotal = Number(formulario.precio) * Number(formulario.tipoCambio) * formulario.cantidad;
                              listaSeleccionadosLocal[i].costoTotal = numberWithCommas(costoTotal.toFixed(2))  + ' MXN';
                              listaSeleccionadosLocal[i].tipoCambio = Number(formulario.tipoCambio).toFixed(2) + ' MXN';
                         } else {
                              let costoTotal = Number(formulario.precio) * formulario.cantidad;
                              listaSeleccionadosLocal[i].costoTotal = numberWithCommas(costoTotal.toFixed(2)) + ' MXN';
                              listaSeleccionadosLocal[i].tipoCambio = 'N/A';
                         }
                    } 
               }
               console.log('lista seleccionados fuera for EditRegistro', listaSeleccionadosLocal);
               this.props.onGuardarProducto(listaSeleccionadosLocal);
               this.props.onShowModalModificar();
               this.props.onActualizarTabla(listaSeleccionadosLocal);
               this.props.onOpenModal();
               this.setState({ tipoMonedaSeleccionada: null });
          };

          const handleCancel = () => {
               this.props.onShowModalModificar();
               if(this.formRef.current){
                    this.formRef.current.resetFields();
               }
               this.setState({ tipoMonedaSeleccionada: null, cantidad: [] });
               this.props.onOpenModal();
          };

          const  disabledDate = (current) => {
               // Can not select days before today and today
               return current && current < moment();
          };

          const onCantidad = (value) => {
               this.formRef.current.setFieldsValue({'cantidad': parseInt(value)});
               const cantidadLocal = []
               for(let i=0; i<parseInt(value); i++){
                   cantidadLocal.push(i + 1);
               }
               this.setState({
                   cantidad: cantidadLocal
               })
          }
          const onNumeroSerie = (id) => {
               if(this.formRef.current){
                    const formulario = this.formRef.current.getFieldsValue();
                    const campo = 'serieNum-' + id;
                    const valor = this.formRef.current.getFieldValue(campo);
                    if(valor){
                         const listaSerieNum = [];
          
                         for (var key in formulario) {
                              if (Object.prototype.hasOwnProperty.call(formulario, key)) {
                                   let idCampo = key.split('-');
                                   if (idCampo.length === 2) {
                                   let id = idCampo[1];
                                   let campo = idCampo[0];
                                   let valor = formulario[key];
               
                                   let serieNum = {[campo]: valor, 'id': parseInt(id)};
                                   listaSerieNum.push(serieNum);
                                   }
                              }
                         }
                         for(let i in listaSerieNum){
                              if(listaSerieNum[i].id !== id){
                                   if(valor === listaSerieNum[i].serieNum){
                                        message.warning('No es posible asignar el número de serie en más de un producto.');
                                        this.formRef.current.resetFields([campo]);
                                   }
                              }
                         }
                    }
               }
          }
 
          return (
               <Modal
                    visible={showModalModificarRegistro}
                    title={'Modificar información de: ' + registroProductoSeleccionado?.nombre}
                    onCancel={handleCancel}
                    footer={false}
               >
                    {registroProductoSeleccionado ? (
                    <Spin spinning={fetchingNuevoFolio}>
                         <Form {...layout}
                              name='formulario'
                              initialValues={registroProductoSeleccionado}
                              onFinish={onFinish}
                              ref={this.formRef}
                         >
                              <Paragraph><Text strong> Producto: {registroProductoSeleccionado.nombre} </Text></Paragraph>
                              <Paragraph><Text type='secondary'> Descripción: {registroProductoSeleccionado.descripcion} </Text></Paragraph>

                              <Divider />
                            
                              <Form.Item
                                   label="Cantidad"
                                   name="cantidad"
                                   rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                              >
                                   <InputNumber min={1} style={{width: '100%'}} onChange={onCantidad} placeholder='Ingresa la cantidad'/>
                              </Form.Item>
                              {registroProductoSeleccionado.subFamiliaByIdSubFamilia.familiaByIdFamilia.tipoFamilia === 'Activos' ? ( <>
                                   {this.state.cantidad.map(i => 
                                   <Form.Item
                                        key={i}
                                        label={"Número de serie para producto Nº" + i}
                                        name={"serieNum-" + i}
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                   >
                                        <Input maxLength={30} onBlur={() => onNumeroSerie(i)} placeholder='Ingresa el número de serie' />
                                   </Form.Item>
                                   )}
                                   <Divider orientation="left" />
                              </> ): false}

                              <Form.Item
                                   label="Tipo de moneda"
                                   name="tipoMonedaEditar"
                                   rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                              >
                                   <Select allowClear
                                   style={{ width: '100%' }}
                                   placeholder="Selecciona el tipo de moneda"
                                   loading={this.props.fetchingListaTipoMonedasActivas}
                                   onChange={(a, b) => { this.setState({ tipoMonedaSeleccionada: b.children }); }}
                                   >
                                   {this.props.listaTipoMonedasActivas.map(option => <Option key={option.idTipoMoneda + ' - ' + option.nombre + ' (' + option.nomenclatura + ')'}>{option.idTipoMoneda + ' - ' + option.nombre + ' (' + option.nomenclatura + ')'}</Option>)}
                                   </Select>
                              </Form.Item>
                              
                              <Form.Item
                                   label="Costo unitario"
                                   name="precio"
                                   rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                              >
                                   <InputNumber min={1} placeholder='Precio' formatter={value =>  `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                              </Form.Item>

                              { this.state.tipoMonedaSeleccionada ? (<>
                                   {this.state.tipoMonedaSeleccionada.split('(')[1] !== 'MXN)' ? (
                                   <Form.Item
                                        label="Tipo de cambio"
                                        name="tipoCambio"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                   >
                                        <InputNumber min={1} placeholder='Tipo de cambio' formatter={value =>  `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                                   </Form.Item>
                                   ) : false}
                              </>): (<>
                                   {registroProductoSeleccionado.tipoMoneda.split('(')[1] !== 'MXN)' ? (
                                   <Form.Item
                                        label="Tipo de Cambio"
                                        name="tipoCambio"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                   >
                                        <InputNumber min={1} placeholder='Tipo de cambio' formatter={value =>  `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                                   </Form.Item>
                                   ) : false}
                              </>) }
                           
                            
                              {registroProductoSeleccionado.caracteristicaLote ? ( <>
                                   <Divider orientation="left" />
                                   <Form.Item
                                   label="Lote"
                                   name="lote"
                                   rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                   >
                                   <InputNumber min={1} placeholder='Lote' />
                                   </Form.Item>
                                   
                                   <Form.Item
                                   label="Fecha de Caducidad"
                                   name="fechaCaducidad"
                                   rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                   >
                                   <DatePicker
                                        style={{ width: '100%' }}
                                        format='DD-MM-YYYY'
                                        placeholder={["Seleccione fecha de caducidad"]}
                                        disabledDate={disabledDate}
                                        showToday={false}
                                   />
                                   </Form.Item>
                              </> ): false}

                            {registroProductoSeleccionado.productoByIdProducto ? ( <>
                                {registroProductoSeleccionado.productoByIdProducto.caracteristicaLote ? ( <>
                                    <Divider orientation="left" />
                                    <Form.Item
                                        label="Lote"
                                        name="lote"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                          <InputNumber placeholder='Lote' />
                                    </Form.Item>
                                    
                                    <Form.Item
                                        label="Fecha de caducidad"
                                        name="fechaCaducidad"
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            format='DD-MM-YYYY'
                                            placeholder={["Seleccione fecha de caducidad"]}
                                            disabledDate={disabledDate}
                                            showToday={false}
                                        />
                                    </Form.Item>
                                </> ): false }
                            </> ): false }

                              { this.props.listaUbicaciones ? (
                                   <Form.Item
                                        label="Ubicación"
                                        name='ubicacion'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                   >
                                        <Select allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Seleccione la ubicación"
                                             loading={this.props.fetchingObtenerUbicacion}
                                        >
                                             {this.props.listaUbicaciones.map(option => <Option key={option.idUbicacionProducto}>{option.area + "-" + option.piso + "-" + option.pasillo + "-" + option.rack + "-" + option.nivelRack + "-" + option.posicionRack}</Option>)}
                                        </Select>
                                   </Form.Item>
                              ): (<>
                                   <Alert message="Para habilitar la sección de ubicaciones es necesario haber seleccionado un almacen" type="info" />
                                   
                                   <Form.Item
                                        label="Ubicación"
                                        name='ubicacion'
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                   >
                                        <Select allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Seleccione la ubicación"
                                             loading={this.props.fetchingObtenerUbicacion}
                                             disabled={true}
                                        />
                                   </Form.Item>
                              </>)}  
                             
                              <Col span={24}>
                                   <Button htmlType="submit" className='nuevoUsuario'>Guardar Cambios</Button>
                              </Col>
                        </Form>
                    </Spin>
               ): false}
               </Modal>
          );
     }
}

const mapStateToProps = state => {
	return {
          showModalModificarRegistro: state.AlmacenesReducer.showModalModificarRegistro,
          fetchingNuevoFolio: state.AlmacenesReducer.fetchingNuevoFolio,
          registroProductoSeleccionado: state.AlmacenesReducer.registroProductoSeleccionado,
          listaSeleccionados: state.AlmacenesReducer.listaSeleccionados,
          vistaModalNuevoFolio: state.AlmacenesReducer.vistaModalNuevoFolio, 
          formularioNuevoFolio: state.AlmacenesReducer.formularioNuevoFolio,
          listaUbicacionesDestino: state.AlmacenesReducer.listaUbicacionesDestino,
          fetchingObtenerUbicacionDestino: state.AlmacenesReducer.fetchingObtenerUbicacionDestino,

          listaTipoMonedasActivas: state.CatalogosGeneralesReducer.listaTipoMonedasActivas,
          fetchingListaTipoMonedasActivas: state.CatalogosGeneralesReducer.fetchingListaTipoMonedasActivas,

          listaUbicaciones: state.AlmacenesReducer.listaUbicaciones,
          fetchingObtenerUbicacion: state.AlmacenesReducer.fetchingObtenerUbicacion,
     };
};

const mapDispatchToProps = dispatch => {
	return {
          onShowModalModificar: () => {
               dispatch({ type: "SHOW_MODAL_MODIFICAR_REGISTRO_VERIFICACION", showModalModificarRegistro: false})
          },
          onGuardarProducto: (listaSeleccionados) => {
               dispatch({ type: "CAMBIAR_VISTA_MODAL_NUEVOFOLIO", vistaModalNuevoFolio: 1, listaSeleccionados: listaSeleccionados})  
          },
          onGetUbicacionDestino: (page, idAlmacen) => {
               dispatch({ type: "UBICACION_DESTINO_POR_ALMACEN_REQUEST", page: page, idAlmacen: idAlmacen})
          },
          onActualizarTabla: (listaSeleccionados) => {
               dispatch({ type: 'ACTUALIZAR_TABLA_MOVIMIENTOS', listaSeleccionados, hasSelected: true})
          },
          onOpenModal: () => {
               dispatch({ type: 'SHOW_MODAL_NUEVOFOLIO_VERIFICACION_CAL', showModalNuevoFolioVC: true })
          },
	};
};
 
export default connect(mapStateToProps, mapDispatchToProps)(ModalModificarRegistro);