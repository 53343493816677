import React, { Component } from 'react';
import { Form, Button, Row, Col, Spin, message, Modal, InputNumber, Divider, Input} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const confirm = Modal.confirm;

class VerificarEdicion extends Component {
    formRef = React.createRef();
    


    render() {
        const { formularioFallaCalidad, onCambiarVista,listaFallasDeCalidadEdicion, CategoriaFallaDeCalidadSeleccionado,listaValoresIniciales
        } = this.props;

        const onFinish = formulario => {
            console.log('formulario');
            console.log(formulario);
            let listaPorcentajes = [];
        
            for (var key in formulario) {
                if (Object.prototype.hasOwnProperty.call(formulario, key)) {
                    let idCampo = key.split('-');
                    if (idCampo.length === 2) {
                        let idFallaCalidad = idCampo[1];
                        let campo = idCampo[0];
                        let valor = formulario[key];
    
                        let porcentajeFormulario = {'idFallaCalidad': parseInt(idFallaCalidad),'fallaCalidad':"",'descripcion':"",[campo]: valor, };
                        listaPorcentajes.push(porcentajeFormulario);
                    }
                }
            }
            console.log(listaPorcentajes);

            const findFallaCalidad = (fallaCalidadItem) => {
                const elemento = listaFallasDeCalidadEdicion.find(item => item.idFallaCalidad == fallaCalidadItem.idFallaCalidad);
                return fallaCalidadItem.valorPorcentaje !== elemento.valorPorcentaje ?  elemento : false;
            }

            const listaFiltradaEditados = listaPorcentajes.filter(fallaCalidadItem => {
                const filtro = findFallaCalidad(fallaCalidadItem)
                const {fallaCalidad,descripcion,fechaInicioVigencia,fechaFinVigencia,fechaAlta,fechaActualizacion,activo, rutaArchivo} = filtro
                fallaCalidadItem.fallaCalidad = fallaCalidad;
                fallaCalidadItem.descripcion = descripcion;
                fallaCalidadItem.fechaInicioVigencia = fechaInicioVigencia;
                fallaCalidadItem.fechaFinVigencia = fechaFinVigencia;
                fallaCalidadItem.fechaAlta = fechaAlta;
                fallaCalidadItem.fechaActualizacion = fechaActualizacion;
                fallaCalidadItem.activo = activo;
                fallaCalidadItem.rutaArchivo = rutaArchivo;
                return filtro;
            });

            const porcentajeEditado = listaPorcentajes.find(item => item.idFallaCalidad == CategoriaFallaDeCalidadSeleccionado.idFallaCalidad);
            console.log(listaFiltradaEditados)

            if (porcentajeEditado.valorPorcentaje === formularioFallaCalidad.valorPorcentaje) {
                confirm({
                    title: 'Estos son los porcentajes de la falla de calidad que fueron modificados. ¿Estás seguro de modificarlos?',
                    content:
                    <div>
                        {listaFiltradaEditados.map(porcentaje => (
                            <Row justify='space-around' key={porcentaje.idFallaCalidad}>
                                <Divider>{`Porcentaje de Falla de Calidad ${porcentaje.idFallaCalidad}`}</Divider>
                                <Col span={14}>
                                    <p><strong>Falla de Calidad: </strong>{porcentaje.fallaCalidad}</p>
                                </Col>
                                <Col span={8}>
                                    <p><strong>Porcentaje: </strong>{porcentaje.valorPorcentaje}</p>
                                </Col>
                            </Row>
                        ))}
                    </div>,
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    width: '50%',
                    onOk() {
                        console.log("CAMBIAR VISTA")
                        onCambiarVista(4, formularioFallaCalidad, listaFiltradaEditados);
                    },
                    onCancel() {
                        onCambiarVista(1,null);
                    },
                });
            } else {
                message.warning(`El porcentaje de la falla de calidad ${porcentajeEditado.idFallaCalidad}, debe ser el mismo que ingresó al editar ('${formularioFallaCalidad.valorPorcentaje}').`);
                this.formRef.current.resetFields([`valorPorcentaje-${porcentajeEditado.idFallaCalidad}`]);
            }
        };

        

        return (
            <Form {...layout}
            name="formulario"
            onFinish={onFinish}
            
            ref={this.formRef}
            initialValues={listaValoresIniciales}
            >
                <div>
                    <h2>Favor de ingresar nuevamente los porcentajes de las fallas de calidad</h2>
                    {listaFallasDeCalidadEdicion.map(fallaCalidad =>
                     <Row justify='space-around' key={fallaCalidad.idFallaCalidad}>
                        <Divider>{`Porcentaje de la Falla de Calidad ${fallaCalidad.idFallaCalidad}`}</Divider>
                        <Col span={11}>
                            <Form.Item
                                label="Valor porcentaje"
                                name={`valorPorcentaje-${fallaCalidad.idFallaCalidad}`}
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                <InputNumber min={1} max={100} placeholder='Valor porcentaje'
                                    title="Número debe ser entero o fracción a dos decímales" pattern='^[0-9]*(\.[0-9]{0,2})?$' >    
                                </InputNumber>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="Categoría de falla de calidad"
                                name={`fallaCalidad-${fallaCalidad.idFallaCalidad}-${fallaCalidad.idFallaCalidad}`}
                            >
                                <Input disabled={true} defaultValue={fallaCalidad.fallaCalidad} ></Input>
                            </Form.Item>
                        </Col>
                     </Row>   
                    )}
                    <Col span={24} className='marginBoton'>
                        <br/>
                        <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Guardar Cambios</Button>
                    </Col>
                </div>
            </Form>
        );
    }
}

const mapStateToProps = state => {
	return {
        formularioFallaCalidad: state.MecanismosDePagosReducer.formularioFallaCalidad,
        fetchingListaFallasDeCalidadEdicion: state.MecanismosDePagosReducer.fetchingListaFallasDeCalidadEdicion,
        listaFallasDeCalidadEdicion: state.MecanismosDePagosReducer.listaFallasDeCalidadEdicion,
        CategoriaFallaDeCalidadSeleccionado: state.MecanismosDePagosReducer.CategoriaFallaDeCalidadSeleccionado,
        listaValoresIniciales: state.MecanismosDePagosReducer.listaValoresIniciales,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onCambiarVista: (vistaModalFallaCalidad, formularioFallaCalidad, listaFiltradaEditados) => {
            dispatch({ type: 'CAMBIAR_VISTA_FALLA_CALIDAD', vistaModalFallaCalidad: vistaModalFallaCalidad, formularioFallaCalidad:formularioFallaCalidad, listaFiltradaEditados:listaFiltradaEditados})
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(VerificarEdicion);