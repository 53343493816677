import React from 'react';
import { connect } from 'react-redux';
import { Table, Row, Col, Button, Input, Switch, Modal, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevaClasificacion from './ModalNuevaClasificacion';
import ModalEditarClasificacion from './ModalEditarClasificacion';

const { Search } = Input;
const { confirm } = Modal;

class ClasificacionGerenciasContainer extends React.Component {
    state = {
        page: 1,
        busqueda: null,
    }

    componentDidMount () {
        this.props.onListaClasificacion(1, null);
    }

    render () {
        const {fetchingListaClasificaciones, listaClasificaciones, listaClasificacionesCantidad, 
            onEstadoClasificacion, onListaClasificacion, onModalNuevaClasificacion, onModalEditarClasificacion
        } = this.props;

            
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columns = [{
            title: 'Nombre de la Gerencia',
            dataIndex: 'nombreGerencia',
            key: 'nombreGerencia',
            width: '40%',
        },{
            title: 'Descripción',
            dataIndex: 'descripcion',
            key: 'descripcion',
            width: '40%',
        },{
            title: 'Activo',
            dataIndex: 'activo',
            key: 'activo',
            width: '10%',
                align: 'center',
            render: (text, record) => (
                <Switch checked={text} disabled={!permisosUsuario.gerenciaActivoinactivo || record.categoriaUnidadMedida==='Tiempo'} onChange = {() => handleDesactivar(record)} />
            ),
        }, {
            title: 'Editar',
            key: 'editar',
            width: '10%',
            align: 'center',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.editarGerencia}   onClick={() => onEditar(record)}  ><EditOutlined /></a>
            ),
        }];

        const handleDesactivar = (key) => {
            let page = this.state.page;
            let busqueda = this.state.busqueda;
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado de la gerencia "' + key.nombreGerencia + '"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoClasificacion(key.idClasificacionGerencia, page, busqueda);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }

        const onBusqueda = (value) => {
            console.log(`value`, value);
            onListaClasificacion(this.state.page, value)
            this.setState({
                busqueda: value,
            })
        };
        
        const onNuevaAsignacion = () => {
            onModalNuevaClasificacion(this.state.page, this.state.busqueda);
        }

        const onEditar = (clasificacionSeleccionada) => {
            onModalEditarClasificacion(clasificacionSeleccionada, this.state.page, this.state.busqueda);
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            onListaClasificacion(pagination.current, this.state.busqueda);
        }

        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search className='buscarModulos' placeholder="Ingresa la gerencia a buscar" onSearch={value => onBusqueda(value)} style={{ width: '100%' }}/>
                    </Col>
                    {/* <Col span={8}>
                        <Button onClick={onNuevaAsignacion} disabled={!permisosUsuario.crearGerencia} className='nuevoUsuario' style={{ width: '100%' }}>Nueva Gerencia</Button>
                    </Col> */}

                    <Col span={1}>
                        <Tooltip title='Nueva Gerencia' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevaAsignacion} disabled={!permisosUsuario.crearGerencia} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    
                    <Col span={24}>
                        <Table
                            size='small'
                            rowKey='idClasificacionGerencia'
                            columns={columns}
                            dataSource={listaClasificaciones}
                            loading={fetchingListaClasificaciones}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaClasificacionesCantidad, simple: true, current: this.state.page }}
                        /> 
                    </Col>
                </Row>
                <ModalNuevaClasificacion/>
                <ModalEditarClasificacion/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaClasificaciones: state.EvaluacionDeLaGestionReducer.fetchingListaClasificaciones,
        listaClasificaciones: state.EvaluacionDeLaGestionReducer.listaClasificaciones,
        listaClasificacionesCantidad: state.EvaluacionDeLaGestionReducer.listaClasificacionesCantidad,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onListaClasificacion: (page, busqueda) => {
            dispatch({ type: "LISTA_CLASIFICACION_REQUEST", page, busqueda});
        },
        onEstadoClasificacion: (idClasificacionGerencia, page, busqueda) => {
            dispatch({ type: "ESTADO_CLASIFICACION_REQUEST", idClasificacionGerencia, page, busqueda});
        },
        onModalNuevaClasificacion: (page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_CREAR_CLASIFICACION", showModalCrearClasificacion: true, page, busqueda});
        },
        onModalEditarClasificacion: (clasificacionSeleccionada, page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_CLASIFICACION", showModalEditarClasificacion: true, clasificacionSeleccionada, page, busqueda});
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClasificacionGerenciasContainer);