import React from 'react';
import { Row, Col, Modal, Button, Form, Tooltip, Table, Switch, Select, DatePicker, Result, Input, message, Spin, Card, Tag, Alert } from 'antd';
import { connect } from "react-redux";
import { FileExcelOutlined, LockOutlined, DeliveredProcedureOutlined, CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import moment from 'moment';
import { loginCall } from "../../../services/LoginServices";


const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;
const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 }};
class DetalleCalculo extends React.Component {
     componentDidMount(){
          this.props.onGetGruposServicios();
          this.props.onGetListaTiposFallasActivas();
          this.props.onGetAreasFuncionales();
          this.props.onGetServiciosActivos();
     }
     formRef = React.createRef();
     state = {
          page: 1,
          idTipoFallaState: '', idAreaFuncionalState: '', idGrupoSerState: '', idServicioState: '', fechaCierre1State: '', fechaCierre2State: '', idTipoFallaEOI: '', idTipoFallaReit: '', tipoFallaSelected: null, visebleModalConfirm: false, visibleIdentificar: false
     }
     render() {
          const { 
               onCloseModal, showModalDetalleCalculoDeductivas, listaDetalleCalculoDeductivas, fetchingListaDetalleCalculoDeductivas, listaDetalleCalculoDeductivasCantidad, fetchingListaGruposServicios, listaGruposServicios, onGetDetelle, idMesCalendario, fechaProyeccion, proyeccion, fetchingListaTiposFallasActivas, listaTiposFallasActivas, listaAreasFuncionalesActivas, fetchingListaAreasFuncionalesActivas, listaServiciosActivosF, fetchingListaServiciosActivosF, listaServiciosByGrupoServicio, onGetServiciosByGrupoServicio, onGetDetalleExcel, fetchingDetalleDeductivaExcel, onProcesarDeductiva, fetchingProcesarDeductiva
          } = this.props;

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
             permisosUsuario = JSON.parse(permisosUsuario);
          }

          let userInfo = sessionStorage.getItem('userInfo');
          if(!userInfo.idUsuario){
               userInfo = JSON.parse(userInfo);
          }

          const onFinish = formulario => {
               const userName = userInfo[0].username;
               const values = {
                   'userName': userName,
                   'password': formulario.password,  
               }
               loginCall(values).then(response => {
                    if(response.status === 200){
                         onProcesarDeductiva(fechaProyeccion, idMesCalendario, proyeccion);
                         this.setState({ visibleIdentificar: false, visebleModalConfirm: false })
                    }
                    else{
                         let errorMensaje = '';
                         if(response.error_message === 'Bad credentials'){
                              errorMensaje = 'Contraseña incorrecta. Por favor intente de nuevo';
                         }
                         else{
                              errorMensaje = response.error_message;
                         }
                         message.error(errorMensaje);
                         return;
                    }
                    this.setState({
                         password: '',
                    });
               }).catch(error => {
                   console.log(error);
                   message.error('Hubo un error, favor de intentar después');
                   return;
               })
          };

          const handleCancel = () => {
               onCloseModal();
               this.formRef.current.resetFields();

               this.setState({ page: 1, idTipoFallaState: '', idAreaFuncionalState: '', idGrupoSerState: '', idServicioState: '', fechaCierre1State: '', fechaCierre2State: '', idTipoFallaEOI: '', idTipoFallaReit: '', tipoFallaSelected: null });
          };

          const handleTableChange = (pagination) => {
               this.setState({ page: pagination.current });
          };

          const procesarDeductiva = () => { this.setState({ visebleModalConfirm:true }); };

          const columns = [
               { title: 'Ticket', dataIndex: 'ticket', key: 'ticket', align: 'center',  width: '2%' },
               { title: 'Tipo ticket', dataIndex: 'tipoTicket', key: 'tipoTicket', align: 'center', width: "3%"  },
               { title: <Tooltip title='Persona que reportó la falla '>Origen</Tooltip>, dataIndex: 'origen', key: 'origen',  align: 'center', width: "5%"  },
               { title: <Tooltip title='Persona que capturó la Solicitud de Servicio'>Personal CCO</Tooltip>, dataIndex: 'personalCCO', key: 'personalCCO', align: 'center', width: "5%" },
               { title: <Tooltip title='Nombre del servicio al que corresponde la falla'>Servicio</Tooltip>, dataIndex: 'nombreServicio', key: 'nombreServicio', align: 'center', width: "8%" },
               { title: <Tooltip title='Clave del área en la que ocurrió la falla'>Clave área</Tooltip>, dataIndex: 'claveArea', key: 'claveArea', align: 'center', width: "2%" },
               { title: <Tooltip title='Nombre del área donde ocurrió la falla '>Nombre área</Tooltip>, dataIndex: 'areaFuncional', key: 'areaFuncional',  align: 'center', width: "8%" },
               { title: 'Código área', key: 'codigoArea', dataIndex: 'codigoArea', align: 'center', width:'2%' },
               // { title: 'Tipo de ticket', dataIndex: 'tipoDeductiva', key: 'tipoDeductiva', align: 'center', width: "4%" },
               { title: <Tooltip title='Tipo de falla, según lo capturado por CCO'>Tipo falla</Tooltip>, dataIndex: 'tipoFalla', key: 'tipoFalla', align: 'center', width: "6%" },
               { title: <Tooltip title='Descripción de la falla capturada por el CCO'>Descripción falla</Tooltip>, dataIndex: 'descripcionSolicitud', key: 'descripcionSolicitud', align: 'center', width: "18%" },
               { title: <Tooltip title='Fecha y hora de creación de la Solicitud de Servicio'>Fecha creación</Tooltip>, dataIndex: 'fechaAltaTabla', key: 'fechaAltaTabla', align: 'center', width: "3%"  },
               { title: <Tooltip title='Fecha y hora de cierre de la Solicitud de Servicio'>Fecha cierre</Tooltip>, dataIndex: 'fechaCierreTabla', key: 'fechaCierreTabla', align: 'center', width: "3%"  },
               { title: <Tooltip title='Nombre de la categoría a la que corresponde la ubicación de la falla'>Categoría área</Tooltip>, dataIndex: 'numeroFallaCategoriaTabla', key: 'numeroFallaCategoriaTabla', align: 'center', width: "2%" },
               { title: 'Clave estándar', dataIndex: 'referenciaEstandarTabla', key: 'referenciaEstandarTabla', align: 'center', width: "2%" },
               { title: 'Descripción estándar', dataIndex: 'descripcionEstandarTabla', key: 'descripcionEstandarTabla', align: 'center', width: "13%" },
               { title: 'Categoría calidad', dataIndex: 'fallaCalidadTabla', key: 'fallaCalidadTabla', align: 'center', width: "3%" },
               { title: 'Categoría disponibilidad', dataIndex: 'fallaDisponibilidadTabla', key: 'fallaDisponibilidadTabla', align: 'center', width: "10%" },
               { title: <Tooltip title='Valor del Pago Mensual de Inversión'>PMI</Tooltip>, dataIndex: 'pmiTabla', key: 'pmiTabla', align: 'right', width: "3%" },
               { title: <Tooltip title='Valor del Pago Mensual de Referencia'>PMR</Tooltip>, dataIndex: 'pmrTabla', key: 'pmrTabla', align: 'right', width: "3%" },
               { title: <Tooltip title='Valor de los días el mes en que se da el servicio para el mes i '>dm</Tooltip>, dataIndex: 'dmi', key: 'dmi', align: 'center', width: "2%" },
               { title: <Tooltip title='Valor del peso del de área funcional'>psa</Tooltip>, dataIndex: 'psaTabla', key: 'psaTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor para el factor de seguridad'>fsa</Tooltip>, dataIndex: 'fsaTabla', key: 'fsaTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor para el inpc de la fecha de inicio de operaciones'>INPC0</Tooltip>, dataIndex: 'inpc0', key: 'inpc0', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor del INPC para el mes i-1'>INPCi-1</Tooltip>, dataIndex: 'inpcCalculo', key: 'inpcCalculo', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor del número de turnos existentes para el área funcional'>ut</Tooltip>, dataIndex: 'utTabla', key: 'utTabla', align: 'center', width: "2%" },
               { title: <Tooltip title='Valor del número de turnos en los que ocurre la falla'>utf</Tooltip>, dataIndex: 'utfTabla', key: 'utfTabla', align: 'center', width: "2%" },
               { title: <Tooltip title='Valor del porcentaje de dedución por fallas de disponibilidad'>pdfd</Tooltip>, dataIndex: 'pdfdTabla', key: 'pdfdTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor del ponderador de servicios'>ps</Tooltip>, dataIndex: 'psTabla', key: 'psTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor del ponderador de categoría de falla de calidad'>pcfc</Tooltip>, dataIndex: 'pcfcTabla', key: 'pcfcTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Número de meses consecutivos en los que ocurren Errores u Omisiones'>pr</Tooltip>, dataIndex: 'prTabla', key: 'prTabla', align: 'right', width: "2%" },
               { title: 'Deductiva', dataIndex: 'deductivaTabla', key: 'deductivaTabla', align: 'right', width: "3.5%" },
               { title: 'Deductiva parcial', dataIndex: 'deductivaParcialTexto', key: 'deductivaParcialTexto', align: 'center', width: "2%" },
               { title: 'Conciliada', dataIndex: 'conciliada', key: 'conciliada', align: 'center', width: "2%",
               render: (text) => (
                   <Switch checked={text} disabled={true}/>
               )},
               { title: <Tooltip title='Valor para el ponderador de servicio por área'>pss_x_a</Tooltip>, dataIndex: 'ponderacionServAreaTabla', key: 'ponderacionServAreaTabla', align: 'center', width: "2%" },
               { title: 'Fecha cierre mes', dataIndex: 'fechaCierreMesTabla', key: 'fechaCierreMesTabla', align: 'center', width: "3%" },
               { title: 'Ticket reiterado', dataIndex: 'ticketReiteradoTabla', key: 'ticketReiteradoTabla', align: 'center', width: "2%" },
               { title: 'Número referencia', dataIndex: 'numReferenciaTabla', key: 'numReferenciaTabla', align: 'center', width: "2%" },
               // { title: 'Tiempo garantía', dataIndex: 'tiempoGarantiaTabla', key: 'tiempoGarantiaTabla', align: 'center', width: "2.5%" },
          ];

          const columnsProv = [
               { title: 'Ticket', dataIndex: 'ticket', key: 'ticket', align: 'center',  width: '2%' },
               { title: 'Tipo ticket', dataIndex: 'tipoTicket', key: 'tipoTicket', align: 'center', width: "3%"  },
               { title: <Tooltip title='Persona que reportó la falla '>Origen</Tooltip>, dataIndex: 'origen', key: 'origen',  align: 'center', width: "5%"  },
               { title: <Tooltip title='Persona que capturó la Solicitud de Servicio'>Personal CCO</Tooltip>, dataIndex: 'personalCCO', key: 'personalCCO', align: 'center', width: "5%" },
               { title: <Tooltip title='Nombre del proveedor que brinda el servicio'>Proveedor</Tooltip>, dataIndex:'nombreProveedor', key:'nombreProveedor', align: 'center', width: "5%" },
               { title: <Tooltip title='Nombre del servicio al que corresponde la falla'>Servicio</Tooltip>, dataIndex: 'nombreServicio', key: 'nombreServicio', align: 'center', width: "8%" },
               { title: <Tooltip title='Clave del área en la que ocurrió la falla'>Clave área</Tooltip>, dataIndex: 'claveArea', key: 'claveArea', align: 'center', width: "2%" },
               { title: <Tooltip title='Nombre del área donde ocurrió la falla '>Nombre área</Tooltip>, dataIndex: 'areaFuncional', key: 'areaFuncional',  align: 'center', width: "8%" },
               { title: 'Código área', key: 'codigoArea', dataIndex: 'codigoArea', align: 'center', width:'2%' },
               // { title: 'Tipo de ticket', dataIndex: 'tipoDeductiva', key: 'tipoDeductiva', align: 'center', width: "4%" },
               { title: <Tooltip title='Tipo de falla, según lo capturado por CCO'>Tipo falla</Tooltip>, dataIndex: 'tipoFalla', key: 'tipoFalla', align: 'center', width: "6%" },
               { title: <Tooltip title='Descripción de la falla capturada por el CCO'>Descripción falla</Tooltip>, dataIndex: 'descripcionSolicitud', key: 'descripcionSolicitud', align: 'center', width: "18%" },
               { title: <Tooltip title='Fecha y hora de creación de la Solicitud de Servicio'>Fecha creación</Tooltip>, dataIndex: 'fechaAltaTabla', key: 'fechaAltaTabla', align: 'center', width: "3%"  },
               { title: <Tooltip title='Fecha y hora de cierre de la Solicitud de Servicio'>Fecha cierre</Tooltip>, dataIndex: 'fechaCierreTabla', key: 'fechaCierreTabla', align: 'center', width: "3%"  },
               { title: <Tooltip title='Nombre de la categoría a la que corresponde la ubicación de la falla'>Categoría área</Tooltip>, dataIndex: 'numeroFallaCategoriaTabla', key: 'numeroFallaCategoriaTabla', align: 'center', width: "2%" },
               { title: 'Clave estándar', dataIndex: 'referenciaEstandarTabla', key: 'referenciaEstandarTabla', align: 'center', width: "2%" },
               { title: 'Descripción estándar', dataIndex: 'descripcionEstandarTabla', key: 'descripcionEstandarTabla', align: 'center', width: "13%" },
               { title: 'Categoría calidad', dataIndex: 'fallaCalidadTabla', key: 'fallaCalidadTabla', align: 'center', width: "3%" },
               { title: 'Categoría disponibilidad', dataIndex: 'fallaDisponibilidadTabla', key: 'fallaDisponibilidadTabla', align: 'center', width: "10%" },
               { title: <Tooltip title='Valor del Pago Mensual de Inversión'>PMI</Tooltip>, dataIndex: 'pmiTabla', key: 'pmiTabla', align: 'right', width: "3%" },
               { title: <Tooltip title='Valor del Pago Mensual de Referencia'>PMR</Tooltip>, dataIndex: 'pmrTabla', key: 'pmrTabla', align: 'right', width: "3%" },
               { title: <Tooltip title='Valor de los días el mes en que se da el servicio para el mes i '>dm</Tooltip>, dataIndex: 'dmi', key: 'dmi', align: 'center', width: "2%" },
               { title: <Tooltip title='Valor del peso del de área funcional'>psa</Tooltip>, dataIndex: 'psaTabla', key: 'psaTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor para el factor de seguridad'>fsa</Tooltip>, dataIndex: 'fsaTabla', key: 'fsaTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor para el inpc de la fecha de inicio de operaciones'>INPC0</Tooltip>, dataIndex: 'inpc0', key: 'inpc0', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor del INPC para el mes i-1'>INPCi-1</Tooltip>, dataIndex: 'inpcCalculo', key: 'inpcCalculo', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor del número de turnos existentes para el área funcional'>ut</Tooltip>, dataIndex: 'utTabla', key: 'utTabla', align: 'center', width: "2%" },
               { title: <Tooltip title='Valor del número de turnos en los que ocurre la falla'>utf</Tooltip>, dataIndex: 'utfTabla', key: 'utfTabla', align: 'center', width: "2%" },
               { title: <Tooltip title='Valor del porcentaje de dedución por fallas de disponibilidad'>pdfd</Tooltip>, dataIndex: 'pdfdTabla', key: 'pdfdTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor del ponderador de servicios'>ps</Tooltip>, dataIndex: 'psTabla', key: 'psTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Valor del ponderador de categoría de falla de calidad'>pcfc</Tooltip>, dataIndex: 'pcfcTabla', key: 'pcfcTabla', align: 'right', width: "2%" },
               { title: <Tooltip title='Número de meses consecutivos en los que ocurren Errores u Omisiones'>pr</Tooltip>, dataIndex: 'prTabla', key: 'prTabla', align: 'right', width: "2%" },
               { title: 'Deductiva', dataIndex: 'deductivaTabla', key: 'deductivaTabla', align: 'right', width: "3.5%" },
               { title: 'Deductiva parcial', dataIndex: 'deductivaParcialTexto', key: 'deductivaParcialTexto', align: 'center', width: "2%" },
               { title: 'Conciliada', dataIndex: 'conciliada', key: 'conciliada', align: 'center', width: "2%",
               render: (text) => (
                   <Switch checked={text} disabled={true}/>
               )},
               { title: <Tooltip title='Valor para el ponderador de servicio por área'>pss_x_a</Tooltip>, dataIndex: 'ponderacionServAreaTabla', key: 'ponderacionServAreaTabla', align: 'center', width: "2%" },
               { title: 'Fecha cierre mes', dataIndex: 'fechaCierreMesTabla', key: 'fechaCierreMesTabla', align: 'center', width: "3%" },
               { title: 'Ticket reiterado', dataIndex: 'ticketReiteradoTabla', key: 'ticketReiteradoTabla', align: 'center', width: "2%" },
               { title: 'Número referencia', dataIndex: 'numReferenciaTabla', key: 'numReferenciaTabla', align: 'center', width: "2%" },
               // { title: 'Tiempo garantía', dataIndex: 'tiempoGarantiaTabla', key: 'tiempoGarantiaTabla', align: 'center', width: "2.5%" },
          ];

          const scroll = {"x": 7000};

          const tipoFallaSelected = (value) => {
               let idTipoFallaEOILocal = '';
               let idTipoFallaReitLocal = '';
               let idTipoFallaLocal = '';
               this.setState({ tipoFallaSelected: value });
               if(value){
                    if(value.indexOf('EOI') > 0){
                         // Tipos de Falla por Errores u Omisiones de la Información
                         idTipoFallaEOILocal = parseInt(value.split(' -')[0]);
                         idTipoFallaReitLocal = '';
                         idTipoFallaLocal = '';
                         this.setState({ idTipoFallaEOI: idTipoFallaEOILocal, idTipoFallaReit: '', idTipoFallaState: '', page: 1  });

                         onGetDetelle(fechaProyeccion, idMesCalendario, idTipoFallaLocal, this.state.idAreaFuncionalState, this.state.idGrupoSerState, this.state.idServicioState, this.state.fechaCierre1State, this.state.fechaCierre2State, idTipoFallaEOILocal, idTipoFallaReitLocal);
                         
                    } else if (value.indexOf('por Reiteración') > 0){
                         // Tipos de Falla por Reiteración
                         idTipoFallaReitLocal = parseInt(value.split(' -')[0]);
                         idTipoFallaEOILocal = '';
                         idTipoFallaLocal = '';
                         this.setState({ idTipoFallaEOI: '', idTipoFallaReit: idTipoFallaReitLocal, idTipoFallaState: '', page: 1  });

                         onGetDetelle(fechaProyeccion, idMesCalendario, idTipoFallaLocal, this.state.idAreaFuncionalState, this.state.idGrupoSerState, this.state.idServicioState, this.state.fechaCierre1State, this.state.fechaCierre2State, idTipoFallaEOILocal, idTipoFallaReitLocal);
                         
                    } else {
                         // Tipos de Falla
                         idTipoFallaLocal = parseInt(value.split(' -')[0]);
                         idTipoFallaReitLocal = '';
                         idTipoFallaEOILocal = '';
                         this.setState({ idTipoFallaState: idTipoFallaLocal, page: 1 });
                         

                         onGetDetelle(fechaProyeccion, idMesCalendario, idTipoFallaLocal, this.state.idAreaFuncionalState, this.state.idGrupoSerState, this.state.idServicioState, this.state.fechaCierre1State, this.state.fechaCierre2State, idTipoFallaEOILocal, idTipoFallaReitLocal);
                         
                    }
               } else {
                    this.setState({ idTipoFallaEOI: '', idTipoFallaReit: '', idTipoFallaState: '', page: 1, tipoFallaSelected: null  });
                    onGetDetelle(fechaProyeccion, idMesCalendario, '', this.state.idAreaFuncionalState, this.state.idGrupoSerState, this.state.idServicioState, this.state.fechaCierre1State, this.state.fechaCierre2State, '', '');
               }
          };

          const areaFuncionalSelected = (value) => {
               if(value){
                    let idAFLocal = parseInt(value.split(' -')[0]);
                    this.setState({ idAreaFuncionalState: idAFLocal, page: 1 });
                    onGetDetelle(fechaProyeccion, idMesCalendario, this.state.idTipoFallaState, idAFLocal, this.state.idGrupoSerState, this.state.idServicioState, this.state.fechaCierre1State, this.state.fechaCierre2State, this.state.idTipoFallaEOI, this.state.idTipoFallaReit);
               } else {
                    this.setState({ idAreaFuncionalState: '', page: 1 });
                    onGetDetelle(fechaProyeccion, idMesCalendario, this.state.idTipoFallaState, '', this.state.idGrupoSerState, this.state.idServicioState, this.state.fechaCierre1State, this.state.fechaCierre2State, this.state.idTipoFallaEOI, this.state.idTipoFallaReit);
               }
          };

          const grupoServicioSelected = (value) => {
               if(value){
                    let idGrupoLocal = parseInt(value.split(' -')[0]);
                    this.setState({ idGrupoSerState: idGrupoLocal, idServicioState: '', page: 1 });
                    onGetServiciosByGrupoServicio(idGrupoLocal);

                    this.formRef.current.resetFields(['servicio']);
                    //this.formRef.current.setFieldsValue({'servicioByGrupo': null, 'servicio': null});
                    onGetDetelle(fechaProyeccion, idMesCalendario, this.state.idTipoFallaState, this.state.idAreaFuncionalState, idGrupoLocal, '', this.state.fechaCierre1State, this.state.fechaCierre2State, this.state.idTipoFallaEOI, this.state.idTipoFallaReit);
               } else {
                    this.setState({ idGrupoSerState: '', idServicioState: '', page: 1 });
                    this.formRef.current.resetFields(['servicio']);
                    //this.formRef.current.setFieldsValue({'servicioByGrupo': null, 'servicio': null});
                    onGetDetelle(fechaProyeccion, idMesCalendario, this.state.idTipoFallaState, this.state.idAreaFuncionalState, '', '', this.state.fechaCierre1State, this.state.fechaCierre2State, this.state.idTipoFallaEOI, this.state.idTipoFallaReit);
               }
          };

          const servicioSelected = (value) => {
               if(value){
                    let idServicioLocal = parseInt(value.split(' -')[0]);
                    this.setState({ idServicioState: idServicioLocal, page: 1 });
                    onGetDetelle(fechaProyeccion, idMesCalendario, this.state.idTipoFallaState, this.state.idAreaFuncionalState, this.state.idGrupoSerState, idServicioLocal, this.state.fechaCierre1State, this.state.fechaCierre2State, this.state.idTipoFallaEOI, this.state.idTipoFallaReit);
               } else {
                    this.setState({ idServicioState: '', page: 1 });
                    onGetDetelle(fechaProyeccion, idMesCalendario, this.state.idTipoFallaState, this.state.idAreaFuncionalState, this.state.idGrupoSerState, '', this.state.fechaCierre1State, this.state.fechaCierre2State, this.state.idTipoFallaEOI, this.state.idTipoFallaReit);
               }

          };

          const fechasSelected = (rangeValue) => {
               if(rangeValue){
                    this.setState({ fechaCierre1State: rangeValue[0].format('YYYY-MM-DD'), fechaCierre2State: rangeValue[1].format('YYYY-MM-DD'), page: 1 });
                    this.setState({ fechaCierre1State: '', fechaCierre2State: '' });
                    onGetDetelle(fechaProyeccion, idMesCalendario, this.state.idTipoFallaState, this.state.idAreaFuncionalState, this.state.idGrupoSerState, this.state.idServicioState, rangeValue[0].format('YYYY-MM-DD'), rangeValue[1].format('YYYY-MM-DD'), this.state.idTipoFallaEOI, this.state.idTipoFallaReit); 
               } else {
                    this.setState({ fechaCierre1State: '', fechaCierre2State: '', page: 1 });
                    onGetDetelle(fechaProyeccion, idMesCalendario, this.state.idTipoFallaState, this.state.idAreaFuncionalState, this.state.idGrupoSerState, this.state.idServicioState, '', '', this.state.idTipoFallaEOI, this.state.idTipoFallaReit); 
               }
          };

          const disabledRange = current => {
               if(fechaProyeccion){
                    let fechaDisabled = fechaProyeccion.split('-')[0] + '-' + fechaProyeccion.split('-')[1] + '-01';
                    return current && current < moment(fechaDisabled, "YYYY-MM-DD");
               }
          };

          const onDescarga = () => {
               if(this.state.tipoFallaSelected) {
                    if(this.state.tipoFallaSelected.indexOf('EOI') > 0){
                         // console.log('Entra a caso descarga TipoFalla EOI');
                         onGetDetalleExcel(fechaProyeccion, idMesCalendario, proyeccion, '', this.state.idAreaFuncionalState, this.state.idGrupoSerState, this.state.idServicioState, this.state.fechaCierre1State, this.state.fechaCierre2State, this.state.idTipoFallaEOI, '');
                    } else if (this.state.tipoFallaSelected.indexOf('por Reiteración') > 0){ 
                         // console.log('Entra a caso descarga TipoFalla Reit');
                         onGetDetalleExcel(fechaProyeccion, idMesCalendario, proyeccion, '', this.state.idAreaFuncionalState, this.state.idGrupoSerState, this.state.idServicioState, this.state.fechaCierre1State, this.state.fechaCierre2State, '', this.state.idTipoFallaReit);
                    } else {
                         // console.log('Entra a caso descarga TipoFalla General');
                         onGetDetalleExcel(fechaProyeccion, idMesCalendario, proyeccion, this.state.idTipoFallaState, this.state.idAreaFuncionalState, this.state.idGrupoSerState, this.state.idServicioState, this.state.fechaCierre1State, this.state.fechaCierre2State, '', '');
                    }
               } else {
                    // console.log('Entra a caso descarga sin TipoFalla')
                    onGetDetalleExcel(fechaProyeccion, idMesCalendario, proyeccion, '', this.state.idAreaFuncionalState, this.state.idGrupoSerState, this.state.idServicioState, this.state.fechaCierre1State, this.state.fechaCierre2State, '', '');
               }
          };

          const onCancelConfirm = () => { this.setState({ visebleModalConfirm:false, visibleIdentificar: false }); };
          const onOkConfirm = () => { this.setState({ visibleIdentificar: true }); };

          return (
               <>
                    { true ? (
                         <Modal visible={showModalDetalleCalculoDeductivas} title={'Detalle para Cálculo de Deductivas'} onCancel={handleCancel}  width={'95%'} footer={false}>
                              <Row justify='space-around'>
                                   { this.props.mesAnterior ? (<Col span={24} style={{paddingBottom:'1em'}}>
                                        { this.props.mesAnterior.estadoMes !== false ? (
                                             <Alert message="Mes anterior no procesado" description='Las deductivas que generen los tickets del mes anterior no se encuentran consideradas.' type="info" showIcon />
                                        ):false }
                                   </Col>): false }

                                   <Col span={24}>
                                        <div className="ant-descriptions-view" >
                                             <Table
                                                  title={ () => (
                                                       <Form name="formulario" ref={this.formRef} >
                                                            <Row justify='end' style={{padding:'1em', paddingRight:'1.5em'}} align='middle'>
                                                                 { this.props.valorCalculoTipoDeductiva.estadoMes ? (
                                                                      <Tag className='tagStatus' icon={<ExclamationCircleFilled />} color="#0e2c82" style={{borderRadius:'1em'}}>
                                                                           Mes Sin Procesar
                                                                      </Tag>
                                                                 ):(
                                                                      <Tag className='tagStatus' icon={<CheckCircleFilled />} color="#ff8c62" style={{borderRadius:'1em'}}>
                                                                           Mes Procesado
                                                                      </Tag>
                                                                 )}
                                                                 <Col span={1} style={{paddingLeft: '1em'}}>
                                                                      <Tooltip title='Exportar a Excel'>
                                                                           <Button loading={this.props.fetchingDetalleDeductivaExcel} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } onClick={onDescarga} disabled={!permisosUsuario.exportarExcelDeductivas ? true : !listaDetalleCalculoDeductivas[0]}/>
                                                                      </Tooltip>
                                                                 </Col>
                                                                 
                                                                 <Col span={1} style={{paddingLeft: '1em'}}>
                                                                      {this.props.mesSeleccionado ? (<>
                                                                           { this.props.valorCalculoTipoDeductiva.estadoMes ? (
                                                                                <Tooltip title='Procesar Deductivas'>
                                                                                     <Button disabled={!permisosUsuario.procesarDeductivas ? true : this.props.ultimoDiaAllPPLs ? true: this.props.cierreMes && this.props.procesable ? true : false } className="iconTer" shape="circle" icon={<DeliveredProcedureOutlined />} onClick={procesarDeductiva} loading={fetchingProcesarDeductiva}/>
                                                                                </Tooltip>
                                                                           ):(
                                                                                <Tooltip title='Procesar Deductivas'>
                                                                                     <Button disabled className="iconTer" shape="circle" icon={<DeliveredProcedureOutlined />} />
                                                                                </Tooltip>
                                                                           ) }
                                                                      </>): false}
                                                                 </Col>
                                                            </Row>
                                                            <Row justify='space-around' gutter={[8,8]} align='middle'>
                                                                 <Col span={4}>
                                                                      <Form.Item name='tipoFalla'>
                                                                           <Select style={{ width: '100%', textAlign: 'left' }} placeholder="Selecciona el tipo de falla" loading={fetchingListaTiposFallasActivas} onChange={tipoFallaSelected} allowClear>
                                                                                <OptGroup label="Tipos de falla">
                                                                                     {listaTiposFallasActivas.map(option => 
                                                                                          <Option key={option.idTipoFalla + ' - ' + option.nombre  + ' (' + option.tipo + ')'}>
                                                                                               <Tooltip placement="topLeft" title={option.idTipoFalla + ' - ' + option.nombre  + ' (' + option.tipo + ')'}>
                                                                                                    {option.idTipoFalla + ' - ' + option.nombre  + ' (' + option.tipo + ')'}
                                                                                               </Tooltip>
                                                                                          </Option>
                                                                                     )}
                                                                                </OptGroup>
                                                                                
                                                                                <OptGroup label="Errores u omisiones de información">
                                                                                     {this.props.listaTiposEOI.map(option => 
                                                                                          <Option key={option.idTipoFallaEOI + ' - ' + option.nombre  + ' (' + option.tipo + ')'}>
                                                                                               <Tooltip placement="topLeft" title={option.idTipoFallaEOI + ' - ' + option.nombre  + ' (' + option.tipo + ')'}>
                                                                                                    {option.idTipoFallaEOI + ' - ' + option.nombre  + ' (' + option.tipo + ')'}
                                                                                               </Tooltip>
                                                                                          </Option>
                                                                                     )}
                                                                                </OptGroup> 

                                                                                <OptGroup label="Reiteración">
                                                                                     {this.props.listaTiposReit.map(option => 
                                                                                          <Option key={option.idTipoFallaReit + ' - ' + option.nombre  + ' (' + option.tipo + ')'}>
                                                                                               <Tooltip placement="topLeft" title={option.idTipoFallaReit + ' - ' + option.nombre  + ' (' + option.tipo + ')'}>
                                                                                                    {option.idTipoFallaReit + ' - ' + option.nombre  + ' (' + option.tipo + ')'}
                                                                                               </Tooltip>
                                                                                          </Option>
                                                                                     )}
                                                                                </OptGroup> 
                                                                                
                                                                           </Select>
                                                                      </Form.Item>
                                                                 </Col>

                                                                 <Col span={5}>
                                                                      <Form.Item name='areaFuncional'>
                                                                           <Select 
                                                                                allowClear showSearch style={{ width: '100%' }} placeholder="Seleccione la área funcional" loading={fetchingListaAreasFuncionalesActivas} onChange={areaFuncionalSelected}
                                                                           >
                                                                                {listaAreasFuncionalesActivas.map(option => 
                                                                                <Option key={option.idAreaFuncional + ' - ' +  option.claveArea + ': ' + option.areaFuncional}>
                                                                                     <Tooltip title={option.idAreaFuncional + ' - ' +  option.claveArea + ': ' + option.areaFuncional}> {option.idAreaFuncional + ' - ' +  option.claveArea + ': ' + option.areaFuncional} </Tooltip>
                                                                                </Option>)}
                                                                           </Select>
                                                                      </Form.Item>
                                                                 </Col>

                                                                 <Col span={5}>
                                                                      <Form.Item name='grupoServicio'>
                                                                           <Select 
                                                                                allowClear  style={{ width: '100%' }} placeholder="Selecciona un grupo de servicio" loading={fetchingListaGruposServicios} onChange={grupoServicioSelected} showSearch
                                                                           >
                                                                                {listaGruposServicios.map(option => <Option key={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}><Tooltip title={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}>{option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}</Tooltip></Option>)}
                                                                           </Select>
                                                                      </Form.Item>
                                                                 </Col>

                                                                 <Col span={5}>
                                                                      <Form.Item name='servicio'>
                                                                           <Select
                                                                                allowClear showSearch style={{ width: '100%' }} placeholder="Selecciona el servicio" loading={fetchingListaServiciosActivosF} onChange={servicioSelected}
                                                                           >
                                                                                { this.state.idGrupoSerState === '' ? (<>
                                                                                     {listaServiciosActivosF.map(option => 
                                                                                     <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                                                          <Tooltip placement="topLeft" title={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                                                               { option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre }
                                                                                          </Tooltip>
                                                                                     </Option>)}
                                                                                </>):(<>
                                                                                     {listaServiciosByGrupoServicio.map(option => 
                                                                                     <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                                                          <Tooltip placement="topLeft" title={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                                                               { option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre }
                                                                                          </Tooltip>
                                                                                     </Option>)}
                                                                                </>)}
                                                                           </Select>
                                                                      </Form.Item>
                                                                 </Col>

                                                                 <Col span={5}>
                                                                      <Tooltip title='Rango para validar fecha de cierre de la Solicitud de Servicio'>
                                                                           <Form.Item name='rangoFechaCierre'>
                                                                           
                                                                                <RangePicker 
                                                                                     format='DD-MM-YYYY'
                                                                                     onChange={fechasSelected}
                                                                                     disabledDate={disabledRange}
                                                                                />
                                                                           
                                                                           </Form.Item>
                                                                      </Tooltip>
                                                                 </Col>
                                                            </Row>
                                                       </Form>

                                                  ) }
                                                  size="small"
                                                  rowKey="idSolicitudServicio"
                                                  columns={ this.props.cierreMes ? columnsProv : columns}
                                                  dataSource={listaDetalleCalculoDeductivas}
                                                  loading={fetchingListaDetalleCalculoDeductivas}
                                                  onChange={handleTableChange}
                                                  locale={{ emptyText: 'Sin datos' }}
                                                  scroll={scroll}
                                                  pagination={{ total: listaDetalleCalculoDeductivasCantidad, simple: true, current: this.state.page }}
                                             />
                                        </div>
                                   </Col>
                                                                                          
                                   {/* <Col span={24}>
                                        <Row justify='end' style={{padding:'1em'}} align='middle'>
                                             <Col span={3} justify='end' style={{padding: '1em'}}>
                                                  { this.props.valorCalculoTipoDeductiva.estadoMes ? (
                                                       <Tag icon={<ExclamationCircleFilled />} color="#0e2c82" style={{borderRadius:'1em'}}>
                                                            Mes Sin Procesar
                                                       </Tag>
                                                  ):(
                                                       <Tag icon={<CheckCircleFilled />} color="#ff8c62" style={{borderRadius:'1em'}}>
                                                            Mes Procesado
                                                       </Tag>
                                                  )}
                                             </Col>
                                             <Col span={1} style={{padding: '1em'}}>
                                                  <Tooltip title='Exportar a Excel'>
                                                       <Button loading={this.props.fetchingDetalleDeductivaExcel} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } onClick={onDescarga} disabled={!listaDetalleCalculoDeductivas[0]}/>
                                                  </Tooltip>
                                             </Col>
                                             
                                             <Col span={1} style={{padding: '1em'}}>
                                                  {this.props.mesSeleccionado ? (<>
                                                       { this.props.valorCalculoTipoDeductiva.estadoMes ? (
                                                            <Tooltip title='Procesar Deductivas'>
                                                                 <Button disabled={ this.props.ultimoDiaAllPPLs ? true: this.props.cierreMes && this.props.procesable ? true : false } className="iconTer" shape="circle" icon={<DeliveredProcedureOutlined />} onClick={procesarDeductiva} loading={fetchingProcesarDeductiva}/>
                                                            </Tooltip>
                                                       ):(
                                                            <Tooltip title='Procesar Deductivas'>
                                                                 <Button disabled className="iconTer" shape="circle" icon={<DeliveredProcedureOutlined />} />
                                                            </Tooltip>
                                                       ) }
                                                  </>): false}
                                             </Col>
                                        </Row>
                                   </Col> */}
                              </Row>   
                         </Modal>
                    ) : false }



                         <Col span={24}>
                              <Modal
                                   visible={this.state.visebleModalConfirm}
                                   footer={false}
                                   onCancel={onCancelConfirm}
                                   width= '35%'
                              >
                                   <Result
                                        status="info"
                                        subTitle= 'Al procesar las deductivas, ya no será posible volver a recalcular, ¿está seguro?'
                                        extra={
                                             <Button className='btnCargaArchivos' onClick={onOkConfirm}>
                                                  Confirmar
                                             </Button>
                                        }
                                   />
                                   { this.state.visibleIdentificar ? (
                                        <Form {...layout} name="formulario" ref={this.formRef} onFinish={onFinish}  >
                                             <Card className="cardCatalogos" title='Identificar Usuario'>
                                                  <Col span={24}>
                                                       <Form.Item
                                                            label="Ingresa tu contraseña"
                                                            name="password"
                                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                                       >
                                                            <Input.Password prefix={<LockOutlined/>} placeholder="Ingresa tu contraseña"/>
                                                       </Form.Item>
                                                  </Col>

                                                  <Col span={24} className='marginBoton'>
                                                       <Button className="nuevoUsuario" style={{width: '100%'}} htmlType="submit">Verificar Contraseña y Procesar</Button>
                                                  </Col>
                                             </Card>
                                        </Form>
                                   ):false }
                              </Modal>
                         </Col>
               </>
          );             
     }
}

const mapStateToProps = state => {
     return {
          showModalDetalleCalculoDeductivas: state.MecanismosDePagosReducer.showModalDetalleCalculoDeductivas,
          listaDetalleCalculoDeductivas: state.MecanismosDePagosReducer.listaDetalleCalculoDeductivas, 
          fetchingListaDetalleCalculoDeductivas: state.MecanismosDePagosReducer.fetchingListaDetalleCalculoDeductivas, 
          listaDetalleCalculoDeductivasCantidad: state.MecanismosDePagosReducer.listaDetalleCalculoDeductivasCantidad,
          fetchingListaGruposServicios: state.CatalogosGeneralesReducer.fetchingListaGruposServicios,
          listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,
          fechaProyeccion: state.MecanismosDePagosReducer.fechaProyeccion, 
          idMesCalendario: state.MecanismosDePagosReducer.idMesCalendario,
          proyeccion: state.MecanismosDePagosReducer.proyeccion,
          fetchingListaTiposFallasActivas: state.SolicitudDeServiciosReducer.fetchingListaTiposFallasActivas,
          listaTiposFallasActivas: state.SolicitudDeServiciosReducer.listaTiposFallasActivas,
          listaAreasFuncionalesActivas: state.AlmacenesReducer.listaAreasFuncionalesActivas,
          fetchingListaAreasFuncionalesActivas: state.AlmacenesReducer.fetchingListaAreasFuncionalesActivas,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
          listaServiciosByGrupoServicio: state.CatalogosGeneralesReducer.listaServiciosByGrupoServicio,
          fetchingDetalleDeductivaExcel: state.MecanismosDePagosReducer.fetchingDetalleDeductivaExcel,
          fetchingProcesarDeductiva: state.MecanismosDePagosReducer.fetchingProcesarDeductiva,
          cierreMes: state.MecanismosDePagosReducer.cierreMes,
          listaTiposEOI: state.SolicitudDeServiciosReducer.listaTiposEOI,
          listaTiposReit: state.SolicitudDeServiciosReducer.listaTiposReit,
          procesable: state.MecanismosDePagosReducer.procesable,
          ultimoDiaAllPPLs: state.MecanismosDePagosReducer.ultimoDiaAllPPLs,
          mesSeleccionado: state.MecanismosDePagosReducer.mesSeleccionado,
          valorCalculoTipoDeductiva: state.MecanismosDePagosReducer.valorCalculoTipoDeductiva,
          mesAnterior: state.MecanismosDePagosReducer.mesAnterior
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onCloseModal: ( ) => {
               dispatch({ type: 'SHOW_MODAL_DETALLE_CALCULO_DEDUCTIVA', showModalDetalleCalculoDeductivas: false, mesAnterior: null });
          },
          onGetGruposServicios: () => {
               dispatch({ type: "LISTA_GRUPOS_SERVICIOS_REQUEST" });
          },
          onGetDetelle: (fechaProyeccion, idMesCalendario, idTipoFalla, idAreaFuncional, idGrupoSer, idServicio, fechaCierre1, fechaCierre2, idTipoFallaEOI, idTipoFallaReit) => {
               dispatch({ type: 'LISTA_DETALLE_CALCULO_DEDUCTIVA_REQUEST', fechaProyeccion, idMesCalendario, idTipoFalla, idAreaFuncional, idGrupoSer, idServicio, fechaCierre1, fechaCierre2, idTipoFallaEOI, idTipoFallaReit });
          },
          onGetListaTiposFallasActivas: () => {
               dispatch({ type: "LISTA_TIPOS_FALLAS_ACTIVAS_REQUEST" })
          },
          onGetAreasFuncionales: () => {
               dispatch({ type: "LISTA_AREA_FUNCIONAL_ACTIVA_REQUEST", });
          },
          onGetServiciosActivos: () => {
               dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
          },
          onGetServiciosByGrupoServicio: (idGrupoSer) => {
               dispatch({ type: 'LISTA_SERVICIOS_BY_GRUPO_SERV_REQUEST', idGrupoSer });
          },
          onGetDetalleExcel: (fechaProyeccion, idMesCalendario, proyeccion, idTipoFalla, idAreaFuncional, idGrupoSer, idServicio, fechaCierre1, fechaCierre2, idTipoFallaEOI, idTipoFallaReit) => {
               dispatch({ type: 'DETALLE_DEDUCTIVA_EXCEL_REQUEST', fechaProyeccion, idMesCalendario, proyeccion, idTipoFalla, idAreaFuncional, idGrupoSer, idServicio, fechaCierre1, fechaCierre2, idTipoFallaEOI, idTipoFallaReit });
          },
          onProcesarDeductiva: (fechaProyeccion, idMesCalendario, proyeccion) => {
               dispatch({ type: 'PROCESAR_DEDUCTIVA_REQUEST', fechaProyeccion, idMesCalendario, proyeccion });
          },
          
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetalleCalculo);