import React, { Component } from 'react';
import { Form, InputNumber, Button, Row, Col, Spin, Modal, Select, Tooltip, Divider } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import { SaveOutlined } from '@ant-design/icons';

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class NuevoTipoCambio extends Component {
     formRef = React.createRef();
     render() {
          const {  limpiarFormulario, } = this.props;

          const onFinish = formulario => { console.log('Success:', formulario); this.props.onNuevo(formulario, this.props.page, this.props.busqueda, this.props.orden);  };

          if (limpiarFormulario && limpiarFormulario === 'crearTipoCambio') { this.formRef.current.resetFields();  this.props.onLimpiarFormulario();  }
               

          

          const handleCancel = () => {  this.props.onCloseModal(); this.formRef.current.resetFields();  }

          return (
               <Modal
               visible={this.props.showModalNuevoTipoCambio}
               title='Nuevo Tipo de Cambio'
               onCancel={handleCancel}
               footer={false}
               >
                    <Spin spinning={this.props.fetchingNuevoTipoCambio}>
                         <Form {...layout}
                              name="formulario"
                              onFinish={onFinish}
                              ref={this.formRef}
                         >
                              <Form.Item
                                   name="idTipoMoneda"
                                   label='Tipo de moneda'
                                   rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                              >
                                   <Select allowClear
                                        mode='tag'
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona tipos de monedas"
                                        loading={this.props.fetchingListaTipoMonedasActivas}
                                   >
                                        {this.props.listaTipoMonedasActivas.map(option => <Option key={option.idTipoMoneda + ' - ' + option.nombre + ' (' + option.nomenclatura + ')'}>{ option.idTipoMoneda + ' - ' + option.nombre + ' (' + option.nomenclatura + ')'}</Option>)}
                                   </Select>
                              </Form.Item>

                              <Form.Item
                                   label="Tipo de cambio"
                                   name="tipoCambio"
                                   rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                              >
                                   <InputNumber prefix={"$"} min={0} style={{width: '100%'}} formatter={value =>  `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                              </Form.Item>

                              <Row justify='end' gutter={[8,8]}>
                                   <Col span={1} style={{paddingRight:'2.8em', paddingTop:'2em'}}>
                                        <Tooltip title='Crear Tipo de Cambio' placement="bottom">
                                             <Button className="iconTer" shape="circle" htmlType="submit" icon={<SaveOutlined />}/>
                                        </Tooltip> 
                                   </Col>
                              </Row>
                         </Form>
                    </Spin>
               </Modal>
          );
     }
}

const mapStateToProps = state => {
	return {
          fetchingNuevoTipoCambio: state.CatalogosGeneralesReducer.fetchingNuevoTipoCambio,
          showModalNuevoTipoCambio: state.CatalogosGeneralesReducer.showModalNuevoTipoCambio,
          page: state.CatalogosGeneralesReducer.page,
          busqueda: state.CatalogosGeneralesReducer.busqueda,
          orden: state.CatalogosGeneralesReducer.orden,
          
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,

          fetchingListaTipoMonedasActivas: state.CatalogosGeneralesReducer.fetchingListaTipoMonedasActivas,
          listaTipoMonedasActivas: state.CatalogosGeneralesReducer.listaTipoMonedasActivas,
	};
};

const mapDispatchToProps = dispatch => {
	return {
          onCloseModal: () => {
               dispatch({ type: 'SHOW_MODAL_NUEVO_TIPO_CAMBIO', showModalNuevoTipoCambio: false})
          },
          onNuevo: ( formulario, page, busqueda, orden ) => {
               dispatch({ type: "NUEVO_TIPO_CAMBIO_REQUEST" , formulario, page, busqueda, orden });
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NuevoTipoCambio);