import React, { Component } from 'react';
import {Row, Col, Table, Button, Input, DatePicker, Select, message, Card, Popover, Spin, Tooltip} from 'antd';
import { FileDoneOutlined, FilePdfOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoFolio from './ModalNuevoFolio';
import ModalVerificacion from './ModalVerificacion';

const { Search } = Input;
const {Option} = Select;
const { RangePicker } = DatePicker;

class VerificacionCalidadContainer extends Component {
    componentDidMount(){
        this.props.onGetFolioVerficiacionCalidad(1, this.state.busqueda);
        this.props.onGetUsuarioRes();
        this.props.onGetTiposMonedas();
    }
    state = {
        page: 1,
        busqueda: {
            param: '',
            order: 'DESC', 
            field: 'fechaAlta',
            fechaInicio: '',
            fechaFinal: '',
            idUsuario: undefined,
        }
    }
    render() {
        const {
            fetchingListaFVerificacionCalidad,
            listaFVerificacionCalidad,
            listaFVerificacionCalidadCantidad,
            showModalCreateFolio,
            onGetFolioVerficiacionCalidad,
            showModalVerificacion,
            onGetDetalleFolio,
            onGetPdfVerificacion,

            fetchingListaUsuariosActivos,
            listaUsuariosActivos,
        } = this.props; 

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        let userInfo = sessionStorage.getItem('userInfo');
        if(!userInfo.idUsuario){
            userInfo =JSON.parse(userInfo);
        }

        const onSearchFolio = (param) => {
           
            const busqueda = this.state.busqueda
            console.log("busqueda",busqueda)
            busqueda.param = param;
            this.setState({
                busqueda
            })
            onGetFolioVerficiacionCalidad(this.state.page, busqueda)
        };

        const onChangeSelectDate = (value) => {
            console.log('value :>> ', value);
            const busqueda = this.state.busqueda
            if(value){
                let fechaInicio = value[0].format('YYYY-MM-DD')
                let fechaFinal = value[1].format('YYYY-MM-DD')
                
                busqueda.fechaInicio = fechaInicio;
                busqueda.fechaFinal = fechaFinal;
                this.setState({
                    busqueda
                })
                onGetFolioVerficiacionCalidad(this.state.page, busqueda)
            }
            else{
                busqueda.fechaInicio = null;
                busqueda.fechaFinal = null;
                onGetFolioVerficiacionCalidad(this.state.page, busqueda)
            }
        };

        const handleTableChange = (pagination, filtro, orden) => {
            this.setState({ page: pagination.current });
            if(orden){
                console.log('orden', orden)
                let campo = 'fechaAlta';
                let order = 'DESC';
        
                if(orden.order === 'ascend'){
                    order = 'ASC';
                }

                if(orden.field === 'folio'){
                    campo = 'idIngresoAlmacenCalidad';
                }
                else if(orden.field === 'fechaAlta'){
                    campo = 'fechaAlta';
                }
                else if(orden.field === 'fechaIngreso'){
                    campo = 'fechaIngreso';
                }
                 
                const busqueda = this.state.busqueda;
                busqueda.order = order;
                busqueda.field = campo;
                onGetFolioVerficiacionCalidad(pagination.current, busqueda);
                this.setState({
                    busqueda: busqueda,
                });                   
            }
            else{
                onGetFolioVerficiacionCalidad(pagination.current, this.state.busqueda);
            }
       }

        const onCreateFolio = () => {
            showModalCreateFolio(this.state.page, this.state.busqueda);
            this.props.onGetProductos(1);
        };

        const showVerificacion = (key) => {
            console.log('key', key)
            onGetDetalleFolio(key.folio, this.state.page)
            showModalVerificacion(key, this.state.page, this.state.busqueda);
              
        }

        const onDescargarPdf = (key) => {
            onGetPdfVerificacion(key.folio, this.state.idUsuario, this.state.page, this.state.busqueda);
        }

        const onChangeUsuario = (value) => {
            console.log(value)
            if(value){
                value = parseInt(value);
            }
            this.setState({
                idUsuario: value
            })
        }

        const columns = [{
            title: 'Folio',
            dataIndex: 'folio',
            key: 'folio',
            align: 'center',
            sorter: true
        },{
            title: 'Servicio',
            dataIndex: 'servicio',
            key: 'servicio',
            align: 'center',
        },{
            title: 'Fecha Ingreso',
            dataIndex: 'fechaIngreso',
            key: 'fechaIngreso',
            align: 'center',
            sorter: true
        },{
            title: 'Fecha Alta',
            dataIndex: 'fechaAlta',
            key: 'fechaAlta',
            align: 'center',
            sorter: true
        },{
            title: 'Descargar PDF',
            key: '',
            align: 'center',
            render: (record) => (
                <Popover placement="top" title='Usuario responsable para descargar PDF'
                    content={
                        <Spin spinning={this.props.fetchingImprimirPDF}>
                            <Row justify='space-around'>
                                <Col span={21}>
                                    <Select 
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el usuario responsable a exportar PDF"
                                        loading={fetchingListaUsuariosActivos}  
                                        onChange={onChangeUsuario}
                                        showSearch
                                    >
                                        {listaUsuariosActivos.map(option => <Option key={option.idUsuario + ' - ' + option.nombre + " " + option.aPaterno + ": " + option.username}>{option.idUsuario + ' - ' + option.nombre + " " + option.aPaterno + ": " + option.username}</Option>)}
                                    </Select>
                                </Col>
                                <Col span={2}>
                                    <Button className="iconSecundary" shape="circle" onClick={ () => onDescargarPdf(record) } icon={<FilePdfOutlined />} disabled={!this.state.idUsuario}/>
                                </Col>
                            </Row>
                        </Spin>
                    } 
                    trigger="click"
                >
                    <a className='editarIcono' disabled={!permisosUsuario.descargarPdfDelFolioDeVerificación}> <FilePdfOutlined /></a>
                </Popover>
            ),          
        }, {
            title: 'Verificación',
            key: '',
            align: 'center',
            render: (record) => (
               <a className='editarIcono' disabled={!permisosUsuario.verificaciónDelFolio} onClick={ () => showVerificacion(record) }> <FileDoneOutlined /></a>
            ),          
        }];

        console.log("listaFVerificacionCalidad",listaFVerificacionCalidad)

        return ( 
            <Card className="cardCatalogos" title={<div className="headerLista">Entrada por Verificación de Calidad</div>}>
                <Row justify='space-between'>
                    <Col span={14}>
                        <Search className='buscarModulos' placeholder='Ingresa folio o servicio a buscar' onSearch={value => onSearchFolio(value)} />
                    </Col>
                    <Col span={8}>
                        <RangePicker 
                            format='DD-MM-YYYY'
                            onChange= {onChangeSelectDate}
                            style={{ width: '100%' }} />
                    </Col>

                    {/* <Col span={3}>
                        <Button onClick={onCreateFolio} disabled={!permisosUsuario.crearFolioDeVerificaciónDeCalidad} className='nuevoUsuario'>Nuevo Folio</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nuevo Folio' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onCreateFolio} disabled={!permisosUsuario.crearFolioDeVerificaciónDeCalidad} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <br />
                            <Table
                                size="small"
                                rowKey="folio"
                                columns={columns}
                                dataSource={listaFVerificacionCalidad}
                                loading={fetchingListaFVerificacionCalidad || this.props.fetchingImprimirPDF}
                                locale={{ emptyText: 'Sin datos' }} 
                                onChange={handleTableChange}
                                pagination={{ total: listaFVerificacionCalidadCantidad, simple: true }}
                            />
                    </Col>
                </Row>
                <ModalNuevoFolio/>
                <ModalVerificacion/>
            </Card>
         );
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaFVerificacionCalidad: state.AlmacenesReducer.fetchingListaFVerificacionCalidad,
        listaFVerificacionCalidad: state.AlmacenesReducer.listaFVerificacionCalidad,
        listaFVerificacionCalidadCantidad: state.AlmacenesReducer.listaFVerificacionCalidadCantidad,

        listaUsuariosActivos: state.AlmacenesReducer.listaUsuariosActivos,
        fetchingListaUsuariosActivos: state.AlmacenesReducer.fetchingListaUsuariosActivos,
        fetchingImprimirPDF: state.AlmacenesReducer.fetchingImprimirPDF,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onGetFolioVerficiacionCalidad: (page, busqueda) => {
            dispatch({ type: 'LISTA_FOLIOS_VERIFICACION_CALIDAD_REQUEST', page, busqueda})
        },
        showModalCreateFolio: (page, busqueda) => {
            dispatch({ type: 'SHOW_MODAL_NUEVOFOLIO_VERIFICACION_CAL', showModalNuevoFolioVC: true, page, busqueda})
        },
        showModalVerificacion: (folioEVDCseleccionado, page, busqueda) => {
            dispatch({ type: 'SHOW_MODAL_VERIFICARFOLIO_CAL', showModalFolioVC: true, folioEVDCseleccionado ,page, busqueda})
        },
        onGetDetalleFolio: (folio, page) => {
            dispatch({ type: 'DETALLE_FOLIO_VERIFICACION_CALIDAD_REQUEST', folio, page})
        },
        onGetPdfVerificacion: (folio, idUsuario, page, busqueda) => {
            dispatch({ type: 'IMPRIMIR_PDF_VERIFICACION_CALIDAD_REQUEST', folio, idUsuario, page, busqueda});
        },
        onGetUsuarioRes: () => {
            dispatch({ type: 'LISTA_USUARIOS_ACTIVOS_REQUEST', });
        },
        onGetUsuarioResLimpiar: () => {
            dispatch({ type: 'LISTA_USUARIOS_ACTIVOS_SUCCESS',  listaUsuariosActivos: []});
        },
        onGetTiposMonedas: () => {
            dispatch({ type: 'LISTA_TIPOS_MONEDAS_ACTIVOS_REQUEST' });
        },
        onGetProductos: (page) => {
            dispatch({ type: "PRODUCTOS_BUSQUEDA_REQUEST", page  });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(VerificacionCalidadContainer)
