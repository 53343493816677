import React from 'react';
import { Row, Col, Table, Input, Button, Card, Form, DatePicker, Tooltip } from 'antd';
import { SearchOutlined, FileExcelFilled } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import '../../../stylesheets/RecursosHumanos/AltaPersonal.css';
import moment from 'moment';
import imagenNoResult from '../../../assets/no-result-icon.png';

const { RangePicker } = DatePicker;
class ControlDeAsistenciaContainer extends React.Component {
    componentDidMount() {
        this.props.onGetControlAsistencias(1, {}, {'field': 'idAsistencia', 'order': 'DESC'});
	}
    
    state={
        page: 1,
        busqueda: {
            param: null,
            servicio: null,
            fechaInicio: null,
            fechaFin: null,
        },
        filtros: {
            field: 'idAsistencia',
            order: 'DESC',
        },
    }

    addDefaultSrc(ev){
        ev.target.src = imagenNoResult;
    }

    render() {
        const { fetchingListaControlAsistenciaBusqueda, listaControlAsistenciaBusqueda, 
            onGetControlAsistencias, listaControlAsistenciaBusquedaCantidad,
            listaControlAsistenciaExportar, tiempoRecarga
        } = this.props;
        
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        const onFinish = (busqueda) => {
            console.log('busqueda');
            console.log(busqueda);
            const rangeValue = busqueda['rangoFechas'];
            console.log('rangeValue');
            console.log(rangeValue);
            
            if(rangeValue){
                busqueda.rangoFechas = rangeValue[0].format('YYYY-MM-DD') + "," + rangeValue[1].format('YYYY-MM-DD');
            }
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            onGetControlAsistencias(1, busqueda, this.state.filtros);
        }

        

        const columns = [{
                title: 'Foto',
                dataIndex: 'fotoGrid',
                key: 'fotoGrid',
                width: '10%',
                render: (text, record) => (
                    <img onError={this.addDefaultSrc} className='fotoGrid' src={text} />
                ),
            },{
                title: 'Número de empleado',
                dataIndex: 'numeroEmpleado',
                key: 'numeroEmpleado',
                width: '12%',
            },{
                title: 'Nombre completo',
                dataIndex: 'nombreCompleto',
                key: 'nombreCompleto',
                width: '15%',
            },{
                title: 'Servicio',
                dataIndex: 'servicio',
                key: 'servicio',
                width: '10%',
            },{
                title: 'Turno y Horario',
                dataIndex: 'turnoHorario',
                key: 'turnoHorario',
                width: '20%',
            },{            
                title: 'ID Biométrico',
                dataIndex: 'idEmpleadoBiometrico',
                key: 'idEmpleadoBiometrico',
                width: '5%',
            },{
                title: 'Fecha de registro',
                dataIndex: 'fechaRegistro',
                key: 'fechaRegistro',
                sorter: true,
                width: '9%',
            },{       
                title: 'Tipo de registro',
                dataIndex: 'tipoDeRegistro',
                key: 'tipoDeRegistro',
                width: '10%',
            },
        ];
        
        const handleTableChange = (pagination, filtro, orden)=>{
            console.log(orden);
            if(orden){
                let order = 'DESC';
                let field = orden.columnKey;
                if(orden.order === 'ascend'){
                    order = 'ASC';
                }
                else if(!orden.order){
                    order = 'DESC';
                    field = 'idAsistencia';
                }
                const filtros = {
                    field,
                    order
                }

                this.setState({
                    page: pagination.current,
                    filtros
                })
                onGetControlAsistencias(pagination.current, this.state.busqueda, filtros);
            }
            else{
                this.setState({
                    page: pagination.current
                })
                
                onGetControlAsistencias(pagination.current, this.state.busqueda, this.state.filtros);
            }
        }
        
        const onExportarCSV = () => {
            listaControlAsistenciaExportar(this.state.busqueda, this.state.filtros);
        }
        const scroll = {"x": 1000};
        return (
            <div>
                <Card className="cardCatalogos">
                    <Row justify='end'>
                        <Col span={8}>
                            <p>Tiempo restante para recarga: <strong>{tiempoRecarga}</strong></p>
                        </Col>
                    </Row>
                    <Row justify='space-between'>
                        <Col span={24}>
                            <Form
                                name="formulario"
                                onFinish={onFinish}
                                ref={this.formRef}
                            >
                                <Row justify='space-around' gutter={[8,8]}>
                                    <Col span={7}>
                                        <Form.Item
                                        name="param"
                                        >
                                            <Input className='inputFiltro' placeholder="Ingresa el nombre a buscar"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item
                                            name="servicio"
                                        >
                                            <Input className='inputFiltro' placeholder="Ingresa servicio a buscar"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item
                                            name="rangoFechas"
                                        >
                                            <RangePicker format = 'DD-MM-YYYY' placeholder={["Fecha Inicio","Fecha Fin"]} style={{width: '100%'}}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}>
                                        <Button shape="circle" icon={<SearchOutlined />} className="btnFiltrar" htmlType="submit"></Button>
                                    </Col>
                                    <Col span={1}>
                                        <Tooltip title='Exportar archivo .csv' placement="topRight">
                                            <Button disabled={!permisosUsuario.exportarCsvInformaciónDeControlDeAsistencia} onClick={onExportarCSV} className="iconSecundary" shape='circle' icon={<FileExcelFilled /> } />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                    
                    <Row justify='space-between' gutter={[8,8]}>
                        <Col span={24}>
                            <Table 
                                size="small" 
                                rowKey="idAsistencia" 
                                columns={columns}
                                dataSource={listaControlAsistenciaBusqueda}
                                loading={fetchingListaControlAsistenciaBusqueda}
                                locale={{ emptyText: 'Sin datos' }}
                                onChange={handleTableChange}
                                pagination={{ total: listaControlAsistenciaBusquedaCantidad, simple: true, current: this.state.page}}
                                scroll={scroll}
                                showSorterTooltip={true}
                            />         
                        </Col>
                    </Row>
                    {/* <Row justify='space-around'>
                        <Col span={12}>
                            <Button className='btnCargaArchivos' disabled={!permisosUsuario.exportarCsvInformaciónDeControlDeAsistencia} onClick={onExportarCSV}>Exportar a CSV</Button>
                        </Col>
                    </Row> */}
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaControlAsistenciaBusqueda: state.RecursosHumanosReducer.listaControlAsistenciaBusqueda,
        fetchingListaControlAsistenciaBusqueda: state.RecursosHumanosReducer.fetchingListaControlAsistenciaBusqueda,
        listaControlAsistenciaBusquedaCantidad: state.RecursosHumanosReducer.listaControlAsistenciaBusquedaCantidad,
        tiempoRecarga: state.ConfiguracionYSeguridadReducer.tiempoRecarga,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetControlAsistencias: (page, busqueda, filtros) => {
	    	dispatch({ type: "LISTA_CONTROL_ASISTENCIA_BUSQUEDA_REQUEST", page, busqueda, filtros });
        },
        listaControlAsistenciaExportar: (busqueda, filtros) => {
            dispatch({ type: "LISTA_CONTROL_ASISTENCIA_EXPORTAR_REQUEST", busqueda, filtros });
        },
        
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ControlDeAsistenciaContainer);