import React, { Component } from 'react';
import { Form, Button, Row, Col, Spin, Upload, message , Modal} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalCerrarFolio extends Component {
    formRef = React.createRef();
    state = {
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
    }
    render() { 
        const {fetchingCerrarFolioRequisicionPuntoReorden, showModalCerrarFolioRequisicion, folioSeleccionado, page, busqueda} = this.props;

        const onFinish = (formulario) => {
            console.log('Success:', formulario);
            this.props.onCerrarFolio(page, formulario, folioSeleccionado, busqueda);
        }

        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                })
            }
            var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
            if (info.file.status === 'done') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }
        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };

        const handleCancel = () => {
            this.props.onShowModalCerrarFolio();
        }
        return ( 
            <Modal
                visible={showModalCerrarFolioRequisicion}
                title='Cerrar Folio Requisicion'
                onCancel={handleCancel}
                footer={false}
                width={'40%'}
            >
            <Spin spinning={fetchingCerrarFolioRequisicionPuntoReorden}>
                <Form {...layout}
                name="formulario"
                onFinish={onFinish}
                
                ref={this.formRef}
                >
                    <Row justify='space-around'>
                        <Col span={24}>
                            <Form.Item
                                label="Archivo PDF"
                                name="pdf"
                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                            >
                                <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                    {this.state.filePDF === false ? (
                                    <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                        <UploadOutlined /> Click para subir archivo PDF
                                    </Button>
                                    ):false}
                                </Upload>
                            </Form.Item>
                        </Col>
                        
                        <Col span={24} className='marginBoton'>
                            <br/>
                            <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Cerrar Folio Requisicion</Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
            </Modal>
         );
    }
}

const mapStateToProps = state => {
    return {
        showModalCerrarFolioRequisicion: state.AlmacenesReducer.showModalCerrarFolioRequisicion,
        fetchingCerrarFolioRequisicionPuntoReorden: state.AlmacenesReducer.fetchingCerrarFolioRequisicionPuntoReorden,
        folioSeleccionado: state.AlmacenesReducer.folioSeleccionado,
        page: state.AlmacenesReducer.page,
        busqueda: state.AlmacenesReducer.busqueda,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onCerrarFolio: (page, formulario, folioSeleccionado, busqueda) => {
            dispatch({ type: "CERRAR_FOLIO_REQUISICION_PUNTOREORDEN_REQUEST", page, formulario, folioSeleccionado, busqueda });
        },
        onShowModalCerrarFolio: () => {
            dispatch({ type: "SHOW_MODAL_CERRAR_FOLIO_REQUISICION", showModalCerrarFolioRequisicion: false});
        },
    } 
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalCerrarFolio)
