import { takeLatest, call, put } from "redux-saga/effects";
import moment from 'moment';
import * as serviciosPlanificadosActions from '../actions/ServiciosPlanificadosActions';
import * as configuracionYSeguridadActions from '../actions/ConfiguracionYSeguridadActions';
import * as solicitudDeServiciosActions from '../actions/SolicitudDeServiciosActions';
import {
    listaRutinasBusquedaCall, rutinaEstadoCall, nuevaRutinaCall, editarRutinaCall,
    
    listaAsignacionSPBusquedaCall, listaSPNoAsignadosCall, crearAsignacionSPCall, editarAsignacionSPCall,
    
    listaServiciosFiltroRutinas, listaRutinasProgramasBusquedaCall, generarOrdenProgramaCall, listaOrdenesRMPbusquedaCall,eliminarTareaOrdenCall, reprogramarTareaCall, obtenerProgramacionCall,generarPdfMantenimientoCall, generarPdfPruebaCall, generarPdfRutinaCall, reprogramarTareaMultipleCall,
    
    listaCancelacionOrdenesSPCall, detalleCancelacionOrdenSPCall, cancelarOrdenSPCall,

    cambiarEstatusOSPCall, listaVistaCalendarioSPCall, editarPersonalOrdenCall, editarInsumosHerramientasCall,

    listaRMPBusquedaCall, programarRMPCall, editarRMPCall,

    reporteSPBusquedaCall, reporteSPExcelCall,reporteSPPDFCall,
    
    calendarioDeProgramacionCall, calendarioDetalleCall,

    listaArchivosCargaSPCall, cargaArchivoSPCall, eliminarCargaSPCall, procesarCargaSPCall, exportarErroresCargaSPCall,

    listaReportesSPCall, listaReportesSPEspecialesCall, reporteDinamicoSPCall, reporteDinamicoAgrupadoSPCall,  estadoRegistroReporteSPCall,  pdfReportesSPCall, excelReportesSPCall, listaReportesAgrupadoSPCall, pdfReportesAgrupadoSPCall, excelReportesAgrupadoSPCall, crearRegistroReporteSPCall, editarRegistroReporteSPCall,
     
} from '../../services/ServiciosPlanificadosServices';

function* listaRutinaBusqueda(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let busqueda = '';
        let page = action.page - 1; 

        if(action.busqueda){
            busqueda = 'param=' + action.busqueda + '&';
        }
        const response = yield call(listaRutinasBusquedaCall, token, busqueda, page)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const listaRutinasBusqueda = response.data.data;
            const listaRutinasBusquedaCantidad = response.data.cantidad;

            for(let i=0; i<listaRutinasBusqueda.length; i++){
                if(listaRutinasBusqueda[i].servicioByIdServicio){
                    listaRutinasBusqueda[i].servicio = listaRutinasBusqueda[i].servicioByIdServicio.nomenclatura + ': ' + listaRutinasBusqueda[i].servicioByIdServicio.nombre;
                    if(listaRutinasBusqueda[i].servicioByIdServicio.grupoSerByIdGrupoSer){
                        listaRutinasBusqueda[i].idGrupoSer = listaRutinasBusqueda[i].servicioByIdServicio.grupoSerByIdGrupoSer.nombre
                    }
                    else{
                        listaRutinasBusqueda[i].idGrupoSer = 'Grupo de Servicios desactivado.'
                    }
                }else{
                    listaRutinasBusqueda[i].servicio = 'No tiene Servicio';
                }

                if(listaRutinasBusqueda[i].partidaByIdPartida){
                    listaRutinasBusqueda[i].partida = listaRutinasBusqueda[i].partidaByIdPartida.nomenclatura + ': ' + listaRutinasBusqueda[i].partidaByIdPartida.nombre;
                }else {
                    listaRutinasBusqueda[i].partida = 'No tiene Partida';
                }
                
                if(listaRutinasBusqueda[i].subFamiliaByIdSubFamilia){
                    listaRutinasBusqueda[i].subfamilia = listaRutinasBusqueda[i].subFamiliaByIdSubFamilia.subFamilia;
                    if(listaRutinasBusqueda[i].subFamiliaByIdSubFamilia.familiaByIdFamilia){
                        listaRutinasBusqueda[i].familia = listaRutinasBusqueda[i].subFamiliaByIdSubFamilia.familiaByIdFamilia.familia;
                    }
                }
                else{
                    listaRutinasBusqueda[i].subfamilia = 'No tiene Subfamilia';
                    listaRutinasBusqueda[i].familia = 'No tiene Familia';
                }
                if(listaRutinasBusqueda[i].fechaEspecifica){
                    listaRutinasBusqueda[i].fechaEspecificaDetalle = 'Sí';
                }
                else{
                    listaRutinasBusqueda[i].fechaEspecificaDetalle = 'No';
                }
                listaRutinasBusqueda[i].herramientas = '';
                listaRutinasBusqueda[i].herramientasLista = [];
                if(listaRutinasBusqueda[i].herramientasByIdRutina){
                    for(let j in listaRutinasBusqueda[i].herramientasByIdRutina){
                        listaRutinasBusqueda[i].herramientas += '-' + listaRutinasBusqueda[i].herramientasByIdRutina[j].productoByIdProducto.nombre + ' ';
                        
                        if(listaRutinasBusqueda[i].herramientasByIdRutina[j].productoByIdProducto.unidadMedidaByIdUnidadMedida){
                            listaRutinasBusqueda[i].herramientasByIdRutina[j].unidadMedida = listaRutinasBusqueda[i].herramientasByIdRutina[j].productoByIdProducto.unidadMedidaByIdUnidadMedida.unidadMedida;
                        }
                        else{
                            listaRutinasBusqueda[i].herramientasByIdRutina[j].unidadMedida = 'Unidad de medida desactivada.'
                        }
                        listaRutinasBusqueda[i].herramientasByIdRutina[j].insumo = listaRutinasBusqueda[i].herramientasByIdRutina[j].productoByIdProducto.idProducto + ' - ' + listaRutinasBusqueda[i].herramientasByIdRutina[j].productoByIdProducto.nombre + ' - Unidad de medida: ' + listaRutinasBusqueda[i].herramientasByIdRutina[j].unidadMedida
                        listaRutinasBusqueda[i].herramientasLista.push(listaRutinasBusqueda[i].herramientasByIdRutina[j].insumo);
                    }
                }
                
                listaRutinasBusqueda[i].insumos = '';
                listaRutinasBusqueda[i].insumosLista = [];

                if(listaRutinasBusqueda[i].insumosByIdRutina){
                    for(let j=0; j<listaRutinasBusqueda[i].insumosByIdRutina.length; j++){
                        listaRutinasBusqueda[i].insumos += '-' + listaRutinasBusqueda[i].insumosByIdRutina[j].productoByIdProducto.nombre + ' ';
                        
                        if(listaRutinasBusqueda[i].insumosByIdRutina[j].productoByIdProducto.unidadMedidaByIdUnidadMedida){
                            listaRutinasBusqueda[i].insumosByIdRutina[j].unidadMedida = listaRutinasBusqueda[i].insumosByIdRutina[j].productoByIdProducto.unidadMedidaByIdUnidadMedida.unidadMedida;
                        }
                        else{
                            listaRutinasBusqueda[i].insumosByIdRutina[j].unidadMedida = 'Unidad de medida desactivada.'
                        }
                        listaRutinasBusqueda[i].insumosByIdRutina[j].insumo = listaRutinasBusqueda[i].insumosByIdRutina[j].productoByIdProducto.idProducto + ' - ' + listaRutinasBusqueda[i].insumosByIdRutina[j].productoByIdProducto.nombre + ' - Unidad de medida: ' + listaRutinasBusqueda[i].insumosByIdRutina[j].unidadMedida
                        listaRutinasBusqueda[i].insumosLista.push(listaRutinasBusqueda[i].insumosByIdRutina[j].insumo);
                    }
                }
            }
            
            yield put({ type: serviciosPlanificadosActions.LISTA_RUTINAS_BUSQUEDA_SUCCESS, listaRutinasBusqueda, listaRutinasBusquedaCantidad });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.LISTA_RUTINAS_BUSQUEDA_FAILURE })
        }
    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.LISTA_RUTINAS_BUSQUEDA_FAILURE})
    }
}
export function* listaRutinaBusquedaSaga(){
    yield takeLatest(serviciosPlanificadosActions.LISTA_RUTINAS_BUSQUEDA_REQUEST, listaRutinaBusqueda)
}

function* estadoRutina(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idRutina = action.idRutina;
        const response = yield call(rutinaEstadoCall, token, idRutina)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "El estado de la rutina fue cambiado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.ESTADO_RUTINA_SUCCESS });
            yield put({ type: serviciosPlanificadosActions.LISTA_RUTINAS_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda });
            yield put({ type: serviciosPlanificadosActions.SHOW_MODAL_NUEVA_RUTINA, showModalNuevaRutina: false, page: null, busqueda: null });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.ESTADO_RUTINA_FAILURE })
        }
    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.ESTADO_RUTINA_FAILURE})
    }
}
export function* estadoRutinaSaga(){
    yield takeLatest(serviciosPlanificadosActions.ESTADO_RUTINA_REQUEST, estadoRutina)
}

function* nuevaRutina(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let insumos = [];
        if(action.formulario.insumos){
            for(let i=0; i<action.formulario.insumos.length; i++){
                const insumo = {
                    "idInsumo": null,
                    "idProducto": action.formulario.insumos[i].insumo.split(' - ')[0],
                    "cantidad": action.formulario.insumos[i].cantidad,
                    'tipo': 'Insumos',
                    "activo": true
                }
                insumos.push(insumo);
            }
        }
        
        let herramientas = [];
        if(action.formulario.herramientas){
            for(let i=0; i<action.formulario.herramientas.length; i++){
                const herramienta = {
                    "idInsumo": null,
                    "idProducto": action.formulario.herramientas[i].insumo.split(' - ')[0],
                    "cantidad": action.formulario.herramientas[i].cantidad,
                    'tipo': 'Herramientas',
                    "activo": true
                }
                herramientas.push(herramienta);
            }
        }
        
        console.log('insumos, herramientas');
        console.log(insumos, herramientas);
        let dias = null;
        let fechaEspecifica = true;
        if(action.formularioRutina.frecuencia){
            dias = action.formularioRutina.frecuencia;
            fechaEspecifica = false;
        }
        let epp = '';
        if(action.formularioRutina.epp){
            epp = action.formularioRutina.epp;
        }
        const data = {
            "idRutina": null,
            "rutina": action.formularioRutina.rutina,
            "programa": action.formularioRutina.programa,
            "tipoRutina": action.formularioRutina.tipoRutina,
            "claveRutina": action.formularioRutina.clave,
            "actividades": action.formularioRutina.actividades,
            "epp": epp,
            "personal": action.formularioRutina.personal,
            "frecuencia": action.formularioRutina.tipoFrecuencia,
            "dias": dias,
            "fechaEspecifica": fechaEspecifica,
            "afectacion": action.formularioRutina.afectacion,
            "idServicio": action.formularioRutina.idServicio.split(' - ')[0],
            "idPartida": action.formularioRutina.idPartida.split(' - ')[0],
            "idSubFamilia": action.formularioRutina.idSubFamilia.split(' - ')[0],
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
            "insumosByIdRutina": insumos,
            "herramientasByIdRutina": herramientas
        }
        console.log('data');
        console.log(data);
        const response = yield call(nuevaRutinaCall, token, data)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "La rutina fue creada con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.NUEVA_RUTINA_SUCCESS });
            yield put({ type: serviciosPlanificadosActions.SHOW_MODAL_NUEVA_RUTINA, showModalNuevaRutina: false, page: action.page, busqueda: action.busqueda});
            yield put({ type: serviciosPlanificadosActions.LISTA_RUTINAS_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearRutina'});
            yield put({ type: serviciosPlanificadosActions.VISTA_NUEVA_RUTINA, formularioRutina: null, vistaNuevaRutina: 1, listaInsumos: null, tipoModal: null});
            yield put({ type: solicitudDeServiciosActions.LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_SUCCESS, listaPartidasByServicio4Fallas: [] });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.NUEVA_RUTINA_FAILURE })
        }
    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.NUEVA_RUTINA_FAILURE})
    }
}
export function* nuevaRutinaSaga(){
    yield takeLatest(serviciosPlanificadosActions.NUEVA_RUTINA_REQUEST, nuevaRutina)
}

function* editarRutina(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idRutina = action.rutinaSeleccionada.idRutina;
        let insumos = [];
        if(action.formulario.insumos.length){
            for(let i=0; i<action.formulario.insumos.length; i++){
                const insumo = {
                    "idInsumo": action.formulario.insumos[i].idInsumo,
                    "idProducto": action.formulario.insumos[i].insumo.split(' - ')[0],
                    "cantidad": action.formulario.insumos[i].cantidad,
                    'tipo': 'Insumos', //Cuando sean herramientas, mandar tipo : Herramientas
                    "activo": true,
                }
                insumos.push(insumo);
            }
        
            console.log('insumos antes de noAgregar');
            console.log(insumos);
            if(action.rutinaSeleccionada.insumosByIdRutina){
                for(let i=0; i<action.rutinaSeleccionada.insumosByIdRutina.length; i++){
                    if(insumos.length){
                        for(let x=0; x<insumos.length; x++){
                            if(action.rutinaSeleccionada.insumosByIdRutina[i].idInsumo === insumos[x].idInsumo){
                                action.rutinaSeleccionada.insumosByIdRutina[i].noAgregar = true;
                            }
                        }
                    }
                    
                }
                for(let i=0; i<action.rutinaSeleccionada.insumosByIdRutina.length; i++){
                    if(!action.rutinaSeleccionada.insumosByIdRutina[i].noAgregar){
                        action.rutinaSeleccionada.insumosByIdRutina[i].activo = false;
                        insumos.push(action.rutinaSeleccionada.insumosByIdRutina[i]);
                    }
                }
            }
        }
        let herramientas = [];
        if(action.formulario.herramientas.length){
            for(let i=0; i<action.formulario.herramientas.length; i++){
                const herramienta = {
                    "idInsumo": action.formulario.herramientas[i].idInsumo,
                    "idProducto": action.formulario.herramientas[i].insumo.split(' - ')[0],
                    "cantidad": action.formulario.herramientas[i].cantidad ? action.formulario.herramientas[i].cantidad : 1,
                    'tipo': 'Herramientas', //Cuando sean herramientas, mandar tipo : Herramientas
                    "activo": true,
                }
                herramientas.push(herramienta);
            }
            console.log('herramientas antes de noAgregar');
            console.log(herramientas);
            if(action.rutinaSeleccionada.herramientasByIdRutina){
               for(let i=0; i<action.rutinaSeleccionada.herramientasByIdRutina.length; i++){
                   if(herramientas.length){
                        for(let x=0; x<herramientas.length; x++){
                            if(action.rutinaSeleccionada.herramientasByIdRutina[i].idInsumo === herramientas[x].idInsumo){
                                action.rutinaSeleccionada.herramientasByIdRutina[i].noAgregar = true;
                            }
                        }
                    }
                }
                for(let i=0; i<action.rutinaSeleccionada.herramientasByIdRutina.length; i++){
                    if(!action.rutinaSeleccionada.herramientasByIdRutina[i].noAgregar){
                        action.rutinaSeleccionada.herramientasByIdRutina[i].activo = false;
                        herramientas.push(action.rutinaSeleccionada.herramientasByIdRutina[i]);
                    }
                } 
            }
        }
        
        let servicio = null;
        for(let i=0; i<action.listaServiciosByGrupoServicio.length; i++){
            if(action.listaServiciosByGrupoServicio[i].nomenclatura + ': ' + action.listaServiciosByGrupoServicio[i].nombre === action.formularioRutina.servicio){
                servicio = action.listaServiciosByGrupoServicio[i].idServicio;
            }
        }

        let partida = null;
        for(let i in action.listaPartidasByServicio4Fallas){
            if(action.listaPartidasByServicio4Fallas[i].nomenclatura + ': ' + action.listaPartidasByServicio4Fallas[i].nombre === action.formularioRutina.partida){
                partida = action.listaPartidasByServicio4Fallas[i].idPartida;
            }
        }

        let idSubFamilia = null;
        for(let i=0; i<action.listaSubfamilias.length; i++){
            console.log(action.listaSubfamilias[i].subFamilia);
            console.log(action.formularioRutina.subfamilia);
            if(action.listaSubfamilias[i].subFamilia === action.formularioRutina.subfamilia){
                idSubFamilia = action.listaSubfamilias[i].idSubFamilia;
            }
        }

        let dias = null;
        let fechaEspecifica = true;
        if(action.formularioRutina.frecuencia === 'Frecuencia'){
            dias = action.formularioRutina.dias;
            fechaEspecifica = false;
        }
        
        const data = {
            "idRutina": idRutina,
            "rutina": action.formularioRutina.rutina,
            "programa": action.formularioRutina.programa,
            "tipoRutina": action.formularioRutina.tipoRutina,
            "claveRutina": action.formularioRutina.claveRutina,
            "actividades": action.formularioRutina.actividades,
            "epp": action.formularioRutina.epp,
            "personal": action.formularioRutina.personal,
            "herramientas": action.formularioRutina.herramientas,
            "frecuencia": action.formularioRutina.frecuencia,
            "dias": dias,
            "fechaEspecifica": fechaEspecifica,
            "afectacion": action.formularioRutina.afectacion,
            "idServicio": servicio,
            "idPartida": partida,
            "idSubFamilia": idSubFamilia,
            "fechaAlta": action.rutinaSeleccionada.fechaAlta,
            "fechaActualizacion": action.rutinaSeleccionada.fechaActualizacion,
            "activo": action.rutinaSeleccionada.activo,
            "insumosByIdRutina": insumos,
            'herramientasByIdRutina': herramientas,
        }
        console.log('data');
        console.log(data);
        const response = yield call(editarRutinaCall, token, idRutina, data);
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "La rutina fue editada con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.EDITAR_RUTINA_SUCCESS });
            yield put({ type: serviciosPlanificadosActions.SHOW_MODAL_EDITAR_RUTINA, rutinaSeleccionada: false, page: action.page, busqueda: action.page, tipoFrecuencia: null, listaInsumos: null, listaSinRepetir: [], listaSinRepetirHerramienta: []})
            yield put({ type: serviciosPlanificadosActions.VISTA_NUEVA_RUTINA, formularioRutina: null, vistaNuevaRutina: 1 })
            yield put({ type: serviciosPlanificadosActions.LISTA_RUTINAS_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda });
            yield put({ type: solicitudDeServiciosActions.LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_SUCCESS, listaPartidasByServicio4Fallas: [] });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.EDITAR_RUTINA_FAILURE })
        }
    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.EDITAR_RUTINA_FAILURE})
    }
}
export function* editarRutinaSaga(){
    yield takeLatest(serviciosPlanificadosActions.EDITAR_RUTINA_REQUEST, editarRutina)
}

//Asignación para Servicios Planificados
function* listaSPAsignadosBusqueda (action) {
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page -1;
        let {idServicio="", programa="", idUbicacion="", idActivoCaract="", idPartida=""} = action;
        idServicio = idServicio !=="" ? parseInt(idServicio.split(' - ')[0]) : "";
        idPartida = idPartida !=="" ? parseInt(idPartida.split(' - ')[0]) : "";
        idUbicacion = idUbicacion !=="" ? parseInt(idUbicacion.split('-')[0]) :"";
        idActivoCaract = idActivoCaract!=="" ? parseInt(idActivoCaract.split('-')[0]) : "";
        console.log(idServicio, idPartida, programa, idUbicacion, idActivoCaract, page);

        const response = yield call(listaAsignacionSPBusquedaCall, token, idServicio, idPartida, programa, idUbicacion, idActivoCaract, page);
        console.log( response);

        if (response.status == 200) {
            let listaAsignacionesSPBusqueda = response.data.data;
            const listaAsignacionesSPCantidad = response.data.cantidad;

            //Optional chaining 
            listaAsignacionesSPBusqueda.forEach( item => {
                const nomenclatura = item?.servicioByIdServicio?.nomenclatura ?? "Sin nomenclatura";
                const categoria = item?.servicioByIdServicio?.grupoSerByIdGrupoSer?.nombre ?? "Sin categoria";
                const nomenclaturaPartida = item?.partidaByIdPartida?.nomenclatura ?? " - ";
                item.nomenclatura = nomenclatura;
                item.categoria = categoria;
                item.nomenclaturaPartida = nomenclaturaPartida;
            
                if (item.programa == "Rutina") {
                    const ubicacionesArray = item.asignacionByIdAsignacionServicioPlanificado.map( asignacion => {
                        asignacion.ubicacion = asignacion.idUbicacion +' - ' + asignacion.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.codigoArea + ', ' +  asignacion.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                        asignacion.ubicacion += asignacion?.ubicacionByIdUbicacion?.unidadFuncionalByIdUnidadFuncional?.unidadFuncional  ? (', ' +  asignacion?.ubicacionByIdUbicacion?.unidadFuncionalByIdUnidadFuncional?.unidadFuncional) :""; 
                        asignacion.ubicacion +=  asignacion?.ubicacionByIdUbicacion?.interiorFuncionalByIdInteriorFuncional?.interiorFuncional ? (', ' + asignacion?.ubicacionByIdUbicacion?.interiorFuncionalByIdInteriorFuncional?.interiorFuncional) : ""; 
                        asignacion.ubicacion +=  asignacion?.ubicacionByIdUbicacion?.subInteriorFuncionalByIdSubInteriorFuncional?.subInteriorFuncional ? (', ' + asignacion?.ubicacionByIdUbicacion?.subInteriorFuncionalByIdSubInteriorFuncional?.subInteriorFuncional) : ""; 
                        return asignacion.ubicacion;
                    });
                    item.ubicaciones = ubicacionesArray;
                } else {
                    const activosArray = item.asignacionByIdAsignacionServicioPlanificado.map( asignacion => {
                        asignacion.descripcion = asignacion.activoByIdActivo.activoCaractByIdActivoCaract.productoByIdProducto.nombre;
                        asignacion.descripcionUnique =asignacion.activoByIdActivo.activoCaractByIdActivoCaract.idActivoCaract + ' - ' + asignacion.activoByIdActivo.activoCaractByIdActivoCaract.productoByIdProducto.nombre + ' - ' + asignacion.activoByIdActivo.activoCaractByIdActivoCaract.productoByIdProducto.codigoAdicional;
                        asignacion.codigoInterno = asignacion.activoByIdActivo.codigoInterno;
                        asignacion.ubicacion = asignacion.activoByIdActivo.idUbicacion +' - ' + asignacion.activoByIdActivo.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.codigoArea + ', ' +  asignacion.activoByIdActivo.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                        asignacion.ubicacion += asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.unidadFuncionalByIdUnidadFuncional?.unidadFuncional  ? (', ' +  asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.unidadFuncionalByIdUnidadFuncional?.unidadFuncional) :""; 
                        asignacion.ubicacion +=  asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.interiorFuncionalByIdInteriorFuncional?.interiorFuncional ? (', ' + asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.interiorFuncionalByIdInteriorFuncional?.interiorFuncional) : ""; 
                        asignacion.ubicacion +=  asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.subInteriorFuncionalByIdSubInteriorFuncional?.subInteriorFuncional ? (', ' + asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.subInteriorFuncionalByIdSubInteriorFuncional?.subInteriorFuncional) : ""; 
                        return asignacion.descripcionUnique;
                    });
                    const uniqueDescription = new Set (activosArray);
                    const activosArrayUnique = [...uniqueDescription];
                    item.activos = activosArrayUnique;
                }
                if(item.insumosByIdRutina){
                    for ( let insumos of item.insumosByIdRutina) {
                        insumos.descripcion = insumos?.productoByIdProducto?.descripcion ?? 'Sin nombre';
                        insumos.unidadMedida = insumos?.productoByIdProducto?.unidadMedidaByIdUnidadMedida?.unidadMedida ?? 'Sin unidad de medida';
                    }
                }
                if(item.herramientasByIdRutina){
                    for ( let insumos of item.herramientasByIdRutina) {
                        insumos.descripcion = insumos?.productoByIdProducto?.descripcion ?? 'Sin nombre';
                        insumos.unidadMedida = insumos?.productoByIdProducto?.unidadMedidaByIdUnidadMedida?.unidadMedida ?? 'Sin unidad de medida';
                    }
                }
            })

            yield put({ type: serviciosPlanificadosActions.LISTA_ASIGNACIONES_SP_BUSQUEDA_SUCCESS, listaAsignacionesSPBusqueda, listaAsignacionesSPCantidad});
        }
        else {
            yield put ({type: serviciosPlanificadosActions.LISTA_ASIGNACIONES_SP_BUSQUEDA_FAILURE});
        }

    } catch (error) {
        console.log(error);
        yield put ({type: serviciosPlanificadosActions.LISTA_ASIGNACIONES_SP_BUSQUEDA_FAILURE});
    }
}
export function* listaSPAsignadosBusquedaSaga() {
    yield takeLatest(serviciosPlanificadosActions.LISTA_ASIGNACIONES_SP_BUSQUEDA_REQUEST, listaSPAsignadosBusqueda);
}

function* listaSPNoAsignados(action) {
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
    
        const response = yield call (listaSPNoAsignadosCall, token);
        console.log(response);
    
        if (response.status == 200) {
            let listaSPNoAsignados = response.data;
            listaSPNoAsignados.forEach(item => {
                const nomenclatura = item?.servicioByIdServicio?.nomenclatura ?? "Sin nomenclatura";
                const categoria = item?.servicioByIdServicio?.grupoSerByIdGrupoSer?.nombre ?? "Sin categoria";
                item.nomenclatura = nomenclatura;
                item.categoria = categoria;
                if(item.insumosByIdRutina){
                    for ( let insumos of item.insumosByIdRutina) {
                        insumos.descripcion = insumos?.productoByIdProducto?.descripcion ?? 'Sin nombre';
                        insumos.unidadMedida = insumos?.productoByIdProducto?.unidadMedidaByIdUnidadMedida?.unidadMedida ?? 'Sin unidad de medida';
                    }
                }
                if(item.herramientasByIdRutina){
                  for ( let insumos of item.herramientasByIdRutina) {
                        insumos.descripcion = insumos?.productoByIdProducto?.descripcion ?? 'Sin nombre';
                        insumos.unidadMedida = insumos?.productoByIdProducto?.unidadMedidaByIdUnidadMedida?.unidadMedida ?? 'Sin unidad de medida';
                    }  
                }
            })
            console.log('listaSPNoAsignados', listaSPNoAsignados);
            yield put({ type: serviciosPlanificadosActions.LISTA_SP_NO_ASIGNADOS_SUCCESS, listaSPNoAsignados})
        }
        else {
            yield put({ type: serviciosPlanificadosActions.LISTA_SP_NO_ASIGNADOS_FAILURE});
        }
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPlanificadosActions.LISTA_SP_NO_ASIGNADOS_FAILURE});
    }

}
export function* listaSPNoAsignadosSaga() {
    yield takeLatest(serviciosPlanificadosActions.LISTA_SP_NO_ASIGNADOS_REQUEST, listaSPNoAsignados);
}

function* crearAsignacionSP (action) {
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {formulario = "", SPSelected = "",activosSelected= [],page=1, busqueda=""} = action;
        const {idRutina, programa} = SPSelected;
        const rutina = programa == 'Rutina' ? true : false;
        const idServicioBusqueda = busqueda.idServicio;
        const idUbicacionBusqueda = busqueda.idUbicacion;
        const programaBusqueda = busqueda.programa;
        const idActivoCaractBusqueda = busqueda.idActivoCaract;
        
        let activosArray = [];
        activosSelected.forEach(item => {
            const activos = item.idsActivosByidActivoCaracteristicas;
            activosArray.push(...activos);
        });

        const asignaciones = rutina ? formulario.idUbicacion : activosArray;
        const asignacionesArray = asignaciones.map(item => {    
            const data = {
                "idAsignacionServicioPlanificado":null,
                "idRutina":null,
                "idUbicacion":null,
                "idActivo":null,
                "fechaAlta":null,
                "fechaActualizacion":null,
                "activo":null
            }
            data.idUbicacion = rutina ? parseInt(item.split('-')[0]) : null;
            data.idActivo = !rutina ? item : null;
            data.idRutina = idRutina;
            return data;
        });
        console.log(asignacionesArray);

        const response = yield call(crearAsignacionSPCall, token, rutina, asignacionesArray);
        console.log(response);
        if( response.status ==  200) {
            const showModalNuevaAsignacionSPlanificado = false;
            const showSuccessMsg = true;
            const textMessage = "Las asignaciones del servicio planificado fueron generadas con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "CASPlanificado" });
            yield put({ type: serviciosPlanificadosActions.NUEVA_ASIGNACION_SP_SUCCESS});
            yield put({ type: serviciosPlanificadosActions.LISTA_ASIGNACIONES_SP_BUSQUEDA_REQUEST, idServicio: idServicioBusqueda, programa:programaBusqueda, idUbicacion: idUbicacionBusqueda, idActivoCaract:idActivoCaractBusqueda, page: page});
            yield put({ type: serviciosPlanificadosActions.SHOW_MODAL_NUEVA_ASIGNACION_SP, showModalNuevaAsignacionSPlanificado});
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.NUEVA_ASIGNACION_SP_FAILURE});
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPlanificadosActions.NUEVA_ASIGNACION_SP_FAILURE});
    }
}
export function* crearAsignacionSPSaga() {
    yield takeLatest( serviciosPlanificadosActions.NUEVA_ASIGNACION_SP_REQUEST, crearAsignacionSP);
}

function* editarAsignacionSP (action) {
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {formulario = "", activosSelected= [],servicioPlanificadoSeleccionado= [], page=1, busqueda={}} = action;
        const {idRutina, rutina, programa, tipoRutina, claveRutina, actividades, epp, personal, herramientas, frecuencia, dias, fechaEspecifica, afectacion, idServicio, idSubFamilia} = servicioPlanificadoSeleccionado;
        const rutinaTF = programa == 'Rutina' ? true : false;
        const idServicioBusqueda = busqueda.idServicio;
        const idUbicacionBusqueda = busqueda.idUbicacion;
        const programaBusqueda = busqueda.programa;
        const idActivoCaractBusqueda = busqueda.idActivoCaract;

        let ubicacionesArray = []
        if (rutinaTF) {
            for ( let ubicacion of formulario.ubicaciones) {
                const idUbicacion = parseInt(ubicacion.split('-')[0]);
                let elemento = servicioPlanificadoSeleccionado.asignacionByIdAsignacionServicioPlanificado.find ( item => item.idUbicacion == idUbicacion);
                if (elemento) {
                    elemento.fechaActualizacion = null;
                    elemento.fechaAlta = null
                    delete elemento.ubicacion;
                    ubicacionesArray.push(elemento);
                } else {
                    const NuevaAsignacion = {
                        "idAsignacionServicioPlanificado": null,
                        "idRutina": null,
                        "idUbicacion": null,
                        "idActivo": null,
                        "fechaAlta": null,
                        "fechaActualizacion": null,
                        "activo": null,
                        "rutinaByIdRutina": null,
                        "ubicacionByIdUbicacion": null,
                        "activoByIdActivo": null
                    }
                    NuevaAsignacion.idRutina = idRutina;
                    NuevaAsignacion.idUbicacion = idUbicacion;
                    ubicacionesArray.push(NuevaAsignacion);
                }
            }
        };
        console.log(ubicacionesArray);
        
        let activosArray = [];
        let idsActivosArray = [];
        if (!rutinaTF) {
            for (let asignaciones of servicioPlanificadoSeleccionado.asignacionByIdAsignacionServicioPlanificado) {
                let elemento = formulario.activos.find(item => parseInt(item.split('-')[0]) == asignaciones.activoByIdActivo.idActivoCaract);
                if (elemento) {
                    asignaciones.fechaAlta = null;
                    asignaciones.fechaActualizacion = null;
                    delete asignaciones.descripcion;
                    delete asignaciones.codigoInterno;
                    delete asignaciones.descripcionUnique; 
                    activosArray.push(asignaciones);
                } 
            }
            for (let nuevosActivos of activosSelected) {
                const idsActivos = nuevosActivos.idsActivosByidActivoCaracteristicas;
                idsActivosArray.push(...idsActivos);
            }
            for (let activos of idsActivosArray) {
                const data = {
                    "idAsignacionServicioPlanificado": null,
                    "idRutina": null,
                    "idUbicacion": null,
                    "idActivo": null,
                    "fechaAlta": null,
                    "fechaActualizacion": null,
                    "activo": null,
                    "rutinaByIdRutina": null,
                    "ubicacionByIdUbicacion": null,
                    "activoByIdActivo": null
                }
                data.idRutina = idRutina;
                data.idActivo = activos;
                activosArray.push(data);
            }
        }
        console.log(activosArray);

        const dataAsignaciones = {
            "idRutina": idRutina,
            "rutina": rutina,
            "programa": programa,
            "tipoRutina": tipoRutina,
            "claveRutina": claveRutina,
            "actividades": actividades,
            "epp": epp,
            "personal": personal,
            "herramientas": herramientas,
            "frecuencia": frecuencia,
            "dias": dias,
            "fechaEspecifica": fechaEspecifica,
            "afectacion": afectacion,
            "idServicio": idServicio,
            "idSubFamilia": idSubFamilia,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
            "insumosByIdRutina": null,
            "herramientasByIdRutina": null,
            "servicioByIdServicio": null,
            "subFamiliaByIdSubFamilia": null,
            "asignacionByIdAsignacionServicioPlanificado": rutinaTF ? ubicacionesArray:  activosArray,
        }
        console.log(dataAsignaciones);

        const response = yield call(editarAsignacionSPCall, token, idRutina, rutinaTF, dataAsignaciones);
        console.log(response);
        if( response.status ==  200) {
            const showModalEditarAsignacionSPlanificado = false;
            const showSuccessMsg = true;
            const textMessage = "Las asignaciones del servicio planificado fueron editadas con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "EASPlanificado" });
            yield put({ type: serviciosPlanificadosActions.EDITAR_ASIGNACION_SP_SUCCESS});
            yield put({ type: serviciosPlanificadosActions.LISTA_ASIGNACIONES_SP_BUSQUEDA_REQUEST, idServicio: idServicioBusqueda, programa:programaBusqueda, idUbicacion: idUbicacionBusqueda, idActivoCaract: idActivoCaractBusqueda, page: page});
            yield put({ type: serviciosPlanificadosActions.SHOW_MODAL_EDITAR_ASIGNACION_SP, showModalEditarAsignacionSPlanificado});
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.EDITAR_ASIGNACION_SP_FAILURE});
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPlanificadosActions.EDITAR_ASIGNACION_SP_FAILURE});
    }
}
export function* editarAsignacionSPSaga() {
    yield takeLatest( serviciosPlanificadosActions.EDITAR_ASIGNACION_SP_REQUEST, editarAsignacionSP);
}

// Programas de Rutinas, Mantenimientos y Pruebas
function* listaRMPBusqueda(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let {idsServicios, programa, busqueda} = action;
        const data = [];

        for(let i=0; i<action.idsServicios.length; i++){
            const servicio = {
                "idServicio": idsServicios[i],
            }
            data.push(servicio);
        }

        const response = yield call(listaRMPBusquedaCall, token, data, programa, page, busqueda);
        console.log('response', response);

        if (response.status === 200) {
            let listaRMPBusqueda = response.data.data;
            let listaRMPBusquedaCantidad = response.data.cantidad;
            let spnbool = false;

            for(let i = 0; i < listaRMPBusqueda.length; i++) {
                listaRMPBusqueda[i].afectacionMin = listaRMPBusqueda[i].afectacion + ' min.';

                if(listaRMPBusqueda[i].servicioByIdServicio){
                    listaRMPBusqueda[i].nomenclatura = listaRMPBusqueda[i].servicioByIdServicio.nomenclatura;
                } else {
                    listaRMPBusqueda[i].nomenclatura = 'N/A';
                }

                if(listaRMPBusqueda[i].servicioByIdServicio.grupoSerByIdGrupoSer){
                    listaRMPBusqueda[i].categoria = listaRMPBusqueda[i].servicioByIdServicio.grupoSerByIdGrupoSer.nombre;
                } else {
                    listaRMPBusqueda[i].categoria = 'N/A';
                }
                if(listaRMPBusqueda[i].insumosByIdRutina){
                    for(let j = 0; j < listaRMPBusqueda[i].insumosByIdRutina.length; j++){
                        if(listaRMPBusqueda[i].insumosByIdRutina[j].productoByIdProducto){
                            listaRMPBusqueda[i].insumosByIdRutina[j].descripcion = listaRMPBusqueda[i].insumosByIdRutina[j].productoByIdProducto.descripcion;

                            if(listaRMPBusqueda[i].insumosByIdRutina[j].productoByIdProducto.unidadMedidaByIdUnidadMedida){
                                listaRMPBusqueda[i].insumosByIdRutina[j].unidadMedida = listaRMPBusqueda[i].insumosByIdRutina[j].productoByIdProducto.unidadMedidaByIdUnidadMedida.unidadMedida;
                            }
                        }
                    }
                }

                if(listaRMPBusqueda[i].herramientasByIdRutina){
                    for(let j = 0; j < listaRMPBusqueda[i].herramientasByIdRutina.length; j++){
                        if(listaRMPBusqueda[i].herramientasByIdRutina[j].productoByIdProducto){
                            listaRMPBusqueda[i].herramientasByIdRutina[j].descripcion = listaRMPBusqueda[i].herramientasByIdRutina[j].productoByIdProducto.descripcion;

                            if(listaRMPBusqueda[i].herramientasByIdRutina[j].productoByIdProducto.unidadMedidaByIdUnidadMedida){
                                listaRMPBusqueda[i].herramientasByIdRutina[j].unidadMedida = listaRMPBusqueda[i].herramientasByIdRutina[j].productoByIdProducto.unidadMedidaByIdUnidadMedida.unidadMedida;
                            }
                        }
                    }
                }
                
                if(listaRMPBusqueda[i].asignacionByIdAsignacionServicioPlanificado){
                    for(let j = 0; j < listaRMPBusqueda[i].asignacionByIdAsignacionServicioPlanificado.length; j++){
                        if(listaRMPBusqueda[i].asignacionByIdAsignacionServicioPlanificado[j].ubicacionByIdUbicacion){
                            if(listaRMPBusqueda[i].asignacionByIdAsignacionServicioPlanificado[j].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional){
                                listaRMPBusqueda[i].asignacionByIdAsignacionServicioPlanificado[j].ubicacionCOA = listaRMPBusqueda[i].asignacionByIdAsignacionServicioPlanificado[j].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.codigoArea + ' - ' + listaRMPBusqueda[i].asignacionByIdAsignacionServicioPlanificado[j].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;

                                if(listaRMPBusqueda[i].asignacionByIdAsignacionServicioPlanificado[j].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional){
                                    listaRMPBusqueda[i].asignacionByIdAsignacionServicioPlanificado[j].ubicacionCOA += ', ' + listaRMPBusqueda[i].asignacionByIdAsignacionServicioPlanificado[j].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;

                                    if(listaRMPBusqueda[i].asignacionByIdAsignacionServicioPlanificado[j].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional){
                                        listaRMPBusqueda[i].asignacionByIdAsignacionServicioPlanificado[j].ubicacionCOA += ', ' + listaRMPBusqueda[i].asignacionByIdAsignacionServicioPlanificado[j].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional;

                                        if(listaRMPBusqueda[i].asignacionByIdAsignacionServicioPlanificado[j].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional){
                                            listaRMPBusqueda[i].asignacionByIdAsignacionServicioPlanificado[j].ubicacionCOA += ', ' + listaRMPBusqueda[i].asignacionByIdAsignacionServicioPlanificado[j].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }


           yield put({ type: serviciosPlanificadosActions.LISTA_RMP_BUSQUEDA_SUCCESS, listaRMPBusqueda, listaRMPBusquedaCantidad, spnbool });
        } else {
            yield put({ type: serviciosPlanificadosActions.LISTA_RMP_BUSQUEDA_FAILURE});
        }
    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.LISTA_RMP_BUSQUEDA_FAILURE});
    }
}
export function* listaRMPBusquedaSaga(){
    yield takeLatest( serviciosPlanificadosActions.LISTA_RMP_BUSQUEDA_REQUEST, listaRMPBusqueda);
}

function* programarRMP(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        const data = action.dataProgramar;
        console.log('data', data)
        
        const response = yield call(programarRMPCall, token, data)
        console.log('response', response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "La programación fue creada con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.PROGRAMAR_RMP_SUCCESS });
            yield put({ type: serviciosPlanificadosActions.SHOW_MODAL_PROGRAMAR_RMP, showModalProgramarRMP: false, page: action.page, busqueda: action.busqueda});
            yield put({ type: serviciosPlanificadosActions.LISTA_RMP_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda, programa: action.programa, idsServicios: action.idsServicios });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.PROGRAMAR_RMP_FAILURE })
        }
    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.PROGRAMAR_RMP_FAILURE})
    }
}
export function* programarRMPSaga(){
    yield takeLatest(serviciosPlanificadosActions.PROGRAMAR_RMP_REQUEST, programarRMP)
}

function* editarRMP(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        let idProgramacionRutina = null;
        let data = [];
        let fechaInicial = '';
        let fechaAlta = '';
        let asignacionServicioPlanificadoByIdAsignacionServicioPlanificado = null;
        let fechasRutinasByIdProgramacion = null;

        console.log('page, busqueda, data, programaRMPSeleccionado, asignacionSeleccionado', action.page, action.busqueda, action.data, action.programaRMPSeleccionado, action.asignacionSeleccionado)

        for(let i = 0; i < action.programaRMPSeleccionado.programacionRutinasByIdProgramacionRutina.length; i++){
            if(action.asignacionSeleccionado.idAsignacionServicioPlanificado === action.programaRMPSeleccionado.programacionRutinasByIdProgramacionRutina[i].idAsignacionServicioPlanificado){
                idProgramacionRutina = action.programaRMPSeleccionado.programacionRutinasByIdProgramacionRutina[i].idProgramacionRutina;

                if(action.programaRMPSeleccionado.programacionRutinasByIdProgramacionRutina[i].fechaInicial){
                    fechaInicial = action.programaRMPSeleccionado.programacionRutinasByIdProgramacionRutina[i].fechaInicial;

                    fechaInicial = fechaInicial.split('T')[0]
                }

                if(action.programaRMPSeleccionado.programacionRutinasByIdProgramacionRutina[i].fechaAlta){
                    fechaAlta = action.programaRMPSeleccionado.programacionRutinasByIdProgramacionRutina[i].fechaAlta;

                    //fechaAlta = fechaAlta.split('T')[0]
                }

                if(action.programaRMPSeleccionado.programacionRutinasByIdProgramacionRutina[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado){
                    asignacionServicioPlanificadoByIdAsignacionServicioPlanificado = action.programaRMPSeleccionado.programacionRutinasByIdProgramacionRutina[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado;
                }

                
                if(action.programaRMPSeleccionado.programacionRutinasByIdProgramacionRutina[i].fechasRutinasByIdProgramacion){
                    fechasRutinasByIdProgramacion = action.programaRMPSeleccionado.programacionRutinasByIdProgramacionRutina[i].fechasRutinasByIdProgramacion;
                }
            }
        }
        
        console.log('idProgramacionRutina', idProgramacionRutina)

        data = {
            "idProgramacionRutina": idProgramacionRutina,
            "idAsignacionServicioPlanificado": action.asignacionSeleccionado.idAsignacionServicioPlanificado,
            "hora": action.asignacionSeleccionado.hora,
            "estadoRutina": action.programaRMPSeleccionado.estadoRutina,
            "fechaInicial": fechaInicial,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "asignacionServicioPlanificadoByIdAsignacionServicioPlanificado": asignacionServicioPlanificadoByIdAsignacionServicioPlanificado,
            "fechasRutinasByIdProgramacion": fechasRutinasByIdProgramacion
        }

        console.log('data', data);
        const response = yield call(editarRMPCall, token, idProgramacionRutina, data);
        console.log('response', response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "La propuesta fue editada con éxito.";
            const idsServiciosSeleccionados = action.idsServicios;

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.EDITAR_RMP_SUCCESS });
            yield put({ type: serviciosPlanificadosActions.LISTA_RMP_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda, programa: action.programa, idsServicios: idsServiciosSeleccionados });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.EDITAR_RMP_FAILURE })
        }
    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.EDITAR_RMP_FAILURE})
    }
}
export function* editarRMPSaga(){
    yield takeLatest(serviciosPlanificadosActions.EDITAR_RMP_REQUEST, editarRMP)
}

// SAGA GENERAR ORDENES RUTINA Y MANTENIMIENTO
function* listaServiciosFiltroRutina(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaServiciosFiltroRutinas, token);
        console.log('response listaServiciosFiltroRutina', response);

        if (response.status === 200) {
           let listaServiciosRutinaFiltro = response.data;

           yield put({ type: serviciosPlanificadosActions.LISTA_SERVICIOS_RUTINAS_ACTIVOS_SUCCESS, listaServiciosRutinaFiltro});
        } else {
            yield put({ type: serviciosPlanificadosActions.LISTA_SERVICIOS_RUTINAS_ACTIVOS_FAILURE});
        }
    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.LISTA_SERVICIOS_RUTINAS_ACTIVOS_FAILURE});
    }
}

export function* listaServiciosFiltroRutinaSaga(){
    yield takeLatest( serviciosPlanificadosActions.LISTA_SERVICIOS_RUTINAS_ACTIVOS_REQUEST, listaServiciosFiltroRutina);
}

// Saga busqueda de filtros para generacion de ordenes rutinas mantenimiento grid 
function* listaRutinasProgramadas(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page-1;
        let fechaInicio = "";
        let fechaFin    = "";
        let {idServicio, programa, fecha, idAreaFuncional,  idUnidadFuncional, idPartida} = action.busqueda;
        if(fecha){
            fechaInicio = fecha[0].format('YYYY-MM-DD');
            fechaFin    = fecha[1].format('YYYY-MM-DD');
        }
        
        idServicio ? idServicio = parseInt(idServicio.split('-')[0]) : idServicio="";
        idAreaFuncional ? idAreaFuncional = parseInt(idAreaFuncional.split('-')[0]) : idAreaFuncional="";
        idUnidadFuncional ? idUnidadFuncional = parseInt(idUnidadFuncional.split('-')[0]) : idUnidadFuncional="";
        idPartida ? idPartida = parseInt(idPartida.split('-')[0]) : idPartida="";
        
        console.log('params:', page, idServicio, programa, idAreaFuncional, idUnidadFuncional, idPartida);
        console.log('fechas', fechaInicio, fechaFin);

        const response = yield call(listaRutinasProgramasBusquedaCall, token, idServicio, programa, fechaInicio, fechaFin, idAreaFuncional, idUnidadFuncional, idPartida, page);
        console.log('response listaRutinasProgramadas', response);

        if (response.status === 200) {
           let listaOrdenesBusquedaRequest = response.data.data;
           let listaOrdenesBusquedaRequestCantidad = response.data.cantidad;

           
            for (const i in listaOrdenesBusquedaRequest) {
                if(listaOrdenesBusquedaRequest[i].partidaByIdPartida) listaOrdenesBusquedaRequest[i].partida = listaOrdenesBusquedaRequest[i].partidaByIdPartida.nomenclatura;  else listaOrdenesBusquedaRequest[i].partida = '-'; 
                // listaOrdenesBusquedaRequest[i].partida = listaOrdenesBusquedaRequest?.partidaByIdPartida?.nomenclatura ?? "-";

                if(listaOrdenesBusquedaRequest[i].herramientasByIdRutina){
                    for(let j = 0; j < listaOrdenesBusquedaRequest[i].herramientasByIdRutina.length; j++){
                        if(listaOrdenesBusquedaRequest[i].herramientasByIdRutina[j].productoByIdProducto){
                            listaOrdenesBusquedaRequest[i].herramientasByIdRutina[j].descripcion = listaOrdenesBusquedaRequest[i].herramientasByIdRutina[j].productoByIdProducto.descripcion;

                            if(listaOrdenesBusquedaRequest[i].herramientasByIdRutina[j].productoByIdProducto.unidadMedidaByIdUnidadMedida){
                                listaOrdenesBusquedaRequest[i].herramientasByIdRutina[j].unidadMedida = listaOrdenesBusquedaRequest[i].herramientasByIdRutina[j].productoByIdProducto.unidadMedidaByIdUnidadMedida.unidadMedida;
                            }
                        }
                    }
                }

                listaOrdenesBusquedaRequest[i].fechaAlta = listaOrdenesBusquedaRequest[i].fechaAlta.split('T')[0].split('-').reverse().join('-')
                if (listaOrdenesBusquedaRequest[i].servicioByIdServicio) {
                    listaOrdenesBusquedaRequest[i].nomenclatura = listaOrdenesBusquedaRequest[i].servicioByIdServicio.nomenclatura;
                    listaOrdenesBusquedaRequest[i].nombre = listaOrdenesBusquedaRequest[i].servicioByIdServicio.nombre;
                }
                for (let x in listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina) {
                    if (listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado) {
                        if (listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo) {
                            if (listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.activoCaractByIdActivoCaract) {
                                listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].descripcion = listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.activoCaractByIdActivoCaract.productoByIdProducto.nombre
                            }
                            if (listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional) {
                                listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].claveUbicacion = listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional
                            }
                            if (listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional) {
                                listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].claveUbicacion = listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional + " - " + listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional
                            }
                            if (listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional) {
                                listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].claveUbicacion = listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional + " - " + listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional + " - " + listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional
                            }
                            if (listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional) {
                                listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].claveUbicacion = listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional + " - " + listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional + " - " + listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional + " - " + listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional
                            }
                        } else {
                            listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].descripcion = "No hay Activo"
                        }
                        if (listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion) {
                            if (listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional) {
                                listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].claveUbicacion = listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional
                            }
                            if (listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional) {
                                listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].claveUbicacion = listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional + " - " + listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional
                            }
                            if (listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional) {
                                listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].claveUbicacion = listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional + " - " + listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional + " - " + listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional
                            }
                            if (listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional) {
                                listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].claveUbicacion = listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional + " - " + listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional + " - " + listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional + " - " + listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional
                            }
                        }
                    }
                    if (listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].fechasRutinasByIdProgramacion) {
                        for(let y in listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].fechasRutinasByIdProgramacion){
                          
                          listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].fechasRutinasByIdProgramacion[y].fecha = listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].fechasRutinasByIdProgramacion[y].fecha.split('T')[0].split('-').reverse().join('-')
                          listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].fechasRutinasByIdProgramacion[y].estadoRutina = listaOrdenesBusquedaRequest[i].programacionRutinasByIdProgramacionRutina[x].fechasRutinasByIdProgramacion[y].estado
                        }
                        
                    }
                }
                if (action.idRutina) {
                    if (listaOrdenesBusquedaRequest[i].idRutina === action.idRutina) {
                        yield put({ type: serviciosPlanificadosActions.SHOW_MODAL_EDITAR_ORDEN, showModalEditarOrden: true, tareaSeleccionada: listaOrdenesBusquedaRequest[i], page: action.page, busqueda: action.busqueda })
                    }
                }
            }

           yield put({ type: serviciosPlanificadosActions.LISTA_RUTINAS_PROGRAMADAS_REPROGRAMADAS_SUCCESS, listaOrdenesBusquedaRequest, listaOrdenesBusquedaRequestCantidad });
        } else {
            yield put({ type: serviciosPlanificadosActions.LISTA_RUTINAS_PROGRAMADAS_REPROGRAMADAS_FAILURE});
        }
    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.LISTA_RUTINAS_PROGRAMADAS_REPROGRAMADAS_FAILURE});
    }
}

export function* listaRutinasProgramadasSaga(){
    yield takeLatest( serviciosPlanificadosActions.LISTA_RUTINAS_PROGRAMADAS_REPROGRAMADAS_REQUEST, listaRutinasProgramadas);
}

// Saga busqueda de ordenes grid 
function* generarOrden(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let listProgramaciones = [];
        let programaEnviar;
        if(action.tareaSeleccionada.programacionRutinasByIdProgramacionRutina){
            for (let i in action.tareaSeleccionada.programacionRutinasByIdProgramacionRutina) {
                if(action.tareaSeleccionada.programacionRutinasByIdProgramacionRutina[i].fechasRutinasByIdProgramacion){
                    for(let x in action.tareaSeleccionada.programacionRutinasByIdProgramacionRutina[i].fechasRutinasByIdProgramacion){
                        programaEnviar = {
                            "idProgramacionRutina": parseInt(action.tareaSeleccionada.programacionRutinasByIdProgramacionRutina[i].fechasRutinasByIdProgramacion[x].idProgramacionRutina),
                            "idFechaRutina": parseInt(action.tareaSeleccionada.programacionRutinasByIdProgramacionRutina[i].fechasRutinasByIdProgramacion[x].idFechaRutina),
                        }
                        listProgramaciones.push(programaEnviar);
                    }
                }
            }
        }
        console.log('listProgramaciones', listProgramaciones);
        
        const response = yield call(generarOrdenProgramaCall, token, listProgramaciones);
        console.log('response generarOrden', response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "La orden fue generada con éxito.";
            const showModalEditarOrden = false;
            const page = action.page;
            const busqueda = action.busqueda;

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.GENERAR_ORDEN_PROGRAMA_SUCCESS});            
            yield put({ type: serviciosPlanificadosActions.SHOW_MODAL_EDITAR_ORDEN, showModalEditarOrden})
            yield put({ type: serviciosPlanificadosActions.LISTA_RUTINAS_PROGRAMADAS_REPROGRAMADAS_REQUEST, busqueda, page, idRutina: action.idRutina});
            
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.GENERAR_ORDEN_PROGRAMA_FAILURE});
        }
        
    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.GENERAR_ORDEN_PROGRAMA_FAILURE});
    }
}

export function* generarOrdenSaga(){
    yield takeLatest( serviciosPlanificadosActions.GENERAR_ORDEN_PROGRAMA_REQUEST, generarOrden);
}
// Saga lista de ordenes busqueda
function* listaOrdenesBusqueda(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let busqueda = action.busquedaOrdenes.param; 
        let page = (action.pageOrdenes - 1) * 10;
        console.log('busqueda saga', busqueda, page);
        const response = yield call(listaOrdenesRMPbusquedaCall, token, busqueda, page);
        console.log('response listaOrdenesBusqueda', response);
 
        if (response.status === 200) {
            let listaOrdenesRMP = response.data.data;
            let listaOrdenesRMPCantidad = response.data.cantidad;

            for (let i in listaOrdenesRMP) {
                listaOrdenesRMP[i].fechaAlta = listaOrdenesRMP[i].fechaAlta.split('T')[0].split('-').reverse().join('-');
            }
            
         yield put({ type: serviciosPlanificadosActions.LISTA_ORDENES_RMP_ACTIVOS_SUCCESS, listaOrdenesRMP, listaOrdenesRMPCantidad})
        } else {
            yield put({ type: serviciosPlanificadosActions.LISTA_ORDENES_RMP_ACTIVOS_FAILURE});
        }

    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.LISTA_ORDENES_RMP_ACTIVOS_FAILURE});
    }
}

export function* listaOrdenesBusquedaSaga(){
    yield takeLatest( serviciosPlanificadosActions.LISTA_ORDENES_RMP_ACTIVOS_REQUEST, listaOrdenesBusqueda)
}
// Saga eliminar ordenes busqueda
function* eliminarTareaOrden(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idFechaRutina;
        // for(let i in action.tarea.fechasRutinasByIdProgramacion){
        //     idFechaRutina = action.tarea.fechasRutinasByIdProgramacion[i].idFechaRutina
        // }
        if(action.tarea.idFechaRutina){
            idFechaRutina = action.tarea.idFechaRutina
        }

        console.log('idFechaRutina', idFechaRutina)
        
        const response = yield call(eliminarTareaOrdenCall, token, idFechaRutina);
        console.log('eliminarTareaOrden response', response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "La tarea fue eliminada con éxito.";
            const page = action.page;
            const busqueda = action.busqueda;
  
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.ELIMINAR_TAREA_ORDEN_SUCCESS});
            yield put({ type: serviciosPlanificadosActions.LISTA_RUTINAS_PROGRAMADAS_REPROGRAMADAS_REQUEST, busqueda, page, idRutina: action.idRutina});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.ELIMINAR_TAREA_ORDEN_FAILURE});
        }

    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.ELIMINAR_TAREA_ORDEN_FAILURE});
    }
}

export function* eliminarTareaOrdenSaga(){
    yield takeLatest( serviciosPlanificadosActions.ELIMINAR_TAREA_ORDEN_REQUEST, eliminarTareaOrden)
}

// Saga reprogramar tarea
function* reprogramarTarea(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idProgramacionRutina = action.tareaSeleccionadaReprogramacion.idProgramacionRutina;
        const observacion = action.formulario.observacion;
        const fechaReprogramacion = action.formulario.fechaReprogramacion.format('YYYY-MM-DD');
        
        let idFechaRutina = '';
        if( action.tareaSeleccionadaReprogramacion.idFechaRutina){
           // if(action.tareaSeleccionadaReprogramacion.fechasRutinasByIdProgramacion[i].idFechaRutina !== null){
                idFechaRutina = "?idFechaRutina=" + action.tareaSeleccionadaReprogramacion.idFechaRutina
            
        }
        console.log('Enviar', idProgramacionRutina,idFechaRutina, observacion, fechaReprogramacion);
        
        const response = yield call(reprogramarTareaCall, token, idProgramacionRutina, idFechaRutina, observacion, fechaReprogramacion);
        console.log(' reprogramarTarea response', response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "La tarea fue reprogramada con éxito.";
            let showModalReprogramarTarea = false;
            let page = action.page;
            let busqueda = action.busqueda;
            console.log('saga page y busqueda reprogramar',page, busqueda);
            
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.REPROGRAMAR_TAREA_ORDEN_SUCCESS});
            yield put({ type: serviciosPlanificadosActions.SHOW_MODAL_REPROGRAMAR_TAREA_ORDEN, showModalReprogramarTarea, page, busqueda});
            yield put({ type: serviciosPlanificadosActions.LISTA_RUTINAS_PROGRAMADAS_REPROGRAMADAS_REQUEST, busqueda, page, idRutina: action.idRutina});
           
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.REPROGRAMAR_TAREA_ORDEN_FAILURE});
        }

    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.REPROGRAMAR_TAREA_ORDEN_FAILURE});
    }
}

export function* reprogramarTareaSaga(){
    yield takeLatest( serviciosPlanificadosActions.REPROGRAMAR_TAREA_ORDEN_REQUEST, reprogramarTarea)
}

// Saga obtener programacion de orden
function* listaProgramacionOrden(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const folio = action.folio;
        const page = action.page-1;
        
        const response = yield call(obtenerProgramacionCall, token, folio, page);
        console.log('response listaProgramacionOrden', response);
 
        if(response.status === 200){
          let listaProgramacionOrden = response.data.data;

            for (let i in listaProgramacionOrden) {
                if (listaProgramacionOrden[i].fechasRutinasByIdOrdenServicio) {
                    listaProgramacionOrden[i].fecha = listaProgramacionOrden[i].fechasRutinasByIdOrdenServicio.fecha.split('T')[0].split('T').reverse().join('-')
                      if(listaProgramacionOrden[i].programacionRutinasByIdProgramacionRutina){
                        listaProgramacionOrden[i].estadoRutina = listaProgramacionOrden[i].programacionRutinasByIdProgramacionRutina.estadoRutina;
                        if(listaProgramacionOrden[i].programacionRutinasByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado){
                            if(listaProgramacionOrden[i].programacionRutinasByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo){
                                if(listaProgramacionOrden[i].programacionRutinasByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.activoCaractByIdActivoCaract){
                                    listaProgramacionOrden[i].descripcion = listaProgramacionOrden[i].programacionRutinasByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.activoCaractByIdActivoCaract.descripcion
                                }
                            } else {
                                listaProgramacionOrden[i].descripcion = "No hay Activo"
                            }
                            if(listaProgramacionOrden[i].programacionRutinasByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion){
                                listaProgramacionOrden[i].claveUbicacion = listaProgramacionOrden[i].programacionRutinasByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.claveUbicacion;
                            } else {
                                listaProgramacionOrden[i].claveUbicacion = "No hay Ubicacion"
                            }
                        } 
                      } 
                }
            }
        
            yield put({ type: serviciosPlanificadosActions.OBTENER_PROGRAMACION_ORDEN_SUCCESS, listaProgramacionOrden})
        } else {
            yield put({ type: serviciosPlanificadosActions.OBTENER_PROGRAMACION_ORDEN_FAILURE});
        }

    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.OBTENER_PROGRAMACION_ORDEN_FAILURE});
    }
}

export function* listaProgramacionOrdenSaga(){
    yield takeLatest( serviciosPlanificadosActions.OBTENER_PROGRAMACION_ORDEN_REQUEST, listaProgramacionOrden)
}

// saga descargar pdf mantenimiento
function* descargarPdfMantenimientoOrden(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const folio = action.folio;

        const response = yield call(generarPdfMantenimientoCall, token, folio);
        console.log('descargarPdfMantenimientoOrden', response);

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "El folio se descargó de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Orden - Mantenimiento " + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.DESCARGAR_PDF_MANTENIMIENTO_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.DESCARGAR_PDF_MANTENIMIENTO_FAILURE });
        }
        

    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.DESCARGAR_PDF_MANTENIMIENTO_FAILURE});
    }
}
export function* descargarPdfMantenimientoOrdenSaga(){
    yield takeLatest( serviciosPlanificadosActions.DESCARGAR_PDF_MANTENIMIENTO_REQUEST, descargarPdfMantenimientoOrden)
}

// saga descargar pdf rutina
function* descargarPdfRutinaOrden(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const folio = action.folio;
        const response = yield call(generarPdfRutinaCall, token, folio);
        console.log('descargarPdfRutinaOrden', response);

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "El folio se descargó de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Orden - Rutina " + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.DESCARGAR_PDF_RUTINA_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.DESCARGAR_PDF_RUTINA_FAILURE });
        }
        

    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.DESCARGAR_PDF_RUTINA_FAILURE});
    }
}
export function* descargarPdfRutinaOrdenSaga(){
    yield takeLatest( serviciosPlanificadosActions.DESCARGAR_PDF_RUTINA_REQUEST, descargarPdfRutinaOrden)
}

// saga descargar pdf prueba
function* descargarPdfPruebaOrden(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const folio = action.folio;
        const response = yield call(generarPdfPruebaCall, token, folio);
        console.log('descargarPdfRutinaOrden', response);
        

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "La orden se descargó de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Orden - Prueba " + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.DESCARGAR_PDF_RUTINA_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.DESCARGAR_PDF_RUTINA_FAILURE });
        }
        

    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.DESCARGAR_PDF_PRUEBA_FAILURE});
    }
}
export function* descargarPdfPruebaOrdenSaga(){
    yield takeLatest( serviciosPlanificadosActions.DESCARGAR_PDF_PRUEBA_REQUEST, descargarPdfPruebaOrden)
}


//Cancelar Orden Servicio Planificado
function* listaOrdenesCancelacionSPBusqueda (action) {
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page -1;
        let {idServicio="", idPartida="", programa="", idUbicacion="", fecha=[]} = action;
        idServicio = idServicio !=="" ? parseInt(idServicio.split(' - ')[0]) : "";
        idPartida = idPartida !=="" ? parseInt(idPartida.split(' - ')[0]) : "";
        idUbicacion = idUbicacion !=="" ? parseInt(idUbicacion.split('-')[0]) :"";
        const fechaInicio = fecha !== null  ? fecha[0].format('YYYY-MM-DD') : "";
        const fechaFin = fecha !== null  ? fecha[1].format('YYYY-MM-DD') : "";
        console.log(idServicio, programa, idUbicacion, fechaInicio, fechaFin, page);

        const response = yield call(listaCancelacionOrdenesSPCall, token, idServicio, idPartida, idUbicacion, fechaInicio, fechaFin, programa, page);
        console.log( response);

        if (response.status == 200) {
            let listaCancelacionOrdenesSPBusqueda = response.data.data;
            const listaCancelacionOrdenesSPCantidad = response.data.cantidad;

            for(let orden of listaCancelacionOrdenesSPBusqueda) {
                const {programa,claveRutina,tipoRutina, actividades, rutina} = orden.programacionRutinasByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.rutinaByIdRutina;
                const {nomenclatura, nombre} = orden.programacionRutinasByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.rutinaByIdRutina.servicioByIdServicio;
                const categoria = orden.programacionRutinasByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.rutinaByIdRutina.servicioByIdServicio.grupoSerByIdGrupoSer.nombre;
                const partida = orden.programacionRutinasByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.rutinaByIdRutina?.partidaByIdPartida?.nombre ?? '-';

                orden.rutina = rutina;
                orden.programa = programa;
                orden.claveRutina = claveRutina;
                orden.tipoRutina = tipoRutina;
                orden.nomenclatura = nomenclatura;
                orden.nombreServicio = nombre;
                orden.categoria = categoria;
                orden.actividades = actividades;
                orden.partida = partida;

                const fechaOrden = orden.fechaAlta.split('T')[0].split('-').reverse().join('-');
                orden.fechaOrden = fechaOrden;


                orden.insumosLista = [];
                if(orden.insumosByOrden){
                    for (let insumo of orden.insumosByOrden) {
                        insumo.descripcion = insumo.productoByIdProducto.descripcion;
                        insumo.unidadMedida = insumo.productoByIdProducto.unidadMedidaByIdUnidadMedida.unidadMedida;
    
                        insumo.insumo = insumo.productoByIdProducto.idProducto + ' - ' + insumo.productoByIdProducto.nombre + ' - Unidad de medida: ' + insumo.unidadMedida;
                        orden.insumosLista.push(insumo.insumo);
                    }
                }
                


                orden.herramientasLista = [];
                if(orden.herramientasByOrden){
                    for (let insumo of orden.herramientasByOrden) {
                        insumo.descripcion = insumo.productoByIdProducto.descripcion;
                        insumo.unidadMedida = insumo.productoByIdProducto.unidadMedidaByIdUnidadMedida.unidadMedida;

                        insumo.insumo = insumo.productoByIdProducto.idProducto + ' - ' + insumo.productoByIdProducto.nombre + ' - Unidad de medida: ' + insumo.unidadMedida;
                        orden.herramientasLista.push(insumo.insumo);
                    }  
                }

                const personalByIdOrden = orden.personalByIdOrdenServicioPlanificado;

                orden.personalByIdOrden = [];
                if(personalByIdOrden){
                    for (let personal of personalByIdOrden) {
                        personal.nombreCompleto = personal.personalByIdPersonal.aMaterno ? personal.personalByIdPersonal.nombre + ' ' + personal.personalByIdPersonal.aPaterno + ' ' +  personal.personalByIdPersonal.aMaterno : personal.personalByIdPersonal.nombre + ' ' + personal.personalByIdPersonal.aPaterno;

                        let listaPersonalPush = personal.personalByIdPersonal.idPersonal + ' - ' + personal.nombreCompleto + ' - ' + personal.personalByIdPersonal.puesto;
                        orden.personalByIdOrden.push(listaPersonalPush);
                    }  
                }
            }
            yield put({ type: serviciosPlanificadosActions.LISTA_BUSQUEDA_ORDENES_SP_CANCELAR_SUCCESS, listaCancelacionOrdenesSPBusqueda, listaCancelacionOrdenesSPCantidad});
        }
        else {
            yield put ({type: serviciosPlanificadosActions.LISTA_BUSQUEDA_ORDENES_SP_CANCELAR_FAILURE});
        }

    } catch (error) {
        console.log(error);
        yield put ({type: serviciosPlanificadosActions.LISTA_BUSQUEDA_ORDENES_SP_CANCELAR_FAILURE});
    }
}
export function* listaOrdenesCancelacionSPBusquedaSaga() {
    yield takeLatest(serviciosPlanificadosActions.LISTA_BUSQUEDA_ORDENES_SP_CANCELAR_REQUEST, listaOrdenesCancelacionSPBusqueda);
}

function* detalleOrdenCancelacionSP(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {ordenCancelacionSPSeleccionado=""} = action;
        const {folioOrden} = ordenCancelacionSPSeleccionado;

        const response = yield call(detalleCancelacionOrdenSPCall, token, folioOrden);
        console.log(response);
        // const response = {status:200}
        if (response.status == 200) {
            let cancelacionOrdenesSPDetalle = response.data;
            let cancelacionOrdenesSPDetalleCantidad = response.data.length;

            for ( let orden of cancelacionOrdenesSPDetalle) {
                let fechaEjecucion = orden.idFechaRutina ? orden.fechasRutinasByIdOrdenServicio.fecha : orden.programacionRutinasByIdProgramacionRutina.fechaInicial;
                orden.fechaEjecucion = fechaEjecucion ? fechaEjecucion.split('T')[0].split('-').reverse().join('-') : "";
                orden.fechaReprogramacion = orden.fechasRutinasByIdOrdenServicio.fecha.split('T')[0].split('-').reverse().join('-');
                orden.fechaRealizacion = moment();

                const asignacion = orden.programacionRutinasByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado;

                if (asignacion.idActivo) {
                    //Ubicaciones Mantenimientos y Pruebas
                    orden.ubicacion = asignacion.activoByIdActivo.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.codigoArea + ', ' +  asignacion.activoByIdActivo.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                    orden.ubicacion += asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.unidadFuncionalByIdUnidadFuncional?.unidadFuncional  ? (', ' +  asignacion.activoByIdActivo?.ubicacionByIdUbicacion?.unidadFuncionalByIdUnidadFuncional?.unidadFuncional) :""; 
                    orden.ubicacion +=  asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.interiorFuncionalByIdInteriorFuncional?.interiorFuncional ? (', ' + asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.interiorFuncionalByIdInteriorFuncional?.interiorFuncional) : ""; 
                    orden.ubicacion +=  asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.subInteriorFuncionalByIdSubInteriorFuncional?.subInteriorFuncional ? (', ' + asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.subInteriorFuncionalByIdSubInteriorFuncional?.subInteriorFuncional) : ""; 

                    orden.sku = asignacion.activoByIdActivo.sku;
                    orden.codigoInterno = asignacion.activoByIdActivo.codigoInterno;
                    orden.descripcion = asignacion.activoByIdActivo.activoCaractByIdActivoCaract.productoByIdProducto.nombre;

                } else {
                    //Ubicaciones Rutinas
                    orden.ubicacion = asignacion.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.codigoArea + ', ' +  asignacion.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                    orden.ubicacion += asignacion?.ubicacionByIdUbicacion?.unidadFuncionalByIdUnidadFuncional?.unidadFuncional  ? (', ' +  asignacion?.ubicacionByIdUbicacion?.unidadFuncionalByIdUnidadFuncional?.unidadFuncional) :""; 
                    orden.ubicacion +=  asignacion?.ubicacionByIdUbicacion?.interiorFuncionalByIdInteriorFuncional?.interiorFuncional ? (', ' + asignacion?.ubicacionByIdUbicacion?.interiorFuncionalByIdInteriorFuncional?.interiorFuncional) : ""; 
                    orden.ubicacion +=  asignacion?.ubicacionByIdUbicacion?.subInteriorFuncionalByIdSubInteriorFuncional?.subInteriorFuncional ? (', ' + asignacion?.ubicacionByIdUbicacion?.subInteriorFuncionalByIdSubInteriorFuncional?.subInteriorFuncional) : ""; 
                }
            }

            yield put({ type: serviciosPlanificadosActions.LISTA_DETALLE_CANCELACION_ORDEN_SP_SUCCESS, cancelacionOrdenesSPDetalle,cancelacionOrdenesSPDetalleCantidad});
        }
        else {
            yield put({ type: serviciosPlanificadosActions.LISTA_DETALLE_CANCELACION_ORDEN_SP_FAILURE});
        }
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPlanificadosActions.LISTA_DETALLE_CANCELACION_ORDEN_SP_FAILURE});
    }

} 
export function* detalleOrdenCancelacionSPSaga() {
    yield takeLatest(serviciosPlanificadosActions.LISTA_DETALLE_CANCELACION_ORDEN_SP_REQUEST, detalleOrdenCancelacionSP);
}

function* cancelarOrdenSP(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token')
        const {formulario="", ordenCancelacionSPSeleccionado="", busqueda={}, page = 1} = action;
        const {observaciones=""} = formulario;
        const {folioOrden=""} = ordenCancelacionSPSeleccionado;
        const {idServicio="", programa="", idUbicacion="", fecha=null} = busqueda;
        console.log(formulario, ordenCancelacionSPSeleccionado);
        
        const response = yield call (cancelarOrdenSPCall,token,folioOrden, observaciones);
        if ( response.status == 200) {
            const showModalCancelarOrdenSP = false;
            const showSuccessMsg = true;
            const textMessage = "La orden de servicio planificado fue cancelada con éxito.";
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "COSP" });
            yield put({ type: serviciosPlanificadosActions.CANCELAR_ORDEN_SP_SUCCESS});
            yield put({ type: serviciosPlanificadosActions.LISTA_BUSQUEDA_ORDENES_SP_CANCELAR_REQUEST, idServicio: idServicio, idUbicacion: idUbicacion, programa:programa, fecha:fecha, page: page});
            yield put({ type: serviciosPlanificadosActions.SHOW_MODAL_CANCELAR_ORDEN_SP, showModalCancelarOrdenSP});
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put ({ type: serviciosPlanificadosActions.CANCELAR_ORDEN_SP_FAILURE});
        }
    } catch (error) {
        console.log( error);
        yield put ({ type: serviciosPlanificadosActions.CANCELAR_ORDEN_SP_FAILURE});
    }
}
export function* cancelarOrdenSPSaga() {
    yield takeLatest(serviciosPlanificadosActions.CANCELAR_ORDEN_SP_REQUEST, cancelarOrdenSP);
}

// //Estatus Ordenes Servicios Planificados
function* editarInsumosEstatus(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idOrden = action.estatusOrdenSPSeleccionado.idOrdenServicioPlanificado;
        let data = [];
        if(action.formulario.insumos){
            for(let i=0; i<action.formulario.insumos.length; i++){
                const insumo = {
                    "folioOrden": action.estatusOrdenSPSeleccionado.folioOrden,
                    "idProducto": action.formulario.insumos[i].insumo.split(' - ')[0],
                    "cantidad": action.formulario.insumos[i].cantidad,
                    'tipo': 'Insumos',
                }
                data.push(insumo);
            }
        }
        
        if(action.formulario.herramientas){
            for(let i=0; i<action.formulario.herramientas.length; i++){
                const insumo = {
                    "folioOrden": action.estatusOrdenSPSeleccionado.folioOrden,
                    "idProducto": action.formulario.herramientas[i].insumo.split(' - ')[0],
                    "cantidad": action.formulario.herramientas[i].cantidad,
                    'tipo': 'Herramientas',
                }
                data.push(insumo);
            }
        }

        console.log('data');
        console.log(data);
        const response = yield call(editarInsumosHerramientasCall, token, idOrden, data);
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Los insumos fueron editados con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.EDITAR_INSUMOS_ESTATUS_SUCCESS });
            yield put({ type: serviciosPlanificadosActions.LISTA_BUSQUEDA_ORDENES_SP_CANCELAR_REQUEST, page: action.page, idServicio: action.busqueda.idServicio, idUbicacion: action.busqueda.idUbicacion, fecha: action.busqueda.fecha, programa: action.busqueda.programa });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.EDITAR_INSUMOS_ESTATUS_FAILURE })
        }
    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.EDITAR_INSUMOS_ESTATUS_FAILURE})
    }
}
export function* editarInsumosEstatusSaga(){
    yield takeLatest(serviciosPlanificadosActions.EDITAR_INSUMOS_ESTATUS_REQUEST, editarInsumosEstatus)
}

function* editarPersonalOrden(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        
        const idOrden = action.estatusOrdenSPSeleccionado.idOrdenServicioPlanificado;
        let data = [];
        for(let i in action.formulario.personal){
            let personal = {
                idPersonal: action.formulario.personal[i].split(' - ')[0],
            }
            data.push(personal);
        }
        console.log('data', data, idOrden);
        const response = yield call(editarPersonalOrdenCall, token, idOrden, data);
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "El personal fue editado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.EDITAR_PERSONAL_SP_SUCCESS });
            //yield put({ type: serviciosPlanificadosActions.LISTA_BUSQUEDA_ORDENES_SP_CANCELAR_REQUEST, page: action.page, idServicio: action.busqueda.idServicio, idUbicacion: action.busqueda.idUbicacion, fecha: action.busqueda.fecha, programa: action.busqueda.programa });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.EDITAR_PERSONAL_SP_FAILURE })
        }
    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.EDITAR_PERSONAL_SP_FAILURE})
    }
}
export function* editarPersonalOrdenSaga(){
    yield takeLatest(serviciosPlanificadosActions.EDITAR_PERSONAL_SP_REQUEST, editarPersonalOrden)
}


function* cambiarEstatusOrdenSP(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const {formulario={}, estatusOrdenSPSeleccionado="", listaTareas=[], tipoEstatus="", busqueda="", page=1} = action;
        const {folioOrden} = estatusOrdenSPSeleccionado;
        const {idServicio="", idPartida="", programa="", idUbicacion="", fecha=null} = busqueda;

        for (let tarea of listaTareas) {
            tarea.programacionRutinasByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado = null;
            const fechaReprogramacion = tarea.fechaReprogramacion.split('-').reverse().join('-');
            tarea.fechasRutinasByIdOrdenServicio.fecha = fechaReprogramacion;
            if(typeof tarea.fechaRealizacion === 'string'){
                let fecha = tarea.fechaRealizacion.split(' ')[0].split('-').reverse().join('-');
                tarea.fechaRealizacion = fecha + 'T' + tarea.fechaRealizacion.split(' ')[1];
            }
            else{
                tarea.fechaRealizacion = tarea.fechaRealizacion.format();
            }
        }

        const data = listaTareas;
        
        const formData = new FormData();
        if (formulario.rutaArchivo) {
            formData.append('file', formulario.rutaArchivo.file.originFileObj);
        }
        formData.append('ordenServPlanReprogramada', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        console.log('data', data, tipoEstatus, folioOrden);
        const response = yield call(cambiarEstatusOSPCall, token, folioOrden, tipoEstatus, formData);
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "El estatus de las tareas fueron cambiadas con éxito.";
            const showModalCambiarEstatusOrdenSP = false;
            const estatusOrdenSPSeleccionado = null;
            
            if(tipoEstatus === 'Realizada') {
                yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "CEOSP" });
            }
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.CAMBIAR_ESTATUS_ORDEN_SP_SUCCESS });
            yield put({ type: serviciosPlanificadosActions.LISTA_BUSQUEDA_ORDENES_SP_CANCELAR_REQUEST, idServicio: idServicio, idUbicacion: idUbicacion, programa:programa, fecha:fecha, page: page});
            yield put({ type: serviciosPlanificadosActions.SHOW_MODAL_CAMBIAR_ESTATUS_OSP, showModalCambiarEstatusOrdenSP,estatusOrdenSPSeleccionado, listaSinRepetir: [], listaSinRepetirHerramienta: []})
            
            let realizada = false;
            for(let tarea of action.listaTareas){
                if(tarea.fechasRutinasByIdOrdenServicio.estado !== 'Reprogramada'){
                    realizada = true;
                }
            }
            
            if(action.listaPersonal && realizada){
                yield put({ type: serviciosPlanificadosActions.EDITAR_PERSONAL_SP_REQUEST, formulario: action.listaPersonal, estatusOrdenSPSeleccionado: action.estatusOrdenSPSeleccionado});
            }
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.CAMBIAR_ESTATUS_ORDEN_SP_FAILURE })
        }
    } catch (error) {
        console.log('error', error)
        yield put({ type: serviciosPlanificadosActions.CAMBIAR_ESTATUS_ORDEN_SP_FAILURE})
    }
}
export function* cambiarEstatusOrdenSPSaga(){
    yield takeLatest(serviciosPlanificadosActions.CAMBIAR_ESTATUS_ORDEN_SP_REQUEST, cambiarEstatusOrdenSP)
}

//Vista Calendario
function* listaTareasVCBusqueda (action) {
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page -1;
        let {idServicio="", idPartida="", programa="", idUbicacion="", fecha=[]} = action;
        const busqueda = {idServicio, idPartida, idUbicacion, fecha, programa};
        idServicio = idServicio !=="" ? parseInt(idServicio.split('-')[0]) : "";
        idPartida = idPartida !=="" ? parseInt(idPartida.split('-')[0]) : "";
        idUbicacion = idUbicacion !=="" ? parseInt(idUbicacion.split('-')[0]) :"";
        const fechaInicio = fecha !== null  ? fecha[0].format('YYYY-MM-DD') : "";
        const fechaFin = fecha !== null  ? fecha[1].format('YYYY-MM-DD') : "";
        console.log(idServicio, idPartida, programa, idUbicacion, fechaInicio, fechaFin, page);

        const tareaSeleccionadaVC = action.tareaSeleccionadaVC;
        let programacionSeleccionadaVC = {};


        const response = yield call(listaVistaCalendarioSPCall, token, idServicio, idPartida, idUbicacion, fechaInicio, fechaFin, programa, page);
        console.log( response);

        if (response.status == 200) {
            let listaTareasVistaCalendarioBusqueda = response.data.data;
            const listaTareasVistaCalendarioBusquedaCantidad = response.data.cantidad;

            for(let tarea of listaTareasVistaCalendarioBusqueda) {
                const {programa,claveRutina,tipoRutina, actividades, rutina} = tarea.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.rutinaByIdRutina;
                const {nomenclatura, nombre} = tarea.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.rutinaByIdRutina.servicioByIdServicio;
                const categoria = tarea.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.rutinaByIdRutina.servicioByIdServicio.grupoSerByIdGrupoSer.nombre;
                tarea.rutina = rutina;
                tarea.programa = programa;
                tarea.claveRutina = claveRutina;
                tarea.tipoRutina = tipoRutina;
                tarea.nomenclatura = nomenclatura;
                tarea.nombreServicio = nombre;
                tarea.categoria = categoria;
                tarea.actividades = actividades;

                let semanasMes = {0:[], 1:[], 2:[], 3:[], 4:[], 5:[], 6:[],
                    7:[], 8:[], 9:[], 10:[], 11:[]};
                
                let tareasMes = {0:[], 1:[], 2:[], 3:[], 4:[], 5:[], 6:[],
                    7:[], 8:[], 9:[], 10:[], 11:[]};

                for (let evento of tarea.fechasRutinasByIdProgramacion) {
                    let fechaEjecucion = evento.fecha;
                    fechaEjecucion = fechaEjecucion.split('T')[0];
                    let fechaEjecucionCopy = fechaEjecucion.split('-').reverse().join('-');
                    evento.fechaEjecucion = fechaEjecucionCopy;
                    evento.fechaEjecucionM = moment(fechaEjecucionCopy, 'DD-MM-YYYY');
                    evento.fechaReprogramacion = moment(fechaEjecucionCopy, 'DD-MM-YYYY');
                    const mesEjecucion = moment(fechaEjecucion).month();
                    const semanaEjecucion = moment(fechaEjecucion).weeks();
                    
                    let mesArray = semanasMes[`${mesEjecucion}`];
                    mesArray.push(semanaEjecucion);
                    semanasMes[`${mesEjecucion}`] = mesArray;

                    let tareasMesArray = tareasMes[`${mesEjecucion}`];
                    tareasMesArray.push(evento);
                    tareasMes[`${mesEjecucion}`] = tareasMesArray;
                }
                tarea.semanasMes = semanasMes;
                tarea.tareasMes = tareasMes;

                const asignacion = tarea.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado;

                if (asignacion.idActivo) {
                    //Ubicaciones Mantenimientos y Pruebas
                    tarea.ubicacion = asignacion.activoByIdActivo.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.codigoArea + ', ' +  asignacion.activoByIdActivo.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                    tarea.ubicacion += asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.unidadFuncionalByIdUnidadFuncional?.unidadFuncional  ? (', ' +  asignacion.activoByIdActivo?.ubicacionByIdUbicacion?.unidadFuncionalByIdUnidadFuncional?.unidadFuncional) :""; 
                    tarea.ubicacion +=  asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.interiorFuncionalByIdInteriorFuncional?.interiorFuncional ? (', ' + asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.interiorFuncionalByIdInteriorFuncional?.interiorFuncional) : ""; 
                    tarea.ubicacion +=  asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.subInteriorFuncionalByIdSubInteriorFuncional?.subInteriorFuncional ? (', ' + asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.subInteriorFuncionalByIdSubInteriorFuncional?.subInteriorFuncional) : ""; 

                    tarea.sku = asignacion.activoByIdActivo.sku;
                    tarea.descripcion = asignacion.activoByIdActivo.activoCaractByIdActivoCaract.productoByIdProducto.nombre;
                    tarea.partida = asignacion?.rutinaByIdRutina?.partidaByIdPartida ? asignacion?.rutinaByIdRutina?.partidaByIdPartida.nomenclatura : "-";

                } else {
                    //Ubicaciones Rutinas
                    tarea.ubicacion = asignacion.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.codigoArea + ', ' +  asignacion.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                    tarea.ubicacion += asignacion?.ubicacionByIdUbicacion?.unidadFuncionalByIdUnidadFuncional?.unidadFuncional  ? (', ' +  asignacion?.ubicacionByIdUbicacion?.unidadFuncionalByIdUnidadFuncional?.unidadFuncional) :""; 

                    tarea.ubicacion +=  asignacion?.ubicacionByIdUbicacion?.interiorFuncionalByIdInteriorFuncional?.interiorFuncional ? (', ' + asignacion?.ubicacionByIdUbicacion?.interiorFuncionalByIdInteriorFuncional?.interiorFuncional) : ""; 

                    tarea.ubicacion +=  asignacion?.ubicacionByIdUbicacion?.subInteriorFuncionalByIdSubInteriorFuncional?.subInteriorFuncional ? (', ' + asignacion?.ubicacionByIdUbicacion?.subInteriorFuncionalByIdSubInteriorFuncional?.subInteriorFuncional) : ""; 

                    tarea.partida = asignacion?.rutinaByIdRutina?.partidaByIdPartida ? asignacion?.rutinaByIdRutina?.partidaByIdPartida.nomenclatura : "-";
                }
            }

            yield put({ type: serviciosPlanificadosActions.LISTA_BUSQUEDA_VISTA_CALENDARIO_SUCCESS, listaTareasVistaCalendarioBusqueda, listaTareasVistaCalendarioBusquedaCantidad});
        }
        else {
            yield put ({type: serviciosPlanificadosActions.LISTA_BUSQUEDA_VISTA_CALENDARIO_FAILURE});
        }

    } catch (error) {
        console.log(error);
        yield put ({type: serviciosPlanificadosActions.LISTA_BUSQUEDA_VISTA_CALENDARIO_FAILURE});
    }
}
export function* listaTareasVCBusquedaSaga() {
    yield takeLatest(serviciosPlanificadosActions.LISTA_BUSQUEDA_VISTA_CALENDARIO_REQUEST, listaTareasVCBusqueda);
}

function* reprogramarTareaVC(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const {formulario={}, tareaSeleccionadaVC="", busqueda="", page=1} = action;
        const {idServicio="", programa="", idUbicacion="", fecha=null} = busqueda;
        const {idProgramacionRutina="", idFechaRutina=""} = tareaSeleccionadaVC;
        const fechaReprogramacion = formulario.fechaReprogramacion.format('YYYY-MM-DD');
        const observacion = formulario.observaciones;

        const fechaRutinaParam = "?idFechaRutina=" + idFechaRutina;

        const response = yield call(reprogramarTareaCall, token, idProgramacionRutina, fechaRutinaParam, observacion, fechaReprogramacion);
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "La reprogramación de la tarea fue realizada con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "TRVC" });
            yield put({ type: serviciosPlanificadosActions.REPROGRAMAR_TAREA_VISTA_CALENDARIO_SUCCESS });
            if(action.calendarioProgramacion){
                yield put({ type: serviciosPlanificadosActions.CALENDARIO_PROGRAMACION_REQUEST, busqueda: action.busqueda });
                yield put({ type: serviciosPlanificadosActions.SHOW_MODAL_CALENDARIO_DETALLE, showModalCalendarioDetalle: false});
            }
            else{
                yield put({ type: serviciosPlanificadosActions.LISTA_BUSQUEDA_VISTA_CALENDARIO_REQUEST, idServicio: idServicio, idUbicacion: idUbicacion, programa:programa, fecha:fecha, page: page, tareaSeleccionadaVC: tareaSeleccionadaVC});

            }
            yield put({ type: serviciosPlanificadosActions.SHOW_MODAL_REPROGRAMAR_TAREA_VC, showModalReprogramarTareaVC: false});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.REPROGRAMAR_TAREA_VISTA_CALENDARIO_FAILURE })
        }
    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.REPROGRAMAR_TAREA_VISTA_CALENDARIO_FAILURE})
    }
}
export function* reprogramarTareaVCSaga(){
    yield takeLatest(serviciosPlanificadosActions.REPROGRAMAR_TAREA_VISTA_CALENDARIO_REQUEST, reprogramarTareaVC)
}

function* reprogramarTareaMultiple(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const data = [];
        if(action.listaIdsTareas){
            for(let i in action.listaIdsTareas){
                for(let x in action.calendarioDetalle){
                    if(action.listaIdsTareas[i] === action.calendarioDetalle[x].idProgramacionRutina){
                        let tarea = {
                            'idProgramacionRutina': action.calendarioDetalle[x].idProgramacionRutina,
                            'idFechaRutina': action.calendarioDetalle[x].idFechaRutina,
                            'observacion': action.formulario.observacion,
                            'fechaReprogramacion': action.formulario.fechaReprogramacion.format('YYYY-MM-DD'),
                            'rutina': action.calendarioDetalle[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.rutinaByIdRutina.rutina,
                            'ubicacion': action.calendarioDetalle[x].ubicacion
                        }
                        data.push(tarea);
                    }
                }
            }
        }
        else{
            for(let x in action.calendarioDetalle){
                let tarea = {
                    'idProgramacionRutina': action.calendarioDetalle[x].idProgramacionRutina,
                    'idFechaRutina': action.calendarioDetalle[x].idFechaRutina,
                    'observacion': action.formulario.observacion,
                    'fechaReprogramacion': action.formulario.fechaReprogramacion,
                    'rutina': action.calendarioDetalle[x].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.rutinaByIdRutina.rutina,
                    'ubicacion': action.calendarioDetalle[x].ubicacion
                }
                data.push(tarea);
            }
        }
        console.log(`data`, data);
        const response = yield call(reprogramarTareaMultipleCall, token, data);
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "La reprogramación de las tareas fue realizada con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            if(action.listaIdsTareas){
                yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "reprogramarTareas" });
            }
            else{
                yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "reprogramarTareasDragDrop" });
            }
            
            yield put({ type: serviciosPlanificadosActions.REPROGRAMAR_TAREA_MULTIPLE_SUCCESS });

            yield put({ type: serviciosPlanificadosActions.CALENDARIO_PROGRAMACION_REQUEST, busqueda: action.busqueda });
            yield put({ type: serviciosPlanificadosActions.SHOW_MODAL_CALENDARIO_DETALLE, showModalCalendarioDetalle: false});
            yield put({ type: serviciosPlanificadosActions.SHOW_MODAL_REPROGRAMAR_DRAG_DROP, showModalReprogramarDragDrop: false});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.REPROGRAMAR_TAREA_MULTIPLE_FAILURE })
        }
    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.REPROGRAMAR_TAREA_MULTIPLE_FAILURE})
    }
}
export function* reprogramarTareaMultipleSaga(){
    yield takeLatest(serviciosPlanificadosActions.REPROGRAMAR_TAREA_MULTIPLE_REQUEST, reprogramarTareaMultiple)
}

//Reporte Servicios Planificados
function* reporteSPBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let { servicio=[],programa, fecha , tipo, estatus, partida} = action.formulario;
        let idsServicios = 'idsServicios=';
        let idsPartidas = '&idsPartidas=';
        const listaServiciosActivosF = action.listaServiciosActivosF;
        const page = action.page-1;
        const fechaInicio = fecha ? fecha[0].format('YYYY-MM-DD') : "";
        const fechaFin = fecha ? fecha[1].format('YYYY-MM-DD') : "";
        
        if (servicio[0] === 'todos') {
            // servicio = listaServiciosActivosF.map(item => item.idServicio);
            for(let i in listaServiciosActivosF){ idsServicios += listaServiciosActivosF[i].idServicio + ',' }
        } else {
            // servicio = servicio.map(item => parseInt(item.split('-')[0]));
            for(let i in servicio){ idsServicios += servicio[i].split(' - ')[0] + ',' }
        }

        if(partida){
            for(let i in partida){ idsPartidas += partida[i].split(' - ')[0] + ',' }
        }
        
        console.log( programa, fechaInicio, fechaFin, tipo, estatus, page, servicio, idsServicios);

        const response = yield call(reporteSPBusquedaCall, token, programa, fechaInicio, fechaFin, tipo, estatus, page, idsServicios, idsPartidas);
        console.log(response);

        if(response.status === 200) {
            let listaReporteSPBusqueda = response.data.data;
            let listaReporteSPBusquedaCantidad = response.data.cantidad;

            if(tipo === 'Tarea') {
                for (let tarea of listaReporteSPBusqueda) {
                    const {tipoRutina, rutina, programa} = tarea.programacionRutinaByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.rutinaByIdRutina;
                    const servicio = tarea.programacionRutinaByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.rutinaByIdRutina.servicioByIdServicio.nombre;
                    const categoria = tarea.programacionRutinaByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.rutinaByIdRutina.servicioByIdServicio.grupoSerByIdGrupoSer.nombre;
                    const fechaEjecucion = tarea.fecha.split('T')[0].split('-').reverse().join('-');
                    const partida = tarea.programacionRutinaByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.rutinaByIdRutina?.partidaByIdPartida?.nombre ?? '-';
                    
                    tarea.programa = programa;
                    tarea.tipoRutina = tipoRutina;
                    tarea.nombreRutina = rutina;
                    tarea.servicio = servicio;
                    tarea.categoriaServicio = categoria;
                    tarea.fechaEjecucion = fechaEjecucion;
                    tarea.partida = partida;

                    const asignacion = tarea.programacionRutinaByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado;

                    if (asignacion.idActivo) {
                        //Ubicaciones Mantenimientos y Pruebas
                        tarea.ubicacion = asignacion.activoByIdActivo.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.codigoArea + ', ' + asignacion.activoByIdActivo.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                        tarea.ubicacion += asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.unidadFuncionalByIdUnidadFuncional?.unidadFuncional ? (', ' + asignacion.activoByIdActivo?.ubicacionByIdUbicacion?.unidadFuncionalByIdUnidadFuncional?.unidadFuncional) : "";
                        tarea.ubicacion += asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.interiorFuncionalByIdInteriorFuncional?.interiorFuncional ? (', ' + asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.interiorFuncionalByIdInteriorFuncional?.interiorFuncional) : "";
                        tarea.ubicacion += asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.subInteriorFuncionalByIdSubInteriorFuncional?.subInteriorFuncional ? (', ' + asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.subInteriorFuncionalByIdSubInteriorFuncional?.subInteriorFuncional) : "";

                        tarea.codigoEquipo = asignacion.activoByIdActivo.codigoEquipo;
                        tarea.descripcion = asignacion.activoByIdActivo.activoCaractByIdActivoCaract.productoByIdProducto.nombre;

                    } else {
                        //Ubicaciones Rutinas
                        tarea.ubicacion = asignacion.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.codigoArea + ', ' + asignacion.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                        tarea.ubicacion += asignacion?.ubicacionByIdUbicacion?.unidadFuncionalByIdUnidadFuncional?.unidadFuncional ? (', ' + asignacion?.ubicacionByIdUbicacion?.unidadFuncionalByIdUnidadFuncional?.unidadFuncional) : "";
                        tarea.ubicacion += asignacion?.ubicacionByIdUbicacion?.interiorFuncionalByIdInteriorFuncional?.interiorFuncional ? (', ' + asignacion?.ubicacionByIdUbicacion?.interiorFuncionalByIdInteriorFuncional?.interiorFuncional) : "";
                        tarea.ubicacion += asignacion?.ubicacionByIdUbicacion?.subInteriorFuncionalByIdSubInteriorFuncional?.subInteriorFuncional ? (', ' + asignacion?.ubicacionByIdUbicacion?.subInteriorFuncionalByIdSubInteriorFuncional?.subInteriorFuncional) : "";
                    }   
                }
            } else {
                //Orden
                for (let orden of listaReporteSPBusqueda) {
                    const {tipoRutina, rutina, programa} = orden.programacionRutinasByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.rutinaByIdRutina;
                    const servicio = orden.programacionRutinasByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.rutinaByIdRutina.servicioByIdServicio.nombre;
                    const categoria = orden.programacionRutinasByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.rutinaByIdRutina.servicioByIdServicio.grupoSerByIdGrupoSer.nombre;
                    const partida = orden.programacionRutinasByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.rutinaByIdRutina?.partidaByIdPartida?.nombre ?? '-';
                    
                    orden.programa = programa;
                    orden.tipoRutina = tipoRutina;
                    orden.nombreRutina = rutina;
                    orden.servicio = servicio;
                    orden.categoriaServicio = categoria;
                    orden.estado = orden.estadoOrden;
                    orden.partida = partida;

                    const asignacion = orden.programacionRutinasByIdProgramacionRutina.asignacionServicioPlanificadoByIdAsignacionServicioPlanificado;

                    if (asignacion.idActivo) {
                        //Ubicaciones Mantenimientos y Pruebas
                        orden.ubicacion = asignacion.activoByIdActivo.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.codigoArea + ', ' + asignacion.activoByIdActivo.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                        orden.ubicacion += asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.unidadFuncionalByIdUnidadFuncional?.unidadFuncional ? (', ' + asignacion.activoByIdActivo?.ubicacionByIdUbicacion?.unidadFuncionalByIdUnidadFuncional?.unidadFuncional) : "";
                        orden.ubicacion += asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.interiorFuncionalByIdInteriorFuncional?.interiorFuncional ? (', ' + asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.interiorFuncionalByIdInteriorFuncional?.interiorFuncional) : "";
                        orden.ubicacion += asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.subInteriorFuncionalByIdSubInteriorFuncional?.subInteriorFuncional ? (', ' + asignacion?.activoByIdActivo?.ubicacionByIdUbicacion?.subInteriorFuncionalByIdSubInteriorFuncional?.subInteriorFuncional) : "";

                        orden.codigoEquipo = asignacion.activoByIdActivo.codigoEquipo;
                        orden.descripcion = asignacion.activoByIdActivo.activoCaractByIdActivoCaract.productoByIdProducto.nombre;

                    } else {
                        //Ubicaciones Rutinas
                        orden.ubicacion = asignacion.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.codigoArea + ', ' + asignacion.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                        orden.ubicacion += asignacion?.ubicacionByIdUbicacion?.unidadFuncionalByIdUnidadFuncional?.unidadFuncional ? (', ' + asignacion?.ubicacionByIdUbicacion?.unidadFuncionalByIdUnidadFuncional?.unidadFuncional) : "";
                        orden.ubicacion += asignacion?.ubicacionByIdUbicacion?.interiorFuncionalByIdInteriorFuncional?.interiorFuncional ? (', ' + asignacion?.ubicacionByIdUbicacion?.interiorFuncionalByIdInteriorFuncional?.interiorFuncional) : "";
                        orden.ubicacion += asignacion?.ubicacionByIdUbicacion?.subInteriorFuncionalByIdSubInteriorFuncional?.subInteriorFuncional ? (', ' + asignacion?.ubicacionByIdUbicacion?.subInteriorFuncionalByIdSubInteriorFuncional?.subInteriorFuncional) : "";
                    }   
                }
            }
            yield put({ type: serviciosPlanificadosActions.LISTA_BUSQUEDA_REPORTE_SP_SUCCESS, listaReporteSPBusqueda, listaReporteSPBusquedaCantidad});
        }
        else {
            yield put ({type: serviciosPlanificadosActions.LISTA_BUSQUEDA_REPORTE_SP_FAILURE});
        }

    } catch (error) {
        console.log(error);
        yield put ({type: serviciosPlanificadosActions.LISTA_BUSQUEDA_REPORTE_SP_FAILURE});
        yield put ({type: serviciosPlanificadosActions.LISTA_BUSQUEDA_REPORTE_SP_DESCARGA_FAILURE});
    }
}
export function* reporteSPBusquedaSaga() {
    yield takeLatest(serviciosPlanificadosActions.LISTA_BUSQUEDA_REPORTE_SP_REQUEST, reporteSPBusqueda)
}

function* descargarReportePdf(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let { servicio=[],programa, fecha , tipo, estatus, partida} = action.formulario;
        const listaServiciosActivosF = action.listaServiciosActivosF;
        const fechaInicio = fecha ? fecha[0].format('YYYY-MM-DD') : "";
        const fechaFin = fecha ? fecha[1].format('YYYY-MM-DD') : "";
        
        // if (servicio[0] === 'todos') {
        //     servicio = listaServiciosActivosF.map(item => item.idServicio);
        // } else {
        //     servicio = servicio.map(item => parseInt(item.split('-')[0]));
        // }

        let idsServicios = 'idsServicios=';
        let idsPartidas = '&idsPartidas=';
        
        if (servicio[0] === 'todos') {
            // servicio = listaServiciosActivosF.map(item => item.idServicio);
            for(let i in listaServiciosActivosF){ idsServicios += listaServiciosActivosF[i].idServicio + ',' }
        } else {
            // servicio = servicio.map(item => parseInt(item.split('-')[0]));
            for(let i in servicio){ idsServicios += servicio[i].split(' - ')[0] + ',' }
        }

        if(partida){
            for(let i in partida){ idsPartidas += partida[i].split(' - ')[0] + ',' }
        }
        
        console.log( programa, fechaInicio, fechaFin, tipo, estatus, idsServicios, idsPartidas);

        const response = yield call(reporteSPPDFCall, token, programa, fechaInicio, fechaFin, tipo, estatus, idsServicios, idsPartidas);
        console.log('descargaReportePDF', response);
        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "El reporte se descargó de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "ReporteServiciosPlanificados" + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.DESCARGAR_PDF_REPORTE_SP_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = "Error al exportar los servicios planificados";
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.DESCARGAR_PDF_REPORTE_SP_FAILURE });
        }
        

    } catch (error) {
        console.log("error descargaReportePDF",error)
        yield put({ type: serviciosPlanificadosActions.DESCARGAR_PDF_REPORTE_SP_FAILURE});
    }
}
export function* descargarReportePdfSaga(){
    yield takeLatest( serviciosPlanificadosActions.DESCARGAR_PDF_REPORTE_SP_REQUEST, descargarReportePdf)
}

function* descargarReporteExcel(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let { servicio=[],programa, fecha , tipo, estatus, partida} = action.formulario;
        const listaServiciosActivosF = action.listaServiciosActivosF;
        const fechaInicio = fecha ? fecha[0].format('YYYY-MM-DD') : "";
        const fechaFin = fecha ? fecha[1].format('YYYY-MM-DD') : "";
        
        // if (servicio[0] === 'todos') {
        //     servicio = listaServiciosActivosF.map(item => item.idServicio);
        // } else {
        //     servicio = servicio.map(item => parseInt(item.split('-')[0]));
        // }

        let idsServicios = 'idsServicios=';
        let idsPartidas = '&idsPartidas=';
        
        if (servicio[0] === 'todos') {
            // servicio = listaServiciosActivosF.map(item => item.idServicio);
            for(let i in listaServiciosActivosF){ idsServicios += listaServiciosActivosF[i].idServicio + ',' }
        } else {
            // servicio = servicio.map(item => parseInt(item.split('-')[0]));
            for(let i in servicio){ idsServicios += servicio[i].split(' - ')[0] + ',' }
        }

        if(partida){
            for(let i in partida){ idsPartidas += partida[i].split(' - ')[0] + ',' }
        }
        
        console.log( programa, fechaInicio, fechaFin, tipo, estatus, idsServicios, idsPartidas);
        const response = yield call(reporteSPExcelCall, token, programa, fechaInicio, fechaFin, tipo, estatus, idsServicios, idsPartidas);
        console.log('descargaReporteExcel', response);

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = "El reporte se descargó de forma correcta";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "ReporteServiciosPlanificados" + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.DESCARGAR_EXCEL_REPORTE_SP_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = "Error al exportar los servicios planificados";
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.DESCARGAR_EXCEL_REPORTE_SP_FAILURE });
        }

    } catch (error) {
        console.log("error descargaReporteExcel",error)
        yield put({ type: serviciosPlanificadosActions.DESCARGAR_EXCEL_REPORTE_SP_FAILURE});
    }
}
export function* descargarReporteExcelSaga(){
    yield takeLatest( serviciosPlanificadosActions.DESCARGAR_EXCEL_REPORTE_SP_REQUEST, descargarReporteExcel)
}

//CALENDARIO DE PROGRAMACIÓN
function* listaCalendarioDeProgramacion(action) {
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        let fechaInicio = 'fechaInicio=' + moment().clone().startOf('month').format('YYYY-MM-DD');
        let fechaFin = '&fechaFin=' + moment().clone().endOf('month').format('YYYY-MM-DD'); 
        const idGrupoSer = '&idGrupoSer=' + action.busqueda.idGrupoSer;
        let tipoPrograma = '';
        let idServicio = '';
        let idAreaFuncional = '';
        let idPartida = '';
        if(action.busqueda.tipoPrograma){
            tipoPrograma = '&tipoPrograma=' + action.busqueda.tipoPrograma;
        }
        if(action.busqueda.idServicio){
            idServicio = '&idServicio=' + action.busqueda.idServicio;
        }
        if(action.busqueda.idPartida){
            idPartida = '&idPartida=' + action.busqueda.idPartida;
        }
        if(action.busqueda.idAreaFuncional){
            idAreaFuncional = '&idAreaFuncional=' + action.busqueda.idAreaFuncional.split(' - ')[0];
        }
        if(action.busqueda.mesVista){
            let fechaActual = action.busqueda.mesVista.format('YYYY-MM-DD');
            if(parseInt(fechaActual.split('-')[2]) !== 1){
                if(parseInt(fechaActual.split('-')[1]) === 12){
                    fechaActual = (parseInt(fechaActual.split('-')[0]) + 1) + '-01-01';
                }
                else{
                    fechaActual = parseInt(fechaActual.split('-')[0]) + '-' + (parseInt(fechaActual.split('-')[1]) + 1) + '-01';
                }
            }
            else{
                fechaActual = parseInt(fechaActual.split('-')[0]) + '-' + parseInt(fechaActual.split('-')[1]) + '-01';
            }
            
            fechaInicio = 'fechaInicio=' + fechaActual;
            console.log(moment(fechaActual.split('-')[0] + '-' + fechaActual.split('-')[1] + '-01'))
            fechaFin = '&fechaFin=' + fechaActual.split('-')[0] + '-' + fechaActual.split('-')[1] + '-31' ;
        }
        

        console.log(fechaInicio, fechaFin, idGrupoSer, tipoPrograma, idServicio, idPartida, idAreaFuncional)
        const response = yield call (calendarioDeProgramacionCall, token, fechaInicio, fechaFin, idGrupoSer, tipoPrograma, idServicio, idPartida, idAreaFuncional);
        console.log(response);
    
        if (response.status == 200) {
            let listaCalendarioProgramacion = response.data;
            const listaCalendarioInicial = [];
            for(let i in listaCalendarioProgramacion){
                if(listaCalendarioProgramacion[i].calendarioProgramacionRutinaResponse[0]){
                    for(let x in listaCalendarioProgramacion[i].calendarioProgramacionRutinaResponse){
                        let igualesTodos = true;
                        let estado = null;
                        
                        for(let y in listaCalendarioProgramacion[i].calendarioProgramacionRutinaResponse[x].calendarioProgramacionQueryResponse){
                            if(estado){
                                if(estado !== listaCalendarioProgramacion[i].calendarioProgramacionRutinaResponse[x].calendarioProgramacionQueryResponse[y].estadoRutina){
                                    igualesTodos = false;
                                }
                            }
                            else{
                                estado = listaCalendarioProgramacion[i].calendarioProgramacionRutinaResponse[x].calendarioProgramacionQueryResponse[y].estadoRutina;
                            }
                        }
                        for(let y in listaCalendarioProgramacion[i].calendarioProgramacionRutinaResponse[x].calendarioProgramacionQueryResponse){
                            if(igualesTodos){
                                listaCalendarioProgramacion[i].calendarioProgramacionRutinaResponse[x].color = listaCalendarioProgramacion[i].calendarioProgramacionRutinaResponse[x].calendarioProgramacionQueryResponse[y].estadoRutina === 'En proceso' ? 'success': listaCalendarioProgramacion[i].calendarioProgramacionRutinaResponse[x].calendarioProgramacionQueryResponse[y].estadoRutina === 'Realizada' ? 'default' : 'warning';
                            }
                            else{
                                listaCalendarioProgramacion[i].calendarioProgramacionRutinaResponse[x].color = 'processing';
                            }
                        }
                        let rutina = listaCalendarioProgramacion[i].calendarioProgramacionRutinaResponse[x];
                        rutina['title'] = listaCalendarioProgramacion[i].calendarioProgramacionRutinaResponse[x].rutina;
                        rutina['date'] = listaCalendarioProgramacion[i].fecha;
                        rutina['fecha'] = listaCalendarioProgramacion[i].fecha;
                        listaCalendarioInicial.push(rutina)
                    }
                }
            }
            yield put({ type: serviciosPlanificadosActions.CALENDARIO_PROGRAMACION_SUCCESS, listaCalendarioProgramacion, listaCalendarioInicial })
        }
        else {
            yield put({ type: serviciosPlanificadosActions.CALENDARIO_PROGRAMACION_FAILURE});
        }
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPlanificadosActions.CALENDARIO_PROGRAMACION_FAILURE});
    }

}
export function* listaCalendarioDeProgramacionSaga() {
    yield takeLatest(serviciosPlanificadosActions.CALENDARIO_PROGRAMACION_REQUEST, listaCalendarioDeProgramacion);
}

function* calendarioDetalle(action) {
    try{
        console.log(action);
        const token = sessionStorage.getItem('token');
        const data = []
        for(let i in action.tareaSeleccionada.calendarioProgramacionQueryResponse){
            // if(action.busqueda?.idAreaFuncional){
            //     if(action.busqueda.idAreaFuncional.split(': ')[1] === action.tareaSeleccionada.calendarioProgramacionQueryResponse[i].areaFuncional){
            //         data.push(action.tareaSeleccionada.calendarioProgramacionQueryResponse[i].idProgramacionRutina)
            //     }
            // }
            // else{
                data.push(action.tareaSeleccionada.calendarioProgramacionQueryResponse[i].idProgramacionRutina)
            // }
        }

        console.log('data de detalle del calendario', data)
        const response = yield call (calendarioDetalleCall, token, data);
        console.log('calendario detalle', response);
    
        if (response.status == 200) {
            let calendarioDetalle = response.data;
            let noDragDrop = false;
            for(let i in calendarioDetalle){
                calendarioDetalle[i].estado = action.tareaSeleccionada.calendarioProgramacionQueryResponse[i].estadoRutina;
                calendarioDetalle[i].hora = calendarioDetalle[i].hora.split(':')[0] + ':' + calendarioDetalle[i].hora.split(':')[1];
                if(calendarioDetalle[i].estado === 'Realizada'){
                    noDragDrop = true;
                }
                calendarioDetalle[i].color = calendarioDetalle[i].estado === 'En proceso' ? 'success': calendarioDetalle[i].estado === 'Realizada' ? 'default' : 'warning';
                calendarioDetalle[i].fechaEjecucion = action.tareaSeleccionada.calendarioProgramacionQueryResponse[i].fechaRutina.split('-').reverse().join('-');
                calendarioDetalle[i].fechaEjecucionM = moment(action.tareaSeleccionada.calendarioProgramacionQueryResponse[i].fechaRutina);
                calendarioDetalle[i].fechaReprogramacion = moment(action.tareaSeleccionada.calendarioProgramacionQueryResponse[i].fechaRutina);
                calendarioDetalle[i].idFechaRutina = action.tareaSeleccionada.calendarioProgramacionQueryResponse[i].idFechaRutina;

                if(calendarioDetalle[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo){
                    calendarioDetalle[i].activo = calendarioDetalle[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.activoCaractByIdActivoCaract.productoByIdProducto.nombre;

                    calendarioDetalle[i].codigoInterno = calendarioDetalle[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.codigoInterno;

                    calendarioDetalle[i].ubicacion = calendarioDetalle[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.ubicacionByIdUbicacion.claveUbicacion + ' ' + calendarioDetalle[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;

                    for(let j in action.listaSubfamilias){
                        if(calendarioDetalle[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.activoCaractByIdActivoCaract.idSubFamilia === action.listaSubfamilias[j].idSubFamilia){
                            calendarioDetalle[i].subFamilia = action.listaSubfamilias[j].subFamilia;
                        }

                        for(let k in action.listaFamiliasDeActivos){
                            if(action.listaFamiliasDeActivos[k].idFamilia === action.listaSubfamilias[j].idFamilia){
                                calendarioDetalle[i].familia = action.listaFamiliasDeActivos[k].familia;
                            }
                        }
                    } 

                    if(calendarioDetalle[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional){
                        calendarioDetalle[i].ubicacion += ', ' + calendarioDetalle[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                    }
                    if(calendarioDetalle[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional){
                        calendarioDetalle[i].ubicacion += ', ' + calendarioDetalle[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional;
                    }
                    if(calendarioDetalle[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional){
                        calendarioDetalle[i].ubicacion += ', ' + calendarioDetalle[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.activoByIdActivo.ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                    }
                }
                else{
                    calendarioDetalle[i].activo = '-';
                    calendarioDetalle[i].codigoInterno = '-';
                    calendarioDetalle[i].subFamilia = '-';
                    calendarioDetalle[i].familia = '-';
                    calendarioDetalle[i].ubicacion = calendarioDetalle[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.claveUbicacion + ' ' + calendarioDetalle[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                    if(calendarioDetalle[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional){
                        calendarioDetalle[i].ubicacion += ', ' + calendarioDetalle[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                    }
                    if(calendarioDetalle[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional){
                        calendarioDetalle[i].ubicacion += ', ' + calendarioDetalle[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional;
                    }
                    if(calendarioDetalle[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional){
                        calendarioDetalle[i].ubicacion += ', ' + calendarioDetalle[i].asignacionServicioPlanificadoByIdAsignacionServicioPlanificado.ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                    }
                }
            }
            yield put({ type: serviciosPlanificadosActions.CALENDARIO_DETALLE_SUCCESS, calendarioDetalle, noDragDrop })
        }
        else {
            yield put({ type: serviciosPlanificadosActions.CALENDARIO_DETALLE_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPlanificadosActions.CALENDARIO_DETALLE_FAILURE });
    }

}
export function* calendarioDetalleSaga() {
    yield takeLatest(serviciosPlanificadosActions.CALENDARIO_DETALLE_REQUEST, calendarioDetalle);
}

//Carga Servicios Planificados

function* listaActivoArchivosEsperaSP(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let {estado='Revision'} = action;
        
        let response = yield call(listaArchivosCargaSPCall, token, estado, page);
        console.log(response);

        if (response.status == 200) {
            let listaArchivosEsperaSP = response.data.data;
            const listaArchivosEsperaSPCantidad = response.data.cantidad;
            for(let i=0; i<listaArchivosEsperaSP.length; i++){
                listaArchivosEsperaSP[i].fechaAlta = listaArchivosEsperaSP[i].fechaAlta.split('T')[0].split('-').reverse().join('-') + ' ' + listaArchivosEsperaSP[i].fechaAlta.split('T')[1].split('.')[0]
            }
            
            yield put({ type: serviciosPlanificadosActions.GET_ARCHIVOS_ESPERA_SP_SUCCESS, listaArchivosEsperaSP, listaArchivosEsperaSPCantidad });
        }
        else {
            yield put({ type: serviciosPlanificadosActions.GET_ARCHIVOS_ESPERA_SP_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPlanificadosActions.GET_ARCHIVOS_ESPERA_SP_FAILURE });
    }
}
export function* listaActivoArchivosEsperaSPSaga() {
    yield takeLatest(serviciosPlanificadosActions.GET_ARCHIVOS_ESPERA_SP_REQUEST, listaActivoArchivosEsperaSP);
}

function* subirArchivoSP(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {page=1, formulario={}} = action;

        const servicio = formulario.servicio ? parseInt(formulario.servicio) : null;

        const formData = new FormData();
        formData.append('file', formulario.csv.file.originFileObj);
        formData.append('servicio', servicio);
        formData.append('tipoArchivo', formulario.tipoDocumento);
        
        let response = yield call(cargaArchivoSPCall, token, formData);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El archivo fue cargado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'cargarArchivoSP' });
            yield put({ type: serviciosPlanificadosActions.GET_ARCHIVOS_ESPERA_SP_REQUEST, page });
            yield put({ type: serviciosPlanificadosActions.SUBIR_ARCHIVO_SP_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.SUBIR_ARCHIVO_SP_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPlanificadosActions.SUBIR_ARCHIVO_SP_FAILURE });
    }
}
export function* subirArchivoSPSaga() {
    yield takeLatest(serviciosPlanificadosActions.SUBIR_ARCHIVO_SP_REQUEST, subirArchivoSP);
}

function* borrarArchivoSP(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idArchivosSPMasivos = action.idArchivosSPMasivos;
        const page = action.page;

        let response = yield call(eliminarCargaSPCall, token, idArchivosSPMasivos);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El archivo fue cancelado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.GET_ARCHIVOS_ESPERA_SP_REQUEST, page });
            yield put({ type: serviciosPlanificadosActions.ELIMINAR_ARCHIVO_SP_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.ELIMINAR_ARCHIVO_SP_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPlanificadosActions.ELIMINAR_ARCHIVO_SP_FAILURE });
    }
}
export function* borrarArchivoSPSaga() {
    yield takeLatest(serviciosPlanificadosActions.ELIMINAR_ARCHIVO_SP_REQUEST, borrarArchivoSP);
}

function* procesarActivoArchivoSP(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {idArchivosSPMasivos=null, page=1} = action;

        let response = yield call(procesarCargaSPCall, token, idArchivosSPMasivos);
        console.log(response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El archivo fue procesado con éxito, favor de revisar en estado 'Finalizado'.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.GET_ARCHIVOS_ESPERA_SP_REQUEST, page });
            yield put({ type: serviciosPlanificadosActions.PROCESAR_ARCHIVO_SP_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.PROCESAR_ARCHIVO_SP_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPlanificadosActions.PROCESAR_ARCHIVO_SP_FAILURE });
    }
}
export function* procesarActivoArchivoSPSaga() {
    yield takeLatest(serviciosPlanificadosActions.PROCESAR_ARCHIVO_SP_REQUEST, procesarActivoArchivoSP);
}

function* exportarErroresArchivoSP(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idArchivosSPMasivos = action.idArchivosSPMasivos;

        let response = yield call(exportarErroresCargaSPCall, token, idArchivosSPMasivos);
        console.log(response);

        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Errores-Archivo-" + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".csv";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            
            yield put({ type: serviciosPlanificadosActions.EXPORTAR_ERRORES_ARCHIVO_SP_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.EXPORTAR_ERRORES_ARCHIVO_SP_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPlanificadosActions.EXPORTAR_ERRORES_ARCHIVO_SP_FAILURE });
    }
}
export function* exportarErroresArchivoSPSaga() {
    yield takeLatest(serviciosPlanificadosActions.EXPORTAR_ERRORES_ARCHIVO_SP_REQUEST, exportarErroresArchivoSP);
}

//Reporte Dinámico SP Sagas
function* getListaRegistrosSP(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;

        const response = yield call(listaReportesSPCall, token, page);
        console.log("response lista registros sp",response);
        if(response.status === 200){
            let listaRegistrosSP = response.data.data;
            let listaRegistrosSPCantidad = response.data.cantidad;

            for(let i in listaRegistrosSP){
                listaRegistrosSP[i].usuario = listaRegistrosSP[i].usuarioByIdUsuario.nombre + ' ' + listaRegistrosSP[i].usuarioByIdUsuario.aPaterno + (listaRegistrosSP[i].usuarioByIdUsuario.aMaterno ? (' ' + listaRegistrosSP[i].usuarioByIdUsuario.aMaterno) : '');


                listaRegistrosSP[i].formulario = {
                    tipo: undefined,
                    idsGerencias: [],
                    idsGruposServicios: [],
                    idsServicios: [],
                    idsPartidas: [],
                    estatusOrden: undefined,
                    tipoPrograma: undefined,
                    areaFuncional: undefined,
                    unidadFuncional: undefined,
                    fechaInicio: undefined,
                    fechaFin: undefined,
                }
                listaRegistrosSP[i].checkbox = {
                    folioOrdenC: false,
                    gerenciaC: false,
                    grupoServiciosC: false,
                    servicioC: false,
                    partidaC: false,
                    tipoProgramaC: false,
                    nombreRutinaC: false,
                    claveRutinaC: false,
                    ubicacionC: false,
                    estatusOrdenC: false,
                    nombreActivoC: false,
                    numeroSerieC: false,
                    codigoInternoC: false,
                    codigoEquipoC: false,
                    codigoActivoC: false,
                    estadoC: false,
                }
                listaRegistrosSP[i].agrupamiento = false;

                listaRegistrosSP[i].valoresColumnasSplit = listaRegistrosSP[i].valoresColumnas.split(',');
                for (let x in listaRegistrosSP[i].valoresColumnasSplit) {
                    listaRegistrosSP[i].valoresColumnasSplit[x] = listaRegistrosSP[i].valoresColumnasSplit[x].charAt(0).toLowerCase() + listaRegistrosSP[i].valoresColumnasSplit[x].slice(1);
                    listaRegistrosSP[i].checkbox[listaRegistrosSP[i].valoresColumnasSplit[x]] = true
                }

                let tipoAgrupado = 'SinAgrupar';
                let order = 'DESC';
                let field = 'osp.IdOrdenServicioPlanificado';

                for (let x in listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados) {
                    listaRegistrosSP[i].formulario.tipo = listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'Tipo' ? listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor : listaRegistrosSP[i].formulario.tipo;
                    listaRegistrosSP[i].formulario.idsGerencias = listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'IdsGerencias' ? listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor.split(',') : listaRegistrosSP[i].formulario.idsGerencias;
                    listaRegistrosSP[i].formulario.idsGruposServicios = listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'IdsGruposServicios' ? listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor.split(',') : listaRegistrosSP[i].formulario.idsGruposServicios;
                    listaRegistrosSP[i].formulario.idsServicios = listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'IdsServicios' ? listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor.split(',') : listaRegistrosSP[i].formulario.idsServicios;
                    listaRegistrosSP[i].formulario.idsPartidas = listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'IdsPartidas' ? listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor.split(',') : listaRegistrosSP[i].formulario.idsPartidas;
                    listaRegistrosSP[i].formulario.estatusOrden = listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'Estatus' ? listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor.split(',') : listaRegistrosSP[i].formulario.estatusOrden;
                    listaRegistrosSP[i].formulario.tipoPrograma = listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'TipoPrograma' ? listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor.split(',') : listaRegistrosSP[i].formulario.tipoPrograma;
                    listaRegistrosSP[i].formulario.areaFuncional = listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'Ubicacion' ? listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor : listaRegistrosSP[i].formulario.areaFuncional;
                    listaRegistrosSP[i].formulario.unidadFuncional = listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'UnidadFuncional' ? listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor : listaRegistrosSP[i].formulario.unidadFuncional;
                    
                    if(listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'Fecha'){
                        if(listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor.includes(',')){
                            listaRegistrosSP[i].formulario.fechaInicio = new Date (listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor.split(',')[0]).toISOString().slice(0, 10);
                            listaRegistrosSP[i].formulario.fechaFin = new Date (listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor.split(',')[1]).toISOString().slice(0, 10);
                        }
                    }

                    if(listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'Agrupamiento'){
                        tipoAgrupado = listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor
                    }

                    if(listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'Order'){
                        order = listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor;
                    }

                    if(listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'Field'){
                        if(listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor === 'idReporteOrdenesServiciosPlanificados'){
                            listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor = 'osp.IdOrdenServicioPlanificado';
                        }
                        else if(listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor === 'tipo'){
                            listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor = 'ru.Tipo';
                        }
                        else if(listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor === 'servicio'){
                            listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor = 'ser.Nombre';
                        }
                        else if(listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor === 'folioOrden'){
                            listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor = 'osp.FolioOrden';
                        }
                        else if(listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor === 'ubicacion'){
                            listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor = 'af.CodigoArea';
                        }
                        else if(listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor === 'nombreActivo'){
                            listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor = 'ace.Descripcion';
                        }
                        else if(listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor === 'fecha'){
                            listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor = 'fre.Fecha';
                        }

                        field = listaRegistrosSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor;
                    }
                }
                listaRegistrosSP[i].tipo = listaRegistrosSP[i].formulario.tipo;
                listaRegistrosSP[i].idsGerencias = listaRegistrosSP[i].formulario.idsGerencias;
                listaRegistrosSP[i].idsGruposServicios= listaRegistrosSP[i].formulario.idsGruposServicios;
                listaRegistrosSP[i].idsServicios= listaRegistrosSP[i].formulario.idsServicios;
                listaRegistrosSP[i].idsPartidas= listaRegistrosSP[i].formulario.idsPartidas;
                listaRegistrosSP[i].estatusOrden= listaRegistrosSP[i].formulario.estatusOrden;
                listaRegistrosSP[i].tipoPrograma= listaRegistrosSP[i].formulario.tipoPrograma;
                listaRegistrosSP[i].areaFuncional= listaRegistrosSP[i].formulario.areaFuncional;
                listaRegistrosSP[i].unidadFuncional= listaRegistrosSP[i].formulario.unidadFuncional;
                
                listaRegistrosSP[i].checkboxData = Object.entries(listaRegistrosSP[i].checkbox);
                let checkAll = true;
                listaRegistrosSP[i].listaFields = [];
                for (let x in listaRegistrosSP[i].checkboxData) {
                    if (!listaRegistrosSP[i].checkboxData[x][1]) {
                        checkAll = false
                    }
                    listaRegistrosSP[i][listaRegistrosSP[i].checkboxData[x][0]] = listaRegistrosSP[i].checkboxData[x][1];
                    listaRegistrosSP[i].checkboxData[x].push(
                        listaRegistrosSP[i].checkboxData[x][0] === 'folioOrdenC' ? 'Folio' : (
                            listaRegistrosSP[i].checkboxData[x][0] === 'gerenciaC' ? 'Gerencia' : (
                                listaRegistrosSP[i].checkboxData[x][0] === 'grupoServiciosC' ? 'Grupo de Servicios' : (
                                    listaRegistrosSP[i].checkboxData[x][0] === 'servicioC' ? 'Servicio' : (
                                        listaRegistrosSP[i].checkboxData[x][0] === 'partidaC' ? 'Partida' : (
                                            listaRegistrosSP[i].checkboxData[x][0] === 'tipoProgramaC' ? 'Tipo de Progama (Rutina, Mantenimiento o Prueba)' : (
                                                listaRegistrosSP[i].checkboxData[x][0] === 'nombreRutinaC' ? 'Nombre de Rutina' : (
                                                    listaRegistrosSP[i].checkboxData[x][0] === 'claveRutinaC' ? 'Clave' : (
                                                        listaRegistrosSP[i].checkboxData[x][0] === 'ubicacionC' ? 'Ubicación' : (
                                                            listaRegistrosSP[i].checkboxData[x][0] === 'estatusOrdenC' ? 'Estatus' : (
                                                                listaRegistrosSP[i].checkboxData[x][0] === 'nombreActivoC' ? 'Nombre del Activo' : (
                                                                    listaRegistrosSP[i].checkboxData[x][0] === 'numeroSerieC' ? 'Número de Serie' : (
                                                                        listaRegistrosSP[i].checkboxData[x][0] === 'codigoInternoC' ? 'Código Interno' : (
                                                                            listaRegistrosSP[i].checkboxData[x][0] === 'codigoEquipoC' ? 'Código de Equipo' : (
                                                                                listaRegistrosSP[i].checkboxData[x][0] === 'codigoActivoC' ? 'Código de Activo' : (
                                                                                    listaRegistrosSP[i].checkboxData[x][0] === 'estadoC' ? 'Estado' : false
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                }

                listaRegistrosSP[i].checkAll = checkAll;
                listaRegistrosSP[i].tipoAgrupado = tipoAgrupado;
                listaRegistrosSP[i].order = order;
                listaRegistrosSP[i].field = field;
            }

            console.log('listaRegistrosSP', listaRegistrosSP)

            yield put({ type: serviciosPlanificadosActions.LISTA_REGISTROS_SP_SUCCESS, listaRegistrosSP, listaRegistrosSPCantidad });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.LISTA_REGISTROS_SP_FAILURE });
        }
    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.LISTA_REGISTROS_SP_FAILURE });
    }
} export function* getListaRegistrosSPSaga(){
    yield takeLatest(serviciosPlanificadosActions.LISTA_REGISTROS_SP_REQUEST, getListaRegistrosSP)
}

//Reporte Dinámico SP Sagas
function* getListaRegistrosEspecialesSP(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;

        const response = yield call(listaReportesSPEspecialesCall, token, page);
        console.log("response lista registros especiales",response);
        if(response.status === 200){
            let listaRegistrosEspecialSP = response.data.data;
            let listaRegistrosEspecialSPCantidad = response.data.cantidad;

            for(let i in listaRegistrosEspecialSP){
                listaRegistrosEspecialSP[i].usuario = listaRegistrosEspecialSP[i].usuarioByIdUsuario.nombre + ' ' + listaRegistrosEspecialSP[i].usuarioByIdUsuario.aPaterno + (listaRegistrosEspecialSP[i].usuarioByIdUsuario.aMaterno ? (' ' + listaRegistrosEspecialSP[i].usuarioByIdUsuario.aMaterno) : '');
                
                listaRegistrosEspecialSP[i].formulario = {
                    tipoC: undefined,
                    gerenciaC: undefined,
                    grupoServiciosC: undefined,
                    servicioC: undefined,
                    partidaC: undefined,
                    estatusOrdenC: undefined,
                    tipoProgramaC: undefined,
                    ubicacion: undefined,
                    unidadFuncional: undefined,
                    fecha: undefined,
                }
                listaRegistrosEspecialSP[i].checkbox = {
                    folioOrdenC: false,
                    gerenciaC: false,
                    grupoServiciosC: false,
                    servicioC: false,
                    partidaC: false,
                    tipoProgramaC: false,
                    nombreRutinaC: false,
                    claveRutinaC: false,
                    ubicacionC: false,
                    estatusOrdenC: false,
                    nombreActivoC: false,
                    numeroSerieC: false,
                    codigoInternoC: false,
                    codigoEquipoC: false,
                    codigoActivoC: false,
                    estadoC: false,
                }
                listaRegistrosEspecialSP[i].agrupamiento = false;

                listaRegistrosEspecialSP[i].valoresColumnasSplit = listaRegistrosEspecialSP[i].valoresColumnas.split(',');
                for (let x in listaRegistrosEspecialSP[i].valoresColumnasSplit) {
                    listaRegistrosEspecialSP[i].valoresColumnasSplit[x] = listaRegistrosEspecialSP[i].valoresColumnasSplit[x].charAt(0).toLowerCase() + listaRegistrosEspecialSP[i].valoresColumnasSplit[x].slice(1);
                    listaRegistrosEspecialSP[i].checkbox[listaRegistrosEspecialSP[i].valoresColumnasSplit[x]] = true
                }

                let tipoAgrupado = 'SinAgrupar';
                let order = 'DESC';
                let field = 'osp.IdOrdenServicioPlanificado'
                let nombreModulo = '';
                let responsable = '';

                for (let x in listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados) {
                    listaRegistrosEspecialSP[i].formulario.idsTiposBitacoras = listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'IdsTiposBitacoras' ? listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor.split(',') : listaRegistrosEspecialSP[i].formulario.idsTiposBitacoras;

                    listaRegistrosEspecialSP[i].formulario.idsPartidas = listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'IdsPartidas' ? listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor.split(',') : listaRegistrosEspecialSP[i].formulario.idsPartidas;

                    listaRegistrosEspecialSP[i].formulario.idsModulos = listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'IdsModulos' ? listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor.split(',') : listaRegistrosEspecialSP[i].formulario.idsModulos;
                    listaRegistrosEspecialSP[i].formulario.idsServicios = listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'IdsServicios' ? listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor.split(',') : listaRegistrosEspecialSP[i].formulario.idsServicios;

                    if(listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'Nombre'){
                        nombreModulo = listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor
                    }

                    if(listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'Responsable'){
                        responsable = listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor
                    }


                    if(listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'FechaIngreso'){
                        if(listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor.includes(',')){
                            listaRegistrosEspecialSP[i].formulario.fechaIngresoInicio = new Date (listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor.split(',')[0]).toISOString().slice(0, 10);
                            listaRegistrosEspecialSP[i].formulario.fechaIngresoFin = new Date (listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor.split(',')[1]).toISOString().slice(0, 10);
                        }
                    }

                    if(listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'Agrupamiento'){
                        tipoAgrupado = listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor
                    }

                    if(listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'Order'){
                        order = listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor;
                    }

                    if(listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].filtro === 'Field'){
                        field = listaRegistrosEspecialSP[i].filtrosByIdReporteOrdenesServiciosPlanificados[x].valor;
                    }
                }

                listaRegistrosEspecialSP[i].checkboxData = Object.entries(listaRegistrosEspecialSP[i].checkbox);
                let checkAll = true;
                listaRegistrosEspecialSP[i].listaFields = [];
                for (let x in listaRegistrosEspecialSP[i].checkboxData) {
                    if (!listaRegistrosEspecialSP[i].checkboxData[x][1]) {
                        checkAll = false
                    }
                    listaRegistrosEspecialSP[i][listaRegistrosEspecialSP[i].checkboxData[x][0]] = listaRegistrosEspecialSP[i].checkboxData[x][1];
                    listaRegistrosEspecialSP[i].checkboxData[x].push(
                        listaRegistrosEspecialSP[i].checkboxData[x][0] === 'folioOrdenC' ? 'Folio' : (
                            listaRegistrosEspecialSP[i].checkboxData[x][0] === 'gerenciaC' ? 'Gerencia' : (
                                listaRegistrosEspecialSP[i].checkboxData[x][0] === 'grupoServiciosC' ? 'Grupo de Servicios' : (
                                    listaRegistrosEspecialSP[i].checkboxData[x][0] === 'servicioC' ? 'Servicio' : (
                                        listaRegistrosEspecialSP[i].checkboxData[x][0] === 'partidaC' ? 'Partida' : (
                                            listaRegistrosEspecialSP[i].checkboxData[x][0] === 'tipoProgramaC' ? 'Tipo de Progama (Rutina, Mantenimiento o Prueba)' : (
                                                listaRegistrosEspecialSP[i].checkboxData[x][0] === 'nombreRutinaC' ? 'Nombre de Rutina' : (
                                                    listaRegistrosEspecialSP[i].checkboxData[x][0] === 'claveRutinaC' ? 'Clave' : (
                                                        listaRegistrosEspecialSP[i].checkboxData[x][0] === 'ubicacionC' ? 'Ubicación' : (
                                                            listaRegistrosEspecialSP[i].checkboxData[x][0] === 'estatusOrdenC' ? 'Estatus' : (
                                                                listaRegistrosEspecialSP[i].checkboxData[x][0] === 'nombreActivoC' ? 'Nombre del Activo' : (
                                                                    listaRegistrosEspecialSP[i].checkboxData[x][0] === 'numeroSerieC' ? 'Número de Serie' : (
                                                                        listaRegistrosEspecialSP[i].checkboxData[x][0] === 'codigoInternoC' ? 'Código Interno' : (
                                                                            listaRegistrosEspecialSP[i].checkboxData[x][0] === 'codigoEquipoC' ? 'Código de Equipo' : (
                                                                                listaRegistrosEspecialSP[i].checkboxData[x][0] === 'codigoActivoC' ? 'Código de Activo' : (
                                                                                    listaRegistrosEspecialSP[i].checkboxData[x][0] === 'estadoC' ? 'Estado' : false
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                }

                listaRegistrosEspecialSP[i].checkAll = checkAll;
                listaRegistrosEspecialSP[i].tipoAgrupado = tipoAgrupado;
                listaRegistrosEspecialSP[i].order = order;
                listaRegistrosEspecialSP[i].field = field;
                listaRegistrosEspecialSP[i].nombreModulo =  nombreModulo;
                listaRegistrosEspecialSP[i].responsable = responsable;
            }

            console.log('listaRegistrosEspecialSP', listaRegistrosEspecialSP)

            yield put({ type: serviciosPlanificadosActions.LISTA_REGISTROS_SP_ESPECIAL_SUCCESS, listaRegistrosEspecialSP, listaRegistrosEspecialSPCantidad });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.LISTA_REGISTROS_SP_ESPECIAL_FAILURE });
        }
    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.LISTA_REGISTROS_SP_ESPECIAL_FAILURE });
    }
} export function* getListaRegistrosEspecialesSPSaga(){
    yield takeLatest(serviciosPlanificadosActions.LISTA_REGISTROS_SP_ESPECIAL_REQUEST, getListaRegistrosEspecialesSP)
}

function* estadoRegistroReporteSP(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idReporte = action.idReporte;

        const response = yield call(estadoRegistroReporteSPCall, token, idReporte);
        console.log("response estado registro sp",response);

        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El estado del reporte personalizado cambió de forma correcta";

            yield put({type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage});

            if (action.personal) {
                yield put({ type: serviciosPlanificadosActions.LISTA_REGISTROS_SP_ESPECIAL_REQUEST,  page: action.page });
            } else {
                yield put({type: serviciosPlanificadosActions.LISTA_REGISTROS_SP_REQUEST, page: action.page});
            }

            yield put({type: serviciosPlanificadosActions.ESTADO_REGISTRO_REPORTE_SP_SUCCESS});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});

            yield put({type: serviciosPlanificadosActions.ESTADO_REGISTRO_REPORTE_SP_FAILURE});
        }

    } catch (error) {
        console.log(error);
        yield put({type: serviciosPlanificadosActions.ESTADO_REGISTRO_REPORTE_SP_FAILURE});
    }
}

export function* estadoRegistroReporteSPSaga() {
    yield takeLatest(serviciosPlanificadosActions.ESTADO_REGISTRO_REPORTE_SP_REQUEST, estadoRegistroReporteSP);
}

function* listaReporteDinamicoSP(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        
        let idsGerencias = [];
        let idsGruposServicios = [];
        let idsServicios = [];
        let idsPartidas = [];
        let estatusOrden = [];
        let tipoPrograma = [];
        let areaFuncional = [];
        let unidadFuncional = [];

        if(action.formulario.idsGerencias){
            for(let i in action.formulario.idsGerencias){
                idsGerencias.push(action.formulario.idsGerencias[i].split(' - ')[0]);
            }
        }
        if(action.formulario.idsGruposServicios){
            for(let i in action.formulario.idsGruposServicios){
                idsGruposServicios.push(action.formulario.idsGruposServicios[i].split(' - ')[0]);
            }
        }
        if(action.formulario.idsServicios){
            for(let i in action.formulario.idsServicios){
                idsServicios.push(action.formulario.idsServicios[i].split(' - ')[0]);
            }
        }
        if(action.formulario.idsPartidas){
            for(let i in action.formulario.idsPartidas){
                idsPartidas.push(action.formulario.idsPartidas[i].split(' - ')[0]);
            }
        }
        if(action.formulario.estatusOrden){
            for(let i in action.formulario.estatusOrden){
                estatusOrden.push(action.formulario.estatusOrden[i]);
            }
        }
        if(action.formulario.tipoPrograma){
            for(let i in action.formulario.tipoPrograma){
                tipoPrograma.push(action.formulario.tipoPrograma[i]);
            }
        }

        if(action.formulario.areaFuncional){
            areaFuncional.push(action.formulario.areaFuncional.split(' - ')[0]);
        }
        if(action.formulario.unidadFuncional){
            unidadFuncional.push(action.formulario.unidadFuncional.split(' - ')[0]);
        }
        const data = {
            "idsGerencias": action.formulario.tipoAgrupado === 'Gerencia' ? [action.agrupado.id] : action.formulario.idsGerencias ? idsGerencias : null,
            "idsGruposServicios": action.formulario.tipoAgrupado === 'Grupo de Servicio' ? [action.agrupado.id] : action.formulario.idsGruposServicios ? idsGruposServicios : null,
            "idsServicios": action.formulario.tipoAgrupado === 'Servicio' ? [action.agrupado.id] : action.formulario.idsServicios ? idsServicios : null,
            "idsPartidas": action.formulario.tipoAgrupado === 'Partida' ? [action.agrupado.id] : action.formulario.idsPartidas ? idsPartidas : null,
            "tipo": action.formulario.tipoAgrupado === 'Tipos' ? action.agrupado.id : action.formulario.tipo ? action.formulario.tipo : null,
            'estatusOrden': action.formulario.tipoAgrupado === 'Estatus' ? [action.agrupado.id] : action.formulario.estatusOrden ? estatusOrden : null,
            'tipoPrograma': action.formulario.tipoAgrupado === 'Tipo de Programa' ? [action.agrupado.id] : action.formulario.tipoPrograma ? tipoPrograma : null,
            'ubicacion': action.formulario.tipoAgrupado === 'Area Funcional' ? [action.agrupado.id] : action.formulario.areaFuncional ? areaFuncional : null,
            'unidadFuncional': action.formulario.unidadFuncional ? unidadFuncional : null,
            "fechaInicio": action.formulario.rangoFechas ? action.formulario.rangoFechas[0].format('YYYY-MM-DD') : null,
            "fechaFin": action.formulario.rangoFechas ? action.formulario.rangoFechas[1].format('YYYY-MM-DD') : null,
            'activo': action.formulario.tipoAgrupado === 'Activo' ? [action.agrupado.id] : null,
            'folioOrdenC': action.checkboxData.folioOrdenC ? action.checkboxData.folioOrdenC : null,
            'gerenciaC': action.checkboxData.gerenciaC ? action.checkboxData.gerenciaC : null,
            'grupoServiciosC': action.checkboxData.grupoServiciosC ? action.checkboxData.grupoServiciosC : null,
            'servicioC': action.checkboxData.servicioC ? action.checkboxData.servicioC : null,
            'partidaC': action.checkboxData.partidaC ? action.checkboxData.partidaC : null,
            'tipoC': true,
            'tipoProgramaC': action.checkboxData.tipoProgramaC ? action.checkboxData.tipoProgramaC : null,
            'nombreRutinaC': action.checkboxData.nombreRutinaC ? action.checkboxData.nombreRutinaC : null,
            'claveRutinaC': action.checkboxData.claveRutinaC ? action.checkboxData.claveRutinaC : null,
            'ubicacionC': action.checkboxData.ubicacionC ? action.checkboxData.ubicacionC : null,
            'estatusOrdenC': action.checkboxData.estatusOrdenC ? action.checkboxData.estatusOrdenC : null,
            'fechaC': true,
            'nombreActivoC': action.checkboxData.nombreActivoC ? action.checkboxData.nombreActivoC : null,
            'numeroSerieC': action.checkboxData.numeroSerieC ? action.checkboxData.numeroSerieC : null,
            'codigoInternoC': action.checkboxData.codigoInternoC ? action.checkboxData.codigoInternoC : null,
            'codigoEquipoC': action.checkboxData.codigoEquipoC ? action.checkboxData.codigoEquipoC : null,
            'codigoActivoC': action.checkboxData.codigoActivoC ? action.checkboxData.codigoActivoC : null,
            'estadoC': action.checkboxData.estadoC ? action.checkboxData.estadoC : null,
            "page": (action.page - 1) * 10,
            "offset": 10,
            "order": action.order.order,
            "field": action.order.field === 'ru.Tipo' ? 'osp.FolioOrden' : action.order.field,
            "tipoAgrupamiento": null,
        }
        console.log(`data`, data);

        const response = yield call(reporteDinamicoSPCall, token, data)
        console.log("response list reporte dinámico sp",response)

        if (response.status === 200) {
            const listaReporteDinamicoSP = response.data.data;
            const listaReporteDinamicoSPCantidad = response.data.cantidad;
            for(let i in listaReporteDinamicoSP){
                listaReporteDinamicoSP[i].id = i+1;
                if(listaReporteDinamicoSP[i].tipoPrograma === 'Rutina'){
                    listaReporteDinamicoSP[i].nombreActivo = 'No aplica';
                    listaReporteDinamicoSP[i].numeroSerie = 'No aplica';
                    listaReporteDinamicoSP[i].codigoActivo = 'No aplica';
                    listaReporteDinamicoSP[i].codigoEquipo = 'No aplica';
                    listaReporteDinamicoSP[i].codigoInterno = 'No aplica';
                }
                else{
                    listaReporteDinamicoSP[i].ubicacion = 'No aplica';
                }
                if(!listaReporteDinamicoSP[i].gerencia){
                    listaReporteDinamicoSP[i].gerencia = 'Sin asignar';
                }
                listaReporteDinamicoSP[i].fecha = listaReporteDinamicoSP[i].fecha.split('T')[0].split('-').reverse().join('-');
            }

            if(action.agrupado){
                const listaReporteDinamicoAgrupadoSP = action.agrupado.listaReporteDinamicoAgrupadoSP;
                const listaReporteDinamicoAgrupadoSPCantidad = action.agrupado.listaReporteDinamicoAgrupadoSPCantidad;
                for(let i in listaReporteDinamicoAgrupadoSP){
                    if(action.agrupado.id === listaReporteDinamicoAgrupadoSP[i].id){
                        listaReporteDinamicoAgrupadoSP[i].agrupados = response.data;
                        listaReporteDinamicoAgrupadoSP[i].agrupados.page = action.page;
                        for(let x in listaReporteDinamicoAgrupadoSP[i].agrupados.data){
                            listaReporteDinamicoAgrupadoSP[i].agrupados.data[x].id = x + 1;
                        };
                    }
                }
                yield put({ type: serviciosPlanificadosActions.LISTA_REPORTE_DINAMICO_AGRUPADO_SP_SUCCESS, listaReporteDinamicoAgrupadoSP, listaReporteDinamicoAgrupadoSPCantidad })
                yield put({ type: serviciosPlanificadosActions.LISTA_REPORTE_DINAMICO_SP_FAILURE });
            }
            else{
                yield put({ type: serviciosPlanificadosActions.LISTA_REPORTE_DINAMICO_SP_SUCCESS, listaReporteDinamicoSP, listaReporteDinamicoSPCantidad })
            }
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: serviciosPlanificadosActions.LISTA_REPORTE_DINAMICO_SP_FAILURE});
        }
    } catch (error) {
        yield put({type: serviciosPlanificadosActions.LISTA_REPORTE_DINAMICO_SP_FAILURE});
    }
}
export function* listaReporteDinamicoSPSaga() {
    yield takeLatest(serviciosPlanificadosActions.LISTA_REPORTE_DINAMICO_SP_REQUEST, listaReporteDinamicoSP)
}

function* exportarReporteDinamicoPDFSP(action) {
    try {
        console.log("action de exportar pdf SP",action)
        const token = sessionStorage.getItem('token');

        let idsGerencias = [];
        let idsGruposServicios = [];
        let idsServicios = [];
        let estatusOrden = [];
        let tipoPrograma = [];
        let areaFuncional = [];
        let unidadFuncional = [];
        let idsPartidas = [];

        if(action.formulario.idsGerencias){
            for(let i in action.formulario.idsGerencias){
                idsGerencias.push(action.formulario.idsGerencias[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsGruposServicios){
            for(let i in action.formulario.idsGruposServicios){
                idsGruposServicios.push(action.formulario.idsGruposServicios[i].split(' - ')[0]);
            }
        }

        if(action.formulario.idsServicios){
            for(let i in action.formulario.idsServicios){
                idsServicios.push(action.formulario.idsServicios[i].split(' - ')[0]);
            }
        }
        if(action.formulario.idsPartidas){
            for(let i in action.formulario.idsPartidas){
                idsPartidas.push(action.formulario.idsPartidas[i].split(' - ')[0]);
            }
        }

        if(action.formulario.estatusOrden){
            for(let i in action.formulario.estatusOrden){
                estatusOrden.push(action.formulario.estatusOrden[i]);
            }
        }

        if(action.formulario.tipoPrograma){
            for(let i in action.formulario.tipoPrograma){
                tipoPrograma.push(action.formulario.tipoPrograma[i]);
            }
        }

        if(action.formulario.areaFuncional){
            areaFuncional.push(action.formulario.areaFuncional.split(' - ')[0]);
        }
        if(action.formulario.unidadFuncional){
            unidadFuncional.push(action.formulario.unidadFuncional.split(' - ')[0]);
        }

        const data = {
            "idsGerencias": action.formulario.idsGerencias ? idsGerencias : null,
            "idsGruposServicios": action.formulario.idsGruposServicios ? idsGruposServicios : null,
            "idsServicios": action.formulario.idsServicios ? idsServicios : null,
            "idsPartidas": action.formulario.idsPartidas ? idsPartidas : null,
            "tipo": action.formulario.tipo ? action.formulario.tipo : null,
            'estatusOrden':  action.formulario.estatusOrden ? estatusOrden : null,
            'tipoPrograma': action.formulario.tipoPrograma ? tipoPrograma : null,
            'ubicacion': action.formulario.areaFuncional ? areaFuncional : null,
            'unidadFuncional': action.formulario.unidadFuncional ? unidadFuncional : null,
            "fechaInicio": action.formulario.rangoFechas ? action.formulario.rangoFechas[0].format('YYYY-MM-DD') : null,
            "fechaFin": action.formulario.rangoFechas ? action.formulario.rangoFechas[1].format('YYYY-MM-DD') : null,
            'activo': null,
            'folioOrdenC': action.checkboxData.folioOrdenC ? action.checkboxData.folioOrdenC : null, //
            'gerenciaC': action.checkboxData.gerenciaC ? action.checkboxData.gerenciaC : null,
            'grupoServiciosC': action.checkboxData.grupoServiciosC ? action.checkboxData.grupoServiciosC : null,
            'servicioC': action.checkboxData.servicioC ? action.checkboxData.servicioC : null,
            'partidaC': action.checkboxData.partidaC ? action.checkboxData.partidaC : null,
            'tipoProgramaC': action.checkboxData.tipoProgramaC ? action.checkboxData.tipoProgramaC : null,
            'nombreRutinaC': action.checkboxData.nombreRutinaC ? action.checkboxData.nombreRutinaC : null,
            'claveRutinaC': action.checkboxData.claveRutinaC ? action.checkboxData.claveRutinaC : null,
            'ubicacionC': action.checkboxData.ubicacionC ? action.checkboxData.ubicacionC : null,
            'estatusOrdenC': action.checkboxData.estatusOrdenC ? action.checkboxData.estatusOrdenC : null,
            'fechaC': true,
            'nombreActivoC': action.checkboxData.nombreActivoC ? action.checkboxData.nombreActivoC : null,
            'numeroSerieC': action.checkboxData.numeroSerieC ? action.checkboxData.numeroSerieC : null,
            'codigoInternoC': action.checkboxData.codigoInternoC ? action.checkboxData.codigoInternoC : null,
            'codigoEquipoC': action.checkboxData.codigoEquipoC ? action.checkboxData.codigoEquipoC : null,
            'codigoActivoC': action.checkboxData.codigoActivoC ? action.checkboxData.codigoActivoC : null,
            'estadoC': action.checkboxData.estadoC ? action.checkboxData.estadoC : null,
            'tipoC': true,
            "page": null,
            "offset": null,
            "order": action.order.order,
            "field": action.order.field === 'ru.Tipo' ? 'osp.FolioOrden' : action.order.field,
            "tipoAgrupamiento": null
        }
        console.log("data PDF de SP", data)
        const response = yield call(pdfReportesSPCall, token, data);
        console.log("response de exportar pdf sp",response)

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const titulo = action.tituloDocumento ? action.tituloDocumento : 'ReporteSP_'
            link.download = titulo + + new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: serviciosPlanificadosActions.PDF_REPORTE_DINAMICO_SP_SUCCESS })

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage: 'El archivo se descargó de forma correcta' });

        } else {
            const textMessage = 'No se pudo descargar el PDF con las columnas y filtros seleccionados';

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg: true, textMessage });
            yield put({ type: serviciosPlanificadosActions.PDF_REPORTE_DINAMICO_SP_FAILURE });
        }
    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.PDF_REPORTE_DINAMICO_SP_FAILURE });
    }
}
export function* exportarReporteDinamicoPDFSPSaga() {
    yield takeLatest(serviciosPlanificadosActions.PDF_REPORTE_DINAMICO_SP_REQUEST, exportarReporteDinamicoPDFSP)
}

function* reporteDinamicoExcelSP(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        
        let idsGerencias = [];
        let idsGruposServicios = [];
        let idsServicios = [];
        let estatusOrden = [];
        let tipoPrograma = [];
        let areaFuncional = [];
        let unidadFuncional = [];
        let idsPartidas = [];

        if(action.formulario.idsGerencias){
            for(let i in action.formulario.idsGerencias){
                idsGerencias.push(action.formulario.idsGerencias[i].split(' - ')[0]);
            }
        }
        if(action.formulario.idsGruposServicios){
            for(let i in action.formulario.idsGruposServicios){
                idsGruposServicios.push(action.formulario.idsGruposServicios[i].split(' - ')[0]);
            }
        }
        if(action.formulario.idsServicios){
            for(let i in action.formulario.idsServicios){
                idsServicios.push(action.formulario.idsServicios[i].split(' - ')[0]);
            }
        }
        if(action.formulario.idsPartidas){
            for(let i in action.formulario.idsPartidas){
                idsPartidas.push(action.formulario.idsPartidas[i].split(' - ')[0]);
            }
        }
        if(action.formulario.estatusOrden){
            for(let i in action.formulario.estatusOrden){
                estatusOrden.push(action.formulario.estatusOrden[i]);
            }
        }
        if(action.formulario.tipoPrograma){
            for(let i in action.formulario.tipoPrograma){
                tipoPrograma.push(action.formulario.tipoPrograma[i]);
            }
        }

        if(action.formulario.areaFuncional){
            areaFuncional.push(action.formulario.areaFuncional.split(' - ')[0]);
        }
        if(action.formulario.unidadFuncional){
            unidadFuncional.push(action.formulario.unidadFuncional.split(' - ')[0]);
        }

        const data = {
            "idsGerencias": action.formulario.idsGerencias ? idsGerencias : null,
            "idsGruposServicios": action.formulario.idsGruposServicios ? idsGruposServicios : null,
            "idsServicios": action.formulario.idsServicios ? idsServicios : null,
            "idsPartidas": action.formulario.idsPartidas ? idsPartidas : null,
            "tipo": action.formulario.tipo ? action.formulario.tipo : null,
            'estatusOrden':  action.formulario.estatusOrden ? estatusOrden : null,
            'tipoPrograma': action.formulario.tipoPrograma ? tipoPrograma : null,
            'ubicacion': action.formulario.areaFuncional ? areaFuncional : null,
            'unidadFuncional': action.formulario.unidadFuncional ? unidadFuncional : null,
            "fechaInicio": action.formulario.rangoFechas ? action.formulario.rangoFechas[0].format('YYYY-MM-DD') : null,
            "fechaFin": action.formulario.rangoFechas ? action.formulario.rangoFechas[1].format('YYYY-MM-DD') : null,
            'activo': null,
            'folioOrdenC': action.checkboxData.folioOrdenC ? action.checkboxData.folioOrdenC : null,
            'gerenciaC': action.checkboxData.gerenciaC ? action.checkboxData.gerenciaC : null,
            'grupoServiciosC': action.checkboxData.grupoServiciosC ? action.checkboxData.grupoServiciosC : null,
            'servicioC': action.checkboxData.servicioC ? action.checkboxData.servicioC : null,
            'partidaC': action.checkboxData.partidaC ? action.checkboxData.partidaC : null,
            'tipoC': true,
            'tipoProgramaC': action.checkboxData.tipoProgramaC ? action.checkboxData.tipoProgramaC : null,
            'nombreRutinaC': action.checkboxData.nombreRutinaC ? action.checkboxData.nombreRutinaC : null,
            'claveRutinaC': action.checkboxData.claveRutinaC ? action.checkboxData.claveRutinaC : null,
            'ubicacionC': action.checkboxData.ubicacionC ? action.checkboxData.ubicacionC : null,
            'estatusOrdenC': action.checkboxData.estatusOrdenC ? action.checkboxData.estatusOrdenC : null,
            'fechaC': true,
            'nombreActivoC': action.checkboxData.nombreActivoC ? action.checkboxData.nombreActivoC : null,
            'numeroSerieC': action.checkboxData.numeroSerieC ? action.checkboxData.numeroSerieC : null,
            'codigoInternoC': action.checkboxData.codigoInternoC ? action.checkboxData.codigoInternoC : null,
            'codigoEquipoC': action.checkboxData.codigoEquipoC ? action.checkboxData.codigoEquipoC : null,
            'codigoActivoC': action.checkboxData.codigoActivoC ? action.checkboxData.codigoActivoC : null,
            'estadoC': action.checkboxData.estadoC ? action.checkboxData.estadoC : null,
            "page": null,
            "offset": null,
            "order": action.order.order,
            "field": action.order.field === 'ru.Tipo' ? 'osp.FolioOrden' : action.order.field,
            "tipoAgrupamiento": null
        }
        console.log(`data`, data);

        const response = yield call(excelReportesSPCall, token, data)
        console.log(response)

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const titulo = action.tituloDocumento ? action.tituloDocumento : 'ReporteSP_'
            link.download = titulo + new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: serviciosPlanificadosActions.EXCEL_REPORTE_DINAMICO_SP_SUCCESS })
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage: 'El archivo se descargó de forma correcta' });

        } else {
            const showErrorMsg = true;
            const textMessage = 'No se pudo descargar el Excel con las columnas y filtros seleccionados';

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: serviciosPlanificadosActions.EXCEL_REPORTE_DINAMICO_SP_FAILURE});
        }
    } catch (error) {
        yield put({type: serviciosPlanificadosActions.EXCEL_REPORTE_DINAMICO_SP_FAILURE});
    }
}
export function* reporteDinamicoExcelSPSaga() {
    yield takeLatest(serviciosPlanificadosActions.EXCEL_REPORTE_DINAMICO_SP_REQUEST, reporteDinamicoExcelSP)
}


function* reporteDinamicoAgrupadoSP(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        let idsGerencias = [];
        let idsGruposServicios = [];
        let idsServicios = [];
        let estatusOrden = [];
        let tipoPrograma = [];
        let areaFuncional = [];
        let unidadFuncional = [];
        let idsPartidas = [];

        if(action.formulario.areaFuncional){
            areaFuncional.push(action.formulario.areaFuncional.split(' - ')[0]);
        }
        if(action.formulario.unidadFuncional){
            unidadFuncional.push(action.formulario.unidadFuncional.split(' - ')[0]);
        }

        if(action.formulario.idsGerencias){
            for(let i in action.formulario.idsGerencias){
                idsGerencias.push(action.formulario.idsGerencias[i].split(' - ')[0]);
            }
        }
        if(action.formulario.idsGruposServicios){
            for(let i in action.formulario.idsGruposServicios){
                idsGruposServicios.push(action.formulario.idsGruposServicios[i].split(' - ')[0]);
            }
        }
        if(action.formulario.idsServicios){
            for(let i in action.formulario.idsServicios){
                idsServicios.push(action.formulario.idsServicios[i].split(' - ')[0]);
            }
        }
        if(action.formulario.idsPartidas){
            for(let i in action.formulario.idsPartidas){
                idsPartidas.push(action.formulario.idsPartidas[i].split(' - ')[0]);
            }
        }
        if(action.formulario.estatusOrden){
            for(let i in action.formulario.estatusOrden){
                estatusOrden.push(action.formulario.estatusOrden[i]);
            }
        }
        if(action.formulario.tipoPrograma){
            for(let i in action.formulario.tipoPrograma){
                tipoPrograma.push(action.formulario.tipoPrograma[i]);
            }
        }
        const data = {
            "idsGerencias": action.formulario.idsGerencias ? idsGerencias : null,
            "idsGruposServicios": action.formulario.idsGruposServicios ? idsGruposServicios : null,
            "idsServicios": action.formulario.idsServicios ? idsServicios : null,
            "idsPartidas": action.formulario.idsPartidas ? idsPartidas : null,
            "tipo": action.formulario.tipo ? action.formulario.tipo : null,
            'estatusOrden': action.formulario.estatusOrden ? estatusOrden : null,
            'tipoPrograma': action.formulario.tipoPrograma ? tipoPrograma : null,
            'ubicacion': action.formulario.areaFuncional ? areaFuncional : null,
            'unidadFuncional': action.formulario.unidadFuncional ? unidadFuncional : null,
            "fechaInicio": action.formulario.rangoFechas ? action.formulario.rangoFechas[0].format('YYYY-MM-DD') : null,
            "fechaFin": action.formulario.rangoFechas ? action.formulario.rangoFechas[1].format('YYYY-MM-DD') : null,
            'activo': null,
            'folioOrdenC': action.checkboxData.folioOrdenC ? action.checkboxData.folioOrdenC : null,
            'gerenciaC': action.checkboxData.gerenciaC ? action.checkboxData.gerenciaC : null,
            'grupoServiciosC': action.checkboxData.grupoServiciosC ? action.checkboxData.grupoServiciosC : null,
            'servicioC': action.checkboxData.servicioC ? action.checkboxData.servicioC : null,
            'partidaC': action.checkboxData.partidaC ? action.checkboxData.partidaC : null,
            'tipoC': true,
            'tipoProgramaC': action.checkboxData.tipoProgramaC ? action.checkboxData.tipoProgramaC : null,
            'nombreRutinaC': action.checkboxData.nombreRutinaC ? action.checkboxData.nombreRutinaC : null,
            'claveRutinaC': action.checkboxData.claveRutinaC ? action.checkboxData.claveRutinaC : null,
            'ubicacionC': action.checkboxData.ubicacionC ? action.checkboxData.ubicacionC : null,
            'estatusOrdenC': action.checkboxData.estatusOrdenC ? action.checkboxData.estatusOrdenC : null,
            'fechaC': true,
            'nombreActivoC': action.checkboxData.nombreActivoC ? action.checkboxData.nombreActivoC : null,
            'numeroSerieC': action.checkboxData.numeroSerieC ? action.checkboxData.numeroSerieC : null,
            'codigoInternoC': action.checkboxData.codigoInternoC ? action.checkboxData.codigoInternoC : null,
            'codigoEquipoC': action.checkboxData.codigoEquipoC ? action.checkboxData.codigoEquipoC : null,
            'codigoActivoC': action.checkboxData.codigoActivoC ? action.checkboxData.codigoActivoC : null,
            'estadoC': action.checkboxData.estadoC ? action.checkboxData.estadoC : null,
            "page": (action.page - 1) * 10,
            "offset": 10,
            "order": action.order.order,
            "field": action.order.field === 'ru.Tipo' ? 'osp.FolioOrden' : action.order.field,
            "tipoAgrupamiento": action.agrupado ? action.agrupado : action.formulario.tipoAgrupado,
        }
        console.log(`data`, data);

        const response = yield call(listaReportesAgrupadoSPCall, token, data)
        console.log("response en reporte dinámico agrupado sp",response)

        if(response.status === 200){
            const listaReporteDinamicoAgrupadoSP = response.data.data;
            const listaReporteDinamicoAgrupadoSPCantidad = response.data.cantidad;
            const agrupadoConst = action.agrupado ? action.agrupado : action.formulario.tipoAgrupado;
            
            if(agrupadoConst === 'Estatus' || agrupadoConst === 'Tipo de Programa' || agrupadoConst === 'Area Funcional' || agrupadoConst === 'Activo' || agrupadoConst === 'Tipos'){
                for(let i in listaReporteDinamicoAgrupadoSP){
                    listaReporteDinamicoAgrupadoSP[i].id = listaReporteDinamicoAgrupadoSP[i].nombre;
                }
            }

            for(let i in listaReporteDinamicoAgrupadoSP){
                if(listaReporteDinamicoAgrupadoSP[i].nombre === '0'){
                    listaReporteDinamicoAgrupadoSP[i].nombre = agrupadoConst === 'Gerencia' ? 'Sin gerencia asignada' : agrupadoConst === 'Area Funcional' ? 'Sin ubicación asiganda' : agrupadoConst === 'Estatus' ? 'Sin estado asignado' : agrupadoConst === 'Partida' ? 'Sin partida asignada' : 'Sin activo asignado';
                }
            }
            
            for(let i in listaReporteDinamicoAgrupadoSP){
                if(listaReporteDinamicoAgrupadoSP[i].nombre === 'Sin asignacion'){
                    listaReporteDinamicoAgrupadoSP[i].nombre = 'Sin asignación'
                }
                const dataAgrupado = {
                    "idsGerencias": agrupadoConst === 'Gerencia' ? listaReporteDinamicoAgrupadoSP[i].id === 0 ? [0] : [listaReporteDinamicoAgrupadoSP[i].id] : action.formulario.idsGerencias ? idsGerencias : null,
                    "idsGruposServicios": agrupadoConst === 'Grupo de Servicios' ? [listaReporteDinamicoAgrupadoSP[i].id] : action.formulario.idsGruposServicios ? idsGruposServicios : null,
                    "idsServicios": agrupadoConst === 'Servicio' ? [listaReporteDinamicoAgrupadoSP[i].id] : action.formulario.idsServicios ? idsServicios : null,
                    "idsPartidas": agrupadoConst === 'Partida' ? [listaReporteDinamicoAgrupadoSP[i].id] : action.formulario.idsPartidas ? idsPartidas : null,
                    "tipo": agrupadoConst === 'Tipos' ? listaReporteDinamicoAgrupadoSP[i].id : action.formulario.tipo ? action.formulario.tipo : null,
                    'estatusOrden': agrupadoConst === 'Estatus' ? [listaReporteDinamicoAgrupadoSP[i].id] : action.formulario.estatusOrden ? estatusOrden : null,
                    'tipoPrograma': agrupadoConst === 'Tipo de Programa' ? [listaReporteDinamicoAgrupadoSP[i].id] : action.formulario.tipoPrograma ? tipoPrograma : null,
                    'ubicacion': agrupadoConst === 'Area Funcional' ? listaReporteDinamicoAgrupadoSP[i].id === 'Sin asignacion' ? ['0'] : [listaReporteDinamicoAgrupadoSP[i].id] : null,
                    'unidadFuncional': action.formulario.unidadFuncional ? unidadFuncional : null,
                    "fechaInicio": action.formulario.rangoFechas ? action.formulario.rangoFechas[0].format('YYYY-MM-DD') : null,
                    "fechaFin": action.formulario.rangoFechas ? action.formulario.rangoFechas[1].format('YYYY-MM-DD') : null,
                    'activo': agrupadoConst === 'Activo' ? listaReporteDinamicoAgrupadoSP[i].id === 'Sin asignacion' ? ['0'] : [listaReporteDinamicoAgrupadoSP[i].id] : null,
                    'folioOrdenC': action.checkboxData.folioOrdenC ? action.checkboxData.folioOrdenC : null,
                    'gerenciaC': action.checkboxData.gerenciaC ? action.checkboxData.gerenciaC : null,
                    'grupoServiciosC': action.checkboxData.grupoServiciosC ? action.checkboxData.grupoServiciosC : null,
                    'servicioC': action.checkboxData.servicioC ? action.checkboxData.servicioC : null,
                    'partidaC': action.checkboxData.partidaC ? action.checkboxData.partidaC : null,
                    'tipoC': true,
                    'tipoProgramaC': action.checkboxData.tipoProgramaC ? action.checkboxData.tipoProgramaC : null,
                    'nombreRutinaC': action.checkboxData.nombreRutinaC ? action.checkboxData.nombreRutinaC : null,
                    'claveRutinaC': action.checkboxData.claveRutinaC ? action.checkboxData.claveRutinaC : null,
                    'ubicacionC': action.checkboxData.ubicacionC ? action.checkboxData.ubicacionC : null,
                    'estatusOrdenC': action.checkboxData.estatusOrdenC ? action.checkboxData.estatusOrdenC : null,
                    'fechaC': true,
                    'nombreActivoC': action.checkboxData.nombreActivoC ? action.checkboxData.nombreActivoC : null,
                    'numeroSerieC': action.checkboxData.numeroSerieC ? action.checkboxData.numeroSerieC : null,
                    'codigoInternoC': action.checkboxData.codigoInternoC ? action.checkboxData.codigoInternoC : null,
                    'codigoEquipoC': action.checkboxData.codigoEquipoC ? action.checkboxData.codigoEquipoC : null,
                    'codigoActivoC': action.checkboxData.codigoActivoC ? action.checkboxData.codigoActivoC : null,
                    'estadoC': action.checkboxData.estadoC ? action.checkboxData.estadoC : null,
                    "page": (action.page - 1) * 10,
                    "offset": 10,
                    "order": action.order.order,
                    "field": action.order.field === 'ru.Tipo' ? 'osp.FolioOrden' : action.order.field,
                    "tipoAgrupamiento": null,
                }
                console.log('dataAgrupado', dataAgrupado);

                const responseAgrupado = yield call(reporteDinamicoSPCall, token, dataAgrupado)
                console.log('responseAgrupado', responseAgrupado)
                if(responseAgrupado.status === 200){
                    listaReporteDinamicoAgrupadoSP[i].agrupados = responseAgrupado.data;
                    listaReporteDinamicoAgrupadoSP[i].agrupados.page = 1;
                    for(let x in listaReporteDinamicoAgrupadoSP[i].agrupados.data){
                        listaReporteDinamicoAgrupadoSP[i].agrupados.data[x].id = parseInt(x) + 1;
                        listaReporteDinamicoAgrupadoSP[i].agrupados.data[x].fecha = listaReporteDinamicoAgrupadoSP[i].agrupados.data[x].fecha.split('T')[0].split('-').reverse().join('-');
                        if(!listaReporteDinamicoAgrupadoSP[i].agrupados.data[x].gerencia){
                            listaReporteDinamicoAgrupadoSP[i].agrupados.data[x].gerencia = 'Sin gerencia asignada';
                        }
                        if(!listaReporteDinamicoAgrupadoSP[i].agrupados.data[x].estatusOrden){
                            listaReporteDinamicoAgrupadoSP[i].agrupados.data[x].estatusOrden = 'Sin estado asignado';
                        }
                        if(listaReporteDinamicoAgrupadoSP[i].agrupados.data[x].tipoPrograma === 'Rutina'){
                            listaReporteDinamicoAgrupadoSP[i].agrupados.data[x].nombreActivo = 'No aplica';
                            listaReporteDinamicoAgrupadoSP[i].agrupados.data[x].numeroSerie = 'No aplica';
                            listaReporteDinamicoAgrupadoSP[i].agrupados.data[x].codigoInterno = 'No aplica';
                            listaReporteDinamicoAgrupadoSP[i].agrupados.data[x].codigoEquipo = 'No aplica';
                            listaReporteDinamicoAgrupadoSP[i].agrupados.data[x].codigoActivo = 'No aplica';
                        }
                        else{
                            listaReporteDinamicoAgrupadoSP[i].agrupados.data[x].ubicacion = 'No aplica';
                        }
                    };
                }
                else{
                    const showErrorMsg = true;
                    const textMessage = responseAgrupado.message;

                    yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                    yield put({ type: serviciosPlanificadosActions.LISTA_REPORTE_DINAMICO_AGRUPADO_SP_FAILURE });
                }
            }

            console.log('listaReporteDinamicoAgrupadoSP', listaReporteDinamicoAgrupadoSP)
            yield put({ type: serviciosPlanificadosActions.LISTA_REPORTE_DINAMICO_AGRUPADO_SP_SUCCESS, listaReporteDinamicoAgrupadoSP, listaReporteDinamicoAgrupadoSPCantidad })

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.LISTA_REPORTE_DINAMICO_AGRUPADO_SP_FAILURE });
        }
    } catch (error) {
        yield put({ type: serviciosPlanificadosActions.LISTA_REPORTE_DINAMICO_AGRUPADO_SP_FAILURE });
    }
}
export function* reporteDinamicoAgrupadoSPSaga(){
    yield takeLatest(serviciosPlanificadosActions.LISTA_REPORTE_DINAMICO_AGRUPADO_SP_REQUEST, reporteDinamicoAgrupadoSP)
}

function* exportarReporteDinamicoPDFAgrupadoSP(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let idsGerencias = [];
        let idsGruposServicios = [];
        let idsServicios = [];
        let estatusOrden = [];
        let tipoPrograma = [];
        let areaFuncional = [];
        let unidadFuncional = [];
        let idsPartidas = [];

        if(action.formulario.idsGerencias){
            for(let i in action.formulario.idsGerencias){
                idsGerencias.push(action.formulario.idsGerencias[i].split(' - ')[0]);
            }
        }
        if(action.formulario.idsGruposServicios){
            for(let i in action.formulario.idsGruposServicios){
                idsGruposServicios.push(action.formulario.idsGruposServicios[i].split(' - ')[0]);
            }
        }
        if(action.formulario.idsServicios){
            for(let i in action.formulario.idsServicios){
                idsServicios.push(action.formulario.idsServicios[i].split(' - ')[0]);
            }
        }
        if(action.formulario.idsPartidas){
            for(let i in action.formulario.idsPartidas){
                idsPartidas.push(action.formulario.idsPartidas[i].split(' - ')[0]);
            }
        }
        if(action.formulario.estatusOrden){
            for(let i in action.formulario.estatusOrden){
                estatusOrden.push(action.formulario.estatusOrden[i]);
            }
        }
        if(action.formulario.tipoPrograma){
            for(let i in action.formulario.tipoPrograma){
                tipoPrograma.push(action.formulario.tipoPrograma[i]);
            }
        }

        if(action.formulario.areaFuncional){
            areaFuncional.push(action.formulario.areaFuncional.split(' - ')[0]);
        }
        if(action.formulario.unidadFuncional){
            unidadFuncional.push(action.formulario.unidadFuncional.split(' - ')[0]);
        }

        const data = {
            "idsGerencias": action.formulario.idsGerencias ? idsGerencias : null,
            "idsGruposServicios": action.formulario.idsGruposServicios ? idsGruposServicios : null,
            "idsServicios": action.formulario.idsServicios ? idsServicios : null,
            "idsPartidas": action.formulario.idsPartidas ? idsPartidas : null,
            "tipo": action.formulario.tipo ? action.formulario.tipo : null,
            'estatusOrden': action.formulario.estatusOrden ? estatusOrden : null,
            'tipoPrograma': action.formulario.tipoPrograma ? tipoPrograma : null,
            'ubicacion': action.formulario.areaFuncional ? areaFuncional : null,
            'unidadFuncional': action.formulario.unidadFuncional ? unidadFuncional : null,
            "fechaInicio": action.formulario.rangoFechas ? action.formulario.rangoFechas[0].format('YYYY-MM-DD') : null,
            "fechaFin": action.formulario.rangoFechas ? action.formulario.rangoFechas[1].format('YYYY-MM-DD') : null,
            'activo': null,
            'folioOrdenC': action.checkboxData.folioOrdenC ? action.checkboxData.folioOrdenC : null,
            'gerenciaC': action.checkboxData.gerenciaC ? action.checkboxData.gerenciaC : null,
            'grupoServiciosC': action.checkboxData.grupoServiciosC ? action.checkboxData.grupoServiciosC : null,
            'servicioC': action.checkboxData.servicioC ? action.checkboxData.servicioC : null,
            'partidaC': action.checkboxData.partidaC ? action.checkboxData.partidaC : null,
            'tipoC': true,
            'tipoProgramaC': action.checkboxData.tipoProgramaC ? action.checkboxData.tipoProgramaC : null,
            'nombreRutinaC': action.checkboxData.nombreRutinaC ? action.checkboxData.nombreRutinaC : null,
            'claveRutinaC': action.checkboxData.claveRutinaC ? action.checkboxData.claveRutinaC : null,
            'ubicacionC': action.checkboxData.ubicacionC ? action.checkboxData.ubicacionC : null,
            'estatusOrdenC': action.checkboxData.estatusOrdenC ? action.checkboxData.estatusOrdenC : null,
            'fechaC': true,
            'nombreActivoC': action.checkboxData.nombreActivoC ? action.checkboxData.nombreActivoC : null,
            'numeroSerieC': action.checkboxData.numeroSerieC ? action.checkboxData.numeroSerieC : null,
            'codigoInternoC': action.checkboxData.codigoInternoC ? action.checkboxData.codigoInternoC : null,
            'codigoEquipoC': action.checkboxData.codigoEquipoC ? action.checkboxData.codigoEquipoC : null,
            'codigoActivoC': action.checkboxData.codigoActivoC ? action.checkboxData.codigoActivoC : null,
            'estadoC': action.checkboxData.estadoC ? action.checkboxData.estadoC : null,
            "page": null,
            "offset": null,
            "order": action.order.order,
            "field": action.order.field === 'ru.Tipo' ? 'osp.FolioOrden' : action.order.field,
            "tipoAgrupamiento": action.agrupado ? action.agrupado : action.formulario.tipoAgrupado,
        }
        console.log(`data`, data);

        const response = yield call(pdfReportesAgrupadoSPCall, token, data)
        console.log("response data agrupados SP",response)

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const titulo = action.tituloDocumento ? action.tituloDocumento : 'ReporteSP_'
            link.download = titulo + + new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({type: serviciosPlanificadosActions.PDF_REPORTE_DINAMICO_AGRUPADO_SP_SUCCESS})

            yield put({
                type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG,
                showSuccessMsg: true,
                textMessage: 'El archivo se descargó de forma correcta'
            });

        } else {
            const showErrorMsg = true;
            const textMessage = 'No se pudo descargar el PDF con las columnas y filtros seleccionados';

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: serviciosPlanificadosActions.PDF_REPORTE_DINAMICO_AGRUPADO_SP_FAILURE});
        }
    } catch (error) {
        yield put({type: serviciosPlanificadosActions.PDF_REPORTE_DINAMICO_AGRUPADO_SP_FAILURE});
    }
}
export function* exportarReporteDinamicoPDFAgrupadoSPSaga() {
    yield takeLatest(serviciosPlanificadosActions.PDF_REPORTE_DINAMICO_AGRUPADO_SP_REQUEST, exportarReporteDinamicoPDFAgrupadoSP)
}

function* reporteDinamicoExcelAgrupadoSP(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');

        let idsGerencias = [];
        let idsGruposServicios = [];
        let idsServicios = [];
        let estatusOrden = [];
        let tipoPrograma = [];
        let areaFuncional = [];
        let unidadFuncional = [];
        let idsPartidas = [];

        if(action.formulario.idsGerencias){
            for(let i in action.formulario.idsGerencias){
                idsGerencias.push(action.formulario.idsGerencias[i].split(' - ')[0]);
            }
        }
        if(action.formulario.idsGruposServicios){
            for(let i in action.formulario.idsGruposServicios){
                idsGruposServicios.push(action.formulario.idsGruposServicios[i].split(' - ')[0]);
            }
        }
        if(action.formulario.idsServicios){
            for(let i in action.formulario.idsServicios){
                idsServicios.push(action.formulario.idsServicios[i].split(' - ')[0]);
            }
        }
        if(action.formulario.idsPartidas){
            for(let i in action.formulario.idsPartidas){
                idsPartidas.push(action.formulario.idsPartidas[i].split(' - ')[0]);
            }
        }
        if(action.formulario.estatusOrden){
            for(let i in action.formulario.estatusOrden){
                estatusOrden.push(action.formulario.estatusOrden[i]);
            }
        }
        if(action.formulario.tipoPrograma){
            for(let i in action.formulario.tipoPrograma){
                tipoPrograma.push(action.formulario.tipoPrograma[i]);
            }
        }

        if(action.formulario.areaFuncional){
            areaFuncional.push(action.formulario.areaFuncional.split(' - ')[0]);
        }
        if(action.formulario.unidadFuncional){
            unidadFuncional.push(action.formulario.unidadFuncional.split(' - ')[0]);
        }


        const data = {
            "idsGerencias": action.formulario.idsGerencias ? idsGerencias : null,
            "idsGruposServicios": action.formulario.idsGruposServicios ? idsGruposServicios : null,
            "idsServicios": action.formulario.idsServicios ? idsServicios : null,
            "idsPartidas": action.formulario.idsPartidas ? idsPartidas : null,
            "tipo": action.formulario.tipo ? action.formulario.tipo : null,
            'estatusOrden': action.formulario.estatusOrden ? estatusOrden : null,
            'tipoPrograma': action.formulario.tipoPrograma ? tipoPrograma : null,
            'ubicacion': action.formulario.areaFuncional ? areaFuncional : null,
            'unidadFuncional': action.formulario.unidadFuncional ? unidadFuncional : null,
            "fechaInicio": action.formulario.rangoFechas ? action.formulario.rangoFechas[0].format('YYYY-MM-DD') : null,
            "fechaFin": action.formulario.rangoFechas ? action.formulario.rangoFechas[1].format('YYYY-MM-DD') : null,
            'activo': null,
            'folioOrdenC': action.checkboxData.folioOrdenC ? action.checkboxData.folioOrdenC : null,
            'gerenciaC': action.checkboxData.gerenciaC ? action.checkboxData.gerenciaC : null,
            'grupoServiciosC': action.checkboxData.grupoServiciosC ? action.checkboxData.grupoServiciosC : null,
            'servicioC': action.checkboxData.servicioC ? action.checkboxData.servicioC : null,
            'partidaC': action.checkboxData.partidaC ? action.checkboxData.partidaC : null,
            'tipoC': true,
            'tipoProgramaC': action.checkboxData.tipoProgramaC ? action.checkboxData.tipoProgramaC : null,
            'nombreRutinaC': action.checkboxData.nombreRutinaC ? action.checkboxData.nombreRutinaC : null,
            'claveRutinaC': action.checkboxData.claveRutinaC ? action.checkboxData.claveRutinaC : null,
            'ubicacionC': action.checkboxData.ubicacionC ? action.checkboxData.ubicacionC : null,
            'estatusOrdenC': action.checkboxData.estatusOrdenC ? action.checkboxData.estatusOrdenC : null,
            'fechaC': true,
            'nombreActivoC': action.checkboxData.nombreActivoC ? action.checkboxData.nombreActivoC : null,
            'numeroSerieC': action.checkboxData.numeroSerieC ? action.checkboxData.numeroSerieC : null,
            'codigoInternoC': action.checkboxData.codigoInternoC ? action.checkboxData.codigoInternoC : null,
            'codigoEquipoC': action.checkboxData.codigoEquipoC ? action.checkboxData.codigoEquipoC : null,
            'codigoActivoC': action.checkboxData.codigoActivoC ? action.checkboxData.codigoActivoC : null,
            'estadoC': action.checkboxData.estadoC ? action.checkboxData.estadoC : null,
            "page": null,
            "offset": null,
            "order": action.order.order,
            "field": action.order.field === 'ru.Tipo' ? 'osp.FolioOrden' : action.order.field,
            "tipoAgrupamiento": action.agrupado ? action.agrupado : action.formulario.tipoAgrupado,
        }
        console.log(`data`, data);

        const response = yield call(excelReportesAgrupadoSPCall, token, data)
        console.log(response)

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const titulo = action.tituloDocumento ? action.tituloDocumento : 'ReporteSP_'
            link.download = titulo + new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: serviciosPlanificadosActions.EXCEL_REPORTE_DINAMICO_AGRUPADO_SP_SUCCESS })
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage: 'El archivo se descargó de forma correcta' });

        } else {
            const showErrorMsg = true;
            const textMessage = 'No se pudo descargar el Excel con las columnas y filtros seleccionados';

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: serviciosPlanificadosActions.EXCEL_REPORTE_DINAMICO_AGRUPADO_SP_FAILURE});
        }
    } catch (error) {
        yield put({type: serviciosPlanificadosActions.EXCEL_REPORTE_DINAMICO_AGRUPADO_SP_FAILURE});
    }
}
export function* reporteDinamicoExcelAgrupadoSPSaga() {
    yield takeLatest(serviciosPlanificadosActions.EXCEL_REPORTE_DINAMICO_AGRUPADO_SP_REQUEST, reporteDinamicoExcelAgrupadoSP)
}

function* crearRegistroReporteSP(action) {
    try {
        console.log("crearRegistroReporteSP action",action)
        const token = sessionStorage.getItem('token');
        let idsGerencias = [];
        let idsGruposServicios = [];
        let idsServicios = [];
        let estatusOrden = [];
        let tipoPrograma = [];
        let areaFuncional = [];
        let unidadFuncional = [];
        let idsPartidas = [];

        if(action.data.formulario.idsGerencias){
            for(let i in action.data.formulario.idsGerencias){
                idsGerencias.push(action.data.formulario.idsGerencias[i].split(' - ')[0]);
            }
        }
        if(action.data.formulario.idsGruposServicios){
            for(let i in action.data.formulario.idsGruposServicios){
                idsGruposServicios.push(action.data.formulario.idsGruposServicios[i].split(' - ')[0]);
            }
        }
        if(action.data.formulario.idsServicios){
            for(let i in action.data.formulario.idsServicios){
                idsServicios.push(action.data.formulario.idsServicios[i].split(' - ')[0]);
            }
        }
        if(action.data.formulario.idsPartidas){
            for(let i in action.data.formulario.idsPartidas){
                idsPartidas.push(action.data.formulario.idsPartidas[i].split(' - ')[0]);
            }
        }
        if(action.data.formulario.estatusOrden){
            for(let i in action.data.formulario.estatusOrden){
                estatusOrden.push(action.data.formulario.estatusOrden[i]);
            }
        }
        if(action.data.formulario.tipoPrograma){
            for(let i in action.data.formulario.tipoPrograma){
                tipoPrograma.push(action.data.formulario.tipoPrograma[i]);
            }
        }
        
        if(action.data.formulario.areaFuncional){
            areaFuncional.push(action.data.formulario.areaFuncional.split(' - ')[0]);
        }
        if(action.data.formulario.unidadFuncional){
            unidadFuncional.push(action.data.formulario.unidadFuncional.split(' - ')[0]);
        }

        const data = {
            "reporteDinamicoOrdenServiciosPlanificadosRequest":{
                "idsGerencias": action.data.formulario.idsGerencias ? idsGerencias : null,
                "idsGruposServicios": action.data.formulario.idsGruposServicios ? idsGruposServicios : null,
                "idsServicios": action.data.formulario.idsServicios ? idsServicios : null,
                "idsPartidas": action.data.formulario.idsPartidas ? idsPartidas : null,
                "tipo": action.data.formulario.tipo ? action.data.formulario.tipo : null,
                'estatusOrden': action.data.formulario.estatusOrden ? estatusOrden : null,
                'tipoPrograma': action.data.formulario.tipoPrograma ? tipoPrograma : null,
                'ubicacion': action.data.formulario.areaFuncional ? areaFuncional : null,
                'unidadFuncional': action.data.formulario.unidadFuncional ? unidadFuncional : null,
                "fechaInicio": action.data.formulario.rangoFechas ? action.data.formulario.rangoFechas[0].format('YYYY-MM-DD') : null,
                "fechaFin": action.data.formulario.rangoFechas ? action.data.formulario.rangoFechas[1].format('YYYY-MM-DD') : null,
                'activo': null,
                'folioOrdenC': action.data.checkboxData.folioOrdenC ? action.data.checkboxData.folioOrdenC : null,
                'gerenciaC': action.data.checkboxData.gerenciaC ? action.data.checkboxData.gerenciaC : null,
                'grupoServiciosC': action.data.checkboxData.grupoServiciosC ? action.data.checkboxData.grupoServiciosC : null,
                'servicioC': action.data.checkboxData.servicioC ? action.data.checkboxData.servicioC : null,
                'partidaC': action.data.checkboxData.partidaC ? action.data.checkboxData.partidaC : null,
                'tipoC': true,
                'tipoProgramaC': action.data.checkboxData.tipoProgramaC ? action.data.checkboxData.tipoProgramaC : null,
                'nombreRutinaC': action.data.checkboxData.nombreRutinaC ? action.data.checkboxData.nombreRutinaC : null,
                'claveRutinaC': action.data.checkboxData.claveRutinaC ? action.data.checkboxData.claveRutinaC : null,
                'ubicacionC': action.data.checkboxData.ubicacionC ? action.data.checkboxData.ubicacionC : null,
                'estatusOrdenC': action.data.checkboxData.estatusOrdenC ? action.data.checkboxData.estatusOrdenC : null,
                'fechaC': true,
                'nombreActivoC': action.data.checkboxData.nombreActivoC ? action.data.checkboxData.nombreActivoC : null,
                'numeroSerieC': action.data.checkboxData.numeroSerieC ? action.data.checkboxData.numeroSerieC : null,
                'codigoInternoC': action.data.checkboxData.codigoInternoC ? action.data.checkboxData.codigoInternoC : null,
                'codigoEquipoC': action.data.checkboxData.codigoEquipoC ? action.data.checkboxData.codigoEquipoC : null,
                'codigoActivoC': action.data.checkboxData.codigoActivoC ? action.data.checkboxData.codigoActivoC : null,
                'estadoC': action.data.checkboxData.estadoC ? action.data.checkboxData.estadoC : null,
                "page": null,
                "offset": null,
                "order": action.data.order.order,
                "field": action.data.order.field === 'ru.Tipo' ? 'osp.FolioOrden' : action.data.order.field,
                "tipoAgrupamiento": action.data.agrupado ? action.data.agrupado : action.data.formulario.tipoAgrupado,
            },
            "reportesOrdenesServiciosPlanificados": {
                "idReporteOrdenesServiciosPlanificados": null,
                'titulo': action.formulario.titulo,
                "nombre": action.formulario.nombre,
                "descripcion": action.formulario.descripcion ? action.formulario.descripcion : '',
                "idUsuario": null,
                "valoresColumnas": "",
                "fechaAlta": null,
                "fechaActualizacion": null,
                "activo": true
            }
        }
        console.log(`data de crear SP`, data);

        const response = yield call(crearRegistroReporteSPCall, token, data)
        console.log("response de crear SP",response)

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se creó el reporte predeterminado de manera exitosa, descargando el reporte...";
            const order = { order: action.data.order.order, field: action.data.order.field}

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPlanificadosActions.MODAL_CREAR_REGISTRO_SP, showModalCrearRegistro: false });
            yield put({ type: serviciosPlanificadosActions.CREATE_REGISTROS_SP_SUCCESS});

            if(action.data.agrupado === 'SinAgrupar'){
                if(action.data.tipoDocumento === 'pdf'){
                    yield put({ type: serviciosPlanificadosActions.PDF_REPORTE_DINAMICO_SP_REQUEST, formulario: action.data.formulario, checkboxData: action.data.checkboxData, order, containerRegistros: true, tituloDocumento: action.formulario.nombre });
                }
                else{
                    yield put({ type: serviciosPlanificadosActions.EXCEL_REPORTE_DINAMICO_SP_REQUEST, formulario: action.data.formulario, checkboxData: action.data.checkboxData, order, containerRegistros: true, tituloDocumento: action.formulario.nombre });
                }
            } else {
                action.formulario.horarioEntrada = action.formulario.horarioEntrada ? action.formulario.horarioEntrada.format('HH:mm:ss') : null;
                if(action.data.tipoDocumento === 'pdf'){
                    yield put({ type: serviciosPlanificadosActions.PDF_REPORTE_DINAMICO_AGRUPADO_SP_REQUEST, formulario: action.data.formulario, checkboxData: action.data.checkboxData, agrupado: action.data.agrupado, order, containerRegistros: true, tituloDocumento: action.formulario.nombre });
                }
                else{
                    yield put({ type: serviciosPlanificadosActions.EXCEL_REPORTE_DINAMICO_AGRUPADO_SP_REQUEST, formulario: action.data.formulario, checkboxData: action.data.checkboxData, agrupado: action.data.agrupado, order, containerRegistros: true, tituloDocumento: action.formulario.nombre });
                }
            }
        } else {
            const showErrorMsg = true;
            const textMessage = 'No se pudo descargar el PDF con las columnas y filtros seleccionados';

            yield put({type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage});
            yield put({type: serviciosPlanificadosActions.CREATE_REGISTROS_SP_FAILURE});
        }
    } catch (error) {
        yield put({type: serviciosPlanificadosActions.CREATE_REGISTROS_SP_FAILURE});
    }
}
export function* crearRegistroReporteSPSaga() {
    yield takeLatest(serviciosPlanificadosActions.CREATE_REGISTROS_SP_REQUEST, crearRegistroReporteSP)
}

function* editarRegistroReporteSP(action) {
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idReporteOrdenesServiciosPlanificados = action.registroReporteSeleccionado.idReporteOrdenesServiciosPlanificados;

        let idsGerencias = [];
        let idsGruposServicios = [];
        let idsServicios = [];
        let estatusOrden = [];
        let tipoPrograma = [];
        let areaFuncional = [];
        let unidadFuncional = [];
        let idsPartidas = [];

        if(action.formulario.idsGerencias){
            for(let i in action.formulario.idsGerencias){
                idsGerencias.push(action.formulario.idsGerencias[i].split(' - ')[0]);
            }
        }
        if(action.formulario.idsGruposServiciosEditar){
            for(let i in action.formulario.idsGruposServiciosEditar){
                idsGruposServicios.push(action.formulario.idsGruposServiciosEditar[i].split(' - ')[0]);
            }
        }
        if(action.formulario.idsServiciosEditar){
            for(let i in action.formulario.idsServiciosEditar){
                idsServicios.push(action.formulario.idsServiciosEditar[i].split(' - ')[0]);
            }
        }
        if(action.formulario.idsPartidasEditar){
            for(let i in action.formulario.idsPartidasEditar){
                idsPartidas.push(action.formulario.idsPartidasEditar[i].split(' - ')[0]);
            }
        }
        if(action.formulario.estatusOrden){
            for(let i in action.formulario.estatusOrden){
                estatusOrden.push(action.formulario.estatusOrden[i]);
            }
        }
        if(action.formulario.tipoPrograma){
            for(let i in action.formulario.tipoPrograma){
                tipoPrograma.push(action.formulario.tipoPrograma[i]);
            }
        }
        
        if(action.formulario.areaFuncional){
            areaFuncional.push(action.formulario.areaFuncional.split(' - ')[0]);
        }
        if(action.formulario.unidadFuncionalEditar){
            unidadFuncional.push(action.formulario.unidadFuncionalEditar);
        }
        console.log('action.formulario.fecha', action.formulario.fecha)

        const data = {
            "reporteDinamicoOrdenServiciosPlanificadosRequest":{
                "idsGerencias": action.formulario.idsGerencias ? idsGerencias : null,
                "idsGruposServicios": action.formulario.idsGruposServicios ? idsGruposServicios : null,
                "idsServicios": action.formulario.idsServicios ? idsServicios : null,
                "idsPartidas": action.formulario.idsPartidas ? idsPartidas : null,
                "tipo": action.formulario.tipo ? action.formulario.tipo : null,
                'estatusOrden': action.formulario.estatusOrden ? estatusOrden : null,
                'tipoPrograma': action.formulario.tipoPrograma ? tipoPrograma : null,
                'ubicacion': action.formulario.areaFuncional ? areaFuncional : null,
                'unidadFuncional': action.formulario.unidadFuncional ? unidadFuncional : null,
                "fechaInicio": action.formulario.fecha ? action.formulario.fecha[0].format('YYYY-MM-DD') : null,
                "fechaFin": action.formulario.fecha ? action.formulario.fecha[1].format('YYYY-MM-DD') : null,
                'activo': null,
                'folioOrdenC': action.registroReporteSeleccionado.folioOrdenC ? action.registroReporteSeleccionado.folioOrdenC : null,
                'gerenciaC': action.registroReporteSeleccionado.gerenciaC ? action.registroReporteSeleccionado.gerenciaC : null,
                'grupoServiciosC': action.registroReporteSeleccionado.grupoServiciosC ? action.registroReporteSeleccionado.grupoServiciosC : null,
                'servicioC': action.registroReporteSeleccionado.servicioC ? action.registroReporteSeleccionado.servicioC : null,
                'partidaC': action.registroReporteSeleccionado.partidaC ? action.registroReporteSeleccionado.partidaC : null,
                'tipoC': true,
                'tipoProgramaC': action.registroReporteSeleccionado.tipoProgramaC ? action.registroReporteSeleccionado.tipoProgramaC : null,
                'nombreRutinaC': action.registroReporteSeleccionado.nombreRutinaC ? action.registroReporteSeleccionado.nombreRutinaC : null,
                'claveRutinaC': action.registroReporteSeleccionado.claveRutinaC ? action.registroReporteSeleccionado.claveRutinaC : null,
                'ubicacionC': action.registroReporteSeleccionado.ubicacionC ? action.registroReporteSeleccionado.ubicacionC : null,
                'estatusOrdenC': action.registroReporteSeleccionado.estatusOrdenC ? action.registroReporteSeleccionado.estatusOrdenC : null,
                'fechaC': true,
                'nombreActivoC': action.registroReporteSeleccionado.nombreActivoC ? action.registroReporteSeleccionado.nombreActivoC : null,
                'numeroSerieC': action.registroReporteSeleccionado.numeroSerieC ? action.registroReporteSeleccionado.numeroSerieC : null,
                'codigoInternoC': action.registroReporteSeleccionado.codigoInternoC ? action.registroReporteSeleccionado.codigoInternoC : null,
                'codigoEquipoC': action.registroReporteSeleccionado.codigoEquipoC ? action.registroReporteSeleccionado.codigoEquipoC : null,
                'codigoActivoC': action.registroReporteSeleccionado.codigoActivoC ? action.registroReporteSeleccionado.codigoActivoC : null,
                'estadoC': action.registroReporteSeleccionado.estadoC ? action.registroReporteSeleccionado.estadoC : null,
                "page": null,
                "offset": null,
                "order": action.formulario.order,
                "field": action.formulario.field === 'ru.Tipo' ? 'osp.FolioOrden' : action.formulario.field,
                "tipoAgrupamiento": action.formulario.tipoAgrupado,
            },
            "reportesOrdenesServiciosPlanificados": {
                "idReporteOrdenesServiciosPlanificados": idReporteOrdenesServiciosPlanificados,
                'titulo': action.formulario.titulo,
                "nombre": action.formulario.nombre,
                "descripcion": action.formulario.descripcion ? action.formulario.descripcion : '',
                "idUsuario": action.registroReporteSeleccionado.idUsuario,
                "valoresColumnas": "",
                "fechaAlta": null,
                "fechaActualizacion": null,
                "activo": true
            }
        }
        console.log(`data`, data);

        const response = yield call(editarRegistroReporteSPCall, token, idReporteOrdenesServiciosPlanificados, data);
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se editó el reporte predeterminado de manera exitosa";

            if (action.personal) {
                yield put({ type: serviciosPlanificadosActions.LISTA_REGISTROS_SP_ESPECIAL_REQUEST, page: action.page });
            } else {
                yield put({ type: serviciosPlanificadosActions.LISTA_REGISTROS_SP_REQUEST, page: action.page});
            }

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage});
            yield put({ type: serviciosPlanificadosActions.MODAL_EDITAR_REGISTRO_SP, showModalEditarRegistro: false, page: action.page, personal: action.personal });

            yield put({type: serviciosPlanificadosActions.EDITAR_REGISTROS_SP_SUCCESS});
        } else {
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg: true, textMessage: response.message });
            yield put({ type: serviciosPlanificadosActions.EDITAR_REGISTROS_SP_FAILURE})
        }
    } catch (error) {
        yield put({type: serviciosPlanificadosActions.EDITAR_REGISTROS_SP_FAILURE})
    }
}

export function* editarRegistroReporteSPSaga() {
    yield takeLatest(serviciosPlanificadosActions.EDITAR_REGISTROS_SP_REQUEST, editarRegistroReporteSP)
}