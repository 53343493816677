import React, { Component } from 'react';
import {Input,Button,Modal,Col,Select,Row,Table,message,Divider, Tooltip} from 'antd';
import { connect } from 'react-redux';
import { ArrowLeftOutlined, ArrowRightOutlined} from '@ant-design/icons';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option } = Select;
const { Search } = Input;

class ModalCatalogoPorTipoDeFolio extends Component {
    formRef = React.createRef();
    state = {
        page: 1,
        idSubFamilia: undefined,
        order: 'ASC',
        field: 'nombre',
        busqueda: '',
        selectedRowKeys: [], // Check here to configure the default column
        selectedRows: [],
        showTable: false,
        idUbicacion: null,
        selectUbicacion: false,
    }
    componentDidMount(){
        this.props.onGetSubfamilias();
    }
    render() {
        const {
            onShowModalNuevoFolio, selectedRowKeys,
            showModalNuevoFolio, pageMovimientos,
            fetchingListaProductosBusqueda, onGetRequisicion,
            listaProductosBusqueda,  formularioNuevoFolio,
            listaProductosBusquedaCantidad, onGetInventario,
            onGetProductos, listaSubfamiliasProductosActivos,
            fetchingListaSubfamiliasProductos, onActualizarTabla, hasSelected, 
            fetchingListaSubfamilias, listaSubfamilias, tipoActivos, listaInventarioTipoActivos,
            fetchingListaInventarioTipoActivos, listaInventarioTipoActivosCantidad,
        } = this.props;
        const folioOrigen = formularioNuevoFolio.folioOrigen; 


        console.log('lista tipo activos: ', tipoActivos);
        const columns = formularioNuevoFolio.idTipoFolio.split('-')[1] === "Suma" ? 
        [
            { title: 'Código de almacén', dataIndex: 'codigoAdicional', key: 'codigoAdicional', align: 'center' },
            { title: 'Nombre del producto', dataIndex: 'nombre',  key: 'nombre', align: 'center', }, 
            { title: 'Clave del producto', dataIndex: 'clave', key: 'clave', align: 'center', }, 
            { title: 'SKU', dataIndex: 'sku', key: 'sku', align: 'center', },
            { title: 'Presentación', dataIndex: 'presentacion', key: 'presentacion', align: 'center' },
            { title: 'Costo unitario', dataIndex: 'precioTabla', key: 'precioTabla', align: 'right', },
            // { title: 'Tipo moneda',  dataIndex: 'tipoMoneda', key: 'tipoMoneda',  align: 'center', },
            { title: 'Cantidad', dataIndex: 'cantidad', key: 'cantidad', align: 'center', },
            { title: 'Ubicacion producto origen', dataIndex: 'ubicacionO', key: 'ubicacionO',  align: 'center', },
            { title: 'Lote', dataIndex: 'lote', key: 'lote',  align: 'center', },
            { title: 'Fecha Caducidad', dataIndex: 'fechaCaducidadC',  key: 'fechaCaducidadC',  align: 'center', }
        ] :
        [
            { title: 'Código de almacén', dataIndex: 'codigoAdicional', key: 'codigoAdicional', align: 'center' },
            { title: 'Nombre del producto', dataIndex: 'nombre',  key: 'nombre', align: 'center', }, 
            { title: 'Clave del producto', dataIndex: 'clave', key: 'clave', align: 'center', },
            { title: 'Número de serie',  dataIndex: 'serieNum', key: 'serieNum', align: 'center', render: (text, record) => (
                record.serieNum ? text : 'No aplica'
            ), },
            { title: 'SKU', dataIndex: 'sku', key: 'sku', align: 'center', },
            { title: 'Presentación', dataIndex: 'presentacion', key: 'presentacion', align: 'center' },
            { title: 'Cantidad', dataIndex: 'cantidad', key: 'cantidad', align: 'center', },
            { title: 'Ubicacion producto origen', dataIndex: 'ubicacionO', key: 'ubicacionO',  align: 'center', },
            { title: 'Lote', dataIndex: 'lote', key: 'lote',  align: 'center', },
            { title: 'Fecha Caducidad', dataIndex: 'fechaCaducidadC',  key: 'fechaCaducidadC',  align: 'center', }
        ];
           
       const onBuscarProducto = (busqueda, name) => {
            this.setState({
                [name]: busqueda,
                page: 1,
            });
            if(formularioNuevoFolio.idTipoFolio.split('-')[2] === 'true'){
                if(name === 'busqueda'){
                    onGetRequisicion(1, folioOrigen, busqueda, this.state.idSubFamilia);
                } else {
                    onGetRequisicion(1, folioOrigen, this.state.busqueda, busqueda);
                }
                } else {
                if(formularioNuevoFolio.idTipoFolio.split('-')[1] === "Suma"){
                    if(name === 'busqueda'){
                        onGetProductos(1, busqueda, this.state.field, this.state.order, this.state.idSubFamilia)
                    } else {
                        onGetProductos(1, this.state.busqueda, this.state.field, this.state.order, busqueda)
                    }
                } else {
                    if(tipoActivos){
                        if(name === 'busqueda'){
                            this.props.onGetInventarioTipoActivos(1, formularioNuevoFolio.idAlmacenOrigen.split('-')[0], busqueda, this.state.idSubFamilia)
                        } else {
                            this.props.onGetInventarioTipoActivos(1, formularioNuevoFolio.idAlmacenOrigen.split('-')[0], this.state.busqueda, busqueda)
                        }
                    }
                    else{
                        if(name === 'busqueda'){
                            onGetInventario(1, busqueda, this.state.idSubFamilia, '', formularioNuevoFolio.idAlmacenOrigen.split('-')[0])
                        } else {
                            onGetInventario(1, this.state.busqueda, busqueda, '', formularioNuevoFolio.idAlmacenOrigen.split('-')[0])
                        }
                    }
                } 
            }
       }  

        const vistaSiguiente = () => {
            this.props.onCambiarVista(3, this.props.listaSeleccionados, formularioNuevoFolio, tipoActivos);
        }
        const vistaAnterior= () => {
            this.props.onCambiarVista(1);
            this.props.onBorrarUbicaciones();
            this.props.onBorrarListas();
            this.props.onBorrarUbicacionesDestino();
            onActualizarTabla(null, [], 1, true);
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
           
            if(formularioNuevoFolio.idTipoFolio.split('-')[2] === 'true'){
                onGetRequisicion(pagination.current, folioOrigen, this.state.busqueda, this.state.idSubFamilia);
            } else {
                console.log('Entra al else paginacion')
                // if(formularioNuevoFolio.idTipoFolio.split('-')[1] === "Suma" || formularioNuevoFolio.idTipoFolio.split('-')[1] === "Resta"){
                if(formularioNuevoFolio.idTipoFolio.split('-')[1] === "Suma"){
                    onGetProductos(pagination.current, this.state.busqueda, this.state.field, this.state.order, this.state.idSubFamilia)
                } else {
                    if(tipoActivos){
                        this.props.onGetInventarioTipoActivos(pagination.current, formularioNuevoFolio.idAlmacenOrigen.split('-')[0], this.state.busqueda, this.state.idSubFamilia)
                    }
                    else{
                        onGetInventario(pagination.current, this.state.busqueda, this.state.idSubFamilia, '', formularioNuevoFolio.idAlmacenOrigen.split('-')[0])
                    }
                }
            }
            onActualizarTabla( this.props.listaSeleccionados, selectedRowKeys, pagination.current, hasSelected );
        }

        const onChangeTabla = (record, selected, selectedRows) => {
            console.log('record: ', record);
            console.log('selected: ', selected);

            let listaSelected = this.props.listaSeleccionados;
            const listaFinal = [];
            if(!listaSelected){
                listaSelected = [];
            }
            
            if(selected.length){
                for(let i in selected){
                    if(selected[i]){
                        selected[i].agregado = false;
                    }
                }
                for(let i in selected){
                    if(listaSelected.length){
                        console.log('If de lista no vacía')
                        for(let x in listaSelected){
                            if(selected[i]){
                                console.log('!selected[i].agregado', !selected[i].agregado)
                                if(!selected[i].agregado){
                                    console.log('listaSelected[x].rowKey, record[i]', listaSelected[x].rowKey, record[i])
                                    if(listaSelected[x].rowKey === record[i]){
                                        console.log('Entró al if de record')
                                        listaFinal.push(listaSelected[x]);
                                        selected[i].agregado = true;
                                    }
                                    else{
                                        console.log('Entró al else de record')
                                        listaFinal.push(selected[i]);
                                        selected[i].agregado = true;
                                    }
                                }
                            }
                            else{
                                if(listaSelected[x].rowKey === record[i]){
                                    console.log('Entró al else de undefined selected')
                                    listaFinal.push(listaSelected[x]);
                                }
                            }
                        }
                    }
                    else{
                        console.log('Else de primera vez')
                        listaFinal.push(selected[i]);
                    }
                }
            }
            console.log('listaFinal');
            console.log(listaFinal);
            
            let hasSelectedLocal = true
            
            if(record.length !== 0){
                hasSelectedLocal = false;
            }
            
            this.setState({ selectedRowKeys: record, selectedRows: selected });
            onActualizarTabla( listaFinal, record, pageMovimientos, hasSelectedLocal );
        };
       
      
        const rowSelection = {
            selectedRowKeys,
            onChange: onChangeTabla,
        };

        const handleCancel = () => {
            onShowModalNuevoFolio();
            this.props.onCambiarVista(1);
            this.props.onBorrarListas();
            this.props.onBorrarUbicaciones();
            this.props.onBorrarUbicacionesDestino();
            onActualizarTabla(null, [], 1, true);
        };
   

        return ( 
            <Modal
            visible={showModalNuevoFolio}
            title='Nuevo Folio'
            onCancel={handleCancel}
            footer={false}
            width={'80%'}
            >
                {console.log('lista productos busqueda:', listaProductosBusqueda)}    
                {console.log('lista inventario tipo activos:', listaInventarioTipoActivos)}    
                <Row type='flex' justify='space-between'>
                    {listaProductosBusqueda || listaInventarioTipoActivos ? (
                    <>
                        {tipoActivos ? (
                        <Col span={11}>
                            <Select allowClear
                                style={{ width: '100%' }}
                                placeholder="Selecciona la subfamilia"
                                loading={fetchingListaSubfamilias}
                                onChange={(value) => onBuscarProducto(value, "idSubFamilia")}
                            >
                                {listaSubfamilias.map(option => <Option key={option.idSubFamilia}>{option.subFamilia}</Option>)}
                            </Select>
                        </Col>
                        ): (
                        <Col span={11}>
                            <Select allowClear
                            style={{ width: '100%' }}
                            placeholder="Selecciona la subfamilia"
                            loading={fetchingListaSubfamiliasProductos}
                            onChange={(value) => onBuscarProducto(value, "idSubFamilia")}
                            >
                            {listaSubfamiliasProductosActivos.map(option => <Option key={option.idSubFamilia}>{option.subFamilia}</Option>)}
                            </Select>
                        </Col>
                        )}
                        <Col span={11}>
                            <Search className='buscarModulos' placeholder='Ingresa nombre/clave/SKU/código de almacén' onSearch={(value) => onBuscarProducto(value, "busqueda")} />
                        </Col>
                    </>
                    ): false}

                    <Col span={20}>
                        <Divider orientation="left">Agregar Productos</Divider>
                    </Col>

                    <Col span={1} style={{ paddingTop:'1em' }}>
                        <Tooltip title='Paso Anterior'>
                            <Button className="iconTer" shape="circle" onClick={vistaAnterior} icon={<ArrowLeftOutlined />}/>
                        </Tooltip>
                    </Col>

                    <Col span={1} style={{ paddingTop:'1em' }}>
                        <Tooltip title='Siguiente Paso'>
                            <Button className="iconTer" shape="circle" onClick={vistaSiguiente} icon={<ArrowRightOutlined />} disabled={hasSelected}/>
                        </Tooltip>
                    </Col>
                    
                    <Col span={24}>
                        <br />
                        <Table
                            size="small"
                            rowKey="rowKey"
                            columns={columns}
                            dataSource={tipoActivos ? listaInventarioTipoActivos : listaProductosBusqueda}
                            loading={tipoActivos ? fetchingListaInventarioTipoActivos : fetchingListaProductosBusqueda}
                            locale={{ emptyText: 'Sin datos de productos' }}
                            onChange={handleTableChange}
                            pagination={{ total: tipoActivos ? listaInventarioTipoActivosCantidad : listaProductosBusquedaCantidad, simple: true, current: this.state.page }}
                            showSorterTooltip={true}
                            rowSelection={rowSelection}
                        />
                    </Col>
                             
                    {/* <Col span={10}>
                        <Button onClick={vistaAnterior}  className='nuevoUsuario'>Anterior Paso</Button>
                    </Col>
                    <Col span={10}>
                        <Button onClick={vistaSiguiente}  disabled={hasSelected} className='nuevoUsuario'>Siguiente Paso</Button>
                    </Col> */}
                </Row>
            </Modal>
            
        );
    }
}

const mapStateToProps = state => {
	return {
        showModalNuevoFolio: state.AlmacenesReducer.showModalNuevoFolio,
        listaProductosBusqueda: state.AlmacenesReducer.listaProductosBusqueda,
        fetchingListaProductosBusqueda: state.AlmacenesReducer.fetchingListaProductosBusqueda,
        listaProductosBusquedaCantidad: state.AlmacenesReducer.listaProductosBusquedaCantidad,
        fetchingListaSubfamiliasProductos: state.CatalogosGeneralesReducer.fetchingListaSubfamiliasProductos,
        listaSubfamiliasProductosActivos: state.CatalogosGeneralesReducer.listaSubfamiliasProductosActivos,
        formularioNuevoFolio: state.AlmacenesReducer.formularioNuevoFolio,
        listaUbicaciones: state.AlmacenesReducer.listaUbicaciones,
        fetchingObtenerUbicacion: state.AlmacenesReducer.fetchingObtenerUbicacion,
        fetchingObtenerUbicacionDestino: state.AlmacenesReducer.fetchingObtenerUbicacionDestino,
        listaUbicacionesDestino: state.AlmacenesReducer.listaUbicacionesDestino,
        pageMovimientos: state.AlmacenesReducer.pageMovimientos,
        listaSeleccionados: state.AlmacenesReducer.listaSeleccionados,
        selectedRowKeys: state.AlmacenesReducer.selectedRowKeys,
        hasSelected: state.AlmacenesReducer.hasSelected,
        tipoActivos: state.AlmacenesReducer.tipoActivos,
        fetchingListaSubfamilias: state.CatalogosGeneralesReducer.fetchingListaSubfamilias,
        listaSubfamilias: state.CatalogosGeneralesReducer.listaSubfamilias,
        fetchingListaInventarioTipoActivos: state.AlmacenesReducer.fetchingListaInventarioTipoActivos,
        listaInventarioTipoActivos: state.AlmacenesReducer.listaInventarioTipoActivos,
        listaInventarioTipoActivosCantidad: state.AlmacenesReducer.listaInventarioTipoActivosCantidad,
    };
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalNuevoFolio: () => {
            dispatch({ type: "SHOW_MODAL_NUEVO_FOLIO", showModalNuevoFolio: false})
        },
        onGetSubfamilias: () => {
            dispatch({ type: 'LISTA_SUBFAMILIAS_PRODUCTOS_REQUEST' });
        },
        onCambiarVista: (vistaModalNuevoFolio, listaSeleccionados, formularioNuevoFolio, tipoActivos) => {
            dispatch({ type: "CAMBIAR_VISTA_MODAL_NUEVOFOLIO", vistaModalNuevoFolio, listaSeleccionados, formularioNuevoFolio, tipoActivos});
        },
        onGetProductos: (page, busqueda, field, order ,idSubFamilia) => {
            dispatch({ type: "PRODUCTOS_BUSQUEDA_REQUEST", page: page, busqueda: busqueda, field: field, order: order, idSubFamilia:idSubFamilia });
        },
        onGetInventario: (page, busqueda, idSubFamilia, idFamilia, idAlmacenOrigen) => {
            dispatch({ type: "LISTA_INVENTARIO_REQUEST", page: page, busqueda: busqueda, idFamilia: idFamilia, idSubFamilia: idSubFamilia, idAlmacenOrigen: idAlmacenOrigen})
        },
        onGetRequisicion: (page, folioOrigen, busqueda, idSubFamilia, idFamilia) => {
            dispatch({ type: "LISTA_REQUISICION_DIRECTA_REQUEST", page: page, folioOrigen: folioOrigen, busqueda: busqueda, idFamilia: idFamilia, idSubFamilia: idSubFamilia});
        },
        onBorrarUbicaciones: () => {
            dispatch({ type: "OBTENER_UBICACION_POR_ALMACEN_SUCCESS", listaUbicaciones: null})
        },
        onBorrarUbicacionesDestino: () => {
            dispatch({ type: "UBICACION_DESTINO_POR_ALMACEN_SUCCESS", listaUbicacionesDestino: null})
        },
        onBorrarListas: () => {
            dispatch({ type: "LISTA_INVENTARIO_SUCCESS", listaProductosBusqueda: null});
        },
        onActualizarTabla: (listaSeleccionados, selectedRowKeys, pageMovimientos, hasSelected) => {
            dispatch({ type: 'ACTUALIZAR_TABLA_MOVIMIENTOS', listaSeleccionados, selectedRowKeys, pageMovimientos, hasSelected})
        },
        onGetInventarioTipoActivos: (page, idAlmacenOrigen, busqueda, idSubFamilia) => {
            dispatch({ type: 'LISTA_INVENTARIO_TIPO_ACTIVOS_REQUEST', page, idAlmacenOrigen, busqueda, idSubFamilia })
        },
	};
};
 
export default connect(mapStateToProps, mapDispatchToProps)(ModalCatalogoPorTipoDeFolio);