import React, { Component } from 'react';
import { Row, Col, Table, Button, message, Divider, Tooltip, Spin } from 'antd';
import { connect } from "react-redux";
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { EditOutlined, ExceptionOutlined, FileDoneOutlined } from '@ant-design/icons';

import ModalEditarProducto from './ModalEditarProducto';
import ModalCerrarFolio from './ModalCerrarFolio';

class OpcionesFolioRequisicion extends Component {
    formRef = React.createRef();
    state = {
        page: 1,
        botonEditar: false,
        botonConsulta: true,
        botonCerrar: false,
        botonCancelar: true,
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
    }
    render() { 
        const {fetchingListaConsultaFolio, listaConsultaFolio, page,
            listaConsultaFolioCantidad, busqueda,folioSeleccionado, limpiarFormulario,
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        };

        if(limpiarFormulario && limpiarFormulario === 'cerrarFolio'){
            console.log('entró a cerrarFolio');
            this.props.onLimpiarFormulario();
            this.props.onShowModalCerrarFolio();
            this.props.onCerrarModalOpciones();
        }

        const onEditar = key => {
            this.props.onModalEditarProducto(key);
        };

        const editarBoton = () => {
            if(folioSeleccionado.estadoFolio === "Abierto"){
                console.log('abro el grid de edicion')
                this.setState({ botonEditar: true })
            } else {
                console.log('mando mensaje warning')
                message.error('Solo puedes editar folios con estado "Abierto" ')
                this.setState({ botonEditar: false })
            }
        }


        const cerrarBoton = () => {
            let contador = 0;
            if(listaConsultaFolio[0].folioRequisicionMateriales){
                this.props.onCerrarFolio(page, folioSeleccionado, busqueda); 
            }
            else{
                for(let i = 0; i < listaConsultaFolio.length; i++){
                    if(listaConsultaFolio[i].nombreProveedor === "No tiene proveedor" || listaConsultaFolio[i].fechaProbableEntrega === "No tiene fecha probable de entrega"){
                        message.error('No puedes cerrar folio con productos sin Nombre de proveedor y fecha Probable de entrega');
                        i = listaConsultaFolio.length;
                    } else {
                        contador++;
                    }
                    
                }
                if(contador === listaConsultaFolio.length){
                    this.props.onModalCerrarFolio(); 
                } 
            }
        }

        const cancelarBoton = () => {
            this.props.onCancelarFolio(folioSeleccionado, page, busqueda);
        }
        
        const columns = [{
            title: 'Código Almacén',
            dataIndex: 'codigoAdicional',
            key: 'codigoAdicional',
            align: 'center',
        },{
            title: 'Producto/Insumo/Herramienta',
            dataIndex: 'nombre',
            key: 'nombre',
            align: 'center',
        },{
            title: 'Clave del Producto',
            dataIndex: 'clave',
            key: 'clave',
            align: 'center',
        },{
            title: 'Descripcion',
            dataIndex: 'descripcion',
            key: 'descripcion',
            align: 'center',
        },{
            title: 'Presentacion',
            dataIndex: 'presentacion',
            key: 'presentacion',
            align: 'center',
        },{
            title: 'Nombre del proveedor',
            dataIndex: 'nombreProveedor',
            key: 'nombreProveedor',
            align: 'center',
        },{
            title: 'Fecha probable de entrega',
            dataIndex: 'fechaProbableEntrega',
            key: 'fechaProbableEntrega',
            align: 'center',
        },{
            title: 'Editar',
            key: 'editar',
            align: 'center',
            render: (record) => (
               <a className='editarIcono' onClick={ () => onEditar(record) }> < EditOutlined />
               </a>
            ),          
        }];

        const columns2 = [{
            title: 'Código Almacén',
            dataIndex: 'codigoAdicional',
            key: 'codigoAdicional',
            align: 'center',
        },{
            title: 'Producto/Insumo/Herramienta',
            dataIndex: 'nombre',
            key: 'nombre',
            align: 'center',
        },{
            title: 'Clave del Producto',
            dataIndex: 'clave',
            key: 'clave',
            align: 'center',
        },{
            title: 'Descripcion',
            dataIndex: 'descripcion',
            key: 'descripcion',
            align: 'center',
        },{
            title: 'Presentacion',
            dataIndex: 'presentacion',
            key: 'presentacion',
            align: 'center',
        },{
            title: 'Nombre del proveedor',
            dataIndex: 'nombreProveedor',
            key: 'nombreProveedor',
            align: 'center',
        },{
            title: 'Fecha probable de entrega',
            dataIndex: 'fechaProbableEntrega',
            key: 'fechaProbableEntrega',
            align: 'center',
        }];

        const columnsCompra = [{
            title: 'Código Almacén',
            dataIndex: 'codigoAdicional',
            key: 'codigoAdicional',
            align: 'center',
        },{
            title: 'Producto/Insumo/Herramienta',
            dataIndex: 'nombre',
            key: 'nombre',
            align: 'center',
        },{
            title: 'Clave del Producto',
            dataIndex: 'clave',
            key: 'clave',
            align: 'center',
        },{
            title: 'Descripcion',
            dataIndex: 'descripcion',
            key: 'descripcion',
            align: 'center',
        },{
            title: 'Presentacion',
            dataIndex: 'presentacion',
            key: 'presentacion',
            align: 'center',
        },{
            title: 'Cantidad Requerida',
            dataIndex: 'cantidad',
            key: 'cantidad',
            align: 'center',
        }];
        
        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            this.props.onGetConsultaFolio(folioSeleccionado.numeroFolio, pagination.current);
        };

        
        return ( 
            <Spin spinning={this.props.fetchingCancelarFolioRequisicionPuntoReorden || this.props.fetchingCerrarFolioRequisicionPuntoReorden}>
                <Row type='flex' justify='space-between' gutter={[8,8]}>
                <Col span={19}>
                        <Divider orientation='left'>Acciones</Divider>
                    </Col>

                    <Col span={1} style={{ paddingTop:'1em' }}>
                        <Tooltip title='Editar Folio' placement='topRight'>
                            <Button className="iconTer" shape="circle" onClick={editarBoton} disabled={!permisosUsuario.editarFolioDeRequisicionPorReorden ? true : folioSeleccionado?.estadoFolio !== "Abierto" ? true : listaConsultaFolio.length ? listaConsultaFolio[0].folioRequisicionMateriales ? true : false : false} icon={<EditOutlined />} />
                        </Tooltip>
                    </Col>

                    <Col span={1} style={{ paddingTop:'1em' }}>
                        <Tooltip title='Cancelar Folio' placement='topRight'>
                            <Button className="iconTer" shape="circle" onClick={cancelarBoton} disabled={!permisosUsuario.cancelarFolioDeRequisicionPorReorden ? true : folioSeleccionado?.estadoFolio !== "Abierto"} icon={<ExceptionOutlined />} />
                        </Tooltip>
                    </Col>


                    <Col span={1} style={{ paddingTop:'1em' }}>
                        <Tooltip title='Cerrar Folio' placement='topRight'>
                            <Button className="iconTer" shape="circle" onClick={cerrarBoton} disabled={!permisosUsuario.cerrarFolioDeRequisicionPorReorden ? true : folioSeleccionado?.estadoFolio !== "Abierto"} icon={<FileDoneOutlined />} />
                        </Tooltip>
                    </Col>
                    {/* <Col span={7}>
                        <Button onClick={cerrarBoton} disabled={!permisosUsuario.cerrarFolioDeRequisicionPorReorden} className='nuevoUsuario'>Cerrar Folio</Button>
                    </Col> */}
                    {/* <Col span={7}>
                        <Button onClick={cancelarBoton} disabled={!permisosUsuario.cancelarFolioDeRequisicionPorReorden} className='nuevoUsuario'>Cancelar Folio</Button>
                    </Col> */}
                    {/* <Col span={7}>
                        <Button onClick={editarBoton} disabled={!permisosUsuario.editarFolioDeRequisicionPorReorden} className='nuevoUsuario'>Editar Folio</Button>
                    </Col> */}
                    
                    {this.state.botonEditar === true && folioSeleccionado? (
                    <>
                        {folioSeleccionado.estadoFolio === "Abierto" ? (
                            <Col span={24}>
                                <Table
                                    size="small"
                                    rowKey="idRequisicionPuntoReorden"
                                    columns={columns}
                                    dataSource={listaConsultaFolio}
                                    loading={fetchingListaConsultaFolio}
                                    locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar' }}
                                    onChange={handleTableChange}
                                    pagination={{ total: listaConsultaFolioCantidad, simple: true, current: this.state.page }}
                                />
                            </Col>
                        ):false}
                    </>
                    ): false }
                    
                    {this.state.botonEditar === false  ? (
                        <>
                        <Col span={24}>
                        <br />
                            <Table
                                size="small"
                                rowKey="idRequisicionPuntoReorden"
                                columns={listaConsultaFolio.length ? listaConsultaFolio[0].folioRequisicionMateriales ? columnsCompra : columns2 : columns2  }
                                dataSource={listaConsultaFolio}
                                loading={fetchingListaConsultaFolio}
                                locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar' }}
                                onChange={handleTableChange}
                                pagination={{ total: listaConsultaFolioCantidad, simple: true, current: this.state.page }}
                            />
                     </Col>
                    </>
                    ): false }
                    <ModalCerrarFolio />
                </Row>
                <ModalEditarProducto/>
            </Spin>
        );
    }
}
const mapStateToProps = state => {
    return {
        fetchingListaConsultaFolio: state.AlmacenesReducer.fetchingListaConsultaFolio,
        listaConsultaFolio: state.AlmacenesReducer.listaConsultaFolio,
        listaConsultaFolioCantidad: state.AlmacenesReducer.listaConsultaFolioCantidad,
        busqueda: state.AlmacenesReducer.busqueda,
        page: state.AlmacenesReducer.page,
        folioSeleccionado: state.AlmacenesReducer.folioSeleccionado,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingCerrarFolioRequisicionPuntoReorden: state.AlmacenesReducer.fetchingCerrarFolioRequisicionPuntoReorden,
        fetchingCancelarFolioRequisicionPuntoReorden: state.AlmacenesReducer.fetchingCancelarFolioRequisicionPuntoReorden,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onGetConsultaFolio: (folio, page) => {
            dispatch({ type: "CONSULTA_FOLIO_REQUISICION_REQUEST" , folio, page})
        },
        onModalEditarProducto: (productoReqSeleccionado) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_PRODUCTO_REQUISICION", showModalEditarProductoRequsicion: true, productoReqSeleccionado })
        },
        onModalCerrarFolio: () => {
            dispatch({ type: "SHOW_MODAL_CERRAR_FOLIO_REQUISICION", showModalCerrarFolioRequisicion: true })
        },
        onCancelarFolio: (folioSeleccionado, page, busqueda) => {
            dispatch({ type: "CANCELAR_FOLIO_REQUISICION_PUNTOREORDEN_REQUEST", folioSeleccionado, page, busqueda})
        },
        onShowOpciones: () => {
            dispatch({ type: "SHOW_MODAL_REQUISICION_PUNTO_REORDEN", showModalRequisicionPuntoReorden: false})
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
        },
        onShowModalCerrarFolio: () => {
            dispatch({ type: "SHOW_MODAL_CERRAR_FOLIO_REQUISICION", showModalCerrarFolioRequisicion: false});
        },
        onCerrarModalOpciones: () => {
            dispatch({ type: "SHOW_MODAL_REQUISICION_PUNTO_REORDEN", showModalRequisicionPuntoReorden: false })
        },
        onCerrarFolio: (page, folioSeleccionado, busqueda) => {
            dispatch({ type: "CERRAR_FOLIO_REQUISICION_PUNTOREORDEN_REQUEST", page, folioSeleccionado, busqueda });
        },
    } 
}
 
export default connect(mapStateToProps, mapDispatchToProps)(OpcionesFolioRequisicion);
