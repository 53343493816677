import React, {useRef, useState} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import { Row, Col, Button, message, Spin, Modal, Form, Upload, DatePicker} from 'antd';
import { UploadOutlined} from '@ant-design/icons';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const { confirm } = Modal;


const ModalCerrarFolio = (props) => {
    const formRef = useRef();
    const {showModalCerrarFolioConciliacion,fetchingCerrarFolioConciliacion, folioConciliacionSeleccionado, page, busqueda, limpiarFormulario,
         onCerrarFolio, onModalCerrarFolio, onLimpiarFormulario} = props;
    
    const initialState = {
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
    }
    const [state, setState] = useState(initialState)

    if(limpiarFormulario && limpiarFormulario === 'CCFC') {
        onLimpiarFormulario();
        formRef.current.resetFields();
    }

    const disabledDate = fecha => {
        return fecha && fecha > moment().endOf('day');
    }

    function range(start, end) {
        const result = [];
        for (let i = start; i < end; i++) {
          result.push(i);
        }
        return result;
    }

    const disabledTime = () => {
        const currentYear = new Date();
        const hours = currentYear.getHours();
        const minutes = currentYear.getMinutes(); 

        return {
            disabledHours: () => range(hours +1, 24),
            disabledMinutes: () => range(minutes +1, 60),
          };
    }

    const onChange = (value, dateString) => {
        console.log('Selected Time: ', value);
        console.log('Formatted Selected Time: ', dateString);
    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const onChangePDF = (info) => {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        else {
            setState({
                ...state,
                botonPDF: true,
                botonEditar: true,
            })
        }
        if (info.file.status === 'done') {
            var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,40}$");
            if (!reGex.test(info.file.name)) {
                message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                formRef.current.resetFields(['pdf']);
                setState({...state, botonPDF: false});
            }
            else {
                message.success(`${info.file.name} se cargó de forma correcta`);
                setState({
                    ...state,
                    botonPDF: true,
                    filePDF: true
                });
            }
        } else if (info.file.status === 'error') {
            if (!reGex.test(info.file.name)) {
                message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                formRef.current.resetFields(['pdf']);
                setState({...state, botonPDF: false});
            }
            else {
                message.error(`${info.file.name} se cargó de forma incorrecta`);
                setState({
                    ...state,
                    filePDF: true
                });
            }
            setState({
                ...state,
                botonPDF: false,
                botonEditar: false,
            });
        }
    }

    const onRemovePDF = () => {
        setState({
            ...state,
            filePDF: false,
            botonPDF: false,
        });
    }
    
    const onFinish = formulario => {
        cerrarFolioConciliacion(formulario)
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    }

    const cerrarFolioConciliacion = (formulario) => {
        confirm({
            title: '¿Estás seguro que deseas cerrar este folio de conciliación?',
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                onCerrarFolio(formulario, folioConciliacionSeleccionado, page, busqueda);
                setState(initialState);
            },
            onCancel() {
            console.log('Cancel');
            },
        });	
    }
    
    const handleCancel = () => {
        onModalCerrarFolio();
        onLimpiarFormulario();
        formRef.current.resetFields();
    }

    return (
    <>
        <Modal
            visible={showModalCerrarFolioConciliacion}
            title='Cerrar Folio De Conciliación'
            onCancel={handleCancel}
            footer={false}
            width='50%'
        >
            <Spin spinning={fetchingCerrarFolioConciliacion}>
                <Form {...layout}
                    name='formulario'
                    onFinish={onFinish}
                    
                    ref={formRef}
                >
                    <Row justify='space-between'>
                        <Col span={11}>
                            <Form.Item
                                label='Fecha de conciliación'
                                name='fechaConciliacion'
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                            <DatePicker
                                style={{ width: '100%' }}
                                format='DD-MM-YYYY HH:mm'
                                placeholder={["Seleccione fecha Compromiso"]}
                                disabledDate={disabledDate}
                                disabledTime={disabledTime}
                                showTime={{ defaultValue: moment('00:00', 'HH:mm') }} 
                                onChange={onChange}
                            />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="Archivo PDF"
                                name="pdf"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                    {state.filePDF === false ? (
                                        <Button disabled={state.botonPDF} className='btnCargaArchivos'>
                                            <UploadOutlined /> Click para subir archivo PDF
                                        </Button>
                                    ) : false}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={24} >
                            <Button disabled={false} className="btnFiltrar" style={{ width: '100%'}} htmlType="submit" >Cerrar Folio de Conciliación</Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        </Modal>
    </>)
}

const mapStateToProps = state => {
    return {
        fetchingCerrarFolioConciliacion: state.MecanismosDePagosReducer.fetchingCerrarFolioConciliacion,
        showModalCerrarFolioConciliacion: state.MecanismosDePagosReducer.showModalCerrarFolioConciliacion,
        folioConciliacionSeleccionado: state.MecanismosDePagosReducer.folioConciliacionSeleccionado,
        page: state.MecanismosDePagosReducer.page,
        busqueda: state.MecanismosDePagosReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCerrarFolio: (formulario, folioConciliacionSeleccionado, page, busqueda) => {
            dispatch({ type: 'CERRAR_FOLIO_CONCILIACION_REQUEST', formulario, folioConciliacionSeleccionado, page, busqueda})
        },
        onModalCerrarFolio: () => {
            dispatch({type:'MODAL_CERRAR_FOLIO_CONCILIACION', showModalCerrarFolioConciliacion: false, folioConciliacionSeleccionado: null})
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalCerrarFolio)