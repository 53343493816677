import React, { Component } from 'react';
import {Form, Row, Col, Modal, Select, Input, InputNumber, Button, DatePicker, message, Spin} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';
import moment from 'moment';

const { Option } = Select;

class ModalEditarIndicadorDesempeno extends Component {
    formRef = React.createRef();

    state={ }

     render() {
          const { onCloseModalEditar, page, busqueda, showModalEditarIndicadorDesempeno, indicadorDesempenoSeleccionado, listaInfoProyecto, fetchingEditarIndicadorDesempeno, onEditIndicador, fetchingListaClasificacionesGerenciaActivas, listaClasificacionesGerenciaActivas, onGetObjetivosByGerencia, objetivosByGerencia, onResetBoolean, indicadorDesempeno, fechaVigencia } = this.props;

          const handleCancel = () => {
               onCloseModalEditar();
               this.formRef.current.resetFields();
               onResetBoolean();
          };

          const onFinish = (formulario) => {
               console.log('Success:', formulario);
               onEditIndicador(formulario, indicadorDesempenoSeleccionado, page, busqueda, indicadorDesempeno, fechaVigencia );
          };

          const onFinishFailed = (errorInfo) => {
               console.log('Failed:', errorInfo);
          };

          const selectGerencia = (value) => { 
               if(value){
                    this.formRef.current.setFieldsValue({'objetivoEditar': undefined});
                    onGetObjetivosByGerencia(value.split(' - ')[0]);
               }
          };

          const fecha = (date, dateString) => {
               if(date){
                    if(listaInfoProyecto.fechaInicio && date){
                         const diferencia = listaInfoProyecto.fechaInicio.diff(moment(date.format('YYYY-MM-DD')), "days");
                         const fechaVigenciaNuevo = listaInfoProyecto.fechaVigencia.split('-')[2] + '-' + listaInfoProyecto.fechaVigencia.split('-')[1] + '-' + listaInfoProyecto.fechaVigencia.split('-')[0];
                         const fechaVigenciaLocal = new Date(fechaVigenciaNuevo);

                         if(diferencia>0){
                              message.error('No es posible seleccionar una fecha de inicio menor al inicio de contrato del proyecto', 5);
                              this.formRef.current.resetFields(['vigencia']);
                         }

                         if(date > fechaVigenciaLocal){
                              message.error('No es posible seleccionar una fecha de mayor a la vigencia del contrato del proyecto', 5);
                              this.formRef.current.resetFields(['vigencia']);
                         }
                    }
                    else{
                         message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                         this.formRef.current.resetFields(['vigencia']);
                    }
               }
          };

          //En caso de que soliciten que se vea el "%"
          //formatter={value => `${value}%`} parser={value => value.replace('%', '')}

          return (
               <Modal
                    visible={showModalEditarIndicadorDesempeno}
                    title='Editar Indicador de Desempeño'
                    onCancel={handleCancel}
                    footer={false}
                    width={'60%'}
               >
                    { indicadorDesempenoSeleccionado ? (
                         <Spin spinning={fetchingEditarIndicadorDesempeno}>
                              <Form name="formulario" onFinish={onFinish}  ref={this.formRef} layout='vertical' initialValues={indicadorDesempenoSeleccionado}>
                                   <Row justify='space-around'>
                                        <Col span={11}>
                                             <Form.Item name='gerenciaEditar' label='Gerencia' rules={[{ required: true, message: 'Favor de llenar el campo'}]}>
                                                  <Select style={{ width: '100%', textAlign: 'left' }} showSearch placeholder="Selecciona la gerencia" onChange={selectGerencia} loading={fetchingListaClasificacionesGerenciaActivas}>
                                                       {listaClasificacionesGerenciaActivas.map(option => <Option key={option.idClasificacionGerencia + ' - ' + option.nombreGerencia}>{option.idClasificacionGerencia + ' - ' + option.nombreGerencia}</Option>)}
                                                  </Select>
                                             </Form.Item>
                                        </Col>

                                        <Col span={11}>
                                             <Form.Item name='objetivoEditar' label='Objetivo' rules={[{ required: true, message: 'Favor de llenar el campo'}]}>
                                                  <Select style={{ width: '100%', textAlign: 'left' }} showSearch placeholder="Selecciona el objetivo" disabled={this.props.disabledObjEdit}>
                                                       {objetivosByGerencia.map(option => 
                                                            <Option key={option.idObjetivo + ' - ' + option.objetivo} >
                                                                 {option.idObjetivo + ' - ' + option.objetivo}
                                                            </Option>
                                                       )} 
                                                  </Select>
                                             </Form.Item>
                                        </Col>

                                        <Col span={11}>
                                             <Form.Item name='indicadorDesempeno' label='Indicador de desempeño' rules={[{ required: true, message: 'Favor de llenar el campo'}]}>
                                                  <Input maxLength={50} placeholder='Ingresa el indicador de desempeño'/>
                                             </Form.Item>
                                        </Col>

                                        <Col span={11}>
                                             <Form.Item name='origenInformacion' label='Origen de la información' rules={[{ required: true, message: 'Favor de llenar el campo'}]}>
                                                  <Input maxLength={50} placeholder='Ingresa el origen de la información'/>
                                             </Form.Item>
                                        </Col>

                                        <Col span={11}>
                                             <Form.Item name='informacionInicial' label='Parte que genera la información inicial' rules={[{ required: false, message: 'Favor de llenar el campo'}]}>
                                                  <Input maxLength={50} placeholder='Ingresa la información inicial'/>
                                             </Form.Item>
                                        </Col>

                                        <Col span={11}>
                                             <Form.Item name='valorEsperado' label='Valor esperado' rules={[{ required: true, message: 'Favor de llenar el campo'}]}>
                                                  <InputNumber style={{width: '100%'}} precision={2} placeholder='Ingresa el valor esperado'/>
                                             </Form.Item>
                                        </Col>

                                        <Col span={11}>
                                             <Form.Item label='Vigencia' name='fechaVigenciaEditar' rules={[{ required: true, message: 'Favor de llenar el campo'}]}>
                                                  <DatePicker style={{ width: '100%' }} format='DD-MM-YYYY' placeholder={["Seleccione fecha vigencia"]} onChange={fecha}/>
                                             </Form.Item>
                                        </Col>

                                        <Col span={11}>
                                             <Form.Item name='frecuenciaEvaluacion' label='Frecuencia de evaluación en días' rules={[{ required: false, message: 'Favor de llenar el campo'}]}>
                                                  <InputNumber min={0} max={364} precision={0} placeholder='Ingresa la frecuencia de evaluación en días'/>
                                             </Form.Item>
                                        </Col>

                                        <Col span={11}>
                                             <br />
                                             <Button className="nuevoUsuario" htmlType='submit'>
                                                  Guardar
                                             </Button>
                                        </Col>
                                   </Row>
                              </Form>
                         </Spin>
                    ): false }
               </Modal>
          )
     }
}

const mapStateToProps = state => {
     return {
          showModalEditarIndicadorDesempeno: state.EvaluacionDeLaGestionReducer.showModalEditarIndicadorDesempeno,
          page: state.EvaluacionDeLaGestionReducer.page,
          busqueda: state.EvaluacionDeLaGestionReducer.busqueda,
          indicadorDesempeno: state.EvaluacionDeLaGestionReducer.indicadorDesempeno,
          fechaVigencia: state.EvaluacionDeLaGestionReducer.fechaVigencia,
          indicadorDesempenoSeleccionado: state.EvaluacionDeLaGestionReducer.indicadorDesempenoSeleccionado,
          listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
          fetchingEditarIndicadorDesempeno: state.EvaluacionDeLaGestionReducer.fetchingEditarIndicadorDesempeno,
          fetchingListaClasificacionesGerenciaActivas: state.EvaluacionDeLaGestionReducer.fetchingListaClasificacionesGerenciaActivas,
          listaClasificacionesGerenciaActivas: state.EvaluacionDeLaGestionReducer.listaClasificacionesGerenciaActivas,
          objetivosByGerencia: state.EvaluacionDeLaGestionReducer.objetivosByGerencia,
          disabledObjEdit: state.EvaluacionDeLaGestionReducer.disabledObjEdit,
     };
};

const mapDispatchToProps = dispatch => {
     return {
          onCloseModalEditar: () => {
               dispatch({ type: 'SHOW_MODAL_EDITAR_INDICADOR_DESEMPENO', showModalEditarIndicadorDesempeno: false });
          },
          onEditIndicador: (formulario, indicadorDesempenoSeleccionado, page, busqueda, indicadorDesempeno, fechaVigencia ) => {
               dispatch({ type: 'EDITAR_INDICADOR_DESEMPENO_REQUEST', formulario, indicadorDesempenoSeleccionado, page, busqueda, indicadorDesempeno, fechaVigencia  });
          },
          onGetObjetivosByGerencia: (idClasificacionGerencia) => {
               dispatch({ type: 'OBJETIVOS_BY_GERENCIA_REQUEST', idClasificacionGerencia });
          },
          onResetBoolean:() => {
               dispatch({ type: 'BOOLEAN_OBJETO_BY_GERENCIA', disabledObjEdit: false });
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarIndicadorDesempeno);