import React, { Component } from 'react';
import { Modal, Tooltip, Row, Col, Table, Card, Descriptions, Avatar, Typography, Timeline } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import imagenNoResult from '../../../assets/no-result-icon.png';

const { Meta } = Card;
const { Text, Paragraph } = Typography;

class ModalInformacionReporteGrafico extends Component {
     state = { page: 1, }
     addDefaultSrc(ev){ if(ev){ev.target.src = imagenNoResult;} }

     render() { 
        const { reporteGraficoSeleccionado, showModalInformacionReporteGrafico } =  this.props;

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
               permisosUsuario = JSON.parse(permisosUsuario);
          }

          const handleCancel = () => {
               this.props.onCerrarModal();
          };

          const handleTableChange = (pagination) => {
               this.props.onGetTrazabilidadFolio(reporteGraficoSeleccionado.folio, pagination.current);
               this.setState({page: pagination.current});
          };

          const columns = [{
               title: 'Fecha de estado',
               dataIndex: 'fechaEstado',
               key: 'fechaEstado',
               align: 'center'
          },{
               title: 'Estado',
               dataIndex: 'estado',
               key: 'estado',
               align: 'center'
          },{
               title: 'Descripción',
               dataIndex: 'descripcion',
               key: 'descripcion',
               align: 'center'
          },{
               title: 'Fecha de actualización',
               dataIndex: 'fechaActualizacion',
               key: 'fechaActualizacion',
               align: 'center'
          },{
               title: 'Usuario que registra',
               dataIndex: 'username',
               key: 'username',
               align: 'center'
          }];

          const scroll = {"x": 700 | true, "y": 1000};

          return ( 
               <div>
                    {reporteGraficoSeleccionado  ? (
                         <Modal
                              visible={showModalInformacionReporteGrafico}
                              title={`Detalle de Solicitud de Servicio`}
                              onCancel={handleCancel}
                              footer={false}
                              width={'60%'}
                         >
                              <Row justify='space-around' gutter={[8,8]}>
                                   <Col span={24}>
                                        <Descriptions layout="horizontal" bordered={true}>
                                             <Descriptions.Item label='Folio'> <Text type="warning" strong> {reporteGraficoSeleccionado.folio} </Text> </Descriptions.Item>
                                             <Descriptions.Item label='Servicio' span={2}> {reporteGraficoSeleccionado.nombreServicio} </Descriptions.Item>
                                             <Descriptions.Item label='Decripción de la solicitud' span={2}> {reporteGraficoSeleccionado.descripcionSolicitud} </Descriptions.Item>
                                             <Descriptions.Item label='Usuario reportó'> {reporteGraficoSeleccionado.usuarioReporta} </Descriptions.Item>
                                             <Descriptions.Item label='Clave ubicación'> {reporteGraficoSeleccionado.claveUbicacion} </Descriptions.Item>
                                             <Descriptions.Item label='Área funcional' span={2}> {reporteGraficoSeleccionado.areaFuncional} </Descriptions.Item>
                                             <Descriptions.Item label='Unidad funcional' span={2}> {reporteGraficoSeleccionado.unidadFuncional} </Descriptions.Item>
                                             <Descriptions.Item label='Interior funcional' span={2}> {reporteGraficoSeleccionado.interiorFuncional} </Descriptions.Item>
                                             <Descriptions.Item label='Fecha de generación de folio'>{(reporteGraficoSeleccionado.fechaAlta.split('T')[0]).split("-").reverse().join("-")}</Descriptions.Item>
                                             <Descriptions.Item label='Fecha compromiso'>{(reporteGraficoSeleccionado.fechaCompromiso.split('T')[0]).split("-").reverse().join("-")}</Descriptions.Item>
                                             <Descriptions.Item label='Fecha cierre'>{ reporteGraficoSeleccionado.fechaCierre ? (reporteGraficoSeleccionado.fechaCierre.split('T')[0]).split("-").reverse().join("-") : 'N/A' }</Descriptions.Item>
                                        </Descriptions>
                                   </Col>
                                   <Col span={24}>
                                        <Card className="cardCatalogos" >
                                             <Meta
                                                  avatar={<Avatar shape="square" size={60} src={reporteGraficoSeleccionado.urlImagen} />}
                                                  title={'Trazabilidad: ' + reporteGraficoSeleccionado.folio}
                                                  description={reporteGraficoSeleccionado.nombreServicio}
                                             /> 
                                             <br />
                                             <Table
                                                  size="small"
                                                  rowKey="idTrazabilidadFolio"
                                                  columns={columns}
                                                  dataSource={this.props.listaTrazabilidad}
                                                  loading={this.props.fetchingListaTrazabilidad}
                                                  onChange={handleTableChange}
                                                  locale={{ emptyText: 'Sin datos' }}
                                                  scroll={scroll}
                                                  pagination={{ total: this.props.listaTrazabilidadCantidad, simple: true, current: this.state.page }}
                                             />
                                             {/* <br />
                                             <Timeline>
                                                  {this.props.listaTrazabilidad.map(trazo => 
                                                       <Timeline.Item color={ trazo.estado === 'Generada' || trazo.estado === 'Generado' ? 'green' : 'gray' }>
                                                            <Paragraph>
                                                                 <Text strong>Fecha estado: {trazo.fechaEstado}</Text>
                                                            </Paragraph>
                                                            <Paragraph>
                                                                 <Text strong>Estado:</Text> <Text style={{color:'green'}}>{trazo.estado}</Text>
                                                            </Paragraph>
                                                            <Paragraph>
                                                                 <Text strong>Descripción:</Text> {trazo.descripcion}
                                                            </Paragraph>
                                                            <Paragraph>
                                                                 <Text strong>Fecha Actualización:</Text> {trazo.fechaActualizacion}
                                                            </Paragraph>
                                                            <Paragraph>
                                                                 <Text strong>Usuario que registra:</Text> {trazo.username}
                                                            </Paragraph>
                                                        </Timeline.Item>
                                                  )}

                                             </Timeline> */}
                                        </Card>
                                   </Col>
                              </Row>

                         </Modal>
                    ) : false }
               </div>
          );
     }
}
 
const mapStateToProps = state => {
     return {
          showModalInformacionReporteGrafico: state.MonitorIntegralReducer.showModalInformacionReporteGrafico,
          reporteGraficoSeleccionado: state.MonitorIntegralReducer.reporteGraficoSeleccionado,

          listaTrazabilidad: state.SolicitudDeServiciosReducer.listaTrazabilidad,
          fetchingListaTrazabilidad: state.SolicitudDeServiciosReducer.fetchingListaTrazabilidad,
          listaTrazabilidadCantidad: state.SolicitudDeServiciosReducer.listaTrazabilidadCantidad,
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          onCerrarModal: () => {
               dispatch({ type: 'SHOW_MODAL_INFO_REPORTE_GRAFICO', showModalInformacionReporteGrafico: false})
          },
          onGetTrazabilidadFolio: (folio, page) => {
               dispatch({ type: "OBTENER_TRAZABILIDAD_FOLIO_REQUEST", folio, page});
          },
     };
};
export default connect(mapStateToProps, mapDispatchToProps) (ModalInformacionReporteGrafico);