//Archivo Saga que ejecuta las acciones del Login

import { takeLatest, call, put } from "redux-saga/effects";
import * as CatalogosGeneralesActions from '../actions/CatalogosGeneralesActions';
import * as ConfiguracionYSeguridadActions from '../actions/ConfiguracionYSeguridadActions';
import * as informacionOperativaActions from '../actions/InformacionOperativaActions';
import {
    listaFamiliasDeActivosBusquedaCall, listaFamiliasDeActivosCall, crearFamiliasDeActivosCall, estadoFamiliasDeActivosCall, editarFamiliasDeActivosCall, listaFamiliasActivasServCall,
    
    getCentroCostosPageCall, listaCentroDeCostosByGerenciaCall, getCentroDeCostosBusquedaCall, crearCentroDeCostos,editarCentroDeCostosCall, estadoCentrosDeCostoCall, listaCentroDeCostosActivosCall,
    
    listaSubfamiliasBusquedaCall, listaSubfamiliasCall, listaSubfamiliasRutinasCall, crearSubfamiliasCall, estadoSubfamiliasCall, editarSubfamiliasCall, listaSubfamiliasProductosCall, listaSubfamiliasPorServicioCall, listaSubfamiliasByPartidaCall,
    
    listaGruposServiciosBusquedaCall, estadoGrupoServicioCall, crearGrupoServicioCall, editarGrupoServicioCall, listaGruposServiciosCall, 
    
    listaServiciosBusquedaCall, listaServiciosByCentroCostoCall, estadoServicioCall, crearServicioCall, listaServiciosSinInfoCall, editarServicioCall, listaServiciosCall, listaServiciosActivosFCall, listaServiciosByProveedorCall, listaServiciosByGrupoServicioCall, listaServiciosByGruposServiciosCall, listaServiciosByIdGerenciaCall, listaServiciosByIdsGruposSerCall,

    listaCategoriasFallasActivosCall, getCategoriasFallasBusquedaCall, crearCategoriaFallaCall, editarCategoriaFallaCall, estadoCategoriaFallaCall, exportExcelTiemposRehabilitacionCall,

    obtenerAreasFuncionalesCall, cambiarEstadoAreasFuncionalCall, editarAreasFuncionalCall, filtroCodigoAreaFuncionalClaveCall, nuevaAreasFuncionalCall, listaAreasFuncionalesActivasCall, areasBloquearCall,
    
    crearUnidadesFuncionalesCall, listaUnidadesFuncionalesBusquedaCall, estadoUnidadesFuncionalesCall, editarUnidadesFuncionalesCall, listaUniActCompletaCall, listaUniActPorAreaCall,
    
    filtroInteriorCall, obtenerInteriorCall, cambiarEstadoInteriorCall, nuevaInteriorCall, editarInteriorCall, filtroSubInteriorCall, obtenerSubInteriorCall, listaInterioresActivosCompletaCall, listaInterioresActivosPorUnidadCall,

    cambiarEstadoSubInteriorCall, nuevaSubInteriorCall, editarSubInteriorCall,  filtroBusquedaUbicacionesCall, cambiarEstadoUbicacionCall, crearUbicacionCall, editarUbicacionCall, listaUbicacionesFiltroCall, listaSubInterioresActivosPorInteriorCall, exportarUbicacionesPDFCall, exportarUbicacionesExcelCall,

    listaAreasActivosCall, listaUnidadesActivosCall, listaInterioresActivosCall, listaSubInterioresActivosCall, listaUbicacionesActivosCall, listaUbicacionesTodasBusquedaCall,

    listaPonderacionesActivosCall, listaPonderacionesBusquedaCall, crearPonderacionCall, editarPonderacionCall,

    listaTipoMonedaCall, estadoTipoMonedaCall, crearTipoMonedaCall, editarTipoMonedaCall, listaTiposMonedasActivosCall, exportarTipoMonedaExcelCall,

    listaTipoCambioCall, nuevoTipoCambioCall, editarTipoCambioCall, exportarTipoCambioExcelCall
} from "../../services/CatalogosGeneralesServices";
import moment from "moment";
import { message } from "antd";

//************************ Lista de servicios para Select de proveedor ***************************
function* listaServicios(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaServiciosCall, token);
        console.log(response);

        if(response.status==200){
            const listaServicios = response.data;
            
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_SUCCESS, listaServicios });
        }
        else{
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_FAILURE });
    }
}export function* listaServiciosSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_SERVICIOS_REQUEST, listaServicios);
}

function* listaServiciosByProveedor(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idProveedor = action.idProveedor;

        const response = yield call(listaServiciosByProveedorCall, token, idProveedor);
        console.log(response);

        if(response.status==200){
            const listaServiciosByProveedor = response.data;
            
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BY_PROVEEDOR_SUCCESS, listaServiciosByProveedor });
        }
        else{
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BY_PROVEEDOR_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BY_PROVEEDOR_FAILURE });
    }
} export function* listaServiciosByProveedorSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_SERVICIOS_BY_PROVEEDOR_REQUEST, listaServiciosByProveedor);
}

function* listaServiciosByGrupoServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idGrupoSer = action.idGrupoSer;

        const response = yield call(listaServiciosByGrupoServicioCall, token, idGrupoSer);
        console.log(response);

        if(response.status==200){
            const listaServiciosByGrupoServicio = response.data;
            
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BY_GRUPO_SERV_SUCCESS, listaServiciosByGrupoServicio });
        }
        else{
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BY_GRUPO_SERV_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BY_GRUPO_SERV_FAILURE });
    }
} export function* listaServiciosByGrupoServicioSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_SERVICIOS_BY_GRUPO_SERV_REQUEST, listaServiciosByGrupoServicio);
}

function* listaServiciosByGruposServicios(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idsGrupoSer = [];

        if(action.reporte){
            for(let i in action.idsGrupoSer){
                idsGrupoSer.push(action.idsGrupoSer[i].split(' - ')[0]);
            }
        }
        else{
            idsGrupoSer = action.idsGrupoSer;
        }
        console.log(`idsGrupoSer`, idsGrupoSer)
        const response = yield call(listaServiciosByGruposServiciosCall, token, idsGrupoSer);
        console.log(response);

        if(response.status==200){
            const listaServiciosByGruposServicios = response.data;
            
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BY_GRUPOS_SERV_SUCCESS, listaServiciosByGruposServicios });
        }
        else{
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BY_GRUPOS_SERV_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BY_GRUPOS_SERV_FAILURE });
    }
} export function* listaServiciosByGruposServiciosSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_SERVICIOS_BY_GRUPOS_SERV_REQUEST, listaServiciosByGruposServicios);
}

function* listaServiciosActivosF(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaServiciosActivosFCall, token);
        console.log('response listaServiciosActivosF', response);

        if(response.status==200){
            const listaServiciosActivosF = response.data.data;
            if(action.busqueda){
                yield put({ type: ConfiguracionYSeguridadActions.LISTA_BITACORAS_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda, listaServiciosActivosF });
            }
            
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_ACTIVOSF_SUCCESS, listaServiciosActivosF });
        }
        else{
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_ACTIVOSF_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_ACTIVOSF_FAILURE });
    }
}
export function* listaServiciosActivosFSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_SERVICIOS_ACTIVOSF_REQUEST, listaServiciosActivosF);
}

function* listaServiciosByIdGerencia(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let data = [];
        if(action.idsGerencias){
            for(let i in action.idsGerencias){
                if(action.idsGerencias[i].split(' - ')[1] === 'Sin gerencia asignada'){
                    data.push(null);
                }
                else{
                    data.push(action.idsGerencias[i].split(' - ')[0]);
                }
            }
        }
        console.log(`data`, data)
        const response = yield call(listaServiciosByIdGerenciaCall, token, data);
        console.log(response);

        if(response.status==200){
            const listaServiciosByIdsGerencias = response.data;
            let userInfo = sessionStorage.getItem('userInfo');
            if(userInfo[0]) {
                userInfo = JSON.parse(userInfo);
            }

            let serviciosByIdUsuarioGerencia = []
            if(userInfo[0].serviciosByIdUsuario && listaServiciosByIdsGerencias){
                for(let i in listaServiciosByIdsGerencias){
                    for(let x in userInfo[0].serviciosByIdUsuario){
                            if(listaServiciosByIdsGerencias[i].idServicio === userInfo[0].serviciosByIdUsuario[x].idServicio){
                                serviciosByIdUsuarioGerencia.push(userInfo[0].serviciosByIdUsuario[x]);
                            }
                    }
                }
            }

            console.log('serviciosByIdUsuarioGerencia', serviciosByIdUsuarioGerencia)
            
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BY_IDGERENCIAS_SUCCESS, listaServiciosByIdsGerencias, serviciosByIdUsuarioGerencia });
        }
        else{
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BY_IDGERENCIAS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BY_IDGERENCIAS_FAILURE });
    }
}
export function* listaServiciosByIdGerenciaSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_SERVICIOS_BY_IDGERENCIAS_REQUEST, listaServiciosByIdGerencia);
}

function* listaServiciosByIdsGruposSer(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let idsGruposSerOrg = '';
        if(action.idsGruposSer){
            for(let i in action.idsGruposSer){
                idsGruposSerOrg += action.idsGruposSer[i].split(' - ')[0] + ',';
            }
        }
        
        let idsGruposSer = idsGruposSerOrg.slice(0, -1);
        console.log('idsGruposSer SAGA', idsGruposSer);
        
        const response = yield call(listaServiciosByIdsGruposSerCall, token, idsGruposSer);
        console.log(response);

        if(response.status==200){
            const listaServiciosByIdsGrupos = response.data;
            let userInfo = sessionStorage.getItem('userInfo');
            if(userInfo[0]) {
                userInfo = JSON.parse(userInfo);
            }

            let serviciosByIdUsuarioGrupos = []
            if(userInfo[0].serviciosByIdUsuario && listaServiciosByIdsGrupos){
                for(let i in listaServiciosByIdsGrupos){
                    for(let x in userInfo[0].serviciosByIdUsuario){
                            if(listaServiciosByIdsGrupos[i].idServicio === userInfo[0].serviciosByIdUsuario[x].idServicio){
                                serviciosByIdUsuarioGrupos.push(userInfo[0].serviciosByIdUsuario[x]);
                            }
                    }
                }
            }
            
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BY_IDSGRUPOS_SUCCESS, listaServiciosByIdsGrupos, serviciosByIdUsuarioGrupos });
        }
        else{
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BY_IDSGRUPOS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BY_IDSGRUPOS_FAILURE });
    }
}
export function* listaServiciosByIdsGruposSerSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_SERVICIOS_BY_IDSGRUPOS_REQUEST, listaServiciosByIdsGruposSer);
}

//********************** Lista de Servicios activos para formulario Alta y Edicion de usuario *****************************
function* listaGruposServicios(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaGruposServiciosCall, token);
        console.log(response);

        if(response.status==200){
            const listaGruposServicios = response.data;

            yield put({ type: CatalogosGeneralesActions.LISTA_GRUPOS_SERVICIOS_SUCCESS, listaGruposServicios });
        }
        else{
            yield put({ type: CatalogosGeneralesActions.LISTA_GRUPOS_SERVICIOS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_GRUPOS_SERVICIOS_FAILURE });
    }
}
export function* listaGruposServiciosSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_GRUPOS_SERVICIOS_REQUEST, listaGruposServicios);
}

//********************** Lista de Grupos de Servicio para Catálogo Grupos de Servicios *****************************
function* listaGruposServiciosBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let busqueda = '';
        if(action.busqueda){
            busqueda = 'param=' + action.busqueda + '&';
        }
        console.log('page');
        console.log(page);
        console.log('busqueda');
        console.log(busqueda);

        let response  = yield call(listaGruposServiciosBusquedaCall, token, page, busqueda);
        console.log(response);

        if(response.status==200){
            let listaGruposServiciosBusqueda = response.data.data;
            const listaGruposServiciosBusquedaCantidad = response.data.cantidad;
            for(let i=0; i<listaGruposServiciosBusqueda.length; i++){
                let fechaAlta = listaGruposServiciosBusqueda[i].fechaAlta.split('T')[0];
                listaGruposServiciosBusqueda[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
            }
            yield put({ type: CatalogosGeneralesActions.GRUPOS_SERVICIOS_BUSQUEDA_SUCCESS, listaGruposServiciosBusqueda, listaGruposServiciosBusquedaCantidad });
        }
        else{
            yield put({ type: CatalogosGeneralesActions.GRUPOS_SERVICIOS_BUSQUEDA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.GRUPOS_SERVICIOS_BUSQUEDA_FAILURE });
    }
}
export function* listaGruposServiciosBusquedaSaga() {
    yield takeLatest(CatalogosGeneralesActions.GRUPOS_SERVICIOS_BUSQUEDA_REQUEST, listaGruposServiciosBusqueda);
}

//********************** Cambiar el estado del Grupo de Servicio Activo/inactivo *****************************
function* cambiarEstadoGrupoServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idGrupoSer = action.idGrupoSer;

        const response = yield call(estadoGrupoServicioCall, token, idGrupoSer);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const busqueda = '';
            const showSuccessMsg = true;
            const textMessage = "El estado del grupo de servicio cambió de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.ESTADO_GRUPO_SERVICIO_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.GRUPOS_SERVICIOS_BUSQUEDA_REQUEST, page, busqueda });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: CatalogosGeneralesActions.ESTADO_GRUPO_SERVICIO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.ESTADO_GRUPO_SERVICIO_FAILURE });
    }
}
export function* cambiarEstadoGrupoServicioSaga() {
    yield takeLatest(CatalogosGeneralesActions.ESTADO_GRUPO_SERVICIO_REQUEST, cambiarEstadoGrupoServicio);
}

//********************** Crear Grupo de Servicio *****************************
function* crearGrupoServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const data = {
            "idGrupoSer": null,
            "descripcion": action.formulario.descripcion,
            "nombre": action.formulario.nombre,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
            "equipamientoTec": action.equipamientoTec
        }
        console.log(data);
        const response = yield call(crearGrupoServicioCall, token, data);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const busqueda = '';
            const showSuccessMsg = true;
            const textMessage = "El grupo de servicio " + action.formulario.nombre + " se creó de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearGrupoServicio' });
            yield put({ type: CatalogosGeneralesActions.NUEVO_GRUPO_SERVICIO_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.GRUPOS_SERVICIOS_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: CatalogosGeneralesActions.LISTA_GRUPOS_SERVICIOS_REQUEST });
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_NUEVO_GRUPO_SERVICIO, showModalNuevoGrupoServicio: false });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: CatalogosGeneralesActions.NUEVO_GRUPO_SERVICIO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.NUEVO_GRUPO_SERVICIO_FAILURE });
    }
}
export function* crearGrupoServicioSaga() {
    yield takeLatest(CatalogosGeneralesActions.NUEVO_GRUPO_SERVICIO_REQUEST, crearGrupoServicio);
}

//********************** Editar Grupo de Servicio *****************************
function* editarGrupoServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idGrupoSer = action.grupoServicioSeleccionado.idGrupoSer;
        const data = {
            "idGrupoSer": idGrupoSer,
            "descripcion": action.formulario.descripcion,
            "nombre": action.formulario.nombre,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
            "equipamientoTec": action.equipamientoTec
        }
        console.log('data', data);
        const response = yield call(editarGrupoServicioCall, token, data, idGrupoSer);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const busqueda = '';
            const showModalEditarDominio = false;
            const grupoServicioSeleccionado = null;
            const showSuccessMsg = true;
            const textMessage = "El grupo de servicio " + action.formulario.nombre + " se modificó de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarGrupoServicio' });
            yield put({ type: CatalogosGeneralesActions.EDITAR_GRUPO_SERVICIO_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.GRUPOS_SERVICIOS_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_EDITAR_GRUPO_SERVICIO, showModalEditarDominio, grupoServicioSeleccionado });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: CatalogosGeneralesActions.EDITAR_GRUPO_SERVICIO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.EDITAR_GRUPO_SERVICIO_FAILURE });
    }
}
export function* editarGrupoServicioSaga() {
    yield takeLatest(CatalogosGeneralesActions.EDITAR_GRUPO_SERVICIO_REQUEST, editarGrupoServicio);
}

// FAMILIAS DE ACTIVOS

//Lista de familias activas por el servicio (activo)
function* listaFamiliasActivasServ(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServicio = action.idServicio;

        const response = yield call(listaFamiliasActivasServCall, token, idServicio);
        console.log(response);

        if (response.status == 200) {
            const listaFamiliasActivasServ = response.data;

            if(listaFamiliasActivasServ.length === 0){
                message.warning('El servicio seleccionado no cuenta con familias asignadas.');
            }

            yield put({ type: CatalogosGeneralesActions.LISTA_FAMILIAS_ACTIVAS_SERVICIO_SUCCESS, listaFamiliasActivasServ });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_FAMILIAS_ACTIVAS_SERVICIO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_FAMILIAS_ACTIVAS_SERVICIO_FAILURE });
    }
}
export function* listaFamiliasActivasServSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_FAMILIAS_ACTIVAS_SERVICIO_REQUEST, listaFamiliasActivasServ);
}

// Lista de Familias Activos para la tabla de Familas Activos
function* listaFamiliasDeActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaFamiliasDeActivosCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaFamiliasDeActivos = response.data;

            yield put({ type: CatalogosGeneralesActions.LISTA_FAMILIAS_ACTIVOS_SUCCESS, listaFamiliasDeActivos });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_FAMILIAS_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_FAMILIAS_ACTIVOS_FAILURE });
    }
}
export function* listaFamiliasDeActivosSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_FAMILIAS_ACTIVOS_REQUEST, listaFamiliasDeActivos);
}

// FAMILIAS DE ACTIVOS

function* listaFamiliasDeActivosBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let busqueda = '';
        if(action.busqueda){
            busqueda = 'fam=' + action.busqueda + '&';
        }
        console.log('page');
        console.log(page);
        console.log('busqueda');
        console.log(busqueda);

        const response  = yield call(listaFamiliasDeActivosBusquedaCall, token, page, busqueda);
        console.log(response);

        if(response.status===200){
            const listaFamiliasDeActivosBusquedaCantidad = response.data.cantidad;
            let listaFamiliasDeActivosBusqueda = response.data.data;
            for(let i=0; i<listaFamiliasDeActivosBusqueda.length; i++){
                let fechaAlta = listaFamiliasDeActivosBusqueda[i].fechaAlta.split('T')[0];
                listaFamiliasDeActivosBusqueda[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
                listaFamiliasDeActivosBusqueda[i].servicioTabla = listaFamiliasDeActivosBusqueda[i].servicioByIdServicio.nombre;
                listaFamiliasDeActivosBusqueda[i].nombre = listaFamiliasDeActivosBusqueda[i].servicioByIdServicio.idServicio + ' - ' + listaFamiliasDeActivosBusqueda[i].servicioByIdServicio.nomenclatura + ': ' + listaFamiliasDeActivosBusqueda[i].servicioByIdServicio.nombre;

                if(listaFamiliasDeActivosBusqueda[i].idPartida){
                    listaFamiliasDeActivosBusqueda[i].partidaTabla = listaFamiliasDeActivosBusqueda[i].partidaByIdPartida.nombre;
                    listaFamiliasDeActivosBusqueda[i].partida = listaFamiliasDeActivosBusqueda[i].idPartida + ' - ' + listaFamiliasDeActivosBusqueda[i].partidaByIdPartida.nombre;
                }
                else{
                    listaFamiliasDeActivosBusqueda[i].partidaTabla = 'Sin partida';
                }
            }

            yield put({ type: CatalogosGeneralesActions.FAMILIAS_ACTIVOS_BUSQUEDA_SUCCESS, listaFamiliasDeActivosBusqueda, listaFamiliasDeActivosBusquedaCantidad });
        }
        else{
            yield put({ type: CatalogosGeneralesActions.FAMILIAS_ACTIVOS_BUSQUEDA_FAILURE });
        }
     
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.FAMILIAS_ACTIVOS_BUSQUEDA_FAILURE });
    }
}
export function* listaFamiliasDeActivosBusquedaSaga() {
    yield takeLatest(CatalogosGeneralesActions.FAMILIAS_ACTIVOS_BUSQUEDA_REQUEST, listaFamiliasDeActivosBusqueda);
}
 
// Cambiar el estado de una familia Activo/inactivo 

function* cambiarEstadoFamiliasDeActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idFamilia = action.idFamilia;

        const response = yield call(estadoFamiliasDeActivosCall, token, idFamilia);
        console.log(response);

        if(response.status === 200){
            const page = action.page;
            const busqueda = '';
            const showSuccessMsg = true;
            const textMessage = "El estado de la familia de activos cambió de forma correcta";
            
            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.ESTADO_FAMILIAS_ACTIVOS_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.FAMILIAS_ACTIVOS_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: CatalogosGeneralesActions.LISTA_FAMILIAS_ACTIVOS_REQUEST});
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: CatalogosGeneralesActions.ESTADO_FAMILIAS_ACTIVOS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.ESTADO_FAMILIAS_ACTIVOS_FAILURE });
    }
}
export function* cambiarEstadoFamiliasDeActivosSaga() {
    yield takeLatest(CatalogosGeneralesActions.ESTADO_FAMILIAS_ACTIVOS_REQUEST, cambiarEstadoFamiliasDeActivos);
}

// Crear nueva Familia 

function* crearFamiliasDeActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idFamilia": null,
            "familia": (action.formulario.familia).trim(),
            "tipoFamilia": action.formulario.tipoFamilia,
            "idServicio": parseInt(action.formulario.idServicio),
            'idPartida': action.formulario.idPartida ? parseInt(action.formulario.idPartida) : null,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }
        console.log(data);
        const response = yield call(crearFamiliasDeActivosCall, token, data);
        console.log(response);

        if(response.status === 200){
            const page = action.page;
            const busqueda = '';
            const showModalNuevoFamiliasDeActivos = false;
            const showSuccessMsg = true;
            const textMessage = "La familia de activos " + action.formulario.familia + " se creó de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.NUEVO_FAMILIAS_ACTIVOS_SUCCESS });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearFamiliasDeActivos' });
            yield put({ type: CatalogosGeneralesActions.FAMILIAS_ACTIVOS_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_NUEVO_FAMILIAS_ACTIVOS, showModalNuevoFamiliasDeActivos });
            yield put({ type: CatalogosGeneralesActions.LISTA_FAMILIAS_ACTIVOS_REQUEST});
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: CatalogosGeneralesActions.NUEVO_FAMILIAS_ACTIVOS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.NUEVO_FAMILIAS_ACTIVOS_FAILURE });
    }
}
export function* crearFamiliasDeActivosSaga() {
    yield takeLatest(CatalogosGeneralesActions.NUEVO_FAMILIAS_ACTIVOS_REQUEST, crearFamiliasDeActivos);
}

// Editar Familia

function* editarFamiliasDeActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idFamilia = action.familiasDeActivosSeleccionado.idFamilia;
        
        const data = {
            "idFamilia": idFamilia,
            "familia": (action.formulario.familia).trim(),
            "tipoFamilia": action.formulario.tipoFamilia,
            "idServicio": parseInt(action.formulario.nombre),
            'idPartida': action.formulario.partida ? parseInt(action.formulario.partida) : null,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }
        console.log(data);
        const response = yield call(editarFamiliasDeActivosCall, token, data, idFamilia);
        console.log(response);

        if(response.status === 200){
            const page = action.page;
            const busqueda = '';
            const showModalEditarFamilia= false;
            const familiasDeActivosSeleccionado = null;
            const showSuccessMsg = true;
            const textMessage = "La familia de activos " + action.formulario.familia + " se modificó de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarFamilia'});
            yield put({ type: CatalogosGeneralesActions.EDITAR_FAMILIAS_ACTIVOS_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.FAMILIAS_ACTIVOS_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_EDITAR_FAMILIAS_ACTIVOS, showModalEditarFamilia, familiasDeActivosSeleccionado });
            yield put({ type: CatalogosGeneralesActions.LISTA_FAMILIAS_ACTIVOS_REQUEST});
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: CatalogosGeneralesActions.EDITAR_FAMILIAS_ACTIVOS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.EDITAR_FAMILIAS_ACTIVOS_FAILURE });
    }
}
export function* editarFamiliasDeActivosSaga() {
    yield takeLatest(CatalogosGeneralesActions.EDITAR_FAMILIAS_ACTIVOS_REQUEST, editarFamiliasDeActivos);
}

// SUBFAMILIAS
// ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

// function* listaSubfamilias(action) {
//     try {
//         console.log(action);
//         const token = sessionStorage.getItem('token');

//         const response = yield call(listaSubfamiliasCall, token);
//         console.log(response);

//         if(response.status === 200){
//             const listaSubfamilias = response.data;

//             yield put({ type: CatalogosGeneralesActions.LISTA_SUBFAMILIAS_SUCCESS, listaSubfamilias });
//         }
//         else{
//             yield put({ type: CatalogosGeneralesActions.LISTA_SUBFAMILIAS_FAILURE });
//         }
    
//     } catch (error) {
//         console.log(error);
//         yield put({ type: CatalogosGeneralesActions.LISTA_SUBFAMILIAS_FAILURE });
//     }
// }
// export function* listaSubfamiliasSaga() {
//     yield takeLatest(CatalogosGeneralesActions.LISTA_SUBFAMILIAS_REQUEST, listaSubfamilias);
// }


// Lista de subfamilias de activos
function* listaSubfamiliasProductosActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaSubfamiliasProductosCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaSubfamiliasProductosActivos = response.data;

            yield put({ type: CatalogosGeneralesActions.LISTA_SUBFAMILIAS_PRODUCTOS_SUCCESS, listaSubfamiliasProductosActivos });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_SUBFAMILIAS_PRODUCTOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_SUBFAMILIAS_PRODUCTOS_FAILURE });
    }
}
export function* listaSubfamiliasProductosActivosSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_SUBFAMILIAS_PRODUCTOS_REQUEST, listaSubfamiliasProductosActivos);
}

function* listaSubfamiliasActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaSubfamiliasCall, token);
        console.log('listaSubfamiliasActivos',response);

        if (response.status == 200) {
            const listaSubfamilias = response.data;

            yield put({ type: CatalogosGeneralesActions.LISTA_SUBFAMILIAS_SUCCESS, listaSubfamilias });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_SUBFAMILIAS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_SUBFAMILIAS_FAILURE });
    }
}
export function* listaSubfamiliasActivosSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_SUBFAMILIAS_REQUEST, listaSubfamiliasActivos);
}

function* listaSubfamiliasPorServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServicio = action.idServicio;

        const response = yield call(listaSubfamiliasPorServicioCall, token, idServicio);
        console.log(response);

        if (response.status == 200) {
            const listaSubfamiliasServicio = response.data.data;

            yield put({ type: CatalogosGeneralesActions.LISTA_SUBFAMILIAS_POR_SERVICIO_SUCCESS, listaSubfamiliasServicio });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_SUBFAMILIAS_POR_SERVICIO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_SUBFAMILIAS_POR_SERVICIO_FAILURE });
    }
}
export function* listaSubfamiliasPorServicioSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_SUBFAMILIAS_POR_SERVICIO_REQUEST, listaSubfamiliasPorServicio);
}

function* listaSubfamiliasRutinas(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServicio = action.idServicio;

        const response = yield call(listaSubfamiliasRutinasCall, token, idServicio);
        console.log(response);

        if (response.status == 200) {
            const listaSubfamiliasRutinas = response.data;

            yield put({ type: CatalogosGeneralesActions.LISTA_SUBFAMILIAS_RUTINAS_SUCCESS, listaSubfamiliasRutinas });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_SUBFAMILIAS_RUTINAS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_SUBFAMILIAS_RUTINAS_FAILURE });
    }
}
export function* listaSubfamiliasRutinasSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_SUBFAMILIAS_RUTINAS_REQUEST, listaSubfamiliasRutinas);
}

// Lista de Unidades Funcionales para Catálogo de Unidades Funcionales 
function* listaSubfamiliasBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let busqueda = '';
        if(action.busqueda){
            busqueda = 'subFam=' + action.busqueda + '&';
        }
        console.log('page');
        console.log(page);
        console.log('busqueda');
        console.log(busqueda);

        const response  = yield call(listaSubfamiliasBusquedaCall, token, page, busqueda);
        console.log(response);

        if(response.status === 200){
            const listaSubfamiliasBusquedaCantidad = response.data.cantidad;
            let listaSubfamiliasBusqueda = response.data.data;
            for(let i=0; i<listaSubfamiliasBusqueda.length; i++){
                let fechaAlta = listaSubfamiliasBusqueda[i].fechaAlta.split('T')[0];
                listaSubfamiliasBusqueda[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
                //listaSubfamiliasBusqueda[i].nombre = listaSubfamiliasBusqueda[i].servicioByIdServicio.nombre;
                listaSubfamiliasBusqueda[i].familia = listaSubfamiliasBusqueda[i].familiaByIdFamilia.familia;
                listaSubfamiliasBusqueda[i].centroCosto = listaSubfamiliasBusqueda[i].centroCostoByIdCentroCosto.centroCosto;

                if( listaSubfamiliasBusqueda[i].familiaByIdFamilia.servicioByIdServicio ){
                    listaSubfamiliasBusqueda[i].servicio = listaSubfamiliasBusqueda[i].familiaByIdFamilia.servicioByIdServicio.nombre;
                }
            }

            yield put({ type: CatalogosGeneralesActions.SUBFAMILIAS_BUSQUEDA_SUCCESS, listaSubfamiliasBusqueda, listaSubfamiliasBusquedaCantidad });
        }
        else{
            yield put({ type: CatalogosGeneralesActions.SUBFAMILIAS_BUSQUEDA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.SUBFAMILIAS_BUSQUEDA_FAILURE });
    }
}
export function* listaSubfamiliasBusquedaSaga() {
    yield takeLatest(CatalogosGeneralesActions.SUBFAMILIAS_BUSQUEDA_REQUEST, listaSubfamiliasBusqueda);
}

// Cambiar el estado de una subfamilia Activo/inactivo 

function* cambiarEstadoSubfamilias(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idSubFamilia  = action.idSubFamilia;

        const response = yield call(estadoSubfamiliasCall, token, idSubFamilia);
        console.log(response);

        if(response.status === 200){
            const page = action.page;
            const busqueda = '';
            const showSuccessMsg = true;
            const textMessage = "El estado de la subfamilia cambió de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.ESTADO_SUBFAMILIAS_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.SUBFAMILIAS_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: CatalogosGeneralesActions.LISTA_SUBFAMILIAS_REQUEST });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: CatalogosGeneralesActions.ESTADO_SUBFAMILIAS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.ESTADO_SUBFAMILIAS_FAILURE });
    }
}
export function* cambiarEstadoSubfamiliasSaga() {
    yield takeLatest(CatalogosGeneralesActions.ESTADO_SUBFAMILIAS_REQUEST, cambiarEstadoSubfamilias);
}

// Crear nueva subfamilia
function* crearSubfamilias(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idSubFamilia": null,
            "subFamilia": (action.formulario.subFamilia).trim(),
            "idCentroCosto": parseInt(action.formulario.idCentroCostos),
            "idFamilia": parseInt(action.formulario.idFamilia),
            //"idServicio": parseInt(action.formulario.idServicio),
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
        }
        console.log(data);
        const response = yield call(crearSubfamiliasCall, token, data);
        console.log(response);

        if(response.status === 200){
            const page = action.page;
            const busqueda = '';
            const showModalNuevoSubfamilias = false;
            const showSuccessMsg = true;
            const textMessage = "La subfamilia " + action.formulario.subFamilia + " se creó de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.NUEVO_SUBFAMILIAS_SUCCESS });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearSubfamilia'});
            yield put({ type: CatalogosGeneralesActions.SUBFAMILIAS_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_NUEVO_SUBFAMILIAS, showModalNuevoSubfamilias });
            yield put({ type: CatalogosGeneralesActions.LISTA_SUBFAMILIAS_REQUEST });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: CatalogosGeneralesActions.NUEVO_SUBFAMILIAS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.NUEVO_SUBFAMILIAS_FAILURE });
    }
}
export function* crearSubfamiliasSaga() {
    yield takeLatest(CatalogosGeneralesActions.NUEVO_SUBFAMILIAS_REQUEST, crearSubfamilias);
}

// Editar Subfamilia

function* editarSubfamilias(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idSubFamilia  = action.SubfamiliasSeleccionado.idSubFamilia;
        let idCentroCosto = null;
        let idFamilia = null;
        
        for(let j = 0; j < action.listaFamiliasActivasServ.length; j++){
            if(action.formulario.familia === action.listaFamiliasActivasServ[j].familia){
                idFamilia = action.listaFamiliasActivasServ[j].idFamilia;
            }
        }

        for(let i = 0; i < action.listaCentroDeCostosActivos.length; i++){
            if(action.formulario.centroCosto === action.listaCentroDeCostosActivos[i].centroCosto){
                idCentroCosto = action.listaCentroDeCostosActivos[i].idCentroCosto;         
            }
        }

        const idServicio = action.idServicio;

        const data = {
            "idSubFamilia": idSubFamilia ,
            "subFamilia": (action.formulario.subFamilia).trim(),
            "idCentroCosto": idCentroCosto,
            "idFamilia": idFamilia,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }
        console.log(data);
        const response = yield call(editarSubfamiliasCall, token, data, idSubFamilia, idServicio );
        console.log(response);

        if(response.status === 200){
            const page = action.page;
            const busqueda = '';
            const showModalEditarSubfamilia= false;
            const subfamiliasSeleccionado = null;
            const showSuccessMsg = true;
            const textMessage = "La subfamilia de activos " + action.formulario.subFamilia + " se modificó de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarSubfamilia'});
            yield put({ type: CatalogosGeneralesActions.EDITAR_SUBFAMILIAS_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.SUBFAMILIAS_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_EDITAR_SUBFAMILIAS, showModalEditarSubfamilia, subfamiliasSeleccionado });
            yield put({ type: CatalogosGeneralesActions.LISTA_SUBFAMILIAS_REQUEST });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: CatalogosGeneralesActions.EDITAR_SUBFAMILIAS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.EDITAR_SUBFAMILIAS_FAILURE });
    }
}
export function* editarSubfamiliasSaga() {
    yield takeLatest(CatalogosGeneralesActions.EDITAR_SUBFAMILIAS_REQUEST, editarSubfamilias);
}

//Lista de subfamilias by id Partida
function* listaSubfamiliasByPartida(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idPartida = action.idPartida;

        const response = yield call(listaSubfamiliasByPartidaCall, token, idPartida);
        console.log(response);

        if (response.status == 200) {
            const listaSubfamiliasByIdPartida = response.data;

            yield put({ type: CatalogosGeneralesActions.LISTA_SUBFAMILIAS_BY_PARTIDA_SUCCESS, listaSubfamiliasByIdPartida });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_SUBFAMILIAS_BY_PARTIDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_SUBFAMILIAS_BY_PARTIDA_FAILURE });
    }
}
export function* listaSubfamiliasByPartidaSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_SUBFAMILIAS_BY_PARTIDA_REQUEST, listaSubfamiliasByPartida);
}

//CÁTALOGO CENTRO DE COSTOS
//**********************Lista de Familias Activos para la tabla de Familas Activos */

function* listaCentroDeCostosActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaCentroDeCostosActivosCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaCentroDeCostosActivos = response.data;

            yield put({ type: CatalogosGeneralesActions.LISTA_CENTRO_COSTOS_ACTIVOS_SUCCESS, listaCentroDeCostosActivos });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_CENTRO_COSTOS_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_CENTRO_COSTOS_ACTIVOS_FAILURE });
    }
}
export function* listaCentroDeCostosActivosSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_CENTRO_COSTOS_ACTIVOS_REQUEST, listaCentroDeCostosActivos);
}

function* listaCentroDeCostosByGerencia(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const data = [];
        for(let i in action.idsGerencias){
            data.push(parseInt(action.idsGerencias[i]))
        }

        const response = yield call(listaCentroDeCostosByGerenciaCall, token, data);
        console.log(response);

        if (response.status == 200) {
            const listaCentroDeCostosByGerencia = response.data;

            yield put({ type: CatalogosGeneralesActions.LISTA_CENTRO_COSTOS_BY_GERENCIA_SUCCESS, listaCentroDeCostosByGerencia });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_CENTRO_COSTOS_BY_GERENCIA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_CENTRO_COSTOS_BY_GERENCIA_FAILURE });
    }
}
export function* listaCentroDeCostosByGerenciaSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_CENTRO_COSTOS_BY_GERENCIA_REQUEST, listaCentroDeCostosByGerencia);
}

function* listaCentroCostosBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let busqueda = action.busqueda;    
        let response = null;
        // Servicio c/s búsqueda
        if (busqueda) {
            console.log("ENTRA CON BUSQUEDA")
            response = yield call(getCentroDeCostosBusquedaCall, token, page, busqueda);
        }
        else {
            console.log("ENTRA SIN BUSQUEDA")
            response = yield call(getCentroCostosPageCall, token, page);
        }
        console.log(response);
        console.log(response.data.data)

        if (response.status == 200) {
            //Condición no data
            let listaCentroDeCostosBusqueda = response.data.data;
            let listaCentroDeCostosBusquedaCantidad = response.data.cantidad;
            for (let i = 0; i < listaCentroDeCostosBusqueda.length; i++) {
                let fechaAlta = listaCentroDeCostosBusqueda[i].fechaAlta.split('T')[0];
                listaCentroDeCostosBusqueda[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
                listaCentroDeCostosBusqueda[i].nombreGerencia = listaCentroDeCostosBusqueda[i].clasificacionByIdClasificacion.nombreGerencia;
                listaCentroDeCostosBusqueda[i].nombreGerenciaEditar = listaCentroDeCostosBusqueda[i].clasificacionByIdClasificacion.idClasificacionGerencia + ' - ' + listaCentroDeCostosBusqueda[i].clasificacionByIdClasificacion.nombreGerencia;
                listaCentroDeCostosBusqueda[i].servicios = '';
                listaCentroDeCostosBusqueda[i].listaServicios = [];
                for(let x in listaCentroDeCostosBusqueda[i].serviciosByIdCentroCosto){
                    if(listaCentroDeCostosBusqueda[i].serviciosByIdCentroCosto[0]){
                        listaCentroDeCostosBusqueda[i].listaServicios.push(listaCentroDeCostosBusqueda[i].serviciosByIdCentroCosto[x].idServicio + ' - ' + listaCentroDeCostosBusqueda[i].serviciosByIdCentroCosto[x].nomenclatura + ': ' + listaCentroDeCostosBusqueda[i].serviciosByIdCentroCosto[x].nombre)
                        listaCentroDeCostosBusqueda[i].servicios += '-' + listaCentroDeCostosBusqueda[i].serviciosByIdCentroCosto[x].nombre + ' ';
                    }
                    else{
                        listaCentroDeCostosBusqueda[i].servicios = 'No cuenta con servicios';
                    }
                }
            }
            yield put({ type: CatalogosGeneralesActions.CENTRO_COSTOS_BUSQUEDA_SUCCESS, listaCentroDeCostosBusqueda, listaCentroDeCostosBusquedaCantidad });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.CENTRO_COSTOS_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.CENTRO_COSTOS_BUSQUEDA_FAILURE });
    }
}
export function* listaCentroCostosBusquedaSaga() {
    yield takeLatest(CatalogosGeneralesActions.CENTRO_COSTOS_BUSQUEDA_REQUEST, listaCentroCostosBusqueda);
}

//********************** Crear Nuevo Centro de Costos *****************************
function* crearNuevoCentroDeCostos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const listaServicios = [];

        for(let i in action.formulario.servicios){
            for(let x in action.listaServiciosActivosF){
               if(parseInt(action.formulario.servicios[i]) === action.listaServiciosActivosF[x].idServicio){
                    listaServicios.push(action.listaServiciosActivosF[x])
                } 
            }
        }
        const data = {
            "idCentroCosto": null,
            "centroCosto": action.formulario.centroDeCostos,
            "idClasificacionGerencia": parseInt(action.formulario.clasificacionGerencia),
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
            "serviciosByIdCentroCosto": listaServicios,
        }
        console.log(data);
        const response = yield call(crearCentroDeCostos, token, data);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showModalNuevoCentroDeCostos = false;
            const showSuccessMsg = true;
            const textMessage = "El Centro de Costos " + action.formulario.CentroDeCostos + " fue agregado con éxito";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearCentro'});
            yield put({ type: CatalogosGeneralesActions.NUEVO_CENTRO_COSTOS_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.CENTRO_COSTOS_BUSQUEDA_REQUEST, page, busqueda: action.busqueda });
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_NUEVO_CENTRO_COSTOS, showModalNuevoCentroDeCostos, page, busqueda: action.busqueda });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: CatalogosGeneralesActions.NUEVO_CENTRO_COSTOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.NUEVO_CENTRO_COSTOS_FAILURE });
    }
}
export function* crearCentroDeCostosSaga() {
    yield takeLatest(CatalogosGeneralesActions.NUEVO_CENTRO_COSTOS_REQUEST, crearNuevoCentroDeCostos);
}

function* editarCentroDeCostos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idCentroCosto = action.CentroDeCostosSeleccionado.idCentroCosto;
        const listaServicios = [];

        for(let i in action.formulario.listaServicios){
            for(let x in action.listaServiciosActivosF){
               if(parseInt(action.formulario.listaServicios[i]) === action.listaServiciosActivosF[x].idServicio){
                    listaServicios.push(action.listaServiciosActivosF[x])
                } 
            }
        }
        
        const data = {
            "idCentroCosto": idCentroCosto,
            "centroCosto": action.formulario.centroCosto,
            "idClasificacionGerencia": parseInt(action.formulario.nombreGerenciaEditar),
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
            "serviciosByIdCentroCosto": listaServicios,
        }
        console.log(data);
        const response = yield call(editarCentroDeCostosCall, token, data, idCentroCosto);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showModalEditarCentroDeCostos = false;
            const CentroDeCostosSeleccionado = null;
            const showSuccessMsg = true;
            const textMessage = "El Centro de Costos " + action.formulario.centroCosto + " fue actualizado con éxito";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarCentro'});
            yield put({ type: CatalogosGeneralesActions.EDITAR_CENTRO_COSTOS_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.CENTRO_COSTOS_BUSQUEDA_REQUEST, page, busqueda: action.busqueda });
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_EDITAR_CENTRO_COSTOS, showModalEditarCentroDeCostos, CentroDeCostosSeleccionado, page, busqueda: action.busqueda });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: CatalogosGeneralesActions.EDITAR_CENTRO_COSTOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.EDITAR_CENTRO_COSTOS_FAILURE });
    }
}
export function* editarCentroDeCostosSaga() {
    yield takeLatest(CatalogosGeneralesActions.EDITAR_CENTRO_COSTOS_REQUEST, editarCentroDeCostos);
}

//********************** Cambiar el estado de un Centro de Costos *****************************
function* cambiarEstadoCentroDeCostos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idCentroCosto = action.idCentroCosto;

        const response = yield call(estadoCentrosDeCostoCall, token, idCentroCosto);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showSuccessMsg = true;
            const textMessage = "El estado del Centro de Costos se cambió de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.ESTADO_CENTRO_COSTOS_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.CENTRO_COSTOS_BUSQUEDA_REQUEST, page, busqueda: action.busqueda });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: CatalogosGeneralesActions.ESTADO_CENTRO_COSTOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.ESTADO_CENTRO_COSTOS_FAILURE });
    }
}
export function* cambiarEstadoCentroDeCostosSaga() {
    yield takeLatest(CatalogosGeneralesActions.ESTADO_CENTRO_COSTOS_REQUEST, cambiarEstadoCentroDeCostos);
}

//********************** Lista de servicios para Catálogo de Servicios *****************************
function* listaServiciosByCentroCosto(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const data = [];
        for(let i in action.idsCentrosCostos){
            data.push(parseInt(action.idsCentrosCostos[i]));
        }

        let response  = yield call(listaServiciosByCentroCostoCall, token, data);
        console.log(response);

        if(response.status==200){
            const listaServiciosByCentroCosto = response.data;
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BY_CENTRO_COSTO_SUCCESS, listaServiciosByCentroCosto });
        }
        else{
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BY_CENTRO_COSTO_FAILURE });
        }
  
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BY_CENTRO_COSTO_FAILURE });
    }
}
export function* listaServiciosByCentroCostoSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_SERVICIOS_BY_CENTRO_COSTO_REQUEST, listaServiciosByCentroCosto);
}

function* serviciosBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let servicio = '';
        if(action.formulario.servicio){
            servicio = 'serv=' + action.formulario.servicio + '&';
        }
        let grupo = '';
        if(action.formulario.grupo){
            grupo = 'grupo=' + action.formulario.grupo + '&';
        }
        console.log('grupo');
        console.log(grupo);
        console.log('servicio');
        console.log(servicio);
        let response  = yield call(listaServiciosBusquedaCall, token, page, servicio, grupo);
        console.log(response);

        if(response.status==200){
            const listaServiciosBusqueda = response.data.data;
            const listaServiciosBusquedaCantidad = response.data.cantidad;
            for(let i=0; i<listaServiciosBusqueda.length; i++){
                let fechaAlta = listaServiciosBusqueda[i].fechaAlta.split('T')[0];
                listaServiciosBusqueda[i].fechaAlta = fechaAlta.split('-').reverse().join('-');
                let fechaActualizacion = listaServiciosBusqueda[i].fechaActualizacion.split('T')[0];
                listaServiciosBusqueda[i].fechaActualizacion = fechaActualizacion.split('-').reverse().join('-');
                

                if(listaServiciosBusqueda[i].ponderacionByIdServicio){
                    listaServiciosBusqueda[i].valorPonderado = listaServiciosBusqueda[i].ponderacionByIdServicio.valorPonderador;
                    let fechaAltaPonderado = listaServiciosBusqueda[i].ponderacionByIdServicio.fechaAlta.split('T')[0];
                    listaServiciosBusqueda[i].fechaAltaPonderado = fechaAltaPonderado.split('-').reverse().join('-');
                    let fechaFinalPonderador = listaServiciosBusqueda[i].ponderacionByIdServicio.fechaTermino.split('T')[0];
                    listaServiciosBusqueda[i].fechaFinalPonderador = fechaFinalPonderador.split('-').reverse().join('-');
                    listaServiciosBusqueda[i].rangoVigencia = [moment(fechaAltaPonderado, 'YYYY-MM-DD'),moment(fechaFinalPonderador, 'YYYY-MM-DD')];
                    listaServiciosBusqueda[i].valorPonderado = listaServiciosBusqueda[i].valorPonderado + '%';
                }
                else{
                    listaServiciosBusqueda[i].valorPonderado = 'No tiene valor ponderador';
                    
                }
                if(listaServiciosBusqueda[i].grupoSerByIdGrupoSer){
                    listaServiciosBusqueda[i].nombreGrupoSer = listaServiciosBusqueda[i].grupoSerByIdGrupoSer.nombre;
                }
                else{
                    listaServiciosBusqueda[i].nombreGrupoSer = 'No tiene grupo servicio';
                }
                listaServiciosBusqueda[i].nombreContrato = []
                if(listaServiciosBusqueda[i].contratosByIdServicio){
                    for(let x in listaServiciosBusqueda[i].contratosByIdServicio){
                        listaServiciosBusqueda[i].nombreContrato.push(listaServiciosBusqueda[i].contratosByIdServicio[x].clave);
                    }
                }
            }
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BUSQUEDA_SUCCESS, listaServiciosBusqueda, listaServiciosBusquedaCantidad });
        }
        else{
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BUSQUEDA_FAILURE });
        }
  
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BUSQUEDA_FAILURE });
    }
}
export function* serviciosBusquedaSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_SERVICIOS_BUSQUEDA_REQUEST, serviciosBusqueda);
}

//********************** Lista de servicios para Catálogo de Servicios *****************************
function* serviciosSinInfo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let response  = yield call(listaServiciosSinInfoCall, token, page);
        console.log(response);

        if(response.status==200){
            const listaServiciosBusqueda = response.data.data;
            const listaServiciosBusquedaCantidad = response.data.cantidad;
            for(let i=0; i<listaServiciosBusqueda.length; i++){
                let fechaAlta = listaServiciosBusqueda[i].fechaAlta.split('T')[0];
                listaServiciosBusqueda[i].fechaAlta = fechaAlta.split('-').reverse().join('-');
                let fechaActualizacion = listaServiciosBusqueda[i].fechaActualizacion.split('T')[0];
                listaServiciosBusqueda[i].fechaActualizacion = fechaActualizacion.split('-').reverse().join('-');
                listaServiciosBusqueda[i].valorPonderado = listaServiciosBusqueda[i].ponderacionByIdServicio.valorPonderador;
                let fechaAltaPonderado = listaServiciosBusqueda[i].ponderacionByIdServicio.fechaAlta.split('T')[0];
                listaServiciosBusqueda[i].fechaAltaPonderado = fechaAltaPonderado.split('-').reverse().join('-');
                let fechaFinalPonderador = listaServiciosBusqueda[i].ponderacionByIdServicio.fechaTermino.split('T')[0];
                listaServiciosBusqueda[i].fechaFinalPonderador = fechaFinalPonderador.split('-').reverse().join('-');
                listaServiciosBusqueda[i].rangoVigencia = moment([fechaAltaPonderado, fechaFinalPonderador]);

                if(listaServiciosBusqueda[i].grupoSerByIdGrupoSer){
                    listaServiciosBusqueda[i].nombreGrupoSer = listaServiciosBusqueda[i].grupoSerByIdGrupoSer.nombre;
                }
                else{
                    listaServiciosBusqueda[i].nombreGrupoSer = 'No tiene grupo servicio';
                }
                listaServiciosBusqueda[i].nombreContrato = [];
                if(listaServiciosBusqueda[i].contratosByIdServicio){
                    for(let x in listaServiciosBusqueda[i].contratosByIdServicio){
                        listaServiciosBusqueda[i].nombreContrato.push(listaServiciosBusqueda[i].contratosByIdServicio[x].clave);
                    }
                }
                listaServiciosBusqueda[i].valorPonderado = listaServiciosBusqueda[i].valorPonderado + '%';
            }
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_SIN_INFO_SUCCESS, listaServiciosBusqueda, listaServiciosBusquedaCantidad });
        }
        else{
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_SIN_INFO_FAILURE });
        }
  
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_SIN_INFO_FAILURE });
    }
}
export function* serviciosSinInfoSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_SERVICIOS_SIN_INFO_REQUEST, serviciosSinInfo);
}

//********************** Cambiar el estado del servicio Activo/inactivo *****************************
function* cambiarEstadoServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServicio = action.idServicio;

        const response = yield call(estadoServicioCall, token, idServicio);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const formulario = {
                servicio: '',
                grupo: '',
            }
            const showSuccessMsg = true;
            const textMessage = "El estado del servicio cambió de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.ESTADO_SERVICIO_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BUSQUEDA_REQUEST, page, formulario });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: CatalogosGeneralesActions.ESTADO_SERVICIO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.ESTADO_SERVICIO_FAILURE });
    }
}
export function* cambiarEstadoServicioSaga() {
    yield takeLatest(CatalogosGeneralesActions.ESTADO_SERVICIO_REQUEST, cambiarEstadoServicio);
}

//********************** Crear nuevo Servicio *****************************
function* crearServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let observaciones = '';
        if(action.formulario.observaciones){
            observaciones = action.formulario.observaciones;
        }
        let contratos = [];
        for(let x in action.formulario.idContrato){
            for(let i in action.listaContratos){
                if(action.listaContratos[i].idContrato === parseInt(action.formulario.idContrato[x])){
                    contratos.push(action.listaContratos[i]);
                }
            }
        }
        
        const data = {
            "idServicio": null,
            "idGrupoSer": parseInt(action.formulario.idGrupoSer),
            "nomenclatura": action.formulario.nomenclatura,
            "nombre": action.formulario.nombre,
            "nombreC": action.formulario.nombreC,
            "nombreManual": '',
            "urlImagen": '',
            "rutaManual": '',
            "peso": 0,
            "observaciones": observaciones,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": 1,
            "ponderacionByIdServicio":{
                "idPonderacionServicio": null,
                "idServicio": null,
                "valorPonderador": action.formulario.valorPonderador,
                "fechaAlta": action.formulario.rangoVigencia.split(',')[0] + 'T00:00:00',
                "fechaTermino": action.formulario.rangoVigencia.split(',')[1] + 'T23:59:59',
                "activo": 1
            },
            "contratosByIdServicio": contratos
        }
        console.log('data', data);
        const formData = new FormData();
        if(action.formulario.imagen){
            formData.append('imageFile', action.formulario.imagen.file.originFileObj);
        }
        if(action.formulario.pdf){
            formData.append('file', action.formulario.pdf.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        const response = yield call(crearServicioCall, token, formData);
        console.log(response);

        if(response.status==200){
            const showSuccessMsg = true;
            const textMessage = "El servicio " + action.formulario.nombre + " se creó de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.NUEVO_SERVICIO_SUCCESS });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearServicio' });
            yield put({ type: informacionOperativaActions.LISTA_CONTRATOS_REQUEST });
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_REQUEST });
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BUSQUEDA_REQUEST, page: action.page, formulario: action.busqueda });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: CatalogosGeneralesActions.NUEVO_SERVICIO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.NUEVO_SERVICIO_FAILURE });
    }
}
export function* crearServicioSaga() {
    yield takeLatest(CatalogosGeneralesActions.NUEVO_SERVICIO_REQUEST, crearServicio);
}

//********************** Editar servicio *****************************
function* editarServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServicio = action.servicioSeleccionado.idServicio;
        let data = {};
        let contrato = [];
        let servicioSeleccionado = action.servicioSeleccionado;

        for(let x in action.formulario.nombreContrato){
            for(let i=0; i<action.listaContratos.length; i++){
                if(action.formulario.nombreContrato[x] === action.listaContratos[i].clave){
                    contrato.push(action.listaContratos[i])
                }
            }
        }

        let idGrupoSer = null;
        if(action.formulario.grupoServicio){
            for(let i=0; i<action.listaGruposServicios.length; i++){
                if(action.formulario.grupoServicio === action.listaGruposServicios[i].nombre){
                    idGrupoSer = action.listaGruposServicios[i].idGrupoSer;
                }
            }
        }
        console.log('idGrupoSer')
        console.log(idGrupoSer)
        let observaciones = '';
        if(action.formulario.observaciones){
            observaciones = action.formulario.observaciones;
        }
        data = {
            "idServicio": idServicio,
            "idGrupoSer": idGrupoSer,
            "nomenclatura": action.formulario.nomenclatura,
            "nombre": action.formulario.nombre,
            "nombreC": action.formulario.nombreC,
            "nombreManual": action.servicioSeleccionado.nombreManual,
            "urlImagen": action.servicioSeleccionado.urlImagen,
            "rutaManual": action.servicioSeleccionado.rutaManual,
            "peso": 0,
            "observaciones": observaciones,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": action.servicioSeleccionado.activo,
            "ponderacionByIdServicio":{
                "idPonderacionServicio": null,
                "idServicio": idServicio,
                "valorPonderador": action.formulario.valorPonderado,
                "fechaAlta": action.formulario.rangoVigencia.split(",")[0] + 'T00:00:00',
                "fechaTermino": action.formulario.rangoVigencia.split(",")[1] + 'T23:59:59',
                "activo": 1
            },
            "contratosByIdServicio": contrato,
        }
        console.log('data dentro de editar');
        console.log(data);
        const formData = new FormData();
        if(action.formulario.imagen){
            formData.append('imageFile', action.formulario.imagen.file.originFileObj);
        }
        if(action.formulario.pdf){
            formData.append('file', action.formulario.pdf.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
 
        console.log('formData');
        console.log(formData);
        const response = yield call(editarServicioCall, token, formData, idServicio);
        console.log(response);

        if(response.status==200){
            const showModalEditarServicio = false;
            let textMessage = "El servicio " + action.formulario.nombre + " se modificó de forma correcta";
            servicioSeleccionado = null;
            
            const showSuccessMsg = true;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarServicio' });
            yield put({ type: CatalogosGeneralesActions.EDITAR_SERVICIO_SUCCESS });
            yield put({ type: informacionOperativaActions.LISTA_CONTRATOS_REQUEST });
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_REQUEST });
            yield put({ type: CatalogosGeneralesActions.LISTA_SERVICIOS_BUSQUEDA_REQUEST, page: action.page, formulario: action.busqueda });
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_EDITAR_SERVICIO, showModalEditarServicio, servicioSeleccionado });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: CatalogosGeneralesActions.EDITAR_SERVICIO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.EDITAR_SERVICIO_FAILURE });
    }
}
export function* editarServicioSaga() {
    yield takeLatest(CatalogosGeneralesActions.EDITAR_SERVICIO_REQUEST, editarServicio);
}

// ************ lista Areas funcionales  *********//
function* listaAreasFuncionalesActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaAreasActivosCall, token);
        console.log(response);

        if (response.status === 200) {
            const listaAreasFuncionalesActivos = response.data;

            yield put({ type: CatalogosGeneralesActions.LISTA_AREASFUNCIONALES_ACTIVOS_SUCCESS, listaAreasFuncionalesActivos });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_AREASFUNCIONALES_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_AREASFUNCIONALES_ACTIVOS_FAILURE });
    }
}
export function* listaAreasFuncionalesActivosSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_AREASFUNCIONALES_ACTIVOS_REQUEST, listaAreasFuncionalesActivos);
}

function* listaAreasFuncionalesBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let busqueda = action.busqueda;
        let response = null
        if (busqueda) {
            response = yield call(filtroCodigoAreaFuncionalClaveCall, token, busqueda, page);
        } else {
            response = yield call(obtenerAreasFuncionalesCall, token, page);
        }
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            let listaAreasFuncionalesBusqueda = response.data.data;
            const listaAreasFuncionalesBusquedaCantidad = response.data.cantidad;
            for (let i = 0; i < listaAreasFuncionalesBusqueda.length; i++) {
                let fechaAlta = listaAreasFuncionalesBusqueda[i].fechaAlta.split('T')[0];
                listaAreasFuncionalesBusqueda[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];

                //Coordenadas
                if(listaAreasFuncionalesBusqueda[i].ubicacionesByIdArea){
                    if(listaAreasFuncionalesBusqueda[i].ubicacionesByIdArea[0].coordenadasByIdUbicacion){
                        let {coordenadasByIdUbicacion} = listaAreasFuncionalesBusqueda[i].ubicacionesByIdArea[0];
                        let coordenadasArray = [];
                        let identificadores = []; 
                        for(let y in coordenadasByIdUbicacion){
                            let agregar = identificadores.includes(coordenadasByIdUbicacion[y].identificador)
                            if(!agregar){
                                identificadores.push(coordenadasByIdUbicacion[y].identificador);
                            }
                        }
                        console.log(`identificadores`, identificadores)
                        for(let x in identificadores){
                            const xyArray = []
                            for(let j=0; j<coordenadasByIdUbicacion.length; j++) {
                                if(identificadores[x] === coordenadasByIdUbicacion[j].identificador){
                                    let coordX =coordenadasByIdUbicacion[j].coordenadaX;
                                    let coordY = coordenadasByIdUbicacion[j].coordenadaY;
                                    let coord = [coordX, coordY, identificadores[x]];
                                    xyArray.push(coord);
                                }
                            }
                            coordenadasArray.push(xyArray);
                        }
                        listaAreasFuncionalesBusqueda[i].coordenadas = coordenadasArray;
                    }
                    else{
                        listaAreasFuncionalesBusqueda[i].coordenadas = []
                    }
                }
            }
            
            yield put({ type: CatalogosGeneralesActions.AREASFUNCIONALES_BUSQUEDA_SUCCESS, listaAreasFuncionalesBusqueda, listaAreasFuncionalesBusquedaCantidad });
            //yield put({ type: CatalogosGeneralesActions.AREASFUNCIONALES_BUSQUEDA_REQUEST });
        } else {
            yield put({ type: CatalogosGeneralesActions.AREASFUNCIONALES_BUSQUEDA_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.AREASFUNCIONALES_BUSQUEDA_FAILURE });
    }
};
export function* listaAreasFuncionalesBusquedaSaga() {
    yield takeLatest(CatalogosGeneralesActions.AREASFUNCIONALES_BUSQUEDA_REQUEST, listaAreasFuncionalesBusqueda);
}

function* cambiarEstadoAreasFuncionales(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idAreaFuncional = action.idAreaFuncional;

        const response = yield call(cambiarEstadoAreasFuncionalCall, token, idAreaFuncional);
        console.log(response);

        if(response.status === 200){
            const page = action.page;
            const busqueda = '';
            const showSuccessMsg = true;
            const textMessage = "El estado de la area funcional cambió de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.ESTADO_AREASFUNCIONALES_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.AREASFUNCIONALES_BUSQUEDA_REQUEST, page, busqueda });
            
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.ESTADO_AREASFUNCIONALES_FAILURE});
        }
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.ESTADO_AREASFUNCIONALES_FAILURE});
    }
}
export function* cambiarEstadoAreasFuncionalesSaga(){
   yield takeLatest(CatalogosGeneralesActions.ESTADO_AREASFUNCIONALES_REQUEST, cambiarEstadoAreasFuncionales);
}

function* editarAreasFuncionales(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let AreaFuncionalSeleccionado = action.AreaFuncionalSeleccionado;
        const idAreaFuncional  = action.AreaFuncionalSeleccionado.idAreaFuncional;
        const {editedGeometry} = action;

        let claveArea = action.formulario.claveArea;
        let codigoArea = action.formulario.codigoArea;

        if(claveArea){
            claveArea = action.formulario.claveArea.toUpperCase();
        }
        if(codigoArea) {
            codigoArea = action.formulario.codigoArea.toUpperCase();
        }

        const data = {
            "idAreaFuncional": idAreaFuncional,
            "codigoArea": codigoArea,
            "areaFuncional": action.formulario.areaFuncional,
            "claveArea": claveArea,
            'cantidad': 1,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "rutaImagen": action.AreaFuncionalSeleccionado.rutaImagen,
            "activo": action.AreaFuncionalSeleccionado.activo
        }
        const coorArray = [];
        if(editedGeometry[0]){
            for(let i in editedGeometry){
                if(!editedGeometry[i].id){
                    editedGeometry[i].map( geo => {
                        let coordenadas = {
                            "idCoordenadas": null,
                            "idUbicacion": null,
                            'identificador': i,
                            "coordenadaX": geo[0],
                            "coordenadaY": geo[1],
                            "fechaAlta": null,
                            "fechaActualizacion": null,
                            "activo": true
                        }
                        coorArray.push(coordenadas);
                    });
                }
                else{
                    editedGeometry[i].latlngs.map( geo => {
                        let coordenadas = {
                            "idCoordenadas": null,
                            "idUbicacion": null,
                            'identificador': i,
                            "coordenadaX": geo.lng,
                            "coordenadaY": geo.lat,
                            "fechaAlta": null,
                            "fechaActualizacion": null,
                            "activo": true
                        }
            
                        coorArray.push(coordenadas);
                    });
                }
            }
        }
        console.log(data, coorArray);
        const formData = new FormData();
        if(!action.bloqueado) {
            formData.append('convenio', action.formularioPDF.pdf.file.originFileObj);
        }
        if(action.formulario.plano?.file) {
            formData.append('file', action.formulario.plano.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        console.log(formData);

        formData.append('coordenadas', new Blob([JSON.stringify(coorArray)], {
            type: "application/json"
        }));

        const response = yield call(editarAreasFuncionalCall, token, idAreaFuncional, formData);
        console.log(response);

        if(response.status === 200){
            console.log("DENTRO DE 200");
            const page = action.AreaFuncionalSeleccionado.page;
            console.log(page);
            const busqueda = '';
            const showModalEditarAreaFuncional= false;
            AreaFuncionalSeleccionado = null;
            const showSuccessMsg = true;
            const textMessage = "El Area funcional " + action.formulario.codigoArea + " se modificó de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarArea'});
            yield put({ type: CatalogosGeneralesActions.EDITAR_AREASFUNCIONALES_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.AREASFUNCIONALES_BUSQUEDA_REQUEST, page, busqueda});
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_EDITAR_AREASFUNCIONALES, showModalEditarAreaFuncional, AreaFuncionalSeleccionado });
            yield put({ type: CatalogosGeneralesActions.CAMBIAR_VISTA_AREAS, cambiarVistaModalArea: 1 })
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.EDITAR_AREASFUNCIONALES_FAILURE });
        }
    }catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.EDITAR_AREASFUNCIONALES_FAILURE });
    }
}
export function* editarAreasFuncionalesSaga() {
    yield takeLatest(CatalogosGeneralesActions.EDITAR_AREASFUNCIONALES_REQUEST, editarAreasFuncionales);
}

function* crearAreasFuncionales(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let claveArea = action.formulario.claveArea;
        let codigoArea = action.formulario.codigoArea;
        const {geometry=[]} = action;
        
        if(claveArea){
            claveArea = action.formulario.claveArea.toUpperCase();  
        }
        if(codigoArea){
            codigoArea = action.formulario.codigoArea.toUpperCase();  
        }

        const data = {
            "idAreaFuncional": null,
            "codigoArea": codigoArea,
            "areaFuncional": action.formulario.areaFuncional,
            "claveArea": claveArea,
            'cantidad': 1,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "rutaImagen": null,
            "activo": true,
            'visible': true,

        };
        const coorArray = [];
        for(let i in geometry){
            geometry[i].latlngs.map( geo => {
                let coordenadas = {
                    "idCoordenadas": null,
                    "idUbicacion": null,
                    'identificador': i,
                    "coordenadaX": geo.lng,
                    "coordenadaY": geo.lat,
                    "fechaAlta": null,
                    "fechaActualizacion": null,
                    "activo": true
                }
    
                coorArray.push(coordenadas);
            });
        }
        

        console.log(data, coorArray);
        const formData = new FormData();
        if(!action.bloqueado) {
            formData.append('convenio', action.formularioPDF.pdf.file.originFileObj);
        }
        if(action.formulario.plano?.file) {
            formData.append('file', action.formulario.plano.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        formData.append('coordenadas', new Blob([JSON.stringify(coorArray)], {
            type: "application/json"
        }));

        const response = yield call(nuevaAreasFuncionalCall, token, formData);
        console.log(response);

        if(response.status === 200) {
            const page = action.page;
            const showModalNuevoAreasFuncionales = false;
            const showSuccessMsg = true;
            const textMessage = "La area funcional " + action.formulario.areaFuncional + " se creó de forma correcta";
            
            yield put({ type: CatalogosGeneralesActions.CAMBIAR_VISTA_AREAS, cambiarVistaModalArea: 1 })
            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearArea'});
            yield put({ type: CatalogosGeneralesActions.NUEVO_AREASFUNCIONALES_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.AREASFUNCIONALES_BUSQUEDA_REQUEST, page });
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_NUEVO_AREASFUNCIONALES, showModalNuevoAreasFuncionales });
            
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.NUEVO_AREASFUNCIONALES_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.NUEVO_AREASFUNCIONALES_FAILURE });
    }
}
export function* crearAreasFuncionalesSaga(){
   yield takeLatest(CatalogosGeneralesActions.NUEVO_AREASFUNCIONALES_REQUEST, crearAreasFuncionales);
}

function* areasBloquear(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(areasBloquearCall, token);
        console.log(response);

        if(response.status === 200){
            const showSuccessMsg = true;
            const textMessage = action.bloqueado ? "Se han bloqueado las áreas funcionales de forma correcta" : "Se han desbloqueado las áreas funcionales de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.AREAS_BLOQUEAR_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.AREASFUNCIONALES_BUSQUEDA_REQUEST, page: 1, busqueda: '' });
            
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.AREAS_BLOQUEAR_FAILURE});
        }
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.AREAS_BLOQUEAR_FAILURE});
    }
}
export function* areasBloquearSaga(){
   yield takeLatest(CatalogosGeneralesActions.AREAS_BLOQUEAR_REQUEST, areasBloquear);
}

// UNIDADES FUNCIONALES
// Lista de Unidades Funcionales para Catálogo de UnidadesFuncionales
function* listaUnidadesFuncionalesActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaUnidadesActivosCall, token);
        console.log(response);

        if (response.status === 200) {
            const listaUnidadesFuncionalesActivos = response.data;

            yield put({ type: CatalogosGeneralesActions.LISTA_UNIDADESFUNCIONALES_ACTIVOS_SUCCESS, listaUnidadesFuncionalesActivos });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_UNIDADESFUNCIONALES_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_UNIDADESFUNCIONALES_ACTIVOS_FAILURE });
    }
}
export function* listaUnidadesFuncionalesActivosSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_UNIDADESFUNCIONALES_ACTIVOS_REQUEST, listaUnidadesFuncionalesActivos);
}

// Lista de Unidades Funcionales Completa
function* listaUniFuncioActCompleta(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const busqueda = action.busqueda;

        const response = yield call(listaUniActCompletaCall, token, busqueda);
        console.log(response);

        if (response.status === 200) {
            const listaUnidadesFuncioActCompleta = response.data;

            yield put({ type: CatalogosGeneralesActions.LISTA_UNI_FUNCIO_ACT_COMPLETA_SUCCESS, listaUnidadesFuncioActCompleta });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_UNI_FUNCIO_ACT_COMPLETA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_UNI_FUNCIO_ACT_COMPLETA_FAILURE });
    }
}
export function* listaUniFuncioActCompletaSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_UNI_FUNCIO_ACT_COMPLETA_REQUEST, listaUniFuncioActCompleta);
}

// Lista de Unidades Funcionales Por idÁreaFuncional
function* listaUniFuncioActPorArea(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idAreaFuncional = action.idAreaFuncional;

        const response = yield call(listaUniActPorAreaCall, token, idAreaFuncional);
        console.log(response);

        if (response.status === 200) {
            const listaUnidadesFuncioActPorArea = response.data;

            yield put({ type: CatalogosGeneralesActions.LISTA_UNI_FUNCIO_ACT_POR_AREA_SUCCESS, listaUnidadesFuncioActPorArea });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_UNI_FUNCIO_ACT_POR_AREA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_UNI_FUNCIO_ACT_POR_AREA_FAILURE });
    }
}
export function* listaUniFuncioActPorAreaSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_UNI_FUNCIO_ACT_POR_AREA_REQUEST, listaUniFuncioActPorArea);
}

function* listaUnidadesFuncionalesBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let busqueda = '';
        if(action.busqueda){
            busqueda = 'unidadFuncional=' + action.busqueda + '&';
        }
        console.log('page');
        console.log(page);
        console.log('busqueda');
        console.log(busqueda);

        const response  = yield call(listaUnidadesFuncionalesBusquedaCall, token, busqueda, page);
        console.log('response unidades funcionales');
        console.log(response);

        if(response.status===200){
            let listaUnidadesFuncionalesBusqueda = response.data.data;
            const listaUnidadesFuncionalesBusquedaCantidad = response.data.cantidad;
            for(let i=0; i<listaUnidadesFuncionalesBusqueda.length; i++){
                let fechaAlta = listaUnidadesFuncionalesBusqueda[i].fechaAlta.split('T')[0];
                listaUnidadesFuncionalesBusqueda[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
                if(listaUnidadesFuncionalesBusqueda[i].areaFuncionalByIdAreaFuncional){
                    listaUnidadesFuncionalesBusqueda[i].areaFuncional = listaUnidadesFuncionalesBusqueda[i].areaFuncionalByIdAreaFuncional.areaFuncional;
                }
                else{
                    listaUnidadesFuncionalesBusqueda[i].areaFuncional = 'No tiene área funcional';
                }

                //Coordenadas
                if(listaUnidadesFuncionalesBusqueda[i].coordenadasByIdCoordenadas){
                    let {coordenadasByIdCoordenadas} = listaUnidadesFuncionalesBusqueda[i];
                    let coordenadasArray = [];
                    let identificadores = []; 
                    for(let y in coordenadasByIdCoordenadas){
                        let agregar = identificadores.includes(coordenadasByIdCoordenadas[y].identificador)
                        if(!agregar){
                            identificadores.push(coordenadasByIdCoordenadas[y].identificador);
                        }
                    }
                    console.log(`identificadores`, identificadores)
                    for(let x in identificadores){
                        const xyArray = []
                        for(let j=0; j<coordenadasByIdCoordenadas.length; j++) {
                            if(identificadores[x] === coordenadasByIdCoordenadas[j].identificador){
                                let coordX = coordenadasByIdCoordenadas[j].coordenadaX;
                                let coordY = coordenadasByIdCoordenadas[j].coordenadaY;
                                let coord = [coordX, coordY, identificadores[x]];
                                xyArray.push(coord);
                            }
                        }
                        coordenadasArray.push(xyArray);
                    }
                    listaUnidadesFuncionalesBusqueda[i].coordenadas = coordenadasArray;
                }
                else{
                    listaUnidadesFuncionalesBusqueda[i].coordenadas = []
                }
            }
            yield put({ type: CatalogosGeneralesActions.UNIDADESFUNCIONALES_BUSQUEDA_SUCCESS, listaUnidadesFuncionalesBusqueda, listaUnidadesFuncionalesBusquedaCantidad });
        }
        else{
            yield put({ type: CatalogosGeneralesActions.UNIDADESFUNCIONALES_BUSQUEDA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.UNIDADESFUNCIONALES_BUSQUEDA_FAILURE });
    }
}
export function* listaUnidadesFuncionalesBusquedaSaga() {
    yield takeLatest(CatalogosGeneralesActions.UNIDADESFUNCIONALES_BUSQUEDA_REQUEST, listaUnidadesFuncionalesBusqueda);
}

// Cambiar el estado de una Unidades Funcionales Activo/inactivo 
function* cambiarEstadoUnidadesFuncionales(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idUnidadFuncional  = action.idUnidadFuncional ;

        const response = yield call(estadoUnidadesFuncionalesCall, token, idUnidadFuncional );
        console.log(response);

        if(response.status === 200){
            const page = action.page;
            const busqueda = '';
            const showSuccessMsg = true;
            const textMessage = "El estado de la unidad funcional cambió de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.ESTADO_UNIDADESFUNCIONALES_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.UNIDADESFUNCIONALES_BUSQUEDA_REQUEST, page, busqueda });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: CatalogosGeneralesActions.ESTADO_UNIDADESFUNCIONALES_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.ESTADO_UNIDADESFUNCIONALES_FAILURE });
    }
}
export function* cambiarEstadoUnidadesFuncionalesSaga() {
    yield takeLatest(CatalogosGeneralesActions.ESTADO_UNIDADESFUNCIONALES_REQUEST, cambiarEstadoUnidadesFuncionales);
}

// Crear nueva Unidad Funcional
function* crearUnidadesFuncionales(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {geometry=[]} = action;

        const data = {
            "idUnidadFuncional": null,
            "unidadFuncional": action.formulario.unidadFuncional,
            "claveUnidad": action.formulario.claveUnidad,
            'consecutivo': 0,
            'cantidad': 1,
            'idAreaFuncional': action.formulario.idAreaFuncional,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "rutaImagen": null,
            "activo": null
        }

        const coorArray = [];
        for(let i in geometry){
            geometry[i].latlngs.map( geo => {
                let coordenadas = {
                    "idCoordenadas": null,
                    "idUbicacion": null,
                    'identificador': i,
                    "coordenadaX": geo.lng,
                    "coordenadaY": geo.lat,
                    "fechaAlta": null,
                    "fechaActualizacion": null,
                    "activo": true
                }
    
                coorArray.push(coordenadas);
            });
        }

        console.log(data, coorArray);
        const formData = new FormData();
        if(!action.bloqueado) {
            formData.append('convenio', action.formularioPDF.pdf.file.originFileObj);
        }
        if(action.formulario.imagen?.file) {
            formData.append('file', action.formulario.imagen.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        formData.append('coordenadas', new Blob([JSON.stringify(coorArray)], {
            type: "application/json"
        }));

        console.log(formData);
        const response = yield call(crearUnidadesFuncionalesCall, token, formData);
        console.log(response);

        if(response.status==200){
            const showModalNuevoUnidadesFuncionales = false;
            const showSuccessMsg = true;
            const textMessage = "La unidad funcional " + action.formulario.unidadFuncional + " se creó de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearUnidadFuncional'});
            yield put({ type: CatalogosGeneralesActions.NUEVO_UNIDADESFUNCIONALES_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.UNIDADESFUNCIONALES_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda });
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_NUEVO_UNIDADESFUNCIONALES, showModalNuevoUnidadesFuncionales });
            yield put({ type: CatalogosGeneralesActions.CAMBIAR_VISTA_UNIDAD, cambiarVistaModalUnidad: 1 })
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: CatalogosGeneralesActions.NUEVO_UNIDADESFUNCIONALES_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.NUEVO_UNIDADESFUNCIONALES_FAILURE });
    }
}
export function* crearUnidadesFuncionalesSaga() {
    yield takeLatest(CatalogosGeneralesActions.NUEVO_UNIDADESFUNCIONALES_REQUEST, crearUnidadesFuncionales);
}

// Editar Unidades Funcionales
function* editarUnidadesFuncionales(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idUnidadFuncional  = action.UnidadesFuncionalesSeleccionado.idUnidadFuncional;
        let idAreaFuncional = action.formulario.areaFuncional;
        const {editedGeometry} = action;

        if(isNaN(idAreaFuncional)){
            idAreaFuncional = action.UnidadesFuncionalesSeleccionado.idAreaFuncional;
        }
        const data = {
            "idUnidadFuncional": idUnidadFuncional,
            "unidadFuncional": action.formulario.unidadFuncional,
            "claveUnidad": action.formulario.claveUnidad,
            'consecutivo': action.UnidadesFuncionalesSeleccionado.consecutivo,
            'cantidad': 1,
            'idAreaFuncional': idAreaFuncional,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "rutaImagen": action.UnidadesFuncionalesSeleccionado.rutaImagen,
            "activo": true
        }

        const coorArray = [];
        if(editedGeometry[0]){
            for(let i in editedGeometry){
                if(!editedGeometry[i].id){
                    editedGeometry[i].map( geo => {
                        let coordenadas = {
                            "idCoordenadas": null,
                            "idUbicacion": null,
                            'identificador': i,
                            "coordenadaX": geo[0],
                            "coordenadaY": geo[1],
                            "fechaAlta": null,
                            "fechaActualizacion": null,
                            "activo": true
                        }
                        coorArray.push(coordenadas);
                    });
                }
                else{
                    editedGeometry[i].latlngs.map( geo => {
                        let coordenadas = {
                            "idCoordenadas": null,
                            "idUbicacion": null,
                            'identificador': i,
                            "coordenadaX": geo.lng,
                            "coordenadaY": geo.lat,
                            "fechaAlta": null,
                            "fechaActualizacion": null,
                            "activo": true
                        }
            
                        coorArray.push(coordenadas);
                    });
                }
            }
        }
        console.log(data, coorArray);
        const formData = new FormData();
        if(!action.bloqueado) {
            formData.append('convenio', action.formularioPDF.pdf.file.originFileObj);
        }
        if(action.formulario.imagen?.file) {
            formData.append('file', action.formulario.imagen.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        formData.append('coordenadas', new Blob([JSON.stringify(coorArray)], {
            type: "application/json"
        }));

        console.log(formData);
        const response = yield call(editarUnidadesFuncionalesCall, token, formData, idUnidadFuncional );
        console.log(response);

        if(response.status === 200){
            const textMessage = "La unidad funcional " + action.formulario.unidadFuncional + " se modificó de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarUnidadFuncional'});
            yield put({ type: CatalogosGeneralesActions.EDITAR_UNIDADESFUNCIONALES_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.UNIDADESFUNCIONALES_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda });
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_EDITAR_UNIDADESFUNCIONALES, showModalEditarUnidadesFuncionales: false, UnidadesFuncionalesSeleccionado: null });
            yield put({ type: CatalogosGeneralesActions.CAMBIAR_VISTA_UNIDAD, cambiarVistaModalUnidad: 1 })
        }
        else{
            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg: true, textMessage: response.message });
            yield put({ type: CatalogosGeneralesActions.EDITAR_UNIDADESFUNCIONALES_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.EDITAR_UNIDADESFUNCIONALES_FAILURE });
    }
}
export function* editarUnidadesFuncionalesSaga() {
    yield takeLatest(CatalogosGeneralesActions.EDITAR_UNIDADESFUNCIONALES_REQUEST, editarUnidadesFuncionales);
}

// *********** Interior ******* //
function* listaInteriorActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaInterioresActivosCall, token);
        console.log(response);

        if (response.status === 200) {
            const listaInteriorActivos = response.data;

            yield put({ type: CatalogosGeneralesActions.LISTA_INTERIOR_ACTIVOS_SUCCESS, listaInteriorActivos });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_INTERIOR_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_INTERIOR_ACTIVOS_FAILURE });
    }
}
export function* listaInteriorActivosSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_INTERIOR_ACTIVOS_REQUEST, listaInteriorActivos);
}

function* listaInteriorActivosCompleta(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const busqueda = action.busqueda;

        const response = yield call(listaInterioresActivosCompletaCall, token, busqueda);
        console.log(response);

        if (response.status === 200) {
            const listaInteriorActivosCompleta = response.data.data;

            yield put({ type: CatalogosGeneralesActions.LISTA_INTERIORES_ACT_COMPLETA_SUCCESS, listaInteriorActivosCompleta });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_INTERIORES_ACT_COMPLETA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_INTERIORES_ACT_COMPLETA_FAILURE });
    }
}
export function* listaInteriorActivosCompletaSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_INTERIORES_ACT_COMPLETA_REQUEST, listaInteriorActivosCompleta);
}

// Lista de Interior Funcional Por idUnidadFuncional
function* listaInteriorActivosPorUnidad(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idUnidadFuncional = action.idUnidadFuncional;
        
        const response = yield call(listaInterioresActivosPorUnidadCall, token, idUnidadFuncional);
        console.log(response);

        if (response.status === 200) {
            const listaInteriorActivosPorUnidad = response.data;

            yield put({ type: CatalogosGeneralesActions.LISTA_INTERIORES_ACT_POR_UNIDAD_SUCCESS, listaInteriorActivosPorUnidad });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_INTERIORES_ACT_POR_UNIDAD_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_INTERIORES_ACT_POR_UNIDAD_FAILURE });
    }
}
export function* listaInteriorActivosPorUnidadSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_INTERIORES_ACT_POR_UNIDAD_REQUEST, listaInteriorActivosPorUnidad);
}

function* listaInteriorBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let busqueda = action.busqueda;
        let response = null
        if (busqueda) {
            response = yield call(filtroInteriorCall, token, busqueda, page);
        } else {
            response = yield call(obtenerInteriorCall, token, page);
        }
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            let listaInteriorBusqueda = response.data.data;
            const listaInteriorBusquedaCantidad = response.data.cantidad;
            for(let i=0; i<listaInteriorBusqueda.length; i++){
                listaInteriorBusqueda[i].todoUbicacion = '';
                if(listaInteriorBusqueda[i].unidadFuncionalByIdUnidadFuncional){
                    listaInteriorBusqueda[i].unidadFuncional = listaInteriorBusqueda[i].unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                
                    if(listaInteriorBusqueda[i].unidadFuncionalByIdUnidadFuncional.areaFuncionalByIdAreaFuncional){
                        listaInteriorBusqueda[i].areaFuncional = listaInteriorBusqueda[i].unidadFuncionalByIdUnidadFuncional.areaFuncionalByIdAreaFuncional.areaFuncional
                    }
                    else{
                        listaInteriorBusqueda[i].areaFuncional = 'No tiene área funcional;'
                    }
                    listaInteriorBusqueda[i].todoUbicacion += listaInteriorBusqueda[i].areaFuncional + ', ' + listaInteriorBusqueda[i].unidadFuncionalByIdUnidadFuncional.unidadFuncional; 

                }
                else{
                    listaInteriorBusqueda[i].unidadFuncional = 'No tiene unidad funcional';
                    listaInteriorBusqueda[i].areaFuncional = 'No tiene área funcional';
                    listaInteriorBusqueda[i].todoUbicacion = 'No tiene unidad funcional';
                }

                //Coordenadas
                if(listaInteriorBusqueda[i].coordenadasInteriorFuncionalByIdUbicacion){
                    let {coordenadasInteriorFuncionalByIdUbicacion} = listaInteriorBusqueda[i];
                    let coordenadasArray = [];
                    let identificadores = []; 
                    for(let y in coordenadasInteriorFuncionalByIdUbicacion){
                        let agregar = identificadores.includes(coordenadasInteriorFuncionalByIdUbicacion[y].identificador)
                        if(!agregar){
                            identificadores.push(coordenadasInteriorFuncionalByIdUbicacion[y].identificador);
                        }
                    }
                    console.log(`identificadores`, identificadores)
                    for(let x in identificadores){
                        const xyArray = []
                        for(let j=0; j<coordenadasInteriorFuncionalByIdUbicacion.length; j++) {
                            if(identificadores[x] === coordenadasInteriorFuncionalByIdUbicacion[j].identificador){
                                let coordX = coordenadasInteriorFuncionalByIdUbicacion[j].coordenadaX;
                                let coordY = coordenadasInteriorFuncionalByIdUbicacion[j].coordenadaY;
                                let coord = [coordX, coordY, identificadores[x]];
                                xyArray.push(coord);
                            }
                        }
                        coordenadasArray.push(xyArray);
                    }
                    listaInteriorBusqueda[i].coordenadas = coordenadasArray;
                }
                else{
                    listaInteriorBusqueda[i].coordenadas = []
                }
                
            }
            yield put({ type: CatalogosGeneralesActions.INTERIOR_BUSQUEDA_SUCCESS, listaInteriorBusqueda, listaInteriorBusquedaCantidad });
        } 
        else {
            yield put({ type: CatalogosGeneralesActions.INTERIOR_BUSQUEDA_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.INTERIOR_BUSQUEDA_FAILURE });
    }
};
export function* listaInteriorBusquedaSaga() {
    yield takeLatest(CatalogosGeneralesActions.INTERIOR_BUSQUEDA_REQUEST, listaInteriorBusqueda);
}


function* cambiarEstadoInterior(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idInteriorFuncional = action.idInteriorFuncional;

        const response = yield call(cambiarEstadoInteriorCall, token, idInteriorFuncional);
        console.log(response);

        if(response.status === 200){
            const page = action.page;
            const busqueda = '';
            const showSuccessMsg = true;
            const textMessage = "El estado de la Interior Funcional cambió de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.ESTADO_INTERIOR_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.INTERIOR_BUSQUEDA_REQUEST, page, busqueda });
           // yield put({ type: CatalogosGeneralesActions.INTERIOR_BUSQUEDA_REQUEST});
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.ESTADO_INTERIOR_FAILURE});
        }
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.ESTADO_INTERIOR_FAILURE});
    }
}
export function* cambiarEstadoInteriorSaga(){
   yield takeLatest(CatalogosGeneralesActions.ESTADO_INTERIOR_REQUEST, cambiarEstadoInterior);
}

function* crearInterior(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idInteriorFuncional": null,
            "interiorFuncional": action.formulario.interiorFuncional,
            'consecutivo': 0,
            'cantidad': 1,
            'idUnidadFuncional': action.formulario.idUnidadFuncional,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
        };

        const {geometry=[]} = action;

        const coorArray = [];
        for(let i in geometry){
            geometry[i].latlngs.map( geo => {
                let coordenadas = {
                    "idCoordenadas": null,
                    "idUbicacion": null,
                    'identificador': i,
                    "coordenadaX": geo.lng,
                    "coordenadaY": geo.lat,
                    "fechaAlta": null,
                    "fechaActualizacion": null,
                    "activo": true
                }
    
                coorArray.push(coordenadas);
            });
        }

        console.log(data, coorArray);
        const formData = new FormData();
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        formData.append('coordenadas', new Blob([JSON.stringify(coorArray)], {
            type: "application/json"
        }));

        console.log(formData);

        const response = yield call(nuevaInteriorCall, token, formData);
        console.log(response);

        if(response.status === 200) {
            const page = action.page;
            const showModalNuevoInterior = false;
            const showSuccessMsg = true;
            const textMessage = "El Interior Funcional " + action.formulario.interiorFuncional + " se creó de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearInterior'});
            yield put({ type: CatalogosGeneralesActions.NUEVO_INTERIOR_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.INTERIOR_BUSQUEDA_REQUEST, page });
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_NUEVO_INTERIOR, showModalNuevoInterior });
            //yield put({ type: CatalogosGeneralesActions.INTERIOR_BUSQUEDA_REQUEST});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.NUEVO_INTERIOR_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.NUEVO_INTERIOR_FAILURE });
    }
}
export function* crearInteriorSaga(){
   yield takeLatest(CatalogosGeneralesActions.NUEVO_INTERIOR_REQUEST, crearInterior);
}

function* editarInterior(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let interiorSeleccionado = action.interiorSeleccionado;
        let idInteriorFuncional = action.interiorSeleccionado.idInteriorFuncional;
        let idUnidadFuncional = action.formulario.todoUbicacion;
        if(isNaN(idUnidadFuncional)){
            idUnidadFuncional = action.interiorSeleccionado.idUnidadFuncional;
        };

        const data = {
            "idInteriorFuncional": idInteriorFuncional,
            "interiorFuncional": action.formulario.interiorFuncional,
            'consecutivo': action.interiorSeleccionado.consecutivo,
            'cantidad': 1,
            'idUnidadFuncional': idUnidadFuncional,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
        };

        const {editedGeometry=[]} = action;

        const coorArray = [];
        if(editedGeometry[0]){
            for(let i in editedGeometry){
                if(!editedGeometry[i].id){
                    editedGeometry[i].map( geo => {
                        let coordenadas = {
                            "idCoordenadas": null,
                            "idUbicacion": null,
                            'identificador': i,
                            "coordenadaX": geo[0],
                            "coordenadaY": geo[1],
                            "fechaAlta": null,
                            "fechaActualizacion": null,
                            "activo": true
                        }
                        coorArray.push(coordenadas);
                    });
                }
                else{
                    editedGeometry[i].latlngs.map( geo => {
                        let coordenadas = {
                            "idCoordenadas": null,
                            "idUbicacion": null,
                            'identificador': i,
                            "coordenadaX": geo.lng,
                            "coordenadaY": geo.lat,
                            "fechaAlta": null,
                            "fechaActualizacion": null,
                            "activo": true
                        }
            
                        coorArray.push(coordenadas);
                    });
                }
            }
        }

        console.log(data, coorArray);
        const formData = new FormData();
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        formData.append('coordenadas', new Blob([JSON.stringify(coorArray)], {
            type: "application/json"
        }));

        console.log(formData);

        console.log(data);
        const response = yield call(editarInteriorCall, token, idInteriorFuncional, formData);
        console.log(response);


        if (response.status === 200) {
            console.log("DENTRO DE 200");
            const page = action.interiorSeleccionado.page;
            console.log(page);
            const busqueda = '';
            const showModalEditarInterior = false;
            interiorSeleccionado = null;
            const showSuccessMsg = true;
            const textMessage = "El Interior funcional " + action.formulario.interiorFuncional + " se modificó de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarInterior'});
            yield put({ type: CatalogosGeneralesActions.EDITAR_INTERIOR_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.INTERIOR_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_EDITAR_INTERIOR, showModalEditarInterior, interiorSeleccionado });
           // yield put({ type: CatalogosGeneralesActions.INTERIOR_BUSQUEDA_REQUEST});
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.EDITAR_INTERIOR_FAILURE });
        }
    } catch (error) {
        yield put({ type: CatalogosGeneralesActions.EDITAR_INTERIOR_FAILURE });
    }
}
export function* editarInteriorSaga(){
    yield takeLatest(CatalogosGeneralesActions.EDITAR_INTERIOR_REQUEST, editarInterior);
}

// ********** SUB INTERIOR  *********** //
function* listaSubInteriorActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaSubInterioresActivosCall, token);
        console.log(response);

        if (response.status === 200) {
            const listaSubInteriorActivos = response.data;

            yield put({ type: CatalogosGeneralesActions.LISTA_SUBINTERIOR_ACTIVOS_SUCCESS, listaSubInteriorActivos });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_SUBINTERIOR_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_SUBINTERIOR_ACTIVOS_FAILURE });
    }
}
export function* listaSubInteriorActivosSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_SUBINTERIOR_ACTIVOS_REQUEST, listaSubInteriorActivos);
}

// Lista de SubInterior Funcional Por idInteriorFuncional
function* listaSubInteriorActivosPorInterior(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idInteriorFuncional = action.idInteriorFuncional;
        
        const response = yield call(listaSubInterioresActivosPorInteriorCall, token, idInteriorFuncional);
        console.log(response);

        if (response.status === 200) {
            const listaSubInteriorActivosPorInterior = response.data;

            yield put({ type: CatalogosGeneralesActions.LISTA_SUBINTERIOR_ACTIVOS_POR_INTERIOR_SUCCESS, listaSubInteriorActivosPorInterior });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_SUBINTERIOR_ACTIVOS_POR_INTERIOR_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_SUBINTERIOR_ACTIVOS_POR_INTERIOR_FAILURE });
    }
}
export function* listaSubInteriorActivosPorInteriorSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_SUBINTERIOR_ACTIVOS_POR_INTERIOR_REQUEST, listaSubInteriorActivosPorInterior);
}

function* listaSubInteriorBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let busqueda;
        action.busqueda ? busqueda = action.busqueda: busqueda = '';

        const response = yield call(filtroSubInteriorCall, token, busqueda, page);
        console.log(response);

        if (response.status === 200) {
            let listaSubInteriorBusqueda = response.data.data;
            const listaSubInteriorBusquedaCantidad = response.data.cantidad;
            for(let i=0; i<listaSubInteriorBusqueda.length; i++){
                listaSubInteriorBusqueda[i].todoUbicacion = '';
                if(listaSubInteriorBusqueda[i].interiorFuncionalByIdInteriorFuncional){
                    listaSubInteriorBusqueda[i].interiorFuncional = listaSubInteriorBusqueda[i].interiorFuncionalByIdInteriorFuncional.interiorFuncional;

                    if(listaSubInteriorBusqueda[i].interiorFuncionalByIdInteriorFuncional.unidadFuncionalByIdUnidadFuncional){
                        listaSubInteriorBusqueda[i].unidadFuncional = listaSubInteriorBusqueda[i].interiorFuncionalByIdInteriorFuncional.unidadFuncionalByIdUnidadFuncional.unidadFuncional;

                        if(listaSubInteriorBusqueda[i].interiorFuncionalByIdInteriorFuncional.unidadFuncionalByIdUnidadFuncional.areaFuncionalByIdAreaFuncional){
                            listaSubInteriorBusqueda[i].areaFuncional = listaSubInteriorBusqueda[i].interiorFuncionalByIdInteriorFuncional.unidadFuncionalByIdUnidadFuncional.areaFuncionalByIdAreaFuncional.areaFuncional;
                        }
                        else{
                            listaSubInteriorBusqueda[i].areaFuncional = 'No tiene área funcional;'
                        }
                        listaSubInteriorBusqueda[i].todoUbicacion = listaSubInteriorBusqueda[i].areaFuncional + ', ' + listaSubInteriorBusqueda[i].unidadFuncional;
                    }
                    else{
                        listaSubInteriorBusqueda[i].unidadFuncional = 'No tiene unidad funcional';
                        listaSubInteriorBusqueda[i].todoUbicacion = 'No tiene unidad funcional';
                    }
                    listaSubInteriorBusqueda[i].todoUbicacion += ', ' + listaSubInteriorBusqueda[i].interiorFuncional;
                }
                else{
                    listaSubInteriorBusqueda[i].interiorFuncional = 'No tiene interior funcional';
                    listaSubInteriorBusqueda[i].unidadFuncional = 'No tiene unidad funcional';
                    listaSubInteriorBusqueda[i].areaFuncional = 'No tiene área funcional';
                    listaSubInteriorBusqueda[i].todoUbicacion = 'No tiene interior funcional';
                }

                //Coordenadas
                if(listaSubInteriorBusqueda[i].coordenadasSubInteriorFuncionalByIdUbicacion){
                    let {coordenadasSubInteriorFuncionalByIdUbicacion} = listaSubInteriorBusqueda[i];
                    let coordenadasArray = [];
                    let identificadores = []; 
                    for(let y in coordenadasSubInteriorFuncionalByIdUbicacion){
                        let agregar = identificadores.includes(coordenadasSubInteriorFuncionalByIdUbicacion[y].identificador)
                        if(!agregar){
                            identificadores.push(coordenadasSubInteriorFuncionalByIdUbicacion[y].identificador);
                        }
                    }
                    console.log(`identificadores`, identificadores)
                    for(let x in identificadores){
                        const xyArray = []
                        for(let j=0; j<coordenadasSubInteriorFuncionalByIdUbicacion.length; j++) {
                            if(identificadores[x] === coordenadasSubInteriorFuncionalByIdUbicacion[j].identificador){
                                let coordX = coordenadasSubInteriorFuncionalByIdUbicacion[j].coordenadaX;
                                let coordY = coordenadasSubInteriorFuncionalByIdUbicacion[j].coordenadaY;
                                let coord = [coordX, coordY, identificadores[x]];
                                xyArray.push(coord);
                            }
                        }
                        coordenadasArray.push(xyArray);
                    }
                    listaSubInteriorBusqueda[i].coordenadas = coordenadasArray;
                }
                else{
                    listaSubInteriorBusqueda[i].coordenadas = []
                }
            }
           
            yield put({ type: CatalogosGeneralesActions.SUBINTERIOR_BUSQUEDA_SUCCESS, listaSubInteriorBusqueda, listaSubInteriorBusquedaCantidad });
           // yield put({ type: CatalogosGeneralesActions.SUBINTERIOR_BUSQUEDA_REQUEST});
        } else {
            yield put({ type: CatalogosGeneralesActions.SUBINTERIOR_BUSQUEDA_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.SUBINTERIOR_BUSQUEDA_FAILURE });
    }
};
export function* listaSubInteriorBusquedaSaga() {
    yield takeLatest(CatalogosGeneralesActions.SUBINTERIOR_BUSQUEDA_REQUEST, listaSubInteriorBusqueda);
}

function* cambiarEstadoSubInterior(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let idSubInteriorFuncional = action.idSubInteriorFuncional;

        const response = yield call(cambiarEstadoSubInteriorCall, token, idSubInteriorFuncional);
        console.log(response);

        if(response.status === 200){
            const page = action.page;
            const busqueda = '';
            const showSuccessMsg = true;
            const textMessage = "El estado de la SubInterior Funcional cambió de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.ESTADO_SUBINTERIOR_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.SUBINTERIOR_BUSQUEDA_REQUEST, page, busqueda });
           // yield put({ type: CatalogosGeneralesActions.SUBINTERIOR_BUSQUEDA_REQUEST});
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.ESTADO_SUBINTERIOR_FAILURE});
        }
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.ESTADO_SUBINTERIOR_FAILURE});
    }
}
export function* cambiarEstadoSubInteriorSaga(){
   yield takeLatest(CatalogosGeneralesActions.ESTADO_SUBINTERIOR_REQUEST, cambiarEstadoSubInterior);
}

function* crearSubInterior(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idSubInteriorFuncional": null,
            "subInteriorFuncional": action.formulario.subInteriorFuncional,
            'consecutivo': 0,
            'idInteriorFuncional': action.formulario.idInteriorFuncional,
            'cantidad': 1,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
        };

        const {geometry=[]} = action;

        const coorArray = [];
        for(let i in geometry){
            geometry[i].latlngs.map( geo => {
                let coordenadas = {
                    "idCoordenadas": null,
                    "idUbicacion": null,
                    'identificador': i,
                    "coordenadaX": geo.lng,
                    "coordenadaY": geo.lat,
                    "fechaAlta": null,
                    "fechaActualizacion": null,
                    "activo": true
                }
    
                coorArray.push(coordenadas);
            });
        }

        console.log(data, coorArray);
        const formData = new FormData();
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        formData.append('coordenadas', new Blob([JSON.stringify(coorArray)], {
            type: "application/json"
        }));

        console.log(formData);

        const response = yield call(nuevaSubInteriorCall, token, formData);
        console.log(response);

        if(response.status === 200) {
            const page = action.page;
            const busqueda = action.busqueda;
            const showModalNuevoSubInterior = false;
            const showSuccessMsg = true;
            const textMessage = "El SubInterior Funcional " + action.formulario.subInteriorFuncional + " se creó de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearSubInterior'});
            yield put({ type: CatalogosGeneralesActions.NUEVO_SUBINTERIOR_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.SUBINTERIOR_BUSQUEDA_REQUEST, page, busqueda});
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_NUEVO_SUBINTERIOR, showModalNuevoSubInterior });
           // yield put({ type: CatalogosGeneralesActions.SUBINTERIOR_BUSQUEDA_REQUEST});
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.NUEVO_SUBINTERIOR_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.NUEVO_SUBINTERIOR_FAILURE });
    }
}
export function* crearSubInteriorSaga(){
   yield takeLatest(CatalogosGeneralesActions.NUEVO_SUBINTERIOR_REQUEST, crearSubInterior);
}

function* editarSubInterior(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let subInteriorSeleccionado = action.subInteriorSeleccionado;
        let idSubInteriorFuncional = action.subInteriorSeleccionado.idSubInteriorFuncional;
        let idInteriorFuncional = action.formulario.todoUbicacion;
        if(isNaN(idInteriorFuncional)){
            idInteriorFuncional = action.subInteriorSeleccionado.idInteriorFuncional;
        }

        const data = {
            "idSubInteriorFuncional": idSubInteriorFuncional,
            "subInteriorFuncional": action.formulario.subInteriorFuncional,
            'consecutivo': action.subInteriorSeleccionado.consecutivo,
            'idInteriorFuncional': idInteriorFuncional,
            'cantidad': 1,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
        };

        const {editedGeometry=[]} = action;

        const coorArray = [];
        if(editedGeometry[0]){
            for(let i in editedGeometry){
                if(!editedGeometry[i].id){
                    editedGeometry[i].map( geo => {
                        let coordenadas = {
                            "idCoordenadas": null,
                            "idUbicacion": null,
                            'identificador': i,
                            "coordenadaX": geo[0],
                            "coordenadaY": geo[1],
                            "fechaAlta": null,
                            "fechaActualizacion": null,
                            "activo": true
                        }
                        coorArray.push(coordenadas);
                    });
                }
                else{
                    editedGeometry[i].latlngs.map( geo => {
                        let coordenadas = {
                            "idCoordenadas": null,
                            "idUbicacion": null,
                            'identificador': i,
                            "coordenadaX": geo.lng,
                            "coordenadaY": geo.lat,
                            "fechaAlta": null,
                            "fechaActualizacion": null,
                            "activo": true
                        }
            
                        coorArray.push(coordenadas);
                    });
                }
            }
        }

        console.log(data, coorArray);
        const formData = new FormData();
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        formData.append('coordenadas', new Blob([JSON.stringify(coorArray)], {
            type: "application/json"
        }));

        console.log(formData);

        const response = yield call(editarSubInteriorCall, token, idSubInteriorFuncional, formData);
        console.log(response);


        if (response.status === 200) {
            const page = action.page;
            const busqueda = action.busqueda;
            const showModalEditarSubInterior = false;
            subInteriorSeleccionado = null;
            const showSuccessMsg = true;
            const textMessage = "El Sub Interior funcional " + action.formulario.subInteriorFuncional + " se modificó de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarSubInterior'});
            yield put({ type: CatalogosGeneralesActions.EDITAR_SUBINTERIOR_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.SUBINTERIOR_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_EDITAR_SUBINTERIOR, showModalEditarSubInterior, subInteriorSeleccionado });
            
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.EDITAR_SUBINTERIOR_FAILURE });
        }
    } catch (error) {
        yield put({ type: CatalogosGeneralesActions.EDITAR_SUBINTERIOR_FAILURE });
    }
}
export function* editarSubInteriorSaga(){
    yield takeLatest(CatalogosGeneralesActions.EDITAR_SUBINTERIOR_REQUEST, editarSubInterior);
}

//CÁTALOGO CATEGORIAS DE FALLAS
function* listaCategoriasFallasActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaCategoriasFallasActivosCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaCategoriasDeFallasActivos = response.data;
            for(let i=0; i<listaCategoriasDeFallasActivos.length; i++){
                listaCategoriasDeFallasActivos[i].fallaCategoriaCompleta = listaCategoriasDeFallasActivos[i].numeroFallaCategoria + ' - ' + listaCategoriasDeFallasActivos[i].fallaCategoria + ' ' + listaCategoriasDeFallasActivos[i].unidadMedidaByIdUnidadMedida.unidadMedida;
            }

            yield put({ type: CatalogosGeneralesActions.LISTA_CATEGORIAS_FALLAS_ACTIVOS_SUCCESS, listaCategoriasDeFallasActivos });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_CATEGORIAS_FALLAS_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_CATEGORIAS_FALLAS_ACTIVOS_FAILURE });
    }
}
export function* listaCategoriasDeFallasActivosSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_CATEGORIAS_FALLAS_ACTIVOS_REQUEST, listaCategoriasFallasActivos);
}

function* listaCategoriasDeFallasBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let busqueda = '';
        if (action.busqueda) {
            console.log("ENTRA CON BUSQUEDA");
            busqueda = action.busqueda;
        }
        console.log(page);
        console.log(busqueda);
        const response = yield call(getCategoriasFallasBusquedaCall, token, page, busqueda);
        console.log(response);
        console.log(response.data.data)

        if (response.status == 200) {
            let listaCategoriasDeFallasBusqueda = response.data.data;
            let listaCategoriasDeFallasBusquedaCantidad = response.data.cantidad;
            for (let i = 0; i < listaCategoriasDeFallasBusqueda.length; i++) {
                let fechaAlta = listaCategoriasDeFallasBusqueda[i].fechaAlta.split('T')[0];
                listaCategoriasDeFallasBusqueda[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
                listaCategoriasDeFallasBusqueda[i].idUnidadMedida = listaCategoriasDeFallasBusqueda[i].unidadMedidaByIdUnidadMedida.unidadMedida;
            }
            yield put({ type: CatalogosGeneralesActions.CATEGORIAS_FALLAS_BUSQUEDA_SUCCESS, listaCategoriasDeFallasBusqueda, listaCategoriasDeFallasBusquedaCantidad });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.CATEGORIAS_FALLAS_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.CATEGORIAS_FALLAS_BUSQUEDA_FAILURE });
    }
}
export function* listaCategoriasDeFallasBusquedaSaga() {
    yield takeLatest(CatalogosGeneralesActions.CATEGORIAS_FALLAS_BUSQUEDA_REQUEST, listaCategoriasDeFallasBusqueda);
}

function* crearNuevaCategoriaDeFalla(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idFallaCategoria": null,
            "fallaCategoria": action.formulario.tiempoRehabilitacion,
            "numeroFallaCategoria": action.formulario.categoria,
            "idUnidadMedida": parseInt(action.formulario.idUnidadMedida),
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }
        console.log(data);
        const response = yield call(crearCategoriaFallaCall, token, data);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showModalNuevaCategoriaDeFalla = false;
            const showSuccessMsg = true;
            const textMessage = "La categoría de falla " + action.formulario.categoria + " fue agregada con éxito.";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "crearCategoriaFalla" });
            yield put({ type: CatalogosGeneralesActions.NUEVA_CATEGORIA_FALLA_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.CATEGORIAS_FALLAS_BUSQUEDA_REQUEST, page });
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_NUEVA_CATEGORIA_FALLA, showModalNuevaCategoriaDeFalla });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.NUEVA_CATEGORIA_FALLA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.NUEVA_CATEGORIA_FALLA_FAILURE });
    }
}
export function* crearCategoriaDeFallaSaga() {
    yield takeLatest(CatalogosGeneralesActions.NUEVA_CATEGORIA_FALLA_REQUEST, crearNuevaCategoriaDeFalla);
}

function* editarCategoriaFalla(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idFallaCategoria = action.CategoriaDeFallaSeleccionado.idFallaCategoria;
        let idUnidadMedida = action.formulario.idUnidadMedida;
        
        for(let i=0; i<action.listaUnidadesDeMedicionActivos.length; i++) {
            if(action.formulario.idUnidadMedida === action.listaUnidadesDeMedicionActivos[i].unidadMedida) {
                idUnidadMedida = action.listaUnidadesDeMedicionActivos[i].idUnidadMedida;
            }
        }
        
        const data = {
            "idFallaCategoria": null,
            "fallaCategoria": action.formulario.fallaCategoria,
            "numeroFallaCategoria": action.formulario.numeroFallaCategoria,
            "idUnidadMedida": parseInt(idUnidadMedida),
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }

        
        console.log(data);
        const response = yield call(editarCategoriaFallaCall, token, data, idFallaCategoria);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showModalEditarCategoriaDeFalla = false;
            const CategoriaDeFallaSeleccionado = null;
            const limpiarFormulario = "editarCategoriaFalla";
            const showSuccessMsg = true;
            const textMessage = "La categoría de falla " + action.formulario.numeroFallaCategoria + " fue actualizada con éxito";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario });
            yield put({ type: CatalogosGeneralesActions.EDITAR_CATEGORIA_FALLA_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.CATEGORIAS_FALLAS_BUSQUEDA_REQUEST, page });
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_EDITAR_CATEGORIA_FALLA, showModalEditarCategoriaDeFalla, CategoriaDeFallaSeleccionado });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message + " Modificar la categoría de falla.";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.EDITAR_CATEGORIA_FALLA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.EDITAR_CATEGORIA_FALLA_FAILURE });
    }
}
export function* editarUnidadDeMedicionSaga() {
    yield takeLatest(CatalogosGeneralesActions.EDITAR_CATEGORIA_FALLA_REQUEST, editarCategoriaFalla);
}

//********************** Cambiar el estado de una Unidad de Medición *****************************
function* cambiarEstadoCategoriaDeFalla(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idFallaCategoria = action.idFallaCategoria;

        const response = yield call(estadoCategoriaFallaCall, token, idFallaCategoria);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showSuccessMsg = true;
            const textMessage = "El estado de la categoría de falla se cambió de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.ESTADO_CATEGORIA_FALLA_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.CATEGORIAS_FALLAS_BUSQUEDA_REQUEST, page });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.ESTADO_CATEGORIA_FALLA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.ESTADO_CATEGORIA_FALLA_FAILURE });
    }
}
export function* cambiarEstadoCategoriaDeFallaSaga() {
    yield takeLatest(CatalogosGeneralesActions.ESTADO_CATEGORIA_FALLA_REQUEST, cambiarEstadoCategoriaDeFalla);
}

// *********** CATALOGO UBICACIONES *********** //
function* listaUbicacionesTodasBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const param = action.param;

        const response = yield call(listaUbicacionesTodasBusquedaCall, token, param);
        console.log(response);

        if (response.status === 200) {
            const listaUbicacionesTodasBusqueda = response.data;

            for(let i=0; i<listaUbicacionesTodasBusqueda.length; i++){
                listaUbicacionesTodasBusqueda[i].todoUbicacion = listaUbicacionesTodasBusqueda[i].areaFuncionalByIdAreaFuncional.areaFuncional;
                if(listaUbicacionesTodasBusqueda[i].unidadFuncionalByIdUnidadFuncional){
                    listaUbicacionesTodasBusqueda[i].todoUbicacion += ', ' + listaUbicacionesTodasBusqueda[i].unidadFuncionalByIdUnidadFuncional.unidadFuncional 
                }
                if(listaUbicacionesTodasBusqueda[i].interiorFuncionalByIdInteriorFuncional){
                    listaUbicacionesTodasBusqueda[i].todoUbicacion += ', ' + listaUbicacionesTodasBusqueda[i].interiorFuncionalByIdInteriorFuncional.interiorFuncional;
                }
                if(listaUbicacionesTodasBusqueda[i].subInteriorFuncionalByIdSubInteriorFuncional){
                    listaUbicacionesTodasBusqueda[i].todoUbicacion += ', ' + listaUbicacionesTodasBusqueda[i].subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional 
                }
                if(listaUbicacionesTodasBusqueda[i].idInteriorFuncional !== null){
                    listaUbicacionesTodasBusqueda[i].ubicacionesConActivos = listaUbicacionesTodasBusqueda[i].claveUbicacion + ' - ' + listaUbicacionesTodasBusqueda[i].todoUbicacion;
                } else {
                    listaUbicacionesTodasBusqueda[i].ubicacionesSinActivos = listaUbicacionesTodasBusqueda[i].claveUbicacion + ' - ' + listaUbicacionesTodasBusqueda[i].todoUbicacion;
                }
            }
            console.log(`listaUbicacionesTodasBusqueda`, listaUbicacionesTodasBusqueda)
            yield put({ type: CatalogosGeneralesActions.LISTA_UBICACIONES_TODAS_BUSQUEDA_SUCCESS, listaUbicacionesTodasBusqueda });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_UBICACIONES_TODAS_BUSQUEDA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_UBICACIONES_TODAS_BUSQUEDA_FAILURE });
    }
}
export function* listaUbicacionesTodasBusquedaSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_UBICACIONES_TODAS_BUSQUEDA_REQUEST, listaUbicacionesTodasBusqueda);
}

// ubicaciones activas
function* listaUbicacionesActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const response = yield call(listaUbicacionesActivosCall, token);
        console.log(response);

        if (response.status === 200) {
            const listaUbicacionesActivos = response.data;
            for(let i=0; i<listaUbicacionesActivos.length; i++){
                listaUbicacionesActivos[i].todoUbicacion = listaUbicacionesActivos[i].areaFuncionalByIdAreaFuncional.areaFuncional;
                if(listaUbicacionesActivos[i].unidadFuncionalByIdUnidadFuncional){
                    listaUbicacionesActivos[i].todoUbicacion += ', ' + listaUbicacionesActivos[i].unidadFuncionalByIdUnidadFuncional.unidadFuncional 
                }
                if(listaUbicacionesActivos[i].interiorFuncionalByIdInteriorFuncional){
                    listaUbicacionesActivos[i].todoUbicacion += ', ' + listaUbicacionesActivos[i].interiorFuncionalByIdInteriorFuncional.interiorFuncional;
                }
                if(listaUbicacionesActivos[i].subInteriorFuncionalByIdSubInteriorFuncional){
                    listaUbicacionesActivos[i].todoUbicacion += ', ' + listaUbicacionesActivos[i].subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional 
                }
                if(listaUbicacionesActivos[i].idInteriorFuncional !== null){
                    listaUbicacionesActivos[i].ubicacionesConActivos = listaUbicacionesActivos[i].claveUbicacion + ' - ' + listaUbicacionesActivos[i].todoUbicacion;
                } else {
                    listaUbicacionesActivos[i].ubicacionesSinActivos = listaUbicacionesActivos[i].claveUbicacion + ' - ' + listaUbicacionesActivos[i].todoUbicacion;
                }
            }
            console.log(listaUbicacionesActivos);

            yield put({ type: CatalogosGeneralesActions.LISTA_UBICACIONES_ACTIVOS_SUCCESS, listaUbicacionesActivos });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_UBICACIONES_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_UBICACIONES_ACTIVOS_FAILURE });
    }
}
export function* listaUbicacionesActivosSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_UBICACIONES_ACTIVOS_REQUEST, listaUbicacionesActivos);
}

function* listaUbicacionesFiltro(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const busqueda = action.busqueda;

        const response = yield call(listaUbicacionesFiltroCall, token, busqueda);
        console.log(response);

        if (response.status === 200) {
            const listaUbicacionesFiltro = response.data;
            for(let i=0; i<listaUbicacionesFiltro.length; i++){
                listaUbicacionesFiltro[i].todoUbicacion = listaUbicacionesFiltro[i].areaFuncionalByIdAreaFuncional.areaFuncional + ', ' + listaUbicacionesFiltro[i].unidadFuncionalByIdUnidadFuncional.unidadFuncional
                listaUbicacionesFiltro[i].todoUbicacionCOA = listaUbicacionesFiltro[i].areaFuncionalByIdAreaFuncional.codigoArea + ', ' + listaUbicacionesFiltro[i].areaFuncionalByIdAreaFuncional.areaFuncional + ', ' + listaUbicacionesFiltro[i].unidadFuncionalByIdUnidadFuncional.unidadFuncional
                if(listaUbicacionesFiltro[i].interiorFuncionalByIdInteriorFuncional){
                    listaUbicacionesFiltro[i].todoUbicacion += ', ' + listaUbicacionesFiltro[i].interiorFuncionalByIdInteriorFuncional.interiorFuncional
                    listaUbicacionesFiltro[i].todoUbicacionCOA += ', ' + listaUbicacionesFiltro[i].interiorFuncionalByIdInteriorFuncional.interiorFuncional  
                }
                if(listaUbicacionesFiltro[i].subInteriorFuncionalByIdSubInteriorFuncional){
                    listaUbicacionesFiltro[i].todoUbicacion += ', ' + listaUbicacionesFiltro[i].subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional 
                    listaUbicacionesFiltro[i].todoUbicacionCOA += ', ' + listaUbicacionesFiltro[i].subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional 
                }
            }
            console.log(listaUbicacionesFiltro);

            yield put({ type: CatalogosGeneralesActions.LISTA_UBICACIONES_FILTRO_SUCCESS, listaUbicacionesFiltro });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_UBICACIONES_FILTRO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_UBICACIONES_FILTRO_FAILURE });
    }
}
export function* listaUbicacionesFiltroSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_UBICACIONES_FILTRO_REQUEST, listaUbicacionesFiltro);
}

// ubicaciones filtros de busqueda por Areas funcionales y Unidades Funcionales
function* UbicacionesBusqueda(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let areaFuncional = '';
        if(action.formulario.areaFuncional){
            areaFuncional = 'param=' + action.formulario.areaFuncional + '&';
        }
        let unidadFuncional = '';
        if(action.formulario.unidadFuncional){
            unidadFuncional = 'unidadFuncional=' + action.formulario.unidadFuncional + '&';
        }
        let interiorFuncional = '';
        if(action.formulario.interiorFuncional){
            interiorFuncional = 'interiorFuncional=' + action.formulario.interiorFuncional + '&';
        }
        
        let response = yield call(filtroBusquedaUbicacionesCall, token, areaFuncional, unidadFuncional, interiorFuncional, page);
        console.log('page')
        console.log(page)
        console.log('response')
        console.log(response);

        if(response.status === 200){
            console.log('dentro del 200 lista')
            let listaUbicacionesBusqueda = response.data.data;
            let listaUbicacionesBusquedaCantidad = response.data.cantidad;
            console.log('listaUbicacionesBusqueda: ',listaUbicacionesBusqueda);

            for(let i = 0; i < listaUbicacionesBusqueda.length; i++){
                
                listaUbicacionesBusqueda[i].fechaAlta = listaUbicacionesBusqueda[i].fechaAlta.split('T')[0].split('-').reverse().join('-')
                
                if (listaUbicacionesBusqueda[i].areaFuncionalByIdAreaFuncional) {
                    listaUbicacionesBusqueda[i].codigoArea = listaUbicacionesBusqueda[i].areaFuncionalByIdAreaFuncional.codigoArea;
                    listaUbicacionesBusqueda[i].areaFuncional = listaUbicacionesBusqueda[i].areaFuncionalByIdAreaFuncional.areaFuncional;
                    listaUbicacionesBusqueda[i].claveArea = listaUbicacionesBusqueda[i].areaFuncionalByIdAreaFuncional.claveArea;
                } else {
                    listaUbicacionesBusqueda[i].codigoArea = 'No tiene codigo área';
                    listaUbicacionesBusqueda[i].areaFuncional = 'No tiene área funcional';
                    listaUbicacionesBusqueda[i].claveArea = 'No tiene clave área';
                }
                

                if(listaUbicacionesBusqueda[i].unidadFuncionalByIdUnidadFuncional) {
                    listaUbicacionesBusqueda[i].claveUnidad = listaUbicacionesBusqueda[i].unidadFuncionalByIdUnidadFuncional.claveUnidad;
                    listaUbicacionesBusqueda[i].unidadFuncional = listaUbicacionesBusqueda[i].unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                } else {
                    listaUbicacionesBusqueda[i].claveUnidad = 'No tiene clave unidad';
                    listaUbicacionesBusqueda[i].unidadFuncional = 'No tiene unidad funcional';
                }
                
                if(listaUbicacionesBusqueda[i].interiorFuncionalByIdInteriorFuncional) {
                    listaUbicacionesBusqueda[i].interiorFuncional = listaUbicacionesBusqueda[i].interiorFuncionalByIdInteriorFuncional.interiorFuncional;
                } else {
                    listaUbicacionesBusqueda[i].interiorFuncional = 'No tiene interior funcional';
                }
                
                if(listaUbicacionesBusqueda[i].subInteriorFuncionalByIdSubInteriorFuncional) {
                    listaUbicacionesBusqueda[i].nombreSubInteriorFuncional = listaUbicacionesBusqueda[i].subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                } else {
                    listaUbicacionesBusqueda[i].nombreSubInteriorFuncional = 'No tiene subinterior funcional';
                }
                
            }
            yield put({ type: CatalogosGeneralesActions.LISTA_UBICACIONES_BUSQUEDA_SUCCESS, listaUbicacionesBusqueda, listaUbicacionesBusquedaCantidad});
            //yield put({ type: CatalogosGeneralesActions.LISTA_UBICACIONES_ACTIVOS_REQUEST })
        } else {
            yield put({ type: CatalogosGeneralesActions.LISTA_UBICACIONES_BUSQUEDA_FAILURE });
        }
    } catch (error) {
        yield put({ type: CatalogosGeneralesActions.LISTA_UBICACIONES_BUSQUEDA_FAILURE });
    }
}
export function* UbicacionesBusquedaSaga(){
    yield takeLatest(CatalogosGeneralesActions.LISTA_UBICACIONES_BUSQUEDA_REQUEST, UbicacionesBusqueda);
}

// cambiar estado ubicacion
function* cambiarEstadoUbicaciones(action){
    try {
        //console.log(action);
        const token = sessionStorage.getItem('token');
        const idUbicacion = action.idUbicacion;

        const response = yield call(cambiarEstadoUbicacionCall, token, idUbicacion); 
        console.log('response');
        console.log(response);

        if(response.status === 200) {
            const page = action.page;
            const formulario = {
                areaFuncional: '',
                unidadFuncional: '',
                interiorFuncional: '',
            }
            const showSuccessMsg = true;
            const textMessage = "El estado de la ubicación cambió de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.ESTADO_UBICACIONES_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.LISTA_UBICACIONES_BUSQUEDA_REQUEST, page, formulario });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.ESTADO_UBICACIONES_FAILURE });
        }
    } catch (error) {
        yield put({ type: CatalogosGeneralesActions.ESTADO_UBICACIONES_FAILURE });
    }
}

export function* cambiarEstadoUbicacionesSaga(){
    yield takeLatest(CatalogosGeneralesActions.ESTADO_UBICACIONES_REQUEST, cambiarEstadoUbicaciones)
}

// nueva Ubicacion
function* crearUbicacion(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let subInteriorFuncional = null;
        if (action.formulario.idSubInteriorFuncional !== undefined){
            subInteriorFuncional = parseInt(action.formulario.idSubInteriorFuncional);
        } 
        const data = {
            "idUbicacion": null,
            "claveUbicacion": null,
            "idAreaFuncional": parseInt(action.formulario.idAreaFuncional),
            "idUnidadFuncional": parseInt(action.formulario.idUnidadFuncional),
            "idInteriorFuncional": parseInt(action.formulario.idInteriorFuncional),
            "idSubInteriorFuncional": subInteriorFuncional,
            "idPonderacionArea": null,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        };

        console.log(data);
        const response = yield call(crearUbicacionCall, token, data);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            console.log('page')
            console.log(page)
            const formulario = {
                areaFuncional: '',
                unidadFuncional: '',
                interiorFuncional: '',
            }
            const showModalNuevoUbicaciones = false;
            const showSuccessMsg = true;
            const textMessage = "La ubicación se creó de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.NUEVO_UBICACIONES_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.LISTA_UBICACIONES_ACTIVOS_REQUEST });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearUbicacion'});
            yield put({ type: CatalogosGeneralesActions.LISTA_UBICACIONES_BUSQUEDA_REQUEST,  page, formulario});
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_NUEVO_UBICACION, showModalNuevoUbicaciones });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: CatalogosGeneralesActions.NUEVO_UBICACIONES_FAILURE });
        }
    } catch (error) {
        yield put({ type: CatalogosGeneralesActions.NUEVO_UBICACIONES_FAILURE });
    }
}

export function* crearUbicacionSaga(){
    yield takeLatest(CatalogosGeneralesActions.NUEVO_UBICACIONES_REQUEST, crearUbicacion);
}

// editar Ubicacion
function* editarUbicacion(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let ubicacionSeleccionado = action.ubicacionSeleccionado;
        const idUbicacion = action.ubicacionSeleccionado.idUbicacion;
        let idAreaFuncional = action.formulario.areaFuncional;
        let idUnidadFuncional = action.formulario.unidadFuncional;
        let idInteriorFuncional = action.formulario.interiorFuncional;
        let idSubInteriorFuncional = action.formulario.nombreSubInteriorFuncional;


        if(isNaN(idAreaFuncional)) {
            idAreaFuncional = action.ubicacionSeleccionado.idAreaFuncional
        }
        if(isNaN(idUnidadFuncional)) {
            idUnidadFuncional = action.ubicacionSeleccionado.idUnidadFuncional
        }
        if(isNaN(idInteriorFuncional)) {
            idInteriorFuncional = action.ubicacionSeleccionado.idInteriorFuncional
        }
        if( idSubInteriorFuncional === undefined ) {
            idSubInteriorFuncional = null
        } else if(isNaN(idSubInteriorFuncional)){
            idSubInteriorFuncional = action.ubicacionSeleccionado.idSubInteriorFuncional
        }

            
           const data = {
                "idUbicacion": idUbicacion,
                "claveUbicacion": action.ubicacionSeleccionado.claveUbicacion,
                "idAreaFuncional": idAreaFuncional,
                "idUnidadFuncional": idUnidadFuncional ,
                "idInteriorFuncional": idInteriorFuncional,
                "idSubInteriorFuncional": idSubInteriorFuncional,
                "idPonderacionArea": action.ubicacionSeleccionado.idPonderacionArea,
                "fechaAlta": action.ubicacionSeleccionado.fechaAlta.split('-').reverse().join('-'),
                "fechaActualizacion": null,
                "activo": action.ubicacionSeleccionado.activo 
            }
       

        console.log('data')
        console.log(data)
        const response = yield call(editarUbicacionCall, token ,idUbicacion ,data)
        console.log(response);

        if(response.status === 200){
            
            const page = action.page;
            const formulario = {
                areaFuncional: '',
                unidadFuncional: '',
                interiorFuncional: '',
            };
            const showModalEditarUbicaciones= false;
            ubicacionSeleccionado = null;
            const showSuccessMsg = true;
            const textMessage = "La ubicación se modificó de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarUbicacion'});
            yield put({ type: CatalogosGeneralesActions.EDITAR_UBICACIONES_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.LISTA_AREASFUNCIONALES_ACTIVOS_REQUEST });
            yield put({ type: CatalogosGeneralesActions.LISTA_UBICACIONES_BUSQUEDA_REQUEST, page, formulario });
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_EDITAR_UBICACION, showModalEditarUbicaciones, ubicacionSeleccionado });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.EDITAR_UBICACIONES_FAILURE });
        }

    } catch (error) {
        yield put({ type: CatalogosGeneralesActions.EDITAR_UBICACIONES_FAILURE });
    }
}
export function* editarUbicacionSaga(){
    yield takeLatest(CatalogosGeneralesActions.EDITAR_UBICACIONES_REQUEST, editarUbicacion);
}

function* exportarUbicacionesPDF(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let areaFuncional = '';
        if(action.formulario.areaFuncional){
            areaFuncional = 'param=' + action.formulario.areaFuncional + '&';
        }
        let unidadFuncional = '';
        if(action.formulario.unidadFuncional){
            unidadFuncional = 'unidadFuncional=' + action.formulario.unidadFuncional + '&';
        }
        let interiorFuncional = '';
        if(action.formulario.interiorFuncional){
            interiorFuncional = 'interiorFuncional=' + action.formulario.interiorFuncional + '&';
        }
        
        const response = yield call(exportarUbicacionesPDFCall, token, areaFuncional, unidadFuncional, interiorFuncional)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se descargó el archivo de forma exitosa";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Ubicaciones " + new Date().getDate() + "-" + + new Date().getMonth()  + "_" + + new Date().getFullYear() + "-" + new Date().getHours() + "_" + new Date().getMinutes() + "_" + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.EXPORTAR_UBICACIONES_PDF_SUCCESS });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.EXPORTAR_UBICACIONES_PDF_FAILURE })
        }
    } catch (error) {
        yield put({ type: CatalogosGeneralesActions.EXPORTAR_UBICACIONES_PDF_FAILURE})
    }
}
export function* exportarUbicacionesPDFSaga(){
    yield takeLatest(CatalogosGeneralesActions.EXPORTAR_UBICACIONES_PDF_REQUEST, exportarUbicacionesPDF)
}

function* exportarUbicacionesExcel(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let areaFuncional = '';
        if(action.formulario.areaFuncional){
            areaFuncional = 'param=' + action.formulario.areaFuncional + '&';
        }
        let unidadFuncional = '';
        if(action.formulario.unidadFuncional){
            unidadFuncional = 'unidadFuncional=' + action.formulario.unidadFuncional + '&';
        }
        let interiorFuncional = '';
        if(action.formulario.interiorFuncional){
            interiorFuncional = 'interiorFuncional=' + action.formulario.interiorFuncional + '&';
        }
        
        const response = yield call(exportarUbicacionesExcelCall, token, areaFuncional, unidadFuncional, interiorFuncional)
        console.log('response');
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se descargó el archivo de forma exitosa";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Ubicaciones " + new Date().getDate() + "-" + + new Date().getMonth()  + "_" + + new Date().getFullYear() + "-" + new Date().getHours() + "_" + new Date().getMinutes() + "_" + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.EXPORTAR_UBICACIONES_EXCEL_SUCCESS });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.EXPORTAR_UBICACIONES_EXCEL_FAILURE })
        }
    } catch (error) {
        yield put({ type: CatalogosGeneralesActions.EXPORTAR_UBICACIONES_EXCEL_FAILURE})
    }
}
export function* exportarUbicacionesExcelSaga(){
    yield takeLatest(CatalogosGeneralesActions.EXPORTAR_UBICACIONES_EXCEL_REQUEST, exportarUbicacionesExcel)
}

// *********** CATALOGO PONDERACIONES *********** //
// Ponderaciones activas
function* listaPonderacionesActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaPonderacionesActivosCall, token);
        console.log(response);

        if (response.status === 200) {
            const listaPonderacionesActivas = response.data;
            let listaPonderacionesAreas = {};
            if(action.formulario){
                listaPonderacionesAreas = {'good': true};
                for(let i=0; i<listaPonderacionesActivas.length; i++){
                    listaPonderacionesActivas[i].pesoArea = listaPonderacionesActivas[i].pesoArea.toFixed(4).toString();
                    if(action.ponderacionSeleccionado.idPonderacionArea === listaPonderacionesActivas[i].idPonderacionArea){
                        listaPonderacionesAreas['pesoArea-' + listaPonderacionesActivas[i].idPonderacionArea] = action.formulario.pesoArea;
                    }
                    else{
                        listaPonderacionesAreas['pesoArea-' + listaPonderacionesActivas[i].idPonderacionArea] = listaPonderacionesActivas[i].pesoArea;
                    }
                }
            }
            
            yield put({ type: CatalogosGeneralesActions.LISTA_PONDERACIONES_ACTIVAS_SUCCESS, listaPonderacionesActivas, listaPonderacionesAreas });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_PONDERACIONES_ACTIVAS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_PONDERACIONES_ACTIVAS_FAILURE });
    }
}
export function* listaPonderacionesActivosSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_PONDERACIONES_ACTIVAS_REQUEST, listaPonderacionesActivos);
}

function* listaPonderacionesBusqueda(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        let busqueda = '';
        if(action.busqueda){
            busqueda = 'param=' + action.busqueda + '&';
        }
        let response = yield call(listaPonderacionesBusquedaCall, token, busqueda, page);
        console.log(response);

        if(response.status === 200){
            let listaPonderacionesBusqueda = response.data.data;
            let listaPonderacionesBusquedaCantidad = response.data.cantidad;
            for(let i=0; i<listaPonderacionesBusqueda.length; i++){
                listaPonderacionesBusqueda[i].rangoVigencia = [moment(listaPonderacionesBusqueda[i].fechaInicioVigencia, 'YYYY-MM-DD'),moment(listaPonderacionesBusqueda[i].fechaFinVigencia, 'YYYY-MM-DD')]
                listaPonderacionesBusqueda[i].pesoArea = listaPonderacionesBusqueda[i].pesoArea.toFixed(4);
                listaPonderacionesBusqueda[i].pesoAreaTabla = listaPonderacionesBusqueda[i].pesoArea.toString() + '%';
                if(listaPonderacionesBusqueda[i].areaFuncionalByIdAreaFuncional){
                    listaPonderacionesBusqueda[i].areaFuncional = listaPonderacionesBusqueda[i].areaFuncionalByIdAreaFuncional.areaFuncional;
                    listaPonderacionesBusqueda[i].codigoArea = listaPonderacionesBusqueda[i].areaFuncionalByIdAreaFuncional.codigoArea;
                    listaPonderacionesBusqueda[i].claveArea = listaPonderacionesBusqueda[i].areaFuncionalByIdAreaFuncional.claveArea;
                }
                else{
                    listaPonderacionesBusqueda[i].areaFuncional = 'No tiene área funcional';
                    listaPonderacionesBusqueda[i].codigoArea = 'No tiene código área funcional';
                    listaPonderacionesBusqueda[i].claveArea = 'No tiene clave área funcional';
                }
                if(listaPonderacionesBusqueda[i].fallaCategoriaByIdFallaCategoria){
                    listaPonderacionesBusqueda[i].fallaCategoria = listaPonderacionesBusqueda[i].fallaCategoriaByIdFallaCategoria.numeroFallaCategoria + ' - ' + listaPonderacionesBusqueda[i].fallaCategoriaByIdFallaCategoria.fallaCategoria + ' ' + listaPonderacionesBusqueda[i].fallaCategoriaByIdFallaCategoria.unidadMedidaByIdUnidadMedida.unidadMedida;
                }
                else{
                    listaPonderacionesBusqueda[i].fallaCategoria = 'No tiene tiempo de rehabilitación';
                }
            }

            yield put({ type: CatalogosGeneralesActions.LISTA_PONDERACIONES_BUSQUEDA_SUCCESS, listaPonderacionesBusqueda, listaPonderacionesBusquedaCantidad});
        } else {
            yield put({ type: CatalogosGeneralesActions.LISTA_PONDERACIONES_BUSQUEDA_FAILURE });
        }
    } catch (error) {
        yield put({ type: CatalogosGeneralesActions.LISTA_PONDERACIONES_BUSQUEDA_FAILURE });
    }
}
export function* listaPonderacionesBusquedaSaga(){
    yield takeLatest(CatalogosGeneralesActions.LISTA_PONDERACIONES_BUSQUEDA_REQUEST, listaPonderacionesBusqueda);
}

// nueva ponderación
function* crearPonderacion(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idPonderacionArea":null,
            "idAreaFuncional": parseInt(action.formulario.idAreaFuncional),
            "pesoArea": action.formulario.pesoArea,
            "idFallaCategoria": parseInt(action.formulario.idFallaCategoria),
            "turnos": action.formulario.turnos,
            "factor1": action.formulario.factor1,
            "factor1Activo": action.formulario.bandera1,
            "factor2": action.formulario.factor2,
            "factor2Activo": action.formulario.bandera2,
            "factor3": action.formulario.factor3,
            "factor3Activo": action.formulario.bandera3,
            "cantidad": action.formulario.cantidad,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "fechaInicioVigencia": action.formulario.rangoVigencia.split(',')[0],
            "fechaFinVigencia": action.formulario.rangoVigencia.split(',')[1],
            "rutaArchivo": "",
            "activo": true
        };

        console.log(data);
        const response = yield call(crearPonderacionCall, token, data);
        console.log(response);

        if(response.status==200){
            const textMessage = "La ponderación de área funcional se creó de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage });
            yield put({ type: CatalogosGeneralesActions.NUEVO_PONDERACION_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.LISTA_PONDERACIONES_ACTIVAS_REQUEST });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearPonderacion'});
            yield put({ type: CatalogosGeneralesActions.LISTA_PONDERACIONES_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda});
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_NUEVO_PONDERACION, showModalNuevoPonderacion: false });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.NUEVO_PONDERACION_FAILURE });
        }
    } catch (error) {
        yield put({ type: CatalogosGeneralesActions.NUEVO_PONDERACION_FAILURE });
    }
}

export function* crearPonderacionSaga(){
    yield takeLatest(CatalogosGeneralesActions.NUEVO_PONDERACION_REQUEST, crearPonderacion);
}

// editar Ponderación
function* editarPonderacion(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idPonderacionArea = action.ponderacionSeleccionado.idPonderacionArea;
        let idAreaFuncional = action.formulario.areaFuncional;
        let idFallaCategoria = action.formulario.fallaCategoria;
        let limpiarFormulario = 'editarPonderacion';
        let list = []; 
        if(isNaN(idAreaFuncional)){
            idAreaFuncional = action.ponderacionSeleccionado.idAreaFuncional;
        }
        if(isNaN(idFallaCategoria)){
            idFallaCategoria = action.ponderacionSeleccionado.idFallaCategoria;
        }
        if(action.ponderacionEditados){
            console.log('entró  a ponderacionEditados');
            for(let i=0; i<action.listaPonderacionesBusqueda.length; i++){
                for(let x=0; x<action.ponderacionEditados.length; x++){
                    if(action.ponderacionEditados[x].idPonderacionArea === action.listaPonderacionesBusqueda[i].idPonderacionArea){
                        const ponderacionAgregar = action.listaPonderacionesBusqueda[i];
                        ponderacionAgregar.pesoArea = action.ponderacionEditados[x].pesoArea;
                        list.push(ponderacionAgregar);
                    }
                }
            }
        }
        console.log('list');
        console.log(list);
            
        const data = {
            "idPonderacionArea": idPonderacionArea,
            "idAreaFuncional": idAreaFuncional,
            "pesoArea": action.formulario.pesoArea,
            "idFallaCategoria": idFallaCategoria,
            "turnos": action.formulario.turnos,
            "factor1": action.formulario.factor1,
            "factor1Activo": action.formulario.factor1Activo,
            "factor2": action.formulario.factor2,
            "factor2Activo": action.formulario.factor2Activo,
            "factor3": action.formulario.factor3,
            "factor3Activo": action.formulario.factor3Activo,
            "cantidad": action.formulario.cantidad,
            "fechaAlta": action.ponderacionSeleccionado.fechaAlta,
            "fechaActualizacion": action.ponderacionSeleccionado.fechaActualizacion,
            "fechaInicioVigencia": action.formulario.rangoVigencia.split(',')[0],
            "fechaFinVigencia": action.formulario.rangoVigencia.split(',')[1],
            "rutaArchivo": action.ponderacionSeleccionado.rutaArchivo,
            "activo": action.ponderacionSeleccionado.activo
        };
       
        console.log('data')
        console.log(data);
        const formData = new FormData();
        if(action.formularioPDF){
            limpiarFormulario = 'editarPonderacionPDF';
            formData.append('file', action.formularioPDF.pdf.file.originFileObj);
            formData.append('list', new Blob([JSON.stringify(list)], {
                type: "application/json"
            }));
        }
        
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        const response = yield call(editarPonderacionCall, token, idPonderacionArea, formData)
        console.log(response);

        if(response.status === 200){
            const textMessage = "La ponderación área funcional se modificó de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario});
            yield put({ type: CatalogosGeneralesActions.EDITAR_PONDERACION_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.CAMBIAR_VISTA_PONDERACION, vistaModalPonderacion: 1, formulario: null, ponderacionEditados: null });
            yield put({ type: CatalogosGeneralesActions.LISTA_PONDERACIONES_ACTIVAS_REQUEST });
            yield put({ type: CatalogosGeneralesActions.LISTA_PONDERACIONES_BUSQUEDA_REQUEST, page: action.page, busqueda: action.busqueda });
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_EDITAR_PONDERACION, showModalEditarPonderacion: false, ponderacionSeleccionado: null });

        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.EDITAR_PONDERACION_FAILURE });
        }

    } catch (error) {
        yield put({ type: CatalogosGeneralesActions.EDITAR_PONDERACION_FAILURE });
    }
}
export function* editarPonderacionSaga(){
    yield takeLatest(CatalogosGeneralesActions.EDITAR_PONDERACION_REQUEST, editarPonderacion);
}

//Tipo de Moneda
function* listaTipoMoneda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data =
        {
            "nombre": action.busqueda ? action.busqueda : null,
            "nomenclatura": action.nomenclatura ? action.nomenclatura : null,
            "page": action.page -1,
            "offset":10,
            "field":"idTipoMoneda",
            "order":"ASC"
        }

        const response = yield call(listaTipoMonedaCall, token, data);

        console.log(response);

        if (response.status == 200) {
            let listTipoMoneda = response.data.data;
            let listTipoMonedaCount = response.data.cantidad;

            yield put({ type: CatalogosGeneralesActions.LISTA_TIPOS_MONEDAS_SUCCESS, listTipoMoneda, listTipoMonedaCount });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_TIPOS_MONEDAS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_TIPOS_MONEDAS_FAILURE });
    }
}
export function* listaTipoMonedaSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_TIPOS_MONEDAS_REQUEST, listaTipoMoneda);
}

function* estadoTipoMoneda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idTipoMoneda = action.idTipoMoneda;

        const response = yield call(estadoTipoMonedaCall, token, idTipoMoneda);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const busqueda = action.busqueda;
            const showSuccessMsg = true;
            const textMessage = "El estado del tipo de moneda cambió de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.ESTADO_TIPO_MONEDA_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.LISTA_TIPOS_MONEDAS_REQUEST, page, busqueda });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: CatalogosGeneralesActions.ESTADO_TIPO_MONEDA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.ESTADO_TIPO_MONEDA_FAILURE });
    }
}
export function* estadoTipoMonedaSaga() {
    yield takeLatest(CatalogosGeneralesActions.ESTADO_TIPO_MONEDA_REQUEST, estadoTipoMoneda);
}

function* crearTipoMoneda(action){
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idTipoMoneda":null,
            "nombre": action.formulario.nombre,
            "nomenclatura": action.formulario.nomenclatura.toUpperCase(),
            "activo": true
        };

        console.log(data);
        const response = yield call(crearTipoMonedaCall, token, data);
        console.log(response);

        if(response.status==200){
            const page = action.page;
            const busqueda = action.busqueda;
            const showModalNuevoTipoMoneda = false;
            const showSuccessMsg = true;
            const textMessage = "El tipo de moneda se creó de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.NUEVO_TIPO_MONEDA_SUCCESS });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearTipoMoneda'});
            yield put({ type: CatalogosGeneralesActions.LISTA_TIPOS_MONEDAS_REQUEST, page, busqueda});
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_NUEVO_TIPO_MONEDA, showModalNuevoTipoMoneda });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.NUEVO_TIPO_MONEDA_FAILURE });
        }
    } catch (error) {
        yield put({ type: CatalogosGeneralesActions.NUEVO_TIPO_MONEDA_FAILURE });
    }
}

export function* crearTipoMonedaSaga(){
    yield takeLatest(CatalogosGeneralesActions.NUEVO_TIPO_MONEDA_REQUEST, crearTipoMoneda);
}

function* editarTipoMoneda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idTipoMoneda = action.tipoMonedaSeleccionado.idTipoMoneda;

        const data = {
            "idTipoMoneda":idTipoMoneda,
            "nombre": action.formulario.nombre,
            "nomenclatura": action.formulario.nomenclatura.toUpperCase(),
            "activo": action.tipoMonedaSeleccionado.activo
        }
        console.log(data);
        const response = yield call(editarTipoMonedaCall, token, data, idTipoMoneda);
        console.log(response);

        if(response.status === 200){
            const page = action.page;
            const busqueda = action.busqueda;
            const showModalEditarTipoMoneda= false;
            const tipoMonedaSeleccionado = null;
            const showSuccessMsg = true;
            const textMessage = "El tipo de moneda: " + action.formulario.nombre + " se modificó de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarTipoMoneda'});
            yield put({ type: CatalogosGeneralesActions.EDITAR_TIPO_MONEDA_SUCCESS });
            yield put({ type: CatalogosGeneralesActions.LISTA_TIPOS_MONEDAS_REQUEST, page, busqueda });
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_EDITAR_TIPO_MONEDA, showModalEditarTipoMoneda, tipoMonedaSeleccionado });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: CatalogosGeneralesActions.EDITAR_TIPO_MONEDA_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.EDITAR_TIPO_MONEDA_FAILURE });
    }
}
export function* editarTipoMonedaSaga() {
    yield takeLatest(CatalogosGeneralesActions.EDITAR_TIPO_MONEDA_REQUEST, editarTipoMoneda);
}

function* listaTiposMonedasActivas(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaTiposMonedasActivosCall, token);
        console.log(response);

        if(response.status==200){
            const listaTipoMonedasActivas = response.data;
            
            yield put({ type: CatalogosGeneralesActions.LISTA_TIPOS_MONEDAS_ACTIVOS_SUCCESS, listaTipoMonedasActivas });
        }
        else{
            yield put({ type: CatalogosGeneralesActions.LISTA_TIPOS_MONEDAS_ACTIVOS_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_TIPOS_MONEDAS_ACTIVOS_FAILURE });
    }
}
export function* listaTiposMonedasActivasSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_TIPOS_MONEDAS_ACTIVOS_REQUEST, listaTiposMonedasActivas);
}


//Tipo de Cambio
function* listaTiposCambio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idsTiposMonedas = [];

        for(let i in action.busqueda.idsTiposMonedas){
            idsTiposMonedas.push(parseInt(action.busqueda.idsTiposMonedas[i]));
        }
        const data = {
            "idsTiposCambios": null,
            "idsTiposMonedas": idsTiposMonedas,
            "tiposCambio": null,
            "fechaInicio": action.busqueda.fechaInicio,
            "fechaFin": action.busqueda.fechaFin,
            "page": (action.page - 1) * 10,
            "offset": 10,
            "order": action.orden.order,
            "field": action.orden.field
        }
        const response = yield call(listaTipoCambioCall, token, data);
        console.log(response);

        if (response.status == 200) {
            let listaTipoCambio = response.data.data;
            let listaTipoCambioCantidad = response.data.cantidad;

            for(let i in listaTipoCambio){
                listaTipoCambio[i].tipoMoneda = listaTipoCambio[i].tipoMonedaByIdTipoMoneda[0].nombre;
                listaTipoCambio[i].nomenclatura = listaTipoCambio[i].tipoMonedaByIdTipoMoneda[0].nomenclatura;
                listaTipoCambio[i].tipoCambioTabla = '$ ' + listaTipoCambio[i].tipoCambio.toFixed(2);
                listaTipoCambio[i].fecha =  listaTipoCambio[i].fecha.split('-').reverse().join('-');
            }

            yield put({ type: CatalogosGeneralesActions.LISTA_TIPO_CAMBIO_SUCCESS, listaTipoCambio, listaTipoCambioCantidad });
        }
        else {
            yield put({ type: CatalogosGeneralesActions.LISTA_TIPO_CAMBIO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.LISTA_TIPO_CAMBIO_FAILURE });
    }
}
export function* listaTiposCambioSaga() {
    yield takeLatest(CatalogosGeneralesActions.LISTA_TIPO_CAMBIO_REQUEST, listaTiposCambio);
}

function* crearTiposCambio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idTipoCambio": null,
            "idTipoMoneda": parseInt(action.formulario.idTipoMoneda.split(' - ')[0]),
            "tipoCambio": action.formulario.tipoCambio,
            "fecha": null
        }
        console.log('data', data);
        const response = yield call(nuevoTipoCambioCall, token, data);

        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const busqueda = action.busqueda;
            const orden = action.orden ? action.orden : {order: 'DESC', field: 'fecha'};
            const showModalNuevoTipoCambio = false;
            const showSuccessMsg = true;
            const textMessage = "El tipo de cambio se creó de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.NUEVO_TIPO_CAMBIO_SUCCESS });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'crearTipoCambio'});
            yield put({ type: CatalogosGeneralesActions.LISTA_TIPO_CAMBIO_REQUEST, page, busqueda, orden});
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_NUEVO_TIPO_CAMBIO, showModalNuevoTipoCambio });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.NUEVO_TIPO_CAMBIO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.NUEVO_TIPO_CAMBIO_FAILURE });
    }
}
export function* crearTiposCambioSaga() {
    yield takeLatest(CatalogosGeneralesActions.NUEVO_TIPO_CAMBIO_REQUEST, crearTiposCambio);
}

function* editarTipoCambio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idTipoCambio = action.tipoCambioSeleccionado.idTipoCambio;
        const data = {
            "idTipoCambio":idTipoCambio,
            "idTipoMoneda": parseInt(action.formulario.idTipoMonedaEditar.split(' - ')[0]),
            "tipoCambio": action.formulario.tipoCambio,
            "fecha": action.tipoCambioSeleccionado.fecha.split('-').reverse().join('-'),
            "fechaActualizacion":null
        }
        console.log(data);
        const response = yield call(editarTipoCambioCall, token, data, idTipoCambio);
        console.log(response);

        if(response.status === 200){
            const page = action.page;
            const busqueda = action.busqueda;
            const orden = action.orden ? action.orden : {order: 'DESC', field: 'fecha'};
            const textMessage = "El tipo de cambio del: " + action.tipoCambioSeleccionado.fecha + " para la moneda: " + action.formulario.idTipoMonedaEditar.split(' - ')[1] + " se modificó de forma correcta";

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg: true, textMessage });
            yield put({ type: CatalogosGeneralesActions.EDITAR_TIPO_CAMBIO_SUCCESS });
            yield put({ type: ConfiguracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarTipoCambio'});
            yield put({ type: CatalogosGeneralesActions.LISTA_TIPO_CAMBIO_REQUEST, page, busqueda, orden});
            yield put({ type: CatalogosGeneralesActions.SHOW_MODAL_EDITAR_TIPO_CAMBIO, showModalEditarTipoCambio: false, tipoCambioSeleccionado: null });
        }
        else{
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.EDITAR_TIPO_CAMBIO_FAILURE });
        }
    
    } catch (error) {
        console.log(error);
        yield put({ type: CatalogosGeneralesActions.EDITAR_TIPO_CAMBIO_FAILURE });
    }
}
export function* editarTipoCambioSaga() {
    yield takeLatest(CatalogosGeneralesActions.EDITAR_TIPO_CAMBIO_REQUEST, editarTipoCambio);
}

function* exportarTipoMonedaExcel(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        
        const response = yield call(exportarTipoMonedaExcelCall, token);
        console.log(response)

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se descargó el archivo de forma exitosa";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "TipoMoneda " + new Date().getDate() + "-" + + new Date().getMonth()  + "_" + + new Date().getFullYear() + "-" + new Date().getHours() + "_" + new Date().getMinutes() + "_" + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.EXPORTAR_EXCEL_TIPO_MONEDA_SUCCESS });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.EXPORTAR_EXCEL_TIPO_MONEDA_FAILURE })
        }
    } catch (error) {
        yield put({ type: CatalogosGeneralesActions.EXPORTAR_EXCEL_TIPO_MONEDA_FAILURE})
    }
}
export function* exportarTipoMonedaExcelSaga(){
    yield takeLatest(CatalogosGeneralesActions.EXPORTAR_EXCEL_TIPO_MONEDA_REQUEST, exportarTipoMonedaExcel)
}


function* exportarTipoCambioExcel(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idsTiposMonedas = [];

        for(let i in action.busqueda.idsTiposMonedas){
            idsTiposMonedas.push(parseInt(action.busqueda.idsTiposMonedas[i]));
        }

        const data = {
            "idsTiposCambios": null,
            "idsTiposMonedas": idsTiposMonedas,
            "tiposCambio": null,
            "fechaInicio": action.busqueda.fechaInicio,
            "fechaFin": action.busqueda.fechaFin,
            "page": (action.page - 1) * 10,
            "offset": 10,
            "order": action.orden.order,
            "field": action.orden.field
        };

        console.log('data', data)
        
        const response = yield call(exportarTipoCambioExcelCall, token, data);
        console.log(response)

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se descargó el archivo de forma exitosa";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "TipoCambio " + new Date().getDate() + "-" + + new Date().getMonth()  + "_" + + new Date().getFullYear() + "-" + new Date().getHours() + "_" + new Date().getMinutes() + "_" + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.EXPORTAR_EXCEL_TIPO_CAMBIO_SUCCESS });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.EXPORTAR_EXCEL_TIPO_CAMBIO_REQUEST })
        }
    } catch (error) {
        yield put({ type: CatalogosGeneralesActions.EXPORTAR_EXCEL_TIPO_CAMBIO_FAILURE})
    }
}
export function* exportarTipoCambioExcelSaga(){
    yield takeLatest(CatalogosGeneralesActions.EXPORTAR_EXCEL_TIPO_CAMBIO_REQUEST, exportarTipoCambioExcel)
}

function* exportExcelTiemposRehabilitacion(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let busqueda = '';
        if (action.busqueda) {
            busqueda = action.busqueda;
        }
        
        const response = yield call(exportExcelTiemposRehabilitacionCall, token, page, busqueda);
        console.log(response)

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se descargó el archivo de forma exitosa";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "TiemposRehabilitacion " + new Date().getDate() + "-" + + new Date().getMonth()  + "_" + + new Date().getFullYear() + "-" + new Date().getHours() + "_" + new Date().getMinutes() + "_" + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: ConfiguracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.EXPORTAR_EXCEL_TIEMPOS_REHABILITACION_SUCCESS });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: ConfiguracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: CatalogosGeneralesActions.EXPORTAR_EXCEL_TIEMPOS_REHABILITACION_FAILURE })
        }
    } catch (error) {
        yield put({ type: CatalogosGeneralesActions.EXPORTAR_EXCEL_TIEMPOS_REHABILITACION_FAILURE})
    }
}
export function* exportExcelTiemposRehabilitacionSaga(){
    yield takeLatest(CatalogosGeneralesActions.EXPORTAR_EXCEL_TIEMPOS_REHABILITACION_REQUEST, exportExcelTiemposRehabilitacion)
}