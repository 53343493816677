import axios from "axios";

//const endpoint = "https://papantla-services.herokuapp.com/";
//const endpoint = "https://papantla-dev.herokuapp.com/";
const endpoint = 'https://morelos-dev.herokuapp.com/';
//const endpoint = "https://papantla-demo.herokuapp.com/";

//const endpoint = "http://172.24.0.170:8080/"

//const endpoint = 'https://papantla-services-jose.herokuapp.com/';
//const endpoint = 'https://papantla-dev-jose.herokuapp.com/';

//const endpoint = 'https://papantla-secret-jose.herokuapp.com/';
//const endpoint = 'https://papantla-secret.herokuapp.com/';

//Servicios de Catálogo de Dominios
export function listaDominiosBusquedaCall(token, page, busqueda) {
	return axios({
	    method: "get",
	    url: endpoint + "dominios?" + busqueda + "page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function estadoDominioCall(token, idDominio) {
	return axios({
	    method: "get",
	    url: endpoint + "dominios/activa/" + idDominio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function crearDominioCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "dominios/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function editarDominioCall(token, data, idDominio) {
	return axios({
	    method: "put",
	    url: endpoint + "dominios/" + idDominio,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Servicios de Catálogo de Expedientes
export function listaExpedientesCall(token) {
	return axios({
	    method: "get",
	    url: endpoint + "expedientes/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function listaExpedientesBusquedaCall(token, page, busqueda) {
	return axios({
	    method: "get",
	    url: endpoint + "expedientes?" + busqueda + "page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function listaExpedientesSinInfoCall(token, page) {
	return axios({
	    method: "get",
	    url: endpoint + "expedientes?check=true&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function estadoExpedientesCall(token, idExpediente) {
	return axios({
	    method: "get",
	    url: endpoint + "expedientes/activa/" + idExpediente,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function verificarExpedientesCall(token, nombreCarpeta) {
	return axios({
	    method: "get",
	    url: endpoint + "expedientes/verificar?folder=" + nombreCarpeta,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function archivosExpedienteCall(token, nombreCarpeta) {
	return axios({
	    method: "get",
	    url: endpoint + "expedientes/ver/archivos?folder=" + nombreCarpeta,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function eliminarArchivoExpedienteCall(token, pathArchivo) {
	return axios({
	    method: "delete",
	    url: pathArchivo,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function crearExpedienteCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "expedientes",
		headers: {'Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function editarExpedienteCall(token, data, idExpediente) {
	return axios({
	    method: "put",
	    url: endpoint + "expedientes/" + idExpediente,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Servicios de Catálogo de Contratos
export function listaContratosCall(token) {
	return axios({
	    method: "get",
	    url: endpoint + "contratos/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaContratosActivosIdServicioCall(token, idServicio) {
	return axios({
	    method: "get",
	    url: endpoint + "contratos/activos/partidas/by?idServicio=" + idServicio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaContratosBusquedaCall(token, page, proveedor, servicio, fechaFin) {
	return axios({
	    method: "get",
	    url: endpoint + "contratos?" + proveedor + servicio + fechaFin + "page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function listaContratosSinInfoCall(token, page) {
	return axios({
	    method: "get",
	    url: endpoint + "contratos?check=true&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function listaContratosPorServicioCall(token, idServicio) {
	return axios({
	    method: "get",
	    url: endpoint + "contratos/activos/partidas?idServicio=" + idServicio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function estadoContratoCall(token, idContrato) {
	return axios({
	    method: "get",
	    url: endpoint + "contratos/activa/" + idContrato,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function crearContratoCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "contratos/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function editarContratoCall(token, data, idContrato) {
	return axios({
	    method: "put",
	    url: endpoint + "contratos/" + idContrato,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Servicios de proveedores
export function listaProveedoresCall(token) {
	return axios({
	    method: "get",
	    url: endpoint + "proveedores/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaBusquedaProveedoresCall(token, busqueda) {
	return axios({
	    method: "get",
	    url: endpoint + "proveedores/activos/search?param=" + busqueda,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function proveedoresBusquedaCall(token, page, busqueda, fechaFin) {
	return axios({
	    method: "get",
	    url: endpoint + "proveedores?" + busqueda + fechaFin + "page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function estadoProveedorCall(token, idProveedor) {
	return axios({
	    method: "get",
	    url: endpoint + "proveedores/activa/" + idProveedor,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function crearProveedorCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "proveedores/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function editarProveedorCall(token, data, idProveedor) {
	return axios({
	    method: "put",
	    url: endpoint + "proveedores/" + idProveedor,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaPartidasByServicioCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "partidas/activas/servicios",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaPartidasByServiciosCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "partidas/reporte/evaluacion",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaServiciosActivosForProveedorCall(token, idProveedor) {
	return axios({
	    method: "get",
	    url: endpoint + "servicios/activos/proveedores" + idProveedor,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaAreasProveedoresCall(token, idServicio, idProveedor, data) {
	return axios({
	    method: "post",
	    url: endpoint + "areas/proveedores?" + idServicio + idProveedor,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Catálogo de Partidas
export function listaPartidasBusquedaCall(token, page, param, servicio) {
	return axios({
	    method: "get",
	    url: endpoint + "partidas?" + param + servicio + "page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function estadoPartidaCall(token, idPartida) {
	return axios({
	    method: "get",
	    url: endpoint + "partidas/activa/" + idPartida,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function crearPartidaCall(token, data) {
	return axios({
	    method: "post",
	    url: endpoint + "partidas",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function editarPartidaCall(token, data, idPartida) {
	return axios({
	    method: "put",
	    url: endpoint + "partidas/" + idPartida,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}