import React, { Component } from 'react';
import {Row, Col, Modal, Form, Spin, Input, message, Button} from 'antd';
import { LockOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import {connect} from 'react-redux';
import {loginCall} from "../../../services/LoginServices";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalContraseña extends Component {
    formRef = React.createRef();
    state = {
        fetchingLogin: false
    }
    render() { 
        const { showModalContraseña, onCerrarModal} = this.props;    

        const handleCancel = () => {
            onCerrarModal(false, false);
        };

        let userInfo = sessionStorage.getItem('userInfo');
        if(!userInfo.idUsuario){
            userInfo = JSON.parse(userInfo);
        }
        
        const onFinish = formulario => {
            console.log('formulario', formulario);
            this.setState({
                fetchingLogin: true
            });
            const userName = userInfo[0].username;
            const values = {
                'userName': userName,
                'password': formulario.password,  
            }
            
            loginCall(values).then(response => {
                console.log('response', response);
               
                if(response.status === 200){
                    onCerrarModal(false, true);
                    this.setState({
                        fetchingLogin: false
                    });
                }
                else{
                    let errorMensaje = '';
                    if(response.error_message === 'Bad credentials'){
                        this.setState({
                            fetchingLogin: false
                        });
                        errorMensaje = 'Contraseña incorrecta. Por favor intente de nuevo';
                    }
                    else{
                        this.setState({
                            fetchingLogin: false
                        });
                        errorMensaje = response.error_message;
                    }
                    message.error(errorMensaje);
                    return;
                }
                
            }).catch(error => {
                console.log(error);
                message.error('Hubo un error, favor de intentar después');
                this.setState({
                    fetchingLogin: false
                })
                return;
            })
            
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo);
        };
        return (
            <Modal
                visible={showModalContraseña}
                title={'Autenticación'}
                onCancel={handleCancel}
                footer={false}
                >
                <Spin spinning={this.state.fetchingLogin}>
                    <Form {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        
                        ref={this.formRef}>
                            <Row justify='space-around'>
                                <Col span={11}>
                                    <Form.Item
                                        label="Ingresa tu contraseña"
                                        name="password"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Input.Password prefix={<LockOutlined/>} placeholder="Ingresa tu contraseña"/>
                                    </Form.Item>
                                </Col>
                                <Col span={11} className='marginBoton'>
                                    <br/>
                                    <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Verificar Contraseña</Button>
                                </Col>
                            </Row>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}
 
const mapStateToProps = (state) => {
    return {
        showModalContraseña: state.SolicitudDeServiciosReducer.showModalContraseña,
        
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCerrarModal: (showModalContraseña, isEspecial) => {
            dispatch({ type: "SHOW_MODAL_CONTRASEÑA" , showModalContraseña, isEspecial })
        },
        
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalContraseña);