import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import { Row, Col, Button, Table, Spin, Modal, Select, List, Card, Divider, DatePicker, Form} from 'antd';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option } = Select;
const {RangePicker} = DatePicker;
const { confirm } = Modal;

const ModalNuevoFolio = (props) => {
    const formRef = useRef();

    const {fetchingMesFolioConciliacion, listaMesesFolioConciliacion, fetchingSSByServicio, listaSSByServicio, listaSSByServicioCantidad, listaSSCantidad, page, busqueda,limpiarFormulario,
        fetchingNuevoFolioConciliacion, showModalNuevoFolioConciliacion, fetchingServiciosFC, listaServiciosFC, 
        onShowModalNuevoFolio, onGetTickets, onListaServiciosSS, onCrearFolio, onLimpiarFormulario, onLimpiarTickets
    } = props;

    const [mes, setMes] = useState({mes: null, nombreMes:""})
    const [periodo, setPeriodo] = useState(null) 
    const [table, setTable] = useState({page:1, selectedRowKeys:[], idsSolicitudes: [] });
    const [listaFolio, setListaFolio] = useState(false);
    
    const columns = [
        {title: 'Folio solicitud de servicio', dataIndex: 'folio', key: 'folio',width:'7%'},
        {title: 'Servicio', dataIndex: 'nombreServicio', key: 'nombreServicio'},
        {title: 'Descripción falla', dataIndex: 'descripcionSolicitud', key: 'descripcionSolicitud'},
        {title: 'Fecha alta', dataIndex: 'fechaAltaHora', key: 'fechaAltaHora', width:'12%'},
        {title: 'Fecha terminación', dataIndex: 'fechaTerminacionHora', key: 'fechaTerminacionHora', width:'12%'},
        {title: 'Fecha cierre', dataIndex: 'fechaCierreHora',key: 'fechaCierreHora', width:'12%'}
    ];

    useEffect (() => {
        if (formRef.current) {
            formRef.current.resetFields();
        }
        onLimpiarFormulario();
        onLimpiarTickets();
        setMes({mes: null, nombreMes:""});
        setPeriodo(null);
        setTable({page:1, selectedRowKeys:[], idsSolicitudes: [] });
        setListaFolio(false);
    }, [limpiarFormulario])

    const handleMes = (value, data) => {
        console.log(value, data);
        const mes = value ? {mes: parseInt(value), nombreMes: data.children} : {mes: null, nombreMes:""}
        setMes(mes);
        setListaFolio(false);
        onLimpiarTickets();
        formRef.current.setFieldsValue({'fecha':null, 'servicio': null});
    }

    const handleFecha = value => {
        setPeriodo(value);
        setListaFolio(false);
        value && onGetTickets(value, 1)
        formRef.current.setFieldsValue({'servicio': null});
    }

    const disabledRange = fecha => {
        const currentYear = new Date();
        let year = currentYear.getFullYear();
        let month = currentYear.getMonth()
        // month = month === 11 ? 1 : month + 2;
        // year = month === 11 ? year + 1 : year;

        if(month === 11){ month = 1; year = year + 1 } else { month = month + 2 }

        const fechaInicio = moment(`1/01/${year}`, 'DD-MM-YYYY');
        const fechaFin = moment(`1/${month}/${year}`, 'DD-MM-YYYY');

        if( fecha && fecha < fechaInicio) {
            return fecha && fecha < fechaInicio;
        } else {
            return fecha && fecha > fechaFin;
        }
    }

    const onFinish = formulario => {
        console.log(formulario);
        onListaServiciosSS(formulario.fecha);
        setListaFolio(true);
    }
    
    const onFinishFailed = errorInfo => {
        console.log(errorInfo)
    }
    
    const buscarTickets = (value) => {
        setTable({...table, page: 1});
        onGetTickets(periodo, 1, value, listaSSCantidad)
    }

    
    const onSelectChange = (selectedRowKeys, data) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys, data);
        const idsSolicitudes = selectedRowKeys.map(id => parseInt(id.split('-')[1]));
        setTable({...table, selectedRowKeys, idsSolicitudes });
    };
    
    const {selectedRowKeys } = table;
    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    };

    const handleTableChange = (pagination) => {
        const page = pagination.current;
        const idServicio = formRef.current.getFieldValue('servicio')
        setTable({...table, page});
        onGetTickets(periodo, page, idServicio,listaSSCantidad);

    }

    const crearFolioConciliacion = () => {
        confirm({
            title: 'Se generará un nuevo folio de conciliación',
            okText: 'Generar',
            okType: 'confirm',
            cancelText: 'Cancelar',
            onOk() {
                onCrearFolio(mes.mes, table.idsSolicitudes, page, busqueda)
            },
            onCancel() {
            console.log('Cancel');
            },
        });	
    }

    const handleCancel = () => {
        onShowModalNuevoFolio();
        setMes({mes: null, nombreMes:""});
        setPeriodo(null);
        setTable({page:1, selectedRowKeys:[], idsSolicitudes: [] });
        setListaFolio(false);
        onLimpiarFormulario();
        formRef.current.resetFields();
    }

    const data = [{title:"Mes Conciliación", data: mes.nombreMes || 'Seleccione mes' },{title:"Cantidad de Tickets", data: listaSSCantidad || 'Seleccione fecha'}];

    return (
    <>
        <Modal
            visible={showModalNuevoFolioConciliacion}
            title='Generar Nuevo Folio De Conciliación'
            onCancel={handleCancel}
            footer={false}
            width='80%'
        >
            <Spin spinning={fetchingNuevoFolioConciliacion}>
                <Form 
                    name='formulario'
                    onFinish={onFinish}
                    
                    ref={formRef}
                >
                    <Row justify='space-between' style={{marginBottom: '15px'}}>
                        <Col span={9}>
                            <Form.Item
                                label='Mes'
                                name='mes'
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona un mes"
                                    loading={fetchingMesFolioConciliacion}
                                    filterOption={false}
                                    notFoundContent={null}
                                    onChange = {handleMes}
                                    >
                                    {listaMesesFolioConciliacion.map(option => <Option  key={option.idMes}>{option.mes}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item
                                label='Fecha'
                                name='fecha'
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <RangePicker 
                                format='DD-MM-YYYY' 
                                placeholder={["Fecha Inicio","Fecha Fin"]} 
                                style={{width: '100%'}}
                                // defaultPickerValue={ [moment(`1/${mes}/${year}`, 'DD-MM-YYYY'), moment(`1/${mes}/${year}`, 'DD-MM-YYYY')]}
                                disabled={!mes.mes}
                                disabledDate={disabledRange}
                                onChange={handleFecha}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={5} >
                            <Button disabled={false} className="btnFiltrar" style={{ width: '100%'}} htmlType="submit" >Generar Listado</Button>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: '20px'}}>
                        <Col span={24}>
                            <List
                            grid={{gutter: 0, column: 2 }}
                            className="listaCatalogos"
                            bordered
                            dataSource={data}
                            footer={false}
                            renderItem={item => (
                               <List.Item className='listaHover' className='listaHover'
                                style={{paddingRight:'0px',paddingLeft:'0px', marginBottom:'0px'}}   
                                >
                                    <Card style={{background: 'transparent', textAlign:'center'}} size='small' bordered={false} title={item.title}>{item.data}</Card>
                                </List.Item>
                            )}
                            />
                        </Col>
                    </Row>
                {listaFolio && 
                <>
                    <Divider orientation="center">Generar Listado</Divider>
                    <Row justify='space-between' >
                        <Col span={18}>
                            <Form.Item
                                name="servicio"
                                label="Servicio"
                            >
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona un servicio"
                                    loading={fetchingServiciosFC}
                                    filterOption={false}
                                    onChange={buscarTickets}
                                    notFoundContent={null}
                                    >
                                    {listaServiciosFC.map(option => <Option  key={option.idServicio}>{option.nombre}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5} >
                            <Button disabled={table.idsSolicitudes.length === 0} onClick={crearFolioConciliacion} className="btnFiltrar" style={{ width: '100%'}}>Generar Folio</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Table
                                size="small"
                                rowKey="folio"
                                rowSelection={rowSelection}
                                columns={columns}
                                dataSource={listaSSByServicio}
                                loading={fetchingSSByServicio}
                                locale={{ emptyText: 'Sin datos' }}
                                onChange={(pagination) => handleTableChange(pagination)}
                                pagination={{ total: listaSSByServicioCantidad, simple: true, current: table.page }}
                                scroll={{"x": 1600}}
                                />
                        </Col>
                    </Row>
                    <Row justify='space-between' style={{ marginTop: '10px'}}>
                    </Row>
                </>
                }
                </Form>
            </Spin>
        </Modal>
    </>)
}

const mapStateToProps = (state) => {
    return {
        fetchingMesFolioConciliacion: state.MecanismosDePagosReducer.fetchingMesFolioConciliacion,
        listaMesesFolioConciliacion: state.MecanismosDePagosReducer.listaMesesFolioConciliacion,
        fetchingSSByServicio: state.MecanismosDePagosReducer.fetchingSSByServicio,
        listaSSByServicio: state.MecanismosDePagosReducer.listaSSByServicio,
        listaSSByServicioCantidad: state.MecanismosDePagosReducer.listaSSByServicioCantidad,
        listaSSCantidad: state.MecanismosDePagosReducer.listaSSCantidad,
        fetchingServiciosFC: state.MecanismosDePagosReducer.fetchingServiciosFC,
        listaServiciosFC: state.MecanismosDePagosReducer.listaServiciosFC,
        fetchingNuevoFolioConciliacion: state.MecanismosDePagosReducer.fetchingNuevoFolioConciliacion,
        showModalNuevoFolioConciliacion: state.MecanismosDePagosReducer.showModalNuevoFolioConciliacion,
        page: state.MecanismosDePagosReducer.page,
        busqueda: state.MecanismosDePagosReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onShowModalNuevoFolio: () => {
            dispatch({ type:'MODAL_NUEVO_FOLIO_CONCILIACION', showModalNuevoFolioConciliacion: false})
        },
        onListaServiciosSS: (rangoFecha) => {
            dispatch({ type: "SERVICIOS_FOLIO_CONCILIACION_REQUEST", rangoFecha});
        },
        onGetTickets: (rangoFecha, page, idServicio,  listaSSCantidad) => {
            dispatch({ type: "SS_BY_SERVICIO_REQUEST", rangoFecha,  page, idServicio,  listaSSCantidad});
        },
        onCrearFolio: (mes, idsSolicitudes, page, busqueda) => {
            dispatch({ type: "NUEVO_FOLIO_CONCILIACION_REQUEST", mes, idsSolicitudes,page, busqueda})
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onLimpiarTickets: () => {
            dispatch({ type: 'SS_BY_SERVICIO_SUCCESS',listaSSByServicio: null,listaSSCantidad: null,listaSSByServicioCantidad:null })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalNuevoFolio);
