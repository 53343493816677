import React, { Component } from 'react';
import {Row, Col, Modal, Button, Table, List, Spin} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import {connect} from 'react-redux';


class ModalGenerarOrden extends Component {

    render() {
        const { programaGenerar, onCerrarModal, showModalGenerarOrden,
               onGenerarOrden, programaSeleccionadoParaOrden, page, busqueda, fetchingGenerarOrden
            } = this.props;

        let data = []
        if (programaSeleccionadoParaOrden) {
            const { programa, rutina, claveRutina, frecuencia } = programaSeleccionadoParaOrden;

            data = [{ title: "Programa", data: programa },
            { title: "Rutina", data: rutina },
            { title: "Clave Rutina", data: claveRutina },
            { title: "Frecuencia", data: frecuencia },
            { title: "Programas", data: programaGenerar }]
        }    


        const handleCancel = () => {
            onCerrarModal();
        };

        const onGenerar = () => {
            onGenerarOrden(programaSeleccionadoParaOrden, programaGenerar, page, busqueda);
        }

        const columns = [{
            title: 'Estado rutina',
            dataIndex: 'estadoRutina',
            key: 'estadoRutina',
        },{
            title: 'Fecha Tarea',
            dataIndex: 'fecha',
            key: 'fecha',
        },{
            title: 'Clave Ubicacion',
            dataIndex: 'claveUbicacion',
            key: 'claveUbicacion',
        },{
            title: 'Activo',
            dataIndex: 'descripcion',
            key: 'descripcion',
        }];


        return (
            <div>
                {programaSeleccionadoParaOrden ? (
                <Modal
                    visible={showModalGenerarOrden}
                    title={'Generar Orden de Programa'}
                    onCancel={handleCancel}
                    footer={false}
                    width='40%'
                >
                    {programaSeleccionadoParaOrden ? (
                    <div>
                        <Row justify='space-around'>
                            <Col span={24}>
                                <List
                                    
                                    className="listaCatalogos"
                                    bordered
                                    dataSource={data}
                                    renderItem={item => (
                                   <List.Item className='listaHover'>
                                        <Col span={5}>
                                            <strong>{item.title}</strong>
                                        </Col>
                                        <Col span={18}>
                                            {item.title !== 'Programas' ? item.data : (
                                            <Table
                                                size='small'
                                                rowKey='idProgramacionRutina'
                                                columns={columns}
                                                dataSource={item.data}
                                                locale={{ emptyText: 'Sin datos' }}
                                                pagination={{ total: item.data ? item.data.length : 0, simple: true, hideOnSinglePage: true }}
                                            />
                                            )}
                                        </Col>
                                    </List.Item>
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <br/>
                            <Button className="btnFiltrar" style={{ width: '100%' }} onClick={onGenerar}>Generar Orden</Button>
                            </Col>
                        </Row>
                    </div>
                    ) : false}
                </Modal>
                ) : false }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showModalGenerarOrden: state.ServiciosPlanificadosReducer.showModalGenerarOrden,
        programaGenerar: state.ServiciosPlanificadosReducer.programaGenerar,
        programaSeleccionadoParaOrden: state.ServiciosPlanificadosReducer.programaSeleccionadoParaOrden,
        page: state.ServiciosPlanificadosReducer.page,
        busqueda: state.ServiciosPlanificadosReducer.busqueda,
        fetchingGenerarOrden: state.ServiciosPlanificadosReducer.fetchingGenerarOrden,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCerrarModal: () => {
            dispatch({ type: "SHOW_MODAL_GENERAR_ORDEN", showModalGenerarOrden: false, programaGenerar: []});
        },
        onGenerarOrden: (programaSeleccionadoParaOrden, programaGenerar, page , busqueda) => {
            dispatch({ type: "GENERAR_ORDEN_PROGRAMA_REQUEST" , programaSeleccionadoParaOrden, programaGenerar, page , busqueda})
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalGenerarOrden);