import React from 'react';
import { Form, Card, Spin, Row, Col, Select, Result, Input, Button, Table, Divider, Modal, Alert, Tooltip } from 'antd';
import { DeleteOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoNumeroDocumento from './ModalNuevoNumeroDocumento';

const { Option } = Select;
const { Search } = Input;

class NoDocumentoCalcularReiteracionFallasContainer extends React.Component {
     formRef = React.createRef();
     componentDidMount(){
          this.props.onGetUltimosNoDocumentos('');
          this.props.onGetUltimosNoDocumentosWithoutSearch();
     }

     state={ page: 1, busqueda: '', visualizarBool: false, nDocState: '', ticketsRelacionadosBool: false, }
 
     render() {
          const {
               onShowModalNuevoNumeroDocumento, onGetUltimosNoDocumentos, fetchingListaDocumentoReiteradoUltimos, listaDocumentosReiteradoUltimos, onEliminarDocumento, onGetTicketsRelacionadosByDocumento, fetchingTicketsReiteradosByDocumento, ticketsReiteradosByDocumento, onCleanTicketsRelacionadosByDocumento, listaDocumentosReiteradoUltimosWitoutSearch,
          } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){ permisosUsuario = JSON.parse(permisosUsuario); }


          const onNuevo = () => {onShowModalNuevoNumeroDocumento();};

          const onBuscar = (value) => {
               this.setState({ busqueda: value, page: 1 });
               console.log('busqueda en onBuscar: ', value);
               onGetUltimosNoDocumentos(value);

               let idDocumento = null;

               console.log('listaDocumentosReiteradoUltimosWitoutSearch en onBuscar', listaDocumentosReiteradoUltimosWitoutSearch)
               for(let i in listaDocumentosReiteradoUltimosWitoutSearch){
                    if(value === listaDocumentosReiteradoUltimosWitoutSearch[i].numeroDocumento){
                         console.log('Entra al if');
                         idDocumento = listaDocumentosReiteradoUltimosWitoutSearch[i].idDocumentoReiteracion;
                    }
               }

               if(idDocumento){
                    this.setState({ ticketsRelacionadosBool: true });
                    onGetTicketsRelacionadosByDocumento(idDocumento);
               } else {
                    this.setState({ ticketsRelacionadosBool: false });
               }

               this.setState({ visualizarBool: true, nDocState: value });
          };

          const visualizarDocumento = (value) => {
               if(value){
                    console.log('value', value);
                    this.setState({ visualizarBool: true, nDocState: value.numeroDocumento, ticketsRelacionadosBool: true });
                    onGetTicketsRelacionadosByDocumento(value.idDocumentoReiteracion);
               } else {
                    this.setState({ visualizarBool: false });
               }
          };

          const eliminarDocumento = (value) => {
               console.log('value', value);
               let _this = this
               Modal.confirm({
                    //title: 'This is a warning message',
                    content: <Result
                    status="warning"
                    title= {<p style={{ fontSize: '16px' }}>¿Está seguro de eliminar el número de documento por reiteración de fallas: <b style={{color:'#0e2c82'}}>{value.numeroDocumento}</b>?</p>}
                  />,
                    okText: 'Confirmar',
                    cancelText: 'Cancelar',
                    width: '35%',
                    onOk() {
                         onEliminarDocumento(value.idDocumentoReiteracion);
                         _this.setState({visualizarBool: false});
                    },
               });
          };

          const columnasDocumentos = [
               { title: 'Número de documento', dataIndex: 'numeroDocumento', key: 'numeroDocumento', align: 'center',
               render: (text, record) => (
                    <a className='naranja' disabled={!permisosUsuario.detalleNúmeroDeDocumento} onClick={() => visualizarDocumento(record)}>{text}</a>
               )},
               { title: 'Fecha registro', dataIndex: 'fRegistro', key: 'fRegistro', align: 'center' },
               { title: 'Estatus', dataIndex: 'estatus', key: 'estatus', align: 'center' },
               { title: '', dataIndex: '', key: '', align: 'center' ,
               render: (record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.eliminarNumeroDeDocumento ? true : record.estado} onClick={() => eliminarDocumento(record)}> <DeleteOutlined /> </a>
               )},
          ];

          const columnasTickets = [
               { title: 'Número de ticket', dataIndex: 'folio', key: 'folio', align: 'center' },
               { title: 'Fecha alta', dataIndex: 'fechaAltaDescription', key: 'fechaAltaDescription', align: 'center' },
               { title: 'Fecha cierre', dataIndex: 'fechaCierreDescription', key: 'fechaCierreDescription', align: 'center' },
               { title: 'Servicio', dataIndex: 'servicio', key: 'servicio', align: 'center' },
               { title: 'Área funcional', dataIndex: 'areaFuncional', key: 'areaFuncional', align: 'center' },
               { title: 'Falla', dataIndex: 'nombreFalla', key: 'nombreFalla', align: 'center' },
          ];

          return (
               <Card className="cardCatalogos" title={<div className='headerLista'>Número de Documento por Reiteración de Fallas</div>}>
                    { permisosUsuario ? (<>
                    <Spin spinning={false}>
                         <Form
                              name="formulario"
                              ref={this.formRef}
                         >
                              <Row justify='space-around' gutter={[8,8]}>
                                   <Col span={22}>
                                        <Search 
                                        className='buscarModulos' 
                                        placeholder="Ingresa el número de documento por reiteración de fallas a buscar" 
                                        onSearch={value => onBuscar(value)} 
                                        style={{ width: '100%' }}
                                        enterButton={<SearchOutlined />}
                                        />
                                   </Col>

                                   {/* <Col span={7}>
                                        <Button className='nuevoUsuario' disabled={!permisosUsuario.crearNumeroDeDocumento} onClick={onNuevo}>
                                             Nuevo Número de Documento
                                        </Button>
                                   </Col> */}
                                   <Col span={1}>
                                        <Tooltip title='Nuevo Número de Documento' placement="topRight">
                                             <Button className="iconTer" shape="circle" disabled={!permisosUsuario.crearNumeroDeDocumento} onClick={onNuevo} icon={<PlusOutlined />}/>
                                        </Tooltip>
                                   </Col>

                                   <Col span={24}>
                                        <Table 
                                             size='small'
                                             rowKey='id'
                                             dataSource={listaDocumentosReiteradoUltimos}
                                             loading={fetchingListaDocumentoReiteradoUltimos}
                                             // onChange={handleTableChange}
                                             columns={columnasDocumentos}
                                             locale={{ emptyText: 'Sin datos' }}
                                             pagination={{ total: 10, simple: true }}
                                             pagination={false}
                                        />
                                   </Col>

                                   { this.state.visualizarBool ? (<>
                                        <Divider orientation='left'>Tickets relacionados al número de documento: {this.state.nDocState} </Divider>
                                             <Col span={24}>
                                                  { this.state.ticketsRelacionadosBool ? (
                                                       <Table 
                                                            size='small'
                                                            rowKey='idSolicitudServicio'
                                                            dataSource={ticketsReiteradosByDocumento}
                                                            loading={fetchingTicketsReiteradosByDocumento}
                                                            // onChange={handleTableChange}
                                                            columns={columnasTickets}
                                                            locale={{ emptyText: 'Sin datos' }}
                                                            pagination={false}
                                                       />
                                                  ):(
                                                       <Spin spinning={fetchingTicketsReiteradosByDocumento}>
                                                       <Alert
                                                            message="Número de Documento"
                                                            description="Ingresa o selecciona un número de documento por reiteración de fallas válido."
                                                            type="info"
                                                            showIcon
                                                            style={{ borderRadius: '2em' }}
                                                       />
                                                       </Spin>
                                                  )}
                                             </Col>
                                   </>): false }

                              </Row>
                              <ModalNuevoNumeroDocumento />
                         </Form>
                    </Spin>
                    </>): (<>
                         <Row justify='center'>
                              <Result
                                   status="403"
                                   title="Acceso denegado"
                                   subTitle="Lo siento, no tiene permiso autorizado para acceder."
                              />
                         </Row>
                    </>) }
               </Card>
          );
     }
}
 
 const mapStateToProps = state => {
     return {
          listaDocumentosReiteradoUltimos: state.MecanismosDePagosReducer.listaDocumentosReiteradoUltimos,
          fetchingListaDocumentoReiteradoUltimos: state.MecanismosDePagosReducer.fetchingListaDocumentoReiteradoUltimos,
          ticketsReiteradosByDocumento: state.MecanismosDePagosReducer.ticketsReiteradosByDocumento,
          fetchingTicketsReiteradosByDocumento: state.MecanismosDePagosReducer.fetchingTicketsReiteradosByDocumento,
          listaDocumentosReiteradoUltimosWitoutSearch: state.MecanismosDePagosReducer.listaDocumentosReiteradoUltimosWitoutSearch,
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          onShowModalNuevoNumeroDocumento: () => {
               dispatch({ type: 'SHOW_MODAL_NUEVO_NO_DOCUMENTO', showModalNuevoNumeroDocumento: true})
          },
          onGetUltimosNoDocumentos: (busqueda) => {
               dispatch({ type: 'LISTA_DOCUMENTO_REITERADO_ULTIMOS_REQUEST', busqueda });
          },
          onEliminarDocumento: (idDocumentoReiteracion) => {
               dispatch({ type: 'ELIMINAR_NUMERO_DOCUMENTO_REQUEST', idDocumentoReiteracion });
          },
          onGetTicketsRelacionadosByDocumento: (idDocumentoReiteracion) => {
               dispatch({ type: 'TICKETS_REITERADOS_BY_DOCUMENTO_REQUEST', idDocumentoReiteracion });
          },
          onCleanTicketsRelacionadosByDocumento: () => {
               dispatch({ type: 'TICKETS_REITERADOS_BY_DOCUMENTO_SUCCESS', ticketsReiteradosByDocumento: [] });
          },

          onGetUltimosNoDocumentosWithoutSearch: () => {
               dispatch({ type: 'LISTA_DOCUMENTO_REITERADO_ULTIMO_WITHOUT_REQUEST' });
          },
          
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(NoDocumentoCalcularReiteracionFallasContainer);