import React, {useEffect,useRef} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {Bar} from 'react-chartjs-2';
import { Card, Row, Col,Button, DatePicker, Select, Form, Alert, Spin, Descriptions, Divider } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

import imaSearch from '../../../assets/undraw_data_report_re_p4so.svg';
import ReactVivus from "react-vivus";

const { Option } = Select;

function EstadisticasSSySP (props) {

    const {fetchingListaClasificacionesActivos,listaClasificacionesActivos,fetchingListaEstadisticasGerencia, estadisticasGerenciaSS, estadisticasGerenciaSP,
        onGetGerencias, onGetEstadisticasGerencia, onLimpiarEstadisticasGerencia} = props;
    
    const formRef = useRef();

    useEffect (() => {
        onGetGerencias();

        //Unmount
        return () => {
            onLimpiarEstadisticasGerencia();
        }
    },[]);

    const disabledDate = fecha => {
        const currentYear = new Date();
        let year = currentYear.getFullYear();
        let month = currentYear.getMonth()
        month = month === 11 ? 1 : month + 2;
        year = month === 11 ? year + 1 : year;
        const fechaInicio = moment(`1/01/${year}`, 'DD-MM-YYYY');
        const fechaFin = moment(`1/${month}/${year}`, 'DD-MM-YYYY');
        if( fecha && fecha < fechaInicio) {
            return fecha && fecha < fechaInicio;
        } else {
            return fecha && fecha > fechaFin;
        }
    }

    const {estadosObjetoArraySS={}, serviciosArraySS=[], listaSSEstadisticasCantidad=1} = estadisticasGerenciaSS;

    const dataSS = {
        labels: serviciosArraySS,
        datasets: [
            {
                label: 'Generada',
                data: estadosObjetoArraySS['Generada'],
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
            },
            {
                label: 'Abierto',
                data: estadosObjetoArraySS['Abierto'],
                backgroundColor: 'rgba(255, 159, 64, 0.8)',
            },
            {
                label: 'Traslado',
                data: estadosObjetoArraySS['Traslado'],
                backgroundColor: 'rgba(153, 102, 255, 0.5)',
            },
            {
                label: 'En Proceso',
                data: estadosObjetoArraySS['En Proceso'],
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                
            },
            {
                label: 'En Espera',
                data: estadosObjetoArraySS['En Espera'],
                backgroundColor: 'rgba(255, 206, 86, 0.5)',
            },
            {
                label: 'Terminada',
                data: estadosObjetoArraySS['Terminada'],
                backgroundColor: 'rgba(25, 105, 118, 0.8)',
            },
            {
                label: 'Cancelado',
                data: estadosObjetoArraySS['Cancelado'],
                backgroundColor: 'rgba(255, 99, 132, 0.8)',
            },
            {
                label: 'Cerrado',
                data: estadosObjetoArraySS['Cerrado'],
                backgroundColor: 'rgb(12, 101, 146, 0.7)',
            },            
        ],
    };

    const optionsSS = {
        scales: {
            yAxes: [
                {
                 ticks: {  beginAtZero: true,  precision: 0, autoSkip: true, maxRotation: 0, minRotation: 0 },
                 scaleLabel: { display: true, labelString: ['No. Solicitudes de Servicio'] } 
                }
            ],
            xAxes: [
                {
                ticks: {  beginAtZero: true, autoSkip: true, maxRotation: 30, minRotation: 0 },
                scaleLabel: { display: true, labelString: ['Servicios'] } 
                },
              ],
        },
        tooltips: { 
            enabled: true,
            callbacks: {
                label: function (tooltipItem, data) {
                    
                    let dataset = data.datasets[tooltipItem.datasetIndex];
                    
                    var currentValue = dataset.data[tooltipItem.index];
                    var percentage = Math.floor(((currentValue / listaSSEstadisticasCantidad) * 100) + 0.5);
                    return 'SS: ' + currentValue + ', porcentaje: ' + percentage + "%";
                },                    
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                },
                
                
            },
        },
        hover: { animationDuration: 7 },
    };

    const {estadosObjetoArraySP={}, serviciosArraySP=[], listaSPEstadisticasCantidad=1} = estadisticasGerenciaSP;
    const dataSP = {
        labels: serviciosArraySP,
        datasets: [
            {
                label: 'Generada',
                data: estadosObjetoArraySP['Generada'],
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
            },
            {
                label: 'Programada',
                data: estadosObjetoArraySP['Programada'],
                backgroundColor: 'rgba(255, 206, 86, 0.5)',
            },
            {
                label: 'En proceso',
                data: estadosObjetoArraySP['En proceso'],
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
            },
            {
                label: 'Realizada',
                data: estadosObjetoArraySP['Realizada'],
                backgroundColor: 'rgba(153, 102, 255, 0.5)',
            },
            {
                label: 'Fuera de tiempo',
                data: estadosObjetoArraySP['Fuera de tiempo'],
                backgroundColor: 'rgba(255, 99, 132, 0.8)',
            },
            {
                label: 'Reprogramada',
                data: estadosObjetoArraySP['Reprogramada'],
                backgroundColor: 'rgba(255, 159, 64, 0.8)',
            },
            {
                label: 'Cancelada',
                data: estadosObjetoArraySP['Cancelada'],
                backgroundColor: 'rgb(12, 101, 146, 0.7)',
            },
        ],
    };

    console.log('dataSP', dataSP)

    const optionsSP = {
        scales: {
            yAxes: [
                {
                 ticks: {  beginAtZero: true, precision: 0, autoSkip: true, maxRotation: 0, minRotation: 0 },
                 scaleLabel: { display: true, labelString: ['No. Servicios Programados'] } 
                }
            ],
            xAxes: [
                {
                ticks: {  beginAtZero: true, autoSkip: true, maxRotation: 30, minRotation: 0 },
                scaleLabel: { display: true, labelString: ['Servicios'] } 
                },
              ],
        },
        tooltips: { 
            enabled: true,
            callbacks: {
                label: function (tooltipItem, data) {
                    
                    let dataset = data.datasets[tooltipItem.datasetIndex];
                    
                    var currentValue = dataset.data[tooltipItem.index];
                    var percentage = Math.floor(((currentValue / listaSPEstadisticasCantidad) * 100) + 0.5);
                    return 'SS: ' + currentValue + ', porcentaje: ' + percentage + "%";
                },                    
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                },
                
                
            },
        },
        hover: { animationDuration: 7 },
    };

    const onFinish = formulario => {
        console.log(formulario);
        onGetEstadisticasGerencia(formulario);
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    }

    return (
        <>
            <Card
                className="cardCatalogos"
                title={<div className="headerLista">Clasificación de Solicitudes de Servicios y Servicios Programados por Gerencia</div>}
            >
                <Form
                    name="formulario"
                    ref={formRef}
                    onFinish={onFinish}
                    
                >
                    <Row justify='space-around' style={{ marginBottom: "20px" }}>
                        <Col span={6}>
                            <Form.Item
                                name='mes'
                                rules={[{ required: true, message: 'Favor de llenar el campo' }]} 
                            >
                                <DatePicker 
                                    picker="month" 
                                    style={{width: "100%"}}
                                    disabledDate={disabledDate} 
                                />
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                name='gerencia' 
                                rules={[{ required: true, message: 'Favor de llenar el campo' }]} 
                            >
                                <Select
                                    allowClear
                                    style={{width: "100%"}}
                                    placeholder='Seleccione una gerencia'
                                    loading={fetchingListaClasificacionesActivos}
                                >
                                    {listaClasificacionesActivos.map(option => (
                                        <Option key={option.idClasificacionGerencia}>{option.nombreGerencia}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={1}>
                            <Button className="btnFiltrar" shape="circle" icon={ <SearchOutlined />} htmlType="submit" />
                        </Col>
                    </Row>
                </Form>

                <Spin spinning={fetchingListaEstadisticasGerencia}>
                    <Row justify='space-around'>
                    {estadisticasGerenciaSS?.serviciosArraySS ? 
                    (
                    <>
                        <Col span={21}>
                            <Descriptions title="Solicitudes de Servicio" bordered style={{width: "100%", marginBottom:'25px'}}>                                

                                <Descriptions.Item label="Total">{listaSSEstadisticasCantidad}</Descriptions.Item>
                            </Descriptions>
                            <Bar
                            data={dataSS}
                            options={optionsSS}
                            />
                        </Col>
                        <Divider />

                        <Col span={21}>
                            <Descriptions title="Servicios Programados" bordered style={{width: "100%", marginBottom:'25px'}}>                                
                                <Descriptions.Item label="Total">{listaSPEstadisticasCantidad}</Descriptions.Item>
                            </Descriptions>
                            <Bar
                            data={dataSP}
                            options={optionsSP}
                            />
                        </Col>
                    </>
                    ) : 
                    (
                        <Col span={24}>
                            <Row justify='center' justify='space-around' align='middle' gutter={[8,8]}>
                                <Col span={24}>
                                    <Alert
                                        message="Clasificación de Solicitudes de Servicios y Servicios Programados por Gerencia"
                                        description="Selecciona una gerencia y un mes para continuar"
                                        type="info"
                                        showIcon
                                        style={{ borderRadius: '2em', width: '100%' }}
                                    />
                                </Col>
                                <Col span={7} style={{paddingTop:'4em', paddingBottom:'1em', textAlign:'center'}}>
                                {/* <img src={imaSearch} /> */}
                                    <ReactVivus
                                        id="imaSearch"
                                        option={{
                                                file: imaSearch,
                                                animTimingFunction: "EASE",
                                                type: "delayed",
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    ) }
                    </Row>
                </Spin>
            </Card>
        </>
    )
}

const mapStateToProps = state => {
    return {
        fetchingListaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.fetchingListaClasificacionesActivos,
        listaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.listaClasificacionesActivos,
        fetchingListaEstadisticasGerencia: state.EvaluacionDeLaGestionReducer.fetchingListaEstadisticasGerencia,
        estadisticasGerenciaSS: state.EvaluacionDeLaGestionReducer.estadisticasGerenciaSS,
        estadisticasGerenciaSP: state.EvaluacionDeLaGestionReducer.estadisticasGerenciaSP,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetGerencias: () => {
            dispatch({ type: 'LISTA_CLASIFICACION_ACTIVOS_REQUEST'})
        },
        onGetEstadisticasGerencia: (formulario) => {
            dispatch({ type: 'GET_ESTADISTICAS_GERENCIA_REQUEST', formulario})
        },
        onLimpiarEstadisticasGerencia: () => {
            dispatch({ type: 'GET_ESTADISTICAS_GERENCIA_SUCCESS', estadisticasGerenciaSS: {}, estadisticasGerenciaSP: {}})
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (EstadisticasSSySP);

