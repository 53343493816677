import React, { useEffect, useState } from 'react';
import {Row, Col, Table, Input, Button, Switch, Modal, Tooltip } from 'antd';
import { EditOutlined, FormOutlined , BarChartOutlined, PlusOutlined } from '@ant-design/icons';
import {useDispatch, useSelector } from 'react-redux';
import ModalNuevoServicioVariable from './ModalNuevoServicioVariable';
import ModalEditarServicioVariable from './ModalEditarServicioVariable';
import ModalCapturarInfoVariable from './ModalCapturarInfoServicioVariable';
import ModalPresentacionServicio from './ModalPresentacionServicio';
const { Search } = Input;
const confirm = Modal.confirm;

const CatalogoServiciosVariables = () => {
    //constants
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState('ASC');
    const [field, setField] = useState('idServicio');
    const [param, setParam] = useState('');
    const [busqueda, setBusqueda] = useState({order: 'ASC', field: 'idServicio'});
    const dispatch = useDispatch();
    const listaServiciosVariables = useSelector((state) => state.ServiciosVariablesReducer.listaServiciosVariables);
    const fetchingListaServiciosVariables = useSelector((state) => state.ServiciosVariablesReducer.fetchingListaServiciosVariables);
    const listaServiciosVariablesCantidad = useSelector((state) => state.ServiciosVariablesReducer.listaServiciosVariablesCantidad);
    const listaUnidadesDeMedicionActivos = useSelector((state) =>  state.AlmacenesReducer.listaUnidadesDeMedicionActivos);
    const listaUbicacionesActivos =  useSelector((state) =>state.CatalogosGeneralesReducer.listaUbicacionesActivos);
    const scroll = {"x": 700};
    const scroll2 = {"x": 500};


    let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        };

    useEffect(() => {
      dispatch({ type: 'LISTA_UNIDADES_MEDICION_ACTIVOS_REQUEST' });
      dispatch({ type: 'LISTA_UBICACIONES_ACTIVOS_REQUEST' });
      dispatch({ type: "OBTENER_SERVICIOS_VARIABLES_REQUEST", page, busqueda})
    }, [page, busqueda]); 
    
    
    //functions
    const desactivarServicioVariable = key => {
        confirm({
            title: '¿Estás seguro que deseas cambiar el estado del servicio variable "' + key.servicioByIdServicio.nombre + '"?',
            content: '',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                dispatch({ type: "ESTADO_SERVICIO_VARIABLES_REQUEST", idServicioVariable:key.idServicioVariable, page});
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };
    const editarServicioVariable = key => {
        //dispatch({ type: "OBTENER_VARIABLES_FILTRADAS_POR_SERVICIO_REQUEST", idServicioVariable:key.idServicioVariable});
        dispatch({ type: "SHOW_MODAL_EDITAR_SERVICIO_VARIABLE" , showModalEditarServicioVariable: true, servicioVariableSeleccionado:key, page, busqueda})
    };

    const capturarInfoVariable = key => {
        dispatch({ type: "SHOW_MODAL_CAPTURAR_INFO_SERVICIO_VARIABLE", showModalCapturarInfoServicioVariable: true, servicioCapturarSeleccionado:key, page})
        dispatch({ type: "LISTA_DETALLE_SERVICIO_VARIABLE_REQUEST", idServicioVariable:key.idServicioVariable});
        if(key.variablesByIdServicioVariable[0]){
            dispatch({ type: "GET_SUBVARIABLE_BY_IDS_REQUEST", idServicioVariable:key.idServicioVariable, idVariable: key.variablesByIdServicioVariable[0].idVariable, listaUbicacionesActivos:listaUbicacionesActivos});
        }
      
    };

    const onPresentacion = key => {
        if(key){
            for (const k in listaServiciosVariables) {
                if(listaServiciosVariables[k].idServicioVariable === key.idServicioVariable){
                    key.servicioVariable = listaServiciosVariables[k].nombre
                }
            }
        }
        dispatch({ type: "SHOW_MODAL_PRESENTACION_SERVICIO_VARIABLE", showModalPresentacionServicioVariable: true, servicioPresentacionSeleccionado:key})
    }

    const onBuscarServicioVariable = param => {
        const search = { param: param, campo:field, orden: order};
        setBusqueda(search);
        setPage(1);
        dispatch({ type: "OBTENER_SERVICIOS_VARIABLES_REQUEST", page:1, busqueda:search})
    };
    const handleTableChange = (pagination, filtro, orderParam) => {
        setPage(pagination.current)
        if(orderParam){
            let campo = 'idServicio';
            let orderValue = 'ASC';

            if(orderParam.order === 'descend'){
                orderValue = 'DESC';
            }
            if (orderParam.columnKey === 'idServicio') {
                campo = 'idServicio';
            } 

            const search = { param: param, field: campo, order: order};
            dispatch({ type: "OBTENER_SERVICIOS_VARIABLES_REQUEST", page:pagination.current, busqueda:search})
            setOrder(orderValue)
            setField(campo)
            setBusqueda(search)
        } else {
            dispatch({ type: "OBTENER_SERVICIOS_VARIABLES_REQUEST", page:pagination.current, busqueda:busqueda})
        }
    };

    const nuevoServicioVariable = () => {
        dispatch({ type: "SHOW_MODAL_CREAR_SERVICIO_VARIABLE", showModalNuevoServicioVariable: true})
    }

    //columns

    const columnSubVariables = [
        { title: 'Subvariable', dataIndex: 'nombre',
            render: (text, record) => (
            <>{record.subvariableByIdSubvariable ? (<span>{record.subvariableByIdSubvariable.nombre}</span>) : (<span>No data</span>)}</>
        )},
        { title: 'Unidad Medida', dataIndex: 'unidadMedida',
        render: (text, record) => (
            <>{record.subvariableByIdSubvariable.unidadMedidaByIdUnidadMedida ? (<span>{record.subvariableByIdSubvariable.unidadMedidaByIdUnidadMedida.unidadMedida}</span>) : (<span>No data</span>)}</>
        )},
        ,{title: 'Presentación', 
            key: 'detalle', 
            align: 'center',
            render: (record) => (
                <a className='editarIcono' disabled={!permisosUsuario.presentaciónDeServicioVariable}  onClick={() => onPresentacion(record)}><BarChartOutlined /></a>
            )
        },
    ];

    const columnServiciosVariablesByVariable = [
        { title: 'Variable', dataIndex: 'variable',  key: 'variable' },
        { title: 'Subvariables', key: 'subvariableByIdSubvariable', align: 'center',
        render: (text, record) => (<>
                <Table style={{padding:'1em'}}
                    size="small" 
                    rowKey="idServicio"
                    scroll={scroll2}
                    columns={columnSubVariables}
                    dataSource={record.variablesServiciosVariablesByIdVariable}
                    locale={{ emptyText: 'Sin datos' }}
                    pagination={ false }
                /> 
        </>),
        },
        { title: 'Tiempo', dataIndex: 'tiempo',  key: 'tiempo'},
    ];

    const columns = [{
        title: 'Servicio',
        dataIndex: 'nombre',
        key: 'nombre',
        align: 'center',
        sorter: true
    },{
        title: 'Descripcion',
        dataIndex: 'descripcion',
        key: 'descripcion',
        align: 'center',
    },{
        title: 'Activo',
        dataIndex: 'activo',
        key: 'activo',
        render: (text, record) => (
            <Switch checked={text} disabled={!permisosUsuario.servicioVariableActivoInactivo} onChange = {() => desactivarServicioVariable(record)} />
        ),
    },
    { title: 'Variables', key: 'variablesServiciosVariablesByIdVariable', align: 'center',
        render: (text, record) => (<>
        
                <Table style={{padding:'1em'}}
                    size="small" 
                    rowKey="idServicio"
                    scroll={scroll2}
                    columns={columnServiciosVariablesByVariable}
                    dataSource={record.variablesByIdServicioVariable}
                    locale={{ emptyText: 'Sin datos' }}
                    pagination={ false }
                /> 
        </>),
     },
    {
        title: 'Editar',
        key: 'editar',
        align: 'center',
        render: (record) => (
           <a className='editarIcono' disabled={!permisosUsuario.editarServicioVariable} onClick={ () => editarServicioVariable(record) }> < EditOutlined />
           </a>
        ),          
    },{
        title: 'Capturar',
        key: 'capturar',
        align: 'center',
        render: (record) => (
           <a className='editarIcono'  disabled={!permisosUsuario.capturarServicioVariable} onClick={ () => capturarInfoVariable(record) }> <FormOutlined />
           </a>
        ),          
    }
   ];


    return(
        <>
            <Row type='flex' justify='space-between' gutter={[8,8]}>
                <Col span={20}>
                    <Search className='buscarModulos' placeholder="Buscar servicio variable" onSearch={value => onBuscarServicioVariable(value)} style={{ width: '100%' }} />
                </Col>
                <Col span={1}>
                    <Tooltip title='Presentación General' placement="topRight">
                        <Button className="iconTer" shape="circle" onClick={() => onPresentacion(null)} disabled={!permisosUsuario.presentaciónDeServicioVariable} icon={<BarChartOutlined />}/>
                    </Tooltip>
                </Col>
                <Col span={1}>
                    <Tooltip title='Nuevo Servicio Variable' placement="topRight">
                        <Button className="iconTer" shape="circle" onClick={nuevoServicioVariable} disabled={!permisosUsuario.crearServicioVariable} icon={<PlusOutlined />}/>
                    </Tooltip>
                </Col>

                <Col span={24}>
                    <Table
                        size="small"
                        rowKey="idServicioVariable"
                        columns={columns}
                        scroll={scroll}
                        dataSource={listaServiciosVariables}
                        loading={fetchingListaServiciosVariables}
                        locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar' }}
                        onChange={handleTableChange}
                        pagination={{ total: listaServiciosVariablesCantidad, simple: true, current: page }}
                    />
                </Col>
            </Row>
            <ModalNuevoServicioVariable/>
            <ModalEditarServicioVariable/>
            <ModalCapturarInfoVariable/>
            <ModalPresentacionServicio/>
        </>
    )
}



export default CatalogoServiciosVariables;