import React from 'react';
import {connect} from 'react-redux';
import {Form, Row, Col, Button, Modal, Spin, Select, message,Popover, InputNumber, Input, Alert} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalAsignacionDepositos extends React.Component {
    formRef = React.createRef();

    state = {
        cantidadDeposito : null,
        depositosArray:[],
        nombreDeposito: "",
        idServicioPublico: null
    }

    componentDidMount() {
        this.props.onListaServiciosPublicosNA();
    }

    render() {
        const {fetchingNuevaAsignacionSP, showModalNuevaAsignacionSP, page, busqueda, limpiarFormulario, fetchingListaServiciosPublicosNoAsignados, listaServiciosPublicosNoAsignados,
            fetchingListaUbicacionesFiltro,listaUbicacionesFiltro, fetchingListaUnidadesDeMedicionActivos, listaUnidadesDeMedicionActivos, onShowModalNuevaAsignacion, 
            onLimpiarFormulario, onListaServiciosPublicosNA, onListaUbicacionesFiltro, onListaUbicacionesFiltroLimpiar, onCrearAsignacion

        } = this.props;

        const {cantidadDeposito, depositosArray,idServicioPublico} = this.state;

        if (limpiarFormulario && limpiarFormulario === 'CASP') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        const handleSelect = (value, data) => {
            console.log(value,data)
            this.formRef.current.resetFields();
            this.setState({cantidadDeposito:value, depositosArray:[], nombreDeposito:"", idServicioPublico: null})
            if (!isNaN(value)){
                this.formRef.current.setFieldsValue({'servicioPublico': data.children})
                const array = [...Array(value).keys()]
                this.setState({depositosArray:array, nombreDeposito: data.nombreDeposito, idServicioPublico: parseInt(data.key)})
            }
        }

        const handleSearch = (value) => {
            if(value.length>=3){
                if (value) {
                    console.log(value)
                    onListaUbicacionesFiltro(value);
                } else {
                    message.warning('No se encontró ubicación con esas palabras')
                }
            }
            else{
                onListaUbicacionesFiltroLimpiar();
            }
        };

        const onFinish = formulario => {
            console.log(formulario);
            let asignacionObjet = {'idAsignacionServicioPublico': null, 'idServicioPublico': idServicioPublico, 'idUbicacion':null,
                'idUnidadMedida':null, 'capacidad': null, 'fechaAlta': null, 'fechaActualizacion':null, 'activo':true}
            let asignacionArray = []
            let contador = 0;
            for (var key in formulario) {
                if (Object.prototype.hasOwnProperty.call(formulario, key)) {
                    let campo = key.split('-');
                    if(campo.length === 2) {
                        let valor = formulario[key]
                        asignacionObjet[campo[0]] = parseInt(valor.toString().split('-')[0])
                        contador++
                        if(contador === 3){
                            asignacionArray.push(asignacionObjet)
                            contador=0;
                            asignacionObjet = {'idAsignacionServicioPublico': null, 'idServicioPublico': idServicioPublico, 'idUbicacion':null,
                                'idUnidadMedida':null, 'capacidad': null, 'fechaAlta': null, 'fechaActualizacion':null, 'activo':true}
                        }
                    } 
                }
            }
            console.log(idServicioPublico, asignacionArray, page, busqueda);
            onCrearAsignacion(idServicioPublico, asignacionArray, page, busqueda)
        }

        const onFinishFailed = () =>{
            console.log('Cancel')
        }
        
        const handleCancel = () => {
            onShowModalNuevaAsignacion();
            this.setState({cantidadDeposito:null, depositosArray:[], nombreDeposito:"", idServicioPublico:null})
            this.formRef.current.resetFields();
        }

        return (
            <div>
                <Modal 
                    visible={showModalNuevaAsignacionSP}
                    title='Nueva Asignación de Depósitos de Servicio Público'
                    onCancel={handleCancel}
                    footer={false}
                    width='90%'
                >
                    <Spin spinning={fetchingNuevaAsignacionSP}>
                        <Form {...layout}
                            name='formulario'
                            onFinish={onFinish}
                            
                            ref={this.formRef}
                        >
                            {!cantidadDeposito ? 
                            <div>
                                <Alert
                                    message={"Favor de seleccionar un servicio público de la lista."}
                                    type="info"
                                />
                                <br/>
                            </div> : false}
                            <Row type='flex' justify='space-around'>
                                <Col span={24}>
                                    <Form.Item
                                        label='Servicio públicos'
                                        name='servicioPublico'
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select
                                            allowClear
                                            style={{width: '100%', textAlign: 'left'}}
                                            placeholder='Seleccione un servicio público'
                                            loading={fetchingListaServiciosPublicosNoAsignados}
                                            onChange = {handleSelect}
                                            >
                                            {listaServiciosPublicosNoAsignados.map(option => <Option key={option.idServicioPublico} value={option.cantidadDeposito} nombreDeposito={option.nombreDeposito}>{option.nombre}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            {cantidadDeposito ? 
                            <div>
                                <Alert
                                    message={"Favor de asignar la ubicación y capacidad a los depósitos."}
                                    type="info"
                                />
                                <br/>
                            </div>: false}
                            {cantidadDeposito ? (depositosArray.map(deposito => (
                                <Row type='flex' justify='space-around'>
                                    <Col span={4}>
                                        <Form.Item
                                            label={deposito==0 ? 'Nombre depósito': false}
                                            name={`${this.state.nombreDeposito}-${deposito}-${deposito}`}
                                        >
                                            <Input disabled defaultValue={this.state.nombreDeposito}/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item
                                            label={deposito==0 ?'Ubicación': false }
                                            name={`idUbicacion-${deposito}`}
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <Select allowClear
                                                style={{ width: '100%' }}
                                                placeholder="Ingrese la ubicación"
                                                loading={fetchingListaUbicacionesFiltro}
                                                showSearch
                                                filterOption={false}
                                                onSearch={handleSearch}
                                                notFoundContent={null}
                                                dropdownStyle={{ minWidth: "60%" }}
                                            >
                                                {listaUbicacionesFiltro.map(option =>
                                                    <Option key={`${option.idUbicacion} - ${option.todoUbicacionCOA}`}>
                                                        <Popover content={option.todoUbicacionCOA} title={false}>
                                                            {`${option.idUbicacion} - ${option.todoUbicacionCOA}`}
                                                        </Popover>
                                                    </Option>
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item
                                            label={deposito==0 ?'Capacidad': false }
                                            name={`capacidad-${deposito}`}
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <InputNumber min={1}  placeholder="Ingrese la capacidad" />

                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item
                                            label={deposito==0 ?'Unidad de medida': false }
                                            name={`idUnidadMedida-${deposito}`}
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <Select
                                                allowClear
                                                style={{ width: '100%' }}
                                                placeholder="Seleccione una unidad de medida"
                                                loading={fetchingListaUnidadesDeMedicionActivos}
                                            >
                                                {listaUnidadesDeMedicionActivos.map(option => <Option key={option.idUnidadMedida}>{option.unidadMedida}</Option>)}
                                            </Select>  
                                        </Form.Item>
                                    </Col>
                            </Row>           
                            ))
                            ): null}
                            <Row type='flex' justify='center'>
                                <Col span={12}>
                                    <Button disabled={!depositosArray.length>0} className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Crear Asignacion de Ubicación y Capacidad</Button>
                                </Col>
                            </Row>
                        </Form>

                    </Spin>

                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingNuevaAsignacionSP: state.ServiciosPublicosReducer.fetchingNuevaAsignacionSP,
        showModalNuevaAsignacionSP: state.ServiciosPublicosReducer.showModalNuevaAsignacionSP,
        page: state.ServiciosPublicosReducer.page,
        busqueda: state.ServiciosPublicosReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaServiciosPublicosNoAsignados: state.ServiciosPublicosReducer.fetchingListaServiciosPublicosNoAsignados,
        listaServiciosPublicosNoAsignados: state.ServiciosPublicosReducer.listaServiciosPublicosNoAsignados,
        fetchingListaUbicacionesFiltro: state.CatalogosGeneralesReducer.fetchingListaUbicacionesFiltro,
        listaUbicacionesFiltro: state.CatalogosGeneralesReducer.listaUbicacionesFiltro,
        fetchingListaUnidadesDeMedicionActivos: state.AlmacenesReducer.fetchingListaUnidadesDeMedicionActivos,
        listaUnidadesDeMedicionActivos: state.AlmacenesReducer.listaUnidadesDeMedicionActivos,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevaAsignacion: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVA_ASIGNACION_DEPOSITOS', showModalNuevaAsignacionSP:false})
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
        },
        onListaServiciosPublicosNA: () => {
            dispatch({ type: 'LISTA_SERVICIOS_PUBLICOS_NO_ASIGNADOS_REQUEST'})
        },
        onListaUbicacionesFiltro: (busqueda) => {
            dispatch({ type: 'LISTA_UBICACIONES_FILTRO_REQUEST', busqueda: busqueda });
        },
        onListaUbicacionesFiltroLimpiar: () => {
            dispatch({ type: 'LISTA_UBICACIONES_FILTRO_SUCCESS', listaUbicacionesFiltro: [] });
        },
        onCrearAsignacion: (idServicioPublico, formulario, page, busqueda) => {
            dispatch({ type: 'NUEVA_ASIGNACION_SERVICIOS_PUBLICOS_REQUEST', idServicioPublico: idServicioPublico, formulario: formulario, page: page, busqueda: busqueda});
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalAsignacionDepositos);