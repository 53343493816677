import React from 'react';
import { message, Form, Row, Col, Button, Modal, Select, Switch, Divider, InputNumber, Card } from 'antd';
import { connect } from "react-redux";
import { ClearOutlined } from '@ant-design/icons';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalProductos from './ModalProductos';

const { Option } = Select;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };

class InventarioCiclicoContainer extends React.Component {
     formRef = React.createRef();

     componentDidMount() {
          this.props.onGetAlmacenesActivos();
          this.props.onGetServiciosActivos();
     }

     state = {
          page: 1,
          checkedAlmacen: true,
          checkedServicio: true,
          checkedFamilia: true,
          checkedSubfamilia: true,

          todosAlmacenesNombre: [],
          todosServiciosNombre: [],
          todasFamiliasNombre: [],
          todasSubfamiliasNombre: [],

          almacenesSeleccionadosLocal: [],
          serviciosSeleccionadosLocal: [],
          familiasSeleccionadasLocal: [],
          subfamiliasSeleccionadasLocal: [],
     }


     render() {
          const { 
               limpiarFormulario, 
               onLimpiarFormulario,
               listaAlmacenesActivos,
               fetchingListaAlmacenesActivos,
               onListaServiciosFiltro,
               onListaServiciosFiltroLimpiar,
               fetchingListaServiciosFiltro,
               listaServiciosFiltro,
               listaServiciosActivosF,
               onGetServiciosAlmacen,
               fetchingListaServiciosbyAlmacen,
               listaServiciosbyAlmacen,
               onGetFamiliasServicio,
               fetchingListaFamiliasbyServicio,
               listaFamiliasbyServicio,
               listaSubfamiliasbyFamilia,
               fetchingListaSubfamiliasbyFamilia,
               onGetSubfamiliasFamilia,
               onLimipiarServiciosAlmacen,
               onLimpiarFamiliasServicio,
               onLimpiarSubfamiliasFamilia,
               onModalProductos,
               onProductosCiclico,
          } = this.props;

          const onFinish = formulario => {              
               console.log('Success:', formulario);
               if(parseFloat(formulario.rangoMenor) > parseFloat(formulario.rangoMayor)){
                    Modal.error({
                         title: <p> <strong>Error en Rangos de Costo </strong> </p>,
                         content: <p> El rango menor ingresado: <strong style={{color: '#ff7875'}}>$ {formulario.rangoMenor}</strong> no puede ser superior a el rango mayor: $ {formulario.rangoMayor}, favor de verificar. </p>,
                    });
               } else {
                    let almacenesSeleccionados = [];
                    let serviciosSeleccionados = [];
                    let familiasSeleccionadas = [];
                    let subfamiliasSeleccionadas = [];
                    if(!this.state.checkedAlmacen){
                         for(let i = 0; i < listaAlmacenesActivos.length; i ++){
                              almacenesSeleccionados[i] = listaAlmacenesActivos[i];
                         }
                    }else{
                         for(let i = 0; i < this.state.almacenesSeleccionadosLocal.length; i ++){
                              almacenesSeleccionados[i] = this.state.almacenesSeleccionadosLocal[i];
                         }
                    }

                    if(!this.state.checkedServicio){
                         for(let i = 0; i < listaServiciosbyAlmacen.length; i ++){
                              serviciosSeleccionados[i] = listaServiciosbyAlmacen[i];
                         }
                    }else{
                         for(let i = 0; i < this.state.serviciosSeleccionadosLocal.length; i ++){
                              serviciosSeleccionados[i] = this.state.serviciosSeleccionadosLocal[i];
                         }
                    }

                    if(!this.state.checkedFamilia){
                         for(let i = 0; i < listaFamiliasbyServicio.length; i ++){
                              familiasSeleccionadas[i] = listaFamiliasbyServicio[i];
                         }
                    }else{
                         for(let i = 0; i < this.state.familiasSeleccionadasLocal.length; i ++){
                              familiasSeleccionadas[i] = this.state.familiasSeleccionadasLocal[i];
                         }
                    }

                    if(!this.state.checkedSubfamilia){
                         for(let i = 0; i < listaSubfamiliasbyFamilia.length; i ++){
                              subfamiliasSeleccionadas[i] = listaSubfamiliasbyFamilia[i];
                         }
                    }else{
                         for(let i = 0; i < this.state.subfamiliasSeleccionadasLocal.length; i ++){
                              subfamiliasSeleccionadas[i] = this.state.subfamiliasSeleccionadasLocal[i];
                         }
                    }
                    onModalProductos(almacenesSeleccionados, serviciosSeleccionados, familiasSeleccionadas, subfamiliasSeleccionadas, parseFloat(formulario.rangoMayor), parseFloat(formulario.rangoMenor), formulario.porcentaje, this.state.page);

                    onProductosCiclico(subfamiliasSeleccionadas, almacenesSeleccionados, formulario.porcentaje, formulario.rangoMayor, formulario.rangoMenor, this.state.page);


                    // this.setState({ almacenesSeleccionadosLocal: [], serviciosSeleccionadosLocal: [], familiasSeleccionadasLocal: [], subfamiliasSeleccionadasLocal: [], checkedAlmacen: true, checkedServicio: true, checkedFamilia: true, checkedSubfamilia: true });
                    // this.formRef.current.resetFields();
                    // onLimipiarServiciosAlmacen();
                    // onLimpiarFamiliasServicio();
                    // onLimpiarSubfamiliasFamilia();

                    //console.log('Data enviar', almacenesSeleccionados, listaServiciosbyAlmacen, listaFamiliasbyServicio, listaSubfamiliasbyFamilia, parseFloat(formulario.rangoMayor), parseFloat(formulario.rangoMenor), formulario.porcentaje);

                    this.formRef.current.resetFields(['rangoMenor','rangoMayor', 'porcentaje']);
                    
               }
          };

          if (limpiarFormulario && limpiarFormulario === 'inventarioFisico') {
               onLimpiarFormulario();
          }

          const onFinishFailed = errorInfo => {
               console.log('Failed:', errorInfo);
          };

          const todosAlmacenes = (checked) => {
               const almacenesNombre = [listaAlmacenesActivos.length];
               const todosAlmacenesSeleccionados = [listaAlmacenesActivos.length];

               for(let i = 0; i < listaAlmacenesActivos.length; i++){
                    almacenesNombre[i] = listaAlmacenesActivos[i].nombreC;
                    todosAlmacenesSeleccionados[i] = listaAlmacenesActivos[i];
               }

               if(!(!this.formRef.current.getFieldValue('servicios')) || !checked){
                    //message.warning('Por motivos de seguridad vuelva a ingresar los campos de Servicios, Familias y Subfamilias');
                    this.formRef.current.resetFields(['servicios', 'familia', 'subfamilia']);
                    onLimipiarServiciosAlmacen();
                    onLimpiarFamiliasServicio();
                    onLimpiarSubfamiliasFamilia();
                    this.setState({checkedServicio: true, checkedFamilia: true, checkedSubfamilia: true, almacenesSeleccionadosLocal: [], serviciosSeleccionadosLocal: [], familiasSeleccionadasLocal: []});
               } else if(checked){
                    this.setState({checkedServicio: true, checkedFamilia: true, checkedSubfamilia: true, almacenesSeleccionadosLocal: [], serviciosSeleccionadosLocal: [], familiasSeleccionadasLocal: []});
               }

               onGetServiciosAlmacen(checked, [],listaAlmacenesActivos, todosAlmacenesSeleccionados);
               this.setState({ checkedAlmacen: !checked, todosAlmacenesNombre: almacenesNombre });
               this.formRef.current.resetFields(['almacenes', 'servicios', 'familia', 'subfamilia']);
          };

          const onAlmacenes = (value) => {
               const almacenesSeleccionados = []
               for(let i = 0; i < listaAlmacenesActivos.length; i++){
                    for(let j = 0; j < value.length; j ++){
                         if(listaAlmacenesActivos[i].idAlmacen === parseInt(value[j])){
                              almacenesSeleccionados[j] = listaAlmacenesActivos[i];
                         }
                    }
               }

               if(this.formRef.current.getFieldValue('servicios') || !this.state.checkedServicio){
                    onLimipiarServiciosAlmacen();
                    onLimpiarFamiliasServicio();
                    onLimpiarSubfamiliasFamilia();
                    this.formRef.current.resetFields(['servicios', 'familia', 'subfamilia']);
                    message.warning('Por motivos de seguridad vuelva a ingresar los campos de Servicios, Familias y Subfamilias');
                    this.setState({
                         //checkedServicio: true, checkedFamilia: true, checkedSubfamilia: true, almacenesSeleccionadosLocal: [], serviciosSeleccionadosLocal: [], familiasSeleccionadasLocal: []
                         checkedServicio: true, checkedFamilia: true, checkedSubfamilia: true, almacenesSeleccionadosLocal: [], serviciosSeleccionadosLocal: [], familiasSeleccionadasLocal: []
                    });
               }

               onGetServiciosAlmacen(!this.state.checkedAlmacen, almacenesSeleccionados, listaAlmacenesActivos, []);
               this.setState({ almacenesSeleccionadosLocal: almacenesSeleccionados });
          };

          const todosServicios = (checked) => {
               const serviciosNombre = [listaServiciosbyAlmacen.length];
               const todosServiciosSeleccionados = [listaServiciosbyAlmacen.length];

               for(let i = 0; i < listaServiciosbyAlmacen.length; i++){
                    serviciosNombre[i] = listaServiciosbyAlmacen[i].nombre;
                    todosServiciosSeleccionados[i] = listaServiciosbyAlmacen[i];
               }

               if(!(!this.formRef.current.getFieldValue('familia')) || !checked){
                    //message.warning('Por motivos de seguridad vuelva a ingresar los campos de Familias y Subfamilias');
                    this.formRef.current.resetFields(['familia', 'subfamilia']);
                    onLimpiarFamiliasServicio();
                    onLimpiarSubfamiliasFamilia();
                    this.setState({checkedFamilia: true, checkedSubfamilia: true, serviciosSeleccionadosLocal: [], familiasSeleccionadasLocal: []});
               } else if(checked){
                    this.setState({checkedFamilia: true, checkedSubfamilia: true, serviciosSeleccionadosLocal: [], familiasSeleccionadasLocal: []});
               }

               onGetFamiliasServicio(checked, [], listaServiciosbyAlmacen, todosServiciosSeleccionados);
               this.setState({ checkedServicio: !checked, todosServiciosNombre: serviciosNombre });
               this.formRef.current.resetFields(['servicios', 'familia', 'subfamilia']);
          };

          const onServicios = (value) => {
               const serviciosSeleccionados = [];

               for(let i = 0; i < listaServiciosbyAlmacen.length; i++){
                    for(let j = 0; j < value.length; j ++){
                         if(listaServiciosbyAlmacen[i].idServicio === parseInt(value[j])){
                              serviciosSeleccionados[j] = listaServiciosbyAlmacen[i];
                         }
                    }
               }

               if(this.formRef.current.getFieldValue('familia') || !this.state.checkedFamilia){
                    this.formRef.current.resetFields(['familia', 'subfamilia']);
                    message.warning('Por motivos de seguridad vuelva a ingresar los campos de Familia y Subfamilia');
                    onLimpiarFamiliasServicio();
                    onLimpiarSubfamiliasFamilia();

                    this.setState({
                         checkedFamilia: true, checkedSubfamilia: true, serviciosSeleccionadosLocal: [], familiasSeleccionadasLocal: []
                    });
               }

               onGetFamiliasServicio(!this.state.checkedServicio, serviciosSeleccionados, listaServiciosbyAlmacen, []);
               this.setState({ serviciosSeleccionadosLocal: serviciosSeleccionados });
          };

          const todasFamilias = (checked) => {
               const familiasNombre = [listaFamiliasbyServicio.length];
               const todasFamiliasSeleccionadas = [listaFamiliasbyServicio.length];

               for(let i = 0; i < listaFamiliasbyServicio.length; i++){
                    familiasNombre[i] = listaFamiliasbyServicio[i].familia;
                    todasFamiliasSeleccionadas[i] = listaFamiliasbyServicio[i];
               }

               if(!(!this.formRef.current.getFieldValue('subfamilia')) || !checked){
                    //message.warning('Por motivos de seguridad vuelva a ingresar el campos de Subfamilias');
                    this.formRef.current.resetFields(['subfamilia']);
                    onLimpiarSubfamiliasFamilia();
                    this.setState({checkedSubfamilia: true, familiasSeleccionadasLocal: []});
               } else if (checked){
                    this.setState({checkedSubfamilia: true, familiasSeleccionadasLocal: []});
               }

               onGetSubfamiliasFamilia(checked, [], listaFamiliasbyServicio, todasFamiliasSeleccionadas);
               this.setState({ checkedFamilia: !checked, todasFamiliasNombre: familiasNombre });
               this.formRef.current.resetFields(['familia', 'subfamilia']);
          };


          const onFamilia = (value) => {
               const familiasSeleccionadas = [];

               for(let i = 0; i < listaFamiliasbyServicio.length; i++){
                    for(let j = 0; j < value.length; j++){
                         if(listaFamiliasbyServicio[i].idFamilia === parseInt(value[j])){
                              familiasSeleccionadas[j] = listaFamiliasbyServicio[i];
                         }
                    }
               }

               if(this.formRef.current.getFieldValue('subfamilia') || !this.state.checkedSubfamilia){
                    this.formRef.current.resetFields(['subfamilia']);
                    message.warning('Por motivos de seguridad vuelva a ingresar el campo de Subfamilia');
                    onLimpiarSubfamiliasFamilia();
                    this.setState({checkedSubfamilia: true, familiasSeleccionadasLocal: []});
               }

               onGetSubfamiliasFamilia(!this.state.checkedFamilia, familiasSeleccionadas, listaFamiliasbyServicio, []);
               this.setState({ familiasSeleccionadasLocal: familiasSeleccionadas });
          };

          const todasSubfamilias = (checked) => {
               const subfamiliasNombre = [listaSubfamiliasbyFamilia.length];

               for(let i = 0; i < listaSubfamiliasbyFamilia.length; i++){
                    subfamiliasNombre[i] = listaSubfamiliasbyFamilia[i].subFamilia;
               }

               this.setState({ checkedSubfamilia: !checked, todasSubfamiliasNombre: subfamiliasNombre });
               this.formRef.current.resetFields(['subfamilia']);
          };

          const onSubfamilias = (value) => {
               const subfamiliasSeleccionadas = [];

               for(let i = 0; i < listaSubfamiliasbyFamilia.length; i++){
                    for(let j = 0; j < value.length; j++){
                         if(listaSubfamiliasbyFamilia[i].idSubFamilia === parseInt(value[j])){
                              subfamiliasSeleccionadas[j] = listaSubfamiliasbyFamilia[i];
                         }
                    }
               }
               
               this.setState({ subfamiliasSeleccionadasLocal: subfamiliasSeleccionadas });
          };

          const changeDecimalesMenor = (value) => {
               let valueDecimal = parseFloat(value).toFixed(2);
               if(isNaN(valueDecimal)){
                    valueDecimal = 0.00;
               }
               this.formRef.current.setFieldsValue({'rangoMenor': valueDecimal});
          };

          const changeDecimalesMayor = (value) => {
               let valueDecimal = parseFloat(value).toFixed(2);
               if(isNaN(valueDecimal)){
                    valueDecimal = 0.00;
               }
               this.formRef.current.setFieldsValue({'rangoMayor': valueDecimal});
          }; 

          return (
          <Card className="cardCatalogos" title={<div className="headerLista">Listado para Toma de Inventario Cíclico</div>}>
               <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
               >
                    <Row justify='space-around' style={{ textAlign: 'left' }} gutter={[8,8]}>     
                         <Col span={22}>
                              <Form.Item
                              label='Almacenes'
                              name='almacenes'
                              rules={[{ required: this.state.checkedAlmacen, message: 'Favor de llenar el campo', }]}
                              >
                                   { this.state.checkedAlmacen ? (
                                        <Select
                                             mode="multiple"
                                             allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Selecciona uno varios almacenes"
                                             loading={fetchingListaAlmacenesActivos}
                                             disabled={!this.state.checkedAlmacen}
                                             notFoundContent={null}
                                             onChange={onAlmacenes}
                                        >
                                             {listaAlmacenesActivos.map(option => <Option key={option.idAlmacen}>{option.nombreC}</Option>)}
                                        </Select>
                                   ): (
                                        <Select
                                             mode="multiple"
                                             style={{ width: '100%' }}
                                             placeholder="Se han seleccionado todos los almacenes"
                                             defaultValue={this.state.todosAlmacenesNombre}
                                             disabled={true}
                                        />

                                   ) }
                              </Form.Item>
                         </Col>

                         <Col span={2}>
                              <Form.Item
                                   label=' '
                                   name='todosAlmacenes'
                              >
                                   <Switch
                                        checkedChildren={'TODOS'}
                                        unCheckedChildren={'TODOS'}
                                        defaultChecked={false}
                                        disabled={!listaAlmacenesActivos[0]}
                                        size={'default'}
                                        onChange={todosAlmacenes}
                                   />
                              </Form.Item>
                         </Col>
                    
                         { !this.state.checkedAlmacen || this.state.almacenesSeleccionadosLocal[0] ? (
                         
                              <Col span={22}>
                                   <Form.Item
                                   label='Servicios'
                                   name='servicios'
                                   rules={[{ required: this.state.checkedServicio, message: 'Favor de llenar el campo', }]}
                                   >
                                        { this.state.checkedServicio ? (
                                             <Select
                                                  mode="multiple"
                                                  allowClear
                                                  style={{ width: '100%' }}
                                                  placeholder="Selecciona uno varios servicios"
                                                  loading={fetchingListaServiciosbyAlmacen}
                                                  onChange={onServicios}
                                             >
                                                  {listaServiciosbyAlmacen.map(option => <Option key={option.idServicio}>{option.nombre}</Option>)}
                                             </Select>
                                        ): (
                                             <Select
                                                  mode="multiple"
                                                  style={{ width: '100%' }}
                                                  placeholder="Se han seleccionado todos los servicios"
                                                  defaultValue={this.state.todosServiciosNombre}
                                                  disabled={true}
                                             />
                                        ) }
                                   </Form.Item>                                  
                              </Col>
                         ): false}

                         { !this.state.checkedAlmacen || this.state.almacenesSeleccionadosLocal[0] ? (
                              <Col span={2}>
                                   <Form.Item
                                        label=' '
                                        name='todosServicios'
                                   >
                                        <Switch
                                             checkedChildren={'TODOS'}
                                             unCheckedChildren={'TODOS'}
                                             defaultChecked={false}
                                             checked={!this.state.checkedServicio}
                                             disabled={!listaServiciosbyAlmacen[0]}
                                             size={'default'}
                                             onChange={todosServicios}
                                             
                                        />
                                   </Form.Item>
                              </Col>   
                         ): false}                                              
                    </Row>

                    <Row justify='space-around' style={{ textAlign: 'left' }} gutter={[8,8]}>

                         { !this.state.checkedServicio || this.state.serviciosSeleccionadosLocal[0] ? (  
                         <Col span={22}>
                              <Form.Item
                              label='Familias'
                              name='familia'
                              rules={[{ required: this.state.checkedFamilia, message: 'Favor de llenar el campo', }]}
                              >
                                   { this.state.checkedFamilia ? (
                                        <Select
                                             mode="multiple"
                                             style={{ width: '100%' }}
                                             placeholder="Selecciona una o varias familias"
                                             loading={fetchingListaFamiliasbyServicio}
                                             onChange={onFamilia}
                                        >
                                             {listaFamiliasbyServicio.map(option => <Option key={option.idFamilia}>{option.familia}</Option>)}
                                        </Select>
                                   ):(
                                        <Select
                                             mode="multiple"
                                             style={{ width: '100%' }}
                                             placeholder="Se han seleccionado todas las familias"
                                             defaultValue={this.state.todasFamiliasNombre}
                                             disabled={true}
                                        />
                                   )}
                              </Form.Item>
                         </Col>
                         ): false } 

                         { !this.state.checkedServicio || this.state.serviciosSeleccionadosLocal[0] ? (
                         <Col span={2}>
                              <Form.Item
                                   label=' '
                                   name='todasFamilias'
                              >
                                   <Switch
                                        checkedChildren={'TODAS'}
                                        unCheckedChildren={'TODAS'}
                                        defaultChecked={false}
                                        checked={!this.state.checkedFamilia}
                                        disabled={!listaFamiliasbyServicio[0]}
                                        size={'default'}
                                        onChange={todasFamilias}
                                   />
                              </Form.Item>
                         </Col>
                         ): false }

                         { !this.state.checkedFamilia || this.state.familiasSeleccionadasLocal[0] ? (
                         <Col span={22}>
                              <Form.Item
                              label='Subfamilias'
                              name='subfamilia'
                              rules={[{ required: this.state.checkedSubfamilia, message: 'Favor de llenar el campo', }]}
                              >
                                   { this.state.checkedSubfamilia ? (
                                        <Select
                                             mode="multiple"
                                             style={{ width: '100%' }}
                                             placeholder="Selecciona una o varias subfamilias"
                                             loading={fetchingListaSubfamiliasbyFamilia}
                                             onChange={onSubfamilias}
                                        >
                                             {listaSubfamiliasbyFamilia.map(option => <Option key={option.idSubFamilia}>{option.subFamilia}</Option>)}
                                        </Select>
                                   ):(
                                        <Select
                                             mode="multiple"
                                             style={{ width: '100%' }}
                                             placeholder="Se han seleccionado todas las subfamilias"
                                             defaultValue={this.state.todasSubfamiliasNombre}
                                             disabled={true}
                                        />
                                   )}
                              </Form.Item>
                         </Col>
                         ): false }

                         { !this.state.checkedFamilia || this.state.familiasSeleccionadasLocal[0] ? (
                         <Col span={2}>
                              <Form.Item
                                   label=' '
                                   name='todasSubfamilias'
                              >
                                   <Switch
                                        checkedChildren={'TODAS'}
                                        unCheckedChildren={'TODAS'}
                                        defaultChecked={false}
                                        disabled={!listaSubfamiliasbyFamilia[0]}
                                        checked={!this.state.checkedSubfamilia}
                                        size={'default'}
                                        onChange={todasSubfamilias}
                                   />
                              </Form.Item>
                         </Col>
                         ): false }    
                    </Row>

                    <Row justify="space-around" gutter={[8,8]}>
                              <Col span={8}>
                                   <Form.Item
                                   label='Rango de Costo Menor'
                                   name='rangoMenor'
                                   rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                   >
                                        <InputNumber
                                             formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                             parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                             onChange={changeDecimalesMenor}
                                             min={0}
                                             className='input'
                                        />
                                   </Form.Item>
                              </Col>

                              <Col span={8}>
                                   <Form.Item
                                   label='Rango de Costo Mayor'
                                   name='rangoMayor'
                                   rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                   > 
                                        <InputNumber
                                             formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                             parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                             onChange={changeDecimalesMayor}
                                             className='input'
                                             min={0}
                                        />
                                   </Form.Item>
                              </Col>

                              <Col span={8}>
                                   <Form.Item
                                   label='Porcentaje de uso'
                                   name='porcentaje'
                                   rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                   >
                                        
                                        <InputNumber
                                             min={0}
                                             max={100}
                                             formatter={value => `${value}%`}
                                             parser={value => value.replace('%', '')}
                                             className='input'
                                        />
                                   </Form.Item>
                              </Col>
                         </Row>                
                         
                    <Divider/>

                    <Button className="btnPrueba" style={{ width: '40%' }} htmlType="submit">
                         Seleccionar Lista de Productos
                    </Button>

                    <ModalProductos/> 
               </Form>
          </Card>         
          );
     }
}

const mapStateToProps = state => {
     return {
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          fetchingListaAlmacenesActivos: state.AlmacenesReducer.fetchingListaAlmacenesActivos,
          listaAlmacenesActivos: state.AlmacenesReducer.listaAlmacenesActivos,
          fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
          listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          listaServiciosbyAlmacen: state.AlmacenesReducer.listaServiciosbyAlmacen,
          fetchingListaServiciosbyAlmacen: state.AlmacenesReducer.fetchingListaServiciosbyAlmacen,
          fetchingListaFamiliasbyServicio: state.AlmacenesReducer.fetchingListaFamiliasbyServicio,
          listaFamiliasbyServicio: state.AlmacenesReducer.listaFamiliasbyServicio,
          fetchingListaSubfamiliasbyFamilia: state.AlmacenesReducer.fetchingListaSubfamiliasbyFamilia,
          listaSubfamiliasbyFamilia: state.AlmacenesReducer.listaSubfamiliasbyFamilia,

     };
};
const mapDispatchToProps = dispatch => {
	return {
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
          onGetAlmacenesActivos: () => {
               dispatch({ type: 'ALMACENES_ACTIVOS_BUSQUEDA_REQUEST' })
          },
          onListaServiciosFiltro: (busqueda) => {
               dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
          },
          onListaServiciosFiltroLimpiar: () => {
               dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
          },
          onGetServiciosActivos: () => {
               dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST" });
          },
          onGetServiciosAlmacen: (todosAlmacenes, almacenesSeleccionados, listaAlmacenesActivos, todosAlmacenesSeleccionados) => {
               dispatch({ type: 'LISTA_SERVICIOS_ALMACEN_REQUEST', todosAlmacenes, almacenesSeleccionados, listaAlmacenesActivos, todosAlmacenesSeleccionados })
          },
          onLimipiarServiciosAlmacen: () => {
               dispatch({ type: 'LISTA_SERVICIOS_ALMACEN_SUCCESS', listaServiciosbyAlmacen: []});
          },
          onGetFamiliasServicio: (todosServicios, serviciosSeleccionados, listaServiciosbyAlmacen, todosServiciosSeleccionados) => {
               dispatch({ type: 'LISTA_FAMILIAS_SERVICIO_REQUEST', todosServicios, serviciosSeleccionados, listaServiciosbyAlmacen, todosServiciosSeleccionados })
          },
          onLimpiarFamiliasServicio: () => {
               dispatch({ type: 'LISTA_FAMILIAS_SERVICIO_SUCCESS', listaFamiliasbyServicio: []});
          },
          onGetSubfamiliasFamilia: (todasFamilias, familiasSeleccionadas, listaFamiliasbyServicio, todasFamiliasSeleccionadas) => {
               dispatch({ type: 'LISTA_SUBFAMILIAS_FAMILIA_REQUEST', todasFamilias, familiasSeleccionadas, listaFamiliasbyServicio, todasFamiliasSeleccionadas })
          },
          onLimpiarSubfamiliasFamilia: () => {
               dispatch({ type: 'LISTA_SUBFAMILIAS_FAMILIA_SUCCESS', listaSubfamiliasbyFamilia: [] });
          },
          onModalProductos: (almacenesSeleccionados, serviciosSeleccionados, familiasSeleccionadas, subfamiliasSeleccionadas, rangoMayor, rangoMenor, porcentaje) => {
               dispatch({ type: "SHOW_MODAL_INVENTARIO_CICLICO", showModalInventarioCiclico: true, almacenesSeleccionados, serviciosSeleccionados, familiasSeleccionadas, subfamiliasSeleccionadas, rangoMayor, rangoMenor, porcentaje });
          },
          onProductosCiclico: (subfamiliasSeleccionadas, almacenesSeleccionados, porcentaje, rangoMayor, rangoMenor,page) => {
               dispatch({ type: 'LISTA_INVENTARIO_CICLICO_REQUEST', subfamiliasSeleccionadas, almacenesSeleccionados, porcentaje, rangoMayor, rangoMenor, page })
          }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InventarioCiclicoContainer);