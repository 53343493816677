import React, { useRef, useState, useEffect } from 'react';
import {connect} from 'react-redux';
import {Modal, Row, Pagination, Table} from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css'

const ModalErroresCM = props => {
    const [page, setPage] = useState(1)
    const {showModalErroresPIH, onShowTablaErrores, listInfoErroresCargaMasiva, fetchingErroresCargaMasiva, listErroresCargaMasiva } = props;

    const columns = [
        {
          title: 'Campo',
          dataIndex: 'campo',
          key: 'campo',
        },
        {
            title: 'Valor',
            dataIndex: 'valor',
            key: 'valor',
        },
        {
          title: 'Error',
          dataIndex: 'errorFila',
          key: 'errorFila',
        },
        {
          title: 'Fila',
          dataIndex: 'fila',
          key: 'fila',
        },
    ]

    const handleTableChange = (value) =>{
        setPage(page+value-1)
        console.log("value", value, page)
    }
    
    console.log("listInfoErroresCargaMasiva",listInfoErroresCargaMasiva)
   
    return (
        <Row>
        
            <Modal
                visible={showModalErroresPIH}
                title='Errores Carga Masiva'
                onCancel={onShowTablaErrores}
                footer={false}
                width='90%'
               >
                    <Table 
                        size="small"
                        rowKey="erroresIDH"
                        columns={columns}
                        dataSource={listErroresCargaMasiva}
                        loading={fetchingErroresCargaMasiva}
                        pagination={false}
                        locale={{ emptyText: 'El archivo no contiene errores. No hay necesidad de cambios.' }}
                    />
                    {listErroresCargaMasiva[0]&&
                        <>
                            <br/>
                            {listInfoErroresCargaMasiva.pageable.pageNumber>0 &&
                                <Pagination simple total={listInfoErroresCargaMasiva.pageable.offset} current={page} onChange={handleTableChange} /> 
                            }
                        </>
                    }
            </Modal>
        </Row>
    )
}

const mapStateToProps = state => {
     return {
        showModalErroresPIH: state.AlmacenesReducer.showModalErroresPIH,
        fetchingErroresCargaMasiva: state.AlmacenesReducer.fetchingErroresCargaMasiva,
        listInfoErroresCargaMasiva: state.AlmacenesReducer.listInfoErroresCargaMasiva,
        listErroresCargaMasiva: state.AlmacenesReducer.listErroresCargaMasiva
     }
}

const mapDispatchToProps = dispatch => {
     return {
        onShowTablaErrores: () =>{
            dispatch({ type: "SHOW_MODAL_ERRORES_CARGA_PIH", showModalErroresPIH:false})
        }
          
     }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalErroresCM);