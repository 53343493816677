import axios from "axios";

//const endpoint = "https://papantla-services.herokuapp.com/";
//const endpoint = "https://papantla-dev.herokuapp.com/";
const endpoint = 'https://morelos-dev.herokuapp.com/';
//const endpoint = "https://papantla-demo.herokuapp.com/";

//const endpoint = "http://172.24.0.170:8080/"

//const endpoint = 'https://papantla-services-jose.herokuapp.com/';
//const endpoint = 'https://papantla-dev-jose.herokuapp.com/';

//const endpoint = 'https://papantla-secret-jose.herokuapp.com/';
//const endpoint = 'https://papantla-secret.herokuapp.com/';

export function loginCall(loginInfo) {
	return axios({
	    method: "post",
	    url: endpoint+"login", 
	    data: {
		    username: loginInfo.userName,
		    password: loginInfo.password
		}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function usuarioDetalleCall(token) {
	console.log(endpoint+"usuarios/usuario")
	return axios({
	    method: "get",
	    url: endpoint+"usuarios/usuario",
	    headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function recuperarContraseñaCall(email) {
	console.log('email');
	console.log('usuarios/resetPassword?email=' + email);
	return axios({
	    method: "get",
	    url: endpoint+"usuarios/resetPassword?email=" + email, 
	    headers: {'Content-Type': 'application/json',}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
export function cerrarSesionCall(token) {
	return axios({
	    method: "get",
	    url: endpoint+"usuarios/logout", 
	    headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
