import React from 'react';
import { Row, Col, DatePicker, Table, Button, Form, Tooltip, message, Select } from 'antd';
import { SearchOutlined, FileSearchOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import moment from 'moment';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import '../../../stylesheets/RecursosHumanos/AltaPersonal.css';
import ModalDetalleReporte from './ModalDetalleReporte';


const { Option } = Select;
const { RangePicker } = DatePicker;
class ReporteContainer extends React.Component {
    componentDidMount(){
        this.props.onGetListaReportes(1, this.state.busqueda);
        this.props.onRequestInfoProyecto();
    }
    componentWillUnmount(){
        this.props.onListaServiciosFiltroLimpiar();
    }
    formRef = React.createRef();
    state={
        page: 1,
        busqueda: {
            fechaInicial: null,
            fechaFinal: null,
            servicio: [],
            estatus: []
        }
    }
    render() {
        const { fetchingReporteBusqueda, listaReporteBusqueda, fetchingExportarReporteExcel,
            onGetListaReportes, listaReporteBusquedaCantidad, onExportarExcel, onListaServiciosFiltro, 
            onListaServiciosFiltroLimpiar, fetchingListaServiciosFiltro, listaServiciosFiltro,
            fetchingExportarReportePDF, listaInfoProyecto, onExportarPDF, onShowModalDetalleReporte,
            onGetTrazabilidadFolio, 
        } = this.props;
        
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        const onFinish = (formulario) => {
            this.setState({
                busqueda: formulario,
                page: 1,
            })
            const rangeValue = formulario['rangoFechas'];
            if(rangeValue){
                formulario.fechaInicial = rangeValue[0].format('YYYY-MM-DD') 
                formulario.fechaFinal = rangeValue[1].format('YYYY-MM-DD');
            }
            else{
                formulario.fechaInicial = null;
                formulario.fechaFinal = null;
            }
            
            console.log(formulario);
            onGetListaReportes(1, formulario);
        }

        const columns = [{
                title: 'Servicio',
                dataIndex: 'servicio',
                key: 'servicio',
            },{
                title: 'Folio',
                dataIndex: 'folio',
                key: 'folio',
            },{
                title: 'Descripción de la falla',
                dataIndex: 'descripcionSolicitud',
                key: 'descripcionSolicitud',
            },{
                title: 'Ubicación',
                dataIndex: 'ubicacion',
                key: 'ubicacion',
            },{
                title: 'Usuario que reporta',
                dataIndex: 'nombreUsuario',
                key: 'nombreUsuario',
            },{
                title: 'Fecha de alta',
                dataIndex: 'fechaAlta',
                key: 'fechaAlta',
            },{
                title: 'Estado del folio',
                dataIndex: 'estado',
                key: 'estado',
            },{
                title: 'Actividades',
                dataIndex: 'actividadesRealizadas',
                key: 'actividadesRealizadas',
            },{
                title: 'Fecha de realizado',
                dataIndex: 'fechaTerminacion',
                key: 'fechaTerminacion',
            },{
                title: 'Fecha de cierre',
                dataIndex: 'fechaCierre',
                key: 'fechaCierre',
            },{
                title: 'Ver detalle',
                dataIndex: 'detalle',
                key: 'detalle',
                width: '5%',
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.verDetalleDeReporteDeSolicitudDeServicio} onClick={() => onDetalle(record)} ><FileSearchOutlined /></a>
                ),
            }
        ];

        const onDetalle = (key) => {
            console.log('key');
            console.log(key);
            onShowModalDetalleReporte(key);
            onGetTrazabilidadFolio(key.folio, 1);
        }

        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            
            onGetListaReportes(pagination.current, this.state.busqueda);
        }
        

        const buscarServicios = value => {
            if (value.length >= 3) {
                if (value) {
                    console.log(value);
                    onListaServiciosFiltro(value)
                } else {
                    message.warning('No se encontró servicio con esas palabras')
                }
            } else {
                onListaServiciosFiltroLimpiar();
            }
        }

        const exportarExcel = () => {
            onExportarExcel(this.state.busqueda );
        }
        const exportarPDF = () => {
            onExportarPDF(this.state.busqueda );
        }
        
        const disabledRange = fecha => {
            return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicio;
        }
        const fechas = (rangeValue) => {
            console.log(`rangeValue`, rangeValue)
            if(listaInfoProyecto.fechaFin){
                if(rangeValue){
                    console.log(moment(listaInfoProyecto.fechaFin, 'YYYY-MM-DD'), moment(rangeValue[1].format('YYYY-MM-DD')));
                    const diferencia = moment(listaInfoProyecto.fechaFin, 'YYYY-MM-DD').diff(moment(rangeValue[1].format('YYYY-MM-DD')), "days");
                    console.log('diferencia');
                    console.log(diferencia);
                    if(diferencia < 0){
                        message.error('No es posible seleccionar una fecha de fin mayor a la del proyecto (' + listaInfoProyecto.fechaFin.format('YYYY-MM-DD').split('-').reverse().join('-') + ')', 7);
                        this.formRef.current.setFieldsValue({'rangoFechas': [rangeValue[0], null]});
                    }
                }
            }
            else{
                message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                this.formRef.current.setFieldsValue({'rangoFechas': [null, null]});
            }
        };
        const scroll = {"x": 2500 | true, "y": 1000};

        return (
            <div>
                <Row justify='space-between'>
                    <Col span={24}>
                        <Form
                        name="formulario"
                        onFinish={onFinish}
                        initialValues={this.state.filtros}
                        ref={this.formRef}
                        >
                            <Row justify='space-around'>
                                <Col span={7}>
                                    <Tooltip title='Ingresa servicio a buscar'>
                                        <Form.Item
                                            name="servicios"
                                        >
                                            <Select
                                                mode='multiple'
                                                allowClear
                                                style={{ width: '100%', textAlign: 'left' }}
                                                placeholder="Ingresa el servicio a buscar"
                                                loading={fetchingListaServiciosFiltro}
                                                showSearch
                                                filterOption={false}
                                                onSearch={buscarServicios}
                                                notFoundContent={null}
                                            >
                                                {listaServiciosFiltro.map(option => <Option key={option.idServicio + '-' + option.nomenclatura + '_' + option.nombre}>{option.idServicio + '-' + option.nomenclatura + '_' + option.nombre}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={7}>
                                    <Tooltip title='Ingresa el rango de fechas a buscar' >
                                        <Form.Item
                                            name="rangoFechas"
                                        >
                                            <RangePicker format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio", "Ingresa fecha fin"]} disabledDate={disabledRange} onChange={fechas} />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={7}>
                                    <Tooltip title='Ingresa el estado a buscar'>
                                        <Form.Item
                                            name="estatus"
                                        >
                                            <Select
                                                mode='multiple'
                                                allowClear
                                                style={{ width: '100%', textAlign: 'left' }}
                                                placeholder="Ingresa el estado a buscar"
                                                showSearch
                                                notFoundContent={null}
                                            >
                                                <Option key='Generada'>Abierto</Option>
                                                <Option key='Abierto'>Asignado</Option>
                                                <Option key='En Proceso'>En Proceso</Option>
                                                <Option key='Reasignacion'>Reasignacion</Option>
                                                <Option key='En espera'>En espera</Option>
                                                <Option key='Terminada'>Terminada</Option>
                                                <Option key='Cerrado'>Cerrado</Option>
                                                <Option key='Cancelado'>Cancelado</Option>
                                                
                                            </Select>
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={2}>
                                    <Button shape="circle" icon={<SearchOutlined />} className="btnFiltrar" htmlType="submit"></Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    
                    <Col span={24}>
                        <br/>
                        <Table 
                            size="small" 
                            rowKey="idFalla" 
                            columns={columns}
                            dataSource={listaReporteBusqueda}
                            loading={fetchingReporteBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaReporteBusquedaCantidad, simple: true, current: this.state.page}}
                            scroll={scroll}
                        /> 
                    </Col>
                </Row>
                <Row justify='space-around'>
                    <Col span={11}>
                        <Button loading={fetchingExportarReporteExcel} disabled={!permisosUsuario.descargarExcelReporteSolicitudDeServicio ? true : !listaReporteBusqueda[0]} className='btnCargaArchivos' onClick={exportarExcel}>Exportar a Excel</Button>
                    </Col>
                    <Col span={11}>
                        <Button loading={fetchingExportarReportePDF} disabled={!permisosUsuario.descargarPdfReporteSolicitudDeServicio ? true : !listaReporteBusqueda[0]} className='btnCargaArchivos' onClick={exportarPDF}>Exportar a PDF</Button>
                    </Col>
                </Row>
                <ModalDetalleReporte/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaReporteBusqueda: state.SolicitudDeServiciosReducer.listaReporteBusqueda,
        fetchingReporteBusqueda: state.SolicitudDeServiciosReducer.fetchingReporteBusqueda,
        listaReporteBusquedaCantidad: state.SolicitudDeServiciosReducer.listaReporteBusquedaCantidad,
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
        fetchingExportarReporteExcel: state.SolicitudDeServiciosReducer.fetchingExportarReporteExcel,
        fetchingExportarReportePDF: state.SolicitudDeServiciosReducer.fetchingExportarReportePDF,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetListaReportes: (page, busqueda) => {
	    	dispatch({ type: "LISTA_REPORTE_BUSQUEDA_REQUEST", page, busqueda });
        },
        onExportarExcel: (busqueda) => {
            dispatch({ type: "EXPORTAR_EXCEL_REPORTE_BUSQUEDA_REQUEST", busqueda });
        },
        onExportarPDF: (busqueda) => {
            dispatch({ type: "EXPORTAR_PDF_REPORTE_BUSQUEDA_REQUEST", busqueda });
        },
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
        },
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        },
        onShowModalDetalleReporte: (reporteSeleccionado) => {
            dispatch({ type: 'SHOW_MODAL_DETALLE_REPORTE', showModalDetalleReporte: true, reporteSeleccionado });
        },
        onGetTrazabilidadFolio: (folio, page) => {
            dispatch({ type: "OBTENER_TRAZABILIDAD_FOLIO_REQUEST", folio, page});
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ReporteContainer);