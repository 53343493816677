import React, { Component } from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import '../../stylesheets/Login/LoginForm.css';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class Login extends Component {
    
    render() {
        const { fetchingLogin, onRequestLogin, onAbrirModal } = this.props;

        const onFinish = values => {
            console.log('Success:', values);
            onRequestLogin(values);
        };
        
        
        const resetPassword = (e) => {
            onAbrirModal();
        }

        return (
            <Form
                {...layout}
                name="login"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                
                >
                <Form.Item
                    label="Usuario"
                    name="userName"
                    rules={[{ required: true, message: 'Favor de utilizar un correo válido', type: 'email'}]}
                >
                    <Input prefix={<UserOutlined/>} placeholder="ejemplo@appcontroller.com"/>
                </Form.Item>

                <Form.Item
                    label="Contraseña"
                    name="password"
                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                >
                    <Input.Password prefix={<LockOutlined/>} placeholder="Ingresa contraseña" />
                </Form.Item>
                <Form.Item>
                    <Row justify='end'>
                        {/* <Col span={24}>
                            <a onClick={resetPassword}><p className="resetPassword"> <strong>¿Olvidaste tu contraseña?</strong></p></a>
                        </Col> */}
                        <Col span={8}>
                            <Button className='btnLogin' htmlType="submit" loading={fetchingLogin}>
                                Ingresar
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        );
    }
}

const mapStateToProps = state => {
	return {
	    fetchingLogin: state.LoginReducer.fetchingLogin,
	    usuario: state.LoginReducer.usuario,
	    errorLogin: state.LoginReducer.errorLogin,
	};
};

const mapDispatchToProps = dispatch => {
	return {
	  	onRequestLogin: (loginInfo) => {
	    	dispatch({ type: "LOGIN_API_CALL_REQUEST", loginInfo:loginInfo });
        },
        onAbrirModal: () => {
            dispatch({ type: "MODAL_RESET_PASSWORD" , showModalReset: true});
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);