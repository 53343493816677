import React, { Component } from 'react';
import { Form, Input, Button, Col, Spin, Modal, InputNumber, message, DatePicker } from 'antd';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import moment from 'moment';


const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalCrearMesCalendario extends Component {
    formRef = React.createRef();
    render() {
        const { 
            fetchingCrearMesCalendario, showModalCrearMesCalendario, onCrearMesCalendario, 
            onShowModalCrearMesCalendario, limpiarFormulario, listaInfoProyecto, fechaAnterior,
        } = this.props;
        
        const onFinish = formulario => {
            console.log('Success:', formulario);
            const rangeValue = formulario['fechaPubInpc'];
            formulario.fechaPubInpc = rangeValue.format('YYYY-MM-DD');
            onCrearMesCalendario(formulario, this.props.busqueda, fechaAnterior, listaInfoProyecto, this.props.listaMesesCalendario);
        };

        console.log('limpiar formulario', limpiarFormulario);
        
        if (limpiarFormulario && limpiarFormulario === 'crearMesCalendario') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }
            
        const handleCancel = () => {
            onShowModalCrearMesCalendario();
            this.formRef.current.resetFields();
        }

        const fechas = (rangeValue) => {
            if(listaInfoProyecto.fechaFin){
                const diferencia = moment(rangeValue.format('YYYY-MM-DD') + ' 00:00:00').diff(moment(listaInfoProyecto.fechaFin.format('YYYY-MM-DD') + ' 00:00:00'), "days");
                console.log('diferencia', diferencia);
                if(diferencia > 0){
                    message.error('No es posible seleccionar una fecha de publicación mayor al final de contrato del proyecto', 7);
                    this.formRef.current.resetFields(['fechaPubInpc']);
                }
            }
            else{
                message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                this.formRef.current.resetFields(['fechaPubInpc']);
            }
        }
        const disabledRange = fecha => {
            return fecha && moment(fecha.format('YYYY-MM-DD')) < moment(fechaAnterior);
        }

        return (
            <Modal
            visible={showModalCrearMesCalendario}
            title='Nuevo Mes Calendario'
            onCancel={handleCancel}
            footer={false}
            >
               {listaInfoProyecto ? (
                <Spin spinning={fetchingCrearMesCalendario}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    ref={this.formRef}
                    >
                        <Form.Item
                            label="INPC i-1"
                            name="inpc"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <InputNumber min={0} placeholder="Ingresa el INPC i-1"
                                step={1.000} 
                            />
                        </Form.Item>

                        <Form.Item
                            label="Fecha de publicación INPC"
                            name="fechaPubInpc"
                            rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                        >
                            <DatePicker
                                disabledDate={disabledRange} showToday={false}
                                style={{width: '100%'}} format='DD-MM-YYYY' onChange={fechas} placeholder={"Ingresa fecha de publicación INPC"}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Fuente de publicación INPC"
                            name="fuentePubInpc"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Input maxLength={250} placeholder="Ingresa la fuente de publicación INPC" />
                        </Form.Item>
                        
                        <Col span={24}>
                            <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Crear Mes Calendario</Button>
                        </Col>
                    </Form>
                </Spin>
                ):<h1>Favor de crear un proyecto en el módulo de 'Información del proyecto'</h1>}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingCrearMesCalendario: state.MecanismosDePagosReducer.fetchingCrearMesCalendario,
        showModalCrearMesCalendario: state.MecanismosDePagosReducer.showModalCrearMesCalendario,
        busqueda: state.MecanismosDePagosReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        fechaAnterior: state.MecanismosDePagosReducer.fechaAnterior,
        listaMesesCalendario: state.MecanismosDePagosReducer.listaMesesCalendario
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalCrearMesCalendario: () => {
            dispatch({ type: 'SHOW_MODAL_CREAR_MES_CALENDARIO', showModalCrearMesCalendario: false})
        },
        onCrearMesCalendario: ( formulario, busqueda, fechaAnterior, listaInfoProyecto, listaMesesCalendario ) => {
            dispatch({ type: "CREAR_MES_CALENDARIO_REQUEST" , formulario, busqueda, fechaAnterior, listaInfoProyecto, listaMesesCalendario });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCrearMesCalendario);