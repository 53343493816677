import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col, Spin, Modal, Select, Table, message } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const { Option } = Select;

const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };

const ModalAsignarActivos = (props) => {
    const [form] = Form.useForm();

    useEffect(() => {
        props.onGetUsuarioRes();
        props.onGetAreasFuncionalesEx(1);  
        if(props.showAlertMsgEx===true){
            message.warning('El área no cuenta con interiores funcionales, por favor elije un área o unidad distinta');
        }
       
    },[props.showAlertMsgEx]);

    const [idAreaFuncional, setIdAreaFuncional] = useState(null);
    const [idUnidadFuncional, setIdUnidadFuncional] = useState(null);
    const [idInteriorFuncional, setIdInteriorFuncional] = useState(null);

    const { 
            showModalAsignarActivos, 
            onNuevaAsignacionDeActivos, 
            onShowModalAsignarActivos, 
            limpiarFormulario,
            fetchingNuevaAsignacionActivos,
            fetchingListaUsuariosActivos,
            listaUsuariosActivos,
            AsignacionActivoSeleccionado,

            fetchingListaAreasFuncionalesActivasEx,
            listaAreasFuncionalesActivasEx,
            fetchingListaUnidadesFuncionalesActivasEx,
            listaUnidadesFuncionalesActivasEx,
            fetchingListaInteriorFuncionalEx,
            listaInteriorFuncionalEx,
            fetchingListaSubinteriorFuncionalEx,
            listaSubinteriorFuncionalEx,

            onGetUnidadesFuncionalesEx,
            onGetInteriorEx,
            onGetSubinteriorEx,
            showAlertMsgEx

    } = props;

    let userInfo = sessionStorage.getItem('userInfo');
    if(!userInfo.idUsuario){ userInfo = JSON.parse(userInfo); }

    console.log("listaUnidadesFuncionalesActivasEx",!listaUnidadesFuncionalesActivasEx[0])

    const onFinish = formulario => {
        onNuevaAsignacionDeActivos(formulario, AsignacionActivoSeleccionado, props.page);
    };

    if (limpiarFormulario && limpiarFormulario === 'crearAsignacionActivos') {
        props.onLimpiarFormulario();
        props.onLimpiarTablaAsignacion();
        form.resetFields();
    }

    const handleCancel = () => {
        onShowModalAsignarActivos();
        form.resetFields();
    }

    //Excluyendo ubicación anterior.
    const onUnidadesFuncionalesEx = (idAreaFuncional) => {
        form.resetFields(['idUnidadFuncional', 'idInteriorFuncional', 'idSubInteriorFuncional']);
        onGetUnidadesFuncionalesEx(AsignacionActivoSeleccionado[0].idUbicacion, idAreaFuncional);
        setIdAreaFuncional(idAreaFuncional)
    }

    const onInterioresFuncionalesEx = (idUnidadFuncional) => {
        form.resetFields(['idInteriorFuncional', 'idSubInteriorFuncional']);
        onGetInteriorEx(AsignacionActivoSeleccionado[0].idUbicacion, idAreaFuncional, idUnidadFuncional);
        setIdUnidadFuncional(idUnidadFuncional)
    }

    const onSubInterioresFuncionalesEx = (idInteriorFuncional) => {
        form.resetFields(['idSubInteriorFuncional']);
        onGetSubinteriorEx(AsignacionActivoSeleccionado[0].idUbicacion,idAreaFuncional,idUnidadFuncional, idInteriorFuncional);
        setIdInteriorFuncional(idInteriorFuncional)
    }

    const columns = [
        {title: 'Descripción', dataIndex: 'descripcion', key: 'descripcion'},,
        {title: 'Código de Equipo', dataIndex: 'codigoEquipo', key: 'codigoEquipo',},
        {title: 'Código Interno',dataIndex: 'codigoInterno',key: 'codigoInterno',},
        {title: 'Num Serie',dataIndex: 'serieNum',key: 'serieNum',},
        {title: 'SKU',dataIndex: 'sku',key: 'sku',},
        {title: 'Clave Ubicación',dataIndex: 'claveUbicacion',key: 'claveUbicacion',},
        {title: 'Área Funcional',dataIndex: 'areaFuncional',key: 'areaFuncional',},
        {title: 'Unidad Funcional',dataIndex: 'unidadFuncional',key: 'unidadFuncional',},
        {title: 'Interior',dataIndex: 'interiorFuncional',key: 'interiorFuncional',},
        {title: 'Subinterior',dataIndex: 'subInteriorFuncional',key: 'subInteriorFuncional',}
    ];

    return (
        <Modal
        visible={showModalAsignarActivos}
        title='Reasignación y Reubicación del Activo'
        onCancel={handleCancel}
        width='90%'
        footer={false}
        >
            <Spin spinning={fetchingNuevaAsignacionActivos}>
                <Form {...layout}
                name="formulario"
                onFinish={onFinish}
                form={form}
                >
                    <Row justify='space-around'>
                        <Col span={11}>
                            <Form.Item
                                label="Área Funcional"
                                name="idAreaFuncional"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Seleccione el área funcional"
                                    loading={fetchingListaAreasFuncionalesActivasEx}
                                    onChange={onUnidadesFuncionalesEx}
                                    showSearch
                                    optionFilterProp="children"
                                >
                                    {listaAreasFuncionalesActivasEx.map(option => <Option key={option.idAreaFuncional}>{ option.areaFuncional }</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="Unidades Funcionales"
                                name="idUnidadFuncional"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Seleccione el unidad funcional"
                                    loading={fetchingListaUnidadesFuncionalesActivasEx}
                                    onChange = {onInterioresFuncionalesEx}
                                    disabled={!listaUnidadesFuncionalesActivasEx[0]}
                                    showSearch
                                    optionFilterProp="children"
                                >
                                    {listaUnidadesFuncionalesActivasEx.map(option => <Option key={option.idUnidadFuncional}>{ option.unidadFuncional }</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="Interior"
                                name="idInteriorFuncional"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Seleccione el interior funcional"
                                    loading={fetchingListaInteriorFuncionalEx}
                                    onChange = {onSubInterioresFuncionalesEx}
                                    disabled={!listaInteriorFuncionalEx[0] && showAlertMsgEx}
                                    showSearch
                                    optionFilterProp="children"
                                >
                                    {listaInteriorFuncionalEx.map(option => <Option key={option.idInteriorFuncional}>{ option.interiorFuncional }</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="Subinterior"
                                name="idSubInteriorFuncional"
                            >
                                <Select allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Seleccione el subinterior funcional"
                                    loading={fetchingListaSubinteriorFuncionalEx}
                                    disabled={!listaSubinteriorFuncionalEx[0]}
                                    showSearch
                                    optionFilterProp="children"
                                >
                                    {listaSubinteriorFuncionalEx.map(option => <Option key={option.idSubInteriorFuncional}>{ option.subInteriorFuncional }</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={23}>
                            <Form.Item
                                label="Activos Seleccionados"
                            >
                                <Table 
                                    size="small" 
                                    rowKey="idActivo" 
                                    columns={columns}
                                    dataSource={AsignacionActivoSeleccionado}        
                                    locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar'}}
                                    pagination={false}
                                    scroll={{ x: 1600 }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="Usuario Responsable"
                                name="idUsuario"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Seleccione el usuario responsable"
                                    loading={fetchingListaUsuariosActivos}
                                    showSearch
                                    optionFilterProp="children"
                                >
                                    {listaUsuariosActivos.map(option => <Option key={option.idUsuario}>{ option.nombre + " " + option.aPaterno + ": " + option.username}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={11}>
                            <br/> <br/>
                            <Button htmlType='submit' className="nuevoUsuario" style={{width: '100%'}}>Reasignar Activo</Button> 
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
}

const mapStateToProps = state => {
	return {
            fetchingNuevaAsignacionActivos: state. AlmacenesReducer.fetchingNuevaAsignacionActivos,
            showModalAsignarActivos: state.AlmacenesReducer.showModalAsignarActivos,
            page: state.AlmacenesReducer.page,
            limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
            listaUsuariosActivos: state.AlmacenesReducer.listaUsuariosActivos,
            fetchingListaUsuariosActivos: state.AlmacenesReducer.fetchingListaUsuariosActivos,
            listaAsignacionActivosActivos: state.AlmacenesReducer.listaAsignacionActivosActivos,
            fetchingListaAsignacionActivosActivos: state.AlmacenesReducer.fetchingListaAsignacionActivosActivos,
            listaAsignacionActivosBusqueda: state.AlmacenesReducer.listaAsignacionActivosBusqueda,
            fetchinglistaAsignacionActivosBusqueda: state.AlmacenesReducer.fetchinglistaAsignacionActivosBusqueda,
            AsignacionActivoSeleccionado: state.AlmacenesReducer.AsignacionActivoSeleccionado,
            
            //Excluyendo ubicación anterior

            fetchingListaAreasFuncionalesActivasEx: state.AlmacenesReducer.fetchingListaAreasFuncionalesActivasEx,
            listaAreasFuncionalesActivasEx: state.AlmacenesReducer.listaAreasFuncionalesActivasEx,
            fetchingListaUnidadesFuncionalesActivasEx: state.AlmacenesReducer.fetchingListaUnidadesFuncionalesActivasEx,
            listaUnidadesFuncionalesActivasEx: state.AlmacenesReducer.listaUnidadesFuncionalesActivasEx,
            listaInteriorFuncionalEx: state.AlmacenesReducer.listaInteriorFuncionalEx,
            fetchingListaInteriorFuncionalEx: state.AlmacenesReducer.fetchingListaInteriorFuncionalEx,
            listaSubinteriorFuncionalEx: state.AlmacenesReducer.listaSubinteriorFuncionalEx,
            fetchingListaSubinteriorFuncionalEx: state.AlmacenesReducer.fetchingListaSubinteriorFuncionalEx,
            listaUsuariosActivosEx: state.AlmacenesReducer.listaUsuariosActivosEx,
            showAlertMsgEx: state.AlmacenesReducer.showAlertMsgEx,

	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalAsignarActivos: () => {
            dispatch({ type: 'SHOW_MODAL_ASIGNACION_ACTIVOS', showModalAsignarActivos: false, AsignacionActivoSeleccionado: null})
        },
        onNuevaAsignacionDeActivos: ( formulario, AsignacionActivoSeleccionado, page ) => {
            dispatch({ type: "NUEVA_ASIGNACION_ACTIVOS_REQUEST" , formulario: formulario, AsignacionActivoSeleccionado: AsignacionActivoSeleccionado, page: page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        },
        onGetUsuarioRes: () => {
            dispatch({ type: 'LISTA_USUARIOS_ACTIVOS_REQUEST', });
        },

        //Excluyendo ubicación anterior

        onGetAreasFuncionalesEx: (idUbicacionAnterior) => {
            dispatch({ type: "LISTA_AREA_FUNCIONAL_ACTIVA_EX_REQUEST", idUbicacionAnterior: idUbicacionAnterior });
        },
        onGetUnidadesFuncionalesEx: (idUbicacionAnterior, idAreaFuncional) => {
            dispatch({ type: "LISTA_UNIDAD_FUNCIONAL_EX_REQUEST", idUbicacionAnterior: idUbicacionAnterior, idAreaFuncional: idAreaFuncional });
        },
        onGetInteriorEx: (idUbicacionAnterior, idAreaFuncional, idUnidadFuncional) => {
            dispatch({ type: 'LISTA_INTERIOR_FUNCIONAL_EX_REQUEST', idUbicacionAnterior: idUbicacionAnterior, idAreaFuncional: idAreaFuncional, idUnidadFuncional: idUnidadFuncional});
        },
        onGetSubinteriorEx: (idUbicacionAnterior, idAreaFuncional, idUnidadFuncional, idInteriorFuncional) => {
            dispatch({ type: 'LISTA_SUBINTERIOR_FUNCIONAL_EX_REQUEST', idUbicacionAnterior: idUbicacionAnterior, idAreaFuncional: idAreaFuncional, idUnidadFuncional: idUnidadFuncional, idInteriorFuncional: idInteriorFuncional});
        },
        onLimpiarTablaAsignacion: () => {
            dispatch({ type: 'ASIGNACION_ACTIVOS_BUSQUEDA_SUCCESS', listaAsignacionActivosBusqueda: [], })
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAsignarActivos);