import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, FileExcelOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css'
import ModalNuevaUnidadDeMedicion from './ModalNuevoCentroDeMedicion';
import ModalEditarUnidadDeMedicion from './ModalEditarCentroDeMedicion';

const confirm = Modal.confirm;
const { Search } = Input;

class CatalogoUnidadesDeMedicion extends React.Component {
    state = {
        page: 1,
        busqueda: '',
    }

    componentDidMount() {
        this.props.onGetUnidadesDeMedicion(1);
    }

    render() {
        const { fetchingListaUnidadesDeMedicionBusqueda, listaUnidadesDeMedicionBusqueda, onGetUnidadesDeMedicion,
            onModalNuevaUnidadDeMedida, onModalEditarUnidadDeMedicion, onEstadoUnidadDeMedicion, listaUnidadesDeMedicionBusquedaCantidad } = this.props;
                
        console.log('ListaUnidadesDeMedicion');
        console.log(listaUnidadesDeMedicionBusqueda);
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const disabledField =['Horas', 'Días','Años', 'Minutos'];

        const columns = [{
            title: 'Unidad de medición',
            dataIndex: 'unidadMedida',
            key: 'unidadMedida',
        }, {
            title: 'Abreviación',
            dataIndex: 'abreviacion',
            key: 'abreviacion',
        }, {
            title: 'Categoría de medición',
            dataIndex: 'idCategoriaUnidadMedida',
            key: 'idCategoriaUnidadMedida',
        },{
            title: 'Activo',
            dataIndex: 'activo',
            key: 'activo',
            align: 'center',
            render: (text, record) => (
                <Switch checked={text} disabled={!permisosUsuario.unidadDeMedicionActivoInactivo || disabledField.includes(record.unidadMedida)} onChange = {() => handleDesactivar(record)} />
            ),
        }, {
            title: 'Editar',
            key: 'editar',
            align: 'center',
            //width: '6%',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.editarUnidadDeMedicion || disabledField.includes(record.unidadMedida)} onClick={() => onEditar(record)}><EditOutlined /></a>
            ),
        },
        ];

        const onBuscarUnidadesDeMedicion = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            });
            console.log(busqueda);
            onGetUnidadesDeMedicion(1, busqueda);
        }

        const handleDesactivar = (key) => {
            console.log(key);
            let page = this.state.page
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado de la unidad de medición "' + key.unidadMedida + '"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoUnidadDeMedicion(key.idUnidadMedida, page);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }

        const onEditar = (key) => {
            onModalEditarUnidadDeMedicion(key, this.state.page);

        }

        const onNuevoUnidadDeMedida = () => {
            onModalNuevaUnidadDeMedida(this.state.page);
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            onGetUnidadesDeMedicion(pagination.current, this.state.busqueda);
        }


        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={20}>
                        <Search className='buscarModulos' placeholder='Ingresar unidad de medición' onSearch={value => onBuscarUnidadesDeMedicion(value)} />
                    </Col>
                    {/* <Col span={9}>
                        <Button onClick={onNuevoUnidadDeMedida} disabled={!permisosUsuario.crearUnidadDeMedicion} className='nuevoUsuario'>Nueva Unidad de Medición</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nueva Unidad de Medición' placement="topRight">
                            <Button className="iconTer" shape="circle"onClick={onNuevoUnidadDeMedida} disabled={!permisosUsuario.crearUnidadDeMedicion} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={1}>
                        <Tooltip title='Exportar archivo .xls' placement="topRight">
                            <Button disabled={!permisosUsuario.exportarExcelCatálogoUnidadesMedición} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } loading={this.props.fetchingExportarExcelUnidadesMedicion} onClick={() => this.props.onExport()}/>
                        </Tooltip>
                    </Col>
                </Row>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={24}>
                        <br />
                        <Table
                            size="small"
                            rowKey="unidadMedida"
                            columns={columns}
                            dataSource={listaUnidadesDeMedicionBusqueda}
                            loading={fetchingListaUnidadesDeMedicionBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                        // scroll={scroll}
                        onChange={handleTableChange}
                        pagination={{ total: listaUnidadesDeMedicionBusquedaCantidad, simple: true, current: this.state.page }}
                        />
                    </Col>
                </Row>
                < ModalNuevaUnidadDeMedicion/>
                <ModalEditarUnidadDeMedicion />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        listaUnidadesDeMedicionBusqueda: state.AlmacenesReducer.listaUnidadesDeMedicionBusqueda,
        fetchingListaUnidadesDeMedicionBusqueda: state.AlmacenesReducer.fetchingListaUnidadesDeMedicionBusqueda,
        listaUnidadesDeMedicionBusquedaCantidad: state.AlmacenesReducer.listaUnidadesDeMedicionBusquedaCantidad,
        fetchingExportarExcelUnidadesMedicion: state.AlmacenesReducer.fetchingExportarExcelUnidadesMedicion,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetUnidadesDeMedicion: (page, busqueda) => {
            dispatch({ type: "UNIDADES_MEDICION_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
        },
        onEstadoUnidadDeMedicion: (idUnidadMedida, page) => {
            dispatch({ type: "ESTADO_UNIDADES_MEDICION_REQUEST", idUnidadMedida: idUnidadMedida, page: page});
        },
        onModalNuevaUnidadDeMedida: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVA_UNIDAD_MEDICION", showModalNuevaUnidadDeMedicion: true, page: page })
        },
        onModalEditarUnidadDeMedicion: (UnidadDeMedicionSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_UNIDAD_MEDICION", showModalEditarUnidadDeMedicion: true, UnidadDeMedicionSeleccionado:UnidadDeMedicionSeleccionado, page:page});
        },
        onExport: () => {
            dispatch({ type: "EXPORTAR_UNIDAD_MEDICION_EXCEL_REQUEST" });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoUnidadesDeMedicion);