import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, Select, message } from 'antd';
import VerificarCorreo from './VerificarCorreo';
import '../../../stylesheets/ConfiguracionYSeguridad/Usuarios.css';
import { connect } from "react-redux";

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalNuevoUsuario extends Component {
    formRef = React.createRef();
    render() {
        const { fetchingNuevoUsuario, modalNuevoUsuario, onNuevoUsuario, onModalNuevoUsuario,
            fetchingServiciosPorProveedor, serviciosPorProveedor, fetchingListaRoles, listaRoles, fetchingListaDominiosActivos,
            verificarCorreo, username, listaProveedoresDominio, onLimpiarFormulario, onServiciosPorProveedor, idDominio, formularioSearch
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onNuevoUsuario(formulario, listaRoles, serviciosPorProveedor, username, idDominio, this.props.page, formularioSearch);
            //this.formRef.current.resetFields();
        };
        
        
        const handleCancel = () => {
            onModalNuevoUsuario();
            onLimpiarFormulario();
        }

        const onSeleccionarProveedor = (idProveedor) => {
            console.log('idProveedor');
            console.log(idProveedor);
            onServiciosPorProveedor(idProveedor)
        }
        // if(this.state.banderaContraseña){
        //     let password = '';
        //     const symbols = "~*$%@#^&!?*'-=/,.{}()[]<>"
        //     for (let i = 0; i < 8; i++) {
        //         let choice = Math.floor(Math.random() * (3 + 1 - 0) + 0)
        //         if (choice === 0) {
        //             password += String.fromCharCode(Math.floor(Math.random() * (97 + 1 - 122) + 122))
        //         } else if (choice === 1) {
        //             password += String.fromCharCode(Math.floor(Math.random() * (65 + 1 - 90) + 90))
        //         } else if (choice === 2) {
        //             password += symbols[Math.floor(Math.random() * (24 + 1 - 0) + 0)]
        //         } else if (choice === 3) {
        //             password += Math.floor(Math.random() * (9 + 1 - 0) + 0)
        //         } else {
        //             i--
        //         }
        //     }
        //     this.setState({
        //         contraseña: password,
        //         banderaContraseña: false,
        //     })
        // }
        
        return (
            <Modal
            visible={modalNuevoUsuario}
            title='Nuevo Usuario'
            onCancel={handleCancel}
            footer={false}
            >
                
                <VerificarCorreo />
                    
                {verificarCorreo ? (
                <Spin spinning={fetchingNuevoUsuario}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Form.Item
                            label="Nombre(s)"
                            name="nombre"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Input pattern="^[ñíóáéú a-zA-Z ]+$" 
                                    title="El nombre debe tener solo letras"
                                    maxLength={80} placeholder="Ingrese nombre(s)" />
                        </Form.Item>
                        <Form.Item
                            label="Apellido paterno"
                            name="aPaterno"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Input pattern="^[ñíóáéú a-zA-Z ]+$" 
                                    title="El apellido paterno debe tener solo letras"
                                   maxLength={80} placeholder="Ingrese apellido paterno" />
                        </Form.Item>
                        <Form.Item
                            label="Apellido materno"
                            name="aMaterno"
                        >
                            <Input pattern="^[ñíóáéú a-zA-Z ]+$" 
                                   title="El apellido materno debe tener solo letras"
                                   maxLength={80}  placeholder="Ingrese apellido materno" />
                        </Form.Item>
                        {/* <Form.Item
                            label="Contraseña"
                            name="password"
                        >
                            <Input disabled defaultValue={this.state.contraseña} />
                        </Form.Item> */}
                        <Form.Item
                            label="Nombre de contacto"
                            name="idProveedor"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Select style={{ width: '100%' }} placeholder="Selecciona el proveedor"
                                loading={fetchingListaDominiosActivos} onChange={onSeleccionarProveedor}
                            >
                                {listaProveedoresDominio.map(option => <Option key={option.idProveedor}>{option.nombre}</Option>)}
                            </Select>
                        </Form.Item>
                        {serviciosPorProveedor[0] ? (
                            <Form.Item
                                label="Nombre servicios"
                                name="serviciosPorProveedor"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona los servicios"
                                    loading={fetchingServiciosPorProveedor}
                                >
                                    {serviciosPorProveedor.map(option => <Option key={option.idServicio}>{option.nombre}</Option>)}
                                </Select>
                            </Form.Item>
                        ):(
                            <Form.Item
                                label="Nombre servicios"
                                name="serviciosPorProveedor"
                                rules={[{ required: true, message: 'Selecciona un proveedor con servicios', }]}
                            >
                                <p>Selecciona un proveedor con servicios</p>
                            </Form.Item>
                        )}
                        <Form.Item
                            label="Tipo de usuario"
                            name="tipoUsuario"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Selecciona un tipo"
                            >
                                <Option key='Servicio'>Servicio</Option>
                                <Option key='AT'>AT</Option>
                                <Option key='PYRS'>PyRS</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Roles"
                            name="listaRoles"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Select mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Selecciona los roles"
                                loading={fetchingListaRoles}
                            >
                                {listaRoles.map(option => <Option key={option.descripcion}>{option.descripcion}</Option>)}
                            </Select>
                        </Form.Item>
                        <Col span={12}>
                            <Button className="btnFiltrar" htmlType="submit">Crear usuario</Button>
                        </Col>
                    </Form>
                </Spin>):(false)}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingNuevoUsuario: state.ConfiguracionYSeguridadReducer.fetchingNuevoUsuario,
        modalNuevoUsuario: state.ConfiguracionYSeguridadReducer.modalNuevoUsuario,
        fetchingServiciosPorProveedor: state.ConfiguracionYSeguridadReducer.fetchingServiciosPorProveedor,
        serviciosPorProveedor: state.ConfiguracionYSeguridadReducer.serviciosPorProveedor,
        fetchingListaRoles: state.ConfiguracionYSeguridadReducer.fetchingListaRoles,
        listaRoles: state.ConfiguracionYSeguridadReducer.listaRoles,
        fetchingListaDominiosActivos: state.ConfiguracionYSeguridadReducer.fetchingListaDominiosActivos,
        listaDominiosActivos: state.ConfiguracionYSeguridadReducer.listaDominiosActivos,
        verificarCorreo: state.ConfiguracionYSeguridadReducer.verificarCorreo,
        fetchingVerificarUsuario: state.ConfiguracionYSeguridadReducer.fetchingVerificarUsuario,
        listaProveedoresDominio: state.ConfiguracionYSeguridadReducer.listaProveedoresDominio,
        username: state.ConfiguracionYSeguridadReducer.username,
        idDominio: state.ConfiguracionYSeguridadReducer.idDominio,
        page: state.ConfiguracionYSeguridadReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        formularioSearch:state.ConfiguracionYSeguridadReducer.formularioSearch,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onModalNuevoUsuario: () => {
            dispatch({ type: 'MODAL_NUEVO_USUARIO', modalNuevoUsuario: false, page: null })
        },
        onVerificarUsuario: (username, dominio, listaDominiosActivos) => {
            dispatch({ type: "VERIFICAR_USUARIO_REQUEST" , username: username, dominio: dominio, listaDominiosActivos: listaDominiosActivos });
        },
        onNuevoUsuario: (formulario, listaRoles, serviciosPorProveedor, username, idDominio, page, formularioSearch) => {
            dispatch({ type: "NUEVO_USUARIO_REQUEST" , formulario: formulario, listaRoles: listaRoles, serviciosPorProveedor: serviciosPorProveedor, username: username, idDominio: idDominio, page: page, formularioSearch:formularioSearch });
        },
        onServiciosPorProveedor: (idProveedor) => {
            dispatch({ type: 'SERVICIO_POR_PROVEEDOR_REQUEST', idProveedor: idProveedor})
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: 'verificarCorreo' });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoUsuario);