import React from 'react';
import { Form, Spin, Row, Col, Table, Tooltip, Button, Select, Result, Empty} from 'antd';
import { connect } from "react-redux";
import '../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { FileExcelOutlined, FilePdfOutlined, SelectOutlined } from '@ant-design/icons';

const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
const {Option} = Select;

class RelacionDeduccionesErrores extends React.Component {
     formRef = React.createRef();
     
     componentDidMount(){ 
          this.props.onGetListaTiposFallasActivas();
     }

     state={ page: 1, idTipoFalla: null }
 
     render() {
          const {  } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){ permisosUsuario = JSON.parse(permisosUsuario); }

          const onFinish = (formulario) => {
               console.log('Success:', formulario);
          };

          const handleTableChange = (pagination) => {
               this.setState({ page: pagination.current });
               this.props.onGetErr(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, pagination.current, 'idFolioErrorOmision', 'DESC', this.props.listaServiciosActivosF, this.props.listaGruposServicios, this.state.idTipoFalla);
          };

          const columns = [
               { title: 'Folio', dataIndex: 'folioErrorOmision', key: 'folioErrorOmision', align: 'center' },
               { title: 'Tipo', dataIndex: 'tipoFolio', key: 'tipoFolio', align: 'center' },
               { title: 'No. Ticket', dataIndex: 'noTicket', key: 'noTicket', align: 'center' },
               { title: 'Fecha registro', dataIndex: 'fechaRegistro', key: 'fechaRegistro', align: 'center' },
               { title: 'Fecha apertura', dataIndex: 'fechaAperturaTabla', key: 'fechaAperturaTabla', align: 'center' },
               { title: 'Fecha cierre', dataIndex: 'fechaCierreTabla', key: 'fechaCierreTabla', align: 'center' },
               { title: 'No. Doc. Fiscalización', dataIndex: 'noDocFiscalizacion', key: 'noDocFiscalizacion', align: 'center' },
               { title: 'Deducción', dataIndex: 'deduccion', key: 'deduccion', align: 'right' },
          ];

          const onExportar = (e) => {
               if(e === 'pdf'){
                    this.props.onExportPDF(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, this.props.mesTexto);
               } else {
                    this.props.onExportXLS(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, this.props.mesTexto);
               }
          };

          const titleTable = () => {
               return (<Row justify='space-around' gutter={[8,8]}>
                    <Col span={20}>
                         <div style={{textAlign:'center'}}>
                              <h4>FALLAS POR ERRORES U OMISIONES DE LA INFORMACIÓN EN LA PRESTACIÓN PARA EL MES <b> {this.props.mesTexto.toUpperCase()} {this.props.anioSeleccionadoRMDP} </b> </h4>
                              {/* <h5>SERVICIO <b> [NOMBRE DEL SERVICIO PERTENCIENTE AL GRUPO DE SERVICIOS] </b></h5> */}
                         </div>
                    </Col>
                    <Col span={4}>
                         <Row justify='space-around'>
                              <Col span={1}>
                                   <Tooltip title='Exportar reporte de fallas .pdf'>
                                        <Button className="iconSecundary" shape="circle" loading={this.props.fetchingExportarFoliosErroresOmisionesPDF} icon={<FilePdfOutlined />} onClick={(e) => { onExportar('pdf'); }} disabled={ this.props.listaFoliosErroresOmisionesCantidad > 0 ? false : true }/>
                                   </Tooltip>
                              </Col>
                              <Col span={1}>
                                   <Tooltip title='Exportar reporte de fallas .xls'>
                                        <Button className="iconSecundary" shape="circle" loading={this.props.fetchingExportarFoliosErroresOmisionesExcel} icon={<FileExcelOutlined />} onClick={(e) => { onExportar('xls'); }} disabled={ this.props.listaFoliosErroresOmisionesCantidad > 0 ? false : true }/>
                                   </Tooltip>
                              </Col>
                         </Row>
                    </Col>
               </Row>);
          };

          const tipoFallaSelected = (e) => {
               if(e.length > 0){
                    let idTipoFalla = [];
                    for(let i in e){
                         idTipoFalla.push(parseInt(e[i].split(' - ')[0]));
                    }

                    this.props.onGetErr(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, 1, 'idFolioErrorOmision', 'DESC', this.props.listaServiciosActivosF, this.props.listaGruposServicios, idTipoFalla);
                    this.setState({ idTipoFalla });
               }
          }

          return (<>
          { this.props.listaFoliosErroresOmisiones ? (
               <Form {...layout} name="formulario" ref={this.formRef} onFinish={onFinish}>
                    <Row justify='end' style={{ paddingBottom:'1em' }}>
                         <Col span={8}>
                              <Select mode='multiple' style={{ width: '100%', textAlign: 'left' }} placeholder="Selecciona el tipo de falla" loading={this.props.fetchingListaTiposFallasActivas} onChange={tipoFallaSelected}>
                                   {this.props.listaTiposFallasActivas.map(option => 
                                        <Option key={option.idTipoFalla + ' - ' + option.nombre}>
                                             <Tooltip placement="topLeft" title={option.idTipoFalla + ' - ' + option.nombre }>
                                                  {option.idTipoFalla + ' - ' + option.nombre}
                                             </Tooltip>
                                        </Option>)}
                                   )}
                              </Select>
                         </Col>
                    </Row>

                    { this.state.idTipoFalla ? (
                    <Spin spinning={this.props.fetchingListaFoliosErroresOmisiones}>
                         <Row justify='space-around'>
                              <Col span={24}>
                                   { this.props.listaFoliosErroresOmisionesCantidad > 0 ? (
                                        <Table
                                             title={titleTable}
                                             size="small"
                                             rowKey="idFolioErrorOmision"
                                             columns={columns}
                                             dataSource={this.props.listaFoliosErroresOmisiones}
                                             loading={this.props.fetchingListaFoliosErroresOmisiones}
                                             onChange={handleTableChange}
                                             pagination={{ total: this.props.listaFoliosErroresOmisionesCantidad, simple: true, current: this.state.page}}
                                        />
                                   ): (
                                        <Spin spinning={this.props.fetchingListaFoliosErroresOmisiones}>
                                             <Row justify='space-around' align='middle' style={{ height:'30em' }}>
                                                  <Col span={24} >
                                                       <Empty description='Sin datos' />
                                                  </Col>
                                             </Row>
                                        </Spin>
                                   ) }
                              </Col>
                         </Row>
                    </Spin>
                    ):(
                         <Col span={24}>
                              <Row justify='space-around' align='middle' style={{height:'30em'}}>
                                   <Result  icon={<SelectOutlined />} subTitle="Selecciona un tipo de falla para continuar." />
                              </Row>
                         </Col>
                    ) }
               </Form>
          ):false }
          </>);
     }
}
 
 const mapStateToProps = state => {
     return {
          anioSeleccionadoRMDP: state.RMDPReducer.anioSeleccionadoRMDP,
          mesTexto: state.RMDPReducer.mesTexto,
          mesSeleccionadoRMDP: state.RMDPReducer.mesSeleccionadoRMDP,

          listaFoliosErroresOmisiones: state.RMDPReducer.listaFoliosErroresOmisiones,
          listaFoliosErroresOmisionesCantidad: state.RMDPReducer.listaFoliosErroresOmisionesCantidad,
          fetchingListaFoliosErroresOmisiones: state.RMDPReducer.fetchingListaFoliosErroresOmisiones,

          fetchingExportarFoliosErroresOmisionesPDF: state.RMDPReducer.fetchingExportarFoliosErroresOmisionesPDF,
          fetchingExportarFoliosErroresOmisionesExcel: state.RMDPReducer.fetchingExportarFoliosErroresOmisionesExcel,

          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,

          fetchingListaTiposFallasActivas: state.SolicitudDeServiciosReducer.fetchingListaTiposFallasActivas,
          listaTiposFallasActivas: state.SolicitudDeServiciosReducer.listaTiposFallasActivas,
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          onGetErr: (mes, anio, page, field, order, listaServiciosActivosF, listaGruposServicios, idTipoFalla) => {
               dispatch({ type: 'LISTA_FOLIOS_ERRORES_OMISIONES_REQUEST', mes, anio, page, field, order, listaServiciosActivosF, listaGruposServicios, idTipoFalla });
          },
          onExportPDF: (mes, anio, mesTxt) => {
               dispatch({ type: 'EXPORTAR_FOLIOS_ERRORES_OMISIONES_PDF_REQUEST', mes, anio, mesTxt});
          },
          onExportXLS: (mes, anio, mesTxt) => {
               dispatch({ type: 'EXPORTAR_FOLIOS_ERRORES_OMISIONES_EXCEL_REQUEST', mes, anio, mesTxt});
          },
          onGetListaTiposFallasActivas: () => {
               dispatch({ type: "LISTA_TIPOS_FALLAS_ACTIVAS_REQUEST" })
          },
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(RelacionDeduccionesErrores);