import React, {Component} from 'react'
import { Form, Select, Button, Col, Spin, Modal, Row, Input, Tooltip } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import moment from 'moment';

const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalNuevaPreguntaEncuesta extends Component {
    formRef = React.createRef();
    render() { 
        const {
            onNuevaPreguntaEncuesta, onShowModalNuevaPreguntaEncuesta, showModalNuevaPreguntaEncuesta,
            fetchingNuevaPreguntaEncuesta, page, limpiarFormulario, fetchingListaServiciosActivosF,
            listaServiciosActivosF, busqueda,
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onNuevaPreguntaEncuesta(formulario, page, busqueda, moment().format('YYYY-MM-DD').split('-')[0]);
        };

        if (limpiarFormulario && limpiarFormulario === 'crearPreguntaEncuesta') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }
        const handleCancel = () => {
            onShowModalNuevaPreguntaEncuesta(page, busqueda);
            this.formRef.current.resetFields();
        }
        

        return (
            <Modal
                visible={showModalNuevaPreguntaEncuesta}
                title={'Nueva Pregunta de Encuesta de Satisfacción para el año ' + moment().format('YYYY-MM-DD').split('-')[0]}
                onCancel={handleCancel}
                footer={false}
                width='50%'
            >
                <Spin spinning={fetchingNuevaPreguntaEncuesta}>
                    <Form {...layout}
                        name="formulario"
                        onFinish={onFinish}
                        ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Servicio"
                                    name="servicio"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona un servicio"
                                        loading={fetchingListaServiciosActivosF}
                                        showSearch={true}
                                        mode="multiple"
                                    >
                                        {listaServiciosActivosF.map(option => 
                                        <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                            <Tooltip title={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                {option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}
                                            </Tooltip>
                                        </Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Pregunta"
                                    name="pregunta"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={200} placeholder='Ingresa la pregunta' />
                                </Form.Item>
                            </Col>

                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Crear Pregunta de Encuesta de Satisfacción</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}
const mapStateToProps = state => {
    return {
        showModalNuevaPreguntaEncuesta: state.GestionDeCalidadReducer.showModalNuevaPreguntaEncuesta,
        fetchingNuevaPreguntaEncuesta: state.GestionDeCalidadReducer.fetchingNuevaPreguntaEncuesta,
        page: state.GestionDeCalidadReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        busqueda: state.GestionDeCalidadReducer.busqueda,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevaPreguntaEncuesta: (page, busqueda) => {
            dispatch ({ type: 'MODAL_NUEVA_PREGUNTA_ENCUESTA', showModalNuevaPreguntaEncuesta: false, page, busqueda })
        },
        onNuevaPreguntaEncuesta: (formulario, page, busqueda, año, añoPasado) => {
            dispatch({ type: "NUEVA_PREGUNTA_ENCUESTA_REQUEST", formulario, page, busqueda, año, añoPasado })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevaPreguntaEncuesta);