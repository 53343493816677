import React from 'react';
import { Card, Table, Row, Col, Button, Form, Select, Spin, Tooltip } from 'antd';
import { IssuesCloseOutlined, DiffOutlined, EyeOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import moment from 'moment';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalRegistrarEncuesta from './ModalRegistrarEncuesta';
import ModalDetalleRegistroEncuesta from './ModalDetalleRegistroEncuesta';
const { Option } = Select;
class RegistroEncuestaContainer extends React.Component {
    formRef = React.createRef();
    componentDidMount(){
        this.props.onListaServiciosActivos();
    }
    componentWillUnmount() {
        this.props.onLimpiarListaMesesAñoServicio();
        this.props.onLimpiarListaFoliosBusqueda();
    }
    state={
        busqueda: {
            idServicio: null,
            año: moment().format('YYYY'),
            mes: null,
            estado: null,
        },
        page: 1,
    }
    render() {
        const { onListaFoliosBusqueda, fetchingListaFoliosBusqueda, listaFoliosBusqueda,
            listaFoliosBusquedaCantidad, onShowModalRegistrarEncuesta,
            fetchingListaServiciosActivosF, listaServiciosActivosF, onListaMesesAñoServicio,
            fetchingListaMesesFolio, listaMesesFolio, onEvaluarEncuestas,
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columns = [{
                title: 'Folio de encuesta',
                dataIndex: 'folioEncuesta',
                key: 'folioEncuesta',
                width: '10%',
            }, {
                title: 'Comentarios',
                dataIndex: 'comentarios',
                key: 'comentarios',
                width: '30%',
            }, {
                title: 'Estado',
                dataIndex: 'estado',
                key: 'estado',
                width: '10%',
            }, {
                title: 'Calificación',
                dataIndex: 'calificacion',
                key: 'calificacion',
                width: '10%',
            }, {
                title: 'Fecha alta',
                dataIndex: 'fechaAlta',
                key: 'fechaAlta',
                width: '10%',
            }, {
                title: 'Ingresar respuestas', 
                key: 'ingresarRespuesta', 
                width: '10%', 
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.ingresarRespuestas ? true: record.estado === 'Generada' ? false: true} onClick={() => onAgregarRespuesta(record)}><IssuesCloseOutlined /></a>
                )
            }, {
                title: 'Evaluar por folio',
                key: 'evaluarFolios', 
                width: '10%', 
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.evaluarPorFolio ? true: record.estado === 'No evaluada' ? false: true} onClick={() => evaluarPorFolio(record)}><DiffOutlined /></a>
                )
            }, {
                title: 'Detalles',
                key: 'detalles', 
                width: '10%', 
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.detallesDeEncuestaDeSatisfacción ? true: record.estado === 'Evaluada' || record.estado === 'No evaluada' ? false: true} onClick={() => detalleFolio(record)}><EyeOutlined /></a>
                )
            }
        ];

        const detalleFolio = (folioSeleccionado) => {
            console.log(`folioSeleccionado`, folioSeleccionado)
            this.props.onShowModalDetalleFolio(folioSeleccionado);
        }

        const onAgregarRespuesta = (folioSeleccionado) => {
            console.log(`folioSeleccionado`, folioSeleccionado);
            onShowModalRegistrarEncuesta(this.state.page, this.state.busqueda, folioSeleccionado);
        }

        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onListaFoliosBusqueda(pagination.current, this.state.busqueda);
        };

        const onServicioSeleccionado = (value) => {
            this.props.onLimpiarListaMesesAñoServicio();
            this.props.onLimpiarListaFoliosBusqueda();
            this.formRef.current.setFieldsValue({ 'mes': undefined })
            if(value){
                onListaMesesAñoServicio(parseInt(value), this.state.busqueda.año);
                const busqueda = {
                    idServicio: parseInt(value),
                    año: this.state.busqueda.año,
                    mes: null,
                    estado: null,
                }
                this.setState({
                    busqueda,
                })
            };
        }
        
        const onMesSeleccionado = (value) => {
            const busqueda = {
                idServicio: this.state.busqueda.idServicio,
                año: this.state.busqueda.año,
                mes: value,
                estado: null,
            }
            this.setState({
                busqueda,
            })
            onListaFoliosBusqueda(this.state.page, busqueda);
        };

        const evaluarPorServicio = () => {
            onEvaluarEncuestas(null, this.state.busqueda.idServicio, this.state.busqueda.año, this.state.busqueda.mes, this.state.page, this.state.busqueda)
        }
        const evaluarPorFolio = (folioSeleccionado) => {
            onEvaluarEncuestas(folioSeleccionado.folioEncuesta, null, null, null, this.state.page, this.state.busqueda)
        }

        const onEstado = (value) => {
            const busqueda = {
                idServicio: this.state.busqueda.idServicio,
                año: this.state.busqueda.año,
                mes: this.state.busqueda.mes,
                estado: value
            }
            this.setState({
                busqueda,
            })
            onListaFoliosBusqueda(this.state.page, busqueda);
        }
        return (
            <Card className="cardCatalogos" title={<div className='headerLista'>Registro de Encuestas de Satisfacción</div>}>
                <Form
                    name="formulario"
                    ref={this.formRef}
                >
                    <Row justify='space-around' label="Filtrado por Ubicación">
                        <Col span={11}>
                            <Form.Item
                                name="servicio"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona un servicio"
                                    loading={fetchingListaServiciosActivosF}
                                    showSearch={true}
                                    onChange={onServicioSeleccionado}
                                >
                                    {listaServiciosActivosF.map(option => 
                                    <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                        <Tooltip title={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                            {option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}
                                        </Tooltip>
                                    </Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                name="mes"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona un mes"
                                    loading={fetchingListaMesesFolio}
                                    showSearch={true}
                                    onChange={onMesSeleccionado}
                                >
                                    {listaMesesFolio.map(option => 
                                    <Option key={option.idMes}>
                                        {option.mes}
                                    </Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <br/>
                <Spin spinning={fetchingListaFoliosBusqueda}>
                    {listaFoliosBusqueda ? (
                    <Row justify='space-around'>
                        <Col span={8}>
                            <Select allowClear
                                style={{ width: '100%' }}
                                placeholder="Selecciona un estado"
                                showSearch={true}
                                onChange={onEstado}
                            >
                                <Option key='Generada'>Generada</Option>
                                <Option key='No evaluada'>No evaluada</Option>
                                <Option key='Evaluada'>Evaluada</Option>
                            </Select>
                        </Col>
                        <Col span={8}>
                            <Button onClick={evaluarPorServicio} disabled={!permisosUsuario.evaluarPorServicio} className='nuevoUsuario'>Evaluar por Servicio</Button>
                        </Col>
                        <Col span={24}>
                            <br/>
                            <Table 
                                size="small" 
                                rowKey="idFolioEncuesta"
                                columns={columns}
                                dataSource={listaFoliosBusqueda}
                                locale={{ emptyText: 'Sin datos' }}
                                onChange={handleTableChange}
                                pagination={{ total: listaFoliosBusquedaCantidad, simple: true, current: this.state.page}}
                            />         
                        </Col>
                    </Row>
                    ):false}
                </Spin>
                <ModalRegistrarEncuesta/>
                <ModalDetalleRegistroEncuesta/>
            </Card>
        );
    }
}
 
const mapStateToProps = state => {
    return {
        fetchingListaFoliosBusqueda: state.GestionDeCalidadReducer.fetchingListaFoliosBusqueda,
        listaFoliosBusqueda: state.GestionDeCalidadReducer.listaFoliosBusqueda,
        listaFoliosBusquedaCantidad: state.GestionDeCalidadReducer.listaFoliosBusquedaCantidad,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        fetchingListaMesesFolio: state.GestionDeCalidadReducer.fetchingListaMesesFolio,
        listaMesesFolio: state.GestionDeCalidadReducer.listaMesesFolio,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onListaServiciosActivos: () => {
            dispatch ({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST' });
        },
        onListaMesesAñoServicio: (idServicio, año) => {
            dispatch ({ type: 'LISTA_MESES_FOLIO_REQUEST', idServicio, año});
        },
        onLimpiarListaMesesAñoServicio: () => {
            dispatch ({ type: 'LISTA_MESES_FOLIO_SUCCESS', listaMesesFolio: []});
        },
        onLimpiarListaFoliosBusqueda: () => {
            dispatch({ type: 'LISTA_FOLIOS_BUSQUEDA_SUCCESS', listaFoliosBusqueda: null });
        },
        onListaFoliosBusqueda: (page, busqueda) => {
            dispatch({ type: 'LISTA_FOLIOS_BUSQUEDA_REQUEST', page, busqueda });
        },
        onShowModalRegistrarEncuesta: (page, busqueda, folioSeleccionado) => {
            dispatch ({ type: 'MODAL_REGISTRAR_ENCUESTA', showModalRegistrarEncuesta: true, page, busqueda, folioSeleccionado });
        },
        onEvaluarEncuestas: (folio, idServicio, año, idMes, page, busqueda) => {
            dispatch({ type: 'EVALUAR_ENCUESTA_REQUEST', folio, idServicio, año, idMes, page, busqueda });
        },
        onShowModalDetalleFolio: (folioSeleccionado) => {
            dispatch ({ type: 'MODAL_DETALLE_FOLIO', showModalDetalleFolio: true, folioSeleccionado })
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RegistroEncuestaContainer);