import React from 'react';
import { message, Form, Row, Col, Button, Select, Card, Switch, Divider, Table, Input, Spin, Alert } from 'antd';
import { connect } from "react-redux";
import { SearchOutlined, FileSearchOutlined, EditOutlined, FieldTimeOutlined, LoadingOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalProgramar from './ModalProgramar';
import ModalActualizar from './ModalActualizar';
import ModalDetalleActividad from './ModalDetalleActividad';
import ModalDetalleUbicacion from './ModalDetalleUbicacion';
import ModalFecha from './ModalFecha';

import imaSearch from '../../../assets/undraw_events_re_98ue.svg';
import ReactVivus from "react-vivus";
const { Option, OptGroup } = Select;
const { Search } = Input;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };

class ProgramasDeRutinasContainer extends React.Component {
     formRef = React.createRef();

     componentDidMount() {
          this.props.onObtenerServicios();
          //this.props.onGetListaRMP(1, [], 'Mantenimiento', '');
     }

     state = {
          page: 1,
          busqueda: '',
          serviciosSeleccionadosLocal: [],
          programa: '',
          checked: true, // cambiar a true
          formulario:{
               descripcion: null,
               servicios: [],
          },
          serviciosTodos: false,
          booleanSearch: false,
     }


     render() {
          const { 
               fetchingListaServiciosFiltro,
               listaServiciosFiltro,
               onListaServiciosFiltro,
               onListaServiciosFiltroLimpiar,
               listaServiciosActivosF,
               onShowProgramar,
               onShowDetalle,
               onShowUbicacion,
               onShowActualizar,
               onGetListaRMP,
               listaRMPBusquedaCantidad,
               listaRMPBusqueda,
               fetchingListaRMPBusqueda,
               spnbool
          } = this.props;

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
               permisosUsuario = JSON.parse(permisosUsuario);
          }

          const onFinish = formulario => {
               console.log('Success:', formulario);
               let idServicioSeleccionados = [];

               if(formulario.servicios[0] === 'todos'){
                    for(let i = 0; i < listaServiciosActivosF.length; i++){
                         idServicioSeleccionados.push(listaServiciosActivosF[i].idServicio);
                    }
               } else {
                    for(let j = 0; j < formulario.servicios.length; j++){
                         idServicioSeleccionados.push(formulario.servicios[j].split(' - ')[0]);
                    }
               }

               onGetListaRMP(this.state.page, idServicioSeleccionados, formulario.programa, this.state.busqueda)
               this.setState({ serviciosSeleccionadosLocal: idServicioSeleccionados, booleanSearch: true });
          };

          const onChangeServicios = value => {
               this.setState({serviciosTodos: false});
               const todos = value.some(item => item === 'todos');
               if(todos) {
                   this.setState({serviciosTodos: true});
                   this.formRef.current.setFieldsValue({'servicios':['todos']})
               }
          }

          const buscarServicios = value => {
               if(value.length>=3){
                    if(value){
                         onListaServiciosFiltro(value)
                    } else {
                         message.warning('No se encontró servicio con esas palabras')
                    }
               } else {
                    onListaServiciosFiltroLimpiar();
               } 
          }

          const onPrograma = (value) => { 
               if(value){
                    this.setState({ programa: value });
               }
          };

          const onBusqueda = (busqueda) => {
               this.setState({
                   busqueda: busqueda,
                   page: 1,
               })
               console.log(busqueda);
               onGetListaRMP(1, this.state.serviciosSeleccionadosLocal, this.state.programa, busqueda);
          };

          const columns = [
               { title: 'Nomenclatura', dataIndex: 'nomenclatura', key: 'nomenclatura', width:'10%'  },
               {title: 'Nombre de rutina', dataIndex: 'rutina', key: 'rutina', width:'20%' },
               { title: 'Categoría', dataIndex: 'categoria', key: 'categoria', width:'20%'  },
               { title: 'Programa', dataIndex: 'programa', key: 'programa', width:'10%' },
               { title: 'Tipo', dataIndex: 'tipoRutina', key: 'tipoRutina', width:'10%'  },
               { title: 'Clave', dataIndex: 'claveRutina', key: 'claveRutina', width:'8%' },
               { title: 'Afectación', dataIndex: 'afectacionMin', key: 'afectacionMin', width:'8%' },
               { title: 'Estado', dataIndex: 'estadoRutina', key: 'estadoRutina', width:'8%' },
               {title: 'Detalle de Actividad', key: '', align: 'center', width:'8%',
               render: (record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.detalleDeActividad}  onClick={() => onDetalleAct(record)}><FileSearchOutlined /></a>
               ),},
               { title: 'Detalle de Programa', dataIndex: '', key: '', align: 'center', width:'8%',
               render: (record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.detalleDePrograma}  onClick={() => onDetalleUbi(record)}><FileSearchOutlined /></a>
               ),},
               {title: 'Programar', key: 'programar', align: 'center', width:'8%',
               render: (record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.programarTarea}  onClick={() => onProgramar(record)}><FieldTimeOutlined /></a>
               ),},
               {title: 'Editar', key: 'editar', align: 'center', width:'8%',
               render: (record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarProgramación}  onClick={() => onEditar(record)}><EditOutlined /></a>
               ),},
          ];

          const onProgramar = (key) => {
               console.log('key', key)
               if(key.estadoRutina === 'Programada'){ message.warning('Rutina/Mantenimiento/Prueba reglamentaria ya programada') } else{ onShowProgramar(key, this.state.programa, this.state.page, this.state.busqueda, this.state.serviciosSeleccionadosLocal,); }
          };

          const onEditar = (key) => {
               if(key.estadoRutina !== 'Programada'){ message.warning('Rutina/Mantenimiento/Prueba reglamentaria sin programar') } else{ onShowActualizar(key, this.state.page, this.state.busqueda, this.state.programa, this.state.serviciosSeleccionadosLocal); }
          };

          const onDetalleAct = (key) => {
               console.log('key', key)
               onShowDetalle(key, this.state.page);
          };

          const onDetalleUbi = (key) => {
               onShowUbicacion(key, this.state.programa, this.state.page, this.state.busqueda);
          };

          const handleTableChange = (pagination)=>{
               this.setState({
                   page: pagination.current,
               });
               onGetListaRMP(pagination.current, this.state.serviciosSeleccionadosLocal, this.state.programa, this.state.busqueda);
          };

          const scroll = {"x": 1500 | true, "y": 1000};

          return (
               <Card
                    className="listaCatalogos"
                    bordered={true}
                    style={{align: 'center'}}
                    title={<div className="headerLista">Programas de Rutinas, Mantenimientos y Pruebas</div>}
               >

                    <Form {...layout}
                         name="formulario"
                         onFinish={onFinish}
                         ref={this.formRef}
                    >
                         <Row justify='space-around' style={{ textAlign: 'left' }}>
                              <Col span={18}>
                                   <Form.Item
                                   label='Servicios'
                                   name='servicios'
                                   rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                   >
                                        <Select
                                             mode='multiple'
                                             allowClear
                                             style={{ width: '100%', textAlign: 'left' }}
                                             placeholder="Ingresa el servicio a buscar"
                                             loading={fetchingListaServiciosFiltro}
                                             showSearch
                                             filterOption={false}
                                             onSearch={buscarServicios}
                                             onChange={onChangeServicios}
                                             notFoundContent={null}
                                        >
                                             <OptGroup label="Todos los servicios">
                                                  <Option value="todos">Todos los Servicios</Option>
                                             </OptGroup>
                                             <OptGroup label="Servicios">
                                                  {listaServiciosFiltro.map(option => <Option disabled={this.state.serviciosTodos} key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>{option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}</Option>)}
                                             </OptGroup>
                                        </Select>
                                   </Form.Item>                                  
                              </Col>       
                              
                              <Col span={4}>
                                   <Form.Item
                                        name='programa'
                                        label='Programa'
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                   >
                                        <Select
                                             showSearch
                                             placeholder='Seleccione un programa'
                                             onChange={onPrograma}
                                        >
                                             <Option value='Rutina'>Rutinas</Option>
                                             <Option value='Mantenimiento'>Mantenimientos</Option>
                                             <Option value='Prueba'>Pruebas</Option>
                                        </Select>

                                   </Form.Item>
                              </Col>

                              <Col span={1}>
                                   <br/><br/>
                                   <Button shape="circle" icon={<SearchOutlined />} className="btnFiltrar" htmlType="submit"></Button>
                              </Col>
                              
                              
                              { this.state.booleanSearch ? (<>

                              <Divider />
                                   
                              <Col span={24}>
                                   <Search className='buscarModulos' placeholder="Ingresa nombre, tipo o clave del RMP a buscar" onSearch={value => onBusqueda(value)} style={{ width: '100%' }}/>
                              </Col>

                              <Col span={24}>
                                   <br/>
                                        
                                        <Table
                                             size="small" 
                                             columns={columns}
                                             rowKey="idRutina" 
                                             dataSource={listaRMPBusqueda}
                                             loading={fetchingListaRMPBusqueda}
                                             locale={{ emptyText: 'Sin datos' }}
                                             onChange={handleTableChange}
                                             pagination={{ total: listaRMPBusquedaCantidad, simple: true, current: this.state.page, hideOnSinglePage: true }}
                                             scroll={scroll}
                                             showSorterTooltip={true}
                                        />
                              </Col>
                              </>): (<Col span={24}> <Spin spinning={fetchingListaRMPBusqueda}>
                                   <Row justify='space-around' align='middle' gutter={[8,8]}>
                                        <Col span={24}>
                                             <Alert
                                                  message="Programas de Rutinas, Mantenimientos y Pruebas"
                                                  description="Selecciona uno, varios o todos los servicios y un tipo de programa para continuar."
                                                  type="info"
                                                  showIcon
                                                  style={{ borderRadius: '2em' }}
                                             />
                                        </Col>
                                        <Col span={7} style={{paddingTop:'4em', paddingBottom:'1em', textAlign:'center'}}>
                                        {/* <img src={imaSearch} /> */}
                                             <ReactVivus
                                                  id="imaSearch"
                                                  option={{
                                                       file: imaSearch,
                                                       animTimingFunction: "EASE",
                                                       type: "delayed",
                                                  }}
                                             />
                                        </Col>
                                   </Row>
                              </Spin> </Col>) }
                         </Row>
                    </Form>
                    <ModalProgramar />
                    <ModalActualizar />
                    <ModalDetalleActividad />
                    <ModalDetalleUbicacion />
                    <ModalFecha />
               </Card>
          );
     }
}

const mapStateToProps = state => {
     return {
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
          listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,

          fetchingListaRMPBusqueda: state.ServiciosPlanificadosReducer.fetchingListaRMPBusqueda,
          listaRMPBusqueda: state.ServiciosPlanificadosReducer.listaRMPBusqueda,
          listaRMPBusquedaCantidad: state.ServiciosPlanificadosReducer.listaRMPBusquedaCantidad,
          spnbool: state.ServiciosPlanificadosReducer.spnbool,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onListaServiciosFiltro: (busqueda) => {
               dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
          },
          onListaServiciosFiltroLimpiar: () => {
               dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
          },
          onObtenerServicios: () => {
               dispatch({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST' });
          },
          onShowProgramar: (programaRMPSeleccionado, programa, page, busqueda, idsServicios) => {
               dispatch({ type: 'SHOW_MODAL_PROGRAMAR_RMP', showModalProgramarRMP: true, programaRMPSeleccionado, programa, page, busqueda, idsServicios });
          },
          onShowDetalle: (programaRMPSeleccionado, page) => {
               dispatch({ type: 'SHOW_MODAL_DETALLE_ACTIVIDAD_RMP', showModalDetalleActividadRMP: true, programaRMPSeleccionado, page });
          },
          onShowUbicacion: (programaRMPSeleccionado, programa, page, busqueda) => {
               dispatch({ type: 'SHOW_MODAL_DETALLE_UBICACION_RMP', showModalDetalleUbicacionRMP: true, programaRMPSeleccionado, programa, page, busqueda })
          },
          onShowActualizar: (programaRMPSeleccionado, page, busqueda, programa, idsServicios) => {
               dispatch({ type: 'SHOW_MODAL_ACTUALIZAR_RM', showModalActualizarRM: true, programaRMPSeleccionado, page, busqueda, programa, idsServicios });
          },
          onGetListaRMP:(page, idsServicios, programa, busqueda) => {
               dispatch({ type: 'LISTA_RMP_BUSQUEDA_REQUEST', page, idsServicios, programa, busqueda });
          }
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramasDeRutinasContainer);
