import React, { Component } from 'react';
import { Form, Button, Row, Col, Spin, Modal, Select, Table, Input, Divider, DatePicker, Popover, message } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import { FieldNumberOutlined, DeleteOutlined } from '@ant-design/icons';
import moment, { parseTwoDigitYear } from 'moment';
import {ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;
const confirm = Modal.confirm;
const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };

class ModalAsignarActivos extends Component {
    formRef = React.createRef();
    
     componentDidMount() {
          
          this.props.onGetUbicaciones();
     }

     state={
          refrescar: false,
          activosSeleccionadosSolicitud: []
     }

     render() {
          const { 
               page,    
               onShowModalSalidaDeBienes, 
               showModalSalidaDeBienes,
               fetchingNuevaSalidaDeBienes,
               limpiarFormulario,
               salidaActivoSeleccionado,
               tipoMovimiento,
               motivoSalida,
               servicioSalida,
               listaUbicacionesFiltro,
               fetchingListaUbicacionesFiltro,
               onListaUbicacionesFiltro,
               onListaUbicacionesFiltroLimpiar,
               onCambiarVista,
               datosSolicitudSeleccionados,
               fetchingListaEstatusActivoActivos,
               listaEstatusActivoActivos,
               onActualizarTabla,
               selectedRowKeys,
               listaActivosEnOperacionServicio
          } = this.props;

          let userInfo = sessionStorage.getItem('userInfo');
          if(!userInfo.idUsuario){ userInfo = JSON.parse(userInfo); }

          const onFinish = formulario => {
               console.log("formulario en vista 2",formulario)
               const value = formulario['fechaRetorno'];
               console.log("fechaRetorno",value)
               let fechaRetornoFinish = '';
               if(value){
                    formulario.fechaRetorno = value.format('YYYY-MM-DD');
                    fechaRetornoFinish = formulario.fechaRetorno;
               }
               console.log('Success:', formulario);
               onCambiarVista(3, formulario, this.state.activosSeleccionadosSolicitud );
               this.setState({
                    fechaRetornoFinish: fechaRetornoFinish
               });
          };

          const onRegresar = formulario => {
               onCambiarVista(1, formulario, this.state.activosSeleccionadosSolicitud);
          }

          if (limpiarFormulario && limpiarFormulario === 'crearSolicitudDeSalida') {
               this.props.onLimpiarFormulario();
               
               onListaUbicacionesFiltro();
               onListaUbicacionesFiltroLimpiar();
               onShowModalSalidaDeBienes();

               this.formRef.current.resetFields();
          }
           
          const onFinishFailed = errorInfo => {
               console.log('Failed:', errorInfo);
          };

          const handleCancel = () => {
               onShowModalSalidaDeBienes();
               onListaUbicacionesFiltro();
               onListaUbicacionesFiltroLimpiar();
               this.formRef.current.resetFields();
          }

          const handleSearch = (value) => {
               if(value.length>=3){
                   if (value) {
                       console.log(value)
                       onListaUbicacionesFiltro(value);
                   } else {
                       message.warning('No se encontró ubicación con esas palabras')
                   }
               }
               else{
                   onListaUbicacionesFiltroLimpiar();
               }
          };

          const disabledRange = fecha => {
               let today = new Date();
               let dd = String(today.getDate()).padStart(2, '0');
               let mm = String(today.getMonth() + 1).padStart(2, '0');
               let yyyy = today.getFullYear();

               today = yyyy + '-' + mm + '-' + dd;
               return fecha && moment(fecha.format('YYYY-MM-DD')) < moment(today);
          }

          const deleteSelected = (idActivo) => {
               console.log('idActivo: ', idActivo);
               let salidaActivoSeleccionadoLocal = [];
               let selectedRowKeysLocal = [];
               let hasSelected = false;

               for(let i=0; i < this.state.activosSeleccionadosSolicitud.length; i++){
                    if(this.state.activosSeleccionadosSolicitud.length > 1){
                         if(this.state.activosSeleccionadosSolicitud[i].idActivo !== idActivo){
                              salidaActivoSeleccionadoLocal.push(this.state.activosSeleccionadosSolicitud[i]);
                              selectedRowKeysLocal.push(this.state.activosSeleccionadosSolicitud[i].idActivo);
                         }
                    } else {
                         hasSelected = true;
                    }
               }

               console.log('salidaActivoSeleccionado dentro: ', salidaActivoSeleccionadoLocal);
               onActualizarTabla(salidaActivoSeleccionadoLocal, selectedRowKeysLocal, hasSelected, page);           
          }
          if(selectedRowKeys !== undefined){
               for(let i = 0; i < selectedRowKeys.length; i++){
                    for(let j = 0; j < listaActivosEnOperacionServicio.length; j ++){
                         if(listaActivosEnOperacionServicio[j].idActivo == selectedRowKeys[i]){
                              this.state.activosSeleccionadosSolicitud[i] = listaActivosEnOperacionServicio[j];
                         }
                    }
               }
          }

          const handleEliminar = (key) => {
               console.log('key: ', key)
               confirm({
                    title: '¿Estás seguro que deseas retirar el activo "' + key.codigoInterno + '" de la solicitud de salida?',
                    content: '',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                         deleteSelected(key.idActivo)
                    },
                    onCancel() {
                         console.log('Cancel');
                    },
               });
          }

          const columns = [
               {
                    fixed: 'left',
                    width: '5%',
                    align: 'center',
                    render: (record) => (
                         <a className='eliminarIcono' onClick={ () => handleEliminar(record) }> 
                              <DeleteOutlined /> 
                         </a>
                    ),          
               },
               {title: 'Descripción', dataIndex: 'descripcion', key: 'descripcion', width:'12%'},,
               {title: 'Código de Equipo', dataIndex: 'codigoEquipo', key: 'codigoEquipo', width:'12%'},
               {title: 'Código Interno',dataIndex: 'codigoInterno',key: 'codigoInterno', width:'8%'},
               {title: 'Num Serie',dataIndex: 'serieNum',key: 'serieNum',  width:'8%'},
               {title: 'SKU',dataIndex: 'sku',key: 'sku', width:'9%'},
               {title: 'Clave Ubicación',dataIndex: 'claveUbicacion',key: 'claveUbicacion', width:'10%'},
               {title: 'Área Funcional',dataIndex: 'areaFuncional',key: 'areaFuncional',width:'12%'},
               {title: 'Unidad Funcional',dataIndex: 'unidadFuncional',key: 'unidadFuncional',width:'12%'},
               {title: 'Interior',dataIndex: 'interiorFuncional',key: 'interiorFuncional',width:'12%'},
               {title: 'Subinterior',dataIndex: 'subInteriorFuncional',key: 'subInteriorFuncional',width:'12%'}
          ];

          let usuario = '';
          if(userInfo[0].aMaterno === null){
               usuario = userInfo[0].nombre + ' ' + userInfo[0].aPaterno + ': ' + userInfo[0].username;
          } else {
               usuario = userInfo[0].nombre + ' ' + userInfo[0].aPaterno + ' ' + userInfo[0].aMaterno + ': ' + userInfo[0].username;
          }

          let sinActivos = false;
          if(salidaActivoSeleccionado === undefined || salidaActivoSeleccionado.length === 0){
               sinActivos = true;
          }

          return (
               <div>
                    <Spin 
                    spinning={fetchingNuevaSalidaDeBienes}
                    >
                         <Form {...layout}
                         name="formulario"
                         onFinish={onFinish}
                         
                         ref={this.formRef}
                         >
                              <Row align="middle" justify="center" gutter={[8,8]}>
                                   <Col span={4}>
                                        <Form.Item
                                        //name='FolioSalida'
                                        label='Folio de Salida'
                                        >
                                             <Input
                                             disabled={true}
                                             // prefix={<FieldNumberOutlined />}
                                             value={tipoMovimiento}
                                             addonBefore={<FieldNumberOutlined />}
                                             />
                                        </Form.Item>
                                   </Col>
                                   <Col span={12}>
                                        <Form.Item
                                        //name="idServicio"
                                        label="Servicio"
                                        rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                        
                                        >
                                             <Input
                                             disabled={true}
                                             value={servicioSalida}
                                             />
                                        </Form.Item>                   
                                   </Col>
                                   <Col span={8}>
                                        <Form.Item
                                        label="Motivos de Salida: "
                                        //name="idMotivoSalida"
                                        rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                        >
                                             <Input
                                             disabled={true}
                                             value={motivoSalida}
                                             />
                                        </Form.Item>
                                   </Col>
                              </Row>
                              <Divider orientation="left"> Solicitud de Salida </Divider>

                              <Row justify='space-around'>
                                   
                                   <Col span={23}>
                                        <Form.Item
                                             label="Activos Seleccionados"
                                             id="tablaActivosSeleccionados"
                                             name='tablaActivosSeleccionados'
                                        >
                                             <Table 
                                                  size="small" 
                                                  rowKey="idActivo" 
                                                  columns={columns}
                                                  dataSource={this.state.activosSeleccionadosSolicitud}        
                                                  locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar'}}
                                                  pagination={false}
                                                  scroll={{ x: 1500 }}
                                                  renderCell
                                             />
                                        </Form.Item>
                                   </Col>

                                   <Col span={11}>
                                        <Form.Item
                                             label="Usuario Entrega"
                                             rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                             <Input
                                                  disabled={true}
                                                  defaultValue={usuario}
                                             />
                                        </Form.Item>
                                   </Col>

                                   <Col span={11}>
                                        <Form.Item
                                             label="Usuario Responsable"
                                             name="usuarioResponsable"
                                             rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                             <Input
                                                  placeholder="Escribe el nombre del usuario que recibe el bien"
                                                  pattern="^^[ñíóáéú a-zA-Z ]+$"
                                                  title="No se aceptan caractes especiales o números"
                                                  maxLength={100} 
                                             />
                                        </Form.Item>
                                   </Col>

                                   {tipoMovimiento.split('-')[0] === 'tem' ? ( 
                                        <Col span={24}>
                                             <Divider orientation="left"> Solicitud de Salida Temporal </Divider>
                                             <Row justify='space-around'>
                                                  <Col span={11}>
                                                       <Form.Item
                                                            label="Fecha de Retorno"
                                                            name="fechaRetorno"
                                                            rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                       >
                                                            <DatePicker 
                                                            style={{ width: '100%' }} 
                                                            format='DD-MM-YYYY' 
                                                            placeholder={["Seleccione fecha de retorno"]}
                                                            disabledDate={disabledRange}
                                                            />
                                                       </Form.Item>
                                                  </Col>
                                             </Row>
                                        </Col>

                                   ): false}
                                   
                                   {/* && motivoSalida.split(', ')[1] === ' Baja administrativa'  */}
                                   
                                   {tipoMovimiento.split('-')[0] === 'def' && motivoSalida.split(', ')[1] === ' Baja administrativa' ? ( 
                                        <Col span={24}>
                                             <Divider orientation="left"> Solicitud de Salida Definitiva y Baja Administrativa </Divider>
                                             <Row justify='space-around'>
                                             <Col span={11}>
                                                  <Form.Item
                                                       label="Nueva Ubicación"
                                                       name="todoUbicacion"
                                                       rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                                  >
                                                       <Select allowClear
                                                            style={{ width: '100%' }}
                                                            placeholder="Escribe la nueva ubicación"
                                                            loading={fetchingListaUbicacionesFiltro}
                                                            showSearch
                                                            filterOption={false}
                                                            onSearch={handleSearch}
                                                            notFoundContent={null}
                                                       >
                                                       {listaUbicacionesFiltro.map(option => 
                                                       <Option key={option.idUbicacion}>
                                                            <Popover content={option.todoUbicacion} title={false}>
                                                                 {option.todoUbicacion}
                                                            </Popover>
                                                       </Option>
                                                       )}
                                                       </Select>
                                                  </Form.Item>
                                             </Col>

                                             <Col span={11}>
                                                  <Form.Item
                                                       label="Condición del Bien"
                                                       name="condicionBien"
                                                       rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                                  >
                                                       <Input
                                                            pattern="^[ñíóáéú a-zA-Z0-9 ]+$"
                                                            title="Descripción contempla carácteres y números."
                                                            maxLength={20}
                                                            placeholder='Escribe la condición del bien'
                                                       />
                                                  </Form.Item>
                                             </Col>
                                             </Row>
                                        </Col>
                                   ): false}

                                   <Col span={23}>
                                        <Form.Item
                                             label="Comentario"
                                             name="comentarioSalida"
                                             rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                             <TextArea
                                                  pattern="^[ñíóáéú a-zA-Z0-9 ]+$"
                                                  title="Descripción contempla carácteres y números."
                                                  maxLength={300}
                                                  placeholder='Escribe el comentario de salida'
                                                  autoSize
                                             />
                                        </Form.Item>
                                   </Col>

                                   <Divider/>
                                   <Col span={10}>
                                        <br/> <br/>
                                        <Button className="btnCargaArchivos" style={{width: '100%'}} onClick={onRegresar}><ArrowLeftOutlined />Anterior</Button> 
                                   </Col>

                                   <Col span={10}>
                                        <br/> <br/>
                                        <Button htmlType='submit' className="btnCargaArchivos" style={{width: '100%'}}disabled={sinActivos}>Siguiente <ArrowRightOutlined /> </Button> 
                                   </Col>
                              </Row>
                         </Form>
                    </Spin>
               </div>
          );   
     }
}

const mapStateToProps = state => {
	return {
          fetchingNuevaSalidaDeBienes: state. AlmacenesReducer.fetchingNuevaSalidaDeBienes,
          showModalSalidaDeBienes: state.AlmacenesReducer.showModalSalidaDeBienes,
          page: state.AlmacenesReducer.page,
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          listaAsignacionActivosActivos: state.AlmacenesReducer.listaAsignacionActivosActivos,
          fetchingListaAsignacionActivosActivos: state.AlmacenesReducer.fetchingListaAsignacionActivosActivos,
          salidaActivoSeleccionado: state.AlmacenesReducer.salidaActivoSeleccionado,
          listaSalidaDeBienesBusqueda: state.AlmacenesReducer.listaSalidaDeBienesBusqueda,

          tipoMovimiento: state.AlmacenesReducer.tipoMovimiento,
          motivoSalida: state.AlmacenesReducer.motivoSalida,
          servicioSalida: state.AlmacenesReducer.servicioSalida,
          fetchingListaUbicacionesFiltro: state.CatalogosGeneralesReducer.fetchingListaUbicacionesFiltro,
          listaUbicacionesFiltro: state.CatalogosGeneralesReducer.listaUbicacionesFiltro,
          datosSolicitudSeleccionados: state.AlmacenesReducer.datosSolicitudSeleccionados,
          listaEstatusActivoActivos: state.AlmacenesReducer.listaEstatusActivoActivos,
          fetchingListaEstatusActivoActivos: state.AlmacenesReducer.fetchingListaEstatusActivoActivos,
          selectedRowKeys: state.AlmacenesReducer.selectedRowKeys,
          listaActivosActivos: state.AlmacenesReducer.listaActivosActivos,

          listaActivosEnOperacionServicio: state.AlmacenesReducer.listaActivosEnOperacionServicio
	};
};

const mapDispatchToProps = dispatch => {
	return {
          onShowModalSalidaDeBienes: () => {
               dispatch({ type: 'SHOW_MODAL_SALIDA_BIENES', showModalSalidaDeBienes: false })
          },
          
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
          onGetUsuarioRes: () => {
               dispatch({ type: 'LISTA_USUARIOS_ACTIVOS_REQUEST' });
          },
          onGetUbicaciones: () => {
               dispatch({ type: 'LISTA_UBICACIONES_ACTIVOS_REQUEST' });
          },
          onListaUbicacionesFiltro: (busqueda) => {
               dispatch({ type: 'LISTA_UBICACIONES_FILTRO_REQUEST', busqueda: busqueda });
          },
          onListaUbicacionesFiltroLimpiar: () => {
               dispatch({ type: 'LISTA_UBICACIONES_FILTRO_SUCCESS', listaUbicacionesFiltro: [] });
          },
          onCambiarVista: (vistaModalSalidaDeBienes, formularioSalidaDeBienes, salidaActivoSeleccionado) => {
               dispatch({ type: 'CAMBIAR_VISTA_SALIDA_BIENES', vistaModalSalidaDeBienes: vistaModalSalidaDeBienes, formularioSalidaDeBienes: formularioSalidaDeBienes, salidaActivoSeleccionado: salidaActivoSeleccionado })
          },
          onActualizarTabla: (salidaActivoSeleccionado, selectedRowKeys, hasSelected, page) => {
               dispatch({ type: 'ACTUALIZAR_TABLA_SALIDA', salidaActivoSeleccionado, selectedRowKeys, hasSelected, page})
          }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAsignarActivos);