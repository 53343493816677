import React from 'react';
import { Row, Col, Modal, Table, Input, Button, Select, message, Form, DatePicker, Card, Tooltip } from 'antd';
import { FileSearchOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import moment from 'moment';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css'

import ModalDetalleActivo from './ModalDetalleActivo';

const confirm = Modal.confirm;
const { Search } = Input;
const { Option } = Select;

class RequisicionActualizacionTecnologica extends React.Component {
    formRef = React.createRef();

    state = {
        page: 1,
        busqueda: '',
        seletedRange: false,
        rangoRenovacionData: {},
    }

    componentDidMount() {
        this.props.onGetAreasFuncionales();
        this.props.onGetRangosRenovacion();
        this.props.onGetServiciosActivos();
        this.props.onGetUnidadesMedida();
        this.props.onSetActualizacionActivosInicial();
        this.props.onRequestInfoProyecto();
        this.props.onListaServiciosFiltro('')
    }

    render() {

        const {fetchingListaServiciosFiltro, listaServiciosFiltro, onListaServiciosFiltro, onListaServiciosFiltroLimpiar, listaAreasFuncionalesActivas, fetchingListaAreasFuncionalesActivas,
            fetchingListaRangosRenovacionActivoActivos, listaRangosRenovacionActivoActivos, onShowModalDetalleActivo, onGetActivosActualizacion, listaServiciosActivosF, listaUnidadesDeMedicionActivos,
            listaActualizacionActivosBusqueda, listaActualizacionActivosBusquedaCantidad, onDescargaArchivoActivos, listaInfoProyecto, fetchingListaActualizacionActivosBusqueda,
            fetchingActualizacionActivosDescarga,
        } = this.props;

        let columns =[    
            {
                title: 'Descripción',
                dataIndex: 'descripcion',
                key: 'descripcion',
                width: '5%',
            }, {
                title: 'Grupo de servicios',
                dataIndex: 'nombreDeGrupoDeServicio',
                key: 'nombreDeGrupoDeServicio',
                width: '4%',
            }, {
                title: 'Familia',
                dataIndex: 'familia',
                key: 'familia',
                width: '4%',
            }, {
                title: 'Subfamilia',
                dataIndex: 'subFamilia',
                key: 'subFamilia',
                width: '4%'
            }, {
                title: 'Centro de costo',
                dataIndex: 'centroCosto',
                key: 'centroCosto',
                width: '4%'
            }, {
                title: 'Clave ubicación',
                dataIndex: 'claveUbicacion',
                key: 'claveUbicacion',
                width: '5%'
            }, {
                title: 'Área funcional',
                dataIndex: 'areafuncional',
                key: 'areafuncional',
                width: '5%'
            }, {
                title: 'Unidad funcional',
                dataIndex: 'unidadFuncional',
                key: 'unidadFuncional',
                width: '5%'
            }, {
                title: 'Interior',
                dataIndex: 'interior',
                key: 'interior',
                width: '5%'
            }, {
                title: 'SubInterior',
                dataIndex: 'subInterior',
                key: 'subInterior',
                width: '5%'
            }, {
                title: 'Detalle',
                key: 'detalle',
                width: '3%',
                align:'center',
                render: (text, record) => (
                    <a className='editarIcono'  onClick={() => onDetalle(record)} ><FileSearchOutlined /></a>
                ),
            },
        ];
        
        const hoy = new Date();
        let dia = hoy.getDate();
        if (dia < 10) {
            dia = '0' + dia;
        }
        let mes = hoy.getMonth() + 1;
        if (mes < 10) {
            mes = '0' + mes;
        }
        const fechaActual = dia + '/' + mes + '/' + hoy.getFullYear();
        console.log(fechaActual);

        let fechaVigencia = moment(listaInfoProyecto.fechaVigencia, "DD-MM-YYYY")
        let fechaFormat = moment(fechaActual, "DD-MM-YYYY");

        const disabledRange = fecha => {
            if (fecha && fecha < fechaFormat) {
                return fecha && fecha < fechaFormat;
            } else {
                return fecha && fecha > fechaVigencia;
            }

        }


        const buscarServicios = value => {
            if (value.length >= 1) {
                if (value) {
                    console.log(value);
                    onListaServiciosFiltro(value)
                } else {
                    message.warning('No se encontró servicio con esas palabras')
                }
            } else {
                onListaServiciosFiltroLimpiar();
            }
        }

        const onFinish = (formulario) =>{
            this.setState({seletedRange:false});
            const rangoRenovacion = this.formRef.current.getFieldValue('idRangoRenovacion');
            if(rangoRenovacion) {
                this.setState({seletedRange:true});
            }
            console.log(formulario, this.state.busqueda, this.state.page)
            onGetActivosActualizacion(formulario, this.state.busqueda, this.state.page, listaAreasFuncionalesActivas, listaServiciosActivosF, this.state.rangoRenovacionData);

        }

        const onSearch = busqueda =>{
            this.setState({
                busqueda: busqueda,
                page: 1,
            });
            const formulario = this.formRef.current.getFieldsValue();
            console.log(formulario, busqueda, this.state.page);
            onGetActivosActualizacion(formulario, busqueda, this.state.page, listaAreasFuncionalesActivas, listaServiciosActivosF, this.state.rangoRenovacionData);
        }

        const onDescargaArchivo = () => {
            const formulario = this.formRef.current.getFieldsValue();
            console.log(formulario, this.state.busqueda,);
            onDescargaArchivoActivos(formulario, this.state.busqueda, listaAreasFuncionalesActivas, listaServiciosActivosF, this.state.rangoRenovacionData);
        }

        const onDetalle = (key) => { console.log('key', key); onShowModalDetalleActivo(key); }

        const unidadesMedidaDia = {"Horas":1/24,"Días":1, "Semanas": 7, "Meses":31, "Años":365}

        const onChangeRango = (value, data) => {
            console.log(data)
            this.setState({rangoRenovacion: {}});
            if (value){
                const unidadMedicionSeleccion = listaUnidadesDeMedicionActivos.find(item => item.idUnidadMedida == data.dataRango.unidadMedida)
                let rangoRenovacionData = data.dataRango;
                rangoRenovacionData.unidadMedida = unidadMedicionSeleccion.unidadMedida;
                rangoRenovacionData.maximoDias = rangoRenovacionData.maximo*(unidadesMedidaDia[rangoRenovacionData.unidadMedida]);
                rangoRenovacionData.minimoDias = rangoRenovacionData.minimo*(unidadesMedidaDia[rangoRenovacionData.unidadMedida]);
                console.log("rangoRenovacionData",rangoRenovacionData)
                this.setState({rangoRenovacionData:rangoRenovacionData});
            }
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            const formulario = this.formRef.current.getFieldsValue();
            onGetActivosActualizacion(formulario, this.state.busqueda, pagination.current, listaAreasFuncionalesActivas, listaServiciosActivosF, this.state.rangoRenovacionData);
        }

        const scroll = {"x": 2500 | true, "y": 1000};

        console.log("listaRangosRenovacionActivoActivos en component",listaRangosRenovacionActivoActivos)

        return (
            <Card className="cardCatalogos" title={<div className="headerLista">Monitor de Actualización Tecnológica</div>}>
                <Form 
                    name="formulario"
                    initialValues={{"fecha": moment(fechaActual,"DD-MM-YYYY")}}
                    onFinish={onFinish}
                    
                    ref={this.formRef}>                
                
                    <Row justify='space-between' gutter={[8,8]}>
                        <Col span={9}>
                            <Form.Item
                                name="idServicio"
                            >    
                                <Select 
                                    mode='multiple'
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona un servicio"
                                    loading={fetchingListaServiciosFiltro}
                                    showSearch
                                    filterOption={false}
                                    onSearch={buscarServicios}
                                    notFoundContent={null}
                                >
                                    {listaServiciosFiltro.map(option => <Option key={option.idServicio + '-' + option.nombre}>{option.idServicio + '-' + option.nombre}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item
                                name="idAreaFuncional"
                            >
                                <Select 
                                    mode='multiple'
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona el área funcional"
                                    loading={fetchingListaAreasFuncionalesActivas}
                                >
                                    {listaAreasFuncionalesActivas.map(option => <Option key={option.idAreaFuncional}>{option.areaFuncional}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name="idRangoRenovacion"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona rango de renovación"
                                    loading={fetchingListaRangosRenovacionActivoActivos}
                                    onChange={onChangeRango}
                                >
                                    {listaRangosRenovacionActivoActivos.map(option => <Option dataRango={{maximo:option.maxNum, minimo:option.minNum, unidadMedida:option.idUnidadMedida}}
                                    key={option.idRangoRenovacion}>{option.descripcion}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                name="fecha"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <DatePicker 
                                    placeholder="Selecciona fecha"
                                    style={{ width: '100%' }}
                                    format='DD-MM-YYYY'
                                    disabledDate={disabledRange}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={1}>
                            <Button shape="circle" icon={<SearchOutlined />} className="btnFiltrar" htmlType="submit"></Button>
                        </Col>

                        <Col span={23}>
                            <Search className='buscarModulos' placeholder='Ingresar código interno, SKU o descripción del activo'
                            onSearch = {value=> onSearch(value)}  />
                        </Col>

                        <Col span={1}>
                            <Tooltip title='Exportar archivo .xls'>
                                <Button className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } loading={fetchingActualizacionActivosDescarga} onClick={onDescargaArchivo} disabled={listaActualizacionActivosBusqueda ? false:true}/>
                            </Tooltip>
                        </Col>
                        <Col span={24}>
                            <br />
                            <Table
                                size="small"
                                rowKey="descripcion"
                                columns={columns}
                                dataSource={listaActualizacionActivosBusqueda}
                                loading={fetchingListaActualizacionActivosBusqueda}
                                locale={{ emptyText: 'Sin datos' }}
                                onChange={handleTableChange}
                                pagination={{ total: listaActualizacionActivosBusquedaCantidad, simple: true, current: this.state.page }}
                                scroll={scroll}
                            />
                        </Col>
                        {/* <Col span={22}>
                                <Button className="btnFiltrar" loading={fetchingActualizacionActivosDescarga} onClick={onDescargaArchivo} disabled={listaActualizacionActivosBusqueda ? false:true}  style={{ width: '40%' }} >Exportar a Excel</Button>
                        </Col> */}
                    </Row>
                    <ModalDetalleActivo />
                </Form>
            </Card>
        )
        
        
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
        listaAreasFuncionalesActivas: state.AlmacenesReducer.listaAreasFuncionalesActivas,
        fetchingListaAreasFuncionalesActivas: state.AlmacenesReducer.fetchingListaAreasFuncionalesActivas,
        fetchingListaRangosRenovacionActivoActivos: state.AlmacenesReducer.fetchingListaRangosRenovacionActivoActivos,
        listaRangosRenovacionActivoActivos: state.AlmacenesReducer.listaRangosRenovacionActivoActivos,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        listaUnidadesDeMedicionActivos: state.AlmacenesReducer.listaUnidadesDeMedicionActivos,
        fetchingListaUnidadesDeMedicionActivos: state.AlmacenesReducer.fetchingListaUnidadesDeMedicionActivos,
        listaActualizacionActivosBusqueda: state.AlmacenesReducer.listaActualizacionActivosBusqueda,
        listaActualizacionActivosBusquedaCantidad: state.AlmacenesReducer.listaActualizacionActivosBusquedaCantidad,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        fetchingListaActualizacionActivosBusqueda: state.AlmacenesReducer.fetchingListaActualizacionActivosBusqueda,
        fetchingActualizacionActivosDescarga: state.AlmacenesReducer.fetchingActualizacionActivosDescarga,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
        },
        onGetAreasFuncionales: () => {
            dispatch({ type: "LISTA_AREA_FUNCIONAL_ACTIVA_REQUEST", });
        },
        onGetServiciosActivos: () => {
            dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
        },
        onGetRangosRenovacion: () => {
            dispatch({ type: "LISTA_RANGOS_RENOVACION_ACTIVOS_REQUEST"});
        },
        onGetUnidadesMedida: () => {
            dispatch({ type: "LISTA_UNIDADES_MEDICION_ACTIVOS_REQUEST"});
        },
        onShowModalDetalleActivo: (ActualizacionActivoSeleccionado, page) => {
            dispatch({ type: 'SHOW_MODAL_ACTUALIZACION_ACTIVO_DETALLE', showModalDetalleActivo: true, ActualizacionActivoSeleccionado: ActualizacionActivoSeleccionado, page: page });
        },
        onGetActivosActualizacion: (formulario, busqueda, page, listaAreasFuncionalesActivas,listaServiciosActivosF, rangoRenovacionData) => {
            dispatch({ type: 'ACTUALIZACION_ACTIVO_BUSQUEDA_REQUEST', formulario: formulario, busqueda: busqueda, page:page, listaAreasFuncionalesActivas:listaAreasFuncionalesActivas, listaServiciosActivosF:listaServiciosActivosF, rangoRenovacionData:rangoRenovacionData});
        },
        onSetActualizacionActivosInicial : () => {
            dispatch({ type: 'ACTUALIZACION_ACTIVO_BUSQUEDA_SUCCESS'})
        },
        onDescargaArchivoActivos: (formulario, busqueda, listaAreasFuncionalesActivas,listaServiciosActivosF, rangoRenovacionData) => {
            dispatch({ type: 'ACTUALIZACION_ACTIVO_DESCARGA_REQUEST', formulario: formulario, busqueda: busqueda, listaAreasFuncionalesActivas:listaAreasFuncionalesActivas, listaServiciosActivosF:listaServiciosActivosF, rangoRenovacionData:rangoRenovacionData});
        },
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        },
        
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequisicionActualizacionTecnologica);