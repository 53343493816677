import React, {Component} from 'react'
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    message,
    Modal,
    Row,
    Select,
    Spin,
    Switch, Table,
    Tooltip,
    Upload
} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import {connect} from "react-redux";
import {DownloadOutlined, EditOutlined, EyeOutlined, UploadOutlined} from '@ant-design/icons';
import moment from 'moment';

const layout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};
const {Option} = Select

class ModalNuevoPlanCorrectivo extends Component {
    formRef = React.createRef();
    state = {
        filePDF: false,
        botonPDF: false,
        botonCrear: false,
        origen: null,
        idServicio: null,
        idFolioEstandar: null,
        noProcede: true,
        listaActividades: [],
        actividad: '',
        responsable: '',
        fechaCompromiso: null,
        fechaCompromisoString: ''
    }

    render() {
        const {
            onNuevoPlanCorrectivo, onShowModalNuevoPlanCorrectivo, showModalNuevoPlanCorrectivo,
            fetchingNuevoPlanCorrectivo, listaInfoProyecto, page, limpiarFormulario, busqueda,
            listaServiciosActivosF, fetchingListaServiciosActivosF, orden, fetchingListaMesesActivos,
            listaMesesActivos, fetchinglistaFoliosEstandares, listaFoliosEstandares, fetchingListaFoliosSolicitudes,
            listaFoliosSolicitudes, fetchingListaEstandaresDesempeñoAuditorias, listaEstandaresDesempeñoAuditorias,
            fetchingListaEstandaresDesempeño, listaEstandaresDesempeño
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            if (this.state.filePDF) {
                onNuevoPlanCorrectivo(formulario, page, busqueda, orden, this.state.listaActividades);
            } else {
                message.error('Favor de adjuntar un archivo PDF', 8);
            }
        };

        if (limpiarFormulario && limpiarFormulario === 'crearPlanCorrectivo') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
            this.setState({
                filePDF: false,
                botonPDF: false,
                botonCrear: false,
                origen: null,
                idServicio: null,
                idFolioEstandar: null,
                noProcede: true,
                listaActividades: [],
                actividad: '',
                responsable: '',
                fechaCompromiso: null,
                fechaCompromisoString: ''
            })
        }
        const handleCancel = () => {
            onShowModalNuevoPlanCorrectivo(page, busqueda);
            this.formRef.current.resetFields();
            this.setState({
                filePDF: false,
                botonPDF: false,
                botonCrear: false,
                origen: null,
                idServicio: null,
                idFolioEstandar: null,
                noProcede: true,
                listaActividades: [],
                actividad: '',
                responsable: '',
                fechaCompromiso: null,
                fechaCompromisoString: ''
            })
        }

        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            } else {
                this.setState({
                    botonPDF: true,
                    botonCrear: true,
                })
            }
            if (info.file.status === 'done') {
                var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,40}$");
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                    this.setState({
                        filePDF: false,
                        botonPDF: false,
                    });
                } else {
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonCrear: false,
                });
            } else if (info.file.status === 'error') {
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                    this.setState({
                        filePDF: false,
                        botonPDF: false,
                    });
                } else {
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonCrear: false,
                });
            }
        }

        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
            this.formRef.current.resetFields(['pdf']);
        }
        const dummyRequest = ({file, onSuccess}) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };
        const onOrigen = (value) => {
            this.setState({
                origen: value
            })
            if (value === 'Encuesta de satisfacción') {
                this.props.onListaMesesActivos();
            }
        }
        const disabledDate = fecha => {
            const fechaInicio = moment(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos, "DD-MM-YYYY")
            const fechaVigencia = moment(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[0] + '-' + listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[1] + '-' + (parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2]) + listaInfoProyecto.vigencia), "DD-MM-YYYY");
            
            if (fecha && fecha < fechaInicio) {
                return fecha && fecha <= fechaInicio;
            } else {
                return fecha && fecha > fechaVigencia;
            }
        }
        const onServicioSeleccionado = (value) => {
            this.setState({
                idServicio: parseInt(value)
            });
            this.props.onListaFolioSolicitudesLimpiar();
            this.props.onListaFoliosEstandaresLimpiar();
            this.props.onListaEstandaresDesempeñoLimpiar();
            this.props.onListaEstandaresDesempeñoAuditoriasLimpiar()
            if (this.state.origen === "Quejas") {
                this.formRef.current.setFieldsValue({"idFolioSolicitud": undefined});
            } else if (this.state.origen === "Auditorías") {
                this.formRef.current.setFieldsValue({"idFolioEstandar": undefined});
            }
            this.formRef.current.setFieldsValue({"idEstandarDesempeño": undefined});
        }
        const onFolioEstandarSeleccionado = (value) => {
            this.setState({
                idFolioEstandar: parseInt(value)
            });
        }
        const onChangeProcede = (value) => {
            this.setState({
                noProcede: !value
            });
        }
        const buscarSolicitudes = (value) => {
            console.log(value)
            if (value.length >= 3) {
                this.props.onListaFoliosSolicitudes(value, this.state.idServicio)
            } else {
                this.props.onListaFolioSolicitudesLimpiar();
            }
        }
        const buscarFolioEstandar = (value) => {
            console.log(value)
            if (value.length >= 3) {
                this.props.onlistaFoliosEstandares(value, this.state.idServicio)
            } else {
                this.props.onListaFoliosEstandaresLimpiar();
            }
        }
        const onAddActividad = () => {
            console.log(`this.state.fechaCompromisoString`, this.state.fechaCompromisoString)
            if (this.state.actividad.length > 0 && this.state.fechaCompromisoString.length > 0) {
                this.state.listaActividades.push({
                    actividad: '' + this.state.actividad,
                    fechaCompromiso: '' + this.state.fechaCompromisoString,
                    responsable: '' + this.state.responsable
                });
                this.setState({
                    actividad: '',
                    fechaCompromiso: null,
                    fechaCompromisoString: '',
                    responsable: ''
                });
                this.formRef.current.resetFields(['actividad', 'fechaCompromiso', 'responsable']);
            }
        }
        const buscarEstandaresDesempeñoAuditorias = (value) => {
            console.log(value)
            if (this.state.idFolioEstandar) {
                if (value.length >= 3) {
                    this.props.onListaEstandaresDesempeñoAuditorias(value, this.state.idFolioEstandar)
                } else {
                    this.props.onListaEstandaresDesempeñoAuditoriasLimpiar();
                }
            } else {
                if (value.length > 0) {
                    message.error('Selecciona antes un servicio', 2);
                }
            }
        }
        const buscarEstandaresDesempeño = (value) => {
            console.log(value)
            if (this.state.idServicio) {
                if (value.length >= 3) {
                    this.props.onListaEstandaresDesempeño(value, this.state.idServicio)
                } else {
                    this.props.onListaEstandaresDesempeñoLimpiar();
                }
            } else {
                if (value.length > 0) {
                    message.error('Selecciona antes un servicio', 2);
                }
            }
        }
        console.log(listaFoliosSolicitudes);
        return (
            <Modal
                visible={showModalNuevoPlanCorrectivo}
                title='Nuevo Plan Correctivo'
                onCancel={handleCancel}
                footer={false}
                width='60%'
            >
                <Spin spinning={fetchingNuevoPlanCorrectivo}>
                    <Form {...layout}
                          name="formulario"
                          onFinish={onFinish}
                          ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}> 
                                <Form.Item
                                    label="Servicio"
                                    name="servicio"
                                    rules={[{required: true, message: 'Favor de llenar el campo',}]}
                                >
                                    <Select allowClear
                                            style={{width: '100%'}}
                                            placeholder="Selecciona un servicio"
                                            loading={fetchingListaServiciosActivosF}
                                            showSearch={true}
                                            onChange={onServicioSeleccionado}
                                    >
                                        {listaServiciosActivosF.map(option => <Option
                                            key={option.idServicio + ' - ' + option.nombre}>{option.idServicio + ' - ' + option.nombre}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Origen"
                                    name="origen"
                                    rules={[{required: true, message: 'Favor de llenar el campo',}]}
                                >
                                    <Select
                                        allowClear
                                        style={{width: '100%'}}
                                        placeholder="Selecciona el origen"
                                        onChange={onOrigen}
                                    >
                                        <Option key='Encuesta de satisfacción'>Encuesta de satisfacción</Option>
                                        <Option key='Quejas'>Quejas</Option>
                                        <Option key='Auditorías'>Auditorías</Option>
                                        <Option key='Otro'>Otro</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            {this.state.origen === 'Encuesta de satisfacción' ? (
                                <Col span={11}>
                                    <Form.Item
                                        label="Mes"
                                        name="idMes"
                                        rules={[{required: true, message: 'Favor de llenar el campo',}]}
                                    >
                                        <Select allowClear
                                                style={{width: '100%'}}
                                                placeholder="Selecciona un mes"
                                                loading={fetchingListaMesesActivos}
                                                showSearch={true}
                                        >
                                            {listaMesesActivos.map(option => <Option
                                                key={option.idMes}>{option.mes}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            ) : false}
                            {this.state.origen === 'Quejas' ? (
                                <Col span={11}>
                                    <Form.Item
                                        label="¿Procede la queja?"
                                        name="procede"
                                    >
                                        <Switch onChange={onChangeProcede} checkedChildren="Procede la queja"
                                                unCheckedChildren="No procede la queja"/>
                                    </Form.Item>
                                </Col>
                            ) : false}
                            {this.state.origen === 'Quejas' && this.state.noProcede ? (
                                <Col span={11}>
                                    <Form.Item
                                        label="Motivo de improcedencia"
                                        name="motivoImprocedencia"
                                        rules={[{required: true, message: 'Favor de llenar el campo'}]}
                                    >
                                        <Input maxLength={450} placeholder='Ingresa el motivo de improcedencia'/>
                                    </Form.Item>
                                </Col>
                            ) : false}
                            {this.state.origen === 'Quejas' && this.state.idServicio && !this.state.noProcede ? (
                                <Col span={11}>
                                    <Form.Item
                                        label="Folio Solicitud"
                                        name="idFolioSolicitud"
                                        rules={[{required: true, message: 'Favor de llenar el campo',}]}
                                    >
                                        <Select allowClear
                                                style={{width: '100%'}}
                                                placeholder="Selecciona un folio"
                                                onSearch={buscarSolicitudes}
                                                loading={fetchingListaFoliosSolicitudes}
                                                notFoundContent={null}
                                                showSearch={true}
                                                filterOption={false}
                                        >
                                            {listaFoliosSolicitudes.map(option => <Option
                                                key={option.idSolicitudServicio}><Tooltip
                                                title={option.folio + ': ' + option.descripcionSolicitud}>{option.folio + ': ' + option.descripcionSolicitud}</Tooltip></Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            ) : false}
                            {this.state.origen === 'Auditorías' && this.state.idServicio ? (
                                <Col span={11}>
                                    <Form.Item
                                        label="Folio Estandar"
                                        name="idFolioEstandar"
                                        rules={[{required: true, message: 'Favor de llenar el campo',}]}
                                    >
                                        <Select allowClear
                                                style={{width: '100%'}}
                                                placeholder="Selecciona un folio"
                                                onSearch={buscarFolioEstandar}
                                                onChange={onFolioEstandarSeleccionado}
                                                loading={fetchinglistaFoliosEstandares}
                                                notFoundContent={null}
                                                showSearch={true}
                                                filterOption={false}
                                        >
                                            {listaFoliosEstandares.map(option => <Option
                                                key={option.idFolioEstandar}><Tooltip
                                                title={option.folioEstandar}>{option.folioEstandar}</Tooltip></Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            ) : false}
                            {this.state.origen === 'Otro' ? (
                                <Col span={11}>
                                    <Form.Item
                                        label="Otro origen"
                                        name="otro"
                                        rules={[{required: true, message: 'Favor de llenar el campo',}]}
                                    >
                                        <Input maxLength={30} placeholder='Ingresa el origen'/>
                                    </Form.Item>
                                </Col>
                            ) : false}
                            {this.state.origen === 'Auditorías' ? (
                                <Col span={11}>
                                    <Form.Item
                                        label="Estándar de desempeño"
                                        name="idEstandarDesempeño"
                                        rules={[{required: true, message: 'Favor de llenar el campo',}]}
                                    >
                                        <Select allowClear
                                                style={{width: '100%'}}
                                                placeholder="Selecciona un estandar de desempeño"
                                                onSearch={buscarEstandaresDesempeñoAuditorias}
                                                loading={fetchingListaEstandaresDesempeñoAuditorias}
                                                notFoundContent={null}
                                                showSearch={true}
                                                filterOption={false}
                                        >
                                            {listaEstandaresDesempeñoAuditorias.map(option => <Option
                                                key={option.idEstandarDesempeno}><Tooltip placement="topLeft"
                                                title={option.idEstandarDesempeno + ': ' + option.descripcion}>{option.idEstandarDesempeno + ': ' + option.descripcion}</Tooltip></Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>

                            ) : false}
                            {this.state.origen !== 'Auditorías' && !(this.state.origen === 'Quejas' && this.state.noProcede) ? (
                                <Col span={11}>
                                    <Form.Item
                                        label="Estándar de desempeño"
                                        name="idEstandarDesempeño"
                                        rules={[{required: true, message: 'Favor de llenar el campo',}]}
                                    >
                                        <Select allowClear
                                                style={{width: '100%'}}
                                                placeholder="Selecciona un estandar de desempeño"
                                                onSearch={buscarEstandaresDesempeño}
                                                loading={fetchingListaEstandaresDesempeño}
                                                notFoundContent={null}
                                                showSearch={true}
                                                filterOption={false}
                                        >
                                            {listaEstandaresDesempeño.map(option => <Option
                                                key={option.idEstandarDesempeno}><Tooltip placement="topLeft"
                                                title={option.idEstandarDesempeno + ': ' + option.descripcion}>{option.idEstandarDesempeno + ': ' + option.descripcion}</Tooltip></Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            ) : false}
                            {!(this.state.origen === 'Quejas' && this.state.noProcede) ? (
                                <Col span={11}>
                                    <Form.Item
                                        label="Fecha de incumplimiento"
                                        name="fechaIncumplimiento"
                                        rules={[{required: true, message: 'Favor de llenar el campo'}]}
                                    >
                                        <DatePicker
                                            style={{width: '100%'}}
                                            format='DD-MM-YYYY'
                                            disabledDate={disabledDate}
                                            showToday={false}
                                        />
                                    </Form.Item>
                                </Col>
                            ) : false}
                            {!(this.state.origen === 'Quejas' && this.state.noProcede) ? (
                                <Col span={11}>
                                    <Form.Item
                                        label="Descripción"
                                        name="incumplimiento"
                                        rules={[{required: true, message: 'Favor de llenar el campo'}]}
                                    >
                                        <Input maxLength={450} placeholder='Ingresa la descripción'/>
                                    </Form.Item>
                                </Col>
                            ) : false}
                            <Col span={11}>
                                <Form.Item
                                    label="Responsable de atención"
                                    name="responsableAtencion"
                                    rules={[{required: true, message: 'Favor de llenar el campo',}]}
                                >
                                    <Input maxLength={150} placeholder='Ingresa el responsale de atención'/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Adjuntar archivo"
                                    name="pdf"
                                    rules={[{required: true, message: 'Favor de llenar el campo',}]}
                                >
                                    <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF}
                                            accept='.pdf' multiple={false}>
                                        {this.state.filePDF === false ? (
                                            <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                                <UploadOutlined/> Click para adjuntar archivo PDF
                                            </Button>
                                        ) : false}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        {!(this.state.origen === 'Quejas' && this.state.noProcede) ? (
                            <Row justify='space-around'>
                                <h3>Agregar Actividades</h3>
                            </Row>) : false}
                        {!(this.state.origen === 'Quejas' && this.state.noProcede) ? (
                            <Row justify='space-around' style={{ marginBottom: '10px' }} >
                                <Col span={7}>
                                    <Form.Item
                                        label='Responsable'
                                        name="responsable"
                                    >
                                        <Input onChange={(value) => this.setState({responsable: value.target.value})}
                                               value={this.state.responsable} maxLength={150}
                                               placeholder='Ingresa el responsable'/>
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        label='Actividad'
                                        name="actividad"
                                    >
                                        <Input onChange={(value) => this.setState({actividad: value.target.value})}
                                               value={this.state.actividad} maxLength={150}
                                               placeholder='Ingresa la actividad'/>
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        label='Fecha Compromiso'
                                        name="fechaCompromiso"
                                    >
                                        <DatePicker
                                            style={{width: '100%'}}
                                            format='DD-MM-YYYY'
                                            onChange={(date, dateString) => this.setState({fechaCompromiso: date, fechaCompromisoString: dateString})}
                                            value={this.state.fechaCompromiso}
                                            disabledDate={disabledDate}
                                            showToday={false}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Button className='btnCargaArchivos' style={{width: '100%'}} onClick={onAddActividad}>Añadir
                                        actividad</Button>
                                </Col>
                            </Row>) : false}
                        {!(this.state.origen === 'Quejas' && this.state.noProcede) ? (
                            <Row justify='space-around'>
                                <table style={{width: '90%'}}>
                                    <tr>
                                        <th>#</th>
                                        <th>Actividad</th>
                                        <th>Fecha</th>
                                        <th>Borrar</th>
                                    </tr>
                                    {this.state.listaActividades.map((actividad, index) =>
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{actividad.actividad}</td>
                                            <td>{actividad.fechaCompromiso}</td>
                                            <a onClick={() => {this.state.listaActividades.splice(index, 1); this.forceUpdate()}}>Borrar</a>
                                        </tr>
                                    )}
                                </table>
                            </Row>) : false}
                        <Row justify='space-around'>
                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button disabled={this.state.botonCrear} className="btnFiltrar" style={{width: '100%'}}
                                        htmlType="submit">Crear Plan Correctivo</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        showModalNuevoPlanCorrectivo: state.GestionDeCalidadReducer.showModalNuevoPlanCorrectivo,
        fetchingNuevoPlanCorrectivo: state.GestionDeCalidadReducer.fetchingNuevoPlanCorrectivo,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        page: state.GestionDeCalidadReducer.page,
        busqueda: state.GestionDeCalidadReducer.busqueda,
        orden: state.GestionDeCalidadReducer.orden,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaPlanCalidadAños: state.GestionDeCalidadReducer.listaPlanCalidadAños,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        fetchingListaMesesActivos: state.GestionDeCalidadReducer.fetchingListaMesesActivos,
        listaMesesActivos: state.GestionDeCalidadReducer.listaMesesActivos,
        fetchinglistaFoliosEstandares: state.GestionDeCalidadReducer.fetchinglistaFoliosEstandares,
        listaFoliosEstandares: state.GestionDeCalidadReducer.listaFoliosEstandares,
        fetchingListaFoliosSolicitudes: state.GestionDeCalidadReducer.fetchingListaFoliosSolicitudes,
        listaFoliosSolicitudes: state.GestionDeCalidadReducer.listaFoliosSolicitudes,
        fetchingListaEstandaresDesempeñoAuditorias: state.GestionDeCalidadReducer.fetchingListaEstandaresDesempeñoAuditorias,
        listaEstandaresDesempeñoAuditorias: state.GestionDeCalidadReducer.listaEstandaresDesempeñoAuditorias,
        fetchingListaEstandaresDesempeño: state.GestionDeCalidadReducer.fetchingListaEstandaresDesempeño,
        listaEstandaresDesempeño: state.GestionDeCalidadReducer.listaEstandaresDesempeño
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevoPlanCorrectivo: (page, busqueda) => {
            dispatch({type: 'MODAL_NUEVO_PLAN_CORRECTIVO', showModalNuevoPlanCorrectivo: false, page, busqueda});
        },
        onNuevoPlanCorrectivo: (formulario, page, busqueda, orden, listaActividades) => {
            dispatch({type: "NUEVO_PLAN_CORRECTIVO_REQUEST", formulario, page, busqueda, orden, listaActividades});
        },
        onLimpiarFormulario: () => {
            dispatch({type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null});
        },
        onListaMesesActivos: () => {
            dispatch({type: 'LISTA_MESES_ACTIVOS_REQUEST'});
        },
        onlistaFoliosEstandares: (param, idServicio) => {
            dispatch({type: 'LISTA_FOLIOS_ESTANDARES_REQUEST', param, idServicio});
        },
        onListaFoliosSolicitudes: (param, idServicio) => {
            dispatch({type: 'LISTA_FOLIOS_SOLICITUDES_REQUEST', param, idServicio});
        },
        onListaEstandaresDesempeñoAuditorias: (param, idFolioEstandar) => {
            dispatch({type: 'LISTA_ESTANDARES_DESEMPENO_AUDITORIAS_REQUEST', param, idFolioEstandar});
        },
        onListaEstandaresDesempeño: (param, idServicio) => {
            dispatch({type: 'LISTA_ESTANDARES_DESEMPENO_REQUEST', param, idServicio});
        },
        onListaFolioSolicitudesLimpiar: () => {
            dispatch({type: "LISTA_FOLIOS_SOLICITUDES_SUCCESS", listaFoliosSolicitudes: []});
        },
        onListaFoliosEstandaresLimpiar: () => {
            dispatch({type: "LISTA_FOLIOS_ESTANDARES_SUCCESS", listaFoliosEstandares: []});
        },
        onListaEstandaresDesempeñoAuditoriasLimpiar: () => {
            dispatch({type: "LISTA_ESTANDARES_DESEMPENO_AUDITORIAS_SUCCESS", listaEstandaresDesempeñoAuditorias: []});
        },
        onListaEstandaresDesempeñoLimpiar: () => {
            dispatch({type: "LISTA_ESTANDARES_DESEMPENO_SUCCESS", listaEstandaresDesempeño: []});
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoPlanCorrectivo);
