import React, { Component } from 'react';
import { Row, Form, Spin, Button, Col, Select, InputNumber, message, Divider } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class InsumosForm extends Component {
    formRef = React.createRef();

    state={
        listaInsumos: [],
        formularioEditar: [],
    }

    render() {
        const {  limpiarFormulario, onLimpiarFormulario, fetchingListaProductosActivosBusqueda, busqueda, page,
            listaProductosActivosBusqueda, listaInsumos,onListaProductosActivosBusqueda, onListaProductosActivosBusquedaLimpiar, listaSinRepetir,
            onActualizarListaSinRepetir,onEditarListaInsumosEstatus,estatusOrdenSPSeleccionado, onEditarInsumos, listaSinRepetirHerramienta,
            listaHerramientasActivasBusqueda, fetchingListaHerramientasActivasBusqueda, onListaHerramientasActivasBusquedaLimpiar, 
            onListaHerramientasActivasBusqueda, fetchinEditarInsumosEstatus, listaTareas, revisarInsumos
        } = this.props;
        
        if(limpiarFormulario && limpiarFormulario === 'CEOSP'){
            if(this.formRef.current){
                this.formRef.current.resetFields();
                this.props.onActualizarRevisarTareas(null, false);
                this.props.onActualizarRevisarInsumos(null, false);
            }
            onLimpiarFormulario();
            
        }

        let todoReprogramado = true;
        if(revisarInsumos){
            for(let tarea of listaTareas){
                if(tarea.fechasRutinasByIdOrdenServicio.estado !== 'Reprogramada'){
                    todoReprogramado = false;
                }
            }
            console.log('todoReprogramado', todoReprogramado)
            if(todoReprogramado){
                if(this.formRef.current){
                    this.formRef.current.resetFields();
                }
                onEditarListaInsumosEstatus({
                    insumos: estatusOrdenSPSeleccionado.insumosByOrden ? estatusOrdenSPSeleccionado.insumosByOrden : [],
                    herramientas: estatusOrdenSPSeleccionado.herramientasByOrden ? estatusOrdenSPSeleccionado.herramientasByOrden : []}, false);
            }
        }

        const onInteger = (value, field) => {
            if(value){
                if(! Number.isInteger(value)){
                    let formulario = this.formRef.current.getFieldsValue('insumos');
                    formulario.insumos[field.key].cantidad = parseInt(value);
                    this.formRef.current.setFieldsValue({'insumos': formulario.insumos});
                }
            }
        }
        
        const buscarActivoCarac = (value, tipo) => {
            if(value.length>=3){
                console.log(value)
                if(tipo === 'insumo')
                    onListaProductosActivosBusqueda(value);
                else
                    onListaHerramientasActivasBusqueda(value)
            }
            else{
                if(tipo === 'insumo')
                    onListaProductosActivosBusquedaLimpiar();
                else
                    onListaHerramientasActivasBusquedaLimpiar();
            }
        };
        
        const onProductoSeleccionado = (value, field) => {
            console.log(this.formRef)
            let formulario = this.formRef.current.getFieldsValue('insumos');
            console.log(value, field, formulario, listaSinRepetir)
            let repetido = false;
            let repetidoNumero = 0;
            if(listaSinRepetir){
                if(formulario.insumos.length !== listaSinRepetir.length){
                    if(listaSinRepetir){
                        for(let i in listaSinRepetir){
                            if(listaSinRepetir[i] === value){
                                formulario.insumos[field.name].insumo = null;
                                message.warning('No es posible seleccionar un producto que ya esté en la lista');
                                this.formRef.current.setFieldsValue({'insumos': formulario.insumos});
                                repetido = true;
                            }
                        }
                    }
                }
                else{
                    for(let i in formulario.insumos){
                        if(formulario.insumos[i].insumo === value){
                            
                            repetidoNumero += 1;
                        }
                    }
                    if(repetidoNumero>1){
                        formulario.insumos[field.name].insumo = null;
                        message.warning('No es posible seleccionar un producto que ya esté en la lista');
                        this.formRef.current.setFieldsValue({'insumos': formulario.insumos});
                    }
                }
            }
            
            console.log('despues de if nuevo y for', formulario, listaSinRepetir, repetidoNumero)
            if(!repetido){
                let listaActualizada = [];
                for(let x=0; x<formulario.insumos.length; x++){
                    listaActualizada.push(formulario.insumos[x]?.insumo ?? 'undefined'); //
                }
                onActualizarListaSinRepetir(listaActualizada, listaSinRepetirHerramienta)
            }
        }
        
        const onHerramientaSeleccionado = (value, field) => {
            console.log(this.formRef)
            let formulario = this.formRef.current.getFieldsValue('insumos');
            console.log(value, field, formulario, listaSinRepetirHerramienta)
            let repetido = false;
            let repetidoNumero = 0;
            if(listaSinRepetirHerramienta){
                if(formulario.herramientas.length !== listaSinRepetirHerramienta.length){
                    if(listaSinRepetirHerramienta){
                        for(let i in listaSinRepetirHerramienta){
                            if(listaSinRepetirHerramienta[i] === value){
                                formulario.herramientas[field.name].insumo = null;
                                message.warning('No es posible seleccionar un producto que ya esté en la lista');
                                this.formRef.current.setFieldsValue({'herramientas': formulario.herramientas});
                                repetido = true;
                            }
                        }
                    }
                }
                else{
                    for(let i in formulario.herramientas){
                        if(formulario.herramientas[i].insumo === value){
                            
                            repetidoNumero += 1;
                        }
                    }
                    if(repetidoNumero>1){
                        formulario.herramientas[field.name].insumo = null;
                        message.warning('No es posible seleccionar un producto que ya esté en la lista');
                        this.formRef.current.setFieldsValue({'herramientas': formulario.herramientas});
                    }
                }
            }
            
            console.log('despues de if nuevo y for', formulario, listaSinRepetirHerramienta, repetidoNumero)
            if(!repetido){
                let listaActualizadaHerramientas = [];
                for(let x=0; x<formulario.herramientas.length; x++){
                    listaActualizadaHerramientas.push(formulario.herramientas[x]?.insumo ?? 'undefined'); //
                }
                onActualizarListaSinRepetir(listaSinRepetir, listaActualizadaHerramientas)
            }
        }
        
        const onValuesChange =  values => {
            const formulario = this.formRef.current.getFieldsValue('insumos');
            onEditarListaInsumosEstatus(formulario, true);
            console.log(formulario, values, this.formRef);
        }
        
        const onFinish = (formulario) => {
            console.log('Formulario', formulario);
            onEditarInsumos(formulario, estatusOrdenSPSeleccionado, busqueda, page);
        };
        console.log('listaInsumos', listaInsumos)
    
        return (
            <Form {...layout}
            name="formulario"
            onFinish={onFinish}
            ref={this.formRef}
            initialValues={listaInsumos}
            onValuesChange={onValuesChange}
            >
                <Spin spinning={fetchinEditarInsumosEstatus}>
                    <Divider orientation="left">Insumos</Divider>
                    <Form.List name="insumos">
                        {(fields, { add, remove }) => (
                        <>
                        {console.log('fields', fields)}
                            {fields.map(field => (
                            <Row justify='space-around' key={field.key}>
                                <Col span={14}>
                                    <Form.Item
                                        {...field}
                                        label='Producto insumo'
                                        name={[field.name, 'insumo']}
                                        fieldKey={[field.fieldKey, 'insumo']}
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <Select
                                            disabled={todoReprogramado}
                                            style={{ width: '100%' }}
                                            placeholder="Ingresa nombre, clave o SKU del producto"
                                            loading={fetchingListaProductosActivosBusqueda}
                                            showSearch
                                            filterOption={false}
                                            onSearch={(value) => buscarActivoCarac(value,'insumo')}
                                            onChange={(value) => onProductoSeleccionado(value, field)}
                                            notFoundContent={null}
                                        >
                                            {listaProductosActivosBusqueda.map(option => <Option key={option.idProducto + ' - ' + option.nombre + ' - Unidad de medida: ' + option.unidadMedida}>{option.idProducto + ' - ' + option.nombre + ' - Unidad de medida: ' + option.unidadMedida}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        {...field}
                                        label='Cantidad'
                                        name={[field.name, 'cantidad']}
                                        fieldKey={[field.fieldKey, 'cantidad']}
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <InputNumber min={1} disabled={todoReprogramado} onChange={(value) => onInteger(value, field)} style={{width: '100%'}} placeholder='Ingresa la cantidad'/>
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    {fields.length > 0 && !todoReprogramado ? (
                                    <Form.Item>
                                        <MinusCircleOutlined className='borrarInsumo' onClick={() => remove(field.name)} />
                                    </Form.Item>
                                    ):false}
                                </Col>
                            </Row>
                            ))}
                            <Form.Item>
                                <Button type="dashed" disabled={todoReprogramado} onClick={() => add()} block icon={<PlusOutlined />}>
                                    Agregar Insumo
                                </Button>
                            </Form.Item>
                        </>
                        )}
                    </Form.List>
                    <br/>
                    <Divider>Agregar Herramientas</Divider>
                    <Form.List name="herramientas">
                        {(fields, { add, remove }) => (
                        <>
                            {console.log('fields en herramientas', fields)}
                            {fields.map(field => (
                            <Row justify='space-around' key={field.key}>
                                <Col span={14}>
                                    <Form.Item
                                        {...field}
                                        label='Herramienta'
                                        name={[field.name, 'insumo']}
                                        fieldKey={[field.fieldKey, 'insumo']}
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <Select
                                            disabled={todoReprogramado}
                                            style={{ width: '100%' }}
                                            placeholder="Ingresa nombre, clave o SKU de la herramienta"
                                            loading={fetchingListaHerramientasActivasBusqueda}
                                            showSearch
                                            filterOption={false}
                                            onSearch={(value) => buscarActivoCarac(value, 'herramienta')}
                                            onChange={(value) => onHerramientaSeleccionado(value, field)}
                                            notFoundContent={null}
                                        >
                                            {listaHerramientasActivasBusqueda.map(option => <Option key={option.idProducto + ' - ' + option.nombre + ' - Unidad de medida: ' + option.unidadMedida}>{option.idProducto + ' - ' + option.nombre + ' - Unidad de medida: ' + option.unidadMedida}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        {...field}
                                        label='Cantidad'
                                        name={[field.name, 'cantidad']}
                                        fieldKey={[field.fieldKey, 'cantidad']}
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <InputNumber disabled={todoReprogramado} min={1} onChange={(value) => onInteger(value, field)} style={{width: '100%'}} placeholder='Ingresa la cantidad'/>
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    {fields.length > 0 ? (
                                    <Form.Item>
                                        <MinusCircleOutlined disabled={todoReprogramado} className='borrarInsumo' onClick={() => remove(field.name)} />
                                    </Form.Item>
                                    ):false}
                                </Col>
                            </Row>
                            ))}
                            <Form.Item>
                                <Button type="dashed" disabled={todoReprogramado} onClick={() => add()} block icon={<PlusOutlined />}>
                                    Agregar Herramienta
                                </Button>
                            </Form.Item>
                        </>
                        )}
                    </Form.List>
                    <Row justify='space-around'>
                        <Col span={11} className='marginBoton'>
                            <Button className="btnFiltrar" disabled={todoReprogramado} style={{ width: '100%' }} htmlType="submit">Editar Insumos y Herramientas</Button>
                        </Col>  
                    </Row>
                </Spin>
            </Form>
        );
    }
}

const mapStateToProps = state => {
	return {
        page: state.ServiciosPlanificadosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        busqueda: state.ServiciosPlanificadosReducer.busqueda,
        estatusOrdenSPSeleccionado: state.ServiciosPlanificadosReducer.estatusOrdenSPSeleccionado,
        formularioRutina: state.ServiciosPlanificadosReducer.formularioRutina, //
        listaInsumos: state.ServiciosPlanificadosReducer.listaInsumos, 
        rutinaSeleccionada: state.ServiciosPlanificadosReducer.rutinaSeleccionada, //
        fetchingListaProductosActivosBusqueda: state.AlmacenesReducer.fetchingListaProductosActivosBusqueda,
        listaProductosActivosBusqueda: state.AlmacenesReducer.listaProductosActivosBusqueda,
        listaSinRepetir: state.ServiciosPlanificadosReducer.listaSinRepetir,
        listaSinRepetirHerramienta: state.ServiciosPlanificadosReducer.listaSinRepetirHerramienta,
        fetchingListaHerramientasActivasBusqueda: state.AlmacenesReducer.fetchingListaHerramientasActivasBusqueda,
        listaHerramientasActivasBusqueda: state.AlmacenesReducer.listaHerramientasActivasBusqueda,
        fetchinEditarInsumosEstatus: state.ServiciosPlanificadosReducer.fetchinEditarInsumosEstatus,
        revisarInsumos: state.ServiciosPlanificadosReducer.revisarInsumos,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onListaProductosActivosBusqueda: (busqueda) => {
            dispatch({ type: 'LISTA_PRODUCTOS_BUSQUEDA_REQUEST', busqueda });
        },
        onListaProductosActivosBusquedaLimpiar: () => {
            dispatch({ type: 'LISTA_PRODUCTOS_BUSQUEDA_SUCCESS', listaProductosActivosBusqueda: [] });
        },
        onActualizarListaSinRepetir: (listaSinRepetir, listaSinRepetirHerramienta) => {
            dispatch({ type: "ACTUALIZAR_LISTA_INSUMOS_SIN_REPETIR",  listaSinRepetir, listaSinRepetirHerramienta });
        },
        onEditarListaInsumosEstatus: (listaInsumos, revisarInsumos) => {
            dispatch({ type: 'ACTUALIZAR_LISTA_INSUMOS_ESTATUS',listaInsumos, revisarInsumos});
        },
        onEditarInsumos: (formulario, estatusOrdenSPSeleccionado, busqueda, page) => {
            dispatch({ type: 'EDITAR_INSUMOS_ESTATUS_REQUEST', formulario, estatusOrdenSPSeleccionado, busqueda, page})
        },
        onListaHerramientasActivasBusqueda: (busqueda) => {
            dispatch({ type: 'LISTA_HERRAMIENTAS_BUSQUEDA_REQUEST', busqueda });
        },
        onListaHerramientasActivasBusquedaLimpiar: () => {
            dispatch({ type: 'LISTA_HERRAMIENTAS_BUSQUEDA_SUCCESS', listaHerramientasActivasBusqueda: [] });
        },
        onActualizarRevisarTareas: (listaPersonal, revisarTareas) => {
            dispatch({ type: "ACTUALIZAR_LISTA_PERSONAL_ESTATUS",  listaPersonal, revisarTareas });
        },
        onActualizarRevisarInsumos: (listaInsumos, revisarInsumos) => {
            dispatch({ type: "ACTUALIZAR_LISTA_INSUMOS_ESTATUS",  listaInsumos, revisarInsumos });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InsumosForm);