import axios from "axios";

//const endpoint = "https://papantla-services.herokuapp.com/";
//const endpoint = "https://papantla-dev.herokuapp.com/";
const endpoint = 'https://morelos-dev.herokuapp.com/';
//const endpoint = "https://papantla-demo.herokuapp.com/";

//const endpoint = "http://172.24.0.170:8080/"

//const endpoint = 'https://papantla-services-jose.herokuapp.com/';
//const endpoint = 'https://papantla-dev-jose.herokuapp.com/';

//const endpoint = 'https://papantla-secret-jose.herokuapp.com/';
//const endpoint = 'https://papantla-secret.herokuapp.com/';

//Servicios para Mapa de Incidencias
export function areasConNumeroCall(token, idGrupoSer, estado, idServicio) {
	return axios({
	    method: "get",
		url: endpoint + "mapas/areas/num/ss?" + idGrupoSer + estado + idServicio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function unidadesConNumeroCall(token, idGrupoSer, estado, idServicio, idAreaFuncional, folio) {
	return axios({
	    method: "get",
		url: endpoint + "mapas/unidades/num/ss?idAreaFuncional=" + idAreaFuncional + idGrupoSer + estado + idServicio + folio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function interioresConNumeroCall(token, idGrupoSer, estado, idServicio, idUnidadFuncional, folio) {
	return axios({
	    method: "get",
		url: endpoint + "mapas/interiores/num/ss?idUnidadFuncional=" + idUnidadFuncional + idGrupoSer + estado + idServicio + folio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function subInterioresConNumeroCall(token, idGrupoSer, estado, idServicio, idInteriorFuncional, folio) {
	return axios({
	    method: "get",
		url: endpoint + "mapas/sub/interiores/num/ss?idInteriorFuncional=" + idInteriorFuncional + idGrupoSer + estado + idServicio + folio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function detalleSSCall(token, idGrupoSer, estado, idServicio, idAreaFuncional, idUnidadFuncional, idInteriorFuncional, idSubInteriorFuncional, folio, page) {
	return axios({
	    method: "get",
		url: endpoint + "mapas/color/ss/by?" + idAreaFuncional + idUnidadFuncional + idInteriorFuncional + idSubInteriorFuncional + idGrupoSer + estado + idServicio + folio  + page,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function infoDetalleSSCall(token, idSolicitudServicio) {
	return axios({
	    method: "get",
		url: endpoint + "mapas/detalle/ss/by/" +  idSolicitudServicio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

// Grupo de servicios activos paginado
export function grupoServiciosCall(token, page) {
	return axios({
	    method: "get",
		url: endpoint + "servicios/grupos?page="+page+"&offset=100",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

// obtener lista de servicios completo
export function servicioPorGrupoServiciosCall(token, page, idGrupoSer, tipo) {
	return axios({
	    method: "get",
		url: endpoint + "seguimiento/solicitud/servicios/tablero?page="+page+"&offset=10&idGrupoSer="+idGrupoSer + '&tipo=' + tipo,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

// obtener solicitudes de servicio 
export function solicitudesDeServicioPorServicioCall(token, idServicio, color, page, idGrupoSer) {
	return axios({
	    method: "get",
		url: endpoint + "seguimiento/solicitud/servicios/solicitudes/"+idServicio+"?color="+color+"&page="+page+"&offset=10&idGroupSer="+idGrupoSer,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function obtenerCantidadFoliosPorIdGrupoServCall(token, estado, idGrupoSer ) {
	return axios({
	    method: "get",
		url: endpoint + "seguimiento/solicitud/cantidad/grupo/?estado="+estado+"&idGrupoSer="+idGrupoSer,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function getDataEncuestasSatisfaccionCall(token, idGrupoSer, idCentroCosto, idClasificacionGerencia, idMes, anio ) {
	return axios({
	    	method: "get",
		url: endpoint + "monitoreo/calidad/encuestas/graficas?" + idGrupoSer + idCentroCosto + idClasificacionGerencia + idMes + anio ,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function getDataEncuestasDesempenoCall(token, idMes, anio, idServicio) {
	return axios({
	    	method: "get",
		url: endpoint + "monitoreo/calidad/encuestas/byServicio?" + idMes + anio + idServicio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function getDataQuejasReclamosCall(token, idGrupoSer, idServicio, idClasificacionGerencia) {
	return axios({
	    	method: "get",
		url: endpoint + "monitoreo/calidad/quejas/?" + idGrupoSer + idServicio + idClasificacionGerencia,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function getDataEvaluacionDesempenoCall(token, idMes, anio, idServicio, idGrupoSer, idClasificacionGerencia) {
	return axios({
	    	method: "get",
		url: endpoint + "monitoreo/calidad/desempeno?" + idMes + anio + idServicio + idGrupoSer + idClasificacionGerencia,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function listaServiciosByClasificacionGerenciaCall(token, idClasificacionGerencia) {
	return axios({
		method: "get",
		url: endpoint + 'servicios/gerencia?idClasificacionGerencia=' + idClasificacionGerencia,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function getDataPromedioSatisfaccionCall(token, idGrupoSer, idCentroCosto, idClasificacionGerencia, idMes, anio) {
	return axios({
	    	method: "get",
		url: endpoint + "monitoreo/calidad/encuestas/grafica/barras?" + idMes + anio +  idGrupoSer + idCentroCosto + idClasificacionGerencia,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function getDataEstandaresByTipoCall(token, idMes, anio, idServicio, idGrupoSer, idClasificacionGerencia) {
	return axios({
	    	method: "get",
		    url: endpoint + "monitoreo/calidad/desempeno/tipo?" + idMes + anio + idServicio + idGrupoSer + idClasificacionGerencia,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function getDataRDOSolicitudesServicioCall(token, idServicio, idGrupoSer, idPartida, idClasificacionGerencia) {
	return axios({
	    	method: "get",
		url: endpoint + "rdo/solicitudes?" + idServicio + idGrupoSer + idPartida + idClasificacionGerencia,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function getDataRDOServiciosPlanificadosCall(token, idServicio, idGrupoSer, idPartida, idClasificacionGerencia) {
	return axios({
	    	method: "get",
		url: endpoint + "rdo/servicios/planificados?" + idServicio + idGrupoSer + idPartida + idClasificacionGerencia,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function getDatosMonitorPlanificadosCall(token, idServicio, idGrupoSer, idPartida, idClasificacionGerencia) {
	return axios({
	    	method: "get",
		url: endpoint + "rdo/servicios/planificados/datos?" + idServicio + idGrupoSer + idPartida + idClasificacionGerencia,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function getDataRDOServiciosPublicosCall(token) {
	return axios({
	    	method: "get",
		url: endpoint + "rdo/servicios/publicos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

// Reporte Gráfico
export function getListReporteGraficoCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "solicitud/servicio/reporte/grafico/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getListReporteGraficoGrupoCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "solicitud/servicio/reporte/grafico/grupos",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}