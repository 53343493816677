import React, { Component } from 'react';
import { Row, Col, Modal, Table, Input, Button, Form, Tooltip, Spin} from 'antd';
import { SearchOutlined, FilePdfFilled, FileExcelFilled} from '@ant-design/icons';
import { connect } from "react-redux";

import ModalNuevaUbicacion from './ModalNuevaUbicacion';
import ModalEditarUbicacion from './ModalEditarUbicacion';

class CatalogoDeUbicaciones extends Component {
    componentDidMount(){  
        const formulario = {
            areaFuncional: '',
            unidadFuncional: '',
            interiorFuncional: '',
        }
        this.props.onRequestListaUbicaciones(1, formulario);
        this.props.onRequestListaAreasFuncionales();
    }
    state = {
        page: 1,
        formulario: {
            areaFuncional: '',
            unidadFuncional: '',
            interiorFuncional: '',
        },
    }
    render() {
        const { listaUbicacionesBusqueda, fetchingListaUbicacionesBusqueda, listaUbicacionesBusquedaCantidad,
            onRequestListaUbicaciones, fetchingExportarUbicaciones, onExportarPDF, onExportarExcel
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const onFinish = formulario => {
            this.setState({
                formulario: formulario,
                page: 1,
            })
            console.log(formulario);
            onRequestListaUbicaciones(1, formulario);
        }

        


        const columns = [{
                title: 'Clave ubicación',
                dataIndex: 'claveUbicacion',
                key: 'claveUbicacion',
            }, {
                title: 'Codigo área',
                dataIndex: 'codigoArea',
                key: 'codigoArea',
            },{
                title: 'Área funcional',
                dataIndex: 'areaFuncional',
                key: 'areaFuncional',
            },{
                title: 'Clave área',
                dataIndex: 'claveArea',
                key: 'claveArea',
            },{
                title: 'Clave unidad',
                dataIndex: 'claveUnidad',
                key: 'claveUnidad',
            }, {
                title: 'Unidad funcional',
                dataIndex: 'unidadFuncional',
                key: 'unidadFuncional',
            }, {
                title: 'Interior',
                dataIndex: 'interiorFuncional',
                key: 'interiorFuncional',
            }, {
                title: 'Sub Interior',
                dataIndex: 'nombreSubInteriorFuncional',
                key: 'nombreSubInteriorFuncional',
            }, {
                title: 'Fecha alta',
                dataIndex: 'fechaAlta',
                key: 'fechaAlta',
            }, 
            // {
            //     title: 'Activo',
            //     dataIndex: 'activo',
            //     key: 'activo',
            //     render: (text, record) => (
            //         <Switch checked={text} onChange={() => handleDesactivar(record)} />
            //     ),
            // }, 
            // {
            //     title: 'Editar',
            //     key: 'editar',
            //     render: (text, record) => (
            //         <a className='editarIcono' onClick={() => onEditar(record)}><EditOutlined /></a>
            //     ),
            // },
        ];
        
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onRequestListaUbicaciones(pagination.current, this.state.formulario);
		}

        const onExportarArchivo = (tipo) => {
            if(tipo === 'PDF'){
                onExportarPDF(this.state.formulario);
            }
            else{
                onExportarExcel(this.state.formulario);
            }
        }

        const scroll = {"x": 2000 | true, "y": 1000};
        return (
            <div>
                <Spin spinning={fetchingExportarUbicaciones}>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={24}>
                        <Form
                            name="formulario"
                            onFinish={onFinish}
                            
                        >
                            <Row justify='space-around' gutter={[8,8]}>
                                <Col span={6}>
                                    <Form.Item
                                        name="areaFuncional"
                                    >
                                        <Input className='inputFiltro' placeholder="Ingresa área funcional a filtrar" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        name="unidadFuncional"
                                    >
                                        <Input className='inputFiltro' placeholder="Ingresa unidad funcional a filtrar" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        name="interiorFuncional"
                                    >
                                        <Input className='inputFiltro' placeholder="Ingresa interior funcional a filtrar" />
                                    </Form.Item>
                                </Col>
                                <Col span={1}>
                                    <Button shape="circle" icon={<SearchOutlined />} className="btnFiltrar" htmlType="submit"></Button>
                                </Col>

                                <Col span={1}>
                                    <Tooltip title='Exportar archivo .pdf' placement="topRight">
                                        <Button disabled={!permisosUsuario.exportarPdfUbicación} onClick={()=>onExportarArchivo('PDF')} className="iconSecundary" shape='circle' icon={<FilePdfFilled /> }/>
                                    </Tooltip>
                                </Col>

                                <Col span={1}>
                                    <Tooltip title='Exportar archivo .xls' placement="topRight">
                                        <Button disabled={!permisosUsuario.exportarExcelUbicación} onClick={()=>onExportarArchivo('CSV')} className="iconSecundary" shape='circle' icon={<FileExcelFilled /> } />
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    {/* <Col span={5}>
                        <Button onClick={onNuevaUbicacion} className='nuevoUsuario'>Nueva Ubicacion</Button>
                        
                    </Col> */}

                    <Col span={24}>
                        <Table
                            size="small"
                            rowKey="claveUbicacion"
                            columns={columns}
                            dataSource={listaUbicacionesBusqueda}
                            loading={fetchingListaUbicacionesBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaUbicacionesBusquedaCantidad, simple: true, current: this.state.page }}
                            scroll={scroll}
                        />         
                    </Col>
                </Row>
                {/* <Row justify='space-around'>
                    <Col span={9}>
                        <Button className='btnCargaArchivos' disabled={!permisosUsuario.exportarPdfUbicación} loading={fetchingExportarUbicaciones} onClick={()=>onExportarArchivo('PDF')}><FilePdfFilled/> Exportar a PDF</Button>
                    </Col>
                    <Col span={9}>
                        <Button className='btnCargaArchivos' disabled={!permisosUsuario.exportarExcelUbicación} loading={fetchingExportarUbicaciones} onClick={()=>onExportarArchivo('CSV')}><FileExcelFilled/> Exportar a Excel</Button>
                    </Col>
                </Row> */}
                </Spin>
                <ModalNuevaUbicacion/>
                <ModalEditarUbicacion/>
            </div >
         );
    }
}

const mapStateToProps = state => {
    return {
        listaUbicacionesBusqueda: state.CatalogosGeneralesReducer.listaUbicacionesBusqueda,
        fetchingListaUbicacionesBusqueda: state.CatalogosGeneralesReducer.fetchingListaUbicacionesBusqueda,
        listaUbicacionesBusquedaCantidad: state.CatalogosGeneralesReducer.listaUbicacionesBusquedaCantidad,
        listaUbicacionesActivos: state.CatalogosGeneralesReducer.listaUbicacionesActivos,
        fetchingExportarUbicaciones: state.CatalogosGeneralesReducer.fetchingExportarUbicaciones,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRequestListaUbicaciones: (page, formulario) => {
            dispatch({ type: 'LISTA_UBICACIONES_BUSQUEDA_REQUEST', page: page, formulario: formulario})
        },
        onEstadoUbicaciones : (idUbicacion, page) => {
            dispatch({ type: 'ESTADO_UBICACIONES_REQUEST', idUbicacion: idUbicacion, page: page})
        },
        onModalNuevaUbicacion: ( page) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_UBICACION", showModalNuevoUbicaciones: true,  page: page});
        },
        onModalEditarUbicacion: (ubicacionSeleccionado, page ) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_UBICACION", showModalEditarUbicaciones: true, ubicacionSeleccionado: ubicacionSeleccionado, page: page});
        },
        onRequestListaAreasFuncionales: () => {
            dispatch({ type: "LISTA_AREASFUNCIONALES_ACTIVOS_REQUEST" });
        },
        onListaUnidadesFuncionalesPorArea: (idAreaFuncional) => {
            dispatch({ type: 'LISTA_UNI_FUNCIO_ACT_POR_AREA_REQUEST', idAreaFuncional });
        },
        onListaInterioresFuncionalesPorUnidad: (idUnidadFuncional) => {
            dispatch({ type: 'LISTA_INTERIORES_ACT_POR_UNIDAD_REQUEST', idUnidadFuncional });
        },
        onListaSubInterioresFuncionalesPorInterior: (idInteriorFuncional) => {
            dispatch({ type: 'LISTA_SUBINTERIOR_ACTIVOS_POR_INTERIOR_REQUEST', idInteriorFuncional });
        },
        onExportarPDF: (formulario) => {
            dispatch({ type: 'EXPORTAR_UBICACIONES_PDF_REQUEST', formulario})
        },
        onExportarExcel: (formulario) => {
            dispatch({ type: 'EXPORTAR_UBICACIONES_EXCEL_REQUEST', formulario})
        },
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoDeUbicaciones);