import React, { Component } from 'react';
import { Form, Button, Row, Col, Spin, Select, DatePicker, InputNumber,message, Divider} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class EditarPonderacionServArea extends Component {
    formRef = React.createRef();
    state={
        pesoEditado: false,
        tipoEdicion: null,
        editarServicio: false,
        disabledSelect: false,
    }
    render() { 
        const {fetchingEditarPonderacionServPorArea,
            ponderacionServPorAreaSeleccionado,
            onEditarPonderacionServArea,
            onLimpiarFormulario,
            onCambiarVista,
            fetchinglistaAreasFuncionalesActivos,
            listaAreasFuncionalesActivos,
            listaInfoProyecto,
            fetchingListaInfoProyecto,
            limpiarFormulario,
            page,
            onListaServiciosFiltro,
            fetchingListaServiciosFiltro,
            onListaServiciosFiltroLimpiar,
            listaServiciosFiltro,
            onGetListaAreasPorServicio,
        } = this.props;

        if(limpiarFormulario && limpiarFormulario === 'editarPonderacionServArea'){
            console.log('entró a limpiar');
            this.formRef.current.resetFields();
            onLimpiarFormulario();
            onListaServiciosFiltroLimpiar();
            onCambiarVista(1);
        };

        const onFinish = formulario => {
            console.log('Success:', formulario);
            const rangeValue = formulario['rangoVigencia'];
            formulario.rangoVigencia = rangeValue[0].format('YYYY-MM-DD') + "," + rangeValue[1].format('YYYY-MM-DD');
            formulario.fechaInicioVigencia = formulario.rangoVigencia.split(',')[0];
            formulario.fechaFinVigencia = formulario.rangoVigencia.split(',')[1];

            if(this.state.tipoEdicion === 'simple'){
                onEditarPonderacionServArea(formulario, page, ponderacionServPorAreaSeleccionado);
            }
            else{
                if (ponderacionServPorAreaSeleccionado.ponderacionServArea === formulario['ponderacionServArea']) {
                    console.log('terminado:', formulario);
                    onEditarPonderacionServArea(formulario, page, ponderacionServPorAreaSeleccionado);
                } else {
                    let idServicio = ponderacionServPorAreaSeleccionado.idServicio;
                    if(!isNaN(formulario.nombre)){
                        idServicio = parseInt(formulario.nombre);
                    }
                    onCambiarVista(2, formulario);
                    onListaServiciosFiltroLimpiar();
                    console.log('Pedir contraseña ');
                    onGetListaAreasPorServicio(idServicio, formulario, ponderacionServPorAreaSeleccionado);
                }
            }
        };

        

        const fechas = (rangeValue) => {
            console.log('conChangeFechas')
            if(listaInfoProyecto.fechaInicio){
                if(rangeValue[0]){
                    const fechaInicioAnterior = ponderacionServPorAreaSeleccionado.fechaInicioVigencia.split('T')[0];
                    const diferenciaAnterior = moment(fechaInicioAnterior, 'YYYY-MM-DD').diff(moment(rangeValue[0].format('YYYY-MM-DD')), "days");

                    if (this.state.pesoEditado && this.formRef.current) {
                        if (diferenciaAnterior > -1) {
                            console.log('this.formRef.current');
                            console.log(this.formRef.current);
                            message.error('No es posible seleccionar una fecha de inicio menor ni igual a la fecha de inicio anterior (' + fechaInicioAnterior.split('-').reverse().join('-') + ')', 7);
                            this.formRef.current.setFieldsValue({ 'rangoVigencia': [null, ponderacionServPorAreaSeleccionado.rangoVigencia[1]] });
                        }
                    }
                }
            } else {
                message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                this.formRef.current.setFieldsValue({ 'rangoVigencia': [null, ponderacionServPorAreaSeleccionado.rangoVigencia[1]] });
            }
        }

        const changeDecimales = (value) => {
            let ponderacionServArea = parseFloat(value).toFixed(1)

            if(isNaN(ponderacionServArea)){
                ponderacionServArea = 0.0;
            }
            if(this.state.tipoEdicion === 'simple'){
                this.formRef.current.setFieldsValue({'ponderacionServArea': ponderacionServArea.toFixed(1)});
            }
            else{
                if(this.formRef.current.getFieldValue('rangoVigencia')[0]){
                    message.warning('Por motivos de seguridad, favor de seleccionar de nuevo la fecha de inicio de vigencia', 7);
                }
                
                if(value !== parseFloat(ponderacionServPorAreaSeleccionado.ponderacionServArea)){
                    this.setState({
                        pesoEditado: true,
                    });
                }
                else{
                    this.setState({
                        pesoEditado: false,
                    });
                }
                this.formRef.current.setFieldsValue({'ponderacionServArea': ponderacionServArea, 'rangoVigencia': [null, ponderacionServPorAreaSeleccionado.rangoVigencia[1]]});
            }
        }


        const disableRange = fecha => {
            return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicio
        }
        
        const buscarServicios = value => {
            if(value.length>=3){
                if(value){
                    console.log(value);
                onListaServiciosFiltro(value)
                } else {
                    message.warning('No se encontró servicio con esas palabras')
                }
            } else {
               onListaServiciosFiltroLimpiar();
            } 
        };
        const onSelectTipoEdicion = (value) => {
            if(value === 'completa'){
                this.setState({
                    editarServicio: true,
                })
            }
            this.setState({
                tipoEdicion: value,
                disabledSelect: true,
            })
        }
        return ( 
            <div>
                {ponderacionServPorAreaSeleccionado ? (
                <Spin spinning={fetchingEditarPonderacionServPorArea}>
                    <Form {...layout}
                        name="formulario"
                        initialValues={ponderacionServPorAreaSeleccionado}
                        onFinish={onFinish}
                        
                        ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <p>Selecciona el tipo de edición</p>
                            </Col>
                            <Col span={11}>
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona el tipo de edición"
                                    onChange={onSelectTipoEdicion}
                                    disabled={this.state.disabledSelect}
                                >
                                    <Option key='simple'>Edición simple</Option>
                                    <Option key='completa'>Edición por convenio</Option>
                                </Select>
                            </Col>
                        </Row>
                        <Divider/>
                        {this.state.tipoEdicion ?(
                        <Row justify='space-around'>
                            <Col span={24}>
                                <Form.Item
                                    label="Áreas Funcionales"
                                    name="areaFuncional"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                <Select allowClear
                                    style={{ width: '100%' }}
                                    disabled={this.state.editarServicio}
                                    placeholder="Selecciona el área funcional"
                                    loading={fetchinglistaAreasFuncionalesActivos}
                                    >
                                        {listaAreasFuncionalesActivos.map(option => <Option key={option.idAreaFuncional}>{option.areaFuncional}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Servicio"
                                    name="nombre"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el servicio"
                                        loading={fetchingListaServiciosFiltro}
                                        showSearch
                                        disabled={this.state.editarServicio}
                                        filterOption={false}
                                        onSearch={buscarServicios}
                                        notFoundContent={null}
                                    >
                                        {listaServiciosFiltro.map(option => <Option key={option.idServicio}>{option.nombre}</Option>)}
                                        </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Ponderacion por Servicio"
                                    name="ponderacionServArea"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                    <InputNumber min={1} max={100} placeholder='Valor ponderacion'
                                        onChange={changeDecimales}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                        />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Fechas vigencias tiempo de garantia"
                                    name="rangoVigencia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <RangePicker
                                        format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio", "Fecha fin"]}
                                        onChange={fechas}
                                        loading={fetchingListaInfoProyecto}
                                        disabledDate={disableRange}
                                        disabled={[false, true]}
                                    />
                                </Form.Item>
                            </Col>
    
                            <Col span={24}>
                                <Button className="btnFiltrar" htmlType="submit">Editar Ponderador</Button>
                            </Col>
                        </Row>
                        ):false}
                    </Form>
                </Spin>
                ): false}
             </div>
         );
    }
}

const mapStateToProps = state => {
    return {
        fetchingEditarPonderacionServPorArea: state.MecanismosDePagosReducer.fetchingEditarPonderacionServPorArea,
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,

        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchinglistaAreasFuncionalesActivos: state.CatalogosGeneralesReducer.fetchinglistaAreasFuncionalesActivos,
        listaAreasFuncionalesActivos: state.CatalogosGeneralesReducer.listaAreasFuncionalesActivos,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        fetchingListaInfoProyecto: state.InformacionDelProyectoReducer.fetchingListaInfoProyecto,

        page: state.MecanismosDePagosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        ponderacionServPorAreaSeleccionado: state.MecanismosDePagosReducer.ponderacionServPorAreaSeleccionado,
        vistaModalPonderacionServPorArea: state.MecanismosDePagosReducer.vistaModalPonderacionServPorArea
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onEditarPonderacionServArea: (formulario, page, ponderacionServPorAreaSeleccionado) => {
            dispatch({ type: "EDITAR_PONDERACION_SERV_POR_AREA_REQUEST", formulario: formulario, page: page, ponderacionServPorAreaSeleccionado : ponderacionServPorAreaSeleccionado})
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onCambiarVista: (vistaModalPonderacionServPorArea, formularioPonderacionServ) => {
            dispatch({ type: 'CAMBIAR_VISTA_PONDERACION_SERV_POR_AREA', vistaModalPonderacionServPorArea, formularioPonderacionServ, ponderacionEditados: []})
        },
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
        },
        onGetListaAreasPorServicio: (idServicio, formulario, ponderacionServPorAreaSeleccionado) => {
            dispatch({ type: 'LISTA_AREAS_POR_SERVICIO_REQUEST', idServicio, formulario, ponderacionServPorAreaSeleccionado });
        }
    }
}
 
export default connect(mapStateToProps,mapDispatchToProps)(EditarPonderacionServArea)