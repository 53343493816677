import React, {Component} from 'react'
import { Form, Select, Button, Col, Spin, Modal, Row, Input, InputNumber } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalNuevoPonderadorEncuesta extends Component {
    formRef = React.createRef();
    state={
        listaAños: [],
    }
    render() { 
        const {
            onNuevoPonderadorEncuesta, onShowModalNuevoPonderadorEncuesta, showModalNuevoPonderadorEncuesta,
            fetchingNuevoPonderadorEncuesta, listaInfoProyecto, page, limpiarFormulario,
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onNuevoPonderadorEncuesta(formulario, page);
        };

        if (limpiarFormulario && limpiarFormulario === 'crearPonderadorEncuesta') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }
        const handleCancel = () => {
            onShowModalNuevoPonderadorEncuesta();
            this.formRef.current.resetFields();
        }
        const onListaAños = () => {
            let list = [];
            console.log('listaInfoProyecto', listaInfoProyecto)
            if(listaInfoProyecto){
                list.push(parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2]))
                for(let i = 0; i <listaInfoProyecto.vigencia; i++){
                    list.push(parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2]) + parseInt(i) +  1)
                }
            }
            this.setState({
               listaAños: list
            }); 
        };

        const changeDecimal = (value) => {
            const factor = parseFloat(value).toFixed(2)
            this.formRef.current.setFieldsValue({'factor': factor})
        }
        
        return (
            <Modal
                visible={showModalNuevoPonderadorEncuesta}
                title='Nuevo Ponderador para Encuesta de Satisfacción'
                onCancel={handleCancel}
                footer={false}
                width='50%'
            >
                <Spin spinning={fetchingNuevoPonderadorEncuesta}>
                    <Form {...layout}
                        name="formulario"
                        onFinish={onFinish}
                        ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Etiqueta de calificación"
                                    name="etiqueta"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={25} placeholder='Ingresa la etiqueta de calificación' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Año de vigencia"
                                    name="año"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Seleccione el año"
                                        loading={this.state.fetchingListaAños}
                                        onClick={onListaAños}
                                    >
                                        {this.state.listaAños.map(option => <Option key={option}>{option}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Factor"
                                    name="factor"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <InputNumber min={0.01} max={1} onChange={changeDecimal} placeholder='Ingresa el factor' style={{width: '100%'}} />
                                </Form.Item>
                            </Col>

                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Crear Ponderador para Encuesta de Satisfacción</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}
 const mapStateToProps = state => {
     return {
        showModalNuevoPonderadorEncuesta: state.GestionDeCalidadReducer.showModalNuevoPonderadorEncuesta,
        fetchingNuevoPonderadorEncuesta: state.GestionDeCalidadReducer.fetchingNuevoPonderadorEncuesta,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        page: state.GestionDeCalidadReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaPlanCalidadAños: state.GestionDeCalidadReducer.listaPlanCalidadAños,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevoPonderadorEncuesta: () => {
            dispatch ({ type: 'MODAL_NUEVO_PONDERADOR_ENCUESTA', showModalNuevoPonderadorEncuesta: false })
        },
        onNuevoPonderadorEncuesta: (formulario, page) => {
            dispatch({ type: "NUEVO_PONDERADOR_ENCUESTA_REQUEST", formulario, page })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoPonderadorEncuesta);