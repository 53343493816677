import React, { Component } from 'react';
import { Row, Form, Input, Button, Modal, Col, Spin, Select, InputNumber } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import InsumosForm from './InsumosForm';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalNuevaRutina extends Component {
    formRef = React.createRef();
    state={
        frecuencia: '',
    }

    render() {
        const { fetchingNuevaRutina, showModalNuevaRutina, onSiguientePaso, onShowModalNuevaRutina,
            fetchingListaSubfamiliasRutinas, listaSubfamiliasRutinas, onGetListaServiciosByGrupoServicio, 
            fetchingListaServiciosByGrupoServicio, listaServiciosByGrupoServicio, fetchingListaGruposServicios, 
            listaGruposServicios, vistaNuevaRutina, formularioRutina, listaInsumos, onGetSubfamiliasRutinas
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onSiguientePaso(formulario, 2, listaInsumos);
        };
        
        

        const handleCancel = () => {
            onShowModalNuevaRutina();
            onSiguientePaso(null, 1);
            if(vistaNuevaRutina === 1){
                this.formRef.current.resetFields();
            }
            this.props.onLimpiarListaServicios();
            this.props.onCleanPartidasByIdServicio();
        }
        const onGrupoServicio = (idGrupoSer) => {
            this.formRef.current.setFieldsValue({'idServicio': undefined});
            this.formRef.current.setFieldsValue({'idSubFamilia': undefined});
            this.formRef.current.setFieldsValue({'idPartida': undefined});
            onGetListaServiciosByGrupoServicio(idGrupoSer);
            this.props.onCleanPartidasByIdServicio();
        }
        const onInteger = (value, tipoInput) => {
            value = parseInt(value);
            if(tipoInput==='afectacion'){
                this.formRef.current.setFieldsValue({'afectacion': value})
            }
            else{
                this.formRef.current.setFieldsValue({'frecuencia': value})
            }
        }
        const onFrecuencia = (tipoFrecuencia) => {
            this.setState({
                frecuencia: tipoFrecuencia,
            })
        }
        const onServicioSeleccionado = (idServicio) => {
            let idServicioLocal =  idServicio.split(' - ')[0];
            onGetSubfamiliasRutinas(idServicioLocal);
            this.props.onGetPartidasByIdServicio(idServicioLocal);
            this.formRef.current.setFieldsValue({'idSubFamilia': null});
        }
        return (
            <Modal
            visible={showModalNuevaRutina}
            title='Nueva Rutina/Mantenimiento/Prueba'
            onCancel={handleCancel}
            footer={false}
            width='70%'
            >
                <Spin spinning={fetchingNuevaRutina}>
                    {vistaNuevaRutina === 1 ? (
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    initialValues={formularioRutina}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Nombre o descripción de rutina"
                                    name="rutina"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={100} placeholder="Ingresa el nombre o descripción"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Programa"
                                    name="programa"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el programa"
                                    >
                                        <Option key='Rutina'>Rutina</Option>
                                        <Option key='Mantenimiento'>Mantenimiento</Option>
                                        <Option key='Prueba'>Prueba</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Tipo de rutina"
                                    name="tipoRutina"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={50} placeholder="Ingresa el tipo de rutina"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Clave de rutina"
                                    name="clave"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={20} placeholder="Ingresa la clave" title="La marca debe contener sólo letras mayúsculas, números, guión bajo e intermedio" pattern="^[-_A-ZÑ0-9 ÁÉÓÚÍ]+$"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Grupo de servicio"
                                    name="grupoServicio"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el grupo de servicios"
                                        loading={fetchingListaGruposServicios}
                                        onChange={onGrupoServicio}
                                    >
                                        {listaGruposServicios.map(option => <Option key={option.idGrupoSer}>{ option.nombre }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {listaServiciosByGrupoServicio.length >= 1 && (
                                <Col span={11}>
                                    <Form.Item
                                        label="Servicio"
                                        name="idServicio"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el grupo de servicios para acceder al servicio"
                                            loading={fetchingListaServiciosByGrupoServicio}
                                            onChange={onServicioSeleccionado}
                                        >
                                            {listaServiciosByGrupoServicio.map(option => 
                                                <Option key={option.idServicio + ' - ' + option.nomenclatura + ' - ' + option.nombre}>
                                                    {option.idServicio + ' - ' + option.nomenclatura + ' - ' + option.nombre }
                                                </Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}

                            { this.props.listaPartidasByServicio4Fallas && (<>
                                { this.props.listaPartidasByServicio4Fallas[0] && ( <Col span={11}>
                                    <Form.Item
                                        label="Partida"
                                        name="idPartida"
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <Select
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona la partidas"
                                            loading={this.props.fetchingListaPartidasByServicio4Fallas}
                                        >
                                            {this.props.listaPartidasByServicio4Fallas.map(option =>
                                                <Option key={option.idPartida + ' - ' + option.nomenclatura  + ': ' + option.nombre}>
                                                    {option.idPartida + ' - ' + option.nomenclatura  + ': ' + option.nombre}
                                                </Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>) }
                            </>) }

                            {/* { this.props.listaSubfamiliasRutinas[0] && ( */}
                                <Col span={11}>
                                    <Form.Item
                                        label="Subfamilia"
                                        name="idSubFamilia"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona un servicio para acceder a la subfamilia"
                                            loading={fetchingListaSubfamiliasRutinas}
                                        >
                                            {listaSubfamiliasRutinas.map(option => <Option key={option.idSubFamilia + ' - ' + option.subFamilia}>{option.idSubFamilia + ' - ' +  option.subFamilia }</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>    
                            <Col span={11}>
                                <Form.Item
                                    label="Actividades"
                                    name="actividades"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <TextArea maxLength={450} placeholder="Ingresa el modelo"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Equipo de protección personal"
                                    name="epp"
                                >
                                    <Input maxLength={450} placeholder="Ingresa el EPP"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Personal"
                                    name="personal"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={100} placeholder="Ingresa el personal"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Tipo de frecuencia"
                                    name="tipoFrecuencia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el programa"
                                        onChange={onFrecuencia}
                                    >
                                        <Option key='Frecuencia'>Frecuencia</Option>
                                        <Option key='Fecha Específica'>Fecha específica</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            {this.state.frecuencia === 'Frecuencia' ? (
                            <Col span={11}>
                                <Form.Item
                                    label="Frecuencia"
                                    name="frecuencia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <InputNumber min={1} max={264} onChange={(value) => onInteger(value, 'Frecuencia')} style={{width: '100%'}} placeholder='Frecuencia en días'/>
                                </Form.Item>
                            </Col>
                            ):false}
                            <Col span={11}>
                                <Form.Item
                                    label="Afectación en minutos"
                                    name="afectacion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <InputNumber min={1} onChange={(value) => onInteger(value, 'afectacion')} style={{width: '100%'}} placeholder='Tiempo de afectación en minutos'/>
                                </Form.Item>
                            </Col>

                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit"><ArrowRightOutlined /> Siguiente Paso</Button>
                            </Col>
                        </Row>
                    </Form>
                    ):(
                    <InsumosForm/>
                    )}
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingNuevaRutina: state.ServiciosPlanificadosReducer.fetchingNuevaRutina,
        showModalNuevaRutina: state.ServiciosPlanificadosReducer.showModalNuevaRutina,
        page: state.ServiciosPlanificadosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        busqueda: state.ServiciosPlanificadosReducer.busqueda,
        listaSubfamiliasRutinas: state.CatalogosGeneralesReducer.listaSubfamiliasRutinas,
        fetchingListaSubfamiliasRutinas: state.CatalogosGeneralesReducer.fetchingListaSubfamiliasRutinas,
        fetchingListaGruposServicios: state.CatalogosGeneralesReducer.fetchingListaGruposServicios,
        listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,
        fetchingListaServiciosByGrupoServicio: state.CatalogosGeneralesReducer.fetchingListaServiciosByGrupoServicio,
        listaServiciosByGrupoServicio: state.CatalogosGeneralesReducer.listaServiciosByGrupoServicio,
        vistaNuevaRutina: state.ServiciosPlanificadosReducer.vistaNuevaRutina,
        formularioRutina: state.ServiciosPlanificadosReducer.formularioRutina,
        listaInsumos: state.ServiciosPlanificadosReducer.listaInsumos,

        listaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.listaPartidasByServicio4Fallas,
        fetchingListaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.fetchingListaPartidasByServicio4Fallas,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalNuevaRutina: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVA_RUTINA', showModalNuevaRutina: false, page: null, busqueda: null })
        },
        onSiguientePaso: ( formularioRutina, vistaNuevaRutina, listaInsumos ) => {
            dispatch({ type: "VISTA_NUEVA_RUTINA" , formularioRutina, vistaNuevaRutina, listaInsumos, tipoModal: 'crear' });
        },
        onGetListaServiciosByGrupoServicio: (idGrupoSer) => {
            dispatch({ type: 'LISTA_SERVICIOS_BY_GRUPO_SERV_REQUEST', idGrupoSer})
        }, 
        onLimpiarListaServicios: () => {
            dispatch({ type: 'LISTA_SERVICIOS_BY_GRUPO_SERV_SUCCESS', listaServiciosByGrupoServicio: []})
        },
        onGetSubfamiliasRutinas: (idServicio) => {
            dispatch({ type: 'LISTA_SUBFAMILIAS_RUTINAS_REQUEST', idServicio });
        },
        onGetPartidasByIdServicio: (idServicio) => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_REQUEST', idServicio})
        },
        onCleanPartidasByIdServicio: () => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_SUCCESS', listaPartidasByServicio4Fallas: []})
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevaRutina);