import React from 'react';
import { message, Form, Row, Col, Button, Modal, Select, Switch, Input, InputNumber, Spin, Divider, Tooltip, Alert } from 'antd';
import { connect } from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import Reporte from '../ReporteFaltantesAjusteInventarioFisico/ReporteFaltantesAjusteInventarioFisico';

const { Option } = Select;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };

class InformacionFolio extends React.Component {
    formRef = React.createRef();

     componentDidMount() {
          
     }

     state = {
          almacenesSeleccionadosLocal: [],

          checkedAlmacenFisico: true,
          checkedPiso: true,
          checkedArea: true,
          checkedPasillo: true,
          checkedRack: true,

          todosAlmacenesFisicoNombre: [],
          todosPisosNombre: [],
          todasAreasNombre: [],
          todosPasillosNombre: [],
          todosRackNombre: [],

          almacenesSeleccionados: [],
          pisosSeleccionados: [],
          areasSeleccionadas: [],
          pasillosSeleccionados: [],
          racksSeleccionados: [],

          loadingBooleanLocal: false,

          buttonUno: false,
          buttonDos: false,
          buttonTres: false,
          numeroConteo: null,
          classButtonUno: 'btnPruebaDisabled',
          classButtonDos: 'btnPruebaDisabled',
          classButtonTres: 'btnPruebaDisabled',

          validacionAnterior: true,
     }


     render() {
          const { 
               limpiarFormulario, 
               onLimpiarFormulario,
               tipoInventario,
               onCloseModalIngresarFolio,
               onShowModalInformacionFolio,
               showModalInformacionFolio,

               listaAlmacenesActivos,
               fetchingListaAlmacenesActivos,
               fetchingListaPisosByAlmacen, 
               listaPisosByAlmacen, 
               fetchingListaAreasByPisos, 
               listaAreasByPisos, 
               fetchingListaPasillosByAreas, 
               listaPasillosByAreas, 
               fetchingListaRacksByPasillos, 
               listaRacksByPasillos,
               onGetPisosByAlmacenes, 
               onGetAreasByPisos, 
               onGetPasillosByAreas, 
               onGetRacksByPasillos,

               onLimpiarPisos,
               onLimpiarAreas,
               onLimpiarPasillos,
               onLimpiarRacks,
               onShowModalCapturaInventario,

               folioInventario,
               onAlamacensByFolio,
               listaAlmacenesByFolio,
               fetchingListaAlmacenesByFolio,
               fetchingListaPisosByFolio,
               listaPisosByFolio,
               onPisosByFolio,
               onLimpiarPisosByFolio,
               onLimpiarAlmacenesByFolio,
               fetchingListaAreasByFolio, 
               listaAreasByFolio,
               onAreasByFolio,
               onLimpiarAreasByFolio,
               fetchingListaPasillosByFolio,
               listaPasillosByFolio,
               fetchingListaRacksByFolio,
               listaRacksByFolio,
               onLimpiarPasillosByFolio,
               onPasillosByFolio,
               onLimpiarRacksByFolio,
               onRacksByFolio,
               onGetProductosByFolio,
               onChangeCatalogo,
               catalogoAlmacenes,
               buttonReporte,
               // listaAlmacenesByFolio1,
               // listaAlmacenesByFolio2
               validacionConteoUno,
               validacionConteoDos,
               validacionConteoTres
          } = this.props;

          const abrirCaptura = () => {
               
               onShowModalCapturaInventario(this.state.numeroConteo);
               onLimpiarPisos();
               onLimpiarAreas();
               onLimpiarPasillos();
               onLimpiarRacks();

               onLimpiarPisosByFolio();
               onLimpiarAreasByFolio();
               onLimpiarPasillosByFolio();
               onLimpiarRacksByFolio();
               this.formRef.current.resetFields();
               this.setState({ 
                    checkedAlmacenFisico: true, 
                    checkedPiso: true,
                    checkedArea: true,
                    checkedPasillo: true,
                    checkedRack: true,
                    almacenesSeleccionadosLocal: [],
                    buttonUno: false,
                    buttonDos: false,
                    buttonTres: false,
                    classButtonUno: 'btnPruebaDisabled',
                    classButtonDos: 'btnPruebaDisabled',
                    classButtonTres: 'btnPruebaDisabled',
               });
          };

          const regresar = () =>{
               onShowModalInformacionFolio();
               this.setState({loadingBooleanLocal: false});
          };

          const onFinish = formulario => {
               console.log('Success:', formulario);
               let almacenesSeleccionadosEnviar = [];
               let pisosSeleccionadosEnviar = [];
               let areasSeleccionadasEnviar = [];
               let pasillosSeleccionadosEnviar = [];
               let racksSeleccionadosEnviar = [];
               
               if(!this.state.checkedAlmacenFisico){
                    for(let i = 0; i < listaAlmacenesByFolio.length; i ++){
                         almacenesSeleccionadosEnviar[i] = listaAlmacenesByFolio[i].idAlmacen;
                    }
               } else {             
                    almacenesSeleccionadosEnviar = this.state.almacenesSeleccionadosLocal;
               }

               if(!this.state.checkedPiso){
                    for(let j = 0; j < listaPisosByFolio.length; j ++){                         
                         pisosSeleccionadosEnviar[j] = listaPisosByFolio[j];                         
                    }
               } else {
                    pisosSeleccionadosEnviar = this.state.pisosSeleccionados;
               }

               if(!this.state.checkedArea){
                    for(let j = 0; j < listaAreasByFolio.length; j ++){                         
                         areasSeleccionadasEnviar[j] = listaAreasByFolio[j];                         
                    }
               } else {
                    areasSeleccionadasEnviar = this.state.areasSeleccionadas;
               }

               if(!this.state.checkedPasillo){
                    for(let j = 0; j < listaPasillosByFolio.length; j ++){                         
                         pasillosSeleccionadosEnviar[j] = listaPasillosByFolio[j];                         
                    }
               } else {
                    pasillosSeleccionadosEnviar = this.state.pasillosSeleccionados;
               }

               if(!this.state.checkedRack){
                    for(let j = 0; j < listaRacksByFolio.length; j ++){                         
                         racksSeleccionadosEnviar[j] = listaRacksByFolio[j];                         
                    }
               } else {
                    racksSeleccionadosEnviar = this.state.racksSeleccionados;
               }


               if(formulario.rack || formulario.todosRacks){
                    this.setState({ loadingBooleanLocal: true });
                    setTimeout(regresar, 2000);
                    abrirCaptura();  

                    onGetProductosByFolio(folioInventario, this.state.numeroConteo, almacenesSeleccionadosEnviar, pisosSeleccionadosEnviar, areasSeleccionadasEnviar, pasillosSeleccionadosEnviar, racksSeleccionadosEnviar);
               } else {
                    message.warning('Para proceder con la captura del folio ' + folioInventario + ' es necesario seleccionar el Rack a capturar ', 10);
               }
          };

          if (limpiarFormulario && limpiarFormulario === 'informacionFolio') {
               onLimpiarFormulario();
          }

          const onFinishFailed = errorInfo => {
               console.log('Failed:', errorInfo);
          };

          const handleCancel = () => {
               onShowModalInformacionFolio();
               onLimpiarAlmacenesByFolio();

               onLimpiarPisosByFolio();
               onLimpiarAreasByFolio();
               onLimpiarPasillosByFolio();
               onLimpiarRacksByFolio();
               this.formRef.current.resetFields();
               this.setState({ 
                    checkedAlmacenFisico: true, 
                    checkedPiso: true,
                    checkedArea: true,
                    checkedPasillo: true,
                    checkedRack: true,
                    almacenesSeleccionadosLocal: [], 
                    buttonUno: false,
                    buttonDos: false,
                    buttonTres: false,                    
                    classButtonUno: 'btnPruebaDisabled',
                    classButtonDos: 'btnPruebaDisabled',
                    classButtonTres: 'btnPruebaDisabled'
               });
          };

          ///////////////////////////////////////////////////////////////////////////////////////////////////////

          const todosAlmacenesFisico = (checked) => {
               const almacenesNombre = [listaAlmacenesByFolio.length];
               const todosAlmacenesSeleccionados = [listaAlmacenesByFolio.length];

               for(let i = 0; i < listaAlmacenesByFolio.length; i++){
                    almacenesNombre[i] = listaAlmacenesByFolio[i].nombreC;
                    todosAlmacenesSeleccionados[i] = listaAlmacenesByFolio[i].idAlmacen;
               }

               if(!(!this.formRef.current.getFieldValue('piso')) || !checked){
                    onLimpiarPisosByFolio();
                    onLimpiarAreasByFolio();
                    onLimpiarPasillosByFolio();
                    onLimpiarRacksByFolio();

                    this.formRef.current.resetFields(['idAlmacen', 'piso', 'area', 'pasillo', 'rack']);
                    this.setState({checkedAlmacenFisico: true, checkedPiso: true, checkedArea: true, checkedPasillo: true, checkedRack: true });
               } else if (checked) {
                    onLimpiarPisosByFolio();
                    onLimpiarAreasByFolio();
                    onLimpiarPasillosByFolio();
                    onLimpiarRacksByFolio();

                    onPisosByFolio(folioInventario, this.state.numeroConteo, todosAlmacenesSeleccionados);

                    this.setState({checkedAlmacenFisico: true, checkedPiso: true, checkedArea: true, checkedPasillo: true, checkedRack: true}); 
               }

               this.setState({ checkedAlmacenFisico: !checked, todosAlmacenesFisicoNombre: almacenesNombre });
               this.formRef.current.resetFields(['idAlmacen', 'piso', 'area', 'pasillo', 'rack']);
          };

          const onChangeAlmacen = (value) => {
               const almacenesSeleccionados = []
               for(let i = 0; i < listaAlmacenesByFolio.length; i++){
                    for(let j = 0; j < value.length; j ++){
                         if(listaAlmacenesByFolio[i].idAlmacen === parseInt(value[j])){
                              almacenesSeleccionados[j] = listaAlmacenesByFolio[i].idAlmacen;
                         }
                    }
               }

               if(this.formRef.current.getFieldValue('piso') || !this.state.checkedPiso){
                    onLimpiarPisosByFolio();
                    onLimpiarAreasByFolio();
                    onLimpiarPasillosByFolio();
                    onLimpiarRacksByFolio();
                    this.formRef.current.resetFields(['piso', 'area', 'pasillo', 'rack']);
                    message.warning('Por motivos de seguridad vuelva a ingresar los campos de Piso, Área, Pasillo y Rack');
                    this.setState({checkedAlmacenFisico: true, checkedPiso: true, checkedArea: true, checkedPasillo: true, checkedRack: true, almacenesSeleccionadosLocal: [] });
               }

               onPisosByFolio(folioInventario, this.state.numeroConteo, almacenesSeleccionados);
               this.setState({ almacenesSeleccionadosLocal: almacenesSeleccionados });
          };

          const todosPisos = (checked) => {
               const pisosNombre = [listaPisosByFolio.length];

               for(let i = 0; i < listaPisosByFolio.length; i++){
                    pisosNombre[i] = listaPisosByFolio[i];
               }

               if(!(!this.formRef.current.getFieldValue('area')) || !checked){
                    onLimpiarAreasByFolio();
                    onLimpiarPasillosByFolio();
                    onLimpiarRacksByFolio();
                    this.formRef.current.resetFields(['piso', 'area', 'pasillo', 'rack']);
                    this.setState({checkedPiso: true, checkedArea: true, checkedPasillo: true, checkedRack: true});
               } else if (checked) {
                    onLimpiarAreasByFolio();
                    onLimpiarPasillosByFolio();
                    onLimpiarRacksByFolio();
                    this.setState({checkedPiso: true, checkedArea: true, checkedPasillo: true, checkedRack: true });

                    onAreasByFolio(folioInventario, this.state.numeroConteo,listaPisosByFolio);
               }

               this.setState({ checkedPiso: !checked, todosPisosNombre: pisosNombre });
               this.formRef.current.resetFields(['piso', 'area', 'pasillo', 'rack']);
          };
            
           const onChangePiso = value => {

               if(this.formRef.current.getFieldValue('area') || !this.state.checkedArea){
                    onLimpiarAreasByFolio();
                    onLimpiarPasillosByFolio();
                    onLimpiarRacksByFolio();
                    this.formRef.current.resetFields(['area', 'pasillo', 'rack']);
                    message.warning('Por motivos de seguridad vuelva a ingresar los campos de Área, Pasillo y Rack');
                    this.setState({checkedPiso: true, checkedArea: true, checkedPasillo: true, checkedRack: true });
               }

               onAreasByFolio(folioInventario, this.state.numeroConteo, value);
               this.setState({ pisosSeleccionados: value });
          }

          const todasAreas = (checked) => {
               const areasNombre = [listaAreasByFolio.length];

               for(let i = 0; i < listaAreasByFolio.length; i++){
                    areasNombre[i] = listaAreasByFolio[i];
               }

               if(!(!this.formRef.current.getFieldValue('pasillo')) || !checked){
                    onLimpiarPasillosByFolio();
                    onLimpiarRacksByFolio();
                    this.formRef.current.resetFields(['area', 'pasillo', 'rack']);
                    this.setState({checkedArea: true, checkedPasillo: true, checkedRack: true });
               } else if (checked) {
                    onLimpiarPasillosByFolio();
                    onLimpiarRacksByFolio();
                    this.setState({checkedArea: true, checkedPasillo: true, checkedRack: true }); 
               }

               onPasillosByFolio(folioInventario, this.state.numeroConteo, listaAreasByFolio);

               this.setState({ checkedArea: !checked, todasAreasNombre: areasNombre });
               this.formRef.current.resetFields(['area', 'pasillo', 'rack']);
          };
   
          const onChangeArea = value => {

               if(this.formRef.current.getFieldValue('pasillo') || !this.state.checkedPasillo){
                    onLimpiarPasillosByFolio();
                    onLimpiarRacksByFolio();
                    this.formRef.current.resetFields(['pasillo', 'rack']);
                    message.warning('Por motivos de seguridad vuelva a ingresar los campos de Pasillo y Rack');
                    this.setState({checkedArea: true, checkedPasillo: true, checkedRack: true });
               }

               onPasillosByFolio(folioInventario, this.state.numeroConteo, value);
               this.setState({ areasSeleccionadas: value });
          }

          const todosPasillos = (checked) => {
               const pasillosNombre = [listaPasillosByFolio.length];

               for(let i = 0; i < listaPasillosByFolio.length; i++){
                    pasillosNombre[i] = listaPasillosByFolio[i];
               }

               if(!(!this.formRef.current.getFieldValue('rack')) || !checked){
                    onLimpiarRacksByFolio();
                    this.formRef.current.resetFields(['pasillo', 'rack']);
                    this.setState({checkedRack: true });
               } else if (checked) {
                    onLimpiarRacksByFolio();
                    this.setState({checkedRack: true }); 

                    onRacksByFolio(folioInventario, this.state.numeroConteo, listaPasillosByFolio);
               }

               this.setState({ checkedPasillo: !checked, todosPasillosNombre: pasillosNombre });
               this.formRef.current.resetFields(['pasillo', 'rack']);
          };
   
          const onChangePasillo = value => {

               if(this.formRef.current.getFieldValue('rack') || !this.state.checkedRack){
                    onLimpiarRacksByFolio();
                    this.formRef.current.resetFields(['rack']);
                    message.warning('Por motivos de seguridad vuelva a ingresar el campo de Rack');
                    this.setState({checkedRack: true });
               }

               onRacksByFolio(folioInventario, this.state.numeroConteo, value);
               this.setState({ pasillosSeleccionados: value });
          };

          const todosRacks = (checked) => {
               const racksNombre = [listaRacksByFolio.length];

               for(let i = 0; i < listaRacksByFolio.length; i++){
                    racksNombre[i] = listaRacksByFolio[i];
               }

               this.setState({ checkedRack: !checked, todosRackNombre: racksNombre });
               this.formRef.current.resetFields(['rack']);
          };
   
          const onChangeRack = value => {
               this.setState({ checkedRack: true, racksSeleccionados: value });
          }

          //////////////////////////////////////////////////////////////////////////////////////////////////////

          const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />

          const loading = (
               <Spin tip="Validando Datos..." indicator={antIcon} style={{ color: '#ff8c62' }}/>
          )

          const onNumeroConteoUno = () => {
               this.setState({validacionAnterior: true});

               this.formRef.current.resetFields();
               this.setState({ 
                    classButtonUno: 'btnPrueba', classButtonDos: 'btnPruebaDisabled', classButtonTres: 'btnPruebaDisabled', buttonDos: true, buttonTres: true, checkedAlmacenFisico: true, checkedPiso: true, checkedArea: true, checkedPasillo: true, checkedRack: true, almacenesSeleccionadosLocal: [], numeroConteo: 1,  validacionAnterior: false,
               });
               onAlamacensByFolio(folioInventario, 1);

          };

          const onNumeroConteoDos = () => {
               this.setState({validacionAnterior: true});
               
               // if(listaAlmacenesByFolio1[0]){
               //      message.warning('Se ha detectado que aún existen productos sin capturar en el Primer Conteo');

               //      this.setState({ validacionAnterior: true,

               //           classButtonUno: 'btnPruebaDisabled', classButtonDos: 'btnPrueba', classButtonTres: 'btnPruebaDisabled', buttonUno: true, buttonTres: true, checkedAlmacenFisico: true, checkedPiso: true, checkedArea: true, checkedPasillo: true, checkedRack: true, almacenesSeleccionadosLocal: [], numeroConteo: 2 
               //       });

               //       onLimpiarPisosByFolio();
               //      onLimpiarAreasByFolio();
               //      onLimpiarPasillosByFolio();
               //      onLimpiarRacksByFolio();
               //      this.formRef.current.resetFields();
               //      this.setState({ 
               //           checkedAlmacenFisico: true, 
               //           checkedPiso: true,
               //           checkedArea: true,
               //           checkedPasillo: true,
               //           almacenesSeleccionadosLocal: [], 
               //           buttonUno: false,
               //           buttonDos: false,
               //           buttonTres: false,                    
               //           classButtonUno: 'btnPruebaDisabled',
               //           classButtonDos: 'btnPruebaDisabled',
               //           classButtonTres: 'btnPruebaDisabled'
               //      });
               // } else {
               
                    onAlamacensByFolio(folioInventario, 2);

                    this.formRef.current.resetFields();
                    this.setState({ 
                         classButtonUno: 'btnPruebaDisabled', classButtonDos: 'btnPrueba', classButtonTres: 'btnPruebaDisabled', buttonUno: true, buttonTres: true, checkedAlmacenFisico: true, checkedPiso: true, checkedArea: true, checkedPasillo: true, checkedRack: true, almacenesSeleccionadosLocal: [], numeroConteo: 2  
                    }); 
               //}

               console.log('validacionAnterior', this.state.validacionAnterior);
          };

          const onNumeroConteoTres = () => {
               this.setState({validacionAnterior: true});
               // if(listaAlmacenesByFolio2[0]) {
               //      message.warning('Se ha detectado que aún existen productos sin capturar en el Segundo Conteo');

               //      this.setState({ validacionAnterior: true, 
                    
               //           classButtonUno: 'btnPruebaDisabled', classButtonDos: 'btnPruebaDisabled', classButtonTres: 'btnPrueba', buttonUno: true, buttonDos: true, checkedAlmacenFisico: true, checkedPiso: true, checkedArea: true, checkedPasillo: true, checkedRack: true, almacenesSeleccionadosLocal: [], numeroConteo: 3, 
               //      });
               //      onLimpiarPisosByFolio();
               //      onLimpiarAreasByFolio();
               //      onLimpiarPasillosByFolio();
               //      onLimpiarRacksByFolio();
               //      this.formRef.current.resetFields();
               //      this.setState({ 
               //           checkedAlmacenFisico: true, 
               //           checkedPiso: true,
               //           checkedArea: true,
               //           checkedPasillo: true,
               //           almacenesSeleccionadosLocal: [], 
               //           buttonUno: false,
               //           buttonDos: false,
               //           buttonTres: false,                    
               //           classButtonUno: 'btnPruebaDisabled',
               //           classButtonDos: 'btnPruebaDisabled',
               //           classButtonTres: 'btnPruebaDisabled'
               //      });
               // } else {
                    onAlamacensByFolio(folioInventario, 3);

                    this.formRef.current.resetFields();
                    this.setState({ 
                         classButtonUno: 'btnPruebaDisabled', classButtonDos: 'btnPruebaDisabled', classButtonTres: 'btnPrueba', buttonUno: true, buttonDos: true, checkedAlmacenFisico: true, checkedPiso: true, checkedArea: true, checkedPasillo: true, checkedRack: true, almacenesSeleccionadosLocal: [], numeroConteo: 3, 
                    });
               //}

               console.log('validacionAnterior', this.state.validacionAnterior);
          };

          const onReporteDiferencias = () => {
               onShowModalInformacionFolio();
               onChangeCatalogo('Reporte de Diferencias de Inventario');
               onLimpiarAlmacenesByFolio();
               onLimpiarPisosByFolio();
               onLimpiarAreasByFolio();
               onLimpiarPasillosByFolio();
               onLimpiarRacksByFolio();
               this.formRef.current.resetFields();
               this.setState({ 
                    checkedAlmacenFisico: true, 
                    checkedPiso: true,
                    checkedArea: true,
                    checkedPasillo: true,
                    checkedRack: true,
                    almacenesSeleccionadosLocal: [], 
                    buttonUno: false,
                    buttonDos: false,
                    buttonTres: false,                    
                    classButtonUno: 'btnPruebaDisabled',
                    classButtonDos: 'btnPruebaDisabled',
                    classButtonTres: 'btnPruebaDisabled',
               });
               return(
                    <Reporte />
               );
          };

          // console.log('validacionConteoUno', validacionConteoUno);
          // console.log('validacionConteoDos', validacionConteoDos);
          // console.log('validacionConteoTres', validacionConteoTres);
          
          return (
               <Modal
                    visible={showModalInformacionFolio}
                    title={'Información del Folio: ' + folioInventario}
                    onCancel={handleCancel}
                    footer={false}
                    width={'60%'}
               >

                    <Form {...layout}
                         name="formulario"
                         onFinish={onFinish}
                         
                         ref={this.formRef}
                    >
                         { this.state.loadingBooleanLocal ? (
                              <Row justify='space-around' style={{ textAlign: 'center' }}>
                                   <Col span={24}>
                                        {loading}
                                   </Col>
                              </Row>
                         ) : (

                         <Row justify='space-around' style={{ textAlign: 'center' }}>

                              <Col span={24}>
                                   <Form.Item
                                        label='Número de Conteo'
                                   />
                              </Col>

                              <Col span={7}>          
                                   <Tooltip title="Primer Conteo">
                                        <Button 
                                             shape="round" 
                                             className={this.state.classButtonUno}
                                             value="1"
                                             disabled={validacionConteoUno}
                                             onClick={onNumeroConteoUno}
                                        > 
                                             1 
                                        </Button>
                                   </Tooltip>
                              </Col>

                              <Col span={7}>
                                   <Tooltip title="Segundo Conteo">
                                        <Button 
                                             shape="round" 
                                             className={this.state.classButtonDos} 
                                             value="2" 
                                             disabled={validacionConteoDos || !validacionConteoUno} 
                                             onClick={onNumeroConteoDos}
                                        > 
                                             2 
                                        </Button>
                                   </Tooltip>
                              </Col>

                              <Col span={7}>
                                   <Tooltip title="Tercer Conteo">
                                        <Button 
                                             shape="round" 
                                             className={this.state.classButtonTres} 
                                             value="3" 
                                             disabled={validacionConteoTres || !validacionConteoDos} 
                                             onClick={onNumeroConteoTres}
                                        >   
                                             3 
                                        </Button>
                                   </Tooltip>
                              </Col>

                              { listaAlmacenesByFolio.length === 0 ? (
                                   <Col span={23}>
                                        <br/><br/>
                                        <Form.Item   
                                             name='alerta'
                                        >
                                             <Alert
                                                  message={"Selecciona el Número de Conteo"}
                                                  description={"Es importante seleccionar el número de conteo correspondiente al folio seleccionado."}
                                                  type="info"
                                             />
                                             
                                        </Form.Item>
                                   </Col>
                              ): false }

                              { (this.state.buttonUno || this.state.buttonDos || this.state.buttonTres) && listaAlmacenesByFolio[0] ? (
                              <Col span={19}>
                                   <br/>
                                   <Form.Item
                                        label='Almacenes'
                                        name='idAlmacen'
                                        rules={[{ required: this.state.checkedAlmacenFisico, message: 'Favor de llenar el campo', }]}
                                   >
                                        { this.state.checkedAlmacenFisico ? (
                                        <Select
                                             mode='multiple'
                                             allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Seleccione un almacén"
                                             loading={fetchingListaAlmacenesByFolio}
                                             onChange={onChangeAlmacen}
                                        >    
                                             
                                             {listaAlmacenesByFolio.map(option => <Option key={option.idAlmacen}>{option.nombreC}</Option>)}

                                        </Select>
                                        ): (
                                             <Select
                                                  mode="multiple"
                                                  style={{ width: '100%' }}
                                                  placeholder="Se han seleccionado todos los almacenes"
                                                  defaultValue={this.state.todosAlmacenesFisicoNombre}
                                                  disabled={true}
                                             />

                                        ) }
                                   </Form.Item>
                              </Col>
                              ):false}

                              { ((this.state.buttonUno || this.state.buttonDos) || this.state.buttonTres) && listaAlmacenesByFolio.length > 0 ? (
                              <Col span={3}>
                                   <br/>
                                   <Form.Item
                                        label=' '
                                        name='todosAlmacenesFisico'
                                   >
                                        <Switch
                                             checkedChildren={'TODOS'}
                                             unCheckedChildren={'TODOS'}
                                             defaultChecked={false}
                                             size={'default'}
                                             disabled={!listaAlmacenesByFolio[0]}
                                             onChange={todosAlmacenesFisico}
                                        />
                                   </Form.Item>
                              </Col>
                              ):false}

                              { (this.state.almacenesSeleccionadosLocal[0] || !this.state.checkedAlmacenFisico) &&  listaPisosByFolio !== undefined ? (
                              <Col span={19}>
                                   <Form.Item
                                        label='Pisos'
                                        name='piso'
                                        rules={[{ required: this.state.checkedPiso, message: 'Favor de llenar el campo', }]}
                                   >
                                        { this.state.checkedPiso ? (
                                        <Select
                                             mode='multiple'
                                             allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Seleccione un piso"
                                             loading={fetchingListaPisosByFolio}
                                             onChange={onChangePiso}
                                             //disabled={!this.state.almacenesSeleccionadosLocal.length > 0}
                                        >
                                        
                                             {listaPisosByFolio.map(option => <Option key={option}>{option}</Option>)}

                                        </Select>
                                        ): (
                                             <Select
                                                  mode="multiple"
                                                  style={{ width: '100%' }}
                                                  placeholder="Se han seleccionado todos los pisos"
                                                  defaultValue={this.state.todosPisosNombre}
                                                  disabled={true}
                                             />
                                        )}
                                   </Form.Item>
                              </Col>
                              ): false }

                              { (this.state.almacenesSeleccionadosLocal[0] || !this.state.checkedAlmacenFisico) &&  listaPisosByFolio !== undefined ? (
                              <Col span={3}>
                                   <Form.Item
                                        label=' '
                                        name='todosPisos'
                                   >
                                        <Switch
                                             checkedChildren={'TODOS'}
                                             unCheckedChildren={'TODOS'}
                                             defaultChecked={false}
                                             checked={!this.state.checkedPiso}
                                             disabled={!listaPisosByFolio[0]}
                                             size={'default'}
                                             onChange={todosPisos}
                                        />
                                   </Form.Item>
                              </Col>
                              ): false }

                              { listaAreasByFolio[0]|| !this.state.checkedPiso ? (    
                              <Col span={19}>
                                   <Form.Item
                                        label='Áreas'
                                        name='area'
                                        rules={[{ required: this.state.checkedArea, message: 'Favor de llenar el campo', }]}
                                   >    
                                        { this.state.checkedArea ? (
                                        <Select
                                             mode='multiple'
                                             allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Seleccione un área"
                                             loading={fetchingListaAreasByFolio}
                                             onChange={onChangeArea}
                                             //disabled={!listaAreasByPisos.length > 0}
                                        >
                                             {listaAreasByFolio.map(option => <Option key={option}>{option}</Option>)}
                                        </Select>
                                        ):(
                                             <Select
                                                  mode="multiple"
                                                  style={{ width: '100%' }}
                                                  placeholder="Se han seleccionado todas las áreas"
                                                  defaultValue={this.state.todasAreasNombre}
                                                  disabled={true}
                                             />
                                        )}
                                   </Form.Item>
                              </Col>
                              ): false }

                              { listaAreasByFolio[0] || !this.state.checkedPiso ? ( 
                              <Col span={3}>
                                   <Form.Item
                                        label=' '
                                        name='todasAreas'
                                   >
                                        <Switch
                                             checkedChildren={'TODAS'}
                                             unCheckedChildren={'TODAS'}
                                             defaultChecked={false}
                                             checked={!this.state.checkedArea}
                                             disabled={!listaAreasByFolio[0]}
                                             size={'default'}
                                             onChange={todasAreas}
                                        />
                                   </Form.Item>
                              </Col>
                              ): false }

                              { listaPasillosByFolio[0] || !this.state.checkedArea ? (      
                              <Col span={19}>
                                   <Form.Item
                                        label='Pasillos'
                                        name='pasillo'
                                        rules={[{ required: this.state.checkedPasillo, message: 'Favor de llenar el campo', }]}
                                   >
                                        { this.state.checkedPasillo ? (
                                        <Select
                                             mode='multiple'
                                             allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Seleccione un pasillo"
                                             loading={fetchingListaPasillosByFolio}
                                             onChange={onChangePasillo}
                                             //disabled={!listaPasillosByAreas.length > 0}
                                        >
                                             {listaPasillosByFolio.map(option => <Option key={option}>{option}</Option>)}
                                        </Select>
                                        ):(
                                             <Select
                                                  mode="multiple"
                                                  style={{ width: '100%' }}
                                                  placeholder="Se han seleccionado todos los pasillos"
                                                  defaultValue={this.state.todosPasillosNombre}
                                                  disabled={true}
                                             />
                                        )}
                                   </Form.Item>
                              </Col>
                              ): false }
                                   
                              { listaPasillosByFolio[0] || !this.state.checkedArea ? (      
                              <Col span={3}>
                                   <Form.Item
                                        label=' '
                                        name='todosPasillos'
                                   >
                                        <Switch
                                             checkedChildren={'TODOS'}
                                             unCheckedChildren={'TODOS'}
                                             defaultChecked={false}
                                             checked={!this.state.checkedPasillo}
                                             disabled={!listaPasillosByFolio[0]}
                                             size={'default'}
                                             onChange={todosPasillos}
                                        />
                                   </Form.Item>
                              </Col>
                              ): false }

                              { listaRacksByFolio[0] || !this.state.checkedPasillo ? ( 
                              <Col span={19}>
                                   <Form.Item
                                        name='rack'
                                        label='Racks'
                                        rules={[{ required: this.state.checkedRack, message: 'Favor de llenar el campo', }]}
                                   >
                                        { this.state.checkedRack ? (
                                        <Select
                                             mode='multiple'
                                             allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Seleccione un rack"
                                             loading={fetchingListaRacksByFolio}
                                             onChange={onChangeRack}
                                             //disabled={!listaRacksByPasillos.length > 0}
                                        >
                                                  {listaRacksByFolio.map(option => <Option key={option}>{option}</Option>)}                                                                 
                                        </Select>
                                        ):(
                                             <Select
                                                  mode="multiple"
                                                  style={{ width: '100%' }}
                                                  placeholder="Se han seleccionado todos los racks"
                                                  defaultValue={this.state.todosRackNombre}
                                                  disabled={true}
                                             />
                                        ) }
                                   </Form.Item>
                              </Col>
                              ): false }

                              { listaRacksByFolio[0] || !this.state.checkedPasillo ? ( 
                              <Col span={3}>
                                   <Form.Item
                                        label=' '
                                        name='todosRacks'
                                   >
                                        <Switch
                                             checkedChildren={'TODOS'}
                                             unCheckedChildren={'TODOS'}
                                             defaultChecked={false}
                                             checked={!this.state.checkedRack}
                                             disabled={!listaRacksByFolio[0]}
                                             size={'default'}
                                             onChange={todosRacks}
                                        />
                                   </Form.Item>
                              </Col>
                              ): false }
                              <Divider style={{ margin: '10px' }} />
                              <Button className="btnPrueba" disabled={ !validacionConteoUno } style={{ width: '40%' }} onClick={onReporteDiferencias}>
                                   Ir a Reporte de Diferencias de Inventario
                              </Button>
                              { this.state.buttonUno || this.state.buttonDos || this.state.buttonTres ? (
                                   <Button className="btnPrueba" style={{ width: '40%' }} htmlType="submit" >
                                        Capturar
                                   </Button>      
                              ): false }
                         </Row>
                         )} 
                    </Form>

               </Modal>
          );
     }
}

const mapStateToProps = state => {
     return {
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          tipoInventario: state.AlmacenesReducer.tipoInventario,
          fetchingListaAlmacenesActivos: state.AlmacenesReducer.fetchingListaAlmacenesActivos,
          listaAlmacenesActivos: state.AlmacenesReducer.listaAlmacenesActivos,
          showModalInformacionFolio: state.AlmacenesReducer. showModalInformacionFolio,
          fetchingListaPisosByAlmacen: state.AlmacenesReducer.fetchingListaPisosByAlmacen,
          listaPisosByAlmacen: state.AlmacenesReducer.listaPisosByAlmacen,
          fetchingListaAreasByPisos: state.AlmacenesReducer.fetchingListaAreasByPisos,
          listaAreasByPisos: state.AlmacenesReducer.listaAreasByPisos,
          fetchingListaPasillosByAreas: state.AlmacenesReducer.fetchingListaPasillosByAreas,
          listaPasillosByAreas: state.AlmacenesReducer.listaPasillosByAreas,
          fetchingListaRacksByPasillos: state.AlmacenesReducer.fetchingListaRacksByPasillos,
          listaRacksByPasillos: state.AlmacenesReducer.listaRacksByPasillos,
          folioInventario: state.AlmacenesReducer.folioInventario,

          listaAlmacenesByFolio: state.AlmacenesReducer.listaAlmacenesByFolio,
          fetchingListaAlmacenesByFolio: state.AlmacenesReducer.fetchingListaAlmacenesByFolio,
          fetchingListaPisosByFolio: state.AlmacenesReducer.fetchingListaPisosByFolio,
          listaPisosByFolio: state.AlmacenesReducer.listaPisosByFolio,
          fetchingListaAreasByFolio: state.AlmacenesReducer.fetchingListaAreasByFolio, 
          listaAreasByFolio: state.AlmacenesReducer.listaAreasByFolio,
          fetchingListaPasillosByFolio: state.AlmacenesReducer.fetchingListaPasillosByFolio,
          listaPasillosByFolio: state.AlmacenesReducer.listaPasillosByFolio,
          fetchingListaRacksByFolio: state.AlmacenesReducer.fetchingListaRacksByFolio,
          listaRacksByFolio: state.AlmacenesReducer.listaRacksByFolio,
          catalogoAlmacenes: state.AlmacenesReducer.catalogoAlmacenes,
          buttonReporte: state.AlmacenesReducer.buttonReporte,
          // listaAlmacenesByFolio1: state.AlmacenesReducer.listaAlmacenesByFolio1,
          // listaAlmacenesByFolio2: state.AlmacenesReducer.listaAlmacenesByFolio2,
          validacionConteoUno: state.AlmacenesReducer.validacionConteoUno,
          validacionConteoDos: state.AlmacenesReducer.validacionConteoDos,
          validacionConteoTres: state.AlmacenesReducer.validacionConteoTres,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
          onCloseModalIngresarFolio: () => {
               dispatch({ type: 'SHOW_MODAL_INGRESAR_FOLIO', showModalIngresarFolio: false });
          },
          onShowModalInformacionFolio: () => {
               dispatch({ type: 'SHOW_MODAL_INFORMACION_FOLIO', showModalInformacionFolio: false });
          },
          onGetAlmacenesActivos: () => {
               dispatch({ type: 'ALMACENES_ACTIVOS_BUSQUEDA_REQUEST' })
          },
          onGetPisosByAlmacenes: (idsAlmacenes) => {
               dispatch({ type:"LISTA_PISOS_BY_ALMACEN_REQUEST", idsAlmacenes: idsAlmacenes})
          },
          onLimpiarPisos: () => {
               dispatch({ type: 'LISTA_PISOS_BY_ALMACEN_SUCCESS', listaPisosByAlmacen: [] });
          },
          onGetAreasByPisos: (pisos) => {
               dispatch({ type:"LISTA_AREAS_BY_PISOS_REQUEST", pisos: pisos});
          },
          onLimpiarAreas: () => {
               dispatch({ type: 'LISTA_AREAS_BY_PISOS_SUCCESS', listaAreasByPisos: [] });
          },
          onGetPasillosByAreas: (areas) => {
               dispatch({ type:"LISTA_PASILLOS_BY_AREAS_REQUEST", areas: areas});
          },
          onLimpiarPasillos: () => {
               dispatch({ type:'LISTA_PASILLOS_BY_AREAS_SUCCESS', listaPasillosByAreas: [] });
          },
          onGetRacksByPasillos: (pasillos) => {
               dispatch({ type:"LISTA_RACKS_BY_PASILLOS_REQUEST", pasillos: pasillos});
          },
          onLimpiarRacks: () => {
               dispatch({ type:'LISTA_RACKS_BY_PASILLOS_SUCCESS', listaRacksByPasillos: [] });
          },
          onGetUbicacionesProductos: (page,nombreAlmacen) => {
               dispatch({ type: "UBICACIONES_PRODUCTOS_BUSQUEDA_REQUEST", page: page,nombreAlmacen:nombreAlmacen });
          },
          onShowModalCapturaInventario: (numeroConteo) => {
               dispatch({ type: 'SHOW_MODAL_CAPTURA_INVENTARIO', showModalCapturaInventario: true, numeroConteo });
          },

          onAlamacensByFolio: (folioInventario, numConteo) => {
               dispatch({ type: 'LISTA_ALMACENES_BY_FOLIO_REQUEST', folioInventario, numConteo });
          },
          onLimpiarAlmacenesByFolio: () => {
               dispatch({ type: 'LISTA_ALMACENES_BY_FOLIO_SUCCESS', listaAlmacenesByFolio: [] });
          },
          onPisosByFolio: (folioInventario, numConteo, almacenes) => {
               dispatch({ type: 'LISTA_PISOS_BY_FOLIO_REQUEST', folioInventario, numConteo, almacenes });
          },
          onLimpiarPisosByFolio: () => {
               dispatch({ type: 'LISTA_PISOS_BY_FOLIO_SUCCESS', listaPisosByFolio: [] });
          },
          onAreasByFolio: (folioInventario, numConteo, areas) => {
               dispatch({ type: 'LISTA_AREAS_BY_FOLIO_REQUEST', folioInventario, numConteo, areas });
          },
          onLimpiarAreasByFolio: () => {
               dispatch({ type: 'LISTA_AREAS_BY_FOLIO_SUCCESS', listaAreasByFolio: [] });
          },
          onPasillosByFolio: (folioInventario, numConteo, pasillos) => {
               dispatch({ type: 'LISTA_PASILLOS_BY_FOLIO_REQUEST', folioInventario, numConteo, pasillos });
          },
          onLimpiarPasillosByFolio: () => {
               dispatch({ type: 'LISTA_PASILLOS_BY_FOLIO_SUCCESS', listaPasillosByFolio: [] });
          },
          onRacksByFolio: (folioInventario, numConteo, racks) => {
               dispatch({ type: 'LISTA_RACKS_BY_FOLIO_REQUEST', folioInventario, numConteo, racks });
          },
          onLimpiarRacksByFolio: () => {
               dispatch({ type: 'LISTA_RACKS_BY_FOLIO_SUCCESS', listaRacksByFolio: [] });
          },
          onGetProductosByFolio: (folioInventario, numConteo, almacenes, pisos, areas, pasillos, racks) => {
               dispatch({ type: 'LISTA_PRODUCTOS_BY_FOLIO_REQUEST', folioInventario, numConteo, almacenes, pisos, areas, pasillos, racks });
          },
          onChangeCatalogo: (catalogoAlmacenes) => {
               dispatch({ type: "CAMBIAR_CATALOGO_ALMACENES" , catalogoAlmacenes });
          },
          onEnviarCaptura: (listaProductosByFolio) => {
               dispatch({ type: 'CAPTURAR_CONTEO_REQUEST', listaProductosByFolio });
          }

     };
};

export default connect(mapStateToProps, mapDispatchToProps)(InformacionFolio);