import * as BaseConocimientoActions from '../actions/BaseDeConocimientoActions';

const initialState = {
    page: null,
    catalogoBaseConocimiento: null,
    busqueda: '',

    //Base de Conocimiento
    fetchingListaDocumentosBaseBusqueda: false,
    listaDocumentosBaseBusqueda: [],
    listaDocumentosBaseBusquedaCantidad: null,

    showModalNuevoDocumentoBase: false,
    showModalEditarDocumentoBase: false,
    DocumentoBaseSeleccionado: null,
    fetchingNuevoDocumentoBase: false,
    fetchingEditarDocumentoBase: false,

    fetchingExportarExcel: false,

    documentoSeleccionado: null,
    showModalVerDocumento: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case BaseConocimientoActions.CAMBIAR_CATALOGO_BASE_CONOCIMIENTO:
            return { ...state, catalogoBaseConocimiento: action.catalogoBaseConocimiento };

        //BASE DE CONOCIMIENTO
        case BaseConocimientoActions.LISTA_BASE_CONOCIMIENTO_REQUEST:
            return { ...state, fetchingListaDocumentosBaseBusqueda: true};
        case BaseConocimientoActions.LISTA_BASE_CONOCIMIENTO_SUCCESS:
            return { ...state, fetchingListaDocumentosBaseBusqueda: false, listaDocumentosBaseBusqueda: action.listaDocumentosBaseBusqueda, listaDocumentosBaseBusquedaCantidad: action.listaDocumentosBaseBusquedaCantidad};
        case BaseConocimientoActions.LISTA_BASE_CONOCIMIENTO_FAILURE:
            return { ...state, fetchingListaDocumentosBaseBusqueda: false};

        case BaseConocimientoActions.SHOW_MODAL_NUEVO_DOCUMENTO_BASE:
            return { ...state, showModalNuevoDocumentoBase: action.showModalNuevoDocumentoBase, page: action.page, busqueda: action.busqueda};

        case BaseConocimientoActions.SHOW_MODAL_EDITAR_DOCUMENTO_BASE:
        return { ...state, showModalEditarDocumentoBase: action.showModalEditarDocumentoBase, DocumentoBaseSeleccionado:action.DocumentoBaseSeleccionado, page: action.page, busqueda: action.busqueda};

        case BaseConocimientoActions.NUEVO_DOCUMENTO_BASE_REQUEST:
            return { ...state, fetchingNuevoDocumentoBase: true};
        case BaseConocimientoActions.NUEVO_DOCUMENTO_BASE_SUCCESS:
            return { ...state, fetchingNuevoDocumentoBase: false};
        case BaseConocimientoActions.NUEVO_DOCUMENTO_BASE_FAILURE:
            return { ...state, fetchingNuevoDocumentoBase: false};

        case BaseConocimientoActions.EDITAR_DOCUMENTO_BASE_REQUEST:
            return { ...state, fetchingEditarDocumentoBase: true};
        case BaseConocimientoActions.EDITAR_DOCUMENTO_BASE_SUCCESS:
            return { ...state, fetchingEditarDocumentoBase: false};
        case BaseConocimientoActions.EDITAR_DOCUMENTO_BASE_FAILURE:
            return { ...state, fetchingEditarDocumentoBase: false};


        case BaseConocimientoActions.EXPORTAR_EXCEL_REQUEST:
            return { ...state, fetchingExportarExcel: true};
        case BaseConocimientoActions.EXPORTAR_EXCEL_SUCCESS:
            return { ...state, fetchingExportarExcel: false};
        case BaseConocimientoActions.EXPORTAR_EXCEL_FAILURE:
            return { ...state, fetchingExportarExcel: false};

        case BaseConocimientoActions.SHOW_MODAL_DOCUMENTO:
            return { ...state, showModalVerDocumento: action.showModalVerDocumento, documentoSeleccionado: action.documentoSeleccionado };

        default:
            return state;
     }
}