import React from 'react';
import {connect} from 'react-redux';
import {Form, Row, Col, Button, Modal, Spin, Select, message,Popover, Divider, Input, Alert, Table, Descriptions} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalNuevaAsignacionSP extends React.Component {
    formRef = React.createRef();

    state = {
        SPSelected: null,
        activosSelected: [],
    }

    componentDidMount() {
        this.props.onListaSPNoAsignados();
    }

    componentDidUpdate ( preProps) {
        if(this.props.limpiarFormulario !== preProps.limpiarFormulario) {
            this.setState({SPSelected: null, activosSelected: []});
            this.props.onListaSPNoAsignados();
        }
    }
    
    render() {

        const {showModalNuevaAsignacionSPlanificado, fetchingNuevaAsignacionSPlanificado, page, limpiarFormulario,
            fetchingUbicacionesSinHijos, ubicacionesSinHijos, fetchingListaActivosCaractFiltroAsignacionSP, listaActivosCaractFiltroAsignacionSP, fetchinglistaSPNoAsignados, listaSPNoAsignados,
            onShowModalNuevaAsignacionSP, onListaUbicacionesFiltro, onListaUbicacionesFiltroLimpiar, onListaActivosCaracteristicasFiltro, onListaActiviosCaracteristicasFiltroLimpiar, onCrearAsignacionSP} = this.props;

        const { busqueda } = this.props;

        const {SPSelected, activosSelected} = this.state;
        
        if (limpiarFormulario && limpiarFormulario === 'CASPlanificado') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        const columns =  
        [{
            title: 'Descripción',
            dataIndex: 'descripcion',
            key: 'descripcion',
            // width: '40%',
        },{
            title: 'Cantidad',
            dataIndex: 'cantidad',
            key: 'cantidad',
            align: 'center'
        },{
            title: 'Unidad de Medida',
            dataIndex: 'unidadMedida',
            key: 'unidadMedida',
            align: 'center'
        }]

        const setFields = value => {
            this.formRef.current.setFieldsValue({'categoria':value.categoria, 'nomenclatura':value.nomenclatura,'programa': value.programa, 'tipoRutina':value.tipoRutina, 'claveRutina': value.claveRutina,
        'actividades':value.actividades, 'epp':value.epp, 'personal': value.personal, 'insumos': value.insumosByIdRutina, 'herramientas':value.herramientas });

        }

        const handleSelect = (value, data) => {
            console.log(value, data);
            this.setState({SPSelected:null})
            if(value) {
                const partida = data.data?.partidaByIdPartida?.nomenclatura ?? '-';
                data.data.partida = partida;
                setFields(data.data);
                this.setState({SPSelected: data.data})
            }
        }

        const handleSearch = (value) => {
            if(value.length>=3){
                if (value) {
                    console.log(value)
                    onListaUbicacionesFiltro(value);
                } else {
                    message.warning('No se encontró ubicación con esas palabras')
                }
            }
            else{
                onListaUbicacionesFiltroLimpiar();
            }
        };

        const handleSearchActivos = (value) => {
            if(value.length>=3){
                if (value) {
                    console.log(value)
                    onListaActivosCaracteristicasFiltro(value);
                } else {
                    message.warning('No se encontró el activo característica con esas palabras')
                }
            }
            else{
                onListaActiviosCaracteristicasFiltroLimpiar();
            }
        };

        const selectActivos = (value, data) => {
            let addActivo = activosSelected;
            addActivo.push(data.data);
            this.setState({activosSelected: addActivo}); 
        }

        const deselectActivos = (value, data) => {
            let deletedActivo = activosSelected.filter(item => item.idActivoCaract != value.split('-')[0]);
            this.setState({activosSelected: deletedActivo});
        }

        const onFinish = (formulario) => {
            onCrearAsignacionSP(formulario, SPSelected, activosSelected, page, busqueda);        }

        const onFinishFailed = () => {
            console.log('Cancel');
        }

        const handleCancel = () => {
            onShowModalNuevaAsignacionSP();
            this.setState({SPSelected: null, activosSelected: []})
            this.formRef.current.resetFields();
        }

        return (
            <div>
                <Modal
                    visible={showModalNuevaAsignacionSPlanificado}
                    title='Nueva Asignación de Servicios Planificados'
                    onCancel={handleCancel}
                    footer={false}
                    width='70%'
                >
                    <Spin spinning={fetchingNuevaAsignacionSPlanificado}>
                        <Form {...layout}
                            name='formulario'
                            onFinish={onFinish}
                            
                            ref={this.formRef}
                        >       
                            {!SPSelected ?
                            <div>
                                <Alert
                                    message={"Favor de seleccionar un servicio planificado de la lista."}
                                    type="info"
                                />
                                <br />
                            </div> : false}
                            <Row type='flex' justify='space-between'>
                                <Col span={24}>
                                    <Form.Item
                                        label='Nombre Rutina-Pruebas'
                                        name='nombreRutina'
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select
                                            allowClear
                                            style={{ width: '100%', textAlign: 'left' }}
                                            placeholder='Seleccione una rutina-prueba'
                                            loading={fetchinglistaSPNoAsignados}
                                            onChange={handleSelect}
                                            showSearch
                                        >
                                            {listaSPNoAsignados.map(option => <Option key={option.idRutina + ' - ' + option.rutina} data={option}>{option.idRutina + ' - ' + option.rutina}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            { SPSelected && ( <div>
                                <Row type='flex' justify='space-between' gutter={[8,8]}>
                                    <Col span={24}>
                                        <Descriptions bordered>
                                            <Descriptions.Item label="Categoría">{SPSelected.categoria}</Descriptions.Item>
                                            <Descriptions.Item label="Nomenclatura">{SPSelected.nomenclatura}</Descriptions.Item>
                                            <Descriptions.Item label="Partida">{SPSelected.partida}</Descriptions.Item>
                                            
                                            <Descriptions.Item label="Programa">{SPSelected.programa}</Descriptions.Item>
                                            <Descriptions.Item label="Tipo">{SPSelected.tipoRutina}</Descriptions.Item>
                                            <Descriptions.Item label="Clave R/M/P">{SPSelected.claveRutina}</Descriptions.Item>
                                            <Descriptions.Item label="EPP">{SPSelected.epp}</Descriptions.Item>
                                            <Descriptions.Item label="Personal">{SPSelected.personal}</Descriptions.Item>
                                            <Descriptions.Item label="Actividades">{SPSelected.actividades}</Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                    
                                    <Col span={12}>
                                        <Divider orientation="left">Insumos</Divider>
                                        <Table
                                            size='small'
                                            rowKey='idProducto'
                                            columns={columns}
                                            dataSource={SPSelected.insumosByIdRutina}
                                            locale={{ emptyText: 'Sin datos' }}
                                            pagination={{ pageSize: SPSelected.insumosByIdRutina ? SPSelected.insumosByIdRutina.length : 0 }}
                                            />                
                                    </Col>
                                    
                                    <Col span={12}>
                                        <Divider orientation="left">Herramientas</Divider>
                                        <Table
                                            size='small'
                                            rowKey='idProducto'
                                            columns={columns}
                                            dataSource={SPSelected.herramientasByIdRutina ? SPSelected.herramientasByIdRutina : []}
                                            locale={{ emptyText: 'Sin datos' }}
                                            pagination={{ pageSize: SPSelected.herramientasByIdRutina ? SPSelected.herramientasByIdRutina.length : 0 }}
                                            />                       
                                    </Col>
                                </Row>
                                <Divider orientation="left">Asignación de Servicios Planificados</Divider>
                                <Alert
                                    message={`Favor de asignar ${SPSelected.programa == 'Rutina' ? 'una ubicación al' : 'una característica del activo al'} servicio planificado seleccionado.`}
                                    type="info"
                                />
                                <br />
                            </div> ) }

                            {SPSelected && SPSelected.programa === 'Rutina' ? (
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            label='Ubicación'
                                            name='idUbicacion'
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <Select allowClear
                                                mode='multiple'
                                                style={{ width: '100%' }}
                                                placeholder="Ingrese la ubicación"
                                                loading={fetchingUbicacionesSinHijos}
                                                showSearch
                                                filterOption={false}
                                                onSearch={handleSearch}
                                                notFoundContent={null}
                                                dropdownStyle={{ minWidth: "60%" }}
                                            >
                                                {ubicacionesSinHijos.map(option =>
                                                    <Option key={`${option.idUbicacion} - ${option.ubicacionCOA}`} >
                                                        <Popover content={option.ubicacionCOA} title={false}>
                                                            {`${option.idUbicacion} - ${option.ubicacionCOA}`}
                                                        </Popover>
                                                    </Option>
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                ): false
                            }
                            {SPSelected && SPSelected.programa !== 'Rutina' ? (    
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            label='Característica del activo'
                                            name='idActivo'
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <Select allowClear
                                                mode='multiple'
                                                style={{ width: '100%' }}
                                                placeholder="Ingrese la característica del activo"
                                                loading={fetchingListaActivosCaractFiltroAsignacionSP}
                                                showSearch
                                                filterOption={false}
                                                onSearch={handleSearchActivos}
                                                onSelect={selectActivos}
                                                onDeselect={deselectActivos}
                                                notFoundContent={null}
                                                dropdownStyle={{ minWidth: "60%" }}
                                            >
                                                {listaActivosCaractFiltroAsignacionSP.map(option =>
                                                    <Option key={`${option.idActivoCaract} - ${option.descripcion} - ${option.codigoAdicional}`} data={option}>
                                                        <Popover content={option.descripcion} title={false}>
                                                            {`${option.idActivoCaract} - ${option.descripcion} - ${option.codigoAdicional}`}
                                                        </Popover>
                                                    </Option>
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                ) : false
                            }
                            {SPSelected ? (
                                <Row type='flex' justify='center'>
                                    <Col span={12}>
                                        <Button  className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Crear Asignacion para Servicios Planificados</Button>
                                    </Col>
                                </Row>
                            ): false}
                        </Form>
                    </Spin>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalNuevaAsignacionSPlanificado: state.ServiciosPlanificadosReducer.showModalNuevaAsignacionSPlanificado,
        fetchingNuevaAsignacionSPlanificado: state.ServiciosPlanificadosReducer.fetchingNuevaAsignacionSPlanificado,
        page: state.ServiciosPlanificadosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchinglistaSPNoAsignados: state.ServiciosPlanificadosReducer.fetchinglistaSPNoAsignados,
        listaSPNoAsignados: state.ServiciosPlanificadosReducer.listaSPNoAsignados,
        fetchingUbicacionesSinHijos: state.SolicitudDeServiciosReducer.fetchingUbicacionesSinHijos,
        ubicacionesSinHijos: state.SolicitudDeServiciosReducer.ubicacionesSinHijos,
        fetchingListaActivosCaractFiltroAsignacionSP: state.AlmacenesReducer.fetchingListaActivosCaractFiltroAsignacionSP,
        listaActivosCaractFiltroAsignacionSP: state.AlmacenesReducer.listaActivosCaractFiltroAsignacionSP,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevaAsignacionSP: () => {
            dispatch({ type: "SHOW_MODAL_NUEVA_ASIGNACION_SP", showModalNuevaAsignacionSPlanificado: false});
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
        },
        onListaSPNoAsignados: () => {
            dispatch({ type: 'LISTA_SP_NO_ASIGNADOS_REQUEST'})
        },
        onListaUbicacionesFiltro: (busqueda) => {
            dispatch({ type: 'UBICACIONES_SIN_HIJOS_REQUEST', busqueda: busqueda });
        },
        onListaUbicacionesFiltroLimpiar: () => {
            dispatch({ type: 'UBICACIONES_SIN_HIJOS_SUCCESS', ubicacionesSinHijos: [] });
        },
        onListaActivosCaracteristicasFiltro: (busqueda) => {
            dispatch({ type: 'LISTA_ACTIVOS_CARACT_FILTRO_ASIGNACION_SP_REQUEST', busqueda: busqueda });
        },
        onListaActiviosCaracteristicasFiltroLimpiar: () => {
            dispatch({ type: 'LISTA_ACTIVOS_CARACT_FILTRO_ASIGNACION_SP_SUCCESS', listaActivosCaractFiltroAsignacionSP: [] });
        },
        onCrearAsignacionSP: (formulario, SPSelected, activosSelected, page, busqueda) => {
            dispatch({ type: 'NUEVA_ASIGNACION_SP_REQUEST', formulario: formulario, SPSelected: SPSelected, activosSelected:activosSelected, page: page, busqueda: busqueda})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalNuevaAsignacionSP);