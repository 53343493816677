import React, { Component } from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoSubInterior from './ModalNuevoSubInterior';
import ModalEditarSubInterior from './ModalEditarSubInterior';

const { Search } = Input;
const confirm = Modal.confirm;
class CatalogoSubInterior extends Component {
    componentDidMount(){
        this.props.onRequestSubInterior(1, this.state.busqueda);
    }
    state = {
        page: 1,
        busqueda: '',
    }
    render() { 
        const {
            listaSubInteriorBusqueda,
            fetchingListaSubInteriorBusqueda,
            listaSubInteriorBusquedaCantidad,
            onRequestSubInterior,
            onEstadoSubInterior,
            onModalNuevoSubInterior,
            onModalEditarSubInterior,
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        };

        const onBuscarSubInterior = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            onRequestSubInterior(1, busqueda);
        };

        const columns = [{
                title: 'SubInterior funcional',
                dataIndex: 'subInteriorFuncional',
                key: 'subInteriorFuncional',
                width: '18%'
            }, {
                title: 'Cantidad',
                dataIndex: 'cantidad',
                key: 'cantidad',
                width: '10%'
            }, {
                title: 'Interior funcional al que pertenece',
                dataIndex: 'interiorFuncional',
                key: 'interiorFuncional',
                width: '18%'
            }, {
                title: 'Unidad funcional a la que pertenece',
                dataIndex: 'unidadFuncional',
                key: 'unidadFuncional',
                width: '18%'
            }, {
                title: 'Área funcional a la que pertenece',
                dataIndex: 'areaFuncional',
                key: 'areaFuncional',
                width: '18%'
            }, {
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                width: '10%',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.subinteriorFuncionalActivoInactivo} onChange={() => handleDesactivar(record)} />
                ),
            }, {
                title: 'Editar',
                key: 'editar',
                width: '10%',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarSubinteriorFuncional} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },
        ];

        const onEditar = (key) => {
            onModalEditarSubInterior(key, this.state.page, this.state.busqueda);
        };

        const handleDesactivar = (key) => {
            const page = this.state.page;
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del subinterior funcional "'+ key.subInteriorFuncional +'"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoSubInterior(key.idSubInteriorFuncional, page);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        } 

        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onRequestSubInterior(pagination.current, this.state.busqueda);
        };
    
        const onNuevoSubInterior = () => {
            onModalNuevoSubInterior(this.state.page, this.state.busqueda);
        };
        const scroll = {"x": 1000 | true, "y": 1000};
        return ( 
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search className='buscarModulos' placeholder="Buscar subinterior funcional" onSearch={value => onBuscarSubInterior(value)} style={{ width: '100%' }} />
                    </Col>
                    {/* <Col span={8}>
                        <Button onClick={onNuevoSubInterior} disabled={!permisosUsuario.crearSubinteriorFuncional} className='nuevoUsuario'>Nuevo SubInterior Funcional</Button> 
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nuevo SubInterior Funcional' placement="topRight">
                            <Button className="iconTer" shape="circle"  onClick={onNuevoSubInterior} disabled={!permisosUsuario.crearSubinteriorFuncional} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table
                            size="small"
                            rowKey="subInteriorFuncional"
                            columns={columns}
                            dataSource={listaSubInteriorBusqueda}
                            loading={fetchingListaSubInteriorBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaSubInteriorBusquedaCantidad, simple: true, current: this.state.page }}
                            scroll={scroll}
                        />
                    </Col>
                </Row>
                <ModalNuevoSubInterior />
                <ModalEditarSubInterior />   
            </div>
        );
    }
}
 
const mapStateToProps = state => {
    return {
        listaSubInteriorBusqueda: state.CatalogosGeneralesReducer.listaSubInteriorBusqueda,
        fetchingListaSubInteriorBusqueda: state.CatalogosGeneralesReducer.fetchingListaSubInteriorBusqueda,
        listaSubInteriorBusquedaCantidad: state.CatalogosGeneralesReducer.listaSubInteriorBusquedaCantidad,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        onRequestSubInterior: (page, busqueda) => {
            dispatch({ type: "SUBINTERIOR_BUSQUEDA_REQUEST", page, busqueda });
        }, 
         onEstadoSubInterior: (idSubInteriorFuncional, page) => {
             dispatch({ type: "ESTADO_SUBINTERIOR_REQUEST", idSubInteriorFuncional, page })
         }, 
         onModalNuevoSubInterior: (page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_SUBINTERIOR", showModalNuevoSubInterior: true  ,page, busqueda});
        },
        onModalEditarSubInterior: (subInteriorSeleccionado, page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_SUBINTERIOR", showModalEditarSubInterior: true, subInteriorSeleccionado, page, busqueda })
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoSubInterior);