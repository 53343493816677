import React from 'react';
import { Form, Row, Col, Divider, Modal, Spin, Input, InputNumber, DatePicker, Button, Select} from 'antd';
import { connect } from "react-redux";
import { DeliveredProcedureOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const {Option} = Select;
const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };

class CapturaAbastecimiento extends React.Component {
     formRef = React.createRef();

     componentDidMount() {  }

     state = {  }


     render() {
          const { listaAsignacionCapacidadByIdServicioPublico } = this.props;

          const handleCancel = () => {
               this.props.onCloseModal();
               this.props.onCleanAsignacion();
          };

          const onFinish = (formulario) => {
               console.log('Success:', formulario);
          };

          const onFinishMap = (formulario) => {
               let listaCaptura = [];
               let abastecimiento = {};
               console.log('formulario', formulario);


               for (let key in formulario) {
                    if (Object.prototype.hasOwnProperty.call(formulario, key)) {
                         let idCampo = key.split('-');
                         let idSomething = key.split('_');
                         if (idCampo.length === 2) {
                              let idAsignacionServicioPublico = idCampo[1];
                              let campo = idCampo[0];
                              let valor = formulario[key];
          
                              let capturaFormulario = {[campo]: valor, 'idAsignacionServicioPublico': parseInt(idAsignacionServicioPublico)};
                              listaCaptura.push(capturaFormulario);
                         }

                         if(idSomething.length === 2){
                              let id = idSomething[1];
                              let campo = idSomething[0];
                              let valor = formulario[key];
          
                              let capturaFormulario = {[campo]: valor, 'id': parseInt(id)};
                              listaCaptura.push(capturaFormulario);
                         }
                    }
               }

               for(let i in listaCaptura){
                    if(listaCaptura[i].hasOwnProperty('unidadMedida')){
                         abastecimiento.idUnidadMedida = listaCaptura[i].id;
                    }

                    if(listaCaptura[i].hasOwnProperty('ubicacion')){
                         abastecimiento.idUbicacion = listaCaptura[i].id;
                    }

                    if(listaCaptura[i].hasOwnProperty('capacidad')){
                         abastecimiento.idAsignacionServicioPublico = listaCaptura[i].idAsignacionServicioPublico;
                    }

                    if(listaCaptura[i].hasOwnProperty('lecturaRegistrada')){
                         abastecimiento.lecturaRegistrada = listaCaptura[i].lecturaRegistrada;
                    }

                    if(listaCaptura[i].hasOwnProperty('fechaAbastecimiento')){
                         abastecimiento.fechaAbastecimiento = listaCaptura[i].fechaAbastecimiento._d.toLocaleDateString();
                         abastecimiento.horaAbastecimiento = listaCaptura[i].fechaAbastecimiento._d.toLocaleTimeString();
                    }

                    if(listaCaptura[i].hasOwnProperty('variableMedicion')){
                         abastecimiento.idVariableMedicion = listaCaptura[i].variableMedicion;
                    }
               }
               
               console.log('abastecimiento', abastecimiento);
               this.props.onRegistrarAbastecimiento(abastecimiento);
          };

          return (
               <Modal
                    visible={this.props.showModalCapturaAbastecimiento}
                    title={'Captura de Abastecimiento para el Servicio Público'}
                    onCancel={handleCancel}
                    footer={false}
                    width={'85%'}
               >
                    { this.props.servicioPublicoSeleccionado ? (
                         <Spin spinning={this.props.fetchingCrearAbastecimiento}>
                              <Form {...layout} name="formulario" ref={this.formRef} onFinish={onFinish}  >
                                   <Row justify='space-around' id='cuerpo'>
                                        <Col span={9}>
                                             <Form.Item
                                                  name='nombre'
                                                  label='Servicio Público'
                                                  rules={[{ required: false, message: 'Favor de llenar el campo'}]}
                                             >
                                                  <Input disabled defaultValue={this.props.servicioPublicoSeleccionado.nombre} />
                                             </Form.Item>
                                        </Col>
                                        <Col span={7}>
                                             <Form.Item
                                                  name='nombreDeposito'
                                                  label='Depósito de Almacenamiento'
                                                  rules={[{ required: false, message: 'Favor de llenar el campo'}]}
                                             >
                                                  <Input disabled defaultValue={this.props.servicioPublicoSeleccionado.nombreDeposito} />
                                             </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                             <Form.Item
                                                  name='cantidadDeposito'
                                                  label='Cantidad de Depósitos'
                                                  rules={[{ required: false, message: 'Favor de llenar el campo'}]}
                                             >
                                                  <Input disabled defaultValue={this.props.servicioPublicoSeleccionado.cantidadDeposito} style={{textAlign:'right'}}/>
                                             </Form.Item>
                                        </Col>
                                   </Row>
                                   <Divider />
                                   {this.props.listaAsignacionCapacidadByIdServicioPublico.map(listaAsignacionCapacidadByIdServicioPublico =>
                                   <Form {...layout} name="formulario" ref={this.formRef} onFinish={onFinishMap}  >
                                        <Row justify='space-around'>
                                             <Col span={6}>
                                                  <Form.Item
                                                       name={'ubicacion_' + listaAsignacionCapacidadByIdServicioPublico.idUbicacion + '-' +listaAsignacionCapacidadByIdServicioPublico.idAsignacionServicioPublico }
                                                       label={<strong>Ubicación </strong>}
                                                  >
                                                       <p>{listaAsignacionCapacidadByIdServicioPublico.claveUbicacion + ': '}<i>{listaAsignacionCapacidadByIdServicioPublico.nombreUbicacion}</i></p>
                                                  </Form.Item>
                                             </Col>

                                             <Col span={2}>
                                                  <Form.Item
                                                       name={`capacidad-${listaAsignacionCapacidadByIdServicioPublico.idAsignacionServicioPublico}`}
                                                       label='Capacidad'
                                                       rules={[{ required: false, message: 'Favor de llenar el campo'}]}
                                                  >
                                                       <Input disabled placeholder='Capacidad' defaultValue={listaAsignacionCapacidadByIdServicioPublico.capacidad} style={{textAlign:'right'}} />
                                                  </Form.Item>
                                             </Col>

                                             <Col span={3}>
                                                  <Form.Item
                                                       name={`unidadMedida_${listaAsignacionCapacidadByIdServicioPublico.idUnidadMedida}-${listaAsignacionCapacidadByIdServicioPublico.idAsignacionServicioPublico}`}
                                                       label='Unidad de Medida'
                                                       rules={[{ required: false, message: 'Favor de llenar el campo'}]}
                                                  >
                                                       <Input disabled placeholder='Unidad de Medida' defaultValue={listaAsignacionCapacidadByIdServicioPublico.unidadMedida}/>
                                                  </Form.Item>
                                             </Col>

                                             <Col span={3}>
                                                  <Form.Item
                                                       name={`variableMedicion-${listaAsignacionCapacidadByIdServicioPublico.idAsignacionServicioPublico}`}
                                                       label='Variable de Medición'
                                                       rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                                  >
                                                       <Select
                                                            allowClear
                                                            style={{ width: '100%' }}
                                                            placeholder="Seleccione una variable de medicion"
                                                            loading={this.props.fetchingListaVariablesMediconByIdServicioPublico}
                                                            
                                                       >
                                                            {this.props.listaVariablesMedicionByIdServicioPublico.map(option => 
                                                                 <Option key={option.idVariableMedicion} >
                                                                      {option.nombre}
                                                                 </Option>
                                                            )}
                                                       </Select> 
                                                  </Form.Item>
                                             </Col>

                                             <Col span={4}>
                                                  <Form.Item
                                                       name={`fechaAbastecimiento-${listaAsignacionCapacidadByIdServicioPublico.idAsignacionServicioPublico}`}
                                                       label='Fecha Abastecimiento'
                                                       rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                                  >
                                                       <DatePicker
                                                            style={{ width: '100%' }} format='DD-MM-YYYY HH:mm'  placeholder={["Seleccione fecha y hora abastecimiento"]} showTime={{ format: 'HH:mm' }}
                                                       />
                                                  </Form.Item>
                                             </Col>

                                             <Col span={3}>
                                                  <Form.Item
                                                       name={`lecturaRegistrada-${listaAsignacionCapacidadByIdServicioPublico.idAsignacionServicioPublico}`}
                                                       label='Abastecimiento'
                                                       rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                                  >
                                                       <InputNumber
                                                            placeholder='Lectura'
                                                            min={1}
                                                            max={listaAsignacionCapacidadByIdServicioPublico.capacidad}
                                                       />
                                                  </Form.Item>
                                             </Col>

                                             <Col span={1} style={{paddingTop:'2.8em'}}>
                                                  <Button shape="circle" icon={<DeliveredProcedureOutlined />} className="iconTer" htmlType='submit'/>
                                             </Col>
                                        </Row>
                                   </Form>
                                   )}
                              </Form>
                         </Spin>
                    ) : false }                
               </Modal>
          );             
     }
}

const mapStateToProps = state => {
     return {
          showModalCapturaAbastecimiento: state.ServiciosPublicosReducer.showModalCapturaAbastecimiento,
          servicioPublicoSeleccionado: state.ServiciosPublicosReducer.servicioPublicoSeleccionado,
          listaAsignacionCapacidadByIdServicioPublico: state.ServiciosPublicosReducer.listaAsignacionCapacidadByIdServicioPublico,

          listaVariablesMedicionByIdServicioPublico: state.ServiciosPublicosReducer.listaVariablesMedicionByIdServicioPublico,
          fetchingListaVariablesMediconByIdServicioPublico: state.ServiciosPublicosReducer.fetchingListaVariablesMediconByIdServicioPublico,
          fetchingCrearAbastecimiento: state.ServiciosPublicosReducer.fetchingCrearAbastecimiento,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onCloseModal: () => {
               dispatch({ type: 'SHOW_MODAL_CAPTURA_ABASTECIMIENTO', showModalCapturaAbastecimiento: false });
          },
          onRegistrarAbastecimiento: (abastecimiento) => {
               dispatch({ type: 'CREAR_ABASTECIMIENTO_REQUEST', abastecimiento });
          },
          onCleanAsignacion: () => {
               dispatch({ type: 'LISTA_ASIGNACION_CAPACIDAD_BYIDSERVICIOPUBLICO_SUCCESS', listaAsignacionCapacidadByIdServicioPublico: [] });
          }
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(CapturaAbastecimiento);