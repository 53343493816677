export const CAMBIAR_CATALOGO_SERVICIOS_VARIABLES = "CAMBIAR_CATALOGO_SERVICIOS_VARIABLES";

// CRUD ACTIONS VARIABLES CATALOGO
export const OBTENER_VARIABLES_REQUEST = "OBTENER_VARIABLES_REQUEST";
export const OBTENER_VARIABLES_SUCCESS = "OBTENER_VARIABLES_SUCCESS";
export const OBTENER_VARIABLES_FAILURE = "OBTENER_VARIABLES_FAILURE";

export const ESTADO_VARIABLES_REQUEST = "ESTADO_VARIABLES_REQUEST";
export const ESTADO_VARIABLES_SUCCESS = "ESTADO_VARIABLES_SUCCESS";
export const ESTADO_VARIABLES_FAILURE = "ESTADO_VARIABLES_FAILURE";

export const SHOW_MODAL_EDITAR_VARIABLE = "SHOW_MODAL_EDITAR_VARIABLE";
export const SHOW_MODAL_CREAR_VARIABLE = "SHOW_MODAL_CREAR_VARIABLE";

export const EDITAR_VARIABLES_REQUEST = "EDITAR_VARIABLES_REQUEST";
export const EDITAR_VARIABLES_SUCCESS = "EDITAR_VARIABLES_SUCCESS";
export const EDITAR_VARIABLES_FAILURE = "EDITAR_VARIABLES_FAILURE";

export const CREAR_VARIABLES_REQUEST = "CREAR_VARIABLES_REQUEST";
export const CREAR_VARIABLES_SUCCESS = "CREAR_VARIABLES_SUCCESS";
export const CREAR_VARIABLES_FAILURE = "CREAR_VARIABLES_FAILURE";


// CRUD ACTIONS SERVICIOS VARIABLES
export const OBTENER_SERVICIOS_VARIABLES_REQUEST = "OBTENER_SERVICIOS_VARIABLES_REQUEST";
export const OBTENER_SERVICIOS_VARIABLES_SUCCESS = "OBTENER_SERVICIOS_VARIABLES_SUCCESS";
export const OBTENER_SERVICIOS_VARIABLES_FAILURE = "OBTENER_SERVICIOS_VARIABLES_FAILURE";

export const BUSCAR_SERVICIOS_VARIABLES_REQUEST = "BUSCAR_SERVICIOS_VARIABLES_REQUEST";
export const BUSCAR_SERVICIOS_VARIABLES_SUCCESS = "BUSCAR_SERVICIOS_VARIABLES_SUCCESS";
export const BUSCAR_SERVICIOS_VARIABLES_FAILURE = "BUSCAR_SERVICIOS_VARIABLES_FAILURE";

export const CREAR_SERVICIO_VARIABLE_REQUEST = "CREAR_SERVICIO_VARIABLE_REQUEST";
export const CREAR_SERVICIO_VARIABLE_SUCCESS = "CREAR_SERVICIO_VARIABLE_SUCCESS";
export const CREAR_SERVICIO_VARIABLE_FAILURE = "CREAR_SERVICIO_VARIABLE_FAILURE";

export const SHOW_MODAL_CREAR_SERVICIO_VARIABLE = "SHOW_MODAL_CREAR_SERVICIO_VARIABLE";

export const EDITAR_SERVICIO_VARIABLE_REQUEST = "EDITAR_SERVICIO_VARIABLE_REQUEST";
export const EDITAR_SERVICIO_VARIABLE_SUCCESS = "EDITAR_SERVICIO_VARIABLE_SUCCESS";
export const EDITAR_SERVICIO_VARIABLE_FAILURE = "EDITAR_SERVICIO_VARIABLE_FAILURE";

export const SHOW_MODAL_EDITAR_SERVICIO_VARIABLE = "SHOW_MODAL_EDITAR_SERVICIO_VARIABLE";

export const OBTENER_VARIABLES_ACTIVAS_REQUEST = "OBTENER_VARIABLES_ACTIVAS_REQUEST";
export const OBTENER_VARIABLES_ACTIVAS_SUCCESS = "OBTENER_VARIABLES_ACTIVAS_SUCCESS";
export const OBTENER_VARIABLES_ACTIVAS_FAILURE = "OBTENER_VARIABLES_ACTIVAS_FAILURE";

export const OBTENER_VARIABLES_BY_SERVICIO_REQUEST = "OBTENER_VARIABLES_BY_SERVICIO_REQUEST";
export const OBTENER_VARIABLES_BY_SERVICIO_SUCCESS = "OBTENER_VARIABLES_BY_SERVICIO_SUCCESS";
export const OBTENER_VARIABLES_BY_SERVICIO_FAILURE = "OBTENER_VARIABLES_BY_SERVICIO_FAILURE";

export const ESTADO_SERVICIO_VARIABLES_REQUEST = "ESTADO_SERVICIO_VARIABLES_REQUEST";
export const ESTADO_SERVICIO_VARIABLES_SUCCESS = "ESTADO_SERVICIO_VARIABLES_SUCCESS";
export const ESTADO_SERVICIO_VARIABLES_FAILURE = "ESTADO_SERVICIO_VARIABLES_FAILURE";

export const SHOW_MODAL_CAPTURAR_INFO_SERVICIO_VARIABLE = "SHOW_MODAL_CAPTURAR_INFO_SERVICIO_VARIABLE";

export const LISTA_DETALLE_SERVICIO_VARIABLE_REQUEST = "LISTA_DETALLE_SERVICIO_VARIABLE_REQUEST";
export const LISTA_DETALLE_SERVICIO_VARIABLE_SUCCESS = "LISTA_DETALLE_SERVICIO_VARIABLE_SUCCESS";
export const LISTA_DETALLE_SERVICIO_VARIABLE_FAILURE = "LISTA_DETALLE_SERVICIO_VARIABLE_FAILURE";

export const SHOW_MODAL_AGREGAR_PRODUCTIVDAD_VARIABLE = "SHOW_MODAL_AGREGAR_PRODUCTIVDAD_VARIABLE";

//GET Subvariables
export const GET_SUBVARIABLE_BY_IDS_REQUEST =  'GET_SUBVARIABLE_BY_IDS_REQUEST';
export const GET_SUBVARIABLE_BY_IDS_SUCCESS = 'GET_SUBVARIABLE_BY_IDS_SUCCESS';
export const GET_SUBVARIABLE_BY_IDS_FAILURE = 'GET_SUBVARIABLE_BY_IDS_FAILURE';

export const GET_SUBVARIABLE_BY_ID_VARIABLE_REQUEST = 'GET_SUBVARIABLE_BY_ID_VARIABLE_REQUEST';
export const GET_SUBVARIABLE_BY_ID_VARIABLE_SUCCESS = 'GET_SUBVARIABLE_BY_ID_VARIABLE_SUCCESS';
export const GET_SUBVARIABLE_BY_ID_VARIABLE_FAILURE = 'GET_SUBVARIABLE_BY_ID_VARIABLE_FAILURE';

export const CAPTURAR_PRODUCTIVIDAD_VARIABLE_REQUEST = "CAPTURAR_PRODUCTIVIDAD_VARIABLE_REQUEST";
export const CAPTURAR_PRODUCTIVIDAD_VARIABLE_SUCCESS = "CAPTURAR_PRODUCTIVIDAD_VARIABLE_SUCCESS";
export const CAPTURAR_PRODUCTIVIDAD_VARIABLE_FAILURE = "CAPTURAR_PRODUCTIVIDAD_VARIABLE_FAILURE";

export const GUARDAR_PRODUCTIVIDAD_VARIABLE = 'GUARDAR_PRODUCTIVIDAD_VARIABLE';

export const OBTENER_UBICACIONES_ACTIVAS_REQUEST = "OBTENER_UBICACIONES_ACTIVAS_REQUEST";
export const OBTENER_UBICACIONES_ACTIVAS_SUCCESS = "OBTENER_UBICACIONES_ACTIVAS_SUCCESS";
export const OBTENER_UBICACIONES_ACTIVAS_FAILURE = "OBTENER_UBICACIONES_ACTIVAS_FAILURE";

export const OBTENER_VARIABLES_FILTRADAS_POR_SERVICIO_REQUEST = "OBTENER_VARIABLES_FILTRADAS_POR_SERVICIO_REQUEST";
export const OBTENER_VARIABLES_FILTRADAS_POR_SERVICIO_SUCCESS = "OBTENER_VARIABLES_FILTRADAS_POR_SERVICIO_SUCCESS";
export const OBTENER_VARIABLES_FILTRADAS_POR_SERVICIO_FAILURE = "OBTENER_VARIABLES_FILTRADAS_POR_SERVICIO_FAILURE";

export const ESTADO_VARIABLE_SERVICIO_VARIABLES_REQUEST = "ESTADO_VARIABLE_SERVICIO_VARIABLES_REQUEST";
export const ESTADO_VARIABLE_SERVICIO_VARIABLES_SUCCESS = "ESTADO_VARIABLE_SERVICIO_VARIABLES_SUCCESS";
export const ESTADO_VARIABLE_SERVICIO_VARIABLES_FAILURE = "ESTADO_VARIABLE_SERVICIO_VARIABLES_FAILURE";

export const PRESENTACION_SERVICIO_VARIABLES_REQUEST = "PRESENTACION_SERVICIO_VARIABLES_REQUEST";
export const PRESENTACION_SERVICIO_VARIABLES_SUCCESS = "PRESENTACION_SERVICIO_VARIABLES_SUCCESS";
export const PRESENTACION_SERVICIO_VARIABLES_FAILURE = "PRESENTACION_SERVICIO_VARIABLES_FAILURE";

export const SHOW_MODAL_PRESENTACION_SERVICIO_VARIABLE = "SHOW_MODAL_PRESENTACION_SERVICIO_VARIABLE";

export const EXPORTAR_EXCEL_SERVICIO_VARIABLES_REQUEST = "EXPORTAR_EXCEL_SERVICIO_VARIABLES_REQUEST";
export const EXPORTAR_EXCEL_SERVICIO_VARIABLES_SUCCESS = "EXPORTAR_EXCEL_SERVICIO_VARIABLES_SUCCESS";
export const EXPORTAR_EXCEL_SERVICIO_VARIABLES_FAILURE = "EXPORTAR_EXCEL_SERVICIO_VARIABLES_FAILURE";

export const LISTA_SERVICIOS_ACTIVOS_REQUEST = "LISTA_SERVICIOS_ACTIVOS_REQUEST";
export const LISTA_SERVICIOS_ACTIVOS_SUCCESS = "LISTA_SERVICIOS_ACTIVOS_SUCCESS";
export const LISTA_SERVICIOS_ACTIVOS_FAILURE = "LISTA_SERVICIOS_ACTIVOS_FAILURE";

export const PRESENTACION_SV_PDF_REQUEST = 'PRESENTACION_SV_PDF_REQUEST';
export const PRESENTACION_SV_PDF_SUCCESS = 'PRESENTACION_SV_PDF_SUCCESS';
export const PRESENTACION_SV_PDF_FAILURE = 'PRESENTACION_SV_PDF_FAILURE';

//Segumiento Diario de Productividades

export const GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_REQUEST = 'GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_REQUEST';
export const GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_SUCCESS = 'GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_SUCCESS';
export const GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_FAILURE = 'GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_FAILURE';

export const GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_TABLA_REQUEST = 'GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_TABLA_REQUEST';
export const GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_TABLA_SUCCESS = 'GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_TABLA_SUCCESS';
export const GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_TABLA_FAILURE = 'GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_TABLA_FAILURE';

export const PDF_SERVICIOS_VARIABLES_PRODUCTIVIDADES_REQUEST = 'PDF_SERVICIOS_VARIABLES_PRODUCTIVIDADES_REQUEST';
export const PDF_SERVICIOS_VARIABLES_PRODUCTIVIDADES_SUCCESS = 'PDF_SERVICIOS_VARIABLES_PRODUCTIVIDADES_SUCCESS';
export const PDF_SERVICIOS_VARIABLES_PRODUCTIVIDADES_FAILURE = 'PDF_SERVICIOS_VARIABLES_PRODUCTIVIDADES_FAILURE';

export const EXCEL_SERVICIOS_VARIABLES_PRODUCTIVIDADES_REQUEST = 'EXCEL_SERVICIOS_VARIABLES_PRODUCTIVIDADES_REQUEST';
export const EXCEL_SERVICIOS_VARIABLES_PRODUCTIVIDADES_SUCCESS = 'EXCEL_SERVICIOS_VARIABLES_PRODUCTIVIDADES_SUCCESS';
export const EXCEL_SERVICIOS_VARIABLES_PRODUCTIVIDADES_FAILURE = 'EXCEL_SERVICIOS_VARIABLES_PRODUCTIVIDADES_FAILURE';

export const LISTA_SERVICIOS_VARIABLES_BY_IDSERVICIO_REQUEST = 'LISTA_SERVICIOS_VARIABLES_BY_IDSERVICIO_REQUEST';
export const LISTA_SERVICIOS_VARIABLES_BY_IDSERVICIO_SUCCESS = 'LISTA_SERVICIOS_VARIABLES_BY_IDSERVICIO_SUCCESS';
export const LISTA_SERVICIOS_VARIABLES_BY_IDSERVICIO_FAILURE = 'LISTA_SERVICIOS_VARIABLES_BY_IDSERVICIO_FAILURE';



