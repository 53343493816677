import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col, Divider, Modal, Button, Radio, DatePicker, Card, message, Spin, Tooltip, Select} from 'antd';
import { Bar } from 'react-chartjs-2';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { BarChartOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';

const { Option } = Select;
const { RangePicker } = DatePicker;

const ModalPresentacionServicioGeneral = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [selectServicio, setSelecServicio] = useState(true);
    const [selectVariable, setSelectVariable] = useState(true);
    const [habilitarExportacion, setHabilitarExportacion] = useState(true);
    const [stateFormulario, setStateFormulario] = useState([])
    const [filterSelected, setFilterSelected] = useState('General');


    const showModalPresentacionServicioVariable = useSelector((state) =>state.ServiciosVariablesReducer.showModalPresentacionServicioVariable);
    const page = useSelector((state) =>state.ServiciosVariablesReducer.page);
    const busqueda = useSelector((state) =>state.ServiciosVariablesReducer.busqueda);
    const serVariablesResponse = useSelector((state) =>state.ServiciosVariablesReducer.serVariablesResponse);
    const dataGraficaUnitarioPSV = useSelector((state) =>state.ServiciosVariablesReducer.dataGraficaUnitarioPSV);
    const optionsGraficaPSV = useSelector((state) =>state.ServiciosVariablesReducer.optionsGraficaPSV);
    const listaUbicacionesFiltro = useSelector((state) =>state.CatalogosGeneralesReducer.listaUbicacionesFiltro);
    const fetchingListaUbicacionesFiltro = useSelector((state) =>state.CatalogosGeneralesReducer.fetchingListaUbicacionesFiltro);
    const fetchingListaVariablesByServicio = useSelector((state) => state.ServiciosVariablesReducer.fetchingListaVariablesByServicio);
    const listaVariablesByServicio = useSelector((state) =>  state.ServiciosVariablesReducer.listaVariablesByServicio);
    const listSubvariablesByVariable = useSelector((state) =>state.ServiciosVariablesReducer.listSubvariablesByVariable);
    const listaServiciosVariablesBusqueda = useSelector((state) => state.ServiciosVariablesReducer.listaServiciosVariablesBusqueda);
    const fetchinglistaServiciosVariables = useSelector((state) => state.ServiciosVariablesReducer.fetchinglistaServiciosVariables);
    const fetchingPresentacionSVPDF = useSelector((state) => state.ServiciosVariablesReducer.fetchingPresentacionSVPDF);

     useEffect(() => {
        if(showModalPresentacionServicioVariable==false){
            form.resetFields();
        }
        form.setFieldsValue({
            comparativa: filterSelected,
        });
        dispatch({ type: "BUSCAR_SERVICIOS_VARIABLES_REQUEST"})
        dispatch({ type: "LISTA_UBICACIONES_FILTRO_REQUEST", busqueda: 'adm'});
     }, [form, showModalPresentacionServicioVariable]);    
    
     //funciones
    const onFinish = formulario => {
        console.log("formulario",formulario)
        let label = [];
        setHabilitarExportacion(false)
        if(formulario.idServicioVariable) {
            for (const k in listaServiciosVariablesBusqueda) {
                if(listaServiciosVariablesBusqueda[k].idServicioVariable === parseInt(formulario.idServicioVariable)){
                    label.push({servicio:listaServiciosVariablesBusqueda[k].nombre})
                }
            }
        }
        if(formulario.idSubvariable) {
            for (const i in listSubvariablesByVariable) {
                if(listSubvariablesByVariable[i].idSubvariable === parseInt(formulario.idSubvariable)){
                    label.push({subvariable:listSubvariablesByVariable[i].nombre })
                }
            }
        }
        
        dispatch({ type: "PRESENTACION_SERVICIO_VARIABLES_REQUEST", formulario:formulario, comparativa: formulario.comparativa, idVariable: formulario.comparativa === 'Unitario' ? formulario.idVariable : '', idSubvariable: formulario.comparativa === 'Unitario' ? formulario.idSubvariable : '', idServicioVariable: formulario.comparativa === 'Unitario' ? parseInt(formulario.idServicioVariable) : 1, page:1, label:label})
    }; 

    const changeVariable = (variable) =>{
        dispatch({ type: "GET_SUBVARIABLE_BY_ID_VARIABLE_REQUEST", idVariable: variable});
        console.log("variable",variable)
        setSelectVariable(false);
        form.setFieldsValue({
            idSubvariable: undefined,
        });

        form.setFieldsValue({
            idVariable: variable,
        });
    }

    const handleSearch = (param) =>{
        if(param){
            dispatch({ type: "LISTA_UBICACIONES_FILTRO_REQUEST", busqueda: param})
        }
    }

    const changeValueFilter = (filter) =>{
        setFilterSelected(filter.target.value)
        const newFormulario = form.getFieldsValue();
        let label = [];
        console.log("newFormulario en changeValueFilter",newFormulario)
        console.log("stateFormulario",stateFormulario)
        if(newFormulario.idServicioVariable) {
            for (const k in listaServiciosVariablesBusqueda) {
                if(listaServiciosVariablesBusqueda[k].idServicioVariable === parseInt(newFormulario.idServicioVariable)){
                    label.push({servicio:listaServiciosVariablesBusqueda[k].nombre})
                }
            }
            setStateFormulario(newFormulario)
        }
        if(newFormulario.idSubvariable) {
            for (const i in listSubvariablesByVariable) {
                if(listSubvariablesByVariable[i].idSubvariable === parseInt(newFormulario.idSubvariable)){
                    label.push({subvariable:listSubvariablesByVariable[i].nombre })
                }
            }
        }
        if(stateFormulario.idServicioVariable || filter.target.value === 'General'){
            dispatch({ type: "PRESENTACION_SERVICIO_VARIABLES_REQUEST", formulario: filter.target.value === 'General' ? newFormulario : stateFormulario, comparativa: filter.target.value, idVariable: filter.target.value === 'Unitario' ? stateFormulario.idVariable : '', idSubvariable: filter.target.value === 'Unitario' ? stateFormulario.idSubvariable : '', idServicioVariable: filter.target.value === 'Unitario' ? parseInt(stateFormulario.idServicioVariable) : 1, page:1, label:label})
        }
        else{
            message.warning('El formulario no cuenta con un servicio seleccionado, por favor elije uno.');
            dispatch({ type: "PRESENTACION_SERVICIO_VARIABLES_SUCCESS", servicioPresentacion: {}, serVariablesResponse:[], dataGraficaUnitarioPSV:{}, optionsGraficaPSV:{}})
        }
      
    }

    const onExportar = () => {
        dispatch({ type: "EXPORTAR_EXCEL_SERVICIO_VARIABLES_REQUEST", serVariablesResponse, page, busqueda});
    };

    const onDescargarPDF = () =>{
        const newFormulario = form.getFieldsValue()
        dispatch({ type: "PRESENTACION_SV_PDF_REQUEST", formulario: newFormulario, rangeValue: newFormulario.fechas, idServicioVariable: newFormulario.idServicioVariable , page})
    }
    const changeServicio = (data) =>{
        setSelecServicio(false);
        form.setFieldsValue({
            idVariable: undefined,
        });
        form.setFieldsValue({
            idSubvariable: undefined,
        });
        dispatch({ type: "OBTENER_VARIABLES_BY_SERVICIO_REQUEST", idServicioVariable:parseInt(data)})
    }

    console.log("listaServiciosVariablesBusqueda en componente",listaServiciosVariablesBusqueda)

    return ( 
        <Form 
                name='formulario'
                onFinish={onFinish}
                preserve={false}
                layout="vertical"
                form={form} 
        >
                <Row>
                    <Col span={4} offset={1}>
                        <Form.Item
                                label="Seleccionar Filtro"
                                name="comparativa"
                                rules={[{ required: true, message: 'Favor de elegir tipo de salida', }]}
                        >
                                <Radio.Group onChange={changeValueFilter}>
                                    <Radio value="Unitario">Unitario</Radio>
                                    <Radio value="General">General</Radio>
                                </Radio.Group>
                        </Form.Item>
                    </Col> 
                    {filterSelected==='General' ? (
                        <>
                            
                            <Col span={16} offset={1}>
                                <Form.Item
                                        label="Periodo"
                                        name="fechas"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <RangePicker style={{ width: '100%' }} format={'DD/MM/YYYY'} /*disabledDate={disabledRangeToday}*//>
                                </Form.Item>
                            </Col>
                            <Col span={1} offset={1} style={{paddingTop:'2.8em'}}>
                                <Tooltip title='Ver Presentacion' placement="topRight">
                                        <Button className="iconTer" shape="circle"htmlType="submit" icon={<BarChartOutlined />}/>
                                </Tooltip>
                            </Col>
                        </>
                        
                        ) : (
                            <>
                                <Col span={7} offset={1}>
                                        <Form.Item
                                            label="Periodo"
                                            name="fechas"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                            <RangePicker style={{ width: '100%' }} format={'DD/MM/YYYY'}/>
                                        </Form.Item>
                                </Col>
                                <Col span={8} offset={1}>
                                        <Form.Item
                                            label="Ubicación"
                                            name='idUbicacion'
                                        >
                                            <Select
                                                style={{ width: '100%' }}
                                                placeholder="Selecciona una ubicación"
                                                showSearch
                                                loading={fetchingListaUbicacionesFiltro}
                                                filterOption={false}
                                                onSearch={handleSearch}
                                            >
                                                {listaUbicacionesFiltro.map(option => <Option key={option.idUbicacion}> {option.todoUbicacion}</Option>)}
                                            </Select>
                                        </Form.Item>
                                </Col>
                                <Col span={9} offset={1}>
                                    <Form.Item label="Servicio" name="idServicioVariable"  rules={[{ required: filterSelected === 'Unitario' ? true : false, message: 'Favor de elegir un servicio', }]}>
                                        <Select
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona un servicio"
                                            onChange={changeServicio}
                                            loading={fetchinglistaServiciosVariables}
                                            showSearch={true}
                                        >
                                            {listaServiciosVariablesBusqueda.map(option => <Option key={option.idServicioVariable + ' - ' + option.nombre}>{option.idServicioVariable + ' - ' + option.nombre}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={5} offset={1}>
                                        <Form.Item
                                            label="Variable"
                                            name="idVariable"
                                        >
                                            <Select
                                                allowClear
                                                style={{ width: '100%' }}
                                                placeholder="Selecciona el servicio"
                                                disabled={selectServicio}
                                                onChange={changeVariable}
                                                loading={fetchingListaVariablesByServicio}
                                            >
                                                {listaVariablesByServicio.map(option => <Option key={option.idVariable}>{option.variable}</Option>)}
                                            </Select>
                                        </Form.Item>
                                </Col>
                                <Col span={5} offset={1}>
                                        <Form.Item
                                            label="Subvariable"
                                            name="idSubvariable"
                                        >
                                            <Select
                                                allowClear
                                                style={{ width: '100%' }}
                                                disabled={selectVariable}
                                                placeholder="Selecciona la Subvariable"
                                            >
                                                {listSubvariablesByVariable.map(option => <Option key={option.idSubvariable}>{option.nombre}</Option>)}
                                            </Select>
                                        </Form.Item>
                                </Col>
                                <Col span={1} offset={1} style={{paddingTop:'2.8em'}}>
                                        <Tooltip title='Ver Presentacion' placement="topRight">
                                            <Button className="iconTer" shape="circle" htmlType="submit" icon={<BarChartOutlined />}/>
                                        </Tooltip>
                                </Col>
                            </>
                        )
                    }
                </Row>
                <Col span={24}>
                    <Row justify='center' id='grafico' className="sansserif" gutter={[8,8]}>
                        <Col span={24} >
                            <Card
                                    title={
                                        <Row justify='space-around' gutter={[8,8]}>
                                            <Col span={20}>
                                            {'Presentación General'}
                                            </Col>
                                            <Col span={1}>
                                                <Tooltip title='Exportar archivo .xls' placement="topRight">
                                                        <Button className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } onClick={onExportar} disabled={habilitarExportacion}/>
                                                </Tooltip>
                                            </Col>
                                            <Col span={1}>
                                                <Tooltip title='Exportar archivo .pdf' placement="topRight">
                                                        <Button className="iconSecundary" shape='circle' loading={fetchingPresentacionSVPDF} icon={<FilePdfOutlined /> } onClick={onDescargarPDF} disabled={habilitarExportacion}/>
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    }
                                    className="listaCatalogos"
                                    bordered={true}
                                    style={{ align: 'center' }}
                            >
                                    <Bar data={dataGraficaUnitarioPSV} options={optionsGraficaPSV} />
                            </Card>
                        </Col>
                    </Row>  
                </Col>
        </Form>
    );
}
export default ModalPresentacionServicioGeneral;