import React from 'react';
import {Form, Spin, Row, Col, Table, Tooltip, Button, Typography, Menu, Result, Modal, Card, Calendar, Tag, Select} from 'antd';
import {connect} from "react-redux";
import '../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { SaveOutlined, FileSearchOutlined, SelectOutlined } from '@ant-design/icons';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-classic-base64-upload/build/ckeditor';
import moment from 'moment';
import ModalCalendarioDetalle from '../ServiciosPlanificados/CalendarioProgramación/ModalCalendarioDetalle';

const { Title, Text } = Typography;
const { SubMenu } = Menu;

ClassicEditor.defaultConfig = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'insertTable',
        '|',
        'imageUpload',
        '|',
        'undo',
        'redo'
      ]
    },
    image: {
      toolbar: [
        'imageStyle:full',
        'imageStyle:side',
        '|',
        'imageTextAlternative'
      ]
    },
    table: {
      contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
    },
    language: 'es'
  };

class Equipo extends React.Component {
    formRef = React.createRef();

    componentDidMount() {
        //this.props.onObtenerArchivoMesAnterior( this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP);
        this.props.onGetListaGrupoSerForRMDP();
        this.props.onGetSubfamilias();
        this.props.onGetFamiliasDeActivos();
    }

    state = { data: '', currentMenu: null, etiqueta: '', idServicio: null, idPartida: null, page: 1, visible: false, etiquetaModal: '', pageActivos: 1}

    handleOk = () => { this.setState({ visible: false}) };  
    handleCancel = () => { this.setState({ visible: false }) };

    render() {
        const {
            anioSeleccionadoRMDP,
            mesSeleccionadoRMDP,
            mesTexto,
            fetchingObtenerArchivoMesAnteriorEquipo,
            archivoMesAnteriorEquipo,
            RMDPObject,
            listaGruposServiciosRMDP,
            onGetTareas,
            baseDataEquipo,
            listaServiciosEquipo,
            onGetActual,
            archivoMesActualEquipo,
            fetchingObtenerArchivoMesActualEquipo,
            listaProgramacionForEquipo,
            fetchingListaCalendarioProgramacion,

            listaSubfamilias,
            listaFamiliasDeActivos
        } = this.props;

        let dias = new Date(anioSeleccionadoRMDP, mesSeleccionadoRMDP, 0).getDate();
        let mesCalendar = mesSeleccionadoRMDP;
        if(parseInt(mesCalendar) < 10){
            mesCalendar = '0' + mesCalendar;
        }
        let fechaInicio = anioSeleccionadoRMDP + '-' + mesCalendar + '-01'; 
        let fechaFin = anioSeleccionadoRMDP + '-' + mesCalendar + '-' + dias;


        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const onGuardarEquipo = () => {
            console.log("RMDPObject", RMDPObject);
            let validar = false;

            if(RMDPObject.informacionAdicionalServicioList){
                if(RMDPObject.informacionAdicionalServicioList[0]){
                    for(let i in RMDPObject.informacionAdicionalServicioList){
                        if(RMDPObject.informacionAdicionalServicioList[i].idPartida){
                            if(RMDPObject.informacionAdicionalServicioList[i].idPartida === parseInt(this.state.idPartida)){
                                validar = true
                            } 
                        } else if(RMDPObject.informacionAdicionalServicioList[i].idServicio) {
                            if(RMDPObject.informacionAdicionalServicioList[i].idServicio === parseInt(this.state.idServicio)){
                                validar = true
                            }
                        }
                    }
                }
            }

            if (this.state.data.length > 0) {
                if (validar) {
                    console.log('Entra a editar');
                    this.props.onEditarEquipo(mesSeleccionadoRMDP, anioSeleccionadoRMDP, this.state.data, RMDPObject, this.state.idServicio, this.state.idPartida, this.state.etiqueta);
                } else {
                    console.log('Entra a crear');
                    this.props.onCrearEquipo(mesSeleccionadoRMDP, anioSeleccionadoRMDP, this.state.data, RMDPObject, this.state.idServicio, this.state.idPartida, this.state.etiqueta);
                }
            }
        };

        const handleClickMenu = e => {
            console.log('click ', e);
            let etiqueta = '1.3.' + e.key + ' - ' + e.item.props.children;
            let etiquetaModal = '1.3.' + e.key + ' ' + e.item.props.children;

            this.props.onCleanAnterior(); this.props.onCleanActual();

            if(e.keyPath.length === 3){
                etiqueta = '1.3.' + e.keyPath[1].split('-')[1] + ' - ' + e.item.props.children;
                etiquetaModal = '1.3.' + e.keyPath[1].split('-')[1];
                let part = e.keyPath[1].split('-')[1];
                this.setState({idPartida:  e.key.split('-')[1], idServicio: part.split(' ')[0]});
                onGetActual(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, 'equipo_Ser-' + part.split(' ')[0] +'_Par-' + e.key.split('-')[1] + '_' + this.props.mesSeleccionadoRMDP + '_' +this.props.anioSeleccionadoRMDP + '.txt', part.split(' ')[0], e.key.split('-')[1]);
                this.props.onObtenerArchivoMesAnterior( this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, part.split(' ')[0], e.key.split('-')[1]);

                this.props.onCalendarioProgramacion(fechaInicio, fechaFin, e.keyPath[2].split('-')[1], null, part.split(' ')[0]);

                this.props.onGetActivosByIdServicio(part.split(' ')[0], this.state.pageActivos);
            } else {
                this.setState({idServicio:  e.key, idPartida: null});
                onGetActual(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, 'equipo_Ser-' + e.key +'_' + this.props.mesSeleccionadoRMDP + '_' +this.props.anioSeleccionadoRMDP + '.txt', e.key, '');
                this.props.onObtenerArchivoMesAnterior( this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, e.key, '');

                this.props.onCalendarioProgramacion(fechaInicio, fechaFin, e.keyPath[1].split('-')[1], null, e.key);

                this.props.onGetActivosByIdServicio(e.key, this.state.pageActivos);
            }
            this.setState({ currentMenu: e.key, etiqueta, etiquetaModal });
        };

        const functionTer = (optionSub) => {
            if(optionSub.partidasByIdServicio){
                return (
                    <SubMenu key={'ser-' + optionSub.idServicio + ' ' + optionSub.nombre} title={optionSub.nombre}>
                        { optionSub.partidasByIdServicio.map( optionSubSub => 
                            <Menu.Item key={'par-' + optionSubSub.idPartida}>
                                { optionSubSub.nombre }
                            </Menu.Item>
                        )}
                    </SubMenu>
                );
            } else {
                return (
                    <Menu.Item key={optionSub.idServicio}>
                        { optionSub.nombre }
                    </Menu.Item>
                );
            }
        };

        const onVerDetalle = () => {
            console.log('Entra a ver detalle')
            onGetTareas(this.state.idServicio, mesSeleccionadoRMDP, anioSeleccionadoRMDP, this.state.page);
            this.props.onObtenerActivosPorServicio(this.state.idServicio, 1, '');
            this.setState({ visible: true });
        };

        const columnsModal = [
            {title: 'Familia', dataIndex: 'familia', key: 'familia', align: 'center'},
            {title: 'Subfamilia', dataIndex: 'subFamilia', key: 'subFamilia', align: 'center'},
            {title: 'Característica', dataIndex: 'descripcion', key: 'descripcion', align: 'center'},
            {title: 'Cantidad', dataIndex: 'cantidad', key: 'cantidad', align: 'right'},
        ];

        function getListData(value) {
            let listData = [];
            for(let i = 0; i < listaProgramacionForEquipo.length; i ++){
                if(value.format('YYYY-MM-DD') === listaProgramacionForEquipo[i].fecha.split('T')[0]) {
                    if(listaProgramacionForEquipo[i].calendarioProgramacionRutinaResponse[0]){
                        for(let x in listaProgramacionForEquipo[i].calendarioProgramacionRutinaResponse){
                            listData.push(listaProgramacionForEquipo[i].calendarioProgramacionRutinaResponse[x]);
                        }
                    }
                }
            }
            return listData;
        }

        const tagSeleccionado = (item) => {
            console.log(`item en tagSeleccionado`, item)
            item.fechaModal = moment(item.calendarioProgramacionQueryResponse[0].fechaRutina).format('LLLL').split(' 0:00')[0].charAt(0).toUpperCase() + moment(item.calendarioProgramacionQueryResponse[0].fechaRutina).format('LLLL').split(' 0:00')[0].slice(1);
            console.log('item', item, this.state.busqueda);
            let busqueda = {
                idGrupoSer: '',
                idServicio: '',
                mesVista: '',
                tipoPrograma: '',
            }
            this.props.onCalendarioDetalle(item, listaFamiliasDeActivos, listaSubfamilias);
            this.props.onShowModalCalendarioDetalle(item, this.state.busqueda)
        }

        function dateCellRender(value) {
            const listData = getListData(value);
            let style = { textAlign: 'center'};
  
            return (
                <ul className="events">
                    {listData.map(item => (
                    <li key={item.idRutina}>
                        <div style={style}>
                            <Tag onClick={() => tagSeleccionado(item)} color={item.color} className='tag-rad' style={{ width:'90%', textAlign:'center' }}><Tooltip placement="topLeft" title={item.rutina}>{item.rutina}</Tooltip></Tag>
                        </div>
                    </li>
                    ))}
                </ul>
            );
        }

        const headerRender = () => null;

        const handleTableActivosChange = (pagination) => {
            this.setState({ pageActivos: pagination.current, });
            this.props.onGetActivosByIdServicio(this.state.idServicio, pagination.current);
        }

        return (<>
            <Row justify='space-around'>
                <Col span={24}>
                    <Spin spinning={this.props.fetchingListaGruposServicios}>
                        <Menu onClick={handleClickMenu} mode="horizontal" theme='dark' style={{textAlign:'center', alignContent:'space-between'}}>
                            { listaGruposServiciosRMDP.map( option => 
                                <SubMenu key={'grup-' + option.idGrupoSer} title={option.nombre}>
                                    { option.serviciosListByIdGrupo.map( optionSub =>
                                        functionTer(optionSub)
                                    ) }
                                </SubMenu>
                            ) }
                        </Menu>
                    </Spin>
                    <br />
                </Col>
            </Row>
            
            { this.state.currentMenu ? (<Spin spinning={this.props.fetchingEditarEquipo || this.props.fetchingCrearEquipo}>
                <Row justify='start'>
                    <Col span={24}>
                        <Title level={4}>Información Adicional</Title>
                        <Text strong>{this.state.etiqueta}</Text>
                    </Col>
                </Row>

                <Row justify='end'>
                    <Col span={4} style={{backgroundColor: '#737373', borderTopLeftRadius: '1em', borderTopRightRadius: '1em', textAlign:'center'}}>
                        <Text style={{ fontSize: 16, color: "white"}} strong> {anioSeleccionadoRMDP} - {mesTexto} </Text>
                    </Col>
                </Row>
                <Spin spinning={fetchingObtenerArchivoMesActualEquipo || fetchingObtenerArchivoMesAnteriorEquipo } >
                    { console.log('archivoMesAnteriorEquipo en Contenedor', archivoMesAnteriorEquipo) }
                    { console.log('archivoMesActualEquipo  en Contenedor', archivoMesActualEquipo) }

                    { archivoMesActualEquipo || archivoMesAnteriorEquipo ? (
                        <CKEditor
                            editor={ClassicEditor}
                            data={archivoMesActualEquipo ? archivoMesActualEquipo : archivoMesAnteriorEquipo }
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                this.setState({
                                    data: data
                                });
                                console.log({event, editor, data});
                            }}
                            onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                            }}
                        />
                    ):false }
                </Spin>

                <Row justify='end' style={{padding:'1em'}}>
                    <Col span={1}>
                        <Tooltip title='Ver Detalle'>
                            <Button className="iconTer" shape="circle" icon={<FileSearchOutlined />} onClick={onVerDetalle}/>
                        </Tooltip>
                    </Col>
                    <Col span={1}>
                        <Tooltip title='Guardar Cambios'>
                            <Button className="iconTer" shape="circle" icon={<SaveOutlined />} onClick={onGuardarEquipo}/>
                        </Tooltip>
                    </Col>

                    <Col span={24}>
                        <Modal title="Detalle" visible={this.state.visible} footer={false} onCancel={this.handleCancel}  width = {'80%'} >
                            <h2>{ this.state.etiquetaModal }</h2>
                            <Row justify='space-around' style={{padding:'1em'}}>
                                <Col span={24}>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={archivoMesActualEquipo ? archivoMesActualEquipo : baseDataEquipo}
                                        onReady={editor => { editor.isReadOnly = true; }}
                                    />
                                </Col>
                            </Row>

                            <Row justify='space-around' style={{padding:'1em'}}>
                                { this.props.listaServiciosByIdServicioCountCantidad > 0 ? (
                                    <Col span={24} style={{paddingBottom:'1em'}}>
                                        <Table
                                            rowKey="id"
                                            size='small'
                                            columns={columnsModal}
                                            dataSource={this.props.listaServiciosByIdServicioCount}
                                            loading={this.props.fetchingListaServicioByIdServicioCount}
                                            locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar'}}
                                            onChange={handleTableActivosChange}
                                            pagination={{ total: this.props.listaServiciosByIdServicioCountCantidad, simple: true, current: this.state.pageActivos }}
                                        />
                                    </Col>
                                ):false}


                            {listaProgramacionForEquipo[0] ? (
                            <Calendar 
                                defaultValue={moment(fechaInicio)}
                                loading={fetchingListaCalendarioProgramacion}
                                dateCellRender={dateCellRender}
                                onSelect={this.onSelect}
                                // headerRender={headerRender}
                                headerRender={({ value, type, onChange }) => {
                                    const start = 0;
                                    const end = 12;
                                    const monthOptions = [];
                            
                                    const current = value.clone();
                                    const localeData = value.localeData();
                                    const months = [];
                                    for (let i = 0; i < 12; i++) {
                                        current.month(i);
                                        months.push(localeData.monthsShort(current));
                                    }
                            
                                    for (let index = start; index < end; index++) {
                                        monthOptions.push(
                                            <Select.Option className="month-item" key={`${index}`}>
                                                {months[index]}
                                            </Select.Option>,
                                        );
                                    }
                                    const month = value.month();
                            
                                    const year = value.year();
                                    const options = [];
                                    for (let i = year - 10; i < year + 10; i += 1) {
                                        options.push(
                                            <Select.Option key={i} value={i} className="year-item">
                                                {i}
                                            </Select.Option>,
                                        );
                                    }
                                    return (
                                        <div style={{ padding: 8 }}>
                                            {/* <Row justify='center'>
                                                <Col style={{fontSize: '5em', color: '#e64d14'}}>
                                                    {value.format('LLLL').split(' 0:00')[0].toUpperCase().split(', ')[1].split(' ')[2]}
                                                </Col>
                                            </Row> */}
                                            <Row justify='end'>
                                                <Col>
                                                    <Select
                                                    dropdownMatchSelectWidth={false}
                                                    className="my-year-select"
                                                    onChange={newYear => {
                                                        const now = value.clone().year(newYear);
                                                        onChange(now);
                                                    }}
                                                    value={String(year)}
                                                    >
                                                    {options}
                                                    </Select>
                                                </Col>
                                                <Col>
                                                    <Select
                                                    dropdownMatchSelectWidth={false}
                                                    value={String(month)}
                                                    onChange={selectedMonth => {
                                                        const newValue = value.clone();
                                                        newValue.month(parseInt(selectedMonth, 10));
                                                        onChange(newValue);
                                                    }}
                                                    >
                                                    {monthOptions}
                                                    </Select>
                                                </Col>
                                            </Row>
                                        </div>
                                    );
                                }}
                            />
                            ):false}
                            </Row>
                        </Modal>

                        <ModalCalendarioDetalle />
                    </Col>
                </Row>
            </Spin>):(<>
                 <Row justify='space-around' align='middle' style={{height:'35em'}}>
                     <Result icon={<SelectOutlined />} subTitle="Es necesario seleccionar un servicio o partida." />
                 </Row>
            </>) }
        </>);
    }
}

const mapStateToProps = state => {
    return {
        anioSeleccionadoRMDP: state.RMDPReducer.anioSeleccionadoRMDP,
        mesSeleccionadoRMDP: state.RMDPReducer.mesSeleccionadoRMDP,
        mesTexto: state.RMDPReducer.mesTexto,
        fetchingObtenerArchivoMesAnteriorEquipo: state.RMDPReducer.fetchingObtenerArchivoMesAnteriorEquipo,
        archivoMesAnteriorEquipo: state.RMDPReducer.archivoMesAnteriorEquipo,
        RMDPObject: state.RMDPReducer.RMDPObject,

        listaGruposServiciosRMDP: state.RMDPReducer.listaGruposServiciosRMDP,
        fetchingListaGruposServicios: state.RMDPReducer.fetchingListaGruposServicios,

        busquedaTareasRMDP: state.RMDPReducer.busquedaTareasRMDP,
        fetchingBuscarTareasRMDP: state.RMDPReducer.fetchingBuscarTareasRMDP,

        listaServiciosEquipo: state.RMDPReducer.listaServiciosEquipo,
        fetchingListaServiciosForEquipo: state.RMDPReducer.fetchingListaServiciosForEquipo,

        archivoMesActualEquipo: state.RMDPReducer.archivoMesActualEquipo,
        fetchingObtenerArchivoMesActualEquipo: state.RMDPReducer.fetchingObtenerArchivoMesActualEquipo,

        fetchingListaCalendarioProgramacion: state.RMDPReducer.fetchingListaCalendarioProgramacion,
        listaProgramacionForEquipo: state.RMDPReducer.listaProgramacionForEquipo,

        fetchingListaSubfamilias: state.CatalogosGeneralesReducer.fetchingListaSubfamilias,
        listaSubfamilias: state.CatalogosGeneralesReducer.listaSubfamilias,

        listaFamiliasDeActivos: state.CatalogosGeneralesReducer.listaFamiliasDeActivos,
        baseDataEquipo: state.RMDPReducer.baseDataEquipo,

        fetchingCrearEquipo: state.RMDPReducer.fetchingCrearEquipo,
        fetchingEditarEquipo: state.RMDPReducer.fetchingEditarEquipo,

        fetchingListaServicioByIdServicioCount: state.RMDPReducer.fetchingListaServicioByIdServicioCount,
        listaServiciosByIdServicioCount: state.RMDPReducer.listaServiciosByIdServicioCount,
        listaServiciosByIdServicioCountCantidad: state.RMDPReducer.listaServiciosByIdServicioCountCantidad,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditorDeTexto: () => {
            dispatch({type: 'MODAL_EDITOR_TEXTO', showModalEditorTexto: true, dataEditorDeTexto: null})
        },
        onCrearEquipo: (mes, anio, fileData, RMDPObject, idServicio, idPartida, etiqueta) => {
            dispatch({type: 'CREAR_EQUIPO_REQUEST', mes, anio, fileData, RMDPObject, idServicio, idPartida, etiqueta})
        },
        onEditarEquipo: (mes, anio, fileData, RMDPObject, idServicio, idPartida, etiqueta) => {
            dispatch({type: 'EDITAR_EQUIPO_REQUEST', mes, anio, fileData, RMDPObject, idServicio, idPartida, etiqueta})
        },
        onObtenerArchivoMesAnterior: (mes, anio, idServicio, idPartida) => {
            dispatch({type: 'OBTENER_ARCHIVO_MES_ANTERIOR_EQUIPO_REQUEST', mes, anio, idServicio, idPartida})
        },
        onGetListaGrupoSerForRMDP: () => {
            dispatch({ type: 'LISTA_GRUPOS_SERVICIOS_RDMP_REQUEST' });
        },
        onGetTareas: (idServicio, mes, anio, page) => {
            dispatch({ type: 'BUSCAR_TAREAS_RMDP_REQUEST', idServicio, mes, anio, page });
        },
        onGetServicios: () => {
            dispatch({ type: 'LISTA_SERVICIOS_FOR_EQUIPO_REQUEST' });
        },
        onGetActual: (mes, anio, nombreArchivo, idServicio, idPartida ) => {
            dispatch({ type: 'OBTENER_ARCHIVO_MES_ACTUAL_EQUIPO_REQUEST', mes, anio, nombreArchivo, idServicio, idPartida });
        },
        onCleanActual: () => {
            dispatch({ type: 'OBTENER_ARCHIVO_MES_ACTUAL_EQUIPO_SUCCESS', archivoMesActualEquipo: null });
        },
        onCalendarioProgramacion: (fechaInicio, fechaFin, idGrupoSer, tipoPrograma, idServicio) => {
            dispatch({ type: 'LISTA_PROGRAMADOS_FOR_EQUIPO_REQUEST', fechaInicio, fechaFin, idGrupoSer, tipoPrograma, idServicio });
        },

        onCalendarioDetalle: (tareaSeleccionada, listaFamiliasDeActivos, listaSubfamilias) => {
            dispatch({ type: 'CALENDARIO_DETALLE_REQUEST', tareaSeleccionada, listaFamiliasDeActivos, listaSubfamilias })
        },
        onShowModalCalendarioDetalle: (tareaSeleccionada, busqueda, listaFamiliasDeActivos, listaSubfamilias) => {
            dispatch({ type: 'SHOW_MODAL_CALENDARIO_DETALLE', showModalCalendarioDetalle: true, tareaSeleccionada, busqueda, rmdp: true })
        },
        onGetSubfamilias: () => {
            dispatch({ type: 'LISTA_SUBFAMILIAS_REQUEST' });
        },
        onGetFamiliasDeActivos: () => {
            dispatch({ type: "LISTA_FAMILIAS_ACTIVOS_REQUEST"});
        },
        onCleanAnterior: () => {
            dispatch({ type: 'OBTENER_ARCHIVO_MES_ANTERIOR_EQUIPO_SUCCESS', archivoMesAnteriorEquipo: null });
        },
        onObtenerActivosPorServicio: (idServicio, page, busqueda) => {
            dispatch({ type: "LISTA_SALIDA_BIENES_BUSQUEDA_REQUEST", idServicio, page, busqueda });
        },
        onGetActivosByIdServicio: (idServicio, page) => {
            dispatch({ type: 'LISTA_SERVICIOS_BYIDSERVICIO_COUNT_REQUEST', idServicio, page });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Equipo);
