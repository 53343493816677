import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, Row, Select, Switch, message, Checkbox } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
const { Option, OptGroup } = Select;
class ModalEditarAlmacen extends Component {
     formRef = React.createRef();
     state = {
          almacenSeleccionadoLocal: '',
          caracteristicaInventarioLocal: false,
          serviciosTodos: false
     }
     render() {
          
          const { 
               fetchingEditarAlmacen, 
               showModalEditarAlmacen, 
               onEditarAlmacen, 
               onShowModalEditarAlmacen,
               almacenSeleccionado, 
               limpiarFormulario,
               fetchingListaServiciosFiltro,
               listaServiciosFiltro,
               fetchingListaAreasFuncionalesActivos,
               listaAreasFuncionalesActivos,
               onListaServiciosFiltroLimpiar,
               listaServiciosActivosF
          } = this.props;

          console.log('almacenSeleccionado en modal: ', almacenSeleccionado);
          const onFinish = formulario => {
               console.log('Success:', formulario);
               onEditarAlmacen(formulario, almacenSeleccionado, this.props.page, this.props.busqueda, listaServiciosActivosF, this.state.serviciosTodos);
               onListaServiciosFiltroLimpiar();
          };

          if (limpiarFormulario && limpiarFormulario === 'editarAlmacen') {
               this.formRef.current.resetFields();
               this.props.onLimpiarFormulario();
          }

          const handleCancel = () => {
               this.setState({
                    almacenSeleccionadoLocal: '',
                    checked: true
                });
               onShowModalEditarAlmacen();
               onListaServiciosFiltroLimpiar();
          };

          const todosServicios = () => {
               this.setState({ checked: !this.state.checked });
               console.log('checked', this.state.checked);
          };

          const onChangeServicios = value => {
               this.setState({serviciosTodos: false});
               const todos = value.some(item => item === 'todos');
               if(todos) {
                    this.setState({ serviciosTodos: true });
                    this.formRef.current.setFieldsValue({'servicios':['todos']})
               }
          }

          const buscarServicios = value => {
               if(value.length>=3){
                    if(value){
                         this.props.onListaServiciosFiltro(value)
                    } else {
                         message.warning('No se encontró servicio con esas palabras')
                    }
               } else {
                    this.props.onListaServiciosFiltroLimpiar();
               } 
          }

          console.log("almacenSeleccionado",almacenSeleccionado)

          return (
               <Modal
               visible={showModalEditarAlmacen}
               title='Editar Almacen'
               onCancel={handleCancel}
               footer={false}
               >
                    {almacenSeleccionado ? ( 
                    <Spin spinning={fetchingEditarAlmacen}>
                         
                         <Form {...layout}
                         name="formulario"
                         initialValues={almacenSeleccionado}
                         onFinish={onFinish}
                         
                         ref={this.formRef}
                         >
                              <Row justify='space-around'>
                                   <Col span={18}>
                                        <Form.Item
                                        label="Nombre"
                                        name="nombreC"
                                        rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                        >
                                             <Input
                                                  pattern="^[0-9A-Za-zñáéíóúÑÁÉÍÓÚüÜ;\.:'\s\-,]+$"
                                                  title="Nombre sin carácteres especiales" 
                                                  maxLength={100} 
                                                  placeholder='Escribe el nombre corto'
                                             />
                                        </Form.Item>
                                   </Col>
                                   <Col span={4}>
                                        <Form.Item
                                        label='Disponible'
                                        name='caracteristica'
                                        valuePropName="checked">
                                             
                                             <Switch
                                                  checkedChildren={<CheckOutlined />}
                                                  unCheckedChildren={<CloseOutlined />}
                                             />                                            
                                        </Form.Item>
                                   </Col>
                              </Row>
                              
                              <Row type='flex' justify='space-around'>
                                   <Col span={24}>
                                        <Form.Item
                                        label="Descripción"
                                        name="nombreL"
                                        rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                        >
                                             <Input
                                                  pattern="^[0-9A-Za-zñáéíóúÑÁÉÍÓÚüÜ;\.:/()'\s\-,]+$"
                                                  title="Nombre sin carácteres especiales."
                                                  maxLength={250}
                                                  placeholder='Escribe la descripción'
                                                  //autoSize
                                             />
                                        </Form.Item>
                                   </Col>

                                   
                                   {/* <Col span={24}>
                                        <Form.Item
                                        label='Servicio'
                                        name='servicios'
                                        rules={[{ required: this.state.checked, message: 'Favor de llenar el campo', }]}
                                        
                                        >
                                             { this.state.checked ? (
                                             <Select
                                                  mode="multiple"
                                                  allowClear
                                                  style={{ width: '100%' }}
                                                  placeholder="Selecciona un servicio"
                                                  loading={fetchingListaServiciosFiltro}
                                                  showSearch
                                                  filterOption={false}
                                                  onSearch={buscarServicios}
                                                  notFoundContent={null}
                                             >
                                                  {listaServiciosFiltro.map(option => <Option key={option.idServicio + '-' + option.nombre}>{option.idServicio + '-' + option.nombre}</Option>)}
                                             </Select>
                                             ): false}
                                        </Form.Item>
                                   </Col>

                                   <Form.Item
                                   name='todosServicios'>
                                        <Checkbox onChange={todosServicios}>Todos</Checkbox>
                                   </Form.Item> */}
                                   
                                   <Col span={24}>
                                        <Form.Item
                                             label='Servicio'
                                             name='servicios'
                                             rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                             <Select
                                                  mode='multiple'
                                                  allowClear
                                                  style={{ width: '100%', textAlign: 'left' }}
                                                  placeholder="Ingresa el servicio a buscar"
                                                  loading={fetchingListaServiciosFiltro}
                                                  showSearch
                                                  filterOption={false}
                                                  onSearch={buscarServicios}
                                                  onChange={onChangeServicios}
                                                  notFoundContent={null}
                                             >
                                                  <OptGroup label="Todos los servicios">
                                                       <Option value="todos">Todos los Servicios</Option>
                                                  </OptGroup>
                                                  <OptGroup label="Servicios">
                                                       {listaServiciosFiltro.map(option => <Option disabled={this.state.serviciosTodos} key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>{option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}</Option>)}
                                                  </OptGroup>
                                             </Select>
                                        </Form.Item>
                                   </Col>

                                   <Col span={24}>
                                        <Form.Item
                                        label='Area Funcional'
                                        name="areaFuncional"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                             <Select 
                                                  allowClear
                                                  style={{ width: '100%' }}
                                                  placeholder="Selecciona el área funcional"
                                                  loading={fetchingListaAreasFuncionalesActivos}
                                             >
                                                  {listaAreasFuncionalesActivos.map(option => <Option key={option.idAreaFuncional}>{ option.codigoArea + ' - ' + option.areaFuncional }</Option>)}
                                             </Select>
                                        </Form.Item>   
                                   </Col>

                                   <Col span={12}>
                                        <Button style={{width:'100%'}} className="btnFiltrar" htmlType="submit">Editar Almacen</Button>
                                   </Col>
                              </Row>
                         </Form>
                         
                    </Spin>
                    ): false}
               </Modal>
          );
     }
}

const mapStateToProps = state => {
	return {
          fetchingEditarAlmacen: state.AlmacenesReducer.fetchingEditarAlmacen,
          showModalEditarAlmacen: state.AlmacenesReducer.showModalEditarAlmacen,
          almacenSeleccionado: state.AlmacenesReducer.almacenSeleccionado,
          page: state.AlmacenesReducer.page,
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
          listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
          fetchingListaAreasFuncionalesActivos: state.CatalogosGeneralesReducer.fetchingListaAreasFuncionalesActivos,
          listaAreasFuncionalesActivos: state.CatalogosGeneralesReducer.listaAreasFuncionalesActivos,
          busqueda: state.AlmacenesReducer.busqueda,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
	};
};

const mapDispatchToProps = dispatch => {
	return {
          onShowModalEditarAlmacen: () => {
               dispatch({ type: 'SHOW_MODAL_EDITAR_ALMACEN', showModalEditarAlmacen: false, almacenSeleccionado: null})
          },
          onEditarAlmacen: ( formulario, almacenSeleccionado, page, busqueda, listaServiciosActivosF, checked ) => {
               dispatch({ type: "EDITAR_ALMACEN_REQUEST" , formulario: formulario, almacenSeleccionado: almacenSeleccionado, page: page, busqueda: busqueda, listaServiciosActivosF, checked });
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
          onListaServiciosFiltro: (busqueda) => {
               dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
          },
          onListaServiciosFiltroLimpiar: () => {
               dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
          },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarAlmacen);