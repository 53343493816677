import React, { Component } from 'react';
import { Row, Col, Button, Form, Input, Spin, Modal, Table, message, Divider, Tooltip, DatePicker, Descriptions, Typography } from 'antd';
import { connect } from "react-redux";
import { EditOutlined, DeleteOutlined, AppstoreAddOutlined, SaveOutlined } from '@ant-design/icons';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalModificarRegistroEditarFolio from './ModalModificarRegistroEditarFolio';
import moment from 'moment';
const confirm = Modal.confirm;
const layout = { labelCol: { span: 24 },   wrapperCol: { span: 24 } };
const {Text} = Typography;

class EditarFolio extends Component {
    state = {
        page: 1,
        busqueda: {order: 'ASC', field: 'folio'},
        cancelado: false,
    }
    componentDidMount(){ this.props.onRequestInfoProyecto(); }
    render() { 
        const {fetchingEditarFolio, listaDetalle, onCambiarVista,onModalEditarSuma,
            onModalEditar, listaDetalleCantidad, onCancelarProducto, almacenOrigen, almacenDestino,fetchingCancelarFolio,
            onCancelarFolio, onShowModalEditarFolio, onShowModalOpcionesMovimientosAlmacen, listaInfoProyecto} = this.props;
           
        const editarFolio = formulario => {
            console.log('formulario', formulario) 

            let fechaMovmientoInicial = formulario.fechaMovimiento._d.toLocaleDateString();
            let dd = Number(fechaMovmientoInicial.split('/')[0]) <= 9 ? '0' + fechaMovmientoInicial.split('/')[0] : fechaMovmientoInicial.split('/')[0];
            let mm = Number(fechaMovmientoInicial.split('/')[1]) <= 9 ? '0' + fechaMovmientoInicial.split('/')[1] : fechaMovmientoInicial.split('/')[1];
            let yyyy = fechaMovmientoInicial.split('/')[2];
            formulario.fechaMovimientoEditar = yyyy + '-' + mm + '-' + dd;
            this.props.onEditarFolio(formulario, listaDetalle);
        }      

        const agregarProductos = () => {
            let folio = listaDetalle[0].folio;
            let op = listaDetalle[0].tiposFoliosByIdTipoFolio.operacion;  
            let requiereFolio = listaDetalle[0].tiposFoliosByIdTipoFolio.folioOrigen;
            let idUbicacionProductoOrigen = listaDetalle[0].idUbicacionProductoOrigen;
            let idUbicacionProductoDestino = listaDetalle[0].idUbicacionProductoDestino;
            let folioOrigen = listaDetalle[0].folioOrigen;
            console.log('lista detalle operacion', op);
            console.log('lista detalle requiere folio origen',requiereFolio);
            console.log('folio origen lista detalle', folioOrigen)
            console.log('idUbicacionProductoOrigen:', idUbicacionProductoOrigen);
            console.log('idUbicacionProductoDestino:', idUbicacionProductoDestino);
            console.log('folio', folio);
            
            if(op === "Suma"){
                if(requiereFolio === true){
                    console.log('entro a requisicion');
                    this.props.onGetRequisicionFiltrado(1, folioOrigen,'','','', folio);
                } else {
                    console.log('entro a lista de productos')
                    this.props.onGetProductosFiltrado(1,'','', folio);
                } 
            } else if(op === "Ambas"){
                console.log(' entro a lista de inventario')
                for(let i=0;i<almacenOrigen.length;i++){
                    let idAlmOrigen = almacenOrigen[0].idAlmacen
                    console.log('idAlmOrigen', idAlmOrigen)

                    if(idAlmOrigen === almacenOrigen[i].idAlmacen ){
                        //this.props.onGetUbicacionOrigen(1, idAlmOrigen);
                        this.props.onGetInventarioFiltrado(1, idAlmOrigen, folio)
                    } 
                }        
            } else if(op === "Resta") {
                if(requiereFolio === true){
                    console.log('entro a resta productos con folio');
                    this.props.onGetRequisicionFiltrado(1, folioOrigen,'','','', folio);
                } else {
                    if(listaDetalle[0].nombreTipo === 'Salida por instalación'){
                        console.log('almacenOrigen', almacenOrigen)
                        this.props.onGetSubfamilias();
                        this.props.onGetInventarioTipoActivos(1, almacenOrigen[0].idAlmacen, true);
                        return this.props.onCambiarVista(2, true); 
                    }
                    for(let i=0;i<almacenOrigen.length;i++){
                        let idAlmOrigen = almacenOrigen[0].idAlmacen
                        console.log('idAlmOrigen', idAlmOrigen)
    
                        if(idAlmOrigen === almacenOrigen[i].idAlmacen ){
                            //this.props.onGetUbicacionOrigen(1, idAlmOrigen);
                            this.props.onGetInventarioFiltrado(1, idAlmOrigen, folio)
                        } 
                    }
                }  
            }
            onCambiarVista(2);
            this.props.onActualizarTabla();
        };


        const onEditar = (key) => {
            console.log('key Editar', key);
            if(key.tiposFoliosByIdTipoFolio.operacion === "Suma"){
                onModalEditarSuma(key, this.state.page);
            } else {
                onModalEditar(key, this.state.page);
            }
        };

        
        const onEliminar = (key) => {
            console.log('key', key)
            let page = this.state.page
            let busqueda = this.state.busqueda
            let cancelado = false;
            if(listaDetalle.length === 1){
                confirm({
                    title: 'El folio debe contener minimo un producto, ¿deseas cancelar folio "' + key.folio + '"?',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                        
                        if(!cancelado){
                            cancelado = true;
                            onCancelarFolio(key.folio, page, busqueda);
                            return onShowModalOpcionesMovimientosAlmacen();
                            //return true;
                        } else {
                            message.warning('Este folio ya fue cancelado')
                            return false;
                        }
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            } else {
                confirm({
                    title: '¿Estás seguro que deseas eliminar el producto "' + key.nombre + '"?',
                    content: '',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                        onCancelarProducto(key.idMovimientoAlmacen_serieNum, key.folio, page);
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            }
        };
        const handleTableChange = (pagination) => {
            this.setState({ page: pagination.current });
        };
        const columns = [{
            title: 'Código de almacén',
            dataIndex: 'codigoAdicional',
            key: 'codigoAdicional',
            align: 'center',
        },{
            title: 'Nombre del producto',
            dataIndex: 'nombre',
            key: 'nombre',
            align: 'center',
        },{
            title: 'Clave del producto',
            dataIndex: 'clave',
            key: 'clave',
            align: 'center',
        },{
        //     title: 'Número de serie',
        //     dataIndex: 'serieNum',
        //     key: 'serieNum',
        //     align: 'center',
        //     render: (text, record) => (
        //         record.serieNum ? text : 'No aplica'
        //     ),
        // },{
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
            align: 'center',
        },{
            title: 'Costo unitario',
            dataIndex: 'precioCostoTabla',
            key: 'precioCostoTabla',
            align: 'right'
        },{
            title: 'Cantidad',
            dataIndex: 'cantidadTotal',
            key: 'cantidadTotal',
            align: 'right'
        },{
            title: 'Lote',
            dataIndex: 'lote',
            key: 'right',
        },{
            title: 'Fecha de caducidad',
            dataIndex: 'fechaCaducidad',
            key: 'fechaCaducidad',
        },{
            title: 'Editar ',
            key: 'editar',
            align: 'center',
            render: (text, record) => (
                <a className='editarIcono' disabled={record.idMovimientoAlmacen_serieNum ? record.idMovimientoAlmacen_serieNum[0].etiqueta ? true: false : true} onClick={() => onEditar(record)}><EditOutlined /></a>
            ),
        },{
            title: ' Eliminar ',
            key: 'eliminar',
            align: 'center',
            render: (text, record) => (
                <a className='editarIcono'  onClick={() => onEliminar(record)}><DeleteOutlined /></a>
            ),
        }];

        const  disabledDate = (fecha) => {
            if( fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicioOperacion) {
                return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicioOperacion;
            } else {
                if(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos){
                return fecha && moment(fecha.format('YYYY-MM-DD')) > moment((listaInfoProyecto.vigencia +  parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2])) + '-' +listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[1] + '-' + listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[0]);
            }
            }
        }

        return ( 
            <Spin spinning={fetchingEditarFolio || this.props.fetchingCancelarProducto || fetchingCancelarFolio}>
                {listaDetalle.length > 0 ? (
                <Form {...layout}
                    name="formulario"
                    onFinish={editarFolio}
                    initialValues={listaDetalle[0]}
                    ref={this.formRef}
                >
                    <Row justify="space-between" gutter={[8,8]}>
                        <Col span={22}>
                            <Divider orientation="left">Folio Seleccionado</Divider>
                        </Col>
                        <Col span={1} style={{ paddingTop:'1em' }}>
                            <Tooltip title='Guardar Edición'>
                                <Button className="iconTer" shape="circle" htmlType="submit" icon={<SaveOutlined />}/>
                            </Tooltip>
                        </Col>
                        <Col span={24}>
                            <Descriptions layout="horizontal" bordered={true}>
                                <Descriptions.Item label='Numero de Folio'> <Text type="warning" strong> {listaDetalle[0].folio} </Text> </Descriptions.Item>
                                <Descriptions.Item label='Tipo de folio'>{listaDetalle[0].nombreTipo}</Descriptions.Item>
                                { listaDetalle[0].folioOrigen && <Descriptions.Item label='Folio Origen'>{listaDetalle[0].folioOrigen}</Descriptions.Item> }
                            </Descriptions>
                        </Col>
                        {/* <Col span={10}>
                            <Form.Item
                                label="Número de Folio"
                                name="folio"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item
                                label="Tipo de Folio"
                                name="nombreTipo"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        {listaDetalle[0].folioOrigen ? (
                        <Col span={10}>
                            <Form.Item
                                label="Folio Origen"
                                name="folioOrigen"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        ): false} */}
                         
                        <Col span={11}>
                            <Form.Item
                                label="Documento Origen"
                                name="documentoOrigen"
                            >
                                <Input placeholder="Ingresar documento origen"/>
                            </Form.Item>
                        </Col>

                        <Col span={11}>
                            <Form.Item
                                label="Fecha Real del Registro"
                                name="fechaMovimiento"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    format='DD-MM-YYYY'
                                    disabledDate={disabledDate}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={22}>
                            <Divider orientation="left">Productos</Divider>
                        </Col>
                        <Col span={1} style={{ paddingTop:'1em' }}>
                            <Tooltip title='Agregar Producto'>
                                <Button className="iconTer" shape="circle" onClick={agregarProductos} icon={<AppstoreAddOutlined />}/>
                            </Tooltip>
                        </Col>
                          
                        <Col span={24}>
                            <br />
                            <Table
                                size="small"
                                rowKey="sku"
                                columns={columns}
                                dataSource={listaDetalle}
                                locale={{ emptyText: 'Sin datos' }}
                                onChange={handleTableChange}
                                pagination={{ total: listaDetalleCantidad, simple: true, current: this.state.page }}
                                scroll={{"x": 1000 | true, "y": 1000}}
                            />
                            <br />
                        </Col>
                    </Row>
                </Form>
                ): false}
                <ModalModificarRegistroEditarFolio/>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
    return {
        fetchingEditarFolio: state.AlmacenesReducer.fetchingEditarFolio,
        listaDetalle: state.AlmacenesReducer.listaDetalle,
        listaDetalleCantidad: state.AlmacenesReducer.listaDetalleCantidad,
        vistaModalEditarFolio: state.AlmacenesReducer.vistaModalEditarFolio,
        listaSeleccionadosEditar: state.AlmacenesReducer.listaSeleccionadosEditar,
        formularioEditarFolio: state.AlmacenesReducer.formularioEditarFolio,
        almacenOrigen: state.AlmacenesReducer.almacenOrigen,
        almacenDestino: state.AlmacenesReducer.almacenDestino,
        fetchingCancelarFolio: state.AlmacenesReducer.fetchingCancelarFolio,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        fetchingCancelarProducto: state.AlmacenesReducer.fetchingCancelarProducto,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onCambiarVista: (vistaModalEditarFolio, tipoActivos) => {
            dispatch({ type: "CAMBIAR_VISTA_MODAL_EDITARFOLIO", vistaModalEditarFolio, tipoActivos});
        },
        onModalEditar: (registroProductoSeleccionado, page) => {
            dispatch({ type: "OBTENER_INFO_IDINVENTARIO_REQUEST", showModalModificarEditarFolio: true, registroProductoSeleccionado, page})
        },
        onModalEditarSuma: ( registroProductoSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_MODIFICAR_EDITARFOLIO" , showModalModificarEditarFolio: true, registroProductoSeleccionado, page});
        },
        onCancelarProducto: (idsMovimientoAlmacen, folio, page, listaDetalle) => {
            dispatch({ type: "CANCELAR_PRODUCTO_REQUEST", idsMovimientoAlmacen, folio, page: page, listaDetalle})
        },
        onGetProductosFiltrado: (page, busqueda, idSubFamilia, folio) => {
            dispatch({ type: "LISTA_PRODUCTOS_FILTRADO_REQUEST", page: page, busqueda: busqueda, idSubFamilia:idSubFamilia, folio:folio });
        }, 
        onGetRequisicionFiltrado: (page, folioOrigen, busqueda, idFamilia, idSubFamilia, folio) => {
            dispatch({ type: "LISTA_REQUISICION_FILTRADO_REQUEST", page: page, folioOrigen: folioOrigen, busqueda: busqueda, idFamilia: idFamilia, idSubFamilia: idSubFamilia, folio: folio});
        },
        onEditarFolio: (formulario, listaDetalle) => {
            dispatch({ type: "EDITAR_FOLIO_REQUEST", formulario: formulario, listaDetalle: listaDetalle})
        },
        onGetUbicacionOrigen: (page, idAlmacen) => {
            dispatch({ type: "OBTENER_UBICACION_POR_ALMACEN_REQUEST", page, idAlmacen})
        },
        onGetUbicacionDestino: (page, idAlmacen) => {
            dispatch({ type: "UBICACION_DESTINO_POR_ALMACEN_REQUEST", page, idAlmacen})
        },
        onCancelarFolio: (folio, page, busqueda) => {
            dispatch({ type: "CANCELAR_FOLIO_REQUEST", folio, page, busqueda});
        },
        onShowModalEditarFolio: () => {
            dispatch({ type: "SHOW_MODAL_EDITAR_FOLIO", showModalEditarFolio: false})
        },
        onShowModalOpcionesMovimientosAlmacen: () => {
            dispatch({ type: "SHOW_MODAL_OPCIONES_MOVIMIENTOS_ALMACEN", showModalOpcionesMovimientosAlmacen: false, movimientoSeleccionado: null})
        },
        onGetInventarioFiltrado: (page, idAlmacenOrigen, folio) => {
            dispatch({ type: "LISTA_INVENTARIO_FILTRADO_REQUEST", page: page, idAlmacenOrigen: idAlmacenOrigen, folio: folio})
        },
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        },
        onGetSubfamilias: () => {
            dispatch({ type: 'LISTA_SUBFAMILIAS_REQUEST' });
        },
        onGetInventarioTipoActivos: (page, idAlmacenOrigen) => {
            dispatch({ type: 'LISTA_INVENTARIO_TIPO_ACTIVOS_REQUEST', page, idAlmacenOrigen })
        },
        onActualizarTabla: () => {
            dispatch({ type: 'ACTUALIZAR_TABLA_MOVIMIENTOS', hasSelected: true })
        },
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(EditarFolio);