import { takeLatest, call, put } from "redux-saga/effects";
import * as informacionDelProyectoActions from '../actions/InformacionDelProyectoActions';
import * as configuracionYSeguridadActions from '../actions/ConfiguracionYSeguridadActions';
import * as mecanismosDePagosActions from '../actions/MecanismosDePagosActions';
import {obtenerInfoProyectoCall, editarInfoProyectoCall, crearInfoProyectoCall} from '../../services/InformacionDelProyectoServices';
import {listaContratosCall} from '../../services/InformacionOperativaServices';

import moment from 'moment';
function* infoProyecto(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let listaInfoProyecto = [];
        
        yield put({ type: informacionDelProyectoActions.GET_INFOPROYECTO_SUCCESS, listaInfoProyecto });
        const response2 = yield call(listaContratosCall, token);
        console.log(response2);
        if (response2.status === 200) {
            let listaContratos = response2.data;
            const response = yield call(obtenerInfoProyectoCall, token);
            console.log(response);
            
            if (response.status == 200) {
                listaInfoProyecto = response.data[0];
                let fechaInicio = listaInfoProyecto.fechaInicio.split('T')[0];
                let fechaFin = (parseInt(fechaInicio.split('-')[0]) + listaInfoProyecto.vigencia) + '-' + fechaInicio.split('-')[1] + '-' + fechaInicio.split('-')[2];
                let fechaVigencia = listaInfoProyecto.fechaVigencia.split('T')[0];
                let fechaAlta = listaInfoProyecto.fechaAlta.split('T')[0];
                let fechaInicioOperacion = listaInfoProyecto.fechaInicioOperacion.split('T')[0];
                // fechas para el detalle de mecanismos de pago CU valores de pago anual
                let fechaInicioOperacionMecanismos = listaInfoProyecto.fechaInicioOperacion.split('T')[0].split('-').reverse().join('-');
                let fechaInicioMecanismos = listaInfoProyecto.fechaInicio.split('T')[0].split('-').reverse().join('-');
                // 
                fechaInicioOperacion = moment(fechaInicioOperacion, 'YYYY-MM-DD')
                fechaInicio = moment(fechaInicio, 'YYYY-MM-DD');
                fechaFin = moment(fechaFin, 'YYYY-MM-DD');
                fechaVigencia = fechaVigencia.split('-')[2] + '-' + fechaVigencia.split('-')[1] + '-' + fechaVigencia.split('-')[0];
                fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
                listaInfoProyecto.fechaInicio = fechaInicio;
                listaInfoProyecto.fechaFin = fechaFin;
                listaInfoProyecto.fechaVigencia = fechaVigencia;
                listaInfoProyecto.fechaAlta = fechaAlta;
                listaInfoProyecto.fechaInicioOperacion = fechaInicioOperacion;
                listaInfoProyecto.fechaInicioOperacionDetalleMecanismos = fechaInicioOperacionMecanismos;
                listaInfoProyecto.fechaInicioDetalleMecanismos = fechaInicioMecanismos;
                if(listaInfoProyecto.expedienteByIdExpediente.clave){
                    listaInfoProyecto.claveExpediente = listaInfoProyecto.expedienteByIdExpediente.clave;
                } else {
                    listaInfoProyecto.claveExpediente = 'No tiene expediente';
                }
                if (listaInfoProyecto.domicilio.split(',').length === 7) {
                    listaInfoProyecto.calle = listaInfoProyecto.domicilio.split(',')[0]
                    listaInfoProyecto.numeroExterior = listaInfoProyecto.domicilio.split(',')[1]
                    listaInfoProyecto.numeroInterior = listaInfoProyecto.domicilio.split(',')[2]
                    listaInfoProyecto.nombreColonia = listaInfoProyecto.domicilio.split(',')[3]
                    listaInfoProyecto.cp = listaInfoProyecto.domicilio.split(',')[4]
                    listaInfoProyecto.municipio = listaInfoProyecto.domicilio.split(',')[5]
                    listaInfoProyecto.estado = listaInfoProyecto.domicilio.split(',')[6]
                } else {
                    listaInfoProyecto.calle = listaInfoProyecto.domicilio.split(',')[0]
                    listaInfoProyecto.numeroExterior = listaInfoProyecto.domicilio.split(',')[1]
                    listaInfoProyecto.numeroInterior = '';
                    listaInfoProyecto.nombreColonia = listaInfoProyecto.domicilio.split(',')[2]
                    listaInfoProyecto.cp = listaInfoProyecto.domicilio.split(',')[3]
                    listaInfoProyecto.municipio = listaInfoProyecto.domicilio.split(',')[4]
                    listaInfoProyecto.estado = listaInfoProyecto.domicilio.split(',')[5]
                }
                if(listaInfoProyecto.contratoByIdContrato){
                    let fechaInicio2 = listaInfoProyecto.contratoByIdContrato.fechaInicio.split('T')[0];
                    listaInfoProyecto.contratoByIdContrato.fechaInicio = fechaInicio2.split('-').reverse().join('-');
                    let fechaFin2 = listaInfoProyecto.contratoByIdContrato.fechaFin.split('T')[0];
                    listaInfoProyecto.contratoByIdContrato.fechaFin = fechaFin2.split('-').reverse().join('-');
                    listaInfoProyecto.contratoByIdContrato.rangoVigencia = [moment(fechaInicio2, 'YYYY-MM-DD'),moment(fechaFin2, 'YYYY-MM-DD')];
                }
                
                for(let i=0; i<listaContratos.length; i++){
                    if(listaContratos[i].idContrato === listaInfoProyecto.idContrato) {
                        listaInfoProyecto.claveContrato = listaContratos[i].clave
                    }
                }
                
                if(action.añoMesCalendario){
                    yield put({ type: mecanismosDePagosActions.LISTA_AÑO_CALENDARIO_REQUEST, listaInfoProyecto });
                }
                yield put({ type: informacionDelProyectoActions.GET_INFOPROYECTO_SUCCESS, listaInfoProyecto });
            }
            else {
                yield put({ type: informacionDelProyectoActions.GET_INFOPROYECTO_FAILURE });
            }
        }
        
    } catch (error) {
        yield put({ type: informacionDelProyectoActions.GET_INFOPROYECTO_FAILURE });
    }
}
export function* infoProyectoSaga(){
    yield takeLatest(informacionDelProyectoActions.GET_INFOPROYECTO_REQUEST, infoProyecto)
}
function* editarInfoProyecto(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token')
        const idProyecto = action.listaInfoProyecto.idProyecto;
        let data = '';

        if(action.idContrato) {
            let contrato = action.idContrato;
            data = {
                "idProyecto": idProyecto,
                "rfc": action.listaInfoProyecto.rfc,
                "razonSocial": action.listaInfoProyecto.razonSocial,
                "nombreCliente": action.listaInfoProyecto.nombreCliente,
                "centroNegocios": action.listaInfoProyecto.centroNegocios,
                "domicilio": action.listaInfoProyecto.domicilio,
                "fechaInicio": action.listaInfoProyecto.fechaInicio.format('YYYY-MM-DD'),
                "vigencia": action.listaInfoProyecto.vigencia,
                "pagoAnualInversion": action.listaInfoProyecto.pagoAnualInversion,
                "pagoAnualReferencia": action.listaInfoProyecto.pagoAnualReferencia,
                "pagoReferenciaVar": action.listaInfoProyecto.pagoReferenciaVar,
                "poblacionPtvMin": action.listaInfoProyecto.poblacionPtvMin,
                "poblacionPtvMax": action.listaInfoProyecto.poblacionPtvMax,
                "logo": action.listaInfoProyecto.logo,
                'rutaImagenCentro': action.listaInfoProyecto.rutaImagenCentro,
                "fechaVigencia": action.listaInfoProyecto.fechaVigencia.split('-').reverse().join('-'),
                "fechaAlta": action.listaInfoProyecto.fechaAlta.split('-').reverse().join('-'),
                "fechaActualizacion": null,
                "fechaInicioOperacion": action.listaInfoProyecto.fechaInicioOperacion.format('YYYY-MM-DD'),
                "idContrato": parseInt(contrato),
                "idExpediente": parseInt(action.listaInfoProyecto.idExpediente)
            } 

        } else {
            let contrato = action.formulario.claveContrato;
            if(isNaN(contrato)){
                contrato = action.listaInfoProyecto.idContrato
            }
            let domicilio = action.formulario.calle + ',' + action.formulario.numeroExterior + ',' +
            action.formulario.numeroInterior + ',' + action.formulario.nombreColonia + ',' +
            action.formulario.cp + ',' + action.formulario.municipio + ',' + action.formulario.estado;

            let fechaInicioOperacion = action.formulario.fechaInicioOperacion.format('YYYY-MM-DD');
            let fechaInicio = action.formulario.fechaInicio.format('YYYY-MM-DD')
            console.log('fechaInicio')
            console.log(fechaInicio)
            let  fechaVigencia = (parseInt(fechaInicio.split('-')[0]) + action.formulario.vigencia) + '-' + fechaInicio.split('-')[1] + '-' + fechaInicio.split('-')[2]
            let fechaAlta = action.listaInfoProyecto.fechaAlta.split('-')[2] + '-' + action.listaInfoProyecto.fechaAlta.split('-')[1] + '-' + action.listaInfoProyecto.fechaAlta.split('-')[0];
            data = {
                "idProyecto": idProyecto,
                "rfc": action.formulario.rfc,
                "razonSocial": action.formulario.razonSocial,
                "nombreCliente": action.formulario.nombreCliente,
                "centroNegocios": action.formulario.centroNegocios,
                "domicilio": domicilio,
                "fechaInicio": fechaInicio,
                "vigencia": action.formulario.vigencia,
                "pagoAnualInversion": action.formulario.pagoAnualInversion,
                "pagoAnualReferencia": action.formulario.pagoAnualReferencia,
                "pagoReferenciaVar": action.formulario.pagoReferenciaVar,
                "poblacionPtvMin": action.formulario.poblacionPtvMin,
                "poblacionPtvMax": action.formulario.poblacionPtvMax,
                "logo": action.listaInfoProyecto.logo,
                'rutaImagenCentro': action.listaInfoProyecto.rutaImagenCentro,
                "fechaVigencia": fechaVigencia,
                "fechaAlta": fechaAlta,
                "fechaActualizacion": null,
                "fechaInicioOperacion": fechaInicioOperacion,
                "idContrato": action.listaInfoProyecto.idContrato,
                "idExpediente": action.listaInfoProyecto.idExpediente
            } 
        }
    
        console.log('data');
        console.log(data);

        const formData = new FormData();
        if(action.formulario){
            if(action.formulario.imagen){
                formData.append('file', action.formulario.imagen.file.originFileObj);
            }
            if (action.formulario.plano) {
                formData.append('image', action.formulario.plano.file.originFileObj);
            }
        }
        
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
 
        console.log('formData');
        console.log(formData);
    
        const response = yield call(editarInfoProyectoCall, token, formData, idProyecto)
        console.log(response);
        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "La informacion del proyecto se modificó de forma correcta";
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: informacionDelProyectoActions.EDITAR_INFOPROYECTO_SUCCESS });
            yield put({ type: informacionDelProyectoActions.GET_INFOPROYECTO_REQUEST });
        } else {
            console.log('elseeee');
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: informacionDelProyectoActions.EDITAR_INFOPROYECTO_FAILURE })
        }
    } catch (error) {
        console.log(error);
        yield put({ type: informacionDelProyectoActions.EDITAR_INFOPROYECTO_FAILURE });
    }
}
export function* editarInfoProyectoSaga() {
    yield takeLatest(informacionDelProyectoActions.EDITAR_INFOPROYECTO_REQUEST, editarInfoProyecto);
}

function* crearInfoProyecto(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token')
        let numeroInterior = '';
        if(action.formulario.numeroInterior){
            numeroInterior = action.formulario.numeroInterior;
        }
        let domicilio = action.formulario.calle + ',' + action.formulario.numeroExterior + ',' +
            numeroInterior + ',' + action.formulario.nombreColonia + ',' +
            action.formulario.cp + ',' + action.formulario.municipio + ',' + action.formulario.estado;

        const  fechaVigencia = (parseInt(action.formulario.fechaInicio.split('-')[2]) + action.formulario.vigencia) + '-' + action.formulario.fechaInicio.split('-')[1] + '-' + action.formulario.fechaInicio.split('-')[0]

        const data = {
            "idProyecto": 1,
            "rfc": action.formulario.rfc,
            "razonSocial": action.formulario.razonSocial,
            "nombreCliente": action.formulario.nombreCliente,
            "centroNegocios": action.formulario.centroNegocios,
            "domicilio": domicilio,
            "fechaInicio": action.formulario.fechaInicio.split('-').reverse().join('-') + 'T00:00:00',
            "vigencia": action.formulario.vigencia,
            "pagoAnualInversion": action.formulario.pagoAnualInversion,
            "pagoAnualReferencia": action.formulario.pagoAnualReferencia,
            "pagoReferenciaVar": action.formulario.pagoReferenciaVar,
            "poblacionPtvMin": action.formulario.poblacionPtvMin,
            "poblacionPtvMax": action.formulario.poblacionPtvMax,
            "logo": '',
            'rutaImagenCentro': '',
            "fechaVigencia": fechaVigencia + 'T23:59:59',
            "fechaAlta": null,
            "fechaActualizacion": null,
            "fechaInicioOperacion": action.formulario.fechaInicioOperacion.split('-').reverse().join('-') + 'T00:00:00',
            "idContrato": null,
            "idExpediente": 1
        }
        console.log('data')
        console.log(data)

        const formData = new FormData();
        if (action.formulario.imagen) {
            formData.append('file', action.formulario.imagen.file.originFileObj);
        }
        if (action.formulario.plano) {
            formData.append('image', action.formulario.plano.file.originFileObj);
        }
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));
        console.log('formData');
        console.log(formData);
        const response = yield call(crearInfoProyectoCall, token, formData);
        console.log(response);

        if (response.status === 200) {
            
            const showSuccessMsg = true;
            const textMessage = "El proyecto se creó de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: informacionDelProyectoActions.NUEVO_INFOPROYECTO_SUCCESS })
            yield put({ type: informacionDelProyectoActions.GET_INFOPROYECTO_REQUEST });
           
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: informacionDelProyectoActions.NUEVO_INFOPROYECTO_FAILURE })
        }
    } catch (error) {
        yield put({ type: informacionDelProyectoActions.NUEVO_INFOPROYECTO_FAILURE })
    }
}

export function* crearInfoProyectoSaga(){
    yield takeLatest(informacionDelProyectoActions.NUEVO_INFOPROYECTO_REQUEST, crearInfoProyecto);
}