import React, {Component} from 'react'
import { Modal } from 'antd';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

import EditarPonderacionServArea from './EditarPonderacionServArea'
import VerificarContraseña from './VerificarContraseña'
import VerificarPonderador from './VerificarPonderador'
import ArchivoPonderacionServArea from './ArchivoPonderacionServArea'

class ModalEditarPonderacionServArea extends Component {
    state = {
        password: '',
    }
    formRef = React.createRef();
    render() { 
        const {vistaModalPonderacionServPorArea,
            showModalEditarPonderacionServPorArea,
            onCambiarVista,
            onLimpiarFormulario,
            onShowModalEditarPonderacionServArea,
            onListaServiciosFiltroLimpiar,
        } = this.props;

        const handleCancel = () => {
            onShowModalEditarPonderacionServArea()
            onListaServiciosFiltroLimpiar();
            onLimpiarFormulario('')
            onCambiarVista(1);
        }

        function ContenidoEditar() {
            if(vistaModalPonderacionServPorArea === 1){
                return(
                     <EditarPonderacionServArea/>
                );
            }
            else if(vistaModalPonderacionServPorArea === 2){
                return(
                    <VerificarContraseña/>
                );
            }
            else if(vistaModalPonderacionServPorArea === 3){
                return(
                    <VerificarPonderador/>
                );
            }
            else if(vistaModalPonderacionServPorArea === 4){
                return(
                    <ArchivoPonderacionServArea/>
                );
            }
        };


        return ( 
            <Modal
            visible={showModalEditarPonderacionServPorArea}
            title='Editar Ponderador'
            onCancel={handleCancel}
            footer={false}
            >
                <ContenidoEditar/>
            </Modal>
         );
    }
}
const mapStateToProps = state => {
	return {
        showModalEditarPonderacionServPorArea: state.MecanismosDePagosReducer.showModalEditarPonderacionServPorArea,
        vistaModalPonderacionServPorArea: state.MecanismosDePagosReducer.vistaModalPonderacionServPorArea,
        formularioPonderacionServ: state.MecanismosDePagosReducer.formularioPonderacionServ
	};
};
const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarPonderacionServArea: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_PONDERACION_SERV_POR_AREA', showModalEditarPonderacionServPorArea: false, page: null })
        },
        onEditarPonderacionServArea: ( formulario, page ) => {
            dispatch({ type: "EDITAR_PONDERACION_SERV_POR_AREA_REQUEST" , formulario: formulario, page: page });
        },
        onLimpiarFormulario: (limpiarFormulario) => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: limpiarFormulario });
        },
        onCambiarVista: (vistaModalPonderacionServPorArea) => {
            dispatch({ type: 'CAMBIAR_VISTA_PONDERACION_SERV_POR_AREA', vistaModalPonderacionServPorArea: vistaModalPonderacionServPorArea, formularioPonderacionServ: [], ponderacionEditados: []})
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
        }
	};
};
 
export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarPonderacionServArea) 