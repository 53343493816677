import React from 'react';
import { Card, Select, Tooltip, Row, Col, Button, Radio, Typography, List, Divider } from 'antd';
import { connect } from "react-redux";
import {RightCircleOutlined, LeftCircleOutlined} from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalSolicitudesServicio from './ModalSolicitudesServicio';
import imagenNoResult from '../../../assets/no-result-icon.png';
import moment from 'moment';
const {Option} = Select;
const { Text } = Typography;
const { Meta } = Card;
class TableroDeOperacionesContainer extends React.Component {
     mount=0;
     componentDidMount(){
          this.mounted = true;
        
          try {
			this.mount = setInterval(async () => {
                    console.log('Entró al seInterval de 5 min');
                    this.props.onGetServiciosPorGrupo(1, this.state.filtro);
               }, 60000);
		}
		catch(e) {
			console.log('Error del intervalo' + e);
          }
          this.props.onGetServiciosPorGrupo(1, this.state.filtro);
          this.props.onGetGruposServicios();
          this.props.onGetCantidadFoliosPorIdGrupoServicio('Abiertos');
     }

     state = {
          page: 1,
          filtro: {
               busqueda: '',
               estadoFolios: 'SinCerrar'
          },
          
          grupoServicio: '',
          colDer: 19,  colIzq: 5, maximizar: false,
     }
     addDefaultSrc(ev){
          ev.target.src = imagenNoResult;
      }
 
     render() {
          const {
               listaGruposServicios,
               fetchingListaGruposServicios,
               onGetServiciosPorGrupo,
               servicioPorGrupo,
               servicioPorGrupoCantidad,
               fetchingServicioPorGrupo,
               onLimpiarServicioPorGrupo,
               onGetSolicitudesDeServicioPorServicio,
               onShowModalSolicitudesServicio,
               onGetCantidadFoliosPorIdGrupoServicio,
               cantidadFoliosPorIdGrupoServ,
          } = this.props;

          const scroll = {"x": 2300 | true, "y": 1000};

          const {page, estadoFolios, busqueda} = this.state;

          const valoresTableroAbiertos = { 
               Verde_24: '#58D68D', 
               Amarillo_48: '#F7DC6F', 
               Rojo_72: '#C0392B', 
               Negro_72: '#17202A', 
          };

          const valoresTableroCerrados = {
               Verde: '#58D68D',
               Rojo: '#C0392B', 
          }

          const valoresTableroColor = {
               Negro_72: '#FDFEFE',
               Rojo_72: '#EAEDED', 
               Rojo: '#EAEDED',
               Amarillo_48: '#17202A',
          }
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
             permisosUsuario = JSON.parse(permisosUsuario);
          }

          const onChangeGrupo = (value) => {
               const filtro = {
                    busqueda: value,
                    estadoFolios: this.state.filtro.estadoFolios,
               }
               this.setState({
                    filtro
               });
               onGetServiciosPorGrupo(page, filtro);
               onGetCantidadFoliosPorIdGrupoServicio(estadoFolios, value)
          };

          const onChangeEstadoSS = (e) => {
               const filtro = {
                    busqueda: this.state.filtro.busqueda,
                    estadoFolios: e.target.value,
               }
               this.setState({
                    filtro
               });
               onGetServiciosPorGrupo(page, filtro);
               onGetCantidadFoliosPorIdGrupoServicio(e.target.value, this.state.filtro.busqueda);
          };

          const handleTableChange = (pagination) => {
               this.setState({
                    page: pagination.current
               });
               onGetServiciosPorGrupo(pagination.current, busqueda)
          };

          const onModalSolicitudes = (record, item) => {
               let {color} = item;
               let {idServicio, idGrupoSer} = record;
               onGetSolicitudesDeServicioPorServicio(idServicio, color, 1, idGrupoSer, this.state.filtro)
               onShowModalSolicitudesServicio(record, idServicio, color, idGrupoSer, this.state.page, this.state.filtro);
          }

          const columns = [{
               title: 'Logo Servicio',
               key: 'urlImagen',
               align: 'center',
               width: '10%',
               render: (record) => (
                    <img onError={this.addDefaultSrc} className='iconTablero' src={record.urlImagen}></img>
               )
               },{
               title: 'Servicio',
               dataIndex: 'nombre',
               key: 'nombre',
               align: 'center',
               width: '15%',
               },{
               title: 'Nomenclatura',
               dataIndex: 'nomenclatura',
               key: 'nomenclatura',
               align: 'center',
               width: '10%',
               }, {
               title: 'Grupo Servicio',
               dataIndex: 'grupoServicio',
               key: 'grupoServicio',
               align: 'center',
               width: '15%',
               }, this.state.filtro.estadoFolios == 'Cerrados' ? {
               title: 'Solicitudes de Servicio Cerrados',
               key: '',
               align: 'center',
               width: '40%',
               render: (record) => (
                    <Row justify='space-around'>
                         {record.cerrados.map( (item, index) => 
                         <Col key={index} span={11}>
                              <Tooltip title={`Cantidad: ${item.cantidad} - Porcentaje: ${item.porcentaje.toFixed(2)}%`}>
                                   <Button
                                        style={{ backgroundColor: ` ${valoresTableroCerrados[item.color]}`, color: `${valoresTableroColor[item.color]}` }}
                                        onClick={() => onModalSolicitudes(record, item)}
                                        > 
                                        { `${item.cantidad} - ${item.porcentaje.toFixed(2)}%`} 
                                   </Button>
                              </Tooltip>
                         </Col>
                         )}
                    </Row>
                    ),
               }: this.state.filtro.estadoFolios == 'SinCerrar' ? {
               title: 'Solicitudes de Servicio Abiertas',
               key: '',
               align: 'center', 
               width: '40%',
               render: (record) => (
                  <Row justify='space-around'>
                       {record.dataTableroResponseByIdServicioLocal.map( (item, index) => 
                         <Col key={index} span={4}>
                              <Tooltip title={`Cantidad: ${item.cantidad} - Porcentaje: ${item.porcentaje.toFixed(2)}%`}>
                                   <Button
                                        style={{ backgroundColor: ` ${valoresTableroAbiertos[item.color]}`, color: `${valoresTableroColor[item.color]}` }}
                                        onClick={() => onModalSolicitudes(record, item)}
                                        > 
                                        { `${item.cantidad} - ${item.porcentaje.toFixed(2)}%`} 
                                   </Button>
                              </Tooltip>
                         </Col>
                       )}
                  </Row>
               ),          
                
          }:{}];

          const onExpandir = () => { if(!this.state.maximizar){ this.setState({ colIzq: 0, colDer: 24, maximizar: true }); } else{ this.setState({  colIzq: 5, colDer: 19, maximizar: false }); }  };

          const listChangePage = (pagination) => {
               this.state({
                    page: pagination.current,
               })
               onGetServiciosPorGrupo(pagination.current, this.state.filtro)
          };

          return (
               <div>

               {/* <Card className="cardCatalogos" title={<div className='headerLista'>Tablero De Operaciones</div>}>
                    <br/>
                    <Row justify='space-around'>
                         <Col span={24}>
                              <Select
                                   allowClear
                                   style={{ width: '100%' }}
                                   placeholder="Selecciona el Grupo de Servicio"
                                   onChange={onChangeGrupo}
                                   loading={fetchingListaGruposServicios}
                              >
                              {listaGruposServicios.map(option => 
                                   <Option key={option.idGrupoSer}>
                                        <Tooltip placement='right' title={option.nombre + '-' +option.descripcion } >
                                             {option.nombre}
                                        </Tooltip>
                                   </Option>)}
                              </Select>
                         </Col>
                         <Col span={8} offset={3}>
                              <br/>
                              <InfoCircleTwoTone className='icono-folios-catalogos' />
                              <p className='num-folios-catalogos'>{}</p>
                         
                              <Radio.Group onChange={onChangeEstadoSS} defaultValue='SinCerrar' >
                                   <Row justify='space-around'>
                                   <Radio value='SinCerrar'>Abiertos</Radio>
                                   </Row>
                                   <Row  justify='space-around'>
                                   <Radio value='Cerrados'>Cerrados</Radio>
                                   </Row>
                              </Radio.Group>
                         </Col>
                    </Row>
                    <Row justify='space-around'>
                         {servicioPorGrupo.length > 0 ? (
                         
                              <Col span={24}>
                                   <br/>                           
                                   <Table 
                                        size="small" 
                                        rowKey="idServicio" 
                                        columns={columns}
                                        dataSource={servicioPorGrupo}
                                        loading={fetchingServicioPorGrupo}
                                        locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar'}}
                                        onChange={handleTableChange}
                                        pagination={{ total: servicioPorGrupoCantidad, simple: true  }}
                                        scroll={scroll}
                                   />                        
                              </Col> 

                         ): false} 

                         {servicioPorGrupo.length === 0 ? (
                              <Col span={23}>
                                   <br/>
                                   <div><Alert style={{ width: '100%' , borderRadius: '2em' }} message='No hay solicitudes de servicio relacionadas a este grupo de servicio' type="info"/></div>
                              </Col>
                         ): false}
                    </Row>
               </Card> */}
               <ModalSolicitudesServicio/>
                    <Row>
                         <Col span={this.state.colIzq} style={{backgroundColor: '#737373', borderTopLeftRadius:'1em', borderBottomLeftRadius:'1em'}}>
                              <Row justify='center' style={{padding:'1em'}}>
                                   <Col span={24}>
                                        <Row justify='space-around'>
                                             <Divider><Text strong style={{color:'white'}}>Filtro de Búsqueda</Text> </Divider>
                                             <Col span={24} style={{paddingTop:'1em'}}>
                                                  <Select
                                                       allowClear
                                                       style={{ width: '100%' }}
                                                       placeholder="Selecciona el Grupo de Servicio"
                                                       onChange={onChangeGrupo}
                                                       loading={fetchingListaGruposServicios}
                                                  >
                                                  {listaGruposServicios.map(option => 
                                                       <Option key={option.idGrupoSer}>
                                                            <Tooltip placement='right' title={option.nombre + '-' +option.descripcion } >
                                                                 {option.nombre}
                                                            </Tooltip>
                                                       </Option>)}
                                                  </Select>
                                             </Col>

                                             {/* <Col span={12}>
                                             <InfoCircleTwoTone className='icono-folios-catalogos' />
                                                  <p className='num-folios-catalogos'>{cantidadFoliosPorIdGrupoServ}</p>
                                                  <p className='text-folios-catalogos'>Solicitudes de Servicio</p>
                                             </Col> */}
                                             <Col span={24}>
                                                  <br/>
                                                  <Radio.Group onChange={onChangeEstadoSS} defaultValue='SinCerrar' >
                                                  <Row justify='space-around'>
                                                       <Radio value='SinCerrar' style={{color: '#fff'}}>Abiertos</Radio>
                                                  </Row>
                                                  <Row  justify='space-around'>
                                                       <Radio value='Cerrados' style={{color: '#fff'}}>Cerrados</Radio>
                                                  </Row>
                                                  </Radio.Group>
                                             </Col>
                                        </Row>
                                   </Col>
                              </Row>
                         </Col>
                         
                         <Col span={this.state.colDer} style={{backgroundColor: '#F2F2F2' }}>
                              <Row justify='space-around' align='middle' style={{ backgroundColor: '#E4E4E4', borderTopRightRadius:'1em' }}>
                                   <Col span={22} style={{backgroundColor: '#E4E4E4', paddingTop: '1em', paddingBottom: '1em', paddingLeft: '1em'}}>
                                        <Row justify='start' align='middle' gutter={[0,0]}>
                                             <Text style={{textAlign: 'left', fontSize: '2em', borderLeftColor: '#E4E4E4', borderRightColor:'#737373', borderTopColor:'#E4E4E4', borderBottomColor:'#E4E4E4', borderStyle: 'solid', paddingRight: '.5em', color: '#737373', paddingLeft:'.5em'}} strong> Tablero De Operaciones </Text>
                                             <Text style={{color:'#1747ca', fontSize: '2em', paddingLeft:'.5em'}}> {servicioPorGrupoCantidad}  {servicioPorGrupoCantidad === 1 ? ' Solicitud de Servicio' : ' Solicitudes de Servicio'} </Text>
                                        </Row>
                                   </Col>
                                   <Col span={1} style={{backgroundColor: '#E4E4E4', paddingTop: '1em', paddingBottom:'1em'}}>
                                        { this.state.maximizar ? (<> <RightCircleOutlined className='iconoMaxMin' onClick={onExpandir} /> </>):(<> <LeftCircleOutlined className='iconoMaxMin' onClick={onExpandir}/> </>) }
                                   </Col>
                              </Row>

                              <Row justify='space-around' gutter={[8,8]}>
                                   <Col span={24} style={{padding:'1em'}}>
                                        <List
                                             pagination={{ total: servicioPorGrupoCantidad, simple: true, pageSize: 12, onChange: page => {listChangePage(page)} }}
                                             style={{backgroundColor:"#F2F2F2"}}
                                             // grid={{ gutter: 16, xs: 1, sm: 2, md: 4, lg: 4,  xl: 5, xxl: 5 }}
                                             grid={{ gutter: 8, xs: 1, sm: 1, md: 2, lg: 4,  xl: 4, xxl: 4  }}
                                             dataSource={servicioPorGrupo}
                                             loading={fetchingServicioPorGrupo}
                                             renderItem={item => (
                                                  <List.Item className='listaHover'>
                                                       <Card
                                                            className='cardReporteGrafico'
                                                            bordered={true} 
                                                            style={{backgroundColor:'#DDDDDD'}}
                                                            cover={ <Row justify='center' align='middle'> <img onError={this.addDefaultSrc} style={{width:'80%', paddingTop:'1em'}} alt={item.nombre} src={item.urlImagen ? item.urlImagen : imagenNoResult} /> </Row> }
                                                            actions={[ 
                                                                 <Row justify='space-around' align='middle' gutter={[8,8]}>
                                                                      {this.state.filtro.estadoFolios === 'Cerrados' ? item.cerrados.map( (i, index) => 
                                                                           <Col key={index} span={12}>
                                                                                <Tooltip title={`Cantidad: ${i.cantidad} - Porcentaje: ${i.porcentaje.toFixed(2)}%`}>
                                                                                     <Button
                                                                                          disabled={i.cantidad === 0 }
                                                                                          style={{ backgroundColor: ` ${valoresTableroCerrados[i.color]}`, color: `${valoresTableroColor[i.color]}` }}
                                                                                          onClick={() => onModalSolicitudes(item, i)}
                                                                                          > 
                                                                                          { `${i.cantidad} - ${i.porcentaje.toFixed(2)}%`} 
                                                                                     </Button>
                                                                                </Tooltip>
                                                                           </Col>
                                                                      ):(
                                                                           <>
                                                                           {item.dataTableroResponseByIdServicioLocal.map( (i, index) => 
                                                                                <Col key={index} span={6}>
                                                                                     <Tooltip title={`Cantidad: ${i.cantidad} - Porcentaje: ${i.porcentaje.toFixed(2)}%`}>
                                                                                          <Button
                                                                                               disabled={i.cantidad === 0}
                                                                                               style={{backgroundColor: ` ${valoresTableroAbiertos[i.color]}`, color: `${valoresTableroColor[i.color]}` }}
                                                                                               onClick={() => onModalSolicitudes(item, i)}
                                                                                          > 
                                                                                               {`${i.cantidad}`} 
                                                                                          </Button>
                                                                                     </Tooltip>
                                                                                </Col>
                                                                           )}
                                                                           </>
                                                                      )}
                                                                      {/* <Col span={6}>
                                                                           <a className='editarIcono' onClick={() => {console.log('item', item); this.props.onShowModal(item); this.props.onGetTrazabilidadFolio(item.folio, 1); }}><EllipsisOutlined /></a> 
                                                                      </Col>
                                                                      <Col span={6}>
                                                                           <a className='editarIcono' onClick={() => {console.log('item', item); this.props.onShowModal(item); this.props.onGetTrazabilidadFolio(item.folio, 1); }}><EllipsisOutlined /></a> 
                                                                      </Col>
                                                                      <Col span={6}>
                                                                           <a className='editarIcono' onClick={() => {console.log('item', item); this.props.onShowModal(item); this.props.onGetTrazabilidadFolio(item.folio, 1); }}><EllipsisOutlined /></a> 
                                                                      </Col>
                                                                      <Col span={6}>
                                                                           <a className='editarIcono' onClick={() => {console.log('item', item); this.props.onShowModal(item); this.props.onGetTrazabilidadFolio(item.folio, 1); }}><EllipsisOutlined /></a> 
                                                                      </Col> */}
                                                                 </Row>
                                                                 
                                                            ]}
                                                       >
                                                            <Meta
                                                                 title={
                                                                      <Row justify='space-around' align='middle' style={{backgroundColor: '#737373', borderRadius:'1em'}}>
                                                                           <Text style={{color:'white'}} ellipsis type='secondary'> <Tooltip title={item.nomenclatura + ' - ' + item.nombre} placement='topLeft'> {item.nomenclatura + ' - ' + item.nombre} </Tooltip> </Text> 
                                                                      </Row>
                                                                 }
                                                                 description={
                                                                      <Row justify='space-around' align='middle' style={{backgroundColor: '#a6a5a5', borderRadius:'1em'}}>
                                                                           <Text ellipsis type='secondary' style={{color:'#fff'}} ><Tooltip title={item.grupoServicio} placement='topLeft'> {item.grupoServicio} </Tooltip></Text>
                                                                      </Row>
                                                                 }
                                                            />

                                                       </Card>
                                                  </List.Item>
                                             )}
                                        />
                                   </Col>
                              </Row>
                         </Col>
                    </Row>
               </div>
          );
     }
}
 
 const mapStateToProps = state => {
     return {
          listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,
          fetchingListaGruposServicios: state.MonitorIntegralReducer.fetchingListaGruposServicios,
          servicioPorGrupoCantidad: state.MonitorIntegralReducer.servicioPorGrupoCantidad,
          servicioPorGrupo: state.MonitorIntegralReducer.servicioPorGrupo,
          fetchingServicioPorGrupo: state.MonitorIntegralReducer.fetchingServicioPorGrupo,
          cantidadFoliosPorIdGrupoServ: state.MonitorIntegralReducer.cantidadFoliosPorIdGrupoServ,
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          onGetGruposServicios: () => {
               dispatch({ type: 'LISTA_GRUPOS_SERVICIOS_REQUEST' });
          },
          onGetServiciosPorGrupo: (page, filtro) => {
               dispatch({ type: 'SERVICIO_POR_GRUPOSERVICIO_REQUEST', page, filtro})
          },
          onLimpiarServicioPorGrupo: () => {
               dispatch({ type: 'SERVICIO_POR_GRUPOSERVICIO_SUCCESS', servicioPorGrupo: [] })
          },
          onShowModalSolicitudesServicio: (servicioSeleccionadoTablero, idServicio, color, idGrupoSer, page, filtro) => {
               dispatch({ type: 'SHOW_MODAL_SOLICITUDES_DE_SERVICIO', showModalSolicitudesDeServicio: true , servicioSeleccionadoTablero, idServicio, color, idGrupoSer, page, filtro})
          },
          onGetSolicitudesDeServicioPorServicio: (idServicio, color, page, idGrupoSer) => {
               dispatch({ type: 'SOLICITUDES_DE_SERVICIO_POR_SERVICIO_REQUEST', idServicio, color, page, idGrupoSer })
          },
          onGetCantidadFoliosPorIdGrupoServicio: (estado, idGrupoSer) => {
               dispatch({ type: 'CANTIDAD_FOLIOS_POR_IDGRUPOSERVICIO_REQUEST',estado, idGrupoSer })
          }
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(TableroDeOperacionesContainer);