import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {Bar} from 'react-chartjs-2';
import {
    Card,
    Row,
    Col,
    Button,
    DatePicker,
    Select,
    Form,
    Alert,
    Spin,
    Descriptions,
    Divider,
    Modal,
    Checkbox, Switch
} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const {Option} = Select;

function ModalConsultaDeEvaluaciones(props) {

    const [state, setState] = useState({ mes1: "", mes2: "", mes2Stored: "", differentServices: false, servicio1: null, servicio2: null });

    const {
        onGetGerencias,
        showModalConsultaDeEvaluaciones,
        onShowModalConsultaEvaluaciones,
        onListaEvaluacionesDeDesempeno1,
        onListaEvaluacionesDeDesempeno2,
        fetchingListaMesesEvaluacion,
        listaMesesEvaluacion,
        onListaMesesEvaluacion,
        fetchingListaServiciosActivos,
        listaServiciosActivos,
        onListaServiciosActivos,
        listaEvaluacionesDesempeno1,
        fetchingListaEvaluacionesDesempeno1,
        listaEvaluacionesDesempenoCantidad1,
        listaEvaluacionesDesempeno2,
        fetchingListaEvaluacionesDesempeno2,
        listaEvaluacionesDesempenoCantidad2,
        nomenclaturaElemento1,
        nomenclaturaElemento2,
        fetchingListaServiciosByCentro,
        listaServiciosByCentro,
        onListaServiciosByCentro,
        listaPartidasByServicio,
        fetchingListaPartidasByServicio,
        listaPartidasByServicio2,
        fetchingListaPartidasByServicio2,
        onListaPartidasByServicio,
        onListaPartidasByServicio2
    } = props;

    const formRef1 = useRef();

    useEffect(() => {

        onListaMesesEvaluacion();
        onListaServiciosActivos();

    }, []);

    const handleCancel = () => {
        onShowModalConsultaEvaluaciones();
        formRef1.current.resetFields();
    }

    const onMes1Change = (value) => {
        if (value) {
            setState({...state, mes1: value.split(' - ')[1]});
        } else {
            setState({...state, mes1: ""});
        }
    }

    const onServicio1Change = (value) => {
        if (value) {
            //Para realizar una comparativa entre servicios diferentes, se consumen el servicio que está justo abajo, en el se envía el primer servicio a comparar y retorna una lista de servcios o servicio con el cual comparte el mismo centro de costo
            onListaServiciosByCentro(value);
            onListaPartidasByServicio(value);
            setState({...state, servicio1: value});
        } else {
            setState({...state, servicio1: null});
        }
        formRef1.current.setFieldsValue({"idPartida1": undefined, "servicio2": undefined});
    }

    const onServicio2Change = (value) => {
        if (value) {
            onListaPartidasByServicio2(value);
            setState({...state, servicio2: value});
        } else {
            setState({...state, servicio2: null});
        }
        formRef1.current.setFieldsValue({"idPartida2": undefined});
    }

    const onMes2Change = (value) => {
        if (value) {
            setState({...state, mes2: value.split(' - ')[1]});
            setState({...state, mes2Stored: value.split(' - ')[1]});
        } else {
            setState({...state, mes2: ""});
            setState({...state, mes2Stored: ""});
        }
    }

    const dataGrafica = {
        labels: [state.mes1 + " " + nomenclaturaElemento1, state.mes2 + " " + nomenclaturaElemento2],
        datasets: listaEvaluacionesDesempeno1.concat(listaEvaluacionesDesempeno2),
    };

    const options = {
        tooltips: {
            mode: 'x',
            intersect: true,
            position: 'average',
            yAlign: 'top',
            callbacks: {
                title: function() {}
            }
        },
        scales: {
            yAxes: [
                {
                    stacked: false,
                    ticks: {beginAtZero: true, precision: 0, autoSkip: false, maxRotation: 0, minRotation: 0},
                    scaleLabel: {display: true, labelString: ['Cantidad']}
                }
            ],
            xAxes: [
                {
                    stacked: true,
                    ticks: {beginAtZero: true, autoSkip: true, maxRotation: 30, minRotation: 0},
                    scaleLabel: {display: true, labelString: ['Servicios']}
                },
            ],
        },
        legend: {
            display: false,
        },
        hover: {animationDuration: 7},
    };

    const onFinish1 = formulario => {
        console.log(formulario);
        var idPartida1 = '';
        var idPartida2 = '';
        if (formulario.idPartida1) {
            idPartida1 = formulario.idPartida1;
        }
        if (formulario.idPartida2) {
            idPartida2 = formulario.idPartida2;
        }
        if (state.differentServices) {
            setState({...state, mes1: formulario.mes.split(' - ')[1], mes2: formulario.mes.split(' - ')[1] });
            onListaEvaluacionesDeDesempeno1(formulario.mes, formulario.servicio, idPartida1);
            onListaEvaluacionesDeDesempeno2(formulario.mes, formulario.servicio2, idPartida2);
        } else {
            setState({...state, mes1: formulario.mes.split(' - ')[1], mes2: formulario.mes2.split(' - ')[1] });
            onListaEvaluacionesDeDesempeno1(formulario.mes, formulario.servicio, idPartida1);
            onListaEvaluacionesDeDesempeno2(formulario.mes2, formulario.servicio, idPartida1);
        }
    }

    const onChangeSwitch = (value) => {
        if (value) {
            setState({...state, mes2: state.mes1, differentServices: value });
        } else {
            setState({...state, mes2: state.mes2Stored, differentServices: value });
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    }

    return (
        <Modal
            visible={showModalConsultaDeEvaluaciones}
            title='Consulta De Evaluaciones'
            onCancel={handleCancel}
            footer={false}
            width='90%'
        >
            <Form
                name="formulario"
                ref={formRef1}
                onFinish={onFinish1}
                
            >
                <Row justify='space-around' style={{marginBottom: "20px" }}>
                    <Col span={21}>
                        <Switch onChange={onChangeSwitch} checkedChildren="Comparar servicios diferentes"
                                unCheckedChildren="Comparar servicios iguales" />
                    </Col>
                </Row>
                <Row justify='space-around'>
                    <Col span={7}>
                        <Form.Item
                            name='servicio'
                            rules={[{required: true, message: 'Favor de llenar el campo'}]}
                        >
                            <Select allowClear
                                    style={{width: '100%'}}
                                    placeholder="Selecciona el servicio a comparar"
                                    loading={fetchingListaServiciosActivos}
                                    onChange={onServicio1Change}
                                    showSearch={true}
                            >
                                {listaServiciosActivos.map(option => <Option
                                    key={option.idServicio}>{option.idServicio + ' - ' + option.nombre}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    {state.servicio1 ?
                        <Col span={7}>
                            <Form.Item
                                name="idPartida1"
                            >
                                <Select allowClear
                                        style={{width: '100%'}}
                                        placeholder="Selecciona una partida"
                                        loading={fetchingListaPartidasByServicio}
                                        showSearch={true}
                                >
                                    {listaPartidasByServicio.map(option => <Option
                                        key={option.idPartida}>{option.nombre}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col> :
                        <Col span={7}>
                        </Col>
                    }
                    <Col span={7}>
                    </Col>
                </Row>
                {state.differentServices && state.servicio1 ?
                <Row justify='space-around'>

                    <Col span={7}>
                        <Form.Item
                            name='servicio2'
                            rules={[{required: true, message: 'Favor de llenar el campo'}]}
                        >
                            <Select allowClear
                                    style={{width: '100%'}}
                                    placeholder="Selecciona el segundo servicio"
                                    loading={fetchingListaServiciosByCentro}
                                    showSearch={true}
                                    onChange={onServicio2Change}
                            >
                                {listaServiciosByCentro.map(option => <Option
                                    key={option.idServicio}>{option.idServicio + ' - ' + option.nombre}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    {state.servicio2 ?
                        <Col span={7}>
                            <Form.Item
                                name="idPartida2"
                            >
                                <Select allowClear
                                        style={{width: '100%'}}
                                        placeholder="Selecciona una partida"
                                        loading={fetchingListaPartidasByServicio2}
                                        showSearch={true}
                                >
                                    {listaPartidasByServicio2.map(option => <Option
                                        key={option.idPartida}>{option.nombre}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col> :
                        <Col span={7}>
                        </Col>
                    }
                    <Col span={7}>
                    </Col>
                </Row> : false}
                <Row justify='space-around' >
                    <Col span={7}>
                        <Form.Item
                            name='mes'
                            rules={[{required: true, message: 'Favor de llenar el campo'}]}
                        >
                            <Select allowClear
                                    style={{width: '100%'}}
                                    placeholder="Selecciona el mes del primer servicio"
                                    loading={fetchingListaMesesEvaluacion}
                                    showSearch={true}
                                    onChange={onMes1Change}
                            >
                                {listaMesesEvaluacion.map(option => <Option
                                    key={'' + option.idMes + ' - ' + option.mes}>{option.mes}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    {!state.differentServices ?
                        <Col span={7}>
                            <Form.Item
                                name='mes2'
                                rules={[{required: true, message: 'Favor de llenar el campo'}]}
                            >
                                <Select allowClear
                                        style={{width: '100%'}}
                                        placeholder="Selecciona el mes del segundo servicio"
                                        loading={fetchingListaMesesEvaluacion}
                                        showSearch={true}
                                        onChange={onMes2Change}
                                >
                                    {listaMesesEvaluacion.map(option => <Option
                                        key={'' + option.idMes + ' - ' + option.mes}>{option.mes}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col> :
                        <Col span={7}>
                        </Col>
                    }
                    <Col span={7}>
                    </Col>
                </Row>
                <Row justify='space-around' style={{marginBottom: "10px"}}>
                    <Col span={7}>
                        <Button className="btnFiltrar" shape="circle" icon={<SearchOutlined/>} htmlType="submit"
                                className='nuevoUsuario'> Consultar Evaluaciones </Button>
                    </Col>
                    <Col span={15}>
                    </Col>
                </Row>
            </Form>

            <Divider/>

            <Spin spinning={fetchingListaEvaluacionesDesempeno1 || fetchingListaEvaluacionesDesempeno2}>
                <Row justify='space-around'>
                    {listaEvaluacionesDesempenoCantidad1 > 0 || listaEvaluacionesDesempenoCantidad2 > 0 ?
                        (
                            <>
                                <Col span={21}>
                                    <Bar
                                        data={dataGrafica}
                                        options={options}
                                    />
                                </Col>
                            </>
                        ) :
                        (
                            <Alert
                                message="Consulta De Evaluaciones"
                                description="Selecciona un servicio y un mes para continuar"
                                type="info"
                                showIcon
                                style={{borderRadius: '2em', width: '100%'}}
                            />
                        )
                    }
                </Row>
            </Spin>
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        fetchingListaMesesEvaluacion: state.EvaluacionDeLaGestionReducer.fetchingListaMesesEvaluacion,
        listaMesesEvaluacion: state.EvaluacionDeLaGestionReducer.listaMesesEvaluacion,
        fetchingListaServiciosActivos: state.ServiciosVariablesReducer.fetchingListaServiciosActivos,
        listaServiciosActivos: state.ServiciosVariablesReducer.listaServiciosActivos,
        listaEvaluacionesDesempeno1: state.EvaluacionDeLaGestionReducer.listaEvaluacionesDesempeno1,
        fetchingListaEvaluacionesDesempeno1: state.EvaluacionDeLaGestionReducer.fetchingListaEvaluacionesDesempeno1,
        listaEvaluacionesDesempenoCantidad1: state.EvaluacionDeLaGestionReducer.listaEvaluacionesDesempenoCantidad1,
        listaEvaluacionesDesempeno2: state.EvaluacionDeLaGestionReducer.listaEvaluacionesDesempeno2,
        fetchingListaEvaluacionesDesempeno2: state.EvaluacionDeLaGestionReducer.fetchingListaEvaluacionesDesempeno2,
        listaEvaluacionesDesempenoCantidad2: state.EvaluacionDeLaGestionReducer.listaEvaluacionesDesempenoCantidad2,
        showModalConsultaDeEvaluaciones: state.EvaluacionDeLaGestionReducer.showModalConsultaDeEvaluaciones,
        nomenclaturaElemento1: state.EvaluacionDeLaGestionReducer.nomenclaturaElemento1,
        nomenclaturaElemento2: state.EvaluacionDeLaGestionReducer.nomenclaturaElemento2,
        fetchingListaServiciosByCentro: state.EvaluacionDeLaGestionReducer.fetchingListaServiciosByCentro,
        listaServiciosByCentro: state.EvaluacionDeLaGestionReducer.listaServiciosByCentro,
        listaPartidasByServicio: state.EvaluacionDeLaGestionReducer.listaPartidasByServicio,
        fetchingListaPartidasByServicio: state.EvaluacionDeLaGestionReducer.fetchingListaPartidasByServicio,
        listaPartidasByServicio2: state.EvaluacionDeLaGestionReducer.listaPartidasByServicio2,
        fetchingListaPartidasByServicio2: state.EvaluacionDeLaGestionReducer.fetchingListaPartidasByServicio2,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onListaMesesEvaluacion: () => {
            dispatch({type: 'LISTA_MESES_EVALUACION_REQUEST'})
        },
        onListaServiciosActivos: () => {
            dispatch({type: 'LISTA_SERVICIOS_ACTIVOS_REQUEST'});
        },
        onListaServiciosByCentro: (idServicio) => {
            dispatch({type: 'LISTA_SERVICIOS_BY_CENTRO_REQUEST', idServicio});
        },
        onListaEvaluacionesDeDesempeno1: (idMes, idServicio, idPartida) => {
            dispatch({
                type: 'LISTA_EVALUACIONES_DESEMPENO_1_REQUEST',
                idClasificacionGerencia: '',
                idMes,
                idObjetivo: '',
                page: 1,
                idProveedor: '',
                order: 'ASC',
                field: 'idEvaluacionIndicador',
                idServicio,
                idPartida
            })
        },
        onListaEvaluacionesDeDesempeno2: (idMes, idServicio, idPartida) => {
            dispatch({
                type: 'LISTA_EVALUACIONES_DESEMPENO_2_REQUEST',
                idClasificacionGerencia: '',
                idMes,
                idObjetivo: '',
                page: 1,
                idProveedor: '',
                order: 'ASC',
                field: 'idEvaluacionIndicador',
                idServicio,
                idPartida
            })
        },
        onShowModalConsultaEvaluaciones: () => {
            dispatch({type: 'MODAL_CONSULTA_EVALUACIONES', showModalConsultaDeEvaluaciones: false})
        },
        onListaPartidasByServicio: (idServicio) => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_REQUEST', idServicio: idServicio})
        },
        onListaPartidasByServicio2: (idServicio) => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_REQUEST2', idServicio: idServicio})
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalConsultaDeEvaluaciones);

