import React, { Component } from 'react';
import { Modal } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import EditarPonderador from './EditarPonderador';
import { connect } from "react-redux";
import VerificarContraseña from './VerificarContraseña';
import ListaPonderaciones from './ListaPonderaciones';
import ArchivoEditarPonderacion from './ArchivoEditarPonderacion';

class ModalEditarPonderacion extends Component {
    state = {
        password: '',
    }
    formRef = React.createRef();
    render() {
        const { showModalEditarPonderacion, onShowModalEditarPonderacion, vistaModalPonderacion, 
            onCambiarVista, onLimpiarFormulario,
        } = this.props;

        const handleCancel = () => {
            onShowModalEditarPonderacion();
            onLimpiarFormulario('')
            onCambiarVista(1);
        }
        function ContenidoEditar() {
            if(vistaModalPonderacion === 1){
                return(
                    <EditarPonderador/>
                );
            }
            else if(vistaModalPonderacion === 2){
                return(
                    <VerificarContraseña/>
                );
            }
            else if(vistaModalPonderacion === 3){
                return(
                    <ListaPonderaciones/>
                );
            }
            else if(vistaModalPonderacion === 4){
                return(
                    <ArchivoEditarPonderacion/>
                );
            }
        };
        
        return (
            <Modal
            visible={showModalEditarPonderacion}
            title='Editar Ponderación Área Funcional'
            onCancel={handleCancel}
            footer={false}
            width={'70%'}
            >
                <ContenidoEditar/>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        showModalEditarPonderacion: state.CatalogosGeneralesReducer.showModalEditarPonderacion,
        vistaModalPonderacion: state.CatalogosGeneralesReducer.vistaModalPonderacion,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarPonderacion: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_PONDERACION', showModalEditarPonderacion: false, page: null })
        },
        onEditarPonderacion: ( formulario, page ) => {
            dispatch({ type: "EDITAR_PONDERACION_REQUEST" , formulario: formulario, page: page });
        },
        onLimpiarFormulario: (limpiarFormulario) => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: limpiarFormulario });
        },
        onCambiarVista: (vistaModalPonderacion) => {
            dispatch({ type: 'CAMBIAR_VISTA_PONDERACION', vistaModalPonderacion: vistaModalPonderacion, formulario: null, ponderacionEditados: null})
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarPonderacion);