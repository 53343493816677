import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, Select } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const { Option, OptGroup } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalNuevoRol extends Component {
    formRef = React.createRef();
    state={
        modulo: '',
        showPermisos: false
    }
    render() {
        const { fetchingNuevoRol, showModalNuevoRol, onNuevoRol, onShowModalNuevoRol, listaModulosSelect, fetchingListaModulos,
            fetchingListaPermisosByModulo, listaPermisosByModulo, limpiarFormulario, onLimpiarFormulario, getPermisosByModulo,
            onActualizarListaModulos, valueSearch
        } = this.props;
        const {showPermisos} = this.state;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            this.setState({
                showPermisos:false
            })
            onNuevoRol(formulario, listaPermisosByModulo, this.props.page, valueSearch);
        };
        

        const handleCancel = () => {
            onShowModalNuevoRol();
            this.setState({
                showPermisos:false
            })
            this.formRef.current.resetFields();
        }

        if(limpiarFormulario && limpiarFormulario === 'crearRol'){
            this.formRef.current.resetFields();
            this.setState({
                showPermisos:false
            })
            onLimpiarFormulario();
        };
        const permisosPorModulo = (value) => {
            getPermisosByModulo(value.split(' - ')[0]);
            this.setState({
                modulo: value.split(' - ')[1],
                showPermisos: true
            })
        }
        return (
            <Modal
            visible={showModalNuevoRol}
            title='Nuevo Rol'
            onCancel={handleCancel}
            footer={false}
            width={'50%'}
            >
                <Spin spinning={fetchingNuevoRol}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Form.Item
                            label="Nombre del rol"
                            name="descripcion"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Input maxLength={80} placeholder="Ingrese nombre del rol" />
                        </Form.Item>

                        <Form.Item
                            label="Módulo de sistema"
                            name="modulo"
                            rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                        >
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Selecciona el módulo de sistema"
                                loading={fetchingListaModulos}
                                notFoundContent={null}
                                onChange={permisosPorModulo}
                            >
                                {listaModulosSelect.map(option => <Option key={option.idModulo + ' - ' + option.nombre}>{option.nombre}</Option>)}
                            </Select>
                        </Form.Item>
                        {showPermisos==true?(
                            <Form.Item
                                label="Permisos"
                                name="idPermiso"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select 
                                    allowClear
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona los permisos"
                                    loading={fetchingListaPermisosByModulo}
                                    // onChange={onTodos}
                                >
                                    {/* <OptGroup label="Todos los permisos del módulo">
                                        <Option value={'Todos ' + this.state.modulo}>Todos {this.state.modulo}</Option>
                                    </OptGroup>
                                    <OptGroup label="Permisos"> */}
                                        {listaPermisosByModulo.map(option => <Option key={option.idPermiso + ' - ' + option.descripcion}>{option.idPermiso + ' - ' + option.descripcion}</Option>)}
                                    {/* </OptGroup> */}
                                </Select>
                            </Form.Item>
                        ):(false)}
                        <Col span={12}>
                            <Button className="btnFiltrar" htmlType="submit">Crear Rol</Button>
                        </Col>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingNuevoRol: state.ConfiguracionYSeguridadReducer.fetchingNuevoRol,
        showModalNuevoRol: state.ConfiguracionYSeguridadReducer.showModalNuevoRol,
        fetchingListaPermisosByModulo: state.ConfiguracionYSeguridadReducer.fetchingListaPermisosByModulo,
        listaPermisosByModulo: state.ConfiguracionYSeguridadReducer.listaPermisosByModulo,
        page: state.ConfiguracionYSeguridadReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaModulosSelect: state.ConfiguracionYSeguridadReducer.listaModulosSelect,
        fetchingListaModulos: state.ConfiguracionYSeguridadReducer.fetchingListaModulos,
        valueSearch: state.ConfiguracionYSeguridadReducer.valueSearch
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalNuevoRol: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_ROL', showModalNuevoRol: false, page: null })
        },
        onNuevoRol: ( formulario, listaPermisos, page, valueSearch ) => {
            dispatch({ type: "NUEVO_ROL_REQUEST" , formulario, listaPermisos, page , valueSearch});
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        getPermisosByModulo: (idModulo) => {
            dispatch({ type: 'GET_PERMISOS_BY_MODULO_REQUEST', idModulo});
        },
        onActualizarListaModulos: (listaModulosSelect) => {
            dispatch({ type: 'LISTA_MODULOS_SUCCESS', listaModulosSelect })
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoRol);