import * as MecanismosDePagoActions from '../actions/MecanismosDePagosActions';
import {EXPORTAR_SERVICIOS_PUBLICOS_PDF_FAILURE} from "../actions/MecanismosDePagosActions";

const initialState = {
    page: null,
    catalogoMecanismosDePagos: null,
    busqueda: '',

    //tiempos de garantia
    fetchingListaServiciosFiltro: false,
    listaServiciosFiltro: [],

    fetchingListaTiempoDeGarantiaBusqueda: false,
    listaTiempoDeGarantiaBusqueda: [],
    listaTiempoDeGarantiaBusquedaCantidad: null,

    showModalNuevoTiempoDeGarantia: false,
    showModalEditarTiempoDeGarantia: false,
    tiempoDeGarantiaSeleccionado: null,

    fetchingNuevoTiempoDeGarantia: false,
    fetchingEditarTiempoDeGarantia: false,
    vistaModalTiempoDeGarantia: 1,
    formularioGarantia: null,
    tiemposEditados: null,

    fetchingListaTiemposTodos: false,
    listaTiemposTodos: [],

    fetchingListaTiemposGarantia: false,
    listaTiemposDeGarantia: [],
    listaTiemposDeGar: [],

    //Reducers Categorias Fallas de Calidad
    fetchingListaCategoriasFallasDeCalidadBusqueda: false,
    listaCategoriasFallasDeCalidadBusqueda: [],
    listaCategoriasFallasDeCalidadBusquedaCantidad: null,
    fetchingListaCategoriasFallasDeCalidadActivos: false,
    listaCategoriasFallasDeCalidadActivos: [],

    fetchingListaFallasDeCalidadEdicion: false,
    listaFallasDeCalidadEdicion: [],

    showModalNuevaCategoriaFallaDeCalidad: false,
    showModalEditarCategoriaFallaDeCalidad: false,
    CategoriaFallaDeCalidadSeleccionado: null,

    fetchingNuevaCategoriaFallaDeCalidad: false,
    fetchingEditarCategoriaFallaDeCalidad: false,
    vistaModalFallaCalidad: 1,
    formularioFallaCalidad: null,
    listaFiltradaEditados: [],
    listaValoresIniciales: {},

    // Reducers Fallas de Disponibilidad
    fetchingListaFallasDeDisponibilidad: false,
    listaFallasDeDisponibilidad: [],

    fetchingListaFallasDeDisponibilidadBusqueda: false,
    listaFallasDeDisponibilidadBusqueda: [],
    listaFallasDeDisponibilidadBusquedaCantidad: null,

    showModalNuevoFallasDeDisponibilidad: false,
    showModalEditarFallasDeDisponibilidad: false,
    FallasDeDisponibilidadSeleccionado: null,

    fetchingNuevoFallasDeDisponibilidad: false,
    fetchingEditarFallasDeDisponibilidad: false,

    vistaModalFallaDisponiblidad: 1,
    formularioDisponibilidad: null,
    fallaDisponibilidadEditados: null,

    fetchingListaCategoriasDisponibilidad: false,
    listaCategoriasDisponibilidad: [],
    listaCatDisponibilidad: [],

    //Reducers Estándares Desempeño Específicos
    fetchingListaEstandaresDesempenoEspecificosBusqueda: false,
    listaEstandaresDesempenoEspecificosBusqueda: [],
    listaEstandaresDesempenoEspecificosBusquedaCantidad: null,

    fetchingListaEstandaresDesempenoEspecificosActivos: false,
    listaEstandaresDesempenoEspecificosActivos: [],

    showModalNuevoEstandarDesempenoEspecifico: false,
    showModalEditarEstandarDesempenoEspecifico: false,
    EstandarDesempenoEspecificoSeleccionado: null,

    fetchingNuevoEstandarDesempenoEspecifico: false,
    fetchingEditarEstandarDesempenoEspecifico: false,

    // Ponderacion servicios por area
    fetchingListaPonderacionServPorAreaBusqueda: false,
    listaPonderacionServPorAreaBusqueda: [],
    listaPonderacionServPorAreaBusquedaCantidad: null,

    fetchingListaAreasPorServicio: false,
    listaAreasPorServicio: [],
    listaPonderacionServicioPorArea: [],

    showModalNuevoPonderacionServPorArea: false,
    showModalEditarPonderacionServPorArea: false,
    ponderacionServPorAreaSeleccionado: null,

    fetchingNuevoPonderacionServPorArea: false,
    fetchingEditarPonderacionServPorArea: false,
    vistaModalPonderacionServPorArea: 1,
    formularioPonderacionServ: null,
    ponderacionEditados: [],

    //Reducers Referencias de Estándares de Desempeño Específicos
    fetchingListaReferenciasDeEstandaresBusqueda: false,
    listaReferenciasDeEstandaresBusqueda: [],
    listaReferenciasDeEstandaresBusquedaCantidad: null,

    fetchingListaReferenciasDeEstandaresActivos: false,
    listaReferenciasDeEstandaresActivos: [],

    showModalNuevaReferenciaEstandar: false,
    showModalEditarReferenciaEstandar: false,
    referenciaEstandarSeleccionada: null,

    fetchingNuevaReferenciaEstandar: false,
    fetchingEditarRerenciaEstandar: false,

    //Reducers Claves Métodos de Supervisón
    fetchingListaClavesMetodosSupervisionBusqueda: false,
    listaClavesMetodosSupervisionBusqueda: [],
    listaClavesMetodosSupervisionBusquedaCantidad: null,

    fetchingListaClavesMetodosSupervisionActivos: false,
    listaClavesMetodosSupervisionActivos: [],
    listaClavesMetodosSupervisionExternos: [],

    showModalNuevaClaveMetodosSupervision: false,
    showModalEditarClaveMetodosSupervision: false,
    ClaveMetodosSupervisionSeleccionado: null,

    fetchingNuevoClavesMetodosSupervision: false,
    fetchingEditarClavesMetodosSupervision: false,
    
    // Reducers Estandáres de Desempeño
    fetchingListaEstandaresFiltro: false,
    listaEstandaresFiltro: [],
    listaEstandaresFiltroActivos: [],

    fetchingListaEstandaresDeDesempeño: false,
    listaEstandaresDeDesempeño: [],

    fetchingListaEstandaresDeDesempeñoBusqueda: false,
    listaEstandaresDeDesempeñoBusqueda: [],
    listaEstandaresDeDesempeñoBusquedaCantidad: null,

    showModalNuevoEstandaresDeDesempeño: false,
    showModalEditarEstandaresDeDesempeño: false,
    EstandaresDeDesempeñoSeleccionado: null,

    fetchingNuevoEstandaresDeDesempeño: false,
    fetchingEditarEstandaresDeDesempeño: false,

    vistaModalEstandaresDeDesempeño: 1,
    formularioEstandaresDeDesempeño: null,
    EstandaresDeDesempeñoEditados: null,
    mostrarEspecifica: false,
    refSeleccionado: null,
    banderaTiempo: false,

    //Año mes calendario reducer
    fetchingListaMesesCalendario: false,
    listaMesesCalendario: [],
    listaMesesCalendarioCantidad: 0,

    fetchingListaAñosCalendario: false,
    listaAñosCalendario: [],

    fetchingDetalleMesCalendario: false,
    detalleMesCalendario: null,

    showModalDetalleMesCalendario: false,
    mesCalendarioSeleccionado: null,

    fetchingCrearMesCalendario: false,
    showModalCrearMesCalendario: false,

    fetchingEditarMesCalendario: false,
    showModalEditarMesCalendario: false,
    mesCalendarioEditar: null,

    fetchingAgregarPPL: false,
    showModalAgregarPPL: false,
    numPplMes: 0,
    terminado: false,
    editando: false,

    fechaAnterior: null,

    // valores pago anual reducers
    fetchingValoresPagoAnual: false,
    listaValoresPagoAnual: [],
    listaValoresPagoAnualCantidad: null,
    fetchingCrearINCP0: false,
    showModalNuevoINPC0: false,
    showModalEditarINPC0: false,
    inpc0Seleccionado: null,
    fetchingEditarINPC0: false,
    fetchingListaPARv: false,
    listaPARv: [],
    listaPARvCantidad: null,
    showModalCrearPARv: false,
    fetchingCrearPARv: false,
    listaVerificarPARv: [],
    fetchingVerificarPARv: false,

    // Periodo Fraccion Maxima de Descuento
    fetchingPeriodoFMD: false,
    listaPeriodoFMD: [],
    listaPeriodoFMDCantidad: null,
    showModalEditarPeriodoFMD: false,
    showModalCrearPeriodoFMD: false,
    fetchingCrearPeriodoFMD: false,
    fetchingEditarPeriodoFMD: false,
    cambiarVistaModalPeriodoFMD: 1,
    añosMaximoPeriodo: false,
    periodoSeleccionado: null,
    formularioPeriodo: null,

    // Administración Sistema de Cálculo de Deductivas
    showModalDetalleCalculoDeductivas: false,
    listaMesesAbiertos: [],
    fetchingListaMesesAbiertos: false,
    valorCalculoTipoDeductiva: [],
    fetchingValorCalculoTipoDeductiva: false,
    listaResumenServicio: [],
    fetchingListaResumenServicio: false,
    listaResumenServicioCantidad: null,
    fetchingDeductivaResumenExcel: false,
    listaDetalleCalculoDeductivas: [],
    fetchingListaDetalleCalculoDeductivas: false,
    listaDetalleCalculoDeductivasCantidad: null,
    fechaProyeccion: '', idMesCalendario: null, proyeccion: null, 
    cierreMes: true, procesable: false,
    fetchingDetalleDeductivaExcel: false,
    fetchingProcesarDeductiva: false,
    listaTicketsNoConciliados: [],
    fetchingListaTicktesNoConciliados: false,

    //
    listaPeriodosTodos: [],
    fetchingListaPeriodosTodos: false,
    listaPeriodosFormularioEditar: [],
    listaPeriodoParaFormulario: [],
    periodosEditados: [],

    //Niveles de Severidad
    fetchingListaNivelesSeveridadActivos: false,
    listaNivelesSeveridadActivos: [],
    listaNivelesSeveridad: null,

    fetchingListaNivelesSeveridadBusqueda: false,
    listaNivelesSeveridadBusqueda: [],
    listaNivelesSeveridadBusquedaCantidad: 0,

    showModalNuevoNivelSeveridad: false,
    fetchingNuevoNivelSeveridad: false,

    showModalEditarNivelSeveridad: false,
    fetchingEditarNivelSeveridad: false,
    nivelSeveridadSeleccionado: null,
    
    vistaModalNivelSeveridad: 1,
    formularioNivelSeveridad: null,
    nivelesEditados: null,

    //CATALOGO NIVELES TIEMPO RESPUESTA POR SECCION
    fetchingListaNivelesTiempoRespuestaBusqueda: false,
    listaNivelesTiempoRespuestaBusqueda: [],
    listaNivelesTiempoRespuestaBusquedaCantidad: null,
    fetchingListaNivelesTiempoRespuestaActivos: false,
    listaNivelesTiempoRespuestaActivos: [],

    showModalNuevoNivelTiempoRespuesta: false,
    showModalEditarNivelTiempoRespuesta: false,
    nivelTiempoRespuestaSeleccionado: null,
    nivelTiempoRespuestaAnterior:[],
    fetchingNivelTiempoRespuestaAnterior: false,
    
    fetchingNuevoNivelTiempoRespuesta: false,
    fetchingEditarNivelTiempoRespuesta: false,
    vistaModalNivelTiempoRespuesta: 1,
    formularioNivelTiempoRespuesta: null,
    
    fetchingListaNivelesTiempoRespuestaEdicion: false,
    listaNivelesTiempoRespuestaEdicion: [],
    listaFiltradaEditadosNTR: [],
    listaValoresInicialesNTR: {},

    // Número de Documento por Reiteración de Fallas
    showModalNuevoNumeroDocumento: false,
    fetchingListaDocumentoReiteradoUltimos: false,
    listaDocumentosReiteradoUltimosWitoutSearch: [],
    fetchingListaDocumentoReiteradoUltimosWithout: false,
    listaDocumentosReiteradoUltimos: [],
    fetchingListaTicketsReiterados: false,
    listaTicketsReiterados: [],
    listaTicketsReiteradosCantidad: null,
    showModalBusquedaTicketReiterado: false,
    showModalBusquedaTicketOrigen: false, idSolicitudServicioReiterada: null,
    showModalDetalleTicketReiterado: false, ticketReiteradoSeleccionado: [],
    showModalDetalleTicketOrigen: false, ticketOrigenSeleccionado: [], idSolicitudServicioOrigen: null,
    showModalBusquedaCodigoInterno: false, codigoInterno: null,
    listaTicketsOrigen: [], fetchingListaTicketsOrigen: false, listaTicketsOrigenCantidad: null,
    documentoRelacionado: [], fetchingDocumentoRelacionado: false, reiteracion: null,
    documentoRelacionadoByNumero: [], fetchingDocumentoRelacionadoByNumero: false,
    fetchingCrearDocumentoReiteracion: false,
    ticketsReiteradosByDocumento: [], fetchingTicketsReiteradosByDocumento: false,
    fetchingEliminarNumeroDocumento: false,
    fetchingListaActivosByCodigoInterno: false, listaActivosByCodigoInterno: [], ticketOrigenSeleccionadoMODAL: [],

    //Cálculo de deductivas por Reiteración para el mes i 
    showModalCalculoDeductivasReiteracion: false,
    mesSeleccionado: [],
    mesAnterior: null,

    // conciliacion
    fetchingBusquedaTicketSS: false,
    busquedaTicketSS: [],
    fetchingFallasPorTipoFalla: false,
    fallasPorTipoFalla: [],
    fetchingNivelesSeveridadPorFalla: false,
    listaNivelesSeveridadPorFalla: [],
    busquedaTicketSSobj: {},
    fetchingSeccionesPorServicio: false,
    seccionesPorServicio: [],
    fetchingFallasPorServicio: false,
    fallasPorServicio: [],
    nivelSeveridad: undefined,
    habilitarSeveridad: undefined,

    //Editar conciliacion
    fetchingBusquedaFolioConciliado: false,
    busquedaFolioConciliado: [],
    busquedaFolioConciliadoobj: {},
    fetchingEditarFolioConciliado: false,

    reporteResumenCalculoDR: [], fetchingReporteResumenCalculoDR: false, reporteResumenCalculoDRCantidad: null,
    fetchingExportarExcelCDR: false,
    fetchingListaDetalleCalculoReiteracion: false, listaDetalleCalculoReiteracion: [], listaDetalleCalculoReiteracionCantidad: null,
    fetchingExportarExcelDetalleCDR: false, fetchingProcesarDeductivaReiteracion: false,


    //Folio Conciliación
    fetchingFoliosConciliacion:false,
    listaFoliosConciliacion:[],
    listaFolicosConciliacionCantidad: null,

    fetchingMesFolioConciliacion: false,
    listaMesesFolioConciliacion: [],

    fetchingServiciosFC: false,
    listaServiciosFC: [],

    fetchingSSByServicio: false,
    listaSSByServicio: [],
    listaSSByServicioCantidad: null,
    listaSSCantidad: null,

    fetchingSSByFolio: false,
    listaSSByFolio: [],
    listaSSByFolioCantidad: null,

    fetchingNuevoFolioConciliacion: false,
    fetchinEliminarTicketFC: false,
    fetchingEditarFolioConciliacion: false,
    fetchingCerrarFolioConciliacion: false, 
    fetchingImprimirFolioConciliacion:false,

    showModalNuevoFolioConciliacion: false,
    showModalEditarFolioConciliacion: false,
    showModalCerrarFolioConciliacion: false,
    folioConciliacionSeleccionado: null,

    // Folio por Errores u Omisiones
    fetchingFoliosErroresOmisiones: false,
    listaFoliosErroresOmisiones: [],
    listaFoliosErroresOmisionesCantidad: null,

    fetchingTicketSSConciliado: false,
    ticketSSConciliado: [],

    fetchingDocumentosFiscalizacion: false,
    listaDocumentosFiscalizacion: [],

    fetchingNuevoFolioEO : false,
    fetchingEditarFolioEO: false,
    fetchingDetalleFolioEO: false,
    fetchingCerrarFolioEO: false,

    showModalNuevoFolioEO : false,
    showModalEditarFolioEO : false,
    showModalDetalleFolioEO: false,
    showModalCerrarFolioEO: false,
    folioErrorOmisionSeleccionado: null,
    
    // CU - Cálculo de deductivas por Errores u Omisiones
    showModalDetalleCalculoError: false,
    fetchingReporteResumenCalculoEO: false,
    reporteResumenCalculoEO: [],
    reporteResumenCalculoEOCantidad: null,
    fetchingExportarExcelCEO: false,
    fetchingListaDetalleCalculoError: false,
    listaDetalleCalculoDEO: [],
    listaDetalleCalculoDEOCantidad: null,
    fetchingExportarExcelDetalleCDEO: false,
    fetchingProcesarDeductivaError: false,
    ultimoDiaAllPPLs: false,

    // CU - Pago por Servicios
    fetchingListaPagoServiciosMeses: false,
    listaPagoServiciosMeses: [],
    fetchingCalcularPagoServicio: false,
    calculoPagoServicio: null,
    visibleCalculoPagoServicio: false,
    fetchingGuardarCalculoPagoServicio: false,
    fetchingPagoServicioDetalle: false, 
    detallePagoServicio: null,
    dtfsMayor: true,
    fetchingCerrarPagoServicio: false,

    // CU - Pago de servicios públicos
    fetchingListaServiciosPublicosMes: false,
    listaServiciosPublicosMes: [],
    listaServiciosPublicosMesCantidad: null,
    fetchingListaServiciosPublicosGenerales: false,
    listaServiciosPublicosGenerales: [],
    listaServiciosPublicosGeneralesCantidad: null,
    fetchingExportarServiciosPublicosPDF: false,

    //RDO | Monitor de Deductivas
    fetchingListaMesesProcesados: false,
    listaMesesProcesados: [],
    fetchingListaRDODeductivasInternas: false,
    listaRDODeductivasInternas: [],
    infoRDO_Internas: {},
    fetchingListaRDODeductivasExternas: false,
    listaRDODeductivasExternas: [],
    infoRDO_Externas: {},
    fetchingDetalleDeductivaRDO: false,
    detalleDeductivaRDO: [],
    fetchingDetalleDeductivaRDOExcel: false,
    fetchingDeductivaByServicio: false,
    deductivaByServicio: [],
    infoRDO_DeductivaByServicio: {},
    fetchingDeductivasAnterioresRDO: false,
    deductivasAnterioresRDO: [],
    infoRDO_DeductivasAnteriores: {},

    //  Reporte de Deductivas 
    resumenReporteDeductivas: [],
    fetchingResumenReporteDeductivas: false,
    fetchingResumenReporteDeductivasExcel: false,
    detalleResumenReporteDeductivas: [],
    fetchingDetalleResumenReporteDeductivas: false,
    detalleResumenReporteDeductivasCantidad: null,
    fetchingDetalleResumenReporteDeductivasExcel: false,

    concentradoAnualResumen: [],
    fetchingConcentradoAnualResumen: false,
    concentrado: [],

    detalleConcentradoAnual: [],
    fetchingDetalleConcentradoAnual: false,
    fetchingExcelDetalleConcentradoAnual: false,
    fetchingExcelResumenConcentradoAnual: false,
    detalleConcentradoAnualCantidad: null,

    fetchingDetalleResumenReporteDeductivasPDF: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case MecanismosDePagoActions.CAMBIAR_CATALOGO_MECANISMOS_PAGOS:
            return { ...state, catalogoMecanismosDePagos: action.catalogoMecanismosDePagos };

        // Tiempo de garantia
        case MecanismosDePagoActions.LISTA_SERVICIOS_FILTRO_REQUEST:
            return { ...state, fetchingListaServiciosFiltro: true };
        case MecanismosDePagoActions.LISTA_SERVICIOS_FILTRO_SUCCESS:
            return { ...state, fetchingListaServiciosFiltro: false, listaServiciosFiltro: action.listaServiciosFiltro };
        case MecanismosDePagoActions.LISTA_SERVICIOS_FILTRO_FAILURE:
            return { ...state, fetchingListaServiciosFiltro: false };

        case MecanismosDePagoActions.LISTA_TIEMPODEGARANTIA_TODOS_REQUEST:
            return { ...state, fetchingListaTiemposTodos: true };
        case MecanismosDePagoActions.LISTA_TIEMPODEGARANTIA_TODOS_SUCCESS:
            return { ...state, fetchingListaTiemposTodos: false, listaTiemposTodos: action.listaTiemposTodos };
        case MecanismosDePagoActions.LISTA_TIEMPODEGARANTIA_TODOS_FAILURE:
            return { ...state, fetchingListaTiemposTodos: false };

         case MecanismosDePagoActions.LISTA_TIEMPODEGARANTIA_FORM_REQUEST:
            return { ...state, fetchingListaTiemposGarantia: true };
        case MecanismosDePagoActions.LISTA_TIEMPODEGARANTIA_FORM_SUCCESS:
            return { ...state, fetchingListaTiemposTodos: false, listaTiemposDeGarantia: action.listaTiemposDeGarantia, listaTiemposDeGar: action.listaTiemposDeGar };
        case MecanismosDePagoActions.LISTA_TIEMPODEGARANTIA_FORM_FAILURE:
            return { ...state, fetchingListaTiemposTodos: false };

        case MecanismosDePagoActions.LISTA_TIEMPODEGARANTIA_REQUEST:
            return { ...state, fetchingListaTiempoDeGarantia: true };
        case MecanismosDePagoActions.LISTA_TIEMPODEGARANTIA_SUCCESS:
            return { ...state, fetchingListaTiempoDeGarantia: false, listaTiempoDeGarantia: action.listaTiempoDeGarantia };
        case MecanismosDePagoActions.LISTA_TIEMPODEGARANTIA_FAILURE:
            return { ...state, fetchingListaTiempoDeGarantia: false };

        case MecanismosDePagoActions.LISTA_TIEMPODEGARANTIA_BUSQUEDA_REQUEST:
            return { ...state, fetchingListaTiempoDeGarantiaBusqueda: true };
        case MecanismosDePagoActions.LISTA_TIEMPODEGARANTIA_BUSQUEDA_SUCCESS:
            return { ...state, fetchingListaTiempoDeGarantiaBusqueda: false, listaTiempoDeGarantiaBusqueda: action.listaTiempoDeGarantiaBusqueda, listaTiempoDeGarantiaBusquedaCantidad: action.listaTiempoDeGarantiaBusquedaCantidad };
        case MecanismosDePagoActions.LISTA_TIEMPODEGARANTIA_BUSQUEDA_FAILURE:
            return { ...state, fetchingListaTiempoDeGarantiaBusqueda: false };

        case MecanismosDePagoActions.ESTADO_TIEMPODEGARANTIA_REQUEST:
            return { ...state, fetchingListaTiempoDeGarantiaBusqueda: true };
        case MecanismosDePagoActions.ESTADO_TIEMPODEGARANTIA_SUCCESS:
            return { ...state, fetchingListaTiempoDeGarantiaBusqueda: false };
        case MecanismosDePagoActions.ESTADO_TIEMPODEGARANTIA_FAILURE:
            return { ...state, fetchingListaTiempoDeGarantiaBusqueda: false };

        case MecanismosDePagoActions.SHOW_MODAL_NUEVO_TIEMPODEGARANTIA:
            return { ...state, showModalNuevoTiempoDeGarantia: action.showModalNuevoTiempoDeGarantia, page: action.page };

        case MecanismosDePagoActions.SHOW_MODAL_EDITAR_TIEMPODEGARANTIA:
            return { ...state, showModalEditarTiempoDeGarantia: action.showModalEditarTiempoDeGarantia, tiempoDeGarantiaSeleccionado: action.tiempoDeGarantiaSeleccionado, page: action.page };

        case MecanismosDePagoActions.NUEVO_TIEMPODEGARANTIA_REQUEST:
            return { ...state, fetchingNuevoTiempoDeGarantia: true };
        case MecanismosDePagoActions.NUEVO_TIEMPODEGARANTIA_SUCCESS:
            return { ...state, fetchingNuevoTiempoDeGarantia: false };
        case MecanismosDePagoActions.NUEVO_TIEMPODEGARANTIA_FAILURE:
            return { ...state, fetchingNuevoTiempoDeGarantia: false };

        case MecanismosDePagoActions.EDITAR_TIEMPODEGARANTIA_REQUEST:
            return { ...state, fetchingEditarTiempoDeGarantia: true };
        case MecanismosDePagoActions.EDITAR_TIEMPODEGARANTIA_SUCCESS:
            return { ...state, fetchingEditarTiempoDeGarantia: false };
        case MecanismosDePagoActions.EDITAR_TIEMPODEGARANTIA_FAILURE:
            return { ...state, fetchingEditarTiempoDeGarantia: false };

        case MecanismosDePagoActions.CAMBIAR_VISTA_TIEMPODEGARANTIA:
            return { ...state, vistaModalTiempoDeGarantia: action.vistaModalTiempoDeGarantia, formularioGarantia: action.formularioGarantia, tiemposEditados: action.tiemposEditados }

        // FALLAS DE DISPONIBILIDAD
        case MecanismosDePagoActions.LISTA_FALLA_DISPONIBILIDAD_REQUEST:
            return { ...state, fetchingListaFallasDeDisponibilidad: true };
        case MecanismosDePagoActions.LISTA_FALLA_DISPONIBILIDAD_SUCCESS:
            return { ...state, fetchingListaFallasDeDisponibilidad: false, listaFallasDeDisponibilidad: action.listaFallasDeDisponibilidad };
        case MecanismosDePagoActions.LISTA_FALLA_DISPONIBILIDAD_FAILURE:
            return { ...state, fetchingListaFallasDeDisponibilidad: false };

        case MecanismosDePagoActions.FALLA_DISPONIBILIDAD_BUSQUEDA_REQUEST:
            return { ...state, fetchingListaFallasDeDisponibilidadBusqueda: true };
        case MecanismosDePagoActions.FALLA_DISPONIBILIDAD_BUSQUEDA_SUCCESS:
            return { ...state, fetchingListaFallasDeDisponibilidadBusqueda: false, listaFallasDeDisponibilidadBusqueda: action.listaFallasDeDisponibilidadBusqueda, listaFallasDeDisponibilidadBusquedaCantidad: action.listaFallasDeDisponibilidadBusquedaCantidad };
        case MecanismosDePagoActions.FALLA_DISPONIBILIDAD_BUSQUEDA_FAILURE:
            return { ...state, fetchingListaFallasDeDisponibilidadBusqueda: false };

        case MecanismosDePagoActions.ESTADO_FALLA_DISPONIBILIDAD_REQUEST:
            return { ...state, fetchingListaFallasDeDisponibilidadBusqueda: true };
        case MecanismosDePagoActions.ESTADO_FALLA_DISPONIBILIDAD_SUCCESS:
            return { ...state, fetchingListaFallasDeDisponibilidadBusqueda: false };
        case MecanismosDePagoActions.ESTADO_FALLA_DISPONIBILIDAD_FAILURE:
            return { ...state, fetchingListaFallasDeDisponibilidadBusqueda: false };

        case MecanismosDePagoActions.SHOW_MODAL_NUEVO_FALLA_DISPONIBILIDAD:
            return { ...state, showModalNuevoFallasDeDisponibilidad: action.showModalNuevoFallasDeDisponibilidad, page: action.page };

        case MecanismosDePagoActions.SHOW_MODAL_EDITAR_FALLA_DISPONIBILIDAD:
            return { ...state, showModalEditarFallasDeDisponibilidad: action.showModalEditarFallasDeDisponibilidad, FallasDeDisponibilidadSeleccionado: action.FallasDeDisponibilidadSeleccionado, page: action.page };

        case MecanismosDePagoActions.NUEVO_FALLA_DISPONIBILIDAD_REQUEST:
            return { ...state, fetchingNuevoFallasDeDisponibilidad: true };
        case MecanismosDePagoActions.NUEVO_FALLA_DISPONIBILIDAD_SUCCESS:
            return { ...state, fetchingNuevoFallasDeDisponibilidad: false };
        case MecanismosDePagoActions.NUEVO_FALLA_DISPONIBILIDAD_FAILURE:
            return { ...state, fetchingNuevoFallasDeDisponibilidad: false };

        case MecanismosDePagoActions.EDITAR_FALLA_DISPONIBILIDAD_REQUEST:
            return { ...state, fetchingEditarFallasDeDisponibilidad: true };
        case MecanismosDePagoActions.EDITAR_FALLA_DISPONIBILIDAD_SUCCESS:
            return { ...state, fetchingEditarFallasDeDisponibilidad: false };
        case MecanismosDePagoActions.EDITAR_FALLA_DISPONIBILIDAD_FAILURE:
            return { ...state, fetchingEditarFallasDeDisponibilidad: false };

        case MecanismosDePagoActions.CAMBIAR_VISTA_FALLA_DISPONIBILIDAD:
            return { ...state, vistaModalFallaDisponiblidad: action.vistaModalFallaDisponiblidad, formularioDisponibilidad: action.formularioDisponibilidad, fallaDisponibilidadEditados: action.fallaDisponibilidadEditados, listaFiltradaEditados: action.listaFiltradaEditados };

        case MecanismosDePagoActions.LISTA_CATEGORIAS_DISPONIBILIDAD_REQUEST:
            return { ...state, fetchingListaCategoriasDisponibilidad: true };
        case MecanismosDePagoActions.LISTA_CATEGORIAS_DISPONIBILIDAD_SUCCESS:
            return { ...state, fetchingListaCategoriasDisponibilidad: false, listaCategoriasDisponibilidad: action.listaCategoriasDisponibilidad, listaCatDisponibilidad: action.listaCatDisponibilidad };
        case MecanismosDePagoActions.LISTA_CATEGORIAS_DISPONIBILIDAD_FAILURE:
            return { ...state, fetchingListaCategoriasDisponibilidad: false };

        //CÁTALOG CATEGORIAS FALLAS DE CALIDAD
        case MecanismosDePagoActions.LISTA_CATEGORIAS_FALLAS_CALIDAD_ACTIVOS_REQUEST:
            return { ...state, fetchingListaCategoriasFallasDeCalidadActivos: true };
        case MecanismosDePagoActions.LISTA_CATEGORIAS_FALLAS_CALIDAD_ACTIVOS_SUCCESS:
            return { ...state, fetchingListaCategoriasFallasDeCalidadActivos: false, listaCategoriasFallasDeCalidadActivos: action.listaCategoriasFallasDeCalidadActivos };
        case MecanismosDePagoActions.LISTA_CATEGORIAS_FALLAS_CALIDAD_ACTIVOS_FAILURE:
            return { ...state, fetchingListaCategoriasFallasDeCalidadActivos: false };

        case MecanismosDePagoActions.LISTA_CATEGORIAS_FALLAS_CALIDAD_EDICION_REQUEST:
            return { ...state, fetchingListaFallasDeCalidadEdicion: true };
        case MecanismosDePagoActions.LISTA_CATEGORIAS_FALLAS_CALIDAD_EDICION_SUCCESS:
            return { ...state, fetchingListaFallasDeCalidadEdicion: false, listaFallasDeCalidadEdicion: action.listaFallasDeCalidadEdicion, listaValoresIniciales: action.listaValoresIniciales };
        case MecanismosDePagoActions.LISTA_CATEGORIAS_FALLAS_CALIDAD_EDICION_FAILURE:
            return { ...state, fetchingListaFallasDeCalidadEdicion: false };

        case MecanismosDePagoActions.CATEGORIAS_FALLAS_CALIDAD_BUSQUEDA_REQUEST:
            return { ...state, fetchingListaCategoriasFallasDeCalidadBusqueda: true };
        case MecanismosDePagoActions.CATEGORIAS_FALLAS_CALIDAD_BUSQUEDA_SUCCESS:
            return { ...state, fetchingListaCategoriasFallasDeCalidadBusqueda: false, listaCategoriasFallasDeCalidadBusqueda: action.listaCategoriasFallasDeCalidadBusqueda, listaCategoriasFallasDeCalidadBusquedaCantidad: action.listaCategoriasFallasDeCalidadBusquedaCantidad };
        case MecanismosDePagoActions.CATEGORIAS_FALLAS_CALIDAD_BUSQUEDA_FAILURE:
            return { ...state, fetchingListaCategoriasFallasDeCalidadBusqueda: false };

        case MecanismosDePagoActions.ESTADO_CATEGORIA_FALLA_CALIDAD_REQUEST:
            return { ...state, fetchingListaCategoriasFallasDeCalidadBusqueda: true };
        case MecanismosDePagoActions.ESTADO_CATEGORIA_FALLA_CALIDAD_SUCCESS:
            return { ...state, fetchingListaCategoriasFallasDeCalidadBusqueda: false };
        case MecanismosDePagoActions.ESTADO_CATEGORIA_FALLA_CALIDAD_FAILURE:
            return { ...state, fetchingListaCategoriasFallasDeCalidadBusqueda: false };

        case MecanismosDePagoActions.SHOW_MODAL_NUEVA_CATEGORIA_FALLA_CALIDAD:
            return { ...state, showModalNuevaCategoriaFallaDeCalidad: action.showModalNuevaCategoriaFallaDeCalidad, page: action.page };

        case MecanismosDePagoActions.SHOW_MODAL_EDITAR_CATEGORIA_FALLA_CALIDAD:
            return { ...state, showModalEditarCategoriaFallaDeCalidad: action.showModalEditarCategoriaFallaDeCalidad, CategoriaFallaDeCalidadSeleccionado: action.CategoriaFallaDeCalidadSeleccionado, page: action.page };

        case MecanismosDePagoActions.NUEVA_CATEGORIA_FALLA_CALIDAD_REQUEST:
            return { ...state, fetchingNuevaCategoriaFallaDeCalidad: true };
        case MecanismosDePagoActions.NUEVA_CATEGORIA_FALLA_CALIDAD_SUCCESS:
            return { ...state, fetchingNuevaCategoriaFallaDeCalidad: false };
        case MecanismosDePagoActions.NUEVA_CATEGORIA_FALLA_CALIDAD_FAILURE:
            return { ...state, fetchingNuevaCategoriaFallaDeCalidad: false };

        case MecanismosDePagoActions.EDITAR_CATEGORIA_FALLA_CALIDAD_REQUEST:
            return { ...state, fetchingEditarCategoriaFallaDeCalidad: true };
        case MecanismosDePagoActions.EDITAR_CATEGORIA_FALLA_CALIDAD_SUCCESS:
            return { ...state, fetchingEditarCategoriaFallaDeCalidad: false };
        case MecanismosDePagoActions.EDITAR_CATEGORIA_FALLA_CALIDAD_FAILURE:
            return { ...state, fetchingEditarCategoriaFallaDeCalidad: false };

        case MecanismosDePagoActions.CAMBIAR_VISTA_FALLA_CALIDAD:
            return { ...state, vistaModalFallaCalidad: action.vistaModalFallaCalidad, formularioFallaCalidad: action.formularioFallaCalidad, listaFiltradaEditados: action.listaFiltradaEditados };


        //CÁTALOGO SERVICIOS Y SECCIONES ESTÁNDARES DESEMPEÑO ESPECÍFICOS
        case MecanismosDePagoActions.LISTA_ESTANDARES_DESEMPENO_ESPECIFICOS_ACTIVOS_REQUEST:
            return { ...state, fetchingListaEstandaresDesempenoEspecificosActivos: true };
        case MecanismosDePagoActions.LISTA_ESTANDARES_DESEMPENO_ESPECIFICOS_ACTIVOS_SUCCESS:
            return { ...state, fetchingListaEstandaresDesempenoEspecificosActivos: false, listaEstandaresDesempenoEspecificosActivos: action.listaEstandaresDesempenoEspecificosActivos };
        case MecanismosDePagoActions.LISTA_ESTANDARES_DESEMPENO_ESPECIFICOS_ACTIVOS_FAILURE:
            return { ...state, fetchingListaEstandaresDesempenoEspecificosActivos: false };

        case MecanismosDePagoActions.ESTANDARES_DESEMPENO_ESPECIFICOS_BUSQUEDA_REQUEST:
            return { ...state, fetchingListaEstandaresDesempenoEspecificosBusqueda: true };
        case MecanismosDePagoActions.ESTANDARES_DESEMPENO_ESPECIFICOS_BUSQUEDA_SUCCESS:
            return { ...state, fetchingListaEstandaresDesempenoEspecificosBusqueda: false, listaEstandaresDesempenoEspecificosBusqueda: action.listaEstandaresDesempenoEspecificosBusqueda, listaEstandaresDesempenoEspecificosBusquedaCantidad: action.listaEstandaresDesempenoEspecificosBusquedaCantidad };
        case MecanismosDePagoActions.ESTANDARES_DESEMPENO_ESPECIFICOS_BUSQUEDA_FAILURE:
            return { ...state, fetchingListaEstandaresDesempenoEspecificosBusqueda: false };

        case MecanismosDePagoActions.ESTADO_ESTANDAR_DESEMPENO_ESPECIFICO_REQUEST:
            return { ...state, fetchingListaEstandaresDesempenoEspecificosBusqueda: true };
        case MecanismosDePagoActions.ESTADO_ESTANDAR_DESEMPENO_ESPECIFICO_SUCCESS:
            return { ...state, fetchingListaEstandaresDesempenoEspecificosBusqueda: false };
        case MecanismosDePagoActions.ESTADO_ESTANDAR_DESEMPENO_ESPECIFICO_FAILURE:
            return { ...state, fetchingListaEstandaresDesempenoEspecificosBusqueda: false };

        case MecanismosDePagoActions.SHOW_MODAL_NUEVO_ESTANDAR_DESEMPENO_ESPECIFICO:
            return { ...state, showModalNuevoEstandarDesempenoEspecifico: action.showModalNuevoEstandarDesempenoEspecifico, page: action.page };

        case MecanismosDePagoActions.SHOW_MODAL_EDITAR_ESTANDAR_DESEMPENO_ESPECIFICO:
            return { ...state, showModalEditarEstandarDesempenoEspecifico: action.showModalEditarEstandarDesempenoEspecifico, EstandarDesempenoEspecificoSeleccionado: action.EstandarDesempenoEspecificoSeleccionado, page: action.page };

        case MecanismosDePagoActions.NUEVO_ESTANDAR_DESEMPENO_ESPECIFICO_REQUEST:
            return { ...state, fetchingNuevoEstandarDesempenoEspecifico: true };
        case MecanismosDePagoActions.NUEVO_ESTANDAR_DESEMPENO_ESPECIFICO_SUCCESS:
            return { ...state, fetchingNuevoEstandarDesempenoEspecifico: false };
        case MecanismosDePagoActions.NUEVO_ESTANDAR_DESEMPENO_ESPECIFICO_FAILURE:
            return { ...state, fetchingNuevoEstandarDesempenoEspecifico: false };

        case MecanismosDePagoActions.EDITAR_ESTANDAR_DESEMPENO_ESPECIFICO_REQUEST:
            return { ...state, fetchingEditarEstandarDesempenoEspecifico: true };
        case MecanismosDePagoActions.EDITAR_ESTANDAR_DESEMPENO_ESPECIFICO_SUCCESS:
            return { ...state, fetchingEditarEstandarDesempenoEspecifico: false };
        case MecanismosDePagoActions.EDITAR_ESTANDAR_DESEMPENO_ESPECIFICO_FAILURE:
            return { ...state, fetchingEditarEstandarDesempenoEspecifico: false };
            
        // reducer ponderacion servcios por area
        case MecanismosDePagoActions.LISTA_PONDERACION_SERV_POR_AREA_BUSQUEDA_REQUEST:
            return { ...state, fetchingListaPonderacionServPorAreaBusqueda: true };
        case MecanismosDePagoActions.LISTA_PONDERACION_SERV_POR_AREA_BUSQUEDA_SUCCESS:
            return { ...state, fetchingListaPonderacionServPorAreaBusqueda: false, listaPonderacionServPorAreaBusqueda: action.listaPonderacionServPorAreaBusqueda, listaPonderacionServPorAreaBusquedaCantidad: action.listaPonderacionServPorAreaBusquedaCantidad };
        case MecanismosDePagoActions.LISTA_PONDERACION_SERV_POR_AREA_BUSQUEDA_FAILURE:
            return { ...state, fetchingListaPonderacionServPorAreaBusqueda: false };

        case MecanismosDePagoActions.LISTA_AREAS_POR_SERVICIO_REQUEST:
            return { ...state, fetchingListaAreasPorServicio: true };
        case MecanismosDePagoActions.LISTA_AREAS_POR_SERVICIO_SUCCESS:
            return { ...state, fetchingListaAreasPorServicio: false, listaAreasPorServicio: action.listaAreasPorServicio, listaPonderacionServicioPorArea: action.listaPonderacionServicioPorArea };
        case MecanismosDePagoActions.LISTA_AREAS_POR_SERVICIO_FAILURE:
            return { ...state, fetchingListaAreasPorServicio: false };

        case MecanismosDePagoActions.ESTADO_PONDERACION_SERV_POR_AREA_REQUEST:
            return { ...state, fetchingListaPonderacionServPorAreaBusqueda: true };
        case MecanismosDePagoActions.ESTADO_PONDERACION_SERV_POR_AREA_SUCCESS:
            return { ...state, fetchingListaPonderacionServPorAreaBusqueda: false };
        case MecanismosDePagoActions.ESTADO_PONDERACION_SERV_POR_AREA_FAILURE:
            return { ...state, fetchingListaPonderacionServPorAreaBusqueda: false };

        case MecanismosDePagoActions.SHOW_MODAL_NUEVO_PONDERACION_SERV_POR_AREA:
            return { ...state, showModalNuevoPonderacionServPorArea: action.showModalNuevoPonderacionServPorArea, page: action.page };

        case MecanismosDePagoActions.SHOW_MODAL_EDITAR_PONDERACION_SERV_POR_AREA:
            return { ...state, showModalEditarPonderacionServPorArea: action.showModalEditarPonderacionServPorArea, ponderacionServPorAreaSeleccionado: action.ponderacionServPorAreaSeleccionado, page: action.page };

        case MecanismosDePagoActions.NUEVO_PONDERACION_SERV_POR_AREA_REQUEST:
            return { ...state, fetchingNuevoPonderacionServPorArea: true };
        case MecanismosDePagoActions.NUEVO_PONDERACION_SERV_POR_AREA_SUCCESS:
            return { ...state, fetchingNuevoPonderacionServPorArea: false };
        case MecanismosDePagoActions.NUEVO_PONDERACION_SERV_POR_AREA_FAILURE:
            return { ...state, fetchingNuevoPonderacionServPorArea: false };

        case MecanismosDePagoActions.EDITAR_PONDERACION_SERV_POR_AREA_REQUEST:
            return { ...state, fetchingEditarPonderacionServPorArea: true };
        case MecanismosDePagoActions.EDITAR_PONDERACION_SERV_POR_AREA_SUCCESS:
            return { ...state, fetchingEditarPonderacionServPorArea: false };
        case MecanismosDePagoActions.EDITAR_TIEMPODEGARANTIA_FAILURE:
            return { ...state, fetchingEditarPonderacionServPorArea: false };

        case MecanismosDePagoActions.LIMPIAR_LISTA_PONDERADORES_SERVICIO_AREA:
            return { ...state, listaPonderacionServicioPorArea: [] }

        case MecanismosDePagoActions.CAMBIAR_VISTA_PONDERACION_SERV_POR_AREA:
            return { ...state, vistaModalPonderacionServPorArea: action.vistaModalPonderacionServPorArea, formularioPonderacionServ: action.formularioPonderacionServ, ponderacionEditados: action.ponderacionEditados, listaAreasPorServicio: action.listaAreasPorServicio }

        // Catálogo de Referencias de Estandares de Desempeño
        case MecanismosDePagoActions.LISTA_REFERENCIAS_ESTANDARES_ACTIVOS_REQUEST:
            return { ...state, fetchingListaReferenciasDeEstandaresActivos: true };
        case MecanismosDePagoActions.LISTA_REFERENCIAS_ESTANDARES_ACTIVOS_SUCCESS:
            return { ...state, fetchingListaReferenciasDeEstandaresActivos: false, listaReferenciasDeEstandaresActivos: action.listaReferenciasDeEstandaresActivos };
        case MecanismosDePagoActions.LISTA_REFERENCIAS_ESTANDARES_ACTIVOS_FAILURE:
            return { ...state, fetchingListaReferenciasDeEstandaresActivos: false };

        case MecanismosDePagoActions.REFERENCIAS_ESTANDARES_BUSQUEDA_REQUEST:
            return { ...state, fetchingListaReferenciasDeEstandaresBusqueda: true };
        case MecanismosDePagoActions.REFERENCIAS_ESTANDARES_BUSQUEDA_SUCCESS:
            return { ...state, fetchingListaReferenciasDeEstandaresBusqueda: false, listaReferenciasDeEstandaresBusqueda: action.listaReferenciasDeEstandaresBusqueda, listaReferenciasDeEstandaresBusquedaCantidad: action.listaReferenciasDeEstandaresBusquedaCantidad };
        case MecanismosDePagoActions.REFERENCIAS_ESTANDARES_BUSQUEDA_FAILURE:
            return { ...state, fetchingListaReferenciasDeEstandaresBusqueda: false, mostrarEspecifica: action.mostrarEspecifica };

        case MecanismosDePagoActions.ESTADO_REFERENCIA_ESTANDAR_REQUEST:
            return { ...state, fetchingListaReferenciasDeEstandaresBusqueda: true };
        case MecanismosDePagoActions.ESTADO_REFERENCIA_ESTANDAR_SUCCESS:
            return { ...state, fetchingListaReferenciasDeEstandaresBusqueda: false };
        case MecanismosDePagoActions.ESTADO_REFERENCIA_ESTANDAR_FAILURE:
            return { ...state, fetchingListaReferenciasDeEstandaresBusqueda: false };

        case MecanismosDePagoActions.SHOW_MODAL_NUEVA_REFERENCIA_ESTANDAR:
            return { ...state, showModalNuevaReferenciaEstandar: action.showModalNuevaReferenciaEstandar, page: action.page };

        case MecanismosDePagoActions.SHOW_MODAL_EDITAR_REFERENCIA_ESTANDAR:
            return { ...state, showModalEditarReferenciaEstandar: action.showModalEditarReferenciaEstandar, referenciaEstandarSeleccionada: action.referenciaEstandarSeleccionada, page: action.page, busqueda: action.busqueda };

        case MecanismosDePagoActions.NUEVA_REFERENCIA_ESTANDAR_REQUEST:
            return { ...state, fetchingNuevaReferenciaEstandar: true };
        case MecanismosDePagoActions.NUEVA_REFERENCIA_ESTANDAR_SUCCESS:
            return { ...state, fetchingNuevaReferenciaEstandar: false };
        case MecanismosDePagoActions.NUEVA_REFERENCIA_ESTANDAR_FAILURE:
            return { ...state, fetchingNuevaReferenciaEstandar: false };

        case MecanismosDePagoActions.EDITAR_REFERENCIA_ESTANDAR_REQUEST:
            return { ...state, fetchingEditarRerenciaEstandar: true };
        case MecanismosDePagoActions.EDITAR_REFERENCIA_ESTANDAR_SUCCESS:
            return { ...state, fetchingEditarRerenciaEstandar: false };
        case MecanismosDePagoActions.EDITAR_REFERENCIA_ESTANDAR_FAILURE:
            return { ...state, fetchingEditarRerenciaEstandar: false };
        
        //CATALOGO CLAVES DE MÉTODOS DE SUPERVISIÓN
        
        case MecanismosDePagoActions.LISTA_CLAVES_METODOS_SUPERVISION_ACTIVOS_REQUEST:
            return { ...state, fetchingListaClavesMetodosSupervisionActivos: true };
        case MecanismosDePagoActions.LISTA_CLAVES_METODOS_SUPERVISION_ACTIVOS_SUCCESS:
            return { ...state, fetchingListaClavesMetodosSupervisionActivos: false, listaClavesMetodosSupervisionActivos: action.listaClavesMetodosSupervisionActivos, listaClavesMetodosSupervisionExternos: action.listaClavesMetodosSupervisionExternos };
        case MecanismosDePagoActions.LISTA_CLAVES_METODOS_SUPERVISION_ACTIVOS_FAILURE:
            return { ...state, fetchingListaClavesMetodosSupervisionActivos: false };

        case MecanismosDePagoActions.CLAVES_METODOS_SUPERVISION_BUSQUEDA_REQUEST:
            return { ...state, fetchingListaClavesMetodosSupervisionBusqueda: true };
        case MecanismosDePagoActions.CLAVES_METODOS_SUPERVISION_BUSQUEDA_SUCCESS:
            return { ...state, fetchingListaClavesMetodosSupervisionBusqueda: false, listaClavesMetodosSupervisionBusqueda: action.listaClavesMetodosSupervisionBusqueda, listaClavesMetodosSupervisionBusquedaCantidad: action.listaClavesMetodosSupervisionBusquedaCantidad };
        case MecanismosDePagoActions.CLAVES_METODOS_SUPERVISION_BUSQUEDA_FAILURE:
            return { ...state, fetchingListaClavesMetodosSupervisionBusqueda: false };

        case MecanismosDePagoActions.ESTADO_CLAVE_METODO_SUPERVISION_REQUEST:
            return { ...state, fetchingListaClavesMetodosSupervisionBusqueda: true };
        case MecanismosDePagoActions.ESTADO_CLAVE_METODO_SUPERVISION_SUCCESS:
            return { ...state, fetchingListaClavesMetodosSupervisionBusqueda: false };
        case MecanismosDePagoActions.ESTADO_CLAVE_METODO_SUPERVISION_FAILURE:
            return { ...state, fetchingListaClavesMetodosSupervisionBusqueda: false };

        case MecanismosDePagoActions.SHOW_MODAL_NUEVO_CLAVE_METODO_SUPERVISION:
            return { ...state, showModalNuevaClaveMetodosSupervision: action.showModalNuevaClaveMetodosSupervision, page: action.page };

        case MecanismosDePagoActions.SHOW_MODAL_EDITAR_CLAVE_METODO_SUPERVISION:
            return { ...state, showModalEditarClaveMetodosSupervision: action.showModalEditarClaveMetodosSupervision, ClaveMetodosSupervisionSeleccionado: action.ClaveMetodosSupervisionSeleccionado, page: action.page };

        case MecanismosDePagoActions.NUEVA_CLAVE_METODO_SUPERVISION_REQUEST:
            return { ...state, fetchingNuevoClavesMetodosSupervision: true };
        case MecanismosDePagoActions.NUEVA_CLAVE_METODO_SUPERVISION_SUCCESS:
            return { ...state, fetchingNuevoClavesMetodosSupervision: false };
        case MecanismosDePagoActions.NUEVA_CLAVE_METODO_SUPERVISION_FAILURE:
            return { ...state, fetchingNuevoClavesMetodosSupervision: false };

        case MecanismosDePagoActions.EDITAR_CLAVE_METODO_SUPERVISION_REQUEST:
            return { ...state, fetchingEditarClavesMetodosSupervision: true };
        case MecanismosDePagoActions.EDITAR_CLAVE_METODO_SUPERVISION_SUCCESS:
            return { ...state, fetchingEditarClavesMetodosSupervision: false };
        case MecanismosDePagoActions.EDITAR_CLAVE_METODO_SUPERVISION_FAILURE:
            return { ...state, fetchingEditarClavesMetodosSupervision: false };

        // ESTANDARES DE DESEMPEÑO
        case MecanismosDePagoActions.LISTA_ESTANDARES_FILTRO_REQUEST:
            return { ...state, fetchingListaEstandaresFiltro: true };
        case MecanismosDePagoActions.LISTA_ESTANDARES_FILTRO_SUCCESS:
            return { ...state, fetchingListaEstandaresFiltro: false, listaEstandaresFiltro: action.listaEstandaresFiltro };
        case MecanismosDePagoActions.LISTA_ESTANDARES_FILTRO_FAILURE:
            return { ...state, fetchingListaEstandaresFiltro: false };

        case MecanismosDePagoActions.LISTA_ESTANDARES_ACTIVOS_REQUEST:
            return { ...state, fetchingListaEstandaresFiltro: true };
        case MecanismosDePagoActions.LISTA_ESTANDARES_ACTIVOS_SUCCESS:
            return { ...state, fetchingListaEstandaresFiltro: false, listaEstandaresFiltroActivos: action.listaEstandaresFiltroActivos };
        case MecanismosDePagoActions.LISTA_ESTANDARES_ACTIVOS_FAILURE:
            return { ...state, fetchingListaEstandaresFiltro: false };

        case MecanismosDePagoActions.LISTA_ESTANDARES_DESEMPEÑO_REQUEST:
            return { ...state, fetchingListaEstandaresDeDesempeño: true };
        case MecanismosDePagoActions.LISTA_ESTANDARES_DESEMPEÑO_SUCCESS:
            return { ...state, fetchingListaEstandaresDeDesempeño: false, listaEstandaresDeDesempeño: action.listaEstandaresDeDesempeño };
        case MecanismosDePagoActions.LISTA_ESTANDARES_DESEMPEÑO_FAILURE:
            return { ...state, fetchingEstandaresDeDesempeño: false, banderaTiempo: action.banderaTiempo };

        case MecanismosDePagoActions.ESTANDARES_DESEMPEÑO_BUSQUEDA_REQUEST:
            return { ...state, fetchingListaEstandaresDeDesempeñoBusqueda: true };
        case MecanismosDePagoActions.ESTANDARES_DESEMPEÑO_BUSQUEDA_SUCCESS:
            return { ...state, fetchingListaEstandaresDeDesempeñoBusqueda: false, listaEstandaresDeDesempeñoBusqueda: action.listaEstandaresDeDesempeñoBusqueda, listaEstandaresDeDesempeñoBusquedaCantidad: action.listaEstandaresDeDesempeñoBusquedaCantidad };
        case MecanismosDePagoActions.ESTANDARES_DESEMPEÑO_BUSQUEDA_FAILURE:
            return { ...state, fetchingListaEstandaresDeDesempeñoBusqueda: false, mostrarEspecifica: action.mostrarEspecifica };

        case MecanismosDePagoActions.ESTADO_ESTANDAR_DESEMPEÑO_REQUEST:
            return { ...state, fetchingListaEstandaresDeDesempeñoBusqueda: true };
        case MecanismosDePagoActions.ESTADO_ESTANDAR_DESEMPEÑO_SUCCESS:
            return { ...state, fetchingListaEstandaresDeDesempeñoBusqueda: false };
        case MecanismosDePagoActions.ESTADO_ESTANDAR_DESEMPEÑO_FAILURE:
            return { ...state, fetchingListaEstandaresDeDesempeñoBusqueda: false, refSeleccionado: action.refSeleccionado };

        case MecanismosDePagoActions.SHOW_MODAL_NUEVO_ESTANDAR_DESEMPEÑO:
            return { ...state, showModalNuevoEstandaresDeDesempeño: action.showModalNuevoEstandaresDeDesempeño, page: action.page, busqueda: action.busqueda };
        
        case MecanismosDePagoActions.SHOW_MODAL_EDITAR_ESTANDAR_DESEMPEÑO:
            return { ...state, showModalEditarEstandaresDeDesempeño: action.showModalEditarEstandaresDeDesempeño, EstandaresDeDesempeñoSeleccionado: action.EstandaresDeDesempeñoSeleccionado, page: action.page, busqueda: action.busqueda };

        case MecanismosDePagoActions.NUEVO_ESTANDAR_DESEMPEÑO_REQUEST:
            return { ...state, fetchingNuevoEstandaresDeDesempeño: true };
        case MecanismosDePagoActions.NUEVO_ESTANDAR_DESEMPEÑO_SUCCESS:
            return { ...state, fetchingNuevoEstandaresDeDesempeño: false };
        case MecanismosDePagoActions.NUEVO_ESTANDAR_DESEMPEÑO_FAILURE:
            return { ...state, fetchingNuevoEstandaresDeDesempeño: false };

        case MecanismosDePagoActions.EDITAR_ESTANDAR_DESEMPEÑO_REQUEST:
            return { ...state, fetchingEditarEstandaresDeDesempeño: true };
        case MecanismosDePagoActions.EDITAR_ESTANDAR_DESEMPEÑO_SUCCESS:
            return { ...state, fetchingEditarEstandaresDeDesempeño: false };
        case MecanismosDePagoActions.EDITAR_ESTANDAR_DESEMPEÑO_FAILURE:
            return { ...state, fetchingEditarEstandaresDeDesempeño: false };

        case MecanismosDePagoActions.CAMBIAR_VISTA_ESTANDAR_DESEMPEÑO:
            return { ...state, vistaModalEstandaresDeDesempeño: action.vistaModalEstandaresDeDesempeño, formularioEstandaresDeDesempeño: action.formularioEstandaresDeDesempeño, EstandaresDeDesempeñoEditados: action.EstandaresDeDesempeñoEditados };
            
        // reducer ponderacion servcios por area
        case MecanismosDePagoActions.LISTA_PONDERACION_SERV_POR_AREA_BUSQUEDA_REQUEST:
            return { ...state, fetchingListaPonderacionServPorAreaBusqueda: true };
        case MecanismosDePagoActions.LISTA_PONDERACION_SERV_POR_AREA_BUSQUEDA_SUCCESS:
            return { ...state, fetchingListaPonderacionServPorAreaBusqueda: false, listaPonderacionServPorAreaBusqueda: action.listaPonderacionServPorAreaBusqueda, listaPonderacionServPorAreaBusquedaCantidad: action.listaPonderacionServPorAreaBusquedaCantidad };
        case MecanismosDePagoActions.LISTA_PONDERACION_SERV_POR_AREA_BUSQUEDA_FAILURE:
            return { ...state, fetchingListaPonderacionServPorAreaBusqueda: false };

        case MecanismosDePagoActions.ESTADO_PONDERACION_SERV_POR_AREA_REQUEST:
            return { ...state, fetchingListaPonderacionServPorAreaBusqueda: true };
        case MecanismosDePagoActions.ESTADO_PONDERACION_SERV_POR_AREA_SUCCESS:
            return { ...state, fetchingListaPonderacionServPorAreaBusqueda: false };
        case MecanismosDePagoActions.ESTADO_PONDERACION_SERV_POR_AREA_FAILURE:
            return { ...state, fetchingListaPonderacionServPorAreaBusqueda: false };

        case MecanismosDePagoActions.SHOW_MODAL_NUEVO_PONDERACION_SERV_POR_AREA:
            return { ...state, showModalNuevoPonderacionServPorArea: action.showModalNuevoPonderacionServPorArea, page: action.page };

        case MecanismosDePagoActions.SHOW_MODAL_EDITAR_PONDERACION_SERV_POR_AREA:
            return { ...state, showModalEditarPonderacionServPorArea: action.showModalEditarPonderacionServPorArea, ponderacionServPorAreaSeleccionado: action.ponderacionServPorAreaSeleccionado, page: action.page };

        case MecanismosDePagoActions.NUEVO_PONDERACION_SERV_POR_AREA_REQUEST:
            return { ...state, fetchingNuevoPonderacionServPorArea: true };
        case MecanismosDePagoActions.NUEVO_PONDERACION_SERV_POR_AREA_SUCCESS:
            return { ...state, fetchingNuevoPonderacionServPorArea: false };
        case MecanismosDePagoActions.NUEVO_PONDERACION_SERV_POR_AREA_FAILURE:
            return { ...state, fetchingNuevoPonderacionServPorArea: false };

        case MecanismosDePagoActions.EDITAR_PONDERACION_SERV_POR_AREA_REQUEST:
            return { ...state, fetchingEditarPonderacionServPorArea: true };
        case MecanismosDePagoActions.EDITAR_PONDERACION_SERV_POR_AREA_SUCCESS:
            return { ...state, fetchingEditarPonderacionServPorArea: false };
        case MecanismosDePagoActions.EDITAR_PONDERACION_SERV_POR_AREA_FAILURE:
            return { ...state, fetchingEditarPonderacionServPorArea: false }; 

        //AÑO MES CALENDARIO REDUCER
        case MecanismosDePagoActions.LISTA_MESES_CALENDARIO_REQUEST:
            return { ...state, fetchingListaMesesCalendario: true };
        case MecanismosDePagoActions.LISTA_MESES_CALENDARIO_SUCCESS:
            return { ...state, fetchingListaMesesCalendario: false, listaMesesCalendario: action.listaMesesCalendario, listaMesesCalendarioCantidad: action.listaMesesCalendarioCantidad };
        case MecanismosDePagoActions.LISTA_MESES_CALENDARIO_FAILURE:
            return { ...state, fetchingListaMesesCalendario: false };

        case MecanismosDePagoActions.LISTA_AÑO_CALENDARIO_REQUEST:
            return { ...state, fetchingListaAñosCalendario: true };
        case MecanismosDePagoActions.LISTA_AÑO_CALENDARIO_SUCCESS:
            return { ...state, fetchingListaAñosCalendario: false, listaAñosCalendario: action.listaAñosCalendario };
        case MecanismosDePagoActions.LISTA_AÑO_CALENDARIO_FAILURE:
            return { ...state, fetchingListaAñosCalendario: false };

        case MecanismosDePagoActions.DETALLE_MES_CALENDARIO_REQUEST:
            return { ...state, fetchingDetalleMesCalendario: true };
        case MecanismosDePagoActions.DETALLE_MES_CALENDARIO_SUCCESS:
            return { ...state, fetchingDetalleMesCalendario: false, detalleMesCalendario: action.detalleMesCalendario };
        case MecanismosDePagoActions.DETALLE_MES_CALENDARIO_FAILURE:
            return { ...state, fetchingDetalleMesCalendario: false };

        case MecanismosDePagoActions.SHOW_MODAL_DETALLE_MES_CALENDARIO:
            return { ...state, showModalDetalleMesCalendario: action.showModalDetalleMesCalendario, mesCalendarioSeleccionado: action.mesCalendarioSeleccionado, busqueda: action.busqueda };

        case MecanismosDePagoActions.CALCULAR_MES_CALENDARIO_REQUEST:
            return { ...state, fetchingListaMesesCalendario: true };
        case MecanismosDePagoActions.CALCULAR_MES_CALENDARIO_SUCCESS:
            return { ...state, fetchingListaMesesCalendario: false };
        case MecanismosDePagoActions.CALCULAR_MES_CALENDARIO_FAILURE:
            return { ...state, fetchingListaMesesCalendario: false };

        case MecanismosDePagoActions.CERRAR_MES_CALENDARIO_REQUEST:
            return { ...state, fetchingListaMesesCalendario: true };
        case MecanismosDePagoActions.CERRAR_MES_CALENDARIO_SUCCESS:
            return { ...state, fetchingListaMesesCalendario: false };
        case MecanismosDePagoActions.CERRAR_MES_CALENDARIO_FAILURE:
            return { ...state, fetchingListaMesesCalendario: false };

        case MecanismosDePagoActions.SHOW_MODAL_CREAR_MES_CALENDARIO:
            return { ...state, showModalCrearMesCalendario: action.showModalCrearMesCalendario, busqueda: action.busqueda, fechaAnterior: action.fechaAnterior };

        case MecanismosDePagoActions.CREAR_MES_CALENDARIO_REQUEST:
            return { ...state, fetchingCrearMesCalendario: true };
        case MecanismosDePagoActions.CREAR_MES_CALENDARIO_SUCCESS:
            return { ...state, fetchingCrearMesCalendario: false };
        case MecanismosDePagoActions.CREAR_MES_CALENDARIO_FAILURE:
            return { ...state, fetchingCrearMesCalendario: false };

        case MecanismosDePagoActions.SHOW_MODAL_EDITAR_MES_CALENDARIO:
            return { ...state, showModalEditarMesCalendario: action.showModalEditarMesCalendario, busqueda: action.busqueda, fechaAnterior: action.fechaAnterior, mesCalendarioEditar: action.mesCalendarioEditar, editando: false };

        case MecanismosDePagoActions.EDITAR_MES_CALENDARIO_REQUEST:
            return { ...state, fetchingEditarMesCalendario: true };
        case MecanismosDePagoActions.EDITAR_MES_CALENDARIO_SUCCESS:
            return { ...state, fetchingEditarMesCalendario: false };
        case MecanismosDePagoActions.EDITAR_MES_CALENDARIO_FAILURE:
            return { ...state, fetchingEditarMesCalendario: false };

        case MecanismosDePagoActions.SHOW_MODAL_AGREGAR_PPL:
            return { ...state, showModalAgregarPPL: action.showModalAgregarPPL, busqueda: action.busqueda, mesCalendarioSeleccionado: action.mesCalendarioSeleccionado, numPplMes: action.numPplMes, terminado: action.terminado, editando: action.editando };

        case MecanismosDePagoActions.AGREGAR_PPL_REQUEST:
            return { ...state, fetchingAgregarPPL: true };
        case MecanismosDePagoActions.AGREGAR_PPL_SUCCESS:
            return { ...state, fetchingAgregarPPL: false };
        case MecanismosDePagoActions.AGREGAR_PPL_FAILURE:
            return { ...state, fetchingAgregarPPL: false };

        case MecanismosDePagoActions.EXPORTAR_EXCEL_MES_CALENDARIO_REQUEST:
            return { ...state, fetchingListaMesesCalendario: true };
        case MecanismosDePagoActions.EXPORTAR_EXCEL_MES_CALENDARIO_SUCCESS:
            return { ...state, fetchingListaMesesCalendario: false };
        case MecanismosDePagoActions.EXPORTAR_EXCEL_MES_CALENDARIO_FAILURE:
            return { ...state, fetchingListaMesesCalendario: false };

        //VALORES DE PAGO ANUALES
        case MecanismosDePagoActions.LISTA_VALORES_PAGO_ANUAL_REQUEST:
            return { ...state, fetchingValoresPagoAnual: true };
        case MecanismosDePagoActions.LISTA_VALORES_PAGO_ANUAL_SUCCESS:
            return { ...state, fetchingValoresPagoAnual: false, listaValoresPagoAnual: action.listaValoresPagoAnual, listaValoresPagoAnualCantidad: action.listaValoresPagoAnualCantidad }; 
        case MecanismosDePagoActions.LISTA_VALORES_PAGO_ANUAL_FAILURE:
            return { ...state, fetchingValoresPagoAnual: false };

        case MecanismosDePagoActions.ESTADO_INPC0_REQUEST:
            return { ...state, fetchingValoresPagoAnual: true };
        case MecanismosDePagoActions.ESTADO_INPC0_SUCCESS:
            return { ...state, fetchingValoresPagoAnual: false }; 
        case MecanismosDePagoActions.ESTADO_INPC0_FAILURE:
            return { ...state, fetchingValoresPagoAnual: false };  
            
        case MecanismosDePagoActions.CREAR_INPC0_REQUEST:
            return { ...state, fetchingCrearINCP0: true };
        case MecanismosDePagoActions.CREAR_INPC0_SUCCESS:
            return { ...state, fetchingCrearINCP0: false }; 
        case MecanismosDePagoActions.CREAR_INPC0_FAILURE:
            return { ...state, fetchingCrearINCP0: false }; 

        case MecanismosDePagoActions.SHOW_MODAL_CREAR_INPC0:
                return { ...state, showModalNuevoINPC0: action.showModalNuevoINPC0, page: action.page };  

        case MecanismosDePagoActions.EDITAR_INPC0_REQUEST:
            return { ...state, fetchingEditarINPC0: true };
        case MecanismosDePagoActions.EDITAR_INPC0_SUCCESS:
            return { ...state, fetchingEditarINPC0: false }; 
        case MecanismosDePagoActions.EDITAR_INPC0_FAILURE:
            return { ...state, fetchingEditarINPC0: false }; 

        case MecanismosDePagoActions.SHOW_MODAL_EDITAR_INPC0:
            return { ...state, showModalEditarINPC0: action.showModalEditarINPC0, inpc0Seleccionado: action.inpc0Seleccionado, page: action.page };  

        case MecanismosDePagoActions.LISTA_PARv_REQUEST:
            return { ...state, fetchingListaPARv: true };
        case MecanismosDePagoActions.LISTA_PARv_SUCCESS:
            return { ...state, fetchingListaPARv: false, listaPARv: action.listaPARv, listaPARvCantidad: action.listaPARvCantidad }; 
        case MecanismosDePagoActions.LISTA_PARv_FAILURE:
            return { ...state, fetchingListaPARv: false };

        case MecanismosDePagoActions.SHOW_MODAL_CREAR_PARv:
            return { ...state, showModalCrearPARv: action.showModalCrearPARv, page: action.page, busqueda: action.busqueda };  

        case MecanismosDePagoActions.CREAR_PARv_REQUEST:
            return {...state, fetchingCrearPARv: true }
        case MecanismosDePagoActions.CREAR_PARv_SUCCESS:
            return {...state, fetchingCrearPARv: false } 
        case MecanismosDePagoActions.CREAR_PARv_FAILURE:
            return {...state, fetchingCrearPARv: false } 

        case MecanismosDePagoActions.VERIFICAR_PARv_REQUEST :
            return {...state, fetchingVerificarPARv: true }
        case MecanismosDePagoActions.VERIFICAR_PARv_SUCCESS:
            return {...state, fetchingVerificarPARv: false , listaVerificarPARv: action.listaVerificarPARv} 
        case MecanismosDePagoActions.VERIFICAR_PARv_FAILURE:
            return {...state, fetchingVerificarPARv: false }  

        // Reducers Catalogo Periodo Fraccion Maxima Descuento         
        case MecanismosDePagoActions.LISTA_CATALOGO_PERIODO_FMD_REQUEST:
            return {...state, fetchingPeriodoFMD: true }
        case MecanismosDePagoActions.LISTA_CATALOGO_PERIODO_FMD_SUCCESS:
            return {...state, fetchingPeriodoFMD: false , listaPeriodoFMD: action.listaPeriodoFMD, listaPeriodoFMDCantidad: action.listaPeriodoFMDCantidad }; 
        case MecanismosDePagoActions.LISTA_CATALOGO_PERIODO_FMD_FAILURE:
            return {...state, fetchingPeriodoFMD: false }   

        case MecanismosDePagoActions.CREAR_PERIODO_FMD_REQUEST:
            return {...state, fetchingCrearPeriodoFMD: true }
        case MecanismosDePagoActions.CREAR_PERIODO_FMD_SUCCESS:
            return {...state, fetchingCrearPeriodoFMD: false } 
        case MecanismosDePagoActions.CREAR_PERIODO_FMD_FAILURE:
            return {...state, fetchingCrearPeriodoFMD: false }     

        case MecanismosDePagoActions.EDITAR_PERIODO_FMD_REQUEST:
            return {...state, fetchingEditarPeriodoFMD: true }
        case MecanismosDePagoActions.EDITAR_PERIODO_FMD_SUCCESS:
            return {...state, fetchingEditarPeriodoFMD: false } 
        case MecanismosDePagoActions.EDITAR_PERIODO_FMD_FAILURE:
            return {...state, fetchingEditarPeriodoFMD: false }

        case MecanismosDePagoActions.SHOW_MODAL_EDITAR_PERIODO_FMD:
            return { ...state, showModalEditarPeriodoFMD: action.showModalEditarPeriodoFMD, periodoSeleccionado: action.periodoSeleccionado, page: action.page };  

        case MecanismosDePagoActions.SHOW_MODAL_CREAR_PERIODO_FMD:
            return { ...state, showModalCrearPeriodoFMD: action.showModalCrearPeriodoFMD, page: action.page };         

        case MecanismosDePagoActions.VERIFICAR_AÑOS_PERIODO_REQUEST:
            return {...state, fetchingPeriodoFMD: true }
        case MecanismosDePagoActions.VERIFICAR_AÑOS_PERIODO_SUCCESS:
            return {...state, fetchingPeriodoFMD: false, añosMaximoPeriodo: action.añosMaximoPeriodo } 
        case MecanismosDePagoActions.VERIFICAR_AÑOS_PERIODO_FAILURE:
            return {...state, fetchingPeriodoFMD: false }

        case MecanismosDePagoActions.CAMBIAR_VISTA_PERIODO_FMD:
             return {...state, cambiarVistaModalPeriodoFMD: action.cambiarVistaModalPeriodoFMD, formularioPeriodo: action.formularioPeriodo, periodosEditados: action.periodosEditados };

        // Administración Sistema de Cálculo de Deductivas     
        case MecanismosDePagoActions.SHOW_MODAL_DETALLE_CALCULO_DEDUCTIVA:
            return { ...state, showModalDetalleCalculoDeductivas: action.showModalDetalleCalculoDeductivas, fechaProyeccion: action.fechaProyeccion, idMesCalendario: action.idMesCalendario, proyeccion: action.proyeccion, cierreMes: action.cierreMes, procesable: action.procesable, ultimoDiaAllPPLs: action.ultimoDiaAllPPLs, mesSeleccionado: action.mesSeleccionado, mesAnterior: action.mesAnterior };

        case MecanismosDePagoActions.VALOR_TIPO_CALCULO_DEDUCTIVA_REQUEST:
            return { ...state, fetchingValorCalculoTipoDeductiva: true };
        case MecanismosDePagoActions.VALOR_TIPO_CALCULO_DEDUCTIVA_SUCCESS:
            return { ...state, fetchingValorCalculoTipoDeductiva: false, valorCalculoTipoDeductiva: action.valorCalculoTipoDeductiva };
        case MecanismosDePagoActions.VALOR_TIPO_CALCULO_DEDUCTIVA_FAILURE:
            return { ...state, fetchingValorCalculoTipoDeductiva: false };

        case MecanismosDePagoActions.LISTA_MESES_ABIERTOS_REQUEST:
            return { ...state, fetchingListaMesesAbiertos: true };
        case MecanismosDePagoActions.LISTA_MESES_ABIERTOS_SUCCESS:
            return { ...state, fetchingListaMesesAbiertos: false, listaMesesAbiertos: action.listaMesesAbiertos };
        case MecanismosDePagoActions.LISTA_MESES_ABIERTOS_FAILURE:
            return { ...state, fetchingListaMesesAbiertos: false };

        case MecanismosDePagoActions.LISTA_RESUMEN_SERVICIO_REQUEST:
            return { ...state, fetchingListaResumenServicio: true };
        case MecanismosDePagoActions.LISTA_RESUMEN_SERVICIO_SUCCESS:
            return { ...state, fetchingListaResumenServicio: false, listaResumenServicio: action.listaResumenServicio, listaResumenServicioCantidad: action.listaResumenServicioCantidad };
        case MecanismosDePagoActions.LISTA_RESUMEN_SERVICIO_FAILURE:
            return { ...state, fetchingListaResumenServicio: false };

        case MecanismosDePagoActions.DEDUCTIVA_RESUMEN_EXCEL_REQUEST:
            return { ...state, fetchingDeductivaResumenExcel: true };
        case MecanismosDePagoActions.DEDUCTIVA_RESUMEN_EXCEL_SUCCESS:
            return { ...state, fetchingDeductivaResumenExcel: false };
        case MecanismosDePagoActions.DEDUCTIVA_RESUMEN_EXCEL_FAILURE:
            return { ...state, fetchingDeductivaResumenExcel: false };

        case MecanismosDePagoActions.LISTA_DETALLE_CALCULO_DEDUCTIVA_REQUEST:
            return { ...state, fetchingListaDetalleCalculoDeductivas: true };
        case MecanismosDePagoActions.LISTA_DETALLE_CALCULO_DEDUCTIVA_SUCCESS:
            return { ...state, fetchingListaDetalleCalculoDeductivas: false, listaDetalleCalculoDeductivas: action.listaDetalleCalculoDeductivas, listaDetalleCalculoDeductivasCantidad: action.listaDetalleCalculoDeductivasCantidad };
        case MecanismosDePagoActions.LISTA_DETALLE_CALCULO_DEDUCTIVA_FAILURE:
            return { ...state, fetchingListaDetalleCalculoDeductivas: false };

        case MecanismosDePagoActions.DETALLE_DEDUCTIVA_EXCEL_REQUEST:
            return { ...state, fetchingDetalleDeductivaExcel: true };
        case MecanismosDePagoActions.DETALLE_DEDUCTIVA_EXCEL_SUCCESS:
            return { ...state, fetchingDetalleDeductivaExcel: false };
        case MecanismosDePagoActions.DETALLE_DEDUCTIVA_EXCEL_FAILURE:
            return { ...state, fetchingDetalleDeductivaExcel: false };

        case MecanismosDePagoActions.PROCESAR_DEDUCTIVA_REQUEST:
            return { ...state, fetchingProcesarDeductiva: true };
        case MecanismosDePagoActions.PROCESAR_DEDUCTIVA_SUCCESS:
            return { ...state, fetchingProcesarDeductiva: false };
        case MecanismosDePagoActions.PROCESAR_DEDUCTIVA_FAILURE:
            return { ...state, fetchingProcesarDeductiva: false };

        case MecanismosDePagoActions.DEDUCTIVA_TICKETS_NO_CONCILIADOS_REQUEST:
            return { ...state, fetchingListaTicktesNoConciliados: true };
        case MecanismosDePagoActions.DEDUCTIVA_TICKETS_NO_CONCILIADOS_SUCCESS:
            return { ...state, fetchingListaTicktesNoConciliados: false, listaTicketsNoConciliados: action.listaTicketsNoConciliados };
        case MecanismosDePagoActions.DEDUCTIVA_TICKETS_NO_CONCILIADOS_FAILURE:
            return { ...state, fetchingListaTicktesNoConciliados: false };

        //   
        case MecanismosDePagoActions.LISTA_PERIODOS_TODOS_REQUEST:
            return {...state, fetchingListaPeriodosTodos: true }
        case MecanismosDePagoActions.LISTA_PERIODOS_TODOS_SUCCESS:
            return {...state, fetchingListaPeriodosTodos: false , listaPeriodosTodos: action.listaPeriodosTodos }; 
        case MecanismosDePagoActions.LISTA_PERIODOS_TODOS_FAILURE:
            return {...state, fetchingListaPeriodosTodos: false }   
           
        case MecanismosDePagoActions.LISTA_PERIODOS_FORMULARIO_EDITAR_REQUEST:
            return {...state, fetchingListaPeriodosTodos: true }
        case MecanismosDePagoActions.LISTA_PERIODOS_FORMULARIO_EDITAR_SUCCESS:
            return {...state, fetchingListaPeriodosTodos: false , listaPeriodosFormularioEditar: action.listaPeriodosFormularioEditar, listaPeriodoParaFormulario: action.listaPeriodoParaFormulario} 
        case MecanismosDePagoActions.LISTA_PERIODOS_FORMULARIO_EDITAR_FAILURE:
            return {...state, fetchingListaPeriodosTodos: false }

        // Tiempo de garantia
        case MecanismosDePagoActions.LISTA_NIVELES_SEVERIDAD_ACTIVOS_REQUEST:
            return { ...state, fetchingListaNivelesSeveridadActivos: true };
        case MecanismosDePagoActions.LISTA_NIVELES_SEVERIDAD_ACTIVOS_SUCCESS:
            return { ...state, fetchingListaNivelesSeveridadActivos: false, listaNivelesSeveridadActivos: action.listaNivelesSeveridadActivos, listaNivelesSeveridad: action.listaNivelesSeveridad };
        case MecanismosDePagoActions.LISTA_NIVELES_SEVERIDAD_ACTIVOS_FAILURE:
            return { ...state, fetchingListaNivelesSeveridadActivos: false };

        case MecanismosDePagoActions.LISTA_NIVELES_SEVERIDAD_BUSQUEDA_REQUEST:
            return { ...state, fetchingListaNivelesSeveridadBusqueda: true };
        case MecanismosDePagoActions.LISTA_NIVELES_SEVERIDAD_BUSQUEDA_SUCCESS:
            return { ...state, fetchingListaNivelesSeveridadBusqueda: false, listaNivelesSeveridadBusqueda: action.listaNivelesSeveridadBusqueda, listaNivelesSeveridadBusquedaCantidad: action.listaNivelesSeveridadBusquedaCantidad };
        case MecanismosDePagoActions.LISTA_NIVELES_SEVERIDAD_BUSQUEDA_FAILURE:
            return { ...state, fetchingListaNivelesSeveridadBusqueda: false };

        case MecanismosDePagoActions.ESTADO_NIVEL_SEVERIDAD_REQUEST:
            return { ...state, fetchingListaNivelesSeveridadBusqueda: true };
        case MecanismosDePagoActions.ESTADO_NIVEL_SEVERIDAD_SUCCESS:
            return { ...state, fetchingListaNivelesSeveridadBusqueda: false };
        case MecanismosDePagoActions.ESTADO_NIVEL_SEVERIDAD_FAILURE:
            return { ...state, fetchingListaNivelesSeveridadBusqueda: false };

        case MecanismosDePagoActions.SHOW_MODAL_NUEVO_NIVEL_SEVERIDAD:
            return { ...state, showModalNuevoNivelSeveridad: action.showModalNuevoNivelSeveridad, page: action.page, busqueda: action.busqueda };

        case MecanismosDePagoActions.NUEVO_NIVEL_SEVERIDAD_REQUEST:
            return { ...state, fetchingNuevoNivelSeveridad: true };
        case MecanismosDePagoActions.NUEVO_NIVEL_SEVERIDAD_SUCCESS:
            return { ...state, fetchingNuevoNivelSeveridad: false };
        case MecanismosDePagoActions.NUEVO_NIVEL_SEVERIDAD_FAILURE:
            return { ...state, fetchingNuevoNivelSeveridad: false };

        case MecanismosDePagoActions.SHOW_MODAL_EDITAR_NIVEL_SEVERIDAD:
            return { ...state, showModalEditarNivelSeveridad: action.showModalEditarNivelSeveridad, nivelSeveridadSeleccionado: action.nivelSeveridadSeleccionado, page: action.page, busqueda: action.busqueda };

        case MecanismosDePagoActions.EDITAR_NIVEL_SEVERIDAD_REQUEST:
            return { ...state, fetchingEditarNivelSeveridad: true };
        case MecanismosDePagoActions.EDITAR_NIVEL_SEVERIDAD_SUCCESS:
            return { ...state, fetchingEditarNivelSeveridad: false };
        case MecanismosDePagoActions.EDITAR_NIVEL_SEVERIDAD_FAILURE:
            return { ...state, fetchingEditarNivelSeveridad: false };

        case MecanismosDePagoActions.CAMBIAR_VISTA_NIVEL_SEVERIDAD:
            return { ...state, vistaModalNivelSeveridad: action.vistaModalNivelSeveridad, formularioNivelSeveridad: action.formularioNivelSeveridad, nivelesEditados: action.nivelesEditados }

        //CATALOGO NIVELES TIEMPO RESPUESTA POR SECCION
        case MecanismosDePagoActions.LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_REQUEST:
            return { ...state, fetchingListaNivelesTiempoRespuestaActivos: true };
        case MecanismosDePagoActions.LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_SUCCESS:
            return { ...state, fetchingListaNivelesTiempoRespuestaActivos: false, listaNivelesTiempoRespuestaActivos: action.listaNivelesTiempoRespuestaActivos };
        case MecanismosDePagoActions.LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_FAILURE:
            return { ...state, fetchingListaNivelesTiempoRespuestaActivos: false };

        case MecanismosDePagoActions.LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_E_REQUEST:
            return { ...state, fetchingListaNivelesTiempoRespuestaEdicion: true };
        case MecanismosDePagoActions.LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_E_SUCCESS:
            return { ...state, fetchingListaNivelesTiempoRespuestaEdicion: false, listaNivelesTiempoRespuestaEdicion: action.listaNivelesTiempoRespuestaEdicion, listaValoresInicialesNTR: action.listaValoresInicialesNTR };
        case MecanismosDePagoActions.LISTA_NIVELES_TIEMPO_RESPUESTA_ACTIVOS_E_FAILURE:
            return { ...state, fetchingListaNivelesTiempoRespuestaEdicion: false };
    
        case MecanismosDePagoActions.ESTADO_NIVEL_TIEMPO_RESPUESTA_REQUEST:
            return { ...state, fetchingListaNivelesTiempoRespuestaBusqueda: true };
        case MecanismosDePagoActions.ESTADO_NIVEL_TIEMPO_RESPUESTA_SUCCESS:
            return { ...state, fetchingListaNivelesTiempoRespuestaBusqueda: false };
        case MecanismosDePagoActions.ESTADO_NIVEL_TIEMPO_RESPUESTA_FAILURE:
            return { ...state, fetchingListaNivelesTiempoRespuestaBusqueda: false };    

        case MecanismosDePagoActions.NIVELES_TIEMPO_RESPUESTA_BUSQUEDA_REQUEST:
            return { ...state, fetchingListaNivelesTiempoRespuestaBusqueda: true };
        case MecanismosDePagoActions.NIVELES_TIEMPO_RESPUESTA_BUSQUEDA_SUCCESS:
            return { ...state, fetchingListaNivelesTiempoRespuestaBusqueda: false, listaNivelesTiempoRespuestaBusqueda: action.listaNivelesTiempoRespuestaBusqueda, listaNivelesTiempoRespuestaBusquedaCantidad: action.listaNivelesTiempoRespuestaBusquedaCantidad };
        case MecanismosDePagoActions.NIVELES_TIEMPO_RESPUESTA_BUSQUEDA_FAILURE:
            return { ...state, fetchingListaNivelesTiempoRespuestaBusqueda: false };

        case MecanismosDePagoActions.NIVEL_TIEMPO_RESPUESTA_ANTERIOR_REQUEST:
            return { ...state, fetchingNivelTiempoRespuestaAnterior: true };
        case MecanismosDePagoActions.NIVEL_TIEMPO_RESPUESTA_ANTERIOR_SUCCESS:
            return { ...state, fetchingNivelTiempoRespuestaAnterior: false, nivelTiempoRespuestaAnterior: action.nivelTiempoRespuestaAnterior};
        case MecanismosDePagoActions.NIVEL_TIEMPO_RESPUESTA_ANTERIOR_FAILURE:
            return { ...state, fetchingNivelTiempoRespuestaAnterior: false };

        case MecanismosDePagoActions.SHOW_MODAL_NUEVO_NIVEL_TIEMPO_RESPUESTA:
            return { ...state, showModalNuevoNivelTiempoRespuesta: action.showModalNuevoNivelTiempoRespuesta, page: action.page, busqueda: action.busqueda };

        case MecanismosDePagoActions.SHOW_MODAL_EDITAR_NIVEL_TIEMPO_RESPUESTA:
            return { ...state, showModalEditarNivelTiempoRespuesta: action.showModalEditarNivelTiempoRespuesta, nivelTiempoRespuestaSeleccionado: action.nivelTiempoRespuestaSeleccionado, page: action.page, busqueda: action.busqueda };

        case MecanismosDePagoActions.NUEVO_NIVEL_TIEMPO_RESPUESTA_REQUEST:
            return { ...state, fetchingNuevoNivelTiempoRespuesta: true };
        case MecanismosDePagoActions.NUEVO_NIVEL_TIEMPO_RESPUESTA_SUCCESS:
            return { ...state, fetchingNuevoNivelTiempoRespuesta: false };
        case MecanismosDePagoActions.NUEVO_NIVEL_TIEMPO_RESPUESTA_FAILURE:
            return { ...state, fetchingNuevoNivelTiempoRespuesta: false };

        case MecanismosDePagoActions.EDITAR_NIVEL_TIEMPO_RESPUESTA_REQUEST:
            return { ...state, fetchingEditarNivelTiempoRespuesta: true };
        case MecanismosDePagoActions.EDITAR_NIVEL_TIEMPO_RESPUESTA_SUCCESS:
            return { ...state, fetchingEditarNivelTiempoRespuesta: false};
        case MecanismosDePagoActions.EDITAR_NIVEL_TIEMPO_RESPUESTA_FAILURE:
            return { ...state, fetchingEditarNivelTiempoRespuesta: false };

        case MecanismosDePagoActions.CAMBIAR_VISTA_NIVEL_TIEMPO_RESPUESTA:
            return { ...state, vistaModalNivelTiempoRespuesta: action.vistaModalNivelTiempoRespuesta, formularioNivelTiempoRespuesta: action.formularioNivelTiempoRespuesta, listaFiltradaEditadosNTR: action.listaFiltradaEditadosNTR };
 
        // Número de Documento por Reiteración de Fallas
        case MecanismosDePagoActions.SHOW_MODAL_NUEVO_NO_DOCUMENTO:
            return { ...state, showModalNuevoNumeroDocumento: action.showModalNuevoNumeroDocumento };

        case MecanismosDePagoActions.LISTA_DOCUMENTO_REITERADO_ULTIMOS_REQUEST:
            return { ...state, fetchingListaDocumentoReiteradoUltimos: true };
        case MecanismosDePagoActions.LISTA_DOCUMENTO_REITERADO_ULTIMOS_SUCCESS:
            return { ...state, fetchingListaDocumentoReiteradoUltimos: false, listaDocumentosReiteradoUltimos: action.listaDocumentosReiteradoUltimos };
        case MecanismosDePagoActions.LISTA_DOCUMENTO_REITERADO_ULTIMOS_FAILURE:
            return { ...state, fetchingListaDocumentoReiteradoUltimos: false };

        case MecanismosDePagoActions.LISTA_DOCUMENTO_REITERADO_ULTIMO_WITHOUT_REQUEST:
            return { ...state, fetchingListaDocumentoReiteradoUltimosWithout: true };
        case MecanismosDePagoActions.LISTA_DOCUMENTO_REITERADO_ULTIMO_WITHOUT_SUCCESS:
            return {...state, fetchingListaDocumentoReiteradoUltimosWithout: false, listaDocumentosReiteradoUltimosWitoutSearch: action.listaDocumentosReiteradoUltimosWitoutSearch };
        case MecanismosDePagoActions.LISTA_DOCUMENTO_REITERADO_ULTIMO_WITHOUT_FAILURE:
            return { ...state, fetchingListaDocumentoReiteradoUltimosWithout: false };

        case MecanismosDePagoActions.LISTA_TICKETS_REITERADOS_REQUEST:
            return { ...state, fetchingListaTicketsReiterados: true };
        case MecanismosDePagoActions.LISTA_TICKETS_REITERADOS_SUCCESS:
            return { ...state, fetchingListaTicketsReiterados: false, listaTicketsReiterados: action.listaTicketsReiterados, listaTicketsReiteradosCantidad: action.listaTicketsReiteradosCantidad };
        case MecanismosDePagoActions.LISTA_TICKETS_REITERADOS_FAILURE:
            return { ...state, fetchingListaTicketsReiterados: false };

        case MecanismosDePagoActions.SHOW_MODAL_BUSQUEDA_TICKET_REITERADO:
            return { ...state, showModalBusquedaTicketReiterado: action.showModalBusquedaTicketReiterado, ticketReiteradoSeleccionado: action.ticketReiteradoSeleccionado, idSolicitudServicioReiterada: action.idSolicitudServicioReiterada };
        
        case MecanismosDePagoActions.SHOW_MODAL_BUSQUEDA_TICKET_ORIGEN:
            return { ...state, showModalBusquedaTicketOrigen: action.showModalBusquedaTicketOrigen, ticketOrigenSeleccionadoMODAL: action.ticketOrigenSeleccionadoMODAL, idSolicitudServicioOrigen: action.idSolicitudServicioOrigen, reiteracion: action.reiteracion };

        case MecanismosDePagoActions.SHOW_MODAL_DETALLE_TICKET_REITERADO:
            return { ...state, showModalDetalleTicketReiterado: action.showModalDetalleTicketReiterado, ticketReiteradoSeleccionado: action.ticketReiteradoSeleccionado };

        case MecanismosDePagoActions.SHOW_MODAL_DETALLE_TICKET_ORIGEN:
            return { ...state, showModalDetalleTicketOrigen: action.showModalDetalleTicketOrigen, ticketOrigenSeleccionado: action.ticketOrigenSeleccionado };

        case MecanismosDePagoActions.SHOW_MODAL_BUSQUEDA_CODIGO_INTERNO:
            return { ...state, showModalBusquedaCodigoInterno: action.showModalBusquedaCodigoInterno, codigoInterno: action.codigoInterno };

        case MecanismosDePagoActions.LISTA_TICKETS_ORIGEN_REQUEST:
            return { ...state, fetchingListaTicketsOrigen: true };
        case MecanismosDePagoActions.LISTA_TICKETS_ORIGEN_SUCCESS:
            return { ...state, fetchingListaTicketsOrigen: false, listaTicketsOrigen: action.listaTicketsOrigen, listaTicketsOrigenCantidad: action.listaTicketsOrigenCantidad };
        case MecanismosDePagoActions.LISTA_TICKETS_ORIGEN_FAILURE:
            return { ...state, fetchingListaTicketsOrigen: false };

        case MecanismosDePagoActions.GET_DOCUMENTO_RELACIONADO_REQUEST:
            return { ...state, fetchingDocumentoRelacionado: true };
        case MecanismosDePagoActions.GET_DOCUMENTO_RELACIONADO_SUCCESS:
            return { ...state, fetchingDocumentoRelacionado: false, documentoRelacionado: action.documentoRelacionado, ticketOrigenSeleccionado: action.ticketOrigenSeleccionado, idSolicitudServicioOrigen: action.idSolicitudServicioOrigen, reiteracion: action.reiteracion };
        case MecanismosDePagoActions.GET_DOCUMENTO_RELACIONADO_FAILURE:
            return { ...state, fetchingDocumentoRelacionado: false };

        case MecanismosDePagoActions.GET_DOCUMENTO_RELACIONADO_BY_NUMERO_REQUEST:
            return { ...state, fetchingDocumentoRelacionado: true };
        case MecanismosDePagoActions.GET_DOCUMENTO_RELACIONADO_BY_NUMERO_SUCCESS:
            return { ...state, fetchingDocumentoRelacionadoByNumero: false, documentoRelacionadoByNumero: action.documentoRelacionadoByNumero };
        case MecanismosDePagoActions.GET_DOCUMENTO_RELACIONADO_BY_NUMERO_FAILURE:
            return { ...state, fetchingDocumentoRelacionado: false };

        case MecanismosDePagoActions.CREAR_DOCUMENTO_REITERACION_REQUEST:
            return { ...state, fetchingCrearDocumentoReiteracion: true };
        case MecanismosDePagoActions.CREAR_DOCUMENTO_REITERACION_SUCCESS:
            return { ...state, fetchingCrearDocumentoReiteracion: false };
        case MecanismosDePagoActions.CREAR_DOCUMENTO_REITERACION_FAILURE:
            return { ...state, fetchingCrearDocumentoReiteracion: false };

        case MecanismosDePagoActions.TICKETS_REITERADOS_BY_DOCUMENTO_REQUEST:
            return { ...state, fetchingTicketsReiteradosByDocumento: true };
        case MecanismosDePagoActions.TICKETS_REITERADOS_BY_DOCUMENTO_SUCCESS:
            return { ...state, fetchingTicketsReiteradosByDocumento: false, ticketsReiteradosByDocumento: action.ticketsReiteradosByDocumento };
        case MecanismosDePagoActions.TICKETS_REITERADOS_BY_DOCUMENTO_FAILURE:
            return { ...state, fetchingTicketsReiteradosByDocumento: false };

        case MecanismosDePagoActions.ELIMINAR_NUMERO_DOCUMENTO_REQUEST:
            return { ...state, fetchingEliminarNumeroDocumento: true };
        case MecanismosDePagoActions.ELIMINAR_NUMERO_DOCUMENTO_SUCCESS:
            return { ...state, fetchingEliminarNumeroDocumento: false };
        case MecanismosDePagoActions.ELIMINAR_NUMERO_DOCUMENTO_FAILURE:
            return { ...state, fetchingEliminarNumeroDocumento: false };

        case MecanismosDePagoActions.LISTA_ACTIVOS_BY_CODIGO_INTERNO_REQUEST:
            return { ...state, fetchingListaActivosByCodigoInterno: true };
        case MecanismosDePagoActions.LISTA_ACTIVOS_BY_CODIGO_INTERNO_SUCCESS:
            return { ...state, fetchingListaActivosByCodigoInterno: false, listaActivosByCodigoInterno: action.listaActivosByCodigoInterno };
        case MecanismosDePagoActions.LISTA_ACTIVOS_BY_CODIGO_INTERNO_FAILURE:
            return { ...state, fetchingListaActivosByCodigoInterno: false };

        case MecanismosDePagoActions.CLEAN_ELEMENTS_DOCUMENTO_REITERACION:
            return { ...state, idSolicitudServicioReiterada: action.idSolicitudServicioReiterada, idSolicitudServicioOrigen: action.idSolicitudServicioOrigen, reiteracion: action.reiteracion, codigoInterno: action.codigoInterno, ticketOrigenSeleccionadoMODAL: action.ticketOrigenSeleccionadoMODAL, ticketOrigenSeleccionado: action.ticketOrigenSeleccionado, ticketReiteradoSeleccionado: action.ticketReiteradoSeleccionado, documentoRelacionado: action.documentoRelacionado};

        case MecanismosDePagoActions.CLEAN_ELEMENTS_DOCUMENTO_REITERACION_BEFORE:
            return { ...state, idSolicitudServicioOrigen: action.idSolicitudServicioOrigen, reiteracion: action.reiteracion, codigoInterno: action.codigoInterno, ticketOrigenSeleccionadoMODAL: action.ticketOrigenSeleccionadoMODAL, ticketOrigenSeleccionado: action.ticketOrigenSeleccionado, documentoRelacionado: action.documentoRelacionado};

        //Cálculo de deductivas por Reiteración para el mes i 
        case MecanismosDePagoActions.SHOW_MODAL_DETALLE_CALCULO_REITERACION:
            return { ...state, showModalCalculoDeductivasReiteracion: action.showModalCalculoDeductivasReiteracion, fechaProyeccion: action.fechaProyeccion, idMesCalendario: action.idMesCalendario };

        case MecanismosDePagoActions.REPORTE_RESUMEN_CALCULO_DEDUCTIVA_REITERACION_REQUEST:
            return { ...state, fetchingReporteResumenCalculoDR: true };
        case MecanismosDePagoActions.REPORTE_RESUMEN_CALCULO_DEDUCTIVA_REITERACION_SUCCESS:
            return { ...state, fetchingReporteResumenCalculoDR: false, reporteResumenCalculoDR: action.reporteResumenCalculoDR, reporteResumenCalculoDRCantidad: action.reporteResumenCalculoDRCantidad };
        case MecanismosDePagoActions.REPORTE_RESUMEN_CALCULO_DEDUCTIVA_REITERACION_FAILURE:
            return { ...state, fetchingReporteResumenCalculoDR: false };

        case MecanismosDePagoActions.EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_REITERACION_REQUEST:
            return { ...state, fetchingExportarExcelCDR: true };
        case MecanismosDePagoActions.EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_REITERACION_SUCCESS:
            return { ...state, fetchingExportarExcelCDR: false };
        case MecanismosDePagoActions.EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_REITERACION_FAILURE:
            return { ...state, fetchingExportarExcelCDR: false };

        case MecanismosDePagoActions.LISTA_DETALLE_CALCULO_REITERACION_REQUEST:
            return { ...state, fetchingListaDetalleCalculoReiteracion: true };
        case MecanismosDePagoActions.LISTA_DETALLE_CALCULO_REITERACION_SUCCESS:
            return { ...state, fetchingListaDetalleCalculoReiteracion: false, listaDetalleCalculoReiteracion: action.listaDetalleCalculoReiteracion, listaDetalleCalculoReiteracionCantidad: action.listaDetalleCalculoReiteracionCantidad };
        case MecanismosDePagoActions.LISTA_DETALLE_CALCULO_REITERACION_FAILURE:
            return { ...state, fetchingListaDetalleCalculoReiteracion: false };

        case MecanismosDePagoActions.EXPORTAR_EXCEL_DETALLE_CALCULO_REITERACION_REQUEST:
            return { ...state, fetchingExportarExcelDetalleCDR: true };
        case MecanismosDePagoActions.EXPORTAR_EXCEL_DETALLE_CALCULO_REITERACION_SUCCESS:
            return { ...state, fetchingExportarExcelDetalleCDR: false };
        case MecanismosDePagoActions.EXPORTAR_EXCEL_DETALLE_CALCULO_REITERACION_FAILURE:
            return { ...state, fetchingExportarExcelDetalleCDR: false };

        case MecanismosDePagoActions.PROCESAR_DEDUCTIVA_REITERACION_REQUEST:
            return { ...state, fetchingProcesarDeductivaReiteracion: true };
        case MecanismosDePagoActions.PROCESAR_DEDUCTIVA_REITERACION_SUCCESS:
            return { ...state, fetchingProcesarDeductivaReiteracion: false };
        case MecanismosDePagoActions.PROCESAR_DEDUCTIVA_REITERACION_FAILURE:
            return { ...state, fetchingProcesarDeductivaReiteracion: false };

        // Conciliacion
        case MecanismosDePagoActions.BUSQUEDA_FOLIO_TICKET_SS_REQUEST:
            return {...state, fetchingBusquedaTicketSS: true };
        case MecanismosDePagoActions.BUSQUEDA_FOLIO_TICKET_SS_SUCCESS:
                return {...state, fetchingBusquedaTicketSS: false, busquedaTicketSS: action.busquedaTicketSS, busquedaTicketSSobj: action.busquedaTicketSSobj };       
        case MecanismosDePagoActions.BUSQUEDA_FOLIO_TICKET_SS_FAILURE:
            return {...state, fetchingBusquedaTicketSS: false };

        case MecanismosDePagoActions.FALLAS_POR_TIPO_DE_FALLA_REQUEST:
            return {...state, fetchingFallasPorTipoFalla: true };
        case MecanismosDePagoActions.FALLAS_POR_TIPO_DE_FALLA_SUCCESS:
                return {...state, fetchingFallasPorTipoFalla: false, fallasPorTipoFalla: action.fallasPorTipoFalla };       
        case MecanismosDePagoActions.FALLAS_POR_TIPO_DE_FALLA_FAILURE:
            return {...state, fetchingFallasPorTipoFalla: false };   

        case MecanismosDePagoActions.NIVELES_DE_SEVERIDAD_POR_FALLA_REQUEST:
            return {...state, fetchingNivelesSeveridadPorFalla: true};    
        case MecanismosDePagoActions.NIVELES_DE_SEVERIDAD_POR_FALLA_SUCCESS:
            return {...state, fetchingNivelesSeveridadPorFalla: false, listaNivelesSeveridadPorFalla: action.listaNivelesSeveridadPorFalla }; 
        case MecanismosDePagoActions.NIVELES_DE_SEVERIDAD_POR_FALLA_FAILURE:
            return {...state, fetchingNivelesSeveridadPorFalla: false};

        case MecanismosDePagoActions.CREAR_CONCILIACION_REQUEST:
            return {...state, fetchingCrearConciliacion: true};    
        case MecanismosDePagoActions.CREAR_CONCILIACION_SUCCESS:
            return {...state, fetchingCrearConciliacion: false }; 
        case MecanismosDePagoActions.CREAR_CONCILIACION_FAILURE:
            return {...state, fetchingCrearConciliacion: false};
        
        case MecanismosDePagoActions.SECCIONES_POR_SERVICIO_REQUEST:
            return {...state, fetchingSeccionesPorServicio: true};
        case MecanismosDePagoActions.SECCIONES_POR_SERVICIO_SUCCESS:
            return {...state, fetchingSeccionesPorServicio: false, seccionesPorServicio: action.seccionesPorServicio};
        case MecanismosDePagoActions.SECCIONES_POR_SERVICIO_FAILURE:
            return {...state, fetchingSeccionesPorServicio: false};

        case MecanismosDePagoActions.FALLAS_POR_SERVICIO_REQUEST:
            return {...state, fetchingFallasPorServicio: true};
        case MecanismosDePagoActions.FALLAS_POR_SERVICIO_SUCCESS:
            return {...state, fetchingFallasPorServicio: false, fallasPorServicio: action.fallasPorServicio};
        case MecanismosDePagoActions.FALLAS_POR_SERVICIO_FAILURE:
            return {...state, fetchingFallasPorServicio: false};

        case MecanismosDePagoActions.REPORTE_RESUMEN_CALCULO_DEDUCTIVA_REITERACION_REQUEST:
            return { ...state, fetchingReporteResumenCalculoDR: true };
        case MecanismosDePagoActions.REPORTE_RESUMEN_CALCULO_DEDUCTIVA_REITERACION_SUCCESS:
            return { ...state, fetchingReporteResumenCalculoDR: false, reporteResumenCalculoDR: action.reporteResumenCalculoDR, reporteResumenCalculoDRCantidad: action.reporteResumenCalculoDRCantidad };
        case MecanismosDePagoActions.REPORTE_RESUMEN_CALCULO_DEDUCTIVA_REITERACION_FAILURE:
            return { ...state, fetchingReporteResumenCalculoDR: false };

        case MecanismosDePagoActions.EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_REITERACION_REQUEST:
            return { ...state, fetchingExportarExcelCDR: true };
        case MecanismosDePagoActions.EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_REITERACION_SUCCESS:
            return { ...state, fetchingExportarExcelCDR: false };
        case MecanismosDePagoActions.EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_REITERACION_FAILURE:
            return { ...state, fetchingExportarExcelCDR: false };

        case MecanismosDePagoActions.LISTA_DETALLE_CALCULO_REITERACION_REQUEST:
            return { ...state, fetchingListaDetalleCalculoReiteracion: true };
        case MecanismosDePagoActions.LISTA_DETALLE_CALCULO_REITERACION_SUCCESS:
            return { ...state, fetchingListaDetalleCalculoReiteracion: false, listaDetalleCalculoReiteracion: action.listaDetalleCalculoReiteracion, listaDetalleCalculoReiteracionCantidad: action.listaDetalleCalculoReiteracionCantidad };
        case MecanismosDePagoActions.LISTA_DETALLE_CALCULO_REITERACION_FAILURE:
            return { ...state, fetchingListaDetalleCalculoReiteracion: false };

        case MecanismosDePagoActions.EXPORTAR_EXCEL_DETALLE_CALCULO_REITERACION_REQUEST:
            return { ...state, fetchingExportarExcelDetalleCDR: true };
        case MecanismosDePagoActions.EXPORTAR_EXCEL_DETALLE_CALCULO_REITERACION_SUCCESS:
            return { ...state, fetchingExportarExcelDetalleCDR: false };
        case MecanismosDePagoActions.EXPORTAR_EXCEL_DETALLE_CALCULO_REITERACION_FAILURE:
            return { ...state, fetchingExportarExcelDetalleCDR: false };

        case MecanismosDePagoActions.PROCESAR_DEDUCTIVA_REITERACION_REQUEST:
            return { ...state, fetchingProcesarDeductivaReiteracion: true };
        case MecanismosDePagoActions.PROCESAR_DEDUCTIVA_REITERACION_SUCCESS:
            return { ...state, fetchingProcesarDeductivaReiteracion: false };
        case MecanismosDePagoActions.PROCESAR_DEDUCTIVA_REITERACION_FAILURE:
            return { ...state, fetchingProcesarDeductivaReiteracion: false };

        // case MecanismosDePagoActions.NIVELES_DE_SEVERIDAD_VAL:
        //     return {...state, nivelSeveridad: action.nivelSeveridad, habilitarSeveridad: action.habilitarSeveridad};


        // Editar Folio Conciliado

        case MecanismosDePagoActions.BUSQUEDA_FOLIO_CONCILIADO_REQUEST:
            return { ...state, fetchingBusquedaFolioConciliado: true };
        case MecanismosDePagoActions.BUSQUEDA_FOLIO_CONCILIADO_SUCCESS:
            return { ...state, fetchingBusquedaFolioConciliado: false , busquedaFolioConciliado: action.busquedaFolioConciliado, busquedaFolioConciliadoobj: action.busquedaFolioConciliadoobj };
        case MecanismosDePagoActions.BUSQUEDA_FOLIO_CONCILIADO_FAILURE:
            return { ...state, fetchingBusquedaFolioConciliado: false };

        case MecanismosDePagoActions.EDITAR_FOLIO_CONCILIADO_REQUEST:
            return { ...state, fetchingEditarFolioConciliado: true };
        case MecanismosDePagoActions.EDITAR_FOLIO_CONCILIADO_SUCCESS:
            return { ...state, fetchingEditarFolioConciliado: false };
        case MecanismosDePagoActions.EDITAR_FOLIO_CONCILIADO_FAILURE:
            return { ...state, fetchingEditarFolioConciliado: false };
    
        
            
        //Folio Conciliación
        case MecanismosDePagoActions.FOLIO_CONCILIACION_BUSQUEDA_REQUEST:
            return { ...state, fetchingFoliosConciliacion: true};
        case MecanismosDePagoActions.FOLIO_CONCILIACION_BUSQUEDA_SUCCESS:
            return { ...state, fetchingFoliosConciliacion: false, listaFoliosConciliacion: action.listaFoliosConciliacion, listaFolicosConciliacionCantidad: action.listaFolicosConciliacionCantidad };
        case MecanismosDePagoActions.FOLIO_CONCILIACION_BUSQUEDA_FAILURE:
            return { ...state, fetchingFoliosConciliacion: false};

        case MecanismosDePagoActions.MES_FOLIO_CONCILIAR_REQUEST:
            return { ...state, fetchingMesFolioConciliacion: true};
        case MecanismosDePagoActions.MES_FOLIO_CONCILIAR_SUCCESS:
            return { ...state, fetchingMesFolioConciliacion: false, listaMesesFolioConciliacion: action.listaMesesFolioConciliacion};
        case MecanismosDePagoActions.MES_FOLIO_CONCILIAR_FAILURE:
            return { ...state, fetchingMesFolioConciliacion: true};

        case MecanismosDePagoActions.SERVICIOS_FOLIO_CONCILIACION_REQUEST:
            return { ...state, fetchingServiciosFC: true}
        case MecanismosDePagoActions.SERVICIOS_FOLIO_CONCILIACION_SUCCESS:
            return { ...state, fetchingServiciosFC: false, listaServiciosFC: action.listaServiciosFC};
        case MecanismosDePagoActions.SERVICIOS_FOLIO_CONCILIACION_FAILURE:
            return { ...state, fetchingServiciosFC: false};

        case MecanismosDePagoActions.SS_BY_SERVICIO_REQUEST:
            return { ...state, fetchingSSByServicio: true}
        case MecanismosDePagoActions.SS_BY_SERVICIO_SUCCESS:
            return { ...state, fetchingSSByServicio: false, listaSSByServicio: action.listaSSByServicio, listaSSCantidad: action.listaSSCantidad ,listaSSByServicioCantidad: action.listaSSByServicioCantidad };
        case MecanismosDePagoActions.SS_BY_SERVICIO_FAILURE:
            return { ...state, fetchingSSByServicio: false};

        case MecanismosDePagoActions.SS_BY_FOLIO_REQUEST:
            return { ...state, fetchingSSByFolio: true}
        case MecanismosDePagoActions.SS_BY_FOLIO_SUCCESS:
            return { ...state, fetchingSSByFolio: false, listaSSByFolio: action.listaSSByFolio ,listaSSByFolioCantidad: action.listaSSByFolioCantidad };
        case MecanismosDePagoActions.SS_BY_FOLIO_FAILURE:
            return { ...state, fetchingSSByFolio: false};    
    
        case MecanismosDePagoActions.MODAL_NUEVO_FOLIO_CONCILIACION:
            return { ...state, showModalNuevoFolioConciliacion: action.showModalNuevoFolioConciliacion, page: action.page, busqueda: action.busqueda, listaSSCantidad: null};   
        case MecanismosDePagoActions.MODAL_EDITAR_FOLIO_CONCILIACION:
            return { ...state, showModalEditarFolioConciliacion: action.showModalEditarFolioConciliacion, folioConciliacionSeleccionado: action.folioConciliacionSeleccionado, page: action.page, busqueda: action.busqueda, listaSSByFolio:action.listaSSByFolio};
        case MecanismosDePagoActions.MODAL_CERRAR_FOLIO_CONCILIACION:
            return { ...state, showModalCerrarFolioConciliacion: action.showModalCerrarFolioConciliacion, folioConciliacionSeleccionado: action.folioConciliacionSeleccionado, page: action.page, busqueda: action.busqueda};

        case MecanismosDePagoActions.NUEVO_FOLIO_CONCILIACION_REQUEST:
            return { ...state, fetchingNuevoFolioConciliacion: true};
        case MecanismosDePagoActions.NUEVO_FOLIO_CONCILIACION_SUCCESS:
            return { ...state, fetchingNuevoFolioConciliacion: false,};
        case MecanismosDePagoActions.NUEVO_FOLIO_CONCILIACION_FAILURE:
            return { ...state, fetchingNuevoFolioConciliacion: false};

        case MecanismosDePagoActions.ELIMINAR_SS_FC_REQUEST:
            return { ...state, fetchinEliminarTicketFC: true};
        case MecanismosDePagoActions.ELIMINAR_SS_FC_SUCCESS:
            return { ...state, fetchinEliminarTicketFC: false};
        case MecanismosDePagoActions.ELIMINAR_SS_FC_FAILURE:
            return { ...state, fetchinEliminarTicketFC: false};

        case MecanismosDePagoActions.EDITAR_FOLIO_CONCILIACION_REQUEST:
            return { ...state, fetchingEditarFolioConciliacion: true};
        case MecanismosDePagoActions.EDITAR_FOLIO_CONCILIACION_SUCCESS:
            return { ...state, fetchingEditarFolioConciliacion: false};
        case MecanismosDePagoActions.EDITAR_FOLIO_CONCILIACION_FAILURE:
            return { ...state, fetchingEditarFolioConciliacion: false};

        case MecanismosDePagoActions.CERRAR_FOLIO_CONCILIACION_REQUEST:
            return { ...state, fetchingCerrarFolioConciliacion: true};
        case MecanismosDePagoActions.CERRAR_FOLIO_CONCILIACION_SUCCESS:
            return { ...state, fetchingCerrarFolioConciliacion: false};
        case MecanismosDePagoActions.CERRAR_FOLIO_CONCILIACION_FAILURE:
            return { ...state, fetchingCerrarFolioConciliacion: false};

        case MecanismosDePagoActions.IMPRIMIR_FOLIO_CONCILIACION_REQUEST:
            return { ...state, fetchingImprimirFolioConciliacion: true};
        case MecanismosDePagoActions.IMPRIMIR_FOLIO_CONCILIACION_SUCCESS:
            return { ...state, fetchingImprimirFolioConciliacion: false};
        case MecanismosDePagoActions.IMPRIMIR_FOLIO_CONCILIACION_FAILURE:
            return { ...state, fetchingImprimirFolioConciliacion: false};

        // Folio por Errores u Omisiones
        case MecanismosDePagoActions.FOLIOS_ERROR_OMISION_BUSQUEDA_REQUEST:
            return { ...state, fetchingFoliosErroresOmisiones: true};
        case MecanismosDePagoActions.FOLIOS_ERROR_OMISION_BUSQUEDA_SUCCESS:
            return { ...state, fetchingFoliosErroresOmisiones: false, listaFoliosErroresOmisiones: action.listaFoliosErroresOmisiones, listaFoliosErroresOmisionesCantidad: action.listaFoliosErroresOmisionesCantidad};
        case MecanismosDePagoActions.FOLIOS_ERROR_OMISION_BUSQUEDA_FAILURE:
            return { ...state, fetchingFoliosErroresOmisiones: false};

        case MecanismosDePagoActions.TICKET_SS_CONCILIADO_REQUEST:
            return { ...state, fetchingTicketSSConciliado: true};
        case MecanismosDePagoActions.TICKET_SS_CONCILIADO_SUCCESS:
            return { ...state, fetchingTicketSSConciliado: false, ticketSSConciliado: action.ticketSSConciliado};
        case MecanismosDePagoActions.TICKET_SS_CONCILIADO_FAILURE:
            return { ...state, fetchingTicketSSConciliado: false};

        case MecanismosDePagoActions.DOCUMENTOS_FISCALIZACION_REQUEST:
            return { ...state, fetchingDocumentosFiscalizacion: true};
        case MecanismosDePagoActions.DOCUMENTOS_FISCALIZACION_SUCCESS:
            return { ...state, fetchingDocumentosFiscalizacion: false, listaDocumentosFiscalizacion: action.listaDocumentosFiscalizacion};
        case MecanismosDePagoActions.DOCUMENTOS_FISCALIZACION_FAILURE:
            return { ...state, fetchingDocumentosFiscalizacion: false};

        case MecanismosDePagoActions.MODAL_NUEVO_FOLIO_EO:
            return { ...state, showModalNuevoFolioEO: action.showModalNuevoFolioEO, page: action.page, busqueda: action.busqueda}
        case MecanismosDePagoActions.MODAL_EDITAR_FOLIO_EO:
            return { ...state, showModalEditarFolioEO: action.showModalEditarFolioEO, folioErrorOmisionSeleccionado: action.folioErrorOmisionSeleccionado, page: action.page, busqueda: action.busqueda}
        case MecanismosDePagoActions.MODAL_DETALLE_FOLIO_EO:
            return { ...state, showModalDetalleFolioEO: action.showModalDetalleFolioEO, folioErrorOmisionSeleccionado: action.folioErrorOmisionSeleccionado}
        case MecanismosDePagoActions.MODAL_CERRAR_FOLIO_EO:
        return { ...state, showModalCerrarFolioEO: action.showModalCerrarFolioEO, folioErrorOmisionSeleccionado: action.folioErrorOmisionSeleccionado,page: action.page, busqueda: action.busqueda}

        case MecanismosDePagoActions.NUEVO_FOLIO_ERROR_OMISION_REQUEST:
            return { ...state, fetchingNuevoFolioEO: true};
        case MecanismosDePagoActions.NUEVO_FOLIO_ERROR_OMISION_SUCCESS:
            return { ...state, fetchingNuevoFolioEO: false};
        case MecanismosDePagoActions.NUEVO_FOLIO_ERROR_OMISION_FAILURE:
            return { ...state, fetchingNuevoFolioEO: false};

        case MecanismosDePagoActions.EDITAR_FOLIO_ERROR_OMISION_REQUEST:
            return { ...state, fetchingEditarFolioEO: true};
        case MecanismosDePagoActions.EDITAR_FOLIO_ERROR_OMISION_SUCCESS:
            return { ...state, fetchingEditarFolioEO: false};
        case MecanismosDePagoActions.EDITAR_FOLIO_ERROR_OMISION_FAILURE:
            return { ...state, fetchingEditarFolioEO: false};

        case MecanismosDePagoActions.CERRAR_FOLIO_ERROR_OMISION_REQUEST:
            return { ...state, fetchingCerrarFolioEO: true};
        case MecanismosDePagoActions.CERRAR_FOLIO_ERROR_OMISION_SUCCESS:
            return { ...state, fetchingCerrarFolioEO: false};
        case MecanismosDePagoActions.CERRAR_FOLIO_ERROR_OMISION_FAILURE:
            return { ...state, fetchingCerrarFolioEO: false};
        
        // CU - Cálculo de deductivas por Errores u Omisiones
        case MecanismosDePagoActions.SHOW_MODAL_DETALLE_CALCULO_ERROR:
            return { ...state, showModalDetalleCalculoError: action.showModalDetalleCalculoError, fechaProyeccion: action.fechaProyeccion, idMesCalendario: action.idMesCalendario };

        case MecanismosDePagoActions.REPORTE_RESUMEN_CALCULO_DEDUCTIVA_ERROR_REQUEST:
            return { ...state, fetchingReporteResumenCalculoEO: true };
        case MecanismosDePagoActions.REPORTE_RESUMEN_CALCULO_DEDUCTIVA_ERROR_SUCCESS:
            return { ...state, fetchingReporteResumenCalculoEO: false, reporteResumenCalculoEO: action.reporteResumenCalculoEO, reporteResumenCalculoEOCantidad: action.reporteResumenCalculoEOCantidad };
        case MecanismosDePagoActions.REPORTE_RESUMEN_CALCULO_DEDUCTIVA_ERROR_FAILURE:
            return { ...state, fetchingReporteResumenCalculoEO: false };
    
        case MecanismosDePagoActions.EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_ERROR_REQUEST:
            return { ...state, fetchingExportarExcelCEO: true };
        case MecanismosDePagoActions.EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_ERROR_SUCCESS:
            return { ...state, fetchingExportarExcelCEO: false };
        case MecanismosDePagoActions.EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_ERROR_FAILURE:
            return { ...state, fetchingExportarExcelCEO: false };
    
        case MecanismosDePagoActions.LISTA_DETALLE_CALCULO_ERROR_REQUEST:
            return { ...state, fetchingListaDetalleCalculoError: true };
        case MecanismosDePagoActions.LISTA_DETALLE_CALCULO_ERROR_SUCCESS:
            return { ...state, fetchingListaDetalleCalculoError: false, listaDetalleCalculoDEO: action.listaDetalleCalculoDEO, listaDetalleCalculoDEOCantidad: action.listaDetalleCalculoDEOCantidad };
        case MecanismosDePagoActions.LISTA_DETALLE_CALCULO_ERROR_FAILURE:
            return { ...state, fetchingListaDetalleCalculoError: false };
    
        case MecanismosDePagoActions.EXPORTAR_EXCEL_DETALLE_CALCULO_ERROR_REQUEST:
            return { ...state, fetchingExportarExcelDetalleCDEO: true };
        case MecanismosDePagoActions.EXPORTAR_EXCEL_DETALLE_CALCULO_ERROR_SUCCESS:
            return { ...state, fetchingExportarExcelDetalleCDEO: false };
        case MecanismosDePagoActions.EXPORTAR_EXCEL_DETALLE_CALCULO_ERROR_FAILURE:
            return { ...state, fetchingExportarExcelDetalleCDEO: false };
    
        case MecanismosDePagoActions.PROCESAR_DEDUCTIVA_ERROR_REQUEST:
            return { ...state, fetchingProcesarDeductivaError: true };
        case MecanismosDePagoActions.PROCESAR_DEDUCTIVA_ERROR_SUCCESS:
            return { ...state, fetchingProcesarDeductivaError: false };
        case MecanismosDePagoActions.PROCESAR_DEDUCTIVA_ERROR_FAILURE:
            return { ...state, fetchingProcesarDeductivaError: false };

        // CU - Pago por Servicios
        case MecanismosDePagoActions.LISTA_PAGO_POR_SERVICIO_MESES_REQUEST:
            return { ...state, fetchingListaPagoServiciosMeses: true };
        case MecanismosDePagoActions.LISTA_PAGO_POR_SERVICIO_MESES_SUCCESS:
            return { ...state, fetchingListaPagoServiciosMeses: false, listaPagoServiciosMeses: action.listaPagoServiciosMeses };
        case MecanismosDePagoActions.LISTA_PAGO_POR_SERVICIO_MESES_FAILURE:
            return { ...state, fetchingListaPagoServiciosMeses: false };

        case MecanismosDePagoActions.CALCULAR_PAGO_SERVICIO_REQUEST:
            return { ...state, fetchingCalcularPagoServicio: true };
        case MecanismosDePagoActions.CALCULAR_PAGO_SERVICIO_SUCCESS:
            return { ...state, fetchingCalcularPagoServicio: false, calculoPagoServicio: action.calculoPagoServicio, visibleCalculoPagoServicio: action.visibleCalculoPagoServicio };
        case MecanismosDePagoActions.CALCULAR_PAGO_SERVICIO_FAILURE:
            return { ...state, fetchingCalcularPagoServicio: false };

        case MecanismosDePagoActions.GUARDAR_CALCULO_PAGO_SERVICIO_REQUEST:
            return { ...state, fetchingGuardarCalculoPagoServicio: true };
        case MecanismosDePagoActions.GUARDAR_CALCULO_PAGO_SERVICIO_SUCCESS:
            return { ...state, fetchingGuardarCalculoPagoServicio: false, visibleCalculoPagoServicio: action.visibleCalculoPagoServicio };
        case MecanismosDePagoActions.GUARDAR_CALCULO_PAGO_SERVICIO_FAILURE:
            return { ...state, fetchingGuardarCalculoPagoServicio: false };

        case MecanismosDePagoActions.PAGO_SERVICIO_DETALLE_REQUEST:
            return { ...state, fetchingPagoServicioDetalle: true };
        case MecanismosDePagoActions.PAGO_SERVICIO_DETALLE_SUCCESS:
            return { ...state, fetchingPagoServicioDetalle: false, detallePagoServicio: action.detallePagoServicio, dtfsMayor: action.dtfsMayor };
        case MecanismosDePagoActions.PAGO_SERVICIO_DETALLE_FAILURE:
            return { ...state, fetchingPagoServicioDetalle: false };

        case MecanismosDePagoActions.CERRAR_PAGO_SERVICIO_REQUEST:
            return { ...state, fetchingCerrarPagoServicio: true };
        case MecanismosDePagoActions.CERRAR_PAGO_SERVICIO_SUCCESS:
            return { ...state, fetchingCerrarPagoServicio: false };
        case MecanismosDePagoActions.CERRAR_PAGO_SERVICIO_FAILURE:
            return { ...state, fetchingCerrarPagoServicio: false };

        // Pago de servicios públicos
        case MecanismosDePagoActions.LISTA_SERVICIOS_PUBLICOS_MES_REQUEST:
            return { ...state, fetchingListaServiciosPublicosMes: true };
        case MecanismosDePagoActions.LISTA_SERVICIOS_PUBLICOS_MES_SUCCESS:
            return { ...state, fetchingListaServiciosPublicosMes: false, listaServiciosPublicosMes: action.listaServiciosPublicosMes, listaServiciosPublicosMesCantidad: action.listaServiciosPublicosMesCantidad };
        case MecanismosDePagoActions.LISTA_SERVICIOS_PUBLICOS_MES_FAILURE:
            return { ...state, fetchingListaServiciosPublicosMes: false };

        case MecanismosDePagoActions.LISTA_SERVICIOS_PUBLICOS_GENERALES_REQUEST:
            return { ...state, fetchingListaServiciosPublicosGenerales: true };
        case MecanismosDePagoActions.LISTA_SERVICIOS_PUBLICOS_GENERALES_SUCCESS:
            return { ...state, fetchingListaServiciosPublicosGenerales: false, listaServiciosPublicosGenerales: action.listaServiciosPublicosGenerales, listaServiciosPublicosGeneralesCantidad: action.listaServiciosPublicosGeneralesCantidad };
        case MecanismosDePagoActions.LISTA_SERVICIOS_PUBLICOS_GENERALES_FAILURE:
            return { ...state, fetchingListaServiciosPublicosGenerales: false };

        case MecanismosDePagoActions.EXPORTAR_SERVICIOS_PUBLICOS_PDF_REQUEST:
            return { ...state, fetchingExportarServiciosPublicosPDF: true };
        case MecanismosDePagoActions.EXPORTAR_SERVICIOS_PUBLICOS_PDF_SUCCESS:
            return { ...state, fetchingExportarServiciosPublicosPDF: false };
        case MecanismosDePagoActions.EXPORTAR_SERVICIOS_PUBLICOS_PDF_FAILURE:
            return { ...state, fetchingExportarServiciosPublicosPDF: false };

        //RDO | Monitor de Deductivas
        case MecanismosDePagoActions.LISTA_MESES_PROCESADOS_REQUEST:
            return { ...state, fetchingListaMesesProcesados: true };
        case MecanismosDePagoActions.LISTA_MESES_PROCESADOS_SUCCESS:
            return { ...state, fetchingListaMesesProcesados: false, listaMesesProcesados: action.listaMesesProcesados };
        case MecanismosDePagoActions.LISTA_MESES_PROCESADOS_FAILURE:
            return { ...state, fetchingListaMesesProcesados: false };

        case MecanismosDePagoActions.RDO_DEDUCTIVAS_INTERNAS_REQUEST:
            return { ...state, fetchingListaRDODeductivasInternas: true };
        case MecanismosDePagoActions.RDO_DEDUCTIVAS_INTERNAS_SUCCESS:
            return { ...state, fetchingListaRDODeductivasInternas: false, listaRDODeductivasInternas: action.listaRDODeductivasInternas, infoRDO_Internas: action.infoRDO_Internas };
        case MecanismosDePagoActions.RDO_DEDUCTIVAS_INTERNAS_FAILURE:
            return { ...state, fetchingListaRDODeductivasInternas: false };

        case MecanismosDePagoActions.RDO_DEDUCTIVAS_EXTERNAS_REQUEST:
            return { ...state, fetchingListaRDODeductivasExternas: true };
        case MecanismosDePagoActions.RDO_DEDUCTIVAS_EXTERNAS_SUCCESS:
            return { ...state, fetchingListaRDODeductivasExternas: false, listaRDODeductivasExternas: action.listaRDODeductivasExternas, infoRDO_Externas: action.infoRDO_Externas };
        case MecanismosDePagoActions.RDO_DEDUCTIVAS_EXTERNAS_FAILURE:
            return { ...state, fetchingListaRDODeductivasExternas: false };

        case MecanismosDePagoActions.DETALLE_DEDUCTIVA_RDO_REQUEST:
            return { ...state, fetchingDetalleDeductivaRDO: true };
        case MecanismosDePagoActions.DETALLE_DEDUCTIVA_RDO_SUCCESS:
            return { ...state, fetchingDetalleDeductivaRDO: false, detalleDeductivaRDO: action.detalleDeductivaRDO };
        case MecanismosDePagoActions.DETALLE_DEDUCTIVA_RDO_FAILURE:
            return { ...state, fetchingDetalleDeductivaRDO: false };

        case MecanismosDePagoActions.DETALLE_DEDUCTIVA_RDO_EXCEL_REQUEST:
            return { ...state, fetchingDetalleDeductivaRDOExcel: true };
        case MecanismosDePagoActions.DETALLE_DEDUCTIVA_RDO_EXCEL_SUCCESS:
            return { ...state, fetchingDetalleDeductivaRDOExcel: false };
        case MecanismosDePagoActions.DETALLE_DEDUCTIVA_RDO_EXCEL_FAILURE:
            return { ...state, fetchingDetalleDeductivaRDOExcel: false };

        case MecanismosDePagoActions.DEDUCTIVA_POR_SERVICIO_REQUEST:
            return { ...state, fetchingDeductivaByServicio: true };
        case MecanismosDePagoActions.DEDUCTIVA_POR_SERVICIO_SUCCESS:
            return { ...state, fetchingDeductivaByServicio: false, deductivaByServicio: action.deductivaByServicio, infoRDO_DeductivaByServicio: action.infoRDO_DeductivaByServicio };
        case MecanismosDePagoActions.DEDUCTIVA_POR_SERVICIO_FAILURE:
            return { ...state, fetchingDeductivaByServicio: false };

        case MecanismosDePagoActions.DEDUCTIVAS_ANTERIORES_RDO_REQUEST:
            return { ...state, fetchingDeductivasAnterioresRDO: true };
        case MecanismosDePagoActions.DEDUCTIVAS_ANTERIORES_RDO_SUCCESS:
            return { ...state, fetchingDeductivasAnterioresRDO: false, deductivasAnterioresRDO: action.deductivasAnterioresRDO, infoRDO_DeductivasAnteriores: action.infoRDO_DeductivasAnteriores };
        case MecanismosDePagoActions.DEDUCTIVAS_ANTERIORES_RDO_FAILURE:
            return { ...state, fetchingDeductivasAnterioresRDO: false };

        //  Reporte de Deductiva
        case MecanismosDePagoActions.REPORTE_DEDUCTIVAS_RESUMEN_REQUEST:
            return { ...state, fetchingResumenReporteDeductivas: true };
        case MecanismosDePagoActions.REPORTE_DEDUCTIVAS_RESUMEN_SUCCESS:
            return { ...state, fetchingResumenReporteDeductivas: false, resumenReporteDeductivas: action.resumenReporteDeductivas };
        case MecanismosDePagoActions.REPORTE_DEDUCTIVAS_RESUMEN_FAILURE:
            return { ...state, fetchingResumenReporteDeductivas: false };

        case MecanismosDePagoActions.EXCEL_REPORTE_DEDUCTIVAS_RESUMEN_REQUEST:
            return { ...state, fetchingResumenReporteDeductivasExcel: true };
        case MecanismosDePagoActions.EXCEL_REPORTE_DEDUCTIVAS_RESUMEN_SUCCESS:
            return { ...state, fetchingResumenReporteDeductivasExcel: false };
        case MecanismosDePagoActions.EXCEL_REPORTE_DEDUCTIVAS_RESUMEN_FAILURE:
            return { ...state, fetchingResumenReporteDeductivasExcel: false };

        case MecanismosDePagoActions.DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_REQUEST:
            return { ...state, fetchingDetalleResumenReporteDeductivas: true };
        case MecanismosDePagoActions.DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_SUCCESS:
            return { ...state, fetchingDetalleResumenReporteDeductivas: false, detalleResumenReporteDeductivas: action.detalleResumenReporteDeductivas, detalleResumenReporteDeductivasCantidad: action.detalleResumenReporteDeductivasCantidad };
        case MecanismosDePagoActions.DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_FAILURE:
            return { ...state, fetchingDetalleResumenReporteDeductivas: false };

        case MecanismosDePagoActions.EXCEL_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_REQUEST:
            return { ...state, fetchingDetalleResumenReporteDeductivasExcel: true };
        case MecanismosDePagoActions.EXCEL_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_SUCCESS:
            return { ...state, fetchingDetalleResumenReporteDeductivasExcel: false };
        case MecanismosDePagoActions.EXCEL_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_FAILURE:
            return { ...state, fetchingDetalleResumenReporteDeductivasExcel: false };

        case MecanismosDePagoActions.PDF_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_REQUEST:
            return { ...state, fetchingDetalleResumenReporteDeductivasPDF: true };
        case MecanismosDePagoActions.PDF_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_SUCCESS:
            return { ...state, fetchingDetalleResumenReporteDeductivasPDF: false };
        case MecanismosDePagoActions.PDF_DETALLE_REPORTE_DEDUCTIVAS_RESUMEN_FAILURE:
            return { ...state, fetchingDetalleResumenReporteDeductivasPDF: false };

        case MecanismosDePagoActions.CONCENTRADO_ANUAL_RESUMEN_REQUEST:
            return { ...state, fetchingConcentradoAnualResumen: true };
        case MecanismosDePagoActions.CONCENTRADO_ANUAL_RESUMEN_SUCCESS:
            return { ...state, fetchingConcentradoAnualResumen: false, concentradoAnualResumen: action.concentradoAnualResumen, concentrado: action.concentrado };
        case MecanismosDePagoActions.CONCENTRADO_ANUAL_RESUMEN_FAILURE:
            return { ...state, fetchingConcentradoAnualResumen: false };

        case MecanismosDePagoActions.EXCEL_CONCENTRADO_ANUAL_RESUMEN_REQUEST:
            return { ...state, fetchingExcelResumenConcentradoAnual: true };
        case MecanismosDePagoActions.EXCEL_CONCENTRADO_ANUAL_RESUMEN_SUCCESS:
            return { ...state, fetchingExcelResumenConcentradoAnual: false };
        case MecanismosDePagoActions.EXCEL_CONCENTRADO_ANUAL_RESUMEN_FAILURE:
            return { ...state, fetchingExcelResumenConcentradoAnual: false };


        case MecanismosDePagoActions.EXCEL_DETALLE_CONCENTRADO_ANUAL_RESUMEN_REQUEST:
            return { ...state, fetchingExcelDetalleConcentradoAnual: true };
        case MecanismosDePagoActions.EXCEL_DETALLE_CONCENTRADO_ANUAL_RESUMEN_SUCCESS:
            return { ...state, fetchingExcelDetalleConcentradoAnual: false };
        case MecanismosDePagoActions.EXCEL_DETALLE_CONCENTRADO_ANUAL_RESUMEN_FAILURE:
            return { ...state, fetchingExcelDetalleConcentradoAnual: false };

        case MecanismosDePagoActions.DETALLE_CONCENTRADO_ANUAL_RESUMEN_REQUEST:
            return { ...state, fetchingDetalleConcentradoAnual: true };
        case MecanismosDePagoActions.DETALLE_CONCENTRADO_ANUAL_RESUMEN_SUCCESS:
            return { ...state, fetchingDetalleConcentradoAnual: false, detalleConcentradoAnual: action.detalleConcentradoAnual, detalleConcentradoAnualCantidad: action.detalleConcentradoAnualCantidad };
        case MecanismosDePagoActions.DETALLE_CONCENTRADO_ANUAL_RESUMEN_FAILURE:
            return { ...state, fetchingDetalleConcentradoAnual: false };

        default:
            return state;
    }
}
