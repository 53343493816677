import React, {Component} from 'react'
import { Modal } from 'antd';
import { connect } from "react-redux";
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import EditarFolio from './EditarFolio';
import ListaPorTipoDeFolio from './ListaPorTipoDeFolio';
import ListaProductosSeleccionadosEditar from './ListaProductosSeleccionadosEditar';

class ContenedorEditarFolio extends Component {
    render() { 
        const {onShowModalEditarFolio, onCambiarVista, vistaModalEditarFolio,
            showModalEditarFolio, onBorrarUbicacionOrigen, 
            onBorrarUbicacionDestino, onBorrarListas, onBorrarListasRequisicion,
            onActualizarTabla
        } = this.props;
        
        const handleCancel = () => {
            onShowModalEditarFolio(this.props.movimientoSeleccionado, this.props.page);
            onCambiarVista(1);
            onBorrarUbicacionOrigen();
            onBorrarUbicacionDestino();
            onBorrarListas();
            onBorrarListasRequisicion();
            onActualizarTabla(null, [], 1, true);
        }

        function ContenidoEditar() {
            if(vistaModalEditarFolio === 1){
                return(
                    <EditarFolio/>
                );
            }
            else if(vistaModalEditarFolio === 2){
                return(
                    <ListaPorTipoDeFolio/>
                );
            }
            else if(vistaModalEditarFolio === 3){
                return(
                    <ListaProductosSeleccionadosEditar/>
                );
            }
        };
        
        return ( 
            <Modal
               visible={showModalEditarFolio}
               title='Editar Folio'
               onCancel={handleCancel}
               footer={false}
               width={'70%'}
            >
                <ContenidoEditar />
            </Modal>
         );
    }
}
 
const mapStateToProps = state => {
	return {
        showModalEditarFolio: state.AlmacenesReducer.showModalEditarFolio,
        vistaModalEditarFolio: state.AlmacenesReducer.vistaModalEditarFolio,
        movimientoSeleccionado: state.AlmacenesReducer.movimientoSeleccionado, 
        page: state.AlmacenesReducer.page,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarFolio: (movimientoSeleccionado, page) => {
            console.log('movimientoSeleccionado, page', movimientoSeleccionado, page)
            dispatch({ type: "SHOW_MODAL_EDITAR_FOLIO", showModalEditarFolio: false, movimientoSeleccionado, page})
        },
        onCambiarVista: (vistaModalEditarFolio) => {
            dispatch({ type: "CAMBIAR_VISTA_MODAL_EDITARFOLIO", vistaModalEditarFolio});
        },
        onBorrarUbicacionOrigen: () => {
            dispatch({ type: "OBTENER_UBICACION_POR_ALMACEN_SUCCESS", listaUbicaciones: null});
        },
        onBorrarUbicacionDestino: () => {
            dispatch({ type: "UBICACION_DESTINO_POR_ALMACEN_SUCCESS", listaUbicacionesDestino: null});
        },
        onBorrarListas: () => {
            dispatch({ type: "LISTA_PRODUCTOS_FILTRADO_SUCCESS", listaProductosFiltradoRequest: null});
        }, 
        onBorrarListasRequisicion: () => {
            dispatch({ type: "LISTA_REQUISICION_FILTRADO_SUCCESS", listaProductosFiltradoRequest: null});
        },
        onActualizarTabla: (listaSeleccionados, selectedRowKeys, pageMovimientos, hasSelected) => {
            dispatch({ type: 'ACTUALIZAR_TABLA_MOVIMIENTOS', listaSeleccionados, selectedRowKeys, pageMovimientos, hasSelected})
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContenedorEditarFolio);