import React from 'react';
import {connect} from 'react-redux';
import {Table, Row, Col, Modal, Descriptions} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

class ModalDetalleOrdenSP extends React.Component {
    formRef = React.createRef();

    state = {
        page: 1
    }

    render () {

        const {showModalDetalleOrdenesSPCancelacion,ordenCancelacionSPSeleccionado,fetchingCancelacionOrdenesSPDetalle,cancelacionOrdenesSPDetalle,cancelacionOrdenesSPDetalleCantidad,onShowModalDetalle, onLimpiarModalDetalle} = this.props;

        const columns = ordenCancelacionSPSeleccionado?.programa == "Rutina" ? 
        [{
            title: 'Fecha de Ejecución',
            dataIndex: 'fechaEjecucion',
            key: 'fechaEjecucion',
            // width: '40%',
        },{
            title: 'Ubicación',
            dataIndex: 'ubicacion',
            key: 'ubicacion',
            // width: '40%',
        }]: 
        [{
            title: 'Fecha de Ejecución',
            dataIndex: 'fechaEjecucion',
            key: 'fechaEjecucion',
            // width: '40%',
        },{
            title: 'Ubicación',
            dataIndex: 'ubicacion',
            key: 'ubicacion',
            // width: '40%',
        },{
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
            // width: '40%',
        },{
            title: 'Código Interno',
            dataIndex: 'codigoInterno',
            key: 'codigoInterno',
            // width: '30%',
        },{
            title: 'Descripción activo',
            dataIndex: 'descripcion',
            key: 'descripcion',
            // width: '40%',
        }]

        const handleCancel = () =>{
            onShowModalDetalle();
            onLimpiarModalDetalle();
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
        }

        return (
            <div>
                {ordenCancelacionSPSeleccionado ? (
                    <Modal
                        visible={showModalDetalleOrdenesSPCancelacion}
                        title={`Detalle de Actividad de la Orden: '${ordenCancelacionSPSeleccionado.folioOrden}'`}
                        onCancel={handleCancel}
                        footer={false}
                        width='80%'
                    > 
                        <Row type='flex' justify='space-around'>
                            <Col span={24}>
                                <Descriptions layout="horizontal" bordered={true}>
                                    <Descriptions.Item label='Actividades'>{ordenCancelacionSPSeleccionado.actividades}</Descriptions.Item>
                                    {ordenCancelacionSPSeleccionado.observaciones ? (<Descriptions.Item label='Observaciones'>{ordenCancelacionSPSeleccionado.observaciones}</Descriptions.Item>) : false }
                                </Descriptions>
                            </Col>    
                        </Row>
                        <br/>
                        <Row type='flex' justify='space-around'>
                            <Col span={24}>
                                <Table
                                    size='small'
                                    rowKey='ubicacion'
                                    columns={columns}
                                    dataSource={cancelacionOrdenesSPDetalle}
                                    loading={fetchingCancelacionOrdenesSPDetalle}
                                    locale={{ emptyText: 'Sin datos' }}
                                    onChange={handleTableChange}
                                    pagination={{ total: cancelacionOrdenesSPDetalleCantidad, simple: true, current: this.state.page, hideOnSinglePage: true }}
                                />     
                            </Col>
                        </Row>                    
                    </Modal>
                ) : false}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalDetalleOrdenesSPCancelacion: state.ServiciosPlanificadosReducer.showModalDetalleOrdenesSPCancelacion,
        ordenCancelacionSPSeleccionado: state.ServiciosPlanificadosReducer.ordenCancelacionSPSeleccionado,
        fetchingCancelacionOrdenesSPDetalle: state.ServiciosPlanificadosReducer.fetchingCancelacionOrdenesSPDetalle,
        cancelacionOrdenesSPDetalle: state.ServiciosPlanificadosReducer.cancelacionOrdenesSPDetalle,
        cancelacionOrdenesSPDetalleCantidad: state.ServiciosPlanificadosReducer.cancelacionOrdenesSPDetalleCantidad,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalDetalle: () => {
            dispatch({ type: 'SHOW_MODAL_DETALLE_CANCELACION_ORDEN_SP', showModalDetalleOrdenesSPCancelacion: false});
        },
        onLimpiarModalDetalle: () => {
            dispatch({ type: 'LISTA_DETALLE_CANCELACION_ORDEN_SP_SUCCESS',cancelacionOrdenesSPDetalle: [], cancelacionOrdenesSPDetalleCantidad: null})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalDetalleOrdenSP);