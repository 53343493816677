import React, { useState } from 'react';
import { Form, Input, Button, Col, Spin, Modal, Select, Row, Radio, Tooltip } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

const { Option } = Select;

const ModalNuevoVariable = () => {
    const [timeIsRequired, setTimeIsRequired] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const showModalNuevaVariable = useSelector((state) => state.ServiciosVariablesReducer.showModalNuevaVariable);
    const fetchingCrearVariable = useSelector((state) => state.ServiciosVariablesReducer.fetchingCrearVariable);
    const fetchingUnidadesMediPorCategoria = useSelector((state) => state.AlmacenesReducer.fetchingUnidadesMediPorCategoria);
    const unidadesMediPorCategoria = useSelector((state) =>  state.AlmacenesReducer.unidadesMediPorCategoria);
    const page = useSelector((state) => state.ServiciosVariablesReducer.page);

    const handleCancel = () => {
        dispatch({
            type: 'SHOW_MODAL_CREAR_VARIABLE',
            showModalNuevaVariable: false
        });
        form.resetFields();
    };

    const onFinish = formulario => {
        console.log('formulario:', formulario);
        dispatch({ 
            type: "CREAR_VARIABLES_REQUEST",
            formulario, 
            page
        });
    }  

    const onChangeRadioBtn = (e) =>{
        setTimeIsRequired(e.target.value)
    }

    const layout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };
    
    console.log("unidadesMediPorCategoria",unidadesMediPorCategoria)

    return(
        <div>
            <Modal
                visible={showModalNuevaVariable}
                title='Nueva Variable'
                onCancel={handleCancel}
                footer={false}
                width='40%'
            >
                <Spin spinning={fetchingCrearVariable}>
                    <Form 
                        {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        form={form} 
                    >
                        <Row type='flex'>
                            <Col span={24}>
                                <Form.Item
                                    label="Nombre de la variable"
                                    name="variable"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                <Input
                                    pattern="^^[ñíóáéú a-zA-Z 0-9]+$"
                                    title="Nombre debe tener letras y numeros."
                                    maxLength={100} placeholder='Ingrese nombre de la variable' />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Considerar Tiempo"
                                    name="considerTiempo"
                                >
                                    <Radio.Group onChange={onChangeRadioBtn} defaultValue={timeIsRequired}>
                                        <Radio value={true}>Si</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            {timeIsRequired === true &&
                                <Col span={14} offset={1}>
                                    <Form.Item
                                        label="Tiempo"
                                        name="tiempo"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Input maxLength={100} placeholder='Ingrese el tiempo' />
                                    </Form.Item>
                                </Col>
                            }
                            </Row>
                            <Row>
                            <Form.List name="subVariables">
                            {(fields, { add, remove }) => (
                            <>
                                {fields.map((field) => (
                                <>
                                    <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, curValues) =>
                                        prevValues.nombre !== curValues.nombre || prevValues.idUnidadMedida !== curValues.idUnidadMedida
                                    }
                                    >
                                    {() => (
                                        <Col span={11}>
                                            <Form.Item
                                            {...field}
                                            label="Nombre Subvariable"
                                            name={[field.name, 'nombre']}
                                            rules={[
                                                {
                                                required: true,
                                                message: 'Favor de llenar el campo',
                                                },
                                            ]}
                                            >
                                                <Input maxLength={100} placeholder='Nombre de la Subvariable' />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    </Form.Item>
                                    <Col span={10} offset={1}>
                                        <Form.Item
                                        label="Unidad de medida"
                                        name={[field.name, 'idUnidadMedida']}
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                            <Select allowClear
                                                style={{ width: '100%' }}
                                                placeholder="Seleccione la unidad de medida"
                                                loading={fetchingUnidadesMediPorCategoria}
                                            >
                                                {unidadesMediPorCategoria.map(option => <Option key={option.idUnidadMedida}>{option.unidadMedida}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Tooltip placement="topLeft" title="Eliminar Subvariable"><MinusCircleOutlined onClick={() => remove(field.name)} /></Tooltip>
                                </>
                                ))}
                                <Col span={24}>
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Agregar Subvariable
                                    </Button>
                                </Form.Item>
                                </Col>
                            </>
                            )}
                            </Form.List>
                            </Row>
                            <Col span={24}>
                                <Button className="btnFiltrar" htmlType="submit">Crear Variable</Button>
                            </Col>
                    </Form>
                </Spin>
            </Modal>
        </div>
    )
}



export default ModalNuevoVariable;