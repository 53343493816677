import React from 'react';
import { Card, Table, Row, Col, Select, Button, Switch, Modal, Tooltip } from 'antd';
import { EditOutlined, EyeOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalVerPDFPlanCalidad from './ModalVerPDFPlanCalidad';
import ModalNuevoPlanCalidad from './ModalNuevoPlanCalidad';
import ModalEditarPlanCalidad from './ModalEditarPlanCalidad';

const { Option } = Select;
const confirm = Modal.confirm;
class GestionDePlanDeCalidadContainer extends React.Component {
     componentDidMount(){
          this.props.onListaPlanesCalidad(1);
          this.props.onRequestInfoProyecto();
          this.props.onPlanCalidadAños();
     }

     state = {
          listaAños: [],
          page: 1,
     }
 
     render() {
          const { listaPlanCalidadAños, onListaPlanesCalidad, fetchingListaPlanesCalidadBusqueda, onShowModalVerPDFPlanCalidad,
               listaPlanesCalidadBusqueda, listaPlanesCalidadBusquedaCantidad, onEstadoPlanCalidad, fetchingListaPlanCalidadAños,
               onShowModalNuevoPlanCalidad, onShowModalEditarPlanCalidad, 
          } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
             permisosUsuario = JSON.parse(permisosUsuario);
          }

          const columns = [{
                    title: 'Nombre del documento',
                    dataIndex: 'nombreArchivo',
                    key: 'nombreArchivo',
                    width: '20%',
               }, {
                    title: 'Año',
                    dataIndex: 'anio',
                    key: 'anio',
                    width: '10%',
               }, {
                    title: 'Nomenclatura',
                    dataIndex: 'nomenclatura',
                    key: 'nomenclatura',
                    width: '10%',
               }, {
                    title: 'Versión',
                    dataIndex: 'version',
                    key: 'version',
                    width: '10%',
               }, {
                    title: 'Activo', 
                    dataIndex: 'activo', 
                    key: 'activo',
                    width: '10%',
                    align: 'center',
                    render: (text, record) => (
                         <Switch checked={text} disabled={!permisosUsuario.planDeCalidadActivoinactivo} onChange={() => handleDesactivar(record)}/>
                    ),
               },{
                    title: 'Editar',
                    key: 'editar',
                    width: '10%',
                    align: 'center',
                    render: (text, record) => (
                         <a className='editarIcono' disabled={!permisosUsuario.editarPlanDeCalidad} onClick={() => onEditar(record)}><EditOutlined /></a>
                    ),
               },{
                    title: 'Consultar',
                    key: 'consultar',
                    width: '10%',
                    align: 'center',
                    render: (text, record) => (
                         <a className='editarIcono' disabled={!permisosUsuario.consultarPlanDeCalidad} onClick={() => onMostrarPDF(record)}><EyeOutlined /></a>
                    ),
               },{
                    title: 'Descargar',
                    key: 'descargar',
                    width: '10%',
                    align: 'center',
                    render: (text, record) => (
                         <a className='editarIcono' disabled={!permisosUsuario.descargarPlanDeCalidad} target='_blank' href={record.rutaArchivo}><DownloadOutlined /></a>
                    ),
               },
          ];

          const onMostrarPDF = (key) => {
               onShowModalVerPDFPlanCalidad(key);
          }

          const handleDesactivar = (key) => {
               console.log(key);
               const page = this.state.page;
               const busqueda = this.state.busqueda;
               confirm({
                    title: '¿Estás seguro que deseas cambiar el estado del plan de calidad?',
                    content: '',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                         onEstadoPlanCalidad(key.idGestionCalidad, page, busqueda)
                    },
                    onCancel() {
                         console.log('Cancel');
                    },
               });
          }

          const onEditar = (key) => {
               console.log(key);
               onShowModalEditarPlanCalidad(key, this.state.page, this.state.busqueda);
          }

          const onNuevoPlanGestion = () => {
               onShowModalNuevoPlanCalidad(this.state.page, this.state.busqueda);
          }

          const onChangeAño = (value) => {
               this.setState({
                    busqueda: value
               });
               onListaPlanesCalidad(this.state.page, value);
          };
          const handleTableChange = (pagination)=>{
               this.setState({
                   page: pagination.current,
               })
               onListaPlanesCalidad(pagination.current, this.state.busqueda);
          };

          return (
               <Card className="cardCatalogos" title={<div className='headerLista'>Gestión De Plan De Calidad</div>}>
                   <Row justify='end' gutter={[8,8]}>
                         <Col span={4}>
                              <Select allowClear
                                   style={{ width: '100%' }}
                                   placeholder="Selecciona el año a filtrar"
                                   loading={fetchingListaPlanCalidadAños}
                                   onChange={onChangeAño}
                              >
                                   {listaPlanCalidadAños.map(option => <Option key={option}>{option}</Option>)}
                              </Select>
                         </Col>
                         {/* <Col span={7}>
                              <Button onClick={onNuevoPlanGestion} disabled={!permisosUsuario.crearPlanDeCalidad} className='nuevoUsuario'>Nuevo Plan de Calidad</Button>
                         </Col> */}
                         <Col span={1}>
                              <Tooltip title='Nuevo Plan de Calidad' placement="topRight">
                                   <Button className="iconTer" shape="circle" onClick={onNuevoPlanGestion} disabled={!permisosUsuario.crearPlanDeCalidad} icon={<PlusOutlined />}/>
                              </Tooltip>
                         </Col>
                    </Row>
                    <Row justify='space-between' gutter={[8,8]}>
                         <Col span={24}>
                              <Table 
                                   size="small" 
                                   rowKey="idGestionCalidad"
                                   columns={columns}
                                   dataSource={listaPlanesCalidadBusqueda}
                                   loading={fetchingListaPlanesCalidadBusqueda}
                                   locale={{ emptyText: 'Sin datos' }}
                                   onChange={handleTableChange}
                                   pagination={{ total: listaPlanesCalidadBusquedaCantidad, simple: true, current: this.state.page}}
                              />         
                         </Col>
                    </Row>
                    <ModalVerPDFPlanCalidad/>
                    <ModalNuevoPlanCalidad/>
                    <ModalEditarPlanCalidad/>
               </Card>
          );
     }
}
 
 const mapStateToProps = state => {
     return {
          fetchingListaPlanesCalidadBusqueda: state.GestionDeCalidadReducer.fetchingListaPlanesCalidadBusqueda,
          listaPlanesCalidadBusqueda: state.GestionDeCalidadReducer.listaPlanesCalidadBusqueda,
          listaPlanesCalidadBusquedaCantidad: state.GestionDeCalidadReducer.listaPlanesCalidadBusquedaCantidad,
          listaPlanCalidadAños: state.GestionDeCalidadReducer.listaPlanCalidadAños,
          fetchingListaPlanCalidadAños: state.GestionDeCalidadReducer.fetchingListaPlanCalidadAños,
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          onRequestInfoProyecto: () => {
               dispatch({ type: 'GET_INFOPROYECTO_REQUEST', planCalidadAños: true });
          },
          onListaPlanesCalidad: (page, busqueda) => {
               dispatch({ type: 'LISTA_PLANES_CALIDAD_BUSQUEDA_REQUEST', page, busqueda })
          },
          onEstadoPlanCalidad: (idGestionCalidad, page, busqueda) => {
               dispatch({ type: 'ESTADO_PLAN_CALIDAD_REQUEST', idGestionCalidad, page, busqueda })
          },
          onShowModalVerPDFPlanCalidad: (planCalidadSeleccionado) => {
               dispatch ({ type: 'MODAL_VER_PLAN_CALIDAD', showModalVerPlanCalidad: true, planCalidadSeleccionado })
          },
          onPlanCalidadAños: () => {
               dispatch ({ type: 'LISTA_AÑOS_PLAN_CALIDAD_REQUEST' });
          },
          onShowModalNuevoPlanCalidad: (page, busqueda) => {
               dispatch ({ type: 'MODAL_NUEVO_PLAN_CALIDAD', showModalNuevoPlanCalidad: true, page, busqueda })
          },
          onShowModalEditarPlanCalidad: (planCalidadSeleccionado, page, busqueda) => {
               dispatch ({ type: 'MODAL_EDITAR_PLAN_CALIDAD', showModalEditarPlanCalidad: true, planCalidadSeleccionado, page, busqueda })
          },
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(GestionDePlanDeCalidadContainer);