import React from 'react';
import { Row, Col, Button, Select, Form, Input, Table, Modal, Card} from 'antd';
import { connect } from 'react-redux';
import MovimientoAlmacenContainer from '../MovimientosAlmacen/MovimientoAlmacenContainer';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const confirm = Modal.confirm;
const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ReporteFaltantes extends React.Component {
    formRef = React.createRef();

    state = {
        page: 1,
    }

    componentDidMount () {
        this.props.onSetReporteFaltantesInicial();
    }
    
    render() {
        const {fetchingListaFaltantesInventarioFisico, listaFaltantesInvetarioFisico,listaFaltantesInvetarioFisicoCantidad, habilitarCerrarFolioInventarioFisico,
            onGetReporteFaltantes, onDescargaReporteFaltantes, onCerrarFolioInventario, fetchingListaFaltantesInventarioFisicoDescarga
        } = this.props;
        const {page} = this.state;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);    
        }
        
        const columns = [
            {
                title: 'Ubicación',
                dataIndex: 'ubicacion',
                key: 'ubicacion',
            },
            {
                title: 'SKU',
                dataIndex: 'sku',
                key: 'sku',
            },
            {
                title: 'Clave del producto',
                dataIndex: 'clave',
                key: 'clave',
            },
            {
                title: 'Nombre del producto',
                dataIndex: 'nombre',
                key: 'nombre',
            },
            {
                title: 'Unidad de medida',
                dataIndex: 'unidadMedida',
                key: 'unidadMedida',
            },
            {
                title: 'Cantidad Sistema',
                dataIndex: 'cantidadInventario',
                key: 'cantidadInventario',
                align: 'center',
                width: '12%',
            },
            {
                title: 'Cantidad Físico',
                dataIndex: 'cantidadFisico',
                key: 'cantidadFisico',
                align: 'center',
                width: '12%', 
            }
        ]


        const onFinish = formulario => {
            console.log('Success:', formulario);
            onGetReporteFaltantes(formulario, page);
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo)
        };

        const onDescargaReporte = () => {
            const formulario = this.formRef.current.getFieldsValue();
            onDescargaReporteFaltantes(formulario);
        }

        const onCerrarFolio = () => {
            const formulario = this.formRef.current.getFieldsValue();
            confirm({
                title: '¿Estás seguro que deseas cerrar el folio de inventario "' + formulario.folio + '"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onCerrarFolioInventario(formulario);
                },
                onCancel() {
                    console.log('Cancel');
                },    
            })
            
        }
        
        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            const formulario = this.formRef.current.getFieldsValue();
            onGetReporteFaltantes(formulario, pagination.current);
        };


        return (
        <Card className="cardCatalogos" title={<div className="headerLista">Reporte de Diferencias de Inventario</div>}>
            <Form
            {...layout}
            name="formularios"
            ref={this.formRef}
            onFinish= {onFinish}
            
            >
                <Row justify='space-between' >
                    <Col span={8}>
                        <Form.Item
                            name='folio'
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Input maxLength={100} placeholder='Ingrese número de folio de inventario'/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name='conteo'
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Select
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Seleccione el número de conteo"
                            >
                                <Option value={1}>Primer conteo</Option>
                                <Option value={2}>Segundo conteo</Option>
                                <Option value={3}>Tercer conteo</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Button className="btnFiltrar" disabled={!permisosUsuario.obtenerDiferenciasDeInventario} style={{ width: '100%' }} htmlType="submit">Obtener diferencias</Button>
                    </Col>
                    <Col span={24}>
                        <br />
                        <Table
                            size="small"
                            rowKey="clave"
                            columns={columns}
                            dataSource={listaFaltantesInvetarioFisico}
                            loading={fetchingListaFaltantesInventarioFisico}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaFaltantesInvetarioFisicoCantidad, simple: true, current: this.state.page }}
                        />
                    </Col>
                    <Col span={11}>
                        <Button className="btnFiltrar" loading={fetchingListaFaltantesInventarioFisicoDescarga} disabled={permisosUsuario.imprimirListaDeProductosConDiferencia && listaFaltantesInvetarioFisico.length > 0 ? false:true}  style={{ width: '100%' }} onClick={onDescargaReporte}>Imprimir Lista de Productos con Diferencia</Button>
                    </Col>
                    <Col span={11}>
                        <Button className="btnFiltrar" disabled={permisosUsuario.cerrarFolioDeInventarioConDiferencias && habilitarCerrarFolioInventarioFisico  > 0 ? false:true}  style={{ width: '100%' }} onClick={onCerrarFolio} >Cerrar Folio de Inventario</Button>
                    </Col>
                </Row>    
            </Form>
        </Card>         
        );
    };
};

const mapStateToProps = state => {
    return {
        fetchingListaFaltantesInventarioFisico: state.AlmacenesReducer.fetchingListaFaltantesInventarioFisico,
        listaFaltantesInvetarioFisico: state.AlmacenesReducer.listaFaltantesInvetarioFisico,
        listaFaltantesInvetarioFisicoCantidad:state.AlmacenesReducer.listaFaltantesInvetarioFisicoCantidad,
        fetchingListaFaltantesInventarioFisicoDescarga: state.AlmacenesReducer.fetchingListaFaltantesInventarioFisicoDescarga,
        habilitarCerrarFolioInventarioFisico : state.AlmacenesReducer.habilitarCerrarFolioInventarioFisico,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetReporteFaltantesInicial: () => {
            dispatch({ type: 'LISTA_FALTANTES_INVENTARIO_FISICO_SUCCESS', fetchingListaFaltantesInventarioFisico: false, listaFaltantesInvetarioFisico: [], listaFaltantesInvetarioFisicoCantidad: null})
        },
        onGetReporteFaltantes: (formulario, page) => {
            dispatch({ type: 'LISTA_FALTANTES_INVENTARIO_FISICO_REQUEST', formulario:formulario, page: page});
        }, 
        onDescargaReporteFaltantes: (formulario) => {
            dispatch({ type: 'LISTA_FALTANTES_INVENTARIO_FISICO_DESCARGA_REQUEST', formulario:formulario});
        }, 
       onCerrarFolioInventario: (formulario) => {
           dispatch({ type: "CERRAR_FOLIO_AJUSTE_INVENTARIO_REQUEST", formulario:formulario});
       }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReporteFaltantes);