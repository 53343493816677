import React, {Component} from 'react'
import {Button, Col, DatePicker, Form, Input, message, Modal, Row, Select, Spin, Tooltip, Upload} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import {connect} from "react-redux";
import {UploadOutlined} from '@ant-design/icons';
import moment from 'moment';
import GestionDeCalidadReducer from "../../../store/reducers/GestionDeCalidadReducer";

const layout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};
const {Option} = Select

class ModalSubirArchivoPorEstandar extends Component {
    formRef = React.createRef();
    state = {
        filePDF: false,
        botonPDF: false,
        botonCrear: false
    }

    render() {
        const {
            onSubirArchivoPorEstandar, onShowModalSubirArchivoPorEstandar, showModalUploadPDFPorEstandar,
            fetchingSubirArchivoPorEstandar, limpiarFormulario, idCumplimientoEstandar, auxDataPDFPorEstandar
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            if (this.state.filePDF) {
                onSubirArchivoPorEstandar(formulario, idCumplimientoEstandar, auxDataPDFPorEstandar);
            } else {
                message.error('Favor de adjuntar un archivo PDF', 8);
            }
        };

        if (limpiarFormulario === 'subirArchivoPorEstandar') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
            this.setState({
                filePDF: false,
                botonPDF: false,
                botonCrear: false
            })
        }
        const handleCancel = () => {
            onShowModalSubirArchivoPorEstandar();
            this.formRef.current.resetFields();
            this.setState({
                filePDF: false,
                botonPDF: false,
                botonCrear: false
            })
        }

        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            } else {
                this.setState({
                    botonPDF: true,
                    botonCrear: true,
                })
            }
            if (info.file.status === 'done') {
                var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,40}$");
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                    this.setState({
                        filePDF: false,
                        botonPDF: false,
                    });
                } else {
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonCrear: false,
                });
            } else if (info.file.status === 'error') {
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                    this.setState({
                        filePDF: false,
                        botonPDF: false,
                    });
                } else {
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonCrear: false,
                });
            }
        }

        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
            this.formRef.current.resetFields(['pdf']);
        }
        const dummyRequest = ({file, onSuccess}) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };
        return (
            <Modal
                visible={showModalUploadPDFPorEstandar}
                title='Subir Archivo Por Estándar'
                onCancel={handleCancel}
                footer={false}
                width='60%'
            >
                <Spin spinning={fetchingSubirArchivoPorEstandar}>
                    <Form {...layout}
                          name="formulario"
                          onFinish={onFinish}
                          ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Adjuntar archivo"
                                    name="pdf"
                                    rules={[{required: true, message: 'Favor de llenar el campo',}]}
                                >
                                    <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF}
                                            accept='.pdf' multiple={false}>
                                        {this.state.filePDF === false ? (
                                            <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                                <UploadOutlined/> Click para adjuntar archivo PDF
                                            </Button>
                                        ) : false}
                                    </Upload>
                                </Form.Item>
                            </Col>

                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button disabled={this.state.botonCrear} className="btnFiltrar" style={{width: '100%'}}
                                        htmlType="submit">Subir archivo por estandar</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        showModalUploadPDFPorEstandar: state.GestionDeCalidadReducer.showModalUploadPDFPorEstandar,
        fetchingSubirArchivoPorEstandar: state.GestionDeCalidadReducer.fetchingSubirArchivoPorEstandar,
        idCumplimientoEstandar: state.GestionDeCalidadReducer.idCumplimientoEstandar,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        auxDataPDFPorEstandar: state.GestionDeCalidadReducer.auxDataPDFPorEstandar
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalSubirArchivoPorEstandar: () => {
            dispatch({type: 'MODAL_UPLOAD_PDF_POR_ESTANDAR', showModalUploadPDFPorEstandar: false, idCumplimientoEstandar: null, auxDataPDFPorEstandar: null})
        },
        onSubirArchivoPorEstandar: (formulario, idCumplimientoEstandar, auxData) => {
            dispatch({type: "SUBIR_ARCHIVO_POR_ESTANDAR_REQUEST", formulario, idCumplimientoEstandar, auxData})
        },
        onLimpiarFormulario: () => {
            dispatch({type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null});
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalSubirArchivoPorEstandar);
