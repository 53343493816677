import React, { Component } from 'react';
import { Row, Form, Input, Button, Modal, Col, Spin, Select, InputNumber } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import InsumosForm from './InsumosForm';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalEditarRutina extends Component {
    formRef = React.createRef();
    state={
        frecuencia: '',
    }

    render() {
        const { fetchingEditarRutina, showModalEditarRutina, onSiguientePaso, onShowModalEditarRutina,
            fetchingListaSubfamiliasRutinas, listaSubfamiliasRutinas, onGetListaServiciosByGrupoServicio, 
            fetchingListaServiciosByGrupoServicio, listaServiciosByGrupoServicio, fetchingListaGruposServicios, 
            listaGruposServicios, vistaNuevaRutina, rutinaSeleccionada, onModalEditarRutina,
            listaInsumos, listaSinRepetir, onGetSubfamiliasRutinas, listaSinRepetirHerramienta,
        } = this.props;
        console.log('listaInsumos');
        console.log(listaInsumos);
        const onFinish = formulario => {
            console.log('Success:', formulario);
            onSiguientePaso(formulario, 2, listaInsumos);
        };
        
        

        const handleCancel = () => {
            onShowModalEditarRutina();
            onSiguientePaso(null, 1);
            this.props.onLimpiarListaServicios();
            this.props.onLimpiarListaSubFamilias();
            this.props.onCleanPartidasByIdServicio();
        }
        const onGrupoServicio = (idGrupoSer) => {
            
            for(let i=0; i<listaGruposServicios.length; i++){
                if(idGrupoSer === listaGruposServicios[i].nombre){
                    idGrupoSer = listaGruposServicios[i].idGrupoSer;
                }
            }
            console.log('idGrupoSer')
            console.log(idGrupoSer)
            onGetListaServiciosByGrupoServicio(idGrupoSer);
            this.formRef.current.setFieldsValue({'servicio': null});
            this.formRef.current.setFieldsValue({'subfamilia': null});
        }
        const onInteger = (value, tipoInput) => {
            value = parseInt(value);
            if(tipoInput==='afectacion'){
                this.formRef.current.setFieldsValue({'afectacion': value})
            }
            else{
                this.formRef.current.setFieldsValue({'dias': value})
            }
        }
        const onFrecuencia = (value) => {
            let tFrecuencia = true;
            if(value === 'Frecuencia'){
                tFrecuencia = false;
            }
            rutinaSeleccionada.fechaEspecifica = tFrecuencia;
            onModalEditarRutina(rutinaSeleccionada, this.props.page, this.props.busqueda, tFrecuencia, listaInsumos, listaSinRepetir, listaSinRepetirHerramienta);
        }
        const onServicioSeleccionado = (idServicio) => {
            if(idServicio){
                for(let i=0; i<listaServiciosByGrupoServicio.length; i++){
                    console.log(listaServiciosByGrupoServicio[i].nomenclatura + ' - ' + listaServiciosByGrupoServicio[i].nombre);
                    console.log(idServicio);
                    if(listaServiciosByGrupoServicio[i].nomenclatura + ': ' + listaServiciosByGrupoServicio[i].nombre === idServicio){
                        idServicio = listaServiciosByGrupoServicio[i].idServicio;
                    }
                }
                this.formRef.current.setFieldsValue({'subfamilia': null});
                console.log('idServicio después del for');
                console.log(idServicio);
                onGetSubfamiliasRutinas(idServicio);
                this.props.onGetPartidasByIdServicio(idServicio);
                this.formRef.current.setFieldsValue({'subfamilia': undefined, 'partida': undefined});
            } else {
                this.props.onCleanPartidasByIdServicio();
                this.formRef.current.setFieldsValue({'subfamilia': undefined, 'partida': undefined});
            }
        }

        console.log('listaPartidasByServicio4Fallas', this.props.listaPartidasByServicio4Fallas)
        return (
            <Modal
            visible={showModalEditarRutina}
            title='Editar Rutina/Mantenimiento/Prueba'
            onCancel={handleCancel}
            footer={false}
            width='70%'
            >
                {rutinaSeleccionada ? (
                <Spin spinning={fetchingEditarRutina}>
                    {vistaNuevaRutina === 1 ? (
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    initialValues={rutinaSeleccionada}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Nombre o descripción de RMP"
                                    name="rutina"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={100} placeholder="Ingresa el nombre o descripción"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Programa"
                                    name="programa"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el programa"
                                    >
                                        <Option key='Rutina'>Rutina</Option>
                                        <Option key='Mantenimiento'>Mantenimiento</Option>
                                        <Option key='Prueba'>Prueba</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Tipo de RMP"
                                    name="tipoRutina"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={50} placeholder="Ingresa el tipo de RMP"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Clave de RMP"
                                    name="claveRutina"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={20} placeholder="Ingresa la clave" title="La marca debe contener sólo letras mayúsculas, números, guión bajo e intermedio" pattern="^[-_A-ZÑ0-9 ÁÉÓÚÍ]+$"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Grupo de servicio"
                                    name="idGrupoSer"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el grupo de servicios"
                                        loading={fetchingListaGruposServicios}
                                        onChange={onGrupoServicio}
                                    >
                                        {listaGruposServicios.map(option => <Option key={option.nombre}>{ option.nombre }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {listaServiciosByGrupoServicio ? (
                            <Col span={11}>
                                <Form.Item
                                    label="Servicio"
                                    name="servicio"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el grupo de servicios"
                                        loading={fetchingListaServiciosByGrupoServicio}
                                        onChange={onServicioSeleccionado}
                                    >
                                        {listaServiciosByGrupoServicio.map(option => <Option key={option.nomenclatura + ': ' + option.nombre}>{option.nomenclatura + ': ' + option.nombre }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            ):false}
                            { this.props.listaPartidasByServicio4Fallas && (<>
                                { this.props.listaPartidasByServicio4Fallas[0] && ( <Col span={11}>
                                    <Form.Item
                                        label="Partida"
                                        name="partida"
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <Select
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona la partidas"
                                            loading={this.props.fetchingListaPartidasByServicio4Fallas}
                                        >
                                            {this.props.listaPartidasByServicio4Fallas.map(option =>
                                                <Option key={option.nomenclatura  + ': ' + option.nombre}>
                                                    {option.nomenclatura  + ': ' + option.nombre}
                                                </Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>) }
                            </>) }
                            
                            {/* { this.props.listaSubfamiliasRutinas[0] && ( */}
                                <Col span={11}>
                                    <Form.Item
                                        label="Subfamilia"
                                        name="subfamilia"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona la subfamilia"
                                            loading={fetchingListaSubfamiliasRutinas}
                                        >
                                            {listaSubfamiliasRutinas.map(option => <Option key={option.subFamilia}>{ option.subFamilia }</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>              
                            <Col span={11}>
                                <Form.Item
                                    label="Actividades"
                                    name="actividades"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <TextArea maxLength={450} placeholder="Ingresa el modelo"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Equipo de protección personal"
                                    name="epp"
                                >
                                    <Input maxLength={450} placeholder="Ingresa el EPP"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Personal"
                                    name="personal"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Input maxLength={100} placeholder="Ingresa el personal"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Tipo de frecuencia"
                                    name="frecuencia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el programa"
                                        onChange={onFrecuencia}
                                    >
                                        <Option key='Frecuencia'>Frecuencia</Option>
                                        <Option key='Fecha Específica'>Fecha específica</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            {!rutinaSeleccionada.fechaEspecifica ? (
                            <Col span={11}>
                                <Form.Item
                                    label="Frecuencia en días"
                                    name="dias"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <InputNumber min={1} max={264} onChange={(value) => onInteger(value, 'Frecuencia')} style={{width: '100%'}} placeholder='Frecuencia en días'/>
                                </Form.Item>
                            </Col>
                            ):false}
                            <Col span={11}>
                                <Form.Item
                                    label="Afectación en minutos"
                                    name="afectacion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <InputNumber min={1} onChange={(value) => onInteger(value, 'afectacion')} style={{width: '100%'}} placeholder='Tiempo de afectación en minutos'/>
                                </Form.Item>
                            </Col>

                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit"><ArrowRightOutlined /> Siguiente Paso</Button>
                            </Col>
                        </Row>
                    </Form>
                    ):(
                    <InsumosForm/>
                    )}
                </Spin>
                ):false}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarRutina: state.ServiciosPlanificadosReducer.fetchingEditarRutina,
        showModalEditarRutina: state.ServiciosPlanificadosReducer.showModalEditarRutina,
        page: state.ServiciosPlanificadosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        busqueda: state.ServiciosPlanificadosReducer.busqueda,
        listaSubfamiliasRutinas: state.CatalogosGeneralesReducer.listaSubfamiliasRutinas,
        fetchingListaSubfamiliasRutinas: state.CatalogosGeneralesReducer.fetchingListaSubfamiliasRutinas,
        fetchingListaGruposServicios: state.CatalogosGeneralesReducer.fetchingListaGruposServicios,
        listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,
        fetchingListaServiciosByGrupoServicio: state.CatalogosGeneralesReducer.fetchingListaServiciosByGrupoServicio,
        listaServiciosByGrupoServicio: state.CatalogosGeneralesReducer.listaServiciosByGrupoServicio,
        vistaNuevaRutina: state.ServiciosPlanificadosReducer.vistaNuevaRutina,
        rutinaSeleccionada: state.ServiciosPlanificadosReducer.rutinaSeleccionada,
        tipoFrecuencia: state.ServiciosPlanificadosReducer.tipoFrecuencia,
        listaInsumos: state.ServiciosPlanificadosReducer.listaInsumos,
        listaSinRepetirHerramienta: state.ServiciosPlanificadosReducer.listaSinRepetirHerramienta,
        listaSinRepetir: state.ServiciosPlanificadosReducer.listaSinRepetir,

        listaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.listaPartidasByServicio4Fallas,
        fetchingListaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.fetchingListaPartidasByServicio4Fallas,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalEditarRutina: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_RUTINA', showModalEditarRutina: false, rutinaSeleccionada: null, page: null, busqueda: null, tipoFrecuencia: false, listaInsumos: [], listaSinRepetir: [], listaSinRepetirHerramienta: [] })
        },
        onSiguientePaso: ( formularioRutina, vistaNuevaRutina, listaInsumos ) => {
            dispatch({ type: "VISTA_NUEVA_RUTINA" , formularioRutina, vistaNuevaRutina, listaInsumos, tipoModal: 'editar' });
        },
        onGetListaServiciosByGrupoServicio: (idGrupoSer) => {
            dispatch({ type: 'LISTA_SERVICIOS_BY_GRUPO_SERV_REQUEST', idGrupoSer})
        },
        onModalEditarRutina: (rutinaSeleccionada, page, busqueda, tipoFrecuencia, listaInsumos, listaSinRepetir, listaSinRepetirHerramienta) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_RUTINA", showModalEditarRutina: true, rutinaSeleccionada, page, busqueda, tipoFrecuencia, listaInsumos, listaSinRepetir, listaSinRepetirHerramienta });
        },
        onLimpiarListaServicios: () => {
            dispatch({ type: 'LISTA_SERVICIOS_BY_GRUPO_SERV_SUCCESS', listaServiciosByGrupoServicio: []})
        },
        onGetSubfamiliasRutinas: (idServicio) => {
            dispatch({ type: 'LISTA_SUBFAMILIAS_RUTINAS_REQUEST', idServicio });
        },
        onLimpiarListaSubFamilias: () => {
            dispatch({ type: 'LISTA_SUBFAMILIAS_RUTINAS_SUCCESS', listaSubfamiliasRutinas: [] });
        },
        onGetPartidasByIdServicio: (idServicio) => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_REQUEST', idServicio})
        },
        onCleanPartidasByIdServicio: () => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_SUCCESS', listaPartidasByServicio4Fallas: []})
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarRutina);