import React, {Component} from 'react'
import {Col, Modal, Table} from 'antd';

import {connect} from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import {DownloadOutlined, EyeOutlined, UploadOutlined} from "@ant-design/icons";
import Search from "antd/es/input/Search";

class ModalEstandaresDesempeno extends Component {
    componentWillUnmount() {
        this.props.onListaFoliosEstandaresLimpiar();
    }

    state = {
        page: 1,
        param: ''
    }

    render() {
        const {
            onShowModalEstandaresDesempeno,
            folioEstandarDesempenoSeleccionado,
            showModalEstandaresDesempeno,
            fetchingListaEstandaresPorFolio,
            listaEstandaresPorFolio,
            listaEstandaresPorFolioCantidad,
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){ permisosUsuario = JSON.parse(permisosUsuario); }

        const columns = [{
            title: 'Estándar de cumplimiento',
            dataIndex: 'idCumplimientoEstandar',
            key: 'idCumplimientoEstandar',
            width: '10%',
        }, {
            title: 'Referencia',
            dataIndex: 'referencia',
            key: 'referencia',
            width: '10%',
        }, {
            title: 'Descripción',
            dataIndex: 'descripcion',
            key: 'descripcion',
            width: '30%',
        }, {
            title: 'Subir archivo',
            key: 'subir',
            width: '8%',
            align: 'center',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.subirArchivoDeEstándaresGenerales} onClick={() => onMostrarModalUpload(record.idCumplimientoEstandar)}><UploadOutlined /></a>
            ),
        }, {
            title: 'Consultar evidencia',
            key: 'consultar',
            width: '8%',
            align: 'center',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.consultarEvidenciaDeEstándaresGenerales ? true : !record.rutaArchivo} onClick={() => onMostrarPDF(record.rutaArchivo)}><EyeOutlined/></a>
            ),
        }, {
            title: 'Descargar',
            key: 'desacrgar',
            width: '8%',
            align: 'center',
            render: (text, record) => (
                <a className='editarIcono' target='_blank' disabled={!permisosUsuario.descargarEvidenciaDeEstándaresGenerales ? true : !record.rutaArchivo} href={record.rutaArchivo}><DownloadOutlined/></a>
            ),
        },
        ];
        const onMostrarModalUpload = (key) => {
            this.props.onShowModalSubirArchivoPorEstandar(key, {page: this.state.page, idFolioEstandar: folioEstandarDesempenoSeleccionado, param: this.state.param});
        }
        const onMostrarPDF = (key) => {
            this.props.onShowModalVerPDFReportesDesempeno(key);
        }
        const handleCancel = () => {
            onShowModalEstandaresDesempeno()
        }
        const onBuscarEstandares = (value) => {
            this.setState({
                param: value
            });
            this.props.onListaEstandaresPorFolio(this.state.page, folioEstandarDesempenoSeleccionado, value);
        }
        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            })
            this.props.onListaEstandaresPorFolio(this.state.page, folioEstandarDesempenoSeleccionado, this.state.param);
        };

        return (
            <Modal
                visible={showModalEstandaresDesempeno}
                title='Estandares Por Folio'
                onCancel={handleCancel}
                footer={false}
                width='80%'
            >
                <Col span={7} >
                    <Search className='buscarModulos' placeholder="Ingresa la descripción a buscar"  onSearch={value => onBuscarEstandares(value)} style={{ width: '100%' }} />
                </Col>
                <br/>
                {folioEstandarDesempenoSeleccionado ? (
                    <Table
                        size="small"
                        rowKey="idEstandarDesempeno"
                        columns={columns}
                        dataSource={listaEstandaresPorFolio}
                        loading={fetchingListaEstandaresPorFolio}
                        locale={{emptyText: 'Sin datos'}}
                        onChange={handleTableChange}
                        pagination={{
                            total: listaEstandaresPorFolioCantidad,
                            simple: true,
                            current: this.state.page
                        }}
                    />
                ) : false}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        showModalEstandaresDesempeno: state.GestionDeCalidadReducer.showModalEstandaresDesempeno,
        folioEstandarDesempenoSeleccionado: state.GestionDeCalidadReducer.folioEstandarDesempenoSeleccionado,

        fetchingListaEstandaresPorFolio: state.GestionDeCalidadReducer.fetchingListaEstandaresPorFolio,
        listaEstandaresPorFolio: state.GestionDeCalidadReducer.listaEstandaresPorFolio,
        listaEstandaresPorFolioCantidad: state.GestionDeCalidadReducer.listaEstandaresPorFolioCantidad,


    };
};

const mapDispatchToProps = dispatch => {
    return {
        onListaEstandaresPorFolio: (page, idFolioEstandar, param) => {
            dispatch({type: 'LISTA_ESTANDARES_POR_FOLIO_REQUEST', page, idFolioEstandar, param})
        },
        onListaFoliosEstandaresLimpiar: () => {
            dispatch({
                type: "LISTA_ESTANDARES_POR_FOLIO_SUCCESS",
                listaEstandaresPorFolio: [],
                listaEstandaresPorFolioCantidad: 0
            });
        },
        onShowModalEstandaresDesempeno: () => {
            dispatch({
                type: 'MODAL_SHOW_ESTANDARES',
                showModalEstandaresDesempeno: false,
                folioEstandarDesempenoSeleccionado: null
            })
        },
        onShowModalVerPDFReportesDesempeno: (rutaArchivo) => {
            dispatch({
                type: 'MODAL_SHOW_PDF_ESTANDARES_DESEMPENO',
                showModalPDFEstandaresDesempeno: true,
                rutaArchivoEstandaresDesempeno: rutaArchivo
            })
        },
        onShowModalSubirArchivoPorEstandar: (idCumplimientoEstandar, auxData) => {
            dispatch({
                type: 'MODAL_UPLOAD_PDF_POR_ESTANDAR',
                showModalUploadPDFPorEstandar: true,
                idCumplimientoEstandar: idCumplimientoEstandar,
                auxDataPDFPorEstandar: auxData
            })
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEstandaresDesempeno);
