import React, { Component } from 'react';
import { Form,Row, Input, Button, Col, Spin, Modal, Select, message, Upload, Typography } from 'antd';
import { FilePdfFilled } from '@ant-design/icons';
import { connect } from 'react-redux';
import {cancelUploadN} from '../../services/BaseDeConocimientoServices';
import '../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option, OptGroup } = Select;
const { Dragger } = Upload;
const { Text, Paragraph } = Typography;
const confirm = Modal.confirm;
const layout = {  labelCol: { span: 24 }, wrapperCol: { span: 24 }, };

class ModalNuevoDocumentoBase extends Component {
    formRef = React.createRef();
    state = {
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
        serviciosTodos:false,
        fileList: []
    }

    componentDidUpdate( prevProps) {
        if (this.props.showModalNuevoDocumentoBase !== prevProps.showModalNuevoDocumentoBase) {
            this.setState({
                filePDF: false,
                botonPDF: false,
                botonEditar: false,
                serviciosTodos: false
            })
            if(this.formRef.current) {
                this.formRef.current.resetFields();
            }
        }
    }

    render() {
        const { fetchingNuevoDocumentoBase, showModalNuevoDocumentoBase, fetchingListaServiciosFiltro, listaServiciosFiltro, listaServiciosActivosF, fetchingListaServiciosActivosF,
            onShowModalNuevoDocumentoBase, onNuevoDocumentoBase, onListaServiciosFiltro, onListaServiciosFiltroLimpiar, page, limpiarFormulario } = this.props
            
        if (limpiarFormulario && limpiarFormulario === 'CDB') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        const buscarServicios = value => {
            if (value.length >= 3) {
                if (value) {
                    console.log(value);
                    onListaServiciosFiltro(value)
                } else {
                    message.warning('No se encontró servicio con esas palabras')
                }
            } else {
                onListaServiciosFiltroLimpiar();
            }
        }

        const onChangeServicios = value => {
            this.setState({serviciosTodos: false});
            const todos = value.some(item => item === 'todos');
            if(todos) {
                this.setState({serviciosTodos: true});
                this.formRef.current.setFieldsValue({'servicios':['todos']})
            }
        }

        const onChangePDF = (info) => {
            let fileList = [...info.fileList];
            // 1. Limit the number of uploaded files. Only to show two recent uploaded files, and old ones will be replaced by the new
            fileList = fileList.slice(-1);

            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                    fileList
                })
            }
            var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
            if (info.file.status === 'done') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['rutaArchivo']);
                    this.setState({fileList: []});
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true,
                        fileList
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['rutaArchivo']);
                    this.setState({fileList: []});
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true,
                        fileList
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }
        const onRemovePDF = () => {
            this.formRef.current.resetFields(['rutaArchivo']);
            this.setState({
                filePDF: false,
                botonPDF: false,
                fileList: [],
            });
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };

        const onFinish = formulario => {
            let {servicios} = formulario;
            const serviciosObject = servicios.map(id => listaServiciosActivosF.find(item => item.idServicio == id.split('-')[0]));
            const serviciosArray = servicios[0] === "todos" ? listaServiciosActivosF : serviciosObject;
            confirm({
                title: `¿Estás seguro que la nomenclatura "${formulario.nomenclatura}" es la correcta?`,
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    console.log('Success:' , formulario);
                    onNuevoDocumentoBase(formulario, serviciosArray, page, this.props.busqueda);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
            this.setState({fileList: []});
        }

        const handleCancel = () => {
            if(fetchingNuevoDocumentoBase) {
                confirm({
                    title: `¿Estás seguro que deseas cancelar la carga del documento?`,
                    content: '',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                        cancelUploadN();
                        onShowModalNuevoDocumentoBase();     
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            } else {     
                onShowModalNuevoDocumentoBase();
                this.formRef.current.resetFields();
                this.setState({
                    filePDF: false,
                    botonPDF: false,
                    botonEditar: false,
                    fileList: []
                })
            }
        }

        const tipoDocumento = ['Políticas Transversales', 'Manuales de Operación', 'Manuales de Procedimientos', 'Información Técnica', 'Planes', 'Contrato'];

        return (
            <Modal
                visible={showModalNuevoDocumentoBase}
                title='Nuevo Documento Base'
                onCancel={handleCancel}
                footer={false}
                width='80%'
            >
                <Spin spinning={fetchingNuevoDocumentoBase}>
                    <Form {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        
                        ref={this.formRef}
                    >
                        <Row type='flex' justify='space-around' gutter={[8,8]}>
                            <Col span={12}>
                                <Form.Item
                                    label="Descripción del documento"
                                    name="descripcion"
                                    hasFeedback
                                    rules={[{ required: true, message: 'Favor de llenar el campo', },
                                        ({getFieldValue}) => ({
                                            validator(_, value){
                                                console.log('value', value)
                                                if(/[*$%"¢@#&{}/]/.test(value)){
                                                    return Promise.reject(new Error('La descripción sólo debe tener letras, números, punto, paréntesis o guiones'));
                                                }else {
                                                    return Promise.resolve();
                                                }
                                            }
                                        })
                                    ]}>
                                    <Input
                                        // pattern="^^[a-zñA-ZÑ0-9 ÁÉÓÚÍáéóíú /-()]+$"
                                        // title="Nombre debe tener letras, números, punto o guiones."
                                        maxLength={50} placeholder='Descripción del documento' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Versión"
                                    name="version"
                                    hasFeedback
                                    rules={[{ required: true, message: 'Favor de llenar el campo', },
                                        ({getFieldValue}) => ({
                                            validator(_, value){
                                                console.log('value', value)
                                                if(!/[0-9]/.test(value)){
                                                    return Promise.reject(new Error('Versión debe tener solo números'));
                                                }else if(/[A-ZÑa-zñ]/.test(value)) {
                                                    return Promise.reject(new Error('Versión debe tener solo números'));
                                                }else {
                                                    return Promise.resolve();
                                                }
                                            }
                                        })
                                    ]}
                                >
                                    <Input
                                        // pattern="^^[. 0-9]+$"
                                        // title="Versión debe tener solo números."
                                        maxLength={10} placeholder='Versión' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Nomenclatura"
                                    name="nomenclatura"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Input
                                        pattern="^^[/- a-zA-Z0-9]+$"
                                        title="Nomenclatura debe tener solo números, letras o guiones."
                                        maxLength={15} placeholder='Nomenclatura' />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Tipo "
                                    name="tipoConocimiento"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Select
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Seleccione el tipo de folio">
                                        {tipoDocumento.map(item => <Option value={item} key={item}>{item}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Servicios"
                                    name="servicios"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Select
                                        mode='multiple'
                                        allowClear
                                        style={{ width: '100%', textAlign: 'left' }}
                                        placeholder="Busque un servicio"
                                        loading={fetchingListaServiciosFiltro}
                                        showSearch
                                        filterOption={false}
                                        onSearch={buscarServicios}
                                        onChange={onChangeServicios}
                                        notFoundContent={null}
                                        >
                                        <OptGroup label="Todos los servicios">
                                            <Option value="todos">Todos</Option>
                                        </OptGroup>
                                        <OptGroup label="Servicios">
                                            {listaServiciosFiltro.map(option => <Option disabled={this.state.serviciosTodos} key={option.idServicio + '-' + option.nombre}>{option.idServicio + '-' + option.nombre}</Option>)}
                                        </OptGroup>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Documento base PDF"
                                    name="rutaArchivo"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                    {/* <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                        {this.state.filePDF === false ? (
                                            <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                                <UploadOutlined /> Click para subir Documento Base PDF
                                            </Button>
                                        ) : false}
                                    </Upload> */}
                                    <Dragger customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false} style={{borderRadius:'1em'}} fileList={this.state.fileList}>
                                        <Row justify='center'>
                                            <FilePdfFilled className='iconTabPane'/>
                                            <Col span={24} style={{paddingTop:'1em'}}>
                                                <Paragraph>
                                                    <Text strong> Click o arrastra a ésta área el archivo PDF </Text>
                                                </Paragraph>
                                            </Col>
                                        </Row>
                                    </Dragger>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Button className="btnFiltrar"  style={{ width: '100%' }} htmlType="submit">Crear Documento Base</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingNuevoDocumentoBase: state.BaseDeConocimientoReducer.fetchingNuevoDocumentoBase,
        showModalNuevoDocumentoBase: state.BaseDeConocimientoReducer.showModalNuevoDocumentoBase,
        page: state.BaseDeConocimientoReducer.page,
        busqueda: state.BaseDeConocimientoReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevoDocumentoBase: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_DOCUMENTO_BASE', showModalNuevoDocumentoBase: false });
        },
        onNuevoDocumentoBase: (formulario, serviciosArray, page, busqueda) => {
            dispatch( { type: 'NUEVO_DOCUMENTO_BASE_REQUEST', formulario: formulario, serviciosArray:serviciosArray, page, busqueda });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        },
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
        },
        onGetServiciosActivos: () => {
            dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoDocumentoBase);