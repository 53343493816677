import React, { Component } from 'react';
import { Form, Button, Row, Col, Spin, Select , Modal} from 'antd';
import { connect } from "react-redux";
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
const layout = { 
    labelCol: { span: 24 }, 
    wrapperCol: { span: 24 }
 };
 const { Option } = Select;
class ModalUsuario extends Component {
    formRef = React.createRef();
    componentDidMount(){
        this.props.onGetUsuarios();
    }
    render() { 
        const {fetchingListaUsuariosActivos, listaUsuariosActivos, onShowModalDescargar, onDescargar,
               showModalDescargarFolio, fetchingDescargarFolioRequisicionPuntoReorden, folioSeleccionado
        } = this.props;

        const onFinish = formulario => {
            console.log('success:', formulario);
            onDescargar(formulario, folioSeleccionado);
            onShowModalDescargar();
        };

        const handleCancel = () => {
            onShowModalDescargar();
        };

        
        return ( 
            <Modal
                visible={showModalDescargarFolio}
                title='Elegir Usuario Responsable'
                onCancel={handleCancel}
                footer={false}
                width={'40%'}
            >
               <Spin spinning={fetchingDescargarFolioRequisicionPuntoReorden}>
                   <Form {...layout}
                   name="formulario"
                   onFinish={onFinish}
                   
                   ref={this.formRef}
                   >
                    <Row justify='space-around'>
                        <Col span={24}>
                                      <Form.Item
                                          label="Usuario Responsable para Descargar Folio"
                                          name="idUsuario"
                                          rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                      >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Seleccione el usuario responsable"
                                            loading={fetchingListaUsuariosActivos}
                                        >
                                          {listaUsuariosActivos.map(option => <Option key={option.idUsuario}>{ option.nombre + " " + option.aPaterno + ": " + option.username}</Option>)}
                                       </Select>
                                     </Form.Item>
                                  </Col>
                        
                           <Col span={24} className='marginBoton'>
                            <br/>
                            <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Descargar Folio Requisicion</Button>
                        </Col>
                    </Row>
                  </Form>
               </Spin>
            </Modal>
         );
    }
}
const mapStateToProps = state => {
    return {
        listaUsuariosActivos: state.AlmacenesReducer.listaUsuariosActivos,
        fetchingListaUsuariosActivos: state.AlmacenesReducer.fetchingListaUsuariosActivos,
        showModalDescargarFolio: state.AlmacenesReducer.showModalDescargarFolio,
        fetchingDescargarFolioRequisicionPuntoReorden: state.AlmacenesReducer.fetchingDescargarFolioRequisicionPuntoReorden,
        folioSeleccionado: state.AlmacenesReducer.folioSeleccionado,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetUsuarios: () => {
            dispatch({ type: "LISTA_USUARIOS_ACTIVOS_REQUEST" });
        },
        onShowModalDescargar: () => {
            dispatch({ type: "SHOW_MODAL_DESCARGAR_FOLIO", showModalDescargarFolio: false})
        },
        onDescargar: (formulario, folioSeleccionado) => {
            dispatch({ type: "DESCARGAR_FOLIO_REQUISICION_PUNTOREORDEN_REQUEST", formulario, folioSeleccionado})
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalUsuario);