import React, { Component } from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoInterior from './ModalNuevoInterior';
import ModalEditarInterior from './ModalEditarInterior';

const { Search } = Input;
const confirm = Modal.confirm;
class CatalogoDeInterior extends Component {
    componentDidMount(){
        this.props.onRequestInterior(1, '');
    }
    state = {
        page: 1,
        busqueda: '',
    }
    render() {
        const {
            onRequestInterior,
            listaInteriorBusqueda,
            fetchingListaInteriorBusqueda,
            listaInteriorBusquedaCantidad,
            onEstadoInterior,
            onModalNuevoInterior,
            onModalEditarInterior,
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        };
        const onBuscarInterior = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            onRequestInterior(1, busqueda);
        };

        const columns = [{
                title: 'Interior funcional',
                dataIndex: 'interiorFuncional',
                key: 'interiorFuncional',
            }, {
                title: 'Cantidad',
                dataIndex: 'cantidad',
                key: 'cantidad',
                align: 'right'
            },{
                title: 'Unidad funcional a la que pertenece',
                dataIndex: 'unidadFuncional',
                key: 'unidadFuncional',
            },{
                title: 'Área funcional a la que pertenece',
                dataIndex: 'areaFuncional',
                key: 'areaFuncional',
            },{
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.interiorFuncionalActivoInactivo} onChange={() => handleDesactivar(record)} />
                ),
            }, {
                title: 'Editar',
                key: 'editar',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarInteriorFuncional} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },
        ];

        const onEditar = (key) => {
            console.log(key);
            key.page = this.state.page;
            onModalEditarInterior(key);
        };

        const handleDesactivar = (key) => {
            const page = this.state.page;
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del interior funcional "'+ key.interiorFuncional +'"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoInterior(key.idInteriorFuncional, page);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        } 

        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onRequestInterior(pagination.current, this.state.busqueda);
        };
    
        const onNuevoInterior = () => {
            onModalNuevoInterior(this.state.page);
        };

        return (
            <div>

                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search className='buscarModulos' placeholder="Buscar interior funcional" onSearch={value => onBuscarInterior(value)} style={{ width: '100%' }} />
                    </Col>
                    {/* <Col span={7}>
                        <Button onClick={onNuevoInterior} disabled={!permisosUsuario.crearInteriorFuncional} className='nuevoUsuario'>Nuevo Interior Funcional</Button> 
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nuevo Interior Funcional' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoInterior} disabled={!permisosUsuario.crearInteriorFuncional} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table
                            size="small"
                            rowKey="interiorFuncional"
                            columns={columns}
                            dataSource={listaInteriorBusqueda}
                            loading={fetchingListaInteriorBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaInteriorBusquedaCantidad, simple: true, current: this.state.page }}
                        />
                    </Col>
                </Row>
                <ModalNuevoInterior />
                <ModalEditarInterior />  
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaInteriorBusqueda: state.CatalogosGeneralesReducer.listaInteriorBusqueda,
        fetchingListaInteriorBusqueda: state.CatalogosGeneralesReducer.fetchingListaInteriorBusqueda,
        listaInteriorBusquedaCantidad: state.CatalogosGeneralesReducer.listaInteriorBusquedaCantidad,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        onRequestInterior: (page, busqueda) => {
            dispatch({ type: "INTERIOR_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
        }, 
         onEstadoInterior: (idInteriorFuncional, page) => {
             dispatch({ type: "ESTADO_INTERIOR_REQUEST", idInteriorFuncional: idInteriorFuncional, page: page })
         }, 
         onModalNuevoInterior: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_INTERIOR", showModalNuevoInterior: true  ,page: page});
        },
        onModalEditarInterior: (interiorSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_INTERIOR", showModalEditarInterior: true, interiorSeleccionado: interiorSeleccionado, page: page })
        },
    }
    
}
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoDeInterior);