import React, { useEffect, useRef } from 'react';
import {connect} from 'react-redux';
import { Alert, Button, Row, Col,Select, Form, Input, DatePicker} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const {Option} = Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const FilterSection = props => {
    let formRef = useRef();
    
    const {
        listaInteriorFuncional,fetchingListaInteriorFuncional, fetchingListaUsuariosParam,listaUsuariosParam, checkboxData, onGetReporteDinamico,formularioRD,
        listaAreasFuncionalesActivas,fetchingListaAreasFuncionalesActivas,listaUnidadesFuncionalesActivas,fetchingListaUnidadesFuncionalesActivas,listaServiciosActivosF,
        handleSteps, onGetAreasFuncionales, onGetUnidadesFuncionalesActivas, onGetInterior,onLimpiarUnidadesFuncional, onLimpiarInteriorFuncional,onBuscarUsuario,onLimpiarListaUsuario,onLimpiarFormulario,
        onGetServiciosActivos, onGetReporteDinamicoAgrupado,
    } = props;


    useEffect(() => {
        onGetAreasFuncionales();
        onGetServiciosActivos();
    }, [])

    let userInfo = sessionStorage.getItem('userInfo');
    if(userInfo[0]) {
        userInfo = JSON.parse(userInfo);
    }

    const serviciosByIdUsuario = userInfo[0].serviciosByIdUsuario ? userInfo[0].serviciosByIdUsuario : [];

    let permisosUsuario = sessionStorage.getItem('permisosUsuario');
    if(permisosUsuario[0]){
        permisosUsuario = JSON.parse(permisosUsuario);
    }

    const onHandleSteps = value => {
        handleSteps(value);
        onLimpiarFormulario();
    }

    const onAreaChange = idAreaFuncional => {
        formRef.current.setFieldsValue({'idUnidadFuncional':undefined,'idInteriorFuncional':undefined })
        onLimpiarInteriorFuncional();
        onLimpiarUnidadesFuncional()
        if(idAreaFuncional) return onGetUnidadesFuncionalesActivas(idAreaFuncional);
    }

    const onUnidadChange = idUnidadFuncional => {
        formRef.current.setFieldsValue({'idInteriorFuncional':undefined })
        const idAreaFuncional = parseInt(formRef.current.getFieldValue('idAreaFuncional'));
        onLimpiarInteriorFuncional();
        if (idUnidadFuncional) return onGetInterior(idAreaFuncional, idUnidadFuncional);
    }

    const buscarUsuario = (value) => {
        if (value.length >= 3) return onBuscarUsuario(value);
        onLimpiarListaUsuario();
    };

    const onFinish = formulario => {
        console.log('formulario', formulario);
        const sorter = {field:'sse.Folio',order:'ASC'}
        onGetReporteDinamico(formulario, 1, listaServiciosActivosF, sorter, null, 10);
        onGetReporteDinamicoAgrupado(formulario, 1, sorter, null);
        handleSteps('siguiente');
    }

    const estados = ['Generada', 'Terminada', 'Abierto', 'Traslado', 'En Espera', 'En Proceso', 'Cerrado', 'Cancelado'];
    const quejas = ['SNP', 'Queja', 'SS', 'Prorroga'];

    return (
        <>
            <Row>
                <Col span={24}>
                    <Alert message="Selecciona los filtros a aplicar en el reporte." type="info" style={{ marginBottom: '16px' }} />
                </Col>
            </Row>
            <Form {...layout}
                name='formulario'
                onFinish={onFinish}
                
                initialValues={formularioRD}
                ref={formRef}
            >
                <Row justify='space-between'>
                    
                    <Col span={11}>
                        <Form.Item
                            name='idServicio'
                            label='Servicio'
                            rules={[{ required: true, message: 'Favor de seleccionar un servicio', }]}
                        >
                            {permisosUsuario.especialSolicitudDeServicio ? (
                            <Select
                                mode='multiple'
                                allowClear
                                style={{ width: '100%' }} placeholder="Selecciona un servicio"
                            >
                                {listaServiciosActivosF.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nombre}</Option>)}
                            </Select>
                            ):(
                            <Select
                                mode='multiple'
                                allowClear
                                style={{ width: '100%' }} placeholder="Selecciona un servicio"
                            >
                                {serviciosByIdUsuario.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nombre}</Option>)}
                            </Select>
                            )}
                        </Form.Item>
                    </Col>
                    
                    {checkboxData.areaFuncional ? (
                        <Col span={11}>
                            <Form.Item
                                name='idAreaFuncional'
                                label='Nombre del área'
                            >
                                <Select allowClear
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder="Seleccione un área funcional"
                                    loading={fetchingListaAreasFuncionalesActivas}
                                    onChange={onAreaChange}
                                >
                                    {listaAreasFuncionalesActivas.map(option => <Option key={option.idAreaFuncional + ' - ' + option.areaFuncional}>{option.idAreaFuncional + ' - ' + option.areaFuncional}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    ):false}
                    {checkboxData.unidadFuncional ? (
                        <Col span={11}>
                            <Form.Item
                                name='idUnidadFuncional'
                                label='Nombre de unidad'
                            >
                                <Select allowClear
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder="Seleccione la unidad funcional"
                                    loading={fetchingListaUnidadesFuncionalesActivas}
                                    onChange={onUnidadChange}
                                >
                                    {listaUnidadesFuncionalesActivas.map(option => <Option key={option.idUnidadFuncional + ' - ' + option.unidadFuncional}>{option.idUnidadFuncional + ' - ' + option.unidadFuncional}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    ):false}
                    {checkboxData.interiorFuncional ? (
                        <Col span={11}>
                            <Form.Item
                                name='idInteriorFuncional'
                                label='Nombre de interior'
                            >
                                <Select allowClear
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder="Seleccione la interior funcional"
                                    loading={fetchingListaInteriorFuncional}
                                >
                                    {listaInteriorFuncional.map(option => <Option key={option.idInteriorFuncional + ' - ' + option.interiorFuncional}>{option.idInteriorFuncional + ' - ' + option.interiorFuncional}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    ):false}
                    {checkboxData.usuarioSolicitoName ? (
                        <Col span={11}>
                            <Form.Item
                                label='Usuario que solicitó el servicio'
                                name='usuarioSolicito'
                            >
                                <Input
                                    pattern="^^[ñíóáéú a-zA-Z ]+$"
                                    title="Usuario debe tener letras."
                                    maxLength={100} placeholder='Usuario solicitante del servicio.' 
                                />
                            </Form.Item>
                        </Col>
                    ) : false}
                    {checkboxData.usuarioRegistro ? (
                        <Col span={11}>
                            <Form.Item
                                label='Usuario CCO'
                                name='usuarioRegistro'
                            >
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Escribe el usuario a buscar"
                                    loading={fetchingListaUsuariosParam}
                                    showSearch
                                    filterOption={false}
                                    onSearch={buscarUsuario}
                                    notFoundContent={null}
                                >
                                    {listaUsuariosParam.map(option => <Option key={option.nombre + ' ' + option.aPaterno}>{option.nombre + ' ' + option.aPaterno}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    ) : false}
                    {checkboxData.fechaAlta ? (
                        <Col span={11}>
                            <Form.Item
                                label="Fecha alta"
                                name="fechaAlta"
                            >
                                <RangePicker
                                    format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio", "Fecha fin"]}
                                    // disabledDate={disabledRange}
                                />
                            </Form.Item>
                        </Col>
                    ) :false}
                    {checkboxData.fechaCierre ? (
                        <Col span={11}>
                            <Form.Item
                                label="Fecha cierre"
                                name="fechaCierre"
                            >
                                <RangePicker
                                    format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio", "Fecha fin"]}
                                    // disabledDate={disabledRange}
                                />
                            </Form.Item>
                        </Col>
                    ): false}
                    {checkboxData.fechaTerminacion ? (
                        <Col span={11}>
                            <Form.Item
                                label="Fecha terminación"
                                name="fechaTerminacion"
                            >
                                <RangePicker
                                    format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio", "Fecha fin"]}
                                    // disabledDate={disabledRange}
                                />
                            </Form.Item>
                        </Col>
                    ) : false}
                    {checkboxData.estado ? (
                        <Col span={11}>
                            <Form.Item
                                name='estado'
                                label='Estado'
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: '100%' }} placeholder="Selecciona un estado"
                                >
                                    {estados.map( estado => <Option key={estado}>{estado}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    ) : false}

                    {checkboxData.queja ? (
                        <Col span={11}>
                            <Form.Item
                                name='queja'
                                label='Clasificación'
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: '100%' }} placeholder="Selecciona un tipo de clasificación del ticket"
                                >
                                    {quejas.map( estado => <Option key={estado}>{estado}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    ) : false}
                </Row>
                <Row>
                    <Col span={6}>
                        <Button className="btnFiltrar" onClick={() => onHandleSteps('anterior')} style={{ width: '100%', marginTop: '20px' }}>Anterior</Button>
                    </Col>
                    <Col span={6} offset={12}>
                        <Button className="btnFiltrar"  htmlType='submit' style={{ width: '100%', marginTop: '20px' }}>Siguiente</Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

const mapStateToProps = state => {
    return {
        listaAreasFuncionalesActivas: state.AlmacenesReducer.listaAreasFuncionalesActivas,
        fetchingListaAreasFuncionalesActivas: state.AlmacenesReducer.fetchingListaAreasFuncionalesActivas,
        listaUnidadesFuncionalesActivas: state. AlmacenesReducer.listaUnidadesFuncionalesActivas,
        fetchingListaUnidadesFuncionalesActivas: state.AlmacenesReducer.fetchingListaUnidadesFuncionalesActivas,
        listaInteriorFuncional: state.AlmacenesReducer.listaInteriorFuncional,
        fetchingListaInteriorFuncional: state.AlmacenesReducer.fetchingListaInteriorFuncional,
        fetchingListaUsuariosParam: state.ConfiguracionYSeguridadReducer.fetchingListaUsuariosParam,
        listaUsuariosParam: state.ConfiguracionYSeguridadReducer.listaUsuariosParam,
        formularioRD: state.SolicitudDeServiciosReducer.formularioRD,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetAreasFuncionales: () => {
            dispatch({ type: "LISTA_AREA_FUNCIONAL_ACTIVA_REQUEST", });
        },
        onGetUnidadesFuncionalesActivas: (idAreaFuncional) => {
            dispatch({ type: 'LISTA_UNIDAD_FUNCIONAL_REQUEST', idAreaFuncional});
        },
        onLimpiarUnidadesFuncional: () => {
            dispatch({ type: 'LISTA_UNIDAD_FUNCIONAL_SUCCESS', listaUnidadesFuncionalesActivas: [] })
        },
        onGetInterior: (idAreaFuncional, idUnidadFuncional) => {
            dispatch({ type: 'LISTA_INTERIOR_FUNCIONAL_REQUEST', idAreaFuncional, idUnidadFuncional });
        },
        onLimpiarInteriorFuncional: () => {
            dispatch({ type: 'LISTA_INTERIOR_FUNCIONAL_SUCCESS', listaInteriorFuncional: [] })
        },
        onBuscarUsuario: (busqueda) => {
            dispatch({ type: 'LISTA_USUARIOS_PARAM_REQUEST', busqueda })
        },
        onLimpiarListaUsuario: () => {
            dispatch({ type: 'LISTA_USUARIOS_PARAM_SUCCESS', listaUsuariosParam: [] })
        },
        onGetReporteDinamico: (formulario, page, listaServiciosActivosF, order, agrupamiento, offset) => {
            dispatch({ type: 'LISTA_BUSQUEDA_REPORTE_DINAMICO_REQUEST', formulario, page, listaServiciosActivosF, order, agrupamiento, offset});
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO_RD', formularioRD: {},listaReporteDinamicoBusqueda: [], listaReporteDinamicoBusquedaCantidad: null})
        },
        onGetServiciosActivos: () => {
            dispatch({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST'})
        },
        onGetReporteDinamicoAgrupado: (formulario, page, order, agrupamiento) => {
            dispatch({ type: 'LISTA_BUSQUEDA_REPORTE_DINAMICO_GRUPO_REQUEST', formulario, page, order, agrupamiento});
        }
    }
}

export default connect (mapStateToProps, mapDispatchToProps) (FilterSection);