import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import { Row, Col, Modal, Table, Input, Button, Switch, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoNivelTiempoR from './ModalNuevoNivelTiempoRespuesta';
import ModalEditarNivelTiempoR from './ModalEditarNivelTiempoRespuesta';

const confirm = Modal.confirm;
const { Search } = Input;

const CtlgNivelesTiempoRespuesta = (props) => {

    const {listaNivelesTiempoRespuestaBusqueda, fetchingListaNivelesTiempoRespuestaBusqueda, listaNivelesTiempoRespuestaBusquedaCantidad,
        onGetNivelesTiempoRespuesta, onModalNuevoNivelTiempoR, onGetUnidadesMedidaTiempo,onGetSeccionesEE,
        onRequestInfoProyecto, onChangeEstadoNivelTR, onModalEditarNivelTiempoR} = props;
    const [state, setState] = useState({busqueda:"", page:1});
    
    useEffect(() => {
        onGetNivelesTiempoRespuesta(1, "");
        onGetUnidadesMedidaTiempo(1);
        onGetSeccionesEE();
        onRequestInfoProyecto();

    },[]);

    let permisosUsuario = sessionStorage.getItem('permisosUsuario');
    if(permisosUsuario[0]) {
        permisosUsuario = JSON.parse(permisosUsuario);
    }

    const columns = [{
        title: 'Sección',
        dataIndex: 'seccion',
        key: 'seccion',
    }, {
        title: 'Categoría',
        dataIndex: 'categoria',
        key: 'categoria',
    }, {
        title: 'Tiempo respuesta',
        dataIndex: 'tiempoRespuesta',
        key: 'tiempoRespuesta',
        align: 'center',
    }, {
        title: 'Unidad de medida',
        dataIndex: 'unidadMedida',
        key: 'unidadMedida',
    },{
        title: 'Fecha Alta',
        dataIndex: 'fechaAlta',
        key: 'fechaAlta',
        width: '14%',
        },{
        title: 'Activo',
        dataIndex: 'activo',
        key: 'activo',
        render: (text, record) => (
            <Switch checked={text} disabled={!permisosUsuario.tiempoDeRespuestaActivoinactivo} onChange = {() => handleDesactivar(record)} />
        ),
    },{
        title: 'Editar',
        key: 'editar',
        render: (text, record) => (
            <a className='editarIcono' disabled={!permisosUsuario.editarTiempoDeRespuesta}  onClick={() => onEditar(record)} ><EditOutlined /></a>
        ),
    },
    ];

    const onBuscarSeccion = (busqueda) => {
        setState({busqueda: busqueda, page: 1,});
        onGetNivelesTiempoRespuesta(1, busqueda);
    } 

    const onNuevoNivelTR = () => {
        onModalNuevoNivelTiempoR(state.page, state.busqueda);
    }

    const handleDesactivar = (key) => {
        console.log(key);
        confirm({
            title: '¿Estás seguro que deseas cambiar el estado del nivel de tiempo de respuesta?',
            content: '',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                onChangeEstadoNivelTR(key.idTiempoRespuestaSeccion, state.page, state.busqueda);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const onEditar = (key) => {
        onModalEditarNivelTiempoR(key, state.page, state.busqueda)
    }

    const handleTableChange = (pagination) => {
        setState({...state, page: pagination.current});
        onGetNivelesTiempoRespuesta(pagination.current, state.busqueda);
    }

    return ( <>
        <Row justify='space-between' gutter={[8,8]}>
            <Col span={22}>
                <Search className='buscarModulos' placeholder='Ingresar la sección' onSearch={value => onBuscarSeccion(value)} />
            </Col>
            {/* <Col span={8}>
                <Button className='nuevoUsuario' onClick={onNuevoNivelTR} disabled={!permisosUsuario.crearNivelTiempoDeRespuesta}>Nuevo Nivel Tiempo Respuesta</Button>
            </Col> */}
            <Col span={1}>
                <Tooltip title='Nuevo Nivel Tiempo Respuesta' placement="topRight">
                    <Button className="iconTer" shape="circle" onClick={onNuevoNivelTR} disabled={!permisosUsuario.crearNivelTiempoDeRespuesta} icon={<PlusOutlined />}/>
                </Tooltip>
            </Col>
        </Row>
        <Row gutter={[8,8]}>
            <Col span={24}>
                <Table
                    size='small'
                    rowKey='categoria'
                    columns={columns}
                    dataSource={listaNivelesTiempoRespuestaBusqueda}
                    loading={fetchingListaNivelesTiempoRespuestaBusqueda}
                    locale={{ emptyText: 'Sin datos' }}
                    onChange={handleTableChange}
                    pagination={{ total: listaNivelesTiempoRespuestaBusquedaCantidad, simple: true, current: state.page }}
                />
            </Col>
        </Row>
        <ModalNuevoNivelTiempoR />
        <ModalEditarNivelTiempoR />
    </> );
}

const mapStateToProps = state => {
    return {
        fetchingListaNivelesTiempoRespuestaBusqueda: state.MecanismosDePagosReducer.fetchingListaNivelesTiempoRespuestaBusqueda,
        listaNivelesTiempoRespuestaBusqueda: state.MecanismosDePagosReducer.listaNivelesTiempoRespuestaBusqueda,
        listaNivelesTiempoRespuestaBusquedaCantidad: state.MecanismosDePagosReducer.listaNivelesTiempoRespuestaBusquedaCantidad,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetNivelesTiempoRespuesta: (page, busqueda) => {
            dispatch({ type: "NIVELES_TIEMPO_RESPUESTA_BUSQUEDA_REQUEST", page, busqueda });
        },
        onModalNuevoNivelTiempoR : (page, busqueda) => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_NIVEL_TIEMPO_RESPUESTA', showModalNuevoNivelTiempoRespuesta: true, page, busqueda})
        },
        onModalEditarNivelTiempoR : (nivelTiempoRespuestaSeleccionado, page, busqueda) => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_NIVEL_TIEMPO_RESPUESTA', showModalEditarNivelTiempoRespuesta: true, nivelTiempoRespuestaSeleccionado, page, busqueda})
        },
        onGetUnidadesMedidaTiempo : (idCategoriaUnidadMedida) => {
            dispatch({ type: 'UNIDADES_MEDI_POR_CATEGORIA_REQUEST', idCategoriaUnidadMedida})
        },
        onGetSeccionesEE: () => {
            dispatch({ type: 'LISTA_ESTANDARES_DESEMPENO_ESPECIFICOS_ACTIVOS_REQUEST'})
        },
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        },
        onChangeEstadoNivelTR: (idTiempoRespuestaSeccion, page, busqueda) => {
            dispatch({ type: 'ESTADO_NIVEL_TIEMPO_RESPUESTA_REQUEST', idTiempoRespuestaSeccion, page, busqueda})
        }
    }
}

export default connect (mapStateToProps, mapDispatchToProps)(CtlgNivelesTiempoRespuesta);