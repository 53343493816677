import React, {Component} from 'react'
import { Form, Upload, Button, Col, Spin, Modal, Row, Radio, message, Input, Select, Tooltip } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import { UploadOutlined } from '@ant-design/icons';

const { Option } = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { TextArea } = Input;

class ModalNuevaPreguntaEncuesta extends Component {
    formRef = React.createRef();
    state={
        listaPreguntas: [],
        mostrarEvidencia: false,
        filePDF: false,
        botonPDF: false,
        uploadOk: false,
    }
    render() { 
        const {
            onRegistrarRespuestasEncuesta, onShowModalRegistrarRespuestaEncuesta, showModalRegistrarEncuesta,
            fetchingRegistrarEncuesta, page, limpiarFormulario, busqueda, folioSeleccionado, fetchingListaUbicacionesTodasBusqueda,
            listaUbicacionesTodasBusqueda,
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            let listaPreguntas = [];
            for (var key in formulario) {
                if (Object.prototype.hasOwnProperty.call(formulario, key)) {
                    let idPregunta = key.split('-');
                    if (idPregunta.length === 2) {
                        let idPreguntaEncuesta = idPregunta[1];
                        let campo = idPregunta[0];
                        let valor = formulario[key];
    
                        let capturaFormulario = {[campo]: valor, 'idPreguntaEncuesta': parseInt(idPreguntaEncuesta)};
                        listaPreguntas.push(capturaFormulario);
                    }
                }
            }
            if(this.state.uploadOk){
                onRegistrarRespuestasEncuesta(listaPreguntas, page, busqueda, folioSeleccionado, formulario);
            }
            else{
                message.error('Favor de agregar un PDF de evidencia');
            }
        };

        const onSiguientePaso = () => {
            this.setState({
                mostrarEvidencia: true
            })
        }

        if (limpiarFormulario && limpiarFormulario === 'registrarEncuesta') {
            this.props.onBuscarUbicacionLimpiar();
            this.props.onLimpiarFormulario();
            this.setState({
                mostrarEvidencia: false,
                filePDF: false,
                botonPDF: false,
                uploadOk: false,
            })
        }
        const handleCancel = () => {
            onShowModalRegistrarRespuestaEncuesta();
            this.props.onBuscarUbicacionLimpiar();
            this.formRef.current.resetFields();
            this.setState({
                mostrarEvidencia: false,
                filePDF: false,
                botonPDF: false,
                uploadOk: false,
            })
        }

        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else {
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                })
            }
            if (info.file.status === 'done') {
                var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,40}$");
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['evidenciaPDF']);
                }
                else {
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true,
                        uploadOk: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['evidenciaPDF']);
                }
                else {
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true,
                        uploadOk: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }

        const onRemovePDF = () => {
            this.formRef.current.resetFields(['evidenciaPDF']);
            this.setState({
                filePDF: false,
                botonPDF: false,
                uploadOk: false,
            });
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };

        const buscarUbicacion = (value) => {
            if(value.length>=3){
                console.log(value)
                this.props.onBuscarUbicacion(value);
            }
            else{
                console.log('Entró a limpiar')
                this.props.onBuscarUbicacionLimpiar();
            }
        };

        return (
            <Modal
                visible={showModalRegistrarEncuesta}
                title='Ingresar Respuestas del Folio'
                onCancel={handleCancel}
                footer={false}
                width='70%'
            >
                <Spin spinning={fetchingRegistrarEncuesta}>
                    {folioSeleccionado ? (
                    <Form {...layout}
                        name="formulario"
                        onFinish={onFinish}
                        ref={this.formRef}
                    >
                        <h3><strong>Nota:</strong> Se debe calificar cada pregunta, en caso contrario se asignará la calificación máxima.</h3>
                        {folioSeleccionado.preguntasEncuestasByIdFolio.map(option => 
                        <Row justify='space-around' key={option.idPreguntaEncuesta}>
                            <Col span={11}>
                                <p className='alinearPregunta'>{option.pregunta}</p>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    name={"pregunta-" + option.idPreguntaEncuesta}
                                >
                                    <Radio.Group>
                                        {option.ponderacionesEncuestas.map(ponderador => 
                                        <Radio key={ponderador.idPonderacionEncuesta} value={ponderador.idPonderacionEncuesta}>{ponderador.etiqueta}</Radio>
                                        )}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        )}
                        
                        {!this.state.mostrarEvidencia ? (
                        <Row justify='space-around'>
                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" style={{ width: '100%' }} onClick={onSiguientePaso}>Adjuntar Evidencia</Button>
                            </Col>
                        </Row>
                        ):(
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Adjuntar evidencia"
                                    name="evidenciaPDF"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                        {this.state.filePDF === false ? (
                                        <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                            <UploadOutlined /> Click para subir evidencia PDF
                                        </Button>
                                        ):false}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Comentarios"
                                    name="comentarios"
                                >
                                    <TextArea maxLength={400}/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Ubicación"
                                    name="ubicacion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Buscar la ubicación"
                                        loading={fetchingListaUbicacionesTodasBusqueda}
                                        showSearch={true}
                                        filterOption={false}
                                        onSearch={buscarUbicacion}
                                        notFoundContent={null}
                                    >
                                        {listaUbicacionesTodasBusqueda.map(option => 
                                        <Option key={option.idUbicacion}>
                                            <Tooltip title={option.todoUbicacion}>
                                                {option.todoUbicacion}
                                            </Tooltip>
                                        </Option>)}
                                    </Select>
                                </Form.Item> 
                            </Col>
                            <Col span={11} className='marginBoton'>
                                <br />
                                <Button disabled={this.state.botonEditar} className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Registrar Respuestas del Folio</Button>
                            </Col>
                        </Row>
                        )}
                    </Form>
                    ):false}
                </Spin>
            </Modal>
        );
    }
}
const mapStateToProps = state => {
    return {
        showModalRegistrarEncuesta: state.GestionDeCalidadReducer.showModalRegistrarEncuesta,
        fetchingRegistrarEncuesta: state.GestionDeCalidadReducer.fetchingRegistrarEncuesta,
        page: state.GestionDeCalidadReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        busqueda: state.GestionDeCalidadReducer.busqueda, 
        folioSeleccionado: state.GestionDeCalidadReducer.folioSeleccionado,
        listaUbicacionesTodasBusqueda: state.CatalogosGeneralesReducer.listaUbicacionesTodasBusqueda,
        fetchingListaUbicacionesTodasBusqueda: state.CatalogosGeneralesReducer.fetchingListaUbicacionesTodasBusqueda,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalRegistrarRespuestaEncuesta: () => {
            dispatch ({ type: 'MODAL_REGISTRAR_ENCUESTA', showModalRegistrarEncuesta: false })
        },
        onRegistrarRespuestasEncuesta: (listaPreguntas, page, busqueda, folioSeleccionado, formulario) => {
            dispatch({ type: "REGISTRAR_ENCUESTA_REQUEST", listaPreguntas, page, busqueda, folioSeleccionado, formulario })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onBuscarUbicacion: (param) => {
            dispatch({ type: "LISTA_UBICACIONES_TODAS_BUSQUEDA_REQUEST", param })
        },
        onBuscarUbicacionLimpiar: () => {
            dispatch({ type: "LISTA_UBICACIONES_TODAS_BUSQUEDA_SUCCESS", listaUbicacionesTodasBusqueda: [] })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevaPreguntaEncuesta);