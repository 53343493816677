export const CAMBIAR_CATALOGO_SOLICITUD_SERVICIOS = 'CAMBIAR_CATALOGO_SOLICITUD_SERVICIOS';
export const CAMBIAR_SOLICITUD_SERVICIOS = 'CAMBIAR_SOLICITUD_SERVICIOS';

export const UBICACIONES_SIN_HIJOS_REQUEST = 'UBICACIONES_SIN_HIJOS_REQUEST';
export const UBICACIONES_SIN_HIJOS_SUCCESS = 'UBICACIONES_SIN_HIJOS_SUCCESS';
export const UBICACIONES_SIN_HIJOS_FAILURE = 'UBICACIONES_SIN_HIJOS_FAILURE';

// Seguimiento Folios Solicitudes de Servicio (SS)
export const LISTA_FOLIOS_SS_BUSQUEDA_REQUEST = "LISTA_FOLIOS_SS_BUSQUEDA_REQUEST";
export const LISTA_FOLIOS_SS_BUSQUEDA_SUCCESS = "LISTA_FOLIOS_SS_BUSQUEDA_SUCCESS";
export const LISTA_FOLIOS_SS_BUSQUEDA_FAILURE = "LISTA_FOLIOS_SS_BUSQUEDA_FAILURE";

export const SHOW_MODAL_VER_DETALLE_FOLIO_SS = "SHOW_MODAL_VER_DETALLE_FOLIO_SS";
export const SHOW_MODAL_OPCIONES_SEGUIMIENTO_FOLIO_SS = "SHOW_MODAL_OPCIONES_SEGUIMIENTO_FOLIO_SS";

export const REASIGNAR_FOLIO_SS_REQUEST = "REASIGNAR_FOLIO_SS_REQUEST";
export const REASIGNAR_FOLIO_SS_SUCCESS = "REASIGNAR_FOLIO_SS_SUCCESS";
export const REASIGNAR_FOLIO_SS_FAILURE = "REASIGNAR_FOLIO_SS_FAILURE";

export const EN_PROCESO_FOLIO_SS_REQUEST = "EN_PROCESO_FOLIO_SS_REQUEST";
export const EN_PROCESO_FOLIO_SS_SUCCESS = "EN_PROCESO_FOLIO_SS_SUCCESS";
export const EN_PROCESO_FOLIO_SS_FAILURE = "EN_PROCESO_FOLIO_SS_FAILURE";

export const TERMINADO_FOLIO_SS_REQUEST = "TERMINADO_FOLIO_SS_REQUEST";
export const TERMINADO_FOLIO_SS_SUCCESS = "TERMINADO_FOLIO_SS_SUCCESS";
export const TERMINADO_FOLIO_SS_FAILURE = "TERMINADO_FOLIO_SS_FAILURE";

export const CERRAR_FOLIO_SS_REQUEST = "CERRAR_FOLIO_SS_REQUEST";
export const CERRAR_FOLIO_SS_SUCCESS = "CERRAR_FOLIO_SS_SUCCESS";
export const CERRAR_FOLIO_SS_FAILURE = "CERRAR_FOLIO_SS_FAILURE";

export const EN_ESPERA_FOLIO_SS_REQUEST = "EN_ESPERA_FOLIO_SS_REQUEST";
export const EN_ESPERA_FOLIO_SS_SUCCESS = "EN_ESPERA_FOLIO_SS_SUCCESS";
export const EN_ESPERA_FOLIO_SS_FAILURE = "EN_ESPERA_FOLIO_SS_FAILURE";

export const CANCELAR_FOLIO_SS_REQUEST = "CANCELAR_FOLIO_SS_REQUEST";
export const CANCELAR_FOLIO_SS_SUCCESS = "CANCELAR_FOLIO_SS_SUCCESS";
export const CANCELAR_FOLIO_SS_FAILURE = "CANCELAR_FOLIO_SS_FAILURE";

export const TRASLADO_FOLIO_SS_REQUEST = "TRASLADO_FOLIO_SS_REQUEST";
export const TRASLADO_FOLIO_SS_SUCCESS = "TRASLADO_FOLIO_SS_SUCCESS";
export const TRASLADO_FOLIO_SS_FAILURE = "TRASLADO_FOLIO_SS_FAILURE";

export const SHOW_MODAL_TRASLADO = "SHOW_MODAL_TRASLADO";

export const ABIERTO_FOLIO_SS_REQUEST = "ABIERTO_FOLIO_SS_REQUEST";
export const ABIERTO_FOLIO_SS_SUCCESS = "ABIERTO_FOLIO_SS_SUCCESS";
export const ABIERTO_FOLIO_SS_FAILURE = "ABIERTO_FOLIO_SS_FAILURE";

export const LISTA_FALLAS_ACTIVAS_POR_SERVICIO_REQUEST = "LISTA_FALLAS_ACTIVAS_POR_SERVICIO_REQUEST";
export const LISTA_FALLAS_ACTIVAS_POR_SERVICIO_SUCCESS = "LISTA_FALLAS_ACTIVAS_POR_SERVICIO_SUCCESS";
export const LISTA_FALLAS_ACTIVAS_POR_SERVICIO_FAILURE = "LISTA_FALLAS_ACTIVAS_POR_SERVICIO_FAILURE";

export const LISTA_SERVICIOS_ACTIVOS_CON_FALLAS_REQUEST = "LISTA_SERVICIOS_ACTIVOS_CON_FALLAS_REQUEST";
export const LISTA_SERVICIOS_ACTIVOS_CON_FALLAS_SUCCESS = "LISTA_SERVICIOS_ACTIVOS_CON_FALLAS_SUCCESS";
export const LISTA_SERVICIOS_ACTIVOS_CON_FALLAS_FAILURE = "LISTA_SERVICIOS_ACTIVOS_CON_FALLAS_FAILURE";

export const OBTENER_UBICACION_REQUEST = "OBTENER_UBICACION_REQUEST";
export const OBTENER_UBICACION_SUCCESS = "OBTENER_UBICACION_SUCCESS";
export const OBTENER_UBICACION_FAILURE = "OBTENER_UBICACION_FAILURE";

export const OBTENER_TRAZABILIDAD_FOLIO_REQUEST = "OBTENER_TRAZABILIDAD_FOLIO_REQUEST";
export const OBTENER_TRAZABILIDAD_FOLIO_SUCCESS = "OBTENER_TRAZABILIDAD_FOLIO_SUCCESS";
export const OBTENER_TRAZABILIDAD_FOLIO_FAILURE = "OBTENER_TRAZABILIDAD_FOLIO_FAILURE";

export const LISTA_PROVEEDORES_BY_SERVICIO_ACTIVOS_REQUEST = "LISTA_PROVEEDORES_BY_SERVICIO_ACTIVOS_REQUEST";
export const LISTA_PROVEEDORES_BY_SERVICIO_ACTIVOS_SUCCESS = "LISTA_PROVEEDORES_BY_SERVICIO_ACTIVOS_SUCCESS";
export const LISTA_PROVEEDORES_BY_SERVICIO_ACTIVOS_FAILURE = "LISTA_PROVEEDORES_BY_SERVICIO_ACTIVOS_FAILURE";

export const CAMBIAR_VISTA_MODAL_ESTADO_TERMINADO = "CAMBIAR_VISTA_MODAL_ESTADO_TERMINADO";
export const SHOW_MODAL_ESTADO_TERMINADO = "SHOW_MODAL_ESTADO_TERMINADO";
export const CURRENT_STATUS_SEGUIMIENTO = "CURRENT_STATUS_SEGUIMIENTO";

export const SHOW_MODAL_CONTRASEÑA = "SHOW_MODAL_CONTRASEÑA";
export const SHOW_MODAL_PRORROGA = "SHOW_MODAL_PRORROGA";

export const CREAR_PRORROGA_REQUEST = "CREAR_PRORROGA_REQUEST";
export const CREAR_PRORROGA_SUCCESS = "CREAR_PRORROGA_SUCCESS";
export const CREAR_PRORROGA_FAILURE = "CREAR_PRORROGA_FAILURE";

export const LISTA_PRORROGA_REQUEST = "LISTA_PRORROGA_REQUEST";
export const LISTA_PRORROGA_SUCCESS = "LISTA_PRORROGA_SUCCESS";
export const LISTA_PRORROGA_FAILURE = "LISTA_PRORROGA_FAILURE";

export const LISTA_TRASLADOS_REQUEST = "LISTA_TRASLADOS_REQUEST";
export const LISTA_TRASLADOS_SUCCESS = "LISTA_TRASLADOS_SUCCESS";
export const LISTA_TRASLADOS_FAILURE = "LISTA_TRASLADOS_FAILURE";




//CATÁLOGO DE TIPOS DE FALLAS
export const LISTA_TIPOS_FALLAS_ACTIVAS_REQUEST = 'LISTA_TIPOS_FALLAS_ACTIVAS_REQUEST';
export const LISTA_TIPOS_FALLAS_ACTIVAS_SUCCESS = 'LISTA_TIPOS_FALLAS_ACTIVAS_SUCCESS';
export const LISTA_TIPOS_FALLAS_ACTIVAS_FAILURE = 'LISTA_TIPOS_FALLAS_ACTIVAS_FAILURE';

export const EXPORTAR_FALLAS_EXCEL_REQUEST = 'EXPORTAR_FALLAS_EXCEL_REQUEST';
export const EXPORTAR_FALLAS_EXCEL_SUCCESS = 'EXPORTAR_FALLAS_EXCEL_SUCCESS';
export const EXPORTAR_FALLAS_EXCEL_FAILURE = 'EXPORTAR_FALLAS_EXCEL_FAILURE';

export const LISTA_TIPOS_FALLAS_BUSQUEDA_REQUEST = 'LISTA_TIPOS_FALLAS_BUSQUEDA_REQUEST';
export const LISTA_TIPOS_FALLAS_BUSQUEDA_SUCCESS = 'LISTA_TIPOS_FALLAS_BUSQUEDA_SUCCESS';
export const LISTA_TIPOS_FALLAS_BUSQUEDA_FAILURE = 'LISTA_TIPOS_FALLAS_BUSQUEDA_FAILURE';

export const ESTADO_TIPO_FALLA_REQUEST = 'ESTADO_TIPO_FALLA_REQUEST';
export const ESTADO_TIPO_FALLA_SUCCESS = 'ESTADO_TIPO_FALLA_SUCCESS';
export const ESTADO_TIPO_FALLA_FAILURE = 'ESTADO_TIPO_FALLA_FAILURE';

export const SHOW_MODAL_NUEVO_TIPO_FALLA = 'SHOW_MODAL_NUEVO_TIPO_FALLA';

export const NUEVO_TIPO_FALLA_REQUEST = 'NUEVO_TIPO_FALLA_REQUEST';
export const NUEVO_TIPO_FALLA_SUCCESS = 'NUEVO_TIPO_FALLA_SUCCESS';
export const NUEVO_TIPO_FALLA_FAILURE = 'NUEVO_TIPO_FALLA_FAILURE';

export const SHOW_MODAL_EDITAR_TIPO_FALLA = 'SHOW_MODAL_EDITAR_TIPO_FALLA';

export const EDITAR_TIPO_FALLA_REQUEST = 'EDITAR_TIPO_FALLA_REQUEST';
export const EDITAR_TIPO_FALLA_SUCCESS = 'EDITAR_TIPO_FALLA_SUCCESS';
export const EDITAR_TIPO_FALLA_FAILURE = 'EDITAR_TIPO_FALLA_FAILURE';

//CATÁLOGO DE FALLAS
export const LISTA_FALLAS_ACTIVAS_REQUEST = 'LISTA_FALLAS_ACTIVAS_REQUEST';
export const LISTA_FALLAS_ACTIVAS_SUCCESS = 'LISTA_FALLAS_ACTIVAS_SUCCESS';
export const LISTA_FALLAS_ACTIVAS_FAILURE = 'LISTA_FALLAS_ACTIVAS_FAILURE';

export const LISTA_FALLAS_BUSQUEDA_REQUEST = 'LISTA_FALLAS_BUSQUEDA_REQUEST';
export const LISTA_FALLAS_BUSQUEDA_SUCCESS = 'LISTA_FALLAS_BUSQUEDA_SUCCESS';
export const LISTA_FALLAS_BUSQUEDA_FAILURE = 'LISTA_FALLAS_BUSQUEDA_FAILURE';

export const ESTADO_FALLA_REQUEST = 'ESTADO_FALLA_REQUEST';
export const ESTADO_FALLA_SUCCESS = 'ESTADO_FALLA_SUCCESS';
export const ESTADO_FALLA_FAILURE = 'ESTADO_FALLA_FAILURE';

export const SHOW_MODAL_NUEVA_FALLA = 'SHOW_MODAL_NUEVA_FALLA';

export const NUEVA_FALLA_REQUEST = 'NUEVA_FALLA_REQUEST';
export const NUEVA_FALLA_SUCCESS = 'NUEVA_FALLA_SUCCESS';
export const NUEVA_FALLA_FAILURE = 'NUEVA_FALLA_FAILURE';

export const SHOW_MODAL_EDITAR_FALLA = 'SHOW_MODAL_EDITAR_FALLA';

export const EDITAR_FALLA_REQUEST = 'EDITAR_FALLA_REQUEST';
export const EDITAR_FALLA_SUCCESS = 'EDITAR_FALLA_SUCCESS';
export const EDITAR_FALLA_FAILURE = 'EDITAR_FALLA_FAILURE';

export const LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_REQUEST = 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_REQUEST';
export const LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_SUCCESS = 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_SUCCESS';
export const LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_FAILURE = 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_FAILURE';

// CU - Recepción y Captura de Solicitud de Servicio
export const SHOW_MODAL_IDENTIFICACION = 'SHOW_MODAL_IDENTIFICACION';

export const CREAR_SOLICITUD_SERVICIO_REQUEST = 'CREAR_SOLICITUD_SERVICIO_REQUEST';
export const CREAR_SOLICITUD_SERVICIO_SUCCESS = 'CREAR_SOLICITUD_SERVICIO_SUCCESS';
export const CREAR_SOLICITUD_SERVICIO_FAILURE = 'CREAR_SOLICITUD_SERVICIO_FAILURE';

export const EXPORTAR_SOLICITUD_SERVICIO_REQUEST = 'EXPORTAR_SOLICITUD_SERVICIO_REQUEST';
export const EXPORTAR_SOLICITUD_SERVICIO_SUCCESS = 'EXPORTAR_SOLICITUD_SERVICIO_SUCCESS';
export const EXPORTAR_SOLICITUD_SERVICIO_FAILURE = 'EXPORTAR_SOLICITUD_SERVICIO_FAILURE';

export const SHOW_MODAL_FOLIO_SOLICITUD = 'SHOW_MODAL_FOLIO_SOLICITUD';

export const LISTA_FOLIOS_SS_REQUEST = 'LISTA_FOLIOS_SS_REQUEST';
export const LISTA_FOLIOS_SS_SUCCESS = 'LISTA_FOLIOS_SS_SUCCESS';
export const LISTA_FOLIOS_SS_FAILURE = 'LISTA_FOLIOS_SS_FAILURE';

export const SHOW_MODAL_DETALLE_SS = 'SHOW_MODAL_DETALLE_SS'; 
export const CRONOMETRO = 'CRONOMETRO';
export const RESTART_CRONOMETRO = 'RESTART_CRONOMETRO';

export const LISTA_UBICACIONES_SIN_INTERIOR_REQUEST = 'LISTA_UBICACIONES_SIN_INTERIOR_REQUEST';
export const LISTA_UBICACIONES_SIN_INTERIOR_SUCCESS = 'LISTA_UBICACIONES_SIN_INTERIOR_SUCCESS';
export const LISTA_UBICACIONES_SIN_INTERIOR_FAILURE = 'LISTA_UBICACIONES_SIN_INTERIOR_FAILURE';

export const LISTA_FALLAS_ACTIVAS_BY_SERVICIO_REQUEST = 'LISTA_FALLAS_ACTIVAS_BY_SERVICIO_REQUEST';
export const LISTA_FALLAS_ACTIVAS_BY_SERVICIO_SUCCESS = 'LISTA_FALLAS_ACTIVAS_BY_SERVICIO_SUCCESS';
export const LISTA_FALLAS_ACTIVAS_BY_SERVICIO_FAILURE = 'LISTA_FALLAS_ACTIVAS_BY_SERVICIO_FAILURE';

export const LISTA_SERVICIOS_ACTIVOS_PROVEEDOR_REQUEST = 'LISTA_SERVICIOS_ACTIVOS_PROVEEDOR_REQUEST';
export const LISTA_SERVICIOS_ACTIVOS_PROVEEDOR_SUCCESS = 'LISTA_SERVICIOS_ACTIVOS_PROVEEDOR_SUCCESS';
export const LISTA_SERVICIOS_ACTIVOS_PROVEEDOR_FAILURE = 'LISTA_SERVICIOS_ACTIVOS_PROVEEDOR_FAILURE';

export const LISTA_PARTIDAS_BY_FALLA_REQUEST = 'LISTA_PARTIDAS_BY_FALLA_REQUEST';
export const LISTA_PARTIDAS_BY_FALLA_SUCCESS = 'LISTA_PARTIDAS_BY_FALLA_SUCCESS';
export const LISTA_PARTIDAS_BY_FALLA_FAILURE = 'LISTA_PARTIDAS_BY_FALLA_FAILURE';

export const REGISTRAR_TIEMPO_LIMITE_REQUEST = 'REGISTRAR_TIEMPO_LIMITE_REQUEST';
export const REGISTRAR_TIEMPO_LIMITE_SUCCESS = 'REGISTRAR_TIEMPO_LIMITE_SUCCESS';
export const REGISTRAR_TIEMPO_LIMITE_FAILURE = 'REGISTRAR_TIEMPO_LIMITE_FAILURE';

// CU -  Reporteador Automático.
export const SHOW_MODAL_CAMBIAR_HR = 'SHOW_MODAL_CAMBIAR_HR';

export const LISTA_SS_DIARIOS_REQUEST = 'LISTA_SS_DIARIOS_REQUEST';
export const LISTA_SS_DIARIOS_SUCCESS = 'LISTA_SS_DIARIOS_SUCCESS';
export const LISTA_SS_DIARIOS_FAILURE = 'LISTA_SS_DIARIOS_FAILURE';

export const VISUALIZAR_REPORTE_REQUEST = 'VISUALIZAR_REPORTE_REQUEST';
export const VISUALIZAR_REPORTE_SUCCESS = 'VISUALIZAR_REPORTE_SUCCESS';
export const VISUALIZAR_REPORTE_FAILURE = 'VISUALIZAR_REPORTE_FAILURE';

//Reporte solicitud servicio
export const LISTA_REPORTE_BUSQUEDA_REQUEST = 'LISTA_REPORTE_BUSQUEDA_REQUEST';
export const LISTA_REPORTE_BUSQUEDA_SUCCESS = 'LISTA_REPORTE_BUSQUEDA_SUCCESS';
export const LISTA_REPORTE_BUSQUEDA_FAILURE = 'LISTA_REPORTE_BUSQUEDA_FAILURE';

export const EXPORTAR_EXCEL_REPORTE_BUSQUEDA_REQUEST = 'EXPORTAR_EXCEL_REPORTE_BUSQUEDA_REQUEST';
export const EXPORTAR_EXCEL_REPORTE_BUSQUEDA_SUCCESS = 'EXPORTAR_EXCEL_REPORTE_BUSQUEDA_SUCCESS';
export const EXPORTAR_EXCEL_REPORTE_BUSQUEDA_FAILURE = 'EXPORTAR_EXCEL_REPORTE_BUSQUEDA_FAILURE';

export const EXPORTAR_PDF_REPORTE_BUSQUEDA_REQUEST = 'EXPORTAR_PDF_REPORTE_BUSQUEDA_REQUEST';
export const EXPORTAR_PDF_REPORTE_BUSQUEDA_SUCCESS = 'EXPORTAR_PDF_REPORTE_BUSQUEDA_SUCCESS';
export const EXPORTAR_PDF_REPORTE_BUSQUEDA_FAILURE = 'EXPORTAR_PDF_REPORTE_BUSQUEDA_FAILURE';

export const SHOW_MODAL_DETALLE_REPORTE = 'SHOW_MODAL_DETALLE_REPORTE';

//Reporte Dinámico
export const LISTA_BUSQUEDA_REPORTE_DINAMICO_REQUEST = 'LISTA_BUSQUEDA_REPORTE_DINAMICO_REQUEST';
export const LISTA_BUSQUEDA_REPORTE_DINAMICO_SUCCESS = 'LISTA_BUSQUEDA_REPORTE_DINAMICO_SUCCESS';
export const LISTA_BUSQUEDA_REPORTE_DINAMICO_FAILURE = 'LISTA_BUSQUEDA_REPORTE_DINAMICO_FAILURE';

export const LISTA_BUSQUEDA_REPORTE_DINAMICO_WITHOUT_REQUEST = 'LISTA_BUSQUEDA_REPORTE_DINAMICO_WITHOUT_REQUEST';
export const LISTA_BUSQUEDA_REPORTE_DINAMICO_WITHOUT_SUCCESS = 'LISTA_BUSQUEDA_REPORTE_DINAMICO_WITHOUT_SUCCESS';
export const LISTA_BUSQUEDA_REPORTE_DINAMICO_WITHOUT_FAILURE = 'LISTA_BUSQUEDA_REPORTE_DINAMICO_WITHOUT_FAILURE';

export const LISTA_BUSQUEDA_REPORTE_DINAMICO_GRUPO_REQUEST = 'LISTA_BUSQUEDA_REPORTE_DINAMICO_GRUPO_REQUEST';
export const LISTA_BUSQUEDA_REPORTE_DINAMICO_GRUPO_SUCCESS = 'LISTA_BUSQUEDA_REPORTE_DINAMICO_GRUPO_SUCCESS';
export const LISTA_BUSQUEDA_REPORTE_DINAMICO_GRUPO_FAILURE = 'LISTA_BUSQUEDA_REPORTE_DINAMICO_GRUPO_FAILURE';

export const EXPORTAR_PDF_REPORTE_DINAMICO_REQUEST = 'EXPORTAR_PDF_REPORTE_DINAMICO_REQUEST';
export const EXPORTAR_PDF_REPORTE_DINAMICO_SUCCESS = 'EXPORTAR_PDF_REPORTE_DINAMICO_SUCCESS';
export const EXPORTAR_PDF_REPORTE_DINAMICO_FAILURE = 'EXPORTAR_PDF_REPORTE_DINAMICO_FAILURE';

export const EXPORTAR_EXCEL_REPORTE_DINAMICO_REQUEST = 'EXPORTAR_EXCEL_REPORTE_DINAMICO_REQUEST';
export const EXPORTAR_EXCEL_REPORTE_DINAMICO_SUCCESS = 'EXPORTAR_EXCEL_REPORTE_DINAMICO_SUCCESS';
export const EXPORTAR_EXCEL_REPORTE_DINAMICO_FAILURE = 'EXPORTAR_EXCEL_REPORTE_DINAMICO_FAILURE';

export const LIMPIAR_FORMULARIO_RD = 'LIMPIAR_FORMULARIO_RD'
