import React from 'react';
import {connect} from 'react-redux';
import { Row, Col, Modal, Form, Input, Button, Spin} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalCancelacionOrdenSP extends React.Component {
    formRef = React.createRef();

    render () {

        const {showModalCancelarOrdenSP, ordenCancelacionSPSeleccionado, fetchingCancelarOrdenSP,limpiarFormulario,page,busqueda,
             onShowModalCancelacion,onLimpiarFormulario, onCancelarOrdenSP} = this.props;
       
        if (limpiarFormulario && limpiarFormulario === 'COSP') {
            this.formRef.current.resetFields();
            onLimpiarFormulario();
        }

        const onFinish = formulario => {
            onCancelarOrdenSP(formulario,ordenCancelacionSPSeleccionado, busqueda, page);
            console.log(formulario,ordenCancelacionSPSeleccionado);
        }

        const onFinishFailed = () => {
            console.log('Cancel');
        }
        
        const handleCancel = () =>{
            onShowModalCancelacion();
            this.formRef.current.resetFields();
        }

        return (
            <div>
                {ordenCancelacionSPSeleccionado? (
                    <Modal
                        visible={showModalCancelarOrdenSP}
                        title={`Cancelar Orden de Servicio Planificado: '${ordenCancelacionSPSeleccionado.folioOrden}'`}
                        onCancel={handleCancel}
                        footer={false}
                        width='50%'
                    >
                        <Spin spinning={fetchingCancelarOrdenSP}>
                            <Form  {...layout}
                                name='formulario'
                                onFinish={onFinish}
                                
                                ref={this.formRef}
                            >
                                <Row type='flex' justify='space-around'>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Observaciones"
                                            name='observaciones'
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <TextArea
                                                pattern="^^[a-zñA-ZÑ0-9 ÁÉÓÚÍáéóíú]+$"
                                                title="Observaciones sin carácteres especiales"
                                                maxLength={250}
                                                placeholder='Observaciones de cancelación.'
                                                autoSize
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row type='flex' justify='space-around'>
                                    <Col span={6}>
                                        <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Cancelar</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Spin>
                    </Modal>
                ):false }   
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalCancelarOrdenSP: state.ServiciosPlanificadosReducer.showModalCancelarOrdenSP,
        ordenCancelacionSPSeleccionado: state.ServiciosPlanificadosReducer.ordenCancelacionSPSeleccionado,
        fetchingCancelarOrdenSP: state.ServiciosPlanificadosReducer.fetchingCancelarOrdenSP,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        page: state.ServiciosPlanificadosReducer.page,
        busqueda: state.ServiciosPlanificadosReducer.busqueda,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalCancelacion: () => {
            dispatch({ type: 'SHOW_MODAL_CANCELAR_ORDEN_SP', showModalCancelarOrdenSP: false });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        },
        onCancelarOrdenSP: (formulario,ordenCancelacionSPSeleccionado,busqueda, page) => {
            dispatch({ type: 'CANCELAR_ORDEN_SP_REQUEST', formulario: formulario, ordenCancelacionSPSeleccionado:ordenCancelacionSPSeleccionado, busqueda: busqueda, page:page});
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalCancelacionOrdenSP);