import React, {Component} from 'react';
import {Card, Row, Col, Table, Input} from 'antd';
import { FolderOpenOutlined, FileSearchOutlined} from '@ant-design/icons';
import {connect} from 'react-redux';
import  ModalDetalleSeguimientoFolioSS  from "./ModalDetalleSeguimientoFolioSS";
import ModalSeguimientoFolioSS from "./ModalSeguimientoFolioSS";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
const { Search } = Input;

class SeguimientoContainer extends Component {
    componentDidMount(){
        this.props.onGetlistaFoliosSeguimientoSS(1, this.state.busqueda);
    }
    state = {
        page: 1,
        busqueda: {order: 'DESC', field: 'idSolicitudServicio'},
        order: 'DESC',
        field: 'idSolicitudServicio',
        param: '',
        pageTrazabilidad: 1,
        pageTraslado: 1,
    }
    render() { 
        const {onGetlistaFoliosSeguimientoSS, listaSeguimientoFolioSS, listaSeguimientoFolioSSCantidad,
               fetchingSeguimientoFolioSS, onShowModalDetalleFolioSS, onShowModalSeguimientoFolioSS,
                onGetTrazabilidadFolio, onGetTrasladosFolio} = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        };

        const onBuscarFolioSS = (param) => {
            const busqueda = { param: param, campo: this.state.field, orden: this.state.order};
            this.setState({
                busqueda: busqueda,
                page: 1
            })
            onGetlistaFoliosSeguimientoSS(1, busqueda);
        };

        const showTools = (key) => {
            console.log('key', key);

            this.props.onGetUsuariosByIdsServiciosTipoRS([key.idServicio + ' - ']);
            this.props.onGetUsuariosByIdsServiciosTipoAT([key.idServicio + ' - ']);
            this.props.onGetUsuariosByIdsServiciosTipoPYRS([key.idServicio + ' - ']);

            onGetTrazabilidadFolio(key.folio, this.state.pageTrazabilidad);
            onGetTrasladosFolio(key, this.state.pageTraslado);
            onShowModalSeguimientoFolioSS(key, this.state.page, this.state.busqueda);
            this.props.onGetPartidasByFalla(key.falla.split('-')[0])
            this.props.onGetUnidades(key.area.split('-')[0]);
            this.props.onGetInteriores(key.area.split('-')[0], key.unidad.split('-')[0]);
            this.props.onGetSubInteriores(key.area.split('-')[0], key.unidad.split('-')[0], key.interior.split('-')[0]);

            if (key.area !== "No tiene Area Funcional"){
                this.props.obtenerUbicacion(key.area.split('-')[0], '', '' ,'');
            } else if (key.unidad !== "No tiene Unidad Funcional" ){
                this.props.obtenerUbicacion(key.area.split('-')[0], key.unidad.split('-')[0] ,'' ,'');
            } else if (key.interior !== "No tiene Interior Funcional"){
                this.props.obtenerUbicacion(key.area.split('-')[0], key.unidad.split('-')[0], key.interior.split('-')[0], '' );
            } else if (key.subInterior !== "No tiene SubInterior Funcional"){
                this.props.obtenerUbicacion(key.area.split('-')[0], key.unidad.split('-')[0], key.interior.split('-')[0], key.subInterior.split('-')[0]);
            } 
           
        };

        const showInfo = (key) => {
            console.log('key', key)
            onGetTrazabilidadFolio(key.folio, this.state.pageTrazabilidad);
            onShowModalDetalleFolioSS(key);
        }

        const handleTableChange = (pagination, filtro, orden) => {
            this.setState({ page: pagination.current });
            console.log(orden);
            if(orden){
                let campo = 'idSolicitudServicio';
                let order = 'DESC';

                if(orden.order === 'ascend'){
                    order = 'ASC';
                }
                if (orden.columnKey === 'folio') {
                    campo = 'folio';
                } 
                if (orden.columnKey === 'fechaAlta') {
                    campo = 'fechaAlta';
                } 
                const busqueda = { param: this.state.param, field: campo, order: order};
                onGetlistaFoliosSeguimientoSS(pagination.current, busqueda)
                this.setState({
                    order: order,
                    field: campo,
                    busqueda: busqueda
                })
            } else {
                onGetlistaFoliosSeguimientoSS(pagination.current, this.state.busqueda)
            }
        };

        const columns = [{
            title: 'Folio Solicitud de Seguimiento',
            dataIndex: 'folio',
            key: 'folio',
            sorter: true,
            align: 'center'
        },{
            title: 'Estado Folio',
            dataIndex: 'estado',
            key: 'estado',
            align: 'center'
        },{
            title: 'Fecha de Alta',
            dataIndex: 'fechaAlta',
            align: 'center',
            sorter: true,
            key: 'fechaAlta',
        },{
            title: 'Ver Detalle',
            key: 'detalle',
            align: 'center',
            render: (record) => (
               <a className='editarIcono' disabled={!permisosUsuario.verDetalleDeSolicitudDeServicio} onClick={ () => showInfo(record) }> <FileSearchOutlined />
               </a>
            ),          
        }, {
            title: 'Seguimiento',
            key: 'seguimiento',
            align: 'center',
            render: (record) => (
               <a className='editarIcono' disabled={!permisosUsuario.seguimientoDeServicio} onClick={ () => showTools(record) }> < FolderOpenOutlined />
               </a>
            ),          
        },];
        return (
            <div>
                <Card className="cardCatalogos" title={<div className='headerLista'>Seguimiento Solicitudes de Servicio</div>} >
                    <Row type="flex" justify='space-between'>
                        <Col span={24}>
                            <Search className='buscarModulos' placeholder="Buscar Folio o Estado" onSearch={value => onBuscarFolioSS(value)} style={{ width: '100%' }} />
                        </Col>

                        <Col span={24}>
                            <br />
                            <Table
                                size="small"
                                rowKey="idSolicitudServicio"
                                columns={columns}
                                dataSource={listaSeguimientoFolioSS}
                                loading={fetchingSeguimientoFolioSS}
                                locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar' }}
                                onChange={handleTableChange}
                                pagination={{ total: listaSeguimientoFolioSSCantidad, simple: true, current: this.state.page }}
                            />
                        </Col>
                    </Row>
                </Card>
                <ModalDetalleSeguimientoFolioSS />
                <ModalSeguimientoFolioSS />
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        listaSeguimientoFolioSS: state.SolicitudDeServiciosReducer.listaSeguimientoFolioSS,
        listaSeguimientoFolioSSCantidad: state.SolicitudDeServiciosReducer.listaSeguimientoFolioSSCantidad,
        fetchingSeguimientoFolioSS: state.SolicitudDeServiciosReducer.fetchingSeguimientoFolioSS,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetlistaFoliosSeguimientoSS: (page, busqueda) => {
            dispatch({ type: "LISTA_FOLIOS_SS_BUSQUEDA_REQUEST", page, busqueda})
        },
        onShowModalDetalleFolioSS: (folioSolicitudServicioSeleccionado2) => {
            dispatch({ type: "SHOW_MODAL_VER_DETALLE_FOLIO_SS", showModalDetalleFolioSS: true, folioSolicitudServicioSeleccionado2})
        },
        onShowModalSeguimientoFolioSS: (folioSolicitudServicioSeleccionado, page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_OPCIONES_SEGUIMIENTO_FOLIO_SS", showModalOpcionesSeguimientoFolio: true, folioSolicitudServicioSeleccionado, page, busqueda})
        },
        onGetTrazabilidadFolio: (folio, page) => {
            dispatch({ type: "OBTENER_TRAZABILIDAD_FOLIO_REQUEST", folio, page});
        },
        onGetUnidades: (idAreaFuncional) => {
            dispatch({ type: "LISTA_UNIDAD_FUNCIONAL_REQUEST" , idAreaFuncional: idAreaFuncional});
        },
        onGetInteriores: (idAreaFuncional, idUnidadFuncional) => {
            dispatch({ type: "LISTA_INTERIOR_FUNCIONAL_REQUEST", idAreaFuncional: idAreaFuncional, idUnidadFuncional: idUnidadFuncional });
        },
        onGetSubInteriores: (idAreaFuncional, idUnidadFuncional, idInteriorFuncional) => {
            dispatch({ type: "LISTA_SUBINTERIOR_FUNCIONAL_REQUEST", idAreaFuncional: idAreaFuncional,  idUnidadFuncional: idUnidadFuncional, idInteriorFuncional: idInteriorFuncional});
        },
        obtenerUbicacion: (idAreaFuncional, idUnidadFuncional, idInteriorFuncional, idSubInteriorFuncional) => {
            dispatch({ type: "OBTENER_UBICACION_REQUEST", idAreaFuncional, idUnidadFuncional, idInteriorFuncional, idSubInteriorFuncional});
        },
        onGetTrasladosFolio: (folioSolicitudServicioSeleccionado, page) => {
            dispatch({ type: "LISTA_TRASLADOS_REQUEST", folioSolicitudServicioSeleccionado, page})
        },
        onGetPartidasByFalla: (idFalla) => {
            dispatch({ type: 'LISTA_PARTIDAS_BY_FALLA_REQUEST', idFalla });
        },

        onGetUsuariosByIdsServiciosTipoRS: (idsServicios) => {
            dispatch({ type: 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_RS_REQUEST', idsServicios });
        },
        onGetUsuariosByIdsServiciosTipoAT: (idsServicios) => {
                dispatch({ type: 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_AT_REQUEST', idsServicios });
        },
        onGetUsuariosByIdsServiciosTipoPYRS: (idsServicios) => {
                dispatch({ type: 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_PYRS_REQUEST', idsServicios });
        },
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(SeguimientoContainer) ;