import React from 'react';
import { Form, Row, Col, Modal, Typography, Descriptions, TimePicker, Collapse, Input, Table, Divider } from 'antd';
import { connect } from "react-redux";
import { CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const {Text} = Typography;
const { Panel } = Collapse;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };

class ModalActualizar extends React.Component {
    formRef = React.createRef();

     componentDidMount() {
          
     }

     state = {
          asignacionByIdAsignacionLocal: [],
     }

     render() {
          const { 
               showModalActualizarRM,
               onCloseModal,
               programaRMPSeleccionado,
               programa,
               page,
               busqueda,
               onActualizarRMP,
               idsServicios,
               onShowFechasActualizar,
          } = this.props;

          const handleCancel = () => {
               console.log('Entra a handleCancel')
               onCloseModal();
               this.formRef.current.resetFields();
          };

          const onGuardarHora = () => {
               onCloseModal();
               this.formRef.current.resetFields();
          };

          const columns = [
               {title: 'Descripcion',dataIndex: 'descripcion',key: 'descripcion',align: 'center', width: '60%',},
               {title: 'Cantidad',dataIndex: 'cantidad',key: 'cantidad',align: 'center', width: '15%',},
               {title: 'Unidad de Medida',dataIndex: 'unidadMedida',key: 'unidadMedida',align: 'center', width: '25%',}
          ];

          let dataUbi = [];
          let dataAct = [];
          let columnsUbi = [];
          let columnsAct = [];
          if (programaRMPSeleccionado){
               console.log('programaRMPSeleccionado', programaRMPSeleccionado);

               if(programa === 'Rutina' ){
                    let { asignacionByIdAsignacionServicioPlanificado, programacionRutinasByIdProgramacionRutina } = programaRMPSeleccionado;

                    if(!programaRMPSeleccionado.fechaEspecifica){
                         if(programaRMPSeleccionado.estadoRutina === 'Programada'){
                              for(let i = 0; i < asignacionByIdAsignacionServicioPlanificado.length; i++){
                                   for(let j = 0; j < programacionRutinasByIdProgramacionRutina.length; j++){
                                        if(asignacionByIdAsignacionServicioPlanificado[i].idAsignacionServicioPlanificado === programacionRutinasByIdProgramacionRutina[j].idAsignacionServicioPlanificado){
                                             asignacionByIdAsignacionServicioPlanificado[i].hora = programacionRutinasByIdProgramacionRutina[j].hora;

                                             let fechaInicial = (programacionRutinasByIdProgramacionRutina[j].fechaInicial).split('T')[0];

                                             asignacionByIdAsignacionServicioPlanificado[i].fechaInicial = fechaInicial.split('-')[2] + '-' + fechaInicial.split('-')[1] + '-' + fechaInicial.split('-')[0];

                                             asignacionByIdAsignacionServicioPlanificado[i].dias = programaRMPSeleccionado.dias;
                                        }
                                   }
                              }

                              columnsUbi = [
                                   {title: 'Ubicación',dataIndex: 'ubicacionCOA',key: 'ubicacionCOA',align: 'left', width: '60%'},
                                   {title: 'Fecha Inicial',dataIndex: 'fechaInicial',key: 'fechaInicial',align: 'center', width: '10%'},
                                   {title: 'Frecuencia',dataIndex: 'dias', key: 'dias',align: 'center', width: '10%'},
                                   { title: 'Fechas', dataIndex: '', key: '', align: 'center', width: '10',
                                   render: (record) => (
                                        <a className='editarIcono' onClick={() => onFechasActualizar(record)}><CalendarOutlined /></a>
                                   ),},
                                   {title: 'Propuesta',dataIndex: 'hora',key: 'hora',align: 'center', width: '10%', fixed: 'right',
                                   render: (record, key) => (
                                        <Form.Item rules={[{ required: true, message: 'Favor de llenar el campo' }]}>
                                             <TimePicker allowClear={false} format='HH:mm' defaultValue={moment(record, 'HH:mm')} onChange = {(value) => handleCapturar(value, record, key)} /> 
                                        </Form.Item>
                                   )},
                              ];
                         } else {
                              columnsUbi = [
                                   {title: 'Ubicación',dataIndex: 'ubicacionCOA',key: 'ubicacionCOA',align: 'left', width: '100%'},
                              ];
                         }
                    } else {
                         if(programaRMPSeleccionado.estadoRutina === 'Programada'){
                              for(let i = 0; i < asignacionByIdAsignacionServicioPlanificado.length; i++){
                                   for(let j = 0; j < programacionRutinasByIdProgramacionRutina.length; j++){
                                        if(asignacionByIdAsignacionServicioPlanificado[i].idAsignacionServicioPlanificado === programacionRutinasByIdProgramacionRutina[j].idAsignacionServicioPlanificado){
                                             asignacionByIdAsignacionServicioPlanificado[i].hora = programacionRutinasByIdProgramacionRutina[j].hora;
                                        }
                                   }
                              }

                              columnsUbi = [
                                   {title: 'Ubicación',dataIndex: 'ubicacionCOA',key: 'ubicacionCOA',align: 'left', width: '70%'},
                                   { title: 'Fechas', dataIndex: '', key: '', align: 'center', width: '15',
                                   render: (record) => (
                                        <a className='editarIcono' onClick={() => onFechasActualizar(record)}><CalendarOutlined /></a>
                                   ),},
                                   {title: 'Propuesta',dataIndex: 'hora',key: 'hora',align: 'center', width: '10%', fixed: 'right',
                                   render: (record, key) => (
                                        <Form.Item rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <TimePicker allowClear={false} format='HH:mm' defaultValue={moment(record, 'HH:mm')} onChange = {(value) => handleCapturar(value, record, key)} />  
                                        </Form.Item>
                                   )},
                              ];

                         } else {
                              columnsUbi = [
                                   {title: 'Ubicación',dataIndex: 'ubicacionCOA',key: 'ubicacionCOA',align: 'left', width: '100%'},
                              ];
                         }
                    }

                    //this.setState({ asignacionByIdAsignacionLocal: asignacionByIdAsignacionServicioPlanificado });

                    dataUbi = [
                         {title: 'Ubicacion', data: asignacionByIdAsignacionServicioPlanificado},
                         {title: 'Programacion', data: programacionRutinasByIdProgramacionRutina}
                    ];
                    console.log('dataUbi', dataUbi)
               } else {
                    console.log('Entró a else, Mantenimiento o prueba');
                    let {asignacionByIdAsignacionServicioPlanificado, programacionRutinasByIdProgramacionRutina} = programaRMPSeleccionado;

                    if(!programaRMPSeleccionado.fechaEspecifica){
                         if(programaRMPSeleccionado.estadoRutina === 'Programada'){
                              console.log('Entró a === programada');
                              if(programaRMPSeleccionado.asignacionByIdAsignacionServicioPlanificado){

                                   for(let i = 0; i < asignacionByIdAsignacionServicioPlanificado.length; i++){
                                        if(asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo){
                                             asignacionByIdAsignacionServicioPlanificado[i].serieNum = asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.serieNum;

                                             asignacionByIdAsignacionServicioPlanificado[i].codigoInterno = asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.codigoInterno;

                                             // idEstadoActivo = 'En operación'
                                             asignacionByIdAsignacionServicioPlanificado[i].idEstadoActivo = asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.idEstadoActivo;

                                             if(asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.activoCaractByIdActivoCaract){
                                                  console.log('Entró a descripcion');
                                                  asignacionByIdAsignacionServicioPlanificado[i].descripcion = asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.activoCaractByIdActivoCaract.productoByIdProducto.nombre;
                                             }

                                             if(asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.ubicacionByIdUbicacion){
                                                  //Ubicación del Activo
                                                  if(asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional){
                                                       asignacionByIdAsignacionServicioPlanificado[i].ubicacionCOA = asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.codigoArea + ' - ' + asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;
                           
                                                       if(asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional){
                                                            asignacionByIdAsignacionServicioPlanificado[i].ubicacionCOA += ', ' + asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                           
                                                            if(asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional){
                                                                 asignacionByIdAsignacionServicioPlanificado[i].ubicacionCOA += ', ' + asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional;
                              
                                                                 if(asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional){
                                                                      asignacionByIdAsignacionServicioPlanificado[i].ubicacionCOA += ', ' + asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                                                                 }
                                                            }
                                                       }
                                                  }
                                             }
                                        }

                                        if(asignacionByIdAsignacionServicioPlanificado[i].idEstadoActivo === 1){
                                             dataAct.push(asignacionByIdAsignacionServicioPlanificado[i]);
                                        }
                                   }

                                   for(let i = 0; i < asignacionByIdAsignacionServicioPlanificado.length; i++){
                                        for(let j = 0; j < programacionRutinasByIdProgramacionRutina.length; j++){
                                             if(asignacionByIdAsignacionServicioPlanificado[i].idAsignacionServicioPlanificado === programacionRutinasByIdProgramacionRutina[j].idAsignacionServicioPlanificado){
                                                  asignacionByIdAsignacionServicioPlanificado[i].hora = programacionRutinasByIdProgramacionRutina[j].hora;
     
                                                  let fechaInicial = (programacionRutinasByIdProgramacionRutina[j].fechaInicial).split('T')[0];
     
                                                  asignacionByIdAsignacionServicioPlanificado[i].fechaInicial = fechaInicial.split('-')[2] + '-' + fechaInicial.split('-')[1] + '-' + fechaInicial.split('-')[0];

                                                  asignacionByIdAsignacionServicioPlanificado[i].dias = programaRMPSeleccionado.dias;
                                             }
                                        }
                                   }

                                   dataAct = [
                                        {title: 'Activo', data: asignacionByIdAsignacionServicioPlanificado},
                                   ];

                                   columnsAct = [
                                        {title: 'Descripción',dataIndex: 'descripcion',key: 'descripcion',align: 'center', width: '20%'},
                                        {title: 'Número de Serie',dataIndex: 'serieNum',key: 'serieNum',align: 'center', width: '14%'},
                                        {title: 'Código Interno', dataIndex: 'codigoInterno',key: 'codigoInterno',align: 'center', width: '10%'},
                                        {title: 'Ubicación del Activo', dataIndex: 'ubicacionCOA',key: 'ubicacionCOA',align: 'center', width: '20%'},
                                        {title: 'Fecha Inicial',dataIndex: 'fechaInicial',key: 'fechaInicial',align: 'center', width: '10%'},
                                        {title: 'Frecuencia',dataIndex: 'dias',key: 'dias',align: 'center', width: '8%'},
                                        { title: 'Fechas', dataIndex: '', key: '', align: 'center', width: '8',
                                        render: (record) => (
                                             <a className='editarIcono' onClick={() => onFechasActualizar(record)}><CalendarOutlined /></a>
                                        ),},
                                        {title: 'Propuesta',dataIndex: 'hora',key: 'hora',align: 'center', width: '10%', fixed: 'right',
                                        render: (record, key) => (
                                             <Form.Item rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                             <TimePicker allowClear={false} format='HH:mm' defaultValue={moment(record, 'HH:mm')} onChange = {(value) => handleCapturar(value, record, key)} /> 
                                             </Form.Item>
                                        )},
                                   ];
                              }
                         } else {
                              console.log('Entró a else de programado');
                              if(programaRMPSeleccionado.asignacionByIdAsignacionServicioPlanificado){

                                   for(let i = 0; i < asignacionByIdAsignacionServicioPlanificado.length; i++){
                                        if(asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo){
                                             asignacionByIdAsignacionServicioPlanificado[i].idEstadoActivo = asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.idEstadoActivo;
                                             asignacionByIdAsignacionServicioPlanificado[i].serieNum = asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.serieNum;
                                             asignacionByIdAsignacionServicioPlanificado[i].descripcion = asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.activoCaractByIdActivoCaract.productoByIdProducto.nombre;
                                             if(asignacionByIdAsignacionServicioPlanificado[i].idEstadoActivo === 1){
                                                  dataAct.push(asignacionByIdAsignacionServicioPlanificado[i]);
                                             }
                                        }
                                   }

                                   dataAct = [
                                        {title: 'Activo', data: asignacionByIdAsignacionServicioPlanificado},
                                   ];

                                   columnsAct = [
                                        {title: 'Descripción',dataIndex: 'descripcion',key: 'descripcion',align: 'center', width: '30%'},
                                        {title: 'SKU',dataIndex: 'sku',key: 'sku',align: 'center', width: '20%'},
                                        {title: 'Código Interno', dataIndex: 'codigoInterno',key: 'codigoInterno',align: 'center', width: '20%'},
                                        {title: 'Ubicación del Activo', dataIndex: 'ubicacionCOA',key: 'ubicacionCOA',align: 'center', width: '30%'},
                                   ];
                              }
                         }
                    } else {
                         if(programaRMPSeleccionado.estadoRutina === 'Programada'){

                              for(let i = 0; i < asignacionByIdAsignacionServicioPlanificado.length; i++){
                                   for(let j = 0; j < programacionRutinasByIdProgramacionRutina.length; j++){
                                        if(asignacionByIdAsignacionServicioPlanificado[i].idAsignacionServicioPlanificado === programacionRutinasByIdProgramacionRutina[j].idAsignacionServicioPlanificado){
                                             asignacionByIdAsignacionServicioPlanificado[i].hora = programacionRutinasByIdProgramacionRutina[j].hora;
                                        }
                                   }
                              }

                              columnsAct = [
                                   {title: 'Descripción',dataIndex: 'descripcion',key: 'descripcion',align: 'center', width: '20%'},
                                   {title: 'SKU',dataIndex: 'sku',key: 'sku',align: 'center', width: '10%'},
                                   {title: 'Código Interno', dataIndex: 'codigoInterno',key: 'codigoInterno',align: 'center', width: '10%'},
                                   {title: 'Ubicación del Activo', dataIndex: 'ubicacionCOA',key: 'ubicacionCOA',align: 'center', width: '40%'},
                                   { title: 'Fechas', dataIndex: '', key: '', align: 'center', width: '10',
                                   render: (record) => (
                                        <a className='editarIcono' onClick={() => onFechasActualizar(record)}><CalendarOutlined /></a>
                                   ),},
                                   {title: 'Propuesta',dataIndex: 'hora',key: 'hora',align: 'center', width: '10%', fixed: 'right',
                                   render: (record, key) => (
                                        <Form.Item rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <TimePicker allowClear={false} format='HH:mm' defaultValue={moment(record, 'HH:mm')} onChange = {(value) => handleCapturar(value, record, key)} /> 
                                        </Form.Item>
                                   )},
                              ];

                         } else {
                              columnsAct = [
                                   {title: 'Descripción',dataIndex: 'descripcion',key: 'descripcion',align: 'center', width: '30%'},
                                   {title: 'SKU',dataIndex: 'sku',key: 'sku',align: 'center', width: '20%'},
                                   {title: 'Código Interno', dataIndex: 'codigoInterno',key: 'codigoInterno',align: 'center', width: '20%'},
                                   {title: 'Ubicación del Activo', dataIndex: 'ubicacionCOA',key: 'ubicacionCOA',align: 'center', width: '30%'},
                              ];
                         }

                         dataAct = [
                              {title: 'Activo', data: asignacionByIdAsignacionServicioPlanificado},
                         ];
                    }

                    //this.setState({ asignacionByIdAsignacionLocal: asignacionByIdAsignacionServicioPlanificado });
               }
          }

          const handleCapturar = (value, record, key) => {
               console.log('key', key)
               if(programa === 'Rutina'){
                    console.log('dataUbi', dataUbi)
                    for(let i = 0; i < dataUbi[0].data.length; i++){
                         if(dataUbi[0].data[i].idAsignacionServicioPlanificado === key.idAsignacionServicioPlanificado){
                              dataUbi[0].data[i].hora = value.format('HH:mm:ss');
                         }
                    }

                    onActualizarRMP(page, busqueda, dataUbi[0].data, programaRMPSeleccionado, key, programa, idsServicios);
               } else {
                    console.log('dataAct', dataAct);
                    for(let i = 0; i < dataAct[0].data.length; i++){
                         if(dataAct[0].data[i].idAsignacionServicioPlanificado === key.idAsignacionServicioPlanificado){
                              dataAct[0].data[i].hora = value.format('HH:mm:ss');
                         }
                    }

                    onActualizarRMP(page, busqueda, dataAct[0].data, programaRMPSeleccionado, key, programa, idsServicios);
               }
          }

          const onFinish = (formulario) => {
               console.log('Success', formulario);

               if(programa === 'Rutina'){

                    onActualizarRMP(page, busqueda, dataUbi[0].data, programaRMPSeleccionado);
               } else {

                    onActualizarRMP(page, busqueda, dataAct[0].data, programaRMPSeleccionado);
               }
          };

          const onFechasActualizar = (key) => {
               console.log('key en onFechasActualizar', key)
               onShowFechasActualizar(key, programaRMPSeleccionado, programa, page, busqueda, 'actualizar');
          };
          
          return (
               <Modal
                    visible={showModalActualizarRM}
                    title={'Editar Programación'}
                    onCancel={handleCancel}
                    footer={false}
                    width={'70%'}
               >
                    { this.props.programaRMPSeleccionado ? (<>
                         <Form {...layout}
                              name="formulario"
                              ref={this.formRef}
                              onFinish={onFinish}
                              initialValues={this.props.programaRMPSeleccionado}
                         >
                              <Row justify='space-around'>
                                   <Col span={24}>
                                        <Descriptions layout="horizontal" bordered={true}>
                                             <Descriptions.Item label='Nombre de rutina'> <Text type="warning" strong> {this.props.programaRMPSeleccionado.rutina} </Text> </Descriptions.Item>
                                             <Descriptions.Item label='Categoría'>{this.props.programaRMPSeleccionado.categoria}</Descriptions.Item>
                                             <Descriptions.Item label='Nomenclatura'>{this.props.programaRMPSeleccionado.nomenclatura}</Descriptions.Item>
                                             <Descriptions.Item label='Programa'>{this.props.programaRMPSeleccionado.programa}</Descriptions.Item>
                                             <Descriptions.Item label='Tipo'>{this.props.programaRMPSeleccionado.tipoRutina}</Descriptions.Item>
                                             <Descriptions.Item label='Clave R/M/P'>{this.props.programaRMPSeleccionado.claveRutina}</Descriptions.Item>
                                             <Descriptions.Item label='Actividades'>{this.props.programaRMPSeleccionado.actividades}</Descriptions.Item>
                                             <Descriptions.Item label='EPP'>{this.props.programaRMPSeleccionado.epp}</Descriptions.Item>
                                             <Descriptions.Item label='Personal'>{this.props.programaRMPSeleccionado.personal}</Descriptions.Item>
                                        </Descriptions>
                                   </Col>

                                   <Col span={24}>
                                        <Row justify='space-around'>
                                             <Col span={11}>
                                                  <Divider orientation="left">Insumos</Divider>
                                                  <Table 
                                                       size='small'
                                                       rowKey='descripcion'
                                                       columns={columns}
                                                       dataSource={programaRMPSeleccionado.insumosByIdRutina ? programaRMPSeleccionado.insumosByIdRutina : []}
                                                       locale={{ emptyText: 'Sin datos' }}
                                                       pagination={{
                                                            total: programaRMPSeleccionado.insumosByIdRutina ? programaRMPSeleccionado.insumosByIdRutina.length : 0,
                                                            pageSize: programaRMPSeleccionado.insumosByIdRutina ? programaRMPSeleccionado.insumosByIdRutina.length : 0,
                                                            hideOnSinglePage: true
                                                       }}
                                                       scroll={{ y: 450 }}
                                                  />
                                                  <br/>
                                             </Col>
                                             <Col span={11}>
                                                  <Divider orientation="left">Herramientas</Divider>
                                                  <Table 
                                                       size='small'
                                                       rowKey='descripcion'
                                                       columns={columns}
                                                       dataSource={programaRMPSeleccionado.herramientasByIdRutina ? programaRMPSeleccionado.herramientasByIdRutina : []}
                                                       locale={{ emptyText: 'Sin datos' }}
                                                       pagination={{
                                                            total: programaRMPSeleccionado.herramientasByIdRutina ? programaRMPSeleccionado.herramientasByIdRutina.length : 0,
                                                            pageSize: programaRMPSeleccionado.herramientasByIdRutina ? programaRMPSeleccionado.herramientasByIdRutina.length : 0,
                                                            hideOnSinglePage: true
                                                       }}
                                                       scroll={{ y: 450 }}
                                                  />
                                             </Col>
                                        </Row>
                                   </Col>

                                   <Divider orientation='left'>Actualizar Propuesta</Divider>

                                   
                                   { dataUbi[0] ? (<>
                                        { programa === 'Rutina' ? ( <> 
                                             <Col span={23}>
                                                  <Table
                                                  size='small'
                                                  rowKey='idAsignacionServicioPlanificado'
                                                  columns={columnsUbi}
                                                  dataSource={dataUbi[0].data}
                                                  locale={{ emptyText: 'Sin datos' }}
                                                  pagination={{
                                                       total: dataUbi[0].data.length,
                                                       pageSize: dataUbi[0].data.length,
                                                       hideOnSinglePage: true
                                                       }}
                                                       scroll={{ x: 1500, y: 450 }}
                                                  />
                                             </Col>
                                        </>) : false }
                                   </>) : false }
                                   { dataAct[0] ? (<>
                                        { programa !== 'Rutina' ? ( <> 
                                             <Col span={23}>
                                                  <Table
                                                  size='small'
                                                  rowKey='idAsignacionServicioPlanificado'
                                                  columns={columnsAct}
                                                  dataSource={dataAct[0].data}
                                                  locale={{ emptyText: 'Sin datos' }}
                                                  pagination={{
                                                       total: dataAct[0].data.length,
                                                       pageSize: dataAct[0].data.length,
                                                       hideOnSinglePage: true
                                                       }}
                                                       scroll={{ x: 1500, y: 450 }}
                                                  />
                                             </Col>
                                        </>) : false }
                                   </>): false }
                                   
                                   {/* <Col span={11}>
                                        <br/>
                                        <Button style={{width: '100%'}} className='btnPrueba' htmlType='submit'>
                                             Editar
                                        </Button>
                                   </Col> */}

                              </Row>
                         </Form>
                    </>): false }
                    {/* <ModalFecha /> */}
               </Modal>
          );
     }
}

const mapStateToProps = state => {
     return {
          page: state.ServiciosPlanificadosReducer.page,
          busqueda: state.ServiciosPlanificadosReducer.busqueda,

          showModalActualizarRM: state.ServiciosPlanificadosReducer.showModalActualizarRM,
          programaRMPSeleccionado: state.ServiciosPlanificadosReducer.programaRMPSeleccionado,
          programa: state.ServiciosPlanificadosReducer.programa,
          idsServicios: state.ServiciosPlanificadosReducer.idsServicios,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onCloseModal: () => {
               dispatch({ type: 'SHOW_MODAL_ACTUALIZAR_RM', showModalActualizarRM: false });
          },
          onActualizarRMP: (page, busqueda, data, programaRMPSeleccionado, asignacionSeleccionado, programa, idsServicios) => {
               dispatch({ type: 'EDITAR_RMP_REQUEST', page, busqueda, data, programaRMPSeleccionado, asignacionSeleccionado, programa, idsServicios});
          },
          onShowFechasActualizar: (asignacionRMPSeleccionada, programaRMPSeleccionado, programa, page, busqueda, typeModal) => {
               dispatch({ type: 'SHOW_MODAL_FECHAS_RMP', showModalFechaRMP: true, asignacionRMPSeleccionada,  programaRMPSeleccionado, programa, page, busqueda, typeModal });
          }
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalActualizar);
