import React from 'react';
import {Form, Spin, Row, Col, Table, Tooltip, Select, Button, Typography} from 'antd';
import {connect} from "react-redux";
import '../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { SaveOutlined } from '@ant-design/icons';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-classic-base64-upload/build/ckeditor';

const {Text} = Typography;

ClassicEditor.defaultConfig = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'insertTable',
        '|',
        'imageUpload',
        '|',
        'undo',
        'redo'
      ]
    },
    image: {
      toolbar: [
        'imageStyle:full',
        'imageStyle:side',
        '|',
        'imageTextAlternative'
      ]
    },
    table: {
      contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
    },
    language: 'es'
  };

class Acciones extends React.Component {
    formRef = React.createRef();

    componentDidMount() {

        if(this.props.RMDPObject){
            if(this.props.RMDPObject.descripcionAccionesByIdRmdp){
                this.props.onGetAccionesActual(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, 'acciones_' + this.props.mesSeleccionadoRMDP + '_' + this.props.anioSeleccionadoRMDP + '.txt');
            }
        }

        this.props.onObtenerArchivoMesAnterior( this.props.mesSeleccionadoRMDP,  this.props.anioSeleccionadoRMDP);
    }

    state = {
        data: ''
    }

    render() {
        const {
            anioSeleccionadoRMDP,
            mesSeleccionadoRMDP,
            mesTexto,
            onShowModalEditorDeTexto,
            fetchingObtenerArchivoMesAnteriorAcciones,
            archivoMesAnteriorAcciones,
            RMDPObject,
            archivoMesActualAcciones
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        let baseData = `
            <body>
            <h1>Acciones</h1>
            <br />
            </body>
        `

        const onGuardarAcciones = () => {
            console.log("RMDPObject", RMDPObject);
            if (this.state.data.length > 0) {
                if (RMDPObject && RMDPObject.descripcionAccionesByIdRmdp) {
                    console.log('Entra a editar')
                    this.props.onEditarAcciones(mesSeleccionadoRMDP, anioSeleccionadoRMDP, this.state.data, RMDPObject);
                } else {
                    console.log('Entra a crear')
                    this.props.onCrearAcciones(mesSeleccionadoRMDP, anioSeleccionadoRMDP, this.state.data, RMDPObject);
                }
            }
        };

        return (<Spin spinning={fetchingObtenerArchivoMesAnteriorAcciones || this.props.fetchingObtenerArchivoMesActualAcciones || this.props.fetchingCrearAcciones || this.props.fetchingEditarAcciones}>
            <Row justify='end'>
                <Col span={4} style={{backgroundColor: '#737373', borderTopLeftRadius: '1em', borderTopRightRadius: '1em', textAlign:'center'}}>
                    <Text style={{ fontSize: 16, color: "white"}} strong> {anioSeleccionadoRMDP} - {mesTexto} </Text>
                </Col>
            </Row>
                { console.log('archivoMesActualAcciones', archivoMesActualAcciones) }
                { console.log('archivoMesAnteriorAcciones', archivoMesAnteriorAcciones) }
                <CKEditor
                    editor={ ClassicEditor }
                    data={archivoMesActualAcciones ? archivoMesActualAcciones : archivoMesAnteriorAcciones ? archivoMesAnteriorAcciones : baseData}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        //editor.config._config.toolbar = ["heading","|","bold","italic","bulletedList","numberedList","|","uploadImage","blockQuote","insertTable","undo","redo"];
                        console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        this.setState({
                            data: data
                        });
                        console.log({event, editor, data});
                    }}
                    onBlur={(event, editor) => {
                        console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                        console.log('Focus.', editor);
                    }}
                />
            <Row justify='end' style={{padding:'1em'}}>
                <Col span={1}>
                    <Tooltip title='Guardar Cambios'>
                        <Button className="iconTer" shape="circle" icon={<SaveOutlined />} onClick={onGuardarAcciones}/>
                    </Tooltip>
                </Col>
            </Row>
        </Spin>);
    }
}

const mapStateToProps = state => {
    return {
        anioSeleccionadoRMDP: state.RMDPReducer.anioSeleccionadoRMDP,
        mesSeleccionadoRMDP: state.RMDPReducer.mesSeleccionadoRMDP,
        mesTexto: state.RMDPReducer.mesTexto,
        fetchingObtenerArchivoMesAnteriorAcciones: state.RMDPReducer.fetchingObtenerArchivoMesAnteriorAcciones,
        archivoMesAnteriorAcciones: state.RMDPReducer.archivoMesAnteriorAcciones,
        RMDPObject: state.RMDPReducer.RMDPObject,
        archivoMesActualAcciones: state.RMDPReducer.archivoMesActualAcciones,
        fetchingObtenerArchivoMesActualAcciones: state.RMDPReducer.fetchingObtenerArchivoMesActualAcciones,
        fetchingCrearAcciones: state.RMDPReducer.fetchingCrearAcciones,
        fetchingEditarAcciones: state.RMDPReducer.fetchingEditarAcciones,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditorDeTexto: () => {
            dispatch({type: 'MODAL_EDITOR_TEXTO', showModalEditorTexto: true, dataEditorDeTexto: null})
        },
        onCrearAcciones: (mes, anio, fileData, RMDPObject) => {
            dispatch({type: 'CREAR_ACCIONES_REQUEST', mes, anio, fileData, RMDPObject})
        },
        onEditarAcciones: (mes, anio, fileData, RMDPObject) => {
            dispatch({type: 'EDITAR_ACCIONES_REQUEST', mes, anio, fileData, RMDPObject})
        },
        onObtenerArchivoMesAnterior: (mes, anio) => {
            dispatch({type: 'OBTENER_ARCHIVO_MES_ANTERIOR_ACCIONES_REQUEST', mes, anio})
        },
        onGetAccionesActual: (mes, anio, nombreArchivo) => {
            dispatch({ type: 'OBTENER_ARCHIVO_MES_ACTUAL_ACCIONES_REQUEST', mes, anio, nombreArchivo });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Acciones);
