import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Select, Tooltip } from 'antd';
import { EditOutlined, FileExcelOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevaUbicacionProducto from './ModalNuevaUbicacionProducto';
import ModalEditarUbicacionProducto from './ModalEditarUbicacionProducto';

const confirm = Modal.confirm;
const { Search } = Input;
const { Option } = Select;

class CatalogoUbicacionesProductos extends React.Component {
    state = {
        page: 1,
        order: 'ASC',
        field: 'idUbicacionProducto',
        busqueda: '',
        nombreAlmacen: "",
    }

    componentDidMount() {
        this.props.onGetUbicacionesProductos(1);
        this.props.onGetAlmacenes();
    }

    render() {
        const { fetchingListaUbicacionesProductosBusqueda, listaUbicacionesProductosBusqueda,listaUbicacionesProductosBusquedaCantidad, onGetUbicacionesProductos,
            onModalNuevaUbicacionProducto, onModalEditarUbicacionProducto, onEstadoUbicacionProducto, fetchingListaAlmacenesActivos, listaAlmacenesActivos } = this.props;
                
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columns = [{
            title: 'Almacén',
            dataIndex: 'almacen',
            key: 'almacen',
            sorter: true,
        },{
            title: 'Piso',
            dataIndex: 'piso',
            key: 'piso',
            sorter: true,
        }, {
            title: 'Área',
            dataIndex: 'area',
            key: 'area',
            sorter: true,
        }, {
            title: 'Pasillo',
            dataIndex: 'pasillo',
            key: 'pasillo',
            sorter: true,
        },{
            title: 'Rack',
            dataIndex: 'rack',
            key: 'rack',
            sorter: true,
        }, {
            title: 'Nivel del rack',
            dataIndex: 'nivelRack',
            key: 'nivelRack',
        }, {
            title: 'Posición en el rack',
            dataIndex: 'posicionRack',
            key: 'posicionRack',
            sorter: true,
        }, {
            title: 'Activo',
            dataIndex: 'activo',
            key: 'activo',
            align: 'center',
            render: (text, record) => (
                <Switch checked={text} disabled={!permisosUsuario.ubicaciónDelProductoActivoInactivo} onChange = {() => handleDesactivar(record)} />
            ),
        }, {
            title: 'Editar',
            key: 'editar',
            align: 'center',
            //width: '6%',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.editarUbicaciónDelProducto}  onClick={() => onEditar(record)}><EditOutlined /></a>
            ),
        },
        ];
        
        const onBuscarUbicacionProducto = (busqueda, name) => {
            this.setState({
                [name]: busqueda,
                page: 1,
            }, ()=> onGetUbicacionesProductos(1, this.state.busqueda, this.state.field, this.state.order, this.state.nombreAlmacen));
        } 

        const handleDesactivar = (key) => {
            console.log(key.idUbicacionProducto);
            let page = this.state.page
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado de la ubicación del producto?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoUbicacionProducto(key.idUbicacionProducto, page);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }

        const onEditar = (key) => {
            onModalEditarUbicacionProducto(key, this.state.page);

        }

        const onNuevaUbicacionProducto = () => {
            onModalNuevaUbicacionProducto(this.state.page);
        }

        const handleTableChange = (pagination, filtro, orden) => {
            this.setState({
                page: pagination.current,
            });
            if (orden.columnKey) {
                let order = 'ASC';
                const campos = {almacen:'alm.nombreC',piso:'piso',area: 'area', pasillo: 'pasillo', rack: 'rack', nivelRack: 'nivelRack', posicionRack: 'posicionRack'};
                let campo = campos[orden.columnKey];

                if (orden.order === 'descend') {
                    order = 'DESC';
                }

                this.setState ({
                    order: order, 
                    field: campo,
                });
                onGetUbicacionesProductos(pagination.current, this.state.busqueda, campo, order, this.state.nombreAlmacen);
            } else {
                onGetUbicacionesProductos(pagination.current, this.state.busqueda, this.state.field, this.state.order, this.state.nombreAlmacen);
            }
        }


        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={10}>
                        <Search className='buscarModulos' placeholder='Ingresar nombre del almacén' onSearch={(value) => onBuscarUbicacionProducto(value, "nombreAlmacen")} />
                    </Col>
                    <Col span={10}>
                        <Search className='buscarModulos' placeholder='Ingresar ubicacion del producto' onSearch={(value) => onBuscarUbicacionProducto(value, "busqueda")} />
                    </Col>
                    {/* <Col span={5}>
                        <Button onClick={onNuevaUbicacionProducto} disabled={!permisosUsuario.crearUbicación} className='nuevoUsuario'>Nueva Ubicación</Button>
                    </Col> */}

                    <Col span={1}>
                        <Tooltip title='Nueva Ubicación' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevaUbicacionProducto} disabled={!permisosUsuario.crearUbicación} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>

                    <Col span={1}>
                        <Tooltip title='Exportar archivo .xls' placement="topRight">
                            <Button disabled={!permisosUsuario.exportarExcelCatálogoUbicacionesProductos} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } loading={this.props.fetchingExportarExcelUbicacionesProductos} onClick={() => this.props.onExport(this.state.order, this.state.field)}/>
                        </Tooltip>
                    </Col>

                    <Col span={24}>
                        <br />
                        <Table
                            size="small"
                            rowKey="piso"
                            columns={columns}
                            dataSource={listaUbicacionesProductosBusqueda}
                            loading={fetchingListaUbicacionesProductosBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaUbicacionesProductosBusquedaCantidad, simple: true, current: this.state.page }}
                        />
                    </Col>
                </Row>
                <ModalNuevaUbicacionProducto/>
                <ModalEditarUbicacionProducto/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        listaUbicacionesProductosBusqueda: state.AlmacenesReducer.listaUbicacionesProductosBusqueda,
        fetchingListaUbicacionesProductosBusqueda: state.AlmacenesReducer.fetchingListaUbicacionesProductosBusqueda,
        listaUbicacionesProductosBusquedaCantidad: state.AlmacenesReducer.listaUbicacionesProductosBusquedaCantidad,
        fetchingListaAlmacenesActivos: state.AlmacenesReducer.fetchingListaAlmacenesActivos,
        listaAlmacenesActivos: state.AlmacenesReducer.listaAlmacenesActivos,
        fetchingExportarExcelUbicacionesProductos: state.AlmacenesReducer.fetchingExportarExcelUbicacionesProductos,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetUbicacionesProductos: (page, busqueda, campo, orden,nombreAlmacen) => {
            dispatch({ type: "UBICACIONES_PRODUCTOS_BUSQUEDA_REQUEST", page: page, busqueda: busqueda, campo: campo, orden: orden, nombreAlmacen:nombreAlmacen });
        },
        onEstadoUbicacionProducto: (idUbicacionProducto, page) => {
            dispatch({ type: "ESTADO_UBICACION_PRODUCTO_REQUEST", idUbicacionProducto: idUbicacionProducto, page: page});
        },
        onModalNuevaUbicacionProducto: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVA_UBICACION_PRODUCTO", showModalNuevaUbicacionProducto: true, page: page })
        },
        onModalEditarUbicacionProducto: (ubicacionProductoSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_UBICACION_PRODUCTO", showModalEditarUbicacionProducto: true, ubicacionProductoSeleccionado:ubicacionProductoSeleccionado, page:page});
        },
        onGetAlmacenes: () => {
            dispatch({ type: 'ALMACENES_ACTIVOS_BUSQUEDA_REQUEST' });
        },
        onExport: (order, field, idSubFamilia) => {
            dispatch({ type: "EXPORTAR_UBICACIONES_PRODUCTOS_EXCEL_REQUEST", order, field, idSubFamilia });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoUbicacionesProductos);