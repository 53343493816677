import React, { Component } from 'react';
import { Form, Button, Divider, Row, Col, Spin, Select, TimePicker, Tooltip } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { connect } from "react-redux";

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class TurnoPersonal extends Component {
    formRef = React.createRef();
    state={
        tipoHorario: '',
    }
    render() {
        const { fetchingNuevoPersonal, formularioPersonal, busqueda, orden, page, onNuevoPersonal, onListaProveedoresFiltroLimpiar,
            onVistaNuevoPersonal, limpiarFormulario, onLimpiarFormulario, onListaServiciosByProveedorLimpiar,
        } = this.props;

        if (limpiarFormulario && limpiarFormulario === 'crearPersonal') {
            this.formRef.current.resetFields();
            this.setState({
                botonEditar: false,
            });
            onLimpiarFormulario();
            onListaServiciosByProveedorLimpiar();
            onListaProveedoresFiltroLimpiar();
            onVistaNuevoPersonal(1, null)
        }

        const onFinish = formulario => {
            console.log('Success:', formulario);
            formulario = Object.assign(formulario, formularioPersonal)
            const horarioEntrada = formulario['horarioEntrada'];
            const horarioSalida = formulario['horarioSalida'];
            
            if(horarioEntrada!==undefined){
                formulario.horarioEntrada = horarioEntrada.format('HH:mm:ss');
                formulario.horarioSalida = horarioSalida.format('HH:mm:ss');
                console.log('formulario');
                console.log(formulario);
                onNuevoPersonal(formulario, page, busqueda, orden );
            }
            else{
                formulario.horarioEntrada = null;
                formulario.horarioSalida = null;
                onNuevoPersonal(formulario, page, busqueda, orden );
            }
        };
        

        const onTipoHorario = (tipoHorario) => {
            this.setState({
                tipoHorario
            });
        }
        const onRegresar = () => {
            onVistaNuevoPersonal(1, formularioPersonal)
        }
        return (
            <Spin spinning={fetchingNuevoPersonal}>
                <Col span={5}>
                    <Button className="btnFormato" style={{width: '100%'}} onClick={onRegresar}><ArrowLeftOutlined /> Paso Anterior</Button>
                </Col>
                <Divider>Turno y Horario</Divider>
                <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                >
                    <Row justify='space-around'>
                        <Col span={11}>
                            <Form.Item
                                label="Días laborales"
                                name="diasLaborales"
                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona los días laborales"
                                >
                                    <Option key='1'>Domingo</Option>
                                    <Option key='2'>Lunes</Option>
                                    <Option key='3'>Martes</Option>
                                    <Option key='4'>Miércoles</Option>
                                    <Option key='5'>Jueves</Option>
                                    <Option key='6'>Viernes</Option>
                                    <Option key='7'>Sábado</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item
                                label="Tipo de turno"
                                name="tipoHorario"
                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                            >
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona el tipo de horario"
                                    onChange={onTipoHorario}
                                >
                                    <Option key='Corrido'>Corrido</Option>
                                    <Option key='Libre'>Libre</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {this.state.tipoHorario === 'Corrido' ?(
                        <Col span={11}>
                            <Row justify='space-between'>
                                <Col span={11}>
                                    <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                        <Form.Item
                                            label="Horario entrada"
                                            name="horarioEntrada"
                                            rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                            <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de entrada"]} style={{width: '100%'}}/>
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={11}>
                                    <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                        <Form.Item
                                            label="Horario salida"
                                            name="horarioSalida"
                                            rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                            <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de salida"]} style={{width: '100%'}}/>
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Col>
                        ):false}
                        <Col span={11} className='marginBoton'>
                            <br/>
                            <Button className="btnFiltrar" disabled={this.state.botonEditar} style={{width: '100%'}} htmlType="submit">Crear Personal</Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingNuevoPersonal: state.RecursosHumanosReducer.fetchingNuevoPersonal,
        page: state.RecursosHumanosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        formularioPersonal: state.RecursosHumanosReducer.formularioPersonal,
        busqueda: state.RecursosHumanosReducer.busqueda, 
        orden: state.RecursosHumanosReducer.orden,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onNuevoPersonal: ( formulario, page, busqueda, orden ) => {
            dispatch({ type: "NUEVO_PERSONAL_REQUEST" , formulario, page, busqueda, orden });
        },
        onVistaNuevoPersonal: (vistaNuevoPersonal, formularioPersonal) => {
            dispatch({ type: 'VISTA_NUEVO_PERSONAL', vistaNuevoPersonal, formularioPersonal})
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onListaServiciosByProveedorLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_BY_PROVEEDOR_SUCCESS", listaServiciosByProveedor: null })
        },
        onListaProveedoresFiltroLimpiar: () => {
            dispatch({ type: "LISTA_PROVEEDORES_PARAM_SUCCESS", listaParamProveedores: []})
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TurnoPersonal);