import React, {Component} from 'react'
import { Form, InputNumber, Button, Col, Spin, Modal, Select, Row, DatePicker, Input } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalNuevoNivelSeveridad extends Component {
    formRef = React.createRef();
    render() { 
        const {
            onNuevoNivelSeveridad, onShowModalNuevoNivelSeveridad, showModalNuevoNivelSeveridad,
            fetchingNuevoNivelSeveridad, listaInfoProyecto, page, limpiarFormulario, busqueda,
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            const rangeValue = formulario['rangoVigencia'];
            formulario.rangoVigencia = rangeValue[0].format('YYYY-MM-DD') + "," + rangeValue[1].format('YYYY-MM-DD');
            formulario.fechaInicioVigencia = formulario.rangoVigencia.split(',')[0];
            onNuevoNivelSeveridad(formulario, page, busqueda);
        };

        if (limpiarFormulario && limpiarFormulario === 'crearNivelSeveridad') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }
        const handleCancel = () => {
            onShowModalNuevoNivelSeveridad();
            this.formRef.current.resetFields();
        }
        const disabledRange = fecha => {
            return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicio;
        }

        const changeDecimales = (value) => {
            let valueDecimal = parseInt(value);
            if(isNaN(valueDecimal)){
            valueDecimal = 1;
            }
            this.formRef.current.setFieldsValue({'tiempoRespuesta': valueDecimal});
        }

        return (
            <Modal
                visible={showModalNuevoNivelSeveridad}
                title='Nuevo Nivel de Severidad'
                onCancel={handleCancel}
                footer={false}
            >
                <Spin spinning={fetchingNuevoNivelSeveridad}>
                    <Form {...layout}
                        name="formulario"
                        onFinish={onFinish}
                        ref={this.formRef}
                    >
                        <Row justify='space-around'>

                            <Col span={24}>
                                <Form.Item
                                    label="Severidad"
                                    name="severidad"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={100} placeholder='Ingresa la severidad'/> 
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Descripción de severidad"
                                    name="descripcion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={200} placeholder='Ingresa la descripción de la severdidad'/> 
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Tiempo de respuesta"
                                    name="tiempoRespuesta"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber min={1} onChange={changeDecimales} placeholder='Ingresa el tiempo de respuesta'/> 
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    label="Unidad de medida"
                                    name="idUnidadMedida"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona la unidad de medida"
                                        showSearch
                                    >
                                        <Option key={'Minutos'}>{'Minutos'}</Option>
                                        <Option key={'Horas'}>{'Horas'}</Option>
                                        <Option key={'Días'}>{'Días'}</Option>
                                        <Option key={'Años'}>{'Años'}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    label="Fechas vigencias del nivel de severidad"
                                    name="rangoVigencia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                >
                                    <RangePicker
                                        format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio","Fecha fin"]}
                                        disabledDate={disabledRange}
                                        defaultValue={[, moment(listaInfoProyecto.fechaVigencia, 'DD-MM-YYYY')]}  
                                        disabled={[false, true]} 
                                        allowClear={false}
                                        />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Crear Nivel de Severidad</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}
 const mapStateToProps = state => {
     return {
        showModalNuevoNivelSeveridad: state.MecanismosDePagosReducer.showModalNuevoNivelSeveridad,
        fetchingNuevoNivelSeveridad: state.MecanismosDePagosReducer.fetchingNuevoNivelSeveridad,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        page: state.MecanismosDePagosReducer.page,
        busqueda: state.MecanismosDePagosReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
    }
}

 const mapDispatchToProps = dispatch => {
     return {
            onShowModalNuevoNivelSeveridad: () => {
                dispatch({ type: "SHOW_MODAL_NUEVO_NIVEL_SEVERIDAD", showModalNuevoNivelSeveridad: false })
            },
            onNuevoNivelSeveridad: (formulario, page, busqueda) => {
                dispatch({ type: "NUEVO_NIVEL_SEVERIDAD_REQUEST", formulario: formulario, page, busqueda })
            },
            onLimpiarFormulario: () => {
                dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
            },
     }
 }

 export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoNivelSeveridad);