import React, { Component } from 'react';
import { Form, Button, Row, Col, Spin, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
}

class ArchivoConvenioUnidad extends Component {
    formRef = React.createRef();
    state = {
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
    }
    render() {
        const { onEditarUnidad, UnidadesFuncionalesSeleccionado, fetchingEditarUnidadesFuncionales, formularioUnidad,
            page, limpiarFormulario, onLimpiarFormulario, busqueda, bloqueado, editedGeometry, cambiarVistaModalUnidad,
            showModalEditarUnidadesFuncionales, onNuevoUnidadFuncional, fetchingNuevoUnidadesFuncionales,
        } = this.props;

        if(cambiarVistaModalUnidad === 3 && limpiarFormulario && limpiarFormulario === 'editarUnidad'){
            console.log('entró a limpiar');
            this.formRef.current.resetFields();
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
            onLimpiarFormulario();
        }
        const onFinish = formularioPDF => {
            console.log('formularioPDF');
            console.log(formularioPDF);
            if(showModalEditarUnidadesFuncionales){
                onEditarUnidad(formularioUnidad, page, busqueda, bloqueado, formularioPDF, UnidadesFuncionalesSeleccionado, editedGeometry);
            }
            else{
                onNuevoUnidadFuncional(formularioUnidad, editedGeometry, page, busqueda, bloqueado, formularioPDF);
            }
        };
        

        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                })
            }
            var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
            if (info.file.status === 'done') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }
        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };
        return (
            <Spin spinning={fetchingEditarUnidadesFuncionales || fetchingNuevoUnidadesFuncionales}>
                <Form {...layout}
                name="formulario"
                onFinish={onFinish}
                ref={this.formRef}
                >
                    <Row justify='space-around'>
                        <Col span={11}>
                            <Form.Item
                                label="Adjunta la evidencia del convenio en PDF"
                                name="pdf"
                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                            >
                                <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                    {this.state.filePDF === false ? (
                                    <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                        <UploadOutlined /> Click para subir evidencia del convenio
                                    </Button>
                                    ):false}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={11} className='marginBoton'>
                            <br/>
                            <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">{showModalEditarUnidadesFuncionales ? 'Editar Área Funcional' : 'Crear Área Funcional'}</Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
	return {
        formularioUnidad: state.CatalogosGeneralesReducer.formularioUnidad,
        fetchingEditarUnidadesFuncionales: state.CatalogosGeneralesReducer.fetchingEditarUnidadesFuncionales,
        fetchingNuevoUnidadesFuncionales: state.CatalogosGeneralesReducer.fetchingNuevoUnidadesFuncionales,
        UnidadesFuncionalesSeleccionado: state.CatalogosGeneralesReducer.UnidadesFuncionalesSeleccionado,
        editedGeometry: state.CatalogosGeneralesReducer.editedGeometry,
        page: state.CatalogosGeneralesReducer.page,
        busqueda: state.CatalogosGeneralesReducer.busqueda,
        bloqueado: state.CatalogosGeneralesReducer.bloqueado,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        cambiarVistaModalUnidad: state.ConfiguracionYSeguridadReducer.cambiarVistaModalUnidad,
        showModalEditarUnidadesFuncionales: state.CatalogosGeneralesReducer.showModalEditarUnidadesFuncionales,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onEditarUnidad: ( formulario, page, busqueda, bloqueado, formularioPDF, UnidadesFuncionalesSeleccionado, editedGeometry ) => {
            dispatch({ type: "EDITAR_UNIDADESFUNCIONALES_REQUEST" , formulario, page, busqueda, bloqueado, formularioPDF, UnidadesFuncionalesSeleccionado, editedGeometry });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onNuevoUnidadFuncional: ( formulario, geometry, page, busqueda, bloqueado, formularioPDF ) => {
            dispatch({ type: "NUEVO_UNIDADESFUNCIONALES_REQUEST" , formulario, geometry,  page, busqueda, bloqueado, formularioPDF });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchivoConvenioUnidad);