export const CAMBIAR_CATALOGO_SERVICIOS_PLANIFICADOS = 'CAMBIAR_CATALOGO_SERVICIOS_PLANIFICADOS';

// Actions ordenes para Rutina y Mantenimiento
export const LISTA_RUTINAS_PROGRAMADAS_REPROGRAMADAS_REQUEST = "LISTA_RUTINAS_PROGRAMADAS_REPROGRAMADAS_REQUEST";
export const LISTA_RUTINAS_PROGRAMADAS_REPROGRAMADAS_SUCCESS = "LISTA_RUTINAS_PROGRAMADAS_REPROGRAMADAS_SUCCESS";
export const LISTA_RUTINAS_PROGRAMADAS_REPROGRAMADAS_FAILURE = "LISTA_RUTINAS_PROGRAMADAS_REPROGRAMADAS_FAILURE";

export const LISTA_SERVICIOS_RUTINAS_ACTIVOS_REQUEST = 'LISTA_SERVICIOS_RUTINAS_ACTIVOS_REQUEST';
export const LISTA_SERVICIOS_RUTINAS_ACTIVOS_SUCCESS = 'LISTA_SERVICIOS_RUTINAS_ACTIVOS_SUCCESS';
export const LISTA_SERVICIOS_RUTINAS_ACTIVOS_FAILURE = 'LISTA_SERVICIOS_RUTINAS_ACTIVOS_FAILURE';

export const GENERAR_ORDEN_PROGRAMA_REQUEST = "GENERAR_ORDEN_PROGRAMA_REQUEST";
export const GENERAR_ORDEN_PROGRAMA_SUCCESS = "GENERAR_ORDEN_PROGRAMA_SUCCESS";
export const GENERAR_ORDEN_PROGRAMA_FAILURE = "GENERAR_ORDEN_PROGRAMA_FAILURE";

export const SHOW_MODAL_DETALLE_PROGRAMA = "SHOW_MODAL_DETALLE_PROGRAMA";
export const SHOW_MODAL_GENERAR_ORDEN = "SHOW_MODAL_GENERAR_ORDEN";

export const LISTA_ORDENES_RMP_ACTIVOS_REQUEST = 'LISTA_ORDENES_RMP_ACTIVOS_REQUEST';
export const LISTA_ORDENES_RMP_ACTIVOS_SUCCESS = 'LISTA_ORDENES_RMP_ACTIVOS_SUCCESS';
export const LISTA_ORDENES_RMP_ACTIVOS_FAILURE = 'LISTA_ORDENES_RMP_ACTIVOS_FAILURE';

export const ELIMINAR_TAREA_ORDEN_REQUEST = 'ELIMINAR_TAREA_ORDEN_REQUEST';
export const ELIMINAR_TAREA_ORDEN_SUCCESS = 'ELIMINAR_TAREA_ORDEN_SUCCESS';
export const ELIMINAR_TAREA_ORDEN_FAILURE = 'ELIMINAR_TAREA_ORDEN_FAILURE';

export const REPROGRAMAR_TAREA_ORDEN_REQUEST = 'REPROGRAMAR_TAREA_ORDEN_REQUEST';
export const REPROGRAMAR_TAREA_ORDEN_SUCCESS = 'REPROGRAMAR_TAREA_ORDEN_SUCCESS';
export const REPROGRAMAR_TAREA_ORDEN_FAILURE = 'REPROGRAMAR_TAREA_ORDEN_FAILURE';

export const REPROGRAMAR_TAREA_MULTIPLE_REQUEST = 'REPROGRAMAR_TAREA_MULTIPLE_REQUEST';
export const REPROGRAMAR_TAREA_MULTIPLE_SUCCESS = 'REPROGRAMAR_TAREA_MULTIPLE_SUCCESS';
export const REPROGRAMAR_TAREA_MULTIPLE_FAILURE = 'REPROGRAMAR_TAREA_MULTIPLE_FAILURE';

export const OBTENER_PROGRAMACION_ORDEN_REQUEST = 'OBTENER_PROGRAMACION_ORDEN_REQUEST';
export const OBTENER_PROGRAMACION_ORDEN_SUCCESS = 'OBTENER_PROGRAMACION_ORDEN_SUCCESS';
export const OBTENER_PROGRAMACION_ORDEN_FAILURE = 'OBTENER_PROGRAMACION_ORDEN_FAILURE';

export const SHOW_MODAL_EDITAR_ORDEN = "SHOW_MODAL_EDITAR_ORDEN";
export const SHOW_MODAL_REPROGRAMAR_TAREA_ORDEN = "SHOW_MODAL_REPROGRAMAR_TAREA_ORDEN";

export const DESCARGAR_PDF_MANTENIMIENTO_REQUEST = 'DESCARGAR_PDF_MANTENIMIENTO_REQUEST';
export const DESCARGAR_PDF_MANTENIMIENTO_SUCCESS = 'DESCARGAR_PDF_MANTENIMIENTO_SUCCESS';
export const DESCARGAR_PDF_MANTENIMIENTO_FAILURE = 'DESCARGAR_PDF_MANTENIMIENTO_FAILURE';

export const DESCARGAR_PDF_RUTINA_REQUEST = 'DESCARGAR_PDF_RUTINA_REQUEST';
export const DESCARGAR_PDF_RUTINA_SUCCESS = 'DESCARGAR_PDF_RUTINA_SUCCESS';
export const DESCARGAR_PDF_RUTINA_FAILURE = 'DESCARGAR_PDF_RUTINA_FAILURE';

export const DESCARGAR_PDF_PRUEBA_REQUEST = 'DESCARGAR_PDF_PRUEBA_REQUEST';
export const DESCARGAR_PDF_PRUEBA_SUCCESS = 'DESCARGAR_PDF_PRUEBA_SUCCESS';
export const DESCARGAR_PDF_PRUEBA_FAILURE = 'DESCARGAR_PDF_PRUEBA_FAILURE';

export const SHOW_MODAL_VER_PROGRAMACION_ORDEN = "SHOW_MODAL_VER_PROGRAMACION_ORDEN";


//RUTINAS Y PRUEBAS
export const LISTA_RUTINAS_BUSQUEDA_REQUEST = 'LISTA_RUTINAS_BUSQUEDA_REQUEST';
export const LISTA_RUTINAS_BUSQUEDA_SUCCESS = 'LISTA_RUTINAS_BUSQUEDA_SUCCESS';
export const LISTA_RUTINAS_BUSQUEDA_FAILURE = 'LISTA_RUTINAS_BUSQUEDA_FAILURE';

export const MODAL_DETALLE_RUTINA = 'MODAL_DETALLE_RUTINA';

export const ESTADO_RUTINA_REQUEST = 'ESTADO_RUTINA_REQUEST';
export const ESTADO_RUTINA_SUCCESS = 'ESTADO_RUTINA_SUCCESS';
export const ESTADO_RUTINA_FAILURE = 'ESTADO_RUTINA_FAILURE';

export const SHOW_MODAL_NUEVA_RUTINA = 'SHOW_MODAL_NUEVA_RUTINA';
export const VISTA_NUEVA_RUTINA = 'VISTA_NUEVA_RUTINA';

export const NUEVA_RUTINA_REQUEST = 'NUEVA_RUTINA_REQUEST';
export const NUEVA_RUTINA_SUCCESS = 'NUEVA_RUTINA_SUCCESS';
export const NUEVA_RUTINA_FAILURE = 'NUEVA_RUTINA_FAILURE';

export const SHOW_MODAL_EDITAR_RUTINA = 'SHOW_MODAL_EDITAR_RUTINA';

export const EDITAR_RUTINA_REQUEST = 'EDITAR_RUTINA_REQUEST';
export const EDITAR_RUTINA_SUCCESS = 'EDITAR_RUTINA_SUCCESS';
export const EDITAR_RUTINA_FAILURE = 'EDITAR_RUTINA_FAILURE';

//ASIGNACIONES  SERVICIOS PROGRAMADOS
export const LISTA_ASIGNACIONES_SP_BUSQUEDA_REQUEST = 'LISTA_ASIGNACIONES_SP_BUSQUEDA_REQUEST';
export const LISTA_ASIGNACIONES_SP_BUSQUEDA_SUCCESS = 'LISTA_ASIGNACIONES_SP_BUSQUEDA_SUCCESS';
export const LISTA_ASIGNACIONES_SP_BUSQUEDA_FAILURE = 'LISTA_ASIGNACIONES_SP_BUSQUEDA_FAILURE';

export const LISTA_SP_NO_ASIGNADOS_REQUEST = 'LISTA_SP_NO_ASIGNADOS_REQUEST';
export const LISTA_SP_NO_ASIGNADOS_SUCCESS = 'LISTA_SP_NO_ASIGNADOS_SUCCESS';
export const LISTA_SP_NO_ASIGNADOS_FAILURE = 'LISTA_SP_NO_ASIGNADOS_FAILURE';

export const SHOW_MODAL_DETALLE_ASIGNACIONES_SP = 'SHOW_MODAL_DETALLE_ASIGNACIONES_SP';
export const SHOW_MODAL_DETALLE_RUTINA_SP = 'SHOW_MODAL_DETALLE_RUTINA_SP';
export const SHOW_MODAL_NUEVA_ASIGNACION_SP = 'SHOW_MODAL_NUEVA_ASIGNACION_SP';
export const SHOW_MODAL_EDITAR_ASIGNACION_SP = 'SHOW_MODAL_EDITAR_ASIGNACION_SP';

export const NUEVA_ASIGNACION_SP_REQUEST = 'NUEVA_ASIGNACION_SP_REQUEST';
export const NUEVA_ASIGNACION_SP_SUCCESS = 'NUEVA_ASIGNACION_SP_SUCCESS';
export const NUEVA_ASIGNACION_SP_FAILURE = 'NUEVA_ASIGNACION_SP_FAILURE';

export const EDITAR_ASIGNACION_SP_REQUEST = 'EDITAR_ASIGNACION_SP_REQUEST';
export const EDITAR_ASIGNACION_SP_SUCCESS = 'EDITAR_ASIGNACION_SP_SUCCESS';
export const EDITAR_ASIGNACION_SP_FAILURE = 'EDITAR_ASIGNACION_SP_FAILURE';

// CU - Programas de Rutinas Mantenimientos y Pruebas para Servicios Planificados.
export const SHOW_MODAL_PROGRAMAR_RMP = 'SHOW_MODAL_PROGRAMAR_RMP';
export const SHOW_MODAL_DETALLE_ACTIVIDAD_RMP = 'SHOW_MODAL_DETALLE_ACTIVIDAD_RMP';
export const SHOW_MODAL_DETALLE_UBICACION_RMP = 'SHOW_MODAL_DETALLE_UBICACION_RMP';
export const SHOW_MODAL_ACTUALIZAR_RM = 'SHOW_MODAL_ACTUALIZAR_RM';
export const SHOW_MODAL_FECHAS_RMP = 'SHOW_MODAL_FECHAS_RMP';

export const LISTA_RMP_BUSQUEDA_REQUEST = 'LISTA_RMP_BUSQUEDA_REQUEST';
export const LISTA_RMP_BUSQUEDA_SUCCESS = 'LISTA_RMP_BUSQUEDA_SUCCESS';
export const LISTA_RMP_BUSQUEDA_FAILURE = 'LISTA_RMP_BUSQUEDA_FAILURE';

export const PROGRAMAR_RMP_REQUEST = 'PROGRAMAR_RMP_REQUEST';
export const PROGRAMAR_RMP_SUCCESS = 'PROGRAMAR_RMP_SUCCESS';
export const PROGRAMAR_RMP_FAILURE = 'PROGRAMAR_RMP_FAILURE';

export const EDITAR_RMP_REQUEST = 'EDITAR_RMP_REQUEST';
export const EDITAR_RMP_SUCCESS = 'EDITAR_RMP_SUCCESS';
export const EDITAR_RMP_FAILURE = 'EDITAR_RMP_FAILURE';

//Cancelacion ordenes Servicios Planificadios
export const LISTA_BUSQUEDA_ORDENES_SP_CANCELAR_REQUEST = 'LISTA_BUSQUEDA_ORDENES_SP_CANCELAR_REQUEST';
export const LISTA_BUSQUEDA_ORDENES_SP_CANCELAR_SUCCESS = 'LISTA_BUSQUEDA_ORDENES_SP_CANCELAR_SUCCESS';
export const LISTA_BUSQUEDA_ORDENES_SP_CANCELAR_FAILURE = 'LISTA_BUSQUEDA_ORDENES_SP_CANCELAR_FAILURE';

export const LISTA_DETALLE_CANCELACION_ORDEN_SP_REQUEST = 'LISTA_DETALLE_CANCELACION_ORDEN_SP_REQUEST';
export const LISTA_DETALLE_CANCELACION_ORDEN_SP_SUCCESS = 'LISTA_DETALLE_CANCELACION_ORDEN_SP_SUCCESS';
export const LISTA_DETALLE_CANCELACION_ORDEN_SP_FAILURE = 'LISTA_DETALLE_CANCELACION_ORDEN_SP_FAILURE';

export const CANCELAR_ORDEN_SP_REQUEST = 'CANCELAR_ORDEN_SP_REQUEST';
export const CANCELAR_ORDEN_SP_SUCCESS = 'CANCELAR_ORDEN_SP_SUCCESS';
export const CANCELAR_ORDEN_SP_FAILURE = 'CANCELAR_ORDEN_SP_FAILURE';

export const SHOW_MODAL_CANCELAR_ORDEN_SP = 'SHOW_MODAL_CANCELAR_ORDEN_SP';
export const SHOW_MODAL_DETALLE_CANCELACION_ORDEN_SP = 'SHOW_MODAL_DETALLE_CANCELACION_ORDEN_SP';

//Estatus de Ordenes de Servicios Planificados
export const CAMBIAR_ESTATUS_ORDEN_SP_REQUEST = 'CAMBIAR_ESTATUS_ORDEN_SP_REQUEST';
export const CAMBIAR_ESTATUS_ORDEN_SP_SUCCESS = 'CAMBIAR_ESTATUS_ORDEN_SP_SUCCESS';
export const CAMBIAR_ESTATUS_ORDEN_SP_FAILURE = 'CAMBIAR_ESTATUS_ORDEN_SP_FAILURE';

export const EDITAR_INSUMOS_ESTATUS_REQUEST = 'EDITAR_INSUMOS_ESTATUS_REQUEST';
export const EDITAR_INSUMOS_ESTATUS_SUCCESS = 'EDITAR_INSUMOS_ESTATUS_SUCCESS';
export const EDITAR_INSUMOS_ESTATUS_FAILURE = 'EDITAR_INSUMOS_ESTATUS_FAILURE';

export const EDITAR_PERSONAL_SP_REQUEST = 'EDITAR_PERSONAL_SP_REQUEST';
export const EDITAR_PERSONAL_SP_SUCCESS = 'EDITAR_PERSONAL_SP_SUCCESS';
export const EDITAR_PERSONAL_SP_FAILURE = 'EDITAR_PERSONAL_SP_FAILURE';

export const SHOW_MODAL_DETALLE_ESTATUS_ORDEN_SP_ACTIVIDAD = 'SHOW_MODAL_DETALLE_ESTATUS_ORDEN_SP_ACTIVIDAD';
export const SHOW_MODAL_DETALLE_ESTATUS_ORDEN_SP_PROGRAMA = 'SHOW_MODAL_DETALLE_ESTATUS_ORDEN_SP_PROGRAMA';
export const SHOW_MODAL_CAMBIAR_ESTATUS_OSP = 'SHOW_MODAL_CAMBIAR_ESTATUS_OSP';
export const ACTUALIZAR_LISTA_INSUMOS_ESTATUS = 'ACTUALIZAR_LISTA_INSUMOS_ESTATUS';
export const ACTUALIZAR_LISTA_INSUMOS_SIN_REPETIR = 'ACTUALIZAR_LISTA_INSUMOS_SIN_REPETIR';
export const ACTUALIZAR_LISTA_PERSONAL_ESTATUS = 'ACTUALIZAR_LISTA_PERSONAL_ESTATUS';

//Vista Calendario Servicios Planificados
export const LISTA_BUSQUEDA_VISTA_CALENDARIO_REQUEST = 'LISTA_BUSQUEDA_VISTA_CALENDARIO_REQUEST';
export const LISTA_BUSQUEDA_VISTA_CALENDARIO_SUCCESS = 'LISTA_BUSQUEDA_VISTA_CALENDARIO_SUCCESS';
export const LISTA_BUSQUEDA_VISTA_CALENDARIO_FAILURE = 'LISTA_BUSQUEDA_VISTA_CALENDARIO_FAILURE';

export const REPROGRAMAR_TAREA_VISTA_CALENDARIO_REQUEST = 'REPROGRAMAR_TAREA_VISTA_CALENDARIO_REQUEST';
export const REPROGRAMAR_TAREA_VISTA_CALENDARIO_SUCCESS = 'REPROGRAMAR_TAREA_VISTA_CALENDARIO_SUCCESS';
export const REPROGRAMAR_TAREA_VISTA_CALENDARIO_FAILURE = 'REPROGRAMAR_TAREA_VISTA_CALENDARIO_FAILURE';

export const SHOW_MODAL_REPROGRAMAR_TAREA_VC = 'SHOW_MODAL_REPROGRAMAR_TAREA_VC';
export const SHOW_MODAL_TABLA_TAREAS_VC = 'SHOW_MODAL_TABLA_TAREAS_VC';

//Reporte Servicios Planificados
export const LISTA_BUSQUEDA_REPORTE_SP_REQUEST = 'LISTA_BUSQUEDA_REPORTE_SP_REQUEST';
export const LISTA_BUSQUEDA_REPORTE_SP_SUCCESS = 'LISTA_BUSQUEDA_REPORTE_SP_SUCCESS';
export const LISTA_BUSQUEDA_REPORTE_SP_FAILURE = 'LISTA_BUSQUEDA_REPORTE_SP_FAILURE';

export const LISTA_BUSQUEDA_REPORTE_SP_DESCARGA_REQUEST = 'LISTA_BUSQUEDA_REPORTE_SP_DESCARGA_REQUEST';
export const LISTA_BUSQUEDA_REPORTE_SP_DESCARGA_SUCCESS = 'LISTA_BUSQUEDA_REPORTE_SP_DESCARGA_SUCCESS';
export const LISTA_BUSQUEDA_REPORTE_SP_DESCARGA_FAILURE = 'LISTA_BUSQUEDA_REPORTE_SP_DESCARGA_FAILURE';

export const DESCARGAR_PDF_REPORTE_SP_REQUEST = 'DESCARGAR_PDF_REPORTE_SP_REQUEST';
export const DESCARGAR_PDF_REPORTE_SP_SUCCESS = 'DESCARGAR_PDF_REPORTE_SP_SUCCESS';
export const DESCARGAR_PDF_REPORTE_SP_FAILURE = 'DESCARGAR_PDF_REPORTE_SP_FAILURE';

export const DESCARGAR_EXCEL_REPORTE_SP_REQUEST = 'DESCARGAR_EXCEL_REPORTE_SP_REQUEST';
export const DESCARGAR_EXCEL_REPORTE_SP_SUCCESS = 'DESCARGAR_EXCEL_REPORTE_SP_SUCCESS';
export const DESCARGAR_EXCEL_REPORTE_SP_FAILURE = 'DESCARGAR_EXCEL_REPORTE_SP_FAILURE';

//Carga de Servicios Planificados
export const GET_ARCHIVOS_ESPERA_SP_REQUEST = 'GET_ARCHIVOS_ESPERA_SP_REQUEST';
export const GET_ARCHIVOS_ESPERA_SP_SUCCESS = 'GET_ARCHIVOS_ESPERA_SUCCESS';
export const GET_ARCHIVOS_ESPERA_SP_FAILURE = 'GET_ARCHIVOS_ESPERA_SP_FAILURE';

export const SUBIR_ARCHIVO_SP_REQUEST = 'SUBIR_ARCHIVO_SP_REQUEST';
export const SUBIR_ARCHIVO_SP_SUCCESS = 'SUBIR_ARCHIVO_SP_SUCCESS';
export const SUBIR_ARCHIVO_SP_FAILURE = 'SUBIR_ARCHIVO_SP_FAILURE';

export const ELIMINAR_ARCHIVO_SP_REQUEST = 'ELIMINAR_ARCHIVO_SP_REQUEST';
export const ELIMINAR_ARCHIVO_SP_SUCCESS = 'ELIMINAR_ARCHIVO_SP_SUCCESS';
export const ELIMINAR_ARCHIVO_SP_FAILURE = 'ELIMINAR_ARCHIVO_SP_FAILURE';

export const PROCESAR_ARCHIVO_SP_REQUEST = 'PROCESAR_ARCHIVO_SP_REQUEST';
export const PROCESAR_ARCHIVO_SP_SUCCESS = 'PROCESAR_ARCHIVO_SP_SUCCESS';
export const PROCESAR_ARCHIVO_SP_FAILURE = 'PROCESAR_ARCHIVO_SP_FAILURE';

export const EXPORTAR_ERRORES_ARCHIVO_SP_REQUEST = 'EXPORTAR_ERRORES_ARCHIVO_SP_REQUEST';
export const EXPORTAR_ERRORES_ARCHIVO_SP_SUCCESS = 'EXPORTAR_ERRORES_ARCHIVO_SP_SUCCESS';
export const EXPORTAR_ERRORES_ARCHIVO_SP_FAILURE = 'EXPORTAR_ERRORES_ARCHIVO_SP_FAILURE';

//CALENDARIO DE PROGRAMACIÓN
export const CALENDARIO_PROGRAMACION_REQUEST = 'CALENDARIO_PROGRAMACION_REQUEST';
export const CALENDARIO_PROGRAMACION_SUCCESS = 'CALENDARIO_PROGRAMACION_SUCCESS';
export const CALENDARIO_PROGRAMACION_FAILURE = 'CALENDARIO_PROGRAMACION_FAILURE';

export const SHOW_MODAL_CALENDARIO_DETALLE = 'SHOW_MODAL_CALENDARIO_DETALLE';
export const SHOW_MODAL_REPROGRAMAR_DRAG_DROP = 'SHOW_MODAL_REPROGRAMAR_DRAG_DROP';

export const CALENDARIO_DETALLE_REQUEST = 'CALENDARIO_DETALLE_REQUEST';
export const CALENDARIO_DETALLE_SUCCESS = 'CALENDARIO_DETALLE_SUCCESS';
export const CALENDARIO_DETALLE_FAILURE = 'CALENDARIO_DETALLE_FAILURE';

//REPORTE DINÁMICO SP
export const LISTA_REGISTROS_SP_REQUEST = 'LISTA_REGISTROS_SP_REQUEST';
export const LISTA_REGISTROS_SP_SUCCESS = 'LISTA_REGISTROS_SP_SUCCESS';
export const LISTA_REGISTROS_SP_FAILURE = 'LISTA_REGISTROS_SP_FAILURE';

export const ESTADO_REGISTRO_REPORTE_SP_REQUEST = 'ESTADO_REGISTRO_REPORTE_SP_REQUEST';
export const ESTADO_REGISTRO_REPORTE_SP_SUCCESS = 'ESTADO_REGISTRO_REPORTE_SP_SUCCESS';
export const ESTADO_REGISTRO_REPORTE_SP_FAILURE = 'ESTADO_REGISTRO_REPORTE_SP_FAILURE';

export const LISTA_REGISTROS_SP_ESPECIAL_REQUEST = 'LISTA_REGISTROS_SP_ESPECIAL_REQUEST';
export const LISTA_REGISTROS_SP_ESPECIAL_SUCCESS = 'LISTA_REGISTROS_SP_ESPECIAL_SUCCESS';
export const LISTA_REGISTROS_SP_ESPECIAL_FAILURE = 'LISTA_REGISTROS_SP_ESPECIAL_FAILURE';

export const LISTA_REPORTE_DINAMICO_SP_REQUEST = 'LISTA_REPORTE_DINAMICO_SP_REQUEST';
export const LISTA_REPORTE_DINAMICO_SP_SUCCESS = 'LISTA_REPORTE_DINAMICO_SP_SUCCESS';
export const LISTA_REPORTE_DINAMICO_SP_FAILURE = 'LISTA_REPORTE_DINAMICO_SP_FAILURE';

export const EXCEL_REPORTE_DINAMICO_SP_REQUEST = 'EXCEL_REPORTE_DINAMICO_SP_REQUEST';
export const EXCEL_REPORTE_DINAMICO_SP_SUCCESS = 'EXCEL_REPORTE_DINAMICO_SP_SUCCESS';
export const EXCEL_REPORTE_DINAMICO_SP_FAILURE = 'EXCEL_REPORTE_DINAMICO_SP_FAILURE';

export const PDF_REPORTE_DINAMICO_SP_REQUEST = 'PDF_REPORTE_DINAMICO_SP_REQUEST';
export const PDF_REPORTE_DINAMICO_SP_SUCCESS = 'PDF_REPORTE_DINAMICO_SP_SUCCESS';
export const PDF_REPORTE_DINAMICO_SP_FAILURE = 'PDF_REPORTE_DINAMICO_SP_FAILURE';

export const EXCEL_REPORTE_DINAMICO_AGRUPADO_SP_REQUEST = 'EXCEL_REPORTE_DINAMICO_AGRUPADO_SP_REQUEST';
export const EXCEL_REPORTE_DINAMICO_AGRUPADO_SP_SUCCESS = 'EXCEL_REPORTE_DINAMICO_AGRUPADO_SP_SUCCESS';
export const EXCEL_REPORTE_DINAMICO_AGRUPADO_SP_FAILURE = 'EXCEL_REPORTE_DINAMICO_AGRUPADO_SP_FAILURE';

export const PDF_REPORTE_DINAMICO_AGRUPADO_SP_REQUEST = 'PDF_REPORTE_DINAMICO_AGRUPADO_SP_REQUEST';
export const PDF_REPORTE_DINAMICO_AGRUPADO_SP_SUCCESS = 'PDF_REPORTE_DINAMICO_AGRUPADO_SP_SUCCESS';
export const PDF_REPORTE_DINAMICO_AGRUPADO_SP_FAILURE = 'PDF_REPORTE_DINAMICO_AGRUPADO_SP_FAILURE';

export const LISTA_REPORTE_DINAMICO_AGRUPADO_SP_REQUEST = 'LISTA_REPORTE_DINAMICO_AGRUPADO_SP_REQUEST';
export const LISTA_REPORTE_DINAMICO_AGRUPADO_SP_SUCCESS = 'LISTA_REPORTE_DINAMICO_AGRUPADO_SP_SUCCESS';
export const LISTA_REPORTE_DINAMICO_AGRUPADO_SP_FAILURE = 'LISTA_REPORTE_DINAMICO_AGRUPADO_SP_FAILURE';


export const MODAL_CREAR_REGISTRO_SP = 'MODAL_CREAR_REGISTRO_SP';

export const CREATE_REGISTROS_SP_REQUEST = 'CREATE_REGISTROS_SP_REQUEST';
export const CREATE_REGISTROS_SP_SUCCESS = 'CREATE_REGISTROS_SP_SUCCESS';
export const CREATE_REGISTROS_SP_FAILURE = 'CREATE_REGISTROS_SP_FAILURE';

export const MODAL_EDITAR_REGISTRO_SP = 'MODAL_EDITAR_REGISTRO_SP';

export const EDITAR_REGISTROS_SP_REQUEST = 'EDITAR_REGISTROS_SP_REQUEST';
export const EDITAR_REGISTROS_SP_SUCCESS = 'EDITAR_REGISTROS_SP_SUCCESS';
export const EDITAR_REGISTROS_SP_FAILURE = 'EDITAR_REGISTROS_SP_FAILURE';