import React, { Component } from 'react';
import { Form, Input, Button, Col, Spin, Modal, Select, InputNumber } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';

const {Option} = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalNuevaCategoriaDeFalla extends Component {
    formRef = React.createRef();

    componentDidMount() {
        this.props.onGetUnidadesdeMedicion(1); //Filtrar request por categoría
    }
    render() {
        const { fetchingNuevaCategoriaDeFalla, showModalNuevaCategoriaDeFalla, onShowModalNuevaFallaDeDisponibilidad,
            onNuevaFallaDeDisponibilidad, unidadesMediPorCategoria, page, limpiarFormulario, fetchingUnidadesMediPorCategoria} = this.props

        const onFinish = formulario => {
            console.log('Success:' , formulario);
            onNuevaFallaDeDisponibilidad(formulario, page);
        }

        if (limpiarFormulario && limpiarFormulario=== 'crearCategoriaFalla') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        const onFinishFailed = errorInfo => {
            console.log('Failed: ', errorInfo);
        };

        const handleCancel = () => {
            onShowModalNuevaFallaDeDisponibilidad();
            this.formRef.current.resetFields();
        }
        return (
            <Modal
                visible={showModalNuevaCategoriaDeFalla}
                title='Nueva Categoría de Tiempo de Rehabilitación'
                onCancel={handleCancel}
                footer={false}
            >
                <Spin spinning={fetchingNuevaCategoriaDeFalla}>
                    <Form {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        
                        ref={this.formRef}
                    >   
                        <Form.Item
                            label="Categoría"
                            name="categoria"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <InputNumber min={1} max={100} placeholder='Categoría'
                            title="Categoría debe ser número entero" pattern="^[0-9]+$" />
                        </Form.Item>
                        <Form.Item 
                            label="Unidad de medición"
                            name="idUnidadMedida"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Select 
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Selecciona la unidad de medición"
                            loading={fetchingUnidadesMediPorCategoria}
                            >
                                {unidadesMediPorCategoria.map(option => <Option key={option.idUnidadMedida}>{option.unidadMedida}</Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Tiempo de rehabilitación"
                            name="tiempoRehabilitacion"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <InputNumber min={1} max={100} placeholder='Tiempo de rehabilitación'
                            title="Tiempo de rehabilitación debe ser número entero" pattern="^[0-9]+$" />
                        </Form.Item>
                        <Col span={12}>
                            <Button className="btnFiltrar" htmlType="submit">Crear Categoría de Tiempo de Rehabilitación</Button>
                        </Col>
                    </Form>
                </Spin>

            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingNuevaCategoriaDeFalla: state.CatalogosGeneralesReducer.fetchingNuevaCategoriaDeFalla,
        showModalNuevaCategoriaDeFalla: state.CatalogosGeneralesReducer.showModalNuevaCategoriaDeFalla,
        page: state.CatalogosGeneralesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        unidadesMediPorCategoria: state.AlmacenesReducer.unidadesMediPorCategoria,
        fetchingUnidadesMediPorCategoria: state.AlmacenesReducer.fetchingUnidadesMediPorCategoria,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevaFallaDeDisponibilidad: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVA_CATEGORIA_FALLA', showModalNuevaCategoriaDeFalla: false });
        },
        onNuevaFallaDeDisponibilidad: (formulario, page) => {
            dispatch( { type: 'NUEVA_CATEGORIA_FALLA_REQUEST', formulario: formulario, page:page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        },
        onGetUnidadesdeMedicion: (idCategoriaUnidadMedida) => {    //Filtrar request por categoría
            dispatch({ type: "UNIDADES_MEDI_POR_CATEGORIA_REQUEST", idCategoriaUnidadMedida:idCategoriaUnidadMedida});
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevaCategoriaDeFalla);