import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import { Form, Button, Row, Col, Spin, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const ArchivoEditarCategoria = props => {
    let formRef = React.createRef();
    const [state, setState] = useState({filePDF:false, botonPDF: false, botonEditar: false});
    
    const {formularioNivelTiempoRespuesta, fetchingEditarNivelTiempoRespuesta, nivelTiempoRespuestaSeleccionado, page, busqueda, listaFiltradaEditadosNTR,
        onEditarNivelTiempoR, onLimpiarFormulario} = props;

    useEffect(() => {
        formRef.current.resetFields();
        setState({filePDF:false, botonPDF:false});
        onLimpiarFormulario();
    }, [onLimpiarFormulario]);

    const onFinish = formularioPDF => {
        console.log(formularioPDF);
        onEditarNivelTiempoR(formularioNivelTiempoRespuesta, page, busqueda, formularioPDF, nivelTiempoRespuestaSeleccionado, listaFiltradaEditadosNTR);
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const onChangePDF = (info) => {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        else{
            setState({
                botonPDF: true,
                botonEditar: true,
                filePDF:false
            })
        }
        var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
        if (info.file.status === 'done') {
            if(!reGex.test(info.file.name)){
                message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                formRef.current.resetFields(['pdf']);
                setState({
                    botonPDF: false,
                    botonEditar: false,
                    filePDF:false
                });
            }
            else{
                message.success(`${info.file.name} se cargó de forma correcta`);
                setState({
                    botonPDF: false,
                    botonEditar: false,
                    filePDF: true
                });
            }
        } else if (info.file.status === 'error') {
            if(!reGex.test(info.file.name)){
                message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                formRef.current.resetFields(['pdf']);
                setState({
                    botonPDF: false,
                    botonEditar: false,
                    filePDF:false
                });
            }
            else{
                message.error(`${info.file.name} se cargó de forma incorrecta`);
                setState({
                    botonPDF: false,
                    botonEditar: false,
                    filePDF: true
                });
            }
        }
    }
    const onRemovePDF = () => {
        setState({
            filePDF: false,
            botonPDF: false,
        });
    }
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };
    
    return (
        <>
            <Spin spinning={fetchingEditarNivelTiempoRespuesta}>
                <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={formRef}
                >
                    <Row justify='space-around'>
                        <Col span={11}>
                            <Form.Item
                                label="Archivo PDF"
                                name="pdf"
                                rules={[{ required: true, message: 'Favor de llenar el campo.' }]}
                            >
                                <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                    {state.filePDF === false ? (
                                        <Button disabled={state.botonPDF} className='btnCargaArchivos'>
                                            <UploadOutlined /> Click para subir convenio PDF
                                        </Button>
                                    ) : false}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={11} className='marginBoton'>
                            <br />
                            <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Editar Nivel Tiempo Respuesta</Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </>
    )
}

const mapStateToProps = state => {
    return {
        formularioNivelTiempoRespuesta: state.MecanismosDePagosReducer.formularioNivelTiempoRespuesta,
        fetchingEditarNivelTiempoRespuesta: state.MecanismosDePagosReducer.fetchingEditarNivelTiempoRespuesta,
        nivelTiempoRespuestaSeleccionado: state.MecanismosDePagosReducer.nivelTiempoRespuestaSeleccionado,
        page: state.MecanismosDePagosReducer.page,
        busqueda: state.MecanismosDePagosReducer.busqueda,
        listaFiltradaEditadosNTR: state.MecanismosDePagosReducer.listaFiltradaEditadosNTR,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditarNivelTiempoR: (formularioNivelTiempoRespuesta, page, busqueda, formularioPDF, nivelTiempoRespuestaSeleccionado, listaFiltradaEditadosNTR) => {
            dispatch( { type: 'EDITAR_NIVEL_TIEMPO_RESPUESTA_REQUEST', formularioNivelTiempoRespuesta, page, busqueda, formularioPDF, nivelTiempoRespuestaSeleccionado, listaFiltradaEditadosNTR });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ArchivoEditarCategoria);