import React, { Component } from 'react';
import {Row, Col, Modal, List} from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';

class ModalDetalleAlerta extends Component {
    formRef = React.createRef();

    render() {
        const { onShowModalDetalleAlerta, showModalDetalleAlerta, alertaSeleccionado} = this.props;

        let data=[]
        if (alertaSeleccionado){            
            data = [
                {title:"Días para alerta", data: alertaSeleccionado.diasAlerta},
                {title:"Hora alerta",data: alertaSeleccionado.horaAlerta},
                {title:"Fecha inicio",data: alertaSeleccionado.fechaInicio.split('T')[0].split('-').reverse().join('-')},
                {title:"Fecha fin",data: alertaSeleccionado.fechaFin.split('T')[0].split('-').reverse().join('-')},
                {title:"Periodicidad",data: alertaSeleccionado.periodicidad},
                {title:"Usuarios para alerta",data: alertaSeleccionado.usuariosAlerta},
            ];
        }
        const handleCancel = () => {
            onShowModalDetalleAlerta();
        }
        
        return (
            <Modal
                visible={showModalDetalleAlerta}
                title='Detalle de la Alerta'
                onCancel={handleCancel}
                footer={false}
            >
                {alertaSeleccionado ? (
                    <Row justify='space-around'>
                        <Col span={24}>
                            <List
                            className="listaCatalogos"
                            bordered
                            dataSource={data}
                            renderItem={item => (
                               <List.Item className='listaHover' className='listaHover'>
                                        <Col span={11}>
                                            <strong>{item.title}</strong>  
                                        </Col>
                                        <Col span={11}>
                                            {item.data ? item.data : '-'} 
                                        </Col>
                                </List.Item>
                            )}
                            />
                        </Col>
                    </Row>
                ) : false}
                
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        showModalDetalleAlerta: state.ConfiguracionYSeguridadReducer.showModalDetalleAlerta,
        alertaSeleccionado: state.ConfiguracionYSeguridadReducer.alertaSeleccionado,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalDetalleAlerta: () => {
            dispatch({ type: 'SHOW_MODAL_DETALLE_ALERTA', showModalDetalleAlerta: false });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetalleAlerta);