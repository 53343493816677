import * as RMDPActions from '../actions/RMDPActions';
import * as GestionDeCalidadActions from "../actions/GestionDeCalidadActions";
import {
     EDITAR_INFORMACION_TERRENO_REQUEST,
     EXPORTAR_FOLIOS_ERRORES_OMISIONES_EXCEL_REQUEST,
     EXPORTAR_FOLIOS_ERRORES_OMISIONES_REQUEST,
     LISTA_GRUPOS_SERVICIOS_REQUEST,
     MODAL_EDITOR_TEXTO,
     OBTENER_UBICACIONES_INFRAESTRUCTURA_REQUEST
} from "../actions/RMDPActions";
import {exportarFoliosErroresUOmisionesPDFCall} from "../../services/RMDPServices";

const initialState = {
     page: 1,
     busqueda: '',
     mesSeleccionadoRMDP: null,
     anioSeleccionadoRMDP: null,
     mesTexto: '',

     listaAniosRMDP: [],
     fetchinglistaAniosRMDP: false,
     listaMesesRMDP: [],
     fetchingListaMesesRMDP: false,

     fetchingGetRMDP: false,
     RMDPObject: null,

     fetchingGetReporteRMDP: false,

     // ACREDITAMIENTO DE LA PRESTACIÓN DE LOS SERVICIOS DURANTE EL PERIODO DE CONFORMIDAD CON LO DISPUESTO EN EL ANEXO 12 DEL CONTRATO

     // Información del Terreno
     fetchingCrearInformacionTerreno: false,
     fetchingEditarInformacionTerreno: false,

     fetchingObtenerArchivoMesAnteriorTerreno: false,
     archivoMesAnteriorTerreno: null,

     fetchingObtenerArchivoMesActualTerreno: false,
     archivoMesActualTerreno: null,

     // Infraestructura
     fetchingCrearInfraestructura: false,
     fetchingEditarInfraestructura: false,

     fetchingObtenerArchivoMesAnteriorInfraestructura: false,
     archivoMesAnteriorInfraestructura: null,

     fetchingObtenerArchivoMesActualInfraestructura: false,
     archivoMesActualInfraestructura: null,

     fetchingObtenerUbicacionesInfraestructura: false,
     ubicacionesInfraestructura: [],
     ubicacionesInfraestructuraCantidad: null,

     // Equipo
     baseDataEquipo: `
            <h3>Niveles de Mantenimiento</h1>
            <br />
            <h3>Conclusión</h1>
            <br />
        `,

     fetchingCrearEquipo: false,
     fetchingEditarEquipo: false,

     fetchingListaGruposServicios: false,
     listaGruposServiciosRMDP: [],

     fetchingObtenerArchivoMesAnteriorEquipo: false,
     archivoMesAnteriorEquipo: null,

     fetchingObtenerArchivoMesActualEquipo: false,
     archivoMesActualEquipo: null,

     fetchingBuscarTareasRMDP: false,
     busquedaTareasRMDP: [],

     fetchingListaCalendarioProgramacion: false,
     listaProgramacionForEquipo: [],

     fetchingListaServiciosForEquipo: false,
     listaServiciosEquipo: [],

     fetchingListaServicioByIdServicioCount: false,
     listaServiciosByIdServicioCount: [],
     listaServiciosByIdServicioCountCantidad: null,

     // Acciones
     fetchingCrearAcciones: false,
     fetchingEditarAcciones: false,

     fetchingObtenerArchivoMesAnteriorAcciones: false,
     archivoMesAnteriorAcciones: null,

     fetchingObtenerArchivoMesActualAcciones: false,
     archivoMesActualAcciones: null,

     // Folios errores u omisiones
     fetchingListaFoliosErroresOmisiones: false,
     listaFoliosErroresOmisiones: [],
     listaFoliosErroresOmisionesCantidad: null,

     fetchingExportarFoliosErroresOmisionesPDF: false,

     fetchingExportarFoliosErroresOmisionesExcel: false,

     // Listados del (i) al (vii) enunciados en el Anexo 1, Mecanismo de Pagos
     fetchingDataSSAgrupamiento: false,
     dataSSAgrupamiento: [],
     dataSSAgrupamientoCantidad: null,
     fetchingDataSSAgrupamientoByGrupo: false,
     dataSSAgrupamientoByGrupo: [],

     dataGraficaRelacionFallas: [],
     dataGraficaRelacionFallasCantidad: null,
     fetchingDataGraficaRelacionFallas: false,

     listaReporteFallas: [],
     fetchingListaReporteFallas: false,
     listaReporteFallasCantidad: null,
     fetchingExcelReporteFallas: false,
     fetchingPDFReporteFallas: false,

     fetchingPDFRelacionDeduccion: false,
     fetchingExcelRelacionDeduccion: false,
     fetchingRelacionDeduccion: false,
     relacionDeduccion: [],
     relacionDeduccionCantidad: null,

     fetchingRelacionDeduccionReiteracion: false,
     relacionDeduccionReiteracion: [],
     relacionDeduccionReiteracionCantidad: null,
     fetchingRelacionDeduccionReiteracionPDF: false,
     fetchingRelacionDeduccionReiteracionEXCEL: false,

     // Cálculo de Pago por Servicio para el Mes i
     //  3.1 Resumen del Pago por Servicio 
     resumenPagoServicio: [],
     fetchingResumenPagoServicio: false,
     fetchingResumenPagoPDF: false,
     fetchingResumenPagoEXCEL: false,

     //  3.2 Resumen para el Cálculo del PMIi & 3.3 Resumen para el Cálculo del PMRi 
     fetchingResumenCalculoPDF: false,
     fetchingResumenCalculo: false,
     resumenCalculo: [],
     fetchingResumenCalculoEXCEL: false,

     //  3.4 Deducción Aplicable
     resumenDeduccionAplicable: [],
     fetchingResumenDeduccionAplicable: false,
     fetchingPDFResumenDeduccionAplicable: false,
     fetchingEXCELResumenDeduccionAplicable: false,

     //  3.5 Deducción por Consumo de los Servicios Públicos del Desarrollador 
     dataTipoConsumoRMDP: [],
     fetchingGetTipoConsumoRMDP: false, 
     dataTipoConsumoRMDPCantidad: null,

     dataTipoConsumoGeneral: [],
     dataTipoConsumoGeneralCantidad: null,
     fetchingDataTipoConsumoGeneral: false,

     fetchingPDFTipoConsumoRMDP: false,
     fetchingPDFTipoConsumoGeneral: false,
     fetchingEXCELTipoConsumoRMDP: false,
     fetchingEXCELTipoConsumoGeneral: false,

     // EDITOR DE TEXTO
     dataEditorDeTexto: null,
     showModalEditorTexto: false,
}    

export default (state = initialState, action) => {
     switch (action.type) {
          case RMDPActions.MES_SELECCIONADO_RMDP:
               return { ...state, mesSeleccionadoRMDP: action.mesSeleccionadoRMDP, anioSeleccionadoRMDP: action.anioSeleccionadoRMDP, mesTexto: action.mesTexto};

          case RMDPActions.ANIOS_RMDP_REQUEST:
               return { ...state, fetchinglistaAniosRMDP: true};
          case RMDPActions.ANIOS_RMDP_SUCCESS:
               return { ...state, fetchinglistaAniosRMDP: false, listaAniosRMDP: action.listaAniosRMDP };
          case RMDPActions.ANIOS_RMDP_FAILURE:
               return { ...state, fetchinglistaAniosRMDP: false};

          case RMDPActions.MESES_RMDP_REQUEST:
               return { ...state, fetchingListaMesesRMDP: true};
          case RMDPActions.MESES_RMDP_SUCCESS:
               return { ...state, fetchingListaMesesRMDP: false, listaMesesRMDP: action.listaMesesRMDP };
          case RMDPActions.MESES_RMDP_FAILURE:
               return { ...state, fetchingListaMesesRMDP: false};

          case RMDPActions.GET_RMDP_REQUEST:
               return { ...state, fetchingGetRMDP: true};
          case RMDPActions.GET_RMDP_SUCCESS:
               return { ...state, fetchingGetRMDP: false, RMDPObject: action.RMDPObject };
          case RMDPActions.GET_RMDP_FAILURE:
               return { ...state, fetchingGetRMDP: false};

          case RMDPActions.GET_REPORTE_RMDP_REQUEST:
               return { ...state, fetchingGetReporteRMDP: true };
          case RMDPActions.GET_REPORTE_RMDP_SUCCESS:
               return { ...state, fetchingGetReporteRMDP: false };
          case RMDPActions.GET_REPORTE_RMDP_FAILURE:
               return { ...state, fetchingGetReporteRMDP: false };

          // ACREDITAMIENTO DE LA PRESTACIÓN DE LOS SERVICIOS DURANTE EL PERIODO DE CONFORMIDAD CON LO DISPUESTO EN EL ANEXO 12 DEL CONTRATO

          // Información del Terreno
          case RMDPActions.CREAR_INFORMACION_TERRENO_REQUEST:
               return { ...state, fetchingCrearInformacionTerreno: true };
          case RMDPActions.CREAR_INFORMACION_TERRENO_SUCCESS:
               return { ...state, fetchingCrearInformacionTerreno: false };
          case RMDPActions.CREAR_INFORMACION_TERRENO_FAILURE:
               return { ...state, fetchingCrearInformacionTerreno: false };

          case RMDPActions.EDITAR_INFORMACION_TERRENO_REQUEST:
               return { ...state, fetchingEditarInformacionTerreno: true };
          case RMDPActions.EDITAR_INFORMACION_TERRENO_SUCCESS:
               return { ...state, fetchingEditarInformacionTerreno: false };
          case RMDPActions.EDITAR_INFORMACION_TERRENO_FAILURE:
               return { ...state, fetchingEditarInformacionTerreno: false };

          case RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_TERRENO_REQUEST:
               return { ...state, fetchingObtenerArchivoMesAnteriorTerreno: true };
          case RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_TERRENO_SUCCESS:
               return { ...state, fetchingObtenerArchivoMesAnteriorTerreno: false, archivoMesAnteriorTerreno: action.archivoMesAnteriorTerreno };
          case RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_TERRENO_FAILURE:
               return { ...state, fetchingObtenerArchivoMesAnteriorTerreno: false };

          case RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_TERRENO_REQUEST:
               return { ...state, fetchingObtenerActualMesAnteriorTerreno: true };
          case RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_TERRENO_SUCCESS:
               return { ...state, fetchingObtenerActualMesAnteriorTerreno: false, archivoMesActualTerreno: action.archivoMesActualTerreno };
          case RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_TERRENO_FAILURE:
               return { ...state, fetchingObtenerActualMesAnteriorTerreno: false };

          // Infraestructura
          case RMDPActions.CREAR_INFRAESTRUCTURA_REQUEST:
               return { ...state, fetchingCrearInfraestructura: true };
          case RMDPActions.CREAR_INFRAESTRUCTURA_SUCCESS:
               return { ...state, fetchingCrearInfraestructura: false };
          case RMDPActions.CREAR_INFRAESTRUCTURA_FAILURE:
               return { ...state, fetchingCrearInfraestructura: false };

          case RMDPActions.EDITAR_INFRAESTRUCTURA_REQUEST:
               return { ...state, fetchingEditarInfraestructura: true };
          case RMDPActions.EDITAR_INFRAESTRUCTURA_SUCCESS:
               return { ...state, fetchingEditarInfraestructura: false };
          case RMDPActions.EDITAR_INFRAESTRUCTURA_FAILURE:
               return { ...state, fetchingEditarInfraestructura: false };

          case RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_INFRAESTRUCTURA_REQUEST:
               return { ...state, fetchingObtenerArchivoMesAnteriorInfraestructura: true };
          case RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_INFRAESTRUCTURA_SUCCESS:
               return { ...state, fetchingObtenerArchivoMesAnteriorInfraestructura: false, archivoMesAnteriorInfraestructura: action.archivoMesAnteriorInfraestructura };
          case RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_INFRAESTRUCTURA_FAILURE:
               return { ...state, fetchingObtenerArchivoMesAnteriorInfraestructura: false };

          case RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_INFRAESTRUCTURA_REQUEST:
               return { ...state, fetchingObtenerArchivoMesActualInfraestructura: true };
          case RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_INFRAESTRUCTURA_SUCCESS:
               return { ...state, fetchingObtenerArchivoMesActualInfraestructura: false, archivoMesActualInfraestructura: action.archivoMesActualInfraestructura};
          case RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_INFRAESTRUCTURA_FAILURE:
               return { ...state, fetchingObtenerArchivoMesActualInfraestructura: false };

          case RMDPActions.OBTENER_UBICACIONES_INFRAESTRUCTURA_REQUEST:
               return { ...state, fetchingObtenerUbicacionesInfraestructura: true };
          case RMDPActions.OBTENER_UBICACIONES_INFRAESTRUCTURA_SUCCESS:
               return { ...state, fetchingObtenerUbicacionesInfraestructura: false, ubicacionesInfraestructura: action.ubicacionesInfraestructura, ubicacionesInfraestructuraCantidad: action.ubicacionesInfraestructuraCantidad};
          case RMDPActions.OBTENER_UBICACIONES_INFRAESTRUCTURA_FAILURE:
               return { ...state, fetchingObtenerUbicacionesInfraestructura: false };


          // Equipo
          case RMDPActions.CREAR_EQUIPO_REQUEST:
               return { ...state, fetchingCrearEquipo: true };
          case RMDPActions.CREAR_EQUIPO_SUCCESS:
               return { ...state, fetchingCrearEquipo: false };
          case RMDPActions.CREAR_EQUIPO_FAILURE:
               return { ...state, fetchingCrearEquipo: false };

          case RMDPActions.EDITAR_EQUIPO_REQUEST:
               return { ...state, fetchingEditarEquipo: true };
          case RMDPActions.EDITAR_EQUIPO_SUCCESS:
               return { ...state, fetchingEditarEquipo: false };
          case RMDPActions.EDITAR_EQUIPO_FAILURE:
               return { ...state, fetchingEditarEquipo: false };

          case RMDPActions.LISTA_GRUPOS_SERVICIOS_RDMP_REQUEST:
               return { ...state, fetchingListaGruposServicios: true };
          case RMDPActions.LISTA_GRUPOS_SERVICIOS_RDMP_SUCCESS:
               return { ...state, fetchingListaGruposServicios: false, listaGruposServiciosRMDP: action.listaGruposServiciosRMDP };
          case RMDPActions.LISTA_GRUPOS_SERVICIOS_RDMP_FAILURE:
               return { ...state, fetchingListaGruposServicios: false };

          case RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_EQUIPO_REQUEST:
               return { ...state, fetchingObtenerArchivoMesAnteriorEquipo: true };
          case RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_EQUIPO_SUCCESS:
               return { ...state, fetchingObtenerArchivoMesAnteriorEquipo: false, archivoMesAnteriorEquipo: action.archivoMesAnteriorEquipo };
          case RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_EQUIPO_FAILURE:
               return { ...state, fetchingObtenerArchivoMesAnteriorEquipo: false, archivoMesAnteriorEquipo: action.archivoMesAnteriorEquipo };

          case RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_EQUIPO_REQUEST:
               return { ...state, fetchingObtenerArchivoMesActualEquipo: true };
          case RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_EQUIPO_SUCCESS:
               return { ...state, fetchingObtenerArchivoMesActualEquipo: false, archivoMesActualEquipo: action.archivoMesActualEquipo};
          case RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_EQUIPO_FAILURE:
               return { ...state, fetchingObtenerArchivoMesActualEquipo: false, archivoMesActualEquipo: action.archivoMesActualEquipo };

          case RMDPActions.BUSCAR_TAREAS_RMDP_REQUEST:
               return { ...state, fetchingBuscarTareasRMDP: true };
          case RMDPActions.BUSCAR_TAREAS_RMDP_SUCCESS:
               return { ...state, fetchingBuscarTareasRMDP: false, busquedaTareasRMDP: action.busquedaTareasRMDP };
          case RMDPActions.BUSCAR_TAREAS_RMDP_FAILURE:
               return { ...state, fetchingBuscarTareasRMDP: false };

          case RMDPActions.LISTA_SERVICIOS_FOR_EQUIPO_REQUEST:
               return { ...state, fetchingListaServiciosForEquipo: true };
          case RMDPActions.LISTA_SERVICIOS_FOR_EQUIPO_SUCCESS:
               return { ...state, fetchingListaServiciosForEquipo: false, listaServiciosEquipo: action.listaServiciosEquipo };
          case RMDPActions.LISTA_SERVICIOS_FOR_EQUIPO_FAILURE:
               return { ...state, fetchingListaServiciosForEquipo: false };

               case RMDPActions.LISTA_PROGRAMADOS_FOR_EQUIPO_REQUEST:
                    return { ...state, fetchingListaCalendarioProgramacion: true };
               case RMDPActions.LISTA_PROGRAMADOS_FOR_EQUIPO_SUCCESS:
                    return { ...state, fetchingListaCalendarioProgramacion: false, listaProgramacionForEquipo: action.listaProgramacionForEquipo };
               case RMDPActions.LISTA_PROGRAMADOS_FOR_EQUIPO_FAILURE:
                    return { ...state, fetchingListaCalendarioProgramacion: false };

               case RMDPActions.LISTA_SERVICIOS_BYIDSERVICIO_COUNT_REQUEST:
                    return { ...state, fetchingListaServicioByIdServicioCount: true };
               case RMDPActions.LISTA_SERVICIOS_BYIDSERVICIO_COUNT_SUCCESS:
                    return { ...state, fetchingListaServicioByIdServicioCount: false, listaServiciosByIdServicioCount: action.listaServiciosByIdServicioCount, listaServiciosByIdServicioCountCantidad: action.listaServiciosByIdServicioCountCantidad };
               case RMDPActions.LISTA_SERVICIOS_BYIDSERVICIO_COUNT_FAILURE:
                    return { ...state, fetchingListaServicioByIdServicioCount: false };

          // Acciones
          case RMDPActions.CREAR_ACCIONES_REQUEST:
               return { ...state, fetchingCrearAcciones: true };
          case RMDPActions.CREAR_ACCIONES_SUCCESS:
               return { ...state, fetchingCrearAcciones: false };
          case RMDPActions.CREAR_ACCIONES_FAILURE:
               return { ...state, fetchingCrearAcciones: false };

          case RMDPActions.EDITAR_ACCIONES_REQUEST:
               return { ...state, fetchingEditarAcciones: true };
          case RMDPActions.EDITAR_ACCIONES_SUCCESS:
               return { ...state, fetchingEditarAcciones: false };
          case RMDPActions.EDITAR_ACCIONES_FAILURE:
               return { ...state, fetchingEditarAcciones: false };

          case RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_ACCIONES_REQUEST:
               return { ...state, fetchingObtenerArchivoMesAnteriorAcciones: true };
          case RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_ACCIONES_SUCCESS:
               return { ...state, fetchingObtenerArchivoMesAnteriorAcciones: false, archivoMesAnteriorAcciones: action.archivoMesAnteriorAcciones };
          case RMDPActions.OBTENER_ARCHIVO_MES_ANTERIOR_ACCIONES_FAILURE:
               return { ...state, fetchingObtenerArchivoMesAnteriorAcciones: false };

          case RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_ACCIONES_REQUEST:
               return { ...state, fetchingObtenerArchivoMesActualAcciones: true };
          case RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_ACCIONES_SUCCESS:
               return { ...state, fetchingObtenerArchivoMesActualAcciones: false, archivoMesActualAcciones: action.archivoMesActualAcciones };
          case RMDPActions.OBTENER_ARCHIVO_MES_ACTUAL_ACCIONES_FAILURE:
               return { ...state, fetchingObtenerArchivoMesActualAcciones: false };

          // Folios errores u omisiones
          case RMDPActions.LISTA_FOLIOS_ERRORES_OMISIONES_REQUEST:
               return { ...state, fetchingListaFoliosErroresOmisiones: true };
          case RMDPActions.LISTA_FOLIOS_ERRORES_OMISIONES_SUCCESS:
               return { ...state, fetchingListaFoliosErroresOmisiones: false, listaFoliosErroresOmisiones: action.listaFoliosErroresOmisiones, listaFoliosErroresOmisionesCantidad: action.listaFoliosErroresOmisionesCantidad };
          case RMDPActions.LISTA_FOLIOS_ERRORES_OMISIONES_FAILURE:
               return { ...state, fetchingListaFoliosErroresOmisiones: false };

          case RMDPActions.EXPORTAR_FOLIOS_ERRORES_OMISIONES_PDF_REQUEST:
               return { ...state, fetchingExportarFoliosErroresOmisionesPDF: true };
          case RMDPActions.EXPORTAR_FOLIOS_ERRORES_OMISIONES_PDF_SUCCESS:
               return { ...state, fetchingExportarFoliosErroresOmisionesPDF: false };
          case RMDPActions.EXPORTAR_FOLIOS_ERRORES_OMISIONES_PDF_FAILURE:
               return { ...state, fetchingExportarFoliosErroresOmisionesPDF: false };

          case RMDPActions.EXPORTAR_FOLIOS_ERRORES_OMISIONES_EXCEL_REQUEST:
               return { ...state, fetchingExportarFoliosErroresOmisionesExcel: true };
          case RMDPActions.EXPORTAR_FOLIOS_ERRORES_OMISIONES_EXCEL_SUCCESS:
               return { ...state, fetchingExportarFoliosErroresOmisionesExcel: false };
          case RMDPActions.EXPORTAR_FOLIOS_ERRORES_OMISIONES_EXCEL_FAILURE:
               return { ...state, fetchingExportarFoliosErroresOmisionesExcel: false };

          // Listados del (i) al (vii) enunciados en el Anexo 1, Mecanismo de Pagos
          case RMDPActions.DATA_SS_AGRUPAMIENTO_REQUEST:
               return { ...state, fetchingDataSSAgrupamiento: true};
          case RMDPActions.DATA_SS_AGRUPAMIENTO_SUCCESS:
               return { ...state, fetchingDataSSAgrupamiento: false, dataSSAgrupamiento: action.dataSSAgrupamiento, dataSSAgrupamientoCantidad: action.dataSSAgrupamientoCantidad};
          case RMDPActions.DATA_SS_AGRUPAMIENTO_FAILURE:
               return { ...state, fetchingDataSSAgrupamiento: false};

          case RMDPActions.DATA_SS_AGRUPAMIENTO_BY_GRUPO_REQUEST:
               return { ...state, fetchingDataSSAgrupamientoByGrupo: true};
          case RMDPActions.DATA_SS_AGRUPAMIENTO_BY_GRUPO_SUCCESS:
               return { ...state, fetchingDataSSAgrupamientoByGrupo: false, dataSSAgrupamientoByGrupo: action.dataSSAgrupamientoByGrupo};
          case RMDPActions.DATA_SS_AGRUPAMIENTO_BY_GRUPO_FAILURE:
               return { ...state, fetchingDataSSAgrupamientoByGrupo: false};

          //  (i) Relación de Fallas en la Presentación del Servicio 

          case RMDPActions.LISTA_REPORTE_FALLAS_REQUEST:
               return { ...state, fetchingListaReporteFallas: true};
          case RMDPActions.LISTA_REPORTE_FALLAS_SUCCESS:
               return { ...state, fetchingListaReporteFallas: false, listaReporteFallas: action.listaReporteFallas, listaReporteFallasCantidad: action.listaReporteFallasCantidad};
          case RMDPActions.LISTA_REPORTE_FALLAS_FAILURE:
               return { ...state, fetchingListaReporteFallas: false};

          case RMDPActions.EXCEL_REPORTE_FALLAS_REQUEST:
               return { ...state, fetchingExcelReporteFallas: true };
          case RMDPActions.EXCEL_REPORTE_FALLAS_SUCCESS:
               return { ...state, fetchingExcelReporteFallas: false };
          case RMDPActions.EXCEL_REPORTE_FALLAS_FAILURE:
               return { ...state, fetchingExcelReporteFallas: false };

          case RMDPActions.PDF_REPORTE_FALLAS_REQUEST:
               return { ...state, fetchingPDFReporteFallas: true };
          case RMDPActions.PDF_REPORTE_FALLAS_SUCCESS:
               return { ...state, fetchingPDFReporteFallas: false };
          case RMDPActions.PDF_REPORTE_FALLAS_FAILURE:
               return { ...state, fetchingPDFReporteFallas: false };

          case RMDPActions.DATA_GRAFICA_RELACION_FALLAS_REQUEST:
               return { ...state, fetchingDataGraficaRelacionFallas: true };
          case RMDPActions.DATA_GRAFICA_RELACION_FALLAS_SUCCESS:
               return { ...state, fetchingDataGraficaRelacionFallas: false, dataGraficaRelacionFallas: action.dataGraficaRelacionFallas, dataGraficaRelacionFallasCantidad: action.dataGraficaRelacionFallasCantidad };
          case RMDPActions.DATA_GRAFICA_RELACION_FALLAS_FAILURE:
               return { ...state, fetchingDataGraficaRelacionFallas: false };

          // (iv) Relación de Deducciones en el Mes i
          case RMDPActions.RELACION_DEDUCCIONES_REQUEST:
               return { ...state, fetchingRelacionDeduccion: true};
          case RMDPActions.RELACION_DEDUCCIONES_SUCCESS:
               return { ...state, fetchingRelacionDeduccion: false, relacionDeduccion: action.relacionDeduccion, relacionDeduccionCantidad: action.relacionDeduccionCantidad};
          case RMDPActions.RELACION_DEDUCCIONES_FAILURE:
               return { ...state, fetchingRelacionDeduccion: false};

          case RMDPActions.PDF_RELACION_DEDUCCION_REQUEST:
               return { ...state, fetchingPDFRelacionDeduccion: true };
          case RMDPActions.PDF_RELACION_DEDUCCION_SUCCESS:
               return { ...state, fetchingPDFRelacionDeduccion: false };
          case RMDPActions.PDF_RELACION_DEDUCCION_FAILURE:
               return { ...state, fetchingPDFRelacionDeduccion: false };
               
          case RMDPActions.EXCEL_RELACION_DEDUCCION_REQUEST:
               return { ...state, fetchingExcelRelacionDeduccion: true };
          case RMDPActions.EXCEL_RELACION_DEDUCCION_SUCCESS:
               return { ...state, fetchingExcelRelacionDeduccion: false };
          case RMDPActions.EXCEL_RELACION_DEDUCCION_FAILURE:
               return { ...state, fetchingExcelRelacionDeduccion: false }; 
               
          //  (v) Relación de Deducciones por Reiteraciones 
          case RMDPActions.RELACION_DEDUCCIONES_REITERACION_REQUEST:
               return { ...state, fetchingRelacionDeduccionReiteracion: true};
          case RMDPActions.RELACION_DEDUCCIONES_REITERACION_SUCCESS:
               return { ...state, fetchingRelacionDeduccionReiteracion: false, relacionDeduccionReiteracion: action.relacionDeduccionReiteracion, relacionDeduccionReiteracionCantidad: action.relacionDeduccionReiteracionCantidad};
          case RMDPActions.RELACION_DEDUCCIONES_REITERACION_FAILURE:
               return { ...state, fetchingRelacionDeduccionReiteracion: false};

          case RMDPActions.PDF_RELACION_DEDUCCION_REITERACION_REQUEST:
               return { ...state, fetchingRelacionDeduccionReiteracionPDF: true };
          case RMDPActions.PDF_RELACION_DEDUCCION_REITERACION_SUCCESS:
               return { ...state, fetchingRelacionDeduccionReiteracionPDF: false };
          case RMDPActions.PDF_RELACION_DEDUCCION_REITERACION_FAILURE:
               return { ...state, fetchingRelacionDeduccionReiteracionPDF: false };

          case RMDPActions.EXCEL_RELACION_DEDUCCION_REITERACION_REQUEST:
               return { ...state, fetchingRelacionDeduccionReiteracionEXCEL: true };
          case RMDPActions.EXCEL_RELACION_DEDUCCION_REITERACION_SUCCESS:
               return { ...state, fetchingRelacionDeduccionReiteracionEXCEL: false };
          case RMDPActions.EXCEL_RELACION_DEDUCCION_REITERACION_FAILURE:
               return { ...state, fetchingRelacionDeduccionReiteracionEXCEL: false };

          // Cálculo de Pago por Servicio para el Mes i
          //  3.1 Resumen del Pago por Servicio 
          case RMDPActions.RESUMEN_PAGO_SERVICIO_REQUEST:
               return { ...state, fetchingResumenPagoServicio: true };
          case RMDPActions.RESUMEN_PAGO_SERVICIO_SUCCESS:
               return { ...state, fetchingResumenPagoServicio: false, resumenPagoServicio: action.resumenPagoServicio };
          case RMDPActions.RESUMEN_PAGO_SERVICIO_FAILURE:
               return { ...state, fetchingResumenPagoServicio: false };

          case RMDPActions.PDF_RESUME_PAGO_REQUEST:
               return { ...state, fetchingResumenPagoPDF: true };
          case RMDPActions.PDF_RESUME_PAGO_SUCCESS:
               return { ...state, fetchingResumenPagoPDF: false };
          case RMDPActions.PDF_RESUME_PAGO_FAILURE:
               return { ...state, fetchingResumenPagoPDF: false };

          case RMDPActions.EXCEL_RESUME_PAGO_REQUEST:
               return { ...state, fetchingResumenPagoEXCEL: true };
          case RMDPActions.EXCEL_RESUME_PAGO_SUCCESS:
               return { ...state, fetchingResumenPagoEXCEL: false };
          case RMDPActions.EXCEL_RESUME_PAGO_FAILURE:
               return { ...state, fetchingResumenPagoEXCEL: false };

          //  3.2 Resumen para el Cálculo del PMIi &  3.3 Resumen para el Cálculo del PMRi 
          case RMDPActions.RESUMEN_CALCULO_REQUEST:
               return { ...state, fetchingResumenCalculo: true };
          case RMDPActions.RESUMEN_CALCULO_SUCCESS:
               return { ...state, fetchingResumenCalculo: false, resumenCalculo: action.resumenCalculo };
          case RMDPActions.RESUMEN_CALCULO_FAILURE:
               return { ...state, fetchingResumenCalculo: false };

          case RMDPActions.PDF_RESUMEN_CALCULO_REQUEST:
               return { ...state, fetchingResumenCalculoPDF: true };
          case RMDPActions.PDF_RESUMEN_CALCULO_SUCCESS:
               return { ...state, fetchingResumenCalculoPDF: false };
          case RMDPActions.PDF_RESUMEN_CALCULO_FAILURE:
               return { ...state, fetchingResumenCalculoPDF: false };

          case RMDPActions.EXCEL_RESUMEN_CALCULO_REQUEST:
               return { ...state, fetchingResumenCalculoEXCEL: true };
          case RMDPActions.EXCEL_RESUMEN_CALCULO_SUCCESS:
               return { ...state, fetchingResumenCalculoEXCEL: false };
          case RMDPActions.EXCEL_RESUMEN_CALCULO_FAILURE:
               return { ...state, fetchingResumenCalculoEXCEL: false };
          
          //  3.4 Deducción Aplicable
          case RMDPActions.DEDUCCION_APLICABLE_RESUMEN_REQUEST:
               return { ...state, fetchingResumenDeduccionAplicable: true };
          case RMDPActions.DEDUCCION_APLICABLE_RESUMEN_SUCCESS:
               return { ...state, fetchingResumenDeduccionAplicable: false, resumenDeduccionAplicable: action.resumenDeduccionAplicable };
          case RMDPActions.DEDUCCION_APLICABLE_RESUMEN_FAILURE:
               return { ...state, fetchingResumenDeduccionAplicable: false };

          case RMDPActions.PDF_DEDUCCION_APLICABLE_RESUMEN_REQUEST:
               return { ...state, fetchingPDFResumenDeduccionAplicable: true };
          case RMDPActions.PDF_DEDUCCION_APLICABLE_RESUMEN_SUCCESS:
               return { ...state, fetchingPDFResumenDeduccionAplicable: false };
          case RMDPActions.PDF_DEDUCCION_APLICABLE_RESUMEN_FAILURE:
               return { ...state, fetchingPDFResumenDeduccionAplicable: false };

          case RMDPActions.EXCEL_DEDUCCION_APLICABLE_RESUMEN_REQUEST:
               return { ...state, fetchingEXCELResumenDeduccionAplicable: true };
          case RMDPActions.EXCEL_DEDUCCION_APLICABLE_RESUMEN_SUCCESS:
               return { ...state, fetchingEXCELResumenDeduccionAplicable: false };
          case RMDPActions.EXCEL_DEDUCCION_APLICABLE_RESUMEN_FAILURE:
               return { ...state, fetchingEXCELResumenDeduccionAplicable: false };

          //  3.5 Deducción por Consumo de los Servicios Públicos del Desarrollador 
          case RMDPActions.GET_TIPO_CONSUMO_RMDP_REQUEST:
               return { ...state, fetchingGetTipoConsumoRMDP: true };
          case RMDPActions.GET_TIPO_CONSUMO_RMDP_SUCCESS:
               return { ...state, fetchingGetTipoConsumoRMDP: false, dataTipoConsumoRMDP: action.dataTipoConsumoRMDP, dataTipoConsumoRMDPCantidad: action.dataTipoConsumoRMDPCantidad };
          case RMDPActions.GET_TIPO_CONSUMO_RMDP_FAILURE:
               return { ...state, fetchingGetTipoConsumoRMDP: false };


          case RMDPActions.PDF_TIPO_CONSUMO_RMDP_REQUEST:
               return { ...state, fetchingPDFTipoConsumoRMDP: true };
          case RMDPActions.PDF_TIPO_CONSUMO_RMDP_SUCCESS:
               return { ...state, fetchingPDFTipoConsumoRMDP: false };
          case RMDPActions.PDF_TIPO_CONSUMO_RMDP_FAILURE:
               return { ...state, fetchingPDFTipoConsumoRMDP: false };

          case RMDPActions.GET_TIPO_CONSUMO_GENERAL_RMDP_REQUEST:
               return { ...state, fetchingDataTipoConsumoGeneral: true };
          case RMDPActions.GET_TIPO_CONSUMO_GENERAL_RMDP_SUCCESS:
               return { ...state, fetchingDataTipoConsumoGeneral: false, dataTipoConsumoGeneral: action.dataTipoConsumoGeneral, dataTipoConsumoGeneralCantidad: action.dataTipoConsumoGeneralCantidad };
          case RMDPActions.GET_TIPO_CONSUMO_GENERAL_RMDP_FAILURE:
               return { ...state, fetchingDataTipoConsumoGeneral: false };

          case RMDPActions.PDF_TIPO_CONSUMO_GENERAL_RMDP_REQUEST:
               return { ...state, fetchingPDFTipoConsumoGeneral: true };
          case RMDPActions.PDF_TIPO_CONSUMO_GENERAL_RMDP_SUCCESS:
               return { ...state, fetchingPDFTipoConsumoGeneral: false };
          case RMDPActions.PDF_TIPO_CONSUMO_GENERAL_RMDP_FAILURE:
               return { ...state, fetchingPDFTipoConsumoGeneral: false };

               case RMDPActions.EXCEL_TIPO_CONSUMO_RMDP_REQUEST:
                    return { ...state, fetchingEXCELTipoConsumoRMDP: true };
               case RMDPActions.EXCEL_TIPO_CONSUMO_RMDP_SUCCESS:
                    return { ...state, fetchingEXCELTipoConsumoRMDP: false };
               case RMDPActions.EXCEL_TIPO_CONSUMO_RMDP_FAILURE:
                    return { ...state, fetchingEXCELTipoConsumoRMDP: false };

               case RMDPActions.EXCEL_TIPO_CONSUMO_GENERAL_RMDP_REQUEST:
                    return { ...state, fetchingEXCELTipoConsumoGeneral: true };
               case RMDPActions.EXCEL_TIPO_CONSUMO_GENERAL_RMDP_SUCCESS:
                    return { ...state, fetchingEXCELTipoConsumoGeneral: false };
               case RMDPActions.EXCEL_TIPO_CONSUMO_GENERAL_RMDP_FAILURE:
                    return { ...state, fetchingEXCELTipoConsumoGeneral: false };

          // EDITOR DE TEXTO

          case RMDPActions.MODAL_EDITOR_TEXTO:
               return { ...state, showModalEditorTexto: action.showModalEditorTexto, dataEditorDeTexto: action.dataEditorDeTexto };


          default:
          return state;
     }
}
