import React, {Component} from 'react'
import { Form, Select, Button, Col, Spin, Modal, Row, Input, Tooltip, DatePicker, Switch, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import moment from 'moment';


const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Option } = Select;
const { TextArea } = Input;
class ModalEvaluarFolioEstandar extends Component {
    formRef = React.createRef();
    state={
        mostrarEvidencia: false,
        filePDF: false,
        botonPDF: false,
        uploadOk: false,
    }
    render() { 
        const {
            onEvaluarFolioEstandar, onShowModalEvaluarFolioEstandar, showModalEvaluarFolioEstandar,
            fetchingEvaluarFolioEstandar, page, limpiarFormulario, fetchingListaUbicacionesTodasBusqueda,
            listaUbicacionesTodasBusqueda, folioEstandarSeleccionado,
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            const listaEstandares = [];
            for (var key in formulario) {
                if (Object.prototype.hasOwnProperty.call(formulario, key)) {
                    let idCampo = key.split('-');
                    if (idCampo.length === 2) {
                        let idCaptura = idCampo[1];
                        let campo = idCampo[0];
                        let valor = formulario[key];
    
                        let estandarFormulario = {[campo]: valor, 'idCumplimientoEstandar': parseInt(idCaptura)};
                        listaEstandares.push(estandarFormulario);
                    }
                }
            }
            if(this.state.uploadOk){
                onEvaluarFolioEstandar(formulario, listaEstandares, page, folioEstandarSeleccionado);
            }
            else{
                message.error('Favor de agregar un PDF de evidencia');
            }
        };

        if (limpiarFormulario && limpiarFormulario === 'evaluarFolioEstandar') {
            this.setState({
                mostrarEvidencia: false,
                filePDF: false,
                botonPDF: false,
                uploadOk: false,
            })
            this.props.onBuscarUbicacionLimpiar();
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }
        const handleCancel = () => {
            onShowModalEvaluarFolioEstandar();
            this.formRef.current.resetFields();
            this.props.onBuscarUbicacionLimpiar();
            this.setState({
                mostrarEvidencia: false,
                filePDF: false,
                botonPDF: false,
                uploadOk: false,
            })
        }
        
        const buscarUbicacion = (value) => {
            if(value.length>=3){
                console.log(value)
                this.props.onBuscarUbicacion(value);
            }
            else{
                console.log('Entró a limpiar')
                this.props.onBuscarUbicacionLimpiar();
            }
        };
        
        const onSiguientePaso = () => {
            this.setState({
                mostrarEvidencia: true
            })
        }

        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else {
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                })
            }
            if (info.file.status === 'done') {
                var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,40}$");
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['evidenciaPDF']);
                }
                else {
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true,
                        uploadOk: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['evidenciaPDF']);
                }
                else {
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true,
                        uploadOk: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }

        const onRemovePDF = () => {
            this.formRef.current.resetFields(['evidenciaPDF']);
            this.setState({
                filePDF: false,
                botonPDF: false,
                uploadOk: false,
            });
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };

        const  disabledDate = (current) => {
            // Can not select days before today and today
            return current && current < moment(folioEstandarSeleccionado.fechaAlta);
        }

        const onSelectFecha = (fecha) => {
            console.log(`fecha`, fecha, moment(folioEstandarSeleccionado.fechaAlta), fecha<moment(folioEstandarSeleccionado.fechaAlta));
            if(fecha<moment(folioEstandarSeleccionado.fechaAlta)){
                message.error('Favor de seleccionar una fecha y hora mayores a la de creación de folio');
                this.formRef.current.resetFields(['fechaEvalua']);
            }
        }
        return (
            <>
                {folioEstandarSeleccionado ? (
                <Modal
                    visible={showModalEvaluarFolioEstandar}
                    title={'Evaluar Folio de Estándar ' + folioEstandarSeleccionado.folioEstandar}
                    onCancel={handleCancel}
                    footer={false}
                    width='70%'
                >
                    <Spin spinning={fetchingEvaluarFolioEstandar}>
                        <Form {...layout}
                            name="formulario"
                            onFinish={onFinish}
                            ref={this.formRef}
                        >
                            <Row justify='space-around'>
                                <Col span={11}>
                                    <Form.Item
                                        label="Fecha y hora"
                                        name="fechaEvalua"
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            format='DD-MM-YYYY HH:mm'
                                            placeholder={["Selecciona fecha y hora"]}
                                            showTime={{ defaultValue: moment('00:00', 'HH:mm') }} 
                                            onChange={onSelectFecha}
                                            showToday={false}
                                            disabledDate={disabledDate}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label="Ubicación"
                                        name="ubicacion"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Buscar la ubicación"
                                            loading={fetchingListaUbicacionesTodasBusqueda}
                                            showSearch={true}
                                            filterOption={false}
                                            onSearch={buscarUbicacion}
                                            notFoundContent={null}
                                        >
                                            {listaUbicacionesTodasBusqueda.map(option => 
                                            <Option key={option.idUbicacion}>
                                                <Tooltip title={option.todoUbicacion}>
                                                    {option.todoUbicacion}
                                                </Tooltip>
                                            </Option>)}
                                        </Select>
                                    </Form.Item> 
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label="Nombre de la persona que evalúa"
                                        name="nombreEvalua"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Input maxLength={250} placeholder='Ingresa el nombre de la persona que evalúa' />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label="Puesto de la persona que evalúa"
                                        name="puestoEvalua"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Input maxLength={250} placeholder='Ingresa el puesto de la persona que evalúa' />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    {folioEstandarSeleccionado.cumplimientosByIdFolioEstandar.map(estandar =>
                                    <Row key={estandar.idCumplimientoEstandar} justify='space-between'>
                                        <Col span={20}>
                                            <p><strong>{estandar.estandarByIdEstandarDesempeno.referencia}</strong>{' - ' + estandar.estandarByIdEstandarDesempeno.descripcion}</p>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item
                                                name={"cumple-" + estandar.idCumplimientoEstandar}
                                                valuePropName="checked"
                                            >
                                                <Switch checkedChildren={'Cumple'} unCheckedChildren={'No Cumple'}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    )}
                                </Col>
                                {!this.state.mostrarEvidencia ? (
                                <Col span={11} className='marginBoton'>
                                    <br/>
                                    <Button className="btnFiltrar" style={{ width: '100%' }} onClick={onSiguientePaso}>Adjuntar Evidencia</Button>
                                </Col>
                                ):(
                                <Col span={24}>
                                    <Row justify='space-around'>
                                        <Col span={11}>
                                            <Form.Item
                                                label="Adjuntar evidencia"
                                                name="evidenciaPDF"
                                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                            >
                                                <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                                    {this.state.filePDF === false ? (
                                                    <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                                        <UploadOutlined /> Click para subir evidencia PDF
                                                    </Button>
                                                    ):false}
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item
                                                label="Observaciones"
                                                name="observaciones"
                                            >
                                                <TextArea
                                                    title="Observaciones"
                                                    maxLength={400}
                                                    placeholder='Observaciones'
                                                    autoSize 
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={11} className='marginBoton'>
                                            <br/>
                                            <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Evaluar Folio de Estándar</Button>
                                        </Col>
                                    </Row>
                                </Col>
                                )}
                            </Row>
                        </Form>
                    </Spin>
                </Modal>
                ):false}
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        folioEstandarSeleccionado: state.GestionDeCalidadReducer.folioEstandarSeleccionado,
        showModalEvaluarFolioEstandar: state.GestionDeCalidadReducer.showModalEvaluarFolioEstandar,
        fetchingEvaluarFolioEstandar: state.GestionDeCalidadReducer.fetchingEvaluarFolioEstandar,
        page: state.GestionDeCalidadReducer.page,
        listaUbicacionesTodasBusqueda: state.CatalogosGeneralesReducer.listaUbicacionesTodasBusqueda,
        fetchingListaUbicacionesTodasBusqueda: state.CatalogosGeneralesReducer.fetchingListaUbicacionesTodasBusqueda,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEvaluarFolioEstandar: () => {
            dispatch ({ type: 'MODAL_EVALUAR_FOLIO_ESTANDAR', showModalEvaluarFolioEstandar: false })
        },
        onEvaluarFolioEstandar: (formulario, listaEstandares, page, folioEstandarSeleccionado) => {
            dispatch({ type: "EVALUAR_FOLIO_ESTANDAR_REQUEST", formulario, listaEstandares, page, folioEstandarSeleccionado })
        },
        onBuscarUbicacion: (param) => {
            dispatch({ type: "LISTA_UBICACIONES_TODAS_BUSQUEDA_REQUEST", param })
        },
        onBuscarUbicacionLimpiar: () => {
            dispatch({ type: "LISTA_UBICACIONES_TODAS_BUSQUEDA_SUCCESS", listaUbicacionesTodasBusqueda: [] })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEvaluarFolioEstandar);