import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import { Alert, Button, Row, Col, Steps, Checkbox, Typography} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import FilterSection from './FilterSection';
import TableSection from './TableSection';

import RegistroReportes from './RegistroReportesContainer';

const { Step } = Steps;
const {Text} = Typography;

const ReporteDinamico = props => {

     const {listaReporteDinamicoRH, formularioReporte,listaServiciosActivosF,onGetReporteDinamico,onLimpiarFormulario, onGetGrupoServicioByIdUsuario, onGetReporteDinamicoAgrupado } = props;

     const [state, setState] = useState({page: 1, currentStep:0});
     const [order, setOrder] = useState({field:'pr.idPersonal',order:'ASC'});
     
     const [checkAll, setCheckAll] = useState (false)
     const [checkArray, setCheckArray] = useState([]);
     const [checkbox, setCheckbox] = useState ({
          nombreServicio: false, folioSolicitudServicio: false, descripcionFalla: false,
          areaFuncional: false, unidadFuncional: false, interiorFuncional: false, 
          usuarioSolicitoName: false, usuarioRegistro: false, fechaAlta: false,
          fechaCierre: false, fechaTerminacion: false, estado: false, actividadesRealizadas: false
     });
    
     let permisosUsuario = sessionStorage.getItem('permisosUsuario');
     if(permisosUsuario[0]) {
          permisosUsuario = JSON.parse(permisosUsuario);
     }

     useEffect(()=> {
          const arrayCheckbox = checkboxArray();
          setCheckArray(arrayCheckbox);
     },[checkbox]);

    
     const checkboxArray = () => {
          let fieldArray = []
          for (var key in checkbox) {
               if(Object.prototype.hasOwnProperty.call(checkbox, key)) {
                    if(checkbox[key] === true){
                    fieldArray.push(key);
                    }
               }
          }
          return fieldArray;
     }

     const handleSteps = value => {
          console.log('Entra al handle')

          if (value === 'siguiente') return  setState({...state, currentStep: state.currentStep + 1});

          setOrder({field:'pr.idPersonal',order:'ASC'});
          setState({page:1, currentStep: state.currentStep -1});
          onLimpiarFormulario(formularioReporte);
     }


     const selectHandle = (event,field) => {
          const checked = event.target.checked
          setCheckAll(false);

          if (checked && field === 'numeroEmpleado') return setCheckbox({...checkbox, nombre: true, aPaterno: true, aMaterno: true, [field]:checked});
          
          if(checked) return setCheckbox({...checkbox,[field]:event.target.checked })
          setCheckbox({...checkbox,[field]:event.target.checked});
     }

     const selectAll = event => {
          setCheckAll(event.target.checked);
          if (event.target.checked) {
               return setCheckbox({
                    numeroEmpleadoC :true, nombreC :true, aPaternoC:true, aMaternoC:true, rfcC:true, folioIDC:true, nombreProveedorC:true, nombreServicioC:true, correoC:true, puestoC:true, fechaIngresoC:true, tipoTurnoC:true, diasLaboralesC:true, horarioEntradaC:true,  horarioSalidaC:true, salidaComidaC:true, regresoComidaC:true, expedienteClaveC:true,  activoC:true, razonBajaC:true
               });
          }
          setCheckbox({
               numeroEmpleadoC :false, nombreC :false, aPaternoC:false, aMaternoC:false, rfcC:false, folioIDC:false, nombreProveedorC:false, nombreServicioC:false, correoC:false, puestoC:false, fechaIngresoC:false, tipoTurnoC:false, diasLaboralesC:false, horarioEntradaC:false,  horarioSalidaC:false, salidaComidaC:false, regresoComidaC:false, expedienteClaveC:false,  activoC:false, razonBajaC: false
          })
     }

     const handleTableChange = (pagination, filter, orden) => {
          setState({...state, page: pagination.current});
          console.log('orden en RD', orden)
          
          const orderFields = {numeroEmpleadoC:'pr.numeroEmpleado', nombreC:'pr.nombre', aPaternoC:'pr.aPaterno', aMaternoC: 'pr.aMaterno', rfcC: 'pr.rfc', folioID: 'pr.folioID', nombreProveedorC: 'pro.nombre', nombreServicioC: 'ser.nombre', correoC: 'pr.correo', puestoC: 'pr.puesto', fechaIngresoC: 'pr.fechaIngreso', tipoTurnoC: 'pr.tipoTurno', diasLaboralesC: 'pr.diasLaborales', horarioEntradaC: 'pr.horarioEntrada', horarioSalidaC: 'pr.horarioSalida', salidaComidaC: 'pr.salidaComida', regresoComidaC: 'pr.regresoComida', expedienteClaveC: 'ex.clave', activoC: 'pr.activo', razonBajaC: 'pr.razonBaja'}

          if(orden) {
               const field = orderFields[orden.columnKey];
               const orderField = orden.order === 'descend' ? 'DESC': 'ASC';
               const sorter = {field: field, order: orderField}
               setOrder({field: field, order: orderField});
               return onGetReporteDinamico(formularioReporte, pagination.current, sorter, checkbox );
          }

          onGetReporteDinamico(formularioReporte, pagination.current, order, checkbox );
     }

     const handleOrden = (field, order) => {
          let agrupado = undefined;
          console.log(`field, orden`, field, order)
          const sorter = {field, order}
          setOrder({field, order});
          onGetReporteDinamicoAgrupado(formularioReporte, state.page, agrupado, checkbox, sorter)
      }

     const checkFields = [
          {field: 'numeroEmpleadoC', title: 'Número de Empleado'}, 
          {field:'nombreC', title:'Nombre'},
          {field:'aPaternoC', title: 'Apellido Paterno'}, 
          {field: 'aMaternoC', title: 'Apellido Materno'},
          {field: 'rfcC', title:'RFC'}, 
          {field:'folioIDC', title: 'Folio ID'},
          {field:'nombreProveedorC', title:'Proveedor'}, 
          {field:'nombreServicioC', title:'Servicio'},
          {field: 'correoC', title: 'Correo'}, 
          {field:'puestoC', title: 'Puesto'}, 
          {field:'fechaIngresoC', title:'Fecha de Ingreso'},
          {field: 'tipoTurnoC', title: 'Tipo de Turno'}, 
          {field: 'diasLaboralesC', title: 'Días Laborales'}, 
          {field: 'horarioEntradaC', title: 'Horario Entrada'}, 
          {field: 'horarioSalidaC', title: 'Horario Salida'}, 
          {field: 'salidaComidaC', title: 'Salida Comida'}, 
          {field: 'regresoComidaC', title: 'Regreso Comida'}, 
          {field: 'expedienteClaveC', title: 'Clave de Expediente'}, 
          {field: 'activoC', title: 'Condición'}, 
          {field: 'razonBajaC', title: 'Razón de Baja'}
     ];

     const SelectSection = () => {
          return (
               <>
                    <Row style={{padding:'1em'}}>
                         <Col span={24}>
                         <Alert message="Selecciona las columnas a incluir en el reporte." type="info" style={{ marginBottom: '16px' }} />
                         </Col>
                    </Row>
                    <Row style={{ width: '100%', textAlign: 'left', marginBottom: '10px', padding:'1em'}}>
                         <Col span={11}>
                              <Checkbox defaultChecked={checkAll}  onChange={selectAll} key='todos'><strong>Todas</strong></Checkbox>
                         </Col>
                    </Row>
                    <Row style={{ width: '100%', textAlign: 'left', padding:'1em'}}>
                         {checkFields.map( item => {
                         return (
                              <Col key={item.field} span={8}>
                                   <Checkbox defaultChecked={checkbox[item.field]} onChange={(event)=>selectHandle(event,item.field)} key={item.field}>{item.title}</Checkbox>
                              </Col>
                         )
                         })}
                    </Row>
                    <Row justify='end' style={{padding:'1em'}}>
                         <Col span={6}>
                              <Button className="btnFiltrar" disabled={!checkArray.length>0} onClick={()=> handleSteps('siguiente')} style={{ width: '100%', marginTop: '20px' }}>
                                   Siguiente
                              </Button>
                         </Col>
                    </Row>
               </>
          )
     }
    
     const dataProps = { checkbox, checkArray, state, order }
     const handleProps = {handleTableChange , handleSteps, handleOrden}
     return (
     <Row gutter={[8,8]}>
          <Col span={24} style={{backgroundColor:'#E4E4E4', borderTopRightRadius:'2em', borderTopLeftRadius:'2em', padding:'1em', textAlign: 'left',}}>
               <Text style={{textAlign: 'left', fontSize: '2em', color: '#737373' }} strong> Reporte Dinámico </Text>
          </Col>

          <Col span={24} style={{paddingTop:'1em'}}>
               <Steps
                    type='navigation'
                    size='small'
                    current={state.currentStep}
                    style={{marginBottom:'20px', paddingTop:'0px'}}
               >
                    <Step status={state.currentStep>0? 'finish':'process'} title='Seleccionar Columnas' />
                    <Step status={state.currentStep>1? 'finish':'process'} title='Filtrar' />
                    <Step status={listaReporteDinamicoRH.length>0? 'finish':'process'} title='Exportar Reporte' />
               </Steps>
               {state.currentStep === 0 ? <SelectSection/> : false}
               {state.currentStep === 1 ? <FilterSection handleSteps={handleSteps} checkboxData={checkbox} order={order} /> : false}
               {state.currentStep === 2 ? <TableSection handlers={handleProps} data={dataProps}/> : false}
               {state.currentStep === 0 ?<RegistroReportes/> : false}
          </Col>
     </Row>
     )
}

const mapStateToProps = state => {
     return {
          listaReporteDinamicoRH: state.RecursosHumanosReducer.listaReporteDinamicoRH,
          formularioReporte: state.RecursosHumanosReducer.formularioReporte,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
     }
}

const mapDispatchToProps = dispatch => {
     return {
          onGetReporteDinamico: (formulario, page, order, checkboxData) => {
               dispatch({ type: 'LISTA_REPORTE_DINAMICO_RH_BUSQUEDA_REQUEST', formulario, page, order, checkboxData});
          },
          onLimpiarFormulario: (formularioReporte) => {
               dispatch({ type: 'LIMPIAR_FORMULARIO_RD', formularioReporte: formularioReporte, listaReporteDinamicoRH: [], listaReporteDinamicoRHCantidad: null})
          },
          onGetReporteDinamicoAgrupado: (formulario, page, agrupado, checkboxData, order) => {
               dispatch({ type: 'REPORTE_DINAMICO_AGRUPADO_RH_REQUEST', formulario, page, agrupado, checkboxData, order});
          },
     }
}

export default connect (mapStateToProps, mapDispatchToProps) (ReporteDinamico);