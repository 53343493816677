import React from 'react';
import {connect} from 'react-redux';
import { Modal, Row, Col, List} from 'antd';
import { FilePdfFilled } from '@ant-design/icons';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

function ModalDetalleFolioEO (props) {
    
    const {folioErrorOmisionSeleccionado, showModalDetalleFolioEO, onShowModalDetalleFolio
    } = props;
    
    let permisosUsuario = sessionStorage.getItem('permisosUsuario');
    if (permisosUsuario[0]) {
        permisosUsuario = JSON.parse(permisosUsuario);
    }
    
    let data=[];
    if (folioErrorOmisionSeleccionado){
        const {folioErrorOmision,tipoFalla,nombreFalla,descripcionDeFalla,nivelSeveridad,ubicacion,servicio,fechaCierreEditar,fechaOmision,evidencia} = folioErrorOmisionSeleccionado;
        const ticket = folioErrorOmisionSeleccionado?.solicitudServicioByIdSolicitudServicio?.folio;

        data = [{title:"Folio Error/Omisión", data:folioErrorOmision},{title:"Número de Ticket",data:ticket},{title:"Tipo de falla",data:tipoFalla},
        {title:"Falla",data:nombreFalla},{title:"Descripcion de falla",data:descripcionDeFalla},{title:"Nivel de Severidad",data:nivelSeveridad}, 
        {title: "Ubicación", data:ubicacion}, {title: "Servicio", data: servicio},{title:"Fecha de omisión", data:fechaOmision.format('YYYY-MM-DD HH:mm')},
        {title:"Fecha de cierre",data: fechaCierreEditar.format('YYYY-MM-DD HH:mm')},{title: "Evidencia", data: evidencia}];
    }

    const handleCancel = () => {
        onShowModalDetalleFolio();
    }

    return (
        <>
        {folioErrorOmisionSeleccionado &&
            <Modal
                visible={showModalDetalleFolioEO}
                title={`Folio de Error u Omisión: ${folioErrorOmisionSeleccionado.folioErrorOmision}`}
                onCancel={handleCancel}
                footer={false}
                width='80%'
            >
                <Row justify='space-around'>
                    <Col span={24}>
                        <List
                        className="listaCatalogos"
                        bordered
                        dataSource={data}
                        renderItem={item => {
                            
                            let tipoFolio = folioErrorOmisionSeleccionado.tipoFolio;
                            if(tipoFolio==='Omision' && (item.title=="Número de Ticket" || item.title=="Nivel de Severidad")) return <></>;
                            if(tipoFolio==='Error' && (item.title=="Fecha de omisión")) return <></>;

                            return (<List.Item className='listaHover'>
                                    <Col span={7}>
                                        <strong>{item.title}</strong>  
                                    </Col>
                                    <Col span={16}>
                                        {item.title!=='Evidencia' ? item.data :
                                        <div disabled={!permisosUsuario.evidenciaDeFolioPorErrorUOmisión}>
                                            <a className='editarIcono' target='_blank' href={item.data}><FilePdfFilled /></a>
                                        </div>
                                        } 
                                    </Col>
                            </List.Item>)
                        }}
                        />
                    </Col>
                </Row>
            </Modal>
        }
    </>
    )
}

const mapStateToProps = state => {
    return {
        folioErrorOmisionSeleccionado: state.MecanismosDePagosReducer.folioErrorOmisionSeleccionado,
        showModalDetalleFolioEO: state.MecanismosDePagosReducer.showModalDetalleFolioEO,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalDetalleFolio: () => {
            dispatch({ type:'MODAL_DETALLE_FOLIO_EO', showModalDetalleFolioEO: false, folioErrorOmisionSeleccionado: null})
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalDetalleFolioEO);