import React, { Component } from 'react';
import { Modal } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import EditarFallaDisponibilidad from './EditarFallaDisponibilidad';
import VerificarContraseña from './VerificarContraseña';
import PorcentajeDeduccion from './PorcentajeDeduccion';
import ArchivoEditarFallaDisponibilidad from './ArchivoEditarFallaDisponibilidad';
import ListaFallasDisponibilidad from './ListaFallasDisponibilidad';

class ModalEditarFallaDeDisponibilidad extends Component {
    formRef = React.createRef();
    render() {
        const { 
               showModalEditarFallasDeDisponibilidad, 
               onshowModalEditarFallasDeDisponibilidad, 
               vistaModalFallaDisponiblidad, 
               onCambiarVista, 
               onLimpiarFormulario,
        } = this.props;

        const handleCancel = () => {
               onshowModalEditarFallasDeDisponibilidad();
               onLimpiarFormulario('')
               onCambiarVista(1);
        }
        function ContenidoEditar() {
            if(vistaModalFallaDisponiblidad === 1){
                return(
                    <EditarFallaDisponibilidad/>
                );
            }
            else if(vistaModalFallaDisponiblidad === 2){
                return(
                    <VerificarContraseña/>
                );
            }
            else if(vistaModalFallaDisponiblidad === 3){
                return(
                     <ListaFallasDisponibilidad/>
                );
            }
            // else if(vistaModalFallaDisponiblidad === 3){
            //     return(
            //          <PorcentajeDeduccion/>
            //     );
            // }
            else if(vistaModalFallaDisponiblidad === 4){
                return(
                    <ArchivoEditarFallaDisponibilidad/>
                );
            }
        };
        
        return (
            <Modal
            visible={showModalEditarFallasDeDisponibilidad}
            title='Editar Categoría'
            onCancel={handleCancel}
            footer={false}
            >
                <ContenidoEditar/>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
          showModalEditarFallasDeDisponibilidad: state.MecanismosDePagosReducer.showModalEditarFallasDeDisponibilidad,
          vistaModalFallaDisponiblidad: state.MecanismosDePagosReducer.vistaModalFallaDisponiblidad,
	};
};

const mapDispatchToProps = dispatch => {
	return {
          onshowModalEditarFallasDeDisponibilidad: () => {
               dispatch({ type: 'SHOW_MODAL_EDITAR_FALLA_DISPONIBILIDAD', showModalEditarFallasDeDisponibilidad: false, page: null })
          },
          onLimpiarFormulario: (limpiarFormulario) => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: limpiarFormulario });
          },
          onCambiarVista: (vistaModalFallaDisponiblidad) => {
               dispatch({ type: 'CAMBIAR_VISTA_FALLA_DISPONIBILIDAD', vistaModalFallaDisponiblidad: vistaModalFallaDisponiblidad, formularioDisponibilidad: null, fallaDisponibilidadEditados: null})
          }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarFallaDeDisponibilidad);