import React from 'react';
import { Row, Col, Modal, List, Button, Spin, Card, Table,  } from 'antd';
import { connect } from "react-redux";
import { FilePdfOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

class Detalle extends React.Component {
     formRef = React.createRef();
     state = {
          page: 1,
     }
     render() {
          const { 
               onCloseModal,
               showModalDetalleSS, htmlSS,
               solicitudDeServicioSeleccionado,
               onDescargar, listaTrazabilidad, fetchingListaTrazabilidad, listaTrazabilidadCantidad,
               fetchingExportarSolicitudDeServicio, onGetTrazabilidadFolio,
          } = this.props;

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
               permisosUsuario = JSON.parse(permisosUsuario);
          }

          const handleCancel = () => {
               onCloseModal(false, null);
          };

          let data=[]
          if (solicitudDeServicioSeleccionado){
               const {
                    nombreFalla,
                    fechaAlta,
                    fechaCompromiso,
                    tiempoCaptura,
                    usuarioRegistra,
                    usuarioReporta,
               } = solicitudDeServicioSeleccionado;
               
               data = [
                    {title: "Falla", data: nombreFalla},
                    {title: 'Tiempo de Captura', data: tiempoCaptura},
                    {title: "Fecha Alta", data: fechaAlta},
                    {title: "Fecha Compromiso", data: fechaCompromiso},
                    {title: "Usuario Reporta", data: usuarioReporta},
                    {title: "Usuario Registra", data: usuarioRegistra},
               ];
          }

          const onDescarga = () => {
               onDescargar(solicitudDeServicioSeleccionado.folio, this.props.page, solicitudDeServicioSeleccionado);
          };

          const handleTableChange = (pagination) => {
               onGetTrazabilidadFolio(solicitudDeServicioSeleccionado.folio, pagination.current);
          }

          const columns = [{
               title: 'Fecha de estado',
               dataIndex: 'fechaEstado',
               key: 'fechaEstado',
               align: 'center'
          },{
               title: 'Estado',
               dataIndex: 'estado',
               key: 'estado',
               align: 'center'
          },{
               title: 'Descripción',
               dataIndex: 'descripcion',
               key: 'descripcion',
               align: 'center'
          },{
               title: 'Fecha de actualización',
               dataIndex: 'fechaActualizacion',
               key: 'fechaActualizacion',
               align: 'center'
          },{
               title: 'Usuario que registra',
               dataIndex: 'username',
               key: 'username',
               align: 'center'
          }];
          const scroll = {"x": 700 | true, "y": 1000};

          return (
               <>
                    { solicitudDeServicioSeleccionado ? (
                         <Modal
                              visible={showModalDetalleSS}
                              title={'Detalle de la Solicitud de Servicio: ' + solicitudDeServicioSeleccionado.folio}
                              onCancel={handleCancel}
                              footer={false}
                              width={'90%'}
                         >
                              <Spin spinning={fetchingExportarSolicitudDeServicio}>
                                   <Row justify='space-around'>
                                        <Col span={9}>
                                             {/* <Divider orientation="left">{variableMedicionSeleccionado.nombre}</Divider> */}
                                             <List
                                             className="listaCatalogos"
                                             bordered={true}
                                             dataSource={data}
                                             renderItem={item => (
                                            <List.Item className='listaHover'>
                                                  <Col span={11}>
                                                       <strong>{item.title}</strong>  
                                                  </Col>
                                                  <Col span={11}>
                                                       {item.data !==null ? item.data : '-'} 
                                                  </Col>
                                             </List.Item>
                                             )}
                                             />
                                             <br/>
                                             <Button disabled={permisosUsuario.especialSolicitudDeServicio ? false: !permisosUsuario.descargarSolicitudDeServicio ? true : !solicitudDeServicioSeleccionado.impreso} className='btnCargaArchivos' style={{ width: '100%' }} onClick={onDescarga}>
                                                  Descargar Solicitud de Servicio <FilePdfOutlined /> 
                                             </Button>
                                        </Col>
                                        
                                        <Col span={13}>
                                             <Card className="cardCatalogos" >
                                                  <Table
                                                       size="small"
                                                       rowKey="idTrazabilidadFolio"
                                                       columns={columns}
                                                       dataSource={listaTrazabilidad}
                                                       loading={fetchingListaTrazabilidad}
                                                       onChange={handleTableChange}
                                                       locale={{ emptyText: 'Sin datos' }}
                                                       scroll={scroll}
                                                       pagination={{ total: listaTrazabilidadCantidad, simple: true }}
                                                  />
                                             </Card>
                                        </Col>
                                   </Row>    
                              </Spin>     
                         </Modal>
                    ) : false }
               </>
          );             
     }
}

const mapStateToProps = state => {
     return {
          showModalDetalleSS: state.SolicitudDeServiciosReducer.showModalDetalleSS,
          solicitudDeServicioSeleccionado: state.SolicitudDeServiciosReducer.solicitudDeServicioSeleccionado,
          fetchingExportarSolicitudDeServicio: state.SolicitudDeServiciosReducer.fetchingExportarSolicitudDeServicio,
          page: state.SolicitudDeServiciosReducer.page,
          listaTrazabilidad: state.SolicitudDeServiciosReducer.listaTrazabilidad,
          fetchingListaTrazabilidad: state.SolicitudDeServiciosReducer.fetchingListaTrazabilidad,
          listaTrazabilidadCantidad: state.SolicitudDeServiciosReducer.listaTrazabilidadCantidad,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onCloseModal: (showModalDetalleSS, solicitudDeServicioSeleccionado ) => {
               dispatch({ type: 'SHOW_MODAL_DETALLE_SS', showModalDetalleSS, solicitudDeServicioSeleccionado });
          },
          onDescargar: (folio, page, solicitudDeServicioSeleccionado) => {
               dispatch({ type: 'EXPORTAR_SOLICITUD_SERVICIO_REQUEST', folio, page, solicitudDeServicioSeleccionado });
          },
          onGetTrazabilidadFolio: (folio, page) => {
               dispatch({ type: "OBTENER_TRAZABILIDAD_FOLIO_REQUEST", folio, page});
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(Detalle);