import React from 'react';
import { Row, Col, Divider, Modal, Table,  } from 'antd';
import { connect } from "react-redux";
import { CalendarOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import moment from 'moment';
import ModalFecha from './ModalFecha';

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };

class ModalDetalleUbicacion extends React.Component {
     formRef = React.createRef();

     componentDidMount() {
          
     }

     state = {
          page: 1,
          value: moment('2020-12-10'),
          selectedValue: moment('2020-12-10'),
     }


     render() {
          const { 
               onCloseModalDetalle,
               showModalDetalleUbicacionRMP,
               programaRMPSeleccionado,
               programa,
               onShowFechas,
               busqueda,
               page,
          } = this.props;

          const handleCancel = () => {
               onCloseModalDetalle();
          };

          let dataUbi = [];
          let dataAct = [];
          let columnsUbi = [];
          let columnsAct = [];
          if (programaRMPSeleccionado){
               console.log('programaRMPSeleccionado', programaRMPSeleccionado);

               if(programa === 'Rutina' ){
                    let { asignacionByIdAsignacionServicioPlanificado, programacionRutinasByIdProgramacionRutina } = programaRMPSeleccionado;

                    if(!programaRMPSeleccionado.fechaEspecifica){
                         if(programaRMPSeleccionado.estadoRutina === 'Programada'){
                              for(let i = 0; i < asignacionByIdAsignacionServicioPlanificado.length; i++){
                                   for(let j = 0; j < programacionRutinasByIdProgramacionRutina.length; j++){
                                        if(asignacionByIdAsignacionServicioPlanificado[i].idAsignacionServicioPlanificado === programacionRutinasByIdProgramacionRutina[j].idAsignacionServicioPlanificado){
                                             asignacionByIdAsignacionServicioPlanificado[i].hora = programacionRutinasByIdProgramacionRutina[j].hora;

                                             let fechaInicial = (programacionRutinasByIdProgramacionRutina[j].fechaInicial).split('T')[0];

                                             asignacionByIdAsignacionServicioPlanificado[i].fechaInicial = fechaInicial.split('-')[2] + '-' + fechaInicial.split('-')[1] + '-' + fechaInicial.split('-')[0];

                                             asignacionByIdAsignacionServicioPlanificado[i].dias = programaRMPSeleccionado.dias;
                                        }
                                   }
                              }

                              columnsUbi = [
                                   {title: 'Ubicación',dataIndex: 'ubicacionCOA',key: 'ubicacionCOA',align: 'left', width: '51%'},
                                   {title: 'Fecha Inicial',dataIndex: 'fechaInicial',key: 'fechaInicial',align: 'center', width: '13%'},
                                   {title: 'Frecuencia',dataIndex: 'dias', key: 'dias',align: 'center', width: '13%'},
                                   {title: 'Propuesta',dataIndex: 'hora',key: 'hora',align: 'center', width: '13%'},
                                   { title: 'Fechas', dataIndex: '', key: '', align: 'center', width: '10',
                                   render: (record) => (
                                        <a className='editarIcono' onClick={() => onFechas(record)}><CalendarOutlined /></a>
                                   ),},
                              ];
                         } else {
                              columnsUbi = [
                                   {title: 'Ubicación',dataIndex: 'ubicacionCOA',key: 'ubicacionCOA',align: 'left', width: '100%'},
                              ];
                         }
                    } else {
                         if(programaRMPSeleccionado.estadoRutina === 'Programada'){
                              for(let i = 0; i < asignacionByIdAsignacionServicioPlanificado.length; i++){
                                   for(let j = 0; j < programacionRutinasByIdProgramacionRutina.length; j++){
                                        if(asignacionByIdAsignacionServicioPlanificado[i].idAsignacionServicioPlanificado === programacionRutinasByIdProgramacionRutina[j].idAsignacionServicioPlanificado){
                                             asignacionByIdAsignacionServicioPlanificado[i].hora = programacionRutinasByIdProgramacionRutina[j].hora;
                                        }
                                   }
                              }

                              columnsUbi = [
                                   {title: 'Ubicación',dataIndex: 'ubicacionCOA',key: 'ubicacionCOA',align: 'left',},
                                   {title: 'Propuesta',dataIndex: 'hora',key: 'hora',align: 'center', },
                                   { title: 'Fechas', dataIndex: '', key: '', align: 'center', 
                                   render: (record) => (
                                        <a className='editarIcono' onClick={() => onFechas(record)}><CalendarOutlined /></a>
                                   ),},
                              ];

                         } else {
                              columnsUbi = [
                                   {title: 'Ubicación',dataIndex: 'ubicacionCOA',key: 'ubicacionCOA',align: 'left', width: '100%'},
                              ];
                         }
                    }
                    dataUbi = [
                         {title: 'Ubicacion', data: asignacionByIdAsignacionServicioPlanificado},
                         {title: 'Programacion', data: programacionRutinasByIdProgramacionRutina}
                    ];
                    console.log('dataUbi', dataUbi)
               } else {
                    if(!programaRMPSeleccionado.fechaEspecifica){
                         if(programaRMPSeleccionado.estadoRutina === 'Programada'){
                              if(programaRMPSeleccionado.asignacionByIdAsignacionServicioPlanificado){
                                   const {asignacionByIdAsignacionServicioPlanificado, programacionRutinasByIdProgramacionRutina} = programaRMPSeleccionado;

                                   for(let i = 0; i < asignacionByIdAsignacionServicioPlanificado.length; i++){
                                        if(asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo){
                                             asignacionByIdAsignacionServicioPlanificado[i].serieNum = asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.serieNum;

                                             // idEstadoActivo = 'En operación'
                                             asignacionByIdAsignacionServicioPlanificado[i].idEstadoActivo = asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.idEstadoActivo;

                                             if(asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.activoCaractByIdActivoCaract){
                                                  asignacionByIdAsignacionServicioPlanificado[i].descripcion = asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.activoCaractByIdActivoCaract.productoByIdProducto?.nombre;
                                             }
                                        }

                                        if(asignacionByIdAsignacionServicioPlanificado[i].idEstadoActivo === 1){
                                             dataAct.push(asignacionByIdAsignacionServicioPlanificado[i]);
                                        }
                                   }

                                   for(let i = 0; i < asignacionByIdAsignacionServicioPlanificado.length; i++){
                                        for(let j = 0; j < programacionRutinasByIdProgramacionRutina.length; j++){
                                             if(asignacionByIdAsignacionServicioPlanificado[i].idAsignacionServicioPlanificado === programacionRutinasByIdProgramacionRutina[j].idAsignacionServicioPlanificado){
                                                  asignacionByIdAsignacionServicioPlanificado[i].hora = programacionRutinasByIdProgramacionRutina[j].hora;
     
                                                  let fechaInicial = (programacionRutinasByIdProgramacionRutina[j].fechaInicial).split('T')[0];
     
                                                  asignacionByIdAsignacionServicioPlanificado[i].fechaInicial = fechaInicial.split('-')[2] + '-' + fechaInicial.split('-')[1] + '-' + fechaInicial.split('-')[0];

                                                  asignacionByIdAsignacionServicioPlanificado[i].dias = programaRMPSeleccionado.dias;
                                             }
                                        }
                                   }

                                   dataAct = [
                                        {title: 'Activo', data: asignacionByIdAsignacionServicioPlanificado},
                                   ];

                                   columnsAct = [
                                        {title: 'Descripción',dataIndex: 'descripcion',key: 'descripcion',align: 'center', width: '35%'},
                                        {title: 'Número de Serie',dataIndex: 'serieNum',key: 'serieNum',align: 'center', width: '13%'},
                                        {title: 'Fecha Inicial',dataIndex: 'fechaInicial',key: 'fechaInicial',align: 'center', width: '15%'},
                                        {title: 'Código Interno', dataIndex: 'codigoInterno',key: 'codigoInterno',align: 'center', width: '10%'},
                                        {title: 'Ubicación del Activo', dataIndex: 'ubicacionCOA',key: 'ubicacionCOA',align: 'center', width: '40%'},
                                        {title: 'Frecuencia',dataIndex: 'dias',key: 'dias',align: 'center', width: '10%'},
                                        {title: 'Propuesta',dataIndex: 'hora',key: 'hora',align: 'center', width: '10%'},
                                        { title: 'Fechas', dataIndex: '', key: '', align: 'center', width: '7%',
                                        render: (record) => (
                                             <a className='editarIcono' onClick={() => onFechas(record)}><CalendarOutlined /></a>
                                        ),},
                                   ];
                              }
                         } else {
                              if(programaRMPSeleccionado.asignacionByIdAsignacionServicioPlanificado){
                                   const {asignacionByIdAsignacionServicioPlanificado} = programaRMPSeleccionado;

                                   for(let i = 0; i < asignacionByIdAsignacionServicioPlanificado.length; i++){
                                        if(asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo){
                                             asignacionByIdAsignacionServicioPlanificado[i].idEstadoActivo = asignacionByIdAsignacionServicioPlanificado[i].activoByIdActivo.idEstadoActivo;
                                             if(asignacionByIdAsignacionServicioPlanificado[i].idEstadoActivo === 1){
                                                  dataAct.push(asignacionByIdAsignacionServicioPlanificado[i]);
                                             }
                                        }
                                   }

                                   dataAct = [
                                        {title: 'Activo', data: asignacionByIdAsignacionServicioPlanificado},
                                   ];

                                   columnsAct = [
                                        {title: 'Descripción',dataIndex: 'descripcion',key: 'descripcion',align: 'center', width: '30%'},
                                        {title: 'Número de Serie',dataIndex: 'serieNum',key: 'serieNum',align: 'center', width: '10%'},
                                        {title: 'Código Interno', dataIndex: 'codigoInterno',key: 'codigoInterno',align: 'center', width: '10%'},
                                        {title: 'Ubicación', dataIndex: 'ubicacionCOA',key: 'ubicacionCOA',align: 'center', width: '40%'},
                                   ];
                              }
                         }
                    } else {
                         const {asignacionByIdAsignacionServicioPlanificado, programacionRutinasByIdProgramacionRutina} = programaRMPSeleccionado;

                         if(programaRMPSeleccionado.estadoRutina === 'Programada'){

                              for(let i = 0; i < asignacionByIdAsignacionServicioPlanificado.length; i++){
                                   for(let j = 0; j < programacionRutinasByIdProgramacionRutina.length; j++){
                                        if(asignacionByIdAsignacionServicioPlanificado[i].idAsignacionServicioPlanificado === programacionRutinasByIdProgramacionRutina[j].idAsignacionServicioPlanificado){
                                             asignacionByIdAsignacionServicioPlanificado[i].hora = programacionRutinasByIdProgramacionRutina[j].hora;
                                        }
                                   }
                              }

                              columnsAct = [
                                   {title: 'Descripción',dataIndex: 'descripcion',key: 'descripcion',align: 'center', width: '20%'},
                                   {title: 'SKU',dataIndex: 'sku',key: 'sku',align: 'center', width: '10%'},
                                   {title: 'Código Interno', dataIndex: 'codigoInterno',key: 'codigoInterno',align: 'center', width: '10%'},
                                   {title: 'Ubicación del Activo', dataIndex: 'ubicacionCOA',key: 'ubicacionCOA',align: 'center', width: '30%'},
                                   {title: 'Propuesta',dataIndex: 'hora',key: 'hora',align: 'center', width: '10%'},
                                   { title: 'Fechas', dataIndex: '', key: '', align: 'center', width: '10%',
                                   render: (record) => (
                                        <a className='editarIcono' onClick={() => onFechas(record)}><CalendarOutlined /></a>
                                   ),},
                              ];

                         } else {
                              columnsAct = [
                                   {title: 'Descripción',dataIndex: 'descripcion',key: 'descripcion',align: 'center', width: '30%'},
                                   {title: 'SKU',dataIndex: 'sku',key: 'sku',align: 'center', width: '10%'},
                                   {title: 'Código Interno', dataIndex: 'codigoInterno',key: 'codigoInterno',align: 'center', width: '10%'},
                                   {title: 'Ubicación', dataIndex: 'ubicacionCOA',key: 'ubicacionCOA',align: 'center', width: '50%'},
                              ];
                         }

                         dataAct = [
                              {title: 'Activo', data: asignacionByIdAsignacionServicioPlanificado},
                         ];
                    }
               }
          }

          const onFechas = (key) => {
               console.log('key en onFechas', key)
               onShowFechas(key, programaRMPSeleccionado, programa, page, busqueda, 'detalle');
          };

          return (
               <Modal
                    visible={showModalDetalleUbicacionRMP}
                    title={'Detalle de Programa'}
                    onCancel={handleCancel}
                    footer={false}
                    width={'70%'}
               >
                    { programaRMPSeleccionado ? (
                         <Row justify='space-around'>
                              { dataUbi[0] ? (<>
                                   { programa === 'Rutina' ? ( <> 
                                        <Col span={24}>
                                             <Table
                                             size='small'
                                             rowKey='idAsignacionServicioPlanificado'
                                             columns={columnsUbi}
                                             dataSource={dataUbi[0].data}
                                             locale={{ emptyText: 'Sin datos' }}
                                             pagination={{
                                                  total: dataUbi[0].data.length,
                                                  pageSize: dataUbi[0].data.length,
                                                  hideOnSinglePage: true
                                                  }}
                                                  scroll={{ x: 1500, y: 450 }}
                                             />
                                        </Col>
                                   </>) : false }
                              </>) : false }
                              { dataAct[0] ? (<>
                                   { programa !== 'Rutina' ? ( <> 
                                        <Col span={24}>
                                             <Table
                                                  size='small'
                                                  rowKey='idAsignacionServicioPlanificado'
                                                  columns={columnsAct}
                                                  dataSource={dataAct[0].data}
                                                  locale={{ emptyText: 'Sin datos' }}
                                                  pagination={{
                                                       total: dataAct[0].data.length,
                                                       pageSize: dataAct[0].data.length,
                                                       hideOnSinglePage: true
                                                       }}
                                                       scroll={{ x: 1500, y: 450 }}
                                             />
                                        </Col>     

                                   </>) : false }
                              </>): false }
                         </Row>
                    ) : false }     
                    {/* <ModalFecha />            */}
               </Modal>
          );             
     }
}

const mapStateToProps = state => {
     return {
          showModalDetalleUbicacionRMP: state.ServiciosPlanificadosReducer.showModalDetalleUbicacionRMP,
          programaRMPSeleccionado: state.ServiciosPlanificadosReducer.programaRMPSeleccionado,
          programa: state.ServiciosPlanificadosReducer.programa,
          page: state.ServiciosPlanificadosReducer.page,
          busqueda: state.ServiciosPlanificadosReducer.busqueda,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onCloseModalDetalle: () => {
               dispatch({ type: 'SHOW_MODAL_DETALLE_UBICACION_RMP', showModalDetalleUbicacionRMP: false });
          },
          onShowFechas: (asignacionRMPSeleccionada,programaRMPSeleccionado, programa, page, busqueda, typeModal) => {
               dispatch({ type: 'SHOW_MODAL_FECHAS_RMP', showModalFechaRMP: true, asignacionRMPSeleccionada,  programaRMPSeleccionado, programa, page, busqueda, typeModal });
          }
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetalleUbicacion);