import React, { Component } from 'react';
import {Row, Col, Modal, Select, DatePicker, Divider, Form, Button, InputNumber, message, Spin, Upload, Tooltip, Typography, Table, Input, Steps} from 'antd';
import { MinusCircleOutlined, PlusOutlined , UploadOutlined, ArrowLeftOutlined, ArrowRightOutlined, EditOutlined, CheckCircleOutlined, ExclamationCircleOutlined, FilePdfFilled} from '@ant-design/icons';
import { connect } from "react-redux";
import moment from 'moment';

import ModalModificarRegistro from './ModalModificarRegistro';

const { Search } = Input;
const { Dragger } = Upload;
const { Option } = Select;
const {Text} = Typography;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Step } = Steps;

class ModalNuevoFolio extends Component {
    formRef = React.createRef();
    componentDidMount(){
        this.props.onGetServiciosAct();
        this.props.onGetAlmacenes();
        this.props.onGetProductos(1);
        this.props.onGetSubfamilias();
        this.props.onRequestInfoProyecto();
    }
    state = {
        idServicio: null,
        idAlmacen: null,
        filePDF: false,
        botonPDF: false,
        tipoMonedaSeleccionada: null,
        page: 1,
        selectedRowKeys: [], // Check here to configure the default column
        selectedRows: [],
        currentStep: 0,
        fileList: [],
        filteredData: [],
        idSubFamilia: '',
        busqueda: ''
    }

    componentDidUpdate(prevProps){
        // console.log('prevProps', prevProps);
        if(prevProps.listaSeleccionados !== this.props.listaSeleccionados){
            this.setState({filteredData: this.props.listaSeleccionados});
        }
    }

    render() { 
        const {
            showModalNuevoFolioVC,
            onCloseModal,

            listaServiciosActivosF,
            fetchingListaServiciosActivosF,

            onListaProductosActivosBusqueda, 
            onListaProductosActivosBusquedaLimpiar, 
            listaSinRepetir,
            fetchingListaProductosActivosBusqueda,
            listaProductosActivosBusqueda,

            onActualizarListaSinRepetir,

            onGetUbicacion,
            fetchingListaAlmacenesActivos,
            listaAlmacenesActivos,
            fetchingObtenerUbicacion,
            listaUbicaciones,
            fetchingNuevoFolioVC,
            onCrearFolio,
            page, 
            busqueda,
            limpiarFormulario,

            selectedRowKeys,
            listaInfoProyecto
        } = this.props;

        const onFinish = (formulario) => {
            console.log(formulario, page, busqueda );

            // if(formulario.insumos === undefined){
            //     message.warning('Seleccionar un producto para Ingreso de almacen')
                
            // } else {
            //     onCrearFolio(formulario, page, busqueda, this.props.listaSeleccionados)
            //     onActualizarListaSinRepetir();
            // } 
            let bandera = true;

            this.props.listaSeleccionados.forEach(element => {
                if(!element.cantidad){
                    bandera = false;
                }
            });

            if(bandera){
                onCrearFolio(formulario, page, busqueda, this.props.listaSeleccionados)
                onActualizarListaSinRepetir();
                this.setState({currentStep: 0, selectedRowKeys: [], selectedRows: [], filePDF: false, botonPDF: false});
                this.props.onCleanSelected();
            } else {
                message.warning('Se debe contar con un registro de todos los productos seleccionados')
            }
            
        };

        if (limpiarFormulario && limpiarFormulario==='crearEVDC') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        const handleCancel = () => {
            onCloseModal();
            onListaProductosActivosBusquedaLimpiar();
            onActualizarListaSinRepetir();
            this.formRef.current.resetFields();
            this.setState({
                idServicio: null,
                idAlmacen: null,
                fechaIngreso: null,
                currentStep: 0, selectedRowKeys: [], selectedRows: [], filePDF: false, botonPDF: false, page: 1,
            });
            this.props.onCleanSelected();
        };

        const buscarActivoCarac = (value) => {
            if(value.length>=3){
                console.log(value)
                onListaProductosActivosBusqueda(value);
            }
            else{
                onListaProductosActivosBusquedaLimpiar();
            }
        };

        const onProductoSeleccionado = (value, field) => {
            console.log(this.formRef, value, field)
            let formulario = this.formRef.current.getFieldsValue('insumos');
            console.log(value, field, formulario, listaSinRepetir)
            if(value.split(' - ')[5] === 'false'){
                
                formulario.insumos[field.key].validarLote = false;
                this.formRef.current.setFieldsValue({'insumos': formulario.insumos});
            } else {
                if (formulario.insumos[field.key]) formulario.insumos[field.key].validarLote = true;
                this.formRef.current.setFieldsValue({'insumos': formulario.insumos});
            }

            console.log(' formulario ',this.formRef.current.getFieldsValue('insumos'))
            let repetido = false;
            if(listaSinRepetir){
                for(let i=0; i<listaSinRepetir.length; i++){
                    if(listaSinRepetir[i] === value){
                        if(listaSinRepetir[field.key]){
                            console.log('entra al if')
                            formulario.insumos[field.key].insumo = listaSinRepetir[field.key];
                            message.warning('No es posible seleccionar un producto que ya esté en la lista');
                            this.formRef.current.setFieldsValue({'insumos': formulario.insumos});
                            repetido = true;
                        }
                        else{
                            
                        }
                        formulario.insumos[field.name].insumo = null;
                        message.warning('No es posible seleccionar un producto que ya esté en la lista');
                        this.formRef.current.setFieldsValue({'insumos': formulario.insumos});
                        repetido = true;
                        break;
                    }
                }
            }
            
            if(!repetido){
                let listaActualizada = [];
                for(let x=0; x<formulario.insumos.length; x++){
                    listaActualizada.push(formulario.insumos[x]?.insumo ?? 'undefined'); 
                }
                onActualizarListaSinRepetir(listaActualizada)
            }
        }

        const onChangeAlmacen = (value) => {
            if(value){ onGetUbicacion(parseInt(value)); } else { this.props.onCleanUbis(); }
        };

        const onChangePDF = (info) => {
            let fileList = [...info.fileList];

            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            fileList = fileList.slice(-1);

            console.log('fileList', fileList);

            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }

            else{
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                    fileList
                });
            }
            var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
            if (info.file.status === 'done') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                    this.setState({fileList: []});
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true,
                        fileList
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                    this.setState({fileList: []});
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true,
                        fileList
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
             }
        }

        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
                fileList: []
            });
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        }; 

        const  disabledDate = (fecha) => {
            // Can not select days before today and today
            // return current && current < moment().subtract(1, 'd');

            if( fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicioOperacion) {
                return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicioOperacion;
            } else {
                if(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos){
                return fecha && moment(fecha.format('YYYY-MM-DD')) > moment((listaInfoProyecto.vigencia +  parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2])) + '-' +listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[1] + '-' + listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[0]);
            }
            }
        }

        const columns = [
            {
                title: 'Código de almacén',
                dataIndex: 'codigoAdicional',
                key: 'codigoAdicional',
                align: 'center'
            },{
                title: 'Nombre del producto',
                dataIndex: 'nombre',
                key: 'nombre',
                align: 'center'
            }, {
                title: 'Clave del producto',
                dataIndex: 'clave',
                key: 'clave',
                align: 'center'
            },{
                title: 'SKU',
                dataIndex: 'sku',
                key: 'sku',
                align: 'center'
            },{
                title: 'Presentación',
                dataIndex: 'presentacion',
                key: 'presentacion',
                align: 'center',
            },
            // {
            //     title: 'Tipo moneda',
            //     dataIndex: 'tipoMoneda',
            //     key: 'tipoMoneda',
            //     align: 'center',
            //     editable: true,
            // },{
            //     title: 'Tipo cambio',
            //     dataIndex: 'tipoCambio',
            //     key: 'tipoCambio',
            //     align: 'center',
            //     editable: true,
            // },
            // {
            //     title: 'Cantidad',
            //     dataIndex: 'cantidad',
            //     key: 'cantidad',
            //     align: 'center',
            //     editable: true,
            // },{
            //     title: 'Lote',
            //     dataIndex: 'lote',
            //     key: 'lote',
            //     align: 'center',
            //     editable: true,
            // },
            // {
            //     title: 'Fecha caducidad',
            //     dataIndex: 'fechaCaducidad',
            //     key: 'fechaCaducidad',
            //     align: 'center',
            //     editable: true,
            // },
        ];

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });

            this.props.onGetProductos(pagination.current);
        };

        const onChangeTabla = (record, selected, selectedRows) => {
            console.log('record: ', record);
            console.log('selected: ', selected);

            let listaSelected = this.props.listaSeleccionados;
            const listaFinal = [];
            if(!listaSelected){
                listaSelected = [];
            }
            
            if(selected.length){
                for(let i in selected){
                    if(selected[i]){
                        selected[i].agregado = false;
                    }
                }
                for(let i in selected){
                    if(listaSelected.length){
                        for(let x in listaSelected){
                            if(selected[i]){
                                if(!selected[i].agregado){
                                    if(listaSelected[x].rowKey === record[i]){
                                        listaFinal.push(listaSelected[x]);
                                        selected[i].agregado = true;
                                    }
                                    else{
                                        listaFinal.push(selected[i]);
                                        selected[i].agregado = true;
                                    }
                                }
                            }
                            else{
                                if(listaSelected[x].rowKey === record[i]){
                                    listaFinal.push(listaSelected[x]);
                                }
                            }
                        }
                    }
                    else{
                        listaFinal.push(selected[i]);
                    }
                }
            }
            console.log('listaFinal');
            console.log(listaFinal);
            
            let hasSelectedLocal = true
            
            if(record.length !== 0){
                hasSelectedLocal = false;
            }
            
            this.setState({ selectedRowKeys: record, selectedRows: selected });
            this.props.onActualizarTabla( listaFinal, record, null, hasSelectedLocal );
        };
       
      
        const rowSelection = {
            selectedRowKeys,
            onChange: onChangeTabla,
        };

        const onEditar=key=> {
            console.log('key', key);
            this.props.onShowModalModificar(key);
            onCloseModal();
        };

        const columnsSelected = [{
            title: 'Código de almacén',
            dataIndex: 'codigoAdicional',
            key: 'codigoAdicional',
            align: 'center'
        },{
            title: 'Nombre del producto',
            dataIndex: 'nombre',
            key: 'nombre',
            align: 'center',
        }, {
            title: 'Clave del producto',
            dataIndex: 'clave',
            key: 'clave',
            align: 'center',
        }, {
            title: 'Número de serie',
            dataIndex: 'serieNum',
            key: 'serieNum',
            align: 'center',
            render: (text, record) => (
                record.subFamiliaByIdSubFamilia.familiaByIdFamilia ? (record.subFamiliaByIdSubFamilia.familiaByIdFamilia.tipoFamilia === 'Activos' ? text : 'No aplica') : (record.subFamiliaByIdSubFamilia.familiaByIdFamilia.tipoFamilia === 'Activos' ? text : 'No aplica')
            ),
        }, {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
            align: 'center',
        // },{
        //     title: 'Tipo moneda',
        //     dataIndex: 'tipoMoneda',
        //     key: 'tipoMoneda',
        //     align: 'center',
        },{
            title: 'Costo unitario',
            dataIndex: 'precioTabla',
            key: 'precioTabla',
            align: 'right',
        },{
            title: 'Tipo cambio',
            dataIndex: 'tipoCambio',
            key: 'tipoCambio',
            align: 'right',
        },{
            title: 'Cantidad   ',
            dataIndex: 'cantidad',
            key: 'cantidad',
            align: 'right',
        },{
            title: 'Costo total',
            dataIndex: 'costoTotal',
            key: 'costoTotal',
            align: 'right',
        },{
            title: 'Lote',
            dataIndex: 'lote',
            key: 'lote',
            align: 'center',
        },{
            title: ' Modificar ',
            key: 'editar',
            align: 'center',
            render: (text, record) => (
                <a className='editarIcono'  onClick={() => onEditar(record)}><EditOutlined /></a>
            ),
        }];

        console.log('listaSeleccionados', this.props.listaSeleccionados);
        console.log('filteredData', this.state.filteredData)

        const onBuscarProducto = (busqueda, name) => {
            this.setState({
                [name]: busqueda,
                page: 1,
            });
            if(name === 'busqueda'){
                this.props.onGetProductos(1, busqueda, this.state.idSubFamilia)
            } else {
                this.props.onGetProductos(1, this.state.busqueda, busqueda)
            }
        };

        // const onSourceChange = () => {
        //     this.setState({filteredData: this.props.listaSeleccionados})
        // }

        return ( 
            <div>
                <Modal
                    visible={showModalNuevoFolioVC}
                    title='Nuevo Folio'
                    onCancel={handleCancel}
                    footer={false}
                    width={'90%'}
                >
                    <Row justify='space-between'>
                        <Col span={24}>
                            <Spin spinning={fetchingNuevoFolioVC}>    
                                <Form {...layout}
                                    name="formulario"
                                    onFinish={onFinish}
                                    ref={this.formRef}
                                >
                                    <Row justify='space-between' gutter={[8,8]}>
                                        <Col span={10}>
                                            <Form.Item
                                                label='Servicio'
                                                name='idServicio'
                                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                            >
                                                <Select
                                                    allowClear
                                                    style={{ width: '100%' }}
                                                    placeholder="Selecciona el servicio"
                                                    loading={fetchingListaServiciosActivosF}
                                                    showSearch
                                                >
                                                    {listaServiciosActivosF.map(option => <Option key={ option.idServicio + ' - ' + option.nombre + ': ' + option.nombreC }>{ option.idServicio + ' - ' + option.nombre + ': ' + option.nombreC }</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={6}>
                                            <Form.Item
                                                label='Almacen'
                                                name='idAlmacen'
                                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                            >
                                                <Select 
                                                    allowClear
                                                    style={{ width: '100%' }}
                                                    placeholder="Seleccione Almacen"
                                                    loading={fetchingListaAlmacenesActivos}
                                                    onChange={onChangeAlmacen}
                                                    showSearch
                                                >
                                                    {listaAlmacenesActivos.map(option => <Option key={option.idAlmacen + ' - ' + option.nombreC}>{ option.idAlmacen + ' - ' + option.nombreC }</Option>)}
                                                </Select>
                                            </Form.Item>    
                                        </Col>

                                        <Col span={6}>
                                            <Form.Item
                                                label='Fecha Real del Registro'
                                                name='fechaIngreso'
                                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                            >
                                            <DatePicker
                                                style={{ width: '100%' }}
                                                format='DD-MM-YYYY'
                                                disabledDate={disabledDate}
                                            />
                                            </Form.Item>
                                        </Col>
                                    
                                
                                    {/* <Form.List name="insumos">
                                        {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(field => (
                                            <Row justify='space-between' gutter={[8,8]}>
                                                <Col span={23}>
                                                    <Row justify='start' key={field.key} gutter={[8,8]}>
                                                        <Col span={7}>
                                                            
                                                            <Form.Item
                                                                {...field}
                                                                label='Producto'
                                                                name={[field.name, 'insumo']}
                                                                fieldKey={[field.fieldKey, 'insumo']}
                                                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                            >
                                                                <Select
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Ingresa nombre, clave o SKU del producto"
                                                                    loading={fetchingListaProductosActivosBusqueda}
                                                                    showSearch
                                                                    filterOption={false}
                                                                    onSearch={buscarActivoCarac}
                                                                    onChange={(value) => onProductoSeleccionado(value, field)}
                                                                    notFoundContent={null}
                                                                >
                                                                    {listaProductosActivosBusqueda.map(option => 
                                                                        <Option key={option.idProducto + ' - ' + option.descripcion + ' - sku: ' + option.sku + ' - Unidad de medida: ' + option.unidadMedida + ' - ' + option.idUnidadMedida + ' - ' + option.caracteristicaLote}>
                                                                            <Tooltip placement="topLeft" title={option.idProducto + ' - ' + option.descripcion + ' - sku: ' + option.sku + ' - Unidad de medida: ' + option.unidadMedida}>
                                                                                {option.idProducto + ' - ' + option.descripcion + ' - sku: ' + option.sku + ' - Unidad de medida: ' + option.unidadMedida}
                                                                            </Tooltip>
                                                                        </Option>
                                                                        )}
                                                                </Select>
                                                            </Form.Item>
                                                            
                                                        </Col>

                                                        <Col span={3}>
                                                            <Form.Item
                                                                {...field}
                                                                label='Cantidad'
                                                                name={[field.name, 'cantidad']}
                                                                fieldKey={[field.fieldKey, 'cantidad']}
                                                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                            >
                                                                <InputNumber min={1} style={{width: '100%'}} placeholder='Ingresa la cantidad'/>
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={5}>
                                                            <Form.Item
                                                                label="Tipo de Moneda"
                                                                name={[field.name, 'tipoMoneda']}
                                                                fieldKey={[field.fieldKey, 'tipoMoneda']}
                                                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                                            >
                                                                <Select allowClear
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Selecciona el tipo de moneda"
                                                                    loading={this.props.fetchingListaTipoMonedasActivas}
                                                                    onChange={(a, b) => { this.setState({ tipoMonedaSeleccionada: b.children }); }}
                                                                    showSearch
                                                                >
                                                                    {this.props.listaTipoMonedasActivas.map(option => <Option key={option.idTipoMoneda + ' - ' + option.nombre + ' (' + option.nomenclatura + ')'}>{option.idTipoMoneda + ' - ' + option.nombre + ' (' + option.nomenclatura + ')'}</Option>)}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                        
                                                        { console.log('this.formRef.current.getFieldsValue', this.formRef.current.getFieldsValue([field.name, 'tipoMoneda'])) }
                                                            <Col span={3}>
                                                                <Form.Item
                                                                    label="Tipo de Cambio"
                                                                    name={[field.name, 'tipoCambio']}
                                                                    fieldKey={[field.fieldKey, 'tipoCambio']}
                                                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                                                >
                                                                    <InputNumber min={1} placeholder='Tipo de cambio' formatter={value =>  `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                                                                </Form.Item>
                                                            </Col>

                                                        <Col span={3}>
                                                            <Form.Item
                                                                label="Precio Costo"
                                                                name={[field.name, 'precio']}
                                                                fieldKey={[field.fieldKey, 'precio']}
                                                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                                                <InputNumber min={1} placeholder='Precio' />
                                                            </Form.Item>
                                                        </Col>
                                                        
                                                        
                                                        
                                                        {this.formRef.current.getFieldsValue('insumos').insumos[field.key] ? (
                                                        <>
                                                        <Col span={3}>
                                                            <Form.Item
                                                                label="Lote"
                                                                name={[field.name, 'lote']}
                                                                fieldKey={[field.fieldKey, 'lote']}
                                                                rules={[{ required: this.formRef.current.getFieldsValue('insumos').insumos[field.key].validarLote, message: 'Favor de llenar el campo', }]}>
                                                                <InputNumber min={1} placeholder='Lote' disabled={!this.formRef.current.getFieldsValue('insumos').insumos[field.key].validarLote}/>
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={4}>
                                                            <Form.Item
                                                                label="Fecha de Caducidad"
                                                                name={[field.name, 'fechaCaducidad']}
                                                                fieldKey={[field.fieldKey, 'fechaCaducidad']}
                                                                rules={[{ required: this.formRef.current.getFieldsValue('insumos').insumos[field.key].validarLote, message: 'Favor de llenar el campo' }]}
                                                            >   
                                                                <DatePicker
                                                                    style={{ width: '100%' }}
                                                                    format='DD-MM-YYYY'
                                                                    placeholder={["Seleccione fecha de caducidad"]}
                                                                    disabled={!this.formRef.current.getFieldsValue('insumos').insumos[field.key].validarLote}
                                                                    disabledDate={disabledDateCad}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        </>
                                                        ): false}
                                                        
                                                        

                                                        { listaUbicaciones ? (
                                                        <Col span={5} >
                                                            <Form.Item
                                                                label="Ubicacion"
                                                                name={[field.name, 'ubicacion']}
                                                                fieldKey={[field.fieldKey, 'ubicacion']}
                                                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                            >
                                                            <Select allowClear
                                                                    style={{ width: '100%' }}
                                                                    placeholder="Seleccione la ubicación"
                                                                    loading={fetchingObtenerUbicacion}
                                                                >
                                                                    {listaUbicaciones.map(option => <Option key={option.idUbicacionProducto}>{option.area + "-" + option.piso + "-" + option.pasillo + "-" + option.rack + "-" + option.nivelRack + "-" + option.posicionRack}</Option>)}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>

                                                        ): false}  
                                                    </Row>
                                                </Col>
                                                <Col span={1}>
                                                    {fields.length > 1 ? (
                                                    <Form.Item>
                                                        <MinusCircleOutlined className='borrarInsumo' onClick={() => remove(field.name)} />
                                                    </Form.Item>
                                                    ):false}
                                                </Col>
                                            </Row>
                                            ))}
                                            <Form.Item>
                                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>Agregar Producto</Button>
                                            </Form.Item>
                                        </>
                                        )}
                                        </Form.List> */}

                                        <Col span={22}>
                                            <Divider orientation='left'> <Text strong> Agregar Productos </Text></Divider>
                                        </Col>

                                        <Col span={1} style={{paddingTop:'1em'}}>
                                            <Tooltip title='Anterior'>
                                                <Button className="iconTer" shape="circle" onClick={() => { this.setState({currentStep: 0}); this.props.onGetProductos(this.state.page) }} icon={<ArrowLeftOutlined />} disabled={ this.state.currentStep !== 0 ? false : true }/>
                                            </Tooltip>
                                        </Col>

                                        <Col span={1} style={{paddingTop:'1em'}}>
                                            <Tooltip title='Siguiente'>
                                                <Button className="iconTer" shape="circle" onClick={() => { this.setState({currentStep: 1}) }} icon={<ArrowRightOutlined />} disabled={ this.state.selectedRowKeys.length > 0 ? false : true }/>
                                            </Tooltip>
                                        </Col>
                                        
                                        <Col span={24} style={{padding:'1em'}}>
                                            <Steps
                                                type='navigation'
                                                size='small'
                                                current={this.state.currentStep}
                                            >
                                                <Step status={this.state.currentStep>0? 'finish':'process'} title='Seleccionar Productos' />
                                                <Step status={this.state.currentStep>1? 'finish':'process'} title='Detalle de Productos' />
                                            </Steps>
                                        </Col>
                                    </Row>

                                        { this.state.currentStep === 0 ? (
                                            <Row justify='space-around' gutter={[16,16]}>
                                                {this.props.listaProductosBusqueda ? (
                                                    <>
                                                        <Col span={12}>
                                                            <Select allowClear
                                                            style={{ width: '100%' }}
                                                            placeholder="Seleccione la subfamilia"
                                                            loading={this.props.fetchingListaSubfamiliasProductos}
                                                            onChange={(value) => onBuscarProducto(value, "idSubFamilia")}
                                                            >
                                                            {this.props.listaSubfamiliasProductosActivos.map(option => <Option key={option.idSubFamilia}>{option.subFamilia}</Option>)}
                                                            </Select>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Search allowClear className='buscarModulos' placeholder='Ingresa nombre/clave/SKU/codigo adicional' onSearch={(value) => onBuscarProducto(value, "busqueda")} />
                                                        </Col>
                                                    </>
                                                ): false}
                                                <Col span={24}>
                                                    <Table 
                                                    size="small"
                                                    rowKey="rowKey"
                                                    columns={columns}
                                                    dataSource={this.props.listaProductosBusqueda}
                                                    loading={this.props.fetchingListaProductosBusqueda}
                                                    locale={{ emptyText: 'Sin datos de productos' }}
                                                    onChange={handleTableChange}
                                                    pagination={{ total: this.props.listaProductosBusquedaCantidad, simple: true, current: this.state.page }}
                                                    showSorterTooltip={true}
                                                    rowSelection={rowSelection}
                                                    />
                                                </Col>
                                            </Row>
                                        ): this.state.currentStep === 1 &&
                                            <Row justify='space-around' gutter={[8,8]}> 
                                                <Col span={24}>
                                                    <Table
                                                        size="small"
                                                        rowKey="idProducto"
                                                        columns={columnsSelected}
                                                        // dataSource={this.props.listaSeleccionados}
                                                        dataSource={this.state.filteredData}
                                                        locale={{ emptyText: 'Sin datos' }}
                                                        pagination={false}
                                                    />
                                                </Col> 

                                                <Col span={24} style={{paddingTop:'1em'}}>
                                                    <Form.Item
                                                        label="Archivo PDF"
                                                        name="pdf"
                                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                    >
                                                    {/* <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                                    {this.state.filePDF === false ? (
                                                        <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                                            <UploadOutlined /> Click para subir archivo PDF
                                                        </Button>
                                                    ):false}
                                                    </Upload> */}
                                                    <Dragger customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false} style={{borderRadius:'1em'}} fileList={this.state.fileList}>
                                                        <Row justify='center'>
                                                            <FilePdfFilled className='iconTabPane'/>
                                                            <Col span={24} style={{paddingTop:'1em'}}>
                                                                <Text strong> Click o arrastra a ésta área el achivo PDF </Text>
                                                            </Col>
                                                        </Row>
                                                    </Dragger>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={12} className='marginBoton'>
                                                    <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Registrar</Button>
                                                </Col>
                                            </Row>
                                        }
                                   
                                </Form>
                            </Spin>
                        </Col>
                    </Row>
                </Modal>
                <ModalModificarRegistro />
            </div>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        page: state.AlmacenesReducer.page,
        busqueda: state.AlmacenesReducer.busqueda,
        showModalNuevoFolioVC: state.AlmacenesReducer.showModalNuevoFolioVC,

        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,

        fetchingListaProductosActivosBusqueda: state.AlmacenesReducer.fetchingListaProductosActivosBusqueda,
        listaProductosActivosBusqueda: state.AlmacenesReducer.listaProductosActivosBusqueda,

        listaSinRepetir: state.ServiciosPlanificadosReducer.listaSinRepetir,
        listaAlmacenesActivos: state.AlmacenesReducer.listaAlmacenesActivos,
        fetchingListaAlmacenesActivos: state.AlmacenesReducer.fetchingListaAlmacenesActivos,

        listaUbicaciones: state.AlmacenesReducer.listaUbicaciones,
        fetchingObtenerUbicacion: state.AlmacenesReducer.fetchingObtenerUbicacion,
        fetchingNuevoFolioVC: state.AlmacenesReducer.fetchingNuevoFolioVC,

        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,

        listaTipoMonedasActivas: state.CatalogosGeneralesReducer.listaTipoMonedasActivas,
        fetchingListaTipoMonedasActivas: state.CatalogosGeneralesReducer.fetchingListaTipoMonedasActivas,

        listaProductosBusqueda: state.AlmacenesReducer.listaProductosBusqueda,
        fetchingListaProductosBusqueda: state.AlmacenesReducer.fetchingListaProductosBusqueda,
        listaProductosBusquedaCantidad: state.AlmacenesReducer.listaProductosBusquedaCantidad,
        listaSeleccionados: state.AlmacenesReducer.listaSeleccionados,

        selectedRowKeys: state.AlmacenesReducer.selectedRowKeys,

        fetchingListaSubfamiliasProductos: state.CatalogosGeneralesReducer.fetchingListaSubfamiliasProductos,
        listaSubfamiliasProductosActivos: state.CatalogosGeneralesReducer.listaSubfamiliasProductosActivos,

        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onCloseModal: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVOFOLIO_VERIFICACION_CAL', showModalNuevoFolioVC: false })
        },
        onGetServiciosAct: () => {
            dispatch({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST' });
        },
        onListaProductosActivosBusqueda: (busqueda) => {
            dispatch({ type: 'LISTA_PRODUCTOS_BUSQUEDA_REQUEST', busqueda });
        },
        onListaProductosActivosBusquedaLimpiar: () => {
            dispatch({ type: 'LISTA_PRODUCTOS_BUSQUEDA_SUCCESS', listaProductosActivosBusqueda: [] });
        },
        onActualizarListaSinRepetir: (listaSinRepetir) => {
            dispatch({ type: "ACTUALIZAR_LISTA_INSUMOS_SIN_REPETIR",  listaSinRepetir });
        },
        onGetUbicacion: (idAlmacen) => {
            dispatch({ type: "OBTENER_UBICACION_POR_ALMACEN_REQUEST", idAlmacen });
        },
        onGetAlmacenes: () => {
            dispatch({ type: "ALMACENES_ACTIVOS_BUSQUEDA_REQUEST"});
        },
        onCrearFolio: (formulario, page, busqueda, listaSeleccionados) => {
            dispatch({ type: "CREAR_FOLIO_VERIFICACION_CALIDAD_REQUEST", formulario, page, busqueda, listaSeleccionados});
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        },

        onGetProductos: (page, busqueda, idSubFamilia) => {
            dispatch({ type: "PRODUCTOS_BUSQUEDA_REQUEST", page, busqueda, idSubFamilia });
        },
        onActualizarTabla: (listaSeleccionados, selectedRowKeys, pageMovimientos, hasSelected) => {
            dispatch({ type: 'ACTUALIZAR_TABLA_MOVIMIENTOS', listaSeleccionados, selectedRowKeys, pageMovimientos, hasSelected})
        },
        onShowModalModificar: (registroProductoSeleccionado) => {
            dispatch({ type: "SHOW_MODAL_MODIFICAR_REGISTRO_VERIFICACION", showModalModificarRegistro: true, registroProductoSeleccionado: registroProductoSeleccionado})
        },
        onCleanSelected: () => {
            dispatch({ type: 'ACTUALIZAR_TABLA_MOVIMIENTOS', listaSeleccionados: [], selectedRowKeys: [], hasSelected: true})
        },
        onGetSubfamilias: () => {
            dispatch({ type: 'LISTA_SUBFAMILIAS_PRODUCTOS_REQUEST' });
        },
        onCleanUbis: () => {
            dispatch({ type: 'OBTENER_UBICACION_POR_ALMACEN_SUCCESS', listaUbicaciones: null });
        },
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoFolio);