import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import { ConfigProvider } from 'antd';
import esES from 'antd/es/locale/es_ES';
import 'moment/locale/es';
import store from "./store/store";

ReactDOM.render(
  	<Provider store={store}>
		<ConfigProvider locale={esES}>
			<App />
		</ConfigProvider>
	</Provider>, 
	 document.getElementById('root')
);

serviceWorker.unregister();
