export const CAMBIAR_CATALOGO_SERVICIOS_PUBLICOS = 'CAMBIAR_CATALOGO_SERVICIOS_PUBLICOS';

// Catálogo de Servicios Públicos
export const LISTA_SERVICIOS_PUBLICOS_ACTIVOS_REQUEST = 'LISTA_SERVICIOS_PUBLICOS_ACTIVOS_REQUEST';
export const LISTA_SERVICIOS_PUBLICOS_ACTIVOS_SUCCESS = 'LISTA_SERVICIOS_PUBLICOS_ACTIVOS_SUCCESS';
export const LISTA_SERVICIOS_PUBLICOS_ACTIVOS_FAILURE = 'LISTA_SERVICIOS_PUBLICOS_ACTIVOS_FAILURE';

export const LISTA_SERVICIOS_PUBLICOS_BUSQUEDA_REQUEST = 'LISTA_SERVICIOS_PUBLICOS_BUSQUEDA_REQUEST';
export const LISTA_SERVICIOS_PUBLICOS_BUSQUEDA_SUCCESS = 'LISTA_SERVICIOS_PUBLICOS_BUSQUEDA_SUCCESS';
export const LISTA_SERVICIOS_PUBLICOS_BUSQUEDA_FAILURE = 'LISTA_SERVICIOS_PUBLICOS_BUSQUEDA_FAILURE';

export const ESTADO_SERVICIO_PUBLICO_REQUEST = 'ESTADO_SERVICIO_PUBLICO_REQUEST';
export const ESTADO_SERVICIO_PUBLICO_SUCCESS = 'ESTADO_SERVICIO_PUBLICO_SUCCESS';
export const ESTADO_SERVICIO_PUBLICO_FAILURE = 'ESTADO_SERVICIO_PUBLICO_FAILURE';

export const SHOW_MODAL_NUEVO_SERVICIO_PUBLICO = 'SHOW_MODAL_NUEVO_SERVICIO_PUBLICO';
export const NUEVO_SERVICIO_PUBLICO_REQUEST = 'NUEVO_SERVICIO_PUBLICO_REQUEST';
export const NUEVO_SERVICIO_PUBLICO_SUCCESS = 'NUEVO_SERVICIO_PUBLICO_SUCCESS';
export const NUEVO_SERVICIO_PUBLICO_FAILURE = 'NUEVO_SERVICIO_PUBLICO_FAILURE';

export const SHOW_MODAL_EDITAR_SERVICIO_PUBLICO = 'SHOW_MODAL_EDITAR_SERVICIO_PUBLICO';
export const EDITAR_SERVICIO_PUBLICO_REQUEST = 'EDITAR_SERVICIO_PUBLICO_REQUEST';
export const EDITAR_SERVICIO_PUBLICO_SUCCESS = 'EDITAR_SERVICIO_PUBLICO_SUCCESS';
export const EDITAR_SERVICIO_PUBLICO_FAILURE = 'EDITAR_SERVICIO_PUBLICO_FAILURE';

// Catálogo de Variables de Medición
export const LISTA_VARIABLES_MEDICION_REQUEST = 'LISTA_VARIABLES_MEDICION_REQUEST';
export const LISTA_VARIABLES_MEDICION_SUCCESS = 'LISTA_VARIABLES_MEDICION_SUCCESS';
export const LISTA_VARIABLES_MEDICION_FAILURE = 'LISTA_VARIABLES_MEDICION_FAILURE';

export const SHOW_MODAL_NUEVA_VARIABLE_MEDICION = 'SHOW_MODAL_NUEVA_VARIABLE_MEDICION';
export const SHOW_MODAL_EDITAR_VARIABLE_MEDICION = 'SHOW_MODAL_EDITAR_VARIABLE_MEDICION';
export const SHOW_MODAL_DETALLE_VARIABLE = 'SHOW_MODAL_DETALLE_VARIABLE';

export const NUEVA_VARIABLE_MEDICION_REQUEST = 'NUEVA_VARIABLE_MEDICION_REQUEST';
export const NUEVA_VARIABLE_MEDICION_SUCCESS = 'NUEVA_VARIABLE_MEDICION_SUCCESS';
export const NUEVA_VARIABLE_MEDICION_FAILURE = 'NUEVA_VARIABLE_MEDICION_FAILURE';

export const EDITAR_VARIABLES_MEDICION_REQUEST = 'EDITAR_VARIABLES_MEDICION_REQUEST';
export const EDITAR_VARIABLES_MEDICION_SUCCESS = 'EDITAR_VARIABLES_MEDICION_SUCCESS';
export const EDITAR_VARIABLES_MEDICION_FAILURE = 'EDITAR_VARIABLES_MEDICION_FAILURE';

export const LISTA_VARIABLES_MEDICION_ACTIVOS_REQUEST = 'LISTA_VARIABLES_MEDICION_ACTIVOS_REQUEST';
export const LISTA_VARIABLES_MEDICION_ACTIVOS_SUCCESS = 'LISTA_VARIABLES_MEDICION_ACTIVOS_SUCCESS';
export const LISTA_VARIABLES_MEDICION_ACTIVOS_FAILURE = 'LISTA_VARIABLES_MEDICION_ACTIVOS_FAILURE';

export const ESTADO_VARIABLES_MEDICION_REQUEST = 'ESTADO_VARIABLES_MEDICION_REQUEST';
export const ESTADO_VARIABLES_MEDICION_SUCCESS = 'ESTADO_VARIABLES_MEDICION_SUCCESS';
export const ESTADO_VARIABLES_MEDICION_FAILURE = 'ESTADO_VARIABLES_MEDICION_FAILURE';

// Programación y Medición de Consumos
export const LISTA_PROGRAMACION_MEDICION_BUSQUEDA_REQUEST = 'LISTA_PROGRAMACION_MEDICION_BUSQUEDA_REQUEST';
export const LISTA_PROGRAMACION_MEDICION_BUSQUEDA_SUCCESS = 'LISTA_PROGRAMACION_MEDICION_BUSQUEDA_SUCCESS';
export const LISTA_PROGRAMACION_MEDICION_BUSQUEDA_FAILURE = 'LISTA_PROGRAMACION_MEDICION_BUSQUEDA_FAILURE';

export const SHOW_MODAL_NUEVA_PROGRAMACION_MEDICION = 'SHOW_MODAL_NUEVA_PROGRAMACION_MEDICION';
export const SHOW_MODAL_EDITAR_PROGRAMACION_MEDICION = 'SHOW_MODAL_EDITAR_PROGRAMACION_MEDICION';

export const NUEVA_PROGRAMACION_MEDICION_REQUEST = 'NUEVA_PROGRAMACION_MEDICION_REQUEST';
export const NUEVA_PROGRAMACION_MEDICION_SUCCESS = 'NUEVA_PROGRAMACION_MEDICION_SUCCESS';
export const NUEVA_PROGRAMACION_MEDICION_FAILURE = 'NUEVA_PROGRAMACION_MEDICION_FAILURE';

export const EDITAR_PROGRAMACION_MEDICION_REQUEST = 'EDITAR_PROGRAMACION_MEDICION_REQUEST';
export const EDITAR_PROGRAMACION_MEDICION_SUCCESS = 'EDITAR_PROGRAMACION_MEDICION_SUCCESS';
export const EDITAR_PROGRAMACION_MEDICION_FAILURE = 'EDITAR_PROGRAMACION_MEDICION_FAILURE';

export const ESTADO_PROGRAMACION_MEDICION_REQUEST = 'ESTADO_PROGRAMACION_MEDICION_REQUEST';
export const ESTADO_PROGRAMACION_MEDICION_SUCCESS = 'ESTADO_PROGRAMACION_MEDICION_SUCCESS';
export const ESTADO_PROGRAMACION_MEDICION_FAILURE = 'ESTADO_PROGRAMACION_MEDICION_FAILURE';

// Registro de Lecturas
export const SHOW_MODAL_CAPTURA_CONSUMOS = 'SHOW_MODAL_CAPTURA_CONSUMOS';
export const SHOW_MODAL_PRESENTACION_CONSUMOS = 'SHOW_MODAL_PRESENTACION_CONSUMOS';

export const LISTA_DEPOSITO_BY_SERVICIO_REQUEST = 'LISTA_DEPOSITO_BY_SERVICIO_REQUEST';
export const LISTA_DEPOSITO_BY_SERVICIO_SUCCESS = 'LISTA_DEPOSITO_BY_SERVICIO_SUCCESS';
export const LISTA_DEPOSITO_BY_SERVICIO_FAILURE = 'LISTA_DEPOSITO_BY_SERVICIO_FAILURE';

export const LISTA_DEPOSITO_BY_SERVICIO_SIN_PROGRA_REQUEST = 'LISTA_DEPOSITO_BY_SERVICIO_SIN_PROGRA_REQUEST';
export const LISTA_DEPOSITO_BY_SERVICIO_SIN_PROGRA_SUCCESS = 'LISTA_DEPOSITO_BY_SERVICIO_SIN_PROGRA_SUCCESS';
export const LISTA_DEPOSITO_BY_SERVICIO_SIN_PROGRA_FAILURE = 'LISTA_DEPOSITO_BY_SERVICIO_SIN_PROGRA_FAILURE';

export const PROGRAMACION_POR_SERVICIO_REQUEST = 'PROGRAMACION_POR_SERVICIO_REQUEST';
export const PROGRAMACION_POR_SERVICIO_SUCCESS = 'PROGRAMACION_POR_SERVICIO_SUCCESS';
export const PROGRAMACION_POR_SERVICIO_FAILURE = 'PROGRAMACION_POR_SERVICIO_FAILURE';

export const VARIABLES_POR_PROGRAMACION_REQUEST = 'VARIABLES_POR_PROGRAMACION_REQUEST';
export const VARIABLES_POR_PROGRAMACION_SUCCESS = 'VARIABLES_POR_PROGRAMACION_SUCCESS';
export const VARIABLES_POR_PROGRAMACION_FAILURE = 'VARIABLES_POR_PROGRAMACION_FAILURE';

export const HORARIOS_BY_SERVICIOS_REQUEST = 'HORARIOS_BY_SERVICIOS_REQUEST';
export const HORARIOS_BY_SERVICIOS_SUCCESS = 'HORARIOS_BY_SERVICIOS_SUCCESS';
export const HORARIOS_BY_SERVICIOS_FAILURE = 'HORARIOS_BY_SERVICIOS_FAILURE';

export const HORARIOS_BY_SERVICIOS_ADMIN_REQUEST = 'HORARIOS_BY_SERVICIOS_ADMIN_REQUEST';
export const HORARIOS_BY_SERVICIOS_ADMIN_SUCCESS = 'HORARIOS_BY_SERVICIOS_ADMIN_SUCCESS';
export const HORARIOS_BY_SERVICIOS_ADMIN_FAILURE = 'HORARIOS_BY_SERVICIOS_ADMIN_FAILURE';

export const REGISTRO_LECTURA_REQUEST = 'REGISTRO_LECTURA_REQUEST';
export const REGISTRO_LECTURA_SUCCESS = 'REGISTRO_LECTURA_SUCCESS';
export const REGISTRO_LECTURA_FAILURE = 'REGISTRO_LECTURA_FAILURE';

export const REGISTRO_LECTURA_PRESENTACION_REQUEST = 'REGISTRO_LECTURA_PRESENTACION_REQUEST';
export const REGISTRO_LECTURA_PRESENTACION_SUCCESS = 'REGISTRO_LECTURA_PRESENTACION_SUCCESS';
export const REGISTRO_LECTURA_PRESENTACION_FAILURE = 'REGISTRO_LECTURA_PRESENTACION_FAILURE';

export const REGISTRO_LECTURA_EXCEL_REQUEST = 'REGISTRO_LECTURA_EXCEL_REQUEST';
export const REGISTRO_LECTURA_EXCEL_SUCCESS = 'REGISTRO_LECTURA_EXCEL_SUCCESS';
export const REGISTRO_LECTURA_EXCEL_FAILURE = 'REGISTRO_LECTURA_EXCEL_FAILURE';

export const VERIFICACION_REGISTRO_LECTURA_REQUEST = 'VERIFICACION_REGISTRO_LECTURA_REQUEST';
export const VERIFICACION_REGISTRO_LECTURA_SUCCESS = 'VERIFICACION_REGISTRO_LECTURA_SUCCESS';
export const VERIFICACION_REGISTRO_LECTURA_FAILURE = 'VERIFICACION_REGISTRO_LECTURA_FAILURE';

export const SHOW_MODAL_CAPTURA_ABASTECIMIENTO = 'SHOW_MODAL_CAPTURA_ABASTECIMIENTO';

export const LISTA_ASIGNACION_CAPACIDAD_BYIDSERVICIOPUBLICO_REQUEST = 'LISTA_ASIGNACION_CAPACIDAD_BYIDSERVICIOPUBLICO_REQUEST';
export const LISTA_ASIGNACION_CAPACIDAD_BYIDSERVICIOPUBLICO_SUCCESS = 'LISTA_ASIGNACION_CAPACIDAD_BYIDSERVICIOPUBLICO_SUCCESS';
export const LISTA_ASIGNACION_CAPACIDAD_BYIDSERVICIOPUBLICO_FAILURE = 'LISTA_ASIGNACION_CAPACIDAD_BYIDSERVICIOPUBLICO_FAILURE';

export const CREAR_ABASTECIMIENTO_REQUEST = 'CREAR_ABASTECIMIENTO_REQUEST';
export const CREAR_ABASTECIMIENTO_SUCCESS = 'CREAR_ABASTECIMIENTO_SUCCESS';
export const CREAR_ABASTECIMIENTO_FAILURE = 'CREAR_ABASTECIMIENTO_FAILURE';

export const LISTA_VARIABLES_MEDICION_BY_IDSERVICIOPUBLICO_REQUEST = 'LISTA_VARIABLES_MEDICION_BY_IDSERVICIOPUBLICO_REQUEST';
export const LISTA_VARIABLES_MEDICION_BY_IDSERVICIOPUBLICO_SUCCESS = 'LISTA_VARIABLES_MEDICION_BY_IDSERVICIOPUBLICO_SUCCESS';
export const LISTA_VARIABLES_MEDICION_BY_IDSERVICIOPUBLICO_FAILURE = 'LISTA_VARIABLES_MEDICION_BY_IDSERVICIOPUBLICO_FAILURE';

export const REGISTRO_LECTURAS_PDF_REQUEST = 'REGISTRO_LECTURAS_PDF_REQUEST';
export const REGISTRO_LECTURAS_PDF_SUCCESS = 'REGISTRO_LECTURAS_PDF_SUCCESS';
export const REGISTRO_LECTURAS_PDF_FAILURE = 'REGISTRO_LECTURAS_PDF_FAILURE';

export const REGISTRO_LECTURA_PRESENTACION_DISPONIBILIDAD_REQUEST = 'REGISTRO_LECTURA_PRESENTACION_DISPONIBILIDAD_REQUEST';
export const REGISTRO_LECTURA_PRESENTACION_DISPONIBILIDAD_SUCCESS = 'REGISTRO_LECTURA_PRESENTACION_DISPONIBILIDAD_SUCCESS';
export const REGISTRO_LECTURA_PRESENTACION_DISPONIBILIDAD_FAILURE = 'REGISTRO_LECTURA_PRESENTACION_DISPONIBILIDAD_FAILURE';

export const REGISTRO_LECTURA_PRESENTACION_CONSUMO_REQUEST = 'REGISTRO_LECTURA_PRESENTACION_CONSUMO_REQUEST';
export const REGISTRO_LECTURA_PRESENTACION_CONSUMO_SUCCESS = 'REGISTRO_LECTURA_PRESENTACION_CONSUMO_SUCCESS';
export const REGISTRO_LECTURA_PRESENTACION_CONSUMO_FAILURE = 'REGISTRO_LECTURA_PRESENTACION_CONSUMO_FAILURE';

export const LISTA_UBICACIONES_BY_IDSERVICIOPUBLICO_REQUEST = 'LISTA_UBICACIONES_BY_IDSERVICIOPUBLICO_REQUEST';
export const LISTA_UBICACIONES_BY_IDSERVICIOPUBLICO_SUCCESS = 'LISTA_UBICACIONES_BY_IDSERVICIOPUBLICO_SUCCESS';
export const LISTA_UBICACIONES_BY_IDSERVICIOPUBLICO_FAILURE = 'LISTA_UBICACIONES_BY_IDSERVICIOPUBLICO_FAILURE';

//disponibilidad y consumo 

export const REGISTRO_LECTURA_DISPONIBILIDAD_EXCEL_REQUEST = 'REGISTRO_LECTURA_DISPONIBILIDAD_EXCEL_REQUEST';
export const REGISTRO_LECTURA_DISPONIBILIDAD_EXCEL_SUCCESS = 'REGISTRO_LECTURA_DISPONIBILIDAD_EXCEL_SUCCESS';
export const REGISTRO_LECTURA_DISPONIBILIDAD_EXCEL_FAILURE = 'REGISTRO_LECTURA_DISPONIBILIDAD_EXCEL_FAILURE';

export const REGISTRO_LECTURA_DISPONIBILIDAD_PDF_REQUEST = 'REGISTRO_LECTURA_DISPONIBILIDAD_PDF_REQUEST';
export const REGISTRO_LECTURA_DISPONIBILIDAD_PDF_SUCCESS = 'REGISTRO_LECTURA_DISPONIBILIDAD_PDF_SUCCESS';
export const REGISTRO_LECTURA_DISPONIBILIDAD_PDF_FAILURE = 'REGISTRO_LECTURA_DISPONIBILIDAD_PDF_FAILURE';

export const REGISTRO_LECTURA_CONSUMO_EXCEL_REQUEST = 'REGISTRO_LECTURA_CONSUMO_EXCEL_REQUEST';
export const REGISTRO_LECTURA_CONSUMO_EXCEL_SUCCESS = 'REGISTRO_LECTURA_CONSUMO_EXCEL_SUCCESS';
export const REGISTRO_LECTURA_CONSUMO_EXCEL_FAILURE = 'REGISTRO_LECTURA_CONSUMO_EXCEL_FAILURE';

export const REGISTRO_LECTURA_CONSUMO_PDF_REQUEST = 'REGISTRO_LECTURA_CONSUMO_PDF_REQUEST';
export const REGISTRO_LECTURA_CONSUMO_PDF_SUCCESS = 'REGISTRO_LECTURA_CONSUMO_PDF_SUCCESS';
export const REGISTRO_LECTURA_CONSUMO_PDF_FAILURE = 'REGISTRO_LECTURA_CONSUMO_PDF_FAILURE';

//Asignación de Ubicación y Capacidad
export const LISTA_SERVICIOS_PUBLICOS_NO_ASIGNADOS_REQUEST = 'LISTA_SERVICIOS_PUBLICOS_NO_ASIGNADOS_REQUEST'; 
export const LISTA_SERVICIOS_PUBLICOS_NO_ASIGNADOS_SUCCESS = 'LISTA_SERVICIOS_PUBLICOS_NO_ASIGNADOS_SUCCESS';
export const LISTA_SERVICIOS_PUBLICOS_NO_ASIGNADOS_FAILURE = 'LISTA_SERVICIOS_PUBLICOS_NO_ASIGNADOS_FAILURE';

export const LISTA_SERVICIOS_PUBLICOS_ASIGNADOS_REQUEST = 'LISTA_SERVICIOS_PUBLICOS_ASIGNADOS_REQUEST'; 
export const LISTA_SERVICIOS_PUBLICOS_ASIGNADOS_SUCCESS = 'LISTA_SERVICIOS_PUBLICOS_ASIGNADOS_SUCCESS';
export const LISTA_SERVICIOS_PUBLICOS_ASIGNADOS_FAILURE = 'LISTA_SERVICIOS_PUBLICOS_ASIGNADOS_FAILURE';

export const LISTA_DEPOSITOS_SERVICIOS_PUBLICOS_REQUEST = 'LISTA_DEPOSITOS_SERVICIOS_PUBLICOS_REQUEST'; 
export const LISTA_DEPOSITOS_SERVICIOS_PUBLICOS_SUCCESS = 'LISTA_DEPOSITOS_SERVICIOS_PUBLICOS_SUCCESS';
export const LISTA_DEPOSITOS_SERVICIOS_PUBLICOS_FAILURE = 'LISTA_DEPOSITOS_SERVICIOS_PUBLICOS_FAILURE';

export const SHOW_MODAL_DETALLE_DEPOSITOS = 'SHOW_MODAL_DETALLE_DEPOSITOS';
export const SHOW_MODAL_NUEVA_ASIGNACION_DEPOSITOS = 'SHOW_MODAL_NUEVA_ASIGNACION_DEPOSITOS';
export const SHOW_MODAL_EDITAR_ASIGNACION_DEPOSITOS = 'SHOW_MODAL_EDITAR_ASIGNACION_DEPOSITOS';

export const NUEVA_ASIGNACION_SERVICIOS_PUBLICOS_REQUEST = 'NUEVA_ASIGNACION_SERVICIOS_PUBLICOS_REQUEST';
export const NUEVA_ASIGNACION_SERVICIOS_PUBLICOS_SUCCESS = 'NUEVA_ASIGNACION_SERVICIOS_PUBLICOS_SUCCESS'; 
export const NUEVA_ASIGNACION_SERVICIOS_PUBLICOS_FAILURE = 'NUEVA_ASIGNACION_SERVICIOS_PUBLICOS_FAILURE';

export const EDITAR_ASIGNACION_SERVICIOS_PUBLICOS_REQUEST = 'EDITAR_ASIGNACION_SERVICIOS_PUBLICOS_REQUEST'; 
export const EDITAR_ASIGNACION_SERVICIOS_PUBLICOS_SUCCESS = 'EDITAR_ASIGNACION_SERVICIOS_PUBLICOS_SUCCESS';
export const EDITAR_ASIGNACION_SERVICIOS_PUBLICOS_FAILURE = 'EDITAR_ASIGNACION_SERVICIOS_PUBLICOS_FAILURE';

// Cálculculos de Consumo Diferenciado (CCD)
export const LISTA_SERVICIOS_PUBLICOS_CCD_REQUEST = 'LISTA_SERVICIOS_PUBLICOS_CCD_REQUEST';
export const LISTA_SERVICIOS_PUBLICOS_CCD_SUCCESS = 'LISTA_SERVICIOS_PUBLICOS_CCD_SUCCESS';
export const LISTA_SERVICIOS_PUBLICOS_CCD_FAILURE = 'LISTA_SERVICIOS_PUBLICOS_CCD_FAILURE';

export const LISTA_SERVICIOS_PUBLICOS_CCD_BUSQUEDA_REQUEST = 'LISTA_SERVICIOS_PUBLICOS_CCD_BUSQUEDA_REQUEST';
export const LISTA_SERVICIOS_PUBLICOS_CCD_BUSQUEDA_SUCCESS = 'LISTA_SERVICIOS_PUBLICOS_CCD_BUSQUEDA_SUCCESS';
export const LISTA_SERVICIOS_PUBLICOS_CCD_BUSQUEDA_FAILURE = 'LISTA_SERVICIOS_PUBLICOS_CCD_BUSQUEDA_FAILURE';

export const LISTA_DETALLE_CCD_INTERNO_REQUEST = 'LISTA_DETALLE_CCD_INTERNO_REQUEST'; 
export const LISTA_DETALLE_CCD_INTERNO_SUCCESS = 'LISTA_DETALLE_CCD_INTERNO_SUCCESS';
export const LISTA_DETALLE_CCD_INTERNO_FAILURE = 'LISTA_DETALLE_CCD_INTERNO_FAILURE';

export const LISTA_DETALLE_CCD_FACTURACION_REQUEST = 'LISTA_DETALLE_CCD_FACTURACION_REQUEST'; 
export const LISTA_DETALLE_CCD_FACTURACION_SUCCESS = 'LISTA_DETALLE_CCD_FACTURACION_SUCCESS';
export const LISTA_DETALLE_CCD_FACTURACION_FAILURE = 'LISTA_DETALLE_CCD_FACTURACION_FAILURE';

export const LISTA_CCD_INTERNO_REQUEST = 'LISTA_CCD_INTERNO_REQUEST';
export const LISTA_CCD_INTERNO_SUCCESS = 'LISTA_CCD_INTERNO_SUCCESS';
export const LISTA_CCD_INTERNO_FAILURE = 'LISTA_CCD_INTERNO_FAILURE';

export const SHOW_MODAL_DETALLE_CCD_INTERNO = 'SHOW_MODAL_DETALLE_CCD_INTERNO';
export const SHOW_MODAL_DETALLE_CCD_FACTURACION = 'SHOW_MODAL_DETALLE_CCD_FACTURACION'
export const SHOW_MODAL_CCD_INTERNO = 'SHOW_MODAL_CCD_INTERNO';
export const SHOW_MODAL_CDD_FACTURACION = 'SHOW_MODAL_CDD_FACTURACION';

export const CALCULO_CONSUMO_DIFERENCIADO_INTERNO_REQUEST = 'CALCULO_CONSUMO_DIFERENCIADO_INTERNO_REQUEST';
export const CALCULO_CONSUMO_DIFERENCIADO_INTERNO_SUCCESS = 'CALCULO_CONSUMO_DIFERENCIADO_INTERNO_SUCCESS'; 
export const CALCULO_CONSUMO_DIFERENCIADO_INTERNO_FAILURE = 'CALCULO_CONSUMO_DIFERENCIADO_INTERNO_FAILURE';

export const CALCULO_CONSUMO_DIFERENCIADO_FACTURACION_REQUEST = 'CALCULO_CONSUMO_DIFERENCIADO_FACTURACION_REQUEST';
export const CALCULO_CONSUMO_DIFERENCIADO_FACTURACION_SUCCESS = 'CALCULO_CONSUMO_DIFERENCIADO_FACTURACION_SUCCESS'; 
export const CALCULO_CONSUMO_DIFERENCIADO_FACTURACION_FAILURE = 'CALCULO_CONSUMO_DIFERENCIADO_FACTURACION_FAILURE';

export const CALCULO_CD_INTERNO_PDF_REQUEST = 'CALCULO_CD_INTERNO_PDF_REQUEST';
export const CALCULO_CD_INTERNO_PDF_SUCCESS = 'CALCULO_CD_INTERNO_PDF_SUCCESS';
export const CALCULO_CD_INTERNO_PDF_FAILURE = 'CALCULO_CD_INTERNO_PDF_FAILURE';

export const CALCULO_CD_INTERNO_EXCEL_REQUEST = 'CALCULO_CD_INTERNO_EXCEL_REQUEST';
export const CALCULO_CD_INTERNO_EXCEL_SUCCESS = 'CALCULO_CD_INTERNO_EXCEL_SUCCESS';
export const CALCULO_CD_INTERNO_EXCEL_FAILURE = 'CALCULO_CD_INTERNO_EXCEL_FAILURE';

export const CALCULO_CD_FACTURACION_PDF_REQUEST = 'CALCULO_CD_FACTURACION_PDF_REQUEST';
export const CALCULO_CD_FACTURACION_PDF_SUCCESS = 'CALCULO_CD_FACTURACION_PDF_SUCCESS';
export const CALCULO_CD_FACTURACION_PDF_FAILURE = 'CALCULO_CD_FACTURACION_PDF_FAILURE';

export const CALCULO_CD_FACTURACION_EXCEL_REQUEST = 'CALCULO_CD_FACTURACION_EXCEL_REQUEST';
export const CALCULO_CD_FACTURACION_EXCEL_SUCCESS = 'CALCULO_CD_FACTURACION_EXCEL_SUCCESS';
export const CALCULO_CD_FACTURACION_EXCEL_FAILURE = 'CALCULO_CD_FACTURACION_EXCEL_FAILURE';