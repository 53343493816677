import React, { Component, message } from 'react';
import { Form, Input, Button, Modal, Row, Col, Spin, Select, Tooltip, InputNumber } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import { SaveOutlined } from '@ant-design/icons';

const layout = {  labelCol: { span: 24 },  wrapperCol: { span: 24 }, };
const { Option } = Select;

class EdtiarTipoCambio extends Component {
     formRef = React.createRef();

     render() {       
          const { fetchingEditarTipoCambio, showModalEditarTipoCambio, onEditar, onCloseModal, tipoCambioSeleccionado, limpiarFormulario, } = this.props;
          
          const onFinish = formulario => {
               console.log('Success:', formulario);
               onEditar(formulario, tipoCambioSeleccionado, this.props.page, this.props.busqueda, this.props.orden);
          };

          if (limpiarFormulario && limpiarFormulario === 'editarTipoCambio') {
               this.formRef.current.resetFields();
               this.props.onLimpiarFormulario();
          }

          

          const handleCancel = () => { onCloseModal(); }

          return (
               <Modal
               visible={showModalEditarTipoCambio}
               title='Editar Tipo de Cambio'
               onCancel={handleCancel}
               footer={false}
               >
                    <Spin spinning={fetchingEditarTipoCambio}>
                         {tipoCambioSeleccionado ? (
                         <Form {...layout}
                         name="formulario"
                         initialValues={tipoCambioSeleccionado}
                         onFinish={onFinish}
                         ref={this.formRef}
                         >
                              <Form.Item
                                   name="idTipoMonedaEditar"
                                   label='Tipo de moneda'
                                   rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                              >
                                   <Select allowClear
                                        mode='tag'
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona tipos de monedas"
                                        loading={this.props.fetchingListaTipoMonedasActivas}
                                   >
                                        {this.props.listaTipoMonedasActivas.map(option => <Option key={option.idTipoMoneda + ' - ' + option.nombre + ' (' + option.nomenclatura + ')'}>{ option.idTipoMoneda + ' - ' + option.nombre + ' (' + option.nomenclatura + ')'}</Option>)}
                                   </Select>
                              </Form.Item>

                              <Form.Item
                                   label="Tipo de cambio"
                                   name="tipoCambio"
                                   rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                              >
                                   <InputNumber prefix={"$"} min={0} style={{width: '100%'}} formatter={value =>  `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                              </Form.Item>

                              {/* <Row justify='end' gutter={[8,8]}>
                                   <Col span={1} style={{paddingRight:'2.8em', paddingTop:'2em'}}>
                                        <Tooltip title='Editar Tipo de Cambio' placement="bottom">
                                             <Button className="iconTer" shape="circle" htmlType="submit" icon={<SaveOutlined />}/>
                                        </Tooltip> 
                                   </Col>
                              </Row> */}

                              <Row justify='center' gutter={[8,8]}>
                                   <Col span={24}>
                                        <Button className="nuevoUsuario" htmlType="submit" style={{width:'100%'}}>Editar Tipo de Cambio</Button> 
                                   </Col>
                              </Row>
                         </Form>
                         ):false}
                    </Spin>
               </Modal>
          );
     }
}

const mapStateToProps = state => {
	return {
          fetchingEditarTipoCambio: state.CatalogosGeneralesReducer.fetchingEditarTipoCambio,
          showModalEditarTipoCambio: state.CatalogosGeneralesReducer.showModalEditarTipoCambio,
          tipoCambioSeleccionado: state.CatalogosGeneralesReducer.tipoCambioSeleccionado,
          
          page: state.CatalogosGeneralesReducer.page,
          busqueda: state.CatalogosGeneralesReducer.busqueda,
          orden: state.CatalogosGeneralesReducer.orden,
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          fetchingListaTipoMonedasActivas: state.CatalogosGeneralesReducer.fetchingListaTipoMonedasActivas,
          listaTipoMonedasActivas: state.CatalogosGeneralesReducer.listaTipoMonedasActivas,
	};
};

const mapDispatchToProps = dispatch => {
	return {
          onCloseModal: () => {
               dispatch({ type: 'SHOW_MODAL_EDITAR_TIPO_CAMBIO', showModalEditarTipoCambio: false})
          },
          onEditar: ( formulario, tipoCambioSeleccionado, page, busqueda, orden ) => {
               dispatch({ type: "EDITAR_TIPO_CAMBIO_REQUEST" , formulario, tipoCambioSeleccionado, page, busqueda, orden });
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EdtiarTipoCambio);