import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Row, Col, Table, Collapse, Button, Tooltip, Typography, Spin, DatePicker, Select, Form} from 'antd';
import { BarChartOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Bar } from 'react-chartjs-2';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const {Option} = Select;

const SeguimientoProductividad = () => {
     const [form] = Form.useForm();
     const dispatch = useDispatch();
     const [page, setPage] = useState(1);
     const [formularioState, setFormularioState] = useState([]);
     const [selectServicio, setSelecServicio] = useState(true);
     const [selectVariable, setSelectVariable] = useState(true);
     const [order, setOrder] = useState('ASC');
     const [field, setField] = useState('var.idVariable');

     let permisosUsuario = sessionStorage.getItem('permisosUsuario');
     if (permisosUsuario[0]) { permisosUsuario = JSON.parse(permisosUsuario);  };

     const serviciosVariablesProductividadesTable = useSelector((state)=> state.ServiciosVariablesReducer.serviciosVariablesProductividadesTable);
     const serviciosVariablesProductividadesTableCantidad = useSelector((state)=> state.ServiciosVariablesReducer.serviciosVariablesProductividadesTableCantidad);
     const fetchingServiciosVariablesProductividadesTable = useSelector((state)=> state.ServiciosVariablesReducer.fetchingServiciosVariablesProductividadesTable);
     const fetchingPDFServiciosVariablesProductividades = useSelector((state)=> state.ServiciosVariablesReducer.fetchingPDFServiciosVariablesProductividades);
     const fetchingExcelServiciosVariablesProductividades = useSelector((state)=> state.ServiciosVariablesReducer.fetchingExcelServiciosVariablesProductividades);
     const fetchingServiciosVariablesProductividades = useSelector((state)=> state.ServiciosVariablesReducer.fetchingServiciosVariablesProductividades);
     const fetchinglistaServiciosVariables = useSelector((state) => state.ServiciosVariablesReducer.fetchinglistaServiciosVariables);
     const listaServiciosVariablesBusqueda = useSelector((state) => state.ServiciosVariablesReducer.listaServiciosVariablesBusqueda);
     const dataGraficaProductividad = useSelector((state)=> state.ServiciosVariablesReducer.dataGraficaProductividad);
     const optionsGraficaProductividad = useSelector((state)=> state.ServiciosVariablesReducer.optionsGraficaProductividad);
     const listaVariablesByServicio = useSelector((state) => state.ServiciosVariablesReducer.listaVariablesByServicio);
     const fetchingListaVariablesByServicio = useSelector((state) => state.ServiciosVariablesReducer.fetchingListaVariablesByServicio);
     const listaUbicacionesFiltro = useSelector((state) =>state.CatalogosGeneralesReducer.listaUbicacionesFiltro);
     const fetchingListaUbicacionesFiltro = useSelector((state) =>state.CatalogosGeneralesReducer.fetchingListaUbicacionesFiltro);
     const listSubvariablesByVariable = useSelector((state) =>state.ServiciosVariablesReducer.listSubvariablesByVariable);
     
     useEffect(() => {
          form.resetFields();
          dispatch({ type: "BUSCAR_SERVICIOS_VARIABLES_REQUEST"})
          dispatch({ type: "LISTA_UBICACIONES_FILTRO_REQUEST", busqueda: 'adm'});
          dispatch({ type: "GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_TABLA_REQUEST", page:1, order:'ASC', field:'var.idVariable'});
          dispatch({ type: "GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_REQUEST", page:1, order:'ASC', field:'var.idVariable'});
     }, [form]);    
     
     const columns = [
          { title: 'Servicio variable', dataIndex: 'servicioVariable',  key: 'servicioVariable', align: 'center' },
          { title: 'Variable', dataIndex: 'variable', key: 'variable', align: 'center', sorter: true, }, 
          { title: 'Subvariable', dataIndex: 'subvariable',  key: 'subvariable', align: 'center' },
          { title: 'Ubicación', dataIndex: ['ubicacionByIdUbicacion','claveUbicacion'],  key: 'ubicacion', align: 'center' },
          { title: 'Asignado a ', dataIndex: 'asignadoA',  key: 'asignadoA', align: 'center' },
          { title: 'Cantidad', dataIndex: 'cantidad', key: 'cantidad', align: 'right', sorter: true, },
          { title: 'Fecha productividad', dataIndex: 'fechaProductividadTabla',  key: 'fechaProductividadTabla', align: 'center', sorter: true, },
     ];

     console.log("listaServiciosVariablesBusqueda",listaServiciosVariablesBusqueda)

     const changeServicio = (data) =>{
          if(data){
               setSelecServicio(false);
          }
          else{
               setSelecServicio(true);
          }
         
          form.setFieldsValue({
               idVariable: undefined,
          });
          form.setFieldsValue({
               idSubvariable: undefined,
          });
          dispatch({ type: "OBTENER_VARIABLES_BY_SERVICIO_REQUEST", idServicioVariable:parseInt(data)})
          dispatch({ type: "OBTENER_VARIABLES_BY_SERVICIO_REQUEST", idServicioVariable:parseInt(data)})
     }

     const changeVariable = (data) =>{
          console.log("data changeVariable",data, parseInt(data))
          dispatch({ type: "GET_SUBVARIABLE_BY_ID_VARIABLE_REQUEST", idVariable: parseInt(data)});
          setSelectVariable(false);
         
          form.setFieldsValue({
               variables: data,
          });
          console.log("variables form",form.getFieldValue("idVariable"));
     }

     const onFinish = formulario => {
          console.log('formulario:', formulario);
          setFormularioState(formulario)
          dispatch({ type: "GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_TABLA_REQUEST", servicio:formulario.servicio ? parseInt(formulario.servicio):null, page:page, idsVariables:formulario.idVariable ? formulario.idVariable.map(elem=> parseInt(elem, 10)): null, idsSubvariables:formulario.idSubvariable ? [formulario.idSubvariable] :null, idsUbicaciones:formulario.idUbicacion ? formulario.idUbicacion.map(elem=> parseInt(elem, 10)): null, order:order, field:field, fechas:formulario.fechas});
          dispatch({ type: "GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_REQUEST",  servicio:formulario.servicio ? parseInt(formulario.servicio):null, page:page, idsVariables:formulario.idVariable ? formulario.idVariable.map(elem=> parseInt(elem, 10)): null, idsSubvariables:formulario.idSubvariable ? [formulario.idSubvariable] :null, idsUbicaciones:formulario.idUbicacion ? formulario.idUbicacion.map(elem=> parseInt(elem, 10)): null, order:order, field:field, fechas:formulario.fechas });
       }; 
     
     const handleSearch = (param) =>{
          if(param){
               dispatch({ type: "LISTA_UBICACIONES_FILTRO_REQUEST", busqueda: param})
          }
     }

     const handleTableChange = (pagination, filtro, orden)=>{
          const newFormulario = form.getFieldsValue();
          console.log("newFormulario",newFormulario)
          dispatch({ type: "GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_SUCCESS", dataGraficaProductividad: {}, optionsGraficaProductividad: {} });
          setPage( pagination.current)
          if(orden){
               let campo = 'fechaProductividad';
               let order = 'DESC';

               if(orden.columnKey === 'fechaProductividadTabla'){
                    campo = 'fechaProductividad';
               } else if(orden.columnKey === 'cantidad'){
                    campo = 'cantidad';
               }
               
               if(orden.order === 'ascend'){ order = 'ASC'; }
               dispatch({ type: "GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_TABLA_REQUEST", servicio:newFormulario.servicio ? parseInt(newFormulario.servicio):null, page:page, idsVariables:newFormulario.idVariable ? newFormulario.idVariable.map(elem=> parseInt(elem, 10)): null, idsSubvariables:newFormulario.idSubvariable ? [newFormulario.idSubvariable] :null, idsUbicaciones:newFormulario.idUbicacion ? newFormulario.idUbicacion.map(elem=> parseInt(elem, 10)): null, order:order, field:campo, fechas:newFormulario.fechas});
               dispatch({ type: "GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_REQUEST", servicio:newFormulario.servicio ? parseInt(newFormulario.servicio):null, page:page, idsVariables:newFormulario.idVariable ? newFormulario.idVariable.map(elem=> parseInt(elem, 10)): null, idsSubvariables:newFormulario.idSubvariable ? [newFormulario.idSubvariable]:null, idsUbicaciones:newFormulario.idUbicacion ? newFormulario.idUbicacion.map(elem=> parseInt(elem, 10)): null, order:order, field:campo, fechas:newFormulario.fechas });
               setOrder(order)
               setField(campo)
              
          }
          else{
               dispatch({ type: "GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_TABLA_REQUEST", servicio:newFormulario.servicio ? parseInt(newFormulario.servicio):null, page:page, idsVariables:newFormulario.idVariable ? newFormulario.idVariable.map(elem=> parseInt(elem, 10)): null, idsSubvariables:newFormulario.idSubvariable ? [newFormulario.idSubvariable]:null, idsUbicaciones:newFormulario.idUbicacion ? newFormulario.idUbicacion.map(elem=> parseInt(elem, 10)): null, order:null, field:null, fechas:newFormulario.fechas});
               dispatch({ type: "GET_SERVICIOS_VARIABLES_PRODUCTIVIDADES_REQUEST", servicio:newFormulario.servicio ? parseInt(newFormulario.servicio):null, page:page, idsVariables:newFormulario.idVariable ? newFormulario.idVariable.map(elem=> parseInt(elem, 10)): null, idsSubvariables:newFormulario.idSubvariable ? [newFormulario.idSubvariable]:null, idsUbicaciones:newFormulario.idUbicacion ? newFormulario.idUbicacion.map(elem=> parseInt(elem, 10)): null, order:null, field:null, fechas:newFormulario.fechas });
          }
     };

     console.log("selectServicio",selectServicio)
     return ( 
          <Card className="cardCatalogos" title={<div className='headerLista'>Monitor de Productividad</div>}>
               <Form name="formulario" form={form} onFinish={onFinish}>
                    <Row justify='space-around'>
                         {listaServiciosVariablesBusqueda &&
                              <Col span={8}>
                                   <Form.Item name="servicio" >
                                        <Select
                                             allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Selecciona un servicio"
                                             onChange={changeServicio}
                                             loading={fetchinglistaServiciosVariables}
                                             showSearch={true}
                                        >
                                             {listaServiciosVariablesBusqueda.map(option => <Option key={option.idServicioVariable + ' - ' + option.nombre}>{option.idServicioVariable + ' - ' + option.nombre}</Option>)}
                                        </Select>
                                   </Form.Item>
                              </Col>
                         }
                         <Col span={7} offset={1}>
                              <Form.Item
                                   name="idVariable"
                              >
                                   <Select
                                        allowClear
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona una variable"
                                        disabled={selectServicio}
                                        onChange={changeVariable}
                                        loading={fetchingListaVariablesByServicio}
                                   >
                                        {listaVariablesByServicio.map(option => <Option key={option.idVariable}>{option.variable}</Option>)}
                                   </Select>
                              </Form.Item>
                         </Col>
                         <Col span={7} offset={1}>
                              <Form.Item
                                   name="idSubvariable"
                              >
                                   <Select
                                        allowClear
                                        style={{ width: '100%' }}
                                        disabled={selectVariable}
                                        placeholder="Selecciona una subvariable"
                                   >
                                        {listSubvariablesByVariable.map(option => <Option key={option.idSubvariable}>{option.nombre}</Option>)}
                                   </Select>
                              </Form.Item>
                         </Col>
                         <Col span={8} offset={1}>
                              <Form.Item
                                   name='idUbicacion'
                              >
                                   <Select
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona una ubicación"
                                        showSearch
                                        mode="multiple"
                                        allowClear
                                        loading={fetchingListaUbicacionesFiltro}
                                        filterOption={false}
                                        onSearch={handleSearch}
                                   >
                                        {listaUbicacionesFiltro.map(option => <Option key={option.idUbicacion}> {option.todoUbicacion}</Option>)}
                                   </Select>
                              </Form.Item>
                         </Col>
                         <Col span={7} offset={1}>
                              <Form.Item
                                   name="fechas"
                              >
                                   <RangePicker style={{ width: '100%' }} format={'DD/MM/YYYY'}/>
                              </Form.Item>
                         </Col>
                         
                         <Col span={1}>
                              <Tooltip title='Exportar archivo .pdf' placement="topRight">
                                   <Button disabled={selectServicio} className="iconSecundary" shape='circle' icon={<FilePdfOutlined /> } loading={fetchingPDFServiciosVariablesProductividades} onClick={() => dispatch({ type: "PDF_SERVICIOS_VARIABLES_PRODUCTIVIDADES_REQUEST", servicio:formularioState.servicio ? parseInt(formularioState.servicio):null, page:page, idsVariables:formularioState.idVariable ? formularioState.idVariable.map(elem=> parseInt(elem, 10)): null, idsSubvariables:formularioState.idSubvariable ? [formularioState.idSubvariable] : null, idsUbicaciones:formularioState.idUbicacion ? formularioState.idUbicacion.map(elem=> parseInt(elem, 10)): null, order:null, field:null, fechas:formularioState.fechas})}/>
                              </Tooltip>
                         </Col>
                         <Col span={1}>
                              <Tooltip title='Exportar archivo .xls' placement="topRight">
                                   <Button disabled={!permisosUsuario.exportarExcelCatálogoActivos} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } loading={fetchingExcelServiciosVariablesProductividades} onClick={() => dispatch({ type: "EXCEL_SERVICIOS_VARIABLES_PRODUCTIVIDADES_REQUEST", servicio:formularioState.servicio ? parseInt(formularioState.servicio):null, page:page, idsVariables:formularioState.idVariable ? formularioState.idVariable.map(elem=> parseInt(elem, 10)): null, idsSubvariables:formularioState.idSubvariable ? [formularioState.idSubvariable] : null, idsUbicaciones:formularioState.idUbicacion ? formularioState.idUbicacion.map(elem=> parseInt(elem, 10)): null, order:null, field:null, fechas:formularioState.fechas})}/>
                              </Tooltip>
                         </Col>
                         <Col span={1}>
                              <Tooltip title='Ver Productividad' placement="topRight">
                                   <Button className="iconTer" shape="circle"htmlType="submit" icon={<BarChartOutlined />}/>
                              </Tooltip>
                         </Col>
                    </Row>
               </Form>
               <Row gutter={[8,8]} justify='space-around'>
                    <Col span={24}>
                         <Collapse accordion bordered={false} style={{borderRadius:'2em'}}>
                              <Panel header={
                                   <Row justify='start' gutter={[8,8]} align='middle'>
                                        <Col span={4}>
                                             <Typography.Text strong> Tabla de datos </Typography.Text>
                                        </Col>
                                   </Row>
                              } key="1" className="site-collapse-custom-panel">
                                   <Table 
                                        size="small"
                                        rowKey="categoriaUnidadMedida"
                                        columns={columns}
                                        dataSource={serviciosVariablesProductividadesTable}
                                        loading={fetchingServiciosVariablesProductividadesTable}
                                        locale={{ emptyText: 'Sin datos' }}
                                        onChange={handleTableChange}
                                        pagination={{ total: serviciosVariablesProductividadesTableCantidad, simple: true, current: page }}
                                   />
                              </Panel>
                         </Collapse>
                    </Col>

                    <Col span={24}>
                         <Card className="cardCatalogos" bordered={false} style={{backgroundColor:'#F2F2F2'}}>
                              <Spin spinning={fetchingServiciosVariablesProductividades}>
                                   <div style={{ height: '30em' }}>
                                        <Bar data={dataGraficaProductividad} options={optionsGraficaProductividad} />
                                   </div>
                              </Spin>
                         </Card>
                    </Col>
               </Row>
          </Card>
     );
}

export default SeguimientoProductividad;