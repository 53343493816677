import React, { Component } from 'react';
import { Form, Button, Row, Col, Spin, Select, DatePicker, InputNumber, message, Input, Divider } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class EditarEstandaresDeDesempeño extends Component {
    formRef = React.createRef();
    state = {
        estandarEditado: false,
        idUnidadMedida: null,
        claseReferenciaSeleccionado: '',
        disabledPorClase: false,
        tipoEdicion: null,
        disabledSelect: false,
        //banderaTiempo: true,
    }
    render() {
        const { 
            fetchingEditarEstandaresDeDesempeño, 
            onEditarEstandaresDeDesempeño,
            limpiarFormulario, 
            onLimpiarFormulario, 
            page,
            busqueda, 
            listaInfoProyecto, 
            fetchingListaInfoProyecto, 
            EstandaresDeDesempeñoSeleccionado,
            onCambiarVista,

            fetchingListaReferenciasDeEstandaresActivos,
            listaReferenciasDeEstandaresActivos,
            fetchingListaCategoriasFallasDeCalidadActivos,
            listaCategoriasFallasDeCalidadActivos,
            fetchingListaClavesMetodosSupervisionActivos,
            listaClavesMetodosSupervisionActivos,
            unidadesMediPorCategoria,
            fetchingUnidadesMediPorCategoria,
            fetchingListaEstandaresDesempenoEspecificosActivos,
            listaEstandaresDesempenoEspecificosActivos,
            listaServiciosActivosF,
            onActualizarEstandar,
            mostrarEspecifica,
            refSeleccionado,
            banderaTiempo,
            onListaServiciosFiltroLimpiar,
            onListaServiciosFiltro,
            onActualizarReferencia,
            onActualizarTiempoRespuesta,
            listaClavesMetodosSupervisionExternos    
        } = this.props;

        if(limpiarFormulario && limpiarFormulario === 'editarEstandarDeDesempeño'){
            this.formRef.current.resetFields();
            onLimpiarFormulario();
            onCambiarVista(1, []);
            onActualizarEstandar(false);
        }

        const onFinish = formulario => {
            const rangeValue = formulario['rangoVigencia'];
            this.state.claseReferenciaSeleccionado = '';
            this.state.disabledPorClase = false;
            if(rangeValue[0]){
                formulario.rangoVigencia = rangeValue[0].format('YYYY-MM-DD') + "," + rangeValue[1].format('YYYY-MM-DD');
                if(this.state.tipoEdicion === 'simple'){
                    onEditarEstandaresDeDesempeño(formulario, page, EstandaresDeDesempeñoSeleccionado, busqueda, listaReferenciasDeEstandaresActivos, listaClavesMetodosSupervisionActivos, listaEstandaresDesempenoEspecificosActivos, listaServiciosActivosF, listaCategoriasFallasDeCalidadActivos, unidadesMediPorCategoria);
                }
                else{
                    if(EstandaresDeDesempeñoSeleccionado.tolerancia === formulario['tolerancia'] && EstandaresDeDesempeñoSeleccionado.fallaCalidadCompleta === formulario['fallaCalidadCompleta'] && EstandaresDeDesempeñoSeleccionado.tiempoRespuesta === formulario['tiempoRespuesta'] && EstandaresDeDesempeñoSeleccionado.unidadMedida === formulario['unidadMedida']){
                        console.log('terminado: ', formulario);
                        onEditarEstandaresDeDesempeño(formulario, page, EstandaresDeDesempeñoSeleccionado, busqueda, listaReferenciasDeEstandaresActivos, listaClavesMetodosSupervisionActivos, listaEstandaresDesempenoEspecificosActivos, listaServiciosActivosF, listaCategoriasFallasDeCalidadActivos, unidadesMediPorCategoria);
                    } else{
                        onCambiarVista(2, formulario);
                        console.log('Pedir contraseña');
                    }
                }
            }
            else{
                message.warning('Favor de seleccionar una fecha de inicio de vigencia', 7);
            }
        }

        

        const changeDecimales = (value) => {
            let valueDecimal = parseFloat(value);
            if(isNaN(valueDecimal)){
                valueDecimal = 0
            }
            if(this.state.tipoEdicion === 'simple'){
                this.formRef.current.setFieldsValue({'tolerancia': valueDecimal});
            }
            else{
                if(this.formRef.current.getFieldValue('rangoVigencia')[0]){
                    message.warning('Por motivos de seguridad, favor de seleccionar de nuevo la fecha de inicio de vigencia', 7);
                }
                if(valueDecimal !== EstandaresDeDesempeñoSeleccionado.tolerancia){
                    this.setState({
                        estandarEditado: true,
                    });
                } else{
                    this.setState({
                        estandarEditado: false,
                    });
                } 
                this.formRef.current.setFieldsValue({'tolerancia': valueDecimal, 'rangoVigencia': [null, EstandaresDeDesempeñoSeleccionado.rangoVigencia[1]]});
            }
        }

        const fechas = (rangeValue) => {
            if(listaInfoProyecto.fechaInicio){
                if(rangeValue[0]){
                    const fechaInicioAnterior = EstandaresDeDesempeñoSeleccionado.fechaInicioVigencia.split('T')[0];
                    const diferenciaAnterior = moment(fechaInicioAnterior, 'YYYY-MM-DD').diff(moment(rangeValue[0].format('YYYY-MM-DD')), "days");
                    console.log('diferenciaAnterior');
                    console.log(diferenciaAnterior);
                    if(this.state.estandarEditado){
                        if(diferenciaAnterior > -1){
                            message.error('No es posible seleccionar una fecha de inicio menor ni igual a la fecha de inicio anterior (' + fechaInicioAnterior.split('-').reverse().join('-') + ')', 7);
                            this.formRef.current.setFieldsValue({'rangoVigencia': [null, EstandaresDeDesempeñoSeleccionado.rangoVigencia[1]]});
                        }
                    }
                }
            }
            else{
                message.warning('No se cuenta con un contrato de proyecto, favor de agregar uno en "Información del proyecto"');
                this.formRef.current.setFieldsValue({'rangoVigencia': [null, EstandaresDeDesempeñoSeleccionado.rangoVigencia[1]]});
            }
        };

        const disableRange = fecha => {
            return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicio
        }

        const changeCampos = (value, campo) => {
            console.log('value, campo')
            console.log(value, campo)
            if(this.state.tipoEdicion === 'completa'){
                if(this.formRef.current.getFieldValue('rangoVigencia')[0]){
                    message.warning('Por motivos de seguridad, favor de seleccionar de nuevo la fecha de inicio de vigencia', 7);
                }

                if(value !== EstandaresDeDesempeñoSeleccionado[campo]){
                    this.setState({
                        estandarEditado: true
                    });
                    this.formRef.current.setFieldsValue({'rangoVigencia': [null, EstandaresDeDesempeñoSeleccionado.rangoVigencia[1]]});
                } 
                else{
                    this.setState({
                        estandarEditado: false
                    });
                }
            }
        }

        const seleccionClaseReferencia = (value) => {
            console.log('value: ', value);
            let mostrarEspecificaLocal = false;

            let referenciaSeleccionada = value.split(', ')[0];

            if(referenciaSeleccionada.length > 5){
                referenciaSeleccionada = referenciaSeleccionada + '.';
            }

            if(value.split(', ')[1] === 'General'){
                mostrarEspecificaLocal = false;
            } else{
                mostrarEspecificaLocal = true;
            }
            onActualizarEstandar(mostrarEspecificaLocal);
            //onActualizarReferencia(referenciaSeleccionada);
            console.log('Dentro de sección mostrarEspecificaLocal: ', mostrarEspecificaLocal);
            console.log('referenciaSeleccionada dentro de sección: ', referenciaSeleccionada);

            this.formRef.current.setFieldsValue({'referenciaSub': referenciaSeleccionada})
        }
       console.log('Fuera de sección mostrarEspecificaLocal: ', mostrarEspecifica);
       console.log('Fuera de sección referenciaSeleccionada: ', refSeleccionado);

       const buscarServicios = value => {
            if(value.length>=3){
                if(value){
                    console.log(value);
                onListaServiciosFiltro(value)
                } else {
                    message.warning('No se encontró servicio con esas palabras')
                }
            } else {
            onListaServiciosFiltroLimpiar();
            } 
       }
        const onSelectTipoEdicion = (value) => {
            this.setState({
                tipoEdicion: value,
                disabledSelect: true,
            })
        }

        const changeTiempoRespuesta = (value) => {
            const tiempoRespuesta = parseInt(value);
            this.formRef.current.setFieldsValue({'tiempoRespuesta': tiempoRespuesta});
            let banderaTiempo = true;

            if(tiempoRespuesta === 0){
                 banderaTiempo = false;
                 this.formRef.current.setFieldsValue({'unidadMedida': null});
            }
            onActualizarTiempoRespuesta(banderaTiempo);
            this.setState({
                 banderaTiempo: banderaTiempo
            });

            console.log('banderaTiempo: ', banderaTiempo);             
        }
        console.log('banderaTiempo fuera: ', banderaTiempo);       
        
        return (
            <div>
                {EstandaresDeDesempeñoSeleccionado ? (
                    <Spin spinning={fetchingEditarEstandaresDeDesempeño}>
                        <Form {...layout}
                        name="formulario"
                        initialValues={EstandaresDeDesempeñoSeleccionado}
                        onFinish={onFinish}
                        
                        ref={this.formRef}>
                            <Row justify='center'>
                                <Col span={5}>
                                    <p>Selecciona el tipo de edición</p>
                                </Col>
                                <Col span={9}>
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el tipo de edición"
                                        onChange={onSelectTipoEdicion}
                                        disabled={this.state.disabledSelect}
                                    >
                                        <Option key='simple'>Edición simple</Option>
                                        <Option key='completa'>Edición por convenio</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Divider/>
                            {this.state.tipoEdicion ?(
                            <Row justify='space-around'>
                            
                                <Col span={23}>
                                    <Form.Item
                                        label="Referencia"
                                        name="referenciaCompleta"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona la referencia"
                                            loading={fetchingListaReferenciasDeEstandaresActivos}
                                            onChange={seleccionClaseReferencia}
                                        >
                                            {listaReferenciasDeEstandaresActivos.map(option => <Option key={option.referencia + '.' + option.subnivel + ', ' + option.claseReferencia + ', ' + option.descripcion}>{ option.referencia + '.' + option.subnivel + ', ' + option.claseReferencia + ', ' + option.descripcion}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={11}>
                                   <Row>
                                        <Col span={4}>
                                            <Form.Item
                                            label="Referencia"
                                            name="referenciaSub"
                                            rules={[{ required: false, message: 'Favor de llenar el campo'}]}
                                            >
                                                
                                                <Input 
                                                    tittle='Referencia'
                                                    disabled={true}
                                                />
                                             </Form.Item>
                                        </Col>
                                        <Col span={20}>
                                             <Form.Item
                                             label="Específica"
                                             name="referenciaEspecifica"
                                             rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                                  <Input
                                                  pattern="^([0-9]{1,2})$"
                                                  title="Específica" 
                                                  maxLength={80} 
                                                  placeholder='Específica'
                                                  title='Sólo es permitido ingresar dos carácteres numéricos'/>
                                             </Form.Item>
                                        </Col>
                                   </Row>
                              </Col>

                                {mostrarEspecifica ===  true ? ( 
                                <Col span={11}>
                                    <Form.Item     
                                        label="Sección"
                                        name="descripcionSecciones"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Descripción del servicio"
                                            loading={fetchingListaEstandaresDesempenoEspecificosActivos}
                                        >
                                            {listaEstandaresDesempenoEspecificosActivos.map(option => <Option key={option.descripcion}>{ option.descripcion}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>                              
                                ): false } 

                                <Col span={11}>
                                    <Form.Item
                                    label="Estándar de Desempeño"
                                    name="descripcion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <TextArea
                                        pattern="^[ñíóáéú a-zA-Z ]+$"
                                        title="Descripción sin carácteres especiales" 
                                        maxLength={500} 
                                        placeholder='Descripción estándar de desempeño'
                                        autoSize />
                                    </Form.Item>
                                </Col>

                                <Col span={11}>
                                    <Form.Item
                                        label="Categoría"
                                        name="fallaCalidadCompleta"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona la categoría de falla de calidad"
                                            loading={fetchingListaCategoriasFallasDeCalidadActivos}
                                            onChange={(value)=>changeCampos(value, 'fallaCalidad')}
                                        >
                                            {listaCategoriasFallasDeCalidadActivos.map(option => <Option key={option.fallaCalidad + ', ' + option.descripcion }>{ option.fallaCalidad + ', ' + option.descripcion }</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={11}>
                                    <Form.Item
                                        label="Tolerancia "
                                        name="tolerancia"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <InputNumber min={0.00}  placeholder="Ingresa el valor de tolerancia"
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                        onChange={changeDecimales}
                                        step={1.00} />
                                    </Form.Item>
                                </Col>

                                <Col span={11}>
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Tiempo de Respuesta"
                                                name="tiempoRespuesta"
                                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                            >
                                                <InputNumber min={0} onChange={(value)=>changeCampos(value, 'tiempoRespuesta')} placeholder="Ingresa el tiempo de respuesta" 
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                onChange={changeTiempoRespuesta}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Unidad de medida"
                                                name="unidadMedida"
                                                rules={[{ required: this.state.banderaTiempo, message: 'Favor de llenar el campo', }]}
                                            >
                                                <Select allowClear
                                                style={{ width: '100%' }}
                                                placeholder="Selecciona una unidad de medida"
                                                loading={fetchingUnidadesMediPorCategoria}
                                                onChange={(value)=>changeCampos(value, 'unidadMedida')}
                                                disabled={!banderaTiempo}
                                                >
                                                    {unidadesMediPorCategoria.map(option => <Option key={option.unidadMedida}>{option.unidadMedida}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col span={11}>
                                    <Form.Item
                                        label="Método de Supervisión"
                                        name="metodosExternos"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona la clave de metodo de supervisión"
                                            loading={fetchingListaClavesMetodosSupervisionActivos}
                                        >
                                            {listaClavesMetodosSupervisionExternos.map(option => <Option key={option.claveMetodoSupervision + ', ' + option.descripcionC}>{ option.claveMetodoSupervision + ', ' + option.descripcionC}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                {listaInfoProyecto ? (     
                                    <Col span={11}>
                                        <Form.Item
                                            label="Fechas vigencia"
                                            name="rangoVigencia"
                                            rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                            <RangePicker
                                            style={{width: '100%'}} 
                                            format='DD-MM-YYYY' 
                                            onChange={fechas} 
                                            placeholder={["Ingresa fecha inicio","Fecha fin"]}
                                            loading={fetchingListaInfoProyecto}
                                            disabledDate={disableRange}
                                            disabled={[false, true]}
                                            />
                                        </Form.Item>
                                    </Col>
                                ): false}
                        
                                <Col span={11} className='marginBoton'>
                                    <br/>
                                    <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Editar Estándar</Button>
                                </Col>
                            </Row>
                            ):false}
                        </Form>
                    </Spin>
                ): false}
            </div>
        );
    }
}

const mapStateToProps = state => {
     return {
          fetchingEditarEstandaresDeDesempeño: state.MecanismosDePagosReducer.fetchingEditarEstandaresDeDesempeño,
          page: state.MecanismosDePagosReducer.page,
          busqueda: state.MecanismosDePagosReducer.busqueda,
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
          fetchingListaInfoProyecto: state.InformacionDelProyectoReducer.fetchingListaInfoProyecto,
          EstandaresDeDesempeñoSeleccionado: state.MecanismosDePagosReducer.EstandaresDeDesempeñoSeleccionado,

          fetchingListaReferenciasDeEstandaresActivos: state.MecanismosDePagosReducer.fetchingListaReferenciasDeEstandaresActivos,
          listaReferenciasDeEstandaresActivos: state.MecanismosDePagosReducer.listaReferenciasDeEstandaresActivos,
          fetchingListaCategoriasFallasDeCalidadActivos: state.MecanismosDePagosReducer.fetchingListaCategoriasFallasDeCalidadActivos,
          listaCategoriasFallasDeCalidadActivos: state.MecanismosDePagosReducer.listaCategoriasFallasDeCalidadActivos,
          listaUnidadesDeMedicionActivos: state.AlmacenesReducer.listaUnidadesDeMedicionActivos,
          fetchingListaUnidadesDeMedicionActivos: state.AlmacenesReducer.fetchingListaUnidadesDeMedicionActivos,
          fetchingListaClavesMetodosSupervisionActivos: state.MecanismosDePagosReducer.fetchingListaClavesMetodosSupervisionActivos,
          listaClavesMetodosSupervisionActivos: state.MecanismosDePagosReducer.listaClavesMetodosSupervisionActivos,
          fetchingUnidadesMediPorCategoria: state.AlmacenesReducer.fetchingUnidadesMediPorCategoria,
          unidadesMediPorCategoria: state.AlmacenesReducer.unidadesMediPorCategoria,
          fetchingListaEstandaresDesempenoEspecificosActivos: state.MecanismosDePagosReducer.fetchingListaEstandaresDesempenoEspecificosActivos,
          listaEstandaresDesempenoEspecificosActivos: state.MecanismosDePagosReducer.listaEstandaresDesempenoEspecificosActivos,
          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,
          fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
          mostrarEspecifica: state.MecanismosDePagosReducer.mostrarEspecifica,
          refSeleccionado: state.MecanismosDePagosReducer.refSeleccionado,
          banderaTiempo: state.MecanismosDePagosReducer.banderaTiempo,
          listaClavesMetodosSupervisionExternos: state.MecanismosDePagosReducer.listaClavesMetodosSupervisionExternos
     };
};

const mapDispatchToProps = dispatch => {
	return {
        onEditarEstandaresDeDesempeño: ( formulario, page, EstandaresDeDesempeñoSeleccionado, busqueda, listaReferenciasDeEstandaresActivos, listaClavesMetodosSupervisionActivos, listaEstandaresDesempenoEspecificosActivos, listaServiciosActivosF, listaCategoriasFallasDeCalidadActivos, unidadesMediPorCategoria ) => {
            dispatch({ type: "EDITAR_ESTANDAR_DESEMPEÑO_REQUEST" , formulario: formulario, page: page, EstandaresDeDesempeñoSeleccionado: EstandaresDeDesempeñoSeleccionado, busqueda: busqueda, listaReferenciasDeEstandaresActivos: listaReferenciasDeEstandaresActivos, listaClavesMetodosSupervisionActivos: listaClavesMetodosSupervisionActivos, listaEstandaresDesempenoEspecificosActivos: listaEstandaresDesempenoEspecificosActivos, listaServiciosActivosF: listaServiciosActivosF, listaCategoriasFallasDeCalidadActivos: listaCategoriasFallasDeCalidadActivos, unidadesMediPorCategoria: unidadesMediPorCategoria });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onCambiarVista: (vistaModalEstandaresDeDesempeño, formularioEstandaresDeDesempeño) => {
            dispatch({ type: 'CAMBIAR_VISTA_ESTANDAR_DESEMPEÑO', vistaModalEstandaresDeDesempeño: vistaModalEstandaresDeDesempeño, formularioEstandaresDeDesempeño: formularioEstandaresDeDesempeño, EstandaresDeDesempeñoEditados: null})
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
        },
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
        },
        onActualizarEstandar: (mostrarEspecifica) => {
            dispatch({ type: "ESTANDARES_DESEMPEÑO_BUSQUEDA_FAILURE", mostrarEspecifica: mostrarEspecifica });
        },
        onActualizarReferencia: (refSeleccionado) => {
            dispatch({ type: "ESTADO_ESTANDAR_DESEMPEÑO_FAILURE", refSeleccionado: refSeleccionado });
        },
        onActualizarTiempoRespuesta: (banderaTiempo) => {
            dispatch({ type: "LISTA_ESTANDARES_DESEMPEÑO_FAILURE", banderaTiempo: banderaTiempo})
       }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EditarEstandaresDeDesempeño);