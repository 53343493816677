import React from 'react';
import { Card, Alert, Row, Col, List } from 'antd';
import { connect } from "react-redux";
import { ShrinkOutlined, ArrowsAltOutlined, ImportOutlined } from '@ant-design/icons';

import MovimientoAlmacenContainer from './MovimientosAlmacen/MovimientoAlmacenContainer';
import CatalogoDeAlmacenesContainer from './CatalogoDeAlmacenes/CatalogoDeAlmacenesContainer';
import RequisicionDirectaContainer from './RequisiciónDirecta/RequisicionDirectaContainer';
import CatalogoTiposFoliosMovimientos from './CatalogoTiposFolioDeMovimientos/CatalogoTiposFoliosMovimientos';
import CatalogoDeProductos from './CatalogoDeProductos/CatalogoDeProductos';
import CatalogoUbicacionesProductos from './CatalogoUbicacionesDeProductos/CatalogoUbicacionesDeProductos';
import ListadoInventarioFisico from './ListadoInventarioFisicoGeneral/ListadoInvetarioFisicoGeneral';
import KardexContainer from './Kardex/KardexContainer';
import InventarioCiclicoContainer from './InventarioCiclico/InventarioCiclicoContainer';
import CapturaInventarioFisico from './CapturaInventarioFisico/CapturaInventarioFisicoContainer'
import CatalogoPuntoReorden from './RequisicionPuntodeReorden/CatalogoPuntoReorden';
import ReporteFaltantes from './ReporteFaltantesAjusteInventarioFisico/ReporteFaltantesAjusteInventarioFisico';
import VerificacionCalidadContainer from './VerificacionCalidad/VerificacionCalidadContainer';
import MovimientoHerramientasContainer from './MovimientosHerramientas/MovimientoHerramientasContainer';

import CatalogoUnidadesDeMedicion from '../ActivoFijo/CatalogoDeUnidadesDeMedicion/CatalogoDeUnidadesDeMedicion';
import CatalogoCategoriasDeMedicion from '../ActivoFijo/CatalogoCategoriasDeMedicion/CatalogoCategoriasDeMedicion';
import CatalogoActivosCaract from '../ActivoFijo/CatálogoActivosCaraterísticas/CatalogoActivosCaract';
import CatalogoDeActivos from '../ActivoFijo/CatalogoActivos/CatalogoActivos';
import CatalogoRangoRenovacionActivo from '../ActivoFijo/CatalogoRangosRenovacionActivoFijo/CatalogoRangosRenovacionActivoFijo';
import CatalogoEstatusDelActivo from '../ActivoFijo/CatalogoEstatusDelActivo/CatalogoEstatusDelActivo';
import CatalogoMotivosDeSalida from '../ActivoFijo/CatalogoMotivosDeSalida/CatalogoMotivosDeSalida';
import CatalogoFallasDisponibilidad from '../../CatalogosGenerales/CatalogoDeFallasDeDisponibilidad/CatalogoDeFallasDeDisponibilidad';
import CatalogoTipoMoneda from '../../CatalogosGenerales/CatalogoTipoMoneda/TipoMonedaContainer';
import TipoCambioContainer from '../../CatalogosGenerales/CatalogoTipoCambio/TipoCambioContainer';

import RangosValidación from '../../ConfiguracionYSeguridad/Catalogos/CatalogoDeRangosValidacion/RangosDeValidacionContainer';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

import imaSearch from '../../../assets/undraw_searching_re_3ra9.svg';
import ReactVivus from "react-vivus";


function isEmpty(obj) {
     for(var prop in obj) {
          if(obj.hasOwnProperty(prop))
               return false;
     }
     return true;
}
class CatalogoContainerAlmacenes extends React.Component {
     state={
          catalogo: 'Selecciona un Catálogo',
          colLista: 8,
          colTabla: 15,
          maximizar: false
     }

     render() {
          const { onChangeCatalogo, catalogoAlmacenes, onLimpiarTablaKardex, onNuevaConsultaKardex } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
               permisosUsuario = JSON.parse(permisosUsuario);
          }

          const listaCatalogos = [];
          if(permisosUsuario.buscarAlmacén){
               listaCatalogos.push({
                    'catalogo': 'Catálogo de Almacenes',
               })
          }
          if(permisosUsuario.buscarUbicaciónDelProducto){
               listaCatalogos.push({
                    'catalogo': 'Catálogo de Ubicación de Productos',
               })
          }
          if(permisosUsuario.buscarPih){
               listaCatalogos.push({
                    'catalogo': 'Catálogo de Productos, Insumos y Herramientas',
               })
          }
          if(permisosUsuario.buscarTipoDeFolioDeMovimiento){
               listaCatalogos.push({
                    'catalogo': 'Catálogo de Tipos de Folios de Movimiento',
               })
          }
          if(permisosUsuario.buscarCategoriaDeMedicion){
               listaCatalogos.push({
                    'catalogo': 'Catálogo de Categorías de Medición',
               });
          }
          if(permisosUsuario.buscarUnidadDeMedicion){
               listaCatalogos.push({
                    'catalogo': 'Catálogo de Unidades de Medición',
               });
          }
          if(permisosUsuario.buscarCaracterísticaDeActivos){
               listaCatalogos.push({
                    'catalogo': 'Catálogo de Características de Activos',
               });
          }
          if(permisosUsuario.verActivo){
               listaCatalogos.push({
                    'catalogo': 'Catálogo de Activos',  
               });
          }
          if(permisosUsuario.buscarEstatusDelActivo){
               listaCatalogos.push({
                    'catalogo': 'Catálogo de Estatus del Activo',
               });
          }
          if(permisosUsuario.buscarRangoDeRenovaciónParaActivosFijos){
               listaCatalogos.push({
                    'catalogo': 'Catálogo de Rangos para Renovación del Activo Fijo',
               });
          }
          if(permisosUsuario.buscarMotivosDeSalida){
               listaCatalogos.push({
                    'catalogo': 'Catálogo de Categorías de Motivos de Salida',
               });
          }
          // if(permisosUsuario.buscarNuevaCategoríaDeTiempoDeRehabilitación){
          //      listaCatalogos.push({
          //          'catalogo': 'Catálogo de Tiempos de Rehabilitación',
          //      })
          // }
          if(permisosUsuario.buscarPonderaciónDeAreaFuncional){
               listaCatalogos.push({
                   'catalogo': 'Catálogo de Tipo de Moneda',
               })
          }
          if(permisosUsuario.buscarPonderaciónDeAreaFuncional){
               listaCatalogos.push({
                   'catalogo': 'Catálogo de Tipo de Cambio',
               })
          }
          if(permisosUsuario.buscarPonderaciónDeAreaFuncional){
               listaCatalogos.push({
                   'catalogo': 'Catálogo de Rangos de Validación',
               })
          }
          function ShowInfo(props) {
               if (isEmpty(catalogoAlmacenes)) {
                    return (
                         <Row justify='space-around' align='middle'>
                        <Col span={24}>
                            <Alert message="Favor de seleccionar un catálogo" type="info" />
                        </Col>
                        <Col span={10} style={{paddingTop:'8em', paddingBottom:'8em'}}>
                            {/* <img src={imaSearch} /> */}
                            <ReactVivus
                                id="imaSearch"
                                option={{
                                    file: imaSearch,
                                    animTimingFunction: "EASE",
                                    type: "delayed",
                                }}
                            />
                        </Col>
                    </Row>);
               }
               
               return (
                    <div>
                         <CatalogoAppTab1/>
                    </div>
               );
          }

          function CatalogoAppTab1() {
               if(catalogoAlmacenes === 'Catálogo de Ubicación de Productos'){
                    return(
                         <div>
                              <CatalogoUbicacionesProductos />
                         </div>
                    );
               }
               else if(catalogoAlmacenes === 'Catálogo de Productos, Insumos y Herramientas'){
                    return(
                         <div>
                              <CatalogoDeProductos />
                         </div>
                    );
               }
               else if(catalogoAlmacenes === 'Generar Folio de Requisición Directa'){
                    return(
                         <div>
                         <RequisicionDirectaContainer/>
                         </div>
                    );
               }
               else if(catalogoAlmacenes === 'Requisición por Punto de Reorden'){
                    return(
                         <div>
                         <CatalogoPuntoReorden/>
                         </div>
                    );
               }
               else if(catalogoAlmacenes === 'Catálogo de Almacenes'){
                    return(
                         <div>
                         <CatalogoDeAlmacenesContainer/>
                         </div>
                    );
               }
               else if(catalogoAlmacenes === 'Catálogo de Tipos de Folios de Movimiento'){
                    return(
                         <div>
                              <CatalogoTiposFoliosMovimientos />
                         </div>
                    );
               }
               else if(catalogoAlmacenes === 'Registro de Movimientos de Almacén'){
                    return(
                         <MovimientoAlmacenContainer />
                    )
               }
               else if (catalogoAlmacenes === 'Listados para toma de Inventario Físico General') {
                    return(
                         <ListadoInventarioFisico />
                    )
               }
               else if(catalogoAlmacenes === 'Listado para Toma de Inventario Cíclico'){
                    return(
                         <InventarioCiclicoContainer />
                    );
               }
               else if(catalogoAlmacenes === 'Kárdex de Movimientos Entrada/Salidas al Almacén'){
                    onLimpiarTablaKardex();
                    onNuevaConsultaKardex();
                    return(
                         <KardexContainer />
                    );
               }
               else if(catalogoAlmacenes === 'Captura de Toma de Inventarios Físicos'){
                    return(
                         <CapturaInventarioFisico />
                    );
               }
               else if(catalogoAlmacenes === 'Reporte de Diferencias de Inventario'){
                    return(
                         <ReporteFaltantes />
                    );
               }
               else if(catalogoAlmacenes === 'Entrada por Verificación de Calidad'){
                    return(
                         <VerificacionCalidadContainer />
                    );
               }
               else if(catalogoAlmacenes === 'Movimientos de Herramientas'){
                    return(
                         <MovimientoHerramientasContainer />
                    );
               }
               else if(catalogoAlmacenes === 'Catálogo de Activos'){
                    return(
                         <div>
                              <CatalogoDeActivos />
                         </div>
                    );
               }
               else if(catalogoAlmacenes === 'Catálogo de Características de Activos'){
                    return(
                         <div>
                              <CatalogoActivosCaract />
                         </div>
                    );
               }
               else if(catalogoAlmacenes === 'Catálogo de Unidades de Medición'){
                    return(
                         <div>
                              <CatalogoUnidadesDeMedicion/>
                         </div>
                    );
               }
               else if(catalogoAlmacenes === 'Catálogo de Categorías de Medición'){
                    return(
                         <div>
                              <CatalogoCategoriasDeMedicion/>
                         </div>
                    );
               }
               else if(catalogoAlmacenes === 'Catálogo de Rangos para Renovación del Activo Fijo'){
                    return(
                         <div>
                              <CatalogoRangoRenovacionActivo/>
                         </div>
                    );
               }
               else if(catalogoAlmacenes === 'Catálogo de Estatus del Activo'){
                    return(
                         <div>
                              <CatalogoEstatusDelActivo/>
                         </div>
                    )
               }
               else if(catalogoAlmacenes === 'Catálogo de Categorías de Motivos de Salida'){
                    return(
                         <div>
                              <CatalogoMotivosDeSalida/>
                         </div>
                    );
               }
               // else if(catalogoAlmacenes === 'Catálogo de Tiempos de Rehabilitación'){
               //      return(
               //           <div>
                              
               //                <CatalogoFallasDisponibilidad/>
               //           </div>
               //      );
               // }
               else if(catalogoAlmacenes === 'Catálogo de Tipo de Moneda'){
                    return(
                         <div>
                              <CatalogoTipoMoneda/>
                         </div>
                    );
               }
               else if(catalogoAlmacenes === 'Catálogo de Tipo de Cambio'){
                    return(
                         <div>
                              <TipoCambioContainer/>
                         </div>
                    );
               }
               else if(catalogoAlmacenes === 'Catálogo de Rangos de Validación'){
                    return(
                         <div>
                              <RangosValidación/>
                         </div>
                    );
               }
               return <div>  </div>;
          };
          
          const changeCatalogo = (catalogo) => {
               this.setState({
                    catalogo: catalogo.catalogo,
               })
               onChangeCatalogo(catalogo.catalogo);
          }
          const onExpandir = () => {
               if(!this.state.maximizar){
                    this.setState({
                         colLista: 0,
                         colTabla: 24,
                         maximizar: true
                    });
               }
               else{
                    this.setState({
                         colLista: 8,
                         colTabla: 15,
                         maximizar: false,
                    });
               }
          }

        
          return (
               <div className="imgCorp">
                    <Row justify='space-between'>
                         <Col span={this.state.colLista}>
                         <List
                              header={
                                   <div className='headerLista'>Catálogos</div>
                              }
                              className="listaCatalogos"
                              bordered
                              dataSource={listaCatalogos}
                              renderItem={item => (
                              <List.Item className='listaHover'
                                   actions={[<ImportOutlined onClick={(e) => { e.stopPropagation(); changeCatalogo(item); } } className="iconAbrir" />]}
                              >
                                   <div>{item.catalogo}</div>
                              </List.Item>
                              )}
                         />                    
                         </Col>
                         <Col span={this.state.colTabla}>
                         <Card className="cardCatalogos" title={<div className='headerLista'>{this.state.catalogo}</div>} extra={this.state.maximizar ? (<ShrinkOutlined className='iconoMaxMin' onClick={onExpandir} />):(<ArrowsAltOutlined className='iconoMaxMin' onClick={onExpandir}/>)}>
                              <ShowInfo info={catalogoAlmacenes} />
                         </Card> 
                         </Col>
                    </Row>
               </div>
          );
     }
}

const mapStateToProps = state => {
     return {
          vistaDashboard: state.ConfiguracionYSeguridadReducer.vistaDashboard,
          catalogoAlmacenes: state.AlmacenesReducer.catalogoAlmacenes,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onRequestLogin: (loginInfo) => {
               dispatch({ type: "LOGIN_API_CALL_REQUEST", loginInfo:loginInfo });
          },
          onChangeCatalogo: (catalogoAlmacenes) => {
               dispatch({ type: "CAMBIAR_CATALOGO_ALMACENES" , catalogoAlmacenes });
          },
          onLimpiarTablaKardex: () => {
               dispatch({ type: 'KARDEX_OBTENER_MOVIMIENTOS_SUCCESS', listaKardexMovimientos: [], dataMovimientosSumas: [], dataMovimientosRestas: []})
          },
          onNuevaConsultaKardex: () => {
               dispatch({ type: 'KARDEX_PRODUCTOS_EXISTENETES_SUCCESS', kardexEncabezado: false, kardexCardsMovimientos: false });
          },
     };
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoContainerAlmacenes);