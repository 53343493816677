import React, { Component } from 'react';
import { Form, Input, Button, Col, Spin, Modal, Row, Select, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

import L from 'leaflet';
import { Map, ImageOverlay, FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import VerificarContraseña from './VerificarContraseña';
import ArchivoConvenioUnidad from './ArchivoConvenioUnidad';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { Option } = Select;
class ModalNuevoUnidadesFuncionales extends Component {
    formRef = React.createRef();

    state = {
        fileImage: false,
        botonImage: false,
        botonEditar: false,
        fetchingImagen: false,
        imageUrl: null,
        height: 0,
        width: 0,
        geometry: [],
    }

    render() {
        const { fetchingNuevoUnidadesFuncionales, showModalNuevoUnidadesFuncionales, onNuevoUnidadesFuncionales, onShowModalNuevoUnidadesFuncionales, limpiarFormulario,
            listaAreasFuncionalesActivos, fetchingListaAreasFuncionalesActivos, cambiarVistaModalUnidad,
            bloqueado, busqueda, page
        } = this.props;

        
        if (limpiarFormulario && limpiarFormulario === 'crearUnidadFuncional') {
            if(bloqueado){
                this.formRef.current.resetFields();
            }
            this.props.onLimpiarFormulario();
            this.setState({
                fileImage: false,
                botonImage: false,
                botonEditar: false,
                fetchingImagen: false,
                imageUrl: null,
                height: 0,
                width: 0,
                geometry: [],
            });
        }

        
        // const enteroInput = (value) => {
        //     this.formRef.current.setFieldsValue({'cantidad': parseInt(value)});
        // }

        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };
    
        const onChangeImage = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info);
            }
            else {
                this.setState({
                    ...this.state,
                    botonImage: true,
                    botonEditar: true,
                })
            }
            if (info.file.status === 'done') {
                var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,40}$");
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['plano']);
                    this.setState({...this.state, botonImage: false});
                    
                }
                else {
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        ...this.state,
                        botonImage: true,
                        fileImage: true,
                    });
                }
            } else if (info.file.status === 'error') {
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['plano']);
                    this.setState({...this.state, botonImage: false});
                }
                else {
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        ...this.state,
                        fileImage: true
                    });
                }
                this.setState({
                    ...this.state,
                    botonImage: false,
                    botonEditar: false,
                });
            }
        }
        
        const onRemoveImage = () => {
            this.setState({
                ...this.state,
                fileImage: false,
                botonImage: false,
            });
        }
        
        const onFinish = formulario => {
            const {geometry, imageUrl} = this.state;
            if(!imageUrl){
                return message.error('La área funcional seleccionada no cuenta con un plano. Favor de seleccionar una área funcional con plano.');
            }
            if(geometry.length>1){
                return message.error('La unidad funcional cuenta con más de una geometría. Favor de trazar solo una geometría.');
            }
            if(geometry.length === 0) {
                return message.error('Favor de trazar una geometría para la unidad funcional');
            }
            if(bloqueado){
                onNuevoUnidadesFuncionales(formulario, geometry, page, busqueda, bloqueado);
            }
            else{
                this.props.onCambiarVista(2, formulario, geometry);
            }
            console.log('Success:', formulario, geometry);
            
        };

        const handleCancel = () => {
            onShowModalNuevoUnidadesFuncionales();
            this.props.onCambiarVista(1, null, null)
            if(this.formRef.current){
                this.formRef.current.resetFields();
            }
            
            this.setState({
                fileImage: false,
                botonImage: false,
                botonEditar: false,
                fetchingImagen: false,
                imageUrl: null,
                height: 0,
                width: 0,
                geometry: [],
            });
        }

        const onAreaFuncional = (value) => {
            console.log(value);
            this.setState({
                ...this.state,
                fetchingImagen: true,
            })
            if(value) {
                let rutaImagen = null; 
                for(let i in listaAreasFuncionalesActivos){
                    if(parseInt(value) === listaAreasFuncionalesActivos[i].idAreaFuncional){
                        rutaImagen = listaAreasFuncionalesActivos[i].rutaImagen
                    }
                }
                const img = new Image();
                const updateState = (width, height) => {
                this.setState({
                    ...this.state,
                    imageUrl: rutaImagen,
                    width, 
                    height,
                    fetchingImagen: false,
                    });
                }
                img.onload = function() {
                    console.log(this.width + 'x' + this.height);
                    updateState(this.width, this.height);
                }
                img.src = rutaImagen;

                if(!rutaImagen){
                    return this.setState({...this.state, imageUrl: null, width: 0, height: 0});
                }

            }
            this.setState({...this.state, imageUrl: null, width: 0, height: 0});
        }

        const bounds = [
            [0, 0],
            [this.state.height, this.state.width],
        ]
        const style = { height: '30em', width: '100%' }

        const setGeometry = (layer) => {
            this.setState({...this.state, geometry: [...this.state.geometry, layer]});
        }
        const _created = (e) => {
            const {layer, layerType} = e;
            if(this.state.geometry.length>0){
                message.warning('No se permite más de una geometría para el área funcional');
            }
            if(layerType === 'polygon') {
                const {_leaflet_id} = layer;
                setGeometry({id: _leaflet_id, latlngs: layer.getLatLngs()[0]});
            }
            
        }

        const deleteGeometry = (geometriesD) => {
            let geoArray = [...this.state.geometry]
            geometriesD.map(deleted => {
                geoArray = geoArray.filter(geo => geo.id !== deleted)
            });
            
            this.setState({...this.state, geometry: geoArray});
        }
        const _deleted = (e) =>{ 
            const {layers: {_layers}} = e;
            let deletedArray = [];
            Object.values(_layers).map(({_leaflet_id, editing}) =>{
                //edited array
                deletedArray = [...deletedArray, _leaflet_id ]
            });
            deleteGeometry(deletedArray);
        }

        const getGeoJson = () => {
            console.log(`this.state.geometry`, this.state.geometry)
            return {
                type: "FeatureCollection",
                features: [{
                    type: "Feature",
                    properties: {},
                    geometry: {
                        type: "Polygon",
                        coordinates: 
                            this.state.geometry
                    }
                }]
            }
        }

        const _onFeatureGroupReady = reactFGref => {
            // populate the leaflet FeatureGroup with the geoJson layers
            if(!reactFGref ) return;

            let leafletGeoJSON = new L.GeoJSON(getGeoJson());
            let leafletFG = reactFGref.leafletElement;
            
            let layers = leafletFG._layers;
            let layers_ids = Object.keys(layers);
            
            if(layers_ids.length === 0) {
                //add layers
                leafletGeoJSON.eachLayer(layer => {
                    leafletFG.addLayer(layer);
                });
            }
        };
        
        return (
            <Modal
            visible={showModalNuevoUnidadesFuncionales}
            title='Nueva Unidad Funcional'
            onCancel={handleCancel}
            footer={false}
            width={'80%'}
            >
                {cambiarVistaModalUnidad === 1 ? (
                <Spin spinning={fetchingNuevoUnidadesFuncionales}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Área funcional a la que pertenece"
                                    name="idAreaFuncional"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona la área funcional"
                                        onChange={onAreaFuncional}
                                        loading={fetchingListaAreasFuncionalesActivos}
                                    >
                                        {listaAreasFuncionalesActivos.map(option => <Option key={option.idAreaFuncional}>{option.areaFuncional}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Unidad funcional"
                                    name="unidadFuncional"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={100} placeholder="Ingresa el nombre de unidad funcional"/>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Clave unidad funcional"
                                    name="claveUnidad"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={30} placeholder="Ingresa la clave de unidad funcional" title='Sólo debe de contener letras mayúscula' pattern="^[A-ZÑ ÁÉÓÚÍ0-9]+$"/>
                                </Form.Item>
                            </Col>
                            {/* <Col span={11}>
                                <Form.Item
                                    label="Cantidad"
                                    name="cantidad"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <InputNumber min={1} max={100} onChange={enteroInput} placeholder="Ingresa la cantidad" />
                                </Form.Item>
                            </Col> */}
                            <Col span={11}>
                                <Form.Item
                                    label="Plano"
                                    name="imagen"
                                    rules={[{ required: true, message: 'Favor de adjuntar plano', }]}
                                >
                                    <Upload  customRequest={dummyRequest} onChange={onChangeImage} onRemove={onRemoveImage} accept='.jpg, .jpeg, .png, .svg' multiple={false} style={{ width: '100%' }}>
                                        {this.state.fileImage === false ? (
                                        <Button disabled={this.state.botonImage} className='btnCargaArchivos'>
                                            <UploadOutlined /> Click para Adjuntar Plano
                                        </Button>
                                        ):false}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={23}>
                                <Spin spinning={this.state.fetchingImagen}>
                                    {this.state.imageUrl ? (<p>Geometría</p>):(<p>Favor de seleccionar una área funcional con plano</p>)}
                                    
                                    {this.state.imageUrl ? (
                                    <Map
                                        crs={L.CRS.Simple}
                                        minZoom={-4}
                                        bounds={bounds}
                                        style={style}
                                    >
                                        <FeatureGroup
                                            ref={reactFGref => {
                                            _onFeatureGroupReady(reactFGref);
                                            }}
                                        >
                                            <EditControl
                                                position="topright"
                                                onCreated={_created}
                                                onDeleted={_deleted}
                                                draw={{
                                                    circle: false,
                                                    circlemarker: false,
                                                    marker: false,
                                                    rectangle: false,
                                                    polyline: false,
                                                }}
                                                edit={{
                                                    remove: true,
                                                    edit: false,
                                                }}
                                            />
                                        </FeatureGroup>
                                        <ImageOverlay
                                            url={this.state.imageUrl}
                                            bounds={bounds}
                                            zIndex={10}
                                        />
                                    </Map>
                                    ):false}
                                </Spin>
                            </Col>
                            
                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" htmlType="submit" style={{width: '100%'}}>Crear Unidad Funcional</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
                ):(
                <>
                    {cambiarVistaModalUnidad === 2 ? (
                    <VerificarContraseña />
                    ):(
                    <ArchivoConvenioUnidad />
                    )}
                </>
                )}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingNuevoUnidadesFuncionales: state.CatalogosGeneralesReducer.fetchingNuevoUnidadesFuncionales,
        showModalNuevoUnidadesFuncionales: state.CatalogosGeneralesReducer.showModalNuevoUnidadesFuncionales,
        page: state.CatalogosGeneralesReducer.page,
        busqueda: state.CatalogosGeneralesReducer.busqueda,
        bloqueado: state.CatalogosGeneralesReducer.bloqueado,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaAreasFuncionalesActivos: state.CatalogosGeneralesReducer.listaAreasFuncionalesActivos,
        fetchingListaAreasFuncionalesActivos: state.CatalogosGeneralesReducer.fetchingListaAreasFuncionalesActivos,
        cambiarVistaModalUnidad: state.CatalogosGeneralesReducer.cambiarVistaModalUnidad,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalNuevoUnidadesFuncionales: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_UNIDADESFUNCIONALES', showModalNuevoUnidadesFuncionales: false })
        },
        onNuevoUnidadesFuncionales: ( formulario, geometry, page, busqueda, bloqueado ) => {
            dispatch({ type: "NUEVO_UNIDADESFUNCIONALES_REQUEST" , formulario, geometry, page, busqueda, bloqueado });
        },
        onCambiarVista: (cambiarVistaModalUnidad, formularioUnidad, editedGeometry) => {
            dispatch({ type: "CAMBIAR_VISTA_UNIDAD", cambiarVistaModalUnidad, formularioUnidad, editedGeometry })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        },
        onShowModalMapa: () => {
            dispatch({ type: 'SHOW_MODAL_MAPA_UNIDAD_FUNCIONAL', showModalMapaUnidadFuncional: true})
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoUnidadesFuncionales);