import React, { Component } from 'react';
import {Row, Col, Modal, Descriptions, Spin} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';

class ModalDetalleTicketReiteracion extends Component {
    formRef = React.createRef();

     render() {
          const { onCloseModalDetalleTicketReiterado, showModalDetalleTicketReiterado,  ticketReiteradoSeleccionado,
          } = this.props;

          let data=[];
          if (ticketReiteradoSeleccionado){
               let { folio, servicio, areaFuncional, nombreFalla, descripcionSolicitud, fechaAltaDescription, fechaCierreDescription } = ticketReiteradoSeleccionado;
               
               data = [
                    {label: 'Número de ticket', data: folio, key: 'folio'},
                    {label: 'Fecha de alta', data: fechaAltaDescription, key: 'fechaAltaDescription'},
                    {label: 'Fecha de cierre', data: fechaCierreDescription, key: 'fechaCierreDescription'},
                    {label: 'Servicio', data: servicio, key: 'servicio'},
                    {label: 'Área funcional', data: areaFuncional, key: 'areaFuncional'},
                    {label: 'Falla', data: nombreFalla, key: 'nombreFalla'},
                    {label: 'Descripción', data: descripcionSolicitud, key: 'descripcionSolicitud'},
               ];
          }

          const handleCancel = () => {
               onCloseModalDetalleTicketReiterado();
          };
        
          return (
               <Modal
                    visible={showModalDetalleTicketReiterado}
                    title='Detalle Ticket Reiterado'
                    onCancel={handleCancel}
                    footer={false}
                    width={'60%'}
               >
                    <Row justify='space-around'>
                         {ticketReiteradoSeleccionado ? (
                              <Col span={24}>
                                   <Spin spinning={false}>
                                        <Descriptions layout="horizontal" bordered= {true} >
                                             {data.map(item => 
                                                  <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                             )}
                                        </Descriptions>
                                   </Spin>
                              </Col>
                         ):false}
                    </Row>
               </Modal>
          )
     }
}

const mapStateToProps = state => {
     return {
          showModalDetalleTicketReiterado: state.MecanismosDePagosReducer.showModalDetalleTicketReiterado,
          fetchingListaTicketsReiterados: state.MecanismosDePagosReducer.fetchingListaTicketsReiterados,
          ticketReiteradoSeleccionado: state.MecanismosDePagosReducer.ticketReiteradoSeleccionado,
     };
};

const mapDispatchToProps = dispatch => {
     return {
          onCloseModalDetalleTicketReiterado: () => {
               dispatch({ type: 'SHOW_MODAL_DETALLE_TICKET_REITERADO', showModalDetalleTicketReiterado: false });
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetalleTicketReiteracion);