import React from 'react';
import {connect} from 'react-redux';
import {Table, Row, Col, Modal} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';


class ModalDetalleDepositos extends React.Component {
    
    render () {
        const {fetchingListaDepositosServiciosPublicos, listaDepositosServiciosPublicos, showModalDetalleDepositosSP, 
            servicioPublicoSeleccionado, onShowModalDetalle} = this.props;

        const columns = [{
            title: 'Nombre depósito',
            dataIndex: 'nombreDeposito',
            key: 'nombreDeposito',
        },{
            title: 'Ubicación',
            dataIndex: 'ubicacion',
            key: 'ubicacion',
        },{
            title: 'Capacidad',
            dataIndex: 'capacidad',
            key: 'capacidad',
            align: 'center'
        },{
            title: 'Unidad de medida',
            dataIndex: 'unidadMedida',
            key: 'unidadMedida',
            align: 'center'
        }];
        
        const handleCancel = () => {
            onShowModalDetalle()
        }

        return (
            <div>
                {servicioPublicoSeleccionado ? (
                    <Modal
                        visible={showModalDetalleDepositosSP}
                        title={`Depositos del Servicio Público: '${servicioPublicoSeleccionado.nombre}'`}
                        onCancel={handleCancel}
                        footer={false}
                        width='70%'
                    >
                        <Row justify='space-between'>
                            <Col span={24}>
                                <Table
                                    size='small'
                                    rowKey='nombreDeposito'
                                    columns={columns}
                                    dataSource={listaDepositosServiciosPublicos}
                                    loading={fetchingListaDepositosServiciosPublicos}
                                    locale={{ emptyText: 'Sin datos' }}
                                    pagination={{ pageSize: listaDepositosServiciosPublicos.length }} 
                                />
                            </Col>
                        </Row>
                    </Modal>
                ) : false}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaDepositosServiciosPublicos: state.ServiciosPublicosReducer.fetchingListaDepositosServiciosPublicos,
        listaDepositosServiciosPublicos: state.ServiciosPublicosReducer.listaDepositosServiciosPublicos,
        showModalDetalleDepositosSP: state.ServiciosPublicosReducer.showModalDetalleDepositosSP,
        servicioPublicoSeleccionado: state.ServiciosPublicosReducer.servicioPublicoSeleccionado,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onShowModalDetalle: () => {
            dispatch( {type: 'SHOW_MODAL_DETALLE_DEPOSITOS', showModalDetalleDepositosSP: false, servicioPublicoSeleccionado: null})
        },
    }

}

export default connect(mapStateToProps, mapDispatchToProps) (ModalDetalleDepositos);