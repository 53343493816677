import React, { Component } from 'react';
import { Form, Input, Button, Modal, Row, Col, Spin, Switch, Tooltip } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import { LaptopOutlined } from '@ant-design/icons';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalNuevoGrupoServicio extends Component {
    formRef = React.createRef();

    state={
        equipamientoTec: false
    }

    render() {
        const { fetchingNuevoGrupoServicio, showModalNuevoGrupoServicio, onNuevoGrupoServicio, onShowModalNuevoGrupoServicio,
            limpiarFormulario, onLimpiarFormulario} = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onNuevoGrupoServicio(formulario, this.props.page, this.state.equipamientoTec);
        };
        

        console.log('limpiarFormulario');
        console.log(limpiarFormulario);
        if (limpiarFormulario && limpiarFormulario === 'crearGrupoServicio') {
            this.formRef.current.resetFields();
            onLimpiarFormulario();
        }

        const handleCancel = () => {
            onShowModalNuevoGrupoServicio();
            this.formRef.current.resetFields();
        }

        const onSeleccionarEquip = (value) => { this.setState({ equipamientoTec: value }); };

        return (
            <Modal
            visible={showModalNuevoGrupoServicio}
            title='Nuevo Grupo de Servicio'
            onCancel={handleCancel}
            footer={false}
            >
                <Spin spinning={fetchingNuevoGrupoServicio}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={24}>
                                <Form.Item
                                    label="Nombre"
                                    name="nombre"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={80} placeholder="Ingrese nombre " />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Descripción"
                                    name="descripcion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={120} placeholder="Ingrese descripcion" />
                                </Form.Item>
                            </Col>
                        
                            <Col span={12}>
                                <Form.Item
                                    name='equipamientoTec'
                                >
                                    <Tooltip title='Equipamiento tecnológico' placement="bottom">
                                        <Switch checkedChildren={<LaptopOutlined />} unCheckedChildren={<LaptopOutlined />} defaultChecked={false} onChange={onSeleccionarEquip} />
                                    </Tooltip>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Button className="btnFiltrar" htmlType="submit" style={{width: '100%'}} >Crear Grupo de Servicio</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingNuevoGrupoServicio: state.CatalogosGeneralesReducer.fetchingNuevoGrupoServicio,
        showModalNuevoGrupoServicio: state.CatalogosGeneralesReducer.showModalNuevoGrupoServicio,
        page: state.CatalogosGeneralesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalNuevoGrupoServicio: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_GRUPO_SERVICIO', showModalNuevoGrupoServicio: false})
        },
        onNuevoGrupoServicio: ( formulario, page, equipamientoTec ) => {
            dispatch({ type: "NUEVO_GRUPO_SERVICIO_REQUEST" , formulario: formulario, page: page, equipamientoTec });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoGrupoServicio);