import React from 'react';
import { Card, Table, Row, Col, Button, Switch, Modal, Spin, Select, Tooltip } from 'antd';
import { EditOutlined, FormOutlined, IssuesCloseOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevaPreguntaEncuesta from './ModalNuevaPreguntaEncuesta';
import ModalEditarPreguntaEncuesta from './ModalEditarPreguntaEncuesta';
import GenerarFolioEncuesta from './GenerarFolioEncuesta';

import imaSearch from '../../../assets/undraw_voting_nvu7.svg';
import ReactVivus from "react-vivus";

const confirm = Modal.confirm;
const {Option} = Select;
class EmisionEncuestaContainer extends React.Component {
     state = {
          page: 1,
          step: 0,
          busqueda: {
               idServicio: null,
               año: null,
          }
     }
     componentDidMount(){
          this.props.onListaServiciosActivos();
          this.props.onListaAñosPregunta();
     }
     render() {
          const { onListaPreguntasEncuesta, fetchingListaPreguntasEncuesta, fetchingRepetirPonderadoresEncuesta,
               listaPreguntasEncuesta, listaPreguntasEncuestaCantidad, onEstadoPreguntaEncuesta,
               onShowModalNuevoPreguntaEncuesta, onShowModalEditarPreguntaEncuesta, fetchingListaAñosPregunta,
               listaAñosPregunta, fetchingListaServiciosActivosF, listaServiciosActivosF,
          } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
             permisosUsuario = JSON.parse(permisosUsuario);
          }

          const columns = [{
                    title: 'Pregunta',
                    dataIndex: 'pregunta',
                    key: 'pregunta',
                    width: '22%',
               }, {
                    title: 'Año de vigencia',
                    dataIndex: 'anio',
                    key: 'anio',
                    width: '8%',
               }, {
                    title: 'Servicio',
                    dataIndex: 'servicio',
                    key: 'servicio',
                    width: '22%',
               }, {
                    title: 'Fecha alta',
                    dataIndex: 'fechaAlta',
                    key: 'fechaAlta',
                    width: '10%',
               }, {
                    title: 'Activo', 
                    dataIndex: 'activo', 
                    key: 'activo',
                    width: '10%',
                    align: 'center',
                    render: (text, record) => (
                         <Switch checked={text} disabled={!permisosUsuario.preguntaEncuestaDeSatisfacciónActivoInactivo} onChange={() => handleDesactivar(record)}/>
                    ),
               },{
                    title: 'Editar',
                    key: 'editar',
                    width: '10%',
                    align: 'center',
                    render: (text, record) => (
                         <a className='editarIcono' disabled={!permisosUsuario.editarPreguntaEncuestaDeSatisfacción} onClick={() => onEditar(record)}><EditOutlined /></a>
                    ),
               }
          ];

          const handleDesactivar = (key) => {
               console.log(key);
               const page = this.state.page;
               confirm({
                    title: '¿Estás seguro que deseas cambiar el estado de la pregunta de encuestas de satisfacción?',
                    content: '',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                         onEstadoPreguntaEncuesta(key.idPreguntaEncuesta, page)
                    },
                    onCancel() {
                         console.log('Cancel');
                    },
               });
          }

          const onEditar = (key) => {
               console.log(key);
               onShowModalEditarPreguntaEncuesta(key, this.state.page);
          }

          const onNuevoPreguntaEncuesta = () => {
               onShowModalNuevoPreguntaEncuesta(this.state.page, this.state.busqueda);
          }

          const handleTableChange = (pagination)=>{
               this.setState({
                   page: pagination.current,
               })
               onListaPreguntasEncuesta(pagination.current, this.state.busqueda);
          };

          const onAltaEncuesta = () => {
               this.setState({
                    step: 1,
               });
               onListaPreguntasEncuesta(1, this.state.busqueda);
          }

          const onEmisionEncuesta = () => {
               this.setState({
                    step: 2,
               });
          }

          const onCancelarEmisionEncuesta = () => {
               this.setState({
                    step: 0,
               });
          }

          const onAñoSeleccionado = (value) => {
               if(!value){
                    value = null;
               }
               const busqueda = {
                    idServicio: this.state.busqueda.idServicio,
                    año: value,
               }
               this.setState({
                    busqueda,
               })
               onListaPreguntasEncuesta(this.state.page, busqueda);
          }
          const onServicioSeleccionado = (value) => {
               if(!value){
                    value = null;
               }
               const busqueda = {
                    idServicio: value,
                    año: this.state.busqueda.año,
               }
               this.setState({
                    busqueda,
               })
               onListaPreguntasEncuesta(this.state.page, busqueda);
          }
          return (
               <Card className="cardCatalogos" title={<div className='headerLista'>Emisión de Encuestas de Satisfacción</div>}>
                    <Row justify='space-between'>
                         <Col span={10}>
                              <Button disabled={this.state.step !== 0 ? true : !permisosUsuario.altaDeEncuestaDeSatisfacción} onClick={onAltaEncuesta} className='botonesEmision'>Alta de Encuesta de Satisfacción <FormOutlined /></Button> 
                         </Col>
                         <Col span={10}>
                              <Button disabled={this.state.step !== 0 ? true : !permisosUsuario.emisiónDeEncuestaDeSatisfacción} onClick={onEmisionEncuesta} className='botonesEmision'>Emisión de Encuesta de Satisfacción <IssuesCloseOutlined /></Button> 
                         </Col>
                    </Row>
                    <br/>
                    {this.state.step === 1 ? (
                    <Spin spinning={fetchingRepetirPonderadoresEncuesta}>
                         <Row justify='space-around'>
                              <Col span={7}>
                                   <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona un año"
                                        loading={fetchingListaAñosPregunta}
                                        showSearch={true}
                                        onChange={onAñoSeleccionado}
                                   >
                                        {listaAñosPregunta.map(option => 
                                        <Option key={option}>
                                             {option}
                                        </Option>
                                        )}
                                   </Select>
                              </Col>
                              <Col span={7}>
                                   <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona un servicio"
                                        loading={fetchingListaServiciosActivosF}
                                        showSearch={true}
                                        onChange={onServicioSeleccionado}
                                    >
                                        {listaServiciosActivosF.map(option => 
                                        <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                            <Tooltip title={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                {option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}
                                            </Tooltip>
                                        </Option>
                                        )}
                                   </Select>
                              </Col>
                              <Col span={8}>
                                   <Button onClick={onNuevoPreguntaEncuesta} className='nuevoUsuario'>Nueva Pregunta de Encuesta de Satisfacción</Button>
                              </Col>
                              <Col span={24}>
                                   <br/>
                                   <Table 
                                        size="small" 
                                        rowKey="idPreguntaEncuesta"
                                        columns={columns}
                                        dataSource={listaPreguntasEncuesta}
                                        loading={fetchingListaPreguntasEncuesta}
                                        locale={{ emptyText: 'Sin datos' }}
                                        onChange={handleTableChange}
                                        pagination={{ total: listaPreguntasEncuestaCantidad, simple: true, current: this.state.page}}
                                   />         
                              </Col>
                         </Row>
                         <Row justify='start'>
                              <Col span={8}>
                                   <Button onClick={onCancelarEmisionEncuesta} className='btnCargaArchivos'>Cancelar <CloseCircleOutlined /></Button> 
                              </Col>
                         </Row>
                    </Spin> 
                    ):(
                         this.state.step === 2 ? (
                              <Row justify='start'>
                                   <Col span={24}>
                                        <GenerarFolioEncuesta/>
                                        <br/>
                                        <br/>
                                   </Col>
                                  
                                   <Col span={8}>
                                        <Button onClick={onCancelarEmisionEncuesta} className='btnCargaArchivos'>Cancelar <CloseCircleOutlined /></Button> 
                                   </Col>
                              </Row>
                              ):(
                                   <Row justify='center' justify='space-around' align='middle' gutter={[8,8]}>
                                        <Col span={8} style={{paddingTop:'4em', paddingBottom:'1em', textAlign:'center'}}>
                                        {/* <img src={imaSearch} /> */}
                                             <ReactVivus
                                                  id="imaSearch"
                                                  option={{
                                                       file: imaSearch,
                                                       animTimingFunction: "EASE",
                                                       type: "delayed",
                                                  }}
                                             />
                                        </Col>
                                   </Row>
                              )
                    ) }
                    {/* {this.state.step === 2 ? (
                    <Row justify='start'>
                         <Col span={24}>
                              <GenerarFolioEncuesta/>
                              <br/>
                              <br/>
                         </Col>
                        
                         <Col span={8}>
                              <Button onClick={onCancelarEmisionEncuesta} className='btnCargaArchivos'>Cancelar <CloseCircleOutlined /></Button> 
                         </Col>
                    </Row>
                    ):false} */}
                    <ModalNuevaPreguntaEncuesta/>
                    <ModalEditarPreguntaEncuesta/>
               </Card>
          );
     }
}
 
const mapStateToProps = state => {
     return {
          fetchingListaPreguntasEncuesta: state.GestionDeCalidadReducer.fetchingListaPreguntasEncuesta,
          listaPreguntasEncuesta: state.GestionDeCalidadReducer.listaPreguntasEncuesta,
          listaPreguntasEncuestaCantidad: state.GestionDeCalidadReducer.listaPreguntasEncuestaCantidad,
          fetchingRepetirPonderadoresEncuesta: state.GestionDeCalidadReducer.fetchingRepetirPonderadoresEncuesta,
          listaAñosPonderadoresEncuesta: state.GestionDeCalidadReducer.listaAñosPonderadoresEncuesta,
          fetchingAñosPonderadoresEncuesta: state.GestionDeCalidadReducer.fetchingAñosPonderadoresEncuesta,
          listaAñosPregunta: state.GestionDeCalidadReducer.listaAñosPregunta,
          fetchingListaAñosPregunta: state.GestionDeCalidadReducer.fetchingListaAñosPregunta,
          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onListaAñosPregunta: () => {
               dispatch({ type: 'LISTA_AÑOS_PREGUNTAS_REQUEST' });
          },
          onListaPreguntasEncuesta: (page, busqueda) => {
               dispatch({ type: 'LISTA_PREGUNTAS_ENCUESTA_REQUEST', page, busqueda });
          },
          onEstadoPreguntaEncuesta: (idPreguntaEncuesta, page, busqueda) => {
               dispatch({ type: 'ESTADO_ENCUESTA_REQUEST', idPreguntaEncuesta, page, busqueda });
          },
          onShowModalNuevoPreguntaEncuesta: (page, busqueda) => {
               dispatch ({ type: 'MODAL_NUEVA_PREGUNTA_ENCUESTA', showModalNuevaPreguntaEncuesta: true, page, busqueda });
          },
          onShowModalEditarPreguntaEncuesta: (preguntaEncuestaSeleccionado, page, busqueda) => {
               dispatch ({ type: 'MODAL_EDITAR_PREGUNTA_ENCUESTA', showModalEditarPreguntaEncuesta: true, preguntaEncuestaSeleccionado, page, busqueda });
          },
          onRequestInfoProyecto: () => {
               dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
          },
          onListaServiciosActivos: () => {
               dispatch ({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST' });
          },
     };
};
 export default connect(mapStateToProps, mapDispatchToProps)(EmisionEncuestaContainer);