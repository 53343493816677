import React, { Component } from 'react';
import { Form, Button, Row, Col, Spin, Modal, Select, Table, Input, Radio , Divider} from 'antd';
import { connect } from "react-redux";
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
const { Option } = Select;
const { TextArea } = Input;
const layout = { 
    labelCol: { span: 24 }, 
    wrapperCol: { span: 24 }
 };
class ModalReingresoActivo extends Component {
    formRef = React.createRef();
    state={
        selectedRowKeys: [],
        selected: false,
        idAreaFuncional: null,
        idUnidadFuncional: null,
        idInteriorFuncional: null,
        idSubInteriorFuncional: null,
        reingresoCheck: 'Asignacion'
    }
    componentDidMount(){
        this.props.onGetAreas()
        this.props.onGetUsuarios()
        this.props.onGetUnidades()
        this.props.onGetInteriores()
        this.props.onGetSubInteriores()
        this.props.onGetEstadosActivos()
    }

    render() { 
        const {
            onNuevoReingresoDeActivos,
            showModalReingresoActivos,
            fetchingNuevoReingresoActivo,
            onShowModalReingresoActivos,
            reingresoSeleccionado,
            limpiarFormulario,
            listaUsuariosActivos,
            fetchingListaUsuariosActivos,
            listaEstatusActivoActivos,
            fetchingListaEstatusActivoActivos,
            listaAreasFuncionalesActivas,
            fetchingListaAreasFuncionalesActivas,
            listaUnidadesFuncionalesActivas,
            fetchingListaUnidadesFuncionalesActivas,
            listaInteriorFuncional,
            fetchingListaInteriorFuncional,
            listaSubinteriorFuncional,
            fetchingListaSubinteriorFuncional,
            onGetUnidades,
            onGetInteriores,
            onGetSubInteriores,
            onLimpiarSeleccionado
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        };

        const cambiarAsignacion = (reingresoCheck) => {
            console.log(reingresoCheck.target.value)
            this.setState({
                reingresoCheck: reingresoCheck.target.value
            });
        }

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onNuevoReingresoDeActivos(formulario, reingresoSeleccionado, this.props.page);
            this.props.limpiarTablaAsignacion()
            onLimpiarSeleccionado()
        };

        if (limpiarFormulario && limpiarFormulario === 'crearReingresoActivos') {
            this.props.onLimpiarFormulario();
            this.formRef.current.resetFields();
        }

        

        const handleCancel = () => {
            onShowModalReingresoActivos();
            this.formRef.current.resetFields();
        }

        const onUnidadesFuncionales = (idAreaFuncional) => {            
            this.formRef.current.resetFields(['idUnidadFuncional','idInteriorFuncional', 'idSubInteriorFuncional']);
           onGetUnidades(idAreaFuncional);
           this.setState({ idAreaFuncional: idAreaFuncional, selectedRowKeys: [] });
            
        }

        const onInterioresFuncionales = (idUnidadFuncional) => {
            this.formRef.current.resetFields(['idInteriorFuncional', 'idSubInteriorFuncional']);
            onGetInteriores(this.state.idAreaFuncional, idUnidadFuncional);
            this.setState({ idUnidadFuncional: idUnidadFuncional, selectedRowKeys: []});
        }

        const onSubInterioresFuncionales = (idInteriorFuncional) => {
            this.formRef.current.resetFields(['idSubInteriorFuncional']);
            onGetSubInteriores(this.state.idAreaFuncional, this.state.idUnidadFuncional, idInteriorFuncional);
             this.setState({ idInteriorFuncional: idInteriorFuncional, selectedRowKeys: [] });
            
        }
        const columns = [{
            title: 'Folio de Salida',
            dataIndex: 'folio',
            key: 'folio',
        },{
            title: 'Descripción',
            dataIndex: 'descripcion',
            key: 'descripcion', 
        },{
            title: 'Código de Equipo',
            dataIndex: 'codigoEquipo',
            key: 'codigoEquipo',
        },{
           title: 'Código Interno',
           dataIndex: 'codigoInterno',
           key: 'codigoInterno',
        },{
            title: 'Num Serie',
            dataIndex: 'serieNum',
            key: 'serieNum',
        },{
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
        },{
            title: 'Clave Ubicación',
            dataIndex: 'claveUbicacion',
            key: 'claveUbicacion',
        }
    ];

    console.log('reingresoSeleccionado', reingresoSeleccionado);

        return ( 
            <Modal
            visible={showModalReingresoActivos}
            title='Reingreso de Activos'
            onCancel={handleCancel}
            width='90%'
            footer={false}
            >
                <Spin spinning={fetchingNuevoReingresoActivo}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Row justify='space-around'>

                        <Col span={11}>
                                <Form.Item
                                    label="Asignar Ubicacion"
                                    name="reingresoCheck"
                                >
                                    <Radio.Group onChange={cambiarAsignacion} defaultValue='Asignacion'>
                                        <Radio value='Asignacion'>Asignacion de Ubicacion</Radio>
                                        <Radio value='SinAsignacion'>Sin Asignacion de Ubicacion</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                         {this.state.reingresoCheck === 'Asignacion' ? (
                             <>
                             <Col span={11}>
                             <Form.Item
                                 label="Área Funcional"
                                 name="idAreaFuncional"
                                 rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                             >
                                 <Select allowClear
                                     style={{ width: '100%' }}
                                     placeholder="Seleccione el área funcional"
                                     loading={fetchingListaAreasFuncionalesActivas}
                                     onChange={onUnidadesFuncionales}
                                 >
                                     {listaAreasFuncionalesActivas.map(option => <Option key={option.idAreaFuncional}>{ option.areaFuncional }</Option>)}
                                 </Select>
                             </Form.Item>
                         </Col>
                           
                          <Col span={11}>
                               <Form.Item
                                   label="Unidades Funcionales"
                                   name="idUnidadFuncional"
                                   rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                               >
                                   <Select allowClear
                                       style={{ width: '100%' }}
                                       placeholder="Seleccione el unidad funcional"
                                       loading={fetchingListaUnidadesFuncionalesActivas}
                                       onChange={onInterioresFuncionales}
                                   >
                                       {listaUnidadesFuncionalesActivas.map(option => <Option key={option.idUnidadFuncional}>{ option.unidadFuncional }</Option>)}
                                   </Select>
                               </Form.Item>
                           </Col>
                            
                                <Col span={11}>
                                <Form.Item
                                    label="Interior"
                                    name="idInteriorFuncional"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Seleccione el interior funcional"
                                        loading={fetchingListaInteriorFuncional}
                                        onChange={onSubInterioresFuncionales}
                                    >
                                        {listaInteriorFuncional.map(option => <Option key={option.idInteriorFuncional}>{ option.interiorFuncional }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            
                                 <Col span={11}>
                                 <Form.Item
                                     label="Subinterior"
                                     name="idSubInteriorFuncional"
                                 >
                                     <Select allowClear
                                         style={{ width: '100%' }}
                                         placeholder="Seleccione el subinterior funcional"
                                         loading={fetchingListaSubinteriorFuncional}
                    
                                     >
                                         {listaSubinteriorFuncional.map(option => <Option key={option.idSubInteriorFuncional}>{ option.subInteriorFuncional }</Option>)}
                                     </Select>
                                 </Form.Item>
                             </Col>

                           
                            <Col span={11}>
                                <Form.Item
                                    label="Usuario Responsable"
                                    name="idUsuario"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Seleccione el usuario responsable"
                                        loading={fetchingListaUsuariosActivos}
                                    >
                                        {listaUsuariosActivos.map(option => <Option key={option.idUsuario}>{ option.nombre + " " + option.aPaterno + ": " + option.username}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={11}>
                                <Form.Item
                                    label="Estatus del Activo"
                                    name="idEstadoActivo"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Seleccione el usuario responsable"
                                        loading={fetchingListaEstatusActivoActivos}
                                    >
                                        {listaEstatusActivoActivos.map(option => <Option key={option.idEstadoActivo}>{ option.descripcionC }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={11}>
                                <Form.Item
                                    label="Comentarios de Reingreso"
                                    name="comentarioReingreso"
                                >
                                     <TextArea
                                    pattern="^[ñíóáéú a-zA-Z0-9 ]+$"
                                    title="Comentarios de reingreso sin carácteres especiales" 
                                    maxLength={300} 
                                    placeholder='Comentarios de reingreso'
                                    autoSize />
                                </Form.Item>
                            </Col>
                            </>
                         ) : (
                             <>
                            <Col span={11}>
                            <Form.Item
                                label="Usuario Responsable"
                                name="idUsuario"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Seleccione el usuario responsable"
                                    loading={fetchingListaUsuariosActivos}
                                >
                                    {listaUsuariosActivos.map(option => <Option key={option.idUsuario}>{ option.nombre + " " + option.aPaterno + ": " + option.username}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={11}>
                            <Form.Item
                                label="Estatus del Activo"
                                name="idEstadoActivo"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Seleccione el usuario responsable"
                                    loading={fetchingListaEstatusActivoActivos}
                                >
                                    {listaEstatusActivoActivos.map(option => <Option key={option.idEstadoActivo}>{ option.descripcionC }</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={11}>
                            <Form.Item
                                label="Comentarios de Reingreso"
                                name="comentarioReingreso"
                            >
                                 <TextArea
                                pattern="^[ñíóáéú a-zA-Z0-9 ]+$"
                                title="Comentarios de reingreso sin carácteres especiales" 
                                maxLength={300} 
                                placeholder='Comentarios de reingreso'
                                autoSize />
                            </Form.Item>
                        </Col>
                        </>
                         )}
                        <Divider orientation="left">Activos Seleccionados</Divider>
                            <Col span={23}>
                                <Form.Item
                                   // label="Activos Seleccionados"
                                >
                                    <Table 
                                        size="small" 
                                        rowKey="idMovimientoActivo" 
                                        columns={columns}
                                        dataSource={reingresoSeleccionado}        
                                        locale={{ emptyText: 'Sin datos'}}
                                        pagination={false}
                                       
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={11}>
                                <br/> <br/>
                                <Button htmlType='submit' className="btnFiltrar" style={{width: '100%'}}>Guardar Cambios</Button> 
                            </Col>

                        </Row>
                    </Form>
                </Spin>
            </Modal>
         );
    }
}
const mapStateToProps = state => {
    return {
        reingresoSeleccionado: state.AlmacenesReducer.reingresoSeleccionado,
        fetchingNuevoReingresoActivo: state.AlmacenesReducer.fetchingNuevoReingresoActivo,
        showModalReingresoActivos: state.AlmacenesReducer.showModalReingresoActivos,
        page: state.AlmacenesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaUsuariosActivos: state.AlmacenesReducer.listaUsuariosActivos,
        fetchingListaUsuariosActivos: state.AlmacenesReducer.fetchingListaUsuariosActivos,
        listaEstatusActivoActivos: state.AlmacenesReducer.listaEstatusActivoActivos,
        fetchingListaEstatusActivoActivos: state.AlmacenesReducer.fetchingListaEstatusActivoActivos,
        listaAreasFuncionalesActivas: state.AlmacenesReducer.listaAreasFuncionalesActivas,
        fetchingListaAreasFuncionalesActivas: state.AlmacenesReducer.fetchingListaAreasFuncionalesActivas,
        listaUnidadesFuncionalesActivas: state. AlmacenesReducer.listaUnidadesFuncionalesActivas,
        fetchingListaUnidadesFuncionalesActivas: state.AlmacenesReducer.fetchingListaUnidadesFuncionalesActivas,
        listaInteriorFuncional: state.AlmacenesReducer.listaInteriorFuncional,
        fetchingListaInteriorFuncional: state.AlmacenesReducer.fetchingListaInteriorFuncional,
        listaSubinteriorFuncional: state.AlmacenesReducer.listaSubinteriorFuncional,
        fetchingListaSubinteriorFuncional: state.AlmacenesReducer.fetchingListaSubinteriorFuncional,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalReingresoActivos: () => {
            dispatch({ type: "SHOW_MODAL_NUEVO_REINGRESO", showModalReingresoActivos: false, reingresoSeleccionado: null})
        },
        onNuevoReingresoDeActivos: (formulario, reingresoSeleccionado, page) => {
            dispatch({ type: "NUEVO_REINGRESO_ACTIVO_REQUEST", formulario: formulario, reingresoSeleccionado: reingresoSeleccionado, page: page})
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
        },
        onGetUsuarios: () => {
            dispatch({ type: 'LISTA_USUARIOS_ACTIVOS_REQUEST' });
        },
        onGetEstadosActivos: () => {
            dispatch({ type: "LISTA_ESTATUS_ACTIVOS_REQUEST"})
        },
        onGetAreas: () => {
            dispatch({ type: "LISTA_AREA_FUNCIONAL_ACTIVA_REQUEST", });
        },
        onGetUnidades: (idAreaFuncional) => {
            dispatch({ type: "LISTA_UNIDAD_FUNCIONAL_REQUEST" , idAreaFuncional: idAreaFuncional});
        },
        onGetInteriores: (idAreaFuncional, idUnidadFuncional) => {
            dispatch({ type: "LISTA_INTERIOR_FUNCIONAL_REQUEST", idAreaFuncional: idAreaFuncional, idUnidadFuncional: idUnidadFuncional });
        },
        onGetSubInteriores: (idAreaFuncional, idUnidadFuncional, idInteriorFuncional) => {
            dispatch({ type: "LISTA_SUBINTERIOR_FUNCIONAL_REQUEST", idAreaFuncional: idAreaFuncional,  idUnidadFuncional: idUnidadFuncional, idInteriorFuncional: idInteriorFuncional});
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalReingresoActivo)