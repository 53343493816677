import React from 'react';
import { Card, Table, Row, Col, Button, Input, Form, DatePicker } from 'antd';
import { SearchOutlined} from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Sistema.css';

class ListaLogs extends React.Component {
    state = {
        page: 1,
        formulario: {
            usuario: '',
            modulo: '',
            fecha: '',
        },
        ordenamiento: {
            orden: 'DESC',
            campo: 'idBitacoraSistema',
        }
    }
    componentDidMount(){
        this.props.onRequestLogs(1, this.state.formulario, this.state.ordenamiento);
    }
    
    render() {
        const { fetchingListaLogs, listaLogs, listaLogsCantidad, onRequestLogs } = this.props;

        const columns = [{
                title: 'ID',
                dataIndex: 'idBitacoraSistema',
                key: 'idBitacoraSistema',
                width: '5%',
                sorter: true,
            },{
                title: 'Acción',
                dataIndex: 'accion',
                key: 'accion',
                width: '10%',
            },{
                title: 'Fecha',
                dataIndex: 'fechaLog',
                key: 'fechaLog',
                width: '10%',
                sorter: true,
            },{
                title: 'Hora',
                dataIndex: 'horaLog',
                key: 'horaLog',
                width: '10%',
            },{
                title: 'Módulo',
                dataIndex: 'modulo',
                key: 'modulo',
                width: '10%',
                sorter: true,
            },{
                title: 'Usuario',
                dataIndex: 'usuario',
                key: 'usuario',
                width: '15%',
                sorter: true,
            },{
                title: 'Campo',
                dataIndex: 'campo',
                key: 'campo',
                width: '10%',
            },{
                title: 'Tabla',
                dataIndex: 'tabla',
                key: 'tabla',
                width: '10%',
            },{
                title: 'Valor anterior',
                dataIndex: 'valorAnterior',
                key: 'valorAnterior',
                width: '15%',
            },{
                title: 'Valor actual',
                dataIndex: 'valorActual',
                key: 'valorActual',
                width: '15%',
            },
        ];

        const onFinish = formulario => {
            if(formulario['fecha']){
                formulario.fecha = formulario['fecha'].format('YYYY-MM-DD')
            }
            
            this.setState({
                formulario: formulario,
                page: 1,
            })
            console.log(formulario);
            onRequestLogs(1, formulario, this.state.ordenamiento,);
        }

        

        const handleTableChange = (pagination, filtro, orden)=>{
            console.log('orden');
            console.log(orden);
            console.log(this.state.formulario);
            let ordenamiento = this.state.ordenamiento;
            if(orden){
                if(orden.order === 'ascend'){
                    ordenamiento.orden = 'ASC';
                }
                else{
                    ordenamiento.orden = 'DESC';
                }

                if(orden.field === 'usuario'){
                    ordenamiento.campo = 'us.username';
                }
                else if(orden.field === 'fechaLog'){
                    ordenamiento.campo = 'fecha';
                }
                else if(orden.field === 'modulo'){
                    ordenamiento.campo = 'mode.nombre';
                }
                else{
                    ordenamiento.campo = 'idBitacoraSistema';
                }
            }
            this.setState({
                page: pagination.current,
                ordenamiento: ordenamiento,
            })
            console.log('ordenamiento');
            console.log(ordenamiento);
            onRequestLogs(pagination.current, this.state.formulario, ordenamiento);
		}

        const scroll = {"x": 1600 | true, "y": 1000};
        return (
            <div>
                <Card className="cardCatalogos" title={<div className='headerLista'>Auditoría del Sistema</div>}>
                    <Row justify='space-around'>
                        <Col span={24}>
                            <Form
                                name="formulario"
                                onFinish={onFinish}
                                
                            >
                                <Row justify='space-around'>
                                    <Col span={7}>
                                        <Form.Item
                                            name="usuario"
                                        >
                                            <Input allowClear className='inputFiltro' placeholder="Ingresa usuario a filtrar" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item
                                            name="modulo"
                                        >
                                            <Input allowClear className='inputFiltro' placeholder="Ingresa módulo a filtrar" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                        <Form.Item
                                            name="fecha"
                                        >
                                            <DatePicker allowClear style={{ width: '100%' }} format='DD-MM-YYYY' placeholder={["Selecciona fecha a filtrar"]} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Button shape="circle" icon={<SearchOutlined />} className="btnFiltrar" htmlType="submit"></Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                    <Table 
                        className='tablaUsuarios'
                        locale={{ emptyText: 'Sin datos' }}
                        rowKey="idBitacoraSistema" 
                        columns={columns}
                        dataSource={listaLogs}
                        loading={fetchingListaLogs}
                        onChange={handleTableChange}
                        pagination={{ total: listaLogsCantidad, simple: true, current: this.state.page }}
                        scroll={scroll}
                    />
                </Card> 
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        fetchingListaLogs: state.ConfiguracionYSeguridadReducer.fetchingListaLogs,
        listaLogs: state.ConfiguracionYSeguridadReducer.listaLogs,
        listaLogsCantidad: state.ConfiguracionYSeguridadReducer.listaLogsCantidad,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onRequestLogs: (page, formulario, ordenamiento) => {
	    	dispatch({ type: "OBTENER_LOGS_REQUEST", page: page, formulario: formulario, ordenamiento: ordenamiento });
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ListaLogs);