import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {Table, Row, Col, Modal, Spin, Form, Select, DatePicker, Button, InputNumber, Radio, Divider, Alert, Input, message} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option } = Select;
const { RangePicker } = DatePicker;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalCDDInterno extends React.Component {
    formRef = React.createRef();

    state = {
        lecturas: false,
        selectedRowKeys: [],
        totalConsumo: null,
        dataSeleccionada: [],
        dataArchivo: [],
    }

    componentDidMount() {
        this.props.onRequestInfoProyecto();

    }

    render() {

        const {limpiarFormulario, showModalCalculoCDInterno, fetchingCalculoCDInterno, listaServiciosPublicosCalculo, fetchingListaServiciosPublicosCalculo, listaInfoProyecto,
            listaDepositosServiciosPublicos, fetchingListaCDInterno, listaCDInterno, consumoTotal, lecturasInicioFinArray,  onShowModalCCDInterno, onGetContenedoresSPA, onGetLecturasDepositos,
            onResetLecturasDepositos, onCrearCalculo,onDescargarPDF, onDescargarExcel } = this.props;
        
        const {lecturas, selectedRowKeys, totalConsumo, dataSeleccionada,dataArchivo} = this.state;

        
        if (limpiarFormulario && limpiarFormulario === 'CCDI') {
            this.formRef.current.resetFields();
            onResetLecturasDepositos();
            this.props.onLimpiarFormulario();
            this.setState({lecturas: false, selectedRowKeys:[], totalConsumo: null, dataSeleccionada:[], dataArchivo: []})
        }
        
        const columns = [{
            title: 'Nombre depósito',
            dataIndex: 'nombreDeposito',
            key: 'nombreDeposito',
        }, {
            title: 'Ubicación',
            dataIndex: 'ubicacion',
            key: 'ubicacion',
        },  {
            title: 'Variable de medición',
            dataIndex: 'variableMedicion',
            key: 'variableMedicion',
        }, {
            title: 'Lectura inicio período',
            dataIndex: 'lecturaInicio',
            key: 'lecturaInicio',
        }, {
            title: 'Lectura fin período',
            dataIndex: 'lecturaFin',
            key: 'lecturaFin',
        }, {
            title: 'Consumo',
            dataIndex: 'consumo',
            key: 'consumo',
        }];

        const handleLecturas = (value, data) => {
            onResetLecturasDepositos();
            this.setState({lecturas: false, selectedRowKeys:[], totalConsumo: null, dataSeleccionada:[], dataArchivo: []})
            this.formRef.current.setFieldsValue({'tipo': undefined, 'consumoGeneral': undefined, 'precioUnitario': undefined, 
                'precioConsumo': undefined})
            if(data == 'select' && value) {
                onGetContenedoresSPA({idServicioPublico:value});
            }
        }

        const disabledRange = fecha => {
            const fechaVigencia = moment(listaInfoProyecto.fechaVigencia, "DD-MM-YYYY")
            const fechaInicio = moment(listaInfoProyecto.fechaInicio, "DD-MM-YYYY")
            if (fecha && fecha < fechaInicio) {
                return fecha && fecha < fechaInicio;
            } else {
                return fecha && fecha > fechaVigencia;
            }
        }

        const onGetLecturas = () => {
            const idServicioPublico = this.formRef.current.getFieldValue('idServicioPublico');
            let periodo = this.formRef.current.getFieldValue('periodo');
            this.formRef.current.setFieldsValue({'sumaConsumoDepositos': 0});
            this.formRef.current.setFieldsValue({'cobroConsumo':0});
            if (idServicioPublico && periodo) {
                const inicioPeriodo = periodo[0].format('YYYY-MM-DD');
                const finPeriodo = periodo[1].format('YYYY-MM-DD'); 
                this.setState({lecturas: true})
                console.log(idServicioPublico, inicioPeriodo, finPeriodo);
                onGetLecturasDepositos(idServicioPublico,listaDepositosServiciosPublicos, inicioPeriodo, finPeriodo)
            }else{
                message.warning('Por favor seleccione un servicio y período.')
            }
        }

        const changeCosto = (value, campo) => {
            const precio = campo == 'precioUnitario' ?  parseFloat(value).toFixed(3) : parseFloat(value).toFixed(2);
            this.formRef.current.setFieldsValue({[campo]: precio})
            const precioUnitario = campo=='precioUnitario' ? precio : null;
            if(totalConsumo && precioUnitario) {
                const cobroConsumo = (totalConsumo * precioUnitario).toFixed(2);
                this.formRef.current.setFieldsValue({'cobroConsumo':cobroConsumo});
            }
        }

        const onSelectChange = selectedRowKeys => {
            const arrayIndex = value => selectedRowKeys.find(item => value.idAsignacionServicioPublico == item );
            const dataSeleccionada = listaCDInterno.filter(item => arrayIndex(item));
            const totalConsumo = dataSeleccionada.reduce((accum, value) => (accum + value.consumo) , 0);
            this.formRef.current.setFieldsValue({'consumoGeneral':consumoTotal});
            this.formRef.current.setFieldsValue({'sumaConsumoDepositos': totalConsumo});
            this.setState({ selectedRowKeys, totalConsumo, dataSeleccionada });
            const precioUnitario = this.formRef.current.getFieldValue('precioUnitario');
            this.formRef.current.setFieldsValue({'cobroConsumo':0});
            if(totalConsumo && precioUnitario) {
                console.log('cambio')
                const cobroConsumo = (totalConsumo * precioUnitario).toFixed(2);
                this.formRef.current.setFieldsValue({'cobroConsumo':cobroConsumo});
            }
            this.setState({dataArchivo:[]});
        };

        const rowSelection = {
            selectedRowKeys,
            onChange: onSelectChange,
        };

        const onFinish = formulario => {
            console.log(formulario);
            const idServicioPublico = this.formRef.current.getFieldValue('idServicioPublico');
            let {tipo="", periodo=[], consumoGeneral="", precioUnitario="", precioConsumo="", sumaConsumoDepositos="", cobroConsumo=0} = formulario;
            const finPeriodo = periodo[1].format('YYYY-MM-DD'); 
            const inicioPeriodo = periodo[0].format('YYYY-MM-DD');
            let data = dataSeleccionada.map( item => {
                let dataObject = {idConsumoDiferenciado: null, idServicioPublico: idServicioPublico, idAsignacionServicioPublico: null, folio: null, tipo: tipo, periodoInicio: inicioPeriodo, periodoFin: finPeriodo, consumoGeneral: parseInt(consumoGeneral), precioUnitario: parseFloat(precioUnitario),
                    precioConsumo:parseFloat(precioConsumo), consumo: null, total: parseFloat(sumaConsumoDepositos), cobroConsumo: parseFloat(cobroConsumo), fechaAlta: null}
                dataObject['idAsignacionServicioPublico'] = item.idAsignacionServicioPublico;
                dataObject['consumo'] = item.consumo;
                return dataObject
            });
            onCrearCalculo(idServicioPublico, data)
            this.setState({dataArchivo: data})
        }

        const onFinishFailed = () => {
            console.log('Cancel');
        }

        const onDescargarArchivo = (type) => {
            console.log(type);
            const idServicioPublico = this.formRef.current.getFieldValue('idServicioPublico');
            const ubicacionCompleta = dataSeleccionada.map( item => {
                let ubicacionObject = {};
                ubicacionObject.idAsignacionServicioPublico = item.idAsignacionServicioPublico;
                ubicacionObject.ubicacionCompleta = item.ubicacion;
                return ubicacionObject;
            })
            console.log(ubicacionCompleta);
            if (type == 'PDF') {
                onDescargarPDF(idServicioPublico, dataArchivo, lecturasInicioFinArray, ubicacionCompleta);
            }
            if (type == 'Excel') {
                onDescargarExcel(idServicioPublico, dataArchivo, lecturasInicioFinArray, ubicacionCompleta);
            }
        }

        
        const handleCancel = () => {
            onShowModalCCDInterno();
            this.setState({lecturas: false, selectedRowKeys:[], totalConsumo: null, dataSeleccionada:[], dataArchivo: []})
            this.formRef.current.resetFields();
        }

        return (
            <div>
                <Modal
                    visible={showModalCalculoCDInterno}
                    title={`Cálculo de Consumo Diferenciado Interno`}
                    onCancel={handleCancel}
                    footer={false}
                    width='90%'
                >
                    <Spin spinning={fetchingListaCDInterno || fetchingCalculoCDInterno}>
                        <Form {...layout}
                            name='formulario'
                            onFinish={onFinish}
                            
                            ref={this.formRef}
                        >   
                            {!lecturas ? 
                            <Alert
                                message={"Favor de seleccionar un servicio público de la lista y un período de consumo para obtener lecturas."}
                                type="info"
                            />
                            : false}
                            <Row type='flex' justify='space-around' align="middle">
                                <Col span={15}>
                                    <Form.Item
                                        label='Servicio públicos'
                                        name='idServicioPublico'
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select
                                            allowClear
                                            style={{width: '100%', textAlign: 'left'}}
                                            placeholder='Seleccione un servicio público'
                                            loading={fetchingListaServiciosPublicosCalculo}
                                            onChange = {value => handleLecturas(value, 'select')}
                                            >
                                            {listaServiciosPublicosCalculo.map(option => <Option key={option.idServicioPublico} value={option.idServicioPublico} nombreDeposito={option.nombreDeposito}>{option.nombre}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        label="Periodo"
                                        name="periodo"
                                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                    >
                                        <RangePicker
                                            format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio", "Fecha fin"]}
                                            disabledDate={disabledRange}
                                            onChange={value => handleLecturas(value, 'rangePicker')} />
                                    </Form.Item>
                                </Col>
                                <Col span={1}  style={{paddingTop:'14px', paddingLeft:'10px'}}>
                                    <Button shape="circle" icon={<SearchOutlined />} className="btnFiltrar"  onClick={onGetLecturas}></Button>
                                </Col>
                            </Row>

                            {lecturas &&  listaCDInterno.length >0 ? 
                                (
                                <div>
                                    <Divider style={{ marginTop: '16px', marginBottom: '16px' }} />
                                    <Row type='flex' justify='space-around'>
                                        <Col span={5}>
                                            <Form.Item
                                                label='Tipo'
                                                name='tipo'
                                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                            >
                                                <Radio.Group>
                                                    <Radio value="Desarrollador">Desarrollador</Radio>
                                                    <Radio value="Pyrs">Pyrs</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label='Consumo general o total'
                                                name='consumoGeneral'
                                            >
                                                <Input disabled defaultValue={consumoTotal} style={{ textAlign: 'right' }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label='Precio unitario'
                                                name='precioUnitario'
                                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                            >
                                                <InputNumber min={0} style={{ width: '100%' }}
                                                    onChange={value => changeCosto(value, 'precioUnitario')}
                                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                    placenholder="Ingrese precio unitario" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label='Precio por consumo'
                                                name='precioConsumo'
                                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                            >
                                                <InputNumber min={0} style={{ width: '100%' }}
                                                    onChange={value => changeCosto(value, 'precioConsumo')}
                                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                    placeholder="Ingrese el precio por consumo" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Divider style={{ marginTop: '16px', marginBottom: '16px' }} />
                                    <Row justify='space-around'>
                                        <Col span={24}>
                                            <Table
                                                size='small'
                                                rowKey='idAsignacionServicioPublico'
                                                rowSelection={rowSelection}
                                                columns={columns}
                                                dataSource={listaCDInterno} 
                                                loading={fetchingListaCDInterno}
                                                locale={{ emptyText: 'Sin datos' }}
                                                pagination={{ pageSize: listaCDInterno.length }}
                                            />
                                        </Col>
                                    </Row>
                                    <Divider style={{ marginTop: '16px', marginBottom: '16px' }} />
                                    <Row type='flex' justify='space-around' align="middle">
                                        <Col span={11}>
                                            <Row type='flex' justify='space-between'>
                                                <Col span={11}>
                                                    <Form.Item
                                                        label='Total consumido'
                                                        name='sumaConsumoDepositos'
                                                    >
                                                        <InputNumber disabled style={{ textAlign: 'right' }} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={11}>
                                                    <Form.Item
                                                        label='Cobro por consumo'
                                                        name='cobroConsumo'
                                                    >
                                                        <InputNumber disabled style={{ textAlign: 'right' }}
                                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={11} style={{ paddingTop: '20px' }}>
                                            <Button  disabled={!dataSeleccionada.length>0} className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Crear Cálculo de Consumo Diferenciado Interno</Button>
                                        </Col>
                                    </Row>
                                    <Row type='flex' justify='space-around'>
                                        <Col span={11}>
                                            <Button disabled={!dataArchivo.length>0} className="btnFiltrar" style={{ width: '100%' }} onClick={()=> onDescargarArchivo('Excel')}>Exportar a Excel</Button>
                                        </Col>
                                        <Col span={11}>
                                            <Button disabled={!dataArchivo.length>0} className="btnFiltrar" style={{ width: '100%' }} onClick={()=> onDescargarArchivo('PDF')}>Exportar a PDF</Button>
                                        </Col>
                                    </Row>
                                </div>
                            )
                            : false}
                        </Form>

                    </Spin>

                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        showModalCalculoCDInterno: state.ServiciosPublicosReducer.showModalCalculoCDInterno,
        fetchingCalculoCDInterno: state.ServiciosPublicosReducer.fetchingCalculoCDInterno,
        listaServiciosPublicosCalculo: state.ServiciosPublicosReducer.listaServiciosPublicosCalculo,
        fetchingListaServiciosPublicosCalculo: state.ServiciosPublicosReducer.fetchingListaServiciosPublicosCalculo,
        listaCalculosServiciosPublicos: state.ServiciosPublicosReducer.listaCalculosServiciosPublicos,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        fetchingListaCDInterno: state.ServiciosPublicosReducer.fetchingListaCDInterno,
        listaCDInterno: state.ServiciosPublicosReducer.listaCDInterno,
        listaDepositosServiciosPublicos: state.ServiciosPublicosReducer.listaDepositosServiciosPublicos,
        fetchingListaDepositosServiciosPublicos: state.ServiciosPublicosReducer.fetchingListaDepositosServiciosPublicos,
        consumoTotal: state.ServiciosPublicosReducer.consumoTotal,
        lecturasInicioFinArray: state.ServiciosPublicosReducer.lecturasInicioFinArray,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRequestInfoProyecto: () => {
            dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
        },
        onShowModalCCDInterno: () => {
            dispatch({ type: 'SHOW_MODAL_CCD_INTERNO', showModalCalculoCDInterno: false})
        },
        onGetContenedoresSPA: (servicioPublicoSeleccionado) => {
            dispatch({ type: 'LISTA_DEPOSITOS_SERVICIOS_PUBLICOS_REQUEST', servicioPublicoSeleccionado: servicioPublicoSeleccionado});
        },
        onGetLecturasDepositos: (idServicioPublico, depositos, inicioPeriodo, finPeriodo) => {
            dispatch({ type: 'LISTA_CCD_INTERNO_REQUEST', idServicioPublico: idServicioPublico, depositos:depositos, inicioPeriodo:inicioPeriodo, finPeriodo:finPeriodo})
        },
        onResetLecturasDepositos: () => {
            dispatch({ type: 'LISTA_CCD_INTERNO_SUCCESS', listaCDInterno: [], consumoTotal: null, lecturasInicioFinArray:[]})
        },
        onCrearCalculo: (idServicioPublico, data, page, busqueda) => {
            dispatch({ type: 'CALCULO_CONSUMO_DIFERENCIADO_INTERNO_REQUEST', idServicioPublico: idServicioPublico, data: data, page: page, busqueda: busqueda});
        },
        onDescargarPDF: (idServicioPublico, data, lecturasInicioFinArray, ubicacionCompleta) => {
            dispatch({ type: 'CALCULO_CD_INTERNO_PDF_REQUEST', idServicioPublico: idServicioPublico, data: data, lecturasInicioFinArray: lecturasInicioFinArray, ubicacionCompleta:ubicacionCompleta});
        },
        onDescargarExcel: (idServicioPublico, data, lecturasInicioFinArray, ubicacionCompleta) => {
            dispatch({ type: 'CALCULO_CD_INTERNO_EXCEL_REQUEST', idServicioPublico: idServicioPublico, data: data, lecturasInicioFinArray: lecturasInicioFinArray, ubicacionCompleta:ubicacionCompleta});
        }

    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ModalCDDInterno);