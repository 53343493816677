import React, { Component } from 'react';
import { message} from 'antd';

import { connect } from "react-redux";

class AlertMessage extends Component {
	componentDidUpdate(){
		if (this.props.showSuccessMsg) {
			message.config({
			});
			message.success(this.props.textMessage, 6); 
			this.props.onShowSuccessMsg(); 
		}
		else if(this.props.showWarningMsg){
			message.config({
			});
			message.warning(this.props.textMessage, 6);
			this.props.onShowWarningMsg();
		}
		else if(this.props.showErrorMsg){
			message.config({
			});
			message.error(this.props.textMessage, 6);
			this.props.onShowErrorMsg();
		}
	}
	render() {
		return (
			<div>
			</div>
		);
	}
}

const mapStateToProps = state => {

	return { 
	  	showSuccessMsg:state.ConfiguracionYSeguridadReducer.showSuccessMsg,
	  	showWarningMsg:state.ConfiguracionYSeguridadReducer.showWarningMsg,
	  	showErrorMsg:state.ConfiguracionYSeguridadReducer.showErrorMsg,
	  	textMessage:state.ConfiguracionYSeguridadReducer.textMessage,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		onShowSuccessMsg: () => {
			dispatch({ type: "SHOW_SUCCESS_MSG" , showSuccessMsg:false, textMessage:null});
		},
		onShowWarningMsg: () => {
			dispatch({ type: "SHOW_WARNING_MSG" , showWarningMsg:false, textMessage:null});
		},
		onShowErrorMsg: () => {
			dispatch({ type: "SHOW_ERROR_MSG" , showErrorMsg:false, textMessage:null});
		},
	};
};
export default connect(mapStateToProps,mapDispatchToProps) (AlertMessage);
