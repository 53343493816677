import React, { Component } from 'react';
import { Row, Col, Table, Button, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoPeriodoFMD from './ModalNuevoPeriodoFMD';
import ModalEditarPeriodoContainer from './ModalEditarPeriodoContainer';


class CatalogoPeriodoFraccionMaximaDescuento extends Component {
    componentDidMount() {
        this.props.onGetPeriodoFraccionMaxDesc(1);
        this.props.onVerificarAños();
    }
    state = { page: 1 }
    
    render() { 

        const {
            fetchingPeriodoFMD, listaPeriodoFMDCantidad, listaPeriodoFMD,
            onShowModalNuevoPeriodo, onVerificarAños, onGetPeriodoFraccionMaxDesc,
            onShowModalEditarPeriodo,
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const handleNuevoPeriodoFraccionMaxDesc = () => {
            const {page} = this.state;

            onVerificarAños();
            onShowModalNuevoPeriodo(page);
        };

        const handleEditarPeriodoFraccionMaxDesc = (key) => {
            console.log('key :>> ', key);
            let {page} = this.state;

            onVerificarAños();
            onShowModalEditarPeriodo(key, page);
        }


        const columns = [{
            title: 'Periodo Años',
            dataIndex: 'periodoAnios',
            key: 'periodoAnios',
            align: 'center'
        }, {
            title: 'Periodo Mes',
            dataIndex: 'periodoMes',
            key: 'periodoMes',
            align: 'center'
        }, {
            title: 'Cantidad de Años Periodo',
            dataIndex: 'cantidadAnios',
            key: 'cantidadAnios',
            align: 'center'
        }, {
            title: 'Fecha Inicial Periodo',
            dataIndex: 'fechaInicialPeriodoGrid',
            key: 'fechaInicialPeriodo',
            align: 'center'
        }, {
            title: 'Fecha Final Periodo',
            dataIndex: 'fechaFinalPeriodoGrid',
            key: 'fechaFinalPeriodo',
            align: 'center'
        }, {
            title: 'Fracción Maxima Descuento',
            dataIndex: 'fraccionMaxDescuento',
            key: 'fraccionMaxDescuento',
            align: 'center'
        }, {
            title: 'Fecha alta',
            dataIndex: 'fechaAlta',
            key: 'fechaAlta',
            align: 'center'
        }, {
            title: 'Fecha Actualización',
            dataIndex: 'fechaActualizacion',
            key: 'fechaActualizacion',
            align: 'center'
        }, {
            title: 'Editar',
            key: 'editar',
            render: (record) => (
                <a className='editarIcono' disabled={!permisosUsuario.editarPeriodoFraccionMáximaDeDescuento} onClick={() => handleEditarPeriodoFraccionMaxDesc(record)}><EditOutlined /></a>
            ),
        },
        ];

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current
            })
            onGetPeriodoFraccionMaxDesc(pagination.current);
        };

        const scroll = {"x": 1300 | true, "y": 1000};

        return ( 
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22} />
                    {/* <Col span={7}>
                        <Button onClick={handleNuevoPeriodoFraccionMaxDesc} disabled={!permisosUsuario.crearPeriodoFraccionMáximaDeDescuento} className='nuevoUsuario'>Nuevo Periodo</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nuevo Periodo' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={handleNuevoPeriodoFraccionMaxDesc} disabled={!permisosUsuario.crearPeriodoFraccionMáximaDeDescuento} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="idPeriodoFraccionMaxima" 
                            columns={columns}
                            dataSource={listaPeriodoFMD}
                            loading={fetchingPeriodoFMD}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaPeriodoFMDCantidad, simple: true }}
                            scroll={scroll}
                        />         
                    </Col>
                </Row>
                <ModalNuevoPeriodoFMD/>
                <ModalEditarPeriodoContainer/>
            </div>
         );
    }
}

const mapStateToProps = state => {
	return {
        fetchingPeriodoFMD:  state.MecanismosDePagosReducer.fetchingPeriodoFMD,
        listaPeriodoFMD:  state.MecanismosDePagosReducer.listaPeriodoFMD,
        listaPeriodoFMDCantidad:  state.MecanismosDePagosReducer.listaPeriodoFMDCantidad,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onGetPeriodoFraccionMaxDesc: (page ) => {
            dispatch({ type: "LISTA_CATALOGO_PERIODO_FMD_REQUEST" , page })
        },
        onShowModalNuevoPeriodo: (page) => {
            dispatch({ type: "SHOW_MODAL_CREAR_PERIODO_FMD" , showModalCrearPeriodoFMD: true, page })
        },
        onVerificarAños: () => {
            dispatch({ type: "VERIFICAR_AÑOS_PERIODO_REQUEST" });
        },
        onShowModalEditarPeriodo: (periodoSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_PERIODO_FMD" , showModalEditarPeriodoFMD: true, periodoSeleccionado, page})
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoPeriodoFraccionMaximaDescuento);
