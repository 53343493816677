import React from 'react';
import { Form, Spin, Row, Col, Input, Result, Table, Tooltip, Button, DatePicker } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalAlta from './ModalAltaIndicadoresDesempeno';
import ModalEditar from './ModalEditarIndicadorDesempeno';

const { Search } = Input;

class CatalogoIndicadoresDesempenoContainer extends React.Component {
     formRef = React.createRef();

     state={  page: 1, busqueda: '', indicadorDesempeno: '', fechaVigencia: '', }

     componentDidMount () {
          this.props.onGetListaGerenciasActivas();
          this.props.onGetListaIndicadoresDesempeno(1, '', '', '');
          this.props.onGetInfoProyecto();
     }
 
     render() {
          const { showModalAlta, showModalEditar, onGetListaIndicadoresDesempeno, listaIndicadoresDesempeno, listaIndicadoresDesempenoCantidad } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){ permisosUsuario = JSON.parse(permisosUsuario); }

          const onShowModalAlta = () => { 
               showModalAlta(this.state.page, this.state.busqueda, this.state.indicadorDesempeno, this.state.fechaVigencia);
          };

          const scroll = {"x": 1500 | true, "y": 1000};

          const columnas = [
               { title: 'Gerencia', dataIndex: 'gerencia', key: 'gerencia', align: 'center', },
               { title: 'Objetivo', dataIndex: 'objetivo', key: 'servicio', align: 'center', },
               { title: 'Indicador de desempeño', dataIndex: 'indicadorDesempeno', key: 'indicadorDesempeno', align: 'center', },
               { title: 'Origen de la informacion', dataIndex: 'origenInformacion', key: 'origenInformacion', align: 'center', },
               { title: <Tooltip title='Parte que genera la información inicial' >Información inicial</Tooltip>, dataIndex: 'informacionInicial', key: 'informacionInicial', align: 'center', },
               { title: 'Valor esperado', dataIndex: 'valorEsperadoTabla', key: 'valorEsperadoTabla', align: 'right', width: '6%'},
               { title: 'Vigencia', dataIndex: 'vigencia', key: 'vigencia', align: 'center', width: '8%'},
               { title: <Tooltip title='Frecuencia de evaluación en días' >Frecuencia de evaluación en días</Tooltip>, dataIndex: 'frecuenciaEvaluacion', key: 'frecuenciaEvaluacion', align: 'right', width: '8%'},
               { title: 'Editar', align: 'center', width: '5%',
               render: (record) => (
                    <EditOutlined disabled={!permisosUsuario.editarIndicadorDeDesempeño} className='editarIcono' onClick={() => onActualizar(record)} />
               )},
          ];

          const onActualizar = (record) => {
               record.gerenciaEditar = record.idClasificacionGerencia + ' - ' + record.gerencia;
               record.objetivoEditar = record.objetivoByIdObjetivo.idObjetivo + ' - ' + record.objetivoByIdObjetivo.objetivo;
               showModalEditar(record, this.state.page, this.state.busqueda, this.state.indicadorDesempeno, this.state.fechaVigencia);
               console.log('indicador', record);
          };

          const onBuscar = (value) => {
               this.setState({ busqueda: value });
               onGetListaIndicadoresDesempeno(this.state.page, value, this.state.indicadorDesempeno, this.state.fechaVigencia);
          };

          const onBuscarIndicador = (value) => {
               this.setState({ indicadorDesempeno: value });
               onGetListaIndicadoresDesempeno(this.state.page, this.state.busqueda, value, this.state.fechaVigencia);
          };

          const handleTableChange = (pagination)=>{
               this.setState({ page: pagination.current })
               onGetListaIndicadoresDesempeno(pagination.current, this.state.busqueda, this.state.indicadorDesempeno, this.state.fechaVigencia);
          };

          const onBuscarFecha = (date, dateString) => {
               if(date){
                    this.setState({ fechaVigencia: dateString.split('-').reverse().join('-') });
                    onGetListaIndicadoresDesempeno(this.state.page, this.state.busqueda, this.state.indicadorDesempeno, dateString.split('-').reverse().join('-'));
               } else {
                    this.setState({ fechaVigencia: '' });
                    onGetListaIndicadoresDesempeno(this.state.page, this.state.busqueda, this.state.indicadorDesempeno, '');
               }
          }

          return (<>
               { permisosUsuario ? (<>
                    <Spin spinning={false}>
                    <Form
                         name="formulario"
                         ref={this.formRef}
                    >
                         <Row justify='space-around' gutter={[8,8]}>
                              <Col span={7}>
                                    <Search className='buscarModulos' placeholder="Ingresa el objetivo"  onSearch={value => onBuscar(value)} style={{ width: '100%' }} />
                              </Col>

                              <Col span={7}>
                                    <Search className='buscarModulos' placeholder="Ingresa el indicador"  onSearch={value => onBuscarIndicador(value)} style={{ width: '100%' }} />
                              </Col>

                              <Col span={7}>
                                   <DatePicker style={{ width: '100%' }} format='DD-MM-YYYY' placeholder={["Seleccione fecha vigencia"]} onChange={onBuscarFecha}/>
                              </Col>

                              {/* <Col span={8}>
                                   <Tooltip title='Ver detalle'>
                                        <Button className="nuevoUsuario" disabled={!permisosUsuario.crearIndicadorDeDesempeño} onClick={onShowModalAlta}>
                                             Nuevo Indicador de Desempeño
                                        </Button>
                                   </Tooltip>
                              </Col> */}

                              <Col span={1}>
                                   <Tooltip title='Nuevo Indicador de Desempeño' placement="topRight">
                                        <Button className="iconTer" shape="circle" disabled={!permisosUsuario.crearIndicadorDeDesempeño} onClick={onShowModalAlta} icon={<PlusOutlined />}/>
                                   </Tooltip>
                              </Col>

                              <Col span={24}>
                                   <Table
                                        size="small"
                                        rowKey="idIndicadorDesempeno"
                                        columns={columnas}
                                        dataSource={listaIndicadoresDesempeno}
                                        loading={this.props.fetchingListaIndicadoresDesempeno}
                                        locale={{ emptyText: 'Sin datos' }}
                                        scroll={scroll}
                                        onChange={handleTableChange}
                                        pagination={{ total: listaIndicadoresDesempenoCantidad, simple: true, current: this.state.page }}
                                   />
                              </Col>

                         </Row>
                         <ModalAlta />
                         <ModalEditar />
                    </Form>
               </Spin>
               </>): (<>
                    <Row justify='center'>
                         <Result
                              status="403"
                              title="Acceso denegado"
                              subTitle="Lo siento, no tiene permiso autorizado para acceder."
                         />
                    </Row>
               </>) }
          </>);
     }
}
 
 const mapStateToProps = state => {
     return {
          listaIndicadoresDesempeno: state.EvaluacionDeLaGestionReducer.listaIndicadoresDesempeno,
          listaIndicadoresDesempenoCantidad: state.EvaluacionDeLaGestionReducer.listaIndicadoresDesempenoCantidad,
          fetchingListaIndicadoresDesempeno: state.EvaluacionDeLaGestionReducer.fetchingListaIndicadoresDesempeno
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          showModalAlta: (page, busqueda, indicadorDesempeno, fechaVigencia) => {
               dispatch({ type: 'SHOW_MODAL_ALTA_INDICADORES_DESEMPENO', showModalAltaIndicaresDesempeno: true, page, busqueda, indicadorDesempeno, fechaVigencia });
          },
          showModalEditar: (indicadorDesempenoSeleccionado, page, busqueda, indicadorDesempeno, fechaVigencia) => {
               dispatch({ type: 'SHOW_MODAL_EDITAR_INDICADOR_DESEMPENO', showModalEditarIndicadorDesempeno: true, indicadorDesempenoSeleccionado, page, busqueda, indicadorDesempeno, fechaVigencia });
          },
          onGetListaGerenciasActivas: () => {
               dispatch({ type: 'LISTA_CLASIFICACIONES_GERENCIA_ACTIVAS_REQUEST' });
          },
          onGetListaIndicadoresDesempeno: (page, busqueda, indicadorDesempeno, fechaVigencia) => {
               dispatch({ type: 'LISTA_INDICADORES_DESEMPENO_BUSQUEDA_REQUEST', page, busqueda, indicadorDesempeno, fechaVigencia });
          },
          onGetInfoProyecto: () => {
               dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
          },
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(CatalogoIndicadoresDesempenoContainer);