import React from 'react';
import {Card, Table, Row, Col, Select, Button, Switch, Modal} from 'antd';
import {EditOutlined, EyeOutlined, DownloadOutlined, ProfileOutlined} from '@ant-design/icons';
import {connect} from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import {listaServiciosPublicosGeneralesCall} from "../../../services/MecanismosDePagosServices";

const {Option} = Select;
const confirm = Modal.confirm;

class PagoDeServiciosPublicosContainer extends React.Component {
    componentDidMount() {
        this.props.onListaMesesEvaluacion();
    }

    state = {
        page: 1,
        idMes: null,
        serviciosGenerales: false,
    }

    render() {
        const {
            listaMesesEvaluacion,
            fetchingListaMesesEvaluacion,
            fetchingListaServiciosPublicosGenerales,
            listaServiciosPublicosGenerales,
            listaServiciosPublicosGeneralesCantidad,
            fetchingListaServiciosPublicosMes,
            listaServiciosPublicosMes,
            listaServiciosPublicosMesCantidad,
            fetchingExportarServiciosPublicosPDF
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){ permisosUsuario = JSON.parse(permisosUsuario); }

        const columnsGenerales = [{
            title: 'Tipo de consumo',
            dataIndex: 'nombre',
            key: 'nombre',
            width: '10%',
        }, {
            title: 'Cantidad consumida por el desarrollador',
            dataIndex: 'totalConsumidaDesarrollador',
            key: 'totalConsumidaDesarrollador',
            align: 'right',
            width: '10%',
        }, {
            title: 'Cantidad consumida por el PYRS',
            dataIndex: 'totalPyrs',
            key: 'totalPyrs',
            align: 'right',
            width: '10%',
        }, {
            title: 'Cantidad total consumida',
            dataIndex: 'totalConsumida',
            key: 'totalConsumida',
            align: 'right',
            width: '10%',
        }, {
            title: 'Monto total a pagar',
            dataIndex: 'totalPagar',
            key: 'totalPagar',
            align: 'right',
            width: '10%',
        }, {
            title: 'Expectativa para el siguiente mes',
            dataIndex: 'expectativaMes',
            key: 'expectativaMes',
            align: 'right',
            width: '10%',
        }, {
            title: 'Expectativa para el siguiente año',
            dataIndex: 'expectativaMesAnio',
            key: 'expectativaMesAnio',
            align: 'right',
            width: '10%',
        }
        ];

        const columnsMes = [{
            title: 'Folio',
            dataIndex: 'folio',
            key: 'folio',
            width: '10%',
        }, {
            title: 'Tipo de consumo',
            dataIndex: 'tipoConsumo',
            key: 'tipoConsumo',
            width: '10%',
        }, {
            title: 'Unidad de medición',
            dataIndex: 'unidadMedicion',
            key: 'unidadMedicion',
            width: '10%',
        }, {
            title: 'Costo unitario',
            dataIndex: 'precioUnitario',
            key: 'precioUnitario',
            align: 'right',
            width: '10%',
        }, {
            title: 'Cantidad consumida por el desarrollador',
            dataIndex: 'consumoDesarrollador',
            key: 'consumoDesarrollador',
            align: 'right',
            width: '10%',
        }, {
            title: 'Cantidad consumida por el PYRS',
            dataIndex: 'consumoPyrs',
            key: 'consumoPyrs',
            align: 'right',
            width: '10%',
        }, {
            title: 'Cantidad total consumida',
            dataIndex: 'totalConsumida',
            key: 'totalConsumida',
            align: 'right',
            width: '10%',
        }, {
            title: 'Monto total a pagar',
            dataIndex: 'totalFacturado',
            key: 'totalFacturado',
            align: 'right',
            width: '10%',
        }
        /* Comentados por mejora de CU, revisión FPTF-RMDP-455
        , {
            title: 'Expectativa para el siguiente mes',
            dataIndex: 'expectativaMes',
            key: 'expectativaMes',
            align: 'right',
            width: '10%',
        }, {
            title: 'Expectativa para el siguiente año',
            dataIndex: 'expectativaMesAnio',
            key: 'expectativaMesAnio',
            align: 'right',
            width: '10%',
        }*/
        ];
        const onChangeTipoServicios = (value) => {
            this.setState({
                serviciosGenerales: value
            })
            if (value) {
                this.props.onListaServiciosPublicosGenerales(this.state.idMes, this.state.page);
            } else {
                this.props.onListaServiciosPublicosMes(this.state.idMes, this.state.page);
            }
        };
        const onChangeMes = (value) => {
            this.setState({
                idMes: value
            });
            if (this.state.serviciosGenerales) {
                this.props.onListaServiciosPublicosGenerales(value, this.state.page);
            } else {
                this.props.onListaServiciosPublicosMes(value, this.state.page);
            }
        };
        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            })
        };
        const descargarReporte = value => {
            if (value === 'pdf') return this.props.onDescargarReportePDF(this.state.idMes);
        }

        return (
            <Card className="cardCatalogos"
                  title={<div className='headerLista'>Pago De Servicios Públicos</div>}>
                <Row justify='space-between'>
                    <Col span={7}>
                        <Select allowClear
                                style={{width: '100%'}}
                                placeholder="Selecciona un mes"
                                loading={fetchingListaMesesEvaluacion}
                                onChange={onChangeMes}
                                showSearch={true}
                        >
                            {listaMesesEvaluacion.map(option => <Option key={option.idMes}>{option.mes}</Option>)}
                        </Select>
                    </Col>
                    <Col span={7}>
                        <Switch onChange={onChangeTipoServicios} disabled={!permisosUsuario.cambiarTipoDeServicioPublicoPorTipoDeConsumo} checkedChildren="Servicios públicos generales"
                            unCheckedChildren="Servicios públicos por tipo de consumo"/>
                    </Col>
                    {this.state.serviciosGenerales ?
                        <Col span={24}>
                            <br/>
                            <Table
                                size="small"
                                rowKey="idServicioPublico"
                                columns={columnsGenerales}
                                dataSource={listaServiciosPublicosGenerales}
                                loading={fetchingListaServiciosPublicosGenerales}
                                locale={{emptyText: 'Sin datos'}}
                                onChange={handleTableChange}
                                pagination={{
                                    total: listaServiciosPublicosGeneralesCantidad,
                                    simple: true,
                                    current: this.state.page
                                }}
                            />
                        </Col>:
                        <Col span={24}>
                            <br/>
                            <Table
                                size="small"
                                rowKey="idServicioPublico"
                                columns={columnsMes}
                                dataSource={listaServiciosPublicosMes}
                                loading={fetchingListaServiciosPublicosMes}
                                locale={{emptyText: 'Sin datos'}}
                                onChange={handleTableChange}
                                pagination={{
                                    total: listaServiciosPublicosMesCantidad,
                                    simple: true,
                                    current: this.state.page
                                }}
                            />
                        </Col>}
                    {this.state.idMes ?
                        <Col span={11}>
                            <Button className="btnFiltrar" disabled={!permisosUsuario.exportarPdfPagoDeServiciosPúblicos ? true : (!listaServiciosPublicosMes.length>0 && !listaServiciosPublicosGenerales.length>0) || fetchingExportarServiciosPublicosPDF} loading={fetchingExportarServiciosPublicosPDF}
                                    onClick={()=> descargarReporte('pdf')} style={{ width: '100%', marginTop: '20px' }}
                            >
                                Exportar Reporte PDF
                            </Button>
                        </Col> : false }
                </Row>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaMesesEvaluacion: state.EvaluacionDeLaGestionReducer.listaMesesEvaluacion,
        fetchingListaMesesEvaluacion: state.EvaluacionDeLaGestionReducer.fetchingListaMesesEvaluacion,
        fetchingListaServiciosPublicosGenerales: state.MecanismosDePagosReducer.fetchingListaServiciosPublicosGenerales,
        listaServiciosPublicosGenerales: state.MecanismosDePagosReducer.listaServiciosPublicosGenerales,
        listaServiciosPublicosGeneralesCantidad: state.MecanismosDePagosReducer.listaServiciosPublicosGeneralesCantidad,
        fetchingListaServiciosPublicosMes: state.MecanismosDePagosReducer.fetchingListaServiciosPublicosMes,
        listaServiciosPublicosMes: state.MecanismosDePagosReducer.listaServiciosPublicosMes,
        listaServiciosPublicosMesCantidad: state.MecanismosDePagosReducer.listaServiciosPublicosMesCantidad,
        fetchingExportarServiciosPublicosPDF: state.MecanismosDePagosReducer.fetchingExportarServiciosPublicosPDF
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onShowModalVerPDFReportesDesempeno: (rutaArchivo) => {
            dispatch({
                type: 'MODAL_SHOW_PDF_ESTANDARES_DESEMPENO',
                showModalPDFEstandaresDesempeno: true,
                rutaArchivoEstandaresDesempeno: rutaArchivo
            })
        },
        onListaServiciosPublicosGenerales: (idMes, page) => {
            dispatch({type: 'LISTA_SERVICIOS_PUBLICOS_GENERALES_REQUEST', idMes, page})
        },
        onListaServiciosPublicosMes: (idMes, page) => {
            dispatch({type: 'LISTA_SERVICIOS_PUBLICOS_MES_REQUEST', idMes, page})
        },
        onListaMesesEvaluacion: () => {
            dispatch({type: 'LISTA_MESES_EVALUACION_REQUEST'})
        },
        onDescargarReportePDF: (idMes) => {
            dispatch({ type: 'EXPORTAR_SERVICIOS_PUBLICOS_PDF_REQUEST', idMes});
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PagoDeServiciosPublicosContainer);
