import React from 'react';
import moment from 'moment';
import { message, Form, Row, Col, Button, Modal, Select, Spin, Input, TimePicker, Tooltip, InputNumber, Switch } from 'antd';
import { connect } from "react-redux";
import { FieldNumberOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option } = Select;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
};

function isEmpty(obj) {
     for(var prop in obj) {
         if(obj.hasOwnProperty(prop))
             return false;
     }
     return true;
}

class ModalNuevaProgramacion extends React.Component {
     formRef = React.createRef();

     componentDidMount() {
          
     }

     state = {
          page: 1,
          numeroLectura: 1,
          formularioInicial: {
               cantidadHorarios: 1,
               vencimientoMinutos: 1
          },
          checkedVariables: true,
          todasVariablesNombre: [],

          Primera: null,
          Segunda: null, segundoCondicion: true,
          Tercera: null, terceraCondcion: true,
          Cuarta: null, cuartaCondicion: true,
          Quinta: null, quintaCondicion: true,
          Sexta: null, sextaCondicion: true,
     }


     render() {
          const { 
               limpiarFormulario, 
               onLimpiarFormulario,
               showModalNuevaProgramacion,
               fetchingNuevaProgramacion,
               onCloseModalNuevaProgramacion,
               onNuevaProgramacion,
               listaServiciosPublicosActivos,
               fetchingListaServiciosPublicosActivos,
               fetchingListaVariablesMedicionActivos,
               listaVariablesMedicionActivos,
               onEditarValorAll,
          } = this.props;

          const onFinish = formulario => {
               console.log('Success:', formulario);

               let horario = [];
               
               if(formulario.cantidadHorarios === 6){
                    horario = [{"horario" : formulario.horarioLectura0.format('HH:mm:ss')}, {"horario" : formulario.horarioLectura1.format('HH:mm:ss')},{"horario" : formulario.horarioLectura2.format('HH:mm:ss')}, {"horario" : formulario.horarioLectura3.format('HH:mm:ss')}, {"horario" : formulario.horarioLectura4.format('HH:mm:ss')}, {"horario" : formulario.horarioLectura5.format('HH:mm:ss')}];
               } else if(formulario.cantidadHorarios === 5){
                    horario = [{"horario" : formulario.horarioLectura0.format('HH:mm:ss')}, {"horario" : formulario.horarioLectura1.format('HH:mm:ss')},{"horario" : formulario.horarioLectura2.format('HH:mm:ss')}, {"horario" : formulario.horarioLectura3.format('HH:mm:ss')}, {"horario" : formulario.horarioLectura4.format('HH:mm:ss')}];
               } else if(formulario.cantidadHorarios === 4){
                    horario = [{"horario" : formulario.horarioLectura0.format('HH:mm:ss')}, {"horario" : formulario.horarioLectura1.format('HH:mm:ss')},{"horario" : formulario.horarioLectura2.format('HH:mm:ss')}, {"horario" : formulario.horarioLectura3.format('HH:mm:ss')}];
               } else if(formulario.cantidadHorarios === 3){
                    horario = [{"horario" : formulario.horarioLectura0.format('HH:mm:ss')}, {"horario" : formulario.horarioLectura1.format('HH:mm:ss')},{"horario" : formulario.horarioLectura2.format('HH:mm:ss')}];
               } else if(formulario.cantidadHorarios === 2){
                    horario = [{"horario" : formulario.horarioLectura0.format('HH:mm:ss')}, {"horario" : formulario.horarioLectura1.format('HH:mm:ss')}];
               } else if(formulario.cantidadHorarios === 1){
                    horario = [{"horario" : formulario.horarioLectura0.format('HH:mm:ss')}];
               }

               console.log('horario', horario)

               onNuevaProgramacion(formulario, this.props.page, horario, !this.state.checkedVariables, listaVariablesMedicionActivos);
               //this.formRef.current.setFieldsValue({'idVariableMedicion': null});
          };

          if (limpiarFormulario && limpiarFormulario === 'crearProgramacion') {
               onLimpiarFormulario();
               this.formRef.current.resetFields();
               this.setState({ numeroLectura: 1, checkedVariables: true, todasVariablesNombre: [], Primera: null,
                    Segunda: null, segundoCondicion: true,
                    Tercera: null, terceraCondcion: true,
                    Cuarta: null, cuartaCondicion: true,
                    Quinta: null, quintaCondicion: true,
                    Sexta: null, sextaCondicion: true, });
          }

          const onFinishFailed = errorInfo => {
               console.log('Failed:', errorInfo);
          };

          const handleCancel = () => {
               onCloseModalNuevaProgramacion();
               
               this.formRef.current.resetFields();
               this.setState({ numeroLectura: 1, checkedVariables: true, todasVariablesNombre: [], Primera: null,
                    Segunda: null, segundoCondicion: true,
                    Tercera: null, terceraCondcion: true,
                    Cuarta: null, cuartaCondicion: true,
                    Quinta: null, quintaCondicion: true,
                    Sexta: null, sextaCondicion: true });
          };

          const onNumeroLecturas = (value) => {
               this.setState({ numeroLectura: value });
          };

          const onTodasVariables = (checked) => {
               const variablesNombre = [listaVariablesMedicionActivos.length];
               const todasVariablesSeleccionadas = [listaVariablesMedicionActivos.length];

               for(let i = 0; i < listaVariablesMedicionActivos.length; i++){
                    variablesNombre[i] = listaVariablesMedicionActivos[i].nombre;
                    todasVariablesSeleccionadas[i] = listaVariablesMedicionActivos[i];
               }

               this.setState({ checkedVariables: !checked, todasVariablesNombre: variablesNombre });
               this.formRef.current.resetFields(['idVariableMedicion']);

               onEditarValorAll([], this.props.page, this.props.busqueda, this.props.cantidadHorarios, checked)
          };

          const onPrimera = (time) => {
               console.log('time Primero', time)
               this.setState({ Primera: time });

               if(time){
                    this.setState({ segundoCondicion: false });
               } else {
                    this.setState({ segundoCondicion: true, terceraCondcion: true, cuartaCondicion: true, quintaCondicion: true, sextaCondicion: true });
                    this.formRef.current.resetFields(['horarioLectura1', 'horarioLectura2', 'horarioLectura3', 'horarioLectura4', 'horarioLectura5']);
               }

               if(this.formRef.current.getFieldValue('horarioLectura1')){
                    if(time >= this.formRef.current.getFieldValue('horarioLectura1')){
                         message.warning('La hora que se intenta ingresar no debe ser mayor a la hora de la siguiente lectura.');
                         this.formRef.current.setFieldsValue({'horarioLectura0': null})
                    }
               }
          };

          const onSegunda = (time) => {
               console.log('time Segunda', time)
               
               if(time <= this.state.Primera){
                    this.setState({ terceraCondcion: true, cuartaCondicion: true, quintaCondicion: true, sextaCondicion: true });
                    //this.formRef.current.resetFields(['horarioLectura1']);
                    this.formRef.current.setFieldsValue({'horarioLectura1': null});
                    message.warning('El horario de lectura ingresado no puede ser menor al anterior.');
               } else {
                    if(time){
                         this.setState({ terceraCondcion: false });
                    } else {
                         console.log('entra a else')
                         this.setState({ terceraCondcion: true, cuartaCondicion: true, quintaCondicion: true, sextaCondicion: true });
                         this.formRef.current.resetFields(['horarioLectura2', 'horarioLectura3', 'horarioLectura4', 'horarioLectura5']);
                         this.formRef.current.setFieldsValue({'horarioLectura2': null, 'horarioLectura3': null, 'horarioLectura4': null, 'horarioLectura5': null});
                    }
               }

               if(time === null){
                    this.setState({ terceraCondcion: true, cuartaCondicion: true, quintaCondicion: true, sextaCondicion: true });
                    this.formRef.current.setFieldsValue({'horarioLectura2': null, 'horarioLectura3': null, 'horarioLectura4': null, 'horarioLectura5': null});
               }

               if(this.formRef.current.getFieldValue('horarioLectura2')){
                    if(time >= this.formRef.current.getFieldValue('horarioLectura2')){
                         message.warning('La hora que se intenta ingresar no debe ser mayor a la hora de la siguiente lectura.');
                         this.formRef.current.setFieldsValue({'horarioLectura1': null})
                    }
               }

               this.setState({ Segunda: time });
          };

          const onTercera = (time) => {
               console.log('time Tercera', time)
               
               if(time <= this.state.Segunda){
                    this.formRef.current.resetFields(['horarioLectura2']);
                    message.warning('El horario de lectura ingresado no puede ser menor al anterior.');
                    this.setState({ cuartaCondicion: true, quintaCondicion: true, sextaCondicion: true });
               } else {

                    if(time){
                         this.setState({ cuartaCondicion: false });
                    } else {
                         this.setState({ cuartaCondicion: true, quintaCondicion: true, sextaCondicion: true });
                         this.formRef.current.resetFields(['horarioLectura3', 'horarioLectura4', 'horarioLectura5']);
                    }
               }

               if(time === null){
                    this.setState({ cuartaCondicion: true, quintaCondicion: true, sextaCondicion: true });
                    this.formRef.current.setFieldsValue({'horarioLectura3': null, 'horarioLectura4': null, 'horarioLectura5': null});
               }

               if(this.formRef.current.getFieldValue('horarioLectura3')){
                    if(time >= this.formRef.current.getFieldValue('horarioLectura3')){
                         message.warning('La hora que se intenta ingresar no debe ser mayor a la hora de la siguiente lectura.');
                         this.formRef.current.setFieldsValue({'horarioLectura2': null})
                    }
               }

               this.setState({ Tercera: time });
          };

          const onCuarta = (time) => {
               console.log('time Cuarta', time)
               if(time <= this.state.Tercera){
                    this.formRef.current.resetFields(['horarioLectura3']);
                    message.warning('El horario de lectura ingresado no puede ser menor al anterior.');
                    this.setState({ quintaCondicion: true, sextaCondicion: true });
               } else {

                    if(time){
                         this.setState({ quintaCondicion: false });
                    } else {
                         this.setState({ quintaCondicion: true, sextaCondicion: true });
                         this.formRef.current.resetFields(['horarioLectura4', 'horarioLectura5']);
                    }
               }

               if(time === null){
                    this.setState({ quintaCondicion: true, sextaCondicion: true });
                    this.formRef.current.setFieldsValue({'horarioLectura4': null, 'horarioLectura5': null});
               }

               if(this.formRef.current.getFieldValue('horarioLectura4')){
                    if(time >= this.formRef.current.getFieldValue('horarioLectura4')){
                         message.warning('La hora que se intenta ingresar no debe ser mayor a la hora de la siguiente lectura.');
                         this.formRef.current.setFieldsValue({'horarioLectura3': null})
                    }
               }

               this.setState({ Cuarta: time });
          };

          const onQuinta = (time) => {
               console.log('time Quinta', time)
               if(time <= this.state.Cuarta){
                    this.formRef.current.resetFields(['horarioLectura4']);
                    message.warning('El horario de lectura ingresado no puede ser menor al anterior.');
                    this.setState({ sextaCondicion: true });
               } else {

                    if(time){
                         this.setState({ sextaCondicion: false });
                    } else {
                         this.setState({ sextaCondicion: true });
                         this.formRef.current.resetFields(['horarioLectura5']);
                    }
               }

               if(time === null){
                    this.setState({ sextaCondicion: true });
                    this.formRef.current.setFieldsValue({'horarioLectura5': null});
               }

               if(this.formRef.current.getFieldValue('horarioLectura5')){
                    if(time >= this.formRef.current.getFieldValue('horarioLectura5')){
                         message.warning('La hora que se intenta ingresar no debe ser mayor a la hora de la siguiente lectura.');
                         this.formRef.current.setFieldsValue({'horarioLectura4': null})
                    }
               }

               this.setState({ Quinta: time });
          };

          const onSexta = (time) => {
               console.log('time Sexta', time)
               if(time <= this.state.Quinta){
                    this.formRef.current.resetFields(['horarioLectura5']);
                    message.warning('El horario de lectura ingresado no puede ser menor al anterior.');

               }
               this.setState({ Sexta: time });
          };

          const onVencimiento = (value) => {
               this.setState({ vencimientoMinutos: value });
          };

          return (
               <Modal
                    visible={showModalNuevaProgramacion}
                    title='Nueva Programación y Medición de Consumos'
                    onCancel={handleCancel}
                    footer={false}
                    width={'45%'}
               >
                    <Spin spinning={fetchingNuevaProgramacion}>
                         <Form {...layout}
                         name='formulario'
                         onFinish={onFinish}
                         
                         ref={this.formRef}
                         initialValues={this.state.formularioInicial}
                         >
                              <Row type='flex' justify='space-between'>
                                   
                                   <Col span={14}>
                                        <Form.Item
                                             name='idServicioPublico'
                                             label='Servicio Público'
                                             rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                        >
                                             <Select
                                                  allowClear
                                                  style={{ width: '100%' }}
                                                  placeholder="Seleccione un servicio público"
                                                  loading={fetchingListaServiciosPublicosActivos}
                                             >
                                                  {listaServiciosPublicosActivos.map(option => <Option key={option.idServicioPublico}>{ option.nombre}</Option>)}
                                             </Select>    
                                        </Form.Item>
                                   </Col>

                                   <Col span={4}>
                                   <Tooltip placement="right" title="Vencimiento de la programación en minutos." >
                                        <Form.Item
                                             label="Vencimiento"
                                             name="vencimientoMinutos"
                                             rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                             <InputNumber
                                                  prefix={<FieldNumberOutlined />}
                                                  min={1}
                                                  onChange={onVencimiento}
                                                  value={1}
                                             />
                                        </Form.Item>
                                   </Tooltip>
                                   </Col>

                                   <Col span={4}>
                                        <Form.Item
                                             label="No. Lecturas"
                                             name="cantidadHorarios"
                                             rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                             <InputNumber
                                                  prefix={<FieldNumberOutlined />}
                                                  placeholder={'No.'}
                                                  max={6}
                                                  min={1}
                                                  onChange={onNumeroLecturas}
                                             />
                                        </Form.Item>
                                   </Col>
                                   
                                   { this.state.numeroLectura === 1 || this.state.numeroLectura === 2 || this.state.numeroLectura === 3 || this.state.numeroLectura === 4 || this.state.numeroLectura === 5 || this.state.numeroLectura === 6 ? (
                                        <Col span={7}>
                                             <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                                  <Form.Item
                                                       label="Primera Lectura"
                                                       name="horarioLectura0"
                                                       rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                  >
                                                  <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de lectura"]} style={{width: '100%'}} onChange={onPrimera} />
                                             </Form.Item>
                                             </Tooltip>
                                        </Col>
                                   ): false }

                                   { this.state.numeroLectura === 2 || this.state.numeroLectura === 3 || this.state.numeroLectura === 4 || this.state.numeroLectura === 5 || this.state.numeroLectura === 6 ? (
                                        <Col span={7}>
                                             <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                                  <Form.Item
                                                       label="Segunda Lectura"
                                                       name="horarioLectura1"
                                                       rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                                  >
                                                  <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de lectura"]} style={{width: '100%'}} onChange={onSegunda} disabled={this.state.segundoCondicion}/>
                                             </Form.Item>
                                             </Tooltip>
                                        </Col>
                                   ): false }
                                   
                                   { this.state.numeroLectura === 3 || this.state.numeroLectura === 4 || this.state.numeroLectura === 5 || this.state.numeroLectura === 6 ? (
                                        <Col span={7}>
                                             <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                                  <Form.Item
                                                       label="Tercera Lectura"
                                                       name="horarioLectura2"
                                                       rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                             >
                                                  <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de lectura"]} style={{width: '100%'}} onChange={onTercera} disabled={this.state.terceraCondcion}/>
                                             </Form.Item>
                                             </Tooltip>
                                        </Col>
                                   ): false }

                                   { this.state.numeroLectura === 4 || this.state.numeroLectura === 5 || this.state.numeroLectura === 6 ? (
                                        <Col span={7}>
                                             <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                                  <Form.Item
                                                       label="Cuarta Lectura"
                                                       name="horarioLectura3"
                                                       rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                             >
                                                  <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de lectura"]} style={{width: '100%'}} onChange={onCuarta} disabled={this.state.cuartaCondicion}/>
                                             </Form.Item>
                                             </Tooltip>
                                        </Col>
                                   ): false }

                                   { this.state.numeroLectura === 5 || this.state.numeroLectura === 6 ? (
                                        <Col span={7}>
                                             <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                                  <Form.Item
                                                       label="Quinta Lectura"
                                                       name="horarioLectura4"
                                                       rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                             >
                                                  <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de lectura"]} style={{width: '100%'}} onChange={onQuinta} disabled={this.state.quintaCondicion}/>
                                             </Form.Item>
                                             </Tooltip>
                                        </Col>
                                   ): false }

                                   { this.state.numeroLectura === 6 ? (
                                        <Col span={7}>
                                             <Tooltip placement="right" title="Para seleccionar la hora, debes dar click en 'Aceptar'" >
                                                  <Form.Item
                                                       label="Sexta Lectura"
                                                       name="horarioLectura5"
                                                       rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                             >
                                                  <TimePicker showNow={false} format='HH:mm' placeholder={["Hora de lectura"]} style={{width: '100%'}} onChange={onSexta} disabled={this.state.sextaCondicion}/>
                                             </Form.Item>
                                             </Tooltip>
                                        </Col>
                                   ): false }

                                   <Col span={19}>
                                        <Form.Item
                                             name='idVariableMedicion'
                                             label='Variable de Medición'
                                             rules={[{ required: this.state.checkedVariables, message: 'Favor de llenar el campo'}]}
                                        >
                                             { this.state.checkedVariables ? (
                                                  <Select
                                                       mode='multiple'
                                                       allowClear
                                                       style={{ width: '100%' }}
                                                       placeholder="Seleccione una variable de medición"
                                                       loading={fetchingListaVariablesMedicionActivos}
                                                  >
                                                       {listaVariablesMedicionActivos.map(option => <Option key={option.idVariableMedicion}>{ option.nombre}</Option>)}
                                                  </Select>
                                             ): (
                                                  <Select
                                                       mode="multiple"
                                                       style={{ width: '100%' }}
                                                       placeholder="Se han seleccionado todas las variables"
                                                       defaultValue={this.state.todasVariablesNombre}
                                                       disabled={true}
                                                  />
                                             )}  
                                        </Form.Item>
                                   </Col>

                                   <Col span={4}>
                                        <Form.Item
                                             label=' '
                                             name='todasVariables'
                                        >
                                             <Switch
                                                  checkedChildren={'TODAS'}
                                                  unCheckedChildren={'TODAS'}
                                                  defaultChecked={false}
                                                  disabled={!listaVariablesMedicionActivos[0]}
                                                  size={'default'}
                                                  onChange={onTodasVariables}
                                             />
                                        </Form.Item>
                                   </Col>

                                   <Col span={24}>
                                        <Form.Item
                                             name="descripcion"
                                             label='Descripción'
                                             rules={[{ required: true, message: 'Favor de llenar el campo'}]}
                                        >
                                             <Input
                                                  placeholder='Descripción de programación y medición de consumo'
                                                  pattern="^[0-9A-Za-zñáéíóúÑÁÉÍÓÚüÜ;\.:'\s\-,]+$"
                                                  title="Descripción sin carácteres especiales." 
                                                  maxLength={250} 
                                             />
                                        </Form.Item>
                                   </Col>

                                   <Col span={24}>
                                        <Button className="btnPrueba" htmlType="submit" style={{ width: '100%' }}>
                                             Guardar
                                        </Button>
                                   </Col>
                              </Row>
                         </Form>
                    </Spin>
               </Modal>
          );
     }
}

const mapStateToProps = state => {
     return {
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          showModalNuevaProgramacion: state.ServiciosPublicosReducer.showModalNuevaProgramacion,
          fetchingNuevaProgramacion: state.ServiciosPublicosReducer.fetchingNuevaProgramacion,
          page: state.ServiciosPublicosReducer.page,
          busqueda: state.ServiciosPublicosReducer.busqueda,
          listaServiciosPublicosActivos: state.ServiciosPublicosReducer.listaServiciosPublicosActivos,
          fetchingListaServiciosPublicosActivos: state.ServiciosPublicosReducer.fetchingListaServiciosPublicosActivos,
          fetchingListaVariablesMedicionActivos: state.ServiciosPublicosReducer.fetchingListaVariablesMedicionActivos,
          listaVariablesMedicionActivos: state.ServiciosPublicosReducer.listaVariablesMedicionActivos,
          cantidadHorarios: state.ServiciosPublicosReducer.cantidadHorarios
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
          onCloseModalNuevaProgramacion: () => {
               dispatch({ type: 'SHOW_MODAL_NUEVA_PROGRAMACION_MEDICION', showModalNuevaProgramacion: false });
          },
          onNuevaProgramacion: (formulario, page, horario, todasVariables, listaVariablesMedicionActivos) => {
               dispatch({ type: 'NUEVA_PROGRAMACION_MEDICION_REQUEST', formulario, page, horario, todasVariables, listaVariablesMedicionActivos });
          },
          onEditarValorAll: (programacionSeleccionada, page, busqueda, cantidadHorarios, todasVariables) => {
               dispatch({ type: 'SHOW_MODAL_EDITAR_PROGRAMACION_MEDICION', showModalEditarProgramacion: false, programacionSeleccionada, page, busqueda ,cantidadHorarios, todasVariables });
          },   
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevaProgramacion);