import axios from "axios";

//const endpoint = "https://papantla-services.herokuapp.com/";
//const endpoint = "https://papantla-dev.herokuapp.com/";
const endpoint = 'https://morelos-dev.herokuapp.com/';
//const endpoint = "https://papantla-demo.herokuapp.com/";

//const endpoint = "http://172.24.0.170:8080/"

//const endpoint = 'https://papantla-services-jose.herokuapp.com/';
// const endpoint = 'https://papantla-dev-jose.herokuapp.com/';

//const endpoint = 'https://papantla-secret-jose.herokuapp.com/';
//const endpoint = 'https://papantla-secret.herokuapp.com/';

export function listaRutinasBusquedaCall(token, busqueda, page) {
    return axios({
        method: "get",
        url: endpoint + "rutinas?" + busqueda + "page=" + page + "&offset=10",
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data;
    });
}

export function rutinaEstadoCall(token, idRutina) {
    return axios({
        method: "get",
        url: endpoint + "rutinas/activa/" + idRutina,
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data;
    });
}

export function nuevaRutinaCall(token, data) {
    return axios({
        method: "post",
        url: endpoint + "rutinas",
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        data: data,
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data;
    });
}

export function editarRutinaCall(token, idRutina, data) {
    return axios({
        method: "put",
        url: endpoint + "rutinas/" + idRutina,
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        data: data,
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data;
    });
}

//Asignacion de Servicios Planificados
export function listaAsignacionSPBusquedaCall(token, idServicio, idPartida, tipo, idUbicacion, idActivoCaract, page) {
    return axios({
        method: 'get',
        url: endpoint + "asignaciones/serv/planificados/?param=" + tipo + "&idServicio=" + idServicio  + "&idPartida=" + idPartida + "&idUbicacion=" + idUbicacion + '&idActivoCaracteristica='+ idActivoCaract +"&page=" + page + "&offset=10",
        headers: { 'Content-Type': 'application/json', 'Authorization': token }
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data;
    })
};

export function listaSPNoAsignadosCall(token) {
    return axios({
        method: 'get',
        url: endpoint + "asignaciones/serv/planificados/sin/asignacion",
        headers: { 'Content-Type': 'application/json', 'Authorization': token }
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data;
    });
}

export function crearAsignacionSPCall(token, programa, data) {
    console.log('data en Services', data)
    return axios({
        method: 'post',
        url: endpoint + "asignaciones/serv/planificados/?rutina=" + programa,
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        data: data
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data;
    });
}

export function editarAsignacionSPCall(token, idRutina, programa, data) {
    return axios({
        method: 'put',
        url: endpoint + 'asignaciones/serv/planificados/' + idRutina + '/' + programa,
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        data: data
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data;
    });
}

// Programas de Rutinas, Mantenimientos y Pruebas
export function listaRMPBusquedaCall(token, data, programa, page, param) {
    return axios({
        method: "post",
        url: endpoint + "rutinas/programa?programa=" + programa + "&param=" + param + "&page=" + page + "&offset=10",
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        data: data,
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data;
    });
}

export function programarRMPCall(token, data) {
    return axios({
        method: "put",
        url: endpoint + "rutinas/programa",
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        data: data,
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data;
    });
}

export function editarRMPCall(token, idProgramacionRutina, data){
    return axios({
        method: "put",
        url: endpoint + "rutinas/programa/" + idProgramacionRutina,
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        data: data,
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data;
    });
}

// Servicio Generar orden para rutina y mantenimiento
export function listaRutinasProgramasBusquedaCall(token, idServicio, programa, fechaInicio, fechaFin, idAreaFuncional, idUnidadFuncional, idPartida, page) {
    return axios({
        method: "get",
        url: endpoint + "orden/servicio/planificado?idServicio=" + idServicio + "&idPartida=" + idPartida + "&programa=" + programa + "&fechaInicio=" + fechaInicio + "&fechaFin=" + fechaFin + "&idAreaFuncional=" + idAreaFuncional + "&idUnidadFuncional=" + idUnidadFuncional + "&page=" + page + "&offset=10",
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data;
    });
}
// servicios en rutina
export function listaServiciosFiltroRutinas(token){
    return axios({
        method: "get",
        url: endpoint + "orden/servicio/planificado/servicios",
        headers: {'Content-Type': 'application/json', 'Authorization': token},
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data
    })
}

export function generarOrdenProgramaCall(token, listProgramaciones) {
    return axios({
        method: "post",
        url: endpoint + "orden/servicio/planificado",
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        data: listProgramaciones
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data;
    });
}

export function editarInsumosHerramientasCall(token, idOrden, data) {
    return axios({
        method: "post",
        url: endpoint + "orden/servicio/planificado/insumos?idOrden=" + idOrden,
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        data: data,
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data;
    });
}
// Saga busqueda ordenes de rutina, mantenimiento y pruebas
export function listaOrdenesRMPbusquedaCall(token, busqueda, page){
    return axios({
        method: "get",
        url: endpoint + "orden/servicio/planificado/programacion/general?folio=" + busqueda + "&page=" + page + "&offset=10",
        headers: {'Content-Type': 'application/json', 'Authorization': token},
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data
    })
};

export function eliminarTareaOrdenCall(token, idFechaRutina){
    return axios({
        method: "get",
        url: endpoint + "orden/servicio/planificado/eliminar/" + idFechaRutina,
        headers: {'Content-Type': 'application/json', 'Authorization': token},
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data
    })
};

export function reprogramarTareaCall(token, idProgramacionRutina, idFechaRutina, observacion, fechaReprogramacion){
    return axios({
        method: "post",
        url: endpoint + "orden/servicio/planificado/reprogramar/" + idProgramacionRutina + idFechaRutina + "&observacion=" + observacion + "&fechaReprogramacion=" + fechaReprogramacion,
        headers: {'Content-Type': 'application/json', 'Authorization': token},
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data
    });
};

export function reprogramarTareaMultipleCall(token, data){
    return axios({
        method: "post",
        url: endpoint + "orden/servicio/planificado/reprogramar/lista",
        headers: {'Content-Type': 'application/json', 'Authorization': token},
        data: data,
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data
    });
};

export function obtenerProgramacionCall(token, folio, page){
    return axios({
        method: "get",
        url: endpoint + "orden/servicio/planificado/programacion?folio=" + folio + "&page="+ page + "&offset=10",
        headers: {'Content-Type': 'application/json', 'Authorization': token},
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data
    })
};

export function generarPdfMantenimientoCall(token, folio){
    return axios({
        method: "post",
        responseType: "blob",
        url: endpoint + "orden/servicio/planificado/exportar/mantenimiento/folio?folio=" + folio,
        headers: {'Content-Type': 'application/json', 'Authorization': token},
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data
    })
};

export function generarPdfRutinaCall(token, folio){
    return axios({
        method: "post",
        responseType: "blob",
        url: endpoint + "orden/servicio/planificado/exportar/rutina/folio?folio=" + folio,
        headers: {'Content-Type': 'application/json', 'Authorization': token},
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data
    })
};

export function generarPdfPruebaCall(token, folio){
    return axios({
        method: "post",
        responseType: "blob",
        url: endpoint + "orden/servicio/planificado/exportar/prueba/folio?folio=" + folio,
        headers: {'Content-Type': 'application/json', 'Authorization': token},
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response.data
    })
};

//Cancelación de Orden de Servicio Planificado
export function listaCancelacionOrdenesSPCall (token, idServicio, idPartida, idUbicacion, fechaInicio, fechaFin, programa, page) {
    return axios({
        method: 'get',
        url: endpoint + `cancelacion/orden?idServicio=${idServicio}&idPartida=${idPartida}&programa=${programa}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&idUbicacion=${idUbicacion}&page=${page}&offset=10`,
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
    }).then( response => {
        return response;
    }).catch ( error => {
        return error.response.data;
    });
}

export function detalleCancelacionOrdenSPCall (token, folioOrden) {
    return axios({
        method: 'get',
        url: endpoint + `cancelacion/orden/detalle?folio=${folioOrden}`,
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
    }).then( response => {
        return response;
    }).catch( error => {
        return error.response.data;
    });
}

export function cancelarOrdenSPCall (token, folioOrden, observacion) {
    return axios({
        method: 'post', 
        url: endpoint + `cancelacion/orden/cancelar/orden?folioOrden=${folioOrden}&observacion=${observacion}`,
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
    }).then( response => {
        return response;
    }).catch( error => {
        return error.response.data;
    });
}

//Estatus Órdenes Servicios Planificados
export function cambiarEstatusOSPCall (token, folioOrden, status, data) {
    return axios({
        method: 'post',
        url: endpoint + `estatus/serv/planificado/cambiar/status?folioOrden=${folioOrden}&status=${status}`,
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        data: data,
    }).then( response => {
        return response;
    }).catch ( error => {
        return error.response.data;
    });
}

//Editar lista de Personal en Órdenes Servicios Planificados
export function editarPersonalOrdenCall (token, idOrden, data) {
    return axios({
        method: 'put',
        url: endpoint + 'orden/servicio/planificado/personal?idOrden=' + idOrden,
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        data: data,
    }).then( response => {
        return response;
    }).catch ( error => {
        return error.response.data;
    });
}

//Vista Calendario de Rutinas y Mantenimientos
export function listaVistaCalendarioSPCall (token, idServicio, idPartida, idUbicacion, fechaInicio, fechaFin, programa, page) {
    return axios({
        method: 'get',
        url: endpoint + `cancelacion/orden/busqueda/calendario?idServicio=${idServicio}&idPartida=${idPartida}&programa=${programa}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&idUbicacion=${idUbicacion}&page=${page}&offset=10`,
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
    }).then( response => {
        return response;
    }).catch ( error => {
        return error.response.data;
    });
}

//Reporte Servicios Planificados
export function reporteSPBusquedaCall (token, programa, fechaInicio, fechaFin, tipo, estatus, page, idsServicios, idsPartidas) {
    programa = programa ? `programa=${programa}&` : "";
    const fecha = fechaInicio !=="" ? `&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&` : "";
    tipo = tipo ? `tipo=${tipo}&`: "";
    estatus = estatus ? `estatus=${estatus}&` : "";
    return axios({
        method: 'post',
        url: endpoint +`orden/servicio/planificado/reporte?`+ programa + fecha + tipo + estatus + idsServicios + idsPartidas + `&page=${page}&offset=10`,
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
    }).then( response => {
        return response;
    }).catch( error => {
        return error.response.data;
    })
}
export function reporteSPExcelCall (token, programa, fechaInicio, fechaFin, tipo, estatus, idsServicios, idsPartidas) {
    programa = programa ? `programa=${programa}&` : "";
    idsServicios = idsServicios ? `idsServicios=${idsServicios}&`  : ""
    idsPartidas = idsPartidas ? `idsPartidas=${idsPartidas}&` : '';
    const fecha = fechaInicio !=="" ? `&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&` : "";
    tipo = tipo ? `tipo=${tipo}&`: "";
    estatus = estatus ? `estatus=${estatus}` : "";
    return axios({
        method: 'get',
        responseType: "blob",
        url: endpoint + 'orden/servicio/planificado/excel?' + tipo + fecha + programa + estatus + idsServicios + idsPartidas,
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
    }).then( response => {
        return response;
    }).catch( error => {
        return error.response.data;
    })
}
export function reporteSPPDFCall (token, programa, fechaInicio, fechaFin, tipo, estatus, idsServicios, idsPartidas) {
    programa = programa ? `programa=${programa}&` : "";
    idsServicios = idsServicios ? `idsServicios=${idsServicios}&`  : ""
    idsPartidas = idsPartidas ? `idsPartidas=${idsPartidas}&` : '';
    const fecha = fechaInicio !=="" ? `&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&` : "";
    tipo = tipo ? `tipo=${tipo}&`: "";
    estatus = estatus ? `estatus=${estatus}` : "";
    return axios({
        method: 'get',
        responseType: "blob",
        url: endpoint + 'orden/servicio/planificado/pdf?' + tipo + fecha + programa + estatus + idsServicios + idsPartidas,
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
    }).then( response => {
        return response;
    }).catch( error => {
        return error.response.data;
    })
}

//CALENDARIO DE PROGRAMACIÓN
export function calendarioDeProgramacionCall (token, fechaInicio, fechaFin, idGrupoSer, tipoPrograma, idServicio, idPartida, idAreaFuncional) {
    return axios({
        method: 'get',
        url: endpoint + 'orden/servicio/planificado/calendario/programacion?' + fechaInicio + fechaFin + idGrupoSer + tipoPrograma + idServicio + idPartida + idAreaFuncional,
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
    }).then( response => {
        return response;
    }).catch( error => {
        return error.response.data;
    });
}

export function calendarioDetalleCall (token, data) {
    return axios({
        method: 'post',
        url: endpoint + 'orden/servicio/planificado/calendario/programacion/detalle',
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        data: data
    }).then( response => {
        return response;
    }).catch( error => {
        return error.response.data;
    })
}

//Carga Servicios planificados

export function listaArchivosCargaSPCall (token, estado, page) {
    return axios({
        method: 'get',
        url: endpoint + `orden/servicio/planificado/carga/masiva?estado=${estado}&page=${page}&offset=10`,
        headers: {'Content-Type': 'application/json', 'Authorization': token}
    }).then(response => response)
    .catch( error => error.response.data);
}

export function cargaArchivoSPCall (token, data) {
    return axios ({
        method: 'post',
        url: endpoint + `orden/servicio/planificado/carga`,
        headers: {'Content-Type':'application/json', 'Authorization': token},
        data: data
    }).then(response => response)
    .catch( error => error.response.data);
}

export function eliminarCargaSPCall (token,idArchivosSPMasivos) {
    return axios({
        method: 'delete',
        url: endpoint + `orden/servicio/planificado/carga/masiva/${idArchivosSPMasivos}`,
        headers: {'Content-Type':'application/json', 'Authorization': token}
    }).then(response => response)
    .catch( error => error.response.data);
}

export function procesarCargaSPCall (token, idArchivosSPMasivos) {
    return axios({
        method: 'get',
        url: endpoint + `orden/servicio/planificado/carga/masiva/procesando?idArchivoMasivo=${idArchivosSPMasivos}`,
        headers: {'Content-Type': 'application/json', 'Authorization': token}
    }).then ( response => response)
    .catch( error => error.response.data);
}

export function exportarErroresCargaSPCall (token, idArchivosSPMasivos) {
    return axios({
        method: 'get',
        url: endpoint + `orden/servicio/planificado/carga/masiva/errores?idArchivoMasivo=${idArchivosSPMasivos}`,
        headers: {'Content-Type': 'application/json', 'Authorization': token},
        responseType: 'blob'
    }).then ( response => response)
    .catch( error => error.response.data);
}

//Reporte Dinámico SP Services
export function listaReportesSPEspecialesCall (token, page) {
    return axios ({
        method: 'get',
        url: endpoint + `orden/servicio/planificado/reporte/dinamico/predeterminado/obtener/todos?page=${page}&offset=10`,
        headers: {'Content-Type': 'application/json', 'Authorization': token},
    }).then(response => response)
    .catch( error => error.response.data);
}

export function reporteDinamicoSPCall(token, data) {
	return axios({
		method: "post",
	    url: endpoint + "orden/servicio/planificado/reporte/dinamico",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function reporteDinamicoAgrupadoSPCall(token, data) {
	return axios({
		method: "post",
	    url: endpoint + "orden/servicio/planificado/reporte/dinamico/agrupado",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaReportesSPCall (token, page) {
    return axios ({
        method: 'get',
        url: endpoint + `orden/servicio/planificado/reporte/dinamico/predeterminado/obtener/?page=${page}&offset=10`,
        headers: {'Content-Type': 'application/json', 'Authorization': token},
    }).then(response => response)
    .catch( error => error.response.data);
}

export function estadoRegistroReporteSPCall(token, idReporte) {
	return axios({
		method: "get",
		url: endpoint + 'orden/servicio/planificado/reporte/dinamico/predeterminado/activarDesactivar/' + idReporte , 
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function pdfReportesSPCall (token, data) {
    return axios ({
        method: 'post',
        url: endpoint + 'orden/servicio/planificado/reporte/dinamico/pdf',
        headers: {'Content-Type':'application/json', 'Authorization': token},
        responseType: "blob",
        data: data
    }).then(response => response)
    .catch( error => error.response.data);
}

export function excelReportesSPCall (token, data) {
    return axios ({
        method: 'post',
        url: endpoint + 'orden/servicio/planificado/reporte/dinamico/excel',
        headers: {'Content-Type':'application/json', 'Authorization': token},
        responseType: "blob",
        data: data
    }).then(response => response)
    .catch( error => error.response.data);
}

export function listaReportesAgrupadoSPCall (token, data) {
    return axios ({
        method: 'post',
        url: endpoint + 'orden/servicio/planificado/reporte/dinamico/agrupado',
        headers: {'Content-Type':'application/json', 'Authorization': token},
        data: data
    }).then(response => response)
    .catch( error => error.response.data);
}
export function pdfReportesAgrupadoSPCall (token, data) {
    return axios ({
        method: 'post',
        url: endpoint + 'orden/servicio/planificado/reporte/dinamico/agrupado/pdf',
        headers: {'Content-Type':'application/json', 'Authorization': token},
        responseType: "blob",
        data: data
    }).then(response => response)
    .catch( error => error.response.data);
}
export function excelReportesAgrupadoSPCall (token, data) {
    return axios ({
        method: 'post',
        url: endpoint + 'orden/servicio/planificado/reporte/dinamico/agrupado/excel',
        headers: {'Content-Type':'application/json', 'Authorization': token},
        responseType: "blob",
        data: data
    }).then(response => response)
    .catch( error => error.response.data);
}

export function crearRegistroReporteSPCall(token, data) {
	return axios({
		method: "post",
	    url: endpoint + "orden/servicio/planificado/reporte/dinamico/predeterminado",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarRegistroReporteSPCall(token, idReporteOrdenesServiciosPlanificados, data) {
	return axios({
		method: "put",
	    url: endpoint + "orden/servicio/planificado/reporte/dinamico/predeterminado/editar/" + idReporteOrdenesServiciosPlanificados,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}