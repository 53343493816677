import React from 'react';
import { message, Form, Row, Col, Button, Modal, Select, Switch, Divider, Card, InputNumber, Tooltip } from 'antd';
import { connect } from "react-redux";
import { SaveFilled } from '@ant-design/icons';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import IngresarFolio from './IngresarFolio';
import InformacionFolio from './InformacionFolio';
import CapturaInventario from './CapturaInventario';
import NoFolio from './NoFolio';
import FolioCreado from './FolioCreado';

import imaSearch from '../../../../assets/undraw_logistics_x-4-dc.svg';
import ReactVivus from "react-vivus";

const { Option, OptGroup } = Select;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };

class CapturaInventarioFisico extends React.Component {
    formRef = React.createRef();

     componentDidMount() {
          this.props.onGetAlmacenesActivos();
     }

     state = {
          selected: true,
          tipoInventarioLocal: '',
          generarClick: false,

          checkedAlmacen: true,
          checkedServicio: true,
          checkedFamilia: true,
          checkedSubfamilia: true,

          todosAlmacenesNombre: [],
          todosAlmacenesFisicoNombre: [],
          todosServiciosNombre: [],
          todasFamiliasNombre: [],
          todasSubfamiliasNombre: [],

          almacenesSeleccionadosLocal: [],
          serviciosSeleccionadosLocal: [],
          familiasSeleccionadasLocal: [],
          subfamiliasSeleccionadasLocal: [],

          checkedAlmacenFisico: true,
          checkedPiso: true,
          checkedArea: true,
          checkedPasillo: true,
          checkedRack: true,

          todosPisosNombre: [],
          todasAreasNombre: [],
          todosPasillosNombre: [],
          todosRackNombre: [],

          pisosSeleccionados: [],
          areasSeleccionadas: [],
          pasillosSeleccionados: [],
          racksSeleccionados: [],

          almacenesTodos: false,
          pisoTodos: false,
          areasTodos: false,
          pasilloTodos: false,
          rackTodos: false,
          piso: [],
          campoPiso: "",
          area: [],
          campoArea:"",
          pasillo: [],
          campoPasillo:"",
          rack: [],
     }


     render() {
          const { 
               limpiarFormulario, 
               onLimpiarFormulario,
               onShowModalIngresarFolio,
               listaAlmacenesActivos,
               fetchingListaAlmacenesActivos,
               onGetServiciosAlmacen,
               fetchingListaServiciosbyAlmacen,
               listaServiciosbyAlmacen,
               onGetFamiliasServicio,
               fetchingListaFamiliasbyServicio,
               listaFamiliasbyServicio,
               listaSubfamiliasbyFamilia,
               fetchingListaSubfamiliasbyFamilia,
               onGetSubfamiliasFamilia,
               onLimipiarServiciosAlmacen,
               onLimpiarFamiliasServicio,
               onLimpiarSubfamiliasFamilia,
               onModalProductos,

               fetchingListaPisosByAlmacen, 
               listaPisosByAlmacen, 
               fetchingListaAreasByPisos, 
               listaAreasByPisos, 
               fetchingListaPasillosByAreas, 
               listaPasillosByAreas, 
               fetchingListaRacksByPasillos, 
               listaRacksByPasillos,
               onGetPisosByAlmacenes, 
               onGetAreasByPisos, 
               onGetPasillosByAreas, 
               onGetRacksByPasillos,

               onLimpiarPisos,
               onLimpiarAreas,
               onLimpiarPasillos,
               onLimpiarRacks,
               onLimpiarAlmacenes,
               onShowModalInformacionFolio,
               onShowModalCapturaInventario,

               onGenerarFolio,
               fetchingGenerarFolioCaptura
               
          } = this.props;

          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
               permisosUsuario = JSON.parse(permisosUsuario);
          }

          const onFinish = formulario => { console.log('Success:', formulario); };

          const onFinishGenerarFisico = formularioGenerarFisico => {
               let tipoSeleccionado = this.state.tipoInventarioLocal.split(" ")[1];
               let almacenesSeleccionados = [];
               let pisosSeleccionados = [];
               let areasSeleccionadas = [];
               let pasillosSeleccionados = [];
               let racksSeleccionados = [];

               if(!this.state.checkedAlmacenFisico){
                    for(let i = 0; i < listaAlmacenesActivos.length; i ++){
                         almacenesSeleccionados[i] = listaAlmacenesActivos[i].idAlmacen;
                    }
               } else {             
                    almacenesSeleccionados = this.state.almacenesSeleccionadosLocal;
               }

               if(!this.state.checkedPiso){
                    for(let j = 0; j < listaPisosByAlmacen.length; j ++){                         
                         pisosSeleccionados[j] = listaPisosByAlmacen[j];                         
                    }
               } else {
                    pisosSeleccionados = this.state.pisosSeleccionados;
               }

               if(!this.state.checkedArea){
                    for(let j = 0; j < listaAreasByPisos.length; j ++){                         
                         areasSeleccionadas[j] = listaAreasByPisos[j];                         
                    }
               } else {
                    areasSeleccionadas = this.state.areasSeleccionadas;
               }

               if(!this.state.checkedPasillo){
                    for(let j = 0; j < listaPasillosByAreas.length; j ++){                         
                         pasillosSeleccionados[j] = listaPasillosByAreas[j];                         
                    }
               } else {
                    pasillosSeleccionados = this.state.pasillosSeleccionados;
               }

               if(!this.state.checkedRack){
                    for(let j = 0; j < listaRacksByPasillos.length; j ++){                         
                         racksSeleccionados[j] = listaRacksByPasillos[j];                         
                    }
               } else {
                    racksSeleccionados = this.state.racksSeleccionados;
               }

               onGenerarFolio(tipoSeleccionado, [], [], almacenesSeleccionados, pisosSeleccionados, areasSeleccionadas, pasillosSeleccionados, racksSeleccionados, null, null);

               
               setTimeout(onLimpiarFisico, 100);
                    this.setState({ 
                    checkedAlmacenFisico: true,
                    todosAlmacenesNombre: [],
                    todosAlmacenesFisicoNombre: [],
                    });
          };

          const onFinishGenerarCiclico = formularioGenerarCiclico => {
               if(parseFloat(this.formRef.current.getFieldValue('rangoMenor')) > parseFloat(this.formRef.current.getFieldValue('rangoMayor'))){
                    Modal.error({
                         title: <p> <strong>Error en Rangos de Costo </strong> </p>,
                         content: <p> El rango menor ingresado: <strong style={{color: '#ff7875'}}>$ {this.formRef.current.getFieldValue('rangoMenor')}</strong> no puede ser superior a el rango mayor: $ {this.formRef.current.getFieldValue('rangoMayor')}, favor de verificar. </p>,
                    });
               }

               let tipoSeleccionado = this.state.tipoInventarioLocal.split(" ")[2];
               let almacenesSeleccionados = [];
               let subfamiliasSeleccionadas = [];

               if(!this.state.checkedAlmacen){
                    for(let i = 0; i < listaAlmacenesActivos.length; i ++){
                         almacenesSeleccionados[i] = listaAlmacenesActivos[i];
                    }
               } else {
                    for(let i = 0; i < this.state.almacenesSeleccionadosLocal.length; i ++){
                         almacenesSeleccionados[i] = this.state.almacenesSeleccionadosLocal[i];
                    }
               }

               if(!this.state.checkedSubfamilia){
                    for(let i = 0; i < listaSubfamiliasbyFamilia.length; i ++){
                         subfamiliasSeleccionadas[i] = listaSubfamiliasbyFamilia[i];
                    }
               } else {
                    for(let i = 0; i < this.state.subfamiliasSeleccionadasLocal.length; i ++){
                         subfamiliasSeleccionadas[i] = this.state.subfamiliasSeleccionadasLocal[i];
                    }
               }
               
               onGenerarFolio(tipoSeleccionado, almacenesSeleccionados, subfamiliasSeleccionadas, [], [], [], [], [], this.formRef.current.getFieldValue('rangoMayor'), this.formRef.current.getFieldValue('rangoMenor'), this.formRef.current.getFieldValue('porcentaje'));

               setTimeout(onLimpiarCiclico, 200);
               this.setState({ 
                    checkedAlmacen: true,
                    checkedServicio: true,
                    checkedFamilia: true,
                    checkedSubfamilia: true,

                    todosAlmacenesNombre: [],
                    todosAlmacenesFisicoNombre: [],
                    todosServiciosNombre: [],
                    todasFamiliasNombre: [],
                    todasSubfamiliasNombre: [],
                    });
          };

          if (limpiarFormulario && limpiarFormulario === 'inventarioFisico') {
               onLimpiarFormulario();
          }

          const handleChange = (value) => {
               console.log(`selected ${value}`);

               let valorSeleccionado = true;
               let textoInventario = '';

               if(value === 'Físico'){
                    textoInventario = ' Físico General';
               }else{
                    textoInventario = ' tipo Cíclico';
               }

               if(value){
                    valorSeleccionado = false;
               }

               this.setState({ selected: valorSeleccionado, tipoInventarioLocal: textoInventario, generarClick: false });
          };

          const onIngresar = () => {
               onShowModalIngresarFolio(this.state.tipoInventarioLocal, false);

               this.setState({ generarClick: false });
          };

          const onGenerar = () => {
               //onLimpiarAlmacenes();
               this.formRef.current.resetFields(['almacenes', 'servicios', 'familia', 'subfamilia', 'idAlmacen', 'piso', 'area', 'pasillo', 'rack', 'rangoMenor','rangoMayor', 'porcentaje']);
               onLimpiarPisos();
               onLimpiarAreas();
               onLimpiarPasillos();
               onLimpiarRacks();

               onLimipiarServiciosAlmacen();
               onLimpiarFamiliasServicio();
               onLimpiarSubfamiliasFamilia();

               this.setState({ generarClick: true, checkedAlmacenFisico: true, checkedAlmacen: true,
                    checkedPiso: true, checkedArea: true, checkedPasillo: true, checkedRack: true, checkedServicio: true, checkedFamilia: true, checkedSubfamilia: true, almacenesSeleccionadosLocal: []});
          };

          //////////////////////////////////////////////////////////////////////////////////////////////////////////

          const changeDecimalesMenor = (value) => {
               let valueDecimal = parseFloat(value).toFixed(2);
               if(isNaN(valueDecimal)){
                    valueDecimal = 0.00;
               }
               this.formRef.current.setFieldsValue({'rangoMenor': valueDecimal});
          };

          const changeDecimalesMayor = (value) => {
               let valueDecimal = parseFloat(value).toFixed(2);
               if(isNaN(valueDecimal)){
                    valueDecimal = 0.00;
               }
               this.formRef.current.setFieldsValue({'rangoMayor': valueDecimal});
          };

          const todosAlmacenes = (checked) => {
               const almacenesNombre = [listaAlmacenesActivos.length];
               const todosAlmacenesSeleccionados = [listaAlmacenesActivos.length];

               for(let i = 0; i < listaAlmacenesActivos.length; i++){
                    almacenesNombre[i] = listaAlmacenesActivos[i].nombreC;
                    todosAlmacenesSeleccionados[i] = listaAlmacenesActivos[i];
               }

               if(!(!this.formRef.current.getFieldValue('servicios')) || !checked){
                    //message.warning('Por motivos de seguridad vuelva a ingresar los campos de Servicios, Familias y Subfamilias');
                    this.formRef.current.resetFields(['servicios', 'familia', 'subfamilia']);
                    onLimipiarServiciosAlmacen();
                    onLimpiarFamiliasServicio();
                    onLimpiarSubfamiliasFamilia();
                    this.setState({checkedServicio: true, checkedFamilia: true, checkedSubfamilia: true, almacenesSeleccionadosLocal: [], serviciosSeleccionadosLocal: [], familiasSeleccionadasLocal: [] });
               } else if (checked) {
                    this.setState({checkedServicio: true, checkedFamilia: true, checkedSubfamilia: true, almacenesSeleccionadosLocal: [], serviciosSeleccionadosLocal: [], familiasSeleccionadasLocal: []}); 
               }

               onGetServiciosAlmacen(checked, [],listaAlmacenesActivos, todosAlmacenesSeleccionados);
               this.setState({ checkedAlmacen: !checked, todosAlmacenesNombre: almacenesNombre });
               this.formRef.current.resetFields(['almacenes', 'servicios', 'familia', 'subfamilia']);
          };

          const onAlmacenes = (value) => {
               const almacenesSeleccionados = []
               for(let i = 0; i < listaAlmacenesActivos.length; i++){
                    for(let j = 0; j < value.length; j ++){
                         if(listaAlmacenesActivos[i].idAlmacen === parseInt(value[j])){
                              almacenesSeleccionados[j] = listaAlmacenesActivos[i];
                         }
                    }
               }

               if(this.formRef.current.getFieldValue('servicios') || !this.state.checkedServicio){
                    onLimipiarServiciosAlmacen();
                    onLimpiarFamiliasServicio();
                    onLimpiarSubfamiliasFamilia();
                    this.formRef.current.resetFields(['servicios', 'familia', 'subfamilia']);
                    message.warning('Por motivos de seguridad vuelva a ingresar los campos de Servicios, Familias y Subfamilias');
                    this.setState({checkedServicio: true, checkedFamilia: true, checkedSubfamilia: true, almacenesSeleccionadosLocal: [], serviciosSeleccionadosLocal: [], familiasSeleccionadasLocal: [] });
               }

               onGetServiciosAlmacen(!this.state.checkedAlmacen, almacenesSeleccionados, listaAlmacenesActivos, []);
               this.setState({ almacenesSeleccionadosLocal: almacenesSeleccionados });
          };

          const todosServicios = (checked) => {
               const serviciosNombre = [listaServiciosbyAlmacen.length];
               const todosServiciosSeleccionados = [listaServiciosbyAlmacen.length];

               for(let i = 0; i < listaServiciosbyAlmacen.length; i++){
                    serviciosNombre[i] = listaServiciosbyAlmacen[i].nombre;
                    todosServiciosSeleccionados[i] = listaServiciosbyAlmacen[i];
               }

               if(!(!this.formRef.current.getFieldValue('familia')) || !checked){
                    //message.warning('Por motivos de seguridad vuelva a ingresar los campos de Familias y Subfamilias');
                    this.formRef.current.resetFields(['familia', 'subfamilia']);
                    onLimpiarFamiliasServicio();
                    onLimpiarSubfamiliasFamilia();
                    this.setState({checkedFamilia: true, checkedSubfamilia: true, serviciosSeleccionadosLocal: [], familiasSeleccionadasLocal: []});
               } else if (checked) {
                    this.setState({checkedFamilia: true, checkedSubfamilia: true, serviciosSeleccionadosLocal: [], familiasSeleccionadasLocal: []});
               }

               onGetFamiliasServicio(checked, [], listaServiciosbyAlmacen, todosServiciosSeleccionados);
               this.setState({ checkedServicio: !checked, todosServiciosNombre: serviciosNombre });
               this.formRef.current.resetFields(['servicios', 'familia', 'subfamilia']);
          };

          const onServicios = (value) => {
               const serviciosSeleccionados = [];

               for(let i = 0; i < listaServiciosbyAlmacen.length; i++){
                    for(let j = 0; j < value.length; j ++){
                         if(listaServiciosbyAlmacen[i].idServicio === parseInt(value[j])){
                              serviciosSeleccionados[j] = listaServiciosbyAlmacen[i];
                         }
                    }
               }

               if(this.formRef.current.getFieldValue('familia') || !this.state.checkedFamilia){
                    this.formRef.current.resetFields(['familia', 'subfamilia']);
                    message.warning('Por motivos de seguridad vuelva a ingresar los campos de Familia y Subfamilia');
                    onLimpiarFamiliasServicio();
                    onLimpiarSubfamiliasFamilia();
                    this.setState({checkedFamilia: true, checkedSubfamilia: true, serviciosSeleccionadosLocal: [], familiasSeleccionadasLocal: []});
               }

               onGetFamiliasServicio(!this.state.checkedServicio, serviciosSeleccionados, listaServiciosbyAlmacen, []);
               this.setState({ serviciosSeleccionadosLocal: serviciosSeleccionados });
          };

          const todasFamilias = (checked) => {
               const familiasNombre = [listaFamiliasbyServicio.length];
               const todasFamiliasSeleccionadas = [listaFamiliasbyServicio.length];

               for(let i = 0; i < listaFamiliasbyServicio.length; i++){
                    familiasNombre[i] = listaFamiliasbyServicio[i].familia;
                    todasFamiliasSeleccionadas[i] = listaFamiliasbyServicio[i];
               }

               if(!(!this.formRef.current.getFieldValue('subfamilia')) || !checked){
                    //message.warning('Por motivos de seguridad vuelva a ingresar el campos de Subfamilias');
                    this.formRef.current.resetFields(['subfamilia']);
                    onLimpiarSubfamiliasFamilia();
                    this.setState({checkedSubfamilia: true, familiasSeleccionadasLocal: []});
               } else if (checked) {
                    this.setState({checkedSubfamilia: true, familiasSeleccionadasLocal: []});
               }

               onGetSubfamiliasFamilia(checked, [], listaFamiliasbyServicio, todasFamiliasSeleccionadas);
               this.setState({ checkedFamilia: !checked, todasFamiliasNombre: familiasNombre });
               this.formRef.current.resetFields(['familia', 'subfamilia']);
          };


          const onFamilia = (value) => {
               const familiasSeleccionadas = [];

               for(let i = 0; i < listaFamiliasbyServicio.length; i++){
                    for(let j = 0; j < value.length; j++){
                         if(listaFamiliasbyServicio[i].idFamilia === parseInt(value[j])){
                              familiasSeleccionadas[j] = listaFamiliasbyServicio[i];
                         }
                    }
               }

               if(this.formRef.current.getFieldValue('subfamilia') || !this.state.checkedSubfamilia){
                    this.formRef.current.resetFields(['subfamilia']);
                    message.warning('Por motivos de seguridad vuelva a ingresar el campo de Subfamilia');
                    onLimpiarSubfamiliasFamilia();
                    this.setState({checkedSubfamilia: true, familiasSeleccionadasLocal: []});
               }

               onGetSubfamiliasFamilia(!this.state.checkedFamilia, familiasSeleccionadas, listaFamiliasbyServicio, []);
               this.setState({ familiasSeleccionadasLocal: familiasSeleccionadas });
          };

          const todasSubfamilias = (checked) => {
               const subfamiliasNombre = [listaSubfamiliasbyFamilia.length];

               for(let i = 0; i < listaSubfamiliasbyFamilia.length; i++){
                    subfamiliasNombre[i] = listaSubfamiliasbyFamilia[i].subFamilia;
               }

               this.setState({ checkedSubfamilia: !checked, todasSubfamiliasNombre: subfamiliasNombre });
               this.formRef.current.resetFields(['subfamilia']);
          };

          const onSubfamilias = (value) => {
               const subfamiliasSeleccionadas = [];

               for(let i = 0; i < listaSubfamiliasbyFamilia.length; i++){
                    for(let j = 0; j < value.length; j++){
                         if(listaSubfamiliasbyFamilia[i].idSubFamilia === parseInt(value[j])){
                              subfamiliasSeleccionadas[j] = listaSubfamiliasbyFamilia[i];
                         }
                    }
               }
               
               this.setState({ subfamiliasSeleccionadasLocal: subfamiliasSeleccionadas });
          };

          const onLimpiarCiclico = () => {
               this.formRef.current.resetFields(['almacenes', 'servicios', 'familia', 'subfamilia', 'rangoMenor', 'rangoMayor', 'porcentaje']);
               onLimipiarServiciosAlmacen();
               onLimpiarFamiliasServicio();
               onLimpiarSubfamiliasFamilia();
               this.setState({
                    chekcedAlmacen: true, checkedServicio: true, checkedFamilia: true, checkedSubfamilia: true, almacenesSeleccionadosLocal: [], serviciosSeleccionadosLocal: [], familiasSeleccionadasLocal: []
               });
          };

          const onGuardarCiclico = () => {
               if(parseFloat(this.formRef.current.getFieldValue('rangoMenor')) > parseFloat(this.formRef.current.getFieldValue('rangoMayor'))){
                    Modal.error({
                         title: <p> <strong>Error en Rangos de Costo </strong> </p>,
                         content: <p> El rango menor ingresado: <strong style={{color: '#ff7875'}}>$ {this.formRef.current.getFieldValue('rangoMenor')}</strong> no puede ser superior a el rango mayor: $ {this.formRef.current.getFieldValue('rangoMayor')}, favor de verificar. </p>,
                    });
               }
                    let tipoSeleccionado = this.state.tipoInventarioLocal.split(" ")[2];
                    let almacenesSeleccionados = [];
                    let subfamiliasSeleccionadas = [];

                    if(!this.state.checkedAlmacen){
                         for(let i = 0; i < listaAlmacenesActivos.length; i ++){
                              almacenesSeleccionados[i] = listaAlmacenesActivos[i];
                         }
                    } else {
                         for(let i = 0; i < this.state.almacenesSeleccionadosLocal.length; i ++){
                              almacenesSeleccionados[i] = this.state.almacenesSeleccionadosLocal[i];
                         }
                    }

                    if(!this.state.checkedSubfamilia){
                         for(let i = 0; i < listaSubfamiliasbyFamilia.length; i ++){
                              subfamiliasSeleccionadas[i] = listaSubfamiliasbyFamilia[i];
                         }
                    } else {
                         for(let i = 0; i < this.state.subfamiliasSeleccionadasLocal.length; i ++){
                              subfamiliasSeleccionadas[i] = this.state.subfamiliasSeleccionadasLocal[i];
                         }
                    }
                    
                    onGenerarFolio(tipoSeleccionado, almacenesSeleccionados, subfamiliasSeleccionadas, [], [], [], [], [], this.formRef.current.getFieldValue('rangoMayor'), this.formRef.current.getFieldValue('rangoMenor'), this.formRef.current.getFieldValue('porcentaje'));

                    setTimeout(onLimpiarCiclico, 200);
                    this.setState({ 
                         checkedAlmacen: true,
                         checkedServicio: true,
                         checkedFamilia: true,
                         checkedSubfamilia: true,

                         todosAlmacenesNombre: [],
                         todosAlmacenesFisicoNombre: [],
                         todosServiciosNombre: [],
                         todasFamiliasNombre: [],
                         todasSubfamiliasNombre: [],
                     });
               //}
          };

          /////////////////////////////////////////////////////////////////////////////////////////////////////

          const todosAlmacenesFisico = (checked) => {
               const almacenesNombre = [listaAlmacenesActivos.length];
               const todosAlmacenesSeleccionados = [listaAlmacenesActivos.length];

               for(let i = 0; i < listaAlmacenesActivos.length; i++){
                    almacenesNombre[i] = listaAlmacenesActivos[i].nombreC;
                    todosAlmacenesSeleccionados[i] = listaAlmacenesActivos[i].idAlmacen;
               }

               if(!(!this.formRef.current.getFieldValue('piso')) || !checked){
                    onLimpiarPisos();
                    onLimpiarAreas();
                    onLimpiarPasillos();
                    onLimpiarRacks();

                    this.formRef.current.resetFields(['idAlmacen', 'piso', 'area', 'pasillo', 'rack']);
                    this.setState({checkedAlmacenFisico: true, checkedPiso: true, checkedArea: true, checkedPasillo: true, checkedRack: true, almacenesSeleccionadosLocal: []});
               } else if (checked) {
                    onLimpiarPisos();
                    onLimpiarAreas();
                    onLimpiarPasillos();
                    onLimpiarRacks();

                    onGetPisosByAlmacenes(todosAlmacenesSeleccionados);
                    this.setState({checkedAlmacenFisico: true, checkedPiso: true, checkedArea: true, checkedPasillo: true, checkedRack: true, almacenesSeleccionadosLocal: []}); 
               }

               this.setState({ checkedAlmacenFisico: !checked, todosAlmacenesFisicoNombre: almacenesNombre });
               this.formRef.current.resetFields(['idAlmacen', 'piso', 'area', 'pasillo', 'rack']);
          };

          const onChangeAlmacen = (value) => {
               const almacenesSeleccionados = []
               for(let i = 0; i < listaAlmacenesActivos.length; i++){
                    for(let j = 0; j < value.length; j ++){
                         if(listaAlmacenesActivos[i].idAlmacen === parseInt(value[j])){
                              almacenesSeleccionados[j] = listaAlmacenesActivos[i].idAlmacen;
                         }
                    }
               }

               if(this.formRef.current.getFieldValue('piso') || !this.state.checkedPiso){
                    onLimpiarPisos();
                    onLimpiarAreas();
                    onLimpiarPasillos();
                    onLimpiarRacks();
                    this.formRef.current.resetFields(['piso', 'area', 'pasillo', 'rack']);
                    message.warning('Por motivos de seguridad vuelva a ingresar los campos de Piso, Área, Pasillo y Rack');
                    this.setState({checkedAlmacenFisico: true, checkedPiso: true, checkedArea: true, checkedPasillo: true, checkedRack: true, almacenesSeleccionadosLocal: []});
               }

               onGetPisosByAlmacenes(almacenesSeleccionados);
               this.setState({ almacenesSeleccionadosLocal: almacenesSeleccionados });
          };

          const todosPisos = (checked) => {
               const pisosNombre = [listaPisosByAlmacen.length];

               for(let i = 0; i < listaPisosByAlmacen.length; i++){
                    pisosNombre[i] = listaPisosByAlmacen[i];
               }

               if(!(!this.formRef.current.getFieldValue('area')) || !checked){
                    onLimpiarAreas();
                    onLimpiarPasillos();
                    onLimpiarRacks();
                    this.formRef.current.resetFields(['piso', 'area', 'pasillo', 'rack']);
                    this.setState({checkedPiso: true, checkedArea: true, checkedPasillo: true, checkedRack: true });
               } else if (checked) {
                    onLimpiarAreas();
                    onLimpiarPasillos();
                    onLimpiarRacks();
                    this.setState({checkedPiso: true, checkedArea: true, checkedPasillo: true, checkedRack: true}); 

                    onGetAreasByPisos(listaPisosByAlmacen);
               }

               this.setState({ checkedPiso: !checked, todosPisosNombre: pisosNombre });
               this.formRef.current.resetFields(['piso', 'area', 'pasillo', 'rack']);
          };
            
           const onChangePiso = value => {

               if(this.formRef.current.getFieldValue('area') || !this.state.checkedArea){
                    onLimpiarAreas();
                    onLimpiarPasillos();
                    onLimpiarRacks();
                    this.formRef.current.resetFields(['area', 'pasillo', 'rack']);
                    message.warning('Por motivos de seguridad vuelva a ingresar los campos de Área, Pasillo y Rack');
                    this.setState({checkedPiso: true, checkedArea: true, checkedPasillo: true, checkedRack: true });
               }

               onGetAreasByPisos(value)
               this.setState({ pisosSeleccionados: value });
          }

          const todasAreas = (checked) => {
               const areasNombre = [listaAreasByPisos.length];

               for(let i = 0; i < listaAreasByPisos.length; i++){
                    areasNombre[i] = listaAreasByPisos[i];
               }

               if(!(!this.formRef.current.getFieldValue('pasillo')) || !checked){
                    onLimpiarPasillos();
                    onLimpiarRacks();
                    this.formRef.current.resetFields(['area', 'pasillo', 'rack']);
                    this.setState({checkedArea: true, checkedPasillo: true, checkedRack: true });
               } else if (checked) {
                    onLimpiarPasillos();
                    onLimpiarRacks();
                    this.setState({checkedArea: true, checkedPasillo: true, checkedRack: true }); 
               }

               onGetPasillosByAreas(listaAreasByPisos);

               this.setState({ checkedArea: !checked, todasAreasNombre: areasNombre });
               this.formRef.current.resetFields(['area', 'pasillo', 'rack']);
          };
   
          const onChangeArea = value => {

               if(this.formRef.current.getFieldValue('pasillo') || !this.state.checkedPasillo){
                    onLimpiarPasillos();
                    onLimpiarRacks();
                    this.formRef.current.resetFields(['pasillo', 'rack']);
                    message.warning('Por motivos de seguridad vuelva a ingresar los campos de Pasillo y Rack');
                    this.setState({
                         checkedArea: true, checkedPasillo: true, checkedRack: true
                    });
               }

               onGetPasillosByAreas(value)
               this.setState({ areasSeleccionadas: value });
          }

          const todosPasillos = (checked) => {
               const pasillosNombre = [listaPasillosByAreas.length];

               for(let i = 0; i < listaPasillosByAreas.length; i++){
                    pasillosNombre[i] = listaPasillosByAreas[i];
               }

               if(!(!this.formRef.current.getFieldValue('rack')) || !checked){
                    onLimpiarRacks();
                    this.formRef.current.resetFields(['pasillo', 'rack']);
                    this.setState({checkedRack: true });
               } else if (checked) {
                    onLimpiarRacks();
                    this.setState({checkedRack: true }); 

                    onGetRacksByPasillos(listaPasillosByAreas);
               }

               this.setState({ checkedPasillo: !checked, todosPasillosNombre: pasillosNombre });
               this.formRef.current.resetFields(['pasillo', 'rack']);
          };
   
          const onChangePasillo = value => {

               if(this.formRef.current.getFieldValue('rack') || !this.state.checkedRack){
                    onLimpiarRacks();
                    this.formRef.current.resetFields(['rack']);
                    message.warning('Por motivos de seguridad vuelva a ingresar el campo de Rack');
                    this.setState({
                         checkedRack: true 
                    });
               }

               onGetRacksByPasillos(value)
               this.setState({ pasillosSeleccionados: value });
          }

          const todosRacks = (checked) => {
               const racksNombre = [listaRacksByPasillos.length];

               for(let i = 0; i < listaRacksByPasillos.length; i++){
                    racksNombre[i] = listaRacksByPasillos[i];
               }

               this.setState({ checkedRack: !checked, todosRackNombre: racksNombre });
               this.formRef.current.resetFields(['rack']);
          };
   
          const onChangeRack = value => {
               this.setState({ checkedRack: true, racksSeleccionados: value });
          }

          const onLimpiarFisico = () => {
               console.log('Entra a limpiar físico')

               this.formRef.current.resetFields(['idAlmacen', 'piso', 'area', 'pasillo', 'rack']);
               
               onLimpiarPisos();
               onLimpiarAreas();
               onLimpiarPasillos();
               onLimpiarRacks();

               this.setState({
                    checkedAlmacenFisico: true, 
                    checkedPiso: true, 
                    checkedArea: true, 
                    checkedPasillo: true, 
                    checkedRack: true, 
                    almacenesSeleccionadosLocal: [],
                    todosAlmacenesFisicoNombre: [],
               });
          };

          const onGuardarFisico = () => {

               // if( !(
               //      ( !(!this.formRef.current.getFieldValue('idAlmacen')) || !this.state.checkedAlmacenFisico ) && 
               //      ( !(!this.formRef.current.getFieldValue('piso')) || !this.state.checkedPiso ) && 
               //      ( !(!this.formRef.current.getFieldValue('area')) || !this.state.checkedArea ) && 
               //      ( !(!this.formRef.current.getFieldValue('pasillo')) || !this.state.checkedPasillo ) && 
               //      ( !(!this.formRef.current.getFieldValue('rack')) || !this.state.checkedRack )
               // )){
               //      Modal.warning({
               //           title: <p> <strong>Campos Incompletos</strong> </p>,
               //           content: <p> Es necesario llenar todos los campos, favor de verificar. </p>,
               //      });
               // } else {
                    let tipoSeleccionado = this.state.tipoInventarioLocal.split(" ")[1];
                    let almacenesSeleccionados = [];
                    let pisosSeleccionados = [];
                    let areasSeleccionadas = [];
                    let pasillosSeleccionados = [];
                    let racksSeleccionados = [];

                    if(!this.state.checkedAlmacenFisico){
                         for(let i = 0; i < listaAlmacenesActivos.length; i ++){
                              almacenesSeleccionados[i] = listaAlmacenesActivos[i].idAlmacen;
                         }
                    } else {             
                         almacenesSeleccionados = this.state.almacenesSeleccionadosLocal;
                    }

                    if(!this.state.checkedPiso){
                         for(let j = 0; j < listaPisosByAlmacen.length; j ++){                         
                              pisosSeleccionados[j] = listaPisosByAlmacen[j];                         
                         }
                    } else {
                         pisosSeleccionados = this.state.pisosSeleccionados;
                    }

                    if(!this.state.checkedArea){
                         for(let j = 0; j < listaAreasByPisos.length; j ++){                         
                              areasSeleccionadas[j] = listaAreasByPisos[j];                         
                         }
                    } else {
                         areasSeleccionadas = this.state.areasSeleccionadas;
                    }

                    if(!this.state.checkedPasillo){
                         for(let j = 0; j < listaPasillosByAreas.length; j ++){                         
                              pasillosSeleccionados[j] = listaPasillosByAreas[j];                         
                         }
                    } else {
                         pasillosSeleccionados = this.state.pasillosSeleccionados;
                    }

                    if(!this.state.checkedRack){
                         for(let j = 0; j < listaRacksByPasillos.length; j ++){                         
                              racksSeleccionados[j] = listaRacksByPasillos[j];                         
                         }
                    } else {
                         racksSeleccionados = this.state.racksSeleccionados;
                    }

                    onGenerarFolio(tipoSeleccionado, [], [], almacenesSeleccionados, pisosSeleccionados, areasSeleccionadas, pasillosSeleccionados, racksSeleccionados, null, null);

                    
                    setTimeout(onLimpiarFisico, 100);
                     this.setState({ 
                         checkedAlmacenFisico: true,
                         todosAlmacenesNombre: [],
                         todosAlmacenesFisicoNombre: [],
                     });
               //}
          };

          //////////////////////////////////////////////////////////////////////////////////////////////////////////

          return (<>
          <Card className="cardCatalogos" title={<div className="headerLista">Captura de Toma de Inventarios Físicos</div>}>
               <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    ref={this.formRef}
               >
                    <Row justify='space-around' style={{ textAlign: 'left' }} gutter={[8,8]}>   
                         <Col span={14}>
                              <Form.Item
                              label="Tipo de Inventario"
                              name="tipoInventario"
                              rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                              >
                                   <Select  
                                        placeholder="Selecciona un tipo de inventario"
                                        onChange={handleChange}
                                   >
                                        <Option value={'Físico'}> Inventario Físico General </Option>
                                        <Option value={'Cíclico'}> Inventario tipo Cíclico </Option>
                                   </Select>
                              </Form.Item>
                         </Col>

                         <Col span={5} style={{paddingTop:'3em'}}>
                              <Button 
                                   className="btnFiltrar" 
                                   style={{ width: '100%' }}
                                   onClick={onIngresar} 
                                   disabled={!permisosUsuario.ingresarFolioDeInventarioFisico}
                              >
                                   Ingresar Folio de Inventario
                              </Button>
                         </Col>

                         <Col span={5} style={{paddingTop:'3em'}}>
                              <Button 
                                   className="btnFiltrar" 
                                   style={{ width: '100%' }}
                                   onClick={onGenerar} disabled={!permisosUsuario.crearFolioDeInventarioFisico || this.state.selected}
                              >
                                   Generar Folio de Inventario
                              </Button>
                         </Col>

                         <IngresarFolio/>
                         <InformacionFolio/>
                         <CapturaInventario/>
                         <NoFolio/>
                         <FolioCreado/>
                    </Row>   
               </Form>
          </Card>
          
          <Divider />

          { this.state.generarClick ? (
               <Card 
                    className="cardCatalogos" 
                    title={<div className="headerLista">Generar Folio de Inventario{this.state.tipoInventarioLocal}</div>}
               >
                    <Row justify='space-around' gutter={[8,8]}>
                         <Col span={24}> 
                              { this.state.tipoInventarioLocal === ' Físico General' ? ( <div>
                                   <Form {...layout}
                                        name="formularioGenerarFisico"
                                        onFinish={onFinishGenerarFisico}
                                        ref={this.formRef}
                                   >
                                        <Row justify='space-between' gutter={[8,8]}>
                                             <Col span={22}>
                                                  <Form.Item
                                                       label='Almacenes'
                                                       name='idAlmacen'
                                                       rules={[{ required: this.state.checkedAlmacenFisico, message: 'Favor de llenar el campo', }]}
                                                  >
                                                       { this.state.checkedAlmacenFisico ? (
                                                       <Select
                                                            mode='multiple'
                                                            allowClear
                                                            style={{ width: '100%' }}
                                                            placeholder="Seleccione un almacén"
                                                            loading={fetchingListaAlmacenesActivos}
                                                            onChange={onChangeAlmacen}
                                                       >    
                                                            
                                                            {listaAlmacenesActivos.map(option => <Option key={option.idAlmacen}>{option.nombreC}</Option>)}

                                                       </Select>
                                                       ): (
                                                            <Select
                                                                 mode="multiple"
                                                                 style={{ width: '100%' }}
                                                                 placeholder="Se han seleccionado todos los almacenes"
                                                                 defaultValue={this.state.todosAlmacenesFisicoNombre}
                                                                 disabled={true}
                                                            />

                                                       ) }
                                                  </Form.Item>
                                             </Col>

                                             <Col span={2}>
                                                  <Form.Item
                                                       label=' '
                                                       name='todosAlmacenesFisico'
                                                  >
                                                       <Switch
                                                            checkedChildren={'TODOS'}
                                                            unCheckedChildren={'TODOS'}
                                                            defaultChecked={false}
                                                            checked={!this.state.checkedAlmacenFisico}
                                                            size={'default'}
                                                            onChange={todosAlmacenesFisico}
                                                       />
                                                  </Form.Item>
                                             </Col>

                                             { this.state.almacenesSeleccionadosLocal[0] || !this.state.checkedAlmacenFisico ? (
                                             <Col span={22}>
                                             <Form.Item
                                                  label='Pisos'
                                                  name='piso'
                                                  rules={[{ required: this.state.checkedPiso, message: 'Favor de llenar el campo', }]}
                                             >
                                                  { this.state.checkedPiso ? (
                                                  <Select
                                                       mode='multiple'
                                                       allowClear
                                                       style={{ width: '100%' }}
                                                       placeholder="Seleccione un piso"
                                                       loading={fetchingListaPisosByAlmacen}
                                                       onChange={onChangePiso}
                                                       //disabled={!this.state.almacenesSeleccionadosLocal.length > 0}
                                                  >
                                                  
                                                       {listaPisosByAlmacen.map(option => <Option key={option}>{option}</Option>)}

                                                  </Select>
                                                  ): (
                                                       <Select
                                                            mode="multiple"
                                                            style={{ width: '100%' }}
                                                            placeholder="Se han seleccionado todos los pisos"
                                                            defaultValue={this.state.todosPisosNombre}
                                                            disabled={true}
                                                       />
                                                  )}
                                             </Form.Item>
                                             </Col>
                                             ): false }

                                             { this.state.almacenesSeleccionadosLocal[0] || !this.state.checkedAlmacenFisico ? (
                                             <Col span={2}>
                                                  <Form.Item
                                                       label=' '
                                                       name='todosPisos'
                                                  >
                                                       <Switch
                                                            checkedChildren={'TODOS'}
                                                            unCheckedChildren={'TODOS'}
                                                            defaultChecked={false}
                                                            checked={!this.state.checkedPiso}
                                                            disabled={!listaPisosByAlmacen[0]}
                                                            size={'default'}
                                                            onChange={todosPisos}
                                                       />
                                                  </Form.Item>
                                             </Col>
                                             ): false }

                                             { listaAreasByPisos[0]|| !this.state.checkedPiso ? (    
                                             <Col span={22}>
                                             <Form.Item
                                                  label='Áreas'
                                                  name='area'
                                                  rules={[{ required: this.state.checkedArea, message: 'Favor de llenar el campo', }]}
                                             >    
                                                  { this.state.checkedArea ? (
                                                  <Select
                                                       mode='multiple'
                                                       allowClear
                                                       style={{ width: '100%' }}
                                                       placeholder="Seleccione un área"
                                                       loading={fetchingListaAreasByPisos}
                                                       onChange={onChangeArea}
                                                       //disabled={!listaAreasByPisos.length > 0}
                                                  >
                                                       {listaAreasByPisos.map(option => <Option key={option}>{option}</Option>)}
                                                  </Select>
                                                  ):(
                                                       <Select
                                                            mode="multiple"
                                                            style={{ width: '100%' }}
                                                            placeholder="Se han seleccionado todas las áreas"
                                                            defaultValue={this.state.todasAreasNombre}
                                                            disabled={true}
                                                       />
                                                  )}
                                             </Form.Item>
                                             </Col>
                                             ): false }

                                             { listaAreasByPisos[0] || !this.state.checkedPiso ? ( 
                                             <Col span={2}>
                                                  <Form.Item
                                                       label=' '
                                                       name='todasAreas'
                                                  >
                                                       <Switch
                                                            checkedChildren={'TODAS'}
                                                            unCheckedChildren={'TODAS'}
                                                            defaultChecked={false}
                                                            checked={!this.state.checkedArea}
                                                            disabled={!listaAreasByPisos[0]}
                                                            size={'default'}
                                                            onChange={todasAreas}
                                                       />
                                                  </Form.Item>
                                             </Col>
                                             ): false }

                                             { listaPasillosByAreas[0] || !this.state.checkedArea ? (      
                                             <Col span={22}>
                                             <Form.Item
                                                  label='Pasillos'
                                                  name='pasillo'
                                                  rules={[{ required: this.state.checkedPasillo, message: 'Favor de llenar el campo', }]}
                                             >
                                                  { this.state.checkedPasillo ? (
                                                  <Select
                                                       mode='multiple'
                                                       allowClear
                                                       style={{ width: '100%' }}
                                                       placeholder="Seleccione un pasillo"
                                                       loading={fetchingListaPasillosByAreas}
                                                       onChange={onChangePasillo}
                                                       //disabled={!listaPasillosByAreas.length > 0}
                                                  >
                                                       {listaPasillosByAreas.map(option => <Option key={option}>{option}</Option>)}
                                                  </Select>
                                                  ):(
                                                       <Select
                                                            mode="multiple"
                                                            style={{ width: '100%' }}
                                                            placeholder="Se han seleccionado todos los pasillos"
                                                            defaultValue={this.state.todosPasillosNombre}
                                                            disabled={true}
                                                       />
                                                  )}
                                             </Form.Item>
                                             </Col>
                                             ): false }
                                             
                                             { listaPasillosByAreas[0] || !this.state.checkedArea ? (      
                                             <Col span={2}>
                                                  <Form.Item
                                                       label=' '
                                                       name='todosPasillos'
                                                  >
                                                       <Switch
                                                            checkedChildren={'TODOS'}
                                                            unCheckedChildren={'TODOS'}
                                                            defaultChecked={false}
                                                            checked={!this.state.checkedPasillo}
                                                            disabled={!listaPasillosByAreas[0]}
                                                            size={'default'}
                                                            onChange={todosPasillos}
                                                       />
                                                  </Form.Item>
                                             </Col>
                                             ): false }

                                             { listaRacksByPasillos[0] || !this.state.checkedPasillo ? ( 
                                             <Col span={22}>
                                             <Form.Item
                                                  name='rack'
                                                  label='Racks'
                                                  rules={[{ required: this.state.checkedRack, message: 'Favor de llenar el campo', }]}
                                             >
                                                  { this.state.checkedRack ? (
                                                  <Select
                                                       mode='multiple'
                                                       allowClear
                                                       style={{ width: '100%' }}
                                                       placeholder="Seleccione un rack"
                                                       loading={fetchingListaRacksByPasillos}
                                                       onChange={onChangeRack}
                                                       //disabled={!listaRacksByPasillos.length > 0}
                                                  >
                                                            {listaRacksByPasillos.map(option => <Option key={option}>{option}</Option>)}                                                                 
                                                  </Select>
                                                  ):(
                                                       <Select
                                                            mode="multiple"
                                                            style={{ width: '100%' }}
                                                            placeholder="Se han seleccionado todos los racks"
                                                            defaultValue={this.state.todosRackNombre}
                                                            disabled={true}
                                                       />
                                                  ) }
                                             </Form.Item>
                                             </Col>
                                             ): false }

                                             { listaRacksByPasillos[0] || !this.state.checkedPasillo ? ( 
                                             <Col span={2}>
                                                  <Form.Item
                                                       label=' '
                                                       name='todosRacks'
                                                  >
                                                       <Switch
                                                            checkedChildren={'TODOS'}
                                                            unCheckedChildren={'TODOS'}
                                                            defaultChecked={false}
                                                            checked={!this.state.checkedRack}
                                                            disabled={!listaRacksByPasillos[0]}
                                                            size={'default'}
                                                            onChange={todosRacks}
                                                       />
                                                  </Form.Item>
                                             </Col>
                                             ): false }
                                        </Row>

                                        {/* <Button
                                             className="btnFiltrar" 
                                             style={{ width: '100%' }}
                                             // onClick={onGuardarFisico} 
                                             htmlType='submit'
                                             loading={fetchingGenerarFolioCaptura}
                                        >
                                             Guardar
                                        </Button> */}
                                        <Row justify='end' gutter={[8,8]}>
                                             <Col span={1} style={{paddingTop:'3em'}}>
                                                  <Tooltip title='Guardar'>
                                                       <Button loading={fetchingGenerarFolioCaptura} shape="circle" icon={<SaveFilled />} className="iconTer" htmlType='submit' />
                                                  </Tooltip>
                                             </Col>  
                                        </Row>
                                   </Form>
                              </div> ): (  <div>
                                   <Form {...layout}
                                        name="formularioGenerarCiclico"
                                        onFinish={onFinishGenerarCiclico}
                                        ref={this.formRef}
                                   >
                                        <Row justify='space-around' gutter={[8,8]}>
                                             <Col span={22}>
                                                  <Form.Item
                                                  label='Almacenes'
                                                  name='almacenes'
                                                  rules={[{ required: this.state.checkedAlmacen, message: 'Favor de llenar el campo', }]}
                                                  >
                                                       { this.state.checkedAlmacen ? (
                                                       <Select
                                                            mode="multiple"
                                                            allowClear
                                                            style={{ width: '100%' }}
                                                            placeholder="Selecciona uno varios almacenes"
                                                            loading={fetchingListaAlmacenesActivos}
                                                            disabled={!this.state.checkedAlmacen}
                                                            notFoundContent={null}
                                                            onChange={onAlmacenes}
                                                       >
                                                            {listaAlmacenesActivos.map(option => <Option key={option.idAlmacen}>{option.nombreC}</Option>)}
                                                       </Select>
                                                       ): (
                                                       <Select
                                                            mode="multiple"
                                                            style={{ width: '100%' }}
                                                            placeholder="Se han seleccionado todos los almacenes"
                                                            defaultValue={this.state.todosAlmacenesNombre}
                                                            disabled={true}
                                                       />

                                                       ) }
                                                  </Form.Item>
                                             </Col>

                                             <Col span={2}>
                                                  <Form.Item
                                                       label=' '
                                                       name='todosAlmacenes'
                                                  >
                                                       <Switch
                                                            checkedChildren={'TODOS'}
                                                            unCheckedChildren={'TODOS'}
                                                            defaultChecked={false}
                                                            checked={!this.state.checkedAlmacen}
                                                            size={'default'}
                                                            onChange={todosAlmacenes}
                                                       />
                                                  </Form.Item>
                                             </Col>
                                        </Row>
                                        
                                        <Row justify='space-around' gutter={[8,8]}>
                                             { (this.state.almacenesSeleccionadosLocal.length > 0) || !this.state.checkedAlmacen ? (
                                             <Col span={22}>
                                                  <Form.Item
                                                  label='Servicios'
                                                  name='servicios'
                                                  rules={[{ required: this.state.checkedServicio, message: 'Favor de llenar el campo', }]}
                                                  >
                                                       { this.state.checkedServicio ? (
                                                            <Select
                                                                 mode="multiple"
                                                                 allowClear
                                                                 style={{ width: '100%' }}
                                                                 placeholder="Selecciona uno varios servicios"
                                                                 loading={fetchingListaServiciosbyAlmacen}
                                                                 onChange={onServicios}
                                                            >
                                                                 {listaServiciosbyAlmacen.map(option => <Option key={option.idServicio}>{option.nombre}</Option>)}
                                                            </Select>
                                                       ): (
                                                            <Select
                                                                 mode="multiple"
                                                                 style={{ width: '100%' }}
                                                                 placeholder="Se han seleccionado todos los servicios"
                                                                 defaultValue={this.state.todosServiciosNombre}
                                                                 disabled={true}
                                                            />
                                                       ) }
                                                  </Form.Item>                                  
                                             </Col>
                                             ): false}

                                             { !this.state.checkedAlmacen || this.state.almacenesSeleccionadosLocal[0] ? (
                                             <Col span={2}>
                                                  <Form.Item
                                                       label=' '
                                                       name='todosServicios'
                                                  >
                                                       <Switch
                                                            checkedChildren={'TODOS'}
                                                            unCheckedChildren={'TODOS'}
                                                            defaultChecked={false}
                                                            checked={!this.state.checkedServicio}
                                                            disabled={!listaServiciosbyAlmacen[0]}
                                                            size={'default'}
                                                            onChange={todosServicios}
                                                            
                                                       />
                                                  </Form.Item>
                                             </Col>   
                                             ): false}                                              

                                             { !this.state.checkedServicio || this.state.serviciosSeleccionadosLocal[0] ? (  
                                             <Col span={22}>
                                                  <Form.Item
                                                  label='Familias'
                                                  name='familia'
                                                  rules={[{ required: this.state.checkedFamilia, message: 'Favor de llenar el campo', }]}
                                                  >
                                                       { this.state.checkedFamilia ? (
                                                            <Select
                                                                 mode="multiple"
                                                                 style={{ width: '100%' }}
                                                                 placeholder="Selecciona una o varias familias"
                                                                 loading={fetchingListaFamiliasbyServicio}
                                                                 onChange={onFamilia}
                                                            >
                                                                 {listaFamiliasbyServicio.map(option => <Option key={option.idFamilia}>{option.familia}</Option>)}
                                                            </Select>
                                                       ):(
                                                            <Select
                                                                 mode="multiple"
                                                                 style={{ width: '100%' }}
                                                                 placeholder="Se han seleccionado todas las familias"
                                                                 defaultValue={this.state.todasFamiliasNombre}
                                                                 disabled={true}
                                                            />
                                                       )}
                                                  </Form.Item>
                                             </Col>
                                             ): false } 

                                             { !this.state.checkedServicio || this.state.serviciosSeleccionadosLocal[0] ? (
                                             <Col span={2}>
                                                  <Form.Item
                                                       label=' '
                                                       name='todasFamilias'
                                                  >
                                                       <Switch
                                                            checkedChildren={'TODAS'}
                                                            unCheckedChildren={'TODAS'}
                                                            defaultChecked={false}
                                                            checked={!this.state.checkedFamilia}
                                                            disabled={!listaFamiliasbyServicio[0]}
                                                            size={'default'}
                                                            onChange={todasFamilias}
                                                       />
                                                  </Form.Item>
                                             </Col>
                                             ): false }

                                             { !this.state.checkedFamilia || this.state.familiasSeleccionadasLocal[0] ? (
                                             <Col span={22}>
                                                  <Form.Item
                                                  label='Subfamilias'
                                                  name='subfamilia'
                                                  rules={[{ required: this.state.checkedSubfamilia, message: 'Favor de llenar el campo', }]}
                                                  >
                                                       { this.state.checkedSubfamilia ? (
                                                            <Select
                                                                 mode="multiple"
                                                                 style={{ width: '100%' }}
                                                                 placeholder="Selecciona una o varias subfamilias"
                                                                 loading={fetchingListaSubfamiliasbyFamilia}
                                                                 onChange={onSubfamilias}
                                                            >
                                                                 {listaSubfamiliasbyFamilia.map(option => <Option key={option.idSubFamilia}>{option.subFamilia}</Option>)}
                                                            </Select>
                                                       ):(
                                                            <Select
                                                                 mode="multiple"
                                                                 style={{ width: '100%' }}
                                                                 placeholder="Se han seleccionado todas las subfamilias"
                                                                 defaultValue={this.state.todasSubfamiliasNombre}
                                                                 disabled={true}
                                                            />
                                                       )}
                                                  </Form.Item>
                                             </Col>
                                             ): false }

                                             { !this.state.checkedFamilia || this.state.familiasSeleccionadasLocal[0] ? (
                                             <Col span={2}>
                                                  <Form.Item
                                                       label=' '
                                                       name='todasSubfamilias'
                                                  >
                                                       <Switch
                                                            checkedChildren={'TODAS'}
                                                            unCheckedChildren={'TODAS'}
                                                            defaultChecked={false}
                                                            checked={!this.state.checkedSubfamilia}
                                                            disabled={!listaSubfamiliasbyFamilia[0]}
                                                            size={'default'}
                                                            onChange={todasSubfamilias}
                                                       />
                                                  </Form.Item>
                                             </Col>
                                             ): false }
                                        </Row>

                                        <Row justify={"space-around"} gutter={[8,8]}>
                                             <Col span={8}>
                                                  <Form.Item
                                                  label='Rango de Costo Menor'
                                                  name='rangoMenor'
                                                  rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                                  >
                                                       
                                                       <InputNumber
                                                            className='input'
                                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                            onChange={changeDecimalesMenor}
                                                            min={0}
                                                       />
                                                  </Form.Item>
                                             </Col>

                                             <Col span={8}>
                                                  <Form.Item
                                                  label='Rango de Costo Mayor'
                                                  name='rangoMayor'
                                                  rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                                  >
                                                       
                                                       <InputNumber
                                                            className='input'
                                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                            onChange={changeDecimalesMayor}
                                                            min={0}
                                                       />
                                                  </Form.Item>
                                             </Col>

                                             <Col span={8}>
                                                  <Form.Item
                                                  label='Porcentaje de Uso'
                                                  name='porcentaje'
                                                  rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                                  >
                                                       
                                                       <InputNumber
                                                            min={0}
                                                            max={100}
                                                            formatter={value => `${value}%`}
                                                            parser={value => value.replace('%', '')}
                                                            className='input'
                                                       />
                                                  </Form.Item>
                                             </Col>
                                        </Row>

                                        {/* <Row justify={"space-around"}>
                                             <Col span={23}>
                                                  <Button
                                                       className="btnFiltrar" 
                                                       style={{ width: '100%' }}
                                                       // onClick={onGuardarCiclico} 
                                                       htmlType="submit"
                                                       loading={fetchingGenerarFolioCaptura}
                                                  >
                                                       Guardar
                                                  </Button>
                                             </Col>
                                        </Row> */}

                                        <Row justify='end' gutter={[8,8]}>
                                             <Col span={1} style={{paddingTop:'3em'}}>
                                                  <Tooltip title='Guardar'>
                                                       <Button loading={fetchingGenerarFolioCaptura} shape="circle" icon={<SaveFilled />} className="iconTer" htmlType='submit' />
                                                  </Tooltip>
                                             </Col>  
                                        </Row>
                                   </Form>
                              </div> ) }
                         </Col>                
                    </Row>
               </Card>
          ): (
               <Row justify='center'>
                    <Col span={8} style={{paddingTop:'2em', paddingBottom:'2em'}}>
                    {/* <img src={imaSearch} /> */}
                         <ReactVivus
                              id="imaSearch"
                              option={{
                                   file: imaSearch,
                                   animTimingFunction: "EASE",
                                   type: "delayed",
                              }}
                         />
                    </Col>
               </Row>
          ) }
          </>);
     }
}

const mapStateToProps = state => {
     return {
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          fetchingListaAlmacenesActivos: state.AlmacenesReducer.fetchingListaAlmacenesActivos,
          listaAlmacenesActivos: state.AlmacenesReducer.listaAlmacenesActivos,
          listaServiciosbyAlmacen: state.AlmacenesReducer.listaServiciosbyAlmacen,
          fetchingListaServiciosbyAlmacen: state.AlmacenesReducer.fetchingListaServiciosbyAlmacen,
          fetchingListaFamiliasbyServicio: state.AlmacenesReducer.fetchingListaFamiliasbyServicio,
          listaFamiliasbyServicio: state.AlmacenesReducer.listaFamiliasbyServicio,
          fetchingListaSubfamiliasbyFamilia: state.AlmacenesReducer.fetchingListaSubfamiliasbyFamilia,
          listaSubfamiliasbyFamilia: state.AlmacenesReducer.listaSubfamiliasbyFamilia,
          fetchingListaPisosByAlmacen: state.AlmacenesReducer.fetchingListaPisosByAlmacen,
          listaPisosByAlmacen: state.AlmacenesReducer.listaPisosByAlmacen,
          fetchingListaAreasByPisos: state.AlmacenesReducer.fetchingListaAreasByPisos,
          listaAreasByPisos: state.AlmacenesReducer.listaAreasByPisos,
          fetchingListaPasillosByAreas: state.AlmacenesReducer.fetchingListaPasillosByAreas,
          listaPasillosByAreas: state.AlmacenesReducer.listaPasillosByAreas,
          fetchingListaRacksByPasillos: state.AlmacenesReducer.fetchingListaRacksByPasillos,
          listaRacksByPasillos: state.AlmacenesReducer.listaRacksByPasillos,
          folioInventario: state.AlmacenesReducer.folioInventario,
          fetchingGenerarFolioCaptura: state.AlmacenesReducer.fetchingGenerarFolioCaptura,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
          onShowModalIngresarFolio: (tipoInventario, loadingBoolean) => {
               dispatch({ type: 'SHOW_MODAL_INGRESAR_FOLIO', showModalIngresarFolio: true, tipoInventario, loadingBoolean });
          },
          onGetAlmacenesActivos: () => {
               dispatch({ type: 'ALMACENES_ACTIVOS_BUSQUEDA_REQUEST' })
          },
          onLimpiarAlmacenes: () => {
               dispatch({ type: 'ALMACENES_ACTIVOS_BUSQUEDA_SUCCESS', listaAlmacenesActivos: [] });
          },
          onGetServiciosAlmacen: (todosAlmacenes, almacenesSeleccionados, listaAlmacenesActivos, todosAlmacenesSeleccionados) => {
               dispatch({ type: 'LISTA_SERVICIOS_ALMACEN_REQUEST', todosAlmacenes, almacenesSeleccionados, listaAlmacenesActivos, todosAlmacenesSeleccionados })
          },
          onLimipiarServiciosAlmacen: () => {
               dispatch({ type: 'LISTA_SERVICIOS_ALMACEN_SUCCESS', listaServiciosbyAlmacen: []});
          },
          onGetFamiliasServicio: (todosServicios, serviciosSeleccionados, listaServiciosbyAlmacen, todosServiciosSeleccionados) => {
               dispatch({ type: 'LISTA_FAMILIAS_SERVICIO_REQUEST', todosServicios, serviciosSeleccionados, listaServiciosbyAlmacen, todosServiciosSeleccionados })
          },
          onLimpiarFamiliasServicio: () => {
               dispatch({ type: 'LISTA_FAMILIAS_SERVICIO_SUCCESS', listaFamiliasbyServicio: []});
          },
          onGetSubfamiliasFamilia: (todasFamilias, familiasSeleccionadas, listaFamiliasbyServicio, todasFamiliasSeleccionadas) => {
               dispatch({ type: 'LISTA_SUBFAMILIAS_FAMILIA_REQUEST', todasFamilias, familiasSeleccionadas, listaFamiliasbyServicio, todasFamiliasSeleccionadas })
          },
          onLimpiarSubfamiliasFamilia: () => {
               dispatch({ type: 'LISTA_SUBFAMILIAS_FAMILIA_SUCCESS', listaSubfamiliasbyFamilia: [] });
          },
          onGetPisosByAlmacenes: (idsAlmacenes) => {
               dispatch({ type:"LISTA_PISOS_BY_ALMACEN_REQUEST", idsAlmacenes: idsAlmacenes})
          },
          onLimpiarPisos: () => {
               dispatch({ type: 'LISTA_PISOS_BY_ALMACEN_SUCCESS', listaPisosByAlmacen: [] });
          },
          onGetAreasByPisos: (pisos) => {
               dispatch({ type:"LISTA_AREAS_BY_PISOS_REQUEST", pisos: pisos});
          },
          onLimpiarAreas: () => {
               dispatch({ type: 'LISTA_AREAS_BY_PISOS_SUCCESS', listaAreasByPisos: [] });
          },
          onGetPasillosByAreas: (areas) => {
               dispatch({ type:"LISTA_PASILLOS_BY_AREAS_REQUEST", areas: areas});
          },
          onLimpiarPasillos: () => {
               dispatch({ type:'LISTA_PASILLOS_BY_AREAS_SUCCESS', listaPasillosByAreas: [] });
          },
          onGetRacksByPasillos: (pasillos) => {
               dispatch({ type:"LISTA_RACKS_BY_PASILLOS_REQUEST", pasillos: pasillos});
          },
          onLimpiarRacks: () => {
               dispatch({ type:'LISTA_RACKS_BY_PASILLOS_SUCCESS', listaRacksByPasillos: [] });
          },
          onGetUbicacionesProductos: (page,nombreAlmacen) => {
               dispatch({ type: "UBICACIONES_PRODUCTOS_BUSQUEDA_REQUEST", page: page,nombreAlmacen:nombreAlmacen });
          },
          onShowModalInformacionFolio: () => {
               dispatch({ type: 'SHOW_MODAL_INFORMACION_FOLIO', showModalInformacionFolio: true });
          },
          onShowModalCapturaInventario: () => {
               dispatch({ type: 'SHOW_MODAL_CAPTURA_INVENTARIO', showModalCapturaInventario: true });
          },

          onGenerarFolio: (tipoSeleccionado, almacenesSeleccionados, subfamiliasSeleccionadas, almacenes, pisos, areas, pasillos, racks, rangoMayor, rangoMenor, porcentaje) => {
               dispatch({ type: 'GENERAR_FOLIO_CAPTURA_REQUEST', tipoSeleccionado, almacenesSeleccionados, subfamiliasSeleccionadas, almacenes, pisos, areas, pasillos, racks, rangoMayor, rangoMenor, porcentaje});
          },

     };
};

export default connect(mapStateToProps, mapDispatchToProps)(CapturaInventarioFisico);