import React from 'react';
import { Form, Spin, Row, Col, Table, Tooltip, Descriptions, Radio, Card, Collapse, Switch, Tag, Modal, Button, Select} from 'antd';
import { EyeOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { Pie } from 'react-chartjs-2';

const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
const { Panel } = Collapse;
const { Option } = Select;
class RelacionFallas extends React.Component {
     formRef = React.createRef();
     componentDidMount(){ 
          this.props.onGetReporteDinamicoAgrupado({field: 'etiqueta', order: 'ASC'}, 'Servicio', this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP);
          this.props.onGetReporteDinamicoAgrupadoByGrupo({field: 'etiqueta', order: 'ASC'}, 'Clasificacion', this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP);
          this.props.onGetListaReporteFallas(this.props.mesSeleccionadoRMDP, this.props.anioSeleccionadoRMDP, 1);
          this.props.onGetServiciosActivos();
          this.props.onGetGruposServicios();
     }

     state={ page: 1, titleFirstGraph: 'Fallas por Clasificación', idServicio: null, idGrupoSer: null, tipo:null }
 
     render() {
          const { dataSSAgrupamiento, mesSeleccionadoRMDP, anioSeleccionadoRMDP, mesTexto, onExportExcel, onExportPDF } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){ permisosUsuario = JSON.parse(permisosUsuario); }

          const onFinish = (formulario) => {
               console.log('Success:', formulario);
          };

          const handleTableChange = (pagination) => {
               this.setState({ page: pagination.current });
               this.props.onGetListaReporteFallas(mesSeleccionadoRMDP, anioSeleccionadoRMDP, pagination.current, this.state.idServicio, this.state.idGrupoSer, this.state.tipo);
          };

          const onChangeTag = () => {
               Modal.info({
                    content: <p style={{ fontSize: '14px' }}>Existen tickets en el mes: <b style={{color:'#0e2c82'}}>{mesTexto}</b> sin coinciliar, favor de verificar.</p>,
                    okText: 'Confirmar',
                    width: '30%',
               });
          };

          const columns = [
               { title: <Tooltip title='Número de ticket'>Folio</Tooltip>, dataIndex: 'folio', key: 'folio', align: 'center' },
               { title: <Tooltip title='Fecha y hora en la que se reportó la falla'>Fecha</Tooltip>, dataIndex: 'fechaAltaTabla', key: 'fechaAltaTabla', align: 'center' },
               { title: <Tooltip title='Código del área en la que ocurrió la falla'>Código área</Tooltip>, dataIndex: 'codigoArea', key: 'codigoArea', align: 'center' },
               { title: <Tooltip title='Nombre del área funcional en la que ocurrió la falla'>Área funcional</Tooltip>, dataIndex: 'areaFuncional', key: 'areaFuncional', align: 'center' },
               { title: <Tooltip title='Nombre de la unidad funcional en la que ocurrió la falla'>Unidad funcional</Tooltip>, dataIndex: 'unidadFuncional', key: 'unidadFuncional', align: 'center' },
               { title: <Tooltip title='Tipo de deductiva'>Tipo</Tooltip>, dataIndex: 'tipoDeductivaTabla', key: 'tipoDeductivaTabla', align: 'right' },
               { title: <Tooltip title='Monto de deducción'>Deducción</Tooltip>, dataIndex: 'deduccion', key: 'deduccion', align: 'right' },
               { title: <Tooltip title='Indica si la falla se atendió dentro del tiempo de rehabilitación o tiempo de respuesta, según la fecha y hora "conciliada"'>En tiempo</Tooltip>, align: 'center', dataIndex: 'aTiempo', key: 'aTiempo', render: (text) => ( <Switch checked={text} disabled={true}/> ) },
               { title: <Tooltip title='Último estatus del ticket'>Estatus</Tooltip>, dataIndex: 'estado', key: 'estado', align: 'center',
                    render : (text, record) => (
                         record.estado !== 'Conciliado' ? (<Tag className='tag-rad' color='red' onClick={onChangeTag}> <Tooltip title='Existen tickets para el mes i sin conciliar, verifique'> {record.estado} </Tooltip> </Tag>) : (<Tag className='tag-rad' >{record.estado}</Tag>)
                    )
               },
          ];

          const radioButton = (value) => {
               let valueRadio = value.target.value;
               this.props.onGetReporteDinamicoAgrupadoByGrupo({field: 'etiqueta', order: 'ASC'}, valueRadio, mesSeleccionadoRMDP, anioSeleccionadoRMDP);
               if( valueRadio === 'GrupoSer' ){
                    this.setState({ titleFirstGraph: 'Fallas por Grupo de Servicios' });
               } else {
                    this.setState({ titleFirstGraph: 'Fallas por Clasificación' });
               }
          };

          const genExtra = () => ( <EyeOutlined onClick={event => { event.stopPropagation(); }} /> );

          ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

          let etiquetasPieService = [];
          let informacionPieServicio = [];
          let etiquetasLargaPieServicio = [];
          let totalFallas = null;
               if(dataSSAgrupamiento){
                    for(let i in dataSSAgrupamiento){
                         etiquetasPieService.push(this.props.dataSSAgrupamiento[i].etiqueta);
                         informacionPieServicio.push(this.props.dataSSAgrupamiento[i].cantidad);
                         etiquetasLargaPieServicio.push(this.props.dataSSAgrupamiento[i].etiquetaLarga);
                         totalFallas += this.props.dataSSAgrupamiento[i].cantidad;
                    }
               }

          const dataDescription = [
               {label: 'Total de Fallas', data: totalFallas},
          ];
     
          const charDataPie = {
               labels: etiquetasPieService,
               datasets: [{  data: informacionPieServicio,  backgroundColor: ['#555e7b', '#b7d968', '#b576ad', '#e04644', '#7ccce5', '#c06c84', '#a7dbd8', '#ecf081', '#3b8183', '#ff9900', '#789048', '#e8caa4','#f07241', '#f07818', '#d4ee5e', '#594f4f', '#8fbe00', '#d68189', '#d9ceb2', '#031634', '#f4ebc3', '#3fb8af', '#547980', '#83af9b', '#00a0b0', '#7a6a53', '#b1c200',], borderColor: '#FFF',  borderWidth: 1 }],
          }

          const optionsDataPie = {
               pieceLabel: {
                    render: {
                         datalabels: {
                              formatter: (value, ctx) => { let datasets = ctx.chart.data.datasets;
                                   if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                                        let sum = 0;
                                        datasets.map(dataset => { sum += dataset.data[ctx.dataIndex]; });
                                        let percentage = Math.round((value / sum) * 100) + '%';
                                        return percentage;
                                   }
                              }
                         }
                    }, fontSize: 14, fontColor: '#fff'
               },
               tooltips: {
                    callbacks: {
                         title: function(tooltipItem, data) { return data['labels'][tooltipItem[0]['index']]; },
                         label: function(tooltipItem) { return 'Servicio: ' + etiquetasLargaPieServicio[tooltipItem['index']]; },
                         afterLabel: function(tooltipItem, data) {
                              let dataset = data.datasets[tooltipItem.datasetIndex];
                              let currentValue = dataset.data[tooltipItem.index];
                              let percentage = (((currentValue * 100) / totalFallas)).toFixed(2);
                              
                              return 'Fallas: ' +  data['datasets'][0]['data'][tooltipItem['index']] + ' | Porcentaje: ' + percentage + '%';
                         }
                    },
                    backgroundColor: '#FFF', titleFontSize: 14, titleFontColor: '#0e2c82', bodyFontColor: '#1747ca', bodyFontSize: 12, displayColors: false
               },
               legend: { position: "right", align: "center", labels: {boxWidth: 15},
                    onHover: function (event, legendItem) {
                         let chart = this.chart;
                         let index = legendItem.index;
                         let segment = chart.getDatasetMeta(0).data[index];
                         chart.tooltip._active = [segment]
                         chart.tooltip.update()
                         chart.draw()
                    },
                    onLeave: function (event, legendItem) {
                         let chart = this.chart;
                         let index = legendItem.index;
                         chart.tooltip._active = []
                         chart.tooltip.update()
                         chart.draw()
                    } 
               },
          };

          ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

          let etiquetasPieGrupo = [];
          let informacionPieGrupo = [];
          let etiquetasLargaPieGrupo = [];
               if(this.props.dataGraficaRelacionFallas){
                    for(let i in this.props.dataGraficaRelacionFallas){
                         etiquetasPieGrupo.push(this.props.dataGraficaRelacionFallas[i].etiqueta);
                         informacionPieGrupo.push(this.props.dataGraficaRelacionFallas[i].cantidad);
                         etiquetasLargaPieGrupo.push(this.props.dataGraficaRelacionFallas[i].etiquetaLarga);
                    }
               }

          const charDataPieGrupo = {
               labels: etiquetasPieGrupo,
               datasets: [{  data: informacionPieGrupo,  backgroundColor: [ '#a7dbd8', '#00a0b0', '#83af9b', '#3b8183', '#555e7b', '#031634', '#b576ad',  '#c06c84', '#d68189',  '#f04644', '#f07241', '#ff9900', '#f4ebc3', '#ecf081', '#d4ee5e',  '#b7d968', '#b1c200', '#8fbe00', '#789048', '#d9ceb2','#e8caa4', '#7a6a53', '#594f4f' ], borderColor: '#FFF',  borderWidth: 1 }],
          };

          const exportExcel = () => { onExportExcel(anioSeleccionadoRMDP, mesSeleccionadoRMDP, mesTexto); };
          const exportPDF = () => { onExportPDF(anioSeleccionadoRMDP, mesSeleccionadoRMDP, mesTexto); }

          const optionsDataPieGrupo = {
               pieceLabel: {
                    render: {
                         datalabels: {
                              formatter: (value, ctx) => { let datasets = ctx.chart.data.datasets;
                                   if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                                        let sum = 0;
                                        datasets.map(dataset => { sum += dataset.data[ctx.dataIndex]; });
                                        let percentage = Math.round((value / sum) * 100) + '%';
                                        return percentage;
                                   }
                              }
                         }
                    }, fontSize: 14, fontColor: '#fff'
               },
               tooltips: {
                    callbacks: {
                         title: function(tooltipItem, data) { return data['labels'][tooltipItem[0]['index']]; },
                         // label: function(tooltipItem) { return 'Descripción: ' + etiquetasLargaPieGrupo[tooltipItem['index']] },
                         label: function(tooltipItem, data) {
                              let dataset = data.datasets[tooltipItem.datasetIndex];
                              let currentValue = dataset.data[tooltipItem.index];
                              let percentage = (((currentValue * 100) / totalFallas)).toFixed(2);
                              
                              return 'Fallas: ' +  data['datasets'][0]['data'][tooltipItem['index']] + ' | Porcentaje: ' + percentage + '%';
                         }
                    },
                    backgroundColor: '#FFF', titleFontSize: 14, titleFontColor: '#0e2c82',  bodyFontColor: '#1747ca', bodyFontSize: 12,  displayColors: false
               },
               legend: { position: "left", align: "center", labels: {boxWidth: 15},
                    onHover: function (event, legendItem) {
                         let chart = this.chart;
                         let index = legendItem.index;
                         let segment = chart.getDatasetMeta(0).data[index];
                         chart.tooltip._active = [segment]
                         chart.tooltip.update()
                         chart.draw()
                    },
                    onLeave: function (event, legendItem) {
                         let chart = this.chart;
                         let index = legendItem.index;
                         chart.tooltip._active = []
                         chart.tooltip.update()
                         chart.draw()
                    },
               },
               
          };

          const onSelectClasficacion = (e) => {
               if(e){
                    let clas = e; if(clas==='SS'){clas=null}
                    this.setState({tipo: e});
                    this.props.onGetListaReporteFallas(mesSeleccionadoRMDP, anioSeleccionadoRMDP, this.state.page, this.state.idServicio, this.state.idGrupoSer, clas);
               } else {
                    this.setState({tipo: null});
                    this.props.onGetListaReporteFallas(mesSeleccionadoRMDP, anioSeleccionadoRMDP, this.state.page, this.state.idServicio, this.state.idGrupoSer, null);
               }
          };

          const onSelectService = (e) => {
               if(e){
                    let id = e.split(' - ')[0];
                    this.setState({idServicio: id});
                    this.props.onGetListaReporteFallas(mesSeleccionadoRMDP, anioSeleccionadoRMDP, this.state.page, id, this.state.idGrupoSer, this.state.tipo);
               } else {
                    this.setState({idServicio: null});
                    this.props.onGetListaReporteFallas(mesSeleccionadoRMDP, anioSeleccionadoRMDP, this.state.page, null, this.state.idGrupoSer, this.state.tipo);
               }
          };

          const grupoServicioSelected = (e) => {
               if(e){
                    let id = e.split(' - ')[0];
                    this.setState({idGrupoSer: id});
                    this.props.onGetListaReporteFallas(mesSeleccionadoRMDP, anioSeleccionadoRMDP, this.state.page, this.state.idServicio, id, this.state.tipo);
               } else {
                    this.setState({idGrupoSer: null});
                    this.props.onGetListaReporteFallas(mesSeleccionadoRMDP, anioSeleccionadoRMDP, this.state.page, this.state.idServicio, null, this.state.tipo);
               }
          };

          const titleTable = () => {
               return (
                    <>
                    <Row justify='space-around' align='middle' gutter={[0,0]}>
                         <Col span={22}>
                              <div style={{textAlign:'center'}}>
                                   <h4>RELACIÓN DE LAS FALLAS EN LA PRESTACIÓN DEL SERVICIO PARA EL MES <b>{ mesTexto.toUpperCase() }  { anioSeleccionadoRMDP } </b> </h4>
                              </div>
                         </Col>

                         <Col span={1}>
                              <Tooltip title='Exportar reporte de fallas .pdf'> <Button disabled={this.props.listaReporteFallas.length === 0 ? true: false} loading={this.props.fetchingPDFReporteFallas} className='iconSecundary' shape='circle' icon={<FilePdfOutlined />} onClick={exportPDF} /> </Tooltip>
                         </Col>

                         <Col span={1}>
                              <Tooltip title='Exportar reporte de fallas .xls'> <Button disabled={this.props.listaReporteFallas.length === 0 ? true: false} loading={this.props.fetchingExcelReporteFallas} className='iconSecundary' shape='circle' icon={<FileExcelOutlined />} onClick={exportExcel} /> </Tooltip>
                         </Col>
                    </Row>
                    <Row justify='space-around' align='middle' gutter={[4,8]} style={{paddingTop:'1em'}}>
                         <Col span={12}>
                              <Select
                                   style={{width: '100%'}}
                                   placeholder="Selecciona un servicio a filtrar"
                                   loading={this.props.fetchingListaServiciosActivosF}
                                   onChange={onSelectService}
                                   showSearch allowClear
                              >
                                   {this.props.listaServiciosActivosF.map(option => 
                                        <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                             <Tooltip placement="topLeft" title={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                  { option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre }
                                             </Tooltip>
                                        </Option>)}
                              </Select>
                         </Col>

                         <Col span={12}>
                              <Select 
                                   allowClear  style={{ width: '100%' }} placeholder="Selecciona un grupo de servicios a filtrar" loading={this.props.fetchingListaGruposServicios} onChange={grupoServicioSelected} showSearch
                              >
                                   {this.props.listaGruposServicios.map(option => <Option key={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}><Tooltip title={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}>{option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}</Tooltip></Option>)}
                              </Select>
                         </Col>

                         {/* <Col span={8}>
                              <Select
                                   style={{width: '100%'}}
                                   placeholder="Selecciona una clasificación a filtrar"
                                   onChange={onSelectClasficacion}
                                   showSearch allowClear
                              >
                                   <Option key='ss'>SS</Option>
                                   <Option key='SNP'>SNP</Option>
                                   <Option key='queja'>Queja</Option>
                              </Select>
                         </Col> */}
                    </Row>
                    </>
               );
          };

          return (<>
               <Form {...layout} name="formulario" ref={this.formRef} onFinish={onFinish}>
                    <Row justify='space-around' gutter={[8,8]}>
                         <Col span={12}>
                              <Card className="cardCatalogos" bordered={false} title={<p style={{fontSize: 14, color: '#0e2c82'}}>{this.state.titleFirstGraph}</p>} style={{backgroundColor:'#F2F2F2'}}>
                                   <Spin spinning={this.props.fetchingDataGraficaRelacionFallas}>
                                        <Pie data={charDataPieGrupo} options={optionsDataPieGrupo} />
                                   </Spin>
                              </Card>
                         </Col>

                         <Col span={12}>
                              <Card className="cardCatalogos" bordered={false} title={<p style={{fontSize: 14, color: '#1747ca'}}>Fallas por Servicio</p>} style={{backgroundColor:'#F2F2F2'}}>
                                   <Spin spinning={this.props.fetchingDataSSAgrupamiento}>
                                        <Pie data={charDataPie} options={optionsDataPie} />
                                   </Spin>
                              </Card>
                         </Col>
                    </Row>
                    <Row justify='space-between'>
                         <Col span={6}>
                         <br />
                              <Radio.Group style={{ width: "100%"}} defaultValue="Clasificacion" onChange={radioButton} size='small'>
                                   <Radio value="GrupoSer" style={{ width: "100%"}}>Grupo de Servicios</Radio>
                                   <Radio value="Clasificacion" style={{ width: "100%"}}>Clasificación</Radio>
                              </Radio.Group>
                         </Col>
                         <Col span={6}>
                         <br />
                              <Descriptions layout="horizontal" bordered= {true} >
                                   {dataDescription.map(item => <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                   )}
                              </Descriptions>
                         </Col>
                    </Row>
                    <Row justify='space-around'>

                         <Col span={24}><br />
                              <Collapse expandIconPosition='right' className='collapseDetalleRDO'>
                                   <Panel header="Ver Reporte de Fallas" key="1" showArrow={true}>
                                        <Table
                                             title={titleTable}
                                             size="small"
                                             rowKey="idSolicitudServicio"
                                             columns={columns}
                                             dataSource={this.props.listaReporteFallas}
                                             loading={this.props.fetchingListaReporteFallas}
                                             onChange={handleTableChange}
                                             pagination={{ total: this.props.listaReporteFallasCantidad, simple: true, current: this.state.page}}
                                        />
                                        {/* <Row justify='end'>
                                             <Tooltip title='Exportar reporte de fallas .xls'> <Button disabled={this.props.listaReporteFallas.length === 0 ? true: false} loading={this.props.fetchingExcelReporteFallas} className='iconSecundary' shape='circle' icon={<FileExcelOutlined />} onClick={exportExcel} /> </Tooltip> <Col span={1}/>
                                             <Tooltip title='Exportar reporte de fallas .pdf'> <Button disabled={this.props.listaReporteFallas.length === 0 ? true: false} loading={this.props.fetchingPDFReporteFallas} className='iconSecundary' shape='circle' icon={<FilePdfOutlined />} onClick={exportPDF} /> </Tooltip>
                                        </Row> */}
                                   </Panel>
                              </Collapse>
                         </Col>
                    </Row>
               </Form>
          </>);
     }
}
 
 const mapStateToProps = state => {
     return {
          dataSSAgrupamiento: state.RMDPReducer.dataSSAgrupamiento,
          fetchingDataSSAgrupamiento: state.RMDPReducer.fetchingDataSSAgrupamiento,
          dataSSAgrupamientoCantidad: state.RMDPReducer.dataSSAgrupamientoCantidad,
          mesSeleccionadoRMDP: state.RMDPReducer.mesSeleccionadoRMDP,
          fetchingDataSSAgrupamientoByGrupo: state.RMDPReducer.fetchingDataSSAgrupamientoByGrupo,
          dataSSAgrupamientoByGrupo: state.RMDPReducer.dataSSAgrupamientoByGrupo,
          listaReporteFallas: state.RMDPReducer.listaReporteFallas,
          listaReporteFallasCantidad: state.RMDPReducer.listaReporteFallasCantidad,
          fetchingListaReporteFallas: state.RMDPReducer.fetchingListaReporteFallas,
          anioSeleccionadoRMDP: state.RMDPReducer.anioSeleccionadoRMDP,
          mesTexto: state.RMDPReducer.mesTexto,
          fetchingExcelReporteFallas: state.RMDPReducer.fetchingExcelReporteFallas,
          fetchingPDFReporteFallas: state.RMDPReducer.fetchingPDFReporteFallas,

          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
          fetchingListaGruposServicios: state.CatalogosGeneralesReducer.fetchingListaGruposServicios,
          listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,

          dataGraficaRelacionFallas: state.RMDPReducer.dataGraficaRelacionFallas,
          dataGraficaRelacionFallasCantidad: state.RMDPReducer.dataGraficaRelacionFallasCantidad,
          fetchingDataGraficaRelacionFallas: state.RMDPReducer.fetchingDataGraficaRelacionFallas,
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          onGetReporteDinamicoAgrupado: (order, agrupamiento, idMes, anio) => {
               dispatch({ type: 'DATA_SS_AGRUPAMIENTO_REQUEST', order, agrupamiento, idMes, anio });
          },
          onGetReporteDinamicoAgrupadoByGrupo: (order, agrupamiento, idMes, anio) => {
               dispatch({ type: 'DATA_GRAFICA_RELACION_FALLAS_REQUEST', order, agrupamiento, idMes, anio });
          },
          onGetListaReporteFallas: (idMes, anio, page, idServicio, idGrupoSer, tipo) => {
               dispatch({ type: 'LISTA_REPORTE_FALLAS_REQUEST', idMes, anio, page, idServicio, idGrupoSer, tipo });
          },
          onExportExcel: (anio, idMes, mes) => {
               dispatch({ type: 'EXCEL_REPORTE_FALLAS_REQUEST', anio, idMes, mes });
          },
          onExportPDF: (anio, idMes, mes) => {
               dispatch({ type: 'PDF_REPORTE_FALLAS_REQUEST', anio, idMes, mes });
          },
          onGetServiciosActivos: () => {
               dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"});
          },
          onGetGruposServicios: () => {
               dispatch({ type: "LISTA_GRUPOS_SERVICIOS_REQUEST" });
          },
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(RelacionFallas);