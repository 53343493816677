import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Select, Tooltip } from 'antd';
import { EditOutlined, FileSearchOutlined, FileExcelOutlined, PlusOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoProducto from './ModalNuevoProducto';
import ModalEditarProducto from './ModalEditarProducto';
import ModalDetalleProducto from './ModalDetalleProducto';
import ModalCargaMasiva from './ModalCargaMasiva';

const confirm = Modal.confirm;
const { Search } = Input;
const { Option } = Select;

class CatalogoDeProductos extends React.Component {
    state = {
        page: 1,
        idSubFamilia: undefined,
        order: 'ASC',
        field: 'nombre',
        busqueda: '',
    }

    componentDidMount() {
        this.props.onGetProductos(1);
        this.props.onGetSubfamilias();
    }

    render() {
        const { fetchingListaProductosBusqueda, listaProductosBusqueda,listaProductosBusquedaCantidad, onGetProductos,
            fetchingListaSubfamiliasProductos, listaSubfamiliasProductosActivos,
            onModalNuevoProducto, onModalEditarProducto, onEstadoProducto, onShowModalDetalleProducto } = this.props;
                
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const columns = [{
            title: 'Código de almacén',
            dataIndex: 'codigoAdicional',
            key: 'codigoAdicional',
        },{
            title: 'Clave del PIH',
            dataIndex: 'clave',
            key: 'clave',
            sorter: true
        }, {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
        },{
            title: 'Nombre del PIH',
            dataIndex: 'nombre',
            key: 'nombre',
            sorter: true
        },{
            title: 'Descripción',
            dataIndex: 'descripcion',
            key: 'descripcion',
            width: '35%',
        }, {
            title: 'Presentación',
            dataIndex: 'presentacion',
            key: 'presentacion',
        },{
            title: 'Tipo moneda',
            dataIndex: 'tipoMoneda',
            key: 'tipoMoneda',
        },{
            title: 'Costo unitario',
            dataIndex: 'precioTablaGridProductos',
            key: 'precioTablaGridProductos',
            align: 'right',
        },{
            title: 'Consideraciones',
            dataIndex: 'consideraciones',
            key: 'consideraciones',
        },{
            title: 'Detalle',
            key: 'detalle',
            width: '5%',
            align: 'center',
            render: (text, record) => (
                <a className='editarIcono'  onClick={() => onDetalle(record)} ><FileSearchOutlined /></a>
            ),
        },{
            title: 'Activo',
            dataIndex: 'activo',
            key: 'activo',
            width: '5%',
            align: 'center',
            render: (text, record) => (
                <Switch checked={text} disabled={!permisosUsuario.pihActivoInactivo} onChange = {() => handleDesactivar(record)} />
            ),
        }, {
            title: 'Editar',
            key: 'editar',
            width: '5%',
            align: 'center',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.editarPih}  onClick={() => onEditar(record)}><EditOutlined /></a>
            ),
        },
        ];

        const onDetalle = (key) => {
            onShowModalDetalleProducto(key);
        }

        const onBuscarProducto = (busqueda, name) => {
            this.setState({
                [name]: busqueda,
                page: 1,
            }, ()=> onGetProductos(1, this.state.busqueda, this.state.field, this.state.order, this.state.idSubFamilia));
        } 

        const handleDesactivar = (key) => {
            console.log(key.idProducto);
            let page = this.state.page
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del producto/inusmo/herramienta "' + key.nombre + '"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoProducto(key.idProducto, page);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }

        const onEditar = (key) => {
            console.log(key)
            onModalEditarProducto(key, this.state.page, this.state.busqueda);

        }

        const onNuevoProducto = () => {
            onModalNuevoProducto(this.state.page);
        }

        const handleTableChange = (pagination, filtro, orden) => {
            this.setState({
                page: pagination.current,
            });
            if (orden.columnKey) {

                let campo = 'nombre';
                let order = 'ASC';

                if (orden.order === 'descend') {
                    order = 'DESC';
                }
                if (orden.columnKey === 'clave') {
                    campo = 'clave';
                }
                this.setState ({
                    order: order, 
                    field: campo,
                },() => onGetProductos(pagination.current, this.state.busqueda, this.state.field, this.state.order, this.state.idSubFamilia));
            } else {
                onGetProductos(pagination.current, this.state.busqueda, this.state.field, this.state.order, this.state.idSubFamilia);

            }
        }
        
        const scroll = {"x": 2700 | true, "y": 1000};

        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={10}>
                        <Select allowClear
                            style={{ width: '100%' }}
                            placeholder="Seleccione la subfamilia"
                            loading={fetchingListaSubfamiliasProductos}
                            onChange={(value) => onBuscarProducto(value, "idSubFamilia")}
                        >
                            {listaSubfamiliasProductosActivos.map(option => <Option key={option.idSubFamilia}>{option.subFamilia}</Option>)}
                        </Select>
                    </Col>
                    <Col span={10}>
                        <Search className='buscarModulos' placeholder='Ingresa nombre/clave/SKU/código de almacén' onSearch={(value) => onBuscarProducto(value, "busqueda")} />
                    </Col>
                    {/* <Col span={5}>
                        <Button disabled={!permisosUsuario.exportarExcelCatálogoPih} onClick={onNuevoProducto} disabled={!permisosUsuario.crearPih} className='nuevoUsuario'>Nuevo PIH</Button>
                    </Col> */}

                    <Col span={1}>
                        <Tooltip title='Carga Masiva' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={() => this.props.onModalCargaMasiva(this.state.page) } disabled={!permisosUsuario.crearPih} icon={<AppstoreAddOutlined />}/>
                        </Tooltip>
                    </Col>

                    <Col span={1}>
                        <Tooltip title='Nuevo PIH' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoProducto} disabled={!permisosUsuario.crearPih} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>

                    <Col span={1}>
                        <Tooltip title='Exportar archivo .xls' placement="topRight">
                            <Button className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } loading={this.props.fetchingExportarExcelProductos} onClick={() => this.props.onExport(this.state.order, this.state.field, this.state.idSubFamilia)}/>
                        </Tooltip>
                    </Col>
                    
                    <Col span={24}>
                        <br />
                        <Table
                            size="small"
                            rowKey="codigoAdicional"
                            columns={columns}
                            dataSource={listaProductosBusqueda}
                            loading={fetchingListaProductosBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaProductosBusquedaCantidad, simple: true, current: this.state.page }}
                            scroll={scroll}
                        />
                    </Col>
                </Row>
                <ModalNuevoProducto/>
                <ModalEditarProducto/>
                <ModalDetalleProducto />
                <ModalCargaMasiva />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        listaProductosBusqueda: state.AlmacenesReducer.listaProductosBusqueda,
        fetchingListaProductosBusqueda: state.AlmacenesReducer.fetchingListaProductosBusqueda,
        listaProductosBusquedaCantidad: state.AlmacenesReducer.listaProductosBusquedaCantidad,
        fetchingListaSubfamiliasProductos: state.CatalogosGeneralesReducer.fetchingListaSubfamiliasProductos,
        listaSubfamiliasProductosActivos: state.CatalogosGeneralesReducer.listaSubfamiliasProductosActivos,
        fetchingExportarExcelProductos: state.AlmacenesReducer.fetchingExportarExcelProductos,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetProductos: (page, busqueda, campo, orden, idSubFamilia) => {
            dispatch({ type: "PRODUCTOS_BUSQUEDA_REQUEST", page: page, busqueda: busqueda, campo:campo, orden:orden, idSubFamilia:idSubFamilia });
        },
        onEstadoProducto: (idProducto, page) => {
            dispatch({ type: "ESTADO_PRODUCTO_REQUEST", idProducto: idProducto, page: page});
        },
        onModalNuevoProducto: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_PRODUCTO", showModalNuevoProducto: true, page: page })
        },
        onModalEditarProducto: (productoSeleccionado, page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_PRODUCTO", showModalEditarProducto: true, productoSeleccionado, page, busqueda});
        },
        onShowModalDetalleProducto: (productoSeleccionado) => {
            dispatch({ type: 'SHOW_MODAL_DETALLE_PRODUCTO', showModalDetalleProducto: true, productoSeleccionado: productoSeleccionado });
        },
        onGetSubfamilias: () => {
            dispatch({ type: 'LISTA_SUBFAMILIAS_PRODUCTOS_REQUEST' });
        },
        onExport: (order, field, idSubFamilia) => {
            dispatch({ type: "EXPORTAR_PRODUCTOS_EXCEL_REQUEST", order, field, idSubFamilia });
        },
        onModalCargaMasiva: (page) => {
            dispatch({ type: "SHOW_MODAL_CARGA_MASIVA_PIH", showModalCargaMasivaPIH: true, resetValues: false, page: page })
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoDeProductos);