import React from 'react';
import { Row, Col, Modal, List, Table } from 'antd';
import { connect } from "react-redux";
import {  } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };

class ModalDetalleActividad extends React.Component {
     formRef = React.createRef();

     state = { page: 1 }


     render() {
          const { onCloseModalDetalle, showModalDetalleActividadRMP, programaRMPSeleccionado, } = this.props;

          const handleCancel = () => {onCloseModalDetalle();};

          let data=[];
          if (programaRMPSeleccionado){
               const {actividades, epp, herramientas, personal, insumosByIdRutina} = programaRMPSeleccionado;
               const herramientasByIdRutina = programaRMPSeleccionado.herramientasByIdRutina ? programaRMPSeleccionado.herramientasByIdRutina : []
               
               data = [
                    {title: "Actividades", data: actividades},
                    {title: "EPP", data: epp},
                    {title: "Equipo/Herramienta", data: herramientas},
                    {title: "Personal", data: personal},
                    {title: 'Insumos', data: insumosByIdRutina},
                    {title: 'Herramientas', data: herramientasByIdRutina},
               ];
          }

          const columns = [
               {title: 'Descripcion',dataIndex: 'descripcion',key: 'descripcion',align: 'center', width: '60%',},
               {title: 'Cantidad',dataIndex: 'cantidad',key: 'cantidad',align: 'center', width: '15%',},
               {title: 'Unidad de Medida',dataIndex: 'unidadMedida',key: 'unidadMedida',align: 'center', width: '25%',}
          ];

          return (
               <Modal
                    visible={showModalDetalleActividadRMP}
                    title={'Detalle de Actividad'}
                    onCancel={handleCancel}
                    footer={false}
                    width='60%'
               >
                    { programaRMPSeleccionado ? (
                         <Row justify='space-around'>
                              <Col span={24}>
                                   <List
                                   className="listaCatalogos"
                                   bordered={true}
                                   dataSource={data}
                                   renderItem={item => (
                                       <List.Item className='listaHover'>
                                             <Col span={5}>
                                                  <strong>{item.title}</strong>
                                             </Col>
                                             <Col span={18}>
                                                  {item.title !== 'Insumos' ?  
                                                       <>
                                                       {item.title !== 'Herramientas' ? item.data : (
                                                            <Table
                                                                 size='small'
                                                                 rowKey='descripcion'
                                                                 columns={columns}
                                                                 dataSource={item.data}
                                                                 locale={{ emptyText: 'Sin datos' }}
                                                                 pagination={{
                                                                      total: item.data ? item.data.length : 0,
                                                                      pageSize: item.data ? item.data.length : 0,
                                                                      hideOnSinglePage: true
                                                                 }}
                                                                 scroll={{ y: 450 }} 
                                                            /> 
                                                       )}
                                                       </>  : (
                                                            <Table
                                                                 size='small'
                                                                 rowKey='descripcion'
                                                                 columns={columns}
                                                                 dataSource={item.data}
                                                                 locale={{ emptyText: 'Sin datos' }}
                                                                 pagination={{
                                                                      total: item.data ? item.data.length : 0,
                                                                      pageSize: item.data ? item.data.length : 0,
                                                                      hideOnSinglePage: true
                                                                 }}
                                                                 scroll={{ y: 450 }}
                                                            />
                                                       )}
                                             </Col>
                                        </List.Item>
                                   )}
                                   />
                              </Col>
                         </Row>
                    ) : false }                
               </Modal>
          );             
     }
}

const mapStateToProps = state => {
     return {
          showModalDetalleActividadRMP: state.ServiciosPlanificadosReducer.showModalDetalleActividadRMP,
          programaRMPSeleccionado: state.ServiciosPlanificadosReducer.programaRMPSeleccionado,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onCloseModalDetalle: () => {
               dispatch({ type: 'SHOW_MODAL_DETALLE_ACTIVIDAD_RMP', showModalDetalleActividadRMP: false });
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetalleActividad);