import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoSubfamilias from './ModalNuevoSubfamilias';
import ModalEditarSubfamilias from './ModalEditarSubfamilias';

const { Search } = Input;
const confirm = Modal.confirm;
class CatalogoDeSubfamilias extends React.Component {
    componentDidMount(){
        this.props.onGetSubfamilias(1, '');
        this.props.onGetCentroDeCostos();
        this.props.onGetFamiliasDeActivos();
        this.props.onGetServiciosActivos();
    }
    state={
        page: 1,
        busqueda: '',
    }
    render() {
        const { fetchingListaSubfamiliasBusqueda, listaSubfamiliasBusqueda, onEstadoSubfamilias, onGetSubfamilias, onModalEditarSubfamilias, onModalNuevoSubfamilias, listaSubfamiliasBusquedaCantidad
        } = this.props;
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        const onBuscarSubfamilias = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            onGetSubfamilias(1, busqueda);
        }
        
        const columns = [{
                title: 'Subfamilia',
                dataIndex: 'subFamilia',
                key: 'subFamilia',
            },{
               title: 'Familia',
               dataIndex: 'familia',
               key: 'familia',
           },{
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.subfamiliaActivoInactivo} onChange={() => handleDesactivar(record)}/>
                ),
            },{
                title: 'Editar',
                key: 'editar',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarSubfamilia} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },
        ];
        const onEditar = (key) => {
            onModalEditarSubfamilias(key, this.state.page);
        }
        const handleDesactivar = (key) => {
            console.log(key);
            const page = this.state.page;
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado de la subfamilia "'+ key.subFamilia +'"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoSubfamilias(key.idSubFamilia, page)
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onGetSubfamilias(pagination.current, this.state.busqueda);
		}
        const onNuevaSubfamilia = () => {
            onModalNuevoSubfamilias(this.state.page);
        }

        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search className='buscarModulos' placeholder="Ingresa el nombre de la subfamilia" onSearch={value => onBuscarSubfamilias(value)} style={{ width: '100%' }}/>
                    </Col>
                    {/* <Col span={7}>
                        <Button onClick={onNuevaSubfamilia} disabled={!permisosUsuario.crearSubfamilia} className='nuevoUsuario'>Nueva Subfamilia</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nueva Subfamilia' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevaSubfamilia} disabled={!permisosUsuario.crearSubfamilia} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="subFamilia" 
                            columns={columns}
                            dataSource={listaSubfamiliasBusqueda}
                            loading={fetchingListaSubfamiliasBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaSubfamiliasBusquedaCantidad, simple: true, current: this.state.page}}
                        />         
                    </Col>
                </Row>
                <ModalNuevoSubfamilias/>
                <ModalEditarSubfamilias/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaSubfamiliasBusqueda: state.CatalogosGeneralesReducer.listaSubfamiliasBusqueda,
        fetchingListaSubfamiliasBusqueda: state.CatalogosGeneralesReducer.fetchingListaSubfamiliasBusqueda,
        listaCentroDeCostosActivos: state.CatalogosGeneralesReducer.listaCentroDeCostosActivos,
        fetchingListaCentroDeCostosActivos: state.CatalogosGeneralesReducer.fetchingListaCentroDeCostosActivos,
        listaSubfamiliasBusquedaCantidad: state.CatalogosGeneralesReducer.listaSubfamiliasBusquedaCantidad,
        listaFamiliasDeActivos: state.CatalogosGeneralesReducer.listaFamiliasDeActivos,
        fetchingListaFamiliasDeActivos: state.CatalogosGeneralesReducer.fetchingListaFamiliasDeActivos,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetSubfamilias: (page, busqueda) => {
	    	dispatch({ type: "SUBFAMILIAS_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
        },
        onEstadoSubfamilias: (idSubFamilia, page) => {
	    	dispatch({ type: "ESTADO_SUBFAMILIAS_REQUEST", idSubFamilia: idSubFamilia, page: page });
        },
        onModalNuevoSubfamilias: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_SUBFAMILIAS", showModalNuevoSubfamilias: true, page: page});
        },
        onModalEditarSubfamilias: (SubfamiliasSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_SUBFAMILIAS", showModalEditarSubfamilias: true, SubfamiliasSeleccionado: SubfamiliasSeleccionado, page: page});
        },
        onGetCentroDeCostos: () => {
            dispatch({ type: "LISTA_CENTRO_COSTOS_ACTIVOS_REQUEST"});
        },
        onGetFamiliasDeActivos: () => {
            dispatch({ type: "LISTA_FAMILIAS_ACTIVOS_REQUEST"});
        },
        onGetServiciosActivos: () => {
            dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST" });
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoDeSubfamilias);