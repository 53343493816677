import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, Select, Tooltip, Row, DatePicker } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import moment from 'moment';

const {Option} = Select;
const layout = { labelCol: { span: 24 },  wrapperCol: { span: 24 } };

class NuevoModuloBitacora extends Component {
     formRef = React.createRef();
     state = { servicioSeleccionado: true }
     render() {
          const { limpiarFormulario, showModalNuevoModuloBitacora, fetchingNuevoModuloBitacora, listaInfoProyecto } = this.props;

          const onFinish = formulario => {
               console.log('Success:', formulario);
               this.props.onNuevoModuloBitacora(formulario, this.props.page, this.props.busqueda);
          };

          let userInfo = sessionStorage.getItem('userInfo');
          if(userInfo[0]) {
               userInfo = JSON.parse(userInfo);
          }
          
          const listaServiciosUsuario = userInfo[0].serviciosByIdUsuario ? userInfo[0].serviciosByIdUsuario : [];

          const handleCancel = () => {
               this.props.onShowModalNuevoTipoBitacora(this.props.page, this.props.busqueda);
               this.formRef.current.resetFields();
          }

          if(limpiarFormulario && limpiarFormulario === 'nuevoModuloBitacora'){
               this.formRef.current.resetFields();
               this.props.onLimpiarFormulario();
          };

          const  disabledDate = (fecha) => {
               if( fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicioOperacion) {
                   return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicioOperacion;
               } else {
                   if(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos){
                return fecha && moment(fecha.format('YYYY-MM-DD')) > moment((listaInfoProyecto.vigencia +  parseInt(listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[2])) + '-' +listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[1] + '-' + listaInfoProyecto.fechaInicioOperacionDetalleMecanismos.split('-')[0]);
            }
               }
          };

          const servicioSeleccionado = (value) => {
               console.log('value', value)
               if(value){
                    this.props.onGetPartidasByIdServicio(value.split(' - ')[0]);
                    this.setState({ servicioSeleccionado: false });
               } else {
                    this.setState({ servicioSeleccionado: true });
                    this.formRef.current.setFieldsValue({'idPartida': undefined});
               }
          };

          return (
               <Modal
               visible={showModalNuevoModuloBitacora}
               title='Nueva Bitácora por Módulo'
               onCancel={handleCancel}
               footer={false}
               width='70%'
               >
                    <Spin spinning={fetchingNuevoModuloBitacora}>
                         <Form {...layout}
                         name="formulario"
                         onFinish={onFinish}
                         ref={this.formRef}
                         >
                         <Row justify='space-around'>
                              <Col span={11}>
                                   <Form.Item
                                        label="Nombre"
                                        name="nombre"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                   >
                                        <Input maxLength={50} placeholder='Escribe el nombre para el módulo de bitácora'/>
                                   </Form.Item>
                              </Col>
                              <Col span={11}>
                                   <Form.Item
                                        label="Tipo de bitácora"
                                        name="idTipoBitacora"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                   >
                                        <Select allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Selecciona un tipo de bitácora"
                                             loading={this.props.fetchingListaTiposBitacoraActivos}
                                             showSearch={true}
                                        >
                                             {this.props.listaTiposBitacoraActivos.map(option => 
                                             <Option key={option.idTipoBitacora + ' - ' + option.tipoBitacora}>
                                                  <Tooltip placement="topLeft" title={option.idTipoBitacora + ' - ' + option.tipoBitacora}>
                                                       {option.idTipoBitacora + ' - ' + option.tipoBitacora}
                                                  </Tooltip>
                                             </Option>)}
                                        </Select>
                                   </Form.Item>
                              </Col>
                              <Col span={11}>
                                   <Form.Item
                                        label="Módulo"
                                        name="idModulo"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                   >
                                        <Select
                                             allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Selecciona el módulo de sistema"
                                             loading={this.props.fetchingListaModulos}
                                             notFoundContent={null}
                                        >
                                             {this.props.listaModulosSelect.map(option => <Option key={option.idModulo + ' - ' + option.nombre}>{option.idModulo + ' - ' + option.nombre}</Option>)}
                                        </Select>
                                   </Form.Item>
                              </Col>
                              <Col span={11}>
                                   <Form.Item
                                        label="Servicio"
                                        name="idServicio"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                   >
                                        {/* <Select allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Selecciona un servicio a filtrar"
                                             onChange={servicioSeleccionado}
                                             showSearch={true}
                                        >
                                             {listaServiciosUsuario.map(option => 
                                             <Option key={option.idServicio + ' - ' + option.nombre}>
                                             <Tooltip placement="topLeft" title={option.idServicio + ' - ' + option.nombre}>
                                                  {option.idServicio + ' - ' + option.nombre}
                                             </Tooltip>
                                             </Option>)}
                                        </Select>   */}
                                        <Select allowClear
                                             style={{ width: '100%' }}
                                             placeholder="Selecciona un servicio a filtrar"
                                             loading={this.props.fetchingListaServiciosActivosF}
                                             onChange={servicioSeleccionado}
                                             showSearch={true}
                                        >
                                             {this.props.listaServiciosActivosF.map(option => 
                                             <Option key={option.idServicio + ' - ' + option.nombre}>
                                                  <Tooltip placement="topLeft" title={option.idServicio + ' - ' + option.nombre}>
                                                       {option.idServicio + ' - ' + option.nombre}
                                                  </Tooltip>
                                             </Option>)}
                                        </Select>
                                   </Form.Item>
                              </Col>
                              <Col span={11}>
                                   <Form.Item
                                        label="Partida"
                                        name="idPartida"
                                        rules={[{ required: this.props.listaPartidasByServicio4Fallas.length > 0 ? true : false, message: 'Favor de llenar el campo', }]}
                                   >
                                        { this.props.listaPartidasByServicio4Fallas.length > 0 ? (
                                             <Select 
                                                  allowClear 
                                                  style={{width: '100%'}}
                                                  placeholder="Selecciona una partida"
                                                  loading={this.props.fetchingListaPartidasByServicio4Fallas}
                                                  showSearch
                                                  disabled={this.state.servicioSeleccionado}
                                             >
                                                  {this.props.listaPartidasByServicio4Fallas.map(option => 
                                                       <Option key={option.idPartida + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                            <Tooltip title={option.nomenclatura + ': ' + option.nombre}>
                                                                 {option.idPartida + ' - ' + option.nomenclatura + ': ' + option.nombre}
                                                            </Tooltip>
                                                       </Option>)}
                                             </Select>
                                        ):(
                                             <Input style={{textAlign:'center'}} disabled placeholder='Servicio sin partidas' />
                                        ) }
                                   </Form.Item>
                              </Col>
                              {/* <Col span={11}>                   
                                   <Form.Item
                                        label="Fecha y hora"
                                        name="fechaHora"
                                   >
                                        <DatePicker
                                             style={{ width: '100%' }} 
                                             format='DD-MM-YYYY HH:mm' 
                                             showTime={true}
                                             disabledDate={disabledDate}
                                             />      
                                   </Form.Item> 
                              </Col> */}
                              
                              <Col span={11} className='marginBoton'>
                                   <br/>
                                   <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Crear Bitácora por Módulo</Button>
                              </Col>
                         </Row>
                         </Form>
                    </Spin>
               </Modal>
          );
     }
}

const mapStateToProps = state => {
	return {
          fetchingNuevoModuloBitacora: state.ConfiguracionYSeguridadReducer.fetchingNuevoModuloBitacora,
          showModalNuevoModuloBitacora: state.ConfiguracionYSeguridadReducer.showModalNuevoModuloBitacora,
          listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,

          page: state.ConfiguracionYSeguridadReducer.page,
          busqueda: state.ConfiguracionYSeguridadReducer.busqueda,
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,

          listaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.listaPartidasByServicio4Fallas,
          fetchingListaPartidasByServicio4Fallas: state.SolicitudDeServiciosReducer.fetchingListaPartidasByServicio4Fallas,

          listaTiposBitacoraActivos: state.ConfiguracionYSeguridadReducer.listaTiposBitacoraActivos,
          fetchingListaTiposBitacoraActivos: state.ConfiguracionYSeguridadReducer.fetchingListaTiposBitacoraActivos,

          listaModulosSelect: state.ConfiguracionYSeguridadReducer.listaModulosSelect,
          fetchingListaModulos: state.ConfiguracionYSeguridadReducer.fetchingListaModulos,

          listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
          fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
	};
};

const mapDispatchToProps = dispatch => {
	return {
          onShowModalNuevoTipoBitacora: (page, busqueda) => {
               dispatch({ type: 'SHOW_MODAL_NUEVO_MODULO_BITACORA', showModalNuevoModuloBitacora: false, page, busqueda })
          },
          onNuevoTipoBitacora: ( formulario, page, busqueda ) => {
               dispatch({ type: "NUEVO_TIPO_BITACORA_REQUEST" , formulario, page, busqueda });
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
          },
          onGetPartidasByIdServicio: (idServicio) => {
               dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_REQUEST', idServicio})
          },
          onNuevoModuloBitacora: ( formulario, page, busqueda ) => {
               dispatch({ type: "NUEVO_MODULO_BITACORA_REQUEST", formulario, page, busqueda });
          },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NuevoModuloBitacora);