export const SHOW_ERROR_MSG = "SHOW_ERROR_MSG";
export const SHOW_SUCCESS_MSG = "SHOW_SUCCESS_MSG";
export const SHOW_WARNING_MSG = "SHOW_WARNING_MSG";
export const LIMPIAR_FORMULARIO = "LIMPIAR_FORMULARIO";

export const CAMBIAR_VISTA_DASHBOARD = 'CAMBIAR_VISTA_DASHBOARD';
export const CAMBIAR_CATALOGO_SELECCIONADO = 'CAMBIAR_CATALOGO_SELECCIONADO';

export const LISTA_USUARIOS_PARAM_REQUEST = 'LISTA_USUARIOS_PARAM_REQUEST';
export const LISTA_USUARIOS_PARAM_SUCCESS = 'LISTA_USUARIOS_PARAM_SUCCESS';
export const LISTA_USUARIOS_PARAM_FAILURE = 'LISTA_USUARIOS_PARAM_FAILURE';

export const LISTA_USUARIOS_SIN_FILTRO_REQUEST = 'LISTA_USUARIOS_SIN_FILTRO_REQUEST';
export const LISTA_USUARIOS_SIN_FILTRO_SUCCESS = 'LISTA_USUARIOS_SIN_FILTRO_SUCCESS';
export const LISTA_USUARIOS_SIN_FILTRO_FAILURE = 'LISTA_USUARIOS_SIN_FILTRO_FAILURE';

export const CAMBIAR_ESTADO_USUARIO_REQUEST = 'CAMBIAR_ESTADO_USUARIO_REQUEST';
export const CAMBIAR_ESTADO_USUARIO_SUCCESS = 'CAMBIAR_ESTADO_USUARIO_SUCCESS';
export const CAMBIAR_ESTADO_USUARIO_FAILURE = 'CAMBIAR_ESTADO_USUARIO_FAILURE';

export const LISTA_DOMINIOS_ACTIVOS_REQUEST = 'LISTA_DOMINIOS_ACTIVOS_REQUEST';
export const LISTA_DOMINIOS_ACTIVOS_SUCCESS = 'LISTA_DOMINIOS_ACTIVOS_SUCCESS';
export const LISTA_DOMINIOS_ACTIVOS_FAILURE = 'LISTA_DOMINIOS_ACTIVOS_FAILURE';

export const LISTA_ROLES_REQUEST = 'LISTA_ROLES_REQUEST';
export const LISTA_ROLES_SUCCESS = 'LISTA_ROLES_SUCCESS';
export const LISTA_ROLES_FAILURE = 'LISTA_ROLES_FAILURE';

export const LISTA_ROLES_BUSQUEDA_REQUEST = 'LISTA_ROLES_BUSQUEDA_REQUEST';
export const LISTA_ROLES_BUSQUEDA_SUCCESS = 'LISTA_ROLES_BUSQUEDA_SUCCESS';
export const LISTA_ROLES_BUSQUEDA_FAILURE = 'LISTA_ROLES_BUSQUEDA_FAILURE';

export const MODAL_EDITAR_USUARIO = 'MODAL_EDITAR_USUARIO';
export const EDITAR_USUARIO_REQUEST = 'EDITAR_USUARIO_REQUEST';
export const EDITAR_USUARIO_SUCCESS = 'EDITAR_USUARIO_SUCCESS';
export const EDITAR_USUARIO_FAILURE = 'EDITAR_USUARIO_FAILURE';

export const MODAL_NUEVO_USUARIO = 'MODAL_NUEVO_USUARIO';
export const NUEVO_USUARIO_REQUEST = 'NUEVO_USUARIO_REQUEST';
export const NUEVO_USUARIO_SUCCESS = 'NUEVO_USUARIO_SUCCESS';
export const NUEVO_USUARIO_FAILURE = 'NUEVO_USUARIO_FAILURE';

export const RECUPERAR_PIN_REQUEST = 'RECUPERAR_PIN_REQUEST';
export const RECUPERAR_PIN_SUCCESS = 'RECUPERAR_PIN_SUCCESS';
export const RECUPERAR_PIN_FAILURE = 'RECUPERAR_PIN_FAILURE';

export const EDITAR_CONTRASEÑA_REQUEST = 'EDITAR_CONTRASEÑA_REQUEST';
export const EDITAR_CONTRASEÑA_SUCCESS = 'EDITAR_CONTRASEÑA_SUCCESS';
export const EDITAR_CONTRASEÑA_FAILURE = 'EDITAR_CONTRASEÑA_FAILURE';

export const VERIFICAR_USUARIO_REQUEST = 'VERIFICAR_USUARIO_REQUEST';
export const VERIFICAR_USUARIO_SUCCESS = 'VERIFICAR_USUARIO_SUCCESS';
export const VERIFICAR_USUARIO_FAILURE = 'VERIFICAR_USUARIO_FAILURE';

export const LISTA_PROVEEDOR_DOMINIO_SUCCESS = 'LISTA_PROVEEDOR_DOMINIO_SUCCESS';
export const LISTA_PROVEEDOR_DOMINIO_FAILURE = 'LISTA_PROVEEDOR_DOMINIO_FAILURE';

export const FILTRO_DOMINIO_REQUEST = 'FILTRO_DOMINIO_REQUEST';
export const FILTRO_DOMINIO_SUCCESS = 'FILTRO_DOMINIO_SUCCESS';
export const FILTRO_DOMINIO_FAILURE = 'FILTRO_DOMINIO_FAILURE';

export const FILTRO_SERVICIO_REQUEST = 'FILTRO_SERVICIO_REQUEST';
export const FILTRO_SERVICIO_SUCCESS = 'FILTRO_SERVICIO_SUCCESS';
export const FILTRO_SERVICIO_FAILURE = 'FILTRO_SERVICIO_FAILURE';

export const FILTRO_DOMINIO_SERVICIO_REQUEST = 'FILTRO_DOMINIO_SERVICIO_REQUEST';
export const FILTRO_DOMINIO_SERVICIO_SUCCESS = 'FILTRO_DOMINIO_SERVICIO_SUCCESS';
export const FILTRO_DOMINIO_SERVICIO_FAILURE = 'FILTRO_DOMINIO_SERVICIO_FAILURE';

// CATÁLOGO DE MODULOS DEL SISTEMA
export const LISTA_MODULOS_REQUEST = 'LISTA_MODULOS_REQUEST';
export const LISTA_MODULOS_SUCCESS = 'LISTA_MODULOS_SUCCESS';
export const LISTA_MODULOS_FAILURE = 'LISTA_MODULOS_FAILURE';

export const GET_MODULOS_REQUEST = 'GET_MODULOS_REQUEST';
export const GET_MODULOS_SUCCESS = 'GET_MODULOS_SUCCESS';
export const GET_MODULOS_FAILURE = 'GET_MODULOS_FAILURE';

export const ESTADO_MODULO_REQUEST = 'ESTADO_MODULO_REQUEST';
export const ESTADO_MODULO_SUCCESS = 'ESTADO_MODULO_SUCCESS';
export const ESTADO_MODULO_FAILURE = 'ESTADO_MODULO_FAILURE';

export const SHOW_NUEVO_MODULO = 'SHOW_NUEVO_MODULO';

export const NUEVO_MODULO_REQUEST = 'NUEVO_MODULO_REQUEST';
export const NUEVO_MODULO_SUCCESS = 'NUEVO_MODULO_SUCCESS';
export const NUEVO_MODULO_FAILURE = 'NUEVO_MODULO_FAILURE';

export const GET_MODULOS_BY_IDUSUARIO_REQUEST = 'GET_MODULOS_BY_IDUSUARIO_REQUEST';
export const GET_MODULOS_BY_IDUSUARIO_SUCCESS = 'GET_MODULOS_BY_IDUSUARIO_SUCCESS';
export const GET_MODULOS_BY_IDUSUARIO_FAILURE = 'GET_MODULOS_BY_IDUSUARIO_FAILURE';

export const GET_PERMISOS_REQUEST = 'GET_PERMISOS_REQUEST';
export const GET_PERMISOS_SUCCESS = 'GET_PERMISOS_SUCCESS';
export const GET_PERMISOS_FAILURE = 'GET_PERMISOS_FAILURE';

export const GET_PERMISOS_BY_MODULO_REQUEST = 'GET_PERMISOS_BY_MODULO_REQUEST';
export const GET_PERMISOS_BY_MODULO_SUCCESS = 'GET_PERMISOS_BY_MODULO_SUCCESS';
export const GET_PERMISOS_BY_MODULO_FAILURE = 'GET_PERMISOS_BY_MODULO_FAILURE';

export const GET_PERMISOS_BUSQUEDA_REQUEST = 'GET_PERMISOS_BUSQUEDA_REQUEST';
export const GET_PERMISOS_BUSQUEDA_SUCCESS = 'GET_PERMISOS_BUSQUEDA_SUCCESS';
export const GET_PERMISOS_BUSQUEDA_FAILURE = 'GET_PERMISOS_BUSQUEDA_FAILURE';

export const ESTADO_ROL_REQUEST = 'ESTADO_ROL_REQUEST';
export const ESTADO_ROL_SUCCESS = 'ESTADO_ROL_SUCCESS';
export const ESTADO_ROL_FAILURE = 'ESTADO_ROL_FAILURE';

export const SHOW_MODAL_NUEVO_ROL = 'SHOW_MODAL_NUEVO_ROL';
export const SHOW_MODAL_EDITAR_ROL = 'SHOW_MODAL_EDITAR_ROL';

export const NUEVO_ROL_REQUEST = 'NUEVO_ROL_REQUEST';
export const NUEVO_ROL_SUCCESS = 'NUEVO_ROL_SUCCESS';
export const NUEVO_ROL_FAILURE = 'NUEVO_ROL_FAILURE';

export const EDITAR_ROL_REQUEST = 'EDITAR_ROL_REQUEST';
export const EDITAR_ROL_SUCCESS = 'EDITAR_ROL_SUCCESS';
export const EDITAR_ROL_FAILURE = 'EDITAR_ROL_FAILURE';

export const GET_VALUE_SEARCH = 'GET_VALUE_SEARCH';

export const OBTENER_LOGS_REQUEST = 'OBTENER_LOGS_REQUEST';
export const OBTENER_LOGS_SUCCESS = 'OBTENER_LOGS_SUCCESS';
export const OBTENER_LOGS_FAILURE = 'OBTENER_LOGS_FAILURE';
export const GET_SETTINGS_REQUEST = 'ON_REQUEST_SETTINGS';
export const GET_SETTINGS_SUCCESS = 'ON_REQUEST_SUCCESS';
export const GET_SETTINGS_FAILURE = 'ON_REQUEST_FAILURE';

export const EDITAR_SETTINGS_REQUEST = 'EDITAR_SETTINGS_REQUEST';
export const EDITAR_SETTINGS_SUCCESS= 'EDITAR_SETTINGS_SUCCESS';
export const EDITAR_SETTINGS_FAILURE = 'EDITAR_SETTINGS_FAILURE';

export const SHOW_MODAL_EDITAR_SETTINGS = 'SHOW_MODAL_EDITAR_SETTINGS';

export const SERVICIO_POR_PROVEEDOR_REQUEST = 'SERVICIO_POR_PROVEEDOR_REQUEST';
export const SERVICIO_POR_PROVEEDOR_SUCCESS = 'SERVICIO_POR_PROVEEDOR_SUCCESS';
export const SERVICIO_POR_PROVEEDOR_FAILURE = 'SERVICIO_POR_PROVEEDOR_FAILURE';

//ALERTAS
export const LISTA_ALERTAS_BUSQUEDA_REQUEST = 'LISTA_ALERTAS_BUSQUEDA_REQUEST';
export const LISTA_ALERTAS_BUSQUEDA_SUCCESS = 'LISTA_ALERTAS_BUSQUEDA_SUCCESS';
export const LISTA_ALERTAS_BUSQUEDA_FAILURE = 'LISTA_ALERTAS_BUSQUEDA_FAILURE';

export const ESTADO_ALERTA_REQUEST = 'ESTADO_ALERTA_REQUEST';
export const ESTADO_ALERTA_SUCCESS = 'ESTADO_ALERTA_SUCCESS';
export const ESTADO_ALERTA_FAILURE = 'ESTADO_ALERTA_FAILURE';

export const LISTA_DIAS_ALERTA_REQUEST = 'LISTA_DIAS_ALERTA_REQUEST';
export const LISTA_DIAS_ALERTA_SUCCESS = 'LISTA_DIAS_ALERTA_SUCCESS';
export const LISTA_DIAS_ALERTA_FAILURE = 'LISTA_DIAS_ALERTA_FAILURE';

export const LISTA_EVENTO_POR_MODULO_REQUEST = 'LISTA_EVENTO_POR_MODULO_REQUEST'
export const LISTA_EVENTO_POR_MODULO_SUCCESS = 'LISTA_EVENTO_POR_MODULO_SUCCESS'
export const LISTA_EVENTO_POR_MODULO_FAILURE = 'LISTA_EVENTO_POR_MODULO_FAILURE'

export const SHOW_MODAL_DETALLE_ALERTA = 'SHOW_MODAL_DETALLE_ALERTA';
export const SHOW_MODAL_NUEVA_ALERTA = 'SHOW_MODAL_NUEVA_ALERTA';
export const SHOW_MODAL_EDITAR_ALERTA = 'SHOW_MODAL_EDITAR_ALERTA';

export const NUEVA_ALERTA_REQUEST = 'NUEVA_ALERTA_REQUEST';
export const NUEVA_ALERTA_SUCCESS = 'NUEVA_ALERTA_SUCCESS';
export const NUEVA_ALERTA_FAILURE = 'NUEVA_ALERTA_FAILURE';

export const EDITAR_ALERTA_REQUEST = 'EDITAR_ALERTA_REQUEST';
export const EDITAR_ALERTA_SUCCESS = 'EDITAR_ALERTA_SUCCESS';
export const EDITAR_ALERTA_FAILURE = 'EDITAR_ALERTA_FAILURE';

export const CAMBIAR_PERIODICIDAD = 'CAMBIAR_PERIODICIDAD';

export const TIEMPO_RECARGA = 'TIEMPO_RECARGA';

export const SHOW_NOTIFICACIONES = 'SHOW_NOTIFICACIONES';

//TIPOS DE BITACORA
export const LISTA_TIPOS_BITACORA_BUSQUEDA_REQUEST = 'LISTA_TIPOS_BITACORA_BUSQUEDA_REQUEST';
export const LISTA_TIPOS_BITACORA_BUSQUEDA_SUCCESS = 'LISTA_TIPOS_BITACORA_BUSQUEDA_SUCCESS';
export const LISTA_TIPOS_BITACORA_BUSQUEDA_FAILURE = 'LISTA_TIPOS_BITACORA_BUSQUEDA_FAILURE';

export const LISTA_TIPOS_BITACORA_ACTIVOS_REQUEST = 'LISTA_TIPOS_BITACORA_ACTIVOS_REQUEST';
export const LISTA_TIPOS_BITACORA_ACTIVOS_SUCCESS = 'LISTA_TIPOS_BITACORA_ACTIVOS_SUCCESS';
export const LISTA_TIPOS_BITACORA_ACTIVOS_FAILURE = 'LISTA_TIPOS_BITACORA_ACTIVOS_FAILURE';

export const ESTADO_TIPO_BITACORA_REQUEST = 'ESTADO_TIPO_BITACORA_REQUEST';
export const ESTADO_TIPO_BITACORA_SUCCESS = 'ESTADO_TIPO_BITACORA_SUCCESS';
export const ESTADO_TIPO_BITACORA_FAILURE = 'ESTADO_TIPO_BITACORA_FAILURE';

export const MODAL_NUEVO_TIPO_BITACORA = 'MODAL_NUEVO_TIPO_BITACORA';

export const NUEVO_TIPO_BITACORA_REQUEST = 'NUEVO_TIPO_BITACORA_REQUEST';
export const NUEVO_TIPO_BITACORA_SUCCESS = 'NUEVO_TIPO_BITACORA_SUCCESS';
export const NUEVO_TIPO_BITACORA_FAILURE = 'NUEVO_TIPO_BITACORA_FAILURE';

export const MODAL_EDITAR_TIPO_BITACORA = 'MODAL_EDITAR_TIPO_BITACORA';

export const EDITAR_TIPO_BITACORA_REQUEST = 'EDITAR_TIPO_BITACORA_REQUEST';
export const EDITAR_TIPO_BITACORA_SUCCESS = 'EDITAR_TIPO_BITACORA_SUCCESS';
export const EDITAR_TIPO_BITACORA_FAILURE = 'EDITAR_TIPO_BITACORA_FAILURE';

export const LISTA_TIPO_BITACORAS_BY_ID_MODULO_REQUEST = 'LISTA_TIPO_BITACORAS_BY_ID_MODULO_REQUEST';
export const LISTA_TIPO_BITACORAS_BY_ID_MODULO_SUCCESS = 'LISTA_TIPO_BITACORAS_BY_ID_MODULO_SUCCESS';
export const LISTA_TIPO_BITACORAS_BY_ID_MODULO_FAILURE = 'LISTA_TIPO_BITACORAS_BY_ID_MODULO_FAILURE';

//CATÁLOGO DE BITACORA
export const LISTA_BITACORAS_BUSQUEDA_REQUEST = 'LISTA_BITACORAS_BUSQUEDA_REQUEST';
export const LISTA_BITACORAS_BUSQUEDA_SUCCESS = 'LISTA_BITACORAS_BUSQUEDA_SUCCESS';
export const LISTA_BITACORAS_BUSQUEDA_FAILURE = 'LISTA_BITACORAS_BUSQUEDA_FAILURE';

export const ESTADO_BITACORA_REQUEST = 'ESTADO_BITACORA_REQUEST';
export const ESTADO_BITACORA_SUCCESS = 'ESTADO_BITACORA_SUCCESS';
export const ESTADO_BITACORA_FAILURE = 'ESTADO_BITACORA_FAILURE';

export const MODAL_NUEVA_BITACORA = 'MODAL_NUEVA_BITACORA';

export const NUEVA_BITACORA_REQUEST = 'NUEVA_BITACORA_REQUEST';
export const NUEVA_BITACORA_SUCCESS = 'NUEVA_BITACORA_SUCCESS';
export const NUEVA_BITACORA_FAILURE = 'NUEVA_BITACORA_FAILURE';

export const MODAL_EDITAR_BITACORA = 'MODAL_EDITAR_BITACORA';

export const EDITAR_BITACORA_REQUEST = 'EDITAR_BITACORA_REQUEST';
export const EDITAR_BITACORA_SUCCESS = 'EDITAR_BITACORA_SUCCESS';
export const EDITAR_BITACORA_FAILURE = 'EDITAR_BITACORA_FAILURE';

export const MODAL_VER_BITACORA = 'MODAL_VER_BITACORA';

export const GET_VALUES_FORMULARIO = 'GET_VALUES_FORMULARIO';

export const GET_VALUES_FORMULARIO_ALERTAS = 'GET_VALUES_FORMULARIO_ALERTAS';
//Bitácoras Módulo
export const GET_ID_MODULO = 'GET_ID_MODULO';

export const LISTA_BITACORAS_MODULOS_BUSQUEDA_REQUEST = 'LISTA_BITACORAS_MODULOS_BUSQUEDA_REQUEST';
export const LISTA_BITACORAS_MODULOS_BUSQUEDA_SUCCESS = 'LISTA_BITACORAS_MODULOS_BUSQUEDA_SUCCESS';
export const LISTA_BITACORAS_MODULOS_BUSQUEDA_FAILURE = 'LISTA_BITACORAS_MODULOS_BUSQUEDA_FAILURE';

export const SHOW_MODAL_NUEVO_MODULO_BITACORA = 'SHOW_MODAL_NUEVO_MODULO_BITACORA';
export const SHOW_MODAL_EDITAR_MODULO_BITACORA = 'SHOW_MODAL_EDITAR_MODULO_BITACORA';

export const NUEVO_MODULO_BITACORA_REQUEST = 'NUEVO_MODULO_BITACORA_REQUEST';
export const NUEVO_MODULO_BITACORA_SUCCESS = 'NUEVO_MODULO_BITACORA_SUCCESS';
export const NUEVO_MODULO_BITACORA_FAILURE = 'NUEVO_MODULO_BITACORA_FAILURE';

export const EDITAR_MODULO_BITACORA_REQUEST = 'EDITAR_MODULO_BITACORA_REQUEST';
export const EDITAR_MODULO_BITACORA_SUCCESS = 'EDITAR_MODULO_BITACORA_SUCCESS';
export const EDITAR_MODULO_BITACORA_FAILURE = 'EDITAR_MODULO_BITACORA_FAILURE';

export const LISTA_MODOULOS_BITACORAS_BY_IDMODULO_REQUEST = 'LISTA_MODOULOS_BITACORAS_BY_IDMODULO_REQUEST';
export const LISTA_MODOULOS_BITACORAS_BY_IDMODULO_SUCCESS = 'LISTA_MODOULOS_BITACORAS_BY_IDMODULO_SUCCESS';
export const LISTA_MODOULOS_BITACORAS_BY_IDMODULO_FAILURE = 'LISTA_MODOULOS_BITACORAS_BY_IDMODULO_FAILURE';

//Reporte Dinámico
export const LISTA_REPORTE_DINAMICO_BITACORA_BUSQUEDA_REQUEST = 'LISTA_REPORTE_DINAMICO_BITACORA_BUSQUEDA_REQUEST';
export const LISTA_REPORTE_DINAMICO_BITACORA_BUSQUEDA_SUCCESS = 'LISTA_REPORTE_DINAMICO_BITACORA_BUSQUEDA_SUCCESS';
export const LISTA_REPORTE_DINAMICO_BITACORA_BUSQUEDA_FAILURE = 'LISTA_REPORTE_DINAMICO_BITACORA_BUSQUEDA_FAILURE';

export const REPORTE_DINAMICO_AGRUPADO_BITACORA_REQUEST = 'REPORTE_DINAMICO_AGRUPADO_BITACORA_REQUEST';
export const REPORTE_DINAMICO_AGRUPADO_BITACORA_SUCCESS = 'REPORTE_DINAMICO_AGRUPADO_BITACORA_SUCCESS';
export const REPORTE_DINAMICO_AGRUPADO_BITACORA_FAILURE = 'REPORTE_DINAMICO_AGRUPADO_BITACORA_FAILURE';

export const PDF_REPORTE_DINAMICO_BITACORA_REQUEST = 'PDF_REPORTE_DINAMICO_BITACORA_REQUEST';
export const PDF_REPORTE_DINAMICO_BITACORA_SUCCESS = 'PDF_REPORTE_DINAMICO_BITACORA_SUCCESS';
export const PDF_REPORTE_DINAMICO_BITACORA_FAILURE = 'PDF_REPORTE_DINAMICO_BITACORA_FAILURE';

export const EXCEL_REPORTE_DINAMICO_BITACORA_REQUEST = 'EXCEL_REPORTE_DINAMICO_BITACORA_REQUEST';
export const EXCEL_REPORTE_DINAMICO_BITACORA_SUCCESS = 'EXCEL_REPORTE_DINAMICO_BITACORA_SUCCESS';
export const EXCEL_REPORTE_DINAMICO_BITACORA_FAILURE = 'EXCEL_REPORTE_DINAMICO_BITACORA_FAILURE';

export const LIMPIAR_FORMULARIO_REPORTE_BITACORA = 'LIMPIAR_FORMULARIO_REPORTE_BITACORA';

export const PDF_REPORTE_DINAMICO_BITACORA_AGRUPADO_REQUEST = 'PDF_REPORTE_DINAMICO_BITACORA_AGRUPADO_REQUEST';
export const PDF_REPORTE_DINAMICO_BITACORA_AGRUPADO_SUCCESS = 'PDF_REPORTE_DINAMICO_BITACORA_AGRUPADO_SUCCESS';
export const PDF_REPORTE_DINAMICO_BITACORA_AGRUPADO_FAILURE = 'PDF_REPORTE_DINAMICO_BITACORA_AGRUPADO_FAILURE';

export const EXCEL_REPORTE_DINAMICO_BITACORA_AGRUPADO_REQUEST = 'EXCEL_REPORTE_DINAMICO_BITACORA_AGRUPADO_REQUEST';
export const EXCEL_REPORTE_DINAMICO_BITACORA_AGRUPADO_SUCCESS = 'EXCEL_REPORTE_DINAMICO_BITACORA_AGRUPADO_SUCCESS';
export const EXCEL_REPORTE_DINAMICO_BITACORA_AGRUPADO_FAILURE = 'EXCEL_REPORTE_DINAMICO_BITACORA_AGRUPADO_FAILURE';

export const LISTA_PARTIDAS_BY_IDSSERVICIOS_REQUEST = 'LISTA_PARTIDAS_BY_IDSSERVICIOS_REQUEST';
export const LISTA_PARTIDAS_BY_IDSSERVICIOS_SUCCESS = 'LISTA_PARTIDAS_BY_IDSSERVICIOS_SUCCESS';
export const LISTA_PARTIDAS_BY_IDSSERVICIOS_FAILURE = 'LISTA_PARTIDAS_BY_IDSSERVICIOS_FAILURE';

export const GET_ALL_REGISTROS_BITACORA_REQUEST = 'GET_ALL_REGISTROS_BITACORA_REQUEST';
export const GET_ALL_REGISTROS_BITACORA_SUCCESS = 'GET_ALL_REGISTROS_BITACORA_SUCCESS';
export const GET_ALL_REGISTROS_BITACORA_FAILURE = 'GET_ALL_REGISTROS_BITACORA_FAILURE';

export const GET_REGISTROS_BY_TOKEN_BITACORA_REQUEST = 'GET_REGISTROS_BY_TOKEN_BITACORA_REQUEST';
export const GET_REGISTROS_BY_TOKEN_BITACORA_SUCCESS = 'GET_REGISTROS_BY_TOKEN_BITACORA_SUCCESS';
export const GET_REGISTROS_BY_TOKEN_BITACORA_FAILURE = 'GET_REGISTROS_BY_TOKEN_BITACORA_FAILURE';

export const CREATE_REGISTROS_BITACORA_REQUEST = 'CREATE_REGISTROS_BITACORA_REQUEST';
export const CREATE_REGISTROS_BITACORA_SUCCESS = 'CREATE_REGISTROS_BITACORA_SUCCESS';
export const CREATE_REGISTROS_BITACORA_FAILURE = 'CREATE_REGISTROS_BITACORA_FAILURE';

export const EDIT_REGISTROS_BITACORA_REQUEST = 'EDIT_REGISTROS_BITACORA_REQUEST';
export const EDIT_REGISTROS_BITACORA_SUCCESS = 'EDIT_REGISTROS_BITACORA_SUCCESS';
export const EDIT_REGISTROS_BITACORA_FAILURE = 'EDIT_REGISTROS_BITACORA_FAILURE';

export const ESTADO_REGISTROS_BITACORA_REQUEST = 'ESTADO_REGISTROS_BITACORA_REQUEST';
export const ESTADO_REGISTROS_BITACORA_SUCCESS = 'ESTADO_REGISTROS_BITACORA_SUCCESS';
export const ESTADO_REGISTROS_BITACORA_FAILURE = 'ESTADO_REGISTROS_BITACORA_FAILURE';

export const MODAL_CREAR_REGISTRO_BITACORA = 'MODAL_CREAR_REGISTRO_BITACORA';

export const MODAL_EDITAR_REGISTRO_BITACORA = 'MODAL_EDITAR_REGISTRO_BITACORA';

export const LISTA_PARTIDAS_ACTIVAS_REQUEST = 'LISTA_PARTIDAS_ACTIVAS_REQUEST';
export const LISTA_PARTIDAS_ACTIVAS_SUCCESS = 'LISTA_PARTIDAS_ACTIVAS_SUCCESS';
export const LISTA_PARTIDAS_ACTIVAS_FAILURE = 'LISTA_PARTIDAS_ACTIVAS_FAILURE';

// Rangos de Validación
export const LISTA_BUSQUEDA_RANGOS_VALIDACION_REQUEST = 'LISTA_BUSQUEDA_RANGOS_VALIDACION_REQUEST';
export const LISTA_BUSQUEDA_RANGOS_VALIDACION_SUCCESS = 'LISTA_BUSQUEDA_RANGOS_VALIDACION_SUCCESS';
export const LISTA_BUSQUEDA_RANGOS_VALIDACION_FAILURE = 'LISTA_BUSQUEDA_RANGOS_VALIDACION_FAILURE';

export const LISTA_ACTIVOS_RANGOS_VALIDACION_REQUEST = 'LISTA_ACTIVOS_RANGOS_VALIDACION_REQUEST';
export const LISTA_ACTIVOS_RANGOS_VALIDACION_SUCCESS = 'LISTA_ACTIVOS_RANGOS_VALIDACION_SUCCESS';
export const LISTA_ACTIVOS_RANGOS_VALIDACION_FAILURE = 'LISTA_ACTIVOS_RANGOS_VALIDACION_FAILURE';

export const NUEVO_RANGO_VALIDACION_REQUEST = 'NUEVO_RANGO_VALIDACION_REQUEST';
export const NUEVO_RANGO_VALIDACION_SUCCESS = 'NUEVO_RANGO_VALIDACION_SUCCESS';
export const NUEVO_RANGO_VALIDACION_FAILURE = 'NUEVO_RANGO_VALIDACION_FAILURE';

export const EDITAR_RANGO_VALIDACION_REQUEST = 'EDITAR_RANGO_VALIDACION_REQUEST';
export const EDITAR_RANGO_VALIDACION_SUCCESS = 'EDITAR_RANGO_VALIDACION_SUCCESS';
export const EDITAR_RANGO_VALIDACION_FAILURE = 'EDITAR_RANGO_VALIDACION_FAILURE';

export const ESTADO_RANGO_VALIDACION_REQUEST = 'ESTADO_RANGO_VALIDACION_REQUEST';
export const ESTADO_RANGO_VALIDACION_SUCCESS = 'ESTADO_RANGO_VALIDACION_SUCCESS';
export const ESTADO_RANGO_VALIDACION_FAILURE = 'ESTADO_RANGO_VALIDACION_FAILURE';

export const SHOW_MODAL_NUEVO_RANGO_VALIDACION = 'SHOW_MODAL_NUEVO_RANGO_VALIDACION';

export const SHOW_MODAL_EDITAR_RANGO_VALIDACION = 'SHOW_MODAL_EDITAR_RANGO_VALIDACION';

export const EXPORT_EXCEL_RANGOS_VALIDACION_REQUEST = 'EXPORT_EXCEL_RANGOS_VALIDACION_REQUEST';
export const EXPORT_EXCEL_RANGOS_VALIDACION_SUCCESS = 'EXPORT_EXCEL_RANGOS_VALIDACION_SUCCESS';
export const EXPORT_EXCEL_RANGOS_VALIDACION_FAILURE = 'EXPORT_EXCEL_RANGOS_VALIDACION_FAILURE';

// Responsables de Servicios
export const LISTA_BUSQUEDA_RESPONSABLES_REQUEST = 'LISTA_BUSQUEDA_RESPONSABLES_REQUEST';
export const LISTA_BUSQUEDA_RESPONSABLES_SUCCESS = 'LISTA_BUSQUEDA_RESPONSABLES_SUCCESS';
export const LISTA_BUSQUEDA_RESPONSABLES_FAILURE = 'LISTA_BUSQUEDA_RESPONSABLES_FAILURE';

export const LISTA_ACTIVOS_RESPONSABLES_REQUEST = 'LISTA_ACTIVOS_RESPONSABLES_REQUEST';
export const LISTA_ACTIVOS_RESPONSABLES_SUCCESS = 'LISTA_ACTIVOS_RESPONSABLES_SUCCESS';
export const LISTA_ACTIVOS_RESPONSABLES_FAILURE = 'LISTA_ACTIVOS_RESPONSABLES_FAILURE';

export const NUEVO_RESPONSABLE_REQUEST = 'NUEVO_RESPONSABLE_REQUEST';
export const NUEVO_RESPONSABLE_SUCCESS = 'NUEVO_RESPONSABLE_SUCCESS';
export const NUEVO_RESPONSABLE_FAILURE = 'NUEVO_RESPONSABLE_FAILURE';

export const EDITAR_RESPONSABLE_REQUEST = 'EDITAR_RESPONSABLE_REQUEST';
export const EDITAR_RESPONSABLE_SUCCESS = 'EDITAR_RESPONSABLE_SUCCESS';
export const EDITAR_RESPONSABLE_FAILURE = 'EDITAR_RESPONSABLE_FAILURE';

export const ESTADO_RESPONSABLE_REQUEST = 'ESTADO_RESPONSABLE_REQUEST';
export const ESTADO_RESPONSABLE_SUCCESS = 'ESTADO_RESPONSABLE_SUCCESS';
export const ESTADO_RESPONSABLE_FAILURE = 'ESTADO_RESPONSABLE_FAILURE';

export const SHOW_MODAL_NUEVO_RESPONSABLE = 'SHOW_MODAL_NUEVO_RESPONSABLE';

export const SHOW_MODAL_EDITAR_RESPONSABLE = 'SHOW_MODAL_EDITAR_RESPONSABLE';

export const LISTA_USUARIOS_BY_IDSSERVICIOS_REQUEST = 'LISTA_USUARIOS_BY_IDSSERVICIOS_REQUEST';
export const LISTA_USUARIOS_BY_IDSSERVICIOS_SUCCESS = 'LISTA_USUARIOS_BY_IDSSERVICIOS_SUCCESS';
export const LISTA_USUARIOS_BY_IDSSERVICIOS_FAILURE = 'LISTA_USUARIOS_BY_IDSSERVICIOS_FAILURE';

export const LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_RS_REQUEST = 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_RS_REQUEST';
export const LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_RS_SUCCESS = 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_RS_SUCCESS';
export const LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_RS_FAILURE = 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_RS_FAILURE';

export const LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_AT_REQUEST = 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_AT_REQUEST';
export const LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_AT_SUCCESS = 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_AT_SUCCESS';
export const LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_AT_FAILURE = 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_AT_FAILURE';

export const LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_PYRS_REQUEST = 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_PYRS_REQUEST';
export const LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_PYRS_SUCCESS = 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_PYRS_SUCCESS';
export const LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_PYRS_FAILURE = 'LISTA_USUARIOS_BY_IDSSERVICIOS_TIPO_PYRS_FAILURE';

export const MODAL_HISTORICO_RESPONSABLES = 'MODAL_HISTORICO_RESPONSABLES';

export const LISTA_HISTORICO_RESPONSABLE_BUSQUEDA_REQUEST = 'LISTA_HISTORICO_RESPONSABLE_BUSQUEDA_REQUEST';
export const LISTA_HISTORICO_RESPONSABLE_BUSQUEDA_SUCCESS = 'LISTA_HISTORICO_RESPONSABLE_BUSQUEDA_SUCCESS';
export const LISTA_HISTORICO_RESPONSABLE_BUSQUEDA_FAILURE = 'LISTA_HISTORICO_RESPONSABLE_BUSQUEDA_FAILURE';

// Notificaciones

export const LISTA_NOTIFICACIONES_REQUEST = 'LISTA_NOTIFICACIONES_REQUEST';
export const LISTA_NOTIFICACIONES_SUCCESS = 'LISTA_NOTIFICACIONES_SUCCESS';
export const LISTA_NOTIFICACIONES_FAILURE = 'LISTA_NOTIFICACIONES_FAILURE';

export const LISTA_NOTIFICACIONES_NO_LEIDAS_REQUEST = 'LISTA_NOTIFICACIONES_NO_LEIDAS_REQUEST';
export const LISTA_NOTIFICACIONES_NO_LEIDAS_SUCCESS = 'LISTA_NOTIFICACIONES_NO_LEIDAS_SUCCESS';
export const LISTA_NOTIFICACIONES_NO_LEIDAS_FAILURE = 'LISTA_NOTIFICACIONES_NO_LEIDAS_FAILURE';

export const ACTUALIZAR_NOTIFICACIONES_REQUEST = 'ACTUALIZAR_NOTIFICACIONES_REQUEST';
export const ACTUALIZAR_NOTIFICACIONES_SUCCESS = 'ACTUALIZAR_NOTIFICACIONES_SUCCESS';
export const ACTUALIZAR_NOTIFICACIONES_FAILURE = 'ACTUALIZAR_NOTIFICACIONES_FAILURE';