import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, FileExcelOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css'
import ModalNuevoEstatusActivo from './ModalNuevoEstatusDelActivo';
import ModalEditarEstatusActivo from './ModalEditarEstatusDelActivo';

const confirm = Modal.confirm;
const { Search } = Input;

class CatalogoEstatusDelActivo extends React.Component {
    state = {
        page: 1,
        busqueda: '',
    }

    componentDidMount() {
        this.props.onGetEstatus(1);

    }

    render() {
        const { fetchingListaEstatusActivoBusqueda, listaEstatusActivoBusqueda, onGetEstatus,
            onModalNuevoEstatusActivo, onModalEditarEstatusActivo, onEstadoEstatus, listaEstatusActivoBusquedaCantidad } = this.props;
    
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if (permisosUsuario[0]) {
            permisosUsuario = JSON.parse(permisosUsuario);
        }

        const disabledField =['En operación', 'Baja física','Baja administrativa', 'En reparación', 'Préstamo'];

        const columns = [{
            title: 'Descripción corta',
            dataIndex: 'descripcionC',
            key: 'descripcionC',
        }, {
            title: 'Descripción larga',
            dataIndex: 'descripcionL',
            key: 'descripcionL',
        }, {
            title: 'Activo',
            dataIndex: 'activo',
            key: 'activo',
            align: 'center',
            render: (text, record) => (
                <Switch checked={text} disabled={!permisosUsuario.estatusDelActivoActivoInactivo} onChange = {() => handleDesactivar(record)}disabled={disabledField.includes(record.descripcionC)} />
            ),
        }, {
            title: 'Editar',
            key: 'editar',
            align: 'center',
            //width: '6%',
            render: (text, record) => (
                <a className='editarIcono' disabled={!permisosUsuario.editarEstatusDelActivo} onClick={() => onEditar(record)}disabled={disabledField.includes(record.descripcionC)}><EditOutlined /></a>
            ),
        },
        ];

        const onBuscarEstatusActivo = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            });
            console.log(busqueda);
            onGetEstatus(1, busqueda);
        }

        const handleDesactivar = (key) => {
            console.log(key);
            let page = this.state.page
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del estatus del activo "' + key.descripcionC + '"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoEstatus(key.idEstadoActivo, page);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }

        const onEditar = (key) => {
            onModalEditarEstatusActivo(key, this.state.page);

        }

        const onNuevoEstatusActivo = () => {
            onModalNuevoEstatusActivo(this.state.page);
        }

        const handleTableChange = (pagination) => {
            this.setState({
                page: pagination.current,
            });
            onGetEstatus(pagination.current, this.state.busqueda);
        }


        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={20}>
                        <Search className='buscarModulos' placeholder='Ingresar estatus del activo' onSearch={value => onBuscarEstatusActivo(value)} />
                    </Col>
                    {/* <Col span={10}>
                        <Button onClick={onNuevoEstatusActivo} disabled={!permisosUsuario.crearEstatusDelActivo} className='nuevoUsuario'>Nuevo Estatus del Activo</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nuevo Estatus del Activo' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoEstatusActivo} disabled={!permisosUsuario.crearEstatusDelActivo} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={1}>
                        <Tooltip title='Exportar archivo .xls' placement="topRight">
                            <Button disabled={!permisosUsuario.exportarExcelCatálogoEstatusDelActivo} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } loading={this.props.fetchingExportarExcelEstatusActivo} onClick={() => this.props.onExport()}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <br />
                        <Table
                            size="small"
                            rowKey="descripcionC"
                            columns={columns}
                            dataSource={listaEstatusActivoBusqueda}
                            loading={fetchingListaEstatusActivoBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaEstatusActivoBusquedaCantidad, simple: true, current: this.state.page }}
                        />
                    </Col>
                </Row>
                < ModalNuevoEstatusActivo/>
                <ModalEditarEstatusActivo />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        listaEstatusActivoBusqueda: state.AlmacenesReducer.listaEstatusActivoBusqueda,
        fetchingListaEstatusActivoBusqueda: state.AlmacenesReducer.fetchingListaEstatusActivoBusqueda,
        listaEstatusActivoBusquedaCantidad: state.AlmacenesReducer.listaEstatusActivoBusquedaCantidad,
        fetchingExportarExcelEstatusActivo: state.AlmacenesReducer.fetchingExportarExcelEstatusActivo,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetEstatus: (page, busqueda) => {
            dispatch({ type: "ESTATUS_ACTIVO_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
        },
        onEstadoEstatus: (idEstadoActivo, page) => {
            dispatch({ type: "ESTADO_ESTATUS_ACTIVO_REQUEST", idEstadoActivo: idEstadoActivo, page: page});
        },
        onModalNuevoEstatusActivo: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_ESTATUS_ACTIVO", showModalNuevoEstatusActivo: true, page: page })
        },
        onModalEditarEstatusActivo: (EstatusActivoSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_ESTATUS_ACTIVO", showModalEditarEstatusActivo: true, EstatusActivoSeleccionado:EstatusActivoSeleccionado, page:page});
        },
        onExport: () => {
            dispatch({ type: "EXPORTAR_ESTATUS_ACTIVO_EXCEL_REQUEST" });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoEstatusDelActivo);