//Archivo que une todos los archivos Saga
import { all, fork } from "redux-saga/effects";
import * as LoginSaga from "./LoginSaga";
import * as ConfiguracionYSeguridadSaga from "./ConfiguracionYSeguridadSaga";
import * as InformacionDelProyectoSaga from "./InformacionDelProyectoSaga";
import * as InformacionOperativaSaga from './InformacionOperativaSaga';
import * as AlmacenesSaga from './AlmacenesSaga';
import * as CatalogosGeneralesSaga from "./CatalogosGeneralesSaga";
import * as MecanismosDePago from "./MecanismosDePagosSaga";
import * as RecursosHumanosSaga from "./RecursosHumanosSaga";
import * as ServiciosPublicosSaga from "./ServiciosPublicosSaga";
import * as ServiciosVariablesSaga from "./ServiciosVariablesSaga";
import * as BaseConocimientoSaga from './BaseDeConocimientoSaga';
import * as SolicitudDeServiciosSaga from './SolicitudDeServiciosSaga';
import * as ServiciosPlanificadosSaga from './ServiciosPlanificadosSaga';
import * as GestionDeCalidadSaga from './GestionDeCalidadSaga';
import * as MonitorIntegralSaga from './MonitorIntegralSaga';
import * as EvaluacionDeLaGestionSaga from './EvaluacionDeLaGestionSaga';
import * as RMDPSaga from './RMDPSaga';

export default function* rootSaga() {

  var allSagas = Object.assign(LoginSaga, ConfiguracionYSeguridadSaga, InformacionDelProyectoSaga, InformacionOperativaSaga, AlmacenesSaga, CatalogosGeneralesSaga, MecanismosDePago, RecursosHumanosSaga, ServiciosPublicosSaga, ServiciosVariablesSaga ,BaseConocimientoSaga, SolicitudDeServiciosSaga, ServiciosPlanificadosSaga, GestionDeCalidadSaga, MonitorIntegralSaga, EvaluacionDeLaGestionSaga, RMDPSaga);

  yield all(
    [...Object.values(allSagas)].map(fork)
  );
}