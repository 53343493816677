import React, { Component } from 'react';
import {Form, Input, Button, Modal, Col, Row, Spin, Divider, DatePicker, InputNumber, Select} from 'antd';
import { connect } from 'react-redux';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import moment from 'moment';

const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
const { Option } = Select;
class ModalModificarRegistroNuevoFolio extends Component {
    state = {  tipoMonedaSeleccionada: null }
    formRef = React.createRef();
    render() { 
        const {showModalModificarEditarFolio, fetchingEditarFolio,
            registroProductoSeleccionado,vistaModalEditarFolio, 
            listaDetalle  
        } = this.props;
     

        const onFinish = formulario => {
            console.log('Success:' , formulario);
            const listaDetalleLocal = listaDetalle;
            if(formulario.lote < 1){
                formulario.lote = 1;
            } 
            if(formulario.cantidadTotal > registroProductoSeleccionado.cantidadInventario){
                formulario.cantidadTotal = registroProductoSeleccionado.cantidadInventario
            }
            this.props.onGuardarProducto(vistaModalEditarFolio, formulario, null);
            for (let i = 0; i < listaDetalleLocal.length; i++) {
               if(listaDetalleLocal[i].idProducto === registroProductoSeleccionado.idProducto){
                 if(listaDetalleLocal[i].tiposFoliosByIdTipoFolio.operacion !== "Resta" && listaDetalleLocal[i].tiposFoliosByIdTipoFolio.operacion !== "Ambas"){
                    //entra a tipo folio suma
                    listaDetalleLocal[i] = Object.assign(listaDetalleLocal[i], formulario)
                 } else {
                     // entra a folio inventario 
                    listaDetalleLocal[i].fechaCaducidad = formulario.fechaCaducidadEditar.format('DD-MM-YYYY')
                    listaDetalleLocal[i] = Object.assign(listaDetalleLocal[i], formulario)
                 }
               }  
            }
            this.props.onGuardarProducto(vistaModalEditarFolio, formulario, listaDetalleLocal);
            this.props.onShowModalModificar();
        };

        const handleCancel = () => {
            this.props.onShowModalModificar();
            this.formRef.current.resetFields();
            this.setState({ tipoMonedaSeleccionada: null });
        };


        const  disabledDate = (current) => {
            // Can not select days before today and today
            return current && current < moment();
        }
        console.log('registroProductoSeleccionado', registroProductoSeleccionado)
        return ( 
            <Modal
                visible={showModalModificarEditarFolio}
                title={'Modificar información de: ' + registroProductoSeleccionado?.nombre}
                onCancel={handleCancel}
                footer={false}
            >
                {registroProductoSeleccionado ? (
                    <Spin spinning={fetchingEditarFolio}>
                        <Form {...layout}
                            name='formulario'
                            initialValues={registroProductoSeleccionado}
                            onFinish={onFinish}
                            ref={this.formRef}
                        >
                            <Form.Item
                                label="Cantidad"
                                name="cantidadTotal"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <InputNumber min={1} placeholder='Cantidad' max={registroProductoSeleccionado.cantidadInventario}/>
                            </Form.Item>

                            {registroProductoSeleccionado.tiposFoliosByIdTipoFolio ? ( <>
                                {registroProductoSeleccionado.tiposFoliosByIdTipoFolio.operacion !== "Ambas" && registroProductoSeleccionado.tiposFoliosByIdTipoFolio.operacion !== "Resta"? ( <>
                                    <Form.Item
                                        label="Tipo de moneda"
                                        name="tipoMonedaEditar"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el tipo de moneda"
                                            loading={this.props.fetchingListaTipoMonedasActivas}
                                            onChange={(a, b) => { this.setState({ tipoMonedaSeleccionada: b.children }); }}
                                        >
                                            {this.props.listaTipoMonedasActivas.map(option => <Option key={option.idTipoMoneda + ' - ' + option.nombre + ' (' + option.nomenclatura + ')'}>{option.idTipoMoneda + ' - ' + option.nombre + ' (' + option.nomenclatura + ')'}</Option>)}
                                        </Select>
                                    </Form.Item>
                                    

                                    <Form.Item
                                        label="Costo unitario"
                                        name="precioCosto"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <InputNumber min={1} placeholder='Precio' formatter={value =>  `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                                    </Form.Item>

                                    { this.state.tipoMonedaSeleccionada ? (<>
                                        {this.state.tipoMonedaSeleccionada.split('(')[1] !== 'MXN)' ? (
                                            <Form.Item
                                                label="Tipo de Cambio"
                                                name="tipoCambio"
                                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                            >
                                                <InputNumber min={1} placeholder='Tipo de cambio' formatter={value =>  `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                                            </Form.Item>
                                        ) : false}
                                    </>): (<>
                                        {registroProductoSeleccionado.tipoMoneda.split('(')[1] !== 'MXN)' ? (
                                            <Form.Item
                                                label="Tipo de Cambio"
                                                name="tipoCambio"
                                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                            >
                                                <InputNumber min={1} placeholder='Tipo de cambio' formatter={value =>  `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                                            </Form.Item>
                                        ) : false}
                                    </>) }

                                    {registroProductoSeleccionado.productosByIdProducto.caracteristicaLote ? ( <>
                                        <Divider orientation="left"></Divider>
                                        <Form.Item
                                            label="Lote"
                                            name="lote"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                            <InputNumber min={1} placeholder='Lote' />
                                        </Form.Item>
                                        
                                        <Form.Item
                                            label="Fecha de caducidad"
                                            name="fechaCaducidadEditar"
                                            //name="fechaCaducidad"
                                            rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                                        >
                                            <DatePicker
                                                style={{ width: '100%' }}
                                                format='DD-MM-YYYY'
                                                disabledDate={disabledDate}
                                                showToday={false}
                                            />
                                        </Form.Item>
                                    </> ): false}
                                </> ): false}
                            </> ): false}
                            
                            
                             
                                
                            <Col span={24}>
                               <Button htmlType="submit" className='nuevoUsuario'>Guardar Cambios</Button>
                            </Col>
                            
                        </Form>
                    </Spin>
                ): false}
                
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        showModalModificarEditarFolio: state.AlmacenesReducer.showModalModificarEditarFolio,
        fetchingEditarFolio: state.AlmacenesReducer.fetchingEditarFolio,
        registroProductoSeleccionado: state.AlmacenesReducer.registroProductoSeleccionado,
        listaDetalle: state.AlmacenesReducer.listaDetalle,
        vistaModalEditarFolio: state.AlmacenesReducer.vistaModalEditarFolio, 
        
        listaTipoMonedasActivas: state.CatalogosGeneralesReducer.listaTipoMonedasActivas,
        fetchingListaTipoMonedasActivas: state.CatalogosGeneralesReducer.fetchingListaTipoMonedasActivas,
    };
};

const mapDispatchToProps = dispatch => {
	return {
        onShowModalModificar: () => {
            dispatch({ type: "SHOW_MODAL_MODIFICAR_EDITARFOLIO", showModalModificarEditarFolio: false})
        },
        onGuardarProducto: (vistaModalEditarFolio, formulario, listaDetalle) => {
            dispatch({ type: "CAMBIAR_VISTA_MODAL_EDITARFOLIO", vistaModalEditarFolio: vistaModalEditarFolio, formularioEditarFolio: formulario, listaSeleccionadosEditar: listaDetalle, listaDetalle})  
        },
	};
};
 
export default connect(mapStateToProps, mapDispatchToProps)(ModalModificarRegistroNuevoFolio);