import * as GestionDeCalidadActions from '../actions/GestionDeCalidadActions';
import {MODAL_LISTA_ACTIVIDADES} from "../actions/GestionDeCalidadActions";

const initialState = {
     page: 1,
     busqueda: '',
     orden: [],

     //Plan de calidad
     fetchingListaPlanesCalidadBusqueda: false,
     listaPlanesCalidadBusqueda: [],
     listaPlanesCalidadBusquedaCantidad: 0,

     fetchingListaPlanCalidadAños: false,
     listaPlanCalidadAños: [],

     showModalVerPlanCalidad: false,
     planCalidadSeleccionado: null,

     showModalNuevoPlanCalidad: false,
     fetchingNuevoPlanCalidad: false,

     showModalEditarPlanCalidad: false,
     fetchingEditarPlanCalidad: false,

     //Planes correctivos
     fetchingListaPlanesCorrectivosBusqueda: false,
     listaPlanesCorrectivosBusqueda: [],
     listaPlanesCorrectivosBusquedaCantidad: 0,

     showModalVerPlanCorrectivo: false,
     planCorrectivoSeleccionado: null,
     estado: false,

     showModalNuevoPlanCorrectivo: false,
     fetchingNuevoPlanCorrectivo: false,

     showModalEditarPlanCorrectivo: false,
     fetchingEditarPlanCorrectivo: false,

     listaMesesActivos: [],
     listaFoliosSolicitudes: [],
     listaFoliosEstandares: [],
     listaEstandaresDesempeñoAuditorias: [],
     listaEstandaresDesempeño: [],

     fetchingListaMesesActivos: false,
     fetchingListaFoliosSolicitudes: false,
     fetchingListaFoliosEstandares: false,
     fetchingListaEstandaresDesempeñoAuditorias: false,
     fetchingListaEstandaresDesempeño: false,

     showModalListaActividades: false,
     listaActividades: [],

     //Ponderadores para encuesta de satisfacción
     fetchingListaPonderadoresEncuesta: false,
     listaPonderadoresEncuesta: [],
     listaPonderadoresEncuestaCantidad: 0,

     fetchingAñosPonderadoresEncuesta: false,
     listaAñosPonderadoresEncuesta: [],

     fetchingRepetirPonderadoresEncuesta: false,
     showModalNuevoPonderadorEncuesta: false,
     fetchingNuevoPonderadorEncuesta: false,

     showModalEditarPonderadorEncuesta: false,
     ponderadorEncuestaSeleccionado: null,
     fetchingEditarPonderadorEncuesta: false,

     //Emisión de encuestas de satisfacción
     fetchingListaAñosPreguntas: false,
     listaAñosPregunta: [],

     fetchingListaPreguntasEncuesta: false,
     listaPreguntasEncuesta: [],
     listaPreguntasEncuestaCantidad: 0,

     showModalNuevaPreguntaEncuesta: false,
     fetchingNuevaPreguntaEncuesta: false,

     showModalEditarPreguntaEncuesta: false,
     preguntaEncuestaSeleccionado: null,
     fetchingEditarPreguntaEncuesta: false,

     fetchingNuevoFolioEncuesta: false,

     //Registro de Encuestas de Satisfacción
     fetchingListaMesesFolio: false,
     listaMesesFolio: [],

     fetchingListaFoliosBusqueda: false,
     listaFoliosBusqueda: null,
     listaFoliosBusquedaCantidad: 0,

     showModalRegistrarEncuesta: false,
     fetchingRegistrarEncuesta: false,
     folioSeleccionado: null,

     showModalDetalleFolio: false,

     // Reportes de encuestas de satisfacción 
     informacionReporte: [],
     fetchingInformacionReporte: false,
     fetchingReporteEncuestasPDF: false,
     columnsReport: [],
     showModalComparativos: false,
     tipoReporteSeleccionado: '', infoReporteSeleccionado: [],
     fetchingGeneralReport: false,
     fetchingComparativaServicioPDF: false,
     informacionPrimerComparativo: [],
     fetchingPrimerComparativo: false,
     informacionSegundoComparativo: [],
     fetchingSegundoComparativo: false,
     informacionReporteCantidad: null,
     tendenciaAnualGraph: {},
     promediosMensualesGraph: {},

     //Reporte Dinámico de Encuestas de Satisfacción
     fetchingReporteDinamicoES: false,
     listaReporteDinamicoES: [],
     listaReporteDinamicoESCantidad: 0,
     fetchingReporteDinamicoAgrupadoES: false,
     listaReporteDinamicoAgrupadoES: [],
     listaReporteDinamicoAgrupadoESCantidad: 0,
     fetchingReporteDinamicoPDF: false,
     fetchingReporteDinamicoExcel: false,

     //Cumplimiento de Estándares
     fetchingListaCumplimientosEstandares: false,
     listaCumplimientosEstandares: [],
     listaCumplimientosEstandaresCantidad: 0,
     listaSeleccionados: [],
     selectedRowKeys: [],
     fetchingCrearFolioEstandar: false,

     fetchingListaFoliosEstandaresEvaluar: false,
     listaFoliosEstandaresEvaluar: [],
     listaFoliosEstandaresEvaluarCantidad: 0,

     showModalEvaluarFolioEstandar: false,
     folioEstandarSeleccionado: null,
     fetchingEvaluarFolioEstandar: false,

     fetchingListaFoliosEstandaresEvaluados: false,
     listaFoliosEstandaresEvaluados: [],
     listaFoliosEstandaresEvaluadosCantidad: 0,

     showModalDetalleFolioEvaluado: false,

     // Reportes de desempeño de estandares generales
     fetchingListaFoliosEstandaresDesempeno: false,
     listaFoliosEstandaresDesempeno: [],
     listaFoliosEstandaresDesempenoCantidad: 0,

     showModalEstandaresDesempeno: false,
     folioEstandarDesempenoSeleccionado: null,

     fetchingListaEstandaresPorFolio: false,
     listaEstandaresPorFolio: [],
     listaEstandaresPorFolioCantidad: 0,

     showModalPDFEstandaresDesempeno: false,
     rutaArchivoEstandaresDesempeno: null,

     showModalUploadPDFPorEstandar: false,
     idCumplimientoEstandar: null,
     auxDataPDFPorEstandar: null,

     fetchingSubirArchivoPorEstandar: false,

     fetchingListaGerenciasByServicio: false,
     listaGerenciasByServicio: [],

     fetchingListaObjetivosByGerencia: false,
     listaObjetivosByGerencia: [],

     fetchingListaServiciosEvaluacionesDesempeno: false,
     listaServiciosEvaluacionesDesempeno: [],

     showModalEvaluacionDesempeno: false,
     modalEvaluacionDesempenoData: null,

     fetchingListaIndicadoresByGerenciaObjetibo: false,
     listaIndicadoresByGerenciaObjetibo: [],

     fetchingCrearEvaluacionDeDesempeno: false,

     fetchingListaMesesEvaluacion: false,
     listaMesesEvaluacion: [],

     fetchingListaEvaluacionesDesempeno: false,
     listaEvaluacionesDesempeno: [],
     listaEvaluacionesDesempenoCantidad: 0,
}

export default (state = initialState, action) => {
     switch (action.type) {
          // PLAN DE CALIDAD
          case GestionDeCalidadActions.LISTA_PLANES_CALIDAD_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaPlanesCalidadBusqueda: true };
          case GestionDeCalidadActions.LISTA_PLANES_CALIDAD_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaPlanesCalidadBusqueda: false, listaPlanesCalidadBusqueda: action.listaPlanesCalidadBusqueda, listaPlanesCalidadBusquedaCantidad: action.listaPlanesCalidadBusquedaCantidad };
          case GestionDeCalidadActions.LISTA_PLANES_CALIDAD_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaPlanesCalidadBusqueda: false };

          case GestionDeCalidadActions.LISTA_AÑOS_PLAN_CALIDAD_REQUEST:
               return { ...state, fetchingListaPlanCalidadAños: true };
          case GestionDeCalidadActions.LISTA_AÑOS_PLAN_CALIDAD_SUCCESS:
               return { ...state, fetchingListaPlanCalidadAños: false, listaPlanCalidadAños: action.listaPlanCalidadAños };
          case GestionDeCalidadActions.LISTA_AÑOS_PLAN_CALIDAD_FAILURE:
               return { ...state, fetchingListaPlanCalidadAños: false };

          case GestionDeCalidadActions.ESTADO_PLAN_CALIDAD_REQUEST:
               return { ...state, fetchingListaPlanesCalidadBusqueda: true };
          case GestionDeCalidadActions.ESTADO_PLAN_CALIDAD_SUCCESS:
               return { ...state, fetchingListaPlanesCalidadBusqueda: false };
          case GestionDeCalidadActions.ESTADO_PLAN_CALIDAD_FAILURE:
               return { ...state, fetchingListaPlanesCalidadBusqueda: false };

          case GestionDeCalidadActions.MODAL_VER_PLAN_CALIDAD:
               return { ...state, showModalVerPlanCalidad: action.showModalVerPlanCalidad, planCalidadSeleccionado: action.planCalidadSeleccionado };

          case GestionDeCalidadActions.MODAL_NUEVO_PLAN_CALIDAD:
               return { ...state, showModalNuevoPlanCalidad: action.showModalNuevoPlanCalidad, page: action.page, busqueda: action.busqueda };

          case GestionDeCalidadActions.NUEVO_PLAN_CALIDAD_REQUEST:
               return { ...state, fetchingNuevoPlanCalidad: true };
          case GestionDeCalidadActions.NUEVO_PLAN_CALIDAD_SUCCESS:
               return { ...state, fetchingNuevoPlanCalidad: false };
          case GestionDeCalidadActions.NUEVO_PLAN_CALIDAD_FAILURE:
               return { ...state, fetchingNuevoPlanCalidad: false };

          case GestionDeCalidadActions.MODAL_EDITAR_PLAN_CALIDAD:
               return { ...state, showModalEditarPlanCalidad: action.showModalEditarPlanCalidad, planCalidadSeleccionado: action.planCalidadSeleccionado, page: action.page, busqueda: action.busqueda };

          case GestionDeCalidadActions.EDITAR_PLAN_CALIDAD_REQUEST:
               return { ...state, fetchingEditarPlanCalidad: true };
          case GestionDeCalidadActions.EDITAR_PLAN_CALIDAD_SUCCESS:
               return { ...state, fetchingEditarPlanCalidad: false };
          case GestionDeCalidadActions.EDITAR_PLAN_CALIDAD_FAILURE:
               return { ...state, fetchingEditarPlanCalidad: false };


          // PLANES CORRECTIVOS
          case GestionDeCalidadActions.LISTA_PLANES_CORRECTIVOS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaPlanesCorrectivosBusqueda: true };
          case GestionDeCalidadActions.LISTA_PLANES_CORRECTIVOS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaPlanesCorrectivosBusqueda: false, listaPlanesCorrectivosBusqueda: action.listaPlanesCorrectivosBusqueda, listaPlanesCorrectivosBusquedaCantidad: action.listaPlanesCorrectivosBusquedaCantidad };
          case GestionDeCalidadActions.LISTA_PLANES_CORRECTIVOS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaPlanesCorrectivosBusqueda: false };

          case GestionDeCalidadActions.MODAL_VER_PLAN_CORRECTIVO:
               return { ...state, showModalVerPlanCorrectivo: action.showModalVerPlanCorrectivo, planCorrectivoSeleccionado: action.planCorrectivoSeleccionado };

          case GestionDeCalidadActions.MODAL_NUEVO_PLAN_CORRECTIVO:
               return { ...state, showModalNuevoPlanCorrectivo: action.showModalNuevoPlanCorrectivo, page: action.page, busqueda: action.busqueda, orden: action.orden, estado: action.estado };

          case GestionDeCalidadActions.NUEVO_PLAN_CORRECTIVO_REQUEST:
               return { ...state, fetchingNuevoPlanCorrectivo: true };
          case GestionDeCalidadActions.NUEVO_PLAN_CORRECTIVO_SUCCESS:
               return { ...state, fetchingNuevoPlanCorrectivo: false };
          case GestionDeCalidadActions.NUEVO_PLAN_CORRECTIVO_FAILURE:
               return { ...state, fetchingNuevoPlanCorrectivo: false };

          case GestionDeCalidadActions.MODAL_EDITAR_PLAN_CORRECTIVO:
               return { ...state, showModalEditarPlanCorrectivo: action.showModalEditarPlanCorrectivo, planCorrectivoSeleccionado: action.planCorrectivoSeleccionado, page: action.page, busqueda: action.busqueda, orden: action.orden, estado: action.estado };

          case GestionDeCalidadActions.EDITAR_PLAN_CORRECTIVO_REQUEST:
               return { ...state, fetchingEditarPlanCorrectivo: true };
          case GestionDeCalidadActions.EDITAR_PLAN_CORRECTIVO_SUCCESS:
               return { ...state, fetchingEditarPlanCorrectivo: false };
          case GestionDeCalidadActions.EDITAR_PLAN_CORRECTIVO_FAILURE:
               return { ...state, fetchingEditarPlanCorrectivo: false };

          case GestionDeCalidadActions.LISTA_MESES_ACTIVOS_REQUEST:
               return { ...state, fetchingListaMesesActivos: true };
          case GestionDeCalidadActions.LISTA_MESES_ACTIVOS_SUCCESS:
               return { ...state, fetchingListaMesesActivos: false, listaMesesActivos: action.listaMesesActivos};
          case GestionDeCalidadActions.LISTA_MESES_ACTIVOS_FAILURE:
               return { ...state, fetchingListaMesesActivos: false };

          case GestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_REQUEST:
               return { ...state, fetchingListaFoliosEstandares: true };
          case GestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_SUCCESS:
               return { ...state, fetchingListaFoliosEstandares: false, listaFoliosEstandares: action.listaFoliosEstandares };
          case GestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_FAILURE:
               return { ...state, fetchingListaFoliosEstandares: false };

          case GestionDeCalidadActions.LISTA_FOLIOS_SOLICITUDES_REQUEST:
               return { ...state, fetchingListaFoliosSolicitudes: true };
          case GestionDeCalidadActions.LISTA_FOLIOS_SOLICITUDES_SUCCESS:
               return { ...state, fetchingListaFoliosSolicitudes: false, listaFoliosSolicitudes: action.listaFoliosSolicitudes };
          case GestionDeCalidadActions.LISTA_FOLIOS_SOLICITUDES_FAILURE:
               return { ...state, fetchingListaFoliosSolicitudes: false };

          case GestionDeCalidadActions.LISTA_ESTANDARES_DESEMPENO_AUDITORIAS_REQUEST:
               return { ...state, fetchingListaEstandaresDesempeñoAuditorias: true };
          case GestionDeCalidadActions.LISTA_ESTANDARES_DESEMPENO_AUDITORIAS_SUCCESS:
               return { ...state, fetchingListaEstandaresDesempeñoAuditorias: false, listaEstandaresDesempeñoAuditorias: action.listaEstandaresDesempeñoAuditorias };
          case GestionDeCalidadActions.LISTA_ESTANDARES_DESEMPENO_AUDITORIAS_FAILURE:
               return { ...state, fetchingListaEstandaresDesempeñoAuditorias: false };

          case GestionDeCalidadActions.LISTA_ESTANDARES_DESEMPENO_REQUEST:
               return { ...state, fetchingListaEstandaresDesempeño: true };
          case GestionDeCalidadActions.LISTA_ESTANDARES_DESEMPENO_SUCCESS:
               return { ...state, fetchingListaEstandaresDesempeño: false, listaEstandaresDesempeño: action.listaEstandaresDesempeño };
          case GestionDeCalidadActions.LISTA_ESTANDARES_DESEMPENO_FAILURE:
               return { ...state, fetchingListaEstandaresDesempeño: false };

          //PONDERADORES PARA ENCUESTA DE SATISFACCIÓN
          case GestionDeCalidadActions.LISTA_PONDERADORES_ENCUESTA_REQUEST:
               return { ...state, fetchingListaPonderadoresEncuesta: true };
          case GestionDeCalidadActions.LISTA_PONDERADORES_ENCUESTA_SUCCESS:
               return { ...state, fetchingListaPonderadoresEncuesta: false, listaPonderadoresEncuesta: action.listaPonderadoresEncuesta, listaPonderadoresEncuestaCantidad: action.listaPonderadoresEncuestaCantidad };
          case GestionDeCalidadActions.LISTA_PONDERADORES_ENCUESTA_FAILURE:
               return { ...state, fetchingListaPonderadoresEncuesta: false };

          case GestionDeCalidadActions.REPETIR_PONDERADORES_ENCUESTA_REQUEST:
               return { ...state, fetchingRepetirPonderadoresEncuesta: true };
          case GestionDeCalidadActions.REPETIR_PONDERADORES_ENCUESTA_SUCCESS:
               return { ...state, fetchingRepetirPonderadoresEncuesta: false };
          case GestionDeCalidadActions.REPETIR_PONDERADORES_ENCUESTA_FAILURE:
               return { ...state, fetchingRepetirPonderadoresEncuesta: false };

          case GestionDeCalidadActions.AÑOS_PONDERADORES_ENCUESTA_REQUEST:
               return { ...state, fetchingAñosPonderadoresEncuesta: true };
          case GestionDeCalidadActions.AÑOS_PONDERADORES_ENCUESTA_SUCCESS:
               return { ...state, fetchingAñosPonderadoresEncuesta: false, listaAñosPonderadoresEncuesta: action.listaAñosPonderadoresEncuesta };
          case GestionDeCalidadActions.AÑOS_PONDERADORES_ENCUESTA_FAILURE:
               return { ...state, fetchingAñosPonderadoresEncuesta: false };

          case GestionDeCalidadActions.ESTADO_PONDERADOR_ENCUESTA_REQUEST:
               return { ...state, fetchingListaPonderadoresEncuesta: true };
          case GestionDeCalidadActions.ESTADO_PONDERADOR_ENCUESTA_SUCCESS:
               return { ...state, fetchingListaPonderadoresEncuesta: false };
          case GestionDeCalidadActions.ESTADO_PONDERADOR_ENCUESTA_FAILURE:
               return { ...state, fetchingListaPonderadoresEncuesta: false };

          case GestionDeCalidadActions.MODAL_NUEVO_PONDERADOR_ENCUESTA:
               return { ...state, showModalNuevoPonderadorEncuesta: action.showModalNuevoPonderadorEncuesta, page: action.page };

          case GestionDeCalidadActions.NUEVO_PONDERADOR_ENCUESTA_REQUEST:
               return { ...state, fetchingNuevoPonderadorEncuesta: true };
          case GestionDeCalidadActions.NUEVO_PONDERADOR_ENCUESTA_SUCCESS:
               return { ...state, fetchingNuevoPonderadorEncuesta: false };
          case GestionDeCalidadActions.NUEVO_PONDERADOR_ENCUESTA_FAILURE:
               return { ...state, fetchingNuevoPonderadorEncuesta: false };

          case GestionDeCalidadActions.MODAL_EDITAR_PONDERADOR_ENCUESTA:
               return { ...state, showModalEditarPonderadorEncuesta: action.showModalEditarPonderadorEncuesta, ponderadorEncuestaSeleccionado: action.ponderadorEncuestaSeleccionado, page: action.page };

          case GestionDeCalidadActions.EDITAR_PONDERADOR_ENCUESTA_REQUEST:
               return { ...state, fetchingEditarPonderadorEncuesta: true };
          case GestionDeCalidadActions.EDITAR_PONDERADOR_ENCUESTA_SUCCESS:
               return { ...state, fetchingEditarPonderadorEncuesta: false };
          case GestionDeCalidadActions.EDITAR_PONDERADOR_ENCUESTA_FAILURE:
               return { ...state, fetchingEditarPonderadorEncuesta: false };

          //EMISIÓN DE ENCUESTA DE SATISFACCIÓN
          case GestionDeCalidadActions.LISTA_AÑOS_PREGUNTAS_REQUEST:
               return { ...state, fetchingListaAñosPreguntas: true };
          case GestionDeCalidadActions.LISTA_AÑOS_PREGUNTAS_SUCCESS:
               return { ...state, fetchingListaAñosPreguntas: false, listaAñosPregunta: action.listaAñosPregunta };
          case GestionDeCalidadActions.LISTA_AÑOS_PREGUNTAS_FAILURE:
               return { ...state, fetchingListaAñosPreguntas: false };

          case GestionDeCalidadActions.LISTA_PREGUNTAS_ENCUESTA_REQUEST:
               return { ...state, fetchingListaPreguntasEncuesta: true };
          case GestionDeCalidadActions.LISTA_PREGUNTAS_ENCUESTA_SUCCESS:
               return { ...state, fetchingListaPreguntasEncuesta: false, listaPreguntasEncuesta: action.listaPreguntasEncuesta, listaPreguntasEncuestaCantidad: action.listaPreguntasEncuestaCantidad };
          case GestionDeCalidadActions.LISTA_PREGUNTAS_ENCUESTA_FAILURE:
               return { ...state, fetchingListaPreguntasEncuesta: false };

          case GestionDeCalidadActions.ESTADO_ENCUESTA_REQUEST:
               return { ...state, fetchingListaPreguntasEncuesta: true };
          case GestionDeCalidadActions.ESTADO_ENCUESTA_SUCCESS:
               return { ...state, fetchingListaPreguntasEncuesta: false };
          case GestionDeCalidadActions.ESTADO_ENCUESTA_FAILURE:
               return { ...state, fetchingListaPreguntasEncuesta: false };

          case GestionDeCalidadActions.MODAL_NUEVA_PREGUNTA_ENCUESTA:
               return { ...state, showModalNuevaPreguntaEncuesta: action.showModalNuevaPreguntaEncuesta, page: action.page, busqueda: action.busqueda };

          case GestionDeCalidadActions.NUEVA_PREGUNTA_ENCUESTA_REQUEST:
               return { ...state, fetchingNuevaPreguntaEncuesta: true };
          case GestionDeCalidadActions.NUEVA_PREGUNTA_ENCUESTA_SUCCESS:
               return { ...state, fetchingNuevaPreguntaEncuesta: false };
          case GestionDeCalidadActions.NUEVA_PREGUNTA_ENCUESTA_FAILURE:
               return { ...state, fetchingNuevaPreguntaEncuesta: false };

          case GestionDeCalidadActions.MODAL_EDITAR_PREGUNTA_ENCUESTA:
               return { ...state, showModalEditarPreguntaEncuesta: action.showModalEditarPreguntaEncuesta, preguntaEncuestaSeleccionado: action.preguntaEncuestaSeleccionado, page: action.page, busqueda: action.busqueda };

          case GestionDeCalidadActions.EDITAR_PREGUNTA_ENCUESTA_REQUEST:
               return { ...state, fetchingEditarPreguntaEncuesta: true };
          case GestionDeCalidadActions.EDITAR_PREGUNTA_ENCUESTA_SUCCESS:
               return { ...state, fetchingEditarPreguntaEncuesta: false };
          case GestionDeCalidadActions.EDITAR_PREGUNTA_ENCUESTA_FAILURE:
               return { ...state, fetchingEditarPreguntaEncuesta: false };

          case GestionDeCalidadActions.NUEVO_FOLIO_ENCUESTA_REQUEST:
               return { ...state, fetchingNuevoFolioEncuesta: true };
          case GestionDeCalidadActions.NUEVO_FOLIO_ENCUESTA_SUCCESS:
               return { ...state, fetchingNuevoFolioEncuesta: false };
          case GestionDeCalidadActions.NUEVO_FOLIO_ENCUESTA_FAILURE:
               return { ...state, fetchingNuevoFolioEncuesta: false };

          case GestionDeCalidadActions.EXPORTAR_FOLIO_ENCUESTA_REQUEST:
               return { ...state, fetchingNuevoFolioEncuesta: true };
          case GestionDeCalidadActions.EXPORTAR_FOLIO_ENCUESTA_SUCCESS:
               return { ...state, fetchingNuevoFolioEncuesta: false };
          case GestionDeCalidadActions.EXPORTAR_FOLIO_ENCUESTA_FAILURE:
               return { ...state, fetchingNuevoFolioEncuesta: false };

          //REGISTRO DE ENCUESTAS DE SATISFACCIÓN
          case GestionDeCalidadActions.LISTA_MESES_FOLIO_REQUEST:
               return { ...state, fetchingListaMesesFolio: true };
          case GestionDeCalidadActions.LISTA_MESES_FOLIO_SUCCESS:
               return { ...state, fetchingListaMesesFolio: false, listaMesesFolio: action.listaMesesFolio };
          case GestionDeCalidadActions.LISTA_MESES_FOLIO_FAILURE:
               return { ...state, fetchingListaMesesFolio: false };

          case GestionDeCalidadActions.LISTA_FOLIOS_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaFoliosBusqueda: true };
          case GestionDeCalidadActions.LISTA_FOLIOS_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaFoliosBusqueda: false, listaFoliosBusqueda: action.listaFoliosBusqueda, listaFoliosBusquedaCantidad: action.listaFoliosBusquedaCantidad };
          case GestionDeCalidadActions.LISTA_FOLIOS_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaFoliosBusqueda: false };

          case GestionDeCalidadActions.MODAL_REGISTRAR_ENCUESTA:
               return { ...state, showModalRegistrarEncuesta: action.showModalRegistrarEncuesta, folioSeleccionado: action.folioSeleccionado, page: action.page, busqueda: action.busqueda };

          case GestionDeCalidadActions.REGISTRAR_ENCUESTA_REQUEST:
               return { ...state, fetchingRegistrarEncuesta: true };
          case GestionDeCalidadActions.REGISTRAR_ENCUESTA_SUCCESS:
               return { ...state, fetchingRegistrarEncuesta: false };
          case GestionDeCalidadActions.REGISTRAR_ENCUESTA_FAILURE:
               return { ...state, fetchingRegistrarEncuesta: false };

          case GestionDeCalidadActions.EVALUAR_ENCUESTA_REQUEST:
               return { ...state, fetchingListaFoliosBusqueda: true };
          case GestionDeCalidadActions.EVALUAR_ENCUESTA_SUCCESS:
               return { ...state, fetchingListaFoliosBusqueda: false };
          case GestionDeCalidadActions.EVALUAR_ENCUESTA_FAILURE:
               return { ...state, fetchingListaFoliosBusqueda: false };

          case GestionDeCalidadActions.MODAL_DETALLE_FOLIO:
               return { ...state, showModalDetalleFolio: action.showModalDetalleFolio, folioSeleccionado: action.folioSeleccionado };

          // Reportes de encuestas de satisfacción
          case GestionDeCalidadActions.OBTENER_INFORMACION_REPORTE_REQUEST:
               return { ...state, fetchingInformacionReporte: true };
          case GestionDeCalidadActions.OBTENER_INFORMACION_REPORTE_SUCCESS:
               return { ...state, fetchingInformacionReporte: false, informacionReporte: action.informacionReporte, columnsReport: action.columnsReport, informacionReporteCantidad: action.informacionReporteCantidad, tendenciaAnualGraph: action.tendenciaAnualGraph, promediosMensualesGraph: action.promediosMensualesGraph };
          case GestionDeCalidadActions.OBTENER_INFORMACION_REPORTE_FAILURE:
               return { ...state, fetchingInformacionReporte: false };

          case GestionDeCalidadActions.OBTENER_INFORMACION_REPORTE_PDF_REQUEST:
               return { ...state, fetchingReporteEncuestasPDF: true };
          case GestionDeCalidadActions.OBTENER_INFORMACION_REPORTE_PDF_SUCCESS:
               return { ...state, fetchingReporteEncuestasPDF: false };
          case GestionDeCalidadActions.OBTENER_INFORMACION_REPORTE_PDF_FAILURE:
               return { ...state, fetchingReporteEncuestasPDF: false };

          case GestionDeCalidadActions.SHOW_MODAL_COMPARATIVOS:
               return { ...state, showModalComparativos: action.showModalComparativos, tipoReporteSeleccionado: action.tipoReporteSeleccionado, infoReporteSeleccionado: action.infoReporteSeleccionado };

          case GestionDeCalidadActions.GET_GENERAL_REPORT_PDF_REQUEST:
               return { ...state, fetchingGeneralReport: true };
          case GestionDeCalidadActions.GET_GENERAL_REPORT_PDF_SUCCESS:
               return { ...state, fetchingGeneralReport: false };
          case GestionDeCalidadActions.GET_GENERAL_REPORT_PDF_FAILURE:
               return { ...state, fetchingGeneralReport: false };

          case GestionDeCalidadActions.COMPARATIVO_SERVICIO_PDF_REQUEST:
               return { ...state, fetchingComparativaServicioPDF: true };
          case GestionDeCalidadActions.COMPARATIVO_SERVICIO_PDF_SUCCESS:
               return { ...state, fetchingComparativaServicioPDF: false };
          case GestionDeCalidadActions.COMPARATIVO_SERVICIO_PDF_FAILURE:
               return { ...state, fetchingComparativaServicioPDF: false };
          
          case GestionDeCalidadActions.OBTENER_INFORMACION_COMPARATIVO1_REQUEST:
               return { ...state, fetchingPrimerComparativo: true };
          case GestionDeCalidadActions.OBTENER_INFORMACION_COMPARATIVO1_SUCCESS:
               return { ...state, fetchingPrimerComparativo: false, informacionPrimerComparativo: action.informacionPrimerComparativo };
          case GestionDeCalidadActions.OBTENER_INFORMACION_COMPARATIVO1_FAILURE:
               return { ...state, fetchingPrimerComparativo: false };

          case GestionDeCalidadActions.OBTENER_INFORMACION_COMPARATIVO2_REQUEST:
               return { ...state, fetchingSegundoComparativo: true };
          case GestionDeCalidadActions.OBTENER_INFORMACION_COMPARATIVO2_SUCCESS:
               return { ...state, fetchingSegundoComparativo: false, informacionSegundoComparativo: action.informacionSegundoComparativo };
          case GestionDeCalidadActions.OBTENER_INFORMACION_COMPARATIVO2_FAILURE:
               return { ...state, fetchingSegundoComparativo: false };

          //REPORTE DINÁMICO PARA ENCUESTAS DE SATISFACCIÓN
          case GestionDeCalidadActions.REPORTE_DINAMICO_ES_REQUEST:
               return { ...state, fetchingReporteDinamicoES: true, formularioReporte: action.formulario };
          case GestionDeCalidadActions.REPORTE_DINAMICO_ES_SUCCESS:
               return { ...state, fetchingReporteDinamicoES: false, listaReporteDinamicoES: action.listaReporteDinamicoES, listaReporteDinamicoESCantidad: action.listaReporteDinamicoESCantidad };
          case GestionDeCalidadActions.REPORTE_DINAMICO_ES_FAILURE:
               return { ...state, fetchingReporteDinamicoES: false };

          case GestionDeCalidadActions.REPORTE_DINAMICO_AGRUPADO_ES_REQUEST:
               return { ...state, fetchingReporteDinamicoAgrupadoES: true, formularioReporte: action.formulario };
          case GestionDeCalidadActions.REPORTE_DINAMICO_AGRUPADO_ES_SUCCESS:
               return { ...state, fetchingReporteDinamicoAgrupadoES: false, listaReporteDinamicoAgrupadoES: action.listaReporteDinamicoAgrupadoES, listaReporteDinamicoAgrupadoESCantidad: action.listaReporteDinamicoAgrupadoESCantidad };
          case GestionDeCalidadActions.REPORTE_DINAMICO_AGRUPADO_ES_FAILURE:
               return { ...state, fetchingReporteDinamicoAgrupadoES: false };
               
          case GestionDeCalidadActions.PDF_REPORTE_DINAMICO_ES_REQUEST:
               return { ...state, fetchingReporteDinamicoPDF: true };
          case GestionDeCalidadActions.PDF_REPORTE_DINAMICO_ES_SUCCESS:
               return { ...state, fetchingReporteDinamicoPDF: false };
          case GestionDeCalidadActions.PDF_REPORTE_DINAMICO_ES_FAILURE:
               return { ...state, fetchingReporteDinamicoPDF: false };

          case GestionDeCalidadActions.EXCEL_REPORTE_DINAMICO_ES_REQUEST:
               return { ...state, fetchingReporteDinamicoExcel: true };
          case GestionDeCalidadActions.EXCEL_REPORTE_DINAMICO_ES_SUCCESS:
               return { ...state, fetchingReporteDinamicoExcel: false };
          case GestionDeCalidadActions.EXCEL_REPORTE_DINAMICO_ES_FAILURE:
               return { ...state, fetchingReporteDinamicoExcel: false };

          case GestionDeCalidadActions.LIMPIAR_FORMULARIO_REPORTE_ES:
               return { ...state, formularioReporte : action.formularioReporte, listaReportesDinamicoES: action.listaReportesDinamicoES, listaReportesDinamicoESCantidad: action.listaReportesDinamicoESCantidad};

          // CUMPLIMIENTO DE ESTÁNDARES
          case GestionDeCalidadActions.LISTA_CUMPLIMIENTO_ESTANDARES_REQUEST:
               return { ...state, fetchingListaCumplimientosEstandares: true };
          case GestionDeCalidadActions.LISTA_CUMPLIMIENTO_ESTANDARES_SUCCESS:
               return { ...state, fetchingListaCumplimientosEstandares: false, listaCumplimientosEstandares: action.listaCumplimientosEstandares, listaCumplimientosEstandaresCantidad: action.listaCumplimientosEstandaresCantidad };
          case GestionDeCalidadActions.LISTA_CUMPLIMIENTO_ESTANDARES_FAILURE:
               return { ...state, fetchingListaCumplimientosEstandares: false };  
               
          case GestionDeCalidadActions.CREAR_FOLIO_ESTANDAR_REQUEST:
               return { ...state, fetchingCrearFolioEstandar: true };
          case GestionDeCalidadActions.CREAR_FOLIO_ESTANDAR_SUCCESS:
               return { ...state, fetchingCrearFolioEstandar: false };
          case GestionDeCalidadActions.CREAR_FOLIO_ESTANDAR_FAILURE:
               return { ...state, fetchingCrearFolioEstandar: false };
          
          case GestionDeCalidadActions.EXPORTAR_FOLIO_ESTANDAR_REQUEST:
               return { ...state, fetchingCrearFolioEstandar: true };
          case GestionDeCalidadActions.EXPORTAR_FOLIO_ESTANDAR_SUCCESS:
               return { ...state, fetchingCrearFolioEstandar: false };
          case GestionDeCalidadActions.EXPORTAR_FOLIO_ESTANDAR_FAILURE:
               return { ...state, fetchingCrearFolioEstandar: false };
     
          case GestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_EVALUAR_REQUEST:
               return { ...state, fetchingListaFoliosEstandaresEvaluar: true };
          case GestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_EVALUAR_SUCCESS:
               return { ...state, fetchingListaFoliosEstandaresEvaluar: false, listaFoliosEstandaresEvaluar: action.listaFoliosEstandaresEvaluar, listaFoliosEstandaresEvaluarCantidad: action.listaFoliosEstandaresEvaluarCantidad };
          case GestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_EVALUAR_FAILURE:
               return { ...state, fetchingListaFoliosEstandaresEvaluar: false };     

          case GestionDeCalidadActions.MODAL_EVALUAR_FOLIO_ESTANDAR:
               return { ...state, showModalEvaluarFolioEstandar: action.showModalEvaluarFolioEstandar, page: action.page, folioEstandarSeleccionado: action.folioEstandarSeleccionado };

          case GestionDeCalidadActions.EVALUAR_FOLIO_ESTANDAR_REQUEST:
               return { ...state, fetchingEvaluarFolioEstandar: true };
          case GestionDeCalidadActions.EVALUAR_FOLIO_ESTANDAR_SUCCESS:
               return { ...state, fetchingEvaluarFolioEstandar: false };
          case GestionDeCalidadActions.EVALUAR_FOLIO_ESTANDAR_FAILURE:
               return { ...state, fetchingEvaluarFolioEstandar: false };

          case GestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_EVALUADOS_REQUEST:
               return { ...state, fetchingListaFoliosEstandaresEvaluados: true };
          case GestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_EVALUADOS_SUCCESS:
               return { ...state, fetchingListaFoliosEstandaresEvaluados: false, listaFoliosEstandaresEvaluados: action.listaFoliosEstandaresEvaluados, listaFoliosEstandaresEvaluadosCantidad: action.listaFoliosEstandaresEvaluadosCantidad };
          case GestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_EVALUADOS_FAILURE:
               return { ...state, fetchingListaFoliosEstandaresEvaluados: false };

          case GestionDeCalidadActions.MODAL_DETALLE_FOLIO_EVALUADO:
               return { ...state, showModalDetalleFolioEvaluado: action.showModalDetalleFolioEvaluado, page: action.page, folioEstandarSeleccionado: action.folioEstandarSeleccionado };


          // Reportes de desempeño de estandares generales
          case GestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_DESEMPENO_REQUEST:
               return { ...state, fetchingListaFoliosEstandaresDesempeno: true };
          case GestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_DESEMPENO_SUCCESS:
               return { ...state, fetchingListaFoliosEstandaresDesempeno: false, listaFoliosEstandaresDesempeno: action.listaFoliosEstandaresDesempeno, listaFoliosEstandaresDesempenoCantidad: action.listaFoliosEstandaresDesempenoCantidad };
          case GestionDeCalidadActions.LISTA_FOLIOS_ESTANDARES_DESEMPENO_FAILURE:
               return { ...state, fetchingListaFoliosEstandaresDesempeno: false };

          case GestionDeCalidadActions.MODAL_SHOW_ESTANDARES:
               return { ...state, showModalEstandaresDesempeno: action.showModalEstandaresDesempeno, folioEstandarDesempenoSeleccionado: action.folioEstandarDesempenoSeleccionado };


          case GestionDeCalidadActions.LISTA_ESTANDARES_POR_FOLIO_REQUEST:
               return { ...state, fetchingListaEstandaresPorFolio: true };
          case GestionDeCalidadActions.LISTA_ESTANDARES_POR_FOLIO_SUCCESS:
               return { ...state, fetchingListaEstandaresPorFolio: false, listaEstandaresPorFolio: action.listaEstandaresPorFolio, listaEstandaresPorFolioCantidad: action.listaEstandaresPorFolioCantidad };
          case GestionDeCalidadActions.LISTA_ESTANDARES_POR_FOLIO_FAILURE:
               return { ...state, fetchingListaEstandaresPorFolio: false };

          case GestionDeCalidadActions.MODAL_SHOW_PDF_ESTANDARES_DESEMPENO:
               return { ...state, showModalPDFEstandaresDesempeno: action.showModalPDFEstandaresDesempeno, rutaArchivoEstandaresDesempeno: action.rutaArchivoEstandaresDesempeno };

          case GestionDeCalidadActions.MODAL_UPLOAD_PDF_POR_ESTANDAR:
               return { ...state, showModalUploadPDFPorEstandar: action.showModalUploadPDFPorEstandar, idCumplimientoEstandar: action.idCumplimientoEstandar, auxDataPDFPorEstandar: action.auxDataPDFPorEstandar };

          case GestionDeCalidadActions.SUBIR_ARCHIVO_POR_ESTANDAR_REQUEST:
               return { ...state, fetchingSubirArchivoPorEstandar: true };
          case GestionDeCalidadActions.SUBIR_ARCHIVO_POR_ESTANDAR_SUCCESS:
               return { ...state, fetchingSubirArchivoPorEstandar: false};
          case GestionDeCalidadActions.SUBIR_ARCHIVO_POR_ESTANDAR_FAILURE:
               return { ...state, fetchingSubirArchivoPorEstandar: false };

          case GestionDeCalidadActions.MODAL_LISTA_ACTIVIDADES:
               return { ...state, showModalListaActividades: action.showModalListaActividades, listaActividades: action.listaActividades };


          default:
               return state;
     }
}
