import React from 'react';
import { message, Form, Row, Col, Modal, Select } from 'antd';
import { connect } from "react-redux";
import { CheckCircleOutlined } from '@ant-design/icons';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const { Option } = Select;

const layout = {
     labelCol: { span: 24 },
     wrapperCol: { span: 24 },
 };

class FolioCreado extends React.Component {
    formRef = React.createRef();

     componentDidMount() {
          
     }

     state = {

     }


     render() {
          const { 
               limpiarFormulario, 
               onLimpiarFormulario,
               showModalFolioCreado,
               onCloseModal,
               folioInventarioGenerado,
               onLimpiarFolio,
          } = this.props;

          const onFinish = formulario => {              
               console.log('Success:', formulario);
          };

          if (limpiarFormulario && limpiarFormulario === 'inventarioFisico') {
               onLimpiarFormulario();
          }

          const onFinishFailed = errorInfo => {
               console.log('Failed:', errorInfo);
          };

          const handleCancel = () => {
               this.formRef.current.resetFields();
               onCloseModal();
               onLimpiarFolio();
          };
          
          return (
               <Modal
                    visible={showModalFolioCreado}
                    title={''}
                    onCancel={handleCancel}
                    footer={false}
                    width={'30%'}
               >

                    <Form {...layout}
                         name="formulario"
                         onFinish={onFinish}
                         
                         ref={this.formRef}
                    >

                         <Row justify='space-around'>
                              <Col span={24}>
                                   <div style={{textAlign:"center"}}>
                                        <CheckCircleOutlined spin={false} style={{fontSize: 80, color:"#ff8c62"}}/>
                                   </div>
                              </Col>
                              
                              <Col span={24}>
                                   <br/>
                                   <h3 style={{ textAlign:"center" }}>
                                        <strong style={{ color:"#ff8c62", }}> { folioInventarioGenerado.message } </strong>
                                   </h3>
                              </Col>

                         </Row>

                    </Form>

               </Modal>

          );
     }
}

const mapStateToProps = state => {
     return {
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
          showModalFolioCreado: state.AlmacenesReducer.showModalFolioCreado,
          folioInventarioGenerado: state.AlmacenesReducer.folioInventarioGenerado,
     };
};
const mapDispatchToProps = dispatch => {
     return {
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          },
          onCloseModal: () => {
               dispatch({ type: 'SHOW_MODAL_FOLIO_CREADO', showModalFolioCreado: false });
          },
          onLimpiarFolio: () => {
               dispatch({ type: 'GENERAR_FOLIO_CAPTURA_SUCCESS', folioInventarioGenerado: '' });
          }

     };
};

export default connect(mapStateToProps, mapDispatchToProps)(FolioCreado);
