import React from 'react';
import { Row, Col, Switch, Modal, Table, Button, Input, Tooltip, Typography } from 'antd';
import { EditOutlined, FileSearchOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import '../../../stylesheets/RecursosHumanos/AltaPersonal.css';
import ModalDetalleRutina from './ModalDetalleRutina';
import ModalNuevaRutina from './ModalNuevaRutina';
import ModalEditarRutina from './ModalEditarRutina';

const confirm = Modal.confirm;
const { Search } = Input;
const {Text} = Typography;
class RutinasContainer extends React.Component {
    componentDidMount(){
        this.props.onGetListaRutinas(1);
        this.props.onGetGruposServicios();
        this.props.onGetUnidadesMedida();
    }
    state={
        page: 1,
        busqueda: null, 
    }
    render() {
        const { fetchingListaRutinasBusqueda, listaRutinasBusqueda, onEstadoRutina, onShowModalDetalleRutina,
            onGetListaRutinas, onModalEditarRutina, onModalNuevaRutina, listaRutinasBusquedaCantidad,
            onGetListaServiciosByGrupoServicio, onGetSubfamiliasRutinas
        } = this.props;
        
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        const onBusqueda = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            onGetListaRutinas(1, busqueda);
        }

        const onDetalle = (key) => {
            console.log('key', key);
            
            onShowModalDetalleRutina(key);
        }
        const columns = [{
                title: 'Nombre de RMP',
                dataIndex: 'rutina',
                key: 'rutina',
                width: '22%',
            },{
                title: 'Tipo de RMP',
                dataIndex: 'tipoRutina',
                key: 'tipoRutina',
                width: '15%',
            },{
                title: 'Servicio',
                dataIndex: 'servicio',
                key: 'servicio',
                width: '20%',
            },{
                title: 'Partida',
                dataIndex: 'partida',
                key: 'partida',
                width: '10%',
                render: (text, record) => (
                    text === 'No tiene Partida' ? <Text italic style={{color:'#dddddd'}}>{text}</Text> : <Text>{text}</Text>
                ),
            },{  
                title: 'Frecuencia',
                dataIndex: 'frecuencia',
                key: 'frecuencia',
                width: '10%',
            },{
                title: 'Detalle',
                key: 'detalle',
                width: '7%',
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.detalleDeRutina} onClick={() => onDetalle(record)}><FileSearchOutlined /></a>
                ),
            },{
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                width: '8%',
                align: 'center',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.rutinaActivoInactivo} onChange={() => onCambiarEstado(record)}/>
                ),
            },{
                title: 'Editar',
                key: 'editar',
                width: '8%',
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarRutina} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            }
        ];

        const onEditar = (key) => {
            console.log('personal seleccionado');
            console.log(key);
            const listaInsumos = {
                insumos: key.insumosByIdRutina ? key.insumosByIdRutina : [],
                herramientas: key.herramientasByIdRutina ? key.herramientasByIdRutina : [],
            }
            const listaSinRepetir = key.insumosLista;
            const listaSinRepetirHerramienta = key.herramientasLista;
            onModalEditarRutina(key, this.state.page, this.state.busqueda, key.fechaEspecifica, listaInsumos, listaSinRepetir, listaSinRepetirHerramienta);
            if(key.idGrupoSer !== 'Grupo de Servicios desactivado.'){
                onGetListaServiciosByGrupoServicio(key.servicioByIdServicio.idGrupoSer);
                onGetSubfamiliasRutinas(key.servicioByIdServicio.idServicio);
                this.props.onGetPartidasByIdServicio(key.servicioByIdServicio.idServicio);
            }
        }

        const onCambiarEstado = (key) => {
            console.log('rutina seleccionado');
            console.log(key);

            const page = this.state.page;
            const busqueda = this.state.busqueda;

            confirm({
                title: '¿Estás seguro que deseas cambiar el estado de la rutina "'+ key.rutina + '"?',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoRutina(key.idRutina, page, busqueda);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
            
        }
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onGetListaRutinas(pagination.current, this.state.busqueda);
            
        }
        
        const onNuevoRutina = () => {
            onModalNuevaRutina(this.state.page, this.state.busqueda);
        }

        const scroll = {"x": 1000 | true, "y": 1000};
        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search className='buscarModulos' placeholder="Ingresa nombre, programa, tipo de RMP, clave o frecuencia de RMP a buscar" onSearch={value => onBusqueda(value)} style={{ width: '100%' }}/>
                    </Col>
                    {/* <Col span={5}>
                        <Button onClick={onNuevoRutina} disabled={!permisosUsuario.crearRutina} className='nuevoUsuario'>Nueva RMP</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nueva RMP' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoRutina} disabled={!permisosUsuario.crearRutina} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="idRutina" 
                            columns={columns}
                            dataSource={listaRutinasBusqueda}
                            loading={fetchingListaRutinasBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaRutinasBusquedaCantidad, simple: true, current: this.state.page}}
                            scroll={scroll}
                            showSorterTooltip={true}
                        />         
                    </Col>
                </Row>
                <ModalDetalleRutina/>
                <ModalNuevaRutina/>
                <ModalEditarRutina/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaRutinasBusqueda: state.ServiciosPlanificadosReducer.listaRutinasBusqueda,
        fetchingListaRutinasBusqueda: state.ServiciosPlanificadosReducer.fetchingListaRutinasBusqueda,
        listaRutinasBusquedaCantidad: state.ServiciosPlanificadosReducer.listaRutinasBusquedaCantidad,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetListaRutinas: (page, busqueda) => {
	    	dispatch({ type: "LISTA_RUTINAS_BUSQUEDA_REQUEST", page, busqueda });
        },
        onEstadoRutina: (idRutina, page, busqueda) => {
	    	dispatch({ type: "ESTADO_RUTINA_REQUEST", idRutina, page, busqueda });
        },
        onModalNuevaRutina: (page, busqueda) => {
            dispatch({ type: "SHOW_MODAL_NUEVA_RUTINA", showModalNuevaRutina: true, page, busqueda });
        },
        onModalEditarRutina: (rutinaSeleccionada, page, busqueda, tipoFrecuencia, listaInsumos, listaSinRepetir, listaSinRepetirHerramienta) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_RUTINA", showModalEditarRutina: true, rutinaSeleccionada, page, busqueda, tipoFrecuencia, listaInsumos, listaSinRepetir, listaSinRepetirHerramienta });
        },
        onShowModalDetalleRutina: (rutinaSeleccionada) => {
            dispatch({ type: 'MODAL_DETALLE_RUTINA', showModalDetalleRutina: true, rutinaSeleccionada });
        },
        onGetGruposServicios: () => {
	    	dispatch({ type: "LISTA_GRUPOS_SERVICIOS_REQUEST" });
        },
        onGetUnidadesMedida: () => {
            dispatch({ type: 'LISTA_UNIDADES_MEDICION_ACTIVOS_REQUEST' });
        },
        onGetListaServiciosByGrupoServicio: (idGrupoSer) => {
            dispatch({ type: 'LISTA_SERVICIOS_BY_GRUPO_SERV_REQUEST', idGrupoSer})
        },
        onGetSubfamiliasRutinas: (idServicio) => {
            dispatch({ type: 'LISTA_SUBFAMILIAS_RUTINAS_REQUEST', idServicio });
        },
        onGetPartidasByIdServicio: (idServicio) => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_FOR_FALLAS_REQUEST', idServicio})
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(RutinasContainer);