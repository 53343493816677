import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Card, Form, Tooltip } from 'antd';
import { EditOutlined, PaperClipOutlined, SearchOutlined, FileSearchOutlined, FilePdfFilled, FileExcelFilled, HistoryOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import ModalNuevoPersonal from './ModalNuevoPersonal';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import '../../../stylesheets/RecursosHumanos/AltaPersonal.css';
import ModalArchivoExpediente from '../../InformaciónOperativa/CatalogoDeExpedientes/ModalArchivoExpediente';
import ModalEditarExpediente from '../../InformaciónOperativa/CatalogoDeExpedientes/ModalEditarExpediente';
import ModalEditarPersonal from './ModalEditarPersonal';
import ModalCambiarEstadoPersonal from './ModalCambiarEstadoPersonal';
import ModalHistorialPersonal from './ModalHistorialPersonal';
import imagenNoResult from '../../../assets/no-result-icon.png';

const confirm = Modal.confirm;
class AltaDePersonalContainer extends React.Component {
    componentDidMount(){
        this.props.onGetListaPersonal(1, {}, {});
    }
    state={
        page: 1,
        busqueda: {
            param: null,
            servicio: null,
            estado: true,
        },
        orden: {
            field: null,
            order: null,
        },
        trueSwitch:{
            estado: true,
        },
        mensaje: null,
    }
    addDefaultSrc(ev){
        ev.target.src = imagenNoResult;
    }

    render() {
        const { fetchingListaPersonalBusqueda, listaPersonalBusqueda, onEstadoPersonal, 
            onGetListaPersonal, onModalEditarPersonal, onModalNuevoPersonal, listaPersonalBusquedaCantidad,
            onExportarPersonalCSV, onArchivosExpediente, onModalArchivoExpediente, onModalEditarExpediente,
            onServicioSeleccionado, onModalEstadoPersonal, onExportarPersonalPDF, onModalHistorialPersonal,
            onHistorialPersonal,
        } = this.props;
        
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        const onFinish = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            onGetListaPersonal(1, busqueda, this.state.orden);
        }

        

        const columns = [{
                title: 'Foto',
                dataIndex: 'fotoGrid',
                key: 'fotoGrid',
                width: '15%',
                render: (text, record) => (
                    <img onError={this.addDefaultSrc} className='fotoGrid' src={text} />
                ),
            },{
                title: 'Número de empleado',
                dataIndex: 'numeroEmpleado',
                key: 'numeroEmpleado',
                width: '10%',
                sorter: true,
            },{
                title: 'RFC',
                dataIndex: 'rfc',
                key: 'rfc',
                width: '10%',
            },{
                title: 'Nombre(s)',
                dataIndex: 'nombre',
                key: 'nombre',
                width: '15%',
                sorter: true,
            },{
                title: 'Apellido Paterno',
                dataIndex: 'aPaterno',
                key: 'aPaterno',
                width: '10%',
                sorter: true,
            },{
                title: 'Apellido Materno',
                dataIndex: 'aMaterno',
                key: 'aMaterno',
                width: '10%',
                sorter: true,
            },{  
                title: 'ID Biométrico',
                dataIndex: 'idEmpleadoBiometrico',
                key: 'idEmpleadoBiometrico',
                width: '10%',
            },{    
                title: 'Proveedor',
                dataIndex: 'proveedor',
                key: 'proveedor',
                width: '12%',
                sorter: true,
            },{
                
                title: 'Servicio',
                dataIndex: 'servicio',
                key: 'servicio',
                width: '10%',
                sorter: true,
            },{            
                title: 'Puesto',
                dataIndex: 'puesto',
                key: 'puesto',
                width: '10%',
                sorter: true,
            },{
                title: 'Turno y Horario',
                dataIndex: 'turnoHorario',
                key: 'turnoHorario',
                width: '15%',
                sorter: true,
            },{
                title: 'Ver Archivos',
                key: 'verArchivos',
                width: '8%',
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.verArchivosEnExpedienteDePersonal} onClick={() => onVerArchivo(record)}><FileSearchOutlined /></a>
                ),
            },{
                title: 'Adjuntar Archivo',
                key: 'archivo',
                width: '8%',
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.adjuntarArchivoAExpedienteDePersonal} onClick={() => onAdjuntarArchivo(record)}><PaperClipOutlined /></a>
                ),
            },{
                title: 'Condición',
                dataIndex: 'activo',
                key: 'activo',
                width: '9%',
                sorter: true,
                align: 'center',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.personalActivoInactivo} onChange={() => onCambiarEstado(record)}/>
                ),
            },{
                title: 'Editar',
                key: 'editar',
                width: '7%',
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarInformaciónDelPersonal} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },{
                title: 'Historial',
                key: 'historial',
                width: '7%',
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.historialDeEdicionesDePersonal} onClick={() => onHistorial(record)}><HistoryOutlined /></a>
                ),
            },
        ];
        const onVerArchivo = (key) => {
            console.log(key);
            onArchivosExpediente(key.expedienteByIdExpediente.nombreCarpeta);
            onModalArchivoExpediente(key.expedienteByIdExpediente, 1);
        }

        const onEditar = (key) => {
            console.log('personal seleccionado');
            console.log(key);
            onModalEditarPersonal(key, this.state.page, key.tipoTurno);
            onServicioSeleccionado(key.servicioByIdServicio)
        }

        const onHistorial = (key) => {
            console.log('key', key);
            const formulario = {
                fechaInicio: null,
                fechaFin: null,
            }
            onModalHistorialPersonal(key);
            onHistorialPersonal(key.idPersonal, 1, formulario);
        }

        const onCambiarEstado = (key) => {
            console.log('personal seleccionado');
            console.log(key);

            const page = this.state.page;
            const busqueda = this.state.busqueda;
            const orden = this.state.orden;

            if(key.activo){
                onModalEstadoPersonal(key, page, busqueda, orden);
            }
            else{
                confirm({
                    title: '¿Estás seguro que deseas cambiar el estado del personal "'+ key.nombre + ' ' + key.aPaterno + '"?',
                    content: 'Su razón de baja fue: ' + key.razonBaja,
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                        onEstadoPersonal(key.idPersonal, page, busqueda, orden)
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            }
            
        }
        const handleTableChange = (pagination, filtro, ordenTabla)=>{
            console.log('ordenTabla');
            console.log(ordenTabla);
            this.setState({
                page: pagination.current,
            })
            if(ordenTabla){
                let orden = {
                    field: ordenTabla.columnKey,
                    order:'ASC',
                }
            
                if(ordenTabla.order === 'descend'){
                    orden.order = 'DESC';
                }

                if(ordenTabla.columnKey === 'servicio'){
                    orden.field = 'ser.nombre';
                }
                else if(ordenTabla.columnKey === 'turnoHorario'){
                    orden.field = 'tur.turno';
                }
                else if(ordenTabla.columnKey === 'proveedor'){
                    orden.field = 'pro.razonSocial';
                }
                console.log('orden');
                console.log(orden);
                
                
                onGetListaPersonal(pagination.current, this.state.busqueda, orden);
                this.setState({
                    orden: orden,
                });
            }
            else{
                onGetListaPersonal(pagination.current, this.state.busqueda, this.state.orden);
            }
        }
        
        const onNuevoUsuario = () => {
            onModalNuevoPersonal(this.state.page);
        }

        const onAdjuntarArchivo = (key) => {
            onModalEditarExpediente(key.expedienteByIdExpediente, 1, true);
        }

        const onExportarArchivo = (tipo) => {
            if(tipo === 'PDF'){
                this.setState({
                    visible: true,
                })
            }
            else{
                onExportarPersonalCSV(this.state.busqueda, this.state.orden);
            }
        }
        const onCompleto = () => {
            onExportarPersonalPDF(this.state.busqueda, this.state.orden, 'completo');
        };
        const onReducido = () => {
            onExportarPersonalPDF(this.state.busqueda, this.state.orden, 'reducido');
        };
        const onCancel = () => {
            this.setState({
                visible: false,
            })
        }
        const scroll = {"x": 1700 | true, "y": 1000};
        return (
            <div>
                <Card className="cardCatalogos">
                    <Modal
                        title="Selecciona el tipo de exportación deseado"
                        onCancel={onCancel}
                        visible={this.state.visible}
                        footer={false}
                    >
                        <Row justify='space-around'>
                            <Col span={10}>
                                <Button className='nuevoUsuario' style={{width: '100%'}} onClick={onReducido}><FilePdfFilled/> Reducido</Button>
                            </Col>
                            <Col span={10}>
                                <Button className='nuevoUsuario' style={{width: '100%'}} onClick={onCompleto}><FilePdfFilled/> Completo</Button>
                            </Col>
                        </Row>
                    </Modal>
                    <Row justify='space-between' gutter={[8,8]}>
                        <Col span={24}>
                            <Form
                                name="formulario"
                                onFinish={onFinish}
                                
                                ref={this.formRef}
                                initialValues={this.state.trueSwitch}
                            >
                                <Row justify='space-around' gutter={[8,8]}>
                                    <Col span={10}>
                                        <Form.Item
                                            name="param"
                                        >
                                            <Input className='inputFiltro' placeholder="Ingresa nombres, apellidos, correo, puesto y/o empresa a buscar"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            name="servicio"
                                        >
                                            <Input className='inputFiltro' placeholder="Ingresa servicio a buscar"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                        <Form.Item
                                            name="estado"
                                            valuePropName="checked"
                                        >
                                            <Switch checkedChildren="Activo" unCheckedChildren="Inactivo"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={1}>
                                        <Button shape="circle" icon={<SearchOutlined />} className="btnFiltrar" htmlType="submit"></Button>
                                    </Col>

                                    <Col span={1}>
                                        <Tooltip title='Nuevo Personal' placement="topRight">
                                            <Button className="iconTer" shape="circle" onClick={onNuevoUsuario} disabled={!permisosUsuario.crearPersonal} icon={<PlusOutlined />}/>
                                        </Tooltip>
                                    </Col>

                                    <Col span={1}>
                                        <Tooltip title='Exportar archivo .pdf' placement="topRight">
                                            <Button disabled={!permisosUsuario.exportarPdfInformaciónDelPersonal} loading={fetchingListaPersonalBusqueda} onClick={()=>onExportarArchivo('PDF')} className="iconSecundary" shape='circle' icon={<FilePdfFilled /> }/>
                                        </Tooltip>
                                    </Col>

                                    <Col span={1}>
                                        <Tooltip title='Exportar archivo .csv' placement="topRight">
                                            <Button disabled={!permisosUsuario.exportarCsvInformaciónDelPersonal} loading={fetchingListaPersonalBusqueda} onClick={()=>onExportarArchivo('CSV')} className="iconSecundary" shape='circle' icon={<FileExcelFilled /> } />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        {/* <Col span={5}>
                            <Button onClick={onNuevoUsuario} disabled={!permisosUsuario.crearPersonal} className='nuevoUsuario'>Nuevo Personal</Button>
                        </Col> */}
                        <Col span={24}>
                            <Table 
                                size="small" 
                                rowKey="idPersonal" 
                                columns={columns}
                                dataSource={listaPersonalBusqueda}
                                loading={fetchingListaPersonalBusqueda}
                                locale={{ emptyText: 'Sin datos' }}
                                onChange={handleTableChange}
                                pagination={{ total: listaPersonalBusquedaCantidad, simple: true, current: this.state.page}}
                                scroll={scroll}
                                showSorterTooltip={true}
                            />         
                        </Col>
                    </Row>
                    {/* <Row justify='space-around'>
                        <Col span={9}>
                            <Button className='btnCargaArchivos' disabled={!permisosUsuario.exportarPdfInformaciónDelPersonal} loading={fetchingListaPersonalBusqueda} onClick={()=>onExportarArchivo('PDF')}><FilePdfFilled/> Exportar a PDF</Button>
                        </Col>
                        <Col span={9}>
                            <Button className='btnCargaArchivos' disabled={!permisosUsuario.exportarCsvInformaciónDelPersonal} loading={fetchingListaPersonalBusqueda} onClick={()=>onExportarArchivo('CSV')}><FileExcelFilled/> Exportar a CSV</Button>
                        </Col>
                    </Row> */}
                </Card>
                <ModalNuevoPersonal/>
                <ModalArchivoExpediente/>
                <ModalEditarExpediente/>
                <ModalEditarPersonal/>
                <ModalCambiarEstadoPersonal/>
                <ModalHistorialPersonal/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaPersonalBusqueda: state.RecursosHumanosReducer.listaPersonalBusqueda,
        fetchingListaPersonalBusqueda: state.RecursosHumanosReducer.fetchingListaPersonalBusqueda,
        listaPersonalBusquedaCantidad: state.RecursosHumanosReducer.listaPersonalBusquedaCantidad,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetListaPersonal: (page, busqueda, orden) => {
	    	dispatch({ type: "LISTA_PERSONAL_BUSQUEDA_REQUEST", page, busqueda, orden });
        },
        onEstadoPersonal: (idPersonal, page, busqueda, orden) => {
	    	dispatch({ type: "CAMBIAR_ESTADO_PERSONAL_REQUEST", idPersonal, page, busqueda, orden });
        },
        onExportarPersonalCSV: (busqueda, orden) => {
            dispatch({ type: "DESCARGAR_EXCEL_PERSONAL_REQUEST", busqueda, orden });
        },
        onExportarPersonalPDF: (busqueda, orden, tipo) => {
            dispatch({ type: "DESCARGAR_PDF_PERSONAL_REQUEST", busqueda, orden, tipo });
        },
        onModalNuevoPersonal: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_PERSONAL", showModalNuevoPersonal: true, page });
        },
        onModalEditarPersonal: (personalSeleccionado, page, tipoTurno) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_PERSONAL", showModalEditarPersonal: true, personalSeleccionado, page, tipoTurno });
        },
        onModalArchivoExpediente: (expedienteSeleccionado, page) => {
            dispatch({ type: 'SHOW_MODAL_CARGAR_ARCHIVO_PERSONAL', showArchivosExpediente: true, expedienteSeleccionado: expedienteSeleccionado, page: page });
        },
        onModalArchivoExpediente: (expedienteSeleccionado, page) => {
            dispatch({ type: 'SHOW_MODAL_ARCHIVOS_EXPEDIENTE', showArchivosExpediente: true, expedienteSeleccionado: expedienteSeleccionado, page: page });
        },
        onArchivosExpediente: (nombreCarpeta) => {
            dispatch({ type: 'ARCHIVOS_EXPEDIENTE_REQUEST', nombreCarpeta: nombreCarpeta });
        },
        onModalEditarExpediente: (expedienteSeleccionado, page, tipoEdicion) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_EXPEDIENTE", showModalEditarExpediente: true, expedienteSeleccionado, page, tipoEdicion });
        },
        onServicioSeleccionado: (servicioSeleccionado) => {
            dispatch({ type: 'LISTA_SERVICIOS_BY_PROVEEDOR_SUCCESS', listaServiciosByProveedor: [servicioSeleccionado] });
        },
        onModalEstadoPersonal: (personalSeleccionado, page, busqueda, orden) => {
            dispatch({ type: 'SHOW_MODAL_ESTADO_PERSONAL', showModalEstadoPersonal: true, personalSeleccionado, page, busqueda, orden })
        },
        onHistorialPersonal: (idPersonal, page, formulario) => {
            dispatch({ type: 'HISTORIAL_PERSONAL_REQUEST', idPersonal, page, formulario });
        },
        onModalHistorialPersonal: (personalSeleccionado) => {
            dispatch({ type: 'SHOW_MODAL_HISTORIAL_PERSONAL', showModalHistorialPersonal:true, personalSeleccionado });
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(AltaDePersonalContainer);