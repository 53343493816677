import React, { Component } from 'react';
import { Form, Button, Row, Col, Spin, message, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import { loginCall } from "../../../services/LoginServices";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class VerificarContraseña extends Component {
    render() {
        const { fetchingLogin, onCambiarVista,
        } = this.props;

        let userInfo = sessionStorage.getItem('userInfo');
        if(!userInfo.idUsuario){
            userInfo = JSON.parse(userInfo);
        }

        const onFinish = formulario => {
            console.log('formulario');
            console.log(formulario);
            const userName = userInfo[0].username;
            const values = {
                'userName': userName,
                'password': formulario.password,  
            }
            loginCall(values).then(response => {
                console.log('response');
                console.log(response);
                
                if(response.status === 200){
                    onCambiarVista(3, this.props.formulario, this.props.ponderacionEditado);
                }
                else{
                    let errorMensaje = '';
                    if(response.error_message === 'Bad credentials'){
                        errorMensaje = 'Contraseña incorrecta. Por favor intente de nuevo';
                    }
                    else{
                        errorMensaje = response.error_message;
                    }
                    message.error(errorMensaje);
                    return;
                }
                this.setState({
                    password: '',
                });
            }).catch(error => {
                console.log(error);
                message.error('Hubo un error, favor de intentar después');
                return;
            })
            
        };
        

        return (
            <Spin spinning={fetchingLogin}>
                <Form {...layout}
                name="formulario"
                onFinish={onFinish}
                
                >
                    <Row justify='space-around'>
                        <Col span={11}>
                            <Form.Item
                                label="Ingresa tu contraseña"
                                name="password"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Input.Password prefix={<LockOutlined/>} placeholder="Ingresa tu contraseña"/>
                            </Form.Item>
                        </Col>
                        <Col span={11} className='marginBoton'>
                            <br/>
                            <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Verificar Contraseña</Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingLogin: state.LoginReducer.fetchingLogin,
        ponderacionEditados: state.CatalogosGeneralesReducer.ponderacionEditados,
        formulario: state.CatalogosGeneralesReducer.formulario,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onCambiarVista: (vistaModalPonderacion, formulario, ponderacionEditados) => {
            dispatch({ type: 'CAMBIAR_VISTA_PONDERACION', vistaModalPonderacion: vistaModalPonderacion, formulario: formulario, ponderacionEditado: ponderacionEditados})
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(VerificarContraseña);