import React, { Component } from 'react';
import { Form, Input, Button, Col, Spin, Modal, Radio, Select, Row, InputNumber, message, Divider, Upload, Typography } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { FileExcelFilled } from '@ant-design/icons';
import { connect } from 'react-redux';
const { Option } = Select;
const {Text} = Typography;
const { TextArea } = Input;
const { Dragger } = Upload;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalEditarProducto extends Component {
    state = {
        lote : null,
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
        fileList: [],
    }
    componentDidMount () {
        this.props.onGetUnidadesMedida();
    }

    formRef = React.createRef();
    render() {
        const { fetchingEditarProducto, showModalEditarProducto, onShowModalEditarProducto, onEditarProducto, productoSeleccionado,
            busqueda ,fetchingListaSubfamiliasProductos, fetchingUnidadesMediPorCategoria, unidadesMediPorCategoria, 
            listaSubfamiliasProductosActivos, page, limpiarFormulario, onActualizarSubFamilia, listaUnidadesDeMedicionActivos
        } = this.props

        const onFinish = formulario => {
            console.log('Success:' , formulario);
            onEditarProducto(formulario, page, productoSeleccionado, listaSubfamiliasProductosActivos, busqueda, listaUnidadesDeMedicionActivos);
        }

        if (limpiarFormulario && limpiarFormulario==='EP') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };
        const onChangePDF = (info) => {
            let fileList = [...info.fileList];
   
            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            fileList = fileList.slice(-1);
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else {
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                    fileList
                })
            }
            if (info.file.status === 'done') {
                var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,40}$");
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['csv']);
                    this.setState({fileList: []});
                }
                else {
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true,
                        fileList
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if (!reGex.test(info.file.name)) {
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['csv']);
                    this.setState({fileList: []});
                }
                else {
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true,
                        fileList
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }

        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
                fileList: []
            });
        }

        const handleCancel = () => {
            onShowModalEditarProducto();
            this.formRef.current.resetFields();
        }

        const changeCosto = (value) => {
            const precio = parseFloat(value).toFixed(2)
            this.formRef.current.setFieldsValue({'precio': precio})
        }

        const handleMaxMin = () => {
            const rangoMax = this.formRef.current.getFieldValue('maximo');
            const rangoMin = this.formRef.current.getFieldValue('minimo');
            const reorden = this.formRef.current.getFieldValue('reorden');
            if(rangoMin && rangoMax && rangoMin>=rangoMax){
                message.error('Número máximo debe ser mayor que número mínimo.');
                this.formRef.current.setFieldsValue({'minimo':null})
            }

            if (rangoMin && reorden && reorden>rangoMin) {
                message.error('Punto de reorden debe ser menor o igual que mínimo.');
                this.formRef.current.setFieldsValue({'reorden':null})
            }
        }

        const changeVidaUtil = (value) => {
            if(value){
                const vidaUtil = parseFloat(value).toFixed(2)
                this.formRef.current.setFieldsValue({'vidaUtil': vidaUtil});
            }
        }
        const changeVidaSugerida = (value, tipo) => {
            console.log('value, tipo', value, tipo)
            let vidaUtilBandera = false;
            if(tipo === 'vidaUtil'){
                if(value){
                    const vidaUtilSugerida = parseFloat(value).toFixed(2)
                    this.formRef.current.setFieldsValue({'vidaUtilSugerida': vidaUtilSugerida})
                    vidaUtilBandera = true;
                }
            }
            else{
                if(value){
                    vidaUtilBandera = true;
                }
            }
            
            this.setState({
                vidaUtil: vidaUtilBandera
            })
            
        }

        const onSubFamilia = (value) => {
            let productoSeleccionadoLocal = productoSeleccionado;
            onActualizarSubFamilia(productoSeleccionadoLocal, page, busqueda);

            for(let i in listaSubfamiliasProductosActivos){
                if(parseInt(value) === listaSubfamiliasProductosActivos[i].idSubFamilia){
                    productoSeleccionadoLocal.subFamiliaByIdSubFamilia.familiaByIdFamilia.tipoFamilia = listaSubfamiliasProductosActivos[i].familiaByIdFamilia.tipoFamilia
                }
            }
            
            onActualizarSubFamilia(productoSeleccionadoLocal, page, busqueda);
        }

        return (
            <Modal
                visible={showModalEditarProducto}
                title='Editar Producto/Insumo/Herramienta'
                onCancel={handleCancel}
                footer={false}
                width='80%'
            >
                <Spin spinning={fetchingEditarProducto}>
                    {productoSeleccionado ? (
                        <Form {...layout}
                        name='formulario'
                        initialValues={productoSeleccionado}
                        onFinish={onFinish}
                        
                        ref={this.formRef}
                    >
                        <Row type='flex' justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Código de almacén"
                                    name="codigoAdicional"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Input
                                        maxLength={50} placeholder='Ingresa el código de almacén' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Nombre del producto/insumo/herramienta"
                                    name="nombre"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Input
                                        // pattern="^^[ñíóáéú a-zA-Z0-9]+$"
                                        // title="Nombre debe tener letras y números"
                                        maxLength={100} placeholder='Ingrese nombre del producto/insumo/herramienta' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Descripción"
                                    name="descripcion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <TextArea rows={2}  maxLength={1000} placeholder='Ingrese descripción' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Marca"
                                    name="marca"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Input
                                        maxLength={250} placeholder='Ingresa la marca' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Modelo"
                                    name="modelo"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Input
                                        maxLength={250} placeholder='Ingresa el modelo' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Fabricante"
                                    name="fabricante"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Input
                                        maxLength={250} placeholder='Ingresa el fabricante' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Clave del PIH"
                                    name="clave"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input
                                        // pattern="^^[ñíóáéú a-zA-Z0-9]+$"
                                        // title="Nombre debe tener solo letras y números"
                                        maxLength={100} placeholder='Ingrese clave del producto/insumo/herramienta'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="SKU"
                                    name="sku"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Input
                                        pattern="^^[ñíóáéú _-a-zA-Z0-9]+$"
                                        title="Nombre debe tener solo letras y números."
                                        maxLength={50} placeholder='Ingrese SKU' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Presentación"
                                    name="presentacion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Input
                                        pattern="^^[ñíóáéú a-zA-Z0-9]+$"
                                        title="Nombre debe tener solo letras y números."
                                        maxLength={100} placeholder='Ingrese presentación' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Subfamilia"
                                    name="subFamilia"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Seleccione la subfamilia"
                                        loading={fetchingListaSubfamiliasProductos}
                                        onChange={onSubFamilia}
                                    >
                                        {listaSubfamiliasProductosActivos.map(option => <Option key={option.subFamilia}>{ option.subFamilia }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Consideraciones"
                                    name="consideraciones"
                                    rules={[{ required: false, message: 'Favor de llenar el campo', }]}>
                                    <Input
                                        pattern="^^[ñíóáéú a-zA-Z0-9]+$"
                                        title="Nombre debe tener solo letras."
                                        maxLength={100} placeholder='Ingrese las consideraciones' />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    label="Característica Lote"
                                    name="caracteristicaLote"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Radio.Group>
                                        <Radio value={true}>Si</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    label="Unidad de medida del producto"
                                    name="unidadMedida"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona la unidad de medida"
                                        loading={fetchingUnidadesMediPorCategoria}
                                    >
                                        {unidadesMediPorCategoria.map(option => <Option key={option.idUnidadMedida}>{ option.unidadMedida }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Row justify='space-between' gutter={16}>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Máximo"
                                            name="maximo"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                            <InputNumber min={2} style={{width: '100%'}}
                                            placeholder="Ingrese máximo"
                                            onChange={handleMaxMin}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Mínimo"
                                            name="minimo"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                            <InputNumber min={1} style={{width: '100%'}}
                                            placeholder="Ingrese mínimo"
                                            onChange={handleMaxMin}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Punto de reorden"
                                            name="reorden"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                            <InputNumber min={0} style={{width: '100%'}}
                                            placeholder="Ingrese punto de reorden"
                                            onChange={handleMaxMin}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={11}>
                                <Row justify='space-between'>
                                    <Col span={11}>
                                        <Form.Item
                                            label="Tipo de moneda"
                                            name="tipoMoneda"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                        >
                                            <Select allowClear
                                                style={{ width: '100%' }}
                                                placeholder="Selecciona el tipo de moneda"
                                                loading={this.props.fetchingListaTipoMonedasActivas}
                                            >
                                                {this.props.listaTipoMonedasActivas.map(option => <Option key={option.idTipoMoneda}>{ option.idTipoMoneda + ' - ' + option.nombre + ' (' + option.nomenclatura + ')'}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item
                                            label="Costo unitario"
                                            name="precio"
                                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                            <InputNumber min={0} style={{width: '100%'}}
                                            onChange={changeCosto}
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            placenholder="Ingrese precio unitario"/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            {productoSeleccionado.subFamiliaByIdSubFamilia.familiaByIdFamilia.tipoFamilia === 'Activos' ? (
                                <Col span={24}>
                                    <Row justify='space-around'>
                                        <Divider orientation="left">Características de Activo</Divider>
                                        {/* <Col span={11}>
                                            <Form.Item
                                                label="Especificaciones"
                                                name="especificaciones"
                                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                            >
                                                <Input maxLength={1000} placeholder='Ingresa especificaciones' />
                                            </Form.Item>
                                        </Col> */}
                                        <Col span={11}>
                                            <Form.Item
                                                label="Uso básico"
                                                name="usoBasico"
                                            >
                                                <Input maxLength={60} placeholder="Ingresa el uso básico" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={11}>
                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label="Vida útil"
                                                        name="vidaUtil"
                                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                                    >
                                                        <InputNumber min={1} onChange={changeVidaUtil} placeholder="Ingresa la vida útil" formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label="Unidad de medida"
                                                        name="unidadMedidaVidaUtil"
                                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                                    >
                                                        <Select
                                                            placeholder="Selecciona una unidad de medida"
                                                            allowClear
                                                        >
                                                            <Option key='Años'>Años</Option>
                                                            <Option key='Días'>Días</Option>
                                                            <Option key='Horas'>Horas</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={11}>
                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label="Vida útil sugerida"
                                                        name="vidaUtilSugerida"
                                                        rules={[{ required: this.state.vidaUtil, message: 'Favor de llenar el campo', }]}
                                                    >
                                                        <InputNumber min={1} onChange={e => changeVidaSugerida(e, 'vidaUtil')} placeholder="Ingresa la vida útil" formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label="Unidad de medida"
                                                        name="unidadMedidaSugerida"
                                                        rules={[{ required: this.state.vidaUtil, message: 'Favor de llenar el campo', }]}
                                                    >
                                                        <Select
                                                            placeholder="Selecciona una unidad de medida"
                                                            onChange={e => changeVidaSugerida(e, 'unidad')}
                                                            allowClear
                                                        >
                                                            <Option key='Años'>Años</Option>
                                                            <Option key='Días'>Días</Option>
                                                            <Option key='Horas'>Horas</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item
                                                label="Condición del activo"
                                                name="condicion"
                                            >
                                                <Select placeholder="Selecciona una condición del activo">
                                                    <Option key='F'>Fijo</Option>
                                                    <Option key='M'>Móvil</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item label="Carta fabricante" name="cartaFabricante" >
                                                <Dragger customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false} style={{borderRadius:'1em'}} fileList={this.state.fileList}>
                                                    <Row justify='center'>
                                                        <FileExcelFilled className='iconTabPane'/>
                                                        <Col span={24} style={{paddingTop:'1em'}}>
                                                                <Text strong> Click o arrastra a ésta área el archivo </Text>
                                                        </Col>
                                                    </Row>
                                                </Dragger>
                                            </Form.Item>
                                        </Col>
                                        <Col span={11} className='marginBoton'>
                                            <br />
                                            <Button className="btnFiltrar" htmlType="submit" style={{ width: '100%' }}>Editar Producto</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            ):(
                                <Col span={11} className='marginBoton'>
                                    <br />
                                    <Button className="btnFiltrar" htmlType="submit" style={{ width: '100%' }}>Editar Producto</Button>
                                </Col>
                            )}
                            
                        </Row>
                    </Form>
                    ) : false} 
                </Spin>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingEditarProducto: state.AlmacenesReducer.fetchingEditarProducto,
        showModalEditarProducto: state.AlmacenesReducer.showModalEditarProducto,
        productoSeleccionado: state.AlmacenesReducer.productoSeleccionado,
        page: state.AlmacenesReducer.page,
        busqueda: state.AlmacenesReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaSubfamiliasProductos: state.CatalogosGeneralesReducer.fetchingListaSubfamiliasProductos,
        listaSubfamiliasProductosActivos: state.CatalogosGeneralesReducer.listaSubfamiliasProductosActivos,
        fetchingUnidadesMediPorCategoria: state.AlmacenesReducer.fetchingUnidadesMediPorCategoria,
        unidadesMediPorCategoria: state.AlmacenesReducer.unidadesMediPorCategoria,
        listaTipoMonedasActivas: state.CatalogosGeneralesReducer.listaTipoMonedasActivas,
        fetchingListaTipoMonedasActivas: state.CatalogosGeneralesReducer.fetchingListaTipoMonedasActivas,

        listaUnidadesDeMedicionActivos: state.AlmacenesReducer.listaUnidadesDeMedicionActivos,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarProducto: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_PRODUCTO', showModalEditarProducto: false, productoSeleccionado: null });
        },
        onActualizarSubFamilia: (productoSeleccionado, page, busqueda) => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_PRODUCTO', showModalEditarProducto: true, productoSeleccionado, page, busqueda });
        },
        onEditarProducto: (formulario, page, productoSeleccionado, listaSubfamiliasProductosActivos, busqueda, listaUnidadesDeMedicionActivos) => {
            dispatch( { type: 'EDITAR_PRODUCTO_REQUEST', formulario, page, productoSeleccionado, listaSubfamiliasProductosActivos, busqueda, listaUnidadesDeMedicionActivos });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        },
        onGetSubfamilias: () => {
            dispatch({ type: 'LISTA_SUBFAMILIAS_REQUEST' });
        },
        onGetUnidadesMedicion: (idCategoriaUnidadMedida) => {
            dispatch({ type: 'UNIDADES_MEDI_POR_CATEGORIA_REQUEST', idCategoriaUnidadMedida })
        },
        onGetTiposMonedas: () => {
            dispatch({ type: 'LISTA_TIPOS_MONEDAS_ACTIVOS_REQUEST' });
        },
        onGetUnidadesMedida: () => {
            dispatch({ type: 'LISTA_UNIDADES_MEDICION_ACTIVOS_REQUEST' });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarProducto);