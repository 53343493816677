import React ,{Component} from 'react';
import {Modal, Col, Button, Form, Input} from 'antd';
import {connect} from 'react-redux';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

const {TextArea} = Input;
const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };

class ModalDevolucion extends Component {
    formRef = React.createRef();
    
    render() { 
        const { 
            onCerrarModal,
            showModalEstadoDevolucion,
            codigoDevolucionSeleccionado,
            listaNumerosInventarioFolio,
            onGuardarDatosEstado,
        } = this.props;

        const handleCancel = () => {
            onCerrarModal();
        };

        const onFinish= formulario =>{
            console.log('form', formulario);

            let listaNumerosInventarioFolioLocal = listaNumerosInventarioFolio;
            console.log('listaNumerosInventarioFolioLocal', listaNumerosInventarioFolioLocal)
            onGuardarDatosEstado(null);
            for(let i in listaNumerosInventarioFolioLocal) {
                if(listaNumerosInventarioFolioLocal[i].numeroInventario == codigoDevolucionSeleccionado.numeroInventario){
                    listaNumerosInventarioFolioLocal[i] = Object.assign(listaNumerosInventarioFolioLocal[i], formulario)
                }
            }
            console.log('lista fuera del for', listaNumerosInventarioFolioLocal)
            onGuardarDatosEstado(listaNumerosInventarioFolioLocal);
            onCerrarModal();
        }

        return ( 
            <div>
                {codigoDevolucionSeleccionado ? (
                <Modal
                    visible={showModalEstadoDevolucion}
                    title='Estado de la Herramienta '
                    onCancel={handleCancel}
                    footer={false}
                    width={'30%'}
                >                        
                    <Form {...layout}
                            name="formulario"
                            onFinish={onFinish}
                            ref={this.formRef}
                            >
                            <Form.Item
                                label="Observaciones sobre estado de Herramienta"
                                name="observaciones"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                            <TextArea
                                pattern="^[ñíóáéú a-zA-Z0-9 ]+$"
                                title="Descripción sin carácteres especiales"
                                maxLength={200}
                                placeholder='Observaciones'
                                autoSize />
                            </Form.Item>
                            <Col span={24}>
                                <br/>
                                <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit" > Guardar Estado</Button> 
                            </Col>
                    </Form>
                    
                </Modal>
                ): false}
            </div>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        showModalEstadoDevolucion: state.AlmacenesReducer.showModalEstadoDevolucion,
        codigoDevolucionSeleccionado: state.AlmacenesReducer.codigoDevolucionSeleccionado,
        listaNumerosInventarioFolio: state.AlmacenesReducer.listaNumerosInventarioFolio,
       
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onCerrarModal: () => {
            dispatch({ type: 'SHOW_MODAL_DEVOLUCION_ESTADO_HERRAMIENTA', showModalEstadoDevolucion: false })
        },
        onGuardarDatosEstado: (listaNumerosInventarioFolioLocal) => {
            dispatch({ type: 'ACTUALIZAR_TABLA_NUMEROS_INVENTARIO_FOLIO' , listaNumerosInventarioFolio: listaNumerosInventarioFolioLocal})
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalDevolucion);