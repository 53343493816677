import {useState} from 'react';
import {  message } from 'antd';

export default function useArchivo (ref) {
    const formRef = ref;
    const initialState = {
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
    }
    const [status, setStatus] = useState(initialState);
 
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const onChangePDF = (info) => {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        else {
            setStatus({
                ...status,
                botonPDF: true,
                botonEditar: true,
            })
        }
        if (info.file.status === 'done') {
            var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,40}$");
            if (!reGex.test(info.file.name)) {
                message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                formRef.current.resetFields(['pdf']);
                setStatus({...status, botonPDF: false});
            }
            else {
                message.success(`${info.file.name} se cargó de forma correcta`);
                setStatus({
                    ...status,
                    botonPDF: true,
                    filePDF: true
                });
            }
        } else if (info.file.status === 'error') {
            if (!reGex.test(info.file.name)) {
                message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                formRef.current.resetFields(['pdf']);
                setStatus({...status, botonPDF: false});
            }
            else {
                message.error(`${info.file.name} se cargó de forma incorrecta`);
                setStatus({
                    ...status,
                    filePDF: true
                });
            }
            setStatus({
                ...status,
                botonPDF: false,
                botonEditar: false,
            });
        }
    }

    const onRemovePDF = () => {
        setStatus({
            ...status,
            filePDF: false,
            botonPDF: false,
        });
    }

    return {status, dummyRequest, onChangePDF, onRemovePDF}

}