import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, Row, Select, Tooltip } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css'
import { connect } from 'react-redux';

const {Option} = Select;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalEditarCentroDeCostos extends Component {
    formRef = React.createRef();

    render() {

        const { fetchingEditarCentroDeCostos, showModalEditarCentroDeCostos, onEditarCentroDeCostos, onShowModalEditarCentroDeCostos,
            CentroDeCostosSeleccionado, page, limpiarFormulario , fetchingListaServiciosActivosF, listaServiciosActivosF,
            fetchingListaClasificacionesActivos, listaClasificacionesActivos, busqueda, 
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onEditarCentroDeCostos(formulario, CentroDeCostosSeleccionado, page, busqueda, listaServiciosActivosF);
        };

        if (limpiarFormulario && limpiarFormulario==='editarCentro') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        

        const handleCancel = () => {
            onShowModalEditarCentroDeCostos(page, busqueda);
        }

        return (
            <Modal
                visible={showModalEditarCentroDeCostos}
                title='Editar Centro de Costos'
                onCancel={handleCancel}
                footer={false}
                width='60%'
            >
                <Spin spinning={fetchingEditarCentroDeCostos}>
                    {CentroDeCostosSeleccionado ? (
                        <Form {...layout}
                            name="formulario"
                            initialValues={CentroDeCostosSeleccionado}
                            onFinish={onFinish}
                            
                            ref={this.formRef}
                        >
                            <Row justify='space-around'>
                                <Col span={11}>
                                    <Form.Item
                                        label="Centro de costos"
                                        name="centroCosto"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                        <Input maxLength={80} placeholder='Centro de costos' />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label="Servicios"
                                        name="listaServicios"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona los servicios"
                                            loading={fetchingListaServiciosActivosF}
                                            showSearch={true}
                                            mode='multiple'
                                        >
                                            {listaServiciosActivosF.map(option => 
                                            <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                <Tooltip title={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                    {option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}
                                                </Tooltip>
                                            </Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        label="Gerencia"
                                        name="nombreGerenciaEditar"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona una gerencia"
                                            loading={fetchingListaClasificacionesActivos}
                                            showSearch={true}
                                        >
                                            {listaClasificacionesActivos.map(option => 
                                            <Option key={option.idClasificacionGerencia + ' - ' + option.nombreGerencia}>
                                                <Tooltip title={option.idClasificacionGerencia + ' - ' + option.nombreGerencia}>
                                                    {option.idClasificacionGerencia + ' - ' + option.nombreGerencia}
                                                </Tooltip>
                                            </Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={11} className='marginBoton'>
                                    <br/>
                                    <Button className="btnFiltrar" style={{ width: '100%' }} htmlType="submit">Editar Centro de Costos</Button>
                                </Col>
                            </Row>
                        </Form>
                    ) : false}
                </Spin>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingEditarCentroDeCostos: state.CatalogosGeneralesReducer.fetchingEditarCentroDeCostos,
        showModalEditarCentroDeCostos: state.CatalogosGeneralesReducer.showModalEditarCentroDeCostos,
        CentroDeCostosSeleccionado: state.CatalogosGeneralesReducer.CentroDeCostosSeleccionado,
        page: state.CatalogosGeneralesReducer.page,
        busqueda: state.CatalogosGeneralesReducer.busqueda,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.fetchingListaClasificacionesActivos,
        listaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.listaClasificacionesActivos,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarCentroDeCostos: (page, busqueda) => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_CENTRO_COSTOS', showModalEditarCentroDeCostos: false, CentroDeCostosSeleccionado: null, page, busqueda })
        },
        onEditarCentroDeCostos: (formulario, CentroDeCostosSeleccionado, page, busqueda, listaServiciosActivosF) => {
            dispatch({ type: 'EDITAR_CENTRO_COSTOS_REQUEST', formulario: formulario, CentroDeCostosSeleccionado, page, busqueda, listaServiciosActivosF })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarCentroDeCostos);