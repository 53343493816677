import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoRol from './ModalNuevoRol';
import ModalEditarRol from './ModalEditarRol';

const { Search } = Input;
const confirm = Modal.confirm;
class CatalogoDePermisos extends React.Component {
    componentDidMount(){
        this.props.onGetModulos();
        this.props.getRolesBusqueda(1,'');
    }
    state={
        page: 1,
        busqueda: '',
    }
    render() {
        const { fetchingListaRoles ,listaRolesBusqueda, onEstadoRol, getRolesBusqueda, onModalEditarRol, 
            onModalNuevoRol, listaRolesBusquedaCantidad, onGetSearch
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        const onBuscarRol = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            getRolesBusqueda(1, busqueda);
        }

        const columns = [{
                title: 'Nombre rol',
                dataIndex: 'descripcion',
                key: 'descripcion',
                width:'10%'
            },{
                title: 'Permisos',
                dataIndex: 'permisos',
                key: 'permisos',
                width:'75%'
            },{
                title: 'Fecha alta',
                dataIndex: 'fechaAlta',
                key: 'fechaAlta',
                width:'5%'
            },{
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                width:'5%',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.rolActivoInactivo} onChange={() => handleDesactivar(record)}/>
                ),
            },{
                title: 'Editar',
                key: 'editar',
                width:'5%',
                render: (text, record) => (
                    <a className='editarIcono' disabled={!permisosUsuario.editarRol} onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },
        ];
        const onEditar = (key) => {
            onModalEditarRol(key, this.state.page);
        }
        const handleDesactivar = (key) => {
            console.log(key);
            const page = this.state.page;
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado del rol "'+ key.descripcion +'"?',
                content: <strong style={{color: '#ff7875'}}>Los usuarios con este rol se verán afectados en sus permisos.</strong>,
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoRol(key.idRol, page);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            getRolesBusqueda(pagination.current, this.state.busqueda);
		}
        const onNuevoRol = () => {
            onModalNuevoRol(this.state.page);
        }
        const scroll = {"x": 1700 | true, "y": 1000};
        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search allowClear className='buscarModulos' disabled={!permisosUsuario.buscarRol} placeholder="Ingresa el nombre del rol" onSearch={value => {onBuscarRol(value); onGetSearch(value, this.state.page)}} style={{ width: '100%' }}/>
                    </Col>
                    {/* <Col span={5}>
                        <Button onClick={onNuevoRol} disabled={!permisosUsuario.crearRol} className='nuevoUsuario'>Nuevo Rol</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nuevo Rol' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoRol} disabled={!permisosUsuario.crearRol} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="descripcion" 
                            columns={columns}
                            dataSource={permisosUsuario.buscarRol ? listaRolesBusqueda : []}
                            loading={fetchingListaRoles}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            scroll={scroll}
                            pagination={{ total: listaRolesBusquedaCantidad, simple: true, current: this.state.page }}
                        />         
                    </Col>
                </Row>
                <ModalNuevoRol/>
                <ModalEditarRol/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        catalogoSeleccionado: state.ConfiguracionYSeguridadReducer.catalogoSeleccionado,
        listaPermisos: state.ConfiguracionYSeguridadReducer.listaPermisos,
        fetchingListaPermisos: state.ConfiguracionYSeguridadReducer.fetchingListaPermisos,
        listaRolesBusqueda: state.ConfiguracionYSeguridadReducer.listaRolesBusqueda,
        fetchingListaRoles: state.ConfiguracionYSeguridadReducer.fetchingListaRoles,
        listaRolesBusquedaCantidad: state.ConfiguracionYSeguridadReducer.listaRolesBusquedaCantidad,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        getRolesBusqueda: (page, busqueda) => {
	    	dispatch({ type: "LISTA_ROLES_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
        },
        onEstadoRol: (idRol, page) => {
	    	dispatch({ type: "ESTADO_ROL_REQUEST", idRol: idRol, page: page });
        },
        onModalNuevoRol: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_ROL", showModalNuevoRol: true, page: page });
        },
        onModalEditarRol: (rolSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_ROL", showModalEditarRol: true, rolSeleccionado: rolSeleccionado, page: page });
        },
        onGetModulos: () => {
            dispatch({ type: 'LISTA_MODULOS_REQUEST'})
        },
        onGetSearch: (search, page) => {
            dispatch({ type: 'GET_VALUE_SEARCH', valueSearch:search, page:page})
        },
        
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoDePermisos);