import React, {Component} from 'react'
import { Form, InputNumber, Button, Col, Spin, Modal, Select, Row, DatePicker, message } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import moment from 'moment';


const { Option } = Select;
const { RangePicker } = DatePicker;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalNuevoPonderacionServArea extends Component {
    componentDidMount(){
        this.props.onGetServicios()
        this.props.onGetAreas()
        this.props.onGetlistaInfoProyecto();
    }
     formRef = React.createRef();
    render() { 
        const {
            showModalNuevoPonderacionServPorArea,
            onShowModalNuevoPonderacionServPorArea,
            fetchingNuevoPonderacionServPorArea,
            onNuevoPonderacionServArea,
            fetchingListaServiciosActivosF,
            listaServiciosActivosF,
            fetchinglistaAreasFuncionalesActivos,
            listaAreasFuncionalesActivos,
            listaInfoProyecto,
            page,
            limpiarFormulario,
            onListaServiciosFiltro,
            onListaServiciosFiltroLimpiar,
            fetchingListaServiciosFiltro,
            listaServiciosFiltro,
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            const rangeValue = formulario['rangoVigencia'];
            formulario.rangoVigencia = rangeValue[0].format('YYYY-MM-DD') + "," + rangeValue[1].format('YYYY-MM-DD');
            formulario.fechaInicioVigencia = formulario.rangoVigencia.split(',')[0];
            onNuevoPonderacionServArea(formulario, page, listaServiciosActivosF, listaAreasFuncionalesActivos);
        };

        

        if (limpiarFormulario && limpiarFormulario === 'crearPonderadorServArea') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        const handleCancel = () => {
            onShowModalNuevoPonderacionServPorArea();
            this.formRef.current.resetFields();
        }

        const disabledRange = fecha => {
            return fecha && moment(fecha.format('YYYY-MM-DD')) < listaInfoProyecto.fechaInicio;
        }

        const buscarServicios = value => {
            if(value.length>=3){
                if(value){
                    console.log(value);
                onListaServiciosFiltro(value)
                } else {
                    message.warning('No se encontró servicio con esas palabras')
                }
            } else {
               onListaServiciosFiltroLimpiar();
            } 
        }

        const changePonderador = value => {
            const ponderacionServArea = parseFloat(value).toFixed(1)
            this.formRef.current.setFieldsValue({'ponderacionServArea': ponderacionServArea})
        }

        return (  <Modal
            visible={showModalNuevoPonderacionServPorArea}
            title='Nuevo Ponderador'
            onCancel={handleCancel}
            footer={false}
        >
            <Spin spinning={fetchingNuevoPonderacionServPorArea}>
                <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                >
                    <Row justify='space-around'></Row>
                    <Col span={24}>
                        <Form.Item
                            label="Areas Funcionales"
                            name="idAreaFuncional"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Select allowClear
                                style={{ width: '100%' }}
                                placeholder="Selecciona el area funcional"
                                loading={fetchinglistaAreasFuncionalesActivos}
                            >
                                {listaAreasFuncionalesActivos.map(option => <Option key={option.idAreaFuncional}>{option.areaFuncional}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                            <Form.Item
                                label="Servicio"
                                name="idServicio"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona el servicio"
                                    loading={fetchingListaServiciosFiltro}
                                    showSearch
                                    filterOption={false}
                                    onSearch={buscarServicios}
                                    notFoundContent={null}
                                >
                                    {listaServiciosFiltro.map(option => <Option key={option.idServicio}>{option.nombre}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                        <Form.Item
                            label="Ponderacion por Servicio"
                            name="ponderacionServArea"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <InputNumber min={1} max={100} placeholder='Ingrese ponderacion'
                            onChange={changePonderador}
                            /> 
                        </Form.Item>
                    </Col>


                    <Col span={24}>
                    <Form.Item
                        label="Fechas vigencias ponderacion por servicio"
                        name="rangoVigencia"
                        rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                    >
                        <RangePicker
                            format='DD-MM-YYYY' style={{ width: '100%' }} placeholder={["Ingresa fecha inicio","Fecha fin"]}
                            disabledDate={disabledRange}
                            defaultValue={[, moment(listaInfoProyecto.fechaVigencia, 'DD-MM-YYYY')]}  
                            disabled={[false, true]} 
                            />
                    </Form.Item>
                        </Col>

                    <Col span={24}>
                        <Button className="btnFiltrar" htmlType="submit">Crear Ponderador</Button>
                    </Col>
                    <Row />
                </Form>
            </Spin>
        </Modal> );
    }
}

const mapStateToProps = state => {
    return {
        showModalNuevoPonderacionServPorArea: state.MecanismosDePagosReducer.showModalNuevoPonderacionServPorArea,
        fetchingNuevoPonderacionServPorArea: state.MecanismosDePagosReducer.fetchingNuevoPonderacionServPorArea,
        fetchingListaServiciosFiltro: state.MecanismosDePagosReducer.fetchingListaServiciosFiltro,
        listaServiciosFiltro: state.MecanismosDePagosReducer.listaServiciosFiltro,

        fetchingListaServiciosActivosF: state.CatalogosGeneralesReducer.fetchingListaServiciosActivosF,
        listaServiciosActivosF: state.CatalogosGeneralesReducer.listaServiciosActivosF,
        fetchinglistaAreasFuncionalesActivos: state.CatalogosGeneralesReducer.fetchinglistaAreasFuncionalesActivos,
        listaAreasFuncionalesActivos: state.CatalogosGeneralesReducer.listaAreasFuncionalesActivos,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        
        page: state.MecanismosDePagosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevoPonderacionServPorArea: () => {
            dispatch({ type: "SHOW_MODAL_NUEVO_PONDERACION_SERV_POR_AREA", showModalNuevoPonderacionServPorArea: false})
        },
        onNuevoPonderacionServArea: (formulario, page, listaServiciosActivosF, listaAreasFuncionalesActivos) => {
            dispatch({ type: "NUEVO_PONDERACION_SERV_POR_AREA_REQUEST", formulario: formulario, page: page, listaServiciosActivosF: listaServiciosActivosF, listaAreasFuncionalesActivos: listaAreasFuncionalesActivos })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
        onGetServicios: () => {
            dispatch({ type: "LISTA_SERVICIOS_ACTIVOSF_REQUEST"})
        },
        onGetAreas: () => {
            dispatch({ type: "LISTA_AREASFUNCIONALES_ACTIVOS_REQUEST"})
        },
        onGetlistaInfoProyecto: () => {
            dispatch({ type: "GET_INFOPROYECTO_REQUEST"})
        },
        onListaServiciosFiltro: (busqueda) => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: busqueda})
        },
        onListaServiciosFiltroLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_FILTRO_SUCCESS", listaServiciosFiltro: []})
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoPonderacionServArea)