import React, {Component} from 'react'
import {Button, Col, DatePicker, Form, Input, message, Modal, Row, Select, Spin, Tooltip, Upload} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import {connect} from "react-redux";
import {UploadOutlined} from '@ant-design/icons';
import moment from 'moment';
import {crearEvaluacionDeDesempenoSaga} from "../../../store/sagas/GestionDeCalidadSaga";

const layout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};
const {Option} = Select

class ModalEvaluacionDeDesempeno extends Component {
    componentDidMount() {
        this.props.onListaMesesEvaluación();
    }

    formRef = React.createRef();
    state = {
        botonCrear: false,
        idServicio: null,
        idClasificacionGerencia: null,
        idObjetivo: null,
    }
    render() {
        const {
            showModalEvaluacionDesempeno, limpiarFormulario,
            listaServiciosEvaluacionesDesempeno, fetchingListaServiciosEvaluacionesDesempeno, listaFoliosSolicitudes,fetchingListaGerenciasByServicio,
            listaGerenciasByServicio, fetchingListaObjetivosByGerencia, listaObjetivosByGerencia, fetchingListaIndicadoresByGerenciaObjetibo,
            listaIndicadoresByGerenciaObjetibo, listaPartidasByServicio, fetchingListaPartidasByServicio,listaMesesEvaluacion,
            fetchingListaMesesEvaluacion, fetchingCrearEvaluacionDeDesempeno
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            this.props.onCrearEvaluacionDeDesempeno(formulario);
        };

        if (limpiarFormulario && limpiarFormulario === 'evaluacionDeDesempeno') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
            this.setState({
                botonCrear: false,
                idServicio: null,
                idClasificacionGerencia: null,
                idObjetivo: null
            })
        }
        const handleCancel = () => {
            this.props.onShowModalEvaluacionDesempeno();
            this.formRef.current.resetFields();
            this.setState({
                botonCrear: false,
                idServicio: null,
                idClasificacionGerencia: null,
                idObjetivo: null
            })
        }
        const onChangeGerencia = (value) => {
            this.setState({
                idClasificacionGerencia: value
            });
            if (value) {
                this.props.onListaObjetivosByGerencia(value);
                this.props.onListaServiciosEvaluaciones(value);
            }

            this.props.onListaServiciosLimpiar();
            this.props.onListaObjetivosLimpiar();
            this.props.onListaPartidasLimpiar();
            this.props.onListaIndicadoresDesempenoLimpiar();

            this.formRef.current.setFieldsValue({"idServicio": undefined});
            this.formRef.current.setFieldsValue({"idPartida": undefined});
            this.formRef.current.setFieldsValue({"idObjetivo": undefined});
            this.formRef.current.setFieldsValue({"idIndicadorDesempeno": undefined});
        };
        const onChangeObjetivo = (value) => {
            this.setState({
                idObjetivo: value
            });
            if (value) {
                this.props.onListaIndicadoresByGerenciaObjetibo(this.state.idClasificacionGerencia, value);
            }

            this.props.onListaIndicadoresDesempenoLimpiar();
            this.formRef.current.setFieldsValue({"idIndicadorDesempeno": undefined});
        };
        const onServicioSeleccionado = (value) => {
            this.setState({
                idServicio: parseInt(value)
            });

            if (value) {
                this.props.onListaPartidasByServicio(value);
            }

            this.props.onListaPartidasLimpiar()
            this.formRef.current.setFieldsValue({"idPartida": undefined});
        }
        console.log(listaFoliosSolicitudes);
        return (
            <Modal
                visible={showModalEvaluacionDesempeno}
                title='Nueva Evaluación De Desempeño'
                onCancel={handleCancel}
                footer={false}
                width='60%'
            >
                <Spin spinning={fetchingCrearEvaluacionDeDesempeno}>
                    <Form {...layout}
                          name="formulario"
                          onFinish={onFinish}
                          ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item
                                    label="Gerencia"
                                    name="idClasificacionGerencia"
                                    rules={[{required: true, message: 'Favor de llenar el campo',}]}
                                >
                                    <Select allowClear
                                            style={{width: '100%'}}
                                            placeholder="Selecciona una gerencia"
                                            loading={fetchingListaGerenciasByServicio}
                                            showSearch={true}
                                            onChange={onChangeGerencia}
                                    >
                                        {listaGerenciasByServicio.map(option => <Option
                                            key={option.idClasificacionGerencia}>{option.nombreGerencia}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {this.state.idClasificacionGerencia ?
                                <Col span={11}>
                                    <Form.Item
                                        label="Servicio"
                                        name="idServicio"
                                        rules={[{required: true, message: 'Favor de llenar el campo',}]}
                                    >
                                        <Select allowClear
                                                style={{width: '100%'}}
                                                placeholder="Selecciona un servicio"
                                                loading={fetchingListaServiciosEvaluacionesDesempeno}
                                                showSearch={true}
                                                onChange={onServicioSeleccionado}
                                        >
                                            {listaServiciosEvaluacionesDesempeno.map(option => <Option
                                                key={option.idServicio}>{option.idServicio + ' - ' + option.nombre}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                : false }
                            {this.state.idServicio ?
                                <Col span={11}>
                                    <Form.Item
                                        label="Partida"
                                        name="idPartida"
                                    >
                                        <Select allowClear
                                                style={{width: '100%'}}
                                                placeholder="Selecciona una partida"
                                                loading={fetchingListaPartidasByServicio}
                                                showSearch={true}
                                        >
                                            {listaPartidasByServicio.map(option => <Option
                                                key={option.idPartida}>{option.nombre}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                : false }
                            {this.state.idClasificacionGerencia ?
                                <Col span={11}>
                                    <Form.Item
                                        label="Objetivo"
                                        name="idObjetivo"
                                        rules={[{required: true, message: 'Favor de llenar el campo',}]}
                                    >
                                        <Select allowClear
                                                style={{width: '100%'}}
                                                placeholder="Selecciona un objetivo"
                                                loading={fetchingListaObjetivosByGerencia}
                                                showSearch={true}
                                                onChange={onChangeObjetivo}
                                        >
                                            {listaObjetivosByGerencia.map(option => <Option
                                                key={option.idObjetivo}>{option.objetivo}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                : false }
                            {this.state.idObjetivo ?
                                <Col span={11}>
                                    <Form.Item
                                        label="Indicador de desempeño"
                                        name="idIndicadorDesempeno"
                                        rules={[{required: true, message: 'Favor de llenar el campo',}]}
                                    >
                                        <Select allowClear
                                                style={{width: '100%'}}
                                                placeholder="Selecciona un indicador de desempeño"
                                                loading={fetchingListaIndicadoresByGerenciaObjetibo}
                                                showSearch={true}
                                        >
                                            {listaIndicadoresByGerenciaObjetibo.map(option => <Option
                                                key={option.idIndicadorDesempeno}>{option.indicadorDesempeno}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                : false }
                            <Col span={11}>
                                <Form.Item
                                    label="Mes"
                                    name="idMes"
                                    rules={[{required: true, message: 'Favor de llenar el campo',}]}
                                >
                                    <Select allowClear
                                            style={{width: '100%'}}
                                            placeholder="Selecciona un mes"
                                            loading={fetchingListaMesesEvaluacion}
                                            showSearch={true}
                                    >
                                        {listaMesesEvaluacion.map(option => <Option
                                            key={option.idMes}>{option.mes}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    label="Valor real"
                                    name="valorReal"
                                    rules={[{required: true, message: 'Favor de llenar el campo'}]}
                                >
                                    <Input maxLength={100} type='number' placeholder='Ingresa el valor real'/>
                                </Form.Item>
                            </Col>
                            <Col span={11} className='marginBoton'>
                                <br/>
                                <Button disabled={this.state.botonCrear} className="btnFiltrar" style={{width: '100%'}}
                                        htmlType="submit">Crear Evaluación De Desempeño</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        fetchingCrearEvaluacionDeDesempeno: state.EvaluacionDeLaGestionReducer.fetchingCrearEvaluacionDeDesempeno,
        showModalEvaluacionDesempeno: state.EvaluacionDeLaGestionReducer.showModalEvaluacionDesempeno,
        listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
        page: state.EvaluacionDeLaGestionReducer.page,
        busqueda: state.EvaluacionDeLaGestionReducer.busqueda,
        orden: state.EvaluacionDeLaGestionReducer.orden,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        listaServiciosEvaluacionesDesempeno: state.EvaluacionDeLaGestionReducer.listaServiciosEvaluacionesDesempeno,
        fetchingListaServiciosEvaluacionesDesempeno: state.EvaluacionDeLaGestionReducer.fetchingListaServiciosEvaluacionesDesempeno,
        fetchingListaMesesActivos: state.EvaluacionDeLaGestionReducer.fetchingListaMesesActivos,
        listaMesesActivos: state.EvaluacionDeLaGestionReducer.listaMesesActivos,
        fetchingListaGerenciasByServicio: state.EvaluacionDeLaGestionReducer.fetchingListaGerenciasByServicio,
        listaGerenciasByServicio: state.EvaluacionDeLaGestionReducer.listaGerenciasByServicio,
        fetchingListaObjetivosByGerencia: state.EvaluacionDeLaGestionReducer.fetchingListaObjetivosByGerencia,
        listaObjetivosByGerencia: state.EvaluacionDeLaGestionReducer.listaObjetivosByGerencia,
        fetchingListaIndicadoresByGerenciaObjetibo: state.EvaluacionDeLaGestionReducer.fetchingListaIndicadoresByGerenciaObjetibo,
        listaIndicadoresByGerenciaObjetibo: state.EvaluacionDeLaGestionReducer.listaIndicadoresByGerenciaObjetibo,
        listaPartidasByServicio: state.EvaluacionDeLaGestionReducer.listaPartidasByServicio,
        fetchingListaPartidasByServicio: state.EvaluacionDeLaGestionReducer.fetchingListaPartidasByServicio,
        listaMesesEvaluacion: state.EvaluacionDeLaGestionReducer.listaMesesEvaluacion,
        fetchingListaMesesEvaluacion: state.EvaluacionDeLaGestionReducer.fetchingListaMesesEvaluacion,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEvaluacionDesempeno: () => {
            dispatch({type: 'MODAL_EVALUACION_DESEMPENO', showModalEvaluacionDesempeno: false, modalEvaluacionDesempenoData: null})
        },
        onCrearEvaluacionDeDesempeno: (formulario) => {
            dispatch({type: "CREAR_EVALUACION_DE_DESEMPENO_REQUEST", formulario})
        },
        onLimpiarFormulario: () => {
            dispatch({type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null});
        },
        onListaMesesEvaluación: () => {
            dispatch({type: 'LISTA_MESES_EVALUACION_REQUEST'})
        },
        onListaServiciosEvaluaciones: (idClasificacionGerencia) => {
            dispatch ({ type: 'LISTA_SERVICIOS_EVALUACIONES_DE_DESEMPENO_REQUEST', idClasificacionGerencia });
        },
        onListaGerencias: () => {
            dispatch({type: 'LISTA_GERENCIAS_BY_SERVICIO_REQUEST'})
        },
        onListaObjetivosByGerencia: (idClasificacionGerencia) => {
            dispatch({type: 'LISTA_OBJETIVOS_BY_GERENCIA_REQUEST', idClasificacionGerencia: idClasificacionGerencia})
        },
        onListaIndicadoresByGerenciaObjetibo: (idClasificacionGerencia, idObjetivo) => {
            dispatch({type: 'LISTA_INDICADORES_BY_GERENCIA_OBJETIVO_REQUEST', idClasificacionGerencia: idClasificacionGerencia, idObjetivo: idObjetivo})
        },
        onListaPartidasByServicio: (idServicio) => {
            dispatch({type: 'LISTA_PARTIDAS_BY_SERVICIO_REQUEST', idServicio: idServicio})
        },
        onListaServiciosLimpiar: () => {
            dispatch({ type: "LISTA_SERVICIOS_EVALUACIONES_DE_DESEMPENO_SUCCESS", listaServiciosEvaluacionesDesempeno: [] });
        },
        onListaObjetivosLimpiar: () => {
            dispatch({ type: "LISTA_OBJETIVOS_BY_GERENCIA_SUCCESS", listaObjetivosByGerencia: [] });
        },
        onListaIndicadoresDesempenoLimpiar: () => {
            dispatch({ type: "LISTA_INDICADORES_BY_GERENCIA_OBJETIVO_SUCCESS", listaIndicadoresByGerenciaObjetibo: [] });
        },
        onListaPartidasLimpiar: () => {
            dispatch({ type: "LISTA_PARTIDAS_BY_SERVICIO_SUCCESS", listaPartidasByServicio: [] });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEvaluacionDeDesempeno);
