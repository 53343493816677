import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import {Row, Col, Spin, Form, Upload, Button, message, Typography, Select, Tooltip, Divider, Popover} from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { UploadOutlined, DownloadOutlined, FileExcelFilled, FilePdfOutlined } from '@ant-design/icons';

const { Dragger } = Upload;
const { Text, Paragraph } = Typography;
const { Option } = Select;
const layout = {  labelCol: { span: 24 }, wrapperCol: { span: 24 }, };

const CargaMasivaAlmacen = () => {
    //constants
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [filePDF, setFilePDF] = useState(false); //file carga masiva
    const [fileList, setFileList] = useState([]);
    const [disableBtnSelect, setDisableBtnSelect] = useState(true); //disable select type of file
    const [typeUpload, setTypeUpload] = useState('');
    const [selectServicesDone, setSelectServicesDone] = useState(false);
    const [fileSeries, setFileSeries] = useState([]);
    
    
    const fetchingListaServiciosFiltro = useSelector((state) => state.MecanismosDePagosReducer.fetchingListaServiciosFiltro);
    const listaServiciosFiltro = useSelector((state) => state.MecanismosDePagosReducer.listaServiciosFiltro);
    const fetchingCargaMasiva = useSelector((state) => state.AlmacenesReducer.fetchingCargaMasiva);
    const disableBtnAdd = useSelector((state) => state.AlmacenesReducer.disableBtnAdd);
    const disableBtnSeries = useSelector((state) => state.AlmacenesReducer.disableBtnSeries);
    const showModalCargaMasivaPIH = useSelector((state) => state.AlmacenesReducer.showModalCargaMasivaPIH);
    const folio = useSelector((state) => state.AlmacenesReducer.folio);

    let permisosUsuario = sessionStorage.getItem('permisosUsuario');
    if (permisosUsuario[0]) {
        permisosUsuario = JSON.parse(permisosUsuario);
    };

    useEffect(() => {
        dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: 's'})
        dispatch({type: "LISTA_USUARIOS_ACTIVOS_REQUEST"})
        if(showModalCargaMasivaPIH===false){
            setFilePDF(false);
            setFileList([]);
            setDisableBtnSelect(true);
            setTypeUpload('');
            setSelectServicesDone(false);
            setFileSeries([]);
            form.resetFields();
        }
    }, [form, showModalCargaMasivaPIH]); 
    
    //functions

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 1000);           
    };

    const changeSelect = (value) =>{
        if(value){
            if(filePDF===true){
                setTypeUpload(value)
                setDisableBtnSelect(false)
                dispatch({type: "CHANGE_VALUE_BTN_ADD", disableBtnAdd:false})
            }
            else{
                setTypeUpload(value)
                setDisableBtnSelect(false)
            }
        }
        else{
            setDisableBtnSelect(true)
            dispatch({type: "CHANGE_VALUE_BTN_ADD", disableBtnAdd:true})
        }
    }

    const onFinish = formulario => {
        if(formulario){
            dispatch({ type: "CARGA_MASIVA_ALMACEN_REQUEST", formulario:formulario.csv})
        }
    };

    console.log("folio",folio)

    const uploadSeriesFile = () =>{
        if(folio[0]){
            dispatch({type: "CARGA_MASIVA_ALMACEN_SERIES_REQUEST", formulario:fileSeries, folio: folio[0].folio})
        }
        else{
            message.warning("El archivo no cuenta con folio debido a que no hay archivos correctamente cargados.")
        }
    }

    const onRemovePDF = () => {
        setFilePDF(false)
        dispatch({type: "CHANGE_VALUE_BTN_ADD", disableBtnAdd:true})
        setFileList([])
        message.warning("Seleccione un archivo, por favor.")
    }

    const onRemoveFileSeries = () => {
        setFileSeries([])
        dispatch({ type: "CHANGE_VALUE_BTN_SERIES", disableBtnSeries:true})
        message.warning("Seleccione un archivo, por favor.")
    }

    const servicioSeleccion = (value) => {
        if(value){
            setSelectServicesDone(true)
        
        } else {
            dispatch({type: "CHANGE_VALUE_BTN_ADD", disableBtnAdd:true})
            setSelectServicesDone(false)
            dispatch({ type: "CHANGE_VALUE_BTN_SERIES", disableBtnSeries:true})
        }
        if(value != undefined && filePDF === true && disableBtnSelect === false){
             dispatch({type: "CHANGE_VALUE_BTN_ADD", disableBtnAdd:false})
        }
        if(value != undefined && fileSeries[0] && disableBtnSelect === false){
            dispatch({ type: "CHANGE_VALUE_BTN_SERIES", disableBtnSeries:false})
        }
    }

    const searchServices = value => {
        if(value.length>=1){
            if(value){
                dispatch({ type: "LISTA_SERVICIOS_FILTRO_REQUEST", busqueda: value})
            } else {
                message.warning('No se encontró servicio con esas palabras')
            }
        }
   }

   const onChangePDF = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);

        if (info.file.status !== 'uploading') {
        //console.log(info.file, info.fileList);
        }
        else {
            setFileList(fileList)
        }
        if (info.file.status === 'done') {
            var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,40}$");
            if (!reGex.test(info.file.name)) {
            message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc.). Favor de cambiar el nombre', 8);
            }
            else {
                setFilePDF(true)
                setFileList(fileList)
                    
                if(disableBtnSelect === false && selectServicesDone === true){
                     dispatch({type: "CHANGE_VALUE_BTN_ADD", disableBtnAdd:false})
                }
                message.loading('Iniciando Revisión de Formato de Archivo')
                message.info("Seleccione un servicio y presione el botón 'Cargar Archivo' para continuar")
            }
            setFileList(fileList)
            
        }
        else if (info.file.status === 'error') {
            if (!reGex.test(info.file.name)) {
                message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc. ). Favor de cambiar el nombre', 8);
            }
            else {
                message.error(`${info.file.name} se cargó de forma incorrecta`);
                setFilePDF(true)
                setFileList(fileList)
            }
            setFileList(fileList)
        }
    }

   const onChangePDFServices = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);

        if (info.file.status !== 'uploading') {
            //console.log(info.file, info.fileList);
        }
        else {
            setFileSeries(fileList)
        }
        if (info.file.status === 'done') {
            var reGex = new RegExp("^[a-zA-Z0-9.-_]\\S{4,40}$");
            if (!reGex.test(info.file.name)) {
                message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc.). Favor de cambiar el nombre', 8);
            }
            else {
                setFileSeries(fileList)
                
                if(disableBtnSelect=== false && selectServicesDone === true){
                    dispatch({ type: "CHANGE_VALUE_BTN_SERIES", disableBtnSeries:false})
                }
                message.loading('Iniciando Revisión de Formato de Archivo')
                message.info("Seleccione un servicio y presione el botón 'Cargar Archivo' para continuar")
            }
            setFileSeries(fileList)
            
        } else if (info.file.status === 'error') {
            if (!reGex.test(info.file.name)) {
                message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc. ). Favor de cambiar el nombre', 8);
            }
            else {
                message.error(`${info.file.name} se cargó de forma incorrecta`);
                setFilePDF(true)
                setFileSeries(fileList)
            }
            setFileSeries(fileList)
        }
    }   

    const onDownloadFormats = () =>{
        if(typeUpload === 'series'){
            window.open('https://desarrollo.appcontrollersolutions.com/FormatoSeries.xlsx');
            window.open('https://desarrollo.appcontrollersolutions.com/FormatoCargaAlmacen.xlsx');
        }
        else{
            window.open('https://desarrollo.appcontrollersolutions.com/FormatoCargaAlmacen.xlsx');
        }
    
    }

    return(
        <>
            <Spin spinning={fetchingCargaMasiva}>
                <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    preserve={false}
                    form={form} 
                >
                    <Row justify='space-around'>
                        <Col span={20}>
                            <Tooltip title='Selecciona el tipo de archivo a ingresar' placement="top">
                                <Form.Item label="Tipo de archivo" name="tipoArchivo">
                                        <Select style={{ width: '100%' }} placeholder="Selecciona una opción" onChange={changeSelect}>
                                            <Option key='masiva'>Formato Carga Masiva Almacén</Option>
                                            <Option key='series'>Formato Series</Option>
                                        </Select>
                                    <div style={{marginBottom:'1em'}}/>
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={2} style={{paddingTop:'3em'}} offset={2}>
                            <Tooltip title='Descargar formato .csv' placement="top">
                                <Button className="iconSecundary" shape='circle' icon={<DownloadOutlined /> } disabled={disableBtnSelect} onClick={onDownloadFormats}/>
                            </Tooltip>
                        </Col>

                        <Col span={24}>
                            <Paragraph>
                                Favor de agregar un archivo de extensión CSV. El nombre del archivo no debe contener caracteres especiales (*, /, acentos,.,comas,etc.).
                            </Paragraph>
                                
                            <Paragraph>
                                <pre style={{backgroundColor:'#fafafa', borderRadius:'1em', padding:'.5em'}}>Ejemplo: tabladatos.csv</pre>
                            </Paragraph>

                            <Paragraph>
                                <Text strong>
                                    Nota: Revisa que el formato no haya tenido pérdidas en data antes de subirlo a revisión.
                                </Text>
                            </Paragraph>

                            <Form.Item label="Archivo CSV" name="csv" rules={[{ required: true, message: 'Favor de llenar el campo', }]}  >
                                <Dragger customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.csv' multiple={false} style={{borderRadius:'1em'}} fileList={fileList}>
                                    <Row justify='center'>
                                            <FileExcelFilled className='iconTabPane'/>
                                            <Col span={24} style={{paddingTop:'1em'}}>
                                                <Text strong> Click o arrastra a ésta área el achivo </Text>
                                            </Col>
                                    </Row>
                                </Dragger>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label='Servicio'
                                name="idServicio"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Select
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Escribe el nombre del servicio o nomenclatura a buscar"
                                    loading={fetchingListaServiciosFiltro}
                                    showSearch={true}
                                    filterOption={false}
                                    onSearch={searchServices}
                                    notFoundContent={null}
                                    onChange={servicioSeleccion}
                                >
                                    {listaServiciosFiltro.map(option => <Option key={option.idServicio}>{option.nomenclatura + ': ' + option.nombre}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={20}>
                            <Divider orientation='left'> Cargar Archivo </Divider>
                        </Col>
                        <Col span={2} style={{paddingTop:'1em'}} offset={2}>
                            <Tooltip title='Cargar Archivo' placement="bottomRight">
                                <Button className="iconTer" shape='circle' icon={<UploadOutlined /> } disabled={disableBtnAdd} htmlType="submit"/>
                            </Tooltip>
                        </Col>
                        {typeUpload === 'series' &&
                            <>
                                <Col span={24}>
                                    <Form.Item label="Archivo CSV Series" name="csv" rules={[{ required: true, message: 'Favor de llenar el campo', }]}  >
                                        <Dragger customRequest={dummyRequest} onChange={onChangePDFServices} onRemove={onRemoveFileSeries} accept='.csv' multiple={false} style={{borderRadius:'1em'}} fileList={fileSeries}>
                                            <Row justify='center'>
                                                    <FileExcelFilled className='iconTabPane'/>
                                                    <Col span={24} style={{paddingTop:'1em'}}>
                                                        <Text strong> Click o arrastra a ésta área el achivo </Text>
                                                    </Col>
                                            </Row>
                                        </Dragger>
                                    </Form.Item>
                                </Col>
                                <Col span={20}>
                                    <Divider orientation='left'> Cargar Archivo Series </Divider>
                                </Col>
                                <Col span={2} style={{paddingTop:'1em'}} offset={2}>
                                    <Tooltip title='Cargar Archivo' placement="bottomRight">
                                        <Button className="iconTer" shape='circle' icon={<UploadOutlined /> } disabled={disableBtnSeries} onClick={uploadSeriesFile} />
                                    </Tooltip>
                                    
                                </Col>
                            </>
                        }
                    </Row>
                </Form>
            </Spin>
        </>
    )
}

export default CargaMasivaAlmacen;