import React from 'react';
import { Row, Col, Table, Input, Button, Tooltip } from 'antd';
import { FileExcelOutlined, MailOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
// import ModalNuevoActivoCaract from './ModalNuevoActivoCaract';
// import ModalEditarActivoCaract from './ModalEditarActivoCaract';
// import listCurrency from '../../../CatalogosGenerales/Currencies.json';

// const confirm = Modal.confirm;
const { Search } = Input;
class CatalogoDeActivosCaract extends React.Component {
    componentDidMount(){
        this.props.onGetActivosCaract(1, ''); this.props.onGetTiposMonedas(); this.props.onGetSubfamilias();
    }
    state={
        page: 1,
        busqueda: '',
    }
    render() {
        const { fetchingListaActivosCaractBusqueda ,listaActivosCaractBusqueda, onGetActivosCaract, listaActivosCaractBusquedaCantidad,
            //onModalEditarActivoCaract, onModalNuevoActivoCaract, onEstadoActivoCaract
        } = this.props;

        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        const onBuscarActivoCaract = busqueda => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            onGetActivosCaract(1, busqueda);
        }


        console.log("listaActivosCaractBusqueda",listaActivosCaractBusqueda)
        
        const columns = [{
                title: 'Código de almacén',
                dataIndex: 'codigoAdicional',
                key: 'codigoAdicional',
            },{
                title: 'Grupo de servicio',
                dataIndex: 'grupoServicio',
                key: 'grupoServicio',
            },{
                title: 'Familia',
                dataIndex: 'familia',
                key: 'familia',
            },{
                title: 'Subfamilia',
                dataIndex: 'subfamilia',
                key: 'subfamilia',
            },{
                title: 'Centro de costo',
                dataIndex: 'centroCosto',
                key: 'centroCosto',
            },{
                title: 'Nombre',
                dataIndex: 'descripcion',
                key: 'descripcion',
            },{
                title: 'Marca',
                dataIndex: 'marca',
                key: 'marca',
            },{
                title: 'Modelo',
                dataIndex: 'modelo',
                key: 'modelo',
            },{
                title: 'Fabricante',
                dataIndex: 'fabricante',
                key: 'fabricante',
            },{
                title: 'Especificaciones',
                dataIndex: 'especificaciones',
                key: 'especificaicones',
                width: '35%',
            },{
                title: 'Uso básico',
                dataIndex: 'usoBasico',
                key: 'usoBasico',
            },{
                title: 'Costo unidad',
                dataIndex: 'costoUnidadTabla',
                key: 'costoUnidadTabla',
                align: 'right',
            },{
                title: 'Vida útil años',
                dataIndex: 'vidaUtilAño',
                key: 'vidaUtilAño',
                align: 'right',
            },{
                title: 'Vida útil días',
                dataIndex: 'vidaUtilDia',
                key: 'vidaUtilDia',
                align: 'right',
            },{
                title: 'Vida útil horas',
                dataIndex: 'vidaUtilHora',
                key: 'vidaUtilHora',
                align: 'right',
            },{
                title: 'Vida útil sugerida',
                dataIndex: 'vidaUtilSugerida',
                key: 'vidaUtilSugerida',
            },{
                title: 'Nomenclatura de servicio',
                dataIndex: 'nomenclatura',
                key: 'nomenclatura',
            },{
                title: 'Condición activo',
                dataIndex: 'condicionTabla',
                key: 'condicionTabla',
            },{
                title: 'Carta fabricante',
                dataIndex: 'cartaFabricante',
                key: 'cartaFabricante',
                width: '4%',
                align: 'center',
                render: (text, record) => (
                    <a className='editarIcono' target='_blank' href={record.cartaFabricante} disabled={ record.cartaFabricante === "Sin Carta" }> <MailOutlined /></a>
                ),
            }, 
            // {
            //     title: 'Activo',
            //     dataIndex: 'activo',
            //     key: 'activo',
            //     align: 'center',
            //     render: (text, record) => (
            //         <Switch checked={text} disabled={!permisosUsuario.característicaDeActivoActivoInactivo} onChange={() => handleDesactivar(record)}/>
            //     ),
            // },{
            //     title: 'Editar',
            //     key: 'editar',
            //     align: 'center',
            //     render: (text, record) => (
            //         <a className='editarIcono' disabled={!permisosUsuario.editarCaracterísticaDeActivo} onClick={() => onEditar(record)}><EditOutlined /></a>
            //     ),
            // },
        ];
        // const onEditar = (key) => {
        //     console.log(key);
        //     let code = key.tipoMonedaEditar.split(' (')[1].slice(0, -1);
        //     listCurrency.hasOwnProperty(code);
        //     if(listCurrency.hasOwnProperty(code)){
        //         key.symbolCurrency = listCurrency[code].symbol;
        //     }
        //     onModalEditarActivoCaract(key, this.state.page);
        // }
        // const handleDesactivar = (key) => {
        //     console.log(key);
        //     const page = this.state.page;
        //     confirm({
        //         title: '¿Estás seguro que deseas cambiar el estado del activo característica "'+ key.descripcion +'"?',
        //         content: '',
        //         okText: 'Sí',
        //         okType: 'danger',
        //         cancelText: 'No',
        //         onOk() {
        //             onEstadoActivoCaract(key.idActivoCaract, page)
        //         },
        //         onCancel() {
        //             console.log('Cancel');
        //         },
        //     });
        // }
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onGetActivosCaract(pagination.current, this.state.busqueda);
		}
        // const onNuevoActivoCaract = () => {
        //     onModalNuevoActivoCaract(this.state.page);
        // }
        const scroll = {"x": 3500 | true, "y": 1000};

        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={23}>
                        <Search className='buscarModulos' placeholder="Ingresa la marca/modelo/fabricante/descripción" onSearch={value => onBuscarActivoCaract(value)} style={{ width: '100%' }}/>
                        <br/>
                        <br/>
                    </Col>
                    {/* <Col span={8}>
                        <Button onClick={onNuevoActivoCaract} disabled={!permisosUsuario.crearCaracterísticaDeActivo} className='nuevoUsuario'>Nueva Característica de Activo</Button>
                        <br/>
                        <br/>
                    </Col> */}
                    {/* <Col span={1}>
                        <Tooltip title='Nueva Característica de Activo' placement="topRight">
                            <Button className="iconTer" shape="circle" onClick={onNuevoActivoCaract} disabled={!permisosUsuario.crearCaracterísticaDeActivo}  icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Exportar archivo .xls' placement="topRight">
                            <Button disabled={!permisosUsuario.exportarExcelCatálogoCaracterísticasActivos} className="iconSecundary" shape='circle' icon={<FileExcelOutlined /> } loading={this.props.fetchingExportarExcelCaracteristicasActivos} onClick={() => this.props.onExport()}/>
                        </Tooltip>
                    </Col>
                </Row>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="idProducto" 
                            columns={columns}
                            dataSource={listaActivosCaractBusqueda}
                            loading={fetchingListaActivosCaractBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaActivosCaractBusquedaCantidad, simple: true, current: this.state.page}}
                            scroll={scroll}
                        />         
                    </Col>
                </Row>
                {/* <ModalNuevoActivoCaract/>
                <ModalEditarActivoCaract/> */}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaActivosCaractBusqueda: state.AlmacenesReducer.listaActivosCaractBusqueda,
        fetchingListaActivosCaractBusqueda: state.AlmacenesReducer.fetchingListaActivosCaractBusqueda,
        listaActivosCaractBusquedaCantidad: state.AlmacenesReducer.listaActivosCaractBusquedaCantidad,
        fetchingExportarExcelCaracteristicasActivos: state.AlmacenesReducer.fetchingExportarExcelCaracteristicasActivos,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetActivosCaract: (page, busqueda) => {
	    	dispatch({ type: "LISTA_ACTIVOS_CARACT_BUSQUEDA_REQUEST", page: page, busqueda: busqueda });
        },
        onEstadoActivoCaract: (idActivoCaract, page) => {
	    	dispatch({ type: "ESTADO_ACTIVO_CARACT_REQUEST", idActivoCaract: idActivoCaract, page: page });
        },
        onModalNuevoActivoCaract: (page) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_ACTIVO_CARACT", showModalNuevoActivoCaract: true, page: page });
        },
        onModalEditarActivoCaract: (activoCaractSeleccionado, page) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_ACTIVO_CARACT", showModalEditarActivoCaract: true, activoCaractSeleccionado: activoCaractSeleccionado, page: page });
        },
        onExport: () => {
            dispatch({ type: "EXPORTAR_CARACTERISTICAS_ACTIVOS_EXCEL_REQUEST" });
        },
        onGetTiposMonedas: () => {
            dispatch({ type: 'LISTA_TIPOS_MONEDAS_ACTIVOS_REQUEST' });
        },
        onGetSubfamilias: () => {
            dispatch({ type: 'LISTA_SUBFAMILIAS_REQUEST' });
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoDeActivosCaract);