import React ,{Component} from 'react';
import {Modal, Col, Button, Table, message} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {connect} from 'react-redux';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalEstadoDevolucion from './ModalEstadoDevolucion';


class ModalDevolucion extends Component {
    formRef = React.createRef();
    state = {
        selectedRowKeys: [], 
        selectedRows: [],
        hasSelected: true,
    }
    render() { 
        const { 
            onCerrarModal,
            showModalDevolucion,
            herramientaSeleccionada,
            
            folioPrestamo,
            onGuardarDatosFolio,
            listaNumerosInventarioFolio,
            fetchingNumeroPrestados,
            onLimpiarTablaListaNumerosInventario,
        } = this.props;

        const handleCancel = () => {
            onCerrarModal();
            //onGuardarDatosFolio(null);
            this.setState({
                selectedRowKeys: [],
            })
        };

        const onGuardarHerramienta = () =>{
            const rows = this.state.selectedRows 
            if(rows){
                for(let i in rows ){
                    if(!rows[i].observaciones){
                       return message.warning('Ingresar estado de la herramienta')
                    } 
                }

                let folioPrestamoLocal = folioPrestamo
                console.log('folioPrestamoLocal', folioPrestamoLocal)
                onGuardarDatosFolio(null);
                for(let i in folioPrestamoLocal){
                    if(folioPrestamoLocal[i].idMovimientoHerramienta === herramientaSeleccionada.idMovimientoHerramienta){
                        console.log('entro aqui', folioPrestamoLocal[i])
                        folioPrestamoLocal[i].cantidadDevolucion = rows.length
                        folioPrestamoLocal[i].herramientas = [...rows]
                    }
                }
                console.log('lista fuera del for', folioPrestamoLocal)
                onGuardarDatosFolio(folioPrestamoLocal);
                onCerrarModal();
            }
        }

        const showObservacionModal = (key) => {
            console.log(' modal para ingresar el estado', key);
            this.props.onShowModalEstadoDevolucion(key);
        };

        const onChangeTabla = (record, selected ) => {
            console.log('record: ', record);
            console.log('selected: ', selected);
            
            if(record.length !== 0 || selected[0] !== undefined){
                this.setState({ 
                    hasSelected: false 
                });
            }else{
                this.setState({
                    hasSelected: true 
                });
            }
            this.setState({ 
                selectedRowKeys: record, 
                selectedRows: selected 
            });          
        };

        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: onChangeTabla,
        };

        const handleTableChange= () => {
            console.log('paginacion ')
        }

        const columns = [{
            title: 'Codigo Inventario',
            dataIndex: 'numeroInventario',
            key: 'numeroInventario',
            align: 'center' 
        },{
            title: 'Observaciones',
            dataIndex: 'observaciones',
            key: 'observaciones',
            align: 'center' 
        },{
            title: 'Estado',
            key: '',
            align: 'center',
            render: (record) => (
               <a className='editarIcono' onClick={ () => showObservacionModal(record) }> <EditOutlined />
               </a>
            ),          
        },];

        return ( 
            <div>
                {herramientaSeleccionada ? (
                <Modal
                    visible={showModalDevolucion}
                    title={`Devolución de Herramientas ${herramientaSeleccionada.folio}`}
                    onCancel={handleCancel}
                    footer={false}
                    width={'40%'}
                >
                    <Col span={24}>
                        <br/>                           
                        <Table 
                            size="small" 
                            rowKey="idNumeroInventario" 
                            columns={columns}
                            rowSelection={rowSelection}
                            dataSource={listaNumerosInventarioFolio}
                            loading={fetchingNumeroPrestados}
                            locale={{ emptyText: 'Sin datos', filterReset: 'Limpiar'}}
                            onChange={handleTableChange}
                            pagination={false}
                            />                      
                        </Col> 
                        <Col span={24}>
                            <br/>
                            <Button className="btnFiltrar" style={{width: '40%'}} onClick={onGuardarHerramienta} disabled={this.state.hasSelected}> Guardar Herramientas</Button> 
                        </Col>
                </Modal>
                ): false}
                <ModalEstadoDevolucion/>
            </div>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        showModalDevolucion: state.AlmacenesReducer.showModalDevolucion,
        herramientaSeleccionada: state.AlmacenesReducer.herramientaSeleccionada,
        folioPrestamo: state.AlmacenesReducer.folioPrestamo,
        listaNumerosInventarioFolio: state.AlmacenesReducer.listaNumerosInventarioFolio,
        fetchingNumeroPrestados: state.AlmacenesReducer.fetchingNumeroPrestados,
       
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onCerrarModal: () => {
            dispatch({ type: 'SHOW_MODAL_DEVOLUCION_HERRAMIENTA', showModalDevolucion: false })
        },
        onGuardarDatosFolio: (folioPrestamoLocal) => {
            dispatch({ type: 'ACTUALIZAR_FOLIO_PRESTAMO', folioPrestamo: folioPrestamoLocal})
        },
        onShowModalEstadoDevolucion: (codigoDevolucionSeleccionado) => {
            dispatch({ type: 'SHOW_MODAL_DEVOLUCION_ESTADO_HERRAMIENTA', showModalEstadoDevolucion: true, codigoDevolucionSeleccionado})
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalDevolucion);