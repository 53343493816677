import React from 'react';
import {useDispatch, useSelector } from 'react-redux';
import { Tabs, Modal} from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import CargaMasiva from './CargaMasiva';
import ModalErroresCM from './ModalErroresCM';
import ProcesamientoCargaMasiva from './ProcesamientoCargaMasiva';
import { CloudUploadOutlined, CloudServerOutlined, CloudSyncOutlined } from '@ant-design/icons';
import CargaMasivaAlmacen from './CargaMasivaAlmacen'
const { TabPane } = Tabs;

const  ModalCargaMasiva = () => {
     const dispatch = useDispatch();
     const showModalCargaMasivaPIH = useSelector((state) => state.AlmacenesReducer.showModalCargaMasivaPIH);
     const onCancelModal = () =>{
          dispatch({ type: 'SHOW_MODAL_CARGA_MASIVA_PIH', showModalCargaMasivaPIH:false });
     }

     return (
          <Modal
               visible={showModalCargaMasivaPIH}
               title='Carga Masiva de Productos, Insumos y Herramientas'
               onCancel={onCancelModal}
               footer={false}
               width='40%'
          >
               <Tabs defaultActiveKey="1">
                    <TabPane
                         tab={
                              <span className='txt-title-tabs'>
                                   <CloudUploadOutlined />
                                   Saldos Iniciales Almacén
                              </span>
                         }
                         key="1"
                    >
                         <CargaMasiva/>
                    </TabPane>
                    <TabPane
                         tab={
                         <span className='txt-title-tabs'>
                              <CloudServerOutlined />
                              Entrada a Verificación
                         </span>
                         }
                         key="2"
                    >
                         <CargaMasivaAlmacen/>
                    </TabPane>
                    <TabPane
                         tab={
                         <span className='txt-title-tabs'>
                              <CloudSyncOutlined />
                              Detalles del Archivo
                         </span>
                         }
                         key="3"
                    >
                         <ProcesamientoCargaMasiva/>
                    </TabPane>
               </Tabs>
               <ModalErroresCM/>
          </Modal>
     )
}

export default ModalCargaMasiva;