import React, { Component } from 'react';
import {Row, Col, Modal, List, Table} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import {connect} from 'react-redux';



class ModalDetallePrograma extends Component {

    render() {
        const {showModalDetallePrograma, 
            onCerrarModal,
            programaSeleccionada, // objeto para tabla insumos
            programaSeleccionadaDetalle, // objeto para lista
        } = this.props;
          
        let data = []
        if (programaSeleccionada) {
            const { actividades, epp, personal } = programaSeleccionadaDetalle;
            const herramientasByIdRutina = programaSeleccionadaDetalle.herramientasByIdRutina ? programaSeleccionadaDetalle.herramientasByIdRutina : []
            data = [{ title: "Actividades", data: actividades },
            { title: "Epp", data: epp },
            { title: "Personal", data: personal },
            { title: "Insumos", data: programaSeleccionada },
            { title: "Herramientas", data: herramientasByIdRutina }]
        }

        const handleCancel = () => {
            onCerrarModal();
        };

        const columns = [{
            title: 'Descripcion',
            dataIndex: 'descripcion',
            key: 'descripcion',
            align: 'center'
        },{
            title: 'Cantidad',
            dataIndex: 'cantidad',
            key: 'cantidad',
            align: 'center'
        },{
            title: 'Unidad de Medida',
            dataIndex: 'unidadMedida',
            key: 'unidadMedida',
            align: 'center'
        }];
        return (
            <div>
                {programaSeleccionada ? (
                    <Modal
                        visible={showModalDetallePrograma}
                        title={`Detalle Folio Solicitud de Servicio`}
                        onCancel={handleCancel}
                        footer={false}
                        width='60%'
                    >
                        {programaSeleccionada ? (
                            <div>
                                <Row justify='space-around'>
                                    <Col span={24}>
                                        <List
                                            className="listaCatalogos"
                                            bordered
                                            dataSource={data}
                                            renderItem={item => (
                                           <List.Item className='listaHover'>
                                                <Col span={5}>
                                                    <strong>{item.title}</strong>
                                                </Col>
                                                <Col span={18}>
                                                    {item.title !== 'Insumos' ? 
                                                        <>
                                                        {item.title !== 'Herramientas' ? item.data : (
                                                        <Table
                                                            size='small'
                                                            rowKey='descripcion'
                                                            columns={columns}
                                                            dataSource={item.data}
                                                            locale={{ emptyText: 'Sin datos' }}
                                                            pagination={{
                                                                total: item.data ? item.data.length : 0,
                                                                pageSize: item.data ? item.data.length : 0,
                                                                hideOnSinglePage: true
                                                            }}
                                                            scroll={{ y: 450 }}                                                            /> 
                                                        )}
                                                        </>  : (
                                                        <Table
                                                            size='small'
                                                            rowKey='descripcion'
                                                            columns={columns}
                                                            dataSource={item.data}
                                                            locale={{ emptyText: 'Sin datos' }}
                                                            pagination={{
                                                                total: item.data ? item.data.length : 0,
                                                                pageSize: item.data ? item.data.length : 0,
                                                                hideOnSinglePage: true
                                                            }}
                                                            scroll={{ y: 450 }}
                                                        />
                                                    )}
                                                </Col>
                                            </List.Item>
                                            )}
                                      />
                                  </Col>
                                </Row>
                            </div>
                        ) : false}
                    </Modal>
                ) : false}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showModalDetallePrograma: state.ServiciosPlanificadosReducer.showModalDetallePrograma,
        programaSeleccionada: state.ServiciosPlanificadosReducer.programaSeleccionada,
        programaSeleccionadaDetalle: state.ServiciosPlanificadosReducer.programaSeleccionadaDetalle,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCerrarModal: () => {
            dispatch({ type: "SHOW_MODAL_DETALLE_PROGRAMA", showModalDetallePrograma: false, programaSeleccionadaDetalle: null, programaSeleccionada: null});
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetallePrograma);