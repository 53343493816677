import * as RecursosHumanosActions from '../actions/RecursosHumanosActions';

const initialState = {
     page: null,
     catalogoRecursosHumanos: null,

     // Alta de Personal
     fetchingListaPersonalBusqueda: false,
     listaPersonalBusqueda: [],
     listaPersonalBusquedaCantidad: 0,

     fetchingListaPersonalServicio: false,
     listaPersonalServicio: [],

     fetchingDescargarExcelPersonal: false,

     showModalNuevoPersonal: false,
     fetchingNuevoPersonal: false,

     showModalEditarPersonal: false,
     fetchingEditarPersonal: false,
     personalSeleccionado: [],

     showModalHistorialPersonal: false,
     fetchingHistorialPersonal: false,

     listaHistorialPersonal: [],
     listaHistorialPersonalCantidad: 0,

     vistaNuevoPersonal: 1,
     formularioPersonal: null,
     tipoTurno: null,

     busqueda: {},
     orden: {},

     // Control de Asistencia
     fetchingListaControlAsistenciaBusqueda: false,
     listaControlAsistenciaBusqueda: [],
     
     fetchingImportarBiometrico: false,

     // Reporte Dinamico
     listaReporteDinamicoRH: [],
     listaReporteDinamicoCantidadRH: null,
     fetchingListaReporteDinamicoRH: false,

     fetchingReporteDinamicoAgrupadoRH: false,
     fetchingReporteDinamicoPDF: false,
     fetchingReporteDinamicoEXCEL: false,
     formularioReporte: [],
     listaReporteDinamicoAgrupadoRH: [],
     listaReporteDinamicoAgrupadoCantidadRH: null,

     listaGrupoServiciosByIdUsuario: [],
     fetchingListaGrupoServiciosByIdUsuario: false,

     fetchingListaGerenciasByIdUsuario: false,
     listaGerenciasByIdUsuario: [],

     fetchingReporteDinamicoAgrupadoPDF: false,
     fetchingReporteDinamicoAgrupadoEXCEL: false,

     fetchingGetAllRegistros: false,
     allRegistros: [],
     allRegistrosCantidad: null,
     fetchingGetRegistrosByToken: false,
     registrosByToken: [],
     registrosByTokenCantidad: null,

     fetchingEstadoRegistros: false,
     fetchingCreateRegistro: false,
     fetchingEditRegistro: false,

     showModalEditarRegistro: false,
     showModalCrearRegistro: false,
     data: null,
     registroReporteSeleccionado: [],
     personal: false,
     fetchingEditRegistros: false,
}

export default (state = initialState, action) => {
     switch (action.type) {

          // Alta de Personal
          case RecursosHumanosActions.LISTA_PERSONAL_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaPersonalBusqueda: true };
          case RecursosHumanosActions.LISTA_PERSONAL_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaPersonalBusqueda: false, listaPersonalBusqueda: action.listaPersonalBusqueda, listaPersonalBusquedaCantidad: action.listaPersonalBusquedaCantidad, busqueda: action.busqueda, orden: action.orden };
          case RecursosHumanosActions.LISTA_PERSONAL_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaPersonalBusqueda: false };

          case RecursosHumanosActions.LISTA_PERSONAL_SERVICIO_REQUEST:
               return { ...state, fetchingListaPersonalServicio: true };
          case RecursosHumanosActions.LISTA_PERSONAL_SERVICIO_SUCCESS:
               return { ...state, fetchingListaPersonalServicio: false, listaPersonalServicio: action.listaPersonalServicio };
          case RecursosHumanosActions.LISTA_PERSONAL_SERVICIO_FAILURE:
               return { ...state, fetchingListaPersonalServicio: false };

          case RecursosHumanosActions.CAMBIAR_ESTADO_PERSONAL_REQUEST:
               return { ...state, fetchingListaPersonalBusqueda: true };
          case RecursosHumanosActions.CAMBIAR_ESTADO_PERSONAL_SUCCESS:
               return { ...state, fetchingListaPersonalBusqueda: false };
          case RecursosHumanosActions.CAMBIAR_ESTADO_PERSONAL_FAILURE:
               return { ...state, fetchingListaPersonalBusqueda: false };

          case RecursosHumanosActions.DESCARGAR_EXCEL_PERSONAL_REQUEST:
               return { ...state, fetchingListaPersonalBusqueda: true };
          case RecursosHumanosActions.DESCARGAR_EXCEL_PERSONAL_SUCCESS:
               return { ...state, fetchingListaPersonalBusqueda: false };
          case RecursosHumanosActions.DESCARGAR_EXCEL_PERSONAL_FAILURE:
               return { ...state, fetchingListaPersonalBusqueda: false };

          case RecursosHumanosActions.DESCARGAR_PDF_PERSONAL_REQUEST:
               return { ...state, fetchingListaPersonalBusqueda: true };
          case RecursosHumanosActions.DESCARGAR_PDF_PERSONAL_SUCCESS:
               return { ...state, fetchingListaPersonalBusqueda: false };
          case RecursosHumanosActions.DESCARGAR_PDF_PERSONAL_FAILURE:
               return { ...state, fetchingListaPersonalBusqueda: false };

          case RecursosHumanosActions.HISTORIAL_PERSONAL_REQUEST:
               return { ...state, fetchingHistorialPersonal: true };
          case RecursosHumanosActions.HISTORIAL_PERSONAL_SUCCESS:
               return { ...state, fetchingHistorialPersonal: false, listaHistorialPersonal: action.listaHistorialPersonal, listaHistorialPersonalCantidad: action.listaHistorialPersonalCantidad };
          case RecursosHumanosActions.HISTORIAL_PERSONAL_FAILURE:
               return { ...state, fetchingHistorialPersonal: false };
          
          case RecursosHumanosActions.NUEVO_PERSONAL_REQUEST:
               return { ...state, fetchingNuevoPersonal: true };
          case RecursosHumanosActions.NUEVO_PERSONAL_SUCCESS:
               return { ...state, fetchingNuevoPersonal: false };
          case RecursosHumanosActions.NUEVO_PERSONAL_FAILURE:
               return { ...state, fetchingNuevoPersonal: false };

          case RecursosHumanosActions.EDITAR_PERSONAL_REQUEST:
               return { ...state, fetchingEditarPersonal: true };
          case RecursosHumanosActions.EDITAR_PERSONAL_SUCCESS:
               return { ...state, fetchingEditarPersonal: false };
          case RecursosHumanosActions.EDITAR_PERSONAL_FAILURE:
               return { ...state, fetchingEditarPersonal: false };

          case RecursosHumanosActions.SHOW_MODAL_NUEVO_PERSONAL:
               return { ...state, showModalNuevoPersonal: action.showModalNuevoPersonal, page: action.page };

          case RecursosHumanosActions.SHOW_MODAL_EDITAR_PERSONAL:
               return { ...state, showModalEditarPersonal: action.showModalEditarPersonal, personalSeleccionado: action.personalSeleccionado, page: action.page, tipoTurno: action.tipoTurno };

          case RecursosHumanosActions.SHOW_MODAL_ESTADO_PERSONAL:
               return { ...state, showModalEstadoPersonal: action.showModalEstadoPersonal, personalSeleccionado: action.personalSeleccionado, page: action.page, busqueda: action.busqueda, orden: action.orden };

          case RecursosHumanosActions.SHOW_MODAL_HISTORIAL_PERSONAL:
               return { ...state, showModalHistorialPersonal: action.showModalHistorialPersonal, personalSeleccionado: action.personalSeleccionado };

          // Control de Asistencia
          case RecursosHumanosActions.LISTA_CONTROL_ASISTENCIA_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaControlAsistenciaBusqueda: true };
          case RecursosHumanosActions.LISTA_CONTROL_ASISTENCIA_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaControlAsistenciaBusqueda: false, listaControlAsistenciaBusqueda: action.listaControlAsistenciaBusqueda, listaControlAsistenciaBusquedaCantidad: action.listaControlAsistenciaBusquedaCantidad };
          case RecursosHumanosActions.LISTA_CONTROL_ASISTENCIA_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaControlAsistenciaBusqueda: false };

          case RecursosHumanosActions.LISTA_CONTROL_ASISTENCIA_EXPORTAR_REQUEST:
               return { ...state, fetchingListaControlAsistenciaBusqueda: true };
          case RecursosHumanosActions.LISTA_CONTROL_ASISTENCIA_EXPORTAR_SUCCESS:
               return { ...state, fetchingListaControlAsistenciaBusqueda: false };
          case RecursosHumanosActions.LISTA_CONTROL_ASISTENCIA_EXPORTAR_FAILURE:
               return { ...state, fetchingListaControlAsistenciaBusqueda: false };

          case RecursosHumanosActions.IMPORTAR_BIOMETRICO_REQUEST:
               return { ...state, fetchingListaControlAsistenciaBusqueda: true };
          case RecursosHumanosActions.IMPORTAR_BIOMETRICO_SUCCESS:
               return { ...state, fetchingListaControlAsistenciaBusqueda: false };
          case RecursosHumanosActions.IMPORTAR_BIOMETRICO_FAILURE:
               return { ...state, fetchingListaControlAsistenciaBusqueda: false };

          case RecursosHumanosActions.VISTA_NUEVO_PERSONAL:
               return { ...state, vistaNuevoPersonal: action.vistaNuevoPersonal, formularioPersonal: action.formularioPersonal };

          // Reporte Dinamico
          case RecursosHumanosActions.LISTA_REPORTE_DINAMICO_RH_BUSQUEDA_REQUEST:
               return { ...state, fetchingListaReporteDinamicoRH: true, formularioReporte: action.formulario };
          case RecursosHumanosActions.LISTA_REPORTE_DINAMICO_RH_BUSQUEDA_SUCCESS:
               return { ...state, fetchingListaReporteDinamicoRH: false, listaReporteDinamicoRH: action.listaReporteDinamicoRH, listaReporteDinamicoCantidadRH: action.listaReporteDinamicoCantidadRH };
          case RecursosHumanosActions.LISTA_REPORTE_DINAMICO_RH_BUSQUEDA_FAILURE:
               return { ...state, fetchingListaReporteDinamicoRH: false };

               case RecursosHumanosActions.REPORTE_DINAMICO_AGRUPADO_RH_REQUEST:
                    return { ...state, fetchingReporteDinamicoAgrupadoRH: true, formularioReporte: action.formulario };
               case RecursosHumanosActions.REPORTE_DINAMICO_AGRUPADO_RH_SUCCESS:
                    return { ...state, fetchingReporteDinamicoAgrupadoRH: false, listaReporteDinamicoAgrupadoRH: action.listaReporteDinamicoAgrupadoRH, listaReporteDinamicoAgrupadoCantidadRH: action.listaReporteDinamicoAgrupadoCantidadRH };
               case RecursosHumanosActions.REPORTE_DINAMICO_AGRUPADO_RH_FAILURE:
                    return { ...state, fetchingReporteDinamicoAgrupadoRH: false };
                    
               case RecursosHumanosActions.PDF_REPORTE_DINAMICO_RH_REQUEST:
                    return { ...state, fetchingReporteDinamicoPDF: true };
               case RecursosHumanosActions.PDF_REPORTE_DINAMICO_RH_SUCCESS:
                    return { ...state, fetchingReporteDinamicoPDF: false };
               case RecursosHumanosActions.PDF_REPORTE_DINAMICO_RH_FAILURE:
                    return { ...state, fetchingReporteDinamicoPDF: false };
     
               case RecursosHumanosActions.EXCEL_REPORTE_DINAMICO_RH_REQUEST:
                    return { ...state, fetchingReporteDinamicoEXCEL: true };
               case RecursosHumanosActions.EXCEL_REPORTE_DINAMICO_RH_SUCCESS:
                    return { ...state, fetchingReporteDinamicoEXCEL: false };
               case RecursosHumanosActions.EXCEL_REPORTE_DINAMICO_RH_FAILURE:
                    return { ...state, fetchingReporteDinamicoEXCEL: false };
     
               case RecursosHumanosActions.LIMPIAR_FORMULARIO_REPORTE_RH:
                    return { ...state, formularioReporte : action.formularioReporte, listaReporteDinamicoRH: action.listaReporteDinamicoRH, listaReporteDinamicoCantidadRH: action.listaReporteDinamicoCantidadRH};

               case RecursosHumanosActions.LISTA_GRUPO_SERVICIOS_BYIDUSUARIO_REQUEST:
                    return { ...state, fetchingListaGrupoServiciosByIdUsuario: true };
               case RecursosHumanosActions.LISTA_GRUPO_SERVICIOS_BYIDUSUARIO_SUCCESS:
                    return { ...state, fetchingListaGrupoServiciosByIdUsuario: false, listaGrupoServiciosByIdUsuario: action.listaGrupoServiciosByIdUsuario };
               case RecursosHumanosActions.LISTA_GRUPO_SERVICIOS_BYIDUSUARIO_FAILURE:
                    return { ...state, fetchingListaGrupoServiciosByIdUsuario: false };
               
               case RecursosHumanosActions.LISTA_GERENCIAS_BYIDUSUARIO_REQUEST:
                    return { ...state, fetchingListaGerenciasByIdUsuario: true };
               case RecursosHumanosActions.LISTA_GERENCIAS_BYIDUSUARIO_SUCCESS:
                    return { ...state, fetchingListaGerenciasByIdUsuario: false, listaGerenciasByIdUsuario: action.listaGerenciasByIdUsuario };
               case RecursosHumanosActions.LISTA_GERENCIAS_BYIDUSUARIO_FAILURE:
                    return { ...state, fetchingListaGerenciasByIdUsuario: false };

               case RecursosHumanosActions.PDF_REPORTE_DINAMICO_RH_AGRUPADO_REQUEST:
                    return { ...state, fetchingReporteDinamicoAgrupadoPDF: true };
               case RecursosHumanosActions.PDF_REPORTE_DINAMICO_RH_AGRUPADO_SUCCESS:
                    return { ...state, fetchingReporteDinamicoAgrupadoPDF: false };
               case RecursosHumanosActions.PDF_REPORTE_DINAMICO_RH_AGRUPADO_FAILURE:
                    return { ...state, fetchingReporteDinamicoAgrupadoPDF: false };
     
               case RecursosHumanosActions.EXCEL_REPORTE_DINAMICO_RH_AGRUPADO_REQUEST:
                    return { ...state, fetchingReporteDinamicoAgrupadoEXCEL: true };
               case RecursosHumanosActions.EXCEL_REPORTE_DINAMICO_RH_AGRUPADO_SUCCESS:
                    return { ...state, fetchingReporteDinamicoAgrupadoEXCEL: false };
               case RecursosHumanosActions.EXCEL_REPORTE_DINAMICO_RH_AGRUPADO_FAILURE:
                    return { ...state, fetchingReporteDinamicoAgrupadoEXCEL: false };

               case RecursosHumanosActions.GET_ALL_REGISTROS_REQUEST:
                    return { ...state, fetchingGetAllRegistros: true };
               case RecursosHumanosActions.GET_ALL_REGISTROS_SUCCESS:
                    return { ...state, fetchingGetAllRegistros: false, allRegistros: action.allRegistros, allRegistrosCantidad: action.allRegistrosCantidad };
               case RecursosHumanosActions.GET_ALL_REGISTROS_FAILURE:
                    return { ...state, fetchingGetAllRegistros: false };

               case RecursosHumanosActions.GET_REGISTROS_BY_TOKEN_REQUEST:
                    return { ...state, fetchingGetRegistrosByToken: true };
               case RecursosHumanosActions.GET_REGISTROS_BY_TOKEN_SUCCESS:
                    return { ...state, fetchingGetRegistrosByToken: false, registrosByToken: action.registrosByToken, registrosByTokenCantidad: action.registrosByTokenCantidad };
               case RecursosHumanosActions.GET_REGISTROS_BY_TOKEN_FAILURE:
                    return { ...state, fetchingGetRegistrosByToken: false };

               case RecursosHumanosActions.CREATE_REGISTROS_REQUEST:
                    return { ...state, fetchingCreateRegistro: true };
               case RecursosHumanosActions.CREATE_REGISTROS_SUCCESS:
                    return { ...state, fetchingCreateRegistro: false };
               case RecursosHumanosActions.CREATE_REGISTROS_FAILURE:
                    return { ...state, fetchingCreateRegistro: false };

               case RecursosHumanosActions.ESTADO_REGISTROS_REQUEST:
                    return { ...state, fetchingEstadoRegistros: true };
               case RecursosHumanosActions.ESTADO_REGISTROS_SUCCESS:
                    return { ...state, fetchingEstadoRegistros: false };
               case RecursosHumanosActions.ESTADO_REGISTROS_FAILURE:
                    return { ...state, fetchingEstadoRegistros: false };

               case RecursosHumanosActions.EDIT_REGISTROS_REQUEST:
                    return { ...state, fetchingEditRegistros: true };
               case RecursosHumanosActions.EDIT_REGISTROS_SUCCESS:
                    return { ...state, fetchingEditRegistros: false };
               case RecursosHumanosActions.EDIT_REGISTROS_FAILURE:
                    return { ...state, fetchingEditRegistros: false };

               case RecursosHumanosActions.MODAL_CREAR_REGISTRO:
                    return { ...state, showModalCrearRegistro: action.showModalCrearRegistro, data: action.data };

               case RecursosHumanosActions.MODAL_EDITAR_REGISTRO:
                    return { ...state, showModalEditarRegistro: action.showModalEditarRegistro, registroReporteSeleccionado: action.registroReporteSeleccionado, page: action.page, personal: action.personal };

          default:
               return state;
     }
};
