import React from 'react';
import { Row, Col } from 'antd';
import ListaPermisos from './ListaPermisos';
import ListaLogs from './ListaLogs';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Sistema.css';
import ListaSettings from './Settings/ListaSettings';
import AlertasContainer from './Alertas/AlertasContainer';

class SistemaContainer extends React.Component {
    render() {
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        return (
            <div>
                <Row justify='space-around'>
                    {permisosUsuario.buscarPermiso ? (
                    <Col span={5}>
                        <ListaPermisos/>
                    </Col>
                    ):false}
                    <Col span={18}>
                        {permisosUsuario.verConfiguraciones ? (
                        <ListaSettings/>
                        ):false}
                        <br/>
                        {permisosUsuario.buscarAlertas ? (
                        <AlertasContainer/>
                        ):false}
                        <br/>
                        {permisosUsuario.buscarAuditoriaDelSistema ? (
                        <ListaLogs />
                        ):false}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default SistemaContainer;