import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, Select, Row } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Usuarios.css';
import { connect } from "react-redux";

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalEditarUsuario extends Component {
    
    render() {
        const { fetchingEditarUsuario, usuario, modalEditarUsuario, onEditarUsuario, onModalEditarUsuario,
            fetchingServiciosPorProveedor, serviciosPorProveedor, fetchingListaRoles, listaRoles, formularioSearch, page
        } = this.props;

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onEditarUsuario(formulario, usuario, listaRoles, serviciosPorProveedor, page, formularioSearch);
        };
        
        
        const handleCancel = () => {
            onModalEditarUsuario()
        }

        return (
            <Modal
            visible={modalEditarUsuario}
            title='Editar Usuario'
            width={'80%'}
            onCancel={handleCancel}
            footer={false}
            >
                {usuario ? (
                <Spin spinning={fetchingEditarUsuario}>
                
                    <Form {...layout}
                    name="formulario"
                    initialValues={usuario}
                    onFinish={onFinish}
                    
                    >
                    <Row justify='space-around' gutter={[8,8]}>
                        <Col span={12}>
                            <Form.Item
                                label="Usuario"
                                name="username"
                            >
                                <Input disabled={true} maxLength={50}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Tipo de usuario"
                                name="tipoUsuario"
                            >
                                <Input disabled={true} maxLength={50}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Nombre(s)"
                                name="nombre"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Input  pattern="^[ñíóáéú a-zA-Z ]+$" 
                                        title="El nombre debe tener solo letras"
                                        maxLength={150} placeholder="Ingrese nombre(s)"/>
                            </Form.Item>
                        </Col>
                         <Col span={8}>
                            <Form.Item
                            label="Apellido paterno"
                            name="aPaterno"
                            rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                        >
                            <Input  pattern="^[ñíóáéú a-zA-Z ]+$" 
                                    title="El apellido paterno debe tener solo letras" 
                                    maxLength={80} placeholder="Ingrese apellido paterno"/>
                          </Form.Item>
                        </Col>
                         <Col span={8}>
                            <Form.Item
                            label="Apellido materno"
                            name="aMaterno"
                        >
                            <Input pattern="^[ñíóáéú a-zA-Z ]+$" 
                                   title="El apellido materno debe tener solo letras"
                                   maxLength={80} placeholder="Ingrese apellido materno"/>
                          </Form.Item>
                        </Col>
                         <Col span={12}>
                            <Form.Item
                            label="Dominio del usuario"
                            name="dominio"
                        >
                            <Input disabled={true}/>
                          </Form.Item>
                        </Col>
                         <Col span={12}>
                            <Form.Item
                            label="Nombre de contacto"
                            name="proveedor"
                        >
                            <Input disabled={true}/>
                          </Form.Item>
                        </Col>
                        {serviciosPorProveedor ? (
                             <Col span={24}>
                            <Form.Item
                                label="Nombre servicios"
                                name="listaServicios"
                            >
                                <Select mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Selecciona los servicios"
                                    loading={fetchingServiciosPorProveedor}
                                    disabled={true}
                                >
                                    {serviciosPorProveedor.map(option => <Option defaultValue={usuario.listaServicios} key={option.nombre}>{option.nombre}</Option>)}
                                </Select>
                              </Form.Item>
                        </Col>
                        ):false}
                        
                         <Col span={24}>
                            <Form.Item
                            label="Roles"
                            name="listaRoles"
                        >
                            <Select mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Selecciona los roles"
                                defaultValue={usuario.listaRoles}
                                loading={fetchingListaRoles}
                            >
                                {listaRoles.map(option => <Option key={option.descripcion}>{option.descripcion}</Option>)}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Button className="btnFiltrar" htmlType="submit" style={{width:'100%'}}>Guardar Cambios</Button>
                        </Col>
                    </Row>
                        
                        
                    </Form>
                </Spin>
                ):(false)}
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingEditarUsuario: state.ConfiguracionYSeguridadReducer.fetchingEditarUsuario,
        modalEditarUsuario: state.ConfiguracionYSeguridadReducer.modalEditarUsuario,
        usuario: state.ConfiguracionYSeguridadReducer.usuario,
        fetchingServiciosPorProveedor: state.ConfiguracionYSeguridadReducer.fetchingServiciosPorProveedor,
        serviciosPorProveedor: state.ConfiguracionYSeguridadReducer.serviciosPorProveedor,
        fetchingListaRoles: state.ConfiguracionYSeguridadReducer.fetchingListaRoles,
        listaRoles: state.ConfiguracionYSeguridadReducer.listaRoles,
        page: state.ConfiguracionYSeguridadReducer.page,
        formularioSearch:state.ConfiguracionYSeguridadReducer.formularioSearch,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onModalEditarUsuario: () => {
            dispatch({ type: 'MODAL_EDITAR_USUARIO', modalEditarUsuario: false, usuario: null, page: null})
        },
        onEditarUsuario: (formulario, usuario, listaRoles, serviciosPorProveedor, page, formularioSearch) => {
            dispatch({ type: "EDITAR_USUARIO_REQUEST" , formulario: formulario, usuario: usuario, listaRoles: listaRoles, serviciosPorProveedor: serviciosPorProveedor, page: page, formularioSearch:formularioSearch });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarUsuario);