import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import ConfiguracionYSeguridadReducer from "./ConfiguracionYSeguridadReducer";
import InformacionDelProyectoReducer from "./InformacionDelProyectoReducer";
import InformacionOperativaReducer from './InformacionOperativaReducer';
import AlmacenesReducer from "./AlmacenesReducer";
import CatalogosGeneralesReducer from "./CatalogosGeneralesReducer";
import MecanismosDePagosReducer from "./MecanismosDePagosReducer";
import RecursosHumanosReducer from "./RecursosHumanosReducer";
import ServiciosPublicosReducer from "./ServiciosPublicosReducer";
import ServiciosVariablesReducer from "./ServiciosVariablesReducer";
import BaseDeConocimientoReducer from "./BaseDeConocimientoReducer";
import SolicitudDeServiciosReducer from "./SolicitudDeServiciosReducer";
import ServiciosPlanificadosReducer from "./ServiciosPlanificadosReducer";
import GestionDeCalidadReducer from "./GestionDeCalidadReducer";
import MonitorIntegralReducer from "./MonitorIntegralReducer";
import EvaluacionDeLaGestionReducer from "./EvaluacionDeLaGestionReducer";
import RMDPReducer from "./RMDPReducer";

export default combineReducers({
  LoginReducer, ConfiguracionYSeguridadReducer, InformacionDelProyectoReducer, InformacionOperativaReducer, AlmacenesReducer, CatalogosGeneralesReducer, MecanismosDePagosReducer, RecursosHumanosReducer, ServiciosPublicosReducer,ServiciosVariablesReducer,BaseDeConocimientoReducer, SolicitudDeServiciosReducer, ServiciosPlanificadosReducer, 
  GestionDeCalidadReducer, MonitorIntegralReducer, EvaluacionDeLaGestionReducer, RMDPReducer,
});
