import React, { Component } from 'react';
import { Form, Button, Col, Spin, Modal, Select, Row } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalNuevaUbicacion extends Component {
    formRef = React.createRef();
    render() { 
        const {
            onNuevaUbicacion, showModalNuevoUbicaciones, onShowModalNuevoUbicaciones, fetchingNuevoUbicaciones,
            page, limpiarFormulario, fetchingListaAreasFuncionalesActivos, listaAreasFuncionalesActivos, 
            fetchingListaUnidadesFuncioActPorArea, listaUnidadesFuncioActPorArea,
            fetchingListaInteriorActivosPorUnidad, listaInteriorActivosPorUnidad,
            fetchingListaSubInteriorActivosPorInterior, listaSubInteriorActivosPorInterior, 
            onListaUnidadesFuncionalesPorArea, onListaInterioresFuncionalesPorUnidad, onListaSubInterioresFuncionalesPorInterior,
            onLimpiarInterioresFuncionales, onLimpiarSubInterioresFuncionales, onLimpiarUnidadesFuncionales,
        } = this.props;
        
        const onFinish = formulario => {
            console.log('Success:', formulario);
            onNuevaUbicacion(formulario, page);
        };
        
        if (limpiarFormulario && limpiarFormulario === 'crearUbicacion') {
            onLimpiarUnidadesFuncionales();
            onLimpiarInterioresFuncionales();
            onLimpiarSubInterioresFuncionales();
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        

        const handleCancel = () => {
            onShowModalNuevoUbicaciones();
            onLimpiarUnidadesFuncionales();
            onLimpiarInterioresFuncionales();
            onLimpiarSubInterioresFuncionales();
            this.formRef.current.resetFields();
        }
        const onSelect = (value, tipo) => {
            if(tipo === 'idAreaFuncional'){
                onListaUnidadesFuncionalesPorArea(value);
                onLimpiarInterioresFuncionales();
                onLimpiarSubInterioresFuncionales();
                this.formRef.current.resetFields(['idUnidadFuncional']);
            }
            else if(tipo === 'idUnidadFuncional'){
                onListaInterioresFuncionalesPorUnidad(value);
                onLimpiarSubInterioresFuncionales();
                this.formRef.current.resetFields(['idInteriorFuncional']);
            }
            else if(tipo === 'idInteriorFuncional'){
                onListaSubInterioresFuncionalesPorInterior(value);
                onLimpiarSubInterioresFuncionales();
                this.formRef.current.resetFields(['idSubInteriorFuncional']);
            }
        }

        return (  
            <Modal
            visible={showModalNuevoUbicaciones}
            title='Nueva Ubicacion'
            onCancel={handleCancel}
            footer={false}
            >
                <Spin spinning={fetchingNuevoUbicaciones}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >
                        <Row justify='space-around'>
                            <Col span={24}>
                                <Form.Item
                                    label="Area funcional"
                                    name="idAreaFuncional"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el area funcional"
                                        loading={fetchingListaAreasFuncionalesActivos}
                                        onChange={(value) => onSelect(value, 'idAreaFuncional')}
                                    >
                                        {listaAreasFuncionalesActivos.map(option => <Option key={option.idAreaFuncional}>{ option.areaFuncional }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Unidad funcional"
                                    name="idUnidadFuncional"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona la unidad funcional"
                                        loading={fetchingListaUnidadesFuncioActPorArea}
                                        onChange={(value) => onSelect(value, 'idUnidadFuncional')}
                                    >
                                        {listaUnidadesFuncioActPorArea.map(option => <Option key={option.idUnidadFuncional}>{ option.unidadFuncional }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Interior funcional"
                                    name="idInteriorFuncional"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona interior funcional"
                                        loading={fetchingListaInteriorActivosPorUnidad}
                                        onChange={(value) => onSelect(value, 'idInteriorFuncional')}
                                    >
                                        {listaInteriorActivosPorUnidad.map(option => <Option key={option.idInteriorFuncional}>{ option.interiorFuncional }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Subinterior funcional"
                                    name="idSubInteriorFuncional"
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona subinterior funcional"
                                        loading={fetchingListaSubInteriorActivosPorInterior}
                                    >
                                        {listaSubInteriorActivosPorInterior.map(option => <Option key={option.idSubInteriorFuncional}>{ option.subInteriorFuncional }</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Button className="btnFiltrar"   htmlType="submit">Crear Ubicacion</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingNuevoUbicaciones: state.CatalogosGeneralesReducer.fetchingNuevoUbicaciones,
        showModalNuevoUbicaciones: state.CatalogosGeneralesReducer.showModalNuevoUbicaciones,
        fetchingListaAreasFuncionalesActivos: state.CatalogosGeneralesReducer.fetchingListaAreasFuncionalesActivos,
        listaAreasFuncionalesActivos: state.CatalogosGeneralesReducer.listaAreasFuncionalesActivos,
        page: state.CatalogosGeneralesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
        fetchingListaUnidadesFuncioActPorArea: state.CatalogosGeneralesReducer.fetchingListaUnidadesFuncioActPorArea, 
        listaUnidadesFuncioActPorArea: state.CatalogosGeneralesReducer.listaUnidadesFuncioActPorArea,
        fetchingListaInteriorActivosPorUnidad: state.CatalogosGeneralesReducer.fetchingListaInteriorActivosPorUnidad, 
        listaInteriorActivosPorUnidad: state.CatalogosGeneralesReducer.listaInteriorActivosPorUnidad,
        fetchingListaSubInteriorActivosPorInterior: state.CatalogosGeneralesReducer.fetchingListaSubInteriorActivosPorInterior, 
        listaSubInteriorActivosPorInterior: state.CatalogosGeneralesReducer.listaSubInteriorActivosPorInterior,
	};
};

const mapDispatchToProps = dispatch => {
    return {
        onShowModalNuevoUbicaciones: () => {
            dispatch({ type: 'SHOW_MODAL_NUEVO_UBICACION', showModalNuevoUbicaciones: false })
        },
        onNuevaUbicacion: (formulario, page) => {
            dispatch({ type: "NUEVO_UBICACIONES_REQUEST", formulario: formulario, page: page });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false })
        },
        onListaUnidadesFuncionalesPorArea: (idAreaFuncional) => {
            dispatch({ type: 'LISTA_UNI_FUNCIO_ACT_POR_AREA_REQUEST', idAreaFuncional });
        },
        onListaInterioresFuncionalesPorUnidad: (idUnidadFuncional) => {
            dispatch({ type: 'LISTA_INTERIORES_ACT_POR_UNIDAD_REQUEST', idUnidadFuncional });
        },
        onListaSubInterioresFuncionalesPorInterior: (idInteriorFuncional) => {
            dispatch({ type: 'LISTA_SUBINTERIOR_ACTIVOS_POR_INTERIOR_REQUEST', idInteriorFuncional });
        },
        onLimpiarInterioresFuncionales: () => {
            dispatch({ type: 'LISTA_INTERIORES_ACT_POR_UNIDAD_SUCCESS', listaInteriorActivosPorUnidad: [] });
        },
        onLimpiarSubInterioresFuncionales: () => {
            dispatch({ type: 'LISTA_SUBINTERIOR_ACTIVOS_POR_INTERIOR_SUCCESS', listaSubInteriorActivosPorInterior: [] });
        },
        onLimpiarUnidadesFuncionales: () => {
            dispatch({ type: 'LISTA_UNI_FUNCIO_ACT_POR_AREA_SUCCESS', listaUnidadesFuncioActPorArea: [] });
        },
    };
};
 
 
 export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevaUbicacion);