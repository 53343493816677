import React, { useEffect } from 'react';
import { Form, Input, Button, Col, Spin, Modal, Select, Row, message } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { useDispatch, useSelector } from 'react-redux';

const { Option } = Select;

const ModalNuevoServicioVariable = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const showModalNuevoServicioVariable = useSelector((state) => state.ServiciosVariablesReducer.showModalNuevoServicioVariable);
    const fetchingNuevoServicioVariable = useSelector((state) => state.ServiciosVariablesReducer.fetchingNuevoServicioVariable);
    const fetchingListaVariablesActivas = useSelector((state) => state.ServiciosVariablesReducer.fetchingListaVariablesActivas);
    const fetchingListaServiciosActivos = useSelector((state) => state.ServiciosVariablesReducer.fetchingListaServiciosActivos);
    const listaServiciosActivos = useSelector((state) =>  state.ServiciosVariablesReducer.listaServiciosActivos);
    const listaVariablesActivas = useSelector((state) =>  state.ServiciosVariablesReducer.listaVariablesActivas);
    const busqueda =  useSelector((state) => state.ServiciosVariablesReducer.busqueda);
    const page = useSelector((state) => state.ServiciosVariablesReducer.page);

    const handleCancel = () => {
        dispatch({
            type: 'SHOW_MODAL_CREAR_SERVICIO_VARIABLE',
            showModalNuevoServicioVariable: false
        });
        form.resetFields();
    };

    useEffect(() => {
        dispatch({ type: "OBTENER_VARIABLES_ACTIVAS_REQUEST"});
        dispatch({ type: "LISTA_SERVICIOS_ACTIVOS_REQUEST"});
        if(showModalNuevoServicioVariable==false){
            form.resetFields();
        }
    }, [form, showModalNuevoServicioVariable]);    

    const onFinish = formulario => {
        console.log('formulario:', formulario);
        if(!formulario.variablesByIdServicioVariable){
            message.error('Debe de ingresarse al menos una variable');
       }
       else{
       dispatch({ 
            type: "CREAR_SERVICIO_VARIABLE_REQUEST",
            formulario, 
            page,
            busqueda,
            listaVariablesActivas
        });
       }
    }  
    const layout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    return(
        <div>
            <Modal
                visible={showModalNuevoServicioVariable}
                title='Nuevo Servicio Variable'
                onCancel={handleCancel}
                footer={false}
                width='40%'
            >
                <Spin spinning={fetchingNuevoServicioVariable}>
                    <Form 
                        {...layout}
                        name='formulario'
                        onFinish={onFinish}
                        preserve={false}
                        form={form} 
                    >
                       <Row type='flex'>
                            <Col span={24}>
                                <Form.Item
                                    label="Servicio"
                                    name="idServicio"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Select allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Selecciona el servicio"
                                        loading={fetchingListaServiciosActivos}
                                    >
                                        {listaServiciosActivos.map(option => <Option key={option.idServicio}>{option.nombre}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Descripción"
                                    name="descripcion"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                    <Input
                                        pattern="^^[ñíóáéú a-zA-Z]+$"
                                        title="Debe contener letras ."
                                        maxLength={100} placeholder='Ingrese la descripción' />
                                </Form.Item>
                            </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Nombre Variable"
                                        name="variablesByIdServicioVariable"
                                        rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                    >
                                        <Select allowClear
                                            mode='multiple'
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona el servicio"
                                            loading={fetchingListaVariablesActivas}
                                        >
                                            {listaVariablesActivas.map(option => <Option key={option.idVariable}>{option.variable}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Button className="btnFiltrar" htmlType="submit">Crear Servicio Variable</Button>
                                </Col>
                            </Row>
                    </Form>
                </Spin>
            </Modal>
        </div>
    )
}



export default ModalNuevoServicioVariable;