import axios from "axios";

//const endpoint = "https://papantla-services.herokuapp.com/";
//const endpoint = "https://papantla-dev.herokuapp.com/";
const endpoint = 'https://morelos-dev.herokuapp.com/';
//const endpoint = "https://papantla-demo.herokuapp.com/";

//const endpoint = "http://172.24.0.170:8080/"

//const endpoint = 'https://papantla-services-jose.herokuapp.com/';
//const endpoint = 'https://papantla-dev-jose.herokuapp.com/';

//const endpoint = 'https://papantla-secret-jose.herokuapp.com/';
//const endpoint = 'https://papantla-secret.herokuapp.com/';


// Servicio de busqueda de Folios Seguimiento SS 
export function busquedaListaFoliosSeguimientoSSCall(token, param, campo, orden, page){
    return axios({
        method: "get",
		url: endpoint + "seguimiento/solicitud/busqueda?param="+param+"&field="+campo+"&order="+orden+"&page="+page+"&offset=10",
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
    }).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//CATÁLOGO DE TIPOS DE FALLAS
export function listaTiposFallasActivasCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "fallas/tipos/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaTiposFallasBusquedaCall(token, busqueda, page) {
	return axios({
	    method: "get",
		url: endpoint + "fallas/tipos?" + busqueda + "page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoTipoFallaBusquedaCall(token, idTipoFalla) {
	return axios({
	    method: "get",
		url: endpoint + "fallas/tipos/activa/" + idTipoFalla,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}    
    

// Servicio para cambio de estado en folios de Seguimiento
export function cambiarEstadoFolioSeguimientoCall(token, idSolicitudServicio, selector, data){
    console.log(endpoint + "seguimiento/solicitud/reasignar/" + idSolicitudServicio + "?selector=" + selector);
    return axios({
        method: "put",
        url: endpoint + "seguimiento/solicitud/reasignar/" + idSolicitudServicio + "?selector=" + selector,
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        data: data,
    }).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
}
// servicio para lista de servicios con fallas
export function listaServiciosConFallasCall(token){
    return axios({
        method: "get",
		url: endpoint + "servicios/activos/solicitudes",
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
    }).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearTipoFallaBusquedaCall(token, data) {
	return axios({
	    method: "post",
		url: endpoint + "fallas/tipos",
        headers: {'Content-Type': 'application/json','Authorization':token},
        data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarTipoFallaBusquedaCall(token, data, idTipoFalla) {
	return axios({
	    method: "put",
		url: endpoint + "fallas/tipos/" + idTipoFalla,
        headers: {'Content-Type': 'application/json','Authorization':token},
        data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};

// servicio para lista de servicios con fallas
export function listaFallasPorServicioCall(token, idServicio){
    return axios({
        method: "get",
		url: endpoint + "fallas/activos/" + idServicio,
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
    }).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//CATÁLOGO DE FALLAS
export function listaFallasBusquedaCall(token, data) {
	return axios({
	    method: "post",
		url: endpoint + "fallas/obtener",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};
export function exportarFallasExcelCall(token, data) {
	return axios({
		method: "post",
		responseType: 'blob',
		url: endpoint + "fallas/export/excel",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};

export function listaFallasActivasCall(token) {
	return axios({
		method: "get",
		url: endpoint + "fallas/activas",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearFallaCall(token, data) {
	return axios({
	    method: "post",
		url: endpoint + "fallas",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};

export function editarFallaCall(token, data, idFalla) {
	return axios({
	    method: "put",
		url: endpoint + "fallas/" + idFalla,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
};

// obtener ubicacion por idArea, idUnidad , etc..
export function obtenerUbicacionCall(token, idAreaFuncional, idUnidadFuncional, idInteriorFuncional, idSubInteriorFuncional){
    return axios({
        method: "get",
        url: endpoint + "areas/ubicacion/solicitud?idAreaFuncional=" + idAreaFuncional + "&idUnidadFuncional=" + idUnidadFuncional + "&idInteriorFuncional=" + idInteriorFuncional + "&idSubInteriorFuncional=" + idSubInteriorFuncional,
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
    }).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
}

// obtener trazabilidad de folios por folio
export function obtenerTrazabilidadFolioCall(token, folio, page){
    return axios({
        method: "get",
        url: endpoint + "seguimiento/solicitud/trazabilidad?folio=" + folio + "&page=" + page + "&offset=10",
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
    }).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
}

// obtener prorroga historial
export function obtenerProrrogasHistorialCall(token, idSolicitudServicio, page){
    return axios({
        method: "get",
        url: endpoint + "seguimiento/solicitud/prorroga/" + idSolicitudServicio + "?page=" + page + "&offset=10",
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
    }).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
}
// crear proroga
export function crearProrrogaCall(token, data){
    return axios({
        method: "post",
        url: endpoint + "seguimiento/solicitud/prorroga",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
    }).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
}
// obtener traslados call
export function obtenerTrasladosCall(token, idSolicitudServicio, page){
	return axios({
        method: "get",
        url: endpoint + "seguimiento/solicitud/traslado/" + idSolicitudServicio + "?page=" + page + "&offset=10",
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
    }).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
    });
}


export function estadoFallaBusquedaCall(token, idFalla) {
	return axios({
	    method: "get",
		url: endpoint + "fallas/activa/" + idFalla,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// ALTA DE SOLICITUDES DE SERVICIO
export function crearSolicitudDeServicioCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "solicitud/servicio",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}


export function exportarSolicitudDeServicioCall(token, folio) {
return axios({
	method: "get",
	url: endpoint + "solicitud/servicio?folio=" + folio,
	headers: {'Content-Type': 'application/json','Authorization':token},
	responseType: 'blob',
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function identificacionCall(token, loginInfo) {
	return axios({
	    method: "post",
	    url: endpoint+"usuarios/verificar/pin", 
	    headers: {'Content-Type': 'application/json','Authorization':token},
	    data: {
			idUsuario: loginInfo.idUsuario,
			pin: loginInfo.pin
		}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function registrarTiempoLimiteCall(token, usuario) {
	return axios({
		method: "get",
		url: endpoint + "solicitud/servicio/bitacora?nombre=" + usuario,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaFoliosSSCall(token, page, order, idServicio, idUbicacion) {
	return axios({
		method: "get",
		url: endpoint + "seguimiento/solicitud/busqueda?" + idServicio + idUbicacion + "order=" + order + "&field=idSolicitudServicio&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function ubicacionesSinInterioresCall(token) {
	return axios({
		method: "get",
		url: endpoint + "areas/activos/ubicaciones/padre",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaFallasActivasByIdServicioCall(token, idServicio) {
	return axios({
		method: "get",
		url: endpoint + "fallas/activos/" + idServicio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function ubicacionesSinHijosCall(token, param) {
	return axios({
		method: 'get',
		url: endpoint + 'areas/activos/ubicaciones/hijos?param=' + param,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaProveedoresByServicioCall(token, idServicio) {
	return axios({
		method: 'get',
		url: endpoint + 'proveedores/proveedor/servicio/' + idServicio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function visualizarReporteCall(token, fechaReporte) {
	return axios({
		method: "get",
		url: endpoint + "solicitud/servicio/reporte?fechaReporte=" + fechaReporte,
		headers: {'Content-Type': 'application/json','Authorization':token},
		responseType: 'blob',
		}).then(response => {
			return response;
		}).catch(error => {
			return error.response.data;
		});
}

export function listaServiciosActivosConProveedoresCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "servicios/activos/conProveedor",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaSSReporteadorAutoCall(token, fechaReporte, page) {
	return axios({
	    method: "get",
		url: endpoint + "solicitud/servicio/presentacion?fechaReporte=" + fechaReporte + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}


//Reporte de Solicitud de Servicio
export function listaReporteBusquedaCall(token, page, data) {
	return axios({
	    method: "post",
		url: endpoint + "solicitud/servicio/reporte?page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaPartidasByFallaCall(token, idFalla) {
	return axios({
	    method: "get",
		url: endpoint + "partidas/activas/falla?idFalla=" + idFalla,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaPartidasByServicio4FallasCall(token, idServicio) {
	return axios({
	    method: "get",
		url: endpoint + "partidas/activas/servicio?idServicio=" + idServicio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarReporteBusquedaExcelCall(token, data) {
	return axios({
		method: "post",
		responseType: 'blob',
		url: endpoint + "solicitud/servicio/reporte/excel",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarReporteBusquedaPDFCall(token, data) {
	return axios({
		method: "post",
		responseType: 'blob',
		url: endpoint + "solicitud/servicio/reporte/pdf",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Reporte Dinámico
export function listaReporteDinamicoCall (token, data) {
	return axios({
		method: 'post',
		url: endpoint + 'solicitud/servicio/reporte/dinamico',
		headers: {'Content-Type': 'application/json', 'Authorization':token},
		data: data,
	}).then( response => response)
	.catch(error => error.response.data);
}

export function listaReporteDinamicoAgrupamientoCall (token, data) {
	return axios({
		method: 'post',
		url: endpoint + 'solicitud/servicio/reporte/dinamico/grupos',
		headers: {'Content-Type': 'application/json', 'Authorization':token},
		data: data,
	}).then( response => response)
	.catch(error => error.response.data);
}

export function exportarReporteDPDFCall (token, data) {
	return axios({
		method: 'post',
		responseType: 'blob',
		url: endpoint + 'solicitud/servicio/reporte/dinamico/pdf',
		headers: {'Content-Type': 'application/json', 'Authorization': token},
		data: data,
	}).then( response => response)
	.catch( error => error.response.data);
}

export function exportarReporteDExcelCall (token, data) {
	return axios({
		method: 'post',
		responseType: 'blob',
		url: endpoint + 'solicitud/servicio/reporte/dinamico/excel',
		headers: {'Content-Type': 'application/json', 'Authorization': token},
		data: data,
	}).then( response => response)
	.catch( error => error.response.data);
}