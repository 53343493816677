export const CAMBIAR_CATALOGO_RECURSOS_HUMANOS = 'CAMBIAR_CATALOGO_RECURSOS_HUMANOS';

// Alta de Personal
export const LISTA_PERSONAL_BUSQUEDA_REQUEST = 'LISTA_PERSONAL_BUSQUEDA_REQUEST';
export const LISTA_PERSONAL_BUSQUEDA_SUCCESS = 'LISTA_PERSONAL_BUSQUEDA_SUCCESS';
export const LISTA_PERSONAL_BUSQUEDA_FAILURE = 'LISTA_PERSONAL_BUSQUEDA_FAILURE';

export const LISTA_PERSONAL_SERVICIO_REQUEST = 'LISTA_PERSONAL_SERVICIO_REQUEST';
export const LISTA_PERSONAL_SERVICIO_SUCCESS = 'LISTA_PERSONAL_SERVICIO_SUCCESS';
export const LISTA_PERSONAL_SERVICIO_FAILURE = 'LISTA_PERSONAL_SERVICIO_FAILURE';

export const CAMBIAR_ESTADO_PERSONAL_REQUEST = 'CAMBIAR_ESTADO_PERSONAL_REQUEST';
export const CAMBIAR_ESTADO_PERSONAL_SUCCESS = 'CAMBIAR_ESTADO_PERSONAL_SUCCESS';
export const CAMBIAR_ESTADO_PERSONAL_FAILURE = 'CAMBIAR_ESTADO_PERSONAL_FAILURE';

export const DESCARGAR_EXCEL_PERSONAL_REQUEST = 'DESCARGAR_EXCEL_PERSONAL_REQUEST';
export const DESCARGAR_EXCEL_PERSONAL_SUCCESS = 'DESCARGAR_EXCEL_PERSONAL_SUCCESS';
export const DESCARGAR_EXCEL_PERSONAL_FAILURE = 'DESCARGAR_EXCEL_PERSONAL_FAILURE';

export const DESCARGAR_PDF_PERSONAL_REQUEST = 'DESCARGAR_PDF_PERSONAL_REQUEST';
export const DESCARGAR_PDF_PERSONAL_SUCCESS = 'DESCARGAR_PDF_PERSONAL_SUCCESS';
export const DESCARGAR_PDF_PERSONAL_FAILURE = 'DESCARGAR_PDF_PERSONAL_FAILURE';

export const HISTORIAL_PERSONAL_REQUEST = 'HISTORIAL_PERSONAL_REQUEST';
export const HISTORIAL_PERSONAL_SUCCESS = 'HISTORIAL_PERSONAL_SUCCESS';
export const HISTORIAL_PERSONAL_FAILURE = 'HISTORIAL_PERSONAL_FAILURE';

export const NUEVO_PERSONAL_REQUEST = 'NUEVO_PERSONAL_REQUEST';
export const NUEVO_PERSONAL_SUCCESS = 'NUEVO_PERSONAL_SUCCESS';
export const NUEVO_PERSONAL_FAILURE = 'NUEVO_PERSONAL_FAILURE';

export const EDITAR_PERSONAL_REQUEST = 'EDITAR_PERSONAL_REQUEST';
export const EDITAR_PERSONAL_SUCCESS = 'EDITAR_PERSONAL_SUCCESS';
export const EDITAR_PERSONAL_FAILURE = 'EDITAR_PERSONAL_FAILURE';

export const SHOW_MODAL_NUEVO_PERSONAL = 'SHOW_MODAL_NUEVO_PERSONAL';
export const SHOW_MODAL_EDITAR_PERSONAL = 'SHOW_MODAL_EDITAR_PERSONAL';
export const SHOW_MODAL_ESTADO_PERSONAL = 'SHOW_MODAL_ESTADO_PERSONAL';
export const SHOW_MODAL_HISTORIAL_PERSONAL = 'SHOW_MODAL_HISTORIAL_PERSONAL';

export const VISTA_NUEVO_PERSONAL = 'VISTA_NUEVO_PERSONAL';

// Control de Asistencia
export const LISTA_CONTROL_ASISTENCIA_BUSQUEDA_REQUEST = 'LISTA_CONTROL_ASISTENCIA_BUSQUEDA_REQUEST';
export const LISTA_CONTROL_ASISTENCIA_BUSQUEDA_SUCCESS = 'LISTA_CONTROL_ASISTENCIA_BUSQUEDA_SUCCESS';
export const LISTA_CONTROL_ASISTENCIA_BUSQUEDA_FAILURE = 'LISTA_CONTROL_ASISTENCIA_BUSQUEDA_FAILURE';

export const LISTA_CONTROL_ASISTENCIA_EXPORTAR_REQUEST = 'LISTA_CONTROL_ASISTENCIA_EXPORTAR_REQUEST';
export const LISTA_CONTROL_ASISTENCIA_EXPORTAR_SUCCESS = 'LISTA_CONTROL_ASISTENCIA_EXPORTAR_SUCCESS';
export const LISTA_CONTROL_ASISTENCIA_EXPORTAR_FAILURE = 'LISTA_CONTROL_ASISTENCIA_EXPORTAR_FAILURE';

export const IMPORTAR_BIOMETRICO_REQUEST = 'IMPORTAR_BIOMETRICO_REQUEST';
export const IMPORTAR_BIOMETRICO_SUCCESS = 'IMPORTAR_BIOMETRICO_SUCCESS';
export const IMPORTAR_BIOMETRICO_FAILURE = 'IMPORTAR_BIOMETRICO_FAILURE';

// Reporte Dinamico
export const LISTA_REPORTE_DINAMICO_RH_BUSQUEDA_REQUEST = 'LISTA_REPORTE_DINAMICO_RH_BUSQUEDA_REQUEST';
export const LISTA_REPORTE_DINAMICO_RH_BUSQUEDA_SUCCESS = 'LISTA_REPORTE_DINAMICO_RH_BUSQUEDA_SUCCESS';
export const LISTA_REPORTE_DINAMICO_RH_BUSQUEDA_FAILURE = 'LISTA_REPORTE_DINAMICO_RH_BUSQUEDA_FAILURE';

export const REPORTE_DINAMICO_AGRUPADO_RH_REQUEST = 'REPORTE_DINAMICO_AGRUPADO_RH_REQUEST';
export const REPORTE_DINAMICO_AGRUPADO_RH_SUCCESS = 'REPORTE_DINAMICO_AGRUPADO_RH_SUCCESS';
export const REPORTE_DINAMICO_AGRUPADO_RH_FAILURE = 'REPORTE_DINAMICO_AGRUPADO_RH_FAILURE';

export const PDF_REPORTE_DINAMICO_RH_REQUEST = 'PDF_REPORTE_DINAMICO_RH_REQUEST';
export const PDF_REPORTE_DINAMICO_RH_SUCCESS = 'PDF_REPORTE_DINAMICO_RH_SUCCESS';
export const PDF_REPORTE_DINAMICO_RH_FAILURE = 'PDF_REPORTE_DINAMICO_RH_FAILURE';

export const EXCEL_REPORTE_DINAMICO_RH_REQUEST = 'EXCEL_REPORTE_DINAMICO_RH_REQUEST';
export const EXCEL_REPORTE_DINAMICO_RH_SUCCESS = 'EXCEL_REPORTE_DINAMICO_RH_SUCCESS';
export const EXCEL_REPORTE_DINAMICO_RH_FAILURE = 'EXCEL_REPORTE_DINAMICO_RH_FAILURE';

export const LIMPIAR_FORMULARIO_REPORTE_RH = 'LIMPIAR_FORMULARIO_REPORTE_RH';

export const LISTA_GRUPO_SERVICIOS_BYIDUSUARIO_REQUEST = 'LISTA_GRUPO_SERVICIOS_BYIDUSUARIO_REQUEST';
export const LISTA_GRUPO_SERVICIOS_BYIDUSUARIO_SUCCESS = 'LISTA_GRUPO_SERVICIOS_BYIDUSUARIO_SUCCESS';
export const LISTA_GRUPO_SERVICIOS_BYIDUSUARIO_FAILURE = 'LISTA_GRUPO_SERVICIOS_BYIDUSUARIO_FAILURE';

export const LISTA_GERENCIAS_BYIDUSUARIO_REQUEST = 'LISTA_GERENCIAS_BYIDUSUARIO_REQUEST';
export const LISTA_GERENCIAS_BYIDUSUARIO_SUCCESS = 'LISTA_GERENCIAS_BYIDUSUARIO_SUCCESS';
export const LISTA_GERENCIAS_BYIDUSUARIO_FAILURE = 'LISTA_GERENCIAS_BYIDUSUARIO_FAILURE';

export const PDF_REPORTE_DINAMICO_RH_AGRUPADO_REQUEST = 'PDF_REPORTE_DINAMICO_RH_AGRUPADO_REQUEST';
export const PDF_REPORTE_DINAMICO_RH_AGRUPADO_SUCCESS = 'PDF_REPORTE_DINAMICO_RH_AGRUPADO_SUCCESS';
export const PDF_REPORTE_DINAMICO_RH_AGRUPADO_FAILURE = 'PDF_REPORTE_DINAMICO_RH_AGRUPADO_FAILURE';

export const EXCEL_REPORTE_DINAMICO_RH_AGRUPADO_REQUEST = 'EXCEL_REPORTE_DINAMICO_RH_AGRUPADO_REQUEST';
export const EXCEL_REPORTE_DINAMICO_RH_AGRUPADO_SUCCESS = 'EXCEL_REPORTE_DINAMICO_RH_AGRUPADO_SUCCESS';
export const EXCEL_REPORTE_DINAMICO_RH_AGRUPADO_FAILURE = 'EXCEL_REPORTE_DINAMICO_RH_AGRUPADO_FAILURE';

export const GET_ALL_REGISTROS_REQUEST = 'GET_ALL_REGISTROS_REQUEST';
export const GET_ALL_REGISTROS_SUCCESS = 'GET_ALL_REGISTROS_SUCCESS';
export const GET_ALL_REGISTROS_FAILURE = 'GET_ALL_REGISTROS_FAILURE';

export const GET_REGISTROS_BY_TOKEN_REQUEST = 'GET_REGISTROS_BY_TOKEN_REQUEST';
export const GET_REGISTROS_BY_TOKEN_SUCCESS = 'GET_REGISTROS_BY_TOKEN_SUCCESS';
export const GET_REGISTROS_BY_TOKEN_FAILURE = 'GET_REGISTROS_BY_TOKEN_FAILURE';

export const CREATE_REGISTROS_REQUEST = 'CREATE_REGISTROS_REQUEST';
export const CREATE_REGISTROS_SUCCESS = 'CREATE_REGISTROS_SUCCESS';
export const CREATE_REGISTROS_FAILURE = 'CREATE_REGISTROS_FAILURE';

export const EDIT_REGISTROS_REQUEST = 'EDIT_REGISTROS_REQUEST';
export const EDIT_REGISTROS_SUCCESS = 'EDIT_REGISTROS_SUCCESS';
export const EDIT_REGISTROS_FAILURE = 'EDIT_REGISTROS_FAILURE';

export const ESTADO_REGISTROS_REQUEST = 'ESTADO_REGISTROS_REQUEST';
export const ESTADO_REGISTROS_SUCCESS = 'ESTADO_REGISTROS_SUCCESS';
export const ESTADO_REGISTROS_FAILURE = 'ESTADO_REGISTROS_FAILURE';

export const MODAL_CREAR_REGISTRO = 'MODAL_CREAR_REGISTRO';

export const MODAL_EDITAR_REGISTRO = 'MODAL_EDITAR_REGISTRO';