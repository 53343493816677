//Actions Mapa de Incidencias
export const LISTA_AREAS_NUMERO_REQUEST = 'LISTA_AREAS_NUMERO_REQUEST';
export const LISTA_AREAS_NUMERO_SUCCESS = 'LISTA_AREAS_NUMERO_SUCCESS';
export const LISTA_AREAS_NUMERO_FAILURE = 'LISTA_AREAS_NUMERO_FAILURE';

export const LISTA_UNIDADES_NUMERO_REQUEST = 'LISTA_UNIDADES_NUMERO_REQUEST';
export const LISTA_UNIDADES_NUMERO_SUCCESS = 'LISTA_UNIDADES_NUMERO_SUCCESS';
export const LISTA_UNIDADES_NUMERO_FAILURE = 'LISTA_UNIDADES_NUMERO_FAILURE';

export const LISTA_INTERIORES_NUMERO_REQUEST = 'LISTA_INTERIORES_NUMERO_REQUEST';
export const LISTA_INTERIORES_NUMERO_SUCCESS = 'LISTA_INTERIORES_NUMERO_SUCCESS';
export const LISTA_INTERIORES_NUMERO_FAILURE = 'LISTA_INTERIORES_NUMERO_FAILURE';

export const LISTA_SUBINTERIORES_NUMERO_REQUEST = 'LISTA_SUBINTERIORES_NUMERO_REQUEST';
export const LISTA_SUBINTERIORES_NUMERO_SUCCESS = 'LISTA_SUBINTERIORES_NUMERO_SUCCESS';
export const LISTA_SUBINTERIORES_NUMERO_FAILURE = 'LISTA_SUBINTERIORES_NUMERO_FAILURE';

export const LISTA_DETALLES_SS_REQUEST = 'LISTA_DETALLES_SS_REQUEST';
export const LISTA_DETALLES_SS_SUCCESS = 'LISTA_DETALLES_SS_SUCCESS';
export const LISTA_DETALLES_SS_FAILURE = 'LISTA_DETALLES_SS_FAILURE';

export const INFO_DETALLE_SS_REQUEST = 'INFO_DETALLE_SS_REQUEST';
export const INFO_DETALLE_SS_SUCCESS = 'INFO_DETALLE_SS_SUCCESS';
export const INFO_DETALLE_SS_FAILURE = 'INFO_DETALLE_SS_FAILURE';

export const ATRAS_MAPA = 'ATRAS_MAPA';

//Actions Tablero de Operaciones
export const LISTA_GRUPOSERVICIOS_REQUEST = "LISTA_GRUPOSERVICIOS_REQUEST";
export const LISTA_GRUPOSERVICIOS_SUCCESS = "LISTA_GRUPOSERVICIOS_SUCCESS";
export const LISTA_GRUPOSERVICIOS_FAILURE = "LISTA_GRUPOSERVICIOS_FAILURE";

export const SERVICIO_POR_GRUPOSERVICIO_REQUEST = "SERVICIO_POR_GRUPOSERVICIO_REQUEST";
export const SERVICIO_POR_GRUPOSERVICIO_SUCCESS = "SERVICIO_POR_GRUPOSERVICIO_SUCCESS";
export const SERVICIO_POR_GRUPOSERVICIO_FAILURE = "SERVICIO_POR_GRUPOSERVICIO_FAILURE";

export const SHOW_MODAL_SOLICITUDES_DE_SERVICIO = "SHOW_MODAL_SOLICITUDES_DE_SERVICIO";

export const SOLICITUDES_DE_SERVICIO_POR_SERVICIO_REQUEST = "SOLICITUDES_DE_SERVICIO_POR_SERVICIO_REQUEST";
export const SOLICITUDES_DE_SERVICIO_POR_SERVICIO_SUCCESS = "SOLICITUDES_DE_SERVICIO_POR_SERVICIO_SUCCESS";
export const SOLICITUDES_DE_SERVICIO_POR_SERVICIO_FAILURE = "SOLICITUDES_DE_SERVICIO_POR_SERVICIO_FAILURE";

export const CANTIDAD_FOLIOS_POR_IDGRUPOSERVICIO_REQUEST = "CANTIDAD_FOLIOS_POR_IDGRUPOSERVICIO_REQUEST";
export const CANTIDAD_FOLIOS_POR_IDGRUPOSERVICIO_SUCCESS = "CANTIDAD_FOLIOS_POR_IDGRUPOSERVICIO_SUCCESS";
export const CANTIDAD_FOLIOS_POR_IDGRUPOSERVICIO_FAILURE = "CANTIDAD_FOLIOS_POR_IDGRUPOSERVICIO_FAILURE";

export const SHOW_MODAL_TRAZABILIDAD = 'SHOW_MODAL_TRAZABILIDAD';

export const DATA_GRAFICAS_ENCUESTAS_SATISFACCION_REQUEST = 'DATA_GRAFICAS_ENCUESTAS_SATISFACCION_REQUEST';
export const DATA_GRAFICAS_ENCUESTAS_SATISFACCION_SUCCESS = 'DATA_GRAFICAS_ENCUESTAS_SATISFACCION_SUCCESS';
export const DATA_GRAFICAS_ENCUESTAS_SATISFACCION_FAILURE = 'DATA_GRAFICAS_ENCUESTAS_SATISFACCION_FAILURE';

export const DATA_GRAFICAS_ENCUSTAS_SATISFACCION_SERVICIO_REQUEST = 'DATA_GRAFICAS_ENCUSTAS_SATISFACCION_SERVICIO_REQUEST';
export const DATA_GRAFICAS_ENCUSTAS_SATISFACCION_SERVICIO_SUCCESS = 'DATA_GRAFICAS_ENCUSTAS_SATISFACCION_SERVICIO_SUCCESS';
export const DATA_GRAFICAS_ENCUSTAS_SATISFACCION_SERVICIO_FAILURE = 'DATA_GRAFICAS_ENCUSTAS_SATISFACCION_SERVICIO_FAILURE';

export const DATA_GRAFICAS_QUEJAS_REQUEST = 'DATA_GRAFICAS_QUEJAS_REQUEST';
export const DATA_GRAFICAS_QUEJAS_SUCCESS = 'DATA_GRAFICAS_QUEJAS_SUCCESS';
export const DATA_GRAFICAS_QUEJAS_FAILURE = 'DATA_GRAFICAS_QUEJAS_FAILURE';

export const DATA_GRAFICAS_QUEJAS_SERVICIO_REQUEST = 'DATA_GRAFICAS_QUEJAS_SERVICIO_REQUEST';
export const DATA_GRAFICAS_QUEJAS_SERVICIO_SUCCESS = 'DATA_GRAFICAS_QUEJAS_SERVICIO_SUCCESS';
export const DATA_GRAFICAS_QUEJAS_SERVICIO_FAILURE = 'DATA_GRAFICAS_QUEJAS_SERVICIO_FAILURE';

export const DATA_GRAFICAS_EVALUACION_DESEMPENO_REQUEST = 'DATA_GRAFICAS_EVALUACION_DESEMPENO_REQUEST';
export const DATA_GRAFICAS_EVALUACION_DESEMPENO_SUCCESS = 'DATA_GRAFICAS_EVALUACION_DESEMPENO_SUCCESS';
export const DATA_GRAFICAS_EVALUACION_DESEMPENO_FAILURE = 'DATA_GRAFICAS_EVALUACION_DESEMPENO_FAILURE';

export const LISTA_SERVICIOS_BY_CLASIFICACION_GERENCIA_REQUEST = 'LISTA_SERVICIOS_BY_CLASIFICACION_GERENCIA_REQUEST';
export const LISTA_SERVICIOS_BY_CLASIFICACION_GERENCIA_SUCCESS = 'LISTA_SERVICIOS_BY_CLASIFICACION_GERENCIA_SUCCESS';
export const LISTA_SERVICIOS_BY_CLASIFICACION_GERENCIA_FAILURE = 'LISTA_SERVICIOS_BY_CLASIFICACION_GERENCIA_FAILURE';

export const DATA_GRAFICA_PROMEDIO_SATISFACCION_REQUEST = 'DATA_GRAFICA_PROMEDIO_SATISFACCION_REQUEST';
export const DATA_GRAFICA_PROMEDIO_SATISFACCION_SUCCESS = 'DATA_GRAFICA_PROMEDIO_SATISFACCION_SUCCESS';
export const DATA_GRAFICA_PROMEDIO_SATISFACCION_FAILURE = 'DATA_GRAFICA_PROMEDIO_SATISFACCION_FAILURE';

export const DATA_ESTANDARES_BY_TIPO_REQUEST = 'DATA_ESTANDARES_BY_TIPO_REQUEST';
export const DATA_ESTANDARES_BY_TIPO_SUCCESS = 'DATA_ESTANDARES_BY_TIPO_SUCCESS';
export const DATA_ESTANDARES_BY_TIPO_FAILURE = 'DATA_ESTANDARES_BY_TIPO_FAILURE';

// RDO | Monitoreo de Módulos Operativos
export const MONITOR_MODULOS_OPERATIVOS_SS_REQUEST =  'MONITOR_MODULOS_OPERATIVOS_SS_REQUEST';
export const MONITOR_MODULOS_OPERATIVOS_SS_SUCCESS =  'MONITOR_MODULOS_OPERATIVOS_SS_SUCCESS';
export const MONITOR_MODULOS_OPERATIVOS_SS_FAILURE =  'MONITOR_MODULOS_OPERATIVOS_SS_FAILURE';

export const MONITOR_MODULOS_OPERATIVOS_SP_REQUEST = 'MONITOR_MODULOS_OPERATIVOS_SP_REQUEST';
export const MONITOR_MODULOS_OPERATIVOS_SP_SUCCESS = 'MONITOR_MODULOS_OPERATIVOS_SP_SUCCESS';
export const MONITOR_MODULOS_OPERATIVOS_SP_FAILURE = 'MONITOR_MODULOS_OPERATIVOS_SP_FAILURE';

export const MONITOR_MODULOS_PLANIFICADOS_DATOS_REQUEST = 'MONITOR_MODULOS_PLANIFICADOS_DATOS_REQUEST';
export const MONITOR_MODULOS_PLANIFICADOS_DATOS_SUCCESS = 'MONITOR_MODULOS_PLANIFICADOS_DATOS_SUCCESS';
export const MONITOR_MODULOS_PLANIFICADOS_DATOS_FAILURE = 'MONITOR_MODULOS_PLANIFICADOS_DATOS_FAILURE';

export const DATA_SERV_PUBLIC_REQUEST = 'DATA_SERV_PUBLIC_REQUEST';
export const DATA_SERV_PUBLIC_SUCCESS = 'DATA_SERV_PUBLIC_SUCCESS';
export const DATA_SERV_PUBLIC_FAILURE = 'DATA_SERV_PUBLIC_FAILURE';

//Reporte Gráfico
export const GET_REPORTE_GRAFICO_REQUEST = 'GET_REPORTE_GRAFICO_REQUEST';
export const GET_REPORTE_GRAFICO_SUCCESS = 'GET_REPORTE_GRAFICO_SUCCESS';
export const GET_REPORTE_GRAFICO_FAILURE = 'GET_REPORTE_GRAFICO_FAILURE';

export const SHOW_MODAL_INFO_REPORTE_GRAFICO = 'SHOW_MODAL_INFO_REPORTE_GRAFICO';

export const GET_REPORTE_GRAFICO_GRUPO_REQUEST = 'GET_REPORTE_GRAFICO_GRUPO_REQUEST';
export const GET_REPORTE_GRAFICO_GRUPO_SUCCESS = 'GET_REPORTE_GRAFICO_GRUPO_SUCCESS';
export const GET_REPORTE_GRAFICO_GRUPO_FAILURE = 'GET_REPORTE_GRAFICO_GRUPO_FAILURE';
