import React, { Component } from 'react';
import { Form, Input, Button, Modal, Col, Spin, Row } from 'antd';
import '../../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css'
import { connect } from 'react-redux';
import moment from 'moment';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

class ModalEditarCategoriaDeMedicion extends Component {
    formRef = React.createRef();

    render() {

        const { fetchingEditarCategoriaDeMedicion, showModalEditarCategoriaDeMedicion, onEditarCategoriaDeMedicion, onShowModalEditarCategoriaDeMedicion,
            CategoriaDeMedicionSeleccionado, page, limpiarFormulario } = this.props;

        console.log('CategoriaDeMedicionSeleccionado en modal');
        console.log(CategoriaDeMedicionSeleccionado);

        const onFinish = formulario => {
            console.log('Success:', formulario);
            onEditarCategoriaDeMedicion(formulario, CategoriaDeMedicionSeleccionado, page);

        };
        if (limpiarFormulario && limpiarFormulario==='editarCategoria') {
            this.formRef.current.resetFields();
            this.props.onLimpiarFormulario();
        }

        

        const handleCancel = () => {
            onShowModalEditarCategoriaDeMedicion();
        }

        return (
            <Modal
                visible={showModalEditarCategoriaDeMedicion}
                title='Editar Categoría de Medición'
                onCancel={handleCancel}
                footer={false}>
                <Spin spinning={fetchingEditarCategoriaDeMedicion}>
                    {CategoriaDeMedicionSeleccionado ? (
                        <Form {...layout}
                            name="formulario"
                            initialValues={CategoriaDeMedicionSeleccionado}
                            onFinish={onFinish}
                            
                            ref={this.formRef}
                        >
                            <Form.Item
                                label="Categoría de medición"
                                name="categoriaUnidadMedida"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                            >
                                <Input 
                                pattern="^^[ñíóáéú a-zA-Z ]+$"
                                title="Categoría de Medición debe tener letras." 
                                maxLength={80} placeholder='Categoría de Medición' />
                            </Form.Item>
                            <Form.Item
                                label="Clave de categoría"
                                name="claveCategoria"
                                rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                <Input
                                maxLength={80} placeholder='Clave de categoría' />
                            </Form.Item>
                            <Row justify='space-around'>
                                <Col span={12}>
                                    <Button style={{width:'100%'}} className="btnFiltrar" htmlType="submit">Editar Categoría de Medición</Button>
                                </Col>
                            </Row>
                        </Form>
                    ) : false}
                </Spin>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        fetchingEditarCategoriaDeMedicion: state.AlmacenesReducer.fetchingEditarCategoriaDeMedicion,
        showModalEditarCategoriaDeMedicion: state.AlmacenesReducer.showModalEditarCategoriaDeMedicion,
        CategoriaDeMedicionSeleccionado: state.AlmacenesReducer.CategoriaDeMedicionSeleccionado,
        page: state.AlmacenesReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarCategoriaDeMedicion: () => {
            dispatch({ type: 'SHOW_MODAL_EDITAR_CATEGORIA_MEDICION', showModalEditarCategoriaDeMedicion: false, CategoriaDeMedicionSeleccionado: null })
        },
        onEditarCategoriaDeMedicion: (formulario, CategoriaDeMedicionSeleccionado, page) => {
            dispatch({ type: 'EDITAR_CATEGORIA_MEDICION_REQUEST', formulario: formulario, CategoriaDeMedicionSeleccionado: CategoriaDeMedicionSeleccionado, page:page, })
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarCategoriaDeMedicion);