import React, { Component } from 'react';
import {  } from 'antd';
import { connect } from "react-redux";
import TerminadoForm from './FormulariosSeguimiento/TerminadoForm';
import ListasEppInsumosHerramientas from './ListasEppInsumosHerramienta';

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';

class EstadoTerminadoContainer extends Component {
    
    render() { 
        const {cambiarVistaModalEstadoTerminado, folioSolicitudServicioSeleccionado} = this.props;

        function Contenido() {
            if(!cambiarVistaModalEstadoTerminado){
                return(
                    <TerminadoForm/>
                );
            }
            else {
                return(
                    <div><ListasEppInsumosHerramientas/></div>
                );
            }
        };
        return (
            <div>
                <Contenido />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        cambiarVistaModalEstadoTerminado: state.SolicitudDeServiciosReducer.cambiarVistaModalEstadoTerminado,
        folioSolicitudServicioSeleccionado: state.SolicitudDeServiciosReducer.folioSolicitudServicioSeleccionado,
    }
} 

const mapDispatchToProps = (dispatch) => {
    return {
        onSiguienteVista: (cambiarVistaModalEstadoTerminado, formularioTerminado, listaEpp, listaHerramienta, listaInsumosT, folioSolicitudServicioSeleccionado) => {
            dispatch({ type: "CAMBIAR_VISTA_MODAL_ESTADO_TERMINADO", cambiarVistaModalEstadoTerminado, formularioTerminado, listaEpp, listaHerramienta, listaInsumosT, folioSolicitudServicioSeleccionado});
          },
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(EstadoTerminadoContainer);