import React, { Component } from 'react';
import { Form, Input, Button, Row, Col, Spin, Modal, Select, Tooltip } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import { SaveOutlined } from '@ant-design/icons';

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class NuevoTipoMoneda extends Component {
     formRef = React.createRef();
     render() {
          const {  limpiarFormulario, } = this.props;

          const onFinish = formulario => {
               console.log('Success:', formulario);
               this.props.onNuevo(formulario, this.props.page, this.props.busqueda);
          };

          if (limpiarFormulario && limpiarFormulario === 'crearTipoMoneda') {
               this.formRef.current.resetFields();
               this.props.onLimpiarFormulario();
          }
               

          

          const handleCancel = () => {
               this.props.onCloseModal();
               this.formRef.current.resetFields();
          }

          return (
               <Modal
               visible={this.props.showModalNuevoTipoMoneda}
               title='Nuevo Tipo de Moneda'
               onCancel={handleCancel}
               footer={false}
               >
                    <Spin spinning={this.props.fetchingNuevoTipoMoneda}>
                         <Form {...layout}
                         name="formulario"
                         onFinish={onFinish}
                         
                         ref={this.formRef}
                         >
                         <Form.Item
                              label="Moneda"
                              name="nombre"
                              rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                         >
                              <Input maxLength={30} placeholder="Ingrese nombre del tipo de moneda" pattern="^[a-zñA-ZÑ ÁÉÓÚÍáéóíú]+$" title="El nombre sólo debe contener letras"/>
                         </Form.Item>

                         <Form.Item
                              label="Nomenclatura"
                              name="nomenclatura"
                              rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                         >
                              <Input maxLength={3} placeholder="Ingrese la nomenclatura del tipo de moneda" pattern="^[a-zñA-ZÑ ÁÉÓÚÍáéóíú]+$" title="El nombre sólo debe contener letras"/>
                         </Form.Item>

                         {/* <Row justify='center'>
                              <Col span={11}>
                                   <Button className="btnFiltrar" htmlType="submit">Crear Tipo de Moneda</Button>
                              </Col>
                         </Row> */}

                         <Row justify='end' gutter={[8,8]}>
                              <Col span={1} style={{paddingRight:'2.8em', paddingTop:'2em'}}>
                                   <Tooltip title='Crear Tipo de Moneda' placement="bottom">
                                        <Button className="iconTer" shape="circle" htmlType="submit" icon={<SaveOutlined />}/>
                                   </Tooltip> 
                              </Col>
                         </Row>
                         </Form>
                    </Spin>
               </Modal>
          );
     }
}

const mapStateToProps = state => {
	return {
          fetchingNuevoTipoMoneda: state.CatalogosGeneralesReducer.fetchingNuevoTipoMoneda,
          showModalNuevoTipoMoneda: state.CatalogosGeneralesReducer.showModalNuevoTipoMoneda,
          page: state.CatalogosGeneralesReducer.page,
          busqueda: state.CatalogosGeneralesReducer.busqueda,
          
          limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
	};
};

const mapDispatchToProps = dispatch => {
	return {
          onCloseModal: () => {
               dispatch({ type: 'SHOW_MODAL_NUEVO_TIPO_MONEDA', showModalNuevoTipoMoneda: false})
          },
          onNuevo: ( formulario, page, busqueda ) => {
               dispatch({ type: "NUEVO_TIPO_MONEDA_REQUEST" , formulario, page, busqueda });
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: false})
          }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NuevoTipoMoneda);