import React from 'react';
import { Card, Select, Tooltip, Row, Col, Table, Button, Alert, Radio, Typography, List, Form, DatePicker, Tabs, Divider } from 'antd';
import { connect } from "react-redux";
import {InfoCircleTwoTone} from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import moment from 'moment';
import imagenNoResult from '../../../assets/no-result-icon.png';
import { RightCircleOutlined, LeftCircleOutlined, EllipsisOutlined, SearchOutlined, FilterOutlined, GroupOutlined } from '@ant-design/icons';
import ModalInfo from './ModalInfo';

const {Option} = Select;
const { Text } = Typography;
const { Meta } = Card;
const { RangePicker } = DatePicker;
const layout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };
const { TabPane } = Tabs;

class ReporteGraficoContainer extends React.Component {
     formRef = React.createRef();
     componentDidMount(){ this.props.onGetAreasFuncionales(); this.props.onGetGerencias(); this.props.onGetGruposServiciosActivos(); this.props.onRequestInfoProyecto(); this.props.onGetListGraphReport({}, 1, {orden: 'DESC', field: 'sse.fechaAlta'}, 'sinAgrupar', null); }

     state = { page: 1, pageAgrupado: 1, colDer: 19,  colIzq: 5, maximizar: false, boolGrupo: false, boolClasificacion: false, gerencias:[], gruposSer:[], servicios: [], estados: [], fechas: [], idAreaFuncional: null, idUnidadFuncional: null, idInteriorFuncional: null, idSubInteriorFuncional: null, orden: 'DESC', field: 'sse.fechaAlta', agrupado: 'sinAgrupar', tipoAgrupado: '', orderAgr: null, clasificaciones: [] }

     addDefaultSrc(ev){
          ev.target.src = imagenNoResult;
     }
 
     render() {
          const { listaAreasFuncionalesActivas,
            fetchingListaUnidadesFuncionalesActivas,
            listaUnidadesFuncionalesActivas,
            fetchingListaInteriorFuncional,
            listaInteriorFuncional,
            fetchingListaSubinteriorFuncional,
            listaSubinteriorFuncional, } = this.props;

          const onFinish = (formulario) => { console.log('formulario', formulario) };

          const onExpandir = () => { if(!this.state.maximizar){ this.setState({ colIzq: 0, colDer: 24, maximizar: true }); } else{ this.setState({  colIzq: 5, colDer: 19, maximizar: false }); }  };

          const listChangePage = (pagination) => {
               let filtro = { gerencias: this.state.gerencias, gruposSer: this.state.gruposSer, servicios: this.state.servicios, estados: this.state.estados, fechas: this.state.fechas, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idInteriorFuncional: this.state.idInteriorFuncional, idSubInteriorFuncional: this.state.idSubInteriorFuncional, clasificaciones: this.state.clasificaciones };
               let order = { orden: this.state.orden, field: this.state.field }
               this.setState({ page: pagination });
               this.props.onGetListGraphReport(filtro, pagination, order, this.state.agrupado);
          };

          const listChangePageAgrupado = (record, pagination) => {
               console.log('record', record, pagination)
               let filtro = { gerencias: this.state.gerencias, gruposSer: this.state.gruposSer, servicios: this.state.servicios, estados: this.state.estados, fechas: this.state.fechas, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idInteriorFuncional: this.state.idInteriorFuncional, idSubInteriorFuncional: this.state.idSubInteriorFuncional, clasificaciones: this.state.clasificaciones };
               let order = { orden: this.state.orden, field: this.state.field }
               record.listReporteGraficoAgrupado = this.props.listReporteGraficoAgrupado;
               record.listReporteGraficoAgrupadoCount = this.props.listReporteGraficoAgrupadoCount;
               this.props.onGetListGraphReport(filtro, pagination, order, this.state.agrupado, record);

          };

          const onClasificacionSeleccionado = (e) => { 
               if(e.length > 0){ 
                    let filtro = { gerencias: e, gruposSer: this.state.gruposSer, servicios: this.state.servicios, estados: this.state.estados, fechas: this.state.fechas, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idInteriorFuncional: this.state.idInteriorFuncional, idSubInteriorFuncional: this.state.idSubInteriorFuncional, clasificaciones: this.state.clasificaciones };
                    let order = { orden: this.state.orden, field: this.state.field }
                    this.setState({boolClasificacion: true, gerencias: e });
                    this.props.onListaServiciosByIdsGerencias(e);
                    this.props.onGetListGraphReport(filtro, this.state.page, order, this.state.agrupado);
               } else { 
                    this.formRef.current.setFieldsValue({'idsServicios': undefined});
                    let filtro = { gerencias: [], gruposSer: this.state.gruposSer, servicios: [], estados: this.state.estados, fechas: this.state.fechas, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idInteriorFuncional: this.state.idInteriorFuncional, idSubInteriorFuncional: this.state.idSubInteriorFuncional, clasificaciones: this.state.clasificaciones };
                    let order = { orden: this.state.orden, field: this.state.field }
                    this.setState({boolClasificacion: false, gerencias: [], servicios: [] }); 
                    this.props.onGetListGraphReport(filtro, this.state.page, order, this.state.agrupado);
               } 
          };

          const onGrupoSeleccionado = (e) => { 
               if(e.length > 0){ 
                    let filtro = { gerencias: this.state.gerencias, gruposSer: e, servicios: this.state.servicios, estados: this.state.estados, fechas: this.state.fechas, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idInteriorFuncional: this.state.idInteriorFuncional, idSubInteriorFuncional: this.state.idSubInteriorFuncional, clasificaciones: this.state.clasificaciones };
                    let order = { orden: this.state.orden, field: this.state.field }
                    this.props.onGetListGraphReport(filtro, this.state.page, order, this.state.agrupado);
                    this.setState({boolGrupo: true, gruposSer: e}); 
                    this.props.onListaServiciosByIdsGrupos(e);
               } else { 
                    this.formRef.current.setFieldsValue({'idsServicios': undefined});
                    let filtro = { gerencias: this.state.gerencias, gruposSer: [], servicios: [], estados: this.state.estados, fechas: this.state.fechas, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idInteriorFuncional: this.state.idInteriorFuncional, idSubInteriorFuncional: this.state.idSubInteriorFuncional, clasificaciones: this.state.clasificaciones };
                    let order = { orden: this.state.orden, field: this.state.field }
                    this.setState({boolGrupo: false, gruposSer: [], servicios: []}); 
                    this.props.onGetListGraphReport(filtro, this.state.page, order, this.state.agrupado);
               } 
          };

          const onEstadoSeleccionado = (e) => {
               if(e.length > 0){
                    let filtro = { gerencias: this.state.gerencias, gruposSer: this.state.gruposSer, servicios: this.state.servicios, estados: e, fechas: this.state.fechas , idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idInteriorFuncional: this.state.idInteriorFuncional, idSubInteriorFuncional: this.state.idSubInteriorFuncional, clasificaciones: this.state.clasificaciones};
                    let order = { orden: this.state.orden, field: this.state.field }
                    this.props.onGetListGraphReport(filtro, this.state.page, order, this.state.agrupado);
                    this.setState({estados: e}); 
               } else {
                    let filtro = { gerencias: this.state.gerencias, gruposSer: this.state.gruposSer, servicios: this.state.servicios, estados: [], fechas: this.state.fechas, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idInteriorFuncional: this.state.idInteriorFuncional, idSubInteriorFuncional: this.state.idSubInteriorFuncional, clasificaciones: this.state.clasificaciones };
                    let order = { orden: this.state.orden, field: this.state.field }
                    this.props.onGetListGraphReport(filtro, this.state.page, order, this.state.agrupado);
                    this.setState({estados: []}); 
               }
          };

          const  disabledDate = (fecha) => {
               let today = new Date();
               let dd = String(today.getDate()).padStart(2, '0');
               let mm = String(today.getMonth() + 1).padStart(2, '0');
               let yyyy = today.getFullYear();

               today = yyyy + '-' + mm + '-' + dd;

               const fInicioAnio = yyyy + '-01-01';
               const fechaInicioA = moment(fInicioAnio, 'YYYY-MM-DD');

               if (fecha && fecha < fechaInicioA) {
                   return fecha && moment(fecha.format('YYYY-MM-DD')) < fechaInicioA;
               } else {
                   return fecha && moment(fecha.format('YYYY-MM-DD')) > moment(today) + 1;
               }
          };

          const onFechas = (object, array) => {
               if(object){
                    let filtro = { gerencias: this.state.gerencias, gruposSer: this.state.gruposSer, servicios: this.state.servicios, estados: this.state.estados, fechas: array, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idInteriorFuncional: this.state.idInteriorFuncional, idSubInteriorFuncional: this.state.idSubInteriorFuncional, clasificaciones: this.state.clasificaciones };
                    let order = { orden: this.state.orden, field: this.state.field }
                    this.props.onGetListGraphReport(filtro, this.state.page, order, this.state.agrupado);
                    this.setState({fechas: array}); 
               } else{
                    let filtro = { gerencias: this.state.gerencias, gruposSer: this.state.gruposSer, servicios: this.state.servicios, estados: this.state.estados, fechas: [], idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idInteriorFuncional: this.state.idInteriorFuncional, idSubInteriorFuncional: this.state.idSubInteriorFuncional, clasificaciones: this.state.clasificaciones };
                    let order = { orden: this.state.orden, field: this.state.field }
                    this.props.onGetListGraphReport(filtro, this.state.page, order, this.state.agrupado);
                    this.setState({fechas: []}); 
               }
          };

          const onServicioSeleccionado = (e) => {
               if(e.length > 0){
                    let filtro = { gerencias: this.state.gerencias, gruposSer: this.state.gruposSer, servicios: e, estados: this.state.estados, fechas: this.state.fechas, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idInteriorFuncional: this.state.idInteriorFuncional, idSubInteriorFuncional: this.state.idSubInteriorFuncional, clasificaciones: this.state.clasificaciones };
                    let order = { orden: this.state.orden, field: this.state.field }
                    this.props.onGetListGraphReport(filtro, this.state.page, order, this.state.agrupado);
                    this.setState({servicios: e}); 
               } else {
                    let filtro = { gerencias: this.state.gerencias, gruposSer: this.state.gruposSer, servicios: [], estados: this.state.estados, fechas: this.state.fechas, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idInteriorFuncional: this.state.idInteriorFuncional, idSubInteriorFuncional: this.state.idSubInteriorFuncional, clasificaciones: this.state.clasificaciones };
                    let order = { orden: this.state.orden, field: this.state.field }
                    this.props.onGetListGraphReport(filtro, this.state.page, order, this.state.agrupado);
                    this.setState({servicios: []}); 
               }
          };

          const onAreaSeleccionada = (idAreaFuncional) => {     
               if(idAreaFuncional){
                    let idAreaFuncionalLocal = idAreaFuncional.split(' - ')[0];
                    let filtro = { gerencias: this.state.gerencias, gruposSer: this.state.gruposSer, servicios: this.state.servicios, estados: this.state.estados, fechas: this.state.fechas, idAreaFuncional: idAreaFuncionalLocal, idUnidadFuncional: this.state.idUnidadFuncional, idInteriorFuncional: this.state.idInteriorFuncional, idSubInteriorFuncional: this.state.idSubInteriorFuncional, clasificaciones: this.state.clasificaciones };
                    let order = { orden: this.state.orden, field: this.state.field }
                    this.formRef.current.resetFields(['idUnidadFuncional','idInteriorFuncional', 'idSubInteriorFuncional']);
                    this.setState({ idAreaFuncional: idAreaFuncionalLocal });
                    this.props.onGetUnidadesFuncionalesActivas(idAreaFuncionalLocal);
                    this.props.onGetListGraphReport(filtro, this.state.page, order, this.state.agrupado);
                    this.props.onLimpiarInteriorFuncional();
                    this.props.onLimpiarSubInteriorFuncional();
               } else {
                    let filtro = { gerencias: this.state.gerencias, gruposSer: this.state.gruposSer, servicios: this.state.servicios, estados: this.state.estados, fechas: this.state.fechas, idAreaFuncional: null, idUnidadFuncional: null, idInteriorFuncional: null, idSubInteriorFuncional: null };
                    let order = { orden: this.state.orden, field: this.state.field, clasificaciones: this.state.clasificaciones }
                    this.formRef.current.resetFields(['idUnidadFuncional','idInteriorFuncional', 'idSubInteriorFuncional']);
                    this.setState({ idAreaFuncional: null, idUnidadFuncional: null, idInteriorFuncional: null });
                    this.props.onGetListGraphReport(filtro, this.state.page, order, this.state.agrupado);
                    this.props.onLimpiarUnidadesFuncional();
                    this.props.onLimpiarInteriorFuncional();
                    this.props.onLimpiarSubInteriorFuncional();
               }
          };

          const onUnidadSeleccionada = (idUnidadFuncional) => {
               if(idUnidadFuncional){
                    let idUnidadFuncionalLocal = idUnidadFuncional.split(' - ')[0];
                    let filtro = { gerencias: this.state.gerencias, gruposSer: this.state.gruposSer, servicios: this.state.servicios, estados: this.state.estados, fechas: this.state.fechas, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: idUnidadFuncionalLocal, idInteriorFuncional: this.state.idInteriorFuncional, idSubInteriorFuncional: this.state.idSubInteriorFuncional, clasificaciones: this.state.clasificaciones };
                    let order = { orden: this.state.orden, field: this.state.field }
                    this.formRef.current.resetFields(['idInteriorFuncional', 'idSubInteriorFuncional']);
                    this.setState({ idUnidadFuncional: idUnidadFuncionalLocal});
                    this.props.onGetInterior(this.state.idAreaFuncional, idUnidadFuncionalLocal);
                    this.props.onLimpiarSubInteriorFuncional();
                    this.props.onGetListGraphReport(filtro, this.state.page, order, this.state.agrupado);
               } else {
                    let filtro = { gerencias: this.state.gerencias, gruposSer: this.state.gruposSer, servicios: this.state.servicios, estados: this.state.estados, fechas: this.state.fechas, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: null, idInteriorFuncional: null, idSubInteriorFuncional: null, clasificaciones: this.state.clasificaciones };
                    let order = { orden: this.state.orden, field: this.state.field }
                    this.formRef.current.resetFields(['idInteriorFuncional', 'idSubInteriorFuncional']);
                    this.setState({ idUnidadFuncional: null, idInteriorFuncional: null});
                    this.props.onLimpiarInteriorFuncional();
                    this.props.onLimpiarSubInteriorFuncional();
                    this.props.onGetListGraphReport(filtro, this.state.page, order, this.state.agrupado);
               }
          }

          const onInteriorSeleccionado = (idInteriorFuncional) => {
               if(idInteriorFuncional){
                    let idInteriorFuncionalLocal = idInteriorFuncional.split(' - ')[0];
                    let filtro = { gerencias: this.state.gerencias, gruposSer: this.state.gruposSer, servicios: this.state.servicios, estados: this.state.estados, fechas: this.state.fechas, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idInteriorFuncional: idInteriorFuncionalLocal, idSubInteriorFuncional: this.state.idSubInteriorFuncional, clasificaciones: this.state.clasificaciones };
                    let order = { orden: this.state.orden, field: this.state.field }
                    this.formRef.current.resetFields(['idSubInteriorFuncional']);
                    this.setState({ idInteriorFuncional: idInteriorFuncionalLocal});
                    this.props.onGetSubinterior(this.state.idAreaFuncional, this.state.idUnidadFuncional, idInteriorFuncionalLocal);
                    this.props.onGetListGraphReport(filtro, this.state.page, order, this.state.agrupado);
               } else {
                    let filtro = { gerencias: this.state.gerencias, gruposSer: this.state.gruposSer, servicios: this.state.servicios, estados: this.state.estados, fechas: this.state.fechas, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idInteriorFuncional: null, idSubInteriorFuncional: null, clasificaciones: this.state.clasificaciones };
                    let order = { orden: this.state.orden, field: this.state.field }
                    this.formRef.current.resetFields(['idSubInteriorFuncional']);
                    this.setState({ idInteriorFuncional: null });
                    this.props.onLimpiarSubInteriorFuncional();
                    this.props.onGetListGraphReport(filtro, this.state.page, order, this.state.agrupado);
               }
          }

          const onSubInterioresSeleccionado = (idSubInteriorFuncional) => { 
               this.setState({ idSubInteriorFuncional: idSubInteriorFuncional});
          }

          const onChangeOrder = (value) => {
               let filtro = { gerencias: this.state.gerencias, gruposSer: this.state.gruposSer, servicios: this.state.servicios, estados: this.state.estados, fechas: this.state.fechas, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idInteriorFuncional: this.state.idInteriorFuncional, idSubInteriorFuncional: this.state.idSubInteriorFuncional, clasificaciones: this.state.clasificaciones };
               let order = { orden: value.target.value, field: this.state.field }
               this.setState({ orden: value.target.value });
               this.props.onGetListGraphReport(filtro, this.state.page, order, this.state.agrupado);
               this.props.onGetListGraphReportAgrupamiento(filtro, this.state.pageAgrupado, order, this.state.agrupado, this.state.pageAgrupado, this.state.orderAgr);
          };

          const onChangeField = (value) => {
               let filtro = { gerencias: this.state.gerencias, gruposSer: this.state.gruposSer, servicios: this.state.servicios, estados: this.state.estados, fechas: this.state.fechas, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idInteriorFuncional: this.state.idInteriorFuncional, idSubInteriorFuncional: this.state.idSubInteriorFuncional, clasificaciones: this.state.clasificaciones };
               let order = { orden: this.state.orden, field: value.target.value }
               this.setState({ field: value.target.value });
               this.props.onGetListGraphReport(filtro, this.state.page, order, this.state.agrupado);
               this.props.onGetListGraphReportAgrupamiento(filtro, this.state.pageAgrupado, order, this.state.agrupado, this.state.pageAgrupado, this.state.orderAgr);
          };

          const radioStyle = {
               display: 'block',
               height: '30px',
               lineHeight: '30px',
          };

          const onChangeAgrupamiento = (value) => {
               const tipoAgrupado = value.target.value === 'Servicio' ? 'Servicio' : value.target.value === 'GrupoSer' ? 'Grupo de Servicios' : value.target.value === 'Gerencia' ? 'Gerencia' : value.target.value === 'Estado' ? 'Estado' : value.target.value === 'Area' ? 'Área Funcional' : value.target.value === 'Unidad' ? 'Unidad Funcional' : value.target.value === 'Interior' ? 'Interior Funcional' : value.target.value === 'Clasificacion' ? 'Clasificación' : 'Fecha'

               let filtro = { gerencias: this.state.gerencias, gruposSer: this.state.gruposSer, servicios: this.state.servicios, estados: this.state.estados, fechas: this.state.fechas, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idInteriorFuncional: this.state.idInteriorFuncional, idSubInteriorFuncional: this.state.idSubInteriorFuncional, clasificaciones: this.state.clasificaciones };
               let order = { orden: this.state.orden, field: this.state.field }
               this.setState({ agrupado: value.target.value, tipoAgrupado });
               this.props.onGetListGraphReportAgrupamiento(filtro, 1, order, value.target.value, this.state.pageAgrupado, this.state.orderAgr);
          };

          const columnsAgrupamiento = [ { title: this.state.tipoAgrupado, dataIndex: this.state.agrupado === 'Fecha' ? 'nombreTable' : 'etiquetaLarga', key: 'etiquetaLarga', sorter: true } ];

          const onHandleTableChange = (pagination, fil, orden) => {
               console.log('pagination', pagination);
               console.log('orden', orden)
               let campoAgrupado = this.state.tipoAgrupado === 'Servicio' ? 'ser.nombre' : this.state.tipoAgrupado === 'Gerencia' ? 'cge.nombreGerencia' : this.state.tipoAgrupado === 'Grupo de Servicios' ? 'gs.nombre' : this.state.tipoAgrupado === 'Estado' ? 'sse.estado' : this.state.tipoAgrupado === 'Área Funcional' ? 'afe.areaFuncional' : this.state.tipoAgrupado === 'Unidad Funcional' ? 'ufe.unidadFuncional' : this.state.tipoAgrupado === 'Interior Funcional' ? 'ife.interiorFuncional' : this.state.tipoAgrupado === 'Fecha' ? 'sse.fechaAlta' : this.state.tipoAgrupado === 'Clasificación' ? 'sse.queja' : '' ;
               let orderAgrupado = 'ASC';
               if(orden){
                    if(orden.order === 'descend'){
                         orderAgrupado = 'DESC';
                    }
    
                    if(orden.column){
                         if(orden.column.title === 'Servicio'){
                              campoAgrupado = 'ser.nombre';
                         } else if(orden.column.title === 'Gerencia'){
                              campoAgrupado = 'cge.nombreGerencia';
                         } else if(orden.column.title === 'Grupo de Servicio'){
                              campoAgrupado = 'gs.nombre';
                         } else if(orden.column.title === 'Estado'){
                              campoAgrupado = 'sse.estado';
                         } else if(orden.column.title === 'Área Funcional'){
                              campoAgrupado = 'afe.areaFuncional';
                         } else if(orden.column.title === 'Unidad Funcional'){
                              campoAgrupado = 'ufe.unidadFuncional';
                         } else if(orden.column.title === 'Interior Funcional'){
                              campoAgrupado = 'ife.interiorFuncional';
                         } else if(orden.column.title === 'Fecha'){
                              campoAgrupado = 'sse.fechaAlta';
                         }else if(orden.column.title === 'Clasificación'){
                              campoAgrupado = 'sse.queja';
                         }
                    }
               }

               let filtro = { gerencias: this.state.gerencias, gruposSer: this.state.gruposSer, servicios: this.state.servicios, estados: this.state.estados, fechas: this.state.fechas, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idInteriorFuncional: this.state.idInteriorFuncional, idSubInteriorFuncional: this.state.idSubInteriorFuncional, clasificaciones: this.state.clasificaciones };
               let order = { orden: this.state.orden, field: this.state.field }
               let orderAgr = { orden: orderAgrupado, field: campoAgrupado }

               this.setState({ pageAgrupado: pagination.current, orderAgr });
               this.props.onGetListGraphReportAgrupamiento(filtro, this.state.page, order, this.state.agrupado, pagination.current, orderAgr);
          };

          const onClasificacionSeleccionada = (e) => {
               if(e.length > 0){
                    let filtro = { gerencias: this.state.gerencias, gruposSer: this.state.gruposSer, servicios: this.state.servicios, estados: this.state.estados, fechas: this.state.fechas , idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idInteriorFuncional: this.state.idInteriorFuncional, idSubInteriorFuncional: this.state.idSubInteriorFuncional, clasificaciones: e };
                    let order = { orden: this.state.orden, field: this.state.field }
                    this.props.onGetListGraphReport(filtro, this.state.page, order, this.state.agrupado);
                    this.setState({clasificaciones: e}); 
               } else {
                    let filtro = { gerencias: this.state.gerencias, gruposSer: this.state.gruposSer, servicios: this.state.servicios, estados: this.state.estados, fechas: this.state.fechas, idAreaFuncional: this.state.idAreaFuncional, idUnidadFuncional: this.state.idUnidadFuncional, idInteriorFuncional: this.state.idInteriorFuncional, idSubInteriorFuncional: this.state.idSubInteriorFuncional, clasificaciones: [] };
                    let order = { orden: this.state.orden, field: this.state.field }
                    this.props.onGetListGraphReport(filtro, this.state.page, order, this.state.agrupado);
                    this.setState({clasificaciones: []}); 
               }
          };

          return (
          <Row>
               <Col span={this.state.colIzq} style={{backgroundColor: '#737373', borderTopLeftRadius:'1em', borderBottomLeftRadius:'1em'}}>
                    <Form {...layout} name='formulario' onFinish={onFinish} ref={this.formRef}>
                         <Row justify='center' style={{padding:'1em'}}>
                              <Col span={24}>
                                   <Tabs defaultActiveKey="1">
                                        <TabPane
                                             tab={
                                             <span>
                                                  <SearchOutlined className='iconTabPaneTer'/>
                                             </span>
                                             }
                                             key="1"
                                        >   
                                             <Row justify='space-around'>
                                                  <Divider><Text strong style={{color:'white'}}>Filtro de Búsqueda</Text> </Divider>
                                                  <Col span={24} style={{paddingTop:'1em'}}>
                                                       <Form.Item
                                                       // label={ <Text style={{color:'white'}}>Gerencia</Text> }
                                                       name="IdsGerencias"
                                                       rules={[{ required: false, message: 'Favor de llenar el campo' }]}
                                                       >
                                                            <Select
                                                            allowClear
                                                            style={{ width: '100%' }} 
                                                            placeholder="Selecciona gerencias"
                                                            loading={this.props.fetchingListaClasificacionesActivos}
                                                            mode='multiple'
                                                            onChange={onClasificacionSeleccionado}
                                                            disabled={this.state.boolGrupo}
                                                       >
                                                            {this.props.listaClasificacionesActivos.map(item => <Option key={item.idClasificacionGerencia + ' - ' + item.nombreGerencia}>{item.idClasificacionGerencia + ' - ' + item.nombreGerencia}</Option>)}
                                                       </Select>
                                                       </Form.Item>
                                                  </Col>

                                                  <Col span={24}>
                                                       <Form.Item
                                                            name='idsGruposServicios'
                                                            // label={ <Text style={{color:'white'}}>Grupo de servicios</Text> }
                                                            rules={[{ required: false, message: 'Favor de llenar el campo' }]}
                                                       >
                                                            <Select
                                                                 allowClear
                                                                 style={{ width: '100%' }} 
                                                                 placeholder="Selecciona grupos de servicios"
                                                                 loading={this.props.fetchingListaGruposServicios}
                                                                 mode='multiple'
                                                                 onChange={onGrupoSeleccionado}
                                                                 disabled={this.state.boolClasificacion}
                                                            >
                                                                 {this.props.listaGruposServicios.map(option => <Option key={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}><Tooltip title={option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}>{option.idGrupoSer + ' - ' + option.nombre + ': ' + option.descripcion}</Tooltip></Option>)}
                                                            </Select>
                                                       </Form.Item>
                                                  </Col>

                                                  <Col span={24}>
                                                       <Form.Item
                                                            name='idsServicios'
                                                            // label={ <Text style={{color:'white'}}>Servicio</Text> }
                                                            rules={[{ required: false, message: 'Favor de seleccionar un servicio', }]}
                                                       >
                                                            <Select
                                                                 allowClear
                                                                 style={{ width: '100%' }} placeholder="Selecciona servicios"
                                                                 mode='multiple'
                                                                 disabled={!this.state.boolGrupo && !this.state.boolClasificacion}
                                                                 loading={this.state.boolClasificacion ? this.props.fetchingListaServiciosByIdsGrupos : this.props.fetchingListaServiciosByIdsGerencias}
                                                                 onChange={onServicioSeleccionado}
                                                            >
                                                                 {!this.state.boolClasificacion ? (<>
                                                                      { this.props.listaServiciosByIdsGrupos ? (<>
                                                                           {this.props.listaServiciosByIdsGrupos.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nombre}</Option>)}
                                                                      </>):false }
                                                                 </>) : (<>
                                                                      { this.props.listaServiciosByIdsGerencias ? (<>
                                                                           {this.props.listaServiciosByIdsGerencias.map(servicio => <Option key={servicio.idServicio + ' - ' + servicio.nombre}>{servicio.idServicio + ' - ' + servicio.nombre}</Option>)}
                                                                      </>):false }
                                                                 </>)}
                                                            </Select>
                                                       </Form.Item>
                                                  </Col>

                                                  <Col span={24}>
                                                       <Form.Item
                                                            name='estados'
                                                            // label={ <Text style={{color:'white'}}>Estado</Text> }
                                                            rules={[{ required: false, message: 'Favor de seleccionar un servicio', }]}
                                                       >
                                                            <Select
                                                                 allowClear
                                                                 style={{ width: '100%' }} 
                                                                 placeholder="Selecciona estados"
                                                                 mode='multiple'
                                                                 onChange={onEstadoSeleccionado}
                                                            >
                                                                 <Option key='Generada'> Generada </Option>
                                                                 <Option key='Abierto'> Abierto </Option>
                                                                 <Option key='Traslado'> Traslado </Option>
                                                                 <Option key='En Proceso'> En Proceso </Option>
                                                                 <Option key='En Espera'> En Espera </Option>
                                                                 <Option key='Terminada'> Terminada </Option>
                                                                 <Option key='Cancelado'> Cancelado </Option>
                                                                 <Option key='Cerrado'> Cerrado </Option>
                                                            </Select>
                                                       </Form.Item>
                                                  </Col>

                                                  <Col span={24}>
                                                       <Form.Item
                                                            name='clasificacion'
                                                            // label={ <Text style={{color:'white'}}>Clasificación</Text> }
                                                            rules={[{ required: false, message: 'Favor de seleccionar un servicio', }]}
                                                       >
                                                            <Select
                                                                 allowClear
                                                                 style={{ width: '100%' }} 
                                                                 placeholder="Selecciona clasificaciones"
                                                                 mode='multiple'
                                                                 onChange={onClasificacionSeleccionada}
                                                            >
                                                                 <Option key='SS'> SS </Option>
                                                                 <Option key='queja'> Queja </Option>
                                                                 <Option key='SNP'> SNP </Option>
                                                            </Select>
                                                       </Form.Item>
                                                  </Col>

                                                  <Col span={24}>
                                                       <Form.Item
                                                            name="idAreaFuncional"
                                                            rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                                       >
                                                            <Select allowClear
                                                                 style={{ width: '100%' }}
                                                                 placeholder="Selecciona la área funcional"
                                                                 loading={this.props.fetchingListaAreasFuncionalesActivas}
                                                                 onChange={onAreaSeleccionada}
                                                                 showSearch
                                                            >
                                                                 {listaAreasFuncionalesActivas.map(option => 
                                                                      <Option key={option.idAreaFuncional + ' - ' + option.areaFuncional}> <Tooltip title={option.areaFuncional}> {option.idAreaFuncional + ' - ' + option.areaFuncional} </Tooltip> </Option> )}
                                                            </Select>
                                                       </Form.Item>
                                                  </Col>

                                                  { listaUnidadesFuncionalesActivas[0] ? (
                                                  <Col span={24} >
                                                            <Form.Item
                                                                 name="idUnidadFuncional"
                                                                 rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                                            >
                                                                 <Select allowClear showSearch
                                                                 style={{ width: '100%' }}
                                                                 placeholder="Selecciona la unidad funcional"
                                                                 loading={fetchingListaUnidadesFuncionalesActivas}
                                                                 onChange={onUnidadSeleccionada}                                           
                                                                 >
                                                                 {listaUnidadesFuncionalesActivas.map(option => <Option key={option.idUnidadFuncional + ' - ' + option.unidadFuncional}><Tooltip title={option.unidadFuncional}>{option.idUnidadFuncional + ' - ' + option.unidadFuncional} </Tooltip></Option>)}
                                                                 </Select>
                                                            </Form.Item>
                                                  </Col>
                                                  ): false }

                                                  { listaInteriorFuncional[0] ? (    
                                                  <Col span={24}>
                                                            <Form.Item
                                                                 name="idInteriorFuncional"
                                                                 rules={[{ required: false, message: 'Favor de llenar el campo', }]}
                                                            >
                                                                 <Select allowClear showSearch
                                                                 style={{ width: '100%' }}
                                                                 placeholder="Selecciona el interior funcional"
                                                                 loading={fetchingListaInteriorFuncional}
                                                                 onChange={onInteriorSeleccionado}
                                                                 >
                                                                 {listaInteriorFuncional.map(option => <Option key={option.idInteriorFuncional + ' - ' + option.interiorFuncional}><Tooltip title={option.interiorFuncional}>{option.idInteriorFuncional + ' - ' + option.interiorFuncional}</Tooltip></Option>)}
                                                                 </Select>
                                                            </Form.Item>
                                                  </Col>
                                                  ): false }

                                                  {/* { listaSubinteriorFuncional[0] ? (
                                                  <Col span={24}>
                                                            <Form.Item
                                                                 name="idSubInteriorFuncional"
                                                            >
                                                                 <Select allowClear
                                                                 style={{ width: '100%' }}
                                                                 placeholder="Seleccione la subinterior funcional"
                                                                 loading={fetchingListaSubinteriorFuncional}
                                                                 className='seleccionAsignar'
                                                                 onChange={onSubInterioresSeleccionado}
                                                                 
                                                                 >
                                                                      {listaSubinteriorFuncional.map(option => <Option key={option.idSubInteriorFuncional}>{option.subInteriorFuncional}</Option>)}
                                                                 </Select>
                                                            </Form.Item>
                                                  </Col>
                                                  ): false } */}

                                                  <Col span={24}>
                                                       <Form.Item
                                                            name="rangoFechaInicio"
                                                            // label={ <Text style={{color:'white'}}>Fecha inicio</Text> }
                                                            rules={[{ required: false, message: 'Favor de llenar el campo' }]}
                                                       >
                                                            <RangePicker
                                                                 className='input'
                                                                 style={{width: '100%'}} 
                                                                 format='DD-MM-YYYY' 
                                                                 onChange={onFechas} 
                                                                 disabledDate={disabledDate}
                                                                 placeholder={["Fecha inicio","Fecha fin"]}
                                                            />
                                                       </Form.Item>
                                                  </Col>
                                             </Row> 

                                        </TabPane>
                                        <TabPane
                                             tab={
                                             <span>
                                                  <FilterOutlined className='iconTabPaneTer'/>
                                             </span>
                                             }
                                             key="2"
                                        >
                                             <Row justify='space-around' gutter={[8,8]}>
                                                  <Divider><Text strong style={{color:'white'}}>Orden</Text> </Divider>
                                                  <Col span={24}>
                                                       <Radio.Group onChange={onChangeOrder} defaultValue='DESC'>
                                                            <Radio value='ASC'><Text style={{color:'white'}}>Ascendente</Text></Radio>
                                                            <Radio value='DESC'><Text style={{color:'white'}}>Descendente</Text></Radio>
                                                       </Radio.Group>
                                                  </Col>

                                                  <Divider><Text strong style={{color:'white'}}>Campo</Text> </Divider>
                                                  <Col span={24}>
                                                       <Radio.Group onChange={onChangeField} defaultValue='sse.fechaAlta'>
                                                            <Radio style={radioStyle} value='sse.fechaAlta'><Text style={{color:'white'}}>Fecha Alta</Text></Radio>
                                                            <Radio style={radioStyle} value='sse.folio'><Text style={{color:'white'}}>Folio</Text></Radio>
                                                            <Radio style={radioStyle} value='ser.nombre'><Text style={{color:'white'}}>Servicios</Text></Radio>
                                                            <Radio style={radioStyle} value='cce.centroCosto'><Text style={{color:'white'}}>Centro de Costos</Text></Radio>
                                                            <Radio style={radioStyle} value='cge.nombreGerencia'><Text style={{color:'white'}}>Gerencias</Text></Radio>
                                                            <Radio style={radioStyle} value='gs.nombre'><Text style={{color:'white'}}>Grupo de Servicio</Text></Radio>
                                                            <Radio style={radioStyle} value='us.nombre'><Text style={{color:'white'}}>Usuario Reporta</Text></Radio>
                                                            <Radio style={radioStyle} value='afe.areaFuncional'><Text style={{color:'white'}}>Área Funcional</Text></Radio>
                                                            <Radio style={radioStyle} value='ufe.unidadFuncional'><Text style={{color:'white'}}>Unidad Funcional</Text></Radio>
                                                            <Radio style={radioStyle} value='ife.interiorFuncional'><Text style={{color:'white'}}>Interior Funcional</Text></Radio>
                                                       </Radio.Group>
                                                  </Col>
                                             </Row>
                                        </TabPane>

                                        <TabPane tab={ <span> <GroupOutlined className='iconTabPaneTer'/> </span> } key="3">
                                             <Row justify='space-around' gutter={[8,8]}>
                                                  <Divider><Text strong style={{color:'white'}}>Agrupamiento</Text> </Divider>
                                                  <Col span={24}>
                                                       <Radio.Group onChange={onChangeAgrupamiento} defaultValue='sinAgrupar'>
                                                            <Radio style={radioStyle} value='Servicio'><Text style={{color:'white'}}>Servicio</Text></Radio>
                                                            <Radio style={radioStyle} value='Gerencia'><Text style={{color:'white'}}>Gerencia</Text></Radio>
                                                            <Radio style={radioStyle} value='GrupoSer'><Text style={{color:'white'}}>Grupo de Servicio</Text></Radio>
                                                            <Radio style={radioStyle} value='Estado'><Text style={{color:'white'}}>Estado</Text></Radio>
                                                            <Radio style={radioStyle} value='Clasificacion'><Text style={{color:'white'}}>Clasificación</Text></Radio>
                                                            <Radio style={radioStyle} value='Area'><Text style={{color:'white'}}>Área Funcional</Text></Radio>
                                                            <Radio style={radioStyle} value='Unidad'><Text style={{color:'white'}}>Unidad Funcional</Text></Radio>
                                                            <Radio style={radioStyle} value='Interior'><Text style={{color:'white'}}>Interior Funcional</Text></Radio>
                                                            <Radio style={radioStyle} value='Fecha'><Text style={{color:'white'}}>Fecha</Text></Radio>
                                                            <Radio style={radioStyle} value='sinAgrupar'><Text style={{color:'white'}}>Sin Agrupar</Text></Radio>
                                                       </Radio.Group>
                                                  </Col>
                                             </Row>
                                        </TabPane> 
                                   </Tabs>
                              </Col>
                         </Row>
                    </Form>
               </Col>
               
               <Col span={this.state.colDer} style={{backgroundColor: '#F2F2F2' }}>
                    <Row justify='space-around' align='middle' style={{ backgroundColor: '#E4E4E4', borderTopRightRadius:'1em' }}>
                         <Col span={22} style={{backgroundColor: '#E4E4E4', paddingTop: '1em', paddingBottom: '1em', paddingLeft: '1em'}}>
                              <Row justify='start' align='middle' gutter={[0,0]}>
                                   <Text style={{textAlign: 'left', fontSize: '2em', color: '#737373', borderLeftColor: '#E4E4E4', borderRightColor:'#E4E4E4', borderTopColor:'#E4E4E4', borderBottomColor:'#E4E4E4', borderStyle: 'solid', paddingRight: '.5em'}} strong> Reporte Gráfico </Text>
                              </Row>
                         </Col>
                         <Col span={1} style={{backgroundColor: '#E4E4E4', paddingTop: '1em', paddingBottom:'1em'}}>
                              { this.state.maximizar ? (<> <RightCircleOutlined className='iconoMaxMin' onClick={onExpandir} /> </>):(<> <LeftCircleOutlined className='iconoMaxMin' onClick={onExpandir}/> </>) }
                         </Col>
                    </Row>

                    <Row justify='space-around' gutter={[8,8]}>
                         <Col span={24} style={{padding:'1em'}}>
                              { this.state.agrupado === 'sinAgrupar' ? (
                                   <List
                                        pagination={{ total: this.props.listReporteGraficoCount, simple: true, current: this.state.page, pageSize: 48, onChange: page => {listChangePage(page)} }}
                                        style={{backgroundColor:"#F2F2F2"}}
                                        // grid={{ gutter: 16, xs: 1, sm: 2, md: 4, lg: 4,  xl: 5, xxl: 5 }}
                                        grid={{ gutter: 8, xs: 1, sm: 2, md: 4, lg: 8,  xl: 8, xxl: 12  }}
                                        dataSource={this.props.listRerpoteGrafico}
                                        loading={this.props.fetchingGetReporteGrafico}
                                        renderItem={item => (
                                             <List.Item className='listaHover'>
                                                  <Card
                                                       className='cardReporteGrafico'
                                                       bordered={true} 
                                                       style={{backgroundColor:'#DDDDDD'}}
                                                       cover={ <Row justify='center' align='middle'> <img onError={this.addDefaultSrc} style={{width:'80%', paddingTop:'1em'}} alt={item.folio} src={item.urlImagen ? item.urlImagen : imagenNoResult} /> </Row> }
                                                       actions={[ <a className='editarIcono' onClick={() => {console.log('item', item); this.props.onShowModal(item); this.props.onGetTrazabilidadFolio(item.folio, 1); }}><EllipsisOutlined /></a> ]}
                                                  >
                                                       <Meta description={<Row justify='center' align='middle' style={{backgroundColor: '#737373', borderRadius:'1em'}}>  <Text style={{color:'white'}} ellipsis type='secondary'> <Tooltip title={item.folio} placement='top'> {item.folio} </Tooltip> </Text> </Row>} />

                                                  </Card>
                                             </List.Item>
                                        )}
                                   />
                              ) : (
                                   <Table
                                        size='small'
                                        rowKey={ this.state.agrupado == 'Estado' || this.state.agrupado == 'Fecha' || this.state.agrupado == 'Clasificacion' ? "etiqueta" : "identificador" }
                                        columns={columnsAgrupamiento}
                                        dataSource={this.props.listReporteGraficoAgrupado}
                                        expandable={{expandedRowRender: record => 
                                             <List
                                                  pagination={{ total: record.agrupados.cantidad, simple: true, current: record.agrupados.page, pageSize: 48, onChange: (e) => {listChangePageAgrupado(record, e)} }}
                                                  style={{backgroundColor:"#F2F2F2"}}
                                                  grid={{ gutter: 8, xs: 1, sm: 2, md: 4, lg: 8,  xl: 8, xxl: 12  }}
                                                  dataSource={record.agrupados.data}
                                                  loading={this.props.fetchingGetReporteGrafico}
                                                  renderItem={item => (
                                                       <List.Item className='listaHover'>
                                                            <Card
                                                                 className='cardReporteGrafico'
                                                                 bordered={true} 
                                                                 style={{backgroundColor:'#DDDDDD'}}
                                                                 cover={ <Row justify='center' align='middle'> <img onError={this.addDefaultSrc} style={{width:'80%', paddingTop:'1em'}} alt={item.folio} src={item.urlImagen ? item.urlImagen : imagenNoResult} /> </Row> }
                                                                 actions={[ <a className='editarIcono' onClick={() => {console.log('item', item); this.props.onShowModal(item); this.props.onGetTrazabilidadFolio(item.folio, 1); }}><EllipsisOutlined /></a> ]}
                                                            >
                                                                 <Meta description={<Row justify='center' align='middle' style={{backgroundColor: '#737373', borderRadius:'1em'}}>  <Text style={{color:'white'}} ellipsis type='secondary'> <Tooltip title={item.folio} placement='top'> {item.folio} </Tooltip> </Text> </Row>} />

                                                            </Card>
                                                       </List.Item>
                                                  )}
                                             />
                                        } }
                                        loading={this.props.fetchingGetRerporteGraficoAgrupado}
                                        locale={{ emptyText: 'Sin datos' }}
                                        onChange={onHandleTableChange}
                                        pagination={{ total: this.props.listReporteGraficoAgrupadoCount, simple: true, current: this.state.pageAgrupado }}
                                   />
                              )}

                         </Col>
                    </Row>
               </Col>
               <ModalInfo />
          </Row>
          );
     }
}
 
 const mapStateToProps = state => {
     return {
          listRerpoteGrafico: state.MonitorIntegralReducer.listRerpoteGrafico,
          listReporteGraficoCount: state.MonitorIntegralReducer.listReporteGraficoCount,
          fetchingGetReporteGrafico: state.MonitorIntegralReducer.fetchingGetReporteGrafico,

          listReporteGraficoAgrupado: state.MonitorIntegralReducer.listReporteGraficoAgrupado,
          listReporteGraficoAgrupadoCount: state.MonitorIntegralReducer.listReporteGraficoAgrupadoCount,
          fetchingGetRerporteGraficoAgrupado: state.MonitorIntegralReducer.fetchingGetRerporteGraficoAgrupado,

          listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,

          fetchingListaGruposServicios: state.CatalogosGeneralesReducer.fetchingListaGruposServicios,
          listaGruposServicios: state.CatalogosGeneralesReducer.listaGruposServicios,

          fetchingListaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.fetchingListaClasificacionesActivos,
          listaClasificacionesActivos: state.EvaluacionDeLaGestionReducer.listaClasificacionesActivos,

          listaServiciosByIdsGrupos: state.CatalogosGeneralesReducer.listaServiciosByIdsGrupos,
          fetchingListaServiciosByIdsGrupos: state.CatalogosGeneralesReducer.fetchingListaServiciosByIdsGrupos,

          listaServiciosByIdsGerencias: state.CatalogosGeneralesReducer.listaServiciosByIdsGerencias,
          fetchingListaServiciosByIdsGerencias: state.CatalogosGeneralesReducer.fetchingListaServiciosByIdsGerencias,

          listaAreasFuncionalesActivas: state.AlmacenesReducer.listaAreasFuncionalesActivas,
          fetchingListaAreasFuncionalesActivas: state.AlmacenesReducer.fetchingListaAreasFuncionalesActivas,
          listaUnidadesFuncionalesActivas: state. AlmacenesReducer.listaUnidadesFuncionalesActivas,
          fetchingListaUnidadesFuncionalesActivas: state.AlmacenesReducer.fetchingListaUnidadesFuncionalesActivas,
          listaInteriorFuncional: state.AlmacenesReducer.listaInteriorFuncional,
          fetchingListaInteriorFuncional: state.AlmacenesReducer.fetchingListaInteriorFuncional,
          listaSubinteriorFuncional: state.AlmacenesReducer.listaSubinteriorFuncional,
          fetchingListaSubinteriorFuncional: state.AlmacenesReducer.fetchingListaSubinteriorFuncional,
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          onGetListGraphReport: (filtro, page, order, agrupamiento, agrupado) => {
               dispatch({ type: 'GET_REPORTE_GRAFICO_REQUEST', filtro, page, order, agrupamiento, agrupado });
          },
          onShowModal: (reporteGraficoSeleccionado) => {
               dispatch({ type: 'SHOW_MODAL_INFO_REPORTE_GRAFICO', showModalInformacionReporteGrafico: true, reporteGraficoSeleccionado})
          },
          onGetListGraphReportAgrupamiento: (filtro, page, order, agrupamiento, pageAgrupado, orderAgrupado) => {
               dispatch({ type: 'GET_REPORTE_GRAFICO_GRUPO_REQUEST', filtro, page, order, agrupamiento, pageAgrupado, orderAgrupado });
          },
          onGetTrazabilidadFolio: (folio, page) => {
               dispatch({ type: "OBTENER_TRAZABILIDAD_FOLIO_REQUEST", folio, page});
          },
          onGetGerencias: () => {
               dispatch({ type: 'LISTA_CLASIFICACION_ACTIVOS_REQUEST' })
          },
          onGetGruposServiciosActivos: () => {
               dispatch({ type: 'LISTA_GRUPOSERVICIOS_REQUEST' });
          },
          onGetServiciosActivos: () => {
               dispatch({ type: 'LISTA_SERVICIOS_ACTIVOSF_REQUEST'})
          },
          onListaServiciosByIdsGerencias: (idsGerencias) => {
               dispatch({ type: 'LISTA_SERVICIOS_BY_IDGERENCIAS_REQUEST', idsGerencias });
          },
          onListaServiciosByIdsGrupos: (idsGruposSer) => {
               dispatch({ type: 'LISTA_SERVICIOS_BY_IDSGRUPOS_REQUEST', idsGruposSer });
          },
          onRequestInfoProyecto: () => {
               dispatch({ type: 'GET_INFOPROYECTO_REQUEST' });
          },
          onGetAreasFuncionales: () => {
            dispatch({ type: "LISTA_AREA_FUNCIONAL_ACTIVA_REQUEST", });
          },
          onGetUnidadesFuncionalesActivas: (idAreaFuncional) => {
               dispatch({ type: 'LISTA_UNIDAD_FUNCIONAL_REQUEST', idAreaFuncional: idAreaFuncional});
          },
          onLimpiarUnidadesFuncional: () => {
               dispatch({ type: 'LISTA_UNIDAD_FUNCIONAL_SUCCESS', listaUnidadesFuncionalesActivas: [] })
          },
          onGetInterior: (idAreaFuncional, idUnidadFuncional) => {
               dispatch({ type: 'LISTA_INTERIOR_FUNCIONAL_REQUEST', idAreaFuncional: idAreaFuncional, idUnidadFuncional: idUnidadFuncional });
          },
          onLimpiarInteriorFuncional: () => {
               dispatch({ type: 'LISTA_INTERIOR_FUNCIONAL_SUCCESS', listaInteriorFuncional: [] })
          },
          onGetSubinterior: (idAreaFuncional, idUnidadFuncional, idInteriorFuncional) => {
               dispatch({ type: 'LISTA_SUBINTERIOR_FUNCIONAL_REQUEST', idAreaFuncional: idAreaFuncional, idUnidadFuncional: idUnidadFuncional, idInteriorFuncional: idInteriorFuncional});
          },
          onLimpiarSubInteriorFuncional: () => {
               dispatch({ type: 'LISTA_SUBINTERIOR_FUNCIONAL_SUCCESS', listaSubinteriorFuncional: [] })
          },
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(ReporteGraficoContainer);