import axios from 'axios'

//const endpoint = "https://papantla-services.herokuapp.com/";
//const endpoint = "https://papantla-dev.herokuapp.com/";
const endpoint = 'https://morelos-dev.herokuapp.com/';
//const endpoint = "https://papantla-demo.herokuapp.com/";

//const endpoint = "http://172.24.0.170:8080/"

//const endpoint = 'https://papantla-services-jose.herokuapp.com/';
//const endpoint = 'https://papantla-dev-jose.herokuapp.com/';

//const endpoint = 'https://papantla-secret-jose.herokuapp.com/';
//const endpoint = 'https://papantla-secret.herokuapp.com/';

export function obtenerInfoProyectoCall(token) {
	return axios({
		method: "get",
		url: endpoint + "settings/proyectos",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarInfoProyectoCall(token, data, idProyecto) {
	return axios({
		method: "put",
		url: endpoint + "settings/proyectos/" + idProyecto,
		headers: {'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearInfoProyectoCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "settings/proyectos/",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}