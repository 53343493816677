import axios from "axios";

//const endpoint = "https://papantla-services.herokuapp.com/";
//const endpoint = "https://papantla-dev.herokuapp.com/";
const endpoint = 'https://morelos-dev.herokuapp.com/';
//const endpoint = "https://papantla-demo.herokuapp.com/";

//const endpoint = "http://172.24.0.170:8080/"

//const endpoint = 'https://papantla-services-jose.herokuapp.com/';
//const endpoint = 'https://papantla-dev-jose.herokuapp.com/';

//const endpoint = 'https://papantla-secret-jose.herokuapp.com/';
//const endpoint = 'https://papantla-secret.herokuapp.com/';

//Servicios Fallas de Calidad
export function listaFallasDeCalidadActivosCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "fallasCalidad/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaFallasDeCalidadTodosCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "fallasCalidad/todas",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getFallasDeCalidadPageCall(token, page, busqueda) {
	return axios({
		method: "get",
		url: endpoint + "fallasCalidad/?param=" + busqueda + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearFallaDeCalidadCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "fallasCalidad/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarFallaDeCalidadCall(token, data, idFallaCalidad) {
	return axios({
		method: "put",
		url: endpoint + "fallasCalidad/" + idFallaCalidad,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoFallaDeCalidadCall(token, idFallaCalidad) {
	return axios({
		method: "get",
		url: endpoint + "fallasCalidad/activar/" + idFallaCalidad,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Fallas de Disponibilidad

export function listaCategoriasDisponibilidadCall(token){
	return axios({
	    method: "get",
		url: endpoint + "fallasDisponibilidad/todas",
	    headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		 return response;
	 }).catch(error => {
		 return error.response.data;
	 });
 }

export function crearFallasDisponibilidadCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "fallasDisponibilidad/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaFallasDisponibilidadBusquedaCall(token, page, busqueda) {
	return axios({
	    method: "get",
	    url: endpoint + "fallasDisponibilidad/?" + busqueda + "page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json', 'Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoFallasDisponibilidadCall(token, idFallaDisponibilidad) {
	return axios({
		method: "get",
		url: endpoint + "fallasDisponibilidad/activa/" + idFallaDisponibilidad,
		headers: {'Content-Type': 'application/json', 'Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarFallasDisponibilidadCall(token, data, idFallaDisponibilidad) {
	return axios({
		method: "put",
		url: endpoint + "fallasDisponibilidad/" + idFallaDisponibilidad,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
		
		
		
// tiempos de garantia
// lista activos/inactivos tiempos
export function listaTiempoTodosCall(token){
    return axios({
        method: "get",
	    url: endpoint + "tiempos/lista",
        headers: {'Content-Type': 'application/json','Authorization':token},
    }).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaTiempoDeGarantiaCall(token, page){
    return axios({
        method: "get",
	    url: endpoint + "tiempos?page=" + page + "&offset=10",
        headers: {'Content-Type': 'application/json','Authorization':token},
    }).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaTiempoDeGarantiaBusquedaCall(token, page, busqueda) {
	return axios({
	    method: "get",
	    url: endpoint + "tiempos?page=" + page + "&offset=10" + busqueda,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
		
export function cambiarEstadoTiempoDeGarantiaCall(token, idTiempoGarantia) {
	return axios({
		method: "get",
		url: endpoint + "tiempos/activar/" + idTiempoGarantia,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearTiempoDeGarantiaCall(token, data){
	return axios({
		method: "post",
		url: endpoint + "tiempos" ,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarTiempoDeGarantiaCall(token, idTiempoGarantia, data) {
	return axios({
		method: "put",
		url: endpoint + "tiempos/" + idTiempoGarantia,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	})
}

// ponderacion por servicios de area servicios 
export function listaPonderacionServAreaCall(token, page){
    return axios({
        method: "get",
	    url: endpoint + "ponderador?page=" + page + "&offset=10",
        headers: {'Content-Type': 'application/json','Authorization':token},
    }).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaServiciosEnAreasCall(token, idServicio) {
	return axios({
		method: "get",
		url: endpoint + "ponderador/lista?idServicio=" + idServicio,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaPonderacionServAreaBusquedaCall(token, busqueda, page) {
	return axios({
	    method: "get",
	    url: endpoint + "ponderador?" + busqueda +"&page=" + page + "&offset=10" ,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function cambiarEstadoPonderacionServAreaCall(token, idPonderacionServArea) {
	return axios({
		method: "get",
		url: endpoint + "ponderador/activar/" + idPonderacionServArea,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearPonderacionServAreaCall(token, data){
	return axios({
		method: "post",
		url: endpoint + "ponderador" ,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarPonderacionServAreaCall(token, idPonderacionServArea, data) {
	return axios({
		method: "put",
		url: endpoint + "ponderador/" + idPonderacionServArea,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	})
}

// Referencias de estándares de desempeño
export function listaEstandaresDesempeñoFiltroCall(token, param, tipo, idServicio) {
	return axios({
	    method: "get",
	    url: endpoint + "estandaresDesempeno/activos/servicio/" + idServicio + "/by?param=" + param + '&tipo=' + tipo,
		headers: {'Content-Type': 'application/json', 'Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaEstandaresDesempeñoFiltroActivosCall(token, param, ) {
	return axios({
	    method: "get",
	    url: endpoint + "estandaresDesempeno/activos/by?param=" + param,
		headers: {'Content-Type': 'application/json', 'Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaReferenciasDeEstandaresBusquedaCall(token, page, param, order, field) {
	return axios({
	    method: "get",
	    url: endpoint + "estandaresDesempeno/referencias?" + param + order +  field + "page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json', 'Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaReferenciasDeEstandaresActivosCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "estandaresDesempeno/referencias/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}


export function listaServiciosFiltroCall(token, busqueda) {
	return axios({
	    method: "get",
	    url: endpoint + "servicios/activosT?param=" + busqueda,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function cambiarEstadoReferenciaDeEstandarCall(token, idReferenciaDesempeno) {
	return axios({
		method: "get",
		url: endpoint + "estandaresDesempeno/referencias/activa/" + idReferenciaDesempeno,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearReferenciaDeEstandarCall(token, data){
	return axios({
		method: "post",
		url: endpoint + "estandaresDesempeno/referencias" ,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}


export function editarReferenciaDeEstandarCall(token, idReferenciaDesempeno, data) {
	return axios({
		method: "put",
		url: endpoint + "estandaresDesempeno/referencias/" + idReferenciaDesempeno,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Servicios/Secciones Estándares de Desempeño Específicos
export function listaEstandaresDesempenoEspecificosActivosCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "servSeccionesEspecificas/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getEstandaresDesempenoEspecificosPageCall(token, page, busqueda) {
	return axios({
		method: "get",
		url: endpoint + "servSeccionesEspecificas/?param=" + busqueda + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearEstandarDesempenoEspecificosCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "servSeccionesEspecificas/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarEstandarDesempenoEspecificosCall(token, data, idServSeccionEspecifico) {
	return axios({
		method: "put",
		url: endpoint + "servSeccionesEspecificas/" + idServSeccionEspecifico,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoEstandarDesempenoEspecificosCall(token, idServSeccionEspecifico) {
	return axios({
		method: "get",
		url: endpoint + "servSeccionesEspecificas/activar/" + idServSeccionEspecifico,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}


// Catálogo Claves Métodos de Supervisión
export function listaClavesMetodosSupervisionActivosCall(token) {
	return axios({
	    method: "get",
		url: endpoint + "clavesMetodosSupervision/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getClavesMetodosSupervisionPageCall(token, page, busqueda) {
	return axios({
		method: "get",
		url: endpoint + "clavesMetodosSupervision/?param=" + busqueda + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearClaveMetodoSupervisionCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "clavesMetodosSupervision/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarClaveMetodoSupervisionCall(token, data, idClaveMetodoSupervision) {
	return axios({
		method: "put",
		url: endpoint + "clavesMetodosSupervision/" + idClaveMetodoSupervision,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function estadoClaveMetodoSupervisionCall(token, idClaveMetodoSupervision) {
	return axios({
		method: "get",
		url: endpoint + "clavesMetodosSupervision/activar/" + idClaveMetodoSupervision,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// Estándares de Desempeño
export function listaEstandaresDesempenoBusquedaCall(token, page, referenciaEstandar, estandar, servicio, seccion, order, field) {
	return axios({
	    	method: "get",
	    	url: endpoint + "estandaresDesempeno?" + referenciaEstandar + estandar + servicio + seccion + order +  field + "page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},	
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function cambiarEstadoEstandarDeDesempeñoCall(token, idEstandarDesempeno) {
	return axios({
		method: "get",
		url: endpoint + "estandaresDesempeno/activa/" + idEstandarDesempeno,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearEstadoEstandarDeDesempeñoCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "estandaresDesempeno/",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarEstandarDeDesempeñoCall(token, data, idEstandarDesempeno) {
	return axios({
		method: "put",
		url: endpoint + "estandaresDesempeno/" + idEstandarDesempeno,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// VALORES DE PAGO ANUAL 
export function getValoresPagoAnualCall(token, page) {
	return axios({
		method: "get",
		url: endpoint + "mecanismos/pago/anual/iNPC0?ano=&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

// activar o desactivar iNPC0
export function cambiarEstadoINCP0(token, idAnoContractual) {
	return axios({
		method: "get",
		url: endpoint + "mecanismos/pago/anual/activa/" + idAnoContractual, 
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

// crear iNPC0
export function crearINCP0Call(token, data) {
	return axios({
		method: "post",
		url: endpoint + "mecanismos/pago/anual/iNPC0/crear", 
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};
// editar iNPC0
export function editarINPC0Call(token, idAnoContratual, data) {
	return axios({
		method: "put",
		url: endpoint + "mecanismos/pago/anual/iNPC0/editar/" + idAnoContratual, 
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};


export function getPARvCall(token, año, page) {
	return axios({
		method: "get",
		url: endpoint + "mecanismos/pago/anual/iNPC0/pago?" + año + "&page=" + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function crearPARvCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "mecanismos/pago/anual/iNPC0/crear/pago",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function verificarPARvCall(token) {
	return axios({
		method: "get",
		url: endpoint + "mecanismos/pago/anual/iNPC0/verificar",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// servicios periodo fraccion maxima de descuento
export function listaPeriodoFraccionMaximaDescCall(token, page){
	return axios({
		method: "get",
		url: endpoint + "mecanismos/pago/anual/periodos?page="+page+"&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function crearPeriodoCall(token, data ){
	return axios({
		method: "post",
		url: endpoint + "mecanismos/pago/anual",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function verificarAñosPeriodoCall(token ){
	return axios({
		method: "get",
		url: endpoint + "mecanismos/pago/anual/verificar/anios",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function editarPeriodoFMDCall(token, idPeriodoFraccionMaxima, data) {
	return axios({
		method: "put",
		url: endpoint + "mecanismos/pago/anual/" + idPeriodoFraccionMaxima,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}


//AÑO MES CALENDARIO SERVICES
export function listaMesesCalendarioCall(token, año) {
	return axios({
		method: "get",
		url: endpoint + "anio/mes/calendario?anio=" + año,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaAñosCalendarioCall(token) {
	return axios({
		method: "get",
		url: endpoint + "anio/mes/calendario/anios",
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function detalleMesCalendarioCall(token, idMes, idAño) {
	return axios({
		method: "get",
		url: endpoint + "anio/mes/calendario/detalles/mes/" + idMes + "/anio/" + idAño,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function calcularMesCalendarioCall(token, idMes, idAño) {
	return axios({
		method: "get",
		url: endpoint + "anio/mes/calendario/calcular/mes/" + idMes + "/anio/" + idAño,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function cerrarMesCalendarioCall(token, idMes) {
	return axios({
		method: "get",
		url: endpoint + "anio/mes/calendario/cerrar/mes/" + idMes,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function crearMesCalendarioCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "anio/mes/calendario",
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function editarMesCalendarioCall(token, data, idMes, idAño) {
	return axios({
		method: "put",
		url: endpoint + "anio/mes/calendario/mes/" + idMes + "/anio/" + idAño,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function agregarPPLCall(token, idMes, idAño, data) {
	return axios({
		method: "put",
		url: endpoint + "anio/mes/calendario/ppls/mes/" + idMes + "/anio/" + idAño,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function exportarExcelMesCalendarioCall(token, año) {
	return axios({
        method: "get",
        responseType: 'blob',
		url: endpoint + "anio/mes/calendario/exportar/excel?anio=" + año,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}
// Calculo de Deductivas
export function listaMesesAbiertosCall(token) {
	return axios({
	    	method: "get",
		url: endpoint + "anio/mes/calendario/meses/abiertos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function valorTipoDeCalculoDeDeductivaCall(token, proyeccion, idMesCalendario) {
	return axios({
	    	method: "get",
		url: endpoint + "deductiva/valores" + idMesCalendario + proyeccion,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaResumenTipoCall(token, fechaProyeccion, idMesCalendario, proyeccion) {
	return axios({
	    	method: "get",
		url: endpoint + "deductiva/resumen/tipo?fechaProyeccion=" + fechaProyeccion + idMesCalendario + proyeccion,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaResumenServicioCall(token, fechaProyeccion, idMesCalendario, proyeccion) {
	return axios({
	    	method: "get",
		url: endpoint + "deductiva/resumen/servicio?fechaProyeccion=" + fechaProyeccion + idMesCalendario + proyeccion,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function deductivaResumenExcelCall(token, fechaProyeccion, idMesCalendario, proyeccion) {
	return axios({
		method: "get",
		url: endpoint + "deductiva/resumen/excel?fechaProyeccion=" + fechaProyeccion + idMesCalendario + proyeccion,
		headers: {'Content-Type': 'application/json','Authorization':token},
		responseType: 'blob'
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaDetalleCalculoDeductivasCall(token, fechaProyeccion, idMesCalendario, idTipoFalla, idTipoFallaEOI, idTipoFallaReit, idAreaFuncional, idGrupoSer, idServicio, fechaCierre1, fechaCierre2) {
	return axios({
	    	method: "get",
		url: endpoint + "deductiva/detalles?fechaProyeccion=" + fechaProyeccion + idMesCalendario + idTipoFalla + idTipoFallaEOI + idTipoFallaReit + idAreaFuncional + idGrupoSer + idServicio + fechaCierre1 + fechaCierre2,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function detalleDeductivaExcelCall(token, fechaProyeccion, idMesCalendario, proyeccion, idTipoFalla, idTipoFallaEOI, idTipoFallaReit, idAreaFuncional, idGrupoSer, idServicio, fechaCierre1, fechaCierre2) {
	return axios({
		method: "get",
		url: endpoint + "deductiva/detalles/excel?fechaProyeccion=" + fechaProyeccion + idMesCalendario + proyeccion + idTipoFalla + idTipoFallaEOI + idTipoFallaReit +  idAreaFuncional + idGrupoSer + idServicio + fechaCierre1 + fechaCierre2,
		headers: {'Content-Type': 'application/json','Authorization':token},
		responseType: 'blob'
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function procesarDeductivaCall(token, fechaProyeccion, idMesCalendario) {
	return axios({
	    	method: "get",
		url: endpoint + "deductiva/procesar?fechaProyeccion=" + fechaProyeccion + idMesCalendario,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function deductivaTicketsNoConciliadosCall(token, idMesCalendario, page) {
	return axios({
	    	method: "get",
		url: endpoint + "deductiva/cierre?idMesCalendario=" + idMesCalendario + '&page=' + page + "&offset=10",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//
export function listaPeriodosTodosCall(token) {
	return axios({
        method: "get",
		url: endpoint + "mecanismos/pago/anual/periodos/activos",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//NIVELES DE SEVERIDAD
export function obtenerNivelesSeveridadActivosCall(token) {
	return axios({
		method: "get",
		url: endpoint + "niveles/severidad/activos", 
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function obtenerNivelesSeveridadBusquedaCall(token, page, busqueda) {
	return axios({
		method: "get",
		url: endpoint + "niveles/severidad?" + busqueda + "page=" + page + "&offset=10", 
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function estadoNivelSeveridadCall(token, idNivelSeveridad) {
	return axios({
		method: "get",
		url: endpoint + "niveles/severidad/activar/" + idNivelSeveridad, 
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function crearNivelSeveridadCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "niveles/severidad", 
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function editarNivelSeveridadCall(token, idNivelSeveridad, data) {
	return axios({
		method: "put",
		url: endpoint + "niveles/severidad/" + idNivelSeveridad, 
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};
//Niveles Tiempos respuesta por seccion
export function listaNivelesTiemposRActivosCall(token) {
	return axios({
		method: 'get',
		url: endpoint + `tiempos/activos`,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then( response => response)
	.catch(error => error.response.data);
}
export function listaNivelesTiemposRCall(token, page, busqueda) {
	busqueda = busqueda!=="" ? `&param=${busqueda}` : "";
	return axios({
		method: 'get',
		url: endpoint + `tiempos/respuesta/seccion?page=${page}&offset=10${busqueda}`,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then( response => response)
	.catch(error => error.response.data);
}
export function nivelTiempoRAnterioCall (token) {
	return axios({
		method: 'get',
		url: endpoint + `tiempos/respuesta/anterior`,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then( response => response)
	.catch( error => error.response.data);
}
export function nivelesTiemposREstadoCall(token,idTiempoR) {
	return axios({
		method: 'get',
		url: endpoint + `tiempos/activar/respuesta/${idTiempoR}`,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then( response => response)
	.catch(error => error.response.data);
}
export function crearNivelTiempoRCall (token, data) {
	return axios({
		method: 'post',
		url: endpoint + `tiempos/respuesta`,
		headers: {'Content-Type': 'application/json', 'Authorization':token},
		data: data
	}).then( response => response)
	.catch ( error => error.response.data);
}
export function editarNivelTiempoRCall (token, data, idTiempoR) {
	return axios({
		method: 'put',
		url: endpoint + `tiempos/respuesta/edicion/${idTiempoR}`,
		headers: {'Content-Type': 'application/json', 'Authorization':token},
		data: data
	}).then( response => response)
	.catch ( error => error.response.data);
}

// Número de Documento por Reiteración de Fallas
export function listaDocumentosReiteradoUltimosCall(token, busqueda) {
	return axios({
		method: 'get',
		url: endpoint + "reiteracion/documento" + busqueda,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then( response => response)
	.catch(error => error.response.data);
}

export function listaTicketsReiteradosCall(token, folio, idAreaFuncional, idServicio, idFalla) {
	return axios({
		method: 'get',
		url: endpoint + "reiteracion/documento/ticket/reiterado" + folio + idAreaFuncional + idServicio + idFalla,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then( response => response)
	.catch(error => error.response.data);
}

export function listaTicketsOrigenCall(token, folio, idAreaFuncional, idServicio, idFalla, idSolicitudServicioReiterada) {
	return axios({
		method: 'get',
		url: endpoint + "reiteracion/documento/ticket/origen" + folio + idAreaFuncional + idServicio + idFalla + idSolicitudServicioReiterada,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then( response => response)
	.catch(error => error.response.data);
}

export function documentoRelacionadoCall(token, idSolicitudServicioOrigen) {
	return axios({
		method: 'get',
		url: endpoint + "reiteracion/documento/relacionado?idSolicitudServicioOrigen=" + idSolicitudServicioOrigen,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then( response => response)
	.catch(error => error.response.data);
}

export function documentoRelacionadoByNumeroCall(token, numeroDocumento) {
	return axios({
		method: 'get',
		url: endpoint + "reiteracion/documento/numero?numeroDocumento=" + numeroDocumento,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then( response => response)
	.catch(error => error.response.data);
}

export function crearDocumentoReiteracionCall (token, data, idDocumentoReiteracion) {
	return axios({
		method: 'post',
		url: endpoint + 'reiteracion/documento' + idDocumentoReiteracion,
		headers: {'Content-Type': 'application/json', 'Authorization':token},
		data: data
	}).then( response => response)
	.catch ( error => error.response.data);
}

export function ticketsReiteradosByNumeroDocumentoCall(token, idDocumentoReiteracion) {
	return axios({
		method: 'get',
		url: endpoint + "reiteracion/documento/ticket/relacionado?idDocumentoReiteracion=" + idDocumentoReiteracion,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then( response => response)
	.catch(error => error.response.data);
}

export function eliminarNumeroDocumentoCall(token, idDocumentoReiteracion) {
	return axios({
		method: 'get',
		url: endpoint + "reiteracion/documento/eliminar?idDocumentoReiteracion=" + idDocumentoReiteracion,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then( response => response)
	.catch(error => error.response.data);
}

export function activosByCodigoInternoCall(token, codigoInterno) {
	return axios({
		method: 'get',
		url: endpoint + "activos/codigo/interno?codigoInterno=" + codigoInterno,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then( response => response)
	.catch(error => error.response.data);
} 


// Conciliacion CU
export function busquedaTicketSSCall(token, busqueda) {
	return axios({
		method: 'get',
		url: endpoint + "conciliacion/solicitud/busqueda?" + busqueda,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then( response => response)
	.catch(error => error.response.data);
} 

export function fallasPorTipoFallaCall(token, idTipoFalla, param) {
	return axios({
		method: 'get',
		url: endpoint + "fallas/tipo/"+ idTipoFalla +"?param=" + param,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then( response => response)
	.catch(error => error.response.data);
} 

export function nivelesDeSeveridadActivosPorFallaCall(token) {
	return axios({
		method: "get",
		url: endpoint + "niveles/severidad/activos", 
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function seccionesPorServiciosCall(token, idServicio) {
	return axios({
		method: "get",
		url: endpoint + "conciliacion/solicitud/seccion?idServicio=" + idServicio, 
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function fallasPorServiciosCall(token, idServicio, param) {
	return axios({
		method: "get",
		url: endpoint + "conciliacion/solicitud/falla?idServicio=" + idServicio + "&param=" + param, 
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function busquedaFolioConciliacionCall(token, param) {
	return axios({
		method: "get",
		url: endpoint + "conciliacion/solicitud/busqueda/conciliada?"+param, 
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function edicionFolioConciliacionCall(token, idConciliacion, data) {
	console.log(data);
	return axios({
		method: "put",
		url: endpoint + "conciliacion/solicitud/"+idConciliacion, 
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//Cálculo de deductivas por Reiteración para el mes i
export function reporteResumenCalculoDeductivaReiteracionCall(token, fechaProyeccion, idMesCalendario) {
	return axios({
	    	method: "get",
		url: endpoint + "reiteracion/resumen?fechaProyeccion=" + fechaProyeccion + idMesCalendario,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function deductivaReiteracionResumenExcelCall(token, fechaProyeccion, idMesCalendario) {
	return axios({
		method: "get",
		url: endpoint + "reiteracion/resumen/excel?fechaProyeccion=" + fechaProyeccion + idMesCalendario,
		headers: {'Content-Type': 'application/json','Authorization':token},
		responseType: 'blob'
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaDetalleCalculoDeductivasReiteracionCall(token, fechaProyeccion, idMesCalendario, idTipoFalla, idAreaFuncional, idGrupoSer, idServicio, fechaCierre1, fechaCierre2) {
	return axios({
	    	method: "get",
		url: endpoint + "reiteracion/detalles?fechaProyeccion=" + fechaProyeccion + idMesCalendario + idTipoFalla + idAreaFuncional + idGrupoSer + idServicio + fechaCierre1 + fechaCierre2,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});

};

export function crearConciliacionCall(token, data) {
	return axios({
		method: "post",
		url: endpoint + "conciliacion/solicitud/", 
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data,
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function detalleDeductivaReiteracionExcelCall(token, fechaProyeccion, idMesCalendario, idTipoFalla, idAreaFuncional, idGrupoSer, idServicio, fechaCierre1, fechaCierre2) {
	return axios({
		method: "get",
		url: endpoint + "reiteracion/detalles/excel?fechaProyeccion=" + fechaProyeccion + idMesCalendario + idTipoFalla + idAreaFuncional + idGrupoSer + idServicio + fechaCierre1 + fechaCierre2,
		headers: {'Content-Type': 'application/json','Authorization':token},
		responseType: 'blob'
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function procesarDeductivaReiteracionCall(token, fechaProyeccion, idMesCalendario) {
	return axios({
	    	method: "get",
		url: endpoint + "reiteracion/procesar?fechaProyeccion=" + fechaProyeccion + idMesCalendario,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}


//Folio de Conciliación
export function busquedaFoliosConciliacionCall (token, busqueda, page) {
	busqueda = busqueda!=="" ? `param=${busqueda}&` : "";
	return axios({
		method: 'get',
		url: endpoint + `conciliacion/folios?${busqueda}page=${page}&offset=10`,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then( response => response)
	.catch(error => error.response.data);
}

export function listaMesesConciliacionCall (token) {
	return axios({
		method: 'get',
		url: endpoint + `conciliacion/meses`,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then( response => response)
	.catch ( error => error.response.data);
}

export function listaTicketsConciliarCall (token, fechaInicio, fechaFin, idServicio, page){
	idServicio = idServicio ? idServicio : "";
	return axios({
		method: 'get',
		url: endpoint + `conciliacion/ss?fechaInicial=${fechaInicio}&fechaFinal=${fechaFin}&idServicio=${idServicio}&page=${page}&offset=10`,
		headers: {'Content-Type': 'application/json','Authorization':token}
	}).then(response => response)
	.catch( error => error.response.data)
}

export function listaServiciosConciliacionCall (token, fechaInicio, fechaFin) {
	return axios({
		method: 'get',
		url: endpoint + `conciliacion/servicios?fechaInicial=${fechaInicio}&fechaFinal=${fechaFin}`,
		headers: {'Content-Type': 'application/json', 'Authorization':token},
	}).then( response => response)
	.catch(error => error.response.data);
}

export function crearFolioConciliacionCall(token, body) {
	return axios ({
		method: 'post',
		url: endpoint + 'conciliacion',
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: body,
	}).then( response => response)
	.catch( error => error.response.data);
}

export function descargarFolioConciliacionCall(token, idFolioConciliacion) {
	return axios({
		method: 'post',
		url: endpoint + `conciliacion/imprimir/folio?idFolioConciliacion=${idFolioConciliacion}`,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
		responseType: 'blob',
	}).then( response => response)
	.catch(error => error.response.data);
}

export function listaTicketsbyFolioCall (token, idFolioConciliacion, page ) {
	return axios({
		method: 'get',
		url: endpoint + `conciliacion/solicitudes?idFolioConciliacion=${idFolioConciliacion}&page=${page}&offset=10`,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
	}).then(response => response)
	.catch(error => error.response.data);
}

export function eliminarTicketFCCall (token, idSSFolioConciliacion) {
	return axios({
		method: 'get',
		url: endpoint + `conciliacion/desactivar/registro/${idSSFolioConciliacion}`,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then( response => response)
	.catch(error => error.response.data)
}

export function editarFolioConciliacionCall (token, idFolioConciliacion, data ) {
	return axios({
		method: 'put',
		url: endpoint + `conciliacion/${idFolioConciliacion}`,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
		data: data,
	}).then(response => response)
	.catch( error => error.response.data);
}

export function cerrarFolioConciliacionCall (token, idFolioConciliacion, data) {
	return axios({
		method: 'put',
		url: endpoint + `conciliacion/cerrar/${idFolioConciliacion}`,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
		data: data,
	}).then(response => response)
	.catch(error => error.response.data)
}

// Folio por Errores u Omisiones
export function getFoliosErrorOmisionCall (token, busqueda, fechaInicio, fechaFin, servicio, page) {
	busqueda = busqueda!=="" ? `folio=${busqueda}&` : "";
	fechaInicio = fechaInicio ? `fechaInicial=${fechaInicio}&` : "";
	fechaFin = fechaFin ? `fechaFinal=${fechaFin}&` : "";
	servicio = servicio ? `idServicio=${servicio}&` : "";
	return axios({
		method: 'get',
		url: endpoint + `folios/error/omision/busqueda?${busqueda}${fechaInicio}${fechaFin}${servicio}page=${page}&offset=10`,
		headers: {'Content-Type': 'application/json', 'Authorization': token}
	}).then(response => response)
	.catch(error => error.response.data);
}

export function getDocFizcalizacionCall (token) {
	return axios({
		method: 'get',
		url: endpoint + 'folios/error/omision/documentos',
		headers: {'Content-Type': 'application/json', 'Authorization': token}
	}).then(response => response)
	.catch (error => error.response.data);
}

export function crearFolioEOCall (token, data) {
	return axios({
		method: 'post',
		url: endpoint + 'folios/error/omision/',
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => response)
	.catch(error => error.response.data);
}

export function editarFolioEOCall (token, data, idFolioErrorOmision) {
	return axios({
		method: 'put',
		url: endpoint + `folios/error/omision/${idFolioErrorOmision}`,
		headers: {'Content-Type': 'application/json','Authorization':token},
		data: data
	}).then(response => response)
	.catch(error => error.response.data);
}

export function cerrarFolioEOCall (token, idFolioErrorOmision) {
	return axios({
		method: 'get',
		url: endpoint + `folios/error/omision/activar/${idFolioErrorOmision}`,
		headers: {'Content-Type': 'application/json', 'Authorization': token},
	}).then(response => response)
	.catch(error => error.response.data);
}

//Cálculo de deductivas por error u omisión para el mes i
export function reporteResumenCalculoDeductivaErrorCall(token, fechaProyeccion, idMesCalendario) {
	return axios({
	    	method: "get",
		url: endpoint + "deductiva/omision/resumen?fechaProyeccion=" + fechaProyeccion + idMesCalendario,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function deductivaErrorResumenExcelCall(token, fechaProyeccion, idMesCalendario) {
	return axios({
		method: "get",
		url: endpoint + "deductiva/omision/resumen/excel?fechaProyeccion=" + fechaProyeccion + idMesCalendario,
		headers: {'Content-Type': 'application/json','Authorization':token},
		responseType: 'blob'
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function listaDetalleCalculoDeductivaErrorCall(token, fechaProyeccion, idMesCalendario, idTipoFalla, idAreaFuncional, idGrupoSer, idServicio, fechaCierre1, fechaCierre2) {
	return axios({
	    	method: "get",
		url: endpoint + "deductiva/omision/detalles?fechaProyeccion=" + fechaProyeccion + idMesCalendario + idTipoFalla + idAreaFuncional + idGrupoSer + idServicio + fechaCierre1 + fechaCierre2,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});

};

export function detalleDeductivaErrorExcelCall(token, fechaProyeccion, idMesCalendario, idTipoFalla, idAreaFuncional, idGrupoSer, idServicio, fechaCierre1, fechaCierre2) {
	return axios({
		method: "get",
		url: endpoint + "deductiva/omision/detalles/excel?fechaProyeccion=" + fechaProyeccion + idMesCalendario + idTipoFalla + idAreaFuncional + idGrupoSer + idServicio + fechaCierre1 + fechaCierre2,
		headers: {'Content-Type': 'application/json','Authorization':token},
		responseType: 'blob'
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
};

export function procesarDeductivaErrorCall(token, fechaProyeccion, idMesCalendario) {
	return axios({
	    	method: "get",
		url: endpoint + "deductiva/omision/procesar?fechaProyeccion=" + fechaProyeccion + idMesCalendario,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

// CU - Pago por Servicios
export function listaPagoServiciosMesesCall(token) {
	return axios({
	    	method: "get",
		url: endpoint + "pago/servicio/meses",
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function calculoPagoServicioCall(token, idMesCalendario) {
	return axios({
	    	method: "get",
		url: endpoint + "pago/servicio/calcular?idMesCalendario=" + idMesCalendario,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function guardarPagoServicioCall(token, idMesCalendario) {
	return axios({
		method: 'post',
		url: endpoint + 'pago/servicio/guardar?idMesCalendario=' + idMesCalendario,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => response)
	.catch(error => error.response.data);
}

export function pagoServicioDetalleCall(token, idMesCalendario) {
	return axios({
		method: 'get',
		url: endpoint + 'pago/servicio/detalles?idMesCalendario=' + idMesCalendario,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => response)
	.catch(error => error.response.data);
}

export function cerrarPagoServicioCall(token, idPagoServicio) {
	return axios({
		method: 'post',
		url: endpoint + 'pago/servicio/cerrar?idPagoServicio=' + idPagoServicio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => response)
	.catch(error => error.response.data);
}

// Pago de servicios públicos
export function listaServiciosPublicosMesCall(token, idMes, page) {
	return axios({
		method: 'get',
		url: endpoint + 'calculo/consumo/detalle/facturacion/mes?idMes=' + idMes + '&page=' + page + '&offset=10',
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => response)
		.catch(error => error.response.data);
}

export function listaServiciosPublicosGeneralesCall(token, idMes, page) {
	return axios({
		method: 'get',
		url: endpoint + 'calculo/consumo/detalle/facturacion/general?idMes=' + idMes + '&page=' + page + '&offset=10',
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => response)
		.catch(error => error.response.data);
}

export function exportarPDFPagoDeServiciosPublicosCall(token, idMes, data) {
	return axios({
		method: "post",
		responseType: 'blob',
		url: endpoint + 'calculo/consumo/facturacion/pdf/mes?idMes=' + idMes,
		headers: { 'Content-Type': 'application/json', 'Authorization': token },
		data: data
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

//RDO | Monitor de Deductivas
export function listaMesesProcesadosCall(token, anio){
	return axios({
		method: 'get',
		url: endpoint + 'anio/mes/calendario/meses/procesados' + anio,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => response)
	.catch(error => error.response.data);
} 

export function listaDeductivasRDOCall(token, tipoDeductiva, idMesCalendario){
	return axios({
		method: 'get',
		url: endpoint + 'rdo/deductivas?tipoDeductiva=' + tipoDeductiva + idMesCalendario,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => response)
	.catch(error => error.response.data);
}

export function detalleDeductivaRDOCall(token, tipoGrafica, idMesCalendario){
	return axios({
		method: 'get',
		url: endpoint + 'rdo/deductivas/detalles?tipoGrafica=' + tipoGrafica + idMesCalendario,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => response)
	.catch(error => error.response.data);
} 

export function exportarDetalleDeductivaRDOExcelCall(token, tipoGrafica, idMesCalendario) {
	return axios({
        method: "get",
        responseType: 'blob',
		url: endpoint + "rdo/deductivas/detalles/excel?tipoGrafica=" + tipoGrafica + idMesCalendario,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function deductivasAnterioresRDOCall(token, idMesCalendario){
	return axios({
		method: 'get',
		url: endpoint + 'rdo/deductivas/anteriores' + idMesCalendario,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => response)
	.catch(error => error.response.data);
} 

//  Reporte de Deductivas 
export function getResumenReporteDeductivasCall(token, tipoDeductiva, idMesCalendario){
	return axios({
		method: 'get',
		url: endpoint + 'deductiva/reporte/resumen?tipoDeductiva=' + tipoDeductiva + idMesCalendario,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => response)
	.catch(error => error.response.data);
} 

export function getResumenReporteDeductivasExcelCall(token, tipoDeductiva, idMesCalendario) {
	return axios({
        method: "get",
        responseType: 'blob',
		url: endpoint + 'deductiva/reporte/resumen/excel?tipoDeductiva=' + tipoDeductiva + idMesCalendario,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getDetalleResumenReporteDeductivasCall(token, tipoDeductiva, idMesCalendario){
	return axios({
		method: 'get',
		url: endpoint + 'deductiva/reporte/detalles?tipoDeductiva=' + tipoDeductiva + idMesCalendario,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => response)
	.catch(error => error.response.data);
} 

export function getDetalleResumenReporteDeductivasExcelCall(token, tipoDeductiva, idMesCalendario) {
	return axios({
        method: "get",
        responseType: 'blob',
		url: endpoint + 'deductiva/reporte/detalles/excel?tipoDeductiva=' + tipoDeductiva + idMesCalendario,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getConcentradoAnualResumenCall(token, tipoDeductiva, fechaInicio, fechaFin){
	return axios({
		method: 'get',
		url: endpoint + 'deductiva/reporte/concentrado/anual/resumen?tipoDeductiva=' + tipoDeductiva + '&fechaInicio=' + fechaInicio + '&fechaFin=' + fechaFin,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => response)
	.catch(error => error.response.data);
}

export function getDetalleConcentradoAnualCall(token, tipoDeductiva, fechaInicio, fechaFin, page){
	return axios({
		method: 'get',
		url: endpoint + 'deductiva/reporte/concentrado/anual/detalles?tipoDeductiva=' + tipoDeductiva + '&fechaInicio=' + fechaInicio + '&fechaFin=' + fechaFin + '&page=10' + '&offset=' + page,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => response)
	.catch(error => error.response.data);
} 

export function getResumenConcentradoAnualExcelCall(token, tipoDeductiva, fechaInicio, fechaFin) {
	return axios({
        method: "get",
        responseType: 'blob',
		url: endpoint + 'deductiva/reporte/concentrado/anual/resumen/excel?tipoDeductiva=' + tipoDeductiva + '&fechaInicio=' + fechaInicio + '&fechaFin=' + fechaFin,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getDetalleConcentradoAnualExcelCall(token, tipoDeductiva, fechaInicio, fechaFin) {
	return axios({
        method: "get",
        responseType: 'blob',
		url: endpoint + 'deductiva/reporte/concentrado/anual/detalles/excel?tipoDeductiva=' + tipoDeductiva + '&fechaInicio=' + fechaInicio + '&fechaFin=' + fechaFin,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}

export function getDetalleReporteDeductivasPDFCall(token, tipoDeductiva, idMesCalendario) {
	return axios({
        method: "get",
        responseType: 'blob',
		url: endpoint + "deductiva/reporte/detalles/pdf?tipoDeductiva=" + tipoDeductiva + idMesCalendario,
		headers: {'Content-Type': 'application/json','Authorization':token},
	}).then(response => {
		return response;
	}).catch(error => {
		return error.response.data;
	});
}