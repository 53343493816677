import React, { Component } from 'react';
import { Form, Button, Row, Col, Spin, message, DatePicker, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";
import moment from 'moment';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const { RangePicker } = DatePicker;

class ArchivoEditarEstandarDeDesempeño extends Component {
    formRef = React.createRef();
    state = {
        filePDF: false,
        botonPDF: false,
        botonEditar: false,
    }
    render() {
        const { 
                onEditarEstandarDesempeño, 
                EstandaresDeDesempeñoSeleccionado, 
                fetchingEditarEstandaresDeDesempeño, 
                formularioEstandaresDeDesempeño,
                EstandaresDeDesempeñoEditados, 
                page,
                busqueda, 
                limpiarFormulario, 
                onLimpiarFormulario,
                listaEstandaresDeDesempeñoBusqueda,
                listaReferenciasDeEstandaresActivos,
                listaClavesMetodosSupervisionActivos,
                listaEstandaresDesempenoEspecificosActivos,
                unidadesMediPorCategoria, 
                listaCategoriasFallasDeCalidadActivos
        } = this.props;

        if(limpiarFormulario && limpiarFormulario === 'editarFallaPDF'){
            console.log('entró a limpiar');
            this.formRef.current.resetFields();
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
            onLimpiarFormulario();
        }
        const onFinish = formularioPDF => {
            console.log('formularioPDF');
            console.log(formularioPDF);
            onEditarEstandarDesempeño(formularioEstandaresDeDesempeño, page, busqueda, EstandaresDeDesempeñoEditados, formularioPDF, EstandaresDeDesempeñoSeleccionado, listaEstandaresDeDesempeñoBusqueda, listaReferenciasDeEstandaresActivos, listaClavesMetodosSupervisionActivos, listaEstandaresDesempenoEspecificosActivos, unidadesMediPorCategoria, listaCategoriasFallasDeCalidadActivos);
        };
        

        const onChangePDF = (info) => {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            else{
                this.setState({
                    botonPDF: true,
                    botonEditar: true,
                })
            }
            var reGex = new RegExp("^[-a-zA-Z0-9._]{4,30}$");
            if (info.file.status === 'done') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.success(`${info.file.name} se cargó de forma correcta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            } else if (info.file.status === 'error') {
                if(!reGex.test(info.file.name)){
                    message.error('No se aceptan nombres de archivo mayores a 30 caracteres, espacios y caracteres especiales (@, ", #, /, etc... ). Favor de cambiar el nombre', 8);
                    this.formRef.current.resetFields(['pdf']);
                }
                else{
                    message.error(`${info.file.name} se cargó de forma incorrecta`);
                    this.setState({
                        filePDF: true
                    });
                }
                this.setState({
                    botonPDF: false,
                    botonEditar: false,
                });
            }
        }
        const onRemovePDF = () => {
            this.setState({
                filePDF: false,
                botonPDF: false,
            });
        }
        const dummyRequest = ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        };
        return (
            <Spin spinning={fetchingEditarEstandaresDeDesempeño}>
                <Form {...layout}
                name="formulario"
                onFinish={onFinish}
                
                ref={this.formRef}
                >
                    <Row justify='space-around'>
                        <Col span={12}>
                            <Form.Item
                                label="Adjunta el archivo PDF"
                                name="pdf"
                                rules={[{ required: true, message: 'Favor de llenar el campo' }]}
                            >
                                <Upload customRequest={dummyRequest} onChange={onChangePDF} onRemove={onRemovePDF} accept='.pdf' multiple={false}>
                                    {this.state.filePDF === false ? (
                                    <Button disabled={this.state.botonPDF} className='btnCargaArchivos'>
                                        <UploadOutlined /> Click para subir archivo PDF
                                    </Button>
                                    ):false}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={11} className='marginBoton'>
                            <br/>
                            <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit">Editar Categoría </Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        );
    }
}

const mapStateToProps = state => {
	return {
          EstandaresDeDesempeñoEditados: state.MecanismosDePagosReducer.EstandaresDeDesempeñoEditados,
          formularioEstandaresDeDesempeño: state.MecanismosDePagosReducer.formularioEstandaresDeDesempeño,
          fetchingEditarEstandaresDeDesempeño: state.MecanismosDePagosReducer.fetchingEditarEstandaresDeDesempeño,
          EstandaresDeDesempeñoSeleccionado: state.MecanismosDePagosReducer.EstandaresDeDesempeñoSeleccionado,
          page: state.MecanismosDePagosReducer.page,
          busqueda: state.MecanismosDePagosReducer.busqueda,
          listaInfoProyecto: state.InformacionDelProyectoReducer.listaInfoProyecto,
          listaEstandaresDeDesempeñoBusqueda: state.MecanismosDePagosReducer.listaEstandaresDeDesempeñoBusqueda,
          listaReferenciasDeEstandaresActivos: state.MecanismosDePagosReducer.listaReferenciasDeEstandaresActivos,
          listaClavesMetodosSupervisionActivos: state.MecanismosDePagosReducer.listaClavesMetodosSupervisionActivos,
          listaEstandaresDesempenoEspecificosActivos: state.MecanismosDePagosReducer.listaEstandaresDesempenoEspecificosActivos,
          unidadesMediPorCategoria: state.AlmacenesReducer.unidadesMediPorCategoria,
          listaCategoriasFallasDeCalidadActivos: state.MecanismosDePagosReducer.listaCategoriasFallasDeCalidadActivos
	};
};

const mapDispatchToProps = dispatch => {
	return {
          onEditarEstandarDesempeño: ( formularioEstandaresDeDesempeño, page, busqueda, EstandaresDeDesempeñoEditados, formularioPDF, EstandaresDeDesempeñoSeleccionado, listaEstandaresDeDesempeñoBusqueda, listaReferenciasDeEstandaresActivos, listaClavesMetodosSupervisionActivos, listaEstandaresDesempenoEspecificosActivos, unidadesMediPorCategoria, listaCategoriasFallasDeCalidadActivos ) => {
               dispatch({ type: "EDITAR_ESTANDAR_DESEMPEÑO_REQUEST" , formulario: formularioEstandaresDeDesempeño, page: page, busqueda: busqueda, EstandaresDeDesempeñoEditados: EstandaresDeDesempeñoEditados, formularioPDF: formularioPDF, EstandaresDeDesempeñoSeleccionado: EstandaresDeDesempeñoSeleccionado, listaEstandaresDeDesempeñoBusqueda: listaEstandaresDeDesempeñoBusqueda, listaReferenciasDeEstandaresActivos: listaReferenciasDeEstandaresActivos, listaClavesMetodosSupervisionActivos: listaClavesMetodosSupervisionActivos, listaEstandaresDesempenoEspecificosActivos: listaEstandaresDesempenoEspecificosActivos, unidadesMediPorCategoria: unidadesMediPorCategoria, listaCategoriasFallasDeCalidadActivos: listaCategoriasFallasDeCalidadActivos });
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
          },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchivoEditarEstandarDeDesempeño);