import React, { Component } from 'react';
import { Form, Input, Button, Modal, Row, Col, Spin, Select, Upload } from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from "react-redux";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
class ModalCambiarEstadoPersonal extends Component {
    formRef = React.createRef();
    render() {
        const { showModalEstadoPersonal, onEstadoPersonal, fetchingListaPersonalBusqueda, page, busqueda, orden,
            personalSeleccionado, limpiarFormulario, onLimpiarFormulario, onShowEstadoPersonal
        } = this.props;

        const onFinish = (formulario) => {
            onEstadoPersonal(personalSeleccionado.idPersonal, page, busqueda, orden, formulario);
        }
        

        if (limpiarFormulario && limpiarFormulario === 'estadoPersonal') {
            this.formRef.current.resetFields();
            onLimpiarFormulario();
            onShowEstadoPersonal();
        }

        const handleCancel = () => {
            onShowEstadoPersonal();
            this.formRef.current.resetFields();
        }

        
        return (
            <Modal
            visible={showModalEstadoPersonal}
            title='Cambiar Estado Personal'
            onCancel={handleCancel}
            footer={false}
            >
                <Spin spinning={fetchingListaPersonalBusqueda}>
                    <Form {...layout}
                    name="formulario"
                    onFinish={onFinish}
                    
                    ref={this.formRef}
                    >   
                        <Row justify='space-around'>
                            <Col span={24}>
                                <Form.Item
                                    label="Razón de baja"
                                    name="mensaje"
                                    rules={[{ required: true, message: 'Favor de llenar el campo', }]}
                                >
                                    <Input maxLength={100} placeholder='Ingresa la razón de la baja' />
                                </Form.Item>
                            </Col>
                            
                            <Col span={24} className='marginBoton'>
                                <br/>
                                <Button className="btnFiltrar" style={{width: '100%'}} htmlType="submit" >Cambiar Estado Personal</Button>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
	return {
        fetchingListaPersonalBusqueda: state.RecursosHumanosReducer.fetchingListaPersonalBusqueda,
        showModalEstadoPersonal: state.RecursosHumanosReducer.showModalEstadoPersonal,
        personalSeleccionado: state.RecursosHumanosReducer.personalSeleccionado,
        busqueda: state.RecursosHumanosReducer.busqueda,
        orden: state.RecursosHumanosReducer.orden,
        page: state.RecursosHumanosReducer.page,
        limpiarFormulario: state.ConfiguracionYSeguridadReducer.limpiarFormulario,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        onShowEstadoPersonal: (page) => {
            dispatch({ type: 'SHOW_MODAL_ESTADO_PERSONAL', showModalEstadoPersonal: false, page })
        },
        onEstadoPersonal: (idPersonal, page, busqueda, orden, formulario) => {
	    	dispatch({ type: "CAMBIAR_ESTADO_PERSONAL_REQUEST", idPersonal, page, busqueda, orden, formulario });
        },
        onLimpiarFormulario: () => {
            dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null });
        },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCambiarEstadoPersonal);