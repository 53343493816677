import { takeLatest, call, put } from "redux-saga/effects";
import { Modal } from 'antd';
import moment from 'moment';
import * as serviciosPublicosActions from '../actions/ServiciosPublicosActions';
import * as configuracionYSeguridadActions from '../actions/ConfiguracionYSeguridadActions'
import {
        
        listaVariablesMedicionBusquedaCall, estadoVariablesMedicionCall, crearVariablesMedicionCall, editarVariablesDeMedicionCall, listaVariablesMedicionActivasCall,
        listaProgramacionMedicionCall, crearProgramacionMedicionCall, editarProgramacionMedicionCall, estadoProgramacionConsumoCall,
        serviciosPublicosBusquedaCall, crearServicioPublicoCall, editarServicioPublicoCall, estadoServicioPublicoCall, listaServiciosPublicosActivosSinUsoCall,
        listaServiciosPublicosACall, listaServiciosPublicosNACall, listaDepositosSPACall, crearAsignacionDepositosCall, editarAsignacionDepositosCall, listaDepositosActivosCall, listaDepositosActivosSinIdProgramacionCall,
        programacionByServicioCall, variableMedicionByProgramacionCall, horariosCapturablesByServicioCall, registroLecturaCall, horariosCapturablesByServicioAdminCall, registroLecturasPresentacionCall, registroLecturasReporteCall, verificarRegistroLecturaCall,
        listaSPCalculoCall, listaSPCalculoBusquedaCall, listaDetalleCCDInternoCall, listaDetalleCCDFactorizacionCall, lecturaInicioCDInternoCall, lecturaFinCDInternoCall, crearCalculoCDInternoCall, crearCalculoCDFacturacionCall, calculoCDInternoPDFCall, calculoCDInternoExcelCall, calculoCDFacturacionPDFCall, calculoCDFacturacionExcelCall,
        listaConsumosAsignacionByIdServicioPublicoACall, crearAbastecimientoCall, listaVariablesMedicionByIdServicioPublicoCall, registroLecturaPDFCall, registroLecturasPresentacionDisponibilidadCall, registroLecturasPresentacionConsumoCall, listaUbicacionesByIdServicioPublicoCall, registroLecturaDisponibilidadYConsumoPDFCall, registroLecturaDisponibilidadYConsumoExcelCall

} from '../../services/ServiciosPublicosServices';
import { message } from "antd";

// SAGA SERVICIOS PÚBLICOS
// Lista Servicios Públicos Activos.

function* listaServiciosPublicosActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaServiciosPublicosActivosSinUsoCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaServiciosPublicosActivos = response.data;

            yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_ACTIVOS_SUCCESS, listaServiciosPublicosActivos });
        }
        else {
            yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_ACTIVOS_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_ACTIVOS_FAILURE });
    }
}
export function* listaServiciosPublicosActivosSaga() {
    yield takeLatest(serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_ACTIVOS_REQUEST, listaServiciosPublicosActivos);
}

//Catálogo de Servicios Públicos
function* serviciosPublicosBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let busqueda = '';
        if (action.busqueda) {
            busqueda = 'param=' + action.busqueda + '&';
        }

        const response = yield call(serviciosPublicosBusquedaCall, token, page, busqueda);
        console.log(response);

        if (response.status == 200) {
            let listaServiciosPublicosBusqueda = response.data.data;
            let listaServiciosPublicosBusquedaCantidad = response.data.cantidad;
            
            yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_BUSQUEDA_SUCCESS, listaServiciosPublicosBusqueda, listaServiciosPublicosBusquedaCantidad });
        }
        else {
            yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_BUSQUEDA_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_BUSQUEDA_FAILURE });
    }
}
export function* serviciosPublicosBusquedaSaga() {
    yield takeLatest(serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_BUSQUEDA_REQUEST, serviciosPublicosBusqueda);
}

function* estadoServicioPublico(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServicioPublico = action.idServicioPublico;

        const response = yield call(estadoServicioPublicoCall, token, idServicioPublico);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const busqueda = action.busqueda;
            const showSuccessMsg = true;
            const textMessage = "El estado del servicio público se cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPublicosActions.ESTADO_SERVICIO_PUBLICO_SUCCESS });
            yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_BUSQUEDA_REQUEST, page, busqueda });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: serviciosPublicosActions.ESTADO_SERVICIO_PUBLICO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.ESTADO_SERVICIO_PUBLICO_FAILURE });
    }
}
export function* estadoServicioPublicoSaga() {
    yield takeLatest(serviciosPublicosActions.ESTADO_SERVICIO_PUBLICO_REQUEST, estadoServicioPublico);
}

function* crearServicioPublico(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let calculo = false;
        let abastecimiento =  action.formulario.abastecimiento ? action.formulario.abastecimiento : false;

        if(action.formulario.calculo){
            calculo = action.formulario.calculo;
        }

        const data = {
            "idServicioPublico": null,
            "nombre": action.formulario.nombre,
            "nombreDeposito": action.formulario.nombreDeposito,
            "cantidadDeposito": action.formulario.cantidadDeposito,
            "calculo": calculo,
            "abastecimiento": abastecimiento,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }
        console.log('data');
        console.log(data);
        const response = yield call(crearServicioPublicoCall, token, data);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const busqueda = action.busqueda;
            const showModalNuevoServicioPublico = false;
            const showSuccessMsg = true;
            const textMessage = "El servicio público " + action.formulario.nombre + " fue agregado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "crearServicioPublico" });
            yield put({ type: serviciosPublicosActions.NUEVO_SERVICIO_PUBLICO_SUCCESS });
            yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: serviciosPublicosActions.SHOW_MODAL_NUEVO_SERVICIO_PUBLICO, showModalNuevoServicioPublico });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: serviciosPublicosActions.NUEVO_SERVICIO_PUBLICO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_ACTIVOS_FAILURE });
        yield put({ type: serviciosPublicosActions.NUEVO_SERVICIO_PUBLICO_FAILURE });

    }
}
export function* crearServicioPublicoSaga() {
    yield takeLatest(serviciosPublicosActions.NUEVO_SERVICIO_PUBLICO_REQUEST, crearServicioPublico);
}

function* editarServicioPublico(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServicioPublico = action.servicioPublicoSeleccionado.idServicioPublico;

        const data = {
            "idServicioPublico": idServicioPublico,
            "nombre": action.formulario.nombre,
            "nombreDeposito": action.formulario.nombreDeposito,
            "cantidadDeposito": action.formulario.cantidadDeposito,
            "calculo": action.formulario.calculo,
            "abastecimiento": action.formulario.abastecimiento,
            "fechaAlta":action.servicioPublicoSeleccionado.fechaAlta,
            "fechaActualizacion": action.servicioPublicoSeleccionado.fechaActualizacion,
            "activo": action.servicioPublicoSeleccionado.activo
        }
        console.log('data');
        console.log(data);
        const response = yield call(editarServicioPublicoCall, token, data, idServicioPublico);
        console.log(response);
 
        if (response.status == 200) {
            const page = action.page;
            const busqueda = action.busqueda;
            const showModalEditarServicioPublico = false;
            const showSuccessMsg = true;
            const textMessage = "El servicio público " + action.formulario.nombre + " fue actualizado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: 'editarServicioPublico' });
            yield put({ type: serviciosPublicosActions.EDITAR_SERVICIO_PUBLICO_SUCCESS });
            yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: serviciosPublicosActions.SHOW_MODAL_EDITAR_SERVICIO_PUBLICO, showModalEditarServicioPublico });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: serviciosPublicosActions.EDITAR_SERVICIO_PUBLICO_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.EDITAR_SERVICIO_PUBLICO_FAILURE });
    }
}
export function* editarServicioPublicoSaga() {
    yield takeLatest(serviciosPublicosActions.EDITAR_SERVICIO_PUBLICO_REQUEST, editarServicioPublico);
}


// SAGA VARIABLES DE MEDICIÓN
// Lista Busqueda
function* listaVariablesMedicionBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let busqueda = '';
        if (action.busqueda) {
            busqueda = action.busqueda;
        }

        const response = yield call(listaVariablesMedicionBusquedaCall, token, page, busqueda);
        console.log(response);

        if (response.status == 200) {
            let listaVariablesMedicionBusqueda = response.data.data;
            let listaVariablesMedicionBusquedaCantidad = response.data.cantidad;

            for (let i = 0; i < listaVariablesMedicionBusqueda.length; i++) {
                let fechaAlta = listaVariablesMedicionBusqueda[i].fechaAlta.split('T')[0];
                listaVariablesMedicionBusqueda[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
                let fechaActualizacion = listaVariablesMedicionBusqueda[i].fechaActualizacion.split('T')[0];
                listaVariablesMedicionBusqueda[i].fechaActualizacion = fechaActualizacion.split('-')[2] + '-' + fechaActualizacion.split('-')[1] + '-' + fechaActualizacion.split('-')[0];

                if(listaVariablesMedicionBusqueda[i].descripcion === ''){
                        listaVariablesMedicionBusqueda[i].descripcion = 'No cuenta con descripción.'
                }

                if(listaVariablesMedicionBusqueda[i].unidadMedidaByIdUnidadMedida){
                        listaVariablesMedicionBusqueda[i].unidadMedida = listaVariablesMedicionBusqueda[i].unidadMedidaByIdUnidadMedida.unidadMedida;
                }
            }
            
            yield put({ type: serviciosPublicosActions.LISTA_VARIABLES_MEDICION_SUCCESS, listaVariablesMedicionBusqueda, listaVariablesMedicionBusquedaCantidad });
        }
        else {
            yield put({ type: serviciosPublicosActions.LISTA_VARIABLES_MEDICION_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.LISTA_VARIABLES_MEDICION_FAILURE });
    }
}
export function* listaVariablesMedicionBusquedaSaga() {
    yield takeLatest(serviciosPublicosActions.LISTA_VARIABLES_MEDICION_REQUEST, listaVariablesMedicionBusqueda);
}

// Lista Activos
function* listaVariablesMedicionActivas(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaVariablesMedicionActivasCall, token);
        console.log(response);

        if (response.status == 200) {
            const listaVariablesMedicionActivos = response.data;

            yield put({ type: serviciosPublicosActions.LISTA_VARIABLES_MEDICION_ACTIVOS_SUCCESS, listaVariablesMedicionActivos });
        }
        else {
            yield put({ type: serviciosPublicosActions.LISTA_VARIABLES_MEDICION_ACTIVOS_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.LISTA_VARIABLES_MEDICION_ACTIVOS_FAILURE });
    }
}
export function* listaVariablesMedicionActivasSaga() {
    yield takeLatest(serviciosPublicosActions.LISTA_VARIABLES_MEDICION_ACTIVOS_REQUEST, listaVariablesMedicionActivas);
}

 // Cambiar estado
function* estadoVariablesMedicion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idVariableMedicion = action.idVariableMedicion;

        const response = yield call(estadoVariablesMedicionCall, token, idVariableMedicion);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showSuccessMsg = true;
            const textMessage = "El estado de la variable de medición se cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPublicosActions.ESTADO_VARIABLES_MEDICION_SUCCESS });
            yield put({ type: serviciosPublicosActions.LISTA_VARIABLES_MEDICION_REQUEST, page });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: serviciosPublicosActions.ESTADO_VARIABLES_MEDICION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.ESTADO_VARIABLES_MEDICION_FAILURE });
    }
}
export function* estadoVariablesMedicionSaga() {
     yield takeLatest(serviciosPublicosActions.ESTADO_VARIABLES_MEDICION_REQUEST, estadoVariablesMedicion);
}

// Crear Variable de Medición
function* crearVariablesMedicion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const data = {
            "idVariableMedicion": null,
            "idUnidadMedida": parseInt(action.idUnidadMedida),
            "nombre": action.nombre,
            "operacion": action.operacion,
            "descripcion": action.descripcion,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }
        
        const response = yield call(crearVariablesMedicionCall, token, data);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showModalNuevaVariableMedicion = false;
            const showSuccessMsg = true;
            const textMessage = "La variable de medición " + action.nombre + " fue agregada con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "crearVariableMedicion" });
            yield put({ type: serviciosPublicosActions.NUEVA_VARIABLE_MEDICION_SUCCESS });
            yield put({ type: serviciosPublicosActions.LISTA_VARIABLES_MEDICION_REQUEST, page });
            yield put({ type: serviciosPublicosActions.SHOW_MODAL_NUEVA_VARIABLE_MEDICION, showModalNuevaVariableMedicion });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: serviciosPublicosActions.NUEVA_VARIABLE_MEDICION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.NUEVA_VARIABLE_MEDICION_FAILURE });
    }
}
export function* crearVariablesMedicionSaga() {
yield takeLatest(serviciosPublicosActions.NUEVA_VARIABLE_MEDICION_REQUEST, crearVariablesMedicion);
}

//Editar Variables de Medición
function* editarVariablesDeMedicion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idVariableMedicion = action.variableMedicionSeleccionado.idVariableMedicion;

        let idUnidadMedida = null;
        for(let i = 0; i < action.listaUnidadesDeMedicionActivos.length; i++){
            if(action.listaUnidadesDeMedicionActivos[i].unidadMedida === action.formulario.unidadMedida){
                idUnidadMedida = action.listaUnidadesDeMedicionActivos[i].idUnidadMedida
            }
        }

        const data = {
            "idVariableMedicion": null,
            "idUnidadMedida": idUnidadMedida,
            "nombre": action.formulario.nombre,
            "operacion": action.formulario.operacion,
            "descripcion": action.formulario.descripcion,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true
        }

        const response = yield call(editarVariablesDeMedicionCall, token, data, idVariableMedicion);
        console.log(response);
 
        if (response.status == 200) {
            const page = action.page;
            const showModalEditarVariableMedicion = false;
            const variableMedicionSeleccionado = null;
            const limpiarFormulario = "editarVariable";
            const showSuccessMsg = true;
            const textMessage = "La variable de medición " + action.formulario.nombre + " fue actualizada con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario });
            yield put({ type: serviciosPublicosActions.EDITAR_VARIABLES_MEDICION_SUCCESS });
            yield put({ type: serviciosPublicosActions.LISTA_VARIABLES_MEDICION_REQUEST, page });
            yield put({ type: serviciosPublicosActions.SHOW_MODAL_EDITAR_VARIABLE_MEDICION, showModalEditarVariableMedicion, variableMedicionSeleccionado });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: serviciosPublicosActions.EDITAR_VARIABLES_MEDICION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.EDITAR_VARIABLES_MEDICION_FAILURE });
    }
}
export function* editarVariablesDeMedicionSaga() {
    yield takeLatest(serviciosPublicosActions.EDITAR_VARIABLES_MEDICION_REQUEST, editarVariablesDeMedicion);
}

// SAGA Programación y Medición de Consumos.
// Busqueda
function* listaProgramacionMedicion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let page = action.page - 1;
        let busqueda = '';
        if (action.busqueda) {
            busqueda = action.busqueda;
        }

        const response = yield call(listaProgramacionMedicionCall, token, page, busqueda);
        console.log(response.data.data);

            if (response.status == 200) {
                let listaProgramacionMedicionBusqueda = response.data.data;
                let listaProgramacionMedicionCantidad = response.data.cantidad;

                for (let i = 0; i < listaProgramacionMedicionBusqueda.length; i++) {
                    let fechaAlta = listaProgramacionMedicionBusqueda[i].fechaAlta.split('T')[0];
                    listaProgramacionMedicionBusqueda[i].fechaAlta = fechaAlta.split('-')[2] + '-' + fechaAlta.split('-')[1] + '-' + fechaAlta.split('-')[0];
                    let fechaActualizacion = listaProgramacionMedicionBusqueda[i].fechaActualizacion.split('T')[0];
                    listaProgramacionMedicionBusqueda[i].fechaActualizacion = fechaActualizacion.split('-')[2] + '-' + fechaActualizacion.split('-')[1] + '-' + fechaActualizacion.split('-')[0];

                    listaProgramacionMedicionBusqueda[i].todasVariables = false;

                    if(listaProgramacionMedicionBusqueda[i].servicioPublicoByIdServicioPublico){
                        listaProgramacionMedicionBusqueda[i].servicioPublico = listaProgramacionMedicionBusqueda[i].servicioPublicoByIdServicioPublico.nombre
                    }

                    // LECTURA CON UN HORARIO
                    if(listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[0]){
                        listaProgramacionMedicionBusqueda[i].horariosProgramacion  = (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[0].horario).split(':')[0] + ":" + (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[0].horario).split(':')[1];

                        listaProgramacionMedicionBusqueda[i].NumLecturas = 1;

                        listaProgramacionMedicionBusqueda[i].horarioLectura0 = moment(listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[0].horario, 'HH:mm:ss');

                        // LECTURA CON DOS HORARIOS
                        if(listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[1]){
                            //console.log('Lista condición lectura con dos horarios')
                            listaProgramacionMedicionBusqueda[i].horariosProgramacion = (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[0].horario).split(':')[0] + ":" + (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[0].horario).split(':')[1] + "; " + (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[1].horario).split(':')[0] + ":" + (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[1].horario).split(':')[1];

                            listaProgramacionMedicionBusqueda[i].NumLecturas = 2;

                            listaProgramacionMedicionBusqueda[i].horarioLectura1 = moment(listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[1].horario, 'HH:mm:ss');

                            // LECTURA CON TRES HORARIOS
                            if(listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[2]){
                                //console.log('Lista condición lectura con tres horarios')
                                listaProgramacionMedicionBusqueda[i].horariosProgramacion = (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[0].horario).split(':')[0] + ":" +  (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[0].horario).split(':')[1] + "; " + 
                                
                                (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[1].horario).split(':')[0] + ":" + (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[1].horario).split(':')[1] + "; " + 
                                
                                (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[2].horario).split(':')[0] + ":" + (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[2].horario).split(':')[1]

                                listaProgramacionMedicionBusqueda[i].NumLecturas = 3;

                                listaProgramacionMedicionBusqueda[i].horarioLectura2 = moment(listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[2].horario, 'HH:mm:ss');
                                
                                // LECTURA CON CUATRO HORARIOS
                                if(listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[3]){
                                    //console.log('Lista condición lectura con cuatro horarios')
                                    listaProgramacionMedicionBusqueda[i].horariosProgramacion = (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[0].horario).split(':')[0] + ":" +  (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[0].horario).split(':')[1] + "; " + 
                                    
                                    (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[1].horario).split(':')[0] + ":" + (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[1].horario).split(':')[1] + "; " + 
                                    
                                    (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[2].horario).split(':')[0] + ":" + (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[2].horario).split(':')[1] 
                                    + "; " + 

                                    (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[3].horario).split(':')[0] + ":" + (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[3].horario).split(':')[1]
    
                                    listaProgramacionMedicionBusqueda[i].NumLecturas = 4;
    
                                    listaProgramacionMedicionBusqueda[i].horarioLectura3 = moment(listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[3].horario, 'HH:mm:ss');

                                    // LECTURA CON CINCO HORARIOS
                                    if(listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[4]){
                                        listaProgramacionMedicionBusqueda[i].horariosProgramacion = (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[0].horario).split(':')[0] +  ":" + (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[0].horario).split(':')[1] + "; " + 
                                        
                                        (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[1].horario).split(':')[0] + ":" + (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[1].horario).split(':')[1] + "; " + 
                                        
                                        (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[2].horario).split(':')[0] + ":" + (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[2].horario).split(':')[1] 
                                        + "; " + 
    
                                        (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[3].horario).split(':')[0] + ":" + (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[3].horario).split(':')[1]
                                        + "; " + 

                                        (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[4].horario).split(':')[0] + ":" + (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[4].horario).split(':')[1]
        
                                        listaProgramacionMedicionBusqueda[i].NumLecturas = 5;
        
                                        listaProgramacionMedicionBusqueda[i].horarioLectura4 = moment(listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[4].horario, 'HH:mm:ss');

                                        // LECTURA CON SEIS HORARIOS
                                        if(listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[5]){
                                            listaProgramacionMedicionBusqueda[i].horariosProgramacion = (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[0].horario).split(':')[0] + ":" + (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[0].horario).split(':')[1] + "; " + 
                                            
                                            (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[1].horario).split(':')[0] + ":" + (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[1].horario).split(':')[1] + "; " + 
                                            
                                            (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[2].horario).split(':')[0] + ":" + (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[2].horario).split(':')[1] 
                                            + "; " + 
        
                                            (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[3].horario).split(':')[0] + ":" + (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[3].horario).split(':')[1]
                                            + "; " + 
    
                                            (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[4].horario).split(':')[0] + ":" + (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[4].horario).split(':')[1]
                                            + "; " + 

                                            (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[5].horario).split(':')[0] + ":" + (listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[5].horario).split(':')[1]
            
                                            listaProgramacionMedicionBusqueda[i].NumLecturas = 6;
            
                                            listaProgramacionMedicionBusqueda[i].horarioLectura5 = moment(listaProgramacionMedicionBusqueda[i].programacionHorariosByIdProgramacionConsumo[5].horario, 'HH:mm:ss');
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if(listaProgramacionMedicionBusqueda[i].variablesMedicionByIdProgramacionConsumo.length > 0){     
                        listaProgramacionMedicionBusqueda[i].variablesMedicionTabla = '';
                        listaProgramacionMedicionBusqueda[i].variablesMedicion = [];
                        listaProgramacionMedicionBusqueda[i].idVariablesMedicion = [];       
                        for(let j = 0; j < listaProgramacionMedicionBusqueda[i].variablesMedicionByIdProgramacionConsumo.length; j ++){
                            listaProgramacionMedicionBusqueda[i].variablesMedicionTabla += '-' + listaProgramacionMedicionBusqueda[i].variablesMedicionByIdProgramacionConsumo[j].nombre + ' ';

                            listaProgramacionMedicionBusqueda[i].variablesMedicion.push(listaProgramacionMedicionBusqueda[i].variablesMedicionByIdProgramacionConsumo[j].nombre); 
                            
                            listaProgramacionMedicionBusqueda[i].idVariablesMedicion.push(listaProgramacionMedicionBusqueda[i].variablesMedicionByIdProgramacionConsumo[j].idVariableMedicion);  
                        }
                    } else {
                        listaProgramacionMedicionBusqueda[i].variablesMedicion = [];
                        listaProgramacionMedicionBusqueda[i].idVariablesMedicion = [];
                        listaProgramacionMedicionBusqueda[i].variablesMedicionTabla = 'No tiene variables asignadas'
                    }
                }

                console.log('listaProgramacionMedicionBusqueda', listaProgramacionMedicionBusqueda)
               
                yield put({ type: serviciosPublicosActions.LISTA_PROGRAMACION_MEDICION_BUSQUEDA_SUCCESS, listaProgramacionMedicionBusqueda, listaProgramacionMedicionCantidad });
            }
            else {
                yield put({ type: serviciosPublicosActions.LISTA_PROGRAMACION_MEDICION_BUSQUEDA_FAILURE });
            }
 
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.LISTA_PROGRAMACION_MEDICION_BUSQUEDA_FAILURE });
    }
}
export function* listaProgramacionMedicionSaga() {
     yield takeLatest(serviciosPublicosActions.LISTA_PROGRAMACION_MEDICION_BUSQUEDA_REQUEST, listaProgramacionMedicion);
}

 // Cambiar estado
 function* estadoProgramacionConsumo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idProgramacionConsumo = action.idProgramacionConsumo;

        const response = yield call(estadoProgramacionConsumoCall, token, idProgramacionConsumo);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const busqueda = action.busqueda;
            const showSuccessMsg = true;
            const textMessage = "El estado de la programación y medición de consumo se cambió de forma correcta";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPublicosActions.ESTADO_PROGRAMACION_MEDICION_SUCCESS });
            yield put({ type: serviciosPublicosActions.LISTA_PROGRAMACION_MEDICION_BUSQUEDA_REQUEST, page, busqueda });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: serviciosPublicosActions.ESTADO_PROGRAMACION_MEDICION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.ESTADO_PROGRAMACION_MEDICION_FAILURE });
    }
}
export function* estadoProgramacionConsumoSaga() {
     yield takeLatest(serviciosPublicosActions.ESTADO_PROGRAMACION_MEDICION_REQUEST, estadoProgramacionConsumo);
}

// Crear Programación y Medición de Consumos
function* crearProgramacionMedicion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let idsVariablesMedicion = [];

        if(!action.todasVariables){
            for(let j = 0; j < action.formulario.idVariableMedicion.length; j ++){
                idsVariablesMedicion.push({"idVariableMedicion": parseInt(action.formulario.idVariableMedicion[j])}); 
            }
        } else {
            for(let j = 0; j < action.listaVariablesMedicionActivos.length; j ++){
                idsVariablesMedicion.push({"idVariableMedicion": parseInt(action.listaVariablesMedicionActivos[j].idVariableMedicion)}); 
            }
        }

        const data = {
            "idServicioPublico": parseInt(action.formulario.idServicioPublico),
            "descripcion": action.formulario.descripcion,
            "programacionHorariosByIdProgramacionConsumo": action.horario,
            "variablesMedicionByIdProgramacionConsumo": idsVariablesMedicion,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "activo": true,
            "vencimientoMinutos": parseInt(action.formulario.vencimientoMinutos)
        }

        console.log('data', data)
        
        const response = yield call(crearProgramacionMedicionCall, token, data);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const showModalNuevaProgramacion = false;
            const showSuccessMsg = true;
            const textMessage = "La programación y medición de consumo fue agregada con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "crearProgramacion" });
            yield put({ type: serviciosPublicosActions.NUEVA_PROGRAMACION_MEDICION_SUCCESS });
            yield put({ type: serviciosPublicosActions.LISTA_PROGRAMACION_MEDICION_BUSQUEDA_REQUEST, page });
            yield put({ type: serviciosPublicosActions.SHOW_MODAL_NUEVA_PROGRAMACION_MEDICION, showModalNuevaProgramacion });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });

            yield put({ type: serviciosPublicosActions.NUEVA_PROGRAMACION_MEDICION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.NUEVA_PROGRAMACION_MEDICION_FAILURE });
    }
}
export function* crearProgramacionMedicionSaga() {
yield takeLatest(serviciosPublicosActions.NUEVA_PROGRAMACION_MEDICION_REQUEST, crearProgramacionMedicion);
}

//Editar Programación y Medición de Consumo
function* editarProgramacionMedicion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idProgramacionConsumo = action.programacionSeleccionada.idProgramacionConsumo;

        let idServicioPublico = null;
        for(let i = 0; i < action.listaServiciosPublicosActivos.length; i++){
            if(action.listaServiciosPublicosActivos[i].nombre === action.formulario.servicioPublico){
                idServicioPublico = action.listaServiciosPublicosActivos[i].idServicioPublico
            }
        }

        let idsVariablesMedicion = [];
        
        if(!action.formulario.todasVariables){
            for(let i = 0; i < action.listaVariablesMedicionActivos.length; i ++){
                for(let j = 0; j < action.formulario.variablesMedicion.length; j ++){
                    if(action.listaVariablesMedicionActivos[i].nombre === action.formulario.variablesMedicion[j]){
                        idsVariablesMedicion.push({"idVariableMedicion": parseInt(action.listaVariablesMedicionActivos[i].idVariableMedicion)}); 
                    }
                }
            }
        } else {
            for(let j = 0; j < action.listaVariablesMedicionActivos.length; j ++){
                idsVariablesMedicion.push({"idVariableMedicion": parseInt(action.listaVariablesMedicionActivos[j].idVariableMedicion)}); 
            }
        }

        console.log('action.formulario', action.formulario)

        let horario = [];
            if(action.formulario.NumLecturas === 6){
                console.log('Entra if validacion 6')
                horario = [
                    {"horario" : action.formulario.horarioLectura0.format('HH:mm:ss')}, 
                    {"horario" : action.formulario.horarioLectura1.format('HH:mm:ss')},
                    {"horario" : action.formulario.horarioLectura2.format('HH:mm:ss')}, 
                    {"horario" : action.formulario.horarioLectura3.format('HH:mm:ss')}, 
                    {"horario" : action.formulario.horarioLectura4.format('HH:mm:ss')}, 
                    {"horario" : action.formulario.horarioLectura5.format('HH:mm:ss')}
                ];
            } else if(action.formulario.NumLecturas === 5){
                horario = [{"horario" : action.formulario.horarioLectura0.format('HH:mm:ss')}, {"horario" : action.formulario.horarioLectura1.format('HH:mm:ss')},{"horario" : action.formulario.horarioLectura2.format('HH:mm:ss')}, {"horario" : action.formulario.horarioLectura3.format('HH:mm:ss')}, {"horario" : action.formulario.horarioLectura4.format('HH:mm:ss')}];
            } else if(action.formulario.NumLecturas === 4){
                horario = [{"horario" : action.formulario.horarioLectura0.format('HH:mm:ss')}, {"horario" : action.formulario.horarioLectura1.format('HH:mm:ss')},{"horario" : action.formulario.horarioLectura2.format('HH:mm:ss')}, {"horario" : action.formulario.horarioLectura3.format('HH:mm:ss')}];
            } else if(action.formulario.NumLecturas === 3){
                horario = [{"horario" : action.formulario.horarioLectura0.format('HH:mm:ss')}, {"horario" : action.formulario.horarioLectura1.format('HH:mm:ss')},{"horario" : action.formulario.horarioLectura2.format('HH:mm:ss')}];
            } else if(action.formulario.NumLecturas === 2){
                horario = [{"horario" : action.formulario.horarioLectura0.format('HH:mm:ss')}, {"horario" : action.formulario.horarioLectura1.format('HH:mm:ss')}];
            } else if(action.formulario.NumLecturas === 1){
                horario = [{"horario" : action.formulario.horarioLectura0.format('HH:mm:ss')}];
            }

            console.log('horario', horario)

        const data = {
            "idProgramacionConsumo": idProgramacionConsumo,
            "idServicioPublico": idServicioPublico,
            "descripcion": action.formulario.descripcion,
            "programacionHorariosByIdProgramacionConsumo": horario,
            "variablesMedicionByIdProgramacionConsumo": idsVariablesMedicion,
            "fechaAlta": null,
            "fechaActualizacion": null,
            "vencimientoMinutos": parseInt(action.formulario.vencimientoMinutos)
        }

        console.log('data', data)

        const response = yield call(editarProgramacionMedicionCall, token, data, idProgramacionConsumo);
        console.log(response);
 
        if (response.status == 200) {
            const page = action.page;
            const showModalEditarProgramacion = false;
            const programacionSeleccionada = null;
            const limpiarFormulario = "editarProgramacion";
            const showSuccessMsg = true;
            const textMessage = "La programación y medición de consumo fue actualizada con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario });
            yield put({ type: serviciosPublicosActions.EDITAR_PROGRAMACION_MEDICION_SUCCESS });
            yield put({ type: serviciosPublicosActions.LISTA_PROGRAMACION_MEDICION_BUSQUEDA_REQUEST, page });
            yield put({ type: serviciosPublicosActions.SHOW_MODAL_EDITAR_PROGRAMACION_MEDICION, showModalEditarProgramacion, programacionSeleccionada });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPublicosActions.EDITAR_PROGRAMACION_MEDICION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.EDITAR_PROGRAMACION_MEDICION_FAILURE });
    }
}
export function* editarProgramacionMedicionSaga() {
    yield takeLatest(serviciosPublicosActions.EDITAR_PROGRAMACION_MEDICION_REQUEST, editarProgramacionMedicion);
}

// Reporte de Lectura
// Lista de Depósitos por idServicioPublico y idProgramacionHorario
function* listaDepositosActivos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let idServicioPublico = parseInt(action.idServicioPublico);
        let idProgramacionHorario = parseInt(action.idProgramacionHorario);

        const response = yield call(listaDepositosActivosCall, token, idServicioPublico, idProgramacionHorario);
        console.log(response)
        if (response.status == 200) {
            const listaDepositosByIdServiciosPublicos = response.data;
            let listaInitialValueDepos = {};

            for(let i = 0; i < listaDepositosByIdServiciosPublicos.length; i++){
                if(listaDepositosByIdServiciosPublicos[i].servicioPublicoByIdServicioPublico){
                    listaDepositosByIdServiciosPublicos[i].nombreDeposito = listaDepositosByIdServiciosPublicos[i].servicioPublicoByIdServicioPublico.nombreDeposito;
                }

                if(listaDepositosByIdServiciosPublicos[i].unidadMedidaByIdUnidadMedida){
                    listaDepositosByIdServiciosPublicos[i].unidadMedida = listaDepositosByIdServiciosPublicos[i].unidadMedidaByIdUnidadMedida.unidadMedida;
                }

                if(listaDepositosByIdServiciosPublicos[i].ubicacionByIdUbicacion){
                    listaDepositosByIdServiciosPublicos[i].claveUbicacion = listaDepositosByIdServiciosPublicos[i].ubicacionByIdUbicacion.claveUbicacion;
                } else {
                    listaDepositosByIdServiciosPublicos[i].claveUbicacion = 'No cuenta con ubicación'
                }

                if(listaDepositosByIdServiciosPublicos[i].servicioPublicoByIdServicioPublico){
                    listaDepositosByIdServiciosPublicos[i].nombre = listaDepositosByIdServiciosPublicos[i].servicioPublicoByIdServicioPublico.nombre;
                }

                if(listaDepositosByIdServiciosPublicos[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional){
                    listaDepositosByIdServiciosPublicos[i].nombreUbicacion = listaDepositosByIdServiciosPublicos[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;

                    if(listaDepositosByIdServiciosPublicos[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional){
                        listaDepositosByIdServiciosPublicos[i].nombreUbicacion = listaDepositosByIdServiciosPublicos[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional + ', ' +listaDepositosByIdServiciosPublicos[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;

                        if(listaDepositosByIdServiciosPublicos[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional){
                            listaDepositosByIdServiciosPublicos[i].nombreUbicacion = listaDepositosByIdServiciosPublicos[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional + ', ' +listaDepositosByIdServiciosPublicos[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional + ', ' + listaDepositosByIdServiciosPublicos[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional;

                            if(listaDepositosByIdServiciosPublicos[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional){
                                listaDepositosByIdServiciosPublicos[i].nombreUbicacion = listaDepositosByIdServiciosPublicos[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional + ', ' +listaDepositosByIdServiciosPublicos[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional + ', ' + listaDepositosByIdServiciosPublicos[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional + ', ' + listaDepositosByIdServiciosPublicos[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                            }
                        }
                    }
                }

                if(listaDepositosByIdServiciosPublicos[i].variableMedicionByIdVariableMedicion){
                    listaDepositosByIdServiciosPublicos[i].idVariableMedicion = listaDepositosByIdServiciosPublicos[i].variableMedicionByIdVariableMedicion.idVariableMedicion
                }

                if(listaDepositosByIdServiciosPublicos[i].lecturaRegistrada){

                    listaInitialValueDepos[`lecturaRegistrada-${listaDepositosByIdServiciosPublicos[i].idAsignacionServicioPublico}`] = listaDepositosByIdServiciosPublicos[i].lecturaRegistrada;

                    listaInitialValueDepos[`idUnidadMedida-${listaDepositosByIdServiciosPublicos[i].idAsignacionServicioPublico}`] = (listaDepositosByIdServiciosPublicos[i].idUnidadMedida);

                    listaInitialValueDepos[`unidadMedida-${listaDepositosByIdServiciosPublicos[i].idAsignacionServicioPublico}`] = (listaDepositosByIdServiciosPublicos[i].unidadMedida);
                    
                    listaInitialValueDepos[`idVariableMedicion-${listaDepositosByIdServiciosPublicos[i].idAsignacionServicioPublico}`] = (listaDepositosByIdServiciosPublicos[i].variableMedicionByIdVariableMedicion.idVariableMedicion);

                    listaInitialValueDepos[`nombre-${listaDepositosByIdServiciosPublicos[i].idAsignacionServicioPublico}`] = (listaDepositosByIdServiciosPublicos[i].variableMedicionByIdVariableMedicion.nombre);
                }
            }

            yield put({ type: serviciosPublicosActions.LISTA_DEPOSITO_BY_SERVICIO_SUCCESS, listaDepositosByIdServiciosPublicos, listaInitialValueDepos, idProgramacionHorario });
        }
        else {
            yield put({ type: serviciosPublicosActions.LISTA_DEPOSITO_BY_SERVICIO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.LISTA_DEPOSITO_BY_SERVICIO_FAILURE });
    }
}
export function* listaDepositosActivosSaga() {
    yield takeLatest(serviciosPublicosActions.LISTA_DEPOSITO_BY_SERVICIO_REQUEST, listaDepositosActivos);
}

// Lista de Depósitos por idServicioPublico SIN idProgramacionHorario
function* listaDepositosActivosSinIdProgramacion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let idServicioPublico = parseInt(action.idServicioPublico);

        const response = yield call(listaDepositosActivosSinIdProgramacionCall, token, idServicioPublico);
        console.log(response);

        if (response.status == 200) {
            const listaDepositosByIdServiciosPublicosSinProgra = response.data;

            for(let i = 0; i < listaDepositosByIdServiciosPublicosSinProgra.length; i++){
                listaDepositosByIdServiciosPublicosSinProgra[i].posicion = i + 1;
                if(listaDepositosByIdServiciosPublicosSinProgra[i].servicioPublicoByIdServicioPublico){
                    listaDepositosByIdServiciosPublicosSinProgra[i].nombreDeposito = listaDepositosByIdServiciosPublicosSinProgra[i].servicioPublicoByIdServicioPublico.nombreDeposito;
                }

                if(listaDepositosByIdServiciosPublicosSinProgra[i].unidadMedidaByIdUnidadMedida){
                    listaDepositosByIdServiciosPublicosSinProgra[i].unidadMedida = listaDepositosByIdServiciosPublicosSinProgra[i].unidadMedidaByIdUnidadMedida.unidadMedida;
                }

                if(listaDepositosByIdServiciosPublicosSinProgra[i].ubicacionByIdUbicacion){
                    listaDepositosByIdServiciosPublicosSinProgra[i].claveUbicacion = listaDepositosByIdServiciosPublicosSinProgra[i].ubicacionByIdUbicacion.claveUbicacion;
                } else {
                    listaDepositosByIdServiciosPublicosSinProgra[i].claveUbicacion = 'No cuenta con ubicación'
                }

                if(listaDepositosByIdServiciosPublicosSinProgra[i].servicioPublicoByIdServicioPublico){
                    listaDepositosByIdServiciosPublicosSinProgra[i].nombre = listaDepositosByIdServiciosPublicosSinProgra[i].servicioPublicoByIdServicioPublico.nombre;
                }

                if(listaDepositosByIdServiciosPublicosSinProgra[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional){
                    listaDepositosByIdServiciosPublicosSinProgra[i].nombreUbicacion = listaDepositosByIdServiciosPublicosSinProgra[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;

                    if(listaDepositosByIdServiciosPublicosSinProgra[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional){
                        listaDepositosByIdServiciosPublicosSinProgra[i].nombreUbicacion = listaDepositosByIdServiciosPublicosSinProgra[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional + ', ' +listaDepositosByIdServiciosPublicosSinProgra[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;

                        if(listaDepositosByIdServiciosPublicosSinProgra[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional){
                            listaDepositosByIdServiciosPublicosSinProgra[i].nombreUbicacion = listaDepositosByIdServiciosPublicosSinProgra[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional + ', ' +listaDepositosByIdServiciosPublicosSinProgra[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional + ', ' + listaDepositosByIdServiciosPublicosSinProgra[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional;

                            if(listaDepositosByIdServiciosPublicosSinProgra[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional){
                                listaDepositosByIdServiciosPublicosSinProgra[i].nombreUbicacion = listaDepositosByIdServiciosPublicosSinProgra[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional + ', ' +listaDepositosByIdServiciosPublicosSinProgra[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional + ', ' + listaDepositosByIdServiciosPublicosSinProgra[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional + ', ' + listaDepositosByIdServiciosPublicosSinProgra[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                            }
                        }
                    }
                }
            }

            yield put({ type: serviciosPublicosActions.LISTA_DEPOSITO_BY_SERVICIO_SIN_PROGRA_SUCCESS, listaDepositosByIdServiciosPublicosSinProgra });
        }
        else {
            yield put({ type: serviciosPublicosActions.LISTA_DEPOSITO_BY_SERVICIO_SIN_PROGRA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.LISTA_DEPOSITO_BY_SERVICIO_SIN_PROGRA_FAILURE });
    }
}
export function* listaDepositosActivosSinIdProgramacionSaga() {
    yield takeLatest(serviciosPublicosActions.LISTA_DEPOSITO_BY_SERVICIO_SIN_PROGRA_REQUEST, listaDepositosActivosSinIdProgramacion);
}

// Registro Lecturas - presentacion
function* registroLecturasPresentacion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let idAsignacionServicioPublico = '';
        let idServicioPublico = '';
        let unidadMedida = '';
        let ubicacionDeposito = '';

        if (action.idAsignacionServicioPublico) {
            idAsignacionServicioPublico = '&idAsignacionServicioPublico=' + parseInt(action.idAsignacionServicioPublico);
        }

        if (action.idServicioPublico) {
            idServicioPublico = '&idServicioPublico=' + parseInt(action.idServicioPublico);
        }

        const response = yield call(registroLecturasPresentacionCall, token, idAsignacionServicioPublico, idServicioPublico, action.fechaInicio1, action.fechaFin1, action.fechaInicio2, action.fechaFin2);

        if (response.status == 200) {
            const response2 = yield call(listaDepositosActivosSinIdProgramacionCall, token, parseInt(action.servicioPublicoSeleccionado.idServicioPublico));
            console.log('response2', response2)
            
            if(response2.status == 200){
                let listaParaUnidad = response2.data;
                unidadMedida = listaParaUnidad[0].unidadMedidaByIdUnidadMedida.unidadMedida;

                for(let i = 0; i < listaParaUnidad.length; i++){
                    if(listaParaUnidad[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional){
                        listaParaUnidad[i].nombreUbicacion = listaParaUnidad[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;

                        if(listaParaUnidad[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional){
                            listaParaUnidad[i].nombreUbicacion = listaParaUnidad[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional + ', ' +listaParaUnidad[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;

                            if(listaParaUnidad[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional){
                                listaParaUnidad[i].nombreUbicacion = listaParaUnidad[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional + ', ' +listaParaUnidad[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional + ', ' + listaParaUnidad[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional;

                                if(listaParaUnidad[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional){
                                    listaParaUnidad[i].nombreUbicacion = listaParaUnidad[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional + ', ' +listaParaUnidad[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional + ', ' + listaParaUnidad[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional + ', ' + listaParaUnidad[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                                }
                            }
                        }
                    }
                }

                if (action.idAsignacionServicioPublico) {
                    for(let i = 0; i < listaParaUnidad.length; i++){
                        if(action.idAsignacionServicioPublico === listaParaUnidad[i].idAsignacionServicioPublico){
                            ubicacionDeposito = listaParaUnidad[i].nombreUbicacion;
                        }
                    }
                }
            }

            let registroLecturasPresentacion = response.data.data;
            let FI1 = action.fechaInicio1.split('-')[2] + '/' + action.fechaInicio1.split('-')[1] + '/' + action.fechaInicio1.split('-')[0];
            let FF1 = action.fechaFin1.split('-')[2] + '/' + action.fechaFin1.split('-')[1] + '/' + action.fechaFin1.split('-')[0];
            let FI2 = action.fechaInicio2.split('-')[2] + '/' + action.fechaInicio2.split('-')[1] + '/' + action.fechaInicio2.split('-')[0];
            let FF2 = action.fechaFin2.split('-')[2] + '/' + action.fechaFin2.split('-')[1] + '/' + action.fechaFin2.split('-')[0];
            let consumo1 = registroLecturasPresentacion[0].cantidad;
            let consumo2 = registroLecturasPresentacion[1].cantidad;

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            let chartData = {
                labels: ['Del ' + FI1  + ' al ' + FF1, 'Del ' + FI2  + ' al ' + FF2],
                datasets: [{
                    //label: ['Consumo en ' + unidadMedida],
                    data: [registroLecturasPresentacion[0].cantidad, registroLecturasPresentacion[1].cantidad],
                    backgroundColor: [
                        'rgb(248,189,93,1)',
                        'rgb(72,89,37,1)',
                    ],
                    borderColor: [
                    'rgb(248,189,93,0.4)',
                    'rgb(72,89,37,0.4)',
                    ],
                    borderWidth: 5
                }],
            }

            let optionsData = {
                scales: {
                        yAxes: [{
                            ticks: {
                                callback: function(value) {
                                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                },
                                beginAtZero: true,
                            },
                            scaleLabel: { 
                                display: true, 
                                labelString: ['Consumo en ' + unidadMedida] 
                            } 
                        }],
                },
                tooltips: {
                    callbacks: {
                        title: function(tooltipItem, data) {
                            return data['labels'][tooltipItem[0]['index']];
                        },
                        label: function(tooltipItem, data) {
                            let dataset = data.datasets[tooltipItem.datasetIndex];
                            
                            return 'Consumo: ' +  numberWithCommas(data['datasets'][0]['data'][tooltipItem['index']]);
                        }
                    },
                    backgroundColor: '#FFF', titleFontSize: 14, titleFontColor: '#1747ca', bodyFontColor: '#0e2c82', bodyFontSize: 12, displayColors: false
               },
                hover: {
                    animationDuration: 7
                },
                legend: {
                    display: false
                },
            }

            let data = [{
                key: '1',
                p1: [FI1] + ' al ' + [FF1],
                p2: [FI2] + ' al ' + [FF2],
                datoGraficado: [action.datoGraficado],
                cp1: [consumo1].toLocaleString('en'),
                cp2: [consumo2].toLocaleString('en'),
                ubidepo: [ubicacionDeposito]
            }];

            yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_PRESENTACION_SUCCESS, registroLecturasPresentacion, chartData, optionsData, consumo1, consumo2, data, fetchingRegistroLecturasPresentacion: true });
        }
        else {
            yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_PRESENTACION_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_PRESENTACION_FAILURE });
    }
}
export function* registroLecturasPresentacionSaga() {
    yield takeLatest(serviciosPublicosActions.REGISTRO_LECTURA_PRESENTACION_REQUEST, registroLecturasPresentacion);
}

function* registroLecturasPresentacionDisponibilidad(action) {
    try {
        console.log("action registro lecturas disponibilidar",action);
        const token = sessionStorage.getItem('token');
        const idAsignacionServicioPublico = action.idAsignacionServicioPublico ? 'idAsignacionServicioPublico=' + action.idAsignacionServicioPublico : '';
        const idServicioPublico = action.idServicioPublico ? '&idServicioPublico=' + action.idServicioPublico : '';
        const idUbicacion = action.idUbicacion ? '&idUbicacion=' + action.idUbicacion : '';
        const fechaInicio = action.fechaInicio ? '&fechaInicio=' + action.fechaInicio : '';
        const fechaFin = action.fechaFin ? '&fechaFin=' + action.fechaFin : '';

        console.log("lo que mando", idAsignacionServicioPublico, idServicioPublico, idUbicacion, fechaInicio, fechaFin)
        const response = yield call(registroLecturasPresentacionDisponibilidadCall, token, idAsignacionServicioPublico, idServicioPublico, idUbicacion, fechaInicio, fechaFin);
        console.log("response registro lecturas disponibilidar",response)

        if (response.status == 200) {
            const response2 = yield call(listaDepositosActivosSinIdProgramacionCall, token, parseInt(action.idServicioPublico));
            console.log("response 2 registro lecturas disponibilidar",response2)
            let unidadMedidaDispo = '';
            if(response2.status == 200){
                if(response2.data.length > 0){
                    unidadMedidaDispo = response2.data[0].unidadMedidaByIdUnidadMedida.unidadMedida;
                }
            }

            let registroLecturaPresentacionDispo = response.data.data;
            let etiquetasPresentacionDispo = [];
            let dataPresentacionDispo = [];
            let dataDescriptionDispo = [];

            if(response.data.cantidad > 0){
                for(let i in registroLecturaPresentacionDispo){
                    etiquetasPresentacionDispo.push(registroLecturaPresentacionDispo[i].etiqueta);
                    dataPresentacionDispo.push(registroLecturaPresentacionDispo[i].cantidad);
                    dataDescriptionDispo.push({ label: registroLecturaPresentacionDispo[i].etiqueta, data: registroLecturaPresentacionDispo[i].cantidad, id: i });
                }
            }

            console.log("lo que hay en la gráfica", etiquetasPresentacionDispo, dataPresentacionDispo, dataDescriptionDispo)

            yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_PRESENTACION_DISPONIBILIDAD_SUCCESS, registroLecturaPresentacionDispo, etiquetasPresentacionDispo, dataPresentacionDispo, unidadMedidaDispo, dataDescriptionDispo });
        }
        else {
            yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_PRESENTACION_DISPONIBILIDAD_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_PRESENTACION_DISPONIBILIDAD_FAILURE });
    }
}
export function* registroLecturasPresentacionDisponibilidadSaga() {
    yield takeLatest(serviciosPublicosActions.REGISTRO_LECTURA_PRESENTACION_DISPONIBILIDAD_REQUEST, registroLecturasPresentacionDisponibilidad);
}


function* registroLecturasPresentacionConsumo(action) {
    try {
        console.log("action registro lecturas",action);
        const token = sessionStorage.getItem('token');
        const idAsignacionServicioPublico = action.idAsignacionServicioPublico ? 'idAsignacionServicioPublico=' + action.idAsignacionServicioPublico : '';
        const idServicioPublico = action.idServicioPublico ? '&idServicioPublico=' + action.idServicioPublico : '';
        const idUbicacion = action.idUbicacion ? '&idUbicacion=' + action.idUbicacion : '';
        const fechaInicio = action.fechaInicio ? '&fechaInicio=' + action.fechaInicio : '';
        const fechaFin = action.fechaFin ? '&fechaFin=' + action.fechaFin : '';

        console.log("lo que mando", idAsignacionServicioPublico, idServicioPublico, idUbicacion, fechaInicio, fechaFin)

        const response = yield call(registroLecturasPresentacionConsumoCall, token, idAsignacionServicioPublico, idServicioPublico, idUbicacion, fechaInicio, fechaFin);
        console.log("response registro lecturas",response)

        if (response.status == 200) {
            const response2 = yield call(listaDepositosActivosSinIdProgramacionCall, token, parseInt(action.idServicioPublico));
            let unidadMedidaCons = '';
            if(response2.status == 200){
                if(response2.data.length > 0){
                    unidadMedidaCons = response2.data[0].unidadMedidaByIdUnidadMedida.unidadMedida;
                }
            }

            let registroLecturaPresentacionCons = response.data.data;
            let etiquetasPresentacionCons = [];
            let dataPresentacionCons = [];
            const dataDescriptionCons = [];

            if(response.data.cantidad > 0){
                for(let i in registroLecturaPresentacionCons){
                    etiquetasPresentacionCons.push(registroLecturaPresentacionCons[i].etiqueta);
                    dataPresentacionCons.push(registroLecturaPresentacionCons[i].cantidad);
                    dataDescriptionCons.push({ label: registroLecturaPresentacionCons[i].etiqueta, data: registroLecturaPresentacionCons[i].cantidad, id: i });
                }
            }

            console.log("lo que hay en la otra gráfica", etiquetasPresentacionCons,dataPresentacionCons, dataDescriptionCons)


            yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_PRESENTACION_CONSUMO_SUCCESS, registroLecturaPresentacionCons, etiquetasPresentacionCons, dataPresentacionCons, unidadMedidaCons, dataDescriptionCons });
        }
        else {
            yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_PRESENTACION_CONSUMO_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_PRESENTACION_CONSUMO_FAILURE });
    }
}
export function* registroLecturasPresentacionConsumoSaga() {
    yield takeLatest(serviciosPublicosActions.REGISTRO_LECTURA_PRESENTACION_CONSUMO_REQUEST, registroLecturasPresentacionConsumo);
}

// Registro de Lectura - excel
function* registroLecturaReporte(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        let idAsignacionServicioPublico = '';
        let idServicioPublico = '';

        if (action.idAsignacionServicioPublico) {
            idAsignacionServicioPublico = '&idAsignacionServicioPublico=' + parseInt(action.idAsignacionServicioPublico);
        }

        if (action.idServicioPublico) {
            idServicioPublico = '&idServicioPublico=' + parseInt(action.idServicioPublico);
        }

        let response = yield call(registroLecturasReporteCall, token, idAsignacionServicioPublico, idServicioPublico, action.fechaInicio1, action.fechaFin1, action.fechaInicio2, action.fechaFin2);

        console.log('response', response)

        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "RegistroLecturaReporte " + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            
            yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_EXCEL_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el documento.";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_EXCEL_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_EXCEL_FAILURE });
    }
}
export function* registroLecturaReporteSaga() {
    yield takeLatest(serviciosPublicosActions.REGISTRO_LECTURA_EXCEL_REQUEST, registroLecturaReporte);
}

function* listaUbicacionesByIdServicioPublico(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServicioPublico = parseInt(action.idServicioPublico);

        const response = yield call(listaUbicacionesByIdServicioPublicoCall, token, idServicioPublico);
        console.log(response);

        if (response.status == 200) {
            let listaUbicacionesByIdServicioPublico = response.data;

            for(let i in listaUbicacionesByIdServicioPublico){

                if(listaUbicacionesByIdServicioPublico[i].areaFuncionalByIdAreaFuncional){
                    listaUbicacionesByIdServicioPublico[i].nombreUbicacion = listaUbicacionesByIdServicioPublico[i].areaFuncionalByIdAreaFuncional.areaFuncional;

                    if(listaUbicacionesByIdServicioPublico[i].unidadFuncionalByIdUnidadFuncional){
                        listaUbicacionesByIdServicioPublico[i].nombreUbicacion = listaUbicacionesByIdServicioPublico[i].areaFuncionalByIdAreaFuncional.areaFuncional + ', ' +listaUbicacionesByIdServicioPublico[i].unidadFuncionalByIdUnidadFuncional.unidadFuncional;

                        if(listaUbicacionesByIdServicioPublico[i].interiorFuncionalByIdInteriorFuncional){
                            listaUbicacionesByIdServicioPublico[i].nombreUbicacion = listaUbicacionesByIdServicioPublico[i].areaFuncionalByIdAreaFuncional.areaFuncional + ', ' +listaUbicacionesByIdServicioPublico[i].unidadFuncionalByIdUnidadFuncional.unidadFuncional + ', ' + listaUbicacionesByIdServicioPublico[i].interiorFuncionalByIdInteriorFuncional.interiorFuncional;

                            if(listaUbicacionesByIdServicioPublico[i].subInteriorFuncionalByIdSubInteriorFuncional){
                                listaUbicacionesByIdServicioPublico[i].nombreUbicacion = listaUbicacionesByIdServicioPublico[i].areaFuncionalByIdAreaFuncional.areaFuncional + ', ' +listaUbicacionesByIdServicioPublico[i].unidadFuncionalByIdUnidadFuncional.unidadFuncional + ', ' + listaUbicacionesByIdServicioPublico[i].interiorFuncionalByIdInteriorFuncional.interiorFuncional + ', ' + listaUbicacionesByIdServicioPublico[i].subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                            }
                        }
                    }
                }
            }

            yield put({ type: serviciosPublicosActions.LISTA_UBICACIONES_BY_IDSERVICIOPUBLICO_SUCCESS, listaUbicacionesByIdServicioPublico });
        }
        else {
            yield put({ type: serviciosPublicosActions.LISTA_UBICACIONES_BY_IDSERVICIOPUBLICO_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.LISTA_UBICACIONES_BY_IDSERVICIOPUBLICO_FAILURE });
    }
}
export function* listaUbicacionesByIdServicioPublicoSaga() {
    yield takeLatest(serviciosPublicosActions.LISTA_UBICACIONES_BY_IDSERVICIOPUBLICO_REQUEST, listaUbicacionesByIdServicioPublico);
}

// Programacion Consumos - por Servicio Publico
function* programacionByServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        
        let idServicioPublico = parseInt(action.idServicioPublico);

        const response = yield call(programacionByServicioCall, token, idServicioPublico);
        console.log(response);

        if (response.status == 200) {
            let programacionByServicio = response.data;
            let programacionHorariosByIdProgramacionConsumo = [];

            if(programacionByServicio[0].programacionHorariosByIdProgramacionConsumo){
                programacionByServicio[0].horarios = [];
                for(let i = 0; i < programacionByServicio[0].programacionHorariosByIdProgramacionConsumo.length; i++){
                    if(programacionByServicio[0].programacionHorariosByIdProgramacionConsumo[i].activo){
                        programacionByServicio[0].horarios.push(programacionByServicio[0].programacionHorariosByIdProgramacionConsumo[i].horario.split(':')[0] + ':' + programacionByServicio[0].programacionHorariosByIdProgramacionConsumo[i].horario.split(':')[1]); 
                    }
                }
            }

            for(let i = 0; i < programacionByServicio[0].programacionHorariosByIdProgramacionConsumo.length; i++){
                if(programacionByServicio[0].programacionHorariosByIdProgramacionConsumo[i].activo){
                    programacionHorariosByIdProgramacionConsumo[i] = programacionByServicio[0].programacionHorariosByIdProgramacionConsumo[i];

                    programacionHorariosByIdProgramacionConsumo[i].horarioSelect = programacionHorariosByIdProgramacionConsumo[i].horario.split(':')[0] + ':' + programacionHorariosByIdProgramacionConsumo[i].horario.split(':')[1]
                }
            }

            yield put({ type: serviciosPublicosActions.PROGRAMACION_POR_SERVICIO_SUCCESS, programacionByServicio, programacionHorariosByIdProgramacionConsumo });
        }
        else {
            yield put({ type: serviciosPublicosActions.PROGRAMACION_POR_SERVICIO_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.PROGRAMACION_POR_SERVICIO_FAILURE });
    }
}
export function* programacionByServicioSaga() {
    yield takeLatest(serviciosPublicosActions.PROGRAMACION_POR_SERVICIO_REQUEST, programacionByServicio);
}

function* registroLecturaPDF(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idServicioPublico = action.idServicioPublico ? 'idServicioPublico=' + action.idServicioPublico : '';
        const idAsignacionServicioPublico = action.idAsignacionServicioPublico ? '&idAsignacionServicioPublico=' + action.idAsignacionServicioPublico : '';
        const fechaInicio1 = action.fechaInicio1 ? '&fechaInicio1=' + action.fechaInicio1 : '';
        const fechaInicio2 = action.fechaInicio2 ? '&fechaInicio2=' + action.fechaInicio2 : '';
        const fechaFin1 = action.fechaFin1 ? '&fechaFin1=' + action.fechaFin1 : '';
        const fechaFin2 = action.fechaFin2 ? '&fechaFin2=' + action.fechaFin2 : '';
        
        const response = yield call(registroLecturaPDFCall, token, idServicioPublico, idAsignacionServicioPublico, fechaInicio1, fechaInicio2, fechaFin1, fechaFin2)
        console.log(response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se descargó el archivo de forma exitosa";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "RegistroLecturaReporte"  + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPublicosActions.REGISTRO_LECTURAS_PDF_SUCCESS });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPublicosActions.REGISTRO_LECTURAS_PDF_FAILURE })
        }
    } catch (error) {
        yield put({ type: serviciosPublicosActions.REGISTRO_LECTURAS_PDF_FAILURE})
    }
}
export function* registroLecturaPDFSaga(){
    yield takeLatest(serviciosPublicosActions.REGISTRO_LECTURAS_PDF_REQUEST, registroLecturaPDF)
}


//Consumo y disponibilidad pdf / excel

function* registroLecturaDisponibilidadExcel(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServicioPublico = action.idServicioPublico ? 'idServicioPublico=' + action.idServicioPublico : '';
        const idAsignacionServicioPublico = action.idAsignacionServicioPublico ? '&idAsignacionServicioPublico=' + action.idAsignacionServicioPublico : '';
        const fechaInicio = action.fechaInicio ? '&fechaInicio=' + action.fechaInicio : '';
        const fechaFin = action.fechaFin ? '&fechaFin=' + action.fechaFin : '';
        const idUbicacion = action.idUbicacion ? '&idUbicacion=' + action.idUbicacion : '';
        const tipo = '&tipo=Disponibilidad'
        let response = yield call(registroLecturaDisponibilidadYConsumoExcelCall, token, idServicioPublico, idAsignacionServicioPublico, fechaInicio, fechaFin, tipo, idUbicacion);
        console.log('response excel', response)

        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "RegistroLecturaReporteDisponibilidad " + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            
            yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_DISPONIBILIDAD_EXCEL_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el documento.";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_DISPONIBILIDAD_EXCEL_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_DISPONIBILIDAD_EXCEL_FAILURE });
    }
}
export function* registroLecturaDisponibilidadExcelSaga() {
    yield takeLatest(serviciosPublicosActions.REGISTRO_LECTURA_DISPONIBILIDAD_EXCEL_REQUEST, registroLecturaDisponibilidadExcel);
}

function* registroLecturaDisponibilidadPDF(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idServicioPublico = action.idServicioPublico ? 'idServicioPublico=' + action.idServicioPublico : '';
        const idAsignacionServicioPublico = action.idAsignacionServicioPublico ? '&idAsignacionServicioPublico=' + action.idAsignacionServicioPublico : '';
        const fechaInicio = action.fechaInicio ? '&fechaInicio=' + action.fechaInicio : '';
        const fechaFin = action.fechaFin ? '&fechaFin=' + action.fechaFin : '';
        const idUbicacion = action.idUbicacion ? '&idUbicacion=' + action.idUbicacion : '';
        const tipo = '&tipo=Disponibilidad'
        
        const response = yield call(registroLecturaDisponibilidadYConsumoPDFCall, token, idServicioPublico, idAsignacionServicioPublico, fechaInicio, fechaFin, tipo, idUbicacion)
        console.log("response pdf",response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se descargó el archivo de forma exitosa";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "RegistroLecturaReporteDisponibilidad"  + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_DISPONIBILIDAD_PDF_SUCCESS });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_DISPONIBILIDAD_PDF_FAILURE })
        }
    } catch (error) {
        yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_DISPONIBILIDAD_PDF_FAILURE})
    }
}
export function* registroLecturaDisponibilidadPDFSaga(){
    yield takeLatest(serviciosPublicosActions.REGISTRO_LECTURA_DISPONIBILIDAD_PDF_REQUEST, registroLecturaDisponibilidadPDF)
}

function* registroLecturaConsumoExcel(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServicioPublico = action.idServicioPublico ? 'idServicioPublico=' + action.idServicioPublico : '';
        const idAsignacionServicioPublico = action.idAsignacionServicioPublico ? '&idAsignacionServicioPublico=' + action.idAsignacionServicioPublico : '';
        const fechaInicio = action.fechaInicio ? '&fechaInicio=' + action.fechaInicio : '';
        const fechaFin = action.fechaFin ? '&fechaFin=' + action.fechaFin : '';
        const idUbicacion = action.idUbicacion ? '&idUbicacion=' + action.idUbicacion : '';
        const tipo = '&tipo=Consumo'
        let response = yield call(registroLecturaDisponibilidadYConsumoExcelCall, token, idServicioPublico, idAsignacionServicioPublico, fechaInicio, fechaFin, tipo, idUbicacion);
        console.log('response excel', response)

        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "RegistroLecturaReporteConsumo " + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            
            yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_CONSUMO_EXCEL_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = "No fue posible exportar el documento.";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_CONSUMO_EXCEL_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_CONSUMO_EXCEL_FAILURE });
    }
}
export function* registroLecturaConsumoExcelSaga() {
    yield takeLatest(serviciosPublicosActions.REGISTRO_LECTURA_CONSUMO_EXCEL_REQUEST, registroLecturaConsumoExcel);
}

function* registroLecturaConsumoPDF(action){
    try {
        console.log(action)
        const token = sessionStorage.getItem('token');
        const idServicioPublico = action.idServicioPublico ? 'idServicioPublico=' + action.idServicioPublico : '';
        const idAsignacionServicioPublico = action.idAsignacionServicioPublico ? '&idAsignacionServicioPublico=' + action.idAsignacionServicioPublico : '';
        const fechaInicio = action.fechaInicio ? '&fechaInicio=' + action.fechaInicio : '';
        const fechaFin = action.fechaFin ? '&fechaFin=' + action.fechaFin : '';
        const idUbicacion = action.idUbicacion ? '&idUbicacion=' + action.idUbicacion : '';
        const tipo = '&tipo=Consumo'
        
        const response = yield call(registroLecturaDisponibilidadYConsumoPDFCall, token, idServicioPublico, idAsignacionServicioPublico, fechaInicio, fechaFin, tipo, idUbicacion)
        console.log("response pdf",response);

        if (response.status === 200) {
            const showSuccessMsg = true;
            const textMessage = "Se descargó el archivo de forma exitosa";

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "RegistroLecturaReporteConsumo"  + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_CONSUMO_PDF_SUCCESS });
        } else {
            const showErrorMsg = true;
            const textMessage = response.message;
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_CONSUMO_PDF_FAILURE })
        }
    } catch (error) {
        yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_CONSUMO_PDF_FAILURE})
    }
}
export function* registroLecturaConsumoPDFSaga(){
    yield takeLatest(serviciosPublicosActions.REGISTRO_LECTURA_CONSUMO_PDF_REQUEST, registroLecturaConsumoPDF)
}

// Registro Lecturas - Horarios Capturables por Servicio Publico
function* horariosCapturablesByServicio(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        
        let idServicioPublico = parseInt(action.idServicioPublico);

        const response = yield call(horariosCapturablesByServicioCall, token, idServicioPublico);

        if (response.status == 200) {
            let horariosByServicio = response.data;

            for(let i = 0; i < horariosByServicio.length; i++){
                if(horariosByServicio[i].activo){
                    horariosByServicio[i].horarioSelect = horariosByServicio[i].horario.split(':')[0] + ':' + horariosByServicio[i].horario.split(':')[1]
                }
            }

            if(!horariosByServicio[0]){
                message.info('Los horarios para éste servicio público no se encuentran disponibles.',4)
                // .then(() => message.info('Puede que el horario anterior ya no se encuentra vigente', 3.5))
                // .then(() => message.info('o aún no se encuentra dentro de un periodo capturable.', 3.5));
            }

            yield put({ type: serviciosPublicosActions.HORARIOS_BY_SERVICIOS_SUCCESS, horariosByServicio });
        }
        else {
            yield put({ type: serviciosPublicosActions.HORARIOS_BY_SERVICIOS_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.HORARIOS_BY_SERVICIOS_FAILURE });
    }
}
export function* horariosCapturablesByServicioSaga() {
    yield takeLatest(serviciosPublicosActions.HORARIOS_BY_SERVICIOS_REQUEST, horariosCapturablesByServicio);
}

// Registro Lecturas - Horarios Capturables por Servicio Publico (admin)
function* horariosCapturablesByServicioAdmin(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        
        let idServicioPublico = parseInt(action.idServicioPublico);

        const response = yield call(horariosCapturablesByServicioAdminCall, token, idServicioPublico);

        if (response.status == 200) {
            let horariosByServicioAdmin = response.data;

            for(let i = 0; i < horariosByServicioAdmin.length; i++){
                if(horariosByServicioAdmin[i].activo){
                    horariosByServicioAdmin[i].horarioSelect = horariosByServicioAdmin[i].horario.split(':')[0] + ':' + horariosByServicioAdmin[i].horario.split(':')[1]
                }
            }

            yield put({ type: serviciosPublicosActions.HORARIOS_BY_SERVICIOS_ADMIN_SUCCESS, horariosByServicioAdmin });
        }
        else {
            yield put({ type: serviciosPublicosActions.HORARIOS_BY_SERVICIOS_ADMIN_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.HORARIOS_BY_SERVICIOS_ADMIN_FAILURE });
    }
}
export function* horariosCapturablesByServicioAdminSaga() {
    yield takeLatest(serviciosPublicosActions.HORARIOS_BY_SERVICIOS_ADMIN_REQUEST, horariosCapturablesByServicioAdmin);
}


function* listaConsumosAsignacionByIdServicioPublico(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServicioPublico =  action.idServicioPublico;

        const response = yield call(listaConsumosAsignacionByIdServicioPublicoACall, token, idServicioPublico);
        console.log(response);

        if (response.status == 200) {
            const listaAsignacionCapacidadByIdServicioPublico = response.data.data;

            for(let i in listaAsignacionCapacidadByIdServicioPublico){
                if(listaAsignacionCapacidadByIdServicioPublico[i].ubicacionByIdUbicacion){
                    listaAsignacionCapacidadByIdServicioPublico[i].claveUbicacion = listaAsignacionCapacidadByIdServicioPublico[i].ubicacionByIdUbicacion.claveUbicacion
                } else {
                    listaAsignacionCapacidadByIdServicioPublico[i].claveUbicacion = 'No cuenta con ubicación'
                }

                if(listaAsignacionCapacidadByIdServicioPublico[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional){
                    listaAsignacionCapacidadByIdServicioPublico[i].nombreUbicacion = listaAsignacionCapacidadByIdServicioPublico[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional;

                    if(listaAsignacionCapacidadByIdServicioPublico[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional){
                        listaAsignacionCapacidadByIdServicioPublico[i].nombreUbicacion = listaAsignacionCapacidadByIdServicioPublico[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional + ', ' +listaAsignacionCapacidadByIdServicioPublico[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;

                        if(listaAsignacionCapacidadByIdServicioPublico[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional){
                            listaAsignacionCapacidadByIdServicioPublico[i].nombreUbicacion = listaAsignacionCapacidadByIdServicioPublico[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional + ', ' +listaAsignacionCapacidadByIdServicioPublico[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional + ', ' + listaAsignacionCapacidadByIdServicioPublico[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional;

                            if(listaAsignacionCapacidadByIdServicioPublico[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional){
                                listaAsignacionCapacidadByIdServicioPublico[i].nombreUbicacion = listaAsignacionCapacidadByIdServicioPublico[i].ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional + ', ' +listaAsignacionCapacidadByIdServicioPublico[i].ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional + ', ' + listaAsignacionCapacidadByIdServicioPublico[i].ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional + ', ' + listaAsignacionCapacidadByIdServicioPublico[i].ubicacionByIdUbicacion.subInteriorFuncionalByIdSubInteriorFuncional.subInteriorFuncional;
                            }
                        }
                    }
                }

                if(listaAsignacionCapacidadByIdServicioPublico[i].unidadMedidaByIdUnidadMedida){
                    listaAsignacionCapacidadByIdServicioPublico[i].unidadMedida = listaAsignacionCapacidadByIdServicioPublico[i].unidadMedidaByIdUnidadMedida.unidadMedida;
                }
            }

            yield put({ type: serviciosPublicosActions.LISTA_ASIGNACION_CAPACIDAD_BYIDSERVICIOPUBLICO_SUCCESS, listaAsignacionCapacidadByIdServicioPublico });
        }
        else {
            yield put({ type: serviciosPublicosActions.LISTA_ASIGNACION_CAPACIDAD_BYIDSERVICIOPUBLICO_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.LISTA_ASIGNACION_CAPACIDAD_BYIDSERVICIOPUBLICO_FAILURE });
    }
}
export function* listaConsumosAsignacionByIdServicioPublicoSaga() {
    yield takeLatest(serviciosPublicosActions.LISTA_ASIGNACION_CAPACIDAD_BYIDSERVICIOPUBLICO_REQUEST, listaConsumosAsignacionByIdServicioPublico);
}

function* crearAbastecimiento(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const fecha = action.abastecimiento.fechaAbastecimiento.replace(/[/]/gi,'-');
        const mes = parseInt(fecha.split('-')[1]) <= 9 ? '0' + fecha.split('-')[1] : fecha.split('-')[1];
        const dia = parseInt(fecha.split('-')[0]) <= 9 ? '0' + fecha.split('-')[0] : fecha.split('-')[0];
        const fechaAbast = fecha.split('-')[2] + '-' + mes + '-' + dia;
        const horaAbast = action.abastecimiento.horaAbastecimiento.split(':')[0] + ':' +  action.abastecimiento.horaAbastecimiento.split(':')[1] + ':00';

        const data = {
            "idAbastecimiento":null,
            "idAsignacionServicioPublico": action.abastecimiento.idAsignacionServicioPublico,
            "idVariableMedicion": parseInt(action.abastecimiento.idVariableMedicion),
            "lectura": action.abastecimiento.lecturaRegistrada,
            "idUbicacion": action.abastecimiento.idUbicacion,
            "fechaAlta":null,
            "fechaAbastecimiento": fechaAbast + 'T' + horaAbast,
            // "horaAbastecimiento": horaAbast,
            "fechaActualizacion":null
        }
        console.log('data', data)
        const response = yield call(crearAbastecimientoCall, token, data);
        console.log(response)
        if (response.status == 200) {
            const showSuccessMsg = true;
            const textMessage = "El registro de abastecimiento para el depósito fue generado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: serviciosPublicosActions.CREAR_ABASTECIMIENTO_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPublicosActions.CREAR_ABASTECIMIENTO_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.CREAR_ABASTECIMIENTO_FAILURE });
    }
}
export function* crearAbastecimientoSaga() {
    yield takeLatest(serviciosPublicosActions.CREAR_ABASTECIMIENTO_REQUEST, crearAbastecimiento);
}

function* listaVariablesMedicionByIdServicioPublico(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const idServicioPublico =  action.idServicioPublico;

        const response = yield call(listaVariablesMedicionByIdServicioPublicoCall, token, idServicioPublico);
        console.log(response);

        if (response.status == 200) {
            const listaVariablesMedicionByIdServicioPublico = response.data;

            yield put({ type: serviciosPublicosActions.LISTA_VARIABLES_MEDICION_BY_IDSERVICIOPUBLICO_SUCCESS, listaVariablesMedicionByIdServicioPublico });
        }
        else {
            yield put({ type: serviciosPublicosActions.LISTA_VARIABLES_MEDICION_BY_IDSERVICIOPUBLICO_FAILURE });
        }
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.LISTA_VARIABLES_MEDICION_BY_IDSERVICIOPUBLICO_FAILURE });
    }
}
export function* listaVariablesMedicionByIdServicioPublicoSaga() {
    yield takeLatest(serviciosPublicosActions.LISTA_VARIABLES_MEDICION_BY_IDSERVICIOPUBLICO_REQUEST, listaVariablesMedicionByIdServicioPublico);
}

// Variables Medicion - activas por Programacion Consumo
function* variableMedicionByProgramacion(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        
        let idProgramacionConsumo = parseInt(action.idProgramacionConsumo);

        const response = yield call(variableMedicionByProgramacionCall, token, idProgramacionConsumo);

        if (response.status == 200) {
            let variableMedicionByProgramacion = response.data;

            for(let i = 0; i < variableMedicionByProgramacion.length; i++){
                if(variableMedicionByProgramacion[i].unidadMedidaByIdUnidadMedida){
                    
                    variableMedicionByProgramacion[i].idCategoriaUnidadMedida = variableMedicionByProgramacion[i].unidadMedidaByIdUnidadMedida.idCategoriaUnidadMedida;
                    
                }
            }

            yield put({ type: serviciosPublicosActions.VARIABLES_POR_PROGRAMACION_SUCCESS, variableMedicionByProgramacion });
        }
        else {
            yield put({ type: serviciosPublicosActions.VARIABLES_POR_PROGRAMACION_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.VARIABLES_POR_PROGRAMACION_FAILURE });
    }
}
export function* variableMedicionByProgramacionSaga() {
    yield takeLatest(serviciosPublicosActions.VARIABLES_POR_PROGRAMACION_REQUEST, variableMedicionByProgramacion);
}

function* registroLectura(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let data = [];

            for(let i = 0; i < action.listaDepositosByIdServiciosPublicos.length; i++){
                const dataLectura = 
                    {
                        "idAsignacionServicioPublico" : parseInt(action.listaDepositosByIdServiciosPublicos[i].idAsignacionServicioPublico),
                        "idVariableMedicion" : action.listaDepositosByIdServiciosPublicos[i].idVariableMedicion,
                        "idUnidadMedida": action.listaDepositosByIdServiciosPublicos[i].idUnidadMedida,
                        "idProgramacionHorario" : parseInt(action.formulario.idProgramacionHorario),
                        "lectura" : action.listaDepositosByIdServiciosPublicos[i].lecturaRegistrada
                    }
                    data.push(dataLectura);
            }

        const response = yield call(registroLecturaCall, token, data);
        console.log(response);

        if (response.status == 200) {
            const page = action.page;
            const busqueda = action.busqueda;
            const showModalCapturaConsumos = false;
            const showSuccessMsg = true;
            const textMessage = "El registro de lectura del servicio público " + action.listaDepositosByIdServiciosPublicos[0].nombre + " fue generado con éxito.";

            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "registroLectura" });
            yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_SUCCESS });
            yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_BUSQUEDA_REQUEST, page, busqueda });
            yield put({ type: serviciosPublicosActions.SHOW_MODAL_CAPTURA_CONSUMOS, showModalCapturaConsumos });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;
            const showModalCapturaConsumos = false;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPublicosActions.SHOW_MODAL_CAPTURA_CONSUMOS, showModalCapturaConsumos });
            yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_ACTIVOS_FAILURE });
        yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_FAILURE });

    }
}
export function* registroLecturaSaga() {
    yield takeLatest(serviciosPublicosActions.REGISTRO_LECTURA_REQUEST, registroLectura);
}

// Verificación de Registro de Lectura
function* verificarRegistroLectura(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const { idProgramacionHorario,  } = action.formulario;
        const data = [];

        for(let i = 0; i < action.listaConjunta.length; i++){
            const dataVerificacion = 
                {
                    "idAsignacionServicioPublico" : parseInt(action.listaConjunta[i].idAsignacionServicioPublico),
                    "idVariableMedicion" : parseInt(action.listaConjunta[i].idVariableMedicion),
                    "idUnidadMedida": parseInt(action.listaConjunta[i].idUnidadMedida),
                    "idProgramacionHorario" : parseInt(idProgramacionHorario),
                    "lectura" : action.listaConjunta[i].lecturaRegistrada
                }
                data.push(dataVerificacion);
        }
        
        console.log('data Verifición', data);

        const response = yield call(verificarRegistroLecturaCall, token, data);
        console.log(response);

        if (response.status == 200) {
            const verificacion = response.data;

            if(verificacion){
                const { confirm } = Modal;
                confirm({
                    title: 'Una o varias lecturas anteriores fueron mayores, ¿Estás seguro que quieres registrar ésta lectura?',
                    content: '',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                        console.log('Ok');
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });	
            } else {
                console.log('Entra al else de verificación');
                const responseRegistro = yield call(registroLecturaCall, token, data);
                console.log('responseRegistro', responseRegistro);
                if (responseRegistro.status == 200) {
                    const page = action.page;
                    const busqueda = action.busqueda;
                    const showModalCapturaConsumos = false;
                    const showSuccessMsg = true;
                    const textMessage = "El registro de lectura del servicio público " + action.listaConjunta[0].nombre + " fue generado con éxito.";

                    yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
                    yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "registroLectura" });
                    yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_SUCCESS });
                    yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_BUSQUEDA_REQUEST, page, busqueda });
                    yield put({ type: serviciosPublicosActions.SHOW_MODAL_CAPTURA_CONSUMOS, showModalCapturaConsumos });
                }
                else {
                    const showErrorMsg = true;
                    const textMessage = responseRegistro.message;
                    const showModalCapturaConsumos = false;

                    yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
                    yield put({ type: serviciosPublicosActions.SHOW_MODAL_CAPTURA_CONSUMOS, showModalCapturaConsumos });
                    yield put({ type: serviciosPublicosActions.REGISTRO_LECTURA_FAILURE });
                }
            }

            yield put({ type: serviciosPublicosActions.VERIFICACION_REGISTRO_LECTURA_SUCCESS, verificacion });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPublicosActions.VERIFICACION_REGISTRO_LECTURA_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.VERIFICACION_REGISTRO_LECTURA_FAILURE });
    }
}
export function* verificarRegistroLecturaSaga() {
yield takeLatest(serviciosPublicosActions.VERIFICACION_REGISTRO_LECTURA_REQUEST, verificarRegistroLectura);
}

//Asignacion de Ubicación y Capacidad
function* serviciosPublicosAsignadosBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const {busqueda = ''} = action;

        const response = yield call(listaServiciosPublicosACall, token, page, busqueda);
        console.log(response);

        if (response.status == 200) {
            let listaServiciosPublicosAsignadosBusqueda = response.data.data;
            const listaServiciosPublicosAsignadosCantidad = response.data.cantidad;

            yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_ASIGNADOS_SUCCESS, listaServiciosPublicosAsignadosBusqueda, listaServiciosPublicosAsignadosCantidad });
        }
        else {
            yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_ASIGNADOS_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_ASIGNADOS_FAILURE });
    }
}
export function* serviciosPublicosAsignadosBusquedaSaga() {
    yield takeLatest(serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_ASIGNADOS_REQUEST, serviciosPublicosAsignadosBusqueda);
}

function* serviciosPublicosNoAsignados(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaServiciosPublicosNACall, token);
        console.log(response);

        if (response.status == 200) {
            let listaServiciosPublicosNoAsignados = response.data;
            yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_NO_ASIGNADOS_SUCCESS, listaServiciosPublicosNoAsignados});
        }
        else {
            yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_NO_ASIGNADOS_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_NO_ASIGNADOS_FAILURE });
    }
}
export function* serviciosPublicosNoAsignadosSaga() {
    yield takeLatest(serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_NO_ASIGNADOS_REQUEST, serviciosPublicosNoAsignados);
}

function* listaDepositosSPA(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const {servicioPublicoSeleccionado="", busqueda="", tipo=""} = action;
        const {idServicioPublico=""} = servicioPublicoSeleccionado

        const response = yield call(listaDepositosSPACall, token, idServicioPublico);
        console.log(response);

        if (response.status == 200) {
            let listaDepositosServiciosPublicos = response.data;
            let listaDepositosSPObjeto = {};
            
            listaDepositosServiciosPublicos.forEach(item => {
                //modal detalle
                item.ubicacion = 'No tiene ubicación'
                item.unidadMedida = 'No tiene unidad de medida'
                item.nombreDeposito = 'No tiene nombre';
                
                //modal edición
                listaDepositosSPObjeto[`capacidad-${item.idAsignacionServicioPublico}`] = item.capacidad;
                listaDepositosSPObjeto[`activo-${item.idAsignacionServicioPublico}`] = item.activo;
                
                if(item.ubicacionByIdUbicacion) {
                    let todoUbicacionCOA = item.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.codigoArea + ', ' + item.ubicacionByIdUbicacion.areaFuncionalByIdAreaFuncional.areaFuncional + ', ' + item.ubicacionByIdUbicacion.unidadFuncionalByIdUnidadFuncional.unidadFuncional;
                    item.ubicacion = todoUbicacionCOA;
                    listaDepositosSPObjeto[`idUbicacion-${item.idAsignacionServicioPublico}`] = todoUbicacionCOA;
                    
                    if(item.ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional) {
                        todoUbicacionCOA += ', ' + item.ubicacionByIdUbicacion.interiorFuncionalByIdInteriorFuncional.interiorFuncional
                        item.ubicacion = todoUbicacionCOA;
                        listaDepositosSPObjeto[`idUbicacion-${item.idAsignacionServicioPublico}`] = todoUbicacionCOA;
                    }
                }
                if(item.unidadMedidaByIdUnidadMedida) {
                    const {unidadMedida=""} = item.unidadMedidaByIdUnidadMedida;
                    item.unidadMedida = unidadMedida;
                    listaDepositosSPObjeto[`idUnidadMedida-${item.idAsignacionServicioPublico}`] = unidadMedida;
                }
                if(item.servicioPublicoByIdServicioPublico) {
                    const {nombreDeposito=""} = item.servicioPublicoByIdServicioPublico;
                    item.nombreDeposito = nombreDeposito;
                }
            })

            yield put({ type: serviciosPublicosActions.LISTA_DEPOSITOS_SERVICIOS_PUBLICOS_SUCCESS, listaDepositosServiciosPublicos });
            
            if (tipo == 'detalle') {
                yield put({ type: serviciosPublicosActions.SHOW_MODAL_DETALLE_DEPOSITOS, showModalDetalleDepositosSP: true, servicioPublicoSeleccionado, page, busqueda });
            }

            if (tipo == 'editar') {
                yield put({ type: serviciosPublicosActions.SHOW_MODAL_EDITAR_ASIGNACION_DEPOSITOS, showModalEditarAsignacionSP: true, servicioPublicoSeleccionado, listaDepositosSPObjeto, page, busqueda });
            }
        }
        else {
            yield put({ type: serviciosPublicosActions.LISTA_DEPOSITOS_SERVICIOS_PUBLICOS_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.LISTA_DEPOSITOS_SERVICIOS_PUBLICOS_FAILURE });
    }
}
export function* listaDepositosSPASaga() {
    yield takeLatest(serviciosPublicosActions.LISTA_DEPOSITOS_SERVICIOS_PUBLICOS_REQUEST, listaDepositosSPA);
}

function* crearAsignacionDepositos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {idServicioPublico="", formulario="", busqueda=""} = action;

        const response = yield call(crearAsignacionDepositosCall, token, idServicioPublico, formulario);
        console.log(response);
        
        if (response.status == 200) {
            const page = action.page;
            const showModalNuevaAsignacionSP = false;
            const showSuccessMsg = true;
            const textMessage = "Los depósitos del servicio público fueron asignados con éxito.";
            
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "CASP" });
            yield put({ type: serviciosPublicosActions.NUEVA_ASIGNACION_SERVICIOS_PUBLICOS_SUCCESS});
            yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_ASIGNADOS_REQUEST, page, busqueda});
            yield put({ type: serviciosPublicosActions.SHOW_MODAL_NUEVA_ASIGNACION_DEPOSITOS, showModalNuevaAsignacionSP})
        }
        else {
            yield put({ type: serviciosPublicosActions.NUEVA_ASIGNACION_SERVICIOS_PUBLICOS_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.NUEVA_ASIGNACION_SERVICIOS_PUBLICOS_FAILURE });
    }
}
export function* crearAsignacionDepositosSaga() {
    yield takeLatest(serviciosPublicosActions.NUEVA_ASIGNACION_SERVICIOS_PUBLICOS_REQUEST, crearAsignacionDepositos);
}

function* editarAsignacionDepositos(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {idServicioPublico="", formulario="", busqueda=""} = action;

        const response = yield call(editarAsignacionDepositosCall, token, idServicioPublico, formulario);
        console.log(response);
        
        if (response.status == 200) {
            const page = action.page;
            const showModalEditarAsignacionSP = false;
            const showSuccessMsg = true;
            const textMessage = "Los depósitos del servicio público fueron editados con éxito.";
            
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "EASP" });
            yield put({ type: serviciosPublicosActions.EDITAR_ASIGNACION_SERVICIOS_PUBLICOS_SUCCESS});
            // yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_ASIGNADOS_REQUEST, page, busqueda});
            yield put({ type: serviciosPublicosActions.SHOW_MODAL_EDITAR_ASIGNACION_DEPOSITOS, showModalEditarAsignacionSP})
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;
            
            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPublicosActions.EDITAR_ASIGNACION_SERVICIOS_PUBLICOS_FAILURE });
        }
        
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.EDITAR_ASIGNACION_SERVICIOS_PUBLICOS_FAILURE });
    }
}
export function* editarAsignacionDepositosSaga() {
    yield takeLatest(serviciosPublicosActions.EDITAR_ASIGNACION_SERVICIOS_PUBLICOS_REQUEST, editarAsignacionDepositos);
}

//Cálculos de Consumo Diferenciado

function* serviciosPublicosCalculo(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');

        const response = yield call(listaSPCalculoCall, token);
        console.log(response);

        if (response.status == 200) {
            let listaServiciosPublicosCalculo = response.data;
            yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_CCD_SUCCESS, listaServiciosPublicosCalculo});
        }
        else {
            yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_CCD_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_CCD_FAILURE });
    }
}
export function* serviciosPublicosCalculoSaga() {
    yield takeLatest(serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_CCD_REQUEST, serviciosPublicosCalculo);
}

function* serviciosPublicosCalculoBusqueda(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const {busqueda = ''} = action;

        const response = yield call(listaSPCalculoBusquedaCall, token, page, busqueda);
        console.log(response);

        if (response.status == 200) {
            const listaServiciosPublicosCalculoBusqueda = response.data.data;
            const listaServiciosPublicosCalculoCantidad = response.data.cantidad;

            yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_CCD_BUSQUEDA_SUCCESS, listaServiciosPublicosCalculoBusqueda, listaServiciosPublicosCalculoCantidad });
        }
        else {
            yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_CCD_BUSQUEDA_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_CCD_BUSQUEDA_FAILURE });
    }
}
export function* serviciosPublicosCalculoBusquedaSaga() {
    yield takeLatest(serviciosPublicosActions.LISTA_SERVICIOS_PUBLICOS_CCD_BUSQUEDA_REQUEST, serviciosPublicosCalculoBusqueda);
}

function* listaDetalleCCDInterno (action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const {idServicioPublico} = action.servicioPublicoSeleccionadoCCDInterno;
        

        const response = yield call(listaDetalleCCDInternoCall, token, idServicioPublico, page);
        console.log(response);

        if (response.status == 200) {
            let listaDetalleCalculosInterno = response.data.data;
            let listaDetalleCalculosInternoCantidad = response.data.cantidad;

            listaDetalleCalculosInterno.forEach(item => {
                item.servicioPublico = item.servicioPublicoByIdServicioPublico.nombre
                item.precioUnitario = item.precioUnitario.toFixed(3);
                item.cobroConsumo = item.cobroConsumo.toFixed(2);
            })

            yield put({ type: serviciosPublicosActions.LISTA_DETALLE_CCD_INTERNO_SUCCESS, listaDetalleCalculosInterno, listaDetalleCalculosInternoCantidad});
        }
        else {
            yield put({ type: serviciosPublicosActions.LISTA_DETALLE_CCD_INTERNO_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.LISTA_DETALLE_CCD_INTERNO_FAILURE });
    }
}
export function* listaDetalleCCDInternoSaga() {
    yield takeLatest(serviciosPublicosActions.LISTA_DETALLE_CCD_INTERNO_REQUEST, listaDetalleCCDInterno);
}

function* listaDetalleCCDFacturacion (action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const page = action.page - 1;
        const {idServicioPublico} = action.servicioPublicoSeleccionadoCCDFacturacion;

        const response = yield call(listaDetalleCCDFactorizacionCall, token, idServicioPublico, page);
        console.log(response);

        if (response.status == 200) {
            let listaDetalleCalculosFacturacion = response.data.data;
            let listaDetalleCalculosFacturacionCantidad = response.data.cantidad;

            function numberWithCommas(x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
           }

            listaDetalleCalculosFacturacion.forEach(item => {
                item.servicioPublico = item.servicioPublicoByIdServicioPublico.nombre
                item.precioUnitario = numberWithCommas(item.precioUnitario.toFixed(3));
                item.cobroConsumoPyrs = numberWithCommas(item.cobroConsumoPyrs.toFixed(2));
                item.cobroConsumoDesarrollador = numberWithCommas(item.cobroConsumoDesarrollador.toFixed(2));
                item.mesNombre = item.mesesByIdMes.mes;
            })
            yield put({ type: serviciosPublicosActions.LISTA_DETALLE_CCD_FACTURACION_SUCCESS, listaDetalleCalculosFacturacion, listaDetalleCalculosFacturacionCantidad});
        }
        else {
            yield put({ type: serviciosPublicosActions.LISTA_DETALLE_CCD_FACTURACION_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.LISTA_DETALLE_CCD_FACTURACION_FAILURE });
    }
}
export function* listaDetalleCCDFacturacionSaga() {
    yield takeLatest(serviciosPublicosActions.LISTA_DETALLE_CCD_FACTURACION_REQUEST, listaDetalleCCDFacturacion);
}

function* listaCDInterno (action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        let {idServicioPublico="", depositos=[] , inicioPeriodo="", finPeriodo=""} = action;

        let lecturaInicioArray=[];
        let lecturaFinArray=[];
        let consumoTotal = 0;
        for (let i=0; depositos.length > i; i++) {
            const responseInicio = yield call(lecturaInicioCDInternoCall, token, depositos[i].idAsignacionServicioPublico, inicioPeriodo, finPeriodo);
            const responseFin = yield call(lecturaFinCDInternoCall, token, depositos[i].idAsignacionServicioPublico, inicioPeriodo, finPeriodo);
            console.log(responseInicio);
            console.log(responseFin);
    
            if (responseInicio.status == 200) {
                let lecturaInicioData = responseInicio.data;
                lecturaInicioArray.push(lecturaInicioData[0]);
                depositos[i].lecturaInicio = "";
                depositos[i].variableMedicion = "";
                if(lecturaInicioData.length > 0){
                    depositos[i].lecturaInicio = lecturaInicioData[0].lectura;
                    depositos[i].variableMedicion = lecturaInicioData[0].variablesMedicionByIdVariableMedicion.nombre;
                }
            }
            else {
                yield put({ type: serviciosPublicosActions.LISTA_CCD_INTERNO_FAILURE });
                break;
            }

            if (responseFin.status == 200) {
                let lecturaFinData = responseFin.data;
                lecturaFinArray.push(lecturaFinData[0]);
                depositos[i].lecturaFin = "";
                if(lecturaFinData.length > 0) {
                    depositos[i].lecturaFin = lecturaFinData[0].lectura;
                } 
            }
            else {
                yield put({ type: serviciosPublicosActions.LISTA_CCD_INTERNO_FAILURE });
                break;
            }
            
            depositos[i].consumo = depositos[i].lecturaFin - depositos[i].lecturaInicio;
            consumoTotal += depositos[i].consumo;
            
            if(depositos.length == i+1) {
                yield put({ type: serviciosPublicosActions.LISTA_CCD_INTERNO_SUCCESS, listaCDInterno: depositos, consumoTotal: consumoTotal, lecturasInicioFinArray: [lecturaInicioArray, lecturaFinArray]});
            }
        }
        if (depositos.length == 0) {
            message.warning('El servicio no cuenta con registro de consumos.')
            yield put({ type: serviciosPublicosActions.LISTA_CCD_INTERNO_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.LISTA_CCD_INTERNO_FAILURE });
    }
}
export function* listaCDInternoSaga() {
    yield takeLatest(serviciosPublicosActions.LISTA_CCD_INTERNO_REQUEST, listaCDInterno);
}

function* crearCalculoCDInterno (action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {idServicioPublico="", data=""} = action
        console.log(data)

        const asignacionServicioPublicoArray = data.map(item => item.idAsignacionServicioPublico);
        console.log(asignacionServicioPublicoArray)
        const formData = new FormData();
        formData.append('nuevoCalculo', new Blob([JSON.stringify(data)],{type: "application/json"}));
        formData.append('idsAsignacionServicioPublico', new Blob([JSON.stringify(asignacionServicioPublicoArray)],{type: "application/json"}));

        const response = yield call(crearCalculoCDInternoCall, token, idServicioPublico, formData);
        console.log(response);
        
        if (response.status == 200) {
            const showModalCalculoCDInterno = true;
            const showSuccessMsg = true;
            const textMessage = "El cálculo de consumo diferenciado interno fue registrado con éxito.";
            
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            // yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "CCDI" });
            yield put({ type: serviciosPublicosActions.CALCULO_CONSUMO_DIFERENCIADO_INTERNO_SUCCESS});
            yield put({ type: serviciosPublicosActions.SHOW_MODAL_CCD_INTERNO, showModalCalculoCDInterno});
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPublicosActions.CALCULO_CONSUMO_DIFERENCIADO_INTERNO_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.CALCULO_CONSUMO_DIFERENCIADO_INTERNO_FAILURE });
    }
}
export function* crearCalculoCDInternoSaga() {
    yield takeLatest(serviciosPublicosActions.CALCULO_CONSUMO_DIFERENCIADO_INTERNO_REQUEST, crearCalculoCDInterno);
}

function* crearCalculoCDFacturacion (action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {idServicioPublico="", periodo=[], cicloFacturacion="", ubicacion="", totalFacturado=0, consumoPyrs=null, consumoDesarrollador=null, unidadMedida="",precioUnitario="",
        cobroConsumoDesarrollador=null, cobroConsumoPyrs=null, rutaArchivo=""} = action.formulario;
        const idMes = parseInt(action.formulario.idMes);
        const factura = action.formulario.numeroFactura;
        const idUbicacion = parseInt(ubicacion.split('-')[0]);
        const periodoInicio = periodo[0].format('YYYY-MM-DD');
        const periodoFin = periodo[1].format('YYYY-MM-DD'); 

        const data = {
            "idConsumoFacturacion": null,
            "idServicioPublico": idServicioPublico,
            "idAsignacionServicioPublico": null,
            "idUbicacion": idUbicacion,
            "idUnidadMedida": parseInt(unidadMedida),
            "idMes": idMes,
            "factura": factura,
            "folio": null,
            "periodoInicio": periodoInicio,
            "periodoFin": periodoFin,
            "cicloFacturacion": cicloFacturacion,
            "totalFacturado": parseFloat(totalFacturado),
            "consumoDesarrollador": consumoDesarrollador,
            "consumoPyrs": consumoPyrs,
            "precioUnitario": parseFloat(precioUnitario),
            "cobroConsumoPyrs": cobroConsumoPyrs == null ? null : parseFloat(cobroConsumoPyrs),
            "cobroConsumoDesarrollador": cobroConsumoDesarrollador == null ? null :parseFloat(cobroConsumoDesarrollador),
            "rutaArchivo": null,
            "fechaAlta": null
        }
        
        console.log(data);

        const formData = new FormData();
        formData.append('file', rutaArchivo.file.originFileObj);
        formData.append('data', new Blob([JSON.stringify(data)], {
            type: "application/json"
        }));   
        
        const response = yield call(crearCalculoCDFacturacionCall, token, formData);
        console.log(response);
        if (response.status == 200) {
            const showModalCalculoCDFacturacion = true;
            const showSuccessMsg = true;
            const textMessage = "El cálculo de consumo diferenciado por facturación fue registrado con éxito.";
            
            yield put({ type: configuracionYSeguridadActions.SHOW_SUCCESS_MSG, showSuccessMsg, textMessage });
            // yield put({ type: configuracionYSeguridadActions.LIMPIAR_FORMULARIO, limpiarFormulario: "CCDF" });
            yield put({ type: serviciosPublicosActions.CALCULO_CONSUMO_DIFERENCIADO_FACTURACION_SUCCESS});
            yield put({ type: serviciosPublicosActions.SHOW_MODAL_CDD_FACTURACION, showModalCalculoCDFacturacion});
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPublicosActions.CALCULO_CONSUMO_DIFERENCIADO_FACTURACION_FAILURE });
        }
 
    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.CALCULO_CONSUMO_DIFERENCIADO_FACTURACION_FAILURE });
    }
}
export function* crearCalculoCDFacturacionSaga() {
    yield takeLatest(serviciosPublicosActions.CALCULO_CONSUMO_DIFERENCIADO_FACTURACION_REQUEST, crearCalculoCDFacturacion);
}

function* calculoCDInternoDescargaPDF(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {idServicioPublico="", data="",lecturasInicioFinArray="",ubicacionCompleta=[]} = action;
        const {periodoInicio="", periodoFin=""} = data[0]
        const lecturaInicioArray = lecturasInicioFinArray[0];
        const lecturaFinArray = lecturasInicioFinArray[1];
        
        const formData = new FormData();
        formData.append('calculos', new Blob([JSON.stringify(data)],{type: "application/json"}));
        formData.append('lecturaInicio', new Blob([JSON.stringify(lecturaInicioArray)],{type: "application/json"}));
        formData.append('lecturaFin', new Blob([JSON.stringify(lecturaFinArray)],{type: "application/json"}));
        formData.append('ubicacionCompleta', new Blob([JSON.stringify(ubicacionCompleta)],{type: "application/json"}));

        console.log(formData);
        let response = yield call(calculoCDInternoPDFCall, token, idServicioPublico, periodoInicio, periodoFin, formData );
        console.log(response);

        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "CalculoCDInterno-Archivo-" + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            
            yield put({ type: serviciosPublicosActions.CALCULO_CD_INTERNO_PDF_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = "Favor de validar lecturas del periodo y consumo";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPublicosActions.CALCULO_CD_INTERNO_PDF_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.CALCULO_CD_INTERNO_PDF_FAILURE });
    }
}
export function* calculoCDInternoDescargaPDFSaga() {
    yield takeLatest(serviciosPublicosActions.CALCULO_CD_INTERNO_PDF_REQUEST, calculoCDInternoDescargaPDF);
}

function* calculoCDInternoDescargaExcel(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {idServicioPublico="", data="",lecturasInicioFinArray="", ubicacionCompleta=[]} = action;
        const {periodoInicio="", periodoFin=""} = data[0]
        const lecturaInicioArray = lecturasInicioFinArray[0];
        const lecturaFinArray = lecturasInicioFinArray[1];
        
        const formData = new FormData();
        formData.append('calculos', new Blob([JSON.stringify(data)],{type: "application/json"}));
        formData.append('lecturaInicio', new Blob([JSON.stringify(lecturaInicioArray)],{type: "application/json"}));
        formData.append('lecturaFin', new Blob([JSON.stringify(lecturaFinArray)],{type: "application/json"}));
        formData.append('ubicacionCompleta', new Blob([JSON.stringify(ubicacionCompleta)],{type: "application/json"}));

        console.log(formData);
        let response = yield call(calculoCDInternoExcelCall, token, formData );
        console.log(response);

        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "CalculoCDInterno-Archivo-" + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            
            yield put({ type: serviciosPublicosActions.CALCULO_CD_INTERNO_EXCEL_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = "Favor de validar lecturas del periodo y consumo";

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPublicosActions.CALCULO_CD_INTERNO_EXCEL_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.CALCULO_CD_INTERNO_EXCEL_FAILURE });
    }
}
export function* calculoCDInternoDescargaExcelSaga() {
    yield takeLatest(serviciosPublicosActions.CALCULO_CD_INTERNO_EXCEL_REQUEST, calculoCDInternoDescargaExcel);
}

function* calculoCDFacturacionDescargaPDF(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {idServicioPublico="", periodo=[], cicloFacturacion="", ubicacion="", totalFacturado=0, consumoPyrs=null, consumoDesarrollador=null, unidadMedida="",precioUnitario="",
        cobroConsumoDesarrollador=null, cobroConsumoPyrs=null} = action.formulario;
        const idUbicacion = parseInt(ubicacion.split('-')[0]);
        const periodoInicio = periodo[0].format('YYYY-MM-DD');
        const periodoFin = periodo[1].format('YYYY-MM-DD'); 
        const ubicacionCompleta = [{"idAsignacionServicioPublico": null, "ubicacionCompleta": ubicacion.split('-')[1]}];

        const data = [{
            "idConsumoFacturacion": null,
            "idServicioPublico": idServicioPublico,
            "idAsignacionServicioPublico": null,
            "idUbicacion": idUbicacion,
            "idUnidadMedida": parseInt(unidadMedida),
            "folio": null,
            "periodoInicio": periodoInicio,
            "periodoFin": periodoFin,
            "cicloFacturacion": cicloFacturacion,
            "totalFacturado": parseFloat(totalFacturado),
            "consumoDesarrollador": consumoDesarrollador,
            "consumoPyrs": consumoPyrs,
            "precioUnitario": parseFloat(precioUnitario),
            "cobroConsumoPyrs":cobroConsumoPyrs !== null ? parseFloat(cobroConsumoPyrs) : null,
            "cobroConsumoDesarrollador": cobroConsumoDesarrollador !== null ? parseFloat(cobroConsumoDesarrollador) : null,
            "rutaArchivo": null,
            "fechaAlta": null,
        }]
        console.log(data, ubicacionCompleta);

        const formData = new FormData();
        formData.append('calculoFacturado', new Blob([JSON.stringify(data)],{type: "application/json"}));
        formData.append('ubicacionCompleta', new Blob([JSON.stringify(ubicacionCompleta)],{type: "application/json"}));

        let response = yield call(calculoCDFacturacionPDFCall, token, formData );
        console.log(response);

        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "CalculoCDFacturacion-Archivo-" + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".pdf";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            
            yield put({ type: serviciosPublicosActions.CALCULO_CD_FACTURACION_PDF_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPublicosActions.CALCULO_CD_FACTURACION_PDF_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.CALCULO_CD_FACTURACION_PDF_FAILURE });
    }
}
export function* calculoCDFacturacionDescargaPDFSaga() {
    yield takeLatest(serviciosPublicosActions.CALCULO_CD_FACTURACION_PDF_REQUEST, calculoCDFacturacionDescargaPDF);
}

function* calculoCDFacturacionDescargaExcel(action) {
    try {
        console.log(action);
        const token = sessionStorage.getItem('token');
        const {idServicioPublico="", periodo=[], cicloFacturacion="", ubicacion="", totalFacturado=0, consumoPyrs=null, consumoDesarrollador=null, unidadMedida="",precioUnitario="",
        cobroConsumoDesarrollador=null, cobroConsumoPyrs=null} = action.formulario;
        const idUbicacion = parseInt(ubicacion.split('-')[0]);
        const periodoInicio = periodo[0].format('YYYY-MM-DD');
        const periodoFin = periodo[1].format('YYYY-MM-DD'); 
        const ubicacionCompleta = [{"idAsignacionServicioPublico": null, "ubicacionCompleta": ubicacion.split('-')[1]}];

        const data = [{
            "idConsumoFacturacion": null,
            "idServicioPublico": idServicioPublico,
            "idAsignacionServicioPublico": null,
            "idUbicacion": idUbicacion,
            "idUnidadMedida": parseInt(unidadMedida),
            "folio": null,
            "periodoInicio": periodoInicio,
            "periodoFin": periodoFin,
            "cicloFacturacion": cicloFacturacion,
            "totalFacturado": parseFloat(totalFacturado),
            "consumoDesarrollador": consumoDesarrollador,
            "consumoPyrs": consumoPyrs,
            "precioUnitario": parseFloat(precioUnitario),
            "cobroConsumoPyrs": cobroConsumoPyrs !== null ? parseFloat(cobroConsumoPyrs) : null,
            "cobroConsumoDesarrollador": cobroConsumoDesarrollador !==null ? parseFloat(cobroConsumoDesarrollador) : null,
            "rutaArchivo": null,
            "fechaAlta": null,
        }]
        console.log(data, ubicacionCompleta);

        const formData = new FormData();
        formData.append('facturacion', new Blob([JSON.stringify(data)],{type: "application/json"}));
        formData.append('ubicacionCompleta', new Blob([JSON.stringify(ubicacionCompleta)],{type: "application/json"}));

        console.log(data);
        let response = yield call(calculoCDFacturacionExcelCall, token, formData);
        console.log(response);

        if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = "CalculoCDFacturacion-Archivo-" + new Date().getDate() + "-" + + new Date().getMonth()  + "-" + + new Date().getFullYear() + " " + new Date().getHours() + " " + new Date().getMinutes() + " " + new Date().getSeconds() + ".xls";

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            
            yield put({ type: serviciosPublicosActions.CALCULO_CD_FACTURACION_EXCEL_SUCCESS });
        }
        else {
            const showErrorMsg = true;
            const textMessage = response.message;

            yield put({ type: configuracionYSeguridadActions.SHOW_ERROR_MSG, showErrorMsg, textMessage });
            yield put({ type: serviciosPublicosActions.CALCULO_CD_FACTURACION_EXCEL_FAILURE });
        }

    } catch (error) {
        console.log(error);
        yield put({ type: serviciosPublicosActions.CALCULO_CD_FACTURACION_EXCEL_FAILURE });
    }
}
export function* calculoCDFacturacionDescargaExcelSaga() {
    yield takeLatest(serviciosPublicosActions.CALCULO_CD_FACTURACION_EXCEL_REQUEST, calculoCDFacturacionDescargaExcel);
}
