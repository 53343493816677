import React from 'react';
import { Form, Card, Spin, Row, Col, Select, Result, Table, Tooltip, Button, Divider, message, Descriptions } from 'antd';
import { FileExcelOutlined, EyeOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import DetalleCalculo from './DetalleCalculoDeductivasReiteracion';

const { Option } = Select;

class CalcularDeductivasReiteracionContainer extends React.Component {
     formRef = React.createRef();
     componentDidMount(){
          this.props.onGetListaMesesAbiertos();
     }

     state={
         visibleButton: false, disabledButtons: true, fechaState: '', visibleValores: false,  mesSeleccionado: []
     }
 
     render() {
          const {
               showModalDetalle, listaMesesAbiertos, fetchingListaMesesAbiertos, valorCalculoTipoDeductiva, fetchingValorCalculoTipoDeductiva, onGetValorCalculo, fetchingReporteResumenCalculoDR, fetchingListaDetalleCalculoReiteracion, onGetDetelle,
               reporteResumenCalculoDR, onGetReporteResumen, onExportarResumenDeductivaReiteracion, fetchingExportarExcelCDR,
          } = this.props;
 
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){ permisosUsuario = JSON.parse(permisosUsuario); }

          const onShowModalDetalle = () => { 
               showModalDetalle(this.state.fechaState, this.state.mesSeleccionado.idMesCalendario);
               onGetDetelle(this.state.fechaState, this.state.mesSeleccionado.idMesCalendario);
          };

          const openNotificationWithIcon = (fecha) => {
               message.info({
                    content: 'Fecha tomada para el cálculo: ' + fecha,
               });
          };

          const onMesSelected = (e) => { 
               if(e){
                    let anio = '';
                    let mes = '';
                    let idMesLocal = parseInt(e.split('-')[0]);

                    let cantidadPPL = null;
                    let dmi = null

                    let mesSeleccionadoLocal = [];

                    for(let i in listaMesesAbiertos){
                         if(listaMesesAbiertos[i].idMesCalendario === idMesLocal){
                              this.setState({mesSeleccionado: listaMesesAbiertos[i]});
                              anio = parseInt(listaMesesAbiertos[i].anioCalendario);
                              mes = parseInt(listaMesesAbiertos[i].idMes);
                              mesSeleccionadoLocal = listaMesesAbiertos[i];
                              cantidadPPL = listaMesesAbiertos[i].cantidadPplCalendario;
                              dmi = listaMesesAbiertos[i].dmi;

                              onGetValorCalculo(null , listaMesesAbiertos[i].idMesCalendario);
                         }
                    }

                    let dias = new Date(anio, mes, 0).getDate();

                    let fecha = new Date(anio, mes -1, dias);
                    fecha.setDate(fecha.getDate() + 1);

                    // CAMBIAR CONDICION A === PARA FUNCIONAR
                    if(dmi === cantidadPPL){
                         if (mes < 10) 
                              mes = '0' + mes;
                         if (dias < 10) 
                              dias = '0' + dias;

                         let fechaToGetReport = anio + '-' + mes + '-' + dias;
                         this.setState({ visibleButton: true, fechaState: fechaToGetReport, visibleValores: false, disabledButtons: true });
                         openNotificationWithIcon(dias + '-' + mes + '-' + anio);
                         onGetReporteResumen(fechaToGetReport, mesSeleccionadoLocal.idMesCalendario, mesSeleccionadoLocal.mesCalendario);
                    } else {
                         this.setState({ visibleButton: false, visibleValores: false, disabledButtons: true });
                         message.warning('La cantidad de días capturados para los PPLs es menor al número de días para el mes seleccionado.', 6);
                    }
               }
          };

          const onCalculo = () => {
               this.setState({ visibleValores: true, disabledButtons: false });
          };

          let data=[];
          if (valorCalculoTipoDeductiva){
               const { dma, dmi, inpc, pmi, pmr, pri, pti, pvi } = valorCalculoTipoDeductiva;

               function numberWithCommas(x) {
                    let parts = x.toString().split(".");
                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return parts.join(".");
               }

               let priFx = '0'; if(pri){priFx = numberWithCommas(pri.toFixed(0))}
               let pmiFx = '0.00'; if(pmi){pmiFx = numberWithCommas(pmi.toFixed(2))}
               let pmrFx = '0.00'; if(pmr){pmrFx = numberWithCommas(pmr.toFixed(2))}
               let ptiFx = '0.00'; if(pti){ptiFx = numberWithCommas(pti.toFixed(2))}
               let pviFx = '0'; if(pvi){pviFx = numberWithCommas(pvi.toFixed(2))}
               let dmaFx = '0'; if(dma){dmaFx = numberWithCommas(dma.toFixed(2))}
               
               data = [
                    {label: <b><Tooltip title="Número de días del mes i">DMI</Tooltip></b>, data: dmi},
                    {label: <b><Tooltip title="Índice Nacional de Precios al Consumidor">INPC i-1</Tooltip></b>, data: inpc},
                    {label: <b><Tooltip title="Suma de los PPL"><a className='pri'>PRI</a></Tooltip></b>, data: priFx},
                    {label: <b><Tooltip title="Promedio diario de los PPLs en el mes"><a className='naranja'>Pti</a></Tooltip></b>, data: ptiFx},
                    {label: <b><Tooltip title="Diferencia entre PPLs mínimos y número de PPLs promedio en el mes"><a className='naranja'>Pvi</a></Tooltip></b>, data: pviFx},
                    { label: <b><Tooltip title='Pago Mensual de Inversión'><a className='naranja'>PMI</a></Tooltip></b>, data: pmiFx, align: 'center' },
                    { label: <b><Tooltip title='Pago Mensual de Referencia'><a className='naranja'>PMR</a></Tooltip></b>, data: pmrFx, align: 'center' },
                    { label: <b><Tooltip title='Deducción Máxima Aplicable'><a className='naranja'>DMA</a></Tooltip></b>, data: dmaFx, align: 'center' },
               ];
          }

          let dataList = reporteResumenCalculoDR;
          const getSummaryData = () => {
               let totalBorrow = 0; let totalCantidad = 0;
               let key = dataList.length;
               key = key.toString();

               let tipoFalla = '';
               let listTiposFalla = [];
               for(let j in reporteResumenCalculoDR[0].graficaResponseList){
                    tipoFalla = reporteResumenCalculoDR[0].graficaResponseList[j].etiqueta.replace(/\s+/g, '');
                    listTiposFalla.push(tipoFalla);
               }

               dataList.forEach(({ foliosByServicio, deductivaAcumulada }) => {
                    totalBorrow += foliosByServicio;
                    totalCantidad += deductivaAcumulada;
               });

               //let totalCantidadTabla = totalCantidad.toLocaleString('en-US', {'maximumFractionDigits': 2});

               function numberWithCommas(x) {
                    let parts = x.toString().split(".");
                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return parts.join(".");
               }

               let totalCantidadTabla = numberWithCommas(totalCantidad.toFixed(2));

               //if(!totalCantidadTabla.includes('.')){ totalCantidadTabla = totalCantidadTabla + '.00'; }

               dataList = [
                    {
                         idResumenServicio: key,
                         // servicio: <b style={{fontSize:14, color:"#0e2c82"}}>TOTAL</b>,
                         foliosByServicio: <b style={{fontSize:14, color:"#0e2c82"}}>TOTAL</b>,
                         cantidadTabla: <b style={{fontSize:14, color:"#0e2c82"}}>{totalCantidadTabla}</b>,
                    },
                    ...dataList
               ];
               dataList.sort(((a, b) => a.idResumenServicio - b.idResumenServicio));

          }; if(reporteResumenCalculoDR.length >= 1){getSummaryData();}

          const scroll = {"x": 1300 | true, "y": 1000};

          const onDescarga = () => {
               onExportarResumenDeductivaReiteracion(this.state.fechaState, this.state.mesSeleccionado.idMesCalendario, this.state.proyeccion);
          };

          return (
               <Card className="cardCatalogos" title={<div className='headerLista'>Cálculo de Deductivas por Reiteración</div>}>
                    <br/>
                    { permisosUsuario ? (<>
                         <Spin spinning={fetchingListaMesesAbiertos}>
                         <Form
                              name="formulario"
                              ref={this.formRef}
                         >
                              <Row justify='space-around'>
                                   <Col span={7}>
                                        <Form.Item
                                             name='mes-i'
                                        >
                                             <Select
                                                  placeholder='Seleccione el mes a calcular'
                                                  loading={fetchingListaMesesAbiertos}
                                                  onChange={(e) => onMesSelected(e)}
                                             >
                                                  {listaMesesAbiertos.map(option => <Option key={option.idMesCalendario + '-' + option.idAnioCalendario}>{option.mesCalendario + ' ' + option.anioCalendario}</Option>)}
                                             </Select>
                                        </Form.Item>
                                   </Col>

                                   <Col span={7}>
                                        <Button className='nuevoUsuario' disabled={!permisosUsuario.calculoDeDeductivasPorReiteración ? true : !this.state.visibleButton} onClick={onCalculo}>
                                             Cálculo de Deductivas por Reiteración
                                        </Button>
                                   </Col>
                              </Row>

                              { this.state.visibleValores ? (<div className='entrenceElement'>
                                   <Row justify='space-around'>
                                        <Col span={24}>
                                             <Spin spinning={fetchingValorCalculoTipoDeductiva}>
                                                  <Card className="cardCatalogos" bordered={false} title={ 
                                                  <Row>
                                                       <Col span={23} style={{fontSize:25, textAlign:"left", color:"#e64d14"}}>     
                                                            {this.state.mesSeleccionado.mesCalendario + ' ' + this.state.mesSeleccionado.anioCalendario}
                                                       </Col>
                                                       <Col span={23} style={{fontSize:18, textAlign:"left", color:"#ff8c62"}}>     
                                                            Valores para el cálculo:
                                                       </Col>
                                                  </Row> } >
                                                       <Descriptions
                                                            layout="horizontal" bordered={true}>
                                                            {data.map(item => 
                                                                 <Descriptions.Item label={item.label}>{item.data}</Descriptions.Item>
                                                            )}
                                                       </Descriptions>
                                                  </Card>
                                             </Spin>
                                             <br />
                                        </Col>

                                        <Col span={24}>
                                             <Card className="cardCatalogos" bordered={false} title={ 
                                             <Row justify='space-around'>
                                                  <Col span={22} style={{fontSize:18, textAlign:"left", color:"#ff8c62"}}>     
                                                       Reporte en Resumen del Cálculo de Deductivas por Reiteración:
                                                  </Col>
                                                  <Col span={1}>
                                                       <Tooltip title='Exportar a Excel'>
                                                            <Button className="iconSecundary" shape='circle' disabled={!permisosUsuario.exportarExcelDeductivasPorReiteración ? true : this.state.disabledButtons}  onClick={onDescarga} icon={<FileExcelOutlined /> } loading={fetchingExportarExcelCDR}/>
                                                       </Tooltip>
                                                  </Col>
                                                  <Col span={1}>
                                                       <Tooltip title='Ver detalle'>
                                                            <Button className="iconTer" shape="circle" disabled={false} icon={<EyeOutlined />} disabled={!permisosUsuario.detalleDeDeductivasPorReiteración ? true : this.state.disabledButtons} onClick={onShowModalDetalle} loading={fetchingListaDetalleCalculoReiteracion}/>
                                                       </Tooltip>
                                                  </Col>
                                             </Row> } >
                                                  <Table
                                                       size="small"
                                                       rowKey="idResumenServicio"
                                                       columns={reporteResumenCalculoDR.columns}
                                                       dataSource={dataList}
                                                       loading={fetchingReporteResumenCalculoDR}
                                                       locale={{ emptyText: 'Sin datos' }}
                                                       scroll={scroll}
                                                       pagination={{ total: 50, simple: true }}
                                                       pagination={false}
                                                  />
                                             </Card>
                                        </Col>
                                   </Row>
                              </div>):false} 

                              {/* <Row justify='end'>
                                   <Col span={1}>
                                   <br />
                                        <Tooltip title='Exportar a Excel'>
                                             <Button className="btnCargaCircle" shape='circle' disabled={this.state.disabledButtons}  onClick={onDescarga} icon={<FileExcelOutlined /> } loading={fetchingExportarExcelCDR}/>
                                        </Tooltip>
                                   </Col>

                                   <Col span={1}>
                                   <br />
                                        <Tooltip title='Ver detalle'>
                                             <Button className="btnFilterCircle" shape="circle" disabled={false} icon={<EyeOutlined />} disabled={this.state.disabledButtons} onClick={onShowModalDetalle} loading={fetchingListaDetalleCalculoReiteracion}/>
                                        </Tooltip>
                                   </Col>
                              </Row> */}

                              <DetalleCalculo />
                         </Form>
                    </Spin>
                    </>): (<>
                         <Row justify='center'>
                              <Result
                                   status="403"
                                   title="Acceso denegado"
                                   subTitle="Lo siento, no tiene permiso autorizado para acceder."
                              />
                         </Row>
                    </>) }
               </Card>
          );
     }
}
 
 const mapStateToProps = state => {
     return {
          listaMesesAbiertos: state.MecanismosDePagosReducer.listaMesesAbiertos,
          fetchingListaMesesAbiertos: state.MecanismosDePagosReducer.fetchingListaMesesAbiertos,

          valorCalculoTipoDeductiva: state.MecanismosDePagosReducer.valorCalculoTipoDeductiva,
          fetchingValorCalculoTipoDeductiva: state.MecanismosDePagosReducer.fetchingValorCalculoTipoDeductiva,
          fetchingReporteResumenCalculoDR: state.MecanismosDePagosReducer.fetchingReporteResumenCalculoDR, 
          reporteResumenCalculoDR: state.MecanismosDePagosReducer.reporteResumenCalculoDR,
          fetchingExportarExcelCDR: state.MecanismosDePagosReducer.fetchingExportarExcelCDR,
          fetchingListaDetalleCalculoReiteracion: state.MecanismosDePagosReducer.fetchingListaDetalleCalculoReiteracion
     };
 };
 const mapDispatchToProps = dispatch => {
     return {
          showModalDetalle: (fechaProyeccion, idMesCalendario) => {
               dispatch({ type: 'SHOW_MODAL_DETALLE_CALCULO_REITERACION', showModalCalculoDeductivasReiteracion: true, fechaProyeccion, idMesCalendario});
          },
          onGetListaMesesAbiertos: () => {
               dispatch({ type: 'LISTA_MESES_ABIERTOS_REQUEST' });
          },
          onGetValorCalculo: (proyeccion, idMesCalendario) => {
               dispatch({ type: 'VALOR_TIPO_CALCULO_DEDUCTIVA_REQUEST', proyeccion, idMesCalendario });
          },
          onGetReporteResumen: (fechaProyeccion, idMesCalendario, mesCalendario) => {
               dispatch({ type: 'REPORTE_RESUMEN_CALCULO_DEDUCTIVA_REITERACION_REQUEST', fechaProyeccion, idMesCalendario, mesCalendario });
          },
          onExportarResumenDeductivaReiteracion: (fechaProyeccion, idMesCalendario) => {
               dispatch({ type: 'EXPORTAR_EXCEL_CALCULO_DEDUCTIVA_REITERACION_REQUEST', fechaProyeccion, idMesCalendario });
          },
          onGetDetelle: (fechaProyeccion, idMesCalendario) => {
               dispatch({ type: 'LISTA_DETALLE_CALCULO_REITERACION_REQUEST', fechaProyeccion, idMesCalendario });
          },
     };
 };
 export default connect(mapStateToProps, mapDispatchToProps)(CalcularDeductivasReiteracionContainer);