import React from 'react';
import { Row, Col, Switch, Modal, Table, Input, Button, Tooltip } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import ModalNuevoUnidadesFuncionales from './ModalNuevoUnidadesFuncionales';
import ModalEditarUnidadesFuncionales from './ModalEditarUnidadesFuncionales';

const { Search } = Input;
const confirm = Modal.confirm;
class CatalogoDeUnidadesFuncionales extends React.Component {
    componentDidMount(){
        this.props.onGetUnidadesFuncionales(1, '');
        this.props.onGetAreasFuncionalesActivas();
    }
    state={
        page: 1,
        busqueda: '',
    }
    render() {
        const { fetchingListaUnidadesFuncionalesBusqueda,
            listaUnidadesFuncionalesBusqueda,
            onEstadoUnidadesFuncionales, 
            onGetUnidadesFuncionales,
            onModalEditarUnidadesFuncionales,
            onModalNuevoUnidadesFuncionales,
            listaUnidadesFuncionalesBusquedaCantidad,
        } = this.props;
        console.log('listaUnidadesFuncionalesBusqueda');
        console.log(listaUnidadesFuncionalesBusqueda);
        let permisosUsuario = sessionStorage.getItem('permisosUsuario');
        if(permisosUsuario[0]){
            permisosUsuario = JSON.parse(permisosUsuario);
        }
        const onBuscarUnidadesFuncionales = (busqueda) => {
            this.setState({
                busqueda: busqueda,
                page: 1,
            })
            console.log(busqueda);
            onGetUnidadesFuncionales(1, busqueda);
        }
        
        const columns = [{
                title: 'Unidad funcional',
                dataIndex: 'unidadFuncional',
                key: 'unidadFuncional',
            },{
                title: 'Clave unidad',
                dataIndex: 'claveUnidad',
                key: 'claveUnidad',
            },{
                title: 'Cantidad',
                dataIndex: 'cantidad',
                key: 'cantidad',
                align: 'right'
            },{
                title: 'Área funcional a la que pertenece',
                dataIndex: 'areaFuncional',
                key: 'areaFuncional',
            },{
                title: 'Activo',
                dataIndex: 'activo',
                key: 'activo',
                render: (text, record) => (
                    <Switch checked={text} disabled={!permisosUsuario.unidadFuncionalActivoInactivo} onChange={() => handleDesactivar(record)}/>
                ),
            },{
                title: 'Editar',
                key: 'editar',
                render: (text, record) => (
                    <a className='editarIcono' disabled={record.areaFuncionalByIdAreaFuncional.visible ? (record.areaFuncionalByIdAreaFuncional.convenio ? !permisosUsuario.editarUnidadFuncional : !permisosUsuario.editarPorConvenio) : true } onClick={() => onEditar(record)}><EditOutlined /></a>
                ),
            },
        ];
        const onEditar = (key) => {
            console.log('key', key)
            onModalEditarUnidadesFuncionales(key, this.state.page, this.state.busqueda, key.areaFuncionalByIdAreaFuncional.convenio);
        }
        const handleDesactivar = (key) => {
            console.log(key);
            const page = this.state.page;
            confirm({
                title: '¿Estás seguro que deseas cambiar el estado de la Unidad Funcional "'+ key.unidadFuncional +'"?',
                content: '',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    onEstadoUnidadesFuncionales(key.idUnidadFuncional, page)
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
        const handleTableChange = (pagination)=>{
            this.setState({
                page: pagination.current,
            })
            onGetUnidadesFuncionales(pagination.current, this.state.busqueda);
		}
        const onNuevaUnidad = () => {
            onModalNuevoUnidadesFuncionales(this.state.page, this.state.busqueda, listaUnidadesFuncionalesBusqueda.length ? listaUnidadesFuncionalesBusqueda[0].areaFuncionalByIdAreaFuncional.convenio : true);
        }

        return (
            <div>
                <Row justify='space-between' gutter={[8,8]}>
                    <Col span={22}>
                        <Search className='buscarModulos' placeholder="Ingresa el nombre de la unidad funcional" onSearch={value => onBuscarUnidadesFuncionales(value)} style={{ width: '100%' }}/>
                    </Col>
                    {/* <Col span={7}>
                        <Button onClick={onNuevaSubfamilia} disabled={!permisosUsuario.nuevaUnidadFuncional} className='nuevoUsuario'>Nueva Unidad Funcional</Button>
                    </Col> */}
                    <Col span={1}>
                        <Tooltip title='Nueva Unidad Funcional' placement="topRight">
                            <Button className="iconTer" shape="circle"  onClick={onNuevaUnidad} disabled={!permisosUsuario.nuevaUnidadFuncional} icon={<PlusOutlined />}/>
                        </Tooltip>
                    </Col>
                    <Col span={24}>
                        <Table 
                            size="small" 
                            rowKey="claveUnidad" 
                            columns={columns}
                            dataSource={listaUnidadesFuncionalesBusqueda}
                            loading={fetchingListaUnidadesFuncionalesBusqueda}
                            locale={{ emptyText: 'Sin datos' }}
                            onChange={handleTableChange}
                            pagination={{ total: listaUnidadesFuncionalesBusquedaCantidad, simple: true, current: this.state.page}}
                        />         
                    </Col>
                </Row>
                <ModalNuevoUnidadesFuncionales/>
                <ModalEditarUnidadesFuncionales/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        listaUnidadesFuncionalesBusqueda: state.CatalogosGeneralesReducer.listaUnidadesFuncionalesBusqueda,
        fetchingListaUnidadesFuncionalesBusqueda: state.CatalogosGeneralesReducer.fetchingListaUnidadesFuncionalesBusqueda, listaUnidadesFuncionalesBusquedaCantidad: state.CatalogosGeneralesReducer.listaUnidadesFuncionalesBusquedaCantidad,
    };
};
const mapDispatchToProps = dispatch => {
	return {
        onGetUnidadesFuncionales: (page, busqueda) => {
	    	dispatch({ type: "UNIDADESFUNCIONALES_BUSQUEDA_REQUEST", page, busqueda });
        },
        onEstadoUnidadesFuncionales: (idUnidadFuncional, page, busqueda) => {
	    	dispatch({ type: "ESTADO_UNIDADESFUNCIONALES_REQUEST", idUnidadFuncional: idUnidadFuncional, page, busqueda });
        },
        onModalNuevoUnidadesFuncionales: (page, busqueda, bloqueado) => {
            dispatch({ type: "SHOW_MODAL_NUEVO_UNIDADESFUNCIONALES", showModalNuevoUnidadesFuncionales: true, page, busqueda, bloqueado});
        },
        onModalEditarUnidadesFuncionales: (UnidadesFuncionalesSeleccionado, page, busqueda, bloqueado) => {
            dispatch({ type: "SHOW_MODAL_EDITAR_UNIDADESFUNCIONALES", showModalEditarUnidadesFuncionales: true, UnidadesFuncionalesSeleccionado: UnidadesFuncionalesSeleccionado, page, busqueda, bloqueado });
        },
        onGetAreasFuncionalesActivas: () => {
            dispatch({ type: 'LISTA_AREASFUNCIONALES_ACTIVOS_REQUEST' });
        }
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CatalogoDeUnidadesFuncionales);