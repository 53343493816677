import React, { Component } from 'react';
import {Form, Row, Col, Modal, Spin, Input, Tooltip, Select, Button, Divider} from 'antd';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';
import { connect } from 'react-redux';

const { Option } = Select;

class ModalNuevoObjetivo extends Component {
    formRef = React.createRef();

    state = { executed: false, serviciosSeleccionadosMappginEdit: [], };

     render() {
          const { onCloseModal, showModalEditarObjetivo,  objetivoSeleccionado, fetchingEditarObjetivo, listaCentroDeCostosActivos, fetchingListaCentroDeCostosActivos, onListaEstandarFiltro, onListaEstandarFiltroLimpiar, fetchingListaEstandaresByServicio, listaEstandaresDesempenoFiltroActivosByServicio, onGetTemasActivos, onGetTemasActivosLimpiar, fetchingListaTemasActivos, listaTemasActivos, serviciosByIdCentroCosto,  fetchingServiciosByIdCentroCosto, onEditObjetivo, onGetServiciosByIdCentroCosto, onGetServiciosByIdCentroCostoLimpiar, cleanObjeto, onUpdateObject } = this.props;

          const handleCancel = () => {
               onCloseModal();
               this.formRef.current.resetFields();
               this.setState({ executed: false });
               onListaEstandarFiltroLimpiar();
               onGetTemasActivosLimpiar();
               onGetServiciosByIdCentroCostoLimpiar();
          };

          if(objetivoSeleccionado){
               if (this.state.executed === false) {
                    this.setState({executed : true});
                    if(objetivoSeleccionado.objetivosEstandaresByIdObjetivo){
                         let serviciosSeleccionadosMappginEdit = [];
                         for(let i in objetivoSeleccionado.objetivosEstandaresByIdObjetivo){
                              if(objetivoSeleccionado.objetivosEstandaresByIdObjetivo[i].servicioByIdServicio){
                                   serviciosSeleccionadosMappginEdit.push(objetivoSeleccionado.objetivosEstandaresByIdObjetivo[i].servicioByIdServicio);
                              } 
                         }
                         this.setState({ serviciosSeleccionadosMappginEdit: this.props.serviciosMapping });
                    }
   
                    onGetServiciosByIdCentroCosto(objetivoSeleccionado.idCentroCosto);
   
               }
          }

          const onFinish = (formulario) => {
               console.log('Success:', formulario);
               onEditObjetivo(formulario, objetivoSeleccionado, this.props.page, this.props.busqueda);
               cleanObjeto();
          };

          const onFinishFailed = (errorInfo) => {
               console.log('Failed:', errorInfo);
          };

          const buscarEstandar = (value, index) => {
               if (value.length >= 3) {
                   onListaEstandarFiltro(value, index)
               } else {
                   onListaEstandarFiltroLimpiar();
               }
          };

          const buscarTema = (value) => {
               if (value.length >= 3) {
                    console.log(value);
                    onGetTemasActivos(value)
               } else {
                    onGetTemasActivosLimpiar();
               }
          };

          const centroCostoSelected = (value) => {
               if(value) {
                    onGetServiciosByIdCentroCosto(parseInt(value.split(' - ')[0]));
                    this.formRef.current.setFieldsValue({'servicioEditar': undefined});
                    this.setState({ serviciosSeleccionadosMappginEdit: [] });
                    onUpdateObject([]);
               }
          };

          const servicioSelected = (value) => {
               if(value){
                    let serviciosSeleccionadosMappginEdit = [];
                    for(let i in value){
                         for(let j in serviciosByIdCentroCosto){
                              if(serviciosByIdCentroCosto[j].idServicio === parseInt(value[i].split(' - ')[0])){
                                   serviciosSeleccionadosMappginEdit.push(serviciosByIdCentroCosto[j])
                              }
                         }
                    }
                    onUpdateObject(serviciosSeleccionadosMappginEdit);
                    this.setState({ serviciosSeleccionadosMappginEdit });
                    
               }
          };

          const estandarSeleccionado = (value) => { if(value){ onListaEstandarFiltroLimpiar(); } };
        
          return (
               <Modal
                    visible={showModalEditarObjetivo}
                    title='Editar Objetivo'
                    onCancel={handleCancel}
                    footer={false}
                    width={'60%'}
               >
                    { objetivoSeleccionado ? (
                         <Spin spinning={fetchingEditarObjetivo}>
                              <Form name="formulario" onFinish={onFinish}  ref={this.formRef} layout='vertical' initialValues={objetivoSeleccionado}>
                                   <Row justify='space-around'>
                                        <Col span={11}>
                                             <Form.Item name='objetivo' label='Objetivo' rules={[{ required: true, message: 'Favor de llenar el campo'}]}>
                                                  <Input maxLength={250} placeholder='Ingresa nombre del objetivo' />
                                             </Form.Item>
                                        </Col>

                                        <Col span={11}>
                                             <Form.Item name='temaEditar' label='Tema' rules={[{ required: true, message: 'Favor de llenar el campo'}]}>
                                                  <Select style={{ width: '100%', textAlign: 'left' }} placeholder="Ingresa el tema a buscar" loading={fetchingListaTemasActivos}  showSearch  notFoundContent={null} onSearch={buscarTema}>
                                                       {listaTemasActivos.map(option => <Option key={option.idTema + ' - ' + option.tema}>
                                                            <Tooltip placement="topLeft" title={option.tema}>
                                                                 {option.idTema + ' - ' + option.tema}
                                                            </Tooltip>
                                                       </Option>)}
                                                  </Select>
                                             </Form.Item>
                                        </Col>

                                        <Col span={11}>
                                             <Form.Item name='centroCostoEditar' label='Centro de costo' rules={[{ required: true, message: 'Favor de llenar el campo'}]}>
                                                  <Select style={{ width: '100%', textAlign: 'left' }} showSearch placeholder="Selecciona el centro de costo" loading={fetchingListaCentroDeCostosActivos} onChange={centroCostoSelected}>
                                                       {listaCentroDeCostosActivos.map(option => <Option key={option.idCentroCosto + ' - ' + option.centroCosto}>{option.idCentroCosto + ' - ' + option.centroCosto}</Option>)}
                                                  </Select>
                                             </Form.Item>
                                        </Col>

                                        <Col span={11}>
                                             <Form.Item name='servicioEditar' label='Servicio' rules={[{ required: true, message: 'Favor de llenar el campo', }]}>
                                                  <Select allowClear showSearch style={{ width: '100%' }} placeholder="Selecciona el servicio" loading={fetchingServiciosByIdCentroCosto} disabled={ !serviciosByIdCentroCosto[0] } mode='multiple' onChange={servicioSelected}>
                                                       {serviciosByIdCentroCosto.map(option => 
                                                            <Option key={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                                 <Tooltip placement="topLeft" title={option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre}>
                                                                      { option.idServicio + ' - ' + option.nomenclatura + ': ' + option.nombre }
                                                                 </Tooltip>
                                                            </Option>
                                                       )}
                                                  </Select>
                                             </Form.Item>
                                        </Col>

                                   </Row>

                                   { serviciosByIdCentroCosto[0] && this.state.serviciosSeleccionadosMappginEdit ? (<>
                                        { this.props.serviciosMapping.map((index, key) =>
                                        
                                             <Row justify='space-around' key={index.idServicio}>
                                                  <Divider orientation='left' style={{fontSize: 14}}>Estándar para el servicio: <b style={{color:'#1747ca'}}>{ index.nombre }</b></Divider>
                                                  <Col span={23}>
                                                       <Spin spinning={fetchingServiciosByIdCentroCosto}>
                                                       <Form.Item
                                                            //label='Estándar'
                                                            name={'estandar-' + index.idServicio}
                                                            rules={[{ required: true, message: 'Favor de seleccionar una opción', }]}
                                                       >
                                                            <Select style={{ width: '100%', textAlign: 'left' }} placeholder="Ingresa el estándar a buscar" loading={fetchingListaEstandaresByServicio}  showSearch  notFoundContent={null} onSearch={(value) => buscarEstandar(value, index.idServicio)} onChange={estandarSeleccionado}>
                                                                 {listaEstandaresDesempenoFiltroActivosByServicio.map(option => <Option key={option.idEstandarDesempeno + ' - ' + option.referencia + ': ' + option.descripcion}>
                                                                      <Tooltip placement="topLeft" title={option.referencia + ': ' + option.descripcion}>
                                                                           {option.idEstandarDesempeno + ' - ' + option.referencia + ': ' + option.descripcion}
                                                                      </Tooltip>
                                                                 </Option>)}
                                                            </Select>
                                                       </Form.Item>
                                                       </Spin>
                                                  </Col>
                                             </Row>
                                        ) }
                                   </>):false }

                                   <Divider />
                                   <Row justify='space-around'>
                                        <Col span={11}>
                                             <Button className="nuevoUsuario" htmlType='submit'>
                                                  Guardar
                                             </Button>
                                        </Col>
                                   </Row>
                              </Form>
                         </Spin>
                    ): false }
               </Modal>
          )
     }
}

const mapStateToProps = state => {
     return {
          page: state.EvaluacionDeLaGestionReducer.page,
          busqueda: state.EvaluacionDeLaGestionReducer.busqueda,
          showModalEditarObjetivo: state.EvaluacionDeLaGestionReducer.showModalEditarObjetivo,
          objetivoSeleccionado: state.EvaluacionDeLaGestionReducer.objetivoSeleccionado,
          fetchingEditarObjetivo: state.EvaluacionDeLaGestionReducer.fetchingEditarObjetivo,

          listaCentroDeCostosActivos: state.CatalogosGeneralesReducer.listaCentroDeCostosActivos,
          fetchingListaCentroDeCostosActivos: state.CatalogosGeneralesReducer.fetchingListaCentroDeCostosActivos,
          
          fetchingListaEstandaresByServicio: state.EvaluacionDeLaGestionReducer.fetchingListaEstandaresByServicio,
          listaEstandaresDesempenoFiltroActivosByServicio: state.EvaluacionDeLaGestionReducer.listaEstandaresDesempenoFiltroActivosByServicio,

          listaTemasActivos: state.EvaluacionDeLaGestionReducer.listaTemasActivos,
          fetchingListaTemasActivos: state.EvaluacionDeLaGestionReducer.fetchingListaTemasActivos,

          serviciosByIdCentroCosto: state.EvaluacionDeLaGestionReducer.serviciosByIdCentroCosto,
          fetchingServiciosByIdCentroCosto: state.EvaluacionDeLaGestionReducer.fetchingServiciosByIdCentroCosto,

          serviciosMapping: state.EvaluacionDeLaGestionReducer.serviciosMapping
     };
};

const mapDispatchToProps = dispatch => {
     return {
          onCloseModal: () => {
               dispatch({ type: 'SHOW_MODAL_EDITAR_OBJETIVO', showModalEditarObjetivo: false });
          },
          onListaEstandarFiltro: (param, idServicio) => {
               dispatch({ type: "LISTA_ESTANDARES_ACTIVOS_PARAM_BY_SERVICIO_REQUEST", param, idServicio})
          },
          onListaEstandarFiltroLimpiar: () => {
               dispatch({ type: "LISTA_ESTANDARES_ACTIVOS_PARAM_BY_SERVICIO_SUCCESS", listaEstandaresDesempenoFiltroActivosByServicio: []})
          },
          onGetTemasActivos: (param) => {
               dispatch({ type: 'LISTA_TEMAS_ACTIVOS_REQUEST', param });
          },
          onGetTemasActivosLimpiar: () => {
               dispatch({ type: 'LISTA_TEMAS_ACTIVOS_SUCCESS', listaTemasActivos: [] });
          },
          onLimpiarFormulario: () => {
               dispatch({ type: 'LIMPIAR_FORMULARIO', limpiarFormulario: null })
          },
          onEditObjetivo: (formulario, objetivoSeleccionado, page, busqueda) => {
               dispatch({ type: 'EDITAR_OBJETIVO_REQUEST', formulario, objetivoSeleccionado, page, busqueda });
          },
          onGetServiciosByIdCentroCosto: (idCentroCosto) => {
               dispatch({ type: 'SERVICIOS_BY_IDCENTROCOSTO_REQUEST', idCentroCosto });
          },
          onGetServiciosByIdCentroCostoLimpiar: () => {
               dispatch({ type: 'SERVICIOS_BY_IDCENTROCOSTO_SUCCESS', serviciosByIdCentroCosto: [] });
          },
          cleanObjeto:() => {
               dispatch({ type: 'CLEAN_OBJECT_SELECTED', objetivoSeleccionado: [] });
          },
          onUpdateObject: (serviciosMapping) => {
               dispatch({ type: 'UPDATE_OBJETCT_SELECTED', serviciosMapping });
          },
     };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalNuevoObjetivo);