import React from 'react';
import { Card, Alert, Row, Col, List } from 'antd';
import { connect } from "react-redux";
import { ShrinkOutlined, ArrowsAltOutlined, ImportOutlined } from '@ant-design/icons';
//Importar catalogos aquí:
import ReporteDiarioAutoContariner from './ReporteDiarioAuto/ReporteDiarioAutoContainer';
import ReporteContainer from './ReporteSolicitudServicio/ReporteContainer';
import ReporteDinamico from './ReporteDinamico/ReporteDinamico';
function isEmpty(obj) {
     for(var prop in obj) {
          if(obj.hasOwnProperty(prop))
               return false;
     }
     return true;
}
class SolicitudDeServiciosContainer extends React.Component {
     state={
          catalogo: 'Selecciona una Opción',
          colLista: 8,
          colTabla: 15,
          maximizar: false
     }
     render() {
          const { onChangeCatalogo, solicitudDeServicios } = this.props;
          
          let permisosUsuario = sessionStorage.getItem('permisosUsuario');
          if(permisosUsuario[0]){
              permisosUsuario = JSON.parse(permisosUsuario);
          }

          const listaCatalogos = [];
          if(permisosUsuario.buscarReporteDeSolicitudDeServicio){
               listaCatalogos.push({
                    'catalogo': 'Reporte de Solicitud de Servicio',
               })
          }
          if(permisosUsuario.verReporteAutomático){
               listaCatalogos.push({
                    'catalogo': 'Reporte Diario Automático',
               })
          }
          if(permisosUsuario.verReporteDinámico){
               listaCatalogos.push({
                    'catalogo': 'Reporte Dinámico para Solicitudes de Servicio',
               })
          }

          function ShowInfo(props) {
               if (isEmpty(solicitudDeServicios)) {
                    return (
                         <Alert message="Favor de seleccionar una opción" type="info" />
                    );
               }
               
               return (
                    <div>
                         <CatalogoApp/>
                    </div>
               );
          }

          function CatalogoApp() {
               if(solicitudDeServicios === 'Reporte Diario Automático'){
                    return(
                         <ReporteDiarioAutoContariner />
                    );
               }
               else if(solicitudDeServicios === 'Reporte de Solicitud de Servicio'){
                    return(
                         <ReporteContainer />
                    );
               }
               else if(solicitudDeServicios === 'Reporte Dinámico para Solicitudes de Servicio'){
                    return(
                         <ReporteDinamico />
                    );
               }
               return <div>  </div>;
          };
          
          const changeCatalogo = (catalogo) => {
               this.setState({
                    catalogo: catalogo.catalogo,
               })
               onChangeCatalogo(catalogo.catalogo);
          }
          const onExpandir = () => {
               if(!this.state.maximizar){
                    this.setState({
                         colLista: 0,
                         colTabla: 24,
                         maximizar: true
                    });
               }
               else{
                    this.setState({
                         colLista: 8,
                         colTabla: 15,
                         maximizar: false,
                    });
               }
          }

          return (
               <div className="imgCorp">
                    <Row justify='space-between'>
                         <Col span={this.state.colLista}>
                              <List
                                   header={
                                        <div className='headerLista'>Reporteador</div>
                                   }
                                   className="listaCatalogos"
                                   bordered
                                   dataSource={listaCatalogos}
                                   renderItem={item => (
                                   <List.Item className='listaHover'
                                        actions={[<ImportOutlined onClick={(e) => { e.stopPropagation(); changeCatalogo(item); } } className="iconAbrir" />]}
                                   >
                                        <div>{item.catalogo}</div>
                                   </List.Item>
                                   )}
                              />                    
                         </Col>

                         <Col span={this.state.colTabla}>
                              <Card className="cardCatalogos" title={<div className='headerLista'>{this.state.catalogo}</div>} extra={this.state.maximizar ? (<ShrinkOutlined className='iconoMaxMin' onClick={onExpandir} />):(<ArrowsAltOutlined className='iconoMaxMin' onClick={onExpandir}/>)}>
                                   <ShowInfo info={solicitudDeServicios} />
                              </Card> 
                         </Col>
                    </Row>
                    
               </div>
          );
     }
}

const mapStateToProps = state => {
     return {
          vistaDashboard: state.ConfiguracionYSeguridadReducer.vistaDashboard,
          solicitudDeServicios: state.SolicitudDeServiciosReducer.solicitudDeServicios,
          segundos: state.SolicitudDeServiciosReducer.segundos,
     };
};
const mapDispatchToProps = dispatch => {
	return {
          onChangeCatalogo: (solicitudDeServicios) => {
               dispatch({ type: "CAMBIAR_SOLICITUD_SERVICIOS" , solicitudDeServicios: solicitudDeServicios});
          },
          // onShowModal: () => {
          //      dispatch({ type: 'SHOW_MODAL_IDENTIFICACION', showModalIdentificacion: true})
          // },
          onRestartCronometro: (segundos, limpiarCrono) => {
               dispatch({ type: 'RESTART_CRONOMETRO', segundos, limpiarCrono });
          }
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(SolicitudDeServiciosContainer);