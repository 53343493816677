import React from 'react';
import {connect} from 'react-redux';
import { Spin , Modal, Button} from 'antd';

import Formulario from './Formulario';
import '../../../stylesheets/ConfiguracionYSeguridad/Catalogos.css';


function ModalEditarFolioEO (props) {
    const {folioErrorOmisionSeleccionado, fetchingEditarFolioEO, showModalEditarFolioEO, page, busqueda, 
        onShowModalEditarFolio
    } = props;

    const handleCancel = () => {
        onShowModalEditarFolio();
    }

    return (
    <>
        <Modal
            visible={showModalEditarFolioEO}
            title='Editar Folio de Error u Omisión'
            onCancel={handleCancel}
            footer={false}
            width='80%'
        >
            {folioErrorOmisionSeleccionado && 
                <Spin spinning={fetchingEditarFolioEO}>
                    <Formulario id={1} disabled={false} ticketSSConciliado={folioErrorOmisionSeleccionado} action='Editar'>
                        <Button className="nuevoUsuario" style={{ width: '100%' }} htmlType="submit">Editar Folio</Button>
                    </Formulario>
                </Spin>
            }
        </Modal>
    </>
    )
}

const mapStateToProps = state => {
    return {
        folioErrorOmisionSeleccionado: state.MecanismosDePagosReducer.folioErrorOmisionSeleccionado,
        fetchingEditarFolioEO: state.MecanismosDePagosReducer.fetchingEditarFolioEO,
        showModalEditarFolioEO: state.MecanismosDePagosReducer.showModalEditarFolioEO,
        page: state.MecanismosDePagosReducer.page,
        busqueda: state.MecanismosDePagosReducer.busqueda,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowModalEditarFolio: () => {
            dispatch({ type:'MODAL_EDITAR_FOLIO_EO', showModalEditarFolioEO: false})
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditarFolioEO);